import { FeatureSwitch } from '@wukong/feature-switch'

// [how_to_add_client_feature_switch] 2/3: 全量/灰度开关注意事项。当旧版客户端有未开发完的开关判断逻辑时，可以配置旧版客户端开关恒为 false
// 客户端开关配置，某个开关最小支持的客户端版本
export const featureSwitchMinClientVersion: Partial<Record<FeatureSwitch, string>> = {
    ['plugin-development' as FeatureSwitch]: '1.1.6',
}

// [how_to_add_client_feature_switch] 3/3: 全量后下开关注意事项
// 已全量并下掉的开关，对于客户端需要一直保持 true，直到所有在线客户端版本都不依赖该开关
export const fulledSwitchsForClient: string[] = [
    'plugin-development', // 线上最小版本 > 1.1.7 时可删除
]

export const isDisableInClient = (feature: FeatureSwitch) => {
    if (!featureSwitchMinClientVersion[feature]) {
        // 没在 featureSwitchMinClientVersion 配置则不处理
        return false
    }

    const clientVersion = getClientVersion()
    if (!clientVersion) {
        // 不在客户端内不处理
        return false
    }

    if (isVersionLessThan(clientVersion, featureSwitchMinClientVersion[feature])) {
        // 当前客户端版本小于指定版本则不支持
        return true
    }

    return false
}

const getClientVersion = () => {
    const userAgent = navigator.userAgent
    const match = userAgent.match(/Motiff(?:Eng)?\/(\d+\.\d+\.\d+)/)
    return match ? match[1] : null
}

const isVersionLessThan = (v1: string, v2: string) => {
    const v1Parts = v1.split('.').map(Number)
    const v2Parts = v2.split('.').map(Number)

    for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
        const v1Part = v1Parts[i] || 0
        const v2Part = v2Parts[i] || 0

        if (v1Part < v2Part) return true
        if (v1Part > v2Part) return false
    }

    return false
}
