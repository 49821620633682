export const materialDesignThemes = {
    '#B33B15': {
        light: {
            primary: '#8F4C38',
            surfaceTint: '#8F4C38',
            onPrimary: '#FFFFFF',
            primaryContainer: '#FFDBD1',
            onPrimaryContainer: '#723523',
            secondary: '#77574E',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#FFDBD1',
            onSecondaryContainer: '#5D4037',
            tertiary: '#6C5D2F',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#F5E1A7',
            onTertiaryContainer: '#534619',
            error: '#BA1A1A',
            onError: '#FFFFFF',
            errorContainer: '#FFDAD6',
            onErrorContainer: '#93000A',
            background: '#FFF8F6',
            onBackground: '#231917',
            surface: '#FFF8F6',
            onSurface: '#231917',
            surfaceVariant: '#F5DED8',
            onSurfaceVariant: '#53433F',
            outline: '#85736E',
            outlineVariant: '#D8C2BC',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#392E2B',
            inverseOnSurface: '#FFEDE8',
            inversePrimary: '#FFB5A0',
            primaryFixed: '#FFDBD1',
            onPrimaryFixed: '#3A0B01',
            primaryFixedDim: '#FFB5A0',
            onPrimaryFixedVariant: '#723523',
            secondaryFixed: '#FFDBD1',
            onSecondaryFixed: '#2C150F',
            secondaryFixedDim: '#E7BDB2',
            onSecondaryFixedVariant: '#5D4037',
            tertiaryFixed: '#F5E1A7',
            onTertiaryFixed: '#231B00',
            tertiaryFixedDim: '#D8C58D',
            onTertiaryFixedVariant: '#534619',
            surfaceDim: '#E8D6D2',
            surfaceBright: '#FFF8F6',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#FFF1ED',
            surfaceContainer: '#FCEAE5',
            surfaceContainerHigh: '#F7E4E0',
            surfaceContainerHighest: '#F1DFDA',
        },
        'light-medium-contrast': {
            primary: '#5D2514',
            surfaceTint: '#8F4C38',
            onPrimary: '#FFFFFF',
            primaryContainer: '#A15A45',
            onPrimaryContainer: '#FFFFFF',
            secondary: '#4B2F28',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#87655C',
            onSecondaryContainer: '#FFFFFF',
            tertiary: '#41350A',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#7B6C3C',
            onTertiaryContainer: '#FFFFFF',
            error: '#740006',
            onError: '#FFFFFF',
            errorContainer: '#CF2C27',
            onErrorContainer: '#FFFFFF',
            background: '#FFF8F6',
            onBackground: '#231917',
            surface: '#FFF8F6',
            onSurface: '#180F0D',
            surfaceVariant: '#F5DED8',
            onSurfaceVariant: '#41332F',
            outline: '#5F4F4A',
            outlineVariant: '#7B6964',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#392E2B',
            inverseOnSurface: '#FFEDE8',
            inversePrimary: '#FFB5A0',
            primaryFixed: '#A15A45',
            onPrimaryFixed: '#FFFFFF',
            primaryFixedDim: '#84422F',
            onPrimaryFixedVariant: '#FFFFFF',
            secondaryFixed: '#87655C',
            onSecondaryFixed: '#FFFFFF',
            secondaryFixedDim: '#6D4D45',
            onSecondaryFixedVariant: '#FFFFFF',
            tertiaryFixed: '#7B6C3C',
            onTertiaryFixed: '#FFFFFF',
            tertiaryFixedDim: '#615426',
            onTertiaryFixedVariant: '#FFFFFF',
            surfaceDim: '#D4C3BE',
            surfaceBright: '#FFF8F6',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#FFF1ED',
            surfaceContainer: '#F7E4E0',
            surfaceContainerHigh: '#EBD9D4',
            surfaceContainerHighest: '#DFCEC9',
        },
        'light-high-contrast': {
            primary: '#501B0B',
            surfaceTint: '#8F4C38',
            onPrimary: '#FFFFFF',
            primaryContainer: '#753725',
            onPrimaryContainer: '#FFFFFF',
            secondary: '#3F261E',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#60423A',
            onSecondaryContainer: '#FFFFFF',
            tertiary: '#362B02',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#55481C',
            onTertiaryContainer: '#FFFFFF',
            error: '#600004',
            onError: '#FFFFFF',
            errorContainer: '#98000A',
            onErrorContainer: '#FFFFFF',
            background: '#FFF8F6',
            onBackground: '#231917',
            surface: '#FFF8F6',
            onSurface: '#000000',
            surfaceVariant: '#F5DED8',
            onSurfaceVariant: '#000000',
            outline: '#372925',
            outlineVariant: '#554641',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#392E2B',
            inverseOnSurface: '#FFFFFF',
            inversePrimary: '#FFB5A0',
            primaryFixed: '#753725',
            onPrimaryFixed: '#FFFFFF',
            primaryFixedDim: '#592111',
            onPrimaryFixedVariant: '#FFFFFF',
            secondaryFixed: '#60423A',
            onSecondaryFixed: '#FFFFFF',
            secondaryFixedDim: '#472C24',
            onSecondaryFixedVariant: '#FFFFFF',
            tertiaryFixed: '#55481C',
            onTertiaryFixed: '#FFFFFF',
            tertiaryFixedDim: '#3D3206',
            onTertiaryFixedVariant: '#FFFFFF',
            surfaceDim: '#C6B5B1',
            surfaceBright: '#FFF8F6',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#FFEDE8',
            surfaceContainer: '#F1DFDA',
            surfaceContainerHigh: '#E2D1CC',
            surfaceContainerHighest: '#D4C3BE',
        },
        dark: {
            primary: '#FFB5A0',
            surfaceTint: '#FFB5A0',
            onPrimary: '#561F0F',
            primaryContainer: '#723523',
            onPrimaryContainer: '#FFDBD1',
            secondary: '#E7BDB2',
            onSecondary: '#442A22',
            secondaryContainer: '#5D4037',
            onSecondaryContainer: '#FFDBD1',
            tertiary: '#D8C58D',
            onTertiary: '#3B2F05',
            tertiaryContainer: '#534619',
            onTertiaryContainer: '#F5E1A7',
            error: '#FFB4AB',
            onError: '#690005',
            errorContainer: '#93000A',
            onErrorContainer: '#FFDAD6',
            background: '#1A110F',
            onBackground: '#F1DFDA',
            surface: '#1A110F',
            onSurface: '#F1DFDA',
            surfaceVariant: '#53433F',
            onSurfaceVariant: '#D8C2BC',
            outline: '#A08C87',
            outlineVariant: '#53433F',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#F1DFDA',
            inverseOnSurface: '#392E2B',
            inversePrimary: '#8F4C38',
            primaryFixed: '#FFDBD1',
            onPrimaryFixed: '#3A0B01',
            primaryFixedDim: '#FFB5A0',
            onPrimaryFixedVariant: '#723523',
            secondaryFixed: '#FFDBD1',
            onSecondaryFixed: '#2C150F',
            secondaryFixedDim: '#E7BDB2',
            onSecondaryFixedVariant: '#5D4037',
            tertiaryFixed: '#F5E1A7',
            onTertiaryFixed: '#231B00',
            tertiaryFixedDim: '#D8C58D',
            onTertiaryFixedVariant: '#534619',
            surfaceDim: '#1A110F',
            surfaceBright: '#423734',
            surfaceContainerLowest: '#140C0A',
            surfaceContainerLow: '#231917',
            surfaceContainer: '#271D1B',
            surfaceContainerHigh: '#322825',
            surfaceContainerHighest: '#3D322F',
        },
        'dark-medium-contrast': {
            primary: '#FFD2C6',
            surfaceTint: '#FFB5A0',
            onPrimary: '#481506',
            primaryContainer: '#CB7C65',
            onPrimaryContainer: '#000000',
            secondary: '#FED3C7',
            onSecondary: '#381F18',
            secondaryContainer: '#AE887E',
            onSecondaryContainer: '#000000',
            tertiary: '#EFDBA1',
            onTertiary: '#2F2500',
            tertiaryContainer: '#A0905C',
            onTertiaryContainer: '#000000',
            error: '#FFD2CC',
            onError: '#540003',
            errorContainer: '#FF5449',
            onErrorContainer: '#000000',
            background: '#1A110F',
            onBackground: '#F1DFDA',
            surface: '#1A110F',
            onSurface: '#FFFFFF',
            surfaceVariant: '#53433F',
            onSurfaceVariant: '#EED7D1',
            outline: '#C2ADA8',
            outlineVariant: '#A08C87',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#F1DFDA',
            inverseOnSurface: '#322825',
            inversePrimary: '#743624',
            primaryFixed: '#FFDBD1',
            onPrimaryFixed: '#280500',
            primaryFixedDim: '#FFB5A0',
            onPrimaryFixedVariant: '#5D2514',
            secondaryFixed: '#FFDBD1',
            onSecondaryFixed: '#200B06',
            secondaryFixedDim: '#E7BDB2',
            onSecondaryFixedVariant: '#4B2F28',
            tertiaryFixed: '#F5E1A7',
            onTertiaryFixed: '#171100',
            tertiaryFixedDim: '#D8C58D',
            onTertiaryFixedVariant: '#41350A',
            surfaceDim: '#1A110F',
            surfaceBright: '#4E423F',
            surfaceContainerLowest: '#0D0604',
            surfaceContainerLow: '#251B19',
            surfaceContainer: '#302623',
            surfaceContainerHigh: '#3B302D',
            surfaceContainerHighest: '#463B38',
        },
        'dark-high-contrast': {
            primary: '#FFECE7',
            surfaceTint: '#FFB5A0',
            onPrimary: '#000000',
            primaryContainer: '#FFAF98',
            onPrimaryContainer: '#1E0300',
            secondary: '#FFECE7',
            onSecondary: '#000000',
            secondaryContainer: '#E3B9AE',
            onSecondaryContainer: '#190603',
            tertiary: '#FFEFC4',
            onTertiary: '#000000',
            tertiaryContainer: '#D4C289',
            onTertiaryContainer: '#100B00',
            error: '#FFECE9',
            onError: '#000000',
            errorContainer: '#FFAEA4',
            onErrorContainer: '#220001',
            background: '#1A110F',
            onBackground: '#F1DFDA',
            surface: '#1A110F',
            onSurface: '#FFFFFF',
            surfaceVariant: '#53433F',
            onSurfaceVariant: '#FFFFFF',
            outline: '#FFECE7',
            outlineVariant: '#D4BEB8',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#F1DFDA',
            inverseOnSurface: '#000000',
            inversePrimary: '#743624',
            primaryFixed: '#FFDBD1',
            onPrimaryFixed: '#000000',
            primaryFixedDim: '#FFB5A0',
            onPrimaryFixedVariant: '#280500',
            secondaryFixed: '#FFDBD1',
            onSecondaryFixed: '#000000',
            secondaryFixedDim: '#E7BDB2',
            onSecondaryFixedVariant: '#200B06',
            tertiaryFixed: '#F5E1A7',
            onTertiaryFixed: '#000000',
            tertiaryFixedDim: '#D8C58D',
            onTertiaryFixedVariant: '#171100',
            surfaceDim: '#1A110F',
            surfaceBright: '#5A4D4A',
            surfaceContainerLowest: '#000000',
            surfaceContainerLow: '#271D1B',
            surfaceContainer: '#392E2B',
            surfaceContainerHigh: '#443936',
            surfaceContainerHighest: '#504441',
        },
    },
    '#63A002': {
        light: {
            primary: '#4C662B',
            surfaceTint: '#4C662B',
            onPrimary: '#FFFFFF',
            primaryContainer: '#CDEDA3',
            onPrimaryContainer: '#354E16',
            secondary: '#586249',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#DCE7C8',
            onSecondaryContainer: '#404A33',
            tertiary: '#386663',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#BCECE7',
            onTertiaryContainer: '#1F4E4B',
            error: '#BA1A1A',
            onError: '#FFFFFF',
            errorContainer: '#FFDAD6',
            onErrorContainer: '#93000A',
            background: '#F9FAEF',
            onBackground: '#1A1C16',
            surface: '#F9FAEF',
            onSurface: '#1A1C16',
            surfaceVariant: '#E1E4D5',
            onSurfaceVariant: '#44483D',
            outline: '#75796C',
            outlineVariant: '#C5C8BA',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#2F312A',
            inverseOnSurface: '#F1F2E6',
            inversePrimary: '#B1D18A',
            primaryFixed: '#CDEDA3',
            onPrimaryFixed: '#102000',
            primaryFixedDim: '#B1D18A',
            onPrimaryFixedVariant: '#354E16',
            secondaryFixed: '#DCE7C8',
            onSecondaryFixed: '#151E0B',
            secondaryFixedDim: '#BFCBAD',
            onSecondaryFixedVariant: '#404A33',
            tertiaryFixed: '#BCECE7',
            onTertiaryFixed: '#00201E',
            tertiaryFixedDim: '#A0D0CB',
            onTertiaryFixedVariant: '#1F4E4B',
            surfaceDim: '#DADBD0',
            surfaceBright: '#F9FAEF',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#F3F4E9',
            surfaceContainer: '#EEEFE3',
            surfaceContainerHigh: '#E8E9DE',
            surfaceContainerHighest: '#E2E3D8',
        },
        'light-medium-contrast': {
            primary: '#253D05',
            surfaceTint: '#4C662B',
            onPrimary: '#FFFFFF',
            primaryContainer: '#5A7539',
            onPrimaryContainer: '#FFFFFF',
            secondary: '#303924',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#667157',
            onSecondaryContainer: '#FFFFFF',
            tertiary: '#083D3A',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#477572',
            onTertiaryContainer: '#FFFFFF',
            error: '#740006',
            onError: '#FFFFFF',
            errorContainer: '#CF2C27',
            onErrorContainer: '#FFFFFF',
            background: '#F9FAEF',
            onBackground: '#1A1C16',
            surface: '#F9FAEF',
            onSurface: '#0F120C',
            surfaceVariant: '#E1E4D5',
            onSurfaceVariant: '#34382D',
            outline: '#505449',
            outlineVariant: '#6B6F62',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#2F312A',
            inverseOnSurface: '#F1F2E6',
            inversePrimary: '#B1D18A',
            primaryFixed: '#5A7539',
            onPrimaryFixed: '#FFFFFF',
            primaryFixedDim: '#425C23',
            onPrimaryFixedVariant: '#FFFFFF',
            secondaryFixed: '#667157',
            onSecondaryFixed: '#FFFFFF',
            secondaryFixedDim: '#4E5840',
            onSecondaryFixedVariant: '#FFFFFF',
            tertiaryFixed: '#477572',
            onTertiaryFixed: '#FFFFFF',
            tertiaryFixedDim: '#2E5C59',
            onTertiaryFixedVariant: '#FFFFFF',
            surfaceDim: '#C6C7BD',
            surfaceBright: '#F9FAEF',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#F3F4E9',
            surfaceContainer: '#E8E9DE',
            surfaceContainerHigh: '#DCDED3',
            surfaceContainerHighest: '#D1D3C8',
        },
        'light-high-contrast': {
            primary: '#1C3200',
            surfaceTint: '#4C662B',
            onPrimary: '#FFFFFF',
            primaryContainer: '#375018',
            onPrimaryContainer: '#FFFFFF',
            secondary: '#262F1A',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#434C35',
            onSecondaryContainer: '#FFFFFF',
            tertiary: '#003230',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#21504E',
            onTertiaryContainer: '#FFFFFF',
            error: '#600004',
            onError: '#FFFFFF',
            errorContainer: '#98000A',
            onErrorContainer: '#FFFFFF',
            background: '#F9FAEF',
            onBackground: '#1A1C16',
            surface: '#F9FAEF',
            onSurface: '#000000',
            surfaceVariant: '#E1E4D5',
            onSurfaceVariant: '#000000',
            outline: '#2A2D24',
            outlineVariant: '#474B40',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#2F312A',
            inverseOnSurface: '#FFFFFF',
            inversePrimary: '#B1D18A',
            primaryFixed: '#375018',
            onPrimaryFixed: '#FFFFFF',
            primaryFixedDim: '#213903',
            onPrimaryFixedVariant: '#FFFFFF',
            secondaryFixed: '#434C35',
            onSecondaryFixed: '#FFFFFF',
            secondaryFixedDim: '#2C3620',
            onSecondaryFixedVariant: '#FFFFFF',
            tertiaryFixed: '#21504E',
            onTertiaryFixed: '#FFFFFF',
            tertiaryFixedDim: '#033937',
            onTertiaryFixedVariant: '#FFFFFF',
            surfaceDim: '#B8BAAF',
            surfaceBright: '#F9FAEF',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#F1F2E6',
            surfaceContainer: '#E2E3D8',
            surfaceContainerHigh: '#D4D5CA',
            surfaceContainerHighest: '#C6C7BD',
        },
        dark: {
            primary: '#B1D18A',
            surfaceTint: '#B1D18A',
            onPrimary: '#1F3701',
            primaryContainer: '#354E16',
            onPrimaryContainer: '#CDEDA3',
            secondary: '#BFCBAD',
            onSecondary: '#2A331E',
            secondaryContainer: '#404A33',
            onSecondaryContainer: '#DCE7C8',
            tertiary: '#A0D0CB',
            onTertiary: '#003735',
            tertiaryContainer: '#1F4E4B',
            onTertiaryContainer: '#BCECE7',
            error: '#FFB4AB',
            onError: '#690005',
            errorContainer: '#93000A',
            onErrorContainer: '#FFDAD6',
            background: '#12140E',
            onBackground: '#E2E3D8',
            surface: '#12140E',
            onSurface: '#E2E3D8',
            surfaceVariant: '#44483D',
            onSurfaceVariant: '#C5C8BA',
            outline: '#8F9285',
            outlineVariant: '#44483D',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#E2E3D8',
            inverseOnSurface: '#2F312A',
            inversePrimary: '#4C662B',
            primaryFixed: '#CDEDA3',
            onPrimaryFixed: '#102000',
            primaryFixedDim: '#B1D18A',
            onPrimaryFixedVariant: '#354E16',
            secondaryFixed: '#DCE7C8',
            onSecondaryFixed: '#151E0B',
            secondaryFixedDim: '#BFCBAD',
            onSecondaryFixedVariant: '#404A33',
            tertiaryFixed: '#BCECE7',
            onTertiaryFixed: '#00201E',
            tertiaryFixedDim: '#A0D0CB',
            onTertiaryFixedVariant: '#1F4E4B',
            surfaceDim: '#12140E',
            surfaceBright: '#383A32',
            surfaceContainerLowest: '#0C0F09',
            surfaceContainerLow: '#1A1C16',
            surfaceContainer: '#1E201A',
            surfaceContainerHigh: '#282B24',
            surfaceContainerHighest: '#33362E',
        },
        'dark-medium-contrast': {
            primary: '#C7E79E',
            surfaceTint: '#B1D18A',
            onPrimary: '#172B00',
            primaryContainer: '#7D9A59',
            onPrimaryContainer: '#000000',
            secondary: '#D5E1C2',
            onSecondary: '#1F2814',
            secondaryContainer: '#8A9579',
            onSecondaryContainer: '#000000',
            tertiary: '#B5E6E1',
            onTertiary: '#002B29',
            tertiaryContainer: '#6B9995',
            onTertiaryContainer: '#000000',
            error: '#FFD2CC',
            onError: '#540003',
            errorContainer: '#FF5449',
            onErrorContainer: '#000000',
            background: '#12140E',
            onBackground: '#E2E3D8',
            surface: '#12140E',
            onSurface: '#FFFFFF',
            surfaceVariant: '#44483D',
            onSurfaceVariant: '#DBDECF',
            outline: '#B0B3A6',
            outlineVariant: '#8E9285',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#E2E3D8',
            inverseOnSurface: '#282B24',
            inversePrimary: '#364F17',
            primaryFixed: '#CDEDA3',
            onPrimaryFixed: '#081400',
            primaryFixedDim: '#B1D18A',
            onPrimaryFixedVariant: '#253D05',
            secondaryFixed: '#DCE7C8',
            onSecondaryFixed: '#0B1403',
            secondaryFixedDim: '#BFCBAD',
            onSecondaryFixedVariant: '#303924',
            tertiaryFixed: '#BCECE7',
            onTertiaryFixed: '#001413',
            tertiaryFixedDim: '#A0D0CB',
            onTertiaryFixedVariant: '#083D3A',
            surfaceDim: '#12140E',
            surfaceBright: '#43453D',
            surfaceContainerLowest: '#060804',
            surfaceContainerLow: '#1C1E18',
            surfaceContainer: '#262922',
            surfaceContainerHigh: '#31342C',
            surfaceContainerHighest: '#3C3F37',
        },
        'dark-high-contrast': {
            primary: '#DAFBB0',
            surfaceTint: '#B1D18A',
            onPrimary: '#000000',
            primaryContainer: '#ADCD86',
            onPrimaryContainer: '#050E00',
            secondary: '#E9F4D5',
            onSecondary: '#000000',
            secondaryContainer: '#BCC7A9',
            onSecondaryContainer: '#060D01',
            tertiary: '#C9F9F5',
            onTertiary: '#000000',
            tertiaryContainer: '#9CCCC7',
            onTertiaryContainer: '#000E0D',
            error: '#FFECE9',
            onError: '#000000',
            errorContainer: '#FFAEA4',
            onErrorContainer: '#220001',
            background: '#12140E',
            onBackground: '#E2E3D8',
            surface: '#12140E',
            onSurface: '#FFFFFF',
            surfaceVariant: '#44483D',
            onSurfaceVariant: '#FFFFFF',
            outline: '#EEF2E2',
            outlineVariant: '#C1C4B6',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#E2E3D8',
            inverseOnSurface: '#000000',
            inversePrimary: '#364F17',
            primaryFixed: '#CDEDA3',
            onPrimaryFixed: '#000000',
            primaryFixedDim: '#B1D18A',
            onPrimaryFixedVariant: '#081400',
            secondaryFixed: '#DCE7C8',
            onSecondaryFixed: '#000000',
            secondaryFixedDim: '#BFCBAD',
            onSecondaryFixedVariant: '#0B1403',
            tertiaryFixed: '#BCECE7',
            onTertiaryFixed: '#000000',
            tertiaryFixedDim: '#A0D0CB',
            onTertiaryFixedVariant: '#001413',
            surfaceDim: '#12140E',
            surfaceBright: '#4F5149',
            surfaceContainerLowest: '#000000',
            surfaceContainerLow: '#1E201A',
            surfaceContainer: '#2F312A',
            surfaceContainerHigh: '#3A3C35',
            surfaceContainerHighest: '#454840',
        },
    },
    '#769CDF': {
        light: {
            primary: '#415F91',
            surfaceTint: '#415F91',
            onPrimary: '#FFFFFF',
            primaryContainer: '#D6E3FF',
            onPrimaryContainer: '#284777',
            secondary: '#565F71',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#DAE2F9',
            onSecondaryContainer: '#3E4759',
            tertiary: '#705575',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#FAD8FD',
            onTertiaryContainer: '#573E5C',
            error: '#BA1A1A',
            onError: '#FFFFFF',
            errorContainer: '#FFDAD6',
            onErrorContainer: '#93000A',
            background: '#F9F9FF',
            onBackground: '#191C20',
            surface: '#F9F9FF',
            onSurface: '#191C20',
            surfaceVariant: '#E0E2EC',
            onSurfaceVariant: '#44474E',
            outline: '#74777F',
            outlineVariant: '#C4C6D0',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#2E3036',
            inverseOnSurface: '#F0F0F7',
            inversePrimary: '#AAC7FF',
            primaryFixed: '#D6E3FF',
            onPrimaryFixed: '#001B3E',
            primaryFixedDim: '#AAC7FF',
            onPrimaryFixedVariant: '#284777',
            secondaryFixed: '#DAE2F9',
            onSecondaryFixed: '#131C2B',
            secondaryFixedDim: '#BEC6DC',
            onSecondaryFixedVariant: '#3E4759',
            tertiaryFixed: '#FAD8FD',
            onTertiaryFixed: '#28132E',
            tertiaryFixedDim: '#DDBCE0',
            onTertiaryFixedVariant: '#573E5C',
            surfaceDim: '#D9D9E0',
            surfaceBright: '#F9F9FF',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#F3F3FA',
            surfaceContainer: '#EDEDF4',
            surfaceContainerHigh: '#E7E8EE',
            surfaceContainerHighest: '#E2E2E9',
        },
        'light-medium-contrast': {
            primary: '#133665',
            surfaceTint: '#415F91',
            onPrimary: '#FFFFFF',
            primaryContainer: '#506DA0',
            onPrimaryContainer: '#FFFFFF',
            secondary: '#2E3647',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#646D80',
            onSecondaryContainer: '#FFFFFF',
            tertiary: '#452E4A',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#7F6484',
            onTertiaryContainer: '#FFFFFF',
            error: '#740006',
            onError: '#FFFFFF',
            errorContainer: '#CF2C27',
            onErrorContainer: '#FFFFFF',
            background: '#F9F9FF',
            onBackground: '#191C20',
            surface: '#F9F9FF',
            onSurface: '#0F1116',
            surfaceVariant: '#E0E2EC',
            onSurfaceVariant: '#33363E',
            outline: '#4F525A',
            outlineVariant: '#6A6D75',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#2E3036',
            inverseOnSurface: '#F0F0F7',
            inversePrimary: '#AAC7FF',
            primaryFixed: '#506DA0',
            onPrimaryFixed: '#FFFFFF',
            primaryFixedDim: '#375586',
            onPrimaryFixedVariant: '#FFFFFF',
            secondaryFixed: '#646D80',
            onSecondaryFixed: '#FFFFFF',
            secondaryFixedDim: '#4C5567',
            onSecondaryFixedVariant: '#FFFFFF',
            tertiaryFixed: '#7F6484',
            onTertiaryFixed: '#FFFFFF',
            tertiaryFixedDim: '#654C6B',
            onTertiaryFixedVariant: '#FFFFFF',
            surfaceDim: '#C5C6CD',
            surfaceBright: '#F9F9FF',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#F3F3FA',
            surfaceContainer: '#E7E8EE',
            surfaceContainerHigh: '#DCDCE3',
            surfaceContainerHighest: '#D1D1D8',
        },
        'light-high-contrast': {
            primary: '#032B5B',
            surfaceTint: '#415F91',
            onPrimary: '#FFFFFF',
            primaryContainer: '#2A497A',
            onPrimaryContainer: '#FFFFFF',
            secondary: '#232C3D',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#41495B',
            onSecondaryContainer: '#FFFFFF',
            tertiary: '#3A2440',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#59405E',
            onTertiaryContainer: '#FFFFFF',
            error: '#600004',
            onError: '#FFFFFF',
            errorContainer: '#98000A',
            onErrorContainer: '#FFFFFF',
            background: '#F9F9FF',
            onBackground: '#191C20',
            surface: '#F9F9FF',
            onSurface: '#000000',
            surfaceVariant: '#E0E2EC',
            onSurfaceVariant: '#000000',
            outline: '#292C33',
            outlineVariant: '#464951',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#2E3036',
            inverseOnSurface: '#FFFFFF',
            inversePrimary: '#AAC7FF',
            primaryFixed: '#2A497A',
            onPrimaryFixed: '#FFFFFF',
            primaryFixedDim: '#0E3262',
            onPrimaryFixedVariant: '#FFFFFF',
            secondaryFixed: '#41495B',
            onSecondaryFixed: '#FFFFFF',
            secondaryFixedDim: '#2A3344',
            onSecondaryFixedVariant: '#FFFFFF',
            tertiaryFixed: '#59405E',
            onTertiaryFixed: '#FFFFFF',
            tertiaryFixedDim: '#412A47',
            onTertiaryFixedVariant: '#FFFFFF',
            surfaceDim: '#B8B8BF',
            surfaceBright: '#F9F9FF',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#F0F0F7',
            surfaceContainer: '#E2E2E9',
            surfaceContainerHigh: '#D3D4DB',
            surfaceContainerHighest: '#C5C6CD',
        },
        dark: {
            primary: '#AAC7FF',
            surfaceTint: '#AAC7FF',
            onPrimary: '#0A305F',
            primaryContainer: '#284777',
            onPrimaryContainer: '#D6E3FF',
            secondary: '#BEC6DC',
            onSecondary: '#283141',
            secondaryContainer: '#3E4759',
            onSecondaryContainer: '#DAE2F9',
            tertiary: '#DDBCE0',
            onTertiary: '#3F2844',
            tertiaryContainer: '#573E5C',
            onTertiaryContainer: '#FAD8FD',
            error: '#FFB4AB',
            onError: '#690005',
            errorContainer: '#93000A',
            onErrorContainer: '#FFDAD6',
            background: '#111318',
            onBackground: '#E2E2E9',
            surface: '#111318',
            onSurface: '#E2E2E9',
            surfaceVariant: '#44474E',
            onSurfaceVariant: '#C4C6D0',
            outline: '#8E9099',
            outlineVariant: '#44474E',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#E2E2E9',
            inverseOnSurface: '#2E3036',
            inversePrimary: '#415F91',
            primaryFixed: '#D6E3FF',
            onPrimaryFixed: '#001B3E',
            primaryFixedDim: '#AAC7FF',
            onPrimaryFixedVariant: '#284777',
            secondaryFixed: '#DAE2F9',
            onSecondaryFixed: '#131C2B',
            secondaryFixedDim: '#BEC6DC',
            onSecondaryFixedVariant: '#3E4759',
            tertiaryFixed: '#FAD8FD',
            onTertiaryFixed: '#28132E',
            tertiaryFixedDim: '#DDBCE0',
            onTertiaryFixedVariant: '#573E5C',
            surfaceDim: '#111318',
            surfaceBright: '#37393E',
            surfaceContainerLowest: '#0C0E13',
            surfaceContainerLow: '#191C20',
            surfaceContainer: '#1D2024',
            surfaceContainerHigh: '#282A2F',
            surfaceContainerHighest: '#33353A',
        },
        'dark-medium-contrast': {
            primary: '#CDDDFF',
            surfaceTint: '#AAC7FF',
            onPrimary: '#002551',
            primaryContainer: '#7491C7',
            onPrimaryContainer: '#000000',
            secondary: '#D4DCF2',
            onSecondary: '#1D2636',
            secondaryContainer: '#8891A5',
            onSecondaryContainer: '#000000',
            tertiary: '#F3D2F7',
            onTertiary: '#331D39',
            tertiaryContainer: '#A487A9',
            onTertiaryContainer: '#000000',
            error: '#FFD2CC',
            onError: '#540003',
            errorContainer: '#FF5449',
            onErrorContainer: '#000000',
            background: '#111318',
            onBackground: '#E2E2E9',
            surface: '#111318',
            onSurface: '#FFFFFF',
            surfaceVariant: '#44474E',
            onSurfaceVariant: '#DADCE6',
            outline: '#AFB2BB',
            outlineVariant: '#8E9099',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#E2E2E9',
            inverseOnSurface: '#282A2F',
            inversePrimary: '#294878',
            primaryFixed: '#D6E3FF',
            onPrimaryFixed: '#00112B',
            primaryFixedDim: '#AAC7FF',
            onPrimaryFixedVariant: '#133665',
            secondaryFixed: '#DAE2F9',
            onSecondaryFixed: '#081121',
            secondaryFixedDim: '#BEC6DC',
            onSecondaryFixedVariant: '#2E3647',
            tertiaryFixed: '#FAD8FD',
            onTertiaryFixed: '#1D0823',
            tertiaryFixedDim: '#DDBCE0',
            onTertiaryFixedVariant: '#452E4A',
            surfaceDim: '#111318',
            surfaceBright: '#43444A',
            surfaceContainerLowest: '#06070C',
            surfaceContainerLow: '#1B1E22',
            surfaceContainer: '#26282D',
            surfaceContainerHigh: '#313238',
            surfaceContainerHighest: '#3C3E43',
        },
        'dark-high-contrast': {
            primary: '#EBF0FF',
            surfaceTint: '#AAC7FF',
            onPrimary: '#000000',
            primaryContainer: '#A6C3FC',
            onPrimaryContainer: '#000B20',
            secondary: '#EBF0FF',
            onSecondary: '#000000',
            secondaryContainer: '#BAC3D8',
            onSecondaryContainer: '#030B1A',
            tertiary: '#FFE9FF',
            onTertiary: '#000000',
            tertiaryContainer: '#D8B8DC',
            onTertiaryContainer: '#16041D',
            error: '#FFECE9',
            onError: '#000000',
            errorContainer: '#FFAEA4',
            onErrorContainer: '#220001',
            background: '#111318',
            onBackground: '#E2E2E9',
            surface: '#111318',
            onSurface: '#FFFFFF',
            surfaceVariant: '#44474E',
            onSurfaceVariant: '#FFFFFF',
            outline: '#EEEFF9',
            outlineVariant: '#C0C2CC',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#E2E2E9',
            inverseOnSurface: '#000000',
            inversePrimary: '#294878',
            primaryFixed: '#D6E3FF',
            onPrimaryFixed: '#000000',
            primaryFixedDim: '#AAC7FF',
            onPrimaryFixedVariant: '#00112B',
            secondaryFixed: '#DAE2F9',
            onSecondaryFixed: '#000000',
            secondaryFixedDim: '#BEC6DC',
            onSecondaryFixedVariant: '#081121',
            tertiaryFixed: '#FAD8FD',
            onTertiaryFixed: '#000000',
            tertiaryFixedDim: '#DDBCE0',
            onTertiaryFixedVariant: '#1D0823',
            surfaceDim: '#111318',
            surfaceBright: '#4E5056',
            surfaceContainerLowest: '#000000',
            surfaceContainerLow: '#1D2024',
            surfaceContainer: '#2E3036',
            surfaceContainerHigh: '#393B41',
            surfaceContainerHighest: '#45474C',
        },
    },
    '#FFDE3F': {
        light: {
            primary: '#6D5E0F',
            surfaceTint: '#6D5E0F',
            onPrimary: '#FFFFFF',
            primaryContainer: '#F8E287',
            onPrimaryContainer: '#534600',
            secondary: '#665E40',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#EEE2BC',
            onSecondaryContainer: '#4E472A',
            tertiary: '#43664E',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#C5ECCE',
            onTertiaryContainer: '#2C4E38',
            error: '#BA1A1A',
            onError: '#FFFFFF',
            errorContainer: '#FFDAD6',
            onErrorContainer: '#93000A',
            background: '#FFF9EE',
            onBackground: '#1E1B13',
            surface: '#FFF9EE',
            onSurface: '#1E1B13',
            surfaceVariant: '#EAE2D0',
            onSurfaceVariant: '#4B4739',
            outline: '#7C7767',
            outlineVariant: '#CDC6B4',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#333027',
            inverseOnSurface: '#F7F0E2',
            inversePrimary: '#DBC66E',
            primaryFixed: '#F8E287',
            onPrimaryFixed: '#221B00',
            primaryFixedDim: '#DBC66E',
            onPrimaryFixedVariant: '#534600',
            secondaryFixed: '#EEE2BC',
            onSecondaryFixed: '#211B04',
            secondaryFixedDim: '#D1C6A1',
            onSecondaryFixedVariant: '#4E472A',
            tertiaryFixed: '#C5ECCE',
            onTertiaryFixed: '#00210F',
            tertiaryFixedDim: '#A9D0B3',
            onTertiaryFixedVariant: '#2C4E38',
            surfaceDim: '#E0D9CC',
            surfaceBright: '#FFF9EE',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#FAF3E5',
            surfaceContainer: '#F4EDDF',
            surfaceContainerHigh: '#EEE8DA',
            surfaceContainerHighest: '#E8E2D4',
        },
        'light-medium-contrast': {
            primary: '#403600',
            surfaceTint: '#6D5E0F',
            onPrimary: '#FFFFFF',
            primaryContainer: '#7D6C1E',
            onPrimaryContainer: '#FFFFFF',
            secondary: '#3C361B',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#756D4E',
            onSecondaryContainer: '#FFFFFF',
            tertiary: '#1B3D28',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#52755D',
            onTertiaryContainer: '#FFFFFF',
            error: '#740006',
            onError: '#FFFFFF',
            errorContainer: '#CF2C27',
            onErrorContainer: '#FFFFFF',
            background: '#FFF9EE',
            onBackground: '#1E1B13',
            surface: '#FFF9EE',
            onSurface: '#131109',
            surfaceVariant: '#EAE2D0',
            onSurfaceVariant: '#3A3629',
            outline: '#575244',
            outlineVariant: '#726D5E',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#333027',
            inverseOnSurface: '#F7F0E2',
            inversePrimary: '#DBC66E',
            primaryFixed: '#7D6C1E',
            onPrimaryFixed: '#FFFFFF',
            primaryFixedDim: '#635403',
            onPrimaryFixedVariant: '#FFFFFF',
            secondaryFixed: '#756D4E',
            onSecondaryFixed: '#FFFFFF',
            secondaryFixedDim: '#5C5537',
            onSecondaryFixedVariant: '#FFFFFF',
            tertiaryFixed: '#52755D',
            onTertiaryFixed: '#FFFFFF',
            tertiaryFixedDim: '#3A5C45',
            onTertiaryFixedVariant: '#FFFFFF',
            surfaceDim: '#CCC6B9',
            surfaceBright: '#FFF9EE',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#FAF3E5',
            surfaceContainer: '#EEE8DA',
            surfaceContainerHigh: '#E3DCCF',
            surfaceContainerHighest: '#D7D1C4',
        },
        'light-high-contrast': {
            primary: '#352C00',
            surfaceTint: '#6D5E0F',
            onPrimary: '#FFFFFF',
            primaryContainer: '#564800',
            onPrimaryContainer: '#FFFFFF',
            secondary: '#322C12',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#50492D',
            onSecondaryContainer: '#FFFFFF',
            tertiary: '#10321F',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#2E503A',
            onTertiaryContainer: '#FFFFFF',
            error: '#600004',
            onError: '#FFFFFF',
            errorContainer: '#98000A',
            onErrorContainer: '#FFFFFF',
            background: '#FFF9EE',
            onBackground: '#1E1B13',
            surface: '#FFF9EE',
            onSurface: '#000000',
            surfaceVariant: '#EAE2D0',
            onSurfaceVariant: '#000000',
            outline: '#2F2C20',
            outlineVariant: '#4D493B',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#333027',
            inverseOnSurface: '#FFFFFF',
            inversePrimary: '#DBC66E',
            primaryFixed: '#564800',
            onPrimaryFixed: '#FFFFFF',
            primaryFixedDim: '#3C3200',
            onPrimaryFixedVariant: '#FFFFFF',
            secondaryFixed: '#50492D',
            onSecondaryFixed: '#FFFFFF',
            secondaryFixedDim: '#393318',
            onSecondaryFixedVariant: '#FFFFFF',
            tertiaryFixed: '#2E503A',
            onTertiaryFixed: '#FFFFFF',
            tertiaryFixedDim: '#173925',
            onTertiaryFixedVariant: '#FFFFFF',
            surfaceDim: '#BEB8AB',
            surfaceBright: '#FFF9EE',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#F7F0E2',
            surfaceContainer: '#E8E2D4',
            surfaceContainerHigh: '#DAD4C6',
            surfaceContainerHighest: '#CCC6B9',
        },
        dark: {
            primary: '#DBC66E',
            surfaceTint: '#DBC66E',
            onPrimary: '#3A3000',
            primaryContainer: '#534600',
            onPrimaryContainer: '#F8E287',
            secondary: '#D1C6A1',
            onSecondary: '#363016',
            secondaryContainer: '#4E472A',
            onSecondaryContainer: '#EEE2BC',
            tertiary: '#A9D0B3',
            onTertiary: '#143723',
            tertiaryContainer: '#2C4E38',
            onTertiaryContainer: '#C5ECCE',
            error: '#FFB4AB',
            onError: '#690005',
            errorContainer: '#93000A',
            onErrorContainer: '#FFDAD6',
            background: '#15130B',
            onBackground: '#E8E2D4',
            surface: '#15130B',
            onSurface: '#E8E2D4',
            surfaceVariant: '#4B4739',
            onSurfaceVariant: '#CDC6B4',
            outline: '#969080',
            outlineVariant: '#4B4739',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#E8E2D4',
            inverseOnSurface: '#333027',
            inversePrimary: '#6D5E0F',
            primaryFixed: '#F8E287',
            onPrimaryFixed: '#221B00',
            primaryFixedDim: '#DBC66E',
            onPrimaryFixedVariant: '#534600',
            secondaryFixed: '#EEE2BC',
            onSecondaryFixed: '#211B04',
            secondaryFixedDim: '#D1C6A1',
            onSecondaryFixedVariant: '#4E472A',
            tertiaryFixed: '#C5ECCE',
            onTertiaryFixed: '#00210F',
            tertiaryFixedDim: '#A9D0B3',
            onTertiaryFixedVariant: '#2C4E38',
            surfaceDim: '#15130B',
            surfaceBright: '#3C3930',
            surfaceContainerLowest: '#100E07',
            surfaceContainerLow: '#1E1B13',
            surfaceContainer: '#222017',
            surfaceContainerHigh: '#2D2A21',
            surfaceContainerHighest: '#38352B',
        },
        'dark-medium-contrast': {
            primary: '#F2DC82',
            surfaceTint: '#DBC66E',
            onPrimary: '#2D2500',
            primaryContainer: '#A3903F',
            onPrimaryContainer: '#000000',
            secondary: '#E8DCB6',
            onSecondary: '#2B250C',
            secondaryContainer: '#9A916F',
            onSecondaryContainer: '#000000',
            tertiary: '#BFE6C8',
            onTertiary: '#082C18',
            tertiaryContainer: '#75997F',
            onTertiaryContainer: '#000000',
            error: '#FFD2CC',
            onError: '#540003',
            errorContainer: '#FF5449',
            onErrorContainer: '#000000',
            background: '#15130B',
            onBackground: '#E8E2D4',
            surface: '#15130B',
            onSurface: '#FFFFFF',
            surfaceVariant: '#4B4739',
            onSurfaceVariant: '#E3DCCA',
            outline: '#B8B1A0',
            outlineVariant: '#969080',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#E8E2D4',
            inverseOnSurface: '#2D2A21',
            inversePrimary: '#554700',
            primaryFixed: '#F8E287',
            onPrimaryFixed: '#161100',
            primaryFixedDim: '#DBC66E',
            onPrimaryFixedVariant: '#403600',
            secondaryFixed: '#EEE2BC',
            onSecondaryFixed: '#161100',
            secondaryFixedDim: '#D1C6A1',
            onSecondaryFixedVariant: '#3C361B',
            tertiaryFixed: '#C5ECCE',
            onTertiaryFixed: '#001508',
            tertiaryFixedDim: '#A9D0B3',
            onTertiaryFixedVariant: '#1B3D28',
            surfaceDim: '#15130B',
            surfaceBright: '#48443A',
            surfaceContainerLowest: '#090703',
            surfaceContainerLow: '#201D15',
            surfaceContainer: '#2A281F',
            surfaceContainerHigh: '#353229',
            surfaceContainerHighest: '#413D34',
        },
        'dark-high-contrast': {
            primary: '#FFF0BA',
            surfaceTint: '#DBC66E',
            onPrimary: '#000000',
            primaryContainer: '#D7C26B',
            onPrimaryContainer: '#0F0B00',
            secondary: '#FCF0C9',
            onSecondary: '#000000',
            secondaryContainer: '#CDC29E',
            onSecondaryContainer: '#0F0B00',
            tertiary: '#D2FADB',
            onTertiary: '#000000',
            tertiaryContainer: '#A5CCAF',
            onTertiaryContainer: '#000F05',
            error: '#FFECE9',
            onError: '#000000',
            errorContainer: '#FFAEA4',
            onErrorContainer: '#220001',
            background: '#15130B',
            onBackground: '#E8E2D4',
            surface: '#15130B',
            onSurface: '#FFFFFF',
            surfaceVariant: '#4B4739',
            onSurfaceVariant: '#FFFFFF',
            outline: '#F7EFDD',
            outlineVariant: '#C9C2B1',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#E8E2D4',
            inverseOnSurface: '#000000',
            inversePrimary: '#554700',
            primaryFixed: '#F8E287',
            onPrimaryFixed: '#000000',
            primaryFixedDim: '#DBC66E',
            onPrimaryFixedVariant: '#161100',
            secondaryFixed: '#EEE2BC',
            onSecondaryFixed: '#000000',
            secondaryFixedDim: '#D1C6A1',
            onSecondaryFixedVariant: '#161100',
            tertiaryFixed: '#C5ECCE',
            onTertiaryFixed: '#000000',
            tertiaryFixedDim: '#A9D0B3',
            onTertiaryFixedVariant: '#001508',
            surfaceDim: '#15130B',
            surfaceBright: '#535046',
            surfaceContainerLowest: '#000000',
            surfaceContainerLow: '#222017',
            surfaceContainer: '#333027',
            surfaceContainerHigh: '#3E3B32',
            surfaceContainerHighest: '#4A473D',
        },
    },
    '#CA3E6C': {
        light: {
            primary: '#8D4A5B',
            surfaceTint: '#8D4A5B',
            onPrimary: '#FFFFFF',
            primaryContainer: '#FFD9E0',
            onPrimaryContainer: '#713344',
            secondary: '#75565D',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#FFD9E0',
            onSecondaryContainer: '#5B3F45',
            tertiary: '#7B5733',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#FFDCBE',
            onTertiaryContainer: '#60401E',
            error: '#BA1A1A',
            onError: '#FFFFFF',
            errorContainer: '#FFDAD6',
            onErrorContainer: '#93000A',
            background: '#FFF8F7',
            onBackground: '#22191B',
            surface: '#FFF8F7',
            onSurface: '#22191B',
            surfaceVariant: '#F3DDE0',
            onSurfaceVariant: '#514346',
            outline: '#847376',
            outlineVariant: '#D6C2C4',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#382E30',
            inverseOnSurface: '#FEEDEF',
            inversePrimary: '#FFB1C2',
            primaryFixed: '#FFD9E0',
            onPrimaryFixed: '#3A0719',
            primaryFixedDim: '#FFB1C2',
            onPrimaryFixedVariant: '#713344',
            secondaryFixed: '#FFD9E0',
            onSecondaryFixed: '#2B151A',
            secondaryFixedDim: '#E4BDC4',
            onSecondaryFixedVariant: '#5B3F45',
            tertiaryFixed: '#FFDCBE',
            onTertiaryFixed: '#2C1600',
            tertiaryFixedDim: '#EDBE91',
            onTertiaryFixedVariant: '#60401E',
            surfaceDim: '#E7D6D8',
            surfaceBright: '#FFF8F7',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#FFF0F1',
            surfaceContainer: '#FBEAEC',
            surfaceContainerHigh: '#F5E4E6',
            surfaceContainerHighest: '#EFDEE0',
        },
        'light-medium-contrast': {
            primary: '#5C2233',
            surfaceTint: '#8D4A5B',
            onPrimary: '#FFFFFF',
            primaryContainer: '#9E5869',
            onPrimaryContainer: '#FFFFFF',
            secondary: '#492F35',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#85656B',
            onSecondaryContainer: '#FFFFFF',
            tertiary: '#4D300F',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#8B6640',
            onTertiaryContainer: '#FFFFFF',
            error: '#740006',
            onError: '#FFFFFF',
            errorContainer: '#CF2C27',
            onErrorContainer: '#FFFFFF',
            background: '#FFF8F7',
            onBackground: '#22191B',
            surface: '#FFF8F7',
            onSurface: '#170F11',
            surfaceVariant: '#F3DDE0',
            onSurfaceVariant: '#403335',
            outline: '#5E4F51',
            outlineVariant: '#79696C',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#382E30',
            inverseOnSurface: '#FEEDEF',
            inversePrimary: '#FFB1C2',
            primaryFixed: '#9E5869',
            onPrimaryFixed: '#FFFFFF',
            primaryFixedDim: '#824051',
            onPrimaryFixedVariant: '#FFFFFF',
            secondaryFixed: '#85656B',
            onSecondaryFixed: '#FFFFFF',
            secondaryFixedDim: '#6A4D53',
            onSecondaryFixedVariant: '#FFFFFF',
            tertiaryFixed: '#8B6640',
            onTertiaryFixed: '#FFFFFF',
            tertiaryFixedDim: '#704E2A',
            onTertiaryFixedVariant: '#FFFFFF',
            surfaceDim: '#D3C3C5',
            surfaceBright: '#FFF8F7',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#FFF0F1',
            surfaceContainer: '#F5E4E6',
            surfaceContainerHigh: '#E9D9DB',
            surfaceContainerHighest: '#DECED0',
        },
        'light-high-contrast': {
            primary: '#501829',
            surfaceTint: '#8D4A5B',
            onPrimary: '#FFFFFF',
            primaryContainer: '#743546',
            onPrimaryContainer: '#FFFFFF',
            secondary: '#3E252B',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#5E4148',
            onSecondaryContainer: '#FFFFFF',
            tertiary: '#412606',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#634220',
            onTertiaryContainer: '#FFFFFF',
            error: '#600004',
            onError: '#FFFFFF',
            errorContainer: '#98000A',
            onErrorContainer: '#FFFFFF',
            background: '#FFF8F7',
            onBackground: '#22191B',
            surface: '#FFF8F7',
            onSurface: '#000000',
            surfaceVariant: '#F3DDE0',
            onSurfaceVariant: '#000000',
            outline: '#35292B',
            outlineVariant: '#544548',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#382E30',
            inverseOnSurface: '#FFFFFF',
            inversePrimary: '#FFB1C2',
            primaryFixed: '#743546',
            onPrimaryFixed: '#FFFFFF',
            primaryFixedDim: '#581F30',
            onPrimaryFixedVariant: '#FFFFFF',
            secondaryFixed: '#5E4148',
            onSecondaryFixed: '#FFFFFF',
            secondaryFixedDim: '#452B31',
            onSecondaryFixedVariant: '#FFFFFF',
            tertiaryFixed: '#634220',
            onTertiaryFixed: '#FFFFFF',
            tertiaryFixedDim: '#492C0B',
            onTertiaryFixedVariant: '#FFFFFF',
            surfaceDim: '#C4B5B7',
            surfaceBright: '#FFF8F7',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#FEEDEF',
            surfaceContainer: '#EFDEE0',
            surfaceContainerHigh: '#E1D0D2',
            surfaceContainerHighest: '#D3C3C5',
        },
        dark: {
            primary: '#FFB1C2',
            surfaceTint: '#FFB1C2',
            onPrimary: '#551D2E',
            primaryContainer: '#713344',
            onPrimaryContainer: '#FFD9E0',
            secondary: '#E4BDC4',
            onSecondary: '#43292F',
            secondaryContainer: '#5B3F45',
            onSecondaryContainer: '#FFD9E0',
            tertiary: '#EDBE91',
            onTertiary: '#462A09',
            tertiaryContainer: '#60401E',
            onTertiaryContainer: '#FFDCBE',
            error: '#FFB4AB',
            onError: '#690005',
            errorContainer: '#93000A',
            onErrorContainer: '#FFDAD6',
            background: '#191113',
            onBackground: '#EFDEE0',
            surface: '#191113',
            onSurface: '#EFDEE0',
            surfaceVariant: '#514346',
            onSurfaceVariant: '#D6C2C4',
            outline: '#9E8C8F',
            outlineVariant: '#514346',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#EFDEE0',
            inverseOnSurface: '#382E30',
            inversePrimary: '#8D4A5B',
            primaryFixed: '#FFD9E0',
            onPrimaryFixed: '#3A0719',
            primaryFixedDim: '#FFB1C2',
            onPrimaryFixedVariant: '#713344',
            secondaryFixed: '#FFD9E0',
            onSecondaryFixed: '#2B151A',
            secondaryFixedDim: '#E4BDC4',
            onSecondaryFixedVariant: '#5B3F45',
            tertiaryFixed: '#FFDCBE',
            onTertiaryFixed: '#2C1600',
            tertiaryFixedDim: '#EDBE91',
            onTertiaryFixedVariant: '#60401E',
            surfaceDim: '#191113',
            surfaceBright: '#413738',
            surfaceContainerLowest: '#140C0E',
            surfaceContainerLow: '#22191B',
            surfaceContainer: '#261D1F',
            surfaceContainerHigh: '#312829',
            surfaceContainerHighest: '#3C3234',
        },
        'dark-medium-contrast': {
            primary: '#FFD1D9',
            surfaceTint: '#FFB1C2',
            onPrimary: '#471223',
            primaryContainer: '#C77A8D',
            onPrimaryContainer: '#000000',
            secondary: '#FBD2D9',
            onSecondary: '#371F24',
            secondaryContainer: '#AB888E',
            onSecondaryContainer: '#000000',
            tertiary: '#FFD5AE',
            onTertiary: '#3A1F02',
            tertiaryContainer: '#B28960',
            onTertiaryContainer: '#000000',
            error: '#FFD2CC',
            onError: '#540003',
            errorContainer: '#FF5449',
            onErrorContainer: '#000000',
            background: '#191113',
            onBackground: '#EFDEE0',
            surface: '#191113',
            onSurface: '#FFFFFF',
            surfaceVariant: '#514346',
            onSurfaceVariant: '#ECD7DA',
            outline: '#C1ADB0',
            outlineVariant: '#9E8C8F',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#EFDEE0',
            inverseOnSurface: '#312829',
            inversePrimary: '#723445',
            primaryFixed: '#FFD9E0',
            onPrimaryFixed: '#2C000F',
            primaryFixedDim: '#FFB1C2',
            onPrimaryFixedVariant: '#5C2233',
            secondaryFixed: '#FFD9E0',
            onSecondaryFixed: '#1F0B10',
            secondaryFixedDim: '#E4BDC4',
            onSecondaryFixedVariant: '#492F35',
            tertiaryFixed: '#FFDCBE',
            onTertiaryFixed: '#1E0D00',
            tertiaryFixedDim: '#EDBE91',
            onTertiaryFixedVariant: '#4D300F',
            surfaceDim: '#191113',
            surfaceBright: '#4D4244',
            surfaceContainerLowest: '#0C0607',
            surfaceContainerLow: '#241B1D',
            surfaceContainer: '#2F2527',
            surfaceContainerHigh: '#3A3032',
            surfaceContainerHighest: '#463B3D',
        },
        'dark-high-contrast': {
            primary: '#FFEBEE',
            surfaceTint: '#FFB1C2',
            onPrimary: '#000000',
            primaryContainer: '#FFABBE',
            onPrimaryContainer: '#210009',
            secondary: '#FFEBEE',
            onSecondary: '#000000',
            secondaryContainer: '#E0B9C0',
            onSecondaryContainer: '#18060A',
            tertiary: '#FFEDDF',
            onTertiary: '#000000',
            tertiaryContainer: '#E8BA8E',
            onTertiaryContainer: '#150800',
            error: '#FFECE9',
            onError: '#000000',
            errorContainer: '#FFAEA4',
            onErrorContainer: '#220001',
            background: '#191113',
            onBackground: '#EFDEE0',
            surface: '#191113',
            onSurface: '#FFFFFF',
            surfaceVariant: '#514346',
            onSurfaceVariant: '#FFFFFF',
            outline: '#FFEBEE',
            outlineVariant: '#D2BEC1',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#EFDEE0',
            inverseOnSurface: '#000000',
            inversePrimary: '#723445',
            primaryFixed: '#FFD9E0',
            onPrimaryFixed: '#000000',
            primaryFixedDim: '#FFB1C2',
            onPrimaryFixedVariant: '#2C000F',
            secondaryFixed: '#FFD9E0',
            onSecondaryFixed: '#000000',
            secondaryFixedDim: '#E4BDC4',
            onSecondaryFixedVariant: '#1F0B10',
            tertiaryFixed: '#FFDCBE',
            onTertiaryFixed: '#000000',
            tertiaryFixedDim: '#EDBE91',
            onTertiaryFixedVariant: '#1E0D00',
            surfaceDim: '#191113',
            surfaceBright: '#594D4F',
            surfaceContainerLowest: '#000000',
            surfaceContainerLow: '#261D1F',
            surfaceContainer: '#382E30',
            surfaceContainerHigh: '#43393B',
            surfaceContainerHighest: '#4F4446',
        },
    },
    '#00A17A': {
        light: {
            primary: '#1A6B52',
            surfaceTint: '#1A6B52',
            onPrimary: '#FFFFFF',
            primaryContainer: '#A6F2D2',
            onPrimaryContainer: '#00513C',
            secondary: '#4C6359',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#CEE9DB',
            onSecondaryContainer: '#354C42',
            tertiary: '#3F6375',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#C2E8FD',
            onTertiaryContainer: '#264B5C',
            error: '#BA1A1A',
            onError: '#FFFFFF',
            errorContainer: '#FFDAD6',
            onErrorContainer: '#93000A',
            background: '#F5FBF6',
            onBackground: '#171D1A',
            surface: '#F5FBF6',
            onSurface: '#171D1A',
            surfaceVariant: '#DBE5DE',
            onSurfaceVariant: '#404944',
            outline: '#707974',
            outlineVariant: '#BFC9C2',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#2C322F',
            inverseOnSurface: '#ECF2ED',
            inversePrimary: '#8AD6B7',
            primaryFixed: '#A6F2D2',
            onPrimaryFixed: '#002116',
            primaryFixedDim: '#8AD6B7',
            onPrimaryFixedVariant: '#00513C',
            secondaryFixed: '#CEE9DB',
            onSecondaryFixed: '#082017',
            secondaryFixedDim: '#B3CCBF',
            onSecondaryFixedVariant: '#354C42',
            tertiaryFixed: '#C2E8FD',
            onTertiaryFixed: '#001F2B',
            tertiaryFixedDim: '#A7CCE0',
            onTertiaryFixedVariant: '#264B5C',
            surfaceDim: '#D5DBD6',
            surfaceBright: '#F5FBF6',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#EFF5F0',
            surfaceContainer: '#E9EFEA',
            surfaceContainerHigh: '#E4EAE4',
            surfaceContainerHighest: '#DEE4DF',
        },
        'light-medium-contrast': {
            primary: '#003F2D',
            surfaceTint: '#1A6B52',
            onPrimary: '#FFFFFF',
            primaryContainer: '#2D7A60',
            onPrimaryContainer: '#FFFFFF',
            secondary: '#243B31',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#5A7267',
            onSecondaryContainer: '#FFFFFF',
            tertiary: '#123A4B',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#4E7284',
            onTertiaryContainer: '#FFFFFF',
            error: '#740006',
            onError: '#FFFFFF',
            errorContainer: '#CF2C27',
            onErrorContainer: '#FFFFFF',
            background: '#F5FBF6',
            onBackground: '#171D1A',
            surface: '#F5FBF6',
            onSurface: '#0D1210',
            surfaceVariant: '#DBE5DE',
            onSurfaceVariant: '#2F3834',
            outline: '#4B5550',
            outlineVariant: '#666F6A',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#2C322F',
            inverseOnSurface: '#ECF2ED',
            inversePrimary: '#8AD6B7',
            primaryFixed: '#2D7A60',
            onPrimaryFixed: '#FFFFFF',
            primaryFixedDim: '#076148',
            onPrimaryFixedVariant: '#FFFFFF',
            secondaryFixed: '#5A7267',
            onSecondaryFixed: '#FFFFFF',
            secondaryFixedDim: '#425A4F',
            onSecondaryFixedVariant: '#FFFFFF',
            tertiaryFixed: '#4E7284',
            onTertiaryFixed: '#FFFFFF',
            tertiaryFixedDim: '#35596B',
            onTertiaryFixedVariant: '#FFFFFF',
            surfaceDim: '#C2C8C3',
            surfaceBright: '#F5FBF6',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#EFF5F0',
            surfaceContainer: '#E4EAE4',
            surfaceContainerHigh: '#D8DED9',
            surfaceContainerHighest: '#CDD3CE',
        },
        'light-high-contrast': {
            primary: '#003325',
            surfaceTint: '#1A6B52',
            onPrimary: '#FFFFFF',
            primaryContainer: '#00543E',
            onPrimaryContainer: '#FFFFFF',
            secondary: '#1A3028',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#374E44',
            onSecondaryContainer: '#FFFFFF',
            tertiary: '#043040',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#284E5F',
            onTertiaryContainer: '#FFFFFF',
            error: '#600004',
            onError: '#FFFFFF',
            errorContainer: '#98000A',
            onErrorContainer: '#FFFFFF',
            background: '#F5FBF6',
            onBackground: '#171D1A',
            surface: '#F5FBF6',
            onSurface: '#000000',
            surfaceVariant: '#DBE5DE',
            onSurfaceVariant: '#000000',
            outline: '#252E2A',
            outlineVariant: '#424B46',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#2C322F',
            inverseOnSurface: '#FFFFFF',
            inversePrimary: '#8AD6B7',
            primaryFixed: '#00543E',
            onPrimaryFixed: '#FFFFFF',
            primaryFixedDim: '#003B2A',
            onPrimaryFixedVariant: '#FFFFFF',
            secondaryFixed: '#374E44',
            onSecondaryFixed: '#FFFFFF',
            secondaryFixedDim: '#21372E',
            onSecondaryFixedVariant: '#FFFFFF',
            tertiaryFixed: '#284E5F',
            onTertiaryFixed: '#FFFFFF',
            tertiaryFixedDim: '#0D3747',
            onTertiaryFixedVariant: '#FFFFFF',
            surfaceDim: '#B4BAB5',
            surfaceBright: '#F5FBF6',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#ECF2ED',
            surfaceContainer: '#DEE4DF',
            surfaceContainerHigh: '#D0D6D1',
            surfaceContainerHighest: '#C2C8C3',
        },
        dark: {
            primary: '#8AD6B7',
            surfaceTint: '#8AD6B7',
            onPrimary: '#003828',
            primaryContainer: '#00513C',
            onPrimaryContainer: '#A6F2D2',
            secondary: '#B3CCBF',
            onSecondary: '#1E352C',
            secondaryContainer: '#354C42',
            onSecondaryContainer: '#CEE9DB',
            tertiary: '#A7CCE0',
            onTertiary: '#0A3445',
            tertiaryContainer: '#264B5C',
            onTertiaryContainer: '#C2E8FD',
            error: '#FFB4AB',
            onError: '#690005',
            errorContainer: '#93000A',
            onErrorContainer: '#FFDAD6',
            background: '#0F1512',
            onBackground: '#DEE4DF',
            surface: '#0F1512',
            onSurface: '#DEE4DF',
            surfaceVariant: '#404944',
            onSurfaceVariant: '#BFC9C2',
            outline: '#89938D',
            outlineVariant: '#404944',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#DEE4DF',
            inverseOnSurface: '#2C322F',
            inversePrimary: '#1A6B52',
            primaryFixed: '#A6F2D2',
            onPrimaryFixed: '#002116',
            primaryFixedDim: '#8AD6B7',
            onPrimaryFixedVariant: '#00513C',
            secondaryFixed: '#CEE9DB',
            onSecondaryFixed: '#082017',
            secondaryFixedDim: '#B3CCBF',
            onSecondaryFixedVariant: '#354C42',
            tertiaryFixed: '#C2E8FD',
            onTertiaryFixed: '#001F2B',
            tertiaryFixedDim: '#A7CCE0',
            onTertiaryFixedVariant: '#264B5C',
            surfaceDim: '#0F1512',
            surfaceBright: '#343B37',
            surfaceContainerLowest: '#0A0F0D',
            surfaceContainerLow: '#171D1A',
            surfaceContainer: '#1B211E',
            surfaceContainerHigh: '#252B28',
            surfaceContainerHighest: '#303633',
        },
        'dark-medium-contrast': {
            primary: '#A0ECCC',
            surfaceTint: '#8AD6B7',
            onPrimary: '#002C1F',
            primaryContainer: '#549E83',
            onPrimaryContainer: '#000000',
            secondary: '#C8E2D5',
            onSecondary: '#132A21',
            secondaryContainer: '#7E968A',
            onSecondaryContainer: '#000000',
            tertiary: '#BCE2F7',
            onTertiary: '#002938',
            tertiaryContainer: '#7196A9',
            onTertiaryContainer: '#000000',
            error: '#FFD2CC',
            onError: '#540003',
            errorContainer: '#FF5449',
            onErrorContainer: '#000000',
            background: '#0F1512',
            onBackground: '#DEE4DF',
            surface: '#0F1512',
            onSurface: '#FFFFFF',
            surfaceVariant: '#404944',
            onSurfaceVariant: '#D5DFD8',
            outline: '#AAB4AE',
            outlineVariant: '#89938D',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#DEE4DF',
            inverseOnSurface: '#252B28',
            inversePrimary: '#00523D',
            primaryFixed: '#A6F2D2',
            onPrimaryFixed: '#00150D',
            primaryFixedDim: '#8AD6B7',
            onPrimaryFixedVariant: '#003F2D',
            secondaryFixed: '#CEE9DB',
            onSecondaryFixed: '#00150D',
            secondaryFixedDim: '#B3CCBF',
            onSecondaryFixedVariant: '#243B31',
            tertiaryFixed: '#C2E8FD',
            onTertiaryFixed: '#00131C',
            tertiaryFixedDim: '#A7CCE0',
            onTertiaryFixedVariant: '#123A4B',
            surfaceDim: '#0F1512',
            surfaceBright: '#404642',
            surfaceContainerLowest: '#040806',
            surfaceContainerLow: '#191F1C',
            surfaceContainer: '#232926',
            surfaceContainerHigh: '#2E3431',
            surfaceContainerHighest: '#393F3C',
        },
        'dark-high-contrast': {
            primary: '#B7FFE0',
            surfaceTint: '#8AD6B7',
            onPrimary: '#000000',
            primaryContainer: '#86D2B3',
            onPrimaryContainer: '#000E08',
            secondary: '#DCF6E8',
            onSecondary: '#000000',
            secondaryContainer: '#AFC8BB',
            onSecondaryContainer: '#000E08',
            tertiary: '#DFF3FF',
            onTertiary: '#000000',
            tertiaryContainer: '#A3C8DC',
            onTertiaryContainer: '#000D14',
            error: '#FFECE9',
            onError: '#000000',
            errorContainer: '#FFAEA4',
            onErrorContainer: '#220001',
            background: '#0F1512',
            onBackground: '#DEE4DF',
            surface: '#0F1512',
            onSurface: '#FFFFFF',
            surfaceVariant: '#404944',
            onSurfaceVariant: '#FFFFFF',
            outline: '#E9F2EB',
            outlineVariant: '#BBC5BF',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#DEE4DF',
            inverseOnSurface: '#000000',
            inversePrimary: '#00523D',
            primaryFixed: '#A6F2D2',
            onPrimaryFixed: '#000000',
            primaryFixedDim: '#8AD6B7',
            onPrimaryFixedVariant: '#00150D',
            secondaryFixed: '#CEE9DB',
            onSecondaryFixed: '#000000',
            secondaryFixedDim: '#B3CCBF',
            onSecondaryFixedVariant: '#00150D',
            tertiaryFixed: '#C2E8FD',
            onTertiaryFixed: '#000000',
            tertiaryFixedDim: '#A7CCE0',
            onTertiaryFixedVariant: '#00131C',
            surfaceDim: '#0F1512',
            surfaceBright: '#4B514E',
            surfaceContainerLowest: '#000000',
            surfaceContainerLow: '#1B211E',
            surfaceContainer: '#2C322F',
            surfaceContainerHigh: '#373D39',
            surfaceContainerHighest: '#424845',
        },
    },
    '#00B7C3': {
        light: {
            primary: '#006970',
            surfaceTint: '#006970',
            onPrimary: '#FFFFFF',
            primaryContainer: '#9DF0F8',
            onPrimaryContainer: '#004F55',
            secondary: '#4A6365',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#CCE8EA',
            onSecondaryContainer: '#324B4D',
            tertiary: '#505E7D',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#D7E2FF',
            onTertiaryContainer: '#384764',
            error: '#BA1A1A',
            onError: '#FFFFFF',
            errorContainer: '#FFDAD6',
            onErrorContainer: '#93000A',
            background: '#F4FAFB',
            onBackground: '#161D1D',
            surface: '#F4FAFB',
            onSurface: '#161D1D',
            surfaceVariant: '#DAE4E5',
            onSurfaceVariant: '#3F4849',
            outline: '#6F797A',
            outlineVariant: '#BEC8C9',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#2B3232',
            inverseOnSurface: '#ECF2F2',
            inversePrimary: '#80D4DC',
            primaryFixed: '#9DF0F8',
            onPrimaryFixed: '#002022',
            primaryFixedDim: '#80D4DC',
            onPrimaryFixedVariant: '#004F55',
            secondaryFixed: '#CCE8EA',
            onSecondaryFixed: '#051F21',
            secondaryFixedDim: '#B1CBCE',
            onSecondaryFixedVariant: '#324B4D',
            tertiaryFixed: '#D7E2FF',
            onTertiaryFixed: '#0A1B36',
            tertiaryFixedDim: '#B7C7EA',
            onTertiaryFixedVariant: '#384764',
            surfaceDim: '#D5DBDC',
            surfaceBright: '#F4FAFB',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#EFF5F5',
            surfaceContainer: '#E9EFEF',
            surfaceContainerHigh: '#E3E9EA',
            surfaceContainerHighest: '#DEE4E4',
        },
        'light-medium-contrast': {
            primary: '#003D41',
            surfaceTint: '#006970',
            onPrimary: '#FFFFFF',
            primaryContainer: '#167880',
            onPrimaryContainer: '#FFFFFF',
            secondary: '#213A3D',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#587174',
            onSecondaryContainer: '#FFFFFF',
            tertiary: '#273652',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#5E6D8C',
            onTertiaryContainer: '#FFFFFF',
            error: '#740006',
            onError: '#FFFFFF',
            errorContainer: '#CF2C27',
            onErrorContainer: '#FFFFFF',
            background: '#F4FAFB',
            onBackground: '#161D1D',
            surface: '#F4FAFB',
            onSurface: '#0C1213',
            surfaceVariant: '#DAE4E5',
            onSurfaceVariant: '#2E3839',
            outline: '#4B5455',
            outlineVariant: '#656F70',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#2B3232',
            inverseOnSurface: '#ECF2F2',
            inversePrimary: '#80D4DC',
            primaryFixed: '#167880',
            onPrimaryFixed: '#FFFFFF',
            primaryFixedDim: '#005E65',
            onPrimaryFixedVariant: '#FFFFFF',
            secondaryFixed: '#587174',
            onSecondaryFixed: '#FFFFFF',
            secondaryFixedDim: '#40595C',
            onSecondaryFixedVariant: '#FFFFFF',
            tertiaryFixed: '#5E6D8C',
            onTertiaryFixed: '#FFFFFF',
            tertiaryFixedDim: '#465573',
            onTertiaryFixedVariant: '#FFFFFF',
            surfaceDim: '#C1C8C8',
            surfaceBright: '#F4FAFB',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#EFF5F5',
            surfaceContainer: '#E3E9EA',
            surfaceContainerHigh: '#D8DEDE',
            surfaceContainerHighest: '#CDD3D3',
        },
        'light-high-contrast': {
            primary: '#003236',
            surfaceTint: '#006970',
            onPrimary: '#FFFFFF',
            primaryContainer: '#005257',
            onPrimaryContainer: '#FFFFFF',
            secondary: '#173032',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#354D50',
            onSecondaryContainer: '#FFFFFF',
            tertiary: '#1D2C48',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#3A4967',
            onTertiaryContainer: '#FFFFFF',
            error: '#600004',
            onError: '#FFFFFF',
            errorContainer: '#98000A',
            onErrorContainer: '#FFFFFF',
            background: '#F4FAFB',
            onBackground: '#161D1D',
            surface: '#F4FAFB',
            onSurface: '#000000',
            surfaceVariant: '#DAE4E5',
            onSurfaceVariant: '#000000',
            outline: '#242E2F',
            outlineVariant: '#414B4C',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#2B3232',
            inverseOnSurface: '#FFFFFF',
            inversePrimary: '#80D4DC',
            primaryFixed: '#005257',
            onPrimaryFixed: '#FFFFFF',
            primaryFixedDim: '#00393D',
            onPrimaryFixedVariant: '#FFFFFF',
            secondaryFixed: '#354D50',
            onSecondaryFixed: '#FFFFFF',
            secondaryFixedDim: '#1E3739',
            onSecondaryFixedVariant: '#FFFFFF',
            tertiaryFixed: '#3A4967',
            onTertiaryFixed: '#FFFFFF',
            tertiaryFixedDim: '#23334F',
            onTertiaryFixedVariant: '#FFFFFF',
            surfaceDim: '#B4BABA',
            surfaceBright: '#F4FAFB',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#ECF2F2',
            surfaceContainer: '#DEE4E4',
            surfaceContainerHigh: '#CFD5D6',
            surfaceContainerHighest: '#C1C8C8',
        },
        dark: {
            primary: '#80D4DC',
            surfaceTint: '#80D4DC',
            onPrimary: '#00363B',
            primaryContainer: '#004F55',
            onPrimaryContainer: '#9DF0F8',
            secondary: '#B1CBCE',
            onSecondary: '#1B3437',
            secondaryContainer: '#324B4D',
            onSecondaryContainer: '#CCE8EA',
            tertiary: '#B7C7EA',
            onTertiary: '#21304C',
            tertiaryContainer: '#384764',
            onTertiaryContainer: '#D7E2FF',
            error: '#FFB4AB',
            onError: '#690005',
            errorContainer: '#93000A',
            onErrorContainer: '#FFDAD6',
            background: '#0E1415',
            onBackground: '#DEE4E4',
            surface: '#0E1415',
            onSurface: '#DEE4E4',
            surfaceVariant: '#3F4849',
            onSurfaceVariant: '#BEC8C9',
            outline: '#899393',
            outlineVariant: '#3F4849',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#DEE4E4',
            inverseOnSurface: '#2B3232',
            inversePrimary: '#006970',
            primaryFixed: '#9DF0F8',
            onPrimaryFixed: '#002022',
            primaryFixedDim: '#80D4DC',
            onPrimaryFixedVariant: '#004F55',
            secondaryFixed: '#CCE8EA',
            onSecondaryFixed: '#051F21',
            secondaryFixedDim: '#B1CBCE',
            onSecondaryFixedVariant: '#324B4D',
            tertiaryFixed: '#D7E2FF',
            onTertiaryFixed: '#0A1B36',
            tertiaryFixedDim: '#B7C7EA',
            onTertiaryFixedVariant: '#384764',
            surfaceDim: '#0E1415',
            surfaceBright: '#343A3B',
            surfaceContainerLowest: '#090F10',
            surfaceContainerLow: '#161D1D',
            surfaceContainer: '#1A2121',
            surfaceContainerHigh: '#252B2C',
            surfaceContainerHighest: '#303637',
        },
        'dark-medium-contrast': {
            primary: '#97EAF2',
            surfaceTint: '#80D4DC',
            onPrimary: '#002B2E',
            primaryContainer: '#479DA5',
            onPrimaryContainer: '#000000',
            secondary: '#C6E1E4',
            onSecondary: '#10292C',
            secondaryContainer: '#7C9598',
            onSecondaryContainer: '#000000',
            tertiary: '#CEDCFF',
            onTertiary: '#162541',
            tertiaryContainer: '#8291B2',
            onTertiaryContainer: '#000000',
            error: '#FFD2CC',
            onError: '#540003',
            errorContainer: '#FF5449',
            onErrorContainer: '#000000',
            background: '#0E1415',
            onBackground: '#DEE4E4',
            surface: '#0E1415',
            onSurface: '#FFFFFF',
            surfaceVariant: '#3F4849',
            onSurfaceVariant: '#D4DEDF',
            outline: '#AAB4B5',
            outlineVariant: '#889293',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#DEE4E4',
            inverseOnSurface: '#252B2C',
            inversePrimary: '#005056',
            primaryFixed: '#9DF0F8',
            onPrimaryFixed: '#001416',
            primaryFixedDim: '#80D4DC',
            onPrimaryFixedVariant: '#003D41',
            secondaryFixed: '#CCE8EA',
            onSecondaryFixed: '#001416',
            secondaryFixedDim: '#B1CBCE',
            onSecondaryFixedVariant: '#213A3D',
            tertiaryFixed: '#D7E2FF',
            onTertiaryFixed: '#01102B',
            tertiaryFixedDim: '#B7C7EA',
            onTertiaryFixedVariant: '#273652',
            surfaceDim: '#0E1415',
            surfaceBright: '#3F4646',
            surfaceContainerLowest: '#040809',
            surfaceContainerLow: '#181F1F',
            surfaceContainer: '#23292A',
            surfaceContainerHigh: '#2D3434',
            surfaceContainerHighest: '#383F3F',
        },
        'dark-high-contrast': {
            primary: '#C4F9FF',
            surfaceTint: '#80D4DC',
            onPrimary: '#000000',
            primaryContainer: '#7CD0D8',
            onPrimaryContainer: '#000E0F',
            secondary: '#DAF5F8',
            onSecondary: '#000000',
            secondaryContainer: '#ADC8CA',
            onSecondaryContainer: '#000E0F',
            tertiary: '#EBF0FF',
            onTertiary: '#000000',
            tertiaryContainer: '#B3C3E6',
            onTertiaryContainer: '#000B21',
            error: '#FFECE9',
            onError: '#000000',
            errorContainer: '#FFAEA4',
            onErrorContainer: '#220001',
            background: '#0E1415',
            onBackground: '#DEE4E4',
            surface: '#0E1415',
            onSurface: '#FFFFFF',
            surfaceVariant: '#3F4849',
            onSurfaceVariant: '#FFFFFF',
            outline: '#E8F2F3',
            outlineVariant: '#BAC4C5',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#DEE4E4',
            inverseOnSurface: '#000000',
            inversePrimary: '#005056',
            primaryFixed: '#9DF0F8',
            onPrimaryFixed: '#000000',
            primaryFixedDim: '#80D4DC',
            onPrimaryFixedVariant: '#001416',
            secondaryFixed: '#CCE8EA',
            onSecondaryFixed: '#000000',
            secondaryFixedDim: '#B1CBCE',
            onSecondaryFixedVariant: '#001416',
            tertiaryFixed: '#D7E2FF',
            onTertiaryFixed: '#000000',
            tertiaryFixedDim: '#B7C7EA',
            onTertiaryFixedVariant: '#01102B',
            surfaceDim: '#0E1415',
            surfaceBright: '#4B5152',
            surfaceContainerLowest: '#000000',
            surfaceContainerLow: '#1A2121',
            surfaceContainer: '#2B3232',
            surfaceContainerHigh: '#363D3D',
            surfaceContainerHighest: '#424849',
        },
    },
    '#E78B1B': {
        light: {
            primary: '#865219',
            surfaceTint: '#865219',
            onPrimary: '#FFFFFF',
            primaryContainer: '#FFDCBF',
            onPrimaryContainer: '#6A3B01',
            secondary: '#735942',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#FFDCBF',
            onSecondaryContainer: '#59422D',
            tertiary: '#596339',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#DDE8B3',
            onTertiaryContainer: '#414B24',
            error: '#BA1A1A',
            onError: '#FFFFFF',
            errorContainer: '#FFDAD6',
            onErrorContainer: '#93000A',
            background: '#FFF8F5',
            onBackground: '#211A14',
            surface: '#FFF8F5',
            onSurface: '#211A14',
            surfaceVariant: '#F2DFD1',
            onSurfaceVariant: '#51443A',
            outline: '#837469',
            outlineVariant: '#D5C3B6',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#372F28',
            inverseOnSurface: '#FDEEE3',
            inversePrimary: '#FDB876',
            primaryFixed: '#FFDCBF',
            onPrimaryFixed: '#2D1600',
            primaryFixedDim: '#FDB876',
            onPrimaryFixedVariant: '#6A3B01',
            secondaryFixed: '#FFDCBF',
            onSecondaryFixed: '#291806',
            secondaryFixedDim: '#E2C0A4',
            onSecondaryFixedVariant: '#59422D',
            tertiaryFixed: '#DDE8B3',
            onTertiaryFixed: '#171E00',
            tertiaryFixedDim: '#C1CC99',
            onTertiaryFixedVariant: '#414B24',
            surfaceDim: '#E6D7CD',
            surfaceBright: '#FFF8F5',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#FFF1E8',
            surfaceContainer: '#FAEBE0',
            surfaceContainerHigh: '#F5E5DB',
            surfaceContainerHighest: '#EFE0D5',
        },
        'light-medium-contrast': {
            primary: '#532D00',
            surfaceTint: '#865219',
            onPrimary: '#FFFFFF',
            primaryContainer: '#976127',
            onPrimaryContainer: '#FFFFFF',
            secondary: '#47321E',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#836850',
            onSecondaryContainer: '#FFFFFF',
            tertiary: '#313A15',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#677146',
            onTertiaryContainer: '#FFFFFF',
            error: '#740006',
            onError: '#FFFFFF',
            errorContainer: '#CF2C27',
            onErrorContainer: '#FFFFFF',
            background: '#FFF8F5',
            onBackground: '#211A14',
            surface: '#FFF8F5',
            onSurface: '#16100A',
            surfaceVariant: '#F2DFD1',
            onSurfaceVariant: '#3F342A',
            outline: '#5D5045',
            outlineVariant: '#796A5F',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#372F28',
            inverseOnSurface: '#FDEEE3',
            inversePrimary: '#FDB876',
            primaryFixed: '#976127',
            onPrimaryFixed: '#FFFFFF',
            primaryFixedDim: '#7B4910',
            onPrimaryFixedVariant: '#FFFFFF',
            secondaryFixed: '#836850',
            onSecondaryFixed: '#FFFFFF',
            secondaryFixedDim: '#68503A',
            onSecondaryFixedVariant: '#FFFFFF',
            tertiaryFixed: '#677146',
            onTertiaryFixed: '#FFFFFF',
            tertiaryFixedDim: '#4F5930',
            onTertiaryFixedVariant: '#FFFFFF',
            surfaceDim: '#D2C4BA',
            surfaceBright: '#FFF8F5',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#FFF1E8',
            surfaceContainer: '#F5E5DB',
            surfaceContainerHigh: '#E9DAD0',
            surfaceContainerHighest: '#DDCFC5',
        },
        'light-high-contrast': {
            primary: '#442400',
            surfaceTint: '#865219',
            onPrimary: '#FFFFFF',
            primaryContainer: '#6D3E03',
            onPrimaryContainer: '#FFFFFF',
            secondary: '#3C2814',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#5C452F',
            onSecondaryContainer: '#FFFFFF',
            tertiary: '#272F0B',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#444D26',
            onTertiaryContainer: '#FFFFFF',
            error: '#600004',
            onError: '#FFFFFF',
            errorContainer: '#98000A',
            onErrorContainer: '#FFFFFF',
            background: '#FFF8F5',
            onBackground: '#211A14',
            surface: '#FFF8F5',
            onSurface: '#000000',
            surfaceVariant: '#F2DFD1',
            onSurfaceVariant: '#000000',
            outline: '#352A21',
            outlineVariant: '#53473C',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#372F28',
            inverseOnSurface: '#FFFFFF',
            inversePrimary: '#FDB876',
            primaryFixed: '#6D3E03',
            onPrimaryFixed: '#FFFFFF',
            primaryFixedDim: '#4E2A00',
            onPrimaryFixedVariant: '#FFFFFF',
            secondaryFixed: '#5C452F',
            onSecondaryFixed: '#FFFFFF',
            secondaryFixedDim: '#432E1A',
            onSecondaryFixedVariant: '#FFFFFF',
            tertiaryFixed: '#444D26',
            onTertiaryFixed: '#FFFFFF',
            tertiaryFixedDim: '#2D3611',
            onTertiaryFixedVariant: '#FFFFFF',
            surfaceDim: '#C4B6AC',
            surfaceBright: '#FFF8F5',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#FDEEE3',
            surfaceContainer: '#EFE0D5',
            surfaceContainerHigh: '#E0D2C7',
            surfaceContainerHighest: '#D2C4BA',
        },
        dark: {
            primary: '#FDB876',
            surfaceTint: '#FDB876',
            onPrimary: '#4A2800',
            primaryContainer: '#6A3B01',
            onPrimaryContainer: '#FFDCBF',
            secondary: '#E2C0A4',
            onSecondary: '#412C18',
            secondaryContainer: '#59422D',
            onSecondaryContainer: '#FFDCBF',
            tertiary: '#C1CC99',
            onTertiary: '#2B340F',
            tertiaryContainer: '#414B24',
            onTertiaryContainer: '#DDE8B3',
            error: '#FFB4AB',
            onError: '#690005',
            errorContainer: '#93000A',
            onErrorContainer: '#FFDAD6',
            background: '#19120C',
            onBackground: '#EFE0D5',
            surface: '#19120C',
            onSurface: '#EFE0D5',
            surfaceVariant: '#51443A',
            onSurfaceVariant: '#D5C3B6',
            outline: '#9E8E81',
            outlineVariant: '#51443A',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#EFE0D5',
            inverseOnSurface: '#372F28',
            inversePrimary: '#865219',
            primaryFixed: '#FFDCBF',
            onPrimaryFixed: '#2D1600',
            primaryFixedDim: '#FDB876',
            onPrimaryFixedVariant: '#6A3B01',
            secondaryFixed: '#FFDCBF',
            onSecondaryFixed: '#291806',
            secondaryFixedDim: '#E2C0A4',
            onSecondaryFixedVariant: '#59422D',
            tertiaryFixed: '#DDE8B3',
            onTertiaryFixed: '#171E00',
            tertiaryFixedDim: '#C1CC99',
            onTertiaryFixedVariant: '#414B24',
            surfaceDim: '#19120C',
            surfaceBright: '#403830',
            surfaceContainerLowest: '#130D07',
            surfaceContainerLow: '#211A14',
            surfaceContainer: '#261E18',
            surfaceContainerHigh: '#312822',
            surfaceContainerHighest: '#3C332C',
        },
        'dark-medium-contrast': {
            primary: '#FFD4AF',
            surfaceTint: '#FDB876',
            onPrimary: '#3B1F00',
            primaryContainer: '#C08446',
            onPrimaryContainer: '#000000',
            secondary: '#F9D6B9',
            onSecondary: '#35220F',
            secondaryContainer: '#A98B71',
            onSecondaryContainer: '#000000',
            tertiary: '#D7E2AE',
            onTertiary: '#212906',
            tertiaryContainer: '#8B9567',
            onTertiaryContainer: '#000000',
            error: '#FFD2CC',
            onError: '#540003',
            errorContainer: '#FF5449',
            onErrorContainer: '#000000',
            background: '#19120C',
            onBackground: '#EFE0D5',
            surface: '#19120C',
            onSurface: '#FFFFFF',
            surfaceVariant: '#51443A',
            onSurfaceVariant: '#ECD9CB',
            outline: '#C0AFA2',
            outlineVariant: '#9D8D81',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#EFE0D5',
            inverseOnSurface: '#312822',
            inversePrimary: '#6B3D02',
            primaryFixed: '#FFDCBF',
            onPrimaryFixed: '#1E0D00',
            primaryFixedDim: '#FDB876',
            onPrimaryFixedVariant: '#532D00',
            secondaryFixed: '#FFDCBF',
            onSecondaryFixed: '#1D0D01',
            secondaryFixedDim: '#E2C0A4',
            onSecondaryFixedVariant: '#47321E',
            tertiaryFixed: '#DDE8B3',
            onTertiaryFixed: '#0D1300',
            tertiaryFixedDim: '#C1CC99',
            onTertiaryFixedVariant: '#313A15',
            surfaceDim: '#19120C',
            surfaceBright: '#4C433B',
            surfaceContainerLowest: '#0C0603',
            surfaceContainerLow: '#241C16',
            surfaceContainer: '#2E2620',
            surfaceContainerHigh: '#3A312A',
            surfaceContainerHighest: '#453C35',
        },
        'dark-high-contrast': {
            primary: '#FFEDDF',
            surfaceTint: '#FDB876',
            onPrimary: '#000000',
            primaryContainer: '#F9B572',
            onPrimaryContainer: '#160800',
            secondary: '#FFEDDF',
            onSecondary: '#000000',
            secondaryContainer: '#DEBDA0',
            onSecondaryContainer: '#160800',
            tertiary: '#EAF6C0',
            onTertiary: '#000000',
            tertiaryContainer: '#BDC896',
            onTertiaryContainer: '#080D00',
            error: '#FFECE9',
            onError: '#000000',
            errorContainer: '#FFAEA4',
            onErrorContainer: '#220001',
            background: '#19120C',
            onBackground: '#EFE0D5',
            surface: '#19120C',
            onSurface: '#FFFFFF',
            surfaceVariant: '#51443A',
            onSurfaceVariant: '#FFFFFF',
            outline: '#FFEDDF',
            outlineVariant: '#D1BFB2',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#EFE0D5',
            inverseOnSurface: '#000000',
            inversePrimary: '#6B3D02',
            primaryFixed: '#FFDCBF',
            onPrimaryFixed: '#000000',
            primaryFixedDim: '#FDB876',
            onPrimaryFixedVariant: '#1E0D00',
            secondaryFixed: '#FFDCBF',
            onSecondaryFixed: '#000000',
            secondaryFixedDim: '#E2C0A4',
            onSecondaryFixedVariant: '#1D0D01',
            tertiaryFixed: '#DDE8B3',
            onTertiaryFixed: '#000000',
            tertiaryFixedDim: '#C1CC99',
            onTertiaryFixedVariant: '#0D1300',
            surfaceDim: '#19120C',
            surfaceBright: '#584E46',
            surfaceContainerLowest: '#000000',
            surfaceContainerLow: '#261E18',
            surfaceContainer: '#372F28',
            surfaceContainerHigh: '#433A32',
            surfaceContainerHighest: '#4E453D',
        },
    },
    '#7C72D3': {
        light: {
            primary: '#5D5791',
            surfaceTint: '#5D5791',
            onPrimary: '#FFFFFF',
            primaryContainer: '#E4DFFF',
            onPrimaryContainer: '#454077',
            secondary: '#5F5C71',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#E4DFF9',
            onSecondaryContainer: '#474459',
            tertiary: '#7B5266',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#FFD8E8',
            onTertiaryContainer: '#613B4E',
            error: '#BA1A1A',
            onError: '#FFFFFF',
            errorContainer: '#FFDAD6',
            onErrorContainer: '#93000A',
            background: '#FCF8FF',
            onBackground: '#1C1B20',
            surface: '#FCF8FF',
            onSurface: '#1C1B20',
            surfaceVariant: '#E5E1EC',
            onSurfaceVariant: '#47464F',
            outline: '#787680',
            outlineVariant: '#C9C5D0',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#313036',
            inverseOnSurface: '#F4EFF7',
            inversePrimary: '#C6BFFF',
            primaryFixed: '#E4DFFF',
            onPrimaryFixed: '#191249',
            primaryFixedDim: '#C6BFFF',
            onPrimaryFixedVariant: '#454077',
            secondaryFixed: '#E4DFF9',
            onSecondaryFixed: '#1B192C',
            secondaryFixedDim: '#C8C3DC',
            onSecondaryFixedVariant: '#474459',
            tertiaryFixed: '#FFD8E8',
            onTertiaryFixed: '#301122',
            tertiaryFixedDim: '#ECB8CF',
            onTertiaryFixedVariant: '#613B4E',
            surfaceDim: '#DDD8E0',
            surfaceBright: '#FCF8FF',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#F6F2FA',
            surfaceContainer: '#F1ECF4',
            surfaceContainerHigh: '#EBE6EF',
            surfaceContainerHighest: '#E5E1E9',
        },
        'light-medium-contrast': {
            primary: '#342F66',
            surfaceTint: '#5D5791',
            onPrimary: '#FFFFFF',
            primaryContainer: '#6C66A1',
            onPrimaryContainer: '#FFFFFF',
            secondary: '#363447',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#6E6A80',
            onSecondaryContainer: '#FFFFFF',
            tertiary: '#4E2B3D',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#8B6174',
            onTertiaryContainer: '#FFFFFF',
            error: '#740006',
            onError: '#FFFFFF',
            errorContainer: '#CF2C27',
            onErrorContainer: '#FFFFFF',
            background: '#FCF8FF',
            onBackground: '#1C1B20',
            surface: '#FCF8FF',
            onSurface: '#111016',
            surfaceVariant: '#E5E1EC',
            onSurfaceVariant: '#37353E',
            outline: '#53515A',
            outlineVariant: '#6E6C75',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#313036',
            inverseOnSurface: '#F4EFF7',
            inversePrimary: '#C6BFFF',
            primaryFixed: '#6C66A1',
            onPrimaryFixed: '#FFFFFF',
            primaryFixedDim: '#534E87',
            onPrimaryFixedVariant: '#FFFFFF',
            secondaryFixed: '#6E6A80',
            onSecondaryFixed: '#FFFFFF',
            secondaryFixedDim: '#555267',
            onSecondaryFixedVariant: '#FFFFFF',
            tertiaryFixed: '#8B6174',
            onTertiaryFixed: '#FFFFFF',
            tertiaryFixedDim: '#70495C',
            onTertiaryFixedVariant: '#FFFFFF',
            surfaceDim: '#C9C5CD',
            surfaceBright: '#FCF8FF',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#F6F2FA',
            surfaceContainer: '#EBE6EF',
            surfaceContainerHigh: '#DFDBE3',
            surfaceContainerHighest: '#D4D0D8',
        },
        'light-high-contrast': {
            primary: '#2A245B',
            surfaceTint: '#5D5791',
            onPrimary: '#FFFFFF',
            primaryContainer: '#48427A',
            onPrimaryContainer: '#FFFFFF',
            secondary: '#2C2A3D',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#49475B',
            onSecondaryContainer: '#FFFFFF',
            tertiary: '#432133',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#643E50',
            onTertiaryContainer: '#FFFFFF',
            error: '#600004',
            onError: '#FFFFFF',
            errorContainer: '#98000A',
            onErrorContainer: '#FFFFFF',
            background: '#FCF8FF',
            onBackground: '#1C1B20',
            surface: '#FCF8FF',
            onSurface: '#000000',
            surfaceVariant: '#E5E1EC',
            onSurfaceVariant: '#000000',
            outline: '#2C2B33',
            outlineVariant: '#4A4851',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#313036',
            inverseOnSurface: '#FFFFFF',
            inversePrimary: '#C6BFFF',
            primaryFixed: '#48427A',
            onPrimaryFixed: '#FFFFFF',
            primaryFixedDim: '#312B62',
            onPrimaryFixedVariant: '#FFFFFF',
            secondaryFixed: '#49475B',
            onSecondaryFixed: '#FFFFFF',
            secondaryFixedDim: '#333044',
            onSecondaryFixedVariant: '#FFFFFF',
            tertiaryFixed: '#643E50',
            onTertiaryFixed: '#FFFFFF',
            tertiaryFixedDim: '#4A2839',
            onTertiaryFixedVariant: '#FFFFFF',
            surfaceDim: '#BBB7BF',
            surfaceBright: '#FCF8FF',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#F4EFF7',
            surfaceContainer: '#E5E1E9',
            surfaceContainerHigh: '#D7D3DB',
            surfaceContainerHighest: '#C9C5CD',
        },
        dark: {
            primary: '#C6BFFF',
            surfaceTint: '#C6BFFF',
            onPrimary: '#2F295F',
            primaryContainer: '#454077',
            onPrimaryContainer: '#E4DFFF',
            secondary: '#C8C3DC',
            onSecondary: '#302E41',
            secondaryContainer: '#474459',
            onSecondaryContainer: '#E4DFF9',
            tertiary: '#ECB8CF',
            onTertiary: '#482537',
            tertiaryContainer: '#613B4E',
            onTertiaryContainer: '#FFD8E8',
            error: '#FFB4AB',
            onError: '#690005',
            errorContainer: '#93000A',
            onErrorContainer: '#FFDAD6',
            background: '#141318',
            onBackground: '#E5E1E9',
            surface: '#141318',
            onSurface: '#E5E1E9',
            surfaceVariant: '#47464F',
            onSurfaceVariant: '#C9C5D0',
            outline: '#928F99',
            outlineVariant: '#47464F',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#E5E1E9',
            inverseOnSurface: '#313036',
            inversePrimary: '#5D5791',
            primaryFixed: '#E4DFFF',
            onPrimaryFixed: '#191249',
            primaryFixedDim: '#C6BFFF',
            onPrimaryFixedVariant: '#454077',
            secondaryFixed: '#E4DFF9',
            onSecondaryFixed: '#1B192C',
            secondaryFixedDim: '#C8C3DC',
            onSecondaryFixedVariant: '#474459',
            tertiaryFixed: '#FFD8E8',
            onTertiaryFixed: '#301122',
            tertiaryFixedDim: '#ECB8CF',
            onTertiaryFixedVariant: '#613B4E',
            surfaceDim: '#141318',
            surfaceBright: '#3A383E',
            surfaceContainerLowest: '#0E0E13',
            surfaceContainerLow: '#1C1B20',
            surfaceContainer: '#201F25',
            surfaceContainerHigh: '#2A292F',
            surfaceContainerHighest: '#35343A',
        },
        'dark-medium-contrast': {
            primary: '#DED8FF',
            surfaceTint: '#C6BFFF',
            onPrimary: '#241D54',
            primaryContainer: '#908AC7',
            onPrimaryContainer: '#000000',
            secondary: '#DED9F3',
            onSecondary: '#252336',
            secondaryContainer: '#928EA5',
            onSecondaryContainer: '#000000',
            tertiary: '#FFCFE4',
            onTertiary: '#3B1B2C',
            tertiaryContainer: '#B28498',
            onTertiaryContainer: '#000000',
            error: '#FFD2CC',
            onError: '#540003',
            errorContainer: '#FF5449',
            onErrorContainer: '#000000',
            background: '#141318',
            onBackground: '#E5E1E9',
            surface: '#141318',
            onSurface: '#FFFFFF',
            surfaceVariant: '#47464F',
            onSurfaceVariant: '#DFDAE6',
            outline: '#B4B0BB',
            outlineVariant: '#928F99',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#E5E1E9',
            inverseOnSurface: '#2A292F',
            inversePrimary: '#464179',
            primaryFixed: '#E4DFFF',
            onPrimaryFixed: '#0E053F',
            primaryFixedDim: '#C6BFFF',
            onPrimaryFixedVariant: '#342F66',
            secondaryFixed: '#E4DFF9',
            onSecondaryFixed: '#110F21',
            secondaryFixedDim: '#C8C3DC',
            onSecondaryFixedVariant: '#363447',
            tertiaryFixed: '#FFD8E8',
            onTertiaryFixed: '#230717',
            tertiaryFixedDim: '#ECB8CF',
            onTertiaryFixedVariant: '#4E2B3D',
            surfaceDim: '#141318',
            surfaceBright: '#45444A',
            surfaceContainerLowest: '#07070C',
            surfaceContainerLow: '#1E1D23',
            surfaceContainer: '#28272D',
            surfaceContainerHigh: '#333238',
            surfaceContainerHighest: '#3E3D43',
        },
        'dark-high-contrast': {
            primary: '#F2EDFF',
            surfaceTint: '#C6BFFF',
            onPrimary: '#000000',
            primaryContainer: '#C2BBFC',
            onPrimaryContainer: '#080038',
            secondary: '#F2EDFF',
            onSecondary: '#000000',
            secondaryContainer: '#C4C0D8',
            onSecondaryContainer: '#0B091A',
            tertiary: '#FFEBF1',
            onTertiary: '#000000',
            tertiaryContainer: '#E7B5CB',
            onTertiaryContainer: '#1C0311',
            error: '#FFECE9',
            onError: '#000000',
            errorContainer: '#FFAEA4',
            onErrorContainer: '#220001',
            background: '#141318',
            onBackground: '#E5E1E9',
            surface: '#141318',
            onSurface: '#FFFFFF',
            surfaceVariant: '#47464F',
            onSurfaceVariant: '#FFFFFF',
            outline: '#F3EEF9',
            outlineVariant: '#C5C1CC',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#E5E1E9',
            inverseOnSurface: '#000000',
            inversePrimary: '#464179',
            primaryFixed: '#E4DFFF',
            onPrimaryFixed: '#000000',
            primaryFixedDim: '#C6BFFF',
            onPrimaryFixedVariant: '#0E053F',
            secondaryFixed: '#E4DFF9',
            onSecondaryFixed: '#000000',
            secondaryFixedDim: '#C8C3DC',
            onSecondaryFixedVariant: '#110F21',
            tertiaryFixed: '#FFD8E8',
            onTertiaryFixed: '#000000',
            tertiaryFixedDim: '#ECB8CF',
            onTertiaryFixedVariant: '#230717',
            surfaceDim: '#141318',
            surfaceBright: '#514F56',
            surfaceContainerLowest: '#000000',
            surfaceContainerLow: '#201F25',
            surfaceContainer: '#313036',
            surfaceContainerHigh: '#3C3A41',
            surfaceContainerHighest: '#47464C',
        },
    },
    '#A15BD2': {
        light: {
            primary: '#725188',
            surfaceTint: '#725188',
            onPrimary: '#FFFFFF',
            primaryContainer: '#F3DAFF',
            onPrimaryContainer: '#593A6E',
            secondary: '#675A6E',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#EFDCF5',
            onSecondaryContainer: '#4F4256',
            tertiary: '#815154',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#FFDADA',
            onTertiaryContainer: '#663B3D',
            error: '#BA1A1A',
            onError: '#FFFFFF',
            errorContainer: '#FFDAD6',
            onErrorContainer: '#93000A',
            background: '#FFF7FD',
            onBackground: '#1E1A20',
            surface: '#FFF7FD',
            onSurface: '#1E1A20',
            surfaceVariant: '#EADFEA',
            onSurfaceVariant: '#4B454D',
            outline: '#7C747E',
            outlineVariant: '#CDC3CE',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#332F35',
            inverseOnSurface: '#F7EEF6',
            inversePrimary: '#DFB8F6',
            primaryFixed: '#F3DAFF',
            onPrimaryFixed: '#2B0C40',
            primaryFixedDim: '#DFB8F6',
            onPrimaryFixedVariant: '#593A6E',
            secondaryFixed: '#EFDCF5',
            onSecondaryFixed: '#221729',
            secondaryFixedDim: '#D2C1D9',
            onSecondaryFixedVariant: '#4F4256',
            tertiaryFixed: '#FFDADA',
            onTertiaryFixed: '#331014',
            tertiaryFixedDim: '#F4B7BA',
            onTertiaryFixedVariant: '#663B3D',
            surfaceDim: '#E0D7DF',
            surfaceBright: '#FFF7FD',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#FAF1F9',
            surfaceContainer: '#F4EBF3',
            surfaceContainerHigh: '#EEE6ED',
            surfaceContainerHighest: '#E9E0E8',
        },
        'light-medium-contrast': {
            primary: '#47295C',
            surfaceTint: '#725188',
            onPrimary: '#FFFFFF',
            primaryContainer: '#816097',
            onPrimaryContainer: '#FFFFFF',
            secondary: '#3E3244',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#76687D',
            onSecondaryContainer: '#FFFFFF',
            tertiary: '#532A2D',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#916062',
            onTertiaryContainer: '#FFFFFF',
            error: '#740006',
            onError: '#FFFFFF',
            errorContainer: '#CF2C27',
            onErrorContainer: '#FFFFFF',
            background: '#FFF7FD',
            onBackground: '#1E1A20',
            surface: '#FFF7FD',
            onSurface: '#131015',
            surfaceVariant: '#EADFEA',
            onSurfaceVariant: '#3A343C',
            outline: '#575059',
            outlineVariant: '#726B74',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#332F35',
            inverseOnSurface: '#F7EEF6',
            inversePrimary: '#DFB8F6',
            primaryFixed: '#816097',
            onPrimaryFixed: '#FFFFFF',
            primaryFixedDim: '#68487D',
            onPrimaryFixedVariant: '#FFFFFF',
            secondaryFixed: '#76687D',
            onSecondaryFixed: '#FFFFFF',
            secondaryFixedDim: '#5D5064',
            onSecondaryFixedVariant: '#FFFFFF',
            tertiaryFixed: '#916062',
            onTertiaryFixed: '#FFFFFF',
            tertiaryFixedDim: '#76484B',
            onTertiaryFixedVariant: '#FFFFFF',
            surfaceDim: '#CCC4CC',
            surfaceBright: '#FFF7FD',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#FAF1F9',
            surfaceContainer: '#EEE6ED',
            surfaceContainerHigh: '#E3DAE2',
            surfaceContainerHighest: '#D7CFD7',
        },
        'light-high-contrast': {
            primary: '#3D1E52',
            surfaceTint: '#725188',
            onPrimary: '#FFFFFF',
            primaryContainer: '#5B3C71',
            onPrimaryContainer: '#FFFFFF',
            secondary: '#33283A',
            onSecondary: '#FFFFFF',
            secondaryContainer: '#514558',
            onSecondaryContainer: '#FFFFFF',
            tertiary: '#472124',
            onTertiary: '#FFFFFF',
            tertiaryContainer: '#693D40',
            onTertiaryContainer: '#FFFFFF',
            error: '#600004',
            onError: '#FFFFFF',
            errorContainer: '#98000A',
            onErrorContainer: '#FFFFFF',
            background: '#FFF7FD',
            onBackground: '#1E1A20',
            surface: '#FFF7FD',
            onSurface: '#000000',
            surfaceVariant: '#EADFEA',
            onSurfaceVariant: '#000000',
            outline: '#302A32',
            outlineVariant: '#4D4750',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#332F35',
            inverseOnSurface: '#FFFFFF',
            inversePrimary: '#DFB8F6',
            primaryFixed: '#5B3C71',
            onPrimaryFixed: '#FFFFFF',
            primaryFixedDim: '#442559',
            onPrimaryFixedVariant: '#FFFFFF',
            secondaryFixed: '#514558',
            onSecondaryFixed: '#FFFFFF',
            secondaryFixedDim: '#3A2E41',
            onSecondaryFixedVariant: '#FFFFFF',
            tertiaryFixed: '#693D40',
            onTertiaryFixed: '#FFFFFF',
            tertiaryFixedDim: '#4E272A',
            onTertiaryFixedVariant: '#FFFFFF',
            surfaceDim: '#BEB6BE',
            surfaceBright: '#FFF7FD',
            surfaceContainerLowest: '#FFFFFF',
            surfaceContainerLow: '#F7EEF6',
            surfaceContainer: '#E9E0E8',
            surfaceContainerHigh: '#DAD2D9',
            surfaceContainerHighest: '#CCC4CC',
        },
        dark: {
            primary: '#DFB8F6',
            surfaceTint: '#DFB8F6',
            onPrimary: '#412356',
            primaryContainer: '#593A6E',
            onPrimaryContainer: '#F3DAFF',
            secondary: '#D2C1D9',
            onSecondary: '#382C3E',
            secondaryContainer: '#4F4256',
            onSecondaryContainer: '#EFDCF5',
            tertiary: '#F4B7BA',
            onTertiary: '#4C2528',
            tertiaryContainer: '#663B3D',
            onTertiaryContainer: '#FFDADA',
            error: '#FFB4AB',
            onError: '#690005',
            errorContainer: '#93000A',
            onErrorContainer: '#FFDAD6',
            background: '#161217',
            onBackground: '#E9E0E8',
            surface: '#161217',
            onSurface: '#E9E0E8',
            surfaceVariant: '#4B454D',
            onSurfaceVariant: '#CDC3CE',
            outline: '#968E98',
            outlineVariant: '#4B454D',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#E9E0E8',
            inverseOnSurface: '#332F35',
            inversePrimary: '#725188',
            primaryFixed: '#F3DAFF',
            onPrimaryFixed: '#2B0C40',
            primaryFixedDim: '#DFB8F6',
            onPrimaryFixedVariant: '#593A6E',
            secondaryFixed: '#EFDCF5',
            onSecondaryFixed: '#221729',
            secondaryFixedDim: '#D2C1D9',
            onSecondaryFixedVariant: '#4F4256',
            tertiaryFixed: '#FFDADA',
            onTertiaryFixed: '#331014',
            tertiaryFixedDim: '#F4B7BA',
            onTertiaryFixedVariant: '#663B3D',
            surfaceDim: '#161217',
            surfaceBright: '#3C383E',
            surfaceContainerLowest: '#100D12',
            surfaceContainerLow: '#1E1A20',
            surfaceContainer: '#221E24',
            surfaceContainerHigh: '#2D292E',
            surfaceContainerHighest: '#383339',
        },
        'dark-medium-contrast': {
            primary: '#F0D2FF',
            surfaceTint: '#DFB8F6',
            onPrimary: '#36174B',
            primaryContainer: '#A783BD',
            onPrimaryContainer: '#000000',
            secondary: '#E8D6EF',
            onSecondary: '#2C2133',
            secondaryContainer: '#9B8BA2',
            onSecondaryContainer: '#000000',
            tertiary: '#FFD1D3',
            onTertiary: '#3F1A1D',
            tertiaryContainer: '#B98385',
            onTertiaryContainer: '#000000',
            error: '#FFD2CC',
            onError: '#540003',
            errorContainer: '#FF5449',
            onErrorContainer: '#000000',
            background: '#161217',
            onBackground: '#E9E0E8',
            surface: '#161217',
            onSurface: '#FFFFFF',
            surfaceVariant: '#4B454D',
            onSurfaceVariant: '#E4D9E4',
            outline: '#B8AFB9',
            outlineVariant: '#968E97',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#E9E0E8',
            inverseOnSurface: '#2D292E',
            inversePrimary: '#5A3B70',
            primaryFixed: '#F3DAFF',
            onPrimaryFixed: '#1F0135',
            primaryFixedDim: '#DFB8F6',
            onPrimaryFixedVariant: '#47295C',
            secondaryFixed: '#EFDCF5',
            onSecondaryFixed: '#170D1E',
            secondaryFixedDim: '#D2C1D9',
            onSecondaryFixedVariant: '#3E3244',
            tertiaryFixed: '#FFDADA',
            onTertiaryFixed: '#25060A',
            tertiaryFixedDim: '#F4B7BA',
            onTertiaryFixedVariant: '#532A2D',
            surfaceDim: '#161217',
            surfaceBright: '#484349',
            surfaceContainerLowest: '#09060B',
            surfaceContainerLow: '#201C22',
            surfaceContainer: '#2B262C',
            surfaceContainerHigh: '#363137',
            surfaceContainerHighest: '#413C42',
        },
        'dark-high-contrast': {
            primary: '#FBEBFF',
            surfaceTint: '#DFB8F6',
            onPrimary: '#000000',
            primaryContainer: '#DBB4F2',
            onPrimaryContainer: '#170029',
            secondary: '#FBEBFF',
            onSecondary: '#000000',
            secondaryContainer: '#CEBDD5',
            onSecondaryContainer: '#110718',
            tertiary: '#FFECEB',
            onTertiary: '#000000',
            tertiaryContainer: '#F0B3B6',
            onTertiaryContainer: '#1E0305',
            error: '#FFECE9',
            onError: '#000000',
            errorContainer: '#FFAEA4',
            onErrorContainer: '#220001',
            background: '#161217',
            onBackground: '#E9E0E8',
            surface: '#161217',
            onSurface: '#FFFFFF',
            surfaceVariant: '#4B454D',
            onSurfaceVariant: '#FFFFFF',
            outline: '#F8EDF8',
            outlineVariant: '#C9C0CA',
            shadow: '#000000',
            scrim: '#000000',
            inverseSurface: '#E9E0E8',
            inverseOnSurface: '#000000',
            inversePrimary: '#5A3B70',
            primaryFixed: '#F3DAFF',
            onPrimaryFixed: '#000000',
            primaryFixedDim: '#DFB8F6',
            onPrimaryFixedVariant: '#1F0135',
            secondaryFixed: '#EFDCF5',
            onSecondaryFixed: '#000000',
            secondaryFixedDim: '#D2C1D9',
            onSecondaryFixedVariant: '#170D1E',
            tertiaryFixed: '#FFDADA',
            onTertiaryFixed: '#000000',
            tertiaryFixedDim: '#F4B7BA',
            onTertiaryFixedVariant: '#25060A',
            surfaceDim: '#161217',
            surfaceBright: '#544E55',
            surfaceContainerLowest: '#000000',
            surfaceContainerLow: '#221E24',
            surfaceContainer: '#332F35',
            surfaceContainerHigh: '#3F3A40',
            surfaceContainerHighest: '#4A454B',
        },
    },
} as const

export interface MaterialDesignTheme {
    primary: string
    surfaceTint: string
    onPrimary: string
    primaryContainer: string
    onPrimaryContainer: string
    secondary: string
    onSecondary: string
    secondaryContainer: string
    onSecondaryContainer: string
    tertiary: string
    onTertiary: string
    tertiaryContainer: string
    onTertiaryContainer: string
    error: string
    onError: string
    errorContainer: string
    onErrorContainer: string
    background: string
    onBackground: string
    surface: string
    onSurface: string
    surfaceVariant: string
    onSurfaceVariant: string
    outline: string
    outlineVariant: string
    shadow: string
    scrim: string
    inverseSurface: string
    inverseOnSurface: string
    inversePrimary: string
    primaryFixed: string
    onPrimaryFixed: string
    primaryFixedDim: string
    onPrimaryFixedVariant: string
    secondaryFixed: string
    onSecondaryFixed: string
    secondaryFixedDim: string
    onSecondaryFixedVariant: string
    tertiaryFixed: string
    onTertiaryFixed: string
    tertiaryFixedDim: string
    onTertiaryFixedVariant: string
    surfaceDim: string
    surfaceBright: string
    surfaceContainerLowest: string
    surfaceContainerLow: string
    surfaceContainer: string
    surfaceContainerHigh: string
    surfaceContainerHighest: string
}
