/* eslint-disable no-restricted-imports */
import { WK } from '../../window'
import { Bridge } from '../bridge/bridge'

class SchemaVersionManager {
    private bridge: Bridge | null = null
    private ticketSchemaVersion = 0
    constructor() {
        WK.schemaVersion = this.getSchemaVersion
    }
    provideBridge(bridge: Bridge) {
        this.bridge = bridge
    }
    getSchemaVersion = () => {
        return this.ticketSchemaVersion
    }
    injectTicketSchemaVersion(version: number) {
        this.ticketSchemaVersion = version
    }
    destroy() {
        this.bridge = null
    }
}

export const schemaVersionManager = new SchemaVersionManager()

export const getSchemaVersion = schemaVersionManager.getSchemaVersion

export const isSchemaVersionSupported = (version: number) => schemaVersionManager.getSchemaVersion() >= version
