import { Wukong } from '@wukong/bridge-proto'
import { MonoIconCommonBell24, WKAlert, WKButton } from '../../../../../../ui-lib/src'
import { LibraryNotificationType2Message } from '../../../../kernel/interface/library'
import { useLibraryComponentService } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { LibraryTestId } from '../../../../window'
import classes from './library-remote-change-warning-tip.module.less'
import { translation } from './library-remote-change-warning-tip.translation'

const RemoteLibraryUpgradeType2Message = (remoteLibraryUpgradeType?: Wukong.DocumentProto.RemoteLibraryUpgradeType) => {
    switch (remoteLibraryUpgradeType) {
        case Wukong.DocumentProto.RemoteLibraryUpgradeType.REMOTE_LIBRARY_UPGRADE_TYPE_ONLY_COMPONENT:
            return LibraryNotificationType2Message(
                Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_REFERENCE_COMPONENT_UPDATED
            )
        case Wukong.DocumentProto.RemoteLibraryUpgradeType.REMOTE_LIBRARY_UPGRADE_TYPE_ONLY_STYLE:
            return LibraryNotificationType2Message(
                Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_REFERENCE_STYLE_UPDATED
            )
        case Wukong.DocumentProto.RemoteLibraryUpgradeType.REMOTE_LIBRARY_UPGRADE_TYPE_COMPONENT_AND_STYLE:
            return LibraryNotificationType2Message(
                Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_REFERENCE_UPDATED
            )
        case Wukong.DocumentProto.RemoteLibraryUpgradeType.REMOTE_LIBRARY_UPGRADE_TYPE_ONLY_VARIABLE:
            return LibraryNotificationType2Message(
                Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_REFERENCE_VARIABLE_UPDATED
            )
        case Wukong.DocumentProto.RemoteLibraryUpgradeType.REMOTE_LIBRARY_UPGRADE_TYPE_VARIABLE_AND_STYLE:
            return LibraryNotificationType2Message(
                Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_VARIABLE_AND_STYLE_UPDATED
            )
        case Wukong.DocumentProto.RemoteLibraryUpgradeType.REMOTE_LIBRARY_UPGRADE_TYPE_VARIABLE_AND_COMPONENT:
            return LibraryNotificationType2Message(
                Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_VARIABLE_AND_COMPONENT_UPDATED
            )
        case Wukong.DocumentProto.RemoteLibraryUpgradeType.REMOTE_LIBRARY_UPGRADE_TYPE_VARIABLE_AND_COMPONENT_AND_STYLE:
            return LibraryNotificationType2Message(
                Wukong.DocumentProto.LibraryNotificationType
                    .LIBRARY_NOTIFICATION_TYPE_VARIABLE_AND_COMPONENT_AND_STYLE_UPDATED
            )
        default:
            return ''
    }
}

// 引用组件更新提示 tip
export function LibraryRemoteChangeWarningTip(props: { isSearching: boolean }) {
    const libraryGlobalState = useViewState('libraryGlobalState')
    const {
        libraryModalRouterService: { goToRemoteLibraryChangeList },
    } = useLibraryComponentService()

    return !props.isSearching && libraryGlobalState?.showRemoteLibraryTabBadge ? (
        <div className={classes.container}>
            <WKAlert.WithoutTitle
                icon={<MonoIconCommonBell24 />}
                iconWrapClassName="!p-0 flex items-center"
                suffix={
                    <WKButton
                        type="primary"
                        onClick={goToRemoteLibraryChangeList}
                        size="small"
                        data-testid={LibraryTestId.HomeModal.GoUpdateRemoteBtn}
                    >
                        {translation('Update')}
                    </WKButton>
                }
            >
                {RemoteLibraryUpgradeType2Message(libraryGlobalState?.remoteLibraryUpgradeType)}
            </WKAlert.WithoutTitle>
        </div>
    ) : (
        <></>
    )
}
