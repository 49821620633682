import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    CannotReplaceText: '无法替换字体缺失的文本',
    ShowMissingFonts: '查看缺失字体',
    SFtNKo: '页面',
    uPBzBL: '文件',
    'Replaced{{num}}Results': '本{{scopeLabel}}内 {{num}} 条结果已替换',
    'ReplaceWith…': '替换为',
    Replace: '替换',
    ReplaceAll: '替换全部',
} as const

export const enTranslation = {
    CannotReplaceText: 'Cannot replace text with missing fonts',
    ShowMissingFonts: 'Show missing fonts',
    SFtNKo: 'page',
    uPBzBL: 'file',
    'Replaced{{num}}Results': '{{num}} {{result}} in this {{scopeLabel}} replaced',
    'ReplaceWith…': 'Replace with',
    Replace: 'Replace',
    ReplaceAll: 'Replace all',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string | number>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
