import { Select } from '../../../../ui-lib/src'
import { RoleStatus } from '../../kernel/interface/type'
import { RoleStatusLabels } from '../../kernel/interface/user-roles'

interface PrivilegesSelectProps {
    accessMenu: RoleStatus[]
    value: RoleStatus
    onChange: (value: RoleStatus) => void
    isCurrentUser?: boolean
    onClose?: () => void
    disabled?: boolean
}

export const PrivilegesSelect = (props: PrivilegesSelectProps) => {
    if (props.accessMenu?.length) {
        return (
            <Select.MinimalSingleLevel
                minWidth={112}
                onClose={props.onClose}
                onChange={(v) => props.onChange(v)}
                value={props.value}
                label={RoleStatusLabels[props.value]}
                dataTestIds={{
                    triggerFocus: 'privileges-select',
                    scrollContainer: 'privileges-scrollContainer',
                }}
                disabled={props.disabled}
            >
                {props.accessMenu.map((role) => (
                    <Select.MinimalSingleLevel.Option key={role} value={role} dataTestId="select-option">
                        {RoleStatusLabels[role]}
                    </Select.MinimalSingleLevel.Option>
                ))}
            </Select.MinimalSingleLevel>
        )
    } else {
        return <>{props.value && RoleStatusLabels[props.value as RoleStatus]}</>
    }
}
