import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useMount, useUpdateEffect } from 'react-use'
import { ExplicitUndefined } from '../../../../utils/explicit-undefined'
import { DragMoveBox, LeftTop } from '../../../drag-move-box/drag-move-box'
import { CaptureKeyboard, CaptureKeyboardProps, CaptureKeyboardRef } from '../../capture-keyboard/capture-keyboard'
import { PopUpStatic, PopUpStaticProps, PopUpStaticRef } from '../../pop-up-static/pop-up-static'
import { PickBaseProps, PickBaseRef, pickComponentNames } from '../type'
import { useComment } from '../use-comment'

type PickPopUpStaticProps = Omit<PopUpStaticProps, 'onClickMask' | 'dataTestIds' | 'style'>
export interface EmptyProps extends PickBaseProps, PickPopUpStaticProps {
    dataTestIds?: PopUpStaticProps['dataTestIds'] & CaptureKeyboardProps['dataTestIds']
    children?: React.ReactNode
}

export interface EmptyRef extends PickBaseRef {}

function _Empty<T extends EmptyProps = EmptyProps>(props: T, ref?: React.Ref<EmptyRef>) {
    const {
        closeByESC,
        onKeyboard,
        openStateToBeTrue,
        openStateToBeFalse,
        onOpen,
        onClose,
        getPortalElement,
        autoAdjustLeft = true,
    } = props
    const [isOpenState, setIsOpenState] = useState(false)
    const captureKeyboardRef = useRef<CaptureKeyboardRef>(null)
    const popUpStaticRef = useRef<PopUpStaticRef>(null)
    const [leftTop, setLeftTop] = useState<LeftTop>({ left: 0, top: 0 })

    const { addOverflowHiddenToBody, resetOverflowStatusToBody } = useComment()

    const open = useCallback(() => {
        if (isOpenState || openStateToBeTrue?.()) {
            return
        }
        addOverflowHiddenToBody()
        setIsOpenState(true)
        onOpen?.()
    }, [addOverflowHiddenToBody, isOpenState, onOpen, openStateToBeTrue])

    const close = useCallback(() => {
        if (!isOpenState || openStateToBeFalse?.()) {
            return
        }
        resetOverflowStatusToBody()
        setIsOpenState(false)
        onClose?.()
    }, [isOpenState, onClose, openStateToBeFalse, resetOverflowStatusToBody])

    const updateOpenStateFromOutside = useCallback(() => {
        if (props.isOpenState === false) {
            close()
        } else if (props.isOpenState === true) {
            open()
        }
    }, [close, open, props.isOpenState])

    useMount(updateOpenStateFromOutside)

    useUpdateEffect(updateOpenStateFromOutside, [updateOpenStateFromOutside])

    const onFocusLeave = useCallback(() => {
        close()
    }, [close])

    const tryRestoreFocusElement = useCallback(() => {
        if (!captureKeyboardRef.current || captureKeyboardRef.current.getContainer().contains(document.activeElement)) {
            return
        }
        captureKeyboardRef.current.focus()
    }, [])

    const _onKeyboardEvent = useCallback(
        (e: React.KeyboardEvent) => {
            if (e.keyCode === 27) {
                e.stopPropagation()
                close()
                closeByESC?.()
            }
            onKeyboard?.(e)
        },
        [close, closeByESC, onKeyboard]
    )

    const onMoving = useCallback(
        (nextLeftTop: LeftTop) => {
            if (autoAdjustLeft) {
                return
            }
            setLeftTop(nextLeftTop)
        },
        [autoAdjustLeft]
    )

    useImperativeHandle(ref, () => ({ open, close }), [close, open])

    return isOpenState
        ? ReactDOM.createPortal(
              <CaptureKeyboard<ExplicitUndefined<CaptureKeyboardProps>>
                  dataTestIds={props.dataTestIds}
                  ref={captureKeyboardRef}
                  onKeyboardEvent={_onKeyboardEvent}
                  onFocusLeave={onFocusLeave}
                  onMouseMove={tryRestoreFocusElement}
                  getFocusElement={() => popUpStaticRef.current?.getKeyboardElement()}
                  data-component-name={pickComponentNames.empty}
                  className={props.pickRest?.classNameRoot}
                  onMouseDown={props.pickRest?.onMouseDown}
              >
                  <PopUpStatic<ExplicitUndefined<PopUpStaticProps>>
                      ref={popUpStaticRef}
                      worldRect={props.worldRect}
                      triggerRect={props.triggerRect}
                      placement={props.placement}
                      overMajorClassName={props.overMajorClassName}
                      overMinorClassName={props.overMinorClassName}
                      isMinWidthFromTrigger={props.isMinWidthFromTrigger}
                      minWidth={props.minWidth}
                      width={props.width}
                      maxWidth={props.maxWidth}
                      style={leftTop}
                      onClickMask={close}
                      dataTestIds={props.dataTestIds}
                      removeMask={props.removeMask}
                      autoAdjustLeft={autoAdjustLeft}
                  >
                      <DragMoveBox onMoving={onMoving}>{props.children}</DragMoveBox>
                  </PopUpStatic>
              </CaptureKeyboard>,
              getPortalElement?.() ?? document.body
          )
        : null
}

export const Empty = forwardRef(_Empty) as <T extends EmptyProps = EmptyProps>(
    props: T & { ref?: React.Ref<EmptyRef> }
) => React.ReactElement
