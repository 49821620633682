/* eslint-disable no-restricted-imports */
import classNames from 'classnames'
import { FC, useMemo } from 'react'

import { isValidKeyValueString } from '../../../../design/component/utils/parse-component-set'
import styles from './index.module.less'

interface LayerPanelRenderItemNameProps {
    name: string
    isFontBold: boolean
    isPurple: boolean
    isVariant: boolean
}

export const LayerPanelRenderItemName: FC<LayerPanelRenderItemNameProps> = ({
    name,
    isFontBold,
    isPurple,
    isVariant,
}) => {
    const fontClassName = useMemo<string | undefined>(() => {
        if (isFontBold) {
            return styles.strong
        }
        if (isPurple) {
            return styles.purple
        }
    }, [isPurple, isFontBold])

    // 将变体名称从 a=b 变成 b
    const displayName = useMemo(() => {
        if (isVariant && isValidKeyValueString(name)) {
            return name
                .split(',')
                .map((value) => value.split('=')[1])
                .join(',')
        } else {
            return name.trim()
        }
    }, [name, isVariant])

    return useMemo(
        () =>
            displayName.length ? (
                <div
                    data-testid="dev-mode-layer-panel-item-name"
                    className={classNames(styles.devModeLayerPanelRenderItemName, fontClassName)}
                >
                    {displayName}
                </div>
            ) : null,
        [fontClassName, displayName]
    )
}
