import { useCallback, useEffect, useState } from 'react'
import { MonoIconCommonArrowLeft16, Scrollbar, Switch } from '../../../../../../ui-lib/src'
import type { LibraryVO } from '../../../../kernel/interface/library'
import { useAIDesignLintService } from '../../../../main/app-context'
import classes from './ai-design-lint-modal.module.less'
import { UpdateAIDesignLintLibraryConfig } from './ai-design-lint-request'
import { NetworkStatus, View } from './ai-design-lint-service'
import { EmptyPrompt } from './common/empty-prompt'
import { PendingWrapper } from './common/pending-wrapper'
// eslint-disable-next-line wukong/restrict-translation-import
import { translation } from './translation/library-setting-page.translation'

function LibraryContentListItem(prop: {
    title?: string
    library: LibraryVO
    toggle: (libId: string) => Promise<void>
    loading: boolean
    disable: boolean
    checked: boolean
}) {
    const { library, toggle, title, loading, disable, checked } = prop

    const onSwitchChange = (_checked: boolean, event: React.MouseEvent | React.KeyboardEvent) => {
        event.stopPropagation()
        toggle(library.id)
    }

    return (
        <div className={classes.library_item_container}>
            <div className={classes.library_item_container_left}>
                <Switch
                    loading={loading}
                    disabled={disable}
                    checked={checked}
                    onChange={onSwitchChange}
                    dataTestIds={{
                        switch: 'switch_' + (title ? title : library.document!.name),
                    }}
                />
                <div>{title ? title : library.document!.name}</div>
            </div>
            <div className={classes.library_item_container_right}>
                {library.componentCount +
                    ((library.componentCount || 0) == 1
                        ? translation('SingleComponent')
                        : translation('PluralComponent'))}
                {translation('Comma')}
                {library.styleCount +
                    ((library.styleCount || 0) > 1 ? translation('PluralStyle') : translation('SingleStyle'))}
            </div>
        </div>
    )
}

function LibraryItemContentTitle(prop: { title: string }) {
    return <div className={classes.library_item_container_title}>{prop.title}</div>
}

function LibraryListContainer() {
    const [pendingLibId, setPendingLibId] = useState<string>()

    const aiDesignLintService = useAIDesignLintService()
    const docId = aiDesignLintService.docID
    const remoteLibraryConfig = aiDesignLintService.states.use.latestLibraryConfigState()

    const [localLibraryConfig, setLocalLibraryConfig] = useState<typeof remoteLibraryConfig | undefined>(undefined)
    useEffect(() => {
        if (localLibraryConfig === undefined) {
            setLocalLibraryConfig(remoteLibraryConfig)
        }
    }, [localLibraryConfig, remoteLibraryConfig])

    const libraryInfoList = aiDesignLintService.states.use.libraryInfoListState()

    const libraryTeamInfoList = aiDesignLintService.states.use.libraryTeamInfoListState()

    const needToWait = !localLibraryConfig || !libraryInfoList || !libraryTeamInfoList

    const localLibraryConfigSet = new Set(localLibraryConfig?.value || [])

    const hasLib = !needToWait ? libraryInfoList.length > 0 : false
    const localLib = !needToWait ? libraryInfoList.filter((item) => item?.document?.id === docId) : []

    const toggleSingleLib = useCallback(
        (libId: string) => {
            if (!docId || localLibraryConfig === undefined) {
                return Promise.resolve()
            }

            setPendingLibId(libId)

            const newLibConfigSet = new Set(localLibraryConfig.value)

            if (newLibConfigSet.has(libId)) {
                newLibConfigSet.delete(libId)
            } else {
                newLibConfigSet.add(libId)
            }

            return new UpdateAIDesignLintLibraryConfig(docId, [...newLibConfigSet])
                .start()
                .then(() => {
                    localLibraryConfig.value = [...newLibConfigSet]
                    setPendingLibId(undefined)
                })
                .catch(() => {
                    aiDesignLintService.updateNetworkStatus(NetworkStatus.SettingLibraryError)
                })
                .finally(() => {
                    aiDesignLintService.refresh()
                })
        },
        [docId, localLibraryConfig, aiDesignLintService]
    )

    return (
        <PendingWrapper pending={needToWait}>
            <Scrollbar height="100%">
                {localLib.length > 0 || hasLib ? (
                    <>
                        {localLib.length > 0 && (
                            <div>
                                <LibraryContentListItem
                                    toggle={toggleSingleLib}
                                    loading={pendingLibId === localLib[0].id}
                                    disable={pendingLibId !== undefined && pendingLibId !== localLib[0].id}
                                    checked={localLibraryConfigSet.has(localLib[0].id)}
                                    title={translation('LocalLibrary')}
                                    library={localLib[0]}
                                />
                            </div>
                        )}

                        {hasLib && (
                            <>
                                {libraryTeamInfoList?.map((libraryTeamInfo) => (
                                    <div key={libraryTeamInfo.name}>
                                        <LibraryItemContentTitle title={libraryTeamInfo.name} />
                                        {libraryTeamInfo.libraryList.map((library) => (
                                            <LibraryContentListItem
                                                toggle={toggleSingleLib}
                                                loading={pendingLibId === library.id}
                                                disable={pendingLibId !== undefined && pendingLibId !== library.id}
                                                checked={localLibraryConfigSet.has(library.id)}
                                                key={library.id}
                                                library={library}
                                            />
                                        ))}
                                    </div>
                                ))}
                            </>
                        )}
                    </>
                ) : (
                    <EmptyPrompt texts={[translation('EmptyPromptFirstLine'), translation('EmptyPromotSecondLine')]} />
                )}
            </Scrollbar>
        </PendingWrapper>
    )
}

function BackBar() {
    const aiDesignLintService = useAIDesignLintService()
    const setCurrentView = aiDesignLintService.setCurrentView
    const onClick = useCallback(() => {
        setCurrentView(View.Home)
    }, [setCurrentView])

    return (
        <div className={classes.back_bar} onClick={onClick}>
            <MonoIconCommonArrowLeft16 />
            <span>{translation('DesignSystem')}</span>
        </div>
    )
}

export function LibrarySettingPage() {
    return (
        <div className="flex flex-col h-full">
            <BackBar />
            <LibraryListContainer />
        </div>
    )
}
