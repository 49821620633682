import { translation } from './validators.translation'
// 跟服务端保持一致
/*
public boolean isValid(String object, ConstraintValidatorContext constraintContext) {
        if (null == object) {
            return true;
        }
        int length = object.replaceAll("[^\\x00-\\xff]", DOUBLE_CHARACTER).length();
        return length <= max && length >= min;
    }

*/
export const getGbLength = (str: string) => {
    const DOUBLE_CHARACTER = '--'
    // eslint-disable-next-line no-control-regex
    const str1 = str.replaceAll(/[^\x00-\xFF]/g, DOUBLE_CHARACTER)
    const length = str1.length
    return length
}

// 字符长度校验，中文算两个，其他算一个
export const charCodeLengthValidator = (str: string, num: number, label?: string) => {
    const length = getGbLength(str)
    if (length > num) {
        return Promise.reject({ message: translation('WordCount', { label: label ?? '', count: Math.round(num / 2) }) })
    } else {
        return Promise.resolve()
    }
}

export const charCodeLengthValidatorV2 = (str: string, num: number, label?: string) => {
    const length = getGbLength(str)
    if (length > num) {
        return translation('WordCount', { label: label ?? '', count: Math.round(num / 2) })
    } else {
        return ''
    }
}

export const docTitleValidator = (name: string) => {
    const trimmedName = name.trim()
    if (trimmedName !== '') {
        return charCodeLengthValidator(name, 100, translation('FileName'))
    } else {
        return Promise.reject({ message: translation('AFileMust') })
    }
}
export const teamNameValidator = (name: string) => {
    if (name) {
        return charCodeLengthValidator(name, 100, translation('TeamName'))
    } else {
        return Promise.reject({ message: translation('ATeamMust') })
    }
}

export const folderNameValidator = (name: string) => {
    if (name) {
        return charCodeLengthValidator(name, 100, translation('ProjectName'))
    } else {
        return Promise.reject({ message: translation('AProjectMust') })
    }
}

const passwordRegexp = /^((?=.*\d)(?=.*[a-z])|(?=.*\d)(?=.*[A-Z])|(?=.*[a-z])(?=.*[A-Z])).{0,}$/

export const emailRegExp = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/

export const passwordValidator = (password: string | undefined, checkExist = false) => {
    if (checkExist && (password === undefined || password?.length === 0)) {
        return Promise.reject({ message: translation('PleaseProvideYour') })
    }
    if ((password?.length ?? 0) < 8) {
        return Promise.reject({
            message: translation('PleaseSetA'),
        })
    } else {
        return password && passwordRegexp.test(password)
            ? Promise.resolve()
            : Promise.reject({
                  message: `${translation('iRiBkm')}`,
              })
    }
}
export const passwordValidatorBoolean = async (...args: Parameters<typeof passwordValidator>) => {
    try {
        await passwordValidator(...args)
        return true
    } catch (e: any) {
        return e.message as string
    }
}
