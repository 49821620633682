import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { useMemo, useRef, useState } from 'react'
import {
    DraggablePopupV2,
    IconComponentLibrary16,
    InputV2,
    ScrollView,
    WKButton,
} from '../../../../../../../../ui-lib/src'
import { ToKeyCode } from '../../../../../../document/util/keycode'
import { useCommand, useLibraryComponentService } from '../../../../../../main/app-context'
import { KeyboardReceiver } from '../../../../../../main/keyboard-receiver/component'
import { useKeyboardStyleStyleList } from '../../../styles/hooks/use-keyboard-style-list'
import style from '../../../styles/style-color/style-color.module.less'
import {
    EffectStyleEditor,
    EffectStyleEditorProps,
} from '../../../styles/style-panel/effect-style-editor/effect-style-editor'
import { StyleTitle, StyleType } from '../../../styles/style-title/style-title'
import { ClientPosittion, useEffectContext } from '../../context'
import { EffectStyleListItem } from './local-select-group'
import { EffectStyleListItemRemote } from './remote-select-group'
import { UnknownEffectStyleListItem } from './unknown-select-group'
import {
    SelectEffectStyleContextProvider,
    useEffectsToCreate,
    useEffectStyleEditInsideSelect,
    useEffectStyleSelectHandler,
    useEffectStyleSelectList,
} from './use-effect-style-select'

export function SelectEffectStyleModal(props: Parameters<typeof SelectEffectStyleModalInternal>[0]) {
    return (
        <SelectEffectStyleContextProvider>
            <SelectEffectStyleModalInternal {...props} />
        </SelectEffectStyleContextProvider>
    )
}

function SelectEffectStyleModalInternal(props: { onClose: () => void; position: ClientPosittion; isMixed: boolean }) {
    const { selectionEffectStyle: effectStyle } = useEffectContext()
    const { effects } = useEffectsToCreate()
    const { onSearch } = useEffectStyleSelectHandler()
    const { list, subscribedList, unknownList, hasLocalStyle, hasRemoteStyle, empty, selectEffectStyleNodeKey } =
        useEffectStyleSelectList()
    const { onSelect } = useEffectStyleSelectHandler()
    const { onEdit, onEndEdit, opened, openedId, position, setPosition } = useEffectStyleEditInsideSelect()
    const titleContainerRef = useRef<HTMLDivElement>(null)
    const command = useCommand()

    const [createStyleId, setCreateStyleId] = useState('')
    const [createStyle, setCreateStyle] = useState<EffectStyleEditorProps['createStyle']>()

    const hasMoved = useRef<boolean>(false)
    const {
        libraryModalRouterService: { goToRemoteLibraryHome },
    } = useLibraryComponentService()

    const _onEndEdit = () => {
        setCreateStyleId('')
        setCreateStyle(undefined)
        onEndEdit()
    }

    const needSplitLineWithUnknownListItem = (index: number) => {
        if (index > 0) {
            return true
        }
        const hasItemBeforeUnknown = list.length > 0
        return hasItemBeforeUnknown
    }
    const needSplitLineWithRemoteSubscribedListItem = (index: number) => {
        if (index > 0) {
            return true
        }
        const hasItemBeforeRemoteStyle = list.length > 0 || unknownList.length > 0
        return hasItemBeforeRemoteStyle
    }

    const maxPreSelectIndex = useMemo(() => {
        let optionSum = 0
        for (const group of list) {
            optionSum += group.effectStyles.length
        }
        for (const group of unknownList) {
            optionSum += group.effectStyles.length
        }
        for (const library of subscribedList) {
            for (const group of library.effectStyles) {
                optionSum += group.effectStyles.length
            }
        }
        return Math.max(optionSum - 1, 0)
    }, [list, subscribedList, unknownList])

    const keyboardStyleStyleList = useKeyboardStyleStyleList({ maxPreSelectIndex })

    const renderItems = () => {
        let optionIndex = 0
        const _selectStyleId = effectStyle?.styleId ? effectStyle?.styleId : undefined
        return (
            <>
                {list.length ? (
                    <div>
                        {list.map((group, index) => (
                            <div key={index}>
                                {group.groupName ? <div className={style.h2}>{group.groupName}</div> : null}
                                <div>
                                    {group.effectStyles.map((v) => (
                                        <EffectStyleListItem
                                            item={v}
                                            key={v.effectStyle.id}
                                            index={optionIndex++}
                                            selectStyleId={_selectStyleId}
                                            setPreselectIndex={keyboardStyleStyleList.setPreselectIndex}
                                            trySetPreselectIndex={keyboardStyleStyleList.trySetPreselectIndex}
                                            recordEnterCallback={keyboardStyleStyleList.recordEnterCallback}
                                        ></EffectStyleListItem>
                                    ))}
                                </div>
                            </div>
                        ))}
                        <div className={style.paddingBottom8}></div>
                    </div>
                ) : null}
                {unknownList.length ? (
                    <div>
                        <div
                            className={classnames(style.h1, {
                                [style.splitLine]: needSplitLineWithUnknownListItem(0),
                            })}
                        >
                            {translation('UnknownLibrary')}
                        </div>
                        {unknownList.map((group, index) => (
                            <div key={index}>
                                {group.groupName ? <div className={style.h2}>{group.groupName}</div> : null}
                                <div>
                                    {group.effectStyles.map((v) => (
                                        <UnknownEffectStyleListItem
                                            item={v}
                                            key={v.effectStyle.id}
                                            index={optionIndex++}
                                            selectStyleId={_selectStyleId}
                                            setPreselectIndex={keyboardStyleStyleList.setPreselectIndex}
                                            trySetPreselectIndex={keyboardStyleStyleList.trySetPreselectIndex}
                                            recordEnterCallback={keyboardStyleStyleList.recordEnterCallback}
                                        ></UnknownEffectStyleListItem>
                                    ))}
                                </div>
                            </div>
                        ))}
                        <div className={style.paddingBottom8}></div>
                    </div>
                ) : null}

                {subscribedList.map((library, index) => (
                    <div key={library.libraryId}>
                        <div
                            className={classnames(style.h1, {
                                [style.splitLine]: needSplitLineWithRemoteSubscribedListItem(index),
                            })}
                        >
                            {library.libraryName || translation('UnknownLibrary_synonyms1')}
                        </div>
                        {library.effectStyles.map((group, index2) => (
                            <div key={index2}>
                                {group.groupName ? <div className={style.h2}>{group.groupName}</div> : null}
                                <div>
                                    {group.effectStyles.map((v) => (
                                        <EffectStyleListItemRemote
                                            item={v}
                                            key={v.effectStyle.id}
                                            docId={library.docId}
                                            selectStyleNodeKey={selectEffectStyleNodeKey}
                                            index={optionIndex++}
                                            selectStyleId={_selectStyleId}
                                            setPreselectIndex={keyboardStyleStyleList.setPreselectIndex}
                                            trySetPreselectIndex={keyboardStyleStyleList.trySetPreselectIndex}
                                            recordEnterCallback={keyboardStyleStyleList.recordEnterCallback}
                                        ></EffectStyleListItemRemote>
                                    ))}
                                </div>
                            </div>
                        ))}
                        <div className={style.paddingBottom8}></div>
                    </div>
                ))}
            </>
        )
    }
    const effectCreateStyle = (styleId: string) => {
        if (!position) {
            return
        }
        onSelect?.(styleId)
        onEdit(styleId, position, true)
        setCreateStyleId(styleId)
        command.commitUndo()
    }

    const onClickAddIcon = () => {
        const rect = titleContainerRef.current?.getBoundingClientRect()
        if (!rect) {
            return
        }
        setCreateStyle({
            isCreate: true,
            isMixed: props.isMixed,
            effects,
        })
        setPosition(rect)
    }

    return (
        <>
            <DraggablePopupV2
                visible
                position={props.position}
                closable={false}
                width={216}
                positionRightBase
                onFirstMove={() => (hasMoved.current = true)}
                header={
                    <div className={style.titleContainer} ref={titleContainerRef}>
                        <StyleTitle
                            styleType={StyleType.EffectStyle}
                            hiddenLibraryIcon={!hasLocalStyle && !hasRemoteStyle}
                            onClickAddIcon={onClickAddIcon}
                            onClickLibraryIcon={goToRemoteLibraryHome}
                        />
                    </div>
                }
                bodyClassName="p-0"
                footer={null}
            >
                <div className={style.contentContainer}>
                    <InputV2.Search
                        style={{ margin: 8 }}
                        onSearch={onSearch}
                        autoFocus
                        onInput={keyboardStyleStyleList.onInput}
                        onKeyDown={keyboardStyleStyleList.onKeyDown}
                        placeholder={translation('Search')}
                    />
                    {empty ? (
                        <div className={style.emptyStyle}>
                            <div className={style.emptyStyle_text}>{translation('NoEffect')}</div>
                            <div className={style.emptyStyle_button}>
                                <WKButton
                                    type="secondary"
                                    className={style.button}
                                    onClick={goToRemoteLibraryHome}
                                    icon={<IconComponentLibrary16 />}
                                >
                                    {translation('BrowseLibraries')}
                                </WKButton>
                            </div>
                        </div>
                    ) : list.length === 0 && subscribedList.length === 0 && unknownList.length === 0 ? (
                        <div className={style.emptyStyle}>
                            <div className={style.emptyStyle_text}>{translation('NoStyles')}</div>
                        </div>
                    ) : (
                        <ScrollView
                            className={classnames(style.content)}
                            key="list"
                            selectKey={keyboardStyleStyleList.preselectIndex}
                            ref={keyboardStyleStyleList.scrollViewRef}
                            block="nearest"
                            scrollbar={{ autoHeight: true, autoHeightMin: 0, autoHeightMax: 352 }}
                        >
                            <KeyboardReceiver keyCode={ToKeyCode.All} onKeydown={keyboardStyleStyleList.onKeyDown}>
                                {renderItems()}
                            </KeyboardReceiver>
                        </ScrollView>
                    )}
                </div>
                {position && createStyle?.isCreate ? (
                    <EffectStyleEditor
                        visible={opened && openedId === createStyleId}
                        onCancel={_onEndEdit}
                        position={position}
                        styleId={createStyleId}
                        onClickCreate={_onEndEdit}
                        createStyle={createStyle}
                        effectCreateStyle={effectCreateStyle}
                    />
                ) : null}
            </DraggablePopupV2>
        </>
    )
}
