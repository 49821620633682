import { translation } from './memory-usage-bar.translation'
/* eslint-disable no-restricted-imports */
import { Tooltip } from '../../../../../ui-lib/src'
import classNames from 'classnames'
import { useViewState } from '../../../view-state-bridge'
import classes from './memory-usage-bar.module.less'
import { ReactComponent as NodeLayerIcon } from './node-layer-icon.svg'
import { useMemoryUsageBar } from './use-memory-usage-bar'

export const MemoryUsageBar = () => {
    const viewport = useViewState('visibleViewport')!
    const background = useViewState('currentPageBackground')?.paint
    const memoryUsage = useViewState('memoryUsage')
    const { show, nodeCount, memoryUsedPercent, isDark } = useMemoryUsageBar(memoryUsage, background)

    if (!show) {
        return null
    }
    return (
        <div
            className={classNames(classes.overlay)}
            style={{
                width: viewport.width,
                height: viewport.height,
                left: viewport.paddingLeft,
                top: viewport.paddingTop,
            }}
        >
            <div className={classNames(classes.panel, isDark ? classes.dark : classes.light)} data-testid="usage-bar">
                <Tooltip title={translation('TotalLayers')} placement="right">
                    <div className={classes.row} data-testid="usage-bar-layer-row">
                        <div className={classNames(classes.column, classes.left)} data-testid="usage-bar-layer-icon">
                            <NodeLayerIcon></NodeLayerIcon>
                        </div>
                        <div className={classNames(classes.column, classes.right)} data-testid="usage-bar-layer-value">
                            {nodeCount ?? 0}
                        </div>
                    </div>
                </Tooltip>
                <Tooltip title={translation('MemoryUsage')} placement="right">
                    <div className={classes.row} data-testid="usage-bar-memory-row">
                        <div className={classNames(classes.column, classes.left)}>
                            <div className={classes.bar}>
                                <div
                                    className={classes.percent}
                                    style={{ width: `${memoryUsedPercent * 100}%` }}
                                    data-testid="usage-bar-memory-bar"
                                ></div>
                            </div>
                        </div>
                        <div className={classNames(classes.column, classes.right)} data-testid="usage-bar-memory-value">
                            {(memoryUsedPercent * 100).toFixed(2)}%
                        </div>
                    </div>
                </Tooltip>
            </div>
        </div>
    )
}
