import {
    AnchorWrapper,
    DropdownDefaultRenderItem,
    RenderStructNodeItem,
    RenderStructNodeItemProps,
} from '../../../../../../../ui-lib/src'
import { MainMenuItemCustomNode } from './type'

export const MenuItemRender: RenderStructNodeItem<MainMenuItemCustomNode> = (
    props: RenderStructNodeItemProps<MainMenuItemCustomNode>
) => {
    return (
        <AnchorWrapper linkInfo={props.data.customNode?.linkInfo} dataTestId="custom-item-anchor">
            <DropdownDefaultRenderItem {...props} />
        </AnchorWrapper>
    )
}
