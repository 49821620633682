import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useEvent } from 'react-use'
import { DraggablePopupV2, Scrollbar, Switch, Tooltip, useEllipsisTooltip } from '../../../../../../ui-lib/src'
import { PluginVO } from '../../../../kernel/interface/plugin'
import { usePluginService } from '../../../../main/app-context'
import { useUserConfigStateV2 } from '../../../../main/user-config/user-config-hook'
import { translation } from './plugin-management.translation'

export const PluginManagement = () => {
    const pluginService = usePluginService()
    const isOpenPluginManagement = pluginService.states.use.isOpenPluginManagement()
    const publishedPlugins = pluginService.states.use.publishedPlugins()

    if (!isOpenPluginManagement) {
        return null
    }

    if (publishedPlugins.length === 0) {
        return null
    }

    return <PluginManagementContainer />
}

const PluginManagementContainer = () => {
    const pluginService = usePluginService()
    const publishedPlugins = pluginService.states.use.publishedPlugins()

    const [pluginEnableConfigV2, setPluginEnableConfigV2] = useUserConfigStateV2('pluginEnableConfig')

    const onChange = (pluginId: string, checked: boolean) => {
        setPluginEnableConfigV2((prev) => {
            const newState = { ...prev }
            newState[pluginId] = {
                enable: checked,
                lastEnableTime: Date.now(),
            }
            return newState
        })
        window.localBridge?.updatePluginMenu?.()
    }

    const { autoHeightMax } = useAutoScrollbarHeight()

    useEffect(() => {
        pluginService.fetchPublishedPlugins()
    }, [pluginService])

    return (
        <DraggablePopupV2
            header={translation('title')}
            visible={true}
            width={400}
            onCancel={() => {
                pluginService.states.getState().updateIsOpenPluginManagement(false)
            }}
            footer={null}
            bodyClassName="p-0"
            testId={'plugin-management-dialog'}
            closeTestId={'close-icon'}
        >
            <Scrollbar autoHeight autoHeightMin={145} autoHeightMax={autoHeightMax}>
                <div className="p-24px">
                    <div className="mb-4 wk-text-12 wk-font-regular color-$wk-v2-label-color-gray-8">
                        {translation('description')}
                    </div>
                    <div className="">
                        {publishedPlugins.map((plugin) => (
                            <PluginItem
                                key={plugin.id}
                                plugin={plugin}
                                isUsed={pluginEnableConfigV2[plugin.id]?.enable}
                                onChange={onChange}
                            />
                        ))}
                    </div>
                </div>
            </Scrollbar>
        </DraggablePopupV2>
    )
}

function useAutoScrollbarHeight() {
    const [autoHeightMax, setAutoHeightMax] = useState<number | undefined>()

    const calcAutoHeightMax = useCallback(() => {
        const popupMaxHeight = Math.min(800, window.innerHeight - 48 - 8 * 2)
        const scrollMaxHeight = popupMaxHeight - 55
        setAutoHeightMax(scrollMaxHeight)
    }, [])

    useLayoutEffect(() => {
        calcAutoHeightMax()
    }, [calcAutoHeightMax])

    useEvent('resize', () => {
        calcAutoHeightMax()
    })

    return { autoHeightMax }
}

const PluginItem = ({
    plugin,
    isUsed,
    onChange,
}: {
    plugin: PluginVO
    isUsed?: boolean
    onChange: (pluginId: string, checked: boolean) => void
}) => {
    const { inactivation, ellipsisRef, onmouseenter, onmouseleave } = useEllipsisTooltip<HTMLDivElement>()

    return (
        <div className="py-10px flex items-center justify-between" data-testid={`plugin-management-item`}>
            <div className="flex items-center justify-start">
                <img src={plugin.iconUrl} className="w-24px h-24px rounded-2.5px" />
                <Tooltip title={plugin.name} inactivation={inactivation}>
                    <div className="ml-3 w-264px wk-text-13 wk-font-medium color-$wk-v2-label-color-gray-13 truncate">
                        <span ref={ellipsisRef} onMouseEnter={onmouseenter} onMouseLeave={onmouseleave}>
                            {plugin.name}
                        </span>
                    </div>
                </Tooltip>
            </div>
            <Switch
                checked={isUsed}
                onChange={(checked) => onChange(plugin.id, checked)}
                dataTestIds={{
                    switch: `plugin-management-item-switch`,
                }}
            />
        </div>
    )
}
