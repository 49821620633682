import { PayPlanType } from '../../../../kernel/interface/type'
import { PayUpgradeDialogContainerProps } from './pay-upgrade-dialog-container/pay-upgrade-dialog-container'
import { PrivilegeCardProps } from './privilege-card/privilege-card'

export enum PayUpgradeDialogFrom {
    Editor = 'editor',
    Space = 'space',
}

export interface ProfessionalSubmitEditorProps {
    from: PayUpgradeDialogFrom.Editor
    teamId: string | undefined
}
export interface ProfessionalSubmitSpaceProps {
    from: PayUpgradeDialogFrom.Space
    teamId: string | undefined
    submit: () => void | (() => Promise<void>)
}
export interface OrganizationSubmitEditorProps {
    from: PayUpgradeDialogFrom.Editor
    teamId: string | undefined
}
export interface OrganizationSubmitSpaceProps {
    from: PayUpgradeDialogFrom.Space
    teamId: string | undefined
    submit: () => void | (() => Promise<void>)
}

export interface StarterSubmitSpaceProps {
    from: PayUpgradeDialogFrom.Space
    submit: () => void
}

export interface PriceComparisonCommonProps {
    dialogProps?: Omit<PayUpgradeDialogContainerProps, 'hide'>
}
export enum PayUpgradeDialogType {
    All = 'all', // 个人 pk 专业 pk 组织
    Professional = 'professional', // 个人 pk 专业
}
export type PriceComparisonAllProps = PriceComparisonCommonProps & {
    type: PayUpgradeDialogType.All
    organizationProps: {
        submitProps: OrganizationSubmitEditorProps | OrganizationSubmitSpaceProps
    }
    professionalProps: {
        submitProps: ProfessionalSubmitEditorProps | ProfessionalSubmitSpaceProps
    }
} & (
        | {
              currentPlanType: PayPlanType.starter
              isCurrentPlanTypeInTrial?: never
              starterProps?: never
          }
        | {
              currentPlanType: PayPlanType.professional
              isCurrentPlanTypeInTrial: boolean
              starterProps: { submitProps: StarterSubmitSpaceProps }
          }
    )
export interface PriceComparisonProfessionalProps extends PriceComparisonCommonProps {
    type: PayUpgradeDialogType.Professional
    title: string
    subtitle: string
    starterProps: { privileges: PrivilegeCardProps['privileges'] }
    professionalProps: {
        privileges: PrivilegeCardProps['privileges']
        submitProps: ProfessionalSubmitEditorProps | ProfessionalSubmitSpaceProps
    }
}

export type PayUpgradeDialogStructProps = PriceComparisonAllProps | PriceComparisonProfessionalProps
