import { useFontManagerService } from '../../../context/document-context'

/**
 * NOTE: 导入的字体名称可能与云端或用户字体不一致，需要根据family统一映射到LocalizedFamily，比如figma导入的文档缺失localizedFamily
 */
export const useFontLocalizedFamily = (family: string, defaultLocalizedFamily: string) => {
    const fontManagerService = useFontManagerService()
    const family2FontInfoMap = fontManagerService.states.use.family2FontInfoMapState()

    return family2FontInfoMap[family]?.localizedFamily ?? (defaultLocalizedFamily || family)
}
