/* eslint-disable no-restricted-imports */
import { Tooltip, useEllipsisTooltip } from '../../../../../../../ui-lib/src'
import { capitalizeFirstLetter, timeDesc3 } from '../../../../../../../util/src'
import { LineIconWrapper } from '../line-icon-wrapper/line-icon-wrapper'
import { UserItem } from '../type'
import { UserList } from '../user-list/user-list'
import classes from './user-item-component.module.less'

export interface UserItemComponentProps {
    item: UserItem
    isFirstItem: boolean
    isLastItem: boolean
    dataTestIds?: {
        description?: string
    }
}

export function UserItemComponent(props: UserItemComponentProps) {
    const { item, isFirstItem, isLastItem, dataTestIds } = props
    const { inactivation, ellipsisRef, onmouseenter, onmouseleave } = useEllipsisTooltip<HTMLDivElement>()

    return (
        <LineIconWrapper
            item={item}
            hideUpperHalf={isFirstItem}
            hideLowerHalf={isLastItem}
            dataTestIds={{
                container: 'history-list-item',
                moreButton: 'history-list-item-more-button',
            }}
        >
            <div className={classes.nameContainer}>
                <Tooltip title={item.name} inactivation={inactivation}>
                    <div
                        className={classes.name}
                        ref={ellipsisRef}
                        onMouseEnter={onmouseenter}
                        onMouseLeave={onmouseleave}
                    >
                        {item.name}
                    </div>
                </Tooltip>
            </div>
            {item.description ? (
                <div className={classes.description} data-testid={dataTestIds?.description}>
                    {item.description}
                </div>
            ) : null}
            <UserList primaryUser={item.primaryUser} secondaryUsers={item.secondaryUsers} />
            <div className={classes.time}>{capitalizeFirstLetter(timeDesc3(item.time))}</div>
        </LineIconWrapper>
    )
}
