export enum AppIds {
    Motiff = 'Motiff',
    MotiffMirror = 'Motiff-Mirror',
    MotiffAbroad = 'Motiff-Abroad',
}

// Note: 大数据平台建议 appID 和 productid 一一对应, 后续添加新 app 需要同步申请新 productId
export const ProductIds = {
    [AppIds.Motiff]: 41000001,
    [AppIds.MotiffMirror]: 41000002,
    [AppIds.MotiffAbroad]: 41000009,
}

export const FrogTokens = {
    [AppIds.Motiff]: '7691f5dcc242422685a370a358be2b0c',
    [AppIds.Motiff + '-test']: '0d59da24400549b09afa72392532aaef',
    [AppIds.MotiffMirror]: '3a6de8c1213746e9856d68b89b59949f',
    [AppIds.MotiffMirror + '-test']: '8da8c369aa4d455d85cc9caa48e2f9f2',
    [AppIds.MotiffAbroad]: 'ff5df5f5f38b4c4aaafac6d03a748ad8',
    [AppIds.MotiffAbroad + '-test']: '0f82bfab074f46f98cc538df221dc0a8',
}

// SDK 中额外定义了 DEV，但是大数据平台最新已经不再单独支持DEV环境，仅有 testing 和 online
export enum FrogEnv {
    TEST,
    ONLINE,
    DEV,
}
