import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    MoveDraftTip: '分享编辑权限需要将草稿移动到团队中。',
} as const

export const enTranslation = {
    MoveDraftTip: `To share editing access to the draft, move it to a team.`,
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
