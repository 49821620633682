import { useCallback, useMemo } from 'react'
import { FormattedInputCustomProps } from '../components/formatted-input'
import { clampMinMax } from './clamp'
import { parseString } from './parse-string'
import { Value } from './type'
import { useUserConfig } from './user-config'

interface BaseFormatterToolProps
    extends Pick<FormattedInputCustomProps<any>, 'bigNudgeAmount' | 'smallNudgeAmount' | 'notUseUserConfig'> {
    min?: number
    max?: number
    allowedUnits?: string
}

export function useBaseFormatterTool(props: BaseFormatterToolProps) {
    const userConfig = useUserConfig()
    const bigNudgeAmount = useMemo(() => {
        if (props?.bigNudgeAmount) {
            return props.bigNudgeAmount
        } else if (!props.notUseUserConfig) {
            return userConfig.bigNudgeAmount
        }
        return 10
    }, [props.bigNudgeAmount, props.notUseUserConfig, userConfig.bigNudgeAmount])

    const smallNudgeAmount = useMemo(() => {
        if (props?.smallNudgeAmount) {
            return props.smallNudgeAmount
        } else if (!props.notUseUserConfig) {
            return userConfig.smallNudgeAmount
        }
        return 1
    }, [props.smallNudgeAmount, props.notUseUserConfig, userConfig.smallNudgeAmount])

    const parse = useCallback(
        (inputValue: string, oldValue?: Value): Value => {
            const regexp = new RegExp(props.allowedUnits ?? '', 'gi')
            inputValue = inputValue.replace(regexp, '')
            if (Array.isArray(oldValue)) {
                const inputValues = inputValue.split(',')
                return oldValue.map((v, index) => parseString(inputValues[index] ?? inputValues[0], v))
            } else {
                return parseString(inputValue, oldValue)
            }
        },
        [props.allowedUnits]
    )

    const format = useCallback((value: Value): string => {
        if (Array.isArray(value)) {
            return value.join(',')
        } else {
            return `${value}`
        }
    }, [])

    const incrementSingle = useCallback(
        (value: number, isIncrement: boolean, shiftKey: boolean): number => {
            if (shiftKey) {
                return isIncrement ? value + bigNudgeAmount : value - bigNudgeAmount
            } else {
                return isIncrement ? value + smallNudgeAmount : value - smallNudgeAmount
            }
        },
        [bigNudgeAmount, smallNudgeAmount]
    )

    const increment = useCallback(
        (value: Value, isIncrement: boolean, shiftKey: boolean): Value => {
            if (Array.isArray(value)) {
                return value.map((v) => incrementSingle(v, isIncrement, shiftKey))
            } else {
                return incrementSingle(value, isIncrement, shiftKey)
            }
        },
        [incrementSingle]
    )

    const clamp = useCallback(
        (value: Value): Value => {
            if (Array.isArray(value)) {
                return value.map((v) => clampMinMax(v, { min: props.min, max: props.max }))
            } else {
                return clampMinMax(value, { min: props.min, max: props.max })
            }
        },
        [props.max, props.min]
    )

    return { parse, format, increment, clamp }
}
