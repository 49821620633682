import { Wukong } from '@wukong/bridge-proto'
import { useMemo } from 'react'
import { Select } from '../../../../../../../../ui-lib/src'
import type { SelectOptionProps } from '../../../../../../../../ui-lib/src/components/selects/picks/single-level/select-option'
import { featureSwitchManager } from '../../../../../../kernel/switch/core'
import { PrototypeTestId } from '../../../../../../window'
import { MIXED_TYPE } from '../../../prototype-interaction/constants'
import classes from '../interaction-action-animation.module.less'
import {
    useAnimationEasingTypeCommand,
    type AnimationTransitionTypeCommandProps,
} from '../use-action-animation-commands'
import { translation } from './animation-easing-type.translation'
import EasingType = Wukong.DocumentProto.EasingType
type EasingTypeWithMixed = EasingType | typeof MIXED_TYPE

export function AnimationEasingTypeSelect(props: {
    state: Wukong.DocumentProto.IEasingTypePropValue
    commandProps: AnimationTransitionTypeCommandProps
}) {
    const command = useAnimationEasingTypeCommand(props.commandProps, props.state)
    const isMixed =
        props.state.valueType ===
        Wukong.DocumentProto.InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_MIXED
    const formatValue: EasingTypeWithMixed = isMixed ? MIXED_TYPE : props.state.value
    const options = useMemo(
        () =>
            [
                EasingType.EASING_TYPE_LINEAR,
                EasingType.EASING_TYPE_IN_CUBIC,
                EasingType.EASING_TYPE_OUT_CUBIC,
                EasingType.EASING_TYPE_INOUT_CUBIC,
                EasingType.EASING_TYPE_IN_BACK_CUBIC,
                EasingType.EASING_TYPE_OUT_BACK_CUBIC,
                EasingType.EASING_TYPE_INOUT_BACK_CUBIC,
                EasingType.EASING_TYPE_CUSTOM_CUBIC,

                ...(featureSwitchManager.isEnabled('prototype-spring')
                    ? [
                          EasingType.EASING_TYPE_GENTLE_SPRING,
                          EasingType.EASING_TYPE_SPRING_PRESET_ONE,
                          EasingType.EASING_TYPE_SPRING_PRESET_TWO,
                          EasingType.EASING_TYPE_SPRING_PRESET_THREE,
                          EasingType.EASING_TYPE_CUSTOM_SPRING,
                      ]
                    : []),
            ].map(getAnimationEasingTypeOption),
        []
    )

    return props.state.show ? (
        <Select.MinimalSingleLevel
            className={classes.easingTypeSelect}
            placement="over"
            width={200}
            isMixed={isMixed}
            onChange={(value) => command.onChange(value, isMixed)}
            value={formatValue}
            label={getAnimationEasingTypeOption(formatValue).label}
            dataTestIds={{
                triggerFocus: PrototypeTestId.InteractionPopup.EasingTypeSelectTrigger,
                container: PrototypeTestId.InteractionPopup.EasingTypeSelectMenu,
            }}
        >
            {options.map((option) => (
                <Select.MinimalSingleLevel.Option
                    {...option}
                    key={option.value}
                    dataTestId={PrototypeTestId.InteractionPopup.EasingTypeSelectOption}
                >
                    {option.label}
                </Select.MinimalSingleLevel.Option>
            ))}
        </Select.MinimalSingleLevel>
    ) : (
        <></>
    )
}

export function getAnimationEasingTypeOption(
    easingType: EasingTypeWithMixed
): Pick<SelectOptionProps, 'splitLineBottom' | 'disabled'> & { value: EasingTypeWithMixed; label: string } {
    if (featureSwitchManager.isEnabled('prototype-spring')) {
        return getAnimationEasingTypeOptionV2(easingType)
    }
    return getAnimationEasingTypeOptionV1(easingType)
}

function getAnimationEasingTypeOptionV1(
    easingType: EasingTypeWithMixed
): Pick<SelectOptionProps, 'splitLineBottom' | 'disabled'> & { value: EasingTypeWithMixed; label: string } {
    switch (easingType) {
        case MIXED_TYPE:
            return { value: easingType, label: translation('Mixed'), splitLineBottom: true, disabled: true }
        case EasingType.EASING_TYPE_LINEAR:
            return { value: easingType, label: translation('Linear'), splitLineBottom: true }
        case EasingType.EASING_TYPE_IN_CUBIC:
            return { value: easingType, label: translation('EaseIn') }
        case EasingType.EASING_TYPE_OUT_CUBIC:
            return { value: easingType, label: translation('EaseOut') }
        case EasingType.EASING_TYPE_INOUT_CUBIC:
            return { value: easingType, label: translation('EaseInAndOut') }
        case EasingType.EASING_TYPE_IN_BACK_CUBIC:
            return { value: easingType, label: translation('EaseInBack') }
        case EasingType.EASING_TYPE_OUT_BACK_CUBIC:
            return { value: easingType, label: translation('EaseOutBack') }
        case EasingType.EASING_TYPE_INOUT_BACK_CUBIC:
            return { value: easingType, label: translation('EaseInAndOutBack') }
        case EasingType.EASING_TYPE_CUSTOM_CUBIC:
            return { value: easingType, label: translation('CustomBezier') }
        default:
            return { value: easingType, label: 'Unknown EasingType' }
    }
}

function getAnimationEasingTypeOptionV2(
    easingType: EasingTypeWithMixed
): Pick<SelectOptionProps, 'splitLineBottom' | 'disabled'> & { value: EasingTypeWithMixed; label: string } {
    switch (easingType) {
        case MIXED_TYPE:
            return { value: easingType, label: translation('Mixed'), splitLineBottom: true, disabled: true }
        case EasingType.EASING_TYPE_LINEAR:
            return { value: easingType, label: translation('Linear'), splitLineBottom: true }
        case EasingType.EASING_TYPE_IN_CUBIC:
            return { value: easingType, label: translation('EaseIn') }
        case EasingType.EASING_TYPE_OUT_CUBIC:
            return { value: easingType, label: translation('EaseOut') }
        case EasingType.EASING_TYPE_INOUT_CUBIC:
            return { value: easingType, label: translation('EaseInAndOut') }
        case EasingType.EASING_TYPE_IN_BACK_CUBIC:
            return { value: easingType, label: translation('EaseInBack') }
        case EasingType.EASING_TYPE_OUT_BACK_CUBIC:
            return { value: easingType, label: translation('EaseOutBack') }
        case EasingType.EASING_TYPE_INOUT_BACK_CUBIC:
            return { value: easingType, label: translation('EaseInAndOutBack') }
        case EasingType.EASING_TYPE_CUSTOM_CUBIC:
            return { value: easingType, label: translation('CustomBezier'), splitLineBottom: true }

        case EasingType.EASING_TYPE_GENTLE_SPRING:
            return { value: easingType, label: translation('Gentle') }
        case EasingType.EASING_TYPE_SPRING_PRESET_ONE:
            return { value: easingType, label: translation('Quick') }
        case EasingType.EASING_TYPE_SPRING_PRESET_TWO:
            return { value: easingType, label: translation('Bouncy') }
        case EasingType.EASING_TYPE_SPRING_PRESET_THREE:
            return { value: easingType, label: translation('Slow') }
        case EasingType.EASING_TYPE_CUSTOM_SPRING:
            return { value: easingType, label: translation('CustomSpring') }
        default:
            return { value: easingType, label: 'Unknown EasingType' }
    }
}
