import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useState, type KeyboardEvent, type KeyboardEventHandler } from 'react'
import { TabElement, Tooltip } from '../../../../../../../../../ui-lib/src'
import { useViewStateBridge } from '../../../../../../../main/app-context'
import { EditorDataTestId } from '../../../../../../../window'
import { useAutoLayoutCommand } from '../command'
import * as icons from '../icons'
import { AlignParam, AutoLayoutState, StackAlign, StackJustify } from '../types'
import style from './align-pad.module.less'
import { translation } from './align-pad.translation'

const fixedSpacingVAlignIcons: JSX.Element[][] = new Array(3).fill([
    icons.svgPadFixedSpacingVAlignLeft,
    icons.svgPadFixedSpacingVAlignCenter,
    icons.svgPadFixedSpacingVAlignRight,
])

const fixedSpacingHAlignIcons: JSX.Element[][] = [
    new Array(3).fill(icons.svgPadFixedSpacingHAlignTop),
    new Array(3).fill(icons.svgPadFixedSpacingHAlignCenter),
    new Array(3).fill(icons.svgPadFixedSpacingHAlignBottom),
]

const fixedSpacingWrapAlignIcons: JSX.Element[][] = new Array(3).fill([
    icons.svgPadFixedSpacingWrapAlignLeft,
    icons.svgPadFixedSpacingWrapAlignCenter,
    icons.svgPadFixedSpacingWrapAlignRight,
])

const autoSpacingVAlignIcons: JSX.Element[][] = [
    [
        icons.svgPadAutoSpacingVAlignLeftTop,
        icons.svgPadAutoSpacingVAlignCenterTop,
        icons.svgPadAutoSpacingVAlignRightTop,
    ],
    [
        icons.svgPadAutoSpacingVAlignLeftCenter,
        icons.svgPadAutoSpacingVAlignCenterCenter,
        icons.svgPadAutoSpacingVAlignRightCenter,
    ],
    [
        icons.svgPadAutoSpacingVAlignLeftBottom,
        icons.svgPadAutoSpacingVAlignCenterBottom,
        icons.svgPadAutoSpacingVAlignRightBottom,
    ],
]

const autoSpacingHAlignIcons: JSX.Element[][] = [
    [
        icons.svgPadAutoSpacingHAlignTopLeft,
        icons.svgPadAutoSpacingHAlignTopCenter,
        icons.svgPadAutoSpacingHAlignTopRight,
    ],
    [
        icons.svgPadAutoSpacingHAlignCenterLeft,
        icons.svgPadAutoSpacingHAlignCenterCenter,
        icons.svgPadAutoSpacingHAlignCenterRight,
    ],
    [
        icons.svgPadAutoSpacingHAlignBottomLeft,
        icons.svgPadAutoSpacingHAlignBottomCenter,
        icons.svgPadAutoSpacingHAlignBottomRight,
    ],
]

const autoSpacingWrapAlignIcons: JSX.Element[][] = new Array(3).fill([
    icons.svgPadAutoSpacingWrapAlignM,
    icons.svgPadAutoSpacingWrapAlignS,
    icons.svgPadAutoSpacingWrapAlignL,
    icons.svgPadAutoSpacingWrapAlignS,
    icons.svgPadAutoSpacingWrapAlignM,
])

const alignPadDots: JSX.Element[] = new Array(9).fill(icons.svgPadBgDot).map((v, i) => (
    <div className={style.alignItem} key={i}>
        {v}
    </div>
))

const AlignPadNineDotsBg = () => {
    return <div className={`${style.alignItems} ${style.alignPadBg}`}>{alignPadDots}</div>
}

export interface AlignItemsProps {
    activeIndex: number[]
    icons: JSX.Element[][]
    disabled?: boolean
    onClickItem: (index: number) => void
    checkHovering?: (checkIndex: number, hoveringIndex: number) => boolean
}

export const alignPadIds = {
    alignToTextBaseline: EditorDataTestId.AutoLayout.AlignPad,
    textItem(index: number) {
        return `baseline-item-${index}`
    },
    cancelBaselineBtn: 'baseline-cancel-btn',
}

const AlignItems = (props: AlignItemsProps) => {
    const defaultCheckHovering = (checkIndex: number, hoveringIndex: number) => checkIndex === hoveringIndex
    const checkHovering = props.checkHovering ?? defaultCheckHovering
    const indexCheck = new Set(props.activeIndex)

    const [hoveringIndex, setHoveringIndex] = useState(-1)
    const hoveringCallback = (index: number) => ({
        onMouseEnter: () => setHoveringIndex(index),
        onMouseLeave: () => setHoveringIndex(-1),
    })

    return (
        <div className={style.alignItems}>
            {props.icons.map((row, i) => {
                return row.map((v, j) => {
                    const index: number = i * 3 + j
                    return indexCheck.has(index) ? (
                        <div className={style.alignItemActive} key={index} {...hoveringCallback(index)}>
                            {v}
                        </div>
                    ) : (
                        <div
                            className={classNames(
                                style.alignItem,
                                checkHovering(index, hoveringIndex) && style.alignItemHover
                            )}
                            key={index}
                            {...hoveringCallback(index)}
                            onClick={() => props.onClickItem(index)}
                        >
                            {v}
                        </div>
                    )
                })
            })}
        </div>
    )
}

const AlignPadVAlignItems = (props: { activeIndex: number[]; onChange: (_: number) => void }) => {
    return <AlignItems icons={fixedSpacingVAlignIcons} activeIndex={props.activeIndex} onClickItem={props.onChange} />
}

const AlignPadHAlignItems = (props: { activeIndex: number[]; onChange: (_: number) => void }) => {
    return <AlignItems icons={fixedSpacingHAlignIcons} activeIndex={props.activeIndex} onClickItem={props.onChange} />
}

const AlignPadWrapItems = (props: { activeIndex: number[]; onChange: (_: number) => void }) => {
    return (
        <AlignItems icons={fixedSpacingWrapAlignIcons} activeIndex={props.activeIndex} onClickItem={props.onChange} />
    )
}

const AlignPadAutoSpacingHAlignItems = (props: { activeIndex: number[]; onChange: (_: number) => void }) => {
    // 自动间距模式下，每一行应该同时响应 hover
    const checkHovering = (checkIndex: number, hoveringIndex: number) => {
        return Math.floor(checkIndex / 3) === Math.floor(hoveringIndex / 3)
    }
    return (
        <AlignItems
            icons={autoSpacingHAlignIcons}
            activeIndex={props.activeIndex}
            checkHovering={checkHovering}
            onClickItem={props.onChange}
        />
    )
}

const AlignPadAutoSpacingVAlignItems = (props: { activeIndex: number[]; onChange: (_: number) => void }) => {
    // 自动间距模式下，每一列应该同时响应 hover
    const checkHovering = (checkIndex: number, hoveringIndex: number) => {
        return checkIndex % 3 === hoveringIndex % 3
    }
    return (
        <AlignItems
            icons={autoSpacingVAlignIcons}
            activeIndex={props.activeIndex}
            checkHovering={checkHovering}
            onClickItem={props.onChange}
        />
    )
}

const AlignPadAutoSpacingWrapAlignItems = (props: { activeIndex: number[]; onChange: (_: number) => void }) => {
    const checkHovering = (checkIndex: number, hoveringIndex: number) => {
        // 自动间距模式下，每一行应该同时响应 hover
        return Math.floor(checkIndex / 5) === Math.floor(hoveringIndex / 5)
    }
    const indexCheck = new Set(props.activeIndex)

    const [hoveringIndex, setHoveringIndex] = useState(-1)
    const hoveringCallback = (index: number) => ({
        onMouseEnter: () => setHoveringIndex(index),
        onMouseLeave: () => setHoveringIndex(-1),
    })

    const positionClassName = (index: number) =>
        index >= 10 ? style.alignItemBottom : index >= 5 ? style.alignItemCenter : style.alignItemTop

    return (
        <div className={style.wrapAlignItems}>
            {autoSpacingWrapAlignIcons.map((row, i) => {
                return row.map((v, j) => {
                    const index: number = i * 5 + j
                    return indexCheck.has(index) ? (
                        <div
                            className={classNames(style.wrapAlignItemActive, positionClassName(index))}
                            key={index}
                            {...hoveringCallback(index)}
                        >
                            {v}
                        </div>
                    ) : (
                        <div
                            className={classNames(
                                style.wrapAlignItem,
                                checkHovering(index, hoveringIndex) && style.wrapAlignItemHover,
                                positionClassName(index)
                            )}
                            key={index}
                            {...hoveringCallback(index)}
                            onClick={() => props.onChange(index)}
                        >
                            {v}
                        </div>
                    )
                })
            })}
        </div>
    )
}

const AlignPadAlignToBaseline = (props: {
    activeIndex: number[]
    isAutoSpacing: boolean
    onChange: (index: number) => void
    onCancel: () => void
    onDoubleClick: () => void
    onKeyDown: KeyboardEventHandler
}) => {
    return (
        <div
            className={style.alignPadBaseline}
            data-testid={alignPadIds.alignToTextBaseline}
            onDoubleClick={props.onDoubleClick}
        >
            <TabElement onKeyDown={props.onKeyDown}></TabElement>
            <div className={style.alignTopRow}>
                {props.isAutoSpacing
                    ? [
                          icons.svgPadAlignToBaseLineLeft,
                          icons.svgPadAlignToBaseLine,
                          icons.svgPadAlignToBaseLineRight,
                      ].map((icon, i) => (
                          <div className={style.alignTopColActive} key={i}>
                              <div className={style.textIcon}>{icon}</div>
                          </div>
                      ))
                    : new Array(3).fill(0).map((_, i) => {
                          return (
                              <div
                                  className={i === props.activeIndex[0] ? style.alignTopColActive : style.alignTopCol}
                                  key={i}
                                  data-testid={alignPadIds.textItem(i)}
                                  onClick={() => props.onChange(i)}
                              >
                                  <div className={style.textIcon}>{icons.svgPadAlignToBaseLine}</div>
                                  <div className={style.dotIcon}>{icons.svgPadAlignToBaseLineBgDot}</div>
                              </div>
                          )
                      })}
            </div>

            <Tooltip title={translation('RemoveBaselineAlignment')}>
                <div
                    className={style.alignBottomRow}
                    onClick={props.onCancel}
                    data-testid={alignPadIds.cancelBaselineBtn}
                ></div>
            </Tooltip>
        </div>
    )
}

export function reverseIndex(i: number) {
    return [0, 3, 6, 1, 4, 7, 2, 5, 8][i] ?? i
}

export function getActiveIndex(state: AutoLayoutState): number[] {
    if (state.flex.isStackModeMixed || state.advance.isAlignToTextBaseline.isMixed) {
        return []
    }

    // 文本基线对齐
    if (state.advance.isAlignToTextBaseline.value) {
        return [
            {
                [StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_MIN]: 0,
                [StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_CENTER]: 1,
                [StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_MAX]: 2,
                [StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_SPACE_EVENTLY]: 3,
            }[state.flex.stackPrimaryAlignItems.value],
        ]
    }

    // 换行+水平自动间距
    if (
        checkAutoSpacing(state) &&
        state.flex.stackMode === Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_WRAP
    ) {
        return {
            [StackAlign.STACK_ALIGN_STACK_ALIGN_MIN]: [0, 1, 2, 3, 4],
            [StackAlign.STACK_ALIGN_STACK_ALIGN_CENTER]: [5, 6, 7, 8, 9],
            [StackAlign.STACK_ALIGN_STACK_ALIGN_MAX]: [10, 11, 12, 13, 14],
            [StackAlign.STACK_ALIGN_STACK_ALIGN_BASE_LINE]: [],
        }[state.flex.stackCounterAlignItems]
    }

    // 默认按纵向排列计算，如果是横向，则需要将 activeIndex 沿对角线翻转
    let acitveIndex: number[] = []
    // 自动间距
    if (checkAutoSpacing(state)) {
        acitveIndex = {
            [StackAlign.STACK_ALIGN_STACK_ALIGN_MIN]: [0, 3, 6],
            [StackAlign.STACK_ALIGN_STACK_ALIGN_CENTER]: [1, 4, 7],
            [StackAlign.STACK_ALIGN_STACK_ALIGN_MAX]: [2, 5, 8],
            [StackAlign.STACK_ALIGN_STACK_ALIGN_BASE_LINE]: [],
        }[state.flex.stackCounterAlignItems]
    } else {
        const heightIndex = {
            [StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_MIN]: 0,
            [StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_CENTER]: 1,
            [StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_MAX]: 2,
            [StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_SPACE_EVENTLY]: 3,
        }[state.flex.stackPrimaryAlignItems.value]
        const widthIndex = {
            [StackAlign.STACK_ALIGN_STACK_ALIGN_MIN]: 0,
            [StackAlign.STACK_ALIGN_STACK_ALIGN_CENTER]: 1,
            [StackAlign.STACK_ALIGN_STACK_ALIGN_MAX]: 2,
            [StackAlign.STACK_ALIGN_STACK_ALIGN_BASE_LINE]: 3,
        }[state.flex.stackCounterAlignItems]

        acitveIndex = [heightIndex * 3 + widthIndex]
    }

    // 沿对角线翻转
    if (
        [
            Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_HORIZONTAL,
            Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_WRAP,
        ].includes(state.flex.stackMode)
    ) {
        acitveIndex = acitveIndex.map((x) => reverseIndex(x))
    }

    return acitveIndex
}

export function mapIndexToAlign(state: AutoLayoutState, index: number) {
    // 如果是纵向排列，则先执行一次翻转，保证后续计算都以横向为准
    if (state.flex.stackMode === Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_VERTICAL) {
        index = reverseIndex(index)
    }

    const count =
        state.flex.stackMode === Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_WRAP &&
        checkAutoSpacing(state)
            ? 5
            : 3

    const widthIndex = index % count // 横向索引
    const heightIndex = Math.floor(index / count) // 纵向索引

    const ret: AlignParam = {
        stackPrimaryAlignItems: [
            StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_MIN,
            StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_CENTER,
            StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_MAX,
        ][widthIndex],
        stackCounterAlignItems: [
            StackAlign.STACK_ALIGN_STACK_ALIGN_MIN,
            StackAlign.STACK_ALIGN_STACK_ALIGN_CENTER,
            StackAlign.STACK_ALIGN_STACK_ALIGN_MAX,
        ][heightIndex],
    }

    if (checkAutoSpacing(state)) {
        delete ret.stackPrimaryAlignItems
    }

    if (state.advance.isAlignToTextBaseline.value && !state.advance.isAlignToTextBaseline.isMixed) {
        delete ret.stackCounterAlignItems
    }

    return ret
}

function useAlignState(state: AutoLayoutState) {
    const command = useAutoLayoutCommand(state)
    const viewStateBridge = useViewStateBridge()
    const isAlignToBaseline = state.advance.isAlignToTextBaseline.value
    const activeIndex = getActiveIndex(state)
    const isAutoSpacing = checkAutoSpacing(state)
    const stackMode = state.flex.stackMode

    return {
        isAlignToBaseline,
        activeIndex,
        isAutoSpacing,
        stackMode,
        onItemChange: (index: number) => {
            command.setAlignMode(mapIndexToAlign(state, index))
        },
        onCancelBaseline: () => {
            command.disableAlignToBaseline()
        },
        toggleWrapLayoutPrimaryAlignItems: () => {
            if (state.flex.isStackModeMixed) {
                return
            }

            command.setStackSpace(isAutoSpacing ? state.flex.stackSpacing.value : 'auto')
        },
        onKeyDown: (e: KeyboardEvent) => {
            const latestState = () => viewStateBridge.getDefaultValue('autoLayout')

            switch (e.key) {
                case 'b':
                    return command.setAdvance('isAlignToTextBaseline', !isAlignToBaseline)
                case 'x':
                    return command.setStackSpace(isAutoSpacing ? state.flex.stackSpacing.value : 'auto')
                default:
                    if (isAlignToBaseline) {
                        if (isAutoSpacing) {
                            switch (e.key) {
                                case 'w':
                                    command.disableAlignToBaseline(true)
                                    command.setAlignMode(mapIndexToAlign(latestState(), 0))
                                    return
                                case 's':
                                    command.disableAlignToBaseline(true)
                                    command.setAlignMode(mapIndexToAlign(latestState(), 6))
                                    return
                                case 'a':
                                    command.setStackSpace(latestState().flex.stackSpacing.value, true)
                                    command.setAlignMode(mapIndexToAlign(latestState(), 0))
                                    return
                                case 'd':
                                    command.setStackSpace(latestState().flex.stackSpacing.value, true)
                                    command.setAlignMode(mapIndexToAlign(latestState(), 2))
                                    return
                                case 'v':
                                    command.disableAlignToBaseline()
                                    return
                                case 'h':
                                    command.setStackSpace(latestState().flex.stackSpacing.value, true)
                                    command.setAlignMode(mapIndexToAlign(latestState(), 1))
                                    return
                                default:
                                    return
                            }
                        } else {
                            switch (e.key) {
                                case 'w':
                                    command.disableAlignToBaseline(true)
                                    command.setStackSpace(latestState().flex.stackSpacing.value, true)
                                    command.setAlignMode(mapIndexToAlign(latestState(), activeIndex[0]))
                                    return
                                case 's':
                                    command.disableAlignToBaseline(true)
                                    command.setStackSpace(latestState().flex.stackSpacing.value, true)
                                    command.setAlignMode(mapIndexToAlign(latestState(), 6 + activeIndex[0]))
                                    return
                                case 'a':
                                    command.setAlignMode(mapIndexToAlign(latestState(), 0))
                                    return
                                case 'd':
                                    command.setAlignMode(mapIndexToAlign(latestState(), 2))
                                    return
                                case 'v':
                                    command.disableAlignToBaseline(true)
                                    command.setStackSpace(latestState().flex.stackSpacing.value, true)
                                    command.setAlignMode(mapIndexToAlign(latestState(), 3 + activeIndex[0]))
                                    return
                                case 'h':
                                    command.setAlignMode(mapIndexToAlign(latestState(), 1))
                                    return
                                case 'ArrowLeft':
                                    e.preventDefault()
                                    if (
                                        state.flex.stackMode !==
                                        Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_VERTICAL
                                    ) {
                                        command.setAlignMode(
                                            mapIndexToAlign(latestState(), Math.max(0, activeIndex[0] - 1))
                                        )
                                    }
                                    return
                                case 'ArrowRight':
                                    e.preventDefault()
                                    if (
                                        state.flex.stackMode !==
                                        Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_VERTICAL
                                    ) {
                                        command.setAlignMode(
                                            mapIndexToAlign(latestState(), Math.min(2, activeIndex[0] + 1))
                                        )
                                    }
                                    return
                            }
                        }
                    } else {
                        if (isAutoSpacing) {
                            switch (e.key) {
                                case 'w':
                                    if (
                                        state.flex.stackMode ===
                                        Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_VERTICAL
                                    ) {
                                        command.setStackSpace(latestState().flex.stackSpacing.value, true)
                                        command.setAlignMode(mapIndexToAlign(latestState(), activeIndex[1] - 3))
                                    } else {
                                        command.setAlignMode(mapIndexToAlign(latestState(), 0))
                                    }
                                    return
                                case 's':
                                    if (
                                        state.flex.stackMode ===
                                        Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_VERTICAL
                                    ) {
                                        command.setStackSpace(latestState().flex.stackSpacing.value, true)
                                        command.setAlignMode(mapIndexToAlign(latestState(), activeIndex[1] + 3))
                                    } else {
                                        command.setAlignMode(mapIndexToAlign(latestState(), 6))
                                    }
                                    return
                                case 'a':
                                    if (
                                        state.flex.stackMode !==
                                        Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_VERTICAL
                                    ) {
                                        command.setStackSpace(latestState().flex.stackSpacing.value, true)
                                    }
                                    command.setAlignMode(mapIndexToAlign(latestState(), 3 * activeIndex[0]))
                                    return
                                case 'd':
                                    if (
                                        state.flex.stackMode !==
                                        Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_VERTICAL
                                    ) {
                                        command.setStackSpace(latestState().flex.stackSpacing.value, true)
                                    }
                                    command.setAlignMode(mapIndexToAlign(latestState(), 3 * activeIndex[0] + 2))
                                    return
                                case 'v':
                                    if (
                                        state.flex.stackMode ===
                                        Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_VERTICAL
                                    ) {
                                        command.setStackSpace(latestState().flex.stackSpacing.value, true)
                                        command.setAlignMode(mapIndexToAlign(latestState(), activeIndex[1]))
                                    } else {
                                        command.setAlignMode(mapIndexToAlign(latestState(), 3))
                                    }
                                    return
                                case 'h':
                                    if (
                                        state.flex.stackMode !==
                                        Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_VERTICAL
                                    ) {
                                        command.setStackSpace(latestState().flex.stackSpacing.value, true)
                                        command.setAlignMode(mapIndexToAlign(latestState(), activeIndex[1]))
                                    } else {
                                        command.setAlignMode(mapIndexToAlign(latestState(), 4))
                                    }
                                    return
                                case 'ArrowUp':
                                    e.preventDefault()
                                    if (
                                        state.flex.stackMode !==
                                        Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_VERTICAL
                                    ) {
                                        command.setAlignMode(
                                            mapIndexToAlign(latestState(), Math.max(0, activeIndex[1] - 3))
                                        )
                                    }
                                    return
                                case 'ArrowDown':
                                    e.preventDefault()
                                    if (
                                        state.flex.stackMode !==
                                        Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_VERTICAL
                                    ) {
                                        command.setAlignMode(
                                            mapIndexToAlign(latestState(), Math.min(6, activeIndex[1] + 3))
                                        )
                                    }
                                    return
                                case 'ArrowLeft':
                                    e.preventDefault()
                                    if (
                                        state.flex.stackMode ===
                                        Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_VERTICAL
                                    ) {
                                        command.setAlignMode(
                                            mapIndexToAlign(latestState(), Math.max(3, activeIndex[1] - 1))
                                        )
                                    }
                                    return
                                case 'ArrowRight':
                                    e.preventDefault()
                                    if (
                                        state.flex.stackMode ===
                                        Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_VERTICAL
                                    ) {
                                        command.setAlignMode(
                                            mapIndexToAlign(latestState(), Math.min(5, activeIndex[1] + 1))
                                        )
                                    }
                                    return
                            }
                        } else {
                            switch (e.key) {
                                case 'w':
                                    command.setAlignMode(mapIndexToAlign(latestState(), activeIndex[0] % 3))
                                    return
                                case 's':
                                    command.setAlignMode(mapIndexToAlign(latestState(), 6 + (activeIndex[0] % 3)))
                                    return
                                case 'a':
                                    command.setAlignMode(
                                        mapIndexToAlign(latestState(), 3 * Math.floor(activeIndex[0] / 3))
                                    )
                                    return
                                case 'd':
                                    command.setAlignMode(
                                        mapIndexToAlign(latestState(), 3 * Math.floor(activeIndex[0] / 3) + 2)
                                    )
                                    return
                                case 'v':
                                    command.setAlignMode(mapIndexToAlign(latestState(), 3 + (activeIndex[0] % 3)))
                                    return
                                case 'h':
                                    command.setAlignMode(
                                        mapIndexToAlign(latestState(), 3 * Math.floor(activeIndex[0] / 3) + 1)
                                    )
                                    return
                                case 'ArrowUp':
                                    e.preventDefault()
                                    if (activeIndex[0] >= 3) {
                                        command.setAlignMode(mapIndexToAlign(latestState(), activeIndex[0] - 3))
                                    }
                                    return
                                case 'ArrowDown':
                                    e.preventDefault()
                                    if (activeIndex[0] <= 5) {
                                        command.setAlignMode(mapIndexToAlign(latestState(), activeIndex[0] + 3))
                                    }
                                    return
                                case 'ArrowLeft':
                                    e.preventDefault()
                                    if (activeIndex[0] % 3 >= 1) {
                                        command.setAlignMode(mapIndexToAlign(latestState(), activeIndex[0] - 1))
                                    }
                                    return
                                case 'ArrowRight':
                                    e.preventDefault()
                                    if (activeIndex[0] % 3 <= 1) {
                                        command.setAlignMode(mapIndexToAlign(latestState(), activeIndex[0] + 1))
                                    }
                                    return
                            }
                        }
                    }
            }
        },
    }
}

export const AlignPad = (props: { state: AutoLayoutState & { disabled?: boolean } }) => {
    const state = useAlignState(props.state)
    const { isAutoSpacing, stackMode, activeIndex, onItemChange, toggleWrapLayoutPrimaryAlignItems, onKeyDown } = state

    if (state.isAlignToBaseline) {
        return (
            <AlignPadAlignToBaseline
                activeIndex={state.activeIndex}
                isAutoSpacing={isAutoSpacing}
                onChange={state.onItemChange}
                onCancel={state.onCancelBaseline}
                onDoubleClick={toggleWrapLayoutPrimaryAlignItems}
                onKeyDown={onKeyDown}
            />
        )
    }

    return (
        <div
            className={classNames(style.alignPad, { [style.disabledPad]: props.state.disabled })}
            data-testid={EditorDataTestId.AutoLayout.AlignPad}
            onDoubleClick={toggleWrapLayoutPrimaryAlignItems}
        >
            <TabElement onKeyDown={onKeyDown}></TabElement>
            <AlignPadNineDotsBg />
            {!isAutoSpacing &&
            stackMode === Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_VERTICAL ? (
                <AlignPadVAlignItems activeIndex={activeIndex} onChange={onItemChange} />
            ) : !isAutoSpacing &&
              stackMode === Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_HORIZONTAL ? (
                <AlignPadHAlignItems activeIndex={activeIndex} onChange={onItemChange} />
            ) : !isAutoSpacing &&
              stackMode === Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_WRAP ? (
                <AlignPadWrapItems activeIndex={activeIndex} onChange={onItemChange} />
            ) : isAutoSpacing &&
              stackMode === Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_VERTICAL ? (
                <AlignPadAutoSpacingVAlignItems activeIndex={activeIndex} onChange={onItemChange} />
            ) : isAutoSpacing &&
              stackMode === Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_WRAP ? (
                <AlignPadAutoSpacingWrapAlignItems activeIndex={activeIndex} onChange={onItemChange} />
            ) : (
                <AlignPadAutoSpacingHAlignItems activeIndex={activeIndex} onChange={onItemChange} />
            )}
        </div>
    )
}

function checkAutoSpacing(state: AutoLayoutState) {
    return (
        !state.flex.stackPrimaryAlignItems.isMixed &&
        state.flex.stackPrimaryAlignItems.value ===
            Wukong.DocumentProto.StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_SPACE_EVENTLY
    )
}
