import { translation } from './effect-style-editor.translation'
/* eslint-disable no-restricted-imports */
import {
    ApplyCreateStyle,
    CancelCreateStyle,
    CreateEffectStyleNodeCommand,
    UpdateEffectStyleNodeDescriptionCommand,
    UpdateEffectStyleNodeEffectsCommand,
    UpdateEffectStyleNodeNameCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { cloneDeep } from 'lodash-es'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ExplicitUndefined } from '../../../../../../../../ui-lib/src'
import { cmdChangePopupState } from '../../../../../../document/command/document-command'
import { cmdChangeAttrPanelStyleEditorState } from '../../../../../../document/command/node-props-command'
import { Effect, PopupState, PopupStateType } from '../../../../../../document/node/node'
import { useViewState } from '../../../../../../view-state-bridge'
import { useCommand } from '../../../../../context/document-context'
import { generateEffectStylePlaceHolderHint } from '../../../../../document-view-state/view-states/effect-style'
import { InputOptionsForUndoSquash } from '../../../../atom/inputs/components/formatted-input'
import { useRenderColorSpace } from '../../../../color-profile'
import { Position } from '../../../../comment/type'
import { getDefaultEffects } from '../../../effects/context'
import { useEditingEffectStyle } from '../../hooks/use-editing-style'
import { EffectStyleData, EffectStylePanel, EffectStylePanelProps } from '../effect-style-panel/effect-style-panel'
import { createCutEffectStyle } from '../utils'

function getEffectStyleNodeInfo(node?: Wukong.DocumentProto.IVEffectStyleNode | null): EffectStyleData {
    const _effectStyleNodeInfo: EffectStyleData = { id: '', name: '', description: '', effects: [], variables: [] }
    if (node) {
        _effectStyleNodeInfo.id = node.id
        _effectStyleNodeInfo.name = node.name
        _effectStyleNodeInfo.description = node.description ?? ''
        _effectStyleNodeInfo.effects = node.effects.slice() as unknown as Effect[]
        _effectStyleNodeInfo.variables = node.variables
        _effectStyleNodeInfo.floatVariables = node.floatVariables
    }
    return _effectStyleNodeInfo
}

export function shouldUpdatePopupPosition(
    effectStyleNode: Wukong.DocumentProto.IVEffectStyleNode | null,
    effects: Effect[]
): boolean {
    return !!effectStyleNode && effectStyleNode.effects.length === effects.length
}

export interface EffectStyleEditorProps
    extends Pick<
        EffectStylePanelProps,
        'visible' | 'position' | 'onCancel' | 'onClickRemoteStyleLink' | 'onClickCreate' | 'hideCommonFooter'
    > {
    isRemoteStyle?: boolean
    createStyle?: {
        isCreate: boolean
        isMixed?: boolean
        effects?: Effect[]
    }
    effectCreateStyle?: (styleId: string) => void
    styleId: string
}

export function EffectStyleEditor(props: EffectStyleEditorProps): JSX.Element {
    const { visible, position, styleId, onCancel, hideCommonFooter, createStyle, effectCreateStyle } = props
    const adding = useRef<boolean>(false)
    const command = useCommand()
    const [styleNodeInfo, setStyleNodeInfo] = useState<EffectStyleData>(getEffectStyleNodeInfo())
    const [selectPaints, setSelectPaints] = useState<Effect[]>([])

    const attrPanelStyleEditorState = useViewState('attrPanelStyleEditorState')
    const popupState = useViewState('popupState')
    const colorSpace = useRenderColorSpace()

    // 修复弹出弹窗时，内容未展示全导致定位错误的问题
    const [popOpen, setPopOpen] = useState<boolean | undefined>()
    const [popPosition, setPopPosition] = useState<Position | undefined>()

    const effectCreateStyleRef = useRef<EffectStyleEditorProps['effectCreateStyle']>()
    effectCreateStyleRef.current = effectCreateStyle

    const selectEffectIndexList = useMemo(() => {
        const length = styleNodeInfo.effects.length - 1
        if (length === -1 || !attrPanelStyleEditorState?.selectReverseIndex) {
            return []
        }
        return attrPanelStyleEditorState.selectReverseIndex
    }, [attrPanelStyleEditorState?.selectReverseIndex, styleNodeInfo.effects.length])

    const effectStyleNode = useEditingEffectStyle()
    const updateStyleEditorState = useCallback(() => {
        const styleNode = effectStyleNode
        if (!styleNode) {
            return
        }
        const _styleNodeInfo = getEffectStyleNodeInfo(styleNode)
        setStyleNodeInfo(_styleNodeInfo)
        if (!attrPanelStyleEditorState) {
            return
        }
        const allPaints = _styleNodeInfo.effects.slice()
        const { selectReverseIndex } = attrPanelStyleEditorState
        if (selectReverseIndex) {
            const _selectPaints = allPaints.filter((_, index) => selectReverseIndex.includes(index))
            setSelectPaints(_selectPaints)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attrPanelStyleEditorState, styleId, effectStyleNode])

    useEffect(() => {
        updateStyleEditorState()
    }, [updateStyleEditorState])

    const onChangeDescription = useCallback(
        (value: string) => {
            command.DEPRECATED_invokeBridge(
                UpdateEffectStyleNodeDescriptionCommand,
                Wukong.DocumentProto.UpdateEffectStyleNodeDescriptionParam.create({
                    styleId,
                    description: value,
                })
            )
            command.commitUndo()
        },
        [command, styleId]
    )

    const onChangeName = useCallback(
        (value: string) => {
            command.DEPRECATED_invokeBridge(
                UpdateEffectStyleNodeNameCommand,
                Wukong.DocumentProto.UpdateEffectStyleNodeNameParam.create({
                    styleId,
                    name: value,
                })
            )
            command.commitUndo()
        },
        [command, styleId]
    )

    const isOpenEffectPanel = useCallback(
        (index: number) => {
            if (!visible) {
                return false
            }
            const multiPopup = popupState?.multiPopup
            if (!multiPopup || multiPopup.length === 0) {
                return false
            }
            const reciprocalIndex = index
            return multiPopup.some(
                (v) =>
                    v.type === PopupStateType.POPUP_STATE_TYPE_EFFECT_STYLE_EDIT &&
                    v.reciprocalIndex === reciprocalIndex
            )
        },
        [popupState?.multiPopup, visible]
    )

    const updatePaints = useCallback(
        (ids: string[], newEffects: Effect[], options?: InputOptionsForUndoSquash) => {
            ids.forEach((id) => {
                command.DEPRECATED_invokeBridge(
                    UpdateEffectStyleNodeEffectsCommand,
                    Wukong.DocumentProto.UpdateEffectStyleNodeEffectsParam.create({
                        styleId: id,
                        effects: newEffects as unknown as Wukong.DocumentProto.IEffect[],
                    })
                )
            })
            command.commitUndo(options?.commitType)
        },
        [command]
    )

    const updatePaintProperties = (obj: { [key: string]: any }, index: number, options?: InputOptionsForUndoSquash) => {
        const effects = styleNodeInfo.effects
        if (!effects || !Array.isArray(effects)) {
            return
        }
        const oldEffectValue = cloneDeep(effects[index])
        if (obj.type !== undefined && obj.type !== oldEffectValue.type) {
            Object.assign(effects[index], getDefaultEffects(), oldEffectValue, { visible: true }, obj)
        } else {
            Object.assign(effects[index], { visible: true }, obj)
        }
        updatePaints([styleId], effects, options)
    }

    const onSelectChange = useCallback(
        (itemsIndex: number[]) => {
            const len = styleNodeInfo.effects.length - 1
            const indexes: number[] = itemsIndex
            command.invoke(cmdChangeAttrPanelStyleEditorState, {
                ...attrPanelStyleEditorState,
                selectReverseIndex: indexes,
                openFromModule: Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_EFFECT,
            })
            command.commitUndo()
        },
        [command, styleNodeInfo.effects, attrPanelStyleEditorState]
    )

    const onDragDone = useCallback(
        (items: Effect[], selectedIndexes: number[]) => {
            command.invoke(cmdChangeAttrPanelStyleEditorState, {
                ...attrPanelStyleEditorState,
                selectReverseIndex: selectedIndexes,
                openFromModule: Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_EFFECT,
            })
            updatePaints([styleId], items)
        },
        [command, styleId, updatePaints, attrPanelStyleEditorState]
    )

    const onClickSub = (index: number) => {
        const newPaints = styleNodeInfo.effects.filter((v, i) => index !== i)
        updatePaints([styleId], newPaints)
    }
    const onClickAddEffect = useCallback(() => {
        adding.current = true
        const effects = styleNodeInfo.effects
        const defaultEffect = Object.assign({}, getDefaultEffects()) as any
        let newEffects: Effect[]
        if (effects.length === 0) {
            newEffects = [defaultEffect]
        } else {
            newEffects = [defaultEffect, ...effects]
        }
        updatePaints([styleId], newEffects)
        adding.current = false
    }, [styleId, styleNodeInfo.effects, updatePaints])

    const onClickTitle = useCallback(() => {
        if (styleNodeInfo.effects.length > 0 || adding.current) {
            return
        }
        onClickAddEffect()
    }, [onClickAddEffect, styleNodeInfo.effects.length])

    const onChangeEffect = (index: number, value: { [key: string]: any }, options?: InputOptionsForUndoSquash) => {
        updatePaintProperties(value, index, options)
    }

    const onChangeVisible = (index: number, v: boolean) => {
        updatePaintProperties({ visible: v }, index)
    }

    const onChangeBlendMode = (index: number, blendMode: Wukong.DocumentProto.BlendMode) => {
        updatePaintProperties({ blendMode }, index)
    }

    const onChangeModalVisible = useCallback(
        (index: number, val: boolean) => {
            const multiPopup = popupState?.multiPopup
            const _popupState: PopupState = {
                type: PopupStateType.POPUP_STATE_TYPE_NONE,
                reciprocalIndex: -1,
                multiPopup: [],
                ...popupState,
            }
            if (!styleNodeInfo) return
            if (val) {
                const openEditor = {
                    type: PopupStateType.POPUP_STATE_TYPE_EFFECT_STYLE_EDIT,
                    reciprocalIndex: index,
                    multiPopup: [],
                }

                command.invoke(cmdChangeAttrPanelStyleEditorState, {
                    ...attrPanelStyleEditorState,
                    selectReverseIndex: [openEditor.reciprocalIndex],
                })

                _popupState.multiPopup = multiPopup
                    ? [
                          ...multiPopup.filter((v) => v.type !== PopupStateType.POPUP_STATE_TYPE_EFFECT_STYLE_EDIT),
                          openEditor,
                      ]
                    : [openEditor]
            } else if (multiPopup) {
                command.invoke(cmdChangeAttrPanelStyleEditorState, {
                    ...attrPanelStyleEditorState,
                    selectReverseIndex: [],
                })
                let needKeep = true
                _popupState.multiPopup = multiPopup.filter((v) => {
                    if (needKeep) {
                        needKeep = v.type !== PopupStateType.POPUP_STATE_TYPE_EFFECT_STYLE_EDIT
                    }
                    return needKeep
                })
            }
            command.commitUndo()
            command.invoke(cmdChangePopupState, _popupState)
        },
        [command, popupState, styleNodeInfo, attrPanelStyleEditorState]
    )

    const onDetachEffectColorVar = (index: number) => {
        updatePaintProperties({ colorVar: null }, index)
    }

    useEffect(() => {
        if (!visible) {
            setPopOpen(false)
            return
        }

        if (shouldUpdatePopupPosition(effectStyleNode, styleNodeInfo.effects)) {
            setPopOpen(true)
            setPopPosition(position)
        }
    }, [styleNodeInfo.effects, visible, position, styleId, effectStyleNode])

    const alreadyApplyCreateRef = useRef<boolean>(false)
    const onCreateStyle = () => {
        alreadyApplyCreateRef.current = true
        if (!effectStyleNode?.name) {
            onChangeName(generateEffectStylePlaceHolderHint(styleNodeInfo.effects))
        }
        command.DEPRECATED_invokeBridge(ApplyCreateStyle, { nodeId: styleId })
        command.commitUndo()
        props.onClickCreate?.()
        command.commitUndo()
    }
    useEffect(() => {
        let effectStyleId: string | null | undefined
        if (createStyle?.isCreate) {
            const effectStyle = command.DEPRECATED_invokeBridge(
                CreateEffectStyleNodeCommand,
                createCutEffectStyle(
                    createStyle.isMixed ? {} : { effects: createStyle.effects }
                ) as unknown as Wukong.DocumentProto.ICreateEffectStyleNodeParam
            )
            effectStyleId = effectStyle.id
            effectCreateStyleRef.current?.(effectStyleId!)
        }
        return () => {
            if (createStyle?.isCreate && !alreadyApplyCreateRef.current && !!effectStyleId) {
                command.DEPRECATED_invokeBridge(CancelCreateStyle, { nodeId: effectStyleId })
                command.commitUndo()
            }
        }
    }, [command, createStyle?.effects, createStyle?.isCreate, createStyle?.isMixed])

    return (
        <EffectStylePanel<ExplicitUndefined<EffectStylePanelProps>>
            openFrom={props.isRemoteStyle ? 'remote' : createStyle?.isCreate ? 'create' : 'normal'}
            onClickRemoteStyleLink={props.onClickRemoteStyleLink}
            onCancel={onCancel}
            hideCommonFooter={hideCommonFooter}
            header={
                props.isRemoteStyle
                    ? translation('ViewEffectStyle')
                    : createStyle?.isCreate
                    ? translation('CreateNewEffect')
                    : translation('EditEffectStyle')
            }
            visible={popOpen}
            position={popPosition}
            effectStyleData={styleNodeInfo}
            colorSpace={colorSpace}
            variables={styleNodeInfo.variables}
            floatVariables={styleNodeInfo.floatVariables}
            onChangeName={onChangeName}
            onChangeDescription={onChangeDescription}
            onClickTitle={onClickTitle}
            onClickAddEffect={onClickAddEffect}
            selectEffectIndexList={selectEffectIndexList}
            onSelectChange={onSelectChange}
            onDragDone={onDragDone}
            isOpenEffectPanel={isOpenEffectPanel}
            onChangeEffect={onChangeEffect}
            onChangeModalVisible={onChangeModalVisible}
            onChangeBlendMode={onChangeBlendMode}
            onChangeVisible={onChangeVisible}
            onClickSub={onClickSub}
            onClickCreate={onCreateStyle}
            onDetachEffectColorVar={onDetachEffectColorVar}
        ></EffectStylePanel>
    )
}
