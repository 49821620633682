import { DEFAULT_ICON_CONFIGS, IIconConfig } from '@icon-park/react/es/runtime'
import { translation } from './config.translation'

export interface IconData {
    id: number
    title: string
    name: string
    category: string
    categoryCN: string
    author: string
    tag: string[]
}

export const LAZY_LOAD_COUNT = 100

export enum IconStyleTheme {
    OUTLINE = 'outline', // {translation('Outline')}
    FILL = 'filled', // {translation('Filled')}
    TWO_TONE = 'two-tone', // {translation('Two-tone')}
    MULTI_COLOR = 'multi-color', // {translation('Multi-color')}
}

export enum IconStyleThemeCN {
    OUTLINE,
    FILL,
    TWO_TONE,
    MULTI_COLOR,
}

export const IconStyleTheme_EN_CN: Map<IconStyleTheme, string> = new Map([
    [IconStyleTheme.OUTLINE, translation('Outline')],
    [IconStyleTheme.FILL, translation('Filled')],
    [IconStyleTheme.TWO_TONE, translation('Two-tone')],
    [IconStyleTheme.MULTI_COLOR, translation('Multi-color')],
])

export const IconStyleTheme_CN_EN: Map<string, IconStyleTheme> = new Map([
    [translation('Outline'), IconStyleTheme.OUTLINE],
    [translation('Filled'), IconStyleTheme.FILL],
    [translation('Two-tone'), IconStyleTheme.TWO_TONE],
    [translation('Multi-color'), IconStyleTheme.MULTI_COLOR],
])

export enum IconColorType {
    OUTLINE = 'outline', // {translation('Outline')}
    FILL = 'filled', // {translation('Filled')}
    TWO_TONE = 'twoTone', // {translation('Two-tone')}
    MULTI_COLOR = 'multiColor', // {translation('Multi-color')}
}
export interface IconColorStyle {
    theme: IconStyleTheme
    colors: {
        [IconColorType.OUTLINE]: {
            fill: string
            background: string
        }
        [IconColorType.FILL]: {
            fill: string
            background: string
        }
        [IconColorType.TWO_TONE]: {
            fill: string
            twoTone: string
        }
        [IconColorType.MULTI_COLOR]: {
            outStrokeColor: string
            outFillColor: string
            innerStrokeColor: string
            innerFillColor: string
        }
    }
}

export const defaultIconColorStyle: IconColorStyle = {
    theme: IconStyleTheme.OUTLINE,
    colors: {
        [IconColorType.OUTLINE]: {
            fill: '#333333',
            background: '#ffffff',
        },
        [IconColorType.FILL]: {
            fill: '#333333',
            background: '#ffffff',
        },
        [IconColorType.TWO_TONE]: {
            fill: '#333333',
            twoTone: '#2F88FF',
        },
        [IconColorType.MULTI_COLOR]: {
            outStrokeColor: '#333333',
            outFillColor: '#2F88FF',
            innerStrokeColor: '#FFFFFF',
            innerFillColor: '#43CCF8',
        },
    },
}

// 端点类型
export enum StrokeLinecap {
    BUTT = 'butt',
    ROUND = 'round',
    SQUARE = 'square',
}

// 拐点（交点）类型
export enum StrokeLinejoin {
    MITER = 'miter',
    ROUND = 'round',
    BEVEL = 'bevel',
}

export const iconSizeValues = [12, 16, 20, 24, 28, 32, 36, 40, 44, 48]
export const strokeWidthValues = [1, 2, 3, 4]

export const iconSizeDefaultIndex = 3
export const strokeWidthDefaultIndex = 3
export const iconStyleDefaultIndex = 0

export const defaultIconConfig: IIconConfig = {
    ...DEFAULT_ICON_CONFIGS,
    // prefix: 'icon',
    size: iconSizeValues[iconSizeDefaultIndex],
    strokeWidth: strokeWidthValues[strokeWidthDefaultIndex],
    theme: defaultIconColorStyle.theme,
    colors: defaultIconColorStyle.colors,
}

export const categoryOrder = new Map<string, number>([
    ['Base', 1],
    ['Safe', 2],
    ['Office', 3],
    ['Edit', 4],
    ['Emoji', 5],
    ['Measurement', 6],
    ['Abstract', 7],
    ['Money', 8],
    ['Animals', 9],
    ['Music', 10],
    ['Clothes', 11],
    ['Character', 12],
    ['Industry', 13],
    ['Makeups', 14],
    ['Graphics', 15],
    ['Build', 16],
    ['Arrows', 17],
    ['Communicate', 18],
    ['Travel', 19],
    ['Components', 20],
    ['Connect', 21],
    ['Operate', 22],
    ['Baby', 23],
    ['Energy', 24],
    ['Brand', 25],
    ['Life', 26],
    ['Time', 27],
    ['Foods', 28],
    ['Hands', 29],
    ['Datas', 30],
    ['Charts', 31],
    ['Sports', 32],
    ['Weather', 33],
    ['Constellation', 34],
    ['Health', 35],
    ['Hardware', 36],
    ['Peoples', 37],
    ['Game', 38],
    ['Others', 39],
])
