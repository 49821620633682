import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    LogOut: '用户登出',
} as const

export const enTranslation = {
    LogOut: 'Log out',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
