import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Search: '搜索',
    Sort: '排序',
    Filter: '筛选',
    SortByDate: '按时间排序',
    SortByUnread: '按未读排序',
    ShowResolvedComments: '显示已解决',
    OnlyYourThreads: '仅显示与你相关',
    OnlyCurrentPage: '仅显示当前页面',
    Settings: '设置',
    HideComments: '隐藏评论',
    NotifyMeAbout: '通知设置',
    Everything: '全部通知',
    JustMentionsAnd: '与我相关的通知',
    Nothing: '不接收通知',
} as const

export const enTranslation = {
    Search: 'Search',
    Sort: 'Sort',
    Filter: 'Filter',
    SortByDate: 'Sort by date',
    SortByUnread: 'Sort by unread',
    ShowResolvedComments: 'Show resolved comments',
    OnlyYourThreads: 'Only your threads',
    OnlyCurrentPage: 'Only current page',
    Settings: 'Settings',
    HideComments: 'Hide comments',
    NotifyMeAbout: 'Notify me about',
    Everything: 'Everything',
    JustMentionsAnd: 'Just mentions and replies',
    Nothing: 'Nothing',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
