import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    CopiedToClipboard: '已复制到剪贴板',
    Content: '内容',
} as const

export const enTranslation = {
    CopiedToClipboard: 'Copied to clipboard',
    Content: 'Content',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
