import { translation } from './hyperlink-switch-item.translation'
import { StartHyperlinkEditing, Wukong } from '@wukong/bridge-proto'
import { MouseEventHandler } from 'react'
import { IconHyperlink } from '../../../../../../ui-lib/src'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { Switch } from '../../atom/switch/switch'
import style from './hyperlink-switch-item.module.less'
import { frogEventToolsMenusAndActionsBasicAction } from '../../../utils/tools-actions-frog'
import { ShortcutKey, shortcutLabelMap } from '../../../../kernel/interface/shortcut-key'
const SelectionHyperlinkType = Wukong.DocumentProto.SelectionHyperlinkType

export function HyperlinkSwitchItem() {
    const selectionHyperlinkState = useViewState('selectionHyperlinkState')
    const command = useCommand()

    const createOrUpdateHyperlink: MouseEventHandler<HTMLElement> = (event) => {
        event.preventDefault()
        event.stopPropagation()
        command.DEPRECATED_invokeBridge(StartHyperlinkEditing)
    }

    if (!selectionHyperlinkState) {
        return null
    }

    if (selectionHyperlinkState.type == SelectionHyperlinkType.SELECTION_HYPERLINK_TYPE_DISABLE) {
        return null
    }

    const isActive = selectionHyperlinkState.type == SelectionHyperlinkType.SELECTION_HYPERLINK_TYPE_SAME_LINK

    return (
        <Switch.Item
            className={`${style.switchItem44} ${isActive ? style.hyperlinkActive : ''}`}
            onMouseDown={(e) => {
                createOrUpdateHyperlink(e)
                frogEventToolsMenusAndActionsBasicAction({
                    action_name: '创建/编辑文字链',
                    operation_way: '上下文工具',
                })
            }}
            tooltipTitle={translation('CreateLink')}
            tooltipShortcut={shortcutLabelMap[ShortcutKey.Hyperlink]}
            useTabFocus={true}
            onKeyboardClick={createOrUpdateHyperlink}
            data-testid="hyperlink-icon"
        >
            <IconHyperlink />
        </Switch.Item>
    )
}
