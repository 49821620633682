import { Wukong } from '@wukong/bridge-proto'
import { encode } from 'base62'
import { getInitialDocSchemaVersion } from '../../../../util/src'
import { translation } from './create-file.translation'

/**
 * 生成默认的文档数据，经过 proto encode，用于模拟 server 新建文档
 */
export const generateDefaultDocument = (schemaVersion?: number): Uint8Array => {
    const document: Wukong.DocumentProto.ISynergyDocument = {
        nodes: [
            {
                nodeId: '0:0',
                partialNode: Wukong.DocumentProto.PartialNode.create({
                    id: '0:0',
                    type: Wukong.DocumentProto.NodeType.NODE_TYPE_DOCUMENT,
                }),
                fields: [1, 5],
            },
            {
                nodeId: '0:1',
                partialNode: Wukong.DocumentProto.PartialNode.create({
                    id: '0:1',
                    type: Wukong.DocumentProto.NodeType.NODE_TYPE_PAGE,
                    name: `${translation('Page')} 1`,
                    parentInfo: Wukong.DocumentProto.ParentInfo.create({
                        parentId: '0:0',
                        orderIndex: Uint8Array.from([128]),
                    }),
                }),
                fields: [1, 2, 5, 7],
            },
            {
                nodeId: '0:2',
                partialNode: Wukong.DocumentProto.PartialNode.create({
                    id: '0:2',
                    type: Wukong.DocumentProto.NodeType.NODE_TYPE_STYLE_CONTAINER,
                }),
                fields: [1, 5],
            },
        ],
        schemaVersion: schemaVersion ?? getInitialDocSchemaVersion(),
    }

    return Wukong.DocumentProto.SynergyDocument.encode(document).finish()
}

/**
 * 在前端生成 doucmentId
 * 算法为 128 位的 base62 的 reverse + 上一位 base62 的随机字符
 */
export const generateDocumentId = () => {
    // 一个 128 位的随机数
    let randomStr = ''
    for (let i = 0; i < 128; i++) {
        randomStr += Math.round(Math.random())
    }
    // 获得 22 位的 base64 并翻转
    let base62 = encode(parseInt(randomStr, 2)).split('').reverse().join('')

    if (base62.length > 22) {
        base62 = base62.substring(0, 22)
    }

    const BASE_62_CHARSET = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
    ]

    // 以创建的 base62 + 随机 charset 组成 23 位的 docId
    while (base62.length != 23) {
        base62 += BASE_62_CHARSET[Math.floor(Math.random() * (BASE_62_CHARSET.length - 1))]
    }

    return base62
}
