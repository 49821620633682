import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    NoProjects: '还没有任何项目',
    EditedTo: '修改于',
    RecognitionComplete: '识别完成',
    Detected: '共检测',
    '10kLayers': '万个图层',
    Draft: '草稿',
    View: '立即查看',
    RecognitionFailureDue: '网络异常导致识别失败',
    PleaseTryAgain: '请稍后再试',
    TheFileIs: '文件过大',
    PleaseSplitIt: '请拆分成 40 万图层以下的文件再试',
    CurrentServerBusy: '当前服务器繁忙',
    ExcessiveNumberOf: '文件数量过多',
    PleaseReduceTo: '请减少至',
    TryAgain: '个以下再试',
    YouHaveAn: '你有正在进行的识别任务',
    PleaseTryAgain_synonyms1: '请在任务结束后再试',
    NoMobileInterface: '未检测到界面',
    PleaseSelectThe: '请重新选择文件后再试',
    GotIt: '我知道了',
    Recognizing: '正在识别',
    Cancel: '取消识别',
    SelectFiles: '选择识别文件',
    ZjTSOj: '已选择',
    FilesSelected: '个文件',
    Cancel_synonyms1: '取消',
    Start: '开始识别',
    CheckAll: '全选',
    NoFiles: '还没有任何文件',
    Title: 'AI 设计系统创建',
    DocSchemaVersionNotSame: '文档数据升级中，请稍后再试',
    Others: '其他',
} as const

export const enTranslation = {
    NoProjects: 'No projects',
    EditedTo: 'Edited',
    RecognitionComplete: 'Recognition Complete',
    Detected: 'Detected',
    '10kLayers': 'layers',
    Draft: 'Drafts',
    View: 'View now',
    RecognitionFailureDue: 'Recognition failure due to network error',
    PleaseTryAgain: 'Try again later',
    TheFileIs: 'The file is too large,',
    PleaseSplitIt: 'split it into smaller files less than 400k layers and try again.',
    CurrentServerBusy: 'Current server busy',
    ExcessiveNumberOf: 'Excessive number of files',
    PleaseReduceTo: 'Reduce to less than',
    TryAgain: 'try again',
    YouHaveAn: 'You have an ongoing recognition task,',
    PleaseTryAgain_synonyms1: 'Try again after task completion',
    NoMobileInterface: 'No interface is detected,',
    PleaseSelectThe: 'reselect the file and try again.',
    GotIt: 'OK',
    Recognizing: 'Recognizing',
    Cancel: 'Cancel',
    SelectFiles: 'Select files',
    ZjTSOj: '',
    FilesSelected: '{{file}} selected',
    Cancel_synonyms1: ' Cancel',
    Start: 'Start recognizing',
    CheckAll: 'Select all',
    NoFiles: 'No files',
    Title: 'AI Design System Creator',
    DocSchemaVersionNotSame: 'Document data is being upgraded. Try again later.',
    Others: 'Others',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
