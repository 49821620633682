import { SwitchEditorTypeCommand } from '@wukong/bridge-proto'
import { MonoIconCommonClose16, WKButton, WKIconButton } from '../../../../../../ui-lib/src'
import { OnboardingScene, OnboardingStatus } from '../../../../kernel/interface/onboarding'
import { onboardingService } from '../../../../main/service/onboarding-service'
import devModeGuide from '../../../assets/layer-panel/readonly-down-grade-guide-3x.png'
import { useCommand } from '../../../context/document-context'
import styles from './index.module.less'
import { translation } from './index.translation'
export function InspectDevModeGuide() {
    const handleBoardingEnd = () => {
        onboardingService.end(OnboardingScene.DemotionDevMode, OnboardingStatus.Finished)
    }
    const command = useCommand()
    const handleClickGetDevMode = () => {
        command.DEPRECATED_invokeBridge(SwitchEditorTypeCommand)
        handleBoardingEnd()
    }

    return (
        <div className={styles.readonlyGuide} data-testid={'doc-readonly-guide'}>
            <div className={styles.title}>
                <span className={styles.titleText}>{translation('StartUseDevMode')}</span>
                <WKIconButton
                    data-testid="doc-readonly-guide-close-icon"
                    className={styles.iconButton}
                    onClick={handleBoardingEnd}
                    icon={<MonoIconCommonClose16 />}
                ></WKIconButton>
            </div>
            <img className={styles.image} src={devModeGuide} width="248px" height="198px"></img>
            <div className="p-4">
                <div className={styles.description}>{translation('DevModeDescription')}</div>
                <WKButton
                    type="primary"
                    className={styles.button}
                    onClick={handleClickGetDevMode}
                    data-testid="doc-readonly-get-dev-mode"
                >
                    {translation('GetDevMode')}
                </WKButton>
            </div>
        </div>
    )
}
