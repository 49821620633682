import classnames from 'classnames'
import React, { forwardRef, HTMLAttributes, ReactNode } from 'react'
import { TabElement } from '../tab-element'
import styles from './icon-button.module.less'

export interface WKIconButtonProps extends HTMLAttributes<HTMLButtonElement> {
    type?: 'primary' | 'secondary' | 'border' | 'lightBlue' | 'deepBlue' | 'white'
    size?: 'small' | 'medium' | 'large'
    disabled?: boolean
    icon: ReactNode
    htmlType?: 'submit' | 'reset' | 'button'
    preventFocus?: boolean // 阻止默认focus
}

export const WKIconButton = forwardRef(function Component(
    props: WKIconButtonProps,
    ref?: React.Ref<HTMLButtonElement>
) {
    const {
        htmlType,
        disabled,
        className,
        type = 'secondary',
        size = 'medium',
        icon,
        preventFocus,
        onMouseDown,
        ...rest
    } = props
    const sizeClassname = styles[size]
    const typeClassname = styles[type]
    const _onMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (preventFocus) {
            e.preventDefault()
        }
        onMouseDown?.(e)
    }
    return (
        <button
            ref={ref}
            type={htmlType}
            disabled={disabled}
            className={classnames(styles.button, sizeClassname, typeClassname, className)}
            tabIndex={-1}
            onMouseDown={_onMouseDown}
            {...rest}
        >
            <TabElement disabled={disabled}></TabElement>
            {icon}
        </button>
    )
})
