import { FeatureSwitchConfig, SwitchEnvironmentScopeType, SwitchStrategyType } from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'float-variable',
    owner: '',
    description: '',
    strategies: [
        {
            env: SwitchEnvironmentScopeType.TESTING,
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                userList: [
                    '67001c05d873b3128d9f09cb7faa91c12c9d4aef',
                    'ba359f8f7cabb59bea6a1c75fceeffdef1f40827',
                    '37b4208d9301d2607fcb9a638988a7b569fa92f5',
                    'fa41bf23ad54135e8310a19dcc012fbfb70d29f2',
                    '4d0c1e32c00069c6a39541d2dcf2a0d7f7322487',
                ],
            },
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
