import { translation } from './more-menu.translation'
/* eslint-disable no-restricted-imports */
import { useCallback } from 'react'
import { DropdownV2, PopupProps, WKToast } from '../../../../../../ui-lib/src'
import { useCopyCheckService } from '../../../../main/app-context'
import {
    useApplyVersion,
    useClearVersionInfo,
    useCopyVersion,
    useCopyVersionLink,
    useEditVersionInfo,
    useIsReadonly,
} from './history-list-provider'
import { AutoItem, ItemType, UserItem } from './type'

export interface MoreMenuProps {
    item: UserItem | AutoItem
    isOpen: PopupProps['isOpen']
    rectContainer?: PopupProps['rectContainer'] // rectContainer 和  handle需传一个
    onClose: () => void
}

enum MoreMenuType {
    EditVersionInfo = 'editVersionInfo',
    ClearVersionInfo = 'clearVersionInfo',
    ApplyVersion = 'applyVersion',
    CopyVersion = 'copyVersion',
    CopyVersionLink = 'copyVersionLink',
}

export function MoreMenu(props: MoreMenuProps) {
    const { item, isOpen, rectContainer, onClose } = props

    const isReadonly = useIsReadonly()
    const editVersionInfo = useEditVersionInfo()
    const clearVersionInfo = useClearVersionInfo()
    const applyVersion = useApplyVersion()
    const copyVersion = useCopyVersion()
    const copyVersionLink = useCopyVersionLink()

    const copyCheckService = useCopyCheckService()
    const copyHasBeenProhibited = copyCheckService.useZustandStore.use.copyHasBeenProhibited()

    const onEnterChange = useCallback(
        (value: MoreMenuType) => {
            switch (value) {
                case MoreMenuType.EditVersionInfo: {
                    editVersionInfo(item)
                    break
                }
                case MoreMenuType.ClearVersionInfo: {
                    clearVersionInfo(item)
                    break
                }
                case MoreMenuType.ApplyVersion: {
                    applyVersion(item)
                    break
                }
                case MoreMenuType.CopyVersion: {
                    if (copyHasBeenProhibited) {
                        WKToast.show(`${translation('ProhibitCopy')}`)
                    } else {
                        copyVersion(item)
                    }
                    break
                }
                case MoreMenuType.CopyVersionLink: {
                    copyVersionLink(item)
                    break
                }
            }
        },
        [applyVersion, clearVersionInfo, copyVersion, copyVersionLink, editVersionInfo, item, copyHasBeenProhibited]
    )

    return (
        <DropdownV2.NoTriggerSingleLevel
            onChange={onEnterChange}
            onClose={onClose}
            isOpenState={isOpen}
            placement="bottom left"
            triggerRect={rectContainer!}
        >
            {isReadonly ? null : (
                <DropdownV2.NoTriggerSingleLevel.Option
                    value={MoreMenuType.EditVersionInfo}
                    dataTestId="history-more-menu-item"
                >
                    {translation('EditVersionInfo')}
                </DropdownV2.NoTriggerSingleLevel.Option>
            )}
            {isReadonly ? null : (
                <DropdownV2.NoTriggerSingleLevel.Option
                    value={MoreMenuType.ApplyVersion}
                    dataTestId="history-more-menu-item"
                >
                    {translation('RestoreThisVersion')}
                </DropdownV2.NoTriggerSingleLevel.Option>
            )}
            <DropdownV2.NoTriggerSingleLevel.Option
                value={MoreMenuType.CopyVersion}
                dataTestId="history-more-menu-item"
            >
                {translation('Duplicate')}
            </DropdownV2.NoTriggerSingleLevel.Option>
            {isReadonly ? null : (
                <DropdownV2.NoTriggerSingleLevel.Option
                    value={MoreMenuType.ClearVersionInfo}
                    dataTestId="history-more-menu-item"
                    disabled={item.type === ItemType.AutoItem}
                >
                    {translation('DeleteVersionInfo')}
                </DropdownV2.NoTriggerSingleLevel.Option>
            )}
            <DropdownV2.NoTriggerSingleLevel.Option
                value={MoreMenuType.CopyVersionLink}
                dataTestId="history-more-menu-item"
            >
                {translation('CopyLink')}
            </DropdownV2.NoTriggerSingleLevel.Option>
        </DropdownV2.NoTriggerSingleLevel>
    )
}
