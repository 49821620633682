export enum CrashFrogType {
    Export = 'userExportCrash', // 导出崩溃
    PublishOrUpdateLibrary = 'userPublishOrUpdateLibraryCrash', // 发布或更新组件库崩溃
}

export enum BenchmarkType {
    // mirror
    MirrorFirstShowFrame = 'benchmarkMirrorFirstShowFrame', //  Mirror 模式用户第一次展示 frame 的时间
    MirrorSwitchFrame = 'benchmarkMirrorSwitchFrame', // Mirror 模式用户切换 frame 的时间

    // font
    FontDownloadSuccessRate = 'benchmarkFontDownloadSuccessRate', // 字体下载的成功率
    FontLoadTimeAfterDocLoaded = 'benchmarkFontLoadTimeAfterDocLoaded', // 文档加载后出现的字体加载时间
    FontDownloadTimeInBootstrap = 'benchmarkFontDownloadTimeInBootstrap', // 文档开始加载时下载字体的时间

    // Image
    ImageDownloadTimeInBootstrap = 'benchmarkImageDownloadTimeInBootstrap', // 文档开始加载时图片的下载时间
    ImageDownloadSuccessRatio = 'MetricImageDownloadSuccessRatio', // 文档下载图片的成功率

    // Import
    ImportSuccessRatio = 'benchmarkImportSuccessRatio', // 导入成功率

    // Render
    RenderOnDomTreeDirty = 'renderOnDomTreeDirty', // Dom 树被标脏后的渲染时间
    RenderOnViewportScaleChanged = 'renderOnViewportScaleChanged', // 画布缩放操作后的渲染时间
    RenderOnViewportTranslationChanged = 'renderOnViewportTranslationChanged', // 画布平移操作后的渲染时间
    RenderOnOther = 'renderOnOther', // 其他操作后的渲染时间

    // Document
    ArchiveSyncDuration = 'ArchiveSyncDuration', // 文档传输时间
    OnDocumentDuration = 'onDocumentDuration', // 文档加载时间
}
