/* eslint-disable no-restricted-imports */
import { useEffect, useState } from 'react'
import { createRoot, Root } from 'react-dom/client'
import { WK } from '../../../../app/src/window'
import { ReactComponent as IconLoading } from '../../icons/loading.svg'
import { createHTMLDivElement, generateKey } from '../../utils/utils'
import { areaUnion } from '../component-union/position-union'
import { ToastStruct, ToastStructProps } from './toast-struct'
import classes from './toast.module.less'

export interface ToastProps extends Omit<ToastStructProps, 'closeToast' | 'showWithAnimation'> {
    delay?: 500 | 2000 // 延迟打开的时间 单位ms
}

export interface NormalToastProps extends Omit<ToastProps, 'type'> {}
export interface ErrorToastProps extends Omit<ToastProps, 'type'> {}
export interface LoadingToastProps extends Omit<ToastProps, 'type' | 'icon'> {}

export type ToastKey = string

class ToastPropsStore {
    private key2PropsMap = new Map<ToastKey, ToastProps>()
    private key2ListenerMap = new Map<ToastKey, (props: ToastProps) => void>()

    public sub = (key: ToastKey, listener: (props: ToastProps) => void) => {
        this.key2ListenerMap.set(key, listener)
        this.notify(key)
    }

    public unsub = (key: ToastKey) => {
        this.key2ListenerMap.delete(key)
    }

    public setOrUpdateProps = (key: ToastKey, props: ToastProps) => {
        this.key2PropsMap.set(key, props)
        this.notify(key)
    }

    public getProps = (key: ToastKey) => {
        return this.key2PropsMap.get(key)
    }

    public clear = (key: ToastKey) => {
        this.key2PropsMap.delete(key)
        this.unsub(key)
    }

    private notify = (key: ToastKey) => {
        const listener = this.key2ListenerMap.get(key)
        if (!listener) {
            return
        }

        const props = this.getProps(key)
        if (props !== undefined) {
            listener(props)
        }
    }
}

/**
 * 为了支持Toast属性的更新, 需要在ToastStructPropsProvider中订阅ToastPropsStore的变化
 */

const ToastContextContainer = (props: {
    toastPropsStore: ToastPropsStore
    toastKey: ToastKey
    closeToast: () => void
    showWithAnimation: boolean
}) => {
    const { toastPropsStore, toastKey, closeToast, showWithAnimation } = props
    const [toastProps, setToastProps] = useState<ToastProps>()
    useEffect(() => {
        toastPropsStore.sub(toastKey, setToastProps)
        return () => toastPropsStore.unsub(toastKey)
    }, [toastKey, toastPropsStore])

    if (!toastProps) {
        return null
    }

    return <ToastStruct key={toastKey} {...toastProps} closeToast={closeToast} showWithAnimation={showWithAnimation} />
}

class GenerateToast {
    private disableToast = false
    private toastContainerId = 'toastContainerId'
    private toastMap: Map<ToastKey, { reactRoot: Root; htmlRoot: HTMLElement; timer?: NodeJS.Timeout }> = new Map()
    private toastPropsStore = new ToastPropsStore()

    private createToast = () => {
        const htmlRoot = createHTMLDivElement({ class: classes.toastRoot })
        const toastKey = generateKey()
        const reactRoot = createRoot(htmlRoot)
        return { reactRoot, htmlRoot, toastKey }
    }

    private getToastContainer = () => {
        let toastContainer = document.getElementById(this.toastContainerId)
        if (toastContainer) {
            return toastContainer
        }
        toastContainer = createHTMLDivElement({ id: this.toastContainerId, class: classes.toastContainer })
        // 这里使用toast|notification|help联合的容器作为toast放置空间，如果之后要孤立toast只需要替换个定位容器即可
        areaUnion.getToastArea().append(toastContainer)
        return toastContainer
    }

    private renderToast = (props: ToastProps) => {
        if (this.disableToast) {
            return ''
        }
        const toastContainer = this.getToastContainer()
        const { reactRoot, htmlRoot, toastKey } = this.createToast()

        // NOTE: render Toast之前将props设置到Store中，因为ToastStructPropsProvider会根据props的值来渲染
        this.toastPropsStore.setOrUpdateProps(toastKey, props)
        const immediateShowToast = () => {
            toastContainer.appendChild(htmlRoot)
            reactRoot.render(
                <ToastContextContainer
                    toastPropsStore={this.toastPropsStore}
                    toastKey={toastKey}
                    closeToast={() => this.close(toastKey)}
                    showWithAnimation={this.toastMap.size === 0}
                />
            )
        }

        let timer: NodeJS.Timeout | undefined
        if (props.delay) {
            timer = setTimeout(immediateShowToast, props.delay)
        } else {
            immediateShowToast()
        }

        this.toastMap.set(toastKey, { reactRoot, htmlRoot, timer })
        return toastKey
    }

    public show = (message: NormalToastProps['message'], otherProps?: Omit<NormalToastProps, 'message'>) => {
        return this.renderToast({ message, ...otherProps, type: 'normal' })
    }

    public error = (message: NormalToastProps['message'], otherProps?: Omit<ErrorToastProps, 'message'>) => {
        return this.renderToast({ message, ...otherProps, type: 'error' })
    }

    public updateMessage = (toastKey: ToastKey, message: ToastProps['message']) => {
        const props = this.toastPropsStore.getProps(toastKey)
        if (!props) {
            return
        }
        this.toastPropsStore.setOrUpdateProps(toastKey, { ...props, message })
    }

    public loading = (message: NormalToastProps['message'], otherProps?: Omit<LoadingToastProps, 'message'>) => {
        return this.renderToast({
            message,
            ...otherProps,
            type: 'normal',
            icon: <IconLoading data-testid="icon-loading-svg" className="animate-spin fill-white translate-z-0" />,
        })
    }

    public close(toastKey: ToastKey) {
        const toast = this.toastMap.get(toastKey)
        if (!toast) {
            return
        }
        clearTimeout(toast.timer)
        toast.reactRoot.unmount()
        toast.htmlRoot.remove()
        this.toastMap.delete(toastKey)
        this.toastPropsStore.clear(toastKey)
    }

    public closeAll() {
        for (const [toastKey] of this.toastMap) {
            this.close(toastKey)
        }
    }

    public disable = () => {
        this.closeAll()
        this.disableToast = true
    }

    public enable = () => {
        this.disableToast = false
    }

    public injectGlobal() {
        WK.disableToast = this.disable
        WK.enableToast = this.enable
    }

    public ejectGlobal() {
        delete WK.disableToast
        delete WK.enableToast
    }
}

export const toast = new GenerateToast()
