/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { OmitToJson, ProtoType } from '../../../../../../view-state-bridge'

import DocumentProto = Wukong.DocumentProto

import AutoLayoutDisplayType = DocumentProto.AutoLayoutDisplayType
import AutoLayoutEditType = DocumentProto.AutoLayoutEditType
import AutoLayoutWHType = DocumentProto.AutoLayoutWHType
import AutoLayoutWHValue = DocumentProto.AutoLayoutWHValue

import StackMode = DocumentProto.StackMode
import StackAlign = DocumentProto.StackAlign
import StackJustify = DocumentProto.StackJustify
import StackPositioning = DocumentProto.StackPositioning
import StackSize = DocumentProto.StackSize
import StackCounterAlign = DocumentProto.StackCounterAlign

import AutoLayoutPanelState = DocumentProto.AutoLayoutPanelState
import AutoLayoutSpacing = DocumentProto.Spacing
import AutoLayoutHoverItem = DocumentProto.AutoLayoutHoverMenuItem
import AutoLayoutSpaceInputType = DocumentProto.FloatSpaceInputType

export type UpdateAutoLayoutHoverItemParam = OmitToJson<DocumentProto.IUpdateAutoLayoutHoverItemParam>

export type AutoLayoutUpdatedParam = OmitToJson<DocumentProto.IAutoLayoutUpdatedParam>
export type BatchAutoLayoutUpdatedParam = OmitToJson<DocumentProto.IBatchAutoLayoutUpdatedParam>
export type AutoLayoutChildUpdatedParam = OmitToJson<DocumentProto.IAutoLayoutChildUpdatedParam>
export type BatchAutoLayoutChildUpdatedParam = OmitToJson<DocumentProto.IBatchAutoLayoutChildUpdatedParam>

export type AlignParam = Pick<AutoLayoutUpdatedParam, 'stackPrimaryAlignItems' | 'stackCounterAlignItems'>

export type AutoLayoutState = ProtoType<AutoLayoutPanelState>

export type Padding = AutoLayoutState['flex']['padding']['vertical']
export type PaddingKey = keyof AutoLayoutState['flex']['padding']
export type InputValue = number | number[]
export type PaddingPair = [PaddingKey, InputValue]

export type AdvanceState = AutoLayoutState['advance']
export type ValidAdvanceItem = keyof Omit<AdvanceState, 'show'>

export enum MapPaddingKey {
    bottom = 'stackPaddingBottom',
    right = 'stackPaddingRight',
    horizontal = 'stackHorizontalPadding',
    vertical = 'stackVerticalPadding',
}

export type AutoLayoutVisualDirection = 'w' | 'h'
export type AutoLayoutLogicDirection = 'primary' | 'counter'
export type ValidStackMode = Exclude<StackMode, StackMode.STACK_MODE_NONE>

export {
    AutoLayoutDisplayType,
    AutoLayoutEditType,
    AutoLayoutHoverItem,
    AutoLayoutPanelState,
    AutoLayoutSpaceInputType,
    AutoLayoutSpacing,
    AutoLayoutWHType,
    AutoLayoutWHValue,
    StackAlign,
    StackCounterAlign,
    StackJustify,
    StackMode,
    StackPositioning,
    StackSize,
}
