import {
    CurrentPageSetSelectionCommandWasmCall,
    DevModeZoomToFitSelectionWasmCall,
    ZoomToSelectionWasmCall,
} from '@wukong/bridge-proto'
import constate from 'constate'
import { useCallback, useEffect, useRef } from 'react'
import { useUnmount } from 'react-use'
import { MonoIconCommonBackToInstance16, WKToast } from '../../../../../../ui-lib/src'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { translation } from './hook.translation'

interface InteractionDevContextProps {
    isDevMode: boolean
}

const useHook = (param: InteractionDevContextProps) => {
    const command = useCommand()
    const toastId = useRef<string | undefined>()
    const moveTargetId = useRef<string | undefined>()
    const singleSelectNode = useViewState('singleSelection')

    useEffect(() => {
        if (singleSelectNode?.id && moveTargetId.current != singleSelectNode?.id) {
            setTimeout(() => toastId.current && WKToast.close(toastId.current))
        }
        if (singleSelectNode?.length == 0) {
            WKToast.closeAll()
        }
    }, [singleSelectNode?.id, singleSelectNode?.length, toastId])

    useUnmount(() => {
        setTimeout(() => toastId.current && WKToast.close(toastId.current))
    })

    const move = useCallback(
        (id: string) => {
            moveTargetId.current = id
            command.DEPRECATED_invokeBridge(CurrentPageSetSelectionCommandWasmCall, { selection: [id] })
            if (param.isDevMode) {
                command.DEPRECATED_invokeBridge(DevModeZoomToFitSelectionWasmCall)
            } else {
                command.DEPRECATED_invokeBridge(ZoomToSelectionWasmCall)
            }
        },
        [command, param.isDevMode]
    )

    const showToast = useCallback(() => {
        const id = WKToast.show(`${translation('Return')} ${singleSelectNode?.name}`, {
            icon: <MonoIconCommonBackToInstance16 className="text-white" />,
            onClose: () => {
                toastId.current = undefined
            },
            duration: -1,
            firstButton: {
                type: 'x',
                onClick: () => {
                    WKToast.close(id)
                },
            },
            onClick: () => {
                move(singleSelectNode?.id ?? '')
                WKToast.close(id)
            },
        })
        toastId.current && WKToast.close(toastId.current)
        toastId.current = id
    }, [move, singleSelectNode?.id, singleSelectNode?.name])

    return { move, showToast }
}

export const [InteractionDevProvider, useInteractionDevContext] = constate(useHook)
