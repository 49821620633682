import { translation } from './unknown-select-group.translation'
/* eslint-disable no-restricted-imports */
import { useCallback, useMemo, useState } from 'react'
import { DropdownV2, RectContainer } from '../../../../../../../../ui-lib/src'
import { useComponentService } from '../../../../../../main/app-context'
import { EffectStyleDetail } from '../../../../../document-view-state/view-states/effect-style'

import { LibraryStyleTestId } from '../../../../../../window'
import { CommonStyleInfo } from '../../../styles/get-style-nodes-info-map'
import style from '../../../styles/style-color/style-color.module.less'
import { StyleListItem, StyleListItemKeyboardProps } from '../../../styles/style-items-layout/style-list-item'
import { EffectStyleEditor } from '../../../styles/style-panel/effect-style-editor/effect-style-editor'
import { useEffectContext } from '../../context'
import { EffectStyleIcon } from '../effect-style-icon'
import { useEffectStyleEditInsideSelect, useEffectStyleSelectHandler } from './use-effect-style-select'

export function UnknownEffectStyleListItem(
    props: { docId?: string; item: EffectStyleDetail } & StyleListItemKeyboardProps
) {
    const { item } = props
    const { closeEffectStyleSelectModal } = useEffectContext()
    const { onSelect } = useEffectStyleSelectHandler()
    const { onEdit, onEndEdit, opened, openedId, position } = useEffectStyleEditInsideSelect()
    const [contextMenuInfo, setContextMenuInfo] = useState<RectContainer | null>(null)
    const componentService = useComponentService()

    const onSelectStyle = () => {
        onSelect(item.effectStyle.id)
        closeEffectStyleSelectModal()
    }

    const onOperateStyle = (_: CommonStyleInfo, e: any) => {
        const { clientX, clientY } = e
        setContextMenuInfo({
            top: clientY,
            bottom: clientY,
            right: clientX,
            left: clientX,
        })
    }

    const onEditStyle = async (_: CommonStyleInfo, containerDomRect: DOMRect) => {
        onEdit(item.effectStyle.id, containerDomRect)
    }

    const onClosehandler = () => {
        setContextMenuInfo(null)
    }

    const jumpToDoc = useCallback(() => {
        const docId = props.docId || item.effectStyle.publishFile
        componentService.jumpToOriginDocument({
            docId: docId,
            nodeId: item.effectStyle.publishId,
            fromFig: item.effectStyle.fromFig,
            name: item.effectStyle.name,
        })
    }, [
        props.docId,
        item.effectStyle.publishFile,
        item.effectStyle.publishId,
        item.effectStyle.fromFig,
        item.effectStyle.name,
        componentService,
    ])

    const onClickJump2Document = (e: any) => {
        e.stopPropagation()
        componentService.jumpToOriginDocument({
            docId: props.docId || item.effectStyle.publishFile,
            nodeId: item.effectStyle.publishId,
            fromFig: item.effectStyle.fromFig,
            name: item.effectStyle.name,
        })
        setContextMenuInfo(null)
    }

    const listItem = useMemo(() => {
        const {
            styleName,
            groupName,
            effectStyle: { id, name, description },
        } = item
        return {
            styleName,
            groupName,
            id,
            name: name || translation('UntitledStyle'),
            description: description || null,
        }
    }, [item])

    return (
        <>
            <StyleListItem
                item={listItem}
                isReadonly
                openStyleId={opened && openedId === item.effectStyle.id && position ? openedId : undefined}
                onSelectItem={onSelectStyle}
                onClickHoverIcon={onEditStyle}
                onContextMenu={onOperateStyle}
                dataTestId={`${LibraryStyleTestId.UnknownEffectStyleItem}-${item.effectStyle.id}`}
                index={props.index}
                selectStyleId={props.selectStyleId}
                setPreselectIndex={props.setPreselectIndex}
                trySetPreselectIndex={props.trySetPreselectIndex}
                recordEnterCallback={props.recordEnterCallback}
            >
                <EffectStyleIcon className={style.thumbnail} iconType={props.item.iconType} />
                <div className={style.name}>{props.item.styleName}</div>
            </StyleListItem>
            {contextMenuInfo ? (
                <DropdownV2.NoTriggerSingleLevel
                    isOpenState={true}
                    triggerRect={contextMenuInfo}
                    onClose={onClosehandler}
                    onChange={(_, e) => onClickJump2Document(e)}
                >
                    <DropdownV2.NoTriggerSingleLevel.Option value={translation('GoTo')}>
                        {translation('GoTo')}
                    </DropdownV2.NoTriggerSingleLevel.Option>
                </DropdownV2.NoTriggerSingleLevel>
            ) : null}
            {position ? (
                <EffectStyleEditor
                    isRemoteStyle={true}
                    styleId={item.effectStyle.id}
                    visible={opened && openedId === item.effectStyle.id}
                    onCancel={onEndEdit}
                    position={position}
                    onClickRemoteStyleLink={jumpToDoc}
                />
            ) : null}
        </>
    )
}
