import { anySignal } from '../abort-controller/abort-any'
import { TraceableAbortController, TraceableAbortSignal } from '../abort-controller/traceable-abort-controller'
import { transaction } from '../abort-controller/traceable-transaction'

/**
 * @experimental 用于替换ReplaySubject
 */
export class ReplayEventEmitter<T> {
    protected readonly cache: T[] = []
    private handler: Array<(_: T) => void> = []
    private bufferSize: number

    constructor(bufferSize = 0) {
        this.bufferSize = bufferSize
    }

    public next(value: T) {
        this.appendCache(value)

        // NOTE: 使用 slice 避免在遍历过程中修改 handler
        this.handler.slice().forEach((fn) => fn(value))
    }

    private appendCache(value: T) {
        if (this.bufferSize > 0) {
            this.cache.push(value)

            if (this.cache.length > this.bufferSize) {
                this.cache.shift()
            }
        }
    }

    private addHandler(fn: (value: T) => void) {
        this.handler.push(fn)
    }

    private removeHandler(fn: (value: T) => void) {
        this.handler = this.handler.filter((h) => h !== fn)
    }

    public onWithSignal(signal: TraceableAbortSignal, fn: (value: T) => void) {
        const { act } = transaction(signal)

        act('onWithSignal', () => {
            // NOTE: 使用 slice 避免在遍历过程中修改 cache
            for (const value of this.cache.slice()) {
                if (signal.aborted) {
                    break
                }
                fn(value)
            }
            if (!signal.aborted) {
                this.addHandler(fn)
            }

            return () => {
                this.removeHandler(fn)
            }
        })
    }

    /**
     * NOTE: 当bufferSize > 1时, 返回第一个值
     */
    public onceWithSignal(signal: TraceableAbortSignal, fn: (value: T) => void) {
        const firstController = new TraceableAbortController('onceWithSignal')
        this.onWithSignal(anySignal([firstController.signal, signal]), (value: T) => {
            fn(value)
            firstController.abort('abort')
        })
    }

    /**
     * NOTE: 当bufferSize > 1时, 返回第一个值
     */
    public async getFirstValue(signal: TraceableAbortSignal): Promise<T> {
        return new Promise((resolve, reject) => {
            const abortHandler = (e: Event) => reject(e)
            signal.addEventListener('abort', abortHandler)

            this.onceWithSignal(signal, (value) => {
                resolve(value)

                // NOTE: 及时移除 abort 事件监听, 避免内存泄漏
                signal.removeEventListener('abort', abortHandler)
            })
        })
    }

    /**
     * NOTE: 当bufferSize > 1时, 返回第一个满足条件的值
     */
    public async getFirstValueWithFilter(signal: TraceableAbortSignal, filter: (value: T) => boolean): Promise<T> {
        return new Promise((resolve, reject) => {
            const abortHandler = (e: Event) => reject(e)
            signal.addEventListener('abort', abortHandler)

            const firstController = new TraceableAbortController('getFirstValueWithFilter')
            this.onWithSignal(anySignal([firstController.signal, signal]), (value) => {
                if (filter(value)) {
                    resolve(value)
                    firstController.abort('abort')

                    // NOTE: 及时移除 abort 事件监听, 避免内存泄漏
                    signal.removeEventListener('abort', abortHandler)
                }
            })
        })
    }
}
