/* eslint-disable no-restricted-imports */
// 获取小于等于当前权限的列表
import { RoleStatus } from '../../../kernel/interface/type'
import { getRoleStatusOrder } from '../../../kernel/interface/user-roles'

export const getLERoles = (role: RoleStatus) => {
    const roleStatusOrder = getRoleStatusOrder()
    const idx = roleStatusOrder.indexOf(role)
    return [...roleStatusOrder].slice(0, idx + 1)
}
