import { splitGrapheme } from '../../../../../util/src'

function isAscii(grapheme: string) {
    return (grapheme.codePointAt(0) ?? 0) < 128
}

export function getGraphemeIndexByUnicodeIndex(graphemes: string[], unicodeIndex: number) {
    let unicodeCount = 0
    for (let graphemeIndex = 0, len = graphemes.length; graphemeIndex < len; ++graphemeIndex) {
        if (unicodeCount >= unicodeIndex) {
            return graphemeIndex
        }
        unicodeCount += [...graphemes[graphemeIndex]].length
    }

    return graphemes.length
}

/**
 * 向前查找N个中文或2N个英文字符
 * 1中文 = 2英文字符
 */
export function getHeadEndByCharCount(graphemes: string[], start: number, end: number, charCount: number): number {
    const len = Math.min(graphemes.length, end)
    for (let i = start, current = 0; i < len; i++) {
        current += isAscii(graphemes[i]) ? 1 : 2
        if (current >= charCount) {
            return i + 1
        }
    }

    return end
}

/**
 * 向前查找2N个中文或N个英文单词
 * 1英文单词 = 2中文
 */
export function getHeadBeforeStartByWordCount(graphemes: string[], start: number, wordCount: number): number {
    let current = 0
    let hasEnChar = false
    for (let i = start - 1; i > 0; --i) {
        if (!isAscii(graphemes[i])) {
            if (hasEnChar) {
                current++
                hasEnChar = false
            }
            current++
        } else if (/[a-zA-Z0-9]/.test(graphemes[i])) {
            hasEnChar = true
        } else {
            current += hasEnChar ? 2 : 1
            hasEnChar = false
        }

        if (current >= wordCount) {
            return i
        }
    }

    return 0
}

export function formatForStartEllipsis(content: string, start: number, end: number, needHeadEllipsis: boolean) {
    if (start == end) {
        return {
            isStartEllipsis: false,
            left: content,
            middleHead: '',
            middleHeadLastChar: '',
            middleTail: '',
            right: '',
        }
    }

    // start/end是unicode的索引, 拆分时需要转换成字素索引避免多个unicode的字符(emoji)被拆开
    const graphemes = splitGrapheme(content).map(({ segment }) => segment)
    const graphemeStart = getGraphemeIndexByUnicodeIndex(graphemes, start)
    const graphemeEnd = getGraphemeIndexByUnicodeIndex(graphemes, end)
    if (needHeadEllipsis) {
        const startEllipsis = getHeadBeforeStartByWordCount(graphemes, graphemeStart, 6)
        return {
            isStartEllipsis: startEllipsis > 0,
            left: graphemes.slice(startEllipsis, graphemeStart).join(''),
            middleHead: '',
            middleHeadLastChar: '',
            middleTail: graphemes.slice(graphemeStart, graphemeEnd).join(''),
            right: graphemes.slice(graphemeEnd).join(''),
        }
    }

    const graphemeHeadEnd = getHeadEndByCharCount(graphemes, graphemeStart, graphemeEnd, 4)
    return {
        isStartEllipsis: false,
        left: graphemes.slice(0, graphemeStart).join(''),
        middleHead: graphemes.slice(graphemeStart, graphemeHeadEnd - 1).join(''),
        middleHeadLastChar: graphemes.slice(graphemeHeadEnd - 1, graphemeHeadEnd).join(''),
        middleTail: graphemes.slice(graphemeHeadEnd, graphemeEnd).join(''),
        right: graphemes.slice(graphemeEnd).join(''),
    }
}
