import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    RecentlyUsed: '最近使用',
    Mixed: '多个值',
    NoMatchingFonts: '无搜索结果',
} as const

export const enTranslation = {
    RecentlyUsed: 'Recently used',
    Mixed: 'Mixed',
    NoMatchingFonts: 'No results found',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
