import { ReactRootRender } from '../react-root-render/react-root-render'
import { PayUpgradeDialogStruct } from './pay-upgrade-dialog-struct/pay-upgrade-dialog-struct'
import { PayUpgradeDialogStructProps } from './pay-upgrade-dialog-struct/type'

export type PayUpgradeDialogProps = PayUpgradeDialogStructProps

export const PayUpgradeDialog = new ReactRootRender<PayUpgradeDialogProps>((props, state) => {
    const { dialogProps, ...otherProps } = props
    const onCancel = () => {
        dialogProps?.onCancel?.()
        state.close()
    }
    const onOk = () => {
        dialogProps?.onOk?.()
        state.close()
    }
    return <PayUpgradeDialogStruct {...otherProps} dialogProps={{ ...dialogProps, onCancel, onOk }} />
})
