import { translation } from './stroke-align.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { Select } from '../../../../../../../ui-lib/src'
import { EditorDataTestId } from '../../../../../window'
import { useStrokeAlign } from './use-stroke-align'

// 描边-对齐位置
export function StrokeAlign() {
    const { alignState, onChangeStrokeAlign } = useStrokeAlign()

    return alignState && alignState.type !== Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_EMPTY ? (
        <Select.NormalSingleLevel
            value={alignState.value}
            isMixed={alignState.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED}
            onChange={onChangeStrokeAlign}
            label={strokeAlign2Label.get(alignState.value)}
            dataTestIds={{
                triggerContainer: EditorDataTestId.StrokeAttr.SelectAlign,
                triggerFocus: EditorDataTestId.StrokeAttr.SelectAlignTrigger,
            }}
        >
            {[...strokeAlign2Label.keys()].map((key) => (
                <Select.NormalSingleLevel.Option
                    value={key}
                    key={key}
                    backwardIcon={''}
                    data-testid={EditorDataTestId.StrokeAttr.SelectAlignPrefix(key)}
                >
                    {strokeAlign2Label.get(key)}
                </Select.NormalSingleLevel.Option>
            ))}
        </Select.NormalSingleLevel>
    ) : (
        <></>
    )
}

const strokeAlign2Label = new Map<Wukong.DocumentProto.StrokeAlign, string>([
    [Wukong.DocumentProto.StrokeAlign.STROKE_ALIGN_CENTER, translation('Center')],
    [Wukong.DocumentProto.StrokeAlign.STROKE_ALIGN_INSIDE, translation('Inside')],
    [Wukong.DocumentProto.StrokeAlign.STROKE_ALIGN_OUTSIDE, translation('Outside')],
])
