import { useState } from 'react'
import { Select, Switch, WKTypography } from '../../../../../../../ui-lib/src'
import { useUserInfoContext } from '../../../../../auth'
import { PreferenceComment, PreferenceEmail, PreferenceInviteRequest } from '../../../../../kernel/interface/account'
import { UpdateEmailPreference } from '../../../../../kernel/request/user'
import classes from './email-config.module.less'
import { translation } from './email-config.translation'

function getNameFromCommentConfig(e: PreferenceComment) {
    switch (e) {
        case PreferenceComment.All:
            return translation('all_cmr')
        case PreferenceComment.Relative:
            return translation('just_y')
        case PreferenceComment.None:
            return translation('none')
    }
}
function getNameFromInvitationApprovalConfig(e: PreferenceInviteRequest) {
    switch (e) {
        case PreferenceInviteRequest.All:
            return translation('all_ir')
        case PreferenceInviteRequest.Approval:
            return translation('only_i')
        case PreferenceInviteRequest.None:
            return translation('none')
    }
}

export function GlobalEmailConfig() {
    const { userInfo, updateUserInfo } = useUserInfoContext()
    const [isLoading, setIsLoading] = useState(false)
    const [enabledEmailConfig, setEnabledEmailConfig] = useState(!!userInfo.enableEmailNotification)
    const [commentConfig, setCommentConfig] = useState<PreferenceComment>(
        userInfo.commentPreference ?? PreferenceComment.All
    )
    const [invitationApprovalConfig, setInvitationApprovalConfig] = useState<PreferenceInviteRequest>(
        userInfo.inviteAndRequestPreference ?? PreferenceInviteRequest.All
    )

    const updatePreference = (v: Partial<PreferenceEmail>) => {
        return new UpdateEmailPreference({
            enableEmailNotification: enabledEmailConfig,
            commentPreference: commentConfig,
            inviteAndRequestPreference: invitationApprovalConfig,
            ...v,
        })
            .start()
            .then(() => updateUserInfo())
    }

    const switchEmailConfig = (toBe: boolean) => {
        setIsLoading(true)
        setEnabledEmailConfig(toBe)
        updatePreference({ enableEmailNotification: toBe }).finally(() => {
            setIsLoading(false)
        })
    }

    const onChangeComment = (v: PreferenceComment) => {
        setCommentConfig(v)
        updatePreference({ commentPreference: v })
    }

    const onChangeInvitationApproval = (v: PreferenceInviteRequest) => {
        setInvitationApprovalConfig(v)
        updatePreference({ inviteAndRequestPreference: v })
    }

    return (
        <div className={classes.container}>
            <div className={classes.title}>
                <div className={classes.titleContent}>
                    <WKTypography.Text weight="medium" data-testid="email-config-name">
                        {translation('email_n')}
                    </WKTypography.Text>
                    {enabledEmailConfig ? null : (
                        <WKTypography.Text color="placeholder" data-testid="email-config-description">
                            {translation('disabled')}
                        </WKTypography.Text>
                    )}
                </div>
                <div className={classes.switch}>
                    <Switch
                        checked={enabledEmailConfig}
                        loading={isLoading}
                        onChange={switchEmailConfig}
                        dataTestIds={{ switch: 'email-config-switch' }}
                    />
                </div>
            </div>
            {enabledEmailConfig ? (
                <div className={classes.content}>
                    <div className={classes.item}>
                        <span className={classes.itemName} data-testid="email-config-item1-name">
                            {translation('file_c')}
                        </span>
                        <Select.NormalSingleLevel
                            className={classes.itemContent}
                            placement="bottom right"
                            onChange={onChangeComment}
                            value={commentConfig}
                            label={getNameFromCommentConfig(commentConfig)}
                            dataTestIds={{
                                container: 'email-config-comment',
                                triggerFocus: 'email-config-comment-trigger',
                            }}
                        >
                            <Select.NormalSingleLevel.Option value={PreferenceComment.All}>
                                {getNameFromCommentConfig(PreferenceComment.All)}
                            </Select.NormalSingleLevel.Option>
                            <Select.NormalSingleLevel.Option value={PreferenceComment.Relative}>
                                {getNameFromCommentConfig(PreferenceComment.Relative)}
                            </Select.NormalSingleLevel.Option>
                            <Select.NormalSingleLevel.Option value={PreferenceComment.None}>
                                {getNameFromCommentConfig(PreferenceComment.None)}
                            </Select.NormalSingleLevel.Option>
                        </Select.NormalSingleLevel>
                    </div>
                    <div className={classes.item}>
                        <span className={classes.itemName} data-testid="email-config-item2-name">
                            {translation('ir')}
                        </span>
                        <Select.NormalSingleLevel
                            className={classes.itemContent}
                            placement="bottom right"
                            onChange={onChangeInvitationApproval}
                            value={invitationApprovalConfig}
                            label={getNameFromInvitationApprovalConfig(invitationApprovalConfig)}
                            dataTestIds={{
                                container: 'email-config-invitation-approval',
                                triggerFocus: 'email-config-invitation-approval-trigger',
                            }}
                        >
                            <Select.NormalSingleLevel.Option value={PreferenceInviteRequest.All}>
                                {getNameFromInvitationApprovalConfig(PreferenceInviteRequest.All)}
                            </Select.NormalSingleLevel.Option>
                            <Select.NormalSingleLevel.Option value={PreferenceInviteRequest.Approval}>
                                {getNameFromInvitationApprovalConfig(PreferenceInviteRequest.Approval)}
                            </Select.NormalSingleLevel.Option>
                            <Select.NormalSingleLevel.Option value={PreferenceInviteRequest.None}>
                                {getNameFromInvitationApprovalConfig(PreferenceInviteRequest.None)}
                            </Select.NormalSingleLevel.Option>
                        </Select.NormalSingleLevel>
                    </div>
                </div>
            ) : null}
        </div>
    )
}
