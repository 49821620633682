/* eslint-disable no-restricted-imports */
import { ExportDocumentCommand, WriteExportDocumentResultCommand, Wukong } from '@wukong/bridge-proto'
import { downloadBuffer } from '../../../../util/src'
import { sleep } from '../../../../util/src/time'
import { CommandInvoker } from '../../document/command/command-invoker'
import { CommitType } from '../../document/command/commit-type'
import { environment } from '../../environment'
import { EmBridge } from '../../kernel/bridge/em-bridge'
import { WkCLog } from '../../kernel/clog/wukong/instance'
import { featureSwitchManager } from '../../kernel/switch'
import { ImageService } from './image-service'

export type UpdateProgressHandler = (progress: number, error: boolean) => void

// 「导出为」Service
// - 导出为 Sketch
export class ExporterService {
    private _docName = ''
    private _updateProgress: UpdateProgressHandler | null = null
    private _currentBufferList: Uint8Array[] = []

    constructor(private commandInvoker: CommandInvoker, private bridge: EmBridge, private imageService: ImageService) {
        bridge.bind(WriteExportDocumentResultCommand, (arg) => {
            if (arg.ptr || arg.size) {
                this.mergeIntoCurrent(arg)
            } else {
                const exportedDocument = this.buildExportedDocumentAndReset()
                const updateProgressHandler = this._updateProgress
                this._updateProgress = null
                if (!updateProgressHandler) {
                    throw Error('update progress handler is null')
                }

                import('../../exporter').then(({ exportAsSketchToZip: exportAsSketchToZipV2, exportAsFigmaToZip }) => {
                    const dispatcher =
                        arg.type === Wukong.DocumentProto.ExportDocumentType.EXPORT_DOCUMENT_TYPE_SKETCH
                            ? exportAsSketchToZipV2
                            : exportAsFigmaToZip
                    const ext =
                        arg.type === Wukong.DocumentProto.ExportDocumentType.EXPORT_DOCUMENT_TYPE_SKETCH
                            ? '.sketch'
                            : '.fig'
                    WkCLog.throttleLog('WK_EXPORT_DOCUMENT_AS', {
                        as: ext,
                    })

                    dispatcher(
                        {
                            env: environment,
                            downloadImage: (imageHash) =>
                                this.imageService
                                    .downloadImage(imageHash)
                                    .then((blob) => blob.arrayBuffer().then((buf) => new Uint8Array(buf))),
                            updateProgress: (progress) => updateProgressHandler(progress, false),
                            fetchDelegate: (...args) => fetch(...args),
                        },
                        exportedDocument
                    )
                        .then((buf) => {
                            downloadBuffer(buf, `${this._docName}${ext}`)
                            // 标记完成
                            updateProgressHandler(100, false)
                        })
                        .catch((err) => {
                            console.error(err)
                            updateProgressHandler(0, true)
                        })
                })
            }
        })
    }

    public async exportAsSketch(params: { showToast: () => void; updateProgressHandler: UpdateProgressHandler }) {
        params.showToast()

        const { prepareExportAsSketchToZip } = await import('../../exporter')
        await prepareExportAsSketchToZip()

        // 延时 500ms，让菜单先关闭
        await sleep(500)

        this._updateProgress = params.updateProgressHandler
        this.reset()
        this.bridge.call(ExportDocumentCommand, {
            type: Wukong.DocumentProto.ExportDocumentType.EXPORT_DOCUMENT_TYPE_SKETCH,
        })
        this._updateProgress = null
    }

    public async exportAsFigma(params: { showToast: () => void; updateProgressHandler: UpdateProgressHandler }) {
        if (!featureSwitchManager.isEnabled('export-ano')) {
            return
        }

        params.showToast()

        // 延时 500ms，让菜单先关闭
        await sleep(500)

        this._updateProgress = params.updateProgressHandler
        this.reset()
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, ExportDocumentCommand, {
            type: Wukong.DocumentProto.ExportDocumentType.EXPORT_DOCUMENT_TYPE_FIGMA,
        })
        this._updateProgress = null
    }

    public destroy() {
        this.bridge.unbind(WriteExportDocumentResultCommand)
    }

    // 临时维护一份文档名
    // 因为 useDocInfo 是个 hook 而不是 service，目前拿不到
    public updateDocName(docName: string) {
        this._docName = docName
    }

    private reset = () => {
        this._currentBufferList = []
    }

    private buildExportedDocumentAndReset = () => {
        const exportedDocument: Wukong.DocumentProto.ISerializedExportedDocument = {
            allNodes: [],
            blobs: {},
        }
        for (const buf of this._currentBufferList) {
            const current = Wukong.DocumentProto.SerializedExportedDocument.decode(buf)
            exportedDocument.allNodes!.push(...(current.allNodes ?? []))
            Object.assign(exportedDocument.blobs!, current.blobs ?? {})
        }
        this.reset()
        return exportedDocument
    }

    private mergeIntoCurrent = (arg: Wukong.DocumentProto.IArg_writeExportDocumentResult) => {
        if (!arg.ptr || !arg.size) {
            throw Error('failed to get export document')
        }

        const size = arg.size
        const current = new Uint8Array(size)
        // copy
        current.set(new Uint8Array(this.bridge.currentEditorService.HEAPU8.buffer, arg.ptr, arg.size))
        this._currentBufferList.push(current)
    }
}
