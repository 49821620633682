import { POST } from '@tutor/network-core'
import { BaseCommonRequest } from './base-request'
import { FolderID, TeamID } from '../interface/type'

// 修改收藏的项目排序
@POST
export class ChangeFavoritesFolderOrder extends BaseCommonRequest<boolean> {
    constructor(private teamId: TeamID, private folderIds: FolderID[]) {
        super()
    }
    public override requestUrl() {
        return `favorites-folder-preference/${this.teamId}`
    }
    public override requestBody() {
        return this.folderIds
    }
}
