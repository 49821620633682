import { domLocation, RouteToken } from '../../../../util/src'
import { anySignal } from '../../../../util/src/abort-controller/abort-any'
import {
    TraceableAbortController,
    TraceableAbortSignal,
} from '../../../../util/src/abort-controller/traceable-abort-controller'

import { command, computed, state } from 'ccstate'
import { action } from '../../../../util/src/ccstate-helper/action'
import { replaceLocation$ } from '../../../../util/src/ccstate-helper/location'
import { traceable } from '../../../../util/src/ccstate-helper/traceable'
import { SessionStorageKey } from '../../web-storage/session-storage/config'
import { enhancedSessionStorage } from '../../web-storage/session-storage/storage'
import { updateUserInfo$ } from './user-info'

const [sessionController$, resetSessionController$] = (() => {
    const inner$ = state<TraceableAbortController | undefined>(undefined)

    return [
        computed((get) => get(inner$)),
        command(({ get, set }) => {
            get(inner$)?.abort('abort last session before init authentication')

            const newSessionController = new TraceableAbortController('sessionController')

            set(inner$, newSessionController)

            return newSessionController
        }),
    ]
})()

export const logout$ = traceable(
    'hulh01@kanyun.com',
    command(async ({ get, set }, signal: TraceableAbortSignal) => {
        // 先重定向，避免渲染执行后无 UserInfo 导致 ErrorBoundary
        await set(redirectWhenAnonymous$, signal)

        // reset userInfo to undefined
        set(updateUserInfo$)

        get(sessionController$)?.abort('user logout')
    })
)

// 尝试生成一个该登录 session 周期的 signal，该 signal 在退出登录的时候被 abort
export const [userSession$, setupUserSession$] = traceable(
    'hulh01@kanyun.com',
    action(({ set }, signal: TraceableAbortSignal) => {
        const newSessionSignal = set(resetSessionController$).signal

        const sessionSignal = anySignal([signal, newSessionSignal])

        return sessionSignal
    })
)

// 当鉴权失败时，重定向到对应的页面
export const redirectWhenAnonymous$ = traceable(
    'hulh01@kanyun.com',
    command(async ({ set }, signal: TraceableAbortSignal) => {
        const pathname = domLocation().pathname
        const search = domLocation().search

        const searchParams = new URLSearchParams(search)

        const referer = encodeURIComponent(pathname + search)

        // 已经在登录页面就不进行重定向
        if (!pathname.includes('login')) {
            if (searchParams.has('unregistered')) {
                await set(
                    replaceLocation$,
                    signal,
                    `/${RouteToken.LoginDoNotUse}?type=signUp&unregistered=${searchParams.get(
                        'unregistered'
                    )}&referer=${pathname}`
                )
                return
            }
            const userTriggerLogout = enhancedSessionStorage.getItem(SessionStorageKey.UserTriggerLogout)
            enhancedSessionStorage.setItem(SessionStorageKey.UserTriggerLogout, '0')
            // 用户主动退出，不添加referer
            if (+(userTriggerLogout ?? 0)) {
                await set(replaceLocation$, signal, `/${RouteToken.LoginDoNotUse}`)
            } else {
                await set(replaceLocation$, signal, `/${RouteToken.LoginDoNotUse}?referer=${referer}`)
            }
        }
    })
)
