/* eslint-disable no-restricted-imports */
import { UndoRedoCommand, Wukong } from '@wukong/bridge-proto'
import { isNil, omit } from 'lodash-es'
import type { HTMLAttributes } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { usePointerCapture } from '../../../../../../ui-lib/src'
import { CommitType } from '../../../../document/command/commit-type'
import { isKey, KeyboardCode } from '../../../../kernel/keyboard/keyboard-event-handler'
import { useCommand } from '../../../context/document-context'
import { InputOptionsForUndoSquash } from '../inputs/components/formatted-input'
import style from './range.module.less'

interface RangeProps
    extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange' | 'onPointerDown' | 'onPointerMove' | 'onPointerUp'> {
    rate?: number // [0-1]
    /// 键盘每次移动的距离
    delta?: number
    onChange: (rate: number, options?: InputOptionsForUndoSquash) => void
    vertical?: boolean
    backwardsIgnoreAttach?: boolean
    /// 移动时的吸附点
    attachPoint?: number
}

function Range(props: RangeProps) {
    const {
        rate = 0.5,
        className,
        delta = 0.05,
        vertical = false,
        onChange,
        onClick,
        onMouseDown,
        onFocus,
        onBlur,
        attachPoint = delta,
        backwardsIgnoreAttach = false,
    } = props

    const otherProps = omit(
        props,
        'vertical',
        'className',
        'onChange',
        'onClick',
        'onKeyDown',
        'onMouseDown',
        'delta',
        'onPointerUp',
        'onPointerDown',
        'onPointerMove',
        'onFocus',
        'onBlur',
        'backwardsIgnoreAttach',
        'attachPoint'
    )

    const [innerCycleStyle, setInnerCycleStyle] = useState<object>({
        left: `${Math.min(100, Math.max(0, rate * 100))}%`,
    })

    const [active, setActive] = useState<boolean>(false)

    const lineRef = useRef<HTMLDivElement>(null)

    const command = useCommand()

    const calculateCycle = useCallback(
        (clientOffset: number) => {
            const { left, top, width, height } = lineRef.current!.getBoundingClientRect()
            const offset = !vertical ? left : top
            const length = !vertical ? width : height

            let cycleOffset = clientOffset - offset
            cycleOffset = cycleOffset < 0 ? 0 : cycleOffset > length ? length : cycleOffset
            let nextRate = cycleOffset / length
            const isBackwards = (rate < 0.5 && nextRate > rate) || (rate > 0.5 && nextRate < rate)
            if (!(backwardsIgnoreAttach && isBackwards)) {
                nextRate = Math.abs(0.5 - nextRate) < attachPoint ? 0.5 : nextRate
            }
            onChange?.(!vertical ? nextRate : 1 - nextRate, { commitType: CommitType.Noop })
        },
        [vertical, backwardsIgnoreAttach, onChange, attachPoint, rate]
    )

    const _onClick = useCallback(
        (e: any) => {
            e.stopPropagation()
            onClick?.(e)
        },
        [onClick]
    )

    const _onMouseDown = useCallback(
        (e: any) => {
            e.stopPropagation()
            onMouseDown?.(e)
        },
        [onMouseDown]
    )

    const captureStart = useCallback(
        (e: React.PointerEvent) => {
            command.commitUndo()
            calculateCycle(!vertical ? e.clientX : e.clientY)
        },
        [calculateCycle, command, vertical]
    )

    const capturing = useCallback(
        (e: React.PointerEvent) => {
            calculateCycle(!vertical ? e.clientX : e.clientY)
        },
        [calculateCycle, vertical]
    )

    const captureEnd = useCallback(() => {
        command.commitUndo()
    }, [command])

    const { pointerdown, pointermove, pointerup } = usePointerCapture({ captureStart, capturing, captureEnd })

    const _onFocus = useCallback(
        (e: any) => {
            setActive(true)
            onFocus?.(e)
        },
        [onFocus]
    )

    const _onBlur = useCallback(
        (e: any) => {
            setActive(false)
            onBlur?.(e)
        },
        [onBlur]
    )

    const _onKeyDown = useCallback(
        (e: any) => {
            // backspace & delete
            if (e.keyCode === KeyboardCode.BACKSPACE || e.keyCode === KeyboardCode.DELETE) {
                e.stopPropagation()
                onChange?.(0.5, { commitType: CommitType.CommitSquash })
            }
            // ↑ & →
            if (isKey(e, KeyboardCode.ARROW_UP) || isKey(e, KeyboardCode.ARROW_RIGHT)) {
                e.stopPropagation()
                onChange?.(Math.min(rate + delta, 1), { commitType: CommitType.CommitSquash })
            }
            // ↓ & ←
            if (isKey(e, KeyboardCode.ARROW_DOWN) || isKey(e, KeyboardCode.ARROW_LEFT)) {
                e.stopPropagation()
                onChange?.(Math.max(rate - delta, 0), { commitType: CommitType.CommitSquash })
            }

            if (isKey(e, KeyboardCode.Z)) {
                e.stopPropagation()
                const { metaKey, ctrlKey, shiftKey, altKey } = e
                command.DEPRECATED_invokeBridge(
                    UndoRedoCommand,
                    Wukong.DocumentProto.UndoRedoCommandParam.create({ metaKey, ctrlKey, shiftKey, altKey })
                )
            }
        },
        [command, delta, onChange, rate]
    )

    useEffect(() => {
        const { width, height } = lineRef.current!.getBoundingClientRect()
        const length = !vertical ? width : height
        const rateValue = isNil(rate) ? 0.5 : rate
        const rateOffset = rateValue < 0 || rateValue > 1 ? 0.5 : rateValue
        if (!vertical) {
            setInnerCycleStyle({ left: length * rateOffset + 'px' })
        } else {
            // 从底部开始为 -1
            setInnerCycleStyle({ top: length * (1 - rateOffset) + 'px' })
        }
    }, [rate, vertical])

    return (
        <div
            onClick={_onClick}
            onKeyDown={_onKeyDown}
            onMouseDown={_onMouseDown}
            onPointerDown={pointerdown}
            onPointerMove={pointermove}
            onPointerUp={pointerup}
            onFocus={_onFocus}
            onBlur={_onBlur}
            className={`${style.container} ${vertical ? style.vertical : ''} ${className ?? ''}`}
            {...otherProps}
            tabIndex={0}
            // 避免触发可拖拽弹窗的拖拽
            data-disabled-drag-move={'true'}
        >
            <div className={`${style.line} ${vertical ? style.vertical : ''}`} ref={lineRef}>
                <span className={`${style.split} ${vertical ? style.vertical : ''}`}></span>
                <span
                    className={`${style.cycle} ${vertical ? style.vertical : ''}
                        ${active ? style.active : ''}
                        ${isNil(rate) || rate === 0.5 ? style.siteCenter : style.siteOther}
                        `}
                    style={innerCycleStyle}
                    data-testid="range-cycle"
                ></span>
            </div>
        </div>
    )
}

export { Range }
