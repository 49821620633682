import classnames from 'classnames'
import React, {
    forwardRef,
    FunctionComponent,
    useCallback,
    useImperativeHandle,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useUpdateEffect } from 'react-use'
import { useWindowResize } from '../../resize/use-window-resize'
import { ScrollView, ScrollViewRef } from '../../scrollbars'
import classes from './pop-up-select-list.module.less'

export interface PopUpSelectListProps<T> {
    items?: T[]
    maxHeight?: number
    renderItem?: FunctionComponent<{ item: T }>
    empty?: React.ReactNode // 列表项为空时展示
    onSelectItem?: (item: T, option: { metaKey: boolean; shiftKey: boolean }) => void
}
export interface PopUpSelectListRef<T> {
    move2top: () => boolean // 返回值表示是否移动成功
    move2bottom: () => boolean // 返回值表示是否移动成功
    getSelectItem: () => T
}
function _PopUpSelectList<T>(
    props: React.PropsWithChildren<PopUpSelectListProps<T>>,
    ref: React.Ref<PopUpSelectListRef<T>>
) {
    const { items = [], maxHeight = 10000, onSelectItem, renderItem: RenderItem = DefaultRenderListItem, empty } = props
    const [selectKey, setSelectKey] = useState<number>(0)
    const [limitMaxHeight, setLimitMaxHeight] = useState<number>(maxHeight)
    const scrollViewRef = useRef<ScrollViewRef>(null)

    const itemsLength = useMemo(() => items.length, [items.length])

    const autoHeightMax = useMemo(() => {
        return Math.min(limitMaxHeight, maxHeight)
    }, [limitMaxHeight, maxHeight])

    const updateLimitMaxHeight = useCallback(() => {
        const rootElement = scrollViewRef.current?.getScrollViewElement()
        if (!rootElement) {
            return
        }
        const clientHeight = document.body.clientHeight
        const minEdge = 8 // 设计定义与底部保持最小8px空隙
        setLimitMaxHeight(clientHeight - rootElement.getBoundingClientRect().top - minEdge)
    }, [])

    const move2top = useCallback(() => {
        const nextSelectKey = (selectKey + itemsLength - 1) % itemsLength
        setSelectKey(nextSelectKey)
        return !isNaN(nextSelectKey)
    }, [itemsLength, selectKey])

    const move2bottom = useCallback(() => {
        const nextSelectKey = (selectKey + 1) % itemsLength
        setSelectKey(nextSelectKey)
        return !isNaN(nextSelectKey)
    }, [itemsLength, selectKey])

    const getSelectItem = useCallback(() => {
        return items[selectKey]
    }, [items, selectKey])

    const _onClick = useCallback(
        (e: React.MouseEvent) => {
            onSelectItem?.(getSelectItem(), { metaKey: e.metaKey, shiftKey: e.shiftKey })
        },
        [getSelectItem, onSelectItem]
    )

    useLayoutEffect(() => {
        updateLimitMaxHeight()
    }, [updateLimitMaxHeight])

    useUpdateEffect(() => {
        setSelectKey(0)
    }, [items])

    useWindowResize(updateLimitMaxHeight)

    useImperativeHandle(ref, () => ({ move2top, move2bottom, getSelectItem }), [getSelectItem, move2bottom, move2top])

    return (
        <ScrollView
            ref={scrollViewRef}
            selectKey={selectKey}
            block="nearest"
            scrollbar={{
                autoHeight: true,
                autoHeightMin: 0,
                autoHeightMax,
                className: classes.content,
            }}
            className={classes.scrollView}
        >
            {items.map((item, index) => (
                <ScrollView.Item
                    uniqueKey={index}
                    key={index}
                    className={classnames(classes.item)}
                    selectClassName={classes.selected}
                    onClick={_onClick}
                    onMouseMove={() => setSelectKey(index)}
                    onMouseDown={(e) => e.preventDefault()}
                >
                    <RenderItem item={item} />
                </ScrollView.Item>
            ))}
            {!items.length && empty}
        </ScrollView>
    )
}

export const PopUpSelectList = forwardRef(_PopUpSelectList) as <T>(
    props: PopUpSelectListProps<T> & { ref?: React.Ref<PopUpSelectListRef<T>> }
) => React.ReactElement

function DefaultRenderListItem<T>(props: { item: T }) {
    return <div style={{ padding: '0 16px', height: 64 }}>{String(props.item)}</div>
}
