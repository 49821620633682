import { useMemo, useState } from 'react'
import { DropdownV2, MonoIconPanelApplyProperty16, WKIconButton } from '../../../../../../ui-lib/src'
import Tooltip from '../../../../../../ui-lib/src/components/tooltip/tooltip'
import { PopupStateType } from '../../../../document/node/node'
import { useComponentProp } from '../../design-panel-v2/component-instance-panel/components/component-prop/hook'
import { ComponentPropNodeField, VComponentPropType } from '../../design-panel-v2/component-instance-panel/types'
import styles from './bool-prop-apply-button.module.less'
import { translation } from './bool-prop-apply-button.translation'

const CreateBoolPropBtnAlias = 'create-bool-prop-btn'

export function BoolPropApplyButton() {
    const {
        componentPropDefs,
        showApplyIconForApplyBoolProp,
        showApplyIconForCreateBoolProp,
        toggleComponentPropPopup,
        applyProp,
    } = useComponentProp()

    const showApplyIcon = showApplyIconForApplyBoolProp || showApplyIconForCreateBoolProp

    const booleanPropList = useMemo(() => {
        return (
            componentPropDefs.filter((compProp) => compProp.type === VComponentPropType.V_COMPONENT_PROP_TYPE_BOOL) ||
            []
        )
    }, [componentPropDefs])

    const [dropdownVisible, setDropdownVisible] = useState(false)

    if (!showApplyIcon) {
        return null
    }

    const onSelectChange = (value: string): void => {
        // value 为 [xx:xx]形式 / CreateBoolPropBtnAlias
        if (value !== CreateBoolPropBtnAlias) {
            applyProp(ComponentPropNodeField.COMPONENT_PROP_NODE_FIELD_VISIBLE, value)
        }
    }

    const openLayerAreaCreateBoolPropPopup = () => {
        toggleComponentPropPopup(PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_BOOL_PROP_OF_LAYER_AREA)
    }

    return (
        <>
            {showApplyIconForCreateBoolProp && (
                <Tooltip title={translation('CreateBoolProp')}>
                    <WKIconButton
                        data-testid="boolean-prop-apply-icon-for-create"
                        icon={<MonoIconPanelApplyProperty16 />}
                        onClick={openLayerAreaCreateBoolPropPopup}
                    />
                </Tooltip>
            )}
            {showApplyIconForApplyBoolProp && (
                <Tooltip title={translation('ApplyBoolProp')} triggerRefKey="getTriggerElement">
                    <DropdownV2.IconSingleLevel
                        maxWidth={228}
                        label={
                            <MonoIconPanelApplyProperty16 className={dropdownVisible ? undefined : styles.grayIcon} />
                        }
                        dataTestIds={{ triggerFocus: 'boolean-prop-apply-icon-for-apply' }}
                        onChange={onSelectChange}
                        onClick={() => setDropdownVisible(!dropdownVisible)}
                        placement="bottom right"
                    >
                        {booleanPropList.map((booleanProp) => (
                            <DropdownV2.IconSingleLevel.Option key={booleanProp.id} value={booleanProp.id}>
                                {booleanProp.name}
                            </DropdownV2.IconSingleLevel.Option>
                        ))}
                        <DropdownV2.IconSingleLevel.Option
                            splitLineTop
                            onClick={openLayerAreaCreateBoolPropPopup}
                            value={CreateBoolPropBtnAlias}
                        >
                            {translation('CreateProp')}
                        </DropdownV2.IconSingleLevel.Option>
                    </DropdownV2.IconSingleLevel>
                </Tooltip>
            )}
        </>
    )
}
