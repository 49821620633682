import { createSelectors, createStore } from '../../../../../../util/src/zustand-utils'
import { CommentId, CommentReply, CommentUser, Strategy } from '../../../../kernel/interface/comment'
import { ShowFilterType, SortType } from '../comment-panel/type'
import { ClusterId, Comment, CommentCluster, EditorType, Message, Position } from '../type'

interface CommentServiceZustandStore {
    canvasCommentsMap: Map<CommentId, Comment>
    canvasPendingCommentsMap: Map<CommentId, Comment>
    canvasCommentClustersMap: Map<ClusterId, CommentCluster>
    usersMap: Map<CommentUser['id'], CommentUser>
    drawingPosition: {
        startPosition: Position
        endPosition: Position
    } | null
    createComment: {
        id: string
        startPosition: Position | null
        endPosition: Position
    } | null
    selectAllInCreateComment: boolean
    insertMentionUserInCreateComment: CommentUser | null
    activeCommentId: CommentId | undefined
    prevCommentId: CommentId | undefined
    nextCommentId: CommentId | undefined
    hoverCommentId: CommentId | undefined
    isStopSyncActiveCommentPosition: boolean
    editingReply: {
        commentId: CommentId
        commentReply: CommentReply
    } | null
    replaceCreateReplyMessages: Message[] | null
    isFastInsertMentionUser: boolean
    insertMentionUserInCreateReply: CommentUser | null
    selectAllInCreateReply: boolean
    selectAllInEditReply: boolean
    insertMentionUserInEditReply: CommentUser | null
    isShowMentionUserList: boolean
    mentionUsersRect: {
        splitLineRect: DOMRect
        containerRect: DOMRect
    } | null
    mentionUserList: CommentUser[]
    preSelectedMentionUserIndex: number
    needShake: boolean
    insertEmojiTarget: EditorType
    insertEmoji: string | undefined
    insertEmojiRect: {
        editRect: DOMRect
        containerRect: DOMRect
    } | null
    hasUnreadComment: boolean
    isNoCommentsYet: boolean
    commentSearchString: string
    commentSortType: SortType
    commentShowFilters: ShowFilterType[]
    commentNoticeType: Strategy | undefined
    commentsList: Comment[]
    commentClustersList: CommentCluster[]
}

export class CommentServiceStore {
    private zustandStore = createStore<CommentServiceZustandStore>(() => ({
        canvasCommentsMap: new Map<CommentId, Comment>(),
        canvasPendingCommentsMap: new Map<CommentId, Comment>(),
        canvasCommentClustersMap: new Map<ClusterId, CommentCluster>(),
        usersMap: new Map<CommentUser['id'], CommentUser>(),
        drawingPosition: null,
        createComment: null,
        selectAllInCreateComment: false,
        insertMentionUserInCreateComment: null,
        activeCommentId: undefined,
        prevCommentId: undefined,
        nextCommentId: undefined,
        hoverCommentId: undefined,
        isStopSyncActiveCommentPosition: false,
        editingReply: null,
        replaceCreateReplyMessages: null,
        isFastInsertMentionUser: false,
        insertMentionUserInCreateReply: null,
        selectAllInCreateReply: false,
        selectAllInEditReply: false,
        insertMentionUserInEditReply: null,
        isShowMentionUserList: false,
        mentionUsersRect: null,
        mentionUserList: [],
        preSelectedMentionUserIndex: -1,
        needShake: false,
        insertEmojiTarget: EditorType.Null,
        insertEmoji: undefined,
        insertEmojiRect: null,
        hasUnreadComment: false,
        isNoCommentsYet: false,
        commentSearchString: '',
        commentSortType: SortType.Time,
        commentShowFilters: [],
        commentNoticeType: undefined,
        commentsList: [],
        commentClustersList: [],
    }))
    useZustandStore = createSelectors(this.zustandStore)

    get<T extends keyof CommentServiceZustandStore>(key: T): CommentServiceZustandStore[T] {
        return this.zustandStore.getState()[key]
    }

    set<T extends keyof CommentServiceZustandStore>(key: T, value: CommentServiceZustandStore[T]) {
        this.zustandStore.setState({ [key]: value })
    }
}
