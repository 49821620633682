import { useCallback, useEffect, useMemo, useRef, WheelEventHandler } from 'react'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { RangeArea, RangeAreaProps } from '../../../atom/range/range-area'
import { DrawMode } from './color-type'
import { ColorSpace, hsl2rgb, hsv2rgb, toCSSBackgroundColor } from './utils/color-translate'

export interface ColorAreaProps extends Pick<RangeAreaProps, 'onDragState'> {
    width: number
    height: number
    drawMode: DrawMode
    hue: number // [0-360]
    horizontal: number // [0-1]
    vertical: number // [0-1]
    colorSpace: ColorSpace
    onChangeHV?: (horizontal: number, vertical: number, options?: InputOptionsForUndoSquash) => void
    onWheel?: WheelEventHandler<HTMLDivElement>
}

export function ColorArea(props: ColorAreaProps) {
    const { width, height, onChangeHV, onDragState, drawMode, hue, horizontal, vertical, colorSpace, onWheel } = props
    const canvasColorArea = useRef<HTMLCanvasElement>(null)

    // 绘制 sl / sv 区域
    const drawColor = useCallback(() => {
        if (!canvasColorArea.current) return
        const ctx = canvasColorArea.current.getContext('2d', {
            colorSpace,
        })
        if (!ctx) return
        let { width: _width, height: _height } = canvasColorArea.current.getBoundingClientRect()
        _width = Math.floor(_width)
        _height = Math.floor(_height)
        canvasColorArea.current.width = _width
        canvasColorArea.current.height = _height
        const imageData =
            colorSpace === 'srgb'
                ? ctx.getImageData(0, 0, _width, _height)
                : ctx.getImageData(0, 0, _width, _height, {
                      colorSpace,
                  })
        const data = imageData.data
        const deltaH = 1 / (_height - 1)
        const deltaW = 1 / (_width - 1)
        let mod = 0,
            s = 0,
            lv = 1 + deltaH
        for (let i = 0; i < data.length; i += 4) {
            mod = (i >> 2) % _width
            s = s + deltaW
            if (mod === 0) {
                lv = lv - deltaH
                s = 0
            }
            const { r, g, b } = drawMode === DrawMode.HSL ? hsl2rgb(hue, s, lv) : hsv2rgb(hue, s, lv)
            data[i] = r * 255
            data[i + 1] = g * 255
            data[i + 2] = b * 255
            data[i + 3] = 255
        }
        ctx.putImageData(imageData, 0, 0)
    }, [hue, drawMode, colorSpace])

    useEffect(() => {
        drawColor()
    }, [drawColor])

    // 通过拖动圆圈 修改水平和垂直的百分比
    const onHandleMove = useCallback(
        (value: any, options?: InputOptionsForUndoSquash) => {
            const { rateX, rateY } = value
            const x = rateX
            const y = 1 - rateY
            onChangeHV?.(x, y, options)
        },
        [onChangeHV]
    )

    const handleStyle = useMemo(() => {
        const { r, g, b } =
            drawMode === DrawMode.HSL ? hsl2rgb(hue, horizontal, vertical) : hsv2rgb(hue, horizontal, vertical)

        return {
            backgroundColor: toCSSBackgroundColor(
                {
                    r: r * 255,
                    g: g * 255,
                    b: b * 255,
                },
                colorSpace
            ),
        }
    }, [drawMode, horizontal, hue, vertical, colorSpace])

    return (
        <RangeArea
            style={{ width: `${width}px`, height: `${height}px` }}
            onHandleMove={onHandleMove}
            onDragState={onDragState}
            handleStyle={handleStyle}
            rateX={horizontal}
            rateY={1 - vertical}
            onWheel={onWheel}
            closeShadow
            testId="color-area"
        >
            <canvas ref={canvasColorArea} width={width} height={height} style={{ width: '100%', height: '100%' }} />
        </RangeArea>
    )
}
