import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    LayoutGrid: '布局网格',
    Style: '样式',
    'Click+To': '点击 + 替换多个属性值',
} as const

export const enTranslation = {
    LayoutGrid: 'Layout grid',
    Style: 'Style',
    'Click+To': 'Click + to replace mixed content',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
