import classNames from 'classnames'
import type { ReactNode } from 'react'
import { WKTypography } from '../../../wk-typography'
import { useRadioGroupContext } from './use-radio-group-context'

export interface WKRadioProps {
    value: string
    label?: ReactNode
    description?: ReactNode
    disabled?: boolean
    readonly?: boolean
    /**
     * @deprecated 已废弃, 不建议通过className自定义样式
     */
    className?: string
    dataTestId?: string
}

const styles = {
    icon: {
        base: 'relative flex-none inline-flex items-center justify-center box-border rounded-full my-4px',
        uncheck: {
            normal: 'border-1 border-solid border-$wk-gray-5 group-hover:border-$wk-brand-6 has-[:focus-visible]:border-2 has-[:focus-visible]:border-$wk-brand-6',
            disabled: 'border-1 border-solid border-$wk-gray-5 bg-$wk-gray-3',
        },
        check: {
            normal: 'bg-$wk-brand-7 group-hover:bg-$wk-brand-6 has-[:focus-visible]:outline has-[:focus-visible]:outline-offset-0 has-[:focus-visible]:outline-$wk-brand-3',
            disabled: 'bg-$wk-brand-3',
        },
        size: {
            12: 'w-12px h-12px',
            14: 'w-14px h-14px',
            16: 'w-16px h-16px',
        },
    },
    iconInnerPoint: {
        base: 'rounded-full bg-$wk-white',
        size: {
            12: 'w-4px h-4px',
            14: 'w-5px h-5px',
            16: 'w-6px h-6px',
        },
    },
}

export const WKRadio = (props: WKRadioProps) => {
    const { label, description, value, disabled, readonly } = props
    const { size, name, computedValue, onRadioItemClick } = useRadioGroupContext()
    const checked = computedValue === value
    const disabledOrReadonly = disabled || readonly
    const disabledOnly = disabled && !readonly
    const getIconClassNames = () => {
        const classes = [styles.icon.base, styles.icon.size[size]]

        if (checked) {
            classes.push(disabledOrReadonly ? styles.icon.check.disabled : styles.icon.check.normal)
        } else {
            classes.push(disabledOrReadonly ? styles.icon.uncheck.disabled : styles.icon.uncheck.normal)
        }
        return classNames(classes)
    }

    const iconInnerPointClassNames = classNames(styles.iconInnerPoint.base, styles.iconInnerPoint.size[size])

    return (
        <label className={classNames('group inline-flex flex-row items-start gap-8px', props.className)}>
            <span className={getIconClassNames()}>
                <input
                    className="absolute inset-0 opacity-0"
                    type="radio"
                    name={name}
                    value={value}
                    checked={checked}
                    disabled={disabledOrReadonly}
                    onChange={() => onRadioItemClick(value)}
                    data-testid={props.dataTestId}
                />
                {checked ? <span className={iconInnerPointClassNames}></span> : null}
            </span>
            <div className={classNames('flex flex-col items-start gap-4px')}>
                {label ? (
                    <WKTypography.Text size={size} color={disabledOnly ? 'disabled' : 'primary'}>
                        {label}
                    </WKTypography.Text>
                ) : null}
                {description ? (
                    <div className={classNames('wk-font-regular wk-text-12 text-gray')}>{description}</div>
                ) : null}
            </div>
        </label>
    )
}
