import { translation } from './delete-comment-modal.translation'
/* eslint-disable no-restricted-imports */
import { WKButtonProps, WKDialogProps, wkDialogConfirm } from '../../../../../../ui-lib/src'
import { CommentDataTestId } from '../../../../window'

export function deleteCommentConfirmModal(props: Pick<WKDialogProps, 'onOk'>) {
    wkDialogConfirm.show({
        title: translation('AreYouSure?'),
        onOk: props.onOk,
        content: translation('AreYouSure'),
        okText: translation('Delete'),
        okButtonProps: { 'data-testid': CommentDataTestId.CommentDetailRemoveConfirm } as Partial<WKButtonProps>,
    })
}
