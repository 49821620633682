import { AimLibraryNodeWasmCall, UpdatePublishHiddenCommand, Wukong } from '@wukong/bridge-proto'
import constate from 'constate'
import { CSSProperties, MouseEventHandler, PointerEventHandler, useCallback, useMemo, useState } from 'react'
import { RectContainer, TooltipPlacement } from '../../../../../ui-lib/src'
import type { CommandInvoker } from '../../../document/command/command-invoker'
import type { LibraryDragEventService } from '../../../ui/component/component-style-library-v2/library-service/library-drag-event-service'
import type { JumpToOriginDocumentParams } from '../../../ui/component/component/component-service'
import { buildThumbnailDataFromVO } from '../library-thumbnail-image'
import {
    getLibraryComponentViewerContextMenuItemsByTypes,
    LibraryComponentViewerContextMenuType,
} from './library-component-viewer-context-menu'
import { ComponentGetVO } from '../../../kernel/interface/component-style'

export type ComponentViewerItem = Wukong.DocumentProto.IVLibraryAssetPanelComponentItem

export function buildComponentViewerItemFromVO(vo: ComponentGetVO): ComponentViewerItem {
    return {
        isLocal: false,
        remoteNodeId: vo.nodeId,
        remoteDocId: vo.documentId,
        name: vo.name ?? '',
        nodeDataPath: vo.nodeDataPath ?? '',
        thumbnailData: buildThumbnailDataFromVO(vo),
        backgroundColor: vo.containingFrameBackgroundColor,
    } as ComponentViewerItem
}

export function useComponentViewer(props: {
    component: ComponentViewerItem
    // 自定义选中状态（传入非 undefined 值后将忽略默认选中行为）
    selected?: boolean
    // 禁止默认右键行为
    disableRightClick?: boolean
    className?: string
    style?: CSSProperties
    innerStyle?: CSSProperties
    isListLayout?: boolean
    // hover 时不显示 name
    hiddenName?: boolean
    // 悬浮时显示 tooltip 位置
    tooltipPlacement?: TooltipPlacement
}) {
    const { command, jumpToOriginDocumentInEditor } = useLibraryComponentViewShareConfig()

    const contextMenuItems = useMemo(
        () =>
            props.component?.isLocal
                ? props.component?.publishHidden
                    ? getLibraryComponentViewerContextMenuItemsByTypes([
                          LibraryComponentViewerContextMenuType.PublishShow,
                      ])
                    : getLibraryComponentViewerContextMenuItemsByTypes([
                          LibraryComponentViewerContextMenuType.PublishHidden,
                      ])
                : getLibraryComponentViewerContextMenuItemsByTypes([
                      LibraryComponentViewerContextMenuType.Jump2Document,
                  ]),
        [props.component?.isLocal, props.component?.publishHidden]
    )

    const handleJump2Document = useCallback(() => {
        if (!jumpToOriginDocumentInEditor) {
            // 工作台打开场景，直接返回
            return
        }

        jumpToOriginDocumentInEditor({
            docId: props.component.remoteDocId,
            nodeId: props.component.remoteNodeId,
            fromFig: props.component.fromFig ?? false,
            name: props.component.name ?? '',
            isShape: true,
        })
    }, [
        jumpToOriginDocumentInEditor,
        props.component.fromFig,
        props.component.name,
        props.component.remoteDocId,
        props.component.remoteNodeId,
    ])

    const handlePublishHidden = useCallback(() => {
        if (!command) {
            // 工作台打开场景，直接返回
            return
        }
        if (props.component.isLocal && props.component.localNodeId) {
            command?.DEPRECATED_invokeBridge(UpdatePublishHiddenCommand, {
                id: props.component.localNodeId,
                publishHidden: true,
            })
            command.commitUndo()
        }
    }, [command, props.component.isLocal, props.component.localNodeId])

    const handlePublishShow = useCallback(() => {
        if (!command) {
            // 工作台打开场景，直接返回
            return
        }

        if (props.component.isLocal && props.component.localNodeId) {
            command.DEPRECATED_invokeBridge(UpdatePublishHiddenCommand, {
                id: props.component.localNodeId,
                publishHidden: false,
            })
            command.commitUndo()
        }
    }, [command, props.component.isLocal, props.component.localNodeId])

    // 右键菜单 enter 回调
    const onContextMenuEnterChange = useCallback(
        (key: LibraryComponentViewerContextMenuType) => {
            if (key === LibraryComponentViewerContextMenuType.Jump2Document) {
                handleJump2Document()
            } else if (key === LibraryComponentViewerContextMenuType.PublishHidden) {
                handlePublishHidden()
            } else if (key === LibraryComponentViewerContextMenuType.PublishShow) {
                handlePublishShow()
            }
        },
        [handleJump2Document, handlePublishHidden, handlePublishShow]
    )

    const [contextMenuInfo, setContextMenuInfo] = useState<RectContainer | null>(null)
    const onContextMenuOpen: MouseEventHandler<HTMLDivElement> = (e) => {
        if (props.disableRightClick || !contextMenuItems.length) {
            return
        }
        const { clientX, clientY } = e
        setContextMenuInfo({
            top: clientY,
            bottom: clientY,
            right: clientX,
            left: clientX,
        })
    }

    const onClick = () => {
        if (!command) {
            // 工作台打开场景，直接返回
            return
        }

        if (props.component.isLocal && props.component.localNodeId) {
            command.DEPRECATED_invokeBridge(
                AimLibraryNodeWasmCall,
                Wukong.DocumentProto.Arg_AimLibraryNode.create({
                    id: props.component.localNodeId,
                })
            )
        }
    }

    const onContextMenuClose = () => {
        if (!command) {
            // 工作台打开场景，直接返回
            return
        }

        setContextMenuInfo(null)
    }

    const onContextMenuOrClick: PointerEventHandler<HTMLDivElement> = (e) => {
        if (e.button === 2) {
            onContextMenuOpen(e)
        } else if (!e.button) {
            onClick()
        }
    }

    return {
        contextMenuInfo,
        contextMenuItems,

        onContextMenuClose,
        onContextMenuEnterChange,

        onContextMenuOrClick,
    }
}

// 计算缩略图展示样式 Hook
export function useComponentViewerStyle(props: {
    isListLayout?: boolean
    style?: CSSProperties
    component: ComponentViewerItem
    // hover 时不显示 name 或者 description
    hiddenNameAndDesc?: boolean
}) {
    const background = props.component.backgroundColor || 'var(--wk-gray-1)'
    const containerStyle = props.isListLayout ? props.style : { ...(props.style ?? {}), background }
    const wrapperStyle = props.isListLayout ? { background } : undefined
    const labelStyle = props.isListLayout ? undefined : { background }
    const isNameShowAsTooltip = useMemo(() => {
        if (props.isListLayout || props.hiddenNameAndDesc || !containerStyle?.width || !containerStyle?.height) {
            return false
        }
        const width = +`${containerStyle.width}`.replace('px', '')
        const height = +`${containerStyle.height}`.replace('px', '')

        if (isNaN(width) || isNaN(height)) {
            return false
        }

        return width <= 42 && height <= 42
    }, [containerStyle?.width, containerStyle?.height, props.isListLayout, props.hiddenNameAndDesc])

    return {
        containerStyle,
        wrapperStyle,
        labelStyle,
        isNameShowAsTooltip,
    }
}

// 为了在工作台、编辑器复用组件库的组件，提供此 context 用于灵活获取配置
function useLibraryComponentViewShareConfigHook(
    props:
        | {
              command?: undefined
              jumpToOriginDocumentInEditor?: undefined
              dragConfig: { draggable: false }
          }
        | {
              command: CommandInvoker
              jumpToOriginDocumentInEditor: (params: JumpToOriginDocumentParams) => void
              dragConfig: { draggable: false } | { draggable: true; libraryDragEventService: LibraryDragEventService }
          }
) {
    return props
}
export const [LibraryComponentViewShareConfigProvider, useLibraryComponentViewShareConfig] = constate(
    useLibraryComponentViewShareConfigHook
)
