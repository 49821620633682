import { AppState, StateCallbackInjector } from '../../types'
import { MirrorContext } from '../../types/editor'
import { injectClog, injectDebugMode, injectFeatureSwitchManager, injectRedirect, injectUserInfo } from './common'

type MirrorAndPreviewLoginCallbackInjector = (injector: StateCallbackInjector<AppState.Login, MirrorContext>) => void

/**
 * mirror Login callback
 */
export const injectMirrorAndPreviewLoginCallback: MirrorAndPreviewLoginCallbackInjector = (injector) => {
    ;[injectUserInfo, injectFeatureSwitchManager, injectRedirect, injectDebugMode, injectClog].forEach((inject) =>
        inject(injector)
    )
}
