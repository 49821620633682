import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    ChangeTo: '更改为',
} as const

export const enTranslation = {
    ChangeTo: 'Change to',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
