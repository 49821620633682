import { translation } from './blend-title.translation'
/* eslint-disable no-restricted-imports */
import { Paint } from '../../../../document/node/node'

import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { Select, Tooltip } from '../../../../../../ui-lib/src'
import { BlendModeSelect } from '../blend-mode/blend-mode-select'
import style from './blend-title.module.less'
import PaintType = Wukong.DocumentProto.PaintType

export const paintTypeToShow: { [key in PaintType]: string } = {
    [PaintType.PAINT_TYPE_SOLID_PAINT]: translation('Solid'),
    [PaintType.PAINT_TYPE_GRADIENT_LINEAR]: translation('LinearGradient'),
    [PaintType.PAINT_TYPE_GRADIENT_RADIAL]: translation('RadialGradient'),
    [PaintType.PAINT_TYPE_GRADIENT_ANGULAR]: translation('AngularGradient'),
    [PaintType.PAINT_TYPE_GRADIENT_DIAMOND]: translation('DiamondGradient'),
    [PaintType.PAINT_TYPE_IMAGE_PAINT]: translation('Image'),
    [PaintType.PAINT_TYPE_EMOJI]: '',
}

export enum OpenBlendFrom {
    SelectColor,
    Background,
    Effects,
}
export interface BlendTitleProps {
    data: Paint
    openBlendFrom?: OpenBlendFrom
    onChangePaintType?: (value: PaintType) => void
    onChangeBlendMode?: (blendMode: Wukong.DocumentProto.BlendMode) => void
}
export function BlendTitle(props: BlendTitleProps) {
    const { data, openBlendFrom, onChangePaintType, onChangeBlendMode } = props

    if (data.type === PaintType.PAINT_TYPE_EMOJI) {
        return null
    }

    return (
        <div className={classnames(style.root)}>
            {openBlendFrom === OpenBlendFrom.Effects ? (
                translation('Custom')
            ) : (
                <div className={style.titleContainer}>
                    <Select.MinimalSingleLevel
                        value={data.type}
                        label={paintTypeToShow[data.type]}
                        onChange={onChangePaintType}
                        weight="semibold"
                    >
                        {Object.keys(paintTypeToShow).map((key) => {
                            const paintType: PaintType = Number(key)
                            if (
                                (openBlendFrom === OpenBlendFrom.SelectColor &&
                                    paintType === PaintType.PAINT_TYPE_IMAGE_PAINT) ||
                                paintType === PaintType.PAINT_TYPE_EMOJI
                            ) {
                                return null
                            }
                            return (
                                <Select.MinimalSingleLevel.Option
                                    value={paintType}
                                    key={key}
                                    backwardIcon={''}
                                    splitLineBottom={paintType === PaintType.PAINT_TYPE_SOLID_PAINT}
                                    splitLineTop={paintType === PaintType.PAINT_TYPE_IMAGE_PAINT}
                                >
                                    {paintTypeToShow[paintType]}
                                </Select.MinimalSingleLevel.Option>
                            )
                        })}
                    </Select.MinimalSingleLevel>
                    <Tooltip title={translation('BlendMode')}>
                        <div className={style.blendMode}>
                            <BlendModeSelect
                                value={data.blendMode ?? undefined}
                                onChange={onChangeBlendMode}
                                onlyIcon
                            ></BlendModeSelect>
                        </div>
                    </Tooltip>
                </div>
            )}
        </div>
    )
}
