import { InputV2 } from '../../../../../../ui-lib/src'
import { translation } from './remix-design-system-name.translation'
import { useAIGenUIInContext } from './use-ai-gen-ui'

export function RemixDesignSystemName() {
    const { updateRemixName, tempRemixName, creatingOrEditingRemix } = useAIGenUIInContext()

    if (tempRemixName === null) {
        return null
    }

    return (
        <div className="flex flex-col gap-2 mb-0.5">
            <span className="p-0 m-0 wk-text-12 wk-font-semibold">{translation('stylingName')}</span>
            <InputV2
                value={tempRemixName}
                defaultValue={tempRemixName}
                size="medium"
                autoFocus={creatingOrEditingRemix?.value === 'Remix'}
                error={{
                    show: tempRemixName.length > 128,
                    tipMessage: translation('stylingNameError'),
                }}
                placeholder={translation('stylingNamePlaceholder')}
                dataTestIds={{ root: 'ai-gen-ui-remix-name' }}
                onChange={(e) => {
                    if (e.currentTarget.value.trim() !== '') {
                        updateRemixName(e.currentTarget.value)
                    }
                }}
            />
        </div>
    )
}
