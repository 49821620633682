import { BitmapDrawble, ImageDecodedBitmapType, TextureCompressionFormat } from '../../image-lib/types'
import { CanvasState, CanvasStateType, WebGLCanvasState, WebGPUCanvasState } from '../../kernel/service/canvas-types'
import type { ImagePixelsStore } from './image-pixels-store'

export interface UploadImageToGPUParams {
    format: TextureCompressionFormat
    chunkIndexLeft: number
    chunkIndexRight: number
    resourcePixelsHandle: number
    textureHandle: number
}

export class BitmapUploader {
    private isDestroy = false
    constructor(
        private canvasStateGetter: () => CanvasState | null,
        private _bitmapStore: ImagePixelsStore,
        private _log: (msg: string) => void
    ) {}

    public destroy() {
        this.isDestroy = true
    }

    /**
     * @brief 将图片上传到 GPU
     */
    public uploadImageToGPUSync(arg: UploadImageToGPUParams) {
        if (this.isDestroy || !arg.resourcePixelsHandle) {
            return
        }

        const imageBitmap = this._bitmapStore.getDrawable(
            arg.resourcePixelsHandle,
            arg.format,
            arg.chunkIndexLeft,
            arg.chunkIndexRight
        )
        if (!imageBitmap) {
            this._log(`[uploadImageToGPUSync] Can't find the ImageBitmap, handle ${arg.resourcePixelsHandle}`)
            return
        }

        const state = this.canvasStateGetter()
        if (state?.type == CanvasStateType.WebGL) {
            this.webglTexBitmap(state, arg.textureHandle, imageBitmap)
        } else if (state?.type == CanvasStateType.WebGPU) {
            this.webgpuTexBitmap(state, arg.textureHandle, imageBitmap)
        }
    }

    private webglTexBitmap(state: WebGLCanvasState, textureHandle: number, drawable: BitmapDrawble) {
        const texture = state.findTexture(textureHandle)
        if (!texture) {
            this._log(`[uploadImageToGPUSync] Can't find the Texture, handle ${textureHandle}`)
            return
        }

        const gl = state.context
        const oldTexture = gl.getParameter(gl.TEXTURE_BINDING_2D)
        gl.bindTexture(gl.TEXTURE_2D, texture)

        if (drawable.type === ImageDecodedBitmapType.Bytes) {
            for (const chunk of drawable.data) {
                gl.texSubImage2D(
                    gl.TEXTURE_2D,
                    0,
                    0,
                    chunk.y,
                    chunk.width,
                    chunk.height,
                    gl.RGBA,
                    gl.UNSIGNED_BYTE,
                    chunk.chunk
                )
            }
        } else if (drawable.type === ImageDecodedBitmapType.Ktx) {
            switch (drawable.format) {
                case TextureCompressionFormat.None: {
                    gl.texSubImage2D(
                        gl.TEXTURE_2D,
                        0,
                        0,
                        0,
                        drawable.width,
                        drawable.height,
                        gl.RGBA,
                        gl.UNSIGNED_BYTE,
                        drawable.data
                    )
                    break
                }
                case TextureCompressionFormat.ASTC_4x4:
                case TextureCompressionFormat.BC7: {
                    gl.compressedTexSubImage2D(
                        gl.TEXTURE_2D,
                        0,
                        0,
                        0,
                        drawable.width,
                        drawable.height,
                        this.getCompressedGLFormatCode(drawable.format),
                        drawable.data
                    )
                    break
                }
            }
        } else {
            gl.texSubImage2D(gl.TEXTURE_2D, 0, 0, 0, gl.RGBA, gl.UNSIGNED_BYTE, drawable.data)
        }
        gl.bindTexture(gl.TEXTURE_2D, oldTexture)
    }

    private getCompressedGLFormatCode(format: TextureCompressionFormat): number {
        switch (format) {
            case TextureCompressionFormat.None: {
                throw Error('cannot get None code')
            }
            case TextureCompressionFormat.ASTC_4x4: {
                return 0x93b0
            }
            case TextureCompressionFormat.BC7: {
                return 0x8e8c
            }
        }
    }

    private webgpuTexBitmap(state: WebGPUCanvasState, textureHandle: number, drawable: BitmapDrawble) {
        const texture = state.findTexture(textureHandle)
        if (!texture) {
            this._log(`[uploadImageToGPUSync] Can't find the Texture, handle ${textureHandle}`)
            return
        }

        if (drawable.type === ImageDecodedBitmapType.Bytes) {
            for (const chunk of drawable.data) {
                state.device?.queue.writeTexture(
                    { texture, origin: { x: 0, y: chunk.y } },
                    chunk.chunk,
                    {
                        bytesPerRow: chunk.width * 4,
                        rowsPerImage: chunk.height,
                    },
                    { width: chunk.width, height: chunk.height }
                )
            }
        } else if (drawable.type === ImageDecodedBitmapType.Ktx) {
            state.device?.queue.writeTexture(
                { texture, origin: { x: 0, y: 0 } },
                drawable.data,
                {
                    bytesPerRow: drawable.width * 4,
                    rowsPerImage: drawable.height,
                },
                { width: drawable.width, height: drawable.height }
            )
        } else {
            state.device?.queue.copyExternalImageToTexture(
                { source: drawable.data },
                { texture, origin: { x: 0, y: 0 }, premultipliedAlpha: true },
                { width: drawable.data.width, height: drawable.data.height }
            )
        }
    }
}
