/* eslint-disable no-restricted-imports */
import {
    LayerPanelCancelHoverCommandWasmCall,
    LayerPanelCommandClickCommand,
    LayerPanelEnterHoverCommandWasmCall,
    LayerPanelLeftClickCommandWasmCall,
    LayerPanelRightClickCommandWasmCall,
    LayerPanelShiftClickCommand,
    Wukong,
} from '@wukong/bridge-proto'
import classNames from 'classnames'
import { FC, MouseEventHandler, useCallback, useMemo } from 'react'
import { CommitType } from '../../../../document/command/commit-type'
import { isWindows } from '../../../../kernel/util/ua'
import { EditorDataTestId } from '../../../../window'
import { useCommand } from '../../../context/document-context'
import { blurActiveElement } from '../../../utils/active-element'
import { RightClickMenuWrapper } from '../../right-click-menu-wrapper'
import { LayerPanelThumbnailIcon } from '../layer-panel-thumbnail-icon'
import { useDragContext } from '../render-item-drag/context'
import { LayerPanelDragNodeItem } from '../render-item-drag/drag-node-item'
import { LayerPanelItemIcon } from '../render-item-icon'
import { LayerPanelItemIndent } from '../render-item-indent'
import { LayerPanelRenderItemName } from '../render-item-name'
import { LayerPanelItemStatus } from '../render-item-status'
import { RenderItemBackground, RenderItemHoveredBackground, RenderItemIsHovered } from '../type'
import styles from './index.module.less'

interface LayerPanelRenderNodeItemProps {
    nodeItem: Wukong.DocumentProto.VLayerPanelNode
    prevItem: Wukong.DocumentProto.VLayerPanelItemInfo | undefined
    nextItem: Wukong.DocumentProto.VLayerPanelItemInfo | undefined
    index: number
    layerUsingModes: Wukong.DocumentProto.IVariableLayerModeStateVector
}

function getRenderItemBackground(item: Wukong.DocumentProto.VLayerPanelNode): RenderItemBackground {
    if (item.isSelected) {
        return RenderItemBackground.Selected
    }
    if (item.isAncestorSelected) {
        return RenderItemBackground.AncesterSelected
    }
    if (item.isInAILayout) {
        return RenderItemBackground.InsideAILayout
    }
    return RenderItemBackground.None
}

function getRenderItemIsHovered(
    item: Wukong.DocumentProto.VLayerPanelNode,
    dropTargetId?: string
): RenderItemIsHovered {
    if (item.isSelected) {
        return RenderItemIsHovered.None
    }
    if (item.isHovered || item.id === dropTargetId) {
        return RenderItemIsHovered.Hovered
    }
    return RenderItemIsHovered.None
}

function getRenderItemHoveredBackground(
    item: Wukong.DocumentProto.VLayerPanelNode,
    dropTargetId?: string
): RenderItemHoveredBackground {
    if (!getRenderItemIsHovered(item, dropTargetId)) {
        return RenderItemHoveredBackground.None
    }
    if (item.isPurple) {
        return RenderItemHoveredBackground.Purple
    }
    return RenderItemHoveredBackground.Normal
}

export const LayerPanelRenderNodeItem: FC<LayerPanelRenderNodeItemProps> = ({
    nodeItem,
    prevItem,
    nextItem,
    index,
    layerUsingModes,
}) => {
    const { dragStartRef, dropContainerId } = useDragContext()
    const command = useCommand()

    // 处理 renderItem 的鼠标事件
    const handleMouseDown: MouseEventHandler<HTMLDivElement> = useCallback(
        (event) => {
            blurActiveElement()
            const { button, ctrlKey, metaKey, shiftKey } = event
            // 响应左键点击
            if (button === 0 && (!ctrlKey || isWindows())) {
                // command 点击
                // windows 上为 ctrl 点击
                if ((!isWindows() && metaKey) || (isWindows() && ctrlKey)) {
                    command.invokeBridge(CommitType.CommitUndo, LayerPanelCommandClickCommand, {
                        value: nodeItem.id,
                    })
                } else if (shiftKey) {
                    // shift 点击
                    command.invokeBridge(CommitType.CommitUndo, LayerPanelShiftClickCommand, {
                        value: nodeItem.id,
                    })
                } else if (!nodeItem.isSelected && !nodeItem.isAncestorSelected) {
                    // 普通点击，只在非 select 状态下响应 mouseDown 点击
                    command.invokeBridge(CommitType.CommitUndo, LayerPanelLeftClickCommandWasmCall, {
                        value: nodeItem.id,
                    })
                }
            } else if ((button === 0 && ctrlKey && !isWindows()) || button === 2) {
                // 响应右键点击
                // windows 不支持 ctrl+点击 唤起右键菜单
                command.invokeBridge(CommitType.Noop, LayerPanelRightClickCommandWasmCall, {
                    value: nodeItem.id,
                })
            }
        },
        [command, nodeItem.id, nodeItem.isAncestorSelected, nodeItem.isSelected]
    )

    // 左键点击选中图层
    const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(
        (event) => {
            const { button, metaKey, shiftKey, ctrlKey } = event
            // 在选中态响应鼠标左键抬起事件，在拖拽时不响应
            if (
                (nodeItem.isSelected || nodeItem.isAncestorSelected) &&
                !metaKey &&
                !shiftKey &&
                button === 0 &&
                !ctrlKey &&
                !dragStartRef.current
            ) {
                command.invokeBridge(CommitType.CommitUndo, LayerPanelLeftClickCommandWasmCall, {
                    value: nodeItem.id,
                })
            }
        },
        [command, dragStartRef, nodeItem.id, nodeItem.isAncestorSelected, nodeItem.isSelected]
    )

    const handleMouseEnter = useCallback(() => {
        // 拖拽时不响应
        if (dragStartRef.current) {
            return
        }
        command.invokeBridge(CommitType.Noop, LayerPanelEnterHoverCommandWasmCall, {
            value: nodeItem.id,
        })
    }, [command, dragStartRef, nodeItem.id])

    const handleMouseLeave = useCallback(() => {
        // 拖拽时不响应
        if (dragStartRef.current) {
            return
        }
        command.invokeBridge(CommitType.Noop, LayerPanelCancelHoverCommandWasmCall, {
            value: nodeItem.id,
        })
    }, [command, dragStartRef, nodeItem.id])

    const background = useMemo<RenderItemBackground>(() => getRenderItemBackground(nodeItem), [nodeItem])
    const hovered = useMemo<RenderItemIsHovered>(
        () => getRenderItemIsHovered(nodeItem, dropContainerId),
        [nodeItem, dropContainerId]
    )
    const hoveredBackground = useMemo<RenderItemHoveredBackground>(
        () => getRenderItemHoveredBackground(nodeItem, dropContainerId),
        [nodeItem, dropContainerId]
    )

    return useMemo(
        () => (
            <RightClickMenuWrapper
                source={Wukong.DocumentProto.RightClickSource.RIGHT_CLICK_SOURCE_DESIGN_MODE_LAYER_PANEL}
                stopPropagation
                dataTestId={EditorDataTestId.LayerPanelContextMenu}
            >
                <LayerPanelDragNodeItem item={nodeItem} preItem={prevItem} nextItem={nextItem} index={index}>
                    <div
                        className={classNames(
                            styles['layer-panel-render-item'],
                            styles[background],
                            styles[hovered],
                            styles[hoveredBackground]
                        )}
                        data-testid={`layer-panel-render-item-${index}`}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onMouseDown={handleMouseDown}
                        onClick={handleClick}
                    >
                        {/* 层级占位，同时负责展开收起的图标和蒙版的图标 */}
                        <LayerPanelItemIndent
                            indent={nodeItem.indent}
                            maskTypes={nodeItem.maskTypes}
                            expandStatus={nodeItem.expandStatus}
                            id={nodeItem.id!}
                            renderItemBackground={background}
                        />
                        {/* 图标 icon */}
                        <LayerPanelItemIcon
                            id={nodeItem.id!}
                            nodeIcon={nodeItem.nodeIcon as Wukong.DocumentProto.NodeIconData}
                            isSelected={nodeItem.isSelected}
                            isPurple={nodeItem.isPurple}
                            isFontBold={nodeItem.isFontBold}
                            visibleStatus={nodeItem.visibleStatus}
                        />
                        {/* 封面图标 icon */}
                        <LayerPanelThumbnailIcon
                            isThumbnail={nodeItem.isThumbnail}
                            isSelected={nodeItem.isSelected}
                            isPurple={nodeItem.isPurple}
                            isFontBold={nodeItem.isFontBold}
                            visibleStatus={nodeItem.visibleStatus}
                        />
                        {/* 名称 && 模式 */}
                        <LayerPanelRenderItemName
                            id={nodeItem.id!}
                            name={nodeItem.name}
                            isEditing={nodeItem.isEditing}
                            isComponentInsideComponentSet={nodeItem.isComponentInsideComponentSet}
                            isFontBold={nodeItem.isFontBold}
                            isPurple={nodeItem.isPurple}
                            visibleStatus={nodeItem.visibleStatus}
                            layerUsingModes={layerUsingModes}
                        />
                        {/* 显示隐藏状态 */}
                        <LayerPanelItemStatus
                            id={nodeItem.id!}
                            visibleStatus={nodeItem.visibleStatus}
                            lockStatus={nodeItem.lockStatus}
                            isPurple={nodeItem.isPurple}
                        />
                    </div>
                </LayerPanelDragNodeItem>
            </RightClickMenuWrapper>
        ),
        [
            nodeItem,
            prevItem,
            nextItem,
            index,
            background,
            hovered,
            hoveredBackground,
            handleMouseEnter,
            handleMouseLeave,
            handleMouseDown,
            handleClick,
            layerUsingModes,
        ]
    )
}
