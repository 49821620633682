import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    Results: '条结果',
    ThisPage: '当前页面',
    AllPages: '全部页面',
} as const

export const enTranslation = {
    Results: '{{result}}',
    ThisPage: 'This page',
    AllPages: 'All pages',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
