import { getTranslationValue } from '../../../../../../../../../util/src/i18n'

export const zhTranslation = {
    SpacingMode: '间距类型',
    CanvasStacking: '重叠时的遮挡方式',
    AlignTextBaseline: '文本基线对齐',
    Auto: '自动间距',
    Fixed: '固定间距',
    Mixed: '多个值',
    FirstOnTop: '前遮后',
    LastOnTop: '后遮前',
    Auto_synonyms1: '自动',
    AdvancedAutoLayout: '自动布局设置',
    Preview: '效果预览',
} as const

export const enTranslation = {
    SpacingMode: 'spacing mode',
    CanvasStacking: 'Canvas stacking',
    AlignTextBaseline: 'Align text baseline',
    Auto: 'Auto',
    Fixed: 'Fixed',
    Mixed: 'Mixed',
    FirstOnTop: 'First on top',
    LastOnTop: 'Last on top',
    Auto_synonyms1: 'Auto',
    AdvancedAutoLayout: 'Advanced auto layout settings',
    Preview: 'Preview',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
