import { Wukong } from '@wukong/bridge-proto'
import { WKFrog } from './frog-service'
import { Ticket } from '../../document/synergy/synergy-ticket'

export function AddFrogRecord(arg: Wukong.DocumentProto.IArg_FrogParam, ticket: Ticket) {
    const record = {
        url: arg.url!,
        eventId: arg.eventId!,
        currentPage: arg.currentPage ?? undefined,
        customExtend: arg.customExtend ?? undefined,
        duration: arg.duration ?? undefined,
        eventAction: arg.eventAction ?? undefined,
        eventName: arg.eventName ?? undefined,
        keyFrom: arg.keyFrom ?? undefined,
        refPage: arg.refPage ?? undefined,
    }

    if (arg.needRole && record.customExtend) {
        record.customExtend.role = ticket.role
    }

    WKFrog.addFrogRecord(record)
}
