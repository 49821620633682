import { useMemo, useState } from 'react'
import { useMount } from 'react-use'
import { environment } from '../../../../environment'
import {
    ContractPeriod,
    ContractSpecification,
    CurrencyType,
    ProductVO,
    SeatType,
} from '../../../../kernel/interface/type'
import { GetContractAllProducts } from '../../../../kernel/request/contract'
import { findProductFeeUnit } from '../../utils'

export function usePayUpgradePrice() {
    const [contractAllProducts, setContractAllProducts] = useState<ProductVO[]>([])

    const price = useMemo(() => {
        const currencyType = environment.isAbroad ? CurrencyType.Usd : CurrencyType.Cny
        const organizationDevYear = findProductFeeUnit({
            allProducts: contractAllProducts,
            seatType: SeatType.developer,
            currencyType,
            contractPeriod: ContractPeriod.Year,
            specification: ContractSpecification.Organization,
        })
        const organizationDesignYear = findProductFeeUnit({
            allProducts: contractAllProducts,
            seatType: SeatType.designer,
            currencyType,
            contractPeriod: ContractPeriod.Year,
            specification: ContractSpecification.Organization,
        })
        const professionDevYear = findProductFeeUnit({
            allProducts: contractAllProducts,
            seatType: SeatType.developer,
            currencyType,
            contractPeriod: ContractPeriod.Year,
            specification: ContractSpecification.Profession,
        })
        const professionDesignYear = findProductFeeUnit({
            allProducts: contractAllProducts,
            seatType: SeatType.designer,
            currencyType,
            contractPeriod: ContractPeriod.Year,
            specification: ContractSpecification.Profession,
        })
        const professionDevMonthly = findProductFeeUnit({
            allProducts: contractAllProducts,
            seatType: SeatType.developer,
            currencyType,
            contractPeriod: ContractPeriod.Month,
            specification: ContractSpecification.Profession,
        })
        const professionDesignMonthly = findProductFeeUnit({
            allProducts: contractAllProducts,
            seatType: SeatType.designer,
            currencyType,
            contractPeriod: ContractPeriod.Month,
            specification: ContractSpecification.Profession,
        })
        return {
            organizationDevYear,
            organizationDesignYear,
            professionDevYear,
            professionDesignYear,
            professionDevMonthly,
            professionDesignMonthly,
        }
    }, [contractAllProducts])

    useMount(() => {
        new GetContractAllProducts()
            .start()
            .catch(() => [] as ProductVO[])
            .then((res) => setContractAllProducts(res))
    })

    return { price }
}
