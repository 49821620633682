export interface BenchmarkCase {
    name: string
    runner: BenchmarkRunner
}

export interface BenchmarkReport {
    name: string
    count: number
    time: number
}

export type BenchmarkRunner = (count: number, start: () => void, end: () => void) => void

export namespace Benchmark {
    export const BENCH_REPORT_FLAG = '-BENCH'
    export const shouldRunBench = () => process.argv.includes(BENCH_REPORT_FLAG)
}

/*
 * 用于跑 benchmark 的 js 单元
 * */
export class BenchSuite {
    private name = ''
    private start = 1
    private end = 1
    private step = 1
    private rounds = 1
    private cases: BenchmarkCase[] = []

    constructor(name = '') {
        this.name = name
    }

    /**
     * range
     * @param {Number} start
     * @param {Number} end
     * @param {Number} step
     */
    public range(start: number, end: number, step: number): BenchSuite {
        this.start = start
        this.end = end
        this.step = step

        return this
    }

    /**
     * @param {Number} n
     */
    public round(n: number): BenchSuite {
        this.rounds = n

        return this
    }

    /**
     * @param {Case['name']} name
     * @param {Case['runner']} runner
     */
    public addCase(name: string, runner: BenchmarkRunner): BenchSuite {
        this.cases.push({ name, runner })

        return this
    }

    /**
     *
     * @param {(_: BenchmarkReport) => void} onCaseComplete
     */
    public run(onCaseComplete = (_: BenchmarkReport) => {}): void {
        for (const benchCase of this.cases) {
            for (let i = this.start; i < this.end; i *= this.step) {
                const report: BenchmarkReport = { name: benchCase.name, time: 0, count: i }
                let st = 0,
                    ed = 0

                for (let j = 0; j < this.rounds; ++j) {
                    benchCase.runner(
                        i,
                        () => {
                            st = performance.now()
                        },
                        () => {
                            ed = performance.now()
                        }
                    )

                    report.time += ed - st
                }

                report.time /= this.rounds

                onCaseComplete(report)
            }
        }
    }
}
