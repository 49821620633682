import type { ImagePaint } from '../../../../document/node/node'
import type { HTMLAttributes } from 'react'
import { usePaintImageThumbnail } from '../../../utils/thumbnail-cache/paints-thumbnail'
import { ImageBlock } from '../../atom/color-block/image'

interface PaintIconImageProps extends HTMLAttributes<HTMLDivElement> {
    paint: ImagePaint
}
export function PaintIconImage(props: PaintIconImageProps) {
    const { paint, ...otherProps } = props
    const imagePaintUrl = usePaintImageThumbnail({
        width: 16,
        height: 16,
        multiple: 2,
        paint,
    })

    return <ImageBlock {...otherProps} src={imagePaintUrl} />
}
