import constate from 'constate'
import { useCallback, useState } from 'react'

export interface WKCollapseContextProps {
    selectionMode: 'single' | 'multiple'
    defaultSelection?: string[]
    selection?: string[]
    onSelectionChange?: (selection: string[]) => void
}

const useCollapse = ({
    selectionMode,
    defaultSelection = [],
    selection,
    onSelectionChange,
}: WKCollapseContextProps) => {
    const [editingSelection, setEditingSelection] = useState<string[]>(defaultSelection)

    const isControlled = selection !== undefined
    const computedSelection = isControlled ? selection : editingSelection

    const onItemOpen = useCallback(
        (value: string) => {
            if (selectionMode === 'single') {
                const nextSelection = [value]
                if (isControlled) {
                    onSelectionChange?.(nextSelection)
                } else {
                    setEditingSelection(nextSelection)
                    onSelectionChange?.(nextSelection)
                }
            } else {
                const nextSelection = [...computedSelection, value]
                if (isControlled) {
                    onSelectionChange?.(nextSelection)
                } else {
                    setEditingSelection(nextSelection)
                    onSelectionChange?.(nextSelection)
                }
            }
        },
        [selectionMode, isControlled, onSelectionChange, computedSelection]
    )

    const onItemClose = useCallback(
        (value: string) => {
            if (selectionMode === 'single') {
                const nextSelection: string[] = []
                if (isControlled) {
                    onSelectionChange?.(nextSelection)
                } else {
                    setEditingSelection(nextSelection)
                    onSelectionChange?.(nextSelection)
                }
            } else {
                const nextSelection = computedSelection.filter((item) => item !== value)
                if (isControlled) {
                    onSelectionChange?.(nextSelection)
                } else {
                    setEditingSelection(nextSelection)
                    onSelectionChange?.(nextSelection)
                }
            }
        },
        [selectionMode, isControlled, onSelectionChange, computedSelection]
    )

    const getIsOpen = useCallback(
        (value: string) => {
            return computedSelection.includes(value)
        },
        [computedSelection]
    )

    return {
        onItemOpen,
        onItemClose,
        getIsOpen,
    }
}

export const [CollapseContextProvider, useCollapseContext] = constate(useCollapse)
