import classnames from 'classnames'
import { useMemo } from 'react'
import {
    DraggablePopupV2,
    DropdownDefaultCustomNode,
    DropdownV2,
    IconComponentLibrary16,
    InputV2,
    ScrollView,
    WKButton,
} from '../../../../../../../ui-lib/src'
import { ToKeyCode } from '../../../../../document/util/keycode'
import { useLibraryComponentService } from '../../../../../main/app-context'
import { KeyboardReceiver } from '../../../../../main/keyboard-receiver/component'
import { LibraryStyleTestId } from '../../../../../window'
import { useRenderColorSpace } from '../../../color-profile'
import { useKeyboardStyleStyleList } from '../hooks/use-keyboard-style-list'
import style from '../style-color/style-color.module.less'
import { LayoutGridStyleEditor } from '../style-panel/layout-grid-style-editor/layout-grid-style-editor'
import { StyleTitle, StyleType } from '../style-title/style-title'
import { RemoteStyleLayoutGridListItem } from './remote-style-layout-grid-list-item'
import { StyleLayoutGridListItem } from './style-layout-grid-list-item'
import { translation } from './style-layout-grid.translation'
import { UnknowStyleColorListItem } from './unknown-style-layout-grid-list-item'
import { StyleLayoutGridProps, useStyleLayoutGrid } from './use-style-layout-grid'

export function StyleLayoutGrid(props: StyleLayoutGridProps): JSX.Element {
    const {
        open,
        position,
        localStyleMap,
        remoteStyleList,
        isNoStyle,
        hasRemoteStyle,
        onClickAddStyle,
        onChangeSearchInput,
        showStyleMap,
        onContextMenu,
        onClickApplyStyle,
        selectStyleId,
        onClickHoverIcon,
        styleIdEditor,
        openStyleEditor,
        positionEditor,
        onCloseEditor,
        contextMenuInfo,
        onCloseContextMenu,
        editStyle,
        deleteStyle,
        onFirstMove,
        jumpToOrigin,
        isRemoteStyle,
        unknownStyleGroup,
        rightClickStyleId,
        setRightClickStyleId,
        titleContainerRef,
        isCreateStyle,
        createStyle,
        effectCreateStyle,
    } = useStyleLayoutGrid(props)
    const colorSpace = useRenderColorSpace()

    const {
        libraryModalRouterService: { goToRemoteLibraryHome },
    } = useLibraryComponentService()

    const showStyleMapEmpty: boolean = useMemo(() => {
        let count = 0

        showStyleMap.map(({ items }) => {
            count += items.length
        })
        return count === 0
    }, [showStyleMap])

    const noSearchResult = useMemo(() => {
        return (
            showStyleMapEmpty &&
            !remoteStyleList?.filter((v) => v.groups.length > 0).length &&
            !unknownStyleGroup?.groups.size
        )
    }, [remoteStyleList, showStyleMapEmpty, unknownStyleGroup?.groups.size])

    const needSplitLineWithRemoteStyleListItem = (index: number) => {
        const hasItemBeforeRemoteStyle = !showStyleMapEmpty || !!unknownStyleGroup?.groups.size
        if (hasItemBeforeRemoteStyle) {
            return true
        }

        const firstItemIndex = remoteStyleList?.findIndex((library) => library.groups.length > 0) ?? -1
        if (firstItemIndex !== -1 && index > firstItemIndex) {
            return true
        }
        return false
    }
    const maxPreSelectIndex = useMemo(() => {
        let optionSum = 0
        for (const { items } of showStyleMap) {
            optionSum += items.length ?? 0
        }
        if (unknownStyleGroup) {
            for (const [_, styles] of unknownStyleGroup.groups) {
                optionSum += styles.length ?? 0
            }
        }
        if (remoteStyleList) {
            for (const { groups } of remoteStyleList) {
                for (const { items } of groups) {
                    optionSum += items.length ?? 0
                }
            }
        }
        return Math.max(optionSum - 1, 0)
    }, [remoteStyleList, showStyleMap, unknownStyleGroup])

    const keyboardStyleStyleList = useKeyboardStyleStyleList({ maxPreSelectIndex })

    const renderListItems = () => {
        let optionIndex = 0
        const _selectStyleId = selectStyleId ? selectStyleId : undefined
        return (
            <>
                {showStyleMap.map(({ name, items }, index) => (
                    <div key={index}>
                        {name ? <div className={style.h2}>{name}</div> : null}
                        <div>
                            {items.map((item, index2) => (
                                <StyleLayoutGridListItem
                                    key={item.id}
                                    item={item}
                                    openStyleId={open && openStyleEditor ? styleIdEditor : undefined}
                                    onContextMenu={(...args) => {
                                        setRightClickStyleId(`${item.id}-show-${index}-${index2}`)
                                        onContextMenu(...args)
                                    }}
                                    dataTestId={`${LibraryStyleTestId.LocalLayoutGridStyleItem}-${item.id}`}
                                    onSelectItem={onClickApplyStyle}
                                    onClickHoverIcon={onClickHoverIcon}
                                    isRightClickStyle={rightClickStyleId === `${item.id}-show-${index}-${index2}`}
                                    index={optionIndex++}
                                    selectStyleId={_selectStyleId}
                                    setPreselectIndex={keyboardStyleStyleList.setPreselectIndex}
                                    trySetPreselectIndex={keyboardStyleStyleList.trySetPreselectIndex}
                                    recordEnterCallback={keyboardStyleStyleList.recordEnterCallback}
                                />
                            ))}
                        </div>
                        <div className={style.paddingBottom8}></div>
                    </div>
                ))}

                {!!unknownStyleGroup?.groups.size && (
                    <div key={unknownStyleGroup.docId}>
                        <div
                            className={classnames(style.h1, {
                                [style.splitLine]: !showStyleMapEmpty,
                            })}
                        >
                            {unknownStyleGroup?.libraryName}
                        </div>
                        {[...unknownStyleGroup.groups.entries()].map(([groupName, styles], index) => (
                            <div key={index}>
                                {groupName ? <div className={style.h2}>{groupName}</div> : null}
                                {styles.map((item, index2) => (
                                    <UnknowStyleColorListItem
                                        key={item.id}
                                        docId={unknownStyleGroup.docId || ''}
                                        item={item}
                                        openStyleId={open && openStyleEditor ? styleIdEditor : undefined}
                                        onContextMenu={(...args) => {
                                            setRightClickStyleId(`${item.id}-unknown-${index}-${index2}`)
                                            onContextMenu(...args)
                                        }}
                                        onSelectItem={onClickApplyStyle}
                                        onClickHoverIcon={onClickHoverIcon}
                                        isRightClickStyle={
                                            rightClickStyleId === `${item.id}-unknown-${index}-${index2}`
                                        }
                                        index={optionIndex++}
                                        selectStyleId={_selectStyleId}
                                        setPreselectIndex={keyboardStyleStyleList.setPreselectIndex}
                                        trySetPreselectIndex={keyboardStyleStyleList.trySetPreselectIndex}
                                        recordEnterCallback={keyboardStyleStyleList.recordEnterCallback}
                                    />
                                ))}
                                <div className={style.paddingBottom8}></div>
                            </div>
                        ))}
                    </div>
                )}

                {remoteStyleList?.map(
                    (library, index) =>
                        library.groups.length > 0 && (
                            <div key={library.libraryId}>
                                <div
                                    className={classnames(style.h1, {
                                        [style.splitLine]: needSplitLineWithRemoteStyleListItem(index),
                                    })}
                                >
                                    {library?.libraryName}
                                </div>
                                {library.groups.map(({ name, items }, index2) => (
                                    <div key={index2}>
                                        {name ? <div className={style.h2}>{name}</div> : null}
                                        {items.map((item, index3) => (
                                            <RemoteStyleLayoutGridListItem
                                                key={item.id}
                                                docId={library.docId || ''}
                                                item={item}
                                                dataTestId={`${LibraryStyleTestId.RemoteLayoutGridStyleItem}-${library.libraryName}-${index3}`}
                                                selectStyleKey={props.selectStyleNodeKey}
                                                openStyleId={open && openStyleEditor ? styleIdEditor : undefined}
                                                onContextMenu={(...args) => {
                                                    setRightClickStyleId(
                                                        `${item.id}-remote-${index}-${index2}-${index3}`
                                                    )
                                                    onContextMenu(...args)
                                                }}
                                                onSelectItem={onClickApplyStyle}
                                                onClickHoverIcon={onClickHoverIcon}
                                                isRightClickStyle={
                                                    rightClickStyleId ===
                                                    `${item.id}-remote-${index}-${index2}-${index3}`
                                                }
                                                index={optionIndex++}
                                                selectStyleId={_selectStyleId}
                                                setPreselectIndex={keyboardStyleStyleList.setPreselectIndex}
                                                trySetPreselectIndex={keyboardStyleStyleList.trySetPreselectIndex}
                                                recordEnterCallback={keyboardStyleStyleList.recordEnterCallback}
                                            />
                                        ))}
                                        <div className={style.paddingBottom8}></div>
                                    </div>
                                ))}
                            </div>
                        )
                )}
            </>
        )
    }

    return (
        <div>
            <DraggablePopupV2
                visible={open}
                position={position}
                closable={false}
                width={216}
                positionRightBase
                onFirstMove={onFirstMove}
                header={
                    <div className={style.titleContainer} ref={titleContainerRef}>
                        <StyleTitle
                            styleType={StyleType.LayoutGridStyle}
                            hiddenLibraryIcon={localStyleMap?.length === 0 && !hasRemoteStyle}
                            isList={true}
                            onClickLibraryIcon={goToRemoteLibraryHome}
                            onClickAddIcon={onClickAddStyle}
                        />
                    </div>
                }
                bodyClassName="p-0"
                footer={null}
            >
                <div className={style.contentContainer}>
                    <InputV2.Search
                        style={{ margin: 8 }}
                        onSearch={onChangeSearchInput}
                        autoFocus
                        onInput={keyboardStyleStyleList.onInput}
                        onKeyDown={keyboardStyleStyleList.onKeyDown}
                        placeholder={translation('Search')}
                    />
                    {isNoStyle ? (
                        <div className={style.emptyStyle}>
                            <div className={style.emptyStyle_text}> {translation('NoGridStyles')}</div>
                            <div className={style.emptyStyle_button}>
                                <WKButton
                                    type="secondary"
                                    className={style.button}
                                    onClick={goToRemoteLibraryHome}
                                    icon={<IconComponentLibrary16 />}
                                >
                                    {translation('BrowseLibraries')}
                                </WKButton>
                            </div>
                        </div>
                    ) : noSearchResult ? (
                        <div className={style.emptyStyle}>
                            <div className={style.emptyStyle_text}>{translation('NoStylesMatch')}</div>
                        </div>
                    ) : (
                        <ScrollView
                            className={classnames(style.content)}
                            key="list"
                            selectKey={keyboardStyleStyleList.preselectIndex}
                            ref={keyboardStyleStyleList.scrollViewRef}
                            block="nearest"
                            scrollbar={{ autoHeight: true, autoHeightMin: 0, autoHeightMax: 352 }}
                        >
                            <KeyboardReceiver keyCode={ToKeyCode.All} onKeydown={keyboardStyleStyleList.onKeyDown}>
                                {renderListItems()}
                            </KeyboardReceiver>
                        </ScrollView>
                    )}
                </div>
            </DraggablePopupV2>

            <LayoutGridStyleEditor
                styleId={styleIdEditor}
                colorSpace={colorSpace}
                visible={open && openStyleEditor}
                position={positionEditor}
                isRemoteStyle={isRemoteStyle}
                onCancel={onCloseEditor}
                onOpenSourceDocument={jumpToOrigin}
                createStyle={createStyle}
                onClickCreate={onCloseEditor}
                effectCreateStyle={effectCreateStyle}
            />
            <DropdownV2.NoTriggerMultiLevel<DropdownDefaultCustomNode & { handler?: () => void }>
                isOpenState={open && !!contextMenuInfo}
                triggerRect={() => contextMenuInfo?.rectContainer!}
                onClose={onCloseContextMenu}
                onChange={(v) => v.customNode?.handler?.()}
                pickRest={{ onMouseDown: (e) => e.stopPropagation() }}
                onKeyboard={(e) => e.code === 'Escape' && e.stopPropagation()}
                items={[
                    {
                        name: translation('GoToStyle'),
                        hidden: !isRemoteStyle,
                        customNode: {
                            handler: jumpToOrigin,
                        },
                    },
                    {
                        name: translation('EditStyle'),
                        hidden: isRemoteStyle,
                        customNode: {
                            handler: editStyle,
                        },
                    },
                    {
                        name: translation('DeleteStyle'),
                        hidden: isRemoteStyle,
                        customNode: {
                            handler: deleteStyle,
                        },
                    },
                ]}
            />
        </div>
    )
}
