/* eslint-disable no-restricted-imports */
import { DragMoveBox, Resize } from '../../../../../../ui-lib/src'
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useCommentService } from '../../../../main/app-context'
import { CommentDataTestId } from '../../../../window'
import { Position } from '../type'
import classes from './create-position.module.less'
import { useCanvas } from '../../../../external-store/atoms/create-canvas'

interface CreatePositionProps {
    children?: React.ReactNode
    changeMaxHeight: (maxHeight: number) => void
}
export function CreatePosition(props: CreatePositionProps) {
    const canvas = useCanvas()
    const { changeMaxHeight } = props
    const divRef = useRef<HTMLDivElement>(null)
    const [maxHeight, setMaxHeight] = useState<number>()
    const keys = useRef<string[]>([])
    const commentService = useCommentService()
    const anchorPositionRef = useRef<Position>({ left: 0, top: 0 }) // 原始位置
    const offsetRef = useRef<Position>({ left: 0, top: 0 }) // 拖拽偏移量
    const coordinateRef = useRef<{ canvasDeltaX: number; canvasDeltaY: number }>({ canvasDeltaX: 0, canvasDeltaY: 0 })

    const bubbleWidthHeight = 32
    const inputHeight = 44
    const inputOffsetBubble = 16

    const transformCoordinate = useCallback(
        (position: Position) => {
            const canvasRect = canvas.getBoundingClientRect()
            const { left, top } = position
            return {
                left:
                    canvasRect.left + coordinateRef.current.canvasDeltaX + left + bubbleWidthHeight + inputOffsetBubble,
                top:
                    canvasRect.top +
                    coordinateRef.current.canvasDeltaY +
                    top -
                    inputHeight +
                    (inputHeight - bubbleWidthHeight) / 2,
            }
        },
        [canvas]
    )

    const setStyle = useCallback(() => {
        if (!divRef.current) {
            return
        }
        const canvasRect = canvas.getBoundingClientRect()
        const divRect = divRef.current.getBoundingClientRect()

        const marginY = 8
        const marginX = 8

        // 1. 实际位置
        let left = anchorPositionRef.current.left + offsetRef.current.left
        let top = anchorPositionRef.current.top + offsetRef.current.top

        // 2. 计算显示在靠左或靠右
        const isOverRight = left + divRect.width + marginX > canvasRect.right
        if (isOverRight) {
            left -= divRect.width + 2 * inputOffsetBubble + bubbleWidthHeight
        }

        // 3. 限制不能超出 viewport
        if (canvasRect && divRect) {
            const minLeft = canvasRect.left + marginX
            const maxLeft = canvasRect.right - divRect.width - marginX
            left = Math.min(maxLeft, Math.max(minLeft, left))

            const minTop = canvasRect.top + marginY
            const maxTop = canvasRect.bottom - divRect.height - marginY
            top = Math.min(maxTop, Math.max(minTop, top))
        }
        if (maxHeight) {
            divRef.current.setAttribute('style', `left:${left}px;top:${top}px;max-height:${maxHeight}px;`)
        } else {
            divRef.current.setAttribute('style', `left:${left}px;top:${top}px;`)
        }
    }, [canvas, maxHeight])

    const onMoving = useCallback(
        (nextPosition: Position) => {
            offsetRef.current = {
                left: nextPosition.left - anchorPositionRef.current.left,
                top: nextPosition.top - anchorPositionRef.current.top,
            }
            setStyle()
        },
        [setStyle]
    )

    const onChangeResize = useCallback(() => {
        const canvasRect = canvas.getBoundingClientRect()
        const divRect = divRef.current?.getBoundingClientRect()
        if (!divRect || !canvasRect) {
            return
        }
        const minEdge = 8
        const currentMaxHeight = canvasRect.height - 2 * minEdge
        setMaxHeight(currentMaxHeight)
        changeMaxHeight(currentMaxHeight)
        let top = divRect.top
        const isOverBottom = divRect.bottom + minEdge > canvasRect.bottom
        if (isOverBottom) {
            top = Math.max(canvasRect.top + canvasRect.height - minEdge - divRect.height, canvasRect.top + minEdge)
            offsetRef.current = {
                left: divRect.left - anchorPositionRef.current.left,
                top: top - anchorPositionRef.current.top,
            }
            setStyle()
        }
    }, [canvas, changeMaxHeight, setStyle])

    const updateCoordinate = useCallback((canvasDeltaX: number, canvasDeltaY: number) => {
        coordinateRef.current = { canvasDeltaX, canvasDeltaY }
    }, [])

    const updateAnchor = useCallback(
        (anchorPosition: Position) => {
            anchorPositionRef.current = transformCoordinate(anchorPosition)
            setStyle()
        },
        [setStyle, transformCoordinate]
    )

    const unsubscribe = useCallback(() => {
        keys.current.forEach((key) => commentService.positionService.unregisterBubble(key))
        keys.current = []
    }, [commentService.positionService])

    const subscribe = useCallback(() => {
        unsubscribe()
        const key1 = commentService.positionService.registerCoordinate(updateCoordinate)
        const key2 = commentService.positionService.registerBubbleAnchor(undefined, updateAnchor)
        keys.current.push(key1, key2)
    }, [unsubscribe, updateAnchor, updateCoordinate, commentService.positionService])

    useLayoutEffect(() => {
        subscribe()
    }, [subscribe])

    useEffect(() => {
        return unsubscribe
    }, [unsubscribe])

    return (
        <DragMoveBox
            ref={divRef}
            onMoving={onMoving}
            className={classes.commentPosition}
            dataTestId={CommentDataTestId.CommentDraftInput}
        >
            <Resize onChangeResize={onChangeResize} connectWindowResize />
            {props.children}
        </DragMoveBox>
    )
}
