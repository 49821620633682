/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from 'protobufjs/minimal'

// Common aliases
const $Reader = $protobuf.Reader,
    $Writer = $protobuf.Writer,
    $util = $protobuf.util

// Exported root namespace
const $root = $protobuf.roots['default'] || ($protobuf.roots['default'] = {})

export const KeyValue = ($root.KeyValue = (() => {
    /**
     * Properties of a KeyValue.
     * @exports IKeyValue
     * @interface IKeyValue
     * @property {string} key KeyValue key
     * @property {string} value KeyValue value
     */

    /**
     * Constructs a new KeyValue.
     * @exports KeyValue
     * @classdesc Represents a KeyValue.
     * @implements IKeyValue
     * @constructor
     * @param {IKeyValue=} [p] Properties to set
     */
    function KeyValue(p) {
        if (p) for (var ks = Object.keys(p), i = 0; i < ks.length; ++i) if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    /**
     * KeyValue key.
     * @member {string} key
     * @memberof KeyValue
     * @instance
     */
    KeyValue.prototype.key = ''

    /**
     * KeyValue value.
     * @member {string} value
     * @memberof KeyValue
     * @instance
     */
    KeyValue.prototype.value = ''

    /**
     * Creates a new KeyValue instance using the specified properties.
     * @function create
     * @memberof KeyValue
     * @static
     * @param {IKeyValue=} [properties] Properties to set
     * @returns {KeyValue} KeyValue instance
     */
    KeyValue.create = function create(properties) {
        return new KeyValue(properties)
    }

    /**
     * Encodes the specified KeyValue message. Does not implicitly {@link KeyValue.verify|verify} messages.
     * @function encode
     * @memberof KeyValue
     * @static
     * @param {IKeyValue} m KeyValue message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    KeyValue.encode = function encode(m, w) {
        if (!w) w = $Writer.create()
        w.uint32(10).string(m.key)
        w.uint32(18).string(m.value)
        return w
    }

    /**
     * Encodes the specified KeyValue message, length delimited. Does not implicitly {@link KeyValue.verify|verify} messages.
     * @function encodeDelimited
     * @memberof KeyValue
     * @static
     * @param {IKeyValue} message KeyValue message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    KeyValue.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a KeyValue message from the specified reader or buffer.
     * @function decode
     * @memberof KeyValue
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {KeyValue} KeyValue
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    KeyValue.decode = function decode(r, l) {
        if (!(r instanceof $Reader)) r = $Reader.create(r)
        var c = l === undefined ? r.len : r.pos + l,
            m = new $root.KeyValue()
        while (r.pos < c) {
            var t = r.uint32()
            switch (t >>> 3) {
                case 1: {
                    m.key = r.string()
                    break
                }
                case 2: {
                    m.value = r.string()
                    break
                }
                default:
                    r.skipType(t & 7)
                    break
            }
        }
        if (!m.hasOwnProperty('key')) throw $util.ProtocolError("missing required 'key'", { instance: m })
        if (!m.hasOwnProperty('value')) throw $util.ProtocolError("missing required 'value'", { instance: m })
        return m
    }

    /**
     * Decodes a KeyValue message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof KeyValue
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {KeyValue} KeyValue
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    KeyValue.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
    }

    /**
     * Creates a KeyValue message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof KeyValue
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {KeyValue} KeyValue
     */
    KeyValue.fromObject = function fromObject(d) {
        if (d instanceof $root.KeyValue) return d
        var m = new $root.KeyValue()
        if (d.key != null) {
            m.key = String(d.key)
        }
        if (d.value != null) {
            m.value = String(d.value)
        }
        return m
    }

    /**
     * Creates a plain object from a KeyValue message. Also converts values to other types if specified.
     * @function toObject
     * @memberof KeyValue
     * @static
     * @param {KeyValue} m KeyValue
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    KeyValue.toObject = function toObject(m, o) {
        if (!o) o = {}
        var d = {}
        if (o.defaults) {
            d.key = ''
            d.value = ''
        }
        if (m.key != null && m.hasOwnProperty('key')) {
            d.key = m.key
        }
        if (m.value != null && m.hasOwnProperty('value')) {
            d.value = m.value
        }
        return d
    }

    /**
     * Converts this KeyValue to JSON.
     * @function toJSON
     * @memberof KeyValue
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    KeyValue.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return KeyValue
})())

export const Header = ($root.Header = (() => {
    /**
     * Properties of a Header.
     * @exports IHeader
     * @interface IHeader
     * @property {Array.<IKeyValue>|null} [keyValues] Header keyValues
     */

    /**
     * Constructs a new Header.
     * @exports Header
     * @classdesc Represents a Header.
     * @implements IHeader
     * @constructor
     * @param {IHeader=} [p] Properties to set
     */
    function Header(p) {
        this.keyValues = []
        if (p) for (var ks = Object.keys(p), i = 0; i < ks.length; ++i) if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    /**
     * Header keyValues.
     * @member {Array.<IKeyValue>} keyValues
     * @memberof Header
     * @instance
     */
    Header.prototype.keyValues = $util.emptyArray

    /**
     * Creates a new Header instance using the specified properties.
     * @function create
     * @memberof Header
     * @static
     * @param {IHeader=} [properties] Properties to set
     * @returns {Header} Header instance
     */
    Header.create = function create(properties) {
        return new Header(properties)
    }

    /**
     * Encodes the specified Header message. Does not implicitly {@link Header.verify|verify} messages.
     * @function encode
     * @memberof Header
     * @static
     * @param {IHeader} m Header message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Header.encode = function encode(m, w) {
        if (!w) w = $Writer.create()
        if (m.keyValues != null && m.keyValues.length) {
            for (var i = 0; i < m.keyValues.length; ++i)
                $root.KeyValue.encode(m.keyValues[i], w.uint32(10).fork()).ldelim()
        }
        return w
    }

    /**
     * Encodes the specified Header message, length delimited. Does not implicitly {@link Header.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Header
     * @static
     * @param {IHeader} message Header message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Header.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a Header message from the specified reader or buffer.
     * @function decode
     * @memberof Header
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {Header} Header
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Header.decode = function decode(r, l) {
        if (!(r instanceof $Reader)) r = $Reader.create(r)
        var c = l === undefined ? r.len : r.pos + l,
            m = new $root.Header()
        while (r.pos < c) {
            var t = r.uint32()
            switch (t >>> 3) {
                case 1: {
                    if (!(m.keyValues && m.keyValues.length)) m.keyValues = []
                    m.keyValues.push($root.KeyValue.decode(r, r.uint32()))
                    break
                }
                default:
                    r.skipType(t & 7)
                    break
            }
        }
        return m
    }

    /**
     * Decodes a Header message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Header
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Header} Header
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Header.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
    }

    /**
     * Creates a Header message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Header
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {Header} Header
     */
    Header.fromObject = function fromObject(d) {
        if (d instanceof $root.Header) return d
        var m = new $root.Header()
        if (d.keyValues) {
            if (!Array.isArray(d.keyValues)) throw TypeError('.Header.keyValues: array expected')
            m.keyValues = []
            for (var i = 0; i < d.keyValues.length; ++i) {
                if (typeof d.keyValues[i] !== 'object') throw TypeError('.Header.keyValues: object expected')
                m.keyValues[i] = $root.KeyValue.fromObject(d.keyValues[i])
            }
        }
        return m
    }

    /**
     * Creates a plain object from a Header message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Header
     * @static
     * @param {Header} m Header
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Header.toObject = function toObject(m, o) {
        if (!o) o = {}
        var d = {}
        if (o.arrays || o.defaults) {
            d.keyValues = []
        }
        if (m.keyValues && m.keyValues.length) {
            d.keyValues = []
            for (var j = 0; j < m.keyValues.length; ++j) {
                d.keyValues[j] = $root.KeyValue.toObject(m.keyValues[j], o)
            }
        }
        return d
    }

    /**
     * Converts this Header to JSON.
     * @function toJSON
     * @memberof Header
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Header.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return Header
})())

export const Entry = ($root.Entry = (() => {
    /**
     * Properties of an Entry.
     * @exports IEntry
     * @interface IEntry
     * @property {number|Long} timestamp Entry timestamp
     * @property {number|Long} seqId Entry seqId
     * @property {string} url Entry url
     * @property {number} category Entry category
     * @property {Array.<IKeyValue>|null} [keyValues] Entry keyValues
     */

    /**
     * Constructs a new Entry.
     * @exports Entry
     * @classdesc Represents an Entry.
     * @implements IEntry
     * @constructor
     * @param {IEntry=} [p] Properties to set
     */
    function Entry(p) {
        this.keyValues = []
        if (p) for (var ks = Object.keys(p), i = 0; i < ks.length; ++i) if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    /**
     * Entry timestamp.
     * @member {number|Long} timestamp
     * @memberof Entry
     * @instance
     */
    Entry.prototype.timestamp = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

    /**
     * Entry seqId.
     * @member {number|Long} seqId
     * @memberof Entry
     * @instance
     */
    Entry.prototype.seqId = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

    /**
     * Entry url.
     * @member {string} url
     * @memberof Entry
     * @instance
     */
    Entry.prototype.url = ''

    /**
     * Entry category.
     * @member {number} category
     * @memberof Entry
     * @instance
     */
    Entry.prototype.category = 0

    /**
     * Entry keyValues.
     * @member {Array.<IKeyValue>} keyValues
     * @memberof Entry
     * @instance
     */
    Entry.prototype.keyValues = $util.emptyArray

    /**
     * Creates a new Entry instance using the specified properties.
     * @function create
     * @memberof Entry
     * @static
     * @param {IEntry=} [properties] Properties to set
     * @returns {Entry} Entry instance
     */
    Entry.create = function create(properties) {
        return new Entry(properties)
    }

    /**
     * Encodes the specified Entry message. Does not implicitly {@link Entry.verify|verify} messages.
     * @function encode
     * @memberof Entry
     * @static
     * @param {IEntry} m Entry message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Entry.encode = function encode(m, w) {
        if (!w) w = $Writer.create()
        w.uint32(8).int64(m.timestamp)
        w.uint32(16).int64(m.seqId)
        w.uint32(26).string(m.url)
        w.uint32(32).int32(m.category)
        if (m.keyValues != null && m.keyValues.length) {
            for (var i = 0; i < m.keyValues.length; ++i)
                $root.KeyValue.encode(m.keyValues[i], w.uint32(42).fork()).ldelim()
        }
        return w
    }

    /**
     * Encodes the specified Entry message, length delimited. Does not implicitly {@link Entry.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Entry
     * @static
     * @param {IEntry} message Entry message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Entry.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes an Entry message from the specified reader or buffer.
     * @function decode
     * @memberof Entry
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {Entry} Entry
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Entry.decode = function decode(r, l) {
        if (!(r instanceof $Reader)) r = $Reader.create(r)
        var c = l === undefined ? r.len : r.pos + l,
            m = new $root.Entry()
        while (r.pos < c) {
            var t = r.uint32()
            switch (t >>> 3) {
                case 1: {
                    m.timestamp = r.int64()
                    break
                }
                case 2: {
                    m.seqId = r.int64()
                    break
                }
                case 3: {
                    m.url = r.string()
                    break
                }
                case 4: {
                    m.category = r.int32()
                    break
                }
                case 5: {
                    if (!(m.keyValues && m.keyValues.length)) m.keyValues = []
                    m.keyValues.push($root.KeyValue.decode(r, r.uint32()))
                    break
                }
                default:
                    r.skipType(t & 7)
                    break
            }
        }
        if (!m.hasOwnProperty('timestamp')) throw $util.ProtocolError("missing required 'timestamp'", { instance: m })
        if (!m.hasOwnProperty('seqId')) throw $util.ProtocolError("missing required 'seqId'", { instance: m })
        if (!m.hasOwnProperty('url')) throw $util.ProtocolError("missing required 'url'", { instance: m })
        if (!m.hasOwnProperty('category')) throw $util.ProtocolError("missing required 'category'", { instance: m })
        return m
    }

    /**
     * Decodes an Entry message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Entry
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Entry} Entry
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Entry.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
    }

    /**
     * Creates an Entry message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Entry
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {Entry} Entry
     */
    Entry.fromObject = function fromObject(d) {
        if (d instanceof $root.Entry) return d
        var m = new $root.Entry()
        if (d.timestamp != null) {
            if ($util.Long) (m.timestamp = $util.Long.fromValue(d.timestamp)).unsigned = false
            else if (typeof d.timestamp === 'string') m.timestamp = parseInt(d.timestamp, 10)
            else if (typeof d.timestamp === 'number') m.timestamp = d.timestamp
            else if (typeof d.timestamp === 'object')
                m.timestamp = new $util.LongBits(d.timestamp.low >>> 0, d.timestamp.high >>> 0).toNumber()
        }
        if (d.seqId != null) {
            if ($util.Long) (m.seqId = $util.Long.fromValue(d.seqId)).unsigned = false
            else if (typeof d.seqId === 'string') m.seqId = parseInt(d.seqId, 10)
            else if (typeof d.seqId === 'number') m.seqId = d.seqId
            else if (typeof d.seqId === 'object')
                m.seqId = new $util.LongBits(d.seqId.low >>> 0, d.seqId.high >>> 0).toNumber()
        }
        if (d.url != null) {
            m.url = String(d.url)
        }
        if (d.category != null) {
            m.category = d.category | 0
        }
        if (d.keyValues) {
            if (!Array.isArray(d.keyValues)) throw TypeError('.Entry.keyValues: array expected')
            m.keyValues = []
            for (var i = 0; i < d.keyValues.length; ++i) {
                if (typeof d.keyValues[i] !== 'object') throw TypeError('.Entry.keyValues: object expected')
                m.keyValues[i] = $root.KeyValue.fromObject(d.keyValues[i])
            }
        }
        return m
    }

    /**
     * Creates a plain object from an Entry message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Entry
     * @static
     * @param {Entry} m Entry
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Entry.toObject = function toObject(m, o) {
        if (!o) o = {}
        var d = {}
        if (o.arrays || o.defaults) {
            d.keyValues = []
        }
        if (o.defaults) {
            if ($util.Long) {
                var n = new $util.Long(0, 0, false)
                d.timestamp = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n
            } else d.timestamp = o.longs === String ? '0' : 0
            if ($util.Long) {
                var n = new $util.Long(0, 0, false)
                d.seqId = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n
            } else d.seqId = o.longs === String ? '0' : 0
            d.url = ''
            d.category = 0
        }
        if (m.timestamp != null && m.hasOwnProperty('timestamp')) {
            if (typeof m.timestamp === 'number') d.timestamp = o.longs === String ? String(m.timestamp) : m.timestamp
            else
                d.timestamp =
                    o.longs === String
                        ? $util.Long.prototype.toString.call(m.timestamp)
                        : o.longs === Number
                        ? new $util.LongBits(m.timestamp.low >>> 0, m.timestamp.high >>> 0).toNumber()
                        : m.timestamp
        }
        if (m.seqId != null && m.hasOwnProperty('seqId')) {
            if (typeof m.seqId === 'number') d.seqId = o.longs === String ? String(m.seqId) : m.seqId
            else
                d.seqId =
                    o.longs === String
                        ? $util.Long.prototype.toString.call(m.seqId)
                        : o.longs === Number
                        ? new $util.LongBits(m.seqId.low >>> 0, m.seqId.high >>> 0).toNumber()
                        : m.seqId
        }
        if (m.url != null && m.hasOwnProperty('url')) {
            d.url = m.url
        }
        if (m.category != null && m.hasOwnProperty('category')) {
            d.category = m.category
        }
        if (m.keyValues && m.keyValues.length) {
            d.keyValues = []
            for (var j = 0; j < m.keyValues.length; ++j) {
                d.keyValues[j] = $root.KeyValue.toObject(m.keyValues[j], o)
            }
        }
        return d
    }

    /**
     * Converts this Entry to JSON.
     * @function toJSON
     * @memberof Entry
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Entry.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return Entry
})())

export const PostData = ($root.PostData = (() => {
    /**
     * Properties of a PostData.
     * @exports IPostData
     * @interface IPostData
     * @property {IHeader} header PostData header
     * @property {Array.<IEntry>|null} [entries] PostData entries
     */

    /**
     * Constructs a new PostData.
     * @exports PostData
     * @classdesc Represents a PostData.
     * @implements IPostData
     * @constructor
     * @param {IPostData=} [p] Properties to set
     */
    function PostData(p) {
        this.entries = []
        if (p) for (var ks = Object.keys(p), i = 0; i < ks.length; ++i) if (p[ks[i]] != null) this[ks[i]] = p[ks[i]]
    }

    /**
     * PostData header.
     * @member {IHeader} header
     * @memberof PostData
     * @instance
     */
    PostData.prototype.header = null

    /**
     * PostData entries.
     * @member {Array.<IEntry>} entries
     * @memberof PostData
     * @instance
     */
    PostData.prototype.entries = $util.emptyArray

    /**
     * Creates a new PostData instance using the specified properties.
     * @function create
     * @memberof PostData
     * @static
     * @param {IPostData=} [properties] Properties to set
     * @returns {PostData} PostData instance
     */
    PostData.create = function create(properties) {
        return new PostData(properties)
    }

    /**
     * Encodes the specified PostData message. Does not implicitly {@link PostData.verify|verify} messages.
     * @function encode
     * @memberof PostData
     * @static
     * @param {IPostData} m PostData message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PostData.encode = function encode(m, w) {
        if (!w) w = $Writer.create()
        $root.Header.encode(m.header, w.uint32(10).fork()).ldelim()
        if (m.entries != null && m.entries.length) {
            for (var i = 0; i < m.entries.length; ++i) $root.Entry.encode(m.entries[i], w.uint32(18).fork()).ldelim()
        }
        return w
    }

    /**
     * Encodes the specified PostData message, length delimited. Does not implicitly {@link PostData.verify|verify} messages.
     * @function encodeDelimited
     * @memberof PostData
     * @static
     * @param {IPostData} message PostData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PostData.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim()
    }

    /**
     * Decodes a PostData message from the specified reader or buffer.
     * @function decode
     * @memberof PostData
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {PostData} PostData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PostData.decode = function decode(r, l) {
        if (!(r instanceof $Reader)) r = $Reader.create(r)
        var c = l === undefined ? r.len : r.pos + l,
            m = new $root.PostData()
        while (r.pos < c) {
            var t = r.uint32()
            switch (t >>> 3) {
                case 1: {
                    m.header = $root.Header.decode(r, r.uint32())
                    break
                }
                case 2: {
                    if (!(m.entries && m.entries.length)) m.entries = []
                    m.entries.push($root.Entry.decode(r, r.uint32()))
                    break
                }
                default:
                    r.skipType(t & 7)
                    break
            }
        }
        if (!m.hasOwnProperty('header')) throw $util.ProtocolError("missing required 'header'", { instance: m })
        return m
    }

    /**
     * Decodes a PostData message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof PostData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {PostData} PostData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PostData.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader)
        return this.decode(reader, reader.uint32())
    }

    /**
     * Creates a PostData message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof PostData
     * @static
     * @param {Object.<string,*>} d Plain object
     * @returns {PostData} PostData
     */
    PostData.fromObject = function fromObject(d) {
        if (d instanceof $root.PostData) return d
        var m = new $root.PostData()
        if (d.header != null) {
            if (typeof d.header !== 'object') throw TypeError('.PostData.header: object expected')
            m.header = $root.Header.fromObject(d.header)
        }
        if (d.entries) {
            if (!Array.isArray(d.entries)) throw TypeError('.PostData.entries: array expected')
            m.entries = []
            for (var i = 0; i < d.entries.length; ++i) {
                if (typeof d.entries[i] !== 'object') throw TypeError('.PostData.entries: object expected')
                m.entries[i] = $root.Entry.fromObject(d.entries[i])
            }
        }
        return m
    }

    /**
     * Creates a plain object from a PostData message. Also converts values to other types if specified.
     * @function toObject
     * @memberof PostData
     * @static
     * @param {PostData} m PostData
     * @param {$protobuf.IConversionOptions} [o] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PostData.toObject = function toObject(m, o) {
        if (!o) o = {}
        var d = {}
        if (o.arrays || o.defaults) {
            d.entries = []
        }
        if (o.defaults) {
            d.header = null
        }
        if (m.header != null && m.hasOwnProperty('header')) {
            d.header = $root.Header.toObject(m.header, o)
        }
        if (m.entries && m.entries.length) {
            d.entries = []
            for (var j = 0; j < m.entries.length; ++j) {
                d.entries[j] = $root.Entry.toObject(m.entries[j], o)
            }
        }
        return d
    }

    /**
     * Converts this PostData to JSON.
     * @function toJSON
     * @memberof PostData
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PostData.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
    }

    return PostData
})())

export { $root as default }
