/* eslint-disable no-restricted-imports */
import {
    CreateEffectStylePreviewCommand,
    CreateGradientThumbnailCommand,
    CreateImageThumbnailCommand,
    CreatePaintStylePreviewCommand,
    CreatePaintStyleThumbnailCommand,
    CreateTextStylePreviewCommand,
    CreateTextStyleThumbnailCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { Effect, GradientPaint, ITextStyle, ImagePaint, Paint } from '../node/node'
import { BridgeCommand } from './command-invoker'

export const createGradientThumbnail: BridgeCommand<
    [{ width: number; height: number; gradientPaint: GradientPaint }],
    string
> = (context, args) => {
    const result = context.bridge.call(CreateGradientThumbnailCommand, args)
    return result.dataUri!
}

export const createImageThumbnail: BridgeCommand<
    [{ width: number; height: number; imagePaint: ImagePaint }],
    string
> = (context, args) => {
    const result = context.bridge.call(CreateImageThumbnailCommand, args)
    return result.dataUri!
}

export const createPaintStyleThumbnail: BridgeCommand<[{ width: number; height: number; paints: Paint[] }], string> = (
    context,
    args
) => {
    const result = context.bridge.call(CreatePaintStyleThumbnailCommand, args)
    return result.dataUri!
}

export const createPaintStylePreview: BridgeCommand<[{ width: number; height: number; paints: Paint[] }], string> = (
    context,
    args
) => {
    const result = context.bridge.call(
        CreatePaintStylePreviewCommand,
        args as Wukong.DocumentProto.IArg_createPaintStylePreview
    )
    return result.dataUri!
}

export const createTextStyleThumbnail: BridgeCommand<
    [{ width: number; height: number; devicePixelRatio: number; style: ITextStyle }],
    string
> = (context, args) => {
    const result = context.bridge.call(
        CreateTextStyleThumbnailCommand,
        args as unknown as Wukong.DocumentProto.IArg_createTextStyleThumbnail
    )
    return result.dataUri!
}

export const createTextStylePreview: BridgeCommand<
    [{ width: number; height: number; devicePixelRatio: number; style: ITextStyle }],
    string
> = (context, args) => {
    const result = context.bridge.call(
        CreateTextStylePreviewCommand,
        args as unknown as Wukong.DocumentProto.IArg_createTextStylePreview
    )
    return result.dataUri!
}

export const createEffectStylePreview: BridgeCommand<[{ width: number; height: number; effects: Effect[] }], string> = (
    context,
    args
) => {
    const result = context.bridge.call(
        CreateEffectStylePreviewCommand,
        args as unknown as Wukong.DocumentProto.IArg_createEffectStylePreview
    )
    return result.dataUri!
}
