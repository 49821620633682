import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    AddReaction: '表情回应',
} as const

export const enTranslation = {
    AddReaction: 'Add reaction',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
