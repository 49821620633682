import { appStore$ } from '../../external-store/store/index'
import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import {
    OnBridgeStatusChange,
    OnDocIdChange,
    OnDocumentCommand,
    OnSessionIdChange,
    OnUserIdChange,
    OnUserRoleChange,
    Wukong,
} from '@wukong/bridge-proto'
import { computed, state } from 'ccstate'
import { getInitialDocSchemaVersion, getUrlDocId } from '../../../../util/src'
import { traceable } from '../../../../util/src/ccstate-helper/traceable'
import type { Ticket } from '../../document/synergy/synergy-ticket'
import { generateDefaultDocument, generateDocumentId } from '../../document/util/create-file'
import { EmBridge } from '../../kernel/bridge/em-bridge'
import { WkCLog } from '../../kernel/clog/wukong/instance'
import { DocStatus, DocWithAuthorityVO, RoleStatus } from '../../kernel/interface/type'
import { RoleStatusWeight } from '../../kernel/interface/user-roles'
import { MetricCollector, MetricName } from '../../kernel/metric-collector'
import { WK } from '../../window'
import { CreateFileData } from './types'

// 标注文档是否是新建的，它和 isCreatingFile 语意并不相同
// isCreatingFile 标注文档是否在新建中，当新建接口完成后，该标注变为 false
// 而 isFileCreated 标注文档是否是新建的，只要为新建文档，该标志一直为 true
const innerClientCreateFile$ = state<CreateFileData | undefined>(undefined)
export const isCreatingFile$ = traceable(
    'hulh01@kanyun.com',
    computed((get) => !!get(innerClientCreateFile$)?.pending)
)

// 标注文档是否是新建的，它和 isCreatingFile 语意并不相同
// isCreatingFile 标注文档是否在新建中，当新建接口完成后，该标注变为 false
// 而 isFileCreated 标注文档是否是新建的，只要为新建文档，该标志一直为 true
const innerIsFileCreated$ = state(false)
export const isFileCreated$ = traceable(
    'hulh01@kanyun.com',
    computed((get) => get(innerIsFileCreated$))
)

/**
 * 用于新建文档存储部分状态，提供一些 mock 数据等
 */
export class CreateFileManager {
    // 新建文档成功后需要执行的回调队列
    private afterCreateFileCallBackQueue: (() => void)[] = []

    // 用于提供新建文档 callback 在创建成功后执行的机制
    private createFileSuccessPromise: Promise<boolean> | undefined
    private createFileSuccessPromiseResolve: () => void = () => {}
    private createFileOrgId: string | undefined

    constructor() {
        this.initCreateFileSuccessPromise()
        WK.isCreatingFile = this.isCreatingFile.bind(this)
    }

    /**
     * 内存里的 docId 在不确定的业务下会和 url 里的不一致，这里先用 urlDocId 当做唯一标识
     * 提供一些 log，尝试找出问题
     * @returns
     */
    private getRightDocId(): string {
        const urlDocId = getUrlDocId()
        const memoryDocId = appStore$.get(innerClientCreateFile$)?.id

        if (urlDocId !== memoryDocId) {
            WkCLog.log(`url docId: ${getUrlDocId} mismatch with memory docId: ${memoryDocId}`)
        }

        return urlDocId!
    }

    private initCreateFileSuccessPromise(): void {
        this.createFileSuccessPromise = new Promise((resolve) => {
            this.createFileSuccessPromiseResolve = () => {
                resolve(true)
            }
        })
    }

    /**
     * 开始新建文档
     * @returns
     */
    public startCreateFile(orgId: string): CreateFileData {
        MetricCollector.pushMetric(MetricName.CREATE_FILE_START, performance.now())
        this.createFileOrgId = orgId
        const file = {
            pending: true,
            id: generateDocumentId(),
            data: generateDefaultDocument(),
        }
        appStore$.set(innerClientCreateFile$, file)
        appStore$.set(innerIsFileCreated$, true)
        return file
    }

    /**
     * 注入新建文档后的回调
     */
    public injectCreateFileCallBack(fn: () => void): void {
        this.afterCreateFileCallBackQueue.push(fn)
    }

    /**
     * 新建文档结束
     * 执行注入到 manager 中的延迟回调
     * 此阶段可以开始建联，可以正常编辑
     */
    public async afterCreateFile(connectSynergy?: () => void): Promise<void> {
        await this.createFileSuccessPromise

        // 等到 promise 完成后重新初始化一次，留给下次使用
        this.initCreateFileSuccessPromise()

        appStore$.set(innerClientCreateFile$, undefined)

        // 执行所有需要回放的函数
        for (const fn of this.afterCreateFileCallBackQueue) {
            fn()
        }
        // 执行后清空
        this.afterCreateFileCallBackQueue = []
        // 开始建联
        connectSynergy?.()
    }

    /**
     * 重置新建状态
     */
    public reset(): void {
        appStore$.set(innerClientCreateFile$, undefined)
        appStore$.set(innerIsFileCreated$, false)

        this.afterCreateFileCallBackQueue = []
    }

    /**
     * 标注是否是新建文档
     * @returns
     */
    public isCreatingFile(): boolean {
        return !!appStore$.get(isCreatingFile$)
    }

    /**
     * 描述新建文档已经成功
     */
    public markCreateFileSuccessed(): void {
        this.createFileSuccessPromiseResolve()
    }

    /**
     * 提供新建文档 mock 的 ticket
     */
    public createMockTicket(userId: number, docId: string): Ticket {
        const orgId = this.createFileOrgId ?? '-1'
        return {
            docId,
            needPassword: false,
            documentName: translation('Untitled'),
            role: RoleStatus.Editor,
            release: '',
            userId: userId,
            orgId: orgId,
            draftAndOwnerShareEditLimited: false,
            canEditDocByUpgradeSeat: false,
            planFreeze: false,
            manager: false,
            exceedUsageLimit: false,
            canEditDocByUpgradePlan: true,
            trial: false,
            schemaVersion: getInitialDocSchemaVersion(),
        }
    }

    /**
     * 提供新建文档 mock 的 document
     */
    public createMockDoc(): DocWithAuthorityVO {
        return {
            id: this.getRightDocId(),
            status: DocStatus.NORMAL,
            role: RoleStatus.Owner,
            name: translation('Untitled'),
        } as DocWithAuthorityVO
    }

    /**
     * 新建文档时，向 wasm 发送固定的 archive 数据
     */
    public createFileOnDocument(userId: number, bridge: EmBridge): void {
        bridge.call(OnDocIdChange, { value: this.getRightDocId() })
        bridge.call(OnUserIdChange, { value: userId })
        bridge.call(OnUserRoleChange, { value: RoleStatusWeight[RoleStatus.Owner] })
        bridge.call(OnBridgeStatusChange, { value: 1 })
        bridge.call(OnSessionIdChange, { value: 1 })
        bridge.call(OnDocumentCommand, {
            payload: appStore$.get(innerClientCreateFile$)?.data!,
            docId: this.getRightDocId(),
            finalFragment: false,
            compressType: Wukong.DocumentProto.CompressType.COMPRESS_TYPE_NO_COMPRESS,
        })
    }
}

export const createFileManager = new CreateFileManager()
