import { DelayTimer } from '../../../../util/src/timer'
import { assert, isNotNullOrUndefined } from '../../../../util/src/type'

import {
    BitmapDrawble,
    ImageDecodedBitmap,
    ImageDecodedBitmapDecompressChunk,
    ImageDecodedBitmapHelpers,
    ImageDecodedBitmapKtx,
    ImageDecodedBitmapNoCompressedChunk,
    ImageDecodedBitmapOnlyCompressedChunk,
    ImageDecodedBitmapType,
    ImageTotalDecompressedDecodedBitmap,
    isImageBitmapSupported,
    TextureCompressionFormat,
} from '../../image-lib'

export function isBitmapValid(bitmap: { width: number; height: number }): boolean {
    return bitmap.width > 0 && bitmap.height > 0
}

export interface IImagePixelsStoreDependency {
    log: (stats: any) => void
    decompressBitmapSync: (data: Uint8Array) => Uint8Array
    decompressBitmap: (data: Uint8Array) => Promise<Uint8Array>
    transcodeKtxSync: (data: Uint8Array, format: TextureCompressionFormat) => Uint8Array
    transcodeKtx: (data: Uint8Array, format: TextureCompressionFormat) => Promise<Uint8Array>
    maxDropOriginThreshold: number
    minDropOriginThreshold: number
}

export class ImagePixelsStore {
    // 位置 0 非法
    private _internals: Array<ImageDecodedBitmap | null> = [null]
    private _freedHandles: Array<number> = []
    private _canCompressOriginHandles: Set<number> = new Set()
    private _canCompressOriginBytes = 0
    private _imageHashes: Map<string, number> = new Map()
    private _stats = {
        totalBytes: 0,
        totalCompressedBytes: 0,
        totalUncompressedBytes: 0,
        totalBitmapBytes: 0,
        compressedCount: 0,
        peakBytes: 0,
    }
    private _tracerTimer: DelayTimer
    private _isDestroyed = false

    constructor(private _params: IImagePixelsStoreDependency) {
        this._tracerTimer = new DelayTimer(2000, () => {
            this._params.log(this.stats())
        })
    }

    public destroy() {
        this._isDestroyed = true
        this._tracerTimer.advanceAndDestroy()

        const internals = this._internals
        // 这里一定要做置空，否则反复进出编辑器会造成内存泄漏，原因不明
        this._internals = []
        this._freedHandles = []
        this._canCompressOriginHandles.clear()
        // 这段可以不做，做了只是为了安全
        for (const bitmap of internals) {
            if (bitmap === null) {
                continue
            }
            if (bitmap.type === ImageDecodedBitmapType.ImageBitmap) {
                bitmap.data.close()
            }
        }

        this._imageHashes.clear()
    }

    public addBitmap(imageHash: string, bitmap: ImageTotalDecompressedDecodedBitmap): number {
        this._runDropOriginBitmaps()
        if (this._imageHashes.has(imageHash)) {
            return this._imageHashes.get(imageHash)!
        }
        if (!isBitmapValid(bitmap)) {
            throw Error(`Invalid bitmap ${imageHash}`)
        }

        let handle: number
        if (this._freedHandles.length === 0) {
            this._internals.push(bitmap)
            handle = this._internals.length - 1
        } else {
            handle = this._freedHandles.pop()!
            this._internals[handle] = bitmap
        }
        this._imageHashes.set(imageHash, handle)
        this._markBitmapAdded(handle, bitmap)
        this._tracerTimer.ensureStarted()
        return handle
    }

    public removeBitmap(imageHash: string) {
        if (!this._imageHashes.has(imageHash)) {
            return
        }
        const handle = this._imageHashes.get(imageHash)!
        this._imageHashes.delete(imageHash)
        const bitmap = this._internals[handle]!
        this._internals[handle] = null
        this._freedHandles.push(handle)
        this._markBitmapRemoved(handle, bitmap)
        this._tracerTimer.ensureStarted()
    }

    public has(handle: number) {
        if (handle <= 0 || handle >= this._internals.length) {
            return false
        }
        return this._internals[handle] !== null
    }

    public getDrawable<T extends number | null>(
        handle: number,
        format: TextureCompressionFormat,
        _l: T,
        _r: T
    ): BitmapDrawble | null {
        this._runDropOriginBitmaps()
        if (!this.has(handle)) {
            return null
        }
        const bitmap = this._internals[handle]
        if (bitmap === null) {
            return null
        }
        if (bitmap.type === ImageDecodedBitmapType.Bytes) {
            const l = _l === null ? 0 : Math.max(_l, 0)
            const r = _r === null ? bitmap.data.length : Math.min(_r, bitmap.data.length)
            if (l > r) {
                return null
            }

            if (ImageDecodedBitmapHelpers.isTotalDecompressed(bitmap)) {
                const chunks = bitmap.data
                return {
                    type: ImageDecodedBitmapType.Bytes,
                    data: chunks.slice(l, r),
                }
            }

            const data = [...bitmap.data]
            const ret: ImageDecodedBitmapDecompressChunk[] = []

            for (let i = l; i < r; i++) {
                const chunk = data[i]
                if (!chunk.compressed) {
                    data[i] = chunk
                    ret.push(chunk)
                } else {
                    const newChunk = {
                        y: chunk.y,
                        width: chunk.width,
                        height: chunk.height,
                        chunk: chunk.chunk ? chunk.chunk : this._params.decompressBitmapSync(chunk.compressed),
                        compressed: chunk.compressed,
                    }
                    data[i] = newChunk
                    ret.push(newChunk)
                }
            }

            const newBitmap: ImageDecodedBitmap = {
                type: ImageDecodedBitmapType.Bytes,
                width: bitmap.width,
                height: bitmap.height,
                data,
            }
            this._internals[handle] = newBitmap
            this._markBitmapRemoved(handle, bitmap)
            this._markBitmapAdded(handle, newBitmap)

            return {
                type: ImageDecodedBitmapType.Bytes,
                data: ret,
            }
        }
        if (bitmap.type === ImageDecodedBitmapType.Ktx) {
            let data: Uint8Array | null = null
            if (bitmap.transcoded && bitmap.transcoded.format === format) {
                data = bitmap.transcoded.data
            } else {
                data = this._params.transcodeKtxSync(bitmap.data, format)
            }

            const width = bitmap.width
            const height = bitmap.height
            const newBitmap: ImageDecodedBitmapKtx = {
                type: ImageDecodedBitmapType.Ktx,
                width,
                height,
                data: bitmap.data,
                transcoded: null,
            }
            this._internals[handle] = newBitmap
            this._markBitmapRemoved(handle, bitmap)
            this._markBitmapAdded(handle, newBitmap)

            return {
                type: ImageDecodedBitmapType.Ktx,
                format,
                width,
                height,
                data,
            }
        }
        return {
            type: ImageDecodedBitmapType.ImageBitmap,
            data: bitmap.data,
        }
    }

    public getMetaByImageHash(imageHash: string): {
        width: number
        height: number
    } | null {
        const handle = this._imageHashes.get(imageHash)
        if (!isNotNullOrUndefined(handle)) {
            return null
        }
        const bitmap = this._internals[handle]
        if (bitmap === null) {
            return null
        }
        return {
            width: bitmap.width,
            height: bitmap.height,
        }
    }

    public printDiagnosisByImageHash(imageHash: string, log: (...args: any[]) => void) {
        const handle = this._imageHashes.get(imageHash)
        if (!isNotNullOrUndefined(handle)) {
            log(`bitmap "${imageHash}" not found`)
            return
        }
        const bitmap = this._internals[handle]
        if (!bitmap) {
            log(`bitmap "${imageHash}" not found, but with handle ${handle}, which is unexpected`)
            return
        }
        log(`bitmap "${imageHash}" with handle ${handle}`, bitmap)
    }

    public hasByImageHash(imageHash: string) {
        return this._imageHashes.has(imageHash)
    }

    public async prepareOriginBitmapsAsync(
        arg: Array<{
            chunkIndexRight: number
            chunkIndexLeft: number
            imageHash: string
            format: TextureCompressionFormat
        }>
    ) {
        const enum PrepareType {
            Bytes,
            Ktx,
        }
        type PrepareItem =
            | {
                  type: PrepareType.Bytes
                  payload: Array<[number, ImageDecodedBitmapDecompressChunk]>
              }
            | {
                  type: PrepareType.Ktx
                  payload: Uint8Array
              }

        // 这里一定要用 imageHash，而不能是 handle，因为 handle 可能被复用
        const preparedFactories: Array<[string, TextureCompressionFormat, () => Promise<PrepareItem>]> = []
        {
            for (const { imageHash, chunkIndexLeft, chunkIndexRight, format } of arg) {
                const handle = this._imageHashes.get(imageHash)
                if (!isNotNullOrUndefined(handle)) {
                    continue
                }
                const bitmap = this._internals[handle]!
                if (!bitmap) {
                    continue
                }

                if (bitmap.type === ImageDecodedBitmapType.ImageBitmap) {
                    continue
                } else if (bitmap.type === ImageDecodedBitmapType.Ktx) {
                    if (!bitmap.transcoded || bitmap.transcoded.format !== format) {
                        const f = async (): Promise<PrepareItem> => {
                            const payload = await this._params.transcodeKtx(bitmap.data, format)
                            return {
                                type: PrepareType.Ktx,
                                payload,
                            }
                        }
                        preparedFactories.push([imageHash, format, f])
                    }
                } else {
                    const l = Math.max(chunkIndexLeft, 0)
                    const r = Math.min(chunkIndexRight, bitmap.data.length)
                    const cloned = [...bitmap.data]

                    const f = async (): Promise<PrepareItem> => {
                        const saved: Array<[number, ImageDecodedBitmapDecompressChunk]> = []
                        for (let i = l; i < r; i++) {
                            const chunk = cloned[i]
                            if (chunk.chunk) {
                                // 即使已经有了，也存一份，因为这是个异步过程，其他地方可能把他们清除掉
                                saved.push([i, chunk])
                            } else {
                                const data = await this._params.decompressBitmap(chunk.compressed)

                                const newChunk = {
                                    y: chunk.y,
                                    width: chunk.width,
                                    height: chunk.height,
                                    chunk: data,
                                    compressed: chunk.compressed,
                                }
                                saved.push([i, newChunk])
                            }
                        }
                        return {
                            type: PrepareType.Bytes,
                            payload: saved,
                        }
                    }
                    preparedFactories.push([imageHash, TextureCompressionFormat.None, f])
                }
            }
        }

        const prepared: Array<[string, TextureCompressionFormat, PrepareItem]> = []
        {
            for (const [imageHash, format, factory] of preparedFactories) {
                const item = await factory()
                if (this._isDestroyed) {
                    return
                }
                prepared.push([imageHash, format, item])
            }
            preparedFactories.splice(0, preparedFactories.length)
        }

        // === 从这里开始，过程必须同步 ===
        // 因为 wasm 拿到数据后会立刻上传纹理，这个时候被其他地方释放掉就说不清了

        // 由于下面是解压缩，所以先跑一次 dropBitmaps
        this._runDropOriginBitmaps()

        for (const [imageHash, format, item] of prepared) {
            const handle = this._imageHashes.get(imageHash)
            if (!isNotNullOrUndefined(handle)) {
                continue
            }
            const oldBitmap = this._internals[handle]!
            if (!oldBitmap) {
                continue
            }

            let newBitmap: ImageDecodedBitmap | null = null
            if (item.type === PrepareType.Bytes && oldBitmap.type === ImageDecodedBitmapType.Bytes) {
                if (ImageDecodedBitmapHelpers.isTotalDecompressed(oldBitmap)) {
                    // 可能因为其他调用把纹理给上传了
                    continue
                }
                newBitmap = {
                    type: ImageDecodedBitmapType.Bytes,
                    width: oldBitmap.width,
                    height: oldBitmap.height,
                    data: [...oldBitmap.data],
                }
                for (const [i, chunk] of item.payload) {
                    newBitmap.data[i] = chunk
                }
            } else if (item.type === PrepareType.Ktx && oldBitmap.type === ImageDecodedBitmapType.Ktx) {
                if (oldBitmap.transcoded?.format === format) {
                    // 可能因为其他调用把纹理给上传了
                    continue
                }
                newBitmap = {
                    type: ImageDecodedBitmapType.Ktx,
                    width: oldBitmap.width,
                    height: oldBitmap.height,
                    data: oldBitmap.data,
                    transcoded: {
                        data: item.payload,
                        format,
                    },
                }
            }

            if (newBitmap) {
                this._internals[handle] = newBitmap
                this._markBitmapRemoved(handle, oldBitmap)
                this._markBitmapAdded(handle, newBitmap)
            }
        }
    }

    public stats() {
        const PerMB = 1 << 20
        return {
            totalBytesInMB: this._stats.totalBytes / PerMB,
            peakBytesInMB: this._stats.peakBytes / PerMB,
            compressedBytesInMB: this._stats.totalCompressedBytes / PerMB,
            uncompressedBytesInMB: this._stats.totalUncompressedBytes / PerMB,
            totalBitmapBytesInMB: this._stats.totalBitmapBytes / PerMB,
            canCompressOriginBytesInMB: this._canCompressOriginBytes / PerMB,
            usedSlots: this._internals.length - this._freedHandles.length - 1,
            freedSlots: this._freedHandles.length,
            totalSlots: this._internals.length - 1,
            compressedCount: this._stats.compressedCount,
        }
    }

    public validateInternalStates() {
        let compressedCount = 0
        let canCompressOriginBytes = 0
        let uncompressedBytes = 0
        let compressedBytes = 0
        let totalBytes = 0
        let totalBitmapBytes = 0

        const imageHashHandles = new Set(this._imageHashes.values())

        for (const [imageHash, handle] of this._imageHashes) {
            const meta = this.getMetaByImageHash(imageHash)
            assert(meta !== null, `imageHash ${imageHash} exist, but meta not exist`)

            const bm = this._internals[handle]
            assert(bm !== null, `imageHash ${imageHash} exist, but bitmap not exist`)
        }

        for (let handle = 1; handle < this._internals.length; handle++) {
            const bitmap = this._internals[handle]

            if (bitmap === null) {
                assert(this._freedHandles.includes(handle), `handle is freed, but not in freeHandles`)
                assert(!this.has(handle), `"has" = true, but actually false`)
                assert(!imageHashHandles.has(handle), `handle is freed, but in imageHashes`)
                continue
            }

            assert(this.has(handle), `"has" = false, but actually true`)
            assert(imageHashHandles.has(handle), `handle exists, but not in imageHashes`)
            if (bitmap.type === ImageDecodedBitmapType.ImageBitmap) {
                assert(bitmap.width === bitmap.data.width, 'width not equal')
                assert(bitmap.height === bitmap.data.height, 'height not equal')
                assert(isImageBitmapSupported() && bitmap.data instanceof ImageBitmap, 'not ImageBitmap')
            } else if (bitmap.type === ImageDecodedBitmapType.Ktx) {
                assert(bitmap.data instanceof Uint8Array, 'not Uint8Array')
            } else {
                assert(Array.isArray(bitmap.data), 'bitmap data is not array')
                let totalHeight = 0
                for (const chunk of bitmap.data) {
                    assert(chunk.y === totalHeight, 'y not match')
                    assert(bitmap.width === chunk.width, 'chunk width not equal')

                    totalHeight += chunk.height

                    assert(chunk.y + chunk.height === totalHeight, 'height not match')
                }
            }

            const originBytes = ImageDecodedBitmapHelpers.bitmapBytes(bitmap)
            totalBitmapBytes += originBytes

            if (bitmap.type === ImageDecodedBitmapType.ImageBitmap) {
                totalBytes += originBytes
                uncompressedBytes += originBytes
            } else if (bitmap.type === ImageDecodedBitmapType.Ktx) {
                const bytes = originBytes + (bitmap.transcoded?.data.byteLength ?? 0)
                totalBytes += bytes
                uncompressedBytes += bytes
            } else {
                let canCompress = false
                let anyCompressed = false
                for (const chunk of bitmap.data) {
                    if (chunk.chunk) {
                        const chunkByteLen = chunk.chunk.byteLength
                        totalBytes += chunkByteLen
                        uncompressedBytes += chunkByteLen
                        assert(bitmap.width * chunk.height * 4 === chunkByteLen, 'chunk bytes not equal')

                        if (chunk.compressed) {
                            const decompressed = this._params.decompressBitmapSync(chunk.compressed)
                            canCompressOriginBytes += chunkByteLen
                            canCompress ||= Boolean(chunk.compressed)

                            assert(
                                decompressed.byteLength === chunk.chunk.byteLength,
                                'decompress bytes length not equal'
                            )
                            for (let i = 0; i < decompressed.byteLength; i++) {
                                assert(decompressed[i] === chunk.chunk[i], `decompressed bytes[${i}] not equal`)
                            }
                        }
                    }
                    if (chunk.compressed) {
                        totalBytes += chunk.compressed.byteLength
                        compressedBytes += chunk.compressed.byteLength
                        anyCompressed = true
                    }
                }
                if (anyCompressed) {
                    compressedCount++
                }
                if (canCompress) {
                    assert(this._canCompressOriginHandles.has(handle), 'not in canCompressOriginHandles')
                } else {
                    assert(!this._canCompressOriginHandles.has(handle), 'in canCompressOriginHandles')
                }
            }
        }

        assert(
            canCompressOriginBytes === this._canCompressOriginBytes,
            `canCompressOriginBytes not equal, expect ${canCompressOriginBytes}, receive ${this._canCompressOriginBytes}`
        )
        assert(
            uncompressedBytes === this._stats.totalUncompressedBytes,
            `uncompressedBytes not equal, expect ${uncompressedBytes}, receive ${this._stats.totalUncompressedBytes}`
        )
        assert(compressedBytes === this._stats.totalCompressedBytes, 'compressedBytes not equal')
        assert(totalBytes === this._stats.totalBytes, 'totalBytes not equal')
        assert(totalBitmapBytes === this._stats.totalBitmapBytes, 'totalBitmapBytes not equal')
        assert(
            compressedCount === this._stats.compressedCount,
            `compressedCount not equal, expect ${compressedCount}, receive ${this._stats.compressedCount}`
        )
    }

    // 这里的策略是
    // - 当可以释放掉的原图 bitmap 达到 MAX_THRESHOLD 时，不断释放，直到可以释放掉的大小不超过 MIN_THRESHOLD
    private _runDropOriginBitmaps() {
        if (this._canCompressOriginBytes < this._params.maxDropOriginThreshold) {
            return
        }

        while (
            this._canCompressOriginHandles.size > 0 &&
            this._canCompressOriginBytes > this._params.minDropOriginThreshold
        ) {
            const handle = this._canCompressOriginHandles.values().next().value as number
            this._canCompressOriginHandles.delete(handle)
            const bitmap = this._internals[handle]!

            if (bitmap.type === ImageDecodedBitmapType.ImageBitmap || bitmap.type === ImageDecodedBitmapType.Ktx) {
                continue
            }

            const newChunks: Array<ImageDecodedBitmapNoCompressedChunk | ImageDecodedBitmapOnlyCompressedChunk> = []
            for (const item of bitmap.data) {
                if (item.compressed) {
                    newChunks.push({
                        y: item.y,
                        width: item.width,
                        height: item.height,
                        chunk: null,
                        compressed: item.compressed,
                    })
                } else {
                    newChunks.push(item)
                }
            }

            const newBitmap: ImageDecodedBitmap = {
                type: ImageDecodedBitmapType.Bytes,
                data: newChunks,
                width: bitmap.width,
                height: bitmap.height,
            }
            this._internals[handle] = newBitmap

            this._markBitmapRemoved(handle, bitmap)
            this._markBitmapAdded(handle, newBitmap)
        }
    }

    private _markBitmapAdded(handle: number, bitmap: ImageDecodedBitmap) {
        const originBytes = ImageDecodedBitmapHelpers.bitmapBytes(bitmap)
        this._stats.totalBitmapBytes += originBytes

        if (bitmap.type === ImageDecodedBitmapType.ImageBitmap) {
            this._stats.totalBytes += originBytes
            this._stats.totalUncompressedBytes += originBytes
        } else if (bitmap.type === ImageDecodedBitmapType.Ktx) {
            const bytes = originBytes + (bitmap.transcoded?.data.byteLength ?? 0)
            this._stats.totalBytes += bytes
            this._stats.totalUncompressedBytes += bytes
        } else {
            let canCompress = false
            let anyCompressed = false

            for (const chunk of bitmap.data) {
                if (chunk.chunk) {
                    const chunkBytes = chunk.chunk.byteLength
                    this._stats.totalBytes += chunkBytes
                    this._stats.totalUncompressedBytes += chunkBytes

                    if (chunk.compressed) {
                        canCompress = true
                        this._canCompressOriginBytes += chunkBytes
                    }
                }
                if (chunk.compressed) {
                    const chunkBytes = chunk.compressed.byteLength
                    this._stats.totalBytes += chunkBytes
                    this._stats.totalCompressedBytes += chunkBytes
                    anyCompressed = true
                }
            }

            if (anyCompressed) {
                this._stats.compressedCount++
            }
            if (canCompress) {
                this._canCompressOriginHandles.add(handle)
            }
        }
        this._stats.peakBytes = Math.max(this._stats.peakBytes, this._stats.totalBytes)
    }

    private _markBitmapRemoved(handle: number, bitmap: ImageDecodedBitmap) {
        const originBytes = ImageDecodedBitmapHelpers.bitmapBytes(bitmap)
        this._stats.totalBitmapBytes -= originBytes

        if (bitmap.type === ImageDecodedBitmapType.ImageBitmap) {
            this._stats.totalBytes -= originBytes
            this._stats.totalUncompressedBytes -= originBytes
        } else if (bitmap.type === ImageDecodedBitmapType.Ktx) {
            const bytes = originBytes + (bitmap.transcoded?.data.byteLength ?? 0)
            this._stats.totalBytes -= bytes
            this._stats.totalUncompressedBytes -= bytes
        } else {
            let canCompress = false
            let anyCompressed = false

            for (const chunk of bitmap.data) {
                if (chunk.chunk) {
                    const chunkBytes = chunk.chunk.byteLength
                    this._stats.totalBytes -= chunkBytes
                    this._stats.totalUncompressedBytes -= chunkBytes

                    if (chunk.compressed) {
                        canCompress = true
                        this._canCompressOriginBytes -= chunkBytes
                    }
                }
                if (chunk.compressed) {
                    const chunkBytes = chunk.compressed.byteLength
                    this._stats.totalBytes -= chunkBytes
                    this._stats.totalCompressedBytes -= chunkBytes
                    anyCompressed = true
                }
            }

            if (anyCompressed) {
                this._stats.compressedCount--
            }
            if (canCompress) {
                this._canCompressOriginHandles.delete(handle)
            }
        }
    }
}
