import { Wukong } from '@wukong/bridge-proto'
import { FC, useMemo } from 'react'
import { LayerPanelRenderNodeItem } from './render-node-item'
import { LayerPanelRenderSectionItem } from './render-section-item'
import { LayerPanelSeeAllLayersItem } from './render-see-all-layers-item'

const RenderItemType = Wukong.DocumentProto.LayerPanelItemType

interface LayerPanelRenderProps {
    renderList: Wukong.DocumentProto.VLayerPanelItemInfo[]
    item: Wukong.DocumentProto.VLayerPanelItemInfo
    index: number
    layerUsingModes: Wukong.DocumentProto.IVariableLayerModeStateVector
}

export const LayerPanelRenderObject: FC<LayerPanelRenderProps> = ({ renderList, item, index, layerUsingModes }) => {
    return useMemo(() => {
        switch (item.type) {
            case RenderItemType.LAYER_PANEL_ITEM_TYPE_NODE:
                return (
                    <LayerPanelRenderNodeItem
                        nodeItem={item.layerPanelNodeInfo as Wukong.DocumentProto.VLayerPanelNode}
                        prevItem={renderList[index - 1]}
                        nextItem={renderList[index + 1]}
                        index={index}
                        layerUsingModes={layerUsingModes}
                    />
                )
            case RenderItemType.LAYER_PANEL_ITEM_TYPE_SECTION:
                return (
                    <LayerPanelRenderSectionItem
                        sectionItem={item.layerPanelSectionInfo as Wukong.DocumentProto.VLayerPanelSection}
                        prevItem={renderList[index - 1]}
                        nextItem={renderList[index + 1]}
                        index={index}
                    />
                )
            case RenderItemType.LAYER_PANEL_ITEM_TYPE_SEE_ALL_LAYERS:
                return (
                    <LayerPanelSeeAllLayersItem
                        index={index}
                        info={item.layerPanelSeeAllLayersInfo}
                        prevItem={renderList[index - 1]}
                        nextItem={renderList[index + 1]}
                    />
                )
        }
    }, [
        item.type,
        item.layerPanelNodeInfo,
        item.layerPanelSectionInfo,
        item.layerPanelSeeAllLayersInfo,
        renderList,
        index,
        layerUsingModes,
    ])
}
