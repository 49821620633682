import classnames from 'classnames'
import React, { HTMLAttributes, useCallback, useImperativeHandle, useRef } from 'react'
import { useMount, useUpdateEffect } from 'react-use'
import { Scrollbar, ScrollbarProps, ScrollbarRef } from '../scrollbar/scrollbar'
import { ScrollItem } from './scroll-item'
import classes from './scroll-view.module.less'
import { ScrollContextProps, SelectContext, scrollTargetClassName } from './type'

export interface ScrollViewRef {
    getOriginRef: () => ScrollbarRef
    getScrollViewElement: () => HTMLDivElement
    updateView: (block?: ScrollLogicalPosition) => void // 手动触发视图计算
}

export interface ScrollViewProps extends HTMLAttributes<HTMLDivElement> {
    selectKey?: ScrollContextProps['selectKey']
    block?: ScrollLogicalPosition
    scrollbar?: ScrollbarProps
    nearestBottomGap?: number // block 为 nearest 时，距离底部的距离
    nearestTopGap?: number // block 为 nearest 时，距离底部的距离
    notUseDefaultClassName?: boolean
    isVertical?: boolean // 垂直方向的自动滚动
    isHorizontal?: boolean // 水平方向的自动滚动
}
// 不使用 element.scrollIntoView api 是因为会引起 Scrollbar组件底部滚动条暴露
// 之后可以考虑把滚动条的宽度设成 0 而避免这一情况
function _ScrollView(props: ScrollViewProps, ref?: React.Ref<ScrollViewRef>): JSX.Element {
    const {
        selectKey,
        block = 'center',
        className,
        scrollbar,
        notUseDefaultClassName,
        nearestBottomGap = 0,
        nearestTopGap = 0,
        isVertical = true,
        isHorizontal = false,
        ...otherProps
    } = props
    const scrollViewRef = useRef<HTMLDivElement>(null)
    const originRef = useRef<ScrollbarRef>(null)

    const isVisible = useCallback(
        (containerRect: DOMRect, targetRect: DOMRect) => {
            const isVerticalVisible = isVertical
                ? containerRect.top <= targetRect.top && containerRect.bottom >= targetRect.bottom
                : true
            const isHorizontalVisible = isHorizontal
                ? containerRect.left <= targetRect.left && containerRect.right >= targetRect.right
                : true
            return isVerticalVisible && isHorizontalVisible
        },
        [isHorizontal, isVertical]
    )

    const blockStart = useCallback(
        (containerRect: DOMRect, targetRect: DOMRect) => {
            if (isVertical) {
                const currentScrollTop = originRef.current?.getScrollTop() ?? 0
                originRef.current?.scrollTop(targetRect.top - containerRect.top + currentScrollTop - nearestTopGap)
            }
            if (isHorizontal) {
                const currentScrollLeft = originRef.current?.getScrollLeft() ?? 0
                originRef.current?.scrollLeft(targetRect.left - containerRect.left + currentScrollLeft - nearestTopGap)
            }
        },
        [isHorizontal, isVertical, nearestTopGap]
    )

    const blockEnd = useCallback(
        (containerRect: DOMRect, targetRect: DOMRect) => {
            if (isVertical) {
                const currentScrollTop = originRef.current?.getScrollTop() ?? 0
                originRef.current?.scrollTop(
                    targetRect.bottom - containerRect.bottom + currentScrollTop + nearestBottomGap
                )
            }
            if (isHorizontal) {
                const currentScrollLeft = originRef.current?.getScrollLeft() ?? 0
                originRef.current?.scrollLeft(targetRect.right - containerRect.right + currentScrollLeft)
            }
        },
        [isHorizontal, isVertical, nearestBottomGap]
    )
    const blockCenter = useCallback(
        (containerRect: DOMRect, targetRect: DOMRect) => {
            if (isVertical) {
                const currentScrollTop = originRef.current?.getScrollTop() ?? 0
                const centerV = targetRect.top + targetRect.height / 2 - containerRect.top - containerRect.height / 2
                originRef.current?.scrollTop(centerV + currentScrollTop)
            }
            if (isHorizontal) {
                const currentScrollLeft = originRef.current?.getScrollLeft() ?? 0
                const centerH = targetRect.left + targetRect.width / 2 - containerRect.left - containerRect.width / 2
                originRef.current?.scrollLeft(centerH + currentScrollLeft)
            }
        },
        [isHorizontal, isVertical]
    )
    const blockNearest = useCallback(
        (containerRect: DOMRect, targetRect: DOMRect) => {
            const containerCenter = (containerRect.top + containerRect.bottom) / 2
            const targetCenter = (targetRect.top + targetRect.bottom) / 2
            if (targetCenter > containerCenter) {
                blockEnd(containerRect, targetRect)
            } else {
                blockStart(containerRect, targetRect)
            }
        },
        [blockEnd, blockStart]
    )

    const scrollIntoView = useCallback(
        (resetBlock?: ScrollLogicalPosition) => {
            if (!scrollViewRef.current || !originRef.current) {
                return null
            }
            const container = scrollViewRef.current as HTMLDivElement
            const target = container.getElementsByClassName(scrollTargetClassName)[0]
            if (!target) {
                return
            }
            const containerRect = container.getBoundingClientRect()
            const targetRect = target.getBoundingClientRect()
            if (isVisible(containerRect, targetRect)) {
                return
            }

            switch (resetBlock ?? block) {
                case 'start':
                    blockStart(containerRect, targetRect)
                    break
                case 'end':
                    blockEnd(containerRect, targetRect)
                    break
                case 'nearest':
                    blockNearest(containerRect, targetRect)
                    break
                case 'center':
                default:
                    blockCenter(containerRect, targetRect)
            }
        },
        [block, blockCenter, blockEnd, blockNearest, blockStart, scrollViewRef, isVisible]
    )

    useMount(scrollIntoView)

    useUpdateEffect(() => {
        scrollIntoView()
    }, [selectKey])

    useImperativeHandle(ref, () => ({
        getOriginRef: () => originRef.current!,
        getScrollViewElement: () => scrollViewRef.current!,
        updateView: scrollIntoView,
    }))

    return (
        <SelectContext.Provider value={{ selectKey, selectClassName: scrollTargetClassName }}>
            <div
                ref={scrollViewRef}
                className={classnames({ [classes.container]: !notUseDefaultClassName }, [className])}
                {...otherProps}
            >
                <Scrollbar {...scrollbar} ref={originRef}>
                    {props.children}
                </Scrollbar>
            </div>
        </SelectContext.Provider>
    )
}
const ScrollViewRef = React.forwardRef<ScrollViewRef, ScrollViewProps>(_ScrollView)
export const ScrollView: typeof ScrollViewRef & { Item: typeof ScrollItem } = Object.assign(ScrollViewRef, {
    Item: ScrollItem,
})
