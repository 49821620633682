import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Layout: '布局',
    Gap: '间距',
    Padding: '边距',
    Radius: '圆角',
    CornerSmoothing: '平滑圆角',
    Rotation: '角度',
    Blend: '混合模式',
    Opacity: '不透明度',
    Auto: '自动',
    LeftBracket: '（',
    RightBracket: '）',
} as const

export const enTranslation = {
    Layout: 'Layout',
    Gap: 'Gap',
    Padding: 'Padding',
    Radius: 'Radius',
    CornerSmoothing: 'Corner smoothing',
    Rotation: 'Rotation',
    Blend: 'Blend',
    Opacity: 'Opacity',
    Auto: 'Auto',
    LeftBracket: ' (',
    RightBracket: ')',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
