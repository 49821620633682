/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { useMemo } from 'react'
import { DraggablePopupV2, InputV2, ScrollView } from '../../../../../../ui-lib/src'
import { ToKeyCode } from '../../../../document/util/keycode'
import { KeyboardReceiver } from '../../../../main/keyboard-receiver/component'
import { CandidateStyleTestId } from '../../../../window'
import { ColorSet } from '../../design/color-interaction/color-set/color-set'
import { useKeyboardStyleStyleList } from '../../design/styles/hooks/use-keyboard-style-list'
import { StyleColorListItem } from '../../design/styles/style-color/style-color-list-item'
import { StyleColorProps, useStyleColor } from '../../design/styles/style-color/use-style-color'
import style from './candidate-style-color.module.less'
import { translation } from './candidate-style-color.translation'

export function CandidateStyleColor(props: StyleColorProps & { onClose: () => void; width?: number }): JSX.Element {
    const {
        open,
        position,
        isNoStyle,
        onChangeSearchInput,
        showStyleMap,
        onContextMenu,
        onClickApplyStyle,
        selectStyleId,
        onClickHoverIcon,
        styleIdEditor,
        openStyleEditor,
        positionEditor,
        onCloseEditor,
        contextMenuInfo,
        onFirstMove,
        rightClickStyleId,
        setRightClickStyleId,
    } = useStyleColor(props)

    const noSearchResult = useMemo(() => {
        return showStyleMap.length === 0
    }, [showStyleMap.length])

    const maxPreSelectIndex = useMemo(() => {
        let optionSum = 0
        for (const { items } of showStyleMap) {
            optionSum += items.length ?? 0
        }
        return Math.max(optionSum - 1, 0)
    }, [showStyleMap])

    const keyboardStyleStyleList = useKeyboardStyleStyleList({ maxPreSelectIndex, disabled: false })

    const renderListItems = () => {
        let optionIndex = 0
        const _selectStyleId = selectStyleId ? selectStyleId : undefined
        return (
            <>
                {showStyleMap.map(({ name, items }, index) => (
                    <div key={index}>
                        {name ? <div className={style.h2}>{name}</div> : null}
                        <div>
                            {items.map((item, index2) => (
                                <StyleColorListItem
                                    key={item.id}
                                    item={item}
                                    openStyleId={open && openStyleEditor ? styleIdEditor : undefined}
                                    onContextMenu={(...args) => {
                                        setRightClickStyleId(`${item.id}-show-${index}-${index2}`)
                                        onContextMenu(...args)
                                    }}
                                    dataTestId={`${CandidateStyleTestId.LocalPaintStyleItem}-${item.id}`}
                                    onSelectItem={onClickApplyStyle}
                                    onClickHoverIcon={onClickHoverIcon}
                                    isRightClickStyle={rightClickStyleId === `${item.id}-show-${index}-${index2}`}
                                    selectStyleId={_selectStyleId}
                                    index={optionIndex++}
                                    setPreselectIndex={keyboardStyleStyleList.setPreselectIndex}
                                    trySetPreselectIndex={keyboardStyleStyleList.trySetPreselectIndex}
                                    recordEnterCallback={keyboardStyleStyleList.recordEnterCallback}
                                />
                            ))}
                        </div>
                        <div className={style.paddingBottom8}></div>
                    </div>
                ))}
            </>
        )
    }

    return (
        <div>
            <DraggablePopupV2
                visible={open}
                position={position}
                width={props.width ? props.width : 216}
                onFirstMove={onFirstMove}
                onCancel={props.onClose}
                header={<div>{translation('ColorStyle')}</div>}
                headerClassName={style.header}
                bodyClassName="p-0"
                footer={null}
                closable={false}
            >
                {
                    <div className={style.contentContainer}>
                        <InputV2.Search
                            style={{ margin: 8 }}
                            onSearch={onChangeSearchInput}
                            autoFocus
                            onInput={keyboardStyleStyleList.onInput}
                            onKeyDown={keyboardStyleStyleList.onKeyDown}
                            placeholder={translation('Search')}
                        />
                        {isNoStyle ? (
                            <div className={style.emptyStyle}>
                                <div className={style.emptyStyle_text}>{translation('NoColorsAvailable')}</div>
                            </div>
                        ) : noSearchResult ? (
                            <div className={style.emptyStyle}>
                                <div className={style.emptyStyle_text}>{translation('NoMatchesFound')}</div>
                            </div>
                        ) : (
                            <ScrollView
                                className={classnames(style.content)}
                                key="list"
                                selectKey={keyboardStyleStyleList.preselectIndex}
                                ref={keyboardStyleStyleList.scrollViewRef}
                                block="nearest"
                                scrollbar={{ autoHeight: true, autoHeightMin: 0, autoHeightMax: 352 }}
                            >
                                <KeyboardReceiver keyCode={ToKeyCode.All} onKeydown={keyboardStyleStyleList.onKeyDown}>
                                    {renderListItems()}
                                </KeyboardReceiver>
                            </ScrollView>
                        )}
                    </div>
                }
            </DraggablePopupV2>
            {open && openStyleEditor ? (
                <ColorSet
                    position={{
                        top: positionEditor?.top ?? 0,
                        left: (positionEditor?.left ?? 0) + 240 + (props.width ? props.width : 216),
                    }}
                    isCreateStyle={false}
                    allwaysUseStyleTab={false}
                    isRemoteStyle={false}
                    hideCommonFooter={false}
                    paints={[]}
                    hideStyleTab={false}
                    hideVariableTab
                    onCancel={onCloseEditor}
                    onClickCreateButton={() => {}}
                    onCreateStyle={() => {}}
                    onCreateColorVariable={() => {}}
                    onClickRemoteStyleLink={undefined}
                />
            ) : null}
        </div>
    )
}
