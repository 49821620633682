import constate from 'constate'
import { useCallback, useEffect, useState } from 'react'
import type { LibraryContentVO, LibraryIdAndNameVO, LibraryVO } from '../../../../kernel/interface/library'
import { useLibraryComponentService } from '../../../../main/app-context'
import { useLibraryRemoteSearchService } from '../../../../share/component-style-library/hook/use-library-service-remote-search'
import { translation } from './use-library-remote-used-hook.translation'

function useLibraryRemoteUsedAndMissingList() {
    // 缺失组件库列表
    const [missingLibraryInfoList, setMissingLibraryInfoList] = useState<LibraryIdAndNameVO[]>([])
    // 此文件使用组件库列表
    const [inUsedLibraryContentList, setInUsedLibraryContentList] = useState<LibraryContentVO[]>([])
    const libraryRemoteSearchService = useLibraryRemoteSearchService()
    const librarySearchKeyword = libraryRemoteSearchService.states.use.librarySearchKeywordState()
    const libraryQueryResponse = libraryRemoteSearchService.states.use.libraryQueryResponseState()
    const libraryComponentService = useLibraryComponentService()
    const syncInUsedDocumentId2LibraryInfoMap =
        libraryComponentService.states.use.syncInUsedDocumentId2LibraryInfoMapState()
    const remoteLibraryContentMap = libraryComponentService.states.use.remoteLibraryContentMapState()
    const syncInUsedDocumentIdOfHybridRemoteLibrary =
        libraryComponentService.states.use.syncInUsedDocumentIdOfHybridRemoteLibraryState()

    useEffect(() => {
        if (
            !syncInUsedDocumentId2LibraryInfoMap ||
            !libraryQueryResponse ||
            !remoteLibraryContentMap ||
            !syncInUsedDocumentIdOfHybridRemoteLibrary
        ) {
            return
        }

        const _inUsedLibraryContentList: LibraryContentVO[] = []
        const _missingLibraryInfoList: LibraryIdAndNameVO[] = []

        Array.from(syncInUsedDocumentId2LibraryInfoMap.values()).forEach((libraryInfo) => {
            let inUsedLibrary: LibraryVO | undefined
            const plyLibraryList = Object.values(libraryQueryResponse.teamId2LibraryList)
            for (const libraryList of plyLibraryList) {
                // 用 query 接口检查用户是否有对应 library 的组件库权限
                inUsedLibrary = libraryList.find((library) => library.id === libraryInfo.id && library.shared)
                if (inUsedLibrary) {
                    const inUsedLibraryContent = remoteLibraryContentMap.get(libraryInfo.id)
                    if (inUsedLibraryContent) {
                        _inUsedLibraryContentList.push(inUsedLibraryContent)
                    }
                    break
                }
            }
            if (!inUsedLibrary) {
                _missingLibraryInfoList.push(libraryInfo)
            }
        })

        if (syncInUsedDocumentIdOfHybridRemoteLibrary?.size) {
            for (const docId of syncInUsedDocumentIdOfHybridRemoteLibrary) {
                _missingLibraryInfoList.push({
                    id: `library-${docId}`,
                    documentName: translation('Unknown'),
                    shared: false,
                    docId: docId,
                })
            }
        }

        _missingLibraryInfoList.sort((item1, item2) =>
            (item1.documentName ?? '') < (item2.documentName ?? '') ? -1 : 1
        )
        _inUsedLibraryContentList.sort((item1, item2) =>
            (item1.library.document?.name ?? '') < (item2.library.document?.name ?? '') ? -1 : 1
        )

        setMissingLibraryInfoList(_missingLibraryInfoList)
        setInUsedLibraryContentList(_inUsedLibraryContentList)
    }, [
        librarySearchKeyword.length,
        syncInUsedDocumentId2LibraryInfoMap,
        libraryQueryResponse,
        remoteLibraryContentMap,
        syncInUsedDocumentIdOfHybridRemoteLibrary,
    ])

    const onClickMissingJumpBtn = useCallback(() => {
        // 缺失组件库只有一个时，直接进入替换组件库面板
        if (missingLibraryInfoList.length == 1) {
            libraryComponentService.libraryModalRouterService.goToLibraryReplaceDetail(
                missingLibraryInfoList[0].docId,
                missingLibraryInfoList[0].documentName,
                missingLibraryInfoList[0].id
            )
        } else {
            libraryComponentService.libraryModalRouterService.goToRemoteLibraryMissingList()
        }
    }, [libraryComponentService, missingLibraryInfoList])

    return {
        missingLibraryInfoList,
        inUsedLibraryContentList,
        onClickMissingJumpBtn,
    }
}

export const [LibraryRemoteUsedAndMissingListProvider, useLibraryRemoteUsedAndMissingListContext] = constate(
    useLibraryRemoteUsedAndMissingList
)
