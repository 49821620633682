import { ResetLibraryAssetPanelExpandKeysCommand } from '@wukong/bridge-proto'
import { xor } from 'lodash-es'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import { ServiceClass } from '../../../../kernel/util/service-class'
import { UserConfigService } from '../../../../main/user-config/user-config-service'

export class LibraryAssetPanelExpandService extends ServiceClass {
    private expandedKeys: string[] = []
    /**
     * @deprecated TODO(@lisw): 开关 user-config-v2 下线后删除此字段
     */
    private unsubscribe?: () => void

    constructor(
        private readonly userConfigService: UserConfigService,
        private readonly commandInvoker: CommandInvoker
    ) {
        super()
    }

    public init = () => {
        this.syncExpandKeysV2(this.userConfigService.useZustandStore2.getState().libraryAssetPanelExpandKeys)
        this.subscribeZustand(
            this.userConfigService.useZustandStore2,
            (store) => store.libraryAssetPanelExpandKeys,
            (value) => this.syncExpandKeysV2(value)
        )
    }

    /**
     * @deprecated TODO(@lisw): 开关 user-config-v2 下线后删除此方法
     */
    public override destroy() {
        this.unsubscribe?.()
        super.destroy()
    }

    private syncExpandKeysV2 = (newExpandKeys: string[]) => {
        if (xor(this.expandedKeys, newExpandKeys).length) {
            this.expandedKeys = newExpandKeys
            this.commandInvoker.DEPRECATED_invokeBridge(ResetLibraryAssetPanelExpandKeysCommand, {
                value: newExpandKeys,
            })
        }
    }

    // 更改 key 对应的展开状态
    public changeExpandStatus = (expandKey: string | null | undefined, expand: boolean) => {
        if (!expandKey) {
            return
        }

        if (expand && !this.expandedKeys.includes(expandKey)) {
            this.userConfigService.updateConfig2('libraryAssetPanelExpandKeys', [...this.expandedKeys, expandKey])
        } else if (!expand && this.expandedKeys.includes(expandKey)) {
            this.userConfigService.updateConfig2(
                'libraryAssetPanelExpandKeys',
                this.expandedKeys.filter((key) => key !== expandKey)
            )
        }
    }
}
