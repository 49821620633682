import { useMemo, useRef } from 'react'
import { MessageItemContentProps } from '../../../../kernel/interface/chatbot'
import { useAppContext } from '../../../../main/app-context'
import { ChatbotConversation } from './chatbot-conversation'
import { ChatbotInput } from './chatbot-input'
import { ChatbotInputMessage } from './chatbot-input-helpers'
import { ChatbotConversationRef } from './type'

export const ChatbotMainConversationPage = () => {
    const chatbotService = useAppContext().chatbotService
    const allConversations = chatbotService.dataStore.use.allConversations()
    const currentConversationId = chatbotService.stateStore.use.currentConversationId()
    const currentConversationType = chatbotService.getCurrentConversationType()

    const dialogueDataList = useMemo(() => {
        if (!currentConversationId) {
            return []
        }

        const conversation = allConversations[currentConversationId]
        return conversation?.conversationDataList ?? []
    }, [currentConversationId, allConversations])

    const sendMessage = (message: ChatbotInputMessage) => {
        const inputMessage: MessageItemContentProps[] = [{ text: message.text }]
        if (message.imageBase64) {
            inputMessage.push({ image: message.imageBase64 })
        }
        chatbotService.handleClickSend(inputMessage, message.selectedNodeIds, message.seletedNodeThumbnailBase64, true)
    }

    const conversationRef = useRef<ChatbotConversationRef>(null)
    const scrollBy = (y: number) => {
        conversationRef.current?.scrollBy(y)
    }

    return (
        <div className="h-519px flex flex-col justify-between h-full">
            <ChatbotConversation
                ref={conversationRef}
                dialogueDataList={dialogueDataList}
                conversationId={currentConversationId}
            />
            <ChatbotInput
                currentConversationType={currentConversationType}
                answering={false}
                onSend={sendMessage}
                scrollBy={scrollBy}
            />
        </div>
    )
}
