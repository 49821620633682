import classes from './wk-loading-clockwise.module.less'

export function WKLoadingClockwise() {
    return (
        <div className={classes.constraint}>
            <div className={classes.loading}>
                <div className={classes.bone}></div>
                <div className={classes.bone}></div>
                <div className={classes.bone}></div>
                <div className={classes.bone}></div>
                <div className={classes.bone}></div>
                <div className={classes.bone}></div>
                <div className={classes.bone}></div>
                <div className={classes.bone}></div>
            </div>
        </div>
    )
}
