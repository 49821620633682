import { useRef } from 'react'
import { useMount } from 'react-use'
import { Checkbox, DraggablePopupV2, InputV2, WKTabsV2 } from '../../../../../../../../ui-lib/src'
import { TextAreaOperations } from '../../../../../../../../ui-lib/src/components/inputs/textarea/textarea'
import { ComponentPropTestId } from '../../../../../../window'
import { VComponentDescUnit } from '../../types'
import styles from './index.module.less'
import { translation } from './index.translation'

export type ComponentConfigPopupType = 'common' | 'variant'

interface BasePopupProps {
    position: { left: number; top: number } | undefined
    onClose: () => void
    onDescTextAreaChange?: (descUnit: VComponentDescUnit) => void
}

interface CommonConfigPopupProps extends BasePopupProps {
    type: 'common'
    commonDescUnit: VComponentDescUnit
    simplifyInstancePanels: boolean
    updateSimplifyInstancePanels: (value: boolean) => void
}

interface VariantConfigPopupProps extends BasePopupProps {
    type: 'variant'
    compSetDescUnit: VComponentDescUnit
    variantDescUnit: VComponentDescUnit
}

interface InstanceConfigPopupProps extends BasePopupProps {
    type: 'instance'
    compDescUnit: VComponentDescUnit
    compSetDescUnit: VComponentDescUnit
}
type ComponentConfigPopupProps = CommonConfigPopupProps | VariantConfigPopupProps | InstanceConfigPopupProps

export function ComponentConfigPopup(props: ComponentConfigPopupProps) {
    const { position, onClose, onDescTextAreaChange } = props

    return (
        <DraggablePopupV2
            visible
            header={translation('ComponentConfig')}
            positionRightBase
            onCancel={onClose}
            styleType="editor"
            position={position}
            footer={null}
            bodyClassName={styles.componentConfigPopupBody}
            testId={ComponentPropTestId.ComponentConfig.popup}
            enableScrollBar
        >
            {props.type === 'common' && (
                <BaseDescArea
                    showSimplifyInstancePanels={true}
                    descUnit={props.commonDescUnit}
                    onDescTextAreaChange={onDescTextAreaChange}
                    simplifyInstancePanels={props.simplifyInstancePanels}
                    updateSimplifyInstancePanels={props.updateSimplifyInstancePanels}
                />
            )}
            {props.type === 'variant' && (
                <VariantContent
                    compSetDescUnit={props.compSetDescUnit}
                    variantDescUnit={props.variantDescUnit}
                    onDescTextAreaChange={onDescTextAreaChange}
                />
            )}
            {props.type == 'instance' &&
                (!!props.compDescUnit.desc && !!props.compSetDescUnit.desc ? (
                    <VariantContent compSetDescUnit={props.compSetDescUnit} variantDescUnit={props.compDescUnit} />
                ) : (
                    <BaseDescArea
                        showSimplifyInstancePanels={false}
                        descUnit={props.compDescUnit.desc ? props.compDescUnit : props.compSetDescUnit}
                    />
                ))}
        </DraggablePopupV2>
    )
}

function BaseDescArea(props: {
    descUnit: VComponentDescUnit
    simplifyInstancePanels?: boolean
    showSimplifyInstancePanels: boolean
    onDescTextAreaChange?: (descUnit: VComponentDescUnit) => void
    updateSimplifyInstancePanels?: (value: boolean) => void
}) {
    const {
        descUnit,
        simplifyInstancePanels,
        showSimplifyInstancePanels,
        onDescTextAreaChange,
        updateSimplifyInstancePanels,
    } = props

    const handleTextAreaChange = (value: string) => {
        if (value !== descUnit.desc) {
            onDescTextAreaChange?.({
                ...descUnit,
                desc: value,
            })
        }
    }

    const textAreaRef = useRef<TextAreaOperations>(null)
    useMount(() => textAreaRef.current?.scrollToBottom())

    return (
        <>
            <div className={styles.baseDescWrapper}>
                <div className={styles.label}>{translation('Desc')}</div>
                <div className={styles.descWrapper}>
                    {onDescTextAreaChange ? (
                        <InputV2.Textarea
                            ref={textAreaRef}
                            autoFocus
                            placeholder={translation('HowToUseThisComponent')}
                            autoHeight
                            minHeight={124}
                            maxHeight={352}
                            defaultValue={descUnit.desc}
                            onBlur={(e) => handleTextAreaChange((e.target as HTMLTextAreaElement).value)}
                        />
                    ) : (
                        <div className={styles.readonlyDesc}>{descUnit.desc}</div>
                    )}
                </div>
            </div>
            {showSimplifyInstancePanels && (
                <div className={styles.simplifyBlock}>
                    <Checkbox
                        checked={simplifyInstancePanels}
                        label={translation('SimplifyAllInstances')}
                        testid={ComponentPropTestId.ComponentConfig.popupSimplifyInstanceBtn}
                        onChange={updateSimplifyInstancePanels}
                    />
                </div>
            )}
        </>
    )
}

enum VariantTabs {
    Variant = 'variant',
    ComponentSet = 'componentSet',
}

function VariantContent(props: {
    compSetDescUnit: VComponentDescUnit
    variantDescUnit: VComponentDescUnit
    onDescTextAreaChange?: (descUnit: VComponentDescUnit) => void
}) {
    const { compSetDescUnit, variantDescUnit, onDescTextAreaChange } = props
    return (
        <div className={styles.variantContent}>
            <WKTabsV2
                navListClassName={styles.variantContentNavList}
                navItemClassName={styles.variantContentNavItem}
                defaultActiveKey={VariantTabs.Variant}
            >
                <WKTabsV2.Item
                    tabKey={VariantTabs.ComponentSet}
                    label={
                        <>
                            <span>{translation('ComponentSet')}</span>
                        </>
                    }
                >
                    <BaseDescArea
                        descUnit={compSetDescUnit}
                        onDescTextAreaChange={onDescTextAreaChange}
                        showSimplifyInstancePanels={false}
                    />
                </WKTabsV2.Item>
                <WKTabsV2.Item
                    tabKey={VariantTabs.Variant}
                    label={
                        <>
                            <span>{translation('SelectedVariant')}</span>
                        </>
                    }
                >
                    <BaseDescArea
                        descUnit={variantDescUnit}
                        onDescTextAreaChange={onDescTextAreaChange}
                        showSimplifyInstancePanels={false}
                    />
                </WKTabsV2.Item>
            </WKTabsV2>
        </div>
    )
}
