import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    Show: '显示',
    Hide: '隐藏',
    HideComments: '隐藏评论',
    Lock: '锁定',
    Unlock: '解锁',
    UnionSelection: '并集',
    SubstrateSelection: '减去顶层',
    IntersectSelection: '交集',
    ExcludeSelection: '排除重叠',
    OutlineStroke: '轮廓化',
    Flatten: '拼合',
    RemoveFill: '移除填充',
    RemoveStroke: '移除描边',
    SwapFillAnd: '交换填充和描边',
    UseAsMask: '设为蒙版',
    RemoveMask: '取消蒙版',
    GroupSelection: '创建编组',
    FrameSelection: '创建容器',
    UngroupSelection: '取消编组',
    BringToFront: '移到顶层',
    BringForward: '上移一层',
    BringBackward: '下移一层',
    SendToBack: '移到底层',
    FlipHorizontal: '水平翻转',
    FlipVertical: '垂直翻转',
    AddAutoLayout: '添加自动布局',
    RemoveAutoLayout: '取消自动布局',
    RemoveAllAutoLayout: '移除全部自动布局',
    RxsUsj: '进入 AI 布局',
    HDyoMR: '退出 AI 布局',
    PublishSelectedComponents: '发布选中组件',
    HideUI: '隐藏界面',
    CreateComponent: '创建组件',
    ResetAllChanges: '重置所有修改',
    DetachInstance: '分离实例',
    GoToMain: '跳转至组件母版',
    Undo: '撤销',
    Redo: '重做',
    Duplicate: '创建副本',
    Delete: '删除',
    PickColor: '吸取颜色',
    SelectAll: '全选',
    SelectNone: '取消选中',
    SelectInverse: '反选',
    Rename: '重命名',
    HideOtherLayers: '隐藏其他图层',
    SmartAddAutoLayout: '智能添加自动布局',
} as const

export const enTranslation = {
    Show: 'Show',
    Hide: 'Hide',
    HideComments: 'Hide comments',
    Lock: 'Lock',
    Unlock: 'Unlock',
    UnionSelection: 'Union selection',
    SubstrateSelection: 'Subtract selection',
    IntersectSelection: 'Intersect selection',
    ExcludeSelection: 'Exclude selection',
    OutlineStroke: 'Outline stroke',
    Flatten: 'Flatten',
    RemoveFill: 'Remove fill',
    RemoveStroke: 'Remove stroke',
    SwapFillAnd: 'Swap fill and stroke',
    UseAsMask: 'Use as mask',
    RemoveMask: 'Remove mask',
    GroupSelection: 'Group selection',
    FrameSelection: 'Frame selection',
    UngroupSelection: 'Ungroup selection',
    BringToFront: 'Bring to front',
    BringForward: 'Bring forward',
    BringBackward: 'Bring backward',
    SendToBack: 'Send to back',
    FlipHorizontal: 'Flip horizontal',
    FlipVertical: 'Flip vertical',
    AddAutoLayout: 'Add auto layout',
    RemoveAutoLayout: 'Remove auto layout',
    RemoveAllAutoLayout: 'Remove all auto layout',
    RxsUsj: '进入 AI layout',
    HDyoMR: '退出 AI layout',
    PublishSelectedComponents: 'Publish selected components',
    HideUI: 'Hide UI',
    CreateComponent: 'Create component',
    ResetAllChanges: 'Reset all changes',
    DetachInstance: 'Detach instance',
    GoToMain: 'Go to main component',
    Undo: 'Undo',
    Redo: 'Redo',
    Duplicate: 'Duplicate',
    Delete: 'Delete',
    PickColor: 'Pick color',
    SelectAll: 'Select all',
    SelectNone: 'Select none',
    SelectInverse: 'Select inverse',
    Rename: 'Rename',
    HideOtherLayers: 'Hide other layers',
    SmartAddAutoLayout: 'AI adds auto layout',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
