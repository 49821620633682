import {
    MonoIconCommonCheckboxChecked16,
    MonoIconCommonCloseBold16,
    MonoIconPanelStrokeEndpointLineArrow16,
    Tag,
    TagProps,
    WKButton,
    WKButtonProps,
    WKTextButton,
} from '../../../../../../../ui-lib/src'
import { isEnglishLanguage } from '../../../../../../../util/src'
import classnames from 'classnames'
import React from 'react'
import classes from './privilege-card.module.less'
import { translation } from './privilege-card.translation'

export interface PrivilegeCardProps {
    name: string
    tagProps?: TagProps
    price?: {
        designer: {
            money: string
            per: string
        }
        developer: {
            money: string
            per: string
        }
    }
    priceReactNode?: React.ReactNode
    buttonProps: WKButtonProps
    privilegesTitle?: string
    privileges: { name: string; enable: boolean }[]
    className?: string
    moreProps?: {
        onClick?: () => void
    }
    dataTestIds?: {
        title?: string
        price?: string
        button?: string
        privileges?: string
        prefixPrivilege?: string
    }
}
export function PrivilegeCard(props: PrivilegeCardProps) {
    const {
        name,
        tagProps,
        price,
        priceReactNode,
        buttonProps,
        privilegesTitle,
        privileges,
        className,
        moreProps,
        dataTestIds,
    } = props
    return (
        <div className={classnames(classes.card, className)}>
            <div className={classes.title}>
                <span className={classes.name} data-testid={dataTestIds?.title}>
                    {name}
                </span>
                {tagProps ? (
                    <div className={classes.tag}>
                        <Tag color="blue" structure="line-surface" {...tagProps} />
                    </div>
                ) : null}
            </div>
            <div className={classes.price} data-testid={dataTestIds?.price}>
                {priceReactNode ? (
                    priceReactNode
                ) : price ? (
                    <>
                        <div className={classes.priceItem}>
                            <div>
                                <div className={classes.priceName}>{translation('des')}</div>
                                <div className={classes.priceSubName}>
                                    {isEnglishLanguage() ? (
                                        <>
                                            <span className={classes.devName}>Develop mode</span> included
                                        </>
                                    ) : (
                                        <>
                                            包含<span className={classes.devName}>研发模式</span>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div>
                                <span className={classes.priceNum}>{price.designer.money}</span>
                                <span className={classes.pricePer}>/ {price.designer.per}</span>
                            </div>
                        </div>
                        <div className={classes.priceItem}>
                            <div>
                                <div className={classes.priceName}>{translation('dev')}</div>
                            </div>
                            <div>
                                <span className={classes.priceNum}>{price.developer.money}</span>
                                <span className={classes.pricePer}>/ {price.developer.per}</span>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className={classes.free}>{translation('free')}</div>
                )}
            </div>
            <div className={classes.button}>
                <WKButton {...buttonProps} style={{ width: '100%' }} dataTestId={dataTestIds?.button} />
            </div>
            <div className={classes.privileges} data-testid={dataTestIds?.privileges}>
                {privilegesTitle ? (
                    <div
                        className={classnames(classes.privilege, classes.privilegeTitle)}
                        data-testid={`${dataTestIds?.prefixPrivilege}-title`}
                    >
                        {privilegesTitle}
                    </div>
                ) : null}
                {privileges.map((v, index) => (
                    <div
                        key={index}
                        className={classes.privilege}
                        data-testid={`${dataTestIds?.prefixPrivilege}-${index}`}
                    >
                        <span className={classes.icon}>
                            {v.enable ? <MonoIconCommonCheckboxChecked16 /> : <MonoIconCommonCloseBold16 />}
                        </span>
                        <span>{v.name}</span>
                    </div>
                ))}
            </div>
            {moreProps ? (
                <div className={classes.more}>
                    <WKTextButton type="primary" size={12} onClick={moreProps.onClick}>
                        {translation('sa')}
                        <MonoIconPanelStrokeEndpointLineArrow16 className={classes.arrow} />
                    </WKTextButton>
                </div>
            ) : null}
        </div>
    )
}
