/* eslint-disable no-restricted-imports */
import { useCallback, useMemo, useRef } from 'react'
import { featureSwitchManager } from '../../../../kernel/switch/core'
import { useCommentService } from '../../../../main/app-context'
import { useEventAnchor } from '../comment-service/use-event-anchor'
import { Comment, CommentCluster } from '../type'
import { getClusterShowUserInfo } from '../utils'
import { ClusterBubbleDOMProps } from './cluster-bubble-dom'
export interface ClusterBubbleProps {
    cluster: CommentCluster
    dataTestIds?: ClusterBubbleDOMProps['dataTestIds']
}

export function useClusterBubble(props: ClusterBubbleProps): ClusterBubbleDOMProps {
    const { cluster, dataTestIds } = props
    const { onAnchorMouseMove } = useEventAnchor()
    const commentService = useCommentService()

    const comments = useMemo(() => {
        return cluster.commentIds
            .map((commentId) => commentService.getCommentById(commentId))
            .filter(Boolean) as Comment[]
    }, [cluster.commentIds, commentService])

    const clusterId = cluster.clusterId

    const moveKeys = useRef<string[]>([])
    const scaleKeys = useRef<string[]>([])

    const showUsers = useMemo(() => {
        return getClusterShowUserInfo(comments)
    }, [comments])

    const onClick = useCallback(() => {
        // 打散策略
        commentService.clickCluster(clusterId)
    }, [clusterId, commentService])

    const onMouseMove = useCallback(
        (e: React.MouseEvent) => {
            onAnchorMouseMove(e.nativeEvent)
        },
        [onAnchorMouseMove]
    )

    const anchorRefCallback = useCallback(
        (element: HTMLDivElement | null) => {
            if (element) {
                const moveKey = commentService.positionService.registerBubbleAnchor(clusterId, (position) => {
                    if (featureSwitchManager.isEnabled('comment-position-transform')) {
                        if (element) {
                            // 居中偏移 x-= width/2 = -32，y -= height/2 = -32
                            element.style.transform = `translate(${position.left - 32}px, ${position.top - 32}px)`
                        }
                    } else {
                        element?.setAttribute('style', `left:${position.left}px;top:${position.top}px;`)
                    }
                })
                moveKeys.current.push(moveKey)

                const scaleKey = commentService.positionService.registerUpdateBubblePositionWhenScale(
                    element,
                    clusterId
                )
                scaleKeys.current.push(scaleKey)
            } else {
                moveKeys.current.forEach((key) => commentService.positionService.unregisterBubble(key))
                scaleKeys.current.forEach((key) =>
                    commentService.positionService.unRegisterUpdateBubblePositionWhenScale(key)
                )
            }
            if (element) {
                if (featureSwitchManager.isEnabled('comment-position-transform')) {
                    commentService.animationService.createClusterByExitClusterV2(element, clusterId)
                } else {
                    commentService.animationService.createClusterByExitCluster(element, clusterId)
                }
            }
        },
        [clusterId, commentService.animationService, commentService.positionService]
    )

    return {
        showUsers: showUsers.userList,
        commentCounts: comments.length,
        showUsersOverlaySize: showUsers.overflowNum,
        isUnread: comments.some((comment) => comment.commentMetaData.unread),
        onClick: onClick,
        onMouseMove: onMouseMove,
        anchorRefCallback: anchorRefCallback,
        dataTestIds: dataTestIds,
    }
}
