import moment from 'moment'
import { translation } from './time.translation'

export function sleep(ms: number) {
    return new Promise<'sleep'>((resolve) =>
        setTimeout(() => {
            resolve('sleep')
        }, ms)
    )
}

export const timeFormat = (timestamp: number, format: string) => {
    return moment(timestamp).format(format)
}

/**
 * 时间展示规则： https://www.notion.so/kanyun/WIP-Motiff-Style-Guide-feeaf02625644267ba57f672af24e212#bb809da8092347a593c6205ae9f55652
 */
export const ONE_MINITE = 60 * 1000
export const ONE_HOUR = 60 * ONE_MINITE
export const ONE_DAY = 24 * ONE_HOUR

function isYesterday(t: number, now = new Date()) {
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const startOfToday = today.getTime()
    const startOfYesterday = startOfToday - ONE_DAY
    return t >= startOfYesterday && t < startOfToday
}

function getMiniuteAgoDesc(diff: number) {
    const minute = Math.floor(diff / ONE_MINITE)
    return `${minute} ${minute === 1 ? translation('MinuteAgo') : translation('MinutesAgo')}`
}

function getHourAgoDesc(diff: number) {
    const hour = Math.floor(diff / ONE_HOUR)
    return `${hour} ${hour === 1 ? translation('HourAgo') : translation('HoursAgo')}`
}

// 如果单独使用，需要首字母大写，使用 capitalizeFirstLetter 函数
// 更粗版
export function timeDesc(timestamp: number, timestampNow = Date.now()) {
    const date = new Date(timestamp)
    const now = new Date(timestampNow)
    const diff = timestampNow - timestamp
    const yearDiff = now.getFullYear() - date.getFullYear()

    if (diff < ONE_MINITE) {
        return translation('JustNow')
    } else if (diff < ONE_HOUR) {
        return getMiniuteAgoDesc(diff)
    } else if (date.toDateString() === now.toDateString()) {
        return getHourAgoDesc(diff)
    } else if (isYesterday(timestamp, now)) {
        return `${translation('Yesterday')}`
    } else if (yearDiff === 0) {
        return `${timeFormat(timestamp, translation('MonthDay'))}`
    } else {
        return `${timeFormat(timestamp, translation('YearMonthDay'))}`
    }
}

// 如果单独使用，需要首字母大写，使用 capitalizeFirstLetter 函数
// 粗略版
export function timeDesc2(timestamp: number, timestampNow = Date.now()) {
    const date = new Date(timestamp)
    const now = new Date(timestampNow)
    const diff = timestampNow - timestamp
    const yearDiff = now.getFullYear() - date.getFullYear()

    if (diff < ONE_MINITE) {
        return translation('JustNow')
    } else if (diff < ONE_HOUR) {
        return getMiniuteAgoDesc(diff)
    } else if (date.toDateString() === now.toDateString()) {
        return `${translation('Today')} ${timeFormat(timestamp, translation('HourMinute'))}`
    } else if (isYesterday(timestamp, now)) {
        return `${translation('Yesterday')} ${timeFormat(timestamp, translation('HourMinute'))}`
    } else if (yearDiff === 0) {
        return `${timeFormat(timestamp, translation('MonthDayHourMinute'))}`
    } else {
        return `${timeFormat(timestamp, translation('YearMonthDay'))}`
    }
}

// 如果单独使用，需要首字母大写，使用 capitalizeFirstLetter 函数
// 明细版
export function timeDesc3(timestamp: number, timestampNow = Date.now()) {
    const date = new Date(timestamp)
    const now = new Date(timestampNow)
    const diff = timestampNow - timestamp
    const yearDiff = now.getFullYear() - date.getFullYear()

    if (diff < ONE_MINITE) {
        return translation('JustNow')
    } else if (diff < ONE_HOUR) {
        return getMiniuteAgoDesc(diff)
    } else if (date.toDateString() === now.toDateString()) {
        return `${translation('Today')} ${timeFormat(timestamp, translation('HourMinute'))}`
    } else if (isYesterday(timestamp, now)) {
        return `${translation('Yesterday')} ${timeFormat(timestamp, translation('HourMinute'))}`
    } else if (yearDiff === 0) {
        return `${timeFormat(timestamp, translation('MonthDayHourMinute'))}`
    } else {
        return `${timeFormat(timestamp, translation('YearMonthDayHourMinute'))}`
    }
}

// 更细版
export function timeDesc4(timestamp: number) {
    return `${timeFormat(timestamp, translation('Detail'))}`
}

export function timeDesc5(timestamp: number) {
    return `${timeFormat(timestamp, translation('YearMonthDay2'))}`
}
