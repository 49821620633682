import { computed } from 'ccstate'
import { traceable } from '../../../../util/src/ccstate-helper/traceable'
import { getCanOpenThisDomainInDesktop } from '../../main/desktop/desktop'
import { useLoadable } from 'ccstate-react'

export const canOpenDesktop$ = traceable(
    'hulh01@kanyun.com',
    computed(async () => getCanOpenThisDomainInDesktop())
)

export const useLoadableCanOpenDesktop = () => useLoadable(canOpenDesktop$)
