import { Select } from '../../../../../../../ui-lib/src'
import {
    InteractionActionType,
    InteractionActionType2Label,
    InteractionActionTypeWithMixed,
    MIXED_TYPE,
    MIXED_TYPE_LABEL,
} from '../../prototype-interaction/constants'
import { InteractionActionIcon } from '../../prototype-interaction/interaction-item'

export const InteractionActionSelect = ({
    type,
    onChange,
}: {
    type: InteractionActionTypeWithMixed
    onChange: (v: InteractionActionType) => void
}) => {
    const renderItem = (item: InteractionActionTypeWithMixed) => {
        return (
            <div className="flex items-center">
                <InteractionActionIcon type={item} />
                <div className="pl-2">{item === MIXED_TYPE ? MIXED_TYPE_LABEL : InteractionActionType2Label[item]}</div>
            </div>
        )
    }
    return (
        <Select.MinimalSingleLevel
            value={type}
            label={<div className="pl-4 wk-font-semibold">{renderItem(type)}</div>}
            onChange={onChange}
            dataTestIds={{
                triggerFocus: 'interaction-action-select-trigger',
                container: 'interaction-action-select-menu',
            }}
        >
            {type === MIXED_TYPE ? (
                <Select.MinimalSingleLevel.Option key={type} value={type} disabled>
                    {renderItem(type)}
                </Select.MinimalSingleLevel.Option>
            ) : null}
            {[...Object.values(InteractionActionType)].map((item) => {
                return (
                    <Select.MinimalSingleLevel.Option key={item} value={item}>
                        {renderItem(item)}
                    </Select.MinimalSingleLevel.Option>
                )
            })}
        </Select.MinimalSingleLevel>
    )
}
