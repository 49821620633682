import { Wukong } from '@wukong/bridge-proto'
import { findLastIndex, flattenDeep } from 'lodash-es'
import { compareOrderIndex, compareString } from '../../../../../util/src'
import {
    ComponentGetVO,
    ComponentSetGetVO,
    StyleGetVO,
    VariableSetGetVO,
} from '../../../kernel/interface/component-style'
import { LibraryContentVO } from '../../../kernel/interface/library'
import { deleteRemovedAndMovedVOInLibraryContent } from './filter'

export enum DivideSortLibraryComponentDisplayType {
    OnlyPage,
    Page,
    Frame,
}

export interface DivideSortLibraryComponentDisplayItem {
    displayType: DivideSortLibraryComponentDisplayType
    id: string
    name?: string | null
    children: ComponentGetVO[] | ComponentSetGetVO[]
}

export interface DivideSortLibraryContent {
    styleList: StyleGetVO[]
    componentDisplayList: DivideSortLibraryComponentDisplayItem[]
    variableCollectionList: VariableSetGetVO[]
}

// 对远端 libraryContent 数据做排序分组
export function getDivideSortLibraryContent(libraryContent_: LibraryContentVO): DivideSortLibraryContent {
    const emptyFrameId = ''
    const libraryContent = deleteRemovedAndMovedVOInLibraryContent(libraryContent_)
    const result: DivideSortLibraryContent = {
        styleList: getDivideSortStyleListAsGroupName(libraryContent.styles),
        componentDisplayList: [],
        variableCollectionList: [...libraryContent.variableSets],
    }

    // 组件/组件集排序
    const pageId2FrameId2ComponentListMap = new Map<
        string,
        {
            containerPageName?: string | null
            frameId2ComponentListMap: Map<
                string,
                {
                    containerFrameName?: string | null
                    componentList: ComponentGetVO[]
                }
            >
        }
    >()

    ;[...libraryContent.components, ...libraryContent.componentSets].sort(compareComponentVO).forEach((component) => {
        if (!component.containingPageId) {
            throw new Error('[deleteRemovedAndMovedVOInLibraryContent] has some dirty vos')
        }
        const frameId2ComponentListMap =
            pageId2FrameId2ComponentListMap.get(component.containingPageId)?.frameId2ComponentListMap ??
            new Map<
                string,
                {
                    containerFrameName?: string | null
                    componentList: ComponentGetVO[]
                }
            >()
        const containingFrameId = component.containingFrameId || emptyFrameId
        frameId2ComponentListMap.set(containingFrameId, {
            containerFrameName: component.containingFrameName,
            componentList: [...(frameId2ComponentListMap.get(containingFrameId)?.componentList ?? []), component],
        })
        pageId2FrameId2ComponentListMap.set(component.containingPageId, {
            containerPageName: component.containingPageName,
            frameId2ComponentListMap,
        })
    })
    ;[...pageId2FrameId2ComponentListMap.entries()]
        .sort((e1, e2) => compareString(e1[1].containerPageName ?? '', e2[1].containerPageName ?? ''))
        .forEach(([pageId, { containerPageName, frameId2ComponentListMap }]) => {
            // 优先展示无 frame 组件
            const emptyFrameComponentList = frameId2ComponentListMap.get(emptyFrameId)?.componentList
            if (emptyFrameComponentList?.length) {
                result.componentDisplayList.push({
                    displayType: DivideSortLibraryComponentDisplayType.OnlyPage,
                    id: pageId,
                    name: containerPageName,
                    children: emptyFrameComponentList.sort(compareComponentVO),
                })
                frameId2ComponentListMap.delete(emptyFrameId)
            } else if (frameId2ComponentListMap.size) {
                result.componentDisplayList.push({
                    displayType: DivideSortLibraryComponentDisplayType.Page,
                    id: 'page-' + pageId,
                    name: containerPageName,
                    children: [],
                })
            }

            ;[...frameId2ComponentListMap.entries()]
                .sort((e1, e2) => compareString(e1[1].containerFrameName ?? '', e2[1].containerFrameName ?? ''))
                .forEach(([frameId, { containerFrameName, componentList }]) => {
                    result.componentDisplayList.push({
                        displayType: DivideSortLibraryComponentDisplayType.Frame,
                        id: frameId,
                        name: containerFrameName,
                        children: componentList.sort(compareComponentVO),
                    })
                })
        })

    return result
}

// 按 name 字典序
export function compareComponentVO(c1: ComponentGetVO, c2: ComponentGetVO) {
    return (c1.name ?? '') > (c2.name ?? '') ? 1 : -1
}

// 按 name 字典序
export function compareVariableSetVO(vs1: VariableSetGetVO, vs2: VariableSetGetVO) {
    return (vs1.name ?? '') > (vs2.name ?? '') ? 1 : -1
}

interface StyleGetVOWithChildren {
    children: StyleGetVO[]
    name: string
}

// 样式排序
function getDivideSortStyleListAsGroupName(styles: ReadonlyArray<StyleGetVO>): StyleGetVO[] {
    const plyStyleList: StyleGetVO[][] = Array.from({ length: 5 }, () => [])
    styles.forEach((style) => {
        switch (style.type) {
            case Wukong.DocumentProto.NodeType.NODE_TYPE_TEXT_STYLE:
                plyStyleList[0].push(style)
                break
            case Wukong.DocumentProto.NodeType.NODE_TYPE_PAINT_STYLE:
                plyStyleList[1].push(style)
                break
            case Wukong.DocumentProto.NodeType.NODE_TYPE_EFFECT_STYLE:
                plyStyleList[2].push(style)
                break
            case Wukong.DocumentProto.NodeType.NODE_TYPE_LAYOUT_GRID_STYLE:
                plyStyleList[3].push(style)
                break
            default:
                plyStyleList[4].push(style)
                break
        }
    })

    return flattenDeep(
        plyStyleList.map((styleList) => {
            if (styleList.length <= 1) {
                return styleList
            }

            styleList.sort((s1, s2) => compareOrderIndex(s1.sortPosition ?? '', s2.sortPosition ?? ''))

            const result: StyleGetVOWithChildren[] = [{ name: '', children: [] }]
            const groupNameSet = new Set<string>()

            styleList.forEach((style) => {
                const allPaths = (style.name ?? '').split('/')
                allPaths.pop()
                const groupNameList: string[] = []
                allPaths.reduce((prefix, groupName) => {
                    groupNameList.push(prefix + groupName)
                    return prefix + groupName + '/'
                }, '')
                let parentGroupName = ''
                groupNameList.forEach((groupName) => {
                    if (!groupNameSet.has(groupName)) {
                        const index = findLastIndex(
                            result,
                            (item) =>
                                (!item.name.length && !parentGroupName.length) || item.name?.startsWith(parentGroupName)
                        )

                        if (index > -1) {
                            result.splice(index, 0, {
                                name: groupName,
                                children: [],
                            })
                            groupNameSet.add(groupName)
                        }
                    }
                    parentGroupName = groupName
                })

                const folder = groupNameList.length ? groupNameList[groupNameList.length - 1] : ''
                const index = result.findIndex((item) => item.name === folder)
                if (index > -1) {
                    result[index].children.push(style)
                }
            })

            return result.map(({ children }) => children)
        })
    )
}
