/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { useViewState } from '../../../view-state-bridge'
import { useCommand } from '../../context/document-context'
import { cmdTextInputChange } from '../../../document/command/node-props-command'

export function useTextEdit() {
    const command = useCommand()

    const viewState = useViewState('textEditingState')

    const hyperlinkEditingState = useViewState('hyperlinkEditingState')
    const hyperlinkEditing =
        hyperlinkEditingState &&
        hyperlinkEditingState.mode != Wukong.DocumentProto.HyperlinkEditingMode.HYPERLINK_EDITING_MODE_NONE

    const isEditing = viewState?.textEditing

    const textInputChange = (inputEvent: Wukong.DocumentProto.IArg_cmdTextInput) => {
        command.invoke(cmdTextInputChange, inputEvent.mode!, inputEvent.value)
    }

    return {
        isEditing,
        hyperlinkEditing,
        textInputChange,
    }
}
