import { HTMLAttributes, useRef, useState } from 'react'
import { useMount } from 'react-use'
import { HtmlInput } from '../../../../../ui-lib/src/components/inputs/html-input/html-input'
import { isKey, isKeyAsEnter, KeyboardCode } from '../../../kernel/keyboard/keyboard-event-handler'

export function BorderlessInput(
    props: {
        initValue: string
        submit: (value: string) => void
        abort: () => void
    } & HTMLAttributes<HTMLInputElement>
) {
    const { initValue, submit, abort, style, ...otherProps } = props
    const inputRef = useRef<HTMLInputElement>(null)
    const [value, setValue] = useState(initValue)
    useMount(() => {
        inputRef.current?.scrollIntoView?.({ block: 'nearest' })
        inputRef.current?.focus()
        inputRef.current?.select()
    })
    return (
        <HtmlInput
            ref={inputRef}
            value={value}
            style={{
                ...style,
                border: 'none',
                padding: 0,
                background: 'transparent',
                cursor: 'default',
            }}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => {
                e.stopPropagation()
                if (isKey(e.nativeEvent, KeyboardCode.ESCAPE)) {
                    abort()
                }
                if (isKeyAsEnter(e.nativeEvent) || isKey(e.nativeEvent, KeyboardCode.TAB)) {
                    submit(value)
                }
            }}
            onBlur={() => {
                submit(value)
            }}
            onMouseDown={(e) => {
                e.stopPropagation()
            }}
            {...otherProps}
        />
    )
}
