import { useEffect, useMemo, useState } from 'react'
import { SceneBlockFull, SceneBlockFullProps } from '../transition/scene-block-full'
import { TransitionContainer, TransitionContainerProps } from '../transition/transition-container'
import { TransitionView } from '../transition/transition-view'

export interface FadeSlideEffectsProps {
    upperBlock: SceneBlockFullProps
    lowerBlock?: SceneBlockFullProps
    smartHeaderBlock?: SceneBlockFullProps
    shouldStartTransition?: TransitionContainerProps['shouldStartTransition']
}
/**
 * @description  淡入淡出效果
 * */
export function FadeSlideEffects(props: FadeSlideEffectsProps) {
    const { upperBlock, lowerBlock, shouldStartTransition, smartHeaderBlock } = props
    const [play, setPlay] = useState<boolean>(false)

    const SceneBlockLowerLayerProps = useMemo((): SceneBlockFullProps => {
        return {
            browHeight: 8,
            backgroundColor: 'white',
            ...lowerBlock,
            play: lowerBlock?.play !== undefined ? lowerBlock.play : play,
        }
    }, [lowerBlock, play])

    const SceneBlockUpperLayerProps = useMemo((): SceneBlockFullProps => {
        return {
            browHeight: 16,
            backgroundColor: 'gray',
            ...upperBlock,
            play: upperBlock.play !== undefined ? upperBlock.play : play,
        }
    }, [play, upperBlock])

    const smartHeader = useMemo(() => {
        if (!smartHeaderBlock) {
            return null
        }

        const headerProps = {
            browHeightFrom: 8,
            browHeightTo: 16,
            backgroundColor: 'transparent',
            ...smartHeaderBlock,
            play: smartHeaderBlock.play !== undefined ? smartHeaderBlock.play : play,
        } as const
        return <SceneBlockFull {...headerProps} />
    }, [play, smartHeaderBlock])

    useEffect(() => setPlay(true), [upperBlock.transition, lowerBlock?.transition, smartHeaderBlock?.transition])

    return (
        <TransitionContainer
            shouldStartTransition={() => (setPlay(true), shouldStartTransition?.())}
            shouldEndTransition={() => setPlay(false)}
        >
            <TransitionView>
                <SceneBlockFull {...SceneBlockLowerLayerProps} dataTestIds={{ block: 'lower-animation-block' }} />
                <SceneBlockFull {...SceneBlockUpperLayerProps} dataTestIds={{ block: 'upper-animation-block' }} />
                {smartHeader}
            </TransitionView>
        </TransitionContainer>
    )
}
