/* eslint-disable no-restricted-imports */
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import type { CanvasRenderBridge } from '../../document/bridge/canvas-render-bridge'
import type { CommandInvoker } from '../../document/command/command-invoker'
import { ImageDownloadContext } from '../../document/command/image-download-context'
import { EmBridge } from '../../kernel/bridge/em-bridge'
import { NotifyService } from '../../kernel/notify/notify-service'
import { isEventTargetCopyable } from '../event-target-copyable'
import { isEventTargetEditable, isFromTextNodeHiddenInput, needForbidShortcuts } from '../event-target-editable'
import { PreExportService } from '../service/pre-export-service'
import { ClipboardDataManager } from './clipboard-service/clipboard-data-manager'
import { ClipboardService } from './clipboard-service/clipboard-service'
import type { ExecutableClipboardService } from './clipboard-service/clipboard-service-interface'
import { ClipboardWasmConnector } from './clipboard-service/clipboard-wasm-connector'

export const shouldHandleClipboardEvent = (event: Event) => {
    return (
        (!isEventTargetEditable(event) && !isEventTargetCopyable(event) && !needForbidShortcuts()) ||
        isFromTextNodeHiddenInput(event)
    )
}

export function setupClipboard(
    signal: TraceableAbortSignal,
    bridge: EmBridge,
    canvasRenderBridge: CanvasRenderBridge,
    commandInvoker: CommandInvoker,
    imageDownloadContext: ImageDownloadContext,
    preExportService: PreExportService,
    docId: string,
    notifyService: NotifyService
): ExecutableClipboardService {
    const clipboardDataManager = new ClipboardDataManager(commandInvoker, imageDownloadContext)
    const clipboardWasmConnector = new ClipboardWasmConnector(
        bridge,
        commandInvoker,
        canvasRenderBridge,
        clipboardDataManager
    )
    const clipboardService = new ClipboardService(
        signal,
        clipboardDataManager,
        commandInvoker,
        clipboardWasmConnector,
        shouldHandleClipboardEvent,
        preExportService,
        bridge,
        docId,
        notifyService,
        imageDownloadContext
    )

    return clipboardService
}
