export enum MemberPermission {
    Inherit = 'inherit',
    View = 'view',
    Edit = 'edit',
    None = 'none',
}

export interface AccessTeamVO {
    teamId: TeamID
    viewableKey: string
    editableKey: string
    editable: boolean
    viewable: boolean
}

export interface AccessFolderVO {
    folderId: FolderID
    teamMemberPermission: MemberPermission
}

export interface UpdateInheritAccessVO {
    memberPermissionEnum: MemberPermission
}

export interface DocumentPublicAccessVO {
    publicAccessible: boolean
    publicAccessRole: RoleStatus
    orgMemberOnly?: boolean
    passwordRequired: boolean
    password: string
}

export interface AccessDocVO {
    docId: DocID
    folderMemberPermission: MemberPermission
    copyable: boolean
    documentPublicAccessVO: DocumentPublicAccessVO
    /**
     * 组织对应的组织域
     */
    readonly domainList?: string[]
}
export enum TeamLinkOperationTypeEnum {
    Generate = 'generate',
    Reset = 'reset',
    Disable = 'disable',
}

export interface OnlineUserVO {
    userId: UserID
    userProfile: UserProfileVO
    entryTime: Datetime
}

export interface UserProfileVO {
    nickname: string
    avatarId: string
    avatarBackgroundColor: string
}

export interface LastUserOperationVO {
    /**
     * 操作时间
     * @format int64
     */
    readonly operationTime: number
    /**
     * 操作日志内容
     */
    readonly operationContent: string
    /**
     * 补充说明，根据当前状态计算出来的宽限期相关补充说明
     */
    readonly additionalOperationContent: string
}

export interface UserOperationVO {
    operatorInfo: UserBriefVO
    operationTime: number
    operationContent: string
    teamName: string
    teamNameAndWorkspaceName: string
    ip: string
}

export type ContractId = number
export type ProductId = number
export type SettlementId = number
export type InvoiceId = string
export type CertificateId = number
export type TradeOrderId = string
export type PayOrderId = string
export type PayChannelId = number
export type ThirdPayConfigId = number
export type BolePayScene = number
export type BoltThirdPayConfigId = number

export enum BoltThirdType {
    WeChat = 1, // 微信支付
    Alipay = 2, // 支付宝支付
    Bank = 17, // 网银支付
}

export enum ContractStatusType {
    New = 'NEW',
    Effective = 'EFFECTIVE',
    Suspended = 'SUSPENDED',
    Cancel = 'CANCELED',
}

export enum FeeType {
    Subscription = 'SUBSCRIPTION',
    TrueUp = 'TRUE_UP',
}

export enum PayGatewayType {
    Stripe = 'STRIPE', // 海外
    Bolt = 'BOLT', // 国内
}

export enum PayChannelType {
    WeChat = 'wechat',
    Alipay = 'alipay',
    Bank = 'bank',
}

export interface ContractStatusResponseVO {
    /**
     * 合约状态
     */
    readonly status: ContractStatusType
    /**
     * 合约ID
     * @format int64
     */
    readonly contractId: ContractId
}

export interface SettlementStatusResponseVO {
    /**
     * 结算单状态
     */
    readonly status: SettlementStatusType
    /**
     * 结算单ID
     * @format int64
     */
    readonly settlementId: SettlementId
}

export interface ContractCreateResponseVO<T extends AbroadPayInfoVO | NationalPayInfoVO> {
    readonly payInfo: T
    /**
     * 合约ID
     * @format int64
     */
    readonly contractId: ContractId

    readonly customer: ContractCustomerInfo
}

export interface ContractCustomerInfo {
    readonly resourceId: string
    readonly resourceType: ResourceType
}

// 海外支付信息
export interface AbroadPayInfoVO {
    /**
     * 支付网关类型
     */
    readonly payGatewayType: PayGatewayType.Stripe
    /**
     * 是否已支付
     */
    readonly paid: boolean
    /**
     * 支付网关信息
     */
    readonly payGateWayInfo: {
        /**
         * 支付秘钥
         */
        readonly payToken: string
    }
}

// 国内支付信息
export interface NationalPayInfoVO {
    /**
     * 支付网关类型
     */
    readonly payGatewayType: PayGatewayType.Bolt

    readonly payGateWayInfo: {
        /**
         * @deprecated 支付渠道与支付二维码url地址（开关 payment-bank 下线后删除此字段 payChannelType2PayQrCodeUrl)
         */
        payChannelType2PayQrCodeUrl: Record<PayChannelType, string>
        /**
         * 服务端生成支付单 id，对应中台接口中的 tradeOrderId
         */
        readonly outTradeNo: TradeOrderId
        /**
         * 中台侧的支付单 id，对应中台接口的 payOrderId
         */
        readonly boltPayOrderId: PayOrderId

        readonly umsChannelInfos: UmsChannelInfoVO[]
    }
}

export interface UmsChannelInfoVO {
    channelId: PayChannelId
    channelNo: string
    channelName: string
}

export interface ContractCreateNationalRequestVO {
    readonly contractDetail: CreateContractDetailVO
    /**
     * 要升级的teamId，如果合约为专业版，则该列表只能有一个teamId，否则异常
     */
    readonly upgradeTeamIds?: TeamID[]
    /**
     * 组织ID，仅企业版试用付费需要
     */
    readonly orgId?: OrgID
    readonly customer: NationalCustomerVO
}

export interface ContractCreateAbroadRequestVO {
    readonly contractDetail: CreateContractDetailVO
    /**
     * 要升级的teamId，如果合约为专业版，则该列表只能有一个teamId，否则异常
     */
    readonly upgradeTeamIds?: TeamID[]
    /**
     * 组织ID，仅企业版试用付费需要
     */
    readonly orgId?: OrgID
    readonly customer: AbroadCustomerVO
}

export enum ContractPeriod {
    Month = 'MONTH',
    Year = 'YEAR',
}

export enum ContractSpecification {
    Profession = 'GROUP',
    Organization = 'ORG',
}

export interface AbroadCustomerVO {
    /**
     * 客户正式名称
     */
    readonly customerLegalName: string
    /**
     * 客户展示名称
     */
    readonly customerDisplayName: string
    /**
     * 国家
     */
    readonly country?: string
    /**
     * 省份
     */
    readonly state?: string
    /**
     * 城市
     */
    readonly city?: string
    /**
     * 邮编
     */
    readonly postalCode?: string
    /**
     * 地址
     */
    readonly address?: string
    /**
     * 门牌号
     */
    readonly apt?: string

    /**
     * 用户名
     */
    readonly fullName?: string
}

export interface NationalCustomerVO {
    /**
     * 客户正式名称
     */
    readonly customerLegalName: string
    /**
     * 客户展示名称
     */
    readonly customerDisplayName: string
}
export interface CreateContractDetailVO {
    /**
     * 研发席位数
     * @format int32
     */
    readonly devSeatCount: number
    /**
     * 设计席位数
     * @format int32
     */
    readonly designSeatCount: number
    /**
     * 付费周期
     */
    readonly period: ContractPeriod
    /**
     * 合约规格
     */
    readonly specification: ContractSpecification
    /**
     * 本次付费周期数，仅国内版有效，海外版默认为1，不为 1 则被视为非法请求
     * @format int32
     */
    readonly initialPeriod: number
}

export interface ContractSettlementsVO {
    /**
     * 合约ID
     * @format int64
     */
    readonly contractId: number
    /**
     * 账单列表
     */
    readonly settlements: SettlementVO[]
    /**
     * 下次扣款日期时间戳
     * @format int64
     */
    readonly nextDeductionDateMillis: number
    /**
     * 合约规格
     */
    readonly specification: ContractSpecification
    /**
     * 合约周期类型
     */
    readonly period: ContractPeriod
    /**
     * 下次续费日期时间戳
     * @format int64
     */
    readonly nextRenewDateMillis: number
    readonly seatQuantityDiffInfo: SeatQuantityDiffInfoVO
}

export interface CustomerContactorVO {
    readonly email: string
}

export interface SeatQuantityDiffInfoVO {
    readonly developerSeatQuantityInfo: SeatQuantityInfoVO
    readonly designerSeatQuantityInfo: SeatQuantityInfoVO
}

export interface SeatQuantityInfoVO {
    /**
     * 已经开始计费的席位数
     * @format int32
     */
    readonly paidQuantity: number
    /**
     * 将于近7天结束宽限期并开始计费席位数
     * @format int32
     */
    readonly autoUpgradeQuantity: number
    /**
     * 之前已付费席位数
     * @format int32
     */
    readonly previousPaidQuantity?: number

    /**
     * 实际付费席位数
     * @format int32
     */
    readonly actualPaidQuantity?: number
    /**
     * 当前已使用席位数
     * @format int32
     */
    readonly currentUsedQuantity?: number
}

export enum SettlementStatusType {
    Upcoming = 'UPCOMING', // 未出账
    NotPaid = 'NEW', // 未支付
    Paid = 'PAID', // 已支付
    Credited = 'CREDITED', // 积分已抵扣
    PayFailed = 'PAY_FAILED', // 支付失败
    PaidInPrivate = 'PAID_IN_PRIVATE', // 已线下支付
    Present = 'PRESENT', // 赠送
    NoChanges = 'NO_CHANGE', // 无席位变更 & 无费用变更
    NoNeedPay = 'NO_NEED_PAY', // 积分结余 / 有席位变更但无费用变更
}

export enum SettlementResultType {
    Reclaim = 'RECLAIM', // 需补款
    Refund = 'REFUND', // 需退款
    NoChanges = 'NO_CHANGE', // 无补款、无退款
}

export enum SettlementFeeType {
    YearlySubscription = 'YEARLY_SUBSCRIPTION',
    MonthlySubscription = 'MONTHLY_SUBSCRIPTION',
    TrueUp = 'TRUE_UP',
}

export interface SettlementVO {
    /**
     * 结算单ID
     * @format int64
     */
    readonly settlementId: SettlementId
    /**
     * 所属账期时间戳
     * @format int64
     */
    readonly billingCycleDay: number
    /**
     * 费用类别
     */
    readonly certificateDisplayType: FeeType
    /**
     * 总计费用
     */
    readonly fee: ExpenseVO
    /**
     * 结算状态
     */
    readonly status: SettlementStatusType
    /**
     * 结算结果，需补款 or 需退款
     */
    readonly result: SettlementResultType
    /**
     * 发票地址，仅海外有
     */
    readonly invoiceUrl?: string
    /**
     * 发票pdf地址，仅海外有
     */
    readonly invoicePdfUrl?: string
    /**
     * 发票号，仅海外有
     */
    readonly invoiceId?: InvoiceId
    /**
     * 支付时间戳，仅结算状态为已支付时才有
     * @format int64
     */
    readonly paidTimeMillis: number
    /**
     * 结算单关联的费用明细
     */
    readonly certificates: CertificateVO[]
    /**
     * 税费
     */
    readonly tax: ExpenseVO
    /**
     * 实付，未支付账单为空
     */
    readonly cashPayment?: {
        fee: ExpenseVO
    }
    /**
     * 积分抵扣，未支付账单为空
     */
    readonly creditPayment?: {
        fee: ExpenseVO
    }
    /**
     * 结算单是否已经超过预计支付时间
     */
    readonly payExpired: boolean
    /**
     * 逾期支付宽限期天数
     * @format int32
     */
    readonly gracePeriodDays: number
    /**
     * 是否逾期，即未支付超过宽限期天数
     */
    readonly exceedGraceDays: boolean
    /**
     * 账单费用类别
     */
    readonly settlementFeeType: SettlementFeeType
}

export enum PayAccountType {
    Cash = 'CASH',
    CreditPoint = 'CREDIT_POINT',
}

export interface SettlementPaymentsVO {
    readonly payAccountType: PayAccountType
    readonly fee: ExpenseVO
}

export interface ExpenseVO {
    /**
     * 金额,单位分
     * @format int32
     */
    readonly valueInCent: number
    /**
     * 币种
     */
    readonly currency: CurrencyType
}

export enum CurrencyType {
    Usd = 'USD', // 美元
    Cny = 'CNY', // 人民币
    CreditPoint = 'CREDIT_POINT', // 积分
}

export interface ProductVO {
    /**
     * 席位类型
     */
    readonly seatType: 'DEV' | 'DESIGN'
    /**
     * 产品ID
     * @format int64
     */
    readonly productId: ProductId
    /**
     * 产品规格，企业版or专业版
     */
    readonly specification: ContractSpecification
    /**
     * 费用列表
     */
    readonly feeUnits: FeeUnitVO[]
}

export interface FeeUnitVO {
    /**
     * 产品ID
     * @format int64
     */
    readonly productId: ProductId
    /**
     * 费用描述
     */
    readonly desc: string
    /**
     * 计费单元ID
     * @format int64
     */
    readonly feeUnitId: number
    readonly price: Expense
    /**
     * 计费周期
     */
    readonly period: ContractPeriod
}

export interface Expense {
    /**
     * 金额，单位分
     * @format int64
     */
    readonly valueInCent: number
    readonly currency: CurrencyType
}

export enum CertificateType {
    Order = 'ORDER', // 订单
    Refund = 'REFUND', // 退费
}

export interface CertificateVO {
    readonly certificateId: CertificateId
    /**
     * 费用类型
     */
    readonly certificateFeeType: FeeType
    /**
     * 费用内容类型
     */
    readonly certificateType: CertificateType
    /**
     * 费用明细
     */
    readonly certificateItems: CertificateItemVO[]
    readonly expense: ExpenseVO
    /**
     * 服务起始时间
     * @format int64
     */
    readonly fulfillStartTimeMillis: number
    /**
     * 服务截止时间
     * @format int64
     */
    readonly fulfillEndTimeMillis: number
}

export interface CertificateItemVO {
    readonly feeUnit: PlainFeeUnitVO
    /**
     * 数量
     * @format int32
     */
    readonly quantity: number
    /**
     * 新数量
     * @format int32
     */
    readonly newQuantity?: number
    /**
     * 旧数量
     * @format int32
     */
    readonly previousQuantity?: number
}

export enum FulfillmentType {
    DevSeat = 'DEV_SEAT',
    DesignSeat = 'DESIGN_SEAT',
}
export interface PlainFeeUnitVO {
    /**
     * 产品规格
     */
    readonly specification: ContractSpecification
    /**
     * 费用描述
     */
    readonly desc: string
    /**
     * 计费单元ID
     * @format int64
     */
    readonly feeUnitId: number
    readonly price: Expense
    /**
     * 计费周期
     */
    readonly period: ContractPeriod
    /**
     * 履约类型
     */
    readonly fulfillmentType: FulfillmentType
}

export interface ContractVO {
    /**
     * 合约ID
     * @format int64
     */
    readonly contractId: ContractId
    /**
     * 合约规格
     */
    readonly specification: ContractSpecification
    /**
     * 付费周期
     */
    readonly period: ContractPeriod
    /**
     * 合约下次续费时间/履约到期时间
     */
    readonly expireTimeMills?: number
    /**
     * 合约状态
     */
    readonly status: 'NEW' | 'EFFECTIVE' | 'CANCELED' | 'TERMINATED'
    /**
     * 是否预约了终止合约
     */
    readonly terminationAppointed: boolean
}

export enum CustomerType {
    Org = 'ORG',
    Group = 'GROUP',
}

export interface ContractSettlementDetailVO {
    /**
     * 合约ID
     * @format int64
     */
    readonly contractId: ContractId
    readonly settlement: SettlementVO
    readonly customer: (NationalCustomerVO | AbroadCustomerVO) & { customerType: CustomerType }
    readonly contactor: CustomerContactorVO
}

// 本次提交的预支付请求的所有三方支付方式的预支付参数
export interface BoltPrePayResponseVO<T extends WeChatOrAliPayPrePayParams | BankPayPrePayParams> {
    /**
     * 返回code，0代表成功，1代表失败
     */
    readonly code: number
    /**
     * 返回code的信息
     */
    readonly message: string
    /**
     * 三方支付配置Id和所有三方支付方式的预支付参数的映射, iap支付返回无意义忽略
     */
    readonly thirdPayConfigId2PrePayParams: Record<ThirdPayConfigId, T>
}

export interface WeChatOrAliPayPrePayParams {
    // 二维码地址
    qrcode_url: string
}

export interface BankPayPrePayParams {
    url: string
}

// 支付请求参数，支持代币支付和三方预支付，可以单独支付，也可以组合支付
export interface BoltPrePayRequestVO {
    /**
     * 交易单id
     */
    readonly tradeOrderId: TradeOrderId
    /**
     * 支付单id
     */
    readonly payOrderId: PayOrderId
    /**
     * 三方支付configId及金额，只能使用人民币，单位为元。使用三方支付时必传。根据中台金额精度的统一规定，强制转为小数点后两位
     */
    readonly thirdPayConfigId2Amount: Record<ThirdPayConfigId, number>
    /**
     * 三方支付的请求参数，使用三方支付时必传，与thirdPayConfigId2Amount强对应
     */
    readonly thirdPayConfigId2ThirdPaymentVO: Record<ThirdPayConfigId, BoltThirdPaymentVO>
    /**
     * 不需要登录时发起支付请求，需要显示传递用户id
     * @format int64
     */
    readonly userId: UserID
}

// 三方支付请求参数
export interface BoltThirdPaymentVO {
    /**
     * 过期时间
     * @format int64
     */
    readonly expiredTime: number
    /**
     * 支付场景
     */
    readonly payScene: BolePayScene
    /**
     * 订单标题, 客户端可以自由传售卖商品相关的名称
     */
    readonly subject: string
    /**
     * 企业网银支付渠道id
     */
    readonly umsBusinessNetPayChnlId?: number
}

// 支付渠道配置信息
export interface BoltPayChannelConfigVO {
    /**
     * 可用于支付的三方支付信息列表，每种三方支付类型只提供唯一可用的thirdPayConfigId
     */
    readonly thirdTypeId2ThirdPayChannelVO: Record<BoltThirdType, BoltThirdPayChannelVO>
}

export interface BoltThirdPayChannelVO {
    /**
     * 三方支付配置id
     */
    readonly thirdPayConfigId: BoltThirdPayConfigId
    /**
     * 三方支付类型 id
     */
    readonly thirdTypeId: BoltThirdType
    /**
     * 支付场景
     */
    readonly payScene: BolePayScene
}

export interface ContractTerminationAppointmentResultVO {
    /**
     * 合约ID
     * @format int64
     */
    readonly contractId: ContractId
    /**
     * 是否有终止预约
     */
    readonly hasTerminationAppointment: boolean
}

export type AssetMigrationMode = 'DEFAULT' | 'FIGMA_MIGRATION'

export enum PayPlanType {
    starter = 'starter', // 基础版
    professional = 'professional', // 专业版
    organization = 'organization', // 企业版
    enterprise = 'enterprise', // 高级企业版
}

export interface PlanAndUserStatesInfo {
    org: PlanAndUserStateVO | undefined
    teams: PlanAndUserStateVO[]
    doc: PlanAndUserStateVO | undefined
    docIsDraft: boolean
}

/**
 * userSeatUpgradeType: 存在三个值
 * - NeedApprove(表示用户的当前席位状态需要管理员审批)、
 * - AutoUpgrade(表示用户的当前席位状态是通过自动升级获得的)、
 * - Unknown(表示用户的当前席位状态是管理员操作的,包括 升级降级、同意拒绝审批)
 *
 * seatUpgradeType: 存在两个值：
 * - NeedApprove(表示当前企业/团队配置了升级需要审批)
 * - AutoUpgrade（表示当前企业/团队配置了自动升级)
 *
 * gracePeriod:
 * - 判断用户是否能够申请宽限期。当用户申请了宽限期后，此值为 false
 * */

export interface PlanAndUserStateVO {
    /**
     * 资源类型
     */
    readonly resourceType: ResourceType
    /**
     * 资源id
     */
    readonly resourceId: string
    /**
     * 收费方案类型
     */
    readonly planType: PayPlanType
    /**
     * 收费方案到期时间
     * @format int64
     */
    readonly planExpireTime: number
    /**
     * 履约是否已锁定，值为true时表示这个履约单位下所有资源只读
     */
    readonly planFreeze: boolean
    /**
     * 值为true表示当前企业或者团队是试用状态（即企业或者团队没有合约），相当于当前是当前planType是专业版或者企业版
     */
    readonly trial: boolean
    /**
     * 试用到期时间，trial=true时有效，否则值为0
     * @format int64
     */
    readonly trialEndTime: number
    /**
     * 企业或者团队的席位升级方式配置
     */
    readonly seatUpgradeType: SeatUpgradeType // 只存在两个值： NeedApprove、AutoUpgrade
    /**
     * 用户当前在这个履约单位下是否有宽限期
     */
    readonly gracePeriod: boolean // 判断用户是否能够申请宽限期。当用户申请了宽限期后，此值为 false
    /**
     * 用户当前生效的席位类型
     */
    readonly userSeatType: SeatType
    /**
     * 席位宽限期到期时间（单位：毫秒），值大于0时表示当前席位是在宽限期的申请，否则值为-1
     * @format int64
     */
    readonly userSeatGracePeriodEndTime: number
    /**
     * 席位升级方式，仅当席位在宽限期内时有效，是用户在创建申请时固化的企业或者团队配置
     */
    readonly userSeatUpgradeType: SeatUpgradeType
    /**
     * 团队是否正在执行套餐变更并且限制用户编辑，仅当查询其他团队下的团队时可能返回true，企业返回false。如果值为true，那么用户进到这个团队时需要弹蒙层提示正在升级
     */
    readonly planUpdating: boolean
    /**
     * 用户在resourceId下的角色
     */
    readonly userRoleInResource: RoleStatus
    /**
     * 用户已申请，但是管理员未审批的席位申请类型，由于同一个席位最多只能申请1次，不同席位可以同时申请，所以这里目前可能会返回0~2个值
     */
    readonly unresolvedApplicationSeatTypes: Array<SeatType>
    /**
     * 合约信息
     */
    readonly contractInfo?: ContractVO
    /**
     * 当前资源使用是否超限，仅当resourceType=team，并且planType=starter时有效
     */
    readonly exceedUsageLimit: boolean
    /**
     * 高级企业版服务端实现后可用
     * 判断这个数组是否为空，从而判断是否在企业中存在工作区管理员的身份。（席位申请）
     */
    readonly adminWorkspaceIds: string[]
}

export interface CanUpgradeTeamVO {
    /**
     * 团队id
     */
    readonly id: TeamID
    /**
     * 名称
     */
    readonly name: string
    /**
     * 图标
     */
    readonly icon: string
    /**
     * 图标背景色
     */
    readonly iconBackgroundColor: string
    /**
     * 当前的付费套餐类型
     */
    readonly planType: PayPlanType
    /**
     * 升级团队时，会自动升级为设计席位的用户id
     */
    readonly designerUserIds: UserID[]
    /**
     * 升级团队时，会自动升级为研发席位的用户id
     */
    readonly developerUserIds: UserID[]
}

export interface CanUpgradeTeamAndUserStateVO {
    /**
     * 用户在其他团队下能升级的团队和团队信息
     */
    readonly teams: CanUpgradeTeamVO[]
    /**
     * 如果值为true，表示当前升级无法完成，原因是用户的邮箱域不在可用范围内（目前只有升级到企业版并且用户邮箱由公共邮箱提供商提供时会返回true）
     */
    readonly userDomainDenied: boolean
}

export interface Page<T> {
    readonly list: ReadonlyArray<T>
    readonly pageInfo: PageInfo
}

export interface PageInfo {
    readonly currentPage: number
    readonly pageSize: number
    readonly totalItem: number
    readonly totalPage: number
}

export interface PageQueryParams {
    page?: number
    pageSize?: number
}

export type FolderID = string

export enum FolderStatus {
    normal, // 正常
    deleted, // 已删除
}

export interface FolderSetVO {
    name: string
    description: string
    teamId: string
}

export interface FolderForSearchVO extends FolderWithDocAndAuthority {
    rank: number
    highlightTexts: HighlightText[]
    shareFolder: boolean
}

export interface FolderSearchGetVO {
    folderForSearchVOList: FolderForSearchVO[]
    folderCount: number
}

export enum SeatUpgradeType {
    Unknown = 'unknown',
    NeedApprove = 'needApprove',
    AutoUpgrade = 'autoUpgrade',
}

export interface UpdateSeatUpgradeTypeRequest {
    /**
     * 资源类型，填企业或者团队
     */
    readonly resourceType: ResourceType
    /**
     * 资源id，填企业id或者团队id
     */
    readonly resourceId: string
    /**
     * 席位升级方式
     */
    readonly seatUpgradeType: SeatUpgradeType
}

export interface SeatApplicationListVO {
    /**
     * 未审批的席位列表
     */
    readonly unresolvedSeatApplications: SeatApplicationVO[]
    /**
     * 在自动升级宽限期内的用户数量
     */
    readonly autoUpgradeMemberCount: number
    /**
     * 审批人中包含自己的席位申请列表
     */
    readonly auditorsIncludeSelfSeatApplications: SeatApplicationVO[]
}

export interface SeatApplicationVO {
    readonly userBrief: UserBriefVO
    /**
     * 申请id
     * @format int64
     */
    readonly applicationId: number
    /**
     * 申请的席位类型
     */
    readonly seatType: SeatType
    /**
     * 申请理由，如果申请时未填写则为空字符串
     */
    readonly reason: string
    /**
     * 申请时间，单位：毫秒
     * @format int64
     */
    readonly applyTime: number
    /**
     * 到期时间，单位：毫秒
     * @format int64
     */
    readonly expireTime: number
    /**
     * 席位升级方式，到期后自动升级还是自动拒绝
     */
    readonly seatUpgradeType: SeatUpgradeType
    /**
     * 审批人列表
     */
    readonly auditors: UserBriefVO[]
    /**
     * 申请的workspace
     */
    readonly workspaceBriefVO: WorkspaceBriefVO
}

export interface UpdateSeatTypeRequest {
    /**
     * 资源类型，填企业或者团队
     */
    readonly resourceType: ResourceType
    /**
     * 资源id，填企业id或者团队id
     */
    readonly resourceId: string
    /**
     * 用户id
     */
    readonly userId: UserID
    /**
     * 席位类型
     */
    readonly seatType: SeatType
}

export interface BatchUpdateSeatTypeRequest {
    /**
     * 资源类型，填企业或者团队
     */
    readonly resourceType: ResourceType
    /**
     * 资源id，填企业id或者团队id
     */
    readonly resourceId: string
    /**
     * 用户id
     */
    readonly userIds: UserID[]
    /**
     * 席位类型
     */
    readonly seatType: SeatType
    /**
     * 工作区id，可选参数，仅planType=ENTERPRISE时生效。如果是企业管理界面，不用传该值；如果是工作区管理员界面，传入对应workspaceId
     */
    readonly workspaceId?: string
}

export interface CreateSeatApplicationRequest {
    /**
     * 资源类型，填企业或者团队
     */
    readonly resourceType: ResourceType
    /**
     * 资源id，填企业id或者团队id
     */
    readonly resourceId: string
    /**
     * 席位类型
     */
    readonly seatType: SeatType
    /**
     * 申请理由，不超过 500 字，可不填
     */
    readonly reason: string
    /**
     * 需要发通知的管理员用户id，如果不填则给所有管理员发通知
     */
    readonly adminUserIds: UserID[]
    /**
     * 触发事件
     */
    readonly triggerScene: TriggerSeatApplicationSceneVO
    /**
     * planType 是高级企业版（PayPlanType.enterprise）时需要
     */
    readonly workspaceId?: string
}

export enum TriggerSeatApplicationSceneType {
    CreateDoc = 'CREATE_DOC',
    ImportDoc = 'IMPORT_DOC',
    MoveDoc = 'MOVE_DOC',
    BatchMoveDoc = 'BATCH_MOVE_DOC',
    EditDoc = 'EDIT_DOC',
    ShareDraftDocEditPermission = 'SHARE_DRAFT_DOC_EDIT_PERMISSION',
    ShareDocEditPublicAccess = 'SHARE_DOC_EDIT_PUBLIC_ACCESS',
    UserDocDevMode = 'USER_DOC_DEV_MODE',
}

export interface TriggerSeatApplicationSceneVO {
    readonly scene: TriggerSeatApplicationSceneType
    readonly folderId?: FolderID
    readonly fromFolderId?: FolderID
    readonly toFolderId?: FolderID
    readonly docId?: DocID
    readonly userId?: UserID
}

export interface SeatApplicationWithResourceBriefVO {
    readonly application: SeatApplicationVO
    /**
     * 资源类型，填企业或者团队
     */
    readonly resourceType: ResourceType
    /**
     * 资源id，填企业id或者团队id
     */
    readonly resourceId: string
    /**
     * 资源名称
     */
    readonly resourceName: string
    /**
     * 当前申请是否已处理，值为true时说明申请已经被通过或者拒绝，值为false时说明申请未被处理，此时可以通过或者拒绝
     */
    readonly resolved: boolean
}

export interface ResolveSeatApplicationRequest {
    /**
     * 申请id
     * @format int64
     */
    readonly applicationId: number
    /**
     * 通过或者拒绝，值为true表示通过，值为false表示拒绝
     */
    readonly approve: boolean
    /**
     * 审批席位类型, 仅approve为true时有效
     */
    seatType?: SeatType
    /**
     * 审批工作区, 仅approve为true、时有效
     */
    workspaceId?: string
}

// 席位类型
export enum SeatType {
    designer = 'designer',
    developer = 'developer',
    viewer = 'viewer',
}
// 席位升级方式配置
export const SeatTypeWeight: Record<SeatType, number> = {
    [SeatType.viewer]: 0,
    [SeatType.developer]: 1,
    [SeatType.designer]: 2,
}

// 比较席位类型的大小，正数，前者比后者类型大
export const compareSeatType = (seat1: SeatType, seat2: SeatType) => {
    return SeatTypeWeight[seat1] - SeatTypeWeight[seat2]
}

export type UserID = number
export interface WorkspaceBriefVO {
    id: string
    name: string
    icon: string
}
export interface UserBriefVO {
    id: UserID
    desensitizedPhone: string
    email: string
    nickname: string
    avatarId: string
    avatarBackgroundColor: string
    orgMember?: boolean
    waitRegister?: boolean
    seatType?: SeatType
    workspaceId?: string
}

export interface UserPreferenceInner {
    global: string
    [key: string]: string
}
export interface UserPreference {
    docId2Preference: UserPreferenceInner
}

export interface GetOrgMemberByEmailResVO extends UserBriefVO {
    orgMember: boolean
}

export type Datetime = number

export type DocID = string
export type OrgID = string

export enum DocStatus {
    NORMAL = 'normal',
    TRASHED = 'trashed',
    DELETED = 'deleted',
}

export interface DocGetVO extends Readonly<DocSetVO> {
    readonly id: DocID
    readonly status: DocStatus
    readonly lastEditedTime: Datetime
    readonly createdTime: Datetime
    readonly createUserId?: UserID
    readonly deletedTime?: Datetime
    readonly deleteUserId: UserID | null
    readonly deleteUser: UserBriefVO | null
    readonly folderName?: string
    readonly thumbnailUrl?: string
    readonly role: RoleStatus
    readonly teamId?: TeamID
    readonly teamName?: string
    readonly draft?: boolean
    readonly favorites?: boolean
    readonly folderId: string
    readonly copyable?: boolean
    readonly lastMovedTime: Datetime // 文件移动时间
    readonly folderRole: RoleStatus // 文档所在项目角色
    readonly folderStatus?: FolderStatus
    readonly teamIcon?: string
    readonly libraryDocument?: boolean
    canvasBackgroundColor?: string
    thumbnailNodeId?: string
    orgId: OrgID
    workspaceId: string
    workspaceName: string
}

// DocumentWithAuthorityVO 文档信息，包含当前登录用户的权限
export interface DocWithAuthorityVO extends DocGetVO {
    /**
     * 用户在该文档下通过文档的公开访问配置获得的临时角色。
     * 判断用户对文档的协同权限时，role 和 temporaryRole，取两者中更高的角色
     */
    temporaryRole: RoleStatus
    seatUpgradeType: SeatUpgradeType
    seatType: SeatType
}

// 缩略图信息
export interface ThumbnailInfoVO {
    readonly width?: number
    readonly height?: number
}

// SetVO 里的所有字段值都需要提供，否则丢失的字段会被重置为空
export interface DocSetVO {
    name: string
    description?: string
}

export interface DocQueryParams extends PageQueryParams {
    id?: DocID
    nameKeyword?: string
    status?: DocStatus
    folderId?: FolderID
    createdUserId?: UserID
    deleteUserId?: UserID
}
export interface DocThumbnailCosAuthorizationVO {
    resourceId: string
    resourceUrl: string
    url: string
    contentType: string
    method: string
    updateThumbnailResourceId: boolean //封面资源id是否需要更新
}

export interface DocSearchGetVO {
    docId: DocID
    docName: string
    folderId: FolderID
    folderName: string
    modifyTime: Datetime
}

export interface HighlightText {
    highlight: boolean
    text: string
}

export interface DocWithAuthorityForSearchVO extends DocWithAuthorityVO {
    rankByName: number
    rankByContent: number
    rank: number
    ownerInfo?: UserBriefVO
    recentAccess: boolean
    highlightTexts: HighlightText[]
    visitedTime: Datetime
    visitedTimeByDoc: Datetime
    editTimeByUser: Datetime
}

export interface DocumentSearchGetVO {
    documentWithAuthorityForSearchVOList: DocWithAuthorityForSearchVO[]
    documentCount: number
}

export interface DocPrototypeVO {
    id: string
    pageId: string
    pageName: string
    docId: string
    isFavorite: boolean
    thumbnailUrl: string
    thumbnailInfo?: { width: number; height: number }
    canvasBackgroundColor: string
    createdTime: number
    document: DocWithAuthorityVO
}

export interface DocPageCountVO {
    [key: DocID]: number
}

export enum LoginPolicy {
    AllowAll = 'allow_all',
    OnlyEmailSso = 'only_email_sso',
    OnlyEmailGoogle = 'only_email_google',
}
export interface OrganizationVO {
    id: OrgID
    name: string
    icon: string
    role: RoleStatus
    iconBackgroundColor: string
    draftFolderId: FolderID
    guest?: boolean
    createTime: number
    seatUpgradeType?: SeatUpgradeType // 当 id == '-1' 时是 undefined
    seatType: SeatType
    publicLinkSharing: boolean
    /**
     * 组织对应的组织域
     */
    readonly domainList: string[]
    samlEnabled: boolean // 是否启用了 sso
    showOrgAccessToken: boolean // 是否显示企业访问令牌
    loginPolicy: LoginPolicy
    /**
     * organization 普通组织，enterprise 高级企业版
     */
    planType: PayPlanType.organization | PayPlanType.enterprise
    importMode?: AssetMigrationMode // 组件迁移方式 // TODO(lizhaohui): 待后端完全上线测试环境和正式环境后，再修改成必有字段
}

export interface OrganizationMemberVO {
    userBrief: UserBriefVO
    role: RoleStatus
    teamCount: number
    lastActiveTime: number
    lastEditTime: number
    guest?: boolean
}
export interface TeamBriefVOForAssignWorkspace {
    id: TeamID
    name: string
    icon: string
    iconBackgroundColor: string
    owner: UserBriefVO | null
}
export interface TeamBriefVO {
    id: TeamID
    name: string
    description: string
    orgId: string
    iconBackgroundColor: string
    icon: string
    publicStatus: PublicStatus
    owner?: UserBriefVO
    workspaceBrief: WorkspaceBriefVO | null
}
export interface OrganizationTeamVO {
    teamBrief: TeamBriefVO
    memberCount: number
    folderCount: number
    createdTime: number
    role: RoleStatus
    userRoleId: number
}

export interface OrganizationOperationQueryParams {
    userIds?: UserID[]
    events?: number[]
    teamIds?: TeamID[]
    startTime?: number
    endTime?: number
    pageSize?: number
    page?: number
}

export interface OrganizationOperationQueryParamsV2 {
    userEmails?: string[]
    events?: number[]
    teamIds?: TeamID[]
    startTime?: number
    endTime?: number
    pageSize?: number
    page?: number
    workspaceIds?: string[]
}

// 活动日志-席位申请与审批的筛选 id
export const SeatApplicationHistoryEventId = 32
export interface OrgEventVO {
    eventId: number
    eventName: string
    eventType: string
}

export interface OrganizationSeatVO {
    /**
     * 成员id或者邀请id，用于修改用户成员角色或者邀请角色时的接口入参
     * @format int64
     */
    readonly id: UserRoleId
    readonly userBrief: UserBriefVO
    /**
     * 成员角色
     */
    readonly role: RoleStatus
    /**
     * 席位类型
     */
    readonly seatType: SeatType
    /**
     * 用户是否为访客，用于展示
     */
    readonly guest: boolean
    /**
     * 最近活动时间
     * @format int64
     */
    readonly lastActiveTime: number
    /**
     * 最近编辑时间
     * @format int64
     */
    readonly lastEditTime: number
    /**
     * 团队数量
     * @format int32
     */
    readonly teamCount: number
    /**
     * 是否上次核对后新增
     */
    readonly newlyAdded: boolean
    /**
     * 席位宽限期到期时间（单位：毫秒），值大于0时表示当前席位是在宽限期的申请，否则值为-1
     * @format int64
     */
    readonly gracePeriodEndTime: number
    /**
     * 席位升级方式，仅当席位在宽限期内时有效
     */
    readonly seatUpgradeType: SeatUpgradeType
    /**
     * 是否宽限期内的付费席位，值为true时表示这个席位在宽限期内，是研发或者设计席位，席位升级配置是自动升级，到期后会开始计费
     */
    readonly inGracePeriodPaidSeat: boolean
    readonly lastUserOperationVO?: LastUserOperationVO
    readonly workspaceBrief: WorkspaceBriefVO | null
    readonly accountTypes: OrgAccountType[]
}

export interface OrganizationSummaryVO {
    /**
     * 团队数量
     * @format int32
     */
    readonly teamCount: number
    /**
     * 企业成员数量
     * @format int32
     */
    readonly memberCount: number
    /**
     * 访客数量
     * @format int32
     */
    readonly guestCount: number
    /**
     * 发布库数量
     * @format int32
     */
    readonly publishedLibraryCount: number
    /**
     * 工作区数量
     * @format int32
     */
    readonly workspaceCount?: number | null
}

export interface OrganizationSamlSSOVO {
    samlEnabled: boolean //企业是否开启了saml sso
    idpIssuer: string //idp身份标识，仅samlEnabled = true时有值
    idpSSOUrl: string //idp接收sso认证请求的地址，仅samlEnabled = true时有值
    idpPublicCertificateFileName: string //idp证书文件名，仅samlEnabled = true时有值
}

export interface OrgAccessTokenVO {
    id: number
    name: string
    expireTime: number
    lastUsedTime: number
    createTime: number
    creator: UserProfileVO
    nonExpire: boolean // 是否永不过期
    neverUsed: boolean // 是否未使用过
}

export interface GenerateOrgAccessTokenVO {
    readonly accessToken: string
}

export interface UpgradeOrganizationVO {
    /**
     * 会自动升级为设计席位的用户数量
     */
    readonly designerUserCount: number

    /**
     * 会自动升级为研发席位的用户数量
     */
    readonly developerUserCount: number
}

export type TeamID = string
export type OssUrl = string
export type TeamMamangePublicStatus = PublicStatus | 'all'
export type UserRoleId = number
export interface JoinApplicationWithTeamBriefVO {
    application: JoinApplicationVO
    team: TeamBriefVO
    resolved: boolean
}

export interface TransferOwnerVO {
    toUserId: number
    resourceType: ResourceType
    resourceId: string
}

export interface UserMemberVO {
    id: UserRoleId
    userId: number
    userBrief: UserBriefVO
    role: RoleStatus
    actualRole: RoleStatus
    // true-待接受邀请，false-已经是成员
    // pending = true 时，id表示邀请id
    // pending = false 时，id表示成员角色id
    pending: boolean
    guest?: boolean
    /**
     * 用户当前生效的席位类型
     */
    seatType: SeatType
    /**
     * 是否在宽限期
     */
    inNeedApproveGracePeriod: boolean
}

export interface JoinApplicationVO {
    userBrief: UserBriefVO
    applicationId: number
    role: RoleStatus
    reason?: string
}
export interface ResourceMemberVO {
    members: UserMemberVO[]
    applications?: JoinApplicationVO[]
    parentMembers: UserMemberVO[]
    parentName: string //【v3/members】可以根据parentName值是否为null判断用户是否有上层资源查看权限
    parentIcon: string
    parentIconBackgroundColor: string
    role: RoleStatus // 当前登录用户在当前资源下的角色，包含邀请和继承
    temporaryRole: RoleStatus // 当前登录用户在当前资源下的临时角色，即公开访问角色。team和folder的temporary一定是none，因为这两层不支持公开访问。当temporaryRole!=none并且role==none时，说明用户只有公开访问权限，此时接口不返回成员列表（members和parentMembers是空list）
    draftAndOwnerShareEditLimited: boolean
    hideTeamMember?: boolean //【v3/members】仅当查询文档成员时有效，表示用户对团队成员有查看权限，如果对团队成员无查看权限并且对项目成员有查看权限，则需要在上层资源的成员列表中展示提示信息，此时返回值中的parentMembers会自动过滤掉是团队成员且不是项目成员的用户
}
export interface InvitationVO {
    id: number
    userId: number
    resourceType: ResourceType
    resourceId: string
    role: RoleStatus
}

export interface InvitationValidateInfoVO {
    doc: DocWithAuthorityVO | undefined
    planInfo: PlanAndUserStateVO | undefined
    draftAndOwnerShareEditLimited: boolean | undefined
    orgDraftFolderOwner: {
        userBriefVO: UserBriefVO
        seatType: SeatType
    } | null
    email2InviteeInfo:
        | {
              [key: string]: UserBriefVO
          }
        | undefined
}

export enum ResourceType {
    Organization = 'organization',
    Team = 'team',
    Folder = 'folder',
    Document = 'document',
    Workspace = 'workspace',
}
export enum RoleStatus {
    None = 'none',
    Viewer = 'viewer',
    Editor = 'editor',
    Admin = 'admin',
    Owner = 'owner',
    ViewPrototype = 'view_prototype',
}

export interface TeamInOrgVO {
    id: string
    orgId: string
    name: string
    icon: string
    status: 'NORMAL' | 'DELETED'
    iconBackgroundColor: string
    createdTime: number
    updatedTime: number
    role: RoleStatus
    hasApplication: boolean
    publicStatus: PublicStatus
    memberCount: number
    workspaceName: string
    workspaceId: string
}

export interface TeamWithAuthorityVO {
    id: string
    orgId: string
    name: string
    description: string
    icon: string
    iconBackgroundColor: string
    status: 'NORMAL' | 'DELETED'
    createdTime: number
    updatedTime: number
    role: RoleStatus
    seatType: SeatType
    hasApplication: boolean
    publicStatus: PublicStatus
    hasOwner: boolean
    needHandover: boolean
    seatUpgradeType: SeatUpgradeType
    workspaceId: string
    workspaceName: string
}

export interface TeamWithAuthorityForSearchVO extends TeamWithAuthorityVO {
    /**
     * 根据团队标题得分的排名
     * @format int32
     */
    readonly rank: number
    /**
     * 高亮文本
     */
    readonly highlightTexts: HighlightText[]
    /**
     * 团队成员数量
     * @format int32
     */
    readonly memberCount: number
}

export interface TeamWithFolderAndAuthorityVO {
    teamWithAuthorityVO: TeamWithAuthorityVO
    folderWithAuthorityVOList: FolderWithAuthorityVO[]
}

export interface TeamSearchGetVO {
    teamForSearchVOList: TeamWithAuthorityForSearchVO[]
    teamCount: number
}

export interface TeamPermissionDetailVO {
    /**
     * 项目或者文件名称
     */
    readonly name: string
}

export interface TeamPermissionSummaryVO {
    /**
     * 可编辑项目明细
     */
    readonly canEditFolders: TeamPermissionDetailVO[]
    /**
     * 可查看项目数量
     * @format int32
     */
    readonly canViewFolderCount: number
    /**
     * 可编辑文档明细
     */
    readonly canEditDocs: TeamPermissionDetailVO[]
    /**
     * 可查看文档数量
     * @format int32
     */
    readonly canViewDocCount: number
}

export interface TeamSeatVO {
    /**
     * 成员id或者邀请id，用于修改用户成员角色或者邀请角色时的接口入参
     * @format int64
     */
    readonly id: UserRoleId
    readonly userBrief: UserBriefVO
    /**
     * 成员角色
     */
    readonly role: RoleStatus
    /**
     * 展示席位类型（例如当用户原本席位是查看，申请了一个带宽限期、需要审批的设计席位，那么在宽限期未到期时，这个值是查看席位）
     */
    readonly seatType: SeatType
    /**
     * 最近活动时间
     * @format int64
     */
    readonly lastActiveTime: number
    readonly permissionDetail: TeamPermissionSummaryVO
    /**
     * 是否上次核对后新增
     */
    readonly newlyAdded: boolean
    /**
     * 席位宽限期到期时间（单位：毫秒），值大于0时表示当前席位是在宽限期的申请，否则值为-1
     * @format int64
     */
    readonly gracePeriodEndTime: number
    /**
     * 席位升级方式，仅当席位在宽限期内时有效（gracePeriodEndTime大于0），否则值为UNKNOWN
     */
    readonly seatUpgradeType: SeatUpgradeType
    /**
     * 是否宽限期内的付费席位，值为true时表示这个席位在宽限期内，是研发或者设计席位，席位升级配置是自动升级，到期后会开始计费
     */
    readonly inGracePeriodPaidSeat: boolean
    /**
     * 用户当前生效的席位类型
     */
    readonly actualSeatType: SeatType
    /**
     * 值为true时，表示用户当前生效的席位在宽限期内，并且席位升级类型配置的是需要审批，宽限期到期后会自动降级
     */
    readonly inNeedApproveGracePeriod: boolean
    readonly lastUserOperationVO?: LastUserOperationVO
}

export interface TeamResourceStateVO {
    /**
     * 已使用项目数
     * @format int32
     */
    readonly folderCount: number
    /**
     * 已使用文档数
     * @format int32
     */
    readonly docCount: number
    /**
     * 已使用文档下的各个doc内使用的page数
     */
    readonly folderId2DocId2PageCount: { [folderId: string]: { [docId: string]: number } }
}

export interface TeamSetVO {
    name: string
    description: string
    icon: OssUrl
    publicStatus: PublicStatus
    orgId?: string
    workspaceId?: string
}

// 团队字段更新，支持单独更新某个字段
export interface TeamUpdateVO {
    name?: string
    description?: string
    icon?: OssUrl
    publicStatus?: PublicStatus
}

export interface FolderWithAuthorityVO {
    id: string
    name: string
    description: string
    teamId: string
    teamName: string
    teamNameV2?: string // 团队名称v2 这个会根据是否有团队访问权限来决定是否返回
    orgId: string
    status: FolderStatus
    draftFolderFromDimission?: boolean // 是否为离职继承项目
    basicInfoUpdatedTime: number

    createdTime: number
    updatedTime: number
    favoriteTime: number
    favorites: boolean
    roleEnum: RoleStatus
    teamRoleEnum: RoleStatus
    teamPublicStatus?: PublicStatus
    teamMemberPermission: MemberPermission
    workspaceId: string
    workspaceName: string
    shared?: boolean // 是否为共享项目 改为服务端判断
}

export interface FolderWithDocAndAuthority {
    folderWithAuthorityVO: FolderWithAuthorityVO
    folderUpdateTime: number
    documentWithAuthorityVOList: DocWithAuthorityVO[]
}
export interface AssetHandoverVO {
    userBrief: UserBriefVO
    folderCount: number
    documentCount: number
}
export interface TeamBriefForRestore {
    id: string
    name: string
    orgId: string
    status: 'NORMAL' | 'DELETED'
    icon: string
    restorePeriodDays: number
    withinRestorePeriod: boolean
}

export interface FolderIdWithDocVO {
    folderId: string
    folderUpdateTime: number
    documentWithAuthorityVOList: DocWithAuthorityVO[]
}

export enum PublicStatus {
    NONE = 'NONE',
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    PRIVATE = 'PRIVATE',
}

export enum OrgAccountType {
    ORG_ADMIN = 'ORG_ADMIN',
    WORKSPACE_ADMIN = 'WORKSPACE_ADMIN',
    ORG_MEMBER = 'ORG_MEMBER',
    ORG_GUEST = 'ORG_GUEST',
}

export interface WorkspaceCountVO {
    id: string
    name: string
    icon: string
    count: number
}

export interface QueryOrganizationSeatsParams {
    queryString?: string // 成员姓名或邮箱，不传时表示不带过滤条件
    accountTypes?: OrgAccountType[] // 账号类型，不传时表示不带过滤条件(全部)
    workspaceIds?: string[] // 工作区列表，不传时代表不带过滤条件(全部)
    seatTypes?: SeatType[] // 席位类型，不传时表示不带过滤条件
    hasNewPaidSeat?: boolean // 上次核对后新的付费席位，不传时表示不带过滤条件。只能不传或者传true
    gracePeriodPaidSeat?: boolean // 宽限期中的付费席位，不传时表示不带过滤条件。只能不传或者传true
}

export interface QueryOrganizationSeatsResponse {
    seats: OrganizationSeatVO[]
    accountType2Count: Record<OrgAccountType, number>
    seatType2Count: Record<SeatType, number>
    workspaces: WorkspaceCountVO[]
}

export interface WorkspaceAndTeamListBriefVO {
    workspaceBriefVO: WorkspaceBriefVO
    teamBriefVOList: TeamBriefVO[]
}
