/* eslint-disable no-restricted-imports */
import { GetFolderRequest } from '../../../kernel/request/folder'
import { SpaceNotifyFlow } from './notify-flow'
import { NoneRightFolderStore, SpaceStoreType, getSpaceStore, setSpaceStore } from './types'

export const generateNoneRightFolderState = (set: setSpaceStore, get: getSpaceStore): NoneRightFolderStore => {
    return {
        folder: undefined,
        setNoneRightFolder: async (folder) => {
            set((state) => {
                state.noneRightFolderStore.folder = folder
            })
        },
        clear() {
            set((state) => {
                state.noneRightFolderStore.folder = undefined
            })
        },
        _refresh: () => {
            const folderId = get().noneRightFolderStore.folder?.id
            folderId && new GetFolderRequest(folderId).start().then(get().noneRightFolderStore.setNoneRightFolder)
        },
    }
}

export const syncNoneRightFolder = (store: SpaceStoreType, notifyFlow: SpaceNotifyFlow) => {
    notifyFlow.addRelationChangeCallbackWithoutAccess(() => {
        if (store.getState().noneRightFolderStore.folder != undefined) {
            store.getState().noneRightFolderStore._refresh()
        }
    })
}
