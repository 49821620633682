import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    Mask: '蒙版',
    UseTransparency: '使用透明度蒙版',
} as const

export const enTranslation = {
    Mask: 'Mask',
    UseTransparency: 'Use alpha mask',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
