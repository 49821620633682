import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    Properties: '属性',
    wxGahM: '间距',
    ZoQLgc: '边距',
    Radius: '圆角',
    CornerSmoothing: '平滑圆角',
    Rotation: '角度',
} as const

export const enTranslation = {
    Properties: 'Properties',
    wxGahM: 'Gap',
    ZoQLgc: 'Padding',
    Radius: 'Radius',
    CornerSmoothing: 'Corner smoothing',
    Rotation: 'Rotation',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
