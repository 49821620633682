import { TabElement } from '../../../../../../ui-lib/src'
import classnames from 'classnames'
import React, { HTMLAttributes, createContext, useCallback, useContext } from 'react'
import classes from './icon-group.module.less'
import { ToKeyCode } from '../../../../document/util/keycode'
import { KeyboardReceiver } from '../../../../main/keyboard-receiver/component'

const IconGroupContext = createContext<{ disabled: boolean }>({ disabled: false })

interface IconGroupProps extends HTMLAttributes<HTMLDivElement> {
    disabled?: boolean
    dataTestId?: string
}
function _IconGroup(
    { className, disabled, dataTestId, ...otherProps }: IconGroupProps,
    ref: React.Ref<HTMLDivElement>
) {
    return (
        <div ref={ref} className={classnames(classes.container, [className])} data-testid={dataTestId} {...otherProps}>
            <TabElement disabled={disabled} />
            <div className={classes.childrenContainer}>
                <IconGroupContext.Provider value={{ disabled: !!disabled }}>
                    {otherProps.children}
                </IconGroupContext.Provider>
            </div>
        </div>
    )
}

interface IconGroupItemProps extends HTMLAttributes<HTMLDivElement> {
    selected?: boolean
    icon?: React.ReactNode
    dataTestId?: string
}
function _IconGroupItem(props: IconGroupItemProps, ref: React.Ref<HTMLDivElement>) {
    const { className, selected, icon, dataTestId, onClick, onMouseDown, ...otherProps } = props
    const { disabled } = useContext(IconGroupContext)

    const _onClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            !disabled && onClick?.(e)
        },
        [onClick, disabled]
    )

    const _onMouseDown = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            !disabled && onMouseDown?.(e)
        },
        [onMouseDown, disabled]
    )

    const _onKeydown = useCallback(
        (e: any) => {
            if (e.target.className === 'tabElemnt-iconGroup') {
                _onClick(e)
                return false
            }
            return true
        },
        [_onClick]
    )

    return (
        <div
            ref={ref}
            className={classnames(classes.item, [className], {
                [classes.selected]: selected,
                [classes.disabled]: disabled,
            })}
            onClick={_onClick}
            onMouseDown={_onMouseDown}
            data-testid={dataTestId}
            {...otherProps}
        >
            <KeyboardReceiver keyCode={ToKeyCode.Enter} onKeydown={_onKeydown}>
                <TabElement className="tabElemnt-iconGroup" />
            </KeyboardReceiver>
            {icon ? icon : props.children}
        </div>
    )
}

const IconGroupItemRef = React.forwardRef(_IconGroupItem)
const IconGroupRef = React.forwardRef(_IconGroup)

export const IconGroup = Object.assign(IconGroupRef, { Item: IconGroupItemRef })
