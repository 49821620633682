/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { useViewState } from '../../../../../view-state-bridge'
import { isExportWebP } from '../util'
import ExportFormatType = Wukong.DocumentProto.ExportFormatType

export function useExportSettings() {
    const state = useViewState('exportSettingsState')
    let exportSettings = state?.exportSettingsState ?? []
    const selectLayer = state?.buttonText
    const buttonText = state?.buttonText
    const singleSelectionId = state?.singleSelectionId
    const selectionNodeName = state?.selectionNodeName
    const exportTarget = state?.exportTarget
    const pageName = state?.pageName
    const nodeIds = selectionNodeName ? selectionNodeName.map(({ id }) => id) : []
    const names = selectionNodeName ? selectionNodeName.map(({ name }) => name) : []
    const pageId = state?.pageId

    if (!isExportWebP()) {
        exportSettings = exportSettings?.filter((setting) => setting.format != ExportFormatType.EXPORT_FORMAT_TYPE_WEBP)
    }

    return {
        exportTarget,
        buttonText,
        exportSettings,
        selectLayer,
        singleSelectionId,
        selectionNodeName,
        pageId,
        pageName,
        nodeIds,
        names,
    } as const
}
