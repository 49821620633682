/* eslint-disable no-restricted-imports */
import { useCallback, useRef } from 'react'
import { useEffectOnce } from 'react-use'
import { useAppContext } from '../app-context'
import { UserConfigKey, UserConfigTypeNew } from './user-config'
import { UserConfigService } from './user-config-service'

export function useUserConfigStateV2<K extends UserConfigKey>(
    key: K,
    initValue?: UserConfigTypeNew[K] | ((prevValue: UserConfigTypeNew[K]) => UserConfigTypeNew[K])
): [
    UserConfigTypeNew[K],
    (nextValue: UserConfigTypeNew[K] | ((prevValue: UserConfigTypeNew[K]) => UserConfigTypeNew[K])) => void
] {
    const userConfigService = useAppContext().userConfigService
    return useUserConfigStateV2WithUserConfigService(userConfigService, key, initValue)
}

export function useUserConfigStateV2WithUserConfigService<K extends UserConfigKey>(
    userConfigService: UserConfigService,
    key: K,
    initValue?: UserConfigTypeNew[K] | ((prevValue: UserConfigTypeNew[K]) => UserConfigTypeNew[K])
): [
    UserConfigTypeNew[K],
    (nextValue: UserConfigTypeNew[K] | ((prevValue: UserConfigTypeNew[K]) => UserConfigTypeNew[K])) => void
] {
    const constKey = useRef(key)
    const constInitValue = useRef(initValue)
    const strState = userConfigService.useZustandStore2.use[constKey.current]?.()
    useEffectOnce(() => {
        if (constInitValue.current != null) {
            const _value =
                typeof constInitValue.current === 'function'
                    ? constInitValue.current(userConfigService.useZustandStore2.getState()[constKey.current])
                    : constInitValue.current
            userConfigService.updateConfig2(constKey.current, _value)
        }
    })
    const update = useCallback(
        (nextValue: UserConfigTypeNew[K] | ((prevValue: UserConfigTypeNew[K]) => UserConfigTypeNew[K])) => {
            if (userConfigService) {
                const _value =
                    typeof nextValue === 'function'
                        ? nextValue(userConfigService.useZustandStore2.getState()[constKey.current])
                        : nextValue
                userConfigService?.updateConfig2(constKey.current, _value)
            }
        },
        [userConfigService]
    )
    return [strState!, update]
}
