import { Wukong } from '@wukong/bridge-proto'
import * as $protobuf from 'protobufjs'
import { DeepRequired } from '../../../../util/src'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { MessageContentType } from '../interface/notify'
import { WsCommentMessage } from './notify-message'
import { NotifyService } from './notify-service'

export type CommentProtoMessage = DeepRequired<Wukong.NotifyProto.IComment>

const parseCommentProtoMessageOptions: $protobuf.IConversionOptions = {
    longs: Number, // Long 类型 decode 时返回 number
    defaults: true, // 无值 decode 时返回默认值
}

const parseCommentProtoMessage = (proto: Wukong.NotifyProto.Comment): CommentProtoMessage => {
    const struct = Wukong.NotifyProto.Comment.toObject(proto, parseCommentProtoMessageOptions)
    return struct as CommentProtoMessage
}

const subscribeParam = (docId: string) => {
    return {
        [MessageContentType.Comment]: {
            filters: [
                {
                    filterParameters: [
                        {
                            name: 'docId',
                            value: docId,
                        },
                    ],
                },
            ],
        },
    }
}

export class CommentNotifyService {
    constructor(private readonly notifyService: NotifyService, private readonly docId: string, private orgId: string) {}
    connectWithSignal(
        signal: TraceableAbortSignal,
        // 连接成功时的回调，包括首次连接和每次重连成功
        onConnectCallback: (disconnectedDuration?: number) => void,
        // 收到消息时的回调
        onMessageCallback: (comment: CommentProtoMessage) => void
    ) {
        this.notifyService.onConnectChangeWithSignal(signal, ({ sessionId, disconnectedDuration }) => {
            if (sessionId) {
                this.notifyService.sendSubscribeProto(this.orgId, subscribeParam(this.docId))
                onConnectCallback(disconnectedDuration)
            }
        })

        this.notifyService.onBusinessMessageChangeWithSignal(signal, (proto) => {
            if (proto.businessCode == WsCommentMessage.code) {
                const body = WsCommentMessage.bodyType.decode(proto.payload)
                onMessageCallback(parseCommentProtoMessage(body))
            }
        })

        signal.addEventListener('abort', () => {
            this.notifyService.sendUnSubscribeProto(this.orgId, subscribeParam(this.docId))
        })
    }
}
