import {
    FeatureSwitchConfig,
    SwitchEnvironmentScopeType,
    SwitchStrategyType,
    UserGroupType,
} from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'ai-gen-prototype',
    owner: '',
    description: '',
    strategies: [
        {
            env: SwitchEnvironmentScopeType.TESTING,
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                userList: [
                    '93b2d50b70abd736fe8799361dc94170a0598567',
                    '8924f0f3c2562122c6dce85e95a7e585a82ba76c',
                    '07de847f05c0a1ab68862dd89660254ba39d540c',
                    '6e0d717d148ad555a886889dd29521beb6c4f86c',
                    'a016fbbfa2e137ebffd52005dccbad8d7e6df177',
                    'a276a0a764a2156e196a98d8ab0bd2efa7688881',
                    'c25a7c619bd3d207ef342fb9328434439de70bd6',
                    '848154b842d852a02cd56aae93f06e95617a3f5b',
                    '20dff2aaca522ac44bf55db0acda5212222716a4',
                    'cc448a05f972eaa52d0745e77d4b9fa4a9cafbc5',
                    '57434b1c0afd109373efd66ab0cc5ed1569a4333',
                ],
            },
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
