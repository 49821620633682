import initBasisTranscoder, { IBasisTranscoderExports } from '../third-party/basis_transcoder/tc_trans'
import { TextureCompressionFormat } from '../types'
import { ktx2ToCompressedTexture } from './basis-core'

export class BasisTranscoder {
    private static _inst: BasisTranscoder | null = null
    private _basisTranscoder: IBasisTranscoderExports | null = null
    private _initSignal: Promise<void> | null = null

    private constructor() {}

    public static get(): BasisTranscoder {
        if (BasisTranscoder._inst) {
            return BasisTranscoder._inst
        }
        const inst = (BasisTranscoder._inst = new BasisTranscoder())
        return inst
    }

    public ensureInitialized(wasmDownloadPrefix: string | undefined) {
        if (!this._initSignal) {
            this._initSignal = initBasisTranscoder({
                locateFile: wasmDownloadPrefix ? () => `${wasmDownloadPrefix}/tc_trans.wasm` : undefined,
            }).then((basis) => {
                this._basisTranscoder = basis
            })
        }
        return this._initSignal
    }

    public isInitialized() {
        return Boolean(this._basisTranscoder)
    }

    public transcodeSync(data: Uint8Array, format: TextureCompressionFormat): Uint8Array {
        const transcoder = this.getTranscoderOrThrow()
        return ktx2ToCompressedTexture(transcoder, data, format)
    }

    public async transcode(
        data: Uint8Array,
        format: TextureCompressionFormat,
        wasmDownloadPrefix: string | undefined
    ): Promise<Uint8Array> {
        await this.ensureInitialized(wasmDownloadPrefix)
        return this.transcodeSync(data, format)
    }

    private getTranscoderOrThrow(): IBasisTranscoderExports {
        if (!this._basisTranscoder) {
            throw Error(`Transcoder have not initialized yet`)
        }
        return this._basisTranscoder
    }
}
