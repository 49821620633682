import { useRef } from 'react'
import { DraggablePopupV2 } from '../../../../../../../../ui-lib/src'
import { PopupStateType } from '../../../../../../document/node/node'
import { useComponentProp } from '../component-prop/hook'
import { CreateCompPropValAreaOfBoolean, UpdateCompPropValAreaOfBoolean } from './bool-prop-popup-content'
import styles from './index.module.less'
import { translation } from './index.translation'
import {
    CreateInstanceSwapPropPopupContent,
    InstanceSwapPropPopupContentRef,
    UpdateInstanceSwapPropPopupContent,
} from './instance-swap-prop-popup-content'
import { CreatePublicPropPopupContent } from './public-prop-popup-content'
import { CreateTextPropPopupContent, UpdateTextPropPopupContent } from './text-prop-popup-content'
import { CreateVariantPropPopupContent, UpdateVariantPropPopupContent } from './variant-prop-popup-content'

interface ComponentPropDragPopupProps {
    position: { left: number; top: number } | undefined
}

const getPopupTitle = (popupType: PopupStateType, onClick: () => void) => {
    let title = ''
    switch (popupType) {
        case PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_VARIANT_PROP_OF_PROP_AREA:
        case PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_BOOL_PROP_OF_LAYER_AREA:
        case PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_BOOL_PROP_OF_PROP_AREA:
        case PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_INSTANCE_SWAP_PROP_OF_PROP_AREA:
        case PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_INSTANCE_SWAP_PROP_OF_INSTANCE_AREA:
        case PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_TEXT_PROP_OF_TEXT_AREA:
        case PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_TEXT_PROP_OF_PROP_AREA:
            title = translation('createComponentProp')
            break
        case PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_VARIANT_PROP:
            title = translation('editVariantProp')
            break
        case PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_BOOL_PROP:
            title = translation('editBooleanProp')
            break
        case PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_INSTANCE_SWAP_PROP:
            title = translation('editInstanceSwap')
            break
        case PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_TEXT_PROP:
            title = translation('editTextProp')
            break
        case PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_PUBLIC_PROP_OF_PROP_AREA:
            title = translation('createComponentPublicProp')
            break
        default:
            break
    }

    return title ? (
        <div className={styles.headerTitle} onClick={onClick}>
            {title}
        </div>
    ) : null
}

export function ComponentPropDragPopup(props: ComponentPropDragPopupProps) {
    const { position } = props

    const {
        closeComponentPropPopup,
        popupType,
        popupVal,
        createProp,
        createAndApplyProp,
        updateProp,
        toggleComponentPropPopup,
        selectNodesBySpecificVariantValue,
        hoverNodesBySpecificVariantValue,
        validateInstanceSwapValue,
        publicPropChains,
    } = useComponentProp()

    const instanceSwapContentRef = useRef<InstanceSwapPropPopupContentRef>(null)
    const popupTitle = getPopupTitle(popupType, () => instanceSwapContentRef.current?.closeComponentPicker())

    return (
        <DraggablePopupV2
            visible
            key={popupVal.id}
            onCancel={closeComponentPropPopup}
            positionRightBase
            header={popupTitle}
            styleType="editor"
            position={position}
            footer={null}
            bodyClassName={styles.compPropBody}
            enableScrollBar={true}
        >
            {PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_VARIANT_PROP_OF_PROP_AREA === popupType && (
                <CreateVariantPropPopupContent
                    popupData={popupVal}
                    popupType={popupType}
                    closePopup={closeComponentPropPopup}
                    createProp={createProp}
                    createAndApplyProp={createAndApplyProp}
                />
            )}
            {PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_VARIANT_PROP === popupType && (
                <UpdateVariantPropPopupContent
                    popupData={popupVal}
                    closePopup={closeComponentPropPopup}
                    modifyVariantName={toggleComponentPropPopup}
                    selectNodesBySpecificVariantValue={selectNodesBySpecificVariantValue}
                    hoverNodesBySpecificVariantValue={hoverNodesBySpecificVariantValue}
                />
            )}
            {[
                PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_BOOL_PROP_OF_PROP_AREA,
                PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_BOOL_PROP_OF_LAYER_AREA,
            ].includes(popupType) && (
                <CreateCompPropValAreaOfBoolean
                    createProp={createProp}
                    createAndApplyProp={createAndApplyProp}
                    closePopup={closeComponentPropPopup}
                    popupData={popupVal}
                    popupType={popupType}
                />
            )}
            {PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_BOOL_PROP === popupType && (
                <UpdateCompPropValAreaOfBoolean popupData={popupVal} updateProp={updateProp} />
            )}
            {[
                PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_TEXT_PROP_OF_PROP_AREA,
                PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_TEXT_PROP_OF_TEXT_AREA,
            ].includes(popupType) && (
                <CreateTextPropPopupContent
                    popupData={popupVal}
                    popupType={popupType}
                    closePopup={closeComponentPropPopup}
                    createProp={createProp}
                    createAndApplyProp={createAndApplyProp}
                />
            )}
            {PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_TEXT_PROP === popupType && (
                <UpdateTextPropPopupContent popupData={popupVal} updateProp={updateProp} />
            )}
            {[
                PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_INSTANCE_SWAP_PROP_OF_PROP_AREA,
                PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_INSTANCE_SWAP_PROP_OF_INSTANCE_AREA,
            ].includes(popupType) && (
                <CreateInstanceSwapPropPopupContent
                    ref={instanceSwapContentRef}
                    popupData={popupVal}
                    popupType={popupType}
                    closePopup={closeComponentPropPopup}
                    createProp={createProp}
                    createAndApplyProp={createAndApplyProp}
                    toggleComponentPropPopup={toggleComponentPropPopup}
                    validateInstanceSwapValue={validateInstanceSwapValue}
                />
            )}
            {PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_INSTANCE_SWAP_PROP === popupType && (
                <UpdateInstanceSwapPropPopupContent
                    ref={instanceSwapContentRef}
                    popupData={popupVal}
                    updateProp={updateProp}
                    validateInstanceSwapValue={validateInstanceSwapValue}
                />
            )}
            {[PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_PUBLIC_PROP_OF_PROP_AREA].includes(popupType) && (
                <CreatePublicPropPopupContent publicPropChains={publicPropChains} />
            )}
        </DraggablePopupV2>
    )
}
