export const SelectNodeIcon = () => {
    return (
        <svg width="160" height="96" viewBox="0 0 160 96">
            <path
                transform="matrix(1 0 0 1 0 0)"
                d="M0 0L160 0L160 96L0 96L0 0Z"
                fillRule="nonzero"
                fill="rgb(255, 255, 255)"
            />
            <path
                transform="matrix(1 0 0 1 62 19)"
                d="M-1 -1L37 -1L37 65L-1 65L-1 -1ZM1 1L1 63L35 63L35 1L1 1Z"
                fillRule="nonzero"
                fill="rgb(191, 195, 201)"
            />
            <path
                transform="matrix(1 0 0 1 41 31)"
                d="M0 0L32 0L32 32L0 32L0 0Z"
                fillRule="nonzero"
                fill="rgb(255, 255, 255)"
            />
            <path
                transform="matrix(1 0 0 1 41 31)"
                d="M-0 -0L32 0L32 32L-0 32L0 -0ZM2 2L2 30L30 30L30 2L2 2Z"
                fillRule="nonzero"
                fill="rgb(191, 195, 201)"
            />
            <path
                transform="matrix(1 0 0 1 48 41)"
                d="M1 0C0.447715 0 0 0.447715 0 1L0 1.0015C0 1.55378 0.447715 2 1 2L17 2C17.5523 2 18 1.55378 18 1.0015L18 1C18 0.447715 17.5523 0 17 0L1 0Z"
                fillRule="nonzero"
                fill="rgb(191, 195, 201)"
            />
            <path
                transform="matrix(1 0 0 1 48 51)"
                d="M1 0C0.447715 0 0 0.447715 0 1L0 1.0015C0 1.55378 0.447715 2 1 2L17 2C17.5523 2 18 1.55378 18 1.0015L18 1C18 0.447715 17.5523 0 17 0L1 0Z"
                fillRule="nonzero"
                fill="rgb(191, 195, 201)"
            />
            <path
                transform="matrix(0 1 -1 0 53 38)"
                d="M1 0C0.447715 0 0 0.447715 0 1L0 1.0015C0 1.55378 0.447715 2 1 2L17 2C17.5523 2 18 1.55378 18 1.0015L18 1C18 0.447715 17.5523 0 17 0L1 0Z"
                fillRule="nonzero"
                fill="rgb(191, 195, 201)"
            />
            <path
                transform="matrix(0 1 -1 0 63 38)"
                d="M1 0C0.447715 0 0 0.447715 0 1L0 1.0015C0 1.55378 0.447715 2 1 2L17 2C17.5523 2 18 1.55378 18 1.0015L18 1C18 0.447715 17.5523 0 17 0L1 0Z"
                fillRule="nonzero"
                fill="rgb(191, 195, 201)"
            />
            <path
                transform="matrix(1 0 0 1 59 16)"
                d="M0 0L6 0L6 6L0 6L0 0Z"
                fillRule="nonzero"
                fill="rgb(255, 255, 255)"
            />
            <path
                transform="matrix(1 0 0 1 59 16)"
                d="M-1 -1L7 -1L7 7L-1 7L-1 -1ZM1 1L1 5L5 5L5 1L1 1Z"
                fillRule="nonzero"
                fill="rgb(191, 195, 201)"
            />
            <path
                transform="matrix(1 0 0 1 95 16)"
                d="M0 0L6 0L6 6L0 6L0 0Z"
                fillRule="nonzero"
                fill="rgb(255, 255, 255)"
            />
            <path
                transform="matrix(1 0 0 1 95 16)"
                d="M-1 -1L7 -1L7 7L-1 7L-1 -1ZM1 1L1 5L5 5L5 1L1 1Z"
                fillRule="nonzero"
                fill="rgb(191, 195, 201)"
            />
            <path
                transform="matrix(1 0 0 1 59 80)"
                d="M0 0L6 0L6 6L0 6L0 0Z"
                fillRule="nonzero"
                fill="rgb(255, 255, 255)"
            />
            <path
                transform="matrix(1 0 0 1 59 80)"
                d="M-1 -1L7 -1L7 7L-1 7L-1 -1ZM1 1L1 5L5 5L5 1L1 1Z"
                fillRule="nonzero"
                fill="rgb(191, 195, 201)"
            />
            <path
                transform="matrix(1 0 0 1 95 80)"
                d="M0 0L6 0L6 6L0 6L0 0Z"
                fillRule="nonzero"
                fill="rgb(255, 255, 255)"
            />
            <path
                transform="matrix(1 0 0 1 95 80)"
                d="M-1 -1L7 -1L7 7L-1 7L-1 -1ZM1 1L1 5L5 5L5 1L1 1Z"
                fillRule="nonzero"
                fill="rgb(191, 195, 201)"
            />
            <path
                transform="matrix(0.866025 -0.5 0.5 0.866025 83.8389 60.5011)"
                d="M7.95066 0.349468C7.72588 -0.130084 7.03776 -0.112041 6.83842 0.378631L0.0464593 17.0969C-0.160774 17.607 0.363469 18.1074 0.863383 17.8767L7.12361 14.9874C7.27508 14.9175 7.44864 14.913 7.60353 14.9749L15.04 17.9495C15.5514 18.1541 16.049 17.6268 15.8153 17.1281L7.95066 0.349468Z"
                fillRule="nonzero"
                fill="rgb(191, 195, 201)"
            />
            <path
                transform="matrix(-1 0 0 1 113 4)"
                d="M18.8095 6.00004L18.8095 0L17.1905 0L17.1905 6.00004L18.8095 6.00004ZM8.57241 7.42762L3.57245 2.42764L2.4276 3.57248L7.42757 8.57246L8.57241 7.42762ZM6.00002 17.1905L0 17.1905L0 18.8095L6.00002 18.8095L6.00002 17.1905Z"
                fillRule="evenodd"
                fill="rgb(191, 195, 201)"
            />
        </svg>
    )
}
