import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    EditStyle: '编辑样式',
    DeleteStyle: '删除样式',
} as const

export const enTranslation = {
    EditStyle: 'Edit style',
    DeleteStyle: 'Delete style',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
