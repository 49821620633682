import { command } from 'ccstate'
import sha1 from 'js-sha1'
import { LoaderFunctionArgs } from 'react-router-dom'
import { TraceableAbortSignal } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { transaction } from '../../../../../../util/src/abort-controller/traceable-transaction'
import { traceable } from '../../../../../../util/src/ccstate-helper/traceable'
import { domLocation } from '../../../../../../util/src/dom-env'
import { kanyunRedirect } from '../../../../../../util/src/redirect'
import { showBrowserSupportPopup$ } from '../../../../auth/auth-guard/browser-support-popup'
import { popWhenDetectExpireLogin } from '../../../../auth/expire-login/expire-login-detect'
import { environment } from '../../../../environment'
import { isCypress } from '../../../../kernel/util/ua'
import { tryInitCLog, WkCLog } from '../../../../kernel/clog/wukong/instance'
import { checkDebugEnabled } from '../../../../kernel/debug'
import { WKFrog } from '../../../../kernel/frog/frog-service'
import { UserVOV2 } from '../../../../kernel/interface/account'
import { NetworkAnalysis } from '../../../../kernel/network-analysis'
import { disableSentry } from '../../../../kernel/sentry'
import { featureSwitchManager } from '../../../../kernel/switch/core'
import { wasm$ } from '../../../../main/lifecycle/setup-editor/editor'
import { dynamicSwitchService } from '../../../../main/service/dynamic-switch-service'
import { onboardingService } from '../../../../main/service/onboarding-service'
import { LocalStorageKey } from '../../../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../../../web-storage/local-storage/storage'
import { SessionStorageKey } from '../../../../web-storage/session-storage/config'
import { enhancedSessionStorage } from '../../../../web-storage/session-storage/storage'
import { WK } from '../../../../window/wk-object'
import { tryRedirectToInitial$, trySSORedirect$ } from '../../../atoms/auth-redirect'
import { asyncReLoginIfNearExpiry$ } from '../../../atoms/check-expire-status'
import { injectUserInfoToGA$ } from '../../../atoms/ga'
import { notify$ } from '../../../atoms/notify'
import { cloudFonts$, defaultFonts$, localFonts$ } from '../../../atoms/preload-fonts'
import { imageLibWorker$ } from '../../../atoms/preload-image-lib'
import { wasmBlobUrl$ } from '../../../atoms/preload-wasm'

export const afterAuthentication$ = traceable(
    'hulh01@kanyun.com',
    command(async ({ get, set }, signal: TraceableAbortSignal, args: LoaderFunctionArgs, userInfo: UserVOV2) => {
        const { act } = transaction(signal)

        act('inject userInfo to context and WK', () => {
            WK.userInfo = userInfo
            // Electron 端环境下，将用户信息传递给 native 端
            window.localBridge?.setUserInfo({
                userId: userInfo?.userBrief.id || 0,
                email: userInfo?.userBrief.email || '',
            })

            if (
                !userInfo.userBrief.email.endsWith('@kanyun.com') &&
                !userInfo.userBrief.email.endsWith('@motifftest.com')
            ) {
                WK.detachFeatureSupport()
            }
            return () => {
                delete WK.userInfo
                delete WK.orgId
                delete WK.initialDocSchemaVersion
            }
        })

        act('try sso redirect', () => {
            set(trySSORedirect$, args)
        })

        act('try redirect to initial', () => {
            set(tryRedirectToInitial$, signal, args, userInfo)
        })

        act('show browser support popup', () => {
            const cleanup = set(showBrowserSupportPopup$)

            return () => {
                cleanup?.()
            }
        })

        act('inject UserInfo to React GA', () => {
            const cleanup = set(injectUserInfoToGA$, userInfo)

            return () => {
                cleanup()
            }
        })

        act('inject window __shareWithSandboxInfo', () => {
            window.__shareWithSandboxInfo = {
                ...window.__shareWithSandboxInfo,
                userInfo,
            }

            return () => {
                delete window.__shareWithSandboxInfo.userInfo
            }
        })

        act('init user email to feature switch', () => {
            featureSwitchManager.injectUserEmail(userInfo.userBrief.email)

            return () => {
                featureSwitchManager.clearSwitches()
            }
        })

        act('mark user email in localStorage', () => {
            enhancedLocalStorage.setSerializedItem(LocalStorageKey.LastLoginedUserSha, sha1(userInfo.userBrief.email))

            return () => {
                enhancedLocalStorage.removeItem(LocalStorageKey.LastLoginedUserSha)
            }
        })

        act('try redirect to kanyun', () => {
            const stayOnProd = enhancedLocalStorage.getSerializedItem(LocalStorageKey.StayOnProd) ?? false
            kanyunRedirect(userInfo.userBrief.email, stayOnProd, disableSentry)
        })

        act('preload wasm', () => {
            get(wasmBlobUrl$)
            get(wasm$)
        })

        act('preload fonts', () => {
            get(defaultFonts$)
            get(localFonts$)
            get(cloudFonts$)
        })

        act('preload image lib worker', () => {
            get(imageLibWorker$)
        })

        act('print debug mode', () => {
            checkDebugEnabled()
        })

        act('notify service connect', () => {
            const notifyService = get(notify$)!
            notifyService.setUserId(userInfo.userId)
            notifyService.connect()
            notifyService.startHandleNetworkChanged()

            return () => {
                notifyService.destroy()
                notifyService.stopHandleNetworkChanged()
            }
        })

        act('onboarding init', () => {
            onboardingService.injectOnboardingInfo(userInfo)

            return () => {
                onboardingService.reset()
            }
        })

        act('try relogin', () => {
            //  距登陆态失效10天，刷新重登
            set(asyncReLoginIfNearExpiry$, signal)
        })

        act('init frog', () => {
            WKFrog.updateFrogUserId(userInfo.userId, userInfo.userBrief.email)

            return () => {
                WKFrog.updateFrogUserId(0, '')
            }
        })

        act('enable clog', () => {
            tryInitCLog()
            WkCLog.setUser(userInfo.userId)
        })

        act('add expire-login listeners to document / window', () => {
            //  登陆态失效，visiblityChange，点击出弹窗
            document.addEventListener(
                'visibilitychange',
                async () => {
                    popWhenDetectExpireLogin()
                },
                { signal: signal }
            )
        })

        act('dynamic switch init', () => {
            dynamicSwitchService.init(userInfo)

            return () => {
                dynamicSwitchService.destroy()
            }
        })

        act('init firt path in session ', () => {
            const firstPath = enhancedSessionStorage.getItem(SessionStorageKey.FirstPath)

            if (!firstPath) {
                enhancedSessionStorage.setItem(SessionStorageKey.FirstPath, domLocation().pathname)

                return () => {
                    enhancedSessionStorage.removeItem(SessionStorageKey.FirstPath)
                }
            }
        })

        if (!environment.isDev && !isCypress()) {
            act('metric network analysis', () => {
                new NetworkAnalysis(signal)
            })
        }
    })
)
