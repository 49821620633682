/* eslint-disable no-restricted-imports */
import * as React from 'react'
import { useCallback } from 'react'
import { IconEyes, IconEyesClose } from '../../../../../../ui-lib/src'
import { RGB, SolidPaint } from '../../../../document/node/node'
import { useCommand } from '../../../../main/app-context'
import { usePosition } from '../../../utils/use-position'
import { IconButton } from '../../atom/button/icon-button'
import { SingleGrid } from '../../atom/grid/single-grid'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { ScrubbableInputPercent } from '../../atom/inputs/scrubbable-input-percent'
import { Value } from '../../atom/inputs/utils/type'
import { PaintIconColor } from '../../paint-icon-color/paint-icon-color'
import { ColorSpace } from '../blend/color-picker/utils/color-translate'
import { ColorInteraction } from '../color-interaction/color-interaction'
import { ColorInteractionFrom } from '../color-interaction/type'
import style from './background-solid-paint.module.less'

interface PaintProps {
    className?: string
    paint: SolidPaint
    colorSpace: ColorSpace
    selected?: boolean
    isOpen?: boolean
    onChangeModalVisible: (visible: boolean) => void
    onChange: (paint: SolidPaint, options?: InputOptionsForUndoSquash) => void
}

function _BackgroundSolidPaint(props: PaintProps, outRef: React.Ref<HTMLDivElement>) {
    const { className, paint, colorSpace, selected, isOpen, onChange, onChangeModalVisible } = props
    const { position, reactRef } = usePosition({ isOpen, outRef })
    const command = useCommand()

    const onChangeSolidPaint = useCallback(
        <T extends keyof SolidPaint, V = SolidPaint[T]>(key: T, value: V, options?: InputOptionsForUndoSquash) => {
            if (key == 'visible') {
                onChange({ ...paint, [key]: value }, options)
            } else {
                // NOTE: 修改Paint后自动更新为可见
                onChange({ ...paint, [key]: value, visible: true }, options)
            }
        },
        [onChange, paint]
    )

    const onClose = useCallback(() => {
        onChangeModalVisible(false)
    }, [onChangeModalVisible])

    const onClickOpenModal = useCallback(() => {
        onChangeModalVisible?.(!isOpen)
    }, [isOpen, onChangeModalVisible])

    const onChangeOpacity = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            onChangeSolidPaint('opacity', value, options)
            command.commitUndo(options?.commitType)
        },
        [command, onChangeSolidPaint]
    )

    const _onChangeOpacity = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value === 'number') {
                onChangeSolidPaint('opacity', value / 100, options)
                command.commitUndo(options?.commitType)
            }
        },
        [command, onChangeSolidPaint]
    )

    const _onChangeColor = useCallback(
        (value: RGB, options?: InputOptionsForUndoSquash) => {
            onChangeSolidPaint('color', value, options)
            command.commitUndo(options?.commitType)
        },
        [command, onChangeSolidPaint]
    )

    const _onChangeVisible = useCallback(() => {
        onChangeModalVisible?.(false)
        onChangeSolidPaint('visible', !paint.visible)
        command.commitUndo()
    }, [command, onChangeModalVisible, onChangeSolidPaint, paint.visible])

    return (
        <>
            <SingleGrid
                ref={reactRef}
                className={`${style.eventPenetrate} ${selected ? style.selected : ''} ${className ?? ''}`}
            >
                <SingleGrid.Item start={5} span={46}>
                    <SingleGrid className={style.backgroundGrid}>
                        <SingleGrid.Item start={1} span={27} onMouseDown={(event) => event.stopPropagation()}>
                            <PaintIconColor
                                paint={paint}
                                colorSpace={colorSpace}
                                onChangeColor={_onChangeColor}
                                onMouseDownIcon={onClickOpenModal}
                                onClickInput={onClose}
                                isWidth={108}
                            />
                        </SingleGrid.Item>
                        <SingleGrid.Item start={28} span={12}>
                            <ScrubbableInputPercent
                                value={paint.opacity}
                                onChange={_onChangeOpacity}
                                onClick={onClose}
                                min={0}
                                max={100}
                                className={`${paint.visible ? '' : style.hidden}`}
                                labelTestId="background-opacity-scrub"
                            />
                        </SingleGrid.Item>
                        <SingleGrid.Item start={40} span={7}>
                            <IconButton
                                icon={paint.visible ? <IconEyes /> : <IconEyesClose />}
                                selected={false}
                                onChange={_onChangeVisible}
                                className={style.eyeIcon}
                                dataTestId="page-background-icon-eyes"
                            />
                        </SingleGrid.Item>
                    </SingleGrid>
                </SingleGrid.Item>
            </SingleGrid>
            {isOpen && position && (
                <ColorInteraction
                    from={ColorInteractionFrom.BACKGROUND}
                    position={position}
                    paint={paint}
                    onCancel={onClose}
                    onChangeColor={_onChangeColor}
                    onChangeOpacity={onChangeOpacity}
                    enterClose={onClose}
                    styleId={undefined}
                    styleKey={undefined}
                    onChangePaintType={() => {}}
                    onChangeBlendMode={() => {}}
                    onChangeImagePaint={() => {}}
                    onChangeColorStops={() => {}}
                    onChangeTransform={() => {}}
                    onChangeStyle={() => {}}
                    onChangeColorVar={() => {}}
                    onChangePaints={() => {}}
                    onClickCreateStyleButton={undefined}
                />
            )}
        </>
    )
}

export const BackgroundSolidPaint = React.forwardRef(_BackgroundSolidPaint)
