import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    FollowMotiff: '跟随引导探索 {{name}}研发模式？',
    Content: '了解研发模式的基础功能。',
    Start: '立即开始',
    No: '我不需要',
} as const

export const enTranslation = {
    FollowMotiff: 'Take a quick tour of Develop Mode?',
    Content: 'Walk through the basic features of Develop Mode.',
    Start: "Let's go",
    No: 'No thanks',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
