import classNames from 'classnames'
import { forwardRef, MouseEvent, useMemo } from 'react'
import { execCommandCopy } from '../../../../util/src'
import { WKToast } from '../wk-toast'
import styles from './index.module.less'
import { translation } from './text.translation'
import { WKTextProps } from './type'
import { getFontColor, getFontSize, getFontWeight } from './util'

export const Text = forwardRef(function Component(props: WKTextProps, ref: React.Ref<HTMLSpanElement>) {
    const { children, size, weight, color, copyable = false, className, copyText, ...rest } = props

    const _size = size as WKTextProps['size'] | undefined

    const fontSize = getFontSize(_size)
    const fontWeight = getFontWeight(weight)
    const fontColor = getFontColor(color)

    const copyableClassName = useMemo(() => {
        if (copyable) {
            return styles.copyable
        } else {
            return ''
        }
    }, [copyable])

    const clickHandler = (e: MouseEvent) => {
        if (copyable) {
            e.stopPropagation()
            if (copyText) {
                execCommandCopy(copyText)
                WKToast.show(translation('CopiedToClipboard'))
            } else {
                if (typeof children === 'string') {
                    execCommandCopy(children)
                    WKToast.show(translation('CopiedToClipboard'))
                }
            }
        }
    }

    return (
        <span
            ref={ref}
            onClick={clickHandler}
            className={classNames(
                'relative inline-flex items-center',
                fontSize,
                fontWeight,
                fontColor,
                copyableClassName,
                className
            )}
            {...rest}
        >
            {children}
        </span>
    )
})
