import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { isNil } from 'lodash-es'
import { PropsWithChildren } from 'react'
import { MonoIconMenuColorVariable12, MonoIconPanelVariable16, Tooltip } from '../../../../../ui-lib/src'
import { buildThumbnailDataFromVO, LibraryThumbnailImage } from '../library-thumbnail-image'
import classes from './library-style-viewer.module.less'
import { translation } from './library-style-viewer.translation'
import { StyleGetVO, VariableGetVO, VariableSetGetVO } from '../../../kernel/interface/component-style'

export type StyleViewItem = Pick<
    Wukong.DocumentProto.IVLibraryAssetPanelComponentItem,
    | 'isLocal'
    | 'localNodeId'
    | 'publishHidden'
    | 'remoteNodeId'
    | 'remoteDocId'
    | 'name'
    | 'nodeDataPath'
    | 'thumbnailData'
    | 'renderKey'
> & {
    description?: string | null
    fontMissing?: boolean | null
}

export function buildStyleViewerItemFromVO(vo: StyleGetVO): StyleViewItem {
    return {
        isLocal: false,
        localNodeId: null,
        publishHidden: false,
        remoteNodeId: vo.nodeId,
        remoteDocId: vo.documentId,
        name: vo.name ?? '',
        description: vo.description,
        nodeDataPath: vo.nodeDataPath ?? '',
        thumbnailData: buildThumbnailDataFromVO(vo),
        renderKey: 'remote-library-style-' + vo.id + '-' + vo.contentHash,
    }
}

export function LibraryStyleViewer(props: {
    style: StyleViewItem
    // 禁止默认右键行为
    disableRightClick?: boolean
    // hover 时不显示 name
    hiddenName?: boolean
}) {
    return (
        <div className={classNames(classes.libraryStyleViewerContainer)}>
            <Tooltip
                inactivation={props.hiddenName}
                overlay={
                    <>
                        {props.style.name || translation('Untitled')}
                        {!!props.style.fontMissing && `（${translation('MissingFonts')}）`}
                        {/* NOTE: 与名称相同内容时不展示备注 */}
                        {!!props.style.description && props.style.name !== props.style.description && (
                            <p className={classes.description}>{props.style.description}</p>
                        )}
                    </>
                }
            >
                <div className={classNames(classes.thumbnailImageWrapper)}>
                    <LibraryThumbnailImage
                        thumbnailData={props.style.thumbnailData}
                        className={classNames(classes.thumbnailImage, {
                            [classes.fontMissing]: !!props.style.fontMissing,
                            [classes.layoutStyle]: props.style.thumbnailData?.type === 23,
                        })}
                        fontMissing={!!props.style.fontMissing}
                    />
                </div>
            </Tooltip>
        </div>
    )
}

export function LibraryVariableSetGridViewer(props: { style: Readonly<VariableSetGetVO> }) {
    return (
        <Tooltip inactivation={false} overlay={<>{props.style.name || translation('Untitled')}</>}>
            <div className={classNames(classes.variableSetGridWrapper)}>
                <MonoIconPanelVariable16 />
            </div>
        </Tooltip>
    )
}

export function LibraryVariableGridViewer(props: { style: Readonly<VariableGetVO> }) {
    return (
        <Tooltip inactivation={false} overlay={<>{props.style.name || translation('Untitled')}</>}>
            <div className={classNames(classes.variableGridWrapper)}>
                <MonoIconMenuColorVariable12 />
            </div>
        </Tooltip>
    )
}

export function LibraryVariableSetViewer(props: { style: Readonly<VariableSetGetVO>; isSearchResult?: boolean }) {
    return (
        <div className={classNames(classes.libraryVariableSetWrapper)}>
            <MonoIconPanelVariable16 style={{ flexShrink: 0 }} />
            <div className={classNames(classes.variableSetName, 'truncate')}>{props.style.name}</div>
            {!!props.style.variableCount && (
                <div className={classNames(classes.variableCount)}>
                    {props.isSearchResult
                        ? translation('SearchVariableCount', {
                              count: props.style.variableCount + '',
                              suffix: props.style.variableCount !== 1 ? 's' : '',
                          })
                        : translation('VariableCount', {
                              count: props.style.variableCount + '',
                              suffix: props.style.variableCount !== 1 ? 's' : '',
                          })}
                </div>
            )}
        </div>
    )
}

// 组件库样式列表布局
export const LibraryStyleListLayout = (
    props: PropsWithChildren<{
        layout?: Wukong.DocumentProto.VLibraryAssetPanelDisplayMode
        maxChildrenLength: number | undefined
        className?: string
    }>
) => {
    return isNil(props.maxChildrenLength) || props.maxChildrenLength > 0 ? (
        <div
            className={classNames(
                classes.libraryStyleLayout,
                {
                    [classes.libraryStyleLayoutGrid]:
                        isNil(props.layout) ||
                        props.layout ===
                            Wukong.DocumentProto.VLibraryAssetPanelDisplayMode.V_LIBRARY_ASSET_PANEL_DISPLAY_MODE_LIST,
                    [classes.libraryStyleLayoutList]:
                        props.layout ===
                        Wukong.DocumentProto.VLibraryAssetPanelDisplayMode.V_LIBRARY_ASSET_PANEL_DISPLAY_MODE_LIST,
                },
                props.className
            )}
            data-testid="library-style-list-layout"
        >
            {props.children}
        </div>
    ) : null
}
