import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    VersionHistory: '历史版本',
    Filter: '筛选',
    All: '所有版本',
    OnlyYours: '我的版本',
    ShowAutosaveVersion: '自动保存版本',
    AddToVersion: '添加历史版本',
    Close: '关闭',
} as const

export const enTranslation = {
    VersionHistory: 'Version history',
    Filter: 'Filter',
    All: 'All',
    OnlyYours: 'Only yours',
    ShowAutosaveVersion: 'Show autosave version',
    AddToVersion: 'Add to version history',
    Close: 'Close',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
