/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { ClassWithEffect, EffectController } from '../../../../../../../util/src/effect-controller'
import { DeepRequired, ViewStateBridge } from '../../../../../view-state-bridge'

export class DevModeHoverNodeLabelService extends ClassWithEffect {
    private overlayElement: HTMLElement | null = null
    private hoverNodeLabelNameElement: HTMLElement | null = null
    private hoverNodeLabelSizeElement: HTMLElement | null = null
    private currentHoveredNodeId: string | null = null // 当前 react 组件渲染基于的 node hover id
    constructor(private readonly viewStateBridge: ViewStateBridge, controller: EffectController) {
        super(controller)
    }

    public init = () => {
        this.viewStateBridge.register('devModeHoverNodeLabelViewState', this.renderOverlayPosition)
    }

    public destroy = () => {
        this.viewStateBridge.unregister('devModeHoverNodeLabelViewState', this.renderOverlayPosition)
    }

    public setOverlayElement = (element: HTMLElement | null) => {
        this.overlayElement = element
        // bridge 被销毁后不应继续 render
        if (this.controller.aborted) {
            return
        }
        this.renderOverlayPosition()
    }

    public setHoverNodeLabelNameElement = (element: HTMLElement | null, hoveredNodeId: string) => {
        this.hoverNodeLabelNameElement = element
        this.currentHoveredNodeId = element ? hoveredNodeId : null
        // bridge 被销毁后不应继续 render
        if (this.controller.aborted) {
            return
        }
        this.renderOverlayPosition()
    }

    public setHoverNodeLabelSizeElement = (element: HTMLElement | null) => {
        this.hoverNodeLabelSizeElement = element
        // bridge 被销毁后不应继续 render
        if (this.controller.aborted) {
            return
        }
        this.renderOverlayPosition()
    }

    private renderOverlayPosition = () => {
        const viewState = this.viewStateBridge.getDefaultValue('devModeHoverNodeLabelViewState')
        if (!viewState) {
            return
        }
        const { originX, originY, translateX, translateY } = calculateOverlayPosition(viewState.visibleViewport)

        if (this.overlayElement) {
            this.overlayElement.style.left = `${originX + translateX}px`
            this.overlayElement.style.top = `${originY + translateY}px`
            this.overlayElement.style.display = viewState.show ? '' : 'none'
        }
        if (this.hoverNodeLabelNameElement && this.currentHoveredNodeId === viewState.hoveredNodeId) {
            this.hoverNodeLabelNameElement.style.left = `${viewState.boundary.x}px`
            this.hoverNodeLabelNameElement.style.top = `${viewState.boundary.y - 26}px`
        }
        if (this.hoverNodeLabelSizeElement) {
            this.hoverNodeLabelSizeElement.style.left = `${viewState.boundary.x}px`
            this.hoverNodeLabelSizeElement.style.top = `${viewState.boundary.y + viewState.boundary.height + 4}px`
            this.hoverNodeLabelSizeElement.style.width = `${viewState.boundary.width}px`
            this.hoverNodeLabelSizeElement.style.display = viewState.isHoverSelectedNode ? '' : 'none'
        }
    }
}

export function calculateOverlayPosition(viewport: DeepRequired<Wukong.DocumentProto.IViewport>) {
    const originX = viewport.width / 2
    const originY = viewport.height / 2
    const translateX = -(viewport.x * viewport.zoom) - originX
    const translateY = -(viewport.y * viewport.zoom) - originY

    return { originX, originY, translateX, translateY }
}
