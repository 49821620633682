import { translation } from './config.translation'
export const topics = [
    [translation('Random'), 'random'],
    [translation('Minimal'), 'minimal'],
    [translation('Animal'), 'animal'],
    [translation('Nature'), 'nature'],
    [translation('Architecture'), 'architecture'],
    [translation('Plant'), 'plant'],
    [translation('Art'), 'art'],
    [translation('Portrait'), 'portrait'],
    [translation('Business'), 'business'],
    [translation('Space'), 'space'],
    [translation('Colorful'), 'colorful'],
    [translation('Technology'), 'technology'],
    [translation('Food'), 'food'],
    [translation('Texture'), 'texture'],
    [translation('Interior'), 'interior'],
    [translation('Abstract'), 'abstract'],
]
