import { Nil } from './type'

export function filterForNonNullableArray<T>(nodes: (T | Nil)[]): T[] {
    return nodes.filter((node): node is T => node != null)
}

// 根据 key 生成二维数组，每个数组中的元素具有相同的 key
// key 顺序和遍历顺序一致
export function groupByKey<T, K extends keyof T>(nodes: T[], key: K): T[][] {
    const map = new Map<T[K], T[]>()
    nodes.forEach((node) => {
        const value = node[key]
        if (map.has(value)) {
            map.get(value)!.push(node)
        } else {
            map.set(value, [node])
        }
    })
    return Array.from(map.values())
}
