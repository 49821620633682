import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    GoTo: '前往',
    Open: '打开',
    Edit: '编辑',
} as const

export const enTranslation = {
    GoTo: 'Go to',
    Open: 'Open',
    Edit: 'Edit',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
