import { isWindowsOrLinux } from '../util/ua'
import { translation } from './shortcut-key.translation'

// 快捷键大全：
// https://www.notion.so/3e1ed49408d74545b2e85b802afcf628?v=2046aebbbb8645eba9ee6b58d12c4eb0
export enum ShortcutKey {
    ShowLeftSidebar, // 显示 / 隐藏左边栏
    PickColor, // 取色器
    Undo, // 撤销
    Redo, // 重做
    MoveTool, // 移动
    ScaleTool, // 缩放
    FrameTool, // 容器
    PenTool, // 钢笔
    TextTool, // 文本
    RectangleTool, // 矩形
    EllipseTool, // 圆形
    LineTool, // 直线
    ArrowTool, // 箭头
    PolygonTool, // 多边形
    StarTool, // 星形
    SliceTool, // 切片
    HandTool, // 手
    ImageTool, // 图片
    Rulers, // 标尺
    PixelGrid, // 像素网格
    SnapToPixelGrid, // 对齐像素网格
    Pan, // 移动画布
    ZoomIn, // 放大
    ZoomOut, // 缩小
    ZoomToFit, // 缩放至显示全部
    ZoomToSelection, // 缩放至已选择图层
    ZoomToPreviousFrame, // 缩放至上个Frame
    ZoomToNextFrame, // 缩放至下个Frame
    PreviousPage, // 切换至上个页面
    NextPage, // 切换至下个页面
    FindPreviousFrame, // 选中上个Frame
    FindNextFrame, // 选中下个Frame
    Underline, // 下划线
    Strikethrough, // 删除线
    TextAlignLeft, // 左对齐
    TextAlignCenter, // 水平居中对齐
    TextAlignRight, // 右对齐
    TextAlignJustified, // 两边对齐
    AdjustFontSize, // 调整字号
    AdjustLetterSpacing, // 调整字间距
    AdjustLineHeight, // 调整行高
    PaintBucket, // 染色桶
    BendTool, // 控制点
    RemoveFill, // 移除填充
    RemoveStroke, // 移除描边
    SwapFillAndStroke, // 交换填充和描边
    OutlineStroke, // 轮廓化
    FlattenSelection, // 拼合
    JoinSelection, // 连接选中节点
    AdjustSize, // 调整大小
    Rotate, // 旋转
    SmoothJoinSelection, // 平滑连接选中节点
    DeleteAndHealSelection, // 删除选中矢量节点并修复连线
    Delete, // 删除选中节点
    SelectAll, // 全选
    SelectInverse, // 反选
    SelectNone, // 取消选中
    DeepSelect, // 透选
    SelectChildren, // 选择子图层
    SelectParent, // 选择父图层
    SelectNextSibling, // 选择下一个兄弟图层
    SelectPreviousSibling, // 选择上一个兄弟图层
    GroupSelection, // 创建编组
    UngroupSelection, // 取消编组
    FrameSelection, // 创建容器
    ToggleShowSelection, // 隐藏 / 显示已选择图层
    ToggleLockSelection, // 锁定 / 解锁已选择图层
    FoldSelection, // 折叠图层
    MeasureToSelection, // 测量距离（选中并悬浮其他图层）
    DuplicateSelection, // 复制选中图层（拖动图层）
    DeepSelectWithinRectangle, // 穿透框选
    ResizeFromCenter, // 从中心调整大小（拖动边界框时）
    ResizeProportionally, // 保持宽高比例调整大小
    IgnoreConstraints, // 忽略约束（拖动父图层边界框时）
    Copy, // 复制
    Cut, // 剪切
    Paste, // 粘贴
    PasteOverSelection, // 粘贴至所选位置
    PasteToReplace, // 粘贴替换
    Duplicate, // 创建副本
    CopyProperties, // 复制属性
    PasteProperties, // 粘贴属性
    CopyAsPNG, // 复制为 PNG
    FlipHorizontal, // 水平翻转
    FlipVertical, // 垂直翻转
    UseAsMask, // 设为蒙版
    EditShapeOrImage, // 编辑形状或图片
    SetOpacityTo0, // 设置不透明度为0%
    SetOpacityTo10, // 设置不透明度为10%
    SetOpacityTo50, // 设置不透明度为50%
    SetOpacityTo100, // 设置不透明度为100%
    BringForward, // 上移一层
    SendBackward, // 下移一层
    AlignLeft, // 左对齐
    AlignRight, // 右对齐
    AlignTop, // 顶对齐
    AlignBottom, // 底对齐
    AlignHorizontalCenters, // 水平居中对齐
    AlignVerticalCenters, // 垂直居中对齐
    DistributeHorizontalSpacing, // 水平间距均分
    DistributeVerticalSpacing, // 垂直间距均分
    AddAutoLayout, // 增加自动布局
    RemoveAutoLayout, // 取消自动布局
    ComponentSelection, // 创建组件
    DetachInstance, // 分离实例
    SwapComponentInstance, // 替换组件实例
    OpenLayersPanel, // 打开图层面板
    OpenAssetsPanel, // 打开资产面板
    OpenLibrary, // 打开组件库
    OpenDesignPanel, // 打开设计面板
    OpenInspectPanel, // 打开标注面板
    ZoomTo100Percent, // 缩放至 100%
    Bold, // 加粗
    Italic, // 斜体
    PasteWithoutStyle, // 文字编辑状态下去除样式并粘贴文字
    AdjustFontWeight, // 调整字重
    ToggleLockAllObjects, // 锁定/解锁所有图层
    RenameSelection, // 重命名所选图层
    CropImage, // 剪裁图片
    BringToFront, // 移至顶层
    SendToBack, // 移至底层
    CommentTool, // 评论
    ShowComment, // 显示 / 隐藏评论
    RunLastPlugin, // 运行最近一次启用
    ZoomInOutView, // 放大缩小视图
    PlaceIgnoreSize, // 忽略尺寸直接放到非最外层容器内
    ScrollVertical, // 画布上下移动
    ScrollHorizontal, // 画布左右移动
    ToggleFullScreen, // 切换全屏
    Copilot, // 智能图层
    ShowMultiplayerCursor, // 展示多人协作光标
    Hyperlink, // 编辑文本链接
    ToggleAILayout, // 切换 AI 布局
    SaveExitAILayout, // 保存并退出 AI 布局
    SaveHistoryVersion, // 保存为历史版本
    ShortcutGuide, // 快进键指南
    TidyUp, // 整理
    BatchExport, // 批量导出
    LayoutGrid, // 布局网格
    CanvasSearch, // 搜索
    AiCreateAutoLayout, // AI 创建自动布局
    AiAlign, // AI 对齐
    AiDuplicate, // AI 复制
    DevMode, // 研发模式
    DevModeInspect, // 标注工具
    PrototypingVisble, // 原型流程可见,
    EnterAiLayout, // 进入 AI 布局 - 临时结构化调整
    SmartAddAutoLayout, // 智能添加自动布局
    Present, // 演示
    ReportObstacle, // 发送错误报告，
    ShowMotiffScope, // 打开节点审查面板
}

export const shortcutMacLabelMap: Record<ShortcutKey, string> = {
    [ShortcutKey.ShowLeftSidebar]: '⇧⌘\\',
    [ShortcutKey.PickColor]: '⌃C',
    [ShortcutKey.Undo]: '⌘Z',
    [ShortcutKey.Redo]: '⇧⌘Z',
    [ShortcutKey.MoveTool]: 'V',
    [ShortcutKey.ScaleTool]: 'K',
    [ShortcutKey.FrameTool]: 'F',
    [ShortcutKey.PenTool]: 'P',
    [ShortcutKey.TextTool]: 'T',
    [ShortcutKey.RectangleTool]: 'R',
    [ShortcutKey.EllipseTool]: 'O',
    [ShortcutKey.LineTool]: 'L',
    [ShortcutKey.ArrowTool]: '⇧L',
    [ShortcutKey.PolygonTool]: '',
    [ShortcutKey.StarTool]: '',
    [ShortcutKey.SliceTool]: 'S',
    [ShortcutKey.HandTool]: 'H',
    [ShortcutKey.ImageTool]: '⇧⌘K',
    [ShortcutKey.Rulers]: '⇧R',
    [ShortcutKey.PixelGrid]: "⌘'",
    [ShortcutKey.SnapToPixelGrid]: "⇧⌘'",
    [ShortcutKey.Pan]: 'Space Drag',
    [ShortcutKey.ZoomIn]: '+',
    [ShortcutKey.ZoomOut]: '-',
    [ShortcutKey.ZoomToFit]: '⇧1',
    [ShortcutKey.ZoomToSelection]: '⇧2',
    [ShortcutKey.ZoomToPreviousFrame]: '⇧N',
    [ShortcutKey.ZoomToNextFrame]: 'N',
    [ShortcutKey.PreviousPage]: 'Page Up',
    [ShortcutKey.NextPage]: 'Page Down',
    [ShortcutKey.FindPreviousFrame]: 'Home',
    [ShortcutKey.FindNextFrame]: 'End',
    [ShortcutKey.Underline]: '⌘U',
    [ShortcutKey.Strikethrough]: '⇧⌘X',
    [ShortcutKey.TextAlignLeft]: '⌥⌘L',
    [ShortcutKey.TextAlignCenter]: '⌥⌘T',
    [ShortcutKey.TextAlignRight]: '⌥⌘R',
    [ShortcutKey.TextAlignJustified]: '⌥⌘J',
    [ShortcutKey.AdjustFontSize]: '⇧⌘< and ⇧⌘>',
    [ShortcutKey.AdjustLetterSpacing]: '⌥< and ⌥>',
    [ShortcutKey.AdjustLineHeight]: '⇧⌥< and ⇧⌥>',
    [ShortcutKey.PaintBucket]: 'B',
    [ShortcutKey.BendTool]: '⌘',
    [ShortcutKey.RemoveFill]: '⌥/',
    [ShortcutKey.RemoveStroke]: '⇧/',
    [ShortcutKey.SwapFillAndStroke]: '⇧X',
    [ShortcutKey.OutlineStroke]: '⇧⌘O',
    [ShortcutKey.FlattenSelection]: '⌘E',
    [ShortcutKey.JoinSelection]: '⌘J',
    [ShortcutKey.AdjustSize]: `⌘${translation('mEQhze')}`,
    [ShortcutKey.Rotate]: '⌘',
    [ShortcutKey.SmoothJoinSelection]: '⇧⌘J',
    [ShortcutKey.DeleteAndHealSelection]: '⇧⌫',
    [ShortcutKey.Delete]: '⌫',
    [ShortcutKey.SelectAll]: '⌘A',
    [ShortcutKey.SelectInverse]: '⇧⌘A',
    [ShortcutKey.SelectNone]: 'Esc',
    [ShortcutKey.DeepSelect]: '⌘Click',
    [ShortcutKey.SelectChildren]: '↩',
    [ShortcutKey.SelectParent]: '⇧↩',
    [ShortcutKey.SelectNextSibling]: 'Tab',
    [ShortcutKey.SelectPreviousSibling]: '⇧Tab',
    [ShortcutKey.GroupSelection]: '⌘G',
    [ShortcutKey.UngroupSelection]: '⇧⌘G',
    [ShortcutKey.FrameSelection]: '⌥⌘G',
    [ShortcutKey.ToggleShowSelection]: '⇧⌘H',
    [ShortcutKey.ToggleLockSelection]: '⇧⌘L',
    [ShortcutKey.FoldSelection]: '⌥L',
    [ShortcutKey.MeasureToSelection]: '⌥',
    [ShortcutKey.DuplicateSelection]: '⌥',
    [ShortcutKey.DeepSelectWithinRectangle]: '⌘Drag',
    [ShortcutKey.ResizeFromCenter]: '⌥',
    [ShortcutKey.ResizeProportionally]: '⇧',
    [ShortcutKey.IgnoreConstraints]: '⌘',
    [ShortcutKey.Copy]: '⌘C',
    [ShortcutKey.Cut]: '⌘X',
    [ShortcutKey.Paste]: '⌘V',
    [ShortcutKey.PasteOverSelection]: '⇧⌘V',
    [ShortcutKey.PasteToReplace]: '⇧⌘R',
    [ShortcutKey.Duplicate]: '⌘D',
    [ShortcutKey.CopyProperties]: '⌥⌘C',
    [ShortcutKey.PasteProperties]: '⌥⌘V',
    [ShortcutKey.CopyAsPNG]: '⇧⌘C',
    [ShortcutKey.FlipHorizontal]: '⇧H',
    [ShortcutKey.FlipVertical]: '⇧V',
    [ShortcutKey.UseAsMask]: '⌃⌘M',
    [ShortcutKey.EditShapeOrImage]: '↩',
    [ShortcutKey.SetOpacityTo0]: '00',
    [ShortcutKey.SetOpacityTo10]: '1',
    [ShortcutKey.SetOpacityTo50]: '5',
    [ShortcutKey.SetOpacityTo100]: '0',
    [ShortcutKey.BringForward]: '⌘]',
    [ShortcutKey.SendBackward]: '⌘[',
    [ShortcutKey.AlignLeft]: '⌥A',
    [ShortcutKey.AlignRight]: '⌥D',
    [ShortcutKey.AlignTop]: '⌥W',
    [ShortcutKey.AlignBottom]: '⌥S',
    [ShortcutKey.AlignHorizontalCenters]: '⌥H',
    [ShortcutKey.AlignVerticalCenters]: '⌥V',
    [ShortcutKey.DistributeHorizontalSpacing]: '⌃⌥H',
    [ShortcutKey.DistributeVerticalSpacing]: '⌃⌥V',
    [ShortcutKey.AddAutoLayout]: '⇧A',
    [ShortcutKey.RemoveAutoLayout]: '⌥⇧A',
    [ShortcutKey.ComponentSelection]: '⌥⌘K',
    [ShortcutKey.DetachInstance]: '⌥⌘B',
    [ShortcutKey.SwapComponentInstance]: '⌥',
    [ShortcutKey.OpenLayersPanel]: '⌥1',
    [ShortcutKey.OpenAssetsPanel]: '⌥2',
    [ShortcutKey.OpenLibrary]: '⌥3',
    [ShortcutKey.OpenDesignPanel]: '⌥8',
    [ShortcutKey.OpenInspectPanel]: '⌥0',
    [ShortcutKey.ZoomTo100Percent]: '⌘0',
    [ShortcutKey.Bold]: '⌘B',
    [ShortcutKey.Italic]: '⌘I',
    [ShortcutKey.PasteWithoutStyle]: '⇧⌘V',
    [ShortcutKey.AdjustFontWeight]: '⌥⌘< and ⌥⌘>',
    [ShortcutKey.ToggleLockAllObjects]: '⇧⌘L',
    [ShortcutKey.RenameSelection]: '⌘R',
    [ShortcutKey.CropImage]: '⌥Double-click',
    [ShortcutKey.BringToFront]: ']',
    [ShortcutKey.SendToBack]: '[',
    [ShortcutKey.CommentTool]: 'C',
    [ShortcutKey.ShowComment]: '⇧C',
    [ShortcutKey.RunLastPlugin]: '⌥⌘P',
    [ShortcutKey.ZoomInOutView]: '⌘Scroll',
    [ShortcutKey.PlaceIgnoreSize]: '⌘',
    [ShortcutKey.ScrollVertical]: 'Scroll',
    [ShortcutKey.ScrollHorizontal]: '⇧Scroll',
    [ShortcutKey.ToggleFullScreen]: '⌘\\',
    [ShortcutKey.Copilot]: 'M',
    [ShortcutKey.ShowMultiplayerCursor]: '⌥⌘\\',
    [ShortcutKey.Hyperlink]: '⌘K',
    [ShortcutKey.ToggleAILayout]: '⇧M',
    [ShortcutKey.SaveExitAILayout]: '⇧⌥M',
    [ShortcutKey.SaveHistoryVersion]: '⌥⌘S',
    [ShortcutKey.ShortcutGuide]: '^⇧?',
    [ShortcutKey.TidyUp]: '⌃⌥T',
    [ShortcutKey.BatchExport]: '⇧⌘E',
    [ShortcutKey.LayoutGrid]: '⇧G',
    [ShortcutKey.CanvasSearch]: '⌘F',
    [ShortcutKey.AiCreateAutoLayout]: '⇧⌘S',
    [ShortcutKey.AiAlign]: '⌥T',
    [ShortcutKey.AiDuplicate]: 'D',
    [ShortcutKey.DevMode]: '⇧D',
    [ShortcutKey.DevModeInspect]: 'V',
    [ShortcutKey.PrototypingVisble]: '⇧E',
    [ShortcutKey.EnterAiLayout]: '⇧S',
    [ShortcutKey.SmartAddAutoLayout]: '⇧⌘S',
    [ShortcutKey.Present]: '⌥⌘↩',
    [ShortcutKey.ReportObstacle]: '⌥⇧⌘I',
    [ShortcutKey.ShowMotiffScope]: '⌃⌥⌘F',
}

export const shortcutWindowsLabelMap: Record<ShortcutKey, string> = {
    [ShortcutKey.ShowLeftSidebar]: 'Ctrl+Shift+\\',
    [ShortcutKey.PickColor]: 'I',
    [ShortcutKey.Undo]: 'Ctrl+Z',
    [ShortcutKey.Redo]: 'Ctrl+Shift+Z',
    [ShortcutKey.MoveTool]: 'V',
    [ShortcutKey.ScaleTool]: 'K',
    [ShortcutKey.FrameTool]: 'F',
    [ShortcutKey.PenTool]: 'P',
    [ShortcutKey.TextTool]: 'T',
    [ShortcutKey.RectangleTool]: 'R',
    [ShortcutKey.EllipseTool]: 'O',
    [ShortcutKey.LineTool]: 'L',
    [ShortcutKey.ArrowTool]: 'Shift+L',
    [ShortcutKey.PolygonTool]: '',
    [ShortcutKey.StarTool]: '',
    [ShortcutKey.SliceTool]: 'S',
    [ShortcutKey.HandTool]: 'H',
    [ShortcutKey.ImageTool]: 'Ctrl+Shift+K',
    [ShortcutKey.Rulers]: 'Shift+R',
    [ShortcutKey.PixelGrid]: "Ctrl+'",
    [ShortcutKey.SnapToPixelGrid]: "Ctrl+Shift+'",
    [ShortcutKey.Pan]: 'Space Drag',
    [ShortcutKey.ZoomIn]: '+',
    [ShortcutKey.ZoomOut]: '-',
    [ShortcutKey.ZoomToFit]: 'Shift+1',
    [ShortcutKey.ZoomToSelection]: 'Shift+2',
    [ShortcutKey.ZoomToPreviousFrame]: 'Shift+N',
    [ShortcutKey.ZoomToNextFrame]: 'N',
    [ShortcutKey.PreviousPage]: 'Page Up',
    [ShortcutKey.NextPage]: 'Page Down',
    [ShortcutKey.FindPreviousFrame]: 'Home',
    [ShortcutKey.FindNextFrame]: 'End',
    [ShortcutKey.Underline]: 'Ctrl+U',
    [ShortcutKey.Strikethrough]: 'Ctrl+Shift+X',
    [ShortcutKey.TextAlignLeft]: 'Ctrl+Alt+L',
    [ShortcutKey.TextAlignCenter]: 'Ctrl+Alt+T',
    [ShortcutKey.TextAlignRight]: 'Ctrl+Alt+R',
    [ShortcutKey.TextAlignJustified]: 'Ctrl+Alt+J',
    [ShortcutKey.AdjustFontSize]: 'Ctrl+Shift+< and Ctrl+Shift+>',
    [ShortcutKey.AdjustLetterSpacing]: 'Alt+< and Alt+>',
    [ShortcutKey.AdjustLineHeight]: 'Alt+Shift+< and Alt+Shift+>',
    [ShortcutKey.PaintBucket]: 'B',
    [ShortcutKey.BendTool]: 'Ctrl',
    [ShortcutKey.RemoveFill]: 'Alt+/',
    [ShortcutKey.RemoveStroke]: 'Shift+/',
    [ShortcutKey.SwapFillAndStroke]: 'Shift+X',
    [ShortcutKey.OutlineStroke]: 'Ctrl+Shift+O',
    [ShortcutKey.FlattenSelection]: 'Ctrl+E',
    [ShortcutKey.JoinSelection]: 'Ctrl+J',
    [ShortcutKey.AdjustSize]: `Ctrl+${translation('mEQhze')}`,
    [ShortcutKey.Rotate]: 'Ctrl',
    [ShortcutKey.SmoothJoinSelection]: 'Ctrl+Shift+J',
    [ShortcutKey.DeleteAndHealSelection]: 'Shift+Backspace',
    [ShortcutKey.Delete]: 'Backspace',
    [ShortcutKey.SelectAll]: 'Ctrl+A',
    [ShortcutKey.SelectInverse]: 'Ctrl+Shift+A',
    [ShortcutKey.SelectNone]: 'Esc',
    [ShortcutKey.DeepSelect]: 'Ctrl+Click',
    [ShortcutKey.SelectChildren]: 'Enter',
    [ShortcutKey.SelectParent]: 'Shift+Enter',
    [ShortcutKey.SelectNextSibling]: 'Tab',
    [ShortcutKey.SelectPreviousSibling]: 'Shift+Tab',
    [ShortcutKey.GroupSelection]: 'Ctrl+G',
    [ShortcutKey.UngroupSelection]: 'Ctrl+Shift+G',
    [ShortcutKey.FrameSelection]: 'Ctrl+Alt+G',
    [ShortcutKey.ToggleShowSelection]: 'Ctrl+Shift+H',
    [ShortcutKey.ToggleLockSelection]: 'Ctrl+Shift+L',
    [ShortcutKey.FoldSelection]: 'Alt+L',
    [ShortcutKey.MeasureToSelection]: 'Alt',
    [ShortcutKey.DuplicateSelection]: 'Alt',
    [ShortcutKey.DeepSelectWithinRectangle]: 'Ctrl+Drag',
    [ShortcutKey.ResizeFromCenter]: 'Alt',
    [ShortcutKey.ResizeProportionally]: 'Shift',
    [ShortcutKey.IgnoreConstraints]: 'Ctrl',
    [ShortcutKey.Copy]: 'Ctrl+C',
    [ShortcutKey.Cut]: 'Ctrl+X',
    [ShortcutKey.Paste]: 'Ctrl+V',
    [ShortcutKey.PasteOverSelection]: 'Ctrl+Shift+V',
    [ShortcutKey.PasteToReplace]: 'Ctrl+Shift+R',
    [ShortcutKey.Duplicate]: 'Ctrl+D',
    [ShortcutKey.CopyProperties]: 'Ctrl+Alt+C',
    [ShortcutKey.PasteProperties]: 'Ctrl+Alt+V',
    [ShortcutKey.CopyAsPNG]: 'Ctrl+Shift+C',
    [ShortcutKey.FlipHorizontal]: 'Shift+H',
    [ShortcutKey.FlipVertical]: 'Shift+V',
    [ShortcutKey.UseAsMask]: 'Ctrl+Alt+M',
    [ShortcutKey.EditShapeOrImage]: 'Enter',
    [ShortcutKey.SetOpacityTo0]: '0+0',
    [ShortcutKey.SetOpacityTo10]: '1',
    [ShortcutKey.SetOpacityTo50]: '5',
    [ShortcutKey.SetOpacityTo100]: '0',
    [ShortcutKey.BringForward]: 'Ctrl+]',
    [ShortcutKey.SendBackward]: 'Ctrl+[',
    [ShortcutKey.AlignLeft]: 'Alt+A',
    [ShortcutKey.AlignRight]: 'Alt+D',
    [ShortcutKey.AlignTop]: 'Alt+W',
    [ShortcutKey.AlignBottom]: 'Alt+S',
    [ShortcutKey.AlignHorizontalCenters]: 'Alt+H',
    [ShortcutKey.AlignVerticalCenters]: 'Alt+V',
    [ShortcutKey.DistributeHorizontalSpacing]: 'Alt+Shift+H',
    [ShortcutKey.DistributeVerticalSpacing]: 'Alt+Shift+V',
    [ShortcutKey.AddAutoLayout]: 'Shift+A',
    [ShortcutKey.RemoveAutoLayout]: 'Alt+Shift+A',
    [ShortcutKey.ComponentSelection]: 'Ctrl+Alt+K',
    [ShortcutKey.DetachInstance]: 'Ctrl+Alt+B',
    [ShortcutKey.SwapComponentInstance]: 'Alt',
    [ShortcutKey.OpenLayersPanel]: 'Alt+1',
    [ShortcutKey.OpenAssetsPanel]: 'Alt+2',
    [ShortcutKey.OpenLibrary]: 'Alt+3',
    [ShortcutKey.OpenDesignPanel]: 'Alt+8',
    [ShortcutKey.OpenInspectPanel]: 'Alt+0',
    [ShortcutKey.ZoomTo100Percent]: 'Ctrl+0',
    [ShortcutKey.Bold]: 'Ctrl+B',
    [ShortcutKey.Italic]: 'Ctrl+I',
    [ShortcutKey.PasteWithoutStyle]: 'Ctrl+Shift+V',
    [ShortcutKey.AdjustFontWeight]: 'Ctrl+Alt+< and Ctrl+Alt+>',
    [ShortcutKey.ToggleLockAllObjects]: 'Ctrl+Shift+L',
    [ShortcutKey.RenameSelection]: 'Ctrl+R',
    [ShortcutKey.CropImage]: 'Alt+Double-click',
    [ShortcutKey.BringToFront]: ']',
    [ShortcutKey.SendToBack]: '[',
    [ShortcutKey.CommentTool]: 'C',
    [ShortcutKey.ShowComment]: 'Shift+C',
    [ShortcutKey.RunLastPlugin]: 'Ctrl+Alt+P',
    [ShortcutKey.ZoomInOutView]: 'Ctrl+Scroll',
    [ShortcutKey.PlaceIgnoreSize]: 'Ctrl',
    [ShortcutKey.ScrollVertical]: 'Scroll',
    [ShortcutKey.ScrollHorizontal]: 'Shift+Scroll',
    [ShortcutKey.ToggleFullScreen]: 'Ctrl+\\',
    [ShortcutKey.Copilot]: 'M',
    [ShortcutKey.ShowMultiplayerCursor]: 'Ctrl+Alt+\\',
    [ShortcutKey.Hyperlink]: 'Ctrl+K',
    [ShortcutKey.ToggleAILayout]: 'Shift+M',
    [ShortcutKey.SaveExitAILayout]: 'Alt+Shift+M',
    [ShortcutKey.SaveHistoryVersion]: 'Ctrl+Alt+S',
    [ShortcutKey.ShortcutGuide]: 'Ctrl+Shift+?',
    [ShortcutKey.TidyUp]: 'Ctrl+Alt+Shift+T',
    [ShortcutKey.BatchExport]: 'Ctrl+Shift+E',
    [ShortcutKey.LayoutGrid]: 'Shift+G',
    [ShortcutKey.CanvasSearch]: 'Ctrl + F',
    [ShortcutKey.AiCreateAutoLayout]: 'Ctrl+Alt+Shift+A',
    [ShortcutKey.AiAlign]: 'Alt+T',
    [ShortcutKey.AiDuplicate]: 'D',
    [ShortcutKey.DevMode]: 'Shift+D',
    [ShortcutKey.DevModeInspect]: 'V',
    [ShortcutKey.PrototypingVisble]: 'Shift+E',
    [ShortcutKey.EnterAiLayout]: 'Shift+S',
    [ShortcutKey.SmartAddAutoLayout]: 'Ctrl+Shift+S',
    [ShortcutKey.Present]: 'Ctrl+Alt+↩',
    [ShortcutKey.ReportObstacle]: 'Ctrl+Alt+Shift+I',
    [ShortcutKey.ShowMotiffScope]: 'Ctrl+Alt+Win+F',
}

export const shortcutLabelMap: Record<ShortcutKey, string> = isWindowsOrLinux()
    ? shortcutWindowsLabelMap
    : shortcutMacLabelMap
