import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { isNil } from 'lodash-es'
import { CSSProperties, HTMLAttributes, PropsWithChildren, forwardRef } from 'react'
import { DropdownV2, Tooltip, TooltipPlacement } from '../../../../../ui-lib/src'
import { LibraryTestId } from '../../../window'
import { LibraryThumbnailImage } from '../library-thumbnail-image'
import styles from './library-component-viewer.module.less'
import { translation } from './library-component-viewer.translation'
import {
    ComponentViewerItem,
    buildComponentViewerItemFromVO,
    useComponentViewer,
    useComponentViewerStyle,
    useLibraryComponentViewShareConfig,
} from './use-library-component-viewer'

export { buildComponentViewerItemFromVO }
export type { ComponentViewerItem }

// 单个组件/组件集预览
export function LibraryComponentViewer(props: {
    component: ComponentViewerItem
    // 自定义选中状态（传入非 undefined 值后将忽略默认选中行为）
    selected?: boolean
    // 禁止默认右键行为
    disableRightClick?: boolean
    className?: string
    style?: CSSProperties
    innerStyle?: CSSProperties
    isListLayout?: boolean
    // hover 时不显示 name
    hiddenName?: boolean
    // 悬浮时显示 tooltip 位置
    tooltipPlacement?: TooltipPlacement
}) {
    const { onContextMenuOrClick, onContextMenuEnterChange, onContextMenuClose, contextMenuInfo, contextMenuItems } =
        useComponentViewer(props)

    return (
        <>
            <div className={props.className} style={props.style} onPointerUp={onContextMenuOrClick}>
                <InnerLibraryComponentViewer
                    {...props}
                    selected={isNil(props.selected) ? !!props.component.inSelection : props.selected}
                    style={props.innerStyle}
                />
            </div>
            {!!contextMenuInfo && (
                <DropdownV2.NoTriggerSingleLevel
                    isOpenState
                    triggerRect={contextMenuInfo}
                    onChange={onContextMenuEnterChange}
                    onClose={onContextMenuClose}
                >
                    {contextMenuItems.map((item) => (
                        <DropdownV2.NoTriggerSingleLevel.Option key={item.key} value={item.key}>
                            {item.label}
                        </DropdownV2.NoTriggerSingleLevel.Option>
                    ))}
                </DropdownV2.NoTriggerSingleLevel>
            )}
        </>
    )
}

function InnerLibraryComponentViewer(props: {
    component: ComponentViewerItem
    // 选中状态
    selected: boolean
    style?: CSSProperties
    isListLayout?: boolean
    // hover 时不显示 name 或者 description
    hiddenNameAndDesc?: boolean
    // 悬浮时显示 tooltip 位置
    tooltipPlacement?: TooltipPlacement
}) {
    const { dragConfig } = useLibraryComponentViewShareConfig()
    const { containerStyle, wrapperStyle, isNameShowAsTooltip, labelStyle } = useComponentViewerStyle(props)

    return (
        <div
            className={classNames(styles.libraryComponentViewerContainer, {
                [styles.selection]: props.selected,
                [styles.publishHidden]: props.component.publishHidden,
            })}
            data-testid={LibraryTestId.Viewer.Component}
            data-id={LibraryTestId.Viewer.ComponentItem(
                (props.component.isLocal ? props.component.localNodeId : props.component.remoteNodeId) ?? ''
            )}
            style={containerStyle}
            onPointerDown={(e) => {
                if (dragConfig.draggable) {
                    dragConfig.libraryDragEventService.onPointerDown(e as unknown as PointerEvent, props.component)
                }
            }}
        >
            <div className={classNames(styles.componentNameThumbnailImageWrapper)} style={wrapperStyle}>
                <LibraryThumbnailImage thumbnailData={props.component.thumbnailData} />
            </div>

            {!props.hiddenNameAndDesc && !isNameShowAsTooltip && (
                <span className={classNames(styles.componentNameLabel)} style={labelStyle}>
                    {props.component.name ?? translation('SlgWOj')}
                </span>
            )}

            {isNameShowAsTooltip ? (
                <Tooltip
                    overlay={
                        <>
                            <p className="break-word my-0">{props.component.name ?? translation('SlgWOj')}</p>
                            {!!props.component.description && props.component.description !== props.component.name && (
                                <p className="mt-1 mb-0 break-word">{props.component.description}</p>
                            )}
                        </>
                    }
                    canMouseDownClose
                    placement="right"
                >
                    <div className={classNames(styles.libraryComponentViewerBorder)} />
                </Tooltip>
            ) : !props.hiddenNameAndDesc && !!props.component.description ? (
                <Tooltip title={props.component.description} canMouseDownClose placement="right">
                    <div className={classNames(styles.libraryComponentViewerBorder)} />
                </Tooltip>
            ) : (
                <div className={classNames(styles.libraryComponentViewerBorder)} />
            )}
        </div>
    )
}

// 组件缩略图列表排列布局
export const LibraryComponentListLayout = forwardRef<
    HTMLDivElement,
    PropsWithChildren<
        {
            layout?: Wukong.DocumentProto.VLibraryAssetPanelDisplayMode
            maxChildrenLength: number | undefined
        } & HTMLAttributes<HTMLDivElement>
    >
>(({ maxChildrenLength, layout, children, className, ...props }, ref) =>
    isNil(maxChildrenLength) || maxChildrenLength > 0 ? (
        <div
            className={classNames(
                {
                    [styles.libraryComponentLayoutGrid]:
                        isNil(layout) ||
                        layout ===
                            Wukong.DocumentProto.VLibraryAssetPanelDisplayMode.V_LIBRARY_ASSET_PANEL_DISPLAY_MODE_GRID,
                    [styles.libraryComponentLayoutList]:
                        layout ===
                        Wukong.DocumentProto.VLibraryAssetPanelDisplayMode.V_LIBRARY_ASSET_PANEL_DISPLAY_MODE_LIST,
                },
                className
            )}
            {...props}
            ref={ref}
        >
            {children}
        </div>
    ) : (
        <></>
    )
)
LibraryComponentListLayout.displayName = 'LibraryComponentListLayout'
