import classnames from 'classnames'
import { useMemo } from 'react'
import classes from './badge-number.module.less'
import { BadgeText, BadgeTextProps } from './badge-text'

export interface BadgeNumberProps extends BadgeTextProps {
    number: number
    maxNumber?: number
    stroke?: boolean
}

export function BadgeNumber(props: BadgeNumberProps) {
    const { stroke, number, maxNumber, className, ...otherProps } = props

    const text = useMemo(() => {
        if (maxNumber === undefined) {
            return number
        }
        return number > maxNumber ? `${maxNumber}+` : number
    }, [maxNumber, number])

    return (
        <BadgeText
            className={classnames(className, {
                [classes.stroke]: stroke && props.color !== 'gray',
            })}
            {...otherProps}
        >
            {text}
        </BadgeText>
    )
}
