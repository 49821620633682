import { useEffectOnce } from 'react-use'
import { DraggablePopupV2 } from '../../../../../../ui-lib/src'
import { useFeatureSwitch } from '../../../../kernel/switch/hooks'
import { useAppContext } from '../../../../main/app-context'
import { AIGenUIBody } from './ai-gen-ui-body'
import { AIGenUIHeader } from './ai-gen-ui-header'
import { useAIGenUIFrog } from './ai-gen-ui-princi'
import { useTempHtmlPrototypeTools } from './use-ai-gen-extra-tools'
import { AIGenUIContext, useAIGenUIInContext, useShowAIGenUIModal } from './use-ai-gen-ui'
import { useAIGenUIModalPos } from './use-ai-gen-ui-modal'

export function AIGenPrototype() {
    const { visible, html, close, loading } = useTempHtmlPrototypeTools()
    const enableAIPrototype = useFeatureSwitch('ai-gen-prototype')

    if (!enableAIPrototype) {
        return <></>
    }

    return (
        <DraggablePopupV2
            width={400}
            visible={visible}
            height={800}
            onCancel={close}
            bodyClassName="m-0 p-0"
            header={null}
            footer={null}
        >
            {loading ? (
                <div className="w-full h-full flex justify-center items-center b-0">loading</div>
            ) : (
                <div className="w-full h-full flex justify-center items-center b-0">
                    <iframe srcDoc={html} width={400} height={800} className="b-0 overflow-y-scroll" />
                </div>
            )}
        </DraggablePopupV2>
    )
}

function AIGenUIModal() {
    const { initPos, storeCurrentPos, updateCurrentPos } = useAIGenUIModalPos()
    const ctx = useAppContext()
    const { cancel } = useAIGenUIInContext()
    const { closeAiGenUIAiGenUiDialog, exposeAiGenUIAiGenUiDialog } = useAIGenUIFrog()
    const aiService = ctx.aiService

    // TODO(chaibowen): 未来这些EffectOne都要用Zustand重构掉
    useEffectOnce(() => {
        exposeAiGenUIAiGenUiDialog()
    })

    return (
        <DraggablePopupV2
            testId="ai-gen-ui-modal"
            width={400}
            position={initPos}
            visible={true}
            onCancel={() => {
                cancel()
                storeCurrentPos()
                aiService.closeAIGenUIModal()
                closeAiGenUIAiGenUiDialog()
            }}
            onMove={updateCurrentPos}
            style={{ zIndex: 11 }}
            header={<AIGenUIHeader />}
            footer={null}
            closeTestId="ai-gen-ui-close"
        >
            <AIGenUIBody />
        </DraggablePopupV2>
    )
}

export function AIGenUI() {
    const { showModal } = useShowAIGenUIModal()

    if (showModal) {
        return (
            <AIGenUIContext>
                <AIGenUIModal />
            </AIGenUIContext>
        )
    } else {
        return <></>
    }
}
