import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { useEffect } from 'react'
import { MonoIconMenuColorVariable12, Tooltip } from '../../../../../../../ui-lib/src'
import { PureBlockStruct } from '../../../atom/color-block/pure'
import { ColorSpace } from '../../blend/color-picker/utils/color-translate'
import { OverlayJsx2 } from '../../styles/style-tooltip/style-tooltip'
import classes from './color-variable-list-v2.module.less'

export interface ColorVariableListItemV2Props {
    className?: string
    isSelected: boolean
    isPreselect: boolean
    name: string
    description: string
    color: Wukong.DocumentProto.IRGBA | null | undefined
    colorSpace: ColorSpace
    onClick: () => void
    onMouseEnter: () => void
    onMouseLeave: () => void
    index: number
    recordEnterCallback: (index: number, callback: () => void) => void
}

export function ColorVariableListItemV2(props: ColorVariableListItemV2Props) {
    const {
        className,
        name,
        description,
        color,
        colorSpace,
        isSelected,
        isPreselect,
        onClick,
        onMouseEnter,
        onMouseLeave,
        index,
        recordEnterCallback,
    } = props

    useEffect(() => {
        recordEnterCallback(index, () => onClick?.())
    }, [onClick, index, recordEnterCallback])

    return (
        <Tooltip overlay={<OverlayJsx2 titleText={name} contentText={description} />}>
            <div
                className={classnames(classes.variable, className, {
                    [classes.selected]: isSelected,
                    [classes.preselect]: isPreselect,
                })}
                onMouseDown={(e) => e.stopPropagation()} // e.stopPropagation() 阻止拖拽
                onClick={onClick}
                onMouseMove={onMouseEnter}
                onMouseLeave={onMouseLeave}
                data-testid="variable-item"
            >
                {color ? (
                    <PureBlockStruct
                        rgb={color}
                        opacity={color.a / 255}
                        colorSpace={colorSpace}
                        className={classes.colorBlock}
                    />
                ) : (
                    <div className={classes.mixed}>
                        <MonoIconMenuColorVariable12 />
                    </div>
                )}
                <div className={classes.name}>{name}</div>
            </div>
        </Tooltip>
    )
}
