import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    StartPoint: '起点',
    Switch: '交换',
    EndPoint: '终点',
    EndPoint_synonyms1: '端点',
} as const

export const enTranslation = {
    StartPoint: 'Start point',
    Switch: 'Swap start and end points',
    EndPoint: 'End point',
    EndPoint_synonyms1: 'End point{{pluralSuffix}}',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
