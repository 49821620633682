/* eslint-disable no-restricted-imports */
import { UpdateCanvasSearchResultItemHeight } from '@wukong/bridge-proto'
import { useEffect, useRef, useState } from 'react'
import { useCommand } from '../../context/document-context'

interface Props {
    itemIndex: number
    itemStartY: number
    itemHeight: number
    children: React.ReactNode
}

export function CanvasSearchResultListVirtualItem({ itemIndex, itemStartY, itemHeight, children }: Props) {
    const command = useCommand()
    const elRef = useRef<HTMLDivElement>(null)

    const [height, setHeight] = useState(0)

    useEffect(() => {
        const el = elRef.current
        if (!el) {
            return
        }

        const updateHeight = () => {
            setHeight(el.getBoundingClientRect().height)
        }

        const observer = new ResizeObserver((entries) => {
            if (entries.length === 0) {
                return
            }
            setHeight(entries[0].contentRect.height)
        })
        observer.observe(el)

        updateHeight()

        return () => {
            observer.disconnect()
        }
    }, [])

    useEffect(() => {
        // 避免子节点动态渲染导致height计算为0, 造成滚动抖动
        if (height > 0 && height != itemHeight) {
            command.DEPRECATED_invokeBridge(UpdateCanvasSearchResultItemHeight, { itemIndex, itemHeight: height })
        }
    }, [command, itemIndex, itemHeight, height])

    return (
        <div
            ref={elRef}
            style={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                width: '100%',
                transform: `translateY(${itemStartY}px)`,
            }}
        >
            {children}
        </div>
    )
}
