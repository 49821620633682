import { GetCurrentEditorTypeCommand, SendEditorTypeToSynergyCommand } from '@wukong/bridge-proto'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { CommandInvoker } from '../../document/command/command-invoker'
import { CommitType } from '../../document/command/commit-type'
import { SynergyManager } from '../../document/synergy/synergy-manager'
import { WebSocketBridge } from '../../document/synergy/web-socket-bridge'
import { EmBridge } from '../../kernel/bridge/em-bridge'
import { Sentry } from '../../kernel/sentry'

export function connectSynergySessionChange(
    signal: TraceableAbortSignal,
    bridge: EmBridge,
    webSocketBridge: WebSocketBridge,
    synergyManager: SynergyManager,
    commandInvoker: CommandInvoker
) {
    bridge.bind(
        SendEditorTypeToSynergyCommand,
        (arg) => {
            // 非核心逻辑，try catch 一下不阻塞流程
            try {
                webSocketBridge.sendEditorTypeToSynergy(arg.value)
            } catch (e) {
                Sentry.captureException(e)
            }
        },
        { signal }
    )
    synergyManager.injectAfterDocumentFinalLoadedCallback([
        () => {
            // 非核心逻辑，try catch 一下不阻塞流程
            try {
                const currentEditorType = commandInvoker.invokeBridge(
                    CommitType.Noop,
                    GetCurrentEditorTypeCommand
                ).value
                webSocketBridge.sendEditorTypeToSynergy(currentEditorType)
            } catch (e) {
                Sentry.captureException(e)
            }
        },
    ])
}
