import { translation } from './style-text-utils.translation'
/* eslint-disable no-restricted-imports */
import { cloneDeep } from 'lodash-es'
import type { ITextStyle, LineHeight } from '../../../../../document/node/node'
import { NumberUnit } from '../../../../../document/node/node'

export function createITextStyle(iTextStyle: ITextStyle): ITextStyle {
    return {
        fontSize: iTextStyle.fontSize,
        fontName: cloneDeep(iTextStyle.fontName),
        textDecoration: iTextStyle.textDecoration,
        lineHeight: cloneDeep(iTextStyle.lineHeight),
        letterSpacing: cloneDeep(iTextStyle.letterSpacing),
        fontVariations: cloneDeep(iTextStyle.fontVariations),
        missFontInfo: cloneDeep(iTextStyle.missFontInfo),
    }
}

function toFixedWithoutZero(value: number, fractionDigits: number): string {
    return `${Number.parseFloat(Number(value).toFixed(fractionDigits))}`
}

export function transformFontSizeForUi(fontSize: number): string {
    return toFixedWithoutZero(fontSize, 2)
}

export function transformLineHeightForUi(lineHeight: LineHeight): string {
    if (!lineHeight) return ''
    const { value, unit } = lineHeight
    if (unit === NumberUnit.Percent) {
        return `${toFixedWithoutZero(value, 1)}%`
    } else if (unit === NumberUnit.Pixels) {
        return `${toFixedWithoutZero(value, 1)}px`
    } else {
        return translation('Auto')
    }
}
