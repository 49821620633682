import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Libraries: '团队组件库',
} as const

export const enTranslation = {
    Libraries: 'Libraries',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
