import { translation } from './use-style-layout-grid.translation'
/* eslint-disable no-restricted-imports */
import { GetSelectionNodeIdsCommandForWasm, Wukong } from '@wukong/bridge-proto'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Position, RectContainer } from '../../../../../../../ui-lib/src'
import { sortArrayByKey } from '../../../../../../../util/src'
import { cmdChangePopupState } from '../../../../../document/command/document-command'
import {
    cmdChangeAttrPanelStyleEditorState,
    cmdDeleteStyleNode,
} from '../../../../../document/command/node-props-command'
import { LayoutGridStyleNode, PopupStateType } from '../../../../../document/node/node'
import { StyleWithoutDocIdGetVO } from '../../../../../kernel/interface/component-style'
import { useCommand, useComponentService } from '../../../../../main/app-context'
import { useViewState } from '../../../../../view-state-bridge'
import { isSearchMatched, searchMatchedFilter } from '../../../../utils/search-sort'
import { CommonStyleInfo, groupStyleVOByName, parseStyleName } from '../get-style-nodes-info-map'
import { modifiedMultiPopup } from '../modified-multi-popup'
import { LayoutGridStyleEditorProps } from '../style-panel/layout-grid-style-editor/layout-grid-style-editor'

interface UnknowStyleGroup {
    libraryId?: string
    libraryName?: string
    docId?: string
    value: LayoutGridStyleNode
    groups: Map<string, LayoutGridStyleNode[]>
}

export interface StyleLayoutGridProps {
    open?: boolean
    position?: Position
    layoutGrids?: Wukong.DocumentProto.ILayoutGrid[]
    selectStyleId?: string
    selectStyleNodeKey: string
    isMixed?: boolean
    onChangeStyle?: (nodeId: string, needClosePopup: boolean) => void
}

export function useStyleLayoutGrid(props: StyleLayoutGridProps) {
    const { open, position, layoutGrids, selectStyleId, selectStyleNodeKey, onChangeStyle } = props
    const command = useCommand()

    const [contextMenuInfo, setContextMenuInfo] = useState<{
        styleNodeId: string
        rectContainer: RectContainer
    } | null>(null)
    const hasMoved = useRef<boolean>(false)
    const [searchStr, setSearchStr] = useState<string>('')
    const [styleIdEditor, setStyleIdEditor] = useState<string>('')
    const [isRemoteStyle, setIsRemoteStyle] = useState<boolean>(false)
    const [positionEditor, setPositionEditor] = useState<Position>()
    const [remoteStyleInfo, setRemoteStyleInfo] = useState<{
        styleId?: string
        docId?: string
        fromFig?: boolean
        name?: string
    }>()

    const popupState = useViewState('popupState')
    const attrPanelStyleEditorState = useViewState('attrPanelStyleEditorState')
    const [rightClickStyleId, setRightClickStyleId] = useState('')
    const titleContainerRef = useRef<HTMLDivElement>(null)
    const [isCreateStyle, setIsCreateStyle] = useState<boolean>(false)
    const [createStyle, setCreateStyle] = useState<LayoutGridStyleEditorProps['createStyle']>()

    const { localStyleGroups, inUseUnknownStyleNodeItems, subscribedRemoteItems, inUseOtherRemoteItems } = useViewState(
        'libraryLayoutGridStyleState'
    ) ?? {
        localStyleGroups: [],
        inUseUnknownStyleNodeItems: [],
        subscribedRemoteItems: [],
        inUseOtherRemoteItems: [],
        fakeFieldToForceUpdate: true,
    }

    const localStyleMap = useMemo(() => {
        return localStyleGroups
            .filter((group) => group.items.length > 0)
            .map((group) => {
                return {
                    ...group,
                    items: group.items.map(
                        (item): CommonStyleInfo & { layoutGrids: readonly Wukong.DocumentProto.ILayoutGrid[] } => {
                            const { styleName, groupName } = parseStyleName(item.name)
                            return {
                                id: item.id,
                                name: item.name,
                                description: item.description,
                                styleName,
                                groupName,
                                layoutGrids: item.layoutGrids as Wukong.DocumentProto.ILayoutGrid[],
                            }
                        }
                    ),
                }
            })
    }, [localStyleGroups])

    const showStyleMap = useMemo(() => {
        const infoMap: typeof localStyleMap = []
        if (!open) {
            return infoMap
        }
        return localStyleMap.map((group) => ({
            ...group,
            items: searchMatchedFilter(group.items, searchStr, (item) => item.name),
        }))
    }, [localStyleMap, open, searchStr])

    const hasRemoteStyle = useMemo(() => {
        return (
            subscribedRemoteItems.find((libraryContent) => {
                return libraryContent.groups.find((group) => group.items.length > 0)
            }) ||
            inUseOtherRemoteItems.find((libraryContent) => {
                return libraryContent.groups.find((group) =>
                    group.items.find((item) => selectStyleNodeKey && selectStyleNodeKey === item.id)
                )
            })
        )
    }, [inUseOtherRemoteItems, selectStyleNodeKey, subscribedRemoteItems])

    const remoteStyleList = useMemo(() => {
        const libs = subscribedRemoteItems.map((libraryContent) => ({
            libraryId: libraryContent.libraryId,
            libraryName: libraryContent.libraryName,
            docId: libraryContent.documentId,
            // 匹配到库名，则展示库内所有样式
            // 未匹配到库名，则展示匹配到的库样式
            groups: (isSearchMatched(libraryContent.libraryName, searchStr)
                ? libraryContent.groups
                : libraryContent.groups.map((group) => ({
                      ...group,
                      items: searchMatchedFilter(group.items, searchStr, (item) => item.name),
                  }))
            ).filter((group) => group.items.length) as unknown as Array<{
                name: string
                items: StyleWithoutDocIdGetVO[]
            }>,
        }))

        inUseOtherRemoteItems.forEach((libraryContent) => {
            libs.push({
                libraryId: libraryContent.libraryId,
                libraryName: libraryContent.libraryName,
                docId: libraryContent.documentId,
                groups: libraryContent.groups
                    .map((group) => {
                        return {
                            ...group,
                            items: group.items.filter(
                                (item) =>
                                    selectStyleNodeKey &&
                                    item.id === selectStyleNodeKey &&
                                    isSearchMatched(item.name, searchStr)
                            ),
                        }
                    })
                    .filter((group) => group.items.length > 0) as unknown as Array<{
                    name: string
                    items: StyleWithoutDocIdGetVO[]
                }>,
            })
        })
        return sortArrayByKey(
            libs.filter(({ groups }) => groups.length),
            'libraryName'
        )
    }, [inUseOtherRemoteItems, searchStr, selectStyleNodeKey, subscribedRemoteItems])

    const unknownStyleGroup: UnknowStyleGroup | undefined = useMemo(() => {
        const unknowStyleNodes = inUseUnknownStyleNodeItems as unknown as LayoutGridStyleNode[]

        for (const value of unknowStyleNodes) {
            if (value.id !== selectStyleId) continue
            if (!isSearchMatched(value.name, searchStr)) continue

            return {
                libraryId: '',
                libraryName: translation('UnknownLibrary'),
                docId: value.publishFile ?? '',
                value,
                groups: groupStyleVOByName<LayoutGridStyleNode>([value]),
            }
        }
        return undefined
    }, [inUseUnknownStyleNodeItems, searchStr, selectStyleId])

    const isNoStyle = localStyleMap.length === 0 && !hasRemoteStyle && inUseUnknownStyleNodeItems.length === 0

    const openStyleEditor = useMemo(() => {
        const multiPopup = popupState?.multiPopup
        if (!multiPopup || multiPopup.length === 0) {
            return false
        }
        const isOpen = multiPopup.some((v) => v.type === PopupStateType.POPUP_STATE_TYPE_LAYOUT_GRID_STYLE_SELECT)
        return isOpen && !!attrPanelStyleEditorState?.editingStyleId
    }, [popupState?.multiPopup, attrPanelStyleEditorState?.editingStyleId])

    const setDocumentPopupState = useCallback(
        (openPopup: boolean) => {
            const _popupState = modifiedMultiPopup(
                popupState,
                PopupStateType.POPUP_STATE_TYPE_LAYOUT_GRID_STYLE_SELECT,
                openPopup
                    ? {
                          type: PopupStateType.POPUP_STATE_TYPE_LAYOUT_GRID_STYLE_SELECT,
                          reciprocalIndex: -1,
                          multiPopup: [],
                      }
                    : undefined
            )
            command.invoke(cmdChangePopupState, _popupState)
        },
        [command, popupState]
    )
    const onFirstMove = useCallback(() => {
        hasMoved.current = true
    }, [])

    const onCloseEditor = useCallback(() => {
        setStyleIdEditor('')
        setRightClickStyleId('')
        setDocumentPopupState(false)
        command.invoke(cmdChangeAttrPanelStyleEditorState, {
            editingStyleId: '',
            openFromModule: attrPanelStyleEditorState?.openFromModule,
        })
    }, [attrPanelStyleEditorState?.openFromModule, command, setDocumentPopupState])

    const onOpenEditor = useCallback(
        (styleId: string, isCreate?: boolean) => {
            const passIds = command.DEPRECATED_invokeBridge(GetSelectionNodeIdsCommandForWasm).value ?? []
            command.invoke(cmdChangeAttrPanelStyleEditorState, {
                editingStyleId: styleId,
                openFromModule: attrPanelStyleEditorState?.openFromModule,
                openFromNodeId: passIds,
                isCreate,
            })
            setDocumentPopupState(true)
            setStyleIdEditor(styleId)
            setIsCreateStyle(false)
            setCreateStyle({ isCreate: false })
        },
        [command, setDocumentPopupState, attrPanelStyleEditorState]
    )
    const effectCreateStyle = useCallback(
        (styleId: string) => {
            onChangeStyle?.(styleId, false)
            const passIds = command.DEPRECATED_invokeBridge(GetSelectionNodeIdsCommandForWasm).value ?? []
            command.invoke(cmdChangeAttrPanelStyleEditorState, {
                editingStyleId: styleId,
                openFromModule: attrPanelStyleEditorState?.openFromModule,
                openFromNodeId: passIds,
                isCreate: true,
            })
            setStyleIdEditor(styleId)
        },
        [attrPanelStyleEditorState?.openFromModule, command, onChangeStyle]
    )

    const onClickAddStyle = useCallback(() => {
        const rect = titleContainerRef.current?.getBoundingClientRect()
        if (!rect) {
            return
        }
        setDocumentPopupState(true)
        setCreateStyle({
            isCreate: true,
            isMixed: props.isMixed,
            layoutGrids: (layoutGrids ?? []).slice(),
        })
        setPositionEditor({ left: rect.left, top: rect.top })
        setIsCreateStyle(true)
        setIsRemoteStyle(false)
    }, [layoutGrids, props.isMixed, setDocumentPopupState])

    const onClickApplyStyle = useCallback(
        (item: CommonStyleInfo) => {
            onCloseEditor()
            onChangeStyle?.(item.id, !hasMoved.current)
            command.commitUndo()
        },
        [command, onChangeStyle, onCloseEditor]
    )

    const onClickHoverIcon = useCallback(
        (
            { id }: any,
            containerDomRect: any,
            remoteStyleId?: string,
            docId?: string,
            name?: string,
            fromFig?: boolean
        ) => {
            setIsRemoteStyle(!!remoteStyleId)
            setRemoteStyleInfo({ styleId: remoteStyleId, docId, name, fromFig })
            styleIdEditor === id ? onCloseEditor() : onOpenEditor(id)
            setPositionEditor({ left: containerDomRect.left, top: containerDomRect.top })
        },
        [onCloseEditor, onOpenEditor, styleIdEditor]
    )

    const onContextMenu = useCallback(
        ({ id }: any, e: any, remoteStyleId?: string, docId?: string, name?: string, fromFig?: boolean) => {
            e.stopPropagation()
            e.preventDefault()
            const { clientX, clientY } = e
            setIsRemoteStyle(!!remoteStyleId)
            setRemoteStyleInfo({ styleId: remoteStyleId, docId, name, fromFig })
            setContextMenuInfo({
                styleNodeId: id,
                rectContainer: {
                    top: clientY,
                    bottom: clientY,
                    right: clientX,
                    left: clientX,
                },
            })
        },
        []
    )

    const onCloseContextMenu = useCallback(() => {
        setRightClickStyleId('')
        setContextMenuInfo(null)
    }, [])

    const editStyle = useCallback(
        (e?: any) => {
            e?.stopPropagation()
            if (!contextMenuInfo) {
                return
            }
            setContextMenuInfo(null)
            const {
                rectContainer: { left, top },
                styleNodeId,
            } = contextMenuInfo
            onOpenEditor(styleNodeId)
            setPositionEditor({ left, top })
        },
        [contextMenuInfo, onOpenEditor]
    )

    const deleteStyle = useCallback(
        (e?: any) => {
            e?.stopPropagation()
            if (!contextMenuInfo) {
                return
            }
            setContextMenuInfo(null)
            command.invoke(cmdDeleteStyleNode, contextMenuInfo.styleNodeId)
            command.commitUndo()
        },
        [command, contextMenuInfo]
    )
    const componentService = useComponentService()
    const jumpToOrigin = useCallback(
        (e?: any) => {
            e.stopPropagation()
            componentService.jumpToOriginDocument({
                docId: remoteStyleInfo?.docId,
                nodeId: remoteStyleInfo?.styleId,
                name: remoteStyleInfo?.name,
                fromFig: remoteStyleInfo?.fromFig,
            })
        },
        [
            componentService,
            remoteStyleInfo?.docId,
            remoteStyleInfo?.styleId,
            remoteStyleInfo?.name,
            remoteStyleInfo?.fromFig,
        ]
    )

    useEffect(() => {
        if (!open) {
            setSearchStr('')
            hasMoved.current = false
        }
    }, [open])

    return {
        open,
        position,
        localStyleMap,
        remoteStyleList,
        isNoStyle,
        hasRemoteStyle,
        onClickAddStyle,
        onChangeSearchInput: setSearchStr,
        showStyleMap,
        onContextMenu,
        onClickApplyStyle,
        selectStyleId,
        onClickHoverIcon,
        styleIdEditor,
        openStyleEditor,
        positionEditor,
        onCloseEditor,
        contextMenuInfo,
        onCloseContextMenu,
        editStyle,
        deleteStyle,
        onFirstMove,
        jumpToOrigin,
        isRemoteStyle,
        unknownStyleGroup,
        rightClickStyleId,
        setRightClickStyleId,
        titleContainerRef,
        isCreateStyle,
        createStyle,
        effectCreateStyle,
    }
}
