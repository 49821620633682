/* eslint-disable no-restricted-imports */
import { UpdateSelectionStrokeAlignCommand, Wukong } from '@wukong/bridge-proto'
import { useCallback } from 'react'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'

export function useStrokeAlign() {
    const command = useCommand()
    const selectionStroke = useViewState('selectionStrokeV2')
    const alignState = selectionStroke?.alignState

    const onChangeStrokeAlign = useCallback(
        (value: Wukong.DocumentProto.StrokeAlign) => {
            command.DEPRECATED_invokeBridge(UpdateSelectionStrokeAlignCommand, { strokeAlign: value })
            command.commitUndo()
        },
        [command]
    )

    return {
        alignState,
        onChangeStrokeAlign,
    }
}
