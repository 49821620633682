import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    ChangeToSRGB: '更改为 sRGB',
    ChangeToP3: '更改为 Display P3',
    ConvertToSRGB: '更改为 sRGB',
    ConvertToP3: '更改为 Display P3',
    AssignSRGB: '更改为 sRGB',
    AssignP3: '更改为 Display P3',
    WEPLUC: '保留色值',
    CAsQQu: '保留相同的颜色值，颜色的显示效果会因此改变。',
    jZjtrb: '保留外观（转换色值）',
    ChangeValuesToSRGB: '将色值（比如 Hex 值）转换为 sRGB 内最接近的对应色值，颜色会看起来一样。',
    ChangeValuesToP3: '将色值（比如 Hex 值）转换为 Display P3 内最接近的对应色值，颜色会看起来一样。',
    GMZmEi: '由于 sRGB 的色域小于 Display P3，某些颜色可能会被转换至一个相近的颜色。',
    YuKwrA: '仍使用',
    NotSupportedSRGB: '虽然你的显示器不支持 sRGB，但颜色配置文件仍然会对此文件生效。',
    NotSupportedP3: '虽然你的显示器不支持 Display P3，但颜色配置文件仍然会对此文件生效。',
    SupportSRGB: '你的 {{name}} 显示器支持 sRGB，你可以切换到该显示器使用 {{motiffName}}。',
    SupportP3: '你的 {{name}} 显示器支持 Display P3，你可以切换到该显示器使用 {{motiffName}}。',
    AppliedSRGB: '虽然你的浏览器不支持 sRGB，但颜色配置文件仍然会对此文件生效。',
    AppliedP3: '虽然你的浏览器不支持 Display P3，但颜色配置文件仍然会对此文件生效。',
    LearnMore: '了解更多',
} as const

export const enTranslation = {
    ChangeToSRGB: 'Change color profiles to sRGB',
    ChangeToP3: 'Change color profiles to Display P3',
    ConvertToSRGB: 'Convert to sRGB',
    ConvertToP3: 'Convert to Display P3',
    AssignSRGB: 'Assign sRGB',
    AssignP3: 'Assign Display P3',
    WEPLUC: 'Keep values (Assign)',
    CAsQQu: 'Keep the same color values, which changes how colors appear.',
    jZjtrb: 'Keep appearence (Convert)',
    ChangeValuesToSRGB:
        'Convert values (such as Hex codes) to its closest equivalent within sRGB, and the color will appear the same.',
    ChangeValuesToP3:
        'Convert values (such as Hex codes) to its closest equivalent within Display P3, and the color will appear the same.',
    GMZmEi: 'Some colors might be clipped. ',
    YuKwrA: 'Keep',
    NotSupportedSRGB: 'The color profile will be applied, even though your display does not support sRGB colors.',
    NotSupportedP3: 'The color profile will be applied, even though your display does not support Display P3 colors.',
    SupportSRGB: 'Switch to your {{name}} display for sRGB support.',
    SupportP3: 'Switch to your {{name}} display for Display P3 support.',
    AppliedSRGB: 'The color profile will be applied, even though your browser does not support sRGB colors.',
    AppliedP3: 'The color profile will be applied, even though your browser does not support Display P3 colors.',
    LearnMore: 'Learn more',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
