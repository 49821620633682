import { translation } from './tool-title-menu.translation'
/* eslint-disable no-restricted-imports */
import {
    UpdateBatchExportPopupStateCommandWasmCall,
    UpdateDevModeBatchExportPopupStateCommandWasmCall,
    Wukong,
} from '@wukong/bridge-proto'
import { useState } from 'react'
import { DropdownV2, wkDialogConfirm, WKToast } from '../../../../../../ui-lib/src'
import { isEnglishLanguage, RouteToken, useWKNavigate } from '../../../../../../util/src'
import { CommitType } from '../../../../document/command/commit-type'
import { ShortcutKey, shortcutLabelMap } from '../../../../kernel/interface/shortcut-key'
import { RoleStatus } from '../../../../kernel/interface/type'
import { compareRole, deleteDocRight } from '../../../../kernel/interface/user-roles'
import { BatchTrashDocRequest } from '../../../../kernel/request/document'
import { batchAddFavoriteDocs, batchRemoveFavoriteDocs } from '../../../../kernel/request/favorite'
import {
    useCommand,
    useCopyCheckService,
    useHistoryService,
    usePlanAndUserStateService,
} from '../../../../main/app-context'
import { useEnterHistoryMode, useLeaveHistoryMode } from '../../../../main/history-mode'
import { BatchMoveDocModal } from '../../../../share/batch-move-doc-modal'
import { isLockedOrgPlan } from '../../../../utils/plan-status-checker'
import { useViewState } from '../../../../view-state-bridge'
import { useDocInfoContext } from '../../../context/top-area-context'
import { frogExposeToolsMenusAndActionsMenuDisplay } from '../../../utils/tools-actions-frog'
import { useColorProfileMenuCore } from '../../color-profile'
import { logs } from '../../dev-mode/logs'
import style from './tool-title.module.less'
import { useCopyFile } from './use-copy-file'

export enum ToolTitleMenuType {
    Copy,
    Rename,
    Favorite,
    Delete,
    History,
    BatchExport,
    MoveTo,
    DocumentColorProfileToCurrent,
    DocumentColorProfileToNext,
}

export const ToolTitleMenu = (props: { clickCallback: (p: ToolTitleMenuType) => void; isDevMode?: boolean }) => {
    const navigate = useWKNavigate()
    const { docData, fetchDocData, getCurrentDocOrganization } = useDocInfoContext()
    const { copyFile } = useCopyFile()
    const { clickCallback } = props

    const docReadonly = useViewState('docReadonly')
    const enterHistoryMode = useEnterHistoryMode()
    const leaveHistoryMode = useLeaveHistoryMode()
    const historyService = useHistoryService()
    const isHistoryMode = historyService.useZustandStore.use.isHistoryMode()
    const command = useCommand()
    const [batchMoveModalVisible, setBatchMoveModalVisible] = useState(false)
    const planAndUserStateService = usePlanAndUserStateService()
    const planAndUserState = planAndUserStateService.useZustandStore.use.planAndUserState()

    const colorProfileMenuCore = useColorProfileMenuCore()

    const copyCheckService = useCopyCheckService()
    const copyHasBeenProhibited = copyCheckService.useZustandStore.use.copyHasBeenProhibited()
    const state = useViewState('topArea')
    const isEditVector = state?.isEditVector
    const editorType = useViewState('editorType')

    if (!docData) {
        return <></>
    }
    const isFreezedOrg = planAndUserState && isLockedOrgPlan(planAndUserState)
    const showRename = !docReadonly && !props.isDevMode

    const showRemove = !docReadonly && !props.isDevMode

    const showColorProfile = !docReadonly && !props.isDevMode

    const showMoveTo =
        !props.isDevMode &&
        !!(
            compareRole(docData.role, RoleStatus.Viewer) > 0 && compareRole(docData.folderRole, RoleStatus.Viewer) > 0
        ) &&
        !isFreezedOrg

    const onClickMenu = async (type: ToolTitleMenuType) => {
        let message
        switch (type) {
            case ToolTitleMenuType.History:
                // 避免闪动问题
                setTimeout(isHistoryMode ? leaveHistoryMode : enterHistoryMode, 0)
                break
            case ToolTitleMenuType.Copy:
                if (copyHasBeenProhibited) {
                    WKToast.show(`${translation('ProhibitCopy')}`)
                } else {
                    copyFile()
                }
                break
            case ToolTitleMenuType.Rename:
                // clickCallback
                break
            case ToolTitleMenuType.Favorite:
                if (docData.favorites) {
                    await batchRemoveFavoriteDocs([{ id: docData.id, isPrototype: false }], docData.orgId)
                    message = translation('FileRemovedTo')
                } else {
                    await batchAddFavoriteDocs([{ id: docData.id, isPrototype: false }], docData.orgId)
                    message = translation('FileAddedTo')
                }
                WKToast.show(message)
                fetchDocData()
                break
            case ToolTitleMenuType.Delete:
                wkDialogConfirm.show({
                    title: `${translation('Delete', { docName: docData.name })}`,
                    content: (
                        <span>
                            {translation('PeopleWithEdit')}
                            {isEnglishLanguage() ? <strong>Trash.</strong> : ''}
                        </span>
                    ),
                    okButtonProps: { danger: true },
                    onOk: async () => {
                        await new BatchTrashDocRequest([docData.id]).start()
                        navigate(`${RouteToken.Recent}`)
                    },
                    okText: translation('Delete_synonyms2'),
                })
                break
            case ToolTitleMenuType.BatchExport:
                if (copyHasBeenProhibited) {
                    WKToast.show(translation('ProhibitExport'))
                    break
                }
                if (props.isDevMode) {
                    logs.DevMode.exportDialogDisplay({ interaction_type: 'click' })
                    command.DEPRECATED_invokeBridge(
                        UpdateDevModeBatchExportPopupStateCommandWasmCall,
                        Wukong.DocumentProto.BridgeProtoBoolean.create({
                            value: true,
                        })
                    )
                } else {
                    // 关闭下拉菜单后再展示Modal
                    setTimeout(() => {
                        command.invokeBridge(CommitType.Noop, UpdateBatchExportPopupStateCommandWasmCall, {
                            value: true,
                        })
                    }, 0)
                }
                break
            case ToolTitleMenuType.MoveTo:
                setBatchMoveModalVisible(true)
                break
            case ToolTitleMenuType.DocumentColorProfileToCurrent:
                // noop
                break
            case ToolTitleMenuType.DocumentColorProfileToNext:
                colorProfileMenuCore.openToNext()
                break
            default:
                break
        }
        clickCallback(type)
    }

    return (
        <>
            {batchMoveModalVisible && (
                <BatchMoveDocModal
                    docs={[docData]}
                    onClose={() => setBatchMoveModalVisible(false)}
                    forceHideTipBanner={true}
                    organization={getCurrentDocOrganization()!}
                />
            )}
            <div className={style.more}>
                <DropdownV2.MinimalMultiLevel
                    onOpen={() => {
                        frogExposeToolsMenusAndActionsMenuDisplay({
                            doc_mode: {
                                is_readonly: !!docReadonly,
                                is_dev_mode: editorType === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV,
                                is_history_version: !!isHistoryMode,
                                is_vector: !!isEditVector,
                            },
                            displayed_menu: '文件下拉菜单',
                        })
                    }}
                    items={[
                        {
                            name: isHistoryMode ? translation('HideVersionHistory') : translation('ShowVersionHistory'),
                            dataTestId: 'tool-title-menu-history-view',
                            customNode: {
                                value: ToolTitleMenuType.History,
                            },
                        },
                        {
                            name: `${translation('Export')}...`,
                            hidden: isHistoryMode,
                            customNode: {
                                value: ToolTitleMenuType.BatchExport,
                                shortcut: shortcutLabelMap[ShortcutKey.BatchExport],
                            },
                        },
                        {
                            name: '',
                            disabled: true,
                            customNode: {
                                autoSplitLine: true,
                            },
                        },
                        {
                            name: translation('FileColorProfile'),
                            hidden: !showColorProfile,
                            children: [
                                {
                                    name: colorProfileMenuCore.currentName,
                                    customNode: {
                                        value: ToolTitleMenuType.DocumentColorProfileToCurrent,
                                        customItemProps: {
                                            isSelect: true,
                                        },
                                    },
                                },
                                {
                                    name: colorProfileMenuCore.nextName,
                                    customNode: {
                                        value: ToolTitleMenuType.DocumentColorProfileToNext,
                                    },
                                },
                            ],
                        },
                        {
                            name: '',
                            disabled: true,
                            hidden: !showColorProfile,
                            customNode: {
                                autoSplitLine: true,
                            },
                        },
                        {
                            name: translation('Duplicate'),
                            hidden: isFreezedOrg,
                            customNode: {
                                value: ToolTitleMenuType.Copy,
                            },
                        },
                        {
                            name: translation('Rename'),
                            disabled: compareRole(docData.role, RoleStatus.Viewer) <= 0,
                            hidden: !showRename,
                            customNode: {
                                value: ToolTitleMenuType.Rename,
                            },
                        },
                        {
                            name: docData.favorites
                                ? translation('RemoveFromFavorites')
                                : translation('AddToFavorites'),
                            customNode: {
                                value: ToolTitleMenuType.Favorite,
                            },
                        },
                        {
                            name: translation('MoveTo...'),
                            hidden: !showMoveTo,
                            customNode: {
                                value: ToolTitleMenuType.MoveTo,
                            },
                        },
                        {
                            name: translation('Delete_synonyms1'),
                            disabled: !deleteDocRight(docData.role, docData.folderRole),
                            hidden: !showRemove,
                            customNode: {
                                value: ToolTitleMenuType.Delete,
                            },
                        },
                    ]}
                    onChange={(v) => v.customNode?.value !== undefined && onClickMenu(v.customNode.value)}
                    className={style.arrowClassNameV2}
                    onlyArrow
                    placement="bottom center"
                    dataTestIds={{ triggerFocus: 'tool-title-menu', container: 'tool-title-menu-item-container' }}
                />
            </div>
        </>
    )
}
