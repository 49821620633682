import { isEnglishLanguage } from '../../../../../../util/src'
import { useMemo, useState } from 'react'
import { fetchCreateApplication } from '../pay-apply-dialog-request'
import { BoldText } from './bold-text'
import { PayApplyDialogContainer1 } from './pay-apply-dialog-container'
import { AutoPayApplyProps } from './type'
import { getSeatTypeFromPayApplyDialogSeatType, getUINameFromPayApplyDialogSeatType } from './utils'

export function AutoPayApply(props: AutoPayApplyProps) {
    const { seatType, dialogProps, httpProps, description } = props
    const [loading, setLoading] = useState<boolean>(false)

    const content = useMemo(() => {
        return description ? (
            description
        ) : isEnglishLanguage() ? (
            <>
                This is a <BoldText>billable event</BoldText>, you will upgrade yourself to a &quot;
                {getUINameFromPayApplyDialogSeatType(seatType)}&quot;.
            </>
        ) : (
            <>
                这是一个<BoldText>计费动作</BoldText>，你将升级自己为「
                {getUINameFromPayApplyDialogSeatType(seatType)}」。
            </>
        )
    }, [description, seatType])

    const onOk = async () => {
        setLoading(true)
        await fetchCreateApplication({
            resourceType: httpProps.resourceType,
            resourceId: httpProps.resourceId,
            seatType: getSeatTypeFromPayApplyDialogSeatType(seatType),
            reason: '',
            adminUserIds: [],
            triggerScene: httpProps.triggerScene,
        }).finally(() => {
            setLoading(false)
        })
        dialogProps?.onOk?.()
    }
    return (
        <PayApplyDialogContainer1
            {...dialogProps}
            onOk={onOk}
            dataTestIds={{ description: 'auto-pay-apply-dialog' }}
            okButtonProps={{ ...dialogProps?.okButtonProps, loading }}
        >
            {content}
        </PayApplyDialogContainer1>
    )
}
