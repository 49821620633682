/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { useCallback, useMemo, useState } from 'react'
import { Position } from '../../../../../../ui-lib/src'
import { cmdChangePopupState } from '../../../../document/command/document-command'
import { cmdChangeAttrPanelStyleEditorState } from '../../../../document/command/node-props-command'
import type { AttrPanelStyleEditorState, PopupState } from '../../../../document/node/node'
import { PopupStateType } from '../../../../document/node/node'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { useTextStyleModel } from './hooks/use-text-style-model'

function useTextStylePopup() {
    const command = useCommand()
    const popupState = useViewState('popupState')
    const attrPanelStyleEditorState = useViewState('attrPanelStyleEditorState')

    const [stylePosition, setStylePosition] = useState<Position>()

    const openStyle = useMemo(() => {
        return (
            popupState?.type === PopupStateType.POPUP_STATE_TYPE_TEXT_STYLE &&
            attrPanelStyleEditorState?.openFromModule ===
                Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_TEXT
        )
    }, [attrPanelStyleEditorState?.openFromModule, popupState?.type])

    const setDocumentState = useCallback(
        (openPopup: boolean) => {
            let _attrPanelStyleEditorState: AttrPanelStyleEditorState | null = null
            const _popupState: PopupState = {
                type: PopupStateType.POPUP_STATE_TYPE_NONE,
                reciprocalIndex: -1,
                multiPopup: [],
            }
            if (openPopup) {
                _attrPanelStyleEditorState = {
                    openFromModule: Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_TEXT,
                    openFromNodeId: [],
                    selectReverseIndex: [],
                    isCreate: false,
                }
                _popupState.type = PopupStateType.POPUP_STATE_TYPE_TEXT_STYLE
            }

            command.invoke(cmdChangeAttrPanelStyleEditorState, _attrPanelStyleEditorState)
            command.invoke(cmdChangePopupState, _popupState)
        },
        [command]
    )

    const switchOpenStyleState = useCallback(
        (state?: boolean) => {
            const _openStyle = state !== undefined ? state : !openStyle
            setDocumentState(_openStyle)
        },
        [openStyle, setDocumentState]
    )

    return { openStyle, switchOpenStyleState, setStylePosition, stylePosition }
}

export function useTextStyle() {
    const { openStyle, switchOpenStyleState, setStylePosition, stylePosition } = useTextStylePopup()

    const {
        modelState: { textStyleState },
        modelCommand,
    } = useTextStyleModel()

    const onClickStyle = useCallback(
        (e: any) => {
            const { bottom } = e.currentTarget.getBoundingClientRect()
            const { clientWidth } = document.documentElement
            setStylePosition({ top: bottom + 8, left: clientWidth - 16 })
            switchOpenStyleState()
        },
        [setStylePosition, switchOpenStyleState]
    )

    const onChangeStyle = useCallback(
        (styleNodeId: string, isCreateStyle: boolean) => {
            if (isCreateStyle) {
                switchOpenStyleState(false)
            }

            modelCommand.changeStyleId(styleNodeId)
        },
        [modelCommand, switchOpenStyleState]
    )

    const onClickAdd = useCallback(() => {
        modelCommand.changeStyleId(textStyleState.textStyleNode?.id || '')
    }, [modelCommand, textStyleState.textStyleNode?.id])

    const isMissStyle = textStyleState.textStyleNode?.missFontInfo?.isMissStyle ?? false

    return {
        selectTextStyleNodeInfo: textStyleState,
        onClickCutLink: modelCommand.detachStyleId,
        onChangeStyle,
        openStyle,
        stylePosition,
        onClickStyle,
        onClickAdd,
        isMissStyleFamily: isMissStyle,
    }
}
