/* eslint-disable no-restricted-imports */
import { Scrollbar } from '../../../../../ui-lib/src'
import { Item } from './data'
import classes from './tab-container.module.less'
import { TabItem } from './tab-item'

export interface TabContainerProps {
    left?: Item[]
    center?: Item[]
    right?: Item[]
}
export function TabContainer(props: TabContainerProps) {
    const { left, center, right } = props
    return (
        <Scrollbar>
            <div className={classes.tabContainer}>
                {!left ? null : (
                    <div className={classes.left}>
                        {left.map((v) => (
                            <TabItem key={v.name} item={v} />
                        ))}
                    </div>
                )}
                {!center ? null : (
                    <div className={classes.center}>
                        {center.map((v) => (
                            <TabItem key={v.name} item={v} />
                        ))}
                    </div>
                )}
                {!right ? null : (
                    <div className={classes.right}>
                        {right.map((v) => (
                            <TabItem key={v.name} item={v} />
                        ))}
                    </div>
                )}
            </div>
        </Scrollbar>
    )
}
