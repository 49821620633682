import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    ApplyDevSeat: '申请席位',
    OpenDevMode: '开启研发模式',
    StartUse: '开始使用',
    DevModeBannerDesc:
        '使用研发模式，获取更专注的研发视角、更详细的标注展示。使用自动识别图标、导出预设、倍率切换等功能。',
    SeatIsApplying: '席位申请中... ',
    ReminderInWaittingToApproved: '你已发送申请，可以联系管理员尽快审批。',
    ReminderInGracePeriod: '你可以在等待审批期间先行使用最长 14 天。',
    ReminderInNeedingReApplySub1: '使用研发模式需要向管理员申请',
    ReminderInNeedingReApplySub2: '「研发席位」。',
    UnableToEditFile: '无法编辑文件...',
    UnableToEditFileReason:
        '当前团队已超过基础版项目文件数量上限。升级付费版后可以无限创建项目和文件，并在团队内共享样式和组件。',
    ViewPlans: '查看付费版本',

    UnableUseDevMode: '无法使用研发模式...',
    UnableUseDesignMode: '无法编辑文件...',
    FreezeOfTeamMember: '该专业版团队因为账单逾期已被锁定，请联系团队管理员了解详情。',
    FreezeOfTeamAdmin: '该专业版团队因为账单逾期已被锁定。',
    FreezeOfOrgMember: '当前企业因为账单逾期已被锁定，请联系企业管理员了解详情。',
    FreezeOfOrgAdmin: '当前企业因为账单逾期已被锁定。',
    FreezeOfTrialOrgMember: '当前企业因为试用到期已被锁定，请联系企业管理员了解详情。',
    FreezeOfTrialOrgAdmin: '当前企业因为试用到期已被锁定。',
} as const

export const enTranslation = {
    ApplyDevSeat: 'Request seat',
    OpenDevMode: 'Get Develop Mode',
    StartUse: 'Try now',
    DevModeBannerDesc:
        'Get a more developer-focused view and detailed inspection display. Use automatic icon detection, export preset, and unit & scale settings.',
    SeatIsApplying: 'Upgrade request pending... ',
    ReminderInWaittingToApproved: 'You have sent a request. Contact the admin for prompt approval.',
    ReminderInGracePeriod: 'While you wait for approval, you can keep using Develop Mode for up to 14 days.',

    ReminderInNeedingReApplySub1: 'To use Develop Mode, you need to request a ',
    ReminderInNeedingReApplySub2: 'Developer seat.',
    UnableToEditFile: 'Unable to edit the file...',
    UnableToEditFileReason:
        'The Starter plan only comes with 1 project and 3 files. Upgrade to Professional plan to create unlimited number of projects and files, and build a shared library for your team.',
    ViewPlans: 'View plans',

    UnableUseDevMode: 'Unable to use Develop Mode...',
    UnableUseDesignMode: 'Unable to edit files...',

    FreezeOfTeamMember:
        'This Professional team has been locked because of an overdue invoice. Ask your team admin for more details.',
    FreezeOfTeamAdmin: 'This Professional team has been locked because of an overdue invoice.',
    FreezeOfOrgMember:
        'The current organization has been locked because of an overdue invoice. Ask your organization admin for more details.',
    FreezeOfOrgAdmin: 'The current organization has been locked because of an overdue invoice.',
    FreezeOfTrialOrgMember:
        'The current organization has been locked because the trial has expired. Ask your organization admin for more details.',
    FreezeOfTrialOrgAdmin: 'The current organization has been locked because the trial has expired.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
