import { FeatureSwitchConfig, SwitchEnvironmentScopeType, SwitchStrategyType } from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'ai-gen-change-base-frame-name',
    owner: '',
    description: '',
    strategies: [
        {
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                userList: [
                    '8924f0f3c2562122c6dce85e95a7e585a82ba76c',
                    '07de847f05c0a1ab68862dd89660254ba39d540c',
                    'a016fbbfa2e137ebffd52005dccbad8d7e6df177',
                    'eff45a3f83f3209c6223ed22924154d913aa48ce',
                    '20dff2aaca522ac44bf55db0acda5212222716a4',
                    'c25a7c619bd3d207ef342fb9328434439de70bd6',
                ],
            },
            env: SwitchEnvironmentScopeType.ALL,
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
