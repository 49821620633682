import { useUnmountHref } from '../../../../../../ui-lib/src'
import { useMount } from 'react-use'
import { WKFrogTask } from '../../../wk-frog-task'
import { PriceComparisonAll } from './price-comparison-all'
import { PriceComparisonProfessional } from './price-comparison-professional'
import { PayUpgradeDialogStructProps, PayUpgradeDialogType } from './type'

export function PayUpgradeDialogStruct(props: PayUpgradeDialogStructProps) {
    useUnmountHref(props.dialogProps?.onCancel)

    useMount(() => {
        WKFrogTask.payment.UpgradePopup()
    })

    return props.type === PayUpgradeDialogType.All ? (
        <PriceComparisonAll {...props} />
    ) : props.type === PayUpgradeDialogType.Professional ? (
        <PriceComparisonProfessional {...props} />
    ) : (
        <></>
    )
}
