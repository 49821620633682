import {
    ChangeCanvasColorSpaceCommand,
    SyncDocumentColorProfileMetaCommand,
    UpdateDocumentColorProfileCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { createSelectors, createStore } from '../../../../util/src'
import { EffectController } from '../../../../util/src/effect-controller'
import { environment } from '../../environment'
import { EmBridge } from '../../kernel/bridge/em-bridge'
import { CanvasStateType } from '../../kernel/service/canvas-types'
import { ServiceClass } from '../../kernel/util/service-class'
import { WK } from '../../window'
import { getIntegrationTestSupports } from '../supports'

// 颜色配置
export interface MonitorInfo {
    name: string
    canRepresentDisplayP3: boolean
}
export interface MonitorsInfo {
    monitors: Array<MonitorInfo>
}

export class MutableStateFlow<T> {
    private _listeners: Set<(_value: T) => void> = new Set()
    constructor(private _value: T) {}

    subscribe(listener: (value: T) => void) {
        this._listeners.add(listener)
        listener(this._value)
    }

    emit(value: T) {
        this._value = value
        for (const listener of [...this._listeners]) {
            listener(this._value)
        }
    }

    destroy() {
        this._listeners.clear()
    }
}

type MonitorDisplayP3SupportedLevelValue<T> = MutableStateFlow<T>

export interface IColorProfileSupports {
    ensureInitialized: () => void
    isHostSupportDisplayP3: () => boolean
    getMonitorInfos: () => Promise<MonitorsInfo | null>
    buildMonitorDisplayP3SupportedLevelListener: () => {
        value$: MonitorDisplayP3SupportedLevelValue<boolean>
        refresh: () => void
    }
    destroy: () => void
}

// 把和浏览器交互的部分抽出来，测试里可以覆盖
export class ColorProfileSupports extends ServiceClass implements IColorProfileSupports {
    private isCanvas2DSupportDisplayP3_ = false
    private isCanvasWebGLSupportDisplayP3_ = false
    private isCSSSupportDisplayP3_ = false

    constructor(private readonly controller: EffectController) {
        super()
        controller.onCleanup(() => this.destroy())
    }

    public ensureInitialized() {
        this.isCSSSupportDisplayP3_ = this.isCSSSupportDisplayP3()
        this.isCanvas2DSupportDisplayP3_ = this.isCanvas2DSupportDisplayP3()
        this.isCanvasWebGLSupportDisplayP3_ = this.isCanvasWebGLSupportDisplayP3()
    }

    public isHostSupportDisplayP3 = (): boolean => {
        // 需要 CSS 和 Canvas 都满足
        return this.isCSSSupportDisplayP3_ && this.isCanvas2DSupportDisplayP3_ && this.isCanvasWebGLSupportDisplayP3_
    }

    public getMonitorInfos = async (): Promise<MonitorsInfo | null> => {
        return new Promise<MonitorsInfo | null>((res, _rej) => {
            const handler = window.localBridge?.getMonitorsInfo
            if (!handler) {
                res(null)
                return
            }
            handler((value: any) => {
                res(value)
            })
        })
    }

    public buildMonitorDisplayP3SupportedLevelListener = () => {
        const { mediaQuery$: source$, refresh } = this.createMediaQuery$('(color-gamut: p3)')
        return {
            value$: source$,
            refresh,
        }
    }

    private createMediaQuery$ = (query: string) => {
        const getMatches = (query_: string): boolean => {
            // Prevents SSR issues
            if (typeof window !== 'undefined') {
                return window.matchMedia(query_).matches
            }
            return false
        }
        const mediaQuery$ = new MutableStateFlow<boolean>(getMatches(query))

        function handleChange() {
            mediaQuery$.emit(getMatches(query))
        }

        const matchMedia = window.matchMedia(query)

        // Listen matchMedia
        if (matchMedia.addListener) {
            matchMedia.addListener(handleChange)
        } else {
            matchMedia.addEventListener('change', handleChange)
        }

        this.controller.onCleanup(() => {
            if (matchMedia.removeListener) {
                matchMedia.removeListener(handleChange)
            } else {
                matchMedia.removeEventListener('change', handleChange)
            }
        })

        return { mediaQuery$, refresh: handleChange }
    }

    private isCSSSupportDisplayP3 = (): boolean => {
        if (globalThis?.CSS && typeof CSS.supports === 'function') {
            return CSS.supports('color', 'color(display-p3 1 1 1)')
        }
        return false
    }

    private isCanvas2DSupportDisplayP3 = (): boolean => {
        try {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d', {
                colorSpace: 'display-p3',
            })
            const isSupported = Boolean(ctx)
            canvas.remove()
            return isSupported
        } catch (e) {}
        return false
    }

    private isCanvasWebGLSupportDisplayP3 = (): boolean => {
        try {
            // 这个地方会修改 canvas 属性，故不使用 GlobalCanvas
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('webgl')
            if ((ctx as any)?.drawingBufferColorSpace) {
                // 能变更的话，那么支持 P3
                ;(ctx as any).drawingBufferColorSpace = 'display-p3'
                const isSupported = (ctx as any).drawingBufferColorSpace === 'display-p3'
                canvas.remove()
                return isSupported
            }
        } catch (e) {}
        return false
    }
}

export class ColorProfileService extends ServiceClass {
    private monitorSupportDisplayP3$: MonitorDisplayP3SupportedLevelValue<boolean>
    private refreshMonitorSupportDisplayP3: () => void
    private colorProfileSupports: IColorProfileSupports
    private colorSpace: PredefinedColorSpace = 'srgb'

    public states = createSelectors(
        createStore<{
            colorProfileState: Wukong.DocumentProto.DocumentColorProfile
            monitorSupportDisplayP3State: boolean
        }>(
            () => ({
                colorProfileState: Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B,
                monitorSupportDisplayP3State: false,
            }),
            environment.isDev
        )
    )

    constructor(protected override bridge: EmBridge, controller: EffectController) {
        super(bridge)
        controller.onCleanup(() => this.destroy())

        this.colorProfileSupports =
            getIntegrationTestSupports()?.colorProfileSupports ?? new ColorProfileSupports(controller)
        this.colorProfileSupports.ensureInitialized()
        const { value$, refresh } = this.colorProfileSupports.buildMonitorDisplayP3SupportedLevelListener()
        this.monitorSupportDisplayP3$ = value$
        this.refreshMonitorSupportDisplayP3 = refresh

        this.bindJsCall(ChangeCanvasColorSpaceCommand, (arg) => {
            const colorProfile =
                arg.colorProfile ?? Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B
            this.changeCanvasColorProfileForWasm(colorProfile)
        })

        WK.getColorSpace = () => this.colorSpace
    }

    public override destroy(): void {
        super.destroy()
        if (this.monitorSupportDisplayP3$ instanceof MutableStateFlow) {
            this.monitorSupportDisplayP3$.destroy()
        }
        this.colorProfileSupports.destroy()
        delete WK.getColorSpace
    }

    public afterEditorBootstrap = () => {
        this.monitorSupportDisplayP3$.subscribe((value) => {
            this.states.setState({
                monitorSupportDisplayP3State: value,
            })

            this.bridge.call(SyncDocumentColorProfileMetaCommand, {
                monitorSupportDisplayP3Level: value
                    ? Wukong.DocumentProto.DeviceSupportDisplayP3Level.DEVICE_SUPPORT_DISPLAY_P3_LEVEL_CURRENT
                    : Wukong.DocumentProto.DeviceSupportDisplayP3Level.DEVICE_SUPPORT_DISPLAY_P3_LEVEL_NONE,
                isHostSupportDisplayP3: this.isHostSupportDisplayP3(),
            })
        })
    }

    public updateDocumentColorProfile = (
        colorProfile: Wukong.DocumentProto.DocumentColorProfile,
        convertColor: boolean
    ) => {
        // 转换外观 比较慢，先等 Dialog 关掉再调用 WASM
        setTimeout(() => {
            if (this.isDestroy) {
                return
            }

            this.bridge.call(UpdateDocumentColorProfileCommand, {
                colorProfile,
                convertColor,
            })
        }, 40)
    }

    public isHostSupportDisplayP3 = (): boolean => {
        return this.colorProfileSupports.isHostSupportDisplayP3()
    }

    public getMonitorInfos = async () => {
        return this.colorProfileSupports.getMonitorInfos()
    }

    public refreshMonitorDisplayP3SupportedLevel = () => {
        this.refreshMonitorSupportDisplayP3()
    }

    private changeCanvasColorProfileForWasm = (colorProfile: Wukong.DocumentProto.DocumentColorProfile) => {
        this.states.setState({
            colorProfileState: colorProfile,
        })

        switch (colorProfile) {
            case Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B:
            default:
                this.colorSpace = 'srgb'
                break
            case Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILE_DISPLAY_P3:
                this.colorSpace = 'display-p3'
                break
        }

        const state = this.bridge.currentEditorService.getCanvasState()
        if (state?.type == CanvasStateType.WebGL) {
            state.context.drawingBufferColorSpace = this.colorSpace
        } else if (state?.type == CanvasStateType.WebGPU) {
            // noop
        }
    }
}
