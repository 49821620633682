import { WKToast } from '../../../../../../../ui-lib/src'
import { signalTimeout } from '../../../../../../../util/src/abort-controller/timers'
import { TraceableAbortSignal } from '../../../../../../../util/src/abort-controller/traceable-abort-controller'
import { ImageDownloadContext } from '../../../../../document/command/image-download-context'
import { DownloadProgressMessage } from '../download-progress-message/download-progress-message'
import { translation } from './export-sync-status-service.translation'

export class ExportSyncStatusService {
    private showToastId?: string

    constructor(
        private readonly signal: TraceableAbortSignal,
        private readonly imageDownloadContext: ImageDownloadContext
    ) {
        this.imageDownloadContext.onExportImageNumChange(this.signal, (exportNum) => {
            const syncProgress = Math.round((exportNum.succeeded / exportNum.total) * 100)
            const progressMsg = `${translation('DownloadingImages')} ${exportNum.succeeded} / ${exportNum.total}`

            if (syncProgress >= 0 && syncProgress < 100) {
                this.showOrUpdateToast(syncProgress, progressMsg)
            } else {
                this.onEnd()
            }
        })
    }

    public destroy() {
        this.closeToast()
    }

    private showOrUpdateToast = (syncProgress: number, progressMsg: string) => {
        const renderToastMsg = () => (
            <DownloadProgressMessage
                progress={syncProgress}
                progressMsg={progressMsg}
                onCancel={this.onCancel}
                onProgressEnd={this.onEnd}
            />
        )

        if (this.showToastId) {
            WKToast.updateMessage(this.showToastId, renderToastMsg())
        } else {
            this.showToastId = WKToast.show(renderToastMsg(), { duration: -1 })
        }
    }

    private onEnd = () => {
        signalTimeout(
            () => {
                this.closeToast()
            },
            100,
            { signal: this.signal }
        )
    }

    private onCancel = () => {
        this.closeToast()
        this.imageDownloadContext.cancelExportImage()
    }

    private closeToast = () => {
        if (this.showToastId) {
            WKToast.close(this.showToastId)
            this.showToastId = undefined
        }
    }
}
