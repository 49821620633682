/* eslint-disable no-restricted-imports */
import {
    AddStrokePaintsForSelectionCommandForWasm,
    AddStrokeStyleIdForSelectionCommandForWasm,
    GetSelectionNodeIdsCommandForWasm,
    RemoveStrokeForSelectedNodesWasmCall,
    UpdateSelectionStrokePaintsCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { cloneDeep, isNil } from 'lodash-es'
import { MouseEventHandler, useCallback, useMemo, useState } from 'react'
import { Position } from '../../../../../../../ui-lib/src'
import { CommitType } from '../../../../../document/command/commit-type'
import { cmdChangePopupState } from '../../../../../document/command/document-command'
import { cmdChangeAttrPanelStyleEditorState } from '../../../../../document/command/node-props-command'
import { cmdUpdateSelectedReverseIndex } from '../../../../../document/command/page-command'
import {
    AttrPanelStyleEditorState,
    ColorStop,
    ImagePaint,
    PopupState,
    PopupStateType,
    RGB,
    Transform,
} from '../../../../../document/node/node'
import { DeepRequired, useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'
import { useSelectionState } from '../../../../document/selection/use-selection-state'
import { getDefaultSolidPaint, isGradientType } from '../../../../utils/switch-paint-type'
import { useGradientEdit } from '../../../../utils/use-gradient-edit'
import { useImageEdit } from '../../../../utils/use-image-edit'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { getRightColorStopIndex } from '../../blend/blend-gradient/gradient-picker'
import { changePaintType } from '../../common/use-paint-type'

import PaintType = Wukong.DocumentProto.PaintType

export function useStrokePaint() {
    const { startEditingImage, endEditingImage } = useImageEdit()
    const { startEditingGradient, endEditingGradient } = useGradientEdit()
    const command = useCommand()
    const selectionStroke = useViewState('selectionStrokeV2')
    const selectedStrokes = useViewState('selectedStrokes')
    const strokePaintState = useMemo(() => {
        const paintState = { ...selectionStroke?.paintState }
        return paintState
    }, [selectionStroke])
    const { changeSelectionInfo } = useSelectionState()

    const popupState = useViewState('popupState')
    const attrPanelStyleEditorState = useViewState('attrPanelStyleEditorState')

    const [stylePosition, setStylePosition] = useState<Position>()
    const openStyle = useMemo(() => {
        return (
            popupState?.type === PopupStateType.POPUP_STATE_TYPE_PAINT_STYLE &&
            attrPanelStyleEditorState?.openFromModule ===
                Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_STROKE
        )
    }, [attrPanelStyleEditorState?.openFromModule, popupState?.type])

    const setDocumentState = useCallback(
        (openPopup: boolean) => {
            let _attrPanelStyleEditorState: AttrPanelStyleEditorState | null = null
            const _popupState: PopupState = {
                type: PopupStateType.POPUP_STATE_TYPE_NONE,
                reciprocalIndex: -1,
                multiPopup: [],
            }
            const passIds = command.DEPRECATED_invokeBridge(GetSelectionNodeIdsCommandForWasm).value ?? []
            if (openPopup) {
                _attrPanelStyleEditorState = {
                    openFromModule: Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_STROKE,
                    openFromNodeId: passIds,
                    selectReverseIndex: [],
                    isCreate: false,
                }
                _popupState.type = PopupStateType.POPUP_STATE_TYPE_PAINT_STYLE
            }

            command.invoke(cmdChangeAttrPanelStyleEditorState, _attrPanelStyleEditorState)
            command.invoke(cmdChangePopupState, _popupState)
        },
        [command]
    )

    const switchOpenStyleState = useCallback(
        (state?: boolean) => {
            const _openStyle = state !== undefined ? state : !openStyle
            setDocumentState(_openStyle)
        },
        [openStyle, setDocumentState]
    )

    const tryIntoGradientImageFromStyle = useCallback(() => {
        if (
            strokePaintState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED ||
            strokePaintState?.strokes?.length !== 1
        ) {
            return
        }
        const paint = strokePaintState.strokes[0]
        const passIds = command.invokeBridge(CommitType.Noop, GetSelectionNodeIdsCommandForWasm).value ?? []
        const nodeId = passIds[0]
        if (
            paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_LINEAR ||
            paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_RADIAL ||
            paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_ANGULAR ||
            paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_DIAMOND
        ) {
            const index = getRightColorStopIndex(paint.gradientStops)
            startEditingGradient(
                nodeId,
                [
                    {
                        type: Wukong.DocumentProto.PaintPositionType.PAINT_POSITION_TYPE_STROKE,
                        index: 0,
                    },
                ],
                index
            )
        } else if (paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_IMAGE_PAINT) {
            startEditingImage([nodeId], Wukong.DocumentProto.EditingPaintType.EDITING_PAINT_TYPE_STROKE, 0, true)
        }
    }, [command, startEditingGradient, startEditingImage, strokePaintState.strokes, strokePaintState?.type])

    const onClickStyle = useCallback(
        (e: any) => {
            const { top } = e.currentTarget.getBoundingClientRect()
            const { clientWidth } = document.documentElement
            setStylePosition({ top: top - 6, left: clientWidth - 248 })
            switchOpenStyleState()
            if (!openStyle) {
                tryIntoGradientImageFromStyle()
            }
        },
        [openStyle, switchOpenStyleState, tryIntoGradientImageFromStyle]
    )

    const isOpen = (index: number) => {
        if (popupState && popupState.type === PopupStateType.POPUP_STATE_TYPE_STROKES) {
            return popupState.reciprocalIndex === index
        }
        return false
    }

    const updateStrokes = useCallback(
        (paints: Wukong.DocumentProto.IPaint[], options?: InputOptionsForUndoSquash) => {
            command.DEPRECATED_invokeBridge(UpdateSelectionStrokePaintsCommand, {
                paints,
                skipEmpty: true,
            })
            command.commitUndo(options?.commitType)
        },
        [command]
    )

    const updatePaintProperties = (obj: { [key: string]: any }, index: number, options?: InputOptionsForUndoSquash) => {
        if (!strokePaintState || !Array.isArray(strokePaintState.strokes)) {
            return
        }
        if (obj.colorVar === undefined) {
            obj.colorVar = undefined
        }
        if (!isNil(obj.type)) {
            strokePaintState.strokes.splice(index, 1, obj as DeepRequired<Wukong.DocumentProto.IPaint>)
        } else {
            Object.assign(strokePaintState.strokes[index], { visible: true }, obj)
        }
        updateStrokes(strokePaintState.strokes, options)
    }

    const onSelectChange = (itemsIndex: number[]) => {
        command.invoke(cmdUpdateSelectedReverseIndex, 'strokes', itemsIndex)
    }

    const onDragDone = (items: Wukong.DocumentProto.IPaint[], selectedIndexList: number[]) => {
        command.invoke(cmdUpdateSelectedReverseIndex, 'strokes', selectedIndexList)
        updateStrokes(items)
    }

    const onClickSub = (index: number) => () => {
        if (Array.isArray(strokePaintState?.strokes)) {
            const newPaints = cloneDeep(strokePaintState?.strokes)!
            newPaints.splice(index, 1)
            if (popupState?.type === PopupStateType.POPUP_STATE_TYPE_STROKES) {
                command.invoke(cmdUpdateSelectedReverseIndex, 'clear', [])
            }
            updateStrokes(newPaints)
        }
    }

    const isShowAddPaintsBtn = useMemo(
        () =>
            !isNil(strokePaintState?.type) &&
            strokePaintState?.type !== Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_STYLE,
        [strokePaintState?.type]
    )
    const onClickAddPaint: MouseEventHandler = (e) => {
        e.stopPropagation()
        command.DEPRECATED_invokeBridge(AddStrokePaintsForSelectionCommandForWasm)
        command.commitUndo()
    }

    const onClickTitle: MouseEventHandler = (e) => {
        if (
            isShowAddPaintsBtn &&
            strokePaintState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_EMPTY
        ) {
            onClickAddPaint(e)
        }
    }

    const onChangeColorStops = (index: number) => (v: ColorStop[]) => {
        updatePaintProperties({ gradientStops: v }, index)
    }

    const onChangeTransform = (index: number) => (v: Transform) => {
        updatePaintProperties({ gradientTransform: v }, index)
    }

    const onChangeVisible = (index: number) => (visible: boolean) => {
        updatePaintProperties({ visible }, index)
    }

    const onChangeColor = (index: number) => (color: RGB, options?: InputOptionsForUndoSquash) => {
        updatePaintProperties({ color }, index, options)
    }

    const onChangeOpacity = (index: number) => (value: string | number, options?: InputOptionsForUndoSquash) => {
        updatePaintProperties({ opacity: Number(value) }, index, options)
    }
    const onChangeBlendMode = (index: number) => (value: string | number) => {
        updatePaintProperties({ blendMode: Number(value) }, index)
    }

    const onDetachColorVar = (index: number) => () => {
        updatePaintProperties({ colorVar: null }, index)
    }

    const onChangeColorVar = (index: number) => (colorVar: Wukong.DocumentProto.IVariableAliasData) => {
        updatePaintProperties({ ...getDefaultSolidPaint(), colorVar }, index)
    }

    const onChangePaints2ColorVar = (colorVar: Wukong.DocumentProto.IVariableAliasData) => {
        command.invokeBridge(CommitType.CommitUndo, UpdateSelectionStrokePaintsCommand, {
            paints: [{ ...getDefaultSolidPaint(), colorVar }],
            skipEmpty: false,
        })
    }

    const onChangePaints2 = (paints: Wukong.DocumentProto.IPaint[]) => {
        command.invokeBridge(CommitType.CommitUndo, UpdateSelectionStrokePaintsCommand, {
            paints,
            skipEmpty: false,
        })
        const passIds = command.invokeBridge(CommitType.Noop, GetSelectionNodeIdsCommandForWasm).value ?? []
        if (paints[0].type === PaintType.PAINT_TYPE_IMAGE_PAINT) {
            startEditingImage(passIds, Wukong.DocumentProto.EditingPaintType.EDITING_PAINT_TYPE_STROKE, 0)
            return
        }
        const nodeId = passIds[0]
        if (nodeId) {
            startEditingGradient(nodeId, [
                {
                    type: Wukong.DocumentProto.PaintPositionType.PAINT_POSITION_TYPE_STROKE,
                    index: 0,
                },
            ])
        }
    }

    const onChangePaints = (paints: Wukong.DocumentProto.IPaint[]) => {
        updateStrokes(paints)
    }

    const canShowWasmGradientControl = useMemo(() => {
        return (
            strokePaintState &&
            strokePaintState?.type !== Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED &&
            Array.isArray(strokePaintState.strokes) &&
            changeSelectionInfo.selectionCount === 1
        )
    }, [changeSelectionInfo.selectionCount, strokePaintState])

    const onChangePaintType =
        (index: number) => (nextPaintType: PaintType, paint: Wukong.DocumentProto.IPaint | undefined | null) => {
            if (!strokePaintState?.strokes) {
                return
            }

            const currentPaintType = strokePaintState.strokes[index]?.type
            if (isNil(currentPaintType)) {
                return
            }

            const reciprocalIndex = index

            command.DEPRECATED_invokeBridge(UpdateSelectionStrokePaintsCommand, {
                paints: changePaintType(
                    strokePaintState.strokes,
                    reciprocalIndex,
                    nextPaintType,
                    paint as DeepRequired<Wukong.DocumentProto.IPaint>
                ),
                skipEmpty: true,
            })

            const passIds = command.DEPRECATED_invokeBridge(GetSelectionNodeIdsCommandForWasm).value ?? []

            if (nextPaintType === PaintType.PAINT_TYPE_IMAGE_PAINT) {
                startEditingImage(
                    passIds,
                    Wukong.DocumentProto.EditingPaintType.EDITING_PAINT_TYPE_STROKE,
                    reciprocalIndex
                )
            } else if (currentPaintType === PaintType.PAINT_TYPE_IMAGE_PAINT) {
                endEditingImage()
            }

            if (canShowWasmGradientControl) {
                if (isGradientType(currentPaintType) && !isGradientType(nextPaintType)) {
                    endEditingGradient()
                } else if (isGradientType(nextPaintType) && !isGradientType(currentPaintType)) {
                    startEditingGradient(passIds[0], [
                        {
                            type: Wukong.DocumentProto.PaintPositionType.PAINT_POSITION_TYPE_STROKE,
                            index: reciprocalIndex,
                        },
                    ])
                }
            }

            command.commitUndo()
        }

    const onChangeImagePaint = (index: number) => (data: ImagePaint) => {
        updatePaintProperties(data, index)
    }
    const onChangeModalVisible = (index: number) => (visible: boolean) => {
        if (!strokePaintState?.strokes?.length) {
            return
        }
        const currentPaintType = strokePaintState.strokes[index]?.type
        if (visible) {
            const reciprocalIndex = index
            command.invoke(cmdChangePopupState, {
                type: PopupStateType.POPUP_STATE_TYPE_STROKES,
                reciprocalIndex,
                multiPopup: [],
            })
            const passIds = command.DEPRECATED_invokeBridge(GetSelectionNodeIdsCommandForWasm).value ?? []
            if (currentPaintType === PaintType.PAINT_TYPE_IMAGE_PAINT) {
                startEditingImage(
                    passIds,
                    Wukong.DocumentProto.EditingPaintType.EDITING_PAINT_TYPE_STROKE,
                    reciprocalIndex
                )
            } else if (canShowWasmGradientControl && isGradientType(currentPaintType)) {
                startEditingGradient(passIds[0], [
                    {
                        type: Wukong.DocumentProto.PaintPositionType.PAINT_POSITION_TYPE_STROKE,
                        index: reciprocalIndex,
                    },
                ])
            }
        } else if (isOpen(index)) {
            command.invoke(cmdChangePopupState, {
                type: PopupStateType.POPUP_STATE_TYPE_NONE,
                reciprocalIndex: -1,
                multiPopup: [],
            })
            if (currentPaintType === PaintType.PAINT_TYPE_IMAGE_PAINT) {
                endEditingImage()
            } else if (isGradientType(currentPaintType)) {
                endEditingGradient()
            }
        }
    }

    const onClickDeleteStyle = () => {
        command.DEPRECATED_invokeBridge(RemoveStrokeForSelectedNodesWasmCall)
        command.commitUndo()
    }

    const onClickCutLink = useCallback(() => {
        command.DEPRECATED_invokeBridge(UpdateSelectionStrokePaintsCommand, {
            paints: strokePaintState.paintStyleNode?.paints,
            skipEmpty: true,
        })
        command.commitUndo()
    }, [command, strokePaintState?.paintStyleNode?.paints])

    const onChangeStyle = useCallback(
        (styleNodeId: string, needClosePopup: boolean) => {
            command.DEPRECATED_invokeBridge(AddStrokeStyleIdForSelectionCommandForWasm, { value: styleNodeId })
            if (needClosePopup) {
                switchOpenStyleState(false)
            }
            command.commitUndo()
        },
        [switchOpenStyleState, command]
    )

    return {
        selectedStrokes,
        isShowAddPaintsBtn,
        strokePaintState,
        onSelectChange,
        onDragDone,
        onClickSub,
        onClickAddPaint,
        onClickTitle,
        onChangeVisible,
        onChangeColor,
        onChangeOpacity,
        onChangeBlendMode,
        onDetachColorVar,
        onChangeImagePaint,
        onChangeModalVisible,
        isOpen,
        onChangePaintType,
        onChangeColorStops,
        onChangeTransform,
        onClickDeleteStyle,
        onClickCutLink,
        onChangeStyle,
        openStyle,
        stylePosition,
        onClickStyle,
        switchOpenStyleState,
        onChangeColorVar,
        onChangePaints2ColorVar,
        onChangePaints,
        onChangePaints2,
    }
}
