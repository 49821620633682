export enum OperatorType {
    NONE = 0,
    OPERATOR = 1,
    NUMBER = 2,
    MIXED_VARIABLE = 3,
}
// 能识别的运算符
export const inputOperator = {
    '(': 1,
    ')': 2,
    '+': 3,
    '-': 4,
    '*': 5,
    '/': 6,
    '^': 7,
    '%': 8,
    x: 9,
    X: 9,
    px: 10,
}
// 运算符 在运算符的信息集合的位置
export enum operatorListIndex {
    NONE = 0,
    PAREN_L = 1,
    PAREN_R = 2,
    ADD = 3,
    SUBTRACT = 4,
    MULTIPLY = 5,
    DIVIDE = 6,
    UNARY_PLUS = 7,
    UNARY_MINUS = 8,
    EXPONENT = 9,
    PERCENT = 10,
    X = 11,
    PIXELS = 12,
}
// 能识别的运算符 到 运算符的信息集合 的映射
export const inputOperator2operatorListIndex = {
    1: operatorListIndex.PAREN_L,
    2: operatorListIndex.PAREN_R,
    3: operatorListIndex.ADD,
    4: operatorListIndex.SUBTRACT,
    5: operatorListIndex.MULTIPLY,
    6: operatorListIndex.DIVIDE,
    7: operatorListIndex.EXPONENT,
    8: operatorListIndex.PERCENT,
    9: operatorListIndex.X,
    10: operatorListIndex.PIXELS,
}
// 运算符的信息集合
export const operatorList = [
    {
        associativity: 0,
        precedence: -1,
        degree: 0,
        name: '',
    },
    {
        associativity: 0,
        precedence: -1,
        degree: 0,
        name: '(',
    },
    {
        associativity: 0,
        precedence: -1,
        degree: 0,
        name: ')',
    },
    {
        associativity: 1,
        precedence: 1,
        degree: 2,
        name: 'add',
    },
    {
        associativity: 1,
        precedence: 1,
        degree: 2,
        name: 'sub',
    },
    {
        associativity: 1,
        precedence: 2,
        degree: 2,
        name: 'mul',
    },
    {
        associativity: 1,
        precedence: 2,
        degree: 2,
        name: 'div',
    },
    {
        associativity: 1,
        precedence: 4,
        degree: 1,
        name: 'pos',
    },
    {
        associativity: 1,
        precedence: 4,
        degree: 1,
        name: 'neg',
    },
    {
        associativity: 2,
        precedence: 5,
        degree: 2,
        name: 'exp',
    },
    {
        associativity: 2,
        precedence: 6,
        degree: 1,
        name: '%',
    },
    {
        associativity: 2,
        precedence: 6,
        degree: 1,
        name: 'x',
    },
    {
        associativity: 2,
        precedence: 6,
        degree: 1,
        name: 'px',
    },
]
export const associativity = {
    '0': 'NONE',
    '1': 'LEFT',
    '2': 'RIGHT',
    NONE: 0,
    LEFT: 1,
    RIGHT: 2,
}
export enum CalculateState {
    SUCCESS = 0,
    UNEXPECTED_TYPE = 1,
    EXPECTED_MORE_ARGUMENTS = 2,
    EXPECTED_NUMBER = 3,
    IMAGINARY = 4,
    DIVIDE_BY_ZERO = 5,
    NO_CURRENT_VALUE = 6,
}
export enum ErrorType {
    NONE = 0,
    SYNTAX_EMPTY = 1,
    SYNTAX_UNRECOGNIZED = 2,
    SYNTAX_MISMATCHED_PARENS = 3,
    EVAL_UNEXPECTED_TYPE = 4,
    EVAL_EXPECTED_MORE_ARGUMENTS = 5,
    EVAL_EXPECTED_NUMBER = 6,
    EVAL_IMAGINARY = 7,
    EVAL_DIVIDE_BY_ZERO = 8,
    EVAL_UNKNOWN = 9,
    EVAL_NO_CURRENT_VALUE = 10,
}
export const calculateState2ErrorType = [
    ErrorType.NONE,
    ErrorType.EVAL_UNEXPECTED_TYPE,
    ErrorType.EVAL_EXPECTED_MORE_ARGUMENTS,
    ErrorType.EVAL_EXPECTED_NUMBER,
    ErrorType.EVAL_IMAGINARY,
    ErrorType.EVAL_DIVIDE_BY_ZERO,
    ErrorType.EVAL_NO_CURRENT_VALUE,
]

export interface CookStringType {
    text: string
    type: OperatorType
    operator: number
    position: number // 出错时记录出错的字符位置
    value: number
}
