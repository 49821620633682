import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    FailedToOpen: '设置公开访问失败',
    Password: '访问密码：',
    LinkCopied: '链接已复制',
    ShareWithLink: '链接分享',
    AnyoneWithThe: '任何获得链接的人',
    MembersHaveEmails: '企业成员的邮箱域名符合',
    NeedPassword: '需要密码',
    CopyLink: '复制链接',
    AndPassword: '和密码',
    DisabledShareWith: '链接分享已禁用，仅允许被邀请的协作者访问',
    DisabledByAdminToast: '该选项已被管理员禁用',
    Advance: '高级选项',
    Copyable: '允许查看者复制、导出、分享文件',
    LinkSharingRange: '通过链接分享',
} as const

export const enTranslation = {
    FailedToOpen: 'An error occurred',
    Password: 'Password: ',
    LinkCopied: 'Link copied',
    ShareWithLink: 'Share with link',
    AnyoneWithThe: 'Anyone with the link',
    MembersHaveEmails: 'Members have emails at',
    NeedPassword: 'Need password',
    CopyLink: 'Copy link',
    AndPassword: ' and password',
    DisabledShareWith: 'Link sharing disabled. Only people invited to this file can access.',
    DisabledByAdminToast: 'This option is disabled by the admin.',
    Advance: 'Advanced options',
    Copyable: 'Allow viewers to copy, share, and export from this file',
    LinkSharingRange: 'Share with link',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
