/* eslint-disable no-restricted-imports */

import { FontInfo, FontName } from '../../document/node/node'

export interface LocalFontInfo {
    family: string
    path: string
    fileName: string
    localizedFamily: string
    postScriptName: string
    style: string
    localizedStyle: string
    italic: boolean
    width: number
    weight: number
    variable: boolean
    variationInstances: FontVariantInstance[] // 未使用 postScriptName
    variationAxes: FontVariantAxes[]
}

export interface LocalFonts {
    appVersion: string
    fontInfos: LocalFontInfo[]
}

/**
 * 扩展FontName FontInfo类型 增加部分需要在font-manager-service中使用的字段
 */

export interface FontNameExt extends FontName {
    /** 字体文件路径 */
    readonly path?: string
    readonly resourceId?: string
    readonly resourceUrl?: string
    readonly sampleResourceId?: string
    readonly sampleResourceUrl?: string
    readonly familySampleResourceUrl?: string
    readonly familySampleResourceId?: string
    readonly variable?: boolean
    readonly variationAxisTickValues?: VariationAxisTickValues // 轴的分割点
    readonly variationInstances?: FontVariantInstance[] // 记录变体实例在每个轴的数值
    readonly variationInstanceAxes?: VariationInstancesAxes[] // 记录变体实例在每个轴的具体信息
}

export interface FontVariantAxes {
    axisTag: string
    name: string
    minValue: number
    maxValue: number
    defaultValue: number
    hidden: boolean
}

export interface FontInfoExt extends FontInfo {
    styles: FontNameExt[]
}

export interface LocalFontsAndAppVersion {
    fonts: FontInfoExt[]
    appVersion: string | undefined
    pluginStatus: LocalFontPluginStatus
    pluginVersionInfo: FontPluginVersionInfo
}

export interface VariationAxisTickValues {
    [key: string]: { value: number[] }
}

export interface FontVariantInstance {
    axes: { [key: string]: number }
    name: string
    localizedName: string
}

export interface FontVariant {
    axes: FontVariantAxes[]
    instances: FontVariantInstance[]
}
export interface VariationInstancesAxes extends Omit<FontVariantAxes, 'axisTag'> {
    value: number
    axisTag: number
}

export interface FontPluginVersionInfo {
    versionUrl: string
    versionDesc: string
    showReminder: boolean
    upgradeType: 'force' | 'option' | 'none'
}

export enum LocalFontPluginStatus {
    DETECTING = 'DETECTING',
    INSTALLED = 'INSTALLED',
    NOT_FOUND = 'NOT_FOUND',
    BROKEN = 'BROKEN',
    SILENT_IGNORE = 'SILENT_IGNORE', // 在 appVersion 接口请求失败等情况下的静默处理
}

export interface FontPluginDetails extends Omit<FontPluginVersionInfo, 'upgradeType'> {
    pluginStatus: 'installed' | 'optionUpdated' | 'forceUpdated' | 'uninstalled' | 'silentIgnore'
}
