import {
    DevModeExportChangeScaleCommand,
    DevModeExportSelectFileTypeCommand,
    DevModeExportSelectPresetCodeTypeCommand,
    Wukong,
} from '@wukong/bridge-proto'
import classnames from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Checkbox, IconAdd16, IconBatchExport, IconDelete16, Select, Tooltip } from '../../../../../../../ui-lib/src'
import { useCopyCheckService } from '../../../../../main/app-context'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'
import { IconButton } from '../../../atom/button/icon-button'
import { isExportWebP } from '../../../design/export/util'
import { useDevModeBatchExport } from '../../dev-mode-batch-export/use-dev-mode-batch-export'
import { logs } from '../../logs'
import style from '../inspect.module.less'
import { fileTypeToName, getScaleItemName, presetCodeTypeToName } from './config'
import { ProhibitExportPanel } from './export-prohibit-panel'
import exportStyle from './export.module.less'
import { translation } from './index.translation'
import { DevModePreviewExpand } from './preview/dev-mode-expand'
import { NodePreview } from './preview/dev-mode-preview'
import { useDevModeExport, useDevModeExportCompress, useShowDevModeExportDetail } from './use-dev-mode-export'

import PresetCodeType = Wukong.DocumentProto.PresetCodeType
import FileType = Wukong.DocumentProto.FileType
import ScaleItem = Wukong.DocumentProto.ScaleItem

export function DetailField(props: { showProhibitExportPanel?: boolean; isShowDetail: boolean }) {
    if (props.showProhibitExportPanel === undefined) {
        return <></>
    } else if (props.showProhibitExportPanel) {
        return <ProhibitExportPanel />
    }
    return <>{props.isShowDetail && <ExportDetail />}</>
}

export function DevModeInspectExport() {
    const { isShowDetail, showDetail, closeDetail } = useShowDevModeExportDetail()
    const { openBatchExport } = useDevModeBatchExport()

    const [showProhibitExportPanel, setShowProhibitExportPanel] = useState<boolean | undefined>()

    const copyCheckService = useCopyCheckService()
    const copyHasBeenProhibited = copyCheckService.useZustandStore.use.copyHasBeenProhibited()
    useEffect(() => {
        setShowProhibitExportPanel(copyHasBeenProhibited)
    }, [copyHasBeenProhibited])

    return (
        <div className={style.panel} style={{ padding: '8px 0' }} data-testid="dev-mode-inspect-export">
            <div
                className={style.panelTitle}
                style={{ marginBottom: '0' }}
                onClick={() => {
                    if (!isShowDetail) {
                        showDetail()
                    }
                }}
                data-testid="dev-mode-inspect-export-title"
            >
                <div className={style.title}>{translation('Export')}</div>
                {!showProhibitExportPanel && (
                    <div className={style.panelCopyActionControls}>
                        <Tooltip title={translation('Export2')}>
                            <IconButton
                                icon={<IconBatchExport />}
                                selected={false}
                                onClick={() => {
                                    logs.DevMode.exportDialogDisplay({ interaction_type: 'click' })
                                    openBatchExport()
                                }}
                                dataTestId="dev-mode-inspect-open-batch-export-icon"
                            />
                        </Tooltip>
                        {!isShowDetail ? (
                            <IconButton
                                icon={<IconAdd16 />}
                                selected={false}
                                onClick={showDetail}
                                dataTestId="dev-mode-inspect-export-show-btn"
                            />
                        ) : (
                            <IconButton
                                icon={<IconDelete16 />}
                                selected={false}
                                onClick={closeDetail}
                                dataTestId="dev-mode-inspect-export-hide-btn"
                            />
                        )}
                    </div>
                )}
            </div>
            <DetailField showProhibitExportPanel={showProhibitExportPanel} isShowDetail={isShowDetail} />
        </div>
    )
}

export function ExportDetail() {
    const exportViewState = useViewState('devModeInspectExportViewState')
    const { exportSelection } = useDevModeExport()

    // <string, bool> => <ScaleItem, bool>
    const scales = useMemo(() => {
        if (!exportViewState?.scaleConfig) {
            return new Map<ScaleItem, boolean>([
                [ScaleItem.SCALE_ITEMX2, true],
                [ScaleItem.SCALE_ITEMX1, true],
            ])
        }

        return Object.entries(exportViewState.scaleConfig).reduce((acc, [key, value]) => {
            acc.set(Number(key) as ScaleItem, value)
            return acc
        }, new Map<ScaleItem, boolean>())
    }, [exportViewState?.scaleConfig])

    const exportEnabled = useMemo(() => {
        return [...scales].some(([, selected]) => selected)
    }, [scales])

    const handleExport = useCallback(() => {
        if (!exportEnabled) {
            return
        }

        exportSelection()
    }, [exportEnabled, exportSelection])

    if (!exportViewState) {
        return null
    }

    return (
        <>
            <ExportSetting />
            <div className={exportStyle.exportControlContainer}>
                <div className={exportStyle.exportBtnContainer}>
                    <div
                        className={classnames(exportStyle.exportBtn, {
                            [exportStyle.disabled]: !exportEnabled,
                        })}
                        onClick={handleExport}
                        data-testid={'dev-mode-export-btn'}
                    >
                        {exportViewState.exportBtnText}
                    </div>
                </div>

                {exportViewState.exportSetting.exportSettings.length > 0 &&
                    exportViewState.exportSetting.selectionNodeIds.length === 1 && (
                        <DevModePreviewExpand title={translation('Preview')}>
                            <NodePreview ids={exportViewState.exportSetting.selectionNodeIds} />
                        </DevModePreviewExpand>
                    )}
            </div>
        </>
    )
}

export function ExportSetting() {
    const exportViewState = useViewState('devModeInspectExportViewState')
    const command = useCommand()

    const { isCompress, setIsCompress } = useDevModeExportCompress()

    const presetCodeType = exportViewState?.presetCodeType || PresetCodeType.PRESET_CODE_TYPE_WEB
    const fileType = useMemo(() => {
        if (!exportViewState?.fileType) {
            return FileType.FILE_TYPE_PNG
        }
        // 不支持 webp 时降级为 PNG 处理
        if (!isExportWebP() && exportViewState.fileType === FileType.FILE_TYPE_WEBP) {
            return FileType.FILE_TYPE_PNG
        }

        return exportViewState.fileType
    }, [exportViewState?.fileType])

    const isShowScaleDetail = [
        FileType.FILE_TYPE_JPEG,
        FileType.FILE_TYPE_PNG,
        FileType.FILE_TYPE_WEBP,
        FileType.FILE_TYPE_AVIF,
    ].includes(fileType)
    const isShowCompress = [FileType.FILE_TYPE_JPEG, FileType.FILE_TYPE_PNG, FileType.FILE_TYPE_WEBP].includes(fileType)

    const handlePresetCodeTypeChange = useCallback(
        (value: PresetCodeType) => {
            command.DEPRECATED_invokeBridge(
                DevModeExportSelectPresetCodeTypeCommand,
                Wukong.DocumentProto.Arg_DevModeExportSelectPresetCodeType.create({
                    presetCodeType: value,
                })
            )
        },
        [command]
    )

    const handleFileTypeChange = useCallback(
        (value: FileType) => {
            command.DEPRECATED_invokeBridge(
                DevModeExportSelectFileTypeCommand,
                Wukong.DocumentProto.Arg_DevModeExportSelectFileType.create({
                    fileType: value,
                })
            )
        },
        [command]
    )

    const handleScaleChange = useCallback(
        (scaleItem: ScaleItem, selected: boolean) => {
            command.DEPRECATED_invokeBridge(
                DevModeExportChangeScaleCommand,
                Wukong.DocumentProto.Arg_DevModeExportChangeScale.create({
                    scaleItem: scaleItem,
                    selected: selected,
                })
            )
        },
        [command]
    )

    if (!exportViewState) {
        return null
    }

    return (
        <div className={classnames(exportStyle.exportSettingContainer)}>
            <PresetCodeAndFileTypeSelect
                presetCodeType={presetCodeType}
                fileType={fileType}
                onPresetCodeTypeChange={handlePresetCodeTypeChange}
                onFileTypeChange={handleFileTypeChange}
            />
            {isShowScaleDetail && (
                <ScaleCheckboxs
                    scales={exportViewState?.scaleConfig ?? {}}
                    onScaleChange={handleScaleChange}
                    presetCodeType={presetCodeType}
                />
            )}
            {isShowCompress && (
                <div className={exportStyle.compressContainer}>
                    <div className={exportStyle.textLabel}>
                        <span>{translation('ImageCompress')}</span>
                    </div>
                    <Select.NormalSingleLevel
                        className={classnames(exportStyle.compressSelect)}
                        value={isCompress}
                        label={isCompress ? translation('Compressed') : translation('Non-compressed')}
                        onChange={setIsCompress}
                        dataTestIds={{ triggerFocus: 'compress-select' }}
                    >
                        <Select.NormalSingleLevel.Option key={0} value={false}>
                            {translation('Non-compressed')}
                        </Select.NormalSingleLevel.Option>
                        <Select.NormalSingleLevel.Option key={1} value={true}>
                            {translation('Compressed')}
                        </Select.NormalSingleLevel.Option>
                    </Select.NormalSingleLevel>
                </div>
            )}
        </div>
    )
}

interface PresetCodeAndFileTypeSelectProps {
    presetCodeType: PresetCodeType
    fileType: FileType
    onPresetCodeTypeChange: (presetCodeType: PresetCodeType) => void
    onFileTypeChange: (fileType: FileType) => void
}

function PresetCodeAndFileTypeSelect(props: PresetCodeAndFileTypeSelectProps) {
    const { presetCodeType, fileType, onPresetCodeTypeChange, onFileTypeChange } = props
    return (
        <div className={exportStyle.selectContainer}>
            <Select.NormalSingleLevel
                className={classnames(exportStyle.selectItem)}
                value={presetCodeType}
                label={presetCodeTypeToName.get(presetCodeType)}
                onChange={onPresetCodeTypeChange}
                dataTestIds={{ triggerFocus: 'preset-code-select' }}
            >
                {[...presetCodeTypeToName].map(([key, value]) => {
                    return (
                        <Select.NormalSingleLevel.Option key={key} value={key}>
                            {value}
                        </Select.NormalSingleLevel.Option>
                    )
                })}
            </Select.NormalSingleLevel>
            <Select.NormalSingleLevel
                className={classnames(exportStyle.selectItem)}
                value={fileType}
                label={fileTypeToName.get(fileType)}
                onChange={onFileTypeChange}
                dataTestIds={{ triggerFocus: 'file-type-select' }}
            >
                {[...fileTypeToName].map(([key, value]) => {
                    if (!isExportWebP() && key === FileType.FILE_TYPE_WEBP) {
                        return null
                    }

                    return (
                        <Select.NormalSingleLevel.Option key={key} value={key}>
                            {value}
                        </Select.NormalSingleLevel.Option>
                    )
                })}
            </Select.NormalSingleLevel>
        </div>
    )
}

interface ScaleCheckboxsProps {
    scales: Record<string, boolean>
    onScaleChange: (scaleItem: ScaleItem, selected: boolean) => void
    presetCodeType: PresetCodeType
}

function ScaleCheckboxs(props: ScaleCheckboxsProps) {
    const { scales, onScaleChange, presetCodeType } = props
    return (
        <div className={exportStyle.checkboxContainer}>
            {Object.entries(scales).map(([_scaleItem, selected], index) => {
                const scaleItem = Number(_scaleItem) as ScaleItem
                return (
                    <Checkbox
                        key={scaleItem}
                        className={classnames(exportStyle.checkboxItem)}
                        checked={selected}
                        onChange={(value: boolean) => {
                            onScaleChange(scaleItem, value)
                        }}
                        label={getScaleItemName(scaleItem, presetCodeType)}
                        size="small"
                        testid={`export-scale-checkbox-${index}`}
                    />
                )
            })}
        </div>
    )
}
