import { translation } from './library-list-modal.translation'

import { Key, useCallback, useRef, useState } from 'react'
import { useEvent } from 'react-use'
import { DraggablePopupCloseButton, DraggablePopupV2, WKTabsV2 } from '../../../../../../ui-lib/src'
import { useLibraryComponentService } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { LibraryTestId } from '../../../../window'
import { useLibraryModalConfigContext } from '../../../context/library-component-style/library-modal-config'
import { LibraryListModalTab } from '../library-service/library-modal-router-service'
import { LibraryShowPointContainer } from '../library-show-point-container/library-show-point-container'
import { LibraryListModalLocal } from './library-list-modal-local/library-list-modal-local'
import { LibraryListModalRemoteContainer } from './library-list-modal-remote/library-list-modal-remote'
import styles from './library-list-modal.module.less'

const LibraryPublishModalTab2Label: Record<LibraryListModalTab, string> = {
    [LibraryListModalTab.Remote]: translation('Libraries'),
    [LibraryListModalTab.Local]: translation('LocalLibrary'),
}

// 【组件库弹窗】（总样式/组件变动入口）（例如：左侧资产面板icon 或右下角更新提示可打开弹窗）
export const LibraryListModal = () => {
    const {
        libraryModalRouterService: {
            closeLibraryMainModal,
            goToLocalLibraryHome,
            goToRemoteLibraryHome,
            updateMainModalPosition,
        },
    } = useLibraryComponentService()
    const { mainModalConfig } = useLibraryModalConfigContext()
    const tabTitleRef = useRef<HTMLDivElement>(null)
    const libraryGlobalState = useViewState('libraryGlobalState')

    const onActiveTabChange = useCallback(
        (activeKey: Key) => {
            if (activeKey !== mainModalConfig.activeTab && activeKey) {
                if (activeKey === LibraryListModalTab.Local) {
                    goToLocalLibraryHome()
                } else if (activeKey === LibraryListModalTab.Remote) {
                    goToRemoteLibraryHome()
                }
            }
        },
        [mainModalConfig.activeTab, goToLocalLibraryHome, goToRemoteLibraryHome]
    )

    const getPopupHeight = () => {
        const winHeight = window.innerHeight
        let height = 600
        if (winHeight < 48 + 8 * 2 + 600) {
            height = Math.max(winHeight - 48 - 8 * 2, 150 + 55 + 60)
        }
        return height
    }
    const [popupHeight, setPopupHeight] = useState<number>(getPopupHeight())

    // 视窗变化时，动态设置 height
    useEvent('resize', () => {
        setPopupHeight(getPopupHeight())
    })

    return mainModalConfig.show ? (
        <DraggablePopupV2
            visible
            bodyClassName={'p-0'}
            header={null}
            footer={null}
            onCancel={closeLibraryMainModal}
            width={464}
            height={popupHeight}
            testId={LibraryTestId.HomeModal.Id}
            position={mainModalConfig.position}
            onMove={updateMainModalPosition}
        >
            <div style={{ paddingTop: '5px', height: `${popupHeight - 5}px` }}>
                <WKTabsV2
                    size="medium"
                    activeKey={mainModalConfig.activeTab}
                    onActiveTabChange={onActiveTabChange}
                    defaultActiveKey={LibraryListModalTab.Local}
                    className={styles.tabContainer}
                    navListClassName={styles.tabNavListContainer}
                    ref={tabTitleRef}
                    keepInactiveTabPane
                    disabledTabPanelDrag
                    rightExpandNode={
                        <DraggablePopupCloseButton
                            className="top-[-28px]!"
                            isCenterVertically
                            onClick={closeLibraryMainModal}
                            dataTestId={LibraryTestId.HomeModal.CloseBtn}
                        />
                    }
                >
                    <WKTabsV2.Item
                        tabKey={LibraryListModalTab.Remote}
                        label={
                            <LibraryShowPointContainer
                                showPoint={Boolean(libraryGlobalState?.showRemoteLibraryTabBadge)}
                            >
                                <span data-testid={LibraryTestId.HomeModal.RemoteTab}>
                                    {LibraryPublishModalTab2Label[LibraryListModalTab.Remote]}
                                </span>
                            </LibraryShowPointContainer>
                        }
                    >
                        <LibraryListModalRemoteContainer />
                    </WKTabsV2.Item>
                    <WKTabsV2.Item
                        tabKey={LibraryListModalTab.Local}
                        label={
                            <LibraryShowPointContainer
                                showPoint={Boolean(libraryGlobalState?.showLocalLibraryTabBadge)}
                            >
                                <span data-testid={LibraryTestId.HomeModal.CurrentLocalTab}>
                                    {LibraryPublishModalTab2Label[LibraryListModalTab.Local]}
                                </span>
                            </LibraryShowPointContainer>
                        }
                    >
                        <LibraryListModalLocal />
                    </WKTabsV2.Item>
                </WKTabsV2>
            </div>
        </DraggablePopupV2>
    ) : (
        <></>
    )
}
