// 只有在只读模式空选状态才会展示
import { CurrentPageSetSelectionCommandWasmCall, ZoomToSelectionWasmCall } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { FC, useCallback } from 'react'
import { MonoIconCanvasLink16, MonoIconPanelTarget16, Tooltip, WKIconButton, WKToast } from '../../../../../ui-lib/src'
import { featureSwitchManager } from '../../../kernel/switch/core'
import { copyFlowLinkForEditor } from '../../../prototype/context/present-url'
import { useViewState } from '../../../view-state-bridge'
import { useCommand } from '../../context/document-context'
import { PrototypeVisibleButton } from '../prototype/prototype-flows/prototype-flows'
import { translation } from './inspect-prototype-flows.translation'

// 因为上面的 本地样式 是永远存在的，所以这里的border 可以写死必有
export const InspectPrototypeFlows: FC<{ topBorder: boolean; bottomBorder: boolean; inDevMode: boolean }> = ({
    topBorder,
    bottomBorder,
    inDevMode,
}) => {
    const docReadonly = useViewState('docReadonly')
    const flows = useViewState('prototypeFlowList')?.list
    const command = useCommand()
    const copyLink = useCallback(
        (id: string) => {
            copyFlowLinkForEditor(command, id)
            WKToast.show(translation('LinkCopied'))
        },
        [command]
    )

    const move = useCallback(
        (nodeId: string) => {
            command.DEPRECATED_invokeBridge(CurrentPageSetSelectionCommandWasmCall, { selection: [nodeId] })
            command.DEPRECATED_invokeBridge(ZoomToSelectionWasmCall)
        },
        [command]
    )

    if (!docReadonly || !flows?.length) return <></>

    return (
        <div
            data-testid="inspect-prototype-flows"
            className={classNames(
                'color-$wk-v2-label-color-gray-13 wk-text-12 border-color-$wk-gray-2 pt-2 pb-3',
                topBorder && 'b-t',
                bottomBorder && 'b-b'
            )}
        >
            <div className="h-8 flex items-center pl-4 pr-10px justify-between">
                <div className="wk-font-semibold">{translation('flows')}</div>
                {/* 现在只有非研发模式展示，研发模式依据开关展示 */}
                {inDevMode ? (
                    <>{featureSwitchManager.isEnabled('dev-prototype') && <PrototypeVisibleButton />}</>
                ) : (
                    <PrototypeVisibleButton />
                )}
            </div>
            {flows.map((item) => {
                return (
                    <div
                        data-testid="flow-panel-item"
                        key={item.nodeId}
                        className="h-9 flex items-center gap-1 group pl-4 pr-10px"
                    >
                        <span className="truncate flex-1">{item.name}</span>
                        <Tooltip title={translation('Position')}>
                            <WKIconButton
                                onClick={() => move(item.nodeId)}
                                className="hidden group-hover:block"
                                icon={<MonoIconPanelTarget16 />}
                            />
                        </Tooltip>
                        <Tooltip title={translation('CopyLink')}>
                            <WKIconButton
                                onClick={() => copyLink(item.nodeId)}
                                className="!ml-0 hidden group-hover:block"
                                icon={<MonoIconCanvasLink16 />}
                                data-testid="inspect-copy-flow-link"
                            />
                        </Tooltip>
                    </div>
                )
            })}
        </div>
    )
}
