import { translation } from './upgrade-components-executor.translation'

import { BatchUpgradePublishMixinsVersionV3Command, Wukong } from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { WKToast } from '../../../../../../ui-lib/src'
import { ConcurrentScheduler } from '../../../../../../util/src'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import { CommitType } from '../../../../document/command/commit-type'
import { LibraryId } from '../../../../kernel/interface/component-style-library-id'
import { DocID } from '../../../../kernel/interface/type'
import { GetLibraryIdAndNameMapRequest } from '../../../../kernel/request/library'
import { SubscribeLibrary } from '../../../../kernel/request/library-subscription'
import { ServiceClass } from '../../../../kernel/util/service-class'
import { LibraryResourceOssClientType } from '../../../../share/component-style-library/service/library-resource-downloader'
import { ToastProgressMessage } from '../../top-area/tool-sync-status/sync-toast-progress-message/sync-toast-progress-message'
import { LibraryNodeDataService } from './library-node-data-service'
import { LibraryNotifySyncService } from './library-notify-sync-service'

// 更新组件库实例版本（包括 组件、组件集、样式）
export class UpgradeComponentsExecutor extends ServiceClass {
    private readonly batchUpgradeConcurrentScheduler = ConcurrentScheduler({ limitCount: 4, delayTime: 100 }) // 批量更新个数限制
    private progressToastId: string | null = null
    private lockFlag = false

    constructor(
        private readonly docId: DocID,
        private readonly command: CommandInvoker,
        private readonly libraryNodeDataService: LibraryNodeDataService,
        private readonly libraryNotifySyncService: LibraryNotifySyncService,
        private readonly closeLibraryModal: () => void
    ) {
        super()
    }

    public batchRun = async (operations: Wukong.DocumentProto.IVLibraryComponentUpdateModalItem[]) => {
        if (this.lockFlag) {
            WKToast.show(translation('ThereIsContent'))
            return
        }

        let hasUpgradeError = false
        const oldLibraryId2MovedNewDocumentIds = new Map<LibraryId, Set<DocID>>()
        const changeList = operations.map(({ items }) => items ?? []).flat()

        this.closeLibraryModal()
        this.showProgressToast()

        let hasUpgradedCount = 0
        await this.upgrade(changeList, oldLibraryId2MovedNewDocumentIds, {
            catch: (err: any) => {
                console.error(err)
                hasUpgradeError = true
            },
            finally: () => {
                this.updateProgressToast((++hasUpgradedCount * 100.0) / changeList.length)
            },
        })

        this.subscribeAllMovedNewDocumentIds(oldLibraryId2MovedNewDocumentIds)

        await this.closeProgressToast()

        if (hasUpgradeError) {
            WKToast.error(translation('UpdateFailed'))
        } else {
            WKToast.show(translation('UpdateSuccessful'))
        }
    }

    public singleRun = async (item: Wukong.DocumentProto.IVLibraryComponentUpdateModalPublishMixinItem) => {
        if (this.lockFlag) {
            WKToast.show(translation('ThereIsContent'))
            return
        }

        let hasUpgradeError = false
        const oldLibraryId2MovedNewDocumentIds = new Map<LibraryId, Set<DocID>>()

        await this.upgrade([item], oldLibraryId2MovedNewDocumentIds, {
            catch: (err: any) => {
                console.error(err)
                hasUpgradeError = true
            },
        })

        this.subscribeAllMovedNewDocumentIds(oldLibraryId2MovedNewDocumentIds)

        if (hasUpgradeError) {
            WKToast.error(translation('UpdateFailed'))
        }
    }

    public runVariables = async (items: Wukong.DocumentProto.IVLibraryComponentUpdateModalPublishMixinItem[]) => {
        if (this.lockFlag) {
            WKToast.show(translation('ThereIsContent'))
            return
        }

        let hasUpgradeError = false
        const oldLibraryId2MovedNewDocumentIds = new Map<LibraryId, Set<DocID>>()

        await this.upgrade(items, oldLibraryId2MovedNewDocumentIds, {
            catch: (err: any) => {
                console.error(err)
                hasUpgradeError = true
            },
        })

        this.subscribeAllMovedNewDocumentIds(oldLibraryId2MovedNewDocumentIds)

        if (hasUpgradeError) {
            WKToast.error(translation('UpdateFailed'))
        }
    }

    private upgrade = async (
        items: Wukong.DocumentProto.IVLibraryComponentUpdateModalPublishMixinItem[],
        oldLibraryId2MovedNewDocumentIds: Map<LibraryId, Set<DocID>>,
        callbackFn: {
            catch?: (err: any) => void
            finally?: () => void
        }
    ) => {
        this.lockFlag = true
        await Promise.all(
            items.map(
                ({
                    upgradeType,
                    nodeDataPath,
                    documentId,
                    nodeId,
                    nodeKey,
                    contentHash,
                    variantPairs,
                    movedOldLibraryId,
                }) =>
                    this.batchUpgradeConcurrentScheduler
                        .add(async () => {
                            if (isNil(upgradeType) || !nodeKey) {
                                return
                            }

                            const fetchData = await (
                                this.libraryNodeDataService as LibraryNodeDataService
                            ).fetchRemoteRawExportedDocument({
                                isLocal: false,
                                remoteDocId: documentId,
                                remoteNodeId: nodeId,
                                nodeDataPath,
                                ossClientType:
                                    upgradeType ===
                                    Wukong.DocumentProto.UpgradePublishMixinVersionType
                                        .UPGRADE_PUBLISH_MIXIN_VERSION_TYPE_STYLE
                                        ? LibraryResourceOssClientType.Style
                                        : upgradeType ===
                                          Wukong.DocumentProto.UpgradePublishMixinVersionType
                                              .UPGRADE_PUBLISH_MIXIN_VERSION_TYPE_VARIABLE
                                        ? LibraryResourceOssClientType.Variable
                                        : LibraryResourceOssClientType.Component,
                            })

                            if (movedOldLibraryId) {
                                oldLibraryId2MovedNewDocumentIds.set(
                                    movedOldLibraryId,
                                    new Set([
                                        ...(oldLibraryId2MovedNewDocumentIds.get(movedOldLibraryId) ?? []),
                                        fetchData.remoteDocId,
                                    ])
                                )
                            }
                            this.command.invokeBridge(CommitType.Noop, BatchUpgradePublishMixinsVersionV3Command, {
                                items: [
                                    {
                                        ...fetchData,
                                        upgradeType,
                                        nodeKey,
                                        contentHash,
                                        variantPairs,
                                    },
                                ],
                            })
                        })
                        .catch(callbackFn?.catch)
                        .finally(callbackFn?.finally)
            )
        )
        if (items.length > 0) {
            this.command.commitUndo()
        }
        this.lockFlag = false
    }

    public override destroy() {
        super.destroy()
        this.closeProgressToast()
        this.batchUpgradeConcurrentScheduler.destroy()
    }

    private showProgressToast = () => {
        this.progressToastId = WKToast.show(
            <ToastProgressMessage
                progress={0}
                progressMsg={translation('Updating')}
                onProgressEnd={this.closeProgressToast}
            />,
            { duration: -1 }
        )
    }

    private updateProgressToast = (progress: number) => {
        if (this.progressToastId) {
            WKToast.updateMessage(
                this.progressToastId,
                <ToastProgressMessage
                    progress={progress}
                    progressMsg={translation('Updating')}
                    onProgressEnd={this.closeProgressToast}
                />
            )
        }
    }

    private closeProgressToast = () => {
        return new Promise<void>((resolve) => {
            const toastId = this.progressToastId
            if (toastId) {
                this.progressToastId = null
                // NOTE: 加 setTimeout 避免出现 Warning: Attempted to synchronously unmount a root while React was already rendering
                setTimeout(() => {
                    WKToast.close(toastId)
                    resolve()
                }, 0)
            } else {
                resolve()
            }
        })
    }

    // 如果 oldLibraryId 被订阅，则对应 movedNewDocumentIds 也需要被订阅
    private subscribeAllMovedNewDocumentIds = async (oldLibraryId2MovedNewDocumentIds: Map<LibraryId, Set<DocID>>) => {
        try {
            const subscribedLibraryIds = this.libraryNotifySyncService
                .getLatestSubscribedLibraryInfoList()
                .map(({ libraryId }) => libraryId)
            const allNewDocumentIds = new Set<DocID>()
            ;[...oldLibraryId2MovedNewDocumentIds.entries()].map(async ([oldLibraryId, newDocumentIds]) => {
                if (subscribedLibraryIds.includes(oldLibraryId)) {
                    newDocumentIds.forEach((docId) => allNewDocumentIds.add(docId))
                }
            })

            // 剔除本文档
            allNewDocumentIds.delete(this.docId)

            if (allNewDocumentIds.size) {
                const libraryIdInfos = await new GetLibraryIdAndNameMapRequest(
                    [...allNewDocumentIds],
                    this.docId
                ).start()
                await Promise.all(
                    Object.values(libraryIdInfos).map(({ id }) => new SubscribeLibrary(this.docId, id).start())
                )
            }
        } catch (err) {
            console.warn(translation('Auto-subscriptionFailsAfter'))
            console.error(err)
        }
    }
}
