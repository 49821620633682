import { PreloadError } from '../../../../util/src/preload-error'
import { ColorSpace, TextureCompressionFormat } from '../types'
import {
    ImageFormat,
    ImageLibContext,
    ImageLibWorkerArgument,
    ImageLibWorkerResult,
    MethodType,
    RemoveICCChunksAndGetMetaResult,
} from './types'
// eslint-disable-next-line import/default
import workerUrl from './image-lib.worker?worker&url'

class ImageLibWorkerError extends Error {
    constructor(code: MethodType) {
        super(`ImageLibWorkerError: method ${code}`)
    }
}

export class ImageLibWorker {
    private static sharedInstance = new ImageLibWorker()

    private nextId = 0
    private callbacks = new Map<number, (_result: ImageLibWorkerResult) => void>()
    private workerPromise?: Promise<Worker>
    private prefetchedPromise?: Promise<Blob>

    private constructor() {}

    private async initAndGetWorker(cx: ImageLibContext) {
        let worker: Worker

        if (cx.env.isDev) {
            const Factory = (await import('./image-lib.worker?worker')).default
            worker = new Factory()
        } else {
            const blob = await this._prefetchWorker()
            worker = new Worker(window.URL.createObjectURL(blob), {
                type: 'module',
            })
        }

        worker.onmessage = (data: MessageEvent) => {
            const result = data.data as ImageLibWorkerResult
            const callback = this.callbacks.get(result.id)
            if (callback) {
                callback(result)
                this.callbacks.delete(result.id)
            }
        }

        return worker
    }

    private _prefetchWorker() {
        if (this.prefetchedPromise) {
            return this.prefetchedPromise
        }
        this.prefetchedPromise = fetch(workerUrl).then((data) => data.blob())
        return this.prefetchedPromise
    }

    public async preload(cx: ImageLibContext): Promise<void> {
        await this._prefetchWorker().catch(() => {
            cx.logger.log('preload image lib error')
            const error = new PreloadError('imageLib')
            throw error
        })
    }

    private ensureInited(cx: ImageLibContext) {
        if (!this.workerPromise) {
            this.workerPromise = this.initAndGetWorker(cx)
        }
        return this.workerPromise
    }

    public static get() {
        return ImageLibWorker.sharedInstance
    }

    public async decode(cx: ImageLibContext, buffer: ArrayBuffer) {
        const id = this.nextId++
        return this.ensureInited(cx).then((worker) => {
            const argument: ImageLibWorkerArgument = {
                type: MethodType.Decode,
                id,
                arg: {
                    buffer: buffer,
                    wasmDownloadPrefix: cx.env.wasmDownloadPrefix,
                },
            }
            worker.postMessage(argument, [buffer])

            return new Promise<{ width: number; height: number; data: ImageData }>((resolve, reject) => {
                this.callbacks.set(id, (result: ImageLibWorkerResult) => {
                    if (result.type === MethodType.Decode && result.ret.image) {
                        resolve({
                            width: result.ret.image.width,
                            height: result.ret.image.height,
                            data: result.ret.image,
                        })
                    } else {
                        reject(this.buildAndReportError(cx, MethodType.Decode))
                    }
                })
            })
        })
    }

    public async removeICCChunksAndGetMeta(
        cx: ImageLibContext,
        source: Uint8Array
    ): Promise<RemoveICCChunksAndGetMetaResult> {
        const id = this.nextId++
        return this.ensureInited(cx).then((worker) => {
            const argument: ImageLibWorkerArgument = {
                type: MethodType.RemoveICCChunksAndGetMeta,
                id,
                arg: {
                    source,
                },
            }
            worker.postMessage(argument, [source.buffer])

            return new Promise((resolve, reject) => {
                this.callbacks.set(id, (result: ImageLibWorkerResult) => {
                    if (result.type === MethodType.RemoveICCChunksAndGetMeta) {
                        resolve(result.ret)
                    } else {
                        reject(this.buildAndReportError(cx, MethodType.RemoveICCChunksAndGetMeta))
                    }
                })
            })
        })
    }

    public async replaceImageICCToDisplayP3(
        cx: ImageLibContext,
        data: Uint8Array,
        type: ImageFormat
    ): Promise<Uint8Array> {
        const id = this.nextId++
        return this.ensureInited(cx).then((worker) => {
            const argument: ImageLibWorkerArgument = {
                type: MethodType.ReplaceImageICCToDisplayP3,
                id,
                arg: {
                    data,
                    type,
                    wasmDownloadPrefix: cx.env.wasmDownloadPrefix,
                },
            }
            worker.postMessage(argument, [data.buffer])

            return new Promise((resolve, reject) => {
                this.callbacks.set(id, (result: ImageLibWorkerResult) => {
                    if (result.type === MethodType.ReplaceImageICCToDisplayP3) {
                        resolve(result.ret.ret)
                    } else {
                        reject(this.buildAndReportError(cx, MethodType.ReplaceImageICCToDisplayP3))
                    }
                })
            })
        })
    }

    public async encodeCompressPng(
        cx: ImageLibContext,
        data: Uint8Array,
        width: number,
        height: number,
        colorProfile: ColorSpace
    ): Promise<Uint8Array> {
        const id = this.nextId++
        return this.ensureInited(cx).then((worker) => {
            const argument: ImageLibWorkerArgument = {
                type: MethodType.EncodeCompressPng,
                id,
                arg: {
                    data,
                    width,
                    height,
                    colorProfile,
                },
            }
            worker.postMessage(argument, [data.buffer])

            return new Promise((resolve, reject) => {
                this.callbacks.set(id, (result: ImageLibWorkerResult) => {
                    if (result.type === MethodType.EncodeCompressPng) {
                        resolve(result.ret.data)
                    } else {
                        reject(this.buildAndReportError(cx, MethodType.EncodeCompressPng))
                    }
                })
            })
        })
    }

    public async encodeAvif(
        cx: ImageLibContext,
        data: Uint8Array,
        width: number,
        height: number,
        colorProfile: ColorSpace
    ): Promise<Uint8Array | null> {
        const id = this.nextId++
        return this.ensureInited(cx).then((worker) => {
            const argument: ImageLibWorkerArgument = {
                type: MethodType.EncodeAvif,
                id,
                arg: {
                    data,
                    width,
                    height,
                    colorProfile,
                    wasmDownloadPrefix: cx.env.wasmDownloadPrefix,
                },
            }
            worker.postMessage(argument, [data.buffer])

            return new Promise((resolve, reject) => {
                this.callbacks.set(id, (result: ImageLibWorkerResult) => {
                    if (result.type === MethodType.EncodeAvif) {
                        resolve(result.ret.data)
                    } else {
                        reject(this.buildAndReportError(cx, MethodType.EncodeAvif))
                    }
                })
            })
        })
    }

    public async compressBitmap(
        cx: ImageLibContext,
        data: Uint8Array,
        width: number,
        height: number
    ): Promise<Uint8Array | null> {
        const id = this.nextId++
        return this.ensureInited(cx).then((worker) => {
            const argument: ImageLibWorkerArgument = {
                type: MethodType.CompressBitmap,
                id,
                arg: {
                    data,
                    width,
                    height,
                },
            }
            // 这里不要填 transfer 对象
            worker.postMessage(argument, [])

            return new Promise((resolve, reject) => {
                this.callbacks.set(id, (result: ImageLibWorkerResult) => {
                    if (result.type === MethodType.CompressBitmap) {
                        resolve(result.ret.data)
                    } else {
                        reject(this.buildAndReportError(cx, MethodType.CompressBitmap))
                    }
                })
            })
        })
    }

    public async decompressBitmap(cx: ImageLibContext, data: Uint8Array): Promise<Uint8Array> {
        const id = this.nextId++
        return this.ensureInited(cx).then((worker) => {
            const argument: ImageLibWorkerArgument = {
                type: MethodType.DecompressBitmap,
                id,
                arg: {
                    data,
                },
            }
            // 这里不要填 transfer 对象
            worker.postMessage(argument, [])

            return new Promise((resolve, reject) => {
                this.callbacks.set(id, (result: ImageLibWorkerResult) => {
                    if (result.type === MethodType.DecompressBitmap && result.ret.data !== null) {
                        resolve(result.ret.data)
                    } else {
                        reject(this.buildAndReportError(cx, MethodType.DecompressBitmap))
                    }
                })
            })
        })
    }

    public async imageBitmapToBytesUsingOffscreenCanvas(
        cx: ImageLibContext,
        imageBitmap: ImageBitmap
    ): Promise<Uint8Array | null> {
        const id = this.nextId++
        return this.ensureInited(cx).then((worker) => {
            const argument: ImageLibWorkerArgument = {
                type: MethodType.ImageBitmapToBytesUsingOffscreenCanvas,
                id,
                arg: {
                    data: imageBitmap,
                },
            }
            // 这里不要填 transfer 对象
            worker.postMessage(argument, [])

            return new Promise((resolve, reject) => {
                this.callbacks.set(id, (result: ImageLibWorkerResult) => {
                    if (result.type === MethodType.ImageBitmapToBytesUsingOffscreenCanvas) {
                        resolve(result.ret.data)
                    } else {
                        reject(this.buildAndReportError(cx, MethodType.ImageBitmapToBytesUsingOffscreenCanvas))
                    }
                })
            })
        })
    }

    public async transcodeKtx2(
        cx: ImageLibContext,
        data: Uint8Array,
        format: TextureCompressionFormat
    ): Promise<Uint8Array> {
        const id = this.nextId++
        return this.ensureInited(cx).then((worker) => {
            const argument: ImageLibWorkerArgument = {
                type: MethodType.TranscodeKtx2,
                id,
                arg: {
                    data,
                    format,
                    wasmDownloadPrefix: cx.env.wasmDownloadPrefix,
                },
            }
            // 这里不要填 transfer 对象
            worker.postMessage(argument, [])

            return new Promise((resolve, reject) => {
                this.callbacks.set(id, (result: ImageLibWorkerResult) => {
                    if (result.type === MethodType.TranscodeKtx2 && result.ret.data) {
                        resolve(result.ret.data)
                    } else {
                        reject(this.buildAndReportError(cx, MethodType.TranscodeKtx2))
                    }
                })
            })
        })
    }

    private buildAndReportError(cx: ImageLibContext, code: MethodType): ImageLibWorkerError {
        const e = new ImageLibWorkerError(code)
        cx.logger.throttleLog('WK_IMAGE_LIB_ERROR', {
            module: 'worker',
            code,
        })
        return e
    }
}
