import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    PleaseDoNot: '文件名称请不要超过 50 字',
    AFileMust: '文件名称不可以为空',
    "Can'tFindObject": '找不到目标项目，移动失败',
} as const

export const enTranslation = {
    PleaseDoNot: 'Name is too long (Maximum is 100 characters)',
    AFileMust: 'A file must have a name',
    "Can'tFindObject": 'An error occurred while moving file',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
