/* eslint-disable no-restricted-imports */
import { useCallback, useEffect, useRef } from 'react'
import type { ColorStop } from '../../../../../document/node/node'
import { getColorStopByPosition } from './utils'

interface RibbonProps {
    colorStops: ReadonlyArray<ColorStop>
    width: number
    height: number
    isCycle?: boolean
    onAddColorStop?: (colorStop: ColorStop) => void
}

export function Ribbon(props: RibbonProps) {
    const { colorStops, width, height, isCycle, onAddColorStop } = props
    const canvasAlpha = useRef<HTMLCanvasElement>(null)

    // 绘制 opacity 区域
    useEffect(() => {
        if (!canvasAlpha.current) return
        const ctx = canvasAlpha.current.getContext('2d')
        if (!ctx) return
        let { width: _width, height: _height } = canvasAlpha.current.getBoundingClientRect()
        _width = Math.floor(_width)
        _height = Math.floor(_height)
        canvasAlpha.current.width = _width
        canvasAlpha.current.height = _height

        ctx.fillStyle = '#e3e3e3'
        const rectWidth = 3
        for (let i = 0; i < _height; i = i + rectWidth) {
            const offset = (i / rectWidth) % 2 ? 0 : rectWidth
            for (let j = offset; j < _width; j = j + 2 * rectWidth) {
                ctx.fillRect(j, i, rectWidth, rectWidth)
            }
        }

        const linearGradient = ctx.createLinearGradient(0, 0, _width, 0)
        let maxColorStop: ColorStop = colorStops[0]
        let minColorStop: ColorStop = colorStops[0]
        for (const colorStop of colorStops) {
            const { position, color } = colorStop
            if (position > maxColorStop.position) {
                maxColorStop = colorStop
            } else if (position < minColorStop.position) {
                minColorStop = colorStop
            }
            linearGradient.addColorStop(
                Math.min(Math.max(position, 0), 1),
                `rgba(${color.r},${color.g},${color.b}, ${color.a / 255})`
            )
        }
        const cyclePointColorStop = getCyclePointColorStop(colorStops)
        if (maxColorStop.position < 1) {
            const { color } = isCycle ? cyclePointColorStop.endColorStop : maxColorStop
            linearGradient.addColorStop(1, `rgba(${color.r},${color.g},${color.b}, ${color.a / 255})`)
        }
        if (minColorStop.position > 0) {
            const { color } = isCycle ? cyclePointColorStop.startColorStop : minColorStop
            linearGradient.addColorStop(0, `rgba(${color.r},${color.g},${color.b}, ${color.a / 255})`)
        }
        ctx.fillStyle = linearGradient
        ctx.fillRect(0, 0, _width, _height)
    }, [colorStops, isCycle])

    const onPointerDown = useCallback(
        (e: any) => {
            const rect = e.target.getBoundingClientRect()
            const position = e.nativeEvent.offsetX / rect.width
            let colorStop: ColorStop
            if (isCycle) {
                const { startColorStop, endColorStop } = getCyclePointColorStop(colorStops)
                colorStop = getColorStopByPosition([startColorStop, endColorStop, ...colorStops], position)
            } else {
                colorStop = getColorStopByPosition(colorStops, position)
            }
            onAddColorStop?.(colorStop)
        },
        [colorStops, isCycle, onAddColorStop]
    )

    return (
        <canvas
            ref={canvasAlpha}
            width={width}
            height={height}
            style={{ width: '100%', height, borderRadius: 'inherit' }}
            onPointerDown={onPointerDown}
            data-testid="ribbon"
        />
    )
}

function getCyclePointColorStop(colorStops: readonly ColorStop[]) {
    let maxColorStop: ColorStop = colorStops[0]
    let minColorStop: ColorStop = colorStops[0]
    for (const colorStop of colorStops) {
        const { position } = colorStop
        if (position > maxColorStop.position) {
            maxColorStop = colorStop
        } else if (position < minColorStop.position) {
            minColorStop = colorStop
        }
    }
    const nearStart = Math.max(minColorStop.position, 0)
    const nearEnd = Math.max(1 - maxColorStop.position, 0)
    const distance = nearEnd + nearStart
    if (distance === 0) {
        return {
            startColorStop: minColorStop,
            endColorStop: maxColorStop,
        }
    }
    const nearStartRatio = nearStart / distance
    const nearEndRatio = 1 - nearStartRatio
    const getRatioValue = (numA: number, numB: number, ratio: number) => {
        if (numA > numB) {
            return Math.round(numA - (numA - numB) * ratio)
        } else {
            return Math.round(numA + (numB - numA) * ratio)
        }
    }
    const startColorStop = {
        position: 0,
        color: {
            r: getRatioValue(minColorStop.color.r, maxColorStop.color.r, nearStartRatio),
            g: getRatioValue(minColorStop.color.g, maxColorStop.color.g, nearStartRatio),
            b: getRatioValue(minColorStop.color.b, maxColorStop.color.b, nearStartRatio),
            a: getRatioValue(minColorStop.color.a, maxColorStop.color.a, nearStartRatio),
        },
    }
    const endColorStop = {
        position: 1,
        color: {
            r: getRatioValue(maxColorStop.color.r, minColorStop.color.r, nearEndRatio),
            g: getRatioValue(maxColorStop.color.g, minColorStop.color.g, nearEndRatio),
            b: getRatioValue(maxColorStop.color.b, minColorStop.color.b, nearEndRatio),
            a: getRatioValue(maxColorStop.color.a, minColorStop.color.a, nearEndRatio),
        },
    }
    return {
        startColorStop,
        endColorStop,
    }
}
