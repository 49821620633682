import { useCallback, useMemo } from 'react'
import { SingleGrid } from '../../../atom/grid/single-grid'
import { Mode } from '../common'
import { ChangeCallBackParams, RectArea } from '../rect-area/rect-area'
import { ConstraintDirection } from '../rect-area/rect-area-dom'
import style from './edit-area.module.less'
import { Constraint, SelectConstraintType } from './select-constraint-type'

import { Wukong } from '@wukong/bridge-proto'
import ConstraintType = Wukong.DocumentProto.ConstraintType

const defaultConstraint = { value: -1, mixed: false }

export interface EditAreaProps {
    horizontal?: Constraint
    vertical?: Constraint
    disabled?: boolean
    onChangeHorizontal?: (type: ConstraintType) => void
    onChangeVertical?: (type: ConstraintType) => void
    disableHorizontalFlexItems?: boolean
    disableVerticalFlexItems?: boolean
}

export function EditArea(props: EditAreaProps) {
    const {
        disabled,
        horizontal = defaultConstraint,
        vertical = defaultConstraint,
        onChangeHorizontal,
        onChangeVertical,
        disableHorizontalFlexItems,
        disableVerticalFlexItems,
    } = props

    const selectDirection = useMemo(() => {
        const _selectDirection: ConstraintDirection[] = []
        if (!horizontal.mixed) {
            _selectDirection.push(...constraintType2ConstraintDirection(horizontal.value, Mode.Horizontal))
        }
        if (!vertical.mixed) {
            _selectDirection.push(...constraintType2ConstraintDirection(vertical.value, Mode.Vertical))
        }
        return _selectDirection
    }, [horizontal, vertical])

    const getNextConstraintType = useCallback(
        (data: Constraint, params: ChangeCallBackParams, disableMetaShift = false) => {
            const { value, shiftKey, metaKey } = params
            let type: ConstraintType = constraintDirection2ConstraintType(value)
            if (!data.mixed && (shiftKey || metaKey) && !disableMetaShift) {
                type = transformConstraintType(data.value, type)
            }
            return type
        },
        []
    )

    const onChangeRectArea = useCallback(
        (params: ChangeCallBackParams) => {
            const mode = constraintDirection2Mode(params.value)
            if (mode === Mode.Horizontal) {
                const type = getNextConstraintType(horizontal, params, disableHorizontalFlexItems)
                if (!horizontal.mixed && horizontal.value === type) {
                    return
                }
                onChangeHorizontal?.(type)
            } else {
                const type = getNextConstraintType(vertical, params, disableVerticalFlexItems)
                if (!vertical.mixed && vertical.value === type) {
                    return
                }
                onChangeVertical?.(type)
            }
        },
        [
            getNextConstraintType,
            horizontal,
            disableHorizontalFlexItems,
            onChangeHorizontal,
            vertical,
            disableVerticalFlexItems,
            onChangeVertical,
        ]
    )

    return (
        <SingleGrid className={style.grid}>
            <SingleGrid.Item start={5} span={16}>
                <RectArea disabled={disabled} selectDirection={selectDirection} onChange={onChangeRectArea} />
            </SingleGrid.Item>
            <SingleGrid.Item start={23} span={28} className={style.selectContainer}>
                <SelectConstraintType
                    className={style.selectComponent}
                    mode={Mode.Horizontal}
                    disabled={disabled}
                    onChange={onChangeHorizontal}
                    disableFlex={disableHorizontalFlexItems}
                    {...horizontal}
                />
                <SelectConstraintType
                    className={style.selectComponent}
                    mode={Mode.Vertical}
                    disabled={disabled}
                    onChange={onChangeVertical}
                    disableFlex={disableVerticalFlexItems}
                    {...vertical}
                />
            </SingleGrid.Item>
        </SingleGrid>
    )
}

function constraintType2ConstraintDirection(constraintType: ConstraintType, mode: Mode): ConstraintDirection[] {
    if (mode === Mode.Horizontal) {
        switch (constraintType) {
            case ConstraintType.CONSTRAINT_TYPE_MIN:
                return [ConstraintDirection.Left]
            case ConstraintType.CONSTRAINT_TYPE_CENTER:
                return [ConstraintDirection.HCenter]
            case ConstraintType.CONSTRAINT_TYPE_MAX:
                return [ConstraintDirection.Right]
            case ConstraintType.CONSTRAINT_TYPE_STRETCH:
                return [ConstraintDirection.Left, ConstraintDirection.Right]
            default:
                return []
        }
    } else {
        switch (constraintType) {
            case ConstraintType.CONSTRAINT_TYPE_MIN:
                return [ConstraintDirection.Top]
            case ConstraintType.CONSTRAINT_TYPE_CENTER:
                return [ConstraintDirection.VCenter]
            case ConstraintType.CONSTRAINT_TYPE_MAX:
                return [ConstraintDirection.Bottom]
            case ConstraintType.CONSTRAINT_TYPE_STRETCH:
                return [ConstraintDirection.Top, ConstraintDirection.Bottom]
            default:
                return []
        }
    }
}

function constraintDirection2ConstraintType(
    constraintDirection: ConstraintDirection
): ConstraintType.CONSTRAINT_TYPE_MIN | ConstraintType.CONSTRAINT_TYPE_MAX | ConstraintType.CONSTRAINT_TYPE_CENTER {
    switch (constraintDirection) {
        case ConstraintDirection.Left:
        case ConstraintDirection.Top:
            return ConstraintType.CONSTRAINT_TYPE_MIN
        case ConstraintDirection.HCenter:
        case ConstraintDirection.VCenter:
            return ConstraintType.CONSTRAINT_TYPE_CENTER
        case ConstraintDirection.Bottom:
        case ConstraintDirection.Right:
            return ConstraintType.CONSTRAINT_TYPE_MAX
    }
}
function constraintDirection2Mode(constraintDirection: ConstraintDirection): Mode {
    switch (constraintDirection) {
        case ConstraintDirection.Left:
        case ConstraintDirection.HCenter:
        case ConstraintDirection.Right:
            return Mode.Horizontal
        case ConstraintDirection.Top:
        case ConstraintDirection.VCenter:
        case ConstraintDirection.Bottom:
            return Mode.Vertical
    }
}

function transformConstraintType(
    oldType: ConstraintType,
    newType:
        | ConstraintType.CONSTRAINT_TYPE_MIN
        | ConstraintType.CONSTRAINT_TYPE_MAX
        | ConstraintType.CONSTRAINT_TYPE_CENTER
): ConstraintType {
    switch (newType) {
        case ConstraintType.CONSTRAINT_TYPE_CENTER: {
            switch (oldType) {
                case ConstraintType.CONSTRAINT_TYPE_CENTER:
                    return ConstraintType.CONSTRAINT_TYPE_SCALE
                default:
                    return ConstraintType.CONSTRAINT_TYPE_CENTER
            }
        }
        case ConstraintType.CONSTRAINT_TYPE_MIN: {
            switch (oldType) {
                case ConstraintType.CONSTRAINT_TYPE_MIN:
                    return ConstraintType.CONSTRAINT_TYPE_SCALE
                case ConstraintType.CONSTRAINT_TYPE_MAX:
                    return ConstraintType.CONSTRAINT_TYPE_STRETCH
                case ConstraintType.CONSTRAINT_TYPE_STRETCH:
                    return ConstraintType.CONSTRAINT_TYPE_MAX
                default:
                    return ConstraintType.CONSTRAINT_TYPE_MIN
            }
        }
        case ConstraintType.CONSTRAINT_TYPE_MAX: {
            switch (oldType) {
                case ConstraintType.CONSTRAINT_TYPE_MIN:
                    return ConstraintType.CONSTRAINT_TYPE_STRETCH
                case ConstraintType.CONSTRAINT_TYPE_MAX:
                    return ConstraintType.CONSTRAINT_TYPE_SCALE
                case ConstraintType.CONSTRAINT_TYPE_STRETCH:
                    return ConstraintType.CONSTRAINT_TYPE_MIN
                default:
                    return ConstraintType.CONSTRAINT_TYPE_MAX
            }
        }
        default:
            return ConstraintType.CONSTRAINT_TYPE_MIN
    }
}
