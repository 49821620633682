import constate from 'constate'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { RouteToken } from '../../../../util/src'
import { SpaceLogs } from '../../kernel/service/space-frog-service/logs'
import { useSpaceStore } from '../app/zustand/space-state'
import { ShowType } from '../util/types'

export enum SearchType {
    doc = 'doc',
    folder = 'folder',
    team = 'team',
}

export const useSearch = () => {
    const { searchType, setSearchType, fetchData } = useSpaceStore.use.searchPageStore()
    const { pathname } = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const typeStr = searchParams.get('type') ?? ''
    const qs = searchParams.get('qs') ?? ''
    const isSearchPage = pathname.includes(`/${RouteToken.Search}`)
    const [showTypeMap, setShowTypeMap] = useState<Record<SearchType, ShowType>>({
        [SearchType.doc]: ShowType.Grid,
        [SearchType.folder]: ShowType.Grid,
        [SearchType.team]: ShowType.Grid,
    })
    const showType = useMemo(() => showTypeMap[searchType], [showTypeMap, searchType])

    useEffect(() => {
        if (isSearchPage && typeStr in SearchType) {
            setSearchType(typeStr as SearchType)
            switch (typeStr) {
                case SearchType.doc:
                    SpaceLogs.SearchResultPage.fileSearchResultPage()
                    break
                case SearchType.folder:
                    SpaceLogs.SearchResultPage.projectSearchResultPage()
                    break
                case SearchType.team:
                    SpaceLogs.SearchResultPage.teamSearchResultPage()
                    break
                default:
                    break
            }
        }
    }, [typeStr, isSearchPage, setSearchType])

    const updateSearchType = useCallback(
        (type: SearchType) => {
            if (isSearchPage && type !== typeStr) {
                searchParams.set('type', type)
                setSearchParams(searchParams, { replace: true })
            }
        },
        [searchParams, isSearchPage, typeStr, setSearchParams]
    )

    const updateQs = useCallback(
        (value: string) => {
            if (isSearchPage && value !== qs) {
                searchParams.set('qs', value)
                setSearchParams(searchParams, { replace: true })
            }
        },
        [searchParams, isSearchPage, qs, setSearchParams]
    )

    const onSearch = useCallback(
        async (value: string, type?: SearchType, fromInput?: boolean) => {
            type && updateSearchType(type)
            updateQs(value)
            fetchData(value, fromInput)
        },
        [fetchData, updateQs, updateSearchType]
    )

    const toggleShowType = useCallback(() => {
        setShowTypeMap((prev) => {
            const newShowType = prev[searchType] === ShowType.Grid ? ShowType.List : ShowType.Grid
            return {
                ...prev,
                [searchType]: newShowType,
            }
        })
    }, [searchType])

    return {
        isSearchPage,
        showType,
        toggleShowType,
        onSearch,
    }
}

export const [SearchProvider, useSearchContext] = constate(useSearch, (ctx) => ctx)
