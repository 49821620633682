import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    SwapInstanceFailed: '替换实例失败',
    SwappingInstance: '正在替换实例',
} as const

export const enTranslation = {
    SwapInstanceFailed: 'An error occurred while swapping instance',
    SwappingInstance: 'Swapping instance',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
