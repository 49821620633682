import constate from 'constate'
import { useCallback } from 'react'
import { useMenuContextState } from '../component/top-area/menu/context/menu-context'
import { useFontManagerService } from './document-context'
import { useDocumentMissFont } from './use-document-miss-font'

function useDocumentMissingFontContext() {
    const {
        isOpenFontMissDialog,
        setIsOpenCandidatePublishDialog,
        setIsOpenFontMissDialog,
        setIsOpenAiReplaceComponent,
    } = useMenuContextState()

    const fontManagerService = useFontManagerService()
    const allFontInfosLoaded = fontManagerService.states.use.allFontInfosLoaded()

    const openDialog = useCallback(() => {
        if (!allFontInfosLoaded) {
            return
        }
        setIsOpenFontMissDialog(true)
        setIsOpenCandidatePublishDialog(false)
        setIsOpenAiReplaceComponent(false)
        fontManagerService.triggerMissingFontDialogSubject()
    }, [
        allFontInfosLoaded,
        setIsOpenFontMissDialog,
        setIsOpenCandidatePublishDialog,
        setIsOpenAiReplaceComponent,
        fontManagerService,
    ])

    const closeDialog = useCallback(() => {
        setIsOpenFontMissDialog(false)
    }, [setIsOpenFontMissDialog])

    const fonts = fontManagerService.states.use.allFontInfosState()
    const { missFont, inspectMissFont, replaceMissFont, uid2CountMap, uid2FullFontName } = useDocumentMissFont(
        fonts,
        openDialog
    )

    const fontMissingDialogState = fontManagerService.states.use.fontMissingDialogState()

    return {
        isOpenFontMissDialog,
        openDialog,
        closeDialog,
        fonts,
        missFont,
        inspectMissFont,
        replaceMissFont,
        uid2CountMap,
        uid2FullFontName,
        allFontInfosLoaded,
        fontMissingDialogState,
    }
}

export const [DocumentMissingFontContextProvider, useDocumentMissingFont] = constate(useDocumentMissingFontContext)
