/* eslint-disable @typescript-eslint/ban-types */

import { BaseVM, DefinePropDescriptor, Handle, INTERNAL_SYMBOL } from './vm-interface'

export class MainVM extends BaseVM {
    vmType = 'mainVM' as const
    undefined = this.wrapHandle(undefined)
    global = this.wrapHandle(globalThis)
    null = this.wrapHandle(null)

    wrapHandle(value: any): Handle {
        if (this.destroyed) {
            throw new Error('VM is destroyed')
        }

        if (typeof value === 'object' && value !== null && value[INTERNAL_SYMBOL]) {
            throw Error('Cannot wrap handle itself')
        }

        return {
            [INTERNAL_SYMBOL]: value,
        }
    }

    unwrapHandle(handle: Handle) {
        if (this.destroyed) {
            throw new Error('VM is destroyed')
        }

        if (typeof handle !== 'object' || handle === null || !(INTERNAL_SYMBOL in handle)) {
            throw Error('Invalid handle')
        }

        return handle[INTERNAL_SYMBOL]
    }

    typeof(handle: Handle) {
        return typeof this.unwrapHandle(handle)
    }

    isNumber(handle: Handle) {
        return typeof this.unwrapHandle(handle) === 'number'
    }

    getNumber(handle: Handle) {
        return Number(this.unwrapHandle(handle))
    }

    newNumber(value: number) {
        return this.wrapHandle(value)
    }

    isString(handle: Handle) {
        return typeof this.unwrapHandle(handle) === 'string'
    }

    getString(handle: Handle) {
        return String(this.unwrapHandle(handle))
    }

    newString(value: string) {
        return this.wrapHandle(value)
    }

    isBoolean(handle: Handle) {
        return typeof this.unwrapHandle(handle) === 'boolean'
    }

    getBoolean(handle: Handle) {
        return Boolean(this.unwrapHandle(handle))
    }

    newBoolean(value: boolean) {
        return this.wrapHandle(value)
    }

    override toBoolean(value: Handle): boolean {
        return !!this.unwrapHandle(value)
    }

    isArray(handle: Handle) {
        return Array.isArray(this.unwrapHandle(handle))
    }

    newArray() {
        return this.wrapHandle([])
    }

    isObject(handle: Handle) {
        return typeof this.unwrapHandle(handle) === 'object' && this.unwrapHandle(handle) !== null
    }

    newObject(protoHandle?: any) {
        if (!protoHandle) {
            return this.wrapHandle({})
        }

        return this.wrapHandle(Object.create(this.unwrapHandle(protoHandle)))
    }

    isFunction(handle: Handle) {
        return typeof this.unwrapHandle(handle) === 'function'
    }

    isNull(handle: Handle) {
        return this.unwrapHandle(handle) === null
    }

    isUndefined(handle: Handle) {
        return this.unwrapHandle(handle) === undefined
    }

    isArrayBuffer(handle: Handle) {
        return this.unwrapHandle(handle) instanceof ArrayBuffer
    }

    getArrayBuffer(handle: Handle) {
        return ArrayBuffer.prototype.slice.call(this.unwrapHandle(handle), 0)
    }

    newArrayBuffer(value: ArrayBuffer) {
        return this.wrapHandle(ArrayBuffer.prototype.slice.call(value, 0))
    }

    isUint8Array(handle: Handle) {
        return this.unwrapHandle(handle) instanceof Uint8Array
    }

    getUint8Array(handle: Handle) {
        return new Uint8Array(this.unwrapHandle(handle))
    }

    newUint8Array(value: Uint8Array) {
        return this.wrapHandle(new Uint8Array(value))
    }

    isEqual(left: Handle, right: Handle) {
        return this.unwrapHandle(left) === this.unwrapHandle(right)
    }

    newFunction(name: string, func: Function) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm = this
        const wrappedFunc = function (...args: any[]) {
            let result
            const thisArg = vm.wrapHandle(this)
            const wrappedArgs = args.map((arg) => vm.wrapHandle(arg))
            while (wrappedArgs.length < func.length) wrappedArgs.push(vm.undefined)
            try {
                result = func.apply(thisArg, wrappedArgs)
            } catch (error: any) {
                let errorMessage = ''
                if (error?.message) {
                    errorMessage = `: ${error.message}`
                }
                throw Error(`in ${name} ${errorMessage}`)
            }
            return result === undefined ? undefined : vm.unwrapHandle(result)
        }
        Object.defineProperty(wrappedFunc, 'name', { writable: false, value: name })
        return vm.wrapHandle(wrappedFunc)
    }

    newPromise() {
        let resolveFunc: (value: any) => void = () => {}
        let rejectFunc: (reason?: any) => void = () => {}
        const promise = new Promise((resolve, reject) => {
            resolveFunc = resolve
            rejectFunc = reject
        })
        return {
            promise: this.wrapHandle(promise),
            resolve: (handle: Handle) => resolveFunc(this.unwrapHandle(handle)),
            reject: (handle: Handle) => rejectFunc(this.unwrapHandle(handle)),
        }
    }

    getProp(objHandle: Handle, prop: string) {
        const obj = this.unwrapHandle(objHandle)
        return this.wrapHandle(obj[prop])
    }

    setProp(objHandle: Handle, prop: string, valueHandle: Handle) {
        const obj = this.unwrapHandle(objHandle)
        const value = this.unwrapHandle(valueHandle)
        obj[prop] = value
    }

    defineProp(objHandle: Handle, prop: string, descriptor: DefinePropDescriptor) {
        const desc: PropertyDescriptor = {}
        if (descriptor.configurable !== undefined) {
            desc.configurable = !!descriptor.configurable
        }
        if (descriptor.enumerable !== undefined) {
            desc.enumerable = !!descriptor.enumerable
        }
        if (descriptor.writable !== undefined) {
            desc.writable = !!descriptor.writable
        }
        if (descriptor.value !== undefined) {
            desc.value = this.unwrapHandle(descriptor.value)
        }
        if (descriptor.get !== undefined) {
            desc.get = this.unwrapHandle(this.newFunction('get_' + prop, descriptor.get))
        }
        if (descriptor.set !== undefined) {
            desc.set = this.unwrapHandle(this.newFunction('set_' + prop, descriptor.set))
        }
        Object.defineProperty(this.unwrapHandle(objHandle), prop, desc)
    }

    callFunction(funcHandle: Handle, thisArgHandle: Handle, ...argHandlesParam: Handle[]) {
        const func = this.unwrapHandle(funcHandle)
        const thisArg = this.unwrapHandle(thisArgHandle)
        const argHandles = argHandlesParam ? argHandlesParam : []
        const args = argHandles.map((arg) => this.unwrapHandle(arg))
        try {
            const result = Function.prototype.apply.call(func, thisArg, args)
            return {
                type: 'SUCCESS',
                handle: this.wrapHandle(result),
            } as const
        } catch (error) {
            console.error(error)
            return {
                type: 'FAILURE',
                error,
            } as const
        }
    }

    getKeys(handle: Handle) {
        return Object.keys(this.unwrapHandle(handle))
    }

    evalCode(code: string) {
        try {
            const result = (0, eval)('"use strict";\n' + code)
            return {
                type: 'SUCCESS',
                handle: this.wrapHandle(result),
            } as const
        } catch (error) {
            console.error(error)
            return {
                type: 'FAILURE',
                error,
            } as const
        }
    }

    retainHandle(_: Handle) {}

    releaseHandle(_: Handle) {}
}
