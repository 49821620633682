import { isSafari } from '../../../../../app/src/kernel/util/ua'
// eslint-disable-next-line import/no-restricted-paths
import { isKeyAsEnter } from '../../../../../app/src/kernel/keyboard/keyboard-event-handler'

const IS_COMPOSING_MAX_DELAY_TIME = 20

/**
 * @description 兼容safari
 * @param e 键盘事件
 * @param compositionEndTime 输入法合成结束时间: Date.now()
 */
export function isComposing(e: KeyboardEvent, compositionEndTime: number) {
    if (e.isComposing) {
        return true
    }
    if (isKeyAsEnter(e) && isSafari()) {
        // 结束输入法合成。compositionend 后isComposing 为false
        // chrome 先keydown 后compositionend。 safari 先compositionend 后keydown。
        // 这里把safari 结束输入法合成的 enter视为输入法合成期间的键盘事件
        const isComposingSafari = Math.abs(Date.now() - compositionEndTime) < IS_COMPOSING_MAX_DELAY_TIME
        // 回车通常会提交数据。如果不做区分，在safari上输入法合成期间输入回车会结束合成输入，同时提交数据。（预期是两次回车）
        // 对于safari其他键盘结束输入法合成都有这个顺序问题，不过目前仅enter影响体验。这里仅处理下 enter
        return isComposingSafari
    }
    return false
}
