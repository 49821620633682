import { domLocation } from '../../../../util/src'
import { environment } from '../../environment'
import { GetAppVersion } from '../../kernel/request/app-version'
import { WkCLog } from '../../kernel/clog/wukong/instance'
import { DESKTOP_CLIENT_CAN_OPEN_URL, DESKTOP_CLIENT_OPEN_URL } from './desktop-config'

const HTTP_TIMEOUT = 15000
const createAbortController = (duration: number) => {
    const controller = new AbortController()
    const timerId = setTimeout(() => controller.abort(), duration)

    return { signal: controller.signal, clear: () => clearTimeout(timerId) }
}
/**
 *
 * @returns 获取当前域名是否能在桌面端打开
 */
export const getCanOpenThisDomainInDesktop = () => {
    const { signal, clear } = createAbortController(HTTP_TIMEOUT)
    return fetch(DESKTOP_CLIENT_CAN_OPEN_URL + '?url=' + domLocation().hostname, { signal })
        .then((resp) => resp.json())
        .then((data) => data?.data?.canOpen === true)
        .catch(() => {
            return false
        })
        .finally(() => clear())
}

/**
 *
 * @returns 在桌面端打开当前页
 */
export const openCurrentPageInDesktop = () => {
    const { signal, clear } = createAbortController(HTTP_TIMEOUT)
    return fetch(DESKTOP_CLIENT_OPEN_URL + '?url=' + domLocation().href, { signal })
        .then((resp) => resp.json())
        .then((data) => {
            if (data?.data?.success !== true) {
                WkCLog.log(
                    `openCurrentPageInDesktop fail, url = ${domLocation().hostname}, resp = ${JSON.stringify(data)}`
                )
            }
        })
        .catch(() => {
            return false
        })
        .finally(() => clear())
}

export const downloadDesktopApp = () => {
    const isMac = navigator.userAgent.includes('Mac')
    const productId = environment.isAbroad ? (isMac ? 41000008 : 41000007) : isMac ? 41000004 : 41000005
    const appVersion = '0.0.0'
    const platform = isMac ? 'mac' : 'win'

    new GetAppVersion(productId, appVersion, platform).start().then((data) => {
        const installUrl = data?.currentVersion?.url
        if (installUrl) {
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = installUrl
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    })
}
