export interface RangeBucket {
    readonly min: number
    readonly max: number
    count: number
}

// 捅之间的范围是连续的
export type SequentialBucket = RangeBucket[]

function findRangeBucket(buckets: SequentialBucket, value: number): RangeBucket {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < buckets.length; i += 1) {
        const bucket = buckets[i]
        if (value >= bucket.min && value < bucket.max) {
            return bucket
        }
    }
    throw new Error('should not reach here')
}

export function incInRangeBucket(buckets: SequentialBucket, value: number) {
    const durationBucket = findRangeBucket(buckets, value)
    durationBucket.count += 1
}

export function cloneRangeBucket(buckets: RangeBucket[]) {
    const newBuckets = new Array(buckets.length)

    for (let i = 0; i < buckets.length; i += 1) {
        newBuckets[i] = { ...buckets[i] }
    }

    return newBuckets
}

/**
 * 初始化 范围以 2 的幂为底的顺序桶
 * @param max
 */
export function initSequentialBucketOfTwoPow(max: number, min = 0) {
    const buckets: RangeBucket[] = []
    for (let i = min; i <= max; i += 1) {
        buckets.push({
            min: i === 0 ? 0 : 2 ** (i - 1),
            max: 2 ** i,
            count: 0,
        })
    }
    buckets.push({
        min: 2 ** max,
        max: Infinity,
        count: 0,
    })
    return buckets
}
