import { MotiffApi } from '@motiffcom/plugin-api-types'
import { SandboxLifecycle } from '../main/sandbox'

export type SandboxWindow = Window & {
    motiff: MotiffApi.PluginAPI
    sandbox: ReturnType<SandboxLifecycle['getContextForAPI']>
}

export enum SandboxEvent {
    Ready = 'sandbox-ready',
}
