import {
    CmdCheckFontMissing,
    DownloadFont,
    HandleTextMissingFontCommand,
    LoadFontInJsCommand,
    LoadFontInJsFailedCommand,
    ReadFontInJs,
    UpdateUsableFontInfoCommandForJs,
    Wukong,
} from '@wukong/bridge-proto'
import { keyBy } from 'lodash-es'
import { createSelectors, createStore, isDocumentVisible } from '../../../../util/src'
import { createDebouncedSwitchTask } from '../../../../util/src/abort-controller/signal-task'
import { signalTimeout } from '../../../../util/src/abort-controller/timers'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { deferedPromise } from '../../../../util/src/defered-promise'
import { FontName } from '../../document/node/node'
import { isEqualFontName } from '../../document/util/font'
import { environment, IN_JEST_TEST } from '../../environment'
import { benchmarkService } from '../../kernel/benchmark'
import { EmBridge } from '../../kernel/bridge/em-bridge'
import { WkCLog } from '../../kernel/clog/wukong/instance'
import { PostscriptFamilyStyleVO } from '../../kernel/interface/font'
import { DocWithAuthorityVO } from '../../kernel/interface/type'
import { UploadPostscriptFamilyStyle } from '../../kernel/request/font'
import { featureSwitchManager } from '../../kernel/switch/core'
import { LocalStorageKey } from '../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../web-storage/local-storage/storage'
import { createFileManager } from '../create-file-manager'
import {
    fetchCloudFontInfo,
    fetchDocumentInfo,
    fetchOrgFontInfo,
    fetchPrototypeDocumentInfo,
    fetchTeamFontInfo,
} from './cloud-font'
import { FALLBACK_FONT_FILE_MAP } from './font-config'
import { DownloadPriority, DownloadTask, FontDownloadScheduler } from './font-download-scheduler'
import {
    FontInfoExt,
    FontNameExt,
    FontPluginDetails,
    LocalFontPluginStatus,
    LocalFontsAndAppVersion,
} from './interface'
import { fetchLocalFontInfo, fetchLocalFonts, getLocalFontFileUrl } from './local-font'
import { loadFontFile, mergeFontInfos, mergeLocalFontInfo, sortFontInfos } from './util'

const ONE_DAY = 24 * 60 * 60 * 1000

export class FontManagerService {
    public states = createSelectors(
        createStore<{
            cloudFontInfosState: FontInfoExt[]
            teamFontInfosState: FontInfoExt[]
            orgFontInfosState: FontInfoExt[]
            localFontInfosState: FontInfoExt[]
            allFontInfosState: FontInfoExt[]
            filteredSharedFontsState: FontInfoExt[]
            family2FontInfoMapState: { [family: string]: FontInfoExt }
            docInfoState: DocWithAuthorityVO | null
            pluginDetails: FontPluginDetails
            pluginInstallPointShowIfPossible: boolean
            allFontInfosLoaded: boolean
            fontMissingDialogState: 'LOADING' | 'LOADED'
        }>(
            () => ({
                cloudFontInfosState: [],
                teamFontInfosState: [],
                orgFontInfosState: [],
                localFontInfosState: [],
                allFontInfosState: [],
                filteredSharedFontsState: [],

                family2FontInfoMapState: {},
                docInfoState: null,
                pluginDetails: {
                    versionUrl: '',
                    versionDesc: '',
                    showReminder: false,
                    pluginStatus: 'silentIgnore',
                },
                pluginInstallPointShowIfPossible: true,
                allFontInfosLoaded: false,
                fontMissingDialogState: 'LOADED',
            }),
            environment.isDev
        )
    )

    private readonly allFontInfosLoadedPromise = deferedPromise<void>()

    private downloadTaskCache = new Map<string, Promise<{ data: Uint8Array; url: string }>>()
    private fontMap = new Map<string, Uint8Array>()
    private downloadScheduler: FontDownloadScheduler

    private openFontMissingDialogCallback: null | (() => void) = null
    private handleReFetchLocalFontInfos: () => void = () => {}

    private isInited = false
    private preLoadedCloudFontPromise: Promise<FontInfoExt[]>
    private preLoadedLocalFontPromise: Promise<LocalFontsAndAppVersion>
    private preLoadedSharedFontPromise: Promise<{ teamFont: FontInfoExt[]; orgFont: FontInfoExt[] }>

    constructor(
        private readonly signal: TraceableAbortSignal,
        private readonly bridge: EmBridge,
        private readonly docId: string,
        private readonly cloudFontPromise?: Promise<FontInfoExt[]>,
        private readonly localFontPromise?: Promise<LocalFontsAndAppVersion>,
        private readonly isMirror = false,
        private readonly pageId?: string
    ) {
        signal.addEventListener('abort', () => {
            this.destroy()
        })

        this.initJsCall()

        this.preLoadedCloudFontPromise = this.fetchCloudFontInfos()
        this.preLoadedSharedFontPromise = this.fetchSharedFontInfos(this.docId)
        this.preLoadedLocalFontPromise = this.fetchLocalFontInfos()

        this.initReFetchLocalFontInfos()
        this.tryUploadUserLocalFontIfNeed()

        this.downloadScheduler = new FontDownloadScheduler(
            this.processDownloadFont, // 实际加载字体的方法
            {
                onSuccess: this.handleDownloadSuccess,
                onError: this.handleDownloadError,
            }
        )
    }

    private initJsCall() {
        this.bridge.bind(HandleTextMissingFontCommand, () => {}, { signal: this.signal })
        this.bridge.bind(CmdCheckFontMissing, () => ({ value: false }), { signal: this.signal })
        this.bridge.bind(DownloadFont, this.handleDownloadFont, { signal: this.signal })
        this.bridge.bind(ReadFontInJs, this.doReadFontInJs, { signal: this.signal })
    }

    private initReFetchLocalFontInfos() {
        // NOTE: 本地字体列表自动刷新逻辑
        this.handleReFetchLocalFontInfos = createDebouncedSwitchTask(
            this.signal,
            async (signal: TraceableAbortSignal) => {
                this.refetchLocalFontInfos(signal)
            },
            500,
            {
                leading: true,
                trailing: false,
            }
        )

        window.addEventListener(
            'focus',
            () => {
                this.handleReFetchLocalFontInfos()
            },
            { signal: this.signal }
        )

        document.addEventListener(
            'visibilitychange',
            () => {
                if (isDocumentVisible()) {
                    this.handleReFetchLocalFontInfos()
                }
            },
            { signal: this.signal }
        )
    }

    // 记录 bootstrap 阶段 download font 的数量
    private downloadFontCountInBootstrap = 0
    private downloadFontStartTime: undefined | number = undefined

    private tryBenchmarkDownloadFontDuration = () => {
        this.downloadFontCountInBootstrap -= 1
        if (this.downloadFontCountInBootstrap === 0 && this.downloadFontStartTime) {
            benchmarkService.benchmarkFontDownloadTimeInBootstrap(performance.now() - this.downloadFontStartTime)
        }
    }

    private handleDownloadFont = async (proto: Wukong.DocumentProto.IArg_downloadFont) => {
        if (proto.loadInBoostrap) {
            this.downloadFontStartTime = this.downloadFontStartTime ? this.downloadFontStartTime : performance.now()
        }

        const finishCallback = (data: Uint8Array, url: string, fontName: Wukong.DocumentProto.IFontName) => {
            this.bridge.call(LoadFontInJsCommand, {
                url,
                size: data.length,
                fontName,
                textNodeId: proto.textNodeId,
                isFallback: proto.isFallback,
            })
        }

        const failedCallback = (fontName: Wukong.DocumentProto.IFontName) => {
            this.bridge.call(LoadFontInJsFailedCommand, {
                fontName,
                textNodeId: proto.textNodeId,
                isFallback: proto.isFallback ?? false,
            })
        }

        for (const font of proto.fontNames!) {
            let fontPath = ''
            const fontSelectEnabled = featureSwitchManager.isEnabled('font-select')
            if (proto.isFallback) {
                fontPath = FALLBACK_FONT_FILE_MAP[font.family!]
            }

            if (!fontPath) {
                // NOTE: 异步获取字体文件路径, 因为字体列表需要从服务端和本地插件请求
                fontPath = await this.getFontFileUrl(font.family!, font as FontName)
            }

            const type = proto.type
            const priority =
                type === Wukong.DocumentProto.DownloadFontType.DOWNLOAD_FONT_TYPE_SET_FONT
                    ? DownloadPriority.SET_FONT
                    : type === Wukong.DocumentProto.DownloadFontType.DOWNLOAD_FONT_TYPE_PREVIEW
                    ? DownloadPriority.PREVIEW
                    : DownloadPriority.DEFAULT

            const downloadTask = {
                fontPath: fontPath,
                font: font,
                args: proto,
                priority: priority,
            }

            if (!fontPath) {
                if (fontSelectEnabled) {
                    this.handleDownloadError(downloadTask)
                } else {
                    // 避免 wasm 调用到 js 直接再调回 wasm
                    signalTimeout(
                        () => {
                            failedCallback(font)
                        },
                        0,
                        { signal: this.signal }
                    )
                }
                continue
            }

            if (fontSelectEnabled) {
                this.downloadScheduler.addTask(downloadTask)
            } else {
                // 数据已下载，直接回调即可
                const fontData = this.fontMap.get(fontPath)
                if (fontData) {
                    // 避免 wasm 调用到 js 直接再调回 wasm，导致 LoadFontInJsCommand 处于下载字体 cmd 的 flush 中，
                    // 进而导致属性读写校验失败
                    signalTimeout(
                        () => {
                            finishCallback(fontData, fontPath, font)
                        },
                        0,
                        { signal: this.signal }
                    )
                    continue
                }

                if (proto.loadInBoostrap) {
                    this.downloadFontCountInBootstrap += 1
                }

                const downloadStart = performance.now()

                // 创建或获取已有的下载任务，下载完回调并删除下载任务
                if (!this.downloadTaskCache.has(fontPath)) {
                    const task = loadFontFile(fontPath)
                    this.downloadTaskCache.set(fontPath, task)

                    const shouldLogTime = font.family === 'Source Han Sans SC' && font.style === 'Regular'

                    task.then(() => {
                        WkCLog.log('download font succeed', { url: fontPath })

                        if (shouldLogTime) {
                            // 粗略估计，小于 1s 认为是从 diskcache 读取的
                            const isFromCache = performance.now() - downloadStart < 1000
                            WkCLog.log(`Download Source Han Sans SC from ${isFromCache ? 'disk cache' : 'server'}`)
                        }
                    }).catch(() => {
                        WkCLog.log('download font failed', { url: fontPath })
                        benchmarkService.benchmarkFontDownloadSuccessRate(false)
                        if (proto.loadInBoostrap) {
                            this.tryBenchmarkDownloadFontDuration()
                        }
                    })
                }
                this.downloadTaskCache
                    .get(fontPath)!
                    .then((item) => {
                        this.fontMap.set(fontPath, item.data)
                        finishCallback(item.data, fontPath, font)
                        benchmarkService.benchmarkFontDownloadSuccessRate(true)
                        // 只 benchmark afterLoad 的字体
                        if (!proto.loadInBoostrap) {
                            benchmarkService.benchmarkFontLoadTimeAfterDocLoaded(performance.now() - downloadStart)
                        } else {
                            this.tryBenchmarkDownloadFontDuration()
                        }
                    })
                    .catch(() => {
                        failedCallback(font)
                    })
                    .finally(() => {
                        this.downloadTaskCache.delete(fontPath)
                    })
            }
        }
    }

    private processDownloadFont = async (task: DownloadTask): Promise<{ data: Uint8Array; url: string }> => {
        try {
            if (task.args.loadInBoostrap) {
                this.downloadFontCountInBootstrap += 1
            }

            const downloadStart = performance.now()
            const shouldLogTime = task.font.family === 'Source Han Sans SC' && task.font.style === 'Regular'

            // 下载数据
            const fontData = await loadFontFile(task.fontPath)

            // log & benchmark
            WkCLog.log('download font succeed', { url: task.fontPath })
            if (shouldLogTime) {
                // 粗略估计，小于 1s 认为是从 diskcache 读取的
                const isFromCache = performance.now() - downloadStart < 1000
                WkCLog.log(`Download Source Han Sans SC from ${isFromCache ? 'disk cache' : 'server'}`)
            }

            benchmarkService.benchmarkFontDownloadSuccessRate(true)
            // 只 benchmark afterLoad 的字体
            if (!task.args.loadInBoostrap) {
                benchmarkService.benchmarkFontLoadTimeAfterDocLoaded(performance.now() - downloadStart)
            } else {
                this.tryBenchmarkDownloadFontDuration()
            }

            return fontData
        } catch (error) {
            WkCLog.log('download font failed', { url: task.fontPath })
            benchmarkService.benchmarkFontDownloadSuccessRate(false)
            if (task.args.loadInBoostrap) {
                this.tryBenchmarkDownloadFontDuration()
            }
            throw error
        }
    }

    private handleDownloadSuccess = (task: DownloadTask, data: Uint8Array) => {
        // 避免 wasm 调用到 js 直接再调回 wasm，导致 LoadFontInJsCommand 处于下载字体 cmd 的 flush 中，
        // 进而导致属性读写校验失败
        signalTimeout(
            () => {
                this.bridge.call(LoadFontInJsCommand, {
                    url: task.fontPath,
                    size: data.length,
                    fontName: task.font,
                    textNodeId: task.args.textNodeId,
                    isFallback: task.args.isFallback,
                })
            },
            0,
            { signal: this.signal }
        )
    }

    private handleDownloadError = (task: DownloadTask) => {
        this.bridge.call(LoadFontInJsFailedCommand, {
            fontName: task.font,
            textNodeId: task.args.textNodeId,
            isFallback: task.args.isFallback ?? false,
        })
    }

    private doReadFontInJs = (proto: Wukong.DocumentProto.IArg_readFontInJs) => {
        if (proto.url == null || proto.position == null || proto.size == null || proto.ptr == null) {
            return
        }

        const font = featureSwitchManager.isEnabled('font-select')
            ? this.downloadScheduler.getFontData(proto.url)
            : this.fontMap.get(proto.url)
        if (font) {
            const dst = new Uint8Array(this.bridge.currentEditorService.HEAPU8.buffer, proto.ptr, proto.size)
            const src = font.subarray(proto.position, Math.min(proto.position + proto.size, font.length))
            dst.set(src)
        }
    }

    private fetchCloudFontInfos = async () => {
        try {
            return (await this.cloudFontPromise) ?? fetchCloudFontInfo()
        } catch (error) {
            WkCLog.log('[FontManagerService] fetch cloud font info failed')
            return []
        }
    }

    private fetchSharedFontInfos = async (documentId: string) => {
        if (createFileManager.isCreatingFile()) {
            // NOTE: 等待 createFile 完成才能获取docInfo
            await new Promise((resolve) => {
                createFileManager.injectCreateFileCallBack(() => {
                    resolve(void 0)
                })
            })
        }

        let docVo: DocWithAuthorityVO
        try {
            docVo = this.isMirror
                ? (await fetchPrototypeDocumentInfo(documentId, this.pageId!)).document!
                : await fetchDocumentInfo(documentId)
            this.states.setState({ docInfoState: docVo })
        } catch (error) {
            WkCLog.log('[FontManagerService] fetch document info failed', { documentId })
            return { teamFont: [], orgFont: [] }
        }

        if (IN_JEST_TEST) {
            // sandbox jest测试的docId为sandbox 无法正确获取docVo
            if (!docVo) {
                return { teamFont: [], orgFont: [] }
            }
        }

        const [teamFont, orgFont] = await Promise.all([this.fetchTeamFontInfos(docVo), this.fetchOrgFontInfos(docVo)])
        return { teamFont, orgFont }
    }

    private fetchOrgFontInfos = async (docVo: DocWithAuthorityVO) => {
        try {
            if (docVo.orgId != '-1') {
                return await fetchOrgFontInfo(docVo.orgId, docVo.id)
            } else {
                return []
            }
        } catch (error) {
            WkCLog.log('[FontManagerService] fetch org font info failed', { orgId: docVo.orgId })
            return []
        }
    }

    private fetchTeamFontInfos = async (docVo: DocWithAuthorityVO) => {
        try {
            if (!docVo.draft && docVo.teamId) {
                return await fetchTeamFontInfo(docVo.teamId, docVo.id)
            } else {
                return []
            }
        } catch (error) {
            WkCLog.log('[FontManagerService] fetch team font info failed', { teamId: docVo.teamId ?? '' })
            return []
        }
    }

    private async fetchLocalFontInfos() {
        return this.localFontPromise ?? fetchLocalFontInfo()
    }

    private async refetchLocalFontInfos(signal: TraceableAbortSignal) {
        if (!this.states.getState().allFontInfosLoaded) {
            // 字体列表未加载完成，不进行本地字体列表刷新
            return
        }

        this.states.setState({ fontMissingDialogState: 'LOADING' })

        // TODO(jiangzg): fetchLocalFontInfo 支持 abort
        const fontInfos = await fetchLocalFontInfo()
        signal.throwIfAborted()

        const currentLocalFontInfos = this.states.getState().localFontInfosState
        const { fonts, modified } = mergeLocalFontInfo(currentLocalFontInfos, fontInfos.fonts)

        if (modified) {
            const sortedLocal = sortFontInfos(fonts)
            const mergedFonts = mergeFontInfos({
                orgFont: this.states.getState().orgFontInfosState,
                teamFont: this.states.getState().teamFontInfosState,
                localFont: sortedLocal,
                cloudFont: this.states.getState().cloudFontInfosState,
            })

            const sortedFonts = sortFontInfos(mergedFonts.allFont)
            const sortedFilteredSharedFonts = sortFontInfos(mergedFonts.sharedFont)

            this.states.setState({
                localFontInfosState: sortedLocal,
                allFontInfosState: sortedFonts,
                filteredSharedFontsState: sortedFilteredSharedFonts,
                family2FontInfoMapState: keyBy(mergedFonts.allFont, (v) => v.family),
                allFontInfosLoaded: true,
            })

            this.updateUsableFontInfos(sortedFonts)
        }
        // 更新字体插件版本信息
        if (this.checkNeedUpdatePluginDetails(fontInfos.pluginStatus)) {
            this.states.setState({
                pluginDetails: {
                    ...fontInfos.pluginVersionInfo,
                    pluginStatus: this.getFontPluginState(
                        fontInfos.pluginStatus,
                        fontInfos.pluginVersionInfo.upgradeType
                    ),
                },
            })
        }

        this.states.setState({ fontMissingDialogState: 'LOADED' })
    }

    private tryUploadUserLocalFontIfNeed = () => {
        // cypress测试不上传本地字体列表
        if (window.navigator.userAgent.includes('Cypress')) {
            return
        }

        const lastUploadTime = enhancedLocalStorage.getSerializedItem(LocalStorageKey.PostscriptName, 0)

        if (Date.now() - lastUploadTime < 30 * ONE_DAY) {
            return
        }

        fetchLocalFonts()
            .then((localFonts) => {
                if (!localFonts || localFonts.fontInfos.length <= 0) {
                    return
                }

                const vos: PostscriptFamilyStyleVO[] = localFonts.fontInfos.map((v) => {
                    return {
                        postScriptName: v.postScriptName,
                        family: v.family,
                        style: v.style,
                        status: 0,
                    }
                })
                new UploadPostscriptFamilyStyle(vos)
                    .start()
                    .catch(() => {})
                    .finally(() => {
                        enhancedLocalStorage.setSerializedItem(LocalStorageKey.PostscriptName, Date.now())
                    })
            })
            .catch(() => {})
    }

    /**
     * 下载顺序: 企业 > 团队 > 本地 > 云端
     * @param family
     * @param fontName
     */

    public getFontFileUrl = async (family: string, fontName: FontName): Promise<string> => {
        let targetFontName: FontNameExt | null = null

        await this.awaitFontInfosLoaded()

        targetFontName = this.getFontName(this.states.getState().orgFontInfosState, family, fontName)
        if (targetFontName) {
            return targetFontName.resourceUrl ?? ''
        }

        targetFontName = this.getFontName(this.states.getState().teamFontInfosState, family, fontName)
        if (targetFontName) {
            return targetFontName.resourceUrl ?? ''
        }

        targetFontName = this.getFontName(this.states.getState().localFontInfosState, family, fontName)
        if (targetFontName) {
            return getLocalFontFileUrl(targetFontName.path)
        }

        targetFontName = this.getFontName(this.states.getState().cloudFontInfosState, family, fontName)
        if (targetFontName) {
            return targetFontName.resourceUrl ?? ''
        }

        return ''
    }

    /**
     * 等待字体列表获取完成
     */
    private awaitFontInfosLoaded = async () => {
        return await this.allFontInfosLoadedPromise.promise
    }

    public getAllFonts = async (): Promise<FontInfoExt[]> => {
        await this.awaitFontInfosLoaded()
        return this.states.getState().allFontInfosState
    }

    public getFontInfo = (family: string) => {
        return this.states.getState().allFontInfosState.find((fontInfo) => fontInfo.family === family)
    }

    private getFontName = (fontInfos: FontInfoExt[], family: string, fontName: FontName) => {
        const targetFontInfo = fontInfos.find((fontInfo) => fontInfo.family === family)

        if (!targetFontInfo) {
            return null
        }

        const targetFontName = targetFontInfo.styles.find((v) => isEqualFontName(v, fontName))
        if (!targetFontName) {
            return null
        }
        return targetFontName
    }

    public destroy() {
        this.openFontMissingDialogCallback = null
        this.downloadTaskCache.clear()
        this.fontMap.clear()

        if (featureSwitchManager.isEnabled('font-select')) {
            this.downloadScheduler.reset()
        }
    }

    public setHandleTextMissingFontCallback = (cb: () => void) => {
        this.bridge.bind(HandleTextMissingFontCommand, cb, { signal: this.signal })
    }

    public setCheckFontMissingCallback = (
        cb: (arg: Wukong.DocumentProto.IFontName) => Wukong.DocumentProto.IBridgeProtoBoolean
    ) => {
        this.bridge.bind(CmdCheckFontMissing, cb, { signal: this.signal })
    }

    public triggerMissingFontDialogSubject = () => {
        this.handleReFetchLocalFontInfos()
    }

    public openFontMissingDialog = () => {
        this.openFontMissingDialogCallback?.()
    }

    public setOpenFontMissingDialogCallback = (cb: () => void) => {
        this.openFontMissingDialogCallback = cb
    }

    public insertFont = (font: { data: Uint8Array; url: string }) => {
        if (featureSwitchManager.isEnabled('font-select')) {
            this.downloadScheduler.insertFontData(font.url, font.data)
        } else {
            this.fontMap.set(font.url, font.data)
        }
    }

    public startSyncUsableFontInfos = async () => {
        this.downloadTaskCache.clear()

        if (this.isInited) {
            // NOTE: 编辑 & 历史模式之间切换, 需要重新同步字体列表到新的 wasm, 如果已经加载完成, 则直接同步
            if (this.states.getState().allFontInfosLoaded) {
                this.updateUsableFontInfos(this.states.getState().allFontInfosState)
            }
        } else {
            this.isInited = true

            // NOTE: 延迟30ms 避免字体列表处理阻塞文档加载
            signalTimeout(
                () => {
                    this.initFetchFontInfos(this.signal)
                },
                30,
                { signal: this.signal }
            )
        }
    }

    private async initFetchFontInfos(signal: TraceableAbortSignal) {
        // TODO(jiangzg): abort fetch
        const [cloud, shared, local] = await Promise.all([
            this.preLoadedCloudFontPromise,
            this.preLoadedSharedFontPromise,
            this.preLoadedLocalFontPromise,
        ])

        signal.throwIfAborted()

        const { teamFont, orgFont } = shared

        const sortedCloud = sortFontInfos(cloud)
        const sortedTeam = sortFontInfos(teamFont)
        const sortedLocal = sortFontInfos(local.fonts)
        const sortedOrg = sortFontInfos(orgFont)

        const fonts = mergeFontInfos({
            orgFont: sortedOrg,
            teamFont: sortedTeam,
            localFont: sortedLocal,
            cloudFont: sortedCloud,
        })

        const sortedFonts = sortFontInfos(fonts.allFont)
        const sortedFilteredSharedFonts = sortFontInfos(fonts.sharedFont)

        this.states.setState({
            cloudFontInfosState: sortedCloud,
            teamFontInfosState: sortedTeam,
            localFontInfosState: sortedLocal,
            orgFontInfosState: sortedOrg,
            allFontInfosState: sortedFonts,
            filteredSharedFontsState: sortedFilteredSharedFonts,
            family2FontInfoMapState: keyBy(fonts.allFont, (v) => v.family),
            allFontInfosLoaded: true,
            pluginDetails: {
                ...local.pluginVersionInfo,
                pluginStatus: this.getFontPluginState(local.pluginStatus, local.pluginVersionInfo.upgradeType),
            },
        })

        this.allFontInfosLoadedPromise.resolve()

        this.updateUsableFontInfos(sortedFonts)

        WkCLog.log('[FontManagerService] init fetch font infos success')
    }

    private updateUsableFontInfos = (fontInfos: FontInfoExt[]) => {
        if (fontInfos.length) {
            WkCLog.log('[FontManagerService] updateUsableFontInfos', { count: fontInfos.length })
            this.bridge.call(UpdateUsableFontInfoCommandForJs, { fontInfos })
        }
    }

    private checkNeedUpdatePluginDetails = (pluginStatus: LocalFontPluginStatus) => {
        const hasLocalFont = this.states.getState().localFontInfosState.length > 0
        const ignoreStatus = [
            LocalFontPluginStatus.BROKEN,
            LocalFontPluginStatus.NOT_FOUND,
            LocalFontPluginStatus.SILENT_IGNORE,
        ]
        return !(hasLocalFont && ignoreStatus.includes(pluginStatus))
    }

    private getFontPluginState = (pluginStatus: LocalFontPluginStatus, upgradeType: 'force' | 'option' | 'none') => {
        switch (pluginStatus) {
            case LocalFontPluginStatus.INSTALLED:
                if (upgradeType === 'force') {
                    return 'forceUpdated'
                } else if (upgradeType === 'option') {
                    return 'optionUpdated'
                } else {
                    return 'installed'
                }
            case LocalFontPluginStatus.NOT_FOUND:
            case LocalFontPluginStatus.BROKEN:
                return 'uninstalled'
            case LocalFontPluginStatus.SILENT_IGNORE:
            case LocalFontPluginStatus.DETECTING:
                return 'silentIgnore'
        }
    }
    public closePluginReminder = () => {
        const oldValue = this.states.getState().pluginDetails
        this.states.setState({ pluginDetails: { ...oldValue, showReminder: false } })
    }

    public setPluginInstallPointUnShown = () => {
        this.states.setState({ pluginInstallPointShowIfPossible: false })
    }
}
