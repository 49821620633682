import { translation } from './editor-shell.translation'
/* eslint-disable no-restricted-imports */
import { Scrollbar, Tooltip, WKButton } from '../../../../../../ui-lib/src'
import classNames from 'classnames'
import React, { useCallback, useMemo, useRef } from 'react'
import { ReactComponent as SvgAirplane } from '../../../assets/comment/airplane.svg'
import { ReactComponent as SvgEmoji } from '../../../assets/comment/emoji.svg'
import classes from './editor-shell.module.less'

export type GetSplitLineRect = () => DOMRect | undefined
export interface EditorShellProps {
    submitType?: 'icon' | 'button'
    disabled?: boolean
    isFoldTool?: boolean
    className?: string
    activeEmoji?: boolean
    maxHeight?: number // 用于内部的滚动通过
    children?: JSX.Element
    textOverMessage?: string
    onCancel?: () => void
    onSubmit?: () => void
    onClickEmoji?: (rect: DOMRect) => void
    stealSplitLineRect?: (getSplitLineRect: GetSplitLineRect) => void
    focusDraftEditor?: () => void
}
export function EditorShell(props: EditorShellProps) {
    const editorShellRef = useRef<HTMLDivElement>(null)
    const draftAreaRef = useRef<HTMLDivElement>(null)
    const toolsRef = useRef<HTMLDivElement>(null)

    const disabledState = useMemo(() => {
        return props.disabled || props.isFoldTool
    }, [props.disabled, props.isFoldTool])

    const isButtonSubmit = useMemo(() => {
        return props.submitType === 'button'
    }, [props.submitType])

    const maxHeightWithContentBox = useMemo(() => {
        if (props.maxHeight === undefined) {
            return
        }
        const buttonTypePadding = 11 + 44 + 1
        const iconTypePadding = 9 + 40 + 1
        return isButtonSubmit ? props.maxHeight - buttonTypePadding : props.maxHeight - iconTypePadding
    }, [isButtonSubmit, props.maxHeight])

    const onSubmit = useCallback(() => {
        if (props.disabled) {
            return
        }
        props.onSubmit?.()
    }, [props])

    const onClickEmoji = useCallback(() => {
        const rect = editorShellRef.current?.getBoundingClientRect()
        if (rect) {
            props.onClickEmoji?.(rect)
        }
    }, [props])

    const getSplitLineRect: GetSplitLineRect = useCallback(() => {
        if (props.isFoldTool) {
            const draftArea = draftAreaRef.current
            if (!draftArea) {
                return
            }
            const { left, bottom, width } = draftArea.getBoundingClientRect()
            const height = 0
            const top = bottom
            return {
                left,
                top,
                width,
                height,
                right: left + width,
                bottom: top + height,
                x: left,
                y: top,
                toJSON: () => {},
            }
        } else {
            const tools = toolsRef.current
            if (!tools) {
                return
            }
            const toolsRect = tools.getBoundingClientRect()
            const top = toolsRect.top - 1
            return {
                left: toolsRect.left,
                top: top,
                width: toolsRect.width,
                height: 0,
                right: toolsRect.right,
                bottom: top,
                x: toolsRect.left,
                y: top,
                toJSON: () => {},
            }
        }
    }, [props.isFoldTool])

    props.stealSplitLineRect?.(getSplitLineRect)

    const onPointerDownAirplane = useCallback(
        (e: React.PointerEvent) => {
            if (!props.disabled) {
                e.stopPropagation()
                e.preventDefault()
            }
        },
        [props.disabled]
    )

    const onPointerDownDraftArea = useCallback(
        (e: React.PointerEvent) => {
            if (props.isFoldTool) {
                return
            }
            e.stopPropagation()
        },
        [props.isFoldTool]
    )

    const onClickDraftArea = useCallback(() => {
        if (props.isFoldTool) {
            return
        }
        props.focusDraftEditor?.()
    }, [props])

    return (
        <div
            ref={editorShellRef}
            className={classNames(
                classes.commentContainer,
                { [classes.foldState]: props.isFoldTool },
                { [classes.disabledState]: disabledState },
                { [classes.editorScroll]: maxHeightWithContentBox },
                { [classes.buttonSubmitState]: isButtonSubmit },
                { [classes.iconSubmitState]: !isButtonSubmit },
                { [props.className ?? '']: props.className }
            )}
            style={{ maxHeight: maxHeightWithContentBox }}
            data-testid="editorShell"
        >
            <div
                ref={draftAreaRef}
                onClick={onClickDraftArea}
                onPointerDown={onPointerDownDraftArea}
                style={{ minHeight: 'inherit' }}
            >
                <Scrollbar
                    autoHeight
                    autoHeightMin={0}
                    autoHeightMax={maxHeightWithContentBox ? maxHeightWithContentBox : 10000000}
                    onClick={onClickDraftArea}
                    renderViewClassName={classes.draftArea}
                >
                    {props.children}
                    {props.textOverMessage ? (
                        <div className={classes.textOverMessage}>{props.textOverMessage}</div>
                    ) : null}
                </Scrollbar>
            </div>
            <div className={classes.tools} ref={toolsRef}>
                <Tooltip title={translation('AddEmoji')}>
                    <span
                        className={classNames(classes.toolItem, classes.toolEmoji, {
                            [classes.activeEmoji]: props.activeEmoji,
                        })}
                        onClick={onClickEmoji}
                        onPointerDown={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                        }}
                        data-testid="emoji"
                    >
                        <SvgEmoji />
                    </span>
                </Tooltip>
            </div>
            {isButtonSubmit ? (
                <div className={classes.buttonSubmit}>
                    <WKButton
                        type="secondary"
                        className={classes.button}
                        onClick={props.onCancel}
                        onPointerDown={(e) => e.stopPropagation()}
                        data-testid="buttonCancel"
                    >
                        {translation('Cancel')}
                    </WKButton>
                    <WKButton
                        className={classes.button}
                        type="primary"
                        onClick={onSubmit}
                        disabled={props.disabled}
                        onPointerDown={(e) => e.stopPropagation()}
                        data-testid="buttonSubmit"
                    >
                        {translation('Save')}
                    </WKButton>
                </div>
            ) : (
                <Tooltip title={translation('Submit')}>
                    <span
                        className={classes.iconSummit}
                        onClick={onSubmit}
                        onPointerDown={onPointerDownAirplane}
                        data-testid="airplaneSubmit"
                    >
                        <SvgAirplane />
                    </span>
                </Tooltip>
            )}
        </div>
    )
}
