import { Wukong } from '@wukong/bridge-proto'
import { IconStrokePointChnage, Tooltip } from '../../../../../../../ui-lib/src'
import { PropsWithChildren, RefObject } from 'react'
import { EditorDataTestId } from '../../../../../window'
import { IconButton } from '../../../atom/button/icon-button'
import { SingleGrid } from '../../../atom/grid/single-grid'
import style from '../advance-stroke/advance-stroke.module.less'
import type { StrokeMoreSettingRef } from '../stroke-more-setting/stroke-more-setting'
import { StrokeCapSelect } from './stroke-cap-select'
import { translation } from './stroke-end-point.translation'
import { useStrokeEndPoint } from './use-stroke-end-point'

// 端点设置-左右端点
export function StrokePairEndPoint(
    props: PropsWithChildren<{
        moreSettingRef: RefObject<StrokeMoreSettingRef>
    }>
) {
    const { endPointStyleState, onChangeStartCap, onChangeEndCap, onClickSwitch, showAsPair, isShowSwitchIcon } =
        useStrokeEndPoint()
    return showAsPair ? (
        <SingleGrid ref={props.moreSettingRef.current?.initTriggerRef}>
            <Tooltip title={translation('StartPoint')}>
                <SingleGrid.Item start={5} span={19}>
                    <StrokeCapSelect
                        iconOptionDirection="start"
                        iconLabel="short"
                        strokeCap={endPointStyleState?.pairState.startState.capValue}
                        mixed={
                            endPointStyleState?.pairState.startState.type ===
                            Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                        }
                        onChange={onChangeStartCap}
                    />
                </SingleGrid.Item>
            </Tooltip>
            <SingleGrid.Item start={25} span={4}>
                <Tooltip title={translation('Switch')}>
                    <IconButton
                        disabled={!isShowSwitchIcon}
                        icon={<IconStrokePointChnage />}
                        selected={false}
                        onClick={onClickSwitch}
                        dataTestId={EditorDataTestId.StrokeAttr.CapSwitchBtn}
                    />
                </Tooltip>
            </SingleGrid.Item>
            <Tooltip title={translation('EndPoint')}>
                <SingleGrid.Item start={32} span={19}>
                    <StrokeCapSelect
                        iconOptionDirection="end"
                        iconLabel="short"
                        strokeCap={endPointStyleState?.pairState.endState.capValue}
                        mixed={
                            endPointStyleState?.pairState.endState.type ===
                            Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                        }
                        onChange={onChangeEndCap}
                    />
                </SingleGrid.Item>
            </Tooltip>
            <SingleGrid.Item start={51} span={12} horizontalCenter>
                {props.children}
            </SingleGrid.Item>
        </SingleGrid>
    ) : (
        <></>
    )
}

// 端点设置- 0 个或 1 个或 3 个以上端点
export function StrokeSingleEndPoint() {
    const { showAsSingle, endPointStyleState, onChangeSingleCap } = useStrokeEndPoint()

    return showAsSingle ? (
        <div className={style.area}>
            <SingleGrid className={style.grid}>
                <SingleGrid.Item
                    start={5}
                    span={28}
                    className={
                        endPointStyleState?.showType ===
                        Wukong.DocumentProto.SelectionStrokeEndPointShowType
                            .SELECTION_STROKE_END_POINT_SHOW_TYPE_DISABLED
                            ? style.disabled
                            : undefined
                    }
                >
                    {translation('EndPoint_synonyms1', {
                        pluralSuffix: endPointStyleState?.endPointCount === 1 ? '' : 's',
                    })}
                </SingleGrid.Item>
                <SingleGrid.Item start={35} span={22}>
                    <StrokeCapSelect
                        iconOptionDirection="end"
                        iconLabel={endPointStyleState?.useAllIcon ? 'long-all' : 'long'}
                        strokeCap={endPointStyleState?.singleState.capValue}
                        mixed={
                            endPointStyleState?.singleState.type ===
                            Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                        }
                        disabled={
                            endPointStyleState?.showType ===
                            Wukong.DocumentProto.SelectionStrokeEndPointShowType
                                .SELECTION_STROKE_END_POINT_SHOW_TYPE_DISABLED
                        }
                        onChange={onChangeSingleCap}
                    />
                </SingleGrid.Item>
            </SingleGrid>
        </div>
    ) : (
        <></>
    )
}
