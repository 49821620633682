import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    CreatedPageLimit: '已添加页面数量 ({{pageNum}}/{{limitNum}})',
    ReachedPageLimit: '已达到页面数量上限 ({{pageNum}}/{{limitNum}})',
    ReachedPageLimitNotNumTip: '已达到页面数量上限',
    UpgradeForUnlimitedPages: '获取无限页面',
} as const

export const enTranslation = {
    CreatedPageLimit: '{{leftNum}} free page left.',
    ReachedPageLimit: 'All {{limitNum}} free pages used.',
    ReachedPageLimitNotNumTip: 'All {{limitNum}} free pages used.',
    UpgradeForUnlimitedPages: 'Get unlimited pages & more',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
