import { translation } from './comment-editor.translation'
/* eslint-disable no-restricted-imports */
import { WKToast } from '../../../../../../ui-lib/src'
import { Editor, EditorState } from 'draft-js'
import { useCallback, useRef, useState } from 'react'
import { isEmptyMessages } from '../utils'
import { DraftEditor, DraftEditorProps } from './draft-editor'
import { EditorShell, EditorShellProps, GetSplitLineRect } from './editor-shell'

export interface CommentEditorProps extends EditorShellProps, DraftEditorProps {
    onMentionStyle?: (splitLineRect: DOMRect) => void
}
export function CommentEditor(props: CommentEditorProps) {
    const [isEmptyContent, setIsEmptyContent] = useState(() => isEmptyMessages(props.initialMessages))
    const [isTextOver, setIsTextOver] = useState(false)
    const [textOverMessage, setTextOverMessage] = useState('')
    const editorRef = useRef<Editor>(null)
    const getSplitLineRectRef = useRef<GetSplitLineRect>()

    const onSendEditorState = useCallback(
        (editorState: EditorState) => {
            const textLength = editorState.getCurrentContent().getPlainText().length
            const _isTextOver = textLength > (props.maxCharSize ?? Number.MAX_SAFE_INTEGER)
            if (!isTextOver && _isTextOver) {
                WKToast.show(translation('iLWYnK', { length: props.maxCharSize ?? 0 }))
            }
            setIsEmptyContent(!textLength)
            setIsTextOver(_isTextOver)
            setTextOverMessage(`${textLength}/${props.maxCharSize}`)
            props.onSendEditorState(editorState)
        },
        [props, isTextOver]
    )

    const stealSplitLineRect = useCallback((getSplitLineRect: GetSplitLineRect) => {
        getSplitLineRectRef.current = getSplitLineRect
    }, [])

    const onSearchMentionUsers = useCallback(
        (findStr: string) => {
            const splitLineRect = getSplitLineRectRef.current?.()
            if (!splitLineRect) {
                return
            }
            props.onMentionStyle?.(splitLineRect)
            props.onSearchMentionUsers?.(findStr)
        },
        [props]
    )

    const focusDraftEditor = useCallback(() => {
        editorRef.current?.focus()
    }, [])

    return (
        <EditorShell
            submitType={props.submitType}
            disabled={isEmptyContent || props.disabled || isTextOver}
            textOverMessage={isTextOver ? textOverMessage : undefined}
            isFoldTool={isEmptyContent}
            className={props.className}
            activeEmoji={props.activeEmoji}
            onClickEmoji={props.onClickEmoji}
            maxHeight={props.maxHeight}
            onCancel={props.onCancel}
            onSubmit={props.onSubmit}
            stealSplitLineRect={stealSplitLineRect}
            focusDraftEditor={focusDraftEditor}
        >
            <DraftEditor
                editorRef={editorRef}
                disabled={props.disabled || isTextOver}
                placeholder={props.placeholder}
                usersMap={props.usersMap}
                initialMessages={props.initialMessages}
                onSendEditorState={onSendEditorState}
                replaceMessages={props.replaceMessages}
                replaceMessagesDone={props.replaceMessagesDone}
                selectAll={props.selectAll}
                selectAllDone={props.selectAllDone}
                isMentionHandling={props.isMentionHandling}
                mentionKeyEvent={props.mentionKeyEvent}
                isFastInsertMentionUser={props.isFastInsertMentionUser}
                insertMentionUser={props.insertMentionUser}
                insertMentionUserDone={props.insertMentionUserDone}
                onSearchMentionUsers={onSearchMentionUsers}
                onSearchMentionUsersDone={props.onSearchMentionUsersDone}
                insertEmoji={props.insertEmoji}
                insertEmojiDone={props.insertEmojiDone}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                onSubmit={props.onSubmit}
                maxCharSize={props.maxCharSize}
                dataTestIds={props.dataTestIds}
            />
        </EditorShell>
    )
}
