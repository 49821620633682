import { DropImageCommand, PlaceImageCommand, Wukong } from '@wukong/bridge-proto'
import { showImageResizeTip, wkDialogConfirm, WKToast } from '../../../../ui-lib/src'
import { BridgeCommand, CommandContext } from './command-invoker'
import { translation } from './image-command.translation'
import { ImageDownloadContext } from './image-download-context'
import { cmdCommitUndo } from './undo-redo-command'

import ImageInfo = Wukong.DocumentProto.ImageInfo

function generateImageInfoFromFileList(
    context: CommandContext,
    imageDownloadContext: ImageDownloadContext,
    fileList: FileList
): Promise<{ imageInfo: ImageInfo; hasCompressed: boolean }>[] {
    if (!fileList.length) {
        return []
    }

    const images = Array.from(fileList).filter((file) => file.type.match('^image/'))
    if (!images.length) {
        return []
    }

    return images.map(async (file) => {
        if (!context.commandInvoker) {
            return {
                imageInfo: await Promise.resolve(ImageInfo.create({ imageName: file.name })),
                hasCompressed: false,
            }
        }
        if (file.type === 'image/svg+xml') {
            const ab = await file.arrayBuffer()

            return {
                imageInfo: Wukong.DocumentProto.ImageInfo.create({
                    imageName: file.name,
                    svgBlob: new Uint8Array(ab),
                }),
                hasCompressed: false,
            }
        }

        return imageDownloadContext.addImageSource(file, file.name).then((result) => {
            if (!result.sucess) {
                return {
                    imageInfo: ImageInfo.create({ imageName: file.name }),
                    hasCompressed: false,
                }
            }

            return {
                imageInfo: result.imageInfo,
                hasCompressed: result.hasCompressed,
            }
        })
    })
}

export const cmdCreateImages: BridgeCommand<
    [
        imageDownloadContext: ImageDownloadContext,
        blobInfos: { blob: Blob; id: string }[],
        progressHandler?: (num: number) => void
    ],
    Promise<{
        imageInfo: ImageInfo
        hasCompressed: boolean
    }>[]
> = (ctx, imageDownloadContext, blobInfos) => {
    return generateImageInfoFromBlobs(ctx, imageDownloadContext, blobInfos)
}

function generateImageInfoFromBlobs(
    context: CommandContext,
    imageDownloadContext: ImageDownloadContext,
    blobInfos: { blob: Blob; id: string }[]
): Promise<{ imageInfo: ImageInfo; hasCompressed: boolean }>[] {
    if (!blobInfos.length) {
        return []
    }

    return blobInfos.map(async (infoPair: { blob: Blob; id: string }) => {
        return imageDownloadContext.addImageSource(infoPair.blob, 'Unsplash: ' + infoPair.id).then((result) => {
            if (!result.sucess) {
                return {
                    imageInfo: ImageInfo.create({ imageName: 'Unsplash: ' + infoPair.id }),
                    hasCompressed: false,
                }
            } else {
                return {
                    imageInfo: result.imageInfo,
                    hasCompressed: result.hasCompressed,
                }
            }
        })
    })
}

function isSuccessful<T>(result: PromiseSettledResult<T>): result is PromiseFulfilledResult<T> {
    return 'value' in result
}

export const cmdImagesSelected: BridgeCommand<[ImageDownloadContext, FileList]> = (
    context: CommandContext,
    imageDownloadContext: ImageDownloadContext,
    files: FileList
) => {
    const tId = WKToast.show(`${translation('AddingImage')}...`, {
        duration: -1,
        delay: 2000,
    })

    const filesPromises = generateImageInfoFromFileList(context, imageDownloadContext, files)
    // WK-14172: 部分图片文件失败时，粘贴操作需要继续
    Promise.allSettled(filesPromises).then((imageInfosWrap) => {
        let hasCompressed = false
        const succeedItems = imageInfosWrap.filter(isSuccessful)
        const hasFailure = imageInfosWrap.length > succeedItems.length
        if (succeedItems.length) {
            const imageInfos = succeedItems.map(({ value }) => {
                if (value.hasCompressed) {
                    hasCompressed = true
                }
                return value.imageInfo
            })
            context.bridge.call(PlaceImageCommand, Wukong.DocumentProto.PlaceImageParam.create({ imageInfos }))
            if (hasCompressed) {
                showImageResizeTip()
            }
        }

        WKToast.close(tId)

        if (hasFailure) {
            showPasteImageFailureModal()
        }
    })
}

export const cmdDropImages: BridgeCommand<[ImageDownloadContext, FileList, number, number]> = (
    ctx,
    imageDownloadContext,
    files,
    canvasX,
    canvasY
) => {
    const tId = WKToast.loading(`${translation('AddingImage')}...`, {
        duration: -1,
        delay: 2000,
    })

    const filesPromises = generateImageInfoFromFileList(ctx, imageDownloadContext, files)
    // WK-14172: 部分图片文件失败时，粘贴操作需要继续
    Promise.allSettled(filesPromises).then((imageInfosWrap) => {
        let hasCompressed = false
        const succeedItems = imageInfosWrap.filter(isSuccessful)
        const hasFailure = imageInfosWrap.length > succeedItems.length
        const imageInfos = succeedItems.map(({ value }) => {
            if (value.hasCompressed) {
                hasCompressed = true
            }
            return value.imageInfo
        })
        ctx.bridge.call(
            DropImageCommand,
            Wukong.DocumentProto.DropImageParam.create({
                imageInfos,
                canvasX,
                canvasY,
            })
        )
        cmdCommitUndo(ctx)

        if (hasCompressed) {
            showImageResizeTip()
        }
        WKToast.close(tId)

        if (hasFailure) {
            showPasteImageFailureModal()
        }
    })
}

export function showPasteImageFailureModal() {
    wkDialogConfirm.error({
        title: translation('FilesFailedTo'),
        closable: false,
        okText: translation('OK'),
        cancelButtonProps: { style: { display: 'none' } },
        content: translation('FileFormatNot'),
    })
}
