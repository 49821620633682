/* eslint-disable no-restricted-imports */
import {
    DetachSelectionPaintStyleIdCommandForEnterCrop,
    ResetSelectionNodeInInstanceCommand,
    ToggleEditingVectorStateCommandForJs,
    Wukong,
} from '@wukong/bridge-proto'
import { MouseEventHandler, useCallback } from 'react'
import { CommitType } from '../../../../document/command/commit-type'
import { cmdChangePopupState } from '../../../../document/command/document-command'
import type { Paint } from '../../../../document/node/node'
import { PopupStateType } from '../../../../document/node/node'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { useImageEdit } from '../../../utils/use-image-edit'
import { useBlendImage } from '../../design/blend/blend-image/use-blend-image'

export function useToolExtend() {
    const command = useCommand()
    const { onChangeScaleMode } = useBlendImage()
    const { startEditingImage } = useImageEdit()
    const documentTopArea = useViewState('topArea')
    const isEditingSinglePaint = documentTopArea?.isEditingSinglePaint
    const state = useViewState('pageTopArea')
    const singleSelection = useViewState('singleSelection')
    const cropImagePaintInfo = singleSelection?.cropImagePaintInfo
    const showCropTool = state?.showCropTool
    const showResetComponent = state?.showResetComponent
    const showSingleComponentTool = state?.showSingleComponentTool
    const showMultipleComponentTool = state?.showMultipleComponentTool
    const singleSelectionId = state?.singleSelectionId
    const showEditTool = state?.showEditTool
    const onClickComponent = () => {
        command.invoke(cmdChangePopupState, {
            type: PopupStateType.POPUP_STATE_TYPE_NONE,
            reciprocalIndex: -1,
            multiPopup: [],
        })
    }

    const onClickCrop: MouseEventHandler<HTMLElement> = (event) => {
        if (!showCropTool) {
            return
        }
        if (!singleSelectionId) {
            return
        }
        if (!cropImagePaintInfo) {
            return
        }

        // 阻止冒泡是为了避免useDetail中再次触发SelectedReverseIndex的清理导致选中状态不正确
        event.stopPropagation()
        command.invokeBridge(
            CommitType.Noop,
            DetachSelectionPaintStyleIdCommandForEnterCrop,
            Wukong.DocumentProto.DetachSelectionPaintStyleIdForEnterCropParam.create({
                paintStyleType: cropImagePaintInfo.isFromFills
                    ? Wukong.DocumentProto.PaintStyleType.PAINT_STYLE_TYPE_FILL
                    : Wukong.DocumentProto.PaintStyleType.PAINT_STYLE_TYPE_STROKE,
            })
        )
        if (cropImagePaintInfo.isFromFills) {
            startEditingImage(
                [singleSelectionId],
                Wukong.DocumentProto.EditingPaintType.EDITING_PAINT_TYPE_FILL,
                cropImagePaintInfo.index
            )
            onChangeScaleMode(Wukong.DocumentProto.ScaleMode.SCALE_MODE_CROP)
        } else {
            startEditingImage(
                [singleSelectionId],
                Wukong.DocumentProto.EditingPaintType.EDITING_PAINT_TYPE_STROKE,
                cropImagePaintInfo.index
            )
            onChangeScaleMode(Wukong.DocumentProto.ScaleMode.SCALE_MODE_CROP)
        }
        command.commitUndo(CommitType.CommitUndo)
    }

    const onClickResetProperties = useCallback(() => {
        command.DEPRECATED_invokeBridge(ResetSelectionNodeInInstanceCommand)
    }, [command])

    const onClickEditVector = useCallback(() => {
        command.DEPRECATED_invokeBridge(ToggleEditingVectorStateCommandForJs)
        command.commitUndo()
    }, [command])

    return {
        isEditingSinglePaint,
        showCropTool,
        onClickComponent,
        onClickCrop,
        showComponentTool: [showSingleComponentTool, showMultipleComponentTool],
        showResetComponent,
        showEditTool,
        onClickResetProperties,
        onClickEditVector,
    }
}

export interface FindCropImagePaint {
    nodeId: string
    paints: Paint[]
    paintsLength: number
    paintIndex: number
    paintType: 'fills' | 'strokes'
}
