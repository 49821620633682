import classNames from 'classnames'
import React, { MouseEvent, useMemo, useRef, useState } from 'react'
import {
    DropdownV2,
    isRightClick,
    MonoIconCommonAdd16,
    MonoIconCommonQuestion16,
    MonoIconPanelLink16,
    MonoIconPanelTarget16,
    Rect,
    WKIconButton,
} from '../../../../../../../../../ui-lib/src'
import Tooltip from '../../../../../../../../../ui-lib/src/components/tooltip/tooltip'
import { ComponentPropType, VComponentPropDef, VComponentPropPill, VComponentPropType } from '../../../types'
import { ComponentPropDefsIconMap } from '../../component-prop-defs-item'
import styles from './index.module.less'
import { translation } from './index.translation'

enum DropdownType {
    LeftClick,
    RightClick,
    None,
}

type PillStatus = 'normal' | 'disabled' | 'zombie'

const PillBtnAliasMap: Record<VComponentPropType, { create: string; edit: string } | undefined> = {
    [VComponentPropType.V_COMPONENT_PROP_TYPE_BOOL]: {
        create: 'create-bool-prop-btn',
        edit: 'edit-bool-prop-btn',
    },
    [VComponentPropType.V_COMPONENT_PROP_TYPE_TEXT]: {
        create: 'create-bool-prop-btn',
        edit: 'edit-bool-prop-btn',
    },
    [VComponentPropType.V_COMPONENT_PROP_TYPE_INSTANCE_SWAP]: {
        create: 'create-bool-prop-btn',
        edit: 'edit-bool-prop-btn',
    },
    [VComponentPropType.V_COMPONENT_PROP_TYPE_VARIANT]: undefined,
}

export function convertVirtualComponentPropType2Real(type: VComponentPropType): ComponentPropType {
    switch (type) {
        case VComponentPropType.V_COMPONENT_PROP_TYPE_BOOL:
            return ComponentPropType.COMPONENT_PROP_TYPE_BOOL
        case VComponentPropType.V_COMPONENT_PROP_TYPE_TEXT:
            return ComponentPropType.COMPONENT_PROP_TYPE_TEXT
        case VComponentPropType.V_COMPONENT_PROP_TYPE_INSTANCE_SWAP:
            return ComponentPropType.COMPONENT_PROP_TYPE_INSTANCE_SWAP
        default:
            throw new Error('不支持的组件属性类型')
    }
}
export function convertRealComponentPropType2Virtual(type: ComponentPropType): VComponentPropType {
    switch (type) {
        case ComponentPropType.COMPONENT_PROP_TYPE_BOOL:
            return VComponentPropType.V_COMPONENT_PROP_TYPE_BOOL
        case ComponentPropType.COMPONENT_PROP_TYPE_TEXT:
            return VComponentPropType.V_COMPONENT_PROP_TYPE_TEXT
        case ComponentPropType.COMPONENT_PROP_TYPE_INSTANCE_SWAP:
            return VComponentPropType.V_COMPONENT_PROP_TYPE_INSTANCE_SWAP
        default:
            throw new Error('不支持的组件属性类型')
    }
}

export function BaseComponentPropPill(props: {
    dataTestId: string
    propDefs: VComponentPropDef[]
    displayProp: VComponentPropPill
    pillStatus: PillStatus
    zombiePillAddBtn: boolean
    className?: {
        container?: string
    }
    detachProp: () => void
    applyProp: (defId: string) => void
    createProp: () => void
    editProp: () => void
    zombiePillAddProp: () => void
    disabledPillLocateProp: () => void
}) {
    const {
        dataTestId,
        propDefs,
        displayProp,
        pillStatus,
        zombiePillAddBtn,
        className,
        detachProp,
        applyProp,
        zombiePillAddProp,
        createProp,
        editProp,
        disabledPillLocateProp,
    } = props
    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false)
    const [pillRect, setPillRect] = useState<Rect>({ top: 0, left: 0, right: 0, bottom: 0 })
    const [dropdownType, setDropdownType] = useState<DropdownType>(DropdownType.None)
    const pillRef = useRef<HTMLDivElement>(null)

    const propList = useMemo(() => {
        return (
            propDefs.filter((compProp) => convertVirtualComponentPropType2Real(compProp.type) === displayProp.type) ||
            []
        )
    }, [propDefs, displayProp])

    const onSelectChange = (value: string): void => {
        if (value == PillBtnAliasMap[displayProp.type]?.create) {
            createProp()
        } else if (value === PillBtnAliasMap[displayProp.type]?.edit) {
            editProp()
        } else {
            applyProp(value)
        }
    }

    const handlePillClick = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault()

        const { clientX, clientY, button } = e

        // 鼠标右键
        if (isRightClick(e)) {
            setPillRect({ top: clientY + 8, left: clientX, right: clientX, bottom: clientY + 8 })
            setDropdownType(DropdownType.RightClick)
            setDropdownVisible(true)
            return
        }

        // 鼠标左键
        if (button === 0 && pillRef.current) {
            const { left, bottom } = pillRef.current.getBoundingClientRect()
            setPillRect({
                top: bottom + 16,
                left: left,
                right: left,
                bottom: bottom + 16,
            })
            setDropdownType(DropdownType.LeftClick)
            setDropdownVisible(true)
            return
        }
    }

    const renderActionBtn = () => {
        const ActionConfigs: Record<
            PillStatus,
            { tooltipTitle: string; handle: () => void; icon: React.ReactNode; hidden?: boolean; testId?: string }[]
        > = {
            normal: [
                {
                    tooltipTitle: translation('detachProp'),
                    handle: detachProp,
                    icon: <MonoIconPanelLink16 />,
                },
            ],
            disabled: [
                {
                    tooltipTitle: translation('seeProp'),
                    handle: disabledPillLocateProp,
                    icon: <MonoIconPanelTarget16 />,
                    testId: 'locate-prop-btn',
                },
            ],
            zombie: [
                {
                    tooltipTitle: translation('detachProp'),
                    handle: detachProp,
                    icon: <MonoIconPanelLink16 />,
                },
                {
                    tooltipTitle: translation('addProp'),
                    handle: zombiePillAddProp,
                    icon: <MonoIconCommonAdd16 />,
                    hidden: !zombiePillAddBtn,
                },
            ],
        }

        const configs = ActionConfigs[pillStatus] || []

        return (
            configs.length && (
                <span className={styles.componentPropPillActionBtns}>
                    {configs.map((config) => {
                        if (config.hidden) {
                            return null
                        }

                        return (
                            <div key={config.tooltipTitle}>
                                <Tooltip title={config.tooltipTitle}>
                                    <WKIconButton
                                        icon={config.icon}
                                        onClick={config.handle}
                                        data-testid={config.testId}
                                    />
                                </Tooltip>
                            </div>
                        )
                    })}
                </span>
            )
        )
    }

    return (
        <div
            className={classNames(styles.componentPropPillContainer, className?.container ?? '')}
            data-testid={dataTestId}
        >
            {pillStatus === 'normal' && (
                <>
                    <div
                        data-testid="normal-component-prop-pill"
                        ref={pillRef}
                        onMouseDown={handlePillClick}
                        className={classNames(styles.componentPropPillBase, styles.componentPropPillEnabled)}
                    >
                        <div className={styles.componentPropPillIcon}>{ComponentPropDefsIconMap[displayProp.type]}</div>
                        <div className={styles.componentPropPillName}>{displayProp.name}</div>
                    </div>
                    <DropdownV2.NoTriggerSingleLevel
                        onChange={onSelectChange}
                        isOpenState={dropdownVisible}
                        triggerRect={pillRect}
                        openStateToBeFalse={() => {
                            setDropdownVisible(false)
                        }}
                        dataTestIds={{ container: 'component-prop-dropdown' }}
                    >
                        {dropdownType === DropdownType.LeftClick && (
                            <>
                                {propList.map((prop) => (
                                    <DropdownV2.NoTriggerSingleLevel.Option
                                        key={prop.id}
                                        value={prop.id}
                                        isSelect={displayProp.editedPropId === prop.id}
                                    >
                                        {prop.name}
                                    </DropdownV2.NoTriggerSingleLevel.Option>
                                ))}
                                <DropdownV2.NoTriggerSingleLevel.Option
                                    splitLineTop
                                    value={PillBtnAliasMap[displayProp.type]?.create}
                                    isSelect={false}
                                >
                                    {translation('createProp')}
                                </DropdownV2.NoTriggerSingleLevel.Option>
                            </>
                        )}
                        {dropdownType === DropdownType.RightClick && (
                            <DropdownV2.NoTriggerSingleLevel.Option value={PillBtnAliasMap[displayProp.type]?.edit}>
                                {translation('editProp')}
                            </DropdownV2.NoTriggerSingleLevel.Option>
                        )}
                    </DropdownV2.NoTriggerSingleLevel>
                </>
            )}
            {pillStatus === 'disabled' && (
                <div
                    data-testid="disabled-component-prop-pill"
                    className={classNames(styles.componentPropPillBase, styles.componentPropPillDisabled)}
                >
                    <div className={styles.componentPropPillIcon}>{ComponentPropDefsIconMap[displayProp.type]}</div>
                    <div className={styles.componentPropPillName}>{displayProp.name}</div>
                </div>
            )}
            {pillStatus === 'zombie' && (
                <Tooltip
                    title={
                        zombiePillAddBtn
                            ? translation('zombiePropOnComponent')
                            : translation('zombiePropOnNonComponent')
                    }
                >
                    <div
                        data-testid="zombie-component-prop-pill"
                        className={classNames(styles.componentPropPillBase, styles.componentPropPillEnabled)}
                    >
                        <div className={styles.componentPropPillIcon}>
                            <MonoIconCommonQuestion16 />
                        </div>
                        <div className={styles.componentPropPillName}>{displayProp.name}</div>
                    </div>
                </Tooltip>
            )}
            {renderActionBtn()}
        </div>
    )
}

export function BaseInstanceSwapPropPill(props: {
    dataTestId: string
    propDefs: VComponentPropDef[]
    displayProp: VComponentPropPill
    pillStatus: PillStatus
    zombiePillAddBtn: boolean
    className?: {
        container?: string
    }
    detachProp: () => void
    applyProp: (defId: string) => void
    createProp: () => void
    editProp: () => void
    zombiePillAddProp: () => void
    disabledPillLocateProp: () => void
}) {
    const {
        dataTestId,
        propDefs,
        displayProp,
        pillStatus,
        zombiePillAddBtn,
        className,
        detachProp,
        applyProp,
        zombiePillAddProp,
        createProp,
        editProp,
        disabledPillLocateProp,
    } = props
    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false)
    const [pillRect, setPillRect] = useState<Rect>({ top: 0, left: 0, right: 0, bottom: 0 })
    const [dropdownType, setDropdownType] = useState<DropdownType>(DropdownType.None)
    const pillRef = useRef<HTMLDivElement>(null)

    const propList = useMemo(() => {
        return (
            propDefs.filter((compProp) => convertVirtualComponentPropType2Real(compProp.type) === displayProp.type) ||
            []
        )
    }, [propDefs, displayProp])

    const onSelectChange = (value: string): void => {
        if (value == PillBtnAliasMap[displayProp.type]?.create) {
            createProp()
        } else if (value === PillBtnAliasMap[displayProp.type]?.edit) {
            editProp()
        } else {
            applyProp(value)
        }
    }

    const handlePillClick = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault()

        const { clientX, clientY, button } = e

        // 鼠标左键
        if (button === 0 && pillRef.current) {
            const { left, bottom } = pillRef.current.getBoundingClientRect()
            setPillRect({
                top: bottom + 16,
                left: left,
                right: left,
                bottom: bottom + 16,
            })
            setDropdownType(DropdownType.LeftClick)
            setDropdownVisible(true)

            return
        }
        // 鼠标右键
        if (isRightClick(e)) {
            setPillRect({ top: clientY + 8, left: clientX, right: clientX, bottom: clientY + 8 })
            setDropdownType(DropdownType.RightClick)
            setDropdownVisible(true)
            return
        }
    }

    const renderActionBtn = () => {
        const ActionConfigs: Record<
            PillStatus,
            { tooltipTitle: string; handle: () => void; icon: React.ReactNode; hidden?: boolean }[]
        > = {
            normal: [
                {
                    tooltipTitle: translation('detachProp'),
                    handle: detachProp,
                    icon: <MonoIconPanelLink16 />,
                },
            ],
            disabled: [
                {
                    tooltipTitle: translation('seeProp'),
                    handle: disabledPillLocateProp,
                    icon: <MonoIconPanelTarget16 />,
                },
            ],
            zombie: [
                {
                    tooltipTitle: translation('detachProp'),
                    handle: detachProp,
                    icon: <MonoIconPanelLink16 />,
                },
                {
                    tooltipTitle: translation('addProp'),
                    handle: zombiePillAddProp,
                    icon: <MonoIconCommonAdd16 />,
                    hidden: !zombiePillAddBtn,
                },
            ],
        }

        const configs = ActionConfigs[pillStatus] || []

        return (
            configs.length && (
                <span className={styles.instanceSwapPropPillActionBtns}>
                    {configs.map((config) => {
                        if (config.hidden) {
                            return null
                        }

                        return (
                            <div key={config.tooltipTitle}>
                                <Tooltip title={config.tooltipTitle}>
                                    <WKIconButton icon={config.icon} onClick={config.handle} />
                                </Tooltip>
                            </div>
                        )
                    })}
                </span>
            )
        )
    }

    const [hovered, setHovered] = useState(false)
    return (
        <div
            className={classNames(styles.componentPropPillContainer, className?.container ?? '')}
            data-testid={dataTestId}
            onMouseEnter={() => {
                setHovered(true)
            }}
            onMouseLeave={() => {
                setHovered(false)
            }}
        >
            {pillStatus === 'normal' && (
                <>
                    <div
                        data-testid="normal-component-prop-pill"
                        ref={pillRef}
                        onMouseDown={handlePillClick}
                        className={classNames(
                            styles.componentPropPillEnabled,
                            hovered ? styles.instanceSwapPropPillHovered : styles.instanceSwapPropPillBase
                        )}
                    >
                        <div className={styles.componentPropPillIcon}>{ComponentPropDefsIconMap[displayProp.type]}</div>
                        <div className={styles.componentPropPillName}>{displayProp.name}</div>
                    </div>
                    <DropdownV2.NoTriggerSingleLevel
                        onChange={onSelectChange}
                        isOpenState={dropdownVisible}
                        triggerRect={pillRect}
                        openStateToBeFalse={() => {
                            setDropdownVisible(false)
                        }}
                        dataTestIds={{ container: 'component-prop-dropdown' }}
                    >
                        {dropdownType === DropdownType.LeftClick && (
                            <>
                                {propList.map((prop) => (
                                    <DropdownV2.NoTriggerSingleLevel.Option
                                        key={prop.id}
                                        value={prop.id}
                                        isSelect={displayProp.editedPropId === prop.id}
                                    >
                                        {prop.name}
                                    </DropdownV2.NoTriggerSingleLevel.Option>
                                ))}
                                <DropdownV2.NoTriggerSingleLevel.Option
                                    splitLineTop
                                    value={PillBtnAliasMap[displayProp.type]?.create}
                                    isSelect={false}
                                >
                                    {translation('createProp')}
                                </DropdownV2.NoTriggerSingleLevel.Option>
                            </>
                        )}
                        {dropdownType === DropdownType.RightClick && (
                            <DropdownV2.NoTriggerSingleLevel.Option value={PillBtnAliasMap[displayProp.type]?.edit}>
                                {translation('editProp')}
                            </DropdownV2.NoTriggerSingleLevel.Option>
                        )}
                    </DropdownV2.NoTriggerSingleLevel>
                </>
            )}
            {pillStatus === 'disabled' && (
                <div
                    data-testid="disabled-component-prop-pill"
                    className={classNames(styles.componentPropPillBase, styles.componentPropPillDisabled)}
                >
                    <div className={styles.componentPropPillIcon}>{ComponentPropDefsIconMap[displayProp.type]}</div>
                    <div className={styles.componentPropPillName}>{displayProp.name}</div>
                </div>
            )}
            {pillStatus === 'zombie' && (
                <Tooltip
                    title={
                        zombiePillAddBtn
                            ? translation('zombiePropOnComponent')
                            : translation('zombiePropOnNonComponent')
                    }
                >
                    <div
                        data-testid="zombie-component-prop-pill"
                        className={classNames(styles.componentPropPillBase, styles.componentPropPillEnabled)}
                    >
                        <div className={styles.componentPropPillIcon}>
                            <MonoIconCommonQuestion16 />
                        </div>
                        <div className={styles.componentPropPillName}>{displayProp.name}</div>
                    </div>
                </Tooltip>
            )}
            {renderActionBtn()}
        </div>
    )
}
