import { translation } from './candidate-component-publish-dialog.translation'
/* eslint-disable no-restricted-imports */
import {
    AICandidateComponentCancelReplaceCommand,
    AICandidateComponentPublishCommand,
    BuildAIComponentDataCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
    Badge,
    DraggablePopupV2,
    MonoIconCommonAIReplaceLibrary24,
    MonoIconPanelMiniScreen16,
    Scrollbar,
    ScrollbarRef,
    WKButton,
    WKToast,
} from '../../../../../../ui-lib/src'
import Tooltip from '../../../../../../ui-lib/src/components/tooltip/tooltip'
import { ToKeyCode } from '../../../../document/util/keycode'
import { WKFrog } from '../../../../kernel/frog'
import {
    CandidateComponentPublishRequestContent,
    CandidateComponentReplaceItem,
} from '../../../../kernel/interface/candidate-component'
import { CandidateComponentPublish } from '../../../../kernel/request/candidate-component'
import { useAppContext, useDocId, usePagePanelService } from '../../../../main/app-context'
import { KeyboardReceiver } from '../../../../main/keyboard-receiver/component'
import { LibraryThumbnailImage } from '../../../../share/component-style-library/library-thumbnail-image'
import { useViewState } from '../../../../view-state-bridge'
import { CandidateComponentPublishDialogTestIds } from '../../../../window'
import { ReactComponent as ArrowRightSvg } from '../../../assets/ai/arrow-right.svg'
import { ReactComponent as RemoveBtnSvg } from '../../../assets/ai/remove-btn.svg'
import { useCommand } from '../../../context/document-context'
import { IconButton } from '../../atom/button/icon-button'
import { RightClickMenuWrapper } from '../../right-click-menu-wrapper'
import { useMenuContextState } from '../../top-area/menu/context/menu-context'
import { CandidateComponentImg } from './candidate-component-img'
import style from './candidate-component-publish-dialog.module.less'
import { EllipsisTooltipWrapper } from './ellipsis-tool-tip-wrapper'

function styleTypeToString(type: Wukong.DocumentProto.AICandidateComponentType) {
    switch (type) {
        case Wukong.DocumentProto.AICandidateComponentType.A_I_CANDIDATE_COMPONENT_TYPE_COLOR:
            return 'color'
        case Wukong.DocumentProto.AICandidateComponentType.A_I_CANDIDATE_COMPONENT_TYPE_FONT:
            return 'font'
        case Wukong.DocumentProto.AICandidateComponentType.A_I_CANDIDATE_COMPONENT_TYPE_EFFECT:
            return 'effect'
        default:
            return ''
    }
}

export function CandidateComponentPublishDialog() {
    const docId = useDocId()
    const aiService = useAppContext().aiService
    const command = useCommand()
    const pagePanelService = usePagePanelService()
    const fullscreen = useViewState('fullscreen')
    const containerRef = useRef<ScrollbarRef>(null)
    const [oldJustConvertNodeId, setOldJustConvertNodeId] = useState<string | null>(null)
    const [oldSelectedComponentId, setOldSelectedComponentId] = useState<string | null>(null)

    const candidateComponentPublishState = useViewState('candidateComponentPublishState')
    const [isLoading, setIsLoading] = useState(false)

    const { isOpenCandidatePublishDialog, setIsOpenCandidatePublishDialog, setIsOpenFontMissDialog } =
        useMenuContextState()

    const handleDelete = useCallback(
        (item: Wukong.DocumentProto.ICandidateComponentReplaceItem) => {
            if (isLoading) {
                return
            }
            const id = item?.candidateComponentId
            if (!id) {
                return
            }
            command.DEPRECATED_invokeBridge(AICandidateComponentCancelReplaceCommand, {
                targetId: id,
            })
        },
        [command, isLoading]
    )

    const handleDialogStateChange = useCallback(
        (isOpen: boolean) => {
            if (isOpen) {
                setIsOpenFontMissDialog(false)
                setIsOpenCandidatePublishDialog(true)
            } else {
                setIsOpenCandidatePublishDialog(false)
            }
        },
        [setIsOpenCandidatePublishDialog, setIsOpenFontMissDialog]
    )

    const keydownHandler = useCallback(
        (event?: KeyboardEvent) => {
            if (event && event.key === 'Escape') {
                event?.preventDefault()
                handleDialogStateChange(false)
                return false
            }
            return true
        },
        [handleDialogStateChange]
    )

    useEffect(() => {
        if (!candidateComponentPublishState || !containerRef.current || !isOpenCandidatePublishDialog) {
            setOldJustConvertNodeId(null)
            setOldSelectedComponentId(null)
            return
        }

        let indexInStyleList = -1
        let indexInComponentList = -1

        if (!candidateComponentPublishState.selectedComponentId) {
            setOldSelectedComponentId(null)
        }

        if (candidateComponentPublishState.selectedComponentId) {
            if (oldSelectedComponentId === candidateComponentPublishState.selectedComponentId) {
                return
            } else {
                setOldSelectedComponentId(candidateComponentPublishState.selectedComponentId)
                candidateComponentPublishState.styleList.forEach((item, index) => {
                    if (item.substituteContentSnapshotNodeId === candidateComponentPublishState.selectedComponentId) {
                        indexInStyleList = index
                        return
                    }
                })

                if (indexInStyleList === -1) {
                    candidateComponentPublishState.componentList.forEach((item, index) => {
                        if (
                            item.substituteContentSnapshotNodeId === candidateComponentPublishState.selectedComponentId
                        ) {
                            indexInComponentList = index
                            return
                        }
                    })
                }
            }
        } else {
            if (oldJustConvertNodeId === candidateComponentPublishState.justConvertNodeId) {
                return
            } else {
                setOldJustConvertNodeId(candidateComponentPublishState.justConvertNodeId)
                candidateComponentPublishState.styleList.forEach((item, index) => {
                    if (item.candidateComponentId === candidateComponentPublishState.justConvertNodeId) {
                        indexInStyleList = index
                        return
                    }
                })

                if (indexInStyleList === -1) {
                    candidateComponentPublishState.componentList.forEach((item, index) => {
                        if (item.candidateComponentId === candidateComponentPublishState.justConvertNodeId) {
                            indexInComponentList = index
                            return
                        }
                    })
                }
            }
        }

        if (indexInStyleList !== -1 || indexInComponentList !== -1) {
            const scrollToTop =
                indexInStyleList !== -1
                    ? 36 * indexInStyleList + 48
                    : (candidateComponentPublishState.styleList.length > 0
                          ? candidateComponentPublishState.styleList.length * 36 + 48 + 16
                          : 0) +
                      96 * indexInComponentList +
                      48
            containerRef.current.scrollTop(scrollToTop)
        }
    }, [
        containerRef,
        candidateComponentPublishState,
        oldJustConvertNodeId,
        setOldJustConvertNodeId,
        isOpenCandidatePublishDialog,
        oldSelectedComponentId,
        setOldSelectedComponentId,
    ])

    useEffect(() => {
        if (
            !candidateComponentPublishState ||
            (candidateComponentPublishState.componentList.length === 0 &&
                candidateComponentPublishState.styleList.length === 0)
        ) {
            handleDialogStateChange(false)
        }
    }, [candidateComponentPublishState, handleDialogStateChange])

    const handleSubmit = useCallback(async () => {
        if (!candidateComponentPublishState) {
            return
        }
        WKFrog.addFrogRecord({
            url: '/click/AIReplacesComponent/ClickPublishReplace',
            eventId: 26845,
            eventAction: 'click',
            eventName: 'ClickPublishReplace',
        })
        setIsLoading(true)

        const componentList: CandidateComponentReplaceItem[] = []
        const styleList: CandidateComponentReplaceItem[] = []
        const allData: Wukong.DocumentProto.IBuildAIComponentDataCommandRet[] = []
        const allDataSet = new Set<string>()

        const promises = candidateComponentPublishState.componentList.map(async (item) => {
            const id = item.candidateContentSnapshot.id
            const data = await aiService.getAiComponentData(id)
            if (!data) {
                return
            }
            componentList.push({
                substituteContentSnapshotNodeId: item.substituteContentSnapshotNodeId,
                candidateContentSnapshots: data,
                thumbnailData: aiService.generateCandidateComponentThumbnail(item.candidateComponentId).buffer,
            })

            const componentId = item.substituteContentSnapshotNodeId

            // 组件数据需要去重, server接口会校验
            if (!allDataSet.has(componentId)) {
                const componentData = command.DEPRECATED_invokeBridge(BuildAIComponentDataCommand, {
                    nodeId: componentId,
                })
                allData.push(componentData)
                allDataSet.add(componentId)
            }

            return item
        })

        const promises2 = candidateComponentPublishState.styleList.map(async (item) => {
            const id = item.candidateContentSnapshot.id
            const data = await aiService.getAiComponentData(id)
            if (!data) {
                return
            }
            styleList.push({
                substituteContentSnapshotNodeId: item.substituteContentSnapshotNodeId,
                candidateContentSnapshots: data,
                thumbnailData: aiService.generateCandidateComponentThumbnail(item.candidateComponentId).buffer,
                candidateName: item.candidateName,
                type: styleTypeToString(item.type),
            })

            const componentId = item.substituteContentSnapshotNodeId

            // 组件数据需要去重, server接口会校验
            if (!allDataSet.has(componentId)) {
                const componentData = command.DEPRECATED_invokeBridge(BuildAIComponentDataCommand, {
                    nodeId: componentId,
                })
                allData.push(componentData)
                allDataSet.add(componentId)
            }

            return item
        })
        try {
            await Promise.all([...promises, ...promises2])
            const requestContent: CandidateComponentPublishRequestContent = {
                schemaVersion: candidateComponentPublishState.schemaVersion,
                allData,
                componentList,
                styleList,
            }
            await new CandidateComponentPublish(docId, { requestContent })
                .start()
                .then(() => {
                    command.DEPRECATED_invokeBridge(AICandidateComponentPublishCommand)
                    WKToast.show(translation('PublishedSuccessfully'))
                })
                .catch(() => {
                    WKToast.show(translation('PublishFailed'))
                })
            setIsLoading(false)
            handleDialogStateChange(false)
        } catch (e) {
            WKToast.show(translation('PublishFailed'))
            setIsLoading(false)
            handleDialogStateChange(false)
        }
    }, [candidateComponentPublishState, aiService, docId, command, setIsLoading, handleDialogStateChange])

    const handleRightClick = useCallback(
        (id: string) => {
            pagePanelService.setLastSelecteAIComponentId(id)
        },
        [pagePanelService]
    )

    if (
        fullscreen ||
        !candidateComponentPublishState ||
        (candidateComponentPublishState.componentList.length === 0 &&
            candidateComponentPublishState.styleList.length === 0)
    ) {
        return <></>
    }

    return (
        <>
            {!isOpenCandidatePublishDialog ? (
                <Tooltip title={translation('PublishReplace')}>
                    <div className={style.publishTip}>
                        <Badge.Number
                            colorRevert
                            stroke
                            number={
                                candidateComponentPublishState.componentList.length +
                                candidateComponentPublishState.styleList.length
                            }
                            maxNumber={99}
                            center={{ top: 1, left: 'calc(100% - 1px)' }}
                        />
                        <IconButton
                            selected={false}
                            icon={
                                <MonoIconCommonAIReplaceLibrary24
                                    className={style.forceSvgGray13}
                                ></MonoIconCommonAIReplaceLibrary24>
                            }
                            className={style.publishBtn}
                            onClick={() => handleDialogStateChange(true)}
                            dataTestId={CandidateComponentPublishDialogTestIds.publishIcon}
                        ></IconButton>
                    </div>
                </Tooltip>
            ) : (
                <KeyboardReceiver keyCode={ToKeyCode.All} onKeydown={keydownHandler}>
                    <DraggablePopupV2
                        visible={true}
                        onCancel={() => handleDialogStateChange(false)}
                        header={
                            <div className={style.titleWrapper}>
                                <div className={style.title}>{translation('PublishReplace')}</div>
                                <IconButton
                                    className={style.closeBtn}
                                    selected={false}
                                    icon={<MonoIconPanelMiniScreen16 />}
                                    onClick={() => handleDialogStateChange(false)}
                                ></IconButton>
                            </div>
                        }
                        closable={false}
                        styleType="editor"
                        footer={
                            <div className={style.buttonWrapper}>
                                <WKButton
                                    type="primary"
                                    className={style.button}
                                    onClick={handleSubmit}
                                    loading={isLoading}
                                >
                                    {isLoading ? translation('Publishing') : translation('PublishReplace')}
                                </WKButton>
                            </div>
                        }
                        bodyClassName="p-0"
                        positionRightBase
                        position={{
                            top: 8,
                            left: window.innerWidth - 263 + 8,
                        }}
                        width={464}
                        height={600}
                    >
                        <Scrollbar autoHeight autoHeightMin={482} ref={containerRef}>
                            <div className={style.contextWrapper}>
                                {candidateComponentPublishState.styleList.length > 0 ? (
                                    <div className={style.listWrapper}>
                                        <div className={style.innerTitle}>
                                            {translation('ReplaceStyle')}{' '}
                                            {candidateComponentPublishState.styleList.length}
                                        </div>
                                        {candidateComponentPublishState.styleList.map((item) => (
                                            <div key={item.candidateComponentId} className={style.styleItem}>
                                                <CandidateComponentImg
                                                    id={item.candidateComponentId}
                                                    isStyle
                                                ></CandidateComponentImg>
                                                <ArrowRightSvg></ArrowRightSvg>
                                                <div className={style.libraryWrapper}>
                                                    <LibraryThumbnailImage
                                                        thumbnailData={{
                                                            ...item.thumbnailData,
                                                            width: 16,
                                                            height: 16,
                                                        }}
                                                        fontMissing={item.fontMissing}
                                                        className={style.styleThumbnail}
                                                    />
                                                    <div className={style.nameWrapper}>
                                                        <EllipsisTooltipWrapper text={item.name} />
                                                    </div>
                                                </div>
                                                <IconButton
                                                    selected={false}
                                                    icon={<RemoveBtnSvg />}
                                                    onClick={() => handleDelete(item)}
                                                ></IconButton>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {candidateComponentPublishState.componentList.length > 0 ? (
                                    <div className={style.listWrapper}>
                                        <div className={style.innerTitle}>
                                            {translation('ReplaceComponent')}{' '}
                                            {candidateComponentPublishState.componentList.length}
                                        </div>
                                        {candidateComponentPublishState.componentList.map((item) => (
                                            <div key={item.candidateComponentId} className={style.componentItem}>
                                                <CandidateComponentImg
                                                    id={item.candidateComponentId}
                                                ></CandidateComponentImg>
                                                <ArrowRightSvg></ArrowRightSvg>
                                                <div className={style.libraryWrapper}>
                                                    <RightClickMenuWrapper
                                                        source={
                                                            Wukong.DocumentProto.RightClickSource
                                                                .RIGHT_CLICK_SOURCE_A_I_COMPONENT_PUBLISH_PANEL
                                                        }
                                                        stopPropagation
                                                        onMouseDown={() =>
                                                            handleRightClick(item.substituteContentSnapshotNodeId)
                                                        }
                                                    >
                                                        <div
                                                            data-testid={
                                                                CandidateComponentPublishDialogTestIds.rightClickWrapper +
                                                                '-' +
                                                                item.name
                                                            }
                                                            className={style.libraryImageWrapper}
                                                        >
                                                            <LibraryThumbnailImage
                                                                thumbnailData={{
                                                                    ...item.thumbnailData,
                                                                }}
                                                                className={style.componentThumbnail}
                                                            />
                                                        </div>
                                                    </RightClickMenuWrapper>
                                                    <div className={style.nameWrapper}>
                                                        <EllipsisTooltipWrapper text={item.name} />
                                                    </div>
                                                </div>
                                                <IconButton
                                                    selected={false}
                                                    icon={<RemoveBtnSvg />}
                                                    onClick={() => handleDelete(item)}
                                                ></IconButton>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </Scrollbar>
                    </DraggablePopupV2>
                </KeyboardReceiver>
            )}
        </>
    )
}
