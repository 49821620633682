import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Collection: '合集',
    Name: '名称',
    NamePlaceholder: '请输入变量名称',
    Description: '描述',
    DescriptionPlaceholder: '请输入变量描述',
    Value: '值',
    CreateVariable: '创建变量',
    DetachVariable: '分离变量',
} as const

export const enTranslation = {
    Collection: 'Collection',
    Name: 'Name',
    NamePlaceholder: 'New variable',
    Description: 'Description',
    DescriptionPlaceholder: 'What is it for?',
    Value: 'Value',
    CreateVariable: 'Create variable',
    DetachVariable: 'Detach variable',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
