import { command } from 'ccstate'
import ReactGA from 'react-ga4'
import { InitOptions } from 'react-ga4/types/ga4'
import { traceable } from '../../../../util/src/ccstate-helper/traceable'
import { isCypress } from '../../../../util/src/ua'
import { environment } from '../../environment'
import { UserVOV2 } from '../../kernel/interface/account'

const GA_ID_GLOBAL = 'G-BSLKYVNRLK' // 对应 https://analytics.google.com/analytics/web/#/a313436502p443576368/admin/streams/table/
const GA_ID_CN = 'G-15BSSFH7PZ' // 对应 https://analytics.google.com/analytics/web/#/a313436502p443613671/admin/streams/table/8207341159
const GA_ID_FULL_GLOBAL = 'G-ZDBNQNR2TT' // 对应 https://analytics.google.com/analytics/web/#/a313436502p440497063/admin/streams/table/8135110652 中的海外版
const GA_ID_FULL_CN = 'G-378W21PWG0' // 对应 https://analytics.google.com/analytics/web/#/a313436502p440497063/admin/streams/table/8135110652 中的国内版

const INIT_OPTIONS: InitOptions[] =
    // 这里每个站都需要上报两个点，因为我们 google analytics 有三个媒体资源，所以每个站除了需要上报自己独立的媒体资源以外，还需要再上传一个全流量的媒体资源 GA_ID_FULL_
    (environment.isAbroad ? [GA_ID_FULL_GLOBAL, GA_ID_GLOBAL] : [GA_ID_FULL_CN, GA_ID_CN]).map((str) => ({
        trackingId: str,
    }))

export const initGA$ = traceable(
    'hulh01@kanyun.com',
    command(() => {
        let debug_mode: undefined | 1 = 1

        if (environment.isProduction && !isCypress()) {
            debug_mode = undefined
        }

        ReactGA.initialize(INIT_OPTIONS, {
            gaOptions: {
                debug_mode,
            },
        })
    })
)

export const injectUserInfoToGA$ = traceable(
    'hulh01@kanyun.com',
    command((_, userInfo: UserVOV2) => {
        ReactGA.set({
            userId: userInfo.userId,
        })

        return () => {
            ReactGA.set({
                userId: undefined,
            })
        }
    })
)
