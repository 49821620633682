import { MonoIconCommonInfoLine16 } from '../../../../../ui-lib/src'
import { translation } from './index.translation'

import styles from './index.module.less'

interface ShareDraftTipBannerProps {
    moveDraftIntoTeam?: () => void
}

export function ShareDraftTipBanner({ moveDraftIntoTeam }: ShareDraftTipBannerProps) {
    return (
        <div className={`py-3 px-6 ${styles.shareDraftTipBanner}`} data-testid="share-draft-tip-banner">
            <div className={styles.tip}>
                <MonoIconCommonInfoLine16 />
                <span className="ml-2">{translation('ShareDraftTip')}</span>
            </div>
            <div
                className={styles.move}
                onClick={() => {
                    moveDraftIntoTeam?.()
                }}
            >
                {translation('Move')}
            </div>
        </div>
    )
}
