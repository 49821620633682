import { Wukong } from '@wukong/bridge-proto'

type CreatePrimitiveVariableData<T> =
    | {
          type: 'alias'
          alias: string
          name: string
      }
    | {
          type: 'value'
          value: T
      }

export type CreateFloatVariableData = CreatePrimitiveVariableData<number>
export type CreateBooleanVariableData = CreatePrimitiveVariableData<boolean>
export type CreateStringVariableData = CreatePrimitiveVariableData<string>

export interface SelectedSingleFloatVariable {
    type: 'float'
    variable: Wukong.DocumentProto.ISingleFloatVariable
}

// 将来其他类型 boolean string
export type SelectedSingleVariable = SelectedSingleFloatVariable

export interface CollectionNameItem {
    type: 'collection'
    name: string
}

export interface DividerItem {
    type: 'divider'
}

export interface GroupNameItem {
    type: 'group'
    name: string
}

export interface LibraryNameItem {
    type: 'library-name'
    name: string
}

export interface BaseSelectableListItem {
    optionIndex: number
    isSelected: boolean
}

export interface LocalVariableItem extends BaseSelectableListItem {
    type: 'local-variable'
    data: FormatedLocalPrimitiveVariable
}

export interface RemoteVariableItem extends BaseSelectableListItem {
    type: 'remote-variable'
    data: FormatedRemotePrimitiveVariable
}

export interface UnknownSingleVariableItem extends BaseSelectableListItem {
    type: 'unkown-single-variable'
    data: {
        type: 'float'
        variable: Wukong.DocumentProto.ISingleFloatVariable
    }
}

export type SelectableVariableItem = LocalVariableItem | RemoteVariableItem | UnknownSingleVariableItem

export const isSelectableVariableItem = (item: PrimitiveVariableVirtualItem): item is SelectableVariableItem => {
    return item.type === 'local-variable' || item.type === 'remote-variable' || item.type === 'unkown-single-variable'
}

export type PrimitiveVariableVirtualItem =
    | CollectionNameItem
    | GroupNameItem
    | LibraryNameItem
    | LocalVariableItem
    | RemoteVariableItem
    | UnknownSingleVariableItem
    | DividerItem

export interface FormatedLocalPrimitiveVariable {
    name: string
    variable: Wukong.DocumentProto.ILocalPrimitiveVariable
}

export interface FormatedLocalPrimitiveCollection {
    collection: {
        name: string
        nodeId: string
    }
    freeVariables: FormatedLocalPrimitiveVariable[]
    groups: {
        name: string
        variables: FormatedLocalPrimitiveVariable[]
    }[]
}

export interface FormatedRemotePrimitiveVariable {
    name: string
    variable: Wukong.DocumentProto.IRemotePrimitiveVariable
}

export interface FormatedRemotePrimitiveCollection {
    collection: {
        name: string
        nodeId: string
    }
    freeVariables: FormatedRemotePrimitiveVariable[]
    groups: {
        name: string
        variables: FormatedRemotePrimitiveVariable[]
    }[]
}

export interface FormatedRemotePrimitiveLibrary {
    id: string
    name: string
    documentId: string
    collections: FormatedRemotePrimitiveCollection[]
}

/**
 * a/b/c -> { groupNames: ['a', 'a/b'], variableName: 'c' }
 */
export const parseVariableName = (name: string) => {
    const strs = name.split('/')
    const variableName = strs.pop() ?? ''

    const groupNames: string[] = []
    let groupName = ''
    for (const str of strs) {
        groupName = groupName ? `${groupName}/${str}` : str
        groupNames.push(groupName)
    }
    return { groupNames, variableName }
}

export const getRequiredLocalPrimitiveCollections = (
    collections: Wukong.DocumentProto.ILocalPrimitiveCollection[],
    requiredScopes: Wukong.DocumentProto.VariableScope[],
    requiredTypes: Wukong.DocumentProto.PrimitiveVariableType[]
) => {
    return collections
        .map((collection) => {
            const variables = collection.variables
                .filter((variable) => {
                    return variable.scopes.some((scope) => requiredScopes.includes(scope))
                })
                .filter((variable) => {
                    return requiredTypes.includes(variable.data.type)
                })

            return { ...collection, variables }
        })
        .filter((collection) => {
            return collection.variables.length > 0
        })
}

export const filterLocalPrimitiveCollections = (
    collections: Wukong.DocumentProto.ILocalPrimitiveCollection[],
    searchStr: string
) => {
    return collections
        .map((collection) => {
            const variables = collection.variables.filter((variable) => {
                return !searchStr || variable.name.includes(searchStr) || variable.description.includes(searchStr)
            })

            return { ...collection, variables }
        })
        .filter((collection) => {
            return collection.variables.length > 0
        })
}

export const formatLocalPrimitiveCollections = (
    collections: Wukong.DocumentProto.ILocalPrimitiveCollection[]
): FormatedLocalPrimitiveCollection[] => {
    return collections.map((collection) => {
        const freeVariables: FormatedLocalPrimitiveVariable[] = []
        const groupsMap = new Map<string, { name: string; variables: FormatedLocalPrimitiveVariable[] }>()

        collection.variables.forEach((variable) => {
            const { groupNames, variableName } = parseVariableName(variable.name)

            // Ensure all parent groups are added to the map in their hierarchical order
            groupNames.forEach((groupName) => {
                if (!groupsMap.has(groupName)) {
                    groupsMap.set(groupName, { name: groupName, variables: [] })
                }
            })

            if (groupNames.length > 0) {
                groupsMap.get(groupNames[groupNames.length - 1])!.variables.push({ name: variableName, variable })
            } else {
                freeVariables.push({ name: variableName, variable })
            }
        })

        return {
            collection: {
                name: collection.name,
                nodeId: collection.nodeId,
            },
            freeVariables,
            groups: [...groupsMap.values()].filter((group) => group.variables.length > 0),
        }
    })
}

export const filterRemotePrimitiveCollections = (
    collections: Wukong.DocumentProto.IRemotePrimitiveCollection[],
    searchStr: string
) => {
    return collections
        .map((collection) => {
            return {
                ...collection,
                variables: collection.variables.filter((variable) => {
                    return !searchStr || variable.name.includes(searchStr) || variable.description.includes(searchStr)
                }),
            }
        })
        .filter((collection) => {
            return collection.variables.length > 0
        })
}

export const filterRemotePrimitiveLibraries = (
    libraries: Wukong.DocumentProto.IRemotePrimitiveLibrary[],
    searchStr: string
) => {
    return libraries
        .map((library) => {
            const collections = filterRemotePrimitiveCollections(library.collections, searchStr)

            return { ...library, collections }
        })
        .filter((library) => {
            return library.collections.length > 0
        })
}

export const getRequiredRemotePrimitiveCollections = (
    collections: Wukong.DocumentProto.IRemotePrimitiveCollection[],
    requiredScopes: Wukong.DocumentProto.VariableScope[],
    requiredTypes: Wukong.DocumentProto.PrimitiveVariableType[]
) => {
    return collections
        .map((collection) => {
            return {
                ...collection,
                variables: collection.variables
                    .filter((variable) => {
                        return variable.scope.some((scope) => requiredScopes.includes(scope))
                    })
                    .filter((variable) => {
                        return requiredTypes.includes(variable.data.type)
                    }),
            }
        })
        .filter((collection) => {
            return collection.variables.length > 0
        })
}

export const getRequiredRemotePrimitiveLibraries = (
    libraries: Wukong.DocumentProto.IRemotePrimitiveLibrary[],
    requiredScopes: Wukong.DocumentProto.VariableScope[],
    requiredTypes: Wukong.DocumentProto.PrimitiveVariableType[]
) => {
    return libraries
        .map((library) => {
            const collections = getRequiredRemotePrimitiveCollections(
                library.collections,
                requiredScopes,
                requiredTypes
            )

            return { ...library, collections }
        })
        .filter((library) => {
            return library.collections.length > 0
        })
}

export const formatRemotePrimitiveCollections = (
    collections: Wukong.DocumentProto.IRemotePrimitiveCollection[]
): FormatedRemotePrimitiveCollection[] => {
    return collections.map((collection) => {
        const freeVariables: FormatedRemotePrimitiveVariable[] = []
        const groupsMap = new Map<string, { name: string; variables: FormatedRemotePrimitiveVariable[] }>()

        collection.variables.forEach((variable) => {
            const { groupNames, variableName } = parseVariableName(variable.name)

            groupNames.forEach((groupName) => {
                if (!groupsMap.has(groupName)) {
                    groupsMap.set(groupName, { name: groupName, variables: [] })
                }
            })

            if (groupNames.length > 0) {
                groupsMap.get(groupNames[groupNames.length - 1])!.variables.push({ name: variableName, variable })
            } else {
                freeVariables.push({ name: variableName, variable })
            }
        })

        return {
            collection: {
                name: collection.name,
                nodeId: collection.id,
            },
            freeVariables,
            groups: [...groupsMap.values()],
        }
    })
}

export const formatRemotePrimitiveLibraries = (
    libraries: Wukong.DocumentProto.IRemotePrimitiveLibrary[]
): FormatedRemotePrimitiveLibrary[] => {
    return libraries
        .map((library) => {
            return {
                id: library.id,
                name: library.name,
                documentId: library.documentId,
                collections: formatRemotePrimitiveCollections(library.collections),
            }
        })
        .filter((library) => {
            return library.collections.length > 0
        })
}

export const isSelectedSingleVariableInLocalOrRemote = (
    collections: Wukong.DocumentProto.ILocalPrimitiveCollection[],
    libraries: Wukong.DocumentProto.IRemotePrimitiveLibrary[],
    variable: SelectedSingleVariable
) => {
    return (
        isSelectedSingleVariableInLocalCollections(collections, variable) ||
        isSelectedSingleVariableInRemoteLibraries(libraries, variable)
    )
}

const isSelectedSingleVariableInLocalCollections = (
    collections: Wukong.DocumentProto.ILocalPrimitiveCollection[],
    variable: SelectedSingleVariable
) => {
    if (variable.variable.isLocal) {
        return collections.some((collection) => {
            return collection.variables.some((v) => v.nodeId === variable.variable.id)
        })
    }
    return false
}

const isSelectedSingleVariableInRemoteLibraries = (
    libraries: Wukong.DocumentProto.IRemotePrimitiveLibrary[],
    variable: SelectedSingleVariable
) => {
    if (!variable.variable.isLocal) {
        return libraries.some((library) => {
            return library.collections.some((collection) =>
                collection.variables.some((v) => v.id === variable.variable.key)
            )
        })
    }
    return false
}

export const uidGenerator = () => {
    let id = 0
    return () => id++
}
