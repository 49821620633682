import abstractIcon from '../../../../assets/plugins/unsplash/abstract.png'
import animalIcon from '../../../../assets/plugins/unsplash/animal.png'
import architectureIcon from '../../../../assets/plugins/unsplash/architecture.png'
import artIcon from '../../../../assets/plugins/unsplash/art.png'
import businessIcon from '../../../../assets/plugins/unsplash/business.png'
import colorfulIcon from '../../../../assets/plugins/unsplash/colorful.png'
import foodIcon from '../../../../assets/plugins/unsplash/food.png'
import interiorIcon from '../../../../assets/plugins/unsplash/interior.png'
import minimal from '../../../../assets/plugins/unsplash/minimal.png'
import natureIcon from '../../../../assets/plugins/unsplash/nature.png'
import plantIcon from '../../../../assets/plugins/unsplash/plant.png'
import portrait from '../../../../assets/plugins/unsplash/portrait.png'
import random from '../../../../assets/plugins/unsplash/random.png'
import spaceIcon from '../../../../assets/plugins/unsplash/space.png'
import technologyIcon from '../../../../assets/plugins/unsplash/technology.png'
import texture from '../../../../assets/plugins/unsplash/texture.png'

export default {
    abstract: abstractIcon as string,
    animal: animalIcon as string,
    architecture: architectureIcon as string,
    art: artIcon as string,
    business: businessIcon as string,
    colorful: colorfulIcon as string,
    food: foodIcon as string,
    interior: interiorIcon as string,
    minimal: minimal as string,
    nature: natureIcon as string,
    plant: plantIcon as string,
    portrait: portrait as string,
    space: spaceIcon as string,
    technology: technologyIcon as string,
    texture: texture as string,
    random: random as string,
}
