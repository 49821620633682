/* eslint-disable no-restricted-imports */
import { InspectStateCommand, Wukong } from '@wukong/bridge-proto'
import { useEffect, useRef } from 'react'
import { Scrollbar, ScrollbarRef } from '../../../../../../ui-lib/src'
import { documentLoaded$ } from '../../../../external-store/atoms/editor-context'
import { appStore$ } from '../../../../external-store/store'
import { useDevModeAvailabilityVerificationService } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { ScrollContainerProvider } from '../../design/design-scroll-container-context'
import { InteractionDevProvider } from '../../prototype/prototype-interaction-dev/hook'
import { PrototypeInteractionDev } from '../../prototype/prototype-interaction-dev/prototype-interaction-dev'
import { DevModeInspectBatchExportBtn } from './dev-mode-inspect-batch-export-btn'
import { DevModeInspectCodeConfig } from './dev-mode-inspect-code-config'
import { DevModeInspectComponent } from './dev-mode-inspect-component'
import { DevModeInspectComponentInstanceSubordinate } from './dev-mode-inspect-component-subordinate'
import { DevModeInspectContent } from './dev-mode-inspect-content'
import { DevModeInspectEffect } from './dev-mode-inspect-effect'
import { DevModeInspectExport } from './dev-mode-inspect-export'
import { DevModeInspectFill } from './dev-mode-inspect-fill'
import { DevModeBasicInfoForNoSeat, DevModeInspectGuide } from './dev-mode-inspect-guide'
import { DevModeInspectMultiSelectionsInfo } from './dev-mode-inspect-multi-selections-info'
import { DevModeInspectNodeBasicInfo } from './dev-mode-inspect-selection-info'
import { DevModeInspectStroke } from './dev-mode-inspect-stroke'
import { DevModeInspectText } from './dev-mode-inspect-text'
import { DevModeInspectTypeSelectPanel } from './dev-mode-inspect-type-select-panel'
import { DevModePrototypeFlows } from './dev-mode-prototype-flows/dev-mode-prototype-flows'
import { DevModeSelectColors } from './dev-mode-select-colors/dev-mode-select-colors'
import style from './inspect.module.less'
import DevModeInspectSelectionInfoType = Wukong.DocumentProto.DevModeInspectSelectionInfoType

export function DevModeInspect() {
    const selectionInfo = useViewState('devModeInspectSelectionInfoViewState')

    const command = useCommand()
    const scrollbarRef = useRef<ScrollbarRef>(null)

    useEffect(() => {
        command.DEPRECATED_invokeBridge(
            InspectStateCommand,
            Wukong.DocumentProto.BridgeProtoBoolean.create({
                value: true,
            })
        )

        return () => {
            if (appStore$.get(documentLoaded$)) {
                command.DEPRECATED_invokeBridge(
                    InspectStateCommand,
                    Wukong.DocumentProto.BridgeProtoBoolean.create({
                        value: false,
                    })
                )
            }
        }
    }, [command])

    const devModeAvailablieyVerificationService = useDevModeAvailabilityVerificationService()
    const isDevModeAccessible = devModeAvailablieyVerificationService.useZustandStore.use.isDevModeAvailable()

    return (
        <Scrollbar ref={scrollbarRef} className={style.variable}>
            <ScrollContainerProvider getScrollContainer={() => scrollbarRef.current?.getContainerElement()}>
                {/* 这个 InteractionDevProvider 必须要放在最外层，因为要控制 toast 的展示状态*/}
                <InteractionDevProvider isDevMode>
                    {!isDevModeAccessible ? (
                        <InspectBasicInfoAndGuide selectionType={selectionInfo?.type} />
                    ) : (
                        <InspectItemsBySelection selectionType={selectionInfo?.type} />
                    )}
                </InteractionDevProvider>
            </ScrollContainerProvider>
            <div className={style.safeArea}></div>
        </Scrollbar>
    )
}

function InspectBasicInfoAndGuide({ selectionType }: { selectionType: DevModeInspectSelectionInfoType | undefined }) {
    return (
        <>
            <DevModeBasicInfoForNoSeat selectionType={selectionType} />
            <DevModeInspectGuide />
        </>
    )
}

function InspectItemsBySelection({ selectionType }: { selectionType: DevModeInspectSelectionInfoType | undefined }) {
    switch (selectionType) {
        case DevModeInspectSelectionInfoType.DEV_MODE_INSPECT_SELECTION_INFO_TYPE_NONE:
            return <NoneSelectionItems />
        case DevModeInspectSelectionInfoType.DEV_MODE_INSPECT_SELECTION_INFO_TYPE_SINGLE:
            return <SingleSelectionItems />
        case DevModeInspectSelectionInfoType.DEV_MODE_INSPECT_SELECTION_INFO_TYPE_MULTI:
            return <MultiSelectionItems />
        default:
            return null
    }
}

function NoneSelectionItems() {
    return (
        <>
            <DevModeInspectCodeConfig />
            <DevModePrototypeFlows />
            <DevModeSelectColors />
            <DevModeInspectBatchExportBtn />
        </>
    )
}

function SingleSelectionItems() {
    const inspectType = useViewState('devModeInspectTypeViewState')
    return (
        <>
            <DevModeInspectNodeBasicInfo />
            <DevModeInspectComponentInstanceSubordinate />
            <DevModeInspectComponent />
            <DevModeInspectTypeSelectPanel />
            {inspectType?.inspectType === Wukong.DocumentProto.DevModeInspectType.DEV_MODE_INSPECT_TYPE_PROPERTY && (
                <>
                    <DevModeInspectText />
                    <DevModeInspectFill />
                    <DevModeInspectStroke />
                    <DevModeInspectEffect />
                </>
            )}

            <DevModeInspectContent />
            <DevModeSelectColors />
            <DevModeInspectExport />
            <PrototypeInteractionDev borderTop={false} />
        </>
    )
}

function MultiSelectionItems() {
    return (
        <>
            <DevModeInspectMultiSelectionsInfo />
            <DevModeSelectColors />
            <DevModeInspectExport />
        </>
    )
}
