import { signalTimeout } from '../../../../util/src/abort-controller/timers'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import type { ExecutableDocumentRoot } from '../../document/document-bridge/document-root'
import { documentLoaded$ } from '../../external-store/atoms/editor-context'
import { appStore$ } from '../../external-store/store'
import type { Handler, HandlerProvider } from '../handler-provider/handler-provider'
import { focusViewManager } from '../service/focus-view/focus-view-manager'
import { getTextEditCustomInput } from './text-editing-input-element'

function isTextEditing(document: ExecutableDocumentRoot) {
    return document.currentPage()?.selectedTextRange
}

export class TextEditingInputHandler {
    constructor(private readonly handlerProvider: HandlerProvider, private readonly signal?: TraceableAbortSignal) {}

    private _refocusIfNeeded: Handler<FocusEvent> = (ctx) => {
        if (!appStore$.get(documentLoaded$)) {
            return
        }

        if (focusViewManager.isFocusViewEnabled) {
            return
        }

        if (!isTextEditing(ctx.documentRoot)) {
            return
        }

        const activeElement = document.activeElement
        if (activeElement && activeElement !== document.body) {
            return
        }

        const textNodeInput = getTextEditCustomInput()
        textNodeInput?.focus()
    }

    private _handleBlurInSignalTimeout: Handler<FocusEvent> = (ctx, e) => {
        // 失焦点后，用 setTimeout 等前序操作响应完成，如点击工具栏切换成 rectangle 模式，则退出了文本编辑
        signalTimeout(
            () => {
                this._refocusIfNeeded(ctx, e)
            },
            100,
            { signal: this.signal }
        )
    }

    public enable = () => {
        window.addEventListener(
            'blur',
            (e) => {
                this.handlerProvider.handle(this._handleBlurInSignalTimeout, e)
            },
            { capture: true, signal: this.signal }
        )
        window.addEventListener(
            'focus',
            (e) => {
                // 窗口重新聚焦后，检查状态并重新聚焦到文本编辑输入框
                if (e.target instanceof Window) {
                    this.handlerProvider.handle(this._refocusIfNeeded, e)
                }
            },
            { capture: true, signal: this.signal }
        )
    }

    public disable = () => {}
}
