import classnames from 'classnames'
import React, { useCallback, useRef } from 'react'
import classes from './transition-container.module.less'

export interface TransitionContainerProps {
    shouldStartTransition: () => void // 动画应当开始
    shouldEndTransition: () => void // 动画应当结束
}
export function TransitionContainer(props: React.PropsWithChildren<TransitionContainerProps>) {
    const { shouldStartTransition, shouldEndTransition } = props
    const keepStateTimerRef = useRef<NodeJS.Timeout>()

    const clearResetPlayTimeout = useCallback(() => {
        clearTimeout(keepStateTimerRef.current)
    }, [])

    const setResetPlayTimeout = useCallback(() => {
        clearResetPlayTimeout()
        keepStateTimerRef.current = setTimeout(() => {
            shouldEndTransition()
        }, 1500) // 动画播完后 1500ms 应当自动回归
    }, [clearResetPlayTimeout, shouldEndTransition])

    const onMouseEnter = useCallback(() => {
        clearResetPlayTimeout()
        shouldStartTransition()
    }, [clearResetPlayTimeout, shouldStartTransition])

    const onMouseLeave = useCallback(() => {
        clearResetPlayTimeout()
        shouldEndTransition()
    }, [clearResetPlayTimeout, shouldEndTransition])

    const onTransitionEnd = useCallback(() => {
        setResetPlayTimeout()
    }, [setResetPlayTimeout])

    return (
        <div
            className={classnames(classes.transitionContainer)}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onTransitionEnd={onTransitionEnd}
        >
            {props.children}
        </div>
    )
}
