import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    CurrentLocation: '当前位置',
    NewProject: '新建项目',
    NoResultsFound: '没有找到匹配的内容',
    Drafts: '草稿',
    SharedProjects: '共享项目',
    MoveFileReachTeamLimitWarning: '基础版团队最多只能创建 3 个文件，升级付费团队可以无限创建文件。',
    MoveFildReachFolderLimitWarning: '基础版团队中的项目最多只能创建 3 个文件，升级付费团队可以无限创建文件。',
    Others: '其他',
} as const

export const enTranslation = {
    CurrentLocation: 'Current location',
    NewProject: 'New project',
    NoResultsFound: 'No results found',
    Drafts: 'Drafts',
    SharedProjects: 'Shared projects',
    MoveFileReachTeamLimitWarning: 'Team reached the starter plan file limit of 3 files',
    MoveFildReachFolderLimitWarning: 'Project reached the starter plan file limit of 3 files',
    Others: 'Others',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
