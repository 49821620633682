import { toMixed } from '../../../../utils/mixed-value'
import { SelectOption, SelectOptionProps } from './select-option'

export interface SelectMixedOptionProps extends Omit<SelectOptionProps, 'disabled' | 'splitLineBottom' | 'value'> {
    isMixed?: boolean
}
export const mixedValue = Symbol('mixed')

export function SelectMixedOption(props: SelectMixedOptionProps) {
    const { isMixed, ...otherProps } = props
    return isMixed ? (
        <SelectOption value={mixedValue} disabled splitLineBottom {...otherProps}>
            {toMixed()}
        </SelectOption>
    ) : null
}
