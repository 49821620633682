import classnames from 'classnames'
import { forwardRef, useMemo } from 'react'
import { Position } from '../type'
import { calculateRange } from '../utils'
import classes from './dot-range.module.less'

interface RangeProps {
    minorAnchorPosition?: Position
    anchorPosition?: Position
    className?: string
    dataTestId?: string
}

export function _Range(props: RangeProps, outRef: React.Ref<HTMLDivElement>) {
    const { minorAnchorPosition, anchorPosition, className, dataTestId } = props

    const dotBoxStyle = useMemo(() => {
        if (!minorAnchorPosition || !anchorPosition) {
            return
        }
        const { width, height, left, top } = calculateRange(anchorPosition, minorAnchorPosition)
        return { width, height, left, top }
    }, [minorAnchorPosition, anchorPosition])

    return (
        <div
            ref={outRef}
            className={classnames(classes.range, { [className ?? '']: className })}
            style={dotBoxStyle}
            data-testid={dataTestId}
        ></div>
    )
}

export const Range = forwardRef(_Range)
