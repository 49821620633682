import { decode } from './image-lib'
import { imageDataToPremulAlphaBytes } from './image-lib/common'
import { ImageLibWorker } from './image-lib/image-lib'
import { ImageLibContext } from './image-lib/types'
import { isOffscreenCanvasSupport } from './offscreen-canvas'
import {
    BitmapDrawble,
    ImageBitmapCompat,
    ImageDecodedBitmap,
    ImageDecodedBitmapDecompressChunk,
    ImageDecodedBitmapKtx,
    ImageDecodedBitmapType,
    ImageTotalDecompressedDecodedBitmap,
} from './types'

export function isImageBitmapSupported() {
    return typeof ImageBitmap !== 'undefined'
}

export async function createImageBitmapCompat(cx: ImageLibContext, source: Blob): Promise<ImageBitmapCompat> {
    try {
        // 优先使用系统解码图片
        if (isImageBitmapSupported()) {
            const bitmap = await createImageBitmap(source)
            return {
                width: bitmap.width,
                height: bitmap.height,
                data: bitmap,
            }
        } else {
            const url = URL.createObjectURL(source)
            const element = new Image()
            element.src = url
            try {
                await element.decode()
            } finally {
                URL.revokeObjectURL(url)
            }
            return {
                width: element.width,
                height: element.height,
                data: element,
            }
        }
    } catch (e) {
        const buffer = await source.arrayBuffer()
        return await decode(cx, buffer)
    }
}

export async function drawImageBitmapCompatToCanvas(
    context: CanvasDrawImage,
    imageBitmap: ImageBitmapCompat,
    dx: number,
    dy: number,
    dw: number,
    dh: number
) {
    if (imageBitmap.data instanceof ImageData) {
        // createImageBitmap 在一些场景下有问题
        // 强制使用 canvas 缩放
        const scaleCanvas = document.createElement('canvas')
        scaleCanvas.width = imageBitmap.width
        scaleCanvas.height = imageBitmap.height
        const scaleContext = scaleCanvas.getContext('2d', {
            alpha: true,
            // 使用 CPU 渲染
            willReadFrequently: true,
        })
        if (scaleContext == null) {
            return
        }
        scaleContext.putImageData(imageBitmap.data, 0, 0)
        context.drawImage(scaleCanvas, dx, dy, dw, dh)
    } else {
        // 非 ImageData 直接渲染
        context.drawImage(imageBitmap.data, dx, dy, dw, dh)
    }
}

function tryToPremulAlphaBitmapBytes(el: HTMLImageElement | ImageBitmap): Uint8Array | null {
    const canvas = document.createElement('canvas')
    canvas.width = el.width
    canvas.height = el.height
    const context = canvas.getContext('2d', {
        alpha: true,
        // 使用 CPU 渲染
        willReadFrequently: true,
    })
    if (!context) {
        return null
    }
    context.drawImage(el, 0, 0)
    const imageData = context.getImageData(0, 0, el.width, el.height)
    return imageData ? imageDataToPremulAlphaBytes(imageData) : null
}

async function tryImageBitmapToPremulAlphaBitmapBytes(
    cx: ImageLibContext,
    el: ImageBitmap
): Promise<Uint8Array | null> {
    const offscreenCanvasSupport = await isOffscreenCanvasSupport()
    if (offscreenCanvasSupport) {
        const data = await ImageLibWorker.get().imageBitmapToBytesUsingOffscreenCanvas(cx, el)
        if (data) {
            return data
        }
    }
    // fallback，最不济用主线程转成 bytes
    return tryToPremulAlphaBitmapBytes(el)
}

const BitmapCompressThreshold = 512
function isSizeRequiresCompressBitmap(width: number, height: number): boolean {
    // 图太小了不要压缩
    if (width <= BitmapCompressThreshold && height <= BitmapCompressThreshold) {
        return false
    }
    return true
}

export class ImageDecodedBitmapHelpers {
    static isTotalDecompressed(bitmap: ImageDecodedBitmap): bitmap is ImageTotalDecompressedDecodedBitmap {
        if (bitmap.type === ImageDecodedBitmapType.ImageBitmap) {
            return true
        }
        if (bitmap.type === ImageDecodedBitmapType.Ktx) {
            return false
        }
        if (bitmap.data.length === 0) {
            return true
        }
        return bitmap.data.every((chunk) => chunk.chunk !== null)
    }

    static createChunkBytesList(bitmap: ImageTotalDecompressedDecodedBitmap): number[] {
        if (bitmap.type === ImageDecodedBitmapType.ImageBitmap) {
            return [ImageDecodedBitmapHelpers.bitmapBytes(bitmap)]
        }
        if (bitmap.type === ImageDecodedBitmapType.Ktx) {
            return [bitmap.data.byteLength]
        }
        return bitmap.data.map((chunk) => chunk.chunk.byteLength)
    }

    static calculateChunkBytesListLen(bitmap: ImageTotalDecompressedDecodedBitmap): number {
        if (bitmap.type === ImageDecodedBitmapType.ImageBitmap) {
            return 1
        }
        return bitmap.data.length
    }

    static bitmapBytes(bitmap: ImageDecodedBitmap | ImageDecodedBitmapDecompressChunk) {
        if ('type' in bitmap && bitmap.type === ImageDecodedBitmapType.Ktx) {
            return bitmap.data.byteLength
        } else {
            return bitmap.width * bitmap.height * 4
        }
    }
}

async function tryCompressBitmap(
    cx: ImageLibContext,
    data: Uint8Array,
    width: number,
    height: number
): Promise<Uint8Array | null> {
    if (!isSizeRequiresCompressBitmap(width, height)) {
        return null
    }

    const compressed = await ImageLibWorker.get().compressBitmap(cx, data, width, height)
    if (compressed && compressed.byteLength > data.byteLength * 0.7) {
        // 如果被压缩了，但是压缩比没有 0.7，那么直接使用不压缩的结果
        return null
    }
    return compressed
}

const TargetChunkByteSize = 2 << 20 // 一个 chunk 2 MB

export async function createChunkedDecodedBitmapImpl(
    origin: Uint8Array,
    width: number,
    height: number,
    targetChunkByteSize: number,
    tryCompressBitmapImpl: (data: Uint8Array, width: number, height: number) => Promise<Uint8Array | null>
): Promise<ImageTotalDecompressedDecodedBitmap> {
    const perChunkHeight = width * 4 >= targetChunkByteSize ? 1 : (targetChunkByteSize / (width * 4)) | 0

    const chunks: Array<ImageDecodedBitmapDecompressChunk> = []
    for (let y = 0; y < height; y += perChunkHeight) {
        const currentHeight = Math.min(perChunkHeight, height - y)
        // copy
        // 这里一定要复制，否则传递 worker 会把一整个 ArrayBuffer 传递过去，会很慢
        const chunk = origin.slice(y * width * 4, (y + currentHeight) * width * 4)
        const compressed = await tryCompressBitmapImpl(chunk, width, currentHeight)
        chunks.push({
            y,
            width,
            height: currentHeight,
            chunk,
            compressed,
        })
    }

    return {
        type: ImageDecodedBitmapType.Bytes,
        width,
        height,
        data: chunks,
    }
}

async function createBytesLikeBitmap(
    cx: ImageLibContext,
    origin: Uint8Array,
    width: number,
    height: number
): Promise<ImageTotalDecompressedDecodedBitmap | ImageDecodedBitmapKtx> {
    return createChunkedDecodedBitmapImpl(origin, width, height, TargetChunkByteSize, (data, chunkWidth, chunkHeight) =>
        tryCompressBitmap(cx, data, chunkWidth, chunkHeight)
    )
}

export async function bitmapCompatToDecoded(
    cx: ImageLibContext,
    bitmap: ImageBitmapCompat
): Promise<
    | {
          success: true
          data: ImageTotalDecompressedDecodedBitmap
      }
    | { success: false }
> {
    if (bitmap.data instanceof ImageData) {
        const data = imageDataToPremulAlphaBytes(bitmap.data)
        return {
            success: true,
            data: await createBytesLikeBitmap(cx, data, bitmap.width, bitmap.height),
        }
    }
    if (bitmap.data instanceof HTMLImageElement) {
        const data = tryToPremulAlphaBitmapBytes(bitmap.data)
        if (!data) {
            return {
                success: false,
            }
        }

        return {
            success: true,
            data: await createBytesLikeBitmap(cx, data, bitmap.width, bitmap.height),
        }
    }

    if (isSizeRequiresCompressBitmap(bitmap.width, bitmap.height)) {
        const data = await tryImageBitmapToPremulAlphaBitmapBytes(cx, bitmap.data)
        if (data) {
            const decodedBitmap = await createBytesLikeBitmap(cx, data, bitmap.width, bitmap.height)

            return {
                success: true,
                data: decodedBitmap,
            }
        }
    }

    return {
        success: true,
        data: {
            type: ImageDecodedBitmapType.ImageBitmap,
            width: bitmap.width,
            height: bitmap.height,
            data: bitmap.data,
        },
    }
}

export async function decodeControlImageWithScaleDown(
    cx: ImageLibContext,
    blob: Blob,
    maxTextureSize: number
): Promise<{
    width: number
    height: number
    data: Uint8Array
}> {
    const bitmapCompact = await createImageBitmapCompat(cx, blob)
    if (bitmapCompact.width === 0 || bitmapCompact.height === 0) {
        throw Error('decode fail')
    }

    const canvas = document.createElement('canvas')
    const scale = Math.min(maxTextureSize / Math.max(bitmapCompact.width, bitmapCompact.height), 1)
    const canvasWidth = bitmapCompact.width * scale
    const canvasHeight = bitmapCompact.height * scale

    canvas.width = canvasWidth
    canvas.height = canvasHeight

    const ctx = canvas.getContext('2d', {
        alpha: true,
        // 使用 CPU 渲染
        willReadFrequently: true,
    })
    if (!ctx) {
        throw Error('cannot create canvas 2d')
    }
    ctx.scale(scale, scale)
    if (bitmapCompact.data instanceof ImageData) {
        ctx.putImageData(bitmapCompact.data, 0, 0)
    } else {
        ctx.drawImage(bitmapCompact.data, 0, 0)
    }
    const imageData = ctx.getImageData(0, 0, canvasWidth, canvasHeight)
    const buffer = imageDataToPremulAlphaBytes(imageData)

    return {
        width: canvasWidth,
        height: canvasHeight,
        data: buffer,
    }
}

export async function drawableToBytes(cx: ImageLibContext, drawable: BitmapDrawble): Promise<Uint8Array | null> {
    if (drawable.type === ImageDecodedBitmapType.ImageBitmap) {
        return tryImageBitmapToPremulAlphaBitmapBytes(cx, drawable.data)
    }

    if (drawable.type === ImageDecodedBitmapType.Ktx) {
        throw Error(`[KTX] decodedToBytes unimplement`)
    }

    let totalBytes = 0
    for (const chunk of drawable.data) {
        totalBytes += ImageDecodedBitmapHelpers.bitmapBytes(chunk)
    }

    const buf = new Uint8Array(totalBytes)
    let offset = 0
    for (const chunk of drawable.data) {
        const chunkBytes = ImageDecodedBitmapHelpers.bitmapBytes(chunk)
        const chunkBuffer = chunk.chunk
        buf.set(chunkBuffer, offset)
        offset += chunkBytes
    }
    return buf
}
