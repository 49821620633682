import { FormatedLocalPrimitiveVariable } from '../common'
import { FloatVariableItem } from './float-variable-item'
import { translation } from './local-float-variable-item.translation'

export interface LocalFloatVariableItem {
    data: FormatedLocalPrimitiveVariable
    hideVariableValue?: boolean
    isSelected: boolean
    isPreselect: boolean
    onClick: () => void
    onMouseEnter: () => void
    onMouseLeave: () => void
}

export const LocalFloatVariableItem = ({
    data,
    hideVariableValue,
    isSelected,
    isPreselect,
    onClick,
    onMouseEnter,
    onMouseLeave,
}: LocalFloatVariableItem) => {
    return (
        <FloatVariableItem
            name={data.name}
            value={data.variable.data.isMixed ? translation('Mixed') : data.variable.data.floatValue}
            hideVariableValue={hideVariableValue}
            isSelected={isSelected}
            isPreselect={isPreselect}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            dataTestId={{
                container: 'local-float-variable-item',
                name: 'local-float-variable-item-name',
                value: 'local-float-variable-item-value',
            }}
        />
    )
}
