import { StateCreator, StoreApi, UseBoundStore, create } from 'zustand'
import { devtools, subscribeWithSelector } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

// store

export const createStore = <T>(state: StateCreator<T>, isDev = false) => {
    return create<T>()(subscribeWithSelector(devtools(state, { enabled: isDev })))
}

// selector

export type UseDefaultSelectors<S> = S extends { getState: () => infer T }
    ? S & { use: { [K in keyof T]: () => T[K] } }
    : never

export const createSelectors = <T extends object, S extends UseBoundStore<StoreApi<T>>>(_store: S) => {
    const store = _store as UseDefaultSelectors<typeof _store>
    store.use = {} as any
    for (const k of Object.keys(store.getState())) {
        ;(store.use as any)[k] = () => store((s) => s[k as keyof typeof s])
    }
    return store
}

export type StoreSelector<T> = UseDefaultSelectors<ReturnType<typeof createStore<T>>>

// immer

type Immer<T> = typeof immer<T>
type ImmerParam<T> = Parameters<Immer<T>>

export const createImmerStore = <T extends unknown>(state: ImmerParam<T>[0], isDev = false) => {
    return create(subscribeWithSelector(devtools(immer<T>(state), { enabled: isDev })))
}

export type ImmerStoreSelector<T> = UseDefaultSelectors<ReturnType<typeof createImmerStore<T>>>
