import { useRef } from 'react'
import constate from 'constate'
import useEvent from 'react-use/lib/useEvent'

// 用于控制连续锁或连续隐藏
const useItemStatus = () => {
    const lockStatusRef = useRef<boolean>()
    const visibleStatusRef = useRef<boolean>()

    // 当鼠标抬起时，重置 lock / visible
    useEvent('mouseup', () => {
        lockStatusRef.current = undefined
        visibleStatusRef.current = undefined
    })

    return {
        lockStatusRef,
        visibleStatusRef,
    }
}

export const [LayerPanelItemStatusProvider, useItemStatusContext] = constate(useItemStatus, (ctx) => ctx)
