import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Following: '正在跟随',
    aIjDaP: '的视角',
} as const

export const enTranslation = {
    Following: 'Following',
    aIjDaP: '',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
