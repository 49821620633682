import { Wukong } from '@wukong/bridge-proto'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMountedState } from 'react-use'
import { environment } from '../../../environment'
import { useColorProfileService } from '../../../main/app-context'
import { MonitorsInfo } from '../../../main/service/color-profile-service'
import { useUserConfigStateV2 } from '../../../main/user-config/user-config-hook'
import { ColorSpace } from '../design/blend/color-picker/utils/color-translate'

export type SupportedDisplayLevel =
    | {
          level: 'current'
      }
    | {
          level: 'none'
      }
    | {
          level: 'other'
          name: string
      }

export function useRenderColorSpace(): ColorSpace {
    const colorProfile = useColorProfileService().states.use.colorProfileState()
    switch (colorProfile) {
        case Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B:
            return 'srgb'
        case Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILE_DISPLAY_P3:
            return 'display-p3'
    }
}

export function useColorProfileDialog(colorProfile: Wukong.DocumentProto.DocumentColorProfile, visible: boolean) {
    const isMounted = useMountedState()
    const service = useColorProfileService()
    const currentDeviceSupportP3 = service.states.use.monitorSupportDisplayP3State()
    const [monitorInfosState, setMonitorsInfoState] = useState<MonitorsInfo | null>()

    useEffect(() => {
        service.getMonitorInfos().then((value) => {
            if (isMounted()) {
                setMonitorsInfoState(value)
            }
        })
    }, [isMounted, visible, service])

    const deviceSupportLevel = useMemo((): SupportedDisplayLevel => {
        if (colorProfile === Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B) {
            return { level: 'current' }
        }
        if (currentDeviceSupportP3) {
            return { level: 'current' }
        }
        const supportedMonitor = monitorInfosState?.monitors.find((m) => m.canRepresentDisplayP3)
        if (supportedMonitor) {
            return {
                level: 'other',
                name: supportedMonitor.name,
            }
        }
        return {
            level: 'none',
        }
    }, [colorProfile, currentDeviceSupportP3, monitorInfosState])

    const isHostSupport =
        colorProfile === Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILE_DISPLAY_P3
            ? service.isHostSupportDisplayP3()
            : true

    return {
        deviceSupportLevel,
        isHostSupport,
    }
}

export function useUserConfigColorProfileV2() {
    const [colorProfile, _saveColorProfileToLocal] = useUserConfigStateV2('colorProfile')

    const saveColorProfileToLocal = useCallback(
        (value: Wukong.DocumentProto.DocumentColorProfile) => {
            _saveColorProfileToLocal(value)
        },
        [_saveColorProfileToLocal]
    )

    return {
        colorProfile,
        saveColorProfileToLocal,
    }
}

export function useUserConfigColorProfile() {
    const n = useUserConfigColorProfileV2()
    return n
}

export const jumpToCenter = () => {
    window.open(environment.helpDocsUrl.color)
}
