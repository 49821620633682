import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    ParentComponent: '所属组件',
    ParentInstance: '所属实例',
} as const

export const enTranslation = {
    ParentComponent: 'Parent component',
    ParentInstance: 'Parent instance',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
