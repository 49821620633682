import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    MissingFonts: '文字缺失',
} as const

export const enTranslation = {
    MissingFonts: 'Missing font',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
