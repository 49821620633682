import classNames from 'classnames'
import { forwardRef, Ref } from 'react'
import { AutoSlideInCenter, AutoSlideInCenterProps, AutoSlideInCenterRef } from '../../../../ui-lib/src'
import style from './top-area.module.less'
export interface EditorTopBarProps extends AutoSlideInCenterProps {
    isNotTitle?: boolean
}
export type EditorTopBarRef = AutoSlideInCenterRef

export const _EditorTopBar = (props: EditorTopBarProps, ref: Ref<EditorTopBarRef>) => {
    const { isNotTitle, className, leftClassName, rightClassName, ...otherProps } = props
    return (
        <AutoSlideInCenter
            ref={ref}
            className={classNames(style.container, className, { [style.isTitle]: !isNotTitle })}
            leftClassName={classNames(style.left, leftClassName)}
            rightClassName={classNames(style.right, rightClassName)}
            {...otherProps}
        />
    )
}

export const EditorTopBar = forwardRef(_EditorTopBar)
