/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { DeepRequired } from '../../../../../view-state-bridge'
import type { ILocalStyleItem } from '../hooks/use-local-style-model-v2'

export const isItemInOrIsFolder = (item: ILocalStyleItem | undefined, folderName: string) => {
    if (!item) {
        return false
    }

    return item.isFolder
        ? item.folderName == folderName || item.folderName.startsWith(folderName + '/')
        : item.styleNode.name.startsWith(folderName + '/')
}

export const isItemInFolder = (item: ILocalStyleItem | undefined, folderName: string) => {
    if (!item) {
        return false
    }

    return item.isFolder
        ? item.folderName.startsWith(folderName + '/')
        : item.styleNode.name.startsWith(folderName + '/')
}

export const isItemSelected = (
    item: ILocalStyleItem,
    selection: DeepRequired<Wukong.DocumentProto.ILocalStyleSelection>
) => {
    return item.isFolder ? selection.folderNames.includes(item.folderName) : selection.styleIds.includes(item.styleId)
}

/**
 *  获取所有父级目录
 *  A => []
 *  A/B => ["A"]
 *  A/B/C => ["A", "A/B"]
 */
export function getParentFolderNames(name: string): string[] {
    const parts = name.split('/')
    parts.pop()

    const result: string[] = []
    let prefix = ''

    for (const part of parts) {
        result.push(prefix + part)
        prefix += part + '/'
    }
    return result
}
