import { CustomNode, DataNode, StructNode } from './type'

export function transformDateNodes2StructNodes<T extends CustomNode>(
    dataNodes: DataNode<T>[],
    parentKey?: string
): StructNode<T>[] {
    const structNodes: StructNode<T>[] = []
    dataNodes.forEach((dataNode, index) => {
        const structNode = transformDateNode2StructNode<T>(dataNode, parentKey ? `${parentKey}-${index}` : `${index}`)
        structNodes.push(structNode)
    })
    return structNodes
}

export function transformDateNode2StructNode<T extends CustomNode>(dataNode: DataNode<T>, key: string): StructNode<T> {
    const structNode: StructNode<T> = {
        data: dataNode,
        key: `${key}`,
    }
    if (dataNode.children) {
        structNode.children = transformDateNodes2StructNodes<T>(dataNode.children, structNode.key)
    }
    return structNode
}

export function hasChildWithStructNode<T extends CustomNode>(structNode?: StructNode<T>) {
    return !!structNode?.children?.some((child) => !isHiddenStructNode(child))
}

export function isHiddenStructNode<T extends CustomNode>(structNode: StructNode<T>) {
    return isHiddenDataNode(structNode.data)
}
export function isHiddenDataNode<T extends CustomNode>(dataNode: T) {
    return typeof dataNode.hidden === 'function' ? dataNode.hidden() : dataNode.hidden
}

export function getSameLevelStructNodesByStructNodeKey<T extends CustomNode>(
    structNodes: StructNode<T>[],
    key?: string
) {
    if (!key) {
        return null
    }
    const indexList = key.split('-')
    const lastIndex = indexList.pop()
    let list: StructNode<T>[] | undefined = structNodes
    let node: StructNode<T> | undefined
    for (const parentIndex of indexList) {
        if (!list) {
            return null
        }
        node = list[parentIndex as unknown as number]
        list = node?.children
    }
    if (!lastIndex || !list) {
        return null
    }
    return { list, index: Number(lastIndex) }
}

export function getKeyWhenMove2Top<T extends CustomNode>(
    structNodes: StructNode<T>[],
    interactiveKey?: string,
    preselectKey?: string
) {
    if (!interactiveKey) {
        return
    }
    const isFindKeyFromBottom = !preselectKey
    const info = getSameLevelStructNodesByStructNodeKey(
        structNodes,
        isFindKeyFromBottom ? interactiveKey : preselectKey
    )
    if (!info) {
        return
    }
    let length = info.list.length
    let index = isFindKeyFromBottom ? length : info.index
    while (length > 0) {
        length--
        index--
        const nextNode = info.list.at(index)
        if (nextNode && !isHiddenStructNode(nextNode) && !nextNode.data.disabled) {
            return nextNode.key
        }
    }
}

export function getKeyWhenMove2Bottom<T extends CustomNode>(
    structNodes: StructNode<T>[],
    interactiveKey?: string,
    preselectKey?: string
) {
    if (!interactiveKey) {
        return
    }
    const isFindKeyFromTop = !preselectKey
    const info = getSameLevelStructNodesByStructNodeKey(structNodes, isFindKeyFromTop ? interactiveKey : preselectKey)
    if (!info) {
        return
    }
    let length = info.list.length
    let index = isFindKeyFromTop ? -1 : info.index - length
    while (length > 0) {
        length--
        index++
        const nextNode = info.list.at(index)
        if (nextNode && !isHiddenStructNode(nextNode) && !nextNode.data.disabled) {
            return nextNode.key
        }
    }
}

export function getKeyWhenMove2Left(interactiveKey?: string, preselectKey?: string) {
    const key = preselectKey ?? interactiveKey
    if (!key) {
        return
    }
    const indexList = key.split('-')
    const lastIndex = indexList.pop()
    const nextKey = indexList.length > 0 ? indexList.join('-') : lastIndex
    return nextKey
}

export function getKeyWhenMove2Right<T extends CustomNode>(
    structNodes: StructNode<T>[],
    interactiveKey?: string,
    preselectKey?: string
) {
    const key = preselectKey ?? interactiveKey
    if (!key) {
        return
    }
    const info = getSameLevelStructNodesByStructNodeKey(structNodes, key)
    const structNode = info?.list[info.index]
    if (!info || !structNode?.children || !hasChildWithStructNode(structNode)) {
        return
    }
    let length = info.list.length
    let index = -1
    while (length > 0) {
        length--
        index++
        const nextNode = structNode.children.at(index)
        if (nextNode && !isHiddenStructNode(nextNode) && !nextNode.data.disabled) {
            return nextNode.key
        }
    }
    // 所有的children都被禁用了
    return `${preselectKey}-`
}

export function existNextDataItem<T extends CustomNode>(dataNodes: DataNode<T>[], index: number) {
    const length = dataNodes.length
    index++
    while (index < length) {
        const nextNode = dataNodes[index]
        if (nextNode && !isHiddenDataNode(nextNode)) {
            return true
        }
        index++
    }
    return false
}

export function existPrevDataItem<T extends CustomNode>(dataNodes: DataNode<T>[], index: number) {
    const length = dataNodes.length
    index--
    while (index < length && index >= 0) {
        const nextNode = dataNodes[index]
        if (nextNode && !isHiddenDataNode(nextNode)) {
            return true
        }
        index--
    }
    return false
}

interface Point {
    x: number
    y: number
}
export function isPointInQuadrilateral(A: Point, B: Point, C: Point, D: Point, P: Point) {
    const isSameSide = (p1: Point, p2: Point, p3: Point, p4: Point) => {
        const cp1 = (p2.x - p1.x) * (p3.y - p1.y) - (p2.y - p1.y) * (p3.x - p1.x)
        const cp2 = (p2.x - p1.x) * (p4.y - p1.y) - (p2.y - p1.y) * (p4.x - p1.x)
        return cp1 * cp2 >= 0
    }

    return isSameSide(A, B, C, P) && isSameSide(A, C, D, P)
}
