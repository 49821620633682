import { useEffect, useState } from 'react'
import { Switch } from '../../../../../ui-lib/src'
import { LibraryTestId } from '../../../window'
import { useLibraryRemoteSearchService } from '../hook/use-library-service-remote-search'
import { LibraryId } from '../../../kernel/interface/component-style'

// 组件库的订阅开关
export function LibrarySubscribeSwitch(props: { libraryId: LibraryId; libraryName?: string }) {
    const libraryRemoteSearchService = useLibraryRemoteSearchService()
    const subscribed = libraryRemoteSearchService.subscribeLibraryIds?.includes(props.libraryId)
    const [loading, setLoading] = useState(true)

    const onSwitchChange = (checked: boolean, event: React.MouseEvent | React.KeyboardEvent) => {
        event.stopPropagation()
        if (libraryRemoteSearchService.readOnly) {
            return
        }
        setLoading(true)
        if (checked) {
            libraryRemoteSearchService.subscribeLibrary(props.libraryId)
        } else {
            libraryRemoteSearchService.unSubscribeLibrary(props.libraryId)
        }
    }

    useEffect(() => {
        setLoading(false)
    }, [subscribed])

    return (
        <Switch
            disabled={libraryRemoteSearchService.readOnly}
            loading={loading}
            checked={subscribed}
            onChange={onSwitchChange}
            dataTestIds={{
                switch: loading
                    ? undefined
                    : LibraryTestId.HomeModal.SubscriptionSwitch(props.libraryName ?? '', !!subscribed),
            }}
        />
    )
}
