import { SeatType } from '../../../../kernel/interface/type'
import { PayApplyDialogSeatType } from './type'
import { translation } from './utils.translation'

export function getUINameFromPayApplyDialogSeatType(key: PayApplyDialogSeatType) {
    switch (key) {
        case PayApplyDialogSeatType.Design:
            return translation('des')
        case PayApplyDialogSeatType.Dev:
            return translation('dev')
    }
}

export function getSeatTypeFromPayApplyDialogSeatType(key: PayApplyDialogSeatType) {
    switch (key) {
        case PayApplyDialogSeatType.Design:
            return SeatType.designer
        case PayApplyDialogSeatType.Dev:
            return SeatType.developer
    }
}
