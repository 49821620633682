export enum ImageFormat {
    Jpg,
    Png,
    Webp,
    Avif,
}

export const enum ColorSpace {
    sRGB,
    DisplayP3,
}

export interface ImageBitmapCompat {
    readonly width: number
    readonly height: number
    readonly data: ImageData | ImageBitmap | HTMLImageElement
}

export const enum ImageDecodedBitmapType {
    ImageBitmap,
    Bytes,
    Ktx,
}

export const enum TextureCompressionFormat {
    None,
    ASTC_4x4,
    BC7,
}

export type BitmapDrawble =
    | {
          type: ImageDecodedBitmapType.ImageBitmap
          data: ImageBitmap
      }
    | {
          type: ImageDecodedBitmapType.Ktx
          format: TextureCompressionFormat
          width: number
          height: number
          data: Uint8Array
      }
    | {
          type: ImageDecodedBitmapType.Bytes
          data: Array<ImageDecodedBitmapDecompressChunk>
      }

interface ImageDecodedBitmapCommon {
    readonly width: number
    readonly height: number
}

export interface ImageDecodedBitmapImageBitmap extends ImageDecodedBitmapCommon {
    readonly type: ImageDecodedBitmapType.ImageBitmap
    readonly data: ImageBitmap
}

export interface ImageDecodedBitmapKtx extends ImageDecodedBitmapCommon {
    readonly type: ImageDecodedBitmapType.Ktx
    readonly data: Uint8Array
    readonly transcoded: {
        format: TextureCompressionFormat
        data: Uint8Array
    } | null
}

export interface ImageDecodedBitmapNoCompressedChunk {
    readonly y: number
    readonly width: number
    readonly height: number
    readonly chunk: Uint8Array
    readonly compressed: null
}

export interface ImageDecodedBitmapOnlyCompressedChunk {
    readonly y: number
    readonly width: number
    readonly height: number
    readonly chunk: null
    readonly compressed: Uint8Array
}

export interface ImageDecodedBitmapBothChunk {
    readonly y: number
    readonly width: number
    readonly height: number
    readonly chunk: Uint8Array
    readonly compressed: Uint8Array
}

export type ImageDecodedBitmapDecompressChunk = ImageDecodedBitmapBothChunk | ImageDecodedBitmapNoCompressedChunk

export interface ImageDecodedBitmapDecompressBytes extends ImageDecodedBitmapCommon {
    readonly type: ImageDecodedBitmapType.Bytes
    readonly data: Array<ImageDecodedBitmapDecompressChunk>
}

export interface ImageDecodedBitmapBothBytes extends ImageDecodedBitmapCommon {
    readonly type: ImageDecodedBitmapType.Bytes
    readonly data: Array<ImageDecodedBitmapBothChunk>
}
export interface ImageDecodedBitmapBytes extends ImageDecodedBitmapCommon {
    readonly type: ImageDecodedBitmapType.Bytes
    readonly data: Array<
        ImageDecodedBitmapBothChunk | ImageDecodedBitmapNoCompressedChunk | ImageDecodedBitmapOnlyCompressedChunk
    >
}

export type ImageDecodedBitmap = ImageDecodedBitmapImageBitmap | ImageDecodedBitmapBytes | ImageDecodedBitmapKtx

export type ImageTotalDecompressedDecodedBitmap =
    | ImageDecodedBitmapImageBitmap
    | ImageDecodedBitmapDecompressBytes
    | ImageDecodedBitmapKtx
