import { throwIfAbort } from './abort-controller/abort-error'
import { TraceableAbortSignal } from './abort-controller/traceable-abort-controller'
import { domLocation } from './dom-env'
import { isCypress } from './ua'

const PROUCTION_HOSTS = ['motiff.com', 'motiff.cn', 'www.motiff.com', 'www.motiff.cn', 'kanyun.motiff.cn']

export const isProductionHost = () => {
    return PROUCTION_HOSTS.includes(domLocation().host)
}

/**
 * 扩大 beta 灰度范围
 * kanyun 邮箱账号访问 motiff.(com|cn)、kanyun.motiff.cn 时重定向到 beta.motiff.(com|cn)
 * 在查询参数中加入 production 可组织重定向行为
 */
export const kanyunRedirect = (email: string, stayOnProd: boolean, preFn?: () => void) => {
    const isKanyunUser = email.includes('kanyun.com')
    const forceEnterProd = domLocation().href.includes('production')

    if (isKanyunUser && isProductionHost() && !forceEnterProd && !isCypress() && !stayOnProd) {
        preFn?.()
        const tld = domLocation().host.includes('.cn') ? 'cn' : 'com'
        domLocation().href = domLocation().href.replace(/((www|kanyun)\.)?motiff\.(cn|com)/, `beta.motiff.${tld}`)
    }
}

/**
 * 访问 .com 域名时有一些跳转逻辑需要处理
 * 如果用户访问了在国内环境已经存在的 project / team / file / org 时，需要重定向到 .cn 的环境
 */
export const abroadOldIdRedirect = async (signal?: TraceableAbortSignal) => {
    const host = domLocation().host
    const pathname = domLocation().pathname
    if (
        host.endsWith('motiff.com') &&
        !pathname.startsWith('/mirror') &&
        !pathname.startsWith('/file/4kM8QkgEggMw25gVIxbuF5Q')
    ) {
        let id = ''
        const pathName = domLocation().pathname

        if (/\/file\//.test(pathName)) {
            id = pathName.replace(/\/file\/(.*)/, '$1')
        } else if (/\/files\/project/.test(pathName)) {
            id = pathName.replace(/\/files\/project\/(.*)/, '$1')
        } else if (/\/files\/team/.test(pathName)) {
            id = pathName.replace(/\/files\/team\/(.*)/, '$1')
        } else if (/\/files\//.test(pathName)) {
            id = pathName.replace(/\/files\/([^/]*).*/, '$1')
        }

        await fetch(`https://api.motiff.com/wukong-api/api/client-utils/redirect-result?resourceId=${id}`, {
            signal,
        })
            .then(async (res) => {
                const { inOldResourceIds } = (await res.json()) as {
                    inOldResourceIds: boolean
                    inland: boolean
                }

                // 如果是旧文档用户，则跳转到 .cn 的文档页
                if (inOldResourceIds) {
                    domLocation().href = domLocation().href.replace('.com', '.cn')
                }
            })
            .catch((error) => {
                throwIfAbort(error)
                console.error('redirect failed!')
            })
    }
}
