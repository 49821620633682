import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    ok: '我知道了',
} as const

export const enTranslation = {
    ok: 'OK',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
