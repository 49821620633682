import { useGet } from 'ccstate-react'
import { PropsWithChildren } from 'react'
import { canvasMounted$ } from '../external-store/atoms/create-canvas'
import { CanvasContainer } from './canvas/canvas-container'

export function AppCanvasContainer(props: PropsWithChildren) {
    const canvasMounted = useGet(canvasMounted$)

    return (
        <>
            <CanvasContainer />
            {canvasMounted && props.children}
        </>
    )
}
