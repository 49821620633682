import { useCallback } from 'react'
import { CommitType } from '../../../../document/command/commit-type'
import { ConstraintUnit } from '../../../../document/node/node'
import { ScrubbableConstraintInput, ScrubbableConstraintProps } from './components/scrubbable-input-constraint'
import { Value } from './utils/type'
import { clamp, format, increment, parse } from './utils/use-export-constraint-formatter-tool'

const formatter = { parse, format, clamp, increment }

/**
 * @param props
 * @returns Function Component
 * @description export-constraint 专用
 */
export function ScrubbableInputExportConstraint(props: Omit<ScrubbableConstraintProps, 'onValueChange' | 'formatter'>) {
    const isScale = ConstraintUnit.Scale === (props.value?.unit ?? ConstraintUnit.Scale)
    const unit = props.value?.unit ?? ConstraintUnit.Scale
    const onChange = props.onChange

    const onValueChange = useCallback(
        (value: Value) => {
            if (typeof value === 'number') {
                onChange({ value, unit }, { commitType: CommitType.Noop })
            }
        },
        [unit, onChange]
    )

    return (
        <ScrubbableConstraintInput
            {...props}
            formatter={formatter}
            scrubbingDisabled={isScale}
            onValueChange={onValueChange}
        />
    )
}
