/* eslint-disable no-restricted-imports */
import {
    ApplyAiCandidate,
    ChangeEditorModeCommandForJs,
    CleanAiCopilotPreviewNode,
    DispatchMouseEventCommand,
    GetAiCopilotInfoWasmCall,
    GetImageOfFigNodeWasmCall,
    UpdateCopilotCursorCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { useRef } from 'react'
import { EditorMode } from '../../../../document/node/node'
import { featureSwitchManager } from '../../../../kernel/switch/core'
import { AICopilotV2 } from '../../../../main/ai-service/typings'
import { useAppContext, useCommand } from '../../../../main/app-context'
import { SandBoxManager } from '../../../../sandbox'
import { DragType, Rect } from './type'

export function useAiMagicCommands() {
    const cmd = useCommand()
    const ai = useAppContext().aiService
    const aiUserId = useAppContext().documentRoot.aiUserId
    const docId = useAppContext().docId
    const lastTextPositionCache = useRef<{
        param: any
        result: any
    } | null>(null)

    return {
        getImageUrlFromBase64(data: string) {
            return 'data:image/png;base64,' + data
        },
        async requestAiCopilot(rect: Rect, textInput: string, compInput: string) {
            const info = this.getAiCopilotInfoAtRect(rect)
            if (!info.screenImg || !info.screenRootData) {
                console.error('[AI Copilot] 获取手机屏数据失败', info)
                return { info, noMobileScreenData: true }
            }
            const res = await ai.requestAICopilotV2({
                indexId: aiUserId,
                intentType: compInput,
                prompt: textInput,
                screenImg: info.screenImg!,
                screenRootData: info.screenRootData!,
                boundingBox: info.boundingBox as any,
                parentId: info.parentId!,
                docId: info.docId!,
            })

            if (!res) {
                console.error('[AI Copilot] 获取 ai 推荐数据失败', info)
                return { info }
            }

            return {
                info,
                nodeBlobInfo: {
                    nodes: res.nodes,
                    blobs: res.blobs,
                },
                candidates: res.aiResult,
                traceId: res.traceId,
            }
        },
        getAiCopilotInfoAtRect(rect: Rect) {
            return cmd.DEPRECATED_invokeBridge(GetAiCopilotInfoWasmCall, rect)
        },
        removeNode(nodeId: string) {
            cmd.DEPRECATED_invokeBridge(CleanAiCopilotPreviewNode, { value: nodeId })
        },
        applyAiCopilotResultTo(
            parentNodeId: string,
            mobileScreenNodeId: string,
            candidate: AICopilotV2.CandidateV2,
            encodedNodeBlobInfo: string,
            isPreview: boolean,
            worldRect: Rect
        ) {
            const param: Wukong.DocumentProto.IArg_AICopilotParam = {
                parentNodeId,
                mobileScreenNodeId,
                candidateNode: {
                    ...candidate.componentData,
                    script: candidate.jsScript,
                },
                ...candidate.position,
                nodeBlobInfo: encodedNodeBlobInfo,
                undoable: !isPreview, // 非预览情况下需要能 undo
                w: worldRect.width,
                h: worldRect.height,
            }

            return cmd.DEPRECATED_invokeBridge(ApplyAiCandidate, param)
        },
        async getImageOfFigNodes(
            nodes: AICopilotV2.CandidateV2[],
            nodeBlobInfo: AICopilotV2.NodeBlobInfo,
            magicBounds: Rect,
            measureTextBounds = false
        ) {
            const param: Wukong.DocumentProto.IArg_getImageOfFigNode = {
                nodes: nodes.map((node) => ({
                    ...node.componentData,
                    script: node.jsScript,
                })),
                nodeBlobInfo: JSON.stringify(nodeBlobInfo),
                scale: window.devicePixelRatio,
                magicBounds,
                measureTextBounds,
            }

            if (featureSwitchManager.isEnabled('sandbox')) {
                const sandbox = await SandBoxManager.sharedInstance
                return sandbox.commands.GetImageOfFigNodeWasmCall.invoke(param)
            }

            return cmd.DEPRECATED_invokeBridge(GetImageOfFigNodeWasmCall, param)
        },
        exitCopilot() {
            cmd.DEPRECATED_invokeBridge(ChangeEditorModeCommandForJs, {
                editorMode: EditorMode.EM_Move,
            })
        },
        changeCursor(dragType: DragType | 'init' | 'create') {
            const CursorMode = Wukong.DocumentProto.CursorMode
            type Cursor = Wukong.DocumentProto.ICursor

            const dragToCursor: Record<DragType | 'init' | 'create', Cursor> = {
                init: {
                    mode: CursorMode.CURSOR_MODE_DRAW,
                },
                create: {
                    mode: CursorMode.CURSOR_MODE_DRAWING,
                },
                l: {
                    mode: CursorMode.CURSOR_MODE_SCALE,
                    rotation: 0,
                },
                r: {
                    mode: CursorMode.CURSOR_MODE_SCALE,
                    rotation: 0,
                },
                t: {
                    mode: CursorMode.CURSOR_MODE_SCALE,
                    rotation: (Math.PI / 180) * 90,
                },
                b: {
                    mode: CursorMode.CURSOR_MODE_SCALE,
                    rotation: (Math.PI / 180) * 90,
                },
                lt: {
                    mode: CursorMode.CURSOR_MODE_SCALE,
                    rotation: (Math.PI / 180) * 45,
                },
                rb: {
                    mode: CursorMode.CURSOR_MODE_SCALE,
                    rotation: (Math.PI / 180) * 45,
                },
                rt: {
                    mode: CursorMode.CURSOR_MODE_SCALE,
                    rotation: (Math.PI / 180) * -45,
                },
                lb: {
                    mode: CursorMode.CURSOR_MODE_SCALE,
                    rotation: (Math.PI / 180) * -45,
                },
                all: {
                    mode: CursorMode.CURSOR_MODE_ARROW,
                },
                none: {
                    mode: CursorMode.CURSOR_MODE_ARROW,
                },
            }

            // 强制在下一个 tick 触发更新
            setTimeout(() => {
                cmd.DEPRECATED_invokeBridge(UpdateCopilotCursorCommand, dragToCursor[dragType])
                // 用于触发光标更新
                cmd.DEPRECATED_invokeBridge(DispatchMouseEventCommand, {})
            }, 1)
        },
        async getTextPosition(param: Omit<AICopilotV2.TextPositionParam, 'docId' | 'indexId'>) {
            const groupByIdResults: Record<string, AICopilotV2.TextPositionData> = {}
            const key = (c: AICopilotV2.ComponentDataV2) => c.docId + c.nodeId

            const ret = {
                groupByIdResults,
                get(componentData: AICopilotV2.ComponentDataV2): AICopilotV2.TextPositionData | undefined {
                    return groupByIdResults[key(componentData)]
                },
            }

            // 过滤空请求
            if (param.positionQueryList.length) {
                // 这里加个单次缓存，这样组件魔法框在更新预览图的时候不用重复请求
                if (JSON.stringify(param) === JSON.stringify(lastTextPositionCache.current?.param)) {
                    return lastTextPositionCache.current?.result
                }

                const results = await ai.requestAICopilotTextPosition({
                    ...param,
                    docId,
                    indexId: aiUserId,
                })

                // 按 docId 和 nodeId 进行分组
                results?.forEach((p) => (groupByIdResults[key(p.componentData)] = p))

                // 缓存归档
                lastTextPositionCache.current = {
                    param,
                    result: ret,
                }
            }

            return ret
        },
        decreaseLabCredits() {
            ai.decreaseAiLabCredits(true)
        },
    }
}

export type CopilotCommandType = ReturnType<typeof useAiMagicCommands>
