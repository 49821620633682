/* eslint-disable no-restricted-imports */
import { TriggerAllKeyUpBindingsCommand, Wukong } from '@wukong/bridge-proto'
import { CommandInvoker } from '../../../document/command/command-invoker'
import { ToKeyCode } from '../../../document/util/keycode'
import { focusViewManager } from '../../service/focus-view/focus-view-manager'
import { focusViewTraceLog } from '../../service/focus-view/focus-view-trace-log'
import { isFocusableElement } from '../../service/focus-view/focusable-element'
import { dispatchKeyboardEventToWasm } from '../../service/focus-view/keyboard-event-to-wasm'

import KeyboardEventType = Wukong.DocumentProto.KeyEventType

export type KeyboardEventHandler = (e: KeyboardEvent) => boolean

export type KeyboardEventHandlerId = string

export interface KeyboardReceiverProps {
    keyCode: ToKeyCode // 绑定的按键，不包含装饰键
    onKeydown?: KeyboardEventHandler // keyDown 的监听，返 true 代表继续冒泡，返回 false 代表不再冒泡
    onKeyup?: KeyboardEventHandler // keyUp 的监听，返 true 代表继续冒泡，返回 false 代表不再冒泡
    triggerOnlyByPropagation?: boolean // 是否只被冒泡触发，不在事件循环中触发
    className?: string // 自定义 className
    style?: React.CSSProperties // 自定义style
}

export interface KeyboardReceiverMapper extends KeyboardReceiverProps {
    id: KeyboardEventHandlerId
}

/**
 * 用于处理 receiver 的事件循环 / 注册注销 / keyboard 事件绑定
 * 全局单例
 */
class KeyboardReceiverStore {
    // 用于存储 id 的序列，事件循环时返序查找
    private keyboardEventHandlerIdSet: Set<KeyboardEventHandlerId> = new Set()

    // 用于存储 id -> 事件的 map
    private id2KeyboardReceiverMapper: Record<KeyboardEventHandlerId, KeyboardReceiverMapper | undefined> = {}

    // 为每一个 receiver 分配一个自增 id，用于检索和 element 查找
    private keyboardReceiverIndex = 0

    // 当前 focused Receiver，当一个 receiver 被注销时，会按照 dom 冒泡的顺序寻址到最近的 ancestor 上
    private focusedReceiverId: KeyboardEventHandlerId | undefined

    // 用于 debug，该内存指向上一次被拦截的 receiverHandlerId
    private lastActivatedReceiverId: KeyboardEventHandlerId | undefined

    private commandInvoker: CommandInvoker | undefined

    // 用于对 repeat 的 keydown 事件进行保活，一段时间没有收到持续时间则清空
    private repeatedKeyDownEventTimer: number | undefined
    private clearRepeatedKeyDownEventTimer = () => {
        if (this.repeatedKeyDownEventTimer) {
            clearTimeout(this.repeatedKeyDownEventTimer)
            this.repeatedKeyDownEventTimer = undefined
        }
    }

    // 用于 bind 形成函数引用
    private travelKeydownEvent: (e: KeyboardEvent) => void
    private travelKeyupEvent: (e: KeyboardEvent) => void
    private handleBlur: () => void

    constructor() {
        this.travelKeydownEvent = this.travelKeyboardEvent.bind(this, KeyboardEventType.KEY_EVENT_TYPE_KEY_DOWN)
        this.travelKeyupEvent = this.travelKeyboardEvent.bind(this, KeyboardEventType.KEY_EVENT_TYPE_KEY_UP)
        this.handleBlur = this.blur.bind(this)
    }

    // 初始化 keyboard 的监听
    public initKeyboardMonitor(commandInvoker: CommandInvoker): void {
        this.commandInvoker = commandInvoker
        window.addEventListener('keydown', this.travelKeydownEvent)
        window.addEventListener('keyup', this.travelKeyupEvent)
        // 监听窗口的 blur，触发所有的 up 事件，避免 down 后 up 的行为在非页面触发后造成状态保留
        window.addEventListener('blur', this.handleBlur)
    }

    // destroy
    public destroy(): void {
        // 清空局部变量
        this.keyboardReceiverIndex = 0
        this.focusedReceiverId = undefined
        this.lastActivatedReceiverId = undefined
        this.commandInvoker = undefined
        // 清空 keyboard 内存
        this.keyboardEventHandlerIdSet.clear()
        this.id2KeyboardReceiverMapper = {}
        // 清空事件
        window.removeEventListener('keydown', this.travelKeydownEvent)
        window.removeEventListener('keyup', this.travelKeyupEvent)
        window.removeEventListener('blur', this.handleBlur)
        // 清空计时器
        this.repeatedKeyDownEventTimer = undefined
    }

    // 获取 receiver 的标识 id
    public getId(): KeyboardEventHandlerId {
        this.keyboardReceiverIndex += 1
        return `keyboard-receiver-${this.keyboardReceiverIndex - 1}`
    }

    // 注册 keyboard handler
    public registerKeyboardEventHandler(mapper: KeyboardReceiverMapper): void {
        this.keyboardEventHandlerIdSet.add(mapper.id)
        this.focusedReceiverId = undefined
        if (!this.id2KeyboardReceiverMapper[mapper.id]) {
            this.id2KeyboardReceiverMapper[mapper.id] = mapper
        }
    }

    // 处理 runtime update
    public updateKeyboardReceiver(mapper: KeyboardReceiverMapper): void {
        if (this.keyboardEventHandlerIdSet.has(mapper.id) && this.id2KeyboardReceiverMapper[mapper.id]) {
            this.id2KeyboardReceiverMapper[mapper.id] = mapper
        }
    }

    // 注销 keydown handler
    public unregisterKeyboardEventHandler(
        id: KeyboardEventHandlerId,
        focusedReceiverId?: KeyboardEventHandlerId | undefined
    ): void {
        this.focusedReceiverId = focusedReceiverId
        this.keyboardEventHandlerIdSet.delete(id)
        this.id2KeyboardReceiverMapper[id] = undefined
    }

    // 获取上一次响应的 receiver element，用于 debug，如果返回 undefined，要么没有被拦截，要么被 wasm 拦截
    public getLastActivatedReceiver(): HTMLElement | null | undefined {
        return this.lastActivatedReceiverId ? document.getElementById(this.lastActivatedReceiverId) : undefined
    }

    // 在 dom 上查找 parent 为 receiver 的节点
    public findParentReceiverById(id: KeyboardEventHandlerId): KeyboardEventHandlerId | undefined {
        let parentEl = document.getElementById(id)?.parentElement
        while (parentEl && !/keyboard-receiver-\d+/.test(parentEl.id)) {
            parentEl = parentEl.parentElement
        }
        return parentEl?.id
    }

    /**
     * keydown 的事件处理
     * 1. 有 activeElement 时，按照 activeElement 向上做冒泡
     * 2. 有 receiver 被注销时，该元素祖先开始冒泡，冒泡后在注册栈中冒泡，最后递交给 wasm
     * 3. 均无，在注册栈中冒泡，最后递交给 wasm
     */
    private travelKeyboardEvent(type: KeyboardEventType, e: KeyboardEvent): void {
        if (focusViewManager.isFocusViewEnabled) {
            this._travelKeyboardEventWhenFocusViewEnabled(type, e)
        } else {
            // 如果焦点系统关闭，且事件来自焦点系统，则不处理
            // TODO(chenyn): 动态切换焦点系统带来的临时问题
            if (focusViewManager.isEventOnFocusViewElement(e)) {
                return
            }
            this._travelKeyboardEventWhenFocusViewDisabled(type, e)
        }
    }

    private _travelKeyboardEventWhenFocusViewEnabled(type: KeyboardEventType, e: KeyboardEvent) {
        // 当焦点不位于画布区，且是符合预期的可聚焦元素，和原来一样在其祖先链路中冒泡
        if (
            !focusViewManager.isCanvasFocusedInputFocused() &&
            isFocusableElement(document.activeElement as HTMLElement)
        ) {
            focusViewTraceLog(
                `[KeyboardReceiverStore] keydown NOT on canvas when FocusView enabled: ${e.type},${e.code}`,
                document.activeElement
            )
            if (document.activeElement && document.activeElement !== document.body) {
                const id = this.findReceiverByEl(document.activeElement)
                if (id) {
                    this.travelInAncestorById(id, type, e)
                }
                return
            }
            return
        }
        focusViewTraceLog(`[KeyboardReceiverStore] keydown on canvas when FocusView enabled: ${e.type},${e.code}`)

        let pass = true
        // 当没有 activeElement 时，先在 focused 链路冒泡
        if (this.focusedReceiverId) {
            pass = this.travelInAncestorById(this.focusedReceiverId, type, e)
        }
        if (!pass) {
            return
        }
        // 在注册事件栈中循环
        pass = this.travelInEventStack(type, e)
        if (!pass) {
            return
        }

        // focus-view 开启时，画布区快捷键由焦点系统接管，这里不触发快捷键
    }

    private _travelKeyboardEventWhenFocusViewDisabled(type: KeyboardEventType, e: KeyboardEvent) {
        // 如果有 activeElement，且不是 body，且是符合预期的可聚焦元素，则只在其祖先链路中冒泡
        if (
            document.activeElement &&
            document.activeElement !== document.body &&
            isFocusableElement(document.activeElement as HTMLElement)
        ) {
            const id = this.findReceiverByEl(document.activeElement)
            if (id) {
                this.travelInAncestorById(id, type, e)
            }
            return
        }
        let pass = true
        // 当没有 activeElement 时，先在 focused 链路冒泡
        if (this.focusedReceiverId) {
            pass = this.travelInAncestorById(this.focusedReceiverId, type, e)
        }
        if (!pass) {
            return
        }
        // 在注册事件栈中循环
        pass = this.travelInEventStack(type, e)
        if (!pass) {
            return
        }
        this.dispatchKeyboardEventToWasm(type, e)
    }

    private dispatchKeyboardEventToWasm(type: KeyboardEventType, e: KeyboardEvent) {
        if (!this.commandInvoker) {
            throw Error('KeyboardReceiverStore 没有初始化 CommandInvoker')
        }
        dispatchKeyboardEventToWasm(
            this.commandInvoker,
            e,
            Wukong.DocumentProto.KeyboardEventTraceSource.KEYBOARD_EVENT_TRACE_SOURCE_KEYBOARD_RECEIVER
        )
    }

    // 在目标 receiver 的祖先节点中冒泡调用事件
    private travelInAncestorById(
        receiverId: KeyboardEventHandlerId,
        type: KeyboardEventType,
        e: KeyboardEvent
    ): boolean {
        let travelId: KeyboardEventHandlerId | undefined = receiverId
        while (travelId) {
            const mapper = this.id2KeyboardReceiverMapper[travelId]
            // 拦截的前提：mapper 存在 && keycode 相等
            if (mapper && (e.keyCode === mapper.keyCode || mapper.keyCode === ToKeyCode.All)) {
                if (type === KeyboardEventType.KEY_EVENT_TYPE_KEY_DOWN && mapper.onKeydown) {
                    const pass = mapper.onKeydown(e)
                    // 标明被该 receiver 拦截
                    if (!pass) {
                        this.lastActivatedReceiverId = mapper.id
                        return false
                    }
                }
                if (type === KeyboardEventType.KEY_EVENT_TYPE_KEY_UP && mapper.onKeyup) {
                    const pass = mapper.onKeyup(e)
                    // 标明被该 receiver 拦截
                    if (!pass) {
                        this.lastActivatedReceiverId = mapper.id
                        return false
                    }
                }
            }
            // 该 mapper 没有拦截，递交给冒泡的下一个节点
            travelId = this.findParentReceiverById(travelId)
        }
        // 没有被任何拦截
        return true
    }

    // 在注册事件栈中循环
    private travelInEventStack(type: KeyboardEventType, e: KeyboardEvent): boolean {
        const reverseList = Array.from(this.keyboardEventHandlerIdSet).reverse()
        for (const id of reverseList) {
            const mapper = this.id2KeyboardReceiverMapper[id]
            // 拦截的前提：mapper 存在 && 不是只在冒泡中处理 && keycode 相等
            if (
                mapper &&
                !mapper.triggerOnlyByPropagation &&
                (e.keyCode === mapper.keyCode || mapper.keyCode === ToKeyCode.All)
            ) {
                if (type === KeyboardEventType.KEY_EVENT_TYPE_KEY_DOWN && mapper.onKeydown) {
                    const pass = mapper.onKeydown(e)
                    // 标明被该 receiver 拦截
                    if (!pass) {
                        this.lastActivatedReceiverId = mapper.id
                        return false
                    }
                }
                if (type === KeyboardEventType.KEY_EVENT_TYPE_KEY_UP && mapper.onKeyup) {
                    const pass = mapper.onKeyup(e)
                    // 标明被该 receiver 拦截
                    if (!pass) {
                        this.lastActivatedReceiverId = mapper.id
                        return false
                    }
                }
            }
        }
        // 没有被任何拦截
        return true
    }

    // 视窗失焦时需要做的处理，一般用于响应 keyup 事件
    private blur() {
        if (this.commandInvoker) {
            // 通知 wasm 触发所有的 keyup 事件
            this.commandInvoker.DEPRECATED_invokeBridge(TriggerAllKeyUpBindingsCommand)
        }
    }

    // 通过 el 向上寻找最近的 receiver
    private findReceiverByEl(el: Element): KeyboardEventHandlerId | undefined {
        let travel: Element | null = el
        while (travel && !/keyboard-receiver-\d+/.test(travel.id)) {
            travel = travel.parentElement
        }
        return travel?.id
    }
}

export const keyboardReceiverStore = new KeyboardReceiverStore()
