/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import type { HTMLAttributes } from 'react'
import { forwardRef, useMemo } from 'react'
import { GradientPaint, ImagePaint } from '../../../../document/node/node'
import { PureBlock } from '../../atom/color-block/pure'
import { PaintIconGradientImage } from './paint-icon-gradient-image'
import { PaintIconImage } from './paint-icon-image'
import style from './paint-icon.module.less'

import PaintType = Wukong.DocumentProto.PaintType

interface PaintIconProps extends Pick<HTMLAttributes<HTMLDivElement>, 'onMouseDown' | 'onClick' | 'className'> {
    paint: Wukong.DocumentProto.IPaint
    focusNoneBorder?: boolean
    colorSpace: 'srgb' | 'display-p3'
}
const _PaintIcon = (props: PaintIconProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { paint, focusNoneBorder, className, onMouseDown, onClick } = props
    const paintIconClassName = useMemo(() => {
        let _className = focusNoneBorder ? style.outlineNone : style.focusWithinOutlineBorder
        className && (_className += ' ' + className)
        return _className
    }, [focusNoneBorder, className])

    return paint.type === PaintType.PAINT_TYPE_IMAGE_PAINT ? (
        <PaintIconImage onMouseDown={onMouseDown} paint={paint as ImagePaint} className={paintIconClassName} />
    ) : paint.type === PaintType.PAINT_TYPE_SOLID_PAINT ? (
        paint.color ? (
            <PureBlock
                ref={ref}
                rgb={{ ...paint.color }}
                colorSpace={props.colorSpace}
                opacity={paint.opacity ?? undefined}
                onMouseDown={onMouseDown}
                onClick={onClick}
                className={paintIconClassName}
            />
        ) : null
    ) : paint.type === PaintType.PAINT_TYPE_GRADIENT_LINEAR ||
      paint.type === PaintType.PAINT_TYPE_GRADIENT_RADIAL ||
      paint.type === PaintType.PAINT_TYPE_GRADIENT_ANGULAR ||
      paint.type === PaintType.PAINT_TYPE_GRADIENT_DIAMOND ? (
        <PaintIconGradientImage
            onMouseDown={onMouseDown}
            paint={paint as GradientPaint}
            className={paintIconClassName}
        />
    ) : null
}

export const PaintIcon = forwardRef(_PaintIcon)
