import { useEffect, useState } from 'react'
import { CircleProgress, useAnimation } from '../../../../../../../ui-lib/src'
import style from '../tool-sync-status.module.less'

// 带进度的 toast 内容
export function ToastProgressMessage({
    progress,
    onProgressEnd,
    successMsg,
    progressMsg,
}: {
    progress: number | undefined
    onProgressEnd: () => void
    successMsg?: string
    progressMsg: string
}) {
    const [syncProgress, setSyncProgress] = useState(0)

    const scheduler = useAnimation(1000)
    useEffect(() => {
        scheduler.current.updateHooks({
            onAnimationUpdate: setSyncProgress,
        })
    }, [scheduler])

    useEffect(() => {
        if (typeof progress === 'number' && progress >= 0) {
            scheduler.current?.updateTarget(progress)
        }
    }, [progress, scheduler])

    useEffect(() => {
        if (syncProgress >= 100) {
            onProgressEnd()
        }
    }, [onProgressEnd, syncProgress])

    return (
        <div className={style.syncToast} style={{ padding: 0 }}>
            {syncProgress >= 100 && successMsg ? (
                <div className={style.toastText} data-testid="sync-progress-success">
                    {successMsg}
                </div>
            ) : (
                <>
                    <CircleProgress progress={syncProgress} color="white" size={12} className={style.toastIcon} />

                    <div className={style.toastText} data-testid="sync-progress-ongoing">
                        {progressMsg}
                    </div>
                </>
            )}
        </div>
    )
}
