import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    yxcOTc: '发送错误报告',
    mQtsMy: '取消',
    mgGmil: '不发送',
    eNbpXi: '发送中',
    XQZPJn: '发送报告',
    VzSpOC: '{{name}}遇到问题意外崩溃',
    PdGsQj: '我们已生成了相关的错误报告，希望你能发送此报告以帮助我们改善 {{name}}的质量。',
    XgjzFE: '描述问题：',
    cfXEyR: '描述引发问题的操作，将会对我们有所帮助',
    wByvxi: '我同意上传本文件的报错、操作数据等信息。',
} as const

export const enTranslation = {
    yxcOTc: 'Report an issue',
    mQtsMy: 'Cancel',
    mgGmil: "Don't send",
    eNbpXi: 'Sending',
    XQZPJn: 'Send',
    VzSpOC: '{{name}} crashed unexpectedly',
    PdGsQj: 'Please submit the crash report to help us better find a solution to this problem.',
    XgjzFE: 'Describe the issue:',
    cfXEyR: 'Provide some detailed information about the issue',
    wByvxi: 'I agree to upload the reported errors, operational data, and other relevant information of this file.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
