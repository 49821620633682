import { useRef } from 'react'

export interface AnimationHooks {
    onAnimationUpdate?: (_: number) => void
    onAnimationStart?: () => void
    onAnimationEnd?: () => void
}

export class AnimationSchedular {
    /**
     * 动画调度器，一般和 svg 搭配使用，使用场景可以参考 CircleProgress 组件
     *
     * 用法：
     * 主要提供一个无量纲的数值，该数值随着浏览器帧变化而变化。
     *
     * 一个经典场景是加载动画的场景，我们需要设定动画的过渡耗时 duration，以及动画属性的目标值。
     *
     * 我们以圆形加载器为例，需要计算圆形组件的角度值 angle 随时间 t 变化的值，记做 angle = f(t)
     *
     * 此外，由于加载的进度和动画的进度并不一致，所以 loading progress 会作为目标值传入 f，然后 f 会给出
     * angle 和 t 之间的关系，此时 angle = f(t, progress)。
     *
     * 又为了保证动画在 duration 内完成，所以 angle = f(t, progress, duration)。
     *
     * 加载进度通常在 0 - 100 之间，随着加载进度的进行，我们需要同步更新 f 的目标值 target，这就意味着
     * f 必须有私有状态，所以将其抽离为 AnimationSchedular 类，就可以这样用：
     *
     * ```typescript
     * // init 阶段
     * const schedular = new AnimationSchedular()
     *
     * schedular.updateHooks({
     *   onAnimationUpdate(x) { updateComponentState(x) }
     * }) // 每次动画值更新，更新组件的属性值
     *
     * // 加载了 10%
     * schedular.updateTarget(10)
     *
     * // 加载了 50%
     * schedular.updateTarget(50)
     *
     * // 加载了 100%
     * schedular.updateTarget(100)
     *
     * // 中断动画
     * schedular.stop()
     * ```
     *
     * @param duration 动画时长
     * @param hooks 动画的执行钩子
     */
    constructor(private readonly duration = 300, private hooks: AnimationHooks = {}) {}

    /**
     * 更新调度的目标值，一般用于计算 css / svg 的属性
     * @param target 目标值
     */
    public updateTarget(target: number) {
        this.targetValue = target
        this.step = ((this.targetValue - this.currentValue) / this.duration) * 16
        if (!this.spinning) this.schedule()
    }

    /**
     * 更新动画钩子
     * @param hooks
     */
    public updateHooks(hooks: AnimationHooks) {
        Object.assign(this.hooks, hooks)
    }

    /**
     * 强制停止动画
     */
    public stop() {
        this.shouldStop = true
    }

    /**
     * 查询动画是否在进行中
     * @returns
     */
    public isSpinning() {
        return this.isSpinning
    }

    /**
     * 调度下一帧
     */
    private schedule() {
        this.hooks?.onAnimationStart?.()
        this.spinning = true
        requestAnimationFrame(() => {
            if (this.shouldStop || this.currentValue >= this.targetValue) {
                this.spinning = false
                this.hooks.onAnimationEnd?.()
                return
            }
            this.currentValue += this.step
            this.currentValue = Math.min(this.targetValue, this.currentValue)
            this.hooks.onAnimationUpdate?.(this.currentValue)
            this.schedule()
        })
    }

    private spinning = false
    private shouldStop = false
    private step = 16 // 步进默认是 16
    private currentValue = 0
    private targetValue = 0
}

export function useAnimation(duration = 600, hooks: AnimationHooks = {}) {
    return useRef(new AnimationSchedular(duration, hooks))
}
