import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    CornerRadius: '圆角',
    IndependentCorners: '独立圆角',
    TopLeft: '左上圆角',
    TopRight: '右上圆角',
    BottomRight: '右下圆角',
    BottomLeft: '左下圆角',
} as const

export const enTranslation = {
    CornerRadius: 'Corner radius',
    IndependentCorners: 'Independent corners',
    TopLeft: 'Top left corner radius',
    TopRight: 'Top right corner radius',
    BottomRight: 'Bottom right corner radius',
    BottomLeft: 'Bottom left corner radius',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
