import { Wukong } from '@wukong/bridge-proto'

export function toCanvasRelativePosition({
    left,
    top,
    viewport,
}: {
    left: number
    top: number
    viewport: Wukong.DocumentProto.IVisibleViewport
}) {
    return {
        left: (left - viewport.x!) * viewport.zoom!,
        top: (top - viewport.y!) * viewport.zoom!,
    }
}
