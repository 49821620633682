import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    FrAmHm: '跟随引导探索',
    yWqHPJ: '基础功能',
    YQYjiW: '了解设计及协作相关基础功能',
    HHVDQP: '立即开始',
    HPubUN: '我不需要',
    FollowMotiff: '跟随引导探索 Motiff 妙多基础功能？',
    Content: '了解设计及协作相关基础功能。',
    Start: '立即开始',
    No: '我不需要',
} as const

export const enTranslation = {
    FrAmHm: '跟随引导探索',
    yWqHPJ: '基础功能',
    YQYjiW: '了解设计及协作相关基础功能',
    HHVDQP: '立即开始',
    HPubUN: '我不需要',
    FollowMotiff: 'Take a quick tour of Motiff?',
    Content: 'Walk through the basic design and collaboration features in Motiff.',
    Start: "Let's go",
    No: 'No thanks',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
