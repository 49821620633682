import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Debugger: '调试',
    MotiffScope: 'Motiff Scope',
    WasmCallMonitor: 'WASMCall 监听',
} as const

export const enTranslation = {
    Debugger: 'Debugger',
    MotiffScope: 'Motiff Scope',
    WasmCallMonitor: 'WASMCall Monitor',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
