import { translation } from './utils.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { cloneDeep } from 'lodash-es'
import { DeepRequired } from '../../../../../util/src'
import { SeatType, ResourceType, RoleStatus } from '../../../kernel/interface/type'
import {
    Message,
    MessageApplyJoinRequest,
    MessageApplyJoinResponse,
    MessageAssetHandover,
    MessageComment,
    MessageInvitation,
    MessagePluginEditInvitation,
    MessageSeatApplication,
    MessageSeatApplyResolved,
    MessageSeatUpdate,
    MessageType,
    PluginUserRoleEnum,
    ResourceBriefVO,
} from '../../../kernel/interface/wukong-message-center'
import {
    LocalMessage,
    LocalMessageApplyJoinRequest,
    LocalMessageApplyJoinResponse,
    LocalMessageAssetHandover,
    LocalMessageComment,
    LocalMessageInvitation,
    LocalMessagePluginEditInvitation,
    LocalMessageSeatApplication,
    LocalMessageSeatApplyResolved,
    LocalMessageSeatUpdate,
    WsAssetHandoverMsg,
    WsCommentMsg,
    WsInvitationMsg,
    WsJoinApplyMsg,
    WsJoinRequestMsg,
    WsPluginEditInvitationMsg,
    WsSeatApplicationMsg,
    WsSeatApplyResolvedMsg,
    WsSeatUpdateMsg,
} from './type'

export const maxAllowMergeGapTime = 3 * 60 * 1000 // 产品定义 相邻3min
export const maxAllowMergeTotalTime = 30 * 60 * 1000 // 产品定义 累计30min

function isLocalMessageAllowMerge(localMessage: Readonly<LocalMessage>): localMessage is LocalMessageComment {
    return (
        localMessage.messageType === MessageType.CommentCreate || localMessage.messageType === MessageType.CommentReply
    )
}

/**
 * @description 新的是否能合并到旧的上
 */
function isTimeAllowMerge(oldLocalMessage: LocalMessageComment, newMessageCreateTime: number) {
    return (
        newMessageCreateTime - oldLocalMessage.createTime <= maxAllowMergeTotalTime &&
        newMessageCreateTime - oldLocalMessage.lastMergeMessageCreateTime <= maxAllowMergeGapTime
    )
}

/**
 * @description 把新的合并到旧的数据上
 */
function mergeLocalMessage2Local(oldLocalMessage: LocalMessageComment, newMessage: LocalMessageComment) {
    const { id, createTime, publishUsersMap } = newMessage
    oldLocalMessage.mergeMessageNum++
    oldLocalMessage.lastMergeMessageCreateTime = createTime
    oldLocalMessage.mergeMessageIds.push(id)
    for (const user of publishUsersMap) {
        oldLocalMessage.publishUsersMap.set(user[0], user[1])
    }
}

/**
 * @description 把新的合并到旧的数据上
 */
function mergeHttpMessage2Local(oldLocalMessage: LocalMessageComment, newMessage: MessageComment) {
    const { id, createTime, publishUser } = newMessage
    oldLocalMessage.mergeMessageNum++
    oldLocalMessage.lastMergeMessageCreateTime = createTime
    oldLocalMessage.publishUsersMap.set(publishUser.id, publishUser)
    oldLocalMessage.mergeMessageIds.push(id)
}

export function unshiftLocalMessages(localMessage: Readonly<LocalMessage>, localMessages: ReadonlyArray<LocalMessage>) {
    let alreadyExist = false
    let lastMergeLocalMessage: LocalMessageComment | undefined = undefined
    const isAllowMerge = isLocalMessageAllowMerge(localMessage)
    for (const v of localMessages) {
        if (isLocalMessageAllowMerge(v)) {
            if (v.mergeMessageIds.includes(localMessage.id)) {
                alreadyExist = true
                break
            }
            if (lastMergeLocalMessage === undefined && isAllowMerge && v.docId === localMessage.docId) {
                lastMergeLocalMessage = v
            }
        } else {
            if (v.id === localMessage.id) {
                alreadyExist = true
                break
            }
        }
    }
    if (alreadyExist) {
        return [...localMessages]
    }
    if (!lastMergeLocalMessage || !isAllowMerge || !isTimeAllowMerge(lastMergeLocalMessage, localMessage.createTime)) {
        return [localMessage, ...localMessages]
    }
    const lastMergeLocalMessageCopy = cloneDeep(lastMergeLocalMessage)
    mergeLocalMessage2Local(lastMergeLocalMessageCopy, localMessage)
    return localMessages.map((v) => (v.id === lastMergeLocalMessageCopy.id ? lastMergeLocalMessageCopy : v))
}

export function transformHtmlMessages2Local(httpMessages: ReadonlyArray<Message>): LocalMessage[] {
    // httpMessages 按创建时间从大到小排序的数组（服务端数据默认）
    const ascMessages = [...httpMessages].sort((a, b) => b.createTime - a.createTime).reverse()
    const ascLocalMassages: LocalMessage[] = []
    const mergingMessageMap: Map<LocalMessageComment['docId'], LocalMessageComment> = new Map()
    const mergeMessage = (message: MessageComment) => {
        const { createTime, messageData } = message
        const mergingMessage = mergingMessageMap.get(messageData.docId)
        if (mergingMessage && isTimeAllowMerge(mergingMessage, createTime)) {
            mergeHttpMessage2Local(mergingMessage, message)
        } else {
            const localMessageComment = createLocalMessageCommentFromHttpMessage(message)
            ascLocalMassages.push(localMessageComment)
            // 把引用记录下来
            mergingMessageMap.set(messageData.docId, localMessageComment)
        }
    }
    for (const message of ascMessages) {
        switch (message.messageType) {
            case MessageType.CommentCreate: {
                mergeMessage(message)
                continue
            }
            case MessageType.CommentReply: {
                mergeMessage(message)
                continue
            }
            case MessageType.CommentMention: {
                ascLocalMassages.push(createLocalMessageCommentFromHttpMessage(message))
                continue
            }
            case MessageType.ApplyJoinRequest: {
                ascLocalMassages.push(createLocalMessageApplyJoinRequestFromHttpMessage(message))
                continue
            }
            case MessageType.ApplyJoinResponse: {
                ascLocalMassages.push(createLocalMessageApplyJoinResponseFromHttpMessage(message))
                continue
            }
            case MessageType.Invitation: {
                ascLocalMassages.push(createLocalMessageInvitationRequestFromHttpMessage(message))
                continue
            }
            case MessageType.AssetHandover: {
                ascLocalMassages.push(createLocalMessageAssetHandoverFromHttpMessage(message))
                continue
            }
            case MessageType.SeatApplication: {
                ascLocalMassages.push(createLocalMessageSeatApplicationFromHttpMessage(message))
                continue
            }
            case MessageType.SeatApplyResolved: {
                ascLocalMassages.push(createLocalMessageSeatApplyResolvedFromHttpMessage(message))
                continue
            }
            case MessageType.SeatUpdate: {
                ascLocalMassages.push(createLocalMessageSeatUpdateFromHttpMessage(message))
                continue
            }
            case MessageType.PluginEditInvitation: {
                ascLocalMassages.push(createLocalMessagePluginEditInvitationFromHttpMessage(message))
                continue
            }
        }
    }
    // 因为上面是把最早的消息push（不想用unshift）到descLocalMassages里，但展示要求是最近的最先展示，因此这里需要翻转下
    return ascLocalMassages.reverse()
}

function createLocalMessageCommentFromHttpMessage(message: MessageComment): LocalMessageComment {
    const { publishUser, messageData } = message
    return {
        id: message.id,
        createTime: message.createTime,
        read: message.read,
        messageType: message.messageType,
        mergeMessageIds: [message.id],
        lastMergeMessageCreateTime: message.createTime,
        messageText: messageData.messageText,
        docId: messageData.docId,
        docName: messageData.docName,
        thumbnail: messageData.thumbnail,
        docBackgroundColor: messageData.docBackgroundColor,
        parentId: messageData.parentId,
        nodeId: messageData.nodeId,
        pageId: messageData.pageId,
        mergeMessageNum: 1,
        publishUsersMap: new Map([[publishUser.id, publishUser]]),
    }
}

function createLocalMessageApplyJoinRequestFromHttpMessage(
    message: MessageApplyJoinRequest
): LocalMessageApplyJoinRequest {
    return {
        id: message.id,
        createTime: message.createTime,
        read: message.read,
        messageType: message.messageType,
        publishUser: message.publishUser,
        resource: message.messageData.resourceBriefVO,
        roleToBe: message.messageData.roleToBe,
        reason: message.messageData.reason,
        applicationId: message.messageData.id,
    }
}

function createLocalMessageApplyJoinResponseFromHttpMessage(
    message: MessageApplyJoinResponse
): LocalMessageApplyJoinResponse {
    return {
        id: message.id,
        createTime: message.createTime,
        read: message.read,
        messageType: message.messageType,
        publishUser: message.publishUser,
        approved: message.messageData.approved,
        joinRequestMessageData: { resource: message.messageData.joinRequestMessageData.resourceBriefVO },
        roleToBe: message.messageData.roleActual,
    }
}

function createLocalMessageInvitationRequestFromHttpMessage(message: MessageInvitation): LocalMessageInvitation {
    return {
        id: message.id,
        createTime: message.createTime,
        read: message.read,
        messageType: message.messageType,
        publishUser: message.publishUser,
        resource: message.messageData.resourceBriefVO,
        roleToBe: message.messageData.roleInvitedToBe,
        inviteRedirectUrl: message.messageData.inviteRedirectUrl,
    }
}

function createLocalMessageAssetHandoverFromHttpMessage(message: MessageAssetHandover): LocalMessageAssetHandover {
    return {
        id: message.id,
        createTime: message.createTime,
        read: message.read,
        messageType: message.messageType,
        publishUser: message.publishUser,
        handoverResources: message.messageData.resourceBriefVOs,
        fromTeamResource: message.messageData.handoverFromTeamBrief,
        fromTeamUser: message.messageData.handoverFromUserBrief,
    }
}

function createLocalMessageSeatApplicationFromHttpMessage(
    message: MessageSeatApplication
): LocalMessageSeatApplication {
    return {
        id: message.id,
        createTime: message.createTime,
        read: message.read,
        messageType: message.messageType,
        publishUser: message.publishUser,
        requestUserVO: message.messageData.requestUserVO,
        resourceBriefVO: message.messageData.resourceBriefVO,
        autoUpgrade: message.messageData.autoUpgrade,
        seatType: message.messageData.seatType,
        gracePeriodDay: message.messageData.gracePeriodDay,
        reason: message.messageData.reason,
        applicationId: message.messageData.id,
    }
}

function createLocalMessageSeatApplyResolvedFromHttpMessage(
    message: MessageSeatApplyResolved
): LocalMessageSeatApplyResolved {
    return {
        id: message.id,
        createTime: message.createTime,
        read: message.read,
        messageType: message.messageType,
        publishUser: message.publishUser,
        operatorUserVO: message.messageData.operatorUserVO,
        requestUserVO: message.messageData.requestUserVO,
        resourceBriefVO: message.messageData.resourceBriefVO,
        approved: message.messageData.approved,
        seatType: message.messageData.seatType,
    }
}

function createLocalMessageSeatUpdateFromHttpMessage(message: MessageSeatUpdate): LocalMessageSeatUpdate {
    return {
        id: message.id,
        createTime: message.createTime,
        read: message.read,
        messageType: message.messageType,
        publishUser: message.publishUser,
        operatorUserVO: message.messageData.operatorUserVO,
        operatedUserVO: message.messageData.operatedUserVO,
        resourceBriefVO: message.messageData.resourceBriefVO,
        upgrade: message.messageData.upgrade,
        actualSeatType: message.messageData.actualSeatType,
    }
}

function createLocalMessagePluginEditInvitationFromHttpMessage(
    message: MessagePluginEditInvitation
): LocalMessagePluginEditInvitation {
    return {
        id: message.id,
        createTime: message.createTime,
        read: message.read,
        messageType: message.messageType,
        publishUser: message.publishUser,
        pluginId: message.messageData.pluginId,
        pluginName: message.messageData.pluginName,
        role: message.messageData.role,
        orgResource: message.messageData.orgResource,
    }
}

function ws2http_role(role: Wukong.NotifyProto.UserRoleEnum): RoleStatus {
    switch (role) {
        case Wukong.NotifyProto.UserRoleEnum.NONE:
            return RoleStatus.None
        case Wukong.NotifyProto.UserRoleEnum.VIEWER:
            return RoleStatus.Viewer
        case Wukong.NotifyProto.UserRoleEnum.EDITOR:
            return RoleStatus.Editor
        case Wukong.NotifyProto.UserRoleEnum.ADMIN:
            return RoleStatus.Admin
        case Wukong.NotifyProto.UserRoleEnum.OWNER:
            return RoleStatus.Owner
        case Wukong.NotifyProto.UserRoleEnum.VIEW_PROTOTYPE:
            return RoleStatus.ViewPrototype
    }
}

function ws2http_seatType(role: Wukong.NotifyProto.SeatTypeEnum): SeatType {
    switch (role) {
        case Wukong.NotifyProto.SeatTypeEnum.DEVELOPER_SEAT_TYPE:
            return SeatType.developer
        case Wukong.NotifyProto.SeatTypeEnum.DESIGNER_SEAT_TYPE:
            return SeatType.designer
        case Wukong.NotifyProto.SeatTypeEnum.VIEWER_SEAT_TYPE:
            return SeatType.viewer
        default:
            return SeatType.viewer
    }
}

function ws2http_resourceType(resourceType: Wukong.NotifyProto.EntityType): ResourceType | undefined {
    switch (resourceType) {
        case Wukong.NotifyProto.EntityType.DOC:
            return ResourceType.Document
        case Wukong.NotifyProto.EntityType.FOLDER:
            return ResourceType.Folder
        case Wukong.NotifyProto.EntityType.ORG:
            return ResourceType.Organization
        case Wukong.NotifyProto.EntityType.TEAM:
            return ResourceType.Team
        default:
            return undefined
    }
}

function ws2http_resource(resource: DeepRequired<Wukong.NotifyProto.IMessageCenterResource>): ResourceBriefVO {
    return { ...resource, resourceType: ws2http_resourceType(resource.type) ?? ResourceType.Team }
}

function ws2http_pluginUserRole(role: string): PluginUserRoleEnum {
    switch (role) {
        case 'owner':
            return PluginUserRoleEnum.Owner
        case 'editor':
            return PluginUserRoleEnum.Editor
        default: // 返回最低权限用于展示
            return PluginUserRoleEnum.Editor
    }
}

export function createLocalMessageCommentFromWsMessage(message: WsCommentMsg): LocalMessageComment {
    const { messageData } = message
    const messageType =
        message.type === Wukong.NotifyProto.MessageCenterNewMessageTypeProto.MENTION_COMMENT_MESSAGE_CENTER_MESSAGE_TYPE
            ? MessageType.CommentMention
            : message.type ===
              Wukong.NotifyProto.MessageCenterNewMessageTypeProto.NEW_COMMENT_MESSAGE_CENTER_MESSAGE_TYPE
            ? MessageType.CommentCreate
            : MessageType.CommentReply
    return {
        id: message.messageId,
        createTime: message.messageCreateTimeMillion,
        read: message.read,
        messageType: messageType,
        mergeMessageIds: [message.messageId],
        lastMergeMessageCreateTime: message.messageCreateTimeMillion,
        messageText: messageData.messageText,
        docId: messageData.docId,
        docName: messageData.docName,
        thumbnail: messageData.thumbnail,
        docBackgroundColor: messageData.docBackgroundColor,
        parentId: messageData.parentId,
        nodeId: messageData.nodeId,
        pageId: messageData.pageId,
        mergeMessageNum: 1,
        publishUsersMap: new Map([[messageData.publishUser.id, messageData.publishUser]]),
    }
}

export function createLocalMessageApplyJoinRequestFromWsMessage(
    message: WsJoinRequestMsg
): LocalMessageApplyJoinRequest {
    const { messageData } = message
    return {
        id: message.messageId,
        createTime: message.messageCreateTimeMillion,
        read: message.read,
        messageType: MessageType.ApplyJoinRequest,
        publishUser: messageData.requestUser,
        resource: ws2http_resource(messageData.resource),
        roleToBe: ws2http_role(messageData.roleToBe),
        reason: messageData.reason,
        applicationId: messageData.id,
    }
}

export function createLocalMessageApplyJoinResponseFromWsMessage(
    message: WsJoinApplyMsg
): LocalMessageApplyJoinResponse {
    return {
        id: message.messageId,
        createTime: message.messageCreateTimeMillion,
        read: message.read,
        messageType: MessageType.ApplyJoinResponse,
        publishUser: message.messageData.processUser,
        approved: message.messageData.approved,
        joinRequestMessageData: { resource: ws2http_resource(message.messageData.joinRequest.resource) },
        roleToBe: ws2http_role(message.messageData.actualRole),
    }
}

export function createLocalMessageInvitationRequestFromWsMessage(message: WsInvitationMsg): LocalMessageInvitation {
    return {
        id: message.messageId,
        createTime: message.messageCreateTimeMillion,
        read: message.read,
        messageType: MessageType.Invitation,
        publishUser: message.messageData.inviteUser,
        resource: ws2http_resource(message.messageData.resource),
        roleToBe: ws2http_role(message.messageData.roleEnum),
        inviteRedirectUrl: message.messageData.inviteRedirectUrl,
    }
}

export function createLocalMessageAssetHandoverFromWsMessage(message: WsAssetHandoverMsg): LocalMessageAssetHandover {
    return {
        id: message.messageId,
        createTime: message.messageCreateTimeMillion,
        read: message.read,
        messageType: MessageType.AssetHandover,
        publishUser: message.messageData.processUser,
        handoverResources: message.messageData.resources.map(ws2http_resource),
        fromTeamResource: ws2http_resource(message.messageData.fromTeam),
        fromTeamUser: message.messageData.fromUser,
    }
}

export function createLocalMessageSeatApplicationFromWsMessage(
    message: WsSeatApplicationMsg
): LocalMessageSeatApplication {
    return {
        id: message.messageId,
        createTime: message.messageCreateTimeMillion,
        read: message.read,
        messageType: MessageType.SeatApplication,
        publishUser: message.messageData.requestUser,
        requestUserVO: message.messageData.requestUser,
        resourceBriefVO: ws2http_resource(message.messageData.resource),
        autoUpgrade: message.messageData.autoUpgrade,
        seatType: ws2http_seatType(message.messageData.seatType),
        gracePeriodDay: message.messageData.gracePeriodDay,
        reason: message.messageData.reason,
        applicationId: message.messageData.id,
    }
}

export function createLocalMessageSeatApplyResolvedFromWsMessage(
    message: WsSeatApplyResolvedMsg
): LocalMessageSeatApplyResolved {
    return {
        id: message.messageId,
        createTime: message.messageCreateTimeMillion,
        read: message.read,
        messageType: MessageType.SeatApplyResolved,
        publishUser: message.messageData.operatorUser,
        operatorUserVO: message.messageData.operatorUser,
        requestUserVO: message.messageData.requestUser,
        resourceBriefVO: ws2http_resource(message.messageData.resource),
        approved: message.messageData.approved,
        seatType: ws2http_seatType(message.messageData.seatType),
    }
}

export function createLocalMessageSeatUpdateFromWsMessage(message: WsSeatUpdateMsg): LocalMessageSeatUpdate {
    return {
        id: message.messageId,
        createTime: message.messageCreateTimeMillion,
        read: message.read,
        messageType: MessageType.SeatUpdate,
        publishUser: message.messageData.operatorUser,
        operatorUserVO: message.messageData.operatorUser,
        operatedUserVO: message.messageData.operatedUser,
        resourceBriefVO: ws2http_resource(message.messageData.resource),
        upgrade: message.messageData.upgrade,
        actualSeatType: ws2http_seatType(message.messageData.seatType),
    }
}

export function createLocalMessagePluginEditInvitationFromWsMessage(
    message: WsPluginEditInvitationMsg
): LocalMessagePluginEditInvitation {
    return {
        id: message.messageId,
        createTime: message.messageCreateTimeMillion,
        read: message.read,
        messageType: MessageType.PluginEditInvitation,
        publishUser: message.messageData.inviteUser,
        pluginId: message.messageData.pluginId,
        pluginName: message.messageData.pluginName,
        role: ws2http_pluginUserRole(message.messageData.role),
        orgResource: ws2http_resource(message.messageData.orgResource),
    }
}

export function getMessageCenterSeatTypeLabel(seatType: SeatType) {
    switch (seatType) {
        case SeatType.designer:
            return translation('DesignerSeat')
        case SeatType.developer:
            return translation('DeveloperSeat')
        case SeatType.viewer:
            return translation('ViewerSeat')
    }
}
