import constate from 'constate'
import { useEffect, useRef, useState, type PropsWithChildren } from 'react'
import { TraceableAbortController } from '../../../../../util/src/abort-controller/traceable-abort-controller'
import type { FolderID, ResourceType, TeamID } from '../../../kernel/interface/type'
import { useSpaceStore } from '../zustand/space-state'
import { DefaultLibraryService } from './service/default-library-service'

function useDefaultLibraryServiceHook(props: { resourceType: ResourceType; resourceId: TeamID | FolderID }) {
    const [service, setService] = useState<DefaultLibraryService>()
    const spaceNotifyStore = useSpaceStore.use.spaceNotifyStore()

    useEffect(() => {
        const controller = new TraceableAbortController('DefaultLibraryService')
        const result = new DefaultLibraryService(
            controller.signal,
            spaceNotifyStore,
            props.resourceId,
            props.resourceType
        )
        setService(result)
        return () => {
            controller.abort('DefaultLibraryService destroy')
            result.destroy()
        }
    }, [props.resourceId, props.resourceType, spaceNotifyStore])

    return service
}

function useInternalDefaultLibraryServiceContext(ctx: { service: DefaultLibraryService }) {
    const serviceRef = useRef<DefaultLibraryService>(ctx.service)
    return serviceRef.current
}

const [InternalDefaultLibraryServiceProvider, useDefaultLibraryService] = constate(
    useInternalDefaultLibraryServiceContext
)

export function DefaultLibraryServiceProvider(
    props: PropsWithChildren<{ resourceId: TeamID | FolderID; resourceType: ResourceType }>
) {
    const service = useDefaultLibraryServiceHook(props)
    return service ? (
        <InternalDefaultLibraryServiceProvider service={service}>
            {props.children}
        </InternalDefaultLibraryServiceProvider>
    ) : (
        <></>
    )
}

export { useDefaultLibraryService }
