import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Tools: '工具',
    View: '视图',
    Copy: '复制',
    Selection: '选中图层',
} as const

export const enTranslation = {
    Tools: 'Tools',
    View: 'View',
    Copy: 'Copy',
    Selection: 'Selection',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
