import { translation } from './blend-image.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { MonoIconPanelRotate16, Select, Tooltip, WKIconButton } from '../../../../../../../ui-lib/src'
import type { ImageFilters, ImagePaint } from '../../../../../document/node/node'

import { SingleGrid } from '../../../atom/grid/single-grid'

import { base64BlackWhite } from '../../../../assets/common/common-base64'
import { useImageEdit } from '../../../../utils/use-image-edit'
import { useEyeDropper } from '../../../eye-dropper/use-eye-dropper'
import { useBlendImage } from './use-blend-image'

import { usePaintImageThumbnail } from '../../../../utils/thumbnail-cache/paints-thumbnail'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { ScrubbableInputPercent } from '../../../atom/inputs/scrubbable-input-percent'
import { Value } from '../../../atom/inputs/utils/type'
import { ImageFilter } from './image-filter'
import { ImageIdenti, ImageShow } from './image-show'

const scaleModeToShow: { [key in Wukong.DocumentProto.ScaleMode]: string } = {
    [Wukong.DocumentProto.ScaleMode.SCALE_MODE_FILL]: translation('Fill'),
    [Wukong.DocumentProto.ScaleMode.SCALE_MODE_FIT]: translation('Fit'),
    [Wukong.DocumentProto.ScaleMode.SCALE_MODE_CROP]: translation('Crop'),
    [Wukong.DocumentProto.ScaleMode.SCALE_MODE_TILE]: translation('Tile'),
}
export interface BlendImageProps {
    data: ImagePaint
    onChangeImagePaint?: (data: ImagePaint, options?: InputOptionsForUndoSquash) => void
    enterClose?: () => void
}

export function BlendImage(props: BlendImageProps) {
    const { data, onChangeImagePaint, enterClose } = props
    const isTile = useMemo(() => data.scaleMode === Wukong.DocumentProto.ScaleMode.SCALE_MODE_TILE, [data.scaleMode])
    const { onChangeScaleMode } = useBlendImage()
    const { eyeDropperState, onChangeEyeDropper } = useEyeDropper()
    const { endEditingImage } = useImageEdit()
    const isInit = useRef<boolean>(true)
    const timerRef = useRef<NodeJS.Timeout>()

    const [isStopUpdateThumbnail, setIsStopUpdateThumbnail] = useState<boolean>(false)
    const imagePaintUrl = usePaintImageThumbnail({
        width: 240,
        height: 152,
        multiple: window.devicePixelRatio,
        paint: data,
        isStopUpdate: isStopUpdateThumbnail,
    })
    const imageUrl = useMemo(() => {
        return imagePaintUrl ? imagePaintUrl : base64BlackWhite
    }, [imagePaintUrl])

    // 取色器与图片编辑功能互斥
    const detectEyeDropper = useCallback(() => {
        timerRef.current = setTimeout(() => {
            if (isInit.current && eyeDropperState?.isDisplay) {
                onChangeEyeDropper()
            } else if (eyeDropperState?.isDisplay) {
                endEditingImage()
                enterClose?.()
            }
            isInit.current = false
        }, 0)
    }, [eyeDropperState, endEditingImage, enterClose, onChangeEyeDropper])

    useEffect(() => {
        detectEyeDropper()
        return () => {
            clearTimeout(timerRef.current)
        }
    }, [detectEyeDropper])

    const _onChangeImagePaint = useCallback(
        (imagePaint: Partial<ImagePaint>, options?: InputOptionsForUndoSquash) => {
            onChangeImagePaint?.(Object.assign({}, data, imagePaint), options)
        },
        [data, onChangeImagePaint]
    )

    const _onChangeScaleMode = useCallback(
        (scaleMode: Wukong.DocumentProto.ScaleMode) => {
            onChangeScaleMode(scaleMode)
        },
        [onChangeScaleMode]
    )

    const onChangeScalingFactor = useCallback(
        (value: Value) => {
            if (typeof value === 'number') {
                const scalingFactor = Number(value) / 100
                _onChangeImagePaint({ scalingFactor })
            }
        },
        [_onChangeImagePaint]
    )
    const onClickRotate = useCallback(() => {
        const rotation = isNil(data.rotation) ? 0 : data.rotation + Math.PI / 2
        _onChangeImagePaint({ rotation })
    }, [_onChangeImagePaint, data.rotation])

    const onChangeFilters = useCallback(
        (filters: ImageFilters, options?: InputOptionsForUndoSquash) => {
            _onChangeImagePaint({ filters }, options)
        },
        [_onChangeImagePaint]
    )

    const onChangeImage = useCallback(
        (imageIdenti: ImageIdenti) => {
            _onChangeImagePaint({ ...imageIdenti })
        },
        [_onChangeImagePaint]
    )

    const _enterClose = useCallback(
        (e: any) => {
            if (e.keyCode === 13) {
                e.stopPropagation()
                enterClose?.()
            }
        },
        [enterClose]
    )

    useEffect(() => {
        document.addEventListener('keydown', _enterClose, false)
        return () => document.removeEventListener('keydown', _enterClose)
    }, [_enterClose])

    const onDragChangeStart = useCallback(() => {
        setIsStopUpdateThumbnail(true)
    }, [])

    const onDragChangeEnd = useCallback(() => {
        setIsStopUpdateThumbnail(false)
    }, [])

    return (
        <>
            <SingleGrid
                style={{
                    gridTemplateColumns: 'repeat(60, 4px)',
                    padding: ' 8px 0',
                }}
                testId="blend-image"
            >
                <SingleGrid.Item start={5} span={21}>
                    <Select.NormalSingleLevel
                        value={data.scaleMode}
                        onChange={_onChangeScaleMode}
                        label={scaleModeToShow[data.scaleMode]}
                        dataTestIds={{
                            triggerFocus: 'scale-mode-trigger-focus',
                            container: 'scale-mode-container',
                        }}
                    >
                        {Object.keys(scaleModeToShow).map((key) => {
                            const scaleMode: Wukong.DocumentProto.ScaleMode = Number(key)
                            return (
                                <Select.NormalSingleLevel.Option
                                    value={scaleMode}
                                    key={key}
                                    backwardIcon={''}
                                    data-testid={'scale-mode-option'}
                                >
                                    {scaleModeToShow[scaleMode]}
                                </Select.NormalSingleLevel.Option>
                            )
                        })}
                    </Select.NormalSingleLevel>
                </SingleGrid.Item>
                <SingleGrid.Item start={28} span={21}>
                    <ScrubbableInputPercent
                        value={isTile ? data.scalingFactor : 1}
                        onChange={onChangeScalingFactor}
                        scrubbingDisabled
                        disabled={!isTile}
                        min={1}
                        testId="scaling-factor"
                    />
                </SingleGrid.Item>
                <SingleGrid.Item start={49} span={12} horizontalCenter>
                    <Tooltip title={translation('Rotate') + ' 90°'}>
                        <WKIconButton icon={<MonoIconPanelRotate16 />} onClick={onClickRotate} />
                    </Tooltip>
                </SingleGrid.Item>
            </SingleGrid>
            <ImageShow src={imageUrl} onChangeImage={onChangeImage} paint={data} />
            <ImageFilter
                filters={data.filters}
                onChange={onChangeFilters}
                onDragChangeStart={onDragChangeStart}
                onDragChangeEnd={onDragChangeEnd}
            />
        </>
    )
}
