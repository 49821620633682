/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { forwardRef } from 'react'
import { ColorInteraction } from '../color-interaction/color-interaction'
import { ColorInteractionFrom, ColorInteractionProps, TypeOnChangeStyle } from '../color-interaction/type'
import { PaintStruct } from './paint-struct'
import { PaintProps, usePaint } from './use-paint'

export const ColorPaint = forwardRef(function Component(
    props: PaintProps & {
        onChangeStyle: TypeOnChangeStyle
        paints: Wukong.DocumentProto.IPaint[]
        useIn: 'fill' | 'stroke'
        onChangePaints: (paints: Wukong.DocumentProto.IPaint[]) => void
        isStroke?: boolean
        onClickCreateStyleButton: ColorInteractionProps['onClickCreateStyleButton']
    },
    outRef: React.Ref<HTMLDivElement>
) {
    const {
        switchModalFalse,
        switchModalState,
        onChangePaintOpacity,
        onChangePaintVisible,
        onClickDetachColorVar,
        position,
        reactRef,
        paint,
        colorVar,
    } = usePaint(props, outRef)

    return (
        <>
            <PaintStruct
                ref={reactRef}
                className={props.className}
                paint={paint}
                colorSpace={props.colorSpace}
                colorVar={colorVar}
                selected={props.selected}
                isMini={props.isMini}
                disabled={props.disabled}
                isStroke={props.isStroke}
                onChangeColor={props.onChangeColor}
                onClickColorBlock={switchModalState}
                onClickColorInput={switchModalFalse}
                onClose={switchModalFalse}
                onChangeOpacity={onChangePaintOpacity}
                onChangeVisible={onChangePaintVisible}
                onClickSub={props.onClickSub}
                onDetachColorVar={onClickDetachColorVar}
            />

            {props.isOpen && position && (
                <ColorInteraction
                    from={props.useIn === 'fill' ? ColorInteractionFrom.FILL_PAINT : ColorInteractionFrom.STROKE_PAINT}
                    position={position}
                    onCancel={switchModalFalse}
                    paints={props.paints}
                    styleId={undefined}
                    paint={paint}
                    onChangePaintType={props.onChangePaintType!}
                    onChangeBlendMode={props.onChangeBlendMode!}
                    onChangeColor={props.onChangeColor}
                    onChangeOpacity={props.onChangeOpacity}
                    onChangeImagePaint={props.onChangeImagePaint}
                    onChangeColorStops={props.onChangeColorStops!}
                    onChangeTransform={props.onChangeTransform}
                    enterClose={switchModalFalse}
                    onChangeStyle={props.onChangeStyle}
                    onChangeColorVar={props.onChangeColorVar}
                    onChangePaints={props.onChangePaints}
                    onClickCreateStyleButton={props.onClickCreateStyleButton}
                />
            )}
        </>
    )
})
