import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    SendInvite: '发送邀请',
} as const

export const enTranslation = {
    SendInvite: 'Send invite',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
