import { MonoIconCommonDark16, MonoIconCommonLight16 } from '../../../../../../ui-lib/src'
import { translation } from './remix-design-system-mode.translation'
import { SelectingButton } from './selecting-button'
import { useAIGenUIInContext } from './use-ai-gen-ui'

export function RemixDesignSystemMode() {
    const { updateRemixConfig, tempRemixConfig } = useAIGenUIInContext()

    if (tempRemixConfig === null) {
        return null
    }

    return (
        <div className="flex flex-col gap-2 mb-0.5">
            <span className="p-0 m-0 wk-text-12 wk-font-semibold">{translation('mode')}</span>
            <div className="flex flex-row w-full gap-2">
                <SelectingButton
                    icon={<MonoIconCommonLight16 className="w-4 h-4" />}
                    onClick={() => updateRemixConfig('mode', 'light')}
                    isSelected={tempRemixConfig.get('mode') === 'light'}
                    extraClass="w-full flex flex-row items-center px-3 py-1.5"
                >
                    {translation('light')}
                </SelectingButton>
                <SelectingButton
                    icon={<MonoIconCommonDark16 className="w-4 h-4" />}
                    onClick={() => updateRemixConfig('mode', 'dark')}
                    isSelected={tempRemixConfig.get('mode') === 'dark'}
                    extraClass="w-full flex flex-row items-center px-3 py-1.5"
                >
                    {translation('dark')}
                </SelectingButton>
            </div>
        </div>
    )
}
