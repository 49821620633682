import { SVGAttributes, useMemo, forwardRef } from 'react'
export const MonoIconControlArrowDown12 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 2.64648 4.14642)"
                d="M0 0.707108L0.707105 0L3.35356 2.64645L6.00001 6.85453e-07L6.70712 0.707108L3.35357 4.06066L0 0.707108Z"
                fillRule="evenodd"
                fill="currentColor"
            />
        </svg>
    )
})
export const MonoIconControlArrowUp12 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 -1 2.64648 7.85358)"
                d="M0 0.707108L0.707105 0L3.35356 2.64645L6.00001 6.85453e-07L6.70712 0.707108L3.35357 4.06066L0 0.707108Z"
                fillRule="evenodd"
                fill="currentColor"
            />
        </svg>
    )
})
export const MonoIconControlMove12 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 3 2.5)"
                d="M0 0L6 0L6 1L0 1L0 0ZM0 3L6 3L6 4L0 4L0 3ZM6 6L0 6L0 7L6 7L6 6Z"
                fillRule="evenodd"
                fill="currentColor"
            />
        </svg>
    )
})
export const MonoIconControlDelete12 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 2.11093 2.11056)"
                d="M4.59624 3.88931L7.7784 0.707101L7.07128 0L3.88913 3.18221L0.707101 0.000238031L0 0.707351L3.18203 3.88932L0.000238031 7.07117L0.707351 7.77827L3.88914 4.59642L7.07104 7.77827L7.77815 7.07116L4.59624 3.88931Z"
                fillRule="nonzero"
                fill="currentColor"
            />
        </svg>
    )
})
export const MonoIconControlClose12 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="11.9995" height="11.9993" viewBox="0 0 11.9995 11.9993" ref={ref} {...props}>
            <path
                transform="matrix(0.707107 0.707107 -0.707107 0.707107 5.99967 0.499512)"
                d="M4.59624 3.88931L7.7784 0.707101L7.07128 0L3.88913 3.18221L0.707101 0.000238031L0 0.707351L3.18203 3.88932L0.000238031 7.07117L0.707351 7.77827L3.88914 4.59642L7.07104 7.77827L7.77815 7.07116L4.59624 3.88931Z"
                fillRule="nonzero"
                fill="currentColor"
            />
        </svg>
    )
})
export const MonoIconMenuControl12 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" ref={ref} {...props}>
            <defs>
                <clipPath id={ids[0]}>
                    <path transform="matrix(1 0 0 1 0 0)" d="M0 0L12 0L12 12L0 12L0 0Z" fillRule="nonzero" />
                </clipPath>
            </defs>
            <g clipPath={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 2.5 2)"
                    d="M0 3.5L0.705 4.205L3.5 1.415L6.295 4.205L7 3.5L3.5 0L0 3.5Z"
                    fillRule="nonzero"
                    fill="currentColor"
                />
            </g>
        </svg>
    )
})
export const MonoIconMenuOption12 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 1 2)"
                d="M6.80902 7L10 7L10 8L6.19098 8L2.69098 1L0 1L0 0L3.30902 0L6.80902 7ZM10 0L10 1L6 1L6 0L10 0Z"
                fillRule="evenodd"
                fill="currentColor"
            />
        </svg>
    )
})
export const MonoIconMenuShift12 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 0.998283 1)"
                d="M2.5 5.5L2.5 8.5C2.5 9.05229 2.94772 9.5 3.5 9.5L6.5 9.5C7.05228 9.5 7.5 9.05229 7.5 8.5L7.5 5.5L8.86973 5.5C9.3038 5.5 9.53168 4.98485 9.23969 4.66366L5.73994 0.813934C5.34318 0.377498 4.65682 0.377498 4.26006 0.813934L0.760306 4.66366C0.468318 4.98485 0.696205 5.5 1.13028 5.5L2.5 5.5ZM3.5 4.5L2.26055 4.5L5 1.48661L7.73945 4.5L6.5 4.5L6.5 8.5L3.5 8.5L3.5 4.5Z"
                fillRule="evenodd"
                fill="currentColor"
            />
        </svg>
    )
})
export const MonoIconMenuCommand12 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" ref={ref} {...props}>
            <defs>
                <clipPath id={ids[0]}>
                    <path transform="matrix(1 0 0 1 0 0)" d="M0 0L12 0L12 12L0 12L0 0Z" fillRule="nonzero" />
                </clipPath>
            </defs>
            <g clipPath={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 1.5 1.5)"
                    d="M7.25 0C6.285 0 5.5 0.785 5.5 1.75L5.5 2.5L3.5 2.5L3.5 1.75C3.5 0.785 2.715 0 1.75 0C0.785 0 0 0.785 0 1.75C0 2.715 0.785 3.5 1.75 3.5L2.5 3.5L2.5 5.5L1.75 5.5C0.785 5.5 0 6.285 0 7.25C0 8.215 0.785 9 1.75 9C2.715 9 3.5 8.215 3.5 7.25L3.5 6.5L5.5 6.5L5.5 7.25C5.5 8.215 6.285 9 7.25 9C8.215 9 9 8.215 9 7.25C9 6.285 8.215 5.5 7.25 5.5L6.5 5.5L6.5 3.5L7.25 3.5C8.215 3.5 9 2.715 9 1.75C9 0.785 8.215 0 7.25 0ZM6.5 2.5L6.5 1.75C6.5 1.335 6.835 1 7.25 1C7.665 1 8 1.335 8 1.75C8 2.165 7.665 2.5 7.25 2.5L6.5 2.5ZM1.75 2.5C1.335 2.5 1 2.165 1 1.75C1 1.335 1.335 1 1.75 1C2.165 1 2.5 1.335 2.5 1.75L2.5 2.5L1.75 2.5ZM3.5 5.5L3.5 3.5L5.5 3.5L5.5 5.5L3.5 5.5ZM7.25 8C6.835 8 6.5 7.665 6.5 7.25L6.5 6.5L7.25 6.5C7.665 6.5 8 6.835 8 7.25C8 7.665 7.665 8 7.25 8ZM1.75 8C1.335 8 1 7.665 1 7.25C1 6.835 1.335 6.5 1.75 6.5L2.5 6.5L2.5 7.25C2.5 7.665 2.165 8 1.75 8Z"
                    fillRule="nonzero"
                    fill="currentColor"
                />
            </g>
        </svg>
    )
})
export const MonoIconMenuDelete12 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" ref={ref} {...props}>
            <path
                transform="matrix(1 -1.60812e-16 1.60812e-16 1 0 2)"
                d="M1.25 4L3.5 1L10 1L10 7L3.5 7L1.25 4ZM2.7 0.4C2.88885 0.148194 3.18524 0 3.5 0L10 0L10 0C10.5523 0 11 0.447712 11 0.999997L11 1L11 7L11 7C11 7.55229 10.5523 8 10 8L10 8L3.5 8C3.18524 8 2.88885 7.85181 2.7 7.6L0.45 4.6C0.183333 4.24444 0.183333 3.75556 0.45 3.4L2.7 0.4ZM7.64648 2.14648L6.50004 3.29293L5.35363 2.14652L4.64652 2.85363L5.79293 4.00004L4.64648 5.14648L5.35359 5.85359L6.50004 4.70714L7.64652 5.85363L8.35363 5.14652L7.20714 4.00004L8.35359 2.85359L7.64648 2.14648Z"
                fillRule="evenodd"
                fill="currentColor"
            />
        </svg>
    )
})
export const MonoIconMenuColorVariable12 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" ref={ref} {...props}>
            <defs>
                <clipPath id={ids[0]}>
                    <path transform="matrix(1 0 0 1 0 0)" d="M0 0L12 0L12 12L0 12L0 0Z" fillRule="nonzero" />
                </clipPath>
            </defs>
            <g clipPath={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 1 1)"
                    d="M5 9.5Q5.16093 9.5 5.23736 9.45137Q5.29343 9.4157 5.33721 9.32181Q5.43296 9.11644 5.49716 8.40815Q5.55655 7.75295 5.6355 7.42293Q5.78334 6.80494 6.12574 6.41844Q6.4385 6.0654 7.06807 5.85558Q7.39736 5.74584 8.11182 5.6Q8.95145 5.4286 9.23042 5.29597Q9.39288 5.21874 9.45672 5.13859Q9.5 5.08427 9.5 5Q9.5 4.08415 9.14657 3.24855Q8.80504 2.44108 8.18198 1.81802Q7.55892 1.19496 6.75145 0.853427Q5.91585 0.5 5 0.5Q4.08415 0.5 3.24855 0.853427Q2.44108 1.19496 1.81802 1.81802Q1.19496 2.44108 0.853427 3.24855Q0.5 4.08415 0.5 5Q0.5 5.91585 0.853427 6.75145Q1.19496 7.55892 1.81802 8.18198Q2.44108 8.80504 3.24855 9.14657Q4.08415 9.5 5 9.5ZM5 10.5Q3.88136 10.5 2.859 10.0676Q1.87189 9.65007 1.11091 8.88909Q0.349932 8.12811 -0.0675777 7.141Q-0.5 6.11864 -0.5 5Q-0.5 3.88136 -0.0675775 2.859Q0.349934 1.87189 1.11091 1.11091Q1.87189 0.349933 2.859 -0.0675777Q3.88136 -0.5 5 -0.5Q6.11864 -0.5 7.141 -0.0675775Q8.12811 0.349933 8.88909 1.11091Q9.65007 1.87189 10.0676 2.859Q10.5 3.88136 10.5 5Q10.5 5.79964 9.6598 6.1991Q9.39373 6.3256 8.99776 6.42778Q8.77532 6.48518 8.31182 6.57979Q7.65639 6.71359 7.38425 6.80428Q7.00915 6.92929 6.87426 7.08156Q6.69771 7.28085 6.60806 7.65559Q6.54593 7.91526 6.49308 8.49842Q6.45492 8.91939 6.42205 9.1204Q6.36133 9.49173 6.24354 9.74437Q5.89125 10.5 5 10.5Z"
                    fillRule="nonzero"
                    fill="currentColor"
                />
                <path
                    transform="matrix(1 0 0 1 6.25 2.75)"
                    d="M0.75 1Q0.853562 1 0.926781 0.926781Q1 0.853562 1 0.75Q1 0.646438 0.926781 0.573219Q0.853562 0.5 0.75 0.5Q0.646438 0.5 0.573219 0.573219Q0.5 0.646438 0.5 0.75Q0.5 0.853562 0.573219 0.926781Q0.646438 1 0.75 1ZM0.75 2Q0.232224 2 -0.133888 1.63389Q-0.5 1.26778 -0.5 0.75Q-0.5 0.232224 -0.133888 -0.133888Q0.232224 -0.5 0.75 -0.5Q1.26778 -0.5 1.63389 -0.133888Q2 0.232224 2 0.75Q2 1.26778 1.63389 1.63389Q1.26778 2 0.75 2Z"
                    fillRule="nonzero"
                    fill="currentColor"
                />
                <path
                    transform="matrix(1 0 0 1 3.25 3.75)"
                    d="M0.75 1Q0.853562 1 0.926781 0.926781Q1 0.853562 1 0.75Q1 0.646438 0.926781 0.573219Q0.853562 0.5 0.75 0.5Q0.646438 0.5 0.573219 0.573219Q0.5 0.646438 0.5 0.75Q0.5 0.853562 0.573219 0.926781Q0.646438 1 0.75 1ZM0.75 2Q0.232224 2 -0.133888 1.63389Q-0.5 1.26778 -0.5 0.75Q-0.5 0.232224 -0.133888 -0.133888Q0.232224 -0.5 0.75 -0.5Q1.26778 -0.5 1.63389 -0.133888Q2 0.232224 2 0.75Q2 1.26778 1.63389 1.63389Q1.26778 2 0.75 2Z"
                    fillRule="nonzero"
                    fill="currentColor"
                />
                <path
                    transform="matrix(1 0 0 1 3.5 7)"
                    d="M0.75 1Q0.853562 1 0.926781 0.926781Q1 0.853562 1 0.75Q1 0.646438 0.926781 0.573219Q0.853562 0.5 0.75 0.5Q0.646438 0.5 0.573219 0.573219Q0.5 0.646438 0.5 0.75Q0.5 0.853562 0.573219 0.926781Q0.646438 1 0.75 1ZM0.75 2Q0.232224 2 -0.133888 1.63389Q-0.5 1.26778 -0.5 0.75Q-0.5 0.232224 -0.133888 -0.133888Q0.232224 -0.5 0.75 -0.5Q1.26778 -0.5 1.63389 -0.133888Q2 0.232224 2 0.75Q2 1.26778 1.63389 1.63389Q1.26778 2 0.75 2Z"
                    fillRule="nonzero"
                    fill="currentColor"
                />
            </g>
        </svg>
    )
})
export const MonoIconMenuNumberVariable12 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" ref={ref} {...props}>
            <defs>
                <clipPath id={ids[0]}>
                    <path transform="matrix(1 0 0 1 0 0)" d="M0 0L12 0L12 12L0 12L0 0Z" fillRule="nonzero" />
                </clipPath>
            </defs>
            <g clipPath={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 1.5 1.5)"
                    d="M-0.5 0.75C-0.5 0.059644 0.059644 -0.5 0.75 -0.5L8.25 -0.5C8.94036 -0.5 9.5 0.059644 9.5 0.75L9.5 8.25C9.5 8.94036 8.94036 9.5 8.25 9.5L0.75 9.5C0.059644 9.5 -0.5 8.94036 -0.5 8.25L-0.5 0.75ZM0.75 0.5C0.611929 0.5 0.5 0.611929 0.5 0.75L0.5 8.25C0.5 8.38807 0.611929 8.5 0.75 8.5L8.25 8.5C8.38807 8.5 8.5 8.38807 8.5 8.25L8.5 0.75C8.5 0.611929 8.38807 0.5 8.25 0.5L0.75 0.5Z"
                    fillRule="nonzero"
                    fill="currentColor"
                />
                <path
                    transform="matrix(1 0 0 1 4.75 4)"
                    d="M0 -0.5C-0.27614 -0.5 -0.5 -0.27614 -0.5 0L-0.5 4C-0.5 4.27614 -0.27614 4.5 0 4.5C0.27614 4.5 0.5 4.27614 0.5 4L0.5 0C0.5 -0.27614 0.27614 -0.5 0 -0.5Z"
                    fillRule="evenodd"
                    fill="currentColor"
                />
                <path
                    transform="matrix(1 0 0 1 7.25 4)"
                    d="M0 -0.5C-0.27614 -0.5 -0.5 -0.27614 -0.5 0L-0.5 4C-0.5 4.27614 -0.27614 4.5 0 4.5C0.27614 4.5 0.5 4.27614 0.5 4L0.5 0C0.5 -0.27614 0.27614 -0.5 0 -0.5Z"
                    fillRule="evenodd"
                    fill="currentColor"
                />
                <path
                    transform="matrix(1 0 0 1 4 4.75)"
                    d="M-0.5 0C-0.5 0.27614 -0.27614 0.5 0 0.5L4 0.5C4.27614 0.5 4.5 0.27614 4.5 0C4.5 -0.27614 4.27614 -0.5 4 -0.5L0 -0.5C-0.27614 -0.5 -0.5 -0.27614 -0.5 0Z"
                    fillRule="evenodd"
                    fill="currentColor"
                />
                <path
                    transform="matrix(1 0 0 1 4 7.25)"
                    d="M-0.5 0C-0.5 0.27614 -0.27614 0.5 0 0.5L4 0.5C4.27614 0.5 4.5 0.27614 4.5 0C4.5 -0.27614 4.27614 -0.5 4 -0.5L0 -0.5C-0.27614 -0.5 -0.5 -0.27614 -0.5 0Z"
                    fillRule="evenodd"
                    fill="currentColor"
                />
            </g>
        </svg>
    )
})
