import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    SearchCloudFonts: '搜索云端字体',
} as const

export const enTranslation = {
    SearchCloudFonts: 'Search cloud fonts',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
