import { FeatureSwitchConfig, SwitchEnvironmentScopeType, SwitchStrategyType } from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'open-mouse-scale-speed',
    owner: '',
    description: '',
    strategies: [
        {
            env: SwitchEnvironmentScopeType.TESTING,
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                userList: ['225eb66e04c4fbf5474dc54c786bcd347796f116', 'b02d0d9ae7de3736f97b6f1c5b162ac7dde63a1a'],
            },
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
