/* eslint-disable no-restricted-imports */
import { ScrollSelectedNodeIntoLayerPanelView, Wukong } from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { createSelectors, createStore } from '../../../../util/src'
import { Bridge } from '../../kernel/bridge/bridge'

// 图层面板

export class LayerPanelService {
    public states = createSelectors(
        createStore<{ autoPosition: number; hasAutoPositioning: boolean }>(() => ({
            autoPosition: 0,
            hasAutoPositioning: false,
        }))
    )
    constructor(private bridge: Bridge) {
        this.bridge.bind(ScrollSelectedNodeIntoLayerPanelView, ({ value }: Wukong.DocumentProto.IBridgeProtoInt) => {
            if (!isNil(value)) {
                this.states.setState({ autoPosition: value, hasAutoPositioning: false })
            }
        })
    }

    public setHasAutoPositioning(value: boolean) {
        this.states.setState({ hasAutoPositioning: value })
    }

    destroy() {
        this.bridge.unbind(ScrollSelectedNodeIntoLayerPanelView)
    }
}
