import { DELETE, GET, POST } from '@tutor/network-core'
import { Message, MessageId } from '../interface/wukong-message-center'
import { omitByValue } from '../util/omit-by-value'
import { BaseCommonRequest } from './base-request'

/**
 * @description 获取一个用户最近的500条消息
 */
@GET
export class GetMessageBeforeNow extends BaseCommonRequest<Message[]> {
    constructor(private orgId: string) {
        super()
    }
    public override requestArgument() {
        return { orgId: this.orgId }
    }

    public override requestUrl() {
        return `wukong-message-center/query/before-now`
    }
}

/**
 * @description 查询用户最后一次访问消息中心面板的事件
 */
@GET
export class GetMessageGetLast extends BaseCommonRequest<number> {
    constructor(private orgId: string) {
        super()
    }
    public override requestArgument() {
        return { orgId: this.orgId }
    }

    public override requestUrl() {
        return `wukong-message-center/view/get-last`
    }
}

/**
 * @description 获取一个用户messageId 小于给定messageId 的500条消息，<b>不</b>包含已经删除的消息。该结果中的消息按消息ID大小从大到小排序。泛型的具体数据结构说明见：https://www.notion.so/kanyun/fb0697518fa147cbb0744b574eb9e184
 */
@GET
export class GetMessageBeforeId extends BaseCommonRequest<Message[]> {
    constructor(private orgId: string, private messageId?: MessageId) {
        super()
    }
    public override requestArgument() {
        return omitByValue({ orgId: this.orgId, messageId: this.messageId })
    }

    public override requestUrl() {
        return `wukong-message-center/query/before-id`
    }
}

/**
 * @description 标记用户最后一次查看消息中心面板的时间为当前时间
 */
@POST
export class PostMessageViewMark extends BaseCommonRequest<void> {
    constructor(private orgId: string) {
        super()
    }

    public override requestArgument() {
        return { orgId: this.orgId }
    }

    public override requestUrl() {
        return `wukong-message-center/view/mark`
    }
}

/**
 * @description 将一个用户的所有消息置为已读。备注：该接口会将所有记录在案的消息置为已读，即便某些消息还未展示在用户的页面上
 */
@DELETE
export class DeleteMessageReadAll extends BaseCommonRequest<void> {
    constructor(private orgId: string) {
        super()
    }

    public override requestArgument() {
        return { orgId: this.orgId }
    }

    public override requestUrl() {
        return `wukong-message-center/read/all`
    }
}

/**
 * @description 将一个用户的所有消息置为已读。备注：该接口会将所有记录在案的消息置为已读，即便某些消息还未展示在用户的页面上
 */
@DELETE
export class DeleteMessageReadSome extends BaseCommonRequest<void> {
    constructor(private orgId: string, private messageIds: MessageId[]) {
        super()
    }

    public override requestArgument() {
        return { orgId: this.orgId }
    }

    public override requestBody() {
        return [...this.messageIds]
    }

    public override requestUrl() {
        return `wukong-message-center/read/some`
    }
}
