import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    Present: '演示',
} as const

export const enTranslation = {
    Present: 'Present',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
