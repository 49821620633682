import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    SmartReplacement: '智能替换',
    ManualReplacement: '手动替换',
    Next: '下一个',
    Loading: '加载中',
    PageLayerDeleted: '页面中需要替换的图层已被删除',
    FileLayerDeleted: '文件中需要替换的图层已被删除',
    CantReplaceWithinInstances: '无法替换嵌套在实例内候选组件',
} as const

export const enTranslation = {
    SmartReplacement: 'Smart replacement',
    ManualReplacement: 'Manual replacement',
    Next: 'Next',
    Loading: 'Loading',
    PageLayerDeleted: 'The layer to be replaced on the page has been deleted.',
    FileLayerDeleted: 'The layer to be replaced in the file has been deleted.',
    CantReplaceWithinInstances: 'Unable to replace candidate components nested within instances.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
