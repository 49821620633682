/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { useCallback } from 'react'
import type { ColorStop, Paint, Transform } from '../../../../document/node/node'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import type { BlendColorProps } from './blend-color/blend-color'
import { BlendColor } from './blend-color/blend-color'
import { GradientPicker, GradientPickerProps } from './blend-gradient/gradient-picker'
import type { BlendImageProps } from './blend-image/blend-image'
import { BlendImage } from './blend-image/blend-image'
import { ColorSpace } from './color-picker/utils/color-translate'
import PaintType = Wukong.DocumentProto.PaintType

export enum OpenBlendFrom {
    SelectColor,
    Background,
    Effects,
}
export interface BlendContentProps
    extends Pick<BlendColorProps, 'onChangeColor' | 'onChangeOpacity'>,
        Pick<BlendImageProps, 'onChangeImagePaint' | 'enterClose'> {
    data: Paint
    colorSpace: ColorSpace
    onChangeColorStops?: GradientPickerProps['onChangeColorStops']
    onChangeTransform?: GradientPickerProps['onChangeTransform']
}
export function BlendContent(props: BlendContentProps) {
    const { data, colorSpace, onChangeColor, onChangeOpacity, onChangeImagePaint, enterClose } = props

    const onChangeColorStops = useCallback(
        (colorStops: ColorStop[], options?: InputOptionsForUndoSquash) => {
            props.onChangeColorStops?.(colorStops, options)
        },
        [props]
    )
    const onChangeTransform = useCallback(
        (transform: Transform) => {
            props.onChangeTransform?.(transform)
        },
        [props]
    )

    if (data.type === PaintType.PAINT_TYPE_EMOJI) {
        return null
    }

    return data.type !== undefined && data.type === PaintType.PAINT_TYPE_SOLID_PAINT ? (
        <BlendColor
            color={data.color}
            colorSpace={colorSpace}
            opacity={data.opacity ?? 1}
            onChangeColor={onChangeColor}
            onChangeOpacity={onChangeOpacity}
        />
    ) : data.type !== undefined && data.type === PaintType.PAINT_TYPE_IMAGE_PAINT ? (
        <BlendImage data={data} onChangeImagePaint={onChangeImagePaint} enterClose={enterClose} />
    ) : (
        <GradientPicker
            type={data.type}
            colorStops={data.gradientStops}
            transform={data.gradientTransform}
            onChangeColorStops={onChangeColorStops}
            onChangeTransform={onChangeTransform}
        />
    )
}
