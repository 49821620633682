import { useWindowSize } from 'react-use'
import { BlockUnit } from './block-unit'

const TeamItem = () => {
    return (
        <div
            className="h-156px border min-w-256px flex-1 box-border border-color-$wk-gray-2 rounded-4px p-6 flex
                flex-col justify-between"
        >
            <div className="flex items-center">
                <BlockUnit className="w-8 h-8 mr-2" />
                <BlockUnit className="w-68% h-6" />
            </div>
            <div className="flex items-center justify-between py-1">
                <BlockUnit className="h-4 w-18" />
                <BlockUnit className="h-4 w-12" />
            </div>
        </div>
    )
}

export const Teams = () => {
    const { width } = useWindowSize()
    return (
        <div className="px-8">
            <div className="flex items-center justify-between mt-13px mb-33px">
                <BlockUnit className="w-140px h-34px" />
                <BlockUnit className="w-92px h-32px" />
            </div>
            <BlockUnit className="w-full h-1px" />
            <div className="flex">
                <div className={'flex-1'}>
                    <div className="flex my-22px">
                        <BlockUnit className="w-19 h-4 mr-5" />
                        <BlockUnit className="w-19 h-4 mr-4" />
                    </div>
                    <div className="flex flex-wrap gap-8">
                        <TeamItem />
                        <TeamItem />
                        <TeamItem />
                        <TeamItem />
                        <TeamItem />
                        <TeamItem />
                        <TeamItem />
                        <TeamItem />
                        <TeamItem />
                        <TeamItem />
                        <TeamItem />
                        <TeamItem />
                        <div className="min-w-256px px-6 flex-1 box-border"></div>
                        <div className="min-w-256px px-6 flex-1 box-border"></div>
                        <div className="min-w-256px px-6 flex-1 box-border"></div>
                        <div className="min-w-256px px-6 flex-1 box-border"></div>
                        <div className="min-w-256px px-6 flex-1 box-border"></div>
                        <div className="min-w-256px px-6 flex-1 box-border"></div>
                        <div className="min-w-256px px-6 flex-1 box-border"></div>
                    </div>
                </div>
                {width > 856 && (
                    <div className="w-280px pr-6 pl-14 box-border">
                        <BlockUnit className="w-24 h-4 mt-5.5 mb-5" />
                        <div className="flex items-center mt-4">
                            <BlockUnit className="w-6 h-6 !rounded-full mr-2" />
                            <BlockUnit className="w-42px h-14px" />
                        </div>
                        <div className="flex items-center mt-4">
                            <BlockUnit className="w-6 h-6 !rounded-full mr-2" />
                            <BlockUnit className="w-7 h-14px" />
                        </div>
                        <div className="flex items-center mt-4">
                            <BlockUnit className="w-6 h-6 !rounded-full mr-2" />
                            <BlockUnit className="w-42px h-14px" />
                        </div>
                        <div className="flex items-center mt-4">
                            <BlockUnit className="w-6 h-6 !rounded-full mr-2" />
                            <BlockUnit className="w-7 h-14px" />
                        </div>
                        <div className="flex items-center mt-4">
                            <BlockUnit className="w-6 h-6 !rounded-full mr-2" />
                            <BlockUnit className="w-42px h-14px" />
                        </div>
                        <div className="flex items-center mt-4">
                            <BlockUnit className="w-6 h-6 !rounded-full mr-2" />
                            <BlockUnit className="w-7 h-14px" />
                        </div>
                        <div className="flex items-center mt-4">
                            <BlockUnit className="w-6 h-6 !rounded-full mr-2" />
                            <BlockUnit className="w-42px h-14px" />
                        </div>
                        <div className="flex items-center mt-4">
                            <BlockUnit className="w-6 h-6 mr-2 !rounded-full" />
                            <BlockUnit className="w-7 h-14px" />
                        </div>
                        <div className="flex items-center mt-4">
                            <BlockUnit className="w-4 h-4 mr-3 ml-1" />
                            <BlockUnit className="w-16 h-14px" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
