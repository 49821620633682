/* eslint-disable no-restricted-imports */
import {
    CharacterMetadata,
    ContentState,
    convertFromRaw,
    convertToRaw,
    DraftDecoratorType,
    EditorState,
    Modifier,
    RawDraftContentBlock,
    RawDraftContentState,
    RawDraftEntity,
    RawDraftEntityRange,
    SelectionState,
} from 'draft-js'
import { isNil } from 'lodash-es'
import { splitGrapheme } from '../../../../../../util/src'
import { CommentUser } from '../../../../kernel/interface/comment'
import { Message, MessageType } from '../type'
import { EmojiMap, EmojiShortNameMap } from './comment-emoji/emoji-map'
import type { EmojiData, MentionData } from './decorator'

interface SelectionStartEnd {
    start: number
    end: number
}

function createBlock(properties: Partial<RawDraftContentBlock>): RawDraftContentBlock {
    return Object.assign(
        {
            key: '0',
            type: 'default',
            text: '',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
        },
        properties
    )
}

export function createEditorStateWithMessages(
    messages: Message[],
    decorator: DraftDecoratorType,
    usersMap: Map<CommentUser['id'], CommentUser>
) {
    const rawState: RawDraftContentState = parseMessagesToRow(messages, usersMap)
    const contentState = convertFromRaw(rawState)
    return EditorState.createWithContent(contentState, decorator)
}

export function parseMessagesToRow(messages: Message[], usersMap: Map<CommentUser['id'], CommentUser>) {
    const blocks: Array<RawDraftContentBlock> = []
    const indexAndEntityMap: Map<number, RawDraftEntity> = new Map()

    let entityRanges: RawDraftEntityRange[] = []
    let blockText = ''
    for (const message of messages) {
        if (message.t === MessageType.Mention) {
            const user = usersMap.get(message.c)
            if (!user) {
                continue
            }
            const data: MentionData = { user }
            const userName = `@${user.nickname}`
            const connectedKey = indexAndEntityMap.size
            indexAndEntityMap.set(connectedKey, {
                type: `${MessageType.Mention}`,
                mutability: 'IMMUTABLE',
                data,
            })
            entityRanges.push({
                key: connectedKey,
                offset: Array.from(blockText).length,
                length: Array.from(userName).length,
            })
            blockText += userName
        } else if (message.t === MessageType.Emoji) {
            const data: EmojiData = { emojiName: message.c }
            const emoji = EmojiShortNameMap.get(message.c) ?? message.c
            const connectedKey = indexAndEntityMap.size
            indexAndEntityMap.set(connectedKey, {
                type: `${MessageType.Emoji}`,
                mutability: 'IMMUTABLE',
                data,
            })
            entityRanges.push({
                key: connectedKey,
                offset: Array.from(blockText).length,
                length: Array.from(emoji).length,
            })
            blockText += emoji
        } else if (message.t === MessageType.Text) {
            if (message.c.endsWith('\u000A')) {
                blockText += message.c.slice(0, -1)
                blocks.push(
                    createBlock({
                        text: blockText,
                        entityRanges,
                        key: blocks.length.toString(),
                    })
                )
                entityRanges = []
                blockText = ''
            } else {
                blockText += message.c
            }
        }
    }
    if (blockText) {
        blocks.push(
            createBlock({
                text: blockText,
                entityRanges,
                key: blocks.length.toString(),
            })
        )
    }

    const entityMap: RawDraftContentState['entityMap'] = Object.fromEntries(indexAndEntityMap.entries())
    return { blocks, entityMap }
}

/**
 * 文本中可能有输入法插入的表情，需要解析一下
 * @param messages
 * @param textArray // 这个数组是使用Array.from生成的（for of迭代生成也行）
 */
export function getEmojiInText(messages: Message[], textArray: string[]) {
    const textString = textArray.join('')
    if (textString.length === textArray.length) {
        messages.push({ t: MessageType.Text, c: textString })
    } else {
        // 迭代完整的 emoji
        const segments = splitGrapheme(textString)
        let preEmojiEndIndex = 0
        for (const { segment, index } of segments) {
            const emojiName = EmojiMap.get(segment)
            if (emojiName) {
                if (index > preEmojiEndIndex) {
                    messages.push({ t: MessageType.Text, c: textString.slice(preEmojiEndIndex, index) })
                }
                messages.push({ t: MessageType.Emoji, c: emojiName })
                preEmojiEndIndex = index + segment.length
            }
        }
        if (preEmojiEndIndex < textString.length) {
            messages.push({ t: MessageType.Text, c: textString.slice(preEmojiEndIndex, textString.length) })
        }
    }
}

export function getMessagesFromEditorState(editorState: EditorState) {
    const contentState = editorState.getCurrentContent()
    const rawDraftContentState = convertToRaw(contentState)
    const blocks = rawDraftContentState.blocks
    const entityMap = rawDraftContentState.entityMap
    const messages: Message[] = []

    for (const block of blocks) {
        if (messages.length > 0) {
            const message = messages[messages.length - 1]
            if (message.t === MessageType.Text) {
                message.c += '\u000A'
            } else {
                messages.push({ t: MessageType.Text, c: '\u000A' })
            }
        }
        const { text, entityRanges } = block
        // 这里的Array.from能正确的拆分 emoji
        const textArray = Array.from(text)
        let startIndex = 0
        let endIndex = 0
        for (const entityRange of entityRanges) {
            if (entityRange.offset > startIndex) {
                endIndex = entityRange.offset
                getEmojiInText(messages, textArray.slice(startIndex, endIndex))
            }
            const entity = entityMap[entityRange.key]
            if (entity.type === `${MessageType.Mention}`) {
                const data = entity.data as MentionData
                messages.push({ t: MessageType.Mention, c: data.user.id })
            } else if (entity.type === `${MessageType.Emoji}`) {
                const data = entity.data as EmojiData
                messages.push({ t: MessageType.Emoji, c: data.emojiName })
            }
            startIndex = entityRange.offset + entityRange.length
        }
        if (textArray.length > startIndex || textArray.length === 0) {
            getEmojiInText(messages, textArray.slice(startIndex, textArray.length))
        }
    }
    return messages
}

export function getEditorStateWithSelectAll(editorState: EditorState) {
    const selection = editorState.getSelection()
    const currentContent = editorState.getCurrentContent()
    const selectionWithAll = selection.merge({
        anchorKey: currentContent.getFirstBlock().getKey(),
        anchorOffset: 0,
        focusKey: currentContent.getLastBlock().getKey(),
        focusOffset: currentContent.getLastBlock().getText().length,
    })
    return EditorState.forceSelection(editorState, selectionWithAll)
}

export function getSearchInfoFromEditorState(editorState: EditorState) {
    // 获取块内插入符前的字符
    const selection = editorState.getSelection()
    const currentContent = editorState.getCurrentContent()
    const anchorKey = selection.getAnchorKey()
    const block = currentContent.getBlockForKey(anchorKey)
    const anchorOffset = selection.getAnchorOffset()
    let startIndex = 0
    block.findEntityRanges(
        (character) => {
            const entity = character.getEntity()
            return !!entity
        },
        (start: number, end: number) => {
            if (end <= anchorOffset) {
                startIndex = end
            }
        }
    )
    const targetStr = block.getText().substring(startIndex, anchorOffset)
    // 获取 @ 后的字符
    const res = targetStr.match(/@([^@]*)$/)
    return res ? { matchString: res[0], searchString: res[1], index: res.index! + startIndex } : undefined
}

export function insertMentionUserToEditorState(editorState: EditorState, user: CommentUser, isFastInsert: boolean) {
    const data: MentionData = { user }
    const mentionEntityKey = editorState
        .getCurrentContent()
        .createEntity(`${MessageType.Mention}`, 'IMMUTABLE', data)
        .getLastCreatedEntityKey()
    const replaceText = `@${user.nickname}`
    const search = isFastInsert ? undefined : getSearchInfoFromEditorState(editorState)
    const startEnd = search ? { start: search.index, end: search.index + search.matchString.length } : undefined
    return replaceSelectionContent(editorState, replaceText, startEnd, mentionEntityKey)
}

export function insertEmojiToEditorState(editorState: EditorState, shortName: string) {
    const data: EmojiData = { emojiName: shortName }
    const emojiEntityKey = editorState
        .getCurrentContent()
        .createEntity(`${MessageType.Emoji}`, 'IMMUTABLE', data)
        .getLastCreatedEntityKey()
    const replaceText = EmojiShortNameMap.get(shortName) ?? shortName
    return replaceSelectionContent(editorState, replaceText, undefined, emojiEntityKey)
}

export function replaceSelectionContent(
    editorState: EditorState,
    replaceText: string,
    startEnd?: SelectionStartEnd,
    entityKey?: string
) {
    const selection = editorState.getSelection()
    const currentContent = editorState.getCurrentContent()
    // 如果指定选取，则设置
    const handlerSelection = startEnd
        ? selection.merge({
              anchorOffset: startEnd.start,
              focusOffset: startEnd.end,
          })
        : selection
    // 把选选区内容替换成给定的值
    let nextContent = Modifier.replaceText(currentContent, handlerSelection, replaceText, undefined, entityKey)
    // 末尾添加空格
    const selectionAfter = nextContent.getSelectionAfter()
    const lastBlock = nextContent.getLastBlock()
    const insertAtContentLast =
        lastBlock.getKey() === selectionAfter.getStartKey() &&
        lastBlock.getKey() === selectionAfter.getEndKey() &&
        lastBlock.getLength() === selectionAfter.getStartOffset() &&
        lastBlock.getLength() === selectionAfter.getEndOffset()
    if (insertAtContentLast) {
        nextContent = Modifier.replaceText(nextContent, selectionAfter, ' ')
    }

    editorState = EditorState.push(editorState, nextContent, 'insert-fragment')

    return EditorState.forceSelection(editorState, nextContent.getSelectionAfter())
}

export function isOverMaxCharSize(maxCharSize: number, editorState: EditorState, willInsetString?: string): boolean {
    const currentLength = editorState.getCurrentContent().getPlainText('').length
    const nextLength = currentLength + (willInsetString ?? '')?.length
    return nextLength > maxCharSize
}

export function filterFnEntity(entityType: string, contentState: ContentState) {
    return (character: CharacterMetadata) => {
        const entityKey = character.getEntity()
        return !isNil(entityKey) && contentState.getEntity(entityKey).getType() === entityType
    }
}

export function moveCursorToLeft(editorState: EditorState) {
    let selection = editorState.getSelection()
    const currentContent = editorState.getCurrentContent()
    const startKey = selection.getStartKey()
    const block = editorState.getCurrentContent().getBlockForKey(startKey)
    const startOffset = selection.getStartOffset()
    let nextPosition: number | undefined
    let nextAnchorKey = startKey
    block.findEntityRanges(filterFnEntity(`${MessageType.Mention}`, currentContent), (start: number, end: number) => {
        if (start < startOffset && end >= startOffset) {
            nextPosition = start
        }
    })
    if (nextPosition === undefined) {
        nextPosition = startOffset - 1
    }
    const needSwitchBlock = nextPosition < 0
    const keyBefore = currentContent.getKeyBefore(startKey)
    if (needSwitchBlock && keyBefore) {
        const blockBefore = editorState.getCurrentContent().getBlockForKey(keyBefore)
        selection = SelectionState.createEmpty(keyBefore)
        nextPosition = blockBefore.getLength()
        nextAnchorKey = keyBefore
    }
    const nextSelection = selection.merge({
        anchorKey: nextAnchorKey,
        anchorOffset: nextPosition,
        focusOffset: nextPosition,
    })
    return EditorState.forceSelection(editorState, nextSelection)
}
export function moveCursorToRight(editorState: EditorState) {
    let selection = editorState.getSelection()
    const currentContent = editorState.getCurrentContent()
    const endKey = selection.getEndKey()
    const block = editorState.getCurrentContent().getBlockForKey(endKey)
    const endOffset = selection.getEndOffset()
    let nextPosition: number | undefined
    let nextAnchorKey = endKey
    block.findEntityRanges(filterFnEntity(`${MessageType.Mention}`, currentContent), (start: number, end: number) => {
        if (start <= endOffset && end > endOffset) {
            nextPosition = end
        }
    })
    if (nextPosition === undefined) {
        nextPosition = endOffset + 1
    }
    const needSwitchBlock = nextPosition > block.getLength()
    const keyAfter = currentContent.getKeyAfter(endKey)
    if (needSwitchBlock && keyAfter) {
        selection = SelectionState.createEmpty(keyAfter)
        nextPosition = 0
        nextAnchorKey = keyAfter
    }
    const nextSelection = selection.merge({
        anchorKey: nextAnchorKey,
        anchorOffset: nextPosition,
        focusOffset: nextPosition,
    })
    return EditorState.forceSelection(editorState, nextSelection)
}

export function isPressedDecorativeKey(e: Pick<KeyboardEvent, 'ctrlKey' | 'metaKey' | 'altKey' | 'shiftKey'>) {
    return e.ctrlKey || e.metaKey || e.altKey || e.shiftKey
}
