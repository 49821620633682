import { deleteDB } from 'idb'
import { IndexedDBName, IndexedDBName2CanRemove } from '../config'
import { IndexedDBConfigName } from '../types'
import { WkCLog } from '../../../kernel/clog/wukong/instance'
import { featureSwitchManager } from '../../../kernel/switch'
import { showQuotaExccededModal } from '../modal'
import { QuotaExccededModalType } from '../modal/types'
import { IN_JEST_TEST } from '../../../environment'
import { sleep } from '../../../../../util/src/time'

/**
 * 支持动态 DB 命名，前缀相同的 DB 使用相同的 Schema 定义
 * @param name
 * @returns
 */
export const combineIndexedDBName = <T extends IndexedDBName>(name: IndexedDBConfigName<T>): T => {
    if (typeof name === 'string') {
        return name
    }

    // 在类型上做 trick 处理，不然无法枚举动态名称 DB 的类型
    return (name.name + name.suffix) as unknown as T
}

/**
 * 静态化可删除的 DB 列表
 */
const canRemoveDBNames = Object.entries(IndexedDBName2CanRemove)
    .filter(([_, value]) => value)
    .map(([name]) => name)

/**
 * 尝试删除合适的 DB
 */
export const tryDeleteSuitableRemoveDB = async (): Promise<void> => {
    try {
        const dbNames = await getAllDatabaseNames()

        if (!dbNames.length) {
            return
        }

        // 尝试删除的 db 数量
        const TRY_DELETE_DB_COUNT = 3

        const shouldRemoveDBNames = dbNames
            .filter((name) =>
                canRemoveDBNames.some((prefix) => name.startsWith(prefix) && !name.includes(String(window.clientId)))
            )
            .slice(0, TRY_DELETE_DB_COUNT)

        if (shouldRemoveDBNames.length) {
            // 可能在 deleteDB 过程中遇到正在写入的 DB 导致卡住，这里设置一个超时时间
            await Promise.all(shouldRemoveDBNames.map((name) => Promise.race([deleteDB(name), sleep(2000)])))
        }
    } catch (err) {
        console.error('try delete low level DB failed', err)
    }
}

/**
 * 获取用户的 quota 信息，在 chromium 下可以获取到
 */
export const getQuota = async (): Promise<StorageEstimate> => {
    try {
        const quota = await navigator.storage?.estimate()
        return quota
    } catch {
        return {
            quota: 0,
            usage: 0,
        }
    }
}

/**
 * 统一处理 IndexedDB 各种问题
 */
export const handleIndexedDBError = async (error: string): Promise<void> => {
    if (IN_JEST_TEST) {
        return
    }

    const { usage, quota } = await getQuota()

    // 发生了内存超限
    if ((quota && usage && usage >= quota) || /[qQ]uota/.test(error)) {
        // 尝试删除低优先级的 DB
        await tryDeleteSuitableRemoveDB()
        // 开启弹窗推送
        showQuotaExccededModal(navigator.onLine ? QuotaExccededModalType.Normal : QuotaExccededModalType.Offline)
    }

    WkCLog.log(`${error}, current quota: ${quota}, current usage: ${usage}`)
}

/**
 * 增强 idb 的 setter
 * - 在报错时删除低优先的数据存储
 * - 提供 metric 能力
 * @param fn
 */
export const enhanceIDBSet = async <T>(fn: () => Promise<T>, ...args: any[]): Promise<T | undefined> => {
    try {
        return await fn()
    } catch (err) {
        await handleIndexedDBError(
            `IndexedDB set item failed: arguments: ${args
                .map((arg) => (typeof arg === 'object' ? JSON.stringify(arg) : arg))
                .join(',')}, error: ${err}`
        )
    }
}
/**
 * 获取所有的 db name
 * 并非所有浏览器都支持该 api，当不支持的时候降级为空数组
 */

export const getAllDatabaseNames = async (): Promise<string[]> => {
    if (!indexedDB.databases) {
        return Promise.resolve([])
    }

    const dbs = await indexedDB.databases()

    return dbs.map(({ name }) => name).filter((name) => !!name) as string[]
}
