/* eslint-disable no-restricted-imports */
import { Dispatch, FC, MouseEventHandler, SetStateAction, useRef, useState } from 'react'
import useEvent, { ListenerType1 } from 'react-use/lib/useEvent'
import { useAppContext } from '../../../../../../../main/app-context'
import styles from './index.module.less'

interface PageResizeLineProps {
    setCustomHeight: Dispatch<SetStateAction<number>>
    setCustomHeightFlag: Dispatch<SetStateAction<boolean>>
    height: number
    pagePanelRef: React.RefObject<HTMLDivElement>
}

export const PageResizeLine: FC<PageResizeLineProps> = ({
    setCustomHeight,
    height,
    setCustomHeightFlag,
    pagePanelRef,
}) => {
    const startRef = useRef<boolean>(false)

    const [resizeLineHeight, setResizeLineHeight] = useState<number>(10)
    const [resizeLintTop, setResiezeLineTop] = useState<number>(-10)

    const userConfigService = useAppContext().userConfigService

    const handleMouseDown = () => {
        setCustomHeight(height)
        const offsetHeight = pagePanelRef.current?.offsetHeight
        userConfigService.updateConfig2('pagePanelHeight', offsetHeight ?? height)
        setCustomHeightFlag(true)
        setResizeLineHeight(200)
        setResiezeLineTop(-100)
        startRef.current = true
    }

    const handleMouseMove: MouseEventHandler<HTMLDivElement> = (e) => {
        if (startRef.current) {
            setCustomHeight((curHeight) => {
                const newHeight = curHeight + e.movementY
                userConfigService.updateConfig2('pagePanelHeight', newHeight)
                return newHeight
            })
        }
    }

    const handleMouseUp = () => {
        if (startRef.current) {
            startRef.current = false
            setResizeLineHeight(10)
            setResiezeLineTop(-10)
        }
    }

    const handleDbClick = () => {
        setCustomHeightFlag(false)
    }

    useEvent<ListenerType1>('mouseup', handleMouseUp)

    useEvent<ListenerType1>('mousemove', handleMouseMove)

    return (
        <div className={styles.pageResizeLineContainer} onMouseDown={handleMouseDown} onDoubleClick={handleDbClick}>
            <div
                className={styles.pageResizeLine}
                data-testid="page-resize-line-height"
                style={{ height: resizeLineHeight + 'px', top: resizeLintTop + 'px' }}
            ></div>
        </div>
    )
}
