import { command } from 'ccstate'
import { isMobile } from 'react-device-detect'
import { LoaderFunctionArgs } from 'react-router'
import { domLocation } from '../../../../util/src'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { replaceLocation$ } from '../../../../util/src/ccstate-helper/location'
import { traceable } from '../../../../util/src/ccstate-helper/traceable'
import { parseUrl } from '../../../../util/src/parse-url'
import { RouteToken } from '../../../../util/src/routes'
import { isEditorSupport } from '../../kernel/util/ua'
import { UserVOV2, UserVOV2Status } from '../../kernel/interface/account'
import { shouldJumpToAIPlayGround } from '../../ui/component/ai/ai-gen-ui/util'

export const trySSORedirect$ = traceable(
    'hulh01@kanyun.com',
    command((_, args: LoaderFunctionArgs) => {
        const { pathname } = parseUrl(args.request.url)

        // 移动端 已经登录了就进落地页。因为这个守卫是mirror和沙盒后的，所以不会导致进不了移动端的mirror
        // 单点登录（SSOConsume）pathname 放行
        const isMobileJumps2LandingPage =
            isMobile && [RouteToken.SSOConsume].map((v) => new RegExp(`/${v}|/${v}/`)).every((v) => !pathname.match(v))

        if (isMobileJumps2LandingPage) {
            domLocation().replace('/landing?type=reset')
        }
    })
)

export const tryRedirectToInitial$ = traceable(
    'hulh01@kanyun.com',
    command(async ({ set }, signal: TraceableAbortSignal, args: LoaderFunctionArgs, userInfo: UserVOV2) => {
        if (!isEditorSupport()) {
            return
        }

        // NOTE: AI GEN landing page需求, 如果用户输入的链接带着 promptId, 就跳过onboarding, 但是退出这个文件就还需要onboarding
        const skipInit = shouldJumpToAIPlayGround()

        if (skipInit) {
            return
        }

        const { pathname } = parseUrl(args.request.url)

        if (
            userInfo.userId &&
            userInfo.status === UserVOV2Status.INIT &&
            !pathname.includes(`/${RouteToken.AppAuth}`)
        ) {
            await set(replaceLocation$, signal, domLocation().origin + '/initial')
        }
    })
)
