/* eslint-disable no-restricted-imports */
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { PubMirrorNotifyEvent } from '../../../kernel/request/notify'
import { useViewState } from '../../../view-state-bridge'

/**
 * 监听预览Frame变化并发送到服务端
 */
export function PubMirrorFrame() {
    const { docId } = useParams()
    const mirrorFrame = useViewState('mirrorFrameViewState')

    useEffect(() => {
        if (docId && mirrorFrame?.frameId) {
            new PubMirrorNotifyEvent(docId, mirrorFrame.frameId, mirrorFrame.frameName).start()
        }
    }, [docId, mirrorFrame])

    return <></>
}
