import { POST } from '@tutor/network-core'
import { BaseCommonRequest } from './base-request'

// 用户反馈
@POST
export class Feedbacks extends BaseCommonRequest<void> {
    constructor(private message: string) {
        super()
    }

    public override requestBody() {
        return { content: this.message }
    }

    public override requestUrl() {
        return `feedbacks`
    }
}
