import { FormatedRemotePrimitiveVariable } from '../common'
import { FloatVariableItem } from './float-variable-item'

export interface RemoteFloatVariableItemProps {
    data: FormatedRemotePrimitiveVariable
    hideVariableValue?: boolean
    isSelected: boolean
    isPreselect: boolean
    onClick: () => void
    onMouseEnter: () => void
    onMouseLeave: () => void
}

export const RemoteFloatVariableItem = ({
    data,
    hideVariableValue,
    isSelected,
    isPreselect,
    onClick,
    onMouseEnter,
    onMouseLeave,
}: RemoteFloatVariableItemProps) => {
    return (
        <FloatVariableItem
            name={data.name}
            value={data.variable.data.floatValue}
            hideVariableValue={hideVariableValue}
            isSelected={isSelected}
            isPreselect={isPreselect}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            dataTestId={{
                container: 'remote-float-variable-item',
                name: 'remote-float-variable-item-name',
                value: 'remote-float-variable-item-value',
            }}
        />
    )
}
