/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { createSelectors, createStore } from '../../../../util/src'
import { CommandInvoker } from '../../document/command/command-invoker'
import { cmdZoomAtCameraCenter } from '../../document/command/viewport-command'
import { ViewStateBridge } from '../../view-state-bridge'

interface ViewMenuZustandStore {
    currentZoom: string // 当前 zoom 值展示的字符串
    inputValue: string // 输入框的输入值
    menuOpened: boolean // 是否展示菜单
}

export class ViewMenuService {
    private inputElement: HTMLInputElement | null = null // 输入框的 DOM 元素
    private inputDirty = false // 输入框的值是否被修改过

    private _zustandStore = createStore<ViewMenuZustandStore>(() => ({
        currentZoom: '',
        inputValue: '',
        menuOpened: false,
    }))
    useZustandStore = createSelectors(this._zustandStore)

    constructor(protected readonly viewStateBridge: ViewStateBridge, private readonly commandInvoker: CommandInvoker) {}

    destroy() {
        this.inputDirty = false
        this.inputElement = null
    }

    init = () => {
        this.viewStateBridge.register('currentViewport', this.updateCurrentZoom)
        return () => this.viewStateBridge.unregister('currentViewport', this.updateCurrentZoom)
    }

    private updateInputValue = (value: string) => {
        this._zustandStore.setState({
            inputValue: value,
        })
    }

    private syncCurrentZoomToInput = () => {
        this.updateInputValue(this._zustandStore.getState().currentZoom)
    }

    private updateCurrentZoom = (viewport: Wukong.DocumentProto.IViewport) => {
        const zoom = Math.round((viewport.zoom ?? 1) * 100) + '%'
        this._zustandStore.setState({
            currentZoom: zoom,
        })
        if (this._zustandStore.getState().menuOpened) {
            this.syncCurrentZoomToInput()
        }
    }

    trySubmitZoom = () => {
        if (this.inputDirty) {
            const numericValue = Number.parseInt(this._zustandStore.getState().inputValue)
            if (numericValue) {
                this.commandInvoker.invoke(cmdZoomAtCameraCenter, numericValue / 100)
            } else {
                this.updateInputValue(this._zustandStore.getState().currentZoom)
            }
        }
        this.resetInputStatus()
    }

    private resetInputStatus() {
        this.inputDirty = false
        this.inputElement?.blur()
    }

    onOpen = () => {
        this._zustandStore.setState({
            menuOpened: true,
        })
        this.syncCurrentZoomToInput()
        this.autoFocusOnInputElement()
    }

    onClose = () => {
        this.trySubmitZoom()
        this._zustandStore.setState({
            inputValue: '',
            menuOpened: false,
        })
    }

    onEnterKeyDown = () => {
        this.trySubmitZoom()
    }

    onInputChange = (value: string) => {
        this.updateInputValue(value)
        this.inputDirty = true
    }

    private autoFocusOnInputElement = () => {
        this.inputElement?.focus()
        this.inputElement?.select()
    }

    onMounted = (inputElement: HTMLInputElement | null) => {
        this.inputElement = inputElement
        if (this._zustandStore.getState().menuOpened) {
            this.autoFocusOnInputElement()
        }
    }
}
