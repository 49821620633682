import { Wukong } from '@wukong/bridge-proto'
import constate from 'constate'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppContext } from '../../../../main/app-context'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { RGBA } from '../../design/blend/color-picker/utils/color-translate'
import { useSelectedCollection, useSelectedVariables } from '../use-view-state-hook'
import {
    getVariablePublishHiddenValue,
    getVariableScopeCheckboxValues,
    SelectedVariablesEditorActiveKey,
    updateVariableScopes,
    VariableCodeSyntaxKey,
    VariableCodeSyntaxKey2Platform,
    VariableScopeCheckboxKey,
} from './utils'

export function _useSelectedVariablesEditor() {
    const service = useAppContext().variableService.localVariableEditorService
    const variables = useSelectedVariables()
    const collection = useSelectedCollection()
    const [activeTabKey, setActiveTabKey] = useState<SelectedVariablesEditorActiveKey>(
        SelectedVariablesEditorActiveKey.DETAIL
    )
    const [selectedModeId, setSelectedModeId] = useState<string>('')
    const publishHiddenValue = useMemo(() => getVariablePublishHiddenValue(variables), [variables])
    const scopeCheckboxValues = useMemo(() => getVariableScopeCheckboxValues(variables), [variables])

    const closeEditor = useCallback(() => {
        service.endEditVariable()
    }, [service])

    const onChangeName = (variable: Wukong.DocumentProto.ILocalVariable, name: string) => {
        service.editorChangeName(variable, name.trim())
    }

    const onChangeDescription = (variable: Wukong.DocumentProto.ILocalVariable, description: string) => {
        service.editorChangeDescription(variable, description.trim())
    }

    const onChangeColor = (variableId: string, modeId: string) => (val: RGBA, options?: InputOptionsForUndoSquash) => {
        service.updateColorVariableValue(variableId, modeId, val, options?.commitType)
    }

    const onChangeColorVar =
        (variableId: string, modeId: string) => (value: Wukong.DocumentProto.IVariableAliasData) => {
            service.setAliasForVariable(variableId, modeId, value.value.alias!)
        }

    const onAddCodeSyntax = (variableId: string, variableName: string) => (key: VariableCodeSyntaxKey) => {
        service.editorChangeCodeSyntax(variableId, VariableCodeSyntaxKey2Platform[key], variableName)
    }

    const onChangeCodeSyntax = (variableId: string) => (key: VariableCodeSyntaxKey, value: string) => {
        service.editorChangeCodeSyntax(variableId, VariableCodeSyntaxKey2Platform[key], value.trim())
    }

    const onRemoveCodeSyntax = (variableId: string) => (key: VariableCodeSyntaxKey) => {
        service.editorRemoveCodeSyntax(variableId, VariableCodeSyntaxKey2Platform[key])
    }

    const onChangePublishHidden = (checked: boolean) => {
        const params = variables.map((variable) => {
            return {
                variableId: variable.id,
                publishHidden: checked,
            }
        })
        service.editorChangePublishHidden(params)
    }

    const onChangeScopes = (key: VariableScopeCheckboxKey, checked: boolean) => {
        const scopesList = variables.map((variable) => {
            return {
                variableId: variable.id,
                scopes: updateVariableScopes(variable.scopes, key, checked),
            }
        })
        service.editorChangeScopes(scopesList)
    }

    const onChangeActiveTabKey = (key: SelectedVariablesEditorActiveKey) => {
        setSelectedModeId('')
        setActiveTabKey(key)
    }

    const onChangeSelectedModeId = (modeId: string) => {
        if (modeId === selectedModeId) {
            setSelectedModeId('')
        } else {
            setSelectedModeId(modeId)
        }
    }

    useEffect(() => {
        const length = variables.length
        if (length) {
            setActiveTabKey(
                length > 1 ? SelectedVariablesEditorActiveKey.SCOPE : SelectedVariablesEditorActiveKey.DETAIL
            )
        } else if (!length) {
            closeEditor()
        }
    }, [variables.length, closeEditor])

    return {
        variables,
        collection,
        publishHiddenValue,
        scopeCheckboxValues,
        activeTabKey,
        selectedModeId,
        closeEditor,
        onChangeName,
        onChangeDescription,
        onChangeColor,
        onChangeColorVar,
        onAddCodeSyntax,
        onChangeCodeSyntax,
        onRemoveCodeSyntax,
        onChangePublishHidden,
        onChangeScopes,
        onChangeActiveTabKey,
        onChangeSelectedModeId,
    }
}

export const [SelectedVariablesEditorContext, useSelectedVariablesEditorContext] = constate(_useSelectedVariablesEditor)
