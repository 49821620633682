import { DocID, ResourceType, SeatType, TriggerSeatApplicationSceneType } from '../../kernel/interface/type'
import { featureSwitchManager } from '../../kernel/switch'
import { UserRole, UserSeatStatus } from '../../utils/payment'
import { PayApplyDialog } from './pay-apply-dialog/pay-apply-dialog'
import {
    PayApplyDialogSeatType,
    PayApplyDialogType,
    PayApplyDialogUserType,
} from './pay-apply-dialog/pay-apply-dialog-struct/type'
import { translation } from './pay-seat-apply-dev-mode.translation'
import { getAdminUpgradeSelfDescription } from './util'

export function PayApplySeatOfDevMode(props: {
    userRole: UserRole
    userSeatStatus: UserSeatStatus
    resourceType: ResourceType
    resourceId: string
    unresolvedSeatTypes: SeatType[]
    docId: DocID
    userId: number
    isEnterprise: boolean
    orgId: string
    isSomeWorkspaceAdmin: boolean
}) {
    const {
        userRole,
        userSeatStatus,
        resourceId,
        resourceType,
        unresolvedSeatTypes,
        docId,
        userId,
        isEnterprise,
        orgId,
        isSomeWorkspaceAdmin,
    } = props

    const httpProps = {
        resourceType: resourceType,
        resourceId: resourceId,
        triggerScene: {
            scene: TriggerSeatApplicationSceneType.UserDocDevMode,
            docId: docId,
            userId: userId,
        },
        orgId: orgId,
    }

    if (userRole == UserRole.Admin || (featureSwitchManager.isEnabled('organization-plus') && isSomeWorkspaceAdmin)) {
        PayApplyDialog.show({
            type: PayApplyDialogType.Admin,
            seatType: PayApplyDialogSeatType.Dev,
            dialogProps: {
                title: translation('DialogTitleOfAdminOrAutoGrade'),
                okText: translation('DialogOkTextOfAdminOrAutoGrade'),
                cancelText: translation('DialogCancelTextOfAdminOrAutoGrade'),
            },
            httpProps: httpProps,
            description: getAdminUpgradeSelfDescription('dev'),
        })
        return
    }

    const userInfo = {
        type: userRole == UserRole.Guest ? PayApplyDialogUserType.External : PayApplyDialogUserType.Normal,
    }

    switch (userSeatStatus) {
        case UserSeatStatus.CanApplyAutoUpgrade: {
            PayApplyDialog.show({
                type: PayApplyDialogType.AutoUpgrade,
                seatType: PayApplyDialogSeatType.Dev,
                dialogProps: {
                    title: translation('DialogTitleOfAdminOrAutoGrade'),
                    okText: translation('DialogOkTextOfAdminOrAutoGrade'),
                    cancelText: translation('DialogCancelTextOfAdminOrAutoGrade'),
                },
                httpProps: httpProps,
                description: undefined,
            })
            break
        }
        case UserSeatStatus.CanApplyGracePeriod:
        case UserSeatStatus.NeedWaitToBeApproved:
        case UserSeatStatus.NeedReApply: {
            PayApplyDialog.show({
                type: PayApplyDialogType.Normal,
                isAllowGracePeriod: userSeatStatus == UserSeatStatus.CanApplyGracePeriod,
                isEnterprise: isEnterprise,
                userInfo: userInfo,
                seatType: PayApplyDialogSeatType.Dev,
                httpProps: httpProps,
                descriptionPrefix: translation('DialogDescriptionPrefix'),
                unresolvedSeatTypes: unresolvedSeatTypes,
                repeatDialogProps: {
                    dialogTitle: '',
                },
            })
            break
        }
        case UserSeatStatus.InGracePeriod:
        case UserSeatStatus.InAutoUpgrade:
        case UserSeatStatus.InPermanentSeat:
            return
    }
}
