import { splitGrapheme } from './grapheme-split'

const EmojiRanges = [
    { start: 0x200d, end: 0x200d },
    { start: 0x203c, end: 0x203c },
    { start: 0x2049, end: 0x25fe },
    { start: 0x2600, end: 0x2b55 },
    { start: 0x3030, end: 0x3299 },
    { start: 0xfe0f, end: 0xfe0f },
    { start: 0x1f004, end: 0x1f004 },
    { start: 0x1f0cf, end: 0x1f0cf },
    { start: 0x1f170, end: 0x1f17f },
    { start: 0x1f18e, end: 0x1f18e },
    { start: 0x1f191, end: 0x1f19a },
    { start: 0x1f1e6, end: 0x1f1ff },
    { start: 0x1f201, end: 0x1f251 },
    { start: 0x1f300, end: 0x1f64f },
    { start: 0x1f680, end: 0x1faff },
    { start: 0xe0062, end: 0xe007f },
]

export function toCodePoints(str: string): number[] {
    const codePoints = [...str].map((v) => v.codePointAt(0) as number)
    return codePoints
}

export function isEmoji(str: string): boolean {
    const codePoints = toCodePoints(str)
    return EmojiRanges.some((range) => {
        return codePoints.some((cp) => range.start <= cp && cp <= range.end)
    })
}

export function getEmojiCodePointsSet(str: string): number[][] {
    return [...new Set(splitGrapheme(str).map((v) => v.segment))].filter(isEmoji).map(toCodePoints)
}
