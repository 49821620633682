/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { useCallback } from 'react'
import { RGB, SolidPaint } from '../../../../document/node/node'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { Value } from '../../atom/inputs/utils/type'
import { GridLayoutContent, GridLayoutContentProps } from './layout-grid-content/grid-layout-grid-content'
import { RowColLayoutContent, RowsLayoutContentProps } from './layout-grid-content/row-col-layout-grid-content'

export interface LayoutContentProps
    extends Omit<
            GridLayoutContentProps,
            'onChangeSolidPaint' | 'onChangeColor' | 'onChangeOpacityValue' | 'onChangeOpacityNumber'
        >,
        Omit<
            RowsLayoutContentProps,
            'onChangeSolidPaint' | 'onChangeColor' | 'onChangeOpacityValue' | 'onChangeOpacityNumber'
        > {
    onChangeSolidPaint: (value: SolidPaint, options?: InputOptionsForUndoSquash) => void
}

export function LayoutContent(prop: LayoutContentProps) {
    const {
        layoutGrid,
        variables,
        colorSpace,
        onChangeSectionSize,
        onChangeSectionSizeVar,
        onChangeAlign,
        onChangeCount,
        onChangeCountVar,
        onChangeOffset,
        onChangeOffsetVar,
        onChangeGutterSize,
        onChangeGutterSizeVar,
        onChangeSolidPaint,
    } = prop

    const _onChangeSolidPaint = useCallback(
        <T extends keyof SolidPaint, V = SolidPaint[T]>(key: T, value: V, options?: InputOptionsForUndoSquash) => {
            onChangeSolidPaint({ ...(layoutGrid.paints as SolidPaint), [key]: value, visible: true }, options)
        },
        [layoutGrid.paints, onChangeSolidPaint]
    )

    const _onChangeColor = useCallback(
        (value: RGB, options?: InputOptionsForUndoSquash) => {
            _onChangeSolidPaint('color', value, options)
        },
        [_onChangeSolidPaint]
    )

    const onChangeOpacityValue = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            _onChangeSolidPaint('opacity', value, options)
        },
        [_onChangeSolidPaint]
    )

    const onChangeOpacityNumber = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value === 'number') {
                _onChangeSolidPaint('opacity', value / 100, options)
            }
        },
        [_onChangeSolidPaint]
    )

    if (layoutGrid.pattern === Wukong.DocumentProto.LayoutGridPattern.LAYOUT_GRID_PATTERN_GRID) {
        return (
            <GridLayoutContent
                layoutGrid={layoutGrid}
                variables={variables}
                colorSpace={colorSpace}
                onChangeSectionSize={onChangeSectionSize}
                onChangeSectionSizeVar={onChangeSectionSizeVar}
                onChangeColor={_onChangeColor}
                onChangeOpacityNumber={onChangeOpacityNumber}
                onChangeOpacityValue={onChangeOpacityValue}
            />
        )
    } else {
        return (
            <RowColLayoutContent
                layoutGrid={layoutGrid}
                variables={variables}
                colorSpace={colorSpace}
                onChangeAlign={onChangeAlign}
                onChangeCount={onChangeCount}
                onChangeCountVar={onChangeCountVar}
                onChangeGutterSize={onChangeGutterSize}
                onChangeGutterSizeVar={onChangeGutterSizeVar}
                onChangeSectionSize={onChangeSectionSize}
                onChangeSectionSizeVar={onChangeSectionSizeVar}
                onChangeOffset={onChangeOffset}
                onChangeOffsetVar={onChangeOffsetVar}
                onChangeColor={_onChangeColor}
                onChangeOpacityNumber={onChangeOpacityNumber}
                onChangeOpacityValue={onChangeOpacityValue}
            />
        )
    }
}
