/* eslint-disable no-restricted-imports */
import classNames from 'classnames'
import { forwardRef, HTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CommitType } from '../../../../../document/command/commit-type'
import { featureSwitchManager } from '../../../../../kernel/switch'
import { isSafari } from '../../../../../kernel/util/ua'
import { useCommand } from '../../../../context/document-context'
import { getMultipleByDeltaY, Multiple, ScrubCursor, ScrubCursorProps } from '../scrub-cursor/scrub-cursor'
import { clampMinMax } from '../utils/clamp'
import { Distance, ScrubInfo, Value } from '../utils/type'
import { UserConfig, useUserConfig } from '../utils/user-config'
import { valueFilter } from '../utils/value-filter'
import { InputOptionsForUndoSquash } from './formatted-input'
import classes from './scrubbable-control.module.less'

function createInitScrubInfo(): ScrubInfo {
    return {
        initPosition: { x: -100, y: -100 },
        absoluteDistance: { x: 0, y: 0 },
        distance: { x: 0, y: 0 },
        shiftKey: false,
    }
}

function getPreScrubValue(value: Value) {
    if (typeof value == 'number' || Array.isArray(value)) {
        return value
    } else {
        return 0
    }
}
export interface ScrubbableControlCustomProps {
    scrubbingDisabled?: boolean // 禁止拖拽功能和对应样式。效果就仿佛这个组件不存在拖拽功能一样
    disabled?: boolean
    value: Value
    isMixed?: boolean
    min?: number
    max?: number
    resolution?: number
    labelTestId?: string
    mixedMathHandler?: {
        getScrubStartValueMixed: () => Map<string, Value>
        onScrubChangeAllMixed?: (map: Map<string, Value>) => void
        /**
         * @deprecated: 请使用 onScrubChangeAllMixed
         */
        onScrubChangeMixed?: (key: string, value: Value) => void
    }
    contentClassName?: string
    focusWithoutBorder?: boolean
    uiFocusWithin?: boolean // 是否需要伪聚焦
    onValueChange: (value: Value, options: InputOptionsForUndoSquash) => void
    valueFilter?: (scrubInfo: ScrubInfo, value: number, userConfig: UserConfig) => number
    // 下面的回调基本不用
    onScrubBegin?: (scrubInfo: ScrubInfo) => void
    onScrubChange?: (value: Value, scrubInfo: ScrubInfo) => void
    onScrubEnd?: (scrubInfo: ScrubInfo) => void
    notUseUserConfig?: boolean
    // 下面的参数基本不用设置，都是一样的
    scrubMultiplier?: number
    shiftScrubMultiplier?: number
    // 左侧和右侧边框线默认支持拖拽
    leftScrubbable?: boolean
    rightScrubbable?: boolean
    // ui交互独立
    tool?: React.ReactNode
    toolClassName?: string
}
export type ScrubbableControlProps = ScrubbableControlCustomProps & HTMLAttributes<HTMLLabelElement>

export function _ScrubbableControl(props: ScrubbableControlProps, outRef: React.Ref<HTMLLabelElement>) {
    const [isLiveScrub, setIsLiveScrub] = useState<boolean>(false)
    const [userForbidPointerLock, setUserForbidPointerLock] = useState<boolean>(false)
    const cancelPointerLockFallbackRef = useRef<boolean>(false)
    const preScrubValue = useRef<Value>(getPreScrubValue(props.value))
    const preScrubMixedValues = useRef<Map<string, Value>>(new Map())
    const scrubInfoRef = useRef<ScrubInfo>(createInitScrubInfo())
    const isShiftKeyPressed = useRef<boolean>(false)
    const [isAltKeyPressed, setIsAltKeyPressed] = useState<boolean>(false)
    const [cursorInfo, setCursorInfo] = useState<ScrubCursorProps>({ x: -100, y: -100, multiple: Multiple.OneOne })
    const { leftScrubbable = true, rightScrubbable = true } = props
    const userConfig = useUserConfig()
    const command = useCommand()

    const effectiveScrubMultiplier = useMemo(() => {
        if (props.scrubMultiplier) {
            return props.scrubMultiplier
        } else if (!props.notUseUserConfig) {
            return userConfig.smallNudgeAmount / 2
        }
        return 0.5
    }, [props.notUseUserConfig, props.scrubMultiplier, userConfig.smallNudgeAmount])

    const effectiveBigIncrementMultiplier = useMemo(() => {
        if (props.shiftScrubMultiplier) {
            return props.shiftScrubMultiplier
        } else if (!props.notUseUserConfig) {
            return userConfig.bigNudgeAmount / 2
        }
        return 5
    }, [props.notUseUserConfig, props.shiftScrubMultiplier, userConfig.bigNudgeAmount])

    const forbidScrubbing = useMemo(() => {
        return props.disabled || props.scrubbingDisabled
    }, [props.disabled, props.scrubbingDisabled])

    const getRelativeDistance = useCallback(
        (absDistance: Distance, shiftKey: boolean): Distance => {
            const multiplier = shiftKey
                ? effectiveBigIncrementMultiplier * effectiveScrubMultiplier
                : effectiveScrubMultiplier
            return {
                x: absDistance.x * multiplier * getMultipleByDeltaY(absDistance.y),
                y: absDistance.y * multiplier * getMultipleByDeltaY(absDistance.y),
            }
        },
        [effectiveBigIncrementMultiplier, effectiveScrubMultiplier]
    )

    const onCursorUpdate = useCallback(() => {
        const { initPosition, absoluteDistance } = scrubInfoRef.current
        const { clientWidth, clientHeight } = document.documentElement
        let x = initPosition.x + absoluteDistance.x
        let y = initPosition.y + absoluteDistance.y
        x = x >= 0 ? x % clientWidth : (x % clientWidth) + clientWidth
        y = y >= 0 ? y % clientHeight : (y % clientHeight) + clientHeight

        setCursorInfo({ x, y, multiple: getMultipleByDeltaY(absoluteDistance.y) })
    }, [])

    const onScrubBegin = useCallback(
        (scrubInfo: ScrubInfo) => {
            if (props.isMixed) {
                if (props.mixedMathHandler) {
                    preScrubMixedValues.current = props.mixedMathHandler.getScrubStartValueMixed()
                } else {
                    return
                }
            } else {
                preScrubValue.current = getPreScrubValue(props.value)
            }
            props.onScrubBegin?.(scrubInfo)
            setIsLiveScrub(true)
            command.commitUndo()
        },
        [command, props]
    )

    const onScrubChange = useCallback(
        (scrubInfo: ScrubInfo) => {
            const scrubHandler = (scrubStartValue: number) => {
                let outputValue = scrubStartValue + scrubInfo.distance.x
                outputValue = clampMinMax(outputValue, props)
                outputValue = valueFilter(outputValue, props.resolution)
                if (props.valueFilter) {
                    outputValue = props.valueFilter(scrubInfo, outputValue, userConfig)
                }
                outputValue = clampMinMax(outputValue, props)
                return outputValue
            }
            if (props.isMixed && props.mixedMathHandler) {
                const { onScrubChangeMixed, onScrubChangeAllMixed } = props.mixedMathHandler
                if (onScrubChangeAllMixed) {
                    const map = new Map<string, Value>()
                    for (const [key, value] of preScrubMixedValues.current) {
                        if (typeof value === 'number') {
                            map.set(key, scrubHandler(value))
                        } else if (Array.isArray(value)) {
                            map.set(key, value.map(scrubHandler))
                        }
                    }
                    onScrubChangeAllMixed(map)
                } else {
                    for (const [key, value] of preScrubMixedValues.current) {
                        if (typeof value === 'number') {
                            onScrubChangeMixed?.(key, scrubHandler(value))
                        } else if (Array.isArray(value)) {
                            onScrubChangeMixed?.(key, value.map(scrubHandler))
                        }
                    }
                }
            } else {
                let n: Value | undefined
                if (Array.isArray(preScrubValue.current)) {
                    n = preScrubValue.current.map(scrubHandler)
                } else if (typeof preScrubValue.current === 'number') {
                    n = scrubHandler(preScrubValue.current)
                }
                if (n !== undefined) {
                    props.onValueChange?.(n, { commitType: CommitType.Noop })
                    props.onScrubChange?.(n, scrubInfo)
                }
            }
        },
        [props, userConfig]
    )

    const onScrubEnd = useCallback(
        (scrubInfo: ScrubInfo) => {
            props.onScrubEnd?.(scrubInfo)
            setIsLiveScrub(false)
            command.commitUndo()
        },
        [command, props]
    )

    const scrubEndHandler = useCallback(() => {
        onScrubEnd(scrubInfoRef.current)
        setIsLiveScrub(false)
    }, [onScrubEnd])

    const isForbidEnterScrub = useCallback(
        (e: React.PointerEvent) => {
            if (forbidScrubbing) {
                return true
            }
            if (!e.altKey && (e.target as HTMLElement).tagName.toLocaleLowerCase() === 'input') {
                return true
            }
            if (!featureSwitchManager.isEnabled('float-variable')) {
                return false
            }
            let element = e.target as HTMLElement | null
            while (element && element !== e.currentTarget) {
                if (element.dataset.scrubbableControlForbidScrubbing) {
                    return true
                }
                element = element.parentElement
            }
            return false
        },
        [forbidScrubbing]
    )

    const _onPointerDown = useCallback(
        (e: React.PointerEvent) => {
            e.stopPropagation()
            const targetElement = e.target as Element
            if (isForbidEnterScrub(e)) {
                return
            }
            e.preventDefault()
            if (isSafari()) {
                targetElement.setPointerCapture(e.pointerId)
            } else {
                const requestPointerLockResult = targetElement.requestPointerLock()
                if (requestPointerLockResult) {
                    setUserForbidPointerLock(false)
                    cancelPointerLockFallbackRef.current = false
                    requestPointerLockResult.catch(() => {
                        setUserForbidPointerLock(true)
                        if (cancelPointerLockFallbackRef.current) {
                            scrubEndHandler()
                        } else {
                            try {
                                targetElement.setPointerCapture(e.pointerId)
                            } catch (_e) {
                                // 上面在异步中的调用，可能出现下面的error信息。这里先忽略
                                // Failed to execute 'setPointerCapture' on 'Element': No active pointer with the given id is found.
                                // TODO: (lsz)chrome130后requestPointerLock需要用户权限，这里setPointerCapture出现可能出现异常。
                                // 需要整体重构下这个组件的拖拽流程。大致流程步骤
                                // 1. try{ setPointerCapture } catch(){}
                                // 2. 条件使用requestPointerLock。增加 Docuemnt pointerlockerror 事件监听
                                // 3. 增加 setPointerCapture、requestPointerLock 均失败的兜底（以document层级的移动事件作为最终的fallback）
                                // setPointerCapture后调用requestPointerLock。在重复快速的点击过程中概率出现光标不显示（重构时如果这么写，需要考虑下）
                            }
                        }
                    })
                }
            }
            scrubInfoRef.current.shiftKey = e.shiftKey
            scrubInfoRef.current.initPosition = { x: e.clientX, y: e.clientY }
            scrubInfoRef.current.absoluteDistance = { x: 0, y: 0 }
            scrubInfoRef.current.distance = { x: 0, y: 0 }
            onCursorUpdate()
            onScrubBegin(scrubInfoRef.current)
        },
        [isForbidEnterScrub, onCursorUpdate, onScrubBegin, scrubEndHandler]
    )

    const _onPointerMove = useCallback(
        (e: React.PointerEvent) => {
            if (!isLiveScrub) {
                return
            }
            scrubInfoRef.current.shiftKey = e.shiftKey
            scrubInfoRef.current.absoluteDistance.x += e.movementX
            scrubInfoRef.current.absoluteDistance.y += e.movementY
            scrubInfoRef.current.distance = getRelativeDistance(scrubInfoRef.current.absoluteDistance, e.shiftKey)
            onCursorUpdate()
            onScrubChange(scrubInfoRef.current)
        },
        [getRelativeDistance, isLiveScrub, onScrubChange, onCursorUpdate]
    )

    const _onPointerUp = useCallback(
        (e: React.PointerEvent) => {
            if (!isLiveScrub) {
                return
            }
            const targetElement = e.target as Element
            targetElement.releasePointerCapture(e.pointerId)
            document.exitPointerLock()
            scrubInfoRef.current.shiftKey = e.shiftKey
            scrubInfoRef.current.distance = getRelativeDistance(scrubInfoRef.current.absoluteDistance, e.shiftKey)
        },
        [getRelativeDistance, isLiveScrub]
    )

    const _onKey = useCallback(
        (e: React.KeyboardEvent<HTMLLabelElement> | KeyboardEvent) => {
            if (isLiveScrub) {
                if (e.shiftKey !== isShiftKeyPressed.current) {
                    isShiftKeyPressed.current = e.shiftKey
                    scrubInfoRef.current.shiftKey = e.shiftKey
                    scrubInfoRef.current.distance = getRelativeDistance(
                        scrubInfoRef.current.absoluteDistance,
                        e.shiftKey
                    )
                    onScrubChange(scrubInfoRef.current)
                }
                isShiftKeyPressed.current = e.shiftKey
            }
            !forbidScrubbing && setIsAltKeyPressed(e.altKey)
        },
        [getRelativeDistance, isLiveScrub, onScrubChange, forbidScrubbing]
    )

    const onPointerLockChange = useCallback(() => {
        if (!document.pointerLockElement && isLiveScrub) {
            scrubEndHandler()
        }
    }, [scrubEndHandler, isLiveScrub])

    const onLostPointerCapture = useCallback(() => {
        if (isLiveScrub) {
            scrubEndHandler()
        }
    }, [scrubEndHandler, isLiveScrub])

    const onPointerUp = useCallback(() => {
        cancelPointerLockFallbackRef.current = true
    }, [])

    useEffect(() => {
        const windowBlur = () => setIsAltKeyPressed(false)
        document.addEventListener('keydown', _onKey, false)
        document.addEventListener('keyup', _onKey, false)
        document.addEventListener('pointerlockchange', onPointerLockChange)
        document.addEventListener('lostpointercapture', onLostPointerCapture)
        document.addEventListener('pointerup', onPointerUp)
        window.addEventListener('blur', windowBlur)
        return () => {
            document.removeEventListener('keydown', _onKey)
            document.removeEventListener('keyup', _onKey)
            document.removeEventListener('pointerlockchange', onPointerLockChange)
            document.removeEventListener('lostpointercapture', onLostPointerCapture)
            document.removeEventListener('pointerup', onPointerUp)
            window.removeEventListener('blur', windowBlur)
        }
    }, [_onKey, onLostPointerCapture, onPointerLockChange, onPointerUp])

    const _labelClassName = useMemo(() => {
        let _className = classes.label
        isAltKeyPressed && (_className += ' ' + classes.altKeyPressed)
        props.className && (_className += ' ' + props.className)
        props.disabled && (_className += ' ' + classes.disabled)
        props.scrubbingDisabled && (_className += ' ' + classes.scrubbingDisabled)
        return _className
    }, [isAltKeyPressed, props.className, props.disabled, props.scrubbingDisabled])

    const _onClick = useCallback(
        (e: React.MouseEvent<HTMLLabelElement>) => {
            e.preventDefault()
            props.onClick?.(e)
        },
        [props]
    )

    if (featureSwitchManager.isEnabled('float-variable')) {
        return (
            <>
                <label
                    ref={outRef}
                    onPointerDown={_onPointerDown}
                    onPointerMove={_onPointerMove}
                    onPointerUp={_onPointerUp}
                    onClick={_onClick}
                    className={classNames(classes.label2, props.className, {
                        [classes.altKeyPressed]: isAltKeyPressed,
                        [classes.disabled]: props.disabled,
                        [classes.scrubbingDisabled]: props.scrubbingDisabled,
                        [classes.uiFocusWithin]: props.uiFocusWithin,
                        [classes.focusWithoutBorder]: props.focusWithoutBorder,
                    })}
                    data-testid={props.labelTestId ?? 'label'}
                >
                    <div className={classNames(classes.content, props.contentClassName)}>
                        {leftScrubbable ? <div className={classes.left}></div> : null}
                        {props.children}
                        {rightScrubbable ? <div className={classes.right}></div> : null}
                    </div>
                    <div className={classNames(classes.tool, props.toolClassName)}>{props.tool}</div>
                </label>
                {isLiveScrub ? (
                    <ScrubCursor {...cursorInfo} useSystemCursor={isSafari() || userForbidPointerLock} />
                ) : null}
            </>
        )
    }

    return (
        <>
            <label
                ref={outRef}
                onPointerDown={_onPointerDown}
                onPointerMove={_onPointerMove}
                onPointerUp={_onPointerUp}
                onKeyDown={_onKey}
                onKeyUp={_onKey}
                className={_labelClassName}
                onClick={props.onClick}
                data-testid={props.labelTestId ?? 'label'}
                tabIndex={-1}
            >
                {leftScrubbable ? <div className={classes.left}></div> : null}
                {props.children}
                {rightScrubbable ? <div className={classes.right}></div> : null}
            </label>
            {isLiveScrub ? <ScrubCursor {...cursorInfo} useSystemCursor={isSafari() || userForbidPointerLock} /> : null}
        </>
    )
}

export const ScrubbableControl = forwardRef(_ScrubbableControl)
