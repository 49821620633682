import { featureSwitchManager } from '../../../kernel/switch/core'
import { useAppContext } from '../../../main/app-context'
import { useViewState } from '../../../view-state-bridge'
import entryIcon from './assets/entry.png'

export const ChatbotEntry = () => {
    const chatbotService = useAppContext().chatbotService
    const fullscreen = useViewState('fullscreen')
    const isHistoryMode = useViewState('historyMode')
    const isReadonly = useViewState('docReadonly')
    if (!featureSwitchManager.isEnabled('chatbot')) {
        return null
    }

    if (fullscreen || isHistoryMode || isReadonly) {
        return null
    }

    return (
        <div
            className="fixed bottom-6 right-68px z-20 rounded-full"
            style={{
                boxShadow: '0 2px 16px 0 var(--wk-gray-13-12)',
            }}
            onClick={() => {
                chatbotService.showModal()
            }}
            data-testid="chatbot-entry"
        >
            <img className="w-8 h-8" src={entryIcon} />
        </div>
    )
}
