import { Wukong } from '@wukong/bridge-proto'
import { addEffects, tryAddStrokes } from '../utils/attrs-modify'
import { getActiveBorderColor, getNextNodeId, getTransformMatrix, parentInfo, parseRGBA } from '../utils/utils'

function precentOrPxToNumber(value: string, width: number) {
    if (value.endsWith('%')) {
        return (+value.replace('%', '') / 100) * width
    }
    return +value.replace('px', '')
}

export function makePseudoNode(
    pseudoElementStyle: CSSStyleDeclaration,
    node: HTMLElement,
    parentId: string,
    pseudoName: string
) {
    const backgroundColor = pseudoElementStyle.backgroundColor
    const parentComputedStyle = getComputedStyle(node)
    const isParentFlex = parentComputedStyle.display === 'flex'
    const isAbsolute = pseudoElementStyle.position === 'absolute'
    const pseudoElementId = getNextNodeId()
    const transformMatrix = getTransformMatrix(pseudoElementStyle.transform)
    const width = +pseudoElementStyle.width.replace('px', '')
    const paddingTop = +pseudoElementStyle.paddingTop.replace('px', '')
    const paddingBottom = +pseudoElementStyle.paddingBottom.replace('px', '')
    const height = +pseudoElementStyle.height.replace('px', '') + paddingTop + paddingBottom
    const left = Number(pseudoElementStyle.left.replace('px', '')) || 0
    const top = Number(pseudoElementStyle.top.replace('px', '')) || 0
    const translateX = transformMatrix?.translateX ?? 0
    const translateY = transformMatrix?.translateY ?? 0
    const marginLeft = Number(pseudoElementStyle.marginLeft.replace('px', '')) || 0
    const marginTop = Number(pseudoElementStyle.marginTop.replace('px', '')) || 0
    const borderLeftWidth = Number(parentComputedStyle.borderLeftWidth.replace('px', '')) || 0
    const borderTopWidth = Number(parentComputedStyle.borderTopWidth.replace('px', '')) || 0
    const pseudoElement: Wukong.DocumentProto.ISynergyNode = {
        nodeId: pseudoElementId,
        partialNode: {
            type: Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME,
            id: pseudoElementId,
            parentInfo: parentInfo.getParentInfo(parentId),
            name: pseudoName.replace('::', '').toUpperCase(),
            width,
            height,
            cornerRadius: precentOrPxToNumber(pseudoElementStyle.borderRadius.replace('px', ''), width),
            topLeftRadius: precentOrPxToNumber(pseudoElementStyle.borderTopLeftRadius.replace('px', ''), width),
            topRightRadius: precentOrPxToNumber(pseudoElementStyle.borderTopRightRadius.replace('px', ''), width),
            bottomLeftRadius: precentOrPxToNumber(pseudoElementStyle.borderBottomLeftRadius.replace('px', ''), width),
            bottomRightRadius: precentOrPxToNumber(pseudoElementStyle.borderBottomRightRadius.replace('px', ''), width),
            opacity: +pseudoElementStyle.opacity,
            fills: [
                {
                    type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
                    visible: true,
                    opacity: parseRGBA(backgroundColor).a,
                    blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
                    color: {
                        r: parseRGBA(backgroundColor).r,
                        g: parseRGBA(backgroundColor).g,
                        b: parseRGBA(backgroundColor).b,
                    },
                },
            ],
            relativeTransform: {
                translateX: isAbsolute
                    ? left + translateX + marginLeft + borderLeftWidth
                    : isParentFlex
                    ? (node.getBoundingClientRect().width - width) / 2
                    : translateX + borderLeftWidth,
                translateY: isAbsolute
                    ? top + translateY + marginTop + borderTopWidth
                    : isParentFlex
                    ? (node.getBoundingClientRect().height - height) / 2
                    : translateY + borderTopWidth,
                scaleX: 1,
                scaleY: 1,
                skewX: 0,
                skewY: 0,
            },
            stackPositioning:
                pseudoElementStyle.position === 'absolute' || pseudoElementStyle.position === 'fixed'
                    ? Wukong.DocumentProto.StackPositioning.STACK_POSITIONING_ABSOLUTE
                    : Wukong.DocumentProto.StackPositioning.STACK_POSITIONING_AUTO,
        },
    }
    if (pseudoElementStyle.boxShadow !== 'none') {
        addEffects(pseudoElement.partialNode!, pseudoElementStyle.boxShadow)
    }

    const borderColor = getActiveBorderColor(pseudoElementStyle)
    tryAddStrokes(pseudoElement, borderColor, pseudoElementStyle)

    // 处理transform, 例如缩放: 99156
    if (pseudoElementStyle.transform !== 'none') {
        const transform = new DOMMatrix([
            pseudoElement.partialNode!.relativeTransform?.scaleX!,
            pseudoElement.partialNode!.relativeTransform?.skewY!,
            pseudoElement.partialNode!.relativeTransform?.skewX!,
            pseudoElement.partialNode!.relativeTransform?.scaleY!,
            pseudoElement.partialNode!.relativeTransform?.translateX!,
            pseudoElement.partialNode!.relativeTransform?.translateY!,
        ])

        const centerX = pseudoElement.partialNode!.width! / 2
        const centerY = pseudoElement.partialNode!.height! / 2
        const translateToOrigin = new DOMMatrix().translate(-centerX, -centerY)
        const transformToPseudo = new DOMMatrix(pseudoElementStyle.transform)
        const translateBack = new DOMMatrix().translate(centerX, centerY)

        const transformResult = translateBack
            .multiply(transformToPseudo)
            .multiply(translateToOrigin)
            .multiply(transform)

        pseudoElement.partialNode!.relativeTransform = {
            translateX: transformResult.e,
            translateY: transformResult.f,
            scaleX: transformResult.a,
            scaleY: transformResult.d,
            skewX: transformResult.b,
            skewY: transformResult.c,
        }
    }

    return pseudoElement
}
