/* eslint-disable no-restricted-imports */
import { forwardRef, useCallback } from 'react'
import type { Paint } from '../../../../../document/node/node'
import { StylePaint } from '../style-paint/style-paint'
import { PaintStyleThumbnail } from './style-thumbnail'

interface StylePaintColorProps extends React.HTMLAttributes<HTMLDivElement> {
    paints: readonly Paint[]
    name: string
    description?: string | null
    isUseColor?: boolean
    isOpen?: boolean
    usingNodeCount?: number
    onClickSub?: () => void
    onClickStyle?: React.MouseEventHandler<HTMLDivElement>
    onClickCutLink?: (paints: Paint[]) => void
    onClickAimIcon?: () => void
    stroke?: boolean
}
function _StylePaintColor(props: StylePaintColorProps, reactRef: React.Ref<HTMLDivElement>): JSX.Element {
    const {
        paints,
        name,
        description,
        isUseColor,
        isOpen,
        usingNodeCount,
        onClickSub,
        onClickStyle,
        onClickCutLink,
        onClickAimIcon,
        stroke,
    } = props

    const _onClickCutLink = useCallback(() => {
        onClickCutLink?.(paints.slice())
    }, [onClickCutLink, paints])

    return (
        <StylePaint
            ref={reactRef}
            name={name}
            description={description}
            thumbnail={<PaintStyleThumbnail stroke={stroke} paints={paints} width={16} height={16} multiple={2} />}
            type={isUseColor ? 'select-color' : undefined}
            isOpen={isOpen}
            usingNodeCount={usingNodeCount}
            onClickThumbnailName={onClickStyle}
            onClickCutLink={_onClickCutLink}
            onClickAim={onClickAimIcon}
            onClickSub={onClickSub}
        />
    )
}

export const StylePaintColor = forwardRef(_StylePaintColor)
