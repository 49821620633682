import { Wukong } from '@wukong/bridge-proto'
import type {
    ComponentId,
    ComponentSetGetVO,
    ComponentSetWithoutDocIdGetVO,
    ComponentWithoutDocIdGetVO,
    LibraryId,
    StyleGetVO,
    StyleWithoutDocIdGetVO,
    VariableGetVO,
    VariableId,
    VariableSetGetVO,
    VariableSetId,
    VariableSetWithoutDocIdGetVO,
    VariableWithoutDocIdGetVO,
} from './component-style'
import { ComponentGetVO } from './component-style'
import { ComponentSetId } from './component-style-library-id'
import { translation } from './library.translation'
import { DocID, DocWithAuthorityVO, FolderID, ResourceType, TeamID } from './type'

type NodeId = string
type StyleId = string

export interface LibraryContentVO {
    readonly library: LibraryVO
    /**
     * 组件库中包含的组件，不包含组件集中的组件
     */
    readonly components: ReadonlyArray<ComponentGetVO>
    /**
     * 组件库中包含的样式
     */
    readonly styles: ReadonlyArray<StyleGetVO>

    /**
     * 组件库中包含的组件集
     */
    readonly componentSets: ReadonlyArray<ComponentSetGetVO>

    /**
     * 组件库中包含的变量
     */
    readonly variables: ReadonlyArray<VariableGetVO>

    /**
     * 组件库中包含的变量集
     */
    readonly variableSets: ReadonlyArray<VariableSetGetVO>

    /**
     * 组件集的具体组件，组件集Id -> 组件集中的组件
     */
    readonly componentSetId2ChildrenComponents: Record<ComponentSetId, ComponentGetVO[]>

    /**
     * 组件库中移走的组件/样式
     */
    readonly libraryMovedInfos: LibraryMovedComponentStyles
}

interface LibraryMovedComponentStyles {
    /**
     * 已经移动的组件：key为移动前的组件id、value为移动后的组件id
     */
    readonly componentMoveRemappings: Record<ComponentId | ComponentSetId, ComponentId | ComponentSetId>

    /**
     * 已经移动的样式：key为移动前的样式id、value为移动后的样式id
     */
    readonly styleMoveRemappings: Record<StyleId, StyleId>

    /**
     * 已经移动的组件/样式当前关联文档基本信息，key为组件库id，value为组件库基本信息
     */
    readonly libraryId2LibraryInfo: Record<LibraryId, LibraryIdAndNameVO>
}

export interface LibraryContentBaseInfoVO {
    /**
     * 组件库内容
     */
    readonly library: LibraryVO
    /**
     * 已经移动的组件当前关联文档基本信息，key为组件库id，value为组件库基本信息
     */
    readonly libraryId2LibraryInfo: Record<LibraryId, LibraryIdAndNameVO>
    /**
     * 组件库数据的文件名
     */
    readonly resourceId: string
}

export interface LibraryContentMetaInfoVO {
    /**
     * 组件库中包含的组件
     */
    readonly components: ReadonlyArray<ComponentWithoutDocIdGetVO>
    /**
     * 组件库中包含的样式
     */
    readonly styles: ReadonlyArray<StyleWithoutDocIdGetVO>

    /**
     * 组件库中包含的组件集
     */
    readonly componentSets: ReadonlyArray<ComponentSetWithoutDocIdGetVO>

    /**
     * 组件库中包含的变量集
     */
    readonly variableCollections: ReadonlyArray<VariableSetWithoutDocIdGetVO>

    /**
     * 组件库中包含的变量
     */
    readonly variables: ReadonlyArray<VariableWithoutDocIdGetVO>

    /**
     * 组件集的具体组件，组件集Id -> 组件集中的组件
     */
    readonly componentSetId2ChildrenComponents: Record<ComponentSetId, ComponentWithoutDocIdGetVO[]>

    /**
     * 组件库中移走的组件样式信息
     */
    readonly libraryMovedInfos: {
        /**
         * 已经移动的组件：key为移动前的组件id、value为移动后的组件id
         */
        readonly componentMoveRemappings: Record<ComponentId | ComponentSetId, ComponentId | ComponentSetId>

        /**
         * 已经移动的样式：key为移动前的样式id、value为移动后的样式id
         */
        readonly styleMoveRemappings: Record<StyleId, StyleId>
    }
}

export interface LibraryVO {
    /**
     * 组件库id
     * @format int64
     */
    readonly id: LibraryId
    readonly document?: DocWithAuthorityVO | null
    /**
     * 组件库包含的已发布组件数量
     * @format int32
     */
    readonly componentCount?: number | null
    /**
     * 组件库包含的已发布样式数量
     * @format int32
     */
    readonly styleCount?: number | null

    /**
     * 组件库包含的已发布变量集数量
     * @format int32
     */
    readonly variableCollectionCount?: number | null
    /**
     * 组件库包含的已发布变量数量
     * @format int32
     */
    readonly variableCount?: number | null
    /**
     * 组件库是否共享
     */
    readonly shared?: boolean | null

    /**
     * 组件库可访问的范围
     */
    readonly shareScope: string
    /**
     * 最近发布时间
     */
    readonly publishTime?: number
}

export interface UpdateLibraryRequest {
    /**
     * @format int64
     */
    readonly id?: LibraryId | null
    readonly shareScope?: string | null
    readonly shared?: boolean | null
}

export interface CreateLibraryRequest {
    readonly docId?: DocID | null
    readonly shareScope?: string | null
}

export interface LibraryQueryResponse {
    /**
     * 符合条件的组件库列表，以team维度聚合，组件库顺序为匹配度由高到低
     */
    readonly teamId2LibraryList: Record<TeamID, Array<LibraryVO>>
    /**
     * team基本信息
     */
    readonly teamId2TeamName: Record<TeamID, string>
    /**
     * 组件库中匹配的组件/组件集信息
     */
    readonly libraryId2MatchedComponentList: Record<LibraryId, Array<ComponentGetVO>>
    /**
     * 组件库中匹配的样式信息
     */
    readonly libraryId2MatchedStyleList: Record<LibraryId, Array<StyleGetVO>>
    /**
     * 组件库中匹配的组件集信息
     */
    readonly libraryId2MatchedVariableCollectionList: Record<LibraryId, Array<VariableSetGetVO>>
    /**
     * 组件库中匹配的变量信息
     */
    readonly libraryId2MatchedVariableList: Record<LibraryId, Array<VariableGetVO>>
}

export interface LibraryQueryResponseWithoutDocId
    extends Omit<LibraryQueryResponse, 'libraryId2MatchedComponentList' | 'libraryId2MatchedStyleList'> {
    /**
     * 组件库中匹配的组件信息
     */
    readonly libraryId2MatchedComponentList: Record<LibraryId, Array<ComponentWithoutDocIdGetVO>>
    /**
     * 组件库中匹配的样式信息
     */
    readonly libraryId2MatchedStyleList: Record<LibraryId, Array<StyleWithoutDocIdGetVO>>
}

export interface LibrarySubscriptionVO {
    /**
     * @format int64
     */
    readonly libraryId: LibraryId

    /**
     * 订阅该 libraryId 的文档 id
     */
    readonly docId: DocID

    /**
     * 该 libraryId 对应文档 id
     */
    readonly libraryDocId: DocID
}

export interface DefaultLibrarySubscription {
    readonly resourceType: ResourceType
    readonly resourceId: TeamID | FolderID
    readonly libraryId: number
}

export interface LibraryIdAndNameVO {
    /**
     * 组件库 id
     * @format int64
     */
    readonly id: LibraryId
    /**
     * 文档名称
     */
    readonly documentName?: string

    readonly shared: boolean

    /**
     * 文档 id
     */
    readonly docId: DocID
}

// 检查组件/样式移动-request
export interface CheckComponentMovementRequest {
    /**
     * 待检查的组件，key为当前nodeId、value为组件id
     */
    readonly nodeId2ComponentId: Record<NodeId, ComponentId | ComponentSetId>

    /**
     * 待检查的样式，key为当前nodeId、value为样式id
     */
    readonly nodeId2StyleId: Record<NodeId, StyleId>
}

// 检查组件/样式移动-response
export interface CheckComponentMovementResponse {
    /**
     * 未移动的组件/样式，key为当前nodeId、value为这个组件/样式所在的文档id
     */
    readonly validNodeId2DocId: Record<NodeId, DocID>

    /**
     * 不允许进行移动的组件，值为当前nodeId
     */
    readonly neverMovableNodeIds: NodeId[]
}

// 更新组件库内容的变化
export interface UpdateLibraryContentRequestV4 {
    readonly requestContent: UpdateLibraryContentDataRequestV4
    readonly manifest: UpdateLibraryMetaRequestV4
}

// 更新组件库内容的请求，用来发布组件和样式的变更
export interface UpdateLibraryMetaRequestV4 {
    /**
     * 组件库id
     * @format int64
     */
    readonly libraryId?: LibraryId | null

    /**
     * 本次发布的描述信息
     */
    description: string

    // 分片发布用于合并的唯一标识
    uniqKey: string

    /**
     * 取消发布的组件id
     */
    readonly unpublishedComponentIds: Array<ComponentId>
    /**
     * 新发布的组件
     */
    readonly publishedComponents: Array<Omit<Wukong.DocumentProto.IComponentUpdateVO, 'nodeDataV2' | 'thumbnailData'>>
    /**
     * 更新的组件
     */
    readonly updatedComponents: Array<Omit<Wukong.DocumentProto.IComponentUpdateVO, 'nodeDataV2' | 'thumbnailData'>>
    /**
     * 取消发布的样式id
     */
    readonly unpublishedStyles: Array<StyleId>
    /**
     * 新发布的样式
     */
    readonly publishedStyles: Array<Wukong.DocumentProto.IStyleUpdateVO>
    /**
     * 更新的样式
     */
    readonly updatedStyles: Array<Wukong.DocumentProto.IStyleUpdateVO>

    /**
     * 取消发布的组件集id
     */
    readonly unpublishedComponentSetIds: Array<ComponentSetId>
    /**
     * 新发布的组件集
     */
    readonly publishedComponentSets: Array<
        Omit<Wukong.DocumentProto.IComponentUpdateVO, 'nodeDataV2' | 'thumbnailData'>
    >
    /**
     * 更新的组件集
     */
    readonly updatedComponentSets?: Array<Omit<Wukong.DocumentProto.IComponentUpdateVO, 'nodeDataV2' | 'thumbnailData'>>

    /**
     * 组件/样式移动操作，需要将组件信息放进 publishedComponents/publishedStyles 中；key 为移动前的 nodeId、value 为当前的组件/样式id
     */
    readonly moveRemappings: Record<NodeId, ComponentId | ComponentSetId | StyleId>

    /**
     * 取消发布的变量集id
     */
    readonly unpublishedVariableCollectionIds: Array<VariableSetId>
    /**
     * 新发布的变量集
     */
    readonly publishedVariableCollections: Array<Omit<Wukong.DocumentProto.IVariableSetUpdateVO, 'nodeDataV2'>>
    /**
     * 更新的变量集
     */
    readonly updatedVariableCollections: Array<Omit<Wukong.DocumentProto.IVariableSetUpdateVO, 'nodeDataV2'>>

    /**
     * 取消发布的变量id
     */
    readonly unpublishedVariableIds: Array<VariableId>
    /**
     * 新发布的变量
     */
    readonly publishedVariables: Array<Omit<Wukong.DocumentProto.IVariableUpdateVO, 'nodeDataV2'>>
    /**
     * 更新的变量
     */
    readonly updatedVariables: Array<Omit<Wukong.DocumentProto.IVariableUpdateVO, 'nodeDataV2'>>
}

// 需要更新组件的数据信息
export interface UpdateLibraryContentDataRequestV4 {
    readonly allData: UpdateLibraryContentNodeDataV3[]
}

// node 的数据信息
export interface UpdateLibraryContentNodeDataV3 {
    readonly nodeId: NodeId
    /**
     * 该数据的类型
     */
    readonly type: UpdateLibraryContentType
    /**
     * 数据本身
     */
    readonly data: string
}

// 更新组件数据的类型
export enum UpdateLibraryContentType {
    ProtoData = 'PROTO_DATA',
    Thumbnail = 'THUMBNAIL',
}

export const LibraryNotificationType2Message = (notificationType: Wukong.DocumentProto.LibraryNotificationType) => {
    switch (notificationType) {
        case Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_COMPONENT_MOVED:
            return translation('ToMovePasted')
        case Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_STYLE_MOVED:
            return translation('ToMovePasted_synonyms1')
        case Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_REFERENCE_COMPONENT_MOVED:
            return translation('SomeComponentsWere')
        case Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_REFERENCE_STYLE_MOVED:
            return translation('SomeStylesWere')
        case Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_REFERENCE_COMPONENT_STYLE_MOVED:
            return translation('SomeComponentsAnd')
        case Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_REFERENCE_UPDATED:
            return translation('ComponentsAndStyles')
        case Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_REFERENCE_COMPONENT_UPDATED:
            return translation('ComponentsUpdatesAvailable')
        case Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_REFERENCE_STYLE_UPDATED:
            return translation('StylesUpdatesAvailable')
        case Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_REFERENCE_VARIABLE_UPDATED:
            return translation('VariablesUpdatesAvailable')
        case Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_VARIABLE_AND_STYLE_UPDATED:
            return translation('VariablesAndStylesUpdatesAvailable')
        case Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_VARIABLE_AND_COMPONENT_UPDATED:
            return translation('VariablesAndComponentsUpdatesAvailable')
        case Wukong.DocumentProto.LibraryNotificationType
            .LIBRARY_NOTIFICATION_TYPE_VARIABLE_AND_COMPONENT_AND_STYLE_UPDATED:
            return translation('VariablesAndComponentsAndStylesUpdatesAvailable')
        default:
            return ''
    }
}

export enum LibraryShareScope {
    ORG = 'ORG',
    TEAM = 'TEAM',
    WORKSPACE = 'WORKSPACE',
}

export interface LibraryPublishScope {
    readonly publishScope: LibraryShareScope[]
    readonly workspaceName: string
}
