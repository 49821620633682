import { translation } from './index.translation'
import { FC, useState } from 'react'
import { ErrorSVG } from './assets/error'
import { WKButton } from '../../../../../../ui-lib/src'
import { environment } from '../../../../environment'
import { IssusModal } from '../../../../ui/component/crash/ui/issue-modal'
import { WkCLog } from '../../../../kernel/clog/wukong/instance'

export const FallbackComponent: FC<{ error: Error }> = ({ error }) => {
    const [showReportModal, setShowReportModal] = useState(false)

    return (
        <div className="flex items-center justify-center h-screen">
            {showReportModal && (
                <IssusModal
                    type="bug"
                    visible={true}
                    onCancel={() => {
                        setShowReportModal(false)
                    }}
                    onOK={(message: string) => {
                        WkCLog.log('motiff-error-boundary', {
                            value: message,
                            stack: error.stack ?? '',
                        })
                        setTimeout(() => {
                            window.location.reload()
                        }, 1000)
                    }}
                    onClose={() => {
                        setShowReportModal(false)
                    }}
                />
            )}

            <div className="flex flex-col items-center justify-center w-320px">
                <ErrorSVG />

                <div className="text-[18px] font-semibold text-[#1B1F26] mt-[48px]">{translation('loadError')}</div>
                <div className="text-[14px] text-[#454959] mt-[8px] text-center">{translation('getHelp')}</div>

                <WKButton
                    size="large"
                    type="primary"
                    className="mt-[24px] w-[224px]"
                    onClick={() => window.location.reload()}
                >
                    {translation('refresh')}
                </WKButton>

                <WKButton
                    size="large"
                    type="secondary"
                    className="mt-[24px] w-[224px] !ml-0"
                    onClick={() => {
                        setShowReportModal(true)
                    }}
                >
                    {translation('sendReport')}
                </WKButton>

                <div className="mt-[24px] text-[14px] text-[#1A1D25]">
                    <span>{translation('visit')}</span>
                    <a
                        target="_blank"
                        href={
                            environment.isAbroad
                                ? 'https://motiff.com/help/docs/articles/73207389362439'
                                : 'https://motiff.cn/help/docs/sections/123207389362439'
                        }
                        rel="noreferrer"
                    >
                        {translation('helpCenter')}
                    </a>
                </div>
            </div>
        </div>
    )
}
