import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import { ToggleShowShortcutPanelWasmCall } from '@wukong/bridge-proto'
import { useMemo, useState } from 'react'
import { environment } from '../../../environment'
import { OnboardingScene } from '../../../kernel/interface/onboarding'
import { ShortcutKey, shortcutLabelMap } from '../../../kernel/interface/shortcut-key'
import { useCommand } from '../../../main/app-context'
import { useLeftPanelShown, useRightPanelShown, useTopbarShown } from '../../../main/layout/layout-context'
import { onboardingService } from '../../../main/service/onboarding-service'
import { HelpCenterV2 } from '../../../share/help-center'
import { useMenuContextState } from '../top-area/menu/context/menu-context'
import { useFeatureSwitch } from '../../../kernel/switch'

export const EditorHelpCenter = () => {
    const [open, setOpen] = useState(false)
    const command = useCommand()
    const { setIsOpenFeedback } = useMenuContextState()
    const showTopbar = useTopbarShown()
    const showLeftPanel = useLeftPanelShown()
    const showRightPanel = useRightPanelShown()
    const enableAIOnboarding = useFeatureSwitch('ai-gen-onboarding-design-system') && environment.isAbroad

    const isNotFullScreen = useMemo(() => {
        return showTopbar || showLeftPanel || showRightPanel
    }, [showLeftPanel, showRightPanel, showTopbar])

    return isNotFullScreen ? (
        <HelpCenterV2
            openState={open}
            onChangeOpenState={setOpen}
            items={[
                {
                    name: translation('HelpPage'),
                    dataTestId: 'design-help-center-item',
                    customNode: {
                        handler: () => window.open(`${environment.host}/help/docs`),
                    },
                },
                ...(!environment.isAbroad
                    ? [
                          {
                              name: translation('VideoTutorials'),
                              dataTestId: 'design-help-center-item',
                              customNode: {
                                  handler: () => window.open(`${environment.host}/help/videos`),
                              },
                          },
                      ]
                    : []),
                {
                    name: translation('ReleaseNotes'),
                    dataTestId: 'release-notes',
                    customNode: {
                        handler: () => window.open(`${environment.host}/release-notes`),
                    },
                },
                {
                    name: translation('KeyboardShortcuts'),
                    dataTestId: 'design-help-center-item',
                    customNode: {
                        shortcut: shortcutLabelMap[ShortcutKey.ShortcutGuide],
                        handler: () => command.DEPRECATED_invokeBridge(ToggleShowShortcutPanelWasmCall),
                    },
                },
                {
                    name: translation('ResetOnboarding'),
                    dataTestId: 'design-help-center-item',
                    customNode: {
                        handler: () => {
                            onboardingService.resetTour(OnboardingScene.Editor)
                        },
                    },
                },
                {
                    name: '',
                    dataTestId: 'design-help-center-item',
                    disabled: true,
                    customNode: { autoSplitLine: true },
                },
                ...(enableAIOnboarding
                    ? [
                          {
                              name: 'Join Motiff Community',
                              customNode: {
                                  handler: () => {
                                      window.open(
                                          'https://join.slack.com/t/motiffcommunity/shared_invite/zt-31koqta1d-vwx0RmEfWC3~~iI6QWUUYA',
                                          '_blank'
                                      )
                                  },
                              },
                          },
                      ]
                    : []),
                {
                    name: translation('SendFeedback'),
                    dataTestId: 'design-help-center-item',
                    customNode: {
                        handler: () => setIsOpenFeedback(true),
                    },
                },
            ]}
        />
    ) : null
}
