/* eslint-disable no-restricted-imports */
import { useCallback, useRef, useState } from 'react'
import { DragMoveBox } from '../../../../../../ui-lib/src'
import { useCommentService } from '../../../../main/app-context'
import { CommentDataTestId } from '../../../../window'
import { useCreateComment } from '../comment-service/comment-service-hook'
import { useEventAnchor } from '../comment-service/use-event-anchor'
import { useEventMinorAnchor } from '../comment-service/use-event-minor-anchor'
import { Dot } from '../dot-range/dot'
import { Range } from '../dot-range/range'
import { Position } from '../type'
import { calculateRange } from '../utils'
import classes from './content-creating.module.less'

export function ContentCreatingOverlayContent() {
    const commentService = useCommentService()
    const createComment = useCreateComment()
    const { onAnchorMouseMove, onAnchorPointerUp } = useEventAnchor()
    const { onMinorAnchorMouseMove, onMinorAnchorPointerUp } = useEventMinorAnchor()
    const rangeRefCallbackKey = useRef<string>('')
    const dotRefCallbackKey = useRef<string>('')
    const bubbleRefCallbackKey = useRef<string>('')
    const [bubbleBasePoint, setBubbleBasePoint] = useState<Position>()
    const [dotBasePoint, setDotBasePoint] = useState<Position>()

    const isCreateCommentAnchorMoving = useRef<boolean>(false)
    const isCreateCommentMinorAnchorMoving = useRef<boolean>(false)

    const createCommentAnchorMoving = useCallback(
        (position: Position) => {
            if (!isCreateCommentAnchorMoving.current) {
                commentService.updateCreateCommentAnchorStart()
            }
            isCreateCommentAnchorMoving.current = true
            commentService.updateCreateCommentAnchor(position)
        },
        [commentService]
    )

    const createCommentAnchorMoveEnd = useCallback(() => {
        if (isCreateCommentAnchorMoving.current) {
            commentService.updateCreateCommentAnchorEnd()
            isCreateCommentAnchorMoving.current = false
        }
    }, [commentService])

    const createCommentMinorAnchorMoving = useCallback(
        (position: Position) => {
            if (!isCreateCommentMinorAnchorMoving.current) {
                commentService.updateCreateCommentMinorAnchorStart()
            }
            isCreateCommentMinorAnchorMoving.current = true
            commentService.updateCreateCommentMinorAnchor(position)
        },
        [commentService]
    )

    const createCommentMinorAnchorMoveEnd = useCallback(() => {
        if (isCreateCommentMinorAnchorMoving.current) {
            commentService.updateCreateCommentMinorAnchorEnd()
            isCreateCommentMinorAnchorMoving.current = false
        }
    }, [commentService])

    const rangeRefCallback = useCallback(
        (rangeRef: HTMLDivElement | null) => {
            if (!rangeRef) {
                commentService.positionService.unregisterBubble(rangeRefCallbackKey.current)
                rangeRefCallbackKey.current = ''
                return
            }
            rangeRefCallbackKey.current = commentService.positionService.registerBubbleAnchorMinorAnchor(
                undefined,
                (anchorPosition: Position, minorAnchorPosition: Position) => {
                    const { width, height, left, top } = calculateRange(anchorPosition, minorAnchorPosition)
                    rangeRef.setAttribute('style', `width:${width}px;height:${height}px;left:${left}px;top:${top}px;`)
                }
            )
        },
        [commentService.positionService]
    )

    const dotRefCallback = useCallback(
        (dotRef: HTMLDivElement | null) => {
            if (!dotRef) {
                commentService.positionService.unregisterBubble(dotRefCallbackKey.current)
                dotRefCallbackKey.current = ''
                return
            }
            dotRefCallbackKey.current = commentService.positionService.registerBubbleMinorAnchor(
                undefined,
                (minorAnchorPosition: Position) => {
                    dotRef.setAttribute('style', `left:${minorAnchorPosition.left}px;top:${minorAnchorPosition.top}px;`)
                    setDotBasePoint(minorAnchorPosition)
                }
            )
        },
        [commentService.positionService]
    )

    const bubbleRefCallback = useCallback(
        (bubbleRef: HTMLDivElement | null) => {
            if (!bubbleRef) {
                commentService.positionService.unregisterBubble(bubbleRefCallbackKey.current)
                bubbleRefCallbackKey.current = ''
                return
            }
            bubbleRefCallbackKey.current = commentService.positionService.registerBubbleAnchor(
                undefined,
                (anchorPosition: Position) => {
                    const len = 32
                    bubbleRef.setAttribute(
                        'style',
                        `left: ${anchorPosition.left}px; top: ${
                            anchorPosition.top - len
                        }px;width:${len}px;height:${len}px;`
                    )
                    setBubbleBasePoint(anchorPosition)
                }
            )
        },
        [commentService.positionService]
    )

    return (
        <>
            {createComment?.startPosition ? (
                <>
                    <Range ref={rangeRefCallback} dataTestId={CommentDataTestId.CommentRangeDraft} />
                    <Dot
                        ref={dotRefCallback}
                        dragMoveBasePoint={dotBasePoint}
                        onMoving={createCommentMinorAnchorMoving}
                        onMoveEnd={createCommentMinorAnchorMoveEnd}
                        onMouseMove={(e) => onMinorAnchorMouseMove(e.nativeEvent)}
                        onPointerUp={(e) => onMinorAnchorPointerUp(e.nativeEvent)}
                        dataTestId={CommentDataTestId.CommentAnchorDraft}
                    />
                </>
            ) : null}
            <DragMoveBox
                ref={bubbleRefCallback}
                className={classes.simpleBubble}
                dragMoveBasePoint={bubbleBasePoint}
                onMoving={createCommentAnchorMoving}
                onMoveEnd={createCommentAnchorMoveEnd}
                onMouseMove={(e) => onAnchorMouseMove(e.nativeEvent)}
                onPointerUp={(e) => onAnchorPointerUp(e.nativeEvent)}
                dataTestId={CommentDataTestId.CommentBubbleDraft}
            />
        </>
    )
}
