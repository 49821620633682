import { IDBPDatabase, IDBPObjectStore, IDBPTransaction } from 'idb'
import { IndexedDBName, IndexedDBName2DBSchema } from '../config'
import { enhanceIDBSet, handleIndexedDBError } from '../utils'
import { enhanceObjectStore } from './store'
import { enhanceTransaction } from './transaction'

/**
 * 增强版本的 IDBPDatabase 实例
 * proxy 了一些写入方法以实现超限重试和 metric 的能力
 *  - add
 *  - createObjectStore
 * @param db
 * @returns
 */
export const enhanceDB = <T extends IndexedDBName>(
    db: IDBPDatabase<IndexedDBName2DBSchema[T]>
): IDBPDatabase<IndexedDBName2DBSchema[T]> => {
    if (!db) {
        return db
    }

    const handleErrorFn = async () => {
        handleIndexedDBError(`${db.name} failed to open!`)
    }

    // 为 db 添加一些基础的错误提示
    db.onerror = handleErrorFn
    db.onabort = handleErrorFn

    return new Proxy(db, {
        get: (target: IDBPDatabase<IndexedDBName2DBSchema[T]>, key: keyof IDBPDatabase<IndexedDBName2DBSchema[T]>) => {
            const method = target[key]

            if (typeof method === 'function') {
                return (...args: any[]) => {
                    // @ts-expect-error
                    const methodWithArgs = method.bind(target, ...args)

                    switch (key) {
                        case 'add':
                        case 'put': {
                            return enhanceIDBSet(methodWithArgs, ...args)
                        }
                        case 'createObjectStore': {
                            return enhanceObjectStore(methodWithArgs() as IDBPObjectStore<IndexedDBName2DBSchema[T]>)
                        }
                        case 'transaction': {
                            try {
                                return enhanceTransaction(
                                    methodWithArgs() as IDBPTransaction<IndexedDBName2DBSchema[T]>
                                )
                            } catch (error) {
                                handleIndexedDBError(`${db.name} failed to transaction!`)
                                throw error
                            }
                        }
                        default: {
                            return methodWithArgs()
                        }
                    }
                }
            } else {
                return method
            }
        },
    })
}
