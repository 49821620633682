import classnames from 'classnames'
import { useRef } from 'react'
import ReactDOM from 'react-dom'
import {
    areaUnion,
    DropdownDefaultCustomNode,
    DropdownNoTriggerMultiLevelProps,
    DropdownV2,
    Tooltip,
} from '../../../../ui-lib/src'
import { featureSwitchManager } from '../../kernel/switch/core'
import classes from './index.module.less'
import { translation } from './index.translation'

export interface HelpCenterV2Props {
    items: DropdownNoTriggerMultiLevelProps<DropdownDefaultCustomNode & { handler?: () => void }>['items']
    openState?: boolean
    onChangeOpenState?: (nextOpenState: boolean) => void
}
export const HelpCenterV2 = (props: HelpCenterV2Props) => {
    const { items, openState, onChangeOpenState } = props
    const helpElementRef = useRef<HTMLDivElement>(null)

    return (
        <>
            {ReactDOM.createPortal(
                <Tooltip title={translation('HelpAndResources')} inactivation={openState}>
                    <div style={{ display: 'flex' }}>
                        <div
                            className={classnames(classes.questionHelp, {
                                [classes.bgWhite]: featureSwitchManager.isEnabled('bg-white-help-icon'),
                            })}
                            style={{ marginTop: 3 }}
                            data-testid="help-center"
                            onClick={() => onChangeOpenState?.(!openState)}
                            onMouseDown={(e) => e.preventDefault()}
                            ref={helpElementRef}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path
                                    transform="matrix(1 0 0 1 10.9004 18)"
                                    d="M0.75 0C1.16421 0 1.5 0.335786 1.5 0.75C1.5 1.16421 1.16421 1.5 0.75 1.5C0.335786 1.5 0 1.16421 0 0.75C0 0.335786 0.335786 0 0.75 0Z"
                                    fillRule="nonzero"
                                    fill="currentColor"
                                />
                                <path
                                    transform="matrix(1 0 0 1 8 5.49951)"
                                    d="M-0.5 3.93092L-0.5 3.44828Q-0.5 2.64346 -0.129423 1.89923Q0.222353 1.19276 0.854742 0.64601Q1.4804 0.105073 2.28021 -0.192319Q3.1077 -0.5 3.99997 -0.5Q6.15627 -0.5 7.37252 0.662832Q7.93642 1.20197 8.22559 1.93959Q8.49995 2.63941 8.49995 3.44826Q8.49995 4.31664 8.10083 5.07024Q7.73265 5.76544 7.05145 6.31186Q6.75655 6.55404 5.97369 6.95044Q5.49035 7.19518 5.26126 7.33162Q4.89716 7.54847 4.66695 7.76378Q4.13631 8.26009 4.13634 8.96551L4.13634 10L3.13634 10L3.13634 8.96555Q3.13629 7.82617 3.98387 7.03344Q4.29283 6.74448 4.74957 6.47246Q5.00806 6.31851 5.52195 6.05829Q6.20684 5.7115 6.42573 5.53181Q7.49994 4.67014 7.49995 3.44826Q7.49995 2.16817 6.68147 1.38563Q5.75515 0.5 3.99997 0.5Q3.28759 0.5 2.62873 0.744984Q1.99798 0.979512 1.50877 1.40248Q1.02811 1.81805 0.765743 2.34497Q0.5 2.87866 0.5 3.44828L0.5 3.93092L-0.5 3.93092Z"
                                    fillRule="nonzero"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                    </div>
                </Tooltip>,
                areaUnion.getHelpArea()
            )}
            <DropdownV2.NoTriggerMultiLevel
                removeMask
                placement="top right"
                items={items}
                triggerRect={() => helpElementRef.current?.getBoundingClientRect()!}
                isOpenState={openState}
                openStateToBeFalse={() => onChangeOpenState?.(false)}
                openStateToBeTrue={() => onChangeOpenState?.(true)}
                onChange={(v) => v.customNode?.handler?.()}
                dataTestIds={{ container: 'wk-help-center-menu' }}
                pickRest={{ firstPopUpScrollProps: { className: 'wk-help-center-menu' } }}
            />
        </>
    )
}
