import { GetLineHeightCommand, RGBAtoCode, Wukong } from '@wukong/bridge-proto'
import type { DeepRequired } from '../../../../../../../util/src'
import { isNil } from 'lodash-es'
import { ColorMode, RGB } from '../../../../../document/node/node'
import type { HandlerProvider } from '../../../../../main/handler-provider/handler-provider'
import { toFixed } from '../../../../utils/to-fixed'
import { float2hex, rgb2hex, rgb2hsl, rgb2hsv } from '../../../design/blend/color-picker/utils/color-translate'
import { CommandInvoker } from '../../../../../document/command/command-invoker'
import { CommitType } from '../../../../../document/command/commit-type'

export function rgb2css(r: number, g: number, b: number, a?: number): string {
    r = Math.round(r)
    g = Math.round(g)
    b = Math.round(b)
    return !isNil(a) ? `rgba(${r},${g},${b},${a})` : `rgb(${r},${g},${b})`
}

export const getColorValue = (colorMode: ColorMode, rgb: RGB, opacity?: number | null) => {
    const formatOpacity = toFixed(opacity, 2)
    let result
    switch (colorMode) {
        case ColorMode.HEX: {
            result = (
                '#' +
                rgb2hex(rgb.r, rgb.g, rgb.b) +
                (formatOpacity === 1 ? '' : float2hex(formatOpacity * 255))
            ).toUpperCase()
            break
        }
        case ColorMode.RGB: {
            result = rgb2css(rgb.r, rgb.g, rgb.b, formatOpacity)
            break
        }
        case ColorMode.CSS: {
            result = rgb2css(rgb.r, rgb.g, rgb.b, formatOpacity)
            break
        }
        case ColorMode.HSL: {
            // 模式切换不会改变色相
            const { h, s, l } = rgb2hsl(rgb.r / 255, rgb.g / 255, rgb.b / 255)
            result = `hsla(${toFixed(h, 0)},${toFixed(s * 100, 2)}%, ${toFixed(l * 100, 2)}%, ${formatOpacity})`
            break
        }
        case ColorMode.HSB: {
            // 模式切换不会改变色相
            const { h, s, v } = rgb2hsv(rgb.r / 255, rgb.g / 255, rgb.b / 255)
            result = `hsba(${toFixed(h, 0)},${toFixed(s * 100, 2)}%, ${toFixed(v * 100, 2)}%, ${formatOpacity})`
            break
        }
    }
    return result ? result.split(',').join(', ') : ''
}

export const getColorValueV2 = (
    cmd: CommandInvoker,
    colorMode: Wukong.DocumentProto.DevAndReadonlyColorMode,
    rgb: RGB,
    opacity?: number
) => {
    return (
        cmd.invokeBridge(
            CommitType.Noop,
            RGBAtoCode,
            Wukong.DocumentProto.Arg_RGBAtoCode.create({
                rgb: rgb,
                a: opacity ?? 1,
                mode: colorMode as unknown as Wukong.DocumentProto.DevAndReadonlyColorMode,
            })
        )?.codes?.[0] ?? ''
    )
}

export function tranformColorMode(colorMode: ColorMode): string {
    switch (colorMode) {
        case ColorMode.HEX:
            return 'Hex'
        case ColorMode.CSS:
            return 'CSS'
        case ColorMode.HSB:
            return 'HSB'
        case ColorMode.HSL:
            return 'HSL'
        case ColorMode.RGB:
            return 'RGB'
        default:
            return 'Hex'
    }
}

export function tranformColorModeV2(colorMode: Wukong.DocumentProto.DevAndReadonlyColorMode): string {
    switch (colorMode) {
        case Wukong.DocumentProto.DevAndReadonlyColorMode.DEV_AND_READONLY_COLOR_MODE_H_E_X:
            return 'Hex'
        case Wukong.DocumentProto.DevAndReadonlyColorMode.DEV_AND_READONLY_COLOR_MODE_R_G_B:
            return 'RGB'
        case Wukong.DocumentProto.DevAndReadonlyColorMode.DEV_AND_READONLY_COLOR_MODE_H_S_L:
            return 'HSL'
        case Wukong.DocumentProto.DevAndReadonlyColorMode.DEV_AND_READONLY_COLOR_MODE_H_S_B:
            return 'HSB'
        case Wukong.DocumentProto.DevAndReadonlyColorMode.DEV_AND_READONLY_COLOR_MODE_U_I_COLOR:
            return 'UIColor'
        case Wukong.DocumentProto.DevAndReadonlyColorMode.DEV_AND_READONLY_COLOR_MODE_A_R_G_B:
            return 'ARGB'
        case Wukong.DocumentProto.DevAndReadonlyColorMode.DEV_AND_READONLY_COLOR_MODE_COMPOSE:
            return 'Compose'
        case Wukong.DocumentProto.DevAndReadonlyColorMode.DEV_AND_READONLY_COLOR_MODE_SWIFT_U_I:
            return 'SwiftUI'
        case Wukong.DocumentProto.DevAndReadonlyColorMode.DEV_AND_READONLY_COLOR_MODE_DO_NOT_USE_ME_ZERO: {
            throw new Error(
                'Not implemented yet: DevAndReadonlyColorMode.DEV_AND_READONLY_COLOR_MODE_DO_NOT_USE_ME_ZERO case'
            )
        }
    }
}

export function computeLineHeight(
    handler: HandlerProvider,
    lineHeight: DeepRequired<Wukong.DocumentProto.ILineHeight>,
    fontName: DeepRequired<Wukong.DocumentProto.IFontName>,
    fontSize: number
) {
    let result = lineHeight.value
    if (lineHeight.unit === Wukong.DocumentProto.NumberUnit.NUMBER_UNIT_AUTO) {
        const lineHeightValue = handler.handle(
            (context, params) => {
                return context.bridge.call(GetLineHeightCommand, params)
            },
            { fontName: fontName, fontSize: fontSize }
        ).value
        if (lineHeightValue) {
            result = lineHeightValue
        }
    } else if (lineHeight.unit === Wukong.DocumentProto.NumberUnit.NUMBER_UNIT_PERCENT) {
        result = (fontSize * lineHeight.value) / 100
    }
    return result
}
