import classnames from 'classnames'
import { HTMLAttributes } from 'react'
import { Badge, WKIconButton as IconButton, MonoIconPanelAdjust16 as IconPanelAdjust } from '../../../../../ui-lib/src'
import { useAppContext } from '../../../main/app-context'
import { useViewState } from '../../../view-state-bridge'
import { Title } from '../atom/title/title'
import styles from './local-variable.module.less'
import { translation } from './local-variable.translation'
import { useEditorOpened, useVariablesCount } from './use-view-state-hook'

export function LocalVariable(props: HTMLAttributes<HTMLDivElement>) {
    const docReadonly = useViewState('docReadonly')
    if (docReadonly) {
        return null
    }
    return (
        <div data-testid="local-variable-panel" className={classnames('pt-8px pb-8px', props.className)}>
            <LocalVariablePanel />
        </div>
    )
}

function LocalVariablePanel() {
    const service = useAppContext().variableService.localVariableEditorService
    const popupOpened = useEditorOpened()
    const totalVariablesCount = useVariablesCount()
    return (
        <>
            <Title
                data-testid="local-variable-panel-title"
                grayTheme={totalVariablesCount === 0 && !popupOpened}
                onClick={service.popupToggle}
            >
                <Title.Left>
                    {translation('LocalVariableTitle')}
                    {totalVariablesCount > 0 ? (
                        <Badge.Number
                            number={totalVariablesCount}
                            color="gray"
                            className={styles['badge-count']}
                            data-testid="local-variable-panel-title-tag"
                        ></Badge.Number>
                    ) : null}
                </Title.Left>
                <Title.Right>
                    <IconButton
                        icon={<IconPanelAdjust />}
                        type={popupOpened ? 'deepBlue' : 'secondary'}
                        data-testid="local-variable-panel-icon"
                    />
                </Title.Right>
            </Title>
        </>
    )
}
