/* eslint-disable no-restricted-imports */
import { SetCurrentPageWasmCall, Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { FC, useEffect, useMemo, useState } from 'react'
import { IconCheckboxChecked, ScrollView } from '../../../../../../../../../ui-lib/src'
import { usePagePanelService } from '../../../../../../../main/app-context'
import { useViewState } from '../../../../../../../view-state-bridge'
import { EditorDataTestId } from '../../../../../../../window'
import { useCommand } from '../../../../../../context/document-context'
import { RightClickMenuWrapper } from '../../../../../right-click-menu-wrapper'
import styles from './index.module.less'
import VPageItem = Wukong.DocumentProto.VPageItem
import SetCurrentPageCommandParam = Wukong.DocumentProto.SetCurrentPageCommandParam

export const PagePanelItemList: FC<{ items: VPageItem[] }> = ({ items }) => {
    return (
        <div className={styles['page-panel-item-list']} data-testid="page-container">
            {items.map((item, index) => (
                <PagePanelItem key={item.id} item={item} pageIndex={index} />
            ))}
        </div>
    )
}

export const PagePanelItem: FC<{
    item: VPageItem
    pageIndex: number
}> = (props) => {
    const { item } = props

    const command = useCommand()

    const rightClickMenuState = useViewState('rightClickMenuState')
    const [itemRightClickState, setItemRightClickState] = useState<boolean>(false)
    const pagePanelService = usePagePanelService()

    const onChangeRightClickState = () => {
        pagePanelService.setLastSelectedPageForRightClick(item.id)
        setItemRightClickState(true)
    }
    useEffect(() => {
        if (!rightClickMenuState?.opened) {
            setItemRightClickState(false)
        }
    }, [rightClickMenuState])
    const enableRightPopOpenColor = useMemo<boolean>(() => {
        return !!rightClickMenuState?.opened && itemRightClickState
    }, [rightClickMenuState, itemRightClickState])

    const handleSelect = () => {
        // 通过 cmd 设置 currentPage
        command.DEPRECATED_invokeBridge(
            SetCurrentPageWasmCall,
            SetCurrentPageCommandParam.create({
                nodeId: item.id,
            })
        )
        command.commitUndo()
    }

    return (
        <RightClickMenuWrapper
            source={Wukong.DocumentProto.RightClickSource.RIGHT_CLICK_SOURCE_DEV_MODE_PAGE_PANEL}
            onMouseDown={onChangeRightClickState}
            stopPropagation
            dataTestId={EditorDataTestId.PagePanelContextMenu}
        >
            <ScrollView.Item
                uniqueKey={undefined}
                isSelectFn={() => item.isSelected}
                data-testid={`page-item:${props.pageIndex}`}
                className={classNames(
                    styles['page-panel-item'],
                    enableRightPopOpenColor && styles['page-panel-item-right-click']
                )}
                onClick={handleSelect}
            >
                <div className={styles['check-icon-container']}>
                    {item.isSelected ? (
                        <IconCheckboxChecked data-testid={`page-item-selected:${props.pageIndex}`} />
                    ) : null}
                </div>
                <div className={styles.doubleClickInputContainer}>
                    <div
                        data-testid="normal-value"
                        className={classNames(styles.normalValue, item.isSelected ? styles.strong : undefined)}
                    >
                        {item.name}
                    </div>
                </div>
            </ScrollView.Item>
        </RightClickMenuWrapper>
    )
}
