import { useViewState } from '../../../view-state-bridge'
import { ReactComponent as LeftArrowDisableSvg } from './assets/dev-mode-navigator-left-arrow-disable.svg'
import { ReactComponent as LeftArrowSvg } from './assets/dev-mode-navigator-left-arrow.svg'
import { ReactComponent as ReturnArrowSvg } from './assets/dev-mode-navigator-return-arrow.svg'
import { ReactComponent as RightArrowDisableSvg } from './assets/dev-mode-navigator-right-arrow-disable.svg'
import { ReactComponent as RightArrowSvg } from './assets/dev-mode-navigator-right-arrow.svg'
import styles from './navigator.module.less'
import { translation } from './navigator.translation'

import { DevModeZoomToFitSelectionWasmCall, SwitchDeliveryUnitByIndex, Wukong } from '@wukong/bridge-proto'
import { useCallback } from 'react'
import ReactDOM from 'react-dom'
import { areaUnion } from '../../../../../ui-lib/src'
import { useCommand } from '../../app-context'

function SwitchButton(props: { index: number; total: number }) {
    const commandInvoker = useCommand()

    const switchToPrev = useCallback(() => {
        commandInvoker.DEPRECATED_invokeBridge(
            SwitchDeliveryUnitByIndex,
            Wukong.DocumentProto.BridgeProtoInt.create({ value: props.index - 1 })
        )
    }, [commandInvoker, props.index])

    const switchToNext = useCallback(() => {
        commandInvoker.DEPRECATED_invokeBridge(
            SwitchDeliveryUnitByIndex,
            Wukong.DocumentProto.BridgeProtoInt.create({ value: props.index + 1 })
        )
    }, [commandInvoker, props.index])

    return (
        <>
            <div data-testid="navigator-switch-button">
                <div className={styles.SwitchButton}>
                    {props.total != 1 && (
                        <div
                            data-testid="navigator-switch-left-arrow-container"
                            className={styles.LeftArrowContainer}
                            onClick={switchToPrev}
                        >
                            {props.index == 0 ? (
                                <LeftArrowDisableSvg data-testid="navigator-switch-left-arrow-disable" />
                            ) : (
                                <LeftArrowSvg data-testid="navigator-switch-left-arrow" />
                            )}
                        </div>
                    )}

                    <span>{props.index + 1 + ' / ' + props.total}</span>

                    {props.total != 1 && (
                        <div
                            data-testid="navigator-switch-right-arrow-container"
                            className={styles.RightArrowContainer}
                            onClick={switchToNext}
                        >
                            {props.index + 1 == props.total ? (
                                <RightArrowDisableSvg data-testid="navigator-switch-right-arrow-disable" />
                            ) : (
                                <RightArrowSvg data-testid="navigator-switch-right-arrow" />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

function RecenterButton() {
    const commandInvoker = useCommand()

    const switchToCurrent = useCallback(() => {
        commandInvoker.DEPRECATED_invokeBridge(DevModeZoomToFitSelectionWasmCall)
    }, [commandInvoker])

    return (
        <>
            <div>
                <div className={styles.ReturnButton} data-testid="navigator-recenter-button" onClick={switchToCurrent}>
                    <div className={styles.PlainContainer}>
                        <ReturnArrowSvg />
                    </div>

                    <span className={styles.MiddleSpan}>{translation('Re-center')}</span>

                    <div>
                        <div className={styles.RightSpan}>⇧2</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function DeliveryUnitNavigator() {
    const selectedUnitInfo = useViewState('devModeSelectedSwitchableDeliveryUnitViewState') ?? {
        index: -1,
        total: -1,
        shouldRecenter: false,
        show: false,
    }
    const show = selectedUnitInfo.show

    return (
        <>
            {show &&
                (selectedUnitInfo.shouldRecenter
                    ? ReactDOM.createPortal(<RecenterButton />, areaUnion.getPageTurnerArea())
                    : ReactDOM.createPortal(
                          <SwitchButton index={selectedUnitInfo.index} total={selectedUnitInfo.total}></SwitchButton>,
                          areaUnion.getPageTurnerArea()
                      ))}
        </>
    )
}
