/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import React from 'react'
import { SimpleDrag, SimpleDragProps } from '../../../../../../../../ui-lib/src'
import { ColorStop, ImagePaint, Paint, RGB, Transform } from '../../../../../../document/node/node'
import { InputOptionsForUndoSquash } from '../../../../atom/inputs/components/formatted-input'
import { ColorSpace } from '../../../blend/color-picker/utils/color-translate'
import { ColorStylePaint } from '../../../paint/color-style-paint'

export interface PaintStyleData {
    id: string
    name: string
    description: string
    paints: Paint[]
    variables?: Wukong.DocumentProto.ISingleVariable[]
}

export interface PaintStylePanelListProps {
    paintStyleData: PaintStyleData
    colorSpace: ColorSpace
    selectPaintIndexList?: number[]
    isRemoteStyle?: boolean
    onSelectChange: SimpleDragProps<Paint>['onSelectChange']
    onDragDone: SimpleDragProps<Paint>['onDragDone']
    isOpenPaintPanel: (paintIndex: number) => boolean
    onChangeModalVisible: (paintIndex: number, v: boolean) => void
    onChangeColor: (paintIndex: number, v: RGB, options?: InputOptionsForUndoSquash) => void
    onChangeOpacity: (paintIndex: number, v: number, options?: InputOptionsForUndoSquash) => void
    onChangeVisible: (paintIndex: number, v: boolean) => void
    onChangeBlendMode: (paintIndex: number, v: Wukong.DocumentProto.BlendMode) => void
    onChangeImagePaint: (paintIndex: number, v: ImagePaint) => void
    onChangePaintType: (
        paintIndex: number,
        v: Wukong.DocumentProto.PaintType,
        paint: Wukong.DocumentProto.IPaint | undefined | null
    ) => void
    onClickSub: (paintIndex: number) => void
    onChangeColorStops: (paintIndex: number, v: ColorStop[]) => void
    onChangeTransform: (paintIndex: number, v: Transform) => void
    onChangeColorVar: (paintIndex: number, v: Wukong.DocumentProto.IVariableAliasData) => void
    onDetachColorVar: (paintIndex: number) => void
}

export function PaintStylePanelList<T extends PaintStylePanelListProps = PaintStylePanelListProps>(props: T) {
    const { paintStyleData, colorSpace, isRemoteStyle, selectPaintIndexList = [] } = props

    const paintsForUI = paintStyleData.paints.slice().reverse()
    const selectPaintIndexListForUI = selectPaintIndexList.map((i) => paintsForUI.length - 1 - i)

    const onSelectChangeForUI = (v: number[], e: React.MouseEvent) => {
        props.onSelectChange?.(
            v.map((i) => paintsForUI.length - 1 - i),
            e
        )
    }
    const onDragDoneForUI = (items: Paint[], indexes: number[]) => {
        const newItems = items.slice().reverse()
        props.onDragDone?.(
            newItems,
            indexes.map((index) => newItems.length - 1 - index)
        )
    }
    const isOpenPaintPanel = (paintIndex: number) => {
        return props.isOpenPaintPanel(paintsForUI.length - 1 - paintIndex)
    }
    const onChangeModalVisible = (index: number) => (visible: boolean) => {
        props.onChangeModalVisible(paintsForUI.length - 1 - index, visible)
    }
    const onChangeColor = (index: number) => (color: RGB, options?: InputOptionsForUndoSquash) => {
        props.onChangeColor(paintsForUI.length - 1 - index, color, options)
    }
    const onChangeColorStops = (index: number) => (v: ColorStop[]) => {
        props.onChangeColorStops(paintsForUI.length - 1 - index, v)
    }
    const onChangeOpacity = (index: number) => (opacity: number, options?: InputOptionsForUndoSquash) => {
        props.onChangeOpacity(paintsForUI.length - 1 - index, opacity, options)
    }
    const onChangeVisible = (index: number) => (visible: boolean) => {
        props.onChangeVisible(paintsForUI.length - 1 - index, visible)
    }
    const onChangeImagePaint = (index: number) => (data: ImagePaint) => {
        props.onChangeImagePaint(paintsForUI.length - 1 - index, data)
    }
    const onChangePaintType =
        (index: number) =>
        (paintType: Wukong.DocumentProto.PaintType, paint: Wukong.DocumentProto.IPaint | undefined | null) => {
            props.onChangePaintType(paintsForUI.length - 1 - index, paintType, paint)
        }
    const onChangeBlendMode = (index: number) => (value: Wukong.DocumentProto.BlendMode) => {
        props.onChangeBlendMode(paintsForUI.length - 1 - index, value)
    }
    const onChangeTransform = (index: number) => (transform: Transform) => {
        props.onChangeTransform(paintsForUI.length - 1 - index, transform)
    }
    const onClickDelete = (index: number) => () => {
        props.onClickSub(paintsForUI.length - 1 - index)
    }
    const onChangeColorVar = (index: number) => (colorVar: Wukong.DocumentProto.IVariableAliasData) => {
        props.onChangeColorVar(paintsForUI.length - 1 - index, colorVar)
    }
    const onDetachColorVar = (index: number) => () => {
        props.onDetachColorVar(paintsForUI.length - 1 - index)
    }

    return (
        <SimpleDrag
            selectedIndexList={selectPaintIndexListForUI}
            onSelectChange={onSelectChangeForUI}
            items={paintsForUI}
            onDragDone={onDragDoneForUI}
            disabled={isRemoteStyle}
            onMouseDown={(e) => e.stopPropagation()}
            // 避免触发可拖拽弹窗的拖拽
            data-disabled-drag-move={'true'}
        >
            {paintsForUI.map((paint, index) => (
                <SimpleDrag.Item
                    key={index}
                    itemIndex={index}
                    dataTestIds={{ item: `paint-style-panel-paint-${index}` }}
                >
                    <ColorStylePaint
                        key={index}
                        selected={selectPaintIndexListForUI.includes(index)}
                        paint={paint}
                        colorSpace={colorSpace}
                        variables={paintStyleData.variables}
                        isOpen={isOpenPaintPanel(index)}
                        onChangeModalVisible={onChangeModalVisible(index)}
                        onChangeColor={onChangeColor(index)}
                        onChangeOpacity={onChangeOpacity(index)}
                        onChangeVisible={onChangeVisible(index)}
                        onChangeBlendMode={onChangeBlendMode(index)}
                        onChangeImagePaint={onChangeImagePaint(index)}
                        onChangePaintType={onChangePaintType(index)}
                        onClickSub={onClickDelete(index)}
                        onChangeColorStops={onChangeColorStops(index)}
                        onChangeTransform={onChangeTransform(index)}
                        isMini={true}
                        disabled={isRemoteStyle}
                        onChangeColorVar={onChangeColorVar(index)}
                        onDetachColorVar={onDetachColorVar(index)}
                    />
                </SimpleDrag.Item>
            ))}
        </SimpleDrag>
    )
}
