import { FeatureSwitchConfig, SwitchEnvironmentScopeType, SwitchStrategyType } from '../feature-switch-config'

const Config: FeatureSwitchConfig = {
    name: 'focus-view',
    owner: '',
    description: '',
    strategies: [
        {
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                userList: ['ba359f8f7cabb59bea6a1c75fceeffdef1f40827'],
            },
            env: SwitchEnvironmentScopeType.ALL,
        },
        // {
        //     config: {
        //         type: SwitchStrategyType.SPECIFIC_USER_LIST,
        //         group: UserGroupType.MOTIFF_GROUP,
        //     },
        //     env: SwitchEnvironmentScopeType.ALL,
        // },
        // {
        //     config: {
        //         type: SwitchStrategyType.SPECIFIC_USER_LIST,
        //         group: UserGroupType.CYPRESS,
        //     },
        //     env: SwitchEnvironmentScopeType.ALL,
        // },
    ],
    alwaysDisableInTests: true,
}
export default Config
