import { Wukong } from '@wukong/bridge-proto'
import { useMemo, useRef, useState } from 'react'
import { MonoIconCommonClose16 } from '../../../../../../../ui-lib/src'
import { DraggablePopupV2 } from '../../../../../../../ui-lib/src/components/draggable-popup'
import { Position } from '../../../../../../../ui-lib/src/components/draggable-popup/util'
import { InputV2 } from '../../../../../../../ui-lib/src/components/inputs'
import { Select } from '../../../../../../../ui-lib/src/components/selects'
import { WKIconButton } from '../../../../../../../ui-lib/src/components/wk-button/icon-button'
import { ToKeyCode } from '../../../../../document/util/keycode'
import { useLibraryComponentService } from '../../../../../main/app-context'
import { KeyboardReceiver } from '../../../../../main/keyboard-receiver/component'
import { LibraryResourceOssClientType } from '../../../../../share/component-style-library/service/library-resource-downloader'
import { useViewState } from '../../../../../view-state-bridge'
import { LibraryAll, LibraryLocal, useEditorContext } from '../../../../context/editor-context'
import {
    filterLocalPrimitiveCollections,
    filterRemotePrimitiveLibraries,
    formatLocalPrimitiveCollections,
    formatRemotePrimitiveLibraries,
    getRequiredLocalPrimitiveCollections,
    getRequiredRemotePrimitiveLibraries,
    isSelectedSingleVariableInLocalOrRemote,
    PrimitiveVariableVirtualItem,
    SelectableVariableItem,
    SelectedSingleVariable,
    uidGenerator,
} from '../common'
import { PrimitiveVariableList, PrimitiveVariableListRef } from './primitive-variable-list'
import { translation } from './primitive-variable-panel-base.translation'

export interface PrimitiveVariablePanelCommonProps {
    requiredScopes: Wukong.DocumentProto.VariableScope[]
    requiredTypes: Wukong.DocumentProto.PrimitiveVariableType[]
    position: Position
    positionRightBase?: boolean
    selectedSingleVariable?: SelectedSingleVariable
    onVariableSelected: (id: string, name: string) => void
    onCancel?: () => void
    header?: React.ReactNode
    hideVariableValue?: boolean // 为 true 时，不展示列表右侧的数值
}

/**
 * NOTE: 为了避免Panel和Creator文件之间的循环引用, 拆分出这个组件
 */
export const PrimitiveVariablePanelBase = (props: PrimitiveVariablePanelCommonProps) => {
    const {
        requiredScopes,
        requiredTypes,
        position,
        positionRightBase,
        selectedSingleVariable,
        onVariableSelected,
        onCancel,
        header,
        hideVariableValue,
    } = props
    const { localCollections, remoteLibraries } = useViewState('librariesPrimitiveVariable', {
        localCollections: [],
        remoteLibraries: [],
    })

    const [searchStr, setSearchStr] = useState<string>('')
    const onSearch = (value: string) => {
        setSearchStr(value)
        virtualListRef.current?.scrollToSelectedOption()
    }

    const requiredLocalCollections = useMemo(() => {
        return getRequiredLocalPrimitiveCollections(localCollections, requiredScopes, requiredTypes)
    }, [localCollections, requiredScopes, requiredTypes])

    const requiredRemoteLibraries = useMemo(() => {
        return getRequiredRemotePrimitiveLibraries(remoteLibraries, requiredScopes, requiredTypes)
    }, [remoteLibraries, requiredScopes, requiredTypes])

    const unknownSingleVariable = useMemo(() => {
        if (
            selectedSingleVariable &&
            !isSelectedSingleVariableInLocalOrRemote(
                requiredLocalCollections,
                requiredRemoteLibraries,
                selectedSingleVariable
            )
        ) {
            return selectedSingleVariable
        } else {
            return null
        }
    }, [requiredLocalCollections, requiredRemoteLibraries, selectedSingleVariable])
    const { libraryPreselected, setLibraryPreselected } = useEditorContext()
    const [selectedLibrary, setSelectedLibrary] = useState<string>(() => {
        if (!libraryPreselected) {
            return LibraryAll
        } else if (libraryPreselected === LibraryAll) {
            return LibraryAll
        } else if (libraryPreselected === LibraryLocal) {
            if (requiredLocalCollections.length) {
                return LibraryLocal
            } else {
                return LibraryAll
            }
        } else {
            if (requiredRemoteLibraries.find((lib) => lib.id === libraryPreselected)) {
                return libraryPreselected
            } else {
                return LibraryAll
            }
        }
    })

    const onSelectLibraryChange = (value: string) => {
        setSelectedLibrary(value)
        setLibraryPreselected(value)
    }

    const selectOptionLabel = useMemo(() => {
        if (selectedLibrary === LibraryAll) return translation('AllLibraries')
        if (selectedLibrary === LibraryLocal) return translation('LocalLibrary')
        return remoteLibraries.find((library) => library.id === selectedLibrary)?.name
    }, [selectedLibrary, remoteLibraries])

    const filteredLocalCollections = useMemo(() => {
        if (selectedLibrary === LibraryLocal || selectedLibrary === LibraryAll) {
            return filterLocalPrimitiveCollections(requiredLocalCollections, searchStr)
        } else {
            return []
        }
    }, [requiredLocalCollections, searchStr, selectedLibrary])

    const formatedLocalCollections = useMemo(() => {
        return formatLocalPrimitiveCollections(filteredLocalCollections)
    }, [filteredLocalCollections])

    const filteredRemoteLibraries = useMemo(() => {
        if (selectedLibrary === LibraryLocal) {
            return []
        } else if (selectedLibrary === LibraryAll) {
            return filterRemotePrimitiveLibraries(requiredRemoteLibraries, searchStr)
        } else {
            return filterRemotePrimitiveLibraries(
                requiredRemoteLibraries.filter((lib) => lib.id === selectedLibrary),
                searchStr
            )
        }
    }, [requiredRemoteLibraries, searchStr, selectedLibrary])

    const formatedRemoteLibraries = useMemo(() => {
        return formatRemotePrimitiveLibraries(filteredRemoteLibraries)
    }, [filteredRemoteLibraries])

    const { libraryNodeDataService } = useLibraryComponentService()
    const onItemSelected = async (item: SelectableVariableItem) => {
        switch (item.type) {
            case 'local-variable':
                onVariableSelected(item.data.variable.nodeId, item.data.variable.name)
                break
            case 'remote-variable': {
                const varId = await libraryNodeDataService.createRemoteVariableNode({
                    isLocal: false,
                    localNodeId: null,
                    ossClientType: LibraryResourceOssClientType.Variable,
                    remoteDocId: item.data.variable.documentId,
                    remoteNodeId: item.data.variable.nodeId,
                    nodeDataPath: item.data.variable.nodeDataPath ?? '',
                    key: item.data.variable.id,
                })
                if (varId) {
                    onVariableSelected(varId, item.data.variable.name)
                }
                break
            }
            case 'unkown-single-variable': {
                onVariableSelected(item.data.variable.id, item.data.variable.name)
                break
            }
        }
    }

    const virtualListRef = useRef<PrimitiveVariableListRef>(null)
    const virtualItems = useMemo(() => {
        const nextOptionIndex = uidGenerator()
        const items: PrimitiveVariableVirtualItem[] = []

        if (unknownSingleVariable) {
            items.push({
                type: 'unkown-single-variable',
                data: unknownSingleVariable,
                optionIndex: nextOptionIndex(),
                isSelected: selectedSingleVariable?.variable.id === unknownSingleVariable.variable.id,
            })
        }

        formatedLocalCollections.forEach((collection) => {
            items.push({
                type: 'collection',
                name: collection.collection.name,
            })

            collection.freeVariables.forEach((variable) => {
                items.push({
                    type: 'local-variable',
                    data: variable,
                    optionIndex: nextOptionIndex(),
                    isSelected: selectedSingleVariable?.variable.id === variable.variable.nodeId,
                })
            })

            collection.groups.forEach((group) => {
                items.push({
                    type: 'group',
                    name: group.name,
                })

                group.variables.forEach((variable) => {
                    items.push({
                        type: 'local-variable',
                        data: variable,
                        optionIndex: nextOptionIndex(),
                        isSelected: selectedSingleVariable?.variable.id === variable.variable.nodeId,
                    })
                })
            })
        })

        formatedRemoteLibraries.forEach((library) => {
            if (items.length > 0) {
                items.push({ type: 'divider' })
            }

            // NOTE: 没有指定筛选库时才展示library名称
            if (selectedLibrary === LibraryAll) {
                items.push({ type: 'library-name', name: library.name })
            }

            library.collections.forEach((collection) => {
                items.push({ type: 'collection', name: collection.collection.name })

                collection.freeVariables.forEach((variable) => {
                    items.push({
                        type: 'remote-variable',
                        data: variable,
                        optionIndex: nextOptionIndex(),
                        isSelected: selectedSingleVariable?.variable.key === variable.variable.id,
                    })
                })

                collection.groups.forEach((group) => {
                    items.push({ type: 'group', name: group.name })

                    group.variables.forEach((variable) => {
                        items.push({
                            type: 'remote-variable',
                            data: variable,
                            optionIndex: nextOptionIndex(),
                            isSelected: selectedSingleVariable?.variable.key === variable.variable.id,
                        })
                    })
                })
            })
        })

        return items
    }, [
        formatedLocalCollections,
        formatedRemoteLibraries,
        unknownSingleVariable,
        selectedSingleVariable,
        selectedLibrary,
    ])

    const onSearchKeyDown = (e: React.KeyboardEvent) => {
        if (e.nativeEvent.isComposing) {
            return
        }

        if (e.key === 'ArrowDown') {
            e.stopPropagation()
            e.preventDefault()
            virtualListRef.current?.nextOption()
        } else if (e.key === 'ArrowUp') {
            e.stopPropagation()
            e.preventDefault()
            virtualListRef.current?.prevOption()
        } else if (e.key === 'Enter') {
            e.stopPropagation()
            e.preventDefault()
            virtualListRef.current?.onSubmit()
        } else if (e.key === 'Escape') {
            e.stopPropagation()
            e.preventDefault()
            onCancel?.()
        }
    }

    return (
        <KeyboardReceiver keyCode={ToKeyCode.Esc} onKeydown={() => (onCancel?.(), false)}>
            <DraggablePopupV2
                testId="primitive-variable-panel"
                width={216}
                visible={true}
                closable={false}
                positionRightBase={positionRightBase}
                position={position}
                mask
                onCancel={onCancel}
                header={
                    header ?? (
                        <div className="flex items-center justify-between pl-16px pr-10px h-40px">
                            <div className="flex-1 wk-font-semibold text-black wk-text-12">{translation('Title')}</div>

                            <WKIconButton icon={<MonoIconCommonClose16 />} onClick={onCancel} />
                        </div>
                    )
                }
                bodyClassName="p-0"
                footer={null}
                closeTestId="primitive-variable-panel-close"
            >
                <div className="p-8px">
                    <InputV2.Search
                        onSearch={onSearch}
                        autoFocus
                        onKeyDown={onSearchKeyDown}
                        placeholder="搜索"
                        skipComposing
                    />
                </div>
                <div className="flex items-center pl-16px pr-10px h-32px">
                    <Select.MinimalSingleLevel
                        onChange={onSelectLibraryChange}
                        value={selectedLibrary}
                        label={selectOptionLabel}
                        maxWidth={460}
                    >
                        <Select.MinimalSingleLevel.Option key="all" value={LibraryAll}>
                            {translation('AllLibraries')}
                        </Select.MinimalSingleLevel.Option>
                        {requiredLocalCollections.length ? (
                            <Select.MinimalSingleLevel.Option key="local" value={LibraryLocal} splitLineTop>
                                {translation('LocalLibrary')}
                            </Select.MinimalSingleLevel.Option>
                        ) : null}
                        {requiredRemoteLibraries.map((library, index) => (
                            <Select.MinimalSingleLevel.Option
                                key={library.id}
                                value={library.id}
                                splitLineTop={index === 0}
                                tooltipTitle={library.name}
                            >
                                {library.name}
                            </Select.MinimalSingleLevel.Option>
                        ))}
                    </Select.MinimalSingleLevel>
                </div>
                {virtualItems.length ? (
                    <PrimitiveVariableList
                        ref={virtualListRef}
                        items={virtualItems}
                        onItemSelected={onItemSelected}
                        hideVariableValue={hideVariableValue}
                    />
                ) : (
                    <div
                        data-testid="primitive-variable-panel-not-found"
                        className="flex h-64px pb-8px items-center justify-center w-full wk-font-regular wk-text-12 text-gray-500"
                    >
                        {translation('NotFound')}
                    </div>
                )}
            </DraggablePopupV2>
        </KeyboardReceiver>
    )
}
