import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    NoResultsFor: '无「{{filterText}}」搜索结果',
    HideWhenPublishing: '隐藏的组件',
} as const

export const enTranslation = {
    NoResultsFor: "No results for '{{filterText}}'",
    HideWhenPublishing: 'Hidden',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
