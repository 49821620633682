import {
    AddVariableSetModeCommand,
    BatchUpdateVariablePublishHiddenCommand,
    BatchUpdateVariableScopesCommand,
    CommitUndo,
    CreateColorVariableCommand,
    CreateVariableSetCommand,
    DeleteVariableSetModeCommand,
    DuplicateVariableCommand,
    DuplicateVariableSetModeCommand,
    GenerateNewVariableNameForCollectionAndTypeCommand,
    GenerateNewVariableNameForCollectionCommand,
    GetLocalVariableCommand,
    InsertVariableBetweenCommand,
    LocalVariableEditor_AbortRenameVariableCommand,
    LocalVariableEditor_AddSelectedVariablesIntoGroupCommand,
    LocalVariableEditor_ClearGroupSelectionCommand,
    LocalVariableEditor_ClearVariableSelectionCommand,
    LocalVariableEditor_CloseVariableEditingPopupCommand,
    LocalVariableEditor_CollapseGroupCommand,
    LocalVariableEditor_CreateCollectionCommand,
    LocalVariableEditor_CreateCollectionModeCommand,
    LocalVariableEditor_CreateVariableCommand,
    LocalVariableEditor_DeleteCollectionCommand,
    LocalVariableEditor_DeleteCollectionModeCommand,
    LocalVariableEditor_DeleteSelectedGroupsCommand,
    LocalVariableEditor_DeleteSelectedVariablesCommand,
    LocalVariableEditor_DirectRenameVariableCommand,
    LocalVariableEditor_DragGroupCommand,
    LocalVariableEditor_DragSelectedVariablesCommand,
    LocalVariableEditor_DragSelectedVariablesIntoGroupCommand,
    LocalVariableEditor_DuplicateCollectionModeCommand,
    LocalVariableEditor_DuplicateSelectedGroupsCommand,
    LocalVariableEditor_DuplicateSelectedVariablesCommand,
    LocalVariableEditor_ExpandGroupCommand,
    LocalVariableEditor_ForceSelectGroupCommand,
    LocalVariableEditor_ForceSelectVariableCommand,
    LocalVariableEditor_GetCollectionCollapsedGroupKeysCommand,
    LocalVariableEditor_GetLatestSelectedCollectionCommand,
    LocalVariableEditor_MultiSelectGroupCommand,
    LocalVariableEditor_MultiSelectVariableCommand,
    LocalVariableEditor_RangeSelectGroupCommand,
    LocalVariableEditor_RangeSelectVariableCommand,
    LocalVariableEditor_RenameCollectionModeCommand,
    LocalVariableEditor_RenameGroupCommand,
    LocalVariableEditor_SafeSelectGroupCommand,
    LocalVariableEditor_SafeSelectVariableCommand,
    LocalVariableEditor_SelectCollectionCommand,
    LocalVariableEditor_SetCollectionCollapsedGroupKeysCommand,
    LocalVariableEditor_SetLatestSelectedCollectionCommand,
    LocalVariableEditor_StartRenameCollectionCommand,
    LocalVariableEditor_StartRenameVariableCommand,
    LocalVariableEditor_SubmitRenameCollectionCommand,
    LocalVariableEditor_SubmitRenameVariableCommand,
    LocalVariableEditor_TogglePanelCommand,
    LocalVariableEditor_ToggleVariableDetailPopupCommand,
    LocalVariableEditor_ToggleVariableValuePopupCommand,
    LocalVariableEditor_UngroupSelectedGroupsCommand,
    RemoveVariableCodeSyntaxCommand,
    RemoveVariableCommand,
    RemoveVariableSetCommand,
    RenameVariableCommand,
    RenameVariableSetCommand,
    SetAliasForVariableCommand,
    SetVariableSetModeNameCommand,
    UpdateColorVariableValueCommand,
    UpdateFloatVariableValueCommand,
    UpdateVariableCodeSyntaxCommand,
    UpdateVariableDescriptionCommand,
    Wukong,
} from '@wukong/bridge-proto'
// eslint-disable-next-line import/no-deprecated
import { BehaviorSubject, combineLatest, map, Subject, takeUntil } from 'rxjs'
import { WKToast } from '../../../../ui-lib/src'
import { createImmerStore, createSelectors, createStore } from '../../../../util/src'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { transaction } from '../../../../util/src/abort-controller/traceable-transaction'
import { CommandInvoker } from '../../document/command/command-invoker'
import { CommitType } from '../../document/command/commit-type'
import { IN_JEST_TEST } from '../../environment'
import { Bridge } from '../../kernel/bridge/bridge'
import { featureSwitchManager } from '../../kernel/switch/core'
import { isWindows } from '../../kernel/util/ua'
import { ColorInteractionFrom } from '../../ui/component/design/color-interaction/type'
import { ViewStateBridge } from '../../view-state-bridge/view-state-bridge'
import { LocalStorageKey } from '../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../web-storage/local-storage/storage'
import { SessionStorageKey } from '../../web-storage/session-storage/config'
import { enhancedSessionStorage } from '../../web-storage/session-storage/storage'
import { WK } from '../../window'
import { translation } from './local-variable-editor-service.translation'

export const ALL_GROUP_LABEL = ''

// 虚拟滚动
const BUFFER_HEIGHT = 100
const ROW_HEIGHT = 36
const LABEL_HEIGHT = 52

export interface LocalVariableGroup {
    displayName: string // 展示的组名，不包含路径
    groupName: string // 完整的组名，包含路径
    groupIndent: number // 在列表中的展示缩进
    variables: Wukong.DocumentProto.ILocalVariable[] // 组中的变量
    subGroups: LocalVariableGroup[] // 组下的子组
    groupIndex: number // 组的排序
    collapsed: boolean // 是否折叠
}

export type LocalVariableTableItem =
    | {
          type: 'group'
          key: string
          groupName: string
      }
    | {
          type: 'variable'
          key: string
          variable: Wukong.DocumentProto.ILocalVariable
      }

function fetchDataFromSessionStorage(): Record<string, string[]> {
    const data = enhancedSessionStorage.getItem(SessionStorageKey.VariableLocalEditorSidebarCollapsedGroups)
    if (!data) {
        return {}
    }
    try {
        const parsedData = JSON.parse(data)
        return Object.keys(parsedData).reduce((acc, collectionId) => {
            acc[collectionId] = Array.isArray(parsedData[collectionId]) ? parsedData[collectionId] : []
            return acc
        }, {} as Record<string, string[]>)
    } catch (e) {
        return {}
    }
}

export class LocalVariableEditorService {
    /**
     * zustand store
     */

    stateStore = createSelectors(
        createStore<{
            // 弹框是否打开
            popupOpened: boolean
            // 是否正在复制变量
            renameVariableState: string | null
            // 是否正在重命名变量合集
            renameCollectionState: boolean
            // 是否正在编辑颜色
            editingColorState: {
                variableId: string
                modeId: string
                position: { left: number; top: number }
                openFrom: ColorInteractionFrom.LOCAL_VARIABLE_EDIT | ColorInteractionFrom.LOCAL_VARIABLE_CREATE_ALIAS
            } | null
            // 编辑变量弹窗是否打开
            selectedVariablesEditorOpened: { left: number; top: number } | null
        }>(() => ({
            popupOpened: false,
            renameVariableState: null,
            renameCollectionState: false,
            editingColorState: null,
            selectedVariablesEditorOpened: null,
        }))
    )
    dataStore = createSelectors(
        createImmerStore<{
            // 所有变量
            variablesCount: number
            // 所有变量合集
            collectionList: Wukong.DocumentProto.ILocalVariableSet[]
            // 有效的选中合集
            selectedCollection: Wukong.DocumentProto.ILocalVariableSet | null
            // 选中的变量合集中的变量总数
            selectedCollectionVariablesCount: number
            // 有效的选中分组
            selectedGroups: LocalVariableGroup[]
            // 有效的选中变量
            selectedVariables: Wukong.DocumentProto.ILocalVariable[]
            // 侧边栏中展示的分组数据，且已平铺开
            groupsList: LocalVariableGroup[]
            // 表格中展示的分组数据，且已平铺开
            visibleItemsInTable: {
                items: LocalVariableTableItem[]
                totalHeight: number | string
                totalWidth: number
                offsetTop: number
            }
        }>(() => ({
            variablesCount: 0,
            collectionList: [],
            selectedCollection: null,
            selectedCollectionVariablesCount: 0,
            selectedGroups: [],
            selectedVariables: [],
            groupsList: [],
            visibleItemsInTable: {
                items: [],
                totalHeight: 0,
                totalWidth: 0,
                offsetTop: 0,
            },
        }))
    )

    /**
     * private states
     */

    private _lastNonShiftSelectState: {
        variableId: string
        previousSelection: string[]
    } | null = null
    private _lastNonShiftSelectGroup: {
        groupKey: string
        previousSelection: string[]
    } | null = null

    /**
     * rxjs subjects
     */

    private _variables$ = new BehaviorSubject<Wukong.DocumentProto.ILocalVariable[]>([])
    private _collections$ = new BehaviorSubject<Wukong.DocumentProto.ILocalVariableSet[]>([])
    private _selectedCollectionId$ = new BehaviorSubject<string | null>(
        enhancedLocalStorage.getSerializedItem(LocalStorageKey.LastSelectedVariableSetId)
    )
    private _selectedGroupKeys$ = new BehaviorSubject<string[]>([])
    private _selectedVariableIds$ = new BehaviorSubject<string[]>([])
    private _collectionToCollapsedGroupKeys$ = new BehaviorSubject<Record<string, string[]>>(
        fetchDataFromSessionStorage()
    )
    private _tableScrollTop$ = new BehaviorSubject<number>(0)
    private _tableContainerHeight$ = new BehaviorSubject<{ width: number; height: number }>({ width: 0, height: 0 })

    /**
     * rxjs observables
     */

    private _selectedCollection$ = combineLatest([this._selectedCollectionId$, this._collections$]).pipe(
        // eslint-disable-next-line import/no-deprecated
        map(([selectedCollectionId, collections]) => {
            if (!collections.length) {
                return null
            }
            if (selectedCollectionId) {
                const collection = collections.find((col) => col.id === selectedCollectionId)
                if (collection) {
                    return collection
                }
            }
            return collections[0]
        })
    )

    private _variablesInCollection: Wukong.DocumentProto.ILocalVariable[] = []
    private _variablesInCollection$ = combineLatest([this._selectedCollection$, this._variables$]).pipe(
        // eslint-disable-next-line import/no-deprecated
        map(([selectedCollection, variables]) => {
            return selectedCollection
                ? variables.filter((variable) => variable.variableSetId === selectedCollection.id)
                : []
        })
    )

    private _groupedVariablesInCollection: Record<string, LocalVariableGroup> = {}
    private _groupedVariablesInCollection$ = this._variablesInCollection$.pipe(
        // eslint-disable-next-line import/no-deprecated
        map((variablesInCollection) => {
            const groupedVariables = {
                [ALL_GROUP_LABEL]: {
                    displayName: ALL_GROUP_LABEL,
                    groupName: ALL_GROUP_LABEL,
                    groupIndent: 0,
                    variables: [],
                    subGroups: [],
                    groupIndex: 0,
                    collapsed: false,
                },
            } as Record<string, LocalVariableGroup>
            variablesInCollection.forEach((variable) => {
                const path = getPath(variable.name)
                if (!path) {
                    // 没有分组，加到全部
                    groupedVariables[ALL_GROUP_LABEL].variables.push(variable)
                } else {
                    // 有分组，依次创建每个分组
                    walkDownPath(variable.name, (curSeg, curPath, parentPath) => {
                        let group
                        if (groupedVariables[curPath]) {
                            group = groupedVariables[curPath]
                        } else {
                            const newGroup = {
                                displayName: curSeg,
                                groupName: curPath,
                                groupIndent: curPath.split('/').length - 1,
                                variables: [],
                                subGroups: [],
                                groupIndex: -1, // -1 表示没有计算
                                collapsed: false,
                            }
                            groupedVariables[curPath] = newGroup
                            groupedVariables[parentPath].subGroups.push(newGroup)
                            group = newGroup
                        }
                        if (curPath === path) {
                            group.variables.push(variable)
                        }
                    })
                }
            })
            Object.values(groupedVariables).forEach((group) => {
                // 将分组内的变量排序
                group.variables = group.variables.sort((a, b) => a.position - b.position)
                // 再递归计算分组的序号
                const updateGroupIndex = (_group: LocalVariableGroup): number => {
                    if (_group.groupIndex !== -1) {
                        return _group.groupIndex
                    }
                    if (_group.variables.length > 0) {
                        return (_group.groupIndex = _group.variables[0].position)
                    }
                    if (_group.subGroups.length > 0) {
                        return (_group.groupIndex = Math.min(..._group.subGroups.map(updateGroupIndex)))
                    }
                    return (_group.groupIndex = 0)
                }
                updateGroupIndex(group)
            })
            // 将分组内的子组排序
            Object.values(groupedVariables).forEach((group) => {
                group.subGroups = group.subGroups.sort((a, b) => a.groupIndex - b.groupIndex)
            })
            return groupedVariables
        })
    )

    private _selectedGroups$ = combineLatest([this._selectedGroupKeys$, this._groupedVariablesInCollection$]).pipe(
        // eslint-disable-next-line import/no-deprecated
        map(([selectedGroupKeys, groupedVariablesInCollection]) => {
            const groups = selectedGroupKeys
                .map((groupKey) => groupedVariablesInCollection[groupKey])
                .filter((group) => !!group)
            if (!groups.length) {
                return [groupedVariablesInCollection[ALL_GROUP_LABEL]]
            }
            return groups
        })
    )

    private _collapsedGroupKeys$ = combineLatest([
        this._selectedCollection$,
        this._collectionToCollapsedGroupKeys$,
    ]).pipe(
        // eslint-disable-next-line import/no-deprecated
        map(([collection, collectionToCollapsedGroupKeys]) => {
            if (!collection) {
                return []
            }
            return collectionToCollapsedGroupKeys[collection.id] || []
        })
    )

    private _groupsList$ = combineLatest([this._groupedVariablesInCollection$, this._collapsedGroupKeys$]).pipe(
        // eslint-disable-next-line import/no-deprecated
        map(([groupedVariablesInCollection, collapsedGroupKeys]) => {
            const groups: LocalVariableGroup[] = []
            const walkDownGroup = (group: LocalVariableGroup) => {
                const isCollapsed = collapsedGroupKeys.includes(group.groupName)
                groups.push({ ...group, collapsed: isCollapsed })
                if (!isCollapsed) {
                    group.subGroups.sort((a, b) => a.groupIndex - b.groupIndex).forEach(walkDownGroup)
                }
            }
            walkDownGroup(groupedVariablesInCollection[ALL_GROUP_LABEL])
            return groups
        })
    )

    private _groupedVariablesInTable: LocalVariableTableItem[] = []
    private _groupedVariablesInTable$ = combineLatest([this._selectedGroups$, this._groupsList$]).pipe(
        // eslint-disable-next-line import/no-deprecated
        map(([selectedGroups, groupsList]) => {
            const selectedGroupNameSet = new Set(selectedGroups.map((group) => group.groupName))
            // 按左侧分组列表的顺序展示
            const sortedGroups = groupsList.filter((group) => selectedGroupNameSet.has(group.groupName))
            // 按深度优先遍历生成最终的数组
            const flatGroups: LocalVariableGroup[] = []
            // 因为父子分组可能同时选中，所以要去重，一旦选中子组则跳过
            const flattedGroupKey: Set<string> = new Set()
            const walkDownGroup = (group: LocalVariableGroup) => {
                if (flattedGroupKey.has(group.groupName)) {
                    return
                }
                flatGroups.push(group)
                flattedGroupKey.add(group.groupName)
                group.subGroups.forEach(walkDownGroup)
            }
            sortedGroups.forEach(walkDownGroup)

            const items: LocalVariableTableItem[] = []
            flatGroups.forEach((group) => {
                // 没有变量和子组，跳过
                if (!group.variables.length) {
                    return
                }
                const showGroupLabel =
                    // 全部分组不展示
                    group.groupName !== ALL_GROUP_LABEL &&
                    // 只选中当前分组不展示
                    !(sortedGroups.length === 1 && sortedGroups[0].groupName === group.groupName)
                if (showGroupLabel) {
                    items.push({
                        type: 'group',
                        key: group.groupName,
                        groupName: group.groupName,
                    })
                }
                group.variables.forEach((variable) => {
                    items.push({
                        type: 'variable',
                        key: `${variable.name}-${variable.id}`,
                        variable,
                    })
                })
                // 因为已经 flat 过，所以不再考虑 subGroup
            })
            return items
        })
    )

    private _visibleItemsInTable$ = combineLatest([
        this._groupedVariablesInTable$,
        this._tableScrollTop$,
        this._tableContainerHeight$,
    ]).pipe(
        // eslint-disable-next-line import/no-deprecated
        map(([groupedVariablesInTable, tableScrollTop, tableContainerRect]) => {
            if (IN_JEST_TEST) {
                return {
                    items: groupedVariablesInTable,
                    totalHeight: '100%',
                    totalWidth: tableContainerRect.width,
                    offsetTop: 0,
                }
            }
            let _tableHeight = ROW_HEIGHT // header height
            let _offsetTop = 0
            let _intoView = false
            const _visibleItems: LocalVariableTableItem[] = []
            groupedVariablesInTable.forEach((item) => {
                const _height = item.type === 'group' ? LABEL_HEIGHT : ROW_HEIGHT
                _tableHeight += _height
                if (
                    _tableHeight >= tableScrollTop - BUFFER_HEIGHT &&
                    _tableHeight <= tableScrollTop + tableContainerRect.height + BUFFER_HEIGHT
                ) {
                    _visibleItems.push(item)
                    _intoView = true
                }
                if (!_intoView) {
                    _offsetTop += _height
                }
            })
            return {
                items: _visibleItems,
                totalHeight: _tableHeight,
                totalWidth: tableContainerRect.width,
                offsetTop: _offsetTop,
            }
        })
    )

    private _selectedVariables$ = combineLatest([this._selectedVariableIds$, this._variablesInCollection$]).pipe(
        // eslint-disable-next-line import/no-deprecated
        map(([selectedVariableIds, variablesInCollection]) => {
            return variablesInCollection.filter((variable) => selectedVariableIds.includes(variable.id))
        })
    )

    constructor(
        _signal: TraceableAbortSignal,
        _bridge: Bridge,
        private readonly _commandInvoker: CommandInvoker,
        private readonly _viewStateBridge: ViewStateBridge
    ) {
        const { act } = transaction(_signal)

        act('[LocalVariableEditorService] register to wukong', () => {
            WK.variableLocalEditor = this
            return () => {
                delete WK.variableLocalEditor
            }
        })

        act('[LocalVariableEditorService] init bridge bindings', () => {
            _bridge.bind(LocalVariableEditor_SetLatestSelectedCollectionCommand, (value) => {
                enhancedLocalStorage.setSerializedItem(LocalStorageKey.LastSelectedVariableSetId, value.value ?? '')
            })
            _bridge.bind(LocalVariableEditor_GetLatestSelectedCollectionCommand, () => {
                return {
                    value: enhancedLocalStorage.getSerializedItem(LocalStorageKey.LastSelectedVariableSetId),
                }
            })
            _bridge.bind(LocalVariableEditor_SetCollectionCollapsedGroupKeysCommand, (param) => {
                const currentCache = enhancedLocalStorage.getSerializedItem(
                    LocalStorageKey.CollectionCollapsedGroupKeys
                )
                const nextCache = {
                    ...currentCache,
                    [param.collectionId]: param.collapsedGroupKeys,
                }
                enhancedLocalStorage.setSerializedItem(LocalStorageKey.CollectionCollapsedGroupKeys, nextCache)
            })
            _bridge.bind(LocalVariableEditor_GetCollectionCollapsedGroupKeysCommand, (param) => {
                const currentCache = enhancedLocalStorage.getSerializedItem(
                    LocalStorageKey.CollectionCollapsedGroupKeys
                )
                return {
                    collectionId: param.value!,
                    collapsedGroupKeys: currentCache?.[param.value!] ?? [],
                }
            })
            return () => {
                _bridge.unbind(LocalVariableEditor_SetLatestSelectedCollectionCommand)
                _bridge.unbind(LocalVariableEditor_GetLatestSelectedCollectionCommand)
                _bridge.unbind(LocalVariableEditor_SetCollectionCollapsedGroupKeysCommand)
                _bridge.unbind(LocalVariableEditor_GetCollectionCollapsedGroupKeysCommand)
            }
        })
    }

    // 通过 viewState 同步到的全部变量与变量合集数据
    connectDocument = (): (() => void) | undefined => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            return
        }

        const destroy$ = new Subject<void>()

        this._variables$.pipe(takeUntil(destroy$)).subscribe((variables) => {
            this.dataStore.setState({
                variablesCount: variables.length,
            })
        })

        this._collections$.pipe(takeUntil(destroy$)).subscribe((collections) => {
            this.dataStore.setState({
                collectionList: collections,
            })
        })

        this._variablesInCollection$.pipe(takeUntil(destroy$)).subscribe((variablesInCollection) => {
            this.dataStore.setState({
                selectedCollectionVariablesCount: variablesInCollection.length,
            })
        })

        this._selectedCollection$.pipe(takeUntil(destroy$)).subscribe((selectedCollection) => {
            this.dataStore.setState({
                selectedCollection,
            })
        })

        this._variablesInCollection$.pipe(takeUntil(destroy$)).subscribe((variablesInCollection) => {
            this._variablesInCollection = variablesInCollection
        })

        this._groupsList$.pipe(takeUntil(destroy$)).subscribe((groupsList) => {
            this.dataStore.setState({
                groupsList,
            })
        })

        this._selectedGroups$.pipe(takeUntil(destroy$)).subscribe((selectedGroups) => {
            this.dataStore.setState({
                selectedGroups,
            })
        })

        this._groupedVariablesInCollection$.pipe(takeUntil(destroy$)).subscribe((groupedVariablesInCollection) => {
            this._groupedVariablesInCollection = groupedVariablesInCollection
        })

        this._groupedVariablesInTable$.pipe(takeUntil(destroy$)).subscribe((groupedVariablesInTable) => {
            this._groupedVariablesInTable = groupedVariablesInTable
        })

        this._visibleItemsInTable$.pipe(takeUntil(destroy$)).subscribe((visibleItemsInTable) => {
            this.dataStore.setState({
                visibleItemsInTable,
            })
        })

        this._selectedVariables$.pipe(takeUntil(destroy$)).subscribe((selectedVariables) => {
            this.dataStore.setState({
                selectedVariables,
            })
        })

        const syncViewState = (viewState: Wukong.DocumentProto.IVariableLocalEditorState) => {
            this._variables$.next(viewState.variables.sort((a, b) => a.position - b.position))
            this._collections$.next(viewState.variableSets.sort((a, b) => a.name.localeCompare(b.name)))
        }
        this._viewStateBridge.register('variableLocalEditorState', syncViewState)

        return () => {
            destroy$.next()
            destroy$.complete()

            this._viewStateBridge.unregister('variableLocalEditorState', syncViewState)
        }
    }

    /**
     * user actions
     */

    // 打开关闭弹框
    popupToggle = () => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_TogglePanelCommand)
        } else {
            const nextState = !this.stateStore.getState().popupOpened
            this.stateStore.setState({
                popupOpened: nextState,
            })

            // 打开时，如果没有合集，则创建一个
            if (nextState) {
                if (!this.dataStore.getState().collectionList.length) {
                    this.createCollection(false)
                }
            }

            // 关闭时，清空编辑状态，清空分组和变量选中状态
            if (!nextState) {
                this.clearGroupSelection()
                this.clearVariableSelection()
                this.clearPopupState()
                // 重置虚拟滚动状态
                this._tableScrollTop$.next(0)
                this._tableContainerHeight$.next({ width: 0, height: 0 })
            }
        }
    }

    // 重置弹框的打开状态
    clearPopupState = () => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_CloseVariableEditingPopupCommand)
        } else {
            this.stateStore.setState({
                editingColorState: null,
                selectedVariablesEditorOpened: null,
            })
        }
    }
    // 选择变量合集
    selectCollection = (variableSetId: string | null) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_SelectCollectionCommand, {
                value: variableSetId,
            })
        } else {
            this._selectedCollectionId$.next(variableSetId)
            enhancedLocalStorage.setSerializedItem(LocalStorageKey.LastSelectedVariableSetId, variableSetId ?? '')
            // 切换合集后，清空选中的变量
            this.clearVariableSelection()
            // 清空编辑状态
            this.clearPopupState()
        }
        // 重置滚动位置为 0
        this._tableScrollTop$.next(0)
    }

    // 选择分组
    private _rangeSelectGroup = (groupKey: string) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_RangeSelectGroupCommand, {
                value: groupKey,
            })
        } else {
            if (!this._lastNonShiftSelectGroup) {
                return
            }
            const rangeEndPoint = this._lastNonShiftSelectGroup.groupKey
            const selectedIds = new Set(this._lastNonShiftSelectGroup.previousSelection)
            let shouldConclude = false
            this.dataStore.getState().groupsList.forEach((group) => {
                if (group.groupName === groupKey || group.groupName === rangeEndPoint) {
                    selectedIds.add(group.groupName)
                    shouldConclude = !shouldConclude
                }
                if (shouldConclude) {
                    selectedIds.add(group.groupName)
                }
            })
            this._selectedGroupKeys$.next(Array.from(selectedIds))
        }
    }
    private _multiSelectGroup = (groupKey: string) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_MultiSelectGroupCommand, {
                value: groupKey,
            })
        } else {
            const selecedKeys = new Set(this._selectedGroupKeys$.getValue())
            if (!selecedKeys.has(groupKey)) {
                selecedKeys.add(groupKey)
            } else {
                selecedKeys.delete(groupKey)
            }
            this._lastNonShiftSelectGroup = {
                groupKey,
                previousSelection: Array.from(selecedKeys),
            }
            this._selectedGroupKeys$.next(Array.from(selecedKeys))
        }
    }
    private _safeSelectGroup = (groupKey: string) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_SafeSelectGroupCommand, {
                value: groupKey,
            })
        } else {
            const selecedKeys = new Set(this._selectedGroupKeys$.getValue())
            if (!selecedKeys.has(groupKey)) {
                selecedKeys.clear()
                selecedKeys.add(groupKey)
            }
            this._lastNonShiftSelectGroup = {
                groupKey,
                previousSelection: Array.from(selecedKeys),
            }
            this._selectedGroupKeys$.next(Array.from(selecedKeys))
        }
    }
    private _forceSelectGroup = (groupKey: string) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_ForceSelectGroupCommand, {
                value: groupKey,
            })
        } else {
            this._lastNonShiftSelectGroup = {
                groupKey,
                previousSelection: [groupKey],
            }
            this._selectedGroupKeys$.next([groupKey])
        }
    }
    mouseDownToSelectGroup = (groupKey: string, e: React.MouseEvent) => {
        if (groupKey === ALL_GROUP_LABEL) {
            this.clearGroupSelection()
            return
        }
        if (e.shiftKey) {
            this._rangeSelectGroup(groupKey)
        } else if (e.metaKey || (isWindows() && e.ctrlKey)) {
            this._multiSelectGroup(groupKey)
        } else {
            this._safeSelectGroup(groupKey)
        }
        // 切换分组后，清空选中的变量
        this.clearVariableSelection()
    }
    clickToSelectGroup = (groupKey: string, e: React.MouseEvent) => {
        if (e.shiftKey || e.metaKey || (isWindows() && e.ctrlKey)) {
            return
        }
        this._forceSelectGroup(groupKey)
    }
    clearGroupSelection = () => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_ClearGroupSelectionCommand)
        } else {
            this._selectedGroupKeys$.next([])
            this._lastNonShiftSelectGroup = null
            // 切换分组后，清空选中的变量
            this.clearVariableSelection()
        }
    }

    // 连选
    private _rangeSelectTo = (variableId: string) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_RangeSelectVariableCommand, {
                value: variableId,
            })
        } else {
            if (!this._lastNonShiftSelectState) {
                return
            }
            const variableListInTable = this._groupedVariablesInTable
                .filter((item) => item.type === 'variable')
                .map((item) => item.variable)
            const rangeEndPoint = this._lastNonShiftSelectState.variableId
            const selectedIds = new Set(this._lastNonShiftSelectState.previousSelection)
            let shouldConclude = false
            variableListInTable.forEach((variable) => {
                if (variable.id === variableId || variable.id === rangeEndPoint) {
                    selectedIds.add(variable.id)
                    shouldConclude = !shouldConclude
                }
                if (shouldConclude) {
                    selectedIds.add(variable.id)
                }
            })
            this._selectedVariableIds$.next(Array.from(selectedIds))
        }
    }
    // 多选
    private _multiSelect = (variableId: string) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_MultiSelectVariableCommand, {
                value: variableId,
            })
        } else {
            const selectedIds = new Set(this._selectedVariableIds$.getValue())
            if (!selectedIds.has(variableId)) {
                selectedIds.add(variableId)
            } else {
                selectedIds.delete(variableId)
            }
            this._lastNonShiftSelectState = {
                variableId,
                previousSelection: Array.from(selectedIds),
            }
            this._selectedVariableIds$.next(Array.from(selectedIds))
        }
    }
    // 单选，但已经选中时不修改选区
    private _safeSingleSelect = (variableId: string) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_SafeSelectVariableCommand, {
                value: variableId,
            })
        } else {
            const selectedIds = new Set(this._selectedVariableIds$.getValue())
            if (!selectedIds.has(variableId)) {
                selectedIds.clear()
                selectedIds.add(variableId)
            }
            this._lastNonShiftSelectState = {
                variableId,
                previousSelection: Array.from(selectedIds),
            }
            this._selectedVariableIds$.next(Array.from(selectedIds))
        }
    }
    // 单选，只保留当前选中
    private _forceSelectVariable = (variableId: string) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_ForceSelectVariableCommand, {
                value: variableId,
            })
        } else {
            this._lastNonShiftSelectState = {
                variableId,
                previousSelection: [variableId],
            }
            this._selectedVariableIds$.next([variableId])
        }
    }
    mouseDownToSelectVariable = (variableId: string, e: React.MouseEvent) => {
        if (e.shiftKey) {
            this._rangeSelectTo(variableId)
        } else if (e.metaKey || (isWindows() && e.ctrlKey)) {
            this._multiSelect(variableId)
        } else {
            this._safeSingleSelect(variableId)
        }
    }
    clickToSelectVariable = (variableId: string, e: React.MouseEvent) => {
        if (e.shiftKey || e.metaKey || (isWindows() && e.ctrlKey)) {
            return
        }
        this._forceSelectVariable(variableId)
    }
    clearVariableSelection = () => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_ClearVariableSelectionCommand)
        } else {
            this._selectedVariableIds$.next([])
            this._lastNonShiftSelectState = null
        }
    }

    // 创建变量合集
    createCollection = (shouldAutoRename = true) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_CreateCollectionCommand)
        } else {
            // 会默认生成一个名称
            const newCollectionName = generateName(
                new Set(this.dataStore.getState().collectionList.map((col) => col.name)),
                translation('默认合集名称')
            )
            const newCollectionId = this._commandInvoker.invokeBridge(CommitType.CommitUndo, CreateVariableSetCommand, {
                name: newCollectionName,
            }).value!
            // 创建后会自动选中这个合集
            this.selectCollection(newCollectionId)
            // 自动进入重命名状态
            if (shouldAutoRename) {
                this.stateStore.setState({
                    renameCollectionState: true,
                })
            }
            return newCollectionId
        }
    }

    // 删除变量合集
    removeCollection = () => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_DeleteCollectionCommand)
        } else {
            const selectedCollection = this.dataStore.getState().selectedCollection
            if (!selectedCollection) {
                return
            }
            this._commandInvoker.invokeBridge(CommitType.CommitUndo, RemoveVariableSetCommand, {
                value: selectedCollection.id,
            })
        }
    }

    // 重命名变量合集
    renameCollection = () => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_StartRenameCollectionCommand)
        } else {
            const selectedCollection = this.dataStore.getState().selectedCollection
            if (!selectedCollection) {
                return
            }
            this.stateStore.setState({
                renameCollectionState: true,
            })
        }
    }
    renameCollectionSubmit = (newName: string) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(
                CommitType.CommitUndo,
                LocalVariableEditor_SubmitRenameCollectionCommand,
                {
                    value: newName,
                }
            )
        } else {
            // 退出重命名状态
            this.stateStore.setState({
                renameCollectionState: false,
            })
            const currentCollection = this.dataStore.getState().selectedCollection
            if (!currentCollection) {
                return
            }
            newName = formatName(newName)
            // 名称为空，不提交
            if (newName === '') {
                return
            }
            // 名称不变，不提交
            if (currentCollection.name === newName) {
                return
            }
            this._commandInvoker.invokeBridge(CommitType.CommitUndo, RenameVariableSetCommand, {
                variableSetId: currentCollection.id,
                name: newName,
            })
        }
    }

    getNewVariableNameInCollection = (collectionId: string) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            return this._commandInvoker.invokeBridge(CommitType.Noop, GenerateNewVariableNameForCollectionCommand, {
                value: collectionId,
            }).value!
        } else {
            const collection = this.dataStore.getState().collectionList.find((c) => c.id === collectionId)
            if (!collection) {
                return translation('默认颜色变量名称')
            }
            const variablesInThisCollection = this._variables$
                .getValue()
                .filter((v) => v.variableSetId === collectionId)
            return generateName(
                new Set(variablesInThisCollection.map((v) => getName(v.name))),
                translation('默认颜色变量名称')
            )
        }
    }

    getNewVariableNameInCollectionAndType = (
        collectionId: string,
        type: Wukong.DocumentProto.VariableResolvedDataType
    ) => {
        return this._commandInvoker.invokeBridge(CommitType.Noop, GenerateNewVariableNameForCollectionAndTypeCommand, {
            collectionId,
            type,
        }).value!
    }

    // 创建变量
    createVariable = (type: Wukong.DocumentProto.VariableResolvedDataType) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_CreateVariableCommand, {
                type,
            })
        } else {
            if (type !== Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR) {
                console.warn('暂不支持创建颜色变量之外其它类型的变量')
                return
            }
            const getTargetCollectionId = () => {
                const selectedCollection = this.dataStore.getState().selectedCollection
                if (!selectedCollection) {
                    // 没有选中的合集，则先要创建一个
                    const collectionId = this.createCollection(false)
                    return collectionId
                }
                return selectedCollection.id
            }
            // 得到当前的合集 id
            const targetCollectionId = getTargetCollectionId()
            if (!targetCollectionId) {
                return
            }
            const firstGroup = this.dataStore.getState().selectedGroups[0] // 选中分组始终存在，多选时创建在第一个分组下
            const newVariableName = generateName(
                new Set(firstGroup.variables.map((variable) => getName(variable.name))),
                translation('默认颜色变量名称')
            )
            const newVariableFullName = joinName([firstGroup.groupName, newVariableName])
            const newVariableId = this._commandInvoker.invokeBridge(CommitType.Noop, CreateColorVariableCommand, {
                name: newVariableFullName,
                variableSetId: targetCollectionId,
                colorValue: { r: 255, g: 255, b: 255, a: 255 }, // 创建新变量的默认颜色值
            }).value
            if (!newVariableId) {
                return
            }
            // 将新变量插入到分组最后一个变量之后，此时要计算包含子孙变量在内的最大值
            // NOTE: [https://wkong.atlassian.net/browse/WK-41797] 有个报错，应该是 groupPostIndex = -1 导致的。当 group 中无变量（选中全部且没有任何变量时）时，groupPostIndex 应该为 0
            let groupPostIndex = firstGroup.groupIndex
            walkDownGroupVariables(firstGroup, (variable) => {
                groupPostIndex = Math.max(groupPostIndex, variable.position)
            })
            this._commandInvoker.invokeBridge(CommitType.Noop, InsertVariableBetweenCommand, {
                variableId: newVariableId,
                preIndex: groupPostIndex,
            })
            // 执行一次 batch commit
            this._commandInvoker.invokeBridge(CommitType.Noop, CommitUndo)
            // 创建后选中变量，并自动进入重命名状态
            this.clearVariableSelection()
            this._selectedVariableIds$.next([newVariableId])
            this.renameVariable(newVariableId)
        }
    }

    // 打开编辑变量弹窗
    startEditVariable = (variableId: string, position: { left: number; top: number }) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_ToggleVariableDetailPopupCommand, {
                position: { left: position.left, top: position.top },
                editingVariableId: variableId,
            })
        } else {
            if (!this.dataStore.getState().selectedVariables.length) {
                return
            }
            this.stateStore.setState({
                editingColorState: null,
                selectedVariablesEditorOpened: position,
            })
        }
    }

    // 关闭编辑变量弹窗
    endEditVariable = () => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_CloseVariableEditingPopupCommand)
        } else {
            this.stateStore.setState({
                selectedVariablesEditorOpened: null,
            })
        }
    }

    // 编辑变量弹窗修改名称
    editorChangeName = (variable: Wukong.DocumentProto.ILocalVariable, newName: string) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_DirectRenameVariableCommand, {
                idOrKey: variable.id,
                newName,
            })
            return
        } else {
            newName = formatName(newName)
            if (newName === '') {
                return
            }
            if (newName === getName(variable.name)) {
                return
            }
            if (containInvalidChars(newName)) {
                WKToast.error(translation('变量名称格式错误'), { dataTestIds: { toast: 'variable-name-invalid' } })
                return
            }
            this._renameVariable(variable, newName)
        }
    }

    // 编辑变量弹窗修改描述
    editorChangeDescription = (variable: Wukong.DocumentProto.ILocalVariable, value: string) => {
        this._commandInvoker.invokeBridge(CommitType.CommitUndo, UpdateVariableDescriptionCommand, {
            variableId: variable.id,
            description: value,
        })
    }

    // 编辑变量弹窗修改代码语法
    editorChangeCodeSyntax = (
        variableId: string,
        platform: Wukong.DocumentProto.VariableCodePlatform,
        value: string
    ) => {
        this._commandInvoker.invokeBridge(CommitType.CommitUndo, UpdateVariableCodeSyntaxCommand, {
            variableId,
            platform,
            value,
        })
    }

    // 编辑变量弹窗删除代码语法
    editorRemoveCodeSyntax = (variableId: string, platform: Wukong.DocumentProto.VariableCodePlatform) => {
        this._commandInvoker.invokeBridge(CommitType.CommitUndo, RemoveVariableCodeSyntaxCommand, {
            variableId,
            platform,
        })
    }

    // 编辑变量弹窗修改是否发布时隐藏
    editorChangePublishHidden = (params: Wukong.DocumentProto.IArg_UpdateVariablePublishHiddenCommandParam[]) => {
        this._commandInvoker.invokeBridge(CommitType.CommitUndo, BatchUpdateVariablePublishHiddenCommand, {
            params,
        })
    }

    // 编辑变量弹窗修改 scopes
    editorChangeScopes = (params: Wukong.DocumentProto.IArg_UpdateVariableScopesCommandParam[]) => {
        this._commandInvoker.invokeBridge(CommitType.CommitUndo, BatchUpdateVariableScopesCommand, {
            params,
        })
    }

    // 打开关闭值编辑弹框
    toggleValueEditingPopup = (
        type: Wukong.DocumentProto.LocalVariableEditorEditingType,
        variableId: string,
        modeId: string,
        position: { left: number; top: number }
    ) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_ToggleVariableValuePopupCommand, {
                type,
                position,
                editingVariableId: variableId,
                editingModeId: modeId,
            })
        } else {
            const state = this.stateStore.getState().editingColorState
            if (state && state.variableId === variableId && state.modeId === modeId) {
                this.closeVariableEditingPopup()
                return
            }
            // v1 版本仅支持颜色变量
            const openFrom = {
                [Wukong.DocumentProto.LocalVariableEditorEditingType.LOCAL_VARIABLE_EDITOR_EDITING_TYPE_NONE]: null,
                [Wukong.DocumentProto.LocalVariableEditorEditingType.LOCAL_VARIABLE_EDITOR_EDITING_TYPE_DETAIL]: null,
                [Wukong.DocumentProto.LocalVariableEditorEditingType.LOCAL_VARIABLE_EDITOR_EDITING_TYPE_COLOR_VALUE]:
                    ColorInteractionFrom.LOCAL_VARIABLE_EDIT,
                [Wukong.DocumentProto.LocalVariableEditorEditingType
                    .LOCAL_VARIABLE_EDITOR_EDITING_TYPE_COLOR_VALUE_ALIAS]:
                    ColorInteractionFrom.LOCAL_VARIABLE_CREATE_ALIAS,
                [Wukong.DocumentProto.LocalVariableEditorEditingType
                    .LOCAL_VARIABLE_EDITOR_EDITING_TYPE_NUMBER_VALUE_ALIAS]: null,
            }[type] as
                | ColorInteractionFrom.LOCAL_VARIABLE_EDIT
                | ColorInteractionFrom.LOCAL_VARIABLE_CREATE_ALIAS
                | null
            if (!openFrom) {
                return
            }
            this.stateStore.setState({
                editingColorState: {
                    variableId,
                    modeId,
                    position,
                    openFrom,
                },
                selectedVariablesEditorOpened: null,
            })
        }
    }
    closeVariableEditingPopup = () => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_CloseVariableEditingPopupCommand)
        } else {
            this.stateStore.setState({ editingColorState: null })
        }
    }

    // 更新变量纯色值
    updateColorVariableValue = (
        variableId: string,
        variableSetModeId: string,
        rgba: Wukong.DocumentProto.IRGBA,
        commitType: CommitType = CommitType.CommitUndo
    ) => {
        return this._commandInvoker.invokeBridge(commitType, UpdateColorVariableValueCommand, {
            variableId,
            variableSetModeId,
            colorRGBAValue: rgba,
        })
    }

    // 更新数值变量值
    updateFloatVariableValue = (variableId: string, variableSetModeId: string, floatValue: number) => {
        return this._commandInvoker.invokeBridge(CommitType.CommitUndo, UpdateFloatVariableValueCommand, {
            variableId,
            variableSetModeId,
            floatValue,
        })
    }

    // 更新变量引用
    setAliasForVariable = (variableId: string, variableSetModeId: string, aliasVariableId: string) => {
        const ret = this._commandInvoker.invokeBridge(CommitType.CommitUndo, SetAliasForVariableCommand, {
            variableId,
            variableSetModeId,
            aliasVariableId,
        })
        switch (ret.code) {
            case Wukong.DocumentProto.RetCode_SetAliasForVariableCommand
                .RET_CODE__SET_ALIAS_FOR_VARIABLE_COMMAND_CANNOT_SET_ALIAS_DUE_TO_CIRCULAR_DEPENDENCIES:
                WKToast.error(translation('变量不能被循环引用'))
                return
            case Wukong.DocumentProto.RetCode_SetAliasForVariableCommand
                .RET_CODE__SET_ALIAS_FOR_VARIABLE_COMMAND_CANNOT_SET_ALIAS_FOR_SELF:
                WKToast.error(translation('变量不能引用自身'))
                return
            default:
                console.warn(Wukong.DocumentProto.RetCode_SetAliasForVariableCommand[ret.code])
        }
    }
    detachAliasForVariable = (variable: Wukong.DocumentProto.ILocalVariable, variableSetModeId: string) => {
        // TODO(variable): 这里可以改成 wcc 实现，而不是在 js 组合调用
        if (featureSwitchManager.isEnabled('float-variable')) {
            switch (variable.dataType) {
                case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR:
                    this.updateColorVariableValue(
                        variable.id,
                        variableSetModeId,
                        variable.dataValues[variableSetModeId].resolvedValue.colorValue
                    )
                    break
                case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT:
                    this.updateFloatVariableValue(
                        variable.id,
                        variableSetModeId,
                        variable.dataValues[variableSetModeId].resolvedValue.floatValue
                    )
                    break
                default:
                    break
            }
        } else {
            this.updateColorVariableValue(
                variable.id,
                variableSetModeId,
                variable.dataValues[variableSetModeId].resolvedValue.colorValue
            )
        }
    }

    // 复制变量
    duplicateSelectedVariables = () => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(
                CommitType.CommitUndo,
                LocalVariableEditor_DuplicateSelectedVariablesCommand
            )
        } else {
            const selectedVariables = this.dataStore.getState().selectedVariables
            if (!selectedVariables.length) {
                return
            }
            // 将多选的变量按组名划分，对每组处理
            const groupedVariablesInSelection = selectedVariables.reduce((groupedVariable, variable) => {
                const groupName = getPath(variable.name)
                groupedVariable[groupName] = groupedVariable[groupName] || []
                groupedVariable[groupName].push(variable)
                return groupedVariable
            }, {} as { [groupName: string]: Wukong.DocumentProto.ILocalVariable[] })
            const newVariableIds: string[] = []
            Object.keys(groupedVariablesInSelection).forEach((groupName) => {
                const selectedVariablesInGroup = groupedVariablesInSelection[groupName].sort(
                    (a, b) => a.position - b.position
                )
                // 找到组下的所有变量
                const siblingsVariableNamesInGroup = this._groupedVariablesInCollection[groupName].variables.map((v) =>
                    getName(v.name)
                )

                selectedVariablesInGroup
                    .map((variable) => {
                        const currentVariableName = getName(variable.name)
                        // 计算新的名称
                        const newVariableName = duplicateName(
                            new Set(siblingsVariableNamesInGroup),
                            currentVariableName
                        )
                        // 将新变量名的路径加入备选列表中，以继续计算
                        siblingsVariableNamesInGroup.push(newVariableName)
                        // 组成完整的变量名，创建变量
                        const newVariableFullName = joinName([groupName, newVariableName])
                        const newVariableId = this._commandInvoker.invokeBridge(
                            CommitType.Noop,
                            DuplicateVariableCommand,
                            {
                                sourceVariableId: variable.id,
                                newName: newVariableFullName,
                            }
                        ).value!
                        // 记录新创建的变量 id
                        newVariableIds.push(newVariableId)
                        return newVariableId
                    })
                    // 反序将新建的变量插入到 index 的位置上
                    .reverse()
                    .forEach((newVariableId) => {
                        // 找到组中最后一个变量的 index
                        // NOTE: 这里需要写后读，因为前面的变量创建之后后面的 index 会变
                        const lastVariableInGroupIndex = this._commandInvoker.invokeBridge(
                            CommitType.Noop,
                            GetLocalVariableCommand,
                            {
                                value: selectedVariablesInGroup[selectedVariablesInGroup.length - 1].id,
                            }
                        ).position
                        this._commandInvoker.invokeBridge(CommitType.Noop, InsertVariableBetweenCommand, {
                            variableId: newVariableId,
                            preIndex: lastVariableInGroupIndex,
                        })
                    })
            })
            // 执行一次 batch commit
            this._commandInvoker.invokeBridge(CommitType.Noop, CommitUndo)
            // 刷新选区选中所有新建的变量
            this._selectedVariableIds$.next(newVariableIds)
            // 只有一个时进入重命名状态，
            if (newVariableIds.length === 1) {
                this.renameVariable(newVariableIds[0])
            }
        }
    }

    // 重命名变量
    renameVariable = (variableId: string | null) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            if (variableId) {
                this._commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_StartRenameVariableCommand, {
                    value: variableId,
                })
            } else {
                this._commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_AbortRenameVariableCommand)
            }
        } else {
            this.stateStore.setState({
                renameVariableState: variableId,
            })
        }
    }
    private _isNameDuplicated = (variable: Wukong.DocumentProto.ILocalVariable, newName: string) => {
        const _newName = formatName(newName)
        return this._variablesInCollection.some((v) => v.name === _newName && v.id !== variable.id)
    }
    private _renameVariable = (variable: Wukong.DocumentProto.ILocalVariable, newName: string, shouldCommit = true) => {
        const _newName = formatName(newName)
        if (this._isNameDuplicated(variable, _newName)) {
            WKToast.error(translation('变量名称不能重复'), { dataTestIds: { toast: 'variable-name-duplicated' } })
            return
        }
        this._commandInvoker.invokeBridge(
            shouldCommit ? CommitType.CommitUndo : CommitType.Noop,
            RenameVariableCommand,
            {
                variableId: variable.id,
                name: _newName,
            }
        )
    }
    renameVariableSubmit = (variable: Wukong.DocumentProto.ILocalVariable, newName: string) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_SubmitRenameVariableCommand, {
                value: newName,
            })
        } else {
            newName = formatName(newName)
            if (newName === '') {
                return
            }
            if (newName === getName(variable.name)) {
                return
            }
            if (containInvalidChars(newName)) {
                WKToast.error(translation('变量名称格式错误'), { dataTestIds: { toast: 'variable-name-invalid' } })
                return
            }
            this._renameVariable(variable, joinName([getPath(variable.name), newName]))
        }
    }

    // 删除变量
    removeSelectedVariables = () => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_DeleteSelectedVariablesCommand)
        } else {
            this.dataStore.getState().selectedVariables.forEach((variable) => {
                this._commandInvoker.invokeBridge(CommitType.Noop, RemoveVariableCommand, {
                    value: variable.id,
                })
            })
            // 清空选中状态
            this.clearVariableSelection()
            // 执行一次 batch commit
            this._commandInvoker.invokeBridge(CommitType.Noop, CommitUndo)
        }
    }

    // 新增合集 mode
    addCollectionMode = () => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_CreateCollectionModeCommand)
        } else {
            const selectedCollection = this.dataStore.getState().selectedCollection
            if (!selectedCollection) {
                return
            }
            const newModeName = generateName(
                new Set(selectedCollection.modes.map((set) => set.modeName)),
                translation('默认模式名称')
            )
            this._commandInvoker.invokeBridge(CommitType.CommitUndo, AddVariableSetModeCommand, {
                variableSetId: selectedCollection.id,
                newModeName,
            })
        }
    }

    // 复制合集 mode
    duplicateCollectionMode = (setMode: Wukong.DocumentProto.IVariableSetMode) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(
                CommitType.CommitUndo,
                LocalVariableEditor_DuplicateCollectionModeCommand,
                {
                    value: setMode.modeId!,
                }
            )
        } else {
            const selectedCollection = this.dataStore.getState().selectedCollection
            if (!selectedCollection) {
                return
            }
            const newModeName = duplicateName(
                new Set(selectedCollection.modes.map((set) => set.modeName)),
                setMode.modeName
            )
            this._commandInvoker.invokeBridge(CommitType.CommitUndo, DuplicateVariableSetModeCommand, {
                variableSetId: selectedCollection.id,
                sourceModeId: setMode.modeId!,
                newModeName,
            })
        }
    }

    // 重命名合集 mode
    renameCollectionMode = (mode: Wukong.DocumentProto.IVariableSetMode, newName: string) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_RenameCollectionModeCommand, {
                idOrKey: mode.modeId!,
                newName,
            })
        } else {
            if (newName === '') {
                return
            }
            if (newName === mode.modeName) {
                return
            }
            const currentCollection = this.dataStore.getState().selectedCollection
            if (!currentCollection) {
                return
            }
            this._commandInvoker.invokeBridge(CommitType.CommitUndo, SetVariableSetModeNameCommand, {
                variableSetId: currentCollection.id,
                variableSetModeId: mode.modeId!,
                newModeName: newName,
            })
        }
    }

    // 删除合集 mode
    deleteCollectionMode = (variableSetModeId: string) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_DeleteCollectionModeCommand, {
                value: variableSetModeId,
            })
        } else {
            const selectedCollection = this.dataStore.getState().selectedCollection
            if (!selectedCollection) {
                return
            }
            return this._commandInvoker.invokeBridge(CommitType.CommitUndo, DeleteVariableSetModeCommand, {
                variableSetId: selectedCollection.id,
                variableSetModeId,
            })
        }
    }

    // 新建分组
    addSelectedVariablesIntoGroup = () => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(
                CommitType.CommitUndo,
                LocalVariableEditor_AddSelectedVariablesIntoGroupCommand
            )
        } else {
            // 按在表格中从上到下的顺序排列，并不一定是实际的 index
            const groupedVariableIdsInTable = this._groupedVariablesInTable
                .filter((gv) => gv.type === 'variable')
                .map((gv) => gv.variable.id)
            const selectedVariables = this.dataStore
                .getState()
                .selectedVariables.map((v) => ({
                    ...v,
                    indexInTable: groupedVariableIdsInTable.indexOf(v.id),
                }))
                .sort((a, b) => a.indexInTable - b.indexInTable)
            if (!selectedVariables.length) {
                return
            }
            const variableNameSet = new Set<string>(selectedVariables.map((v) => getName(v.name)))
            // 多选新建分组最终会放到一个分组下，所以有重复名称则不新建分组
            if (variableNameSet.size < selectedVariables.length) {
                WKToast.error(translation('变量名称不能重复'), { dataTestIds: { toast: 'variable-name-duplicated' } })
                return
            }
            const basedGroupName = getPath(selectedVariables[0].name)
            // 找到所有子组名称
            const allGroupsNames = Object.keys(this._groupedVariablesInCollection)
            const childrenGroups = findDirectChildren(allGroupsNames, basedGroupName).map(getName)
            // 根据默认名称生成下一个可用名称
            const newGroupName = generateName(new Set(childrenGroups), translation('默认分组名称'))
            // 组成新的组名
            const newGroupFullName = joinName([basedGroupName, newGroupName])
            // 对于每一个变量替换组为新组名
            selectedVariables.forEach((variable) => {
                const variableNewFullName = joinName([newGroupFullName, getName(variable.name)])
                this._renameVariable(variable, variableNewFullName, false)
            })
            // 执行一次 batch commit
            this._commandInvoker.invokeBridge(CommitType.Noop, CommitUndo)
        }
    }

    // 重命名分组
    renameGroup = (group: LocalVariableGroup, newName: string) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            return
        } else {
            newName = formatName(newName)
            if (newName === '') {
                return
            }
            if (newName === group.displayName) {
                return
            }
            if (containInvalidChars(newName)) {
                WKToast.error(translation('分组名称格式错误'), { dataTestIds: { toast: 'group-name-invalid' } })
                return
            }
            // 将当前组名的父级组名和新名称组成新组名
            const groupPath = getPath(group.groupName)
            const newGroupFullName = joinName([groupPath, newName])
            // 对于组下的每一个变量，将原组名替换为新组名
            walkDownGroupVariables(group, (variable) => {
                // 对于更改组名所在的层级之后的路径保持不变
                const variableNameAfterGroup = joinName(
                    sliceAfter(getSegments(variable.name), getSegments(group.groupName).length)
                )
                const variableNewFullName = joinName([newGroupFullName, variableNameAfterGroup])
                this._renameVariable(variable, variableNewFullName, false)
            })
            // 执行一次 batch commit
            this._commandInvoker.invokeBridge(CommitType.Noop, CommitUndo)
            // 选中新组
            this._selectedGroupKeys$.next([newGroupFullName])
        }
    }
    renameGroupV2 = (group: Wukong.DocumentProto.ILocalVariableGroupItem, newName: string) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_RenameGroupCommand, {
                idOrKey: group.key,
                newName,
            })
        }
    }

    // 复制分组
    duplicateSelectedGroups = () => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_DuplicateSelectedGroupsCommand)
        } else {
            const selectedGroups = filterForAncestors(this.dataStore.getState().selectedGroups).sort(
                (a, b) => a.groupIndex - b.groupIndex
            ) // 选中的 group 从 index 小的依次各自复制
            const allGroupNames = Object.keys(this._groupedVariablesInCollection)
            // 维护一个 group 到 varId 的 map，因为多选分组复制时，所有新分组得插入到同层级下被复制分组的最后一个后面
            const groupToInsertVarId = new Map<string, Wukong.DocumentProto.ILocalVariable>()
            const newVariableInfo: { id: string; name: string }[] = []
            // 先按顺序对每个组内的变量进行复制，此时变量在最后，记录新变量的 id 及每个组的 index
            selectedGroups.forEach((group) => {
                const currentGroupName = getName(group.groupName)
                const groupPath = getPath(group.groupName)
                // 找到当前组所在父级分组中的所有子组
                const siblingGroupNames = findSiblingsIncludingSelf(allGroupNames, group.groupName).map(getName)
                // 复制出新的组名
                const newGroupName = duplicateName(new Set(siblingGroupNames), currentGroupName)
                // 组成完整的新组名
                const newGroupFullName = joinName([groupPath, newGroupName])
                // 对于组中的每个变量，将组路径替换为新组名
                walkDownGroupVariables(group, (variable) => {
                    // 变量名在所选组名之后的部分
                    const variableNameAfterGroup = joinName(
                        sliceAfter(getSegments(variable.name), getSegments(group.groupName).length)
                    )
                    // 将父级组名+新组名+变量后面的名称组成新变量名
                    const variableNewFullName = joinName([newGroupFullName, variableNameAfterGroup])
                    const newVariableId = this._commandInvoker.invokeBridge(CommitType.Noop, DuplicateVariableCommand, {
                        sourceVariableId: variable.id,
                        newName: variableNewFullName,
                    }).value!
                    // 将新变量名的路径加入备选列表中，以继续计算
                    allGroupNames.push(getPath(variableNewFullName))
                    // 记录新建的分组
                    newVariableInfo.push({ id: newVariableId, name: variableNewFullName })
                    // 同层级下分组，记录最后一个作为插入的位置
                    if (
                        !groupToInsertVarId.has(groupPath) ||
                        groupToInsertVarId.get(groupPath)!.position < variable.position
                    ) {
                        groupToInsertVarId.set(groupPath, variable)
                    }
                })
            })
            // 对于每个新建的变量，找到所在组的父层，定位到插入的位置，依次插入
            newVariableInfo.forEach((variableInfo) => {
                const { id, name } = variableInfo
                const newGroupName = getPath(name)
                const newGroupParent = getPath(newGroupName)
                if (groupToInsertVarId.has(newGroupParent)) {
                    const insertVariable = groupToInsertVarId.get(newGroupParent)!
                    const currentIndex = this._commandInvoker.invokeBridge(CommitType.Noop, GetLocalVariableCommand, {
                        value: insertVariable.id,
                    }).position
                    // 插入到位置后面
                    this._commandInvoker.invokeBridge(CommitType.Noop, InsertVariableBetweenCommand, {
                        variableId: id,
                        preIndex: currentIndex,
                    })
                    // 获取最新的位置
                    const newVariable = this._commandInvoker.invokeBridge(CommitType.Noop, GetLocalVariableCommand, {
                        value: id,
                    })
                    // 更新下一位
                    groupToInsertVarId.set(newGroupParent, newVariable)
                }
            })
            // 执行一次 batch commit
            this._commandInvoker.invokeBridge(CommitType.Noop, CommitUndo)
        }
    }

    // 取消分组。如选中组 a/b/c，取消分组 c，即是 c 下的所有变量移动到组 a/b 下，即组名只保留前两位
    ungroupSelectedGroups = () => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_UngroupSelectedGroupsCommand)
        } else {
            const selectedGroups = this.dataStore.getState().selectedGroups
            // 暂时只允许选中一个分组解组
            if (!selectedGroups.length || selectedGroups.length > 1) {
                return
            }
            const group = selectedGroups[0]
            // 解散组时有一个名称重复则本次操作不生效
            const prepareUngroupActionInfo: {
                variable: Wukong.DocumentProto.ILocalVariable
                newName: string
            }[] = []
            let shouldCommit = true
            // 父级组的路径长度
            const groupPath = getPath(group.groupName)
            // 维护一个目标分组中的名称 set 以检查重复
            const comparedNames = new Set<string>(
                this._groupedVariablesInCollection[groupPath]?.variables.map((v) => getName(v.name))
            )
            walkDownGroupVariables(group, (variable) => {
                if (!shouldCommit) {
                    return
                }
                const variableName = getName(variable.name)
                // 每个变量的路径截取到和父级路径长度一样，名称不变
                if (comparedNames.has(variableName)) {
                    shouldCommit = false
                    return
                }
                comparedNames.add(variableName)
                const newPathSegments = sliceStart(getSegments(variable.name), getSegments(groupPath).length)
                prepareUngroupActionInfo.push({ variable, newName: joinName([...newPathSegments, variableName]) })
            })
            // 无重复再一次行执行重命名操作
            if (!shouldCommit) {
                WKToast.error(translation('变量名称不能重复'), { dataTestIds: { toast: 'variable-name-duplicated' } })
                return
            }
            prepareUngroupActionInfo.forEach(({ variable, newName }) => {
                this._renameVariable(variable, newName, false)
            })
            // 执行一次 batch commit
            this._commandInvoker.invokeBridge(CommitType.Noop, CommitUndo)
            // 选中解组后的父级组
            this._selectedGroupKeys$.next([groupPath])
        }
    }

    // 删除分组
    deleteSelectedGroups = () => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_DeleteSelectedGroupsCommand)
        } else {
            this.dataStore.getState().selectedGroups.forEach((selectedGroup) => {
                walkDownGroupVariables(selectedGroup, (variable) => {
                    this._commandInvoker.invokeBridge(CommitType.Noop, RemoveVariableCommand, {
                        value: variable.id,
                    })
                })
            })
            // 执行一次 batch commit
            this._commandInvoker.invokeBridge(CommitType.Noop, CommitUndo)
            // 选中全部
            this.clearGroupSelection()
        }
    }

    /**
     * drag actions
     */
    dragSelectedVariablesInTable = (variable: Wukong.DocumentProto.ILocalVariable, position: 'before' | 'after') => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_DragSelectedVariablesCommand, {
                targetVariableId: variable.id,
                position: {
                    before: Wukong.DocumentProto.LocalVariableDragPosition.LOCAL_VARIABLE_DRAG_POSITION_BEFORE,
                    after: Wukong.DocumentProto.LocalVariableDragPosition.LOCAL_VARIABLE_DRAG_POSITION_AFTER,
                }[position],
            })
        } else {
            // 按在表格中从上到下的顺序排列，并不一定是实际的 index
            const groupedVariableIdsInTable = this._groupedVariablesInTable
                .filter((gv) => gv.type === 'variable')
                .map((gv) => gv.variable.id)
            const selectedVariables = this.dataStore
                .getState()
                .selectedVariables.map((v) => ({
                    ...v,
                    indexInTable: groupedVariableIdsInTable.indexOf(v.id),
                }))
                .sort((a, b) => a.indexInTable - b.indexInTable)
            if (!selectedVariables.length) {
                return
            }
            // 重命名
            const groupName = getPath(variable.name)
            const group = this._groupedVariablesInCollection[groupName]
            if (!group) {
                return
            }
            const nameSet = new Set<string>(group.variables.map((v) => getName(v.name)))
            // 记录重命名之后的名字
            selectedVariables.forEach((v) => {
                const variableGroupName = getPath(v.name)
                if (variableGroupName === groupName) {
                    return
                }
                const variableName = getName(v.name)
                const variableNewName = nameSet.has(variableName) ? duplicateName(nameSet, variableName) : variableName
                nameSet.add(variableNewName)
                const variableNewFullName = joinName([groupName, variableNewName])
                this._renameVariable(v, variableNewFullName, false)
            })

            let indexToInsert = variable.position
            selectedVariables.forEach((v, index) => {
                if (index === 0) {
                    // 插入第一个变量时，插入的目标位置为当前变量的位置
                    if (position === 'before') {
                        // 插入到目标之前，正序
                        this._commandInvoker.invokeBridge(CommitType.Noop, InsertVariableBetweenCommand, {
                            variableId: v.id,
                            postIndex: indexToInsert,
                        })
                    } else if (position === 'after') {
                        // 插入到目标之后，倒序
                        this._commandInvoker.invokeBridge(CommitType.Noop, InsertVariableBetweenCommand, {
                            variableId: v.id,
                            preIndex: indexToInsert,
                        })
                    }
                } else {
                    // 插入后面的变量时，按选中的顺序插入到前一个变量之后
                    this._commandInvoker.invokeBridge(CommitType.Noop, InsertVariableBetweenCommand, {
                        variableId: v.id,
                        preIndex: indexToInsert,
                    })
                }
                // 获取最新的位置
                indexToInsert = this._commandInvoker.invokeBridge(CommitType.Noop, GetLocalVariableCommand, {
                    value: v.id,
                }).position
            })
            // 执行一次 batch commit
            this._commandInvoker.invokeBridge(CommitType.Noop, CommitUndo)
        }
    }
    dragSelectedVariablesToSidebarGroup = (groupName: string) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(
                CommitType.CommitUndo,
                LocalVariableEditor_DragSelectedVariablesIntoGroupCommand,
                {
                    value: groupName,
                }
            )
        } else {
            const group = this._groupedVariablesInCollection[groupName]
            if (!group) {
                return
            }
            // 按在表格中从上到下的顺序排列，并不一定是实际的 index
            const groupedVariableIdsInTable = this._groupedVariablesInTable
                .filter((gv) => gv.type === 'variable')
                .map((gv) => gv.variable.id)
            const selectedVariables = this.dataStore
                .getState()
                .selectedVariables.map((v) => ({
                    ...v,
                    indexInTable: groupedVariableIdsInTable.indexOf(v.id),
                }))
                .sort((a, b) => a.indexInTable - b.indexInTable)
            if (!selectedVariables.length) {
                return
            }
            const nameSet = new Set<string>(group.variables.map((v) => getName(v.name)))
            let indexToInsert = group.groupIndex
            selectedVariables.forEach((v, index) => {
                const variableGroupName = getPath(v.name)
                // 如果变量已经在目标组下，不处理
                if (variableGroupName === groupName) {
                    return
                }
                // 重命名时如有重复会自动重命名
                if (variableGroupName !== groupName) {
                    const variableName = getName(v.name)
                    const variableNewName = nameSet.has(variableName)
                        ? duplicateName(nameSet, variableName)
                        : variableName
                    nameSet.add(variableNewName)
                    const variableNewFullName = joinName([groupName, variableNewName])
                    this._renameVariable(v, variableNewFullName, false)
                }

                if (index === 0) {
                    // 插入第一个变量时，插入的目标组最前面
                    this._commandInvoker.invokeBridge(CommitType.Noop, InsertVariableBetweenCommand, {
                        variableId: v.id,
                        postIndex: indexToInsert,
                    })
                } else {
                    // 插入后面的变量时，按选中的顺序插入到前一个变量之后
                    this._commandInvoker.invokeBridge(CommitType.Noop, InsertVariableBetweenCommand, {
                        variableId: v.id,
                        preIndex: indexToInsert,
                    })
                }
                // 获取最新的位置
                indexToInsert = this._commandInvoker.invokeBridge(CommitType.Noop, GetLocalVariableCommand, {
                    value: v.id,
                }).position
            })
            // 执行一次 batch commit
            this._commandInvoker.invokeBridge(CommitType.Noop, CommitUndo)
        }
    }
    private _dragSelectedGroupsTo = (
        selectedGroupsInOrder: LocalVariableGroup[],
        targetGroupName: string,
        startIndex: number,
        insertPosition: 'before' | 'after' | 'into'
    ) => {
        // 目标组为选中组的子组，此次操作全部不生效
        if (selectedGroupsInOrder.some((g) => isDescendant(g.groupName, targetGroupName))) {
            return
        }
        // 遍历选中组中的所有变量，将变量映射到新组下的命名
        const pendingVariableRenameList: {
            variable: Wukong.DocumentProto.ILocalVariable
            newName: string
        }[] = []
        const newSelectedGroups = new Set<string>()
        let lastGroupName: string | null = null
        selectedGroupsInOrder.forEach((selectedGroup) => {
            // 记录上一个需要操作的 group，如果下一个是其子组，则只记录新的组名保持选中状态
            if (lastGroupName !== null && isDescendant(lastGroupName, selectedGroup.groupName)) {
                // 如上一个父组为 a/b，当前组为 a/b/c/d，目标组为 e
                // 则拼 [e, [b,c,d]] 为 e/b/c/d
                const newGroupName = joinName([
                    targetGroupName,
                    ...sliceAfter(getSegments(selectedGroup.groupName), getSegments(getPath(lastGroupName)).length),
                ])
                newSelectedGroups.add(newGroupName)
                return
            }
            lastGroupName = selectedGroup.groupName
            // 如变量名为 a/b/c/1，选中的是 a/b
            // 那么需要保持 b/c/1 不变，只把 a 换掉
            // 如果拖拽目标为 d/e/f 中的 d/e，那 parent 为 d
            // 新变量名变为 d/b/c/1，新组名为 d/b
            const selectedGroupParent = getPath(selectedGroup.groupName)
            // 计算组中变量的新名称
            walkDownGroupVariables(selectedGroup, (variable) => {
                const variableNewFullName = joinName([
                    targetGroupName,
                    ...sliceAfter(getSegments(variable.name), getSegments(selectedGroupParent).length),
                ])
                // 得到需要重命名的变量及其新名称
                pendingVariableRenameList.push({
                    variable,
                    newName: variableNewFullName,
                })
            })
            // 记录选中的新组
            const newGroupName = joinName([targetGroupName, getName(selectedGroup.groupName)])
            newSelectedGroups.add(newGroupName)
        })
        if (!pendingVariableRenameList.length) {
            return
        }
        if (pendingVariableRenameList.some((v) => this._isNameDuplicated(v.variable, v.newName))) {
            WKToast.error(translation('变量名称不能重复'), { dataTestIds: { toast: 'variable-name-duplicated' } })
            return
        }
        // 按顺序执行重命名和排序
        pendingVariableRenameList.sort((a, b) => a.variable.position - b.variable.position)
        pendingVariableRenameList.forEach((v, index) => {
            // 先重命名
            this._renameVariable(v.variable, v.newName, false)
            // 再按位置插入
            if (index === 0) {
                // 插入第一个组时，目标位置根据 index 和 position 决定
                if (insertPosition === 'before') {
                    this._commandInvoker.invokeBridge(CommitType.Noop, InsertVariableBetweenCommand, {
                        variableId: v.variable.id,
                        postIndex: startIndex,
                    })
                } else if (insertPosition === 'after') {
                    this._commandInvoker.invokeBridge(CommitType.Noop, InsertVariableBetweenCommand, {
                        variableId: v.variable.id,
                        preIndex: startIndex,
                    })
                }
            } else {
                // 插入后面的变量时，按选中的顺序插入到前一个变量之后
                this._commandInvoker.invokeBridge(CommitType.Noop, InsertVariableBetweenCommand, {
                    variableId: v.variable.id,
                    preIndex: startIndex,
                })
            }
            // 获取最新的位置
            startIndex = this._commandInvoker.invokeBridge(CommitType.Noop, GetLocalVariableCommand, {
                value: v.variable.id,
            }).position
        })
        // 选中新的组名
        this._selectedGroupKeys$.next(Array.from(newSelectedGroups))
        // 执行一次 batch commit
        this._commandInvoker.invokeBridge(CommitType.Noop, CommitUndo)
    }
    dragSelectedGroups = (groupKey: string, position: 'before' | 'after' | 'into') => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_DragGroupCommand, {
                groupKey,
                position: {
                    before: Wukong.DocumentProto.LocalVariableDragPosition.LOCAL_VARIABLE_DRAG_POSITION_BEFORE,
                    after: Wukong.DocumentProto.LocalVariableDragPosition.LOCAL_VARIABLE_DRAG_POSITION_AFTER,
                    into: Wukong.DocumentProto.LocalVariableDragPosition.LOCAL_VARIABLE_DRAG_POSITION_INTO,
                }[position],
            })
        } else {
            const targetGroup = this._groupedVariablesInCollection[groupKey]
            if (!targetGroup) {
                return
            }
            // 按在表格中从上到下的顺序排列，并不一定是实际的 index
            const selectedGroupKeySet = new Set<string>(
                this.dataStore.getState().selectedGroups.map((g) => g.groupName)
            )
            const selectedGroupsInOrder = this.dataStore
                .getState()
                .groupsList.filter((g) => selectedGroupKeySet.has(g.groupName))
            // 根据拖拽的位置和目标 group 是否有子层结构决定拖拽的逻辑
            if (position === 'before') {
                // 插入到组之前，实际是插入到组的 parent 中，位置在组之前
                this._dragSelectedGroupsTo(
                    selectedGroupsInOrder,
                    getPath(targetGroup.groupName),
                    targetGroup.groupIndex,
                    'before'
                )
            } else if (position === 'into') {
                // 插入到组中，实际是插入到组中，位置在最前
                this._dragSelectedGroupsTo(selectedGroupsInOrder, targetGroup.groupName, targetGroup.groupIndex, 'into')
                this.expandGroup(groupKey)
            } else if (position === 'after') {
                // 插入到组之后，得看组是否有子组且是否展开
                const currentCollectionId = this.dataStore.getState().selectedCollection?.id
                if (!currentCollectionId) {
                    return
                }
                const currentCollapsedGroupKeys =
                    this._collectionToCollapsedGroupKeys$.getValue()[currentCollectionId] ?? []
                if (targetGroup.subGroups.length && !currentCollapsedGroupKeys.includes(groupKey)) {
                    // 有子组且展开，则插入到组中，位置在最前
                    this._dragSelectedGroupsTo(
                        selectedGroupsInOrder,
                        targetGroup.groupName,
                        targetGroup.groupIndex,
                        'before'
                    )
                } else {
                    // 否则插入到组的 parent 中，位置在组之后
                    this._dragSelectedGroupsTo(
                        selectedGroupsInOrder,
                        getPath(targetGroup.groupName),
                        targetGroup.groupIndex,
                        'after'
                    )
                }
            }
        }
    }

    /**
     * sidebar collapsed
     */

    collapseGroup = (groupKey: string) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_CollapseGroupCommand, {
                value: groupKey,
            })
        } else {
            const collectionId = this.dataStore.getState().selectedCollection?.id
            if (!collectionId) {
                return
            }
            const collectionToCollapsedGroupKeys = this._collectionToCollapsedGroupKeys$.getValue()
            const keySet = new Set<string>(
                (collectionToCollapsedGroupKeys[collectionId] =
                    collectionToCollapsedGroupKeys[collectionId] || new Set<string>())
            )
            keySet.add(groupKey)
            collectionToCollapsedGroupKeys[collectionId] = Array.from(keySet)

            this._collectionToCollapsedGroupKeys$.next(collectionToCollapsedGroupKeys)
            enhancedSessionStorage.setItem(
                SessionStorageKey.VariableLocalEditorSidebarCollapsedGroups,
                JSON.stringify(collectionToCollapsedGroupKeys)
            )
        }
    }

    expandGroup = (groupKey: string) => {
        if (featureSwitchManager.isEnabled('variable-wasm-refactor')) {
            this._commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_ExpandGroupCommand, {
                value: groupKey,
            })
        } else {
            const collectionId = this.dataStore.getState().selectedCollection?.id
            if (!collectionId) {
                return
            }
            const collectionToCollapsedGroupKeys = this._collectionToCollapsedGroupKeys$.getValue()
            const keySet = new Set<string>(
                (collectionToCollapsedGroupKeys[collectionId] =
                    collectionToCollapsedGroupKeys[collectionId] || new Set<string>())
            )
            keySet.delete(groupKey)
            collectionToCollapsedGroupKeys[collectionId] = Array.from(keySet)

            this._collectionToCollapsedGroupKeys$.next(collectionToCollapsedGroupKeys)
            enhancedSessionStorage.setItem(
                SessionStorageKey.VariableLocalEditorSidebarCollapsedGroups,
                JSON.stringify(collectionToCollapsedGroupKeys)
            )
        }
    }

    /**
     * table scroll
     */
    onTableScroll = (scrollTop: number) => {
        this._tableScrollTop$.next(scrollTop)
    }
    onTableContainerHeightChange = (rect: DOMRectReadOnly) => {
        this._tableContainerHeight$.next(rect)
    }
}

/**
 * utils
 */

function walkDownGroupVariables(
    group: LocalVariableGroup,
    callback: (variable: Wukong.DocumentProto.ILocalVariable) => void
) {
    group.variables.forEach((variable) => {
        callback(variable)
    })
    group.subGroups.forEach((child) => {
        walkDownGroupVariables(child, callback)
    })
}
// 遍历每一层组名，如 a/b/1 得到 ['a', 'a/b']
function walkDownPath(fullname: string, callback: (curSeg: string, curPath: string, parentPath: string) => void) {
    const path = getPath(fullname)
    if (!path) {
        callback('', '', '')
        return
    }
    const segs = path.split('/').filter(Boolean)
    for (let i = 0; i < segs.length; i++) {
        callback(segs[i], segs.slice(0, i + 1).join('/'), segs.slice(0, i).join('/'))
    }
}
function findPattern(fullname: string): [string, number] {
    const segs = fullname.split(' ')
    if (segs.length <= 1) {
        return [fullname, 1]
    }
    const lastSeg = sliceLast(segs)[0]
    const tryParseInt = parseInt(lastSeg, 10)
    if (String(tryParseInt) !== lastSeg) {
        return [fullname, 1]
    }
    return [sliceStart(segs, segs.length - 1).join(' '), tryParseInt]
}
// 给定一个关键词和已有名称的列表，生成下一个数字名称
export function generateName(names: Set<string>, keyword: string) {
    let maxIndex = 0
    names.forEach((name) => {
        const [nameKeyword, nameIndex] = findPattern(name)
        if (nameKeyword === keyword) {
            maxIndex = Math.max(maxIndex, nameIndex)
        }
    })
    if (maxIndex === 0) {
        return keyword
    }
    return `${keyword} ${maxIndex + 1}`
}
// 给定一个名称，根据规则生成下一个数字名称
export function duplicateName(names: Set<string>, input: string): string {
    const [keyword, possibleIndex] = findPattern(input)
    let maxIndex = possibleIndex
    names.forEach((name) => {
        const [nameKeyword, nameIndex] = findPattern(name)
        if (nameKeyword === keyword) {
            maxIndex = Math.max(maxIndex, nameIndex)
        }
    })
    return `${keyword} ${maxIndex + 1}`
}
// ['a/b/c', 'a/b/d', 'a/b/e', 'a/b/d/1', 'a/b'] 匹配 ['a/b/c'] 的兄弟，得到 ['a/b/c', 'a/b/d', 'a/b/e']
function findSiblingsIncludingSelf(allFullNames: string[], targetFullName: string) {
    const targetPath = getPath(targetFullName)
    return allFullNames.filter((name) => getPath(name) === targetPath)
}
// ['a/b/c', 'a/b/d', 'a/b/e', 'a/b/d/1', 'a/b'] 匹配 'a/b'，得到 ['a/b/c', 'a/b/d', 'a/b/e']
// ['', 'a', 'b/c'] 匹配 ''，得到 ['a']
function findDirectChildren(allFullNames: string[], targetFullName: string) {
    return allFullNames.filter((name) => getPath(name) === targetFullName)
}
// ['a', 'b', 'c'] 截取前 2 个 ['a', 'b']
function sliceStart(segments: string[], length: number) {
    return segments.slice(0, length)
}
// ['a', 'b', 'c'] 截取 2 个之后的，得到 ['c']
function sliceAfter(segments: string[], length: number) {
    return segments.slice(length)
}
// ['a', 'b', 'c'] 截取到倒数第二个 ['a', 'b']
// ['1'] 得到 []
function sliceToParent(segments: string[]) {
    return segments.slice(0, -1)
}
// ['a', 'b', 'c', '1'] 截取后 1 个 ['1']
// ['1'] 得到 ['1']
function sliceLast(segments: string[]) {
    return segments.slice(-1)
}
// a/b/c/1 得到 ['a', 'b', 'c', '1']
// 1 得到 ['1']
function getSegments(fullname: string) {
    return fullname.trim().split('/').filter(Boolean)
}
// a/b/c/1 得到 1
// 1 得到 1
function getName(fullname: string) {
    return sliceLast(getSegments(fullname)).join('')
}
// a/b/c/1 得到 a/b/c
// 1 得到 ''
function getPath(fullname: string) {
    return joinName(sliceToParent(getSegments(fullname)))
}
// 检查是否为祖孙关系
function isDescendant(ancestor: string, descendant: string) {
    const descendantSegments = getSegments(descendant)
    return getSegments(ancestor).every((seg, index) => descendantSegments[index] === seg)
}
// 根据子孙级联关系过滤出上层分组
function filterForAncestors(groups: LocalVariableGroup[]) {
    const ancestorSet = new Set<string>()
    const afterFilterGroups: LocalVariableGroup[] = []
    groups
        .sort((a, b) => getSegments(a.groupName).length - getSegments(b.groupName).length)
        .forEach((group) => {
            let ancestorFound = false
            walkDownPath(group.groupName, (_, curPath) => {
                if (ancestorSet.has(curPath)) {
                    ancestorFound = true
                    return
                }
            })
            if (!ancestorFound) {
                afterFilterGroups.push(group)
                ancestorSet.add(group.groupName)
            }
        })
    return afterFilterGroups
}
// 检查无效字符
function containInvalidChars(name: string) {
    return ['$', '.', '{', '}'].some((char) => name.includes(char))
}
// 连接变量名，移除无效的空字符
function joinName(segments: string[]) {
    return segments.filter(Boolean).join('/')
}
// 分组去重
function formatName(fullname: string) {
    return joinName(getSegments(fullname))
}
