import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    zinPLC: '离线操作保存失败。请在网络恢复后重新加载此文件，之前的离线操作可以被同步至线上',
    OeveVP: '重新加载',
} as const

export const enTranslation = {
    zinPLC: 'Failed to save offline changes. Reconnect to the network and reload this file to sync previous offline changes online',
    OeveVP: 'Reload',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
