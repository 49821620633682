import { Wukong } from '@wukong/bridge-proto'
import { environment } from '../environment'
import { WkCLog } from '../kernel/clog/wukong/instance'
import { featureSwitchManager } from '../kernel/switch'
import { ImageLibContext } from './image-lib/types'
import { ColorSpace, ImageFormat, TextureCompressionFormat } from './types'

let _imageLibContext: ImageLibContext | null = null

export function toTextureCompression(fmt: Wukong.DocumentProto.SerTcFormat): TextureCompressionFormat {
    switch (fmt) {
        case Wukong.DocumentProto.SerTcFormat.SER_TC_FORMAT_NONE:
            return TextureCompressionFormat.None
        case Wukong.DocumentProto.SerTcFormat.ASTC_4x4:
            return TextureCompressionFormat.ASTC_4x4
        case Wukong.DocumentProto.SerTcFormat.SER_TC_FORMAT_B_C7:
            return TextureCompressionFormat.BC7
    }
}

export function toColorSpace(colorProfile: Wukong.DocumentProto.DocumentColorProfile): ColorSpace {
    switch (colorProfile) {
        case Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B:
            return ColorSpace.sRGB
        case Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILE_DISPLAY_P3:
            return ColorSpace.DisplayP3
    }
}

export function toImageFormat(fmt: Wukong.DocumentProto.ImageFormat): ImageFormat {
    switch (fmt) {
        case Wukong.DocumentProto.ImageFormat.IMAGE_FORMAT_JPG:
            return ImageFormat.Jpg
        case Wukong.DocumentProto.ImageFormat.IMAGE_FORMAT_PNG:
            return ImageFormat.Png
        case Wukong.DocumentProto.ImageFormat.IMAGE_FORMAT_WEBP:
            return ImageFormat.Webp
        case Wukong.DocumentProto.ImageFormat.IMAGE_FORMAT_AVIF:
            return ImageFormat.Avif
    }
}

export function getImageLibContext(): ImageLibContext {
    if (_imageLibContext === null) {
        _imageLibContext = {
            env: {
                isDev: environment.isDev,
                wasmDownloadPrefix: environment.wasmDownloadPrefix,
            },
            logger: {
                log: (desc, logData) => {
                    WkCLog.log(desc, logData)
                },
                throttleLog: (desc, logData) => {
                    WkCLog.throttleLog(desc, logData)
                },
            },
            isFeatureEnabled: (featureSwitch) => {
                return featureSwitchManager.isEnabled(featureSwitch)
            },
        }
    }
    return _imageLibContext
}
