/* eslint-disable no-restricted-imports */
import { GET, POST, PUT, RequestBody } from '@tutor/network-core'
import { Wukong } from '@wukong/bridge-proto'
import { DocWithAuthorityVO } from '../../kernel/interface/type'
import { BaseCommonRequest } from '../../kernel/request/base-request'
import {
    AIComponentReplaceResponse,
    AICopilotParam,
    AICopilotV2,
    AILayoutRequestResponseV2,
    AiDuplicate,
    AiScene,
    CallAILayoutRequestParam,
    UserAiCreditsVO,
} from './typings'

@POST
export class CallAILayoutRequest extends BaseCommonRequest<AILayoutRequestResponseV2> {
    constructor(private readonly param: CallAILayoutRequestParam) {
        super()
    }

    public override requestUrl() {
        return 'ai/layout/v2'
    }

    public override requestBody() {
        return this.param
    }
}

interface AiDuplicateParam {
    docId: string
    nodeJson: string
    duplicateCount: number
}

@POST
export class CallAiDuplicateRequest extends BaseCommonRequest<AiDuplicate.AiDuplicateResponse> {
    constructor(private readonly param: AiDuplicateParam) {
        super()
    }

    public override requestUrl() {
        return 'ai/duplicate'
    }

    public override requestBody() {
        return this.param
    }
}

@POST
export class AICopilotV2Request extends BaseCommonRequest<AICopilotV2.RawResponse> {
    constructor(private readonly param: AICopilotParam) {
        super()
    }

    public override requestUrl() {
        return 'ai/copilot'
    }

    public override requestBody() {
        return this.param
    }
}

@POST
export class AICopilotV2TextPositionRequest extends BaseCommonRequest<AICopilotV2.TextPositionRawResponse> {
    constructor(private readonly param: AICopilotV2.TextPositionParam) {
        super()
    }

    public override requestUrl() {
        return 'ai/copilot/text-position'
    }

    public override requestBody() {
        return this.param
    }
}

@GET
export class AICopilotV2ValidateRequest extends BaseCommonRequest<AICopilotV2.ValidateResponse> {
    constructor(private readonly param: string) {
        super()
    }

    public override requestUrl() {
        return `ai/copilot/validate?thisDocId=${this.param}`
    }
}

@POST
export class CallAIAlignRequest extends BaseCommonRequest<AILayoutRequestResponseV2> {
    constructor(private readonly param: Wukong.DocumentProto.ICallAiAlignRequest) {
        super()
    }

    public override requestUrl() {
        return 'ai/align'
    }

    public override requestBody() {
        return Wukong.DocumentProto.CallAiAlignRequest.toObject(this.param as Wukong.DocumentProto.CallAiAlignRequest, {
            enums: Number,
        })
    }
}

@POST
export class CallAICreateAutolayoutRequest extends BaseCommonRequest<AILayoutRequestResponseV2> {
    constructor(private readonly param: Wukong.DocumentProto.ICallAiAlignRequest) {
        super()
    }

    public override requestUrl() {
        return 'ai/autolayout'
    }

    public override requestBody() {
        return Wukong.DocumentProto.CallAiAlignRequest.toObject(this.param as Wukong.DocumentProto.CallAiAlignRequest, {
            enums: Number,
        })
    }
}

@GET
export class CallAIComponentReplaceListRequest extends BaseCommonRequest<AIComponentReplaceResponse> {
    constructor(private readonly docId: string) {
        super()
    }

    public override requestUrl() {
        return `ai/library-substitute?docId=${this.docId}`
    }
}

@PUT
export class AddAiCopilotPlaygroundRequest extends BaseCommonRequest<DocWithAuthorityVO> {
    constructor(private readonly orgId: string) {
        super()
    }

    public override requestUrl() {
        return `ai/copilot/playground`
    }

    override requestArgument(): { [key: string]: string | number } | undefined {
        return { orgId: this.orgId }
    }
}

export enum PlaygroundType {
    AiReduplication = 'ai_reduplication',
    AiDesignSystemCreator = 'ai_design_system_creator',
    AiDesignSystemMaintainer = 'ai_design_system_maintainer',
    AiLayout = 'ai_layout',
    AiConsistencyChecker = 'ai_consistency_checker',
    AiGenerateUI = 'ai_generates_ui',
    AiMagicBox = 'ai_magic_box',
    Variable = 'variable',
}

@POST
export class CreatePlaygroundRequest extends BaseCommonRequest<DocWithAuthorityVO> {
    constructor(private readonly orgId: string, private readonly playgroundType: PlaygroundType) {
        super()
    }

    public override requestUrl() {
        return `documents/playground`
    }

    override requestBody(): RequestBody {
        return { orgId: this.orgId, playgroundType: this.playgroundType }
    }
}

@GET
export class GetUserAiCreditsRequest extends BaseCommonRequest<UserAiCreditsVO> {
    public override requestUrl() {
        return `ai/credits`
    }
}

@POST
export class DecreaceUserAiCreditsRequest extends BaseCommonRequest {
    constructor(private readonly scene: AiScene) {
        super()
    }

    override requestArgument(): { [key: string]: string | number } | undefined {
        return {
            scene: this.scene,
        }
    }

    public override requestUrl() {
        return `ai/credits`
    }
}
