/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { useMemo } from 'react'
import { isWindowsOrLinux } from '../../../kernel/util/ua'
import { Item, Key, KeyType } from './data'
import classes from './tab-item.module.less'

export interface TabItemProps {
    item: Item
}

export function TabItem(props: TabItemProps) {
    const { item } = props

    // TODO(jiangjk): 与 wukong/wukong-web/packages/app/src/kernel/interface/shortcut-key.ts 使用统一的快捷键描述后, 可不再需要关心操作系统
    const _isWindowsOrLinux = useMemo(() => {
        return isWindowsOrLinux()
    }, [])

    const keys = useMemo(() => {
        return _isWindowsOrLinux ? item.winKeys : item.macKeys
    }, [_isWindowsOrLinux, item.macKeys, item.winKeys])

    return (
        <div className={classes.item}>
            <div className={classnames(classes.itemName, { [classes.itemNameTitle]: keys.length === 0 })}>
                {item.name}
            </div>
            <div className={classes.itemKeys}>
                {keys.map((key, index) => (
                    <TabItemKey key={index} dataKey={key} />
                ))}
            </div>
        </div>
    )
}
export interface TabItemKeyProps {
    dataKey: Key
}

function TabItemKey(props: TabItemKeyProps) {
    const { dataKey } = props
    return dataKey.type === KeyType.Key ? (
        <div key={dataKey.char} className={classes.key}>
            {dataKey.char}
        </div>
    ) : dataKey.type === KeyType.Split ? (
        <div key={dataKey.char} className={classes.split}>
            {dataKey.char}
        </div>
    ) : null
}
