import { Tooltip } from '../../../../../../ui-lib/src'
import { useIsEllipsisActive } from '../../../../../../util/src'
import { useRef } from 'react'
import style from './ellipsis-tool-tip-wrapper.module.less'

export function EllipsisTooltipWrapper({ text, width }: { text: string; width?: number }) {
    const headerRef = useRef<HTMLDivElement>(null)
    const isEllipsis = useIsEllipsisActive(headerRef.current)
    return (
        <Tooltip title={text} inactivation={!isEllipsis}>
            <span className={style.text} ref={headerRef} style={{ width: width ? width + 'px' : '' }}>
                {text}
            </span>
        </Tooltip>
    )
}
