/* eslint-disable no-restricted-imports */
import { GET, POST, ResponseType } from '@tutor/network-core'
import {
    ComponentDetailVO,
    ComponentWithVariantDetailVO,
    OrganizationLibrarySummaryVO,
    SingleLibraryDetailVO,
    SingleLibrarySummaryVO,
    TeamLibrarySummaryVO,
    TimePickerRange,
} from '../interface/library-usage'
import { omitByValue } from '../util/omit-by-value'
import { BaseCommonRequest } from './base-request'

// 首页/全部组件库列表上面的汇总数据查询接口
@GET
export class GetUsageOrganizationLibrarySummary extends BaseCommonRequest<OrganizationLibrarySummaryVO> {
    constructor(private orgId: string) {
        super()
    }

    public override requestArgument() {
        return {
            orgId: this.orgId,
        }
    }

    public override requestUrl() {
        return 'library-usage/org-summary'
    }
}

// 首页/全部组件库列表下面的团队和组件库搜索接口
@GET
export class QueryUsageTeamLibrarySummary extends BaseCommonRequest<TeamLibrarySummaryVO[]> {
    constructor(private orgId: string, private queryString?: string) {
        super()
    }

    public override requestArgument() {
        return omitByValue({
            orgId: this.orgId,
            queryString: this.queryString,
        })
    }

    public override requestUrl() {
        return 'library-usage/library-query'
    }
}

// 单一组件库概览页表头
@GET
export class GetUsageSingleLibrarySummary extends BaseCommonRequest<SingleLibrarySummaryVO> {
    constructor(private libraryId: string) {
        super()
    }

    public override requestArgument() {
        return {
            libraryId: this.libraryId,
        }
    }

    public override requestUrl() {
        return 'library-usage/library/summary'
    }
}

// 单一组件库分析页查询接口
@GET
export class QueryUsageSingleLibraryDetail extends BaseCommonRequest<SingleLibraryDetailVO> {
    constructor(private libraryId: string, private timePicker: TimePickerRange) {
        super()
    }

    public override requestArgument() {
        return {
            libraryId: this.libraryId,
            timePicker: this.timePicker,
        }
    }

    public override requestUrl() {
        return 'library-usage/library'
    }
}

// 单一组件库分析页导出指定时间范围内的团队引用和分离动作明细
@GET
export class ExportUsageSingleLibraryTeam extends BaseCommonRequest<string> {
    constructor(private libraryId: string, private timePicker: TimePickerRange) {
        super()
    }

    public override requestArgument() {
        return {
            libraryId: this.libraryId,
            timePicker: this.timePicker,
        }
    }

    public override requestUrl() {
        return 'library-usage/library/team/export'
    }

    public override responseType(): ResponseType {
        return ResponseType.Blob
    }
}

// 单一组件变体页查询接口
@GET
export class GetUsageSingleLibraryComponentVariant extends BaseCommonRequest<ComponentWithVariantDetailVO> {
    constructor(private componentId: string, private timePicker: TimePickerRange) {
        super()
    }

    public override requestArgument() {
        return {
            componentId: this.componentId,
            timePicker: this.timePicker,
        }
    }

    public override requestUrl() {
        return 'library-usage/component/variant'
    }
}

// 单一组件详情页查询接口
@GET
export class GetUsageSingleLibraryComponentDetail extends BaseCommonRequest<ComponentDetailVO> {
    constructor(private componentId: string, private timePicker: TimePickerRange) {
        super()
    }

    public override requestArgument() {
        return {
            componentId: this.componentId,
            timePicker: this.timePicker,
        }
    }

    public override requestUrl() {
        return 'library-usage/component'
    }
}

// 组件分离操作上报接口
@POST
export class ReportDetachSelectedInstance extends BaseCommonRequest {
    constructor(private docId: string, private nodeId2ComponentNodeId: Record<string, string>) {
        super()
    }

    public override requestBody() {
        return {
            docId: this.docId,
            nodeId2ComponentNodeId: this.nodeId2ComponentNodeId,
        }
    }

    public override requestUrl() {
        return 'library-usage/component-detach'
    }
}
