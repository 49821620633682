/* eslint-disable no-restricted-imports */
import type { ITextStyle } from '../../../../../document/node/node'
import { StyleListItem, StyleListItemKeyboardProps, StyleListItemProps } from '../style-items-layout/style-list-item'
import style from './style-text-list-item.module.less'
import { transformFontSizeForUi, transformLineHeightForUi } from './style-text-utils'
import { LocalTextStyleLibraryThumbnailImage } from './style-thumbnail'

export function StyleTextListItem(
    props: Omit<StyleListItemProps<ITextStyle & { contentHash: string }>, 'className'> & StyleListItemKeyboardProps
) {
    const { item } = props
    const isMissStyle = item.missFontInfo?.isMissStyle ?? false

    return (
        <StyleListItem disabledOnClick={isMissStyle} className={isMissStyle ? style.isMiss : undefined} {...props}>
            <LocalTextStyleLibraryThumbnailImage
                className={style.thumbnail}
                id={item.id}
                contentHash={item.contentHash}
                fontName={item.fontName}
                missFontInfo={item.missFontInfo ?? { isMissStyle: false, isMissFamily: false }}
            />
            <div className={style.name}>{item.styleName}</div>
            <div className={style.textInfo}>
                {transformFontSizeForUi(item.fontSize)}/{transformLineHeightForUi(item.lineHeight)}
            </div>
        </StyleListItem>
    )
}
