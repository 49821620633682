import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    ComponentCanNotContainItself: '组件不能包含它自身的实例',
} as const

export const enTranslation = {
    ComponentCanNotContainItself: `Components can't contain instances of themselves`,
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
