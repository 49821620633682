/* eslint-disable no-restricted-imports */
import {
    ClickOnCommentCommand,
    LocateNodeInDocumentForDebugOnly,
    Wukong,
    ZoomAtViewportCenterWasmCall,
} from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { CommandInvoker } from '../../document/command/command-invoker'
import { MessageContentType } from '../../kernel/interface/notify'
import { WsPropertyChangeMessage, WsRelationChangeMessage } from '../../kernel/notify/notify-message'
import { NotifyService } from '../../kernel/notify/notify-service'
import { GetDocOnlineUsers } from '../../kernel/request/doc-users'
import { GetDocRequest } from '../../kernel/request/document'
import { fullImageUrl } from '../../kernel/request/upload'
import { GetUserRequest } from '../../kernel/request/user'
import { Poster } from '../../window/client-local-bridge'

const subscribeParam = (id: string) => {
    return {
        [MessageContentType.RelationChange]: {
            filters: [
                {
                    filterParameters: [
                        {
                            name: 'entityType',
                            value: Wukong.NotifyProto.EntityType.DOC - 1 + '',
                        },
                        {
                            name: 'entityId',
                            value: id,
                        },
                    ],
                },
            ],
        },
        [MessageContentType.PropertyChange]: {
            filters: [
                {
                    filterParameters: [
                        {
                            name: 'entityType',
                            value: Wukong.NotifyProto.EntityType.DOC - 1 + '',
                        },
                        {
                            name: 'entityId',
                            value: id,
                        },
                    ],
                },
            ],
        },
    }
}

export class DesktopPreviewService {
    private timer?: NodeJS.Timeout
    private lastEditedTime?: number
    private userId?: number
    constructor(
        private readonly signal: TraceableAbortSignal,
        private readonly docId: string,
        private readonly notify: NotifyService,
        private readonly commandInvoker: CommandInvoker
    ) {
        if (!window.localBridge) {
            return
        }

        window.localBridge.bindLocateNode?.((nodeId: string) => {
            commandInvoker.DEPRECATED_invokeBridge(LocateNodeInDocumentForDebugOnly, { value: nodeId })
            commandInvoker.DEPRECATED_invokeBridge(ZoomAtViewportCenterWasmCall, {
                scale: 1,
            })
        })

        window.localBridge.bindLocateComment?.((commentId: string) => {
            commandInvoker.DEPRECATED_invokeBridge(ClickOnCommentCommand, { value: commentId })
        })

        this.notify.sendSubscribeProto(this.docId, subscribeParam(this.docId))

        this.notify.onConnectChangeWithSignal(this.signal, ({ sessionId }) => {
            if (sessionId) {
                this.notify.sendSubscribeProto(this.docId, subscribeParam(this.docId))
            }
        })

        this.signal.addEventListener('abort', () => {
            this.notify.sendUnSubscribeProto(this.docId, subscribeParam(this.docId))
        })

        this.notify.onBusinessMessageChangeWithSignal(this.signal, (proto) => {
            if (proto.businessCode !== WsPropertyChangeMessage.code) {
                return
            }

            const body = WsPropertyChangeMessage.bodyType.decode(proto.payload)

            if (body.businessEntity?.entityType !== Wukong.NotifyProto.EntityType.DOC) {
                return
            }

            if (body.businessEntity?.entityId !== this.docId) {
                return
            }

            const poster: Poster = {}
            if (body.changedProperties.name) {
                poster.name = body.changedProperties.name
            }

            if (!isNil(body.changedProperties.canvasBackgroundColor)) {
                poster.canvasBackgroundColor = body.changedProperties.canvasBackgroundColor
            }

            if (!isNil(body.changedProperties.thumbnailNodeId)) {
                poster.thumbnailNodeId = body.changedProperties.thumbnailNodeId
            }

            if (!isNil(body.changedProperties.thumbnailUrl)) {
                poster.thumbnailUrl = body.changedProperties.thumbnailUrl
            }

            window.localBridge?.updatePoster({
                id: body.businessEntity?.entityId!,
                poster,
            })
        })

        this.notify.onBusinessMessageChangeWithSignal(this.signal, (proto) => {
            if (proto.businessCode !== WsRelationChangeMessage.code) {
                return
            }

            const body = WsRelationChangeMessage.bodyType.decode(proto.payload)

            const validChangeTypes = [
                Wukong.NotifyProto.RelationChangeType.ADD,
                Wukong.NotifyProto.RelationChangeType.REMOVE,
            ]
            if (!validChangeTypes.includes(body.changeType)) {
                return
            }

            if (body.relation?.relation !== Wukong.NotifyProto.Relation.ACCESS) {
                return
            }

            if (body.relation?.one?.entityType !== Wukong.NotifyProto.EntityType.USER) {
                return
            }

            if (body.relation?.another?.entityType !== Wukong.NotifyProto.EntityType.DOC) {
                return
            }

            const entityId = body.relation?.another?.entityId
            if (entityId !== this.docId) {
                return
            }

            if (entityId) {
                this.syncDocUsersInfo(entityId)
            }
        })

        this.initDefalutData()

        this.startGetDocInfo()
    }

    private async syncDocUsersInfo(docId: string) {
        const res = await new GetDocOnlineUsers([docId]).start()
        if (res?.[docId]) {
            const userList = res[docId]
            userList.sort((a, b) => a.entryTime - b.entryTime)
            const currentUserIndex = userList.findIndex((user) => this.userId === user.userId)
            if (currentUserIndex > 0) {
                const currentUser = userList.splice(currentUserIndex, 1)
                userList.unshift(currentUser[0])
            }
            window.localBridge?.updatePoster({
                id: docId,
                poster: {
                    onlineUsers: await Promise.all(
                        userList.slice(0, 3).map(async (item) => {
                            if (item.userProfile.avatarId) {
                                item.userProfile.avatarId = await fullImageUrl(item.userProfile.avatarId, {
                                    minify: true,
                                    width: 60,
                                })
                            }
                            return item
                        })
                    ),
                },
            })
        }
    }

    private async initDefalutData() {
        const userInfo = await new GetUserRequest().start()
        this.userId = userInfo.userId
        this.syncDocData()
        this.syncDocUsersInfo(this.docId)
    }

    private async syncDocData() {
        const docInfo = await new GetDocRequest(this.docId).start()
        const poster: Poster = {}
        if (docInfo.name) {
            poster.name = docInfo.name
        }
        if (docInfo.thumbnailUrl) {
            poster.thumbnailUrl = docInfo.thumbnailUrl
        }
        if (docInfo.lastEditedTime || docInfo.createdTime) {
            poster.lastEditedTime = Math.max(docInfo.lastEditedTime, docInfo.createdTime)
        }
        if (docInfo.canvasBackgroundColor) {
            poster.canvasBackgroundColor = docInfo.canvasBackgroundColor
        }
        if (docInfo.thumbnailNodeId) {
            poster.thumbnailNodeId = docInfo.thumbnailNodeId
        }
        window.localBridge?.updatePoster({
            id: docInfo.id,
            poster,
        })
    }

    private startGetDocInfo() {
        this.timer = setInterval(async () => {
            const docInfo = await new GetDocRequest(this.docId).start()
            const currentLastEditedTime = Math.max(docInfo.lastEditedTime, docInfo.createdTime)
            if (currentLastEditedTime && this.lastEditedTime !== currentLastEditedTime) {
                this.lastEditedTime = currentLastEditedTime
                window.localBridge?.updatePoster({ id: this.docId, poster: { lastEditedTime: currentLastEditedTime } })
            }
        }, 60000)
    }

    private endGetDocInfo() {
        clearInterval(this.timer)
    }

    destroy() {
        this.endGetDocInfo()
    }
}
