import type { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import type { PropsWithChildren } from 'react'
import type { DeepRequired } from '../../../../../../view-state-bridge'
import { DevModeBoxModelTestId } from '../../../../../../window'
import classes from '../box-model.module.less'
import { DevModeInspectCopyableValue } from '../common/number-value'

export function PaddingBox(
    props: PropsWithChildren<{ value: DeepRequired<Wukong.DocumentProto.IDevModeInspectBoxModelLayout> }>
) {
    return (
        <div
            className={classNames(classes.paddingBoxContainer)}
            data-label="Padding"
            data-testid={DevModeBoxModelTestId.Padding}
        >
            <div />
            <PaddingMeasure value={props.value.topPadding} />
            <div />
            <PaddingMeasure value={props.value.leftPadding} />
            <div>{props.children}</div>
            <PaddingMeasure value={props.value.rightPadding} />
            <div />
            <PaddingMeasure value={props.value.bottomPadding} />
            <div />
        </div>
    )
}

function PaddingMeasure(props: { value: number }) {
    return (
        <div className={classes.paddingMeasureValue}>
            <DevModeInspectCopyableValue
                className={classes.measureValue}
                emptyClassName={classes.emptyValue}
                value={props.value}
                feedback="deep"
            />
        </div>
    )
}
