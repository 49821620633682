/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { isEqual } from 'lodash-es'
import React, { useCallback, useContext, useMemo } from 'react'
import { CustomItem, CustomItemProps } from '../../../custom-item/custom-item'
import { SingleLevelContext } from './type'

export interface SelectOptionProps extends Omit<CustomItemProps, 'addRightPadding' | 'isInteractive'> {
    value: unknown
    dataTestId?: string
}

export function SelectOption(props: SelectOptionProps) {
    const { className, value, isSelect, onClick, onMouseMove, onMouseLeave, ...otherProps } = props
    const {
        preselectValue,
        selectOptionsValue,
        selectElementClassName,
        optionElementClassName,
        preselectClassName,
        onChange,
        setPreselectValueByMouse,
    } = useContext(SingleLevelContext)

    const isSelectByMatch = useMemo(() => {
        if (selectOptionsValue === undefined) {
            return false
        }
        if (Array.isArray(selectOptionsValue)) {
            return selectOptionsValue.some((selectOptionValue) => isEqual(selectOptionValue, value))
        }
        return isEqual(selectOptionsValue, value)
    }, [selectOptionsValue, value])

    const isPreselect = useMemo(() => {
        if (props.disabled || preselectValue === undefined) {
            return false
        }
        return isEqual(preselectValue, value)
    }, [props.disabled, preselectValue, value])

    const _onClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            onChange(value, e)
            onClick?.(e)
        },
        [onChange, onClick, value]
    )

    const _onMouseMove = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (!isEqual(preselectValue, value)) {
                setPreselectValueByMouse(value)
            }
            onMouseMove?.(e)
        },
        [onMouseMove, preselectValue, setPreselectValueByMouse, value]
    )

    const _onMouseLeave = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            setPreselectValueByMouse(undefined)
            onMouseLeave?.(e)
        },
        [onMouseLeave, setPreselectValueByMouse]
    )

    return (
        <CustomItem
            {...otherProps}
            backwardIconStyle={{ paddingLeft: 16 }}
            className={classnames(optionElementClassName, [className], {
                [selectElementClassName]: isSelectByMatch,
                [preselectClassName]: isPreselect,
            })}
            isInteractive={isPreselect}
            onClick={_onClick}
            onMouseMove={_onMouseMove}
            onMouseLeave={_onMouseLeave}
            isSelect={isSelect || isSelectByMatch || false}
            addRightPadding
        >
            {props.children}
        </CustomItem>
    )
}
