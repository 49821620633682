import { isEnglishLanguage } from './i18n'

/**
 * 获取 motiff 名称
 */
export const getMotiffName = () => {
    if (isEnglishLanguage()) {
        return 'Motiff'
    }
    return 'Motiff 妙多'
}
