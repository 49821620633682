import { getRouteToken } from '../../../../../util/src'
import { WKFrog } from '../../frog'
import {
    AccountType,
    LinkPublicOptionType,
    LocatePageType,
    MotiffPlanType,
    OpenFileWayType,
    PermissionOptionType,
    ProjectMenuEntryType,
    RouteToken2LocatePageType,
    SpaceLogs,
    TeamMenuEntryType,
    getFileFlowDirection,
    updateLocatePage,
    updateMotiffPlan,
} from './logs'

export class SpaceFrogService {
    private hasExposed: boolean

    constructor() {
        this.hasExposed = false
        window.addEventListener('beforeunload', () => WKFrog.flushRecord())
    }

    expose(v: MotiffPlanType) {
        this.update(v)

        if (!this.hasExposed) {
            this.hasExposed = true
            SpaceLogs.Files.filesEnter()
        }
    }

    routerChange(v: any) {
        const routeToken = getRouteToken(v.pathname) as keyof typeof RouteToken2LocatePageType
        const locatePage = RouteToken2LocatePageType[routeToken] ?? routeToken
        updateLocatePage(locatePage)
    }

    update(v: MotiffPlanType) {
        updateMotiffPlan(v)
    }
}

export const spaceFrogService = new SpaceFrogService()
export {
    AccountType,
    LinkPublicOptionType,
    LocatePageType,
    MotiffPlanType,
    OpenFileWayType,
    PermissionOptionType,
    ProjectMenuEntryType,
    SpaceLogs,
    TeamMenuEntryType,
    getFileFlowDirection,
}
