import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Organization: '企业版',
    m: '月',
    UpgradeToOrganization: '升级至企业版',
    ChooseOrganization: '选择企业版',
} as const

export const enTranslation = {
    Organization: 'Organization',
    m: 'month',
    UpgradeToOrganization: 'Upgrade to Organization',
    ChooseOrganization: 'Choose Organization',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
