import { translation } from './utils.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { EditorMode } from '../../../document/node/node'
import { ShortcutKey, shortcutLabelMap } from '../../../kernel/interface/shortcut-key'

interface EditorModeInfo {
    name: string
    shortcutLabel: string
}

const editorModeInfoMap: Map<EditorMode, EditorModeInfo> = new Map([
    [
        EditorMode.EM_Move,
        {
            name: translation('Move'),
            shortcutLabel: shortcutLabelMap[ShortcutKey.MoveTool],
        },
    ],
    [
        EditorMode.EM_Scale,
        {
            name: translation('Scale'),
            shortcutLabel: shortcutLabelMap[ShortcutKey.ScaleTool],
        },
    ],
    [
        EditorMode.EM_Frame,
        {
            name: translation('Frame'),
            shortcutLabel: shortcutLabelMap[ShortcutKey.FrameTool],
        },
    ],
    [
        EditorMode.EM_Slice,
        {
            name: translation('Slice'),
            shortcutLabel: shortcutLabelMap[ShortcutKey.SliceTool],
        },
    ],
    [
        EditorMode.EM_Rectangle,
        {
            name: translation('Rectangle'),
            shortcutLabel: shortcutLabelMap[ShortcutKey.RectangleTool],
        },
    ],
    [
        EditorMode.EM_Line,
        {
            name: translation('Line'),
            shortcutLabel: shortcutLabelMap[ShortcutKey.LineTool],
        },
    ],
    [
        EditorMode.EM_Arrow,
        {
            name: translation('Arrow'),
            shortcutLabel: shortcutLabelMap[ShortcutKey.ArrowTool],
        },
    ],
    [
        EditorMode.EM_Ellipse,
        {
            name: translation('Ellipse'),
            shortcutLabel: shortcutLabelMap[ShortcutKey.EllipseTool],
        },
    ],
    [
        EditorMode.EM_Polygon,
        {
            name: translation('Polygon'),
            shortcutLabel: shortcutLabelMap[ShortcutKey.PolygonTool],
        },
    ],
    [
        EditorMode.EM_Star,
        {
            name: translation('Star'),
            shortcutLabel: shortcutLabelMap[ShortcutKey.StarTool],
        },
    ],
    [
        EditorMode.EM_PlaceImage,
        {
            name: translation('PlaceImage'),
            shortcutLabel: shortcutLabelMap[ShortcutKey.ImageTool],
        },
    ],
    [
        EditorMode.EM_PEN,
        {
            name: translation('Pen'),
            shortcutLabel: shortcutLabelMap[ShortcutKey.PenTool],
        },
    ],
    [
        EditorMode.EM_Text,
        {
            name: translation('Text'),
            shortcutLabel: shortcutLabelMap[ShortcutKey.TextTool],
        },
    ],
    [
        EditorMode.EM_HandTool,
        {
            name: translation('HandTool'),
            shortcutLabel: shortcutLabelMap[ShortcutKey.HandTool],
        },
    ],
    [
        EditorMode.EM_Comment,
        {
            name: translation('AddComment'),
            shortcutLabel: shortcutLabelMap[ShortcutKey.CommentTool],
        },
    ],
    [
        EditorMode.EM_BendTool,
        {
            name: translation('BendTool'),
            shortcutLabel: shortcutLabelMap[ShortcutKey.BendTool],
        },
    ],
    [
        EditorMode.EM_PaintBucket,
        {
            name: translation('PaintBucket'),
            shortcutLabel: shortcutLabelMap[ShortcutKey.PaintBucket],
        },
    ],
    [
        EditorMode.EM_Copilot,
        {
            name: `AI ${translation('BwkPDs')}`,
            shortcutLabel: shortcutLabelMap[ShortcutKey.Copilot],
        },
    ],
    [
        EditorMode.EM_AiDuplicate,
        {
            name: `AI ${translation('Copy')}`,
            shortcutLabel: shortcutLabelMap[ShortcutKey.AiDuplicate],
        },
    ],
    [
        EditorMode.EM_DevModeInspect,
        {
            name: translation('Inspect'),
            shortcutLabel: shortcutLabelMap[ShortcutKey.DevModeInspect],
        },
    ],
])

export function getEditorModeName(editorMode: EditorMode | undefined): string {
    return editorMode === undefined ? '' : editorModeInfoMap.get(editorMode)?.name ?? ''
}
export function getEditorModeShortCut(editorMode: EditorMode | undefined): string {
    return editorMode === undefined ? '' : editorModeInfoMap.get(editorMode)?.shortcutLabel ?? ''
}

const alignModeInfoMap: Map<Wukong.DocumentProto.AlignType, EditorModeInfo> = new Map([
    [
        Wukong.DocumentProto.AlignType.ALIGN_TYPE_LEFT,
        { name: translation('AlignLeft'), shortcutLabel: shortcutLabelMap[ShortcutKey.AlignLeft] },
    ],
    [
        Wukong.DocumentProto.AlignType.ALIGN_TYPE_HORIZONTAL_CENTERS,
        {
            name: translation('AlignHorizontalCenters'),
            shortcutLabel: shortcutLabelMap[ShortcutKey.AlignHorizontalCenters],
        },
    ],
    [
        Wukong.DocumentProto.AlignType.ALIGN_TYPE_RIGHT,
        { name: translation('AlignRight'), shortcutLabel: shortcutLabelMap[ShortcutKey.AlignRight] },
    ],
    [
        Wukong.DocumentProto.AlignType.ALIGN_TYPE_TOP,
        { name: translation('AlignTop'), shortcutLabel: shortcutLabelMap[ShortcutKey.AlignTop] },
    ],
    [
        Wukong.DocumentProto.AlignType.ALIGN_TYPE_VERTICAL_CENTERS,
        {
            name: translation('AlignVerticalCenters'),
            shortcutLabel: shortcutLabelMap[ShortcutKey.AlignVerticalCenters],
        },
    ],
    [
        Wukong.DocumentProto.AlignType.ALIGN_TYPE_BOTTOM,
        { name: translation('AlignBottom'), shortcutLabel: shortcutLabelMap[ShortcutKey.AlignBottom] },
    ],
])

export function getAlignModeName(mode: Wukong.DocumentProto.AlignType | undefined): string {
    return mode === undefined ? '' : alignModeInfoMap.get(mode)?.name ?? ''
}
export function getAlignModeShortCut(mode: Wukong.DocumentProto.AlignType | undefined): string {
    return mode === undefined ? '' : alignModeInfoMap.get(mode)?.shortcutLabel ?? ''
}
