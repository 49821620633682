/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { MonoIconCommonEnterRight16 } from '../../../../../ui-lib/src'
import { ToKeyCode } from '../../../document/util/keycode'
import styles from './canvas-search-page-header.module.less'

interface Props {
    title: string
    count: number
    expand: boolean
    toggleExpand: () => void
}

export function CanvasSearchPageHeader({ title, count, expand, toggleExpand }: Props) {
    const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
        if (event.keyCode == ToKeyCode.Space) {
            toggleExpand()
            event.preventDefault()
            event.stopPropagation()
        }
    }

    return (
        <div tabIndex={-1} className={styles.root} onClick={toggleExpand} onKeyDown={handleKeyDown}>
            <MonoIconCommonEnterRight16 className={classnames(styles.arrow, { [styles.expanded]: expand })} />
            <div className={styles.title}>{title}</div>
            <div className={styles.count}>{count}</div>
        </div>
    )
}
