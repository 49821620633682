import { createContext, useContext } from 'react'
import type { FontInfoExt } from '../../../../../main/font/interface'

export interface IFontSelectContext {
    readonly recentFontInfos: FontInfoExt[]
    readonly currentSelectedFamily: string
    readonly onFontSelectChange: (option: FontInfoExt) => void
    readonly onPreselectFont?: (fontInfo: FontInfoExt | null) => void
    readonly expend: boolean
    readonly setExpend: (expend: boolean) => void
    readonly searchStringBelongTo: string
}

const noop = () => {}

const FontSelectContext = createContext<IFontSelectContext>({
    recentFontInfos: [],
    currentSelectedFamily: '',
    onFontSelectChange: noop,
    onPreselectFont: noop,
    expend: false,
    setExpend: noop,
    searchStringBelongTo: '',
})

export const FontSelectContextProvider = FontSelectContext.Provider
export const useFontSelectContext = () => {
    return useContext(FontSelectContext)
}
