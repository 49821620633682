import constate from 'constate'
import { useState } from 'react'

export enum DragInsertArea {
    None,
    Before,
    After,
}

export interface DragEndProps {
    dragItem: any
    insertItem: any
    insertArea: DragInsertArea
}

interface UseDragContextProps {
    onDragEnd: (props: DragEndProps) => void
}

const useDragContext = (props: UseDragContextProps) => {
    // 被拖拽的 item
    const [activedDragItem, setActivedDragItem] = useState<unknown>()

    const [currentEnterItem, setCurrentEnterItem] = useState<{ item: unknown; insertArea: DragInsertArea }>()

    const onDragEnd = () => {
        if (activedDragItem && currentEnterItem?.item) {
            props.onDragEnd({
                dragItem: activedDragItem,
                insertItem: currentEnterItem.item,
                insertArea: currentEnterItem.insertArea,
            })
        }
    }

    return {
        activedDragItem,
        setActivedDragItem,
        currentEnterItem,
        setCurrentEnterItem,
        onDragEnd,
    }
}

export const [DragContextProvider, useDragItem] = constate(useDragContext, (context) => ({
    activeDragItem: context.activedDragItem,
    setActiveDragItem: context.setActivedDragItem,
    currentEnterItem: context.currentEnterItem,
    setCurrentEnterItem: context.setCurrentEnterItem,
    onDragEnd: context.onDragEnd,
}))
