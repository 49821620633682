import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    title: '磁盘空间不足，离线操作保存失败',
    reload: '重新加载',
    description:
        '网络异常，你已处于离线编辑状态。在离线编辑状态下，离线操作将通过浏览器暂时保存在本地。但此设备磁盘空间不足，离线操作无法保存，因此无法继续进行离线编辑。',
    learnMore: '了解更多',
    description2: '请检查网络情况，待网络恢复后重新加载此标签页。',
    description3: '请释放部分磁盘空间后重新加载此标签页。',
} as const

export const enTranslation = {
    title: 'Insufficient disk space, failed to save the offline changes',
    reload: 'Reload',
    description:
        'Network unavailable. You are now in offline editing mode. All offline changes will be temporarily saved locally through your browser. However, you cannot save offline changes or continue offline editing because this device has insufficient disk space.',
    learnMore: 'Learn more',
    description2: 'Check your network connection and reload this tab once the network is restored. ',
    description3: 'Free up some disk space and then reload this tab. ',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
