/* eslint-disable no-restricted-imports */
import { FeedBack as FeedBackUILib } from '../../../../../../../ui-lib/src'
import { useUserInfoContext } from '../../../../../auth'
import { useMenuContextState } from '../context/menu-context'

export function FeedBack() {
    const { isOpenFeedback, setIsOpenFeedback } = useMenuContextState()
    const { userInfo } = useUserInfoContext()
    return <FeedBackUILib isOpen={isOpenFeedback} setIsOpen={setIsOpenFeedback} userInfo={userInfo} />
}
