import { DELETE, GET, POST, PUT } from '@tutor/network-core'
import { OrgID, UserBriefVO } from '../interface/type'
import {
    CreateWorkspaceRequest,
    OrganizationSeatBriefVO,
    UpdateWorkspaceRequest,
    UpdateWorkspaceVisibilityRequest,
    WorkspaceDetailVO,
    WorkspaceInOrgManageVO,
    WorkspaceSummaryInOrgVOList,
    WorkspaceSummaryVO,
    WorkspaceWithAuthorityVO,
} from '../interface/workspace'
import { omitByValue } from '../util/omit-by-value'
import { BaseCommonRequest } from './base-request'

// 查询当前用户管理的工作区
@GET
export class GetCurrentUserManagedWorkspaces extends BaseCommonRequest<WorkspaceWithAuthorityVO[]> {
    constructor(private readonly orgId: OrgID) {
        super()
    }
    public override requestUrl() {
        return 'workspaces/admin'
    }
    public override requestArgument() {
        return omitByValue({
            orgId: this.orgId,
        })
    }
}

// 「企业管理-工作区列表」查询组织工作区列表
@GET
export class GetOrganizationWorkspaces extends BaseCommonRequest<WorkspaceInOrgManageVO[]> {
    constructor(private readonly orgId: string, private readonly queryString?: string) {
        super()
    }
    public override requestUrl() {
        const queryString = this.queryString
            ? `orgId=${this.orgId}&queryString=${this.queryString}`
            : `orgId=${this.orgId}`
        return `workspaces/in-org?${queryString}`
    }
}

// 「企业管理-成员」查询组织工作区列表
@GET
export class GetWorkspaceUnassignedSeats extends BaseCommonRequest<OrganizationSeatBriefVO[]> {
    constructor(
        private readonly orgId: string,
        private readonly workspaceId: string,
        private readonly queryString?: string
    ) {
        super()
    }
    public override requestUrl() {
        return `organizations/seats/unassigned/workspace`
    }
    public override requestArgument() {
        return omitByValue({
            orgId: this.orgId,
            workspaceId: this.workspaceId,
            queryString: this.queryString,
        })
    }
}

// 「企业管理-工作区」查询组织成员列表
@GET
export class GetOrganizationUnassignedMembers extends BaseCommonRequest<OrganizationSeatBriefVO[]> {
    constructor(private readonly orgId: string, private readonly queryString?: string) {
        super()
    }
    public override requestUrl() {
        return `organizations/seats/unassigned`
    }
    public override requestArgument() {
        return omitByValue({
            orgId: this.orgId,
            queryString: this.queryString,
        })
    }
}

// 删除工作区(可批量)
@DELETE
export class DeleteWorkspace extends BaseCommonRequest {
    constructor(private readonly workspaceIds: string[]) {
        super()
    }

    public override requestUrl() {
        return `workspaces`
    }

    public override requestBody() {
        return this.workspaceIds
    }
}

// 创建工作区
@POST
export class CreateWorkspace extends BaseCommonRequest {
    constructor(private readonly params: CreateWorkspaceRequest) {
        super()
    }

    public override requestUrl() {
        return `workspaces`
    }

    public override requestBody() {
        return this.params
    }
}

// 更新工作区
@PUT
export class UpdateWorkspace extends BaseCommonRequest {
    constructor(private readonly params: UpdateWorkspaceRequest) {
        super()
    }

    public override requestUrl() {
        return `workspaces`
    }

    public override requestBody() {
        return this.params
    }
}

// 更新工作区可见性(可批量)
@PUT
export class UpdateBatchWorkspaceVisibility extends BaseCommonRequest {
    constructor(private readonly params: UpdateWorkspaceVisibilityRequest) {
        super()
    }

    public override requestUrl() {
        return `workspaces/visibility`
    }

    public override requestBody() {
        return this.params
    }
}

// 查询组织下全部工作区概览信息
@GET
export class GetWorkspacesSummary extends BaseCommonRequest<WorkspaceSummaryInOrgVOList> {
    constructor(private readonly orgId: string) {
        super()
    }

    public override requestUrl() {
        return `workspaces/summary`
    }

    public override requestArgument() {
        return omitByValue({
            orgId: this.orgId,
        })
    }
}

// 「企业管理(工作区管理员)-概览」获取单个工作区概览信息
@GET
export class GetWorkspaceSummaryById extends BaseCommonRequest<WorkspaceSummaryVO> {
    constructor(private readonly workspaceId: string) {
        super()
    }

    public override requestUrl() {
        return `workspaces/summary/${this.workspaceId}`
    }
}

// 「工作区详情页」详情内容
@GET
export class GetWorkspaceDetail extends BaseCommonRequest<WorkspaceDetailVO> {
    constructor(private readonly workspaceId: string) {
        super()
    }

    public override requestUrl() {
        return `workspaces/detail/${this.workspaceId}`
    }

    public override requestDidFailed() {
        return false
    }
}

// 查询工作区管理员列表
@GET
export class GetWorkspaceAdmins extends BaseCommonRequest<UserBriefVO[]> {
    constructor(private readonly workspaceId: string) {
        super()
    }

    public override requestUrl() {
        return `workspaces/admins/${this.workspaceId}`
    }

    public override requestDidFailed() {
        return false
    }
}

// 更新工作区图标
@PUT
export class UpdateWorkspaceIcon extends BaseCommonRequest {
    constructor(private readonly workspaceId: string, private readonly icon: string) {
        super()
    }

    public override requestUrl() {
        return `workspaces/icon`
    }

    public override requestBody() {
        return { workspaceId: this.workspaceId, icon: this.icon }
    }
}

// 更新工作区描述
@PUT
export class UpdateWorkspaceDesc extends BaseCommonRequest {
    constructor(private readonly workspaceId: string, private readonly desc: string) {
        super()
    }

    public override requestUrl() {
        return `workspaces/desc`
    }

    public override requestBody() {
        return { workspaceId: this.workspaceId, desc: this.desc }
    }
}

// 批量更新工作区可见性
@PUT
export class BatchUpdateWorkspaceVisibility extends BaseCommonRequest {
    constructor(private readonly workspaceIds: string[], private readonly visibility: boolean) {
        super()
    }

    public override requestUrl() {
        return `workspaces/visibility`
    }

    public override requestBody() {
        return { workspaceIds: this.workspaceIds, visibility: this.visibility }
    }
}
