import { useEffect, useState } from 'react'
import { useViewStateBridge } from '../main/app-context'
import { ViewStateTokenTypeMap } from './view-state-token-type-map'
import { ViewStateToken } from './view-state-tokens'

export function useViewState<T extends ViewStateToken>(token: T): ViewStateTokenTypeMap[T] | undefined
export function useViewState<T extends ViewStateToken>(
    token: T,
    defaultValue: ViewStateTokenTypeMap[T]
): ViewStateTokenTypeMap[T]
export function useViewState<T extends ViewStateToken>(
    token: T,
    defaultValue: ViewStateTokenTypeMap[T] | undefined
): ViewStateTokenTypeMap[T] | undefined

/**
 *
 * @param token
 * @param defaultValue
 */
export function useViewState<T extends ViewStateToken>(
    token: T,
    defaultValue?: ViewStateTokenTypeMap[T] | undefined
): ViewStateTokenTypeMap[T] | undefined {
    const bridge = useViewStateBridge()
    const [state, setState] = useState(() => bridge.getDefaultValue(token, defaultValue))
    useEffect(() => {
        bridge.register(token, setState)
        setState(bridge.getDefaultValue(token))
        return () => {
            bridge.unregister(token, setState)
        }
    }, [bridge, token])
    return state
}

/**
 *
 * @param token
 * @param callback
 */
export function useViewCallback<T extends ViewStateToken>(token: T, callback: (v: ViewStateTokenTypeMap[T]) => void) {
    const bridge = useViewStateBridge()
    useEffect(() => {
        bridge.register(token, callback)
        callback(bridge.getDefaultValue(token))
        return () => {
            bridge.unregister(token, callback)
        }
    }, [bridge, callback, token])
}

/**
 *
 * @param token
 * @param defaultValue
 * @param callback
 */
export function useViewCallbackWithDefaultValue<T extends ViewStateToken>(
    token: T,
    defaultValue: ViewStateTokenTypeMap[T],
    callback: (v: ViewStateTokenTypeMap[T]) => void
) {
    const bridge = useViewStateBridge()
    useEffect(() => {
        bridge.register(token, callback)
        callback(bridge.getDefaultValue(token, defaultValue))
        return () => {
            bridge.unregister(token, callback)
        }
    }, [bridge, callback, defaultValue, token])
}
