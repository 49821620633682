import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { PrototypeTestId } from '../../../../../../window'
import { ScrubbableInputNumber } from '../../../../atom/inputs/scrubbable-input-number'
import {
    useAnimationEasingFunctionCommand,
    type AnimationTransitionTypeCommandProps,
} from '../use-action-animation-commands'
import classes from './animation-easing-function.module.less'
import { CubicBezierCanvas } from './cubic-bezier-canvas'

const SmallNudgeAmount = 0.01
const BigNudgeAmount = 0.1
export function AnimationEasingFunctionInput(props: {
    state: Wukong.DocumentProto.IEasingFunctionPropValue
    commandProps: AnimationTransitionTypeCommandProps
    continuousChangeStart: () => void
    continuousChangeEnd: () => void
}) {
    const command = useAnimationEasingFunctionCommand(props.commandProps)

    return props.state.show ? (
        <div className={classes.animationEasingFunction}>
            <div className={classes.canvasContainer} data-disabled-drag-move="true">
                <CubicBezierCanvas
                    x1={props.state.value[0]}
                    y1={props.state.value[1]}
                    x2={props.state.value[2]}
                    y2={props.state.value[3]}
                    onChange={command.onChangeCubicBezier}
                    dragStart={props.continuousChangeStart}
                    dragEnd={props.continuousChangeEnd}
                />
            </div>
            <div className={classes.easingFunctionInputGroup}>
                <ScrubbableInputNumber
                    value={props.state.value[0]}
                    resolution={1}
                    min={0}
                    max={1}
                    leftScrubbable={false}
                    rightScrubbable={false}
                    smallNudgeAmount={SmallNudgeAmount}
                    bigNudgeAmount={BigNudgeAmount}
                    notUseUserConfig
                    onChange={(value, options) => command.onChange(value, 0, props.state.value, options)}
                    testId={PrototypeTestId.InteractionPopup.EasingFunctionInput}
                    onScrubBegin={props.continuousChangeStart}
                    onScrubEnd={props.continuousChangeEnd}
                    className={classNames(classes.inputLabel, classes.inputLabelLeft)}
                />
                <ScrubbableInputNumber
                    value={props.state.value[1]}
                    resolution={1}
                    leftScrubbable={false}
                    rightScrubbable={false}
                    smallNudgeAmount={SmallNudgeAmount}
                    bigNudgeAmount={BigNudgeAmount}
                    notUseUserConfig
                    onChange={(value, options) => command.onChange(value, 1, props.state.value, options)}
                    testId={PrototypeTestId.InteractionPopup.EasingFunctionInput}
                    onScrubBegin={props.continuousChangeStart}
                    onScrubEnd={props.continuousChangeEnd}
                    className={classNames(classes.inputLabel, classes.inputLabelMiddle)}
                />
                <ScrubbableInputNumber
                    value={props.state.value[2]}
                    resolution={1}
                    min={0}
                    max={1}
                    leftScrubbable={false}
                    rightScrubbable={false}
                    smallNudgeAmount={SmallNudgeAmount}
                    bigNudgeAmount={BigNudgeAmount}
                    notUseUserConfig
                    onChange={(value, options) => command.onChange(value, 2, props.state.value, options)}
                    testId={PrototypeTestId.InteractionPopup.EasingFunctionInput}
                    onScrubBegin={props.continuousChangeStart}
                    onScrubEnd={props.continuousChangeEnd}
                    className={classNames(classes.inputLabel, classes.inputLabelMiddle)}
                />
                <ScrubbableInputNumber
                    value={props.state.value[3]}
                    resolution={1}
                    leftScrubbable={false}
                    rightScrubbable={false}
                    smallNudgeAmount={SmallNudgeAmount}
                    bigNudgeAmount={BigNudgeAmount}
                    notUseUserConfig
                    onChange={(value, options) => command.onChange(value, 3, props.state.value, options)}
                    testId={PrototypeTestId.InteractionPopup.EasingFunctionInput}
                    onScrubBegin={props.continuousChangeStart}
                    onScrubEnd={props.continuousChangeEnd}
                    className={classNames(classes.inputLabel, classes.inputLabelRight)}
                />
            </div>
        </div>
    ) : (
        <></>
    )
}
