import { useEffect, useRef } from 'react'
import { wkDialogConfirm } from '../../../../../../ui-lib/src'
import { usePlanAndUserStateService } from '../../../../main/app-context'
import { useDocInfoContext } from '../../../context/top-area-context'
import { translation } from './index.translation'

export function PlanUpgradingModal() {
    const planAndUserStateService = usePlanAndUserStateService()
    const planAndUserState = planAndUserStateService.useZustandStore.use.planAndUserState()

    const { docData } = useDocInfoContext()
    const dialogConfirmDestroyFn = useRef<() => void>()

    useEffect(() => {
        if (
            docData &&
            docData.orgId == '-1' &&
            planAndUserState?.planUpdating &&
            document.getElementsByClassName('planUpgradingModalPop').length <= 0
        ) {
            dialogConfirmDestroyFn.current = wkDialogConfirm.warning({
                closable: false,
                title: translation('UpdatingWarningTitle'),
                className: 'planUpgradingModalPop',
                content:
                    translation('UpdatingWarningContentPrefix') +
                    docData?.teamName +
                    translation('UpdatingWarningContentPostfix'),
                showButton: false,
                closeWhenHrefChanged: true,
            }).destroy
        } else {
            dialogConfirmDestroyFn.current?.()
        }
    }, [docData, planAndUserState?.planUpdating])

    return null
}
