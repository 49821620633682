/* eslint-disable no-restricted-imports */
import {
    SelectionColorsDetachStyleWasmCall,
    SelectionColorsDetachVariableWasmCall,
    SelectionColorsInspectColorWasmCall,
    SelectionColorsInspectStyleWasmCall,
    SelectionColorsReplaceColorWasmCall,
    SelectionColorsReplaceColorWithStyleWasmCall,
    SelectionColorsReplaceStyleWasmCall,
    SelectionColorsReplaceStyleWithColorWasmCall,
    UpdateFreezeSelectionPaintCommand,
    UpdateInfiniteSelectionPaintWasmCall,
    Wukong,
} from '@wukong/bridge-proto'
import { useCallback, useMemo } from 'react'
import { CommitType } from '../../../../../document/command/commit-type'
import { GradientPaint, Paint, SolidPaint } from '../../../../../document/node/node'
import { useCommand } from '../../../../../main/app-context'
import { useViewState } from '../../../../../view-state-bridge'
import { switchPaintType } from '../../../../utils/switch-paint-type'
import { useGradientEdit } from '../../../../utils/use-gradient-edit'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'

export interface PaintInfo {
    originPaint: SolidPaint | GradientPaint
    paint: SolidPaint | GradientPaint
    usingNodeCount: number
    usingInfos: Wukong.DocumentProto.PaintUsingInfo[]
}

export interface PaintVarInfo {
    originPaint: SolidPaint
    paint: SolidPaint
    usingNodeCount: number
    usingInfos: Wukong.DocumentProto.PaintUsingInfo[]
    variable: Wukong.DocumentProto.ISingleVariable
}

export interface PaintStyleInfo {
    originStyleId: string
    style: Wukong.DocumentProto.VPaintStyleNode
    usingNodeCount: number
    usingInfos: Wukong.DocumentProto.PaintStyleUsingInfo[]
}

export interface SelectionPaintType {
    readonly show: boolean
    readonly uniqueSelectionNodeId: string
    readonly emptyDueToLimitExceeded: boolean
    readonly paintInfos: PaintInfo[]
    readonly paintVarInfos: PaintVarInfo[]
    readonly styleInfos: PaintStyleInfo[]
}

export const useSelectionPaintModel = () => {
    const command = useCommand()
    const selectionPaint = useViewState('selectionPaint')
    const { startEditingGradient, endEditingGradient } = useGradientEdit()

    // TODO(jiangzg): 统一ts类型
    const modelState: SelectionPaintType = useMemo(() => {
        let state: SelectionPaintType = {
            show: false,
            uniqueSelectionNodeId: '',
            emptyDueToLimitExceeded: false,
            paintInfos: [],
            paintVarInfos: [],
            styleInfos: [],
        }
        if (selectionPaint) {
            state = {
                show: selectionPaint.show,
                uniqueSelectionNodeId: selectionPaint.uniqueSelectionNodeId,
                emptyDueToLimitExceeded: selectionPaint.emptyDueToLimitExceeded,
                paintInfos: selectionPaint.paintInfos.filter((paintInfo) => !paintInfo.variable),
                paintVarInfos: selectionPaint.paintInfos.filter((paintInfo) => paintInfo.variable),
                styleInfos: selectionPaint.styleInfos,
            } as unknown as SelectionPaintType
        }
        return state
    }, [selectionPaint])

    const updateFreezeSelectionPaintCallback = useCallback(
        (value: boolean) => {
            command.DEPRECATED_invokeBridge(
                UpdateFreezeSelectionPaintCommand,
                Wukong.DocumentProto.BridgeProtoBoolean.create({ value })
            )
        },
        [command]
    )

    return {
        modelState,
        modelCommand: {
            cmdUpdateInfiniteSelectionPaint: () => {
                // 复杂选区强制推送
                command.DEPRECATED_invokeBridge(
                    UpdateInfiniteSelectionPaintWasmCall,
                    Wukong.DocumentProto.BridgeProtoBoolean.create({ value: true })
                )
            },

            cmdUpdateFreezeSelectionPaint: updateFreezeSelectionPaintCallback,

            cmdSelectionColorsInspectColor: (paint: Paint | Wukong.DocumentProto.IPaint) => {
                command.DEPRECATED_invokeBridge(
                    SelectionColorsInspectColorWasmCall,
                    Wukong.DocumentProto.SelectionColorsInspectColorParam.create({
                        color: { ...(paint as Wukong.DocumentProto.IPaint) },
                    })
                )
                command.commitUndo()
            },

            cmdSelectionColorsInspectStyle: (styleId: string) => {
                command.DEPRECATED_invokeBridge(
                    SelectionColorsInspectStyleWasmCall,
                    Wukong.DocumentProto.BridgeProtoString.create({ value: styleId })
                )
                command.commitUndo()
            },

            cmdSelectionColorsReplaceColor: (
                srcPaint: Paint | Wukong.DocumentProto.IPaint,
                dstPaint: Paint | Wukong.DocumentProto.IPaint
            ) => {
                command.DEPRECATED_invokeBridge(
                    SelectionColorsReplaceColorWasmCall,
                    Wukong.DocumentProto.SelectionColorsReplaceParam.create({
                        srcColor: srcPaint as Wukong.DocumentProto.IPaint,
                        dstColor: dstPaint as Wukong.DocumentProto.IPaint,
                    })
                )
            },

            cmdSelectionColorsReplaceColorV2: (
                srcPaint: Paint | Wukong.DocumentProto.IPaint,
                dstPaint: Paint | Wukong.DocumentProto.IPaint,
                commitType: CommitType
            ) => {
                command.invokeBridge(
                    commitType,
                    SelectionColorsReplaceColorWasmCall,
                    Wukong.DocumentProto.SelectionColorsReplaceParam.create({
                        srcColor: srcPaint as Wukong.DocumentProto.IPaint,
                        dstColor: dstPaint as Wukong.DocumentProto.IPaint,
                    })
                )
            },

            cmdSelectionColorsDetachVariable: (
                srcColor: Paint | Wukong.DocumentProto.IPaint,
                dstColor: Paint | Wukong.DocumentProto.IPaint
            ) => {
                command.invokeBridge(
                    CommitType.CommitUndo,
                    SelectionColorsDetachVariableWasmCall,
                    Wukong.DocumentProto.SelectionColorsDetachVariableParam.create({
                        srcColor: srcColor as Wukong.DocumentProto.IPaint,
                        dstColor: dstColor as Wukong.DocumentProto.IPaint,
                    })
                )
            },

            cmdSelectionColorsReplaceStyle: (srcStyleId: string, dstStyleId: string) => {
                command.DEPRECATED_invokeBridge(
                    SelectionColorsReplaceStyleWasmCall,
                    Wukong.DocumentProto.SelectionColorsReplaceStyleParam.create({ srcStyleId, dstStyleId })
                )
                command.commitUndo()
            },

            cmdSelectionColorsDetachStyle: (srcStyleId: string) => {
                command.DEPRECATED_invokeBridge(
                    SelectionColorsDetachStyleWasmCall,
                    Wukong.DocumentProto.BridgeProtoString.create({ value: srcStyleId })
                )
                command.commitUndo()
            },

            cmdSelectionColorsReplaceColorByStyle: (
                originPaint: Paint | Wukong.DocumentProto.IPaint,
                dstStyleId: string
            ) => {
                command.DEPRECATED_invokeBridge(
                    SelectionColorsReplaceColorWithStyleWasmCall,
                    Wukong.DocumentProto.SelectionColorsReplaceColorWithStyleParam.create({
                        srcColor: originPaint as Wukong.DocumentProto.IPaint,
                        dstStyleId,
                    })
                )
                command.commitUndo()
            },

            cmdSelectionColorsReplaceStyleWithColor: (
                srcStyleId: string,
                dstColor: Wukong.DocumentProto.IPaint,
                options?: InputOptionsForUndoSquash
            ) => {
                command.invokeBridge(
                    options?.commitType ?? CommitType.CommitUndo,
                    SelectionColorsReplaceStyleWithColorWasmCall,
                    Wukong.DocumentProto.SelectionColorsReplaceStyleWithColorParam.create({ srcStyleId, dstColor })
                )
            },

            startEditingGradient: (usingInfos: PaintInfo['usingInfos']) => {
                if (usingInfos.length === 1) {
                    const { nodeId, type, index } = usingInfos[0]
                    if (modelState.uniqueSelectionNodeId !== nodeId) {
                        return
                    }

                    if (type === Wukong.DocumentProto.PaintUsingType.PAINT_USING_TYPE_FILL) {
                        startEditingGradient(nodeId, [
                            { type: Wukong.DocumentProto.PaintPositionType.PAINT_POSITION_TYPE_FILL, index: index },
                        ])
                    } else if (type === Wukong.DocumentProto.PaintUsingType.PAINT_USING_TYPE_STROKE) {
                        startEditingGradient(nodeId, [
                            { type: Wukong.DocumentProto.PaintPositionType.PAINT_POSITION_TYPE_STROKE, index },
                        ])
                    }
                }
            },

            endEditingGradient,

            changeSelectColorPaintType: (
                paintInfo: PaintInfo,
                type: Wukong.DocumentProto.PaintType,
                paint: Wukong.DocumentProto.IPaint | undefined | null
            ) => {
                const nextPaint = paint ? paint : switchPaintType(paintInfo.paint, type)
                command.DEPRECATED_invokeBridge(
                    SelectionColorsReplaceColorWasmCall,
                    Wukong.DocumentProto.SelectionColorsReplaceParam.create({
                        srcColor: paintInfo.originPaint as Wukong.DocumentProto.IPaint,
                        dstColor: nextPaint as Wukong.DocumentProto.IPaint,
                    })
                )
            },
        },
    } as const
}
