import { useEffect, useMemo, useRef, useState } from 'react'

interface CircleProgressProps {
    progress?: number // Optional - only used when manually completing
    size?: number
    color?: string
    thickness?: number
    className?: string
    onComplete?: () => void
}

function calculateProgressByTime(elapsedTime: number): number {
    // Convert elapsed time to seconds
    const t = elapsedTime / 1000

    if (t <= 36) {
        // 0-36s: Fast growth to 50%
        return 50 * (Math.log(t + 1) / Math.log(36))
    } else if (t <= 108) {
        // 36-108s: Slow growth to 90%
        return 50 + 40 * (Math.log(t - 35) / Math.log(72))
    } else if (t <= 120) {
        // 108-120s: Decelerated growth to 95%
        return 90 + 5 * (1 - Math.log(12 - (t - 108) + 1) / Math.log(12))
    }

    return 95 // Cap at 95% until manual completion
}

function polarToCartesian(cx: number, cy: number, radius: number, degrees: number) {
    const angleInRadians = ((degrees - 90) * Math.PI) / 180.0

    return {
        x: cx + radius * Math.cos(angleInRadians),
        y: cy + radius * Math.sin(angleInRadians),
    }
}

export function CircleProgressDot(props: CircleProgressProps) {
    const { size = 40, thickness = size, onComplete } = props
    const [animatedProgress, setAnimatedProgress] = useState(0)
    const startTimeRef = useRef<number>(Date.now())
    const animationFrameRef = useRef<number>()
    const completionAnimationRef = useRef<number>()

    // Handle automatic progress based on time
    useEffect(() => {
        const animate = () => {
            const elapsedTime = Date.now() - startTimeRef.current
            const calculatedProgress = calculateProgressByTime(elapsedTime)

            setAnimatedProgress(calculatedProgress)
            animationFrameRef.current = requestAnimationFrame(animate)
        }

        animationFrameRef.current = requestAnimationFrame(animate)

        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current)
            }
        }
    }, [])

    // Handle completion animation when progress prop is set to 100
    useEffect(() => {
        if (props.progress === 100) {
            // Cancel the automatic progress animation
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current)
            }

            const startProgress = animatedProgress
            const startTime = Date.now()
            const COMPLETION_DURATION = 500 // 0.5s completion animation

            const animateCompletion = () => {
                const elapsed = Date.now() - startTime
                const progress = Math.min(elapsed / COMPLETION_DURATION, 1)

                // Easing function for smooth completion
                const eased = 1 - Math.pow(1 - progress, 3) // Cubic ease-out
                const currentProgress = startProgress + (100 - startProgress) * eased

                setAnimatedProgress(currentProgress)

                if (progress < 1) {
                    completionAnimationRef.current = requestAnimationFrame(animateCompletion)
                } else {
                    onComplete?.()
                }
            }

            completionAnimationRef.current = requestAnimationFrame(animateCompletion)

            return () => {
                if (completionAnimationRef.current) {
                    cancelAnimationFrame(completionAnimationRef.current)
                }
            }
        }
    }, [props.progress, animatedProgress, onComplete])

    const opts = useMemo(
        () => ({
            cx: size,
            cy: size,
            radius: size,
            startAngle: 0,
            endAngle: Math.ceil((animatedProgress / 100) * 360),
            thickness,
        }),
        [animatedProgress, thickness, size]
    )

    const path = useMemo(() => {
        const start = polarToCartesian(opts.cx, opts.cy, opts.radius, opts.endAngle)
        const end = polarToCartesian(opts.cx, opts.cy, opts.radius, opts.startAngle)
        const flag = opts.endAngle - opts.startAngle <= 180 ? 0 : 1

        const cutout = opts.radius - opts.thickness
        const innerStart = polarToCartesian(opts.cx, opts.cy, cutout, opts.endAngle)
        const innerEnd = polarToCartesian(opts.cx, opts.cy, cutout, opts.startAngle)

        const pathItems = [
            ['M', start.x, start.y],
            ['A', opts.radius, opts.radius, 0, flag, 0, end.x, end.y],
            ['L', opts.cx, opts.cy],
            ['Z'],
            ['M', innerStart.x, innerStart.y],
            ['A', cutout, cutout, 0, flag, 0, innerEnd.x, innerEnd.y],
            ['L', opts.cx, opts.cy],
            ['Z'],
        ].map((v) => v.join(' '))

        return pathItems.join(' ')
    }, [opts])

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox={`0 0 ${size * 2} ${size * 2}`}
            width={size}
            height={size}
            className={props.className}
            data-testid="circle-progress-svg"
        >
            {(props.progress ?? animatedProgress) < 100 ? (
                <path fill={props.color ?? 'white'} stroke="none" fillRule="evenodd" d={path} />
            ) : (
                <circle cx={opts.cx} cy={opts.cy} r={opts.radius} fill={props.color ?? 'white'} />
            )}
        </svg>
    )
}
