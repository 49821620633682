import { environment } from '../../environment'
import { isSafari } from '../../kernel/util/ua'
export const ELECTRON_FONT_AGENT_BASE_URL = isSafari()
    ? `${environment.fontPluginDaemonHost}:38475/`
    : `${environment.fontPluginLocalHost}:38475/`
/**
 * 是否能调起桌面端
 */
export const DESKTOP_CLIENT_CAN_OPEN_URL = `${ELECTRON_FONT_AGENT_BASE_URL}can-open`

/**
 * 在桌面端中打开链接
 */
export const DESKTOP_CLIENT_OPEN_URL = `${ELECTRON_FONT_AGENT_BASE_URL}open`
