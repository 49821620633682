import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    ScrollBehavior: '滚动行为',
    Overflow: '溢出',
    Interactions: '交互',
    NoScrolling: '无滚动',
    Vertical: '垂直滚动',
    Horizontal: '水平滚动',
    BothDirections: '双向滚动',
    Position: '定位',
    ScrollWithParent: '随父图层滚动',
    FixedStayInPlace: '固定',
    Fixed: '固定',
    StickyTop: '粘性',
    Sticky: '粘性',
    OverflowWarning: '请确保容器内的子图层超出容器尺寸以正常使用滚动行为属性。',
} as const

export const enTranslation = {
    ScrollBehavior: 'Scroll behavior',
    Overflow: 'Overflow',
    Interactions: 'Interactions',
    NoScrolling: 'No scrolling',
    Vertical: 'Vertical',
    Horizontal: 'Horizontal',
    BothDirections: 'Both directions',
    Position: 'Position',
    ScrollWithParent: 'Scroll with parent',
    FixedStayInPlace: 'Fixed (stay in place)',
    Fixed: 'Fixed',
    StickyTop: 'Sticky (stop at top edge)',
    Sticky: 'Sticky',
    OverflowWarning: 'Extend the content beyond the frame to make scrolling work on this frame.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
