import classnames from 'classnames'
import { HTMLAttributes, forwardRef, useContext, useMemo } from 'react'
import { SelectContext } from './type'

export interface ScrollItemProps extends HTMLAttributes<HTMLDivElement> {
    uniqueKey: unknown
    selectClassName?: string
    isSelectFn?: (selectKey: unknown) => boolean
}

function _ScrollItem(props: ScrollItemProps, ref?: React.Ref<HTMLDivElement>) {
    const { uniqueKey, className, selectClassName: itemSelectClassName, isSelectFn, ...otherProps } = props
    const { selectKey, selectClassName } = useContext(SelectContext)

    const isSelect = useMemo(() => {
        if (typeof isSelectFn === 'function') {
            return isSelectFn(selectKey)
        }
        return selectKey !== undefined && selectKey === uniqueKey
    }, [isSelectFn, selectKey, uniqueKey])

    return (
        <div
            ref={ref}
            className={classnames([className], {
                [selectClassName]: isSelect,
                [itemSelectClassName!]: isSelect && itemSelectClassName,
            })}
            {...otherProps}
        >
            {props.children}
        </div>
    )
}

export const ScrollItem = forwardRef(_ScrollItem)
