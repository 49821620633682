/* eslint-disable no-restricted-imports */
import { MethodSignature } from '@wukong/bridge-proto'
import { CommandInvoker } from '../../../document/command/command-invoker'

type MethodSignatureArg<ARG> = ARG extends void ? undefined : ARG
export function getViewCommandByMethodSignature<ARG>(
    methodSignature: MethodSignature<void, ARG>,
    args: MethodSignatureArg<ARG>
) {
    return (command: CommandInvoker) => command.DEPRECATED_invokeBridge(methodSignature, args)
}
