import classNames from 'classnames'
import { useCallback, useMemo, useState } from 'react'
import { rgb2hsv } from '../../../../../util/src'
import classes from './doc-list.module.less'

export const DocThumbnail = ({
    url,
    width,
    canvasBackgroundColor,
    thumbnailNodeId,
    gapSize,
    rounded,
    isPrototype,
}: {
    url?: string
    rounded?: boolean
    width?: string | number
    gapSize?: 'small'
    canvasBackgroundColor?: string
    thumbnailNodeId?: string
    isPrototype?: boolean
}) => {
    const [error, setError] = useState(false)

    const thumbnailAspectRatio = useMemo(() => {
        const obj = canvasBackgroundColor ? parseCanvasBackgroundColor(canvasBackgroundColor) : undefined
        if (obj?.width && obj?.height) {
            return obj.width / obj.height
        }
        return 0
    }, [canvasBackgroundColor])

    const docThumbnailInnerStyle = useMemo(() => {
        return { ...generateImageContainerStyleFragmentFromCanvasBackgroundColor(canvasBackgroundColor), width }
    }, [canvasBackgroundColor, width])

    const onError = useCallback(() => {
        setError(true)
    }, [])

    const onLoad = useCallback(
        (e: React.SyntheticEvent<HTMLImageElement>) => {
            if (!isPrototype) {
                return
            }
            const el = e.target as HTMLImageElement
            const prototypeAspectRatio = el.naturalWidth / el.naturalHeight
            el.style.setProperty('object-fit', 'cover')
            el.style.setProperty('object-position', prototypeAspectRatio > 5 / 3 ? '50% 50%' : '0 0')
        },
        [isPrototype]
    )

    return useMemo(
        () => (
            <div
                data-testid="doc-thumbnail-wrapper"
                className={classNames(classes.docThumbnailWrapper, 'mosaic-8 rounded-3px', {
                    [classes.imgLoadError]: error,
                })}
            >
                <div
                    data-testid="doc-thumbnail-inner"
                    style={docThumbnailInnerStyle}
                    className={classNames('overflow-hidden', rounded != false && 'rounded-3px')}
                >
                    <div
                        className={classNames(
                            'rounded-3px relative',
                            { 'm-2': !thumbnailNodeId && !gapSize },
                            { 'm-1': !thumbnailNodeId && gapSize === 'small' },
                            { [classes.prototypeImgContainer]: isPrototype }
                        )}
                        style={{
                            paddingTop:
                                isPrototype || thumbnailNodeId
                                    ? `60%`
                                    : gapSize === 'small'
                                    ? `calc(60% - 8px)`
                                    : `calc(60% - 16px)`,
                            margin: isPrototype ? 0 : undefined,
                        }}
                    >
                        <img
                            key={url?.split?.('?')?.[0]}
                            data-pin-nopin="1"
                            src={url}
                            draggable={false}
                            className={classNames(['w-full absolute left-0 top-0'], {
                                ['h-full object-contain']: !thumbnailNodeId,
                                ['h-full object-cover']: thumbnailNodeId && thumbnailAspectRatio > 5 / 3,
                                ['h-full']: isPrototype,
                            })}
                            onLoad={onLoad}
                            onError={onError}
                        />
                    </div>
                </div>
            </div>
        ),
        [
            error,
            docThumbnailInnerStyle,
            rounded,
            thumbnailNodeId,
            gapSize,
            isPrototype,
            url,
            thumbnailAspectRatio,
            onLoad,
            onError,
        ]
    )
}

export function parseCanvasBackgroundColor(canvasBackgroundColor: string): Record<string, any> | undefined {
    try {
        return JSON.parse(canvasBackgroundColor)
    } catch (e) {}
}

/**
 * @description 更加 canvasBackgroundColor 字符串生成图片背景和边框颜色
 */
export function generateImageContainerStyleFragmentFromCanvasBackgroundColor(canvasBackgroundColor?: string) {
    const color = canvasBackgroundColor !== undefined ? parseCanvasBackgroundColor(canvasBackgroundColor) : undefined
    if (color) {
        const needBoxShadow = color.a < 1 || rgb2hsv(color.r, color.g, color.b).v * 100 >= 95
        return {
            backgroundColor: `rgba(${color.r * 255},${color.g * 255},${color.b * 255},${color.a})`,
            boxShadow: needBoxShadow ? 'inset 0 0 0 1px var(--wk-gray-13-8)' : undefined,
        }
    } else {
        return {
            backgroundColor: 'var(--wk-gray-3)',
            boxShadow: undefined,
        }
    }
}
