import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import type { HTMLAttributes } from 'react'
import React, { useCallback, useMemo } from 'react'
import type { SolidPaint } from '../../../document/node/node'
import { SingleGrid } from '../atom/grid/single-grid'
import { HexInput } from '../atom/inputs/hex-input'
import type { ColorSpace, RGB } from '../design/blend/color-picker/utils/color-translate'
import { hex2rgb, rgb2hex } from '../design/blend/color-picker/utils/color-translate'
import style from './paint-icon-color.module.less'
import { translation } from './paint-icon-color.translation'
import { PaintIcon } from './paint-icon/paint-icon'
import PaintType = Wukong.DocumentProto.PaintType

export interface PaintIconProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
    paint: Wukong.DocumentProto.IPaint
    colorSpace: ColorSpace
    onChangeColor?: (rgb: RGB) => void
    onMouseDownIcon?: (e: any) => void
    onClickIcon?: (e: any) => void
    onClickInput?: (e: React.MouseEvent) => void
    isWidth?: 84 | 88 | 92 | 96 | 100 | 104 | 108 // 总宽度
    disabled?: boolean
    dataTestIds?: {
        hexInput?: string
    }
}
export function PaintIconColor(props: PaintIconProps) {
    const {
        paint,
        disabled,
        colorSpace,
        isWidth = 100,
        dataTestIds,
        onMouseDownIcon,
        onClickIcon,
        onChangeColor,
        onClickInput,
    } = props

    const isSolidPaintFn = useCallback((_paint: Wukong.DocumentProto.IPaint): _paint is SolidPaint => {
        return _paint.type === PaintType.PAINT_TYPE_SOLID_PAINT
    }, [])

    const isSolidPaint = useMemo(() => {
        return isSolidPaintFn(paint)
    }, [isSolidPaintFn, paint])

    const paintTypeName = useMemo(() => {
        switch (paint.type) {
            case PaintType.PAINT_TYPE_IMAGE_PAINT:
                return translation('Image')
            case PaintType.PAINT_TYPE_GRADIENT_LINEAR:
                return translation('LinearGradient')
            case PaintType.PAINT_TYPE_GRADIENT_RADIAL:
                return translation('RadialGradient')
            case PaintType.PAINT_TYPE_GRADIENT_ANGULAR:
                return translation('AngularGradient')
            case PaintType.PAINT_TYPE_GRADIENT_DIAMOND:
                return translation('DiamondGradient')
            default:
                return ''
        }
    }, [paint.type])

    const _onChangeColor = useCallback(
        (hex: string | number) => {
            onChangeColor?.(hex2rgb(String(hex)))
        },
        [onChangeColor]
    )

    const _onMouseDownIcon = useCallback(
        (e: React.MouseEvent) => {
            if (disabled) {
                return
            }
            onMouseDownIcon?.(e)
        },
        [disabled, onMouseDownIcon]
    )

    const _onClickIcon = useCallback(
        (e: React.MouseEvent) => {
            if (disabled) {
                return
            }
            onClickIcon?.(e)
        },
        [disabled, onClickIcon]
    )

    return (
        <SingleGrid
            className={classnames(style.container, {
                [style.noSolid]: !isSolidPaint,
                [style.disabled]: disabled,
                [style.isWidth84]: isWidth === 84,
                [style.isWidth88]: isWidth === 88,
                [style.isWidth92]: isWidth === 92,
                [style.isWidth96]: isWidth === 96,
                [style.isWidth100]: isWidth === 100,
                [style.isWidth104]: isWidth === 104,
                [style.isWidth108]: isWidth === 108,
            })}
        >
            <SingleGrid.Item
                className={classnames(style.item1, {
                    [style.mask]: isSolidPaint,
                })}
            >
                <PaintIcon
                    paint={paint}
                    onMouseDown={_onMouseDownIcon}
                    onClick={_onClickIcon}
                    focusNoneBorder={!isSolidPaint}
                    colorSpace={colorSpace}
                />
            </SingleGrid.Item>
            <SingleGrid.Item className={style.item2}>
                {isSolidPaintFn(paint) ? (
                    <HexInput
                        value={rgb2hex(paint.color.r, paint.color.g, paint.color.b)}
                        onChange={_onChangeColor}
                        className={classnames(paint.visible ? undefined : style.hidden, style.hexInput)}
                        onClick={onClickInput}
                        disabled={disabled}
                        testId={dataTestIds?.hexInput}
                    />
                ) : (
                    <div
                        className={classnames(style.colorName, {
                            [style.hidden]: !paint.visible,
                        })}
                        onMouseDown={_onMouseDownIcon}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {paintTypeName}
                    </div>
                )}
            </SingleGrid.Item>
        </SingleGrid>
    )
}
