import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    Copied: '已复制',
    You: '（你）',
    PendingJoin: ' (待加入)',
} as const

export const enTranslation = {
    Copied: 'Email address copied',
    You: ' (You)',
    PendingJoin: ' (Pending)',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
