/* eslint-disable no-restricted-imports */
import { ApplyArcTextToSelectionCommand } from '@wukong/bridge-proto'
import { useCommand } from '../../../../main/app-context'
import { PluginArcTextState } from './type'

export const useArcTextCommands = () => {
    const invoker = useCommand()

    return {
        applyArcTextPlugin(state: PluginArcTextState) {
            invoker.DEPRECATED_invokeBridge(ApplyArcTextToSelectionCommand, state)
            motiff.commitUndo()
        },
    }
}

export type ArcTextCommandType = ReturnType<typeof useArcTextCommands>
