import { useMemo } from 'react'
import { DropdownV2, WKDivider } from '../../../../../../ui-lib/src'
import {
    AISearchFileOwnerCondition,
    AiSearchResourceType,
    AISearchSortingRule,
} from '../../../../kernel/interface/ai-search'
import { translation } from './ai-search-result-body.translation'
import { AISearchResultError } from './ai-search-result-error'
import { AISearchResultFileList } from './ai-search-result-file-list'
import { AISearchResultLoading } from './ai-search-result-loading'
import { AiSearchResultModel } from './use-ai-search'

function SearchFilterResourceType({ model }: { model: AiSearchResultModel }) {
    const { searchInfo, changeResourceType } = model
    const ResourceTypeLabel: Record<AiSearchResourceType, string> = useMemo(() => {
        return {
            [AiSearchResourceType.ORGANIZATION]: translation('AllSpace'),
            [AiSearchResourceType.TEAM]: translation('CurrentTeam'),
            [AiSearchResourceType.FOLDER]: translation('CurrentFolder'),
        }
    }, [])

    return (
        <div>
            <span className="wk-font-regular wk-text-12 text-gray">{translation('Space')}</span>
            <DropdownV2.MinimalSingleLevel
                weight="regular"
                size="small"
                label={ResourceTypeLabel[searchInfo.searchFilter.resourceType]}
                onChange={changeResourceType}
            >
                <DropdownV2.MinimalSingleLevel.Option
                    value={AiSearchResourceType.ORGANIZATION}
                    isSelect={searchInfo.searchFilter.resourceType === AiSearchResourceType.ORGANIZATION}
                >
                    {ResourceTypeLabel[AiSearchResourceType.ORGANIZATION]}
                </DropdownV2.MinimalSingleLevel.Option>
                {!model.isDraft && (
                    <>
                        <DropdownV2.MinimalSingleLevel.Option
                            value={AiSearchResourceType.TEAM}
                            isSelect={searchInfo.searchFilter.resourceType === AiSearchResourceType.TEAM}
                        >
                            {ResourceTypeLabel[AiSearchResourceType.TEAM]}
                        </DropdownV2.MinimalSingleLevel.Option>
                        <DropdownV2.MinimalSingleLevel.Option
                            value={AiSearchResourceType.FOLDER}
                            isSelect={searchInfo.searchFilter.resourceType === AiSearchResourceType.FOLDER}
                        >
                            {ResourceTypeLabel[AiSearchResourceType.FOLDER]}
                        </DropdownV2.MinimalSingleLevel.Option>
                    </>
                )}
            </DropdownV2.MinimalSingleLevel>
        </div>
    )
}

function SearchFilterFileOwner({ model }: { model: AiSearchResultModel }) {
    const { searchInfo, changeFileOwnerCondition } = model
    const FileOwnerConditionLabel: Record<AISearchFileOwnerCondition, string> = useMemo(() => {
        return {
            [AISearchFileOwnerCondition.ANY_USER]: translation('AnyUser'),
            [AISearchFileOwnerCondition.MYSELF]: translation('Myself'),
        }
    }, [])

    return (
        <div>
            <span className="wk-font-regular wk-text-12 text-gray">{translation('Owner')}</span>
            <DropdownV2.MinimalSingleLevel
                weight="regular"
                size="small"
                label={FileOwnerConditionLabel[searchInfo.searchFilter.fileOwnerCondition]}
                onChange={changeFileOwnerCondition}
            >
                <DropdownV2.MinimalSingleLevel.Option
                    value={AISearchFileOwnerCondition.ANY_USER}
                    isSelect={searchInfo.searchFilter.fileOwnerCondition === AISearchFileOwnerCondition.ANY_USER}
                >
                    {FileOwnerConditionLabel[AISearchFileOwnerCondition.ANY_USER]}
                </DropdownV2.MinimalSingleLevel.Option>
                <DropdownV2.MinimalSingleLevel.Option
                    value={AISearchFileOwnerCondition.MYSELF}
                    isSelect={searchInfo.searchFilter.fileOwnerCondition === AISearchFileOwnerCondition.MYSELF}
                >
                    {FileOwnerConditionLabel[AISearchFileOwnerCondition.MYSELF]}
                </DropdownV2.MinimalSingleLevel.Option>
            </DropdownV2.MinimalSingleLevel>
        </div>
    )
}

function SearchFilterSortBy({ model }: { model: AiSearchResultModel }) {
    const { searchInfo, changeSortingRule } = model
    const SortingRuleLabel: Record<AISearchSortingRule, string> = useMemo(() => {
        return {
            [AISearchSortingRule.SIMILARITY]: translation('Similarity'),
            [AISearchSortingRule.EDIT_TIME]: translation('EditTime'),
        }
    }, [])

    return (
        <div>
            <span className="wk-font-regular wk-text-12 text-gray">{translation('SortBy')}</span>
            <DropdownV2.MinimalSingleLevel
                weight="regular"
                size="small"
                label={SortingRuleLabel[searchInfo.searchFilter.sortingRule]}
                onChange={changeSortingRule}
            >
                <DropdownV2.MinimalSingleLevel.Option
                    value={AISearchSortingRule.SIMILARITY}
                    isSelect={searchInfo.searchFilter.sortingRule === AISearchSortingRule.SIMILARITY}
                >
                    {SortingRuleLabel[AISearchSortingRule.SIMILARITY]}
                </DropdownV2.MinimalSingleLevel.Option>
                <DropdownV2.MinimalSingleLevel.Option
                    value={AISearchSortingRule.EDIT_TIME}
                    isSelect={searchInfo.searchFilter.sortingRule === AISearchSortingRule.EDIT_TIME}
                >
                    {SortingRuleLabel[AISearchSortingRule.EDIT_TIME]}
                </DropdownV2.MinimalSingleLevel.Option>
            </DropdownV2.MinimalSingleLevel>
        </div>
    )
}

function SearchResult({ model, resultHeight }: { model: AiSearchResultModel; resultHeight: number }) {
    switch (model.searchResult.type) {
        case 'Loading':
            return <AISearchResultLoading />
        case 'Success':
            return <AISearchResultFileList model={model} resultHeight={resultHeight} />
        case 'Error':
            return <AISearchResultError model={model} />
    }
}

export function AiSearchResultBody({ model, resultHeight }: { model: AiSearchResultModel; resultHeight: number }) {
    return (
        <div className="w-full flex flex-col">
            <div className="h-48px px-24px flex-none flex flex-row items-center justify-end gap-20px">
                <SearchFilterResourceType model={model} />
                <SearchFilterFileOwner model={model} />
                <SearchFilterSortBy model={model} />
            </div>
            <WKDivider />
            <SearchResult model={model} resultHeight={resultHeight} />
        </div>
    )
}
