import { Wukong } from '@wukong/bridge-proto'
import { useCallback, useMemo, useState } from 'react'
import { MonoIconCommonRemove16, WKIconButton } from '../../../../../../ui-lib/src'
import { useViewState } from '../../../../view-state-bridge'
import {
    LocalVariableSetItem,
    RemoteVariableSetItem,
    VariableModeSelectProps,
    VariableSetModeSelectionType,
} from './interface'
import { VariableModeDisplaySelect } from './variable-mode-inspect-select'
import style from './variable-mode-inspect.module.less'

export function VariableModeInspect(props: VariableModeSelectProps) {
    const { source, data, onChange } = props

    const selectChangeTime = useViewState('selectChangeTime')
    const [displaySets, setDisplaySets] = useState<Set<string>>(new Set())
    const [prevSelectChangeTime, setPrevSelectChangeTime] = useState<string>(selectChangeTime?.value ?? '')

    const getNextDisplaySets = useCallback(() => {
        const updatedDisplaySets = prevSelectChangeTime === selectChangeTime?.value ? displaySets : new Set<string>()
        data.localVariableSets.forEach((setState) => {
            if (
                setState.modeSelectionState.selectionType !==
                VariableSetModeSelectionType.VARIABLE_SET_MODE_SELECTION_TYPE_DEFAULT
            ) {
                updatedDisplaySets.add(setState.uniqueKey)
            }
        })
        data.unknownVariableSets.forEach((setState) => {
            if (
                setState.modeSelectionState.selectionType !==
                VariableSetModeSelectionType.VARIABLE_SET_MODE_SELECTION_TYPE_DEFAULT
            ) {
                updatedDisplaySets.add(setState.uniqueKey)
            }
        })
        data.remoteVariableSets.forEach((states) => {
            states.items.forEach((item) => {
                if (
                    item.modeSelectionState.selectionType !==
                    VariableSetModeSelectionType.VARIABLE_SET_MODE_SELECTION_TYPE_DEFAULT
                ) {
                    updatedDisplaySets.add(item.uniqueKey)
                }
            })
        })

        setPrevSelectChangeTime(selectChangeTime?.value ?? '')
        return updatedDisplaySets
    }, [data, displaySets, prevSelectChangeTime, selectChangeTime?.value])

    const allSets = useMemo(() => {
        const nextDisplaySets = getNextDisplaySets()

        const assignedLocalSets = data.localVariableSets.filter((setState) => nextDisplaySets.has(setState.uniqueKey))
        const assignedUnknownSets = data.unknownVariableSets.filter((setState) =>
            nextDisplaySets.has(setState.uniqueKey)
        )
        const assignedRemoteSets: Wukong.DocumentProto.IRemoteVariableSetItem[] = []
        data.remoteVariableSets.forEach((group) => {
            group.items.forEach((item) => {
                if (nextDisplaySets.has(item.uniqueKey)) {
                    assignedRemoteSets.push(item)
                }
            })
        })

        const all = [...assignedLocalSets, ...assignedUnknownSets, ...assignedRemoteSets]
        all.sort((a, b) => a.name.localeCompare(b.name))

        setDisplaySets(nextDisplaySets)
        return all
    }, [data, getNextDisplaySets])

    const onRemove = useCallback(
        (value: { item: LocalVariableSetItem | RemoteVariableSetItem; type: 'auto' | 'value' | 'mixed' }) => {
            const ids = value.item.isLocal
                ? [(value.item as LocalVariableSetItem).id]
                : (value.item as RemoteVariableSetItem).localNodeIds
            onChange({ variableSetIds: ids, type: value.type })
            setDisplaySets((prev) => {
                prev.delete(value.item.uniqueKey)
                return prev
            })
        },
        [onChange]
    )

    return (
        <div className={style.container} data-testid="variable-mode-inspect-container">
            <div>
                {allSets.map((item, index) => (
                    <div key={index} className={style.text}>
                        {item.name}
                    </div>
                ))}
            </div>
            <div className={style.select}>
                {allSets.map((set) => (
                    <div key={set.uniqueKey} className={style.item}>
                        <VariableModeDisplaySelect item={set} source={source} onChange={onChange} />
                    </div>
                ))}
            </div>
            <div>
                {allSets.map((set) => (
                    <div key={set.uniqueKey} className={style.button}>
                        <WKIconButton
                            data-testid="remove-mode"
                            icon={<MonoIconCommonRemove16 />}
                            size="medium"
                            onClick={() =>
                                onRemove({
                                    item: set,
                                    type: 'auto',
                                })
                            }
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}
