/* eslint-disable no-restricted-imports */
import { useEffect } from 'react'
import { useCommentService, useHistoryService } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { CommentContent } from './comment-content'

export function Comment() {
    const historyService = useHistoryService()
    const isHistoryMode = historyService.useZustandStore.use.isHistoryMode()

    const showComment = useViewState('showComments', true)
    const commentService = useCommentService()

    useEffect(() => {
        commentService.updateShowComment(showComment)
    }, [commentService, showComment])

    // 历史版本下不展示评论
    return showComment && !isHistoryMode ? <CommentContent /> : null
}
