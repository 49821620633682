/**
 * @owner: loushuangzhanbj@kanyun.com
 */
import { useRef } from 'react'
import { createRoot } from 'react-dom/client'
import { useMount } from 'react-use'
import { createHTMLDivElement, generateKey } from '../../utils/utils'
import { AutoSlideInCenter, AutoSlideInCenterRef } from '../auto-slide-in-center/auto-slide-in-center'
import classes from './position-union.module.less'

interface PositionUnionProps {
    toastArea: HTMLElement
    notificationArea: HTMLElement
    helpArea: HTMLElement
    pageTurnerArea: HTMLElement
    likeToastArea: HTMLElement
}
function PositionUnion(props: PositionUnionProps) {
    const ref = useRef<AutoSlideInCenterRef>(null)

    useMount(() => {
        if (!ref.current) {
            return
        }
        const container = ref.current.getContainerElement()
        const centerContent = ref.current.getCenterContentElement()
        const right = ref.current.getRightElement()
        container && (container.style.opacity = '0')
        right?.appendChild(props.notificationArea)
        right?.appendChild(props.helpArea)
        centerContent?.appendChild(props.toastArea)
        centerContent?.appendChild(props.pageTurnerArea)
        centerContent?.appendChild(props.likeToastArea)
        // 等待组件root.render 完成，重新计算一次位置。整个app存在期间执行一次
        // 因为help按钮常驻，所以这个这个useMount会在进入工作台或者进入编辑器的loading中完成，无感
        // 因为help按钮目前总是第一个加载（相对toast|notification）,所以效果的不加setTimeout也一样
        // 但是如果toast先加载，因为toast的位置需要准确计算到中间，toast的root.render可能没完成。所以添加setTimeout
        setTimeout(() => {
            ref.current?.reCalculate()
            container?.style.removeProperty('opacity')
        }, 0)
    })

    return (
        <AutoSlideInCenter
            ref={ref}
            className={classes.autoSlideInCenter}
            rightClassName={classes.right}
            centerContentClassName={classes.centerContent}
        ></AutoSlideInCenter>
    )
}

/**
 * @description toast|notification|help 在body上添加一个共用的容器
 * */
class AreaUnion {
    private key = generateKey()
    private rootId = `au_root_${this.key}`
    private toastAreaId = `au_toast_${this.key}`
    private notificationAreaId = `au_notification_${this.key}`
    private helpAreaId = `au_help_${this.key}`
    private pageTurnerAreaId = `au_page_tuner_${this.key}`
    private likeToastAreaId = `au_like_toast_${this.key}`

    private createRoot = () => {
        return createHTMLDivElement({
            id: this.rootId,
            class: classes.root,
            dataTestId: this.rootId,
        })
    }
    private createToastArea = () => {
        return createHTMLDivElement({
            id: this.toastAreaId,
            class: classes.toastArea,
            dataTestId: this.toastAreaId,
        })
    }
    private createNotificationArea = () => {
        return createHTMLDivElement({
            id: this.notificationAreaId,
            class: classes.notificationArea,
            dataTestId: this.notificationAreaId,
        })
    }
    private createHelpArea = () => {
        const shadow = createHTMLDivElement({})
        const helpArea = createHTMLDivElement({
            id: this.helpAreaId,
            class: classes.helpArea,
            dataTestId: this.helpAreaId,
        })
        document.body.appendChild(helpArea)
        const observer = new ResizeObserver((entries) => {
            const width = entries[0]?.contentRect.width
            if (width) {
                shadow.style.width = width + 'px'
                shadow.style.marginLeft = '16px'
            } else {
                shadow.style.width = '0px'
                shadow.style.marginLeft = '0px'
            }
        })
        observer.observe(helpArea)
        return shadow
    }

    private createPageTurnerArea = () => {
        return createHTMLDivElement({
            id: this.pageTurnerAreaId,
            class: classes.pageTurnerArea,
            dataTestId: this.pageTurnerAreaId,
        })
    }
    private createLikeToastArea = () => {
        return createHTMLDivElement({
            id: this.likeToastAreaId,
            class: classes.likeToastArea,
            dataTestId: this.likeToastAreaId,
        })
    }

    public init = () => {
        if (document.getElementById(this.rootId)) {
            return
        }
        const htmlRoot = this.createRoot()
        const toastArea = this.createToastArea()
        const notificationArea = this.createNotificationArea()
        const helpArea = this.createHelpArea()
        const pageTurnerArea = this.createPageTurnerArea()
        const likeToastArea = this.createLikeToastArea()

        htmlRoot.appendChild(toastArea)
        htmlRoot.appendChild(notificationArea)
        htmlRoot.appendChild(helpArea)
        htmlRoot.appendChild(pageTurnerArea)
        htmlRoot.appendChild(likeToastArea)
        document.body.appendChild(htmlRoot)

        const reactRoot = createRoot(htmlRoot)
        reactRoot.render(
            <PositionUnion
                toastArea={toastArea}
                notificationArea={notificationArea}
                helpArea={helpArea}
                pageTurnerArea={pageTurnerArea}
                likeToastArea={likeToastArea}
            />
        )

        return () => {
            reactRoot.unmount()
            document.body.removeChild(htmlRoot)
        }
    }

    public getAreaIds = () => {
        return {
            root: this.rootId,
            toast: this.toastAreaId,
            notification: this.notificationAreaId,
            help: this.helpAreaId,
            pageTurner: this.pageTurnerAreaId,
            likeToast: this.likeToastAreaId,
        }
    }

    public getToastArea = () => {
        this.init()
        return document.getElementById(this.toastAreaId)!
    }

    public getNotificationArea = () => {
        this.init()
        return document.getElementById(this.notificationAreaId)!
    }

    public getHelpArea = () => {
        this.init()
        return document.getElementById(this.helpAreaId)!
    }

    public getPageTurnerArea = () => {
        this.init()
        return document.getElementById(this.pageTurnerAreaId)!
    }

    public getLikeToastArea = () => {
        this.init()
        return document.getElementById(this.likeToastAreaId)!
    }

    public customLikeToastArea = () => {
        const connectLikeToastArea = (content: HTMLElement) => {
            const shadow = this.getLikeToastArea()
            const observer = new ResizeObserver((entries) => {
                const height = entries[0]?.contentRect.height
                if (height) {
                    shadow.style.height = height + 'px'
                    shadow.style.marginTop = '24px'
                } else {
                    shadow.style.height = '0px'
                    shadow.style.marginTop = '0px'
                }
            })
            observer.observe(content)
            const disconnect = () => observer.disconnect()
            return disconnect
        }
        return {
            ui: {
                bottom: 24, // 因距离底部固定距离
            },
            connect: connectLikeToastArea,
        }
    }
}

export const areaUnion = new AreaUnion()
