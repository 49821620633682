import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    UnknownLibrary: '未知库',
    NoTextStyles: '本文件没有文本样式',
    NoStylesMatch: '没有找到该文本样式',
    TextStyle: '文本样式',
    Search: '搜索',
} as const

export const enTranslation = {
    UnknownLibrary: 'Unknown library',
    NoTextStyles: 'This file has no text styles',
    NoStylesMatch: 'No styles match this search',
    TextStyle: 'Text styles',
    Search: 'Search',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
