import { Wukong } from '@wukong/bridge-proto'

export function getDefaultSolidPaint(): Wukong.DocumentProto.IPaint {
    return {
        type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
        color: { r: 255, g: 255, b: 255 },
        opacity: 1,
        visible: true,
        blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
    }
}
