import { isEqual } from 'lodash-es'
import { useEffect, useRef, useState } from 'react'

export function useDeepEqual<T>(inputValue: T) {
    const [value, setValue] = useState<T>(inputValue)
    const previousRef = useRef(inputValue)

    useEffect(() => {
        if (!isEqual(inputValue, previousRef.current)) {
            setValue(inputValue)
        }

        return () => {
            previousRef.current = inputValue
        }
    }, [inputValue])

    return value
}
