import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Changes: '已修改',
    StylePublished: '发布后，该样式的引用关系将由「{{file}}」移至此文件。',
    StyleMoved: '该样式已被移至「{{file}}」中，发布该样式不会关联之前的引用关系',
    ComponentPublished: '发布后，该组件的引用关系将由「{{file}}」移至此文件。',
    ComponentMoved: '该组件已被移至「{{file}}」中， 发布该副本不会关联其它文件中的实例。',
    GcbIjj: '此变体组件内变体已被移至其他文件， 发布该副本不会关联其它文件中的实例。',
    EBeFOz: '此变体组件内变体在其它文件发布过，在本文件发布后，它与之前实例的关联关系也将移至本文件。',
    Unchanged: '未修改',
    Hidden: '已隐藏',
    "TheseWon'tBe": '下列隐藏的组件将不会被发布',
    ConflictingPropertyValues: '属性值冲突',
    MissingFonts: '缺失字体',
    InvalidAssets: '无效组件',
    ShowWhenPublishing: '发布时展示',
    HideWhenPublishing: '发布时隐藏',
    UnusedProperties: '存在未使用属性',
    VariableSet: '变量合集',
    Component: '组件',
    Style: '样式',
    VarWontBePublished: '以下变量将不会被发布',
    VariableUpdate: '变量修改 {{count}}',
    VariableHidden: '变量隐藏 {{count}}',
    Selected: '已选中',
} as const

export const enTranslation = {
    Changes: 'Changes',
    StylePublished:
        'This style will be moved from original document to "{{file}}", and any uses of the style will be connected to this file going forward.',
    StyleMoved: `This style was moved to "{{file}}". Publishing this new copy won't update instances in other files.`,
    ComponentPublished:
        'This component will be moved from "{{file}}" to this file, and its instances will be connected to this file going forward.',
    ComponentMoved: `This component was moved to "{{file}}". Publishing this new copy won't update instances in other files.`,
    GcbIjj: "Variants within this component set have been moved to other files. Publishing this new copy won't update instances in other files.",
    EBeFOz: "Variants within this component set have been published in other files. Publishing this new copy won't update instances in other files.",
    Unchanged: 'Unchanged',
    Hidden: 'Hidden',
    "TheseWon'tBe": 'These will not be published. ',
    ConflictingPropertyValues: 'Conflicting property values',
    MissingFonts: 'Missing font',
    InvalidAssets: 'Invalid assets',
    ShowWhenPublishing: 'Show when publishing',
    HideWhenPublishing: 'Hide when publishing',
    UnusedProperties: 'Unused properties',
    VariableSet: 'Variable collections',
    Component: 'Components',
    Style: 'Styles',
    VarWontBePublished: 'These will not be published.',
    VariableUpdate: '{{count}} variable{{suffix}} changed',
    VariableHidden: '{{count}} variable{{suffix}} hidden',
    Selected: 'Selected',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
