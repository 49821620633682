/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { toast } from '../../../../../ui-lib/src/components/wk-toast/toast'
import { createSelectors, createStore, domLocation, RouteToken } from '../../../../../util/src'
import { TraceableAbortController } from '../../../../../util/src/abort-controller/traceable-abort-controller'
import { CommentId } from '../../../kernel/interface/comment'
import { RoleStatus } from '../../../kernel/interface/type'
import { Message, MessageId } from '../../../kernel/interface/wukong-message-center'
import { MessageCenterNotifyService } from '../../../kernel/notify/message-center-notify-service'
import { NotifyService } from '../../../kernel/notify/notify-service'
import { RequestResponseErrorHandler } from '../../../kernel/request/error-handler'
import { featureSwitchManager } from '../../../kernel/switch'
import {
    fetchDeleteMessageReadAll,
    fetchDeleteMessageReadSome,
    fetchGetMessageBeforeId,
    fetchGetMessageBeforeNow,
    fetchGetMessageGetLast,
    fetchPostMessageViewMark,
    fetchResolveJoinTeam,
    fetchResolveSeatApplication,
} from './message-center-request'
import { translation } from './message-center-service.translation'
import {
    LocalMessage,
    LocalMessageApplyJoinRequest,
    LocalMessageSeatApplication,
    WsAssetHandoverMsg,
    WsCommentMsg,
    WsInvitationMsg,
    WsJoinApplyMsg,
    WsJoinRequestMsg,
    WsMarkOpenedMsg,
    WsMessage,
    WsModifyMsg,
    WsPluginEditInvitationMsg,
    WsSeatApplicationMsg,
    WsSeatApplyResolvedMsg,
    WsSeatUpdateMsg,
} from './type'
import {
    createLocalMessageApplyJoinRequestFromWsMessage,
    createLocalMessageApplyJoinResponseFromWsMessage,
    createLocalMessageAssetHandoverFromWsMessage,
    createLocalMessageCommentFromWsMessage,
    createLocalMessageInvitationRequestFromWsMessage,
    createLocalMessagePluginEditInvitationFromWsMessage,
    createLocalMessageSeatApplicationFromWsMessage,
    createLocalMessageSeatApplyResolvedFromWsMessage,
    createLocalMessageSeatUpdateFromWsMessage,
    transformHtmlMessages2Local,
    unshiftLocalMessages,
} from './utils'

export class MessageCenterService {
    protected httpMessagesMap: Map<MessageId, Message> = new Map() // 来自网络请求的消息集
    protected wsMessagesMap: Map<WsMessage['messageId'], WsMessage> = new Map()
    protected lastOpenMessageCenterTime = 0 // 最后一次访问消息中心的时间
    protected isMoreLoading = false
    public socket: {
        orgId: string
        userId: number
        messageCenterSocket: MessageCenterNotifyService
    } | null = null
    private connectSocketController: TraceableAbortController | null = null
    public orgId = ''
    private zustandStore = createStore<{
        isBellRedDot: boolean
        localMessages: LocalMessage[]
        initLoading: boolean
    }>(() => ({
        isBellRedDot: false,
        localMessages: [],
        initLoading: false,
    }))
    useZustandStore = createSelectors(this.zustandStore)

    initOrganizationCompleted = (orgId: string) => {
        if (this.orgId === orgId) {
            return
        }
        this.orgId = orgId
    }

    onScroll2Bottom = () => {
        if (this.isMoreLoading) {
            return
        }
        this.isMoreLoading = true
        const oldLocalMessages = this.zustandStore.getState().localMessages
        const earliestMessageId = oldLocalMessages[oldLocalMessages.length - 1]?.id
        this.fetchGetMessageBeforeId(this.orgId, earliestMessageId)
            .then((messages) => {
                messages.forEach((message) => this.httpMessagesMap.set(message.id, message))
                // 每次请求的message不要求与上一次的合在一次再合并
                const localMessages = transformHtmlMessages2Local(messages)
                this.zustandStore.setState({
                    localMessages: [...oldLocalMessages, ...localMessages],
                })
            })
            .finally(() => {
                this.isMoreLoading = false
            })
    }

    openMessageCenter = () => {
        this.fetchPostMessageViewMark(this.orgId).catch(() => {})
    }

    closeMessageCenter = () => {
        this.fetchPostMessageViewMark(this.orgId).catch(() => {})
    }

    onMarkAllRead = () => {
        this.fetchDeleteMessageReadAll(this.orgId).then(() => {
            this.readAllMessages()
            this.updateIsBellRedDot()
        })
    }

    onMarkRead = (messageId: MessageId, messageIds: MessageId[]) => {
        this.fetchDeleteMessageReadSome(this.orgId, messageIds).then(() => {
            this.readMessage(messageId, messageIds)
            this.updateIsBellRedDot()
        })
    }

    onApplyRejected = (id: MessageId, item: LocalMessageApplyJoinRequest) => {
        this.onApplyHandler(id, item, false).catch(() => {})
    }

    onApplyAllowed = (id: MessageId, item: LocalMessageApplyJoinRequest) => {
        return this.onApplyHandler(id, item, true)
    }

    onSeatApplyRejected = (id: MessageId, item: LocalMessageSeatApplication) => {
        this.onSeatApplyHandler(id, item, false).catch(() => {})
    }

    onSeatApplyAllowed = (id: MessageId, item: LocalMessageSeatApplication) => {
        this.onSeatApplyHandler(id, item, true).catch(() => {})
    }

    private onApplyHandler = (id: MessageId, item: LocalMessageApplyJoinRequest, isAllow: boolean) => {
        return this.fetchResolveJoinTeam(item.applicationId, isAllow ? true : false, item.roleToBe)
            .then(() => {
                this.deleteMessage(id)
            })
            .catch((e) => {
                const msg = RequestResponseErrorHandler(e)
                // 403权限不足 和 400 当前申请已被处理 的时候把消息从列表删除。toast已经全局处理了，这里不重复
                if (msg.status === 403 || msg.status === 400) {
                    this.deleteMessage(id)
                }
                return Promise.reject('message-center-service')
            })
    }

    private onSeatApplyHandler = (id: MessageId, item: LocalMessageSeatApplication, isAllow: boolean) => {
        return this.fetchResolveSeatApplication(item.applicationId, isAllow ? true : false)
            .then(() => {
                this.deleteMessage(id)
            })
            .catch((e) => {
                const msg = RequestResponseErrorHandler(e)
                // 403权限不足 和 400 当前申请已被处理 的时候把消息从列表删除。toast已经全局处理了，这里不重复
                if (msg.status === 403 || msg.status === 400 || msg.status === 404) {
                    this.deleteMessage(id)
                }
                if (msg.status === 400 && msg.businessStatus === 80001) {
                    toast.error(translation('400_80001'))
                } else if (msg.status === 400 && msg.businessStatus === 80004) {
                    toast.error(translation('400_80004'))
                } else if (msg.status === 403) {
                    toast.error(translation('403'))
                } else if (msg.status === 404 && featureSwitchManager.isEnabled('organization-plus')) {
                    toast.error(translation('404_95000'))
                } else {
                    window.dispatchEvent(new CustomEvent('request-error', { detail: { statusCode: msg.status } }))
                }
                return Promise.reject('message-center-service')
            })
    }

    /**
     * socket start
     */
    connectSocket = (notifyService: NotifyService, orgId: string, userId: number) => {
        // 如果是同样的参数就不再重复断连监听了
        if (this.socket && this.socket.orgId === orgId && this.socket.userId === userId) {
            return
        }
        this.disconnectSocket()
        const messageCenterSocket = new MessageCenterNotifyService(notifyService, orgId, userId)

        this.socket = { orgId, userId, messageCenterSocket }

        const msgCallbacks = {
            onConnect: this.onConnect,
            onModify: this.onModify,
            onComment: this.onComment,
            onJoinRequest: this.onJoinRequest,
            onJoinApply: this.onJoinApply,
            onInvitation: this.onInvitation,
            onAssetHandover: this.onAssetHandover,
            onSeatApplication: this.onSeatApplication,
            onSeatApplyResolved: this.onSeatApplyResolved,
            onSeatUpdate: this.onSeatUpdate,
            onPluginEditInvitation: this.onPluginEditInvitation,
            onMarkOpened: this.onMarkOpened,
        }

        this.connectSocketController = new TraceableAbortController('MessageCenterService connectSocket')
        messageCenterSocket.connectWithSignal(this.connectSocketController.signal, msgCallbacks)
    }

    disconnectSocket = () => {
        this.connectSocketController?.abort('disconnectSocket')
        this.socket = null
    }

    protected onConnect = () => {
        this.initMessageCenter(this.orgId)
    }
    protected onModify = (msg: WsModifyMsg) => {
        switch (msg.modifyType) {
            case Wukong.NotifyProto.MessageCenterMessageModifyTypeProto.MESSAGE_CENTER_MESSAGE_READ:
                return this.onModifyRead(msg.messageId)
            case Wukong.NotifyProto.MessageCenterMessageModifyTypeProto.MESSAGE_CENTER_MESSAGE_DELETE:
                return this.onModifyDelete(msg.messageId)
            case Wukong.NotifyProto.MessageCenterMessageModifyTypeProto.MESSAGE_CENTER_MESSAGE_RECOVER:
            default:
        }
    }

    private onModifyRead = (messageId: MessageId) => {
        this.readMessage(messageId, [messageId])
        this.updateIsBellRedDot()
    }

    private onModifyDelete = (messageId: MessageId) => {
        this.deleteMessage(messageId)
        this.updateIsBellRedDot()
    }

    protected onComment = (msg: WsCommentMsg) => {
        this.wsMessagesMap.set(msg.messageId, msg)
        const localMessage = createLocalMessageCommentFromWsMessage(msg)
        this.addLocalMessageFromWs(localMessage)
        this.updateIsBellRedDot()
    }
    protected onJoinRequest = (msg: WsJoinRequestMsg) => {
        this.wsMessagesMap.set(msg.messageId, msg)
        const localMessage = createLocalMessageApplyJoinRequestFromWsMessage(msg)
        this.addLocalMessageFromWs(localMessage)
        this.updateIsBellRedDot()
    }
    protected onJoinApply = (msg: WsJoinApplyMsg) => {
        this.wsMessagesMap.set(msg.messageId, msg)
        const localMessage = createLocalMessageApplyJoinResponseFromWsMessage(msg)
        this.addLocalMessageFromWs(localMessage)
        this.updateIsBellRedDot()
    }
    protected onInvitation = (msg: WsInvitationMsg) => {
        this.wsMessagesMap.set(msg.messageId, msg)
        const localMessage = createLocalMessageInvitationRequestFromWsMessage(msg)
        this.addLocalMessageFromWs(localMessage)
        this.updateIsBellRedDot()
    }
    protected onAssetHandover = (msg: WsAssetHandoverMsg) => {
        this.wsMessagesMap.set(msg.messageId, msg)
        const localMessage = createLocalMessageAssetHandoverFromWsMessage(msg)
        this.addLocalMessageFromWs(localMessage)
        this.updateIsBellRedDot()
    }
    protected onSeatApplication = (msg: WsSeatApplicationMsg) => {
        this.wsMessagesMap.set(msg.messageId, msg)
        const localMessage = createLocalMessageSeatApplicationFromWsMessage(msg)
        this.addLocalMessageFromWs(localMessage)
        this.updateIsBellRedDot()
    }
    protected onSeatApplyResolved = (msg: WsSeatApplyResolvedMsg) => {
        this.wsMessagesMap.set(msg.messageId, msg)
        const localMessage = createLocalMessageSeatApplyResolvedFromWsMessage(msg)
        this.addLocalMessageFromWs(localMessage)
        this.updateIsBellRedDot()
    }
    protected onSeatUpdate = (msg: WsSeatUpdateMsg) => {
        this.wsMessagesMap.set(msg.messageId, msg)
        const localMessage = createLocalMessageSeatUpdateFromWsMessage(msg)
        this.addLocalMessageFromWs(localMessage)
        this.updateIsBellRedDot()
    }
    protected onMarkOpened = (msg: WsMarkOpenedMsg) => {
        this.lastOpenMessageCenterTime = msg.lastOpenedTime
        this.updateIsBellRedDot()
    }
    protected onPluginEditInvitation = (msg: WsPluginEditInvitationMsg) => {
        this.wsMessagesMap.set(msg.messageId, msg)
        const localMessage = createLocalMessagePluginEditInvitationFromWsMessage(msg)
        this.addLocalMessageFromWs(localMessage)
        this.updateIsBellRedDot()
    }

    /**
     * socket end
     */

    /**
     * https start
     */
    private fetchGetMessageBeforeNow = (orgId: string) => {
        return fetchGetMessageBeforeNow(orgId).then((messages) => {
            return messages.sort((a, b) => b.createTime - a.createTime)
        })
    }

    private fetchGetMessageGetLast = (orgId: string) => {
        return fetchGetMessageGetLast(orgId).catch(() => 0)
    }

    private fetchPostMessageViewMark = (orgId: string) => {
        return fetchPostMessageViewMark(orgId)
    }

    private fetchGetMessageBeforeId = (orgId: string, messageId?: MessageId) => {
        return fetchGetMessageBeforeId(orgId, messageId).then((messages) => {
            return messages.sort((a, b) => b.createTime - a.createTime)
        })
    }

    private fetchDeleteMessageReadAll = (orgId: string) => {
        return fetchDeleteMessageReadAll(orgId)
    }

    private fetchDeleteMessageReadSome = (orgId: string, messageIds: MessageId[]) => {
        return fetchDeleteMessageReadSome(orgId, messageIds)
    }

    private fetchResolveJoinTeam = (applicationId: number, approve: boolean, role: RoleStatus) => {
        return fetchResolveJoinTeam(applicationId, approve, role)
    }

    private fetchResolveSeatApplication = (applicationId: number, approve: boolean) => {
        return fetchResolveSeatApplication({ applicationId, approve })
    }

    /**
     * https end
     */

    private initMessageCenter = (orgId: string) => {
        this.zustandStore.setState({
            initLoading: true,
        })
        Promise.all([this.fetchGetMessageGetLast(orgId), this.fetchGetMessageBeforeNow(orgId)])
            .then(([time, messages]) => {
                this.lastOpenMessageCenterTime = time
                this.httpMessagesMap = new Map(messages.map((message) => [message.id, message]))
                this.wsMessagesMap.clear()

                const localMessages = transformHtmlMessages2Local(messages)
                this.zustandStore.setState({
                    localMessages: localMessages,
                    initLoading: false,
                })
                this.updateIsBellRedDot()
            })
            .catch(() => {
                this.lastOpenMessageCenterTime = 0
                this.httpMessagesMap = new Map()
                this.wsMessagesMap.clear()
                this.zustandStore.setState({
                    localMessages: [],
                })
                this.updateIsBellRedDot()
            })
    }

    private addLocalMessageFromWs = (localMessage: LocalMessage) => {
        const localMessages = this.zustandStore.getState().localMessages
        const newLocalMessages = unshiftLocalMessages(localMessage, [...localMessages])
        this.zustandStore.setState({
            localMessages: newLocalMessages,
        })
    }

    private deleteMessage = (messageId: MessageId) => {
        this.httpMessagesMap.delete(messageId)
        this.wsMessagesMap.delete(messageId)
        const localMessages = this.zustandStore.getState().localMessages
        // 删除的消息不会是评论，这里直接过滤就可以了
        const newLocalMessages = localMessages.filter((localMessage) => localMessage.id !== messageId)
        if (localMessages.length !== newLocalMessages.length) {
            this.zustandStore.setState({
                localMessages: newLocalMessages,
            })
        }
    }

    private readMessage = (messageId: MessageId, messageIds: MessageId[]) => {
        messageIds.forEach((id) => {
            const httpMessage = this.httpMessagesMap.get(id)
            const wsMessages = this.wsMessagesMap.get(id)
            if (httpMessage) {
                httpMessage.read = true
            }
            if (wsMessages) {
                wsMessages.read = true
            }
        })
        const localMessages = this.zustandStore.getState().localMessages
        let shouldUpdateLocalMessage = false
        const newLocalMessage = localMessages.map((v) => {
            if (v.id === messageId && !v.read) {
                shouldUpdateLocalMessage = true
                return { ...v, read: true }
            }
            return v
        })
        if (shouldUpdateLocalMessage) {
            this.zustandStore.setState({
                localMessages: newLocalMessage,
            })
        }
    }

    private readAllMessages = () => {
        this.httpMessagesMap.forEach((httpMessage) => {
            httpMessage.read = true
        })
        this.wsMessagesMap.forEach((wsMessage) => {
            wsMessage.read = true
        })
        const localMessages = this.zustandStore.getState().localMessages
        this.zustandStore.setState({
            localMessages: localMessages.map((v) => ({ ...v, read: true })),
        })
    }

    private updateIsBellRedDot = () => {
        const localMessages = this.zustandStore.getState().localMessages
        const hasNewMessage = localMessages.some((v) => v.createTime > this.lastOpenMessageCenterTime && !v.read)
        this.zustandStore.setState({
            isBellRedDot: hasNewMessage,
        })
    }

    private getSpaceUrlPrefix = () => {
        if (this.orgId === '-1') {
            return `https://${domLocation().host}/${RouteToken.OrganizationWithoutOrgId}`
        }
        return `https://${domLocation().host}/${RouteToken.OrganizationWithoutOrgId}/${this.orgId}`
    }

    private getEditorUrlPrefix = () => {
        return `https://${domLocation().host}/file`
    }

    public openDocumentTab = (docId: string) => {
        window.open(`${this.getEditorUrlPrefix()}/${docId}`, '_blank')
    }

    public openDocumentPageTab = (docId: string, pageId: string) => {
        window.open(`${this.getEditorUrlPrefix()}/${docId}?nodeId=${pageId}`, '_blank')
    }

    public openCommentTab = (docId: string, commentId: CommentId, nodeId: string, pageId: string) => {
        const _nodeId = nodeId ? nodeId : pageId
        window.open(`${this.getEditorUrlPrefix()}/${docId}?commentId=${commentId}&nodeId=${_nodeId}`, '_blank')
    }

    public openTeamMemberTab = (teamId: string) => {
        window.open(`${this.getSpaceUrlPrefix()}/${RouteToken.Team}/${teamId}/members`, '_blank')
    }

    public openTeamTab = (teamId: string) => {
        window.open(`${this.getSpaceUrlPrefix()}/${RouteToken.Team}/${teamId}`, '_blank')
    }

    public openTeamsTab = () => {
        window.open(`${this.getSpaceUrlPrefix()}/${RouteToken.Teams}`, '_blank')
    }

    public openProjectTab = (projectId: string) => {
        window.open(`${this.getSpaceUrlPrefix()}/${RouteToken.Project}/${projectId}`, '_blank')
    }

    public openPrototypeTab = (url: string) => {
        window.open(url, '_blank')
    }
}

export const messageCenterService = new MessageCenterService()
