import { wkDialogConfirm } from '../../../../ui-lib/src'
import { PayPlanType } from '../../kernel/interface/type'
import { translation } from './pay-lock-dialog.translation'

export const PayPlanLockedDialog = (
    scene: 'ShareFile' | 'MoveFile',
    lockedType: 'invoiceOverdue' | 'trialExpiry',
    isAdmin: boolean,
    planType: PayPlanType.organization | PayPlanType.professional
) => {
    let title: string
    switch (scene) {
        case 'ShareFile':
            title = translation('ShareFileTitle')
            break
        case 'MoveFile':
            title = translation('MoveFileTitle')
            break
    }

    const contentOfTeamLock = isAdmin
        ? translation('TeamInvoiceOverdueContentOfAdmin')
        : translation('TeamInvoiceOverdueContentOfMember')

    const contentOfOrgLock =
        lockedType == 'invoiceOverdue'
            ? isAdmin
                ? translation('OrgInvoiceOverdueContentOfAdmin')
                : translation('OrgInvoiceOverdueContentOfMember')
            : isAdmin
            ? translation('OrgTrialExpiryContentOfAdmin')
            : translation('OrgTrialExpiryContentOfMember')

    const content = planType == PayPlanType.organization ? contentOfOrgLock : contentOfTeamLock

    wkDialogConfirm.error({
        wrapperTestId: 'pay-plan-locked-dialog',
        title: title,
        content: content,
        okText: translation('Iknow'),
        hideCancelButton: true,
        closable: true,
    })
}
