import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    ResetPassword: '设置密码',
    YourPasswordHas: '密码已重置',
    IncorrectVerificationCode: '验证码校验不通过',
    DQxQQv: '输入验证码',
    Password: '输入密码',
    ToNehW: '两次输入密码不一致',
    IkmzAl: '再次输入密码',
    'password-too-short': '密码请不要少于8位',
    'password-must-include-two-of-digits-uppercase-lowercase': '密码必须包括数字、大写字母、小写字母中的两种',
    'reset-password-not-match': '两次输入密码不一致',
} as const

export const enTranslation = {
    ResetPassword: 'Reset password',
    YourPasswordHas: 'Password reset',
    IncorrectVerificationCode: 'Incorrect verification code',
    DQxQQv: 'Verification code',
    Password: 'Password',
    ToNehW: 'Inconsistent entry',
    IkmzAl: 'Confirm password',
    'password-too-short': 'Passwords must have at least 8 characters.',
    'password-must-include-two-of-digits-uppercase-lowercase':
        'Passwords must contain at least two of the following: numbers, upper case letters and lower case letters.',
    'reset-password-not-match': 'Inconsistent entry',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
