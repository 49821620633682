/* eslint-disable no-restricted-imports */
import classNames from 'classnames'
import React, { useCallback, useRef } from 'react'
import { ScrollView, TabElement, isRightClick } from '../../../../../../../../ui-lib/src'
import { ToKeyCode } from '../../../../../../document/util/keycode'
import { StyleToolTip } from '../../style-tooltip/style-tooltip'
import style from '../style-color-grid-item.module.less'
import { PaintStyleThumbnail } from '../style-thumbnail'
import { UnknowRemoteStyleProps } from './unknow-style-color-list-item'

export function UnknowStyleColorGridItem(props: UnknowRemoteStyleProps) {
    const { item, docId, onSelectItem, onContextMenu, isRightClickStyle } = props
    const { id, name, description } = props.item
    const containerRef = useRef<HTMLDivElement>(null)

    const _onContextMenu: React.MouseEventHandler<HTMLDivElement> = useCallback(
        (e) => {
            e.stopPropagation()
            e.preventDefault()
            onContextMenu?.(
                {
                    id: id,
                    name: name || '',
                    description: description || '',
                    groupName: '',
                    styleName: '',
                },
                e,
                item.publishFile,
                docId
            )
        },
        [description, docId, id, item.publishFile, name, onContextMenu]
    )

    const _onMouseDown = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (isRightClick(e)) {
                return
            }
            const value = {
                id: id,
                name: name || '',
                description: description || '',
                groupName: '',
                styleName: '',
            }
            onSelectItem(value)
        },
        [description, id, name, onSelectItem]
    )

    const _onKeydown = useCallback(
        (e: any) => {
            if (e.keyCode === ToKeyCode.Enter) {
                e.stopPropagation()
                _onMouseDown(e)
            }
        },
        [_onMouseDown]
    )
    return (
        <StyleToolTip titleText={item.name || ''} contentText={item.description}>
            <ScrollView.Item
                uniqueKey={item.id}
                ref={containerRef}
                onContextMenu={_onContextMenu}
                onMouseDown={_onMouseDown}
                selectClassName={style.selectItem}
                className={classNames(
                    style.gridItem,
                    isRightClickStyle && style['right-click-menu-visible'],
                    props.className
                )}
                onKeyDown={_onKeydown}
            >
                <TabElement></TabElement>
                <PaintStyleThumbnail
                    className={style.thumbnail}
                    paints={item.paints}
                    width={props.imageSize || 24}
                    height={props.imageSize || 24}
                    multiple={2}
                />
            </ScrollView.Item>
        </StyleToolTip>
    )
}
