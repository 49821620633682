import { Action, transaction as txn } from 'signal-transaction'
import { TraceableAbortSignal } from './traceable-abort-controller'

/**
 * 封装 signal-transaction 的 transaction 方法，使其支持 trace 能力
 * @param signal
 */
export function transaction(signal: TraceableAbortSignal) {
    const signalRelatedActions: { name: string; actions: string[] } = {
        name: signal.name,
        actions: [],
    }

    const { act } = txn(signal)

    return {
        act: (description: string, action: Action) => {
            act(action)
            signalRelatedActions.actions.push(description)
        },
        trace: (): Readonly<typeof signalRelatedActions> => signalRelatedActions,
    }
}
