import { domLocation } from '../../../../../util/src'
import { IKeyValue } from '../protobuf/proto'
import { LogValue } from './type'

/**
 * 返回浏览器和页面地址的通用日志数据
 * @returns commonHeaders
 */
export function getCommonHeader() {
    const screenOrientation = window.screen.orientation?.type

    return {
        location: domLocation().href,
        userAgent: navigator.userAgent,
        devicePixelRatio: window.devicePixelRatio,
        screenWidth: window.screen.availWidth,
        screenHeight: window.screen.availHeight,
        screenOrientation,
    }
}
/**
 * 生成一个随机字符串，作为traceId
 * 规则为`traceId_${timestamp}_${random}`
 */
export function generateTraceId() {
    return `traceId_${Date.now()}_${Math.random().toString().slice(2, 8)}`
}

/**
 * 将对象转换为IKeyValue数组
 * @param values 对象
 */

export function transObjectToKeyValue(values: LogValue) {
    const keys = Object.keys(values)
    return keys.map((key) => {
        if (typeof values[key] === 'object') {
            return {
                key,
                value: JSON.stringify(values[key]),
            } as IKeyValue
        } else {
            return {
                key,
                value: `${values[key]}`,
            } as IKeyValue
        }
    })
}

/**
 * 统计performance性能指标
 */
export function getPerformanceLogData() {
    const timing = window.performance.timing
    const readyStart = timing.fetchStart - timing.navigationStart
    const redirectTime = timing.redirectEnd - timing.redirectStart
    const unloadEventTime = timing.unloadEventEnd - timing.unloadEventStart
    const lookupDomainTime = timing.domainLookupEnd - timing.domainLookupStart
    const connectTime = timing.connectEnd - timing.connectStart
    const requestTime = timing.responseEnd - timing.requestStart
    const initDomTreeTime = timing.domInteractive - timing.responseEnd
    const whiteScreenTime = timing.responseStart - timing.navigationStart
    const domReadyTime = timing.domContentLoadedEventEnd - timing.navigationStart
    const loadTime = timing.loadEventEnd - timing.navigationStart

    return {
        readyStart,
        redirectTime,
        unloadEventTime,
        lookupDomainTime,
        connectTime,
        requestTime,
        initDomTreeTime,
        whiteScreenTime,
        domReadyTime,
        loadTime,
    }
}

// 自增seqId
export const seqId = (() => {
    let uid = 1
    return () => {
        return uid++
    }
})()

export class DelayTimer {
    private _id: number | null = null
    constructor(private _ms: number, private _callback: () => void) {}

    public ensureStarted() {
        if (this._id === null) {
            this._id = window.setTimeout(() => {
                try {
                    this._callback()
                } finally {
                    this._id = null
                }
            }, this._ms)
        }
    }

    public destroy() {
        if (this._id !== null) {
            window.clearTimeout(this._id)
        }
        this._id = null
    }

    public advanceAndDestroy() {
        if (this._id !== null) {
            this.destroy()
            this._callback()
        }
    }
}
