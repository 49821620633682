type FlatPromiseReturnType<R> = R extends Promise<infer T> ? T : R
type ConcurrentTask<R> = () => R

// 并发控制器
export function ConcurrentScheduler({ delayTime, limitCount }: { limitCount: number; delayTime: number }) {
    if (limitCount <= 0 || delayTime < 0) {
        throw new Error(`limitCount 和 delayTime 请传入正整数`)
    }

    let queue: ConcurrentTask<Promise<any>>[] = []
    let run: Promise<any>[] = []
    let destroyed = false

    function schedule<R>(): Promise<FlatPromiseReturnType<R> | null> {
        if (destroyed || (!queue.length && !run.length)) {
            return new Promise<FlatPromiseReturnType<R>>(() => {})
        }
        if (run.length < limitCount && queue.length) {
            const task = queue.shift()!
            const promise: Promise<FlatPromiseReturnType<R> | null> = task()
                .then((value: FlatPromiseReturnType<R>) => {
                    run.splice(run.indexOf(promise), 1)
                    return value
                })
                .catch(() => {
                    run.splice(run.indexOf(promise), 1)
                    return null
                })
            run.push(promise)
            return promise
        } else {
            return Promise.race(run).then(() => schedule())
        }
    }

    function add<R>(task: () => R): Promise<FlatPromiseReturnType<R>> {
        return new Promise<FlatPromiseReturnType<R>>((resolve1, reject1) => {
            queue.push(
                () =>
                    new Promise<FlatPromiseReturnType<R>>((resolve2, reject2) => {
                        const timeout = setTimeout(async () => {
                            clearTimeout(timeout)

                            // 已经销毁后不再执行task 避免task执行触发异常导致jest失败
                            if (destroyed) {
                                return
                            }

                            try {
                                // eslint-disable-next-line @typescript-eslint/await-thenable
                                const result = await task()
                                // @ts-expect-error
                                resolve2(result)
                                // @ts-expect-error
                                resolve1(result)
                            } catch (err) {
                                reject2(err)
                                reject1(err)
                            }
                        }, delayTime)
                    })
            )
            schedule<R>()
        })
    }

    const destroy = () => {
        destroyed = true
        run = []
        queue = []
    }

    return { add, destroy }
}
