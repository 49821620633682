import { SelectedSingleFloatVariable } from '../common'
import { FloatVariableItem } from './float-variable-item'
import { translation } from './unkown-single-variable-item.translation'
export interface UnknownSingleVariableItem {
    data: SelectedSingleFloatVariable
    hideVariableValue?: boolean
    isSelected: boolean
    isPreselect: boolean
    onClick: () => void
    onMouseEnter: () => void
    onMouseLeave: () => void
}

export const UnknownSingleVariableItem = ({
    data,
    hideVariableValue,
    isSelected,
    isPreselect,
    onClick,
    onMouseEnter,
    onMouseLeave,
}: UnknownSingleVariableItem) => {
    return (
        <FloatVariableItem
            name={data.variable.name}
            value={data.variable.floatValue ?? translation('Mixed')}
            hideVariableValue={hideVariableValue}
            isSelected={isSelected}
            isPreselect={isPreselect}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            dataTestId={{
                container: 'unkown-single-variable-item',
                name: 'unkown-single-variable-item-name',
                value: 'unkown-single-variable-item-value',
            }}
        />
    )
}
