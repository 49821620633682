import { translation } from './fills.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { IconAdd16, IconStyle, SimpleDrag, Tooltip } from '../../../../../../ui-lib/src'
import type { ColorStop, ImagePaint, Paint as PaintType, Transform } from '../../../../document/node/node'
import { IconButton } from '../../atom/button/icon-button'
import { SingleGrid } from '../../atom/grid/single-grid'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { Title } from '../../atom/title/title'
import { useRenderColorSpace } from '../../color-profile'
import { RGB } from '../blend/color-picker/utils/color-translate'
import { ColorInteraction } from '../color-interaction/color-interaction'
import { ColorInteractionFrom, TypeOnChangeStyle } from '../color-interaction/type'
import { ColorPaint } from '../paint/color-paint'
import { StylePaintColor } from '../styles/style-color/style-paint-color'
import style from './fills.module.less'
import { useFills } from './use-fills'

export function Fills(): JSX.Element {
    const {
        selectedFills,
        modelState,
        isEmptyFill,
        styleInfo,
        onSelectChange,
        onDragDone,
        onClickAddPaint,
        onClickTitle,
        onChangeColor,
        onChangeVisible,
        onChangeOpacity,
        onChangeImagePaint,
        onClickSub,
        onChangeModalVisible,
        isOpen,
        onChangePaintType,
        onChangeColorStops,
        onChangeTransform,
        onClickDeleteStyle,
        onClickCutLink,
        onChangeStyle,
        onChangeBlendMode,
        openStyle,
        stylePosition,
        onClickStyle,
        onDetachColorVar,
        switchOpenStyleState,
        onChangeColorVar,
        onChangePaints2ColorVar,
        onChangePaints,
        onChangePaints2,
    } = useFills()
    const colorSpace = useRenderColorSpace()
    const isMixed = modelState.type === 'mixed' || styleInfo.length > 1

    return (
        <div className={classnames('pt-8px', isEmptyFill ? 'pb-8px' : 'pb-12px')} data-testid="fills">
            <Title
                grayTheme={isEmptyFill}
                onClick={onClickTitle}
                className={isEmptyFill && !openStyle ? style.emptyTitle : ''}
            >
                <Title.Left>{translation('Fill')}</Title.Left>
                <Title.Right>
                    {modelState.type !== 'mixed' && styleInfo.length === 1 ? null : (
                        <Tooltip title={translation('StyleAndVariable')}>
                            <IconButton
                                icon={<IconStyle />}
                                selected={openStyle}
                                onClick={onClickStyle}
                                deepColor
                                className={style.styleIcon}
                                dataTestId="add-fill-style"
                            />
                        </Tooltip>
                    )}
                    {modelState.type === 'mixed' || styleInfo.length !== 1 ? (
                        <IconButton
                            icon={<IconAdd16 />}
                            selected={false}
                            onClick={onClickAddPaint}
                            dataTestId="fill-add"
                        />
                    ) : null}
                </Title.Right>
            </Title>
            {isMixed ? (
                <SingleGrid>
                    <SingleGrid.Item start={5} span={55} className={style.mixedTipText}>
                        {translation('Click')} + {translation('ToReplace')}
                    </SingleGrid.Item>
                </SingleGrid>
            ) : styleInfo.length === 1 ? (
                <StylePaintColor
                    paints={styleInfo[0].paints}
                    name={styleInfo[0].name}
                    description={styleInfo[0].description}
                    isOpen={openStyle}
                    onClickSub={() => onClickDeleteStyle()}
                    onClickStyle={onClickStyle}
                    onClickCutLink={() => onClickCutLink(styleInfo[0].paints)}
                />
            ) : modelState.type === 'normal' && modelState.paints?.length ? (
                <ReversedFills
                    paints={modelState.paints}
                    selectedIndexes={selectedFills?.indexes ?? []}
                    colorSpace={colorSpace}
                    variables={modelState.variables}
                    isOpen={isOpen}
                    onSelectChange={onSelectChange}
                    onDragDone={onDragDone}
                    onChangeModalVisible={onChangeModalVisible}
                    onChangeColor={onChangeColor}
                    onChangeColorStops={onChangeColorStops}
                    onChangeOpacity={onChangeOpacity}
                    onChangeVisible={onChangeVisible}
                    onChangeImagePaint={onChangeImagePaint}
                    onChangePaintType={onChangePaintType}
                    onChangeBlendMode={onChangeBlendMode}
                    onChangeTransform={onChangeTransform}
                    onClickDelete={onClickSub}
                    onChangeStyle={onChangeStyle}
                    onDetachColorVar={onDetachColorVar}
                    onChangeColorVar={onChangeColorVar}
                    onChangePaints={onChangePaints}
                    onClickCreateStyleButton={() => switchOpenStyleState(false)}
                ></ReversedFills>
            ) : null}
            {openStyle
                ? stylePosition && (
                      <div>
                          <ColorInteraction
                              from={ColorInteractionFrom.FILL}
                              position={stylePosition}
                              paints={
                                  modelState.type === 'normal'
                                      ? modelState.paints.slice()
                                      : styleInfo.length === 1
                                      ? styleInfo[0].paints.slice()
                                      : []
                              }
                              styleId={styleInfo.length === 1 ? styleInfo[0].node : undefined}
                              styleKey={styleInfo.length === 1 ? styleInfo[0].key : ''}
                              onCancel={() => switchOpenStyleState(false)}
                              onChangePaintType={onChangePaintType(0)}
                              onChangeBlendMode={onChangeBlendMode(0)}
                              onChangeColor={onChangeColor(0)}
                              onChangeOpacity={onChangeOpacity(0)}
                              onChangeImagePaint={onChangeImagePaint(0)}
                              onChangeColorStops={onChangeColorStops(0)}
                              onChangeTransform={onChangeTransform(0)}
                              enterClose={() => onChangeModalVisible(0)(false)}
                              onChangeStyle={onChangeStyle}
                              onChangeColorVar={onChangePaints2ColorVar}
                              onChangePaints={onChangePaints2}
                              onClickCreateStyleButton={undefined}
                          />
                      </div>
                  )
                : null}
        </div>
    )
}

function ReversedFills(props: {
    readonly paints: ReturnType<typeof useFills>['modelState']['paints']
    readonly selectedIndexes: number[]
    readonly colorSpace: ReturnType<typeof useRenderColorSpace>
    readonly variables: ReturnType<typeof useFills>['modelState']['variables']
    readonly isOpen: ReturnType<typeof useFills>['isOpen']
    readonly onSelectChange: ReturnType<typeof useFills>['onSelectChange']
    readonly onDragDone: ReturnType<typeof useFills>['onDragDone']
    readonly onChangeModalVisible: ReturnType<typeof useFills>['onChangeModalVisible']
    readonly onChangeColor: ReturnType<typeof useFills>['onChangeColor']
    readonly onChangeColorStops: ReturnType<typeof useFills>['onChangeColorStops']
    readonly onChangeOpacity: ReturnType<typeof useFills>['onChangeOpacity']
    readonly onChangeVisible: ReturnType<typeof useFills>['onChangeVisible']
    readonly onChangeImagePaint: ReturnType<typeof useFills>['onChangeImagePaint']
    readonly onChangePaintType: ReturnType<typeof useFills>['onChangePaintType']
    readonly onChangeBlendMode: ReturnType<typeof useFills>['onChangeBlendMode']
    readonly onChangeTransform: ReturnType<typeof useFills>['onChangeTransform']
    readonly onClickDelete: ReturnType<typeof useFills>['onClickSub']
    readonly onDetachColorVar: ReturnType<typeof useFills>['onDetachColorVar']
    readonly onChangeStyle: TypeOnChangeStyle
    readonly onChangeColorVar: ReturnType<typeof useFills>['onChangeColorVar']
    readonly onChangePaints: ReturnType<typeof useFills>['onChangePaints']
    readonly onClickCreateStyleButton: () => void
}) {
    const paints = (props.paints ?? []).slice().reverse()
    const selectedIndexes = props.selectedIndexes.map((index) => paints.length - 1 - index)
    const onSelectChange = (indexes: number[]) => {
        props.onSelectChange(indexes.map((index) => paints.length - 1 - index))
    }
    const onDragDone = (items: PaintType[], indexes: number[]) => {
        const newItems = items.slice().reverse()
        props.onDragDone(
            newItems,
            indexes.map((index) => newItems.length - 1 - index)
        )
    }
    const isOpen = (index: number) => props.isOpen(paints.length - 1 - index)
    const onChangeModalVisible = (index: number) => (visible: boolean) => {
        props.onChangeModalVisible(paints.length - 1 - index)(visible)
    }
    const onChangeColor = (index: number) => (color: RGB, options?: InputOptionsForUndoSquash) => {
        props.onChangeColor(paints.length - 1 - index)(color, options)
    }
    const onChangeColorStops = (index: number) => (v: ColorStop[], options?: InputOptionsForUndoSquash) => {
        props.onChangeColorStops(paints.length - 1 - index)(v, options)
    }
    const onChangeOpacity = (index: number) => (opacity: number, options?: InputOptionsForUndoSquash) => {
        props.onChangeOpacity(paints.length - 1 - index)(opacity, options)
    }
    const onChangeVisible = (index: number) => (visible: boolean) => {
        props.onChangeVisible(paints.length - 1 - index)(visible)
    }
    const onChangeImagePaint = (index: number) => (data: ImagePaint, options?: InputOptionsForUndoSquash) => {
        props.onChangeImagePaint(paints.length - 1 - index)(data, options)
    }
    const onChangePaintType =
        (index: number) =>
        (paintType: Wukong.DocumentProto.PaintType, paint: Wukong.DocumentProto.IPaint | undefined | null) => {
            props.onChangePaintType(paints.length - 1 - index)(paintType, paint)
        }
    const onChangeBlendMode = (index: number) => (value: Wukong.DocumentProto.BlendMode) => {
        props.onChangeBlendMode(paints.length - 1 - index)(value)
    }
    const onChangeTransform = (index: number) => (transform: Transform) => {
        props.onChangeTransform(paints.length - 1 - index)(transform)
    }
    const onClickDelete = (index: number) => () => {
        props.onClickDelete(paints.length - 1 - index)()
    }
    const onDetachColorVar = (index: number) => () => {
        props.onDetachColorVar(paints.length - 1 - index)()
    }
    const onChangeColorVar = (index: number) => (colorVar: Wukong.DocumentProto.IVariableAliasData) => {
        props.onChangeColorVar(paints.length - 1 - index)(colorVar)
    }
    return (
        <SimpleDrag<PaintType>
            selectedIndexList={selectedIndexes}
            items={paints}
            onSelectChange={onSelectChange}
            onDragDone={onDragDone}
            onMouseDown={(e) => e.stopPropagation()}
            dataTestIds={{
                simpleDrag: 'fill-list',
            }}
        >
            {paints.map((paint, index) => (
                <SimpleDrag.Item
                    key={index}
                    itemIndex={index}
                    dataTestIds={{ item: `fill-${index}`, dragIcon: `fill-${index}-icon` }}
                >
                    <ColorPaint
                        useIn="fill"
                        key={index}
                        selected={selectedIndexes.includes(index)}
                        paint={paint}
                        paints={props.paints as Wukong.DocumentProto.IPaint[]}
                        colorSpace={props.colorSpace}
                        variables={props.variables}
                        isOpen={isOpen(index)}
                        onChangeModalVisible={onChangeModalVisible(index)}
                        onChangeColor={onChangeColor(index)}
                        onChangeColorStops={onChangeColorStops(index)}
                        onChangeOpacity={onChangeOpacity(index)}
                        onChangeVisible={onChangeVisible(index)}
                        onChangeImagePaint={onChangeImagePaint(index)}
                        onChangePaintType={onChangePaintType(index)}
                        onChangeBlendMode={onChangeBlendMode(index)}
                        onChangeTransform={onChangeTransform(index)}
                        onClickSub={onClickDelete(index)}
                        onChangeStyle={props.onChangeStyle}
                        onDetachColorVar={onDetachColorVar(index)}
                        onChangeColorVar={onChangeColorVar(index)}
                        onChangePaints={props.onChangePaints}
                        onClickCreateStyleButton={props.onClickCreateStyleButton}
                    />
                </SimpleDrag.Item>
            ))}
        </SimpleDrag>
    )
}
