/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import {
    IconAbsolutePositionInstance,
    IconComponent16,
    IconExampleImage,
    IconHorizentalCenter,
    IconHorizentalTop,
    IconInstance,
    IconVerticalCenter,
    IconVerticalLeft,
    IconVerticalRight,
    MonoIconlayerAbsoluteCandidateComponents16,
    MonoIconlayerCandidateComponents16,
} from '../../../../../../../../ui-lib/src'
import classNames from 'classnames'
import { FC, useMemo } from 'react'
import styles from './index.module.less'
import {
    IconAIAutoLayoutWrapCenter,
    IconAIAutoLayoutWrapCenterAbsolute,
    IconAIAutoLayoutWrapLeft,
    IconAIAutoLayoutWrapLeftAbsolute,
    IconAIAutoLayoutWrapRight,
    IconAIAutoLayoutWrapRightAbsolute,
    IconAIFrame,
    IconAIFrameAbsolute,
    IconAIGroup,
    IconAIGroupAbsolute,
    IconAIHorizontalBottom,
    IconAIHorizontalBottomAbsolute,
    IconAIHorizontalCenter,
    IconAIHorizontalCenterAbsolute,
    IconAIHorizontalTop,
    IconAIHorizontalTopAbsolute,
    IconAIVerticalCenter,
    IconAIVerticalCenterAbsolute,
    IconAIVerticalLeft,
    IconAIVerticalLeftAbsolute,
    IconAIVerticalRight,
    IconAIVerticalRightAbsolute,
    IconAutoLayoutBottom,
    IconAutoLayoutBottomAbsolute,
    IconAutoLayoutCenterHorizentalAbsolute,
    IconAutoLayoutCenterVerticalAbsolute,
    IconAutoLayoutLeftAbsolute,
    IconAutoLayoutRightAbsolute,
    IconAutoLayoutTopAbsolute,
    IconAutoLayoutWrapCenter,
    IconAutoLayoutWrapCenterAbsolute,
    IconAutoLayoutWrapLeft,
    IconAutoLayoutWrapLeftAbsolute,
    IconAutoLayoutWrapRight,
    IconAutoLayoutWrapRightAbsolute,
    IconComponentAbsoulute,
    IconExclude,
    IconExcludeAbsolute,
    IconFrame,
    IconFrameAbsolute,
    IconGroup,
    IconGroupAbsolute,
    IconImageAbsolute,
    IconIntersect,
    IconIntersectAbsolute,
    IconMask,
    IconMaskAbsolute,
    IconPolygon,
    IconPolygonAbsolute,
    IconSlice,
    IconSliceAbsolute,
    IconStar,
    IconStarAbsolute,
    IconSubtract,
    IconSubtractAbsoulte,
    IconText,
    IconTextAbsolute,
    IconUnion,
    IconUnionAbsolute,
    IconVariants,
    IconVariantsAbsolute,
    IconVector,
    IconVectorAbsolute,
} from './svg'
import NodeIconType = Wukong.DocumentProto.NodeIconType

interface DevModeLayerPanelIconProps {
    nodeIcon: Wukong.DocumentProto.NodeIconData
    isSelected: boolean
    isPurple: boolean
    isFontBold: boolean
    shouldShowIcon: boolean
    isAsset: boolean
}

enum SvgFillColor {
    Normal = 'normal',
    PurpleNormal = 'purpleNormal',
    Shallow = 'shallow',
    PurpleShallow = 'purpleShallow',
}

export const getIconStyle = (params: { isPurple: boolean; isFontBold: boolean; isSelected: boolean }) => {
    const { isPurple, isFontBold, isSelected } = params
    if (isFontBold) {
        return SvgFillColor.Normal
    }
    if (isSelected) {
        return isPurple ? SvgFillColor.PurpleNormal : SvgFillColor.Normal
    }
    return isPurple ? SvgFillColor.PurpleShallow : SvgFillColor.Shallow
}

export const NodeIconTypeMap = {
    [NodeIconType.NODE_ICON_TYPE_CUSTOM]: (path: string) => (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d={path} fill="none" strokeWidth="1" strokeLinecap="square" />
        </svg>
    ),
    [NodeIconType.NODE_ICON_TYPE_COMPONENT]: <IconComponent16 />,
    [NodeIconType.NODE_ICON_TYPE_COMPONENT_ABSOLUTE]: <IconComponentAbsoulute />,
    [NodeIconType.NODE_ICON_TYPE_INSTANCE]: <IconInstance />,
    [NodeIconType.NODE_ICON_TYPE_INSTANCE_ABSOLUTE]: <IconAbsolutePositionInstance />,
    [NodeIconType.NODE_ICON_TYPE_FRAME]: <IconFrame />,
    [NodeIconType.NODE_ICON_TYPE_FRAME_ABSOLUTE]: <IconFrameAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_CANDIDATE_COMPONENT]: <MonoIconlayerCandidateComponents16 stroke={'none'} />,
    [NodeIconType.NODE_ICON_TYPE_CANDIDATE_COMPONENT_ABSOLUTE]: (
        <MonoIconlayerAbsoluteCandidateComponents16 stroke={'none'} />
    ),
    [NodeIconType.NODE_ICON_TYPE_VARIANT]: <IconVariants />,
    [NodeIconType.NODE_ICON_TYPE_VARIANT_ABSOLUTE]: <IconVariantsAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_GROUP]: <IconGroup />,
    [NodeIconType.NODE_ICON_TYPE_GROUP_ABSOLUTE]: <IconGroupAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_TEXT]: <IconText />,
    [NodeIconType.NODE_ICON_TYPE_TEXT_ABSOLUTE]: <IconTextAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_POLYGON]: <IconPolygon />,
    [NodeIconType.NODE_ICON_TYPE_POLYGON_ABSOLUTE]: <IconPolygonAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_STAR]: <IconStar />,
    [NodeIconType.NODE_ICON_TYPE_STAR_ABSOLUTE]: <IconStarAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_VECTOR]: <IconVector />,
    [NodeIconType.NODE_ICON_TYPE_VECTOR_ABSOLUTE]: <IconVectorAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_BOOL_UNION]: <IconUnion />,
    [NodeIconType.NODE_ICON_TYPE_BOOL_UNION_ABSOLUTE]: <IconUnionAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_BOOL_INTERSECT]: <IconIntersect />,
    [NodeIconType.NODE_ICON_TYPE_BOOL_INTERSECT_ABSOLUTE]: <IconIntersectAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_BOOL_SUBTRACT]: <IconSubtract />,
    [NodeIconType.NODE_ICON_TYPE_BOOL_SUBTRACT_ABSOLUTE]: <IconSubtractAbsoulte />,
    [NodeIconType.NODE_ICON_TYPE_BOOL_EXCLUDE]: <IconExclude />,
    [NodeIconType.NODE_ICON_TYPE_BOOL_EXCLUDE_ABSOLUTE]: <IconExcludeAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_AUTO_LAYOUT_TOP]: <IconHorizentalTop />,
    [NodeIconType.NODE_ICON_TYPE_AUTO_LAYOUT_TOP_ABSOLUTE]: <IconAutoLayoutTopAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_AUTO_LAYOUT_CENTER_VERTICAL]: <IconVerticalCenter />,
    [NodeIconType.NODE_ICON_TYPE_AUTO_LAYOUT_CENTER_VERTICAL_ABSOLUTE]: <IconAutoLayoutCenterVerticalAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_AUTO_LAYOUT_BOTTOM]: <IconAutoLayoutBottom />,
    [NodeIconType.NODE_ICON_TYPE_AUTO_LAYOUT_BOTTOM_ABSOLUTE]: <IconAutoLayoutBottomAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_AUTO_LAYOUT_LEFT]: <IconVerticalLeft />,
    [NodeIconType.NODE_ICON_TYPE_AUTO_LAYOUT_LEFT_ABSOLUTE]: <IconAutoLayoutLeftAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_AUTO_LAYOUT_CENTER_HORIZONTAL]: <IconHorizentalCenter />,
    [NodeIconType.NODE_ICON_TYPE_AUTO_LAYOUT_CENTER_HORIZONTAL_ABSOLUTE]: <IconAutoLayoutCenterHorizentalAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_AUTO_LAYOUT_RIGHT]: <IconVerticalRight />,
    [NodeIconType.NODE_ICON_TYPE_AUTO_LAYOUT_RIGHT_ABSOLUTE]: <IconAutoLayoutRightAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_IMAGE]: <IconExampleImage />,
    [NodeIconType.NODE_ICON_TYPE_IMAGE_ABSOLUTE]: <IconImageAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_MASK]: <IconMask />,
    [NodeIconType.NODE_ICON_TYPE_MASK_ABSOLUTE]: <IconMaskAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_SLICE]: <IconSlice />,
    [NodeIconType.NODE_ICON_TYPE_SLICE_ABSOLUTE]: <IconSliceAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_A_I_FRAME]: <IconAIFrame />,
    [NodeIconType.NODE_ICON_TYPE_A_I_FRAME_ABSOLUTE]: <IconAIFrameAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_A_I_GROUP]: <IconAIGroup />,
    [NodeIconType.NODE_ICON_TYPE_A_I_GROUP_ABSOLUTE]: <IconAIGroupAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_A_I_HORIZONTAL_BOTTOM]: <IconAIHorizontalBottom />,
    [NodeIconType.NODE_ICON_TYPE_A_I_HORIZONTAL_BOTTOM_ABSOLUTE]: <IconAIHorizontalBottomAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_A_I_HORIZONTAL_CENTER]: <IconAIHorizontalCenter />,
    [NodeIconType.NODE_ICON_TYPE_A_I_HORIZONTAL_CENTER_ABSOLUTE]: <IconAIHorizontalCenterAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_A_I_HORIZONTAL_TOP]: <IconAIHorizontalTop />,
    [NodeIconType.NODE_ICON_TYPE_A_I_HORIZONTAL_TOP_ABSOLUTE]: <IconAIHorizontalTopAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_A_I_VERTICAL_LEFT]: <IconAIVerticalLeft />,
    [NodeIconType.NODE_ICON_TYPE_A_I_VERTICAL_LEFT_ABSOLUTE]: <IconAIVerticalLeftAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_A_I_VERTICAL_CENTER]: <IconAIVerticalCenter />,
    [NodeIconType.NODE_ICON_TYPE_A_I_VERTICAL_CENTER_ABSOLUTE]: <IconAIVerticalCenterAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_A_I_VERTICAL_RIGHT]: <IconAIVerticalRight />,
    [NodeIconType.NODE_ICON_TYPE_A_I_VERTICAL_RIGHT_ABSOLUTE]: <IconAIVerticalRightAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_A_I_AUTO_LAYOUT_WRAP_LEFT]: <IconAIAutoLayoutWrapLeft />,
    [NodeIconType.NODE_ICON_TYPE_A_I_AUTO_LAYOUT_WRAP_CENTER]: <IconAIAutoLayoutWrapCenter />,
    [NodeIconType.NODE_ICON_TYPE_A_I_AUTO_LAYOUT_WRAP_RIGHT]: <IconAIAutoLayoutWrapRight />,
    [NodeIconType.NODE_ICON_TYPE_A_I_AUTO_LAYOUT_WRAP_LEFT_ABSOLUTE]: <IconAIAutoLayoutWrapLeftAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_A_I_AUTO_LAYOUT_WRAP_CENTER_ABSOLUTE]: <IconAIAutoLayoutWrapCenterAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_A_I_AUTO_LAYOUT_WRAP_RIGHT_ABSOLUTE]: <IconAIAutoLayoutWrapRightAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_AUTO_LAYOUT_WRAP_LEFT]: <IconAutoLayoutWrapLeft />,
    [NodeIconType.NODE_ICON_TYPE_AUTO_LAYOUT_WRAP_CENTER]: <IconAutoLayoutWrapCenter />,
    [NodeIconType.NODE_ICON_TYPE_AUTO_LAYOUT_WRAP_RIGHT]: <IconAutoLayoutWrapRight />,
    [NodeIconType.NODE_ICON_TYPE_AUTO_LAYOUT_WRAP_LEFT_ABSOLUTE]: <IconAutoLayoutWrapLeftAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_AUTO_LAYOUT_WRAP_CENTER_ABSOLUTE]: <IconAutoLayoutWrapCenterAbsolute />,
    [NodeIconType.NODE_ICON_TYPE_AUTO_LAYOUT_WRAP_RIGHT_ABSOLUTE]: <IconAutoLayoutWrapRightAbsolute />,
}

export const getSVGIconByNodeIconType = (nodeIcon: Wukong.DocumentProto.NodeIconData) => {
    if (nodeIcon.type === NodeIconType.NODE_ICON_TYPE_CUSTOM) {
        return NodeIconTypeMap[nodeIcon.type](nodeIcon.path)
    }
    return NodeIconTypeMap[nodeIcon.type]
}

export const DevModeLayerPanelIcon: FC<DevModeLayerPanelIconProps> = ({
    nodeIcon,
    isSelected,
    isPurple,
    isFontBold,
    shouldShowIcon,
    isAsset,
}) => {
    const iconStyle = useMemo(
        () =>
            getIconStyle({
                isSelected,
                isPurple,
                isFontBold,
            }),
        [isSelected, isPurple, isFontBold]
    )

    return shouldShowIcon ? (
        <div
            className={classNames(styles['render-item-icon'], styles[iconStyle], {
                [styles.marginLeft]: isAsset,
            })}
        >
            {getSVGIconByNodeIconType(nodeIcon)}
        </div>
    ) : null
}
