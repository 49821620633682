import { Wukong } from '@wukong/bridge-proto'

export class Rect {
    constructor(public left: number, public top: number, public right: number, public bottom: number) {}

    public width() {
        return this.right - this.left
    }
    public height() {
        return this.bottom - this.top
    }
}

export interface Point {
    x: number
    y: number
}

export function crossMultiply(lhs: Point, rhs: Point): number {
    return lhs.x * rhs.y - lhs.y * rhs.x
}

// 此类 copy 自 figma 源码并加以修改
export class Matrix {
    private m00: number
    private m01: number
    private m02: number
    private m10: number
    private m11: number
    private m12: number

    constructor(m00: number, m01: number, m02: number, m10: number, m11: number, m12: number) {
        this.m00 = m00
        this.m01 = m01
        this.m02 = m02
        this.m10 = m10
        this.m11 = m11
        this.m12 = m12
    }
    static from(e: { m00: number; m01: number; m02: number; m10: number; m11: number; m12: number }) {
        return new Matrix(e.m00, e.m01, e.m02, e.m10, e.m11, e.m12)
    }
    static fromMatrix(e: Matrix) {
        return new Matrix(e.m00, e.m01, e.m02, e.m10, e.m11, e.m12)
    }
    static fromWukongTransform(e: Wukong.DocumentProto.ITransform) {
        return new Matrix(
            e.scaleX ?? 1,
            e.skewX ?? 0,
            e.translateX ?? 0,
            e.skewY ?? 0,
            e.scaleY ?? 1,
            e.translateY ?? 0
        )
    }
    private assign(e: Matrix) {
        this.m00 = e.m00
        this.m01 = e.m01
        this.m02 = e.m02
        this.m10 = e.m10
        this.m11 = e.m11
        this.m12 = e.m12
    }
    getScaleX() {
        return this.m00
    }
    getScaleY() {
        return this.m11
    }
    getSkewX() {
        return this.m01
    }
    getSkewY() {
        return this.m10
    }
    getTranslateX() {
        return this.m02
    }
    getTranslateY() {
        return this.m12
    }

    toWukongTransform(): Wukong.DocumentProto.ITransform {
        return {
            scaleX: this.m00,
            skewX: this.m01,
            translateX: this.m02,
            skewY: this.m10,
            scaleY: this.m11,
            translateY: this.m12,
        }
    }
    transformPoint(e: { x: number; y: number }) {
        return {
            x: this.m00 * e.x + this.m01 * e.y + this.m02,
            y: this.m10 * e.x + this.m11 * e.y + this.m12,
        }
    }
    translateBy(x: number, y: number) {
        this.m02 += x * this.m00 + y * this.m01
        this.m12 += x * this.m10 + y * this.m11
    }
    scaleBy(x: number, y: number) {
        this.m00 *= x
        this.m01 *= y
        this.m10 *= x
        this.m11 *= y
    }
    rotateBy(deg: number) {
        const t = Math.sin(deg)
        const n = Math.cos(deg)
        const i = n * this.m00 + t * this.m01
        const r = n * this.m01 - t * this.m00
        const a = n * this.m10 + t * this.m11
        const o = n * this.m11 - t * this.m10
        this.m00 = i
        this.m01 = r
        this.m10 = a
        this.m11 = o
    }
    postTranslate(x: number, y: number) {
        const mat = new Matrix(1, 0, x, 0, 1, y)
        mat.multiplyBy(this)
        this.assign(mat)
    }
    postScale(x: number, y: number) {
        const mat = new Matrix(x, 0, 0, 0, y, 0)
        mat.multiplyBy(this)
        this.assign(mat)
    }
    postRotate(deg: number) {
        const rad = (deg / 180) * Math.PI
        const s = Math.sin(rad)
        const c = Math.cos(rad)
        const mat = new Matrix(c, -s, 0, s, c, 0)
        mat.multiplyBy(this)
        this.assign(mat)
    }
    multiplyBy(e: Matrix) {
        const m00 = this.m00 * e.m00 + this.m01 * e.m10
        const m01 = this.m00 * e.m01 + this.m01 * e.m11
        const m02 = this.m00 * e.m02 + this.m01 * e.m12 + this.m02
        const m10 = this.m10 * e.m00 + this.m11 * e.m10
        const m11 = this.m10 * e.m01 + this.m11 * e.m11
        const m12 = this.m10 * e.m02 + this.m11 * e.m12 + this.m12
        this.m00 = m00
        this.m01 = m01
        this.m02 = m02
        this.m10 = m10
        this.m11 = m11
        this.m12 = m12
    }
    invert(): boolean {
        const i = this.m00 * this.m11 - this.m01 * this.m10
        if (isNear(i, 0)) {
            return false
        }

        const e = 1 / i
        const m00 = this.m11 * e
        const m01 = -this.m01 * e
        const m02 = (this.m01 * this.m12 - this.m11 * this.m02) * e
        const m10 = -this.m10 * e
        const m11 = this.m00 * e
        const m12 = (this.m10 * this.m02 - this.m00 * this.m12) * e
        this.m00 = m00
        this.m01 = m01
        this.m02 = m02
        this.m10 = m10
        this.m11 = m11
        this.m12 = m12
        return true
    }

    guessRotationAndFlip(): [number, boolean, boolean] {
        let flipX = false
        let flipY = false

        if (Math.abs(this.getScaleY()) > 0.1) {
            flipY = Boolean(Math.sign(this.getScaleY()) !== Math.sign(this.getScaleX()))
        } else {
            flipY = Boolean(Math.sign(this.getSkewX()) === Math.sign(this.getSkewY()))
        }

        let angle = (Math.atan2(-this.getSkewY(), this.getScaleX()) / Math.PI) * 180
        if (flipY) {
            angle *= -1
        }

        if ((Math.abs(angle) > 90 && Math.abs(angle) < 179) || (Math.abs(angle) > 179 && flipY)) {
            flipX = !flipX
            flipY = !flipY
            angle = (angle + 180) % 360
        }

        return [angle, flipX, flipY]
    }

    static identity() {
        return new Matrix(1, 0, 0, 0, 1, 0)
    }
}

export function isNear(x: number, y: number, eps = 1e-5) {
    return Math.abs(x - y) < eps
}

export function isPointInOneLine(points: Point[]): boolean {
    if (points.length <= 2) {
        return true
    }
    const vectors: Point[] = []
    for (let i = 1; i < points.length; i++) {
        vectors.push({
            x: points[i].x - points[0].x,
            y: points[i].y - points[0].y,
        })
    }

    for (let i = 1; i < vectors.length; i++) {
        if (!isNear(crossMultiply(vectors[0], vectors[i]), 0)) {
            return false
        }
    }
    return true
}

export function getHigh32Bits(num: number) {
    const other = parseInt(num.toString()).toString(2)
    if (other.length <= 32) {
        return 0
    }
    const highBits = other.length - 32
    return parseInt(other.slice(0, highBits), 2)
}
