import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    PleaseSelect: '请选择',
} as const

export const enTranslation = {
    PleaseSelect: 'Select',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
