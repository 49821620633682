import { Wukong } from '@wukong/bridge-proto'
import { domLocation, RouteToken } from '../../../../../util/src'
import { IN_JEST_TEST } from '../../../environment'
import { OrgID, PlanAndUserStateVO, ResourceType } from '../../../kernel/interface/type'
import { GetAllPayExpiredSettlements } from '../../../kernel/request/contract'
import { GetFolderRequest } from '../../../kernel/request/folder'
import { QueryOrgAndUserState } from '../../../kernel/request/organizations'
import { GetTeamResourceState, QueryTeamAndUserState } from '../../../kernel/request/team'
import { featureSwitchManager } from '../../../kernel/switch'
import { planUpdatingToast } from '../../../utils/payment'
import { SpaceNotifyFlow } from './notify-flow'
import { allFoldersSelector } from './teams-folders-state'
import { getSpaceStore, PlanAndUserStateStore, setSpaceStore, SpaceStoreType } from './types'

const TWO_HOURS = 2 * 60 * 60 * 1000

export const generatePlanAndUserState = (set: setSpaceStore, get: getSpaceStore): PlanAndUserStateStore => ({
    orgId: '',
    planInOrg: undefined,
    planInTeams: [],
    unpaidSettlementsMap: {},

    fetch: async ({ needFetchUnpaidSettlements, isNotify }) => {
        const orgId = get().organizationStore.organization.id
        if (!orgId) {
            return
        }

        set((state) => {
            state.planAndUserStateStore.orgId = orgId
        })

        if (orgId !== '-1') {
            await new QueryOrgAndUserState(orgId).start().then((res) => {
                set((state) => {
                    state.planAndUserStateStore.planInOrg = res
                })
            })
        } else {
            await new QueryTeamAndUserState(isNotify).start().then((res) => {
                set((state) => {
                    state.planAndUserStateStore.planInTeams = res
                })
            })
        }

        if (needFetchUnpaidSettlements) {
            get().planAndUserStateStore.fetchUnpaidSettlements()
        }
    },

    fetchUnpaidSettlements: async () => {
        const orgId = get().organizationStore.organization.id
        const resourceType = orgId !== '-1' ? ResourceType.Organization : ResourceType.Team
        const resourceIds = orgId !== '-1' ? [orgId] : get().planAndUserStateStore.planInTeams.map((p) => p.resourceId)

        const res = await new GetAllPayExpiredSettlements(resourceType, resourceIds).start()
        set((state) => {
            state.planAndUserStateStore.unpaidSettlementsMap = res
        })
        return res
    },

    getTeamPlan: (teamId: string, orgId: OrgID): PlanAndUserStateVO | undefined => {
        if (orgId == '-1') {
            return get().planAndUserStateStore.planInTeams.find(
                (plan) => plan.resourceId === teamId && plan.resourceType == ResourceType.Team
            )
        } else {
            return get().planAndUserStateStore.planInOrg
        }
    },

    getPlanByDocId: (docId: string): PlanAndUserStateVO | undefined => {
        const docVO = get().docListPageStore.docList.find((doc) => doc.id === docId)
        if (docVO?.orgId === '-1') {
            if (docVO.teamId) {
                return get().planAndUserStateStore.getTeamPlan(docVO.teamId, '-1')
            } else {
                return undefined
            }
        } else {
            return get().planAndUserStateStore.planInOrg
        }
    },

    checkPlanInUpdatingByTeamId: (teamId: string, teamName: string) => {
        const teamPlan = get().planAndUserStateStore.getTeamPlan(teamId, '-1')
        if (teamPlan?.planUpdating) {
            planUpdatingToast(teamName)
            return true
        }
        return false
    },

    checkPlanIsUpdatingByFolderId: async (folderId: string | undefined, sync?: boolean) => {
        if (folderId) {
            const folderVO = sync
                ? allFoldersSelector(get()).find((folder) => folder.id === folderId)
                : await new GetFolderRequest(folderId).start()
            if (folderVO && get().planAndUserStateStore.getTeamPlan(folderVO.teamId, '-1')?.planUpdating) {
                planUpdatingToast(folderVO.teamName)
                return true
            }
        }
        return false
    },

    checkPlanIsUpdatingByDocs: (docIds: string[]) => {
        if (!docIds.length) {
            return false
        }

        docIds.forEach((docId) => {
            const docVO = get().docListPageStore.docList.find((doc) => doc.id === docId)
            if (docVO?.orgId === '-1' && docVO.teamId) {
                const teamPlan = get().planAndUserStateStore.getTeamPlan(docVO.teamId, '-1')
                if (teamPlan?.planUpdating) {
                    planUpdatingToast(docVO?.teamName ?? '')
                    return true
                }
            } else {
                return false
            }
        })

        return false
    },

    getTeamResourceState: async (teamId: string) => {
        return new GetTeamResourceState(teamId).start()
    },
})

const shouldProcessOtherTeamPropertyNotification = (value: any, store: SpaceStoreType) => {
    // TODO: 需要找 zhengyihui 确认，看代码这块就没有传 orgId 进来，为何能跑通
    const { resourceId, teamId, orgId } = value
    const { orgId: currentOrgId, planInTeams } = store.getState().planAndUserStateStore

    // 上层「space-notify-service」已经做了过滤，只订阅当前 orgId 的变更，这里订阅「有效企业」的变更通知
    const isValidOrg = currentOrgId !== '-1'
    if (isValidOrg) {
        return true
    }

    // 对于其他团队下的变更通知，只关心当前用户有席位的团队的变更通知，其他情况不再发无效请求
    const isInPlanTeams = planInTeams.some((plan) => [resourceId, teamId, orgId].includes(plan.resourceId))
    return isInPlanTeams
}

export const syncPlanState = (store: SpaceStoreType, notifyFlow: SpaceNotifyFlow) => {
    notifyFlow.addUserOrgPlanChangeCallback(() => {
        store.getState().planAndUserStateStore.fetch({ isNotify: true })
    })
    notifyFlow.addUserTeamPlanChangeCallback(() => {
        store.getState().planAndUserStateStore.fetch({ isNotify: true })
    })
    notifyFlow.addTeamRelationChangeCallback(() => {
        store.getState().planAndUserStateStore.fetch({ isNotify: true })
    })
    notifyFlow.addRemoveWorkspaceCallback(() => {
        if (featureSwitchManager.isEnabled('organization-plus')) {
            store.getState().planAndUserStateStore.fetch({ isNotify: true })
        }
    })
    notifyFlow.addWorkspaceUserAuthorizationChangeCallback(() => {
        if (featureSwitchManager.isEnabled('organization-plus')) {
            store.getState().planAndUserStateStore.fetch({ isNotify: true })
        }
    })

    notifyFlow.addPropertyChangeCallbackWithoutDocAndUser((body) => {
        let resourceId: string | undefined | null
        let name: string | undefined | null
        let teamId: string | undefined | null
        // 支付：企业、团队 seatUpgradeType 变更
        if (
            (body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.TEAM ||
                body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.ORG) &&
            !!body.changedProperties.seatUpgradeType
        ) {
            resourceId = body.businessEntity?.entityId
            name = body.changedProperties?.teamName
        }
        // 团队履约合同的升降级变更通知
        if (
            body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.TEAM &&
            !!body.changedProperties.planUpdateType
        ) {
            teamId = body.businessEntity?.entityId
            name = body.changedProperties.planUpdateType
        }
        // 企业/团队 (- 企业试用到期 - 企业/团队年付月结账单逾期- 企业订阅到期账单逾期)
        if (
            (body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.TEAM ||
                body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.ORG) &&
            !!body.changedProperties.planFreeze
        ) {
            resourceId = body.businessEntity?.entityId
            name = body.changedProperties.planFreeze
        }
        // 团队超限基础版变更为正常基础版
        if (
            body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.TEAM &&
            !!body.changedProperties.exceedUsageLimit
        ) {
            teamId = body.businessEntity?.entityId
            name = body.changedProperties.exceedUsageLimit
        }

        const flag = shouldProcessOtherTeamPropertyNotification({ resourceId, teamId, name }, store)
        if (flag) {
            store.getState().planAndUserStateStore.fetch({ isNotify: true })
        }
    })
    // 服务端暂时没有账单数据的通知下发，因此先定时轮询调用接口更新数据「每两小时一次」
    if (!IN_JEST_TEST) {
        // Jest 中运行该代码会导致测试超时，在 Jest 环境下不启动定时器
        setInterval(() => {
            if (domLocation().pathname.includes(`/${RouteToken.OrganizationWithoutOrgId}`)) {
                store.getState().planAndUserStateStore.fetchUnpaidSettlements()
            }
        }, TWO_HOURS)
    }
}
