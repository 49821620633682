export const EmojiMap = new Map([
    ['😀', ':grinning:'],
    ['😃', ':smiley:'],
    ['😄', ':smile:'],
    ['😁', ':grin:'],
    ['😆', ':laughing:'],
    ['😅', ':sweat_smile:'],
    ['😂', ':joy:'],
    ['🤣', ':rolling_on_the_floor_laughing:'],
    ['🥲', ':smiling_face_with_tear:'],
    ['☺️', ':relaxed:'],
    ['😊', ':blush:'],
    ['😇', ':innocent:'],
    ['🙂', ':slightly_smiling_face:'],
    ['🙃', ':upside_down_face:'],
    ['😉', ':wink:'],
    ['😌', ':relieved:'],
    ['😍', ':heart_eyes:'],
    ['🥰', ':smiling_face_with_3_hearts:'],
    ['😘', ':kissing_heart:'],
    ['😗', ':kissing:'],
    ['😙', ':kissing_smiling_eyes:'],
    ['😚', ':kissing_closed_eyes:'],
    ['😋', ':yum:'],
    ['😛', ':stuck_out_tongue:'],
    ['😝', ':stuck_out_tongue_closed_eyes:'],
    ['😜', ':stuck_out_tongue_winking_eye:'],
    ['🤪', ':zany_face:'],
    ['🤨', ':face_with_raised_eyebrow:'],
    ['🧐', ':face_with_monocle:'],
    ['🤓', ':nerd_face:'],
    ['😎', ':sunglasses:'],
    ['🥸', ':disguised_face:'],
    ['🤩', ':star_struck:'],
    ['🥳', ':partying_face:'],
    ['😏', ':smirk:'],
    ['😒', ':unamused:'],
    ['😞', ':disappointed:'],
    ['😔', ':pensive:'],
    ['😟', ':worried:'],
    ['😕', ':confused:'],
    ['🙁', ':slightly_frowning_face:'],
    ['☹️', ':frowning_face:'],
    ['😣', ':persevere:'],
    ['😖', ':confounded:'],
    ['😫', ':tired_face:'],
    ['😩', ':weary:'],
    ['🥺', ':pleading_face:'],
    ['😢', ':cry:'],
    ['😭', ':sob:'],
    ['😤', ':triumph:'],
    ['😠', ':angry:'],
    ['😡', ':rage:'],
    ['🤬', ':cursing_face:'],
    ['🤯', ':exploding_head:'],
    ['😳', ':flushed:'],
    ['🥵', ':hot_face:'],
    ['🥶', ':cold_face:'],
    ['😱', ':scream:'],
    ['😨', ':fearful:'],
    ['😰', ':cold_sweat:'],
    ['😥', ':disappointed_relieved:'],
    ['😓', ':sweat:'],
    ['🤗', ':hugging_face:'],
    ['🤔', ':thinking_face:'],
    ['🤭', ':face_with_hand_over_mouth:'],
    ['🤫', ':shushing_face:'],
    ['🤥', ':lying_face:'],
    ['😶', ':no_mouth:'],
    ['😐', ':neutral_face:'],
    ['😑', ':expressionless:'],
    ['😬', ':grimacing:'],
    ['🙄', ':face_with_rolling_eyes:'],
    ['😯', ':hushed:'],
    ['😦', ':frowning:'],
    ['😧', ':anguished:'],
    ['😮', ':open_mouth:'],
    ['😲', ':astonished:'],
    ['🥱', ':yawning_face'],
    ['😴', ':sleeping:'],
    ['🤤', ':drooling_face:'],
    ['😪', ':sleepy:'],
    ['😵', ':dizzy_face:'],
    ['🤐', ':zipper_mouth_face:'],
    ['🥴', ':woozy_face:'],
    ['🤢', ':nauseated_face:'],
    ['🤮', ':face_vomiting:'],
    ['🤧', ':sneezing_face:'],
    ['😷', ':mask:'],
    ['🤒', ':face_with_thermometer:'],
    ['🤕', ':face_with_head_bandage:'],
    ['🤑', ':money_mouth_face:'],
    ['🤠', ':face_with_cowboy_hat:'],
    ['😈', ':smiling_imp:'],
    ['👿', ':imp:'],
    ['👹', ':japanese_ogre:'],
    ['👺', ':japanese_goblin:'],
    ['🤡', ':clown_face:'],
    ['💩', ':hankey:'],
    ['👻', ':ghost:'],
    ['💀', ':skull:'],
    ['☠️', ':skull_and_crossbones:'],
    ['👽', ':alien:'],
    ['👾', ':space_invader:'],
    ['🤖', ':robot_face:'],
    ['🎃', ':jack_o_lantern:'],
    ['😺', ':smiley_cat:'],
    ['😸', ':smile_cat:'],
    ['😹', ':joy_cat:'],
    ['😻', ':heart_eyes_cat:'],
    ['😼', ':smirk_cat:'],
    ['😽', ':kissing_cat:'],
    ['🙀', ':scream_cat:'],
    ['😿', ':crying_cat_face:'],
    ['😾', ':pouting_cat:'],
    ['👋', ':wave:'],
    ['🖐️', ':raised_hand_with_fingers_splayed:'],
    ['👌', ':ok_hand:'],
    ['🤌', ':pinched_fingers:'],
    ['🤘', ':the_horns:'],
    ['🤙', ':call_me_hand:'],
    ['👈', ':point_left:'],
    ['👉', ':point_right:'],
    ['👆', ':point_up_2:'],
    ['👇', ':point_down:'],
    ['👍', ':+1:'],
    ['👎', ':-1:'],
    ['✊', ':fist:'],
    ['👊', ':facepunch:'],
    ['👏', ':clap:'],
    ['🤝', ':handshake:'],
    ['🙏', ':pray:'],
    ['💪', ':muscle:'],
    ['👂', ':ear:'],
    ['👀', ':eyes:'],
    ['👄', ':lips:'],
    ['🐶', ':dog:'],
    ['🐱', ':cat:'],
    ['🐒', ':monkey:'],
    ['🌞', ':sun_with_face:'],
    ['🌝', ':full_moon_with_face:'],
    ['🌚', ':new_moon_with_face:'],
    ['☀️', ':sunny:'],
    ['🔥', ':fire:'],
    ['🍎', ':apple:'],
    ['🍋', ':lemon:'],
    ['🍻', ':beers:'],
    ['🎂', ':birthday:'],
    ['🥤', ':cup_with_straw:'],
    ['👨‍💻', ':male-technologist:'],
    ['🧑‍🎓', ':student:'],
    ['🧑‍🎨', ':artist:'],
    ['🙇', ':bow:'],
    ['💁', ':information_desk_person:'],
    ['🙅', ':no_good:'],
    ['🙆', ':ok_woman:'],
    ['🙋', ':raising_hand:'],
    ['🧏', ':deaf_person:'],
    ['🤦', ':face_palm:'],
    ['🤷', ':shrug:'],
    ['🙎', ':person_with_pouting_face:'],
    ['🙍', ':person_frowning:'],
    ['💇', ':haircut:'],
    ['🚗', ':car:'],
    ['✈️', ':airplane:'],
    ['🏠', ':house:'],
    ['🧱', ':bricks:'],
    ['🔫', ':gun:'],
    ['💣', ':bomb:'],
    ['💊', ':pill:'],
    ['🔒', ':lock:'],
    ['❤️', ':heart:'],
    ['💔', ':broken_heart:'],
    ['🆘', ':sos:'],
    ['❌', ':x:'],
    ['⭕️', ':o:'],
    ['💯', ':100:'],
    ['❗️', ':exclamation:'],
    ['✅', ':white_check_mark:'],
    ['🆗', ':ok:'],
    ['🆒', ':cool:'],
    ['➕', ':heavy_plus_sign:'],
    ['➖', ':heavy_minus_sign:'],
])

export const EmojiShortNameMap = new Map([...EmojiMap.entries()].map(([emoji, id]) => [id, emoji]))

// 勿删 用于评论emoji 分析
export function debuggerToolGenerateEmojiInfo(emojiMap: typeof EmojiMap) {
    const unicodeRanges = [
        // 数字
        [parseInt('0030', 16), parseInt('0039', 16)],
        // 中文
        [parseInt('4E00', 16), parseInt('9FA5', 16)],
        // 英文
        [parseInt('0041', 16), parseInt('005a', 16)],
        [parseInt('0061', 16), parseInt('007a', 16)],
    ]
    const isNormalUnicode = (unicode: number | undefined) => {
        if (unicode === undefined) {
            return false
        }
        for (const [start, end] of unicodeRanges) {
            if (unicode >= start && unicode <= end) {
                return true
            }
        }
        return false
    }

    const extendsEmojiInfo: {
        unicode: number | undefined
        unicode16: string | undefined
        codePointAt: number
        emojiLength: number
        emoji: string
        emojiName: string
        isNormalUnicode: boolean
    }[] = []
    for (const [emoji, emojiName] of emojiMap.entries()) {
        for (let i = 0; i < emoji.length; i++) {
            extendsEmojiInfo.push({
                unicode: emoji.codePointAt(i),
                unicode16: emoji.codePointAt(i)?.toString(16),
                codePointAt: i,
                emojiLength: emoji.length,
                emoji,
                emojiName,
                isNormalUnicode: isNormalUnicode(emoji.codePointAt(i)),
            })
        }
    }
    extendsEmojiInfo.sort((a, b) => Number(a.unicode) - Number(b.unicode))
    const range = []
    let undefinedTimes = 0
    let equalTimes = 0
    let rangeStart = -1
    let rangeEnd = -1
    for (const { unicode } of extendsEmojiInfo) {
        if (unicode === undefined) {
            undefinedTimes++
            continue
        }
        if (rangeStart === -1) {
            rangeStart = rangeEnd = unicode
            continue
        }

        if (unicode > rangeEnd + 1) {
            range.push([rangeStart, rangeEnd])
            rangeStart = rangeEnd = unicode
        } else if (unicode === rangeEnd + 1) {
            rangeEnd = unicode
        } else {
            equalTimes++
        }
    }

    if (rangeStart !== -1) {
        range.push([rangeStart, rangeEnd])
    }

    const rangeUnicode = range.map(([start, end]) => [start, end, start.toString(16), end.toString(16)])
    return {
        emojiMap,
        extendsEmojiInfo,
        rangeUnicode,
        inputNumber: extendsEmojiInfo.length,
        outputNumber: range.reduce((p, n) => p + n[1] - n[0] + 1, 0),
        undefinedTimes,
        equalTimes,
        normalUnicodeNumber: extendsEmojiInfo.filter((v) => v.isNormalUnicode).length,
        getUnicodeRange:
            rangeUnicode.reduce((p, n) => {
                if (n[0] < n[1]) {
                    return `${p}U+${n[2]}-${n[3]},`
                } else if (n[0] === n[1]) {
                    return `${p}U+${n[2]},`
                }
                return p
            }, '') + ';',
    } as const
}
