import constate from 'constate'
import { useEffect, useRef, useState, type PropsWithChildren } from 'react'
import { TraceableAbortController } from '../../../../../util/src/abort-controller/traceable-abort-controller'
import { LibraryId } from '../../../kernel/interface/component-style'
import type { ResourceType } from '../../../kernel/interface/type'
import { DocID } from '../../../kernel/interface/type'
import { LibraryRemoteSearchService } from '../service/library-remote-search-service'

// 远程库搜索相关
function useLibraryRemoteSearchServiceHook(props: { resourceId: string; resourceType: ResourceType }) {
    const [service, setService] = useState<LibraryRemoteSearchService>()

    useEffect(() => {
        const controller = new TraceableAbortController('library-remote-search-service')
        const result = new LibraryRemoteSearchService(controller.signal, props.resourceId, props.resourceType)

        setService(result)
        return () => {
            controller.abort('library-remote-search-service')
            result.destroy()
        }
    }, [props.resourceId, props.resourceType])

    return service
}

function useInternalLibraryRemoteSearchServiceContext({
    service,
    ...props
}: {
    service: LibraryRemoteSearchService
    resourceId: string
    resourceType: ResourceType
    readOnly: boolean // 是否只读
    switchDesc?: string // 开关组件的描述
    openDetail: (params: { libraryId: LibraryId; docId: DocID }) => void // 进入远端组件库详情页
    jump2DetailDoc: (docId: DocID) => void // 跳转到组件库所在文档
    goBackHome: () => void // 回到首页
    subscribeLibrary: (libraryId: LibraryId) => void // 打开订阅开关
    unSubscribeLibrary: (libraryId: LibraryId) => void // 关闭订阅开关
    subscribeLibraryIds: LibraryId[] | undefined // 订阅的组件库 ids
}) {
    const ref = useRef({ ...service })
    return {
        ...ref.current,
        ...props,
    }
}

const [InternalLibraryRemoteSearchServiceProvider, useLibraryRemoteSearchService] = constate(
    useInternalLibraryRemoteSearchServiceContext
)

export function SearchRemoteLibraryProvider({
    children,
    ...props
}: PropsWithChildren<
    Parameters<typeof useLibraryRemoteSearchServiceHook>[0] &
        Omit<Parameters<typeof useInternalLibraryRemoteSearchServiceContext>[0], 'service'>
>) {
    const service = useLibraryRemoteSearchServiceHook({
        resourceId: props.resourceId,
        resourceType: props.resourceType,
    })
    return service ? (
        <InternalLibraryRemoteSearchServiceProvider service={service} {...props}>
            {children}
        </InternalLibraryRemoteSearchServiceProvider>
    ) : (
        <></>
    )
}

export { useLibraryRemoteSearchService }
