import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    Reload: '重新加载',
    dPvlJH: '文件内存不足，无法继续编辑。',
    pVoWCd: '该文件已达到浏览器可用内存上限。请关闭其他标签页减少浏览器内存占用，再尝试重新加载。',
    cgbChw: '为保证数据安全和操作体验，请删减内容或拆分文件使内存使用量小于',
} as const

export const enTranslation = {
    Reload: 'Reload',
    dPvlJH: 'Browser memory limit exceeded.',
    pVoWCd: 'Out of browser memory. Close other tabs to reduce browser memory usage, and then try to reload it.',
    cgbChw: 'Try removing unneeded content to free up memory and keep things running smoothly',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
