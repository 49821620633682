/* eslint-disable no-restricted-imports */
import {
    CommentId,
    CommentPosition,
    CommentReply,
    CommentThread,
    CommentUser,
    CreateCommentReplyArgs,
    CreateCommentThreadArgs,
    EditCommentOrReplyArgs,
    Strategy,
} from '../../../kernel/interface/comment'
import {
    DeleteCommentOrReply,
    DeleteReactions,
    EditCommentOrReply,
    EditCommentPosition,
    GetAllComments,
    GetCommentDetail,
    GetContactsList,
    GetContactsRecommend,
    GetMailStrategy,
    QueryCommentUser,
    ReleaseComment,
    ReleaseReply,
    ReplyReactions,
    SetCommentsAsRead,
    SetCommentsAsResolve,
    SetCommentsAsUnread,
    SetCommentsAsUnResolve,
    SetMailStrategy,
} from '../../../kernel/request/comment'

export const fetchGetAllComments = (docId: string, startFromCommentId?: CommentId): Promise<CommentThread[]> => {
    return new GetAllComments(docId, startFromCommentId).start()
}
/**
 * @deprecated
 */
export const fetchGetContactsList = (docId: string): Promise<CommentUser[]> => {
    return new GetContactsList(docId).start()
}

export const fetchGetContactsRecommend = (docId: string): Promise<CommentUser[]> => {
    return new GetContactsRecommend(docId).start()
}

export const fetchReleaseComment = (commentInfo: CreateCommentThreadArgs): Promise<CommentThread> => {
    return new ReleaseComment(commentInfo).start()
}

export const fetchSetCommentsAsRead = (commentIds: number[]): Promise<number> => {
    return new SetCommentsAsRead(commentIds).start()
}

export const fetchSetCommentsAsUnread = (commentIds: number[]): Promise<number> => {
    return new SetCommentsAsUnread(commentIds).start()
}

export const fetchSetCommentsAsResolve = (commentIds: number): Promise<number> => {
    return new SetCommentsAsResolve(commentIds).start()
}

export const fetchSetCommentsAsUnResolve = (commentIds: number): Promise<number> => {
    return new SetCommentsAsUnResolve(commentIds).start()
}

export const fetchDeleteCommentOrReply = (id: number): Promise<number> => {
    return new DeleteCommentOrReply(id).start()
}

export const fetchEditCommentOrReply = (id: number, info: EditCommentOrReplyArgs): Promise<void> => {
    return new EditCommentOrReply(id, info).start()
}

export const fetchEditCommentPosition = (id: number, info: CommentPosition, pageId: string): Promise<void> => {
    return new EditCommentPosition(id, info, pageId).start()
}

export const fetchReleaseReply = (info: CreateCommentReplyArgs): Promise<CommentReply> => {
    return new ReleaseReply(info).start()
}

export const fetchQueryCommentUser = (docId: string, queryString: string): Promise<CommentUser[]> => {
    return new QueryCommentUser(docId, queryString).start()
}

export const fetchDeleteReactions = (id: number): Promise<number> => {
    return new DeleteReactions(id).start()
}

export const fetchReplyReactions = (commentId: number, message: string): Promise<number> => {
    return new ReplyReactions(commentId, message).start()
}

export const fetchGetCommentDetail = (commentId: number): Promise<CommentThread> => {
    return new GetCommentDetail(commentId).start()
}

export const fetchSetMailStrategy = (docId: string, strategy: Strategy): Promise<void> => {
    return new SetMailStrategy(docId, strategy).start()
}

export const fetchGetMailStrategy = (docId: string): Promise<Strategy> => {
    return new GetMailStrategy(docId).start()
}
