import { Observable, mergeMap, throwError, timer } from 'rxjs'

// 自定义 retry（随重试次数累加时间）
export const genericRetryStrategy =
    ({
        maxRetryAttempts = 3,
        scalingDuration = 1000,
        excludedStatusCodes = [],
    }: {
        maxRetryAttempts?: number
        scalingDuration?: number
        excludedStatusCodes?: number[]
    } = {}) =>
    (attempts: Observable<any>) => {
        return attempts.pipe(
            mergeMap((error, i) => {
                const retryAttempt = i + 1
                // if maximum number of retries have been met
                // or response is a status code we don't wish to retry, throw error
                if (retryAttempt > maxRetryAttempts || excludedStatusCodes.find((e) => e === error.status)) {
                    return throwError(error)
                }
                console.warn(`Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`)
                // retry after 1s, 2s, etc...
                return timer(retryAttempt * scalingDuration)
            })
        )
    }
