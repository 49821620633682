/* eslint-disable no-restricted-imports */
import { EndEditingPrototypeInteraction, UpdatePrototypeSelectedInteractionKeys } from '@wukong/bridge-proto'
import { useCallback, useRef } from 'react'
import { cmdChangePopupState } from '../../../document/command/document-command'
import { cmdUpdateSelectedReverseIndex } from '../../../document/command/page-command'
import { PopupStateType } from '../../../document/node/node'
import { useViewState } from '../../../view-state-bridge'
import { useCommand } from '../../context/document-context'

export function useDetail() {
    const containerRef = useRef<HTMLDivElement>(null)

    const command = useCommand()

    const popupState = useViewState('popupState')

    const onMouseDown = useCallback(() => {
        if (popupState && popupState.type !== PopupStateType.POPUP_STATE_TYPE_NONE) {
            switch (popupState.type) {
                case PopupStateType.POPUP_STATE_TYPE_PROTOTYPE_INTERACTION:
                    command.DEPRECATED_invokeBridge(EndEditingPrototypeInteraction)
                    break
                default:
                    command.invoke(cmdChangePopupState, {
                        type: PopupStateType.POPUP_STATE_TYPE_NONE,
                        reciprocalIndex: -1,
                        multiPopup: [],
                    })
                    break
            }
        } else {
            command.invoke(cmdUpdateSelectedReverseIndex, 'clear', [])
            command.DEPRECATED_invokeBridge(UpdatePrototypeSelectedInteractionKeys, {
                interactionKeys: [],
            })
        }
    }, [command, popupState])

    return { containerRef, onMouseDown }
}
