import { command, computed, state } from 'ccstate'
import { LoaderFunctionArgs } from 'react-router-dom'
import { action } from '../../../../util/src/ccstate-helper/action'
import { traceable } from '../../../../util/src/ccstate-helper/traceable'
import { parseUrl } from '../../../../util/src/parse-url'
import { type EditorContext } from '../../main/lifecycle/types/editor'
import { isPresentPath } from '../../prototype/context/present-url'
import {
    TraceableAbortController,
    TraceableAbortSignal,
} from '../../../../util/src/abort-controller/traceable-abort-controller'
import { anySignal } from '../../../../util/src/abort-controller/abort-any'

export const [editorLoaderArgs$, setupEditorLoaderArgs$] = traceable(
    'hulh01@kanyun.com',
    action((_, args: LoaderFunctionArgs) => {
        return args
    })
)

export const editorLoaderPathname$ = traceable(
    'hulh01@kanyun.com',
    computed((get) => {
        const args = get(editorLoaderArgs$)

        if (!args) {
            return undefined
        }

        const { pathname } = parseUrl(args.request.url)

        return pathname
    })
)

export const editorDocId$ = traceable(
    'hulh01@kanyun.com',
    computed((get) => {
        const args = get(editorLoaderArgs$)

        if (!args) {
            return undefined
        }

        return args.params.docId as string
    })
)

export const isPresentPath$ = traceable(
    'hulh01@kanyun.com',
    computed((get) => {
        const pathname = get(editorLoaderPathname$)

        if (!pathname) {
            return false
        }

        return isPresentPath(pathname)
    })
)

export const [editorContext$, setupEditorContext$] = traceable(
    'hulh01@kanyun.com',
    action((_, editorContext: EditorContext) => editorContext)
)

export const [documentLoaded$, setupDocumentLoaded$] = traceable(
    'hulh01@kanyun.com',
    action((_, loaded: boolean) => loaded)
)

export const [destroyEditorController$, createEditorSignal$] = (() => {
    const editorController$ = state(new TraceableAbortController('editorController'))

    return [
        traceable(
            'hulh01@kanyun.com',
            command(({ get }) => {
                get(editorController$).abort('editor destroy')
            })
        ),
        traceable(
            'hulh01@kanyun.com',
            command(({ get, set }, signal: TraceableAbortSignal) => {
                const controller = get(editorController$)

                controller.abort('destroy last editor controller')

                const newController = new TraceableAbortController('editorController')

                set(editorController$, newController)

                return anySignal([newController.signal, signal])
            })
        ),
    ]
})()
