import { Wukong } from '@wukong/bridge-proto'
import { PrototypeTestId } from '../../../../../../window'
import { ScrubbableInput } from '../../../../atom/inputs/components/scrubbable-input'
import classes from '../interaction-action-animation.module.less'
import {
    useAnimationTransitionDurationCommand,
    type AnimationTransitionTypeCommandProps,
} from '../use-action-animation-commands'

export function AnimationTransitionDuration(props: {
    state: Wukong.DocumentProto.ITransitionDurationPropValue
    commandProps: AnimationTransitionTypeCommandProps
}) {
    const command = useAnimationTransitionDurationCommand(props.commandProps)
    const isMixed =
        props.state.valueType ===
        Wukong.DocumentProto.InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_MIXED

    return props.state.show ? (
        <ScrubbableInput
            className={classes.durationInput}
            isMixed={isMixed}
            formatter={command.formatter}
            onChange={command.onChange}
            mixedMathHandler={command.mixedMathHandler}
            value={props.state.value}
            resolution={1}
            min={1}
            max={10000}
            smallNudgeAmount={10}
            bigNudgeAmount={100}
            notUseUserConfig
            testId={PrototypeTestId.InteractionPopup.TransitionDurationInput}
        />
    ) : (
        <></>
    )
}
