import { asyncConfirm } from '../../../../../ui-lib/src'
import { PayPlanType, ResourceType } from '../../../kernel/interface/type'
import { RequestResponseErrorHandler } from '../../../kernel/request/error-handler'
import { QuerySeatApplications } from '../../../kernel/request/seat'
import { QueryTeamSeats } from '../../../kernel/request/team'
import { DeleteMember, GetMembersV3, UpdateOwner } from '../../../kernel/request/user-roles'
import { canEditSetting } from '../../util/access/team'
import { SpaceNotifyFlow } from './notify-flow'
import { useSpaceStore } from './space-state'
import { translation } from './team-members-page-state.translation'
import { getSpaceStore, setSpaceStore, SpaceStoreType, type TeamMembersPageStore } from './types'

export const generateTeamMembersPageState = (set: setSpaceStore, get: getSpaceStore): TeamMembersPageStore => {
    return {
        data: undefined,
        loading: true,
        searchKey: '',
        setSearchKey: (searchKey) => {
            set((state) => {
                state.teamMembersPageStore.searchKey = searchKey
            })
        },
        _search: async () => {
            const searchKey = get().teamMembersPageStore.searchKey
            const teamId = get().teamPageStore.teamId
            const orgId = get().organizationStore.organization.id

            if (!teamId) {
                return
            }

            const resourceMemberVO = await new GetMembersV3(ResourceType.Team, teamId, searchKey)
                .start()
                .catch((error) => {
                    if (RequestResponseErrorHandler(error).businessStatus === 8888) {
                        throw error
                    } else {
                        return undefined
                    }
                })

            if (!resourceMemberVO) {
                return set((state) => {
                    state.teamMembersPageStore.data = undefined
                })
            }

            if (orgId === '-1') {
                const teamPlan = get().planAndUserStateStore.getTeamPlan(teamId, '-1')

                if (teamPlan?.planType === PayPlanType.professional && canEditSetting(resourceMemberVO.role)) {
                    const [seats, seatApplicationList] = await Promise.all([
                        new QueryTeamSeats(teamId, searchKey).start().catch(() => undefined),
                        new QuerySeatApplications(ResourceType.Team, teamId).start().catch(() => undefined),
                    ])

                    if (!seats) {
                        return set((state) => {
                            state.teamMembersPageStore.data = undefined
                        })
                    } else {
                        return set((state) => {
                            state.teamMembersPageStore.data = {
                                joinApplications: resourceMemberVO.applications,
                                seatApplicationList,
                                isOtherPaymentTeamAdmin: true,
                                seats,
                                members: [],
                            }
                        })
                    }
                }
            }

            return set((state) => {
                state.teamMembersPageStore.data = {
                    joinApplications: resourceMemberVO.applications,
                    seatApplicationList: undefined,
                    isOtherPaymentTeamAdmin: false,
                    seats: [],
                    members: resourceMemberVO.members,
                }
            })
        },
        search: async () => {
            const teamId = get().teamPageStore.teamId
            if (teamId) {
                set((state) => {
                    state.teamMembersPageStore.loading = true
                })
                await get().teamMembersPageStore._search()
                set((state) => {
                    state.teamMembersPageStore.loading = false
                })
            } else {
                set((state) => {
                    state.teamMembersPageStore.loading = false
                    state.teamMembersPageStore.data = undefined
                })
            }
        },
        updateTeamOwner: async (toUserId, toUserName) => {
            const teamId = useSpaceStore.getState().teamPageStore.teamId
            const teams = useSpaceStore.getState().teamsFoldersStore.teamsFolders
            const team = teams.find((o) => o.teamWithAuthorityVO.id == teamId)?.teamWithAuthorityVO
            if (team) {
                try {
                    await asyncConfirm({
                        title: translation('AreYouSure', { memberName: toUserName, teamName: team.name }),
                        content: translation("YouCan'tUndo"),
                        okButtonProps: { danger: true },
                        okText: translation('TransferOwnership'),
                    })
                } catch (e) {
                    return
                }
                await new UpdateOwner(toUserId, ResourceType.Team, team.id).start()
                useSpaceStore.getState().teamsFoldersStore._refreshData()
            }
        },
        deleteMember: async (userRoleId, isCurrentUser = false) => {
            await new DeleteMember(userRoleId).start()
            if (isCurrentUser) {
                const teamId = useSpaceStore.getState().teamPageStore.teamId!
                useSpaceStore.getState().teamsFoldersStore._removeTeam(teamId)
            } else {
                set((state) => {
                    state.teamMembersPageStore.data!.members = state.teamMembersPageStore.data!.members.filter(
                        (m) => m.id !== userRoleId
                    )
                    state.teamMembersPageStore.data!.seats = state.teamMembersPageStore.data!.seats.filter(
                        (m) => m.id !== userRoleId
                    )
                })
            }
        },
    }
}

export const syncTeamMembersPageState = (store: SpaceStoreType, notifyFlow: SpaceNotifyFlow) => {
    store.subscribe(
        (state) => state.teamPageStore.teamId,
        () => {
            store.getState().teamMembersPageStore.setSearchKey('')
            store.getState().teamMembersPageStore.search()
        }
    )
    store.subscribe(
        (state) => state.teamMembersPageStore.searchKey,
        () => {
            store.getState().teamMembersPageStore._search()
        }
    )

    // 协同
    notifyFlow.addSeatTypeOrSeatApplicationRelationChangeCallback((msg) => {
        if (msg.resourceId == useSpaceStore.getState().teamPageStore.teamId) {
            store.getState().teamMembersPageStore._search()
        }
    })

    notifyFlow.addTeamRelationChangeCallback((msg) => {
        if (msg.teamId == useSpaceStore.getState().teamPageStore.teamId) {
            store.getState().teamMembersPageStore._search()
        }
    })
}
