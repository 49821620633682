/* eslint-disable no-restricted-imports */
import { useEffect, useRef } from 'react'
import Tooltip from '../../../../../../ui-lib/src/components/tooltip/tooltip'
import { useAppContext } from '../../../../main/app-context'
import style from './candidate-component-tooltip.module.less'
import { translation } from './candidate-component-tooltip.translation'

export function CandidateComponentTooltip() {
    const toolTipRef = useRef<any>()

    const appContext = useAppContext()
    const currentTooltipData = appContext.aiService.useZustandStore.use.currentTooltipData()

    useEffect(() => {
        if (currentTooltipData?.show) {
            toolTipRef.current.openTooltip()
        }
    }, [currentTooltipData, toolTipRef])
    return (
        <>
            {currentTooltipData?.show && (
                <Tooltip
                    title={translation('ViewDetails')}
                    ref={toolTipRef}
                    placement="top"
                    toolTipClassName={style.tooltipZindex}
                >
                    <div
                        className={style.tooltipInnerDiv}
                        style={{
                            position: 'absolute',
                            left: currentTooltipData.x,
                            top: currentTooltipData.y,
                        }}
                    ></div>
                </Tooltip>
            )}
        </>
    )
}
