/**
 * @owner: chenxiangbj@kanyun.com
 */
import { createRef, FC, ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { generateUniqString } from '../../utils/random'
import { translation } from './index.translation'
import { NotificationRenderComponent, NotificationRenderComponentBlack } from './render-component'
import { WKNotificationProps } from './type'

const WKNotificationComponent: FC<WKNotificationProps> = (props) => {
    const { type, duration = 5000, onClose, cancelText, okText, ...rest } = props
    useEffect(() => {
        if (duration <= 0) {
            return
        }
        const timer = setTimeout(() => {
            onClose?.()
        }, duration)
        return () => {
            clearTimeout(timer)
        }
    }, [duration, onClose])
    if (type == 'black') {
        return (
            <NotificationRenderComponentBlack
                {...rest}
                onClose={onClose}
                cancelText={cancelText ?? translation('Cancel')}
                okText={okText ?? translation('RkTpHI')}
            />
        )
    }
    return (
        <NotificationRenderComponent
            {...rest}
            onClose={onClose}
            cancelText={cancelText ?? translation('Cancel')}
            okText={okText ?? translation('RkTpHI')}
        />
    )
}

interface WKNotificationContainerRef {
    add: (props: WKNotificationProps) => { close: () => void }
}
interface WKNotificationContainerProps {}

const _WKNotificationContainer = (p: WKNotificationContainerProps, ref: ForwardedRef<WKNotificationContainerRef>) => {
    const [list, setList] = useState<Array<WKNotificationProps & { key: string }>>([])
    useImperativeHandle(ref, () => ({
        add: (props) => {
            const key = generateUniqString()
            const remove = () => setList((l) => l.filter((_) => _.key !== key))
            const _props: WKNotificationProps & { key: string } = {
                ...props,
                key,
                onClose: () => {
                    props.onClose?.()
                    remove()
                },
                onOk: () => {
                    props.onOk?.()
                    remove()
                },
                onCancel: () => {
                    props.onCancel?.()
                    remove()
                },
            }
            setList((l) => [...l, _props])
            return {
                close: remove,
            }
        },
    }))

    return (
        <div data-testid="WKNotification-container">
            {list.map(({ key, ...rest }) => {
                return <WKNotificationComponent key={key} {...rest} />
            })}
        </div>
    )
}

const WKNotificationContainer = forwardRef<WKNotificationContainerRef, WKNotificationContainerProps>(
    _WKNotificationContainer
)

class WKNotification {
    private ref = createRef<WKNotificationContainerRef>()
    public init() {
        const notificationContainer = document.createElement('div')
        notificationContainer.style.position = 'fixed'
        notificationContainer.style.right = '72px'
        notificationContainer.style.bottom = '24px'
        notificationContainer.style.zIndex = '99'
        document.body.appendChild(notificationContainer)
        const root = createRoot(notificationContainer)
        root.render(<WKNotificationContainer ref={this.ref} />)

        return () => {
            root.unmount()
            document.body.removeChild(notificationContainer)
        }
    }
    public show(param: WKNotificationProps) {
        return this.ref.current?.add(param)
    }
}
export const wkNotification = new WKNotification()
