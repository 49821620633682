import { translation } from './candidate-variant-panel.translation'
/* eslint-disable no-restricted-imports */
import { cloneDeep } from 'lodash-es'
import { useRef, useState } from 'react'

import {
    IconError,
    InputV2,
    Select,
    SimpleDrag,
    Switch,
    Tooltip,
    useEllipsisTooltip,
} from '../../../../../../../ui-lib/src'
import { SingleGrid } from '../../../atom/grid/single-grid'

import { Wukong } from '@wukong/bridge-proto'
import { DeepRequired } from '../../../../../view-state-bridge'
import { useComponentInstanceCommand } from '../commands'
import { isBooleanOption, VariantState } from '../types'
import style from './candidate-variant-panel.module.less'

export const VariantTestIds = {
    dragVarProp: 'variant-prop-drag',
    variantProp: (key: string) => ({
        name: `variant-prop-name-${key}`,

        drag: `variant-prop-name-${key}-drag`,
        // 可编辑属性值
        value: `variant-prop-value-${key}`,
        valueInput: `variant-prop-value-${key}-input`,
        valueSelect: `variant-prop-value-${key}-select`,
        select: `variant-prop-${key}-select`, // 实例属性下拉框
        switch: `variant-prop-${key}-switch`,
        option: (value: string) => `variant-prop-value-${key}-${value}`,
    }),
}

// 变体和变体实例属性
export function CandidateVariantProperty(
    props: VariantState & { onChange: (variantProps: Wukong.DocumentProto.IVariantProp[]) => void }
) {
    const { variantProps, onChange } = props
    const commands = useComponentInstanceCommand()
    const isVariantInstanceOrRemoved = props.isVariantInstance || props.isRemovedVariantInstance

    type VariantProps = typeof variantProps
    const [selectedDragItems, setSelectedDragItems] = useState<number[]>([])

    const onDragDone = (newProps: VariantProps) => {
        // 重排组件集所有属性，同步到节点上
        const newCompSetProps = newProps.map((prop) => prop.compSetProp)
        commands.updateCompSetProps(newCompSetProps)
    }

    const propItems = variantProps.map((prop, propIndex) => {
        const onChangePropValue = (newPropValue: string | number) => {
            const varProps = cloneDeep(variantProps)
            if (
                newPropValue &&
                newPropValue.toString().trim().length > 0 &&
                newPropValue !== variantProps[propIndex].value
            ) {
                varProps[propIndex].value = newPropValue.toString()
                onChange(varProps)
            }
        }

        return (
            <PropItem
                key={propIndex}
                prop={prop}
                onChangePropValue={onChangePropValue}
                isVariantInstanceOrRemoved={isVariantInstanceOrRemoved}
            />
        )
    })

    // 变体实例属性无法被拖拽
    return isVariantInstanceOrRemoved ? (
        <>{propItems}</>
    ) : (
        <SimpleDrag
            selectedIndexList={selectedDragItems}
            items={variantProps}
            onSelectChange={setSelectedDragItems}
            onDragDone={onDragDone}
            data-testid={VariantTestIds.dragVarProp}
        >
            {propItems.map((item, propIndex) => (
                <SimpleDrag.Item
                    key={propIndex}
                    itemIndex={propIndex}
                    dataTestIds={{
                        dragIcon: VariantTestIds.variantProp(variantProps[propIndex].compSetProp.name).drag,
                    }}
                >
                    {item}
                </SimpleDrag.Item>
            ))}
        </SimpleDrag>
    )
}

function PropItem(props: {
    prop: DeepRequired<Wukong.DocumentProto.IVariantProp>
    isVariantInstanceOrRemoved: boolean
    onChangePropValue: (newPropValue: string | number) => void
}) {
    const { prop, isVariantInstanceOrRemoved, onChangePropValue: onChangePropValueOut } = props
    const { inactivation, ellipsisRef, onmouseenter } = useEllipsisTooltip<HTMLDivElement>()
    const testIds = VariantTestIds.variantProp(prop.compSetProp.name)
    const switchRef = useRef<HTMLButtonElement>(null)
    const onChangePropValue = (newPropValue: string | number) => {
        onChangePropValueOut(newPropValue)
        switchRef.current?.blur()
    }
    const booleanOptions = isBooleanOption(prop.compSetProp.options)

    return (
        <SingleGrid className={style.grid}>
            <SingleGrid.Item start={5} span={25}>
                <Tooltip title={prop.compSetProp.name} inactivation={inactivation}>
                    <div
                        className={style.propertyKey}
                        data-testid={testIds.name}
                        ref={ellipsisRef}
                        onMouseEnter={onmouseenter}
                        data-simple-drag-ignore
                    >
                        {prop.compSetProp.name}
                    </div>
                </Tooltip>
            </SingleGrid.Item>
            <SingleGrid.Item start={32} span={45} data-simple-drag-ignore>
                {isVariantInstanceOrRemoved ? (
                    booleanOptions ? (
                        <Switch
                            checked={booleanOptions.checked(prop.value)}
                            onChange={(changedBoolean) =>
                                onChangePropValue(booleanOptions.getOriginOption(changedBoolean)!)
                            }
                            dataTestIds={{
                                switch: testIds.switch,
                            }}
                        />
                    ) : (
                        <Select.NormalSingleLevel
                            isMixed={prop.isMixed}
                            value={prop.isInvalid ? '-' : prop.value}
                            onChange={onChangePropValue}
                            dataTestIds={{ triggerFocus: testIds.select }}
                            maxWidth={232}
                            label={prop.value}
                        >
                            {prop.compSetProp.options.map((option, optionIndex) => (
                                <Select.NormalSingleLevel.Option
                                    key={option + optionIndex.toString()}
                                    value={option}
                                    data-testid={testIds.option(option)}
                                    placement="left"
                                    tooltipTitle={option}
                                >
                                    {option}
                                </Select.NormalSingleLevel.Option>
                            ))}
                        </Select.NormalSingleLevel>
                    )
                ) : (
                    <Select.ArrowSingleLevel
                        isMixed={prop.isInvalid ? false : prop.isMixed}
                        value={prop.isInvalid ? '-' : prop.value}
                        onChange={onChangePropValue}
                        dataTestIds={{ triggerContainer: testIds.value, triggerFocus: testIds.valueSelect }}
                        label={
                            <InputV2
                                size="small"
                                value={prop.isInvalid ? '-' : prop.isMixed ? translation('Mixed') : prop.value}
                                onBlur={(e) => onChangePropValue(e.target.value)}
                                data-testid={testIds.valueInput}
                            />
                        }
                        maxWidth={232}
                    >
                        {prop.compSetProp.options.map((option, optionIndex) => (
                            <Select.ArrowSingleLevel.Option
                                key={option + optionIndex.toString()}
                                value={option}
                                data-testid={testIds.option(option)}
                                placement="left"
                                tooltipTitle={option}
                            >
                                {option}
                            </Select.ArrowSingleLevel.Option>
                        ))}
                    </Select.ArrowSingleLevel>
                )}
            </SingleGrid.Item>
            {prop.isInvalid && (
                <SingleGrid.Item start={55} span={4} className={style.errIcon}>
                    <IconError />
                </SingleGrid.Item>
            )}
        </SingleGrid>
    )
}
