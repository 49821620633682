import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { asyncModal, WKButton, WKDivider, WKTextButton } from '../../../../../../../ui-lib/src'
import { FormCompPasswordInput } from '../../../../../../../ui-lib/src/components/wk-form/form-component'
import { WKForm, WKFormItem } from '../../../../../../../ui-lib/src/components/wk-form/index'
import { WKTypography } from '../../../../../../../ui-lib/src/components/wk-typography/index'
import { DeleteAccountPasswordValidate } from '../../../../../kernel/request/account'
import { RequestResponseErrorHandler } from '../../../../../kernel/request/error-handler'
import { translation } from './password-validate-modal.translation'
// 1 代表重置密码
interface PasswordValidateModalReturnType {
    verifyToken: string | false | 1
}
const schema = z.object({
    password: z.string().min(1, ''),
})
type DataType = z.infer<typeof schema>

const PasswordValidateModalBody = ({ resolve }: { resolve: (value: PasswordValidateModalReturnType) => void }) => {
    const {
        handleSubmit,
        control,
        formState: { isValid, isSubmitting },
        setError,
        clearErrors,
    } = useForm<DataType>({
        resolver: zodResolver(schema),
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        defaultValues: {
            password: '',
        },
    })
    const validatePassword = async (data: DataType) => {
        try {
            const { verifyToken } = await new DeleteAccountPasswordValidate(data.password).start()
            resolve({ verifyToken })
        } catch (e) {
            if (RequestResponseErrorHandler(e).businessStatus === 8888) {
                setError('password', {
                    message: translation('NetworkError'),
                })
            } else {
                setError('password', {
                    message: translation('PasswordError'),
                })
            }
        }
    }

    return (
        <div className="flex flex-col">
            <div className="flex flex-col gap-4 px-6 pt-5 pb-6">
                <WKTypography.Text>{translation('PleaseInputPassword')}</WKTypography.Text>
                <WKForm
                    onChange={() => {
                        clearErrors()
                    }}
                    id="validatePassworFormId"
                    onSubmit={handleSubmit(validatePassword)}
                >
                    <WKFormItem label="">
                        <FormCompPasswordInput
                            inputProps={{
                                autoFocus: true,
                                placeholder: translation('InputPassword'),
                            }}
                            control={control}
                            name="password"
                        ></FormCompPasswordInput>
                    </WKFormItem>
                </WKForm>
            </div>
            <WKDivider />
            <div className="flex px-6 py-4 items-center">
                <div className="flex-1">
                    <WKTypography.Text size={12}>{translation('ForgetPassword')}</WKTypography.Text>
                    <WKTextButton onClick={() => resolve({ verifyToken: 1 })} type="primary" size={12}>
                        {translation('Reset')}
                    </WKTextButton>
                </div>
                <div className="flex items-center">
                    <WKButton
                        data-testid="cancel-button"
                        type="secondary"
                        onClick={() => resolve({ verifyToken: false })}
                    >
                        {translation('Cancel')}
                    </WKButton>
                    <WKButton
                        type="primary"
                        formId="validatePassworFormId"
                        htmlType="submit"
                        data-testid="confirm-button"
                        disabled={!isValid}
                        loading={isSubmitting}
                    >
                        {translation('Confirm')}
                    </WKButton>
                </div>
            </div>
        </div>
    )
}

export const passwordValidateModal = () =>
    asyncModal<PasswordValidateModalReturnType>({
        title: translation('CancelAccount'),
        bodyStyle: {
            padding: 0,
        },
        closeWhenHrefChanged: true,
        onCancel: (resolve) => {
            resolve({ verifyToken: false })
        },
        childrenFn: (resolve) => <PasswordValidateModalBody resolve={resolve} />,
        footer: null,
    })
