import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    AddingImage: '正在添加图片',
    FilesFailedTo: '添加图片失败',
    OK: '我知道了',
    FileFormatNot: '因图片文件损坏，无法添加图片。请检查图片文件后重试',
} as const

export const enTranslation = {
    AddingImage: 'Adding image',
    FilesFailedTo: 'Failed to import files',
    OK: 'OK',
    FileFormatNot: 'File format not recognized or failed to convert.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
