import { translation } from './content-creating.translation'
/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useCommentService } from '../../../../main/app-context'
import { CommentDataTestId } from '../../../../window'
import { CommentEditor } from '../comment-editor/comment-editor'
import { EmojiPick } from '../comment-editor/comment-emoji/emoji-pick'
import {
    useCommentNeedShake,
    useCommentUsersMap,
    useInsertEmoji,
    useInsertEmojiTarget,
    useInsertMentionUserInCreateComment,
    useIsShowMentionUserList,
    useSelectAllInCreateComment,
} from '../comment-service/comment-service-hook'
import { MentionUsers } from '../mention-users/mention-users'
import classesShake from '../shake.module.less'
import { EditorType } from '../type'
import classes from './content-creating.module.less'
import { CreatePosition } from './create-position'

export function ContentCreating() {
    const contentRef = useRef<HTMLDivElement>(null)
    const [maxHeight, setMaxHeight] = useState<number>()
    const commentService = useCommentService()
    const needShake = useCommentNeedShake()
    const usersMap = useCommentUsersMap()
    const selectAllInCreateComment = useSelectAllInCreateComment()
    const isShowMentionUserList = useIsShowMentionUserList()
    const insertMentionUserInCreateComment = useInsertMentionUserInCreateComment()
    const insertEmojiTarget = useInsertEmojiTarget()
    const insertEmoji = useInsertEmoji()

    const initialMessages = useMemo(() => {
        return commentService.getCreateCommentInitialMessages()
    }, [commentService])

    const onAnimationEnd = useCallback(
        (e: React.AnimationEvent<HTMLDivElement>) => {
            if (e.currentTarget !== e.target) {
                return
            }
            commentService.shakeAnimationEnd()
        },
        [commentService]
    )

    useEffect(() => {
        return () => {
            // 动画没结束就销毁了组件时，认为动画结束了
            needShake && commentService.shakeAnimationEnd()
        }
    }, [commentService, needShake])

    const changeMaxHeight = useCallback((_maxHeight: number) => {
        setMaxHeight(_maxHeight)
    }, [])

    const onMentionStyle = useCallback(
        (splitLineRect: DOMRect) => {
            const containerRect = contentRef.current?.parentElement?.getBoundingClientRect()
            if (!containerRect) {
                return
            }
            commentService.updateMentionUsersRect(splitLineRect, containerRect)
        },
        [commentService]
    )

    const onClickEmojiInCreateComment = useCallback(
        (editorRect: DOMRect) => {
            const containerRect = contentRef.current?.parentElement?.getBoundingClientRect()
            if (!containerRect) {
                return
            }
            commentService.updateInsertEmojiRect(editorRect, containerRect)
            commentService.openEmojiPick(EditorType.CreateComment)
        },
        [commentService]
    )

    return (
        <CreatePosition changeMaxHeight={changeMaxHeight}>
            <div
                className={classnames(classes.createCommentEditorContainer, { [classesShake.shake]: needShake })}
                onAnimationEnd={onAnimationEnd}
                ref={contentRef}
            >
                <CommentEditor
                    submitType="icon"
                    usersMap={usersMap}
                    onSendEditorState={commentService.updateCommentCreateMessage}
                    initialMessages={initialMessages}
                    onCancel={() => commentService.updateCommentCreateMessage(null)}
                    onSubmit={commentService.submitCreateComment}
                    placeholder={translation('AddAComment')}
                    selectAll={selectAllInCreateComment}
                    selectAllDone={commentService.selectAllDone}
                    maxHeight={maxHeight}
                    onSearchMentionUsers={commentService.searchMentionUsers}
                    onSearchMentionUsersDone={commentService.closeMentionUser}
                    onMentionStyle={onMentionStyle}
                    isMentionHandling={isShowMentionUserList}
                    mentionKeyEvent={commentService.mentionKeyEvent}
                    insertMentionUser={insertMentionUserInCreateComment}
                    insertMentionUserDone={commentService.insertMentionUserDone}
                    activeEmoji={insertEmojiTarget === EditorType.CreateComment}
                    onClickEmoji={onClickEmojiInCreateComment}
                    insertEmoji={insertEmojiTarget === EditorType.CreateComment ? insertEmoji : undefined}
                    insertEmojiDone={commentService.insertEmojiDone}
                    onFocus={() => commentService.editorFocus(EditorType.CreateComment)}
                    onBlur={commentService.editorBlur}
                    maxCharSize={1000}
                    dataTestIds={{
                        CommentEditor: CommentDataTestId.CommentEditorDraft,
                        CommentEditorInput: CommentDataTestId.CommentEditorDraftInput,
                    }}
                />
            </div>
            {isShowMentionUserList ? <MentionUsers /> : null}
            {insertEmojiTarget === EditorType.CreateComment ? <EmojiPick /> : null}
        </CreatePosition>
    )
}
