/* eslint-disable no-restricted-imports */
import { UpdateDevModeBatchExportPopupStateCommandWasmCall, Wukong } from '@wukong/bridge-proto'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { WKToast } from '../../../../../../ui-lib/src'
import { usePageSignal } from '../../../../external-store/atoms/page-signal'
import { useCommand, useCopyCheckService } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { useStableSelected } from '../../../utils/use-selected'
import { useExportByConfigs } from '../../design/export/hooks/use-export'
import { useExportPreparation } from '../../design/export/hooks/use-export-preparation'
import {
    useDevModeExportCompress,
    useDevModeExportSetting,
} from '../inspect/dev-mode-inspect-export/use-dev-mode-export'
import { multiNodesMultiExportSettings } from '../inspect/dev-mode-inspect-export/utils'
import { logs } from '../logs'
import { BatchExportItemContent } from './batch-export-item'
import { translation } from './use-dev-mode-batch-export.translation'
import PresetCodeType = Wukong.DocumentProto.PresetCodeType
import FileType = Wukong.DocumentProto.FileType

export function useShowDevModeBatchExport() {
    const command = useCommand()
    const devModeBatchExportViewState = useViewState('devModeBatchExportViewState')
    const closeBatchExport = useCallback(() => {
        command.DEPRECATED_invokeBridge(
            UpdateDevModeBatchExportPopupStateCommandWasmCall,
            Wukong.DocumentProto.BridgeProtoBoolean.create({
                value: false,
            })
        )
    }, [command])

    const [showBatchExport, setShowOpenBatchExport] = useState(false)
    const copyCheckService = useCopyCheckService()
    const copyHasBeenProhibited = copyCheckService.useZustandStore.use.copyHasBeenProhibited()
    useEffect(() => {
        if (!devModeBatchExportViewState?.show) {
            setShowOpenBatchExport(!!devModeBatchExportViewState?.show)
            return
        }
        if (copyHasBeenProhibited) {
            WKToast.show(translation('ProhibitExport'))
            closeBatchExport()
        } else {
            setShowOpenBatchExport(true)
        }
    }, [devModeBatchExportViewState?.show, closeBatchExport, copyHasBeenProhibited])

    return {
        showBatchExport,
    } as const
}

export function useDevModeBatchExport() {
    const command = useCommand()
    const devModeBatchExportViewState = useViewState('devModeBatchExportViewState')

    const totalBatchExportItemContents = useMemo<BatchExportItemContent[]>(() => {
        return (devModeBatchExportViewState?.batchExportItems ?? []).map((item) => {
            return {
                nodeIds: [item.nodeId],
                name: item.name,
                width: item.width,
                height: item.height,
            }
        })
    }, [devModeBatchExportViewState?.batchExportItems])

    const { selectAllOrNone, select, unSelect, selected, quanlity, count, indeterminate, allChecked } =
        useStableSelected(totalBatchExportItemContents, (item) => item.nodeIds.join(','))

    const { presetCodeType, fileType, exportSetting } = useDevModeExportSetting()

    const { isCompress } = useDevModeExportCompress()
    const exportByConfigs = useExportByConfigs()
    const pageSignal = usePageSignal()
    const { prepare, done } = useExportPreparation()

    const handleBatchExport = useCallback(async () => {
        if (exportSetting.exportSettings.length === 0) {
            return
        }

        addBatchExportFrog(
            presetCodeType!,
            fileType!,
            exportSetting.exportSettings.map((s) => s.constraint.value),
            isCompress
        )

        const nameCount = new Map()
        const configs = multiNodesMultiExportSettings(
            nameCount,
            [...selected].map((content) => content.nodeIds),
            [...selected].map((content) => content.name),
            exportSetting.exportSettings,
            isCompress,
            presetCodeType,
            fileType
        )
        const id = await prepare(pageSignal)
        await exportByConfigs(configs)
        if (id) {
            done(id)
        }
    }, [
        done,
        exportByConfigs,
        exportSetting.exportSettings,
        fileType,
        isCompress,
        prepare,
        presetCodeType,
        selected,
        pageSignal,
    ])

    const openBatchExport = useCallback(() => {
        command.DEPRECATED_invokeBridge(
            UpdateDevModeBatchExportPopupStateCommandWasmCall,
            Wukong.DocumentProto.BridgeProtoBoolean.create({
                value: true,
            })
        )
    }, [command])

    const closeBatchExport = useCallback(() => {
        command.DEPRECATED_invokeBridge(
            UpdateDevModeBatchExportPopupStateCommandWasmCall,
            Wukong.DocumentProto.BridgeProtoBoolean.create({
                value: false,
            })
        )
    }, [command])

    return {
        openBatchExport,
        closeBatchExport,
        totalBatchExportItemContents,
        selectAllOrNone,
        select,
        unSelect,
        selected,
        quanlity,
        count,
        indeterminate,
        allChecked,
        handleBatchExport,
        exportSetting,
    } as const
}

function addBatchExportFrog(
    presetCodeType: PresetCodeType,
    fileType: FileType,
    scalesValue: number[],
    isCompress: boolean
) {
    const export_preset_type = (() => {
        switch (presetCodeType) {
            case PresetCodeType.PRESET_CODE_TYPE_WEB:
                return 'Web'
            case PresetCodeType.PRESET_CODE_TYPE_I_O_S:
                return 'iOS'
            case PresetCodeType.PRESET_CODE_TYPE_ANDROID:
                return 'Android'
        }
    })()
    const export_scale = scalesValue
        .map((scale) => {
            switch (scale) {
                case 1000:
                    return '1x'
                case 2000:
                    return '2x'
                case 3000:
                    return '3x'
                case 4000:
                    return '4x'
                case 1500:
                    return '1.5x'
            }
        })
        .join(',')

    const export_file_type = (() => {
        switch (fileType) {
            case FileType.FILE_TYPE_PNG:
                return 'PNG'
            case FileType.FILE_TYPE_JPEG:
                return 'JPG'
            case FileType.FILE_TYPE_PDF:
                return 'PDF'
            case FileType.FILE_TYPE_WEBP:
                return 'WebP'
            case FileType.FILE_TYPE_AVIF:
                return 'AVIF'
            case FileType.FILE_TYPE_SVG:
                return 'SVG'
        }
    })()

    const export_compression = isCompress ? 'compressed' : 'uncompressed'

    logs.DevMode.bulkExportExecute({ export_preset_type, export_file_type, export_scale, export_compression })
}
