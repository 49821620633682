import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useCallback, useMemo, useRef, useState } from 'react'
import {
    DropdownIconSingleLevelRef,
    DropdownV2,
    InputV2,
    MonoIconCommonAdd16,
    MonoIconCommonRemove16,
    WKIconButton,
} from '../../../../../../../ui-lib/src'
import { isEnglishLanguage } from '../../../../../../../util/src'
import { VariableCodeSyntaxKey, VariableCodeSyntaxKey2Platform } from '../utils'
import classes from './index.module.less'
import { translation } from './index.translation'

const codeSyntaxOptionKeys = [VariableCodeSyntaxKey.Web, VariableCodeSyntaxKey.iOS, VariableCodeSyntaxKey.Android]

const VariableCodeSyntaxItem = ({
    codeKey,
    value,
    editingPlatform,
    onFocus,
    onBlur,
    onRemove,
}: {
    codeKey: VariableCodeSyntaxKey
    value: string
    editingPlatform?: VariableCodeSyntaxKey
    onFocus: (key: VariableCodeSyntaxKey) => void
    onBlur: (key: VariableCodeSyntaxKey, value: string) => void
    onRemove: (key: VariableCodeSyntaxKey) => void
}) => {
    const [inputValue, setInputValue] = useState<string>(value)

    const onInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value)
    }, [])

    const onInputBlur = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            onBlur(codeKey, e.target.value.trim())
            setInputValue(e.target.value.trim())
        },
        [onBlur, codeKey]
    )

    return (
        <div data-testid="variable-code-syntax-item">
            <div className={classes.line}>
                <div className={classes.label}>{codeKey}</div>
                <div className={classes.inputContainer}>
                    <InputV2
                        className={classes.input}
                        autoFocus={editingPlatform === codeKey}
                        value={value}
                        size="small"
                        onFocus={() => onFocus(codeKey)}
                        onBlur={onInputBlur}
                        onChange={onInputChange}
                        dataTestIds={{
                            input: 'variable-code-syntax-input',
                        }}
                    />
                </div>
                <WKIconButton
                    data-testid="variable-code-syntax-remove"
                    icon={<MonoIconCommonRemove16 />}
                    onClick={() => onRemove(codeKey)}
                />
            </div>
            {editingPlatform === codeKey && (
                <div className={classes.code}>
                    {codeKey === VariableCodeSyntaxKey.Web && (
                        <>
                            property: <span>{inputValue}</span>;
                        </>
                    )}
                    {codeKey === VariableCodeSyntaxKey.iOS && (
                        <>
                            .property(<span>{inputValue}</span>)
                        </>
                    )}
                    {codeKey === VariableCodeSyntaxKey.Android && (
                        <>
                            .property(color = <span>{inputValue}</span>)
                        </>
                    )}
                </div>
            )}
        </div>
    )
}

export function VariableCodeSyntax({
    codeSyntax,
    onAdd,
    onChange,
    onRemove,
}: {
    codeSyntax: Wukong.DocumentProto.IVariableCodeSyntax[]
    onAdd: (key: VariableCodeSyntaxKey) => void
    onChange: (key: VariableCodeSyntaxKey, value: string) => void
    onRemove: (key: VariableCodeSyntaxKey) => void
}) {
    const dropdownRef = useRef<DropdownIconSingleLevelRef>(null)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [editingPlatform, setEditingPlatform] = useState<VariableCodeSyntaxKey>()
    const isEmpty = !codeSyntax.length
    const codeSyntaxOptions = useMemo(
        () =>
            codeSyntaxOptionKeys.map((key) => ({
                key,
                disabled: codeSyntax.some((item) => item.platform === VariableCodeSyntaxKey2Platform[key]),
            })),
        [codeSyntax]
    )
    const codeSyntaxList = useMemo(() => {
        const list: { key: VariableCodeSyntaxKey; value: string }[] = []
        codeSyntaxOptionKeys.forEach((key) => {
            const item = codeSyntax.find((v) => v.platform === VariableCodeSyntaxKey2Platform[key])
            if (item) {
                list.push({
                    key,
                    value: item.value,
                })
            }
        })
        return list
    }, [codeSyntax])
    const disabled = codeSyntaxOptions.every((option) => option.disabled)

    const onClickTitle = () => {
        if (isEmpty) {
            dropdownRef.current?.open()
        }
    }

    const onClickMenu = (key: VariableCodeSyntaxKey) => {
        setEditingPlatform(key)
        onAdd(key)
    }

    const onFocus = (key: VariableCodeSyntaxKey) => {
        setEditingPlatform(key)
    }

    const onBlur = (key: VariableCodeSyntaxKey, value: string) => {
        if (value) {
            onChange(key, value)
        }
        setEditingPlatform(undefined)
    }

    return (
        <div
            className={classNames(classes.container, isEnglishLanguage() && classes.en)}
            data-testid="variable-code-syntax"
        >
            <div
                data-testid="variable-code-syntax-title"
                className={classNames(classes.title, isEmpty && classes.empty, isOpen && classes.open)}
                onClick={onClickTitle}
            >
                <span>{translation('CodeSyntax')}</span>
                <DropdownV2.IconSingleLevel
                    ref={dropdownRef}
                    label={
                        <MonoIconCommonAdd16 className={classNames(classes.addIcon, disabled && classes.disabled)} />
                    }
                    placement="bottom right"
                    onClick={(e) => e.stopPropagation()}
                    onChange={onClickMenu}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    dataTestIds={{
                        triggerFocus: 'variable-code-syntax-add-button',
                    }}
                    disabled={disabled}
                >
                    {codeSyntaxOptions.map((option) => (
                        <DropdownV2.IconSingleLevel.Option
                            dataTestId="variable-code-syntax-menu-item"
                            key={option.key}
                            value={option.key}
                            disabled={option.disabled}
                        >
                            {option.key}
                        </DropdownV2.IconSingleLevel.Option>
                    ))}
                </DropdownV2.IconSingleLevel>
            </div>
            {codeSyntaxList.map(({ key, value }) => (
                <VariableCodeSyntaxItem
                    key={key}
                    codeKey={key}
                    value={value}
                    editingPlatform={editingPlatform}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onRemove={onRemove}
                />
            ))}
        </div>
    )
}
