import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    AlignTop: '顶对齐',
    AlignMiddle: '垂直居中对齐',
    AlignBottom: '底对齐',
    AutoWidth: '自动宽度',
    AutoHeight: '自动高度',
    FixSize: '固定尺寸',
    Text: '文本',
    Style: '样式',
    Click: '点击',
    ToReplaceMixed: '替换多个属性值',
    LineHeight: '行高',
    LetterSpacing: '字间距',
    ParagraphSpacing: '段间距',
    AlignLeft: '左对齐',
    AlignCenter: '水平居中对齐',
    AlignRight: '右对齐',
    MoreSettings: '更多设置',
    TypeSettings: '文本设置',
} as const

export const enTranslation = {
    AlignTop: 'Align top',
    AlignMiddle: 'Align middle',
    AlignBottom: 'Align bottom',
    AutoWidth: 'Auto width',
    AutoHeight: 'Auto height',
    FixSize: 'Fixed size',
    Text: 'Text',
    Style: 'Style',
    Click: 'Click',
    ToReplaceMixed: 'to replace mixed content',
    LineHeight: 'Line height',
    LetterSpacing: 'Letter spacing',
    ParagraphSpacing: 'Paragraph spacing',
    AlignLeft: 'Align left',
    AlignCenter: 'Align center',
    AlignRight: 'Align right',
    MoreSettings: 'Type settings',
    TypeSettings: 'Type settings',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
