import { Wukong } from '@wukong/bridge-proto'
import { useCallback, useMemo, useRef } from 'react'
import { Select } from '../../../../../../ui-lib/src'
import { FrameTemplateItem } from '../frame-template/template-data'
import style from './attribute.module.less'
import { translation } from './frame-preset-select.translation'

export interface FrameGroupSelectProps {
    data?: FrameTemplateItem[][]
    isMixedFrameGroup?: boolean
    disabledGroup?: boolean
    selectFrameGroupType?: Wukong.DocumentProto.NodeType
    selectWidth?: number
    selectHeight?: number
    applyTemplate?: (item: FrameTemplateItem) => void
    onClickFrame?: () => void
    onClickGroup?: () => void
    testId?: string
}
export function FrameGroupSelect(props: FrameGroupSelectProps) {
    const {
        data,
        selectWidth,
        selectHeight,
        isMixedFrameGroup,
        selectFrameGroupType,
        disabledGroup,
        applyTemplate,
        onClickFrame,
        onClickGroup,
    } = props
    const hasTemplateSelected = useRef<boolean>(false)
    hasTemplateSelected.current = false

    const jsxList2 = useMemo(() => {
        let hasItemSelected = false
        const isSelectFn = (item: FrameTemplateItem) => {
            if (hasItemSelected) {
                return false
            }
            hasItemSelected = item.width === selectWidth && item.height === selectHeight
            return hasItemSelected
        }
        const list: JSX.Element[] = []
        for (const items of data ?? []) {
            list.push(
                ...items.map((item, index) => (
                    <Select.MinimalSingleLevel.Option
                        value={item.name}
                        backwardIcon={
                            <span className={style.sizeContainer}>
                                {item.width}
                                <span className={style.multiply}>×</span>
                                {item.height}
                            </span>
                        }
                        isSelect={isSelectFn(item)}
                        dataTestId={item.name}
                        key={item.name}
                        splitLineTop={index === 0}
                        tooltipTitle={item.name}
                        placement="left"
                    >
                        {item.name}
                    </Select.MinimalSingleLevel.Option>
                ))
            )
        }
        return list
    }, [data, selectHeight, selectWidth])

    const onChangeSelect = useCallback(
        (value: unknown) => {
            switch (value) {
                case Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME:
                    return onClickFrame?.()
                case Wukong.DocumentProto.NodeType.NODE_TYPE_GROUP:
                    return onClickGroup?.()
                default: {
                    let targetItem: FrameTemplateItem | undefined
                    for (const items of data ?? []) {
                        targetItem = items.find((v) => v.name === value)
                        if (targetItem) {
                            break
                        }
                    }
                    if (targetItem) {
                        applyTemplate?.(targetItem)
                    }
                }
            }
        },
        [applyTemplate, data, onClickFrame, onClickGroup]
    )

    return (
        <Select.MinimalSingleLevel
            isMixed={isMixedFrameGroup}
            value={selectFrameGroupType}
            label={
                selectFrameGroupType === Wukong.DocumentProto.NodeType.NODE_TYPE_GROUP
                    ? translation('Group')
                    : translation('Frame')
            }
            onChange={onChangeSelect}
            weight="semibold"
            dataTestIds={{ triggerFocus: props.testId }}
            width={232}
        >
            {!isMixedFrameGroup && selectFrameGroupType === Wukong.DocumentProto.NodeType.NODE_TYPE_COMPONENT ? (
                <Select.MinimalSingleLevel.Option
                    value={Wukong.DocumentProto.NodeType.NODE_TYPE_COMPONENT}
                    backwardIcon={''}
                >
                    {translation('Frame')}
                </Select.MinimalSingleLevel.Option>
            ) : (
                <Select.MinimalSingleLevel.Option
                    value={Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME}
                    backwardIcon={''}
                >
                    {translation('Frame')}
                </Select.MinimalSingleLevel.Option>
            )}
            <Select.MinimalSingleLevel.Option
                value={Wukong.DocumentProto.NodeType.NODE_TYPE_GROUP}
                backwardIcon={''}
                disabled={disabledGroup}
            >
                {translation('Group')}
            </Select.MinimalSingleLevel.Option>
            {jsxList2}
        </Select.MinimalSingleLevel>
    )
}
