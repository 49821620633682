// 比较 node.orderIndex 大小（与 OrderIndexUtil::compare 逻辑一致）
export function compareOrderIndex(orderIndex1: string, orderIndex2: string) {
    const lhs = Buffer.from(orderIndex1 ?? '', 'base64')
    const rhs = Buffer.from(orderIndex2 ?? '', 'base64')
    const maxLength = Math.max(lhs.length, rhs.length)
    for (let i = 0; i < maxLength; i++) {
        const bLhs = lhs[i] ?? 0
        const bRhs = rhs[i] ?? 0
        if (bLhs === bRhs) {
            continue
        }
        return bLhs < bRhs ? 1 : -1
    }
    return 0
}

export class OrderIndexUtil {
    static default(): Uint8Array {
        return Uint8Array.from([0b10000000])
    }

    // 递增
    static greater(index: Uint8Array): Uint8Array {
        // 从右向左 找到不为0xFF的第一个byte，将其值加1，后续byte清空
        for (let i = index.length - 1; i >= 0; --i) {
            if (index[i] != 0xff) {
                const result = OrderIndexUtil.copy(index, Math.min(index.length, i + 1))
                result[i] = index[i] + 1
                return result
            }
        }
        // 如果找不到不为0xFF的byte，就在末尾添加一个新的byte，值为1
        const result = OrderIndexUtil.copy(index, index.length + 1)
        result[index.length] = 1
        return result
    }

    static compare(lhs: Uint8Array, rhs: Uint8Array): 1 | -1 | 0 {
        const maxLength = Math.max(lhs.length, rhs.length)
        for (let i = 0; i < maxLength; i++) {
            const bLhs = i < lhs.length ? lhs[i] : 0
            const bRhs = i < rhs.length ? rhs[i] : 0
            if (bLhs === bRhs) {
                continue
            }
            return bLhs > bRhs ? 1 : -1
        }

        return 0
    }

    /**
     * 从头开始复制数据
     * @param byte 源数据
     * @param length 目标长度，有可能超出原数据的长度
     * @returns
     */
    private static copy(byte: Uint8Array, length: number): Uint8Array {
        const buffer = new Uint8Array(length)
        const temp = byte.subarray(0, length)
        buffer.set(temp)
        return buffer
    }
}
