import { useMemo } from 'react'
import { IconLink, InputV2, trimInputValue } from '../../../../../../../ui-lib/src'
import { generateURLWithNodeId } from '../../../../../document/util/url'
import styles from './hyperlink-editor.module.less'
import { translation } from './hyperlink-editor.translation'
import { HyperlinkModel } from './use-hyperlink-model'
import { usePopupPosition } from './use-popup-position'

interface IProps {
    model: HyperlinkModel
}

export function HyperlinkEditor({ model }: IProps) {
    const { modelState, modelCommand } = model
    const { transformX, transformY } = usePopupPosition(modelState)

    const editingUrl = useMemo(() => {
        if (modelState?.editingHyperlink.nodeId) {
            return generateURLWithNodeId(modelState.editingHyperlink.nodeId)
        }
        return modelState?.editingHyperlink.url ?? ''
    }, [modelState?.editingHyperlink.url, modelState?.editingHyperlink.nodeId])

    const onSubmit = (value: string) => {
        if (value) {
            modelCommand.endHyperlinkEditing(value)
        } else {
            modelCommand.detachHyperlink()
        }
    }

    const onDetach = () => {
        modelCommand.detachHyperlink()
    }

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key == 'Escape') {
            modelCommand.cancelHyperlinkEditing()
        } else if (event.key == 'Enter') {
            ;(event.target as HTMLInputElement).blur()
        }
    }

    if (!modelState) {
        return null
    }

    return (
        <div className={styles.root} style={{ transform: `translate(${transformX}px, ${transformY}px)` }}>
            <div className={styles.editor}>
                <InputV2.Html
                    style={{ padding: '0 8px', boxSizing: 'border-box' }}
                    defaultValue={editingUrl}
                    placeholder={translation('TypeOr')}
                    autoFocus
                    onBlur={(e) => onSubmit(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => {
                        trimInputValue(e.target)
                    }}
                    data-testid="hyperlink-edit-input"
                />
                <div className={styles.editIcon} onMouseDown={onDetach}>
                    <IconLink />
                </div>
            </div>
        </div>
    )
}
