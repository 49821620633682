import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Documentation: '所在文件',
    ZoomIn: '放大',
    ZoomOut: '缩小',
    ZoomToFit: '缩放至显示全部',
    ImageLoading: '图片加载中',
    GoToMain: '跳转至候选组件文件',
    Edited: '修改于',
    NoAccessPermission: '当前界面所在文件无访问权限',
    Details: '使用详情',
    DetailsLoading: '详情加载中',
    Draft: '草稿',
    NotAvaiable: '练习场不支持跳转至原文件',
} as const

export const enTranslation = {
    Documentation: 'Documentation',
    ZoomIn: 'Zoom in',
    ZoomOut: 'Zoom out',
    ZoomToFit: 'Zoom to fit',
    ImageLoading: 'Loading image',
    GoToMain: 'Go to the original file',
    Edited: 'Edited',
    NoAccessPermission: 'You have no permission to access the current file. ',
    Details: 'Details',
    DetailsLoading: 'Loading details',
    Draft: 'Draft',
    NotAvaiable: 'Not applicable for playground',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
