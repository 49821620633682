import { GetVRAM } from '@wukong/bridge-proto'
import { EffectController } from '../../../../util/src/effect-controller'
import { CommandInvoker } from '../../document/command/command-invoker'
import { CommitType } from '../../document/command/commit-type'
import { EmBridge } from '../../kernel/bridge/em-bridge'
import { EditorServiceState } from '../../kernel/service/editor-service'
import { ServiceClass } from '../../kernel/util/service-class'

interface VRAMMessage {
    type: 'vram'
    id: number
    vram: number
    time: number
}

interface VRAMRecord {
    vram: number
    time: number
}

export class GPUBroadcast extends ServiceClass {
    // 1 分钟发送一次
    private static readonly SEND_INTERVAL = 1 * 60 * 1000
    // 5 分钟内无消息就视为不存活
    private static readonly ALIVE_INTERVAL = 5 * 60 * 1000

    private id = 0
    private channel: BroadcastChannel | null = null
    private vrams: Map<number, VRAMRecord> = new Map()

    get tabs() {
        return this.vrams.size
    }

    get totalVRAM() {
        let total = 0
        this.vrams.forEach((vram) => {
            total += vram.vram
        })
        return total
    }

    constructor(protected override bridge: EmBridge, private invoker: CommandInvoker, controller: EffectController) {
        super(bridge)
        controller.onCleanup(() => this.destroy())

        if (typeof BroadcastChannel === 'undefined') {
            // 浏览器不支持 BroadcastChannel
            return
        }

        this.id = Date.now()
        this.channel = new BroadcastChannel('GPUBroadcast')
        this.channel.onmessage = (event) => {
            this.onMessage(event.data)
        }

        this.sendVRAMMessage()
    }

    public override destroy(): void {
        this.channel?.close()
        this.channel = null
        super.destroy()
    }

    private onMessage(event: any) {
        if (event.type == 'vram') {
            this.onVRAMMessage(event as VRAMMessage)
        }
    }

    private onVRAMMessage = (event: VRAMMessage) => {
        this.vrams.set(event.id, {
            vram: event.vram,
            time: event.time,
        })
        // 删除过期的信息
        const deadline = Date.now() - GPUBroadcast.ALIVE_INTERVAL
        const toDelete: number[] = []
        this.vrams.forEach((vram, id) => {
            if (vram.time < deadline) {
                toDelete.push(id)
            }
        })
        toDelete.forEach((id) => {
            this.vrams.delete(id)
        })
        // TODO(liangyou): 如果当前所有显存过大，且当前页面不活跃，则清理显存资源
    }

    private sendVRAMMessage = () => {
        // wasm 准备好后才能调 wasm call
        if (this.bridge.currentEditorService.getState() == EditorServiceState.Ready) {
            const message: VRAMMessage = {
                type: 'vram',
                id: this.id,
                vram: this.invoker.invokeBridge(CommitType.Noop, GetVRAM).vram,
                time: Date.now(),
            }
            this.onVRAMMessage(message)
            this.channel?.postMessage(message)
        }

        this.startSetTimeout(() => {
            if (this.isDestroy) {
                return
            }
            this.sendVRAMMessage()
        }, GPUBroadcast.SEND_INTERVAL)
    }
}
