import style from './rect-area.module.less'
import { ColorLine } from './color-line'

export enum ConstraintDirection {
    Top,
    VCenter,
    Bottom,
    Left,
    HCenter,
    Right,
}
export interface RectAreaDomProps {
    disabled?: boolean
    isHover: (direction: ConstraintDirection) => boolean
    isSelect: (direction: ConstraintDirection) => boolean
    onMouseEnter: (direction: ConstraintDirection) => void
    onMouseLeave: () => void
    onMouseMoveCenter: (e: any) => void
    onClick: (e: any) => void
}

export function RectAreaDom(props: RectAreaDomProps) {
    const { disabled, isHover, isSelect, onMouseEnter, onMouseLeave, onMouseMoveCenter, onClick } = props

    return (
        <div
            className={`${style.rectContainer} ${disabled ? style.disabled : ''}`}
            onClick={onClick}
            data-testid="rectContainer"
        >
            <div className={style.top}>
                <div
                    className={`${style.edgeLineContainer} ${style.hotHorizontalExpend} ${
                        isHover(ConstraintDirection.Top) ? style.hover : ''
                    }`}
                    onMouseEnter={() => onMouseEnter(ConstraintDirection.Top)}
                    onMouseLeave={onMouseLeave}
                    data-testid="top"
                >
                    <ColorLine disabled={disabled} width={1} height={8} selected={isSelect(ConstraintDirection.Top)} />
                </div>
            </div>
            <div className={style.center}>
                <div className={style.center_left}>
                    <div
                        className={`${style.edgeLineContainer} ${style.hotVerticalExpend} ${
                            isHover(ConstraintDirection.Left) ? style.hover : ''
                        }`}
                        onMouseEnter={() => onMouseEnter(ConstraintDirection.Left)}
                        onMouseLeave={onMouseLeave}
                        data-testid="left"
                    >
                        <ColorLine
                            disabled={disabled}
                            width={8}
                            height={1}
                            selected={isSelect(ConstraintDirection.Left)}
                        />
                    </div>
                </div>
                <div
                    className={style.center_center}
                    onMouseMove={onMouseMoveCenter}
                    onMouseLeave={onMouseLeave}
                    data-testid="center"
                >
                    <div
                        className={`${style.centerHLineContainer} ${
                            isHover(ConstraintDirection.HCenter) ? style.hover : ''
                        }`}
                    >
                        <ColorLine
                            disabled={disabled}
                            width={12}
                            height={1}
                            selected={isSelect(ConstraintDirection.HCenter)}
                            testId="constraint-hcenter"
                        />
                    </div>
                    <div
                        className={`${style.centerVLineContainer} ${
                            isHover(ConstraintDirection.VCenter) ? style.hover : ''
                        }`}
                    >
                        <ColorLine
                            disabled={disabled}
                            width={1}
                            height={12}
                            selected={isSelect(ConstraintDirection.VCenter)}
                            testId="constraint-vcenter"
                        />
                    </div>
                </div>
                <div className={style.center_right}>
                    <div
                        className={`${style.edgeLineContainer} ${style.hotVerticalExpend} ${
                            isHover(ConstraintDirection.Right) ? style.hover : ''
                        }`}
                        onMouseEnter={() => onMouseEnter(ConstraintDirection.Right)}
                        onMouseLeave={onMouseLeave}
                        data-testid="right"
                    >
                        <ColorLine
                            disabled={disabled}
                            width={8}
                            height={1}
                            selected={isSelect(ConstraintDirection.Right)}
                        />
                    </div>
                </div>
            </div>
            <div className={style.bottom}>
                <div
                    className={`${style.edgeLineContainer} ${style.hotHorizontalExpend} ${
                        isHover(ConstraintDirection.Bottom) ? style.hover : ''
                    }`}
                    onMouseEnter={() => onMouseEnter(ConstraintDirection.Bottom)}
                    onMouseLeave={onMouseLeave}
                    data-testid="bottom"
                >
                    <ColorLine
                        disabled={disabled}
                        width={1}
                        height={8}
                        selected={isSelect(ConstraintDirection.Bottom)}
                    />
                </div>
            </div>
        </div>
    )
}
