import { FontSelectInput } from '../../design/text/font/font-select-input'
import style from './remix-design-system-font.module.less'
import { translation } from './remix-design-system-font.translation'
import { useAIGenUIInContext } from './use-ai-gen-ui'

export function RemixDesignSystemFont() {
    const { updateRemixConfig, tempRemixConfig, setFontInfo, fontInfo } = useAIGenUIInContext()

    if (tempRemixConfig === null) {
        return null
    }

    return (
        <div className="flex flex-col gap-2 pb-2">
            <span className="m-0 p-0 wk-text-12 wk-font-semibold">{translation('font')}</span>
            <FontSelectInput
                customStyle={{ width: '100%', padding: 0 }}
                selectedFamily={fontInfo?.family ?? tempRemixConfig.get('font') ?? 'Inter'}
                recentFonts={[]}
                selectedLocalizedFamily={fontInfo?.localizedFamily ?? tempRemixConfig.get('font') ?? 'Inter'}
                enabledTabs={['Cloud']}
                searchStringBelongTo={''}
                defaultSelectedTab="Cloud"
                className="z-1000"
                extraInputClass={style['font-select-input']}
                onChange={(info) => {
                    setFontInfo(info)
                    updateRemixConfig('font', info.family)
                }}
            />
        </div>
    )
}
