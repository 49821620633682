import { CommentReply, CommentThread } from '../../../../kernel/interface/comment'
import { CommentProtoMessage } from '../../../../kernel/notify/comment-notify-service'

export function createCommentProtoToThread(incoming: CommentProtoMessage, currentUserId: number): CommentThread {
    return {
        id: incoming.id,
        docId: incoming.docId,
        uuid: incoming.uuid,
        page: incoming.page,
        owner: incoming.owner,
        message: incoming.message,
        messageText: incoming.messageText,
        mentionedUsers: incoming.mentionedUsers,
        resolved: incoming.resolved,
        createdTime: incoming.createdTime,
        updatedTime: incoming.updatedTime,
        lastEditTime: incoming.lastEditTime,
        position: { ...incoming.position, commentId: incoming.id },
        reactions: incoming.reactions,
        seq: incoming.seq,
        replies: [],
        // 新增时初始为 true，更新时不变
        unread: incoming.owner.id !== currentUserId,
    }
}

export function updateCommentProtoToThread(incoming: CommentProtoMessage, original: CommentThread): CommentThread {
    return {
        id: incoming.id,
        uuid: incoming.uuid,
        docId: incoming.docId,
        page: incoming.page,
        owner: incoming.owner,
        message: incoming.message,
        messageText: incoming.messageText,
        mentionedUsers: incoming.mentionedUsers,
        resolved: incoming.resolved,
        createdTime: incoming.createdTime,
        updatedTime: incoming.updatedTime,
        lastEditTime: incoming.lastEditTime,
        position: { ...incoming.position, commentId: incoming.id },
        reactions: incoming.reactions,
        seq: incoming.seq,
        replies: original.replies,
        unread: original.unread,
    }
}

export function createCommentProtoToReply(incoming: CommentProtoMessage, currentUserId: number): CommentReply {
    return {
        // 全量覆盖
        id: incoming.id,
        uuid: incoming.uuid,
        docId: incoming.docId,
        parentId: incoming.parentId,
        owner: incoming.owner,
        message: incoming.message,
        messageText: incoming.messageText,
        mentionedUsers: incoming.mentionedUsers,
        createdTime: incoming.createdTime,
        updatedTime: incoming.updatedTime,
        lastEditTime: incoming.lastEditTime,
        reactions: incoming.reactions,
        unread: incoming.owner.id !== currentUserId,
    }
}

export function updateCommentProtoToReply(incoming: CommentProtoMessage, original: CommentReply): CommentReply {
    return {
        // 全量覆盖
        id: incoming.id,
        uuid: incoming.uuid,
        docId: incoming.docId,
        parentId: incoming.parentId,
        owner: incoming.owner,
        message: incoming.message,
        messageText: incoming.messageText,
        mentionedUsers: incoming.mentionedUsers,
        createdTime: incoming.createdTime,
        updatedTime: incoming.updatedTime,
        lastEditTime: incoming.lastEditTime,
        reactions: incoming.reactions,
        unread: original.unread,
    }
}
