import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Assets: '资产',
    'SearchAssets...': '搜索',
} as const

export const enTranslation = {
    Assets: 'Assets',
    'SearchAssets...': 'Search assets',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
