import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Position: '选中容器',
    CopyLink: '复制链接',
    Flow: '流程',
    ShowPrototypingSettings: '显示原型设置',
    LinkCopied: '链接已复制',
} as const

export const enTranslation = {
    Position: 'Select frame',
    CopyLink: 'Copy link',
    Flow: 'Flows',
    ShowPrototypingSettings: 'Prototyping visibility',
    LinkCopied: 'Copied to clipboard',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
