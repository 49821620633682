import { DevModeAvailabilityVerificationWasmCall, Wukong } from '@wukong/bridge-proto'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { createSelectors, createStore } from '../../../../util/src/zustand-utils'
import { CommandInvoker } from '../../document/command/command-invoker'
import { WebSocketBridge } from '../../document/synergy/web-socket-bridge'

export class DevModeAvailabilityVerificationService {
    private zustandStore = createStore<{ isDevModeAvailable: boolean }>(() => ({
        isDevModeAvailable: false,
    }))
    useZustandStore = createSelectors(this.zustandStore)

    constructor(
        private commandInvoker: CommandInvoker,
        private webSocketBridge: WebSocketBridge,
        private signal: TraceableAbortSignal
    ) {}

    enable() {
        this.webSocketBridge.onAuthResultsChange(this.signal, (authResults) => {
            const devModeAuthResult = authResults.find(
                (authResult) => authResult.authFeatureType === Wukong.ServerProto.AuthFeatureType.DEV_MODE
            )

            if (!devModeAuthResult) {
                return
            }

            const cipherTexts = devModeAuthResult.payload
                ? Wukong.ServerProto.DevModeCiphers.decode(devModeAuthResult.payload).ciphers
                : []
            const hasAccess = devModeAuthResult.hasAccess ? true : false

            const isAvailable = this.commandInvoker.DEPRECATED_invokeBridge(DevModeAvailabilityVerificationWasmCall, {
                hasAccess: hasAccess,
                cipherTextsWrappedAsString: cipherTexts,
            })

            this.zustandStore.setState({ isDevModeAvailable: isAvailable.value as boolean })
        })
    }
}
