import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    gasxPn: '网络异常，请重试',
    SmartlyAddingAutolayout: '正在通过 AI 布局智能添加自动布局…',
    mGYSQl: '所选图层及其相关图层已智能添加自动布局',
    EnablingStructuredTemporarily: '正在进入 AI 布局-结构化调整…',
    LayerAutolayout: '图层 {{name}} 及其相关图层已智能添加自动布局',
    AILayoutMobileSupportHint: '临时结构化调整仅支持移动端界面，请在移动端容器尺寸内重试',
    ToolBoxToast: 'AI 工具箱本月体验次数已用完',
    AiDSToast: 'AI 设计系统本月体验次数已用完',
    AiLabToast: 'Motiff 妙多实验室本月体验次数已用完',
    LearnMore: '了解更多',
    InDuplication: '正在进行 AI 复制...',
    DuplicateSucceed: 'AI 复制成功',
    DuplicateFailed: 'AI 复制失败',
    DuplicateError: '网络异常，请重试',
} as const

export const enTranslation = {
    gasxPn: 'An error occurred while creating AI layout',
    SmartlyAddingAutolayout: 'AI is adding auto layout…',
    mGYSQl: 'Selection has been added auto layout by AI.',
    EnablingStructuredTemporarily: 'AI is building temporary structure…',
    LayerAutolayout: '{{name}} has been added auto layout by AI.',
    AILayoutMobileSupportHint:
        'Temporary structure are only supported for mobile interfaces, please retry in a mobile size frame',
    ToolBoxToast: 'Max AI Toolbox monthly usage reached',
    AiDSToast: 'Max AI Design Systems monthly usage reached',
    AiLabToast: 'Max Motiff Lab monthly usage reached',
    LearnMore: 'Learn more',
    InDuplication: 'Reduplicating...',
    DuplicateSucceed: 'Reduplications generated',
    DuplicateFailed: 'An error occurred while reduplicating',
    DuplicateError: 'Network unavailable',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
