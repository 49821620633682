/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { DeepRequired, ViewStateBridge } from '../../../../view-state-bridge'

export class CommentOverlayPositionService {
    private overlayElement: HTMLElement | null = null

    constructor(private readonly viewStateBridge: ViewStateBridge) {}

    public init = () => {
        this.viewStateBridge.register('visibleViewport', this.renderOverlayPosition)
    }

    public destroy = () => {
        this.viewStateBridge.unregister('visibleViewport', this.renderOverlayPosition)
    }

    public setOverlayElement = (element: HTMLElement | null) => {
        this.overlayElement = element
        this.renderOverlayPosition()
    }

    private renderOverlayPosition = () => {
        if (this.overlayElement) {
            const viewport = this.viewStateBridge.getDefaultValue('visibleViewport')
            if (!viewport) {
                return
            }
            const { originX, originY, translateX, translateY } = calculateOverlayPosition(viewport)
            this.overlayElement.style.left = `${originX + translateX}px`
            this.overlayElement.style.top = `${originY + translateY}px`
        }
    }
}

export function calculateOverlayPosition(viewport: DeepRequired<Wukong.DocumentProto.IViewport>) {
    const originX = viewport.width / 2
    const originY = viewport.height / 2
    const translateX = -(viewport.x * viewport.zoom) - originX
    const translateY = -(viewport.y * viewport.zoom) - originY

    return { originX, originY, translateX, translateY }
}
