import {
    FeatureSwitchConfig,
    SwitchEnvironmentScopeType,
    SwitchStrategyType,
    UserGroupType,
} from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'chatbot-debug',
    owner: '',
    description: '',
    strategies: [
        {
            env: SwitchEnvironmentScopeType.TESTING,
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                group: UserGroupType.MOTIFF_GROUP,
            },
        },
        {
            env: SwitchEnvironmentScopeType.TESTING,
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                group: UserGroupType.AI_LAB,
            },
        },
        {
            env: SwitchEnvironmentScopeType.ALL,
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                userList: ['05cc727e3c9837ac3b846dccbb3be8066073adc9', '42dfaf3c6e424285078d88146c7e8083e4f41f67'],
            },
        },
    ],
    alwaysDisableInTests: true,
}
export default Config
