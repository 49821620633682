/* eslint-disable no-restricted-imports */
import { UpdateSelectionStrokeModeCommand, Wukong } from '@wukong/bridge-proto'
import { useCallback } from 'react'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'

export function useStrokeMode() {
    const command = useCommand()
    const selectionStroke = useViewState('selectionStrokeV2')
    const modeState = selectionStroke?.modeState
    const switchMode = useCallback(
        (value: Wukong.DocumentProto.StrokeModeType) => {
            command.DEPRECATED_invokeBridge(UpdateSelectionStrokeModeCommand, { strokeMode: value })
            command.commitUndo()
        },
        [command]
    )

    return {
        modeState,
        switchMode,
    }
}
