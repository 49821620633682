export enum IconDragProps {
    title = 'title',
    data = 'text/svg',
}

export const getIconFile = (dataTransfer: DataTransfer): File => {
    const title = dataTransfer.getData(IconDragProps.title)
    const svgStr = dataTransfer.getData(IconDragProps.data)
    const blob = new Blob([svgStr], { type: 'image/svg+xml' })
    const svgFile = new File([blob], `${title}.svg`, { type: 'image/svg+xml' })
    return svgFile
}
