/* eslint-disable no-restricted-imports */
import {
    AddLayoutGridForSelectionCommandForWasm,
    DetachLayoutGridStyleForSelectionCommand,
    GetSelectionNodeIdsCommandForWasm,
    RemoveLayoutGridStyleForSelectionCommand,
    ToggleEnabledLayoutGridWasmCall,
    UpdateLayotuGridStyleForSelectionCommandForWasm,
    Wukong,
} from '@wukong/bridge-proto'
import { useCallback, useMemo, useState } from 'react'
import { cmdChangePopupState } from '../../../../document/command/document-command'
import { cmdChangeAttrPanelStyleEditorState } from '../../../../document/command/node-props-command'
import { cmdUpdateSelectedReverseIndex } from '../../../../document/command/page-command'
import { AttrPanelStyleEditorState, PopupState, PopupStateType, SolidPaint } from '../../../../document/node/node'
import { featureSwitchManager } from '../../../../kernel/switch'
import { useCommand } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { Value } from '../../atom/inputs/utils/type'
import { Position } from '../../comment/type'
import { useLayoutGridModel } from './use-layout-grid-model'

export function useGridLayout() {
    const selectLayoutGridState = useViewState('selectionLayoutGridState')
    const command = useCommand()
    const { modelState, modelCommand } = useLayoutGridModel()
    const popupState = useViewState('popupState')

    const attrPanelStyleEditorState = useViewState('attrPanelStyleEditorState')
    const selectionLayoutGridListSelectedReversedIndexes = useViewState('selectedLayoutGrids')
    const [stylePosition, setStylePosition] = useState<Position>()
    const onClickTitle = () => {
        if (modelState.layoutGrids?.length === 0) {
            onClickAddGrid()
        }
    }

    const setDocumentState = useCallback(
        (openPopup: boolean) => {
            let _attrPanelStyleEditorState: AttrPanelStyleEditorState | null = null
            const _popupState: PopupState = {
                type: PopupStateType.POPUP_STATE_TYPE_NONE,
                reciprocalIndex: -1,
                multiPopup: [],
            }

            const passIds = command.DEPRECATED_invokeBridge(GetSelectionNodeIdsCommandForWasm).value ?? []
            if (openPopup) {
                _attrPanelStyleEditorState = {
                    openFromModule: Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_LAYOUT_GRID,
                    openFromNodeId: passIds!,
                    selectReverseIndex: [],
                    isCreate: false,
                }
                _popupState.type = PopupStateType.POPUP_STATE_TYPE_LAYOUT_GRID_STYLE_SELECT
            }

            command.invoke(cmdChangeAttrPanelStyleEditorState, _attrPanelStyleEditorState)
            command.invoke(cmdChangePopupState, _popupState)
        },
        [command]
    )

    const openStyle = useMemo(() => {
        return (
            popupState?.type === PopupStateType.POPUP_STATE_TYPE_LAYOUT_GRID_STYLE_SELECT &&
            attrPanelStyleEditorState?.openFromModule ===
                Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_LAYOUT_GRID
        )
    }, [attrPanelStyleEditorState?.openFromModule, popupState?.type])

    const switchOpenStyleState = useCallback(
        (state?: boolean) => {
            const _openStyle = state !== undefined ? state : !openStyle
            setDocumentState(_openStyle)
        },
        [openStyle, setDocumentState]
    )

    const onClickStyle = useCallback(
        (e: any) => {
            const { bottom } = e.currentTarget.getBoundingClientRect()
            const { clientWidth } = document.documentElement
            setStylePosition({ top: bottom + 8, left: clientWidth - 16 })
            switchOpenStyleState()
        },
        [switchOpenStyleState]
    )

    const onClickAddGrid = useCallback(() => {
        command.DEPRECATED_invokeBridge(AddLayoutGridForSelectionCommandForWasm)
        command.commitUndo()
    }, [command])

    const isShowAddGridBtn =
        selectLayoutGridState?.type != Wukong.DocumentProto.VSelectionLayoutGridType.V_SELECTION_LAYOUT_GRID_TYPE_STYLE
    const selectGrid: any[] = []

    const layoutGrids = useMemo(() => {
        if (selectLayoutGridState) {
            return {
                ...selectLayoutGridState,
                layoutGrids: selectLayoutGridState.layoutGrids,
                variables: selectLayoutGridState.variables,
            }
        }
    }, [selectLayoutGridState])

    const onClickSub = useCallback(
        (index: number) => {
            const nextLayoutGrid = modelState.layoutGrids?.slice() ?? []
            nextLayoutGrid?.splice(index, 1)
            modelCommand.changelayoutGrids(nextLayoutGrid, true)
        },
        [modelState.layoutGrids, modelCommand]
    )

    const updateSingleLayoutGridAttr = (
        obj: { [key: string]: any },
        index: number,
        options?: InputOptionsForUndoSquash
    ) => {
        const nextLayoutGrids = modelState.layoutGrids?.slice() ?? []
        const layoutGridAtIndex = nextLayoutGrids?.at(index)!
        nextLayoutGrids?.splice(index, 1, {
            ...layoutGridAtIndex,
            ...obj,
        })
        modelCommand.changelayoutGrids(nextLayoutGrids, false, options)
    }

    const onChangeSectionSizeV1 = (index: number) => (value: Value, options?: InputOptionsForUndoSquash) => {
        if (typeof value === 'number') {
            updateSingleLayoutGridAttr({ sectionSize: Math.round(value), visible: true }, index, options)
        }
    }

    const onChangeSectionSizeV2 = (index: number) => (value: Value, options?: InputOptionsForUndoSquash) => {
        if (typeof value === 'number') {
            updateSingleLayoutGridAttr(
                { sectionSize: Math.round(value), sectionSizeVar: null, visible: true },
                index,
                options
            )
        }
    }

    const flag = featureSwitchManager.isEnabled('float-variable')
    const onChangeSectionSize = flag ? onChangeSectionSizeV2 : onChangeSectionSizeV1
    const onChangeSectionSizeVar = (index: number) => (value: string | null, options?: InputOptionsForUndoSquash) => {
        if (flag) {
            if (value) {
                updateSingleLayoutGridAttr(
                    {
                        sectionSizeVar: {
                            dataType: Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_ALIAS,
                            resolvedDataType:
                                Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT,
                            value: { alias: value },
                        },
                    },
                    index,
                    options
                )
            } else {
                updateSingleLayoutGridAttr({ sectionSizeVar: null }, index, options)
            }
        }
    }

    const onChangeVisible = (index: number) => (visible: boolean) => {
        updateSingleLayoutGridAttr({ visible: visible }, index)
    }

    const onChangeLayoutPattern = (index: number) => (pattern: Wukong.DocumentProto.LayoutGridPattern) => {
        updateSingleLayoutGridAttr({ pattern: pattern }, index)
    }

    const onChangeCountV1 = (index: number) => (value: Value, options?: InputOptionsForUndoSquash) => {
        if (typeof value === 'number') {
            updateSingleLayoutGridAttr({ count: Math.round(value), visible: true }, index, options)
        }
    }

    const onChangeCountV2 = (index: number) => (value: Value, options?: InputOptionsForUndoSquash) => {
        if (typeof value === 'number') {
            updateSingleLayoutGridAttr({ count: Math.round(value), countVar: null, visible: true }, index, options)
        }
    }

    const onChangeCount = flag ? onChangeCountV2 : onChangeCountV1

    const onChangeCountVar = (index: number) => (value: string | null, options?: InputOptionsForUndoSquash) => {
        if (flag) {
            if (value) {
                updateSingleLayoutGridAttr(
                    {
                        countVar: {
                            dataType: Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_ALIAS,
                            resolvedDataType:
                                Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT,
                            value: { alias: value },
                        },
                    },
                    index,
                    options
                )
            } else {
                updateSingleLayoutGridAttr({ countVar: null }, index, options)
            }
        }
    }

    const onChangeAlign = (index: number) => (align: Wukong.DocumentProto.RowsColsLayoutGridAlign) => {
        updateSingleLayoutGridAttr({ align: align, visible: true }, index)
    }

    const onChangeGutterSizeV1 = (index: number) => (value: Value, options?: InputOptionsForUndoSquash) => {
        if (typeof value === 'number') {
            updateSingleLayoutGridAttr({ gutterSize: Math.round(value), visible: true }, index, options)
        }
    }

    const onChangeGutterSizeV2 = (index: number) => (value: Value, options?: InputOptionsForUndoSquash) => {
        if (typeof value === 'number') {
            updateSingleLayoutGridAttr(
                { gutterSize: Math.round(value), gutterSizeVar: null, visible: true },
                index,
                options
            )
        }
    }
    const onChangeGutterSize = flag ? onChangeGutterSizeV2 : onChangeGutterSizeV1
    const onChangeGutterSizeVar = (index: number) => (value: string | null, options?: InputOptionsForUndoSquash) => {
        if (flag) {
            if (value) {
                updateSingleLayoutGridAttr(
                    {
                        gutterSizeVar: {
                            dataType: Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_ALIAS,
                            resolvedDataType:
                                Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT,
                            value: { alias: value },
                        },
                    },
                    index,
                    options
                )
            } else {
                updateSingleLayoutGridAttr({ gutterSizeVar: null }, index, options)
            }
        }
    }

    const onChangeOffsetV1 = (index: number) => (value: Value, options?: InputOptionsForUndoSquash) => {
        if (typeof value === 'number') {
            updateSingleLayoutGridAttr({ offset: Math.round(value), visible: true }, index, options)
        }
    }

    const onChangeOffsetV2 = (index: number) => (value: Value, options?: InputOptionsForUndoSquash) => {
        if (typeof value === 'number') {
            updateSingleLayoutGridAttr({ offset: Math.round(value), offsetVar: null, visible: true }, index, options)
        }
    }

    const onChangeOffset = flag ? onChangeOffsetV2 : onChangeOffsetV1

    const onChangeOffsetVar = (index: number) => (value: string | null, options?: InputOptionsForUndoSquash) => {
        if (flag) {
            if (value) {
                updateSingleLayoutGridAttr(
                    {
                        offsetVar: {
                            dataType: Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_ALIAS,
                            resolvedDataType:
                                Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT,
                            value: { alias: value },
                        },
                    },
                    index,
                    options
                )
            } else {
                updateSingleLayoutGridAttr({ offsetVar: null }, index, options)
            }
        }
    }

    const onChangeSolidPaint = (index: number) => (value: SolidPaint, options?: InputOptionsForUndoSquash) => {
        updateSingleLayoutGridAttr({ paints: value, visible: true }, index, options)
    }

    const onChangeModalVisible = (index: number) => (visible: boolean) => {
        if (!layoutGrids?.layoutGrids.length) {
            return
        }
        if (visible) {
            const reciprocalIndex = index
            command.invoke(cmdChangePopupState, {
                type: PopupStateType.POPUP_STATE_TYPE_LAYOUT_GRID,
                reciprocalIndex,
                multiPopup: [],
            })
        } else {
            command.invoke(cmdChangePopupState, {
                type: PopupStateType.POPUP_STATE_TYPE_NONE,
                reciprocalIndex: -1,
                multiPopup: [],
            })
        }
    }
    const isOpen = (index: number) => {
        if (popupState && popupState.type === PopupStateType.POPUP_STATE_TYPE_LAYOUT_GRID) {
            return popupState.reciprocalIndex === index
        }
        return false
    }

    const onChangeStyle = useCallback(
        (nodeId: string, needClosePop: boolean) => {
            command.DEPRECATED_invokeBridge(UpdateLayotuGridStyleForSelectionCommandForWasm, { node: nodeId })
            if (needClosePop) {
                switchOpenStyleState(false)
            }
            command.commitUndo()
        },
        [command, switchOpenStyleState]
    )

    const onClickCutLink = useCallback(
        (nodeId: string) => {
            command.DEPRECATED_invokeBridge(DetachLayoutGridStyleForSelectionCommand, { node: nodeId })
            command.commitUndo()
        },
        [command]
    )

    const onRemoveStyle = useCallback(() => {
        command.DEPRECATED_invokeBridge(RemoveLayoutGridStyleForSelectionCommand)
        command.commitUndo()
    }, [command])

    const onChangeStyleVisible = useCallback(() => {
        command.DEPRECATED_invokeBridge(ToggleEnabledLayoutGridWasmCall)
    }, [command])

    const selectionSelectedLayoutGrids = useMemo(() => {
        return (
            selectionLayoutGridListSelectedReversedIndexes?.indexes.map((index) => layoutGrids?.layoutGrids[index]) ??
            []
        )
    }, [layoutGrids?.layoutGrids, selectionLayoutGridListSelectedReversedIndexes?.indexes])

    const onSelectChange = useCallback(
        (itemsIndex: number[]) => {
            command.invoke(cmdUpdateSelectedReverseIndex, 'layoutgrids', itemsIndex)
        },
        [command]
    )

    const onDropDone = useCallback(
        (items: Wukong.DocumentProto.ILayoutGrid[], indexes: number[]) => {
            command.invoke(cmdUpdateSelectedReverseIndex, 'layoutgrids', indexes)
            modelCommand.changelayoutGrids(items, false)
        },
        [command, modelCommand]
    )
    return {
        selectionLayoutGridListSelectedReversedIndexes,
        isOpen,
        layoutGrids,
        onClickTitle,
        onClickStyle,
        isShowAddGridBtn,
        openStyle,
        onClickAddGrid,
        selectGrid,
        stylePosition,
        selectionSelectedLayoutGrids,
        onClickSub,
        onChangeVisible,
        onChangeLayoutPattern,
        onChangeSectionSize,
        onChangeSectionSizeVar,
        onChangeSolidPaint,
        onChangeCount,
        onChangeCountVar,
        onChangeAlign,
        onChangeGutterSize,
        onChangeGutterSizeVar,
        onChangeOffset,
        onChangeOffsetVar,
        onChangeModalVisible,
        onChangeStyle,
        onClickCutLink,
        onRemoveStyle,
        onChangeStyleVisible,
        onSelectChange,
        onDropDone,
    }
}
