import { Wukong } from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import { DataNode, DropdownDefaultCustomNode, DropdownV2, MonoIconPanelMode16 } from '../../../../../../ui-lib/src'
import Tooltip from '../../../../../../ui-lib/src/components/tooltip/tooltip'
import { useLibraryComponentService } from '../../../../main/app-context'
import { LibraryResourceOssClientType } from '../../../../share/component-style-library/service/library-resource-downloader'
import {
    VariableModeSelectProps,
    VariableSetModeConflictType,
    VariableSetModeSelectionState,
    VariableSetModeState,
} from './interface'
import { ModeConflictWarning } from './mode-conflict-icon'
import { getAutoModeName, handleConflictToast } from './util'
import classes from './variable-mode-select.module.less'
import { translation } from './variable-mode-select.translation'
export function VariableModeSelect(props: VariableModeSelectProps) {
    const { source, data, onChange } = props

    const getModeState = useCallback(
        (
            variableSetIds: string[],
            autoMode: Wukong.DocumentProto.IAutoModeInfo,
            variableModes: VariableSetModeState[],
            modeSelectionState: VariableSetModeSelectionState,
            remoteVO?: Wukong.DocumentProto.IRemoteVariableSetItem
        ) => {
            const modeHasDeleted = modeSelectionState.isDeleted
            return [
                {
                    name: translation('Mixed'),
                    hidden:
                        modeSelectionState.selectionType !==
                        Wukong.DocumentProto.VariableSetModeSelectionType.VARIABLE_SET_MODE_SELECTION_TYPE_MIXED,
                    customNode: {
                        customItemProps: {
                            isSelect:
                                modeSelectionState.selectionType ===
                                Wukong.DocumentProto.VariableSetModeSelectionType
                                    .VARIABLE_SET_MODE_SELECTION_TYPE_MIXED,
                        },
                        type: 'mixed',
                        remoteVO,
                    },
                },
                {
                    name: getAutoModeName(autoMode, source),
                    hidden: false,
                    customNode: {
                        customItemProps: {
                            isSelect:
                                modeSelectionState.selectionType ===
                                Wukong.DocumentProto.VariableSetModeSelectionType
                                    .VARIABLE_SET_MODE_SELECTION_TYPE_DEFAULT,
                        },
                        type: 'auto',
                        variableSetIds: variableSetIds,
                        remoteVO,
                    },
                },
                {
                    name: 'spilt-line',
                    customNode: {
                        autoSplitLine: true,
                    },
                },
                ...variableModes.map((mode) => ({
                    name: mode.variableSetMode.modeName,
                    hidden: false,
                    customNode: {
                        customItemProps: {
                            isSelect:
                                mode.variableSetMode.modeId === modeSelectionState.selectedMode.modeId &&
                                modeSelectionState.selectionType ===
                                    Wukong.DocumentProto.VariableSetModeSelectionType
                                        .VARIABLE_SET_MODE_SELECTION_TYPE_VALUE,
                            backwardIcon: mode.conflictType !=
                                VariableSetModeConflictType.VARIABLE_SET_MODE_CONFLICT_TYPE_NONE && (
                                <ModeConflictWarning mode={mode} />
                            ),
                        },
                        type: 'value',
                        variableSetIds: variableSetIds,
                        mode: mode.variableSetMode,
                        remoteVO,
                        conflictType: mode.conflictType,
                    },
                })),
                {
                    name: translation('ModeDeleted'),
                    hidden: !modeHasDeleted,
                    disabled: true,
                    customNode: {
                        customItemProps: {
                            isSelect: modeHasDeleted,
                        },
                    },
                },
            ] as DataNode<DropdownDefaultCustomNode>[]
        },
        [source]
    )

    const localGroup = useMemo(() => {
        if (data.localVariableSets.length) {
            return [
                {
                    name: translation('LocalVariableSet'),
                    key: 'local-variable',
                    disabled: true,
                },
                ...data.localVariableSets.map((set) => ({
                    name: set.name,
                    key: set.uniqueKey,
                    children: getModeState([set.id], set.autoMode, set.variableModes, set.modeSelectionState),
                })),
            ] as DataNode<DropdownDefaultCustomNode>[]
        } else {
            return []
        }
    }, [data.localVariableSets, getModeState])

    const remoteGroup = useMemo(() => {
        return data.remoteVariableSets.map((remote) => {
            if (remote.items.length) {
                return [
                    { name: remote.docName, key: `remote-${remote.libraryId}`, disabled: true },
                    ...remote.items.map((set) => ({
                        name: set.name,
                        key: set.uniqueKey,
                        children: getModeState(
                            set.localNodeIds,
                            set.autoMode,
                            set.variableModes,
                            set.modeSelectionState,
                            set
                        ),
                    })),
                ] as DataNode<DropdownDefaultCustomNode>[]
            } else {
                return []
            }
        })
    }, [data.remoteVariableSets, getModeState])

    const unknownGroup = useMemo(() => {
        if (data.unknownVariableSets.length) {
            return [
                { name: translation('UnknownVariableSet'), key: 'unknown-variable', disabled: true },
                ...data.unknownVariableSets.map((set) => ({
                    name: set.name,
                    key: set.uniqueKey,
                    children: getModeState(set.localNodeIds, set.autoMode, set.variableModes, set.modeSelectionState),
                })),
            ] as DataNode<DropdownDefaultCustomNode>[]
        }
        return []
    }, [data.unknownVariableSets, getModeState])

    const { libraryNodeDataService } = useLibraryComponentService()

    const getRemoteVariableSetIds = useCallback(
        async (remoteVO: Wukong.DocumentProto.IRemoteVariableSetItem) => {
            if (remoteVO.localNodeIds.length) {
                return remoteVO.localNodeIds
            }

            const id = await libraryNodeDataService.createRemoteVariableSetNode({
                isLocal: false,
                localNodeId: null,
                ossClientType: LibraryResourceOssClientType.VariableSet,
                remoteDocId: remoteVO.documentId,
                remoteNodeId: remoteVO.remoteNodeId,
                nodeDataPath: remoteVO.nodeDataPath ?? '',
                key: remoteVO.key,
            })
            return [id ?? '']
        },
        [libraryNodeDataService]
    )

    const {
        libraryModalRouterService: { goToRemoteLibraryChangeList },
    } = useLibraryComponentService()

    const onChange_ = useCallback(
        async (
            value: DataNode<
                DropdownDefaultCustomNode & {
                    remoteVO?: Wukong.DocumentProto.IRemoteVariableSetItem
                    variableSetIds?: string[]
                    mode?: Wukong.DocumentProto.IVariableSetMode
                    type?: 'mixed' | 'value' | 'auto'
                    conflictType?: Wukong.DocumentProto.VariableSetModeConflictType
                }
            >
        ) => {
            const variableSetIds = !isNil(value.customNode?.remoteVO)
                ? await getRemoteVariableSetIds(value.customNode?.remoteVO)
                : value.customNode?.variableSetIds ?? []

            onChange({
                variableSetIds,
                modeId: value.customNode?.mode?.modeId ?? undefined,
                type: value.customNode?.type,
            })

            if (!isNil(value.customNode?.conflictType) && !isNil(value.customNode?.mode)) {
                handleConflictToast(value.customNode?.mode, value.customNode?.conflictType, goToRemoteLibraryChangeList)
            }
        },
        [getRemoteVariableSetIds, goToRemoteLibraryChangeList, onChange]
    )

    return (
        <Tooltip title={translation('VariableModeSelectTooltip')} triggerRefKey="getTriggerElement">
            <DropdownV2.IconMultiLevel<
                DropdownDefaultCustomNode & {
                    remoteVO?: Wukong.DocumentProto.IRemoteVariableSetItem
                    variableSetId?: string
                    mode?: Wukong.DocumentProto.IVariableSetMode
                    type?: 'mixed' | 'value' | 'auto'
                    conflictType?: Wukong.DocumentProto.VariableSetModeConflictType
                }
            >
                label={<MonoIconPanelMode16 />}
                placement={'bottom left'}
                onChange={onChange_}
                items={[...localGroup, ...remoteGroup.flat(), ...unknownGroup]}
                classNameTriggerFocus={classes.trigger}
                dataTestIds={{
                    triggerFocus: 'variable-mode-select-trigger',
                    container: 'variable-mode-select-container',
                }}
                maxWidth={420}
            ></DropdownV2.IconMultiLevel>
        </Tooltip>
    )
}
