import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { ExplicitUndefined } from '../../../utils/explicit-undefined'
import { Empty, EmptyProps, EmptyRef } from '../picks/empty/empty'
import { TriggerEmpty, TriggerEmptyRef } from '../triggers/trigger-empty'
import { SelectEmptyProps, SelectTriggerEmptyProps } from './type'
import { useCommentSelect } from './use-comment-select'

export interface SelectEmptyEmptyRef {
    close: () => void
    open: () => void
}

export type SelectEmptyEmptyProps = SelectTriggerEmptyProps & SelectEmptyProps

function _SelectEmptyEmpty(props: SelectEmptyEmptyProps, ref?: React.Ref<SelectEmptyEmptyRef>) {
    const pickRef = useRef<EmptyRef>(null)
    const triggerRef = useRef<TriggerEmptyRef>(null)
    const {
        pickRest,
        worldRect,
        triggerRect,
        placement,
        overMajorClassName,
        overMinorClassName,
        isMinWidthFromTrigger = true,
        minWidth,
        width,
        maxWidth,
        autoAdjustLeft,
        onKeyboard,
        onClose,
        onOpen,
        ...otherProps
    } = props

    const commentSelectState = useCommentSelect({
        triggerRect,
        triggerRef,
        pickRef,
        onOpen,
        onClose,
    })

    useImperativeHandle(
        ref,
        () => ({
            close: () => pickRef.current?.close(),
            open: () => pickRef.current?.open(),
        }),
        []
    )

    return (
        <TriggerEmpty
            ref={triggerRef}
            {...otherProps}
            isOpenPick={commentSelectState.isOpenPick}
            openAction={commentSelectState.openAction}
            closeAction={commentSelectState.closeAction}
        >
            <Empty<ExplicitUndefined<EmptyProps>>
                pickRest={pickRest}
                dataTestIds={props.dataTestIds}
                triggerRect={commentSelectState.triggerRect}
                closeByESC={commentSelectState.closeByESC}
                onClose={commentSelectState.onSelectClose}
                onOpen={commentSelectState.onSelectOpen}
                ref={pickRef}
                worldRect={worldRect}
                placement={placement}
                overMajorClassName={overMajorClassName}
                overMinorClassName={overMinorClassName}
                isMinWidthFromTrigger={isMinWidthFromTrigger}
                minWidth={minWidth}
                width={width}
                maxWidth={maxWidth}
                onKeyboard={onKeyboard}
                isOpenState={undefined}
                openStateToBeFalse={undefined}
                openStateToBeTrue={undefined}
                getPortalElement={commentSelectState.getPortalElement}
                removeMask={undefined}
                autoAdjustLeft={autoAdjustLeft}
            >
                {props.children}
            </Empty>
        </TriggerEmpty>
    )
}

/**
 * @description 这个 select 不属于设计的定义。提供的目的是给一些想用select组件去实现别的组件的场景
 * */
export const SelectEmptyEmpty = forwardRef(_SelectEmptyEmpty)
