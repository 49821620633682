import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    AiDuplicateReferenceSelect: 'AI 复制参考范围',
    Description1: '你可以使用 AI 复制功能快速进行批量复制。同时由 Motiff AI 基于过往设计稿生成并替换副本中的图文内容。',
    Description2: '使用 AI 复制前，请先设置生成内容可参考的设计稿范围。',
    UpdateTimeTip: '更新时间',
    TipDetail: '修改参考的文件范围，将在几分钟后生效。具体文件内容的变更，将在 24 小时内更新至参考范围。',
    Cancel: '取消',
    Next: '下一步',
    Save: '保存',
    NoItems: '还没有团队',
    NoProjects: '还没有项目',
    Updated: '参考范围已设置',
    UpdatedWithMinutes: '参考范围已设置， {{fileCount}} 个文件需要更新，将在约 {{minutes}} 分钟后生效',
    UpdatedWithHours: '参考范围已设置，{{fileCount}} 个文件需要更新，将在约 {{hours}} 小时后生效',
    AlertLatest: '当前参考范围已更新至最新状态。',
    AlertLatestTooltip:
        '当前参考范围已更新至最新状态。若修改参考的文件范围，将在几分钟后生效。若参考范围内的文件内容的变更，将在 24 小时内更新至参考范围。',
    AlertNewlyAdded: '当前参考范围内存在 {{fileCount}} 个新增文件，正在识别中...',
    AlertNewlyAdded2: '当前参考范围内存在 {{fileCount}} 个新增文件，正在识别中。',
    AlertNewlyAddedTooltip:
        '由于导入文件、文件移动等操作，当前的参考范围内，存在新增文件。完成对新增文件的识别后，该文件中的内容可被纳入 AI 复制的参考范围。',
    AlertModified: '当前参考范围内存在 {{fileCount}} 个文件有内容变更，正在等待识别。',
    AlertModifiedTooltip:
        '当前的参考范围内，存在文件内容变更。完成对变更内容的识别后，新的内容可被纳入 AI 复制的参考范围。此过程一般会在文件更新后的 24 小时内完成。',
    LearnMore: '了解更多',
} as const

export const enTranslation = {
    AiDuplicateReferenceSelect: 'Reference range of AI Reduplication',
    Description1:
        'You can use AI Reduplication to duplicate in bulk. Meanwhile, Motiff AI will generate and replace the graphic and text content based on historical design files.',
    Description2:
        'Before using AI Reduplication, please set the range of design files as a reference for content generation.',
    UpdateTimeTip: 'Timeliness for the update',
    TipDetail:
        'Changes made to the range of reference files will take effect in a few minutes. Changes made to file content will be updated to the reference range within 24 hours.',
    Cancel: 'Cancel',
    Next: 'Next',
    Save: 'Save',
    NoItems: 'No teams',
    NoProjects: 'No Projects',
    Updated: 'Reference range has been set',
    UpdatedWithMinutes:
        'Reference range has been set. {{fileCount}} {{file}} will be updated in about {{minutes}} minutes.',
    UpdatedWithHours: 'Reference range has been set. {{fileCount}} files will be updated in about {{hours}} {{hour}}.',
    AlertLatest: 'The current reference range has been updated to the latest version.',
    AlertLatestTooltip:
        'The current reference range has been updated to the latest version. Changes made to the range of reference files will take effect in a few minutes. Changes made to file content will be updated to the reference range within 24 hours.',
    AlertNewlyAdded: '{{fileCount}} newly added {{file}} in the current reference range are being identified...',
    AlertNewlyAdded2: '{{fileCount}} newly added {{file}} in the current reference range are being identified.',
    AlertNewlyAddedTooltip:
        'New files have been added to the current reference range due to activities such as importing files or moving them to the range. Once these new files are identified, their contents will be added to the AI Reduplication reference range.',
    AlertModified:
        '{{fileCount}} {{file}} in the current reference range have updated content and are pending identification.',
    AlertModifiedTooltip:
        'Changes in file content within the reference range are pending identification. This process will be completed within 24 hours after the file content changes. Once identified, they will be added to the AI Reduplication reference range.',
    LearnMore: 'Learn More',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
