import { useMemo } from 'react'
import { useAsync, useWindowSize } from 'react-use'
import { InputV2, OneInputModal, ScrollView, WKDialog } from '../../../../ui-lib/src'
import { BatchMoveDocModalProps, BatchMoveDocModalProvider, useBatchMoveDocModalContext } from './context'
import { translation } from './index.translation'
import { MoveDraftTipBanner } from './move-draft-tip-banner'
import { WKTeamFolderTree } from './tree'

export const _BatchMoveDocModal = () => {
    const {
        onClose,
        createFolder,
        defaultSelectedFolderId,
        moveDocs,
        visible,
        userSelectedFolder,
        setQs,
        selectKey,
        result,
        createFolderModalVisible,
        createFolderTeamId,
        setCreateFolderModalVisible,
        setVisible,
        showMoveDraftTipBanner,
        checkMoveDocAvailable,
    } = useBatchMoveDocModalContext()
    const { height } = useWindowSize()
    const scrollContainerHeight = useMemo(() => {
        const _height = Math.min(346, height * 0.77 - 203)
        return Math.max(136, _height)
    }, [height])

    const moveDocAvailable = useAsync(async () => {
        return await checkMoveDocAvailable()
    })
    if (!moveDocAvailable) {
        return <></>
    }

    return (
        <>
            <WKDialog
                title={translation('MoveFile')}
                tabIndex={-1}
                onCancel={() => onClose(false)}
                onOk={moveDocs}
                okText={translation('Move')}
                okButtonProps={
                    {
                        disabled: !userSelectedFolder || defaultSelectedFolderId === userSelectedFolder.id,
                        ['data-testid']: 'batch-move-doc-modal-ok-btn',
                    } as any
                }
                cancelButtonProps={
                    {
                        ['data-testid']: 'batch-move-doc-modal-cancel-btn',
                    } as any
                }
                maskStyle={{
                    opacity: visible ? 1 : 0,
                    zIndex: visible ? undefined : -1,
                }}
                visible={true}
                bodyStyle={{ padding: 0 }}
            >
                {showMoveDraftTipBanner && <MoveDraftTipBanner />}
                <div className="px-6 pt-6">
                    <InputV2.Search
                        autoFocus
                        debounce
                        onSearch={(s) => setQs(s.trim())}
                        placeholder={translation('SearchForA')}
                        dataTestIds={{ clearIcon: 'icon-close-svg' }}
                    />
                    <div
                        className="-mx-6 mt-4"
                        style={{ height: scrollContainerHeight }}
                        data-testid="move-file-center"
                    >
                        <ScrollView selectKey={selectKey}>
                            <div className="overflow-hidden px-6">{result && <WKTeamFolderTree />}</div>
                        </ScrollView>
                    </div>
                </div>
            </WKDialog>
            <OneInputModal
                key={createFolderModalVisible}
                title={translation('NewProject')}
                maxCharCodeLength={100}
                lowerCaseName={translation('lowerCaseName')}
                valueLabel={translation('ProjectName')}
                visible={!!createFolderModalVisible}
                onCancel={() => {
                    createFolderTeamId.current = undefined
                    setVisible(true)
                    setCreateFolderModalVisible(0)
                }}
                onSubmit={(name) => createFolder(name, createFolderTeamId.current!)}
            />
        </>
    )
}
export const BatchMoveDocModal = (props: BatchMoveDocModalProps) => {
    return (
        <BatchMoveDocModalProvider {...props}>
            <_BatchMoveDocModal></_BatchMoveDocModal>
        </BatchMoveDocModalProvider>
    )
}
