import style from './popup.module.less'
import type { MinDistanceNearEdge, PopupPosition, RectContainer, RectContent } from './type'
import { Direction } from './type'

const arrowDirection = {
    top: style.arrow_up,
    bottom: style.arrow_down,
    left: style.arrow_left,
    right: style.arrow_right,
}

export function calculatePopupPosition(position: PopupPosition, base: RectContainer, content: RectContent, offset = 0) {
    const pos = position.toLocaleLowerCase().split(' ')
    const contentPos: { top: number | undefined; left: number | undefined } = {
        top: undefined,
        left: undefined,
    }
    const arrowPos: { top: number | undefined; left: number | undefined } = { top: undefined, left: undefined }
    let arrowDirectionClassName: string | undefined = undefined

    switch (pos[0]) {
        case Direction.Top:
            {
                contentPos.top = base.top - content.height - offset
                arrowPos.top = base.top - offset
                arrowDirectionClassName = arrowDirection.bottom
            }
            break
        case Direction.Right:
            {
                contentPos.left = base.right + offset
                arrowPos.left = contentPos.left
                arrowDirectionClassName = arrowDirection.left
            }
            break
        case Direction.Bottom:
            {
                contentPos.top = base.bottom + offset
                arrowPos.top = contentPos.top
                arrowDirectionClassName = arrowDirection.top
            }
            break
        case Direction.Left:
            {
                contentPos.left = base.left - content.width - offset
                arrowPos.left = base.left - offset
                arrowDirectionClassName = arrowDirection.right
            }
            break
        case Direction.Center:
            {
                contentPos.left = (base.right + base.left - content.width) / 2
                contentPos.top = (base.bottom + base.top - content.height) / 2
            }
            break
        default:
    }
    switch (pos[1]) {
        case Direction.Top:
            {
                contentPos.top = base.top
                arrowPos.top = base.top + offset
            }
            break
        case Direction.Right:
            {
                contentPos.left = base.right - content.width
                arrowPos.left = base.right - offset
            }
            break
        case Direction.Bottom:
            {
                contentPos.top = base.bottom - content.height
                arrowPos.top = base.bottom - offset
            }
            break
        case Direction.Left:
            {
                contentPos.left = base.left
                arrowPos.left = base.left + offset
            }
            break
        case Direction.Center:
            {
                if (pos[0] === Direction.Top || pos[0] === Direction.Bottom) {
                    contentPos.left = (base.right + base.left - content.width) / 2
                    arrowPos.left = (base.right + base.left) / 2
                } else if (pos[0] === Direction.Left || pos[0] === Direction.Right) {
                    contentPos.top = (base.bottom + base.top - content.height) / 2
                    arrowPos.top = (base.bottom + base.top) / 2
                }
            }
            break
        default:
    }

    return { contentPos, arrowPos, arrowDirectionClassName }
}

export function overflowAdjust(
    position: PopupPosition,
    width: number,
    height: number,
    top: number,
    left: number,
    minEdge: MinDistanceNearEdge,
    skip?: boolean
) {
    const { clientWidth, clientHeight } = document.documentElement
    const pos = position.toLocaleLowerCase().split(' ')
    let amendX = 0,
        amendY = 0
    const minHorizontalEdge = minEdge[1] + minEdge[3]
    if ((pos[0] === Direction.Top || pos[0] === Direction.Bottom) && width + minHorizontalEdge <= clientWidth) {
        const right = left + width + minEdge[1]
        if (right > clientWidth) {
            amendX = clientWidth - right
        } else if (left < minEdge[3]) {
            amendX = minEdge[3] - left
        }
    }
    const minVerticalEdge = minEdge[0] + minEdge[2]
    if (
        (pos[1] === Direction.Left || pos[1] === Direction.Right) &&
        height + minVerticalEdge <= clientHeight &&
        !skip
    ) {
        const bottom = top + height + minEdge[2]
        if (height + minVerticalEdge + top > clientHeight) {
            amendY = clientHeight - bottom
        } else if (top < minEdge[0]) {
            amendY = minEdge[0] - top
        }
    }
    return { amendX, amendY }
}
