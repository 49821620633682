import { Fragment, useState } from 'react'
import { WKTextButton } from '../../../../../../ui-lib/src'
import { RoleStatus } from '../../../../kernel/interface/type'
import { usePlanAndUserStateService } from '../../../../main/app-context'
import { BatchMoveDocModal } from '../../../../share/batch-move-doc-modal'
import { PayUpgradeDialog } from '../../../../share/payment/pay-upgrade-dialog/pay-upgrade-dialog'
import {
    PayUpgradeDialogFrom,
    PayUpgradeDialogType,
} from '../../../../share/payment/pay-upgrade-dialog/pay-upgrade-dialog-struct/type'
import { UpgradePopupType, WKFrogTask } from '../../../../share/wk-frog-task'
import { navigateToPricing } from '../../../../utils/payment'
import { inOtherTeamDraftBox, isStarterPlan } from '../../../../utils/plan-status-checker'
import { useDocInfoContext } from '../../../context/top-area-context'
import styles from './upgrade-tip.module.less'
import { translation } from './upgrade-tip.translation'

interface ContentWithButtonProps {
    content: string
    button: string
    handle: () => void
    testId: string
}

// 创建 ContentWithButton 组件
const ContentWithButton = ({ content, button, handle, testId }: ContentWithButtonProps) => {
    // 将 content 分割为文本片段和占位符
    const parts = content.split('[[button]]')

    return (
        <span>
            {parts.map((part, index) => (
                <Fragment key={index}>
                    {part}
                    {index < parts.length - 1 && (
                        <WKTextButton type="primary" size={12} onClick={handle} testId={testId}>
                            {button}
                        </WKTextButton>
                    )}
                </Fragment>
            ))}
        </span>
    )
}

export function UpgradeTip({ existsBeyondThresholdVersion }: { existsBeyondThresholdVersion: boolean }) {
    const [moveFileVisible, setMoveFileVisible] = useState<boolean>(false)
    const { docData, getCurrentDocOrganization } = useDocInfoContext()
    const planAndUserStateService = usePlanAndUserStateService()
    const planInfo = planAndUserStateService.useZustandStore.use.planAndUserState()

    const isFreeTeam = planInfo && isStarterPlan(planInfo)
    const isOtherTeamDraft = docData && inOtherTeamDraftBox(planInfo, docData)

    const show = docData && (isFreeTeam || isOtherTeamDraft)

    if (!show) {
        return null
    }

    const btnHandleMap = {
        [translation('LearnMore')]: {
            handle: () => navigateToPricing(),
            testId: 'history-limit-learn-more',
        },
        [translation('Upgrade')]: {
            handle: () => {
                WKFrogTask.payment.UpgradePopupType(UpgradePopupType.SeeVersionHistory)
                PayUpgradeDialog.show({
                    type: PayUpgradeDialogType.Professional,
                    title: translation('PayUpgradeDialogTitle'),
                    subtitle: translation('PayUpgradeDialogSubTitle'),
                    starterProps: {
                        privileges: [
                            { name: translation('OnlyShowVersionWithin30D'), enable: true },
                            { name: translation('ThreeFileOneProject'), enable: true },
                            { name: translation('ThreePagesPerFile'), enable: true },
                        ],
                    },
                    professionalProps: {
                        privileges: [
                            { name: translation('UnlimitedVersions1'), enable: true },
                            { name: translation('UnlimitedFilesAndProjects'), enable: true },
                            { name: translation('UnlimitedPagesInFiles'), enable: true },
                        ],
                        submitProps: {
                            from: PayUpgradeDialogFrom.Editor,
                            teamId: docData.teamId,
                        },
                    },
                })
            },
            testId: 'history-limit-upgrade',
        },
        [translation('MoveFile')]: {
            handle: () => setMoveFileVisible(true),
            testId: 'history-limit-move-file',
        },
    }

    const renderContent = () => {
        if (isOtherTeamDraft) {
            const content = existsBeyondThresholdVersion
                ? translation('RecentVersions')
                : translation('UnlimitedVersions')
            const btn = docData?.role === RoleStatus.Owner ? translation('MoveFile') : translation('LearnMore')
            return <ContentWithButton content={content} button={btn} {...btnHandleMap[btn]} />
        } else if (isFreeTeam) {
            const content = existsBeyondThresholdVersion
                ? translation('RecentVersionsUpgrade')
                : translation('UnlimitedVersionsUpgrade')
            const btn = translation('Upgrade')
            return <ContentWithButton content={content} button={btn} {...btnHandleMap[btn]} />
        }
    }

    const getTitle = () =>
        existsBeyondThresholdVersion ? translation('ViewMoreHistory') : translation('UnlimitedHistory')

    return (
        <>
            {moveFileVisible && (
                <BatchMoveDocModal
                    docs={[docData]}
                    forceHideTipBanner={true}
                    organization={getCurrentDocOrganization()!}
                    onClose={() => setMoveFileVisible(false)}
                />
            )}
            <div className={styles.upgradeTip} data-testid="history-panel-upgrade-tip">
                <div className={styles.title}>{getTitle()}</div>
                {renderContent()}
            </div>
        </>
    )
}
