import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Inspect: '标注',
    AddComment: '评论',
    ShowHideUI: '显示/隐藏界面',
    OpenLayersPanel: '打开图层面板',
    MultiplayerCursors: '多人协作光标',
    OpenDesignMode: '进入设计模式',
    ZoomInOut: '放大/缩小',
    ZoomTo: '缩放至',
    ZoomToFit: '缩放至显示全部',
    ZoomToSelection: '缩放至显示选中',
    Copy: '复制',
    CopyAs: '复制为',
    SelectNone: '取消选中',
    SelectChildren: '选中子图层',
    SelectParent: '选中父图层',
} as const

export const enTranslation = {
    Inspect: 'Inspect',
    AddComment: 'Add comment',
    ShowHideUI: 'Show/Hide UI',
    OpenLayersPanel: 'Open layers panel',
    MultiplayerCursors: 'Multiplayer cursors',
    OpenDesignMode: 'Open design mode',
    ZoomInOut: 'Zoom in/out',
    ZoomTo: 'Zoom to',
    ZoomToFit: 'Zoom to fit',
    ZoomToSelection: 'Zoom to selection',
    Copy: 'Copy',
    CopyAs: 'Copy as',
    SelectNone: 'Select none',
    SelectChildren: 'Select children',
    SelectParent: 'Select parent',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
