import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import { ToastKey, wkDialogConfirm, WKToast } from '../../../../ui-lib/src'
import { domLocation, generateRouterPath, isEnglishLanguage, RouteToken } from '../../../../util/src'
import { environment } from '../../environment'
import { GlobalCanvas } from '../../main/gpu/global-canvas'
import { LocalStorageKey } from '../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../web-storage/local-storage/storage'

export function UnSupportWebGLPop() {
    const helpDoc = environment.helpDocsUrl.webgl
    wkDialogConfirm.warning({
        title: translation('UhOh...We'),
        okText: translation('GotIt'),
        content: isEnglishLanguage() ? (
            <p>
                The file cannot be opened because WebGL is not supported or is disabled in your browser. Find out more
                about how to enable it in the{' '}
                <a href={helpDoc} target="_blank" rel="noreferrer" style={{ cursor: 'default' }}>
                    Help center
                </a>
                {'.'}
            </p>
        ) : (
            <p>
                由于浏览器不支持 WebGL 或 WebGL 未被启用，我们无法打开文件。你可以通过查看
                <a href={helpDoc} target="_blank" rel="noreferrer" style={{ cursor: 'default' }}>
                    &nbsp; 帮助文档 &nbsp;
                </a>
                尝试启用 WebGL。
            </p>
        ),
    })
}

const webglAccToastKeys: Set<ToastKey> = new Set()
function UnAccelerateToast() {
    if (environment.isAbroad) {
        return
    }
    const helpDoc = environment.helpDocsUrl.webgl
    const showMoreTid = WKToast.show(translation('ImprovePerformanceBy'), {
        duration: -1,
        firstButton: { type: 'x' },
        secondButton: {
            type: 'button',
            text: translation('LearnMore'),
            onClick: () => window.open(helpDoc),
        },
    })
    webglAccToastKeys.add(showMoreTid)
}

// 当前是否开启了硬件加速
function isGPUAcceleratorEnabled() {
    const webgl = GlobalCanvas.webgl()
    if (webgl) {
        const debugInfo = webgl.context.getExtension('WEBGL_debug_renderer_info')
        if (debugInfo) {
            const renderer = webgl.context.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)
            return !/SwiftShader/gi.test(renderer)
        }
    }
    return false
}

function isWebGLSupported() {
    return !!GlobalCanvas.webgl()
}

export function detectWebGlEnabled() {
    if (!isGPUAcceleratorEnabled()) {
        return isWebGLSupported()
    }
    return true
}

export function toastWhenCloseWebgl() {
    if (!isGPUAcceleratorEnabled() && isWebGLSupported()) {
        UnAccelerateToast()
    }
}

export function popWhenWebGLNoSupport() {
    if (!detectWebGlEnabled()) {
        UnSupportWebGLPop()
        return true
    }
    return false
}

export function redirectWhenWebGLNoSupport() {
    if (!detectWebGlEnabled()) {
        enhancedLocalStorage.setSerializedItem(LocalStorageKey.WebGLSupport, '0')
        domLocation().replace('/' + generateRouterPath(RouteToken.OrganizationWithoutOrgId + '/' + RouteToken.Recent))
    }
}
export function popAfterRedirectWhenWebGLNoSupport() {
    if (enhancedLocalStorage.getSerializedItem(LocalStorageKey.WebGLSupport) === '0') {
        enhancedLocalStorage.setSerializedItem(LocalStorageKey.WebGLSupport, '1')
        UnSupportWebGLPop()
    }
}

export function clearToast() {
    webglAccToastKeys.forEach((toastKey) => WKToast.close(toastKey))
}
