import { isNil } from 'lodash-es'

export function clampMinMax(value: number, minMax: { min?: number; max?: number }) {
    if (!isNil(minMax.min)) {
        value = Math.max(minMax.min, value)
    }
    if (!isNil(minMax.max)) {
        value = Math.min(minMax.max, value)
    }
    return value
}
