import { DELETE, GET, POST, PUT } from '@tutor/network-core'
import { getGbLength } from '../../../../util/src'
import type {
    DocID,
    DocPageCountVO,
    DocPrototypeVO,
    DocSearchGetVO,
    DocSetVO,
    DocumentSearchGetVO,
    DocWithAuthorityVO,
    OrgID,
    DocThumbnailCosAuthorizationVO,
    FolderID,
} from '../interface/type'
import { BusinessStatusCode } from '../interface/request-error-code'
import { omitByValue } from '../util/omit-by-value'
import { BaseCommonRequest } from './base-request'
import { translation } from './document.translation'
import { RequestResponseErrorHandler } from './error-handler'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'

// 获取文档信息
@GET
export class GetDocRequest extends BaseCommonRequest<DocWithAuthorityVO> {
    constructor(private readonly docId: string) {
        super()
    }

    public override requestUrl() {
        return `documents/${this.docId}`
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 兼容在原型预览下中获取文档信息的方法，可查看原型权限下 GetDocRequest 服务端处理为无权限拿不到文档数据
export const getDocRequest = async (docId: string, isPrototype: boolean) => {
    if (isPrototype) {
        return (await new GetPrototypeDocRequest(docId, '').start()).document
    } else {
        return await new GetDocRequest(docId).start()
    }
}
// 兼容在原型预览下中获取文档信息的方法，可查看原型权限下 GetDocRequest 服务端处理为无权限拿不到文档数据
export const getDocRequestWithSignal = async (docId: string, isPrototype: boolean, signal: TraceableAbortSignal) => {
    if (isPrototype) {
        return (await new GetPrototypeDocRequest(docId, '').startWithSignal(signal)).document
    } else {
        return await new GetDocRequest(docId).startWithSignal(signal)
    }
}

// 获取原型文档信息
@GET
export class GetPrototypeDocRequest extends BaseCommonRequest<DocPrototypeVO> {
    constructor(private readonly docId: string, private readonly pageId: string) {
        super()
    }

    public override requestUrl() {
        return `documents/${this.docId}/prototype`
    }

    public override requestDidFailed(): boolean {
        return false
    }

    public override requestArgument() {
        return {
            pageId: this.pageId,
        }
    }
}

// 批量获取文档信息
@POST
export class GetDocMapRequest extends BaseCommonRequest<Record<DocID, DocWithAuthorityVO>> {
    constructor(private readonly docIds: DocID[]) {
        super()
    }

    public override requestUrl() {
        return `documents/ids`
    }

    public override requestBody() {
        return this.docIds
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 极速新建文档，客户端提供 id / 初始数据
@POST
export class CreateDocRequest extends BaseCommonRequest<DocWithAuthorityVO> {
    constructor(
        private readonly newDoc: { id: string; name: string; folderId: string },
        private readonly archive: Uint8Array
    ) {
        super()
    }

    public override requestUrl() {
        return `documents/v2`
    }

    public override requestArgument(): { [key: string]: string | number } | undefined {
        const { id, name, folderId } = this.newDoc

        return {
            id,
            name,
            folderId,
        }
    }

    public override requestBody() {
        const formData = new FormData()
        formData.append('file', new Blob([this.archive]))
        return formData
    }

    public override requestTimeout(): number {
        return 60 * 10
    }
}

// 更新一个文档的信息
@PUT
export class UpdateDocRequest extends BaseCommonRequest<boolean> {
    constructor(private readonly docId: DocID, private readonly doc: DocSetVO) {
        super()
    }

    public override requestUrl() {
        return `documents/${this.docId}`
    }

    public override requestBody() {
        return this.doc
    }

    public override requestDidFailed(): boolean {
        let message = ''
        const length = getGbLength(this.doc.name)
        if (length > 100) {
            message = translation('PleaseDoNot')
        }
        if (length === 0) {
            message = translation('AFileMust')
        }
        window.dispatchEvent(
            new CustomEvent('request-error', {
                detail: {
                    statusCode: this.responseStatusCode,
                    message: message,
                    direction: 'top',
                },
            })
        )
        return false
    }
}

// 移动文件到另一个项目
@PUT
export class MoveDocRequest extends BaseCommonRequest<boolean> {
    constructor(private readonly docIds: DocID | DocID[], private readonly toFolderId: FolderID) {
        super()
    }

    public override requestUrl() {
        return `documents/move`
    }
    public override requestDidFailed(e: any): boolean {
        const { status, businessStatus } = RequestResponseErrorHandler(e)
        if (status == 410 || businessStatus == 44009) {
            window.dispatchEvent(
                new CustomEvent('request-error', {
                    detail: {
                        statusCode: 410,
                        message: `${translation("Can'tFindObject")}`,
                    },
                })
            )
        } else {
            window.dispatchEvent(
                new CustomEvent('request-error', {
                    detail: { statusCode: status },
                })
            )
        }
        return false
    }

    public override requestBody() {
        return {
            docIds: [this.docIds].flat(),
            toFolderId: this.toFolderId,
        }
    }
}

// 用户访问一个文档时，上传其最近访问记录
@POST
export class AddDocOpenRecordRequest extends BaseCommonRequest {
    constructor(private readonly docId: DocID) {
        super()
    }

    public override requestUrl() {
        return 'recents'
    }

    public override requestArgument() {
        return {
            docId: this.docId,
        }
    }
}

@POST
export class PostExportRecord extends BaseCommonRequest {
    constructor(private readonly docId: DocID) {
        super()
    }

    public override requestUrl() {
        return 'documents/export-content'
    }

    public override requestArgument() {
        return {
            docId: this.docId,
        }
    }
}

// 查询目录下的文档，草稿箱也是一个目录
@GET
export class QueryDocFromFolderRequest extends BaseCommonRequest<Array<DocWithAuthorityVO>> {
    constructor(private readonly folderId: string) {
        super()
    }

    public override requestUrl() {
        return 'documents/query-from-folder'
    }

    public override requestDidFailed(): boolean {
        return false
    }

    public override requestArgument() {
        return {
            folderId: this.folderId,
        }
    }
}

// 查询回收站文档
@GET
export class QueryTrashedDocRequest extends BaseCommonRequest<Array<DocWithAuthorityVO>> {
    constructor(private readonly orgId?: OrgID) {
        super()
    }

    public override requestUrl() {
        return 'documents/query-trashed'
    }
    public override requestArgument() {
        return omitByValue({ orgId: this.orgId })
    }
}

// 复制文档
@POST
export class BatchDuplicateDocRequest extends BaseCommonRequest<DocWithAuthorityVO[]> {
    constructor(private readonly docIds: DocID[]) {
        super()
    }
    public override requestUrl() {
        return `documents/batch-copy`
    }
    public override requestBody() {
        return this.docIds
    }
    public override requestDidFailed(): boolean {
        return false
    }
}

// 从回收站复制文档至草稿箱
@POST
export class BatchDuplicateDocToDraftRequest extends BaseCommonRequest<DocWithAuthorityVO[]> {
    constructor(private readonly docIds: DocID[]) {
        super()
    }

    public override requestUrl() {
        return `documents/batch-copy-trashed-to-draft`
    }
    public override requestBody() {
        return this.docIds
    }
}

// 批量将文档放入回收站（标记状态为删除）
@PUT
export class BatchTrashDocRequest extends BaseCommonRequest {
    constructor(private readonly docIds: DocID[]) {
        super()
    }

    public override requestUrl() {
        return `documents/batch-trash`
    }

    public override requestBody() {
        return this.docIds
    }
}

// 批量从回收站中恢复文档
@PUT
export class BatchRecoverDocRequest extends BaseCommonRequest<DocWithAuthorityVO[]> {
    constructor(private readonly docIds: DocID[]) {
        super()
    }

    public override requestUrl() {
        return `documents/batch-restore`
    }

    public override requestBody() {
        return this.docIds
    }
    public override requestDidFailed(e: Error): boolean {
        const { businessStatus } = RequestResponseErrorHandler(e)
        if (businessStatus != BusinessStatusCode.Unavailable) {
            window.dispatchEvent(new CustomEvent('request-error', { detail: { statusCode: this.responseStatusCode } }))
        }
        return false
    }
}

// 批量永久删除文档（需要已经处于 trashed 状态）
@DELETE
export class BatchPermanentlyRemoveDocRequest extends BaseCommonRequest {
    constructor(private readonly docIds: DocID[]) {
        super()
    }

    public override requestUrl() {
        return `documents/batch`
    }

    public override requestBody() {
        return this.docIds
    }
}

// 批量获取包含当前登录用户权限的文档信息
@POST
export class GetDocWithAuthorityVOListRequest extends BaseCommonRequest<DocWithAuthorityVO[]> {
    constructor(private readonly docIds: DocID[]) {
        super()
    }

    public override requestUrl() {
        return `documents/query-and-authorize`
    }

    public override requestBody() {
        return {
            docIds: this.docIds,
        }
    }
}

// 批量获取包含当前登录用户权限的文档信息
@POST
export class GetDocWithAuthorityVOListByIds extends BaseCommonRequest<DocWithAuthorityVO[]> {
    constructor(private readonly docIds: DocID[]) {
        super()
    }

    public override requestUrl() {
        return `documents/ids`
    }

    public override requestBody() {
        return this.docIds
    }
}

// 获取单个文档封面cos上传授权
@GET
export class GetDocThumbnailAuth extends BaseCommonRequest<DocThumbnailCosAuthorizationVO> {
    constructor(private docId: DocID, private format: string) {
        super()
    }
    public override requestUrl() {
        return `documents/${this.docId}/thumbnail/uploadAuthorization?format=${this.format}`
    }
}

// 通知服务端 封面Id
@PUT
export class PutThumbnailNodeId extends BaseCommonRequest {
    constructor(private docId: string, private thumbnailNodeId: string, private canvasBackgroundColor: string) {
        super()
    }
    public override requestUrl() {
        return `documents/${this.docId}/thumbnail-node`
    }
    public override requestBody() {
        return {
            thumbnailNodeId: this.thumbnailNodeId,
            canvasBackgroundColor: this.canvasBackgroundColor,
        }
    }
}

// 根据条件搜索文件
@GET
export class DocSearchRequest extends BaseCommonRequest<ReadonlyArray<DocSearchGetVO>> {
    constructor(private queryString: string, private orgId?: OrgID) {
        super()
    }

    public override requestUrl() {
        return 'documents/search'
    }

    public override requestArgument() {
        return omitByValue({ queryString: this.queryString, orgId: this.orgId })
    }
}

// 根据条件搜索文件
@GET
export class DocumentSearchRequest extends BaseCommonRequest<DocumentSearchGetVO> {
    constructor(private queryString: string, private orgId: OrgID, private pageSize?: number) {
        super()
    }

    public override requestUrl() {
        return 'documents/search/v2'
    }

    public override requestArgument() {
        return omitByValue({ queryString: this.queryString, orgId: this.orgId, pageSize: this.pageSize })
    }
}

//创建版本，用于导入场景客户端fix完数据后调用
@POST
export class AfterImportFixOperations extends BaseCommonRequest {
    constructor(private docId: string) {
        super()
    }
    public override requestUrl() {
        return `documents/version/${this.docId}/after-import-fix-operations`
    }
}

// 查询文档列表中各自的页面数量
@POST
export class GetDocPageCount extends BaseCommonRequest<DocPageCountVO> {
    constructor(private readonly docIds: DocID[]) {
        super()
    }

    public override requestUrl() {
        return `documents/page-count`
    }
    public override requestBody() {
        return this.docIds
    }
}
