import { MotiffApi } from '@motiffcom/plugin-api-types'
import {
    FrameNodePluginData,
    FrameNodePluginDataKeys,
    NodePluginData,
    SceneNodePluginData,
    StylePluginData,
    TextNodePluginData,
    TextNodePluginDataKeys,
    VectorNodePluginData,
    VectorNodePluginDataKeys,
} from './constant'

export function exportStylePluginAPIData(node: MotiffApi.SceneNode): StylePluginData {
    // Get all the style id, find the node, export the data
    return {}
}

export function exportSceneNodePluginAPIData(node: MotiffApi.SceneNode): SceneNodePluginData | null {
    switch (node.type) {
        case 'FRAME':
            return exportFramePluginAPIData(node)
        case 'VECTOR':
            return exportVectorPluginAPIData(node)
        case 'TEXT':
        case 'RECTANGLE':
        case 'ELLIPSE':
        case 'POLYGON':
        case 'STAR':
        case 'LINE':
        case 'BOOLEAN_OPERATION':
        case 'COMPONENT':
        case 'INSTANCE':
        case 'GROUP':
        case 'COMPONENT_SET':
        default:
            return null
        // throw new Error(`unsupported type: ${node.type}`)
    }
}

export function exportNodePluginAPIData(node: MotiffApi.SceneNode): NodePluginData {
    const nodeData = exportSceneNodePluginAPIData(node)
    const stylePluginData = exportStylePluginAPIData(node)
    return {
        style: stylePluginData,
        node: nodeData,
    }
}

export function exportTextPluginAPIData(node: MotiffApi.TextNode): TextNodePluginData {
    const entries = Object.entries(node)
    const data = Object.fromEntries(
        entries.filter(([key, _]) => TextNodePluginDataKeys.includes(key as any)).map((v) => structuredClone(v))
    ) as unknown as TextNodePluginData
    return data
}

export function exportFramePluginAPIData(node: MotiffApi.FrameNode): FrameNodePluginData {
    const data: FrameNodePluginData = {}
    for (const key of FrameNodePluginDataKeys) {
        const v = node[key]
        if (v) {
            data[key] = v
        }
    }
    data.children = []
    for (const child of node.children ?? []) {
        const childData = exportSceneNodePluginAPIData(child)
        if (childData) {
            data.children.push(childData)
        }
    }
    return data
}

export function exportVectorPluginAPIData(node: MotiffApi.VectorNode): VectorNodePluginData {
    const data: VectorNodePluginData = {}
    for (const key of VectorNodePluginDataKeys) {
        // TODO(chaibowen): examine why any is needed
        const v: any = node[key]
        if (v) {
            data[key] = v
        }
    }
    return data
}
