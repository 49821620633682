import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    f: '免费',
    sf: '已经创建 {{currentStep}}/{{sumStep}} 文件',
    sp: '基础版团队中仅能创建 {{sumStep}} 个文件。升级后：',

    up: '无限创建项目、文件、页面',
    sl: '发布组件库',
    uv: '查看无限历史版本等...',

    cp: '查看付费版本',
} as const

export const enTranslation = {
    f: 'Free',
    sf: '{{currentStep}} of {{sumStep}} free files created.',
    sp: 'Starter plans are free but they limit you to just {{sumStep}} files. Upgrade for:',

    up: 'Unlimited Projects, files, pages',
    sl: 'Sharing library',
    uv: 'Unlimited version history and more...',

    cp: 'Compare plans',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
