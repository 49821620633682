import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    LinkCopied: '链接已复制',
    Position: '选中容器',
    CopyLink: '复制链接',
    flows: '流程',
} as const

export const enTranslation = {
    LinkCopied: 'Link copied',
    Position: 'Select frame',
    CopyLink: 'Copied to clipboard',
    flows: 'Flows',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
