/* eslint-disable no-restricted-imports */
import { useMemo, useRef } from 'react'
import { Wukong } from '@wukong/bridge-proto'
import { EditorTopBarRef } from '../../../share/editor-top-bar/wrapper'
import { useViewState } from '../../../view-state-bridge'
import { TopAreaContextProps } from './top-area-context'

export function useTopArea() {
    const hasSelection = useViewState('hasSelection')
    const docReadonly = useViewState('docReadonly')
    const editorMode = useViewState('editorModeState')?.editorMode
    const editorTopBarRef = useRef<EditorTopBarRef>(null)

    const isNotTitle = useMemo(() => {
        return hasSelection && !docReadonly && editorMode !== Wukong.DocumentProto.EditorMode.EM_Comment
    }, [docReadonly, editorMode, hasSelection])

    const contextValue: TopAreaContextProps = useMemo(() => {
        return {
            getCenterWidth: () => editorTopBarRef.current?.getCenterElement()?.getBoundingClientRect().width,
            editorTopBarRef,
        }
    }, [])

    return { isNotTitle, contextValue, docReadonly, editorTopBarRef }
}
