import { ComponentId, ContentHash, LibraryId, StyleVOId, VariableId, VariableSetId } from './component-style-library-id'
import { DocID } from './type'

export * from './component-style-library-id'

export type LibraryComponentStyleWithDocId<T> = T & {
    documentId: DocID | null // 前端塞的模板 docId 值，并非服务端下发值
}

export const enum EffectStyleIconType {
    Default,
    BackgroundBlur,
    LayerBlur,
    InnerButtomRight,
    InnerButtom,
    InnerButtomLeft,
    InnerLeft,
    InnerTopLeft,
    InnerTop,
    InnerTopRight,
    InnerRight,
    DropButtomRight,
    DropButtom,
    DropButtomLeft,
    DropLeft,
    DropTopLeft,
    DropTop,
    DropTopRight,
    DropRight,
}

export const enum LayoutGridStyleIconType {
    Default,
    Grid,
    Row,
    Column,
}

type NodeId = string

export interface ComponentUpdateVO {
    /**
     * 组件id
     * @format int64
     */
    readonly id?: ComponentId | null
    /**
     * 所属组件库id
     * @format int64
     */
    readonly libraryId: LibraryId
    /**
     * 在母版组件库中的nodeId
     */
    readonly nodeId: NodeId
    /**
     * 组件的名字
     */
    readonly name?: string | null
    /**
     * 组件的描述信息
     */
    readonly description?: string | null
    /**
     * 组件的hash值，用来判断是否有更新
     */
    readonly contentHash?: ContentHash | null
    /**
     * 在组件库中该组件所属的pageId
     */
    readonly containingPageId?: string | null
    /**
     * 在组件库中该组件所属的page名称
     */
    readonly containingPageName?: string | null
    /**
     * 最小节点宽度
     * @format int32
     */
    readonly minNodeWidth?: number | null
    /**
     * 最小节点高度
     * @format int32
     */
    readonly minNodeHeight?: number | null

    /**
     * 缩略图
     */
    readonly thumbnailData?: string | null
    /**
     * 组件的node信息
     */
    readonly nodeDataV2?: string

    /**
     * 外层组件集的nodeId，在组件集中的component才会有
     */
    readonly containerNodeId?: string
}

export interface ComponentWithoutDocIdGetVO {
    /**
     * 组件id
     * @format int64
     */
    readonly id: ComponentId
    /**
     * 所属组件库id
     * @format int64
     */
    readonly libraryId: LibraryId
    /**
     * 在母版组件库中的nodeId
     */
    readonly nodeId: NodeId
    /**
     * 组件的名字
     */
    readonly name?: string | null
    /**
     * 组件的描述信息
     */
    readonly description?: string | null
    /**
     * 组件的hash值，用来判断是否有更新
     */
    readonly contentHash?: ContentHash | null
    /**
     * 在组件库中该组件所属的pageId
     */
    readonly containingPageId?: string
    /**
     * 在组件库中该组件所属的page名称
     */
    readonly containingPageName?: string | null
    /**
     * 最外层 frame id
     */
    readonly containingFrameId?: string | null
    /**
     * 最外层 frame name
     */
    readonly containingFrameName?: string | null
    /**
     * 最外层 frame 的背景色
     */
    readonly containingFrameBackgroundColor?: string | null
    /**
     * 最小节点宽度
     * @format int32
     */
    readonly minNodeWidth?: number | null
    /**
     * 最小节点高度
     * @format int32
     */
    readonly minNodeHeight?: number | null

    /**
     * 组件的node信息
     */
    readonly nodeDataPath?: string

    /**
     * 是否被删除
     */
    readonly removed: boolean

    /**
     * 缩略图
     */
    readonly thumbnailDataPath?: string

    /**
     * 外层组件集的nodeId，在组件集中的component才会有
     */
    readonly containerNodeId?: string
}

export type ComponentGetVO = LibraryComponentStyleWithDocId<ComponentWithoutDocIdGetVO>

export interface StyleUpdateVO {
    /**
     * 样式id
     * @format int64
     */
    readonly id?: StyleVOId | null
    /**
     * 所属组件库id
     * @format int64
     */
    readonly libraryId: LibraryId
    /**
     * 在组件库中的nodeId
     */
    readonly nodeId: NodeId
    /**
     * 样式的名字
     */
    readonly name?: string | null
    /**
     * 样式的类型
     * @format int32
     */
    readonly type?: number | null
    /**
     * 样式的描述信息
     */
    readonly description?: string | null
    /**
     * 样式的hash值，用来判断是否有更新
     */
    readonly contentHash?: ContentHash | null
    /**
     * 样式节点的顺序坐标，等于样式节点orderIndex值的base64
     */
    readonly sortPosition?: string | null

    /**
     * 缩略图
     */
    readonly thumbnailData?: string | null

    /**
     * 样式的node信息
     */
    readonly nodeDataV2?: string

    /**
     *  EffectStyle的 svg icon type 用于指定缩略图
     */
    readonly effectStyleIconType?: EffectStyleIconType

    readonly fontSize?: number

    readonly lineHeightValue?: number

    readonly lineHeightUnit?: number

    readonly layoutGridStyleIconType?: LayoutGridStyleIconType
}

export interface StyleWithoutDocIdGetVO {
    /**
     * 样式id
     * @format int64
     */
    readonly id: StyleVOId
    /**
     * 所属组件库id
     * @format int64
     */
    readonly libraryId: LibraryId
    /**
     * 在组件库中的nodeId
     */
    readonly nodeId: NodeId
    /**
     * 样式的名字
     */
    readonly name?: string | null
    /**
     * 样式的类型
     * @format int32
     */
    readonly type?: number | null
    /**
     * 样式的描述信息
     */
    readonly description?: string | null
    /**
     * 样式的hash值，用来判断是否有更新
     */
    readonly contentHash?: ContentHash | null
    /**
     * 样式节点的顺序坐标，等于样式节点orderIndex值的base64
     */
    readonly sortPosition?: string | null

    /**
     * 缩略图
     */
    readonly thumbnailDataPath?: string

    /**
     * 样式的node信息
     */
    readonly nodeDataPath?: string

    /**
     * 是否从原组件库被删除
     */
    readonly removed?: boolean

    /**
     *  EffectStyle的 svg icon type 用于指定缩略图
     */
    readonly effectStyleIconType?: EffectStyleIconType

    readonly fontSize?: number

    readonly lineHeightValue?: number

    readonly lineHeightUnit?: number

    readonly layoutGridStyleIconType?: LayoutGridStyleIconType
}

export type StyleGetVO = LibraryComponentStyleWithDocId<StyleWithoutDocIdGetVO>

export type ComponentSetUpdateVO = ComponentUpdateVO

export type ComponentSetWithoutDocIdGetVO = ComponentWithoutDocIdGetVO

export type ComponentSetGetVO = ComponentGetVO

export interface ComponentWithAllDataVO {}

export interface VariableSetWithoutDocIdGetVO {
    /**
     * 变量集id
     * @format int64
     */
    readonly id: VariableSetId

    /**
     * 所属组件库id
     * @format int64
     */
    readonly libraryId: LibraryId

    /**
     * 在母版组件库中的nodeId
     */
    readonly nodeId: NodeId

    /**
     * 变量集的名字
     */
    readonly name?: string | null

    /**
     * 变量集的hash值，用来判断是否有更新
     */
    readonly contentHash?: ContentHash | null

    /**
     * 是否被删除
     */
    readonly removed: boolean

    /**
     * 变量集中的变量数量
     */
    readonly variableCount?: number

    /**
     * 变量集的node信息
     */
    readonly nodeDataPath?: string

    /**
     * 变量集的modes信息, json字符串
     */
    readonly modes?: string
}

export type VariableSetGetVO = LibraryComponentStyleWithDocId<VariableSetWithoutDocIdGetVO>

export interface VariableWithoutDocIdGetVO {
    /**
     * 变量id
     * @format int64
     */
    readonly id: VariableId

    /**
     * 变量所属变量集id
     * @format int64
     */
    readonly collectionId: VariableSetId

    /**
     * 在母版组件库中的nodeId
     */
    readonly nodeId: NodeId

    /**
     * 变量的名字
     */
    readonly name?: string | null

    /**
     * 变量的描述信息
     */
    readonly description?: string | null

    /**
     * 变量的类型
     * @format int32
     */
    readonly type?: number | null

    /**
     * 变量的hash值，用来判断是否有更新
     */
    readonly contentHash?: ContentHash | null

    /**
     * 变量节点的顺序坐标，等于样式节点orderIndex值的base64
     */
    readonly sortPosition?: string | null

    /**
     * 是否被删除
     */
    readonly removed: boolean

    /**
     * 变量的node信息
     */
    readonly nodeDataPath?: string

    /**
     * 变量的scope信息, json字符串
     */
    readonly scope?: string

    /**
     * 变量的value信息, json字符串
     */
    readonly value?: string
}

export type VariableGetVO = LibraryComponentStyleWithDocId<VariableWithoutDocIdGetVO>
