import { DELETE, GET, POST, PUT } from '@tutor/network-core'
import type {
    FolderID,
    FolderSearchGetVO,
    FolderSetVO,
    FolderWithAuthorityVO,
    FolderWithDocAndAuthority,
    OrgID,
    TeamID,
} from '../interface/type'
import { omitByValue } from '../util/omit-by-value'
import { BaseCommonRequest } from './base-request'
import { RequestResponseErrorHandler, requestDidFailedHandler } from './error-handler'
import { translation } from './folder.translation'

// 创建一个项目
@POST
export class CreateFolderRequest extends BaseCommonRequest<FolderWithAuthorityVO> {
    constructor(private readonly initialFolder: FolderSetVO) {
        super()
    }

    public override requestUrl() {
        return 'folders'
    }

    public override requestBody() {
        return this.initialFolder
    }

    public override requestDidFailed(err: any): boolean {
        return requestDidFailedHandler(err, this.responseStatusCode)
    }
}

// 更新一个项目的信息
@PUT
export class UpdateFolderRequest extends BaseCommonRequest {
    constructor(private readonly folderId: FolderID, private readonly folder: FolderSetVO) {
        super()
    }

    public override requestUrl() {
        return `folders/${this.folderId}`
    }

    public override requestBody() {
        return this.folder
    }

    public override requestDidFailed(err: any): boolean {
        return requestDidFailedHandler(err, this.responseStatusCode)
    }
}

// 移动文件夹到另一个团队
@PUT
export class MoveFolderRequest extends BaseCommonRequest<boolean> {
    constructor(
        private readonly folderId: FolderID,
        private readonly toTeamId: TeamID,
        private readonly updateFavorite?: boolean
    ) {
        super()
    }

    public override requestUrl() {
        return `folders/${this.folderId}/teamId`
    }

    public override requestArgument() {
        return {
            folderId: this.folderId,
            toTeamId: this.toTeamId,
            updateFavorite: this.updateFavorite ? 'true' : 'false',
        }
    }
    public override requestDidFailed(e: any): boolean {
        const { status, businessStatus } = RequestResponseErrorHandler(e)
        if (status == 410 || businessStatus == 44009) {
            window.dispatchEvent(
                new CustomEvent('request-error', {
                    detail: { statusCode: 410, message: translation("Can'tFindObject") },
                })
            )
        } else {
            window.dispatchEvent(
                new CustomEvent('request-error', {
                    detail: { statusCode: status },
                })
            )
        }
        return false
    }
}

// 获取一个项目的信息
@GET
export class GetFolderRequest extends BaseCommonRequest<FolderWithAuthorityVO> {
    constructor(private readonly folderId: FolderID) {
        super()
    }

    public override requestDidFailed(): boolean {
        return false
    }

    public override requestUrl() {
        return `folders/v2/${this.folderId}`
    }
}

// 删除一个项目
@DELETE
export class DeleteFolderRequest extends BaseCommonRequest {
    constructor(private readonly folderId: FolderID) {
        super()
    }

    public override requestUrl() {
        return `folders/${this.folderId}`
    }
}

// 删除一个项目
@DELETE
export class DeleteShareFolderRequest extends BaseCommonRequest {
    constructor(private readonly folderId: FolderID) {
        super()
    }

    public override requestUrl() {
        return `folders/share/${this.folderId}`
    }
}

// 获取一个团队下的所有项目
@GET
export class GetFolderListByTeamId extends BaseCommonRequest<ReadonlyArray<FolderWithAuthorityVO>> {
    constructor(private readonly teamId: TeamID) {
        super()
    }

    public override requestUrl() {
        return 'folders'
    }

    public override requestArgument() {
        return {
            teamId: this.teamId,
        }
    }
}

// 查询共享项目列表
// 获取一个团队下的所有项目
@GET
export class GetShareFolderListV2 extends BaseCommonRequest<FolderWithDocAndAuthority[]> {
    constructor(private readonly orgId?: OrgID, private readonly additionalRequestHeader?: Record<string, string>) {
        super()
    }
    public override requestUrl() {
        return 'folders/share/v2'
    }
    public override additionalRequestHeaders(): { [key: string]: string } | undefined {
        if (this.additionalRequestHeader) {
            return this.additionalRequestHeader
        }
        return undefined
    }
    public override requestArgument() {
        return omitByValue({
            orgId: this.orgId,
        })
    }
}

// 根据条件搜索项目
@GET
export class FolderSearchRequest extends BaseCommonRequest<FolderSearchGetVO> {
    constructor(private queryString: string, private orgId: OrgID, private pageSize?: number) {
        super()
    }

    public override requestUrl() {
        return 'folders/search'
    }

    public override requestArgument() {
        return omitByValue({ queryString: this.queryString, orgId: this.orgId, pageSize: this.pageSize })
    }
}
