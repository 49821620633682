/* eslint-disable no-restricted-imports */
import { PickImages as pickImagesForJS } from '@wukong/bridge-proto'
import { filePicker } from '../../../../util/src'
import { CommandInvoker } from '../../document/command/command-invoker'
import { cmdImagesSelected } from '../../document/command/image-command'
import { ImageDownloadContext } from '../../document/command/image-download-context'
import { environment } from '../../environment'
import { Bridge } from '../../kernel/bridge/bridge'

function pickImages(commandInvoker: CommandInvoker, imageDownloadContext: ImageDownloadContext) {
    filePicker({
        multiple: true,
        accept:
            'image/*' +
            // WebP
            ',.webp' +
            // heif
            ',.heif,.heifs,.heic,.heics,.avci,.avcs,.avif,.avifs',
    }).then((files) => {
        if (!files?.length) {
            return
        }

        commandInvoker.invoke(cmdImagesSelected, imageDownloadContext, files)
    })
}

export class ImageService {
    constructor(
        private bridge: Bridge,
        private commandInvoker: CommandInvoker,
        private readonly imageDownloadContext: ImageDownloadContext
    ) {
        bridge.bind(pickImagesForJS, () => {
            pickImages(commandInvoker, imageDownloadContext)
        })
    }

    public async downloadImage(imageHash: string): Promise<Blob> {
        const url = `${environment.ossCdnPath}private/resource/image/${imageHash}`
        const res = await fetch(url)
        if (!res.ok) {
            throw new Error(`download ${url} returned ${res.status}`)
        }
        const resBody = await res.blob()
        return resBody
    }

    public destroy() {
        this.bridge.unbind(pickImagesForJS)
    }
}
