/* eslint-disable no-restricted-imports */
import { DispatchMouseEventCommand, SetDevicePixelRatioCommand, Wukong } from '@wukong/bridge-proto'
import { Bridge } from '../../kernel/bridge/bridge'
import { RootComponentId } from '../canvas/canvas'
import { focusViewTraceLog } from '../service/focus-view/focus-view-trace-log'

const CanvasMouseEventTypeMap: {
    [key: string]: Wukong.DocumentProto.MouseEventType
} = {
    mousedown: Wukong.DocumentProto.MouseEventType.MOUSE_EVENT_TYPE_DOWN,
    mouseup: Wukong.DocumentProto.MouseEventType.MOUSE_EVENT_TYPE_UP,
    mousemove: Wukong.DocumentProto.MouseEventType.MOUSE_EVENT_TYPE_MOVE,
    dragenter: Wukong.DocumentProto.MouseEventType.MOUSE_EVENT_TYPE_MOVE,
    dragover: Wukong.DocumentProto.MouseEventType.MOUSE_EVENT_TYPE_MOVE,
    contextmenu: Wukong.DocumentProto.MouseEventType.MOUSE_EVENT_TYPE_CONTEXT_MENU,
    // 不处理 click 事件，由 wasm 根据 down & up 处理
    // dblclick: Wukong.DocumentProto.MouseEventType.MOUSE_EVENT_TYPE_DOUBLE_CLICK,
    // click: Wukong.DocumentProto.MouseEventType.MOUSE_EVENT_TYPE_CLICK,
}

function shouldAcceptAllMouseEvent(element: HTMLElement) {
    return !!element.getAttribute('data-should-accept-mouse-event-for-canvas')
}

function shouldOnlyAcceptMoveEvent(eventType: string, element: HTMLElement) {
    return (
        !!element.getAttribute('data-should-only-aceept-move-event-for-canvas') &&
        ['mousemove', 'wheel'].includes(eventType)
    )
}

function isEventAcceptable(e: MouseEvent) {
    let element = e.target
    while (element && element instanceof HTMLElement) {
        if (shouldAcceptAllMouseEvent(element) || shouldOnlyAcceptMoveEvent(e.type, element)) {
            return true
        }
        element = element.parentElement
    }
    return false
}

function getMousePosition(e: MouseEvent) {
    const canvasRange = document.getElementById(RootComponentId.CanvasOverlay)?.getBoundingClientRect()
    if (!canvasRange) {
        return { x: 0, y: 0 }
    }
    return {
        x: e.clientX - canvasRange.x,
        y: e.clientY - canvasRange.y,
    }
}

export function handleMouseEventForDevMode(bridge: Bridge, e: MouseEvent) {
    if (e.type === 'wheel' && (e.ctrlKey || e.metaKey)) {
        // 之前有关的代码：
        // 1. 搜 add some listeners to document / window，在一个生命周期钩子中监听 wheel 事件
        // 2. 在 canvas-event-handler 中监听 wheel 事件
        // TODO(chenyn): 新增 wheel 事件处理，包给 wasm，合并到一起
        e.preventDefault()
    }
    const type = CanvasMouseEventTypeMap[e.type]
    if (type == null) {
        return
    }
    const position = getMousePosition(e)
    const eventArgs: Wukong.DocumentProto.IArg_dispatchMouseEvent = {
        clientX: position.x,
        clientY: position.y,
        movementX: e.movementX,
        movementY: e.movementY,
        altKey: e.altKey,
        ctrlKey: e.ctrlKey,
        metaKey: e.metaKey,
        shiftKey: e.shiftKey,
        button: e.button,
        buttons: e.buttons,
        acceptable: isEventAcceptable(e),
    }
    const { accepted } = bridge.call(DispatchMouseEventCommand, {
        type,
        ...eventArgs,
    })
    // 产生鼠标事件时，更新 dpr
    bridge.call(SetDevicePixelRatioCommand, {
        value: window.devicePixelRatio,
    })

    if (!accepted) {
        if (type === Wukong.DocumentProto.MouseEventType.MOUSE_EVENT_TYPE_DOWN) {
            focusViewTraceLog('[handleMouseEventForDevMode] mousedown not accepted')
        }
        return
    }
    if (type === Wukong.DocumentProto.MouseEventType.MOUSE_EVENT_TYPE_DOWN) {
        focusViewTraceLog('[handleMouseEventForDevMode] mousedown accepted')
    }
    e.preventDefault()
}
