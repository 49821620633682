import { translation } from './object.translation'
/* eslint-disable no-restricted-imports */
import { LayerPanelCollapseWasmCall, Wukong } from '@wukong/bridge-proto'
import { WKItemType, WKMenuConfig } from '../../../../../../../ui-lib/src'
import { CommandInvoker } from '../../../../../document/command/command-invoker'
import { useViewState } from '../../../../../view-state-bridge'
import { useDocThumbnailMainMenuItem } from '../../../../hooks/use-doc-thumbnail'
import { useEditorActionStatusSubMenuItem } from '../hook/editor-action-status-menu'
import { generateSubMenuConfigUnderSelectionView } from './util'
import ActionEnabledType = Wukong.DocumentProto.ActionEnabledType
import { frogEventToolsMenusAndActionsBasicAction } from '../../../../utils/tools-actions-frog'
import { ShortcutKey, shortcutLabelMap } from '../../../../../kernel/interface/shortcut-key'

export const useMenuObject = (command: CommandInvoker): WKMenuConfig => {
    const thumbMenu = useDocThumbnailMainMenuItem()
    return [
        {
            name: translation('Object'),
            position: 'right top',
            testId: 'main-menu-object',
            children: [
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_GROUP_SELECTION,
                    hiddenWhenDisabled: false,
                }),
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_FRAME_SELECTION,
                    hiddenWhenDisabled: false,
                }),
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_UNGROUP_SELECTION,
                    hiddenWhenDisabled: false,
                }),
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_USE_AS_MASK,
                    hiddenWhenDisabled: !!useViewState('actionEnabledCancelMaskStatus'),
                    handlerCallback: () => {
                        frogEventToolsMenusAndActionsBasicAction({
                            action_name: '创建蒙版',
                            operation_way: '主菜单',
                        })
                    },
                }),
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_CANCEL_MASK,
                    hiddenWhenDisabled: true,
                    hidden: false,
                    handlerCallback: () => {
                        frogEventToolsMenusAndActionsBasicAction({
                            action_name: '取消蒙版',
                            operation_way: '主菜单',
                        })
                    },
                }),
                thumbMenu,
                { type: WKItemType.Divider },
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_ADD_AUTO_LAYOUT,
                    hiddenWhenDisabled: true,
                }),
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_REMOVE_AUTO_LAYOUT,
                    hiddenWhenDisabled: true,
                }),
                { type: WKItemType.Divider },
                {
                    name: translation('MoreLayoutOption'),
                    position: 'right top',
                    hidden: !useViewState('actionEnabledMoreLayoutOptionStatus'),
                    children: [
                        useEditorActionStatusSubMenuItem({
                            command,
                            editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_AI_CREATE_AUTO_LAYOUT,
                            hiddenWhenDisabled: true,
                        }),
                        useEditorActionStatusSubMenuItem({
                            command,
                            editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_REMOVE_ALL_AUTO_LAYOUT,
                            hiddenWhenDisabled: true,
                        }),
                    ],
                },
                { type: WKItemType.Divider },
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_BRING_TO_FRONT,
                    hiddenWhenDisabled: false,
                }),
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_BRING_FORWARD,
                    hiddenWhenDisabled: false,
                }),
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_SEND_BACKWARD,
                    hiddenWhenDisabled: false,
                }),
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_SEND_TO_BACK,
                    hiddenWhenDisabled: false,
                }),
                { type: WKItemType.Divider },
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_FLIP_HORIZONTAL,
                    hiddenWhenDisabled: false,
                }),
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_FLIP_VERTICAL,
                    hiddenWhenDisabled: false,
                }),
                { type: WKItemType.Divider },
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_FLATTEN,
                    handlerCallback: () => {
                        frogEventToolsMenusAndActionsBasicAction({
                            action_name: '使用拼合',
                            operation_way: '主菜单',
                        })
                    },
                    hiddenWhenDisabled: false,
                }),
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_OUTLINE_STROKE,
                    hiddenWhenDisabled: false,
                }),
                { type: WKItemType.Divider },
                {
                    name: translation('BooleanGroups'),
                    position: 'right top',
                    children: [
                        useEditorActionStatusSubMenuItem({
                            command,
                            editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_UNION,
                            handlerCallback: () => {
                                frogEventToolsMenusAndActionsBasicAction({
                                    action_name: '使用并集',
                                    operation_way: '主菜单',
                                })
                            },
                            hiddenWhenDisabled: false,
                        }),
                        useEditorActionStatusSubMenuItem({
                            command,
                            editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_SUBTRACT,
                            handlerCallback: () => {
                                frogEventToolsMenusAndActionsBasicAction({
                                    action_name: '使用减去顶层',
                                    operation_way: '主菜单',
                                })
                            },
                            hiddenWhenDisabled: false,
                        }),
                        useEditorActionStatusSubMenuItem({
                            command,
                            editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_INTERSECT,
                            handlerCallback: () => {
                                frogEventToolsMenusAndActionsBasicAction({
                                    action_name: '使用交集',
                                    operation_way: '主菜单',
                                })
                            },
                            hiddenWhenDisabled: false,
                        }),
                        useEditorActionStatusSubMenuItem({
                            command,
                            editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_EXCLUDE,
                            handlerCallback: () => {
                                frogEventToolsMenusAndActionsBasicAction({
                                    action_name: '使用排除重叠',
                                    operation_way: '主菜单',
                                })
                            },
                            hiddenWhenDisabled: false,
                        }),
                    ],
                },
                { type: WKItemType.Divider },
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_SHOWN,
                    hiddenWhenDisabled: false,
                }),
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_LOCKED,
                    hiddenWhenDisabled: false,
                }),
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_HIDE_OTHER_LAYERS,
                    hiddenWhenDisabled: false,
                    testId: 'hide-other-layers',
                }),
                generateSubMenuConfigUnderSelectionView(
                    false,
                    command,
                    translation('CollapseLayers'),
                    shortcutLabelMap[ShortcutKey.FoldSelection],
                    true,
                    LayerPanelCollapseWasmCall
                ),
                { type: WKItemType.Divider },
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_REMOVE_FILL,
                    hiddenWhenDisabled: false,
                }),
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_REMOVE_STROKE,
                    hiddenWhenDisabled: false,
                }),
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_SWAP_FILL_AND_STROKE,
                    hiddenWhenDisabled: false,
                }),
            ],
        },
    ]
}
