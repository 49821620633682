/* eslint-disable no-restricted-imports */
import { EditorState } from 'draft-js'
import { CommentPosition, CommentReply, CommentThread, CommentUser } from '../../../kernel/interface/comment'
import { Comment, Message, MessageType, Position } from './type'

export function getMentionUserIdsByMessages(messages: ReadonlyArray<Message>) {
    const mentionedUserIds = []
    for (const message of messages) {
        if (message.t === MessageType.Mention) {
            mentionedUserIds.push(message.c)
        }
    }
    return mentionedUserIds
}

export function getMentionUsersByMessages(
    messages: ReadonlyArray<Message>,
    usersMap: Map<CommentUser['id'], CommentUser>
) {
    const mentionedUsers: CommentUser[] = []
    for (const message of messages) {
        if (message.t !== MessageType.Mention) {
            continue
        }
        const commentUser = usersMap.get(message.c)
        if (commentUser) {
            mentionedUsers.push(commentUser)
        }
    }
    return mentionedUsers
}
export function getMentionUsersChangeState(oldReply: CommentReply, newReply: CommentReply) {
    const oldMentionUserIdSet = new Set(oldReply.mentionedUsers.map((user) => user.id))
    const newMentionUserIdSet = new Set(newReply.mentionedUsers.map((user) => user.id))
    const addedUserIds: CommentUser['id'][] = []
    const removedUserIds: CommentUser['id'][] = []
    for (const user of newReply.mentionedUsers) {
        if (!oldMentionUserIdSet.has(user.id)) {
            addedUserIds.push(user.id)
        }
    }
    for (const user of oldReply.mentionedUsers) {
        if (!newMentionUserIdSet.has(user.id)) {
            removedUserIds.push(user.id)
        }
    }
    return { addedUserIds, removedUserIds }
}

export function transformLocalMessageToOrigin(messages: ReadonlyArray<Message>) {
    return JSON.stringify(messages)
}

export function transformOriginMessageToLocal(messages: string): Message[] {
    try {
        const res = JSON.parse(messages)
        return Array.isArray(res) ? res : []
    } catch (error) {
        return []
    }
}

export function createOwner(properties?: Partial<CommentUser>): CommentUser {
    return Object.assign(
        {
            id: -1,
            desensitizedPhone: '',
            email: '',
            nickname: '',
            avatarId: '',
            avatarBackgroundColor: '',
        },
        properties
    )
}

export function createCommentPosition(properties?: Partial<CommentPosition>): CommentPosition {
    return Object.assign(
        {
            commentId: 0,
            nodeId: '',
            hasAnchor: false,
            x: 0,
            y: 0,
            offsetX: 0,
            offsetY: 0,
            anchorX: 0,
            anchorY: 0,
        },
        properties
    )
}

export function createReply(properties?: Partial<CommentReply>): CommentReply {
    const currentTime = new Date().getTime()
    return Object.assign(
        {
            id: currentTime,
            parentId: -1,
            unread: true,
            docId: '',
            uuid: '',
            owner: createOwner(),
            createdTime: currentTime,
            updatedTime: currentTime,
            lastEditTime: currentTime,
            deleted: false,
            deletedTime: 0,
            message: '',
            messageText: '',
            mentionedUsers: [],
            reactions: [],
        },
        properties
    )
}

export const generateTempCommentIdForCreating = () => {
    return `${new Date().getTime()}`
}

export function createCommentThread(properties?: Partial<CommentThread>): CommentThread {
    return Object.assign(
        {
            id: 0,
            docId: '',
            page: '',
            seq: 0,
            uuid: '',
            owner: createOwner(),
            replies: [],
            position: createCommentPosition({ commentId: properties?.id }),
            unread: false,
            resolved: false,
            createdTime: 0,
            updatedTime: 0,
            lastEditTime: 0,
            message: '',
            messageText: '',
            mentionedUsers: [],
            reactions: [],
        },
        properties
    )
}

export function isPreventExist(editorState: EditorState | null) {
    if (!editorState) {
        return false
    }
    const maxStrNum = 5
    const text = editorState.getCurrentContent().getPlainText()
    return text.length > maxStrNum
}

export function isEmptyMessages(messages?: Message[]): boolean {
    if (!messages) {
        return true
    }
    for (const message of messages) {
        if (message.t !== MessageType.Text) {
            return false
        }
        if (message.c.length) {
            return false
        }
    }
    return true
}

/**
 * @param popupRect 弹出的容器：表情选择
 * @param dependOnRect 依赖的容器：文本编辑器
 * @param fixedOnRect 绝对定位的目标容器：评论详情和创建容器
 * @param containerRect 检测目标：canvas容器
 */
export function calculateEditorPopupPosition(
    popupRect: DOMRect,
    dependOnRect: DOMRect,
    fixedOnRect: DOMRect,
    containerRect: DOMRect
) {
    const style: React.CSSProperties = {
        position: 'absolute',
        width: dependOnRect.width,
        left: dependOnRect.left - fixedOnRect.left,
    }
    const edge = 8
    const canPlaceOnBottom = dependOnRect.bottom + popupRect.height + edge <= containerRect.bottom
    if (canPlaceOnBottom) {
        style.top = dependOnRect.bottom - fixedOnRect.top
        return style
    }
    const canPlaceOnTop = dependOnRect.top - popupRect.height - edge >= containerRect.top
    if (canPlaceOnTop) {
        style.top = dependOnRect.top - popupRect.height - fixedOnRect.top
        return style
    }
    style.top = containerRect.bottom - edge - popupRect.height - fixedOnRect.top
    return style
}

export function calculateRange(anchorPosition: Position, minorAnchorPosition: Position) {
    const width = Math.abs(minorAnchorPosition.left - anchorPosition.left)
    const height = Math.abs(minorAnchorPosition.top - anchorPosition.top)
    const left = Math.min(minorAnchorPosition.left, anchorPosition.left)
    const top = Math.min(minorAnchorPosition.top, anchorPosition.top)
    return { width, height, left, top }
}

export function getShowUserInfo(owner: CommentUser, replies: CommentReply[]) {
    const otherOwnerMap: Map<number, CommentUser> = new Map()
    const _replies = replies.sort((a, b) => a.createdTime - b.createdTime)
    for (const reply of _replies) {
        if (owner.id !== reply.owner.id && !otherOwnerMap.get(reply.owner.id)) {
            otherOwnerMap.set(reply.owner.id, reply.owner)
        }
    }
    const list = [...otherOwnerMap.values()].reverse()
    return {
        userList: list.length > 2 ? [list[list.length - 1], owner] : [...list, owner],
        overflowNum: list.length > 2 ? list.length - 1 : 0,
    }
}

export function getCommentHeadCount(commentMeta: CommentThread) {
    const { userList, overflowNum } = getShowUserInfo(commentMeta.owner, commentMeta.replies)
    if (overflowNum > 0) {
        return 3
    }
    return userList.length
}

export function getClusterShowUserInfo(comments: Comment[]) {
    const userMap = new Map<number, CommentUser>()
    const userCount = new Map<number, number>()
    const userEarliestTime = new Map<number, number>()

    function countCommentUser(user: CommentUser, createTime: number) {
        if (!userMap.has(user.id)) {
            userCount.set(user.id, 0)
            userMap.set(user.id, user)
            userEarliestTime.set(user.id, createTime)
        }
        userCount.set(user.id, userCount.get(user.id)! + 1)
        if (createTime < userEarliestTime.get(user.id)!) {
            userEarliestTime.set(user.id, createTime)
        }
    }

    comments.forEach((comment) => {
        countCommentUser(comment.commentMetaData.owner, comment.commentMetaData.createdTime)
        comment.commentMetaData.replies.forEach((reply) => {
            countCommentUser(reply.owner, reply.createdTime)
        })
    })
    // 按照 出现次数（userCount）多的在前面
    // 出现次数相等时，创建时间早的在前面
    // 时间一样则 id 小的在前面
    let userList = Array.from(userMap.values()).sort((a, b) => {
        const countDiff = userCount.get(b.id)! - userCount.get(a.id)!
        if (countDiff !== 0) {
            return countDiff
        }
        const timeDiff = userEarliestTime.get(a.id)! - userEarliestTime.get(b.id)!
        if (timeDiff !== 0) {
            return timeDiff
        }
        return a.id - b.id
    })

    if (userList.length == 1) {
        userList = userList.concat(userList)
    }

    if (userList.length > 5) {
        return {
            userList: userList.slice(0, 4),
            overflowNum: userList.length - 4,
        }
    } else {
        return {
            userList: userList,
            overflowNum: 0,
        }
    }
}
