import { isEnglishLanguage } from '../../../../../../util/src'
import { useMemo } from 'react'
import classes from './ai-tool-header.module.less'
import { UserAiSceneCreditsVO } from '../../../../main/ai-service/typings'

export function AiToolHeader(props: { name: string; aiCredit: UserAiSceneCreditsVO }) {
    const suffix = useMemo(() => {
        if (isEnglishLanguage()) {
            if (props.aiCredit.userCredits > 1) {
                return 'times'
            } else {
                return 'time'
            }
        } else {
            return '次'
        }
    }, [props.aiCredit.userCredits])

    return (
        <div className={classes.Container}>
            <div className={classes.Title}>{props.name}</div>
            <div
                className={classes.Credits}
            >{`${props.aiCredit.userCredits}/${props.aiCredit.limitation} ${suffix}`}</div>
        </div>
    )
}
