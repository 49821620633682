/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { useCallback, useRef } from 'react'
import { FontName, ITextStyle } from '../../../../../document/node/node'
import { LibraryThumbnailImage } from '../../../../../share/component-style-library/library-thumbnail-image'
import { buildThumbnailDataFromLocalTextStyle } from '../../../../../share/component-style-library/library-thumbnail-image/use-library-thumbnail-image-v2'
import { useTextStylePreviewThumbnail, useTextStyleThumbnail } from '../../../../utils/thumbnail-cache/text-thumbnail'
import { useFontLoaded } from '../../../../utils/thumbnail-cache/use-font-loaded'
import { FontMissIcon } from '../../text/font/font-miss-icon/font-miss-icon'
import classes from './style-thumbnail.module.less'

export interface TextStyleThumbnailProps extends Omit<TextThumbnailProps, 'testId'>, useTextStyleMissFamilyProps {
    textThumbnailTestId?: string
    fontMissIconTestId?: string
}

interface LocalTextStyleLibraryThumbnailImageProps {
    className?: string
    id: string
    contentHash: string
    fontName: Wukong.DocumentProto.IFontName | FontName
    missFontInfo: Wukong.DocumentProto.IVMissFontInfo
}

export function LocalTextStyleLibraryThumbnailImage(props: LocalTextStyleLibraryThumbnailImageProps) {
    const { isFontLoaded } = useFontLoaded(props.fontName)
    const fontMissing = props.missFontInfo.isMissStyle

    if (fontMissing || isFontLoaded) {
        return (
            <LibraryThumbnailImage
                className={props.className}
                thumbnailData={buildThumbnailDataFromLocalTextStyle({
                    id: props.id,
                    contentHash: props.contentHash,
                })}
                fontMissing={fontMissing}
            />
        )
    }

    // 字体未加载时使用空白占位
    return <span className={props.className}></span>
}

export function TextStyleThumbnail(props: TextStyleThumbnailProps): JSX.Element {
    const isMissStyle = props.styleData?.missFontInfo?.isMissStyle ?? false

    return <TextStyleThumbnailDOM {...props} isMissStyle={isMissStyle} />
}

export function TextStyleThumbnailDOM(props: TextStyleThumbnailProps & { isMissStyle?: boolean }) {
    const ref = useRef<HTMLDivElement>(null)
    const triggerRect = useCallback(() => {
        const rect = ref.current!.getBoundingClientRect()
        return { top: rect.top - 8, bottom: rect.bottom + 8, left: rect.left, right: rect.right }
    }, [])
    return props.isMissStyle ? (
        <div className={props.className}>
            <FontMissIcon ref={ref} triggerRect={triggerRect} testId={props.fontMissIconTestId} />
        </div>
    ) : (
        <TextThumbnail {...props} testId={props.textThumbnailTestId} />
    )
}

interface TextThumbnailProps {
    className?: string
    testId?: string
    styleData?: ITextStyle
    width: number
    height: number
    mode?: 'preview' | 'thumbnail'
    multiple?: number
}
function TextThumbnail(props: TextThumbnailProps) {
    return props.mode === 'preview' ? <ModePreview {...props} /> : <ModeNotPreview {...props} />
}

function ModePreview(props: TextThumbnailProps) {
    const thumbnailSrc = useTextStylePreviewThumbnail(props)
    return <TextThumbnailDOM url={thumbnailSrc} className={props.className} dataTestId={props.testId} />
}
function ModeNotPreview(props: TextThumbnailProps) {
    const thumbnailSrc = useTextStyleThumbnail(props)
    return <TextThumbnailDOM url={thumbnailSrc} className={props.className} dataTestId={props.testId} />
}

function TextThumbnailDOM(props: { url?: string; className?: string; dataTestId?: string }) {
    return <img src={props.url} className={classnames(classes.img, props.className)} data-testid={props.dataTestId} />
}

export interface useTextStyleMissFamilyProps {
    textStyleId?: string
    styleData?: ITextStyle
    isMissState?: (missStyle: { isMissStyle: boolean; isMissFamily: boolean }) => void
}
