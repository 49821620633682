import { Checkbox, MonoIconCommonInfoLine16, WKDivider, WKPopover } from '../../../../../../../ui-lib/src'
import { MIXED_TYPE } from '../../prototype-interaction/constants'
import { translation } from './index.translation'
interface Props {
    checked: boolean | typeof MIXED_TYPE
    onChangeResetScrollPosition: (reset: boolean) => void
}
export const StateManagement = (props: Props) => {
    return (
        <>
            <div className="h-16px flex flex-row items-center">
                <WKDivider></WKDivider>
            </div>
            <div className="pl-10 pr-4">
                <div className="flex flex-row items-center justify-between py-2 color-$wk-v2-label-color-gray-8">
                    <div className="text-gray wk-font-medium">{translation('StateManagement')}</div>
                </div>
                <div className="flex flex-row items-center py-2 gap-4px color-$wk-v2-label-color-gray-8">
                    <Checkbox
                        size="small"
                        checked={props.checked === true}
                        indeterminate={props.checked === MIXED_TYPE}
                        label={translation('ResetScrollPosition')}
                        onChange={props.onChangeResetScrollPosition}
                        testid="reset-scroll-position-checkbox"
                    ></Checkbox>
                    <WKPopover
                        contents={
                            <>
                                {translation('Description')}
                                {/* TODO(jiangzg): 运营准备好文档后再添加跳转链接 */}
                                {/* <WKTextButton
                                    type="primary"
                                    size={12}
                                    onClick={() => window.open(environment.helpDocsUrl.stateManagement)}
                                >
                                    {translation('LearnMore')}
                                </WKTextButton> */}
                            </>
                        }
                        contentClassName="w-200px py-6px! px-12px! box-border"
                    >
                        <MonoIconCommonInfoLine16 />
                    </WKPopover>
                </div>
            </div>
        </>
    )
}
