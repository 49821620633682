import {
    FeatureSwitchConfig,
    SwitchEnvironmentScopeType,
    SwitchStrategyType,
    UserGroupType,
} from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'dev-prototype',
    owner: '',
    description: '',
    strategies: [
        {
            env: SwitchEnvironmentScopeType.TESTING,
            config: { type: SwitchStrategyType.SPECIFIC_USER_LIST, group: UserGroupType.RD },
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
