import { translation } from './history-service.translation'
/* eslint-disable no-restricted-imports */
import { WKToast } from '../../../../../../ui-lib/src'
import {
    createSelectors,
    createStore,
    domLocation,
    generateRouterPath,
    RouteToken,
    timeDesc3,
} from '../../../../../../util/src'
import { openIndependentWindow } from '../../../../kernel/util/open-window'
import { CreateHistoryVersionType, HistoryVersion } from '../../../../kernel/interface/history-version'
import { ExecutableClipboardService } from '../../../../main/clipboard/clipboard-service/clipboard-service-interface'
import { ViewStateBridge } from '../../../../view-state-bridge'
import { AutoItem, AutoItemGroup, CurrentItem, IconType, ItemType, UserItem } from '../history-list/type'
import {
    fetchApplyHistoryVersion,
    fetchCopyHistoryVersionAsNewFile,
    fetchCreateVersion,
    fetchEditHistoryVersionInfo,
    fetchGetHistoryVersion,
} from '../history-request'
import { SortFilter } from '../history-title/history-title'
import {
    createHrefByVersionId,
    getQueryParamVersionId,
    isAutoItem,
    isRelatedItemToUser,
    transformOriginHistoryItems2Local,
} from '../utils'

export type ToastFail =
    | { type: 'save'; title: string; params: Parameters<typeof fetchCreateVersion>[0] }
    | { type: 'add'; title: string; params: Parameters<typeof fetchCreateVersion>[0] }

export interface HistoryZustandStore {
    isHistoryMode: boolean
    items: (UserItem | AutoItemGroup)[]
    currentItem: CurrentItem
    currentHistoryVersionId: number
    isDifferentSelectSort: boolean
    selectSortFilter: SortFilter[]
    isFinish: boolean
    existsBeyondThresholdVersion: boolean
    isLoadMoreLoading: boolean
    toastFailList: Map<number, ToastFail>
}

export class HistoryService {
    protected getInitValueHistoryList = (): Map<HistoryVersion['id'], HistoryVersion> => new Map()
    protected getInitValueItems = (): (UserItem | AutoItemGroup)[] => []
    protected getInitValueCurrentItem = (): CurrentItem => ({
        type: ItemType.CurrentItem,
        iconType: IconType.Normal,
        name: translation('CurrentVersion'),
        versionId: -1,
        origin: {
            id: -1,
        },
    })
    protected getInitValueCurrentHistoryVersionId = () => getQueryParamVersionId()
    protected getInitValueIsFinish = () => false
    protected getInitValueExistsBeyondThresholdVersion = () => false
    protected getInitValueIsLoadMoreLoading = () => false
    protected getInitValueCurrentHistoryVersion = () => null
    protected getInitValueIsDifferentSelectSort = () => false
    protected getInitValueSelectSortFilter = (): SortFilter[] => [SortFilter.AllVersion, SortFilter.AutoVersion]

    protected historyList: Map<HistoryVersion['id'], HistoryVersion> = this.getInitValueHistoryList()
    protected currentHistoryVersion: HistoryVersion | null = this.getInitValueCurrentHistoryVersion()
    // inHistoryWasm 的意思是当前的 wasm 处于新的 wasm。
    // viewState('historyMode') 在{translation('SwitchTo')}历史 wasm 时会被置为 false 一次(即使当前处在历史模式)
    // inHistoryWasm 和 viewState('historyMode') 结合才能标识当前的 isHistoryMode 是否处于 历史模式
    protected isInHistoryWasm = false
    protected autoRefreshTimer: NodeJS.Timeout | undefined = undefined
    private zustandStore = createStore<HistoryZustandStore>(() => ({
        isHistoryMode: false,
        items: this.getInitValueItems(),
        currentItem: this.getInitValueCurrentItem(),
        currentHistoryVersionId: this.getInitValueCurrentHistoryVersionId(),
        isDifferentSelectSort: this.getInitValueIsDifferentSelectSort(),
        selectSortFilter: this.getInitValueSelectSortFilter(),
        isFinish: this.getInitValueIsFinish(),
        existsBeyondThresholdVersion: this.getInitValueExistsBeyondThresholdVersion(),
        isLoadMoreLoading: this.getInitValueIsLoadMoreLoading(),
        toastFailList: new Map<number, ToastFail>(),
    }))
    useZustandStore = createSelectors(this.zustandStore)

    private bindViewStates = () => {
        this.viewStateBridge.register('historyMode', this.updateViewHistoryMode)
    }

    private bindHtmlEvent = () => {}

    private unbindHtmlEvent = () => {}

    public destroy = () => {
        this.viewStateBridge.unregister('historyMode', this.updateViewHistoryMode)
        this.unbindHtmlEvent()
        this.endAutoRefresh()
    }

    constructor(
        protected readonly viewStateBridge: ViewStateBridge,
        protected readonly clipboardService: ExecutableClipboardService,
        protected readonly docId: string,
        protected readonly userId: number
    ) {
        this.bindHtmlEvent()
    }

    public init = () => {
        this.bindViewStates()
    }

    protected updateViewHistoryMode = (isHistoryMode: boolean) => {
        isHistoryMode = isHistoryMode || this.isInHistoryWasm
        const oldState = this.zustandStore.getState().isHistoryMode
        if (!oldState && isHistoryMode) {
            this.switchHistoryMode2True()
        } else if (oldState && !isHistoryMode) {
            this.switchHistoryMode2False()
        }
        this.zustandStore.setState({
            isHistoryMode,
        })
    }

    protected switchHistoryMode2False = () => {
        this.endAutoRefresh()
    }

    protected switchHistoryMode2True = () => {
        this.historyList = this.getInitValueHistoryList()
        this.currentHistoryVersion = this.getInitValueCurrentHistoryVersion()
        this.zustandStore.setState({
            items: this.getInitValueItems(),
            currentItem: this.getInitValueCurrentItem(),
            currentHistoryVersionId: this.getInitValueCurrentHistoryVersionId(),
            isDifferentSelectSort: this.getInitValueIsDifferentSelectSort(),
            selectSortFilter: this.getInitValueSelectSortFilter(),
            isFinish: this.getInitValueIsFinish(),
            existsBeyondThresholdVersion: this.getInitValueExistsBeyondThresholdVersion(),
            isLoadMoreLoading: this.getInitValueIsLoadMoreLoading(),
        })
        this.requestGetHistoryVersion()
        this.startAutoRefresh()
    }

    protected updateItems = () => {
        const selectSortFilter = this.zustandStore.getState().selectSortFilter
        const hasAutoVersion = selectSortFilter.includes(SortFilter.AutoVersion)
        const hasMyVersion = selectSortFilter.includes(SortFilter.MyVersion)
        const hasAllVersion = selectSortFilter.includes(SortFilter.AllVersion)

        const filterHistoryList = []
        for (const [_, historyVersion] of this.historyList) {
            if (!hasAutoVersion && isAutoItem(historyVersion)) {
                continue
            }
            if (hasAllVersion) {
                filterHistoryList.push(historyVersion)
                continue
            }
            if (hasMyVersion && isRelatedItemToUser(historyVersion, this.userId)) {
                filterHistoryList.push(historyVersion)
                continue
            }
        }
        const newItems = transformOriginHistoryItems2Local(filterHistoryList)
        this.zustandStore.setState({
            items: newItems,
        })
    }

    protected loadHistoryItems2HistoryList = (historyVersions: HistoryVersion[]) => {
        historyVersions.forEach((v) => this.historyList.set(v.id, v))
        this.updateCurrentHistoryVersion()
        this.updateItems()
    }

    protected createHistoryItem2HistoryList = (historyVersion: HistoryVersion) => {
        this.historyList = new Map([[historyVersion.id, historyVersion], ...this.historyList])
        this.updateItems()
    }

    protected editHistoryItem2HistoryList = (historyVersion: HistoryVersion) => {
        this.historyList.get(historyVersion.id) && this.historyList.set(historyVersion.id, historyVersion)
        this.updateCurrentHistoryVersion()
        this.updateItems()
    }

    protected resetHistoryList = () => {
        this.historyList = this.getInitValueHistoryList()
        this.updateItems()
    }

    protected refreshHistoryList = () => {
        this.updateItems()
    }

    protected startAutoRefresh = () => {
        this.endAutoRefresh()
        // 版本时间要实时显示最新的状态，所以这里 15s 刷新下列表以更新时间状态
        this.autoRefreshTimer = setTimeout(() => {
            this.refreshHistoryList()
            this.startAutoRefresh()
        }, 15000)
    }

    protected endAutoRefresh = () => {
        clearTimeout(this.autoRefreshTimer)
    }

    // 列表新增 和 historyVersionId 变更时需要更新下这个值
    protected updateCurrentHistoryVersion = () => {
        const currentHistoryVersionId = this.zustandStore.getState().currentHistoryVersionId
        this.currentHistoryVersion = this.historyList.get(currentHistoryVersionId) ?? null
    }

    protected isAllowCheckAutoPull = () => {
        // 当前应用的版本是{translation('CurrentVersion')}
        const currentHistoryVersionId = this.zustandStore.getState().currentHistoryVersionId
        const currentItemId = this.zustandStore.getState().currentItem.origin.id
        if (currentHistoryVersionId === currentItemId) {
            return true
        }
        // 还没有查到过currentHistoryVersionId 对应的信息
        if (this.currentHistoryVersion === null) {
            return true
        }
        // 当前应用的版本是否在过滤条件之内
        const filters = this.zustandStore.getState().selectSortFilter
        const isInFilter = isAutoItem(this.currentHistoryVersion)
            ? filters.includes(SortFilter.AutoVersion)
            : isRelatedItemToUser(this.currentHistoryVersion, this.userId)
            ? true
            : filters.includes(SortFilter.AllVersion)

        return isInFilter
    }

    protected shouldAutoPullNextPageHistoryVersion = () => {
        if (this.zustandStore.getState().isFinish) {
            return false
        }
        const currentHistoryVersionId = this.zustandStore.getState().currentHistoryVersionId
        const currentItemId = this.zustandStore.getState().currentItem.origin.id
        if (currentHistoryVersionId === currentItemId) {
            return false
        }
        return !this.historyList.has(currentHistoryVersionId)
    }

    protected getRequestStartId = () => {
        return [...this.historyList.keys()][this.historyList.size - 1] ?? undefined
    }
    protected requestGetHistoryVersion = () => {
        if (!this.docId) {
            return
        }
        const startId = this.getRequestStartId()
        const selectSortFilter = this.zustandStore.getState().selectSortFilter
        const onlyMine = selectSortFilter.includes(SortFilter.MyVersion)
        const includeAutoSave = selectSortFilter.includes(SortFilter.AutoVersion)
        this.zustandStore.setState({
            isLoadMoreLoading: true,
        })
        fetchGetHistoryVersion(this.docId, 30, startId, onlyMine, includeAutoSave)
            .then((v) => {
                this.zustandStore.setState({
                    isLoadMoreLoading: false,
                    isFinish: v.finish,
                    existsBeyondThresholdVersion: v.existsBeyondThresholdVersion,
                })
                // 列表id和查询条件都没变才去把新获取的版本信息记录保存下来
                if (
                    startId === this.getRequestStartId() &&
                    this.isSameSortFilter(selectSortFilter, this.zustandStore.getState().selectSortFilter)
                ) {
                    this.loadHistoryItems2HistoryList(v.list)
                }
            })
            .then(() => {
                if (this.isAllowCheckAutoPull() && this.shouldAutoPullNextPageHistoryVersion()) {
                    this.requestGetHistoryVersion()
                }
            })
            .catch(() => {})
    }

    public requestCreatePublishLibraryVersion = (desc: string) => {
        const params = {
            docId: this.docId,
            name: translation('ComponentsPublished'),
            desc,
            createType: CreateHistoryVersionType.PublishLibrary,
        }
        fetchCreateVersion(params)
    }

    public requestCreateUnPublishLibraryVersion = () => {
        const params = {
            docId: this.docId,
            name: translation('ComponentsUnpublished'),
            desc: '',
            createType: CreateHistoryVersionType.UnPublishLibrary,
        }
        fetchCreateVersion(params)
    }

    public requestCreateUpdateLibraryVersion = (desc: string) => {
        const params = {
            docId: this.docId,
            name: translation('ComponentsPublished_synonyms1'),
            desc,
            createType: CreateHistoryVersionType.UpdateLibrary,
        }
        fetchCreateVersion(params)
    }

    protected requestCreateVersion = (params: Parameters<typeof fetchCreateVersion>[0]) => {
        return fetchCreateVersion(params).then((v) => {
            this.createHistoryItem2HistoryList(v)
        })
    }

    public requestAddVersionForPluginApi = (name: string, desc: string) => {
        const params = { docId: this.docId, name, desc, createType: CreateHistoryVersionType.Manual }
        return fetchCreateVersion(params)
    }

    public requestAddVersion = (name: string, desc: string, docId = this.docId) => {
        const params = { docId, name, desc, createType: CreateHistoryVersionType.Manual }
        this.requestCreateVersion(params)
            .then(() => {
                WKToast.show(translation('AddToVersion'))
            })
            .catch(() => {
                this.addToastFailList({ type: 'add', title: translation('AddToVersion_synonyms1'), params })
            })
    }

    public requestSaveVersion = (name: string, desc: string, docId = this.docId) => {
        const params = { docId, name, desc, createType: CreateHistoryVersionType.Manual }
        this.requestCreateVersion(params)
            .then(() => {
                WKToast.show(translation('SaveToVersion'))
            })
            .catch(() => {
                this.addToastFailList({ type: 'save', title: translation('SaveToVersion_synonyms1'), params })
            })
    }

    public requestEditHistoryVersionInfo = (historyVersionId: number, name: string, description: string) => {
        fetchEditHistoryVersionInfo(this.docId, historyVersionId, {
            name,
            desc: description,
        })
            .then((v) => {
                this.editHistoryItem2HistoryList(v)
            })
            .catch(() => {
                WKToast.error(translation('EditVersionInfo'))
            })
    }

    protected addToastFailList = (info: ToastFail) => {
        const toastFailList = this.zustandStore.getState().toastFailList
        const nextToastFailList = new Map<number, ToastFail>([...toastFailList, [Date.now(), info]])
        this.zustandStore.setState({ toastFailList: nextToastFailList })
    }

    public removeToastFailList = (id: number) => {
        const toastFailList = this.zustandStore.getState().toastFailList
        if (toastFailList.delete(id)) {
            const nextToastFailList = new Map<number, ToastFail>(toastFailList)
            this.zustandStore.setState({ toastFailList: nextToastFailList })
        }
    }

    public retryToastFailList = (id: number) => {
        const toastFail = this.zustandStore.getState().toastFailList.get(id)
        this.removeToastFailList(id)
        if (toastFail?.type === 'save') {
            const { name, desc, docId } = toastFail.params
            this.requestSaveVersion(name, desc, docId)
        } else if (toastFail?.type === 'add') {
            const { name, desc, docId } = toastFail.params
            this.requestAddVersion(name, desc, docId)
        }
    }

    public clearVersionInfo = (value: UserItem | AutoItem) => {
        fetchEditHistoryVersionInfo(value.origin.docId, value.origin.id, {
            name: '',
            desc: '',
        })
            .then((v) => {
                this.editHistoryItem2HistoryList(v)
            })
            .catch(() => {
                WKToast.error(translation('DeleteVersionInfo'))
            })
    }

    public applyVersion = (value: UserItem | AutoItem) => {
        fetchApplyHistoryVersion(value.origin.docId, value.origin.id)
            .then(() => {
                domLocation().reload()
            })
            .catch(() => {
                WKToast.error(translation('RestoreThisVersion'))
            })
    }

    public copyVersion = (value: UserItem | AutoItem) => {
        fetchCopyHistoryVersionAsNewFile(value.origin.docId, value.origin.id)
            .then((doc) => {
                openIndependentWindow(`${domLocation().origin}/${generateRouterPath(RouteToken.File)}/${doc.id}`)
            })
            .catch(() => {})
    }

    public copyVersionLink = (value: UserItem | AutoItem) => {
        const nextHref = createHrefByVersionId(value.origin.id)
        this.clipboardService.copyText(nextHref)
        WKToast.show(translation('LinkCopiedTo'))
    }

    public loadMore = () => {
        this.requestGetHistoryVersion()
    }

    public updateCurrentHistoryVersionId = (historyVersionId = getQueryParamVersionId()) => {
        this.zustandStore.setState({ currentHistoryVersionId: historyVersionId })
        this.updateCurrentHistoryVersion()
    }

    public switchHistoryVersionSuccess = (historyVersionId: number) => {
        const versionInfo = this.historyList.get(historyVersionId)
        if (versionInfo?.name) {
            const nameText = versionInfo.name.length > 20 ? versionInfo.name.slice(0, 19) + '...' : versionInfo.name
            WKToast.show(`${translation('Viewing')} ${nameText}`)
        } else if (versionInfo?.dbctime) {
            const timeText = timeDesc3(versionInfo?.dbctime)
            WKToast.show(`${translation('Viewing')} ${timeText}`)
        }
    }

    public switchHistoryVersionFailure = (historyVersionId: number, prevHistoryVersionId: number) => {
        const versionInfo = this.historyList.get(historyVersionId)
        if (versionInfo?.name) {
            const nameText = versionInfo.name.length > 20 ? versionInfo.name.slice(0, 19) + '...' : versionInfo.name
            WKToast.error(translation('SwitchFailed', { name: nameText }))
        } else if (versionInfo?.dbctime) {
            const timeText = timeDesc3(versionInfo?.dbctime)
            WKToast.error(translation('SwitchFailed', { name: timeText }))
        }
        // 将查询参数重置为 prevHistoryVersionId
        const params = createHrefByVersionId(prevHistoryVersionId)
        history.pushState(null, '', params)
    }

    public updateIsInHistoryWasm = (isInHistoryWasm: boolean) => {
        this.isInHistoryWasm = isInHistoryWasm
    }

    protected isSameSortFilter = (a: SortFilter[], b: SortFilter[]) => {
        const aSet = new Set(a)
        const bSet = new Set(b)
        return aSet.size === bSet.size && new Set([...aSet].filter((x) => !bSet.has(x))).size === 0
    }

    public updateSelectSortFilter = (v: SortFilter[]) => {
        const isSameSortFilter = this.isSameSortFilter(v, this.getInitValueSelectSortFilter())
        this.zustandStore.setState({
            isDifferentSelectSort: !isSameSortFilter,
            selectSortFilter: v,
        })
        this.resetHistoryList()
        this.requestGetHistoryVersion()
    }
}
