/* eslint-disable no-restricted-imports */
import { ColorMode } from '../../../../../document/node/node'

import classnames from 'classnames'
import { IconEyeDropper, Select } from '../../../../../../../ui-lib/src'
import { IconButton } from '../../../atom/button/icon-button'
import { FormattedInput } from '../../../atom/inputs/components/formatted-input'
import { HexInput } from '../../../atom/inputs/hex-input'
import { ScrubbableInputInteger } from '../../../atom/inputs/scrubbable-input-integer'
import { ScrubbableInputPercent } from '../../../atom/inputs/scrubbable-input-percent'
import { tranformColorMode } from '../../../inspect/utils'
import { ColorArea } from './color-area'
import style from './color-picker.module.less'
import { ChangeColorType } from './color-type'
import { HueRange } from './hue-range'
import { OpacityRange } from './opacity-range'
import type { ColorPickerProps } from './use-color-picker'
import { parseCSS, useColorPicker } from './use-color-picker'

const selectColorModeItems = [ColorMode.HEX, ColorMode.RGB, ColorMode.CSS, ColorMode.HSL, ColorMode.HSB]
export function ColorPicker(props: ColorPickerProps) {
    const {
        onChangeColorMode,
        hue,
        horizontal,
        vertical,
        drawMode,
        colorMode,
        selectEyedropper,
        onChangeEyeDropper,
        colorRef,
        colorSpace,
        opacity,
        onChangeOpacityRange,
        onChangeOpacityInput,
        inputR,
        inputG,
        inputB,
        inputHex,
        inputCSS,
        onChangeColorInput,
        onDragState,
        onWheel,
    } = useColorPicker(props)

    return (
        <div className={style.container}>
            <ColorArea
                width={240}
                height={240}
                hue={hue}
                horizontal={horizontal}
                vertical={vertical}
                drawMode={drawMode}
                colorSpace={colorSpace}
                onChangeHV={onChangeColorInput(ChangeColorType.RangeHV)}
                onDragState={onDragState}
                onWheel={onWheel}
            />
            <div className={classnames(style.AreaPicker, style.AreaPicker2)}>
                <IconButton
                    className={style.AreaPicker_left}
                    selected={!!selectEyedropper}
                    onChange={onChangeEyeDropper}
                    deepColor={true}
                    icon={<IconEyeDropper />}
                    dataTestId="color-picker-eyedropper"
                />
                <div className={style.AreaPicker_right}>
                    <HueRange
                        width={168}
                        height={12}
                        hue={hue}
                        colorSpace={colorSpace}
                        onChange={(v, options) => onChangeColorInput(ChangeColorType.RangeHue)(v, undefined, options)}
                        className={style.range}
                    />
                    <OpacityRange
                        width={168}
                        height={12}
                        color={colorRef.current}
                        opacity={opacity}
                        colorSpace={colorSpace}
                        onChange={onChangeOpacityRange}
                        className={style.range}
                    />
                </div>
            </div>
            {
                <div className={style.colorContainer2}>
                    <Select.MinimalSingleLevel
                        value={colorMode}
                        onChange={onChangeColorMode}
                        label={tranformColorMode(colorMode)}
                        dataTestIds={{
                            container: 'color-picker-container',
                            triggerFocus: 'color-picker-trigger-focus',
                        }}
                    >
                        {selectColorModeItems.map((mode) => (
                            <Select.MinimalSingleLevel.Option
                                key={mode}
                                value={mode}
                                backwardIcon={''}
                                data-testid="color-picker-color-mode-item"
                            >
                                {tranformColorMode(mode)}
                            </Select.MinimalSingleLevel.Option>
                        ))}
                    </Select.MinimalSingleLevel>
                    <div className={style.inputGroup2} data-testid="color-picker-input-group">
                        {colorMode === ColorMode.HEX ? (
                            <HexInput
                                className={style.inputHex}
                                onChange={(v, options) =>
                                    onChangeColorInput(ChangeColorType.Hex)(v, undefined, options)
                                }
                                value={inputHex}
                                autoFocus
                            />
                        ) : colorMode === ColorMode.RGB ? (
                            <>
                                <ScrubbableInputInteger
                                    className={style.inputNumber}
                                    min={0}
                                    max={255}
                                    onChange={(v, options) =>
                                        onChangeColorInput(ChangeColorType.R)(v as number, undefined, options)
                                    }
                                    value={inputR}
                                    autoFocus
                                    noRightPadding
                                    notUseUserConfig
                                    testId="color-picker-r-input"
                                />
                                <ScrubbableInputInteger
                                    className={style.inputNumber}
                                    min={0}
                                    max={255}
                                    onChange={(v, options) =>
                                        onChangeColorInput(ChangeColorType.G)(v as number, undefined, options)
                                    }
                                    value={inputG}
                                    noRightPadding
                                    notUseUserConfig
                                />
                                <ScrubbableInputInteger
                                    className={style.inputNumber}
                                    min={0}
                                    max={255}
                                    onChange={(v, options) =>
                                        onChangeColorInput(ChangeColorType.B)(v as number, undefined, options)
                                    }
                                    value={inputB}
                                    noRightPadding
                                    notUseUserConfig
                                />
                            </>
                        ) : colorMode === ColorMode.CSS ? (
                            <FormattedInput
                                className={style.inputCss}
                                onChange={(v, options) =>
                                    onChangeColorInput(ChangeColorType.CSS)(v as string, undefined, options)
                                }
                                value={inputCSS}
                                formatter={{
                                    format: (v) => String(v as string).toLowerCase(),
                                    parse: parseCSS,
                                    increment: (v) => v,
                                }}
                                autoFocus
                            />
                        ) : colorMode === ColorMode.HSL ? (
                            <>
                                <ScrubbableInputInteger
                                    className={style.inputNumber}
                                    min={0}
                                    max={360}
                                    onChange={(v, options) =>
                                        onChangeColorInput(ChangeColorType.LH)(v as number, undefined, options)
                                    }
                                    value={hue}
                                    autoFocus
                                    noRightPadding
                                    notUseUserConfig
                                />
                                <ScrubbableInputInteger
                                    className={style.inputNumber}
                                    min={0}
                                    max={100}
                                    onChange={(v, options) =>
                                        onChangeColorInput(ChangeColorType.LS)(v as number, undefined, options)
                                    }
                                    value={horizontal * 100}
                                    noRightPadding
                                    notUseUserConfig
                                />
                                <ScrubbableInputInteger
                                    className={style.inputNumber}
                                    min={0}
                                    max={100}
                                    onChange={(v, options) =>
                                        onChangeColorInput(ChangeColorType.LL)(v as number, undefined, options)
                                    }
                                    value={vertical * 100}
                                    noRightPadding
                                    notUseUserConfig
                                />
                            </>
                        ) : colorMode === ColorMode.HSB ? (
                            <>
                                <ScrubbableInputInteger
                                    className={style.inputNumber}
                                    min={0}
                                    max={360}
                                    onChange={(v, options) =>
                                        onChangeColorInput(ChangeColorType.BH)(v as number, undefined, options)
                                    }
                                    value={hue}
                                    autoFocus
                                    noRightPadding
                                    notUseUserConfig
                                />
                                <ScrubbableInputInteger
                                    className={style.inputNumber}
                                    min={0}
                                    max={100}
                                    onChange={(v, options) =>
                                        onChangeColorInput(ChangeColorType.BS)(v as number, undefined, options)
                                    }
                                    value={horizontal * 100}
                                    noRightPadding
                                    notUseUserConfig
                                />
                                <ScrubbableInputInteger
                                    className={style.inputNumber}
                                    min={0}
                                    max={100}
                                    onChange={(v, options) =>
                                        onChangeColorInput(ChangeColorType.BB)(v as number, undefined, options)
                                    }
                                    value={vertical * 100}
                                    noRightPadding
                                    notUseUserConfig
                                />
                            </>
                        ) : null}
                        {colorMode !== ColorMode.CSS ? (
                            <ScrubbableInputPercent
                                className={style.inputOpacity}
                                value={opacity}
                                onChange={onChangeOpacityInput}
                                min={0}
                                max={100}
                                testId="color-picker-opacity-input"
                            />
                        ) : null}
                    </div>
                </div>
            }
        </div>
    )
}
