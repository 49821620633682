import { translation } from './types.translation'
/* eslint-disable no-restricted-imports */
import {
    ContractSpecification,
    DocumentPublicAccessVO,
    FolderID,
    PlanAndUserStateVO,
    RoleStatus,
    TeamID,
    UserMemberVO,
    ResourceMemberVO,
    MemberPermission,
} from '../../kernel/interface/type'
import { FlatDoc } from '../../space/util/types'

export enum ShareDialogType {
    Folder,
    Doc,
}

export const ShareDialogType2Label = {
    [ShareDialogType.Folder]: translation('Project'),
    [ShareDialogType.Doc]: translation('File'),
}

export interface CurrentUser {
    role: RoleStatus
    temporaryRole: RoleStatus
    userId: number
}

export interface AccessVO {
    id?: string
    role?: MemberPermission
    publicAccess?: DocumentPublicAccessVO
    copyable?: boolean
}

export enum SpecialShareDialogModeType {
    DevMode = 'DevMode',
    PrototypePresent = 'PrototypePresent',
    Normal = 'Normal',
}
export type SpecialShareDialogModeTypeString = `${SpecialShareDialogModeType}`

export interface ShareDialogProps {
    visible: boolean
    type: ShareDialogType
    onCancel: () => void
    access: AccessVO
    orgId: string
    members: ResourceMemberVO
    isDraft?: boolean
    currentUser?: CurrentUser
    docData?: FlatDoc
    dimission: boolean // 表示这个项目或者文件是否是离职继承的
    domainList?: string[]
    resourceName: string
    refreshMembersList?: () => Promise<void>
    onLeave?: () => void
    specialModeType?: SpecialShareDialogModeType
    showDevModeShareTips?: boolean
    hideDevModeShareTipsCallback?: () => void
    teamPlanOfSharingDoc: PlanAndUserStateVO | undefined
    showShareDraftTipBanner?: boolean
    initUpgradePlan?: (plan: ContractSpecification, selectedTeamId?: TeamID) => Promise<void>
    moveDraftIntoTeam?: () => void
    shareDocLimitAction?: {
        setMoveModal: (v: boolean) => void
        setShareModal: (v: boolean) => void
    }
    draftFolderId: FolderID | undefined
}

export interface ClickAccessMenuParams {
    type: AccessDropdownType
    key: AccessMenuKey
    member: UserMemberVO
}

export enum AccessDropdownType {
    Parent, // {translation('InheritPermissions')}
    Member, // 成员权限
    Invited, // 邀请权限
    Self, // 自己权限
    View, // 只展示，无菜单
    Public, // 公共访问菜单
}

export enum AccessAction {
    Remove = 'remove',
    ReInvite = 'reInvite',
    DeleteInvite = 'deleteInvite',
    Leave = 'leave',
}

export type AccessMenuKey = MemberPermission | RoleStatus | AccessAction

export const AccessMenuLabel = {
    [MemberPermission.Inherit]: translation('InheritPermissions'),
    [MemberPermission.Edit]: translation('CanEdit'),
    [MemberPermission.View]: translation('CanView'),
    [MemberPermission.None]: translation('Remove'),
    [RoleStatus.Owner]: translation('Owner'),
    [RoleStatus.Admin]: translation('Admin'),
    [RoleStatus.Editor]: translation('CanEdit'),
    [RoleStatus.Viewer]: translation('CanView'),
    [RoleStatus.ViewPrototype]: translation('CanViewPrototype'),
    [AccessAction.Remove]: translation('Remove_synonyms1'),
    [AccessAction.ReInvite]: translation('ResendInvite'),
    [AccessAction.DeleteInvite]: translation('Remove_synonyms1'),
    [AccessAction.Leave]: translation('Leave'),
}
