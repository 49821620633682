import { Scrollbars, ScrollbarProps as _ScrollbarProps, positionValues } from '@yuanfudao/react-custom-scrollbars'
import classnames from 'classnames'
import React, { HTMLAttributes, forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'
import {
    RenderTrackHorizontal,
    RenderTrackVertical,
    RenderView,
    renderThumbHorizontal,
    renderThumbVertical,
} from './render-components'
import classes from './scrollbar.module.less'
import { useScrollBarWidth } from './use-scrollbar-width'

export type { positionValues }
export interface ScrollbarRef {
    scrollTop: (top: number) => void
    scrollLeft: (left: number) => void
    scrollToTop: () => void
    scrollToBottom: () => void
    scrollToLeft: () => void
    scrollToRight: () => void
    getScrollLeft: () => number
    getScrollTop: () => number
    getScrollWidth: () => number
    getScrollHeight: () => number
    getClientWidth: () => number
    getClientHeight: () => number
    getValues: () => positionValues
    getContainerElement: () => HTMLElement | null
}
export interface ScrollbarProps extends _ScrollbarProps {
    renderViewClassName?: string
    hideScrollbar?: boolean
    children?: React.ReactNode
    scrollVerticalClassName?: string
    scrollHorizontalClassName?: string
    /**
     * 隐藏横向滚动及额外的margin-bottom, 避免mac系统配置始终展示滚动条时计算容器高度包含margin-bottom的问题
     */
    hideHorizontalScrollbar?: boolean
}

export function _Scrollbar(props: ScrollbarProps, ref?: React.Ref<ScrollbarRef>) {
    const {
        className,
        renderViewClassName,
        hideScrollbar,
        scrollVerticalClassName,
        scrollHorizontalClassName,
        hideHorizontalScrollbar,
        ...otherProps
    } = props
    const [autoHide, setAutoHide] = useState<boolean>(true)
    const originRef = useRef<Scrollbars>(null)
    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const { scrollbarWidth } = useScrollBarWidth()
    const isZeroWidthScrollbar = useMemo(() => scrollbarWidth === 0, [scrollbarWidth])

    const componentRefMounted = useCallback((el: HTMLDivElement | null) => {
        Object.assign(scrollContainerRef, { current: el })
    }, [])

    const getContainerElement = useCallback(() => {
        return scrollContainerRef.current
    }, [])

    useImperativeHandle(ref, () => Object.assign(originRef.current ?? {}, { getContainerElement }) as ScrollbarRef)

    return (
        <Scrollbars
            {...otherProps}
            ref={originRef}
            autoHide={autoHide}
            className={classnames(classes.scrollbar, [className], { [classes.hover]: !autoHide })}
            onMouseEnter={() => setAutoHide(false)}
            onMouseLeave={() => setAutoHide(true)}
            renderView={(viewProps: HTMLAttributes<HTMLDivElement>) => (
                <RenderView
                    {...viewProps}
                    className={classnames(renderViewClassName, {
                        [classes.hiddenSystemScrollbar]: isZeroWidthScrollbar,
                        [classes.hideHorizontalScrollbar]: hideHorizontalScrollbar,
                    })}
                    componentRefMounted={componentRefMounted}
                />
            )}
            renderTrackHorizontal={(trackHorizontalProps: HTMLAttributes<HTMLDivElement>) => (
                <RenderTrackHorizontal
                    className={classnames(hideScrollbar ? classes.hideScrollbar : undefined, scrollHorizontalClassName)}
                    // 避免触发可拖拽弹窗的拖拽
                    data-disabled-drag-move={'true'}
                    {...trackHorizontalProps}
                />
            )}
            renderTrackVertical={(trackVerticalProps: HTMLAttributes<HTMLDivElement>) => (
                <RenderTrackVertical
                    className={classnames(hideScrollbar ? classes.hideScrollbar : undefined, scrollVerticalClassName)}
                    // 避免触发可拖拽弹窗的拖拽
                    data-disabled-drag-move={'true'}
                    {...trackVerticalProps}
                />
            )}
            renderThumbHorizontal={renderThumbHorizontal}
            renderThumbVertical={renderThumbVertical}
            hideTracksWhenNotNeeded
            allowZeroWidth
            systemScrollbarWidth={scrollbarWidth}
        >
            {props.children}
        </Scrollbars>
    )
}

export const Scrollbar = forwardRef(_Scrollbar)
