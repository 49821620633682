/* eslint-disable no-restricted-imports */
import { useCommand } from '../../../main/app-context'
import { ITextStyle } from '../../../document/node/node'
import { useCallback } from 'react'
import { useFontLoaded } from './use-font-loaded'
import { useThumbnailCache } from './use-thumbnail-cache'
import { createTextStylePreview, createTextStyleThumbnail } from '../../../document/command/preview-thumbnail'

export interface TextStyleThumbnailProps {
    width: number
    height: number
    styleData?: Readonly<ITextStyle>
}

/**
 * @description 文字样式缩略图
 */
export function useTextStyleThumbnail(props: TextStyleThumbnailProps) {
    const { width, height, styleData } = props
    const command = useCommand()
    const { isFontLoaded } = useFontLoaded(styleData?.fontName)

    const getThumbnailMethod = useCallback(
        (_props: TextStyleThumbnailProps & { isFontLoaded: boolean }) => {
            if (!_props.isFontLoaded || !_props.styleData) {
                return ''
            }
            const style = clampThumbnailStyleData(_props.styleData)
            return command.invoke(createTextStyleThumbnail, {
                width: _props.width,
                height: _props.height,
                // NOTE: 最低使用dpr=2, 提高预览图的清晰度
                devicePixelRatio: Math.max(2, window.devicePixelRatio),
                style,
            })
        },
        [command]
    )
    return useThumbnailCache({ width, height, styleData, isFontLoaded }, getThumbnailMethod)
}

export type TextStylePreviewThumbnailProps = TextStyleThumbnailProps
/**
 * @description 效果样式 预览缩略图
 */
export function useTextStylePreviewThumbnail(props: TextStylePreviewThumbnailProps) {
    const { width, height, styleData } = props
    const command = useCommand()
    const { isFontLoaded } = useFontLoaded(styleData?.fontName)

    const getThumbnailMethod = useCallback(
        (_props: TextStyleThumbnailProps & { isFontLoaded: boolean }) => {
            if (!_props.isFontLoaded || !_props.styleData) {
                return ''
            }
            const style = clampPreviewStyleData(_props.styleData)
            return command.invoke(createTextStylePreview, {
                width: _props.width,
                height: _props.height,
                devicePixelRatio: window.devicePixelRatio,
                style,
            })
        },
        [command]
    )
    return useThumbnailCache({ width, height, styleData, isFontLoaded }, getThumbnailMethod)
}

export function clampPreviewStyleData(styleData: Readonly<ITextStyle>): Readonly<ITextStyle> {
    const fontSize = styleData.fontSize
    let targetFontSize: number
    if (fontSize > 32) {
        targetFontSize = 32
    } else if (fontSize < 8) {
        targetFontSize = 8
    } else {
        targetFontSize = fontSize
    }
    return Object.assign({}, styleData, { fontSize: targetFontSize })
}

export function clampThumbnailStyleData(styleData: Readonly<ITextStyle>): Readonly<ITextStyle> {
    const fontSize = styleData.fontSize
    let targetFontSize: number
    if (fontSize > 20) {
        targetFontSize = 13
    } else if (fontSize > 16) {
        targetFontSize = 12
    } else if (fontSize > 12) {
        targetFontSize = 10
    } else {
        targetFontSize = 8
    }
    return Object.assign({}, styleData, { fontSize: targetFontSize })
}
