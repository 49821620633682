import { translation } from './inspect-property.translation'
/* eslint-disable no-restricted-imports */
import classNames from 'classnames'
import { useMemo } from 'react'
import { Wukong } from '@wukong/bridge-proto'
import { useViewState } from '../../../view-state-bridge'
import { toFixed } from '../../utils/to-fixed'
import { useAutoLayoutText } from '../design-panel-v2/attributes/components/auto-layout'
import { useAttributeV2 } from '../design/attribute/use-attribute'
import { CopyableRow } from './comp/copyable-row'
import { InspectPropertyBlendMode } from './inspect-property-blend-mode'
import style from './inspect.module.less'

export function InspectProperty() {
    const {
        selectionW,
        selectionH,
        cornerRadius,
        cornerSmoothing,
        independentCornerRadius,
        topLeftRadius,
        topRightRadius,
        bottomLeftRadius,
        bottomRightRadius,
        rotation,
        selectionX,
        selectionY,
    } = useAttributeV2()
    const autoLayoutText = useAutoLayoutText()
    const blendModeViewState = useViewState('blendModeView')

    const xValue = toFixed(selectionX?.value, 2) + 'px'
    const yValue = toFixed(selectionY?.value, 2) + 'px'
    const widthValue = autoLayoutText.width(toFixed(selectionW?.value, 2) + 'px')
    const heightValue = autoLayoutText.height(toFixed(selectionH?.value, 2) + 'px')
    const { space, padding, isAutoLayout } = autoLayoutText

    const cornerRadiusValue = toFixed(cornerRadius?.value, 2) + 'px'
    const topLeftRadiusValue = toFixed(topLeftRadius?.value, 2) + 'px'
    const topRightRadiusValue = toFixed(topRightRadius?.value, 2) + 'px'
    const bottomLeftRadiusValue = toFixed(bottomLeftRadius?.value, 2) + 'px'
    const bottomRightRadiusValue = toFixed(bottomRightRadius?.value, 2) + 'px'
    const cornerSmoothingValue = toFixed(cornerSmoothing?.value, 2) * 100 + '%'

    const rotationValue =
        toFixed(
            rotation?.valueType === Wukong.DocumentProto.BaseAttributePropValueType.BASE_ATTRIBUTE_PROP_VALUE_TYPE_MIXED
                ? rotation?.value
                : (Number(rotation?.value) * 180) / Math.PI,
            2
        ) + 'deg'

    const includeRadius = useMemo(() => {
        if (cornerRadius?.value && cornerRadius?.value > 0 && !cornerRadius?.mixed) {
            return true
        }
        if (
            independentCornerRadius?.show &&
            (topLeftRadius?.value || topRightRadius?.value || bottomLeftRadius?.value || bottomRightRadius?.value)
        ) {
            return true
        }
        return false
    }, [
        bottomLeftRadius?.value,
        bottomRightRadius?.value,
        topLeftRadius?.value,
        topRightRadius?.value,
        independentCornerRadius?.show,
        cornerRadius?.mixed,
        cornerRadius?.value,
    ])

    const isSameRadius = useMemo(() => {
        return !cornerRadius?.mixed
    }, [cornerRadius?.mixed])

    const includeCornerSmoothing = useMemo(() => {
        return includeRadius && cornerSmoothing && cornerSmoothing?.value > 0
    }, [includeRadius, cornerSmoothing])

    return (
        <div className={style.panel}>
            <div className={style.panelTitle} data-testid="inspect-attribute">
                {translation('Properties')}
            </div>
            <div className={classNames(style.propertyRowNarrow, style.basePropertyRow)}>
                <span className={style.propertyName}>X</span>
                <div className={style.properyPairsContainer}>
                    <CopyableRow copyValue={xValue}>
                        <span className={style.shadowPairValue}>{xValue}</span>
                    </CopyableRow>
                </div>
            </div>
            <div className={classNames(style.propertyRowNarrow, style.basePropertyRow)}>
                <span className={style.propertyName}>Y</span>
                <div className={style.properyPairsContainer}>
                    <CopyableRow copyValue={yValue}>
                        <span className={style.shadowPairValue}>{yValue}</span>
                    </CopyableRow>
                </div>
            </div>

            <div className={classNames(style.propertyRowNarrow, style.basePropertyRow)}>
                <span className={style.propertyName}>W</span>
                <div className={style.properyPairsContainer}>
                    <CopyableRow copyValue={widthValue}>
                        <span className={style.shadowPairValue}>{widthValue}</span>
                    </CopyableRow>
                </div>
            </div>
            <div className={classNames(style.propertyRowNarrow, style.basePropertyRow)}>
                <span className={style.propertyName}>H</span>
                <div className={style.properyPairsContainer}>
                    <CopyableRow copyValue={heightValue}>
                        <span className={style.shadowPairValue}>{heightValue}</span>
                    </CopyableRow>
                </div>
            </div>

            {isAutoLayout && (
                <>
                    {space && (
                        <div className={classNames(style.propertyRowNarrow, style.basePropertyRow)}>
                            <span className={style.propertyName}>{translation('wxGahM')}</span>
                            <div className={style.properyPairsContainer}>
                                <CopyableRow copyValue={space}>
                                    <span className={style.shadowPairValue}>{space}</span>
                                </CopyableRow>
                            </div>
                        </div>
                    )}
                    {padding && (
                        <div className={classNames(style.propertyRowNarrow, style.basePropertyRow)}>
                            <span className={style.propertyName}>{translation('ZoQLgc')}</span>
                            <div className={style.properyPairsContainer}>
                                <CopyableRow copyValue={padding}>
                                    <span className={style.shadowPairValue}>{padding}</span>
                                </CopyableRow>
                            </div>
                        </div>
                    )}
                </>
            )}

            {includeRadius && (
                <div className={classNames(style.propertyRowNarrow, style.basePropertyRow)}>
                    <span className={style.propertyName}>{translation('Radius')}</span>
                    {isSameRadius ? (
                        <div className={style.radiusPairsContainer}>
                            <CopyableRow copyValue={cornerRadiusValue}>
                                <span className={style.shadowPairValue}>{cornerRadiusValue}</span>
                            </CopyableRow>
                        </div>
                    ) : (
                        <div className={style.radiusPairsContainer}>
                            <CopyableRow
                                copyValue={`topLeft:${topLeftRadiusValue} topRight:${topRightRadiusValue} bottomRight:${bottomRightRadiusValue} bottomLeft:${bottomLeftRadiusValue} `}
                            >
                                <span className={style.shadowPairValue}>{topLeftRadiusValue},</span>
                                <span className={style.shadowPairValue}>{topRightRadiusValue},</span>
                                <span className={style.shadowPairValue}>{bottomRightRadiusValue},</span>
                                <span className={style.shadowPairValue}>{bottomLeftRadiusValue}</span>
                            </CopyableRow>
                        </div>
                    )}
                </div>
            )}
            {includeCornerSmoothing && (
                <div className={classNames(style.propertyRowNarrow, style.basePropertyRow)}>
                    <span className={style.propertyName}>{translation('CornerSmoothing')}</span>
                    <div className={style.properyPairsContainer}>
                        <CopyableRow copyValue={cornerSmoothingValue}>
                            <span className={style.shadowPairValue}>{cornerSmoothingValue}</span>
                        </CopyableRow>
                    </div>
                </div>
            )}
            {rotationValue !== '0deg' && (
                <div className={classNames(style.propertyRowNarrow, style.basePropertyRow)}>
                    <span className={style.propertyName}>{translation('Rotation')}</span>
                    <div className={style.properyPairsContainer}>
                        <CopyableRow copyValue={`${rotationValue}`}>
                            <span className={style.propertyValue}>{rotationValue}</span>
                        </CopyableRow>
                    </div>
                </div>
            )}
            <InspectPropertyBlendMode blendModeViewState={blendModeViewState} />
        </div>
    )
}
