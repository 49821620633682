import { MethodSignature } from '@wukong/bridge-proto'
import { WKSubMenuItem } from '../../../../../../../ui-lib/src'
import { CommandInvoker, type BridgeCommand } from '../../../../../document/command/command-invoker'

export const generateSubMenuConfigUnderSelection = (
    hasSelection: boolean,
    commandInvoker: CommandInvoker,
    name: string,
    shortCut: string,
    callBridge: boolean,
    command: BridgeCommand | MethodSignature<any, any>,
    ...args: any[]
): WKSubMenuItem => ({
    name,
    shortCut,
    disable: !hasSelection,
    handler: () =>
        callBridge
            ? commandInvoker.DEPRECATED_invokeBridge(command as MethodSignature<any, any>, ...args)
            : commandInvoker.invoke(command as BridgeCommand<any>, ...args),
})
export const generateSubMenuConfigUnderSelectionSort = (
    disable: boolean,
    commandInvoker: CommandInvoker,
    name: string,
    shortCut: string,
    callBridge: boolean,
    command: BridgeCommand | MethodSignature<any, any>,
    supprtUndo?: boolean,
    ...args: any[]
): WKSubMenuItem => ({
    name,
    shortCut,
    disable,
    handler: () =>
        callBridge
            ? commandInvoker.DEPRECATED_invokeBridge(command as MethodSignature<any, any>, ...args)
            : commandInvoker.invoke(command as BridgeCommand<any>, ...args),
    autoCommit: supprtUndo,
})

export const generateSubMenuConfigUnderSelectionView = (
    disable: boolean,
    commandInvoker: CommandInvoker,
    name: string,
    shortCut: string,
    callBridge: boolean,
    command: BridgeCommand | MethodSignature<any, any>,
    ...args: any[]
): WKSubMenuItem => ({
    name,
    shortCut,
    disable,
    handler: () =>
        callBridge
            ? commandInvoker.DEPRECATED_invokeBridge(command as MethodSignature<any, any>, ...args)
            : commandInvoker.invoke(command as BridgeCommand<any>, ...args),
})

export const generateSubMenuConfigWithActivity = (
    disable: boolean,
    commandInvoker: CommandInvoker,
    name: string,
    shortCut: string,
    activity: boolean,
    callBridge: boolean,
    command: BridgeCommand | MethodSignature<any, any>,
    ...args: any[]
): WKSubMenuItem => ({
    name,
    shortCut,
    activity,
    disable,
    testId: name,
    handler: () =>
        callBridge
            ? commandInvoker.DEPRECATED_invokeBridge(command as MethodSignature<any, any>, ...args)
            : commandInvoker.invoke(command as BridgeCommand<any>, ...args),
})

export const generateSubMenuConfigStatic = (
    // disable: boolean,
    commandInvoker: CommandInvoker,
    name: string,
    shortCut: string,
    callBridge: boolean,
    command: BridgeCommand | MethodSignature<any, any>,
    ...args: any[]
): WKSubMenuItem => ({
    name,
    shortCut,
    disable: false,
    testId: name,
    handler: () =>
        callBridge
            ? commandInvoker.DEPRECATED_invokeBridge(command as MethodSignature<any, any>, ...args)
            : commandInvoker.invoke(command as BridgeCommand<any>, ...args),
})
