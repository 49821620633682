import { Wukong } from '@wukong/bridge-proto'
import constate from 'constate'
import { isEqual } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useUserInfoContext } from '../../auth/auth-guard/context/user'
import { OrganizationVO, ResourceMemberVO, ResourceType, RoleStatus } from '../../kernel/interface/type'
import { GetDocumentAccess } from '../../kernel/request/accesses'
import { UpdateDocRequest } from '../../kernel/request/document'
import { GetFolderRequest } from '../../kernel/request/folder'
import { GetOrganizations } from '../../kernel/request/organizations'
import { GetMembersV3 } from '../../kernel/request/user-roles'
import { MotiffPlanType, spaceFrogService } from '../../kernel/service/space-frog-service'
import { featureSwitchManager } from '../../kernel/switch/core'
import { useOrganizationNotifyService } from '../../main/app-context'
import { createFileManager } from '../../main/create-file-manager'
import { useDocFolderTeamData } from '../../main/layout/layout-context'
import { AccessVO, CurrentUser } from '../../share/share-dialog/types'
import { WKFrogTaskStateService } from '../../share/wk-frog-task'
import { WK } from '../../window'
import { RoleStatusWeight } from '../../kernel/interface/user-roles'

const defaultMembers = {
    members: [],
    parentMembers: [],
    parentName: '',
    parentIcon: '',
    parentIconBackgroundColor: '',
    role: RoleStatus.None,
    temporaryRole: RoleStatus.None,
    draftAndOwnerShareEditLimited: false,
}

const useSyncDocAndFolderName = (
    docId: string | undefined,
    folderId: string | undefined,
    docNameChanger: (name: string) => void,
    folderNameChanger: (name: string) => void,
    relationChangeHandler: (newFolderId: string) => void
) => {
    const orgNotify = useOrganizationNotifyService()
    const propertiesMessage = orgNotify.states.use.propertiesMessageState()
    const relationMessage = orgNotify.states.use.relationMessageState()

    useEffect(() => {
        if (!propertiesMessage) {
            return
        }
        if (propertiesMessage.businessEntity?.entityType !== Wukong.NotifyProto.EntityType.DOC) {
            return
        }
        if (!docId || propertiesMessage.businessEntity?.entityId !== docId) {
            return
        }
        if (!propertiesMessage.changedProperties.name) {
            return
        }
        docNameChanger(propertiesMessage.changedProperties.name)
    }, [docId, docNameChanger, propertiesMessage])

    useEffect(() => {
        if (!propertiesMessage) {
            return
        }
        if (propertiesMessage.businessEntity?.entityType !== Wukong.NotifyProto.EntityType.FOLDER) {
            return
        }
        if (!folderId || propertiesMessage.businessEntity?.entityId !== folderId) {
            return
        }
        if (!propertiesMessage.changedProperties.name) {
            return
        }
        folderNameChanger(propertiesMessage.changedProperties.name)
    }, [folderId, folderNameChanger, propertiesMessage])

    useEffect(() => {
        if (!relationMessage) {
            return
        }
        if (
            !docId ||
            relationMessage.relation?.one?.entityType !== Wukong.NotifyProto.EntityType.DOC ||
            relationMessage.relation.one.entityId !== docId
        ) {
            return
        }
        if (
            relationMessage.changeType !== Wukong.NotifyProto.RelationChangeType.ADD &&
            relationMessage.changeType !== Wukong.NotifyProto.RelationChangeType.UPDATE
        ) {
            return
        }
        if (
            relationMessage.relation?.another?.entityType !== Wukong.NotifyProto.EntityType.FOLDER ||
            !relationMessage.relation.another.entityId
        ) {
            return
        }
        relationChangeHandler(relationMessage.relation.another.entityId)
    }, [docId, relationChangeHandler, relationMessage])
}

const useSyncTeamData = () => {
    const orgNotify = useOrganizationNotifyService()
    const propertiesMessage = orgNotify.states.use.propertiesMessageState()
    const { teamData, fetchTeamData } = useDocFolderTeamData()

    useEffect(() => {
        if (!teamData?.id) {
            return
        }
        // 团队公开性变化属于属性变更，所以只要接收到对应团队的属性变更通知 更新数据即可
        if (
            propertiesMessage?.businessEntity?.entityType === Wukong.NotifyProto.EntityType.TEAM &&
            propertiesMessage?.businessEntity?.entityId === teamData.id
        ) {
            fetchTeamData()
        }
    }, [fetchTeamData, propertiesMessage, teamData?.id])
}

function useDocInfo() {
    const params = useParams()
    const docId = params.docId || ''
    const { docData, fetchDocData, setDocData, folderData, setFolderData } = useDocFolderTeamData()
    const { userInfo } = useUserInfoContext()
    const [shareDialogMembers, setShareDialogMembers] = useState<ResourceMemberVO>(defaultMembers)
    const [currentUser, setCurrentUser] = useState<CurrentUser>()
    const [access, setAccess] = useState<AccessVO>({ id: docId })
    const [domainList, setDomainList] = useState<string[]>([])
    const [organizationList, setOrganizationList] = useState<OrganizationVO[]>([])

    WKFrogTaskStateService.setUserVOV2(userInfo)
    useEffect(() => {
        if (!docData) {
            return
        }
        if (createFileManager.isCreatingFile()) {
            WKFrogTaskStateService.setDocGetVO(docData)
            return
        }
        if (docId) {
            spaceFrogService.update(docData.orgId === '-1' ? MotiffPlanType.Team : MotiffPlanType.Enterprise)
            WKFrogTaskStateService.setDocGetVO(docData)
        }
    }, [docId, docData])

    const updateDocShareData = async () => {
        const [members, docAccess] = await Promise.all([
            new GetMembersV3(ResourceType.Document, docId, '').start(),
            new GetDocumentAccess(docId).start(),
        ])
        setDomainList(docAccess.domainList ?? [])
        const newAccess = {
            id: docAccess.docId,
            role: docAccess.folderMemberPermission,
            copyable: docAccess.copyable,
            publicAccess: docAccess.documentPublicAccessVO,
        }
        const newCurrentUser = {
            role: members.role,
            userId: userInfo.userId,
            temporaryRole: members.temporaryRole,
        }
        if (!isEqual(newAccess, access)) {
            setAccess(newAccess)
        }
        if (!isEqual(members, shareDialogMembers)) {
            setShareDialogMembers(members)
        }
        if (!isEqual(newCurrentUser, currentUser)) {
            setCurrentUser(newCurrentUser)
        }
    }

    const syncFolderData = useCallback(
        async (folderId: string) => {
            fetchDocData()
            const request = new GetFolderRequest(folderId)
            request
                .start()
                .then(setFolderData)
                .catch(() => {
                    setFolderData(undefined)
                })
        },
        [fetchDocData, setFolderData]
    )
    useSyncTeamData()

    const updateDocName = useCallback(
        (name: string) =>
            docData
                ? new UpdateDocRequest(docData.id, { ...docData, name }).start()
                : Promise.reject('top-area-context'),
        [docData]
    )

    const syncDocName = useCallback(
        (name: string) =>
            setDocData((doc) => {
                const nextDoc = doc ? { ...doc, name } : doc
                WKFrogTaskStateService.setDocGetVO(nextDoc)
                return nextDoc
            }),
        [setDocData]
    )

    const syncFolderName = useCallback(
        (name: string) => setFolderData((folder) => (folder ? { ...folder, name } : folder)),
        [setFolderData]
    )

    useSyncDocAndFolderName(docId, folderData?.id, syncDocName, syncFolderName, syncFolderData)

    const role = folderData?.roleEnum || 'none'
    const isShow = useMemo(() => {
        return RoleStatusWeight[role] >= RoleStatusWeight.viewer
    }, [role])

    const getCurrentDocOrganization = useCallback(() => {
        return organizationList.find((organization) => organization.id === docData?.orgId)
    }, [docData?.orgId, organizationList])

    useEffect(() => {
        new GetOrganizations()
            .start()
            .then(setOrganizationList)
            .catch(() => {})
    }, [])

    useEffect(() => {
        if (featureSwitchManager.isEnabled('async-request-schema-version')) {
            if (docData?.orgId) {
                const currentDocOrg = organizationList.find((org) => org.id === docData?.orgId)
                if (currentDocOrg) {
                    if (WK.orgId !== currentDocOrg.id) {
                        WK.orgId = currentDocOrg.id
                    }
                } else {
                    WK.orgId = '-1'
                }
            }
        }
    }, [organizationList, docData])

    return {
        isShow,
        domainList,
        fetchDocData,
        docData,
        folderData,
        updateDocName,
        access,
        shareDialogMembers,
        currentUser,
        updateDocShareData,
        organizationList,
        getCurrentDocOrganization,
    }
}

export const [DocInfoContextProvider, useDocInfoContext] = constate(useDocInfo)
