import AbroadPlugin from './abroad-plugin'
import AiAlign from './ai-align'
import AiGenChangeBaseFrameName from './ai-gen-change-base-frame-name'
import AiGenCustomConfig from './ai-gen-custom-config'
import AiGenOnboardingDesignSystem from './ai-gen-onboarding-design-system'
import AiGenPrototype from './ai-gen-prototype'
import AiGenSelectDesignSystemAndPlatform from './ai-gen-select-design-system-and-platform'
import AiGenUiBatchOp from './ai-gen-ui-batch-op'
import AiGenUiMaxLimitPrompt from './ai-gen-ui-max-limit-prompt'
import AiRecognizeDebug from './ai-recognize-debug'
import AiSearch from './ai-search'
import AllowDebugLog from './allow-debug-log'
import AllowTraceLog from './allow-trace-log'
import AssetMigrationMode from './asset-migration-mode'
import AsyncRequestSchemaVersion from './async-request-schema-version'
import AutoRedirectToBetaHelpCenter from './auto-redirect-to-beta-help-center'
import BetaForceUpgrade from './beta-force-upgrade'
import BgWhiteHelpIcon from './bg-white-help-icon'
import Chatbot from './chatbot'
import ChatbotDebug from './chatbot-debug'
import ChatbotV02 from './chatbot-v02'
import CommentPerfDebugLog from './comment-perf-debug-log'
import CommentPerfViewportFilter from './comment-perf-viewport-filter'
import CommentPositionTransform from './comment-position-transform'
import ComputeAndCheckDocumentHash from './compute-and-check-document-hash'
import CursorLog from './cursor-log'
import DarkMode from './dark-mode'
import DesktopUpdate from './desktop-update'
import DevModeAvailabilityVerificationTestEnv from './dev-mode-availability-verification-test-env'
import DevModeTrackCursorIntercepter from './dev-mode-track-cursor-intercepter'
import DevModeTrackHittest from './dev-mode-track-hittest'
import DevModeTrackMouseEvent from './dev-mode-track-mouse-event'
import DevModeTrackMouseEventProcessor from './dev-mode-track-mouse-event-processor'
import DevPrototype from './dev-prototype'
import DevTool from './dev-tool'
import DevWebgpu from './dev-webgpu'
import DoNotSetCanvasSizeOnAfterEditorSetup from './do-not-set-canvas-size-on-after-editor-setup'
import ExportAno from './export-ano'
import FeatChatbotFocusV2 from './feat-chatbot-focus-v2'
import FeatOwnerPageOldValueForRenderSystem from './feat-owner-page-old-value-for-render-system'
import FeatReplacedInstanceInherited from './feat-replaced-instance-inherited'
import FeatReportToUnsplash from './feat-report-to-unsplash'
import FixPathToVn from './fix-path-to-vn'
import FloatVariable from './float-variable'
import FocusView from './focus-view'
import FocusViewDebugLog from './focus-view-debug-log'
import FontSelect from './font-select'
import HandleWheelDebugger from './handle-wheel-debugger'
import ImportHtml from './import-html'
import JsImagePostAwait from './js-image-post-await'
import LibraryCloseModal from './library-close-modal'
import LibraryNotifySyncServiceRxjsRemoval from './library-notify-sync-service-rxjs-removal'
import LibraryReduceCalcFurtherOptimize from './library-reduce-calc-further-optimize'
import MirrorScalingMinZoom from './mirror-scaling-min-zoom'
import MotiffDebugger from './motiff-debugger'
import MotiffScopePrettyProps from './motiff-scope-pretty-props'
import NativeImageDrop from './native-image-drop'
import NewDocShareDialog from './new-doc-share-dialog'
import NoSelectionWithCopyAsPng from './no-selection-with-copy-as-png'
import NotRecalLayoutWhenApplyRemote from './not-recal-layout-when-apply-remote'
import OpenMouseScaleSpeed from './open-mouse-scale-speed'
import OrganizationPlus from './organization-plus'
import PasteFromFigma from './paste-from-figma'
import PasteProtoToUi from './paste-proto-to-ui'
import PaymentBank from './payment-bank'
import PaymentTax from './payment-tax'
import PerfStrokeGeometry from './perf-stroke-geometry'
import PerfStrokeJoin from './perf-stroke-join'
import PluginApiFontLoadCheck from './plugin-api-font-load-check'
import PluginPerf from './plugin-perf'
import PresentComment from './present-comment'
import PrototypeDebugLog from './prototype-debug-log'
import PrototypeOverlayPosition from './prototype-overlay-position'
import PrototypeScaleSize from './prototype-scale-size'
import PrototypeSmartAnimation from './prototype-smart-animation'
import PrototypeSpring from './prototype-spring'
import PrototypeSwitchDeviceFrame from './prototype-switch-device-frame'
import QuickImport from './quick-import'
import RefactorAiDesignLintServiceRxjsRemoval from './refactor-ai-design-lint-service-rxjs-removal'
import RefactorNewGuide from './refactor-new-guide'
import RenderWebgpu from './render-webgpu'
import Sandbox from './sandbox'
import ShowPerformanceScore from './show-performance-score'
import StorageEventAsChannel from './storage-event-as-channel'
import SwitchLanguage from './switch-language'
import TestSocketConnectivity from './test-socket-connectivity'
import VariableGenerateName from './variable-generate-name'
import VariableWasmRefactor from './variable-wasm-refactor'
import VectorEditGradient from './vector-edit-gradient'
import WebgpuBlacklist from './webgpu-blacklist'
import Wk43991 from './wk-43991'
import Wk44265 from './wk-44265'
import WkAdmin from './wk-admin'
import ZoomAtMouse from './zoom-at-mouse'
export const FeatureSwitchConfigs = [
    AbroadPlugin,
    AiAlign,
    AiGenChangeBaseFrameName,
    AiGenCustomConfig,
    AiGenOnboardingDesignSystem,
    AiGenPrototype,
    AiGenSelectDesignSystemAndPlatform,
    AiGenUiBatchOp,
    AiGenUiMaxLimitPrompt,
    AiRecognizeDebug,
    AiSearch,
    AllowDebugLog,
    AllowTraceLog,
    AssetMigrationMode,
    AsyncRequestSchemaVersion,
    AutoRedirectToBetaHelpCenter,
    BetaForceUpgrade,
    BgWhiteHelpIcon,
    Chatbot,
    ChatbotDebug,
    ChatbotV02,
    CommentPerfDebugLog,
    CommentPerfViewportFilter,
    CommentPositionTransform,
    ComputeAndCheckDocumentHash,
    CursorLog,
    DarkMode,
    DesktopUpdate,
    DevModeAvailabilityVerificationTestEnv,
    DevModeTrackCursorIntercepter,
    DevModeTrackHittest,
    DevModeTrackMouseEvent,
    DevModeTrackMouseEventProcessor,
    DevPrototype,
    DevTool,
    DevWebgpu,
    DoNotSetCanvasSizeOnAfterEditorSetup,
    ExportAno,
    FeatChatbotFocusV2,
    FeatOwnerPageOldValueForRenderSystem,
    FeatReplacedInstanceInherited,
    FeatReportToUnsplash,
    FixPathToVn,
    FloatVariable,
    FocusView,
    FocusViewDebugLog,
    FontSelect,
    HandleWheelDebugger,
    ImportHtml,
    JsImagePostAwait,
    LibraryCloseModal,
    LibraryNotifySyncServiceRxjsRemoval,
    LibraryReduceCalcFurtherOptimize,
    MirrorScalingMinZoom,
    MotiffDebugger,
    MotiffScopePrettyProps,
    NativeImageDrop,
    NewDocShareDialog,
    NoSelectionWithCopyAsPng,
    NotRecalLayoutWhenApplyRemote,
    OpenMouseScaleSpeed,
    OrganizationPlus,
    PasteFromFigma,
    PasteProtoToUi,
    PaymentBank,
    PaymentTax,
    PerfStrokeGeometry,
    PerfStrokeJoin,
    PluginApiFontLoadCheck,
    PluginPerf,
    PresentComment,
    PrototypeDebugLog,
    PrototypeOverlayPosition,
    PrototypeScaleSize,
    PrototypeSmartAnimation,
    PrototypeSpring,
    PrototypeSwitchDeviceFrame,
    QuickImport,
    RefactorAiDesignLintServiceRxjsRemoval,
    RefactorNewGuide,
    RenderWebgpu,
    Sandbox,
    ShowPerformanceScore,
    StorageEventAsChannel,
    SwitchLanguage,
    TestSocketConnectivity,
    VariableGenerateName,
    VariableWasmRefactor,
    VectorEditGradient,
    WebgpuBlacklist,
    Wk43991,
    Wk44265,
    WkAdmin,
    ZoomAtMouse,
]
