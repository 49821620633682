import { translation } from './stroke-more-setting.translation'
/* eslint-disable no-restricted-imports */
import { Ref, forwardRef, useImperativeHandle } from 'react'
import { DraggablePopupV2, IconMote } from '../../../../../../../ui-lib/src'
import { EditorDataTestId } from '../../../../../window'
import { usePosition } from '../../../../utils/use-position'
import { IconButton } from '../../../atom/button/icon-button'
import { AdvanceStroke } from '../advance-stroke/advance-stroke'
import { useStrokeMoreSetting } from './use-stroke-more-setting'

export interface StrokeMoreSettingRef {
    initTriggerRef: Ref<HTMLDivElement>
}

// 描边-{translation('AdvancedStroke')}
export const StrokeMoreSetting = forwardRef<StrokeMoreSettingRef>((props, ref) => {
    const { isOpen, onClickMoreIcon, onClose } = useStrokeMoreSetting()
    const { position, reactRef } = usePosition({ isOpen })

    useImperativeHandle(
        ref,
        () => ({
            initTriggerRef: reactRef,
        }),
        [reactRef]
    )

    return (
        <>
            <IconButton
                icon={<IconMote />}
                selected={!!isOpen}
                onClick={onClickMoreIcon}
                dataTestId={EditorDataTestId.StrokeAttr.MoreSetting}
                deepColor
            />
            <DraggablePopupV2
                visible={isOpen && !!position}
                position={position}
                onCancel={onClose}
                header={translation('AdvancedStroke')}
                footer={null}
                positionRightBase
                styleType="editor"
                bodyClassName="p-0"
            >
                <AdvanceStroke />
            </DraggablePopupV2>
        </>
    )
})

StrokeMoreSetting.displayName = 'StrokeMoreSetting'
