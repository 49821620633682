import classnames from 'classnames'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Scrollbar } from '../../../../../../ui-lib/src'
import { useUserInfoContext } from '../../../../auth'
import { CommentId, CommentReply } from '../../../../kernel/interface/comment'
import { isKey, KeyboardCode } from '../../../../kernel/keyboard/keyboard-event-handler'
import { useCommentService } from '../../../../main/app-context'
import { FocusContainer } from '../../../../main/service/focus-view/focus-container'
import { useViewState } from '../../../../view-state-bridge'
import { CommentDataTestId } from '../../../../window'
import { EmojiPick } from '../comment-editor/comment-emoji/emoji-pick'
import {
    useCommentNeedShake,
    useCommentUsersMap,
    useEditingReply,
    useInsertEmoji,
    useInsertEmojiTarget,
    useInsertMentionUserInCreateReply,
    useInsertMentionUserInEditReply,
    useIsFastInsertMentionUser,
    useIsShowMentionUserList,
    useIsStopSyncActiveCommentPosition,
    useNextCommentId,
    usePrevCommentId,
    useReplaceCreateReplyMessages,
    useSelectAllInCreateReply,
    useSelectAllInEditReply,
} from '../comment-service/comment-service-hook'
import { MentionUsers } from '../mention-users/mention-users'
import { ReplyEditor } from '../reply-editor/reply-editor'
import { UsualEmoji } from '../reply-item/common'
import { ReplyItem } from '../reply-item/reply-item'
import classesShake from '../shake.module.less'
import { Comment, EditorType } from '../type'
import { transformOriginMessageToLocal } from '../utils'
import classes from './comment-detail.module.less'
import { translation } from './comment-detail.translation'
import { CommentTitle } from './comment-title'
import { DetailPosition } from './detail-position'

interface CommentDetailProps {
    comment: Comment
}

function usePendingCreateReplies() {
    const commentService = useCommentService()
    return commentService.replyCommentService.useZustandStore.use.pendingCreateReplies()
}

function usePendingEditReplies() {
    const commentService = useCommentService()
    return commentService.replyCommentService.useZustandStore.use.pendingEditReplies()
}

export function CommentDetail(props: CommentDetailProps) {
    const docReadonly = useViewState('docReadonly')

    const contentRef = useRef<HTMLDivElement>(null)
    const [isDockToSide, setIsDockToSide] = useState<boolean>(false)
    const [showDockToSideButton, setShowDockToSideButton] = useState<boolean>(true)
    const [maxHeight, setMaxHeight] = useState<number>()
    const [openPopupReplyId, setOpenPopupReplyId] = useState<number>()

    const { comment } = props
    const { userInfo } = useUserInfoContext()
    const commentService = useCommentService()
    const editingReply = useEditingReply()
    const isShowMentionUserList = useIsShowMentionUserList()
    const isFastInsertMentionUser = useIsFastInsertMentionUser()
    const prevCommentId = usePrevCommentId()
    const nextCommentId = useNextCommentId()
    const insertMentionUserInCreateReply = useInsertMentionUserInCreateReply()
    const insertMentionUserInEditReply = useInsertMentionUserInEditReply()
    const usersMap = useCommentUsersMap()
    const needShake = useCommentNeedShake()
    const replaceCreateReplyMessages = useReplaceCreateReplyMessages()
    const selectAllInCreateReply = useSelectAllInCreateReply()
    const selectAllInEditReply = useSelectAllInEditReply()
    const pendingCreateReplies = usePendingCreateReplies()
    const pendingEditReplies = usePendingEditReplies()
    const insertEmojiTarget = useInsertEmojiTarget()
    const insertEmoji = useInsertEmoji()
    const isStopSyncActiveCommentPosition = useIsStopSyncActiveCommentPosition()

    const initialMessages = useMemo(() => {
        return commentService.getCreateReplyInitialMessages()
    }, [commentService])

    const replies = useMemo(() => {
        return [...comment.commentMetaData.replies].sort((a, b) => a.createdTime - b.createdTime)
    }, [comment.commentMetaData.replies])

    const forbidOverflow = useMemo(() => {
        if (openPopupReplyId === undefined) {
            return false
        }
        return (
            openPopupReplyId === comment.commentMetaData.id ||
            comment.commentMetaData.replies.some((reply) => reply.id === openPopupReplyId)
        )
    }, [openPopupReplyId, comment.commentMetaData.id, comment.commentMetaData.replies])

    const creatingRepliesWithCommentId = useMemo(() => {
        const repliesMap = pendingCreateReplies.get(comment.commentMetaData.id)
        return repliesMap ? [...repliesMap.values()] : []
    }, [comment.commentMetaData.id, pendingCreateReplies])

    const onEditCommentAndReply = useCallback(
        (commentReply: CommentReply) => {
            commentService.editReply(comment.commentMetaData.id, commentReply)
        },
        [comment.commentMetaData.id, commentService]
    )

    const getEditingReply = useCallback(
        (replyId: number) => {
            return comment.commentMetaData.id === editingReply?.commentId && editingReply.commentReply.id === replyId
                ? editingReply.commentReply
                : null
        },
        [comment.commentMetaData.id, editingReply]
    )

    const onDeleteReply = useCallback(
        (commentReply: CommentReply) => {
            commentService.deleteReply(comment.commentMetaData.id, commentReply.id)
        },
        [comment.commentMetaData.id, commentService]
    )

    const onFastReply = useCallback(
        (commentReply: CommentReply) => {
            commentService.fastReply(commentReply.owner)
        },
        [commentService]
    )

    const onClickEmoji = useCallback(
        (editorRect: DOMRect, editorType: EditorType) => {
            const containerRect = contentRef.current?.parentElement?.getBoundingClientRect()
            if (!containerRect) {
                return
            }
            commentService.updateInsertEmojiRect(editorRect, containerRect)
            commentService.openEmojiPick(editorType)
        },
        [commentService]
    )

    const onMentionStyle = useCallback(
        (splitLineRect: DOMRect) => {
            const containerRect = contentRef.current?.parentElement?.getBoundingClientRect()
            if (!containerRect) {
                return
            }
            commentService.updateMentionUsersRect(splitLineRect, containerRect)
        },
        [commentService]
    )

    const onClickReplyItemEmoji = useCallback(
        (commentReply: CommentReply, emojiInfo: UsualEmoji) => {
            commentService.updateCommentReplyEmoji(comment.commentMetaData.id, commentReply.id, emojiInfo.id)
        },
        [comment.commentMetaData.id, commentService]
    )

    const renderPendingReply = useCallback(
        (commentReply: CommentReply) => {
            return (
                <ReplyItem
                    key={commentReply.id}
                    commentReply={commentReply}
                    usersMap={usersMap}
                    currentUserId={userInfo.userBrief.id}
                    pending={true}
                    dataTestId={CommentDataTestId.CommentReplyPendingItem}
                />
            )
        },
        [usersMap, userInfo.userBrief.id]
    )

    const renderReplyEditor = useCallback(
        (commentReply: CommentReply, isComment?: boolean) => {
            return (
                <ReplyEditor
                    key={commentReply.id}
                    userInfo={commentReply.owner}
                    usersMap={usersMap}
                    placeholder={isComment ? translation('AddAComment') : translation('Reply')}
                    submitType="button"
                    onCancel={commentService.closeEditCommentReply}
                    onSendEditorState={commentService.updateEditCommentOrReplyMessage}
                    onSubmit={commentService.submitEditCommentOrReply}
                    initialMessages={
                        commentService.getEditCommentOrReplyEditorState() ??
                        transformOriginMessageToLocal(commentReply.message)
                    }
                    onSearchMentionUsers={commentService.searchMentionUsers}
                    onSearchMentionUsersDone={commentService.closeMentionUser}
                    onMentionStyle={onMentionStyle}
                    selectAll={selectAllInEditReply}
                    selectAllDone={commentService.selectAllDone}
                    isMentionHandling={isShowMentionUserList}
                    mentionKeyEvent={commentService.mentionKeyEvent}
                    isFastInsertMentionUser={isFastInsertMentionUser}
                    insertMentionUser={insertMentionUserInEditReply}
                    insertMentionUserDone={commentService.insertMentionUserDone}
                    activeEmoji={insertEmojiTarget === EditorType.EditReply}
                    onClickEmoji={(editorRect) => onClickEmoji(editorRect, EditorType.EditReply)}
                    insertEmoji={insertEmojiTarget === EditorType.EditReply ? insertEmoji : undefined}
                    insertEmojiDone={commentService.insertEmojiDone}
                    onFocus={() => commentService.editorFocus(EditorType.EditReply)}
                    onBlur={commentService.editorBlur}
                    maxCharSize={1000}
                    dataTestIds={{
                        CommentEditor: CommentDataTestId.CommentEditorReply,
                        CommentEditorInput: CommentDataTestId.CommentEditorReplyInput,
                    }}
                />
            )
        },
        [
            commentService,
            insertEmoji,
            insertEmojiTarget,
            insertMentionUserInEditReply,
            isFastInsertMentionUser,
            isShowMentionUserList,
            onClickEmoji,
            onMentionStyle,
            selectAllInEditReply,
            usersMap,
        ]
    )

    const renderReplyItem = useCallback(
        (commentReply: CommentReply, isComment?: boolean) => {
            return (
                <ReplyItem
                    key={commentReply.id}
                    commentReply={commentReply}
                    usersMap={usersMap}
                    currentUserId={userInfo.userBrief.id}
                    isComment={isComment}
                    onEditCommentAndReply={onEditCommentAndReply}
                    onDeleteReply={onDeleteReply}
                    onFastReply={onFastReply}
                    onClickEmoji={onClickReplyItemEmoji}
                    onPopupOpen={() => setOpenPopupReplyId(commentReply.id)}
                    onPopupClose={() => setOpenPopupReplyId(undefined)}
                    dataTestId={CommentDataTestId.CommentReplyItem}
                />
            )
        },
        [onDeleteReply, onEditCommentAndReply, onFastReply, onClickReplyItemEmoji, userInfo.userBrief.id, usersMap]
    )

    const renderReply = useCallback(
        (commentReply: CommentReply, isComment?: boolean) => {
            const editingCommentReply = getEditingReply(commentReply.id)
            if (editingCommentReply) {
                return renderReplyEditor(editingCommentReply, isComment)
            }
            const pendingCommentReply = pendingEditReplies.get(commentReply.uuid)
            if (pendingCommentReply) {
                return renderPendingReply(pendingCommentReply)
            }
            return renderReplyItem(commentReply, isComment)
        },
        [getEditingReply, pendingEditReplies, renderPendingReply, renderReplyEditor, renderReplyItem]
    )

    const onAnimationEnd = useCallback(
        (e: React.AnimationEvent<HTMLDivElement>) => {
            if (e.currentTarget !== e.target) {
                return
            }
            commentService.shakeAnimationEnd()
        },
        [commentService]
    )

    useEffect(() => {
        return () => {
            // 动画没结束就销毁了组件时，认为动画结束了
            needShake && commentService.shakeAnimationEnd()
        }
    }, [commentService, needShake])

    useEffect(() => {
        commentService.updateCommentAsRead(comment.commentMetaData.id)
    }, [commentService, comment.commentMetaData.id])

    const onDockToSide = useCallback(() => {
        setIsDockToSide(true)
    }, [])

    const onDockToSideDone = useCallback(() => {
        setIsDockToSide(false)
        setShowDockToSideButton(false)
    }, [])

    const onShowDockToSideButton = useCallback(() => {
        setShowDockToSideButton(true)
    }, [])

    const changeMaxHeight = useCallback((_maxHeight: number) => {
        const titleHeight = 40
        setMaxHeight(_maxHeight - titleHeight)
    }, [])

    const rightOffsetAnchor = useMemo(() => {
        const { owner, replies: _replies } = comment.commentMetaData
        const idSet: Set<number> = new Set([owner.id, ..._replies.map((reply) => reply.owner.id)])
        const padding = 10
        const margin = 32
        const oneBubbleWidth = 22
        const bubbleNum = Math.min(idSet.size, 3)
        const extendWidth = Math.max(0, idSet.size.toString().length - 1) * 8
        return padding + margin + bubbleNum * oneBubbleWidth + extendWidth
    }, [comment.commentMetaData])

    const setCommentsAsResolve = useCallback(
        (commentId: CommentId) => {
            commentService.setCommentsAsResolve(commentId, true)
        },
        [commentService]
    )

    const closeComment = useCallback(
        (e: React.KeyboardEvent) => {
            if (!isKey(e.nativeEvent, KeyboardCode.ESCAPE)) {
                return
            }
            if (commentService.hasOpenComment() && !commentService.checkMouseEventExist()) {
                commentService.closeOpenComment()
            }
        },
        [commentService]
    )

    return (
        <div className={classes.commentContent}>
            <DetailPosition
                commentId={comment.commentMetaData.id}
                topOffsetAnchor={64}
                leftOffsetAnchor={32}
                rightOffsetAnchor={rightOffsetAnchor}
                dockToSide={isDockToSide}
                isStopSyncActiveCommentPosition={isStopSyncActiveCommentPosition}
                dockToSideDone={onDockToSideDone}
                onMoveEnd={onShowDockToSideButton}
                changeMaxHeight={changeMaxHeight}
                dataTestId={CommentDataTestId.CommentDetail}
            >
                <FocusContainer
                    className={classnames(classes.commentDetail, { [classesShake.shake]: needShake })}
                    ref={contentRef}
                    onKeyDown={closeComment}
                    onAnimationEnd={onAnimationEnd}
                >
                    <CommentTitle
                        hideResolvedIcon={
                            docReadonly && !commentService.isCurrentUserInComment(comment.commentMetaData)
                        }
                        commentThread={comment.commentMetaData}
                        onCopyLink={commentService.copyCommentLink}
                        onSetUnread={commentService.setCommentsAsUnread}
                        onDeleteComment={commentService.deleteComment}
                        onSetResolve={setCommentsAsResolve}
                        onSetUnResolve={commentService.setCommentsAsUnResolve}
                        onDockToSide={onDockToSide}
                        onCloseComment={commentService.closeCommentDetail}
                        isPrevCommentDisabled={typeof prevCommentId != 'number'}
                        isNextCommentDisabled={typeof nextCommentId != 'number'}
                        onPrevComment={commentService.focusPrevComment}
                        onNextComment={commentService.focusNextComment}
                        currentUser={userInfo.userBrief}
                        showDockToSideButton={showDockToSideButton}
                    />
                    <Scrollbar
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={maxHeight ?? '100vh'}
                        onPointerDown={(e) => e.stopPropagation()}
                        hideScrollbar={forbidOverflow}
                    >
                        <div className={classes.replies}>
                            <div>
                                {renderReply(Object.assign({ parentId: -1 }, comment.commentMetaData), true)}
                                {replies.map((reply) => renderReply(reply))}
                                {creatingRepliesWithCommentId.map((reply) => renderPendingReply(reply))}
                            </div>
                            <ReplyEditor
                                placeholder={translation('Reply')}
                                userInfo={userInfo.userBrief}
                                usersMap={usersMap}
                                onSendEditorState={commentService.updateCreateReplyMessage}
                                initialMessages={initialMessages}
                                onSubmit={commentService.submitCreateReply}
                                onCancel={() => commentService.updateCreateReplyMessage(null)}
                                onSearchMentionUsers={commentService.searchMentionUsers}
                                onMentionStyle={onMentionStyle}
                                onSearchMentionUsersDone={commentService.closeMentionUser}
                                isMentionHandling={isShowMentionUserList}
                                mentionKeyEvent={commentService.mentionKeyEvent}
                                isFastInsertMentionUser={isFastInsertMentionUser}
                                insertMentionUser={insertMentionUserInCreateReply}
                                insertMentionUserDone={commentService.insertMentionUserDone}
                                replaceMessages={replaceCreateReplyMessages}
                                replaceMessagesDone={commentService.replaceCreateReplyMessagesDone}
                                activeEmoji={insertEmojiTarget === EditorType.CreateReply}
                                onClickEmoji={(editorRect) => onClickEmoji(editorRect, EditorType.CreateReply)}
                                insertEmoji={insertEmojiTarget === EditorType.CreateReply ? insertEmoji : undefined}
                                insertEmojiDone={commentService.insertEmojiDone}
                                disabled={!!editingReply}
                                selectAll={selectAllInCreateReply}
                                selectAllDone={commentService.selectAllDone}
                                onFocus={() => commentService.editorFocus(EditorType.CreateReply)}
                                onBlur={commentService.editorBlur}
                                maxCharSize={1000}
                                dataTestIds={{
                                    CommentEditor: CommentDataTestId.CommentEditorDetail,
                                    CommentEditorInput: CommentDataTestId.CommentEditorDraftInput,
                                }}
                            />
                        </div>
                    </Scrollbar>
                </FocusContainer>
                <div></div>
                {isShowMentionUserList ? <MentionUsers /> : null}
                {insertEmojiTarget === EditorType.EditReply || insertEmojiTarget === EditorType.CreateReply ? (
                    <EmojiPick />
                ) : null}
            </DetailPosition>
        </div>
    )
}
