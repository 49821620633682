import classnames from 'classnames'
import React, { forwardRef, useCallback, useState } from 'react'
import { MonoIconCommonEyes16, MonoIconCommonEyesClose16 } from '../../../icons-v2'
import { NormalInput, NormalInputProps, NormalInputRef } from '../normal-input/normal-input'
import classes from './password-input.module.less'
import { translation } from './password-input.translation'

export interface PasswordInputProps extends Omit<NormalInputProps, 'type' | 'backwardIcon' | 'dataTestIds'> {
    iconRender?: (visible: boolean) => JSX.Element
    dataTestIds?: NormalInputProps['dataTestIds'] & { eye?: string }
}

export interface PasswordInputRef extends NormalInputRef {}

function _PasswordInput(props: PasswordInputProps, ref?: React.Ref<PasswordInputRef>) {
    const { className, iconRender, placeholder = translation('EnterPassword'), ...otherProps } = props

    const [visible, setVisible] = useState<boolean>(false)

    const switchVisible = useCallback(() => {
        setVisible((v) => !v)
    }, [])

    const defaultIconRender = useCallback(() => {
        if (visible) {
            return <MonoIconCommonEyes16 />
        } else {
            return <MonoIconCommonEyesClose16 />
        }
    }, [visible])

    return (
        <NormalInput
            ref={ref}
            placeholder={placeholder}
            type={visible ? undefined : 'password'}
            className={classnames(classes.passwordInput, className)}
            backwardIcon={
                <span
                    className={classnames(classes.backwardIcon)}
                    onClick={switchVisible}
                    data-testid={props.dataTestIds?.eye}
                >
                    {iconRender ? iconRender(visible) : defaultIconRender()}
                </span>
            }
            {...otherProps}
        />
    )
}

export const PasswordInput = forwardRef(_PasswordInput)
