import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    ColorProfileSettings: '颜色配置文件',
    PreferredColorProfile: '默认颜色描述文件：',
    SRGBIsBest:
        '如果你的设计内容用于多个平台，建议使用 sRGB，这样可以尽可能保持多个平台的色彩统一性。但它的色域比 Display P3 小。',
    BestForApple: '如果你只对苹果设备进行设计，建议使用 Display P3，它的色域更广，但不建议用于网页端设计。',
    ThisPreferenceApplies: '这个偏好设置会对新创建的文件生效。',
    'YourDisplayCan’t': '你的显示器不支持',
    ButYouCan: '，但你仍然可以以这个颜色描述文件导出设计内容。',
    SupportSRGB: '你的 {{name}} 显示器支持 sRGB，你可以切换到该显示器使用 {{motiffName}}。',
    SupportP3: '你的 {{name}} 显示器支持 Display P3，你可以切换到该显示器使用 {{motiffName}}',
    YourDisplaySupports: '你的浏览器不支持',
    YourBrowserSupportsSRGB: '你的显示器支持 sRGB。',
    YourBrowserSupportsP3: '你的显示器支持 Display P3。',
    LearnMore: '了解更多',
} as const

export const enTranslation = {
    ColorProfileSettings: 'Color profile settings',
    PreferredColorProfile: 'Preferred color profile:',
    SRGBIsBest:
        'sRGB is ideal for designing across various devices, though it has a narrower color spectrum compared to Display P3.',
    BestForApple:
        'With its broader color spectrum, Display P3 is best suited for Apple devices but is not recommended for web design.',
    ThisPreferenceApplies: 'This preference applies to new Motiff files. ',
    'YourDisplayCan’t': 'Your display cannot support',
    ButYouCan: ', but you can still export using this profile. ',
    SupportSRGB: 'Switch to your {{name}} display for sRGB support. ',
    SupportP3: 'Switch to your {{name}} display to enable Display P3 support.',
    YourDisplaySupports: 'Your browser cannot support',
    YourBrowserSupportsSRGB: 'Your display supports sRGB.',
    YourBrowserSupportsP3: 'Your display supports Display P3.',
    LearnMore: 'Learn more',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
