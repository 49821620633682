import { SVGAttributes } from 'react'

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconStar = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.2749 8.86889L12 4.25952L9.72516 8.86889L4.63842 9.60804L8.31922 13.1959L7.4503 18.2621L12 15.8702L16.5497 18.2621L15.6808 13.1959L19.3616 9.60804L14.2749 8.86889ZM9.0611 7.95489L4.49462 8.61843C3.6744 8.73762 3.34689 9.74559 3.94041 10.3241L7.24474 13.5451L6.4647 18.0931C6.32459 18.91 7.18202 19.5329 7.91565 19.1473L12 17L16.0844 19.1473C16.818 19.5329 17.6755 18.91 17.5354 18.0931L16.7553 13.5451L20.0596 10.3241C20.6532 9.74559 20.3256 8.73762 19.5054 8.61843L14.939 7.95489L12.8968 3.81696C12.5299 3.07371 11.4701 3.07371 11.1033 3.81696L9.0611 7.95489Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconRecent = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12ZM5 12C5 10.067 5.68342 8.41709 7.05025 7.05025C8.41709 5.68342 10.067 5 12 5C13.933 5 15.5829 5.68342 16.9497 7.05025C18.3166 8.41709 19 10.067 19 12C19 13.933 18.3166 15.5829 16.9497 16.9497C15.5829 18.3166 13.933 19 12 19C10.067 19 8.41709 18.3166 7.05025 16.9497C5.68342 15.5829 5 13.933 5 12ZM11 8V12.5V13H11.5H16V12H12V8H11Z"
            fill="#1B1F26"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconDraft = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 4C5.44771 4 5 4.44771 5 5V19C5 19.5523 5.44771 20 6 20H18C18.5523 20 19 19.5523 19 19V9.41421C19 9.18545 18.8689 8.86887 18.7071 8.70711L14.2929 4.29289C14.1311 4.13113 13.8145 4 13.5858 4H6ZM13 5V9.3C13 9.76667 13.2333 10 13.7 10H18V19H6V5H13ZM17.5858 9H14V5.41421L17.5858 9Z"
            fill="#1B1F26"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconShare = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.7929 7.70711L15.0858 8H15.5H19V18H5V6H13.0858L14.7929 7.70711ZM13.7929 5.29289L15.5 7H19C19.5523 7 20 7.44772 20 8V18C20 18.5523 19.5523 19 19 19H5C4.44772 19 4 18.5523 4 18V6C4 5.44772 4.44772 5 5 5H13.0858C13.351 5 13.6054 5.10536 13.7929 5.29289ZM11.7071 9.5L11.3536 9.85356L10.7071 10.5H14.5H15V11.5H14.5H9.74142C9.20688 11.5 8.93918 10.8537 9.31716 10.4757L10.6464 9.14645L11 8.7929L11.7071 9.5ZM12.2929 14.5L12.6464 14.1465L13.2929 13.5H9.5H9V12.5H9.5H14.2586C14.7931 12.5 15.0608 13.1463 14.6828 13.5243L13.3536 14.8536L13 15.2071L12.2929 14.5Z"
            fill="#1B1F26"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconDelete = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 4H8V5H16V4ZM4 7H5.5H6.50295H17.497H18.5H20V8H18.4231L17.571 19.0767C17.5309 19.5977 17.0965 20 16.574 20H7.42603C6.90349 20 6.46905 19.5977 6.42898 19.0767L5.57692 8H4V7ZM6.57988 8H17.4201L16.574 19L7.42603 19L6.57988 8Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconAdd = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5 5H11.5V11.5H5V12.5H11.5V19H12.5V12.5H19V11.5H12.5V5Z"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconLoading24 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <path
            d="M12 4L12 5Q10.5421 5 9.31161 5.50967Q8.08117 6.01933 7.05025 7.05025Q6.01933 8.08117 5.50967 9.31161Q5 10.5421 5 12Q5 13.4579 5.50967 14.6884Q6.01933 15.9188 7.05025 16.9497Q8.08117 17.9807 9.31162 18.4903Q10.5421 19 12 19Q13.4579 19 14.6884 18.4903Q15.9188 17.9807 16.9497 16.9497Q17.9807 15.9188 18.4903 14.6884Q19 13.4579 19 12L20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4Z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconInfo = (props: SVGAttributes<SVGSVGElement>) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
            d="M12 2.99976C16.9706 2.99976 21 7.0293 21 11.9998C21 16.9702 16.9706 20.9998 12 20.9998C7.02942 20.9998 3 16.9702 3 11.9998C3 7.0293 7.02942 2.99976 12 2.99976ZM12.75 7.74976C12.75 8.16406 12.4142 8.49976 12 8.49976C11.5858 8.49976 11.25 8.16406 11.25 7.74976C11.25 7.33545 11.5858 6.99976 12 6.99976C12.4142 6.99976 12.75 7.33545 12.75 7.74976ZM12.5 9.99927L11.5 9.99927L11.5 16.9993L12.5 16.9993L12.5 9.99927Z"
            fillRule="evenodd"
            fill="#3071FF"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconFolder24 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
            transform="matrix(1 0 0 1 4.99997 6.00007)"
            d="M1.2 0C0.537258 0 0 0.537258 0 1.2L0 10.8C0 11.4627 0.537258 12 1.2 12L12.8 12C13.4627 12 14 11.4627 14 10.8L14 2.98738C14 2.32463 13.4627 1.78738 12.8 1.78738L6.82551 1.78738C6.45566 1.78738 6.10647 1.61683 5.87908 1.32513L5.20645 0.462248C4.97907 0.170549 4.62988 0 4.26003 0L1.2 0Z"
            fillRule="nonzero"
            fill="rgb(89, 147, 255)"
        />
        <defs>
            <mask id="mask0640936966" style={{ maskType: 'alpha' }}>
                <path
                    transform="matrix(1 0 0 1 4.99997 6.00007)"
                    d="M1.2 0C0.537258 0 0 0.537258 0 1.2L0 10.8C0 11.4627 0.537258 12 1.2 12L12.8 12C13.4627 12 14 11.4627 14 10.8L14 2.98738C14 2.32463 13.4627 1.78738 12.8 1.78738L6.82551 1.78738C6.45566 1.78738 6.10647 1.61683 5.87908 1.32513L5.20645 0.462248C4.97907 0.170549 4.62988 0 4.26003 0L1.2 0Z"
                    fillRule="nonzero"
                    fill="rgb(89, 147, 255)"
                />
            </mask>
        </defs>
        <g mask="url('#mask0640936966')">
            <defs>
                <linearGradient
                    id="linearGradient5517543740"
                    gradientTransform="matrix(0 0.71875 -0.71875 0 0.859375 0.09375)"
                >
                    <stop offset="0" stopColor="rgb(30, 81, 210)" stopOpacity="0" />
                    <stop offset="1" stopColor="rgb(30, 81, 210)" />
                </linearGradient>
            </defs>
            <path
                transform="matrix(1 0 0 1 4.99997 8.00007)"
                d="M0 0L14 0L14 4L0 4L0 0Z"
                fillRule="nonzero"
                fill="url('#linearGradient5517543740')"
                opacity="0.4"
            />
        </g>
        <path
            transform="matrix(1 0 0 1 4.99997 10.0001)"
            d="M0 1.2C0 0.537258 0.537258 0 1.2 0L12.8 0C13.4627 0 14 0.537258 14 1.2L14 6.8C14 7.46274 13.4627 8 12.8 8L1.2 8C0.537258 8 0 7.46274 0 6.8L0 1.2Z"
            fillRule="nonzero"
            fill="rgb(120, 172, 255)"
        />
        <defs>
            <mask id="mask5736230897" style={{ maskType: 'alpha' }}>
                <path
                    transform="matrix(1 0 0 1 4.99997 10.0001)"
                    d="M0 1.2C0 0.537258 0.537258 0 1.2 0L12.8 0C13.4627 0 14 0.537258 14 1.2L14 6.8C14 7.46274 13.4627 8 12.8 8L1.2 8C0.537258 8 0 7.46274 0 6.8L0 1.2Z"
                    fillRule="nonzero"
                    fill="rgb(120, 172, 255)"
                />
            </mask>
        </defs>
        <g mask="url('#mask5736230897')">
            <defs>
                <linearGradient id="linearGradient3268792539" gradientTransform="matrix(0 2.25 -2.25 0 1.625 -1.25)">
                    <stop offset="0" stopColor="rgb(255, 255, 255)" />
                    <stop offset="1" stopColor="rgb(255, 255, 255)" stopOpacity="0" />
                </linearGradient>
            </defs>
            <path
                transform="matrix(1 0 0 1 4.99997 10.0001)"
                d="M0 0L14 0L14 1L0 1L0 0Z"
                fillRule="nonzero"
                fill="url('#linearGradient3268792539')"
                opacity="0.5"
            />
            <defs>
                <linearGradient id="linearGradient5623076271" gradientTransform="matrix(0 1 -1 0 1 0)">
                    <stop offset="0" stopColor="rgb(48, 113, 255)" stopOpacity="0" />
                    <stop offset="1" stopColor="rgb(48, 113, 255)" />
                </linearGradient>
            </defs>
            <path
                transform="matrix(1 0 0 1 4.99997 16.0001)"
                d="M0 0L14 0L14 2L0 2L0 0Z"
                fillRule="nonzero"
                fill="url('#linearGradient5623076271')"
                opacity="0.5"
            />
        </g>
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconCheckBoxChecked24 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <path
            transform="matrix(1 0 0 1 7 8.25)"
            d="M0.883883 1.61612L4.63388 5.36612L3.75 6.25L2.86612 5.36612L9.11612 -0.883883L10.8839 0.883883L3.75 8.01777L-0.883883 3.38388L0.883883 1.61612Z"
            fillRule="nonzero"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconCheckBoxNoChecked24 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <path
            transform="matrix(1 0 0 1 7.625 7.625)"
            d="M4.375 2.60723L0.883883 -0.883883L-0.883883 0.883883L2.60723 4.375L-0.883883 7.86612L0.883883 9.63388L4.375 6.14277L7.86612 9.63388L9.63388 7.86612L6.14277 4.375L9.63388 0.883883L7.86612 -0.883883L4.375 2.60723Z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconBookBigOpen24 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <path
            transform="matrix(1 0 0 1 3.49937 5.04869)"
            d="M14.8416 0.418479C14.0753 0.0750679 13.2369 -0.0569862 12.3265 0.022316C11.4957 0.0946858 10.6981 0.332092 9.93352 0.734535C9.37409 1.02901 8.89423 1.37883 8.49392 1.78399C8.09416 1.37885 7.61564 1.02914 7.05835 0.734845C6.29591 0.332213 5.50054 0.0947072 4.67223 0.0223281C3.76446 -0.0569948 2.92775 0.0750825 2.16209 0.41856C1.30568 0.802747 0.610346 1.42232 0.076079 2.27727L0 2.54257L0 13.5655L0.860983 13.913C1.10666 13.6582 1.42903 13.4311 1.82808 13.2315C2.31959 12.9857 2.85359 12.8249 3.43008 12.7493C4.95981 12.5485 6.5602 12.9626 8.23126 13.9917L8.7563 13.9917C10.4275 12.9625 12.0302 12.5484 13.5645 12.7493C14.1428 12.825 14.6785 12.9858 15.1715 13.2317C15.572 13.4315 15.8949 13.6586 16.1403 13.913L17.0013 13.5655L17.0013 2.54257L16.9258 2.27833C16.3942 1.42288 15.6995 0.802926 14.8416 0.418479ZM13.6945 11.7565C12.8811 11.65 12.0452 11.6946 11.1866 11.8902C10.458 12.0563 9.72725 12.3285 8.99441 12.7071L8.99441 2.71945C9.35831 2.28851 9.8268 1.9222 10.3999 1.62054C11.0454 1.28074 11.7166 1.08049 12.4134 1.01979C13.1511 0.955533 13.824 1.05966 14.4321 1.33218C15.0646 1.61561 15.5872 2.06803 16 2.68943L16 12.5445C15.8791 12.4719 15.7519 12.4023 15.6184 12.3358C15.0252 12.0399 14.3839 11.8468 13.6945 11.7565ZM7.99316 2.71941C7.62918 2.28812 7.16173 1.92173 6.5908 1.62023C5.94761 1.28057 5.27903 1.08042 4.58507 1.01978C3.85024 0.955568 3.17918 1.05968 2.57191 1.3321C1.93999 1.61558 1.41644 2.06825 1.00125 2.69009L1.00125 12.5438C1.12142 12.4715 1.24774 12.4023 1.38021 12.336C1.97212 12.04 2.61197 11.8468 3.29977 11.7565C4.11133 11.65 4.94582 11.6946 5.80323 11.8902C6.5307 12.0563 7.26067 12.3285 7.99316 12.707L7.99316 2.71941Z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const Component24 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
            transform="matrix(1 0 0 1 3.21997 3.21997)"
            d="M8.07129 0.292969C8.46191 -0.0976562 9.09473 -0.0976562 9.48535 0.292969L12.1367 2.94434C12.5273 3.33496 12.5273 3.96777 12.1372 4.3584L9.48535 7.01074C9.09473 7.40039 8.46143 7.40137 8.0708 7.01074L5.41943 4.35938C5.02881 3.96875 5.02881 3.33496 5.41943 2.94531L8.07129 0.292969ZM8.77832 1L11.4297 3.65137L8.77783 6.30371L6.12646 3.65234L8.77832 1ZM14.6118 5.41895C14.2212 5.0293 13.5879 5.02832 13.1978 5.41895L10.5459 8.07031C10.1553 8.46094 10.1553 9.0957 10.5459 9.48535L13.1978 12.1367C13.5884 12.5273 14.2212 12.5273 14.6118 12.1367L17.2637 9.48535C17.6543 9.09473 17.6543 8.46191 17.2637 8.07129L14.6118 5.41895ZM4.35889 5.41992C3.96826 5.0293 3.33545 5.0293 2.94482 5.41992L0.292969 8.07129C-0.0976562 8.46191 -0.0976562 9.09473 0.292969 9.48535L2.94434 12.1367C3.33496 12.5273 3.96826 12.5273 4.35889 12.1367L7.01025 9.48535C7.40088 9.09473 7.40088 8.46191 7.01025 8.07129L4.35889 5.41992ZM16.5566 8.77832L13.9048 6.12598L11.2529 8.77734L13.9048 11.4297L16.5566 8.77832ZM6.30273 8.77832L3.65186 6.12695L1 8.77832L3.65137 11.4297L6.30273 8.77832ZM8.0708 10.5459C8.46143 10.1553 9.09473 10.1562 9.48535 10.5459L12.1372 13.1982C12.5278 13.5889 12.5273 14.2217 12.1367 14.6123L9.48535 17.2637C9.09473 17.6543 8.46191 17.6543 8.07129 17.2637L5.41943 14.6113C5.02881 14.2217 5.02881 13.5879 5.41943 13.1973L8.0708 10.5459ZM8.77783 11.2529L11.4297 13.9053L8.77832 16.5566L6.12646 13.9043L8.77783 11.2529Z"
            fillRule="evenodd"
            fill="rgb(26, 29, 37)"
        />
    </svg>
)
