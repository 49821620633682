/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { FC, useCallback, useMemo } from 'react'
import { LayerPanelItemExpand } from '../render-item-expand'
import { RenderItemBackground } from '../type'
import styles from './index.module.less'
import { MaskEnd, MaskEndSmall, MaskMiddle, MaskStart } from './svg'
import VLayerPanelMaskType = Wukong.DocumentProto.VLayerPanelMaskType
import VLayerPanelNodeExpandStauts = Wukong.DocumentProto.VLayerPanelNodeExpandStauts

import { NodeId } from '../../../../document/node/node'

interface LayerPanelItemIndentProps {
    indent: number
    maskTypes: VLayerPanelMaskType[]
    id: NodeId
    expandStatus: VLayerPanelNodeExpandStauts
    renderItemBackground: RenderItemBackground
}

export const LayerPanelItemIndent: FC<LayerPanelItemIndentProps> = ({
    indent,
    maskTypes,
    id,
    expandStatus,
    renderItemBackground,
}) => {
    // 渲染由于其他图层导致的连接型 Icon
    const getIndentMaskIcon = useCallback((type: VLayerPanelMaskType | undefined) => {
        if (type) {
            return <MaskMiddle />
        }
        return null
    }, [])

    // 渲染节点本身的 maskIcon
    const getMaskIcon = useCallback(
        (type: VLayerPanelMaskType) => {
            switch (type) {
                case VLayerPanelMaskType.V_LAYER_PANEL_MASK_TYPE_START: {
                    return <MaskStart />
                }
                case VLayerPanelMaskType.V_LAYER_PANEL_MASK_TYPE_MIDDLE: {
                    return <MaskMiddle />
                }
                case VLayerPanelMaskType.V_LAYER_PANEL_MASK_TYPE_END: {
                    return indent === 0 ? <MaskEndSmall /> : <MaskEnd />
                }
                case VLayerPanelMaskType.V_LAYER_PANEL_MASK_TYPE_NONE:
                default: {
                    return null
                }
            }
        },
        [indent]
    )

    return useMemo(
        () => (
            <>
                {/* 渲染占位或连接型 mask */}
                {Array(indent)
                    .fill('')
                    .map((_, index) => (
                        <div
                            key={index}
                            data-testid="layer-panel-item-indent"
                            className={classNames(
                                styles['render-item-indent'],
                                index !== 0 && styles['render-item-indent-large']
                            )}
                        >
                            {getIndentMaskIcon(maskTypes[index])}
                        </div>
                    ))}
                {/* 渲染 mask 和 expand */}
                <div
                    className={classNames(
                        styles['render-item-expand-and-mask'],
                        indent !== 0 && styles['render-item-expand-and-mask-large']
                    )}
                >
                    {getMaskIcon(maskTypes[maskTypes.length - 1])}
                    <LayerPanelItemExpand
                        id={id}
                        expandStatus={expandStatus}
                        renderItemBackground={renderItemBackground}
                    />
                </div>
            </>
        ),
        [indent, getMaskIcon, maskTypes, renderItemBackground, id, expandStatus, getIndentMaskIcon]
    )
}
