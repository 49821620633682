import { MonoIconCommonWarningLine16, Tooltip } from '../../../../../../ui-lib/src'
import { VariableSetModeConflictType, VariableSetModeState } from './interface'
import { translation } from './mode-conflict-icon.translation'

export const ModeConflictWarning = (props: { mode: VariableSetModeState }) => {
    const { mode } = props
    if (mode.conflictType == VariableSetModeConflictType.VARIABLE_SET_MODE_CONFLICT_TYPE_NONE) {
        return null
    }
    const title =
        mode.conflictType == VariableSetModeConflictType.VARIABLE_SET_MODE_CONFLICT_TYPE_UPDATE
            ? translation('PleaseUpdate', { ModeName: mode.variableSetMode.modeName })
            : translation('PleasePublish', { ModeName: mode.variableSetMode.modeName })

    return (
        <Tooltip title={title}>
            <MonoIconCommonWarningLine16 />
        </Tooltip>
    )
}
