/**
 * 复制自定义数据时，需要提供的数据结构
 */
export interface ClipboardDataItem {
    type: string
    content: string
}

export interface ClipboardDataFile {
    type: string
    name: string
    size: number
    buffer: Uint8Array
}

export interface ClipboardDataPromiseFile {
    type: string
    name: string
    buffer: Promise<Uint8Array>
}

export interface ClipboardDataProxyFile {
    type: string
    name: string
    size: number
    blobId: number
}

export enum WKClipboardErrorType {
    CopyImageLimitSize,
    ProhibitCopy,
    NavigatorApi,
}

export class WKClipboardError extends Error {
    constructor(public readonly type: WKClipboardErrorType, baseError: unknown) {
        super()

        if (baseError instanceof Error) {
            this.message = baseError.message
        }
    }
}
