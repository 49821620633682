import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    SourcedFromIconPark: '图标库来源于',
    IconparkIcon: '开源图标-来自 IconPark',
    Search: '搜索',
} as const

export const enTranslation = {
    SourcedFromIconPark: 'Sourced from',
    IconparkIcon: 'Iconpark Icon',
    Search: 'Search',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
