import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Stroke: '描边颜色',
    Background: '填充颜色',
    Outline: '外部描边颜色',
    Background_synonyms1: '外部填充颜色',
    InnerStroke: '内部描边颜色',
    InnerFill: '内部填充颜色',
} as const

export const enTranslation = {
    Stroke: 'Stroke',
    Background: 'Background',
    Outline: 'Outline',
    Background_synonyms1: 'Background',
    InnerStroke: 'Inner stroke',
    InnerFill: 'Inner fill',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
