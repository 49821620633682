import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    font: '字体',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    font: 'Font',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
