/* eslint-disable no-restricted-imports */
import { ScrollbarRef } from '../../../../ui-lib/src'
import { useEffect, useRef } from 'react'

export interface UseVirtualListContainerParams {
    onScroll: (scrollTop: number) => void
    onResize: (width: number, height: number) => void
    deps?: any[]
}

export function useVirtualListContainer({ onScroll, onResize, deps = [] }: UseVirtualListContainerParams) {
    const ref = useRef<ScrollbarRef>(null)

    useEffect(() => {
        const dom = ref.current?.getContainerElement()
        if (!dom) {
            return
        }

        const handleEvent = (event: Event) => {
            const target = event.target as HTMLDivElement | null
            if (!target) {
                return
            }
            onScroll(target.scrollTop)
        }

        setTimeout(() => {
            const target = ref.current
            if (target) {
                onScroll(target.getScrollTop())
            }
        })
        dom.addEventListener('scroll', handleEvent)
        return () => {
            dom.removeEventListener('scroll', handleEvent)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onScroll, ref.current, ...deps])

    useEffect(() => {
        const dom = ref.current?.getContainerElement()
        if (!dom) {
            return
        }

        const observer = new ResizeObserver((entries) => {
            if (entries.length === 0) {
                return
            }
            const entry = entries[0]
            const width = entry.contentRect.width
            const height = entry.contentRect.height
            onResize(width, height)
        })
        observer.observe(dom)
        const rect = dom.getBoundingClientRect()
        onResize(rect.width, rect.height)
        return () => {
            observer.disconnect()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onResize, ref.current, ...deps])

    return { ref }
}
