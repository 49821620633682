import {
    cmdGetDocumentNodeId,
    CommitUndo,
    CreateNodeFromSvgCommand,
    DocumentGetNodeCommand,
    MarkEvalJsBeginCommand,
    MarkEvalJsEndCommand,
    MethodSignature,
    PluginApiCombineAsVariants,
    PluginApiCreateComponentFromNode,
    PluginApiCreateImage,
    PluginApiCreatePage,
    PluginApiCreateShapeNode,
    PluginApiCreateStyleNode,
    PluginApiCurrentUser,
    PluginApiDropEventPickInsertionLocation,
    PluginApiExclude,
    PluginApiFlatten,
    PluginApiGetCurrentPage,
    PluginApiGetFileKey,
    PluginApiGetImageByHash,
    PluginApiGetLocalEffectStyles,
    PluginApiGetLocalGridStyles,
    PluginApiGetLocalPaintStyles,
    PluginApiGetLocalTextStyles,
    PluginApiGetSelectionColors,
    PluginApiGroup,
    PluginApiIntersect,
    PluginApiSetCurrentPage,
    PluginApiSubtract,
    PluginApiUngroup,
    PluginApiUnion,
    PluginCancelEventNotifierCommand,
    PluginRegisterEventNotifierCommand,
    UndoRedoCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { z, ZodIssue, ZodType } from 'zod'
import { IPluginHostService } from '../document/plugin-ui/plugin-host-service-interface'
import { IBasicBridge } from '../kernel/bridge/basic-bridge'
import { featureSwitchManager } from '../kernel/switch'
import { Motiff, PrototypePropMapper } from './mapper'
import { SceneNodePrototype } from './node-prototype/scene-node-prototype'
import { StyleNodePrototype } from './node-prototype/style-node-prototype'
import {
    DefineVmFunctionParams,
    DefineVmPropParams,
    EventName,
    IPluginAPIContext,
} from './plugin-api-context-interface'
import { enableEventDispatchV2 } from './plugin-event-dispatch'
import { typeStringToEnum } from './plugin-event-utils'
import { createClientStorageApi } from './sub-apis/client-storage'
import { ImageStore } from './sub-apis/image'
import { LegacyPromiseStore } from './sub-apis/legacy-external-promise'
import { createListAvailableFontsAsyncImpl } from './sub-apis/list-available-fonts'
import { createLoadFontImpl } from './sub-apis/load-font'
import { createNotifyApi } from './sub-apis/notify'
import { createImportLibNodeByKeyImpl } from './sub-apis/remote-library'
import { createSaveVersionHistoryApi } from './sub-apis/save-version-history'
import { createUIApi } from './sub-apis/ui-api'
import { createViewportApi } from './sub-apis/view-port'
import { BaseVM, Handle } from './vm-interface'
import { ZodTypes } from './zod-type'

// 1. 基础类型的 get/set
// 2. 复合类型的 get/set
// 3. 参数是基本类型的同步方法调用
// 4. 参数是复合类型的同步方法调用
// 5. 参数是基本类型的异步方法调用
// 6. 参数是复合类型的异步方法调用
// 7. 事件监听的基本框架

// ## 调用链路
// 1. 定义 descriptor：
// key: 'name'
// get: function () { const ret = wasmCall(); return vm.newString(ret) }
// set: function (valueHandle) { const value = vm.unwrapHandle(valueHandle); wasmCall(value) }

// 2. vm 创建prototype
// const rectProto = ...
// vm.defineProp(rectProto, 'name', { get: ... })

// 3. 创建实例和调用
// const rect = vm.newObject(rectProto)
// rect.name = 'rect'
// rect.name

function throwPluginApiError(errMsg: string): never {
    throw new Error(errMsg)
}

interface EventHandler {
    handler: Handle
    isOnce: boolean
    pageId?: string
}

export class PluginAPIContext implements IPluginAPIContext {
    sceneNodePrototype = new SceneNodePrototype(this)
    styleNodePrototype = new StyleNodePrototype(this)

    onMessageCallback: Handle | undefined
    eventHandlers = new Map<EventName, EventHandler[]>()
    eventHandlerTimeouts = new Map<EventName, any>()
    scheduledEvents = new Map<EventName, () => void>()
    mixedSymbol!: Handle
    skipInvisibleInstanceChildren = false
    imageStore!: ImageStore
    legacyPromiseStore!: LegacyPromiseStore

    constructor(public vm: BaseVM, private bridge: IBasicBridge, public hostService: IPluginHostService) {
        // 仅支持 page 类型
        const nodeChangeCallback = (
            documentChanges: {
                ownerPage?: string
                type: string
                origin: string
                id: string
                properties?: string[]
            }[]
        ) => {
            if (!featureSwitchManager.isEnabled('feat-owner-page-old-value-for-render-system')) {
                return
            }

            // group by ownerPage
            const groupedByOwnerPage = documentChanges.reduce(
                (acc, change) => {
                    if (change.ownerPage) {
                        acc[change.ownerPage] = [
                            ...(acc[change.ownerPage] || []),
                            change as {
                                ownerPage: string
                                type: string
                                origin: string
                                id: string
                                properties?: string[]
                            },
                        ]
                    }
                    return acc
                },
                {} as Record<
                    string,
                    {
                        ownerPage: string
                        type: string
                        origin: string
                        id: string
                        properties?: string[]
                    }[]
                >
            )

            for (const [ownerPageId, changes] of Object.entries(groupedByOwnerPage)) {
                const events = this.vm.newArray()

                for (let i = 0; i < changes.length; i++) {
                    const singleEvent = changes[i]

                    const event = this.vm.newObject()

                    this.vm.setProp(event, 'origin', this.vm.newString(singleEvent.origin))
                    this.vm.setProp(event, 'type', this.vm.newString(singleEvent.type))
                    this.vm.setProp(event, 'id', this.vm.newString(singleEvent.id))

                    switch (singleEvent.type) {
                        case 'PROPERTY_CHANGE': {
                            const properties = this.vm.newArray()
                            for (let j = 0; j < singleEvent.properties!.length; j++) {
                                const property = singleEvent.properties![j]
                                this.vm.setProp(properties, String(j), this.vm.newString(property))
                            }
                            this.vm.setProp(event, 'properties', properties)
                            this.vm.setProp(event, 'node', this.sceneNodePrototype.createNodeHandle(singleEvent.id))
                            break
                        }
                        case 'CREATE': {
                            this.vm.setProp(event, 'node', this.sceneNodePrototype.createNodeHandle(singleEvent.id))
                            break
                        }
                        case 'DELETE': {
                            const removedNode = this.vm.newObject()
                            this.vm.setProp(removedNode, 'removed', this.vm.newBoolean(true))
                            this.vm.setProp(removedNode, 'type', this.vm.newString(singleEvent.type))
                            this.vm.setProp(event, 'node', removedNode)
                            break
                        }
                    }

                    this.vm.setProp(events, String(i), event)
                }

                const nodeChanges = this.vm.newObject()
                this.vm.setProp(nodeChanges, 'nodeChanges', events)
                this.fireEventSyncForPage('nodechange', ownerPageId, [nodeChanges])
            }
        }

        const dropCallback = (event: DragEvent) => {
            if (!event.dataTransfer) {
                return true
            }

            const items: { type: string; data: string }[] = []

            for (const item of event.dataTransfer.items) {
                if (item.kind === 'string') {
                    item.getAsString((data) => {
                        items.push({
                            type: item.type,
                            data,
                        })
                    })
                }
            }

            const dropEvent = this.createDropEvent(
                { x: event.offsetX, y: event.offsetY },
                items,
                event.dataTransfer.files
            )

            return this.fireEventSyncWithReturn('drop', [dropEvent])
        }

        // 选区变更
        const selectionCallback = () => {
            this.fireEvent('selectionchange', [])
        }

        // currentPage 变更
        const pageCallback = () => {
            this.fireEvent('currentpagechange', [])
        }

        // 文档变更
        const documentChangeCallback = (
            documentChanges: {
                ownerPage?: string
                type: string
                origin: string
                id: string
                properties?: string[]
            }[]
        ) => {
            const events = this.vm.newArray()

            for (let i = 0; i < documentChanges.length; i++) {
                const singleEvent = documentChanges[i]

                const event = this.vm.newObject()

                this.vm.setProp(event, 'origin', this.vm.newString(singleEvent.origin))
                this.vm.setProp(event, 'type', this.vm.newString(singleEvent.type))
                this.vm.setProp(event, 'id', this.vm.newString(singleEvent.id))

                switch (singleEvent.type) {
                    case 'PROPERTY_CHANGE': {
                        const properties = this.vm.newArray()
                        for (let j = 0; j < singleEvent.properties!.length; j++) {
                            const property = singleEvent.properties![j]
                            this.vm.setProp(properties, String(j), this.vm.newString(property))
                        }
                        this.vm.setProp(event, 'properties', properties)
                        this.vm.setProp(event, 'node', this.sceneNodePrototype.createNodeHandle(singleEvent.id))
                        break
                    }
                    case 'CREATE': {
                        this.vm.setProp(event, 'node', this.sceneNodePrototype.createNodeHandle(singleEvent.id))
                        break
                    }
                    case 'DELETE': {
                        const removedNode = this.vm.newObject()
                        this.vm.setProp(removedNode, 'removed', this.vm.newBoolean(true))
                        this.vm.setProp(removedNode, 'type', this.vm.newString(singleEvent.type))
                        this.vm.setProp(event, 'node', removedNode)
                        break
                    }
                    case 'STYLE_PROPERTY_CHANGE': {
                        this.vm.setProp(event, 'style', this.styleNodePrototype.createNodeHandle(singleEvent.id))

                        const properties = this.vm.newArray()
                        for (let j = 0; j < singleEvent.properties!.length; j++) {
                            const property = singleEvent.properties![j]
                            this.vm.setProp(properties, String(j), this.vm.newString(property))
                        }

                        this.vm.setProp(event, 'properties', properties)
                        break
                    }
                    case 'STYLE_CREATE': {
                        this.vm.setProp(event, 'style', this.styleNodePrototype.createNodeHandle(singleEvent.id))
                        break
                    }
                    case 'STYLE_DELETE': {
                        this.vm.setProp(event, 'style', this.vm.null)
                        break
                    }
                }

                this.vm.setProp(events, String(i), event)
            }

            const documentChange = this.vm.newObject()
            this.vm.setProp(documentChange, 'documentChanges', events)

            this.fireEvent('documentchange', [documentChange])
        }

        this.hostService.registerDropEventCallback(dropCallback)

        enableEventDispatchV2(
            this.bridge,
            documentChangeCallback,
            nodeChangeCallback,
            selectionCallback,
            pageCallback,
            this.eventHandlers
        )
        this.imageStore = new ImageStore(bridge, this)
        this.legacyPromiseStore = new LegacyPromiseStore(bridge)
    }

    unwrapCallBridge<RET extends Wukong.DocumentProto.IReturnTypeWithError, ARG>(
        method: MethodSignature<RET, ARG>,
        arg?: ARG
    ): Omit<RET, 'error'> {
        const ret = this.bridge.call(method, arg)
        if (ret.error?.message) {
            throwPluginApiError(ret.error.message)
        }
        delete ret.error
        return ret
    }

    getNodeId(node: Handle): string {
        if (!this.vm.isObject(node)) {
            throw Error(`Expected node, got ${this.vm.isNull(node) ? 'null' : this.vm.typeof(node)}`)
        }
        const id = this.vm.getProp(node, 'id')
        if (!this.vm.isString(id)) {
            throw Error(`Expected node id to be a string, got ${this.vm.typeof(id)}`)
        }
        return this.vm.getString(id)
    }
    callBridge<RET, ARG>(method: MethodSignature<RET, ARG>, arg?: ARG): RET {
        return this.bridge.call(method, arg)
    }

    destroy() {
        this.bridge.call(MarkEvalJsBeginCommand)
        this.fireEvent('close', [])
        this.bridge.call(MarkEvalJsEndCommand)
        this.vm.destroy()
        this.eventHandlerTimeouts.forEach((timeoutId) => clearTimeout(timeoutId))
    }

    defineVmProp(param: DefineVmPropParams) {
        const self = this
        // 这里可以给 get/set 加一些业务上额外的逻辑，如统一的只读模式限制
        const get = param.get
            ? function () {
                  return param.get!.call(this)
              }
            : undefined
        const set = param.set
            ? function (value: Handle) {
                  if (!param.canUseInReadonly && self.hostService.isDocReadonly()) {
                      throwPluginApiError('cannot use in readonly mode')
                  }
                  param.set!.call(this, value)
              }
            : undefined
        this.vm.defineProp(param.objhandle, param.key, {
            get,
            set,
            value: param.value,
            enumerable: param.enumerable,
            writable: param.writable,
        })
    }

    defineVmFunction(param: DefineVmFunctionParams) {
        const self = this
        // 这里可以给 function 加一些业务上额外的逻辑，如统一的只读模式限制
        const wrappedFunc = function ($0: Handle, $1: Handle, $2: Handle, $3: Handle, $4: Handle) {
            if (!param.canUseInReadonly && self.hostService.isDocReadonly()) {
                throwPluginApiError('cannot use in readonly mode')
            }
            return param.func.call(this, $0, $1, $2, $3, $4)
        }
        this.vm.defineFunction(param.objhandle, param.key, wrappedFunc)
    }

    createMixedSymbol() {
        const evalResult = this.vm.evalCode('Symbol("mixed")')
        if (evalResult.type === 'FAILURE') {
            throw new Error('Failed to create mixed symbol')
        }
        this.mixedSymbol = evalResult.handle
        this.vm.retainHandle(this.mixedSymbol)
    }

    wrapFile(file: File) {
        const vm = this.vm

        const fileObject = this.vm.newObject()
        this.vm.setProp(fileObject, 'name', this.vm.newString(file.name))
        this.vm.setProp(fileObject, 'type', this.vm.newString(file.type))

        this.vm.defineFunction(fileObject, 'getBytesAsync', function () {
            const { promise, resolve, reject } = vm.newPromise()
            const reader = new FileReader()

            reader.onload = () => {
                vm.isDestroyed() || resolve(vm.deepWrapHandle(new Uint8Array(reader.result as ArrayBuffer)))
            }

            reader.onerror = () => {
                vm.isDestroyed() || reject(vm.deepWrapHandle(reader.error))
            }

            reader.readAsArrayBuffer(file)

            return promise
        })

        this.vm.defineFunction(fileObject, 'getTextAsync', function () {
            const { promise, resolve, reject } = vm.newPromise()
            const reader = new FileReader()

            reader.onload = () => {
                vm.isDestroyed() || resolve(vm.newString(reader.result as string))
            }

            reader.onerror = () => {
                vm.isDestroyed() || reject(vm.deepWrapHandle(reader.error))
            }

            reader.readAsText(file)

            return promise
        })

        return fileObject
    }

    createDropEvent(posInCamera: { x: number; y: number }, items: { type: string; data: string }[], files: FileList) {
        const pickInsertionLocation = (x: number, y: number) => {
            const ret = this.bridge.call(PluginApiDropEventPickInsertionLocation, {
                xInCamera: x,
                yInCamera: y,
            })
            return {
                parentId: ret.parentId,
                relativePos: {
                    x: ret.relativeX,
                    y: ret.relativeY,
                },
                absolutePos: {
                    x: ret.absoluteX,
                    y: ret.absoluteY,
                },
            }
        }

        const { parentId: nodeId, relativePos, absolutePos } = pickInsertionLocation(posInCamera.x, posInCamera.y)

        const data = {
            // 相对 parent 的坐标
            x: relativePos.x,
            y: relativePos.y,
            // 画布坐标系
            absoluteX: absolutePos.x,
            absoluteY: absolutePos.y,
            items: items,
        }

        const dropEvent = this.vm.deepWrapHandle(data)

        this.vm.setProp(dropEvent, 'node', this.sceneNodePrototype.createNodeHandle(nodeId as string))

        const filesArray = this.vm.newArray()

        for (let idx = 0; idx < files.length; idx++) {
            const file = files[idx]
            this.vm.setProp(filesArray, String(idx), this.wrapFile(file))
        }

        this.vm.setProp(dropEvent, 'files', filesArray)

        return dropEvent
    }

    createAPI() {
        this.sceneNodePrototype.createNodePrototypes()
        this.styleNodePrototype.createNodePrototypes()
        this.createMixedSymbol()

        const apiObject = this.vm.newObject()
        this.vm.setProp(this.vm.global, 'motiff', apiObject)

        this.defineVmProp({
            objhandle: apiObject,
            key: 'apiVersion',
            value: this.vm.newString('1.0.0'),
            enumerable: true,
            writable: false,
        })
        this.defineVmProp({
            objhandle: apiObject,
            key: 'mixed',
            value: this.mixedSymbol,
            enumerable: true,
            writable: false,
        })

        this.defineVmProp({
            objhandle: apiObject,
            key: 'editorType',
            get: () => {
                return this.vm.newString(this.hostService.getCurrentEditorType())
            },
        })

        this.defineVmProp({
            objhandle: apiObject,
            key: 'mode',
            get: () => {
                return this.vm.newString(this.hostService.getCurrentRunningMode())
            },
        })

        this.addEventHandlersTo(apiObject, [
            'run',
            'close',
            'documentchange',
            'selectionchange',
            'currentpagechange',
            'drop',
        ])

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'getNodeById',
            func: (nodeIdHandle: Handle) => {
                const nodeId = this.vm.getString(nodeIdHandle)
                const json = this.bridge.call(DocumentGetNodeCommand, {
                    currentNodeId: nodeId,
                    skipInvisibleInstanceChildren: this.skipInvisibleInstanceChildren,
                }).value
                if (!json) {
                    return this.vm.null
                }
                return this.sceneNodePrototype.createNodeHandle(nodeId)
            },
            canUseInReadonly: true,
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'getStyleById',
            func: (nodeIdHandle: Handle) => {
                const nodeId = this.vm.getString(nodeIdHandle)
                const json = this.bridge.call(DocumentGetNodeCommand, {
                    currentNodeId: nodeId,
                    skipInvisibleInstanceChildren: false,
                }).value
                if (!json) {
                    return this.vm.null
                }
                return this.styleNodePrototype.createNodeHandle(nodeId)
            },
            canUseInReadonly: true,
        })

        this.defineVmProp({
            objhandle: apiObject,
            key: 'clientStorage',
            value: createClientStorageApi(this),
            enumerable: false,
            writable: false,
        })

        this.defineVmProp({
            objhandle: apiObject,
            key: 'viewport',
            value: createViewportApi(this),
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'showUI',
            func: (html_, options_) => {
                const html = this.unwrapAndValidate({
                    handle: html_,
                    key: 'showUI',
                    type: z.string(),
                })
                let options = this.unwrapAndValidate({
                    handle: options_,
                    key: 'showUI options',
                    type: z
                        .strictObject({
                            visible: z.boolean(),
                            title: z.string(),
                            width: ZodTypes.FiniteNumber.min(0).int(),
                            height: ZodTypes.FiniteNumber.min(0).int(),
                            position: z.strictObject({
                                x: ZodTypes.FiniteNumber,
                                y: ZodTypes.FiniteNumber,
                            }),
                            themeColors: z.boolean(),
                        })
                        .partial()
                        .optional(),
                })

                if (!options) {
                    options = {
                        visible: true,
                    }
                }

                this.hostService.showUI(html, options)
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'closePlugin',
            func: (msg_: Handle) => {
                const msg = this.unwrapAndValidate({
                    handle: msg_,
                    key: 'closePlugin',
                    type: z.string().optional(),
                })
                this.hostService.closePlugin(msg)
            },
        })

        this.defineVmProp({
            objhandle: apiObject,
            key: 'ui',
            value: createUIApi(this),
            enumerable: false,
            writable: false,
        })

        this.defineVmProp({
            objhandle: apiObject,
            key: 'root',
            get: () => {
                const documentNodeId = this.bridge.call(cmdGetDocumentNodeId).value
                if (!documentNodeId) {
                    throwPluginApiError('root node does not existed')
                }
                return this.sceneNodePrototype.createNodeHandle(documentNodeId)
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'base64Encode',
            func: (data_: Handle) => {
                const data = this.unwrapAndValidate({
                    handle: data_,
                    key: 'base64Encode',
                    type: ZodTypes.UInt8Array,
                })
                return this.vm.newString(Buffer.from(data).toString('base64'))
            },
            canUseInReadonly: true,
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'base64Decode',
            func: (data_: Handle) => {
                const data = this.unwrapAndValidate({
                    handle: data_,
                    key: 'base64Decode',
                    type: z.string(),
                })
                return this.vm.newUint8Array(new Uint8Array(Buffer.from(data, 'base64')))
            },
            canUseInReadonly: true,
        })

        this.defineVmProp({
            objhandle: apiObject,
            key: 'currentPage',
            get: () => {
                const { nodeId } = this.bridge.call(PluginApiGetCurrentPage)
                return nodeId ? this.sceneNodePrototype.createNodeHandle(nodeId) : this.vm.null
            },
            set: (val) => {
                const nodeId = this.getNodeId(val)
                this.unwrapCallBridge(PluginApiSetCurrentPage, { nodeId })
            },
            canUseInReadonly: true,
        })

        this.defineVmProp({
            objhandle: apiObject,
            key: 'currentUser',
            get: () => {
                const toNearestHexInteger = (value: number) => Math.round(value).toString(16)

                const user = this.callBridge(PluginApiCurrentUser)
                const color = `#${toNearestHexInteger(user.color.r)}${toNearestHexInteger(
                    user.color.g
                )}${toNearestHexInteger(user.color.b)}`

                return this.vm.deepWrapHandle({
                    name: user.name,
                    photoUrl: user.photoUrl,
                    color: color,
                    sessionId: user.sessionId,
                    id: user.userId.toString(),
                })
            },
        })

        this.defineVmProp({
            objhandle: apiObject,
            key: 'skipInvisibleInstanceChildren',
            get: () => this.vm.newBoolean(this.skipInvisibleInstanceChildren),
            set: (value) => {
                this.skipInvisibleInstanceChildren = this.vm.getBoolean(value)
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'createFrame',
            func: (removeFrameFill_: Handle) => {
                const removeFrameFill = this.vm.getBoolean(removeFrameFill_)
                return this.sceneNodePrototype.createNodeHandle(
                    this.unwrapCallBridge(PluginApiCreateShapeNode, {
                        nodeType: Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME,
                        removeFrameFill: !!removeFrameFill,
                    }).value!
                )
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'createText',
            func: () => {
                return this.sceneNodePrototype.createNodeHandle(
                    this.unwrapCallBridge(PluginApiCreateShapeNode, {
                        nodeType: Wukong.DocumentProto.NodeType.NODE_TYPE_TEXT,
                        removeFrameFill: false,
                    }).value!
                )
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'createRectangle',
            func: () => {
                return this.sceneNodePrototype.createNodeHandle(
                    this.unwrapCallBridge(PluginApiCreateShapeNode, {
                        nodeType: Wukong.DocumentProto.NodeType.NODE_TYPE_RECTANGLE,
                        removeFrameFill: false,
                    }).value!
                )
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'createVector',
            func: () => {
                return this.sceneNodePrototype.createNodeHandle(
                    this.unwrapCallBridge(PluginApiCreateShapeNode, {
                        nodeType: Wukong.DocumentProto.NodeType.NODE_TYPE_VECTOR,
                        removeFrameFill: false,
                    }).value!
                )
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'createEllipse',
            func: () => {
                return this.sceneNodePrototype.createNodeHandle(
                    this.unwrapCallBridge(PluginApiCreateShapeNode, {
                        nodeType: Wukong.DocumentProto.NodeType.NODE_TYPE_ELLIPSE,
                        removeFrameFill: false,
                    }).value!
                )
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'createStar',
            func: () => {
                return this.sceneNodePrototype.createNodeHandle(
                    this.unwrapCallBridge(PluginApiCreateShapeNode, {
                        nodeType: Wukong.DocumentProto.NodeType.NODE_TYPE_STAR,
                        removeFrameFill: false,
                    }).value!
                )
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'createPolygon',
            func: () => {
                return this.sceneNodePrototype.createNodeHandle(
                    this.unwrapCallBridge(PluginApiCreateShapeNode, {
                        nodeType: Wukong.DocumentProto.NodeType.NODE_TYPE_POLYGON,
                        removeFrameFill: false,
                    }).value!
                )
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'createLine',
            func: () => {
                return this.sceneNodePrototype.createNodeHandle(
                    this.unwrapCallBridge(PluginApiCreateShapeNode, {
                        nodeType: Wukong.DocumentProto.NodeType.NODE_TYPE_LINE,
                        removeFrameFill: false,
                    }).value!
                )
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'createSlice',
            func: () => {
                return this.sceneNodePrototype.createNodeHandle(
                    this.unwrapCallBridge(PluginApiCreateShapeNode, {
                        nodeType: Wukong.DocumentProto.NodeType.NODE_TYPE_SLICE,
                        removeFrameFill: false,
                    }).value!
                )
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'createNodeFromSvg',
            func: (svgString_: Handle) => {
                const svgString = this.unwrapAndValidate({
                    handle: svgString_,
                    key: 'createNodeFromSvg',
                    type: ZodTypes.String,
                })
                return this.sceneNodePrototype.createNodeHandle(
                    this.unwrapCallBridge(CreateNodeFromSvgCommand, {
                        value: svgString,
                    }).value!
                )
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'createComponent',
            func: () => {
                return this.sceneNodePrototype.createNodeHandle(
                    this.unwrapCallBridge(PluginApiCreateShapeNode, {
                        nodeType: Wukong.DocumentProto.NodeType.NODE_TYPE_COMPONENT,
                        removeFrameFill: false,
                    }).value!
                )
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'createComponentFromNode',
            func: (node: Handle) => {
                const { nodeId } = this.unwrapCallBridge(PluginApiCreateComponentFromNode, {
                    nodeId: this.getNodeId(node),
                })
                return nodeId ? this.sceneNodePrototype.createNodeHandle(nodeId) : this.vm.null
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'createBooleanOperation',
            func: () => {
                return this.sceneNodePrototype.createNodeHandle(
                    this.unwrapCallBridge(PluginApiCreateShapeNode, {
                        nodeType: Wukong.DocumentProto.NodeType.NODE_TYPE_BOOL_OPERATION,
                        removeFrameFill: false,
                    }).value!
                )
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'createPage',
            func: () => {
                return this.sceneNodePrototype.createNodeHandle(this.unwrapCallBridge(PluginApiCreatePage).value!)
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'createPaintStyle',
            func: () => {
                return this.styleNodePrototype.createNodeHandle(
                    this.unwrapCallBridge(PluginApiCreateStyleNode, {
                        nodeType: Wukong.DocumentProto.NodeType.NODE_TYPE_PAINT_STYLE,
                    }).value!
                )
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'createTextStyle',
            func: () => {
                return this.styleNodePrototype.createNodeHandle(
                    this.unwrapCallBridge(PluginApiCreateStyleNode, {
                        nodeType: Wukong.DocumentProto.NodeType.NODE_TYPE_TEXT_STYLE,
                    }).value!
                )
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'createGridStyle',
            func: () => {
                return this.styleNodePrototype.createNodeHandle(
                    this.unwrapCallBridge(PluginApiCreateStyleNode, {
                        nodeType: Wukong.DocumentProto.NodeType.NODE_TYPE_LAYOUT_GRID_STYLE,
                    }).value!
                )
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'createEffectStyle',
            func: () => {
                return this.styleNodePrototype.createNodeHandle(
                    this.unwrapCallBridge(PluginApiCreateStyleNode, {
                        nodeType: Wukong.DocumentProto.NodeType.NODE_TYPE_EFFECT_STYLE,
                    }).value!
                )
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'triggerUndo',
            func: () => {
                this.callBridge(
                    UndoRedoCommand,
                    Wukong.DocumentProto.UndoRedoCommandParam.create({
                        metaKey: true,
                        ctrlKey: false,
                        shiftKey: false,
                        altKey: false,
                    })
                )
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'triggerRedo',
            func: () => {
                this.callBridge(UndoRedoCommand, {
                    metaKey: true,
                    ctrlKey: false,
                    shiftKey: true,
                    altKey: false,
                })
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'commitUndo',
            func: () => {
                this.callBridge(CommitUndo)
            },
        })

        this.defineVmProp({
            objhandle: apiObject,
            key: 'fileKey',
            get: () => {
                return this.vm.newString(this.unwrapCallBridge(PluginApiGetFileKey).fileKey)
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'getLocalGridStyles',
            func: () => {
                const ids = this.unwrapCallBridge(PluginApiGetLocalGridStyles).value!
                const ret = this.vm.newArray()
                for (let i = 0; i < ids.length; i++) {
                    this.vm.setProp(ret, i.toString(), this.styleNodePrototype.createNodeHandle(ids[i]))
                }
                return ret
            },
            canUseInReadonly: true,
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'getLocalGridStylesAsync',
            func: () => {
                const { promise, resolve } = this.vm.newPromise()
                const ids = this.unwrapCallBridge(PluginApiGetLocalGridStyles).value!
                const ret = this.vm.newArray()
                for (let i = 0; i < ids.length; i++) {
                    this.vm.setProp(ret, i.toString(), this.styleNodePrototype.createNodeHandle(ids[i]))
                }
                resolve(ret)
                return promise
            },
            canUseInReadonly: true,
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'getLocalPaintStyles',
            func: () => {
                const ids = this.unwrapCallBridge(PluginApiGetLocalPaintStyles).ids
                const ret = this.vm.newArray()
                for (let i = 0; i < ids.length; i++) {
                    this.vm.setProp(ret, i.toString(), this.styleNodePrototype.createNodeHandle(ids[i]))
                }
                return ret
            },
            canUseInReadonly: true,
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'getLocalPaintStylesAsync',
            func: () => {
                const { promise, resolve } = this.vm.newPromise()
                const ids = this.unwrapCallBridge(PluginApiGetLocalPaintStyles).ids
                const ret = this.vm.newArray()
                for (let i = 0; i < ids.length; i++) {
                    this.vm.setProp(ret, i.toString(), this.styleNodePrototype.createNodeHandle(ids[i]))
                }
                resolve(ret)
                return promise
            },
            canUseInReadonly: true,
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'getLocalTextStyles',
            func: () => {
                const ids = this.unwrapCallBridge(PluginApiGetLocalTextStyles).ids
                const ret = this.vm.newArray()
                for (let i = 0; i < ids.length; i++) {
                    this.vm.setProp(ret, i.toString(), this.styleNodePrototype.createNodeHandle(ids[i]))
                }
                return ret
            },
            canUseInReadonly: true,
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'getLocalTextStylesAsync',
            func: () => {
                const { promise, resolve } = this.vm.newPromise()
                const ids = this.unwrapCallBridge(PluginApiGetLocalTextStyles).ids
                const ret = this.vm.newArray()
                for (let i = 0; i < ids.length; i++) {
                    this.vm.setProp(ret, i.toString(), this.styleNodePrototype.createNodeHandle(ids[i]))
                }
                resolve(ret)
                return promise
            },
            canUseInReadonly: true,
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'getLocalEffectStyles',
            func: () => {
                const ids = this.unwrapCallBridge(PluginApiGetLocalEffectStyles).ids
                const ret = this.vm.newArray()
                for (let i = 0; i < ids.length; i++) {
                    this.vm.setProp(ret, i.toString(), this.styleNodePrototype.createNodeHandle(ids[i]))
                }
                return ret
            },
            canUseInReadonly: true,
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'getLocalEffectStylesAsync',
            func: () => {
                const { promise, resolve } = this.vm.newPromise()
                const ids = this.unwrapCallBridge(PluginApiGetLocalEffectStyles).ids
                const ret = this.vm.newArray()
                for (let i = 0; i < ids.length; i++) {
                    this.vm.setProp(ret, i.toString(), this.styleNodePrototype.createNodeHandle(ids[i]))
                }
                resolve(ret)
                return promise
            },
            canUseInReadonly: true,
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'getSelectionColors',
            func: () => {
                const { paints, styleIds, emptyValue } = this.unwrapCallBridge(PluginApiGetSelectionColors)

                if (!emptyValue) {
                    const ret = this.vm.newObject()
                    this.vm.setProp(
                        ret,
                        'paints',
                        this.vm.deepWrapHandle(PrototypePropMapper.Paints.fromMotiff(paints as Motiff.Paint[]))
                    )
                    const styles = this.vm.newArray()
                    for (let i = 0; i < styleIds.length; i++) {
                        this.vm.setProp(styles, i.toString(), this.styleNodePrototype.createNodeHandle(styleIds[i]))
                    }
                    this.vm.setProp(ret, 'styles', styles)
                    return ret
                } else {
                    return this.vm.null
                }
            },
            canUseInReadonly: true,
        })

        const getGroupLikeOpsParam = (nodes_: Handle, parent_: Handle, insertIndex_: Handle, key: string) => {
            if (!this.vm.isArray(nodes_)) {
                throw new Error(`First argument to ${key}() must be an array`)
            }
            const length = this.vm.getNumberValue(nodes_, 'length')
            if (length < 1) {
                throw Error(`First argument to ${key}() must be an array of at least one node`)
            }
            if (this.vm.isUndefined(parent_)) {
                throw Error(`Second argument to ${key}() must be provided`)
            }
            const nodeIds = []
            for (let i = 0; i < length; i++) {
                nodeIds.push(this.getNodeId(this.vm.getProp(nodes_, i.toString())))
            }
            const parentId = this.getNodeId(parent_)
            let insertIndex: number | undefined = undefined
            if (!this.vm.isUndefined(insertIndex_)) {
                insertIndex = this.unwrapAndValidate({
                    handle: insertIndex_,
                    key: 'insertIndex',
                    type: ZodTypes.PositiveInteger,
                })
            }
            return { nodeIds, parentId, insertIndex }
        }

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'group',
            func: (nodes_: Handle, parent_: Handle, insertIndex_: Handle) => {
                const { nodeIds, parentId, insertIndex } = getGroupLikeOpsParam(nodes_, parent_, insertIndex_, 'group')
                const ret = this.sceneNodePrototype.createNodeHandle(
                    this.unwrapCallBridge(PluginApiGroup, {
                        nodeIds,
                        parentId,
                        insertIndex,
                    }).value!
                )
                return ret
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'ungroup',
            func: (node: Handle) => {
                if (this.vm.isUndefined(node)) {
                    throw Error(`Parent must be provided to ungroup()`)
                }
                const nodeId = this.getNodeId(node)
                const ids = this.unwrapCallBridge(PluginApiUngroup, {
                    nodeId,
                }).ids!
                const ret = this.vm.newArray()
                for (let i = 0; i < ids.length; i++) {
                    this.vm.setProp(ret, i.toString(), this.sceneNodePrototype.createNodeHandle(ids[i]))
                }
                return ret
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'flatten',
            func: (nodes_: Handle, parent_: Handle, insertIndex_: Handle) => {
                const { nodeIds, parentId, insertIndex } = getGroupLikeOpsParam(
                    nodes_,
                    parent_,
                    insertIndex_,
                    'flatten'
                )
                const ret = this.sceneNodePrototype.createNodeHandle(
                    this.unwrapCallBridge(PluginApiFlatten, {
                        nodeIds,
                        parentId,
                        insertIndex,
                    }).value!
                )
                return ret
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'union',
            func: (nodes_: Handle, parent_: Handle, insertIndex_: Handle) => {
                const { nodeIds, parentId, insertIndex } = getGroupLikeOpsParam(nodes_, parent_, insertIndex_, 'union')
                const ret = this.sceneNodePrototype.createNodeHandle(
                    this.unwrapCallBridge(PluginApiUnion, {
                        nodeIds,
                        parentId,
                        insertIndex,
                    }).value!
                )
                return ret
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'subtract',
            func: (nodes_: Handle, parent_: Handle, insertIndex_: Handle) => {
                const { nodeIds, parentId, insertIndex } = getGroupLikeOpsParam(
                    nodes_,
                    parent_,
                    insertIndex_,
                    'subtract'
                )
                const ret = this.sceneNodePrototype.createNodeHandle(
                    this.unwrapCallBridge(PluginApiSubtract, {
                        nodeIds,
                        parentId,
                        insertIndex,
                    }).value!
                )
                return ret
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'intersect',
            func: (nodes_: Handle, parent_: Handle, insertIndex_: Handle) => {
                const { nodeIds, parentId, insertIndex } = getGroupLikeOpsParam(
                    nodes_,
                    parent_,
                    insertIndex_,
                    'intersect'
                )
                const ret = this.sceneNodePrototype.createNodeHandle(
                    this.unwrapCallBridge(PluginApiIntersect, {
                        nodeIds,
                        parentId,
                        insertIndex,
                    }).value!
                )
                return ret
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'exclude',
            func: (nodes_: Handle, parent_: Handle, insertIndex_: Handle) => {
                const { nodeIds, parentId, insertIndex } = getGroupLikeOpsParam(
                    nodes_,
                    parent_,
                    insertIndex_,
                    'exclude'
                )
                const ret = this.sceneNodePrototype.createNodeHandle(
                    this.unwrapCallBridge(PluginApiExclude, {
                        nodeIds,
                        parentId,
                        insertIndex,
                    }).value!
                )
                return ret
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'combineAsVariants',
            func: (nodes_: Handle, parent_: Handle, insertIndex_: Handle) => {
                const { nodeIds, parentId, insertIndex } = getGroupLikeOpsParam(
                    nodes_,
                    parent_,
                    insertIndex_,
                    'combineAsVariants'
                )
                const ret = this.sceneNodePrototype.createNodeHandle(
                    this.unwrapCallBridge(PluginApiCombineAsVariants, {
                        nodeIds,
                        parentId,
                        insertIndex,
                    }).value!
                )
                return ret
            },
        })

        const { listAvailableFontsAsync } = createListAvailableFontsAsyncImpl(this.bridge, this.vm)
        this.defineVmFunction({
            objhandle: apiObject,
            key: 'loadAllPagesAsync',
            func: () => {
                const { promise, resolve } = this.vm.newPromise()
                resolve(this.vm.undefined)
                return promise
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'listAvailableFontsAsync',
            func: listAvailableFontsAsync,
        })

        const { loadFontAsync } = createLoadFontImpl(this.bridge, this)

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'loadFontAsync',
            func: loadFontAsync,
        })

        createSaveVersionHistoryApi(apiObject, this.bridge, this)

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'createImage',
            func: (blob_: Handle) => {
                const blob = this.unwrapAndValidate({
                    handle: blob_,
                    type: ZodTypes.UInt8Array,
                    key: 'createImage',
                })
                const hash = this.unwrapCallBridge(PluginApiCreateImage, {
                    value: blob,
                }).hash
                return this.imageStore.createImageHandle(hash)
            },
        })

        this.defineVmFunction({
            objhandle: apiObject,
            key: 'getImageByHash',
            func: (hash_: Handle) => {
                const hash = this.unwrapAndValidate({
                    handle: hash_,
                    type: ZodTypes.String,
                    key: 'getImageByHash',
                })
                const exist = this.unwrapCallBridge(PluginApiGetImageByHash, {
                    value: hash,
                }).exist
                if (exist) {
                    return this.imageStore.createImageHandle(hash)
                } else {
                    return this.vm.null
                }
            },
        })

        createImportLibNodeByKeyImpl(apiObject, this.bridge, this)
        createNotifyApi(apiObject, this.bridge, this)

        this.fireRunEvent()
    }

    formatIssue(issue: ZodIssue, indent = 0) {
        const pathString = issue.path.map((p) => (typeof p === 'number' ? `[${p}]` : `.${p}`)).join('')
        const indentSpace = ' '.repeat(indent)

        if (issue.code === 'invalid_union') {
            const unionIssues = issue.unionErrors
                .reduce((acc, unionError) => {
                    const hasMultipleIssues = unionError.issues.length > 1
                    const unionIndent = indent + (hasMultipleIssues ? 4 : 2)
                    const unionMessage = unionError.issues.map((err) => this.formatIssue(err, unionIndent)).join('\n')
                    const message = hasMultipleIssues
                        ? `${indentSpace}Multiple issues${
                              issue.path.length > 0 ? ' at ' + pathString : ''
                          }:\n${unionMessage}`
                        : unionMessage
                    if (!acc.includes(message as never)) acc.push(message as never)
                    return acc
                }, [])
                .join('\n')

            return `${indentSpace}Expected ${
                issue.path.length > 0 ? pathString + ' to be ' : ''
            }one of the following, but none matched:\n${unionIssues}`
        }

        const message = `${indentSpace}${issue.message === 'Required' ? 'Required value missing' : issue.message}`
        if (issue.path.length > 0) {
            if (issue.path.length === 1 && typeof issue.path[0] === 'number') {
                return `${message} at index ${issue.path[0]}`
            }
            return `${message} at ${pathString}`
        }
        return message
    }

    unwrapAndValidate<T>({ handle, type, key }: { handle: Handle; type: ZodType<T>; key: string }) {
        const value = this.vm.deepUnWrapHandle(handle) as T

        const validateResult = type.safeParse(value)

        if (validateResult.success) {
            return validateResult.data
        }

        const formattedIssues = validateResult.error.issues.map((issue) => this.formatIssue(issue)).join('\n')
        throw Error(`Property "${key}" failed validation:\n\n${formattedIssues}`)
    }

    addEventHandler = (node: Handle, eventName: EventName, handler: Handle, isOnce: boolean) => {
        if (!this.vm.isFunction(handler)) {
            throw new Error(`${eventName} handler must be a function`)
        }

        const handlers = this.eventHandlers.get(eventName) || []
        const isFirst = handlers.length === 0

        if (isFirst) {
            // set wasm 内的 flag, 告知 wasm 需要收集的事件类型
            if (['documentchange', 'selectionchange', 'currentpagechange', 'drop', 'nodechange'].includes(eventName)) {
                this.bridge.call(
                    PluginRegisterEventNotifierCommand,
                    Wukong.DocumentProto.Arg_MonitorEvents.create({
                        event: [typeStringToEnum[eventName]],
                    })
                )
            }
        }

        handlers.push({
            handler,
            isOnce,
            pageId: eventName === 'nodechange' ? this.getNodeId(node) : undefined,
        })
        this.eventHandlers.set(eventName, handlers)
        this.vm.retainHandle(handler)

        const scheduledEvent = this.scheduledEvents.get(eventName)
        if (scheduledEvent) {
            scheduledEvent()
        }
    }

    removeEventHandler = (eventName: EventName, handler: Handle) => {
        const handlers = this.eventHandlers.get(eventName)
        if (!handlers) return

        const handlerIndex = handlers.findIndex((currentHandler) => this.vm.isEqual(currentHandler.handler, handler))

        if (handlerIndex !== -1) {
            handlers.splice(handlerIndex, 1)
            this.vm.releaseHandle(handler)

            if (handlers.length === 0) {
                // do cleanup listeners

                // unset wasm 内的 flag, 告知 wasm 无需收集特定类型的事件
                this.bridge.call(
                    PluginCancelEventNotifierCommand,
                    Wukong.DocumentProto.Arg_MonitorEvents.create({
                        event: [typeStringToEnum[eventName]],
                    })
                )
            }
        }
    }

    addEventHandlersTo(targetHandle: Handle, eventNames: EventName[]) {
        const addEventHanlder = this.addEventHandler
        const removeEventHanlder = this.removeEventHandler
        const vm = this.vm

        const validateEventName = (eventName: Handle) => {
            return this.unwrapAndValidate({
                handle: eventName,
                key: 'eventName',
                type: z.string().refine((name) => eventNames.includes(name as EventName)),
            }) as EventName
        }

        this.defineVmFunction({
            objhandle: targetHandle,
            key: 'on',
            func: function (eventName: Handle, handler: Handle) {
                addEventHanlder(this, validateEventName(eventName), handler, false)
                return vm.undefined
            },
        })

        this.defineVmFunction({
            objhandle: targetHandle,
            key: 'once',
            func: function (eventName: Handle, handler: Handle) {
                addEventHanlder(this, validateEventName(eventName), handler, true)
                return vm.undefined
            },
        })

        this.defineVmFunction({
            objhandle: targetHandle,
            key: 'off',
            func: function (eventName: Handle, handler: Handle) {
                removeEventHanlder(validateEventName(eventName), handler)
                return vm.undefined
            },
        })
    }

    fireEventSyncForPage(eventName: EventName, targetPageId: string, params: Handle[]) {
        const handlers = this.eventHandlers.get(eventName)

        const pageHandlers = handlers ? handlers.filter((handler) => handler.pageId === targetPageId) : []

        if (handlers && pageHandlers.length > 0) {
            this.eventHandlers.set(
                eventName,
                handlers.filter(({ isOnce, pageId }) => {
                    return !(isOnce && pageId === targetPageId)
                })
            )
        }

        for (const handler of pageHandlers) {
            this.vm.callFunction(handler.handler, this.vm.undefined, ...params)
        }
    }

    // 执行具有返回值的回调
    fireEventSyncWithReturn(eventName: EventName, params: Handle[]) {
        const vm = this.vm
        let handlers = this.eventHandlers.get(eventName)

        // Remove one-time handlers if we have any handlers
        if (handlers) {
            this.eventHandlers.set(
                eventName,
                handlers.filter(({ isOnce }) => !isOnce)
            )
        } else {
            handlers = []
        }

        // Call each handler and check return value
        for (const handler of handlers) {
            const result = vm.callFunction(handler.handler, this.vm.undefined, ...params)

            // Return false if handler returns false
            if (result.type === 'SUCCESS' && vm.deepUnWrapHandle(result.handle) === false) {
                return false
            }
        }

        return true
    }

    fireEvent(eventName: EventName, params: Handle[]) {
        let handlers = this.eventHandlers.get(eventName)

        // If handlers exist, filter out one-time handlers
        if (handlers) {
            this.eventHandlers.set(
                eventName,
                handlers.filter(({ isOnce }) => !isOnce)
            )
        } else {
            handlers = []
        }

        // Call each handler with the event arguments
        for (const handler of handlers) {
            this.vm.callFunction(handler.handler, this.vm.undefined, ...params)
        }
    }

    delayCallback(callback: () => void) {
        Promise.resolve().then(callback)
    }

    debounceCallback(eventName: EventName, callback: () => void) {
        if (this.eventHandlerTimeouts.has(eventName)) {
            clearTimeout(this.eventHandlerTimeouts.get(eventName))
        }

        const timeoutId = setTimeout(() => {
            this.eventHandlerTimeouts.delete(eventName)
            callback()
        }, 0)

        this.eventHandlerTimeouts.set(eventName, timeoutId)
    }

    fireAsyncOrSchedule(eventName: EventName, callback: () => void) {
        const handlers = this.eventHandlers.get(eventName)
        const hasHandlers = handlers && handlers.length > 0

        if (hasHandlers) {
            this.delayCallback(callback)
        } else {
            this.scheduledEvents.set(eventName, () => {
                this.scheduledEvents.delete(eventName)
                this.delayCallback(callback)
            })
        }
    }

    fireRunEvent() {
        this.fireAsyncOrSchedule('run', () => {
            const runParam = this.vm.newObject()
            this.vm.setProp(runParam, 'command', this.vm.newString(''))
            this.bridge.call(MarkEvalJsBeginCommand)
            this.fireEvent('run', [runParam])
            this.bridge.call(MarkEvalJsEndCommand)
        })
    }

    onIframeMessage(msg: MessageEvent) {
        if ('pluginMessage' in msg.data) {
            const messageHandlers = this.eventHandlers.get('message')
            if ((undefined === messageHandlers || !messageHandlers.length) && !this.onMessageCallback) {
                console.warn('Message from UI to plugin dropped due to no message handler installed')
                return
            }

            const messageArgs = [this.vm.deepWrapHandle(msg.data.pluginMessage), this.vm.deepWrapHandle({ origin })]

            this.bridge.call(MarkEvalJsBeginCommand)

            this.fireEvent('message', messageArgs)

            if (this.onMessageCallback) {
                this.vm.callFunction(this.onMessageCallback, this.vm.undefined, ...messageArgs)
            }

            this.bridge.call(MarkEvalJsEndCommand)
            return
        }

        // 处理并分发插件构造的 drop 事件
        if ('pluginDrop' in msg.data) {
            const pluginDrop = msg.data.pluginDrop

            if (pluginDrop.clientX === undefined || pluginDrop.clientY === undefined) {
                console.warn('"clientX" and "clientY" fields are required')
                return
            }

            if (pluginDrop.files !== undefined && !(pluginDrop.files instanceof Array)) {
                console.warn('"files" field must be an array')
                return
            }

            if (pluginDrop.files.some((e: any) => !(e instanceof File))) {
                console.warn('"files" field must be an array of File')
                return
            }

            if (pluginDrop.items !== undefined && !(pluginDrop.items instanceof Array)) {
                console.warn('"items" field must be an array')
                return
            }

            if (
                pluginDrop.items?.some((item: any) => !('type' in item) || typeof item.type !== 'string') ||
                pluginDrop.items?.some((item: any) => !('data' in item) || typeof item.data !== 'string')
            ) {
                console.warn('"items" field must be an array of objects with "type" and "data" string fields')
                return
            }

            let offsetX = 0
            let offsetY = 0
            const canvasOverlay = document.getElementById('WKC-Overlay')
            if (canvasOverlay) {
                const rect = canvasOverlay.getBoundingClientRect()
                offsetX = -rect.left
                offsetY = -rect.top
            }

            const dropEvent = this.createDropEvent(
                // pluginDrop 的坐标应是相对于浏览器 viewport 的左上角
                {
                    x: pluginDrop.clientX + offsetX,
                    y: pluginDrop.clientY + offsetY,
                },
                pluginDrop.items,
                pluginDrop.files
            )

            this.vm.setProp(dropEvent, 'dropMetadata', this.vm.deepWrapHandle(pluginDrop.dropMetadata))

            this.bridge.call(MarkEvalJsBeginCommand)
            this.fireEvent('drop', [dropEvent])
            this.bridge.call(MarkEvalJsEndCommand)
            return
        }
    }

    createNodeHandle(id: string): Handle {
        return this.sceneNodePrototype.createNodeHandle(id)
    }

    createStyleNodeHandle(id: string): Handle {
        return this.styleNodePrototype.createNodeHandle(id)
    }

    evalCode(code: string) {
        this.bridge.call(MarkEvalJsBeginCommand)
        this.vm.evalCode(code)
        this.bridge.call(MarkEvalJsEndCommand)
    }
}
