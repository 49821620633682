import { FeatureSwitchConfig, SwitchEnvironmentScopeType, SwitchStrategyType } from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'organization-plus',
    owner: '',
    description: '',
    strategies: [
        {
            env: SwitchEnvironmentScopeType.TESTING,
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                userList: [
                    '96a0470e759201f48ceb2d2f9f134df24a508b59',
                    'cff50cb5a26acb11aea4dac603e98ca5f6d6a213',
                    '37b4208d9301d2607fcb9a638988a7b569fa92f5',
                    '3d2cad017486e6a0b24792d50788fa3359bbc6f5',
                    '4d0c1e32c00069c6a39541d2dcf2a0d7f7322487',
                    'f60e805d8ac53117369ef63077dbd96b097f6159',
                    '815c1bc60a12c15d19e320526962b4a6281d3342',
                    '413067bae9fad3ea29bcb5221f0eb4b0c87f95dd',
                    '9d6bf699ed20fc7bfe83d4b392dae8722b702339',
                    '216a0a529993e408ddd1a7b86fb0865297b9f9fd',
                    '2ce4665f28bf253877c3c2193651c43cab63066a',
                    '34b4d9c9a8a2bbfcd9569427b41fce53520220ac',
                    'c46b469e77fe2ac0f7daaaaf3d83f0ed5f53401c',
                    'c2520912ef296753265cdcdcf7564f496c39c41a',
                    '2dc4a7dcfb76e9fd291a7a626e4f5d8ad267b68b',
                    'f539551df4043ef63503ecdd6ccedadc98ca8787',
                    '4681b6ae7bd6d904b13696c5ea3b6cbd6e1b65a0',
                    '140fee94d148f6c4d273d93ee476610d40691215',
                    '6e0780c20a8eba8a9eb9d5fac8b7d3347f17deee',
                    'b370615e1b86232d5cc7ff9415df15b2c8df3b81',
                    'bd82a1a7ea79e13ebe3064b941d6be3246f69707',
                    'e526a6e6c506c08c65fa39d76a5530e7e337623d',
                ],
            },
        },
    ],
    alwaysDisableInTests: true,
}
export default Config
