import React, { HTMLAttributes, useCallback, useLayoutEffect, useRef, useState } from 'react'
import { usePointerCapture } from '../../../../../../ui-lib/src'
import { CommitType } from '../../../../document/command/commit-type'
import { useCommand } from '../../../context/document-context'
import { InputOptionsForUndoSquash } from '../inputs/components/formatted-input'
import style from './range-area.module.less'

interface HandleMoveType {
    rateX: number
    rateY: number
}
export interface RangeAreaProps
    extends Omit<HTMLAttributes<HTMLDivElement>, 'onPointerDown' | 'onPointerMove' | 'onPointerUp'> {
    handleStyle?: { [key: string]: string }
    forbidOverflow?: boolean
    canvasStyle?: { [key: string]: string }
    closeShadow?: boolean
    onDragState?: (dragState: 'dragging' | 'end') => void
    onHandleMove: (position: HandleMoveType, options?: InputOptionsForUndoSquash) => void
    rateX: number
    rateY: number
    testId?: string
}

export function RangeArea(props: RangeAreaProps) {
    const {
        className,
        handleStyle,
        children,
        canvasStyle,
        closeShadow,
        onHandleMove,
        onWheel,
        onDragState,
        forbidOverflow,
        rateX,
        rateY,
    } = props

    const divRef = useRef<HTMLDivElement>(null)

    const divRect = useRef<DOMRect>()

    const spanRef = useRef<HTMLSpanElement>(null)

    const spanRect = useRef<DOMRect>()

    const [innerStyle, setInnerStyle] = useState<object>({})

    useLayoutEffect(() => {
        const { width, height } = divRef.current!.getBoundingClientRect()
        const { width: cycleWidth, height: cycleHeight } = spanRef.current!.getBoundingClientRect()
        const x = rateX > 1 || rateX < 0 ? 0.5 : rateX
        const y = rateY > 1 || rateY < 0 ? 0.5 : rateY
        const _style: any = {}
        if (forbidOverflow) {
            if (x !== undefined) {
                _style.left = x * (width - cycleWidth) + 'px'
            }
            if (y !== undefined) {
                _style.top = y * (height - cycleHeight) + 'px'
            }
        } else {
            _style.transform = 'translateY(-50%) translateX(-50%)'
            if (x !== undefined) {
                _style.left = x * 100 + '%'
            }
            if (y !== undefined) {
                _style.top = y * 100 + '%'
            }
        }
        setInnerStyle(_style)
    }, [forbidOverflow, rateX, rateY])

    const moveHandle = useCallback(
        (x: number, y: number) => {
            if (!divRect.current || !spanRect.current) return
            let { left, top, width, height } = divRect.current
            const { width: cycleWidth, height: cycleHeight } = spanRect.current
            if (forbidOverflow) {
                left = left + cycleWidth / 2
                top = top + cycleHeight / 2
                width = width - cycleWidth
                height = height - cycleHeight
            }

            let cycleOffsetX = x - left
            let cycleOffsetY = y - top
            cycleOffsetX = cycleOffsetX < 0 ? 0 : cycleOffsetX > width ? width : cycleOffsetX
            cycleOffsetY = cycleOffsetY < 0 ? 0 : cycleOffsetY > width ? width : cycleOffsetY

            let _rateX = cycleOffsetX / width
            let _rateY = cycleOffsetY / height
            if (_rateX > 1 || _rateX < 0) {
                _rateX = 0.5
            }
            if (_rateY > 1 || _rateY < 0) {
                _rateY = 0.5
            }
            onHandleMove?.({ rateX: _rateX, rateY: _rateY }, { commitType: CommitType.Noop })
        },
        [forbidOverflow, onHandleMove]
    )

    const captureStart = useCallback(
        (e: React.PointerEvent) => {
            spanRect.current = spanRef.current!.getBoundingClientRect()
            divRect.current = divRef.current!.getBoundingClientRect()
            moveHandle(e.clientX, e.clientY)
            onDragState?.('dragging')
        },
        [onDragState, moveHandle]
    )

    const capturing = useCallback(
        (e: React.PointerEvent) => {
            moveHandle(e.clientX, e.clientY)
        },
        [moveHandle]
    )

    const command = useCommand()
    const captureEnd = useCallback(() => {
        onDragState?.('end')
        spanRect.current = undefined
        divRect.current = undefined
        command.commitUndo(CommitType.CommitSquash)
    }, [command, onDragState])

    const { pointerdown, pointermove, pointerup } = usePointerCapture({ captureStart, capturing, captureEnd })

    return (
        <div
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
            onPointerDown={pointerdown}
            onPointerMove={pointermove}
            onPointerUp={pointerup}
            className={`${className ?? ''}`}
            onWheel={onWheel}
            ref={divRef}
            style={{ position: 'relative', ...props.style }}
            data-testid={props.testId}
            // 避免触发可拖拽弹窗的拖拽
            data-disabled-drag-move={'true'}
        >
            <div className={`${style.content} ${closeShadow ? style.noShadow : ''}`} style={canvasStyle}>
                {children}
            </div>
            <span ref={spanRef} style={Object.assign({}, innerStyle, handleStyle)} className={style.cycleHandle}></span>
        </div>
    )
}
