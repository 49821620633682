import constate from 'constate'
import { useRef } from 'react'
import type { EditorContext } from './lifecycle/types/editor'

function useAppContextInternal({ ctx }: { ctx: EditorContext }) {
    const ctxRef = useRef<EditorContext>(ctx)

    return ctxRef.current
}

const [
    AppContextProvider,
    useAppContextHook,
    useDocumentRootHook,
    useCommandInvokerHook,
    useHandlerProviderHook,
    useFontManagerHook,
    useClipboardServiceHook,
    useExporterServiceHook,
    useColorProfileServiceHook,
    useOrganizationNotifyServiceHook,
    usePreExportServiceHook,
    useRawMemAccessServiceHook,
    useViewStateBridgeHook,
    useCursorManager,
    useCommentServiceHook,
    useViewportAnimationServiceHook,
    useSynergySyncStatusServiceHook,
    useHistoryServiceHook,
    useLibraryComponentServiceHook,
    useComponentServiceHook,
    useMouseOnCanvasHook,
    useSelectionServiceHook,
    useViewMenuServiceHook,
    usePluginServiceHook,
    useCooperationServiceHook,
    useDocIdHook,
    useHyperlinkServiceHook,
    useBridgeHook,
    useResetRafCountHook,
    useWebsocketBridgeHook,
    useLayerPanelServiceHook,
    usePagePanelServiceHook,
    useCanvasSearchServiceHook,
    useDevModeHoverNodeLabelServiceHook,
    useAIDesignLintServiceHook,
    usePlanAndUserStateServiceHook,
    useCopyCheckServiceHook,
    useNetworkServiceHook,
    useColorModeServiceHook,
    useColorModeServiceV2Hook,
    useDevModeAvailabilityVerificationServiceHook,
    useImageDownloadContextHook,
    useComponentPropServiceHook,
    usePluginHostServiceHook,
    useNotifyServiceHook,
] = constate(
    useAppContextInternal,
    (ctx) => ctx,
    (ctx) => ctx.documentRoot,
    (ctx) => ctx.commandInvoker,
    (ctx) => ctx.handlerProvider,
    (ctx) => ctx.fontManagerService,
    (ctx) => ctx.clipboardService,
    (ctx) => ctx.exporterService,
    (ctx) => ctx.colorProfileService,
    (ctx) => ctx.organizationNotifyService,
    (ctx) => ctx.preExportService,
    (ctx) => ctx.rawMemAccessService,
    (ctx) => ctx.viewStateBridge,
    (ctx) => ctx.cursorManager,
    (ctx) => ctx.commentService,
    (ctx) => ctx.viewportAnimationService,
    (ctx) => ctx.synergySyncStatusService,
    (ctx) => ctx.historyService,
    (ctx) => ctx.libraryComponentService,
    (ctx) => ctx.componentService,
    (ctx) => ctx.mouseOnCanvas,
    (ctx) => ctx.selectionService,
    (ctx) => ctx.viewMenuService,
    (ctx) => ctx.pluginService,
    (ctx) => ctx.cooperationService,
    (ctx) => ctx.docId,
    (ctx) => ctx.hyperlinkService,
    (ctx) => ctx.bridge,
    (ctx) => ctx.resetRafCount,
    (ctx) => ctx.webSocketBridge,
    (ctx) => ctx.layerPanelService,
    (ctx) => ctx.pagePanelService,
    (ctx) => ctx.canvasSearchService,
    (ctx) => ctx.devModeHoverNodeLabelService,
    (ctx) => ctx.aiDesignLintService,
    (ctx) => ctx.planAndUserStateService,
    (ctx) => ctx.copyCheckService,
    (ctx) => ctx.networkService,
    (ctx) => ctx.colorModeService,
    (ctx) => ctx.colorModeServiceV2,
    (ctx) => ctx.devModeAvailabilityVerificationService,
    (ctx) => ctx.imageDownloadContext,
    (ctx) => ctx.componentPropService,
    (ctx) => ctx.pluginHostService,
    (ctx) => ctx.notifyService
)

export const useAppContext = useAppContextHook
export const useDocument = useDocumentRootHook
export const useNetworkService = useNetworkServiceHook
export const useCommand = useCommandInvokerHook
export const useHandlerProvider = useHandlerProviderHook
export const useFontManager = useFontManagerHook
export const useClipboard = useClipboardServiceHook
export const useExporterService = useExporterServiceHook
export const useColorProfileService = useColorProfileServiceHook
export const useOrganizationNotifyService = useOrganizationNotifyServiceHook
export const usePreExportService = usePreExportServiceHook
export const useRawMemAccessService = useRawMemAccessServiceHook
export const useViewStateBridge = useViewStateBridgeHook
export const useCanvasCursor = useCursorManager
export const useCommentService = useCommentServiceHook
export const useViewportAnimationService = useViewportAnimationServiceHook
export const useSynergySyncStatusService = useSynergySyncStatusServiceHook
export const useHistoryService = useHistoryServiceHook
export const useLibraryComponentService = useLibraryComponentServiceHook
export const useComponentService = useComponentServiceHook
export const useMouseOnCanvas = useMouseOnCanvasHook
export const useSelectionService = useSelectionServiceHook
export const useViewMenuService = useViewMenuServiceHook
export const usePluginService = usePluginServiceHook
export const useCooperationService = useCooperationServiceHook
export const useDocId = useDocIdHook
export const useHyperlinkService = useHyperlinkServiceHook
export const useBridge = useBridgeHook
export const useResetRafCount = useResetRafCountHook
export const useWebsocketBridge = useWebsocketBridgeHook
export const useLayerPanelService = useLayerPanelServiceHook
export const usePagePanelService = usePagePanelServiceHook
export const useCanvasSearchService = useCanvasSearchServiceHook
export const useDevModeHoverNodeLabelService = useDevModeHoverNodeLabelServiceHook
export const useAIDesignLintService = useAIDesignLintServiceHook
export const usePlanAndUserStateService = usePlanAndUserStateServiceHook
export const useCopyCheckService = useCopyCheckServiceHook
export const useColorModeService = useColorModeServiceHook
export const useColorModeServiceV2 = useColorModeServiceV2Hook
export const useDevModeAvailabilityVerificationService = useDevModeAvailabilityVerificationServiceHook
export const useImageDownloadContext = useImageDownloadContextHook
export const useComponentPropService = useComponentPropServiceHook
export const usePluginHostService = usePluginHostServiceHook
export const useNotifyService = useNotifyServiceHook
export { AppContextProvider }
