/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { FunctionComponent } from 'react'
import { NodeId } from '../../../../document/node/node'
import { useNodePreview } from '../export/hooks/use-node-preview'
import { PreviewImageSize } from '../export/util'
import styles from './node-preview.module.less'

export interface NodePreviewProps {
    ids: NodeId[]
    size: PreviewImageSize
    className?: string
}

export const NodePreview: FunctionComponent<NodePreviewProps> = (props: NodePreviewProps) => {
    const base64 = useNodePreview(props.ids, props.size)

    return (
        <div className={classnames(styles['preview-img'], props.className)}>
            {
                <img
                    src={base64 ?? ''}
                    style={{ pointerEvents: 'none' }}
                    className={classnames(styles.img, 'mosaic-8')}
                />
            }
        </div>
    )
}
