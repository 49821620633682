import { translation } from './remote-select-group.translation'
/* eslint-disable no-restricted-imports */
import { useCallback, useMemo, useState } from 'react'
import { DropdownDefaultCustomNode, DropdownV2, RectContainer } from '../../../../../../../../ui-lib/src'
import { NodeId } from '../../../../../../document/node/node'
import { useComponentService } from '../../../../../../main/app-context'
import { LibraryStyleTestId } from '../../../../../../window'
import { EffectStyleDetailRemote } from '../../../../../document-view-state/view-states/effect-style'
import { CommonStyleInfo } from '../../../styles/get-style-nodes-info-map'
import { useEditingEffectStyle } from '../../../styles/hooks/use-editing-style'
import style from '../../../styles/style-color/style-color.module.less'
import { StyleListItem, StyleListItemKeyboardProps } from '../../../styles/style-items-layout/style-list-item'
import { EffectStyleEditor } from '../../../styles/style-panel/effect-style-editor/effect-style-editor'
import { useEffectContext } from '../../context'
import { EffectStyleIcon } from '../effect-style-icon'
import { useEffectStyleEditInsideSelect, useRemoteEffectHandler } from './use-effect-style-select'

export function EffectStyleListItemRemote(
    props: {
        docId?: string
        item: EffectStyleDetailRemote
        selectStyleId?: NodeId
        selectStyleNodeKey: string
    } & StyleListItemKeyboardProps
) {
    const { docId, item, selectStyleId, selectStyleNodeKey } = props
    const { closeEffectStyleSelectModal } = useEffectContext()
    const { onSelect, getNodeId } = useRemoteEffectHandler()
    const { onEdit, onEndEdit, opened, openedId, position } = useEffectStyleEditInsideSelect()
    const [contextMenuInfo, setContextMenuInfo] = useState<RectContainer | null>(null)
    const remoteStyle = useMemo(
        () => props.item.effectStyle,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.item.effectStyle.contentHash, props.item.effectStyle.id]
    )

    const effectStyleNode = useEditingEffectStyle()
    const componentService = useComponentService()

    const onSelectStyle = async () => {
        if (!docId) {
            return
        }
        await onSelect(remoteStyle, docId)
        closeEffectStyleSelectModal()
    }

    const onOperateStyle = (_: CommonStyleInfo, e: any) => {
        const { clientX, clientY } = e
        setContextMenuInfo({
            top: clientY,
            bottom: clientY,
            right: clientX,
            left: clientX,
        })
    }

    const onClosehandler = () => {
        setContextMenuInfo(null)
    }

    const [remoteLocalStyleNodeId, setRemoteLocalStyleNodeId] = useState<string | null>(null)
    const onEditStyle = async (_: CommonStyleInfo, containerDomRect: DOMRect) => {
        if (!docId) {
            return
        }
        const styleNodeId = await getNodeId(remoteStyle, docId)
        if (!styleNodeId) return
        setRemoteLocalStyleNodeId(styleNodeId)
        onEdit(styleNodeId, containerDomRect)
    }

    const onClickJump2Document = (e?: React.MouseEvent) => {
        e?.stopPropagation()
        jumpToDoc()

        setContextMenuInfo(null)
    }

    const jumpToDoc = useCallback(() => {
        componentService.jumpToOriginDocument({
            docId: props.docId,
            nodeId: remoteStyle.nodeId,
        })
    }, [componentService, props.docId, remoteStyle.nodeId])

    return (
        <>
            <StyleListItem
                item={{
                    id:
                        selectStyleId && item.effectStyle.id === selectStyleNodeKey
                            ? selectStyleId
                            : item.effectStyle.id || '',
                    name: item.effectStyle.name || '',
                    description: item.effectStyle.description || '',
                    groupName: '',
                    styleName: '',
                }}
                isReadonly
                openStyleId={opened && openedId && openedId === remoteLocalStyleNodeId ? remoteStyle.id : undefined}
                onSelectItem={onSelectStyle}
                onClickHoverIcon={onEditStyle}
                onContextMenu={onOperateStyle}
                dataTestId={`${LibraryStyleTestId.RemoteEffectStyleItem}-${item.effectStyle.id}`}
                index={props.index}
                selectStyleId={props.selectStyleId}
                setPreselectIndex={props.setPreselectIndex}
                trySetPreselectIndex={props.trySetPreselectIndex}
                recordEnterCallback={props.recordEnterCallback}
            >
                <EffectStyleIcon className={style.thumbnail} iconType={props.item.iconType} />
                <div className={style.name}>{props.item.styleName}</div>
            </StyleListItem>
            {contextMenuInfo ? (
                <DropdownV2.NoTriggerMultiLevel<DropdownDefaultCustomNode & { handler?: () => void }>
                    isOpenState={true}
                    triggerRect={contextMenuInfo}
                    onClose={onClosehandler}
                    onChange={(v) => v.customNode?.handler?.()}
                    pickRest={{ onMouseDown: (e) => e.stopPropagation() }}
                    onKeyboard={(e) => e.code === 'Escape' && e.stopPropagation()}
                    items={[
                        {
                            name: translation('GoTo'),
                            customNode: {
                                handler: onClickJump2Document,
                            },
                        },
                    ]}
                />
            ) : null}
            {effectStyleNode && position && remoteLocalStyleNodeId ? (
                <EffectStyleEditor
                    isRemoteStyle={true}
                    styleId={effectStyleNode.id}
                    visible={!!(opened && openedId === remoteLocalStyleNodeId)}
                    onCancel={onEndEdit}
                    position={position}
                    onClickRemoteStyleLink={jumpToDoc}
                />
            ) : null}
        </>
    )
}
