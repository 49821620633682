import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    DuplicateResource: '文件已被添加到草稿',
} as const

export const enTranslation = {
    DuplicateResource: 'The file has been added to your drafts.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
