/* eslint-disable no-restricted-imports */
import { randomString } from '../../../../util/src'
import { LoggerLevel, PlatformType, WebFrog } from '@yuanfudao/infra-frog/web'
import { environment, IN_JEST_TEST } from '../../environment'
import { WK } from '../../window'
import { getOSType } from '../util/ua'
import { AppIds, FrogEnv, FrogTokens, ProductIds } from './enums'

export interface IFrogData {
    url: string
    eventId: number
    customExtend?: FrogCustomExtend
    refPage?: string
    duration?: number
    keyFrom?: string
    currentPage?: string
    eventAction?: string
    eventName?: string
}

export interface FrogCustomExtend {
    [key: string]: string | number | undefined
}

class WKFrogService {
    private frog_: WebFrog
    private userId_: number
    private appId_: AppIds
    private sessionId_: string

    constructor(appId: AppIds) {
        const token = this.getFrogToken(appId)
        const isTesting = this.isFrogTestEnv()
        this.appId_ = appId
        this.userId_ = WK.userInfo ? WK.userInfo.userId : 0

        this.frog_ = new WebFrog({
            appId: this.appId_,
            appToken: token,
            env: isTesting ? FrogEnv.TEST : FrogEnv.ONLINE,
            getTimestamp: () => Date.now(),
            immediate: isTesting, // 测试环境立即上传,否则 cache 5s
            debug: false,
            loggerMode: LoggerLevel.ERROR,
            compatible: false,
            ignoreDefaultProps: true,
        })

        this.frog_.init({
            userId: this.userId_,
            originUserId: this.userId_,
            primaryUserId: this.userId_,
            productId: ProductIds[appId],
            ldap: '', // ldap 为 userBrief.email
            appVersion: this.getAppVersion(),
            canUpdate: true, // 可以多次初始化： userId 在login之后才可以获得
            serverUrlConfig: {
                [FrogEnv.TEST]: 'https://frog.yuanfudao.biz/statV4',
                [FrogEnv.ONLINE]: environment.isAbroad
                    ? 'https://frog.motiff.com/statV4'
                    : 'https://frog.yuanfudao.com/statV4',
                [FrogEnv.DEV]: 'https://princi.zhenguanyu.com/frog-test-server/statV4',
            },
        })

        this.frog_.setCommonProperty({
            platformType: PlatformType.WEB,
            // Browser 环境不能获得到真正的 deviceid
            yfdU: '123456',
            screenWidth: window.screen.width,
            screenHeight: window.screen.height,
            ua: navigator.userAgent,
            osType: getOSType(),
            language: navigator.language,
        })

        this.sessionId_ = `${randomString(6)}${Date.now()}`
        this.frog_.beginSession(this.sessionId_)
    }

    private isFrogTestEnv(): boolean {
        return !environment.isProduction && !IN_JEST_TEST
    }

    private getFrogToken(appId: AppIds): string {
        const isTesting = this.isFrogTestEnv()

        return FrogTokens[appId + (isTesting ? '-test' : '')]
    }

    private getAppVersion(): string {
        return WK.releaseTag
    }

    public updateFrogUserId(id: number, email: string) {
        this.userId_ = id
        // 可以无副作用的更新 userId
        this.frog_.init({
            userId: this.userId_,
            originUserId: this.userId_,
            primaryUserId: this.userId_,
            productId: ProductIds[this.appId_],
            ldap: email,
            canUpdate: true,
        })
    }

    public addFrogRecord(data: IFrogData, immediate?: boolean | undefined) {
        this.frog_.add(data, immediate)
    }

    public flushRecord() {
        this.frog_.flush()
    }
}

let wkFrogInstance_: WKFrogService | null = null
let isInitialized = false

export class WKFrog {
    public static updateFrogUserId(...args: Parameters<WKFrogService['updateFrogUserId']>) {
        if (!isInitialized) {
            console.error('Update userId is called before frog initialized !!')
            return
        }

        wkFrogInstance_?.updateFrogUserId(...args)
    }

    public static addFrogRecord(...args: Parameters<WKFrogService['addFrogRecord']>) {
        if (!isInitialized || WK.e2e) {
            return
        }

        wkFrogInstance_?.addFrogRecord(...args)
    }

    public static flushRecord() {
        if (!isInitialized) {
            return
        }

        wkFrogInstance_?.flushRecord()
    }
}

export function tryInitFrog(appId: AppIds) {
    wkFrogInstance_ = new WKFrogService(appId)
    isInitialized = true
}
