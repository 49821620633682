import classNames from 'classnames'
import { isEqual, isNil } from 'lodash-es'
import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useRef,
    useState,
} from 'react'
import { toMixed } from '../../../../../../../ui-lib/src'
import { CommitType } from '../../../../../document/command/commit-type'
import {
    cppInterceptThisKeyboardEvent,
    divertKeyboardEvent,
} from '../../../../../main/service/design-panel-input-keyboard-event-service'
import { CustomCalculateError } from '../utils/parse-string'
import { ErrorType } from '../utils/parse-string-type'
import { Formatter, GetIncrementTargetsFn } from '../utils/type'
import { isOnlyPressedSpecialKey } from '../utils/utils'
import classes from './formatted-input.module.less'
import { HtmlInput, HtmlInputProps } from './html-input'

export interface InputOptionsForUndoSquash {
    commitType: CommitType
}

export interface FormattedInputCustomProps<T> {
    testId?: string
    value: T
    isMixed?: boolean
    customMixedValue?: string
    shouldClearOnFocus?: boolean
    focusWithoutBorder?: boolean
    formatter: Formatter<T>
    mixedMathHandler?: {
        onChangeMixed: (parse: (value: T) => T, options: InputOptionsForUndoSquash) => void
    }
    defaultShowEmpty?: boolean
    noRightPadding?: boolean
    notUseUserConfig?: boolean // format 函数中使用
    bigNudgeAmount?: number // format 函数中使用
    smallNudgeAmount?: number // format 函数中使用
    onChange: (value: T, options: InputOptionsForUndoSquash) => void
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
}

export interface FormattedInputProps<T>
    extends FormattedInputCustomProps<T>,
        Omit<HtmlInputProps, 'value' | 'onChange'> {}

export interface FormattedInputRef {
    getInputElement: () => HTMLInputElement
    updateValue: (value: string, options?: { selection?: { start: number; end: number } }) => void
}

function _FormattedInput<T>(props: FormattedInputProps<T>, ref: React.Ref<FormattedInputRef>) {
    const htmlRef = useRef<HTMLInputElement>(null)
    const [editingValue, setEditingValue] = useState<string | null>(null)
    const [incrementTargets, setIncrementTargets] = useState<ReturnType<GetIncrementTargetsFn>>()
    const [selection, setSelection] = useState<{ value: string; start: number; end: number } | null>(null)
    const didIncrement = useRef<boolean>(false)
    const needToSelectAll = useRef<boolean>(false)
    const prePropsValue = useRef<T>(props.value)

    const valueForRender = useCallback(
        (customInputValue: string | null) => {
            if (customInputValue !== null) {
                return customInputValue
            } else if (props.isMixed) {
                return props.customMixedValue ?? toMixed()
            } else if (isNil(props.value) || props.defaultShowEmpty) {
                return ''
            } else if (props.formatter.format) {
                return props.formatter.format(props.value)
            } else {
                return props.value
            }
        },
        [props.formatter, props.isMixed, props.customMixedValue, props.value, props.defaultShowEmpty]
    )

    const selectAll = useCallback(() => {
        if (!htmlRef.current) {
            return
        }
        if (!props.isMixed && props.formatter.defaultSelection) {
            const selectionInfo = props.formatter.defaultSelection(htmlRef.current.value)
            htmlRef.current.setSelectionRange(selectionInfo.start, selectionInfo.end)
        } else {
            htmlRef.current.select()
        }
        needToSelectAll.current = false
    }, [htmlRef, props.formatter, props.isMixed])

    const looksEqual = useCallback(
        (value: T, value2: T) => {
            if (props.isMixed) {
                return false
            } else if (props.formatter.isEqual) {
                return props.formatter.isEqual(value, value2)
            } else if (props.formatter.format) {
                return props.formatter.format(value) === props.formatter.format(value2)
            } else {
                return value === value2
            }
        },
        [props.formatter, props.isMixed]
    )

    const _onKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.nativeEvent.isComposing) {
                // 输入法合成中的键盘行为不处理。应由输入法自己响应
                return
            }
            const isInputValueEqualCurrentValue = editingValue === null || editingValue === valueForRender(null)
            if (cppInterceptThisKeyboardEvent(e, !isInputValueEqualCurrentValue, divertKeyboardEvent)) {
                return
            }
            if (
                !(
                    e.key === 'Alt' ||
                    e.keyCode === 18 ||
                    e.code === 'AltLeft' ||
                    e.code === 'AltRight' ||
                    e.code === 'ControlLeft' ||
                    e.code === 'ControlRight' ||
                    e.code === 'KeyC'
                )
            ) {
                e.stopPropagation()
            }
            props.onKeyDown?.(e)
            if (!htmlRef.current) {
                return
            }
            didIncrement.current = false
            if (e.keyCode === 27 || e.code === 'Esc') {
                e.preventDefault()
                e.stopPropagation()
                setEditingValue(null)
                setTimeout(() => htmlRef.current?.blur(), 0)
            } else {
                if (e.keyCode === 13 || e.code === 'Enter') {
                    htmlRef.current?.blur?.()
                    e.preventDefault()
                    e.stopPropagation()
                }
                if (e.keyCode === 38 || e.code === 'ArrowUp' || e.keyCode === 40 || e.code === 'ArrowDown') {
                    e.preventDefault()
                    e.stopPropagation()
                    if (!props.formatter.increment) {
                        return
                    }
                    let preParseValue: T | null | undefined
                    try {
                        const inputValue = htmlRef.current.value || (isNil(props.placeholder) ? '' : props.placeholder)
                        const oldValue = props.isMixed || isNil(props.value) ? undefined : props.value
                        preParseValue = props.formatter.parse(inputValue, oldValue)
                    } catch (c) {}

                    let isNeedMixedHandle = false
                    if (isNil(preParseValue)) {
                        if (props.isMixed) {
                            isNeedMixedHandle = true
                        } else {
                            preParseValue = props.value
                        }
                    }
                    if (!isNeedMixedHandle) {
                        if (isNil(preParseValue)) {
                            return
                        }
                        let incrementTargetsInfo
                        if (props.formatter.getIncrementTargets) {
                            const start = htmlRef.current.selectionStart || 0
                            const end = htmlRef.current.selectionEnd || 0
                            // incrementTargetsInfo 与修改值后的选中状态关联
                            incrementTargetsInfo = props.formatter.getIncrementTargets(htmlRef.current.value, {
                                start,
                                end,
                            })
                        }
                        let outputValue = props.formatter.increment(
                            preParseValue,
                            e.keyCode === 38 || e.code === 'ArrowUp',
                            e.shiftKey,
                            incrementTargetsInfo
                        )
                        if (props.formatter.clamp) {
                            outputValue = props.formatter.clamp(outputValue, true)
                        }
                        setEditingValue(null)
                        setIncrementTargets(incrementTargetsInfo)
                        if (!looksEqual(outputValue, props.value)) {
                            props.onChange(outputValue, {
                                commitType: CommitType.CommitSquash,
                            })
                            didIncrement.current = true
                        }
                    } else if (props.mixedMathHandler) {
                        const upDownParseFn = (initValue: T) => {
                            if (!props.formatter.increment) {
                                return initValue
                            }
                            let outputValue = props.formatter.increment(
                                initValue,
                                e.keyCode === 38 || e.code === 'ArrowUp',
                                e.shiftKey,
                                undefined
                            )
                            if (props.formatter.clamp) {
                                outputValue = props.formatter.clamp(outputValue, true)
                            }
                            return outputValue
                        }
                        props.mixedMathHandler.onChangeMixed(upDownParseFn, {
                            commitType: CommitType.CommitSquash,
                        })
                    }
                }
            }
        },
        [editingValue, htmlRef, looksEqual, props, valueForRender]
    )

    const _onKeyUp = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (!htmlRef.current || !props.formatter.autocomplete) {
                return
            }
            const inputValue = htmlRef.current.value
            const isInsetting =
                inputValue.length > 0 &&
                htmlRef.current.selectionStart === inputValue.length &&
                htmlRef.current.selectionEnd === inputValue.length
            // 一个可打印且字形为一个长度的字符
            const aPrintedRepresentation = e.key && e.key.length === 1
            // 空格 或 a-z的字母
            const spaceAZ = e.keyCode === 32 || e.code === 'Space' || (e.keyCode >= 65 && e.keyCode <= 90)
            if (isInsetting && (aPrintedRepresentation || spaceAZ) && isOnlyPressedSpecialKey(e, 1024)) {
                const completeValue = props.formatter.autocomplete(inputValue)
                if (!isNil(completeValue)) {
                    htmlRef.current.value = completeValue
                    htmlRef.current.setSelectionRange(inputValue.length, completeValue.length)
                }
            }
        },
        [htmlRef, props.formatter]
    )

    const _onMouseDown = useCallback(
        (e: React.MouseEvent<HTMLInputElement>) => {
            e.stopPropagation()
            props.onMouseDown?.(e)
        },
        [props]
    )

    const _onMouseUp = useCallback(
        (e: React.MouseEvent<HTMLInputElement>) => {
            if (htmlRef.current && needToSelectAll.current) {
                htmlRef.current.selectionStart === htmlRef.current.selectionEnd && selectAll()
                needToSelectAll.current = false
            } else {
                e.stopPropagation()
            }
        },
        [htmlRef, selectAll]
    )

    const _onMouseLeave = useCallback(() => {
        if (needToSelectAll.current) {
            needToSelectAll.current = false
        }
    }, [])

    const _onFocus = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            needToSelectAll.current = true
            if (props.shouldClearOnFocus) {
                setEditingValue('')
            }
            props.onFocus?.(e)
        },
        [props]
    )

    const _onChange = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        let nextValue = e.target.value
        if (nextValue.includes('。')) {
            // 中文输入法下的句号转成英文的句号时，选区会被重置到末尾，这里手动恢复
            nextValue = nextValue.replace(/。/g, '.')
            const selectionStart = e.target.selectionStart
            const selectionEnd = e.target.selectionEnd
            e.target.value = nextValue
            e.target.setSelectionRange(selectionStart, selectionEnd)
        }
        setEditingValue(nextValue)
    }, [])

    const _onBlur = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            const inputValue = htmlRef.current?.value ?? ''
            if (editingValue !== null) {
                const parse = (initValue?: T) => {
                    let outputValue = props.formatter.parse(inputValue, isNil(initValue) ? undefined : initValue)
                    if (props.formatter.clamp) {
                        outputValue = props.formatter.clamp(outputValue)
                    }
                    return outputValue
                }
                try {
                    const parseValue = parse(props.isMixed ? undefined : props.value)
                    if (!looksEqual(parseValue, props.value)) {
                        props.onChange(parseValue, { commitType: CommitType.CommitUndo })
                    }
                } catch (error: any) {
                    if (
                        error instanceof CustomCalculateError &&
                        error.errorInfo?.type === ErrorType.EVAL_NO_CURRENT_VALUE &&
                        props.mixedMathHandler
                    ) {
                        props.mixedMathHandler?.onChangeMixed(parse, { commitType: CommitType.CommitUndo })
                    }
                }
            }
            setTimeout(setEditingValue, 0, null)
            props.onBlur?.(e)
        },
        [editingValue, htmlRef, looksEqual, props]
    )

    const updateValue = useCallback((value: string, options?: { selection?: { start: number; end: number } }) => {
        setEditingValue(value)
        if (options?.selection) {
            setSelection({ value, ...options.selection })
        }
    }, [])

    // 更新一次 selection
    useEffect(() => {
        if (selection && selection.value === htmlRef.current?.value) {
            htmlRef.current?.setSelectionRange(selection.start, selection.end)
            setSelection(null)
        }
    }, [selection])

    // componentDidMount
    useEffect(() => {
        if (props.autoFocus) {
            htmlRef.current?.focus()
            htmlRef.current?.select()
        }
    }, [htmlRef, props.autoFocus])

    useEffect(() => {
        if (!isEqual(prePropsValue.current, props.value)) {
            setEditingValue(null)
        }
        prePropsValue.current = props.value
    }, [props.value])

    const resetSelectionRef = useRef<() => void>()
    resetSelectionRef.current = () => {
        if (didIncrement.current) {
            didIncrement.current = false
            if (incrementTargets && props.formatter.getSelection) {
                const { start, end } = props.formatter.getSelection(htmlRef.current?.value ?? '', incrementTargets)
                htmlRef.current?.setSelectionRange(start, end)
            } else {
                selectAll()
            }
        }
    }
    useLayoutEffect(() => {
        resetSelectionRef.current?.()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value])

    useImperativeHandle(ref, () => ({
        getInputElement: () => htmlRef.current!,
        updateValue,
    }))

    return (
        <HtmlInput
            key={String(props.disabled)}
            ref={htmlRef}
            onKeyDown={_onKeyDown}
            onKeyUp={_onKeyUp}
            onMouseDown={_onMouseDown}
            onMouseUp={_onMouseUp}
            onMouseLeave={_onMouseLeave}
            onFocus={_onFocus}
            onChange={_onChange}
            onBlur={_onBlur}
            value={valueForRender(editingValue)}
            placeholder={props.placeholder}
            className={classNames(classes.input, [props.className], {
                [classes.focusWithinBorder]: !props.focusWithoutBorder,
                [classes.disabled]: props.disabled,
                [classes.noRightPadding]: props.noRightPadding,
            })}
            autoFocus={props.autoFocus}
            disabled={props.disabled}
            testId={props.testId}
            tabIndex={props.tabIndex}
        />
    )
}

export const FormattedInput = forwardRef(_FormattedInput) as <T>(
    props: FormattedInputProps<T> & { ref?: React.Ref<FormattedInputRef> }
) => React.ReactElement
