/* eslint-disable no-restricted-imports */
import { SetViewportByAnimationKeyframeCommand, StartViewportAnimationCommand, Wukong } from '@wukong/bridge-proto'
import { signalAnimationFrame, signalTimeout } from '../../../../util/src/abort-controller/timers'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { createSignalSwitch } from '../../../../util/src/abort-controller/traceable-signal-switch'
import { CommandInvoker } from '../../document/command/command-invoker'
import { Bridge } from '../../kernel/bridge/bridge'
import { featureSwitchManager } from '../../kernel/switch'
import { DeepRequired, ViewStateBridge } from '../../view-state-bridge'

type Viewport = DeepRequired<Wukong.DocumentProto.IViewport>
type AnimationParam = Wukong.DocumentProto.IStartViewportAnimationParam
type ViewportAnimationKeyframe = Wukong.DocumentProto.IViewportAnimationKeyframe
import ViewportAnimationKeyframeType = Wukong.DocumentProto.ViewportAnimationKeyframeType

interface ViewportAnimationConfig {
    defaultDuration: number
    animationTimingFunction: (x: number) => number
}
export interface WorldPosition {
    x: number
    y: number
}

export class ViewportAnimationService {
    private startViewportAnimationSignalSwitch: (param: AnimationParam) => Promise<{ cancelled: boolean }>
    private config: ViewportAnimationConfig
    private performanceTime = performance.now()

    constructor(
        private readonly bridge: Bridge,
        protected readonly commandInvoker: CommandInvoker,
        protected readonly viewStateBridge: ViewStateBridge,
        signal: TraceableAbortSignal
    ) {
        this.config = {
            defaultDuration: 200,
            animationTimingFunction: animationTimingFunction,
        }

        this.startViewportAnimationSignalSwitch = createSignalSwitch(signal)(this._startViewportAnimation)
        this.bridge.bind(StartViewportAnimationCommand, this.startViewportAnimationSignalSwitch, {
            signal,
        })
    }

    startViewportAnimation = (param: AnimationParam) => {
        return this.startViewportAnimationSignalSwitch(param)
    }

    private _startViewportAnimation = (
        signal: TraceableAbortSignal,
        param: AnimationParam
    ): Promise<{ cancelled: boolean }> => {
        if (!param.width || !param.height) {
            return Promise.resolve({ cancelled: true })
        }

        const width = param.width
        const height = param.height

        const startX = param.startX ?? 0
        const startY = param.startY ?? 0
        const startZoom = param.startZoom ?? 1

        const endX = param.endX ?? startX
        const endY = param.endY ?? startY
        const endZoom = param.endZoom ?? startZoom

        const duration = param.duration ?? this.config.defaultDuration
        const delay = param.delay ?? 0

        // 不执行动画
        if (duration <= 0) {
            this.updateViewport({
                type: ViewportAnimationKeyframeType.VIEWPORT_ANIMATION_KEYFRAME_TYPE_END,
                x: endX,
                y: endY,
                width: width,
                height: height,
                zoom: endZoom,
            })
            return Promise.resolve({ cancelled: false })
        }

        // 计算视图更新所需的数值
        const startCenter: WorldPosition = {
            x: startX + width / 2 / startZoom,
            y: startY + height / 2 / startZoom,
        }
        const endCenter: WorldPosition = {
            x: endX + width / 2 / endZoom,
            y: endY + height / 2 / endZoom,
        }

        return new Promise((resolve) => {
            const complete = (done: boolean) => {
                resolve({ cancelled: !done })
            }
            // NOTE: 切页场景下使用getCurrentViewport会导致下次raf判断viewport不一致从而中断动画
            let lastViewport = {
                x: startX,
                y: startY,
                zoom: startZoom,
                width: width,
                height: height,
            }

            let startTime = performance.now()
            this.performanceTime = startTime
            const updateFrame = () => {
                if (featureSwitchManager.isEnabled('comment-perf-debug-log')) {
                    console.info('viewport animation fps:', 1000 / (performance.now() - this.performanceTime))
                }
                this.performanceTime = performance.now()
                // TODO(chenyn_signal): 可能和单测中 mock raf 有关
                // signal abort 之后应该要 cancel raf，但 mock 中似乎没有 cancel，导致仍执行到了 raf 回调中
                // 解决这个问题之后，应该把下面的 if aborted complete 删掉
                if (signal.aborted) {
                    complete(false)
                    return
                }

                const curViewport = this.getCurrentViewport()
                if (notEqualViewport(curViewport, lastViewport)) {
                    // 任何修改 viewport 的非动画操作都会中断动画
                    this.updateViewport({
                        type: ViewportAnimationKeyframeType.VIEWPORT_ANIMATION_KEYFRAME_TYPE_CANCEL,
                    })
                    complete(false)
                    return
                }
                const now = performance.now()
                const elapsed = now - startTime
                // 绝对进度
                let progress = Math.min(elapsed / duration, 1)
                // 非线性进度（类似 ease-in-out）
                progress = this.config.animationTimingFunction(progress)

                if (progress >= 1) {
                    // 正常完成
                    this.updateViewport({
                        type: ViewportAnimationKeyframeType.VIEWPORT_ANIMATION_KEYFRAME_TYPE_END,
                        x: endX,
                        y: endY,
                        width: width,
                        height: height,
                        zoom: endZoom,
                    })
                    complete(true)
                    return
                }
                // 计算当前进度下应该有的 viewport
                const { curX, curY, curZoom } = calcCurViewportPosAndZoom(
                    startCenter,
                    endCenter,
                    startZoom,
                    endZoom,
                    width,
                    height,
                    progress
                )

                lastViewport = {
                    zoom: curZoom,
                    x: curX,
                    y: curY,
                    width: width,
                    height: height,
                }
                this.updateViewport({
                    type: ViewportAnimationKeyframeType.VIEWPORT_ANIMATION_KEYFRAME_TYPE_RUNNING,
                    ...lastViewport,
                })

                signalAnimationFrame(updateFrame, { signal })
            }

            if (delay) {
                signalTimeout(
                    () => {
                        // 有延迟开始的动画, startTime应该为第一帧动画实际开始的时间
                        startTime = performance.now()
                        signalAnimationFrame(updateFrame, { signal })
                    },
                    delay,
                    { signal: signal }
                )
            } else {
                signalAnimationFrame(updateFrame, { signal })
            }
        })
    }

    private getCurrentViewport = (): Viewport => {
        return this.viewStateBridge.getDefaultValue('currentViewport')!
    }

    private updateViewport = (keyframe: ViewportAnimationKeyframe) => {
        this.commandInvoker.DEPRECATED_invokeBridge(SetViewportByAnimationKeyframeCommand, keyframe)
    }
}

function calcCurViewportPosAndZoom(
    startCenter: WorldPosition,
    endCenter: WorldPosition,
    startZoom: number,
    endZoom: number,
    width: number,
    height: number,
    progress: number
): { curX: number; curY: number; curZoom: number } {
    // viewport 左上角世界坐标
    const viewportLeftTopStartWorldPos = {
        x: startCenter.x - width / (2 * startZoom),
        y: startCenter.y - height / (2 * startZoom),
    }

    const viewportLeftTopEndWorldPos = {
        x: endCenter.x - width / (2 * endZoom),
        y: endCenter.y - height / (2 * endZoom),
    }

    // viewport 左上角相机坐标
    const viewportLeftTopStartCameraPos = {
        x: viewportLeftTopStartWorldPos.x * startZoom,
        y: viewportLeftTopStartWorldPos.y * startZoom,
    }

    const viewportLeftTopEndCameraPos = {
        x: viewportLeftTopEndWorldPos.x * endZoom,
        y: viewportLeftTopEndWorldPos.y * endZoom,
    }

    // 计算相机坐标整体差值
    const cameraPositionDelta = {
        x: viewportLeftTopEndCameraPos.x - viewportLeftTopStartCameraPos.x,
        y: viewportLeftTopEndCameraPos.y - viewportLeftTopStartCameraPos.y,
    }

    const curZoom = startZoom + (endZoom - startZoom) * progress

    // 基于相机坐标计算进度
    const curCameraLeftTopPos = {
        x: viewportLeftTopStartCameraPos.x + cameraPositionDelta.x * progress,
        y: viewportLeftTopStartCameraPos.y + cameraPositionDelta.y * progress,
    }

    // 转换回世界坐标
    return {
        curX: curCameraLeftTopPos.x / curZoom,
        curY: curCameraLeftTopPos.y / curZoom,
        curZoom,
    }
}

function animationTimingFunction(x: number) {
    return x < 0.5 ? 4 * x * x * x : (x - 1) * (2 * x - 2) * (2 * x - 2) + 1
}

function notEqualViewport(v1: Viewport, v2: Viewport) {
    return Math.abs(v1.x - v2.x) > 1 || Math.abs(v1.y - v2.y) > 1 || Math.abs(v1.zoom - v2.zoom) > 0.001
}
