import type { RefCallback, RefObject } from 'react'
import { useCallback, useMemo, useRef } from 'react'

export function isFnRef<T = any>(ref?: React.Ref<T>): ref is RefCallback<T> {
    return ref instanceof Function
}

export function isObjRef<T = any>(ref?: React.Ref<T>): ref is RefObject<T> {
    return !!ref && !(ref instanceof Function)
}

export function useElementRef<T = HTMLElement>(outerDomRef?: React.Ref<T>, innerDomRef?: React.Ref<T>) {
    const defaultRef = useRef<T>(null)

    const ObjRef: RefObject<T> = useMemo(() => {
        if (isObjRef(outerDomRef)) {
            return outerDomRef
        } else if (isObjRef(innerDomRef)) {
            return innerDomRef
        }
        return defaultRef
    }, [innerDomRef, outerDomRef])

    const fnRef: RefCallback<T> = useCallback(
        (dom: T) => {
            if (isFnRef(innerDomRef)) {
                innerDomRef(dom)
            } else if (innerDomRef) {
                ;(innerDomRef as any).current = dom
            }
            if (isFnRef(outerDomRef)) {
                outerDomRef(dom)
            } else if (outerDomRef) {
                ;(outerDomRef as any).current = dom
            }
            ;(defaultRef as any).current = dom
        },
        [innerDomRef, outerDomRef]
    )

    const reactRef = useMemo(() => {
        return isFnRef(outerDomRef) || isFnRef(innerDomRef) ? fnRef : ObjRef
    }, [outerDomRef, innerDomRef, fnRef, ObjRef])

    return { htmlRef: ObjRef, reactRef }
}
