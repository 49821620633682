import { GET } from '@tutor/network-core'
import { BaseCommonRequest } from './base-request'

/**
 * AWS 提供图片压缩的服务，由服务端转发
 * 图片处理参数见 Sharp: https://sharp.pixelplumbing.com/api-resize
 */
@GET
export class AWSImageProcessService extends BaseCommonRequest<{ url: string }> {
    constructor(private readonly key: string, private readonly formatParams: Record<string, any>) {
        super()
    }

    public override requestUrl() {
        return '/client-utils/images/process'
    }

    public override requestDidFailed(): boolean {
        return false
    }

    public override requestArgument() {
        return {
            formatParams: JSON.stringify({
                key: this.key,
                edits: this.formatParams,
            }),
        }
    }
}
