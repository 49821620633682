import { SVGAttributes } from 'react'

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconLoading48 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
        <path
            d="M24 8.00002L24 9.50002Q20.9847 9.50002 18.4319 10.5574Q15.8791 11.6148 13.747 13.747Q11.6148 15.8791 10.5574 18.4319Q9.5 20.9847 9.5 24Q9.5 27.0154 10.5574 29.5681Q11.6148 32.1209 13.747 34.2531Q15.8791 36.3852 18.4319 37.4426Q20.9847 38.5 24 38.5Q27.0153 38.5 29.5681 37.4426Q32.1209 36.3852 34.253 34.2531Q36.3852 32.1209 37.4426 29.5681Q38.5 27.0153 38.5 24L40 24C40 32.8366 32.8366 40 24 40C15.1634 40 8 32.8366 8 24C8 15.1635 15.1634 8.00002 24 8.00002Z"
            fillRule="evenodd"
            fill="rgb(191, 195, 201)"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconFigmaComponentsDoc48 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" {...props}>
        <path
            transform="matrix(1 0 0 1 9 5.99963)"
            d="M0 2C0 0.89543 0.895431 0 2 0L20.1476 0C20.6922 0 21.2132 0.222076 21.5903 0.614937L29.4428 8.79456C29.8003 9.16701 30 9.66331 30 10.1796C30 13.7934 30 28.5833 30 34C30 35.1046 29.1046 36 28 36L2 36C0.895431 36 0 35.1046 0 34L0 2Z"
            fillRule="nonzero"
            fill="rgb(230, 232, 234)"
        />
        <defs>
            <mask id="mask5037979102" style={{ maskType: 'alpha' }}>
                <path
                    transform="matrix(1 0 0 1 9 5.99963)"
                    d="M0 2C0 0.89543 0.895431 0 2 0L20.1476 0C20.6922 0 21.2132 0.222076 21.5903 0.614937L29.4428 8.79456C29.8003 9.16701 30 9.66331 30 10.1796C30 13.7934 30 28.5833 30 34C30 35.1046 29.1046 36 28 36L2 36C0.895431 36 0 35.1046 0 34L0 2Z"
                    fillRule="nonzero"
                    fill="rgb(230, 232, 234)"
                />
            </mask>
        </defs>
        <g mask="url('#mask5037979102')">
            <path
                transform="matrix(1 0 0 1 25.995 6)"
                d="M4 2C4 0.89543 3.10457 0 2 0L0 0L13 0L13 14L13 11C13 9.89543 12.1046 9 11 9L6 9C4.89543 9 4 8.10457 4 7L4 2Z"
                fillRule="nonzero"
                fill="rgb(218, 220, 224)"
            />
        </g>
        <path
            transform="matrix(1 0 0 1 24 22.4999)"
            d="M0 3.75002C0 1.68225 1.68224 0 3.75 0C5.81776 0 7.5 1.68225 7.5 3.75002C7.5 5.81778 5.81776 7.50003 3.75 7.50003C1.68224 7.50003 0 5.81778 0 3.75002Z"
            fillRule="nonzero"
            fill="rgb(26, 188, 254)"
        />
        <path
            transform="matrix(1 0 0 1 16.5 30.0002)"
            d="M0 3.75002C0 1.68225 1.69014 0 3.76761 0L7.5 0L7.5 3.75002C7.5 5.81778 5.80986 7.50003 3.73239 7.50003C1.65493 7.50003 0 5.81778 0 3.75002L0 3.75002Z"
            fillRule="nonzero"
            fill="rgb(10, 207, 131)"
        />
        <path
            transform="matrix(1 0 0 1 24 15)"
            d="M0 0L0 7.50003L3.75 7.50003C5.81776 7.50003 7.5 5.80989 7.5 3.73241C7.5 1.65494 5.81776 0 3.75 0L0 0Z"
            fillRule="nonzero"
            fill="rgb(255, 114, 98)"
        />
        <path
            transform="matrix(1 0 0 1 16.5 15)"
            d="M0 3.75002C0 5.81778 1.69014 7.50003 3.76761 7.50003L7.5 7.50003L7.5 0L3.73239 0C1.65493 0 0 1.68225 0 3.75002L0 3.75002Z"
            fillRule="nonzero"
            fill="rgb(242, 78, 30)"
        />
        <path
            transform="matrix(1 0 0 1 16.5 22.4999)"
            d="M0 3.75002C0 5.81778 1.69014 7.50003 3.76761 7.50003L7.5 7.50003L7.5 0L3.73239 0C1.65493 0 0 1.68225 0 3.75002L0 3.75002Z"
            fillRule="nonzero"
            fill="rgb(162, 89, 255)"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconSketchDoc48 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" {...props}>
        <path
            transform="matrix(1 0 0 1 9 5.99963)"
            d="M0 2C0 0.89543 0.895431 0 2 0L20.1476 0C20.6922 0 21.2132 0.222076 21.5903 0.614937L29.4428 8.79456C29.8003 9.16701 30 9.66331 30 10.1796C30 13.7934 30 28.5833 30 34C30 35.1046 29.1046 36 28 36L2 36C0.895431 36 0 35.1046 0 34L0 2Z"
            fillRule="nonzero"
            fill="rgb(230, 232, 234)"
        />
        <defs>
            <mask id="mask4643881423" style={{ maskType: 'alpha' }}>
                <path
                    transform="matrix(1 0 0 1 9 5.99963)"
                    d="M0 2C0 0.89543 0.895431 0 2 0L20.1476 0C20.6922 0 21.2132 0.222076 21.5903 0.614937L29.4428 8.79456C29.8003 9.16701 30 9.66331 30 10.1796C30 13.7934 30 28.5833 30 34C30 35.1046 29.1046 36 28 36L2 36C0.895431 36 0 35.1046 0 34L0 2Z"
                    fillRule="nonzero"
                    fill="rgb(230, 232, 234)"
                />
            </mask>
        </defs>
        <g mask="url('#mask4643881423')">
            <path
                transform="matrix(1 0 0 1 27.495 6)"
                d="M4 2C4 0.89543 3.10457 0 2 0L0 0L13 0L13 14L13 11C13 9.89543 12.1046 9 11 9L6 9C4.89543 9 4 8.10457 4 7L4 2Z"
                fillRule="nonzero"
                fill="rgb(218, 220, 224)"
            />
        </g>
        <defs>
            <clipPath id="clipPath3832121842">
                <path
                    transform="matrix(1 0 0 1 12 15)"
                    d="M4.5 0C2.01472 0 0 2.01472 0 4.5L0 19.5C0 21.9853 2.01472 24 4.5 24L19.5 24C21.9853 24 24 21.9853 24 19.5L24 4.5C24 2.01472 21.9853 0 19.5 0L4.5 0Z"
                    fillRule="nonzero"
                />
            </clipPath>
        </defs>
        <g clipPath="url('#clipPath3832121842')">
            <path
                transform="matrix(1 0 0 1 12.1871 16.686)"
                d="M11.8129 21.4782L0 7.70304L5.10558 0.727649L11.8129 0L18.5203 0.727649L23.6258 7.70304L11.8129 21.4782Z"
                fillRule="nonzero"
                fill="rgb(255, 174, 0)"
            />
            <path
                transform="matrix(1 0 0 1 12.1871 24.3857)"
                d="M11.8129 13.7751L0 0L23.6258 0L11.8129 13.7751Z"
                fillRule="nonzero"
                fill="rgb(236, 108, 0)"
            />
            <path
                transform="matrix(1 0 0 1 16.9649 24.3857)"
                d="M7.03269 13.7751L0 0L14.0654 0L7.03269 13.7751Z"
                fillRule="nonzero"
                fill="rgb(255, 174, 0)"
            />
            <path
                transform="matrix(1 0 0 1 16.9649 16.686)"
                d="M7.03269 0L0 7.70304L14.0654 7.70304L7.03269 0Z"
                fillRule="nonzero"
                fill="rgb(255, 239, 180)"
            />
            <path
                transform="matrix(1 0 0 1 12.1871 17.4147)"
                d="M5.10558 0L2.47771 3.51278L0 6.97539L4.83028 6.97539L5.10558 0ZM18.5203 0L21.1481 3.51278L23.6258 6.97539L18.7956 6.97539L18.5203 0Z"
                fillRule="nonzero"
                fill="rgb(255, 174, 0)"
            />
            <path
                transform="matrix(1 0 0 1 16.9649 16.686)"
                d="M0.325356 0.727649L0 7.70304L7.03269 0L0.325356 0.727649ZM13.74 0.727649L14.0654 7.70304L7.03269 0L13.74 0.727649Z"
                fillRule="nonzero"
                fill="rgb(254, 211, 5)"
            />
        </g>
    </svg>
)
