import { Wukong } from '@wukong/bridge-proto'
import { computed } from 'ccstate'
import { traceable } from '../../../../util/src/ccstate-helper/traceable'
import { GetTicketByDocId, GetTicketRequestOptions } from '../../document/synergy/synergy-ticket'
import { createFileManager, isFileCreated$ } from '../../main/create-file-manager'
import { previewSenceEnum2value } from '../../prototype/context/preview-scene'
import { useDocInfo } from '../context/doc-info'
import { editorDocId$, isPresentPath$ } from './editor-context'
import { userInfo$ } from './user-info'

const realTicket$ = traceable(
    'hulh01@kanyun.com',
    computed(
        async (get) => {
            const docId = get(editorDocId$)

            if (!docId) {
                return undefined
            }

            const options: GetTicketRequestOptions = {}

            if (get(isPresentPath$)) {
                // TODO(chenyn): 临时复用 mirror 的参数，为了服务端处理 ticket 下发的 role
                options.mirror = true
                options.scene =
                    previewSenceEnum2value[Wukong.DocumentProto.PreviewScene.PREVIEW_SCENE_P_R_O_T_O_T_Y_P_E]
            }

            const ticket = await new GetTicketByDocId(docId, options).start().catch(() => {
                return undefined
            })

            return ticket
        },
        { debugLabel: 'realTicket$' }
    )
)

const mockTicket$ = traceable(
    'hulh01@kanyun.com',
    computed(async (get) => {
        const docId = get(editorDocId$)

        if (!docId) {
            return undefined
        }

        const userInfo = await get(userInfo$)

        return createFileManager.createMockTicket(userInfo.userId, docId)
    })
)

export const ticket$ = traceable(
    'hulh01@kanyun.com',
    computed(
        async (get) => {
            if (get(isFileCreated$)) {
                return await get(mockTicket$)
            }

            const ticket = await get(realTicket$)

            return ticket
        },
        { debugLabel: 'ticket$' }
    )
)

export const useTicket = () => useDocInfo().ticket
