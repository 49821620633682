import { BadgeDot } from './badge-dot'
import { BadgeNumber } from './badge-number'
import { BadgeText } from './badge-text'

export type { BadgeDotProps } from './badge-dot'
export type { BadgeNumberProps } from './badge-number'
export type { BadgeTextProps } from './badge-text'

/**
 * @description 在定位容器上出现
 */
export const Badge = {
    Dot: BadgeDot,
    Number: BadgeNumber,
    Text: BadgeText,
}
