/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-imports */
import {
    PluginShowToast,
    ShowLastUsedPlugin,
    ToastShow,
    UpdateActivatedPluginCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { WKToast } from '../../../../../ui-lib/src'
import { createImmerStore, createSelectors } from '../../../../../util/src'
import { CommandInvoker } from '../../../document/command/command-invoker'
import { environment } from '../../../environment'
import { debugLog } from '../../../kernel/debug'
import { featureSwitchManager } from '../../../kernel/switch/core'
import AdjustMouseScaleSpeed from './adjust-mouse-scale-speed'
import AE from './ae'
import ArcText from './arc-text'
import FillRuleEditor from './fill-rule-editor'
import Iconpark from './iconpark'
import Looper from './looper'
import { PluginToastProps, PluginToastType } from './plugin-toast'
import RotateCopy from './rotate-copy'
import { exported as SkewTool } from './skew-tool'
import { PluginExported } from './type'
// eslint-disable-next-line import/no-named-as-default
import { ClassWithEffect, EffectController } from '../../../../../util/src/effect-controller'
import { LoadPluginOptions, PluginHostService } from '../../../document/plugin-ui/plugin-host-service'
import { Bridge } from '../../../kernel/bridge/bridge'
import { PluginVO } from '../../../kernel/interface/plugin'
import { GetDocRequest } from '../../../kernel/request/document'
import { GetOrganizations } from '../../../kernel/request/organizations'
import { WheelEventDebugPlugin } from '../../../main/canvas/handle-wheel-event'
import { createFileManager } from '../../../main/create-file-manager'
import { ClientService } from '../../../main/service/client-service'
import { UserConfigService } from '../../../main/user-config/user-config-service'
import { LocalStorageKey } from '../../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../../web-storage/local-storage/storage'
import {
    fetchGetEditablePlugins,
    fetchGetPublishedPlugins,
    fetchGetPublishedPluginsByIds,
} from './plugin-development/plugin-request'
import {
    LocalPlugin,
    PluginId,
    PluginLocalManifestFileError,
    PluginPublishDraft,
} from './plugin-development/template/type'
import { getLocalPluginData, getLocalPluginManifest } from './plugin-development/use-plugin-development'
import { fileSep, isPluginDevelopmentEnabled } from './plugin-development/util'
import { PluginHotReloadService } from './plugin-hot-reload-service'
import { translation } from './plugin-service.translation'
import Unsplash from './unsplash'

interface CurrentModalState {
    key: Wukong.DocumentProto.PluginType
    width?: number
    height?: number
}

interface ToastState extends PluginToastProps {
    show: boolean
}

enum ClientPluginItemClickValue {
    RunLastUsedPlugin = 'run-last-used-plugin',
    DevPlugin = 'dev-plugin',
}

interface ClientPluginMenuItem {
    type: 'normal' | 'separator'
    label?: string
    clickValue?: ClientPluginItemClickValue | Wukong.DocumentProto.PluginType | string
    accelerator?: string
}

export class PluginService extends ClassWithEffect {
    public pluginHotReloadService: PluginHotReloadService

    readonly officialPlugins: PluginExported[] = [SkewTool]
    states = createSelectors(
        createImmerStore<{
            currentModalState: CurrentModalState | null
            updateCurrentModalState: (state: CurrentModalState | null) => void
            toastState: ToastState | null
            updateToastState: (state: ToastState | null) => void
            isOpenPluginDevelopment: boolean
            updateIsOpenPluginDevelopment: (state: boolean) => void
            localPlugins: LocalPlugin[]
            updateLocalPlugins: (plugins: LocalPlugin[], updateLocalStorage?: boolean) => void
            pluginPublishDrafts: Record<string, PluginPublishDraft>
            updatePluginPublishDrafts: (drafts: Record<string, PluginPublishDraft>) => void
            isOpenPluginManagement: boolean
            updateIsOpenPluginManagement: (state: boolean) => void
            publishedPlugins: PluginVO[] // 插件菜单栏的已发布私有插件
            updatePublishedPlugins: (plugins: PluginVO[]) => void
            editablePublishedPlugins: PluginVO[] // 插件开发面板的已发布私有插件
            updateEditablePublishedPlugins: (plugins: PluginVO[]) => void
            editablePluginIds: Set<PluginId>
            updateEditablePluginIds: (ids: PluginId[]) => void
            isGuest: boolean
            updateIsGuest: (state: boolean) => void
        }>(
            (set) => ({
                currentModalState: null,
                updateCurrentModalState: (state: CurrentModalState | null) => {
                    set({ currentModalState: state })
                },
                toastState: null,
                updateToastState: (state: ToastState | null) => {
                    set({ toastState: state })
                },
                isOpenPluginDevelopment: false,
                updateIsOpenPluginDevelopment: (state: boolean) => {
                    if (state) {
                        this.updateLocalPluginFileChangeCallbacks(this.states.getState().localPlugins)
                    } else {
                        this.clearLocalPluginFileChangeCallbacks()
                    }
                    set({ isOpenPluginDevelopment: state })
                },
                localPlugins: [],
                updateLocalPlugins: (plugins: LocalPlugin[], updateLocalStorage = true) => {
                    this.updateLocalPluginFileChangeCallbacks(plugins)
                    if (updateLocalStorage) {
                        setStorageLocalPlugins(
                            plugins.map((plugin) => ({
                                ...plugin,
                                publishInfo: undefined,
                            })),
                            this.orgId,
                            this.userId
                        )
                    }
                    set({ localPlugins: plugins })
                },
                pluginPublishDrafts: {},
                updatePluginPublishDrafts: (drafts: Record<string, PluginPublishDraft>) => {
                    set({ pluginPublishDrafts: drafts })
                },
                isOpenPluginManagement: false,
                updateIsOpenPluginManagement: (state: boolean) => {
                    set({ isOpenPluginManagement: state })
                },
                publishedPlugins: [],
                updatePublishedPlugins: (plugins: PluginVO[]) => {
                    set({ publishedPlugins: plugins })
                },
                editablePublishedPlugins: [],
                updateEditablePublishedPlugins: (plugins: PluginVO[]) => {
                    set({ editablePublishedPlugins: plugins })
                },
                editablePluginIds: new Set(),
                updateEditablePluginIds: (ids: PluginId[]) => {
                    set({ editablePluginIds: new Set(ids) })
                },
                isGuest: true,
                updateIsGuest: (state: boolean) => {
                    set({ isGuest: state })
                },
            }),
            environment.isDev
        )
    )
    private activatedPluginCloseFn: (() => any) | undefined
    private localFileChangeCallbacks: Map<string, (_filepath: string) => Promise<void>> = new Map()
    private localRunningPluginPath = ''

    constructor(
        controller: EffectController,
        private commandInvoker: CommandInvoker,
        private userConfigService: UserConfigService,
        private pluginHostService: PluginHostService,
        private bridge: Bridge,
        private clientService: ClientService,
        private userId: number,
        private orgId: string,
        private readonly docId: string
    ) {
        super(controller)
        this.pluginHotReloadService = new PluginHotReloadService(controller, clientService, pluginHostService)
        this.officialPlugins.push(ArcText)
        this.officialPlugins.push(Looper)
        this.officialPlugins.push(RotateCopy)
        this.officialPlugins.push(AE)
        this.officialPlugins.push(FillRuleEditor)
        this.officialPlugins.push(Unsplash)
        this.officialPlugins.push(Iconpark)
        if (featureSwitchManager.isEnabled('open-mouse-scale-speed')) {
            this.officialPlugins.push(AdjustMouseScaleSpeed)
        }
        if (featureSwitchManager.isEnabled('handle-wheel-debugger')) {
            this.officialPlugins.push(WheelEventDebugPlugin)
        }

        this.bridge.bind(ShowLastUsedPlugin, this.showLastUsedPlugin.bind(this))
        this.bridge.bind(ToastShow, (proto) => {
            switch (proto.type) {
                case Wukong.DocumentProto.ToastType.TOAST_TYPE_DEFAULT: {
                    WKToast.show(proto.value, { dataTestIds: { toast: proto.dataTestId ?? undefined } })
                    break
                }
                case Wukong.DocumentProto.ToastType.TOAST_TYPE_ERROR: {
                    WKToast.error(proto.value, { dataTestIds: { toast: proto.dataTestId ?? undefined } })
                    break
                }
                default: {
                    WKToast.show(proto.value, { dataTestIds: { toast: proto.dataTestId ?? undefined } })
                    break
                }
            }
        })
        this.bridge.bind(PluginShowToast, (proto: Wukong.DocumentProto.IBridgeProtoString) => {
            if (this.states.getState().currentModalState) {
                this.showToast({
                    message: proto.value!,
                    type: PluginToastType.Default,
                })
            }
        })
        // 向客户端提供插件菜单的内容信息
        this.clientService.bindClientRequestHandler('plugin-menu-items', this.generClientPluginMenuItems)
        // 响应客户端点击插件项
        window.localBridge?.bindClickPluginMenuItem?.(this.handleClientClickPluginMenuItem)
        this.fetchPublishedPlugins()
        this.fetchEditablePublishedPlugins()
        this.pluginHostService.bindClosePluginCallback(() => {
            this.localRunningPluginPath = ''
        })

        this.injectCreateFileCallBack(async () => {
            // 获取文档的最新 orgId，对于新建文件来说，service 初始化阶段还拿不到 orgId（为 -1），需要在 createFile 后获取最新的
            await new GetDocRequest(this.docId).start().then((docInfo) => {
                if (this.controller.aborted) {
                    return
                }
                this.orgId = docInfo.orgId
                // 更新客户端菜单栏
                window.localBridge?.updatePluginMenu?.()
                // 从 localstorage 加载本地插件缓存
                const localPlugins = getStorageLocalPlugins(this.orgId, this.userId)
                if (localPlugins) {
                    this.states.getState().updateLocalPlugins(localPlugins, false)
                }
                this.fetchPublishedPlugins()
                this.fetchEditablePublishedPlugins()
            })
            if (this.controller.aborted) {
                return
            }
            await this.fetchIsGuest()
            window.localBridge?.updatePluginMenu?.()
        })

        window.addEventListener('storage', this.handleLocalStoragePluginChange)
    }

    showLastUsedPlugin() {
        const lastUsedPluginId = this.userConfigService.useZustandStore2.getState().lastUsedPluginId
        if (lastUsedPluginId) {
            const officialPluginId = Wukong.DocumentProto.PluginType[lastUsedPluginId as any] as unknown as
                | Wukong.DocumentProto.PluginType
                | undefined
            if (officialPluginId !== undefined) {
                this.showModal(officialPluginId)
            } else {
                this.runPublishedPlugin(lastUsedPluginId)
            }
        }
    }

    showModal = (
        key: Wukong.DocumentProto.PluginType,
        options?: {
            width?: number
            height?: number
        }
    ) => {
        motiff.closePlugin()
        this.states.getState().updateCurrentModalState({
            key,
            width: options?.width,
            height: options?.height,
        })
        window.localBridge?.updatePluginMenu?.()
        this.commandInvoker.DEPRECATED_invokeBridge(
            UpdateActivatedPluginCommand,
            Wukong.DocumentProto.Args_UpdateActivatedPluginCommand.create({
                activatedPluginType: key,
            })
        )
    }
    injectActivatedPluginCloseFn = (fn: any) => {
        this.activatedPluginCloseFn = fn
    }
    clearActivatedPluginCloseFn = () => {
        this.activatedPluginCloseFn = undefined
    }
    closeModal = () => {
        if (this.activatedPluginCloseFn) {
            this.activatedPluginCloseFn()
        }
        this.states.getState().updateCurrentModalState(null)
        this.closeToast()
        this.commandInvoker.DEPRECATED_invokeBridge(
            UpdateActivatedPluginCommand,
            Wukong.DocumentProto.Args_UpdateActivatedPluginCommand.create({
                activatedPluginType: null,
            })
        )
    }

    destroy = () => {
        this.bridge.unbind(ShowLastUsedPlugin)
        this.bridge.unbind(ToastShow)
        this.bridge.unbind(PluginShowToast)
        this.clientService.unbindClientRequestHandler('plugin-menu-items')
        this.clearLocalPluginFileChangeCallbacks()
        window.removeEventListener('storage', this.handleLocalStoragePluginChange)
    }

    showToast = (props: PluginToastProps) => {
        if (props.message !== this.states.getState().toastState?.message) {
            this.closeToast()
            setTimeout(() => {
                this.states.getState().updateToastState({
                    show: true,
                    ...props,
                })
            }, 0)
        } else {
            this.states.getState().updateToastState({
                show: true,
                ...props,
            })
        }
    }

    closeToast = () => {
        this.states.getState().updateToastState(null)
    }

    getEnabledPluginsForEditor = () => {
        // 非企业版或访客不支持私有插件
        if (!this.orgId || this.orgId === '-1' || this.states.getState().isGuest) {
            return []
        }

        const publishedPlugins = this.states.getState().publishedPlugins
        const pluginEnableConfig = (() => {
            return this.userConfigService.useZustandStore2.getState().pluginEnableConfig
        })()
        return publishedPlugins
            .filter((plugin) => pluginEnableConfig[plugin.id]?.enable)
            .sort((a, b) => pluginEnableConfig[b.id]?.lastEnableTime - pluginEnableConfig[a.id]?.lastEnableTime)
    }

    // 编辑器内运行企业内发布的插件
    runPublishedPlugin = async (pluginId: string, useSandbox = true) => {
        const plugin = this.states.getState().publishedPlugins.find((p) => p.id === pluginId)
        if (!plugin) {
            return
        }

        const pluginCodeContent = await this.getPublishedPluginCodeContent(pluginId, plugin.codeContentUrl).catch(
            (e) => {
                console.error(e)
                return null
            }
        )

        if (!pluginCodeContent) {
            WKToast.error(translation('RunPluginFailed'))
            return
        }

        // 缓存插件代码
        this.states.getState().updatePublishedPlugins(
            this.states.getState().publishedPlugins.map((p) => {
                if (p.id === pluginId) {
                    return {
                        ...p,
                        codeContentUrl: pluginCodeContent,
                    }
                }
                return p
            })
        )

        debugLog('runPublishedPlugin: ', pluginCodeContent)
        this.userConfigService.updateConfig2('lastUsedPluginId', pluginId)
        // 更新客户端菜单的「运行上一次插件」内容
        window.localBridge?.updatePluginMenu?.()

        this.loadPlugin({
            id: pluginId,
            name: plugin.name,
            rawCode: pluginCodeContent,
            useSandbox,
            iconUrl: plugin.iconUrl,
            runningMode: 'default',
        })
    }

    // 插件开发面板运行发布版本
    runPublishedPluginForDevelopment = async (publishedPlugin: PluginVO) => {
        debugLog('runPublishedPluginForDevelopment: ', publishedPlugin)

        try {
            const plublishedCodeContent = await this.getPublishedPluginCodeContent(
                publishedPlugin.id,
                publishedPlugin.codeContentUrl
            )
            if (!plublishedCodeContent) {
                return
            }

            // 缓存插件代码
            this.states.getState().updateEditablePublishedPlugins(
                this.states.getState().editablePublishedPlugins.map((p) => {
                    if (p.id === publishedPlugin.id) {
                        return {
                            ...p,
                            codeContentUrl: plublishedCodeContent,
                        }
                    }
                    return p
                })
            )

            this.loadPlugin({
                id: publishedPlugin.id,
                name: publishedPlugin.name,
                rawCode: plublishedCodeContent,
                useSandbox: true,
                iconUrl: publishedPlugin.iconUrl,
                runningMode: 'default',
            })
        } catch (e) {
            WKToast.error(translation('RunPluginFailed'), {
                secondButton: {
                    type: 'button',
                    text: translation('OpenConsole'),
                    onClick: () => {
                        window.localBridge?.toggleDevTools?.(true)
                    },
                },
            })
        }
    }

    private getPublishedPluginCodeContent = async (pluginId: string, plublishedCodeContent: string) => {
        if (!plublishedCodeContent.startsWith('http')) {
            return plublishedCodeContent
        }

        const latestPublishedPlugin = await fetchGetPublishedPluginsByIds(this.orgId, [pluginId])
        return await fetch(latestPublishedPlugin[0].codeContentUrl).then((res) => res.text())
    }

    public runLocalPlugin = async (path: string) => {
        try {
            const data = await getLocalPluginData(path)
            if (!data) {
                throw new Error()
            }

            const { manifest, codeContent } = data

            const isUseSandbox = this.userConfigService.useZustandStore2.getState().pluginDevUseSandbox

            debugLog('runCustomPlugin code: ', codeContent)
            this.loadPlugin({
                id: manifest.id,
                name: manifest.name,
                rawCode: codeContent,
                useSandbox: isUseSandbox,
                runningMode: 'default',
            })

            this.localRunningPluginPath = path

            const isUseHotLoad = this.userConfigService.useZustandStore2.getState().pluginDevUseHotReload

            if (isUseHotLoad) {
                this.openLocalPluginHotReload()
            }
        } catch (e) {
            WKToast.error(translation('RunPluginFailed'), {
                secondButton: {
                    type: 'button',
                    text: translation('OpenConsole'),
                    onClick: () => {
                        window.localBridge?.toggleDevTools?.(true)
                    },
                },
            })
        }
    }

    public openLocalPluginHotReload = () => {
        if (!this.localRunningPluginPath) {
            return
        }
        const path = this.localRunningPluginPath

        this.pluginHotReloadService.bindHotReloadPluginOnce(path, async () => {
            const lastedIsUseHotLoad = this.userConfigService.useZustandStore2.getState().pluginDevUseHotReload

            // 热重载回调时判断最新是否设置了热重载，如果未设置则不响应
            if (!lastedIsUseHotLoad) {
                return
            }

            motiff.closePlugin()
            this.runLocalPlugin(path) // 递归调用自重新绑定插件监听（因为 manifest 文件可能会更改 ui 和 main 路径，需要重新监听）
        })
    }

    public fetchPublishedPlugins = async () => {
        const plugins = await fetchGetPublishedPlugins(this.orgId)
        if (this.controller.aborted) {
            return
        }
        plugins.sort((a, b) => b.lastEditTime - a.lastEditTime)
        this.states.getState().updatePublishedPlugins(plugins)
        window.localBridge?.updatePluginMenu?.()
    }

    public fetchEditablePublishedPlugins = async () => {
        const res = await fetchGetEditablePlugins(this.orgId)
        if (this.controller.aborted) {
            return
        }
        this.states.getState().updateEditablePluginIds(res.map((plugin) => plugin.id))

        const plugins = res.filter((plugin) => plugin.published).sort((a, b) => b.lastEditTime - a.lastEditTime)
        this.states.getState().updateEditablePublishedPlugins(plugins)
    }

    private handleLocalStoragePluginChange = (event: StorageEvent) => {
        if (event.key === LocalStorageKey.PluginDevLocalPlugins) {
            const plugins = getStorageLocalPlugins(this.orgId, this.userId)
            this.states.getState().updateLocalPlugins(plugins, false)
        }
    }

    private loadPlugin = (options: LoadPluginOptions) => {
        this.closeModal()
        this.pluginHostService.loadPlugin(options)
    }

    private injectCreateFileCallBack(fn: () => void) {
        if (createFileManager.isCreatingFile()) {
            createFileManager.injectCreateFileCallBack(fn)
        } else {
            fn()
        }
    }

    private fetchIsGuest = async () => {
        if (!this.orgId || this.orgId === '-1') {
            this.states.getState().updateIsGuest(true)
            return
        }

        const orgVOs = await new GetOrganizations().start()
        const orgVO = orgVOs.find((org) => org.id === this.orgId)

        if (orgVO?.guest) {
            this.states.getState().updateIsGuest(true)
            return
        }

        this.states.getState().updateIsGuest(false)
    }

    private generClientPluginMenuItems = (): ClientPluginMenuItem[] => {
        if (!isPluginDevelopmentEnabled()) {
            return []
        }

        const officialPlugins: ClientPluginMenuItem[] = this.officialPlugins.map((plugin) => {
            return {
                label: plugin.manifest.name,
                type: 'normal',
                clickValue: plugin.manifest.key,
            }
        })

        const publishedEnabledPlugins: ClientPluginMenuItem[] = this.getEnabledPluginsForEditor().map((plugin) => {
            return {
                label: plugin.name,
                type: 'normal',
                clickValue: plugin.id,
            }
        })

        const lastUsedPluginId = this.userConfigService.useZustandStore2.getState().lastUsedPluginId
        const runLastUsedPlugin: ClientPluginMenuItem = {
            label: translation('RunLastPlugin'),
            type: 'normal',
            clickValue: ClientPluginItemClickValue.RunLastUsedPlugin,
            accelerator: 'Option+CommandOrControl+P',
        }

        const devPlugin: ClientPluginMenuItem = {
            label: translation('DevPrivatePlugin'),
            type: 'normal',
            clickValue: ClientPluginItemClickValue.DevPlugin,
        }
        const isOrganizationMember = this.orgId && this.orgId !== '-1' && !this.states.getState().isGuest
        const isAbroad = environment.isAbroad

        const sep: ClientPluginMenuItem = { type: 'separator' }
        const isPluginEnabled =
            isOrganizationMember && (!isAbroad || (isAbroad && featureSwitchManager.isEnabled('abroad-plugin')))
        return [
            ...officialPlugins,
            ...(isOrganizationMember ? publishedEnabledPlugins : []),
            ...(lastUsedPluginId ? [sep, runLastUsedPlugin] : []),
            ...(isPluginEnabled ? [sep, devPlugin] : []),
        ]
    }

    private handleClientClickPluginMenuItem = (key: string | Wukong.DocumentProto.PluginType) => {
        if (typeof key === 'number') {
            this.userConfigService.updateConfig2('lastUsedPluginId', Wukong.DocumentProto.PluginType[key])
            this.showModal(key as Wukong.DocumentProto.PluginType)
        } else {
            if (key === ClientPluginItemClickValue.RunLastUsedPlugin) {
                this.showLastUsedPlugin()
            } else if (key === ClientPluginItemClickValue.DevPlugin) {
                this.states.getState().updateIsOpenPluginDevelopment(true)
            } else {
                this.runPublishedPlugin(key)
            }
        }
    }

    private updateLocalPluginFileChangeCallbacks = (plugins: LocalPlugin[]) => {
        const pluginLocalFilePaths = new Set(plugins.map((plugin) => `${plugin.path}${fileSep()}manifest.json`))

        // 注销删除的 plugin 回调
        for (const [localFilePath, callback] of this.localFileChangeCallbacks.entries()) {
            if (!pluginLocalFilePaths.has(localFilePath)) {
                this.clientService.unbindClientLocalFileChangeListener(localFilePath, callback)
                this.localFileChangeCallbacks.delete(localFilePath)
            }
        }

        // 注册新增 plugin 回调
        for (const localFile of pluginLocalFilePaths) {
            if (!this.localFileChangeCallbacks.has(localFile)) {
                const callback = async (filePath: string) => {
                    const { manifest, error } = await getLocalPluginManifest(filePath)
                    const localPlugins = this.states.getState().localPlugins
                    const changedPlugin = localPlugins.find(
                        (plugin) => `${plugin.path}${fileSep()}manifest.json` === filePath
                    )
                    if (!changedPlugin) return

                    // 本地文件不存在时，如果该插件已发布，则直接删除该插件
                    if (error === PluginLocalManifestFileError.NotFoundError) {
                        if (
                            this.states
                                .getState()
                                .editablePublishedPlugins.find((plugin) => plugin.id === changedPlugin.id)
                        ) {
                            this.states.getState().updateLocalPlugins(
                                localPlugins.filter((plugin) => {
                                    return plugin !== changedPlugin
                                })
                            )
                            return
                        }
                    }

                    if (error) {
                        this.states.getState().updateLocalPlugins(
                            localPlugins.map((plugin) => {
                                if (plugin === changedPlugin) {
                                    return {
                                        ...plugin,
                                        localManifestFileError: error,
                                        lastEditTime: Date.now(),
                                    }
                                }
                                return plugin
                            })
                        )
                    }

                    if (manifest) {
                        this.states.getState().updateLocalPlugins(
                            localPlugins.map((plugin) => {
                                if (plugin === changedPlugin) {
                                    return {
                                        ...plugin,
                                        id: manifest.id,
                                        name: manifest.name,
                                        localManifestFileError: undefined,
                                        manifest,
                                        lastEditTime: Date.now(),
                                    }
                                }
                                return plugin
                            })
                        )
                    }
                }

                this.clientService.bindClientLocalFileChangeListener(localFile, callback)
                this.localFileChangeCallbacks.set(localFile, callback)
            }
        }
    }

    private clearLocalPluginFileChangeCallbacks = () => {
        for (const [localFilePath, callback] of this.localFileChangeCallbacks.entries()) {
            this.clientService.unbindClientLocalFileChangeListener(localFilePath, callback)
        }
        this.localFileChangeCallbacks.clear()
    }
}

function getStorageLocalPlugins(orgId: string, userId: number) {
    const storagePlugins = enhancedLocalStorage.getSerializedItem(LocalStorageKey.PluginDevLocalPlugins)
    if (!storagePlugins) {
        return []
    }

    const key = `${orgId}-${userId}`
    return storagePlugins[key] ?? []
}

function setStorageLocalPlugins(plugins: LocalPlugin[], orgId: string, userId: number) {
    const key = `${orgId}-${userId}`
    enhancedLocalStorage.setSerializedItem(LocalStorageKey.PluginDevLocalPlugins, {
        ...enhancedLocalStorage.getSerializedItem(LocalStorageKey.PluginDevLocalPlugins),
        [key]: plugins,
    })
}
