import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    Untitled: '未命名',
    FailedToCreate: '文件创建失败',
} as const

export const enTranslation = {
    Untitled: 'Untitled',
    FailedToCreate: 'An error occurred while creating file',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
