import classnames from 'classnames'
import { isNil } from 'lodash-es'
import React, { CSSProperties, forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { useMount, useUpdateEffect } from 'react-use'
// eslint-disable-next-line import/no-restricted-paths
import { isKeyAsEnter } from '../../../../../app/src/kernel/keyboard/keyboard-event-handler'
import { HtmlInput, HtmlInputProps } from '../html-input/html-input'
import classes from './normal-input.module.less'

/**
 * - 指定value时的特别说明
 *  - 与受控组件不同的是，在指定value后，即使没有指定onchange之类动态更新value值的回调，输入框的值也能更新。但是这种更新是临时的。input失焦后清除临时状态显示传入的input，也就是如果没有把编辑中的值更新到value里，那么编辑状态不会保留
 *  - 为什么行为不按【受控组件】来？最初的base-input也是这种行为。这种行为对应着实际的需求（比如ctrl+z的撤销和esc的退出，都需要中间状态的支持。也可以把使用defaultValue，不过使用似乎有些奇怪不再是value-onchange.而是defaultValue-onchange）。
 *  - 传defaultValue或value的区别？没啥太太区别，不传value会当非受控组件，失焦不清空编辑中的值。
 */
export interface NormalInputProps extends Omit<HtmlInputProps, 'size'> {
    rootClassName?: string
    size?: 'small' | 'medium' | 'large' // 28px、32px、48px
    backgroundWhite?: boolean
    minWidth?: number
    maxWidth?: number
    width?: number
    fontWeight?: 'normal' | 'bold'
    forwardIcon?: React.ReactNode
    backwardIcon?: React.ReactNode
    ellipsis?: boolean // input 文字 是否超出显示省略号
    error?: {
        show?: boolean
        tipMessage?: string // 报错的提示文字
    }
    removeLeftPadding?: boolean
    removeRightPadding?: boolean
    removeDefaultBackgroundColor?: boolean
    dataTestIds?: { root?: string; input?: string }
}

export interface NormalInputRef {
    getInputElement: () => HTMLInputElement
}

function _NormalInput(props: NormalInputProps, ref?: React.Ref<NormalInputRef>) {
    const {
        rootClassName,
        size = 'medium',
        backgroundWhite,
        minWidth,
        maxWidth,
        width,
        fontWeight = 'normal',
        forwardIcon,
        backwardIcon,
        ellipsis,
        error,
        removeLeftPadding,
        removeRightPadding,
        removeDefaultBackgroundColor,
        dataTestIds,
        disabled,
        readOnly,
        value,
        defaultValue,
        style,
        className,
        onFocus,
        onBlur,
        onKeyDown,
        onChange,
        ...otherProps
    } = props
    const [inputFocus, setInputFocus] = useState<boolean>(false)
    const [editingValue, setEditingValue] = useState<string | null>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const initValueRef = useRef<string>()

    const getValueForRender = useCallback(
        (valueForRender?: string | null) => {
            if (!isNil(valueForRender)) {
                return valueForRender
            } else if (!isNil(value)) {
                return String(value)
            }
            return String(defaultValue ?? '')
        },
        [defaultValue, value]
    )

    const rootStyle = useMemo((): CSSProperties => {
        return { minWidth, maxWidth, width, ...style }
    }, [maxWidth, minWidth, style, width])

    const inputAreaStyle = useMemo((): CSSProperties => {
        const backgroundColor = backgroundWhite ? 'var(--wk-white)' : undefined
        return { backgroundColor }
    }, [backgroundWhite])

    const inputStyle = useMemo((): CSSProperties => {
        return {
            fontWeight:
                fontWeight === 'normal'
                    ? 'var(--wk-font-weight-regular)'
                    : fontWeight === 'bold'
                    ? 'var(--wk-font-weight-medium)'
                    : undefined,
        }
    }, [fontWeight])

    const isControlledComponent = useMemo(() => value !== undefined, [value])

    const _onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setEditingValue(e.target.value)
            onChange?.(e)
        },
        [onChange]
    )

    const _onFocus = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            setInputFocus(true)
            onFocus?.(e)
        },
        [onFocus]
    )

    const _onBlur = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            setInputFocus(false)
            onBlur?.(e)
            // 受控组件（指定value）失焦后清除编辑状态
            if (isControlledComponent) {
                setTimeout(setEditingValue, 0, null)
            }
        },
        [isControlledComponent, onBlur]
    )

    const _onKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            const inputElement = e.target as HTMLInputElement
            if (isKeyAsEnter(e.nativeEvent)) {
                setTimeout(() => inputElement?.blur(), 0)
            } else if (e.code === 'Escape') {
                setEditingValue(null)
                // 等react的editingValue状态更新后失焦
                setTimeout(() => inputElement?.blur(), 0)
            }
            onKeyDown?.(e)
        },
        [onKeyDown]
    )

    useMount(() => {
        initValueRef.current = getValueForRender()
        if (props.autoFocus) {
            inputRef.current?.focus()
            inputRef.current?.select()
        }
    })

    useUpdateEffect(() => {
        const currInitValue = getValueForRender()
        if (initValueRef.current === currInitValue) {
            return
        }
        setEditingValue(null)
        initValueRef.current = currInitValue
    }, [getValueForRender])

    useImperativeHandle(ref, () => ({ getInputElement: () => inputRef.current! }), [])

    return (
        <div
            className={classnames(classes.root, [rootClassName], [classes[size]], {
                [classes.disabled]: disabled,
                [classes.inputFocus]: inputFocus,
                [classes.error]: error?.show,
                [classes.readOnly]: readOnly,
            })}
            style={rootStyle}
            data-testid={dataTestIds?.root}
        >
            <div
                className={classnames(classes.inputArea, {
                    [classes.removeDefaultBackgroundColor]: removeDefaultBackgroundColor,
                })}
                style={inputAreaStyle}
            >
                <div
                    className={classes.hoverLayer}
                    style={{
                        ...(removeLeftPadding ? { paddingLeft: 0 } : {}),
                        ...(removeRightPadding ? { paddingRight: 0 } : {}),
                    }}
                >
                    {forwardIcon !== undefined ? <div className={classes.forwardIcon}>{forwardIcon}</div> : null}
                    <HtmlInput
                        ref={inputRef}
                        className={classnames(classes.input, [className], { [classes.inputEllipsis]: ellipsis })}
                        style={inputStyle}
                        disabled={disabled}
                        readOnly={readOnly}
                        value={getValueForRender(editingValue)}
                        onChange={_onChange}
                        onFocus={_onFocus}
                        onBlur={_onBlur}
                        onKeyDown={_onKeyDown}
                        data-testid={dataTestIds?.input}
                        {...otherProps}
                    />
                    {backwardIcon !== undefined ? <div className={classes.backwardIcon}>{backwardIcon}</div> : null}
                </div>
            </div>
            {error?.show && error?.tipMessage ? <div className={classes.errorMessage}>{error.tipMessage}</div> : null}
        </div>
    )
}

export const NormalInput = forwardRef(_NormalInput)
