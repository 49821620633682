import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    HyperlinkFormatError: '链接格式错误，请检查后再次输入',
} as const

export const enTranslation = {
    HyperlinkFormatError: 'Invalid link',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
