import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    zwScDh: '替换实例失败',
    BDDuSC: '正在替换实例',
    RestoreMainComponent: '恢复组件',
    RestoreComponentTip: '该实例的组件母版嵌套在其他组件中，Motiff 会将该实例的组件母版及其嵌套的组件母版一并恢复。',
    Cancel: '取消',
    Restore: '恢复',
} as const

export const enTranslation = {
    zwScDh: 'An error occurred while swapping instance',
    BDDuSC: 'Swapping instance',
    RestoreMainComponent: 'Restore main component',
    RestoreComponentTip:
        'The main component of this instance is nested within other components. Motiff will restore the main component of this instance along with its nested components.',
    Cancel: 'Cancel',
    Restore: 'Restore',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
