/* eslint-disable no-restricted-imports */
import { GET, POST, PUT } from '@tutor/network-core'
import { useEffect, useState } from 'react'
import { environment } from '../../environment'
import { FileCosAuthorizationVO } from '../interface/image-cos'
import { AWSImageProcessService } from './aws-image-process'
import { BaseCommonRequest } from './base-request'

// 获取授权
@GET
export class GetUploadAuthorization extends BaseCommonRequest<FileCosAuthorizationVO> {
    constructor(private readonly format: string) {
        super()
    }

    public override requestArgument() {
        return { format: this.format }
    }

    public override requestUrl() {
        return 'image/cos/public/uploadAuthorization'
    }
}

@GET
export class GetPrivateUploadAuthorization extends BaseCommonRequest<FileCosAuthorizationVO> {
    constructor(private readonly data: { format: string; fileName?: string }) {
        super()
    }

    public override requestArgument() {
        return { ...this.data, t: Math.random() * 1e18 }
    }

    public override requestUrl() {
        return 'image/cos/private/uploadAuthorization'
    }

    public override requestTimeout(): number {
        return 60 * 100
    }
}

interface GetPrivateUploadAuthorizationsArg {
    format2Count: Record<string, number>
}

type GetPrivateUploadAuthorizationsRet = Record<string, FileCosAuthorizationVO[]>

@POST
export class GetPrivateUploadAuthorizations extends BaseCommonRequest<GetPrivateUploadAuthorizationsRet> {
    constructor(private readonly data: GetPrivateUploadAuthorizationsArg) {
        super()
    }

    public override requestArgument() {
        return { t: Math.random() * 1e18 }
    }

    public override requestBody() {
        return this.data
    }

    public override requestUrl() {
        return 'image/cos/private/uploadAuthorization'
    }

    public override requestTimeout(): number {
        return 60 * 100
    }
}

@PUT
export class PutFile extends BaseCommonRequest {
    constructor(private readonly url: string, private file: Blob, private headers: any) {
        super()
    }

    public override additionalRequestHeaders() {
        return this.headers
    }

    public override withCredentials(): boolean {
        return false
    }

    public override requestBody() {
        return this.file
    }
    public override baseUrl(): string {
        return this.url
    }

    public override requestTimeout(): number {
        return 60 * 100
    }
}

// 获取上传文件需要设置的自定义 header
export function getUploadHeader(param: { contentType: string }): Record<string, string> {
    return {
        'Content-Type': param.contentType,
        'Cache-Control': 'public, max-age=2592000',
    }
}

// 这个方法只用于上传头像，因为海外版会限制最大宽度
export const uploadAvatarImage = async (file: Blob) => {
    const { contentType, resourceId, ossUrl } = await new GetUploadAuthorization(file.type.split('/')[1]).start()
    await new PutFile(ossUrl, file, getUploadHeader({ contentType })).start()
    if (environment.isAbroad) {
        // 如果是海外版，需要裁剪后下载下来，再次上传
        const { url } = await new AWSImageProcessService(`public/resource/image/${resourceId}`, {
            resize: {
                height: 300, // 和 ui 商量之后，300 是一个考虑未来需求后的安全的范围
                fit: 'inside',
                withoutEnlargement: true,
            },
        }).start()
        const newFile = await fetch(url).then((res) => res.blob())
        await new PutFile(ossUrl, newFile, getUploadHeader({ contentType })).start()
    }
    return { resourceId }
}

export class UploadImageService {
    static PRIVIEW_SIZE = 512
    public static async uploadPrivateImages(_files: Blob[], createPreview = false, allowFail = false) {
        if (createPreview && _files.length > 50) {
            throw Error('创建预览图片时，最多支持 50 张图片')
        } else if (_files.length > 100) {
            throw Error('最多支持 100 张图片')
        }

        const format2Count: Record<string, number> = {}
        const files = _files.map((file) => [file, file.type.split('/')[1]] as const)
        for (const [_, format] of files) {
            format2Count[format] ??= 0
            format2Count[format] += createPreview ? 2 : 1
        }

        const format2List = await new GetPrivateUploadAuthorizations({ format2Count }).start()
        const ret: Array<{ resourceId: string; previewId: string }> = new Array(files.length).fill(0).map((v) => v)
        await Promise.all(
            files.map(async ([file, format], index) => {
                const { ossUrl, contentType, resourceId } = format2List[format].pop()!

                this.putFile(ossUrl, file, contentType).catch((err) => {
                    if (!allowFail) {
                        throw err
                    } else {
                        console.error(err)
                    }
                })

                let previewId = ''
                // 来自sketch的导入需要创建预览图；figma的不需要，因为自带了预览图
                if (createPreview) {
                    const image = await createImageBitmap(file)
                    if (image.width > this.PRIVIEW_SIZE || image.height > this.PRIVIEW_SIZE) {
                        const { previewWidth, previewHeight } = this.reduceImageSize(image.width, image.height)
                        const canvas = new OffscreenCanvas(previewWidth, previewHeight)

                        // 将图片绘制到 Canvas 元素上
                        const ctx = canvas.getContext('2d')
                        if (ctx) {
                            canvas.width = previewWidth
                            canvas.height = previewHeight

                            ctx?.drawImage(image, 0, 0, previewWidth, previewHeight)
                            const imageFile = await canvas.convertToBlob()

                            const {
                                ossUrl: previewOssUrl,
                                resourceId: previewResourceId,
                                contentType: previewContentType,
                            } = format2List[format].pop()!

                            this.putFile(previewOssUrl, imageFile, previewContentType).catch((err) => {
                                if (!allowFail) {
                                    throw err
                                } else {
                                    console.error(err)
                                }
                            })
                            previewId = previewResourceId
                        }
                    }
                }

                ret[index] = { resourceId, previewId }
            })
        )
        return ret
    }

    public static async uploadPrivateImagesAsync(_files: Blob[]) {
        if (_files.length > 100) {
            throw Error('最多支持 100 张图片')
        }

        const format2Count: Record<string, number> = {}
        const files = _files.map((file) => [file, file.type.split('/')[1]] as const)
        for (const [_, format] of files) {
            format2Count[format] ??= 0
            format2Count[format]++
        }

        const format2List = await new GetPrivateUploadAuthorizations({ format2Count }).start()
        const ret: Array<{ resourceId: string; file: Blob; contentType: string; ossUrl: string }> = new Array(
            files.length
        )
            .fill(0)
            .map((v) => v)
        files.map(([file, format], index) => {
            const { contentType, resourceId, ossUrl } = format2List[format].pop()!
            ret[index] = { resourceId, file, contentType, ossUrl }
        })
        return ret
    }

    public static async uploadPrivateImage(file: Blob, createPreview = false) {
        const list = await this.uploadPrivateImages([file], createPreview)
        return list[0]
    }

    private static reduceImageSize(width: number, height: number) {
        while (width > 512 || height > 512) {
            width /= 2
            height /= 2
        }
        return {
            previewWidth: Math.ceil(width),
            previewHeight: Math.ceil(height),
        }
    }

    public static async putFile(url: string, file: Blob, contentType: string) {
        const request = await new PutFile(
            url,
            file,
            getUploadHeader({
                contentType: contentType,
            })
        ).start(false)
        if (request.responseStatusCode < 200 || request.responseStatusCode >= 300) {
            throw new Error(`bad status code: ${request.responseStatusCode}`)
        }
    }
}

export const fullImageUrl = async (id: string, config?: { width?: number; minify: boolean }) => {
    if (config?.minify && config.width) {
        if (environment.isAbroad) {
            return await new AWSImageProcessService(`public/resource/image/${id}`, {
                resize: {
                    height: config.width * 2,
                    fit: 'inside',
                    withoutEnlargement: true,
                },
            })
                .start()
                .then(({ url }) => url)
        }
        return `${environment.ossCdnPath}public/resource/image/${id}?x-oss-process=image/resize,h_${
            config.width * 2
        },m_lfit`
    }
    return `${environment.ossCdnPath}public/resource/image/${id}`
}

export const getFullAvatarImageUrl = (id: string, config?: { width?: number; minify: boolean }) => {
    if (config?.minify && config.width) {
        if (environment.isAbroad) {
            // 海外头像数据都为 固定尺寸 300 * 300
            return `${environment.ossCdnPath}public/resource/image/${id}`
        }
        return `${environment.ossCdnPath}public/resource/image/${id}?x-oss-process=image/resize,h_${
            config.width * 2
        },m_lfit`
    }
    return `${environment.ossCdnPath}public/resource/image/${id}`
}

export const useFullImageUrl = (id?: string, config?: { width?: number; minify: boolean }) => {
    const [imageUrl, setImageUrl] = useState<string>()

    useEffect(() => {
        if (!id) {
            setImageUrl('')
            return
        }
        if (config?.minify && config.width) {
            if (environment.isAbroad) {
                // 等服务端刷完数据，放开下面一行的注释，删掉调接口的代码
                // setImageUrl(`${environment.ossCdnPath}public/resource/image/${id}`)
                new AWSImageProcessService(`public/resource/image/${id}`, {
                    resize: {
                        height: config.width * 2,
                        fit: 'inside',
                        withoutEnlargement: true,
                    },
                })
                    .start()
                    .then(({ url }) => {
                        setImageUrl(url)
                    })
            } else {
                setImageUrl(
                    `${environment.ossCdnPath}public/resource/image/${id}?x-oss-process=image/resize,h_${
                        config.width * 2
                    },m_lfit`
                )
            }
        } else {
            setImageUrl(`${environment.ossCdnPath}public/resource/image/${id}`)
        }
    }, [config?.minify, config?.width, id])

    return imageUrl
}
