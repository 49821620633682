import {
    AddPrototypeStartingPointCommand,
    DeletePrototypeStartingPointsCommand,
    UpdatePrototypeStartingPointDescriptionCommand,
    UpdatePrototypeStartingPointNameCommand,
    Wukong,
} from '@wukong/bridge-proto'
import constate from 'constate'
import { useCallback, useState } from 'react'
import { cmdChangePopupState } from '../../../../document/command/document-command'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'

const useHook = () => {
    const command = useCommand()
    const [autoFocus, setAutoFocus] = useState<boolean>(false)

    const addStartingPoint = useCallback(() => {
        setAutoFocus(true)
        command.DEPRECATED_invokeBridge(AddPrototypeStartingPointCommand)
        command.commitUndo()
    }, [command])
    const changeFlowName = useCallback(
        (name: string) => {
            setAutoFocus(false)
            command.DEPRECATED_invokeBridge(UpdatePrototypeStartingPointNameCommand, {
                value: name,
            })
            command.commitUndo()
        },
        [command]
    )
    const deleteStartPoint = useCallback(() => {
        command.DEPRECATED_invokeBridge(DeletePrototypeStartingPointsCommand)
        command.commitUndo()
    }, [command])

    const updateDesc = useCallback(
        (value: string) => {
            command.DEPRECATED_invokeBridge(UpdatePrototypeStartingPointDescriptionCommand, {
                value,
            })
            command.commitUndo()
        },
        [command]
    )

    const pupupState = useViewState('popupState')

    const descVisible =
        pupupState?.type == Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_PROTOTYPE_STARTING_POINT_DESC

    const closeDescDialog = useCallback(() => {
        command.invoke(cmdChangePopupState, {
            type: Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_NONE,
            reciprocalIndex: -1,
            multiPopup: [],
        })
    }, [command])

    const openDescDialog = useCallback(() => {
        command.invoke(cmdChangePopupState, {
            type: Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_PROTOTYPE_STARTING_POINT_DESC,
            reciprocalIndex: -1,
            multiPopup: [],
        })
    }, [command])

    return {
        addStartingPoint,
        changeFlowName,
        deleteStartPoint,
        openDescDialog,
        autoFocus,
        setAutoFocus,
        updateDesc,
        descVisible,
        closeDescDialog,
    }
}

export const [PrototypeAddStartingPointProvider, usePrototypeAddStartingPoint] = constate(useHook)
