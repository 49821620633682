import { FC } from 'react'
import styles from './left.module.less'
import { Skeleton } from '../../../../../main/loading-container/skeleton'

export const LeftAreaSkeleton: FC = () => {
    return (
        <div className={styles['left-area-container']}>
            <div className={styles['skeleton-top-container']}>
                {[0, 1].map((key) => (
                    <Skeleton className={styles.skeleton} type="rectangle" width="32" key={key} />
                ))}
            </div>
            {['136', '104', '64', '136', '64', '104', '136'].map((width, index) => (
                <Skeleton className={styles['skeleton-middle']} type="rectangle" width={width} key={index} />
            ))}
        </div>
    )
}
