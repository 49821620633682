import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import { SelectSidebarPanelCommand, Wukong } from '@wukong/bridge-proto'
import { useEffect, useMemo, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { wkNotification } from '../../../../../ui-lib/src'
import { WKTour, WKTourStep } from '../../../../../ui-lib/src/components/wk-tour'
import { WKFrog } from '../../../kernel/frog'
import {
    OnboardingAction,
    OnboardingPopupStatus,
    OnboardingScene,
    OnboardingStatus,
} from '../../../kernel/interface/onboarding'
import { QueryOnboarding } from '../../../kernel/request/user'
import { useCommand } from '../../../main/app-context'
import { onboardingService } from '../../../main/service/onboarding-service'
import { useViewState } from '../../../view-state-bridge'
import { useDocInfoContext } from '../../context/top-area-context'
import { onboardingSteps } from './config'

export const EditorOnboarding = () => {
    const command = useCommand()
    const onboarding = onboardingService
    const onboardingStep = onboardingService.useZustandStore.use.step()
    const onboardingAction = onboardingService.useZustandStore.use.action()
    const popupStatus = onboardingService.useZustandStore.use.popupStatus()
    const [notify, setNotify] = useState<any>()
    const sidebarPanels = useViewState('sidebarPanels', { sidebarPanels: [] }).sidebarPanels
    const assetsPanel = useMemo(() => {
        return sidebarPanels.find(
            ({ type }) => type === Wukong.DocumentProto.SidebarPanelType.SIDEBAR_PANEL_TYPE_ASSETS
        )!
    }, [sidebarPanels])
    // const [steps, setSteps] = useState<TourStep[]>(onboardingSteps)
    const [steps, setSteps] = useState<WKTourStep[]>(onboardingSteps)
    const { docData } = useDocInfoContext()
    const docReadonly = useViewState('docReadonly')

    const onClose = () => {
        onboarding.packingFrog(OnboardingScene.Editor, 'closed')
        onboarding.end(OnboardingScene.Editor, OnboardingStatus.Closed)
    }

    const onChange = () => {
        onboarding.handleNextStep(OnboardingScene.Editor)
    }

    const onClickStart = () => {
        WKFrog.addFrogRecord({
            url: '/click/EditorTutorial/basicGuide0Start',
            eventId: 20711,
            currentPage: 'EditorTutorial',
            eventAction: 'click',
            eventName: 'basicGuide0Start',
        })
        onboarding.restart(OnboardingScene.Editor)
    }

    const onClickDontNeed = () => {
        WKFrog.addFrogRecord({
            url: '/click/EditorTutorial/basicGuide0Refuse',
            eventId: 20713,
            currentPage: 'EditorTutorial',
            eventAction: 'click',
            eventName: 'basicGuide0Refuse',
        })
        onboarding.end(OnboardingScene.Editor, OnboardingStatus.Closed)
    }

    const onClickClose = () => {
        WKFrog.addFrogRecord({
            url: '/click/EditorTutorial/basicGuide0Close',
            eventId: 20712,
            currentPage: 'EditorTutorial',
            eventAction: 'click',
            eventName: 'basicGuide0Close',
        })
        onboarding.end(OnboardingScene.Editor, OnboardingStatus.Closed)
    }

    const onUnload = () => {
        onboardingService.pageUnload(OnboardingScene.Editor)
    }

    useEffectOnce(() => {
        onboardingService.loaded(OnboardingScene.Editor)
        window.addEventListener('unload', onUnload)

        return () => {
            window.removeEventListener('unload', onUnload)
        }
    })

    useEffect(() => {
        if (!docData) {
            return
        }
        if (onboardingAction === OnboardingAction.SwitchToAssets && onboardingStep === 5) {
            if (!docReadonly) {
                command.DEPRECATED_invokeBridge(
                    SelectSidebarPanelCommand,
                    Wukong.DocumentProto.Args_SelectSidebarPanelCommand.create({
                        value: Wukong.DocumentProto.SidebarPanelType.SIDEBAR_PANEL_TYPE_ASSETS,
                    })
                )
            }
            // 需要等渲染完进入下一步
            setTimeout(() => {
                onboarding.forceEnterNextStep(OnboardingScene.Editor)
            }, 0)
        } else if (onboardingAction === OnboardingAction.FetchEditorDocs) {
            new QueryOnboarding(OnboardingScene.Editor, docData?.orgId || '-1').start().then((res) => {
                if (
                    res.draftDocs.some((doc) => doc.docId === docData?.id) ||
                    res.nonDraftDocs.some((doc) => doc.docId === docData?.id)
                ) {
                    onboarding.checkOnboardingStatusLegal(OnboardingScene.Editor, true)
                }
            })
        }
    }, [onboardingAction, docData, command, onboardingStep, onboarding, docReadonly])

    useEffect(() => {
        const computedSteps = onboardingSteps
        const libraryStep = 6
        // 在切换资产 tab 到 layers 时，需要手动处理一次 target 使其变为居中
        if (onboardingStep === libraryStep && !assetsPanel.isSelected) {
            const newSteps = [...computedSteps]
            newSteps[libraryStep] = { ...computedSteps[libraryStep], targets: [] }
            setSteps(newSteps)
        }
        if (onboardingStep === 0) {
            setSteps(computedSteps)
        }
    }, [assetsPanel.isSelected, onboardingStep])

    useEffect(() => {
        if (popupStatus === OnboardingPopupStatus.Notify) {
            WKFrog.addFrogRecord({
                url: '/expose/EditorTutorial/basicGuide0',
                eventId: 20710,
                currentPage: 'EditorTutorial',
                eventAction: 'expose',
                eventName: 'basicGuide0',
            })
            const notification = wkNotification.show({
                title: translation('FollowMotiff'),
                content: translation('Content'),
                okText: translation('Start'),
                cancelText: translation('No'),
                onOk: onClickStart,
                onClose: onClickClose,
                onCancel: onClickDontNeed,
                duration: 0,
                okButtonProps: {
                    white: true,
                },
                cancelButtonProps: {
                    white: true,
                },
                wrapClassName: '!bg-$wk-brand-7',
                titleClassName: '!color-white',
                contentClassName: '!color-white',
                closeIconProps: { type: 'white' },
            })
            setNotify(notification)

            return notification?.close
        } else {
            notify?.close()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [popupStatus])

    if (popupStatus === OnboardingPopupStatus.EditorTour) {
        return (
            <>
                {steps.map((step, idx) => (
                    <img key={idx} src={step.image || ''} style={{ display: 'none' }} />
                ))}
                <WKTour
                    open
                    draggable
                    current={onboardingStep}
                    steps={steps}
                    zIndex={800}
                    onClose={onClose}
                    onChange={onChange}
                />
            </>
        )
    }

    return null
}
