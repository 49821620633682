import { RouteObject } from 'react-router'
import { domLocation } from './dom-env'

export const RouteToken = {
    OrganizationWithoutOrgId: 'files',
    Admin: 'admin',
    Recent: 'recent',
    Drafts: 'drafts',
    Favorites: 'favorites',
    Trash: 'trash',
    ProjectWithId: 'project/:folderId',
    Project: 'project',
    Workbench: 'workbench/*',
    FileWithDocId: 'file/:docId',
    PrototypeWithDocId: 'proto/:docId',
    File: 'file',
    Space: '*',
    Teams: 'teams',
    Workspaces: 'workspaces',
    LoginDoNotUse: 'login',
    AppAuth: 'app-auth',
    DocAccess: 'doc-access',
    DocAccessWithDocId: 'doc-access/:docId',
    Invite: 'invite',
    Reset: 'reset',
    FigmaOauth: 'figma-oauth',
    ForceUpgrade: 'force-upgrade',
    Shared: 'shared',
    Team: 'team',
    Workspace: 'workspace',
    WorkspaceWithId: 'workspace/:workspaceId',
    WorkspaceUnassigned: 'workspace/unassigned',
    SSOConsume: 'sso-consume',
    RestoreTeam: 'files/team/:teamId/restore',
    Application: 'application/:applicationId',
    SeatApplication: 'seat-application/:applicationId',
    Manage: 'manage',
    Search: 'search',
    Invoice: 'invoice/*',
    NormalInvoice: 'invoice',
    Upcoming: 'upcoming',
    GetResource: 'get-resource/:resourceId',
} as const

export type RouteTokenValue = typeof RouteToken[keyof typeof RouteToken]

type RequiredKey<U extends object, K extends keyof U> = U & Required<Pick<U, K>>

export type TRouteObject = RequiredKey<RouteObject, 'path' | 'element'>

export const getRouteToken = (path?: string) => {
    const pathname = path || domLocation().pathname

    if (/\/file\//.test(pathname)) {
        return RouteToken.File
    }

    if (/\/files\//.test(pathname)) {
        if (/\/recent$/.test(pathname)) {
            return RouteToken.Recent
        }
        if (/\/drafts$/.test(pathname)) {
            return RouteToken.Drafts
        }
        if (/\/favorites$/.test(pathname)) {
            return RouteToken.Favorites
        }
        if (/\/trash$/.test(pathname)) {
            return RouteToken.Trash
        }
        if (/\/team\//.test(pathname)) {
            return RouteToken.Team
        }
        if (/\/project\//.test(pathname)) {
            return RouteToken.Project
        }
        // 企业管理
        if (/\/admin\//.test(pathname)) {
            return RouteToken.Admin
        }
        // 全部团队
        if (/\/teams$/.test(pathname)) {
            return RouteToken.Teams
        }
        // 搜索
        if (/\/search$/.test(pathname)) {
            return RouteToken.Search
        }
    }

    if (/\/login$/.test(pathname)) {
        return RouteToken.LoginDoNotUse
    }

    if (/\/doc-access\//.test(pathname)) {
        return RouteToken.DocAccess
    }

    return pathname
}
