/* eslint-disable no-restricted-imports */
import { kanyunRedirect } from '../../../../../../util/src'
import { featureSwitchManager } from '../../../../kernel/switch/core'
import { tryInitCLog, WkCLog } from '../../../../kernel/clog/wukong/instance'
import { checkDebugEnabled } from '../../../../kernel/debug'
import { LocalStorageKey } from '../../../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../../../web-storage/local-storage/storage'
import { WK } from '../../../../window'
import { AppState, StateCallbackInjector } from '../../types'
import { EditorContextUnion } from '../../types/editor'
import { disableSentry } from '../../../../kernel/sentry'

type CommonLoginCallbackInjector = <T extends EditorContextUnion>(
    injector: StateCallbackInjector<AppState.Login, T>
) => void

export const injectRedirect: CommonLoginCallbackInjector = (injector) => {
    injector('try redirect to kanyun', ({ userInfo }) => {
        const stayOnProd = enhancedLocalStorage.getSerializedItem(LocalStorageKey.StayOnProd) ?? false
        kanyunRedirect(userInfo.userBrief.email, stayOnProd, disableSentry)
    })
}

export const injectUserInfo: CommonLoginCallbackInjector = (injector) => {
    injector('inject userInfo to context and WK', ({ userInfo }, fillLifecycleContext) => {
        WK.userInfo = userInfo
        // Electron 端环境下，将用户信息传递给 native 端
        window.localBridge?.setUserInfo({ userId: userInfo?.userBrief.id || 0, email: userInfo?.userBrief.email || '' })

        fillLifecycleContext({ userInfo })

        if (
            !userInfo.userBrief.email.endsWith('@kanyun.com') &&
            !userInfo.userBrief.email.endsWith('@motifftest.com')
        ) {
            WK.detachFeatureSupport()
        }
        return (deleteLifecycleContext) => {
            delete WK.userInfo
            delete WK.orgId
            delete WK.initialDocSchemaVersion
            deleteLifecycleContext('userInfo')
        }
    })
}

export const injectFeatureSwitchManager: CommonLoginCallbackInjector = (injector) => {
    injector('inject user email to feature switch', ({ userInfo }) => {
        featureSwitchManager.injectUserEmail(userInfo.userBrief.email)

        return () => {
            featureSwitchManager.clearSwitches()
        }
    })
}

export const injectClog: CommonLoginCallbackInjector = (injector) => {
    injector('enable clog', ({ userInfo }) => {
        tryInitCLog()
        WkCLog.setUser(userInfo.userId)
    })
}

export const injectDebugMode: CommonLoginCallbackInjector = (injector) => {
    injector('print debug mode', () => {
        checkDebugEnabled()
    })
}
