import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Colors: '种颜色',
    SeeAll: '查看所有',
    LibraryColors: '种样式',
    LibraryColorsV2: '种组件库颜色',
    SelectionColors: '选中的颜色',
    ShowSelectionColors: '展示选中的颜色',
} as const

export const enTranslation = {
    Colors: 'colors',
    SeeAll: 'See all',
    LibraryColors: 'styles',
    LibraryColorsV2: 'library colors',
    SelectionColors: 'Selection colors',
    ShowSelectionColors: 'Show selection colors',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
