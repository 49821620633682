import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    MoveFile: '移动文件',
    Move: '移动',
    SearchForA: '搜索团队或项目',
    NewProject: '新建项目',
    ProjectName: '项目名称',
    lowerCaseName: '项目',
} as const

export const enTranslation = {
    MoveFile: 'Move file',
    Move: 'Move',
    SearchForA: 'Search teams or projects',
    NewProject: 'New project',
    ProjectName: 'Project name',
    lowerCaseName: 'project',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
