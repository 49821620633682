import { useEffect, useRef } from 'react'
import {
    DraggablePopupV2,
    MonoIconCommonArrowLeft16,
    MonoIconCommonClose16,
    MonoIconCommonHistoricalApproval16,
    Position,
    WKIconButton,
    WKPopover,
    WKPopoverRef,
} from '../../../../../../ui-lib/src'
import { featureSwitchManager } from '../../../../kernel/switch/core'
import { useAppContext } from '../../../../main/app-context'
import { ChatbotHistory } from '../chatbot-component/chatbot-history'
import { ChatbotDebugSettingPanel } from './debug-component/debug-setting-panel'

interface ChatbotDialogContainerProps {
    size?: 'small' | 'large'
    title?: string | React.ReactNode
    showReturnButton?: boolean
    onReturn?: () => void
    onClose?: () => void
    children: React.ReactNode
}

export const ChatbotDialogContainer = ({
    size = 'small',
    title,
    showReturnButton = false,
    onReturn,
    onClose,
    children,
    ...props
}: ChatbotDialogContainerProps) => {
    const popoverRef = useRef<WKPopoverRef>(null)
    const chatbotService = useAppContext().chatbotService
    const popupTopCenterPosition = chatbotService.stateStore.use.popupTopCenterPosition()

    const containerWidth = size === 'small' ? 460 : 808
    const containerHeight = size === 'small' ? 580 : 760

    const topCenterPosition = (() => {
        if (popupTopCenterPosition) {
            return popupTopCenterPosition
        }
        return {
            left: window.innerWidth - containerWidth / 2 - 24,
            top: window.innerHeight - containerHeight - 64,
        }
    })()

    useEffect(() => {
        if (popupTopCenterPosition) {
            const fixedPosition = {
                ...popupTopCenterPosition,
            }

            // 左溢出
            if (fixedPosition.left - containerWidth / 2 < 0) {
                fixedPosition.left = containerWidth / 2 + 8
            }

            // 上溢出
            if (fixedPosition.top < 48 + 8) {
                fixedPosition.top = 48 + 8
            }

            // 右溢出
            if (fixedPosition.left + containerWidth / 2 > window.innerWidth) {
                fixedPosition.left = window.innerWidth - containerWidth / 2 - 8
            }

            // 下溢出
            if (fixedPosition.top + containerHeight > window.innerHeight) {
                fixedPosition.top = window.innerHeight - containerHeight - 8
            }

            chatbotService.stateStore.setState({ popupTopCenterPosition: fixedPosition })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatbotService, containerWidth, containerHeight])

    const chatbotDebug = featureSwitchManager.isEnabled('chatbot-debug')

    const onCoversationChange = () => {
        popoverRef.current?.hide()
    }

    return (
        <DraggablePopupV2
            header={
                <div className={`py-4 pl-4 pr-3 flex items-center justify-between truncate`}>
                    {/* left */}
                    <div className="flex-1 max-w-100px">
                        {showReturnButton && <WKIconButton icon={<MonoIconCommonArrowLeft16 />} onClick={onReturn} />}
                    </div>
                    {/* center */}
                    <div className={`${size === 'small' ? 'max-w-344px' : 'max-w-692px'}`}>
                        <div className="text-center wk-text-14 font-600 truncate">{title}</div>
                    </div>
                    {/* right */}
                    <div className="flex-1 max-w-100px flex flex-row items-center justify-end">
                        {chatbotDebug && <ChatbotDebugSettingPanel />}
                        <WKPopover
                            placement="bottom-left"
                            contentClassName="p-0"
                            contents={<ChatbotHistory onCoversationChange={onCoversationChange} />}
                            ref={popoverRef}
                        >
                            <WKIconButton icon={<MonoIconCommonHistoricalApproval16 />} />
                        </WKPopover>
                        <WKIconButton className="ml-1" icon={<MonoIconCommonClose16 />} onClick={onClose} />
                    </div>
                </div>
            }
            headerClassName="shadow-none"
            closable={false}
            onCancel={onClose}
            position={{ left: topCenterPosition.left - containerWidth / 2, top: topCenterPosition.top }}
            useRawPosition
            onMove={(pos: Position) => {
                chatbotService.stateStore.setState({
                    popupTopCenterPosition: { left: pos.left + containerWidth / 2, top: pos.top },
                })
            }}
            visible={true}
            footer={null}
            width={containerWidth}
            height={containerHeight}
            bodyClassName="p-0"
            testId="chatbot-dialog"
            {...props}
        >
            {children}
        </DraggablePopupV2>
    )
}
