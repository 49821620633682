import { useLocalStorageState } from '../../web-storage/local-storage/hooks/index'
/* eslint-disable no-restricted-imports */
import constate from 'constate'
import { keyBy } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import { isNotNullOrUndefined } from '../../../../util/src'
import type { FontInfoExt } from '../../main/font/interface'
import { LocalStorageKey } from '../../web-storage/local-storage/config'
import { useFontManagerService } from './document-context'

/**
 * 最大使用记录数
 */
const MAX_COUNT = 3

/**
 * 最近使用的字体记录, 存储在LocalStorage, 最多3条
 * @returns
 */
function useRecentFontsContext() {
    const fontManagerService = useFontManagerService()
    const availableFonts = fontManagerService.states.use.allFontInfosState()

    const [recentFonts, setRecentFont] = useLocalStorageState(LocalStorageKey.FontHistory, [])

    const updateRecentFont = useCallback(
        (fontInfo?: FontInfoExt) => {
            if (!fontInfo) {
                return
            }

            // 与上次使用的字体相同不需要更新更新
            if (fontInfo.family === recentFonts[0]?.family) {
                return
            }

            const nextRecentFontInfos = [
                fontInfo,
                ...recentFonts.filter((v) => v.family !== fontInfo.family).slice(0, MAX_COUNT - 1),
            ]
            setRecentFont(nextRecentFontInfos)
        },
        [recentFonts, setRecentFont]
    )

    const availableFamilyMap = useMemo(() => {
        return keyBy(availableFonts, (v) => v.family)
    }, [availableFonts])

    // NOTE: 需要过滤掉不包含在allFontInfo中的字体
    const availableRecentFonts: FontInfoExt[] = useMemo(() => {
        return recentFonts.map((font) => availableFamilyMap[font.family]).filter(isNotNullOrUndefined)
    }, [recentFonts, availableFamilyMap])
    return { recentFonts: availableRecentFonts, updateRecentFont } as const
}

export const [RecentFontsContextProvider, useRecentFonts] = constate(useRecentFontsContext)
