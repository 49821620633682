import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    ClickAnywhereIn: '点击文件中的任意位置，即可添加评论。',
} as const

export const enTranslation = {
    ClickAnywhereIn: 'Click anywhere in the file to leave a comment.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
