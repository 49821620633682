import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    PleaseSelectA: '请选择一个文件',
    FailedToRead: '读取失败',
} as const

export const enTranslation = {
    PleaseSelectA: 'Select a file first',
    FailedToRead: 'An error occurred while reading',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
