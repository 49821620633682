import { command } from 'ccstate'
import { TraceableAbortSignal } from '../abort-controller/traceable-abort-controller'
import { domLocation } from '../dom-env'
import { traceable } from './traceable'

const NEVER_RESOLVE_PROMISE = new Promise(() => {
    void 0
})

export const changeLocationHref$ = traceable(
    'hulh01@kanyun.com',
    command(async (_, signal: TraceableAbortSignal, href: string) => {
        domLocation().href = href
        await NEVER_RESOLVE_PROMISE
    })
)

export const replaceLocation$ = traceable(
    'hulh01@kanyun.com',
    command(async (_, signal: TraceableAbortSignal, url: string) => {
        domLocation().replace(url)
        await NEVER_RESOLVE_PROMISE
    })
)
