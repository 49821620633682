import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    DownloadingImages: '正在下载图片',
} as const

export const enTranslation = {
    DownloadingImages: 'Downloading images',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
