import { translation } from './text-detail.translation'
/* eslint-disable no-restricted-imports */
import { useCallback, useMemo } from 'react'
import { Wukong } from '@wukong/bridge-proto'
import {
    IconAutoWidthHorizontal,
    IconOptionOff,
    IconOrderedList,
    IconTextAlignCenter,
    IconTextAlignJustified,
    IconTextAlignLeft,
    IconTextAlignRight,
    IconTextDecorationStrikeThrough,
    IconTextDecorationUnderline,
    IconTextResizeFixed,
    IconTextResizeHeight,
    IconTextTransformCapitalize,
    IconTextTransformLower,
    IconTextTransformUpper,
    IconTextTruncation,
    IconUnorderedList,
    Tooltip,
} from '../../../../../../ui-lib/src'
import {
    TextAlignHorizontal,
    TextAlignVertical,
    TextAutoResize,
    TextCase,
    TextDecoration,
    TextTruncation,
} from '../../../../document/node/node'
import { SelectIconGroup } from '../../atom/button/select-button-group'
import { SingleGrid } from '../../atom/grid/single-grid'
import { ScrubbableInputInteger } from '../../atom/inputs/scrubbable-input-integer'
import { Value } from '../../atom/inputs/utils/type'
import { useTextCommand } from './hooks/use-text-command'
import { TextListType, TextListTypeValues, useTextListModel } from './hooks/use-text-list-model'
import { SelectionTextState } from './hooks/use-text-state'
import style from './text-detail.module.less'
import { useText } from './use-text'
import { ShortcutKey, shortcutLabelMap } from '../../../../kernel/interface/shortcut-key'

export interface TextDetailProps {
    isStyleMode?: boolean
    selectionTextState: SelectionTextState
    /**
     * 是否禁用编辑
     */
    disabled?: boolean
}

export function TextDetail(props: TextDetailProps) {
    const { isStyleMode, disabled, selectionTextState } = props
    const {
        textAutoResize,
        textAlignHorizontal,
        textAlignVertical,
        textDecoration,
        textCase,
        textTruncation,
        maxLines,
    } = selectionTextState

    const { onChangeAutoResize } = useText()

    const {
        changeTextAlignHorizontal, //
        changeTextAlignVertical,
        changeTextCase,
        changeTextDecoration,
        changeTextTruncation,
        changeMaxLines,
    } = useTextCommand()

    const { listType, handleListTypeChange } = useTextListModel()

    const onClickTextAutoResizeJsxIcon = useCallback(
        (value: TextAutoResize) => {
            onChangeAutoResize(value)
        },
        [onChangeAutoResize]
    )

    const textAutoResizeOptionValue = useMemo(() => {
        return [TextAutoResize.WidthAndHeight, TextAutoResize.Height, TextAutoResize.None]
    }, [])

    const onClickVerticalAlignIcon = useCallback(
        (value: TextAlignVertical) => {
            changeTextAlignVertical(value)
        },
        [changeTextAlignVertical]
    )

    const verticalAlignOptionValue = useMemo(() => {
        return [TextAlignVertical.Top, TextAlignVertical.Center, TextAlignVertical.Bottom]
    }, [])

    const onClickHorizontalAlignIcon = useCallback(
        (value: TextAlignHorizontal) => {
            changeTextAlignHorizontal(value)
        },
        [changeTextAlignHorizontal]
    )

    const horizontalAlignOptionValue = useMemo(() => {
        return [
            TextAlignHorizontal.Left,
            TextAlignHorizontal.Center,
            TextAlignHorizontal.Right,
            TextAlignHorizontal.Justified,
        ]
    }, [])

    const onClickDecorationIcon = useCallback(
        (value: TextDecoration) => {
            changeTextDecoration(value)
        },
        [changeTextDecoration]
    )

    const decorationOptionValue = useMemo(() => {
        return [TextDecoration.None, TextDecoration.Underline, TextDecoration.Strikethrough]
    }, [])

    const onClickTextCaseIcon = useCallback(
        (value: TextCase) => {
            changeTextCase(value)
        },
        [changeTextCase]
    )

    const textCaseOptionValue = useMemo(() => {
        return [TextCase.Original, TextCase.Upper, TextCase.Title, TextCase.Lower]
    }, [])

    const textTruncationValues = useMemo(() => {
        return [TextTruncation.Disabled, TextTruncation.Ending]
    }, [])

    function formatToIntAndAuto(value: Value): string {
        if (typeof value === 'number') {
            return value === 0 ? translation('Auto') : String(Math.round(value))
        }
        return ''
    }

    return (
        <div className={style.content} data-testid="design-panel-text-detail">
            <SingleGrid className={style.grid}>
                <SingleGrid.Item start={5} span={20}>
                    {translation('Resizing')}
                </SingleGrid.Item>
                <SingleGrid.Item start={35} span={22} className={style.flexEnd}>
                    <SelectIconGroup
                        disabled={disabled}
                        optionValue={textAutoResizeOptionValue}
                        onClickIcon={onClickTextAutoResizeJsxIcon}
                    >
                        <Tooltip title={translation('AutoWidth')}>
                            <SelectIconGroup.Item
                                value={TextAutoResize.WidthAndHeight}
                                icon={<IconAutoWidthHorizontal />}
                                selected={
                                    textAutoResize.value ===
                                    Wukong.DocumentProto.TextAutoResize.TEXT_AUTO_RESIZE_WIDTH_AND_HEIGHT
                                }
                            />
                        </Tooltip>
                        <Tooltip title={translation('AutoHeight')}>
                            <SelectIconGroup.Item
                                value={TextAutoResize.Height}
                                icon={<IconTextResizeHeight />}
                                selected={
                                    textAutoResize.value === Wukong.DocumentProto.TextAutoResize.TEXT_AUTO_RESIZE_HEIGHT
                                }
                            />
                        </Tooltip>
                        <Tooltip title={translation('FixedSize')}>
                            <SelectIconGroup.Item
                                value={TextAutoResize.None}
                                icon={<IconTextResizeFixed />}
                                selected={
                                    textAutoResize.value === Wukong.DocumentProto.TextAutoResize.TEXT_AUTO_RESIZE_NONE
                                }
                            />
                        </Tooltip>
                    </SelectIconGroup>
                </SingleGrid.Item>
            </SingleGrid>
            <SingleGrid className={style.grid}>
                <SingleGrid.Item start={5} span={20}>
                    {translation('HorizontalAlignment')}
                </SingleGrid.Item>
                <SingleGrid.Item start={27} span={30} className={style.flexEnd}>
                    <SelectIconGroup
                        className={style.iconGroup118}
                        disabled={disabled}
                        onClickIcon={onClickHorizontalAlignIcon}
                        optionValue={horizontalAlignOptionValue}
                    >
                        <Tooltip
                            title={translation('AlignLeft')}
                            shortcut={shortcutLabelMap[ShortcutKey.TextAlignLeft]}
                        >
                            <SelectIconGroup.Item
                                value={TextAlignHorizontal.Left}
                                icon={<IconTextAlignLeft />}
                                selected={
                                    textAlignHorizontal.value ===
                                    Wukong.DocumentProto.TextAlignHorizontal.TEXT_ALIGN_HORIZONTAL_LEFT
                                }
                            />
                        </Tooltip>
                        <Tooltip
                            title={translation('AlignCenter')}
                            shortcut={shortcutLabelMap[ShortcutKey.TextAlignCenter]}
                        >
                            <SelectIconGroup.Item
                                value={TextAlignHorizontal.Center}
                                icon={<IconTextAlignCenter />}
                                selected={
                                    textAlignHorizontal.value ===
                                    Wukong.DocumentProto.TextAlignHorizontal.TEXT_ALIGN_HORIZONTAL_CENTER
                                }
                            />
                        </Tooltip>
                        <Tooltip
                            title={translation('AlignRight')}
                            shortcut={shortcutLabelMap[ShortcutKey.TextAlignRight]}
                        >
                            <SelectIconGroup.Item
                                value={TextAlignHorizontal.Right}
                                icon={<IconTextAlignRight />}
                                selected={
                                    textAlignHorizontal.value ===
                                    Wukong.DocumentProto.TextAlignHorizontal.TEXT_ALIGN_HORIZONTAL_RIGHT
                                }
                            />
                        </Tooltip>
                        <Tooltip
                            title={translation('TextAlignJustified')}
                            shortcut={shortcutLabelMap[ShortcutKey.TextAlignJustified]}
                        >
                            <SelectIconGroup.Item
                                value={TextAlignHorizontal.Justified}
                                icon={<IconTextAlignJustified />}
                                selected={
                                    textAlignHorizontal.value ===
                                    Wukong.DocumentProto.TextAlignHorizontal.TEXT_ALIGN_HORIZONTAL_JUSTIFIED
                                }
                            />
                        </Tooltip>
                    </SelectIconGroup>
                </SingleGrid.Item>
            </SingleGrid>
            {isStyleMode ? null : (
                <SingleGrid className={style.grid}>
                    <SingleGrid.Item start={5} span={28}>
                        {translation('Decoration')}
                    </SingleGrid.Item>
                    <SingleGrid.Item start={35} span={22} className={style.flexEnd}>
                        <SelectIconGroup
                            disabled={disabled}
                            optionValue={decorationOptionValue}
                            onClickIcon={onClickDecorationIcon}
                        >
                            <Tooltip title={translation('None')}>
                                <SelectIconGroup.Item
                                    value={TextDecoration.None}
                                    icon={<IconOptionOff />}
                                    selected={
                                        textDecoration.value ===
                                        Wukong.DocumentProto.TextDecoration.TEXT_DECORATION_NONE
                                    }
                                    dataTestId="decoration-none"
                                />
                            </Tooltip>
                            <Tooltip title={translation('Underline')}>
                                <SelectIconGroup.Item
                                    value={TextDecoration.Underline}
                                    icon={<IconTextDecorationUnderline />}
                                    selected={
                                        textDecoration.value ===
                                        Wukong.DocumentProto.TextDecoration.TEXT_DECORATION_UNDERLINE
                                    }
                                    dataTestId="decoration-underline"
                                />
                            </Tooltip>
                            <Tooltip title={translation('Strikethrough')}>
                                <SelectIconGroup.Item
                                    value={TextDecoration.Strikethrough}
                                    icon={<IconTextDecorationStrikeThrough />}
                                    selected={
                                        textDecoration.value ===
                                        Wukong.DocumentProto.TextDecoration.TEXT_DECORATION_STRIKETHROUGH
                                    }
                                    dataTestId="decoration-strikethrough"
                                />
                            </Tooltip>
                        </SelectIconGroup>
                    </SingleGrid.Item>
                </SingleGrid>
            )}
            <SingleGrid className={style.grid}>
                <SingleGrid.Item start={5} span={28}>
                    {translation('ListStyle')}
                </SingleGrid.Item>
                <SingleGrid.Item start={35} span={22} className={style.flexEnd}>
                    <SelectIconGroup
                        disabled={disabled}
                        optionValue={TextListTypeValues}
                        onClickIcon={handleListTypeChange}
                    >
                        <Tooltip title={translation('None')}>
                            <SelectIconGroup.Item
                                value={TextListType.Plain}
                                icon={<IconOptionOff />}
                                selected={listType === TextListType.Plain}
                                dataTestId="listType-none"
                            />
                        </Tooltip>
                        <Tooltip title={translation('BulletedList')}>
                            <SelectIconGroup.Item
                                value={TextListType.Unordered}
                                icon={<IconUnorderedList />}
                                selected={listType === TextListType.Unordered}
                                dataTestId="listType-unordered"
                            />
                        </Tooltip>
                        <Tooltip title={translation('NumberedList')}>
                            <SelectIconGroup.Item
                                value={TextListType.Ordered}
                                icon={<IconOrderedList />}
                                selected={listType === TextListType.Ordered}
                                dataTestId="listType-ordered"
                            />
                        </Tooltip>
                    </SelectIconGroup>
                </SingleGrid.Item>
            </SingleGrid>
            <SingleGrid className={style.grid}>
                <SingleGrid.Item start={5} span={20}>
                    {translation('Case')}
                </SingleGrid.Item>
                <SingleGrid.Item start={27} span={30} className={style.flexEnd}>
                    <SelectIconGroup
                        className={style.iconGroup118}
                        disabled={disabled}
                        optionValue={textCaseOptionValue}
                        onClickIcon={onClickTextCaseIcon}
                    >
                        <Tooltip title={translation('AsTyped')}>
                            <SelectIconGroup.Item
                                value={TextCase.Original}
                                icon={<IconOptionOff />}
                                selected={textCase.value === Wukong.DocumentProto.TextCase.TEXT_CASE_ORIGINAL}
                                dataTestId="textcase-none"
                            />
                        </Tooltip>
                        <Tooltip title={translation('Uppercase')}>
                            <SelectIconGroup.Item
                                value={TextCase.Upper}
                                icon={<IconTextTransformUpper />}
                                selected={textCase.value === Wukong.DocumentProto.TextCase.TEXT_CASE_UPPER}
                                dataTestId="textcase-upper"
                            />
                        </Tooltip>
                        <Tooltip title={translation('TitleCase')}>
                            <SelectIconGroup.Item
                                value={TextCase.Title}
                                icon={<IconTextTransformCapitalize />}
                                selected={textCase.value === Wukong.DocumentProto.TextCase.TEXT_CASE_TITLE}
                                dataTestId="textcase-title"
                            />
                        </Tooltip>
                        <Tooltip title={translation('Lowercase')}>
                            <SelectIconGroup.Item
                                value={TextCase.Lower}
                                icon={<IconTextTransformLower />}
                                selected={textCase.value === Wukong.DocumentProto.TextCase.TEXT_CASE_LOWER}
                                dataTestId="textcase-lower"
                            />
                        </Tooltip>
                    </SelectIconGroup>
                </SingleGrid.Item>
            </SingleGrid>
            <SingleGrid className={style.grid}>
                <SingleGrid.Item start={5} span={28}>
                    {translation('TruncateText')}
                </SingleGrid.Item>
                <SingleGrid.Item start={43} span={14} className={style.flexEnd}>
                    <SelectIconGroup
                        disabled={disabled}
                        optionValue={[textTruncationValues]}
                        onClickIcon={changeTextTruncation}
                    >
                        <SelectIconGroup.Item
                            value={TextTruncation.Disabled}
                            icon={<IconOptionOff />}
                            selected={
                                textTruncation.value === Wukong.DocumentProto.TextTruncation.TEXT_TRUNCATION_DISABLED
                            }
                            dataTestId="truncation-none"
                        />
                        <SelectIconGroup.Item
                            value={TextTruncation.Ending}
                            icon={<IconTextTruncation />}
                            selected={
                                textTruncation.value === Wukong.DocumentProto.TextTruncation.TEXT_TRUNCATION_ENDING
                            }
                            dataTestId="truncation-ending"
                        />
                    </SelectIconGroup>
                </SingleGrid.Item>
            </SingleGrid>

            {textTruncation.value === Wukong.DocumentProto.TextTruncation.TEXT_TRUNCATION_ENDING &&
            textAutoResize.value !== Wukong.DocumentProto.TextAutoResize.TEXT_AUTO_RESIZE_NONE ? (
                <SingleGrid className={style.grid}>
                    <SingleGrid.Item start={5} span={28}>
                        {translation('MaxLines')}
                    </SingleGrid.Item>
                    <SingleGrid.Item start={43} span={14}>
                        <ScrubbableInputInteger
                            disabled={disabled}
                            isMixed={maxLines.mixed}
                            value={maxLines.value}
                            formatter={{ format: formatToIntAndAuto }}
                            onChange={(v, option) => changeMaxLines(v as number, option)}
                            min={1}
                            testId="max-lines-input"
                        />
                    </SingleGrid.Item>
                </SingleGrid>
            ) : null}
        </div>
    )
}
