import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    OnClick: '点击',
    Click: '点击',
    OnTap: '轻点',
    Tap: '轻点',
    OnDrag: '拖拽',
    Drag: '拖拽',
    WhileHovering: '悬停',
    Hover: '悬停',
    WhilePressing: '按住',
    Press: '按住',
    MouseEnter: '光标移入',
    MouseLeave: '光标移出',
    MouseDown: '鼠标按下',
    TouchDown: '向下点触',
    MouseUp: '鼠标抬起',
    TouchUp: '抬起',
    AfterDelay: '延时',
    AddDelay: '添加延时',
    None: '无',
    Mixed: '多个值',
    triggerConflict: '由于其他交互会先执行，当前交互将不会生效',
    NavigateTo: '前往',
    Back: '返回',
    ScrollTo: '滚动到',
    OpenLink: '打开链接',
    OpenOverlay: '打开浮层',
    SwapOverlay: '切换浮层',
    CloseOverlay: '关闭浮层',
} as const

export const enTranslation = {
    OnClick: 'On click',
    Click: 'Click',
    OnTap: 'On tap',
    Tap: 'Tap',
    OnDrag: 'On drag',
    Drag: 'Drag',
    WhileHovering: 'While hovering',
    Hover: 'Hover',
    WhilePressing: 'While pressing',
    Press: 'Press',
    MouseEnter: 'Mouse enter',
    MouseLeave: 'Mouse leave',
    MouseDown: 'Mouse down',
    TouchDown: 'Touch down',
    MouseUp: 'Mouse up',
    TouchUp: 'Touch up',
    AfterDelay: 'After delay',
    AddDelay: 'Add delay',
    None: 'None',
    Mixed: 'Mixed',
    triggerConflict: 'This interaction will not be triggered as another one will occur first.',
    NavigateTo: 'Navigate to',
    Back: 'Back',
    ScrollTo: 'Scroll to',
    OpenLink: 'Open link',
    OpenOverlay: 'Open overlay',
    SwapOverlay: 'Swap overlay',
    CloseOverlay: 'Close overlay',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
