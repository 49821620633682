import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Stroke: '描边',
    Style: '样式',
    StyleAndVariable: '样式与变量',
    Click: '点击',
    ToReplaceMixed: '替换多个属性值',
} as const

export const enTranslation = {
    Stroke: 'Stroke',
    Style: 'Style',
    StyleAndVariable: 'Styles and variables',
    Click: 'Click',
    ToReplaceMixed: 'to replace mixed content',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
