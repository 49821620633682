import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    'resend-in': '重新获取 ({{count}})',
    'send-code': '获取验证码',
} as const

export const enTranslation = {
    'resend-in': 'Resend in {{count}}s',
    'send-code': 'Send code',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
