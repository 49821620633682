/* eslint-disable no-restricted-imports */
import {
    UpdateSelectionTextNodeAlignHorizontalCommand,
    UpdateSelectionTextNodeAlignVerticalCommand,
    UpdateSelectionTextNodeAutoResizeCommand,
    UpdateSelectionTextNodeFontFamilyCommand,
    UpdateSelectionTextNodeFontNameCommand,
    UpdateSelectionTextNodeFontSizeCommand,
    UpdateSelectionTextNodeLetterSpacingCommand,
    UpdateSelectionTextNodeLineHeightCommand,
    UpdateSelectionTextNodeMaxLinesCommand,
    UpdateSelectionTextNodeParagraphIndentCommand,
    UpdateSelectionTextNodeParagraphSpacingCommand,
    UpdateSelectionTextNodeTextCaseCommand,
    UpdateSelectionTextNodeTextDecorationCommand,
    UpdateSelectionTextNodeTextTruncationCommand,
    UpdateTextSettingTabKeyCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { CommitType } from '../../../../../document/command/commit-type'
import {
    FontName,
    LetterSpacing,
    LineHeight,
    TextAlignHorizontal,
    TextAlignVertical,
    TextAutoResize,
    TextCase,
    TextDecoration,
    TextTruncation,
} from '../../../../../document/node/node'
import { useCommand } from '../../../../context/document-context'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'

export function useTextCommand() {
    const command = useCommand()

    return {
        changeTextAlignVertical: (textAlignVertical: TextAlignVertical) => {
            command.DEPRECATED_invokeBridge(
                UpdateSelectionTextNodeAlignVerticalCommand,
                Wukong.DocumentProto.UpdateSelectionTextNodeAlignVerticalParam.create({
                    textAlignVertical: textAlignVertical as unknown as Wukong.DocumentProto.TextAlignVertical,
                })
            )
            command.commitUndo()
        },

        changeTextAlignHorizontal: (textAlignHorizontal: TextAlignHorizontal) => {
            command.DEPRECATED_invokeBridge(
                UpdateSelectionTextNodeAlignHorizontalCommand,
                Wukong.DocumentProto.UpdateSelectionTextNodeAlignHorizontalParam.create({
                    textAlignHorizontal: textAlignHorizontal as unknown as Wukong.DocumentProto.TextAlignHorizontal,
                })
            )
            command.commitUndo()
        },

        changeAutoResize: (textAutoResize: TextAutoResize) => {
            command.DEPRECATED_invokeBridge(
                UpdateSelectionTextNodeAutoResizeCommand,
                Wukong.DocumentProto.UpdateSelectionTextNodeAutoResizeParam.create({
                    textAutoResize: textAutoResize as unknown as Wukong.DocumentProto.TextAutoResize,
                })
            )
            command.commitUndo()
        },

        changeParagraphIndent: (paragraphIndent: number) => {
            command.DEPRECATED_invokeBridge(
                UpdateSelectionTextNodeParagraphIndentCommand,
                Wukong.DocumentProto.UpdateSelectionTextNodeParagraphIndentParam.create({
                    paragraphIndent,
                })
            )
            command.commitUndo()
        },

        changeParagraphSpacing: (paragraphSpacing: number, options?: InputOptionsForUndoSquash) => {
            command.DEPRECATED_invokeBridge(
                UpdateSelectionTextNodeParagraphSpacingCommand,
                Wukong.DocumentProto.UpdateSelectionTextNodeParagraphSpacingParam.create({
                    paragraphSpacing,
                })
            )
            command.commitUndo(options?.commitType)
        },

        changeTextTruncation: (textTruncation: TextTruncation) => {
            command.DEPRECATED_invokeBridge(
                UpdateSelectionTextNodeTextTruncationCommand,
                Wukong.DocumentProto.UpdateSelectionTextNodeTextTruncationParam.create({
                    textTruncation: textTruncation as unknown as Wukong.DocumentProto.TextTruncation,
                })
            )
            command.commitUndo()
        },

        changeMaxLines: (value: number, options?: InputOptionsForUndoSquash) => {
            command.DEPRECATED_invokeBridge(
                UpdateSelectionTextNodeMaxLinesCommand,
                Wukong.DocumentProto.UpdateSelectionTextNodeMaxLinesParam.create({ value })
            )
            command.commitUndo(options?.commitType)
        },

        /* -------------------------------------------------------------------------- */
        changeFontSize: (fontSize: number, options?: InputOptionsForUndoSquash) => {
            command.DEPRECATED_invokeBridge(
                UpdateSelectionTextNodeFontSizeCommand,
                Wukong.DocumentProto.UpdateSelectionTextNodeFontSizeParam.create({
                    fontSize,
                })
            )
            command.commitUndo(options?.commitType)
        },

        changeFontName: (fontName: FontName, customAxesStyle: string, isMissFontCustomStyle: boolean) => {
            command.DEPRECATED_invokeBridge(
                UpdateSelectionTextNodeFontNameCommand,
                Wukong.DocumentProto.UpdateSelectionTextNodeFontNameParam.create({
                    fontName,
                    customAxesStyle: { name: customAxesStyle },
                    isMissFontCustomStyle,
                    isPreset: false,
                })
            )
            command.commitUndo()
        },

        presetFontName: (fontName: FontName, customAxesStyle: string, isMissFontCustomStyle: boolean) => {
            command.invokeBridge(
                CommitType.Noop,
                UpdateSelectionTextNodeFontNameCommand,
                Wukong.DocumentProto.UpdateSelectionTextNodeFontNameParam.create({
                    fontName,
                    customAxesStyle: { name: customAxesStyle },
                    isMissFontCustomStyle,
                    isPreset: true,
                })
            )
        },

        changeFontFamily: (family: string) => {
            command.DEPRECATED_invokeBridge(
                UpdateSelectionTextNodeFontFamilyCommand,
                Wukong.DocumentProto.Arg_setFontFamily.create({
                    value: family,
                    isPreset: false,
                })
            )
            command.DEPRECATED_invokeBridge(UpdateTextSettingTabKeyCommand, {
                tabKey: Wukong.DocumentProto.TextSettingTabKey.TEXT_SETTING_TAB_KEY_BASICS,
            })
            command.commitUndo()
        },

        presetFontFamily: (family: string) => {
            command.invokeBridge(
                CommitType.Noop,
                UpdateSelectionTextNodeFontFamilyCommand,
                Wukong.DocumentProto.Arg_setFontFamily.create({
                    value: family,
                    isPreset: true,
                })
            )
        },

        changeTextDecoration: (textDecoration: TextDecoration) => {
            command.DEPRECATED_invokeBridge(
                UpdateSelectionTextNodeTextDecorationCommand,
                Wukong.DocumentProto.UpdateSelectionTextNodeTextDecorationParam.create({
                    textDecoration: textDecoration as unknown as Wukong.DocumentProto.TextDecoration,
                })
            )
            command.commitUndo()
        },

        changeLineHeight: (lineHeight: LineHeight, options?: InputOptionsForUndoSquash) => {
            command.DEPRECATED_invokeBridge(
                UpdateSelectionTextNodeLineHeightCommand,
                Wukong.DocumentProto.UpdateSelectionTextNodeLineHeightParam.create({
                    lineHeight: lineHeight as unknown as Wukong.DocumentProto.ILineHeight,
                })
            )
            command.commitUndo(options?.commitType)
        },

        changeLetterSpacing: (letterSpacing: LetterSpacing, options?: InputOptionsForUndoSquash) => {
            command.DEPRECATED_invokeBridge(
                UpdateSelectionTextNodeLetterSpacingCommand,
                Wukong.DocumentProto.UpdateSelectionTextNodeLetterSpacingParam.create({
                    letterSpacing: letterSpacing as unknown as Wukong.DocumentProto.ILetterSpacing,
                })
            )
            command.commitUndo(options?.commitType)
        },

        changeTextCase: (textCase: TextCase) => {
            command.DEPRECATED_invokeBridge(
                UpdateSelectionTextNodeTextCaseCommand,
                Wukong.DocumentProto.UpdateSelectionTextNodeTextCaseParam.create({
                    textCase: textCase as unknown as Wukong.DocumentProto.TextCase,
                })
            )
            command.commitUndo()
        },
    }
}
