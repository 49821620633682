import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    ShowPrototypingSettings: '显示原型设置',
} as const

export const enTranslation = {
    ShowPrototypingSettings: 'Show prototyping settings',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
