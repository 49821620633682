import { Wukong } from '@wukong/bridge-proto'
import { WKToast, wkDialogConfirm } from '../../../../../../../ui-lib/src'
import { createSelectors, createStore } from '../../../../../../../util/src'
import { createSwitchTask } from '../../../../../../../util/src/abort-controller/signal-task'
import { signalTimeout } from '../../../../../../../util/src/abort-controller/timers'
import { TraceableAbortSignal } from '../../../../../../../util/src/abort-controller/traceable-abort-controller'
import { createCombineLatestEventHandler } from '../../../../../../../util/src/combine-latest-event-handler'
import { distinctFn } from '../../../../../../../util/src/distinct-fn'
import { ReplayEventEmitter } from '../../../../../../../util/src/event-emitter/replay-event-emitter'
import type { CommandInvoker } from '../../../../../document/command/command-invoker'
import { ImageDownloadContext } from '../../../../../document/command/image-download-context'
import { OfflineImageProcess } from '../../../../../document/document-bridge/types'
import { RepositoryStatus } from '../../../../../document/node/node'
import type { SynergyManager } from '../../../../../document/synergy/synergy-manager'
import type { WebSocketBridge } from '../../../../../document/synergy/web-socket-bridge'
import { environment } from '../../../../../environment'
import { isSynergyStateOffline } from '../../../../../kernel/util/synergy-state'
import type { ViewStateBridge } from '../../../../../view-state-bridge'
import { EditorDataTestId, ToastTestId } from '../../../../../window/wk-data-test'
import { ToastProgressMessage } from '../sync-toast-progress-message/sync-toast-progress-message'
import { translation } from './synergy-sync-status-service.translation'

export const OfflineLayBackTime = 60000 // 1分钟

export class SynergySyncStatusService {
    public states = createSelectors(
        createStore<{
            showOfflineIconState: boolean // 离线状态图标展示状态
            isSynergyOfflineState: boolean // 当前 synergy ws 是否离线
        }>(
            () => ({
                showOfflineIconState: false,
                isSynergyOfflineState: false,
            }),
            environment.isDev
        )
    )

    private inHistoryWasmState: boolean | null = null
    private inHistoryEventEmitter = new ReplayEventEmitter<boolean>(1)

    private offlineState: boolean | null = null
    private offlineEventEmitter = new ReplayEventEmitter<boolean>(1)

    private showOfflineEditToastId?: string
    private offlineEditModalDestroyFn?: () => void
    private showSyncOfflineEditToastId?: string

    constructor(
        protected readonly signal: TraceableAbortSignal,
        protected readonly viewStateBridge: ViewStateBridge,
        private readonly webSocketBridge: WebSocketBridge,
        private readonly synergyManager: SynergyManager,
        private readonly commandInvoker: CommandInvoker,
        private readonly imageDownloadContext: ImageDownloadContext
    ) {}

    public destroy() {
        this.closeOfflineEditToast()
        this.closeOfflineEditModal()
        this.closeSyncOfflineEditToast()
    }

    public init = () => {
        this.initOffline$()
        this.initIsSynergyOfflineState()
        this.initShowOfflineIconState()
        this.initOfflineEditToastModal()
        this.initSyncOfflineEditToast()
    }

    public updateInHistoryWasm = (inHistoryWasm: boolean) => {
        this.inHistoryWasmState = inHistoryWasm
        this.inHistoryEventEmitter.next(inHistoryWasm)
    }

    private initOffline$ = () => {
        const handleChange = createSwitchTask(this.signal, async (signal, offline: boolean) => {
            if (offline) {
                signalTimeout(
                    () => {
                        this.offlineState = offline
                        this.offlineEventEmitter.next(offline)
                    },
                    OfflineLayBackTime,
                    { signal }
                )
            } else {
                this.offlineState = offline
                this.offlineEventEmitter.next(offline)
            }
        })
        const distinctHandleChange = distinctFn(handleChange)

        this.webSocketBridge.onSynergyStateChangeWithSignal(this.signal, (status) => {
            const nextStatus = status !== Wukong.DocumentProto.SynergyState.SYNERGY_STATE_ONLINE
            distinctHandleChange(nextStatus)
        })
    }

    // 管理离线图标显示 / 隐藏状态
    private initShowOfflineIconState = () => {
        const handleExitHistoryWasm = createSwitchTask(this.signal, async (signal) => {
            // 退出历史版本状态时，100ms 内没有联网，则展示离线图标
            signalTimeout(
                () => {
                    // NOTE: 100ms内可能已经链接成功或者又重新切换为历史模式了，此时不展示离线图标
                    if (this.offlineState && !this.inHistoryWasmState) {
                        this.states.setState({ showOfflineIconState: true })
                    }
                },
                100,
                { signal }
            )
        })

        this.inHistoryEventEmitter.onWithSignal(this.signal, (inHistoryWasm) => {
            if (this.inHistoryWasmState) {
                this.states.setState({ showOfflineIconState: false })
            } else {
                handleExitHistoryWasm()
            }
        })

        this.offlineEventEmitter.onWithSignal(this.signal, (offline) => {
            if (this.inHistoryWasmState === false) {
                this.states.setState({ showOfflineIconState: offline })
            }
        })
    }

    // 当前 synergy ws 是否离线
    private initIsSynergyOfflineState = () => {
        this.webSocketBridge.onSynergyStateChangeWithSignal(this.signal, (status) => {
            this.states.setState({ isSynergyOfflineState: isSynergyStateOffline(status) })
        })
    }

    // 断网后用户编辑操作时的 toast 和 modal
    private initOfflineEditToastModal = () => {
        // 非历史版本时，显示离线编辑的文案
        const combineLatestEventHandler = createCombineLatestEventHandler(
            {
                offline: { init: false },
                repositoryStatus: { init: false },
            },
            ({ offline, repositoryStatus }: { offline: boolean; repositoryStatus: RepositoryStatus }) => {
                if (offline && repositoryStatus === RepositoryStatus.NonNull && this.inHistoryWasmState === false) {
                    this.showOfflineEditToast()
                }
            }
        )

        this.offlineEventEmitter.onWithSignal(this.signal, (offline) => {
            combineLatestEventHandler.handleEvent('offline', offline)
        })

        this.synergyManager.onRepositoryStatusChangeWithSignal(
            this.signal,
            distinctFn((repositoryStatus) => {
                combineLatestEventHandler.handleEvent('repositoryStatus', repositoryStatus)
            })
        )

        this.offlineEventEmitter.onWithSignal(this.signal, (offline) => {
            // 联网后自动关闭离线编辑提示文案
            if (!offline) {
                this.closeOfflineEditToast()
                this.closeOfflineEditModal()
            }
        })
    }

    private showOfflineEditToast = () => {
        if (!this.showOfflineEditToastId) {
            this.showOfflineEditToastId = WKToast.show(translation('ThisFileHas'), {
                duration: -1,
                firstButton: { type: 'x', dataTestId: ToastTestId.CloseButton },
                secondButton: {
                    type: 'button',
                    text: translation('LearnMore'),
                    onClick: this.showOfflineEditModal,
                    dataTestId: EditorDataTestId.Sync.OfflineEditToast,
                },
                dataTestIds: { toast: EditorDataTestId.Sync.OfflineEditToast },
            })
        }
    }

    private closeOfflineEditToast = () => {
        if (this.showOfflineEditToastId) {
            WKToast.close(this.showOfflineEditToastId)
            this.showOfflineEditToastId = undefined
        }
    }

    private showOfflineEditModal = () => {
        this.closeOfflineEditToast()
        if (!this.offlineEditModalDestroyFn) {
            this.offlineEditModalDestroyFn = wkDialogConfirm.info({
                title: translation('AboutUnsavedChanges'),
                content: translation('UnsavedChangesCan'),
                okText: translation('Done'),
                hideCancelButton: true,
                wrapperTestId: EditorDataTestId.Sync.OfflineEditModal,
            }).destroy
        }
    }

    private closeOfflineEditModal = () => {
        if (this.offlineEditModalDestroyFn) {
            this.offlineEditModalDestroyFn()
            this.offlineEditModalDestroyFn = undefined
        }
    }

    // 联网后同步离线操作进度的 toast
    private initSyncOfflineEditToast = () => {
        const syncToastCombineEventHandler = createCombineLatestEventHandler(
            {
                offline: { init: false },
                syncProgress: { init: false },
            },
            ({ offline, syncProgress }: { offline: boolean; syncProgress: number }) => {
                if (!offline && syncProgress >= 0 && syncProgress <= 100) {
                    if (this.showSyncOfflineEditToastId) {
                        this.updateSyncOfflineEditToast(syncProgress)
                    } else {
                        this.showSyncOfflineEditToast(syncProgress)
                    }
                } else {
                    this.closeSyncOfflineEditToast()
                }
            }
        )

        this.offlineEventEmitter.onWithSignal(this.signal, (offline) => {
            syncToastCombineEventHandler.handleEvent('offline', offline)
        })

        // NOTE: wuyangjie@kanyun.com 上传进度为负数时报错提示（当进度条展示时数据有误，才展示错误 toast）
        const handleInvalidSyncProgress = (syncProgress: number) => {
            if (this.showSyncOfflineEditToastId && syncProgress < 0) {
                this.closeSyncOfflineEditToast()
                WKToast.error(translation('AnErrorOccurred'))
            }
        }

        const syncProgressCombineEventHandler = createCombineLatestEventHandler(
            {
                offlineImageNum: { init: false },
                syncProgress: { init: false },
            },
            ({
                offlineImageNum,
                syncProgress,
            }: {
                offlineImageNum: OfflineImageProcess
                syncProgress: {
                    total: number
                    ack: number
                    value: number
                }
            }) => {
                let localSyncProgress = syncProgress?.value

                if (syncProgress && syncProgress.value !== 1 && syncProgress.value !== -1) {
                    localSyncProgress = Math.round(
                        ((syncProgress.ack + offlineImageNum.succeeded) /
                            (syncProgress.total + offlineImageNum.total)) *
                            100
                    )
                }

                handleInvalidSyncProgress(localSyncProgress)
                syncToastCombineEventHandler.handleEvent('syncProgress', localSyncProgress)
            }
        )

        this.viewStateBridge.registerWithSignal(this.signal, 'syncProgress', (syncProgress) => {
            syncProgressCombineEventHandler.handleEvent('syncProgress', syncProgress)
        })

        this.imageDownloadContext.onOfflineImageNumChangeWithSignal(this.signal, (offlineNum) => {
            syncProgressCombineEventHandler.handleEvent('offlineImageNum', offlineNum)
        })
    }

    private showSyncOfflineEditToast = (progress: number) => {
        this.showSyncOfflineEditToastId = WKToast.show(
            <ToastProgressMessage
                progress={progress}
                successMsg={translation('YourOfflineChanges')}
                progressMsg={translation('SynchronizingOfflineChanges')}
                onProgressEnd={this.afterSyncOfflineEditEnd}
            />,
            {
                duration: -1,
                dataTestIds: { toast: EditorDataTestId.Sync.OfflineEditSyncProgressToast },
            }
        )
    }

    private updateSyncOfflineEditToast = (progress: number) => {
        if (this.showSyncOfflineEditToastId) {
            WKToast.updateMessage(
                this.showSyncOfflineEditToastId,
                <ToastProgressMessage
                    progress={progress}
                    successMsg={translation('YourOfflineChanges')}
                    progressMsg={translation('SynchronizingOfflineChanges')}
                    onProgressEnd={this.afterSyncOfflineEditEnd}
                />
            )
        }
    }

    private afterSyncOfflineEditEnd = () => {
        signalTimeout(
            () => {
                this.closeSyncOfflineEditToast()
            },
            2000,
            { signal: this.signal }
        )
    }

    private closeSyncOfflineEditToast = () => {
        if (this.showSyncOfflineEditToastId) {
            WKToast.close(this.showSyncOfflineEditToastId)
            this.showSyncOfflineEditToastId = undefined
        }
    }
}
