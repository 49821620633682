import type { PropsWithChildren } from 'react'
import { useCommand, useComponentService, useLibraryComponentService } from '../../../main/app-context'
import { LibraryComponentViewShareConfigProvider } from './use-library-component-viewer'

// 编辑器内部使用的配置提供者
export function LibraryComponentViewEditorConfigProvider(props: PropsWithChildren<{ draggable: boolean }>) {
    const command = useCommand()
    const componentService = useComponentService()
    const { libraryDragEventService } = useLibraryComponentService()

    return (
        <LibraryComponentViewShareConfigProvider
            command={command}
            jumpToOriginDocumentInEditor={componentService.jumpToOriginDocument}
            dragConfig={{ draggable: props.draggable, libraryDragEventService }}
        >
            {props.children}
        </LibraryComponentViewShareConfigProvider>
    )
}

// 工作台内部使用的配置提供者
export function LibraryComponentViewSpaceConfigProvider(props: PropsWithChildren) {
    return (
        <LibraryComponentViewShareConfigProvider dragConfig={{ draggable: false }}>
            {props.children}
        </LibraryComponentViewShareConfigProvider>
    )
}
