import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { FC, useMemo } from 'react'
import { MulticolorIconCommonMissingMode16 } from '../../../../../../ui-lib/src'
import Tooltip from '../../../../../../ui-lib/src/components/tooltip/tooltip'
import styles from './index.module.less'
import { translation } from './index.translation'
import { LayerPanelTag } from './tag'

import VariableLayerModeStateVector = Wukong.DocumentProto.IVariableLayerModeStateVector
import VLayerPanelVisibleStatus = Wukong.DocumentProto.VLayerPanelVisibleStatus
interface LayerPanelItemModeProps {
    modes: VariableLayerModeStateVector
    isPurple: boolean
    visibleStatus: VLayerPanelVisibleStatus
}
export const LayerPanelItemMode: FC<LayerPanelItemModeProps> = ({ modes, isPurple, visibleStatus }) => {
    const existMode = modes.states.filter((mode) => !mode.hasDeleted)
    const missingModeCount = modes.states.length - existMode.length

    const isShallow = useMemo(
        () =>
            [
                VLayerPanelVisibleStatus.V_LAYER_PANEL_VISIBLE_STATUS_ANCESTOR_INVISIBLE,
                VLayerPanelVisibleStatus.V_LAYER_PANEL_VISIBLE_STATUS_INVISIBLE,
                VLayerPanelVisibleStatus.V_LAYER_PANEL_VISIBLE_STATUS_INVISIBLE_FOR_DOC_READ_ONLY,
            ].includes(visibleStatus),
        [visibleStatus]
    )

    return useMemo(
        () => (
            <div className={classnames(styles.modeContainer)}>
                {missingModeCount != 0 && (
                    <div className={classnames(styles.missingMode, { [styles.rightPadding]: existMode.length != 0 })}>
                        <Tooltip title={translation('MissingMode', { count: missingModeCount.toString() })}>
                            <MulticolorIconCommonMissingMode16 />
                        </Tooltip>
                    </div>
                )}
                {existMode.length > 0 && (
                    <Tooltip title={existMode.map((mode) => mode.mode.modeName).join(', ')}>
                        <LayerPanelTag
                            name={existMode[0].mode.modeName}
                            number={existMode.length > 1 ? existMode.length - 1 : undefined}
                            color={isPurple ? 'purple' : 'gray'}
                            isShallow={isShallow}
                        />
                    </Tooltip>
                )}
            </div>
        ),
        [existMode, isPurple, isShallow, missingModeCount]
    )
}
