import classNames from 'classnames'
import { MonoIconCommonLoading16, WKDivider, WKTypography } from '../../../../../ui-lib/src'
import { useLibraryRemoteSearchService } from '../hook/use-library-service-remote-search'
import { LibraryTeamInfoListItem } from '../library-team-info-list-item/library-team-info-list-item'
import styles from './library-team-info-list.module.less'
import { translation } from './library-team-info-list.translation'

// 团队组件库列表
export function LibraryTeamInfoList() {
    const libraryRemoteSearchService = useLibraryRemoteSearchService()
    const libraryTeamInfoList = libraryRemoteSearchService.states.use.libraryTeamInfoListState()
    const librarySearchKeyword = libraryRemoteSearchService.states.use.librarySearchKeywordState()
    const librarySearchError = libraryRemoteSearchService.states.use.librarySearchErrorState()
    const librarySearchLoading = libraryRemoteSearchService.states.use.librarySearchLoadingState()

    return librarySearchError ? (
        <WKTypography.Paragraph color="placeholder" className={styles.emptyContainer}>
            {translation('SearchError')}
        </WKTypography.Paragraph>
    ) : librarySearchLoading ? (
        <MonoIconCommonLoading16 className={styles.loading} />
    ) : libraryTeamInfoList?.length ? (
        <>
            {libraryTeamInfoList.map((libraryTeamInfo, index) => (
                <div key={libraryTeamInfo.id}>
                    {index !== 0 && <WKDivider className={classNames(styles.divider, '!mb-2')} />}
                    <LibraryTeamInfoListItem libraryTeamInfo={libraryTeamInfo} />
                </div>
            ))}
        </>
    ) : (
        <WKTypography.Paragraph color="placeholder" className={styles.emptyContainer}>
            {librarySearchKeyword ? translation('NoResultsFor', { librarySearchKeyword }) : translation('NoResults')}
        </WKTypography.Paragraph>
    )
}
