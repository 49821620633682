import { Checkbox, MonoIconCommonWarningLine16 } from '../../../../../../../ui-lib/src'
import Tooltip from '../../../../../../../ui-lib/src/components/tooltip/tooltip'
import { useSelectedVariablesEditorContext } from '../selected-variables-editor-context'
import { VariableScopeCheckboxKey2Label, VariableScopeCheckboxKeyDepth, VariableScopeCheckboxValue } from '../utils'
import { translation } from './index.translation'

export function SelectedVariablesEditorScope({
    scopeCheckboxValues,
}: {
    scopeCheckboxValues: VariableScopeCheckboxValue[]
}) {
    const { onChangeScopes } = useSelectedVariablesEditorContext()

    return (
        <div data-testid="selected-variables-editor-scope" className="pt-2 pb-3 pl-4">
            {scopeCheckboxValues.map((value, index) => (
                <div
                    key={value.key}
                    data-testid="selected-variables-editor-scope-item"
                    className="flex py-1 items-center"
                    style={{ paddingLeft: VariableScopeCheckboxKeyDepth[value.key] * 24 }}
                >
                    <Checkbox
                        containerTestId={`scope-checkbox-${
                            value.mixed ? 'mixed' : value.checked ? 'checked' : 'unchecked'
                        }`}
                        checked={value.checked}
                        indeterminate={value.mixed}
                        label={VariableScopeCheckboxKey2Label[value.key]}
                        onChange={(checked) => onChangeScopes(value.key, checked)}
                    />
                    {index === 0 && (
                        <Tooltip title={translation('Hint')} firstDelay={100}>
                            <MonoIconCommonWarningLine16
                                data-testid="selected-variables-editor-scope-hint-icon"
                                className="color-$wk-v2-label-color-gray-8 ml-1"
                            />
                        </Tooltip>
                    )}
                </div>
            ))}
        </div>
    )
}
