/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { debounce } from 'lodash-es'
import { isSafari } from '../../../../kernel/util/ua'
import { DeepRequired } from '../../../../view-state-bridge'
import { rgb2Style } from '../util/color'
import { getIconSrc } from './coactor-cursor-config'

interface RenderInfoV2 {
    url: string
    color: string // rgb(*,*,*)
    nickname: string
    cursorMode: Wukong.DocumentProto.CursorMode
    colorIndex: number
}

export class CoactorCursorRenderServiceV2 {
    private containerElement: HTMLElement | null = null
    private lastCoactorMousePosition: DeepRequired<Wukong.DocumentProto.ICoactorMousePosition> | null = null
    private renderInfo: RenderInfoV2 | null = null
    private needRerenderBasicInfo = true
    private needRerenderCursorMode = true
    private isInSafari = isSafari()

    // 处理被添加的协作者光标
    public handleAppendCoactorCursor = (element: HTMLElement) => {
        this.containerElement = element
        this.needRerenderBasicInfo = true
        this.needRerenderCursorMode = true
        this.renderCoactorCursor()
    }

    // 处理被移除的协作者光标
    public handleRemoveCoactorCursor = () => {
        this.containerElement = null
    }

    // 更新渲染 config
    public rerenderCoactorCursor = (
        cursorMode: Wukong.DocumentProto.CursorMode,
        color: DeepRequired<Wukong.DocumentProto.IRGB>,
        colorIndex: number,
        nickname: string,
        position: DeepRequired<Wukong.DocumentProto.ICoactorMousePosition>,
        needDemote: boolean
    ) => {
        if (!this.renderInfo || this.renderInfo.cursorMode !== cursorMode || this.renderInfo.colorIndex != colorIndex) {
            this.needRerenderCursorMode = true
            this.renderInfo = {
                url: getIconSrc(cursorMode, colorIndex),
                color: rgb2Style(color),
                nickname,
                cursorMode,
                colorIndex,
            }
        }
        this.lastCoactorMousePosition = position

        needDemote ? this.debounceRenderCoactorCursor() : this.renderCoactorCursor()
    }

    // 更新协作者光标位置
    // NOTE: react 更新机制会导致卡帧，故而直接 set 原始 dom
    private renderCoactorCursor = () => {
        if (!this.containerElement || !this.lastCoactorMousePosition || !this.renderInfo) {
            return
        }
        // 以下 dom 操作较为耗时且不会频繁更新，故而做标识选择性更新
        if (this.needRerenderCursorMode) {
            let iconDom: HTMLObjectElement | HTMLImageElement
            if (this.isInSafari) {
                // safari 展示 img svg 会模糊，故而使用 object
                iconDom = this.containerElement.firstElementChild as HTMLObjectElement
                iconDom.data = this.renderInfo.url
            } else {
                iconDom = this.containerElement.firstElementChild as HTMLImageElement
                iconDom.src = this.renderInfo.url
            }

            const nameLabelDom = this.containerElement.lastElementChild as HTMLElement
            this.needRerenderCursorMode = false

            if (this.needRerenderBasicInfo) {
                nameLabelDom.innerText = this.renderInfo.nickname
                nameLabelDom.style.background = this.renderInfo.color
                this.containerElement.style.color = this.renderInfo.color
                this.containerElement.style.visibility = 'visible'

                this.needRerenderBasicInfo = false
            }
        }

        this.containerElement.style.transform = `translate3d(${this.lastCoactorMousePosition.x}px, ${this.lastCoactorMousePosition.y}px, 0px)`
    }

    private debounceRenderCoactorCursor = debounce(this.renderCoactorCursor.bind(this), 50, { maxWait: 80 })
}
