import type { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { IMAGE_PATH_PREFIX } from '../../../../../../document/config/image-config'
import type { DeepRequired } from '../../../../../../view-state-bridge'
import { DevModeBoxModelTestId } from '../../../../../../window'
import { base64BlackWhite } from '../../../../../assets/common/common-base64'
import classes from '../box-model.module.less'
import { DevModeInspectCopyableValue } from '../common/number-value'

export function ContentBox(props: { value: DeepRequired<Wukong.DocumentProto.IDevModeInspectBoxModelLayout> }) {
    return (
        <div className={classNames(classes.contentBoxContainer)} data-testid={DevModeBoxModelTestId.LayoutContent}>
            <span className={classes.measureContent}>
                <DevModeInspectCopyableValue value={props.value.width} className={classes.measureValue} />
            </span>
            <span className={classes.splitSymbol}>x</span>
            <span className={classes.measureContent}>
                <DevModeInspectCopyableValue value={props.value.height} className={classes.measureValue} />
            </span>
        </div>
    )
}

export function CommonAssetContentBox(props: {
    value: DeepRequired<Wukong.DocumentProto.IDevModeInspectBoxModelCommonAsset>
}) {
    return (
        <div className={classNames(classes.assetContentBoxContainer)} data-testid={DevModeBoxModelTestId.AssetContent}>
            <span className={classNames(classes.textInfo)}>icon</span>
            <div className={classNames(classes.contentValue)}>
                <span className={classes.measureContent}>
                    <DevModeInspectCopyableValue value={props.value.width} className={classes.measureValue} />
                </span>
                <span className={classes.splitSymbol}>x</span>
                <span className={classes.measureContent}>
                    <DevModeInspectCopyableValue value={props.value.height} className={classes.measureValue} />
                </span>
            </div>
        </div>
    )
}

export function ImageAssetContentBox(props: {
    value: DeepRequired<Wukong.DocumentProto.IDevModeInspectBoxModelImageAsset>
}) {
    const onClick = () => {
        if (!props.value.imageName) {
            return
        }

        if (props.value.imageId) {
            window.open(`${IMAGE_PATH_PREFIX}${props.value.imageId}`, '_blank')
        } else {
            const image = new Image()
            image.src = base64BlackWhite
            const newWindow = window.open('', '_blank')
            if (newWindow) {
                newWindow.document.write(image.outerHTML)
                newWindow.document.title = props.value.imageName
                newWindow.document.close()
            }
        }
    }

    return (
        <div className={classNames(classes.assetContentBoxContainer)} data-testid={DevModeBoxModelTestId.AssetContent}>
            <span className={classNames(props.value.imageName ? classes.linkInfo : classes.textInfo)} onClick={onClick}>
                {props.value.imageName || 'image'}
            </span>
            <div className={classNames(classes.contentValue)}>
                <span className={classes.measureContent}>
                    <DevModeInspectCopyableValue value={props.value.width} className={classes.measureValue} />
                </span>
                <span className={classes.splitSymbol}>x</span>
                <span className={classes.measureContent}>
                    <DevModeInspectCopyableValue value={props.value.height} className={classes.measureValue} />
                </span>
            </div>
        </div>
    )
}
