import { useState } from 'react'
import { InputV2, WKDialog, WKTextButton, WKToast } from '../../../../../../../ui-lib/src'
import { usePluginService } from '../../../../../main/app-context'
import { useDocInfoContext } from '../../../../context/top-area-context'
import { fetchCreatePlugin, fetchPublishPlugin } from '../plugin-request'
import { PluginId } from '../template/type'
import { assertManifest, getLocalPluginData, PluginDevStatus, usePluginDevelopment } from '../use-plugin-development'
import { IconImageUploader } from './icon-image-uploader'
import { translation } from './plugin-dev-publish.translation'
import { usePluginDevPublishValidate, ValidStatus } from './use-plugin-dev-publish-validate'

export function PluginDevelopmentPublish() {
    const pluginService = usePluginService()
    const { setPluginDevStatus, currentPublishPluginLocalPath, openPublishSuccessDialog } = usePluginDevelopment()

    const publishDrafts = pluginService.states.use.pluginPublishDrafts()
    const localPlugins = pluginService.states.use.localPlugins()

    const { validStatus, validate, setValidStatus } = usePluginDevPublishValidate()
    const [generagedNewPluginId, setGeneragedNewPluginId] = useState<PluginId | null>(null)
    const [publishAfterGenerateNewPluginId, setPublishAfterGenerateNewPluginId] = useState<boolean>(false)

    const [loading, setLoading] = useState<boolean>(false)

    const currentPublishPluginId = localPlugins.find((p) => p.path === currentPublishPluginLocalPath)?.id
    if (!currentPublishPluginId) {
        return null
    }

    const publishDraft = publishDrafts[currentPublishPluginLocalPath]

    if (!publishDraft) {
        return null
    }

    const iconSrc = publishDraft.iconInfo
        ? `data:${publishDraft.iconInfo?.format};base64,${publishDraft.iconInfo?.imageData}`
        : undefined

    const handleIconChange = async (file: { imageData: string; format: string }) => {
        pluginService.states.getState().updatePluginPublishDrafts({
            ...publishDrafts,
            [currentPublishPluginLocalPath]: {
                ...publishDraft,
                iconInfo: {
                    imageData: file.imageData,
                    format: file.format,
                },
            },
        })
        setValidStatus((status) => ({
            ...status,
            iconErrorText: undefined,
        }))
    }

    const handleNameChange = (name: string) => {
        pluginService.states.getState().updatePluginPublishDrafts({
            ...publishDrafts,
            [currentPublishPluginLocalPath]: {
                ...publishDraft,
                name,
            },
        })
        setValidStatus((status) => ({
            ...status,
            nameErrorText: undefined,
        }))
    }

    const onClickPublish = async () => {
        if (!validate(publishDraft.name, iconSrc)) {
            return
        }

        setLoading(true)
        try {
            const localPluginData = await getLocalPluginData(publishDraft.path)
            if (!localPluginData) {
                WKToast.error(translation('ReadPluginFailed'), {
                    secondButton: {
                        type: 'button',
                        text: translation('ShowConsole'),
                        onClick: () => {
                            window.localBridge?.toggleDevTools?.(true)
                        },
                    },
                })
                return
            }
            const { manifest, codeContent } = localPluginData
            const { error } = assertManifest(manifest)
            if (error) {
                WKToast.error(translation('ManiFestError'), {
                    duration: -1,
                    secondButton: {
                        type: 'button',
                        text: translation('ViewDetails'),
                        onClick: () => {
                            setPluginDevStatus(PluginDevStatus.Main)
                        },
                    },
                })
                return
            }

            // 如果插件 id 属于当前用户可编辑的插件，则提示重新生成
            if (!pluginService.states.getState().editablePluginIds.has(manifest.id)) {
                setValidStatus((status) => ({
                    ...status,
                    idError: true,
                }))
                if (generagedNewPluginId) {
                    setPublishAfterGenerateNewPluginId(true)
                }
                return
            }

            const publishOptions = {
                updateRequest: {
                    name: publishDraft.name,
                    iconData: publishDraft.iconInfo!.imageData,
                    format: publishDraft.iconInfo!.format,
                },
                manifestContent: JSON.stringify(manifest),
                codeContent,
            }
            const publishedPlugin = await fetchPublishPlugin(manifest.id, publishOptions)

            // 发布成功后删除草稿
            const updatedDrafts = {
                ...publishDrafts,
            }
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete updatedDrafts[currentPublishPluginLocalPath]
            pluginService.states.getState().updatePluginPublishDrafts(updatedDrafts)

            pluginService.fetchEditablePublishedPlugins()
            pluginService.fetchPublishedPlugins()

            openPublishSuccessDialog(publishedPlugin)
        } catch (e) {
            WKToast.error(translation('PublishFailed'))
        } finally {
            setLoading(false)
        }
    }

    const onGeneragedNewPluginId = (newPluginId: PluginId) => {
        setGeneragedNewPluginId(newPluginId)
        pluginService.states
            .getState()
            .updateEditablePluginIds([...pluginService.states.getState().editablePluginIds, newPluginId])
    }

    const idErrorText = validStatus.idError
        ? publishAfterGenerateNewPluginId
            ? translation('ManifestIdInvalidPleaseCopy')
            : translation('PleaseCopy')
        : undefined

    return (
        <WKDialog
            title={translation('PublishToOrg')}
            visible={true}
            showTitle={true}
            width={400}
            onCancel={() => {
                setPluginDevStatus(PluginDevStatus.Main)
            }}
            onCloseIconCancel={() => {
                setPluginDevStatus(PluginDevStatus.Main)
            }}
            onOk={onClickPublish}
            okText={translation('Publish')}
            okButtonProps={{ loading }}
            bodyStyle={{ padding: 0 }}
        >
            <div className="p-24px grid auto-rows-min gap-row-24px gap-col-16px grid-cols-[auto_1fr] wk-text-12 wk-font-regular color-$wk-v2-label-color-gray-13">
                <div className="mt-10px">{translation('Icon')}</div>
                <div>
                    <IconImageUploader
                        iconSrc={iconSrc}
                        onChange={handleIconChange}
                        error={{
                            show: !!validStatus.iconErrorText,
                            tipMessage: validStatus.iconErrorText,
                        }}
                        dataTestIds={{
                            clickButton: 'plugin-dev-icon-uploader',
                        }}
                    />
                </div>
                <div className="mt-6px">{translation('Name')}</div>
                <div>
                    <InputV2
                        dataTestIds={{
                            input: 'plugin-dev-description-input',
                        }}
                        value={publishDraft.name}
                        onChange={(e) => {
                            handleNameChange(e.target.value)
                        }}
                        error={{
                            show: !!validStatus.nameErrorText,
                            tipMessage: validStatus.nameErrorText,
                        }}
                    />
                </div>
                <div>ID</div>
                <div>
                    <PublistIdInput
                        pendingPluginId={publishDraft.id}
                        setValidStatus={setValidStatus}
                        generagedNewPluginId={generagedNewPluginId}
                        onGeneragedNewPluginId={onGeneragedNewPluginId}
                        errorText={idErrorText}
                    />
                </div>
            </div>
        </WKDialog>
    )
}

const PublistIdInput = ({
    pendingPluginId,
    setValidStatus,
    generagedNewPluginId,
    onGeneragedNewPluginId,
    errorText,
}: {
    pendingPluginId: PluginId
    setValidStatus: React.Dispatch<React.SetStateAction<ValidStatus>>
    generagedNewPluginId: PluginId | null
    onGeneragedNewPluginId: (pluginId: PluginId) => void
    errorText?: string
}) => {
    const { docData } = useDocInfoContext()

    const clickGenerateNewPluginId = async () => {
        try {
            const newPlugin = await fetchCreatePlugin(docData?.orgId)
            onGeneragedNewPluginId(newPlugin.id)
        } catch (e) {
            WKToast.error(translation('GenerateIdFailed'))
        }
    }

    const clickCopyPluginId = () => {
        if (!generagedNewPluginId) {
            return
        }

        navigator.clipboard.writeText(generagedNewPluginId)
        setValidStatus((status: ValidStatus) => ({
            ...status,
            idError: undefined,
        }))
        WKToast.show(translation('CopyDone'))
    }

    if (!generagedNewPluginId) {
        return (
            <>
                {!errorText ? (
                    <span>{pendingPluginId}</span>
                ) : (
                    <div className="w-full flex flex-row items-center justify-start">
                        <span className="wk-text-12 wk-font-regular color-$wk-red-8 flex-1 mr-4">
                            {translation('ManifestIdInvalid')}
                        </span>

                        <WKTextButton type="primary" size={12} onClick={clickGenerateNewPluginId}>
                            {translation('GenerateNewPluginId')}
                        </WKTextButton>
                    </div>
                )}
            </>
        )
    }

    return (
        <div className="w-full flex flex-row items-start justify-start">
            <div className="flex-1 mr-4">
                <div>{generagedNewPluginId}</div>
                {errorText && <div className="color-$wk-red-8">{errorText}</div>}
            </div>

            <WKTextButton type="primary" size={12} onClick={clickCopyPluginId}>
                {translation('CopyId')}
            </WKTextButton>
        </div>
    )
}
