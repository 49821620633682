import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    变量名称: '名称',
    单值模式标签: '值',
    复制模式: '复制模式',
    重命名模式: '重命名模式',
    删除模式: '删除模式',
    新建分组: '添加到新分组',
    编辑变量: '编辑变量',
    编辑变量复数: '编辑变量',
    复制变量: '创建变量副本',
    复制变量复数: '创建变量副本',
    删除变量: '删除变量',
    删除变量复数: '删除变量',
    引用其它变量: '引用其它变量',
    分离变量: '分离变量',
    添加模式: '添加模式',
} as const

export const enTranslation = {
    变量名称: 'Name',
    单值模式标签: 'Value',
    复制模式: 'Duplicate mode',
    重命名模式: 'Rename mode',
    删除模式: 'Delete mode',
    新建分组: 'Add new group',
    编辑变量: 'Edit variable',
    编辑变量复数: 'Edit variables',
    复制变量: 'Duplicate variable',
    复制变量复数: 'Duplicate variables',
    删除变量: 'Delete variable',
    删除变量复数: 'Delete variables',
    引用其它变量: 'Create alias',
    分离变量: 'Clear alias',
    添加模式: 'Add new mode',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
