import { Wukong } from '@wukong/bridge-proto'

import CursorMode = Wukong.DocumentProto.CursorMode
export { CursorMode }

export type Cursor = Wukong.DocumentProto.ICursor

/**
 * 光标元数据
 */
export interface CursorMeta {
    key: CursorMode
    src: string
    top: number
    left: number
}

/**
 * 可旋转光标的元数据
 */
export interface CursorMetaExtended {
    startIndex: number
    step: number
    fullAngle: number
}

/**
 * 光标渲染信息缓存数据
 */
export interface CursorBufferData {
    key: number
    top: number
    left: number
    width: number
    height: number
    dataUri: string
}

/**
 * 光标渲染信息的缓存表
 */
export type BufferMap = Record<number, CursorBufferData>

/**
 * 光标上屏信息
 */
export interface CursorRenderInfo {
    dataUri: string
    top: number
    left: number
    key: string // 这里的 key 与 BufferMap 中的 key 不一样，这里的 key 用于生成 className
    devicePixelRatio: number
}

/**
 * 将光标缓存数据转换为上屏信息
 * @param cursorBuffer
 * @returns
 */
export function generateCursorRenderInfoByBufferData(cursorBuffer: CursorBufferData) {
    return {
        dataUri: cursorBuffer.dataUri,
        top: cursorBuffer.top,
        left: cursorBuffer.left,
        key: `${cursorBuffer.key}_${Math.round(cursorBuffer.width * 100)}_${Math.round(devicePixelRatio * 200)}`,
        devicePixelRatio,
    } as CursorRenderInfo
}
