import { AppCanvasContainer } from './app-canvas-context'
import { AppContextContainer } from './app-context-container'
import { AppDocumentContainer } from './app-document-context'
import { HistoryModeContextProvider } from './history-mode/context/history-mode-context'
import { LayerPanelScrollTopProvider } from './history-mode/context/layer-panel-scroll'
import { AppLayoutContextProvider, DocFolderTeamDataProvider } from './layout/layout-context'

export function AppRoot() {
    return (
        <div style={{ height: '100vh', display: 'flex' }}>
            <div style={{ position: 'relative', flex: '1 1 auto' }}>
                <AppLayoutContextProvider>
                    <DocFolderTeamDataProvider>
                        <AppCanvasContainer>
                            <AppContextContainer>
                                <HistoryModeContextProvider>
                                    <LayerPanelScrollTopProvider>
                                        <AppDocumentContainer />
                                    </LayerPanelScrollTopProvider>
                                </HistoryModeContextProvider>
                            </AppContextContainer>
                        </AppCanvasContainer>
                    </DocFolderTeamDataProvider>
                </AppLayoutContextProvider>
            </div>
        </div>
    )
}
