export const MaskStart = () => (
    <svg width="16" height="32" viewBox="0 0 16 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3557_27380)">
            <path d="M21 144H8C8 144 8 50.0524 8 11M21 144L17 140M21 144L17 148" stroke="#C2C3C4" />
        </g>
        <defs>
            <clipPath id="clip0_3557_27380">
                <rect width="16" height="32" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export const MaskMiddle = () => (
    <svg width="16" height="32" viewBox="0 0 16 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3557_27381)">
            <path d="M21 133H8C8 133 8 39.0524 8 0M21 133L17 129M21 133L17 137" stroke="#C2C3C4" />
        </g>
        <defs>
            <clipPath id="clip0_3557_27381">
                <rect width="16" height="32" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export const MaskEnd = () => (
    <svg width="24" height="32" viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3557_27382)">
            <path d="M21 16H8C8 16 8 -77.9476 8 -117M21 16L17 12M21 16L17 20" stroke="#C2C3C4" />
        </g>
        <defs>
            <clipPath id="clip0_3557_27382">
                <rect width="24" height="32" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export const MaskEndSmall = () => {
    return (
        <svg width="16" height="32" viewBox="0 0 16 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3611_29918)">
                <path d="M15.3 16H8C8 16 8 -77.9476 8 -117M15.3 16L11.3 12M15.3 16L11.3 20" stroke="#C2C3C4" />
            </g>
            <defs>
                <clipPath id="clip0_3611_29918">
                    <rect width="16" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
