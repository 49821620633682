import { PluginApiSaveVersionHistoryCallbackCommandJS, PluginApiSaveVersionHistoryCommand } from '@wukong/bridge-proto'
import { IBasicBridge } from '../../kernel/bridge/basic-bridge'
import { IPluginAPIContext } from '../plugin-api-context-interface'
import { Handle } from '../vm-interface'
import { ZodTypes } from '../zod-type'

export function createSaveVersionHistoryApi(apiObject: Handle, bridge: IBasicBridge, ctx: IPluginAPIContext) {
    let saveVersionCbId = 1
    const saveVersionCbMap = new Map<number, { resolve: (_: Handle) => void; reject: (_: Handle) => void }>()

    bridge.bind(PluginApiSaveVersionHistoryCallbackCommandJS, (arg) => {
        const cb = saveVersionCbMap.get(arg.cbId)
        if (cb) {
            if (arg.success) {
                cb.resolve(
                    ctx.vm.deepWrapHandle({
                        id: arg.id,
                    })
                )
            } else {
                cb.reject(ctx.vm.newString('save version failed'))
            }
        }
        saveVersionCbMap.delete(arg.cbId)
    })

    ctx.defineVmFunction({
        objhandle: apiObject,
        key: 'saveVersionHistoryAsync',
        func: (nameHandle, descHandle) => {
            const { promise, resolve, reject } = ctx.vm.newPromise()
            const name = ctx.unwrapAndValidate({ handle: nameHandle, type: ZodTypes.String, key: 'name' })
            const desc = ctx.unwrapAndValidate({ handle: descHandle, type: ZodTypes.String, key: 'desc' })
            const cbId = saveVersionCbId++
            saveVersionCbMap.set(cbId, { resolve, reject })
            ctx.unwrapCallBridge(PluginApiSaveVersionHistoryCommand, {
                cbId,
                title: name,
                description: desc ?? '',
            })
            return promise
        },
    })
}
