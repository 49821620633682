/* eslint-disable no-restricted-imports */
import { SetBigNudgeAmountCommand, SetSmallNudgeAmountCommand, Wukong } from '@wukong/bridge-proto'
import { useCallback } from 'react'
import ReactDOM from 'react-dom'
import { DraggablePopupV2 } from '../../../../../../../ui-lib/src'
import { useUserConfigStateV2 } from '../../../../../main/user-config/user-config-hook'
import { useCommand } from '../../../../context/document-context'
import { ScrubbableInputNumber } from '../../../atom/inputs/scrubbable-input-number'
import { Value } from '../../../atom/inputs/utils/type'
import { useMenuContextState } from '../context/menu-context'
import classes from './fine-tuning.module.less'
import { translation } from './fine-tuning.translation'

export function FineTuning() {
    const { isOpenFineTuning, setIsOpenFineTuning } = useMenuContextState()
    const [smallNudgeAmountV2, setSmallNudgeAmountV2] = useUserConfigStateV2('smallNudgeAmount', (v) => (v ? v : 1))
    const [bigNudgeAmountV2, setBigNudgeAmountV2] = useUserConfigStateV2('bigNudgeAmount', (v) => (v ? v : 10))
    const commandInvoker = useCommand()

    const changeInput = useCallback(
        (value: Value) => {
            if (typeof value === 'number') {
                setSmallNudgeAmountV2(value)
                commandInvoker.DEPRECATED_invokeBridge(
                    SetSmallNudgeAmountCommand,
                    Wukong.DocumentProto.BridgeProtoFloat.create({ value })
                )
            }
        },
        [commandInvoker, setSmallNudgeAmountV2]
    )

    const changeInputShift = useCallback(
        (value: Value) => {
            if (typeof value === 'number') {
                setBigNudgeAmountV2(value)
                commandInvoker.DEPRECATED_invokeBridge(
                    SetBigNudgeAmountCommand,
                    Wukong.DocumentProto.BridgeProtoFloat.create({ value })
                )
            }
        },
        [commandInvoker, setBigNudgeAmountV2]
    )

    return (
        <>
            {ReactDOM.createPortal(
                <DraggablePopupV2
                    footer={null}
                    closable
                    header={translation('NudgeAmount')}
                    visible={isOpenFineTuning}
                    onCancel={() => setIsOpenFineTuning(false)}
                    testId={'fine-tuning'}
                >
                    <div className={classes.item}>
                        <div className={classes.name}>{translation('SmallNudge')}</div>
                        <ScrubbableInputNumber
                            testId="small-nudge-input"
                            className={classes.input}
                            scrubbingDisabled
                            value={isNaN(Number(smallNudgeAmountV2)) ? 1 : Number(smallNudgeAmountV2)}
                            onChange={changeInput}
                            min={0.1}
                            max={999999}
                            notUseUserConfig
                        />
                    </div>
                    <div className={classes.item}>
                        <div className={classes.name}>{translation('BigNudge')}</div>
                        <ScrubbableInputNumber
                            testId="big-nudge-input"
                            className={classes.input}
                            scrubbingDisabled
                            value={isNaN(Number(bigNudgeAmountV2)) ? 10 : Number(bigNudgeAmountV2)}
                            onChange={changeInputShift}
                            min={1}
                            max={999999}
                            notUseUserConfig
                            autoFocus
                        />
                    </div>
                </DraggablePopupV2>,
                document.body
            )}
        </>
    )
}
