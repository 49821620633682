import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    createComponentProp: '创建组件属性',
    editVariantProp: '编辑变体属性',
    editBooleanProp: '编辑布尔属性',
    editTextProp: '编辑文本属性',
    editInstanceSwap: '编辑实例替换属性',
    createComponentPublicProp: '展示组件属性',
} as const

export const enTranslation = {
    createComponentProp: 'Create component property',
    editVariantProp: 'Edit variant property',
    editBooleanProp: 'Edit boolean property',
    editTextProp: 'Edit text property',
    editInstanceSwap: 'Edit instance swap property',
    createComponentPublicProp: 'Expose properties from',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
