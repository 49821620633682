import type { UserVOV2 } from '../../../kernel/interface/account'
import type { EditorContextUnion } from './editor'

export interface LifecycleContext<E extends EditorContextUnion> {
    /**
     * afterLogin 可用
     */
    userInfo: UserVOV2
}

export enum AppState {
    CreateApp = 'createApp',
    Login = 'login',
}

export type AppState2CallbackArgs<T, E extends EditorContextUnion> = T extends AppState.CreateApp
    ? { getTraceInfo: () => LifecycleTraceInfo }
    : T extends AppState.Login
    ? Pick<LifecycleContext<E>, 'userInfo'>
    : LifecycleContext<E>

export type AppState2ResolveType<T extends AppStateNeedsAsyncResource> = T extends AppState.Login ? UserVOV2 : void

export type DeleteLifecycleContext<E extends EditorContextUnion> = (...args: (keyof LifecycleContext<E>)[]) => void

export type CallbackCleanup<E extends EditorContextUnion> =
    | void
    | ((deleteLifecycleContext: DeleteLifecycleContext<E>) => void)

export type AppState2Callback<T, E extends EditorContextUnion> = (
    args: AppState2CallbackArgs<T, E>,
    fillLifecycleContext: (context: Partial<LifecycleContext<E>>) => void
) => CallbackCleanup<E>

export interface CustomCallback<T extends AppState, E extends EditorContextUnion> {
    description: string
    callback: AppState2Callback<T, E>
    shouldCleanup: boolean
}

// 生命周期回调的注入器，需要提供对应 callbck 的描述，在其返回方法里，可以注册对这次回调的 cleanup
export type StateCallbackInjector<T extends AppState, E extends EditorContextUnion> = (
    description: string,
    callback: AppState2Callback<T, E>
) => void

export enum LifecycleAsyncResource {
    UserInfo = 'userInfo',
}

export const appStateNeedsAsyncResource = [AppState.Login] as const

export type AppStateNeedsAsyncResource = typeof appStateNeedsAsyncResource[number]

export type AppState2LifecycleAsyncResource<T extends AppStateNeedsAsyncResource> = T extends AppState.Login
    ? LifecycleAsyncResource.UserInfo
    : any

export type AppState2AsyncResourceComplete<T extends AppStateNeedsAsyncResource> = Record<
    T,
    Partial<Record<AppState2LifecycleAsyncResource<T>, boolean>>
>

export interface LifecycleTraceInfo {
    currentState: AppState
    currentUnFinishedCustomCallbackIndex: number
    appState2CallbackDescriptionsList: Partial<Record<AppState, string[]>>
    appState2AsyncResourceComplete: AppState2AsyncResourceComplete<AppStateNeedsAsyncResource>
}

export interface AsyncResourceControl<T extends AppStateNeedsAsyncResource> {
    resolve?: (args: AppState2ResolveType<T>) => void
    reject?: (reason?: any) => void
}

export type AppState2AsyncResourceControl<T extends AppStateNeedsAsyncResource> = Record<T, AsyncResourceControl<T>>

export type CallbackInjector<T extends AppState, E extends EditorContextUnion> = (
    injector: StateCallbackInjector<T, E>
) => void
