import { UserVOV2 } from '../../kernel/interface/account'
import { ServiceClass } from '../../kernel/util/service-class'

export type DynamicFeatureKeys = 'ai-align' | 'ai-search' | 'ai-gen'

export class DynamicSwitchService extends ServiceClass {
    user!: UserVOV2
    openedFeatures: Set<string> = new Set()

    init(user: UserVOV2) {
        this.user = user
        this.openedFeatures = new Set(user.featureFlagKeys)
    }

    isFeatureEnabled(featureKey: DynamicFeatureKeys) {
        return this.openedFeatures.has(featureKey)
    }
}

export const dynamicSwitchService = new DynamicSwitchService()
