/**
 * copy from https://github.com/oysterlab/ttc2ttf
 */
import struct from './struct'

function byteArray(length: number) {
    const ab = new ArrayBuffer(length)
    const view = new Uint8Array(ab)
    return view.buffer
}

function ceil4(n: number) {
    return (n + 3) & ~3
}

function sleep() {
    return new Promise((resolve) => setTimeout(() => resolve(1), 1))
}

const pack_into_from = (size: number, sourceBuf: ArrayBuffer, sourceOffs: number, buf: ArrayBuffer, offs: number) => {
    new Uint8Array(buf, offs, size).set(new Uint8Array(sourceBuf, sourceOffs | 0, size))
}

const CHUCK_LENGTH: number = 1024 * 1024
const structI = struct('I')

export async function ttc2ttf(buf: ArrayBuffer): Promise<ArrayBuffer[]> {
    const type = struct('4c').unpack_from(buf, 0).join('')
    const result: ArrayBuffer[] = []

    if (type !== 'ttcf') {
        return []
    }

    const ttfCount = structI.unpack_from(buf, 0x08)[0]

    for (let i = 0; i < ttfCount; i++) {
        const tableHeaderOffset = structI.unpack_from(buf, 0x0c + 0x04 * i)[0]
        const tableCount = struct('H').unpack_from(buf, tableHeaderOffset + 0x04)[0]
        const headerLength = 0x0c + tableCount * 0x10

        let tableLength = 0
        for (let j = 0; j < tableCount; j++) {
            const length = structI.unpack_from(buf, tableHeaderOffset + +0x0c + 0x0c + j * 0x10)[0]
            tableLength += ceil4(length)
        }

        const totalLength = headerLength + tableLength
        const newBuf = byteArray(totalLength)

        pack_into_from(headerLength, buf, tableHeaderOffset, newBuf, 0)
        let currentOffset = headerLength

        for (let j = 0; j < tableCount; j++) {
            const offset: number = structI.unpack_from(buf, tableHeaderOffset + 0x0c + 0x08 + j * 0x10)[0]
            const length: number = structI.unpack_from(buf, tableHeaderOffset + 0x0c + 0x0c + j * 0x10)[0]
            structI.pack_into(newBuf, 0x0c + 0x08 + j * 0x10, currentOffset)

            const lastLength = length % CHUCK_LENGTH

            let steps = parseInt((length / CHUCK_LENGTH).toString())

            if (lastLength > 0) steps++

            for (let k = 0; k < steps; k++) {
                const chuckOffset = offset + k * CHUCK_LENGTH
                const chuckLength = steps != k + 1 ? CHUCK_LENGTH : lastLength

                pack_into_from(chuckLength, buf, chuckOffset, newBuf, currentOffset)
                currentOffset += ceil4(chuckLength)
            }
            // NOTE: 字体文件较大时，拆分时间可能会很长，sleep一下避免长时间block
            await sleep()
        }
        result.push(newBuf)
    }
    return result
}
