import { Wukong } from '@wukong/bridge-proto'

import classnames from 'classnames'
import { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { MonoIconCommonArrowDown16, MonoIconCommonArrowUp16, WKIconButton } from '../../../../../../../ui-lib/src'
import { NodeId } from '../../../../../document/node/node'
import { StyleGetVO } from '../../../../../kernel/interface/component-style'
import classes from './card.module.less'

export interface CardProp {
    expanded: boolean
    deleteCard?: () => void
    setTargetStyleNodeId: (targetStyleNodeId: NodeId) => void
    targetStyleNodeId: NodeId
}

export interface ImportedTextStyleNode extends Wukong.DocumentProto.VTextStyleNode {
    styleGetVO: StyleGetVO
}

export interface ImportedPaintStyleNode extends Wukong.DocumentProto.VPaintStyleNode {
    styleGetVO: StyleGetVO
}

export function roundTo2DecimalPlaces(num: number) {
    return Number(num.toFixed(2))
}

export function useSameResAndSimilarResProcess<T>(
    sameStyleCardInfos: { info: T; targetStyleNodeId: NodeId }[],
    similarStyleCardInfos: { info: T; targetStyleNodeId: NodeId }[]
) {
    const [deletedIndexes, setDeletedIndexes] = useState<Set<number>>(new Set<number>())
    const [targetStyleNodeIds, setTargetStyleNodeIds] = useState<Map<number, NodeId>>(new Map<number, NodeId>())

    const ignoreItemByIndex = useCallback((cardIndex: number) => {
        setDeletedIndexes((prevIndexes) => {
            const newIndexes = new Set(prevIndexes)
            newIndexes.add(cardIndex)
            return newIndexes
        })
    }, [])

    const allInfos = [
        ...sameStyleCardInfos.map(({ info, targetStyleNodeId }) => {
            return { info: info, type: 'same', targetStyleNodeId: targetStyleNodeId }
        }),
        ...similarStyleCardInfos.map(({ info, targetStyleNodeId }) => {
            return { info: info, type: 'similar', targetStyleNodeId: targetStyleNodeId }
        }),
    ].map((info, index) => {
        const targetStyleNodeId = targetStyleNodeIds.get(index) || info.targetStyleNodeId
        return { ...info, targetStyleNodeId: targetStyleNodeId }
    })

    const avaliableInfoIndexes = Array.from({ length: allInfos.length }, (_, i) => i).filter(
        (index) => !deletedIndexes.has(index)
    )

    const avaliableSimilarIndexes = avaliableInfoIndexes.filter((index) => allInfos[index].type === 'similar')

    const avaliableSameIndexes = avaliableInfoIndexes.filter((index) => allInfos[index].type === 'same')

    const setTargetStyleNodeIdByIndex = useCallback(
        (index: number, targetStyleNodeId: NodeId) => {
            setTargetStyleNodeIds((prev) => {
                const newMap = new Map(prev)
                newMap.set(index, targetStyleNodeId)
                return newMap
            })
        },
        [setTargetStyleNodeIds]
    )

    return {
        ignoreItemByIndex,
        setTargetStyleNodeIdByIndex,
        allInfos,
        avaliableInfoIndexes,
        avaliableSimilarIndexes,
        avaliableSameIndexes,
    }
}

export function useCardInnerNavigation(
    total: number,
    expanded: boolean,
    setCurrentIdxCallback?: (currentIdx: number) => void
) {
    const [curIdx, setCurIdx] = useState(0)
    const [showCurrentIdx, setShowCurrentIdx] = useState<boolean>(false)

    const setCurrentIdx = useCallback(
        (currentIdx: number) => {
            setCurrentIdxCallback?.(currentIdx)
            setCurIdx(currentIdx)
        },
        [setCurrentIdxCallback]
    )

    useEffect(() => {
        if (!expanded) {
            setShowCurrentIdx(false)
            setCurIdx(0)
        }
    }, [expanded])

    const handleArrowUpClick = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation()

            if (!showCurrentIdx) {
                setShowCurrentIdx(true)
                // last item
                setCurrentIdx(total - 1)
            } else {
                setCurrentIdx((curIdx + total - 1) % total)
            }
        },
        [curIdx, setCurrentIdx, showCurrentIdx, total]
    )

    const handleArrowDownClick = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation()

            if (!showCurrentIdx) {
                setShowCurrentIdx(true)
                // first item
                setCurrentIdx(0)
            } else {
                setCurrentIdx((curIdx + 1) % total)
            }
        },
        [curIdx, setCurrentIdx, showCurrentIdx, total]
    )

    return {
        curIdx,
        showCurrentIdx,
        handleArrowDownClick,
        handleArrowUpClick,
    }
}

export function CardInnerNavigation(prop: {
    curIdx: number
    expanded: boolean
    showCurrentIdx: boolean
    total: number
    handleArrowUpClick: (e: React.MouseEvent) => void
    handleArrowDownClick: (e: React.MouseEvent) => void
}) {
    const { expanded, total, curIdx, showCurrentIdx, handleArrowDownClick, handleArrowUpClick } = prop

    return (
        <div className={classes.card_container_item_navigation}>
            {expanded && showCurrentIdx ? (
                <div className={classes.number}>
                    {curIdx + 1} / {total}
                </div>
            ) : (
                <div className={classes.number}>{total}</div>
            )}
            {expanded && (
                <>
                    <WKIconButton
                        className={'p-1'}
                        data-testid="card-item-up-arrow"
                        onClick={handleArrowUpClick}
                        icon={<MonoIconCommonArrowUp16 />}
                    ></WKIconButton>
                    <WKIconButton
                        className={'p-1 !m-0'}
                        data-testid="card-item-down-arrow"
                        onClick={handleArrowDownClick}
                        icon={<MonoIconCommonArrowDown16 />}
                    ></WKIconButton>
                </>
            )}
        </div>
    )
}

export function Card(props: PropsWithChildren<{ expanded: boolean; testId?: string }>) {
    const { testId, expanded } = props

    return (
        <div
            {...(testId ? { 'data-testid': testId } : {})}
            className={classnames(classes.card_container, expanded ? '' : 'hover:bg-$wk-gray-2')}
            style={{
                boxShadow: expanded ? 'inset 0 0 0 1px var(--wk-brand-7)' : 'inset 0 0 0 1px var(--wk-gray-2)',
                paddingBottom: expanded ? '12px' : '8px',
            }}
        >
            {props.children}
        </div>
    )
}
