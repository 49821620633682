import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Update: '更新',
    UnknownComponent: '未知组件',
    UnknownComponentSet: '未知组件集',
    UnknownStyle: '未知样式',
    UnknownLibrary: '未知组件库',
    UnknownVariableSet: '未知变量合集',
} as const

export const enTranslation = {
    Update: 'Update',
    UnknownComponent: 'Unknown component',
    UnknownComponentSet: 'Unknown component set',
    UnknownStyle: 'Unknown style',
    UnknownLibrary: 'Unknown library',
    UnknownVariableSet: 'Unknown variable collection',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
