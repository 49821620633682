import { useCallback, type PropsWithChildren } from 'react'
import { useLibraryComponentService, useNetworkService } from '../../../main/app-context'
import { useDefaultLibraryService } from '../../../space/app/default-library/context'
import { LibraryThumbnailImageShareConfigProvider } from './use-library-thumbnail-image-v2'

// 编辑器内部使用的配置提供者
export function LibraryThumbnailImageEditorConfigProvider({ children }: PropsWithChildren) {
    const {
        libraryThumbnailService: { loadThumbnailDataFromCache, loadThumbnailData, eraseThumbnailDataCache },
    } = useLibraryComponentService()
    const networkService = useNetworkService()
    const getBrowserOnline = useCallback(() => networkService.states.getState().browserOnlineState, [networkService])

    return (
        <LibraryThumbnailImageShareConfigProvider
            loadThumbnailData={loadThumbnailData}
            loadThumbnailDataFromCache={loadThumbnailDataFromCache}
            eraseThumbnailDataCache={eraseThumbnailDataCache}
            getBrowserOnline={getBrowserOnline}
        >
            {children}
        </LibraryThumbnailImageShareConfigProvider>
    )
}

// 工作台内部使用的配置提供者
export function LibraryThumbnailImageSpaceConfigProvider({ children }: PropsWithChildren) {
    const {
        networkService,
        libraryThumbnailService: { loadThumbnailDataFromCache, loadThumbnailData, eraseThumbnailDataCache },
    } = useDefaultLibraryService()
    const getBrowserOnline = useCallback(() => networkService.states.getState().browserOnlineState, [networkService])

    return (
        <LibraryThumbnailImageShareConfigProvider
            loadThumbnailData={loadThumbnailData}
            loadThumbnailDataFromCache={loadThumbnailDataFromCache}
            eraseThumbnailDataCache={eraseThumbnailDataCache}
            getBrowserOnline={getBrowserOnline}
        >
            {children}
        </LibraryThumbnailImageShareConfigProvider>
    )
}
