import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Tip1: '同属性组件缺失',
    Tip1Extra: '在指定的设计系统内没有找到具有相同尺寸、填充、描边、文本、圆角和布局属性的组件。',

    EmptyPrompt: '未发现组件一致性问题',
} as const

export const enTranslation = {
    Tip1: 'No same-property components',
    Tip1Extra:
        'No components with the same size, padding, stroke, radius, and layout properties are found in the design system.',

    EmptyPrompt: 'No component consistency issues found',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
