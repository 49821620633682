import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    IncorrectPassword: '密码校验不通过',
    ThisEmailIs: '用户账号不存在',
    PasswordChanged: '密码已修改',
    FailedToChange: '密码修改失败',
    ChangePassword: '修改密码',
    Cancel: '取消',
    Submit: '确定',
    CurrentPassword: '输入旧密码',
    NewPassword: '输入新密码',
    PleaseRetypeYour: '两次输入密码不一致',
    ConfirmPassword: '再次输入新密码',
    'password-too-short': '密码请不要少于8位',
    'password-must-include-two-of-digits-uppercase-lowercase': '密码必须包括数字、大写字母、小写字母中的两种',
    'reset-password-not-match': '两次输入密码不一致',
    ForgotPassword: '忘记密码？',
    ResetPassword: '点此重置',
} as const

export const enTranslation = {
    IncorrectPassword: 'Incorrect password',
    ThisEmailIs: 'This email has not been registered.',
    PasswordChanged: 'Password changed',
    FailedToChange: 'An error occurred while changing password',
    ChangePassword: 'Change password',
    Cancel: 'Cancel',
    Submit: 'Submit',
    CurrentPassword: 'Current password',
    NewPassword: 'New password',
    PleaseRetypeYour: 'Inconsistent entry',
    ConfirmPassword: 'Confirm password',
    'password-too-short': 'Passwords must have at least 8 characters.',
    'password-must-include-two-of-digits-uppercase-lowercase':
        'Passwords must contain at least two of the following: numbers, upper case letters and lower case letters.',
    'reset-password-not-match': 'Inconsistent entry',
    ForgotPassword: '',
    ResetPassword: 'Forgot password?',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
