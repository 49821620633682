/* eslint-disable no-restricted-imports */
import { EditImageScaleModeWasmCall, Wukong } from '@wukong/bridge-proto'

import { useCommand } from '../../../../context/document-context'

export function useBlendImage() {
    const command = useCommand()

    const onChangeScaleMode = (value: Wukong.DocumentProto.ScaleMode) => {
        command.DEPRECATED_invokeBridge(EditImageScaleModeWasmCall, { scaleMode: value })
    }

    return {
        onChangeScaleMode,
    }
}
