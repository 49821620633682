/* eslint-disable no-restricted-imports */
import { ConfirmTitleInputCommand, Wukong } from '@wukong/bridge-proto'
import { useCallback, useEffect, useRef, useState } from 'react'
import { ToKeyCode } from '../../../document/util/keycode'
import { useAppContext } from '../../../main/app-context'
import { KeyboardReceiver } from '../../../main/keyboard-receiver/component'
import { useCommand } from '../../context/document-context'
import classes from './title-input.module.less'

export function TitleInput() {
    const [isInputVisible, setIsInputVisible] = useState(false)
    const [name, setName] = useState('')
    const [x, setX] = useState(0)
    const [y, setY] = useState(0)
    const [nodeId, setNodeId] = useState<string>()
    const [maxWidth, setMaxWidth] = useState(0)
    const [width, setWidth] = useState(1)
    const inputEl = useRef<HTMLInputElement | null>()
    const measureEl = useRef<HTMLSpanElement>(null)
    const commandInvoker = useCommand()
    const titleInputService = useAppContext().titleInputService

    const inputPositionInfo = useRef<{ x: number; y: number; rotation: number }>()
    const updatePositionCallback = useCallback(() => {
        if (inputPositionInfo.current) {
            // 绕过React的batchUpdate, 直接监听viewState变化操作原生DOM, 避免拖动画布时抖动
            inputEl.current?.style.setProperty('left', inputPositionInfo.current.x + 'px')
            inputEl.current?.style.setProperty('top', inputPositionInfo.current.y + 'px')
            inputEl.current?.style.setProperty('transform', `rotate(${inputPositionInfo.current.rotation}rad)`)
        }
    }, [])

    const inputRefCallback = useCallback(
        (el: HTMLInputElement | null) => {
            inputEl.current = el
            updatePositionCallback()
        },
        [updatePositionCallback]
    )

    useEffect(() => {
        titleInputService?.setShowTitleInputCallback((data) => {
            // 防止被协同删除
            if (!data.show) {
                setIsInputVisible(false)
                return
            }

            const newX = data.position!.x! - 3
            const newY = data.position!.y! - 20
            const newRotation = data.rotation!

            inputPositionInfo.current = {
                x: newX,
                y: newY,
                rotation: newRotation,
            }
            updatePositionCallback()

            setX(newX)
            setY(newY)
            if (data.text) {
                setName(data.text!)
            }
            setNodeId(data.editingNodeId!)
            setMaxWidth(data.maxWidth!)
            setIsInputVisible(true)
        })
    }, [titleInputService, updatePositionCallback])

    useEffect(() => {
        inputEl.current?.setSelectionRange(0, inputEl.current.value.length)
        inputEl.current?.focus()
    }, [isInputVisible])

    useEffect(() => {
        const width_ = Math.min(maxWidth, measureEl.current?.clientWidth ? measureEl.current?.clientWidth + 2 : 0)
        setWidth(width_)
    }, [isInputVisible, maxWidth])

    const onInputChange = (e: any) => {
        setName(e.target.value)
        measureEl.current!.innerHTML = e.target.value
        const width_ = Math.min(maxWidth, measureEl.current?.clientWidth ? measureEl.current?.clientWidth + 2 : 0)
        setWidth(width_)
    }

    const confirmInput = () => {
        commandInvoker.DEPRECATED_invokeBridge(
            ConfirmTitleInputCommand,
            Wukong.DocumentProto.Args_ConfirmTitleInputCommand.create({
                nodeId,
                name,
            })
        )
        setIsInputVisible(false)
        setWidth(0)
        commandInvoker.commitUndo()
    }

    const onKeyDown = (e: KeyboardEvent) => {
        if (!e.isComposing && (e.key === 'Enter' || e.key === 'Escape')) {
            confirmInput()
            return false
        }
        return true
    }

    if (isInputVisible) {
        return (
            <>
                <span
                    className={classes.frame_input_measure}
                    ref={measureEl}
                    style={{
                        left: x + 3 + 'px',
                        top: y + 20 + 'px',
                    }}
                >
                    {name}
                </span>
                <KeyboardReceiver keyCode={ToKeyCode.All} onKeydown={onKeyDown} triggerOnlyByPropagation>
                    <input
                        id="titleInput"
                        ref={inputRefCallback}
                        value={name}
                        className={classes.frame_input}
                        onChange={onInputChange}
                        onBlur={confirmInput}
                        style={{ width: width + 'px' }}
                        autoComplete="off"
                        autoCorrect="false"
                        autoCapitalize="false"
                        spellCheck="false"
                        dir="auto"
                        data-testid="title-input"
                    />
                </KeyboardReceiver>
            </>
        )
    }

    return null
}
