import { useCallback, useEffect, useRef } from 'react'

export function useComment() {
    const bodyStyleOverflowRef = useRef<string>()
    const addOverflowHiddenToBody = useCallback(() => {
        bodyStyleOverflowRef.current = document.body.style.overflow
        document.body.style.overflow = 'hidden'
    }, [])
    const resetOverflowStatusToBody = useCallback(() => {
        if (bodyStyleOverflowRef.current !== undefined) {
            document.body.style.overflow = bodyStyleOverflowRef.current
            bodyStyleOverflowRef.current = undefined
        }
    }, [])
    useEffect(() => {
        return resetOverflowStatusToBody
    }, [resetOverflowStatusToBody])

    return { addOverflowHiddenToBody, resetOverflowStatusToBody }
}
