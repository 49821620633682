import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    TypeOr: '请输入链接',
} as const

export const enTranslation = {
    TypeOr: 'Type or paste URL',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
