import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Count: '边数',
    Count_synonyms1: '角数',
} as const

export const enTranslation = {
    Count: 'Count',
    Count_synonyms1: 'Count',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
