/* eslint-disable no-restricted-imports */
import {
    DecodeJsonAIRecognizeResultWithEncodedNodesV2,
    DecodeJsonAiDuplicateResult,
    DecodeJsonApplyAiDuplicateResultDataV2,
    DecodeJsonNodeBlobInfoV2,
    DecodeJsonPluginArcTextState,
    DocModeCommand,
    GetJsStack,
    RemoveNullContent,
    RenderTypeCommand,
    StartRecordingAllocationJsStack,
    StopRecordingAllocationJsStack,
    TraceEventForWasm,
    Wukong,
} from '@wukong/bridge-proto'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { EffectController } from '../../../../util/src/effect-controller'
import { Bridge } from '../../kernel/bridge/bridge'
import { EmBridge } from '../../kernel/bridge/em-bridge'
import { WasmUtilBridge } from '../bridge/wasm-uti-bridge'
import { CommandInvoker } from '../command/command-invoker'
import { ImageDownloadContext } from '../command/image-download-context'
import { CooperationSynergy } from '../cooperation/cooperation-synergy'
import { SynergyManager } from '../synergy/synergy-manager'
import { WebSocketBridge } from '../synergy/web-socket-bridge'
import { DocumentRoot } from './document-root'
import { handleTraceEvent } from './handle-trace-event'

function jsonParseWithLog(encodedJson: string): any {
    try {
        return JSON.parse(encodedJson)
    } catch (e) {
        throw new Error(`parse json failed with error ${e}: ${encodedJson}`)
    }
}

export function initTraceBridge(bridge: EmBridge) {
    const openedByWorkbench = window.opener?.postMessage
    bridge.bind(DecodeJsonApplyAiDuplicateResultDataV2, (arg) => {
        const v1 = jsonParseWithLog(arg.value!)
        const v2 = {} as Wukong.DocumentProto.IApplyAiDuplicateResultDataV2
        v2.aiResult = v1.aiResult
        v2.keyRelation = v1.keyRelation
        v2.blobs = Object.fromEntries(
            Object.entries(v1.blobs || {}).map(([k, v]) => [
                k,
                { value: v as Wukong.DocumentProto.IBridgeProtoStringMap },
            ])
        )
        v2.nodes = Object.fromEntries(
            Object.entries(v1.nodes || {}).map(([k, v]) => [
                k,
                { value: v as Wukong.DocumentProto.IBridgeProtoStringMap },
            ])
        )
        return v2
    })
    bridge.bind(DecodeJsonAiDuplicateResult, (arg) => {
        return jsonParseWithLog(arg.value!)
    })
    bridge.bind(DecodeJsonNodeBlobInfoV2, (arg) => {
        const v1 = jsonParseWithLog(arg.value!)
        const v2 = {} as Wukong.DocumentProto.INodeBlobInfoV2
        v2.blobs = Object.fromEntries(
            Object.entries(v1.blobs || {}).map(([k, v]) => [
                k,
                { value: v as Wukong.DocumentProto.IBridgeProtoStringMap },
            ])
        )
        v2.nodes = Object.fromEntries(
            Object.entries(v1.nodes || {}).map(([k, v]) => [
                k,
                { value: v as Wukong.DocumentProto.IBridgeProtoStringMap },
            ])
        )
        return v2
    })
    bridge.bind(DecodeJsonAIRecognizeResultWithEncodedNodesV2, (arg) => {
        const v1 = jsonParseWithLog(arg.value!)
        const v2 = {} as Wukong.DocumentProto.IAIRecognizeResultWithEncodedNodesV2
        v2.aiResult = v1.aiResult
        v2.blobs = Object.fromEntries(
            Object.entries(v1.blobs || {}).map(([k, v]) => [
                k,
                { value: v as Wukong.DocumentProto.IBridgeProtoStringMap },
            ])
        )
        v2.nodes = Object.fromEntries(
            Object.entries(v1.nodes || {}).map(([k, v]) => [
                k,
                { value: v as Wukong.DocumentProto.IBridgeProtoStringMap },
            ])
        )
        return v2
    })
    bridge.bind(DecodeJsonPluginArcTextState, (arg) => {
        return jsonParseWithLog(arg.value!)
    })
    bridge.bind(RemoveNullContent, (arg) => {
        // Parse the JSON string to a JavaScript object
        const jsonObject = jsonParseWithLog(arg.value!)

        // Recursive function to filter null values
        function filterNullKeys(obj: any): any {
            if (Array.isArray(obj)) {
                // Filter arrays by recursively processing each element and removing nulls
                return obj.map(filterNullKeys).filter((element) => element !== null)
            } else if (obj !== null && typeof obj === 'object') {
                // Process objects - create a new object with non-null keys only
                return Object.keys(obj).reduce((acc: any, key) => {
                    if (obj[key] !== null) {
                        // Ignore key if value is null
                        acc[key] = filterNullKeys(obj[key]) // Recursively process the value
                    }
                    return acc
                }, {})
            } else {
                // Return the value directly if it is not an object or array or null
                return obj
            }
        }

        // Filter the top-level object
        const filteredObject = filterNullKeys(jsonObject)

        // Convert the filtered object back to a JSON string
        return { value: JSON.stringify(filteredObject) }
    })
    bridge.bind(TraceEventForWasm, (proto) => {
        try {
            if (openedByWorkbench) {
                ;(window.opener as Window).postMessage(proto.value)
            }
            const traceEvent = JSON.parse(proto.value!)
            handleTraceEvent(bridge, traceEvent, proto.includesStack!)
        } catch (e) {
            console.warn('handle traceEvent failed', e, proto.value)
        }
    })
    bridge.bind(GetJsStack, () => {
        return { value: bridge.currentEditorService.getJsStack() }
    })
    if (openedByWorkbench) {
        window.addEventListener('message', (e) => {
            try {
                const { traceCommandName } = JSON.parse(e.data)
                switch (traceCommandName) {
                    case 'startRecordingAllocationJsStack':
                        bridge.call(StartRecordingAllocationJsStack)
                        break
                    case 'stopRecordingAllocationJsStack':
                        bridge.call(StopRecordingAllocationJsStack)
                        break
                }
            } catch (e_) {
                console.warn('handle traceCommand failed', e_)
            }
        })
    }

    return {
        destroyTraceBridge: () => {
            bridge.unbind(DecodeJsonApplyAiDuplicateResultDataV2)
            bridge.unbind(DecodeJsonAiDuplicateResult)
            bridge.unbind(DecodeJsonNodeBlobInfoV2)
            bridge.unbind(DecodeJsonAIRecognizeResultWithEncodedNodesV2)
            bridge.unbind(DecodeJsonPluginArcTextState)
            bridge.unbind(RemoveNullContent)
            bridge.unbind(TraceEventForWasm)
            bridge.unbind(GetJsStack)
        },
    }
}

function provideDocMode(
    bridge: Bridge,
    docMode: Wukong.DocumentProto.DocMode,
    renderType: Wukong.DocumentProto.RenderType,
    previewScene?: Wukong.DocumentProto.PreviewScene
) {
    bridge.bind(DocModeCommand, () => {
        return { docMode: docMode, previewScene: previewScene } as Wukong.DocumentProto.DocModeCommandParam
    })
    bridge.bind(RenderTypeCommand, () => {
        return { type: renderType } as Wukong.DocumentProto.Ret_RenderTypeCommand
    })

    return () => {
        bridge.unbind(DocModeCommand)
        bridge.unbind(RenderTypeCommand)
    }
}

const MIRROR_OSS_IMAGE_MAX_SIZE = 2048

interface InitDocumentContextConfig {
    docMode: Wukong.DocumentProto.DocMode
    renderType: Wukong.DocumentProto.RenderType
    previewScene?: Wukong.DocumentProto.PreviewScene
}

export function initDocumentContext(
    bridge: Bridge,
    docId: string,
    userId: number,
    config: InitDocumentContextConfig,
    controller: EffectController,
    signal: TraceableAbortSignal
) {
    const { destroyTraceBridge } = initTraceBridge(bridge as EmBridge)
    const destroyDocModeBridge = provideDocMode(bridge, config.docMode, config.renderType, config.previewScene)
    // 这里先和 mirror 设置成一样
    const isMirror = [Wukong.DocumentProto.DocMode.DOC_MODE_PROTOTYPE].includes(config.docMode)

    const webSocketBridge = new WebSocketBridge(
        signal,
        controller,
        docId,
        userId,
        true,
        bridge,
        isMirror,
        config.previewScene
    )
    // cooperationSynergy 必须位于 synergyManager 之前
    const cooperationSynergy = new CooperationSynergy(signal, webSocketBridge, bridge as EmBridge, isMirror)
    const synergyManager = new SynergyManager(signal, webSocketBridge, bridge as EmBridge, isMirror)
    const documentRoot = new DocumentRoot(bridge)
    const commandInvoker = new CommandInvoker(documentRoot, bridge as EmBridge)
    const imageDownloadContext = new ImageDownloadContext(
        bridge as EmBridge,
        commandInvoker,
        docId,
        webSocketBridge,
        isMirror ? MIRROR_OSS_IMAGE_MAX_SIZE : null,
        signal
    )

    const wasmUtilBridge = new WasmUtilBridge(
        bridge,
        config.docMode == Wukong.DocumentProto.DocMode.DOC_MODE_PROTOTYPE,
        signal
    )

    return {
        documentRoot,
        commandInvoker,
        imageDownloadContext,
        connectSynergy: () => {
            webSocketBridge.setClientReleaseVersion()
            webSocketBridge.connect()
            webSocketBridge.enableAutoReconnect()
        },
        destroySynergy: () => {
            webSocketBridge.destroy()
        },
        destroy: () => {
            documentRoot.destroy()
            synergyManager.destroy()
            imageDownloadContext.destroy()

            wasmUtilBridge.destroy()
            commandInvoker.destroy()
            destroyTraceBridge()
            destroyDocModeBridge()
        },
        webSocketBridge,
        synergyManager,
        resetRafCount: () => {
            wasmUtilBridge.resetRafCount()
        },
    }
}

export function initSandboxDocumentContext(bridge: Bridge, docId: string, signal: TraceableAbortSignal) {
    const documentRoot = new DocumentRoot(bridge)

    const { destroyTraceBridge } = initTraceBridge(bridge as EmBridge)
    provideDocMode(
        bridge,
        Wukong.DocumentProto.DocMode.DOC_MODE_DEFAULT,
        Wukong.DocumentProto.RenderType.RENDER_TYPE_NORMAL
    )

    const commandInvoker = new CommandInvoker(documentRoot, bridge as EmBridge)
    const imageDownloadContext = new ImageDownloadContext(
        bridge as EmBridge,
        commandInvoker,
        docId,
        undefined,
        null,
        signal
    )
    const wasmUtilBridge = new WasmUtilBridge(bridge, false)

    return {
        documentRoot,
        commandInvoker,
        imageDownloadContext,
        destroy: () => {
            documentRoot.destroy()

            wasmUtilBridge.destroy()
            commandInvoker.destroy()
            destroyTraceBridge()
        },
        resetRafCount: () => {
            wasmUtilBridge.resetRafCount()
        },
    }
}
