import { useMemo, useRef } from 'react'
import { DropdownV2, MonoIconPanelApplyProperty16, WKIconButton, WKToast } from '../../../../../../../../ui-lib/src'
import Tooltip from '../../../../../../../../ui-lib/src/components/tooltip/tooltip'
import { PopupStateType } from '../../../../../../document/node/node'
import { ComponentPropNodeField, ValidateInstanceSwapValueScene, VComponentPropType } from '../../types'
import { useComponentProp } from '../component-prop/hook'
import { translation } from './index.translation'

const CreateInstanceSwapPropBtnAlias = 'create-instance-swap-prop-btn'

export function InstanceSwapPropApplyBtn() {
    const {
        showApplyIconForCreateInstanceSwapProp,
        showApplyIconForApplyInstanceSwapProp,
        componentPropDefs,
        toggleComponentPropPopup,
        applyProp,
        validateInstanceSwapValue,
    } = useComponentProp()

    const containerRef = useRef<HTMLDivElement>(null)
    const instanceSwapPropList = useMemo(() => {
        return componentPropDefs.filter(
            (compProp) => compProp.type === VComponentPropType.V_COMPONENT_PROP_TYPE_INSTANCE_SWAP
        )
    }, [componentPropDefs])

    const showApplyIcon = showApplyIconForCreateInstanceSwapProp || showApplyIconForApplyInstanceSwapProp
    if (!showApplyIcon) {
        return null
    }

    const onSelectChange = (value: string): void => {
        // value 为 [xx:xx]形式 / CreateBoolPropBtnAlias
        if (value !== CreateInstanceSwapPropBtnAlias) {
            const applyDef = instanceSwapPropList.find((def) => def.id === value)
            if (applyDef) {
                if (
                    validateInstanceSwapValue({
                        scene: ValidateInstanceSwapValueScene.VALIDATE_INSTANCE_SWAP_VALUE_SCENE_APPLY_INSTANCE_SWAP,
                        newInstanceSwapValue: applyDef.varValue.value.mainComponentId,
                        affectedNodeIds: [],
                    })
                ) {
                    applyProp(ComponentPropNodeField.COMPONENT_PROP_NODE_FIELD_OVERRIDDEN_SYMBOL_ID, value)
                } else {
                    WKToast.show(translation('ComponentCanNotContainItself'))
                }
            }
        }
    }

    const openInstanceAreaCreateInstanceSwapPropPopup = () => {
        toggleComponentPropPopup(PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_INSTANCE_SWAP_PROP_OF_INSTANCE_AREA)
    }

    return (
        <div ref={containerRef}>
            {showApplyIconForCreateInstanceSwapProp && (
                <Tooltip title={translation('CreateInstanceSwapProp')}>
                    <WKIconButton
                        data-testid="instance-swap-prop-apply-icon-for-create"
                        icon={<MonoIconPanelApplyProperty16 />}
                        onClick={openInstanceAreaCreateInstanceSwapPropPopup}
                        type="primary"
                    />
                </Tooltip>
            )}
            {showApplyIconForApplyInstanceSwapProp && (
                <Tooltip title={translation('ApplyInstanceSwapProp')} triggerRefKey="getTriggerElement">
                    <DropdownV2.IconSingleLevel
                        maxWidth={228}
                        label={<MonoIconPanelApplyProperty16 />}
                        dataTestIds={{ triggerFocus: 'instance-swap-prop-apply-icon-for-apply' }}
                        onChange={onSelectChange}
                    >
                        {instanceSwapPropList.map((instanceSwapProp) => (
                            <DropdownV2.IconSingleLevel.Option key={instanceSwapProp.id} value={instanceSwapProp.id}>
                                {instanceSwapProp.name}
                            </DropdownV2.IconSingleLevel.Option>
                        ))}
                        <DropdownV2.IconSingleLevel.Option
                            splitLineTop
                            onClick={openInstanceAreaCreateInstanceSwapPropPopup}
                            value={CreateInstanceSwapPropBtnAlias}
                        >
                            {translation('CreateProp')}
                        </DropdownV2.IconSingleLevel.Option>
                    </DropdownV2.IconSingleLevel>
                </Tooltip>
            )}
        </div>
    )
}
