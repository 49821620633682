import { WKToast } from '../../../../ui-lib/src'
import { BatchInviteUser, ReinviteUser } from '../../kernel/request/user-roles'
import { RequestResponseErrorHandler } from '../../kernel/request/error-handler'
import { translation } from './batch-invite-user-request.translation'

export const batchInviteUserRequest = (...args: ConstructorParameters<typeof BatchInviteUser>) => {
    return new BatchInviteUser(...args).start().catch((error) => {
        const { businessStatus } = RequestResponseErrorHandler(error)
        if (businessStatus === 75004) {
            WKToast.error(translation('msg'))
        }
        throw error
    })
}

export const reinviteUserRequest = (...args: ConstructorParameters<typeof ReinviteUser>) => {
    return new ReinviteUser(...args)
        .start()
        .then(() => {
            WKToast.show(translation('ReinviteSuccess'))
        })
        .catch((error) => {
            const { businessStatus } = RequestResponseErrorHandler(error)
            if (businessStatus === 75004) {
                WKToast.error(translation('msg'))
            }
            throw error
        })
}
