import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    PendingTipAboveProgressBar: 'AI 一致性检查中…',
    PendingTipUnderProgressBar: 'AI 检查过程中的修改不会被识别',
} as const

export const enTranslation = {
    PendingTipAboveProgressBar: 'AI consistency checking…',
    PendingTipUnderProgressBar: 'Modifications during AI checking will not be recognized',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
