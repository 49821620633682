import { translation } from './index.translation'
import { WKButton } from '../../../../../../ui-lib/src/components/wk-button/button'
import { WKDialog } from '../../../../../../ui-lib/src/components/wk-dialog'
import { FC } from 'react'
import { QuotaExccededModalProps } from '../types'
import styles from './index.module.less'
import { getMotiffName } from '../../../../../../util/src/motiff-name'
import { Hint } from '../assets/hint'
import { MonoIconCommonClose16 } from '../../../../../../ui-lib/src/icons-v2/mono/16'
import { WKIconButton } from '../../../../../../ui-lib/src/components/wk-button/icon-button'
import { environment } from '../../../../environment'

export const NormalModal: FC<QuotaExccededModalProps> = ({ close }) => {
    const handleClose = () => {
        close()
    }

    return (
        <WKDialog
            titleRender={
                <div className={styles['title-wrapper']}>
                    <Hint />
                    <div className={styles.title}>{translation('title')}</div>
                    <WKIconButton
                        data-testid="close-icon"
                        className="shrink-0"
                        onClick={handleClose}
                        icon={<MonoIconCommonClose16 />}
                    ></WKIconButton>
                </div>
            }
            bodyStyle={{ paddingTop: '12px' }}
            footer={
                <WKButton type="primary" onClick={handleClose}>
                    {translation('ok')}
                </WKButton>
            }
            maskClosable={false}
            hideFooterLine
            hideTitleLine
            visible
        >
            <div className={styles['description-wrapper']}>
                <div className={styles.description}>
                    <span>
                        {translation('description', {
                            name: getMotiffName(),
                        })}
                    </span>
                    <a href={`${environment.helpDocsUrl.quota}`} target="_black">
                        {translation('learnMore')}
                    </a>
                </div>
            </div>
        </WKDialog>
    )
}
