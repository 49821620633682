import { translation } from './style-list-item.translation'
/* eslint-disable no-restricted-imports */
import classNames from 'classnames'
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { IconAdjust, ScrollView, Tooltip, isRightClick } from '../../../../../../../ui-lib/src'
import { IconButton } from '../../../atom/button/icon-button'
import { CommonStyleInfo } from '../get-style-nodes-info-map'
import { useLazyItem } from '../hooks/use-lazy-item'
import { StyleToolTip } from '../style-tooltip/style-tooltip'
import style from './style-list-item.module.less'

export interface StyleListItemKeyboardProps {
    index: number
    selectStyleId?: string
    setPreselectIndex: (index?: number) => void
    trySetPreselectIndex: (index: number) => void
    recordEnterCallback: (index: number, callback: () => void) => void
}
export interface StyleListItemProps<T> {
    item: CommonStyleInfo & T
    openStyleId?: string
    className?: string
    disabledOnClick?: boolean
    children?: React.ReactNode
    dataTestId?: string
    onSelectItem?: (item: CommonStyleInfo & T) => void
    onClickHoverIcon?: (item: CommonStyleInfo & T, containerDomRect: DOMRect) => void
    onContextMenu?: (item: CommonStyleInfo & T, e: any) => void
    isRightClickStyle?: boolean
    isReadonly?: boolean
}
export function StyleListItem<T>(props: StyleListItemProps<T> & StyleListItemKeyboardProps) {
    const {
        item,
        openStyleId,
        className,
        disabledOnClick,
        onSelectItem,
        onClickHoverIcon,
        onContextMenu,
        dataTestId,
        isRightClickStyle,
        isReadonly = false,
    } = props
    const containerRef = useRef<HTMLDivElement>(null)
    const { visible } = useLazyItem(containerRef)

    const _onMouseDown: React.MouseEventHandler<HTMLDivElement> = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (disabledOnClick || isRightClick(e)) {
                return
            }
            onSelectItem?.(item)
        },
        [item, onSelectItem, disabledOnClick]
    )

    const _onClickHoverIcon: React.MouseEventHandler<HTMLDivElement> = useCallback(() => {
        if (!containerRef.current) {
            return
        }
        const containerDomRect = containerRef.current.getBoundingClientRect()
        onClickHoverIcon?.(item, containerDomRect)
    }, [item, onClickHoverIcon])

    const _onContextMenu: React.MouseEventHandler<HTMLDivElement> = useCallback(
        (e) => {
            e.stopPropagation()
            e.preventDefault()
            onContextMenu?.(item, e)
        },
        [item, onContextMenu]
    )

    const isOpenEditing = useMemo(() => {
        return item.id === openStyleId
    }, [item.id, openStyleId])

    useLayoutEffect(() => {
        if (item.id === props.selectStyleId) {
            props.trySetPreselectIndex(props.index)
        }
    }, [item.id, props])

    useEffect(() => {
        props.recordEnterCallback(props.index, () => !disabledOnClick && onSelectItem?.(item))
    }, [disabledOnClick, item, onSelectItem, props])

    return (
        <ScrollView.Item
            uniqueKey={props.index}
            ref={containerRef}
            onContextMenu={_onContextMenu}
            data-testid={dataTestId}
            data-index={props.index}
            onMouseDown={_onMouseDown}
            selectClassName={style.preselect}
            className={classNames(style.listItem, [className], {
                [style['right-click-menu-visible']]: isRightClickStyle,
                [style.selectItem]: item.id === props.selectStyleId,
            })}
            onMouseMove={() => props.setPreselectIndex(props.index)}
            onMouseLeave={() => props.setPreselectIndex()}
        >
            {visible ? (
                <>
                    <StyleToolTip
                        titleText={item.name}
                        contentText={item.description}
                        triggerRect={() => containerRef.current!.getBoundingClientRect()}
                    >
                        <div className={style.left} data-testid={item.id}>
                            {props.children}
                        </div>
                    </StyleToolTip>
                    {onClickHoverIcon && (
                        <div className={classNames(style.right, { [style.showRight]: isOpenEditing })}>
                            <Tooltip title={isReadonly ? translation('ViewStyle') : translation('EditStyle')}>
                                <IconButton
                                    icon={<IconAdjust />}
                                    selected={false}
                                    className={classNames(style.adjust, { [style.selected]: isOpenEditing })}
                                    onClick={_onClickHoverIcon}
                                    dataTestId="style-list-item-adjust-button"
                                />
                            </Tooltip>
                        </div>
                    )}
                </>
            ) : null}
        </ScrollView.Item>
    )
}
