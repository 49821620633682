import {
    DownloadPrototypeDeviceFrameCommand,
    OnPrototypeDeviceFrameDownloadCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { sleep } from '../../../../util/src'
import { EffectController } from '../../../../util/src/effect-controller'
import type { ImageDownloadContext } from '../../document/command/image-download-context'
import { environment } from '../../environment'
import { Bridge } from '../../kernel/bridge/bridge'
import { ServiceClass } from '../../kernel/util/service-class'

export class PrototypePreviewService extends ServiceClass {
    private MAX_CACHE_SIZE = 10
    private deviceFrameBlobs = new Map<string, Blob>()

    constructor(
        protected override readonly bridge: Bridge,
        private readonly imageManager: ImageDownloadContext,
        controller: EffectController
    ) {
        super(bridge)
        controller.onCleanup(() => this.destroy())

        this.bindJsCall(DownloadPrototypeDeviceFrameCommand, (arg) => {
            this.downloadDeviceFrameImpl(arg)
        })
    }

    public override destroy() {
        super.destroy()
        this.deviceFrameBlobs.clear()
    }

    private async downloadDeviceFrameImpl(arg: Wukong.DocumentProto.IArg_DownloadPrototypeDeviceFrame) {
        // 保证异步
        await sleep(0)
        if (this.isDestroy) {
            return
        }

        if (arg.deviceImageUrl === '') {
            this.bridge.call(OnPrototypeDeviceFrameDownloadCommand, {
                deviceImageUrl: '',
                width: 0,
                height: 0,
                data: new Uint8Array(),
            })
            return
        }

        try {
            const bitmap = await this.fetchDeviceFrameAndDecode(arg.deviceImageUrl)
            if (this.isDestroy) {
                return
            }
            this.bridge.call(OnPrototypeDeviceFrameDownloadCommand, {
                deviceImageUrl: arg.deviceImageUrl,
                width: bitmap.width,
                height: bitmap.height,
                data: bitmap.data,
            })
        } catch (e) {
            console.error(e)
        }
    }

    private async fetchDeviceFrameAndDecode(deviceImageUrl: string): Promise<{
        width: number
        height: number
        data: Uint8Array
    }> {
        return this.imageManager.fetchAndDecodeControlImage(() => this.fetchDeviceFrame(deviceImageUrl))
    }

    private async fetchDeviceFrame(deviceImageUrl: string): Promise<Blob> {
        if (this.deviceFrameBlobs.has(deviceImageUrl)) {
            return this.deviceFrameBlobs.get(deviceImageUrl)!
        }

        const url = `${environment.ossCdnPath}${environment.publicResourcePrefix}/device/${deviceImageUrl}`
        const blob = await fetch(url).then((resp) => {
            if (!resp.ok) {
                throw Error(`fail to fetch ${deviceImageUrl}`)
            }
            return resp.blob()
        })
        if (this.deviceFrameBlobs.has(deviceImageUrl)) {
            return this.deviceFrameBlobs.get(deviceImageUrl)!
        }
        if (this.deviceFrameBlobs.size >= this.MAX_CACHE_SIZE) {
            this.deviceFrameBlobs.delete(this.deviceFrameBlobs.keys().next().value!)
        }
        this.deviceFrameBlobs.set(deviceImageUrl, blob)
        return blob
    }
}
