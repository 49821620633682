import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    AnimateMatchingLayers: '匹配图层智能动画',
} as const

export const enTranslation = {
    AnimateMatchingLayers: 'Animate matching layers',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
