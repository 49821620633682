import { useViewState } from '../../../../../../view-state-bridge'
import { CompSetPropConflictType } from '../../types'
import { ConflictPanel } from '../variant-panel'

export function ComponentPropVariantConflict() {
    const variantState = useViewState('componentPanelState')!.variantState

    return (
        <>
            {!variantState.isRemovedVariantInstance &&
                variantState.conflict.type !== CompSetPropConflictType.COMP_SET_PROP_CONFLICT_TYPE_EMPTY && (
                    <ConflictPanel {...variantState.conflict} />
                )}
        </>
    )
}
