import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    PleaseDownloadAnd: '请先下载并安装字体助手，刷新页面可使用本地字体',
    DownloadInstallerTo: '安装字体助手',
    Update: '立即更新',
    UseNewVersion: '本地字体版本过低，请更新后刷新页面使用本地字体。',
} as const

export const enTranslation = {
    PleaseDownloadAnd: 'Install the Motiff Font Agent, and reload the page to enable local fonts.',
    DownloadInstallerTo: 'Download Motiff Font Agent',
    Update: 'Upgrade now',
    UseNewVersion: 'The font agent version is out of date. Update it and refresh the page to use the local font.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
