import { MethodSignature } from '@wukong/bridge-proto'
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription, type Observer } from 'rxjs'
import type { createStore } from '../../../../util/src/zustand-utils'
import { ViewStateBridge, ViewStateToken, ViewStateTokenTypeMap } from '../../view-state-bridge'
import { Bridge } from '../bridge/bridge'

type StoreSubscribeWithSelectorSubscribe<T, U> = (
    selector: (state: T) => U,
    listener: (selectedState: U, previousSelectedState: U) => void,
    options?: {
        equalityFn?: (a: U, b: U) => boolean
        fireImmediately?: boolean
    }
) => () => void

export class ServiceClass {
    public isDestroy = false
    private subscriptions: Subscription[] = []
    private zustandStoreSubscriptions: (() => void)[] = []
    private subjects: Subject<any>[] = []
    private bindJsBridgeMethods: MethodSignature<any, any>[] = []
    private windowListeners = new Map<string, Set<(ev: any) => any>>()
    private registerViewStates = new Map<ViewStateToken, Set<(value: any) => void>>()
    private timeoutTimers = new Set<NodeJS.Timeout>()

    constructor(protected readonly bridge?: Bridge, protected readonly viewStateBridge?: ViewStateBridge) {}

    protected buildBehaviorSubject = <T>(defaultValue: T) => {
        const subject = new BehaviorSubject<T>(defaultValue)
        this.subjects.push(subject)
        return subject
    }

    protected buildReplaySubject = <T>(bufferSize = 1) => {
        const subject = new ReplaySubject<T>(bufferSize)
        this.subjects.push(subject)
        return subject
    }

    private destroySubjects = () => {
        this.subjects.forEach((subject) => subject.complete())
        this.subjects = []
    }

    protected subscribe = <T>(observable: Observable<T>, fn: Partial<Observer<T>> | ((data: T) => void)) => {
        const unsubscribe = observable.subscribe(fn)
        this.subscriptions.push(unsubscribe)
        return unsubscribe
    }

    protected subscribeZustand = <T, U>(
        zustandStore: ReturnType<typeof createStore<T>>,
        ...args: Parameters<StoreSubscribeWithSelectorSubscribe<T, U>>
    ) => {
        const unsubscribe = zustandStore.subscribe(...args)
        this.zustandStoreSubscriptions.push(unsubscribe)
        return unsubscribe
    }

    private destroySubscriptions = () => {
        this.subscriptions.forEach((sub) => sub.unsubscribe())
        this.subscriptions = []

        this.zustandStoreSubscriptions.forEach((sub) => sub())
        this.zustandStoreSubscriptions = []
    }

    protected bindJsCall = <RET, ARG>(method: MethodSignature<RET, ARG>, fn: (arg: ARG) => RET) => {
        if (!this.bridge) {
            throw Error('constructor 缺少 bridge 传入')
        }
        this.bindJsBridgeMethods.push(method)
        this.bridge?.bind(method, fn)
    }

    private unbindJsCalls = () => {
        this.bindJsBridgeMethods.forEach((method) => {
            this.bridge?.unbind(method)
        })
    }

    protected registerViewState: ViewStateBridge['register'] = <T extends ViewStateToken>(
        token: T,
        syncViewStateFn: (value: ViewStateTokenTypeMap[T]) => void
    ): void => {
        if (!this.viewStateBridge) {
            throw Error('constructor 缺少 viewStateBridge 传入')
        }

        this.viewStateBridge.register(token, syncViewStateFn)

        if (this.registerViewStates.has(token)) {
            this.registerViewStates.get(token)?.add(syncViewStateFn)
        } else {
            this.registerViewStates.set(token, new Set([syncViewStateFn]))
        }
    }

    protected unregisterViewState: ViewStateBridge['unregister'] = <T extends ViewStateToken>(
        token: T,
        syncViewStateFn: (value: ViewStateTokenTypeMap[T]) => void
    ): void => {
        if (!this.viewStateBridge) {
            throw Error('constructor 缺少 viewStateBridge 传入')
        }

        this.viewStateBridge.unregister(token, syncViewStateFn)

        if (this.registerViewStates.has(token)) {
            this.registerViewStates.get(token)?.delete(syncViewStateFn)
        }
    }

    private unregisterViewStates = (): void => {
        ;[...this.registerViewStates.entries()].forEach(([token, syncViewStateFns]) => {
            syncViewStateFns.forEach((syncViewStateFn) => {
                this.viewStateBridge?.unregister(token, syncViewStateFn)
            })
        })
        this.registerViewStates.clear()
    }

    protected startSetTimeout = (...[callback, ms]: Parameters<typeof setTimeout>) => {
        const timer = setTimeout(() => {
            this.timeoutTimers.delete(timer)
            callback()
        }, ms)
        this.timeoutTimers.add(timer)
        return timer
    }

    protected clearTimeoutTimer = (timer: NodeJS.Timeout) => {
        clearTimeout(timer)
        this.timeoutTimers.delete(timer)
    }

    private clearTimeoutTimers = () => {
        this.timeoutTimers.forEach((timer) => clearTimeout(timer))
        this.timeoutTimers.clear()
    }

    public addWindowEventListener = <K extends keyof WindowEventMap>(
        type: K,
        listener: (ev: WindowEventMap[K]) => any
    ): void => {
        if (!this.windowListeners.has(type)) {
            this.windowListeners.set(type, new Set())
        }

        this.windowListeners.get(type)?.add(listener)
        window.addEventListener(type, listener)
    }

    public addWindowEventListenerOnce = <K extends keyof WindowEventMap>(
        type: K,
        listener: (ev: WindowEventMap[K]) => any
    ): void => {
        const onceListener = (ev: WindowEventMap[K]) => {
            this.removeWindowEventListener(type, onceListener)
            listener(ev)
        }

        this.addWindowEventListener(type, onceListener)
    }

    public removeWindowEventListener = <K extends keyof WindowEventMap>(
        type: K,
        listener?: (ev: WindowEventMap[K]) => any
    ): void => {
        if (listener) {
            this.windowListeners.get(type)?.delete(listener)
            window.removeEventListener(type, listener)
        } else {
            this.windowListeners.get(type)?.forEach((item) => {
                window.removeEventListener(type, item)
            })
            this.windowListeners.delete(type)
        }
    }

    private clearWindowEventListeners = () => {
        this.windowListeners.forEach((listeners, type) => {
            listeners.forEach((listener) => {
                window.removeEventListener(type, listener)
            })
        })
        this.windowListeners.clear()
    }

    public destroy() {
        this.destroySubscriptions()
        this.destroySubjects()

        this.unbindJsCalls()
        this.clearWindowEventListeners()
        this.unregisterViewStates()
        this.clearTimeoutTimers()
        this.isDestroy = true
    }
}
