/* eslint-disable no-restricted-imports */
import { useCallback, useRef, useState } from 'react'
import { ScrollViewRef, safeCall } from '../../../../../../../ui-lib/src'

export interface UseKeyboardStyleListProps {
    disabled?: boolean
    maxPreSelectIndex: number
}

export function useKeyboardStyleStyleList(props: UseKeyboardStyleListProps) {
    const { disabled, maxPreSelectIndex } = props
    const [preselectIndex, _setPreselectIndex] = useState<number>(0)
    const enterCallbackRef = useRef<Map<number, () => void>>(new Map())
    const allowTrySetPreselectIndex = useRef<boolean>(true)
    const scrollViewRef = useRef<ScrollViewRef>(null)
    const preValue = useRef<string>('')
    const composing = useRef<boolean>(false)
    const recordEnterCallback = useCallback((index: number, callback: () => void) => {
        enterCallbackRef.current.set(index, () => safeCall(callback))
    }, [])
    const setPreselectIndex = useCallback(
        (index?: number) => {
            if (disabled) {
                return
            }
            _setPreselectIndex(index === undefined ? -1 : index)
        },
        [disabled]
    )
    const trySetPreselectIndex = useCallback(
        (index: number) => {
            if (disabled) {
                return
            }
            if (allowTrySetPreselectIndex.current) {
                setPreselectIndex(index)
            }
            allowTrySetPreselectIndex.current = false
        },
        [disabled, setPreselectIndex]
    )

    const compositionStart = useCallback(() => {
        composing.current = true
    }, [])

    const compositionEnd = useCallback(() => {
        composing.current = false
    }, [])

    const onInput = useCallback(
        (e: React.FormEvent<HTMLInputElement>) => {
            const currValue = (e.target as HTMLInputElement).value
            if (preValue.current !== currValue) {
                allowTrySetPreselectIndex.current = true
                preValue.current = currValue
                setPreselectIndex(0)
            }
        },
        [setPreselectIndex]
    )

    const findIndex = useCallback((isRevert?: boolean) => {
        const container = scrollViewRef.current?.getOriginRef().getContainerElement()
        if (!container) {
            return
        }
        const containerRect = container.getBoundingClientRect()
        const options = container.querySelectorAll('[data-index]') as NodeListOf<HTMLElement>
        if (isRevert) {
            for (const option of [...options].reverse()) {
                const optionRect = option.getBoundingClientRect()
                if (optionRect.bottom <= containerRect.top + container.clientHeight) {
                    const index = option.dataset.index
                    return index ? Number(index) : undefined
                }
            }
        } else {
            for (const option of options) {
                const optionRect = option.getBoundingClientRect()
                if (optionRect.top >= containerRect.top) {
                    const index = option.dataset.index
                    return index ? Number(index) : undefined
                }
            }
        }
    }, [])

    const onKeyDown = useCallback(
        (e: React.KeyboardEvent | KeyboardEvent) => {
            if (disabled || composing.current) {
                return true
            }
            if (e.key === 'ArrowDown') {
                e.stopPropagation()
                e.preventDefault()
                if (preselectIndex === -1) {
                    const index = findIndex()
                    if (index !== undefined) {
                        setPreselectIndex(index)
                        return false
                    }
                }
                if (preselectIndex >= maxPreSelectIndex) {
                    setPreselectIndex(0)
                } else {
                    setPreselectIndex(preselectIndex + 1)
                }
                return false
            }
            if (e.key === 'ArrowUp') {
                e.stopPropagation()
                e.preventDefault()
                if (preselectIndex === -1) {
                    const index = findIndex(true)
                    if (index !== undefined) {
                        setPreselectIndex(index)
                        return false
                    }
                }
                if (preselectIndex <= 0) {
                    setPreselectIndex(maxPreSelectIndex)
                } else {
                    setPreselectIndex(preselectIndex - 1)
                }
                return false
            }

            if (e.key === 'Enter') {
                e.stopPropagation()
                e.preventDefault()
                enterCallbackRef.current.get(preselectIndex)?.()
                return false
            }
            return true
        },
        [disabled, preselectIndex, maxPreSelectIndex, findIndex, setPreselectIndex]
    )

    const reset = useCallback(() => {
        allowTrySetPreselectIndex.current = true
        setPreselectIndex(0)
    }, [setPreselectIndex])

    return {
        preselectIndex,
        scrollViewRef,
        onInput,
        compositionStart,
        compositionEnd,
        onKeyDown,
        setPreselectIndex,
        trySetPreselectIndex,
        recordEnterCallback,
        reset,
    }
}
