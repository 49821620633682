import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    sr: '发送申请',
    r: '申请{{seat}}',
    a14: '你可以在等待审批期间先行使用最长 14 天。',
    ca: '选择管理员：',
    an: '请说明申请理由（选填）',
    eml: '请不要超过 500 字。',
    cal: '请选择至少一位管理员',
    workspaceDescription: '你的申请将发送给工作区或企业管理员，选择与你最相关的工作区：',
    SelectWorkspace: '选择工作区',
    MyWorkspaceIsNotListed: '以上没有我的工作区',
    IDoNotKnowMyWorkspace: '我不知道我的工作区',
} as const

export const enTranslation = {
    sr: 'Send request',
    r: 'Request {{seat}}',
    a14: ' Available for 14 days before approval.',
    ca: 'Choose admin: ',
    an: 'Add a note (optional)',
    eml: 'Exceeds maximum length limit of 500 characters.',
    cal: 'Choose at least one admin',
    workspaceDescription:
        'Your request will be sent to your workspace or organization admins, select the workspace that is most relevant to you:',
    SelectWorkspace: 'Select workspace',
    MyWorkspaceIsNotListed: 'My workspace is not listed',
    IDoNotKnowMyWorkspace: "I don't know my workspace",
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
