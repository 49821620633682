import { HTMLAttributes } from 'react'

export interface Rect {
    left: number
    top: number
    right: number
    bottom: number
}

export interface WKPopoverPosition {
    top: number
    left: number
}

export interface WKPopoverProps extends HTMLAttributes<HTMLDivElement> {
    /**
     * 弹窗气泡内容
     */
    contents?: React.ReactNode | (() => React.ReactNode)

    /**
     * 出现气泡延迟时间，单位ms，默认为100
     */
    showDelayTime?: number

    /**
     * 隐藏气泡延迟时间，单位ms，默认为300
     */
    hideDelayTime?: number

    /**
     * 触发方式，目前只支持hover
     */
    trigger?: 'hover'[]

    /**
     * 是否显示气泡
     */
    visible?: boolean

    /**
     * 弹窗气泡位置，默认 botom-center
     */
    placement?: WKPopoverPlacement

    testId?: string
    arrowTestId?: string
    triggerTestId?: string

    /**
     * 弹窗气泡内容的 className
     */
    contentClassName?: string

    /**
     * 自定义触发器的位置
     */
    triggerRect?: Rect | (() => Rect | undefined)

    /**
     * 自定义视窗的位置
     */
    worldRect?: Rect | (() => Rect)
}

export interface WKPopoverRef {
    show: () => void
    hide: () => void
}

export type WKPopoverPlacement =
    | 'bottom-center'
    | 'bottom-left'
    | 'bottom-right'
    | 'top-center'
    | 'top-left'
    | 'top-right'
    | 'left-center'
    | 'left-top'
    | 'left-bottom'
    | 'right-center'
    | 'right-top'
    | 'right-bottom'

export const POPOVER_CONTAINER_CLASSNAME = 'wk-popover-container'
// 视窗安全距离8px
export const DISTANCE_TOP = 8
export const DISTANCE_BOTTOM = 8
// 滚动高度最小为120px
export const POPOVER_MIN_SCROLL_HEIGHT = 120
// 箭头宽度12px，高度6px
export const POPOVER_ARROW_WIDTH = 12
// 箭头偏移量10px
export const POPOVER_ARROW_OFFSET = 10
export const MIN_OFFSET_TOP = DISTANCE_TOP + POPOVER_ARROW_WIDTH / 2 + POPOVER_MIN_SCROLL_HEIGHT
export const MIN_OFFSET_BOTTOM = DISTANCE_BOTTOM + POPOVER_ARROW_WIDTH / 2 + POPOVER_MIN_SCROLL_HEIGHT

/**
 * 计算上下展开的气泡位置
 * 箭头位置会根据worldRect重新计算
 */
const calculateVerticalPosition = ({
    placement,
    triggerRect,
    worldRect,
    contentRect,
    newMaxHeight,
}: {
    placement: WKPopoverPlacement
    triggerRect: Rect
    worldRect: Rect
    contentRect: Rect
    newMaxHeight: number
}) => {
    const { top: triggerTop, left: triggerLeft, bottom: triggerBottom } = triggerRect
    const triggerWidth = triggerRect.right - triggerRect.left
    const contentWidth = contentRect.right - contentRect.left
    const triggerCenter = triggerLeft + triggerWidth / 2
    const newPopoverPosition = { top: 0, left: 0 }
    const posArr = placement.split('-')
    const isBottom = posArr[0] === 'bottom'
    let newPlacement = placement

    if (isBottom) {
        newPopoverPosition.top = triggerBottom + POPOVER_ARROW_WIDTH / 2
    } else {
        newPopoverPosition.top = triggerTop - newMaxHeight - POPOVER_ARROW_WIDTH / 2
    }

    // top-right
    const topLeft1 = triggerCenter - POPOVER_ARROW_OFFSET - POPOVER_ARROW_WIDTH / 2
    // top
    const topLeft2 = triggerCenter - contentWidth / 2
    // top-left
    const topLeft3 = triggerCenter + POPOVER_ARROW_OFFSET + POPOVER_ARROW_WIDTH / 2 - contentWidth

    switch (placement) {
        case 'top-right':
        case 'bottom-right':
            newPopoverPosition.left = topLeft1
            // 右侧超出屏幕，左侧有空间
            if (topLeft1 + contentWidth > worldRect.right) {
                if (topLeft2 > worldRect.left && topLeft2 + contentWidth < worldRect.right) {
                    newPlacement = isBottom ? 'bottom-center' : 'top-center'
                    newPopoverPosition.left = topLeft2
                } else if (topLeft3 > worldRect.left) {
                    newPlacement = isBottom ? 'bottom-left' : 'top-left'
                    newPopoverPosition.left = topLeft3
                }
            }
            break
        case 'top-center':
        case 'bottom-center':
            newPopoverPosition.left = topLeft2
            // 左侧超出屏幕，右侧有空间
            if (topLeft2 < worldRect.left && topLeft1 + contentWidth < worldRect.right) {
                newPlacement = isBottom ? 'bottom-right' : 'top-right'
                newPopoverPosition.left = topLeft1
                // 右侧超出屏幕，左侧有空间
            } else if (topLeft2 + contentWidth > worldRect.right && topLeft3 > worldRect.left) {
                newPlacement = isBottom ? 'bottom-left' : 'top-left'
                newPopoverPosition.left = topLeft3
            }
            break
        case 'top-left':
        case 'bottom-left':
            newPopoverPosition.left = topLeft3
            // 左侧超出屏幕，右侧有空间
            if (topLeft3 < worldRect.left) {
                if (topLeft2 > worldRect.left && topLeft2 + contentWidth < worldRect.right) {
                    newPlacement = isBottom ? 'bottom-center' : 'top-center'
                    newPopoverPosition.left = topLeft2
                } else if (topLeft1 + contentWidth < worldRect.right) {
                    newPlacement = isBottom ? 'bottom-right' : 'top-right'
                    newPopoverPosition.left = topLeft1
                }
            }
            break
        default:
            break
    }
    return { newPlacement, newPopoverPosition }
}

/**
 * 计算左右展开的气泡位置
 * 不会重新计算箭头位置
 */
const calculateHorizontalPosition = ({
    placement,
    triggerRect,
    contentRect,
}: {
    placement: WKPopoverPlacement
    triggerRect: Rect
    contentRect: Rect
}) => {
    const newPopoverPosition = { top: 0, left: 0 }
    const posArr = placement.split('-')
    const isRight = posArr[0] === 'right'
    const contentWidth = contentRect.right - contentRect.left
    const contentHeight = contentRect.bottom - contentRect.top
    const triggerMiddle = (triggerRect.top + triggerRect.bottom) / 2

    if (isRight) {
        newPopoverPosition.left = triggerRect.right + POPOVER_ARROW_WIDTH / 2
    } else {
        newPopoverPosition.left = triggerRect.left - contentWidth - POPOVER_ARROW_WIDTH / 2
    }

    switch (placement) {
        case 'left-top':
        case 'right-top':
            newPopoverPosition.top = triggerMiddle + POPOVER_ARROW_OFFSET + POPOVER_ARROW_WIDTH / 2 - contentHeight
            break
        case 'left-center':
        case 'right-center':
            newPopoverPosition.top = triggerMiddle - contentHeight / 2
            break
        case 'left-bottom':
        case 'right-bottom':
            newPopoverPosition.top = triggerMiddle - POPOVER_ARROW_OFFSET - POPOVER_ARROW_WIDTH / 2
            break
        default:
            break
    }
    return { newPopoverPosition }
}

/**
 * 计算 popover 的位置
 * @returns {newPlacement, newPopoverPosition, newMaxHeight}
 */
export const calculatePopoverRect = ({
    placement,
    triggerRect,
    contentRect,
    worldRect,
}: {
    placement: WKPopoverPlacement
    triggerRect: Rect
    contentRect: Rect
    worldRect: Rect
}) => {
    const posArr = placement.split('-')
    const isBottom = posArr[0] === 'bottom'
    const bottomRest = worldRect.bottom - triggerRect.bottom
    const topRest = triggerRect.top - worldRect.top
    const contentHeight = contentRect.bottom - contentRect.top
    let newMaxHeight = contentHeight

    // 左右展开的气泡，不会重新计算
    if (posArr[0] === 'left' || posArr[0] === 'right') {
        const { newPopoverPosition } = calculateHorizontalPosition({
            placement,
            triggerRect,
            contentRect,
        })
        return { newPlacement: placement, newPopoverPosition, newMaxHeight }
    }

    // 上下展开的气泡会根据worldRect重新计算气泡展开的方向&滚动高度
    // 气泡出现在上方
    if (
        (isBottom &&
            bottomRest < newMaxHeight + POPOVER_ARROW_WIDTH / 2 + DISTANCE_BOTTOM &&
            bottomRest < topRest &&
            bottomRest < MIN_OFFSET_BOTTOM) ||
        (!isBottom &&
            !(
                topRest < newMaxHeight + POPOVER_ARROW_WIDTH / 2 + DISTANCE_TOP &&
                topRest < bottomRest &&
                topRest < MIN_OFFSET_TOP
            ))
    ) {
        if (contentHeight > topRest - DISTANCE_TOP - POPOVER_ARROW_WIDTH / 2) {
            newMaxHeight = Math.max(topRest - DISTANCE_TOP - POPOVER_ARROW_WIDTH / 2, POPOVER_MIN_SCROLL_HEIGHT)
        }
        const { newPlacement, newPopoverPosition } = calculateVerticalPosition({
            placement: placement.replace('bottom', 'top') as WKPopoverPlacement,
            triggerRect,
            worldRect,
            contentRect,
            newMaxHeight,
        })
        return { newPlacement, newPopoverPosition, newMaxHeight }
    } else {
        if (contentHeight > bottomRest - DISTANCE_BOTTOM - POPOVER_ARROW_WIDTH / 2) {
            newMaxHeight = Math.max(bottomRest - DISTANCE_BOTTOM - POPOVER_ARROW_WIDTH / 2, POPOVER_MIN_SCROLL_HEIGHT)
        }
        const { newPlacement, newPopoverPosition } = calculateVerticalPosition({
            placement: placement.replace('top', 'bottom') as WKPopoverPlacement,
            triggerRect,
            worldRect,
            contentRect,
            newMaxHeight,
        })
        return { newPlacement, newPopoverPosition, newMaxHeight }
    }
}
