import classNames from 'classnames'
import { HTMLAttributes, useMemo } from 'react'
import { WKTextProps } from './type'
import { getFontColor, getFontSize, getFontWeight } from './util'
interface TitleProps extends HTMLAttributes<HTMLHeadingElement> {
    level: 1 | 2 | 3 | 4 | 5
    color?: WKTextProps['color']
    weight?: 'medium' | 'semibold'
}

const HTMLTag: React.FC<{ level: TitleProps['level'] } & HTMLAttributes<HTMLHeadingElement>> = (props) => {
    switch (props.level) {
        case 1:
            return <h1 {...props}></h1>
        case 2:
            return <h2 {...props}></h2>
        case 3:
            return <h3 {...props}></h3>
        case 4:
            return <h4 {...props}></h4>
        case 5:
            return <h5 {...props}></h5>
    }
}
export const Title: React.FC<TitleProps> = (props) => {
    const { weight = 'medium', className, color, level, ...rest } = props
    const fontWeight = getFontWeight(weight)
    const fontColor = getFontColor(color)
    const size = useMemo(() => {
        switch (level) {
            case 1:
                return 28
            case 2:
                return 18
            case 3:
                return 16
            case 4:
                return 14
            case 5:
                return 12
        }
    }, [level])

    const fontSize = getFontSize(size)

    return (
        <HTMLTag
            className={classNames('my-0 flex items-center', fontWeight, fontColor, fontSize, className)}
            level={level}
            {...rest}
        />
    )
}
