import { translation } from './empty-library-list.translation'

import { useCallback } from 'react'
import { Component24, IconBookBigOpen24, WKTextButton } from '../../../../../../../ui-lib/src'
import { environment } from '../../../../../environment'
import { useLibraryComponentService } from '../../../../../main/app-context'
import { useLibraryModalConfigContext } from '../../../../context/library-component-style/library-modal-config'
import classes from './empty-library-list.module.less'

export interface EmptyLibraryListProps {
    dataTestIds?: {
        moreButton?: string
        gridStateIcon?: string
        listStateIcon?: string
        libraryButton?: string
    }
}
export function EmptyLibraryList(props: EmptyLibraryListProps) {
    const {
        libraryModalRouterService: { toggleLibraryMainModal },
    } = useLibraryComponentService()
    const {
        mainModalConfig: { show },
    } = useLibraryModalConfigContext()

    const onClickJump = useCallback(() => {
        window.open(environment.helpDocsUrl.component)
    }, [])

    const onClickOpenLibrary = useCallback(() => {
        if (show) {
            return
        }
        toggleLibraryMainModal()
    }, [toggleLibraryMainModal, show])

    return (
        <div>
            <div className={classes.item}>
                <div className={classes.itemLeft}>
                    <Component24 />
                </div>
                <div>
                    <div className={classes.description}>{translation('ButtonInThe')}</div>
                    <WKTextButton
                        type="primary"
                        size={12}
                        className={classes.link}
                        onClick={onClickJump}
                        data-testid={props.dataTestIds?.moreButton}
                    >
                        {translation('LearnMore')}
                    </WKTextButton>
                </div>
            </div>
            <div className={classes.item}>
                <div className={classes.itemLeft}>
                    <IconBookBigOpen24 className={classes.icon} data-testid={props.dataTestIds?.gridStateIcon} />
                </div>
                <div>
                    <div className={classes.description}>{translation('UseLibrariesTo')}</div>
                    <WKTextButton
                        type="primary"
                        size={12}
                        className={classes.link}
                        onClick={onClickOpenLibrary}
                        data-testid={props.dataTestIds?.libraryButton}
                    >
                        {translation('ExploreLibraries...')}
                    </WKTextButton>
                </div>
            </div>
        </div>
    )
}
