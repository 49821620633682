import { translation } from './candidate-component-replace-panel.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { DraggablePopupV2, InputV2, ScrollContainer } from '../../../../../../../ui-lib/src'
import { sortArrayByKey } from '../../../../../../../util/src'
import { useViewState } from '../../../../../view-state-bridge'
import { CandidateComponentReplacePanelTestIds as ReplaceTestIds } from '../../../../../window'
import { useComponentInstanceCommand } from '../commands'
import { ReplaceInstanceLibraryList, ReplaceInstanceSearchResults } from '../types'
import { IconBack, IconFolder, IconForward } from './icons'
import style from './instance-panel.module.less'
import { buildFolderTree, FolderTree, ListItem, Thumbnail } from './replace-instance-panel'

function CandidateComponentReplacePanelFoldes({
    library,
    onSetStickyTitle,
    onClickThumbnail,
}: {
    library: ReplaceInstanceLibraryList[0]
    onSetStickyTitle: (content: ReactNode) => void
    onClickThumbnail: (node: Wukong.DocumentProto.IReplaceInstanceNodeData) => void
}) {
    const [defaultTree] = useState(() => buildFolderTree(library.tree))
    const [allFolders, setFolders] = useState<FolderTree[]>(defaultTree.flattenFolders)
    const [folderIndex, setFolderIndex] = useState<number>(defaultTree.selectedFolderIndex)

    const currentFolder = allFolders.at(folderIndex)
    const onUpperFolder = useCallback(() => {
        setFolderIndex(currentFolder?.parent ?? 0)
    }, [currentFolder?.parent])

    useEffect(() => {
        setFolders(buildFolderTree(library.tree).flattenFolders)
    }, [library])

    useEffect(() => {
        if (currentFolder?.name !== undefined && currentFolder?.parent >= 0) {
            onSetStickyTitle(
                <ListItem
                    head={<IconBack />}
                    body={currentFolder?.name}
                    testId={ReplaceTestIds.upper}
                    onClick={onUpperFolder}
                />
            )
        } else {
            onSetStickyTitle(null)
        }
    }, [currentFolder?.name, currentFolder?.parent, onSetStickyTitle, onUpperFolder])

    useEffect(() => {
        return () => {
            onSetStickyTitle(null)
        }
    }, [onSetStickyTitle])

    const sortedNodes = useMemo(() => {
        return sortArrayByKey(currentFolder?.nodes.slice() ?? [], 'name')
    }, [currentFolder])
    return (
        <div className={style.currentFolder} data-testid={ReplaceTestIds.currentFolder(currentFolder?.name ?? 'none')}>
            <div className={style.folders}>
                {currentFolder?.folderChildren.map((childFolderIndex) => {
                    const folder = allFolders.at(childFolderIndex)
                    return (
                        folder && (
                            <ListItem
                                key={folder.name}
                                head={<IconFolder />}
                                body={folder.name}
                                tail={<IconForward />}
                                onClick={() => setFolderIndex(childFolderIndex)}
                                testId={ReplaceTestIds.folder(folder.name)}
                            />
                        )
                    )
                })}
            </div>
            {currentFolder && sortedNodes.length > 0 && (
                <div className={style.thumbnailList}>
                    {sortedNodes.map(({ key, ...node }) => (
                        <Thumbnail
                            {...node}
                            docId={library.docId ?? ''}
                            key={node.nodeId || key}
                            onClick={() => onClickThumbnail(node as Wukong.DocumentProto.IReplaceInstanceNodeData)}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

export function CandidateComponentReplacePanel(props: {
    onClose: () => void
    onSelect: (node: Wukong.DocumentProto.IReplaceInstanceNodeData) => void
    position?: {
        left: number
        top: number
    }
    width?: number
}) {
    const commands = useComponentInstanceCommand()
    const candidateComponentReplaceState = useViewState('candidateComponentReplaceState')

    const [searchResults, setSearchResults] = useState<ReplaceInstanceSearchResults>([])
    const [isSearching, setIsSearching] = useState(false)

    const onSearchList = useCallback(
        (searchInput: string) => {
            setIsSearching(!!searchInput)
            if (searchInput) {
                setSearchResults(
                    commands.searchByKeyword(
                        searchInput,
                        Wukong.DocumentProto.ComponentPickerTrigger.COMPONENT_PICKER_TRIGGER_NONE,
                        true
                    )
                )
            }
        },
        [commands]
    )

    const onClose = useCallback(() => {
        props.onClose()
        onSearchList('')
    }, [props, onSearchList])

    const onClickThumbnail = useCallback(
        (node: Wukong.DocumentProto.IReplaceInstanceNodeData) => {
            props.onSelect(node)
            onClose()
        },
        [props, onClose]
    )

    const [stickyTitle, setStickyTitle] = useState<ReactNode>(null)
    if (!candidateComponentReplaceState) {
        return <></>
    }

    const selectedLibrary = candidateComponentReplaceState.allLibraryList.at(0)
    const isNoComponent = selectedLibrary?.tree.nodes.length === 0 && selectedLibrary?.tree.folderChildren.length === 0

    return (
        <DraggablePopupV2
            visible
            position={props.position}
            width={props.width ? props.width : 216}
            onCancel={onClose}
            header={
                <div data-testid={ReplaceTestIds.title} className={style.modalTitle}>
                    {selectedLibrary?.name ?? ''}
                </div>
            }
            bodyClassName="p-0"
            footer={null}
            maxScrollHeight={352}
            minScrollHeight={352}
            testId={ReplaceTestIds.popup}
            closeTestId={ReplaceTestIds.closeBtn}
            styleType="editor"
            closable={false}
        >
            {isNoComponent ? (
                <div className={style.emptyComponent}>
                    <div className={style.emptyComponent_text}>{translation('NoComponent')}</div>
                </div>
            ) : (
                <ScrollContainer
                    className={style.scrollContainer}
                    scrollViewProps={{
                        scrollbar: { autoHeight: true, autoHeightMin: 352, autoHeightMax: 352 },
                    }}
                    stickyHeader={
                        <>
                            <div className={style.searchInput}>
                                <InputV2.Search
                                    onSearch={onSearchList}
                                    onBlur={(e) => onSearchList(e.target.value.trim())}
                                    placeholder={translation('Search')}
                                    dataTestIds={{
                                        input: ReplaceTestIds.searchInput,
                                        clearIcon: 'icon-close-svg',
                                    }}
                                    autoFocus
                                />
                            </div>
                            {stickyTitle}
                        </>
                    }
                >
                    {isSearching ? (
                        <div className={style.searchResult} data-testid={ReplaceTestIds.searchResult}>
                            {searchResults.length > 0 ? (
                                <>
                                    <div className={style.searchTitle}>{translation('wuvOAM')}</div>
                                    <div className={style.thumbnailList}>
                                        {searchResults.map(({ key, ...node }) => (
                                            <Thumbnail
                                                {...node}
                                                docId={selectedLibrary?.docId ?? ''}
                                                key={node.nodeId || key}
                                                onClick={() =>
                                                    onClickThumbnail(
                                                        node as Wukong.DocumentProto.IReplaceInstanceNodeData
                                                    )
                                                }
                                            />
                                        ))}
                                    </div>
                                </>
                            ) : (
                                <div className={style.noResult} data-testid={ReplaceTestIds.noResult}>
                                    {translation('lOvDrz')}
                                </div>
                            )}
                        </div>
                    ) : (
                        selectedLibrary && (
                            <CandidateComponentReplacePanelFoldes
                                library={selectedLibrary as unknown as ReplaceInstanceLibraryList[0]}
                                key={selectedLibrary?.docId}
                                onSetStickyTitle={setStickyTitle}
                                onClickThumbnail={onClickThumbnail}
                            />
                        )
                    )}
                </ScrollContainer>
            )}
        </DraggablePopupV2>
    )
}
