import { uniq } from 'lodash-es'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDeepCompareEffect } from 'react-use'
import { Scrollbar, UserBriefAndErrorVO, WKDialog, wkDialogConfirm, WKDivider, WKToast } from '../../../../ui-lib/src'
import { isEnglishLanguage, useElementResize } from '../../../../util/src'
import {
    ResourceType,
    MemberPermission,
    RoleStatus,
    ContractSpecification,
    OrganizationVO,
    UserID,
    SeatType,
} from '../../kernel/interface/type'
import { BusinessStatusCode } from '../../kernel/interface/request-error-code'
import { CheckDocumentCopyAccess, UpdateDocumentAccess, UpdateFolderAccess } from '../../kernel/request/accesses'
import { RequestResponseErrorHandler } from '../../kernel/request/error-handler'
import { GetOrganizations } from '../../kernel/request/organizations'
import { GetUsers } from '../../kernel/request/user'
import { DeleteMember, UpdateMemberRole, UpdateOwner } from '../../kernel/request/user-roles'
import { PermissionOptionType, SpaceLogs } from '../../kernel/service/space-frog-service/logs'
import { getLERoles } from '../../space/util/access/util'
import { getPayUpgradeDialogFrom } from '../../utils/payment'
import {
    isActiveOrgPlan,
    isActiveProPlan,
    isLockedOrgPlan,
    isLockedProPlan,
    isStarterPlan,
} from '../../utils/plan-status-checker'
import { batchInviteUserRequest, reinviteUserRequest } from '../batch-invite-user-request/batch-invite-user-request'
import { InviteMemberInputV2 } from '../invite-member-input'
import { PayUpgradePrivateProject, PayUpgradeSharePrototypeSceneDiadlog } from '../payment'
import { PayPreCheck } from '../payment/pay-pre-check'
import { PayUpgradeDialog } from '../payment/pay-upgrade-dialog/pay-upgrade-dialog'
import {
    PayUpgradeDialogFrom,
    PayUpgradeDialogType,
} from '../payment/pay-upgrade-dialog/pay-upgrade-dialog-struct/type'
import { PrivilegesSelect } from '../privileges-select/privileges-select'
import { UpgradePopupType, WKFrogTask } from '../wk-frog-task'
import { getInviteMemberMenuItems } from './access-dropdown/util'
import { DevModeTips } from './dev-mode-tips'
import { translation } from './index.translation'
import { PublicAccess } from './public-access'
import { ShareDraftTipBanner } from './share-draft-tip-banner'
import {
    AccessAction,
    AccessDropdownType,
    AccessMenuLabel,
    ClickAccessMenuParams,
    CurrentUser,
    ShareDialogProps,
    ShareDialogType,
    ShareDialogType2Label,
    SpecialShareDialogModeType,
} from './types'
import { usePrototypePresentShareUtil } from './use-prototype-present-share'
import { UserList } from './user-list'
import { RoleStatus2PermissionOption, RoleStatusWeight } from '../../kernel/interface/user-roles'

const ShareDialogTitle = {
    [ShareDialogType.Folder]: translation('ShareProject'),
    [ShareDialogType.Doc]: translation('ShareFile'),
}

const defaultCurrentUser = {
    role: RoleStatus.Viewer,
}

export const ShareDialog = ({
    visible,
    type,
    onCancel,
    isDraft,
    members,
    access,
    domainList,
    orgId,
    docData,
    resourceName,
    dimission,
    currentUser = defaultCurrentUser as CurrentUser,
    refreshMembersList,
    onLeave,
    specialModeType,
    showDevModeShareTips,
    hideDevModeShareTipsCallback,
    teamPlanOfSharingDoc,
    showShareDraftTipBanner,
    initUpgradePlan,
    moveDraftIntoTeam,
    shareDocLimitAction,
    draftFolderId,
}: ShareDialogProps) => {
    const label = ShareDialogType2Label[type]
    const folderId = type === ShareDialogType.Folder && access.id
    const docId = type === ShareDialogType.Doc && access.id
    const [dialogVisible, setDialogVisible] = useState(false)
    const [hideShareDialog, setHideShareDialog] = useState(false)

    const [publicLinkSharing, setPublicLinkSharing] = useState(false)
    const inviteRef = useRef<HTMLDivElement>(null)
    const accessRef = useRef<HTMLDivElement>(null)
    const [element, setElement] = useState<HTMLDivElement | null>(null)
    const { resizeHeight } = useElementResize({
        element,
        maxContainerHeight: 800,
        otherHeight: type === ShareDialogType.Doc ? 99 : 71,
        otherElements: [inviteRef.current, accessRef.current].filter((v) => !!v) as HTMLDivElement[],
    })

    const { prototypePresentInviteUsers } = usePrototypePresentShareUtil(docId ? docId : '')

    const onRealNameVerificationStart = useCallback(() => {
        setHideShareDialog(true)
    }, [])

    const onRealNameVerificationEnd = useCallback(() => {
        setHideShareDialog(false)
    }, [])

    useEffect(() => {
        if (visible) {
            type === ShareDialogType.Doc
                ? SpaceLogs.FileShareAndPermission.shareFile()
                : SpaceLogs.ProjectShareAndPermission.shareproject()

            new GetOrganizations()
                .start()
                .then((orgs: OrganizationVO[]) => {
                    const target = orgs.find((org) => org.id == orgId)
                    // 默认为 true，针对企业外部用户访问企业文档，获取不到组件信息时，默认开启公有链接，走文档自身配置
                    setPublicLinkSharing(target?.publicLinkSharing ?? true)
                })
                .finally(() => {
                    setDialogVisible(true)
                })
        } else {
            setDialogVisible(false)
        }
    }, [visible, type, orgId])

    const modifyMemberpermission = (curr: RoleStatus, prev: RoleStatus) => {
        if (type === ShareDialogType.Doc) {
            SpaceLogs.FileShareAndPermission.modifyFileMemberpermission({
                permission_option: RoleStatus2PermissionOption[curr],
                previous_permissions_option: RoleStatus2PermissionOption[prev],
            })
        } else {
            SpaceLogs.ProjectShareAndPermission.modifyProjectMemberpermission({
                permission_option: RoleStatus2PermissionOption[curr],
                previous_permissions_option: RoleStatus2PermissionOption[prev],
            })
        }
    }

    const inviteUsers = async (users: UserBriefAndErrorVO[], role: RoleStatus) => {
        const emails = users.map((user) => user.email)
        if (folderId) {
            await batchInviteUserRequest(ResourceType.Folder, folderId, emails, role)
        } else if (docId) {
            await batchInviteUserRequest(ResourceType.Document, docId, emails, role)
        }
    }

    const reinviteUser = async (userId: UserID) => {
        if (folderId) {
            await reinviteUserRequest(ResourceType.Folder, folderId, userId)
        } else if (docId) {
            await reinviteUserRequest(ResourceType.Document, docId, userId)
        }
    }

    const onClickAccessMenu = async (params: ClickAccessMenuParams) => {
        const isFreeTeam = teamPlanOfSharingDoc && isStarterPlan(teamPlanOfSharingDoc)

        const { member, key, type: dropdownType } = params
        if (key === member.role) {
            return
        } else if (type === ShareDialogType.Folder && dropdownType === AccessDropdownType.Parent && isFreeTeam) {
            return PayUpgradePrivateProject({
                teamId: teamPlanOfSharingDoc?.resourceId,
                initUpgradePlan,
                actions: shareDocLimitAction,
            })
        } else if (
            type === ShareDialogType.Doc &&
            dropdownType === AccessDropdownType.Member &&
            key === RoleStatus.Editor &&
            getLERoles(RoleStatus.Viewer).includes(member.role) &&
            !(await PayPreCheck.checkShareDoc({
                doc: docData!,
                invitedUsers: [member.userBrief],
                userId: currentUser.userId,
                actions: shareDocLimitAction,
                draftFolderId,
            }))
        ) {
            return
        }
        switch (key) {
            case MemberPermission.Inherit:
            case MemberPermission.Edit:
            case MemberPermission.View:
            case MemberPermission.None:
                if (folderId) {
                    SpaceLogs.ProjectShareAndPermission.setTeamInheritedpermission({
                        permission_option: AccessMenuLabel[key] as PermissionOptionType,
                        previous_permissions_option: AccessMenuLabel[member.role] as PermissionOptionType,
                    })
                    await new UpdateFolderAccess(folderId, {
                        memberPermissionEnum: key,
                    }).start()
                } else if (docId) {
                    SpaceLogs.FileShareAndPermission.setProjectInheritedpermission({
                        permission_option: AccessMenuLabel[key] as PermissionOptionType,
                        previous_permissions_option: AccessMenuLabel[member.role] as PermissionOptionType,
                    })
                    await new UpdateDocumentAccess(docId, {
                        memberPermissionEnum: key,
                    }).start()
                }
                break
            case RoleStatus.Owner:
                if (member.role !== RoleStatus.Owner) {
                    setDialogVisible(false)
                    let willUpgradeMemberSeatToDesign = false
                    const inActiveResource =
                        teamPlanOfSharingDoc &&
                        (isActiveProPlan(teamPlanOfSharingDoc) || isActiveOrgPlan(teamPlanOfSharingDoc))

                    if (
                        inActiveResource &&
                        type === ShareDialogType.Doc &&
                        !docData?.draft &&
                        teamPlanOfSharingDoc &&
                        !getLERoles(RoleStatus.Editor).includes(teamPlanOfSharingDoc.userRoleInResource) &&
                        member.seatType !== SeatType.designer
                    ) {
                        willUpgradeMemberSeatToDesign = true
                    }
                    wkDialogConfirm.show({
                        title: `${translation('ggHxDU', {
                            label,
                            resourceName,
                            nickname: member.userBrief.nickname,
                        })}`,
                        content: willUpgradeMemberSeatToDesign
                            ? translation('AdminApplyDesignSeatForOther')
                            : translation('aTmPyR'),
                        okText: willUpgradeMemberSeatToDesign ? undefined : translation('TransferOwner'),
                        onOk: async () => {
                            if (folderId) {
                                await new UpdateOwner(member.userId, ResourceType.Folder, folderId).start()
                            } else if (docId) {
                                await new UpdateOwner(member.userId, ResourceType.Document, docId).start()
                            }
                            modifyMemberpermission(RoleStatus.Owner, member.role)
                            refreshMembersList?.()
                            setDialogVisible(visible)
                        },
                        onClose: () => {
                            setDialogVisible(visible)
                        },
                    })
                }
                break
            case RoleStatus.Editor:
            case RoleStatus.Viewer:
            case RoleStatus.ViewPrototype:
                modifyMemberpermission(key, member.role)
                await new UpdateMemberRole(member.id, key).start()
                break
            case AccessAction.ReInvite:
                await reinviteUser(member.userId)
                break
            case AccessAction.DeleteInvite:
                setDialogVisible(false)
                wkDialogConfirm.show({
                    title: `${translation('MKWXEt', { label, resourceName, nickname: member.userBrief.nickname })}`,
                    content: `${translation('OlAXkP', {
                        file: type === ShareDialogType.Doc ? translation('JhjXFc') : translation('uXbutb'),
                    })}`,
                    okButtonProps: { danger: true },
                    okText: translation('XmMaTE'),
                    onOk: async () => {
                        await new DeleteMember(member.id).start()
                        modifyMemberpermission(RoleStatus.None, member.role)
                        refreshMembersList?.()
                        setDialogVisible(visible)
                    },
                    onClose: () => {
                        setDialogVisible(visible)
                    },
                })
                break
            case AccessAction.Remove:
                setDialogVisible(false)
                wkDialogConfirm.show({
                    title: `${translation('MKWXEt', { label, resourceName, nickname: member.userBrief.nickname })}`,
                    content: `${translation('OlAXkP', {
                        file: type === ShareDialogType.Doc ? translation('JhjXFc') : translation('uXbutb'),
                    })}`,
                    okText: translation('XmMaTE'),
                    okButtonProps: { danger: true },
                    onOk: async () => {
                        await new DeleteMember(member.id).start()
                        modifyMemberpermission(RoleStatus.None, member.role)
                        refreshMembersList?.()
                        setDialogVisible(visible)
                    },
                    onClose: () => {
                        setDialogVisible(visible)
                    },
                })
                break
            case AccessAction.Leave:
                setDialogVisible(false)
                if (member.role === RoleStatus.Owner) {
                    wkDialogConfirm.show({
                        title: `${translation('DliUGl')}${label}`,
                        content: `${translation('CzCJjB', { label })}`,
                        okButtonProps: { type: 'primary' },
                        cancelButtonProps: { style: { display: 'none' } },
                        okText: translation('OK'),
                        onOk: () => {
                            setDialogVisible(visible)
                        },
                        onClose: () => {
                            setDialogVisible(visible)
                        },
                    })
                } else {
                    wkDialogConfirm.show({
                        title: `${translation('CPCNWh', { label, resourceName })}`,
                        content: isEnglishLanguage()
                            ? `After leaving, you may lose access to ${
                                  type === ShareDialogType.Doc ? 'this file.' : 'this project and all files within it.'
                              } ${translation('aTmPyR')}`
                            : `离开后，你可能无法访问${
                                  type === ShareDialogType.Doc ? translation('JhjXFc') : translation('uXbutb')
                              }，${translation('aTmPyR')}`,
                        okText: `${translation('Leave')}${label}`,
                        okButtonProps: { danger: true },
                        onOk: async () => {
                            await new DeleteMember(member.id).start()
                            if (type === ShareDialogType.Doc) {
                                SpaceLogs.FileShareAndPermission.leaveFile()
                            } else {
                                SpaceLogs.ProjectShareAndPermission.leaveProject()
                            }
                            try {
                                refreshMembersList && (await refreshMembersList())
                                onLeave?.()
                            } catch (e) {
                                onCancel()
                            }
                            setDialogVisible(visible)
                        },
                        onClose: () => {
                            setDialogVisible(visible)
                        },
                    })
                }
                break
            default:
                break
        }
        refreshMembersList?.()
    }

    const onClickInvite = async (users: UserBriefAndErrorVO[]) => {
        setKey((r) => r + 1)
        if (
            type === ShareDialogType.Doc &&
            role === RoleStatus.Editor &&
            !(await PayPreCheck.checkShareDoc({
                doc: docData!,
                invitedUsers: users,
                userId: currentUser.userId,
                actions: shareDocLimitAction,
                refreshMembersList,
                draftFolderId,
            }))
        ) {
            return
        }
        const uniqUsers = uniq(users)
        if (type === ShareDialogType.Doc) {
            SpaceLogs.FileShareAndPermission.inviteFileMembers({
                people_count: uniqUsers.length,
                permission_option: RoleStatus2PermissionOption[role],
            })
        } else {
            SpaceLogs.ProjectShareAndPermission.inviteProjectMember({
                people_count: uniqUsers.length,
                permission_option: RoleStatus2PermissionOption[role],
            })
        }
        try {
            if (specialModeType === SpecialShareDialogModeType.PrototypePresent) {
                await prototypePresentInviteUsers(uniqUsers, role, folderId, docId)
            } else {
                await inviteUsers(uniqUsers, role)
            }
        } catch (e) {
            const msg = RequestResponseErrorHandler(e)
            if (msg.businessStatus === BusinessStatusCode.ProhibitCopy) {
                WKToast.show(translation('ProhibitInvite'))
                setProhibitInvite(true)
            }
            return
        }
        refreshMembersList?.()
        WKToast.show(translation('InviteSent'))
    }
    let defaultRole: RoleStatus
    if (RoleStatusWeight[currentUser.role!] > RoleStatusWeight[RoleStatus.Viewer]) {
        defaultRole = RoleStatus.Viewer
    } else {
        defaultRole = currentUser.role!
    }
    const [role, setRole] = useState<RoleStatus>(defaultRole)
    const accessMenu = getInviteMemberMenuItems(currentUser.role, !!docData?.draft || dimission, type)
    useDeepCompareEffect(() => {
        if (accessMenu) {
            if (
                specialModeType === SpecialShareDialogModeType.PrototypePresent &&
                accessMenu.find((o) => o == RoleStatus.ViewPrototype)
            ) {
                // 原型预览下默认为「可查看原型」权限，草稿文件没有「可查看权限」
                setRole(RoleStatus.ViewPrototype)
                return
            }
            setRole(RoleStatus.Viewer)
        }
    }, [accessMenu])
    const isTempRole = currentUser.role == RoleStatus.None && currentUser.temporaryRole !== RoleStatus.None
    const handlerRoleChange = (role_: RoleStatus) => {
        if (role_ == RoleStatus.ViewPrototype && type == ShareDialogType.Doc) {
            const hasStarterPlan = teamPlanOfSharingDoc && isStarterPlan(teamPlanOfSharingDoc)

            if (hasStarterPlan) {
                setDialogVisible(false)
                PayUpgradeSharePrototypeSceneDiadlog(
                    getPayUpgradeDialogFrom(),
                    teamPlanOfSharingDoc?.resourceId,
                    () => {
                        initUpgradePlan?.(ContractSpecification.Profession, teamPlanOfSharingDoc?.resourceId)
                    },
                    {
                        onCancel: () => setDialogVisible(true),
                        onOk: () => setDialogVisible(true),
                    }
                )
                return
            }
        }
        setRole(role_)
    }
    const isFreeOrExpiredTeamOrOrgDoc = useMemo(() => {
        return (
            !docData?.draft &&
            teamPlanOfSharingDoc &&
            (isStarterPlan(teamPlanOfSharingDoc) ||
                isLockedOrgPlan(teamPlanOfSharingDoc) ||
                isLockedProPlan(teamPlanOfSharingDoc))
        )
    }, [docData?.draft, teamPlanOfSharingDoc])

    const canClickPasswordRequired = () => {
        const hasStarterPlan = teamPlanOfSharingDoc && isStarterPlan(teamPlanOfSharingDoc)

        if (hasStarterPlan) {
            WKFrogTask.payment.UpgradePopupType(UpgradePopupType.PasswordProtection)
            PayUpgradeDialog.show({
                type: PayUpgradeDialogType.Professional,
                title: translation('WantPasswordProtection'),
                subtitle: translation('UpgradeForPasswordProtection'),
                starterProps: {
                    privileges: [
                        { name: translation('NoPasswordProtection'), enable: false },
                        { name: translation('ThreeFilesOneProject'), enable: true },
                        { name: translation('ThreePagesPerFile'), enable: true },
                    ],
                },
                professionalProps: {
                    privileges: [
                        { name: translation('PasswordProtection'), enable: true },
                        { name: translation('UnlimitedFilesAndProjects'), enable: true },
                        { name: translation('UnlimitedPages'), enable: true },
                    ],
                    submitProps:
                        getPayUpgradeDialogFrom() === PayUpgradeDialogFrom.Space
                            ? {
                                  from: PayUpgradeDialogFrom.Space,
                                  teamId: docData?.teamId,
                                  submit: () => {
                                      onCancel()
                                      initUpgradePlan?.(ContractSpecification.Profession, docData?.teamId)
                                  },
                              }
                            : {
                                  from: PayUpgradeDialogFrom.Editor,
                                  teamId: docData?.teamId,
                              },
                },
            })
            return false
        }
        return true
    }

    const [prohibitInvite, setProhibitInvite] = useState(false)

    useEffect(() => {
        if (dialogVisible && docData?.id) {
            new CheckDocumentCopyAccess(docData?.id ?? '')
                .start()
                .then(() => {
                    setProhibitInvite(false)
                })
                .catch((e) => {
                    const msg = RequestResponseErrorHandler(e)
                    if (msg.status === 403) {
                        setProhibitInvite(true)
                    }
                })
        }
    }, [dialogVisible, docData?.id])
    const [key, setKey] = useState(Date.now()) // 通过 key 来重置输入框
    const requestFn = useCallback((query: string) => new GetUsers(query, orgId).start(), [orgId])
    return (
        <WKDialog
            visible={dialogVisible}
            title={ShareDialogTitle[type]}
            footer={null}
            testId="share-dialog"
            rootTestId="root-share-dialog"
            onCancel={onCancel}
            width={464}
            bodyStyle={{ padding: 0 }}
            maskStyle={hideShareDialog ? { opacity: 0, pointerEvents: 'none' } : undefined}
        >
            {!isTempRole && (
                <>
                    {showShareDraftTipBanner && <ShareDraftTipBanner moveDraftIntoTeam={moveDraftIntoTeam} />}
                    <div className="pt-4">
                        {specialModeType === SpecialShareDialogModeType.DevMode && showDevModeShareTips && (
                            <DevModeTips hide={hideDevModeShareTipsCallback} />
                        )}
                        {type === ShareDialogType.Doc && (
                            <p className={'wk-text-12 wk-font-medium py-1 mb-0 px-6'}>{translation('SendInvite')}</p>
                        )}
                        <div ref={inviteRef} className={'py-2 px-6'}>
                            <InviteMemberInputV2
                                requestFn={requestFn}
                                key={key}
                                onOk={onClickInvite}
                                disabled={prohibitInvite}
                                placeholder={
                                    prohibitInvite ? translation('ProhibitInvite') : translation('Email,CommaSeparated')
                                }
                                tool={
                                    prohibitInvite ? (
                                        <></>
                                    ) : (
                                        <PrivilegesSelect
                                            accessMenu={accessMenu}
                                            value={role}
                                            onChange={handlerRoleChange}
                                        />
                                    )
                                }
                            />
                        </div>
                        <Scrollbar autoHeight autoHeightMax={resizeHeight}>
                            <div className="px-6" ref={(el) => setElement(el)}>
                                <UserList
                                    type={type}
                                    dimission={dimission}
                                    access={access}
                                    members={members}
                                    currentUser={currentUser}
                                    onClickAccessMenu={onClickAccessMenu}
                                    isDraft={isDraft}
                                    hidePrototype={!!isFreeOrExpiredTeamOrOrgDoc}
                                    teamPlanOfSharingDoc={teamPlanOfSharingDoc}
                                />
                            </div>
                        </Scrollbar>
                        {type === ShareDialogType.Doc && <WKDivider />}
                    </div>
                </>
            )}
            {type === ShareDialogType.Doc && (
                <div ref={accessRef} className="px-6">
                    <PublicAccess
                        domainList={domainList}
                        shareDialogType={type}
                        dimission={dimission}
                        access={access}
                        currentUser={currentUser}
                        docData={docData}
                        refreshMembersList={refreshMembersList}
                        specialModeType={specialModeType}
                        teamPlanOfSharingDoc={teamPlanOfSharingDoc}
                        publicLinkSharing={publicLinkSharing}
                        canClickPasswordRequired={canClickPasswordRequired}
                        shareDocLimitAction={shareDocLimitAction}
                        draftFolderId={draftFolderId}
                        onRealNameVerificationStart={onRealNameVerificationStart}
                        onRealNameVerificationEnd={onRealNameVerificationEnd}
                    />
                </div>
            )}
        </WKDialog>
    )
}
