import { Wukong } from '@wukong/bridge-proto'
import { useMemo } from 'react'
import { useDevModeHoverNodeLabelService } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge/use-view-state'
import { getSVGIconByNodeIconType } from '../left-side-panel/dev-mode-layer-panel/render-item-icon'
import style from './label.module.less'

export function HoverNodeLabelLayerWrapper() {
    const viewport = useViewState('visibleViewport')!
    return (
        <div
            className={style.floatOverlayWrapper}
            style={{
                width: viewport.width,
                height: viewport.height,
                left: viewport.paddingLeft,
                top: viewport.paddingTop,
            }}
        >
            <HoverNodeLabelLayer />
        </div>
    )
}
export function HoverNodeLabelLayer() {
    const devModeHoverNodeLabelService = useDevModeHoverNodeLabelService()

    const viewState = useViewState('devModeHoverNodeLabelViewState')

    const iconSvg = useMemo(() => {
        return getSVGIconByNodeIconType((viewState?.nodeIcon ?? {}) as Wukong.DocumentProto.NodeIconData)
    }, [viewState])

    if (!viewState) {
        return null
    }

    return (
        <div
            data-testid="dev-mode-hover-node-label-layer"
            className={style.floatOverlay}
            ref={(overlayEl: HTMLElement | null) => {
                devModeHoverNodeLabelService.setOverlayElement(overlayEl)
            }}
        >
            {viewState.show && (
                <>
                    <div
                        className={`${style.layerNameLabel} ${
                            viewState.relatedToComponentInstance ? style.componentBackground : style.normalBackground
                        }
                        ${viewState.name ? style.layerNameWithLetter : style.layoutNameWithoutLetter}
                        `}
                        data-testid="dev-mode-hover-node-name"
                        ref={(overlayEl: HTMLElement | null) => {
                            devModeHoverNodeLabelService.setHoverNodeLabelNameElement(
                                overlayEl,
                                viewState.hoveredNodeId
                            )
                        }}
                    >
                        <div className={style.layerIcon}>{iconSvg} </div>
                        {viewState?.name && <div className={style.layerName}> {viewState?.name} </div>}
                    </div>
                    {viewState.isHoverSelectedNode && (
                        <div
                            className={style.layerSizeLabelWrapper}
                            ref={(overlayEl: HTMLElement | null) => {
                                devModeHoverNodeLabelService.setHoverNodeLabelSizeElement(overlayEl)
                            }}
                            data-testid="dev-mode-hover-node-size"
                        >
                            <div
                                className={`${style.layerSizeLabel} ${
                                    viewState.relatedToComponentInstance
                                        ? style.componentBackground
                                        : style.normalBackground
                                }`}
                            >
                                <div
                                    className={`${style['item-title']} ${
                                        viewState.relatedToComponentInstance && style.itemTitleComponentColor
                                    }`}
                                >
                                    W
                                </div>
                                <div className={style['item-prop']}>{viewState.widthStackMode}</div>
                                <div className={style['item-value']} data-testid="dev-mode-hover-node-width">
                                    {viewState.width}
                                </div>

                                <div
                                    className={`${style['item-title']} ${
                                        viewState.relatedToComponentInstance && style.itemTitleComponentColor
                                    }`}
                                >
                                    H
                                </div>
                                <div className={style['item-prop']}>{viewState.heightStackMode}</div>
                                <div className={style['item-value']} data-testid="dev-mode-hover-node-height">
                                    {viewState.height}
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}
