import { translation } from './main-menu.translation'
/* eslint-disable no-restricted-imports */
import { UpdateMainMenuVisibleCommand } from '@wukong/bridge-proto'
import { useEffect, useState } from 'react'
import { Tooltip, WKMenuConfig } from '../../../../../../ui-lib/src'
import { useCommand } from '../../../context/document-context'
import { Switch } from '../../atom/switch/switch'
import style from './main-menu.module.less'
import { MenuV2 } from './menu-v2/menu-v2'

export function MainMenu(props: { config: WKMenuConfig }) {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const command = useCommand()

    useEffect(() => {
        command.DEPRECATED_invokeBridge(UpdateMainMenuVisibleCommand, { value: isOpen })
    }, [command, isOpen])

    return (
        <Tooltip title={translation('MainMenu')}>
            <Switch className={style.dividerLine}>
                <MenuV2 config={props.config} onVisible={setIsOpen} />
            </Switch>
        </Tooltip>
    )
}
