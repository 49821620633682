import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import { UpdateSelectedVectorNetworkRegionCommand, Wukong } from '@wukong/bridge-proto'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getMotiffName } from '../../../../../../util/src'
import { useCommand } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { ReactComponent as FillRuleEditorEvenOddLarge } from '../../../assets/plugins/fill-rule-editor/fill-rule-editor-even-odd-large.svg'
import { ReactComponent as FillRuleEditorEvenOddSmall } from '../../../assets/plugins/fill-rule-editor/fill-rule-editor-even-odd-small.svg'
import { ReactComponent as FillRuleEditorNonZeroLarge } from '../../../assets/plugins/fill-rule-editor/fill-rule-editor-non-zero-large.svg'
import { ReactComponent as FillRuleEditorNonZeroSmall } from '../../../assets/plugins/fill-rule-editor/fill-rule-editor-non-zero-small.svg'
import { ReactComponent as FillRuleEditorSwitch } from '../../../assets/plugins/fill-rule-editor/fill-rule-editor-switch.svg'
import { ReactComponent as FillRuleEditorIcon } from '../../../assets/plugins/fill-rule-editor/fill-rule-editor.svg'
import { getLdap, logs } from '../log'
import { Manifest, PluginExported, PluginModalProps } from '../type'
import style from './index.module.less'
import { EvenOddDiagonalPattern, NonZeroDiagonalPattern } from './patterns'
import {
    createTransforms,
    cubic1D,
    cubicDerivative1D,
    drawSegment,
    drawVertex,
    hitTestLoops,
    isPointInsideRegion,
    outlineLoop,
    segmentIterator,
} from './utils'

type HoverState = { type: string; regionIndex: number; loopIndex: number } | null

const canvasWidth = 368
const canvasHeight = 368
const canvasInset = 3
const nonzeroColor = '#67ADFF4D'
const evenoddColor = '#FFBD304D'
const defaultStrokeColor = '#828A99'
const hoverStrokeColor = '#454959'

export const FillRuleEditorTestIds = {
    NoSelection: 'fill-rule-editor-preview',
    Editing: 'fill-rule-editor-editing',
}

export function FillRuleEditorPlugin(_: PluginModalProps) {
    // 统计曝光次数
    useEffect(() => {
        // trace
        {
            logs.Plugins.fillruleeditor({ ldap: getLdap() })
        }
    }, [])

    const command = useCommand()
    const viewState = useViewState('pluginFillRuleEditorState')
    const [inEditing, setInEditing] = useState(false)
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const [hoverState, setHoverState] = useState<HoverState>()
    const evenOddPattern = EvenOddDiagonalPattern()
    const nonZeroPattern = NonZeroDiagonalPattern()

    useEffect(() => {
        // trace
        {
            if (inEditing) {
                logs.Plugins.fillruleeditorStatusTwo({ ldap: getLdap() })
            } else {
                logs.Plugins.fillruleeditorStatusOne({ ldap: getLdap() })
            }
        }
    }, [inEditing])

    const draw = useCallback(() => {
        if (!canvasRef.current) {
            return
        }
        const ctx = canvasRef.current.getContext('2d')
        if (!ctx) {
            return
        }

        const ratio = window.devicePixelRatio
        canvasRef.current.style.width = canvasWidth + 'px'
        canvasRef.current.style.height = canvasHeight + 'px'
        canvasRef.current.width = canvasWidth * ratio
        canvasRef.current.height = canvasHeight * ratio
        ctx.scale(ratio, ratio)

        const width = viewState?.width!
        const height = viewState?.height!
        const vn = viewState?.vectorNetwork!
        const { tx, ty } = createTransforms(width, height, canvasWidth, canvasHeight, canvasInset)

        // Regions
        let regionIndex = 0
        for (const { windingRule, loops } of vn.regions!) {
            const nonzero = windingRule === Wukong.DocumentProto.WindingRule.WINDING_RULE_NONZERO
            const isHovered = hoverState && hoverState.type === 'region' && hoverState.regionIndex === regionIndex

            ctx.beginPath()
            for (const loop of loops!) {
                const segmentIndices = loop.segmentIndices!
                outlineLoop(tx, ty, vn, segmentIndices, ctx)
            }
            if (isHovered) {
                ctx.fillStyle = nonzero ? nonzeroColor : evenoddColor
                ctx.fill(nonzero ? 'nonzero' : 'evenodd')
            } else {
                ctx.save()
                ctx.clip(nonzero ? 'nonzero' : 'evenodd')
                ctx.drawImage(nonzero ? nonZeroPattern : evenOddPattern, 0, 0)
                ctx.restore()
            }
            regionIndex++
        }
        // Vertices
        for (const { x, y } of vn.vertices!) {
            drawVertex(tx(x), ty(y), defaultStrokeColor, ctx)
        }
        // Segments
        for (const { start: s, end: e, tangentStart: ts, tangentEnd: te } of vn.segments!) {
            const { x: sx, y: sy } = vn.vertices[s]!
            const { x: ex, y: ey } = vn.vertices[e]!
            const cs = ts ? ts! : { x: 0, y: 0 }
            const ce = te ? te! : { x: 0, y: 0 }
            drawSegment(
                tx(sx),
                ty(sy),
                tx(sx + cs.x),
                ty(sy + cs.y),
                tx(ex + ce.x),
                ty(ey + ce.y),
                tx(ex),
                ty(ey),
                defaultStrokeColor,
                ctx
            )
        }
        // Loop not hovered
        regionIndex = 0
        for (const { loops } of vn.regions!) {
            let loopIndex = 0

            for (const loop of loops) {
                const isHovered =
                    hoverState &&
                    hoverState.type === 'loop' &&
                    hoverState.regionIndex === regionIndex &&
                    hoverState.loopIndex === loopIndex
                loopIndex++
                if (isHovered) {
                    continue
                }
                ctx.fillStyle = defaultStrokeColor

                for (const { s, cs, ce, e } of segmentIterator(vn, loop.segmentIndices)) {
                    const t = 0.5
                    const x = tx(cubic1D(s.x!, cs.x, ce.x, e.x!, t))
                    const y = ty(cubic1D(s.y!, cs.y, ce.y, e.y!, t))
                    const dx = cubicDerivative1D(s.x!, cs.x, ce.x, e.x!, t)
                    const dy = cubicDerivative1D(s.y!, cs.y, ce.y, e.y!, t)
                    const len = Math.sqrt(dx * dx + dy * dy)
                    ctx.beginPath()
                    ctx.moveTo(x + (dx * 4) / len, y + (dy * 4) / len)
                    ctx.lineTo(x - ((dx + dy) * 3) / len, y - ((dy - dx) * 3) / len)
                    ctx.lineTo(x - ((dx - dy) * 3) / len, y - ((dy + dx) * 3) / len)
                    ctx.fill()
                }
            }
            regionIndex++
        }
        // Loop hovered
        regionIndex = 0
        for (const { loops } of vn.regions!) {
            let loopIndex = 0

            for (const loop of loops) {
                const isHovered =
                    hoverState &&
                    hoverState.type === 'loop' &&
                    hoverState.regionIndex === regionIndex &&
                    hoverState.loopIndex === loopIndex
                loopIndex++
                if (!isHovered) {
                    continue
                }
                ctx.fillStyle = hoverStrokeColor

                for (const { s, cs, ce, e } of segmentIterator(vn, loop.segmentIndices)) {
                    const t = 0.5
                    const x = tx(cubic1D(s.x!, cs.x, ce.x, e.x!, t))
                    const y = ty(cubic1D(s.y!, cs.y, ce.y, e.y!, t))
                    const dx = cubicDerivative1D(s.x!, cs.x, ce.x, e.x!, t)
                    const dy = cubicDerivative1D(s.y!, cs.y, ce.y, e.y!, t)
                    const len = Math.sqrt(dx * dx + dy * dy)
                    ctx.beginPath()
                    ctx.moveTo(x + (dx * 4) / len, y + (dy * 4) / len)
                    ctx.lineTo(x - ((dx + dy) * 3) / len, y - ((dy - dx) * 3) / len)
                    ctx.lineTo(x - ((dx - dy) * 3) / len, y - ((dy + dx) * 3) / len)
                    ctx.fill()

                    ctx.lineWidth = 2
                    drawSegment(
                        tx(s.x!),
                        ty(s.y!),
                        tx(cs.x),
                        ty(cs.y),
                        tx(ce.x),
                        ty(ce.y),
                        tx(e.x!),
                        ty(e.y!),
                        hoverStrokeColor,
                        ctx
                    )
                    ctx.lineWidth = 1
                    drawVertex(tx(s.x!), ty(s.y!), hoverStrokeColor, ctx)
                    drawVertex(tx(e.x!), ty(e.y!), hoverStrokeColor, ctx)
                }
            }
            regionIndex++
        }
    }, [viewState, hoverState, evenOddPattern, nonZeroPattern])

    const hitTest = (event: React.MouseEvent) => {
        if (!canvasRef.current) {
            return
        }

        const rect = canvasRef.current.getBoundingClientRect()
        const point = { x: event.clientX - rect.left, y: event.clientY - rect.top }
        const width = viewState?.width!
        const height = viewState?.height!
        const vn = viewState?.vectorNetwork!
        const { tx, ty } = createTransforms(width, height, canvasWidth, canvasHeight, canvasInset)

        const hit = hitTestLoops(tx, ty, point, 2, vn)
        if (hit != null) {
            return hit
        }

        for (let regionIndex = vn.regions.length - 1; regionIndex >= 0; --regionIndex) {
            if (isPointInsideRegion(tx, ty, vn, vn.regions[regionIndex], point)) {
                return { type: 'region', regionIndex: regionIndex, loopIndex: 0 }
            }
        }

        return hitTestLoops(tx, ty, point, 5, vn)
    }

    const onMouseMove = (event: React.MouseEvent) => {
        setHoverState(hitTest(event))
    }

    const onMouseLeave = (_event: React.MouseEvent) => {
        setHoverState(null)
    }

    const onMouseDown = (event: React.MouseEvent) => {
        event.stopPropagation()
        const hover = hitTest(event)
        if (hover) {
            command.DEPRECATED_invokeBridge(UpdateSelectedVectorNetworkRegionCommand, {
                type:
                    hover.type === 'region'
                        ? Wukong.DocumentProto.UpdateVectorNetworkRegionType
                              .UPDATE_VECTOR_NETWORK_REGION_TYPE_R_E_G_I_O_N
                        : Wukong.DocumentProto.UpdateVectorNetworkRegionType.UPDATE_VECTOR_NETWORK_REGION_TYPE_L_O_O_P,
                regionIndex: hover.regionIndex,
                loopIndex: hover.loopIndex,
            })
            motiff.commitUndo()
        }
    }

    useEffect(() => {
        const editing = !viewState?.isEmpty
        setInEditing(editing)
        if (editing) {
            draw()
        }
    }, [viewState, hoverState, draw])

    function UIForNoSelection() {
        return (
            <div className={style['fill-rule-editor-no-selection']} data-testid={FillRuleEditorTestIds.NoSelection}>
                <div className={style.inner}>
                    <div>{translation('ThisPluginLets')}</div>
                    <div className={style['rules-row']}>
                        <div>
                            <FillRuleEditorEvenOddLarge />
                            <div className={style['rules-title']}>{translation('Even-oddRule')}</div>
                            <div className={style['rules-hint']}>Motiff</div>
                        </div>
                        <FillRuleEditorSwitch className={style['rules-switch']} />
                        <div>
                            <FillRuleEditorNonZeroLarge />
                            <div className={style['rules-title']}>{translation('Non-zeroRule')}</div>
                            <div className={style['rules-hint']}>iconfont</div>
                        </div>
                    </div>
                    <div>{translation('CertainExportFormats', { name: getMotiffName() })}</div>
                </div>
                <div className={style.hint}>
                    <div className={style.inner}>
                        <div className={style.title}>{translation('ToUse')}：</div>
                        <div>1. {translation('SelectAVector')}</div>
                        <div>2. {translation('ClickOnA')}</div>
                        <div>
                            3.
                            {translation('ClickOnA_synonyms1')}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (!inEditing) {
        return <UIForNoSelection />
    }

    return (
        <div className={style['fill-rule-editor-editing']} data-testid={FillRuleEditorTestIds.Editing}>
            <div className={style.content}>
                <canvas
                    ref={canvasRef}
                    onMouseMove={(e) => onMouseMove(e)}
                    onMouseDown={(e) => onMouseDown(e)}
                    onMouseLeave={(e) => onMouseLeave(e)}
                ></canvas>
            </div>
            <div className={style.hint}>
                <div className={style['hint-row']}>
                    <div className={style['hint-row-item']}>
                        <div className={style['hint-row-icon']}>
                            <FillRuleEditorEvenOddSmall />
                        </div>
                        <div className={style['hint-row-text']}>{translation('Even-oddRule')}</div>
                    </div>
                    <div className={style['hint-row-item']}>
                        <span className={style['hint-row-icon']}>
                            <FillRuleEditorNonZeroSmall />
                        </span>
                        <span className={style['hint-row-text']}>{translation('Non-zeroRule')}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const manifest: Manifest = {
    key: Wukong.DocumentProto.PluginType.PLUGIN_TYPE_FILL_RULE_EDITOR,
    name: translation('FillRuleEditor'),
    width: 400,
    height: 524,
    icon: <FillRuleEditorIcon />,
}

export default {
    manifest,
    Component: FillRuleEditorPlugin,
} as unknown as PluginExported
