import { translation } from './frame-preset.translation'
/* eslint-disable no-restricted-imports */
import { UpdateFrameTemplateCommand, Wukong } from '@wukong/bridge-proto'
import { useCallback, useMemo } from 'react'
import {
    IconOrientationlandscape,
    IconOrientationPortrait,
    IconResizeToFit,
    Tooltip,
} from '../../../../../../ui-lib/src'
import { useCommand } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { IconButton } from '../../atom/button/icon-button'
import { SelectIconGroup } from '../../atom/button/select-button-group'
import { SingleGrid } from '../../atom/grid/single-grid'
import { FrameTemplateItem, getFrameTemplateData } from '../frame-template/template-data'
import style from './attribute.module.less'
import { FrameGroupSelect } from './frame-preset-select'
import { useAttributeV2 } from './use-attribute'

enum StackMode {
    Vertical = 'vertical',
    Horizontal = 'horizontal',
}

export function FramePreset() {
    const { onChange, resizeToFit, onClickGroupToFrame, onClickFrameToGroup, disabledByAILayout } = useAttributeV2()
    const state = useViewState('FramePresetState')
    const command = useCommand()
    const show = state?.available
    const typeValue = state?.type
    const typeMixed = state?.typeMixed
    const allowToShowStackModeToggleAndResizeToFitButton = state?.allowShowStackModeToggleAndResizeToFitButton
    const disableStackModeToggle = state?.disableStackModeToggle

    const templatesData = useMemo(() => [...getFrameTemplateData().values()], [])

    const applyTemplate = useCallback(
        (data: FrameTemplateItem) => {
            command.DEPRECATED_invokeBridge(
                UpdateFrameTemplateCommand,
                Wukong.DocumentProto.FrameTemplateSize.create(data)
            )
            command.commitUndo()
        },
        [command]
    )

    const onClickIcon = useCallback(
        (value: StackMode) => {
            switch (value) {
                case StackMode.Vertical: {
                    onChange('stackMode')(
                        Wukong.DocumentProto.UpdateSelectionFrameDirectionCommandEnum
                            .UPDATE_SELECTION_FRAME_DIRECTION_COMMAND_ENUM_VERTICAL
                    )
                    break
                }

                case StackMode.Horizontal: {
                    onChange('stackMode')(
                        Wukong.DocumentProto.UpdateSelectionFrameDirectionCommandEnum
                            .UPDATE_SELECTION_FRAME_DIRECTION_COMMAND_ENUM_HORIZONTAL
                    )
                    break
                }
            }
        },
        [onChange]
    )

    return show ? (
        <SingleGrid className={style.frameGroup}>
            <SingleGrid.Item start={5} span={32}>
                <FrameGroupSelect
                    isMixedFrameGroup={typeMixed}
                    selectFrameGroupType={typeValue}
                    disabledGroup={state.disabledGroup}
                    onClickFrame={onClickGroupToFrame}
                    onClickGroup={onClickFrameToGroup}
                    data={templatesData}
                    selectWidth={!state.sizeMixed ? state.sameSizeWidth : undefined}
                    selectHeight={!state.sizeMixed ? state.sameSizeHeight : undefined}
                    applyTemplate={applyTemplate}
                />
            </SingleGrid.Item>
            {allowToShowStackModeToggleAndResizeToFitButton && (
                <>
                    {!disableStackModeToggle && (
                        <SingleGrid.Item start={39} span={12}>
                            <SelectIconGroup
                                optionValue={[StackMode.Vertical, StackMode.Horizontal]}
                                disabled={typeMixed || typeValue !== Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME}
                                className={style.iconGroup}
                                onClickIcon={onClickIcon}
                            >
                                <Tooltip title={translation('Portrait')}>
                                    <SelectIconGroup.Item
                                        value={StackMode.Vertical}
                                        selected={
                                            !state?.stackModeMixed &&
                                            state?.stackMode === Wukong.DocumentProto.StackMode.STACK_MODE_VERTICAL
                                        }
                                        icon={<IconOrientationPortrait />}
                                        dataTestId="frameVertical"
                                    />
                                </Tooltip>
                                <Tooltip title={translation('Landscape')}>
                                    <SelectIconGroup.Item
                                        value={StackMode.Horizontal}
                                        selected={
                                            !state?.stackModeMixed &&
                                            state?.stackMode === Wukong.DocumentProto.StackMode.STACK_MODE_HORIZONTAL
                                        }
                                        icon={<IconOrientationlandscape />}
                                        dataTestId="frameHorizontal"
                                    />
                                </Tooltip>
                            </SelectIconGroup>
                        </SingleGrid.Item>
                    )}
                    <SingleGrid.Item start={51} span={12} horizontalCenter>
                        <Tooltip title={translation('ResizeTo')}>
                            <IconButton
                                onClick={resizeToFit}
                                selected={false}
                                icon={<IconResizeToFit />}
                                disabled={disabledByAILayout}
                                dataTestId="resizeToFit"
                            />
                        </Tooltip>
                    </SingleGrid.Item>
                </>
            )}
        </SingleGrid>
    ) : (
        <div className={style.frameGroup_uiDiv}></div>
    )
}
