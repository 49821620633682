/* eslint-disable no-var */
import { z } from 'zod'
import { isFunction } from './vm-interface'

export namespace ZodTypes {
    export const String = z.string()
    export const FiniteNumber = z.number().finite()
    export const PositiveInteger = FiniteNumber.min(0).int()
    export const PositiveFloat = FiniteNumber.min(0)
    export const Float = FiniteNumber
    export const ZeroToOne = FiniteNumber.min(0).max(1)
    export const FloatNegativeOneToOne = FiniteNumber.min(-1).max(1)
    export const NonZeroPositiveFloat = FiniteNumber.min(0.01)

    export const FontName = z.strictObject({
        family: z.string(),
        style: z.string(),
    })
    export const TextAutoResize = z.enum(['NONE', 'WIDTH_AND_HEIGHT', 'HEIGHT', 'TRUNCATE'])
    export const TextAlignHorizontal = z.enum(['LEFT', 'CENTER', 'RIGHT', 'JUSTIFIED'])
    export const TextAlignVertical = z.enum(['TOP', 'CENTER', 'BOTTOM'])
    export const TextTruncation = z.enum(['DISABLED', 'ENDING'])
    export const BlendMode = z.enum([
        'PASS_THROUGH',
        'NORMAL',
        'DARKEN',
        'MULTIPLY',
        'LINEAR_BURN',
        'COLOR_BURN',
        'LIGHTEN',
        'SCREEN',
        'LINEAR_DODGE',
        'COLOR_DODGE',
        'OVERLAY',
        'SOFT_LIGHT',
        'HARD_LIGHT',
        'DIFFERENCE',
        'EXCLUSION',
        'HUE',
        'SATURATION',
        'COLOR',
        'LUMINOSITY',
    ])
    export const TextCase = z.enum(['ORIGINAL', 'UPPER', 'LOWER', 'TITLE', 'SMALL_CAPS', 'SMALL_CAPS_FORCED'])
    export const TextDecoration = z.enum(['NONE', 'UNDERLINE', 'STRIKETHROUGH'])
    export const NumberUnit = z.enum(['PIXELS', 'PERCENT'])
    export const LetterSpacing = z
        .strictObject({
            value: z.number().finite(),
            unit: NumberUnit,
        })
        .strict()
    export const LineHeight = z.discriminatedUnion('unit', [
        z
            .strictObject({
                value: z.number().finite(),
                unit: NumberUnit,
            })
            .strict(),
        z
            .strictObject({
                unit: z.literal('AUTO'),
            })
            .strict(),
    ])
    export const TextHyperlinkType = z.enum(['URL', 'NODE'])
    export const TextHyperlinkOptions = z.union([
        z.strictObject({
            type: TextHyperlinkType,
            value: z.string(),
        }),
        z.null(),
    ])
    export const MaxLines = z.number().int().min(1).finite().nullable()
    export const ParagraphSpacing = z.number().finite().nullable()
    export const LayoutAlign = z.enum(['MIN', 'CENTER', 'MAX', 'STRETCH', 'INHERIT'])
    export const LayoutMode = z.enum(['NONE', 'HORIZONTAL', 'VERTICAL'])
    export const LayoutPositioning = z.enum(['AUTO', 'ABSOLUTE'])
    export const StackWrap = z.enum(['NO_WRAP', 'WRAP'])
    export const StackCounterAlignContent = z.enum(['AUTO', 'SPACE_BETWEEN'])
    export const StackJustify = z.enum(['MIN', 'MAX', 'CENTER', 'SPACE_BETWEEN'])
    export const StackAlign = z.enum(['MIN', 'MAX', 'CENTER', 'BASELINE'])
    export const SizingMode = z.enum(['FIXED', 'AUTO'])
    export const Vector = z.strictObject({
        x: FiniteNumber,
        y: FiniteNumber,
    })
    const matrixArr = z.union([
        z
            .array(FiniteNumber)
            .length(3)
            .transform((e) => ({
                0: e[0],
                1: e[1],
                2: e[2],
            })),
        z
            .strictObject({
                0: FiniteNumber,
                1: FiniteNumber,
                2: FiniteNumber,
            })
            .strict(),
    ])
    export const Matrix = z.union([
        z
            .array(matrixArr)
            .length(2)
            .transform((e) => ({
                0: e[0],
                1: e[1],
            })),
        z
            .strictObject({
                0: matrixArr,
                1: matrixArr,
            })
            .strict(),
    ])
    export const ConstraintType = z.enum(['MIN', 'CENTER', 'MAX', 'STRETCH', 'SCALE'])
    export const Constraints = z.strictObject({
        horizontal: ConstraintType,
        vertical: ConstraintType,
    })

    export const Guide = z
        .strictObject({
            axis: z.enum(['X', 'Y']),
            offset: z.number().finite(),
        })
        .strict()
    export const Guides = z.array(Guide)
    export const BooleanOperation = z.enum(['UNION', 'INTERSECT', 'SUBTRACT', 'EXCLUDE'])
    export const StrokeCap = z.enum(['NONE', 'ROUND', 'SQUARE', 'ARROW_LINES', 'ARROW_EQUILATERAL'])
    export const StrokeAlign = z.enum(['CENTER', 'INSIDE', 'OUTSIDE'])
    export const StrokeJoin = z.enum(['MITER', 'BEVEL', 'ROUND'])
    export const VariableAlias = z.strictObject({
        type: z.literal('VARIABLE_ALIAS'),
        id: z.string(),
    })
    export const ColorA = z.strictObject({
        r: ZeroToOne,
        g: ZeroToOne,
        b: ZeroToOne,
        a: ZeroToOne,
    })
    export const Color = z.strictObject({
        r: ZeroToOne,
        g: ZeroToOne,
        b: ZeroToOne,
    })
    const LayoutGridTypeA = z
        .strictObject({
            pattern: z.enum(['COLUMNS', 'ROWS']),
            alignment: z.enum(['MIN', 'MAX']),
            gutterSize: Float,
            count: z.union([PositiveInteger, z.literal(1 / 0)]),
            sectionSize: PositiveFloat,
            offset: PositiveFloat,
            visible: z.boolean().optional(),
            color: ColorA.optional(),
            boundVariables: z
                .strictObject({
                    gutterSize: VariableAlias,
                    count: VariableAlias,
                    sectionSize: VariableAlias,
                    offset: VariableAlias,
                })
                .partial()
                .optional(),
        })
        .strict()
    export const LayoutGridTypeB = z
        .strictObject({
            pattern: z.enum(['COLUMNS', 'ROWS']),
            alignment: z.literal('STRETCH'),
            gutterSize: Float,
            count: z.number().finite().min(0),
            offset: PositiveFloat,
            visible: z.boolean().optional(),
            color: ColorA.optional(),
            boundVariables: z
                .strictObject({
                    gutterSize: VariableAlias,
                    count: VariableAlias,
                    offset: VariableAlias,
                })
                .partial()
                .optional(),
        })
        .strict()
    export const LayoutGridTypeC = z
        .strictObject({
            pattern: z.enum(['COLUMNS', 'ROWS']),
            alignment: z.literal('CENTER'),
            gutterSize: Float,
            count: z.union([PositiveInteger, z.literal(1 / 0)]),
            sectionSize: PositiveFloat,
            visible: z.boolean().optional(),
            color: ColorA.optional(),
            boundVariables: z
                .strictObject({
                    gutterSize: VariableAlias,
                    count: VariableAlias,
                    sectionSize: VariableAlias,
                })
                .partial()
                .optional(),
        })
        .strict()
    export const LayoutGridTypeD = z
        .strictObject({
            pattern: z.literal('GRID'),
            sectionSize: PositiveFloat,
            visible: z.boolean().optional(),
            color: ColorA.optional(),
            boundVariables: z
                .strictObject({
                    sectionSize: VariableAlias,
                })
                .partial()
                .optional(),
        })
        .strict()
    export const LayoutGrid = z.union([LayoutGridTypeA, LayoutGridTypeB, LayoutGridTypeC, LayoutGridTypeD])
    export const LayoutGrids = z.array(LayoutGrid)
    export const PaintBoundVariables = z
        .strictObject({
            color: z
                .strictObject({
                    type: z.literal('VARIABLE_ALIAS'),
                    id: z.string(),
                })
                .optional(),
        })
        .strict()
    const Filters = z
        .strictObject({
            exposure: FloatNegativeOneToOne,
            contrast: FloatNegativeOneToOne,
            saturation: FloatNegativeOneToOne,
            temperature: FloatNegativeOneToOne,
            tint: FloatNegativeOneToOne,
            highlights: FloatNegativeOneToOne,
            shadows: FloatNegativeOneToOne,
        })
        .partial()
    const GradientStop = z
        .strictObject({
            position: ZeroToOne,
            color: ColorA,
            boundVariables: PaintBoundVariables.optional(),
        })
        .strict()
    const SolidPaint = z
        .strictObject({
            type: z.literal('SOLID'),
            color: Color,
            visible: z.boolean().optional(),
            opacity: ZeroToOne.optional(),
            blendMode: BlendMode.optional(),
            boundVariables: PaintBoundVariables.optional(),
        })
        .strict()
    export const Paint = z.discriminatedUnion('type', [
        SolidPaint,
        z
            .strictObject({
                type: z.enum(['GRADIENT_LINEAR', 'GRADIENT_RADIAL', 'GRADIENT_ANGULAR', 'GRADIENT_DIAMOND']),
                gradientTransform: Matrix,
                gradientStops: z.array(GradientStop),
                visible: z.boolean().optional(),
                opacity: ZeroToOne.optional(),
                blendMode: BlendMode.optional(),
            })
            .strict(),
        z
            .strictObject({
                type: z.literal('IMAGE'),
                scaleMode: z.enum(['FILL', 'FIT', 'CROP', 'TILE']),
                imageHash: z.string(),
                scalingFactor: PositiveFloat.optional(),
                rotation: PositiveInteger.optional(),
                imageTransform: Matrix.optional(),
                filters: Filters.optional(),
                visible: z.boolean().optional(),
                opacity: ZeroToOne.optional(),
                blendMode: BlendMode.optional(),
            })
            .strict(),
        z
            .strictObject({
                type: z.literal('VIDEO'),
                scaleMode: z.enum(['FILL', 'FIT', 'CROP', 'TILE']),
                videoHash: z.string(),
                scalingFactor: PositiveFloat.optional(),
                rotation: PositiveInteger.optional(),
                videoTransform: Matrix.optional(),
                filters: Filters.optional(),
                visible: z.boolean().optional(),
                opacity: ZeroToOne.optional(),
                blendMode: BlendMode.optional(),
            })
            .strict(),
    ])
    export const Paints = z.array(Paint)
    export const ShadowEffectBoundVariables = z
        .strictObject({
            color: VariableAlias.optional(),
            radius: VariableAlias.optional(),
            spread: VariableAlias.optional(),
            offsetX: VariableAlias.optional(),
            offsetY: VariableAlias.optional(),
        })
        .strict()
    export const BlurEffectBoundVariables = z
        .strictObject({
            radius: VariableAlias.optional(),
        })
        .strict()
    const ShadowEffect = {
        offset: Vector,
        color: ColorA,
        blendMode: BlendMode,
        radius: Float.min(0),
        spread: Float.optional(),
        visible: z.boolean(),
        boundVariables: ShadowEffectBoundVariables.optional(),
    }
    const InnerShadowEffect = z
        .strictObject({
            ...ShadowEffect,
            type: z.literal('INNER_SHADOW'),
        })
        .strict()
    const DropShadowEffect = z
        .strictObject({
            ...ShadowEffect,
            type: z.literal('DROP_SHADOW'),
            showShadowBehindNode: z.boolean().optional(),
        })
        .strict()
    const BlurEffect = z
        .strictObject({
            type: z.enum(['LAYER_BLUR', 'BACKGROUND_BLUR']),
            radius: Float.min(0),
            visible: z.boolean(),
            boundVariables: BlurEffectBoundVariables.optional(),
        })
        .strict()
    export const Effect = z.union([InnerShadowEffect, DropShadowEffect, BlurEffect])
    export const Effects = z.array(Effect)
    export const FindCriteriaWithPluginDataSchema = z.strictObject({
        types: z
            .array(
                z.enum([
                    'DOCUMENT',
                    'PAGE',
                    'SLICE',
                    'FRAME',
                    'GROUP',
                    'COMPONENT',
                    'COMPONENT_SET',
                    'INSTANCE',
                    'BOOLEAN_OPERATION',
                    'VECTOR',
                    'RECTANGLE',
                    'LINE',
                    'ELLIPSE',
                    'POLYGON',
                    'STAR',
                    'TEXT',
                ])
            )
            .optional(),
        sharedPluginData: z
            .strictObject({
                namespace: z.string(),
                keys: z.array(z.string()).optional(),
            })
            .optional(),
        pluginData: z
            .strictObject({
                keys: z.array(z.string()).optional(),
            })
            .optional(),
    })
    export const UInt8Array = z.instanceof(Uint8Array)

    export const HandleMirroring = z.enum(['NONE', 'ANGLE', 'ANGLE_AND_LENGTH'])
    const WindingRule = z.enum(['NONZERO', 'EVENODD'])
    const VectorPath = z.strictObject({
        windingRule: z.union([WindingRule, z.literal('NONE')]),
        data: z.string(),
    })
    export const VectorPaths = z.array(VectorPath)
    const VectorPoint = z.strictObject({
        x: FiniteNumber,
        y: FiniteNumber,
        strokeCap: StrokeCap.optional(),
        strokeJoin: StrokeJoin.optional(),
        cornerRadius: PositiveFloat.optional(),
        handleMirroring: HandleMirroring.optional(),
    })
    const VectorSegment = z
        .strictObject({
            start: PositiveInteger,
            end: PositiveInteger,
            tangentStart: Vector.optional(),
            tangentEnd: Vector.optional(),
        })
        .strict()
    const VectorRegion = z.strictObject({
        windingRule: WindingRule,
        loops: z.array(z.array(PositiveInteger)),
        fills: Paints.optional(),
        fillStyleId: z.string().optional(),
    })
    export const VectorNetwork = z
        .strictObject({
            vertices: z.array(VectorPoint),
            segments: z.array(VectorSegment),
            regions: z.array(VectorRegion).optional(),
        })
        .strict()

    export const NodeWithId = z.strictObject({
        id: z.string(),
    })
    export const SelectedText = z.union([
        z
            .strictObject({
                node: NodeWithId,
                start: PositiveInteger,
                end: PositiveInteger,
            })
            .strict(),
        z.null(),
    ])
    export const ArcData = z.strictObject({
        startingAngle: FiniteNumber,
        endingAngle: FiniteNumber,
        innerRadius: ZeroToOne,
    })

    export const OverflowDirection = z.enum(['NONE', 'HORIZONTAL', 'VERTICAL', 'BOTH'])

    const exportSettingsConstraints = z.object({
        type: z.enum(['SCALE', 'WIDTH', 'HEIGHT']),
        value: z.number(),
    })
    const exportSettingsSVGBase = z.object({
        contentsOnly: z.boolean().optional(),
        useAbsoluteBounds: z.boolean().optional(),
        suffix: z.string().optional(),
        svgOutlineText: z.boolean().optional(),
        svgIdAttribute: z.boolean().optional(),
        svgSimplifyStroke: z.boolean().optional(),
        colorProfile: z.enum(['DOCUMENT', 'SRGB', 'DISPLAY_P3_V4']).optional(),
    })
    const exportSettingsImage = z.object({
        format: z.enum(['JPG', 'PNG', 'AVIF', 'WEBP']),
        contentsOnly: z.boolean().optional(),
        useAbsoluteBounds: z.boolean().optional(),
        suffix: z.string().optional(),
        constraint: exportSettingsConstraints.optional(),
        colorProfile: z.enum(['DOCUMENT', 'SRGB', 'DISPLAY_P3_V4']).optional(),
    })
    const exportSettingsSVG = z.object({
        format: z.literal('SVG'),
        ...exportSettingsSVGBase.shape,
    })
    const exportSettingsPDF = z.object({
        format: z.literal('PDF'),
        contentsOnly: z.boolean().optional(),
        useAbsoluteBounds: z.boolean().optional(),
        suffix: z.string().optional(),
        colorProfile: z.enum(['DOCUMENT', 'SRGB', 'DISPLAY_P3_V4']).optional(),
    })
    const exportSettingsSVGString = z.object({
        format: z.literal('SVG_STRING'),
        ...exportSettingsSVGBase.shape,
    })
    export const ExportSettings = z
        .discriminatedUnion('format', [exportSettingsImage, exportSettingsSVG, exportSettingsPDF])
        .array()

    export const ExportAsyncSetting = z.discriminatedUnion('format', [
        exportSettingsImage,
        exportSettingsSVG,
        exportSettingsSVGString,
        exportSettingsPDF,
    ])

    export const ComponentPropertyReferences = z.strictObject({
        visible: z.string().optional(),
        characters: z.string().optional(),
        mainComponent: z.string().optional(),
    })
    export const ComponentPropertyType = z.enum(['BOOLEAN', 'TEXT', 'INSTANCE_SWAP', 'VARIANT'])
    export const ComponentPropertyValue = z.union([z.boolean(), z.string()])
    export const InstanceSwapPreferredValueType = z.enum(['COMPONENT', 'COMPONENT_SET'])
    export const InstanceSwapPreferredValue = z.strictObject({
        type: InstanceSwapPreferredValueType,
        key: z.string(),
    })
    export const ComponentPropertyPreferredValues = z.array(InstanceSwapPreferredValue)
    export const ComponentPropertyOptions = z
        .strictObject({
            preferredValues: ComponentPropertyPreferredValues.optional(),
        })
        .optional()
    export const ComponentPropertyDefinitionSchema = z
        .strictObject({
            name: z.string().optional(),
            defaultValue: ComponentPropertyValue.optional(),
            preferredValues: ComponentPropertyPreferredValues.optional(),
        })
        .strict()
    export const VariableDataType = z.enum([
        'BOOLEAN',
        'FLOAT',
        'STRING',
        'VARIABLE_ALIAS',
        'COLOR',
        'EXPRESSION',
        // 'MAP',
        // 'SYMBOL_ID',
    ])
    export const VariableResolvedDataType = z.enum(['BOOLEAN', 'COLOR', 'FLOAT', 'STRING'])
    export const ExpressionFunction = z.enum([
        'ADDITION',
        'SUBTRACTION',
        'RESOLVE_VARIANT',
        'MULTIPLICATION',
        'DIVISION',
        'EQUALS',
        'NOT_EQUAL',
        'LESS_THAN',
        'LESS_THAN_OR_EQUAL',
        'GREATER_THAN',
        'GREATER_THAN_OR_EQUAL',
        'AND',
        'OR',
        'NOT',
        'STRINGIFY',
        'TERNARY',
        'VAR_MODE_LOOKUP',
        'NEGATE',
        'IS_TRUTHY',
    ])
    export var VariableData = z.strictObject({
        type: VariableDataType.optional(),
        resolvedType: VariableResolvedDataType.optional(),
        value: z
            .union([FiniteNumber, z.boolean(), String, ColorA, VariableAlias, z.lazy((): z.ZodType => Expression)])
            .optional(),
    })
    export var Expression = z.strictObject({
        expressionFunction: ExpressionFunction,
        expressionArguments: z.array(z.lazy(() => VariableData)),
    })
    export const Navigation = z.enum(['NAVIGATE', 'SWAP', 'OVERLAY', 'SCROLL_TO'])
    export const Easing = z.strictObject({
        type: z.enum([
            'EASE_IN',
            'EASE_OUT',
            'EASE_IN_AND_OUT',
            'LINEAR',
            'EASE_IN_BACK',
            'EASE_OUT_BACK',
            'EASE_IN_AND_OUT_BACK',
            'CUSTOM_CUBIC_BEZIER',
            'GENTLE',
            'QUICK',
            'BOUNCY',
            'SLOW',
            'CUSTOM_SPRING',
        ]),
        easingFunctionCubicBezier: z
            .strictObject({
                x1: FiniteNumber,
                y1: FiniteNumber,
                x2: FiniteNumber,
                y2: FiniteNumber,
            })
            .optional(),
        easingFunctionSpring: z
            .strictObject({
                mass: FiniteNumber,
                stiffness: FiniteNumber,
                damping: FiniteNumber,
                initialVelocity: FiniteNumber,
            })
            .optional(),
    })
    export const SimpleTransition = z.strictObject({
        type: z.enum(['DISSOLVE', 'SMART_ANIMATE', 'SCROLL_ANIMATE']),
        easing: Easing,
        duration: FiniteNumber,
    })
    export const DirectionalTransition = z.strictObject({
        type: z.enum(['MOVE_IN', 'MOVE_OUT', 'PUSH', 'SLIDE_IN', 'SLIDE_OUT']),
        direction: z.enum(['LEFT', 'RIGHT', 'TOP', 'BOTTOM']),
        matchLayers: z.boolean(),
        easing: Easing,
        duration: FiniteNumber,
    })
    export const Transition = z.union([SimpleTransition, DirectionalTransition])
    export const ReactionActionNoConditional = z.union([
        z.strictObject({
            type: z.enum(['BACK', 'CLOSE']),
        }),
        z.strictObject({
            type: z.literal('URL'),
            url: z.string(),
            openInNewTab: z.boolean().optional(),
        }),
        z.strictObject({
            type: z.literal('UPDATE_MEDIA_RUNTIME'),
            destinationId: z.union([z.string(), z.null()]),
            mediaAction: z.enum(['PLAY', 'PAUSE', 'TOGGLE_PLAY_PAUSE', 'MUTE', 'UNMUTE', 'TOGGLE_MUTE_UNMUTE']),
        }),
        z.strictObject({
            type: z.literal('UPDATE_MEDIA_RUNTIME'),
            destinationId: z.union([z.string(), z.null()]),
            mediaAction: z.enum(['SKIP_FORWARD', 'SKIP_BACKWARD']),
            amountToSkip: FiniteNumber,
        }),
        z.strictObject({
            type: z.literal('UPDATE_MEDIA_RUNTIME'),
            destinationId: z.union([z.string(), z.null()]),
            mediaAction: z.enum(['SKIP_TO']),
            newTimestamp: FiniteNumber,
        }),
        z.strictObject({
            type: z.literal('NODE'),
            destinationId: z.union([z.string(), z.null()]),
            navigation: Navigation,
            transition: z.union([Transition, z.null()]),
            preserveScrollPosition: z.boolean().optional(),
            resetVideoPosition: z.boolean().optional(),
            overlayRelativePosition: Vector.optional(),
            resetScrollPosition: z.boolean().optional(),
            resetInteractiveComponents: z.boolean().optional(),
        }),
        z.strictObject({
            type: z.literal('SET_VARIABLE'),
            variableId: z.union([z.string(), z.null()]),
            variableValue: VariableData.optional(),
        }),
        // z.strictObject({
        //     type: z.literal('SET_VARIABLE_MODE'),
        //     variableCollectionId: z.union([z.string(), z.null()]),
        //     variableModeId: z.union([z.string(), z.null()]),
        // }),
    ])
    export const Trigger = z.union([
        z.strictObject({
            type: z.enum(['ON_CLICK', 'ON_HOVER', 'ON_PRESS', 'ON_DRAG', 'ON_MEDIA_END']),
        }),
        z.strictObject({
            type: z.literal('AFTER_TIMEOUT'),
            timeout: FiniteNumber,
        }),
        z.strictObject({
            type: z.enum(['MOUSE_ENTER', 'MOUSE_LEAVE', 'MOUSE_UP', 'MOUSE_DOWN']),
            delay: FiniteNumber,
        }),
        z.strictObject({
            type: z.literal('ON_KEY_DOWN'),
            device: z.enum(['KEYBOARD', 'XBOX_ONE', 'PS4', 'SWITCH_PRO', 'UNKNOWN_CONTROLLER']),
            keyCodes: z.array(FiniteNumber),
        }),
        z.strictObject({
            type: z.literal('ON_MEDIA_HIT'),
            mediaHitTime: FiniteNumber,
        }),
    ])
    export const ConditionalBlock = z.strictObject({
        condition: VariableData.optional(),
        actions: z.array(ReactionActionNoConditional),
    })
    export const ReactionAction = z.union([
        z.strictObject({
            type: z.literal('CONDITIONAL'),
            conditionalBlocks: z.array(ConditionalBlock),
        }),
        ReactionActionNoConditional,
    ])
    export const Reaction = z.strictObject({
        action: ReactionAction.optional().nullable(),
        actions: z.array(ReactionAction).optional(),
        trigger: z.union([Trigger, z.null()]),
    })
    export const Reactions = z.array(Reaction)
    export const StyledTextSegmentFields = z.array(
        z.enum([
            'fontSize',
            'fontName',
            'fontWeight',
            'textDecoration',
            'textCase',
            'lineHeight',
            'letterSpacing',
            'fills',
            'textStyleId',
            'fillStyleId',
            'listOptions',
            'indentation',
            'hyperlink',
            'openTypeFeatures',
        ])
    )
    export const TextListType = z.enum(['NONE', 'ORDERED', 'UNORDERED'])
    export const TextListOptions = z.strictObject({
        type: TextListType,
    })
    export const PluginFunction = z.any().refine((t) => isFunction(t))
    export const NotifyOptions = z
        .strictObject({
            timeout: z
                .number()
                .min(0)
                .max(1 / 0),
            error: z.boolean(),
            onDequeue: PluginFunction,
            button: z.strictObject({
                text: z.string(),
                action: PluginFunction,
            }),
        })
        .partial()
}
