import { UserItem } from '../type'
import { UserAvatar } from '../user-avatar/user-avatar'
import classes from './user-list.module.less'

export function UserList(props: Pick<UserItem, 'primaryUser' | 'secondaryUsers'> & { className?: string }) {
    return (
        <div className={classes.userList}>
            {props.primaryUser ? <UserAvatar userInfo={props.primaryUser} className={classes.user} /> : null}
            {props.secondaryUsers.map((userInfo) => {
                if (userInfo.id === props.primaryUser?.id) {
                    return null
                }
                return <UserAvatar key={userInfo.id} userInfo={userInfo} className={classes.user} />
            })}
        </div>
    )
}
