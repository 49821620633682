import { DependencyList, useCallback, useState } from 'react'
import { useAsync } from 'react-use'

// 这里不能直接使用 react-use 的 useAsyncRetry，他们的在 loading 状态会忽略 retry 调用
// https://github.com/streamich/react-use/blob/master/src/useAsyncRetry.ts
export const useAsyncRetry = <T>(fn: () => Promise<T>, deps: DependencyList = []) => {
    const [attempt, setAttempt] = useState<number>(0)
    const state = useAsync(fn, [...deps, attempt])

    const retry = useCallback(() => {
        setAttempt((currentAttempt) => currentAttempt + 1)
    }, [])

    return { ...state, retry }
}
