import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    stylingName: '风格名称',
    stylingNamePlaceholder: '请输入风格名称',
    stylingNameError: '最多128个字符',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    stylingName: 'Styling Name',
    stylingNamePlaceholder: 'Enter the styling name',
    stylingNameError: 'Maximum 128 characters',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
