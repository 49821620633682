export const AIMagicIds = {
    main: 'ai-magic',
    rect: 'ai-magic-rect',
    rectInner: 'ai-magic-rect-inner',
    rectDrag: (id: any) => `ai-magic-rect-drag-${id}`,

    switch: {
        main: 'ai-magic-switch',
        comp: 'ai-magic-switch-comp',
        text: 'ai-magic-switch-text',
    },

    prompt: {
        main: 'ai-magic-prompt',

        comp: {
            main: 'ai-magic-comp',
            icon: 'ai-magic-comp-icon',
            compInput: 'ai-magic-comp-input', // 组件输入框（意图）
            textInput: 'ai-magic-comp-text-input', // 文本输入框
            candidate: {
                main: 'ai-magic-comp-candidate',
                category: 'ai-magic-comp-candidate-category',
                cateItem: (id: any) => `ai-magic-comp-candidate-category-item-${id}`,
                empty: 'ai-magic-comp-candidate-empty',
                list: 'ai-magic-comp-candidate-list',
                item: (id: any) => `ai-magic-comp-candidate-list-item-${id}`,
            },
        },

        text: {
            main: 'ai-magic-text',
            icon: 'ai-magic-text-icon',
        },
    },
}
