import { translation } from './use-style-color.translation'
/* eslint-disable no-restricted-imports */
import { GetSelectionNodeIdsCommandForWasm } from '@wukong/bridge-proto'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Position, RectContainer } from '../../../../../../../ui-lib/src'
import { sortArrayByKey } from '../../../../../../../util/src'
import { cmdChangePopupState } from '../../../../../document/command/document-command'
import {
    cmdChangeAttrPanelStyleEditorState,
    cmdDeleteStyleNode,
} from '../../../../../document/command/node-props-command'
import { Paint, PaintStyleNode, PopupStateType } from '../../../../../document/node/node'
import { StyleGetVO, StyleWithoutDocIdGetVO } from '../../../../../kernel/interface/component-style'
import { useComponentService } from '../../../../../main/app-context'
import { useUserConfigStateV2 } from '../../../../../main/user-config/user-config-hook'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'
import { isSearchMatched, searchMatchedFilter } from '../../../../utils/search-sort'
import { CommonStyleInfo, groupStyleVOByName, parseStyleName } from '../get-style-nodes-info-map'
import { modifiedMultiPopup } from '../modified-multi-popup'

export interface RemoteStyleGroup {
    libraryId?: string
    libraryName?: string
    docId?: string
    groups: Map<string, StyleGetVO[]>
}

interface UnknowStyleGroup {
    libraryId?: string
    libraryName?: string
    docId?: string
    value: PaintStyleNode
    groups: Map<string, PaintStyleNode[]>
}

export interface StyleColorProps {
    open?: boolean
    position?: Position
    paints?: Paint[]
    selectStyleId: string | undefined
    selectStyleNodeKey: string
    isMixed: boolean
    onChangeStyle?: (styleId: string, needClosePopup: boolean, styleName?: string, isCreate?: boolean) => void
}

export function useStyleColor(props: StyleColorProps) {
    const { open, position, paints, selectStyleId, selectStyleNodeKey, onChangeStyle } = props
    const command = useCommand()
    const componentService = useComponentService()
    const [contextMenuInfo, setContextMenuInfo] = useState<{
        styleNodeId: string
        rectContainer: RectContainer
    } | null>(null)

    const [isList, setIsList] = useUserConfigStateV2('stylePickerListLayout')

    const hasMoved = useRef<boolean>(false)
    const [searchStr, setSearchStr] = useState<string>('')
    const [styleIdEditor, setStyleIdEditor] = useState<string>('')
    const [isRemoteStyle, setIsRemoteStyle] = useState<boolean>(false)
    const [positionEditor, setPositionEditor] = useState<Position>()
    const [remoteStyleInfo, setRemoteStyleInfo] = useState<{
        styleId?: string
        docId?: string
        fromFig?: boolean
        name?: string
    }>()
    const popupState = useViewState('popupState')
    const attrPanelStyleEditorState = useViewState('attrPanelStyleEditorState')
    const titleContainerRef = useRef<HTMLDivElement>(null)
    const [rightClickStyleId, setRightClickStyleId] = useState('')
    const { localStyleGroups, inUseUnknownStyleNodeItems, subscribedRemoteItems, inUseOtherRemoteItems } = useViewState(
        'libraryPaintStyleState',
        {
            localStyleGroups: [],
            inUseUnknownStyleNodeItems: [],
            subscribedRemoteItems: [],
            inUseOtherRemoteItems: [],
            fakeFieldToForceUpdate: true,
        }
    )
    const [isCreateStyle, setIsCreateStyle] = useState<boolean>(false)
    const [createStyle, setCreateStyle] = useState<{ isCreate: boolean; isMixed?: boolean; paints?: Paint[] }>({
        isCreate: false,
    })

    const localStyleMap = useMemo(() => {
        return localStyleGroups
            .filter((group) => group.items.length > 0)
            .map((group) => {
                return {
                    ...group,
                    items: group.items.map(
                        (
                            item
                        ): CommonStyleInfo & {
                            paints: readonly Paint[]
                            contentHash: string
                        } => {
                            const { styleName, groupName } = parseStyleName(item.name)
                            return {
                                id: item.id,
                                name: item.name,
                                description: item.description,
                                styleName,
                                groupName,
                                paints: item.paints as Paint[],
                                contentHash: item.contentHash ?? '',
                            }
                        }
                    ),
                }
            })
    }, [localStyleGroups])

    const showStyleMap = useMemo(() => {
        const infoMap: typeof localStyleMap = []
        if (!open) {
            return infoMap
        }

        return localStyleMap
            .map((group) => ({
                ...group,
                items: searchMatchedFilter(group.items, searchStr, (item) => item.name),
            }))
            .filter((group) => group.items.length > 0)
    }, [localStyleMap, open, searchStr])

    const hasRemoteStyle = useMemo(() => {
        return (
            subscribedRemoteItems.find((libraryContent) => {
                return libraryContent.groups.find((group) => group.items.length > 0)
            }) ||
            inUseOtherRemoteItems.find((libraryContent) => {
                return libraryContent.groups.find((group) =>
                    group.items.find((item) => selectStyleNodeKey && item.id === selectStyleNodeKey)
                )
            })
        )
    }, [inUseOtherRemoteItems, selectStyleNodeKey, subscribedRemoteItems])

    const remoteStyleList = useMemo(() => {
        const libs = subscribedRemoteItems.map((libraryContent) => ({
            libraryId: libraryContent.libraryId,
            libraryName: libraryContent.libraryName,
            docId: libraryContent.documentId,
            // 匹配到库名，则展示库内所有样式
            // 未匹配到库名，则展示匹配到的库样式
            groups: (isSearchMatched(libraryContent.libraryName, searchStr)
                ? libraryContent.groups
                : libraryContent.groups.map((group) => ({
                      ...group,
                      items: searchMatchedFilter(group.items, searchStr, (item) => item.name),
                  }))
            ).filter((group) => group.items.length) as unknown as Array<{
                name: string
                items: StyleWithoutDocIdGetVO[]
            }>,
        }))

        inUseOtherRemoteItems.forEach((libraryContent) => {
            libs.push({
                libraryId: libraryContent.libraryId,
                libraryName: libraryContent.libraryName,
                docId: libraryContent.documentId,
                groups: libraryContent.groups
                    .map((group) => ({
                        ...group,
                        items: group.items.filter(
                            (item) =>
                                selectStyleNodeKey &&
                                item.id === selectStyleNodeKey &&
                                isSearchMatched(item.name, searchStr)
                        ),
                    }))
                    .filter((group) => group.items.length > 0) as unknown as Array<{
                    name: string
                    items: StyleWithoutDocIdGetVO[]
                }>,
            })
        })

        return sortArrayByKey(
            libs.filter(({ groups }) => groups.length),
            'libraryName'
        )
    }, [subscribedRemoteItems, inUseOtherRemoteItems, searchStr, selectStyleNodeKey])

    const isNoStyle = localStyleMap.length === 0 && !hasRemoteStyle && inUseUnknownStyleNodeItems.length === 0

    const unknownStyleGroup: UnknowStyleGroup | undefined = useMemo(() => {
        const unknowStyleNodes = inUseUnknownStyleNodeItems as unknown as PaintStyleNode[]

        for (const value of unknowStyleNodes) {
            if (value.id !== selectStyleId) continue
            if (!isSearchMatched(value.name, searchStr)) continue

            return {
                libraryId: '',
                libraryName: translation('UnknownLibrary'),
                docId: value.publishFile ?? '',
                value,
                groups: groupStyleVOByName<PaintStyleNode>([value]),
            }
        }
        return undefined
    }, [inUseUnknownStyleNodeItems, searchStr, selectStyleId])

    const openStyleEditor = useMemo(() => {
        const multiPopup = popupState?.multiPopup
        if (!multiPopup || multiPopup.length === 0) {
            return false
        }
        const isOpen = multiPopup.some((v) => v.type === PopupStateType.POPUP_STATE_TYPE_PAINT_STYLE_EDITOR)
        return isOpen && !!attrPanelStyleEditorState?.editingStyleId
    }, [popupState?.multiPopup, attrPanelStyleEditorState?.editingStyleId])

    const setDocumentPopupState = useCallback(
        (openPopup: boolean) => {
            const _popupState = modifiedMultiPopup(
                popupState,
                PopupStateType.POPUP_STATE_TYPE_PAINT_STYLE_EDITOR,
                openPopup
                    ? {
                          type: PopupStateType.POPUP_STATE_TYPE_PAINT_STYLE_EDITOR,
                          reciprocalIndex: -1,
                          multiPopup: [],
                      }
                    : undefined
            )
            command.invoke(cmdChangePopupState, _popupState)
        },
        [command, popupState]
    )

    const onFirstMove = useCallback(() => {
        hasMoved.current = true
    }, [])

    const onCloseEditor = useCallback(() => {
        setStyleIdEditor('')
        setRightClickStyleId('')
        setDocumentPopupState(false)
        command.invoke(cmdChangeAttrPanelStyleEditorState, {
            editingStyleId: '',
            openFromModule: attrPanelStyleEditorState?.openFromModule,
        })
    }, [attrPanelStyleEditorState?.openFromModule, command, setDocumentPopupState])

    const onOpenEditor = useCallback(
        (styleId: string, isCreate?: boolean) => {
            const passIds = command.DEPRECATED_invokeBridge(GetSelectionNodeIdsCommandForWasm).value ?? []
            command.invoke(cmdChangeAttrPanelStyleEditorState, {
                editingStyleId: styleId,
                openFromModule: attrPanelStyleEditorState?.openFromModule,
                openFromNodeId: passIds,
                isCreate,
            })
            setDocumentPopupState(true)
            setStyleIdEditor(styleId)
            setIsCreateStyle(false)
            setCreateStyle({ isCreate: false })
        },
        [command, setDocumentPopupState, attrPanelStyleEditorState]
    )

    const effectCreateStyle = useCallback(
        (styleId: string) => {
            onChangeStyle?.(styleId, false, undefined, true)
            const passIds = command.DEPRECATED_invokeBridge(GetSelectionNodeIdsCommandForWasm).value ?? []
            command.invoke(cmdChangeAttrPanelStyleEditorState, {
                editingStyleId: styleId,
                openFromModule: attrPanelStyleEditorState?.openFromModule,
                openFromNodeId: passIds,
                isCreate: true,
            })
            setStyleIdEditor(styleId)
        },
        [attrPanelStyleEditorState?.openFromModule, command, onChangeStyle]
    )

    const onClickChangeLayout = useCallback(() => {
        setIsList(!isList)
    }, [isList, setIsList])

    const onClickAddStyle = useCallback(() => {
        const rect = titleContainerRef.current?.getBoundingClientRect()
        if (!rect) {
            return
        }
        setDocumentPopupState(true)
        setCreateStyle({ isCreate: true, isMixed: props.isMixed, paints: paints })

        setPositionEditor({ left: rect.left, top: rect.top })
        setIsRemoteStyle(false)
    }, [paints, props.isMixed, setDocumentPopupState])

    const onClickApplyStyle = useCallback(
        ({ id, name }: any) => {
            onCloseEditor()
            onChangeStyle?.(id, !hasMoved.current, name)
        },
        [onChangeStyle, onCloseEditor]
    )

    const onClickHoverIcon = useCallback(
        (
            { id }: any,
            containerDomRect: any,
            remoteStyleId?: string,
            docId?: string,
            name?: string,
            fromFig?: boolean
        ) => {
            setIsRemoteStyle(!!remoteStyleId)
            setRemoteStyleInfo({ styleId: remoteStyleId, docId, name, fromFig })
            styleIdEditor === id ? onCloseEditor() : onOpenEditor(id)
            setPositionEditor({ left: containerDomRect.left, top: containerDomRect.top })
        },
        [onCloseEditor, onOpenEditor, styleIdEditor]
    )
    const onContextMenu = useCallback(
        ({ id }: any, e: any, remoteStyleId?: string, docId?: string, name?: string, fromFig?: boolean) => {
            e.stopPropagation()
            e.preventDefault()
            const { clientX, clientY } = e
            setIsRemoteStyle(!!remoteStyleId)
            setRemoteStyleInfo({ styleId: remoteStyleId, docId, name, fromFig })
            setContextMenuInfo({
                styleNodeId: id,
                rectContainer: {
                    top: clientY,
                    bottom: clientY,
                    right: clientX,
                    left: clientX,
                },
            })
        },
        []
    )

    const onCloseContextMenu = useCallback(() => {
        setRightClickStyleId('')
        setContextMenuInfo(null)
    }, [])

    const editStyle = useCallback(
        (e?: any) => {
            e?.stopPropagation()
            if (!contextMenuInfo) {
                return
            }
            setContextMenuInfo(null)
            const {
                rectContainer: { left, top },
                styleNodeId,
            } = contextMenuInfo
            onOpenEditor(styleNodeId)
            setPositionEditor({ left, top })
        },
        [contextMenuInfo, onOpenEditor]
    )

    const deleteStyle = useCallback(
        (e?: any) => {
            e?.stopPropagation()
            if (!contextMenuInfo) {
                return
            }
            setContextMenuInfo(null)
            command.invoke(cmdDeleteStyleNode, contextMenuInfo.styleNodeId)
            command.commitUndo()
        },
        [command, contextMenuInfo]
    )

    const jumpToOrigin = useCallback(
        (e?: any) => {
            e?.stopPropagation()
            componentService.jumpToOriginDocument({
                docId: remoteStyleInfo?.docId,
                nodeId: remoteStyleInfo?.styleId,
                name: remoteStyleInfo?.name,
                fromFig: remoteStyleInfo?.fromFig,
            })
        },
        [
            componentService,
            remoteStyleInfo?.docId,
            remoteStyleInfo?.styleId,
            remoteStyleInfo?.name,
            remoteStyleInfo?.fromFig,
        ]
    )

    useEffect(() => {
        if (!open) {
            setSearchStr('')
            hasMoved.current = false
        }
    }, [open])

    return {
        open,
        position,
        localStyleMap,
        remoteStyleList,
        isNoStyle,
        hasRemoteStyle,
        isList,
        onClickChangeLayout,
        onClickAddStyle,
        onChangeSearchInput: setSearchStr,
        showStyleMap,
        onContextMenu,
        onClickApplyStyle,
        selectStyleId,
        onClickHoverIcon,
        styleIdEditor,
        openStyleEditor,
        positionEditor,
        onCloseEditor,
        contextMenuInfo,
        onCloseContextMenu,
        editStyle,
        deleteStyle,
        onFirstMove,
        jumpToOrigin,
        isRemoteStyle,
        unknownStyleGroup,
        rightClickStyleId,
        setRightClickStyleId,
        titleContainerRef,
        isCreateStyle,
        createStyle,
        effectCreateStyle,
    }
}
