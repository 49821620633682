import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Arrange: '排列',
    RoundToPixel: '将所选图层对齐像素网格',
    PackHorizontal: '水平堆叠',
    PackVertical: '垂直堆叠',
    DistributeHorizontalSpacing: '水平等间距分布',
    DistributeVerticalSpacing: '垂直等间距分布',
    DistributeLeft: '左边等间距分布',
    DistributeHorizontalCenters: '水平中心等间距分布',
    DistributeRight: '右边等间距分布',
    DistributeTop: '顶边等间距分布',
    DistributeVerticalCenters: '垂直中心等间距分布',
    DistributeBottom: '底边等间距分布',
} as const

export const enTranslation = {
    Arrange: 'Arrange',
    RoundToPixel: 'Round to pixel',
    PackHorizontal: 'Pack horizontal',
    PackVertical: 'Pack vertical',
    DistributeHorizontalSpacing: 'Distribute horizontal spacing',
    DistributeVerticalSpacing: 'Distribute vertical spacing',
    DistributeLeft: 'Distribute left',
    DistributeHorizontalCenters: 'Distribute horizontal centers',
    DistributeRight: 'Distribute right',
    DistributeTop: 'Distribute top',
    DistributeVerticalCenters: 'Distribute vertical centers',
    DistributeBottom: 'Distribute bottom',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
