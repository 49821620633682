import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import {
    AeExportCommand,
    AeRasterizationCommand,
    GetSelectionNodeIdsCommandForWasm,
    Wukong,
} from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import { Checkbox, IconExclamation, WKButton } from '../../../../../../ui-lib/src'
import { blobToDataURI, isEnglishLanguage } from '../../../../../../util/src'
import { useCommand, usePluginService } from '../../../../main/app-context'
import { useUserConfigStateV2 } from '../../../../main/user-config/user-config-hook'
import { useViewState } from '../../../../view-state-bridge'
import { ReactComponent as AEExport } from '../../../assets/plugins/export-ae.svg'
import { useCanvasRenderBridge } from '../../../context/document-context'
import { getLdap, logs } from '../log'
import { PluginToastType } from '../plugin-toast'
import { PluginExported } from '../type'
import styles from './index.module.less'
import { getAeFontName } from './util/font-name'
import { parseSvg } from './util/svg'

enum AePluginStatus {
    Disabled,
    Enabled,
    Exporting,
}

const AEUX_HOST = 'http://127.0.0.1:7240'

export function PopupModal() {
    // 统计曝光次数
    useEffect(() => {
        // trace
        {
            logs.Plugins.ae({ ldap: getLdap() })
        }
    }, [])

    const [exportImageCheckV2, setExportImageCheckV2] = useUserConfigStateV2('aeExportImage')

    const enabled = useViewState('pluginAeExportEnabled', false)

    const [status, setStatus] = useState<AePluginStatus>(AePluginStatus.Disabled)

    const exportWord = useMemo(() => {
        switch (status) {
            case AePluginStatus.Disabled: {
                return translation('SelectLayersFirst')
            }
            case AePluginStatus.Enabled: {
                return `${translation('ExportSelectionTo')} AE`
            }
            case AePluginStatus.Exporting: {
                return translation('Exporting')
            }
        }
    }, [status])

    const pluginService = usePluginService()

    useEffect(() => {
        setStatus(enabled ? AePluginStatus.Enabled : AePluginStatus.Disabled)
    }, [enabled])

    const commandInvoker = useCommand()

    const rasterization = () => {
        commandInvoker.DEPRECATED_invokeBridge(AeRasterizationCommand)
    }

    // 验证 ae 是否打开且装载了 aeux 插件
    const aeuxValidator = async () => {
        return new Promise<boolean>((resolve) => {
            fetch(`${AEUX_HOST}`, {
                method: 'HEAD',
            })
                .then(() => {
                    resolve(true)
                })
                .catch(() => {
                    resolve(false)
                })
        })
    }

    const reduceAeFloatList = (list: Wukong.DocumentProto.IAeFloatList[]) => {
        return list.reduce((res, cur) => {
            res.push(cur.values as number[])
            return res
        }, [] as number[][])
    }

    // 由于序列化过来的 data 有些不符合要求，需要重写 toJSON 的方法
    const translateData = (key: string, value: any) => {
        // points 包裹在 {values} 中，故需要做一个解构
        if (key === 'path') {
            if (value.svg) {
                return parseSvg(value.svg)
            }
            return {
                points: reduceAeFloatList(value.points ?? []),
                inTangents: reduceAeFloatList(value.inTangents ?? []),
                outTangents: reduceAeFloatList(value.outTangents ?? []),
                closed: value.closed,
            }
        }
        // strokeDashes 为空时需要传递一个空数组
        if (key === 'stroke') {
            return value.map((stroke: Wukong.DocumentProto.AeStroke) => ({
                ...stroke,
                strokeDashes: stroke.strokeDashes ?? [],
            }))
        }
        // 对 c++ 的 fontName 类型转化到 AE 类型，这里 map 过程复杂，在 js 做
        if (key === 'fontName') {
            return getAeFontName(value)
        }
        // Text 类型需要保证有 stroke
        if (typeof value === 'object' && value?.type === 'Text' && !value.stroke) {
            value.stroke = []
            return value
        }
        // CompoundShape 类型需要保证有 layers，可能存在空的 bool 脏数据导致没有 layers
        if (typeof value === 'object' && value?.type === 'CompoundShape' && !value.layers) {
            value.layers = []
            return value
        }
        // 一些情况会导入非法的 frame，需要处理
        if (key === 'frame') {
            Object.keys(value).forEach((subKey) => {
                if (isNaN(value[subKey])) {
                    value[subKey] = 0
                }
            })
            return value
        }
        return value
    }

    const export2Ae = async () => {
        // 需要先验证 AE 是否打开且安装了 AEUX 插件
        const validate = await aeuxValidator()

        // trace
        {
            const selectionIds = commandInvoker.DEPRECATED_invokeBridge(GetSelectionNodeIdsCommandForWasm).value ?? []
            logs.Plugins.aeExport({
                ldap: getLdap(),
                layer_id: selectionIds.join(','),
                connect_ae: validate ? '1' : '0',
            })
        }

        if (!validate) {
            pluginService.showToast({
                message: translation('FailedToConnect'),
                type: PluginToastType.Error,
            })
            return
        }

        setStatus(AePluginStatus.Exporting)

        // 开始{translation('MotiffTo')} AE
        const exportedData = commandInvoker.DEPRECATED_invokeBridge(AeExportCommand, {
            shouldExportImage: exportImageCheckV2,
        })

        const { datas, rasterizedImages } = exportedData

        if (isNil(datas) || isNil(rasterizedImages)) {
            return
        }

        // {translation('Actions')}后的数据不一定都是合法的，需要获取合法的数据
        const legalDatas = datas.filter((data) => {
            // 图片不能没有宽高
            if (data.type === 'Image' && (data.frame?.width === 0 || data.frame?.height === 0)) {
                return false
            }
            return true
        })

        // 根据不同的{translation('Actions')}结果走不同的服务接口

        // 没有{translation('Actions')}图片时
        if (rasterizedImages.length === 0) {
            exportDatas2Ae(legalDatas)
        }
        // {translation('Actions')}图片时
        else {
            exportDatasAndImages2Ae(legalDatas, rasterizedImages)
        }
    }

    // {translation('Actions')}数据到 AE
    const exportDatas2Ae = (datas: Wukong.DocumentProto.IAeData[]) => {
        fetch(`${AEUX_HOST}/evalScript`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    method: 'buildLayers',
                    data: {
                        layerData: datas,
                    },
                    switch: 'aftereffects',
                    getPrefs: true,
                },
                translateData
            ),
        })
            .then((res) => {
                if (res.ok) {
                    pluginService.showToast({
                        message: translation('ExportSuccessfully'),
                        type: PluginToastType.Default,
                    })
                    setStatus(enabled ? AePluginStatus.Enabled : AePluginStatus.Disabled)
                }
            })
            .catch(() => {
                pluginService.showToast({ message: translation('FailedToExport'), type: PluginToastType.Error })
                setStatus(enabled ? AePluginStatus.Enabled : AePluginStatus.Disabled)
            })
    }

    const canvasRenderBridge = useCanvasRenderBridge()

    // {translation('Actions')}数据和图片到 AE
    const exportDatasAndImages2Ae = (
        datas: Wukong.DocumentProto.IAeData[],
        images: Wukong.DocumentProto.IRasterizedImage[]
    ) => {
        Promise.all(
            images.map(
                ({ id, name }) =>
                    // eslint-disable-next-line no-async-promise-executor
                    new Promise(async (resolve) => {
                        // {translation('Actions')}的数据数据可能有不存在的图片，需要做个防御。没有生成的图片就不显示
                        try {
                            const blob = await canvasRenderBridge.fetchImageBlob(id!)

                            const base64 = await blobToDataURI(blob!)

                            resolve({
                                name,
                                // 去掉 base64 的前缀
                                imgData: base64.replace(/^.*,/, ''),
                            })
                        } catch {
                            resolve(undefined)
                        }
                    })
            )
        )
            .then((imageList) => imageList.filter((item) => !!item))
            .then((imageList) => {
                fetch(`http://127.0.0.1:7240/writeFiles`, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(
                        {
                            switch: 'aftereffects',
                            images: imageList,
                            data: {
                                layerData: datas,
                            },
                        },
                        translateData
                    ),
                }).then((res) => {
                    if (res.ok) {
                        pluginService.showToast({
                            message: translation('ExportSuccessfully'),
                            type: PluginToastType.Default,
                        })
                        setStatus(enabled ? AePluginStatus.Enabled : AePluginStatus.Disabled)
                    }
                })
            })
            .catch(() => {
                pluginService.showToast({ message: translation('FailedToExport'), type: PluginToastType.Error })
                setStatus(enabled ? AePluginStatus.Enabled : AePluginStatus.Disabled)
            })
    }

    return (
        <>
            {/* body */}
            <div className={styles['modal-body']}>
                <p className={styles.title}>{translation('Options')}</p>
                <WKButton
                    className={styles['ae-btn']}
                    type="secondary"
                    onClick={() => {
                        // trace
                        {
                            logs.Plugins.aeRasterization({ ldap: getLdap() })
                        }
                        rasterization()
                    }}
                >
                    {translation('RasterizeLayerOn')}
                </WKButton>

                <div className={styles.line} />

                <p className={styles.title}>{translation('Actions')}</p>
                <WKButton
                    className={styles['ae-btn']}
                    type="primary"
                    onClick={export2Ae}
                    disabled={status === AePluginStatus.Disabled}
                    loading={status === AePluginStatus.Exporting}
                >
                    {exportWord}
                </WKButton>

                <div className={styles['export-image-container']}>
                    <Checkbox
                        checked={exportImageCheckV2}
                        onChange={(checked) => {
                            setExportImageCheckV2(checked)
                        }}
                        label={translation('ExportReferenceImage')}
                    />
                </div>
            </div>
            {/* footer */}
            <div className={styles['modal-footer']}>
                <div style={{ height: 16, marginBottom: isEnglishLanguage() ? 20 : 0 }}>
                    <IconExclamation />
                </div>
                {isEnglishLanguage() ? (
                    <div style={{ marginLeft: 5 }}>
                        Keep AE open and make sure the
                        <a
                            href="https://kanyun.notion.site/Motiff-to-AE-Instructions-3fb90628156b4a55a00d1a4e8b36e102"
                            target="_blank"
                            rel="noreferrer"
                            style={{ margin: '0 4px' }}
                            onMouseDown={() => {
                                // trace
                                {
                                    logs.Plugins.aeHelpDocumentLink({ ldap: getLdap() })
                                }
                            }}
                        >
                            AEUX extension
                        </a>
                        installed.
                    </div>
                ) : (
                    <>
                        <span style={{ margin: '0 4px' }}>请确认已打开 AE 且已安装</span>
                        <a
                            href="https://llt9vli7k1.feishu.cn/wiki/Bt7gwV32aiKiCBkj9KKcoYHQno3"
                            target="_blank"
                            rel="noreferrer"
                            onMouseDown={() => {
                                // trace
                                {
                                    logs.Plugins.aeHelpDocumentLink({ ldap: getLdap() })
                                }
                            }}
                        >
                            AEUX 扩展
                        </a>
                    </>
                )}
            </div>
        </>
    )
}

export default {
    manifest: {
        key: Wukong.DocumentProto.PluginType.PLUGIN_TYPE_A_E,
        name: `${translation('MotiffTo')} AE`,
        width: 280,
        height: 306,
        icon: <AEExport />,
    },
    Component: PopupModal,
} as PluginExported
