import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { forwardRef, HTMLAttributes, memo, PropsWithChildren, Ref, useRef, useState } from 'react'
import {
    DropdownV2,
    MonoIconCommonAdd16 as IconAdd,
    WKIconButton as IconButton,
    MonoIconMenuColorVariable12 as IconColor,
    MonoIconPanelAdjust16 as IconEditDetail,
    MonoIconPanelLink16,
    Scrollbar,
    Tooltip,
    WKIconButton,
} from '../../../../../ui-lib/src'
import { ToKeyCode } from '../../../document/util/keycode'
import { isKeyAsEnter } from '../../../kernel/keyboard/keyboard-event-handler'
import { useAppContext } from '../../../main/app-context'
import { KeyboardReceiver } from '../../../main/keyboard-receiver/component'
import { LocalVariableTableItem } from '../../../main/service/local-variable-editor-service'
import { InputOptionsForUndoSquash } from '../atom/inputs/components/formatted-input'
import { HexInput } from '../atom/inputs/hex-input'
import { ScrubbableInputPercent } from '../atom/inputs/scrubbable-input-percent'
import { useRenderColorSpace } from '../color-profile/hook'
import { ColorVarIcon } from '../color-var-item/color-var-icon'
import { ColorVarName } from '../color-var-item/color-var-name'
import { hex2rgb, RGB, rgb2hex } from '../design/blend/color-picker/utils/color-translate'
import { ColorInteraction } from '../design/color-interaction/color-interaction'
import { PaintIcon } from '../paint-icon-color/paint-icon/paint-icon'
import { BorderlessInput } from './borderless-input'
import { useLocalVariableDragContext } from './local-variable-drag-context'
import styles from './local-variable-editor-table-v1.module.less'
import { translation } from './local-variable-editor-table-v1.translation'
import { useLocalVariableScrollContext } from './local-variable-scroll-context'
import { useEditingDetailState } from './use-view-state-hook'

const FIXED_CELL_WIDTH = 210
const MIN_CELL_WIDTH = 36 + 12 * 2 // 36 是行高，12 是两侧的 padding

function dynamicGridTemplateColumns(modeCount: number) {
    return `${FIXED_CELL_WIDTH}px repeat(${modeCount}, ${FIXED_CELL_WIDTH}px) minmax(${MIN_CELL_WIDTH}px, 1fr)`
}

function useEditingColorState(variableId: string, modeId: string) {
    const service = useAppContext().variableService.localVariableEditorService
    const colorInteractionState = service.stateStore.use.editingColorState()
    return colorInteractionState &&
        colorInteractionState.variableId === variableId &&
        colorInteractionState?.modeId === modeId
        ? {
              position: colorInteractionState.position,
              openFrom: colorInteractionState.openFrom,
          }
        : null
}

// 表格行同意样式
function _TableRow(
    props: PropsWithChildren<
        {
            selected?: boolean
        } & HTMLAttributes<HTMLDivElement>
    >,
    ref: Ref<HTMLDivElement>
) {
    const { selected = false, className, children, ...otherProps } = props
    return (
        <div
            ref={ref}
            className={classNames(styles['table-row'], selected && styles.selected, className)}
            {...otherProps}
        >
            {children}
        </div>
    )
}
const TableRow = forwardRef(_TableRow)

// 表格单元格统一样式
function _TableCell(props: PropsWithChildren<HTMLAttributes<HTMLDivElement>>, ref: Ref<HTMLDivElement>) {
    const { children, className, ...otherProps } = props
    return (
        <div ref={ref} className={classNames(styles['table-cell'], className)} {...otherProps}>
            {children}
        </div>
    )
}
const TableCell = forwardRef(_TableCell)

// 表头中展示变量合集模式的单元格
function VariableSetModeCell(props: { setMode: Wukong.DocumentProto.IVariableSetMode }) {
    const service = useAppContext().variableService.localVariableEditorService
    const selectedCollection = service.dataStore.use.selectedCollection()
    // 展示的名称
    const nameValue = (selectedCollection?.modes.length || 0) > 1 ? props.setMode.modeName : translation('单值模式标签')
    // 是否允许编辑模式
    const disableEditing = (selectedCollection?.modes.length || 0) <= 1
    // 重命名编辑状态
    const [isEditing, setIsEditing] = useState(false)
    // 右键菜单状态
    const [contextMenuState, setContextMenuState] = useState<{ x: number; y: number } | null>(null)
    return (
        <>
            <TableCell
                data-testid="local-variable-editor-table-header-mode"
                className={classNames(
                    !disableEditing && !!contextMenuState ? styles['table-cell-focused'] : undefined,
                    !disableEditing && styles.selectable
                )}
                onDoubleClick={() => {
                    if (disableEditing) {
                        return
                    }
                    setIsEditing(true)
                }}
                onContextMenu={(e) => {
                    if (disableEditing) {
                        return
                    }
                    setContextMenuState({ x: e.clientX, y: e.clientY })
                    e.preventDefault()
                }}
            >
                <div
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {isEditing ? (
                        <BorderlessInput
                            style={{ color: 'var(--wk-v2-label-color-gray-13)' }}
                            initValue={nameValue}
                            submit={(value) => {
                                service.renameCollectionMode(props.setMode, value)
                                setIsEditing(false)
                            }}
                            abort={() => {
                                setIsEditing(false)
                            }}
                            data-testid="local-variable-editor-table-header-mode-name-input"
                        />
                    ) : (
                        nameValue
                    )}
                </div>
            </TableCell>
            {/* 右键菜单 */}
            {contextMenuState ? (
                <DropdownV2.NoTriggerSingleLevel
                    isOpenState={true}
                    onClose={() => setContextMenuState(null)}
                    onChange={(value, e) => {
                        // 不执行外部表格行的 click 选中
                        e?.stopPropagation()
                        switch (value) {
                            case translation('复制模式'): {
                                service.duplicateCollectionMode(props.setMode)
                                return
                            }
                            case translation('重命名模式'): {
                                setIsEditing(true)
                                return
                            }
                            case translation('删除模式'): {
                                service.deleteCollectionMode(props.setMode.modeId!)
                                return
                            }
                        }
                    }}
                    triggerRect={{
                        left: contextMenuState.x,
                        right: contextMenuState.x,
                        top: contextMenuState.y,
                        bottom: contextMenuState.y,
                    }}
                    dataTestIds={{
                        container: 'local-variable-editor-table-header-mode-context-menu',
                    }}
                >
                    <DropdownV2.NoTriggerSingleLevel.Option value={translation('复制模式')}>
                        <span data-testid="local-variable-editor-table-header-mode-context-menu-duplicate">
                            {translation('复制模式')}
                        </span>
                    </DropdownV2.NoTriggerSingleLevel.Option>
                    <DropdownV2.NoTriggerSingleLevel.Option value={translation('重命名模式')} splitLineBottom>
                        <span data-testid="local-variable-editor-table-header-mode-context-menu-rename">
                            {translation('重命名模式')}
                        </span>
                    </DropdownV2.NoTriggerSingleLevel.Option>
                    <DropdownV2.NoTriggerSingleLevel.Option value={translation('删除模式')}>
                        <span data-testid="local-variable-editor-table-header-mode-context-menu-delete">
                            {translation('删除模式')}
                        </span>
                    </DropdownV2.NoTriggerSingleLevel.Option>
                </DropdownV2.NoTriggerSingleLevel>
            ) : null}
        </>
    )
}

// 变量颜色值的单元格
function VariableColorCell({
    variable,
    modeId,
    onEdit,
}: {
    variable: Wukong.DocumentProto.ILocalVariable
    modeId: string
    onEdit: (horizontalPosition: number) => void
}) {
    const service = useAppContext().variableService.localVariableEditorService
    const selectedVariables = service.dataStore.use.selectedVariables()
    const isMultiSelected = selectedVariables.length > 1
    const colorSpace = useRenderColorSpace()
    const data = variable.dataValues[modeId]
    const isAliasData = data.dataType === Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_ALIAS
    const paint = {
        type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
        visible: true,
        opacity: data.resolvedValue.colorValue?.a / 255,
        color: data.resolvedValue.colorValue,
        colorVar: isAliasData
            ? ({
                  value: {
                      alias: data.alias,
                  },
                  dataType: data.dataType,
                  resolvedDataType: data.resolvedDataType,
              } as Wukong.DocumentProto.IVariableAliasData)
            : undefined,
    }
    const showOpacityInput = paint.opacity !== 1
    const onChangeColor = (hex: string) => {
        const color = hex2rgb(hex)
        const rgba = { ...color, a: Math.max(0, Math.min(paint.opacity, 1)) * 255 } as Wukong.DocumentProto.IRGBA
        service.updateColorVariableValue(variable.id, modeId, rgba)
    }
    const onChangeColorFromInteraction = (value: RGB, options?: InputOptionsForUndoSquash) => {
        const rgba = { ...value, a: Math.max(0, Math.min(paint.opacity, 1)) * 255 } as Wukong.DocumentProto.IRGBA
        service.updateColorVariableValue(variable.id, modeId, rgba, options?.commitType)
    }
    const onChangeOpacity = (opacity: number, options?: InputOptionsForUndoSquash) => {
        const rgba = { ...paint.color, a: Math.max(0, Math.min(opacity, 1)) * 255 } as Wukong.DocumentProto.IRGBA
        service.updateColorVariableValue(variable.id, modeId, rgba, options?.commitType)
    }
    const onChangeColorVar = (value: Wukong.DocumentProto.IVariableAliasData) => {
        service.setAliasForVariable(variable.id, modeId, value.value.alias!)
    }
    const colorInteractionState = useEditingColorState(variable.id, modeId)
    const toggleColorEditing = (
        type:
            | Wukong.DocumentProto.LocalVariableEditorEditingType.LOCAL_VARIABLE_EDITOR_EDITING_TYPE_COLOR_VALUE
            | Wukong.DocumentProto.LocalVariableEditorEditingType.LOCAL_VARIABLE_EDITOR_EDITING_TYPE_COLOR_VALUE_ALIAS
    ) => {
        service.toggleValueEditingPopup(type, variable.id, modeId, {
            left: (colorEditingPositionRef.current?.getBoundingClientRect().left ?? 0) + 6,
            top: colorEditingPositionRef.current?.getBoundingClientRect().bottom ?? 0,
        })
    }
    const colorEditingPositionRef = useRef<HTMLDivElement>(null)
    // 悬浮状态
    const [isHovered, setIsHovered] = useState(false)
    const [isHoveredName, setIsHoveredName] = useState(false)
    // 右键菜单状态
    const [contextMenuState, setContextMenuState] = useState<{ x: number; y: number } | null>(null)
    const tableCellRef = useRef<HTMLDivElement>(null)

    if (!data) {
        return null
    }
    return (
        <>
            <TableCell
                ref={tableCellRef}
                data-testid="local-variable-editor-table-item-color"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onContextMenu={(e) => {
                    setContextMenuState({ x: e.clientX, y: e.clientY })
                    service.mouseDownToSelectVariable(variable.id, e)
                    e.stopPropagation()
                    e.preventDefault()
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        alignItems: 'center',
                    }}
                >
                    {isAliasData ? (
                        <>
                            <ColorVarIcon
                                focusNoneBorder
                                className={styles['color-var-icon']}
                                rgb={paint.color}
                                colorSpace={colorSpace}
                                opacity={paint.opacity}
                                onClick={(e) => {
                                    toggleColorEditing(
                                        Wukong.DocumentProto.LocalVariableEditorEditingType
                                            .LOCAL_VARIABLE_EDITOR_EDITING_TYPE_COLOR_VALUE
                                    )
                                    e.stopPropagation()
                                }}
                                ref={colorEditingPositionRef}
                            />
                            <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <ColorVarName
                                    name={data.aliasName}
                                    deleted={data.isSoftDeleted}
                                    selected={!!colorInteractionState}
                                    hovered={isHoveredName}
                                    onClickOnTag={(e) => {
                                        toggleColorEditing(
                                            Wukong.DocumentProto.LocalVariableEditorEditingType
                                                .LOCAL_VARIABLE_EDITOR_EDITING_TYPE_COLOR_VALUE
                                        )
                                        e.stopPropagation()
                                    }}
                                    onHoverName={(hovered) => setIsHoveredName(hovered)}
                                />
                            </div>
                            {isHovered && (
                                <div className={styles['detach-icon']}>
                                    <Tooltip title={translation('分离变量')}>
                                        <WKIconButton
                                            preventFocus
                                            data-testid="local-variable-editor-table-item-color-alias-detach-button"
                                            icon={<MonoIconPanelLink16 />}
                                            onClick={() => service.detachAliasForVariable(variable, modeId)}
                                        />
                                    </Tooltip>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <PaintIcon
                                paint={paint}
                                focusNoneBorder
                                className={styles['paint-icon']}
                                colorSpace={colorSpace}
                                onClick={(e) => {
                                    toggleColorEditing(
                                        Wukong.DocumentProto.LocalVariableEditorEditingType
                                            .LOCAL_VARIABLE_EDITOR_EDITING_TYPE_COLOR_VALUE
                                    )
                                    e.stopPropagation()
                                }}
                                ref={colorEditingPositionRef}
                            />
                            <div style={{ display: 'flex' }}>
                                <HexInput
                                    className={styles['color-input']}
                                    value={rgb2hex(paint.color.r, paint.color.g, paint.color.b)}
                                    focusWithoutBorder={true}
                                    onChange={onChangeColor}
                                    testId="local-variable-editor-table-item-color-input"
                                />
                                {showOpacityInput && (
                                    <ScrubbableInputPercent
                                        className={styles['opacity-input-control']}
                                        inputClassName={styles['opacity-input']}
                                        focusWithoutBorder={true}
                                        value={paint.opacity}
                                        onChange={(value, options) => onChangeOpacity((value as number) / 100, options)}
                                        testId="local-variable-editor-table-item-color-opacity"
                                    />
                                )}
                            </div>
                        </>
                    )}
                    {/* 颜色选择器 */}
                    {colorInteractionState && (
                        <KeyboardReceiver
                            keyCode={ToKeyCode.Esc}
                            onKeydown={() => (service.closeVariableEditingPopup(), false)}
                        >
                            <ColorInteraction
                                zIndex={200}
                                position={colorInteractionState.position}
                                positionRightBase={false}
                                from={colorInteractionState.openFrom}
                                paint={paint}
                                styleId={undefined}
                                styleKey={''}
                                onCancel={service.closeVariableEditingPopup}
                                onChangePaintType={() => {}}
                                onChangeBlendMode={() => {}}
                                onChangeColor={onChangeColorFromInteraction}
                                onChangeOpacity={onChangeOpacity}
                                onChangeImagePaint={() => {}}
                                onChangeColorStops={() => {}}
                                onChangeTransform={() => {}}
                                enterClose={service.closeVariableEditingPopup}
                                onChangeStyle={() => {}}
                                onChangeColorVar={onChangeColorVar}
                                onChangePaints={() => {}}
                                onClickCreateStyleButton={undefined}
                            />
                        </KeyboardReceiver>
                    )}
                </div>
            </TableCell>
            {/* 右键菜单 */}
            {contextMenuState ? (
                <DropdownV2.NoTriggerSingleLevel
                    isOpenState={true}
                    onClose={() => setContextMenuState(null)}
                    onChange={(value, e) => {
                        // 不执行外部表格行的 click 选中
                        e?.stopPropagation()
                        switch (value) {
                            case translation('分离变量'): {
                                service.detachAliasForVariable(variable, modeId)
                                return
                            }
                            case translation('引用其它变量'): {
                                toggleColorEditing(
                                    Wukong.DocumentProto.LocalVariableEditorEditingType
                                        .LOCAL_VARIABLE_EDITOR_EDITING_TYPE_COLOR_VALUE_ALIAS
                                )
                                return
                            }
                            case translation('新建分组'): {
                                service.addSelectedVariablesIntoGroup()
                                return
                            }
                            case translation('编辑变量'): {
                                onEdit(contextMenuState.x)
                                return
                            }
                            case translation('复制变量'): {
                                service.duplicateSelectedVariables()
                                return
                            }
                            case translation('删除变量'): {
                                service.removeSelectedVariables()
                                return
                            }
                        }
                    }}
                    triggerRect={{
                        left: contextMenuState.x,
                        right: contextMenuState.x,
                        top: contextMenuState.y,
                        bottom: contextMenuState.y,
                    }}
                    dataTestIds={{
                        container: 'local-variable-editor-table-item-context-menu',
                    }}
                >
                    {/* 多选时不展示引用和分离 */}
                    {selectedVariables.length === 1 ? (
                        isAliasData ? (
                            <DropdownV2.NoTriggerSingleLevel.Option value={translation('分离变量')}>
                                <span data-testid="local-variable-editor-table-item-context-menu-detach-alias">
                                    {translation('分离变量')}
                                </span>
                            </DropdownV2.NoTriggerSingleLevel.Option>
                        ) : (
                            <DropdownV2.NoTriggerSingleLevel.Option value={translation('引用其它变量')}>
                                <span data-testid="local-variable-editor-table-item-context-menu-create-alias">
                                    {translation('引用其它变量')}
                                </span>
                            </DropdownV2.NoTriggerSingleLevel.Option>
                        )
                    ) : null}
                    <DropdownV2.NoTriggerSingleLevel.Option value={translation('新建分组')}>
                        <span data-testid="local-variable-editor-table-item-context-menu-new-group">
                            {translation('新建分组')}
                        </span>
                    </DropdownV2.NoTriggerSingleLevel.Option>
                    <DropdownV2.NoTriggerSingleLevel.Option value={translation('编辑变量')} onClick={() => {}}>
                        {isMultiSelected ? translation('编辑变量复数') : translation('编辑变量')}
                    </DropdownV2.NoTriggerSingleLevel.Option>
                    <DropdownV2.NoTriggerSingleLevel.Option value={translation('复制变量')} splitLineBottom>
                        <span data-testid="local-variable-editor-table-item-context-menu-duplicate">
                            {isMultiSelected ? translation('复制变量复数') : translation('复制变量')}
                        </span>
                    </DropdownV2.NoTriggerSingleLevel.Option>
                    <DropdownV2.NoTriggerSingleLevel.Option value={translation('删除变量')}>
                        <span data-testid="local-variable-editor-table-item-context-menu-delete">
                            {isMultiSelected ? translation('删除变量复数') : translation('删除变量')}
                        </span>
                    </DropdownV2.NoTriggerSingleLevel.Option>
                </DropdownV2.NoTriggerSingleLevel>
            ) : null}
        </>
    )
}

// 变量表格行
function VariableRow({ variable, index }: { variable: Wukong.DocumentProto.ILocalVariable; index: number }) {
    const service = useAppContext().variableService.localVariableEditorService
    const selectedCollection = service.dataStore.use.selectedCollection()
    const selectedVariables = service.dataStore.use.selectedVariables().map((v) => v.id)
    // 展示的名称
    const nameValue = variable.name.split('/').reverse()[0]
    // 重命名状态
    const editingVariableId = service.stateStore.use.renameVariableState()
    // 右键菜单状态
    const [contextMenuState, setContextMenuState] = useState<{ x: number; y: number } | null>(null)
    // 拖拽状态
    const { dragStart, dragEnterRow, dragLeaveRow } = useLocalVariableDragContext()
    const tableRowRef = useRef<HTMLDivElement>(null)
    const editTableCellRef = useRef<HTMLDivElement>(null)
    // 编辑弹框
    const onEditOpen = (horizontalPosition: number) => {
        const rect = editTableCellRef.current?.getBoundingClientRect?.()
        if (rect) {
            service.startEditVariable(variable.id, {
                top: rect.bottom, // 始终在右键这一行的下方紧挨着
                left: horizontalPosition, // 水平位置由触发场景决定，如右键打开或单击编辑按钮打开
            })
        }
    }
    const isOpened = useEditingDetailState()
    const isMultiSelected = selectedVariables.length > 1
    const highlightEditButton = isOpened && selectedVariables.includes(variable.id) && !isMultiSelected
    return (
        <>
            <TableRow
                ref={tableRowRef}
                data-testid="local-variable-editor-table-item"
                data-selected={selectedVariables.includes(variable.id)}
                selected={selectedVariables.includes(variable.id)}
                style={{
                    gridTemplateColumns: dynamicGridTemplateColumns(selectedCollection?.modes.length ?? 1),
                }}
                onClick={(e) => {
                    // NOTE: 右键菜单 mask 的点击事件也可以出触发这个，但并不在层级内部
                    if (e.currentTarget.contains(e.target as Node)) {
                        service.clickToSelectVariable(variable.id, e)
                    }
                }}
                onMouseDown={(e) => {
                    service.mouseDownToSelectVariable(variable.id, e)
                    if (tableRowRef.current) {
                        dragStart(e, variable, index, tableRowRef.current)
                    }
                    e.stopPropagation()
                }}
                onContextMenu={(e) => {
                    setContextMenuState({ x: e.clientX, y: e.clientY })
                    e.stopPropagation()
                    e.preventDefault()
                }}
                onMouseEnter={(e) => {
                    if (tableRowRef.current) {
                        dragEnterRow(e, variable, index, tableRowRef.current)
                    }
                }}
                onMouseLeave={() => dragLeaveRow()}
            >
                <TableCell
                    style={{ gap: '8px' }}
                    onDoubleClick={() => service.renameVariable(variable.id)}
                    data-testid="local-variable-editor-table-item-name"
                >
                    <div
                        style={{
                            width: '12px',
                            height: '12px',
                            color: 'var(--wk-v2-label-color-gray-8)',
                            lineHeight: 1,
                        }}
                    >
                        <IconColor />
                    </div>
                    <div
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'pre',
                        }}
                    >
                        {editingVariableId === variable.id ? (
                            <BorderlessInput
                                data-testid="local-variable-editor-table-item-name-input"
                                initValue={nameValue}
                                submit={(value) => {
                                    service.renameVariableSubmit(variable, value)
                                    service.renameVariable(null)
                                }}
                                abort={() => {
                                    service.renameVariable(null)
                                }}
                            />
                        ) : (
                            nameValue
                        )}
                    </div>
                </TableCell>
                {selectedCollection?.modes.map((mode) => (
                    <VariableColorCell
                        key={`${variable.id}-${mode.modeId}`}
                        variable={variable}
                        modeId={mode.modeId!}
                        onEdit={onEditOpen}
                    />
                ))}
                <TableCell ref={editTableCellRef}>
                    <IconButton
                        data-testid="local-variable-editor-table-item-edit-button"
                        type={highlightEditButton ? 'deepBlue' : 'secondary'}
                        icon={<IconEditDetail />}
                        className={classNames(
                            styles['table-cell-icon-button'],
                            // 只有在单选打开时常驻展示
                            highlightEditButton && styles['table-cell-icon-button-show']
                        )}
                        onClick={(e) => {
                            // 不执行外部表格行的 click 选中
                            e.stopPropagation()
                            const rect = editTableCellRef.current?.getBoundingClientRect?.()
                            if (rect) {
                                onEditOpen(rect.x - 196)
                            }
                        }}
                    />
                </TableCell>
            </TableRow>
            {/* 右键菜单 */}
            {contextMenuState ? (
                <DropdownV2.NoTriggerSingleLevel
                    isOpenState={true}
                    onClose={() => setContextMenuState(null)}
                    onChange={(value, e) => {
                        // 不执行外部表格行的 click 选中
                        e?.stopPropagation()
                        switch (value) {
                            case translation('新建分组'): {
                                service.addSelectedVariablesIntoGroup()
                                return
                            }
                            case translation('编辑变量'): {
                                // 右键打开编辑弹框时，位置跟着右键菜单走
                                onEditOpen(contextMenuState.x)
                                return
                            }
                            case translation('复制变量'): {
                                service.duplicateSelectedVariables()
                                return
                            }
                            case translation('删除变量'): {
                                service.removeSelectedVariables()
                                return
                            }
                        }
                    }}
                    triggerRect={{
                        left: contextMenuState.x,
                        right: contextMenuState.x,
                        top: contextMenuState.y,
                        bottom: contextMenuState.y,
                    }}
                    dataTestIds={{
                        container: 'local-variable-editor-table-item-context-menu',
                    }}
                >
                    <DropdownV2.NoTriggerSingleLevel.Option value={translation('新建分组')}>
                        <span data-testid="local-variable-editor-table-item-context-menu-new-group">
                            {translation('新建分组')}
                        </span>
                    </DropdownV2.NoTriggerSingleLevel.Option>
                    <DropdownV2.NoTriggerSingleLevel.Option value={translation('编辑变量')}>
                        {isMultiSelected ? translation('编辑变量复数') : translation('编辑变量')}
                    </DropdownV2.NoTriggerSingleLevel.Option>
                    <DropdownV2.NoTriggerSingleLevel.Option
                        value={translation('复制变量')}
                        backwardIcon={'⇧↩'}
                        splitLineBottom
                    >
                        <span data-testid="local-variable-editor-table-item-context-menu-duplicate">
                            {isMultiSelected ? translation('复制变量复数') : translation('复制变量')}
                        </span>
                    </DropdownV2.NoTriggerSingleLevel.Option>
                    <DropdownV2.NoTriggerSingleLevel.Option value={translation('删除变量')}>
                        <span data-testid="local-variable-editor-table-item-context-menu-delete">
                            {isMultiSelected ? translation('删除变量复数') : translation('删除变量')}
                        </span>
                    </DropdownV2.NoTriggerSingleLevel.Option>
                </DropdownV2.NoTriggerSingleLevel>
            ) : null}
        </>
    )
}

// 表格表头行
function VariableTableHeader() {
    const service = useAppContext().variableService.localVariableEditorService
    const selectedCollection = service.dataStore.use.selectedCollection()
    return (
        <TableRow
            data-testid="local-variable-editor-table-header"
            style={{
                gridTemplateColumns: dynamicGridTemplateColumns(selectedCollection?.modes.length ?? 1),
            }}
        >
            <TableCell>{translation('变量名称')}</TableCell>
            {selectedCollection?.modes.map((setMode) => (
                <VariableSetModeCell key={setMode.modeId} setMode={setMode} />
            ))}
            <TableCell>
                <Tooltip title={translation('添加模式')}>
                    <IconButton
                        icon={<IconAdd />}
                        onClick={service.addCollectionMode}
                        preventFocus
                        data-testid="local-variable-editor-table-header-create-mode-button"
                    />
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}

function VariableGroupLabel({ groupName, maxWidth }: { groupName: string; maxWidth: number }) {
    const segments = groupName.split('/')
    const name = segments.pop()
    const path = segments.map((segment) => `${segment} / `)
    return (
        <>
            {path ? <span data-testid="local-variable-editor-table-group-label">{path}</span> : null}
            {name ? (
                <span data-testid="local-variable-editor-table-group-label" style={{ maxWidth }}>
                    {name}
                </span>
            ) : null}
        </>
    )
}

// 变量分组后在表格中展示的一个区块，包含分组名称和下面的行
function VariableTableItem({
    item,
    fixWidth,
    index,
}: {
    item: LocalVariableTableItem
    fixWidth: number
    index: number
}) {
    const service = useAppContext().variableService.localVariableEditorService
    if (item.type === 'group') {
        return (
            <TableRow
                key={item.groupName}
                className={styles['table-row-group']}
                data-testid="local-variable-editor-table-group"
                onMouseDown={(e) => {
                    service.clearVariableSelection()
                    e.stopPropagation()
                }}
                onClick={(e) => {
                    service.clearPopupState()
                    e.stopPropagation()
                }}
            >
                <Tooltip title={item.groupName}>
                    <div className={styles['table-row-group-label']} style={{ width: fixWidth - 32 }}>
                        <VariableGroupLabel groupName={item.groupName} maxWidth={fixWidth - 32} />
                    </div>
                </Tooltip>
            </TableRow>
        )
    }
    return <VariableRow variable={item.variable} index={index} />
}
function VariableTableDragLine() {
    const { dragLine } = useLocalVariableDragContext()
    if (!dragLine.show) {
        return null
    }
    return (
        <div
            style={{
                position: 'absolute',
                zIndex: 100,
                top: dragLine.top,
                left: 0,
                right: 0,
                height: '2px',
                backgroundColor: 'var(--wk-gray-13)',
            }}
        />
    )
}
const _LocalVariableEditorTable = () => {
    const service = useAppContext().variableService.localVariableEditorService
    const selectedVariables = service.dataStore.use.selectedVariables()
    const tableData = service.dataStore.use.visibleItemsInTable()
    // 滚动
    const { scrollbarRef, handleScroll } = useLocalVariableScrollContext()
    const handleTableContainerScroll = (e: React.UIEvent<HTMLDivElement>) => handleScroll(e.target as HTMLDivElement)
    return (
        <Scrollbar
            ref={scrollbarRef}
            onScroll={handleTableContainerScroll}
            className={styles['table-container-scrollbar']}
            renderViewClassName={styles['table-container-scrollbar-view']}
            scrollHorizontalClassName={styles['table-container-scrollbar-horizontal']}
            scrollVerticalClassName={styles['table-container-scrollbar-vertical']}
        >
            <div
                style={{ flex: 1 }}
                onMouseDown={(e) => {
                    if (e.currentTarget === e.target) {
                        service.clearVariableSelection()
                        e.stopPropagation()
                    }
                }}
                onClick={(e) => {
                    service.clearPopupState()
                    e.stopPropagation()
                }}
                data-testid="local-variable-editor-table-container"
            >
                <KeyboardReceiver
                    keyCode={ToKeyCode.All}
                    onKeydown={(e) => {
                        if (selectedVariables.length && isKeyAsEnter(e) && e.shiftKey) {
                            service.duplicateSelectedVariables()
                            return false
                        }
                        return true
                    }}
                >
                    <div
                        className={styles.table}
                        data-testid="local-variable-editor-table"
                        style={{ height: tableData.totalHeight }}
                    >
                        <VariableTableHeader />
                        {tableData.offsetTop > 0 && <div style={{ height: tableData.offsetTop }}></div>}
                        {tableData.items.map((item, index) => (
                            <VariableTableItem
                                key={item.key}
                                item={item}
                                fixWidth={tableData.totalWidth}
                                index={index}
                            />
                        ))}
                        <VariableTableDragLine />
                    </div>
                </KeyboardReceiver>
            </div>
        </Scrollbar>
    )
}
export const LocalVariableEditorTable = memo(_LocalVariableEditorTable)
