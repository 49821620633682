import { translation } from './copyable-row.translation'
/* eslint-disable no-restricted-imports */
import { WKToast } from '../../../../../../ui-lib/src'
import classNames from 'classnames'
import type { HTMLAttributes } from 'react'
import { useCallback } from 'react'
import { useHandler } from '../../../context/document-context'
import { copyText } from '../../../handler/copy-handler'
import style from './copyable-row.module.less'

interface InspectProps extends HTMLAttributes<HTMLDivElement> {
    copyValue: string
}

export function CopyableRow(props: InspectProps) {
    const handler = useHandler()
    const onCopy = useCallback(() => {
        handler.handle(copyText, props.copyValue)
        WKToast.show(translation('CopiedToClipboard'))
    }, [handler, props.copyValue])
    const { className } = props

    return (
        <span className={classNames(className, style.copyableRow)} onClick={onCopy}>
            {props.children}
        </span>
    )
}
