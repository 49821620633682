import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Share: '分享',
} as const

export const enTranslation = {
    Share: 'Share',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
