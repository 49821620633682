import type { Paint } from '../../document/node/node'
import { Wukong } from '@wukong/bridge-proto'
import PaintType = Wukong.DocumentProto.PaintType

export function getPaintsHash(paints: readonly Paint[]) {
    const imageHashList: string[] = []
    for (const paint of paints) {
        if (paint.type === PaintType.PAINT_TYPE_IMAGE_PAINT && paint.imageHash) {
            imageHashList.push(paint.imageHash)
        }
    }

    return imageHashList
}
