import classnames from 'classnames'
import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useRafLoop, useUpdateEffect } from 'react-use'
import { IconScrollArrowBottom, IconScrollArrowTop } from '../../../svg-icon/8/common'
import { useWindowResize } from '../../resize/use-window-resize'
import classes from './pop-up-scroll.module.less'

export type Rect = Pick<DOMRect, 'top' | 'bottom' | 'left' | 'right'>

export interface PopUpScrollProps {
    placement?:
        | 'over'
        | 'bottom left'
        | 'bottom center'
        | 'bottom right'
        | 'top left'
        | 'top center'
        | 'top right'
        | 'right top'
        | 'left top'
    autoAdjustTop?: boolean // placement='left top'|'right top'时生效。初始计算位置时底部空间不足，向上调整高度（top不再与triggerRect对齐）
    overMajorClassName?: string // 只有 placement = 'over' 才需要用此定位
    overMinorClassName?: string // 只有 placement = 'over' 且没找到 overMajorClassName 对应元素时 才需要用此定位
    visibleClassName?: string // 用于获取要出现在视图里的元素的 rect
    visibleTrigger?: unknown // 用于触发获取动作
    worldRect?: Rect | (() => Rect) // 根据这个值做边缘检测
    triggerRect: Rect | (() => Rect) // 根据这个值去定位
    isNotCoverTriggerRect?: boolean // placement = 'right top' | 'left top' 时是否允许在自适应时避免遮挡住triggerRect区域
    isMinWidthFromTrigger?: boolean // 是否使用trigger的宽度作为弹出内容的最小宽度
    minWidth?: number // 弹出内容的最小宽度
    width?: number // 弹出内容的宽度
    maxWidth?: number // 弹出内容的最大宽度
    removeMask?: boolean
    removeTopPadding?: boolean // 移除自带的顶部白条。需要设置者自己预留这个区域
    removeBottomPadding?: boolean // 移除自带的底部白条。需要设置者自己预留这个区域
    onClickMask?: () => void
    children?: React.ReactNode
    className?: string
    dataTestIds?: {
        container?: string
        scrollContainer?: string
        scrollContent?: string
        mask?: string
        arrowTop?: string
        arrowBottom?: string
    }
}

export const arrowReservedHeight = 8
export const minEdgeInWorldRect = 8
export const offsetTriggerHorizontal = 8
const defaultScrollDelta = 4

export interface PopUpScrollRef {
    getContainer: () => HTMLDivElement
    getKeyboardElement: () => HTMLDivElement
}
function _PopUpScroll<T extends PopUpScrollProps = PopUpScrollProps>(props: T, ref?: React.Ref<PopUpScrollRef>) {
    const {
        triggerRect,
        isNotCoverTriggerRect,
        dataTestIds,
        isMinWidthFromTrigger,
        minWidth,
        width,
        maxWidth,
        placement = 'bottom center',
        autoAdjustTop,
        visibleTrigger,
        visibleClassName,
        overMajorClassName,
        overMinorClassName,
        worldRect,
        removeMask,
        removeTopPadding,
        removeBottomPadding,
        className,
        onClickMask,
    } = props
    const hasMouseDownMask = useRef<boolean>(false)
    const containerRef = useRef<HTMLDivElement>(null)
    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const scrollContentRef = useRef<HTMLDivElement>(null)
    const [isShowTopArrow, setIsShowTopArrow] = useState<boolean>(false)
    const [isShowBottomArrow, setIsShowBottomArrow] = useState<boolean>(false)
    const preScrollTopRef = useRef<number>(0)
    const isMountedRef = useRef<boolean>(false)

    // 滚动的方向只能通过 scrollTop 判断
    const managePreScrollTopRef = useCallback((_scrollTop: number) => {
        const delta = _scrollTop - preScrollTopRef.current
        preScrollTopRef.current = _scrollTop
        return delta
    }, [])

    const getWorldRect = useCallback(() => {
        return worldRect
            ? typeof worldRect === 'function'
                ? worldRect()
                : worldRect
            : {
                  left: 0,
                  top: 0,
                  right: document.documentElement.clientWidth,
                  bottom: document.documentElement.clientHeight,
              }
    }, [worldRect])

    const getTriggerRect = useCallback(() => {
        return typeof triggerRect === 'function' ? triggerRect() : triggerRect
    }, [triggerRect])

    const getOverRect = useCallback(
        (el: HTMLElement) => {
            let rect: Rect | undefined
            if (overMajorClassName) {
                rect = el.getElementsByClassName(overMajorClassName)[0]?.getBoundingClientRect()
            }
            if (rect) {
                return rect
            }
            if (overMinorClassName) {
                rect = el.getElementsByClassName(overMinorClassName)[0]?.getBoundingClientRect()
            }
            if (rect) {
                return rect
            }
            const rect1 = el.getBoundingClientRect()
            const rect2 = getTriggerRect()
            const top = rect1.top + arrowReservedHeight
            return { left: rect1.left, top, right: rect1.right, bottom: top + rect2.bottom - rect2.top }
        },
        [getTriggerRect, overMinorClassName, overMajorClassName]
    )

    const getVisibleRect = useCallback(
        (el: HTMLElement): Rect | null => {
            return visibleClassName ? el.getElementsByClassName(visibleClassName)[0]?.getBoundingClientRect() : null
        },
        [visibleClassName]
    )

    const scrollContainerStyle = useMemo(() => {
        let _minWidth = minWidth
        if (isMinWidthFromTrigger) {
            const rect1 = getTriggerRect()
            const rect2 = getWorldRect()
            const limitMinWidth = Math.min(rect1.right - rect1.left, rect2.right - rect2.left - 2 * minEdgeInWorldRect)
            _minWidth = Math.max(_minWidth ?? 0, limitMinWidth)
        }
        const isOverflowHidden =
            (typeof maxWidth === 'number' && !isNaN(maxWidth)) || (typeof width === 'number' && !isNaN(width))
        return {
            minWidth: _minWidth,
            width,
            maxWidth,
            overflow: isOverflowHidden ? 'hidden' : undefined,
        }
    }, [getTriggerRect, getWorldRect, isMinWidthFromTrigger, maxWidth, minWidth, width])

    const setContainerStyle = useCallback((top: number, left: number, bottom: number) => {
        const container = containerRef.current
        if (!container) {
            return
        }
        container.setAttribute('style', `top:${top}px; left: ${left}px;height:${bottom - top}px;`)
    }, [])

    // 用于初次显示时计算位置
    const setOpenPosition = useCallback(() => {
        if (!containerRef.current || !scrollContainerRef.current || !scrollContentRef.current) {
            return
        }
        const scrollContentElement = scrollContentRef.current
        const _worldRect = getWorldRect()
        const _triggerRect = getTriggerRect()
        const scrollContentRect = scrollContentElement.getBoundingClientRect()

        const containerLeft = getContainerLeft(
            _worldRect,
            _triggerRect,
            scrollContentRect.width,
            minEdgeInWorldRect,
            placement,
            !!isNotCoverTriggerRect
        )

        if (placement === 'over') {
            const overRect = getOverRect(scrollContentElement)
            const containerOffsetTop = overRect.top - scrollContentRect.top + (overRect.bottom - overRect.top) / 2
            const containerOffsetBottom = scrollContentRect.height - containerOffsetTop
            const triggerCenterLine = (_triggerRect.top + _triggerRect.bottom) / 2
            const containerTop = Math.max(_worldRect.top + minEdgeInWorldRect, triggerCenterLine - containerOffsetTop)
            const containerBottom = Math.min(
                _worldRect.bottom - minEdgeInWorldRect,
                triggerCenterLine + containerOffsetBottom
            )
            setContainerStyle(containerTop, containerLeft, containerBottom)

            const scrollTop = containerOffsetTop - (triggerCenterLine - containerTop)
            scrollContainerRef.current.scrollTop = scrollTop
            managePreScrollTopRef(scrollContainerRef.current.scrollTop)

            const isTopOverflow = containerOffsetTop > triggerCenterLine - containerTop
            const isBottomOverflow = containerOffsetBottom > containerBottom - triggerCenterLine
            setIsShowTopArrow(isTopOverflow)
            setIsShowBottomArrow(isBottomOverflow)
        } else if (placement.includes('bottom ')) {
            const containerTop = _triggerRect.bottom + offsetTriggerHorizontal
            const containerBottom = Math.min(
                _worldRect.bottom - minEdgeInWorldRect,
                containerTop + scrollContentRect.height
            )
            setContainerStyle(containerTop, containerLeft, containerBottom)
            setIsShowBottomArrow(containerTop + scrollContentRect.height > _worldRect.bottom - minEdgeInWorldRect)
        } else if (placement.includes('top ')) {
            const containerBottom = _triggerRect.top - offsetTriggerHorizontal
            const containerTop = Math.max(
                _worldRect.top + minEdgeInWorldRect,
                containerBottom - scrollContentRect.height
            )
            setContainerStyle(containerTop, containerLeft, containerBottom)
            setIsShowBottomArrow(containerBottom - scrollContentRect.height < _worldRect.top + minEdgeInWorldRect)
        } else if (placement === 'left top' || placement === 'right top') {
            let containerTop = _triggerRect.top - arrowReservedHeight
            const allowMaxBottom = _worldRect.bottom - minEdgeInWorldRect
            let involuntaryBottom = containerTop + scrollContentRect.height
            let containerBottom = Math.min(allowMaxBottom, involuntaryBottom)
            if (autoAdjustTop && involuntaryBottom > allowMaxBottom) {
                const allowMaxTop = _worldRect.top + minEdgeInWorldRect
                const allowAdjustDeltaTop = Math.min(involuntaryBottom - allowMaxBottom, containerTop - allowMaxTop)
                containerTop = containerTop - allowAdjustDeltaTop
                involuntaryBottom = containerTop + scrollContentRect.height
                containerBottom = Math.min(allowMaxBottom, involuntaryBottom)
            }
            setContainerStyle(containerTop, containerLeft, containerBottom)
            setIsShowBottomArrow(involuntaryBottom > allowMaxBottom)
        }
    }, [
        getWorldRect,
        getTriggerRect,
        placement,
        isNotCoverTriggerRect,
        getOverRect,
        setContainerStyle,
        managePreScrollTopRef,
        autoAdjustTop,
    ])

    const onScrollTop = useCallback(
        (scrollDelta = defaultScrollDelta) => {
            if (!scrollContentRef.current || !scrollContainerRef.current || !containerRef.current) {
                return
            }
            const scrollContainerElement = scrollContainerRef.current as HTMLDivElement
            const scrollContentElement = scrollContentRef.current as HTMLDivElement
            const _worldRect = getWorldRect()
            const containerRect = containerRef.current.getBoundingClientRect()
            const contentHeight = scrollContentElement.getBoundingClientRect().height
            let nextBottom = Math.min(containerRect.top + contentHeight, containerRect.bottom + scrollDelta)
            let nextScrollTop = 0
            if (nextBottom > _worldRect.bottom - minEdgeInWorldRect) {
                nextBottom = _worldRect.bottom - minEdgeInWorldRect
                nextScrollTop = scrollContainerElement.scrollTop - scrollDelta
                setIsShowBottomArrow(true)
            } else {
                nextScrollTop = contentHeight - (nextBottom - containerRect.top)
                setIsShowBottomArrow(false)
            }
            setContainerStyle(containerRect.top, containerRect.left, nextBottom)
            nextScrollTop = Math.max(nextScrollTop, 0)
            scrollContainerElement.scrollTop = nextScrollTop
            managePreScrollTopRef(scrollContainerElement.scrollTop)
            setIsShowTopArrow(!isScrollToTop(scrollContainerElement))
        },
        [getWorldRect, managePreScrollTopRef, setContainerStyle]
    )

    const onScrollBottom = useCallback(
        (scrollDelta = defaultScrollDelta) => {
            if (!scrollContentRef.current || !scrollContainerRef.current || !containerRef.current) {
                return
            }
            const scrollContainerElement = scrollContainerRef.current as HTMLDivElement
            const scrollContentElement = scrollContentRef.current as HTMLDivElement
            const _worldRect = getWorldRect()
            const containerRect = containerRef.current.getBoundingClientRect()
            const contentHeight = scrollContentElement.getBoundingClientRect().height
            let nextTop = Math.max(containerRect.bottom - contentHeight, containerRect.top - scrollDelta)
            let nextScrollTop = 0
            if (nextTop < _worldRect.top + minEdgeInWorldRect) {
                nextTop = _worldRect.top + minEdgeInWorldRect
                nextScrollTop = scrollContainerElement.scrollTop + scrollDelta
                setIsShowTopArrow(true)
            } else {
                setIsShowTopArrow(false)
            }
            setContainerStyle(nextTop, containerRect.left, containerRect.bottom)
            nextScrollTop = Math.min(nextScrollTop, contentHeight - containerRect.height)
            scrollContainerElement.scrollTop = nextScrollTop
            managePreScrollTopRef(scrollContainerElement.scrollTop)
            setIsShowBottomArrow(!isScrollToBottom(scrollContainerElement))
        },
        [getWorldRect, managePreScrollTopRef, setContainerStyle]
    )

    const onScroll = useCallback(
        (e: React.UIEvent<HTMLDivElement>) => {
            const canUseDefaultScroll = isShowTopArrow && isShowBottomArrow
            const element = e.target as HTMLDivElement
            const dir = managePreScrollTopRef(element.scrollTop)
            if (dir < 0) {
                if (canUseDefaultScroll) {
                    setIsShowTopArrow(!isScrollToTop(element))
                } else {
                    onScrollTop(-dir)
                }
            } else if (dir > 0) {
                if (canUseDefaultScroll) {
                    setIsShowBottomArrow(!isScrollToBottom(element))
                } else {
                    onScrollBottom(dir)
                }
            }
        },
        [isShowBottomArrow, isShowTopArrow, managePreScrollTopRef, onScrollBottom, onScrollTop]
    )

    const onResize = useCallback(() => {
        // resize有两种:窗口的大小，scrollContent的大小。可能影响left、bottom、isShowBottomArrow
        if (!scrollContentRef.current || !scrollContainerRef.current || !containerRef.current) {
            return
        }
        const _worldRect = getWorldRect()
        const scrollContentRect = scrollContentRef.current.getBoundingClientRect()
        const containerRect = containerRef.current.getBoundingClientRect()

        const allowMaxBottom = _worldRect.bottom - minEdgeInWorldRect

        const _triggerRect = getTriggerRect()
        const containerLeft = getContainerLeft(
            _worldRect,
            _triggerRect,
            scrollContentRect.width,
            minEdgeInWorldRect,
            placement,
            !!isNotCoverTriggerRect
        )
        setIsShowBottomArrow(allowMaxBottom < scrollContentRect.bottom)
        setContainerStyle(containerRect.top, containerLeft, Math.min(scrollContentRect.bottom, allowMaxBottom))
    }, [getTriggerRect, getWorldRect, isNotCoverTriggerRect, placement, setContainerStyle])

    const [StopScrollTop, StartScrollTop] = useRafLoop(() => onScrollTop(), false)
    const [StopScrollBottom, StartScrollBottom] = useRafLoop(() => onScrollBottom(), false)

    useLayoutEffect(() => {
        if (isMountedRef.current) {
            return
        }
        setOpenPosition()
    }, [setOpenPosition])

    useEffect(() => {
        isMountedRef.current = true
    }, [])

    useEffect(() => {
        if (!scrollContentRef.current) {
            return
        }
        const resize = new ResizeObserver(onResize)
        resize.observe(scrollContentRef.current)
        return () => resize.disconnect()
    }, [onResize])

    useWindowResize(onResize)

    useUpdateEffect(() => {
        const scrollContainer = scrollContainerRef.current
        if (!scrollContainer || !containerRef.current || !scrollContentRef.current) {
            return
        }
        const preselectRect = getVisibleRect(scrollContentRef.current)
        if (!preselectRect) {
            return
        }
        const containerRect = containerRef.current.getBoundingClientRect()
        if (preselectRect.top < containerRect.top + 16) {
            onScrollTop(containerRect.top + 16 - preselectRect.top)
        } else if (preselectRect.bottom > containerRect.bottom - 16) {
            onScrollBottom(preselectRect.bottom - containerRect.bottom + 16)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getVisibleRect, visibleTrigger])

    useImperativeHandle(
        ref,
        () => ({ getContainer: () => containerRef.current!, getKeyboardElement: () => containerRef.current! }),
        []
    )

    return (
        <div
            className={classnames(classes.positionContainer, className, {
                [classes.isShowTopArrow]: isShowTopArrow,
                [classes.isShowBottomArrow]: isShowBottomArrow,
            })}
            ref={containerRef}
            data-testid={dataTestIds?.container}
            tabIndex={-1}
            data-forbid-shortcuts
        >
            <div className={classes.limitContainer}>
                <div
                    className={classes.scrollContainer}
                    onScroll={onScroll}
                    ref={scrollContainerRef}
                    data-testid={dataTestIds?.scrollContainer}
                >
                    <div ref={scrollContentRef} data-testid={dataTestIds?.scrollContent} style={scrollContainerStyle}>
                        {removeTopPadding ? null : (
                            <div className={classes.paddingElement} style={{ height: arrowReservedHeight }}></div>
                        )}
                        {props.children}
                        {removeBottomPadding ? null : (
                            <div className={classes.paddingElement} style={{ height: arrowReservedHeight }}></div>
                        )}
                    </div>
                </div>
                {removeMask ? null : (
                    <span
                        className={classes.mask}
                        onMouseDown={() => (hasMouseDownMask.current = true)}
                        onClick={onClickMask}
                        onContextMenu={() => hasMouseDownMask.current && onClickMask?.()}
                        data-testid={dataTestIds?.mask}
                    ></span>
                )}
                <div
                    className={classes.scrollArrowTop}
                    onMouseEnter={StartScrollTop}
                    onMouseLeave={StopScrollTop}
                    data-testid={dataTestIds?.arrowTop}
                >
                    <span className={classes.scrollIcon}>
                        <IconScrollArrowTop />
                    </span>
                </div>
                <div
                    className={classes.scrollArrowBottom}
                    onMouseEnter={StartScrollBottom}
                    onMouseLeave={StopScrollBottom}
                    data-testid={dataTestIds?.arrowBottom}
                >
                    <span className={classes.scrollIcon}>
                        <IconScrollArrowBottom />
                    </span>
                </div>
            </div>
        </div>
    )
}

export const PopUpScroll = forwardRef(_PopUpScroll) as <T extends PopUpScrollProps = PopUpScrollProps>(
    props: T & { ref?: React.Ref<PopUpScrollRef> }
) => React.ReactElement

function isScrollToBottom(containerDOM: HTMLElement, nextScrollTop?: number) {
    const scrollTop = nextScrollTop ?? containerDOM.scrollTop
    return containerDOM.scrollHeight - containerDOM.clientHeight - scrollTop <= 1
}
function isScrollToTop(containerDOM: HTMLElement, nextScrollTop?: number) {
    const scrollTop = nextScrollTop ?? containerDOM.scrollTop
    return scrollTop <= 0
}

export function getContainerLeft(
    worldRect: Rect,
    triggerRect: Rect,
    contentWidth: number,
    minEdge: number,
    placement: PopUpScrollProps['placement'],
    isNotCoverTriggerRect: boolean
): number {
    let left = triggerRect.left
    if (placement === 'over' || placement === 'bottom left' || placement === 'top left') {
        left = triggerRect.left
    } else if (placement === 'bottom right' || placement === 'top right') {
        left = triggerRect.right - contentWidth
    } else if (placement === 'bottom center' || placement === 'top center') {
        left = (triggerRect.right + triggerRect.left - contentWidth) / 2
    } else if (placement === 'right top') {
        left = triggerRect.right
    } else if (placement === 'left top') {
        left = triggerRect.left - contentWidth
    }
    const isLeftOverflow = left < worldRect.left + minEdge
    const isRightOverflow = left + contentWidth > worldRect.right - minEdge
    if (!isLeftOverflow && !isRightOverflow) {
        return left
    }

    const isEnoughSpace = worldRect.right - worldRect.left - 2 * minEdge >= contentWidth
    if (!isEnoughSpace) {
        return worldRect.left + minEdge
    }
    if (isLeftOverflow) {
        if (
            isNotCoverTriggerRect &&
            placement === 'left top' &&
            triggerRect.right + contentWidth <= worldRect.right - minEdge
        ) {
            return triggerRect.right
        }
        return worldRect.left + minEdge
    }
    if (isRightOverflow) {
        if (
            isNotCoverTriggerRect &&
            placement === 'right top' &&
            triggerRect.left - contentWidth >= worldRect.left + minEdge
        ) {
            return triggerRect.left - contentWidth
        }
        return worldRect.right - minEdge - contentWidth
    }
    return left
}
