import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import {
    CurrentPageSetSelectionCommandWasmCall,
    LayerPanelCancelHoverCommandWasmCall,
    LayerPanelEnterHoverCommandWasmCall,
    Wukong,
} from '@wukong/bridge-proto'
import { IconTarget } from '../../../../../../ui-lib/src'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { IconButton } from '../../atom/button/icon-button'
import { LayerPanelItemIcon } from '../../layer-panel/render-item-icon'
import styles from './index.module.less'

export const DataTestId = {
    InspectTarget: 'inspect-target',
}

export function InspectComponentInfo() {
    const command = useCommand()
    const viewState = useViewState('inspectSelectionComponentInfoViewState')

    const handleTargetClick = () => {
        if (!viewState?.ownerNodeId) {
            return
        }

        command.DEPRECATED_invokeBridge(
            CurrentPageSetSelectionCommandWasmCall,
            Wukong.DocumentProto.CurrentPageSetSelectionCommandParam.create({
                selection: [viewState.ownerNodeId],
            })
        )
    }

    const handleMouseEnter = () => {
        if (!viewState?.ownerNodeId) {
            return
        }

        command.DEPRECATED_invokeBridge(
            LayerPanelEnterHoverCommandWasmCall,
            Wukong.DocumentProto.BridgeProtoString.create({
                value: viewState.ownerNodeId,
            })
        )
    }
    const handleMouseLeave = () => {
        if (!viewState?.ownerNodeId) {
            return
        }

        command.DEPRECATED_invokeBridge(
            LayerPanelCancelHoverCommandWasmCall,
            Wukong.DocumentProto.BridgeProtoString.create({
                value: viewState.ownerNodeId,
            })
        )
    }

    if (!viewState?.show) {
        return null
    }

    return (
        <div className={styles.panel}>
            <div className={styles['panel-title']}>{translation('ParentComponent')}</div>
            <div className={styles['panel-content']}>
                <LayerPanelItemIcon
                    id={viewState.ownerNodeId}
                    nodeIcon={viewState.ownerNodeIconData as Wukong.DocumentProto.NodeIconData}
                    isSelected={false}
                    isPurple={false}
                    isFontBold={true}
                    visibleStatus={Wukong.DocumentProto.VLayerPanelVisibleStatus.V_LAYER_PANEL_VISIBLE_STATUS_VISIBLE}
                />
                <div className={styles['panel-content-name']}>{viewState.ownerNodeName}</div>
                <IconButton
                    dataTestId={DataTestId.InspectTarget}
                    icon={<IconTarget />}
                    selected={false}
                    onClick={handleTargetClick}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                />
            </div>
        </div>
    )
}
