import { useEffect, useMemo, useState } from 'react'
import { SceneBlockFull, SceneBlockFullProps } from '../transition/scene-block-full'
import { TransitionContainer, TransitionContainerProps } from '../transition/transition-container'
import { TransitionView } from '../transition/transition-view'

export interface TransitionEffectsProps {
    block: SceneBlockFullProps
    shouldStartTransition?: TransitionContainerProps['shouldStartTransition']
}
/**
 * @description 转场动画
 * */
export function TransitionEffects(props: TransitionEffectsProps) {
    const { block, shouldStartTransition } = props
    const [play, setPlay] = useState<boolean>(false)

    const SceneBlockProps = useMemo((): SceneBlockFullProps => {
        return {
            backgroundColor: 'gray',
            ...block,
            fromStyle: { opacity: 0 },
            toStyle: { opacity: 1 },
            play: block.play !== undefined ? block.play : play,
        }
    }, [block, play])

    useEffect(() => setPlay(true), [block.transition])

    return (
        <TransitionContainer
            shouldStartTransition={() => (setPlay(true), shouldStartTransition?.())}
            shouldEndTransition={() => setPlay(false)}
        >
            <TransitionView>
                <SceneBlockFull {...SceneBlockProps} />
            </TransitionView>
        </TransitionContainer>
    )
}
