import constate from 'constate'
import { RadioGroupStateProps, useRadioGroupState } from '../common/use-radio-group-state'

export interface WKRadioGroupContextProps extends RadioGroupStateProps {
    /**
     * @defaultValue 12
     */
    size?: 12 | 14 | 16
}

const useRadioGroup = (props: WKRadioGroupContextProps) => {
    const { size = 12 } = props
    const radioGroupState = useRadioGroupState(props)

    return {
        size,
        ...radioGroupState,
    }
}

export const [RadioGroupProvider, useRadioGroupContext] = constate(useRadioGroup)
