import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    ProhibitExport: '当前文件被设置为禁止查看者导出',
} as const

export const enTranslation = {
    ProhibitExport: 'This file settings disable viewers from exporting.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
