export const Palette = {
	'--wk-brand-1': 'rgb(237, 245, 252)',
	'--wk-brand-2': 'rgb(224, 239, 255)',
	'--wk-brand-3': 'rgb(201, 226, 255)',
	'--wk-brand-4': 'rgb(166, 203, 255)',
	'--wk-brand-5': 'rgb(120, 172, 255)',
	'--wk-brand-6': 'rgb(89, 147, 255)',
	'--wk-brand-7': 'rgb(48, 113, 255)',
	'--wk-brand-7-8': 'rgba(48, 113, 255, 0.08)',
	'--wk-brand-8': 'rgb(39, 99, 230)',
	'--wk-brand-9': 'rgb(30, 81, 210)',
	'--wk-brand-10': 'rgb(16, 53, 166)',
	'--wk-brand-11': 'rgb(6, 31, 121)',
	'--wk-lake-3': 'rgb(186, 225, 255)',
	'--wk-lake-6': 'rgb(87, 182, 255)',
	'--wk-lake-7': 'rgb(45, 164, 255)',
	'--wk-lake-8': 'rgb(40, 148, 230)',
	'--wk-blue-color-selection-font': 'rgba(77, 166, 255, 0.3)',
	'--wk-blue-color-line-selection': 'rgba(77, 166, 255, 0.19)',
	'--wk-blue-color-line': 'rgba(77, 166, 255, 0.8)',
	'--wk-white': 'rgb(255, 255, 255)',
	'--wk-white-20': 'rgba(255, 255, 255, 0.2)',
	'--wk-white-45': 'rgba(255, 255, 255, 0.45)',
	'--wk-white-64': 'rgba(255, 255, 255, 0.64)',
	'--wk-white-70': 'rgba(255, 255, 255, 0.7)',
	'--wk-white-85': 'rgba(255, 255, 255, 0.85)',
	'--wk-black': 'rgb(0, 0, 0)',
	'--wk-black-10': 'rgba(0, 0, 0, 0.1)',
	'--wk-gray-1': 'rgb(249, 249, 250)',
	'--wk-gray-2': 'rgb(244, 245, 245)',
	'--wk-gray-2-40': 'rgba(244, 245, 245, 0.4)',
	'--wk-gray-3': 'rgb(238, 239, 240)',
	'--wk-gray-4': 'rgb(231, 232, 235)',
	'--wk-gray-5': 'rgb(218, 220, 224)',
	'--wk-gray-6': 'rgb(191, 195, 201)',
	'--wk-gray-7': 'rgb(165, 173, 184)',
	'--wk-gray-8': 'rgb(130, 138, 153)',
	'--wk-gray-9': 'rgb(109, 116, 133)',
	'--wk-gray-10': 'rgb(84, 90, 105)',
	'--wk-gray-11': 'rgb(69, 73, 89)',
	'--wk-gray-11-16': 'rgba(69, 73, 89, 0.16)',
	'--wk-gray-12': 'rgb(47, 51, 64)',
	'--wk-gray-13': 'rgb(26, 29, 37)',
	'--wk-gray-13-4': 'rgba(26, 29, 37, 0.04)',
	'--wk-gray-13-6': 'rgba(26, 29, 37, 0.06)',
	'--wk-gray-13-8': 'rgba(26, 29, 37, 0.08)',
	'--wk-gray-13-12': 'rgba(26, 29, 37, 0.12)',
	'--wk-gray-13-15': 'rgba(26, 29, 37, 0.15)',
	'--wk-gray-13-22': 'rgba(26, 29, 37, 0.22)',
	'--wk-gray-13-30': 'rgba(26, 29, 37, 0.3)',
	'--wk-gray-13-40': 'rgba(26, 29, 37, 0.4)',
	'--wk-gray-13-50': 'rgba(26, 29, 37, 0.5)',
	'--wk-gray-13-60': 'rgba(26, 29, 37, 0.6)',
	'--wk-gray-13-70': 'rgba(26, 29, 37, 0.7)',
	'--wk-gray-13-80': 'rgba(26, 29, 37, 0.8)',
	'--wk-gray-13-90': 'rgba(26, 29, 37, 0.9)',
	'--wk-blue-1': 'rgb(237, 245, 252)',
	'--wk-blue-2': 'rgb(224, 239, 255)',
	'--wk-blue-3': 'rgb(201, 226, 255)',
	'--wk-blue-4': 'rgb(166, 203, 255)',
	'--wk-blue-5': 'rgb(120, 172, 255)',
	'--wk-blue-6': 'rgb(89, 147, 255)',
	'--wk-blue-7': 'rgb(48, 113, 255)',
	'--wk-blue-7-8': 'rgba(48, 113, 255, 0.08)',
	'--wk-blue-8': 'rgb(39, 99, 230)',
	'--wk-blue-9': 'rgb(30, 81, 210)',
	'--wk-blue-10': 'rgb(16, 53, 166)',
	'--wk-blue-11': 'rgb(6, 31, 121)',
	'--wk-green-1': 'rgb(241, 249, 242)',
	'--wk-green-2': 'rgb(225, 243, 228)',
	'--wk-green-3': 'rgb(186, 227, 198)',
	'--wk-green-4': 'rgb(153, 224, 177)',
	'--wk-green-5': 'rgb(109, 209, 146)',
	'--wk-green-6': 'rgb(78, 194, 124)',
	'--wk-green-7': 'rgb(34, 179, 96)',
	'--wk-green-7-8': 'rgba(34, 179, 96, 0.08)',
	'--wk-green-7-20': 'rgba(34, 179, 96, 0.2)',
	'--wk-green-8': 'rgb(28, 158, 89)',
	'--wk-green-9': 'rgb(18, 133, 72)',
	'--wk-green-10': 'rgb(5, 102, 53)',
	'--wk-green-11': 'rgb(0, 71, 40)',
	'--wk-yellow-1': 'rgb(255, 252, 232)',
	'--wk-yellow-2': 'rgb(255, 247, 201)',
	'--wk-yellow-3': 'rgb(255, 240, 171)',
	'--wk-yellow-4': 'rgb(255, 230, 140)',
	'--wk-yellow-5': 'rgb(255, 219, 110)',
	'--wk-yellow-6': 'rgb(255, 205, 79)',
	'--wk-yellow-7': 'rgb(255, 189, 48)',
	'--wk-yellow-7-30': 'rgba(255, 189, 48, 0.3)',
	'--wk-yellow-8': 'rgb(235, 167, 33)',
	'--wk-yellow-9': 'rgb(204, 133, 20)',
	'--wk-yellow-10': 'rgb(143, 86, 7)',
	'--wk-yellow-11': 'rgb(89, 49, 0)',
	'--wk-red-1': 'rgb(255, 243, 240)',
	'--wk-red-2': 'rgb(255, 231, 224)',
	'--wk-red-3': 'rgb(255, 212, 201)',
	'--wk-red-4': 'rgb(255, 182, 163)',
	'--wk-red-5': 'rgb(255, 144, 116)',
	'--wk-red-6': 'rgb(255, 113, 77)',
	'--wk-red-7': 'rgb(255, 81, 38)',
	'--wk-red-7-50': 'rgba(255, 81, 38, 0.5)',
	'--wk-red-7-70': 'rgba(255, 81, 38, 0.7)',
	'--wk-red-8': 'rgb(235, 69, 28)',
	'--wk-red-8-6': 'rgba(235, 69, 28, 0.06)',
	'--wk-red-9': 'rgb(204, 57, 20)',
	'--wk-red-10': 'rgb(128, 30, 5)',
	'--wk-red-11': 'rgb(82, 16, 0)',
	'--wk-pink-1': 'rgb(255, 232, 245)',
	'--wk-pink-2': 'rgb(255, 214, 237)',
	'--wk-pink-3': 'rgb(253, 194, 227)',
	'--wk-pink-4': 'rgb(251, 157, 210)',
	'--wk-pink-5': 'rgb(249, 121, 194)',
	'--wk-pink-6': 'rgb(247, 84, 176)',
	'--wk-pink-7': 'rgb(245, 49, 160)',
	'--wk-pink-7-10': 'rgba(245, 49, 160, 0.1)',
	'--wk-pink-7-20': 'rgba(245, 49, 160, 0.2)',
	'--wk-pink-7-30': 'rgba(245, 49, 160, 0.3)',
	'--wk-pink-8': 'rgb(222, 35, 141)',
	'--wk-pink-9': 'rgb(189, 23, 117)',
	'--wk-pink-10': 'rgb(133, 7, 78)',
	'--wk-pink-11': 'rgb(77, 0, 44)',
	'--wk-purple-1': 'rgb(251, 242, 255)',
	'--wk-purple-2': 'rgb(247, 230, 255)',
	'--wk-purple-3': 'rgb(240, 207, 255)',
	'--wk-purple-4': 'rgb(228, 168, 255)',
	'--wk-purple-5': 'rgb(217, 135, 255)',
	'--wk-purple-6': 'rgb(205, 97, 255)',
	'--wk-purple-7': 'rgb(172, 46, 230)',
	'--wk-purple-7-40': 'rgba(172, 46, 230, 0.4)',
	'--wk-purple-8': 'rgb(153, 31, 209)',
	'--wk-purple-9': 'rgb(117, 12, 166)',
	'--wk-purple-10': 'rgb(82, 0, 120)',
	'--wk-purple-11': 'rgb(51, 0, 74)',
	'--wk-ultramarine-1': 'rgb(237, 240, 252)',
	'--wk-ultramarine-2': 'rgb(230, 232, 255)',
	'--wk-ultramarine-3': 'rgb(207, 211, 255)',
	'--wk-ultramarine-4': 'rgb(176, 177, 255)',
	'--wk-ultramarine-5': 'rgb(139, 133, 255)',
	'--wk-ultramarine-6': 'rgb(115, 97, 255)',
	'--wk-ultramarine-7': 'rgb(97, 61, 255)',
	'--wk-ultramarine-7-10': 'rgba(97, 61, 255, 0.1)',
	'--wk-ultramarine-8': 'rgb(80, 46, 230)',
	'--wk-ultramarine-9': 'rgb(69, 34, 210)',
	'--wk-ultramarine-10': 'rgb(53, 22, 166)',
	'--wk-ultramarine-11': 'rgb(38, 12, 121)',
	'--wk-multiplayer-red': 'rgb(250, 80, 92)',
	'--wk-multiplayer-orange': 'rgb(245, 125, 34)',
	'--wk-multiplayer-yellow': 'rgb(221, 199, 0)',
	'--wk-multiplayer-olive': 'rgb(162, 198, 0)',
	'--wk-multiplayer-green': 'rgb(81, 189, 30)',
	'--wk-multiplayer-lake': 'rgb(6, 196, 184)',
	'--wk-multiplayer-blue': 'rgb(49, 161, 247)',
	'--wk-multiplayer-lilac': 'rgb(135, 110, 240)',
	'--wk-multiplayer-purple': 'rgb(173, 86, 214)',
	'--wk-multiplayer-pink': 'rgb(219, 88, 196)',
	'--wk-canvas-1': 'rgb(216, 216, 216)',
	'--wk-canvas-2': 'rgb(170, 170, 170)',
	'--wk-canvas-2-80': 'rgba(170, 170, 170, 0.8)',
	'--wk-composite-hover-1': 'rgb(240, 240, 241)',
	'--wk-missing-color': 'rgb(255, 231, 2)',
	'--wk-bg-color-page': '--wk-white',
	'--wk-bg-color-container': '--wk-gray-1',
	'--wk-bg-color-container-black': '--wk-black',
	'--wk-bg-color-canvas': '--wk-gray-3',
	'--wk-bg-color-disabled': '--wk-gray-3',
	'--wk-component-color-stroke-secondary': '--wk-gray-2',
	'--wk-component-color-stroke-primary': '--wk-gray-3',
	'--wk-component-color-border-tertiary': '--wk-gray-13-4',
	'--wk-component-color-border-secondary': '--wk-gray-13-8',
	'--wk-component-color-border-primary': '--wk-gray-13-15',
	'--wk-bg-color-container-hover': '--wk-gray-13-4',
	'--wk-bg-color-container-active': '--wk-gray-13-4',
	'--wk-bg-color-component': '--wk-gray-13-60',
	'--wk-gray-color-selection': '--wk-gray-4',
	'--wk-brand-color-selection-placeholder': '--wk-brand-1',
	'--wk-brand-color-selection-secondary': '--wk-brand-2',
	'--wk-brand-color-selection-primary': '--wk-brand-3',
	'--wk-slider-color': '--wk-gray-13-15',
	'--wk-slider-color-hover': '--wk-gray-13-30',
	'--wk-text-color-primary': '--wk-gray-13',
	'--wk-text-color-secondary': '--wk-gray-11',
	'--wk-text-color-placeholder': '--wk-gray-8',
	'--wk-text-color-disabled': '--wk-gray-6',
	'--wk-text-color-brand': '--wk-brand-7',
	'--wk-text-color-link': '--wk-brand-7',
	'--wk-text-color-white': '--wk-white',
	'--wk-icon-color-primary': '--wk-gray-13',
	'--wk-icon-color-solid': '--wk-gray-11',
	'--wk-icon-color-secondary': '--wk-gray-8',
	'--wk-icon-color-disabled': '--wk-gray-6',
	'--wk-icon-color-brand': '--wk-brand-7',
	'--wk-icon-color-brand-disabled': '--wk-brand-4',
	'--wk-icon-color-white': '--wk-white',
	'--wk-brand-color': '--wk-brand-7',
	'--wk-brand-color-hover': '--wk-brand-6',
	'--wk-brand-color-active': '--wk-brand-9',
	'--wk-brand-color-disabled': '--wk-brand-3',
	'--wk-brand-color-bg-active': '--wk-brand-2',
	'--wk-brand-color-bg-hover': '--wk-brand-1',
	'--wk-brand-color-bg': '--wk-brand-1',
	'--wk-brand-color-border': '--wk-brand-3',
	'--wk-success-color': '--wk-green-7',
	'--wk-success-color-border': '--wk-green-3',
	'--wk-success-color-dark-bg': '--wk-green-8',
	'--wk-success-color-light-bg': '--wk-green-1',
	'--wk-warning-color': '--wk-yellow-7',
	'--wk-warning-color-font': '--wk-yellow-11',
	'--wk-warning-color-bg': '--wk-yellow-8',
	'--wk-warning-color-border': '--wk-yellow-4',
	'--wk-warning-color-bg-primary': '--wk-yellow-2',
	'--wk-warning-color-bg-secondary': '--wk-yellow-1',
	'--wk-error-color': '--wk-red-8',
	'--wk-error-color-hover': '--wk-red-6',
	'--wk-error-color-active': '--wk-red-9',
	'--wk-error-color-disabled': '--wk-red-3',
	'--wk-error-color-bg-active': '--wk-red-2',
	'--wk-error-color-bg-hover': '--wk-red-1',
	'--wk-error-color-border': '--wk-red-3',
	'--wk-purple-color-primary': '--wk-purple-7',
	'--wk-purple-color-secondary': '--wk-purple-5',
	'--wk-purple-color-active': '--wk-purple-8',
	'--wk-purple-color-disabled': '--wk-purple-3',
	'--wk-gray-color': '--wk-gray-11',
	'--wk-gray-color-hover': '--wk-gray-8',
	'--wk-gray-color-active': '--wk-gray-13',
	'--wk-gray-color-disabled': '--wk-gray-5',
	'--wk-gray-color-bg-active': '--wk-gray-3',
	'--wk-gray-color-bg-hover': '--wk-gray-2',
	'--wk-gray-color-border': '--wk-gray-5',
	'--wk-white-color-line': '--wk-white-70',
	'--wk-brand-color-selection-box': '--wk-brand-7-8',
	'--wk-pink-color-padding': '--wk-pink-7',
	'--wk-red-color': '--wk-red-7',
	'--wk-red-color-line-edit': '--wk-red-7-70',
	'--wk-red-color-line-losefocus': '--wk-red-7-50',
	'--wk-canvas-color-fill': '--wk-canvas-1',
	'--wk-canvas-color-line': '--wk-canvas-2-80',
	'--wk-canvas-color-frame-name': '--wk-canvas-2',
	'--wk-ultramarine-color': '--wk-ultramarine-7',
	'--wk-ultramarine-mask-color': '--wk-ultramarine-7-10',
	'--wk-bg-error-color-container-hover': '--wk-red-8-6',
	'--wk-v2-white': 'rgb(255, 255, 255)',
	'--wk-v2-black': 'rgb(0, 0, 0)',
	'--wk-v2-midnight': 'rgb(18, 19, 22)',
	'--wk-v2-coal': 'rgb(25, 26, 29)',
	'--wk-v2-brand-1': 'rgb(237, 245, 252)',
	'--wk-v2-brand-2': 'rgb(224, 239, 255)',
	'--wk-v2-brand-3': 'rgb(201, 226, 255)',
	'--wk-v2-brand-4': 'rgb(166, 203, 255)',
	'--wk-v2-brand-5': 'rgb(120, 172, 255)',
	'--wk-v2-brand-6': 'rgb(89, 147, 255)',
	'--wk-v2-brand-7': 'rgb(48, 113, 255)',
	'--wk-v2-brand-8': 'rgb(39, 99, 230)',
	'--wk-v2-brand-9': 'rgb(30, 81, 210)',
	'--wk-v2-brand-10': 'rgb(16, 53, 166)',
	'--wk-v2-brand-11': 'rgb(6, 31, 121)',
	'--wk-v2-brand-dark-1': 'rgb(26, 29, 38)',
	'--wk-v2-brand-dark-2': 'rgb(37, 43, 63)',
	'--wk-v2-brand-dark-3': 'rgb(54, 69, 102)',
	'--wk-v2-brand-dark-4': 'rgb(65, 89, 140)',
	'--wk-v2-brand-dark-5': 'rgb(71, 106, 179)',
	'--wk-v2-brand-dark-6': 'rgb(74, 119, 217)',
	'--wk-v2-brand-dark-7': 'rgb(68, 124, 246)',
	'--wk-v2-brand-dark-8': 'rgb(92, 144, 255)',
	'--wk-v2-brand-dark-9': 'rgb(116, 161, 255)',
	'--wk-v2-brand-dark-10': 'rgb(140, 178, 255)',
	'--wk-v2-brand-dark-11': 'rgb(164, 195, 255)',
	'--wk-v2-gray-1': 'rgb(249, 249, 250)',
	'--wk-v2-gray-2': 'rgb(244, 245, 245)',
	'--wk-v2-gray-3': 'rgb(238, 239, 240)',
	'--wk-v2-gray-4': 'rgb(231, 232, 235)',
	'--wk-v2-gray-5': 'rgb(218, 220, 224)',
	'--wk-v2-gray-6': 'rgb(191, 195, 201)',
	'--wk-v2-gray-7': 'rgb(165, 173, 184)',
	'--wk-v2-gray-8': 'rgb(130, 138, 153)',
	'--wk-v2-gray-9': 'rgb(109, 116, 133)',
	'--wk-v2-gray-10': 'rgb(84, 90, 105)',
	'--wk-v2-gray-11': 'rgb(69, 73, 89)',
	'--wk-v2-gray-12': 'rgb(47, 51, 64)',
	'--wk-v2-gray-13': 'rgb(26, 29, 37)',
	'--wk-v2-gray-dark-1': 'rgba(255, 255, 255, 0.03)',
	'--wk-v2-gray-dark-2': 'rgba(255, 255, 255, 0.04)',
	'--wk-v2-gray-dark-3': 'rgba(255, 255, 255, 0.05)',
	'--wk-v2-gray-dark-4': 'rgba(255, 255, 255, 0.1)',
	'--wk-v2-gray-dark-5': 'rgba(255, 255, 255, 0.15)',
	'--wk-v2-gray-dark-6': 'rgba(255, 255, 255, 0.2)',
	'--wk-v2-gray-dark-7': 'rgba(255, 255, 255, 0.28)',
	'--wk-v2-gray-dark-8': 'rgba(255, 255, 255, 0.36)',
	'--wk-v2-gray-dark-9': 'rgba(255, 255, 255, 0.44)',
	'--wk-v2-gray-dark-10': 'rgba(255, 255, 255, 0.52)',
	'--wk-v2-gray-dark-11': 'rgba(255, 255, 255, 0.6)',
	'--wk-v2-gray-dark-12': 'rgba(255, 255, 255, 0.62)',
	'--wk-v2-gray-dark-13': 'rgba(255, 255, 255, 0.65)',
	'--wk-v2-gray-opacity-1': 'rgba(26, 29, 37, 0.04)',
	'--wk-v2-gray-opacity-2': 'rgba(26, 29, 37, 0.06)',
	'--wk-v2-gray-opacity-3': 'rgba(26, 29, 37, 0.08)',
	'--wk-v2-gray-opacity-4': 'rgba(26, 29, 37, 0.12)',
	'--wk-v2-gray-opacity-5': 'rgba(26, 29, 37, 0.15)',
	'--wk-v2-gray-opacity-6': 'rgba(26, 29, 37, 0.22)',
	'--wk-v2-gray-opacity-7': 'rgba(26, 29, 37, 0.3)',
	'--wk-v2-gray-opacity-8': 'rgba(26, 29, 37, 0.4)',
	'--wk-v2-gray-opacity-9': 'rgba(26, 29, 37, 0.5)',
	'--wk-v2-gray-opacity-10': 'rgba(26, 29, 37, 0.6)',
	'--wk-v2-gray-opacity-11': 'rgba(26, 29, 37, 0.7)',
	'--wk-v2-gray-opacity-12': 'rgba(26, 29, 37, 0.8)',
	'--wk-v2-gray-opacity-13': 'rgba(26, 29, 37, 0.9)',
	'--wk-v2-gray-dark-opacity-1': 'rgba(255, 255, 255, 0.03)',
	'--wk-v2-gray-dark-opacity-2': 'rgba(255, 255, 255, 0.04)',
	'--wk-v2-gray-dark-opacity-3': 'rgba(255, 255, 255, 0.05)',
	'--wk-v2-gray-dark-opacity-4': 'rgba(255, 255, 255, 0.1)',
	'--wk-v2-gray-dark-opacity-5': 'rgba(255, 255, 255, 0.15)',
	'--wk-v2-gray-dark-opacity-6': 'rgba(255, 255, 255, 0.2)',
	'--wk-v2-gray-dark-opacity-7': 'rgba(255, 255, 255, 0.28)',
	'--wk-v2-gray-dark-opacity-8': 'rgba(255, 255, 255, 0.36)',
	'--wk-v2-gray-dark-opacity-9': 'rgba(255, 255, 255, 0.44)',
	'--wk-v2-gray-dark-opacity-10': 'rgba(255, 255, 255, 0.52)',
	'--wk-v2-gray-dark-opacity-11': 'rgba(255, 255, 255, 0.6)',
	'--wk-v2-gray-dark-opacity-12': 'rgba(255, 255, 255, 0.62)',
	'--wk-v2-gray-dark-opacity-13': 'rgba(255, 255, 255, 0.65)',
	'--wk-v2-green-1': 'rgb(241, 249, 242)',
	'--wk-v2-green-2': 'rgb(225, 243, 228)',
	'--wk-v2-green-3': 'rgb(186, 227, 198)',
	'--wk-v2-green-4': 'rgb(153, 224, 177)',
	'--wk-v2-green-5': 'rgb(109, 209, 146)',
	'--wk-v2-green-6': 'rgb(78, 194, 124)',
	'--wk-v2-green-7': 'rgb(34, 179, 96)',
	'--wk-v2-green-8': 'rgb(28, 158, 89)',
	'--wk-v2-green-9': 'rgb(18, 133, 72)',
	'--wk-v2-green-10': 'rgb(5, 102, 53)',
	'--wk-v2-green-11': 'rgb(0, 71, 40)',
	'--wk-v2-green-dark-1': 'rgb(25, 36, 26)',
	'--wk-v2-green-dark-2': 'rgb(35, 49, 37)',
	'--wk-v2-green-dark-3': 'rgb(43, 69, 51)',
	'--wk-v2-green-dark-4': 'rgb(47, 89, 61)',
	'--wk-v2-green-dark-5': 'rgb(48, 110, 71)',
	'--wk-v2-green-dark-6': 'rgb(46, 130, 79)',
	'--wk-v2-green-dark-7': 'rgb(39, 151, 87)',
	'--wk-v2-green-dark-8': 'rgb(69, 173, 118)',
	'--wk-v2-green-dark-9': 'rgb(105, 194, 146)',
	'--wk-v2-green-dark-10': 'rgb(145, 217, 181)',
	'--wk-v2-green-dark-11': 'rgb(194, 240, 208)',
	'--wk-v2-yellow-1': 'rgb(255, 252, 232)',
	'--wk-v2-yellow-2': 'rgb(255, 247, 201)',
	'--wk-v2-yellow-3': 'rgb(255, 240, 171)',
	'--wk-v2-yellow-4': 'rgb(255, 230, 140)',
	'--wk-v2-yellow-5': 'rgb(255, 219, 110)',
	'--wk-v2-yellow-6': 'rgb(255, 205, 79)',
	'--wk-v2-yellow-7': 'rgb(255, 189, 48)',
	'--wk-v2-yellow-8': 'rgb(235, 167, 33)',
	'--wk-v2-yellow-9': 'rgb(204, 133, 20)',
	'--wk-v2-yellow-10': 'rgb(143, 86, 7)',
	'--wk-v2-yellow-11': 'rgb(89, 49, 0)',
	'--wk-v2-yellow-dark-1': 'rgb(36, 34, 25)',
	'--wk-v2-yellow-dark-2': 'rgb(49, 47, 35)',
	'--wk-v2-yellow-dark-3': 'rgb(84, 78, 50)',
	'--wk-v2-yellow-dark-4': 'rgb(120, 106, 56)',
	'--wk-v2-yellow-dark-5': 'rgb(158, 132, 54)',
	'--wk-v2-yellow-dark-6': 'rgb(194, 151, 43)',
	'--wk-v2-yellow-dark-7': 'rgb(230, 164, 22)',
	'--wk-v2-yellow-dark-8': 'rgb(28, 158, 89)',
	'--wk-v2-yellow-dark-9': 'rgb(242, 208, 107)',
	'--wk-v2-yellow-dark-10': 'rgb(250, 229, 155)',
	'--wk-v2-yellow-dark-11': 'rgb(255, 245, 201)',
	'--wk-v2-red-1': 'rgb(255, 243, 240)',
	'--wk-v2-red-2': 'rgb(255, 231, 224)',
	'--wk-v2-red-3': 'rgb(255, 212, 201)',
	'--wk-v2-red-4': 'rgb(255, 182, 163)',
	'--wk-v2-red-5': 'rgb(255, 144, 116)',
	'--wk-v2-red-6': 'rgb(255, 113, 77)',
	'--wk-v2-red-7': 'rgb(255, 81, 38)',
	'--wk-v2-red-8': 'rgb(235, 69, 28)',
	'--wk-v2-red-9': 'rgb(204, 57, 20)',
	'--wk-v2-red-10': 'rgb(128, 30, 5)',
	'--wk-v2-red-11': 'rgb(82, 16, 0)',
	'--wk-v2-red-dark-1': 'rgb(36, 27, 25)',
	'--wk-v2-red-dark-2': 'rgb(49, 38, 35)',
	'--wk-v2-red-dark-3': 'rgb(77, 53, 47)',
	'--wk-v2-red-dark-4': 'rgb(107, 67, 57)',
	'--wk-v2-red-dark-5': 'rgb(138, 76, 61)',
	'--wk-v2-red-dark-6': 'rgb(166, 80, 58)',
	'--wk-v2-red-dark-7': 'rgb(196, 80, 51)',
	'--wk-v2-red-dark-8': 'rgb(226, 75, 37)',
	'--wk-v2-red-dark-9': 'rgb(235, 124, 84)',
	'--wk-v2-red-dark-10': 'rgb(242, 168, 136)',
	'--wk-v2-red-dark-11': 'rgb(249, 209, 190)',
} as const