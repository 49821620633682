import { Wukong } from '@wukong/bridge-proto'
import { useCallback } from 'react'
import { useMount } from 'react-use'
import { MulticolorIconCommonPic16 } from '../../../../../../ui-lib/src'
import {
    AISearchFileOwnerCondition,
    AiSearchResourceType,
    AISearchSortingRule,
} from '../../../../kernel/interface/ai-search'
import { useAppContext, useCommand } from '../../../../main/app-context'
import { AISearchNodeIcon } from '../../ai/ai-search/ai-search-node-icon'
import { AiSearchResultBody } from '../../ai/ai-search/ai-search-result-body'
import { AiSearchResultFileDetail } from '../../ai/ai-search/ai-search-result-file-detail'
import { AiSearchResultModel, useAiSearch } from '../../ai/ai-search/use-ai-search'
import { nodeToCompressPNGBlob } from '../../design/export/util'
import { ChatbotDialogContainer } from '../ui-component/chatbot-container'
import { translation } from './chatbot-ai-searh.translation'

export const ChatbotAISearch = () => {
    const chatbotService = useAppContext().chatbotService
    const command = useCommand()

    const { uploadedImageFile, singleSelectedNodeId, searchType } = useCurrentDialogue()
    const model = useAiSearch()
    const initByImage = useCallback(() => {
        if (model.init && uploadedImageFile) {
            model.setSearchInfo({
                type: 'IMAGE',
                file: uploadedImageFile,
                searchFilter: {
                    resourceType: AiSearchResourceType.ORGANIZATION,
                    fileOwnerCondition: AISearchFileOwnerCondition.ANY_USER,
                    sortingRule: AISearchSortingRule.SIMILARITY,
                },
            })
            return true
        }
        return false
    }, [model, uploadedImageFile])

    const initByNode = useCallback(async () => {
        if (model.init && singleSelectedNodeId) {
            const blob = await nodeToCompressPNGBlob(
                command,
                [singleSelectedNodeId],
                {
                    type: Wukong.DocumentProto.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_SCALE,
                    value: 1,
                },
                Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B
            )
            const node = chatbotService.getAISearchNode(singleSelectedNodeId)
            if (blob && node) {
                const file = new File([blob], 'node.png', { type: 'image/png' })
                model.setSearchInfo({
                    type: 'NODE',
                    file,
                    node,
                    searchFilter: {
                        resourceType: AiSearchResourceType.ORGANIZATION,
                        fileOwnerCondition: AISearchFileOwnerCondition.ANY_USER,
                        sortingRule: AISearchSortingRule.SIMILARITY,
                    },
                })
                return true
            }
        }
        return false
    }, [chatbotService, command, model, singleSelectedNodeId])

    useMount(async () => {
        if (searchType === 'imageUrl' && initByImage()) {
            return
        }

        if (searchType === 'selectedNode' && (await initByNode())) {
            return
        }

        // 优先根据 ai 的 searchType 进行初始化，如果未成功则默认优先 image
        if (!initByImage()) {
            initByNode()
        }
    })

    if (model.init) {
        return (
            <ChatbotDialogContainer
                size={'large'}
                title={'loading...'}
                showReturnButton={true}
                onClose={() => chatbotService.closeModal()}
                onReturn={() => chatbotService.returnPage()}
            >
                {null}
            </ChatbotDialogContainer>
        )
    }

    return <ChatbotAISearchResult model={model} />
}

function ChatbotAISearchResult({ model }: { model: AiSearchResultModel }) {
    const chatbotService = useAppContext().chatbotService

    const handleClickReturn = () => {
        if (!model.currentFile) {
            chatbotService.returnPage()
        } else {
            model.onBack()
        }
    }

    return (
        <ChatbotDialogContainer
            size={'large'}
            title={<AISearchModalTitle model={model} />}
            showReturnButton={true}
            onClose={() => chatbotService.closeModal()}
            onReturn={handleClickReturn}
        >
            <div style={{ display: model.currentFile ? 'none' : 'block' }}>
                <AiSearchResultBody model={model} resultHeight={650} />
            </div>

            {model.currentFile ? <AiSearchResultFileDetail file={model.currentFile} model={model} /> : null}
        </ChatbotDialogContainer>
    )
}

const useCurrentDialogue = () => {
    const currentDialogue = useAppContext().chatbotService.stateStore.use.pageStack().at(-1)?.sourceDialogue
    const uploadedImage = currentDialogue?.questionInput.messages
        .at(-1)!
        .content.find((content) => content.image)?.image
    const uploadedImageFile = uploadedImage ? base64ToFile(uploadedImage, 'search.png', 'image/png') : null
    const selectedNodeIds = currentDialogue?.questionInput.nodeIds
    const singleSelectedNodeId = selectedNodeIds?.length === 1 ? selectedNodeIds[0] : null
    const searchType = currentDialogue?.questionAnswer.ops.find((op) => 'searchType' in op)?.searchType

    return {
        uploadedImageFile,
        singleSelectedNodeId,
        searchType,
    }
}

function base64ToFile(base64String: string, fileName: string, mimeType: string): File {
    const base64Data = base64String.split(',')[1]
    const byteCharacters = atob(base64Data)
    const byteNumbers = new Array(byteCharacters.length)

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    return new File([byteArray], fileName, { type: mimeType })
}

function AISearchModalTitle({ model }: { model: AiSearchResultModel }) {
    const { searchInfo, currentFile } = model
    if (currentFile) {
        return (
            <div className="text-black overflow-hidden text-ellipsis whitespace-nowrap">
                {currentFile.documentVO.name}
            </div>
        )
    }

    switch (searchInfo.type) {
        case 'IMAGE':
            return (
                <ModalTitleSearchInfo
                    prefix={translation('ImagePrefix')}
                    suffix={translation('ImageSuffix')}
                    icon={<MulticolorIconCommonPic16 className="flex-nonem mr-4px" />}
                    name={searchInfo.file.name}
                />
            )
        case 'NODE':
            return (
                <ModalTitleSearchInfo
                    prefix={translation('LayerPrefix')}
                    suffix={translation('LayerSuffix')}
                    icon={<AISearchNodeIcon node={searchInfo.node} className="flex-none mr-2px" />}
                    name={searchInfo.node.name}
                />
            )
    }
}

function ModalTitleSearchInfo({
    prefix,
    suffix,
    icon,
    name,
}: {
    prefix: string
    suffix: string
    icon: React.ReactNode
    name: string
}) {
    return (
        <div className="flex flex-row justify-center items-center overflow-hidden">
            <div>{translation('ResultsFor')}</div>
            <div className="flex flex-row items-center overflow-hidden">
                <div className="flex-none">{prefix}</div>
                <div className="h-20px overflow-hidden flex flex-row items-center">
                    {icon}
                    <span className="truncate">{name}</span>
                </div>
                <div className="flex-none">{suffix}</div>
            </div>
        </div>
    )
}
