/**
 * @owner: chenxiangbj@kanyun.com
 */
import { CSSProperties, forwardRef, useMemo } from 'react'
// eslint-disable-next-line import/no-restricted-paths
import { UserBriefVO } from '../../../../app/src/kernel/interface/type'
import { AvatarDefaultSVG } from './default-svg'
import { getDisplayName, WKAvatar, WKAvatarProps, WKAvatarRef } from './wk-avatar'

export type WKUserAvatarProps = {
    userInfo: Pick<UserBriefVO, 'avatarBackgroundColor' | 'avatarId' | 'nickname' | 'waitRegister'>
    style?: CSSProperties
    backgroundColor?: string
    useDefault?: boolean
} & Omit<WKAvatarProps, 'type' | 'backgroundColor' | 'name' | 'imageId'>

export type WKUserAvatarRef = WKAvatarRef

export const WKUserAvatar = forwardRef(function Component(props: WKUserAvatarProps, ref?: WKUserAvatarRef) {
    const { userInfo, style, useDefault, backgroundColor, ...rest } = props
    const waitRegisetBorder = useMemo(() => {
        if (userInfo.waitRegister) {
            return {
                border: '1px dashed var(--wk-gray-13-15)',
                borderRadius: 'var(--wk-radius-circle)',
            }
        }
        return {}
    }, [userInfo.waitRegister])
    const innerContent = useMemo(() => {
        if (userInfo.waitRegister) {
            return <></>
        }
        if (useDefault) {
            return <AvatarDefaultSVG />
        }
        const displayName = getDisplayName(userInfo.nickname)
        // 不是中文或字母开头，就展示白色小人
        const isChineseOrLetter = /^([\u4E00-\u9FA5]|[a-zA-Z]){1,1}/.test(displayName)
        if (!isChineseOrLetter) {
            return <AvatarDefaultSVG />
        }
        return undefined
    }, [useDefault, userInfo.nickname, userInfo.waitRegister])
    return (
        <WKAvatar
            ref={ref}
            type={'rounded'}
            innerContent={innerContent}
            backgroundColor={backgroundColor ?? userInfo.avatarBackgroundColor}
            name={userInfo.nickname}
            imageId={userInfo.avatarId}
            style={{ ...style, ...waitRegisetBorder }}
            {...rest}
        />
    )
})
