import { debounce, DebounceSettings, throttle, ThrottleSettings } from 'lodash-es'
import { TraceableAbortSignal } from './traceable-abort-controller'
import { createSignalSwitch } from './traceable-signal-switch'

/**
 * @experimental 用于替换rxjs的switchMap
 */
export const createSwitchTask = <T extends unknown[], R>(
    signal: TraceableAbortSignal,
    task: (signal: TraceableAbortSignal, ...args: T) => Promise<R>
) => {
    const signalSwitch = createSignalSwitch(signal)

    return signalSwitch(async (signal, ...args: T) => {
        return await task(signal, ...args)
    })
}

/**
 * @experimental 用于替换rxjs的debounce + switchMap
 */
export const createDebouncedSwitchTask = <T extends unknown[], R>(
    signal: TraceableAbortSignal,
    task: (signal: TraceableAbortSignal, ...args: T) => Promise<R>,
    delay: number,
    options?: DebounceSettings
) => {
    const debouncedTask = debounce(createSwitchTask(signal, task), delay, options)

    signal.addEventListener('abort', () => {
        debouncedTask.cancel()
    })

    return debouncedTask
}

/**
 * @experimental 用于替换rxjs的concatMap / mergeMap with concurrency parameter set to 1
 */
export const createPendingTaskExecutor = (signal: TraceableAbortSignal) => {
    let isExecuting = false
    let pendingTask: ((signal: TraceableAbortSignal) => Promise<void>)[] = []

    signal.addEventListener('abort', () => {
        pendingTask = []
    })

    const executeTask = async () => {
        if (isExecuting) {
            return
        }

        if (pendingTask.length) {
            const task = pendingTask.shift()
            if (task) {
                isExecuting = true

                await task(signal)

                isExecuting = false
                executeTask()
            }
        }
    }

    const addTask = (task: (signal: TraceableAbortSignal) => Promise<void>) => {
        pendingTask.push(task)
        executeTask()
    }

    return { addTask }
}

export const signalDebounce = <T extends (...args: any) => any>(
    signal: TraceableAbortSignal,
    func: T,
    wait?: number,
    options?: DebounceSettings
) => {
    const debouncedFunc = debounce(func, wait, options)

    signal.addEventListener('abort', () => {
        debouncedFunc.cancel()
    })

    return debouncedFunc
}

export const signalThrottle = <T extends (...args: any) => any>(
    signal: TraceableAbortSignal,
    func: T,
    wait?: number,
    options?: ThrottleSettings
) => {
    const throttledFunc = throttle(func, wait, options)

    signal.addEventListener('abort', () => {
        throttledFunc.cancel()
    })

    return throttledFunc
}
