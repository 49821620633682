import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    EffectSettings: '效果设置',
    DetachStyle: '分离样式',
    SelectItemsUsing: '选中使用该样式的 {{usingNodeCount}} 个图层',
} as const

export const enTranslation = {
    EffectSettings: 'Effect settings',
    DetachStyle: 'Detach style',
    SelectItemsUsing: 'Select {{usingNodeCount}} {{item}} using this style',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
