import { WKButton } from '../../../../../../ui-lib/src'

export function SelectingButton({
    icon,
    children,
    onClick,
    isSelected,
    extraClass,
}: {
    icon?: React.ReactNode
    children: React.ReactNode
    onClick: () => void
    isSelected: boolean
    extraClass?: string
}) {
    return (
        <WKButton
            icon={icon}
            type="text"
            className={`bg-[var(--wk-gray-1)] cursor-default hover:text-black ${
                isSelected
                    ? 'wk-focus-outline-inset bg-[var(--wk-brand-1)]! wk-font-medium text-black hover:text-black'
                    : 'wk-font-regular'
            } ${extraClass ?? ''} m-0`}
            style={{ margin: 0 }}
            onClick={onClick}
        >
            {children}
        </WKButton>
    )
}
