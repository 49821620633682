import { forwardRef, useImperativeHandle, useRef } from 'react'
import { ExplicitUndefined } from '../../../utils/explicit-undefined'
import { CustomNode } from '../../selects/picks/multi-level/type'
import { MultiLevel, MultiLevelProps, MultiLevelRef } from '../picks/multi-level/multi-level'
import { DropdownDefaultRenderItem } from './dropdown-default-custom-render'

export interface DropdownNoTriggerMultiLevelRef {
    open: () => void
    close: () => void
}
export type DropdownNoTriggerMultiLevelProps<T extends CustomNode> = Omit<
    MultiLevelProps<T>,
    'isMinWidthFromTrigger' | 'width' | 'minWidth' | 'renderItem'
> &
    Partial<Pick<MultiLevelProps<T>, 'renderItem'>>

function _DropdownNoTriggerMultiLevel<T extends CustomNode>(
    props: DropdownNoTriggerMultiLevelProps<T>,
    ref?: React.Ref<DropdownNoTriggerMultiLevelRef>
) {
    const pickRef = useRef<MultiLevelRef>(null)
    useImperativeHandle(ref, () => ({ open: () => pickRef.current?.open(), close: () => pickRef.current?.close() }), [])

    return (
        <MultiLevel<T, ExplicitUndefined<MultiLevelProps<T>>>
            pickRest={props.pickRest}
            ref={pickRef}
            dataTestIds={props.dataTestIds}
            items={props.items}
            placement={props.placement ?? 'right top'}
            maxWidth={props.maxWidth}
            worldRect={props.worldRect}
            isOpenState={props.isOpenState}
            onKeyboard={props.onKeyboard}
            onChange={props.onChange}
            renderItemPrev={props.renderItemPrev}
            renderItem={props.renderItem ?? DropdownDefaultRenderItem}
            renderItemNext={props.renderItemNext}
            openStateToBeFalse={props.openStateToBeFalse}
            openStateToBeTrue={props.openStateToBeTrue}
            closeByESC={props.closeByESC}
            triggerRect={props.triggerRect}
            onOpen={props.onOpen}
            onClose={props.onClose}
            getPortalElement={props.getPortalElement}
            removeMask={props.removeMask}
            removeTopPadding={props.removeTopPadding}
            removeBottomPadding={props.removeBottomPadding}
            autoAdjustTop={true}
            isMinWidthFromTrigger={undefined}
            minWidth={undefined}
            width={undefined}
        />
    )
}

export const DropdownNoTriggerMultiLevel = forwardRef(_DropdownNoTriggerMultiLevel) as <
    T extends CustomNode = CustomNode
>(
    props: DropdownNoTriggerMultiLevelProps<T> & { ref?: React.Ref<DropdownNoTriggerMultiLevelRef> }
) => React.ReactElement
