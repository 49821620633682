import { RouteToken, domLocation } from '../../../../../util/src'
import { ResourceType } from '../../../kernel/interface/type'
import { GetContractSettlementV2 } from '../../../kernel/request/contract'
import { SpaceNotifyFlow } from './notify-flow'
import { SpaceStoreType, getSpaceStore, setSpaceStore, type OrganizationManageSettlementStore } from './types'

// 企业版-账单信息
export const generateOrganizationManageSettlementState = (
    set: setSpaceStore,
    get: getSpaceStore
): OrganizationManageSettlementStore => {
    return {
        data: undefined,
        loading: true,
        _fetchData: async () => {
            const orgId = get().organizationStore.organization.id
            if (orgId === '-1') {
                return
            }

            const settlement = await new GetContractSettlementV2(orgId, ResourceType.Organization)
                .start()
                .catch(() => undefined)
            if (!settlement) {
                return
            }

            set((state) => {
                state.organizationManageSettlementStore.data = settlement
            })
        },
        fetchData: async () => {
            set((state) => {
                state.organizationManageSettlementStore.loading = true
            })
            await get().organizationManageSettlementStore._fetchData()
            set((state) => {
                state.organizationManageSettlementStore.loading = false
            })
        },
    }
}

const isInOrganizationSettlementPage = () => domLocation().pathname.includes(`${RouteToken.Admin}/settlement`)

export const syncOrganizationManageSettlementState = (store: SpaceStoreType, notifyFlow: SpaceNotifyFlow) => {
    notifyFlow.addRelationChangeCallbackWithoutAccess(() => {
        if (isInOrganizationSettlementPage()) {
            store.getState().organizationManageSettlementStore._fetchData()
        }
    })

    notifyFlow.addPropertyChangeCallbackWithoutDocAndUser(() => {
        if (isInOrganizationSettlementPage()) {
            store.getState().organizationManageSettlementStore._fetchData()
        }
    })
}
