import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    AdvancedStrokeSettings: '更多设置',
} as const

export const enTranslation = {
    AdvancedStrokeSettings: 'Advanced stroke settings',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
