import { PopupStateType } from '../../../../../../document/node/node'
import { ComponentPropNodeField, type ComponentPropEditPopupVal } from '../../types'
import { useComponentProp } from '../component-prop/hook'
import { BaseComponentPropPill, convertRealComponentPropType2Virtual } from './base-component-prop-pill'
import style from './index.module.less'

export function TextComponentPropPill() {
    const {
        showAddZombiePropBtnOfText,
        sharedTextPropDefOfSelectedNodes,
        componentPropDefs,
        showNormalPillOfText,
        showDisabledPillOfText,
        showZombiePillOfText,
        detachProp,
        applyProp,
        createAndApplyProp,
        locateComponentProp,
        locateInstanceOfRef,
        toggleComponentPropPopup,
    } = useComponentProp()

    const showPill = showNormalPillOfText || showDisabledPillOfText || showZombiePillOfText

    const getPillStatus = () => {
        if (showNormalPillOfText) {
            return 'normal'
        }
        if (showDisabledPillOfText) {
            return 'disabled'
        }
        if (showZombiePillOfText) {
            return 'zombie'
        }
        return 'normal'
    }

    if (!showPill) {
        return null
    }

    return (
        <BaseComponentPropPill
            className={{ container: style.textPropPillContainer }}
            dataTestId="text-prop-pill"
            propDefs={componentPropDefs}
            displayProp={sharedTextPropDefOfSelectedNodes}
            pillStatus={getPillStatus()}
            zombiePillAddBtn={showAddZombiePropBtnOfText}
            detachProp={() => {
                detachProp(sharedTextPropDefOfSelectedNodes.detachDatas)
            }}
            applyProp={(defId: string) => {
                applyProp(ComponentPropNodeField.COMPONENT_PROP_NODE_FIELD_TEXT_DATA, defId)
            }}
            createProp={() => {
                toggleComponentPropPopup(PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_TEXT_PROP_OF_TEXT_AREA)
            }}
            editProp={() => {
                const shouldOpenEditPopup = locateComponentProp(sharedTextPropDefOfSelectedNodes.editedPropId)
                if (shouldOpenEditPopup.value) {
                    toggleComponentPropPopup(PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_TEXT_PROP, {
                        id: sharedTextPropDefOfSelectedNodes.editedPropId,
                        name: sharedTextPropDefOfSelectedNodes.name,
                        type: convertRealComponentPropType2Virtual(sharedTextPropDefOfSelectedNodes.type),
                        varValue: sharedTextPropDefOfSelectedNodes.varValue,
                    } as ComponentPropEditPopupVal)
                }
            }}
            zombiePillAddProp={() => [
                detachProp(sharedTextPropDefOfSelectedNodes.detachDatas),
                createAndApplyProp({
                    name: sharedTextPropDefOfSelectedNodes.name,
                    type: sharedTextPropDefOfSelectedNodes.type,
                    value: sharedTextPropDefOfSelectedNodes.varValue.value,
                }),
            ]}
            disabledPillLocateProp={() => {
                locateInstanceOfRef(sharedTextPropDefOfSelectedNodes.editedPropId)
            }}
        />
    )
}
