/* eslint-disable no-restricted-imports */
import { DropImageCommand, Wukong } from '@wukong/bridge-proto'
import { CommandInvoker } from '../../../document/command/command-invoker'
import { cmdDropImages } from '../../../document/command/image-command'
import { Handler } from '../../../main/handler-provider/handler-provider'
import { getIconFile } from '../plugin/iconpark/utils/drag-util'

export enum DropType {
    Iconpark = 'iconpark',
}

/**
 * 获取鼠标落点相对于dom元素的偏移
 */
function getOffset(evt: React.DragEvent) {
    const targetRect = evt.currentTarget.getBoundingClientRect()
    return {
        x: evt.clientX - targetRect.x,
        y: evt.clientY - targetRect.y,
    }
}

export const dropToCanvasHandlerV2: Handler<React.DragEvent> = (ctx, evt) => {
    const dataTransfer = evt.dataTransfer

    switch (dataTransfer.getData('type')) {
        case DropType.Iconpark: {
            const svgFile = getIconFile(dataTransfer)
            tryCreateSVGComponentNode(ctx.commandInvoker, evt, svgFile)
            return
        }

        default: {
            const dropOffset = getOffset(evt)

            if (dataTransfer.files.length) {
                ctx.commandInvoker.invoke(
                    cmdDropImages,
                    ctx.imageDownloadContext,
                    dataTransfer.files,
                    dropOffset.x!,
                    dropOffset.y!
                )
            }
        }
    }
}

const tryCreateSVGComponentNode = async (command: CommandInvoker, e: React.DragEvent, svgFile: File) => {
    const canvasRect = e.currentTarget.getBoundingClientRect()

    const dropOffset = {
        x: e.clientX - canvasRect.x,
        y: e.clientY - canvasRect.y,
    }

    await dropSVG(command, svgFile, dropOffset.x, dropOffset.y)
    // 拖拽放置时有概率不触发鼠标抬起事件，从而可能没 commitUndo 导致 undo/redo 操作粘连，这里手动 commit undo
    command.commitUndo()
}

async function dropSVG(command: CommandInvoker, file: File, canvasX: number, canvasY: number) {
    const imageName = file.name
    const ab = await file.arrayBuffer()

    const imageInfo = Wukong.DocumentProto.ImageInfo.create({
        imageName,
        svgBlob: new Uint8Array(ab),
    })

    command.DEPRECATED_invokeBridge(
        DropImageCommand,
        Wukong.DocumentProto.DropImageParam.create({
            imageInfos: [imageInfo],
            canvasX,
            canvasY,
        })
    )
}
