import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    'AreYouSure?': '确定要删除评论？',
    AreYouSure: '你将删除该评论及其所有回复。',
    Delete: '删除',
} as const

export const enTranslation = {
    'AreYouSure?': 'Are you sure you want to delete this comment?',
    AreYouSure: 'This will delete any replies as well.',
    Delete: 'Delete',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
