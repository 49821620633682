import { UpdatePrototypeBackgroundColorCommand } from '@wukong/bridge-proto'
import { useCallback } from 'react'
import { cmdChangePopupState } from '../../../../document/command/document-command'
import { PopupStateType, RGB, SolidPaint } from '../../../../document/node/node'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { usePosition } from '../../../utils/use-position'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { Value } from '../../atom/inputs/utils/type'
import { useRenderColorSpace } from '../../color-profile'

export const usePrototypeBackgroundColor = () => {
    const state = useViewState('prototypeBackgroundColor')
    const command = useCommand()

    const popupState = useViewState('popupState')
    const isOpen =
        popupState && popupState.type === PopupStateType.POPUP_STATE_TYPE_CURRENT_PAGE_PROTOTYPE_BACKGROUND_EDIT
    const { position, htmlRef } = usePosition({ isOpen, outRef: undefined })
    const colorSpace = useRenderColorSpace()
    const paint = state?.paint as SolidPaint
    const onChangeModalVisible = useCallback(
        (visible: boolean) => {
            if (visible) {
                command.invoke(cmdChangePopupState, {
                    type: PopupStateType.POPUP_STATE_TYPE_CURRENT_PAGE_PROTOTYPE_BACKGROUND_EDIT,
                    reciprocalIndex: -1,
                    multiPopup: [],
                })
            } else {
                command.invoke(cmdChangePopupState, {
                    type: PopupStateType.POPUP_STATE_TYPE_NONE,
                    reciprocalIndex: -1,
                    multiPopup: [],
                })
            }
        },
        [command]
    )
    const onClose = useCallback(() => {
        onChangeModalVisible(false)
    }, [onChangeModalVisible])
    const onClickOpenModal = useCallback(() => {
        onChangeModalVisible?.(!isOpen)
    }, [isOpen, onChangeModalVisible])
    const onChangeSolidPaint = useCallback(
        (value: SolidPaint) => {
            command.DEPRECATED_invokeBridge(UpdatePrototypeBackgroundColorCommand, { paint: value })
        },
        [command]
    )

    const _onChangeSolidPaint = useCallback(
        <T extends keyof SolidPaint, V = SolidPaint[T]>(key: T, value: V) => {
            if (key == 'visible') {
                onChangeSolidPaint({ ...paint, [key]: value })
            } else {
                // NOTE: 修改Paint后自动更新为可见
                onChangeSolidPaint({ ...paint, [key]: value, visible: true })
            }
        },
        [onChangeSolidPaint, paint]
    )

    const _onChangeColor = useCallback(
        (value: RGB, options?: InputOptionsForUndoSquash) => {
            _onChangeSolidPaint('color', value)
            command.commitUndo(options?.commitType)
        },
        [_onChangeSolidPaint, command]
    )

    const onChangeOpacity = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            _onChangeSolidPaint('opacity', value)
            command.commitUndo(options?.commitType)
        },
        [_onChangeSolidPaint, command]
    )

    return {
        _onChangeColor,
        onChangeOpacity,
        onClickOpenModal,
        onClose,
        paint,
        colorSpace,
        position,
        htmlRef,
        isOpen,
        onChangeModalVisible,
    }
}
