import { MouseEvent, ReactNode } from 'react'
import type { PopupPosition } from '../popup/type'

export interface WKDividerMenuItem {
    type: WKItemType.Divider
    hidden?: boolean | (() => boolean)
    testId?: string
    key?: string
    autoCommit?: boolean // 为了 WKMenuItemType 能够添加 autoCommit 属性
}

export interface WKGroupHeaderMenuItem {
    type: WKItemType.GroupHeader
    name: string
    hidden?: boolean | (() => boolean)
    testId?: string
    key?: string
    className?: string
    autoCommit?: boolean // 为了 WKMenuItemType 能够添加 autoCommit 属性
}

export interface WKMenuItem {
    linkInfo?: { href: string }
    name: string
    forwardIcon?: ReactNode
    key?: string
    activity?: boolean
    hidden?: boolean | (() => boolean)
    position: PopupPosition
    children: WKMenuConfig
    testId?: string
    showCheckBoxAndForwardIcon?: boolean
    isLocked?: boolean
    autoCommit?: boolean
}

export interface WKSubMenuItem extends Omit<WKMenuItem, 'children' | 'position'> {
    shortCut?: string
    disable?: boolean
    activity?: boolean
    handler: (e?: MouseEvent<HTMLDivElement> | KeyboardEvent) => void
    mouseEnterHandler?: (e?: MouseEvent<HTMLDivElement>) => void
    mouseLevelHandler?: (e?: MouseEvent<HTMLDivElement>) => void
}

export type WKMenuItemType = WKMenuItem | WKSubMenuItem | WKDividerMenuItem | WKGroupHeaderMenuItem
export type WKMenuConfig = Array<WKMenuItemType>

export const enum WKItemType {
    Divider,
    GroupHeader,
}
