import type { UserVOV2 } from '../../../kernel/interface/account'
import type { Lifecycle } from '../chore'
import { AppState, LifecycleContext } from '../types'
import type { EditorContextUnion, MirrorContext } from '../types/editor'

/**
 * 业务可通过 lifecycle 提供的接口来与生命周期产生交互，注入 lifecycle 的所需的资源
 * 目前业务可访问的切换生命周期状态的接口：
 * 1. startApp 用于开启生命周期
 * 2. logout 用于登出
 * 3. destroyEditor 用于返回工作台
 */
class LifecycleInterface<T extends EditorContextUnion> {
    private lifecycle: Lifecycle<T> = {
        getContext() {
            return {}
        },
        getCurrentState() {
            return AppState.CreateApp
        },
    } as Lifecycle<T>

    /**
     * 开始创建 App
     * appState: BeforeCreate
     */
    public startApp(lifecycle: Lifecycle<T>): void {
        this.lifecycle = lifecycle
        this.lifecycle.beginStartAppFlow()
    }

    /**
     * 使生命周期回退到 login 阶段，应对重新登录的流程
     */
    public logout(): void {
        this.lifecycle.resetToBeginLoginFlow()
    }

    /**
     * 注入 userInfo
     */
    public injectUserInfo(userInfo: UserVOV2): void {
        this.lifecycle.injectUserInfo(userInfo)
    }

    /**
     * 获取 context, readonly
     * @returns
     */
    public getContext(): Readonly<Partial<LifecycleContext<T>>> {
        return this.lifecycle.getContext()
    }

    /**
     * 获取当前的 AppState
     * @returns
     */
    public getCurrentState(): AppState {
        return this.lifecycle.getCurrentState()
    }
}

export const mirrorPreviewLifecycleInterface = new LifecycleInterface<MirrorContext>()
