import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    GoToMain: '跳转至组件母版',
    ShowWhenPublishing: '发布时展示该组件',
    HideWhenPublishing: '发布时隐藏该组件',
    KWgoOK: '未定义菜单项',
} as const

export const enTranslation = {
    GoToMain: 'Go to main component',
    ShowWhenPublishing: 'Show when publishing',
    HideWhenPublishing: 'Hide when publishing',
    KWgoOK: 'Unknown',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
