import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router'
import { useFeatureSwitch } from '../../../../kernel/switch/hooks'
import { useAppContext } from '../../../../main/app-context'
import { WK } from '../../../../window'
import { useCreateBaseFrame } from './create-base-frame'
import { enExamples, zhExamples } from './use-ai-gen-ui-example-prompts'
import { AIGenStreamInput } from './util'

interface ExtraToolsParams {
    examples: string[]
    applyPrompt: (input: Omit<AIGenStreamInput, 'getStyleConfig'>) => Promise<unknown>
    setWaitForResult: (s: boolean) => void
    configs: Array<number | undefined>
    onFinish: () => unknown
}

export function useTempHtmlPrototypeTools() {
    const aiService = useAppContext().aiService
    const visible = aiService.useZustandStore.use.showAIGenPrototypeModal()
    const html = aiService.useZustandStore.use.aiGenPrototypeHtml()
    const loading = aiService.useZustandStore.use.aiGenPrototypeLoading()
    const close = useCallback(() => {
        aiService.closeAIGenPrototypeModal()
    }, [aiService])
    return { visible, html, close, loading } as const
}

export function useAIGenExtraTools({ examples, applyPrompt, setWaitForResult, configs, onFinish }: ExtraToolsParams) {
    const params = useParams()
    const docId = params.docId || ''
    const aiGenBatchOp = useFeatureSwitch('ai-gen-ui-batch-op')
    const createBaseFrame = useCreateBaseFrame()

    useEffect(() => {
        if (aiGenBatchOp) {
            WK.aiGenUIJob = async (
                inputPrompts: string[] | null = null,
                batch = 10,
                placeDirection: 'H' | 'V' | null = null
            ) => {
                let prompts: string[] | null = inputPrompts
                if (prompts === null) {
                    const children = motiff.currentPage.children
                    const [jsonPrompt] = children
                        .filter((node) => node.name.toLowerCase() === 'json prompt' && node.type === 'TEXT')
                        .map((node) => (node as unknown as ReturnType<typeof motiff.createText>).characters.trim())
                    if (jsonPrompt) {
                        const originData = JSON.parse(jsonPrompt)
                        if (Array.isArray(originData)) {
                            const noDup = new Set(originData.map((data) => data.prompt.trim() as string))
                            for (const example in [...examples, ...enExamples, ...zhExamples]) {
                                noDup.delete(example)
                            }
                            prompts = Array.from(noDup.values())
                        }
                    }
                }
                if (prompts === null) {
                    const children = motiff.currentPage.children
                    const promptChildren = children.filter(
                        (node) => node.name.startsWith('Prompt') && node.type === 'TEXT'
                    )
                    prompts = promptChildren
                        .map((node) => node as unknown as ReturnType<typeof motiff.createText>)
                        .map((text) => text.characters.trim())
                }
                setWaitForResult(true)
                const withDefaultConfig = configs.length === 0 ? [undefined] : configs
                for (const config of withDefaultConfig) {
                    const promptWithFrame = await Promise.all(
                        prompts.map(async (eachPrompt) => {
                            const alreadyPlacedFrame = await createBaseFrame(eachPrompt, 1440, 844)
                            return {
                                eachPrompt,
                                alreadyPlacedFrame,
                            } as const
                        })
                    )
                    const allFrames = promptWithFrame.map(
                        ({ alreadyPlacedFrame }) =>
                            motiff.getNodeById(
                                alreadyPlacedFrame
                            ) as unknown as typeof motiff.currentPage.children[number] as ReturnType<
                                typeof motiff.createFrame
                            >
                    )
                    if (placeDirection) {
                        const autoLayoutFrame = motiff.createFrame(true)
                        autoLayoutFrame.resize(
                            placeDirection === 'H'
                                ? allFrames.reduce((v, frame) => v + frame.width, 0)
                                : allFrames[0].width,
                            placeDirection === 'V'
                                ? allFrames.reduce((v, frame) => v + frame.height, 0)
                                : allFrames[0].height
                        )
                        autoLayoutFrame.x = allFrames[0].x
                        autoLayoutFrame.y = allFrames[0].y
                        autoLayoutFrame.name = `Config ID: ${config ?? 'prod config'}`
                        autoLayoutFrame.layoutMode = placeDirection === 'V' ? 'VERTICAL' : 'HORIZONTAL'
                        autoLayoutFrame.itemSpacing = 10
                        // autoLayoutFrame.layoutSizingHorizontal = 'FIXED'
                        for (const frame of allFrames) {
                            autoLayoutFrame.appendChild(frame)
                        }
                    }
                    for (let i = 0; i < prompts.length; i += batch) {
                        const chunkedPrompts = promptWithFrame.slice(i, i + batch)
                        await Promise.allSettled(
                            chunkedPrompts.map(async ({ eachPrompt, alreadyPlacedFrame }) => {
                                await applyPrompt({
                                    docId,
                                    prompt: eachPrompt,
                                    baseFrame: alreadyPlacedFrame,
                                    configId: config,
                                    promptImageUrl: null,
                                    prefix: 'Config ID: ' + config,
                                })
                                    .catch(() => {
                                        console.error('AI Gen Batch Task Failed: ', {
                                            docId,
                                            prompt: eachPrompt,
                                            configId: config,
                                        })
                                        return applyPrompt({
                                            docId,
                                            prompt: eachPrompt,
                                            baseFrame: alreadyPlacedFrame,
                                            configId: config,
                                            promptImageUrl: null,
                                            prefix: 'Config ID: ' + config,
                                        })
                                    })
                                    .catch(() => {
                                        console.error('AI Gen Batch Retry Task Failed: ', {
                                            docId,
                                            prompt: eachPrompt,
                                            configId: config,
                                        })
                                    })
                                return alreadyPlacedFrame
                            })
                        )
                    }
                }
                onFinish()
                setWaitForResult(false)
            }
            return () => {
                WK.aiGenUIJob = undefined
            }
        }
    }, [aiGenBatchOp, applyPrompt, createBaseFrame, docId, examples, setWaitForResult, configs, onFinish])
}
