import { translation } from './use-doc-thumbnail.translation'
/* eslint-disable no-restricted-imports */
import { SetThumbnailIdWasmCall, Wukong } from '@wukong/bridge-proto'
import { useCallback, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useDeepCompareEffect } from 'react-use'
import { DeepRequired, useViewState } from '../../view-state-bridge'

import { WKMenuItemType, WKToast } from '../../../../ui-lib/src'

import { useCommand } from '../context/document-context'

import NodeType = Wukong.DocumentProto.NodeType

import { debounce, isNil } from 'lodash-es'
import { PutThumbnailNodeId } from '../../kernel/request/document'

// 保留两位小数
export const toFixed = (num: number) => {
    return Math.round(num * 100) / 100
}
const debouncePutThumbnailNodeId = debounce((docId: string, thumbnailId: string, canvasBackgroundColor: string) => {
    new PutThumbnailNodeId(docId, thumbnailId, canvasBackgroundColor).start().catch((error) => {
        console.warn(error)
    })
}, 1000)
export const useDocThumbnail = () => {
    const docId = useParams().docId!
    const thumbnail = useViewState('thumbnail')
    const docReadonly = useViewState('docReadonly')
    const upload = useCallback(
        async (_thumbnail: DeepRequired<Wukong.DocumentProto.IThumbnail>) => {
            try {
                let canvasBackgroundColor = ''
                const { visible, color, opacity } = _thumbnail.thumbnailPaint
                const { r, g, b } = color
                if (visible) {
                    canvasBackgroundColor = JSON.stringify({
                        r: toFixed(r / 255),
                        g: toFixed(g / 255),
                        b: toFixed(b / 255),
                        a: toFixed(opacity),
                        width: toFixed(_thumbnail.width ?? 0),
                        height: toFixed(_thumbnail.height ?? 0),
                    })
                }
                debouncePutThumbnailNodeId(docId, _thumbnail.id, canvasBackgroundColor)
            } catch (e) {}
        },
        [docId]
    )
    const flag = useRef(false)
    useDeepCompareEffect(() => {
        if (docReadonly) {
            return
        }
        if (isNil(thumbnail)) {
            return
        }
        // 首次打开不上传
        if (flag.current) {
            upload(thumbnail)
        } else {
            flag.current = true
        }
    }, [thumbnail, upload])
}

export const useDocThumbnailMainMenuItem = (): WKMenuItemType => {
    const thumbnail = useViewState('thumbnail')
    const thumbnailId = thumbnail?.id
    const singleSelection = useViewState('singleSelection')
    const singleSelectionId = singleSelection?.id
    const singleSelectionType = singleSelection?.type
    const selectionLength = singleSelection?.length
    const commandInvoker = useCommand()

    const DisableItem: WKMenuItemType = {
        name: translation('RestoreDefaultThumbnail'),
        disable: true,
        handler: () => {},
    }
    const SetDefaultThumbnail: WKMenuItemType = useMemo(() => {
        return {
            name: translation('RestoreDefaultThumbnail'),
            handler: () => {
                commandInvoker.DEPRECATED_invokeBridge(SetThumbnailIdWasmCall, { value: undefined })
                WKToast.show(translation('DefaultThumbnailRestored'))
            },
        }
    }, [commandInvoker])
    if (!selectionLength) {
        if (thumbnailId) {
            return SetDefaultThumbnail
        } else {
            return DisableItem
        }
    } else if (selectionLength === 1) {
        if (thumbnailId === singleSelectionId) {
            return SetDefaultThumbnail
        } else {
            const arr = [NodeType.NODE_TYPE_FRAME, NodeType.NODE_TYPE_COMPONENT, NodeType.NODE_TYPE_INSTANCE]
            if (arr.includes(singleSelectionType!)) {
                return {
                    name: translation('SetAsThumbnail'),
                    handler: () => {
                        commandInvoker.DEPRECATED_invokeBridge(SetThumbnailIdWasmCall, {
                            value: singleSelectionId,
                        })
                        WKToast.show(translation('ThumbnailSet'))
                    },
                }
            } else {
                return DisableItem
            }
        }
    } else {
        return DisableItem
    }
}
