/* eslint-disable no-restricted-imports */
import { UpdateSelectionStrokeDashLenCommand } from '@wukong/bridge-proto'
import { useCallback } from 'react'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { Value } from '../../../atom/inputs/utils/type'

export function useStrokeDashLen() {
    const command = useCommand()
    const selectionStroke = useViewState('selectionStrokeV2')
    const dashLenState = selectionStroke?.dashLenState
    const onChangeDashLen = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value !== 'number') {
                return
            }

            command.DEPRECATED_invokeBridge(UpdateSelectionStrokeDashLenCommand, { strokeDashLen: value })
            command.commitUndo(options?.commitType)
        },
        [command]
    )

    return {
        dashLenState,
        onChangeDashLen,
    }
}
