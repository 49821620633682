import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { isNil } from 'lodash-es'
import { useMemo } from 'react'
import {
    CustomItemProps,
    MonoIconPanelArrowDown16,
    MonoIconPanelArrowLeft16,
    MonoIconPanelArrowRight16,
    MonoIconPanelArrowUp16,
    Select,
    WKDivider,
} from '../../../../../../../../ui-lib/src'
import { featureSwitchManager } from '../../../../../../kernel/switch/core'
import { PrototypeTestId } from '../../../../../../window'
import { SelectIconGroup } from '../../../../atom/button/select-button-group'
import {
    InteractionActionType,
    MIXED_TYPE,
    type InteractionActionTypeWithMixed,
    type InteractionTypeWithMixed,
} from '../../../prototype-interaction/constants'
import { getSingleActionType } from '../../../prototype-interaction/utils'
import classes from '../interaction-action-animation.module.less'
import {
    useAnimationTransitionTypeCommand,
    type AnimationTransitionTypeCommandProps,
} from '../use-action-animation-commands'
import { translation } from './animation-transition-type.translation'

import TransitionType = Wukong.DocumentProto.TransitionType
import InteractionType = Wukong.DocumentProto.InteractionType
import ConnectionType = Wukong.DocumentProto.ConnectionType
import NavigationType = Wukong.DocumentProto.NavigationType

type TransitionTypeWithMixed = TransitionType | typeof MIXED_TYPE

const IconGroupOptionButton = [
    <MonoIconPanelArrowLeft16 key="0" />,
    <MonoIconPanelArrowRight16 key="1" />,
    <MonoIconPanelArrowDown16 key="2" />,
    <MonoIconPanelArrowUp16 key="3" />,
]

export function InteractionActionTransitionTypeSelect(props: {
    actionType: InteractionActionTypeWithMixed
    interactionType: InteractionTypeWithMixed
    state: Wukong.DocumentProto.ITransitionTypeProtoValue
    commandProps: AnimationTransitionTypeCommandProps
}) {
    const options = useMemo(
        () =>
            getTransitionTypeOptions({
                actionType: props.actionType,
                interactionType: props.interactionType,
            }),
        [props.actionType, props.interactionType]
    )
    const isMixed =
        props.state.valueType ===
        Wukong.DocumentProto.InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_MIXED
    const formatValue: TransitionTypeWithMixed = isMixed ? MIXED_TYPE : props.state.value
    const iconGroupOptions = useMemo(
        () =>
            isMixed
                ? getIconGroupOptions(TransitionType.TRANSITION_TYPE_MOVE_FROM_RIGHT)
                : getIconGroupOptions(props.state.value),
        [isMixed, props.state.value]
    )
    const command = useAnimationTransitionTypeCommand(props.commandProps)

    const onSelectTransitionType = (value: TransitionType) => {
        if (isMixed || !iconGroupOptions?.length) {
            return command.onChange(value)
        } else {
            // Keep the direction when changing the transition type
            const iconOptionIndex = iconGroupOptions.indexOf(props.state.value)
            if (iconOptionIndex !== -1) {
                const keepDirectionNewValue = getIconGroupOptions(value)?.[iconOptionIndex]
                if (!isNil(keepDirectionNewValue)) {
                    return command.onChange(keepDirectionNewValue)
                }
            }
            return command.onChange(value)
        }
    }

    return props.state.show ? (
        <>
            {featureSwitchManager.isEnabled('prototype-spring') && (
                <div className="h-16px flex flex-row items-center">
                    <WKDivider></WKDivider>
                </div>
            )}
            <div className={classes.animationContainer}>
                <Select.MinimalSingleLevel
                    className={classnames(classes.transitionTypeSelect, {
                        [classes.withIconGroup]: !!iconGroupOptions?.length,
                    })}
                    placement="over"
                    isMixed={isMixed}
                    onChange={onSelectTransitionType}
                    value={formatValue}
                    label={getTransitionTypeLabel(formatValue)}
                    dataTestIds={{
                        triggerFocus: PrototypeTestId.InteractionPopup.TransitionTypeSelectTrigger,
                        container: PrototypeTestId.InteractionPopup.TransitionTypeSelectMenu,
                    }}
                >
                    {options.map(({ iconGroupOptions: innerIconGroupOptions, ...option }) => {
                        const isSelect =
                            formatValue !== MIXED_TYPE &&
                            (formatValue === option.value || innerIconGroupOptions?.includes(formatValue))
                        return (
                            <Select.MinimalSingleLevel.Option
                                {...option}
                                key={option.value}
                                isSelect={isSelect}
                                dataTestId={PrototypeTestId.InteractionPopup.TransitionTypeSelectOption(
                                    !!option.disabled,
                                    !!isSelect
                                )}
                            >
                                {option.label}
                            </Select.MinimalSingleLevel.Option>
                        )
                    })}
                </Select.MinimalSingleLevel>

                {!!iconGroupOptions && (
                    <div className={classes.transitionTypeIconGroup}>
                        <SelectIconGroup optionValue={iconGroupOptions} onClickIcon={command.onChange}>
                            {iconGroupOptions.map((value, index) => (
                                <SelectIconGroup.Item
                                    key={value}
                                    value={value}
                                    selected={!isMixed && props.state.value === value}
                                    icon={IconGroupOptionButton[index]}
                                    dataTestId={PrototypeTestId.InteractionPopup.TransitionDirectionBtn(
                                        !isMixed && props.state.value === value
                                    )}
                                />
                            ))}
                        </SelectIconGroup>
                    </div>
                )}
            </div>
        </>
    ) : (
        <></>
    )
}

type TransitionTypeOption = Pick<CustomItemProps, 'disabled' | 'splitLineTop' | 'isSelect'> &
    ReturnType<typeof getTransitionOptionConfigByType>
function getTransitionTypeOptions(props: {
    actionType: InteractionActionTypeWithMixed
    interactionType: InteractionTypeWithMixed
}): TransitionTypeOption[] {
    let options: TransitionTypeOption[]
    switch (props.actionType) {
        case InteractionActionType.NavigateTo:
            options = [
                getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_INSTANT_TRANSITION),
                getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_DISSOLVE),
                ...(featureSwitchManager.isEnabled('prototype-smart-animation')
                    ? [getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_SMART_ANIMATE)]
                    : []),
                {
                    ...getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_MOVE_FROM_RIGHT),
                    splitLineTop: true,
                },
                getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_RIGHT),
                getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_PUSH_FROM_RIGHT),
                getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_SLIDE_FROM_RIGHT),
                getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_RIGHT),
            ]
            break
        case InteractionActionType.ScrollTo:
            options = [
                getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_INSTANT_TRANSITION),
                getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_SCROLL_ANIMATE),
            ]
            break
        case InteractionActionType.OpenOverlay:
            options = [
                getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_INSTANT_TRANSITION),
                getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_DISSOLVE),
                {
                    ...getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_MOVE_FROM_RIGHT),
                    splitLineTop: true,
                },
                {
                    ...getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_RIGHT),
                    disabled: true,
                },
                {
                    ...getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_PUSH_FROM_RIGHT),
                    disabled: true,
                },
                {
                    ...getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_SLIDE_FROM_RIGHT),
                    disabled: true,
                },
                {
                    ...getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_RIGHT),
                    disabled: true,
                },
            ]
            break
        case InteractionActionType.SwapOverlay:
            options = [
                getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_INSTANT_TRANSITION),
                getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_DISSOLVE),
                ...(featureSwitchManager.isEnabled('prototype-smart-animation')
                    ? [getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_SMART_ANIMATE)]
                    : []),
                {
                    ...getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_MOVE_FROM_RIGHT),
                    splitLineTop: true,
                },
                {
                    ...getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_RIGHT),
                    disabled: true,
                },
                {
                    ...getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_PUSH_FROM_RIGHT),
                    disabled: true,
                },
                {
                    ...getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_SLIDE_FROM_RIGHT),
                    disabled: true,
                },
                {
                    ...getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_RIGHT),
                    disabled: true,
                },
            ]
            break
        case MIXED_TYPE:
            options = [
                getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_INSTANT_TRANSITION),
                getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_DISSOLVE),
                ...(featureSwitchManager.isEnabled('prototype-smart-animation')
                    ? [getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_SMART_ANIMATE)]
                    : []),

                {
                    ...getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_MOVE_FROM_RIGHT),
                    splitLineTop: true,
                },
                getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_RIGHT),
                getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_PUSH_FROM_RIGHT),
                getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_SLIDE_FROM_RIGHT),
                getTransitionOptionConfigByType(TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_RIGHT),
            ].map((config) => ({ ...config, disabled: true }))
            break
        default:
            options = []
            break
    }

    if (props.interactionType === InteractionType.INTERACTION_TYPE_DRAG) {
        return options.map((option) =>
            [TransitionType.TRANSITION_TYPE_INSTANT_TRANSITION, TransitionType.TRANSITION_TYPE_DISSOLVE].includes(
                option.value
            )
                ? { ...option, disabled: true }
                : option
        )
    }

    return options
}

function getTransitionOptionConfigByType(transitionType: TransitionType): {
    value: TransitionType
    label: string
    iconGroupOptions?: ReturnType<typeof getIconGroupOptions>
} {
    return {
        value: transitionType,
        label: getTransitionTypeLabel(transitionType),
        iconGroupOptions: getIconGroupOptions(transitionType),
    }
}

function getIconGroupOptions(
    transitionType: TransitionTypeWithMixed
): [TransitionType, TransitionType, TransitionType, TransitionType] | undefined {
    switch (transitionType) {
        case TransitionType.TRANSITION_TYPE_SLIDE_FROM_LEFT:
        case TransitionType.TRANSITION_TYPE_SLIDE_FROM_RIGHT:
        case TransitionType.TRANSITION_TYPE_SLIDE_FROM_TOP:
        case TransitionType.TRANSITION_TYPE_SLIDE_FROM_BOTTOM:
            return [
                TransitionType.TRANSITION_TYPE_SLIDE_FROM_RIGHT,
                TransitionType.TRANSITION_TYPE_SLIDE_FROM_LEFT,
                TransitionType.TRANSITION_TYPE_SLIDE_FROM_TOP,
                TransitionType.TRANSITION_TYPE_SLIDE_FROM_BOTTOM,
            ]
        case TransitionType.TRANSITION_TYPE_PUSH_FROM_LEFT:
        case TransitionType.TRANSITION_TYPE_PUSH_FROM_RIGHT:
        case TransitionType.TRANSITION_TYPE_PUSH_FROM_TOP:
        case TransitionType.TRANSITION_TYPE_PUSH_FROM_BOTTOM:
            return [
                TransitionType.TRANSITION_TYPE_PUSH_FROM_RIGHT,
                TransitionType.TRANSITION_TYPE_PUSH_FROM_LEFT,
                TransitionType.TRANSITION_TYPE_PUSH_FROM_TOP,
                TransitionType.TRANSITION_TYPE_PUSH_FROM_BOTTOM,
            ]
        case TransitionType.TRANSITION_TYPE_MOVE_FROM_LEFT:
        case TransitionType.TRANSITION_TYPE_MOVE_FROM_RIGHT:
        case TransitionType.TRANSITION_TYPE_MOVE_FROM_TOP:
        case TransitionType.TRANSITION_TYPE_MOVE_FROM_BOTTOM:
            return [
                TransitionType.TRANSITION_TYPE_MOVE_FROM_RIGHT,
                TransitionType.TRANSITION_TYPE_MOVE_FROM_LEFT,
                TransitionType.TRANSITION_TYPE_MOVE_FROM_TOP,
                TransitionType.TRANSITION_TYPE_MOVE_FROM_BOTTOM,
            ]
        case TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_LEFT:
        case TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_RIGHT:
        case TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_TOP:
        case TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_BOTTOM:
            return [
                TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_RIGHT,
                TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_LEFT,
                TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_TOP,
                TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_BOTTOM,
            ]
        case TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_LEFT:
        case TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_RIGHT:
        case TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_TOP:
        case TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_BOTTOM:
            return [
                TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_RIGHT,
                TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_LEFT,
                TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_TOP,
                TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_BOTTOM,
            ]
        default:
            return undefined
    }
}

export function getTransitionTypeLabel(transitionType: TransitionTypeWithMixed) {
    switch (transitionType) {
        case TransitionType.TRANSITION_TYPE_INSTANT_TRANSITION:
            return translation('Instant')
        case TransitionType.TRANSITION_TYPE_DISSOLVE:
            return translation('Dissolve')
        case TransitionType.TRANSITION_TYPE_SLIDE_FROM_LEFT:
        case TransitionType.TRANSITION_TYPE_SLIDE_FROM_RIGHT:
        case TransitionType.TRANSITION_TYPE_SLIDE_FROM_TOP:
        case TransitionType.TRANSITION_TYPE_SLIDE_FROM_BOTTOM:
            return translation('SlideIn')
        case TransitionType.TRANSITION_TYPE_PUSH_FROM_LEFT:
        case TransitionType.TRANSITION_TYPE_PUSH_FROM_RIGHT:
        case TransitionType.TRANSITION_TYPE_PUSH_FROM_TOP:
        case TransitionType.TRANSITION_TYPE_PUSH_FROM_BOTTOM:
            return translation('Push')
        case TransitionType.TRANSITION_TYPE_MOVE_FROM_LEFT:
        case TransitionType.TRANSITION_TYPE_MOVE_FROM_RIGHT:
        case TransitionType.TRANSITION_TYPE_MOVE_FROM_TOP:
        case TransitionType.TRANSITION_TYPE_MOVE_FROM_BOTTOM:
            return translation('MoveIn')
        case TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_LEFT:
        case TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_RIGHT:
        case TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_TOP:
        case TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_BOTTOM:
            return translation('SlideOut')
        case TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_LEFT:
        case TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_RIGHT:
        case TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_TOP:
        case TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_BOTTOM:
            return translation('MoveOut')
        case TransitionType.TRANSITION_TYPE_SCROLL_ANIMATE:
            return translation('ScrollAnimate')
        case TransitionType.TRANSITION_TYPE_SMART_ANIMATE:
            return featureSwitchManager.isEnabled('prototype-smart-animation') ? translation('SmartAnimate') : 'Unknown'
        case MIXED_TYPE:
            return translation('Mixed')
        default:
            return 'Unknown'
    }
}

export function getValidTransitionTypes({
    interactionType,
    connectionType,
    navigationType,
}: {
    interactionType: InteractionType
    connectionType: ConnectionType
    navigationType: NavigationType
}) {
    const actionType = getSingleActionType({ connectionType, navigationType })

    return getTransitionTypeOptions({ actionType, interactionType })
        .filter((option) => !option.disabled)
        .map(({ value, iconGroupOptions }) => (iconGroupOptions?.length ? iconGroupOptions : value))
        .flat()
}
