import { useFontManagerService } from '../../../../../context/document-context'
import { FontSelect } from '../font-select/font-select'
import { translation } from './font-cloud-tab-item.translation'

export function FontCloudTabItem({ selectMaxHeight }: { selectMaxHeight: number }) {
    const fontManagerService = useFontManagerService()
    const fonts = fontManagerService.states.use.cloudFontInfosState()

    return (
        <FontSelect
            placeholder={translation('SearchCloudFonts')}
            fontInfos={fonts}
            selectMaxHeight={selectMaxHeight}
        ></FontSelect>
    )
}
