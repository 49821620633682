import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    CreateStyle: '创建',
} as const

export const enTranslation = {
    CreateStyle: 'Create style',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
