import { Wukong } from '@wukong/bridge-proto'
import { useCallback, useState } from 'react'
import { Position } from '../../../../../../ui-lib/src/components/draggable-popup/util'
import { featureSwitchManager } from '../../../../kernel/switch'
import { CreateConfig, PrimitiveVariablePanel } from './primitive-variable-panel'
import { useRequiredVariable } from './use-primitive-variable'

interface UseFloatVariablePanelProps {
    requiredScopes: Wukong.DocumentProto.VariableScope[]
    onVariableSelected: (id: string, name: string) => void
    onVariableDetach: () => void
    createEnable: boolean
    selectedVariable: Wukong.DocumentProto.ISingleFloatVariable | null | undefined
    /**
     * selectedVariable计算floatValue为Mixed时, 使用此值
     * 通常应该设置为当前属性在属性面板input中展示的值
     */
    selectedVariableFallbackFloatValue: number | null | undefined //原始属性值
    /**
     * 快捷创建变量的默认值, 如果存在selectedVariable则使用selectedVariable的值
     * 通常应该设置为当前属性在属性面板input中展示的值
     */
    defaultCreateValue?: number | null
    hideIconBindUnbind?: boolean
}

export const useFloatVariablePanel = (props: UseFloatVariablePanelProps) => {
    const {
        onVariableSelected,
        onVariableDetach,
        selectedVariableFallbackFloatValue,
        requiredScopes,
        createEnable,
        selectedVariable,
        defaultCreateValue = 0,
        hideIconBindUnbind = false,
    } = props

    const flag = featureSwitchManager.isEnabled('float-variable')
    const { hasRequiredVariable } = useRequiredVariable({
        requiredScopes,
        requiredTypes: [Wukong.DocumentProto.PrimitiveVariableType.PRIMITIVE_VARIABLE_TYPE_FLOAT],
    })
    const [pickerInfo, setPickerInfo] = useState<{ show: false } | { show: true; position: Position }>({ show: false })

    const openVarPicker = useCallback((p: { rootRect: DOMRect }) => {
        setPickerInfo({
            position: { top: p.rootRect.bottom + 8, left: p.rootRect.left },
            show: true,
        })
    }, [])

    const hideVarPicker = useCallback(() => {
        setPickerInfo({
            show: false,
        })
    }, [])

    const selectVariable = (id: string, name: string) => {
        onVariableSelected(id, name)
        hideVarPicker()
    }

    const useVariable = flag
        ? {
              open: pickerInfo.show,
              notAvailable: !hasRequiredVariable,
              variable: selectedVariable
                  ? {
                        name: selectedVariable.name,
                        value: selectedVariable.floatValue ?? selectedVariableFallbackFloatValue ?? 0,
                        remove: selectedVariable.isSoftDeleted,
                        onClick: openVarPicker,
                    }
                  : null,
              onUnbind: onVariableDetach,
              iconBindUnbind: hideIconBindUnbind
                  ? undefined
                  : {
                        onBind: openVarPicker,
                    },
          }
        : undefined

    const createConfig: CreateConfig | undefined = createEnable
        ? {
              type: Wukong.DocumentProto.PrimitiveVariableType.PRIMITIVE_VARIABLE_TYPE_FLOAT,
              data:
                  selectedVariable && !selectedVariable.isSoftDeleted
                      ? {
                            type: 'alias',
                            alias: selectedVariable.id,
                            name: selectedVariable.name,
                        }
                      : {
                            type: 'value',
                            value: defaultCreateValue ?? 0,
                        },
          }
        : undefined

    return {
        hasRequiredVariable,
        hasSelectedVariable: !!selectedVariable,
        openPicker: (position: Position) => {
            setPickerInfo({ position, show: true })
        },
        useVariable,
        renderPanel: () => {
            return pickerInfo.show ? (
                <PrimitiveVariablePanel
                    createEnable={true}
                    createConfig={createConfig}
                    selectedSingleVariable={
                        selectedVariable
                            ? {
                                  type: 'float',
                                  variable: selectedVariable,
                              }
                            : undefined
                    }
                    position={pickerInfo.position}
                    onCancel={() => setPickerInfo({ show: false })}
                    onVariableSelected={selectVariable}
                    requiredTypes={[Wukong.DocumentProto.PrimitiveVariableType.PRIMITIVE_VARIABLE_TYPE_FLOAT]}
                    requiredScopes={requiredScopes}
                />
            ) : null
        },
    } as const
}
