import { translation } from './style-paint.translation'
/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { forwardRef, useCallback, useMemo, useRef, useState } from 'react'
import { IconDelete16, IconLink, IconTarget, Tooltip } from '../../../../../../../ui-lib/src'
import { IconButton } from '../../../atom/button/icon-button'
import { SingleGrid } from '../../../atom/grid/single-grid'
import { StyleToolTip } from '../style-tooltip/style-tooltip'
import style from './style-paint.module.less'

interface StylePaintProps extends React.HTMLAttributes<HTMLDivElement> {
    name: string
    description?: string | null
    thumbnail?: JSX.Element
    type?: 'normal' | 'select-color' | 'text' | 'effect'
    isOpen?: boolean
    isLoading?: boolean
    usingNodeCount?: number
    onClickThumbnailName?: React.MouseEventHandler<HTMLDivElement>
    onClickCutLink?: () => void
    onClickAim?: () => void
    onClickSub?: () => void
}
function _StylePaint(props: StylePaintProps, reactRef: React.Ref<HTMLDivElement>): JSX.Element {
    const {
        name,
        description,
        isOpen,
        isLoading = false,
        thumbnail,
        type = 'normal',
        usingNodeCount = 0,
        onClickSub,
        onClickThumbnailName,
        onClickCutLink,
        onClickAim,
    } = props
    const gridRef = useRef<HTMLDivElement>(null)
    const [isShowCutLinkIcon, setIsShowCutLinkIcon] = useState<boolean>(false)
    const isSelectColor = useMemo(() => {
        return type === 'select-color'
    }, [type])
    const isCycleImage = useMemo(() => {
        return type === 'select-color' || type === 'normal'
    }, [type])
    const _onClick = useCallback((e: React.MouseEvent) => {
        e.stopPropagation()
    }, [])

    const _onMouseDown = useCallback((e: React.MouseEvent) => {
        e.stopPropagation()
    }, [])

    const _onMouseEnter = useCallback(() => {
        setIsShowCutLinkIcon(true)
    }, [])

    const _onMouseLeave = useCallback(() => {
        setIsShowCutLinkIcon(false)
    }, [])

    const renderIcon = () => {
        return (
            <SingleGrid.Item
                start={1}
                span={8}
                className={`${style.imageContainer} ${isCycleImage ? style.cycleImage : ''}`}
            >
                {thumbnail}
            </SingleGrid.Item>
        )
    }

    return (
        <SingleGrid
            testId="style-paint"
            ref={reactRef}
            className={classnames(
                style.paint,
                {
                    [style.opened]: isOpen,
                    [style.isShowCutLinkIcon]: isShowCutLinkIcon,
                    [style.loading]: isLoading,
                },
                style.variable
            )}
            onClick={_onClick}
            onMouseEnter={_onMouseEnter}
            onMouseLeave={_onMouseLeave}
            onMouseDown={_onMouseDown}
        >
            <SingleGrid.Item start={5} span={46}>
                <SingleGrid className={style.backgroundGrid}>
                    <SingleGrid.Item start={1} span={isShowCutLinkIcon ? 39 : 46}>
                        <SingleGrid
                            onClick={onClickThumbnailName}
                            className={style.colorNameContainer}
                            style={{ gridTemplateColumns: `repeat(${isShowCutLinkIcon ? 39 : 46}, 4px)` }}
                            ref={gridRef}
                        >
                            {type === 'effect' ? (
                                <Tooltip title={translation('EffectSettings')}>{renderIcon()}</Tooltip>
                            ) : (
                                renderIcon()
                            )}
                            <StyleToolTip
                                titleText={name}
                                contentText={description}
                                triggerRect={() => gridRef.current!.getBoundingClientRect()}
                            >
                                <SingleGrid.Item start={9} span={isShowCutLinkIcon ? 31 : 38}>
                                    <div className={style.name}>{name}</div>
                                </SingleGrid.Item>
                            </StyleToolTip>
                        </SingleGrid>
                    </SingleGrid.Item>
                    {isShowCutLinkIcon ? (
                        <SingleGrid.Item start={40} span={7}>
                            <Tooltip title={translation('DetachStyle')}>
                                <IconButton
                                    dataTestId="cut-style-link"
                                    icon={<IconLink />}
                                    selected={false}
                                    className={style.link}
                                    onClick={onClickCutLink}
                                />
                            </Tooltip>
                        </SingleGrid.Item>
                    ) : null}
                </SingleGrid>
            </SingleGrid.Item>
            {type === 'text' ? null : (
                <SingleGrid.Item
                    className={isSelectColor ? style.link : undefined}
                    start={51}
                    span={12}
                    horizontalCenter
                >
                    {isSelectColor ? (
                        <Tooltip
                            title={translation('SelectItemsUsing', {
                                usingNodeCount: String(usingNodeCount),
                                item: usingNodeCount > 1 ? 'items' : 'item',
                            })}
                        >
                            <IconButton
                                icon={<IconTarget />}
                                selected={false}
                                dataTestId="select-color-inspect-style"
                                onClick={onClickAim}
                            />
                        </Tooltip>
                    ) : (
                        <IconButton
                            icon={<IconDelete16 />}
                            selected={false}
                            onClick={onClickSub}
                            dataTestId="delete-style"
                        />
                    )}
                </SingleGrid.Item>
            )}
        </SingleGrid>
    )
}

export const StylePaint = forwardRef(_StylePaint)
