import { cloneDeep, isEqual } from 'lodash-es'
import { useCallback, useMemo, useRef } from 'react'

export type GetThumbnailMethod<T, K> = (props: T, cache: K | undefined) => K

export function useThumbnailCache<T, K>(props: Exclude<T, undefined>, getThumbnailMethod: GetThumbnailMethod<T, K>) {
    const prevPropsRef = useRef<T>()
    const prevCacheRef = useRef<K>()
    const isSameParams = useCallback((params: T) => {
        const isSame = isEqual(params, prevPropsRef.current)
        if (!isSame) {
            prevPropsRef.current = cloneDeep(params)
        }
        return isSame
    }, [])

    return useMemo(() => {
        if (isSameParams(props)) {
            return prevCacheRef.current!
        }
        prevCacheRef.current = getThumbnailMethod(props, prevCacheRef.current)
        return prevCacheRef.current
    }, [isSameParams, getThumbnailMethod, props])
}
