import classnames from 'classnames'
import { MonoIconToolbarPresent24 } from '../../../../../ui-lib/src'
import Tooltip from '../../../../../ui-lib/src/components/tooltip/tooltip'
import { useBridge } from '../../../main/app-context'
import { openPresentUrl } from '../../../prototype/context/present-url'
import { useViewState } from '../../../view-state-bridge'
import { Switch } from '../atom/switch/switch'
import style from './top-preview.module.less'
import { translation } from './top-preview.translation'
import { ShortcutKey, shortcutLabelMap } from '../../../kernel/interface/shortcut-key'

export function PrototypePresent() {
    const bridge = useBridge()
    const isHistoryMode = useViewState('historyMode')
    if (isHistoryMode) {
        return null
    }
    return (
        <Switch>
            <Switch.Item
                className={classnames(style.switchItem44)}
                useTabFocus={true}
                onClick={() => openPresentUrl(bridge)}
            >
                <Tooltip title={translation('Present')} shortcut={shortcutLabelMap[ShortcutKey.Present]}>
                    <div
                        data-testid="editor-prototype-present"
                        style={{
                            width: '48px',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <MonoIconToolbarPresent24></MonoIconToolbarPresent24>
                    </div>
                </Tooltip>
            </Switch.Item>
        </Switch>
    )
}
