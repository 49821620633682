/* eslint-disable no-restricted-imports */
import {
    CurrentPageSetSelectionCommandWasmCall,
    SelectionAllNodesCommand,
    ToggleSelectionChildrenCommandForJs,
    ToggleSelectionNodesCommand,
    ToggleSelectionParentCommand,
} from '@wukong/bridge-proto'
import { NodeId } from '../node/node'
import { BridgeCommand } from './command-invoker'

export const cmdClearSelectNodes: BridgeCommand = ({ commandInvoker }) => {
    commandInvoker.invoke(cmdSetSelection, [])
}

export const cmdToggleChildren: BridgeCommand = ({ commandInvoker }) => {
    commandInvoker.DEPRECATED_invokeBridge(ToggleSelectionChildrenCommandForJs)
}

export const cmdToggleParent: BridgeCommand = ({ commandInvoker }) => {
    commandInvoker.DEPRECATED_invokeBridge(ToggleSelectionParentCommand)
}

export const cmdToggleSelectNodes: BridgeCommand<[boolean]> = ({ commandInvoker }, isNext: boolean) => {
    commandInvoker.DEPRECATED_invokeBridge(ToggleSelectionNodesCommand, { value: isNext })
}

export const cmdSetSelection: BridgeCommand<[NodeId[]]> = ({ bridge }, nodeIds) => {
    bridge.call(CurrentPageSetSelectionCommandWasmCall, { selection: nodeIds })
}

export const cmdSelectAll: BridgeCommand = (ctx) => {
    ctx.commandInvoker.DEPRECATED_invokeBridge(SelectionAllNodesCommand)
}
