import {
    PluginApiGetViewport,
    PluginApiScrollAndZoomIntoView,
    PluginApiSetViewportCenter,
    PluginApiSetViewportZoom,
} from '@wukong/bridge-proto'
import { IPluginAPIContext } from '../plugin-api-context-interface'
import { Handle } from '../vm-interface'
import { ZodTypes } from '../zod-type'

export function createViewportApi(ctx: IPluginAPIContext) {
    const vm = ctx.vm
    const apiObject = vm.newObject()

    ctx.defineVmProp({
        objhandle: apiObject,
        key: 'zoom',
        get: () => {
            return vm.newNumber(ctx.unwrapCallBridge(PluginApiGetViewport).zoom)
        },
        set: (value_: Handle) => {
            const value = ctx.unwrapAndValidate({
                handle: value_,
                key: 'zoom',
                type: ZodTypes.PositiveFloat,
            })
            ctx.unwrapCallBridge(PluginApiSetViewportZoom, { zoom: value })
        },
        canUseInReadonly: true,
    })
    ctx.defineVmProp({
        objhandle: apiObject,
        key: 'bounds',
        get: () => {
            const v = ctx.unwrapCallBridge(PluginApiGetViewport)
            return vm.deepWrapHandle({
                x: v.x,
                y: v.y,
                width: v.width,
                height: v.height,
            })
        },
    })
    ctx.defineVmProp({
        objhandle: apiObject,
        key: 'center',
        get: () => {
            const v = ctx.unwrapCallBridge(PluginApiGetViewport)
            return vm.deepWrapHandle({
                x: v.x + v.width / 2,
                y: v.y + v.height / 2,
            })
        },
        set: (value_: Handle) => {
            const value = ctx.unwrapAndValidate({
                handle: value_,
                key: 'center',
                type: ZodTypes.Vector,
            })
            ctx.unwrapCallBridge(PluginApiSetViewportCenter, {
                centerX: value.x,
                centerY: value.y,
            })
        },
        canUseInReadonly: true,
    })
    ctx.defineVmFunction({
        objhandle: apiObject,
        key: 'scrollAndZoomIntoView',
        func: (nodes: Handle) => {
            const nodeIds = []
            if (!vm.isArray(nodes)) throw Error('Call to scrollAndZoomIntoView expected an array')
            const length = vm.getNumberValue(nodes, 'length')
            for (let n = 0; n < length; n++) {
                const node = vm.getProp(nodes, n.toString())
                nodeIds.push(vm.getString(vm.getProp(node, 'id')))
            }
            ctx.unwrapCallBridge(PluginApiScrollAndZoomIntoView, {
                nodeIds: nodeIds,
            })
        },
        canUseInReadonly: true,
    })
    return apiObject
}
