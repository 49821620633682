export const IconHorizonalWarning = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
        <path
            transform="matrix(1 0 0 1 15 11)"
            d="M-0 -0L12 0L12 18L-0 18L0 -0ZM1 1L1 17L11 17L11 1L1 1Z"
            fillRule="nonzero"
            fill="rgb(26, 29, 37)"
        />
        <path
            transform="matrix(1 0 0 1 26 11)"
            d="M0 1.35L0 0L1.25 0L1.25 1L1 1L1 1.35L0 1.35ZM2.08333 0L4.58333 0L4.58333 1L2.08333 1L2.08333 0ZM5.41667 0L7.91667 0L7.91667 1L5.41667 1L5.41667 0ZM8.75 0L10 0L10 1.35L9 1.35L9 1L8.75 1L8.75 0ZM10 2.25L9 2.25L9 4.95L10 4.95L10 2.25ZM0 4.95L1 4.95L1 2.25L0 2.25L0 4.95ZM10 5.85L9 5.85L9 8.55L10 8.55L10 5.85ZM0 8.55L1 8.55L1 5.85L0 5.85L0 8.55ZM10 9.45L9 9.45L9 12.15L10 12.15L10 9.45ZM0 12.15L1 12.15L1 9.45L0 9.45L0 12.15ZM10 13.05L9 13.05L9 15.75L10 15.75L10 13.05ZM0 15.75L1 15.75L1 13.05L0 13.05L0 15.75ZM1.25 18L0 18L0 16.65L1 16.65L1 17L1.25 17L1.25 18ZM10 16.65L10 18L8.75 18L8.75 17L9 17L9 16.65L10 16.65ZM4.58333 18L2.08333 18L2.08333 17L4.58333 17L4.58333 18ZM7.91667 18L5.41667 18L5.41667 17L7.91667 17L7.91667 18Z"
            fillRule="nonzero"
            fill="rgb(26, 29, 37)"
        />
        <path
            transform="matrix(1 0 0 1 5 5)"
            d="M-0 2C0 0.89543 0.89543 -0 2 -0L20 0C21.1046 0 22 0.89543 22 2L22 28C22 29.1046 21.1046 30 20 30L2 30C0.89543 30 -0 29.1046 -0 28L0 2ZM2 1C1.44772 1 1 1.44772 1 2L1 28C1 28.5523 1.44772 29 2 29L20 29C20.5523 29 21 28.5523 21 28L21 2C21 1.44772 20.5523 1 20 1L2 1Z"
            fillRule="nonzero"
            fill="rgb(191, 195, 201)"
        />
    </svg>
)

export const IconVerticalWarning = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
        <path
            transform="matrix(1 0 0 1 11 14)"
            d="M-0 -0L18 0L18 12L-0 12L0 -0ZM1 1L1 11L17 11L17 1L1 1Z"
            fillRule="nonzero"
            fill="rgb(26, 29, 37)"
        />
        <path
            transform="matrix(1 0 0 1 11 25)"
            d="M0 1.375L0 0L1.35 0L1.35 1L1 1L1 1.375L0 1.375ZM2.25 0L4.95 0L4.95 1L2.25 1L2.25 0ZM5.85 0L8.55 0L8.55 1L5.85 1L5.85 0ZM9.45 0L12.15 0L12.15 1L9.45 1L9.45 0ZM13.05 0L15.75 0L15.75 1L13.05 1L13.05 0ZM16.65 0L18 0L18 1.375L17 1.375L17 1L16.65 1L16.65 0ZM0 5.04167L1 5.04167L1 2.29167L0 2.29167L0 5.04167ZM18 2.29167L17 2.29167L17 5.04167L18 5.04167L18 2.29167ZM0 8.70833L1 8.70833L1 5.95833L0 5.95833L0 8.70833ZM18 5.95833L17 5.95833L17 8.70833L18 8.70833L18 5.95833ZM1.35 11L0 11L0 9.625L1 9.625L1 10L1.35 10L1.35 11ZM18 9.625L18 11L16.65 11L16.65 10L17 10L17 9.625L18 9.625ZM4.95 11L2.25 11L2.25 10L4.95 10L4.95 11ZM8.55 11L5.85 11L5.85 10L8.55 10L8.55 11ZM12.15 11L9.45 11L9.45 10L12.15 10L12.15 11ZM15.75 11L13.05 11L13.05 10L15.75 10L15.75 11Z"
            fillRule="evenodd"
            fill="rgb(26, 29, 37)"
        />
        <path
            transform="matrix(1 0 0 1 5 4)"
            d="M-0 2C0 0.89543 0.89543 -0 2 -0L28 0C29.1046 0 30 0.89543 30 2L30 20C30 21.1046 29.1046 22 28 22L2 22C0.89543 22 -0 21.1046 -0 20L0 2ZM2 1C1.44772 1 1 1.44772 1 2L1 20C1 20.5523 1.44772 21 2 21L28 21C28.5523 21 29 20.5523 29 20L29 2C29 1.44772 28.5523 1 28 1L2 1Z"
            fillRule="nonzero"
            fill="rgb(191, 195, 201)"
        />
    </svg>
)
