import { isNil } from 'lodash-es'
import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { wkDialogConfirm, type WKButtonProps } from '../../../../../../ui-lib/src'
import { useLibraryComponentService } from '../../../../main/app-context'
import { BatchMoveDocModal } from '../../../../share/batch-move-doc-modal'
import { LibraryTestId } from '../../../../window'
import { useDocInfoContext } from '../../../context/top-area-context'
import type { OpenLibraryPublishModalSource } from '../library-service/library-modal-router-service'
import { translation } from './draft-library-move-to-team.translation'

export const DraftLibraryMoveToTeam = () => {
    const { libraryModalRouterService } = useLibraryComponentService()
    return (
        <InnerDraftLibraryMoveToTeam
            ref={libraryModalRouterService.onDraftLibraryMoveToTeamRefChange}
            afterMoved={libraryModalRouterService.afterDraftMoveModalClose}
        />
    )
}

export interface InnerDraftLibraryMoveToTeamRef {
    moveDoc: (openSource: OpenLibraryPublishModalSource) => void
}
const InnerDraftLibraryMoveToTeam = forwardRef<
    InnerDraftLibraryMoveToTeamRef,
    { afterMoved: (moved: boolean, openSource: OpenLibraryPublishModalSource) => void }
>((props, ref) => {
    const { docData, getCurrentDocOrganization } = useDocInfoContext()
    const [visible, setVisible] = useState<boolean>(false)
    const openSourceRef = useRef<OpenLibraryPublishModalSource>()

    const onClose = (moved: boolean) => {
        setVisible(false)
        if (!isNil(openSourceRef.current)) {
            props.afterMoved(moved, openSourceRef.current)
        }
    }

    useImperativeHandle(ref, () => ({
        moveDoc: (openSource: OpenLibraryPublishModalSource) => {
            openSourceRef.current = openSource
            wkDialogConfirm.show({
                title: translation('MoveToTitle'),
                content: translation('MoveToContext'),
                okText: translation('Move'),
                onOk: () => setVisible(true),
                onClose: () => onClose(false),
                closable: true,
                okButtonProps: {
                    'data-testid': LibraryTestId.DraftPublishModal.ConfirmMoveBtn,
                } as Partial<WKButtonProps>,
                cancelButtonProps: {
                    'data-testid': LibraryTestId.DraftPublishModal.CancelMoveBtn,
                } as Partial<WKButtonProps>,
            })
        },
    }))

    return !!docData && visible ? (
        <BatchMoveDocModal
            forceHideTipBanner={true}
            docs={[docData]}
            organization={getCurrentDocOrganization()!}
            onClose={onClose}
        />
    ) : (
        <></>
    )
})
InnerDraftLibraryMoveToTeam.displayName = 'InnerDraftLibraryMoveToTeam'
