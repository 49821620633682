import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Solid: '纯色',
    LinearGradient: '线性渐变',
    RadialGradient: '径向渐变',
    AngularGradient: '角度渐变',
    DiamondGradient: '菱形渐变',
    Image: '图片',
    Custom: '效果颜色',
    BlendMode: '混合模式',
} as const

export const enTranslation = {
    Solid: 'Solid',
    LinearGradient: 'Linear gradient',
    RadialGradient: 'Radial gradient',
    AngularGradient: 'Angular gradient',
    DiamondGradient: 'Diamond gradient',
    Image: 'Image',
    Custom: 'Color',
    BlendMode: 'Blend mode',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
