import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    "YouCan'tUndo": '此操作无法撤销',
    TransferOwnership: '转移所有者',
    AreYouSure: '确定将团队「{{teamName}}」所有者转移给「{{memberName}}」？',
} as const

export const enTranslation = {
    "YouCan'tUndo": 'You cannot undo this action.',
    TransferOwnership: 'Transfer ownership',
    AreYouSure: 'Are you sure you want to make "{{memberName}}" the owner of "{{teamName}}"?',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
