/* eslint-disable no-restricted-imports */
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { CommentId } from '../../../../kernel/interface/comment'
import { useCommentService } from '../../../../main/app-context'
import { CommentDataTestId } from '../../../../window'
import { useEventMinorAnchor } from '../comment-service/use-event-minor-anchor'
import { Dot } from '../dot-range/dot'
import { Range } from '../dot-range/range'
import { Position } from '../type'
import { calculateRange } from '../utils'

interface MinorAnchorProps {
    commentId: CommentId
    isStopSyncActiveCommentPosition: boolean
    closeMove?: boolean
}
export function MinorAnchor(props: MinorAnchorProps) {
    const { commentId, isStopSyncActiveCommentPosition, closeMove } = props
    const hasMovedBeforeEnd = useRef<boolean>(false)
    const keys = useRef<string[]>([])
    const rangeRef = useRef<HTMLDivElement>(null)
    const dotRef = useRef<HTMLDivElement>(null)
    const isStopSyncRef = useRef<boolean>(false)
    isStopSyncRef.current = isStopSyncActiveCommentPosition

    const { onMinorAnchorMouseMove, onMinorAnchorPointerUp, onMinorAnchorPointerEnter, onMinorAnchorPointerLeave } =
        useEventMinorAnchor()
    const commentService = useCommentService()

    const _anchorPosition = useRef<Position>()
    const [_minorAnchorPosition, setMinorAnchorPosition] = useState<Position>()

    const updateRangeStyle = useCallback((anchorPosition: Position, minorAnchorPosition: Position) => {
        if (!rangeRef.current || isStopSyncRef.current) {
            return
        }
        const { width, height, left, top } = calculateRange(anchorPosition, minorAnchorPosition)
        rangeRef.current.setAttribute('style', `width:${width}px;height:${height}px;left:${left}px;top:${top}px;`)
        _anchorPosition.current = anchorPosition
        setMinorAnchorPosition(minorAnchorPosition)
    }, [])

    const updateDotStyle = useCallback((minorAnchorPosition: Position) => {
        if (!dotRef.current || isStopSyncRef.current) {
            return
        }
        dotRef.current.setAttribute('style', `left:${minorAnchorPosition.left}px;top:${minorAnchorPosition.top}px;`)
        setMinorAnchorPosition(minorAnchorPosition)
    }, [])

    const onMoving = useCallback(
        (nextPosition: Position) => {
            if (!_anchorPosition.current) {
                return
            }
            if (!hasMovedBeforeEnd.current) {
                commentService.updateCommentMinorAnchorStart(commentId)
            }
            hasMovedBeforeEnd.current = true
            setMinorAnchorPosition(nextPosition)
            commentService.updateCommentMinorAnchor(commentId, nextPosition)
        },
        [commentService, commentId]
    )

    const onMoveEnd = useCallback(() => {
        if (hasMovedBeforeEnd.current) {
            commentService.updateCommentMinorAnchorEnd(commentId)
            hasMovedBeforeEnd.current = false
        }
    }, [commentId, commentService])

    const onMouseMove = useCallback(
        (e: React.MouseEvent) => {
            onMinorAnchorMouseMove(e.nativeEvent, commentId)
        },
        [commentId, onMinorAnchorMouseMove]
    )

    const onPointerUp = useCallback(
        (e: React.MouseEvent) => {
            onMinorAnchorPointerUp(e.nativeEvent, commentId)
        },
        [commentId, onMinorAnchorPointerUp]
    )

    const onPointerEnter = useCallback(
        (e: React.MouseEvent) => {
            onMinorAnchorPointerEnter(e.nativeEvent, commentId)
        },
        [commentId, onMinorAnchorPointerEnter]
    )

    const onPointerLeave = useCallback(
        (e: React.MouseEvent) => {
            onMinorAnchorPointerLeave(e.nativeEvent, commentId)
        },
        [commentId, onMinorAnchorPointerLeave]
    )

    const unsubscribe = useCallback(() => {
        keys.current.forEach((key) => commentService.positionService.unregisterBubble(key))
        keys.current = []
    }, [commentService.positionService])

    const subscribe = useCallback(() => {
        unsubscribe()
        const key1 = commentService.positionService.registerBubbleAnchorMinorAnchor(commentId, updateRangeStyle)
        const key2 = commentService.positionService.registerBubbleMinorAnchor(commentId, updateDotStyle)
        keys.current.push(key1, key2)
    }, [commentId, unsubscribe, updateRangeStyle, updateDotStyle, commentService.positionService])

    useLayoutEffect(() => {
        subscribe()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscribe, isStopSyncActiveCommentPosition])

    useEffect(() => {
        subscribe()
        return unsubscribe
    }, [subscribe, unsubscribe])

    return (
        <>
            <Range ref={rangeRef} dataTestId={CommentDataTestId.CommentRangeActive} />
            <Dot
                ref={dotRef}
                dragMoveBasePoint={_minorAnchorPosition}
                onMoving={onMoving}
                onMoveEnd={onMoveEnd}
                onPointerEnter={onPointerEnter}
                onPointerLeave={onPointerLeave}
                onMouseMove={onMouseMove}
                onPointerUp={onPointerUp}
                closeMove={closeMove}
                dataTestId={CommentDataTestId.CommentAnchorActive}
            />
        </>
    )
}
