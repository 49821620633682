import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Exposure: '曝光度',
    Contrast: '对比度',
    Saturation: '饱和度',
    Temperature: '色温',
    Tint: '色调',
    Highlights: '高光',
    Shadows: '阴影',
} as const

export const enTranslation = {
    Exposure: 'Exposure',
    Contrast: 'Contrast',
    Saturation: 'Saturation',
    Temperature: 'Temperature',
    Tint: 'Tint',
    Highlights: 'Highlights',
    Shadows: 'Shadows',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
