import {
    BenchmarkImageDownloadCommand,
    GetImageDownloadSuccessRatioCommand,
    MetricRenderDurationCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { isCypress, sleep } from '../../../../util/src'
import type { GraphicCardInfo } from '../../main/gpu/utils'
import { Bridge } from '../bridge/bridge'
import { WkCLog } from '../clog/wukong/instance'
import { BenchmarkType, CrashFrogType } from '../interface/performance'
const MetricImageType = Wukong.DocumentProto.MetricImageType

// 用于 octopus 日志筛选
enum ClogDispatchType {
    Crash = 'crash',
    Mirror = 'mirror',
    Font = 'font',
    Image = 'image',
    Import = 'import',
    Render = 'render',
    Document = 'document',
}

type UploadBenchmarkParams = Record<string, any> & { type: ClogDispatchType }

/**
 * 用于收集用户全量预览的 benchmark 指标
 */
class BenchmarkService {
    private readonly TRACE_IMAGE_DOWNLOAD_SUCCESS_RATIO_DURATION = 1000 * 60
    private bridge?: Bridge
    private graphicCardInfo: GraphicCardInfo | null = null

    public setGraphicCardInfo(info: GraphicCardInfo | null) {
        this.graphicCardInfo = info
    }

    public injectBridge(bridge: Bridge): () => void {
        this.bridge = bridge
        bridge.bind(BenchmarkImageDownloadCommand, this.benchmarkImageDownloadTime.bind(this))
        bridge.bind(MetricRenderDurationCommand, this.benchmarkRenderDuration.bind(this))
        return () => {
            bridge.unbind(BenchmarkImageDownloadCommand)
            bridge.unbind(MetricRenderDurationCommand)
        }
    }

    public startMetricImageDownloadSuccessRation(): NodeJS.Timeout {
        return setInterval(() => {
            this.MetricImageDownloadSuccessRation()
        }, this.TRACE_IMAGE_DOWNLOAD_SUCCESS_RATIO_DURATION)
    }

    private uploadReport(type: CrashFrogType | BenchmarkType, params: UploadBenchmarkParams): void {
        // 不在 cypress 中 benchmark
        if (isCypress()) {
            return
        }
        WkCLog.throttleLog(type, {
            ...params,
            service: 'motiff-benchmark',
        })
    }

    public benchmarkCrash(crashType: CrashFrogType, crash: boolean, params: Record<string, any> = {}): void {
        this.uploadReport(crashType, {
            crash,
            type: ClogDispatchType.Crash,
            ...params,
        })
    }

    private mirrorStatus: {
        startTime: number | undefined
        isFirstTime: boolean
        frameId: string | undefined
    } = {
        startTime: undefined,
        isFirstTime: true,
        frameId: undefined,
    }

    public reportUserOnDocumentDuration(
        archiveSyncDuration: number,
        onDocumentDuration: number,
        nodeCounts: number
    ): void {
        this.uploadReport(BenchmarkType.ArchiveSyncDuration, {
            nodeCounts,
            duration: archiveSyncDuration,
            type: ClogDispatchType.Document,
        })
        this.uploadReport(BenchmarkType.OnDocumentDuration, {
            nodeCounts,
            duration: onDocumentDuration,
            type: ClogDispatchType.Document,
        })
    }

    /**
     * 标记接收到 mirror notifyService 的 frameId change 事件
     * @param frameId
     */
    public recordMirrorReceiveFrameChange(frameId: string): void {
        if (this.mirrorStatus.frameId !== frameId) {
            this.mirrorStatus.frameId = frameId
            this.mirrorStatus.startTime = performance.now()
        }
    }

    /**
     * 在 flush 阶段结束后 benchmark switch 时长
     * @returns
     */
    public benchmarkMirrorSwitchFrame(): void {
        const startTime = this.mirrorStatus.startTime
        // 需要保证有开始点位
        if (startTime) {
            const duration = performance.now() - startTime

            // 第一次切换标记
            if (this.mirrorStatus.isFirstTime) {
                this.uploadReport(BenchmarkType.MirrorFirstShowFrame, {
                    duration,
                    type: ClogDispatchType.Mirror,
                })
                this.mirrorStatus.isFirstTime = false
                this.mirrorStatus.startTime = undefined
                return
            }

            // 切换 frame 点位
            this.uploadReport(BenchmarkType.MirrorSwitchFrame, {
                duration,
                type: ClogDispatchType.Mirror,
            })

            this.mirrorStatus.startTime = undefined
        }
    }

    public benchmarkFontDownloadSuccessRate(success: boolean): void {
        this.uploadReport(BenchmarkType.FontDownloadSuccessRate, {
            success,
            type: ClogDispatchType.Font,
        })
    }

    public benchmarkFontLoadTimeAfterDocLoaded(duration: number): void {
        this.uploadReport(BenchmarkType.FontLoadTimeAfterDocLoaded, {
            duration,
            type: ClogDispatchType.Font,
        })
    }

    public benchmarkFontDownloadTimeInBootstrap(duration: number): void {
        this.uploadReport(BenchmarkType.FontDownloadTimeInBootstrap, {
            duration,
            type: ClogDispatchType.Font,
        })
    }

    private benchmarkImageDownloadTime(args: Wukong.DocumentProto.IArgs_BenchmarkImageDownloadCommand) {
        const { type, duration } = args
        const type2Label = {
            [MetricImageType.METRIC_IMAGE_TYPE_ORIGIN]: 'origin',
            [MetricImageType.METRIC_IMAGE_TYPE_PREVIEW]: 'preview',
            [MetricImageType.METRIC_IMAGE_TYPE_EMOJI]: 'emoji',
        }
        this.uploadReport(BenchmarkType.ImageDownloadTimeInBootstrap, {
            duration,
            type: ClogDispatchType.Image,
            tag: type2Label[type!],
        })
    }

    private async benchmarkRenderDuration(args: Wukong.DocumentProto.IArg_MetricRenderDuration) {
        await sleep(0)

        function toBenchmarkType(type: Wukong.DocumentProto.MetricRenderDurationItemType): BenchmarkType {
            switch (type) {
                case Wukong.DocumentProto.MetricRenderDurationItemType.METRIC_RENDER_DURATION_ITEM_TYPE_DOM_TREE_DIRTY:
                    return BenchmarkType.RenderOnDomTreeDirty
                case Wukong.DocumentProto.MetricRenderDurationItemType
                    .METRIC_RENDER_DURATION_ITEM_TYPE_VIEWPORT_SCALE_CHANGED:
                    return BenchmarkType.RenderOnViewportScaleChanged
                case Wukong.DocumentProto.MetricRenderDurationItemType
                    .METRIC_RENDER_DURATION_ITEM_TYPE_VIEWPORT_TRANSLATION_CHANGED:
                    return BenchmarkType.RenderOnViewportTranslationChanged
                case Wukong.DocumentProto.MetricRenderDurationItemType.METRIC_RENDER_DURATION_ITEM_TYPE_OTHER:
                    return BenchmarkType.RenderOnOther
            }
        }

        for (const arg of args.items) {
            const benchmarkType = toBenchmarkType(arg.type)
            this.uploadReport(benchmarkType, {
                type: ClogDispatchType.Render,
                duration: arg.duration,
            })
        }
    }

    private MetricImageDownloadSuccessRation() {
        if (this.bridge) {
            const returnValue = this.bridge.call(GetImageDownloadSuccessRatioCommand)
            if (returnValue.emojiDownloadSuccessRatio !== null && returnValue.emojiDownloadTotalCount !== 0) {
                this.uploadReport(BenchmarkType.ImageDownloadSuccessRatio, {
                    type: ClogDispatchType.Image,
                    ratio: returnValue.emojiDownloadSuccessRatio,
                    imgDownloadSuccessCount: returnValue.emojiDownloadSuccessCount,
                    imgDownloadTotalCount: returnValue.emojiDownloadTotalCount,
                    tag: 'emoji',
                })
            }
            if (returnValue.originDownloadSuccessRatio !== null && returnValue.originDownloadTotalCount !== 0) {
                this.uploadReport(BenchmarkType.ImageDownloadSuccessRatio, {
                    type: ClogDispatchType.Image,
                    ratio: returnValue.originDownloadSuccessRatio,
                    imgDownloadSuccessCount: returnValue.originDownloadSuccessCount,
                    imgDownloadTotalCount: returnValue.originDownloadTotalCount,
                    tag: 'origin',
                })
            }
            if (returnValue.previewDownloadSuccessRatio !== null && returnValue.previewDownloadTotalCount !== 0) {
                this.uploadReport(BenchmarkType.ImageDownloadSuccessRatio, {
                    type: ClogDispatchType.Image,
                    ratio: returnValue.previewDownloadSuccessRatio,
                    imgDownloadSuccessCount: returnValue.previewDownloadSuccessCount,
                    imgDownloadTotalCount: returnValue.previewDownloadTotalCount,
                    tag: 'preview',
                })
            }
        }
    }

    public benchmarkImportSuccessRatio(success: boolean, type: string) {
        this.uploadReport(BenchmarkType.ImportSuccessRatio, {
            type: ClogDispatchType.Import,
            tag: type,
            success,
        })
    }
}

export const benchmarkService = new BenchmarkService()
