import { translation } from './effect-style-list-v2.translation'
/* eslint-disable no-restricted-imports */
import { IconAdjust, Tooltip } from '../../../../../../ui-lib/src'
import classNames from 'classnames'
import { useMemo, useRef } from 'react'
import { Wukong } from '@wukong/bridge-proto'
import { DeepRequired } from '../../../../view-state-bridge'
import { LocalStyleTestId } from '../../../../window'
import { IconButton } from '../../atom/button/icon-button'
import { collapse, expand } from '../../inspect/assets/inspect-svg'
import { RightClickMenuWrapper } from '../../right-click-menu-wrapper'
import { EffectStyleIcon, getEffectStyleIcon } from '../effects/effect-style/effect-style-icon'
import { DragContextProvider } from './drag/drag-context'
import { DragItem } from './drag/drag-item'
import { useStyleFolderCollapseModel } from './hooks/use-doc-style-folder-collapse'
import { LocalStyleModel } from './hooks/use-local-style-model-v2'
import { useStyleListItem } from './hooks/use-style-list-item-v2'
import { StyleName } from './style-name'
import { StyleTitle, StyleTitleProps } from './style-title'
import style from './style.module.less'

const LOCAL_STYLE_SELECTION_TYPE_EFFECT = Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_EFFECT

interface Props {
    model: LocalStyleModel
    hover: string | undefined
    setHover: (value: string) => void
    isReadonly?: boolean
    onChickAddIcon: StyleTitleProps['onChickAddIcon']
}

interface EffectStyleListItemProps {
    item: DeepRequired<Wukong.DocumentProto.ILocalEffectStyleItem>
    itemIndex: number
    model: LocalStyleModel
    hover: string | undefined
    setHover: (value: string) => void
    isReadonly?: boolean
    isFolderCollapse: boolean
    toggleFolderCollapse: (folderName: string) => void
}

const EffectStyleListItem = (props: EffectStyleListItemProps) => {
    const {
        item,
        itemIndex,
        model: { modelCommand, relatedSelectionItems, modelState, isEditingName },
        hover,
        setHover,
        isReadonly,
        isFolderCollapse,
        toggleFolderCollapse,
    } = props

    const {
        itemKey,
        handleMouseDown,
        handleMouseUp,
        itemClassNames,
        isHover,
        isEditingItemName,
        rowRightStyle,
        isOpenPopup,
    } = useStyleListItem({
        type: LOCAL_STYLE_SELECTION_TYPE_EFFECT,
        itemKeyType: 'effect',
        item,
        itemList: modelState?.effectStyleItems ?? [],
        relatedSelectionItems: relatedSelectionItems.effect,
        model: props.model,
        hover,
        style,
        isReadonly,
    })

    return (
        <RightClickMenuWrapper
            source={Wukong.DocumentProto.RightClickSource.RIGHT_CLICK_SOURCE_DESIGN_PANEL}
            stopPropagation
        >
            <DragItem itemIndex={itemIndex} isFolderCollapse={isFolderCollapse}>
                <div
                    data-style-id={itemKey}
                    data-testid={LocalStyleTestId.EffectStyleListItem + itemIndex}
                    className={classNames(...itemClassNames)}
                    onMouseEnter={(): void => setHover(itemKey)}
                    onMouseLeave={(): void => setHover('')}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                >
                    {<div style={{ flex: 1 }}></div>}
                    <div className={style.row_right} style={rowRightStyle}>
                        {item.isFolder ? (
                            <div
                                className={style.svg_container}
                                onMouseDown={(): void => toggleFolderCollapse(item.folderName)}
                            >
                                {isFolderCollapse ? collapse : expand}
                            </div>
                        ) : (
                            <EffectStyleIcon
                                className={style.paint_preview}
                                iconType={getEffectStyleIcon(item.styleNode.effects)}
                            />
                        )}
                        <StyleName
                            isEdit={isEditingItemName}
                            fullName={item.isFolder ? item.folderName : item.styleNode.name}
                            startEditName={() => {
                                modelCommand.updateEditName(item)
                            }}
                            endEditName={() => modelCommand.clearEditName()}
                            rename={(newName) => modelCommand.renameLocalStyleItem(item, newName)}
                            isReadonly={isReadonly}
                        />
                        {!item.isFolder && !isEditingName && (isHover || isOpenPopup) && (
                            <span className={style.hover_icon}>
                                <Tooltip title={translation('EditStyle')}>
                                    <IconButton
                                        icon={<IconAdjust />}
                                        selected={isOpenPopup}
                                        deepColor
                                        onClick={() =>
                                            modelCommand.updateEditStyle({
                                                type: LOCAL_STYLE_SELECTION_TYPE_EFFECT,
                                                editingStyleId: item.styleId,
                                            })
                                        }
                                        dataTestId={LocalStyleTestId.EffectStyleListItem + itemIndex + '-edit-icon'}
                                    />
                                </Tooltip>
                            </span>
                        )}
                    </div>
                </div>
            </DragItem>
        </RightClickMenuWrapper>
    )
}

export const EffectStyleList = (props: Props) => {
    const { modelState } = props.model

    if (modelState?.effectStyleItems?.length) {
        return <EffectStyleListInternal {...props} />
    }
    return null
}

const EffectStyleListInternal = ({ model, hover, setHover, isReadonly, onChickAddIcon }: Props) => {
    const { modelState, selection } = model

    const containerRef = useRef<HTMLDivElement>(null)

    const effectStyleItems = useMemo(() => modelState?.effectStyleItems ?? [], [modelState?.effectStyleItems])
    const folderCollapseModel = useStyleFolderCollapseModel('effect', effectStyleItems)
    const renderItemList = useMemo(() => {
        return effectStyleItems.filter((item) => {
            return folderCollapseModel.isItemVisible(item)
        })
    }, [folderCollapseModel, effectStyleItems])

    const list = renderItemList.map((item, index) => {
        return (
            <EffectStyleListItem
                key={index}
                item={item}
                itemIndex={index}
                model={model}
                hover={hover}
                setHover={setHover}
                isReadonly={isReadonly}
                isFolderCollapse={item.isFolder && folderCollapseModel.isFolderCollapse(item.folderName)}
                toggleFolderCollapse={folderCollapseModel.toggleFolderCollapse}
            ></EffectStyleListItem>
        )
    })

    if (!modelState) {
        return null
    }

    return (
        <>
            {modelState.effectStyleItems.length > 0 ? (
                <StyleTitle
                    title={translation('EffectStyles')}
                    tooltipTitle={translation('CreateNewEffect')}
                    onChickAddIcon={onChickAddIcon}
                    isReadonly={!!isReadonly}
                ></StyleTitle>
            ) : null}
            <div ref={containerRef} data-testid="effect-style-container">
                <DragContextProvider
                    containerRef={containerRef}
                    renderList={renderItemList}
                    selection={selection}
                    handleToggleFolderCollapse={folderCollapseModel.toggleFolderCollapse}
                >
                    {list}
                </DragContextProvider>
            </div>
        </>
    )
}
