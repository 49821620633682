import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Title: 'AI 一致性检查',
    Manage: '管理',
    Desc: 'AI 一致性检查基于选定的设计系统，检查当前页面内与设计系统内组件和样式不一致的图层。',
    Preference: '检查规则',
    Start: '开始检查',
    Cancel: '取消',
    DesignSystem: '设计系统',
    SingleComponent: ' 个组件',
    PluralComponent: ' 个组件',
    Comma: '，',
    SingleStyle: ' 个样式',
    PluralStyle: ' 个样式',
    LocalLibrary: '本地组件库',
    EmptyTip: '未选择设计系统',
    Select: '选择',
    PendingTaskAlert: '你有正在进行的一致性检查任务，请等待任务结束后再试。',

    CloseDialogTitle: '确定退出 AI 一致性检查？',
    CloseDialogText: '退出后，本次检查生成的结果列表将不会被保留。',
    DocSchemaVersionNotSame: '文档数据升级中，请稍后再试',
} as const

export const enTranslation = {
    Title: 'AI Consistency Checker',
    Manage: 'Manage',
    Desc: "Based on the selected design system, AI Consistency Checker can identify layers on the current page that are inconsistent with the system's components and styles.",
    Preference: 'Preference',
    Start: 'Start',
    Cancel: 'Cancel',
    DesignSystem: 'Design systems',
    SingleComponent: ' component',
    PluralComponent: ' components',
    Comma: ', ',
    SingleStyle: ' style',
    PluralStyle: ' styles',
    LocalLibrary: 'Local library',
    EmptyTip: 'No design system selected',
    Select: 'Select',
    PendingTaskAlert: 'You have an ongoing consistency check task. Try again after task completion.',

    CloseDialogTitle: 'Are you sure you want to close AI Consistency Checker?',
    CloseDialogText: 'The result list generated by this check will not be retained.',
    DocSchemaVersionNotSame: 'Document data is being upgraded. Try again later.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
