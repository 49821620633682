import { Wukong } from '@wukong/bridge-proto'
import { ClassWithEffect, EffectController } from '../../../../util/src/effect-controller'
import { isPluginDevelopmentEnabled } from '../../ui/component/plugin/plugin-development/util'
import { ViewStateBridge } from '../../view-state-bridge'
import { translation } from './client-service.translation'

interface PluginMenuEnableStatus {
    visible: boolean
    enable: boolean
    disabledMessage?: string
}

export class ClientService extends ClassWithEffect {
    private handlerMap: Map<string, (_options?: any) => any> = new Map()
    private clientLocalFileChangeHandlerMap: Map<string, Set<(_filePath: string) => void>> = new Map()
    private pluginEnableState: Wukong.DocumentProto.IPluginViewState | null = null

    constructor(private viewStateBridge: ViewStateBridge, private docReadonly: boolean, controller: EffectController) {
        super(controller)
    }
    init = () => {
        window.localBridge?.bindRequestFromWindow?.(this.handleClientRequest)
        this.viewStateBridge.register('editorType', this.editorTypeChange)
        this.viewStateBridge.register('pluginState', this.pluginStateChange)
        this.viewStateBridge.register('historyMode', this.historyModeChange)
        // [how_to_add_client_request]: 2/3, 绑定对应 key 处理客户端发来的请求，再 return 给客户端
        // TODO(lizhaohui): 等客户端版本更新后，下掉 doc-readonly、dev-mode、show-plugin-menu 回调
        this.bindClientRequestHandler('doc-readonly', this.handleClientRequestReadOnly)
        this.bindClientRequestHandler('dev-mode', this.handleClientRequestDevMode)
        this.bindClientRequestHandler('show-plugin-menu', this.handleShowPluginMenu)
        this.bindClientRequestHandler('plugin-menu-enable-status', this.handlePluginMenuEnableStatus)

        return () => {
            this.viewStateBridge.unregister('editorType', this.editorTypeChange)
            this.viewStateBridge.unregister('pluginState', this.pluginStateChange)
            this.viewStateBridge.unregister('historyMode', this.historyModeChange)
            this.unbindClientRequestHandler('doc-readonly')
            this.unbindClientRequestHandler('dev-mode')
            this.unbindClientRequestHandler('show-plugin-menu')
            this.unbindClientRequestHandler('plugin-menu-enable-status')
        }
    }

    public destroy() {
        this.clearClientLocalFileChangeHandler()
    }

    /**
     * 注册客户端请求回调
     * @param key
     * @param handler
     */
    public bindClientRequestHandler(key: string, handler: (options?: any) => any): void {
        if (this.controller.aborted) {
            return
        }
        if (!this.handlerMap.has(key)) {
            this.handlerMap.set(key, handler)
        } else {
            console.error(`ClientService: bindClientRequestHandler: key ${key} already binded`)
        }
    }

    /**
     * 清除客户端请求回调
     * @param key
     * @param handler
     */
    public unbindClientRequestHandler(key: string): void {
        if (this.controller.aborted) {
            return
        }

        this.handlerMap.delete(key)
    }

    // 监听客户端本地文件变化
    public bindClientLocalFileChangeListener = (filePath: string, cb: (filePath: string) => void) => {
        if (!this.clientLocalFileChangeHandlerMap.has(filePath)) {
            this.clientLocalFileChangeHandlerMap.set(filePath, new Set())
            window.localBridge?.registerLocalFileChange?.(filePath, this.clientLocalFileChangeHandler)
        }
        this.clientLocalFileChangeHandlerMap.get(filePath)?.add(cb)
    }

    // 清除文件变更回调
    public unbindClientLocalFileChangeListener = (filePath: string, cb: (filePath: string) => void) => {
        const callbacks = this.clientLocalFileChangeHandlerMap.get(filePath)
        if (callbacks) {
            callbacks.delete(cb)
            if (callbacks.size === 0) {
                this.clientLocalFileChangeHandlerMap.delete(filePath)
                window.localBridge?.unregisterLocalFileChange?.(filePath)
            }
        }
    }

    private clearClientLocalFileChangeHandler = () => {
        this.clientLocalFileChangeHandlerMap.forEach((callbacks, filePath) => {
            window.localBridge?.unregisterLocalFileChange?.(filePath)
        })
        this.clientLocalFileChangeHandlerMap.clear()
    }

    private clientLocalFileChangeHandler = (filePath: string) => {
        const callbacks = this.clientLocalFileChangeHandlerMap.get(filePath)
        if (callbacks) {
            callbacks.forEach((cb) => cb(filePath))
        }
    }

    private handleClientRequest = (key: string, _options?: any): any => {
        const handler = this.handlerMap.get(key)
        if (handler) {
            return handler(_options)
        }
        console.error(`ClientService: handleClientRequest: key ${key} not binded`)
        return null
    }

    private editorTypeChange = () => {
        window.localBridge?.updatePluginMenu?.()
    }

    private pluginStateChange = (state: Wukong.DocumentProto.IPluginViewState) => {
        if (
            this.pluginEnableState?.enableContextMenuPlugin !== state.enableContextMenuPlugin ||
            this.pluginEnableState?.enableLastPlugin !== state.enableLastPlugin
        ) {
            window.localBridge?.updatePluginMenu?.()
        }
        // TODO(lizhaohui): 临时优化方案，减少客户端更新菜单的次数
        // pluginState viewState 会因画布区点击、切换选区，频繁更新 NP_editingPaint 和 NP_editingVectorState，但实际两个值内容没变，从而导致 pluginState 频繁更新
        this.pluginEnableState = state
    }

    private historyModeChange = () => {
        window.localBridge?.updatePluginMenu?.()
    }

    private handleClientRequestReadOnly = () => {
        return this.docReadonly
    }

    private handleClientRequestDevMode = () => {
        const isDevMode =
            this.viewStateBridge.getDefaultValue('editorType') === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV
        return isDevMode
    }

    private handleShowPluginMenu = () => {
        const pluginState = this.viewStateBridge.getDefaultValue('pluginState')
        return pluginState?.enableContextMenuPlugin
    }

    private handlePluginMenuEnableStatus = (): PluginMenuEnableStatus => {
        if (!isPluginDevelopmentEnabled()) {
            return {
                visible: false,
                enable: false,
            }
        }
        if (this.docReadonly) {
            return {
                visible: true,
                enable: false,
                disabledMessage: translation('ReadOnlyDisabledMessage'),
            }
        }

        const isDevMode =
            this.viewStateBridge.getDefaultValue('editorType') === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV
        if (isDevMode) {
            return {
                visible: true,
                enable: false,
                disabledMessage: translation('DevModeDisabledMessage'),
            }
        }

        const isHistoryMode = this.viewStateBridge.getDefaultValue('historyMode')
        if (isHistoryMode) {
            return {
                visible: true,
                enable: false,
                disabledMessage: translation('HistoryModeDisabledMessage'),
            }
        }

        const showPluginMenu = this.viewStateBridge.getDefaultValue('pluginState')?.enableContextMenuPlugin
        if (!showPluginMenu) {
            return {
                visible: false,
                enable: false,
            }
        }

        return {
            visible: true,
            enable: true,
        }
    }
}
