import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    Frame: '容器',
    FrameContent1: '使用「容器」图层划定一个界面区域。',
    FrameContent2:
        '你可以给容器添加自动布局、在容器内嵌套其他容器、对容器内的子图层设置约束，由此帮助你创建更复杂的设计内容。',
    VN: '矢量网络',
    VNContent1: '使用矢量网络更自然的绘制你想要的图形，即使他们复杂如网络。',
    VNContent2: '你可以自由调整图形的圆角、描边、填充等。',
    Comment: '评论',
    CommentContent1: '使用评论工具在文件中留下批注，与工作伙伴交流意见。',
    CommentContent2: '你也可以在评论面板中查看新评论及回复。',
    AIContent1: '使用 Motiff AI 助力你的设计工作。',
    AIContent2: 'AI 工具箱、AI 设计系统、Motiff AI Lab，你想要的都在这里。',
    Share: '分享和权限',
    ShareContent1: '将设计文件分享给同事、客户等工作伙伴。你可以分别给予他们不同的权限，并在此处集中管理。',
    ShareContent2: '你还可以通过链接快速分享文件给他人。',
    Multiplayer: '多人协作',
    MultiplayerContent1: '与团队实时协作，无需担心你的文件是否是最新的。',
    MultiplayerContent2: '无需同步，无需等待，没有合并冲突。',
    TeamLibrary: '组件库',
    TeamLibraryContent1: '发布当前文件内的组件和样式到团队库来与整个团队共享。',
    TeamLibraryContent2: '也可以快速在当前文件中引用共享给你的库。',
    Prototype: '原型',
    PrototypeContent1: '从设计一键切换至原型，在画布区即可创建交互流程。快速搭建高保真、可交互原型，验证方案更轻松。',
    Device: '设备预览',
    DeviceContent1: '想在移动设备上实时查看你的设计，只需在移动端设备上下载 Motiff。',
    DeviceContent2: '无需 USB 连接，连接网络就可实现实时预览。',
} as const

export const enTranslation = {
    Frame: 'Frames',
    FrameContent1: 'Use Frame to draw an interface area.',
    FrameContent2:
        'To create more complex designs, you can add auto-layout to the frames, nest other frames within them, and set constraints on the child layer.',
    VN: 'Vector networks',
    VNContent1: 'Use vector networks to draw the shapes you want more naturally, even though they are very complex.',
    VNContent2: 'You can freely adjust the radius, strokes, fills, etc. ',
    Comment: 'Comments',
    CommentContent1: 'Leave comments to the file and exchange ideas with your teammates. ',
    CommentContent2: 'You can view all new comments and replies in the comment panel. ',
    AIContent1: 'Empower your design with Motiff AI.',
    AIContent2: 'Everything you need is in our AI Toolbox, AI Design Systems, and Motiff AI Lab.',
    Share: 'Sharing and Permission',
    ShareContent1:
        'Share design files with colleagues, clients, and other teammates. You can assign them with different access permissions and manage them centrally.',
    ShareContent2: 'You can also quickly share files with others via links.',
    Multiplayer: 'Multiplayer',
    MultiplayerContent1: 'Real-time collaboration with your team ensures your files are always up-to-date. ',
    MultiplayerContent2: 'No syncing, no waiting, no merge conflicts.',
    TeamLibrary: 'Team library',
    TeamLibraryContent1: 'Share components and styles across your team by publishing them to the team library. ',
    TeamLibraryContent2: 'You can also allow quick access to other libraries in this file. ',
    Prototype: 'Prototype',
    PrototypeContent1: 'Swiftly create high-fidelity, interactive prototypes with Motiff for easy validation. ',
    Device: 'Live device preview',
    DeviceContent1: 'You can download Motiff app on your mobile device to view your designs in real time. ',
    DeviceContent2:
        "Log in to the same Motiff account no matter what network you're on and with no need to connect via USB.",
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
