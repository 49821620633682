import { UndoRedoCommand, Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { isNil, omit } from 'lodash-es'
import React, { HTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { usePointerCapture } from '../../../../../../ui-lib/src'
import { isKey, KeyboardCode } from '../../../../kernel/keyboard/keyboard-event-handler'
import { useCommand } from '../../../context/document-context'
import styles from './index.module.less'

interface SliderProps
    extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange' | 'onPointerDown' | 'onPointerMove' | 'onPointerUp'> {
    curValue: number // 当前值
    minValue: number // 最小值
    maxValue: number //最大值
    spiltValues: number[] // 分割线的数值
    defaultValue: number // 在该处 circle 是空心
    onChange: (value: number) => void
    isMixed: boolean // 混合值不显示圆圈
    disabled?: boolean // 是否禁用
    step: number // 步长
}

function isNear(x: number, y: number, eps = 1e-5) {
    return Math.abs(x - y) < eps
}

export function RangeV2(props: SliderProps) {
    const {
        curValue,
        minValue,
        maxValue,
        spiltValues,
        isMixed,
        defaultValue,
        onChange,
        onClick,
        onFocus,
        onBlur,
        onMouseDown,
        disabled,
        step,
    } = props

    const otherProps = omit(
        props,
        'className',
        'onChange',
        'onClick',
        'onMouseDown',
        'onPointerUp',
        'onPointerDown',
        'onPointerMove',
        'onFocus',
        'onBlur',
        'curValue',
        'minValue',
        'maxValue',
        'spiltValues',
        'isMixed',
        'defaultValue',
        'disabled',
        'step'
    )

    const [innerCycleStyle, setInnerCycleStyle] = useState<object>({})

    const [active, setActive] = useState<boolean>(false)

    const [circleFilled, setCircleFilled] = useState<boolean>(curValue !== defaultValue)

    const sliderRef = useRef<HTMLDivElement>(null)

    const getClosestSplitValue = useCallback(
        (value: number) => {
            let diff = Number.MAX_VALUE
            let closestSplitValue = value
            spiltValues.forEach((split) => {
                if (Math.abs(split - value) < diff) {
                    diff = Math.abs(split - value)
                    closestSplitValue = split
                }
            }, [])
            return { closestSplitValue, diff }
        },
        [spiltValues]
    )

    const getNextValue = useCallback(
        (v: number, maxDistance: number) => {
            const { closestSplitValue, diff } = getClosestSplitValue(v)
            const ret = !isNil(closestSplitValue) && diff < maxDistance ? closestSplitValue : v
            return Math.round(ret / step) * step
        },
        [getClosestSplitValue, step]
    )

    const calculateCycle = useCallback(
        (clientOffset: number) => {
            if (disabled) {
                return
            }
            const { left, width } = sliderRef.current!.getBoundingClientRect()
            const offset = left
            const length = width

            let cycleOffset = clientOffset - offset
            cycleOffset = cycleOffset < 0 ? 0 : cycleOffset > length ? length : cycleOffset

            let nextValue = (cycleOffset / length) * (maxValue - minValue) + minValue
            const attachDistance = (4 / width) * (maxValue - minValue) // 4px 吸附距离
            nextValue = getNextValue(nextValue, attachDistance)
            setCircleFilled(!isNear(defaultValue, nextValue))
            onChange?.(nextValue)
        },
        [defaultValue, disabled, getNextValue, maxValue, minValue, onChange]
    )

    const _onClick = useCallback(
        (e: any) => {
            e.stopPropagation()
            onClick?.(e)
        },
        [onClick]
    )

    const _onMouseDown = useCallback(
        (e: any) => {
            e.stopPropagation()
            onMouseDown?.(e)
        },
        [onMouseDown]
    )

    const command = useCommand()
    const captureStart = useCallback(
        (e: React.PointerEvent) => {
            command.commitUndo()
            calculateCycle(e.clientX)
        },
        [calculateCycle, command]
    )

    const capturing = useCallback(
        (e: React.PointerEvent) => {
            calculateCycle(e.clientX)
        },
        [calculateCycle]
    )

    const captureEnd = useCallback(() => {
        command.commitUndo()
    }, [command])

    const onPointerMove = useCallback((e: React.PointerEvent) => {
        e.preventDefault()
    }, [])

    const { pointerdown, pointermove, pointerup } = usePointerCapture({
        captureStart,
        capturing,
        captureEnd,
        onPointerMove,
    })

    const _onFocus = useCallback(
        (e: any) => {
            setActive(true)
            onFocus?.(e)
        },
        [onFocus]
    )

    const _onBlur = useCallback(
        (e: any) => {
            setActive(false)
            onBlur?.(e)
        },
        [onBlur]
    )
    const _onKeydown = useCallback(
        (e: any) => {
            if (isKey(e, KeyboardCode.Z)) {
                e.stopPropagation()
                const { metaKey, ctrlKey, shiftKey, altKey } = e
                command.DEPRECATED_invokeBridge(
                    UndoRedoCommand,
                    Wukong.DocumentProto.UndoRedoCommandParam.create({ metaKey, ctrlKey, shiftKey, altKey })
                )
            }
        },
        [command]
    )

    const splits = useMemo(() => {
        return (
            <>
                {spiltValues.map((value, index) => {
                    const ratio = parseFloat(((value - minValue) / (maxValue - minValue)).toFixed(4))
                    return (
                        <div
                            key={index}
                            className={styles.split}
                            style={{
                                left: `${ratio * 100}%`,
                            }}
                            data-testid={`slider-split-${index}`}
                        ></div>
                    )
                })}
            </>
        )
    }, [maxValue, minValue, spiltValues])

    useEffect(() => {
        if (sliderRef.current) {
            const { width } = sliderRef.current.getBoundingClientRect()
            const rate = Math.min(1, Math.max((curValue - minValue) / (maxValue - minValue), 0))
            setInnerCycleStyle({ left: width * rate + 'px' })
        }
    }, [curValue, maxValue, minValue])

    useEffect(() => {
        setCircleFilled(!isNear(defaultValue, curValue))
    }, [curValue, defaultValue])

    return (
        <div
            onClick={_onClick}
            onMouseDown={_onMouseDown}
            onPointerDown={pointerdown}
            onPointerUp={pointerup}
            onPointerMove={pointermove}
            onFocus={_onFocus}
            onBlur={_onBlur}
            onKeyDown={_onKeydown}
            className={styles.container}
            {...otherProps}
            tabIndex={0}
            data-testid={'slider'}
            // 避免触发可拖拽弹窗的拖拽
            data-disabled-drag-move={'true'}
        >
            <div ref={sliderRef} className={styles.line}>
                {splits}
                {!isMixed && (
                    <span
                        className={classnames(
                            styles.cycle,
                            circleFilled ? styles.circleFilled : styles.circleEmpty,
                            active && styles.active,
                            disabled && styles.disabled
                        )}
                        style={innerCycleStyle}
                    ></span>
                )}
            </div>
        </div>
    )
}
