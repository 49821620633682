/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { InputV2, MonoIconPanelArrowRight16, WKButton, WKToast } from '../../../../../ui-lib/src'
import { isKey, KeyboardCode } from '../../../kernel/keyboard/keyboard-event-handler'
import { shouldPassThisEventToWasmWhenButtonFocused } from '../../../main/service/focus-view/keyboard-event-prevent-or-pass'
import { dispatchKeyboardEventToWasm } from '../../../main/service/focus-view/keyboard-event-to-wasm'
import { useCommand, useFontManagerService } from '../../context/document-context'
import styles from './canvas-search-replace.module.less'
import { translation } from './canvas-search-replace.translation'
import { CanvasSearchParamModel, CanvasSearchResultModel } from './use-canvas-search-model'

interface ModelProps {
    canvasSearchParamModel: CanvasSearchParamModel
    canvasSearchResultModel: CanvasSearchResultModel
}

export function CanvasSearchReplace({ canvasSearchParamModel, canvasSearchResultModel }: ModelProps) {
    const { modelState, replaceValue, setReplaceValue } = canvasSearchParamModel
    const isResultEmpty = !canvasSearchResultModel.modelState?.totalCount
    const command = useCommand()
    const fontManagerService = useFontManagerService()
    const showFontMissDialog = () => {
        fontManagerService.openFontMissingDialog()
    }

    const showFontMissToast = () => {
        WKToast.show(translation('CannotReplaceText'), {
            firstButton: {
                type: 'button',
                text: translation('ShowMissingFonts'),
                onClick: showFontMissDialog,
            },
        })
    }

    const replaceSelected = () => {
        const result = canvasSearchResultModel.modelCommand.repalceSelected(replaceValue)
        if (result.hasMissFont) {
            showFontMissToast()
        }
    }

    const replaceAll = () => {
        const result = canvasSearchResultModel.modelCommand.repalceAll(replaceValue)
        if (result.hasMissFont) {
            showFontMissToast()
        } else {
            if (result.totalCount) {
                const scopeLabel =
                    modelState.scope == Wukong.DocumentProto.CanvasSearchScope.CANVAS_SEARCH_SCOPE_CURRENT_PAGE
                        ? translation('SFtNKo')
                        : translation('uPBzBL')

                WKToast.show(
                    translation('Replaced{{num}}Results', {
                        scopeLabel: scopeLabel,
                        num: result.totalCount,
                        result: result.totalCount > 1 ? 'results' : 'result',
                    })
                )
            }
        }
    }

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.nativeEvent.isComposing) {
            return
        }

        if (isKey(event.nativeEvent, KeyboardCode.ENTER)) {
            replaceSelected()
        }
    }

    const handleButtonKeyDown: React.KeyboardEventHandler<HTMLButtonElement> = (event) => {
        if (shouldPassThisEventToWasmWhenButtonFocused(event.nativeEvent)) {
            dispatchKeyboardEventToWasm(
                command,
                event.nativeEvent,
                Wukong.DocumentProto.KeyboardEventTraceSource.KEYBOARD_EVENT_TRACE_SOURCE_CANVAS_SEARCH
            )
        }
    }

    if (!modelState?.show || modelState?.mode !== Wukong.DocumentProto.CanvasSearchMode.CANVAS_SEARCH_MODE_REPLACE) {
        return null
    }

    return (
        <div className={styles.root}>
            <div className={styles.searchContainer}>
                <InputV2.Search
                    className={styles.search}
                    placeholder={translation('ReplaceWith…')}
                    prefixIcon={<MonoIconPanelArrowRight16 />}
                    autoFocus={modelState.value != ''}
                    value={replaceValue}
                    onKeyDown={handleKeyDown}
                    onSearch={setReplaceValue}
                    dataTestIds={{ input: 'canvas-search-replace-input', clearIcon: 'icon-close-svg' }}
                />
            </div>
            <div className={styles.btns}>
                <WKButton
                    type="secondary"
                    size="medium"
                    disabled={isResultEmpty}
                    onClick={replaceSelected}
                    onKeyDown={handleButtonKeyDown}
                >
                    {translation('Replace')}
                </WKButton>
                <WKButton
                    type="secondary"
                    size="medium"
                    disabled={isResultEmpty}
                    onClick={replaceAll}
                    onKeyDown={handleButtonKeyDown}
                >
                    {translation('ReplaceAll')}
                </WKButton>
            </div>
        </div>
    )
}
