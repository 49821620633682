import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Export: '导出',
    Export2: '批量导出',
    Preview: '预览',
    ImageCompress: '图片压缩',
    'Non-compressed': '不压缩',
    Compressed: '压缩',
} as const

export const enTranslation = {
    Export: 'Export',
    Export2: 'Export',
    Preview: 'Preview',
    ImageCompress: 'Image compress',
    'Non-compressed': 'Non-compressed',
    Compressed: 'Compressed',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
