import { Wukong } from '@wukong/bridge-proto'
import { ScrubbableInput, ScrubbableInputProps } from '../../../atom/inputs/components/scrubbable-input'
import { translation } from './interaction-delay-input.translation'

import InteractionType = Wukong.DocumentProto.InteractionType
import { FC } from 'react'

export const InteractionDelayInput: FC<
    {
        type: InteractionType
    } & Omit<ScrubbableInputProps, 'type'>
> = (props) => {
    const { value, isMixed, formatter, onChange } = props

    return (
        <div className="w-30 h-7">
            <ScrubbableInput
                value={value}
                formatter={formatter}
                onChange={onChange}
                resolution={10}
                min={0}
                max={10000}
                placeholder={translation('AddDelay')}
                defaultShowEmpty={!value}
                isMixed={isMixed}
                leftScrubbable={!!value && !isMixed}
                rightScrubbable={!!value && !isMixed}
                testId="interaction-delay-input"
            />
        </div>
    )
}
