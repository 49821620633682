/**
 * @owner: wangshuleibj@kanyun.com
 */
import classNames from 'classnames'
import { isNil } from 'lodash-es'
import type { HTMLAttributes, ReactNode } from 'react'
import { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { IconCheckboxChecked, IconCheckboxMixed } from '../../svg-icon/16/common'
import style from './index.module.less'

export interface CheckboxProps extends Omit<HTMLAttributes<HTMLInputElement>, 'onChange'> {
    checked?: boolean
    indeterminate?: boolean
    disabled?: boolean
    label?: ReactNode
    labelContainerClassName?: string
    onChange?: (checked: boolean) => void
    autoBlur?: boolean
    testid?: string
    stopPropagation?: boolean
    // small - 12px, medium - 14px, large - 16px
    size?: 'small' | 'medium' | 'large'
    containerTestId?: string
}
export const Checkbox = (props: CheckboxProps) => {
    const {
        stopPropagation,
        className,
        checked,
        indeterminate,
        label,
        disabled,
        onChange,
        size = 'small',
        containerTestId,
        labelContainerClassName,
    } = props
    const [checkboxId, _] = useState<string>(`checkbox_${Date.now()}_${Math.floor(Math.random() * 1e8)}`)
    const inputRef = useRef<HTMLInputElement>(null)
    const autoBlur = props.autoBlur ?? true

    useLayoutEffect(() => {
        if (inputRef.current && !isNil(indeterminate)) {
            inputRef.current.indeterminate = indeterminate
        }
    }, [indeterminate])

    const _onChange = useCallback(() => {
        let value: boolean
        if (indeterminate) {
            value = true
        } else {
            value = !checked
        }
        onChange?.(value)
        if (autoBlur) {
            const sto = setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.blur() // 移除 checkbox input 的 focus 状态, 清空 document.activeElement, 以便让全局快捷键生效
                }
                clearTimeout(sto)
            })
        }
    }, [autoBlur, checked, indeterminate, onChange])

    const _onKeyDown = useCallback(
        (e: React.KeyboardEvent) => {
            if (e.code === 'Enter' || e.code === 'Space') {
                e.stopPropagation()
                _onChange()
            }
        },
        [_onChange]
    )

    return (
        <div
            onClick={(e) => {
                if (stopPropagation) {
                    e.stopPropagation()
                }
            }}
            className={classNames(style.container, style[size], className, disabled && style.disabled)}
            data-testid={containerTestId ?? 'wk-checkbox'}
        >
            <div className={style.iconContainer}>
                <input
                    ref={inputRef}
                    type="checkbox"
                    checked={checked}
                    id={checkboxId}
                    className={style.checkbox}
                    onChange={_onChange}
                    tabIndex={-1}
                    disabled={disabled}
                    data-testid={props.testid}
                />
                <span
                    className={classNames(
                        style.normalIcon,
                        indeterminate ? style.indeterminateIcon : checked ? style.checkedIcon : ''
                    )}
                    onKeyDown={_onKeyDown}
                >
                    <div tabIndex={disabled ? -1 : 0} className={style.focusElement}></div>
                    <span
                        className={style.svgContainer}
                        data-testid={`checkbox-${indeterminate ? 'mixed' : checked ? 'checked' : 'unchecked'}-icon`}
                    >
                        {indeterminate ? <IconCheckboxMixed /> : checked ? <IconCheckboxChecked /> : null}
                    </span>
                </span>
            </div>
            {label ? (
                <label className={classNames(style.label, labelContainerClassName)} htmlFor={checkboxId}>
                    {label}
                </label>
            ) : null}
        </div>
    )
}
