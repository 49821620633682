import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    StrokeStyle: '描边样式',
    Solid: '正常',
    Dash: '虚线',
} as const

export const enTranslation = {
    StrokeStyle: 'Stroke style',
    Solid: 'Solid',
    Dash: 'Dash',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
