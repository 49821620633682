import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    默认合集名称: '合集',
    默认模式名称: '模式',
    默认颜色变量名称: '颜色',
    默认分组名称: '分组',
    变量名称格式错误: '变量名称不能包含下列字符「$ . { }」',
    分组名称格式错误: '分组名称不能包含下列字符「$ . { }」',
    变量名称不能重复: '合集内的变量名称不能重复',
    变量不能被循环引用: '变量不能循环引用',
    变量不能引用自身: '变量不能引用自身',
} as const

export const enTranslation = {
    默认合集名称: 'Collection',
    默认模式名称: 'Mode',
    默认颜色变量名称: 'Color',
    默认分组名称: 'New group',
    变量名称格式错误: 'Variable names cannot contain the following characters: "$ . { }".',
    分组名称格式错误: 'Group names cannot contain the following characters: "$ . { }".',
    变量名称不能重复: 'Variable names cannot be repeated within a collection.',
    变量不能被循环引用: 'Variables cannot have circular references.',
    变量不能引用自身: 'Variables cannot reference themselves.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
