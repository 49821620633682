import constate from 'constate'
import { useCallback } from 'react'
import { AutoItem, CurrentItem, UserItem } from './type'

export interface HistoryListContextProps {
    selectVersionId: number
    isReadonly: boolean
    clickVersion: (value: UserItem | AutoItem | CurrentItem) => void
    editVersionInfo: (value: UserItem | AutoItem) => void
    clearVersionInfo: (value: UserItem | AutoItem) => void
    applyVersion: (value: UserItem | AutoItem) => void
    copyVersion: (value: UserItem | AutoItem) => void
    copyVersionLink: (value: UserItem | AutoItem) => void
}

function useHistoryListConstate(props: HistoryListContextProps) {
    const isSelectVersion = useCallback(
        (versionId: number) => {
            return props.selectVersionId === versionId
        },
        [props.selectVersionId]
    )
    return { ...props, isSelectVersion }
}

export const [
    HistoryListProvider,
    useSelectVersionId,
    useIsReadonly,
    useClickVersion,
    useEditVersionInfo,
    useClearVersionInfo,
    useApplyVersion,
    useCopyVersion,
    useCopyVersionLink,
    useIsSelectVersion,
] = constate(
    useHistoryListConstate,
    (ctx) => ctx.selectVersionId,
    (ctx) => ctx.isReadonly,
    (ctx) => ctx.clickVersion,
    (ctx) => ctx.editVersionInfo,
    (ctx) => ctx.clearVersionInfo,
    (ctx) => ctx.applyVersion,
    (ctx) => ctx.copyVersion,
    (ctx) => ctx.copyVersionLink,
    (ctx) => ctx.isSelectVersion
)

// export interface HistoryListContextProps {
//     selectVersionId: number
//     isReadonly: boolean
//     editVersionInfo: (value: UserItem | AutoItem) => void
//     clearVersionInfo: (value: UserItem | AutoItem) => void
//     applyVersion: (value: UserItem | AutoItem) => void
//     copyVersion: (value: UserItem | AutoItem) => void
//     copyVersionLink: (value: UserItem | AutoItem) => void
// }
// export const defaultHistoryListContext: HistoryListContextProps = {
//     selectVersionId: '',
//     isReadonly: false,
//     editVersionInfo: () => {},
//     clearVersionInfo: () => {},
//     applyVersion: () => {},
//     copyVersion: () => {},
//     copyVersionLink: () => {},
// }
// export const HistoryContext = React.createContext<HistoryListContextProps>(defaultHistoryListContext)
