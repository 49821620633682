import { translation } from './history-title.translation'
/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { useCallback } from 'react'
import {
    DropdownV2,
    IconAdd16,
    MonoIconCommonClose16,
    MonoIconPanelFilter16,
    Tooltip,
    WKIconButton,
} from '../../../../../../ui-lib/src'
import { IconButton } from '../../atom/button/icon-button'
import classes from './history-title.module.less'

export enum SortFilter {
    AllVersion,
    MyVersion,
    AutoVersion,
}

export interface HistoryTitleProps {
    isReadonly: boolean
    isDifferentSelectSort: boolean
    selectSort: SortFilter[]
    onChangeSort: (params: SortFilter[]) => void
    onClickClose?: () => void
    onClickAdd?: () => void
}
export function HistoryTitle(props: HistoryTitleProps) {
    const { isReadonly, isDifferentSelectSort, selectSort, onChangeSort, onClickClose, onClickAdd } = props

    const _onChangeSort = useCallback(
        (value: SortFilter) => {
            if (value === SortFilter.AutoVersion) {
                if (selectSort.includes(value)) {
                    onChangeSort(selectSort.filter((v) => v !== SortFilter.AutoVersion))
                } else {
                    onChangeSort([...selectSort, SortFilter.AutoVersion])
                }
            } else {
                if (selectSort.includes(value)) {
                    return
                }
                if (selectSort.includes(SortFilter.AutoVersion)) {
                    onChangeSort([value, SortFilter.AutoVersion])
                } else {
                    onChangeSort([value])
                }
            }
        },
        [onChangeSort, selectSort]
    )

    return (
        <div className={classnames(classes.title)}>
            <div className={classes.titleName}>{translation('VersionHistory')}</div>
            <div className={classes.titleTool}>
                <Tooltip title={translation('Filter')} triggerRefKey="getTriggerElement">
                    <DropdownV2.IconSingleLevel
                        placement="bottom right"
                        label={<MonoIconPanelFilter16 />}
                        onChange={_onChangeSort}
                        dataTestIds={{ triggerFocus: 'history-sort-icon' }}
                        className={classes.sortIconV2}
                        classNameTriggerFocus={isDifferentSelectSort ? classes.specialSortIcon : undefined}
                    >
                        <DropdownV2.IconSingleLevel.Option
                            value={SortFilter.AllVersion}
                            dataTestId="history-sort-item"
                            isSelect={selectSort.includes(SortFilter.AllVersion)}
                        >
                            {translation('All')}
                        </DropdownV2.IconSingleLevel.Option>
                        <DropdownV2.IconSingleLevel.Option
                            value={SortFilter.MyVersion}
                            dataTestId="history-sort-item"
                            isSelect={selectSort.includes(SortFilter.MyVersion)}
                        >
                            {translation('OnlyYours')}
                        </DropdownV2.IconSingleLevel.Option>
                        <DropdownV2.IconSingleLevel.Option
                            value={SortFilter.AutoVersion}
                            dataTestId="history-sort-item"
                            isSelect={selectSort.includes(SortFilter.AutoVersion)}
                            splitLineTop
                        >
                            {translation('ShowAutosaveVersion')}
                        </DropdownV2.IconSingleLevel.Option>
                    </DropdownV2.IconSingleLevel>
                </Tooltip>
                {isReadonly ? null : (
                    <Tooltip title={translation('AddToVersion')}>
                        <IconButton
                            className={classes.addIcon}
                            icon={<IconAdd16 />}
                            selected={false}
                            onClick={onClickAdd}
                            data-testid="history-panel-add-version"
                        />
                    </Tooltip>
                )}
                <Tooltip title={translation('Close')}>
                    <WKIconButton
                        className={classes.closeIcon}
                        icon={<MonoIconCommonClose16 />}
                        onClick={onClickClose}
                        data-testid="history-panel-close"
                    />
                </Tooltip>
            </div>
        </div>
    )
}
