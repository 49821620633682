/* eslint-disable no-restricted-imports */
import { DispatchMouseEventCommand, UpdateMouseCameraPositionCommand, Wukong } from '@wukong/bridge-proto'
import { DeepRequired } from '../../../../util/src'
import { CommandInvoker } from '../../document/command/command-invoker'
import {
    CanvasMouseEventTypeMap,
    translateClientRelativePositionToTargetOffsetPosition,
} from '../../main/canvas/canvas-event-handler'

type MouseEventLike = {
    [k in keyof Omit<
        DeepRequired<Wukong.DocumentProto.IArg_dispatchMouseEvent>,
        'triggerByKeyboard' | 'type' | 'acceptable'
    >]: MouseEvent[k]
}
export function buildMouseEvent(type: MouseEvent['type'], e: MouseEventLike) {
    return {
        type,
        clientX: e.clientX,
        clientY: e.clientY,
        movementX: e.movementX,
        movementY: e.movementY,
        altKey: e.altKey,
        ctrlKey: e.ctrlKey,
        metaKey: e.metaKey,
        shiftKey: e.shiftKey,
    }
}

export function dispatchMouseEventSpecially(
    e: ReturnType<typeof buildMouseEvent>,
    command: CommandInvoker,
    canvas: HTMLCanvasElement
) {
    const { x, y } = translateClientRelativePositionToTargetOffsetPosition(e, canvas)
    const param = {
        type: CanvasMouseEventTypeMap[e.type],
        clientX: x,
        clientY: y,
        movementX: e.movementX,
        movementY: e.movementY,
        altKey: e.altKey,
        ctrlKey: e.ctrlKey,
        metaKey: e.metaKey,
        shiftKey: e.shiftKey,
    }
    command.DEPRECATED_invokeBridge(
        DispatchMouseEventCommand,
        Wukong.DocumentProto.Arg_dispatchMouseEvent.create(param)
    )
    command.DEPRECATED_invokeBridge(
        UpdateMouseCameraPositionCommand,
        Wukong.DocumentProto.Arg_cmdUpdateMouseCameraPosition.create({
            ...param,
            valueType: Wukong.DocumentProto.ProtoValueType.PROTO_VALUE_TYPE_HAS_VALUE,
        })
    )
}
