import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    UpdatingWarningTitle: '团队升级中',
    UpdatingWarningContentPrefix: '管理员正在升级「',
    UpdatingWarningContentPostfix: '」团队，当前无法执行编辑操作。',
} as const

export const enTranslation = {
    UpdatingWarningTitle: 'Upgrading the team',
    UpdatingWarningContentPrefix: 'The admin is upgrading "',
    UpdatingWarningContentPostfix: '". Editing is currently unavailable.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
