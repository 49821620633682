import { Position } from '../../../../../../ui-lib/src'
import { ColorSet } from '../color-interaction/color-set/color-set'
import { useEditingPaintStyle } from '../styles/hooks/use-editing-style'

export interface StyleViewerProps {
    open?: boolean
    position?: Position
    onClose: () => void
}

export const StyleViewer = (props: StyleViewerProps) => {
    const { open, position, onClose } = props
    const style = useEditingPaintStyle()

    if (!style) {
        return null
    }

    return open && position ? (
        <ColorSet
            position={position}
            isCreateStyle={false}
            allwaysUseStyleTab={false}
            isRemoteStyle
            hideCommonFooter
            paints={[]}
            hideStyleTab={false}
            hideVariableTab
            onCancel={onClose}
            onClickCreateButton={() => {}}
            onCreateStyle={() => {}}
            onCreateColorVariable={() => {}}
            onClickRemoteStyleLink={undefined}
        />
    ) : null
}
