import { FC, useMemo } from 'react'
import styles from './index.module.less'
interface DevModeLayerPanelRenderItemTextContentProps {
    textContent: string | null | undefined
}

export const DevModeLayerPanelRenderItemTextContent: FC<DevModeLayerPanelRenderItemTextContentProps> = ({
    textContent,
}) => {
    return useMemo(
        () =>
            textContent ? (
                <div className={styles.devModeLayerPanelRenderItemTextContent}>{textContent.trim()}</div>
            ) : null,
        [textContent]
    )
}
