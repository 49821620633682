import { translation } from './canvas-search-setting.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { Tag } from '../../../../../ui-lib/src'
import styles from './canvas-search-setting.module.less'
import { CanvasSearchParamModel } from './use-canvas-search-model'

const NodeFilterTypeLabelMap: Record<Wukong.DocumentProto.CanvasSearchNodeTypeFilter, string> = {
    [Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_ALL]: translation('All'),
    [Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_TEXT]: translation('Text'),
    [Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_FRAME]: `${translation(
        'Frame'
    )}/${translation('Group')}`,
    [Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_COMPONENT]:
        translation('Component'),
    [Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_INSTANCE]: translation('Instance'),
    [Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_IMAGE]: translation('Image'),
    [Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_SHAPE]: translation('Shape'),
    [Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_OTHER]: translation('Other'),
}

export function CanvasSearchSetting({ canvasSearchParamModel }: { canvasSearchParamModel: CanvasSearchParamModel }) {
    const isReplaceMode =
        canvasSearchParamModel.modelState.mode == Wukong.DocumentProto.CanvasSearchMode.CANVAS_SEARCH_MODE_REPLACE

    const tags = []

    if (
        canvasSearchParamModel.modelState.nodeFilterType !==
            Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_ALL &&
        !isReplaceMode
    ) {
        tags.push(
            <Tag
                name={NodeFilterTypeLabelMap[canvasSearchParamModel.modelState.nodeFilterType]}
                key="nodeFilterType"
                structure="line-surface"
                closable
                onDelete={() =>
                    canvasSearchParamModel.modelCommand.updateNodeFilterType(
                        Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_ALL
                    )
                }
            />
        )
    }

    if (canvasSearchParamModel.modelState.matchCase) {
        tags.push(
            <Tag
                name={`${translation('MatchCase')} ${translation('fUWyLG')}`}
                key="matchCase"
                structure="line-surface"
                closable
                onDelete={() => canvasSearchParamModel.modelCommand.updateMatchCase(false)}
            />
        )
    }

    if (canvasSearchParamModel.modelState.wholeWords) {
        tags.push(
            <Tag
                name={`${translation('WholeWords')} ${translation('fUWyLG')}`}
                key="wholeWords"
                structure="line-surface"
                closable
                onDelete={() => canvasSearchParamModel.modelCommand.updateWholeWords(false)}
            />
        )
    }

    if (tags.length <= 0) {
        return null
    }

    return (
        <div className={styles.searchSetting} data-testid="canvas-search-settings">
            {tags}
        </div>
    )
}
