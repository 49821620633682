import { PermissionOptionType } from '../service/space-frog-service'
import { RoleStatus } from './type'
import { translation } from './user-roles.translation'

export const GetDocRoleStatus = () => {
    return [RoleStatus.Owner, RoleStatus.Editor, RoleStatus.Viewer, RoleStatus.ViewPrototype, RoleStatus.None]
}
export const GetFolderRoleStatus = () => {
    return [RoleStatus.Owner, RoleStatus.Editor, RoleStatus.Viewer, RoleStatus.None]
}

export const GetTeamRoleStatus = () => {
    return [RoleStatus.Owner, RoleStatus.Admin, RoleStatus.Editor, RoleStatus.Viewer, RoleStatus.None]
}

export const RoleStatusLabels: Record<RoleStatus, string> = {
    [RoleStatus.None]: translation('NhUZtq'),
    [RoleStatus.Editor]: translation('CanEdit'),
    [RoleStatus.Viewer]: translation('CanView'),
    [RoleStatus.Admin]: translation('Admin'),
    [RoleStatus.Owner]: translation('Owner'),
    [RoleStatus.ViewPrototype]: translation('CanViewPrototype'),
}
export const RoleStatusLabelsWithArticle: Record<RoleStatus, string> = {
    [RoleStatus.None]: '',
    [RoleStatus.Editor]: 'an editor',
    [RoleStatus.Viewer]: 'a viewer',
    [RoleStatus.Admin]: 'an admin',
    [RoleStatus.Owner]: 'an owner',
    [RoleStatus.ViewPrototype]: 'a prototype viewer',
}
export const RoleStatusWeight: Record<RoleStatus, number> = {
    [RoleStatus.None]: 0,
    [RoleStatus.ViewPrototype]: 1,
    [RoleStatus.Viewer]: 2,
    [RoleStatus.Editor]: 3,
    [RoleStatus.Admin]: 4,
    [RoleStatus.Owner]: 5,
}
export const RoleStatus2PermissionOption: Record<RoleStatus, PermissionOptionType> = {
    [RoleStatus.None]: PermissionOptionType.None,
    [RoleStatus.Editor]: PermissionOptionType.Edit,
    [RoleStatus.Viewer]: PermissionOptionType.View,
    [RoleStatus.Admin]: PermissionOptionType.Admin,
    [RoleStatus.Owner]: PermissionOptionType.Owner,
    [RoleStatus.ViewPrototype]: PermissionOptionType.ViewPrototype,
}
export function getRoleStatusOrder() {
    return Object.entries(RoleStatusWeight)
        .sort((a, b) => {
            return a[1] - b[1]
        })
        .map((o) => o[0] as RoleStatus)
}

// 比较两个权限的大小 正数 前者比后者权限大
export const compareRole = (role1: RoleStatus, role2: RoleStatus) => {
    return RoleStatusWeight[role1] - RoleStatusWeight[role2]
}

// 删除文档权限
export const deleteDocRight = (role: RoleStatus, folderRole: RoleStatus) =>
    compareRole(role, RoleStatus.Viewer) > 0 && compareRole(folderRole, RoleStatus.Viewer) > 0
