import { translation } from './comment-title.translation'
/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { useCallback, useMemo, useState } from 'react'
import {
    IconArrowLeft16,
    IconArrowRight16,
    IconCommentDockToSide,
    IconResolve,
    Tooltip,
} from '../../../../../../ui-lib/src'
import { CommentThread, CommentUser } from '../../../../kernel/interface/comment'
import { CommentDataTestId } from '../../../../window'
import { svgClose } from '../../../assets/common/common-svg'
import { IconButton } from '../../atom/button/icon-button'
import { Title } from '../../atom/title/title'
import { isUnreadComment } from '../comment-service/comment-service'
import { deleteCommentConfirmModal } from '../common-component/delete-comment-modal'
import { MoreCommentSettingsV2 } from '../common-component/more-comment-settings'
import classes from './comment-title.module.less'

interface CommentTitleProps {
    commentThread: CommentThread
    currentUser?: CommentUser
    showDockToSideButton?: boolean
    hideResolvedIcon?: boolean
    isResolved?: boolean
    isPrevCommentDisabled: boolean
    isNextCommentDisabled: boolean
    onCopyLink: (commentId: number) => void
    onSetUnread?: (commentId: number) => void
    onSetRead?: (commentId: number) => void
    onDeleteComment?: (commentId: number) => void
    onSetUnResolve?: (commentId: number) => void
    onSetResolve?: (commentId: number) => void
    onDockToSide?: () => void
    onCloseComment?: () => void
    onPrevComment?: () => void
    onNextComment?: () => void
}
export function CommentTitle(props: CommentTitleProps) {
    const {
        isPrevCommentDisabled,
        isNextCommentDisabled,
        onSetUnResolve,
        onSetResolve,
        onDeleteComment,
        onCopyLink,
        onPrevComment,
        onNextComment,
    } = props
    const [isOpenMoreTool, setIsOpenMoreTool] = useState<boolean>(false)

    const isResolved = useMemo(() => {
        return props.commentThread.resolved
    }, [props.commentThread.resolved])

    const isUnread = useMemo(() => {
        return isUnreadComment(props.commentThread.unread, props.commentThread.replies)
    }, [props.commentThread.unread, props.commentThread.replies])

    const toggleResolveComment = useCallback(() => {
        if (props.commentThread.resolved) {
            onSetUnResolve?.(props.commentThread.id)
        } else {
            onSetResolve?.(props.commentThread.id)
        }
    }, [props.commentThread.resolved, props.commentThread.id, onSetUnResolve, onSetResolve])

    const switchOpen = useCallback(() => {
        setIsOpenMoreTool(!isOpenMoreTool)
    }, [isOpenMoreTool])

    const onPointerDown = useCallback((e: React.PointerEvent<HTMLElement>) => {
        e.stopPropagation()
    }, [])

    const _onCopyLink = useCallback(() => {
        onCopyLink(props.commentThread.id)
        switchOpen()
    }, [props.commentThread.id, onCopyLink, switchOpen])

    const onSwitchUnreadState = useCallback(() => {
        switchOpen()
        if (isUnread) {
            props.onSetRead?.(props.commentThread.id)
        } else {
            props.onSetUnread?.(props.commentThread.id)
        }
    }, [isUnread, props, switchOpen])

    const openModal = useCallback(() => {
        switchOpen()
        deleteCommentConfirmModal({ onOk: () => onDeleteComment?.(props.commentThread.id) })
    }, [props.commentThread.id, onDeleteComment, switchOpen])

    return (
        <Title className={classes.title}>
            <Title.Left onPointerDown={(e) => e.stopPropagation()}>
                <Tooltip title={translation('Previous')}>
                    <IconButton
                        className={classnames(classes.icon, classes.svgPreNext)}
                        selected={false}
                        icon={<IconArrowLeft16 />}
                        onPointerDown={onPointerDown}
                        onClick={onPrevComment}
                        disabled={isPrevCommentDisabled}
                    />
                </Tooltip>
                <Tooltip title={translation('Next')}>
                    <IconButton
                        className={classnames(classes.icon, classes.svgPreNext)}
                        selected={false}
                        icon={<IconArrowRight16 />}
                        onPointerDown={onPointerDown}
                        onClick={onNextComment}
                        disabled={isNextCommentDisabled}
                    />
                </Tooltip>
            </Title.Left>
            <Title.Right>
                <MoreCommentSettingsV2
                    placement="bottom center"
                    isOwner={props.currentUser?.id === props.commentThread.owner.id}
                    isUnread={isUnread}
                    onCopyLink={_onCopyLink}
                    onSetUnread={onSwitchUnreadState}
                    onSetRead={onSwitchUnreadState}
                    onDeleteComment={openModal}
                    onPointerDown={onPointerDown}
                    dataTestIds={{
                        triggerFocus: CommentDataTestId.CommentDetailMoreOperation,
                        unReadItem: CommentDataTestId.CommentDetailUnread,
                        copyLinkItem: CommentDataTestId.CommentDetailCopyLink,
                        deleteItem: CommentDataTestId.CommentDetailRemove,
                    }}
                />
                {props.hideResolvedIcon ? null : (
                    <Tooltip title={isResolved ? translation('MarkAsUnresolved') : translation('MaskAsResolved')}>
                        <IconButton
                            className={classnames(classes.icon, classes.svgResolve, {
                                [classes.resolved]: isResolved,
                            })}
                            selected={false}
                            icon={<IconResolve />}
                            onPointerDown={onPointerDown}
                            onClick={toggleResolveComment}
                            dataTestId={CommentDataTestId.CommentDetailResolve}
                        />
                    </Tooltip>
                )}
                {props.showDockToSideButton ? (
                    <Tooltip title={translation('DockToSide')}>
                        <IconButton
                            className={classnames(classes.icon, classes.svgFixedPosition)}
                            selected={false}
                            icon={<IconCommentDockToSide />}
                            onPointerDown={onPointerDown}
                            onClick={props.onDockToSide}
                            dataTestId={CommentDataTestId.CommentDetailLeftTop}
                        />
                    </Tooltip>
                ) : null}
                <IconButton
                    className={classnames(classes.icon, classes.svgClose)}
                    selected={false}
                    icon={svgClose}
                    onPointerDown={onPointerDown}
                    onClick={props.onCloseComment}
                    dataTestId={CommentDataTestId.CommentDetailClose}
                />
            </Title.Right>
        </Title>
    )
}
