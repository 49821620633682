export type TraceableAbortSignal = AbortSignal & { name: string }

export class AbortError extends Error {
    constructor(message: string) {
        super(message)

        super.name = 'AbortError'
    }
}

export class TraceableAbortController extends AbortController {
    constructor(private name: string) {
        super()
    }

    private banAsyncCleanup() {
        const originFetch = window.fetch
        const originSetTimeout = window.setTimeout
        const originSetInterval = window.setInterval
        const originRequestAnimationFrame = window.requestAnimationFrame
        const originQueueMicrotask = window.queueMicrotask
        const originPromise = window.Promise

        let maxErrorCount = 0

        const errorFn = () => {
            maxErrorCount++
            if (maxErrorCount > 14) {
                console.error('using async function in cleanup is illegal')
            }
        }

        window.fetch = (...args) => {
            errorFn()
            return originFetch(...args)
        }
        // @ts-expect-error
        window.setTimeout = (...args) => {
            errorFn()
            // @ts-expect-error
            return originSetTimeout(...args)
        }
        // @ts-expect-error
        window.setInterval = (...args) => {
            errorFn()
            // @ts-expect-error
            return originSetInterval(...args)
        }
        window.requestAnimationFrame = (...args) => {
            errorFn()
            return originRequestAnimationFrame(...args)
        }
        window.queueMicrotask = (...args) => {
            errorFn()
            return originQueueMicrotask(...args)
        }
        window.Promise = class ErrorPromise<T> extends Promise<T> {
            constructor(...args: any) {
                errorFn()
                // @ts-expect-error
                super(...args)
            }
        }

        return () => {
            maxErrorCount = 0
            window.fetch = originFetch
            window.setTimeout = originSetTimeout
            window.setInterval = originSetInterval
            window.requestAnimationFrame = originRequestAnimationFrame
            window.queueMicrotask = originQueueMicrotask
            window.Promise = originPromise
        }
    }

    override abort(reason: AbortError | string) {
        super.abort(reason instanceof AbortError ? reason : new AbortError(reason))
    }

    override get signal(): TraceableAbortSignal {
        return Object.assign(super.signal, {
            name: this.name,
            throwIfAborted: this.throwIfAbortedPolyfill(super.signal),
        })
    }

    /**
     * jest 或老的浏览器不支持 signal.throwIfAborted，所以需要 polyfill
     * @param signal
     * @returns
     */
    private throwIfAbortedPolyfill(signal: AbortSignal) {
        return () => {
            if (signal.aborted) {
                const reason = signal.reason
                throw new AbortError(reason instanceof AbortError ? reason.message : reason)
            }
        }
    }
}
