import classnames from 'classnames'
import { useCallback, useMemo, useState } from 'react'
import { InputV2 } from '../../../../../../../../ui-lib/src'
import { BlurType } from '../../../../../../main/service/active-element-blur-service'
import classes from './common-name-description.module.less'
import { translation } from './common-name-description.translation'

export interface CommonNameDescriptionProps {
    name?: string | null
    description?: string | null
    isReadOnly?: boolean
    onChangeName: (value: string) => void
    onChangeDescription: (value: string) => void
    testId?: string
}

export function CommonNameDescription(props: CommonNameDescriptionProps) {
    const { name, description, isReadOnly, onChangeName, onChangeDescription } = props

    const hiddenDescription = useMemo(() => isReadOnly && !description, [description, isReadOnly])

    const [nameWidth, setNameWidth] = useState<number>(0)
    const [descriptionWidth, setDescriptionWidth] = useState<number>(0)

    const nameDescriptionStyle = useMemo((): React.CSSProperties => {
        return {
            minWidth: Math.max(nameWidth, descriptionWidth),
        }
    }, [nameWidth, descriptionWidth])

    const nameRef = useCallback((el: HTMLSpanElement | null) => {
        setNameWidth(el?.clientWidth ?? 0)
    }, [])

    const descriptionRef = useCallback((el: HTMLSpanElement | null) => {
        setDescriptionWidth(el?.clientWidth ?? 0)
    }, [])

    return (
        <div
            className={classnames(classes.container, {
                [classes.readonly]: isReadOnly,
            })}
            data-testid={props.testId}
        >
            <div className={classes.line}>
                <div className={classes.label} ref={nameRef} style={nameDescriptionStyle}>
                    {translation('Name')}
                </div>
                <div className={classes.name}>
                    {isReadOnly ? (
                        name
                    ) : (
                        <InputV2
                            value={name ?? ''}
                            onBlur={(e) => onChangeName(e.target.value)}
                            autoFocus
                            placeholder={translation('NewStyle')}
                            data-blur-after-capture-mouse-down={BlurType.Immediately}
                        />
                    )}
                </div>
            </div>
            {hiddenDescription ? null : (
                <div className={classes.line}>
                    <div className={classes.label} ref={descriptionRef} style={nameDescriptionStyle}>
                        {translation('Description')}
                    </div>
                    <div className={classes.description}>
                        {isReadOnly ? (
                            description
                        ) : (
                            <InputV2.Textarea
                                key={description}
                                placeholder={translation('WhatIsIt')}
                                onBlur={(e) => onChangeDescription((e.target as HTMLTextAreaElement).value)}
                                defaultValue={description ?? ''}
                                minHeight={32}
                                autoHeight
                                data-blur-after-capture-mouse-down={BlurType.Immediately}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}
