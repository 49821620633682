/* eslint-disable no-restricted-imports */
import { domLocation } from '../../../../../util/src'
import { CreateHistoryVersionType, HistoryVersion } from '../../../kernel/interface/history-version'
import { AutoItem, AutoItemGroup, IconType, ItemType, UserItem } from './history-list/type'

export function isUserItem(item: HistoryVersion) {
    return !!item.name
}

export function isAutoItem(item: HistoryVersion) {
    return !isUserItem(item)
}

export function isRelatedItemToUser(item: HistoryVersion, userId: number) {
    return item.createUser?.id === userId || item.editUsers.some((v) => v.id === userId)
}

export function transformOriginHistoryItems2Local(items: HistoryVersion[]) {
    const res: (UserItem | AutoItemGroup)[] = []
    let autoCroup: AutoItemGroup | null = null
    for (const item of items) {
        if (isUserItem(item)) {
            res.push(transformHistoryVersionItem2UserItem(item))
            autoCroup = null
        } else {
            if (autoCroup !== null) {
                autoCroup.children.push(transformHistoryVersionItem2AutoItem(item))
            } else {
                autoCroup = {
                    type: ItemType.AutoItemGroup,
                    iconType: IconType.Normal,
                    children: [transformHistoryVersionItem2AutoItem(item)],
                }
                res.push(autoCroup)
            }
        }
    }
    return res
}

export function transformHistoryVersionItem2UserItem(item: HistoryVersion): UserItem {
    return {
        type: ItemType.UserItem,
        iconType: transformCreateType2IconType(item.createType),
        name: item.name,
        description: item.desc,
        time: item.dbctime,
        versionId: item.id,
        primaryUser: item.createUser ?? undefined,
        secondaryUsers: item.editUsers,
        origin: item,
    }
}

export function transformHistoryVersionItem2AutoItem(item: HistoryVersion): AutoItem {
    return {
        type: ItemType.AutoItem,
        iconType: IconType.Null,
        time: item.dbctime,
        versionId: item.id,
        primaryUser: item.createUser ?? undefined,
        secondaryUsers: item.editUsers,
        origin: item,
    }
}

export function transformCreateType2IconType(createType: CreateHistoryVersionType) {
    switch (createType) {
        case CreateHistoryVersionType.UpdateLibrary:
        case CreateHistoryVersionType.PublishLibrary:
            return IconType.Public
        case CreateHistoryVersionType.UnPublishLibrary:
            return IconType.CancelPublic
        default:
            return IconType.Normal
    }
}

export function mergeItems(
    items: (UserItem | AutoItemGroup)[],
    addItems: (UserItem | AutoItemGroup)[]
): (UserItem | AutoItemGroup)[] {
    const oldLastItem: UserItem | AutoItemGroup | undefined = items[items.length - 1]
    const addFirstItem: UserItem | AutoItemGroup | undefined = addItems[0]
    if (oldLastItem?.type === ItemType.AutoItemGroup && addFirstItem?.type === ItemType.AutoItemGroup) {
        return [
            ...items.slice(0, -1),
            Object.assign({}, oldLastItem, { children: [...oldLastItem.children, ...addFirstItem.children] }),
            ...addItems.slice(1),
        ]
    }
    return [...items, ...addItems]
}

export function createHrefByVersionId(historyVersionId: number) {
    let search = domLocation().search
    const isDeleteVersionId = historyVersionId === -1
    const mergeSearch = () => domLocation().origin + domLocation().pathname + search
    if (isDeleteVersionId) {
        const params = search.split(/&|\?|historyVersionId=[0-9]*/).filter((v) => v)
        search = params.length ? `?${params.join('&')}` : ''
        return mergeSearch()
    }
    const regexp = /historyVersionId=[0-9]*/
    const isEditVersionId = regexp.test(search)
    if (isEditVersionId) {
        search = search.replace(regexp, `historyVersionId=${historyVersionId}`)
        return mergeSearch()
    }
    search += search.startsWith('?') ? `&historyVersionId=${historyVersionId}` : `?historyVersionId=${historyVersionId}`
    return mergeSearch()
}

export function getQueryParamVersionId() {
    const res = domLocation().search.match(/historyVersionId=([0-9]*)/)
    const versionId = Number(res?.[1] ?? -1)
    return isNaN(versionId) ? -1 : versionId
}
