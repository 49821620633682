import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    NoGridStyles: '没有布局网格样式',
    BrowseLibraries: '浏览团队组件库',
    NoStylesMatch: '没有找到该布局网格样式',
    GoToStyle: '打开样式所在文件',
    EditStyle: '编辑样式',
    DeleteStyle: '删除样式',
    Search: '搜索',
} as const

export const enTranslation = {
    NoGridStyles: 'No grid styles',
    BrowseLibraries: 'Browse libraries',
    NoStylesMatch: 'No results found',
    GoToStyle: 'Go to style definition',
    EditStyle: 'Edit style',
    DeleteStyle: 'Delete style',
    Search: 'Search',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
