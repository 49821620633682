import { translation } from './corner-smoothing.translation'
/* eslint-disable no-restricted-imports */
import { UndoRedoCommand, UpdateSelectionCornerSmoothingCommand, Wukong } from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import React, { HTMLAttributes, useCallback, useEffect, useRef, useState } from 'react'
import { DraggablePopupV2, IconMote, Tooltip, usePointerCapture } from '../../../../../../ui-lib/src'
import { cmdChangePopupState } from '../../../../document/command/document-command'
import { PopupStateType } from '../../../../document/node/node'
import { ToKeyCode } from '../../../../document/util/keycode'
import { useCommand } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { IconButton } from '../../atom/button/icon-button'
import { SingleGrid } from '../../atom/grid/single-grid'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { ScrubbableInputPercent } from '../../atom/inputs/scrubbable-input-percent'
import { Value } from '../../atom/inputs/utils/type'
import style from './corner-smoothing.module.less'
import { useAttributeV2 } from './use-attribute'

interface CornerSmoothingProps {
    onChange: (key: string) => (v: string | number | boolean) => void
}

export function CornerSmoothing(props: CornerSmoothingProps) {
    const { onChange } = props
    const command = useCommand()
    const { cornerSmoothing } = useAttributeV2()
    const popupState = useViewState('popupState')
    const isOpen = popupState?.type === PopupStateType.POPUP_STATE_TYPE_CORNER_SMOOTHING
    const iconRef = useRef<HTMLDivElement>(null)
    const [position, setPosition] = useState({ left: 0, top: 0 })
    const percent = cornerSmoothing?.value ?? 0

    useEffect(() => {
        if (isOpen) {
            const { left = 0, top = 0 } = iconRef.current?.getBoundingClientRect() || {}
            setPosition({ left: left - 210, top: top - 6 })
        }
    }, [popupState, isOpen])

    const onClose = () => {
        command.invoke(cmdChangePopupState, {
            type: PopupStateType.POPUP_STATE_TYPE_NONE,
            reciprocalIndex: -1,
            multiPopup: [],
        })
    }

    const onChangeValue = (key: string) => (value: Value, options?: InputOptionsForUndoSquash) => {
        if (!isNil(value)) {
            const per = key === 'slider' ? Number(value) : Number(value) / 100
            command.DEPRECATED_invokeBridge(UpdateSelectionCornerSmoothingCommand, {
                value: per,
            })
            command.commitUndo(options?.commitType)
        }
    }

    if (!cornerSmoothing?.show) {
        return null
    }

    return (
        <>
            <SingleGrid.Item start={51} span={12} horizontalCenter>
                <Tooltip title={translation('CornerSmoothing')}>
                    <IconButton
                        dataTestId="corner-smoothing-icon"
                        ref={iconRef}
                        onChange={onChange('cornerSmoothing')}
                        selected={!!cornerSmoothing?.selected}
                        icon={<IconMote />}
                        deepColor
                    />
                </Tooltip>
            </SingleGrid.Item>

            <DraggablePopupV2
                visible={isOpen}
                position={position}
                onCancel={onClose}
                header={translation('CornerSmoothing')}
                styleType="editor"
                footer={null}
                bodyClassName="p-0"
                positionRightBase
            >
                <div className={style.popupBody}>
                    <RangeSlider rate={percent} onChange={onChangeValue('slider')} />
                    <ScrubbableInputPercent
                        labelTestId="coordinate-corner-smoothing-label"
                        leftScrubbable={false}
                        rightScrubbable={false}
                        className={style.input}
                        value={percent}
                        onChange={onChangeValue('input')}
                        min={0}
                        max={100}
                        testId="corner-smoothing-value"
                    />
                </div>
            </DraggablePopupV2>
        </>
    )
}

interface RangeSliderProps
    extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange' | 'onPointerDown' | 'onPointerMove' | 'onPointerUp'> {
    rate?: number // [0-1]
    onChange: (rate: number) => void
}

export function RangeSlider(props: RangeSliderProps) {
    const { rate = 0.5, className, onChange, onMouseDown, ...otherProps } = props

    const lineRef = useRef<HTMLDivElement>(null)
    const [innerPercentStyle, setInnerPercentStyle] = useState({ width: '0%' })
    const [active, setActive] = useState(false)
    const [focus, setFocus] = useState(false)
    const [isDragging, setIsDragging] = useState(false)
    const command = useCommand()

    const calculatePercent = useCallback(
        (clientX: number) => {
            const { left, width } = lineRef.current!.getBoundingClientRect()
            let offsetX = clientX - left
            offsetX = offsetX < 0 ? 0 : offsetX > width ? width : offsetX
            onChange?.(offsetX / width)
        },
        [onChange]
    )

    const captureStart = useCallback(
        (e: React.PointerEvent) => {
            calculatePercent(e.clientX)
            setIsDragging(true)
        },
        [calculatePercent]
    )

    const capturing = useCallback(
        (e: React.PointerEvent) => {
            calculatePercent(e.clientX)
        },
        [calculatePercent]
    )

    const captureEnd = useCallback(() => {
        setIsDragging(false)
        command.commitUndo()
    }, [command])
    const { pointerdown, pointermove, pointerup } = usePointerCapture({ captureStart, capturing, captureEnd })

    const _onMouseDown = useCallback(
        (e: any) => {
            e.stopPropagation()
            onMouseDown?.(e)
        },
        [onMouseDown]
    )

    const _onMouseUp = useCallback(
        (e: any) => {
            e.stopPropagation()
            command.commitUndo()
        },
        [command]
    )

    const onMouseEnterPoint = () => {
        setFocus(true)
    }

    const onMouseLeavePoint = () => {
        !isDragging && setFocus(false)
    }

    const onMouseEnterIOS = () => {
        setActive(true)
    }

    const onMouseLeaveIOS = () => {
        setActive(false)
    }

    useEffect(() => {
        const rateValue = isNil(rate) ? 0 : rate
        const rateX = rateValue < 0 || rateValue > 1 ? 0 : rateValue
        setInnerPercentStyle({ width: `${rateX * 100}%` })
        if (Math.round(rate * 100) === 60 && active) {
            setFocus(true)
        } else if (!active && !isDragging) {
            setFocus(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rate, active])

    useEffect(() => {
        setFocus(isDragging)
    }, [isDragging])

    const _onKeyDown = useCallback(
        (e: any) => {
            if (e.keyCode === ToKeyCode.Z) {
                e.stopPropagation()
                const { metaKey, ctrlKey, shiftKey, altKey } = e
                command.DEPRECATED_invokeBridge(
                    UndoRedoCommand,
                    Wukong.DocumentProto.UndoRedoCommandParam.create({ metaKey, ctrlKey, shiftKey, altKey })
                )
            }
        },
        [command]
    )

    const _onClickIOS = useCallback(() => {
        onChange(0.6)
        command.commitUndo()
    }, [command, onChange])

    return (
        <div
            className={`${style.rangeSlider} ${active ? style.active : ''} ${className ?? ''} ${
                focus ? style.focus : ''
            }`}
            tabIndex={0}
            onKeyDown={_onKeyDown}
        >
            <div
                className={style.slider}
                onPointerUp={pointerup}
                onPointerMove={pointermove}
                onPointerDown={pointerdown}
                onMouseDown={_onMouseDown}
                onMouseUp={_onMouseUp}
                {...otherProps}
                data-testid="corner-smoothing-range-slider"
                // 避免触发可拖拽弹窗的拖拽
                data-disabled-drag-move={'true'}
            >
                <div className={style.line} ref={lineRef}>
                    <span className={style.split}></span>
                    <div className={style.percentLine} style={innerPercentStyle}></div>
                    <div className={style.inner}>
                        <div className={style.innerLine} style={innerPercentStyle}>
                            <span
                                onMouseEnter={onMouseEnterPoint}
                                onMouseLeave={onMouseLeavePoint}
                                className={style.point}
                            ></span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                data-testid="ios-percent"
                className={style.ios}
                onMouseEnter={onMouseEnterIOS}
                onMouseLeave={onMouseLeaveIOS}
                onMouseDown={(e) => e.stopPropagation()}
                onClick={_onClickIOS}
            >
                iOS
            </div>
        </div>
    )
}
