import {
    MonoIconCommonWarningLine16,
    Select,
    Switch,
    Tooltip,
    useEllipsisTooltip,
} from '../../../../../../../../ui-lib/src'
import { SingleGrid } from '../../../../atom/grid/single-grid'
import { isBooleanOption, VariantProp } from '../../types'
import { VariantTestIds } from '../variant-panel'
import styles from './index.module.less'

export function InstanceVariantPropItem(props: {
    prop: VariantProp
    onChangePropValue: (newPropValue: string | number) => void
}) {
    const { prop, onChangePropValue } = props

    const { inactivation, ellipsisRef, onmouseenter } = useEllipsisTooltip<HTMLDivElement>()
    const testIds = VariantTestIds.variantProp(prop.compSetProp.name)

    const booleanOptions = isBooleanOption(prop.compSetProp.options)

    return (
        <SingleGrid>
            <SingleGrid.Item start={5} span={18}>
                <Tooltip title={prop.compSetProp.name} inactivation={inactivation}>
                    <div
                        className={styles.propertyKey}
                        data-testid={testIds.name}
                        ref={ellipsisRef}
                        onMouseEnter={onmouseenter}
                        data-simple-drag-ignore
                    >
                        {prop.compSetProp.name}
                    </div>
                </Tooltip>
            </SingleGrid.Item>
            <SingleGrid.Item start={25} span={26} data-simple-drag-ignore>
                {booleanOptions ? (
                    <Switch
                        checked={booleanOptions.checked(prop.value)}
                        onChange={(changedBoolean) =>
                            onChangePropValue(booleanOptions.getOriginOption(changedBoolean)!)
                        }
                        dataTestIds={{
                            switch: testIds.switch,
                        }}
                    />
                ) : (
                    <Select.NormalSingleLevel
                        isMixed={prop.isMixed}
                        value={prop.isInvalid ? '-' : prop.value}
                        onChange={onChangePropValue}
                        dataTestIds={{ triggerFocus: testIds.select }}
                        maxWidth={232}
                        label={prop.isInvalid ? '-' : prop.value}
                    >
                        {prop.compSetProp.options.map((option, optionIndex) => (
                            <Select.NormalSingleLevel.Option
                                key={option + optionIndex.toString()}
                                value={option}
                                data-testid={testIds.option(option)}
                                placement="left"
                                tooltipTitle={option}
                            >
                                {option}
                            </Select.NormalSingleLevel.Option>
                        ))}
                    </Select.NormalSingleLevel>
                )}
            </SingleGrid.Item>
            {prop.isInvalid && (
                <SingleGrid.Item start={55} span={4}>
                    {/* <Tooltip title={translation('MissingProperty')}> */}
                    <MonoIconCommonWarningLine16 />
                    {/* </Tooltip> */}
                </SingleGrid.Item>
            )}
        </SingleGrid>
    )
}
