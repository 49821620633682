/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { escapeRegExp } from 'lodash-es'
import { DeepRequired } from '../../view-state-bridge'

type CursorRenderInfo = DeepRequired<Wukong.DocumentProto.IArg_ApplyCursor>
export function isValidCursor(cursor: Wukong.DocumentProto.IArg_ApplyCursor): cursor is CursorRenderInfo {
    return !!cursor
}

export function renderCursor(
    cursor: CursorRenderInfo,
    element: HTMLElement,
    document: Document
): Omit<Wukong.DocumentProto.IRenderCursorResult, 'renderTarget'> {
    const { styleSheetStatus, styleClassName } = createCursorStyle(cursor, document)
    // hash 为空时不渲染光标，清除之前的光标样式
    if (!styleClassName) {
        clearCursorClass(element)
        return {
            styleSheetStatus,
            styleClassName,
            classListStatus: Wukong.DocumentProto.ClassListStatus.CLASS_LIST_STATUS_CLEAR_CLASS_NAME,
            finalClassList: element.classList.toString(),
        }
    }
    // hash 一致时不更新光标样式
    if (element.classList.contains(styleClassName)) {
        return {
            styleSheetStatus,
            styleClassName,
            classListStatus: Wukong.DocumentProto.ClassListStatus.CLASS_LIST_STATUS_CLASS_NAME_EXISTED_SKIP,
            finalClassList: element.classList.toString(),
        }
    }
    // hash 不一致时覆盖之前的光标样式
    clearCursorClass(element)
    element.classList.add(styleClassName)
    return {
        styleSheetStatus,
        styleClassName,
        classListStatus: Wukong.DocumentProto.ClassListStatus.CLASS_LIST_STATUS_CLASS_NAME_UPDATED,
        finalClassList: element.classList.toString(),
    }
}

function getCursorClassId(hash: string) {
    return `${CURSOR_CLASSNAME_PREFIX}${hash}`
}
function createCursorStyle(
    cursorInfo: CursorRenderInfo,
    document: Document
): Pick<Wukong.DocumentProto.IRenderCursorResult, 'styleSheetStatus' | 'styleClassName'> {
    if (!cursorInfo.hash) {
        return {
            styleSheetStatus: Wukong.DocumentProto.StyleSheetStatus.STYLE_SHEET_STATUS_UNKNOWN,
            styleClassName: '',
        }
    }
    const cursorStyle = generateCursorStyle(cursorInfo)
    const styleClassName = getCursorClassId(cursorInfo.hash)
    const styleSheetStatus = updateCursorStyleSheet(styleClassName, cursorStyle, document)
    return {
        styleSheetStatus,
        styleClassName,
    }
}
// 更新光标样式到 styleSheet，返回是否更新成功
function updateCursorStyleSheet(
    className: string,
    cursorStyle: string,
    document: Document
): Wukong.DocumentProto.StyleSheetStatus {
    const styleSheet = getCursorStyleSheet(document)
    if (!styleSheet) {
        return Wukong.DocumentProto.StyleSheetStatus.STYLE_SHEET_STATUS_STYLE_SHEET_NOT_FOUND
    }
    const cssRule = styleSheet.cssRules[0]
    if (cssRule) {
        const regexp = new RegExp(`^body \\.${escapeRegExp(className)} *{`)
        // 如果样式已经存在，则不更新
        if (regexp.test(cssRule.cssText)) {
            return Wukong.DocumentProto.StyleSheetStatus.STYLE_SHEET_STATUS_CSS_RULE_EXISTED_SKIP
        }
        styleSheet.deleteRule(0)
    }
    styleSheet.insertRule(`body .${className}{${cursorStyle}}`, 0)
    return Wukong.DocumentProto.StyleSheetStatus.STYLE_SHEET_STATUS_STYLE_SHEET_UPDATED
}
function getCursorStyleSheet(document: Document) {
    const styleSheet = findStyleSheetByTitle(STYLE_SHEET_TITLE, document)
    if (styleSheet) {
        return styleSheet
    }
    return createCursorStyleSheet(document)
}
function createCursorStyleSheet(document: Document) {
    const style = document.createElement('style')
    style.title = STYLE_SHEET_TITLE
    document.head.appendChild(style)
    return findStyleSheetByTitle(STYLE_SHEET_TITLE, document)
}
function findStyleSheetByTitle(title: string, document: Document) {
    for (let i = document.styleSheets.length - 1; i >= 0; i--) {
        const styleSheet = document.styleSheets.item(i)
        if (styleSheet?.title === title) {
            return styleSheet
        }
    }
}
function generateCursorStyle(cursor: CursorRenderInfo) {
    let cursorStyle = `cursor: url("${cursor.dataUriFor1x}") ${cursor.hotspotX} ${cursor.hotspotY}, auto !important;`
    if (cursor.dpr !== 1) {
        cursorStyle +=
            `cursor: -webkit-image-set(url("${cursor.dataUri}")${cursor.dpr}x, url("${cursor.dataUri}")1x) ${cursor.hotspotX} ${cursor.hotspotY}, auto !important;` +
            `cursor: image-set(url("${cursor.dataUri}")${cursor.dpr}x, url("${cursor.dataUri}")1x) ${cursor.hotspotX} ${cursor.hotspotY}, auto !important;`
    }
    return cursorStyle
}
function clearCursorClass(targetElement: HTMLElement) {
    targetElement.classList.forEach((c) => {
        if (c.startsWith(CURSOR_CLASSNAME_PREFIX)) {
            targetElement.classList.remove(c)
        }
    })
}
const STYLE_SHEET_TITLE = '_wk_cursor_style_sheet'
const CURSOR_CLASSNAME_PREFIX = '_cursor_'
