// 传入创建时间，根据创建时间生成颜色，产品要求颜色不能随着名字变
export const randomColor = (time: number): string => {
    const t = +String(time).replace(/0+$/, '')
    return [
        'var(--wk-multiplayer-red)',
        'var(--wk-multiplayer-orange)',
        'var(--wk-multiplayer-yellow)',
        'var(--wk-multiplayer-olive)',
        'var(--wk-multiplayer-green)',
        'var(--wk-multiplayer-lake)',
        'var(--wk-multiplayer-blue)',
        'var(--wk-multiplayer-lilac)',
        'var(--wk-multiplayer-purple)',
        'var(--wk-multiplayer-pink)',
    ][t % 10]
}

export const randomString = (length: number): string => {
    const chars = 'abcdefghijklmnopqrstuvwxyz'
    let result = ''
    for (let i = length; i > 0; --i) {
        result += chars[Math.floor(Math.random() * chars.length)]
    }
    return result
}

export function randomNumber() {
    return Math.floor(Math.random() * 10 ** 8)
}

/**
 * @description 获取随机字符串
 */
export function generateUniqString(): string {
    return Math.random().toString(36).substr(2, 9)
}
