import { ComponentSetId, LibraryComponentStyleWithDocId, LibraryId } from '../interface/component-style'
import { DocID } from '../interface/type'
import {
    LibraryContentVO,
    LibraryQueryResponse,
    LibraryQueryResponseWithoutDocId,
    type LibraryContentBaseInfoVO,
    type LibraryContentMetaInfoVO,
} from '../interface/library'

// 补充 documentId 字段到服务端下发的 vo 中，方便前端使用 @zhangxiaozhi
export function combineLibraryComponentStyleVOWithDocumentIdList<T>(
    documentId: DocID | null,
    list: ReadonlyArray<T>
): LibraryComponentStyleWithDocId<T>[] {
    return list.map((vo) => ({ ...vo, documentId }))
}

type LibraryIdMapValueVOAttrName = keyof Pick<
    LibraryQueryResponseWithoutDocId,
    | 'libraryId2MatchedComponentList'
    | 'libraryId2MatchedStyleList'
    | 'libraryId2MatchedVariableCollectionList'
    | 'libraryId2MatchedVariableList'
>
type LibraryIdMapValueVO<M extends LibraryQueryResponse, K extends LibraryIdMapValueVOAttrName> = M[K] extends Record<
    LibraryId,
    (infer T)[]
>
    ? T
    : never

function transformLibraryId2VOList<
    K extends keyof Pick<
        LibraryQueryResponseWithoutDocId,
        | 'libraryId2MatchedComponentList'
        | 'libraryId2MatchedStyleList'
        | 'libraryId2MatchedVariableCollectionList'
        | 'libraryId2MatchedVariableList'
    >
>(
    attrName: K,
    libraryQueryResponse: LibraryQueryResponseWithoutDocId
): Record<LibraryId, LibraryIdMapValueVO<LibraryQueryResponse, K>[]> {
    if (attrName === 'libraryId2MatchedVariableCollectionList' || attrName === 'libraryId2MatchedVariableList') {
        if (!libraryQueryResponse[attrName]) {
            return {}
        }
    }
    return Object.fromEntries(
        Object.entries(libraryQueryResponse[attrName]).map(([libraryId, list]) => {
            const documentId =
                Object.values(libraryQueryResponse.teamId2LibraryList)
                    .flat()
                    .find((library) => library.id === libraryId)?.document?.id ?? null
            return [libraryId as LibraryId, combineLibraryComponentStyleVOWithDocumentIdList(documentId, list)]
        })
    )
}

// 转换 LibraryQueryResponseWithoutDocId -> LibraryQueryResponse
export function transformLibraryQueryResponse(
    libraryQueryResponse: LibraryQueryResponseWithoutDocId
): LibraryQueryResponse {
    return {
        ...libraryQueryResponse,
        libraryId2MatchedComponentList: transformLibraryId2VOList(
            'libraryId2MatchedComponentList',
            libraryQueryResponse
        ),
        libraryId2MatchedStyleList: transformLibraryId2VOList('libraryId2MatchedStyleList', libraryQueryResponse),
        libraryId2MatchedVariableList: transformLibraryId2VOList('libraryId2MatchedVariableList', libraryQueryResponse),
        libraryId2MatchedVariableCollectionList: transformLibraryId2VOList(
            'libraryId2MatchedVariableCollectionList',
            libraryQueryResponse
        ),
    }
}

export function transformLibraryContentVO(
    libraryContentBaseInfo: LibraryContentBaseInfoVO,
    libraryContentMetaInfo: LibraryContentMetaInfoVO
): LibraryContentVO {
    const documentId = libraryContentBaseInfo.library.document?.id
    if (!documentId) {
        throw new Error('[transformLibraryContentVOV2] documentId is null')
    }
    return {
        library: libraryContentBaseInfo.library,
        components: combineLibraryComponentStyleVOWithDocumentIdList(
            documentId,
            libraryContentMetaInfo.components ?? []
        ),
        styles: combineLibraryComponentStyleVOWithDocumentIdList(documentId, libraryContentMetaInfo.styles ?? []),
        componentSets: combineLibraryComponentStyleVOWithDocumentIdList(
            documentId,
            libraryContentMetaInfo.componentSets ?? []
        ),
        componentSetId2ChildrenComponents: Object.fromEntries(
            Object.entries(libraryContentMetaInfo.componentSetId2ChildrenComponents).map(([setId, childComponents]) => [
                setId as ComponentSetId,
                combineLibraryComponentStyleVOWithDocumentIdList(documentId, childComponents),
            ])
        ),
        variableSets: combineLibraryComponentStyleVOWithDocumentIdList(
            documentId,
            libraryContentMetaInfo.variableCollections ?? []
        ),
        variables: combineLibraryComponentStyleVOWithDocumentIdList(documentId, libraryContentMetaInfo.variables ?? []),
        libraryMovedInfos: {
            ...libraryContentMetaInfo.libraryMovedInfos,
            libraryId2LibraryInfo: libraryContentBaseInfo.libraryId2LibraryInfo,
        },
    }
}
