import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    Layers: '图层',
    'Find…': '查找',
} as const

export const enTranslation = {
    Layers: 'Layers',
    'Find…': 'Find...',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
