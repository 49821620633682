/**
 * @owner: chenxiangbj@kanyun.com
 */
import { WKIconLogo } from './wk-icon-logo'
import { WKStandardLogo } from './wk-standard-logo'

const WKLogo = {
    WithText: WKStandardLogo,
    WithoutText: WKIconLogo,
}

export { WKLogo }
