import { translation } from './inspect-property-blend-mode.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { DeepRequired } from '../../../view-state-bridge'
import { toFixed } from '../../utils/to-fixed'
import { InspectPropertyCopyableRow } from './inspect-property-copyable-row'

export const BLENDMODE_TO_NAME_MAP = new Map<Wukong.DocumentProto.BlendMode | string, string>([
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_PASS_THROUGH, translation('PassThrough')],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL, translation('Normal')],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_DARKEN, translation('Darken')],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_MULTIPLY, translation('Multiply')],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_COLOR_BURN, translation('ColorBurn')],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_LIGHTEN, translation('Lighten')],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_SCREEN, translation('Screen')],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_COLOR_DODGE, translation('PlusLighter')],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_OVERLAY, translation('Overlay')],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_SOFT_LIGHT, translation('SoftLight')],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_HARD_LIGHT, translation('HardLight')],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_DIFFERENCE, translation('Difference')],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_EXCLUSION, translation('Exclusion')],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_HUE, translation('Hue')],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_SATURATION, translation('Saturation')],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_COLOR, translation('Color')],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_LUMINOSITY, translation('Luminosity')],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_LINEAR_BURN, translation('LinearBurn')],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_LINEAR_DODGE, translation('LinearDodge')],
])

export function InspectPropertyBlendMode({
    blendModeViewState,
}: {
    blendModeViewState: DeepRequired<Wukong.DocumentProto.IBlendModeView> | undefined
}) {
    if (!blendModeViewState) {
        return null
    }

    const shouldShowBlendMode =
        !blendModeViewState.multiBlendMode &&
        blendModeViewState.blendMode &&
        ![
            Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
            Wukong.DocumentProto.BlendMode.BLEND_MODE_PASS_THROUGH,
        ].includes(blendModeViewState.blendMode)

    const shouldShowOpacity = !blendModeViewState.multiOpacity && blendModeViewState.opacity !== 1

    return (
        <>
            {shouldShowBlendMode ? (
                <InspectPropertyCopyableRow
                    name={translation('Blend')}
                    value={BLENDMODE_TO_NAME_MAP.get(blendModeViewState.blendMode) ?? ''}
                />
            ) : null}

            {shouldShowOpacity ? (
                <InspectPropertyCopyableRow
                    name={translation('Opacity')}
                    value={toFixed(blendModeViewState!.opacity * 100, 0) + '%'}
                />
            ) : null}
        </>
    )
}
