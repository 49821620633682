import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    SeeAll: '查看所有 {{count}} 种',
} as const

export const enTranslation = {
    SeeAll: 'See all {{count}}',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string | number>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
