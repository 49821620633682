/* eslint-disable no-restricted-imports */
import { cloneDeep, isEqual, isNil } from 'lodash-es'
import { sortArrayByKey } from '../../../../util/src'
import { FontInfo, FontName } from '../../document/node/node'
import { FontInfoVO } from '../../kernel/interface/font'
import { FontMeta } from '../../space/app/team-font/internal/interface'
import { fourByteStringToNumber } from '../../ui/component/design/text/utils'
import {
    FontInfoExt,
    FontNameExt,
    FontVariant,
    FontVariantAxes,
    FontVariantInstance,
    LocalFontInfo,
    VariationAxisTickValues,
    VariationInstancesAxes,
} from './interface'

/**
 * 中文字符正则
 */
const CJK_REGEX = /[\u3006\u3007\u4e00-\u9fff\u3400-\u4dbf\u{20000}-\u{2a6df}\u{2a700}-\u{2ebef}\u{30000}-\u{3134f}]/u

/**
 * 是否包含中文字符
 * @param str
 * @returns
 */
export const containsCJK = (str: string) => str.search(CJK_REGEX) != -1

const checkItalicInfo = (styles: FontName[]) => {
    const italicInfo: { normal: boolean; italic: boolean } = { normal: false, italic: false }
    styles.forEach((style) => {
        if (style.italic) {
            italicInfo.italic = true
        } else {
            italicInfo.normal = true
        }
    })
    return italicInfo
}

const mergeFonts = (
    allFonts: FontInfo[],
    familySet: Set<string>,
    fontsToMerge: FontInfo[],
    filtedFont: FontInfo[],
    source: 'shared' | 'local' | 'cloud'
) => {
    fontsToMerge.forEach((fontInfo) => {
        // wasm 预加载了 Inter 字体(正 & 斜)；当其他来源有 Inter，会导致不存在于预加载字体的字重无法使用。
        if (fontInfo.family === 'Inter' && source !== 'cloud') {
            return
        }

        if (!familySet.has(fontInfo.family)) {
            familySet.add(fontInfo.family)
            allFonts.push(cloneDeep(fontInfo))
            filtedFont.push(cloneDeep(fontInfo))
        } else {
            const styles = allFonts.find((item) => item.family === fontInfo.family)?.styles ?? []
            const filtedStyles = filtedFont.find((item) => item.family === fontInfo.family)?.styles ?? []
            const italicInfo = checkItalicInfo(styles)
            if (!italicInfo.italic || !italicInfo.normal) {
                fontInfo.styles.forEach((style) => {
                    if ((style.italic && !italicInfo.italic) || (!style.italic && italicInfo.italic)) {
                        styles?.push(style)
                        filtedStyles?.push(style)
                    }
                })
            }
        }
    })
}

/**
 * 按照来源((企业+团队)>本地>云端)和family 合并字体列表,并对 FontName 去重
 */

export const mergeFontInfos = (fontList: {
    readonly orgFont: FontInfo[]
    readonly teamFont: FontInfo[]
    readonly localFont: FontInfo[]
    readonly cloudFont: FontInfo[]
}) => {
    const { orgFont, teamFont, localFont, cloudFont } = fontList

    const allFonts: FontInfo[] = []
    const filteredSharedFonts: FontInfo[] = []
    const filteredLocalFonts: FontInfo[] = []
    const filteredCloudFonts: FontInfo[] = []

    const familySet: Set<string> = new Set()
    mergeFonts(allFonts, familySet, orgFont, filteredSharedFonts, 'shared')
    mergeFonts(allFonts, familySet, teamFont, filteredSharedFonts, 'shared')
    mergeFonts(allFonts, familySet, localFont, filteredLocalFonts, 'local')
    mergeFonts(allFonts, familySet, cloudFont, filteredCloudFonts, 'cloud')

    return {
        allFont: allFonts,
        sharedFont: filteredSharedFonts,
        localFont: filteredLocalFonts,
        cloudFont: filteredCloudFonts,
    }
}

/**
 * 字体排序
 * 规则: 中文在前、其他在后
 * @param fontInfos
 * @returns
 */
export const sortFontInfos = (fontInfos: FontInfo[]) => {
    const cjkFonts: FontInfo[] = []
    const otherFonts: FontInfo[] = []

    fontInfos.forEach((fontInfo) => {
        if (containsCJK(fontInfo.localizedFamily)) {
            cjkFonts.push(fontInfo)
        } else {
            otherFonts.push(fontInfo)
        }
    })

    return [
        ...sortArrayByKey(cjkFonts, 'localizedFamily', true),
        ...sortArrayByKey(otherFonts, 'localizedFamily', true),
    ]
}

/**
 * 根据 variationInstances & variationAxes 解析出可变字体的 style
 */

export const parseVariableFontStyle = (
    variationInstances: FontVariantInstance[],
    variationAxes: FontVariantAxes[],
    fontInfo: FontInfoVO | LocalFontInfo
): FontNameExt => {
    // 获取 轴的 tick 值
    const variationAxisTickValues: VariationAxisTickValues = variationAxes.reduce((acc, axis) => {
        acc[axis.axisTag] = { value: [] }
        return acc
    }, {} as VariationAxisTickValues)

    variationInstances.forEach((instance) => {
        Object.entries(instance.axes).forEach(([key, value]) => {
            variationAxisTickValues[key].value.push(value)
        })
    })

    Array.from(Object.values(variationAxisTickValues)).forEach((axis) => {
        axis.value = Array.from(new Set(axis.value)).sort((a, b) => a - b)
    })

    // 获取 此变体实例在每个轴的具体信息
    let variationInstanceAxes: VariationInstancesAxes[] = []
    let instanceInfo: FontVariantInstance | undefined
    variationInstances.forEach((instance) => {
        if (instance.name == fontInfo.style) {
            instanceInfo = instance
            variationInstanceAxes = variationAxes!.map((axis) => ({
                ...axis,
                value: instance.axes[axis.axisTag],
                axisTag: fourByteStringToNumber(axis.axisTag),
            }))
            return
        }
    })
    const fontNameExt: FontNameExt = {
        ...fontInfo,
        weight: instanceInfo?.axes.wght ?? fontInfo.weight,
        width: formatWidth(instanceInfo?.axes.wdth, fontInfo.width),
        italic: checkInstanceItalicByAxis(instanceInfo ?? ({} as FontVariantInstance)) || fontInfo.italic,
        variable: true,
        variationAxisTickValues,
        variationInstanceAxes,
        variationInstances: variationInstances,
        localizedStyle: fontInfo.localizedStyle,
    }
    return fontNameExt
}

export const checkInstanceItalicByAxis = (instance: FontVariantInstance) => {
    return (
        (instance.axes.slnt != undefined && instance.axes.slnt != 0) ||
        (instance.axes.ital != undefined && instance.axes.ital != 0)
    )
}

/**
 * @returns 返回 fontInfos 中的所有字体的 style, 并按照 weight 从小到大排序
 */
export const getStylesSortedByWeight = (fontInfos: FontInfoVO[] | FontMeta) => {
    const styles = new Map<string, number>()
    const BIG_NUM = 100000

    const handleFontInfoVOArray = (fontInfos_: FontInfoVO[]) => {
        const visitedId = new Set<number>()
        fontInfos_.forEach((font) => {
            if (!visitedId.has(font.id)) {
                if (font.variationInfo?.length) {
                    const variationInfo: FontVariant = JSON.parse(font.variationInfo)
                    variationInfo.instances.forEach((instance) => {
                        const italic = checkInstanceItalicByAxis(instance)
                        styles.set(
                            instance.name,
                            (isNil(instance.axes.wght) ? 0 : instance.axes.wght) + (italic ? BIG_NUM : 0)
                        )
                    })
                    visitedId.add(font.id)
                } else {
                    styles.set(font.style, font.weight)
                }
            }
        })
    }

    const handleFontMeta = (fontInfos_: FontMeta) => {
        if (isNil(fontInfos_.variationInfo)) {
            styles.set(fontInfos_.style, 0)
        } else {
            fontInfos_.variationInfo.instances.forEach((instance) => {
                const italic = checkInstanceItalicByAxis(instance)
                styles.set(instance.name, (isNil(instance.axes.wght) ? 0 : instance.axes.wght) + (italic ? BIG_NUM : 0))
            })
        }
    }

    if (fontInfos instanceof Array) {
        handleFontInfoVOArray(fontInfos as FontInfoVO[])
    } else {
        handleFontMeta(fontInfos as FontMeta)
    }

    return Array.from(styles.entries())
        .sort((a, b) => a[1] - b[1])
        .map(([key]) => key)
}

export const formatWidth = (width: number | undefined, defaultWidth: number) => {
    if (isNil(width)) {
        return defaultWidth
    }

    const UltraCondensedWidth = 1
    const ExtraCondensedWidth = 2
    const CondensedWidth = 3
    const SemiCondensedWidth = 4
    const NormalWidth = 5
    const SemiExpandedWidth = 6
    const ExpandedWidth = 7
    const ExtraExpandedWidth = 8
    const UltraExpandedWidth = 9

    const FcWidthExtraCondensed = 63
    const FcWidthCondensed = 75
    const FcWidthSemiCondensed = 87
    const FcWidthNormal = 100
    const FcWidthSemiExpanded = 113
    const FcWidthExpanded = 125
    const FcWidthExtraExpanded = 150
    const FcWidthUltraExpanded = 200

    if (width < FcWidthExtraCondensed) {
        return UltraCondensedWidth
    }
    if (width < FcWidthCondensed) {
        return ExtraCondensedWidth
    }
    if (width < FcWidthSemiCondensed) {
        return CondensedWidth
    }
    if (width < FcWidthNormal) {
        return SemiCondensedWidth
    }
    if (width < FcWidthSemiExpanded) {
        return NormalWidth
    }
    if (width < FcWidthExpanded) {
        return SemiExpandedWidth
    }
    if (width < FcWidthExtraExpanded) {
        return ExpandedWidth
    }
    if (width < FcWidthUltraExpanded) {
        return ExtraExpandedWidth
    }
    return UltraExpandedWidth
}

function isEqualInstance(pre: FontVariantInstance[], next: FontVariantInstance[]): boolean {
    if (pre.length !== next.length) {
        return false
    }

    for (let i = 0; i < pre.length; i++) {
        if (pre[i].name !== next[i].name || pre[i].localizedName !== next[i].localizedName) {
            return false
        }
        const nextKeys = Object.keys(next[i].axes)
        for (const [key, value] of Object.entries(pre[i].axes)) {
            if (!nextKeys.includes(key) || value !== next[i].axes[key]) {
                return false
            }
        }
    }

    return true
}

function isEqualTickValues(pre: VariationAxisTickValues, next: VariationAxisTickValues): boolean {
    if (pre.length !== next.length) {
        return false
    }

    const nextKeys = Object.keys(next)
    for (const [key, value] of Object.entries(pre)) {
        if (!nextKeys.includes(key) || !isEqual(value, next[key])) {
            return false
        }
    }

    return true
}

export const mergeLocalFontInfo = (pre: FontInfoExt[], next: FontInfoExt[]) => {
    if (next.length === 0) {
        return { fonts: pre, modified: false }
    }

    if (pre.length === 0) {
        return { fonts: next, modified: true }
    }

    let modified = pre.length !== next.length
    const mergeRet: FontInfoExt[] = [...next]
    const nextFamilyMap = new Map(next.map((font) => [font.family, font]))

    pre.forEach((preFont) => {
        if (!nextFamilyMap.has(preFont.family)) {
            mergeRet.push(preFont) // 将被删掉的字体也加入字体列表
            modified = true
        } else {
            if (!modified) {
                const nextFont = nextFamilyMap.get(preFont.family)!
                if (!checkEqual(preFont, nextFont)) {
                    modified = true
                }
            }
        }
    })

    return { fonts: mergeRet, modified }
}

export const checkEqual = (pre: FontInfoExt, next: FontInfoExt) => {
    if (pre.localizedFamily !== next.localizedFamily || pre.styles.length !== next.styles.length) {
        return false
    }

    for (let j = 0; j < pre.styles.length; j++) {
        const preStyle = pre.styles[j]
        const nextStyle = next.styles[j]
        if (
            preStyle.style !== nextStyle.style ||
            preStyle.localizedStyle !== nextStyle.localizedStyle ||
            preStyle.family !== nextStyle.family ||
            preStyle.localizedFamily !== nextStyle.localizedFamily ||
            preStyle.variable !== nextStyle.variable ||
            preStyle.italic !== nextStyle.italic ||
            preStyle.width !== nextStyle.width ||
            preStyle.weight !== nextStyle.weight
        ) {
            return false
        }

        if (
            preStyle.variable &&
            !(
                isEqualInstance(preStyle.variationInstances!, nextStyle.variationInstances!) &&
                isEqualTickValues(preStyle.variationAxisTickValues!, nextStyle.variationAxisTickValues!)
            )
        ) {
            return false
        }
    }

    return true
}

function timeoutPromise<T>(promise: Promise<T>, timeout: number): Promise<T> {
    let timer: NodeJS.Timeout | undefined
    const delay = new Promise((resolve) => {
        timer = setTimeout(resolve, timeout)
    })
    return Promise.race([
        promise,
        delay.then(() => Promise.reject(new Error(`Request timed out after ${timeout}ms`))),
    ]).finally(() => clearTimeout(timer))
}

export async function loadFontFile(url: string) {
    // 2 分钟超时
    const timeoutMs = 2 * 60 * 1000
    const res = await timeoutPromise(fetch(url), timeoutMs)
    if (!res.ok) {
        throw new Error('SentryIgnoreError: failed to load font ' + url + ', status code ' + res.status)
    }
    return { data: new Uint8Array(await timeoutPromise(res.arrayBuffer(), timeoutMs)), url }
}
