/**
 * @owner: loushuangzhanbj@kanyun.com
 */
import { CSSProperties, useEffect, useMemo, useRef } from 'react'

export interface ResizeProps {
    connectWindowResize?: boolean // connectWindowResize=true表示 window 的resize也会触发onChangeResize的回调
    onChangeResize?: () => void
}
/**
 * @export
 * @param {ResizeProps} props
 * @description 触发时机：最近的position≠static的祖先 & 大小发生变化
 */
export function Resize(props: ResizeProps) {
    const objectRef = useRef<HTMLObjectElement>(null)
    const resizeCallbackRef = useRef(props.onChangeResize)
    resizeCallbackRef.current = props.onChangeResize
    const style = useMemo((): CSSProperties => {
        return {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: -999,
            pointerEvents: 'none',
        }
    }, [])

    useEffect(() => {
        const childDoc = objectRef.current?.contentDocument?.defaultView
        if (!childDoc) {
            return
        }
        const onResize = () => {
            resizeCallbackRef.current?.()
        }
        childDoc.addEventListener('resize', onResize, false)
        return () => {
            if (typeof childDoc?.removeEventListener === 'function') {
                childDoc?.removeEventListener('resize', onResize, false)
            }
        }
    }, [])

    useEffect(() => {
        const onResize = () => {
            resizeCallbackRef.current?.()
        }
        if (props.connectWindowResize) {
            window.addEventListener('resize', onResize, false)
        } else {
            window.removeEventListener('resize', onResize)
        }
        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [props.connectWindowResize])

    return (
        <object
            tabIndex={-1}
            type="text/html"
            ref={objectRef}
            data="about:blank"
            style={style}
            width={`100%`}
            height={`100%`}
        ></object>
    )
}
