import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Auto: '自动',
    Top: '上',
    Bottom: '下',
    Center: '居中',
    Stretch: '拉伸',
    Left: '左',
    Right: '右',
    Count: '数量',
    Color: '颜色',
    Type: '布局方式',
    Height: '行高',
    Width: '列宽',
    Margin: '边距',
    Offset: '偏移',
    Gutter: '间距',
    LayoutGrid: '布局网格',
    ApplyVariable: '使用变量',
    DetachVariable: '分离变量',
} as const

export const enTranslation = {
    Auto: 'Auto',
    Top: 'Top',
    Bottom: 'Bottom',
    Center: 'Center',
    Stretch: 'Stretch',
    Left: 'Left',
    Right: 'Right',
    Count: 'Count',
    Color: 'Color',
    Type: 'Type',
    Height: 'Height',
    Width: 'Width',
    Margin: 'Margin',
    Offset: 'Offset',
    Gutter: 'Gutter',
    LayoutGrid: 'Color',
    ApplyVariable: 'Apply variable',
    DetachVariable: 'Detach variable',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
