import { ReactNode } from 'react'
import { translation } from './error-msg-map-mobile.translation'

export const ErrorMsgMapMobile: Record<number, ReactNode> = {
    40000: translation('ThatEmailAnd'),
    40002: translation('ThisEmailIs'),
    40006: translation('FailedToSend'),
    40007: translation('IncorrectVerificationCode'),
    40010: translation('ThisEmailIs_synonyms1'),
    40013: `${translation('FailedToSend_synonyms1')}${translation('PleaseTryAgain')}`,
    40014: translation('PleaseEnterA'),
    40015: `${translation('TheAccountHas')}${translation('IfYouWant')} Motiff ${translation('OfficialSite')}`,
    40017: `${translation('TheOperationIs')}${translation('PleaseTryAgain')}`,
    40019: `${translation('FailedToSend_synonyms1_synonyms2')}${translation('PleaseTryAgain')}`,
    45004: translation('IncorrectVerificationCode'),
    42005: `${translation('TheAccountHas_synonyms1')}${translation('WantToUnfreeze')}${translation('ResetPassword')}`,
    40021: `Log in with Google or reset password with "Reset password"`,
    40027: translation('ssoOnlyLogin'),
    40028: translation('googleOnlyLogin'),
}
