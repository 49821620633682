import { translation } from './file.translation'
/* eslint-disable no-restricted-imports */
import {
    UpdateBatchExportPopupStateCommandWasmCall,
    UpdateDevModeBatchExportPopupStateCommandWasmCall,
    Wukong,
} from '@wukong/bridge-proto'
import { useCallback } from 'react'
import { WKItemType, WKMenuConfig, WKMenuItemType, WKToast } from '../../../../../../../ui-lib/src'
import { domLocation, generateRouterPath, RouteToken } from '../../../../../../../util/src'
import { CommandInvoker } from '../../../../../document/command/command-invoker'
import { CommitType } from '../../../../../document/command/commit-type'
import { generateDefaultDocument, generateDocumentId } from '../../../../../document/util/create-file'
import { usePageSignal } from '../../../../../external-store/atoms/page-signal'
import { useDocReadonly } from '../../../../../external-store/context/doc-info'
import { ShortcutKey, shortcutLabelMap } from '../../../../../kernel/interface/shortcut-key'
import { CreateDocRequest } from '../../../../../kernel/request/document'
import { featureSwitchManager } from '../../../../../kernel/switch'
import { openIndependentWindow } from '../../../../../kernel/util/open-window'
import {
    useDevModeAvailabilityVerificationService,
    useHistoryService,
    usePlanAndUserStateService,
} from '../../../../../main/app-context'
import { useEnterHistoryMode, useLeaveHistoryMode } from '../../../../../main/history-mode'
import { PayPreCheck } from '../../../../../share/payment/pay-pre-check'
import { PayUpgradeDialogFrom } from '../../../../../share/payment/pay-upgrade-dialog/pay-upgrade-dialog-struct/type'
import { isLockedOrgPlan } from '../../../../../utils/plan-status-checker'
import { useViewState } from '../../../../../view-state-bridge'
import { useDocInfoContext } from '../../../../context/top-area-context'
import { useColorProfileMenu } from '../../../color-profile'
import { logs } from '../../../dev-mode/logs'
import { useEachTopFrameAsPDFPage, useExportAsDocument } from '../../../exporter'
import { useCopyFile } from '../../tool-title/use-copy-file'
import { useSaveHistoryVersion } from '../components/use-save-history-version'

function useCommonMenuFile() {
    const { docData, folderData, getCurrentDocOrganization } = useDocInfoContext()
    const isReadOnly = useDocReadonly()

    const createFile = useCallback(() => {
        const currentDocOrganization = getCurrentDocOrganization()
        const fileId = isReadOnly ? currentDocOrganization?.draftFolderId : folderData?.id
        if (fileId) {
            PayPreCheck.checkCreateDoc({
                orgId: currentDocOrganization?.id,
                teamId: folderData?.teamId,
                folderId: fileId,
                from: PayUpgradeDialogFrom.Editor,
                inDraft: isReadOnly ? true : docData?.draft ?? false,
            }).then((res) => {
                if (res.pass) {
                    createEmptyFile(fileId)
                        .then((v) => {
                            openIndependentWindow(
                                `${domLocation().origin}/${generateRouterPath(RouteToken.File)}/${v.id}`
                            )
                        })
                        .catch(() => {
                            WKToast.error(translation('FailedToCreate'))
                        })
                }
            })
        }
    }, [docData?.draft, folderData?.id, folderData?.teamId, getCurrentDocOrganization, isReadOnly])

    return { createFile }
}

export const useDesignModeMenuFile = (command: CommandInvoker): WKMenuConfig => {
    const docReadonly = useViewState('docReadonly')
    const { copyFile } = useCopyFile()
    const saveHistoryVersion = useSaveHistoryVersion()
    const leaveHistoryMode = useLeaveHistoryMode()
    const historyService = useHistoryService()
    const isHistoryMode = historyService.useZustandStore.use.isHistoryMode()
    const { exportAsSketch, exportAsFigma } = useExportAsDocument()
    const createColorProfileMenu = useColorProfileMenu(`${translation('FileColorProfile')}`)
    const eachTopFrameAsPDFPage = useEachTopFrameAsPDFPage()
    const pageSignal = usePageSignal()
    const { createFile } = useCommonMenuFile()
    const { docData } = useDocInfoContext()
    const planAndUserStateService = usePlanAndUserStateService()
    const planAndUserState = planAndUserStateService.useZustandStore.use.planAndUserState()

    const createFileAccessible = PayPreCheck.checkCreatOrImportDocAccessible({
        planAndUserState,
        inDraft: !docData?.teamId,
    })
    const enterHistoryMode = useEnterHistoryMode()
    const hideDuplicate = planAndUserState && isLockedOrgPlan(planAndUserState)

    const batchExport = [
        {
            name: `${translation('Export')}...`,
            handler: () => {
                // 先关闭menu，再打开Modal
                setTimeout(() => {
                    command.invokeBridge(CommitType.Noop, UpdateBatchExportPopupStateCommandWasmCall, {
                        value: true,
                    })
                }, 0)
            },
            disable: isHistoryMode,
            shortCut: shortcutLabelMap[ShortcutKey.BatchExport],
        },
    ]

    return [
        {
            name: translation('File'),
            position: 'right top',
            testId: 'main-menu-file',
            children: [
                {
                    name: translation('NewFile'),
                    disable: false,
                    hidden: !createFileAccessible,
                    handler: createFile,
                },
                {
                    name: translation('Duplicate'),
                    shortCut: '',
                    disable: false,
                    hidden: hideDuplicate,
                    handler: copyFile,
                },
                { type: WKItemType.Divider, hidden: !createFileAccessible && hideDuplicate },
                {
                    name: translation('SaveToVersion'),
                    shortCut: shortcutLabelMap[ShortcutKey.SaveHistoryVersion],
                    disable: false,
                    handler: saveHistoryVersion,
                    hidden: docReadonly,
                },
                {
                    name: isHistoryMode ? translation('HideVersionHistory') : translation('ShowVersionHistory'),
                    shortCut: '',
                    disable: false,
                    handler: isHistoryMode ? leaveHistoryMode : enterHistoryMode,
                },
                { type: WKItemType.Divider } as WKMenuItemType,
                ...batchExport,
                {
                    name: `${translation('ExportFramesToPDF')}`,
                    shortCut: '',
                    disable: false,
                    handler: () => {
                        eachTopFrameAsPDFPage(pageSignal)
                    },
                },
                {
                    name: `${translation('SaveAs')}`,
                    shortCut: '',
                    disable: false,
                    handler: exportAsSketch,
                    hidden: docReadonly,
                },
                ...(!featureSwitchManager.isEnabled('export-ano')
                    ? []
                    : [
                          {
                              name: `${translation('SaveAsFigma')}`,
                              shortCut: '',
                              disable: false,
                              handler: exportAsFigma,
                              hidden: docReadonly,
                          },
                      ]),
                { type: WKItemType.Divider },
                createColorProfileMenu(),
            ],
        },
    ]
}

export const useDevModeMenuFile = (command: CommandInvoker): WKMenuConfig => {
    const { copyFile } = useCopyFile()
    const leaveHistoryMode = useLeaveHistoryMode()
    const historyService = useHistoryService()
    const isHistoryMode = historyService.useZustandStore.use.isHistoryMode()
    const { exportAsSketch, exportAsFigma } = useExportAsDocument()
    const eachTopFrameAsPDFPage = useEachTopFrameAsPDFPage()
    const { createFile } = useCommonMenuFile()
    const pageSignal = usePageSignal()

    const enterHistoryMode = useEnterHistoryMode()
    const { docData } = useDocInfoContext()

    const planAndUserStateService = usePlanAndUserStateService()
    const planAndUserState = planAndUserStateService.useZustandStore.use.planAndUserState()

    const devModeAvailablieyVerificationService = useDevModeAvailabilityVerificationService()
    const isDevModeAccessible = devModeAvailablieyVerificationService.useZustandStore.use.isDevModeAvailable()

    const createFileAccessible = PayPreCheck.checkCreatOrImportDocAccessible({
        planAndUserState,
        inDraft: !docData?.teamId,
    })
    const hideDuplicate = planAndUserState && isLockedOrgPlan(planAndUserState)

    const batchExport = [
        {
            name: `${translation('Export')}...`,
            handler: () => {
                logs.DevMode.exportDialogDisplay({ interaction_type: 'click' })
                command.DEPRECATED_invokeBridge(
                    UpdateDevModeBatchExportPopupStateCommandWasmCall,
                    Wukong.DocumentProto.BridgeProtoBoolean.create({
                        value: true,
                    })
                )
            },
            disable: isHistoryMode,
            shortCut: shortcutLabelMap[ShortcutKey.BatchExport],
            hidden: !isDevModeAccessible,
        },
    ]

    return [
        {
            name: translation('File'),
            position: 'right top',
            testId: 'main-menu-file',
            children: [
                {
                    name: translation('NewFile'),
                    disable: false,
                    hidden: !createFileAccessible,
                    handler: createFile,
                },
                {
                    name: translation('Duplicate'),
                    shortCut: '',
                    disable: false,
                    hidden: hideDuplicate,
                    handler: copyFile,
                },
                { type: WKItemType.Divider, hidden: !isDevModeAccessible || (!createFileAccessible && hideDuplicate) },

                {
                    name: isHistoryMode ? translation('HideVersionHistory') : translation('ShowVersionHistory'),
                    shortCut: '',
                    activity: false,
                    disable: false,
                    hidden: !isDevModeAccessible,
                    handler: isHistoryMode ? leaveHistoryMode : enterHistoryMode,
                },
                { type: WKItemType.Divider, hidden: !isDevModeAccessible } as WKMenuItemType,
                ...batchExport,
                {
                    name: `${translation('ExportFramesToPDF')}`,
                    shortCut: '',
                    disable: false,
                    hidden: !isDevModeAccessible,
                    handler: () => {
                        eachTopFrameAsPDFPage(pageSignal)
                    },
                    testId: 'dev-mode-main-menu-file-batch-export',
                },
                {
                    name: `${translation('SaveAs')}`,
                    shortCut: '',
                    disable: false,
                    hidden: !isDevModeAccessible,
                    handler: exportAsSketch,
                },
                ...(!featureSwitchManager.isEnabled('export-ano')
                    ? []
                    : [
                          {
                              name: `${translation('SaveAsFigma')}`,
                              shortCut: '',
                              disable: false,
                              hidden: !isDevModeAccessible,
                              handler: exportAsFigma,
                          },
                      ]),
                { type: WKItemType.Divider, hidden: !isDevModeAccessible },
            ],
        },
    ]
}

export async function createEmptyFile(folderId: string, fileName = translation('Untitled')) {
    const id = generateDocumentId()
    const data = generateDefaultDocument()

    return new CreateDocRequest({ name: fileName, id, folderId }, data).start()
}
