import { domLocation, safeDecodeUriComponent } from '../../../../../util/src'

export const getRouteHistoryVersionIdAndNodeId = () => {
    const searchParams = new URLSearchParams(domLocation().search)

    function parseValue(key: string) {
        const rawValue = searchParams.get(key) // 如果有多个则只获得第一个
        return rawValue && safeDecodeUriComponent(rawValue)
    }

    return {
        historyVersionId: parseValue('historyVersionId'),
        nodeId: parseValue('nodeId'),
    }
}
