/* eslint-disable no-restricted-imports */
import { FC, MouseEventHandler, PropsWithChildren } from 'react'
import { Wukong } from '@wukong/bridge-proto'
import { isWindows } from '../../../kernel/util/ua'
import { useRightClickOpen } from '../top-area/menu/right-click-menu/right-click-menu'
import RightClickSource = Wukong.DocumentProto.RightClickSource

interface RightClickMenuWrapperProps {
    hidden?: boolean // 不展示右键菜单
    stopPropagation?: boolean
    source: RightClickSource
    onMouseDown?: any
    dataTestId?: string
}

/**
 * 用于响应右键菜单的组件
 * @param props
 */
export const RightClickMenuWrapper: FC<PropsWithChildren<RightClickMenuWrapperProps>> = (props) => {
    const { children, hidden, stopPropagation, dataTestId, source, onMouseDown } = props
    const onOpen = useRightClickOpen()
    // 用于描述 menu 的位置

    // 绑定右键或 control + 左键
    const handleMouseDown: MouseEventHandler<HTMLDivElement> = (event) => {
        const { button, ctrlKey, clientX, clientY } = event
        if (stopPropagation) {
            // 避免选中状态被清除
            event.stopPropagation()
        }

        if (hidden) {
            return
        }

        // windows 不支持 ctrl+左键 唤起右键菜单
        if (button === 2 || (button == 0 && ctrlKey && !isWindows())) {
            onOpen({ source, position: { left: clientX, top: clientY + 8 }, dataTestId })

            onMouseDown?.()
        }
    }

    return <div onMouseDown={handleMouseDown}>{children}</div>
}
