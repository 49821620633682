/* eslint-disable no-restricted-imports */
import { useMemo } from 'react'
import { CommentReaction } from '../../../../kernel/interface/comment'
import { UsualEmoji, usualEmojis } from './common'
import { ReplyEmojiItem, ReplyEmojiItemProps } from './reply-emoji-item'
export interface ReplyEmojiProps extends Pick<ReplyEmojiItemProps, 'currentUserId' | 'onClickEmoji'> {
    reactions: CommentReaction[]
}

export function ReplyEmoji(props: ReplyEmojiProps) {
    const { reactions, currentUserId, onClickEmoji } = props

    const collectEmojis = useMemo(() => {
        const emojiMap = collectEmojiMap(reactions)
        return sortEmojiNamesMapByEmoji(emojiMap)
    }, [reactions])

    return collectEmojis.length ? (
        <div>
            {collectEmojis.map((collectEmoji, index) => (
                <ReplyEmojiItem
                    key={index}
                    reactions={collectEmoji.reactions}
                    emojiInfo={collectEmoji.emojiInfo}
                    currentUserId={currentUserId}
                    onClickEmoji={onClickEmoji}
                />
            ))}
        </div>
    ) : null
}

export function collectEmojiMap(reactions: CommentReaction[]) {
    const map: Map<string, CommentReaction[]> = new Map()
    for (const reaction of reactions) {
        const reactionList = map.get(reaction.message)
        if (reactionList) {
            reactionList.push(reaction)
        } else {
            map.set(reaction.message, [reaction])
        }
    }
    return map
}

export function sortEmojiNamesMapByEmoji(map: ReturnType<typeof collectEmojiMap>) {
    const emojiNames: { emojiInfo: UsualEmoji; reactions: CommentReaction[] }[] = []
    for (const usualEmoji of usualEmojis) {
        const reactions = map.get(usualEmoji.id)
        if (!reactions) {
            continue
        }
        emojiNames.push({ emojiInfo: usualEmoji, reactions })
    }
    return emojiNames
}
