export type baseType = string | number | boolean | null | undefined

export type ObjectLike = Record<string | number, Serializable | baseType>

export interface SerializableObject extends ObjectLike {
    [key: string | number]: Serializable | baseType
}

export type Serializable = SerializableObject | (SerializableObject | baseType)[] | baseType

export type SeenObjects = WeakMap<SerializableObject, keyof SerializableObject>

// TODO(chaibowen): handle circled reference object, function and others correctly
export const serialize = (value: Serializable): string => {
    return JSON.stringify({ value })
}

export const deserialize = <T>(str: string): T => {
    const obj = JSON.parse(str)
    if (obj.type !== undefined && obj.value !== undefined) {
        const { value } = obj
        return value as T
    }
    throw new Error('unexpected message input')
}
