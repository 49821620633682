import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { ExplicitUndefined } from '../../../utils/explicit-undefined'
import { Empty, EmptyProps, EmptyRef } from '../picks/empty/empty'
import { TriggerSvg, TriggerSvgRef } from '../triggers/trigger-svg'
import { SelectEmptyProps, SelectTriggerSvgProps } from './type'
import { useCommentSelect } from './use-comment-select'

export interface SelectSvgEmptyRef {
    close: () => void
}

export type SelectSvgEmptyProps = SelectTriggerSvgProps & SelectEmptyProps

function _SelectSvgEmpty(props: SelectSvgEmptyProps, ref?: React.Ref<SelectSvgEmptyRef>) {
    const pickRef = useRef<EmptyRef>(null)
    const triggerRef = useRef<TriggerSvgRef>(null)
    const {
        pickRest,
        worldRect,
        triggerRect,
        placement,
        overMajorClassName,
        overMinorClassName,
        isMinWidthFromTrigger = true,
        minWidth,
        width,
        maxWidth,
        autoAdjustLeft,
        onKeyboard,
        onClose,
        onOpen,
        ...otherProps
    } = props

    const commentSelectState = useCommentSelect({
        triggerRect,
        triggerRef,
        pickRef,
        onOpen,
        onClose,
    })

    useImperativeHandle(ref, () => ({ close: () => pickRef.current?.close() }), [])

    return (
        <TriggerSvg
            ref={triggerRef}
            {...otherProps}
            isOpenPick={commentSelectState.isOpenPick}
            openAction={commentSelectState.openAction}
            closeAction={commentSelectState.closeAction}
        >
            <Empty<ExplicitUndefined<EmptyProps>>
                pickRest={pickRest}
                dataTestIds={props.dataTestIds}
                triggerRect={commentSelectState.triggerRect}
                closeByESC={commentSelectState.closeByESC}
                onClose={commentSelectState.onSelectClose}
                onOpen={commentSelectState.onSelectOpen}
                ref={pickRef}
                worldRect={worldRect}
                placement={placement}
                overMajorClassName={overMajorClassName}
                overMinorClassName={overMinorClassName}
                isMinWidthFromTrigger={isMinWidthFromTrigger}
                minWidth={minWidth}
                width={width}
                maxWidth={maxWidth}
                onKeyboard={onKeyboard}
                isOpenState={undefined}
                openStateToBeFalse={undefined}
                openStateToBeTrue={undefined}
                getPortalElement={commentSelectState.getPortalElement}
                removeMask={undefined}
                autoAdjustLeft={autoAdjustLeft}
            >
                {props.children}
            </Empty>
        </TriggerSvg>
    )
}

export const SelectSvgEmpty = forwardRef(_SelectSvgEmpty)
