/* eslint-disable no-restricted-imports */
import { Wukong, SelectionCountChange } from '@wukong/bridge-proto'
import { createSelectors, createStore } from '../../../../../util/src'
import { environment } from '../../../environment'
import { Bridge } from '../../../kernel/bridge/bridge'

export interface ChangeSelectionInfo {
    sessionId: number
    selectionCount: number
}

export class SelectionService {
    states = createSelectors(
        createStore<{
            changeSelectionInfo: ChangeSelectionInfo
            updateSelectionInfo: (info: ChangeSelectionInfo) => void
        }>(
            (set) => ({
                changeSelectionInfo: {
                    sessionId: 0,
                    selectionCount: 0,
                },
                updateSelectionInfo: (info: ChangeSelectionInfo) => {
                    set({ changeSelectionInfo: info })
                },
            }),
            environment.isDev
        )
    )

    constructor(private readonly bridge: Bridge) {
        this.bindWasmCallJs()
    }

    private bindWasmCallJs = () => {
        this.bridge.bind(SelectionCountChange, this.wasmChangeSelection)
    }

    private wasmChangeSelection = ({ value }: Wukong.DocumentProto.IBridgeProtoInt) => {
        // 需要改成随机数，要不然测试过不去
        this.states
            .getState()
            .updateSelectionInfo({ sessionId: Date.now() * Math.random(), selectionCount: value ?? 0 })
    }

    destroy = () => {
        this.bridge.unbind(SelectionCountChange)
    }
}
