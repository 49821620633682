import { useGet } from 'ccstate-react'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { action } from '../../../../util/src/ccstate-helper/action'
import { traceable } from '../../../../util/src/ccstate-helper/traceable'

export const [pageSignal$, setupPageSignal$] = traceable(
    'hulh01@kanyun.com',
    action((_, signal: TraceableAbortSignal) => {
        return signal
    })
)

export const usePageSignal = () => {
    const pageSignal = useGet(pageSignal$)

    if (!pageSignal) {
        throw new Error('pageSignal is not initialized')
    }

    return pageSignal
}
