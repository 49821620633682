import { RoleStatus } from '../../../kernel/interface/type'
import { compareRole, GetTeamRoleStatus, RoleStatusWeight } from '../../../kernel/interface/user-roles'
import { featureSwitchManager } from '../../../kernel/switch'
import { translation } from './team.translation'
import { getLERoles } from './util'

export enum TeamInfoMenuKeyEnum {
    LeaveTeam = 'LeaveTeam',
    Rename = 'rename',
    NeedHandover = 'needHandover',
    ChangeIcon = 'changeIcon',
    Delete = 'delete',
    CopyLink = 'copyLink',
    ChangeTeamPublicStatus = 'changeTeamPublicStatus',
}

const TeamInfoMenuKey2label: Record<TeamInfoMenuKeyEnum, string> = {
    [TeamInfoMenuKeyEnum.LeaveTeam]: translation('LeaveTeam...'),
    [TeamInfoMenuKeyEnum.Rename]: translation('Rename'),
    [TeamInfoMenuKeyEnum.NeedHandover]: translation('TransferResourses'),
    [TeamInfoMenuKeyEnum.ChangeIcon]: translation('ChangeIcon'),
    [TeamInfoMenuKeyEnum.Delete]: translation('DeleteTeam...'),
    [TeamInfoMenuKeyEnum.CopyLink]: translation('CopyLink'),
    [TeamInfoMenuKeyEnum.ChangeTeamPublicStatus]: featureSwitchManager.isEnabled('organization-plus')
        ? translation('ChangeTeamAccess')
        : translation('ChangeTeamVisibility'),
}

// 有权限的人都可以邀请成员 但是只能邀请为不高于自己的权限
export const getTeamInviteMemberMenuItems = (role: RoleStatus) => {
    const roles = GetTeamRoleStatus()
        .filter((r) => RoleStatusWeight[r] <= RoleStatusWeight[role])
        .filter((r) => ![RoleStatus.None, RoleStatus.Owner].includes(r))
    return roles
}

// 团队-管理员、所有者、编辑者可以看到设置页
export const canShowTeamSetting = (role: RoleStatus) => {
    return !getLERoles(RoleStatus.Viewer).includes(role)
}

// 企业-管理员、所有者可以看到设置页
export const canShowOrgSetting = (role: RoleStatus) => {
    return !getLERoles(RoleStatus.Editor).includes(role)
}

// 管理员和所有者可以在设置页面做修改操作
export const canEditSetting = (role: RoleStatus) => {
    return !getLERoles(RoleStatus.Editor).includes(role)
}

// 管理员和所有者可以修改团队名称描述头像
export const canEditTeamInfo = (role: RoleStatus) => {
    return !getLERoles(RoleStatus.Editor).includes(role)
}

// 管理员和所有者可以删除团队
export const canDeleteTeam = (role: RoleStatus) => {
    return !getLERoles(RoleStatus.Editor).includes(role)
}

// 管理员和所有者可以查看并编辑资源, 可编辑可以查看资源

export const canShowResource = (role: RoleStatus) => {
    return compareRole(role, RoleStatus.Editor) >= 0
}

// 获取团队列表右键菜单项
export const getTeamItemMenuItemsV2 = (
    role: RoleStatus,
    orgId: string,
    needHandover: boolean,
    teamReadonly: boolean
) => {
    const res: TeamInfoMenuKeyEnum[] = [TeamInfoMenuKeyEnum.CopyLink]
    if (canEditTeamInfo(role)) {
        res.push(TeamInfoMenuKeyEnum.Rename, TeamInfoMenuKeyEnum.ChangeIcon)
    }
    const res2: TeamInfoMenuKeyEnum[] = []
    // 团队只读模式的时候，不展示离开团队按钮
    if (!teamReadonly) {
        res2.push(TeamInfoMenuKeyEnum.LeaveTeam)
    }
    if (canDeleteTeam(role)) {
        res2.push(TeamInfoMenuKeyEnum.Delete)
    }

    return [res, res2]
}

export const getTeamContextMenuItemsForNavbar = (...args: Parameters<typeof getTeamItemMenuItemsV2>) => {
    const keysList = getTeamItemMenuItemsV2(...args)
    return keysList
        .map((keys, idx) => {
            if (idx === 0) {
                return keys.map((value) => ({
                    label: TeamInfoMenuKey2label[value],
                    value,
                }))
            }
            return keys.map((value, _idx) => ({
                label: TeamInfoMenuKey2label[value],
                value,
                splitLineTop: !_idx,
            }))
        })
        .flat()
}
