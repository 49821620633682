import {
    PluginApiGetImageBytesAsync,
    PluginApiGetImageBytesAsyncCallback,
    PluginApiGetImageSizeAsync,
    PluginApiGetImageSizeAsyncCallback,
} from '@wukong/bridge-proto'
import { IBasicBridge } from '../../kernel/bridge/basic-bridge'
import { IPluginAPIContext } from '../plugin-api-context-interface'

export class ImageStore {
    getImageSizeAsyncCbId = 1
    getImageBytesAsyncCbId = 1
    getImageSizeAsyncCbMap = new Map<
        number,
        { successCb: (value: { width: number; height: number }) => void; failCb: () => void }
    >()
    getImageBytesAsyncCbMap = new Map<
        number,
        { successCb: (value: { bytes: Uint8Array }) => void; failCb: () => void }
    >()
    constructor(private readonly bridge: IBasicBridge, private readonly ctx: IPluginAPIContext) {
        bridge.bind(PluginApiGetImageSizeAsyncCallback, (arg) => {
            const cb = this.getImageSizeAsyncCbMap.get(arg.cbId)
            if (cb) {
                if (arg.success) {
                    cb.successCb({
                        width: arg.width,
                        height: arg.height,
                    })
                } else {
                    cb.failCb()
                }
            }
            this.getImageSizeAsyncCbMap.delete(arg.cbId)
        })
        bridge.bind(PluginApiGetImageBytesAsyncCallback, (arg) => {
            const cb = this.getImageBytesAsyncCbMap.get(arg.cbId)
            if (cb) {
                if (arg.success) {
                    cb.successCb({
                        bytes: arg.bytes,
                    })
                } else {
                    cb.failCb()
                }
            }
            this.getImageBytesAsyncCbMap.delete(arg.cbId)
        })
    }

    createImageHandle(hash: string) {
        const ret = this.ctx.vm.newObject()
        this.ctx.defineVmProp({
            objhandle: ret,
            key: 'hash',
            value: this.ctx.vm.newString(hash),
        })

        this.ctx.defineVmFunction({
            objhandle: ret,
            key: 'getSizeAsync',
            func: () => {
                const { promise, resolve, reject } = this.ctx.vm.newPromise()
                const cbId = this.getImageSizeAsyncCbId++
                this.getImageSizeAsyncCbMap.set(cbId, {
                    successCb: (value) => {
                        resolve(this.ctx.vm.deepWrapHandle(value))
                    },
                    failCb: () => {
                        reject(this.ctx.vm.newString('Failed to get image size'))
                    },
                })
                this.ctx.unwrapCallBridge(PluginApiGetImageSizeAsync, {
                    cbId,
                    hash,
                })
                return promise
            },
            canUseInReadonly: true,
        })

        this.ctx.defineVmFunction({
            objhandle: ret,
            key: 'getBytesAsync',
            func: () => {
                const { promise, resolve, reject } = this.ctx.vm.newPromise()
                const cbId = this.getImageBytesAsyncCbId++
                this.getImageBytesAsyncCbMap.set(cbId, {
                    successCb: (value) => {
                        resolve(this.ctx.vm.deepWrapHandle(value))
                    },
                    failCb: () => {
                        reject(this.ctx.vm.newString('Failed to get image bytes'))
                    },
                })
                this.ctx.unwrapCallBridge(PluginApiGetImageBytesAsync, {
                    cbId,
                    hash,
                })
                return promise
            },
            canUseInReadonly: true,
        })

        return ret
    }
}
