import { ScrubbableInputNumber } from '../../atom/inputs/scrubbable-input-number'
import { Value } from '../../atom/inputs/utils/type'
import { RangeV2 } from '../../atom/range-v2'
import { translation } from './remix-design-system-custom-size.translation'

import { useAIGenUIInContext } from './use-ai-gen-ui'

const sizeSettings = [
    {
        id: 'fontSizeBase',
        label: translation('baseFontSize'),
        defaultValue: 14,
        min: 12,
        max: 34,
        step: 2,
        splitValues: [12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34],
    },
    //{
    //    id: 'margin',
    //    label: 'Margin',
    //    defaultValue: 4,
    //    min: 0,
    //    max: 16,
    //    step: 2,
    //    splitValues: [0, 2, 4, 6, 8, 10, 12, 14, 16],
    //},
    {
        id: 'borderRadius',
        label: translation('borderRadius'),
        defaultValue: 6,
        min: 0,
        max: 16,
        step: 2,
        splitValues: [0, 2, 4, 6, 8, 10, 12, 14, 16],
    },
] as const

export function StylingAntDesignSize() {
    const { updateRemixConfig, tempRemixConfig } = useAIGenUIInContext()

    if (tempRemixConfig === null) {
        return null
    }

    return (
        <div className="flex flex-col gap-1 mb-0.5">
            <span className="wk-text-12 text-black wk-font-semibold">{translation('size')}</span>
            <div className="flex flex-col gap-3">
                {sizeSettings.map((setting) => {
                    const tempConfigValue = tempRemixConfig.get(setting.id)
                    if (typeof tempConfigValue !== 'string') {
                        return null
                    }
                    const parsedNumber = Number(tempConfigValue ?? setting.defaultValue)

                    return (
                        <div key={setting.id} className="flex flex-col h-13 gap-1">
                            <span className="wk-text-12 text-gray">{setting.label}</span>
                            <div className="flex flex-row h-7 gap-4">
                                <RangeV2
                                    disabled={false}
                                    curValue={parsedNumber}
                                    minValue={setting.min}
                                    maxValue={setting.max}
                                    defaultValue={setting.defaultValue}
                                    spiltValues={[...setting.splitValues]}
                                    isMixed={false}
                                    step={setting.step}
                                    onChange={(v: number) => updateRemixConfig(setting.id, v.toString())}
                                />
                                <ScrubbableInputNumber
                                    className="w-8.5 h-full shrink-0"
                                    disabled={false}
                                    value={parsedNumber}
                                    isMixed={false}
                                    onChange={(v: Value) => updateRemixConfig(setting.id, (v as number).toString())}
                                    min={setting.min}
                                    max={setting.max}
                                    leftScrubbable={false}
                                    rightScrubbable={false}
                                    notUseUserConfig
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
