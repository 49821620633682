import { Wukong } from '@wukong/bridge-proto'
import { Scrollbar } from '../../../../../ui-lib/src'
import { useViewState } from '../../../view-state-bridge'
import { FrameTemplate } from '../design/frame-template/frame-template'
import { BackPrototypeSettingButton } from './back-prototype-setting-button'
import { PrototypeAddStartingPoint } from './prototype-add-starting-point/prototype-add-starting-point'
import { PrototypeBackgroundColor } from './prototype-background-color/prototype-background-color'
import { PrototypeDevice } from './prototype-device/prototype-device'
import { PrototypeFlows } from './prototype-flows/prototype-flows'
import { PrototypeInteraction } from './prototype-interaction'
import { ProtoTypeScrollBehavior } from './prototype-scroll-behavior'

export const Prototype = () => {
    const selectionLength = useViewState('singleSelection')?.length ?? 0
    const editorMode = useViewState('editorModeState')
    return (
        <Scrollbar data-testid="prototype-panel">
            {editorMode?.editorMode == Wukong.DocumentProto.EditorMode.EM_Frame ? (
                <FrameTemplate />
            ) : (
                <>
                    {selectionLength > 0 ? (
                        <>
                            <PrototypeAddStartingPoint />
                            <PrototypeInteraction />
                            <ProtoTypeScrollBehavior />
                            <BackPrototypeSettingButton />
                        </>
                    ) : (
                        <>
                            <PrototypeDevice />
                            <PrototypeBackgroundColor />
                            <PrototypeFlows disableRename={false} disableDelete={false} />
                        </>
                    )}
                </>
            )}
        </Scrollbar>
    )
}
