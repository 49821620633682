import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { WKButton, WKDialog, WKDivider, WKToast, WKTypography } from '../../../../../../../ui-lib/src'
import { WKForm, WKFormItem } from '../../../../../../../ui-lib/src/components/wk-form'
import {
    FormCompNormalInput,
    FormCompPasswordInput,
} from '../../../../../../../ui-lib/src/components/wk-form/form-component'
import { isEnglishLanguage } from '../../../../../../../util/src'
import { useUserInfoContext } from '../../../../../auth'
import { ResetPasswordWithEmailLogin } from '../../../../../kernel/request/account'
import { AccountManage } from '../../../frog-task/account-manage'
import { translation } from './index.translation'
import { CountdownButton } from './verify-code-but'
const passwordRegexp = /^(?=.*[a-zA-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z]).*$/

const passwordSchema = z
    .string()
    .min(8, translation('password-too-short'))
    .regex(passwordRegexp, translation('password-must-include-two-of-digits-uppercase-lowercase'))

export const ResetPassword = ({ closeResetPassword }: { closeResetPassword: () => void }) => {
    const { userInfo } = useUserInfoContext()
    const { email } = userInfo.userBrief
    const formId = 'reset-password-login'
    const [otpToken, setOtpToken] = useState('')

    const schema = z
        .object({
            otp: z.string().min(1, translation('DQxQQv')),
            password: passwordSchema,
            rePassword: z.string(),
        })
        .superRefine((data, ctx) => {
            if (data.password && data.rePassword && data.password !== data.rePassword) {
                ctx.addIssue({
                    path: ['rePassword'],
                    code: z.ZodIssueCode.custom,
                    message: translation('reset-password-not-match'),
                })
            }
        })

    type Data = z.infer<typeof schema>

    const {
        handleSubmit,
        control,
        formState: { isValid },
        setError,
    } = useForm<Data>({
        mode: 'onBlur',
        resolver: zodResolver(schema),
        defaultValues: {
            password: '',
            rePassword: '',
            otp: '',
        },
    })
    const onSubmit = async (data: Data) => {
        const { otp, password } = data
        try {
            await new ResetPasswordWithEmailLogin({ email, otp, otpToken, password }).start()
            AccountManage.resetPasswordSuccess()
            WKToast.show(translation('YourPasswordHas'))
            closeResetPassword()
        } catch {
            setError('otp', { message: translation('IncorrectVerificationCode') })
        }
    }

    return (
        <WKDialog
            footer={null}
            title={translation('ResetPassword')}
            visible={true}
            onCancel={closeResetPassword}
            bodyStyle={{ padding: 0 }}
        >
            <div>
                <div className="px-6 pb-6 pt-5 flex flex-col gap-3">
                    <WKTypography.Text className="inline!">
                        {isEnglishLanguage() ? (
                            <>
                                Resetting passwords requires security verification. Verify via your email{' '}
                                <span className={'wk-font-semibold'}>{email}</span>.
                            </>
                        ) : (
                            <>
                                设置密码需要进行安全验证。请通过邮箱 <span className={'wk-font-semibold'}>{email}</span>{' '}
                                进行验证
                            </>
                        )}
                    </WKTypography.Text>
                    <WKForm id={formId} onSubmit={handleSubmit(onSubmit)}>
                        <WKFormItem>
                            <div className="flex gap-2">
                                <FormCompNormalInput
                                    name="otp"
                                    control={control}
                                    inputProps={{ placeholder: translation('DQxQQv'), rootClassName: 'flex-1' }}
                                />
                                <CountdownButton
                                    onError={() => {
                                        setError('otp', { message: translation('IncorrectVerificationCode') })
                                    }}
                                    onOtpToken={setOtpToken}
                                />
                            </div>
                        </WKFormItem>
                        <WKFormItem>
                            <FormCompPasswordInput
                                name="password"
                                control={control}
                                inputProps={{ placeholder: translation('Password') }}
                            />
                        </WKFormItem>
                        <WKFormItem>
                            <FormCompPasswordInput
                                name="rePassword"
                                control={control}
                                inputProps={{ placeholder: translation('IkmzAl') }}
                            />
                        </WKFormItem>
                    </WKForm>
                </div>
                <WKDivider />
                <div className="flex items-center justify-end px-6 py-4 ">
                    <WKButton onClick={closeResetPassword} type="secondary">
                        取消
                    </WKButton>
                    <WKButton type="primary" htmlType="submit" formId={formId} disabled={!isValid}>
                        确定
                    </WKButton>
                </div>
            </div>
        </WKDialog>
    )
}
