import { translation } from './scrubbable-input-layout-grid.translation'
/**
 * layoutGrid 专用输入框
 * 功能特点： 当禁用的时候显示 传入的参数 showWhendisabled，非禁用状态时 显示整数值
 */

import { useCallback } from 'react'
import { ScrubbableInput, ScrubbableInputProps } from './components/scrubbable-input'
import { formateToInteger } from './utils/format'
import { Value } from './utils/type'
import { useBaseFormatterTool } from './utils/use-base-formatter-tool'
import { valueFilter10, valueFilterUserConfig } from './utils/value-filter'

export interface ScrubbableInputLayoutGridProps extends Omit<ScrubbableInputProps, 'disabled'> {
    disabled: boolean
    showWhendisabled: string
    showAutoWhenValueEqual?: Value
}

export function ScrubbableInputLayoutGrid(props: ScrubbableInputLayoutGridProps) {
    const baseFormatter = useBaseFormatterTool(props)
    const parse = useCallback(
        (inputValue: string, oldValue?: Value): Value => {
            const res = baseFormatter.parse(inputValue, oldValue)
            if (Array.isArray(res)) {
                return res.map(Math.round)
            } else {
                return Math.round(res)
            }
        },
        [baseFormatter]
    )

    const formatter =
        (disable: boolean, showAutoWhenValueEqual: Value | undefined) =>
        (value: Value): string => {
            if (disable) {
                return props.showWhendisabled
            }
            if (showAutoWhenValueEqual === value) {
                return translation('Auto')
            }

            return formateToInteger(value)
        }

    return (
        <ScrubbableInput
            formatter={{ format: formatter(props.disabled, props.showAutoWhenValueEqual), parse }}
            valueFilter={!props.notUseUserConfig ? valueFilterUserConfig : valueFilter10}
            resolution={1}
            {...props}
            allowedUnits={'px'}
        />
    )
}
