import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useCallback, useMemo, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { MonoIconCommonInfoLine16, Select, Tooltip, useEllipsisTooltip } from '../../../../../../../ui-lib/src'
import { isEnglishLanguage } from '../../../../../../../util/src'
import { LibraryPublishScope, LibraryShareScope } from '../../../../../kernel/interface/library'
import { DocID, PublicStatus } from '../../../../../kernel/interface/type'
import { GetLibraryShareScope } from '../../../../../kernel/request/library'
import { featureSwitchManager } from '../../../../../kernel/switch'
import { LibraryTestId } from '../../../../../window'
import { useDocInfoContext } from '../../../../context/top-area-context'
import styles from './share-scope.module.less'
import { translation } from './share-scope.translation'

const ShareScopeType2Label = {
    [Wukong.DocumentProto.LibraryShareScopeType.LIBRARY_SHARE_SCOPE_TYPE_ORG]: translation('OrgV2'),
    [Wukong.DocumentProto.LibraryShareScopeType.LIBRARY_SHARE_SCOPE_TYPE_TEAM]: translation('Team'),
    [Wukong.DocumentProto.LibraryShareScopeType.LIBRARY_SHARE_SCOPE_TYPE_WORKSPACE]: translation('Workspace'),
}

const SHARE_SCOPE_ITEMS = [
    {
        key: Wukong.DocumentProto.LibraryShareScopeType.LIBRARY_SHARE_SCOPE_TYPE_ORG,
        serverScope: LibraryShareScope.ORG,
    },
    {
        key: Wukong.DocumentProto.LibraryShareScopeType.LIBRARY_SHARE_SCOPE_TYPE_WORKSPACE,
        serverScope: LibraryShareScope.WORKSPACE,
    },
    {
        key: Wukong.DocumentProto.LibraryShareScopeType.LIBRARY_SHARE_SCOPE_TYPE_TEAM,
        serverScope: LibraryShareScope.TEAM,
    },
]

const ShareScopeSelectV1 = ({
    teamPublicStatus,
    orgId,
    selected,
    onChange,
}: {
    teamPublicStatus: PublicStatus | undefined
    orgId: string | undefined
    selected: Wukong.DocumentProto.LibraryShareScopeType
    onChange: (scope: Wukong.DocumentProto.LibraryShareScopeType) => void
    docId: DocID
}) => {
    const items = useMemo(
        () => [
            ...(orgId && orgId !== '-1'
                ? [
                      {
                          key: Wukong.DocumentProto.LibraryShareScopeType.LIBRARY_SHARE_SCOPE_TYPE_ORG,
                          label: (
                              <ShareScope2LabelV1
                                  scopeType={Wukong.DocumentProto.LibraryShareScopeType.LIBRARY_SHARE_SCOPE_TYPE_ORG}
                                  hiddenTooltip
                                  className={styles.shareScopeOption}
                              />
                          ),
                      },
                  ]
                : []),
            {
                key: Wukong.DocumentProto.LibraryShareScopeType.LIBRARY_SHARE_SCOPE_TYPE_TEAM,
                label: (
                    <ShareScope2LabelV1
                        scopeType={Wukong.DocumentProto.LibraryShareScopeType.LIBRARY_SHARE_SCOPE_TYPE_TEAM}
                        hiddenTooltip
                        className={styles.shareScopeOption}
                    />
                ),
            },
        ],
        [orgId]
    )

    return (
        <div
            className="flex items-center overflow-hidden color-$wk-v2-label-color-gray-13"
            data-testid={LibraryTestId.PublishModal.ShareScopeSelectContainer}
        >
            <span
                className={classNames('inline-block flex-none', {
                    'mr-1': !featureSwitchManager.isEnabled('organization-plus'),
                })}
            >
                {featureSwitchManager.isEnabled('organization-plus')
                    ? translation('PublishToV2')
                    : translation('PublishTo')}
            </span>
            {items.length > 1 ? (
                <Select.MinimalSingleLevel
                    value={selected}
                    disableDefaultPreselect
                    label={<ShareScope2LabelV1 scopeType={selected} />}
                    onChange={onChange}
                    dataTestIds={{ triggerFocus: LibraryTestId.PublishModal.ShareScopeSelect }}
                    placement="bottom left"
                    className="flex-auto overflow-hidden"
                >
                    {items.map((v) => (
                        <Select.MinimalSingleLevel.Option
                            centerContainerClassName="flex-auto"
                            key={v.key}
                            value={v.key}
                        >
                            {v.label}
                        </Select.MinimalSingleLevel.Option>
                    ))}
                </Select.MinimalSingleLevel>
            ) : (
                <ShareScope2LabelV1 scopeType={selected} />
            )}
            <PrivateTeamWarningTip scopeType={selected} teamPublicStatus={teamPublicStatus} />
        </div>
    )
}

const ShareScopeSelectV2 = ({
    docId,
    teamPublicStatus,
    selected,
    onChange,
}: {
    docId: DocID
    teamPublicStatus: PublicStatus | undefined
    orgId: string | undefined
    selected: Wukong.DocumentProto.LibraryShareScopeType
    onChange: (scope: Wukong.DocumentProto.LibraryShareScopeType) => void
}) => {
    const [publicScopeValue, setPublicScopeValue] = useState<LibraryPublishScope>({
        publishScope: [],
        workspaceName: '',
    })

    const selectItems = useMemo(
        () =>
            SHARE_SCOPE_ITEMS.map((o) => ({
                ...o,
                label: (
                    <ShareScope2LabelV2
                        scopeType={o.key}
                        hiddenTooltip
                        className={styles.shareScopeOption}
                        workspaceName={publicScopeValue.workspaceName}
                    />
                ),
            })),
        [publicScopeValue.workspaceName]
    )

    const fetchShareScope = useCallback(
        async (inital: boolean) => {
            await new GetLibraryShareScope(docId)
                .start()
                .then((v) => {
                    setPublicScopeValue(v)
                })
                .catch(() => {
                    setPublicScopeValue({
                        publishScope: [],
                        workspaceName: '',
                    })
                })
                .finally(() => {
                    if (!inital) {
                        setSelectOptVisible(true)
                    }
                })
        },
        [docId]
    )

    useEffectOnce(() => {
        fetchShareScope(true)
    })

    const [selectOptVisible, setSelectOptVisible] = useState(false)

    return (
        <div
            className="flex items-center overflow-hidden color-$wk-v2-label-color-gray-13"
            data-testid={LibraryTestId.PublishModal.ShareScopeSelectContainer}
        >
            <span className="inline-block flex-none">{translation('PublishToV2')}</span>
            {publicScopeValue.publishScope.length > 1 ? (
                <Select.MinimalSingleLevel
                    openStateToBeTrue={() => selectOptVisible}
                    onOpen={async () => fetchShareScope(false)}
                    onClose={() => setSelectOptVisible(false)}
                    value={selected}
                    disableDefaultPreselect
                    label={<ShareScope2LabelV2 scopeType={selected} workspaceName={publicScopeValue.workspaceName} />}
                    onChange={onChange}
                    dataTestIds={{ triggerFocus: LibraryTestId.PublishModal.ShareScopeSelect }}
                    placement="bottom left"
                    className="flex-auto overflow-hidden"
                >
                    {selectItems.map((v) => {
                        if (publicScopeValue.publishScope.includes(v.serverScope)) {
                            return (
                                <Select.MinimalSingleLevel.Option
                                    centerContainerClassName="flex-auto"
                                    key={v.key}
                                    value={v.key}
                                >
                                    {v.label}
                                </Select.MinimalSingleLevel.Option>
                            )
                        } else {
                            return null
                        }
                    })}
                </Select.MinimalSingleLevel>
            ) : (
                <ShareScope2LabelV2 scopeType={selected} workspaceName={publicScopeValue.workspaceName} />
            )}
            <PrivateTeamWarningTip scopeType={selected} teamPublicStatus={teamPublicStatus} />
        </div>
    )
}

export const ShareScopeSelect = (props: {
    docId: DocID
    teamPublicStatus: PublicStatus | undefined
    orgId: string | undefined
    selected: Wukong.DocumentProto.LibraryShareScopeType
    onChange: (scope: Wukong.DocumentProto.LibraryShareScopeType) => void
}) => {
    return featureSwitchManager.isEnabled('organization-plus') ? (
        <ShareScopeSelectV2 {...props} />
    ) : (
        <ShareScopeSelectV1 {...props} />
    )
}

function PrivateTeamWarningTip(props: {
    scopeType: Wukong.DocumentProto.LibraryShareScopeType
    teamPublicStatus: PublicStatus | undefined
}) {
    return props.teamPublicStatus === PublicStatus.PRIVATE &&
        props.scopeType == Wukong.DocumentProto.LibraryShareScopeType.LIBRARY_SHARE_SCOPE_TYPE_ORG ? (
        <Tooltip title={translation('PrivateTeamPublishTooltip')} placement="bottom">
            <MonoIconCommonInfoLine16
                className={classNames(styles.warningTip, 'flex-none ml-2')}
                data-testid={LibraryTestId.PublishModal.PrivateTeamWarning}
            />
        </Tooltip>
    ) : (
        <></>
    )
}

function useShareScope2Label(scopeType: Wukong.DocumentProto.LibraryShareScopeType) {
    const { docData, organizationList } = useDocInfoContext()

    if (scopeType === Wukong.DocumentProto.LibraryShareScopeType.LIBRARY_SHARE_SCOPE_TYPE_TEAM) {
        return docData?.teamName || translation('Team')
    }

    if (scopeType === Wukong.DocumentProto.LibraryShareScopeType.LIBRARY_SHARE_SCOPE_TYPE_ORG) {
        return (
            (docData?.orgId && docData.orgId !== '-1'
                ? organizationList.find(({ id }) => docData.orgId === id)?.name
                : '') || translation('Org')
        )
    }

    return ''
}

function useShareScope2LabelV2(scopeType: Wukong.DocumentProto.LibraryShareScopeType, workspaceName?: string | null) {
    const { docData, organizationList } = useDocInfoContext()

    if (scopeType === Wukong.DocumentProto.LibraryShareScopeType.LIBRARY_SHARE_SCOPE_TYPE_TEAM) {
        return docData?.teamName || translation('Team')
    } else if (scopeType === Wukong.DocumentProto.LibraryShareScopeType.LIBRARY_SHARE_SCOPE_TYPE_ORG) {
        return (
            (docData?.orgId && docData.orgId !== '-1'
                ? organizationList.find(({ id }) => docData.orgId === id)?.name
                : '') || translation('Org')
        )
    } else if (scopeType === Wukong.DocumentProto.LibraryShareScopeType.LIBRARY_SHARE_SCOPE_TYPE_WORKSPACE) {
        return workspaceName ? workspaceName : translation('Workspace')
    }

    return ''
}

function ShareScope2LabelV1(props: {
    scopeType: Wukong.DocumentProto.LibraryShareScopeType
    className?: string
    hiddenTooltip?: boolean
}) {
    const { inactivation, ellipsisRef, onmouseenter, onmouseleave } = useEllipsisTooltip<HTMLDivElement>()
    const labelText = useShareScope2Label(props.scopeType)
    const isEnglish = useMemo(() => isEnglishLanguage(), [])
    const text = useMemo(() => {
        if (isEnglish) {
            return {
                content: (
                    <>
                        <span className="inline-block flex-none">
                            {props.scopeType === Wukong.DocumentProto.LibraryShareScopeType.LIBRARY_SHARE_SCOPE_TYPE_ORG
                                ? ' everyone at'
                                : ' everyone in'}
                        </span>
                        <span className="inline-block truncate ml-1">&quot;{labelText}&quot;</span>
                    </>
                ),
                suffix: '',
            }
        } else {
            return {
                content: <span className="inline-block truncate">「{labelText}</span>,
                suffix: '」中的所有人',
            }
        }
    }, [isEnglish, labelText, props.scopeType])

    return (
        <Tooltip
            title={text.content + text.suffix}
            inactivation={inactivation || props.hiddenTooltip}
            placement="bottom"
            addContainer={{
                onMouseEnter: onmouseenter,
                onMouseLeave: onmouseleave,
                className: classNames(props.className, 'flex flex-auto overflow-hidden items-center'),
            }}
        >
            <div
                className="inline-block overflow-hidden flex items-center"
                ref={ellipsisRef}
                data-testid={LibraryTestId.PublishModal.ShareScopeSelectItem(props.scopeType)}
            >
                {text.content}
            </div>
            <span className="inline-block flex-none">{text.suffix}</span>
        </Tooltip>
    )
}

function ShareScope2LabelV2(props: {
    scopeType: Wukong.DocumentProto.LibraryShareScopeType
    className?: string
    hiddenTooltip?: boolean
    workspaceName: string | null
}) {
    const { inactivation, ellipsisRef, onmouseenter, onmouseleave } = useEllipsisTooltip<HTMLDivElement>()
    const labelText = useShareScope2LabelV2(props.scopeType, props.workspaceName)
    const isEnglish = useMemo(() => isEnglishLanguage(), [])
    const text = useMemo(() => {
        if (isEnglish) {
            return {
                content: (
                    <>
                        <span className="inline-block flex-none">{` everyone in ${
                            ShareScopeType2Label[props.scopeType]
                        }\u00A0`}</span>
                        <span className="inline-block truncate" ref={ellipsisRef}>
                            &quot;{labelText}&quot;
                        </span>
                    </>
                ),
                suffix: '',
            }
        } else {
            return {
                content: (
                    <span ref={ellipsisRef} className="inline-block truncate">{`${
                        ShareScopeType2Label[props.scopeType]
                    }「${labelText}`}</span>
                ),
                suffix: '」中的所有人',
            }
        }
    }, [isEnglish, labelText, props.scopeType, ellipsisRef])

    return (
        <Tooltip
            title={translation('PublishEveryoneInSpecificOrganizationTooltip', {
                specificOrg: ShareScopeType2Label[props.scopeType],
                specificOrgName: labelText,
            })}
            inactivation={inactivation || props.hiddenTooltip}
            placement="bottom"
            addContainer={{
                onMouseEnter: onmouseenter,
                onMouseLeave: onmouseleave,
                className: classNames(props.className, 'flex flex-auto overflow-hidden items-center'),
            }}
        >
            <div
                className="inline-block overflow-hidden flex items-center"
                data-testid={LibraryTestId.PublishModal.ShareScopeSelectItem(props.scopeType)}
            >
                {text.content}
            </div>
            <span className="inline-block flex-none">{text.suffix}</span>
        </Tooltip>
    )
}
