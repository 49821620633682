import classNames from 'classnames'
import React, { forwardRef, Ref, useCallback, useMemo } from 'react'
import { TabElement } from '../tab-element'
import classes from './switch.module.less'

export interface SwitchProps {
    checked?: boolean
    loading?: boolean
    isMixed?: boolean
    disabled?: boolean
    isStyleHover?: boolean
    isStyleFocus?: boolean
    dataTestIds?: { switch?: string; tabElement?: string }
    onChange?: (checkedToBe: boolean, e: React.MouseEvent | React.KeyboardEvent) => void
}

function _Switch(props: SwitchProps, ref: Ref<HTMLElement>) {
    const { checked, loading, isMixed, disabled, isStyleHover, isStyleFocus, dataTestIds, onChange } = props

    const state = useMemo(() => {
        if (isMixed) {
            return 'mixed'
        }
        if (checked) {
            if (loading) {
                return 'opening'
            } else {
                return 'opened'
            }
        } else {
            if (loading) {
                return 'closing'
            } else {
                return 'closed'
            }
        }
    }, [checked, isMixed, loading])

    const disabledSwitch = useMemo(() => {
        return disabled || state === 'closing' || state === 'opening'
    }, [disabled, state])

    const _onChange = useCallback(
        (e: React.MouseEvent | React.KeyboardEvent) => {
            if (disabledSwitch) {
                return
            }
            const checkedToBe = state === 'opened' ? false : true
            onChange?.(checkedToBe, e)
        },
        [disabledSwitch, state, onChange]
    )

    const _onKeyDown = useCallback(
        (e: React.KeyboardEvent) => {
            if (e.code === 'Enter' || e.code === 'Space') {
                _onChange(e)
            }
        },
        [_onChange]
    )

    return (
        <span
            ref={ref}
            data-testid={dataTestIds?.switch}
            role="switch"
            aria-checked={checked}
            onClick={_onChange}
            className={classNames(classes.switch, classes[state], {
                [classes.disabledSwitch]: disabledSwitch,
                [classes.isStyleHover]: isStyleHover,
                [classes.isStyleFocus]: isStyleFocus,
            })}
        >
            <TabElement disabled={disabledSwitch} data-testid={dataTestIds?.tabElement} onKeyDown={_onKeyDown} />
            <span className={classes.hotArea}></span>
            <div className={classes.dot}>
                <div className={classes.spin}></div>
            </div>
        </span>
    )
}
export const Switch = forwardRef(_Switch)
