import { translation } from './stroke-paint.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { IconAdd16, IconStyle, SimpleDrag, Tooltip } from '../../../../../../../ui-lib/src'
import { ColorStop, ImagePaint, Paint as PaintType, RGB, Transform } from '../../../../../document/node/node'
import { IconButton } from '../../../atom/button/icon-button'
import { SingleGrid } from '../../../atom/grid/single-grid'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { Title } from '../../../atom/title/title'
import { useRenderColorSpace } from '../../../color-profile'
import { ColorInteraction } from '../../color-interaction/color-interaction'
import { ColorInteractionFrom, TypeOnChangeStyle } from '../../color-interaction/type'
import style from '../../fills/fills.module.less'
import { ColorPaint } from '../../paint/color-paint'
import { StylePaintColor } from '../../styles/style-color/style-paint-color'
import { useStrokePaint } from './use-stroke-paint'

// {translation('Stroke')}-颜色图层
export function StrokePaint() {
    const {
        selectedStrokes,
        isShowAddPaintsBtn,
        strokePaintState,
        onSelectChange,
        onDragDone,
        onClickAddPaint,
        onClickTitle,
        onChangeColor,
        onChangeVisible,
        onChangeOpacity,
        onChangeBlendMode,
        onChangeImagePaint,
        onChangeModalVisible,
        onClickSub,
        isOpen,
        onChangePaintType,
        onChangeColorStops,
        onChangeTransform,
        onClickDeleteStyle,
        onClickCutLink,
        onChangeStyle,
        openStyle,
        stylePosition,
        onClickStyle,
        switchOpenStyleState,
        onChangeColorVar,
        onDetachColorVar,
        onChangePaints2ColorVar,
        onChangePaints,
        onChangePaints2,
    } = useStrokePaint()
    const colorSpace = useRenderColorSpace()
    const isMixed = strokePaintState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
    const showStyle = strokePaintState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_STYLE
    const strokes = strokePaintState.strokes ?? []
    const variables = strokePaintState.variables ?? []

    return (
        <div>
            <Title
                grayTheme={!strokePaintState?.type}
                onClick={onClickTitle}
                className={!strokePaintState?.type && !openStyle ? style.emptyTitle : ''}
            >
                <Title.Left>{translation('Stroke')}</Title.Left>
                {isShowAddPaintsBtn && (
                    <Title.Right>
                        <Tooltip title={translation('StyleAndVariable')}>
                            <IconButton
                                icon={<IconStyle />}
                                selected={openStyle}
                                deepColor
                                onClick={onClickStyle}
                                className={style.styleIcon}
                                dataTestId="add-stroke-style"
                            />
                        </Tooltip>
                        <IconButton
                            icon={<IconAdd16 />}
                            selected={false}
                            onClick={onClickAddPaint}
                            dataTestId="stroke-add"
                        />
                    </Title.Right>
                )}
            </Title>
            {isMixed ? (
                <SingleGrid>
                    <SingleGrid.Item start={5} span={55} className={style.mixedTipText}>
                        {translation('Click')} + {translation('ToReplaceMixed')}
                    </SingleGrid.Item>
                </SingleGrid>
            ) : showStyle ? (
                <StylePaintColor
                    stroke
                    paints={(strokePaintState.paintStyleNode?.paints ?? []) as PaintType[]}
                    name={strokePaintState.paintStyleNode?.name ?? ''}
                    description={strokePaintState.paintStyleNode?.description}
                    isOpen={openStyle}
                    onClickSub={onClickDeleteStyle}
                    onClickStyle={onClickStyle}
                    onClickCutLink={onClickCutLink}
                />
            ) : (
                !!strokes.length && (
                    <ReversedStrokes
                        paints={strokes}
                        selectedIndexes={selectedStrokes?.indexes ?? []}
                        colorSpace={colorSpace}
                        variables={variables}
                        isOpen={isOpen}
                        onSelectChange={onSelectChange}
                        onDragDone={onDragDone}
                        onChangeModalVisible={onChangeModalVisible}
                        onChangeColor={onChangeColor}
                        onChangeColorStops={onChangeColorStops}
                        onChangeOpacity={onChangeOpacity}
                        onChangeVisible={onChangeVisible}
                        onChangeImagePaint={onChangeImagePaint}
                        onChangePaintType={onChangePaintType}
                        onChangeBlendMode={onChangeBlendMode}
                        onChangeTransform={onChangeTransform}
                        onClickDelete={onClickSub}
                        onChangeStyle={onChangeStyle}
                        onDetachColorVar={onDetachColorVar}
                        onChangeColorVar={onChangeColorVar}
                        onChangePaints={onChangePaints}
                        onClickCreateStyleButton={() => switchOpenStyleState(false)}
                    ></ReversedStrokes>
                )
            )}
            {openStyle
                ? stylePosition && (
                      <ColorInteraction
                          from={ColorInteractionFrom.STROKE}
                          position={stylePosition}
                          paints={
                              showStyle
                                  ? ((strokePaintState.paintStyleNode?.paints ?? []) as PaintType[])
                                  : (strokes as PaintType[])
                          }
                          styleId={strokePaintState?.strokeStyleId}
                          styleKey={strokePaintState?.paintStyleNode?.key ?? ''}
                          onCancel={() => switchOpenStyleState(false)}
                          onChangePaintType={onChangePaintType(0)}
                          onChangeBlendMode={onChangeBlendMode(0)}
                          onChangeColor={onChangeColor(0)}
                          onChangeOpacity={onChangeOpacity(0)}
                          onChangeImagePaint={onChangeImagePaint(0)}
                          onChangeColorStops={onChangeColorStops(0)}
                          onChangeTransform={onChangeTransform(0)}
                          enterClose={() => onChangeModalVisible(0)(false)}
                          onChangeStyle={onChangeStyle}
                          onChangeColorVar={onChangePaints2ColorVar}
                          onChangePaints={onChangePaints2}
                          onClickCreateStyleButton={undefined}
                      />
                  )
                : null}
        </div>
    )
}

function ReversedStrokes(props: {
    readonly paints: ReturnType<typeof useStrokePaint>['strokePaintState']['strokes']
    readonly selectedIndexes: number[]
    readonly colorSpace: ReturnType<typeof useRenderColorSpace>
    readonly variables: ReturnType<typeof useStrokePaint>['strokePaintState']['variables']
    readonly isOpen: ReturnType<typeof useStrokePaint>['isOpen']
    readonly onSelectChange: ReturnType<typeof useStrokePaint>['onSelectChange']
    readonly onDragDone: ReturnType<typeof useStrokePaint>['onDragDone']
    readonly onChangeModalVisible: ReturnType<typeof useStrokePaint>['onChangeModalVisible']
    readonly onChangeColor: ReturnType<typeof useStrokePaint>['onChangeColor']
    readonly onChangeColorStops: ReturnType<typeof useStrokePaint>['onChangeColorStops']
    readonly onChangeOpacity: ReturnType<typeof useStrokePaint>['onChangeOpacity']
    readonly onChangeVisible: ReturnType<typeof useStrokePaint>['onChangeVisible']
    readonly onChangeImagePaint: ReturnType<typeof useStrokePaint>['onChangeImagePaint']
    readonly onChangePaintType: ReturnType<typeof useStrokePaint>['onChangePaintType']
    readonly onChangeBlendMode: ReturnType<typeof useStrokePaint>['onChangeBlendMode']
    readonly onDetachColorVar: ReturnType<typeof useStrokePaint>['onDetachColorVar']
    readonly onChangeTransform: ReturnType<typeof useStrokePaint>['onChangeTransform']
    readonly onClickDelete: ReturnType<typeof useStrokePaint>['onClickSub']
    readonly onChangeStyle: TypeOnChangeStyle
    readonly onChangeColorVar: ReturnType<typeof useStrokePaint>['onChangeColorVar']
    readonly onChangePaints: ReturnType<typeof useStrokePaint>['onChangePaints']
    readonly onClickCreateStyleButton: () => void
}) {
    const paints = (props.paints ?? []).slice().reverse()
    const selectedIndexes = props.selectedIndexes.map((index) => paints.length - 1 - index)
    const onSelectChange = (indexes: number[]) => {
        props.onSelectChange(indexes.map((index) => paints.length - 1 - index))
    }
    const onDragDone = (items: PaintType[], indexes: number[]) => {
        const newItems = items.slice().reverse()
        props.onDragDone(
            newItems,
            indexes.map((index) => newItems.length - 1 - index)
        )
    }
    const isOpen = (index: number) => props.isOpen(paints.length - 1 - index)
    const onChangeModalVisible = (index: number) => (visible: boolean) => {
        props.onChangeModalVisible(paints.length - 1 - index)(visible)
    }
    const onChangeColor = (index: number) => (color: RGB, options?: InputOptionsForUndoSquash) => {
        props.onChangeColor(paints.length - 1 - index)(color, options)
    }
    const onChangeColorStops = (index: number) => (v: ColorStop[]) => {
        props.onChangeColorStops(paints.length - 1 - index)(v)
    }
    const onChangeOpacity = (index: number) => (opacity: number, options?: InputOptionsForUndoSquash) => {
        props.onChangeOpacity(paints.length - 1 - index)(opacity, options)
    }
    const onChangeVisible = (index: number) => (visible: boolean) => {
        props.onChangeVisible(paints.length - 1 - index)(visible)
    }
    const onChangeImagePaint = (index: number) => (data: ImagePaint) => {
        props.onChangeImagePaint(paints.length - 1 - index)(data)
    }
    const onChangePaintType =
        (index: number) =>
        (paintType: Wukong.DocumentProto.PaintType, paint: Wukong.DocumentProto.IPaint | undefined | null) => {
            props.onChangePaintType(paints.length - 1 - index)(paintType, paint)
        }
    const onChangeBlendMode = (index: number) => (value: Wukong.DocumentProto.BlendMode) => {
        props.onChangeBlendMode(paints.length - 1 - index)(value)
    }
    const onDetachColorVar = (index: number) => () => {
        props.onDetachColorVar(paints.length - 1 - index)()
    }
    const onChangeTransform = (index: number) => (transform: Transform) => {
        props.onChangeTransform(paints.length - 1 - index)(transform)
    }
    const onClickDelete = (index: number) => () => {
        props.onClickDelete(paints.length - 1 - index)()
    }
    const onChangeColorVar = (index: number) => (colorVar: Wukong.DocumentProto.IVariableAliasData) => {
        props.onChangeColorVar(paints.length - 1 - index)(colorVar)
    }
    return (
        <SimpleDrag<PaintType>
            selectedIndexList={selectedIndexes}
            items={paints}
            onSelectChange={onSelectChange}
            onDragDone={onDragDone}
            onMouseDown={(e) => e.stopPropagation()}
            dataTestIds={{
                simpleDrag: 'stroke-list',
            }}
        >
            {paints.map((paint, index) => (
                <SimpleDrag.Item
                    key={index}
                    itemIndex={index}
                    dataTestIds={{ item: `stroke-${index}`, dragIcon: `stroke-${index}-icon` }}
                >
                    <ColorPaint
                        useIn="stroke"
                        key={index}
                        selected={selectedIndexes.includes(index)}
                        paint={paint}
                        paints={props.paints as Wukong.DocumentProto.IPaint[]}
                        colorSpace={props.colorSpace}
                        variables={props.variables}
                        isOpen={isOpen(index)}
                        onChangeModalVisible={onChangeModalVisible(index)}
                        onChangeColor={onChangeColor(index)}
                        onChangeColorStops={onChangeColorStops(index)}
                        onChangeOpacity={onChangeOpacity(index)}
                        onChangeVisible={onChangeVisible(index)}
                        onChangeImagePaint={onChangeImagePaint(index)}
                        onChangePaintType={onChangePaintType(index)}
                        onChangeBlendMode={onChangeBlendMode(index)}
                        onChangeTransform={onChangeTransform(index)}
                        onClickSub={onClickDelete(index)}
                        onChangeStyle={props.onChangeStyle}
                        onDetachColorVar={onDetachColorVar(index)}
                        onChangeColorVar={onChangeColorVar(index)}
                        onChangePaints={props.onChangePaints}
                        onClickCreateStyleButton={props.onClickCreateStyleButton}
                    />
                </SimpleDrag.Item>
            ))}
        </SimpleDrag>
    )
}
