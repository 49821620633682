import { translation } from './copyable-row.translation'
/* eslint-disable no-restricted-imports */
import classNames from 'classnames'
import type { HTMLAttributes } from 'react'
import { useCallback } from 'react'
import { WKToast } from '../../../../../../../ui-lib/src'
import { useHandler } from '../../../../context/document-context'
import { copyText } from '../../../../handler/copy-handler'
import style from './copyable-row.module.less'

export interface CopyableRowProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onClick'> {
    copyValue: string
    noPaddingLeft?: boolean // 复制区域左边的 padding 是否占位
    feedback?: 'shallow' | 'deep' // 悬浮点击状态时的反馈程度 shallow: gray-13-8, deep: gray-13-15
    paddingMode?: 'normal' // normal: 1px 4px;
}

export function CopyableRow({
    copyValue,
    noPaddingLeft,
    className,
    children,
    feedback = 'shallow',
    paddingMode,
    ...otherProps
}: CopyableRowProps) {
    const handler = useHandler()
    const onCopy = useCallback(() => {
        handler.handle(copyText, copyValue)
        WKToast.show(translation('CopiedToClipboard'))
    }, [handler, copyValue])

    return (
        <span
            className={classNames(
                style.copyableRow,
                {
                    [style.noPaddingLeft]: noPaddingLeft,
                    [style.feedbackShallow]: feedback === 'shallow',
                    [style.feedbackDeep]: feedback === 'deep',
                    [style.paddingNormal]: paddingMode === 'normal',
                },
                className
            )}
            onClick={onCopy}
            {...otherProps}
        >
            {children}
        </span>
    )
}
