import { translation } from './view-menu.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import {
    DropdownDefaultCustomNode,
    DropdownDefaultRenderItem,
    DropdownV2,
    RenderStructNodeItemProps,
    WKDivider,
    WKMenuConfig,
} from '../../../../../../ui-lib/src'
import { useViewMenuService } from '../../../../main/app-context'
import { BlurType } from '../../../../main/service/active-element-blur-service'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { frogExposeToolsMenusAndActionsMenuDisplay } from '../../../utils/tools-actions-frog'
import style from './main-menu.module.less'
import { MainMenuItemCustomNode } from './menu-v2/type'
import { useMenu } from './menu-v2/use-menu'
import { transformMenuConfig } from './menu-v2/utils'
import { useDesignModeViewMenuConfig, useDevModeViewMenuConfig } from './view-menu-config'
export function DevModeViewMenu() {
    const commandInvoker = useCommand()
    const zoomMenuConfigItems = useDevModeViewMenuConfig(commandInvoker)

    return <ViewMenu menus={zoomMenuConfigItems} />
}

export function DesignModeViewMenu() {
    const commandInvoker = useCommand()
    const zoomMenuConfigItems = useDesignModeViewMenuConfig(commandInvoker)

    return <ViewMenu menus={zoomMenuConfigItems} />
}

function ViewMenu(props: { menus: WKMenuConfig }) {
    const viewMenuService = useViewMenuService()
    const { onChange } = useMenu()

    const zoom = viewMenuService.useZustandStore.use.currentZoom()
    const docReadonly = useViewState('docReadonly')
    const isHistoryMode = useViewState('historyMode')
    const state = useViewState('topArea')
    const isEditVector = state?.isEditVector
    const editorType = useViewState('editorType')
    return (
        <DropdownV2.MenuMultiLevel<MainMenuItemCustomNode & { isInput?: boolean }>
            onOpen={() => {
                viewMenuService.onOpen()
                frogExposeToolsMenusAndActionsMenuDisplay({
                    doc_mode: {
                        is_readonly: !!docReadonly,
                        is_dev_mode: editorType === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV,
                        is_history_version: !!isHistoryMode,
                        is_vector: !!isEditVector,
                    },
                    displayed_menu: '缩放/视图选项',
                })
            }}
            onClose={viewMenuService.onClose}
            onChange={onChange}
            tooltipProps={{ title: `${translation('Zoom')}/${translation('ViewOptions')}` }}
            dataTestIds={{ triggerFocus: 'view-menu-trigger-button', captureKeyboard: 'view-menu-keyboard' }}
            label={
                <span className={style.slot} data-testid="view-menu-zoom-value">
                    {zoom}
                </span>
            }
            style={{ width: 70, paddingRight: 4, borderRadius: 'var(--wk-radius-none)' }}
            items={[{ name: '', disabled: true, customNode: { isInput: true } }, ...transformMenuConfig(props.menus)]}
            renderItem={ViewMenuRenderItem}
        />
    )
}

function ViewMenuRenderItem(props: RenderStructNodeItemProps<DropdownDefaultCustomNode & { isInput?: boolean }>) {
    if (props.data.customNode?.isInput) {
        return (
            <>
                <ViewMenuInput />
                <WKDivider className="mt-8px mb-7px" />
            </>
        )
    }
    return <DropdownDefaultRenderItem {...props} />
}

function ViewMenuInput() {
    const viewMenuService = useViewMenuService()
    const zoomInput = viewMenuService.useZustandStore.use.inputValue()

    return (
        <input
            data-blur-after-capture-mouse-down={BlurType.Immediately}
            data-testid="view-menu-zoom-input"
            className={style.zoom}
            ref={viewMenuService.onMounted}
            value={zoomInput}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    viewMenuService.onEnterKeyDown()
                } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                    e.currentTarget.blur()
                }
            }}
            onBlur={viewMenuService.trySubmitZoom}
            onChange={(e) => viewMenuService.onInputChange(e.target.value)}
        ></input>
    )
}
