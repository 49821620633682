import { getTranslationValue } from '../../../../../../../../../util/src/i18n'

export const zhTranslation = {
    PropertyName: '属性名',
    PropertyValue: '属性值',
    CreateProperty: '创建属性',
    Default: '默认',
    SelectVariants: '选中 {{amount}} 个变体',
} as const

export const enTranslation = {
    PropertyName: 'Name',
    PropertyValue: 'Value',
    CreateProperty: 'Create property',
    Default: 'Default',
    SelectVariants: 'Select {{amount}} variant',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
