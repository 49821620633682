import { InputV2, trimInputValue } from '../../../../../../../ui-lib/src'
import styles from './hyperlink-creator.module.less'
import { translation } from './hyperlink-creator.translation'
import { HyperlinkModel } from './use-hyperlink-model'
import { usePopupPosition } from './use-popup-position'

interface IProps {
    model: HyperlinkModel
}

export function HyperlinkCreator({ model }: IProps) {
    const { modelState, modelCommand } = model
    const { transformX, transformY } = usePopupPosition(modelState)

    const onSubmit = (value: string) => {
        if (value) {
            modelCommand.endHyperlinkEditing(value)
        } else {
            modelCommand.detachHyperlink()
        }
    }

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key == 'Escape') {
            modelCommand.cancelHyperlinkEditing()
        } else if (event.key == 'Enter') {
            ;(event.target as HTMLInputElement).blur()
        }
    }

    if (!modelState) {
        return null
    }

    return (
        <div className={styles.root} style={{ transform: `translate(${transformX}px, ${transformY}px)` }}>
            <InputV2.Html
                style={{ padding: '0 8px', boxSizing: 'border-box' }}
                className={styles.createInput}
                placeholder={translation('TypeOr')}
                autoFocus
                onKeyDown={handleKeyDown}
                onBlur={(e) => onSubmit(e.target.value)}
                onChange={(e) => {
                    trimInputValue(e.target)
                }}
                data-testid="hyperlink-create-input"
            />
        </div>
    )
}
