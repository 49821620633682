import { command } from 'ccstate'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { traceable } from '../../../../util/src/ccstate-helper/traceable'
import { isLoginExpired, reLogin } from '../../kernel/util/expire-login'

export const asyncReLoginIfNearExpiry$ = traceable(
    'zangbingjie@kanyun.com',
    command(async (_, signal: TraceableAbortSignal) => {
        return isLoginExpired(true).then((nearExpiry) => {
            signal.throwIfAborted()
            if (nearExpiry) {
                reLogin()
            }
        })
    })
)
