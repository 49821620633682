/**
 * @owner: loushuangzhanbj@kanyun.com
 */
import classNames from 'classnames'
import { HtmlHTMLAttributes, PropsWithChildren, ReactNode, UIEvent, useMemo, useState } from 'react'
import { ScrollView, ScrollViewProps } from '../scrollbars'

import classes from './scroll-container.module.less'

export function ScrollContainer({
    className,
    stickyHeader,
    stickyFooter,
    children,
    scrollViewProps,
    ...props
}: PropsWithChildren<
    {
        stickyHeader?: ReactNode
        stickyFooter?: ReactNode
        scrollViewProps?: ScrollViewProps
    } & HtmlHTMLAttributes<HTMLDivElement>
>) {
    const [isScroll, setIsScroll] = useState<boolean>(false)

    const formatScrollViewProps = useMemo(
        () => ({
            ...(scrollViewProps ?? {}),
            className: classNames(classes.scrollWrapper, 'flex-auto', scrollViewProps?.className),
            scrollbar: {
                ...(scrollViewProps?.scrollbar ?? {}),
                onScroll: (e: UIEvent<HTMLDivElement>) => {
                    if (e.currentTarget) {
                        setIsScroll(e.currentTarget.scrollTop !== 0)
                    }
                },
            },
        }),
        [scrollViewProps]
    )

    return (
        <div {...props} className={classNames(classes.container, className, 'flex overflow-hidden flex-col')}>
            {!!stickyHeader && (
                <div className={classNames('flex-none', { [classes.sticktHeader]: isScroll })}>{stickyHeader}</div>
            )}
            <ScrollView {...formatScrollViewProps}>{children}</ScrollView>
            {!!stickyFooter && <div className="flex-none">{stickyFooter}</div>}
        </div>
    )
}
