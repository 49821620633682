import { translation } from './document.translation'
import { timeDesc } from './time'

export function getDocDesc(
    props: {
        folderName?: string
        lastEditedTime?: number
        deletedTime?: number
        deleteUserName?: string
        hiddeFolderName?: boolean
        folderClassName?: string
        timeClassName?: string
    } = {}
) {
    const { folderName, lastEditedTime, deletedTime, deleteUserName, hiddeFolderName, folderClassName, timeClassName } =
        props
    if (deletedTime) {
        return (
            <span className={timeClassName}>
                {`${translation('DeletedOn')} ${timeDesc(deletedTime)}`} · by {deleteUserName}
            </span>
        )
    } else if (lastEditedTime) {
        return folderName ? (
            <>
                {!hiddeFolderName && (
                    <>
                        <span className={folderClassName}>{folderName}</span>
                        <span>&nbsp;·&nbsp;</span>
                    </>
                )}
                <span className={timeClassName}>{`${translation('EditedOn')} ${timeDesc(lastEditedTime)}`}</span>
            </>
        ) : (
            <span className={timeClassName}>{`${translation('EditedOn')} ${timeDesc(lastEditedTime)}`}</span>
        )
    } else if (folderName) {
        return <span className={folderClassName}>{folderName}</span>
    } else {
        return ''
    }
}
export const getMoveDocsTitle = (docNames: string[], targetFolderName: string) => {
    if (docNames.length > 1) {
        return translation('muilt', { fileCount: docNames.length + '', folderName: targetFolderName })
    } else {
        return translation('single', { fileName: docNames.join(''), folderName: targetFolderName })
    }
}
