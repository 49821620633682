import { WKToast } from '../../../../../../ui-lib/src'
import { createSelectors, createStore } from '../../../../../../util/src'
import { TraceableAbortSignal } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import type { NetworkService } from '../../../../document/synergy/network-service'
import { environment } from '../../../../environment'
import { Bridge } from '../../../../kernel/bridge/bridge'
import { LibraryId } from '../../../../kernel/interface/component-style-library-id'
import { DocID } from '../../../../kernel/interface/type'
import type { LibraryContentVO, LibraryIdAndNameVO } from '../../../../kernel/interface/library'
import { NotifyService } from '../../../../kernel/notify/notify-service'
import { SubscribeLibrary, UnSubscribeLibrary } from '../../../../kernel/request/library-subscription'
import { ServiceClass } from '../../../../kernel/util/service-class'
import { ColorProfileService } from '../../../../main/service/color-profile-service'
import { UserConfigService } from '../../../../main/user-config/user-config-service'
import { LibraryResourceDownloader } from '../../../../share/component-style-library/service/library-resource-downloader'
import { ViewStateBridge } from '../../../../view-state-bridge'
import { HistoryService } from '../../history-file/history-service/history-service'
import { LibraryAssetPanelExpandService } from './library-asset-panel-expand-service'
import { translation } from './library-component-service.translation'
import { LibraryDragEventService } from './library-drag-event-service'
import { LibraryModalRouterService } from './library-modal-router-service'
import { LibraryMovementService } from './library-movement-service'
import { LibraryNodeDataService } from './library-node-data-service'
import { LibraryNotificationService } from './library-notification-service'
import { LibraryNotifySyncService } from './library-notify-sync-service'
import { LibraryRecoverService } from './library-recover-service'
import { LibraryReplaceService } from './library-replace-service'
import { LibraryNodeThumbnailService } from './library-thumbnail-service/library-thumbnail-service'
import { LibraryWasmSyncService } from './library-wasm-sync-service'
import { PublishComponentsExecutor } from './publish-components-executor'
import { UpgradeComponentsExecutor } from './upgrade-components-executor'

export class LibraryComponentService extends ServiceClass {
    public states = createSelectors(
        createStore<{
            syncInUsedDocumentId2LibraryInfoMapState: Map<DocID, LibraryIdAndNameVO> | undefined
            subscribeLibraryIds: LibraryId[] | undefined
            remoteLibraryContentMapState: Map<LibraryId, LibraryContentVO> | undefined
            currentDocRemoteLibraryContentState: LibraryContentVO | null | undefined
            syncInUsedDocumentIdOfHybridRemoteLibraryState: Set<DocID> | undefined
        }>(
            () => ({
                syncInUsedDocumentId2LibraryInfoMapState: undefined,
                subscribeLibraryIds: undefined,
                remoteLibraryContentMapState: undefined,
                currentDocRemoteLibraryContentState: undefined,
                syncInUsedDocumentIdOfHybridRemoteLibraryState: undefined,
            }),
            environment.isDev
        )
    )

    // 组件库 oss 资源下载器
    private readonly libraryResourceDownloader!: LibraryResourceDownloader
    // thumbnail
    public readonly libraryThumbnailService!: LibraryNodeThumbnailService
    // nodeData
    public readonly libraryNodeDataService!: LibraryNodeDataService
    // 组件库替换相关
    public readonly libraryReplaceService!: LibraryReplaceService
    // 组件移动相关
    public readonly libraryMovementService!: LibraryMovementService
    // 右下角提示管理
    public readonly libraryNotificationService!: LibraryNotificationService
    // 组件库更新相关
    public readonly upgradeComponentsExecutor!: UpgradeComponentsExecutor
    // 组件库发布相关
    public readonly publishComponentsExecutor!: PublishComponentsExecutor
    // 组件库长连接
    private readonly libraryNotifySyncService!: LibraryNotifySyncService
    // 组件库资产面板展开/收起状态管理
    public readonly libraryAssetPanelExpandService!: LibraryAssetPanelExpandService
    // 组件缩略图拖拽事件管理
    public readonly libraryDragEventService!: LibraryDragEventService
    // 组件弹窗路由管理
    public readonly libraryModalRouterService!: LibraryModalRouterService
    // 处理 wasm 和 远端数据的同步
    private readonly libraryWasmSyncService: LibraryWasmSyncService
    // 恢复组件相关
    private readonly libraryRecoverService: LibraryRecoverService

    constructor(
        public readonly docId: DocID,
        private readonly docReadonly: boolean,
        protected override readonly viewStateBridge: ViewStateBridge,
        private readonly commandInvoker: CommandInvoker,
        protected override readonly bridge: Bridge,
        private readonly historyService: HistoryService,
        private readonly userConfigService: UserConfigService,
        private readonly colorProfileService: ColorProfileService,
        private readonly networkService: NetworkService,
        private readonly notifyService: NotifyService,
        private readonly signal: TraceableAbortSignal,
        private readonly canvas: HTMLCanvasElement
    ) {
        super(bridge, viewStateBridge)
        this.libraryResourceDownloader = new LibraryResourceDownloader(this.networkService, this.signal)
        this.libraryThumbnailService = new LibraryNodeThumbnailService(
            commandInvoker,
            bridge,
            this.colorProfileService,
            this.libraryResourceDownloader
        )
        this.libraryNodeDataService = new LibraryNodeDataService(commandInvoker, this.libraryResourceDownloader)
        this.libraryNotifySyncService = new LibraryNotifySyncService(
            docId,
            this.libraryResourceDownloader,
            notifyService,
            this.signal
        )
        this.libraryWasmSyncService = new LibraryWasmSyncService(
            docId,
            this.docReadonly,
            commandInvoker,
            this.libraryNotifySyncService,
            this.bridge,
            this.viewStateBridge,
            this.signal
        )
        this.libraryReplaceService = new LibraryReplaceService(commandInvoker, bridge, this.libraryNodeDataService)
        this.libraryMovementService = new LibraryMovementService(docId, commandInvoker, bridge, this.signal)
        this.libraryModalRouterService = new LibraryModalRouterService(
            this.bridge,
            this.commandInvoker,
            this.viewStateBridge
        )
        this.libraryNotificationService = new LibraryNotificationService(
            viewStateBridge,
            commandInvoker,
            bridge,
            this.libraryModalRouterService,
            this.signal
        )
        this.libraryAssetPanelExpandService = new LibraryAssetPanelExpandService(
            this.userConfigService,
            this.commandInvoker
        )
        this.upgradeComponentsExecutor = new UpgradeComponentsExecutor(
            this.docId,
            this.commandInvoker,
            this.libraryNodeDataService,
            this.libraryNotifySyncService,
            this.libraryModalRouterService.closeLibraryMainModal
        )
        this.publishComponentsExecutor = new PublishComponentsExecutor(
            this.docId,
            this.commandInvoker,
            this.bridge,
            this.viewStateBridge,
            this.libraryNotifySyncService,
            this.historyService,
            this.signal
        )
        this.libraryDragEventService = new LibraryDragEventService(
            this.commandInvoker,
            this.libraryNodeDataService,
            this.canvas
        )
        this.libraryRecoverService = new LibraryRecoverService(
            this.bridge,
            this.commandInvoker,
            this.libraryNotifySyncService,
            this.libraryNodeDataService,
            this.signal
        )
    }

    public init = () => {
        if (this.isDestroy) {
            return
        }
        this.libraryWasmSyncService.init()
        this.libraryModalRouterService.init()
        this.libraryAssetPanelExpandService.init()
        this.libraryNotificationService.init()
        this.initState()
    }

    private initState = () => {
        this.libraryNotifySyncService.onAllRemoteLibraryContentChanged((value) => {
            this.states.setState({ remoteLibraryContentMapState: value })
        }, this.signal)
        this.libraryNotifySyncService.onSubscribedLibraryInfoListChanged((value) => {
            this.states.setState({ subscribeLibraryIds: value.map(({ libraryId }) => libraryId) })
        }, this.signal)
        this.libraryNotifySyncService.onCurrentRemoteLibraryContentChanged((value) => {
            this.states.setState({ currentDocRemoteLibraryContentState: value })
        }, this.signal)
        this.libraryWasmSyncService.onSyncInUsedDocumentId2LibraryInfoMapChanged(this.signal, (value) => {
            this.states.setState({ syncInUsedDocumentId2LibraryInfoMapState: value })
        })
        this.libraryWasmSyncService.onSyncInUsedDocumentIdOfHybridRemoteLibraryChanged(this.signal, (value) => {
            this.states.setState({ syncInUsedDocumentIdOfHybridRemoteLibraryState: value })
        })
    }

    public override destroy() {
        super.destroy()

        this.upgradeComponentsExecutor.destroy()
        this.publishComponentsExecutor.destroy()
        this.libraryModalRouterService.destroy()
        this.libraryReplaceService.destroy()
        this.libraryNodeDataService.destroy()
        this.libraryThumbnailService.destroy()
        this.libraryMovementService.destroy()
        this.libraryNotificationService.destroy()
        this.libraryNotifySyncService.destroy()
        this.libraryAssetPanelExpandService.destroy()
        this.libraryDragEventService.destroy()
        this.libraryResourceDownloader.destroy()
        this.libraryWasmSyncService.destroy()
        this.libraryRecoverService.destroy()
    }

    public subscribeLibrary = async (libraryId: LibraryId) => {
        try {
            await new SubscribeLibrary(this.docId, libraryId).start()
        } catch {
            WKToast.error(translation('EnableFailed'))
        }
    }

    public unSubscribeLibrary = async (libraryId: LibraryId) => {
        try {
            await new UnSubscribeLibrary(this.docId, libraryId).start()
        } catch {
            WKToast.error(translation('DisableFailed'))
        }
    }
}
