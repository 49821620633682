import { CurrentPageSetSelectionCommandWasmCall, Wukong } from '@wukong/bridge-proto'
import { useCallback } from 'react'
import {
    IconComponent16,
    IconInstance,
    MonoIconPanelParentLevel16,
    WKIconButton,
} from '../../../../../../../ui-lib/src'
import { useCommand } from '../../../../../main/app-context'
import { useViewState } from '../../../../../view-state-bridge'
import { IconVariants } from '../../../layer-panel/render-item-icon/svg'
import style from '../inspect.module.less'
import classes from './index.module.less'
import { translation } from './index.translation'

export function MasterIcon({ isComponent, isVarient }: { isComponent: boolean; isVarient: boolean }) {
    if (isVarient) {
        return <IconVariants />
    }
    if (isComponent) {
        return <IconComponent16 />
    }
    return <IconInstance />
}

export function DevModeInspectComponentInstanceSubordinate() {
    const viewState = useViewState('devModeInspectComponentInstanceSubordinateViewState')
    const normalCommands = useCommand()
    const jumpTo = useCallback(() => {
        if (!viewState) {
            return
        }
        normalCommands.DEPRECATED_invokeBridge(
            CurrentPageSetSelectionCommandWasmCall,
            Wukong.DocumentProto.CurrentPageSetSelectionCommandParam.create({
                selection: [viewState.targetNodeId],
            })
        )
    }, [normalCommands, viewState])

    if (!viewState?.show) {
        return null
    }
    return (
        <div
            className={style.panel}
            style={{ padding: '8px 0 12px 0' }}
            data-testid="dev-mode-inspect-component-instance-subordinate"
        >
            <div className={style.panelTitle} style={{ marginBottom: '0px' }}>
                <div className={style.title}>
                    {viewState.isComponent ? translation('ParentComponent') : translation('ParentInstance')}
                </div>
            </div>

            <div className={classes.nameIconContainerWrapper}>
                <div className={classes.icon}>
                    <MasterIcon isComponent={viewState.isComponent} isVarient={viewState.isVarient} />
                </div>
                <div className={classes.name}>{viewState.masterName}</div>
                <WKIconButton className={classes.jump} onClick={jumpTo} icon={<MonoIconPanelParentLevel16 />} />
            </div>
        </div>
    )
}
