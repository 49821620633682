/* eslint-disable no-restricted-imports */
import type * as React from 'react'
import { useCallback, useLayoutEffect, useState } from 'react'
import { Position, useElementRef } from '../../../../ui-lib/src'

interface PositionProps {
    isOpen?: boolean
    outRef?: React.Ref<HTMLDivElement>
}

export function usePosition(props: PositionProps) {
    const { isOpen, outRef } = props
    const [position, setPosition] = useState<Position>()
    const fnRef = useCallback((e: any) => {
        if (e) {
            const { left, top } = e.getBoundingClientRect()
            setPosition({ left, top })
        }
    }, [])
    const { htmlRef, reactRef } = useElementRef(outRef, fnRef)

    useLayoutEffect(() => {
        if (isOpen && htmlRef.current) {
            fnRef(htmlRef.current)
        }
    }, [htmlRef, isOpen, fnRef])

    return { position, reactRef, htmlRef, setPosition }
}
