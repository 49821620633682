import { forwardRef, useImperativeHandle, useRef } from 'react'
import { ExplicitUndefined } from '../../../utils/explicit-undefined'
import { useCommentSelect } from '../../selects/select/use-comment-select'
import { SelectOption } from '../picks/single-level/select-option'
import { SingleLevel, SingleLevelProps, SingleLevelRef } from '../picks/single-level/single-level'
import { TriggerEmpty, TriggerEmptyRef } from '../triggers/trigger-empty'
import { DropdownSingleLevelProps, DropdownTriggerEmptyProps } from './type'

export interface DropdownEmptySingleLevelRef {
    close: () => void
    open: () => void
    getTriggerElement: () => HTMLElement | null | undefined
}

export type DropdownEmptySingleLevelProps = DropdownTriggerEmptyProps & DropdownSingleLevelProps

function _DropdownEmptySingleLevel(props: DropdownEmptySingleLevelProps, ref?: React.Ref<DropdownEmptySingleLevelRef>) {
    const pickRef = useRef<SingleLevelRef>(null)
    const triggerRef = useRef<TriggerEmptyRef>(null)
    const {
        pickRest,
        placement,
        triggerRect,
        worldRect,
        isMinWidthFromTrigger = true,
        maxWidth,
        removeMask,
        removeTopPadding,
        removeBottomPadding,
        onKeyboard,
        onChange,
        onClose,
        onOpen,
        onChangePreselect,
        ...otherProps
    } = props

    const commentSelectState = useCommentSelect({
        triggerRect,
        triggerRef,
        pickRef,
        onOpen,
        onClose,
    })
    useImperativeHandle(
        ref,
        () => ({
            close: () => pickRef.current?.close(),
            open: () => pickRef.current?.open(),
            getTriggerElement: () => triggerRef.current?.getTriggerElement(),
        }),
        []
    )

    return (
        <TriggerEmpty
            ref={triggerRef}
            {...otherProps}
            isOpenPick={commentSelectState.isOpenPick}
            openAction={commentSelectState.openAction}
            closeAction={commentSelectState.closeAction}
        >
            <SingleLevel<ExplicitUndefined<SingleLevelProps>>
                pickRest={pickRest}
                optionTemplate={SelectOption}
                dataTestIds={props.dataTestIds}
                removeMask={removeMask}
                triggerRect={commentSelectState.triggerRect}
                closeByESC={commentSelectState.closeByESC}
                onClose={commentSelectState.onSelectClose}
                onOpen={commentSelectState.onSelectOpen}
                ref={pickRef}
                placement={placement}
                worldRect={worldRect}
                isMinWidthFromTrigger={isMinWidthFromTrigger}
                maxWidth={maxWidth}
                onKeyboard={onKeyboard}
                onChange={onChange}
                onChangePreselect={onChangePreselect}
                disableDefaultPreselect={true}
                getPortalElement={commentSelectState.getPortalElement}
                isAllowEmptyOption
                removeTopPadding={removeTopPadding}
                removeBottomPadding={removeBottomPadding}
                isOpenState={undefined}
                openStateToBeFalse={undefined}
                openStateToBeTrue={undefined}
                isMixed={undefined}
                minWidth={undefined}
                width={undefined}
                value={undefined}
                mainValue={undefined}
                autoAdjustTop={undefined}
            >
                {props.children}
            </SingleLevel>
        </TriggerEmpty>
    )
}

const forwardRefComponent = forwardRef(_DropdownEmptySingleLevel)

/**
 * @description 这个 dropdown 不属于设计的定义。提供的目的因为设计还没有定义及更新组件。但实际场景已经用到了
 * */
export const DropdownEmptySingleLevel: typeof forwardRefComponent & { Option: typeof SelectOption } = Object.assign(
    forwardRefComponent,
    {
        Option: SelectOption,
    }
)
