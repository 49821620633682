import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    LocalStyles: '本地样式',
    CreateStyle: '创建样式',
    TextStyles: '文本样式',
    ColorStyles: '颜色样式',
    EffectStyles: '效果样式',
    GridStyles: '布局网格样式',
} as const

export const enTranslation = {
    LocalStyles: 'Local styles',
    CreateStyle: 'Create style',
    TextStyles: 'Text styles',
    ColorStyles: 'Color styles',
    EffectStyles: 'Effect styles',
    GridStyles: 'Grid styles',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
