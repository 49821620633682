import { translation } from './font-team-uploader.translation'
import { useCallback, useMemo } from 'react'
import { useUpdateEffect } from 'react-use'
import { MonoIconCommonFont24, WKButton, WKTextButton } from '../../../../../../../../ui-lib/src'
import { environment } from '../../../../../../environment'
import { RoleStatus } from '../../../../../../kernel/interface/type'
import { useBaseCommonRequest } from '../../../../../../kernel/request/use-request'
import { WKFrogTask } from '../../../../../../share/wk-frog-task'
import styles from './font-team-uploader.module.less'
import { GetTeamRequestV2 } from '../../../../../../kernel/request/team'

export function FontTeamUploader({ teamId }: { teamId: string }) {
    const request = useMemo(() => new GetTeamRequestV2(teamId), [teamId])
    const { vo } = useBaseCommonRequest(request, null)

    const onClick = useCallback(() => {
        WKFrogTask.fontSelector.uploadTeamFonts()
        window.open(`${environment.host}/team/${teamId}/fonts`)
    }, [teamId])

    const isEditor = vo?.status === 'NORMAL' && (vo?.role === RoleStatus.Admin || vo?.role === RoleStatus.Owner)

    useUpdateEffect(() => {
        if (isEditor) {
            WKFrogTask.fontSelector.exposeUploadTeamFonts()
        }
    }, [isEditor])

    if (!vo) {
        return null
    }

    if (isEditor) {
        return (
            <div>
                <div className={styles.tip}>
                    {translation('AfterUploadingTeamNew')}
                    <br />
                    {translation('TeamMembersCanNew')}
                </div>
                <div className={styles.uploader}>
                    <WKButton type="secondary" onClick={onClick}>
                        {translation('UploadFontsNew')}
                    </WKButton>
                </div>
            </div>
        )
    }
    return <LearnMoreSharedFont desc={translation('AfterUploadingTeam_synonyms2')} />
}

export function LearnMoreSharedFont({ desc }: { desc: string }) {
    const navigateToHelp = useCallback(() => {
        environment.isAbroad
            ? window.open(`${environment.host}/help/docs/sections/251119495609089`)
            : window.open(`${environment.host}/help/docs/sections/249684234015744`)
    }, [])
    return (
        <div className={styles.readonly} data-testid={'learn-more-shared-font'}>
            <div>
                <MonoIconCommonFont24 className="color-$wk-v2-label-color-gray-13" />
            </div>
            <div className={styles.right}>
                <div className="mb-1">{desc}</div>
                <WKTextButton type="primary" size={12} onClick={navigateToHelp}>
                    {translation('LearnMore')}
                </WKTextButton>
            </div>
        </div>
    )
}
