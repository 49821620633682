import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Scrollbar } from '../../../../../../../ui-lib/src'
import { useCommentService } from '../../../../../main/app-context'
import { useInsertEmojiRect } from '../../comment-service/comment-service-hook'
import { calculateEditorPopupPosition } from '../../utils'
import { EmojiSelect } from './emoji-list'
import classes from './emoji-list.module.less'
import { useCanvas } from '../../../../../external-store/atoms/create-canvas'

export function EmojiPick() {
    const commentService = useCommentService()
    const insertEmojiRect = useInsertEmojiRect()
    const emojiPickRef = useRef<HTMLDivElement>(null)
    const canvas = useCanvas()
    const [style, setStyle] = useState<React.CSSProperties>()

    const onSelect = useCallback(
        (shortName: string) => {
            commentService.updateInsertEmoji(shortName)
        },
        [commentService]
    )

    useLayoutEffect(() => {
        const emojiPickRect = emojiPickRef.current?.getBoundingClientRect()
        const canvasRect = canvas.getBoundingClientRect()
        if (!emojiPickRect || !insertEmojiRect) {
            return
        }
        const { editRect, containerRect } = insertEmojiRect
        const res = calculateEditorPopupPosition(emojiPickRect, editRect, containerRect, canvasRect)
        setStyle(res)
    }, [canvas, insertEmojiRect])

    useEffect(() => {
        const callback = (e: PointerEvent) => {
            const container = emojiPickRef.current
            if (!container?.contains(e.target as HTMLElement)) {
                commentService.closeEmojiPick()
            }
        }
        document.addEventListener('pointerdown', callback, true)
        return () => document.removeEventListener('pointerdown', callback, true)
    }, [commentService])

    const onPointerDown = useCallback((e: React.PointerEvent) => {
        // 阻止拖拽
        e.stopPropagation()
    }, [])

    const onMouseDown = useCallback((e: React.MouseEvent) => {
        // 阻止点击拾取框导致富文本编辑器失焦（如果它有聚焦）
        e.stopPropagation()
        e.preventDefault()
        e.nativeEvent.stopImmediatePropagation()
    }, [])

    return (
        <div
            style={style}
            ref={emojiPickRef}
            onPointerDown={onPointerDown}
            className={classes.emojiPick}
            onPointerUp={(e) => e.stopPropagation()}
            onMouseDown={onMouseDown}
        >
            <Scrollbar>
                <EmojiSelect onSelect={onSelect} />
            </Scrollbar>
        </div>
    )
}
