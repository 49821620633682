import { Wukong } from '@wukong/bridge-proto'
import { translation } from './utils.translation'

export enum SelectedVariablesEditorActiveKey {
    DETAIL = 'detail',
    SCOPE = 'scope',
}

export enum VariableCodeSyntaxKey {
    Web = 'Web',
    iOS = 'iOS',
    Android = 'Android',
}

export const VariableCodeSyntaxKey2Platform: Record<VariableCodeSyntaxKey, Wukong.DocumentProto.VariableCodePlatform> =
    {
        [VariableCodeSyntaxKey.Web]: Wukong.DocumentProto.VariableCodePlatform.VARIABLE_CODE_PLATFORM_W_E_B,
        [VariableCodeSyntaxKey.iOS]: Wukong.DocumentProto.VariableCodePlatform.VARIABLE_CODE_PLATFORM_I_O_S,
        [VariableCodeSyntaxKey.Android]: Wukong.DocumentProto.VariableCodePlatform.VARIABLE_CODE_PLATFORM_A_N_D_R_O_I_D,
    }

export enum VariableScopeCheckboxKey {
    All = 'All',
    Fill = 'Fill',
    FrameFill = 'FrameFill',
    ShapeFill = 'ShapeFill',
    TextFill = 'TextFill',
    Stroke = 'Stroke',
    Effect = 'Effect',
}

export const VariableScopeCheckboxKeyDepth: Record<VariableScopeCheckboxKey, number> = {
    [VariableScopeCheckboxKey.All]: 0,
    [VariableScopeCheckboxKey.Fill]: 1,
    [VariableScopeCheckboxKey.FrameFill]: 2,
    [VariableScopeCheckboxKey.ShapeFill]: 2,
    [VariableScopeCheckboxKey.TextFill]: 2,
    [VariableScopeCheckboxKey.Stroke]: 1,
    [VariableScopeCheckboxKey.Effect]: 1,
}

export const VariableScopeCheckboxKey2Label: Record<VariableScopeCheckboxKey, string> = {
    [VariableScopeCheckboxKey.All]: translation('All'),
    [VariableScopeCheckboxKey.Fill]: translation('Fill'),
    [VariableScopeCheckboxKey.FrameFill]: translation('FrameFill'),
    [VariableScopeCheckboxKey.ShapeFill]: translation('ShapeFill'),
    [VariableScopeCheckboxKey.TextFill]: translation('TextFill'),
    [VariableScopeCheckboxKey.Stroke]: translation('Stroke'),
    [VariableScopeCheckboxKey.Effect]: translation('Effect'),
}

const VariableScopeCheckboxKey2Scope: Record<VariableScopeCheckboxKey, Wukong.DocumentProto.VariableScope> = {
    [VariableScopeCheckboxKey.All]: Wukong.DocumentProto.VariableScope.ALL_SCOPES,
    [VariableScopeCheckboxKey.Fill]: Wukong.DocumentProto.VariableScope.ALL_FILLS,
    [VariableScopeCheckboxKey.FrameFill]: Wukong.DocumentProto.VariableScope.FRAME_FILL,
    [VariableScopeCheckboxKey.ShapeFill]: Wukong.DocumentProto.VariableScope.SHAPE_FILL,
    [VariableScopeCheckboxKey.TextFill]: Wukong.DocumentProto.VariableScope.TEXT_FILL,
    [VariableScopeCheckboxKey.Stroke]: Wukong.DocumentProto.VariableScope.VARIABLE_SCOPE_S_T_R_O_K_E,
    [VariableScopeCheckboxKey.Effect]: Wukong.DocumentProto.VariableScope.EFFECT_COLOR,
}

export interface VariableScopeCheckboxValue {
    key: VariableScopeCheckboxKey
    mixed: boolean
    checked: boolean
}

export interface VariablePublishHiddenValue {
    value: boolean
    mixed: boolean
}

export function getVariablePublishHiddenValue(variables: Wukong.DocumentProto.ILocalVariable[]) {
    let val: VariablePublishHiddenValue | undefined
    for (const variable of variables) {
        if (!val) {
            val = {
                value: !variable.isPublishable,
                mixed: false,
            }
        } else if (val.value !== !variable.isPublishable) {
            val.mixed = true
            break
        }
    }
    return val
}

export function getVariableScopeCheckboxValues(variables: Wukong.DocumentProto.ILocalVariable[]) {
    const scopesList = variables.map((v) => v.scopes)
    let scopeCheckboxValuesMap = {} as Record<VariableScopeCheckboxKey, VariableScopeCheckboxValue>

    for (const [index, scopes] of scopesList.entries()) {
        if (index === 0) {
            scopeCheckboxValuesMap = generateScopeCheckboxValuesMap(scopes)
        } else if (calcScopeCheckboxValuesMap(scopeCheckboxValuesMap, generateScopeCheckboxValuesMap(scopes))) {
            break
        }
    }

    return Object.values(VariableScopeCheckboxKey).map((key) => scopeCheckboxValuesMap[key])
}

export function updateVariableScopes(
    scopes: Wukong.DocumentProto.VariableScope[],
    checkboxKey: VariableScopeCheckboxKey,
    checked: boolean
) {
    const checkboxValuesMap = generateScopeCheckboxValuesMap(scopes)
    const isAllChecked = checkboxValuesMap[VariableScopeCheckboxKey.All].checked
    const isFillChecked = checkboxValuesMap[VariableScopeCheckboxKey.Fill].checked
    const newScopes: Wukong.DocumentProto.VariableScope[] = []

    switch (checkboxKey) {
        case VariableScopeCheckboxKey.All:
            if (checked) {
                newScopes.push(Wukong.DocumentProto.VariableScope.ALL_SCOPES)
            }
            break
        case VariableScopeCheckboxKey.Fill:
            if (checked && isAllChecked) {
                newScopes.push(Wukong.DocumentProto.VariableScope.ALL_SCOPES)
            } else {
                if (checked) {
                    newScopes.push(Wukong.DocumentProto.VariableScope.ALL_FILLS)
                }
                ;[VariableScopeCheckboxKey.Stroke, VariableScopeCheckboxKey.Effect].forEach((key) => {
                    checkboxValuesMap[key].checked && newScopes.push(VariableScopeCheckboxKey2Scope[key])
                })
            }
            break
        case VariableScopeCheckboxKey.FrameFill:
            if (checked && isAllChecked) {
                newScopes.push(Wukong.DocumentProto.VariableScope.ALL_SCOPES)
            } else {
                if (checked && isFillChecked) {
                    newScopes.push(Wukong.DocumentProto.VariableScope.ALL_FILLS)
                } else {
                    if (checked) {
                        newScopes.push(Wukong.DocumentProto.VariableScope.FRAME_FILL)
                    }
                    ;[VariableScopeCheckboxKey.ShapeFill, VariableScopeCheckboxKey.TextFill].forEach((key) => {
                        checkboxValuesMap[key].checked && newScopes.push(VariableScopeCheckboxKey2Scope[key])
                    })
                }
                ;[VariableScopeCheckboxKey.Effect, VariableScopeCheckboxKey.Stroke].forEach((key) => {
                    checkboxValuesMap[key].checked && newScopes.push(VariableScopeCheckboxKey2Scope[key])
                })
            }
            break
        case VariableScopeCheckboxKey.ShapeFill:
            if (checked && isAllChecked) {
                newScopes.push(Wukong.DocumentProto.VariableScope.ALL_SCOPES)
            } else {
                if (checked && isFillChecked) {
                    newScopes.push(Wukong.DocumentProto.VariableScope.ALL_FILLS)
                } else {
                    if (checked) {
                        newScopes.push(Wukong.DocumentProto.VariableScope.SHAPE_FILL)
                    }
                    ;[VariableScopeCheckboxKey.FrameFill, VariableScopeCheckboxKey.TextFill].forEach((key) => {
                        checkboxValuesMap[key].checked && newScopes.push(VariableScopeCheckboxKey2Scope[key])
                    })
                }
                ;[VariableScopeCheckboxKey.Effect, VariableScopeCheckboxKey.Stroke].forEach((key) => {
                    checkboxValuesMap[key].checked && newScopes.push(VariableScopeCheckboxKey2Scope[key])
                })
            }
            break
        case VariableScopeCheckboxKey.TextFill:
            if (checked && isAllChecked) {
                newScopes.push(Wukong.DocumentProto.VariableScope.ALL_SCOPES)
            } else {
                if (checked && isFillChecked) {
                    newScopes.push(Wukong.DocumentProto.VariableScope.ALL_FILLS)
                } else {
                    if (checked) {
                        newScopes.push(Wukong.DocumentProto.VariableScope.TEXT_FILL)
                    }
                    ;[VariableScopeCheckboxKey.FrameFill, VariableScopeCheckboxKey.ShapeFill].forEach((key) => {
                        checkboxValuesMap[key].checked && newScopes.push(VariableScopeCheckboxKey2Scope[key])
                    })
                }
                ;[VariableScopeCheckboxKey.Effect, VariableScopeCheckboxKey.Stroke].forEach((key) => {
                    checkboxValuesMap[key].checked && newScopes.push(VariableScopeCheckboxKey2Scope[key])
                })
            }
            break
        case VariableScopeCheckboxKey.Stroke:
            if (checked && isAllChecked) {
                newScopes.push(Wukong.DocumentProto.VariableScope.ALL_SCOPES)
            } else {
                if (checked) {
                    newScopes.push(Wukong.DocumentProto.VariableScope.VARIABLE_SCOPE_S_T_R_O_K_E)
                }
                if (isFillChecked) {
                    newScopes.push(Wukong.DocumentProto.VariableScope.ALL_FILLS)
                } else {
                    ;[
                        VariableScopeCheckboxKey.FrameFill,
                        VariableScopeCheckboxKey.ShapeFill,
                        VariableScopeCheckboxKey.TextFill,
                    ].forEach((key) => {
                        checkboxValuesMap[key].checked && newScopes.push(VariableScopeCheckboxKey2Scope[key])
                    })
                }
                checkboxValuesMap[VariableScopeCheckboxKey.Effect].checked &&
                    newScopes.push(VariableScopeCheckboxKey2Scope[VariableScopeCheckboxKey.Effect])
            }
            break
        case VariableScopeCheckboxKey.Effect:
            if (checked && isAllChecked) {
                newScopes.push(Wukong.DocumentProto.VariableScope.ALL_SCOPES)
            } else {
                if (checked) {
                    newScopes.push(Wukong.DocumentProto.VariableScope.EFFECT_COLOR)
                }
                if (isFillChecked) {
                    newScopes.push(Wukong.DocumentProto.VariableScope.ALL_FILLS)
                } else {
                    ;[
                        VariableScopeCheckboxKey.FrameFill,
                        VariableScopeCheckboxKey.ShapeFill,
                        VariableScopeCheckboxKey.TextFill,
                    ].forEach((key) => {
                        checkboxValuesMap[key].checked && newScopes.push(VariableScopeCheckboxKey2Scope[key])
                    })
                }
                checkboxValuesMap[VariableScopeCheckboxKey.Stroke].checked &&
                    newScopes.push(VariableScopeCheckboxKey2Scope[VariableScopeCheckboxKey.Stroke])
            }
            break
        default:
            break
    }
    return newScopes
}

function generateScopeCheckboxValuesMap(scopes: Wukong.DocumentProto.VariableScope[]) {
    const scopeCheckboxValuesMap = {} as Record<VariableScopeCheckboxKey, VariableScopeCheckboxValue>
    Object.values(VariableScopeCheckboxKey).forEach((key) => {
        scopeCheckboxValuesMap[key] = { key, mixed: false, checked: false }
    })
    scopes.forEach((scope) => {
        switch (scope) {
            case Wukong.DocumentProto.VariableScope.ALL_SCOPES:
                Object.values(VariableScopeCheckboxKey).forEach((key) => {
                    scopeCheckboxValuesMap[key].checked = true
                })
                break
            case Wukong.DocumentProto.VariableScope.ALL_FILLS:
                Object.values([
                    VariableScopeCheckboxKey.Fill,
                    VariableScopeCheckboxKey.FrameFill,
                    VariableScopeCheckboxKey.ShapeFill,
                    VariableScopeCheckboxKey.TextFill,
                ]).forEach((key) => {
                    scopeCheckboxValuesMap[key].checked = true
                })
                break
            case Wukong.DocumentProto.VariableScope.FRAME_FILL:
                scopeCheckboxValuesMap[VariableScopeCheckboxKey.FrameFill].checked = true
                break
            case Wukong.DocumentProto.VariableScope.SHAPE_FILL:
                scopeCheckboxValuesMap[VariableScopeCheckboxKey.ShapeFill].checked = true
                break
            case Wukong.DocumentProto.VariableScope.TEXT_FILL:
                scopeCheckboxValuesMap[VariableScopeCheckboxKey.TextFill].checked = true
                break
            case Wukong.DocumentProto.VariableScope.VARIABLE_SCOPE_S_T_R_O_K_E:
                scopeCheckboxValuesMap[VariableScopeCheckboxKey.Stroke].checked = true
                break
            case Wukong.DocumentProto.VariableScope.EFFECT_COLOR:
                scopeCheckboxValuesMap[VariableScopeCheckboxKey.Effect].checked = true
                break
            default:
                break
        }
    })

    return scopeCheckboxValuesMap
}

function calcScopeCheckboxValuesMap(
    resMap: Record<VariableScopeCheckboxKey, VariableScopeCheckboxValue>,
    map: Record<VariableScopeCheckboxKey, VariableScopeCheckboxValue>
) {
    let isAllMixed = true
    Object.values(VariableScopeCheckboxKey).forEach((key) => {
        if (!resMap[key].mixed && resMap[key].checked !== map[key].checked) {
            resMap[key].mixed = true
        }
        if (!resMap[key].mixed) {
            isAllMixed = false
        }
    })
    return isAllMixed
}
