import classNames from 'classnames'
import { useCallback, useMemo, useRef } from 'react'
import {
    MonoIconCommonBell24,
    Scrollbar,
    WKAlert,
    WKButton,
    wkDialogConfirm,
    WKToast,
    WKTypography,
} from '../../../../../../../ui-lib/src'
import { WKLoading } from '../../../../../../../ui-lib/src/components/wk-loading/wk-loading'
import { isEnglishLanguage } from '../../../../../../../util/src'
import { DocID } from '../../../../../kernel/interface/type'
import type { LibraryContentVO } from '../../../../../kernel/interface/library'
import { PutLibrary } from '../../../../../kernel/request/library'
import { useHistoryService, useLibraryComponentService } from '../../../../../main/app-context'
import { LibraryComponentViewEditorConfigProvider } from '../../../../../share/component-style-library/library-component-viewer/context'
import { RemoteLibraryContentViewerV2 } from '../../../../../share/component-style-library/library-control-list-item-detail/library-control-list-item-detail'
import { deleteRemovedAndMovedVOInLibraryContent } from '../../../../../share/component-style-library/util/filter'
import { useViewState } from '../../../../../view-state-bridge'
import { LibraryTestId } from '../../../../../window'
import { useDocInfoContext } from '../../../../context/top-area-context'
import { OpenLibraryPublishModalSource } from '../../library-service/library-modal-router-service'
import { useLibraryModalRouter } from '../../library-service/use-library-modal-router'
import classes from './library-list-modal-local.module.less'
import { translation } from './library-list-modal-local.translation'

// 本地组件库 tab 内容
export function LibraryListModalLocal() {
    const { docData } = useDocInfoContext()
    const modalDestroyFnRef = useRef<() => void>()
    const {
        docId,
        states,
        libraryModalRouterService: { goToLocalLibraryHome, closeLibraryMainModal },
    } = useLibraryComponentService()
    const { openLibraryPublishModalIfPaid } = useLibraryModalRouter()
    const currentDocRemoteLibraryContent = states.use.currentDocRemoteLibraryContentState()
    const libraryStoreLibrary = useMemo(
        () =>
            currentDocRemoteLibraryContent
                ? deleteRemovedAndMovedVOInLibraryContent(currentDocRemoteLibraryContent)
                : currentDocRemoteLibraryContent,
        [currentDocRemoteLibraryContent]
    )
    const historyService = useHistoryService()

    const onUnPublish = useCallback(async () => {
        if (!libraryStoreLibrary) return
        modalDestroyFnRef.current?.()
        closeLibraryMainModal()
        modalDestroyFnRef.current = wkDialogConfirm.show({
            title: `${translation('RemoveFromLibrary')}`,
            content: translation('ThisActionWill'),
            okText: translation('Remove'),
            onOk: async () => {
                try {
                    await new PutLibrary(libraryStoreLibrary.library.id, {
                        id: libraryStoreLibrary.library.id,
                        shared: false,
                        shareScope: libraryStoreLibrary.library.shareScope,
                    }).start()
                    historyService.requestCreateUnPublishLibraryVersion()
                    WKToast.show(translation('SuccessfullyUnpublishedLibrary'))
                } catch (err) {
                    WKToast.error(translation('FailedTo'))
                    console.error(err)
                }
            },
            onClose: goToLocalLibraryHome,
        }).destroy
    }, [closeLibraryMainModal, goToLocalLibraryHome, historyService, libraryStoreLibrary])

    // TODO(lisw) 待 DraggableModal visible=false 可以正常关闭弹窗后，再将此注释打开
    // useEffect(() => {
    //     return () => {
    //         modalDestroyFnRef.current?.()
    //     }
    // }, [])

    return (
        <LocalLibraryContentDetail
            isDraft={!!docData?.draft}
            docName={docData?.name}
            docId={docId}
            remoteLibraryContent={libraryStoreLibrary}
            onPublish={() =>
                openLibraryPublishModalIfPaid(OpenLibraryPublishModalSource.CurrentDocPublishButton, !!docData?.draft)
            }
            onUnPublish={onUnPublish}
        />
    )
}

export function LocalLibraryContentDetail(props: {
    isDraft: boolean // 文件是否在草稿箱里
    docName: string | undefined
    docId: DocID
    remoteLibraryContent: LibraryContentVO | null | undefined
    onPublish: () => void
    onUnPublish: () => void
}) {
    return props.remoteLibraryContent?.library?.shared &&
        (props.remoteLibraryContent.components.length ||
            props.remoteLibraryContent.componentSets.length ||
            props.remoteLibraryContent.styles.length ||
            props.remoteLibraryContent.variableSets.length ||
            props.remoteLibraryContent.variables.length) ? (
        <CurrenRemoteLibraryContent
            docName={props.docName}
            docId={props.docId}
            remoteLibraryContent={props.remoteLibraryContent}
            onUnPublish={props.onUnPublish}
        />
    ) : (
        <EmptyOrNoSharingLocalLibraryContent
            loading={props.remoteLibraryContent === undefined}
            onPublish={props.onPublish}
        />
    )
}

// 已发布&共享中的本地组件库
function CurrenRemoteLibraryContent(props: {
    docName: string | undefined
    docId: DocID
    remoteLibraryContent: LibraryContentVO
    onUnPublish: () => void
}) {
    return (
        <div className={classes.container} data-testid={LibraryTestId.HomeModal.CurrentBody}>
            <div className={classes.header}>
                <WKTypography.Paragraph className={classes.title}>
                    {props.docName || translation('UnknownLibrary')}
                </WKTypography.Paragraph>
                <div className={classes.actionContainer}>
                    <div
                        data-testid={LibraryTestId.HomeModal.UnpublishBtn}
                        className={classNames([classes.action, 'ml-2'])}
                    >
                        <WKButton type="secondary" size="small" onClick={props.onUnPublish}>
                            {translation('Unpublish')}
                        </WKButton>
                    </div>
                </div>
            </div>
            <Scrollbar className={classes.scrollWrapper}>
                <ChangesAlert />
                <div className={classes.content}>
                    <LibraryComponentViewEditorConfigProvider draggable>
                        <RemoteLibraryContentViewerV2 libraryContent={props.remoteLibraryContent} />
                    </LibraryComponentViewEditorConfigProvider>
                </div>
            </Scrollbar>
        </div>
    )
}

// 未发布的本地组件库
function EmptyOrNoSharingLocalLibraryContent(props: { loading: boolean; onPublish: () => void }) {
    const currentLocalModalState = useViewState('libraryCurrentLocalModal')
    const text = useMemo(() => {
        const texts = []
        if (currentLocalModalState?.currentLocalComponentCount) {
            texts.push(
                ` ${currentLocalModalState?.currentLocalComponentCount}${
                    isEnglishLanguage()
                        ? currentLocalModalState?.currentLocalComponentCount === 1
                            ? ' component'
                            : ' components'
                        : ' 个组件'
                }`
            )
        }
        if (currentLocalModalState?.currentLocalStyleCount) {
            texts.push(
                ` ${currentLocalModalState?.currentLocalStyleCount}${
                    isEnglishLanguage()
                        ? currentLocalModalState?.currentLocalStyleCount === 1
                            ? ' style'
                            : ' styles'
                        : ' 个样式'
                }`
            )
        }
        if (currentLocalModalState?.currentLocalVariableConut) {
            texts.push(
                ` ${currentLocalModalState?.currentLocalVariableConut}${
                    isEnglishLanguage()
                        ? currentLocalModalState?.currentLocalVariableConut === 1
                            ? ' variable'
                            : ' variables'
                        : ' 个变量'
                }`
            )
        } else if (currentLocalModalState?.currentLocalVariableSetCount) {
            texts.push(
                ` ${currentLocalModalState?.currentLocalVariableSetCount}${
                    isEnglishLanguage()
                        ? currentLocalModalState?.currentLocalVariableSetCount === 1
                            ? ' variable collection'
                            : ' variable collections'
                        : ' 个变量合集'
                }`
            )
        }
        if (texts.length) {
            if (texts.length === 3) {
                return `${translation('gHrUlZ')}${translation('ThreeTypes', {
                    type1: texts[0],
                    type2: texts[1],
                    type3: texts[2],
                })}`
            }
            return `${translation('gHrUlZ')}${texts.join(translation(','))}`
        }
        return translation('NoComponents,styles,variables')
    }, [
        currentLocalModalState?.currentLocalComponentCount,
        currentLocalModalState?.currentLocalStyleCount,
        currentLocalModalState?.currentLocalVariableConut,
        currentLocalModalState?.currentLocalVariableSetCount,
    ])

    return props.loading ? (
        <WKLoading noText className="!flex mx-auto my-2" />
    ) : (
        <div className={classes.emptyOrNoSharedContainer}>
            {currentLocalModalState?.currentLocalComponentCount ||
            currentLocalModalState?.currentLocalStyleCount ||
            currentLocalModalState?.currentLocalVariableSetCount ? (
                <>
                    <span className={classes.title}>{translation('LocalLibraryNot')}</span>
                    <span className={classes.description} data-testid="current-library-detail-count-info">
                        {text}
                    </span>
                    <WKButton
                        type="secondary"
                        size="small"
                        onClick={props.onPublish}
                        data-testid={LibraryTestId.HomeModal.GoPublishLibrary}
                        className={classes.button}
                    >
                        {translation('Publish')}
                    </WKButton>
                </>
            ) : (
                <span className={classes.description} data-testid={LibraryTestId.HomeModal.CurrentEmptyPlaceholder}>
                    {text}
                </span>
            )}
        </div>
    )
}

function ChangesAlert() {
    const { docData } = useDocInfoContext()
    const { openLibraryPublishModalIfPaid } = useLibraryModalRouter()
    const libraryGlobalState = useViewState('libraryGlobalState')
    const publishableCount = libraryGlobalState?.publishableCount ?? 0

    return publishableCount ? (
        <div className={classes.alert}>
            <WKAlert.WithoutTitle
                icon={<MonoIconCommonBell24 />}
                iconWrapClassName="!p-0 flex items-center"
                suffix={
                    <WKButton
                        size="small"
                        onClick={() =>
                            openLibraryPublishModalIfPaid(
                                OpenLibraryPublishModalSource.CurrentDocPublishButton,
                                !!docData?.draft
                            )
                        }
                        type="primary"
                        data-testid={LibraryTestId.HomeModal.GoUpdateLibrary}
                    >
                        {translation('Publish_synonyms1')}
                    </WKButton>
                }
            >
                {translation('FBOUJC', {
                    count: publishableCount,
                    change: publishableCount > 1 ? 'changes' : 'change',
                })}
            </WKAlert.WithoutTitle>
        </div>
    ) : (
        <></>
    )
}
