import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    ReadOnlyDisabledMessage: '不支持在只读模式使用',
    DevModeDisabledMessage: '暂不支持在研发模式使用',
    HistoryModeDisabledMessage: '不支持在查看历史版本时使用',
} as const

export const enTranslation = {
    ReadOnlyDisabledMessage: 'Unavailable in view-only',
    DevModeDisabledMessage: 'Unavailable in Develop Mode for now',
    HistoryModeDisabledMessage: 'Unavailable when showing version history',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
