import { ReplayEventEmitter } from './replay-event-emitter'

/**
 * @experimental 用于替换BehaviorSubject
 */
export class BehaviorEventEmitter<T> extends ReplayEventEmitter<T> {
    constructor(init: T) {
        super(1)
        this.next(init)
    }

    get value(): T {
        return this.getValue()
    }

    getValue(): T {
        if (this.cache.length !== 1) {
            throw new Error('BehaviorEventEmitter is not initialized')
        }

        return this.cache[0]
    }
}
