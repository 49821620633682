/* eslint-disable no-restricted-imports */
import classNames from 'classnames'
import { FC, useMemo } from 'react'
import { useCommand } from '../../../../../../main/app-context'
import { getNodesPreviewBase64ImageWithFixedSize } from '../../../../design/export/util'
import styles from './index.module.less'

interface IDevModeLayerPanelAssetPreviewProps {
    id: string
    isImageAsset: boolean
    isCommonAsset: boolean
    darkBackgroud: boolean
    useCoverFit: boolean
}

export const DevModeLayerPanelAssetPreview: FC<IDevModeLayerPanelAssetPreviewProps> = ({
    id,
    isImageAsset,
    isCommonAsset,
    darkBackgroud,
    useCoverFit,
}) => {
    const command = useCommand()
    const previewBase64 = useMemo(
        () => (isImageAsset || isCommonAsset ? getNodesPreviewBase64ImageWithFixedSize(command, [id], 64) : undefined),
        [command, id, isCommonAsset, isImageAsset]
    )

    return previewBase64 ? (
        <img
            className={classNames(styles.devModeLayerPanelAssetPreview, {
                [styles.darkBackgroud]: darkBackgroud,
                [styles.coverFit]: useCoverFit,
            })}
            src={previewBase64}
            data-testid="dev-mode-layer-panel-preview-asset"
        />
    ) : null
}
