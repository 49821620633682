import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    OverlaySettings: '浮层设置',
    Center: '居中',
    TopLeft: '左上',
    TopCenter: '顶部居中',
    TopRight: '右上',
    BottomLeft: '左下',
    BottomCenter: '底部居中',
    BottomRight: '右下',
    Manual: '自定义',
    CloseOutside: '点击浮层外关闭',
    AddBackground: '添加蒙层',
    Background: '蒙层颜色',
} as const

export const enTranslation = {
    OverlaySettings: 'Overlay settings',
    Center: 'Center',
    TopLeft: 'Top left',
    TopCenter: 'Top center',
    TopRight: 'Top right',
    BottomLeft: 'Bottom left',
    BottomCenter: 'Bottom center',
    BottomRight: 'Bottom right',
    Manual: 'Manual',
    CloseOutside: 'Close when clicking outside',
    AddBackground: 'Add background',
    Background: 'Background',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
