import { useEffect, useRef, useState } from 'react'
import { WKLoading } from '../../../../../../ui-lib/src/components/wk-loading/wk-loading'
import { TraceableAbortController } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { getEnvironment } from '../../../../environment'
import { SampleHTML } from './antd-sample-html'
import { useAIGenUIInContext } from './use-ai-gen-ui'
export function AntdUISample() {
    // render SampleHTML as shadow dom
    const ref = useRef<HTMLDivElement>(null)
    const shadowRootRef = useRef<ShadowRoot | null>(null)
    const { tempRemixConfig, antdDesignPalette } = useAIGenUIInContext()

    const fontFamily = tempRemixConfig?.get('font') ?? 'Roboto'
    const radius = tempRemixConfig?.get('borderRadius') ?? '6px'
    const fontSize = tempRemixConfig?.get('fontSizeBase') ?? '14px'
    const mode = tempRemixConfig?.get('mode') ?? 'light'
    const [waitForCSS, setWaitForCSS] = useState(false)

    useEffect(() => {
        if (ref.current && !shadowRootRef.current) {
            // Only create shadow root once
            setWaitForCSS(true)
            shadowRootRef.current = ref.current.attachShadow({ mode: 'open' })

            const env = getEnvironment()
            const cssURL = `${env.ossCdnPath}app/public/resource/external-style/antd.css`

            // Set HTML content to the shadow root, not the host element
            shadowRootRef.current.innerHTML = SampleHTML(cssURL)
        }
    }, [])

    useEffect(() => {
        if (shadowRootRef.current) {
            const abc = new TraceableAbortController('Remove listen loaded')
            const styles = shadowRootRef.current.querySelectorAll('link')
            const rootC = shadowRootRef.current.querySelector('#root') as HTMLDivElement
            styles.forEach((style) => {
                style.addEventListener(
                    'load',
                    () => {
                        rootC.style.setProperty('display', 'block')
                        setWaitForCSS(false)
                    },
                    { signal: abc.signal }
                )
            })
            return () => {
                abc.abort('Remove listen loaded')
            }
        }
    }, [])

    useEffect(() => {
        if (shadowRootRef.current) {
            const rootTheme: HTMLDivElement | null = shadowRootRef.current.querySelector('#root')
            if (rootTheme) {
                rootTheme.setAttribute('data-theme', mode ?? 'light')

                rootTheme.style.setProperty('--ant-primary', antdDesignPalette.colorPrimary)
                rootTheme.style.setProperty('--ant-success', antdDesignPalette.colorSuccess)
                rootTheme.style.setProperty('--ant-warning', antdDesignPalette.colorWarning)
                rootTheme.style.setProperty('--ant-error', antdDesignPalette.colorError)
                rootTheme.style.setProperty('--ant-link', antdDesignPalette.colorLink ?? '#1677ff')
                rootTheme.style.setProperty('--ant-text-base', antdDesignPalette.colorTextBase)
                rootTheme.style.setProperty('--ant-bg-base', antdDesignPalette.colorBackgroundBase)

                rootTheme.style.setProperty('--font-family', fontFamily)
                rootTheme.style.setProperty('--base-radius', radius + 'px')
                rootTheme.style.setProperty('--font-size-base', fontSize + 'px')

                rootTheme.style.setProperty('border-radius', '10.5px')
                rootTheme.style.setProperty('box-shadow', '0 0 0 5.26px var(--wk-gray-13-8)')
                rootTheme.style.setProperty('overflow', 'hidden')
            }
        }
    }, [antdDesignPalette, fontFamily, radius, fontSize, mode])

    return (
        <>
            <div
                className={`${waitForCSS ? 'hidden' : 'block'}`}
                style={{
                    transform: 'scale(0.3875)',
                    transformOrigin: 'center',
                }}
                ref={ref}
            />
            <div className={`${waitForCSS ? 'block' : 'hidden'}`}>
                <WKLoading noText />
            </div>
        </>
    )
}
