/* eslint-disable no-restricted-imports */
import { ToggleSelectionMaskOutline } from '@wukong/bridge-proto'
import { useCommand } from '../../../../../context/document-context'

export function useMaskCommand() {
    const command = useCommand()

    return {
        toggleSelectionMaskOutline: () => {
            command.DEPRECATED_invokeBridge(ToggleSelectionMaskOutline)
            command.commitUndo()
        },
    }
}
