import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    Rename: '重命名选中的',
    Layers: '个图层',
    StartAscendingFrom: '数字降序至',
    StartSequenceAt: '数字升序于',
    Cancel: '取消',
    Rename_synonyms1: '重命名',
    Rename_synonyms2: '重命名',
    Preview: '预览',
    Match: '查找（选填）',
    CurrentName: '图层名称',
    Number: '数字',
} as const

export const enTranslation = {
    Rename: 'Rename',
    Layers: 'layers',
    StartAscendingFrom: 'Stop descending at',
    StartSequenceAt: 'Start ascending from',
    Cancel: 'Cancel',
    Rename_synonyms1: 'Rename',
    Rename_synonyms2: 'Rename to',
    Preview: 'Preview',
    Match: 'Match(optional)',
    CurrentName: 'Current name',
    Number: 'Number',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
