import { IconLoading48 } from '../../../../../../../ui-lib/src'
import { PropsWithChildren } from 'react'

export function PendingWrapper(props: PropsWithChildren<{ pending: boolean }>) {
    const { pending, children } = props

    return pending ? (
        <div className="flex flex-grow justify-center items-center">
            <IconLoading48 className="animate-spin"></IconLoading48>
        </div>
    ) : (
        <>{children}</>
    )
}
