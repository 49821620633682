import {
    DismissComponentMoveNotificationCommand,
    DismissLibraryUpgradeNotificationCommandWasmCall,
    ViewLibraryUpgradeNotificationCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { MonoIconCommonLibrary24, wkNotification } from '../../../../../../ui-lib/src'
import { TraceableAbortSignal } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import { CommitType } from '../../../../document/command/commit-type'
import { Bridge } from '../../../../kernel/bridge/bridge'
import { LibraryNotificationType2Message } from '../../../../kernel/interface/library'
import { ServiceClass } from '../../../../kernel/util/service-class'
import { ViewStateBridge } from '../../../../view-state-bridge'
import { LibraryModalRouterService, OpenLibraryPublishModalSource } from './library-modal-router-service'
import { translation } from './library-notification-service.translation'

// 组件库右下角提示管理 class
export class LibraryNotificationService extends ServiceClass {
    private noticeDestroyFn?: () => void
    private readonly notificationType$ = this.buildReplaySubject<Wukong.DocumentProto.LibraryNotificationType>()
    private lastNotificationType: Wukong.DocumentProto.LibraryNotificationType =
        Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_NONE
    private onNotificationTypeChanged = (type: Wukong.DocumentProto.LibraryNotificationType) => {
        if (this.lastNotificationType !== type) {
            this.showNotification(type)
            this.lastNotificationType = type
        }
    }

    constructor(
        protected override readonly viewStateBridge: ViewStateBridge,
        private readonly commandInvoker: CommandInvoker,
        protected override readonly bridge: Bridge,
        private readonly libraryModalRouterService: LibraryModalRouterService,
        private readonly signal: TraceableAbortSignal
    ) {
        super(bridge, viewStateBridge)
    }

    public override destroy(): void {
        super.destroy()
        this.closeNotification()
    }

    public init = () => {
        this.registerViewState('libraryGlobalState', this.onLibraryGlobalStateChanged)
        this.registerViewState('editorType', this.onEditorType)
    }

    private onLibraryGlobalStateChanged = (state: Wukong.DocumentProto.IVLibraryGlobalState) => {
        this.onNotificationTypeChanged(
            state.notificationType ?? Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_NONE
        )
    }

    private onEditorType = (editorType: Wukong.DocumentProto.EditorType) => {
        if (editorType === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV) {
            this.onNotificationTypeChanged(Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_NONE)
        }
    }

    private showNotification = (notificationType: Wukong.DocumentProto.LibraryNotificationType) => {
        this.closeNotification()

        switch (notificationType) {
            case Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_COMPONENT_MOVED:
            case Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_STYLE_MOVED:
                this.noticeDestroyFn = wkNotification.show({
                    icon: <MonoIconCommonLibrary24 />,
                    title: LibraryNotificationType2Message(notificationType),
                    duration: -1,
                    type: 'black',
                    okText: translation('Review'),
                    cancelText: translation('Dismiss'),
                    okButtonProps: {
                        dataTestId: 'ui-notification-ok',
                    },
                    onOk: () => {
                        this.libraryModalRouterService.openLibraryPublishModal(
                            OpenLibraryPublishModalSource.MoveNotification,
                            false
                        )
                    },
                    onCancel: () => {
                        this.commandInvoker.invokeBridge(CommitType.Noop, DismissComponentMoveNotificationCommand)
                    },
                })?.close
                break
            case Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_REFERENCE_COMPONENT_MOVED:
            case Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_REFERENCE_STYLE_MOVED:
            case Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_REFERENCE_COMPONENT_STYLE_MOVED:
                this.noticeDestroyFn = wkNotification.show({
                    icon: <MonoIconCommonLibrary24 />,
                    title: LibraryNotificationType2Message(notificationType),
                    duration: -1,
                    type: 'black',
                    okText: translation('Review'),
                    cancelText: translation('Dismiss'),
                    okButtonProps: {
                        dataTestId: 'ui-notification-ok',
                    },
                    onOk: () => {
                        this.commandInvoker.invokeBridge(CommitType.Noop, ViewLibraryUpgradeNotificationCommand)
                        this.libraryModalRouterService.goToRemoteLibraryChangeList()
                    },
                    onCancel: () => {
                        this.commandInvoker.invokeBridge(
                            CommitType.Noop,
                            DismissLibraryUpgradeNotificationCommandWasmCall
                        )
                    },
                })?.close
                break
            case Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_REFERENCE_UPDATED:
            case Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_REFERENCE_COMPONENT_UPDATED:
            case Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_REFERENCE_STYLE_UPDATED:
                this.noticeDestroyFn = wkNotification.show({
                    icon: <MonoIconCommonLibrary24 />,
                    title: LibraryNotificationType2Message(notificationType),
                    duration: -1,
                    type: 'black',
                    okText: translation('Review'),
                    cancelText: translation('Dismiss'),
                    okButtonProps: {
                        dataTestId: 'ui-notification-ok',
                    },
                    onOk: () => {
                        this.commandInvoker.invokeBridge(CommitType.Noop, ViewLibraryUpgradeNotificationCommand)
                        this.libraryModalRouterService.goToRemoteLibraryChangeList()
                    },
                    onCancel: () => {
                        this.commandInvoker.invokeBridge(
                            CommitType.Noop,
                            DismissLibraryUpgradeNotificationCommandWasmCall
                        )
                    },
                })?.close
                break
            case Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_REFERENCE_VARIABLE_UPDATED:
            case Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_VARIABLE_AND_STYLE_UPDATED:
            case Wukong.DocumentProto.LibraryNotificationType.LIBRARY_NOTIFICATION_TYPE_VARIABLE_AND_COMPONENT_UPDATED:
            case Wukong.DocumentProto.LibraryNotificationType
                .LIBRARY_NOTIFICATION_TYPE_VARIABLE_AND_COMPONENT_AND_STYLE_UPDATED: {
                this.noticeDestroyFn = wkNotification.show({
                    icon: <MonoIconCommonLibrary24 />,
                    title: LibraryNotificationType2Message(notificationType),
                    duration: -1,
                    type: 'black',
                    okText: translation('Review'),
                    cancelText: translation('Dismiss'),
                    okButtonProps: {
                        dataTestId: 'ui-notification-ok',
                    },
                    onOk: () => {
                        this.commandInvoker.invokeBridge(CommitType.Noop, ViewLibraryUpgradeNotificationCommand)
                        this.libraryModalRouterService.goToRemoteLibraryChangeList()
                    },
                    onCancel: () => {
                        this.commandInvoker.invokeBridge(
                            CommitType.Noop,
                            DismissLibraryUpgradeNotificationCommandWasmCall
                        )
                    },
                })?.close
                break
            }
            default:
                break
        }
    }

    private closeNotification = () => {
        this.noticeDestroyFn?.()
        this.noticeDestroyFn = undefined
    }
}
