import { translation } from './select-constraint-type.translation'
/* eslint-disable no-restricted-imports */
import { useMemo } from 'react'
import { Mode } from '../common'

import { Wukong } from '@wukong/bridge-proto'
import { IconHeightFix, IconWidthFix, Select } from '../../../../../../../ui-lib/src'
import ConstraintType = Wukong.DocumentProto.ConstraintType

export interface Constraint {
    value: ConstraintType
    mixed: boolean
}

interface SelectConstraintTypeProps extends Constraint {
    className?: string
    mode: Mode
    disabled?: boolean
    disableFlex?: boolean // 是否应该禁用弹性约束选项
    onChange?: (type: ConstraintType) => void
}
export function SelectConstraintType(props: SelectConstraintTypeProps) {
    const { mixed, value, mode, disabled, className, onChange, disableFlex } = props

    const selectOptionMaps = useMemo(() => {
        return createSelectOptionMaps(mode, !!disableFlex)
    }, [mode, disableFlex])

    const selectOptions = useMemo(() => {
        return [...selectOptionMaps.values()]
    }, [selectOptionMaps])

    const isMixed = useMemo(() => {
        return value == ConstraintType.CONSTRAINT_TYPE_MIXED || mixed
    }, [mixed, value])

    return (
        <div className={className}>
            <Select.NormalSingleLevel
                disabled={disabled}
                isMixed={isMixed}
                icon={mode === Mode.Vertical ? <IconHeightFix /> : <IconWidthFix />}
                value={value}
                onChange={onChange}
                label={selectOptionMaps.get(value)?.label}
                minWidth={104}
            >
                {selectOptions.map((info) => (
                    <Select.NormalSingleLevel.Option
                        value={info.value}
                        key={info.value}
                        backwardIcon={''}
                        disabled={info.disabled}
                    >
                        {info.name}
                    </Select.NormalSingleLevel.Option>
                ))}
            </Select.NormalSingleLevel>
        </div>
    )
}
const constraintTypeSet: Set<ConstraintType> = new Set([
    ConstraintType.CONSTRAINT_TYPE_MIN,
    ConstraintType.CONSTRAINT_TYPE_MAX,
    ConstraintType.CONSTRAINT_TYPE_STRETCH,
    ConstraintType.CONSTRAINT_TYPE_CENTER,
    ConstraintType.CONSTRAINT_TYPE_SCALE,
])

function isFlexContraintType(type: ConstraintType) {
    return type === ConstraintType.CONSTRAINT_TYPE_STRETCH || type === ConstraintType.CONSTRAINT_TYPE_SCALE
}

function createSelectOptionMaps(mode: Mode, disableFlex: boolean) {
    const map: Map<
        ConstraintType,
        {
            value: ConstraintType
            name: string | undefined
            label: string | undefined
            disabled: boolean
        }
    > = new Map()

    for (const constraintType of constraintTypeSet) {
        const name = getConstraintTypeName(mode, constraintType)
        map.set(constraintType, {
            value: constraintType,
            name,
            label: name,
            disabled: disableFlex && isFlexContraintType(constraintType),
        })
    }

    return map
}
function getConstraintTypeName(mode: Mode, constraintType: ConstraintType) {
    switch (constraintType) {
        case ConstraintType.CONSTRAINT_TYPE_MIN:
            return mode === Mode.Vertical ? translation('Top') : translation('Left')
        case ConstraintType.CONSTRAINT_TYPE_MAX:
            return mode === Mode.Vertical ? translation('Bottom') : translation('Right')
        case ConstraintType.CONSTRAINT_TYPE_STRETCH:
            return mode === Mode.Vertical ? translation('TopAnd') : translation('LeftAnd')
        case ConstraintType.CONSTRAINT_TYPE_CENTER:
            return translation('Center')
        case ConstraintType.CONSTRAINT_TYPE_SCALE:
            return translation('Scale')
        case ConstraintType.CONSTRAINT_TYPE_MIXED:
            return 'Mixed'
    }
}
