import { useCallback } from 'react'
import { useMount } from 'react-use'
import { useFeatureSwitch } from '../../../../../kernel/switch/hooks'
import { useAppContext } from '../../../../../main/app-context'
import { LocalStorageKey } from '../../../../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../../../../web-storage/local-storage/storage'
import { defaultChatbotDebugSetting } from './debug-setting-default'

export const useDebugSetting = () => {
    const chatbotService = useAppContext().chatbotService
    const debugSetting = chatbotService.stateStore.use.chatbotDebugSetting()

    const focusV2 = useFeatureSwitch('feat-chatbot-focus-v2')

    useMount(() => {
        const settingRawString = enhancedLocalStorage.getSerializedItem(
            LocalStorageKey.ChatbotDebugSetting,
            JSON.stringify(defaultChatbotDebugSetting)
        )

        // 若存在相关开关，则使用开关值覆盖
        const setting = JSON.parse(settingRawString)
        setting.focusV2 = focusV2

        // update service state
        chatbotService.stateStore.setState({
            chatbotDebugSetting: setting,
        })
    })

    const setDebugSetting = useCallback(
        (key: keyof typeof debugSetting, value: boolean) => {
            const newDebugSetting = { ...debugSetting }
            newDebugSetting[key] = value

            enhancedLocalStorage.setSerializedItem(LocalStorageKey.ChatbotDebugSetting, JSON.stringify(newDebugSetting))
            chatbotService.stateStore.setState({
                chatbotDebugSetting: newDebugSetting,
            })
        },
        [debugSetting, chatbotService]
    )

    return {
        debugSetting,
        setDebugSetting,
    }
}
