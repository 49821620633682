import { Wukong } from '@wukong/bridge-proto'
import { RefObject, useMemo } from 'react'
import { ToKeyCode } from '../../../../../document/util/keycode'
import { useAppContext } from '../../../../../main/app-context'
import { KeyboardReceiver } from '../../../../../main/keyboard-receiver/component'
import { SingleGrid } from '../../../atom/grid/single-grid'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { ScrubbableInputPercent } from '../../../atom/inputs/scrubbable-input-percent'
import { Value } from '../../../atom/inputs/utils/type'
import { useRenderColorSpace } from '../../../color-profile/hook'
import { ColorVarItem } from '../../../color-var-item'
import { RGBA } from '../../../design/blend/color-picker/utils/color-translate'
import { ColorInteraction } from '../../../design/color-interaction/color-interaction'
import { ColorInteractionFrom } from '../../../design/color-interaction/type'
import { PaintIconColor } from '../../../paint-icon-color/paint-icon-color'
import { useSelectedVariablesEditorContext } from '../selected-variables-editor-context'
import classes from './color-item.module.less'

export function ColorVariableValueItem({
    mode,
    variable,
    popupRef,
}: {
    mode: Wukong.DocumentProto.IVariableSetMode
    variable: Wukong.DocumentProto.ILocalVariable
    popupRef?: RefObject<HTMLDivElement>
}) {
    const { modeId } = mode
    const value = modeId ? variable.dataValues?.[modeId] : undefined
    const isAliasData = value?.dataType === Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_ALIAS
    const colorVar = useMemo(() => {
        if (value && isAliasData) {
            return {
                id: value.alias,
                name: value.aliasName,
                isSoftDeleted: value.isSoftDeleted,
            } as Wukong.DocumentProto.ISingleVariable
        }
    }, [value, isAliasData])
    const paint = useMemo(() => {
        if (value) {
            return {
                type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
                opacity: value.resolvedValue.colorValue.a / 255,
                color: {
                    r: value.resolvedValue.colorValue.r,
                    g: value.resolvedValue.colorValue.g,
                    b: value.resolvedValue.colorValue.b,
                },
                visible: true,
                colorVar: isAliasData
                    ? ({
                          value: {
                              alias: value.alias,
                          },
                          dataType: value.dataType,
                          resolvedDataType: value.resolvedDataType,
                      } as Wukong.DocumentProto.IVariableAliasData)
                    : undefined,
            } as Wukong.DocumentProto.IPaint
        }
    }, [value, isAliasData])
    const service = useAppContext().variableService.localVariableEditorService
    const onChangeColor = (val: RGBA, options?: InputOptionsForUndoSquash) => {
        service.updateColorVariableValue(variable.id, modeId!, val, options?.commitType)
    }
    const onChangeOpacity = (val: Value, options?: InputOptionsForUndoSquash) => {
        if (typeof val === 'number' && paint?.color) {
            const rgba = { ...paint.color, a: (val / 100) * 255 } as RGBA
            onChangeColor(rgba, options)
        }
    }
    const { selectedModeId, onChangeSelectedModeId } = useSelectedVariablesEditorContext()
    const selected = selectedModeId === modeId
    const onClickDetachColorVar = () => {
        if (value) {
            onChangeColor(value.resolvedValue.colorValue)
            // 分离变量时，关闭弹窗
            onChangeSelectedModeId('')
        }
    }
    const onChangeColorVar = (aliasData: Wukong.DocumentProto.IVariableAliasData) => {
        service.setAliasForVariable(variable.id, modeId!, aliasData.value.alias!)
    }
    const colorSpace = useRenderColorSpace()
    if (!value || !paint) {
        return null
    }
    return (
        <>
            {colorVar ? (
                <ColorVarItem
                    paint={paint}
                    colorSpace={colorSpace}
                    variable={colorVar}
                    selected={selected}
                    width={144}
                    onClickDetach={onClickDetachColorVar}
                    onClickItem={() => onChangeSelectedModeId(modeId ?? '')}
                />
            ) : (
                <SingleGrid className={classes.backgroundGrid}>
                    <SingleGrid.Item start={1} span={23}>
                        <PaintIconColor
                            paint={paint}
                            colorSpace={colorSpace}
                            onChangeColor={(rgb) => onChangeColor({ ...rgb, a: value.resolvedValue.colorValue.a })}
                            onClickIcon={() => onChangeSelectedModeId(modeId ?? '')}
                            isWidth={92}
                            dataTestIds={{
                                hexInput: 'variable-value-item-hex-input',
                            }}
                        />
                    </SingleGrid.Item>
                    <SingleGrid.Item start={24} span={13}>
                        <ScrubbableInputPercent
                            value={paint?.opacity ?? undefined}
                            onChange={onChangeOpacity}
                            min={0}
                            max={100}
                            testId="variable-value-item-opacity-input"
                        />
                    </SingleGrid.Item>
                </SingleGrid>
            )}
            {selected && (
                <KeyboardReceiver keyCode={ToKeyCode.Esc} onKeydown={() => (onChangeSelectedModeId(''), false)}>
                    <ColorInteraction
                        position={{
                            // 在编辑区域的左下角打开
                            left: popupRef?.current?.getBoundingClientRect()?.left ?? 0 + 6,
                            top: popupRef?.current?.getBoundingClientRect()?.bottom ?? 0,
                        }}
                        positionRightBase={false}
                        from={ColorInteractionFrom.LOCAL_VARIABLE_EDIT}
                        paint={paint}
                        onCancel={() => onChangeSelectedModeId('')}
                        onChangePaintType={() => {}}
                        onChangeBlendMode={() => {}}
                        onChangeColor={(rgb, options) =>
                            onChangeColor({ ...rgb, a: value.resolvedValue.colorValue.a }, options)
                        }
                        onChangeOpacity={(opacity, options) => onChangeOpacity(opacity * 100, options)}
                        onChangeColorVar={onChangeColorVar}
                        onChangeImagePaint={() => {}}
                        onChangeColorStops={() => {}}
                        onChangeTransform={() => {}}
                        enterClose={() => {}}
                        onChangeStyle={() => {}}
                        onChangePaints={() => {}}
                        styleId={undefined}
                        onClickCreateStyleButton={undefined}
                    />
                </KeyboardReceiver>
            )}
        </>
    )
}
