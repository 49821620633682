import { translation } from './inspect-border-v2.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { isNil } from 'lodash-es'
import { useMemo } from 'react'
import { IconStrokeWeight } from '../../../../../ui-lib/src'
import { keepDecimal } from '../../../../../util/src'
import { ColorMode, RGB, StrokeAlign } from '../../../document/node/node'
import { useViewState } from '../../../view-state-bridge'
import { toFixed } from '../../utils/to-fixed'
import { useRenderColorSpace } from '../color-profile'
import { rgb2hex } from '../design/blend/color-picker/utils/color-translate'
import { ColorModePicker } from '../dev-mode/inspect/dev-mode-inspect-fill/color-mode-picker'
import { CopyableRow } from './comp/copyable-row'
import { StyleColorsV2 } from './comp/style-colors-v2'
import { UnStyleColorsV2 } from './comp/un-style-colors-v2'
import { strokeAlignToShow } from './inspect-type'
import style from './inspect.module.less'
import { getColorValue } from './utils'

function isNear(x: number, y: number, eps = 1e-5) {
    return Math.abs(x - y) < eps
}

export const colorRender = (colorMode: ColorMode, rgb: RGB, opacity?: number) => {
    const color = getColorValue(colorMode, rgb, opacity)
    const colorValue = '#' + rgb2hex(rgb.r, rgb.g, rgb.b).toUpperCase()
    const formatOpacity = toFixed(opacity, 2)
    if (colorMode === ColorMode.HEX) {
        return (
            <span className={style.paintColor}>
                <span className={style.rgb}>{colorValue}</span>
                {formatOpacity !== 1 && <span className={style.opacity}>{formatOpacity * 100}%</span>}
            </span>
        )
    } else {
        return <span className={style.paintColor}>{color}</span>
    }
}

export function InspectBorderV2() {
    const selectionStroke = useViewState('selectionStrokeV2')
    const colorSpace = useRenderColorSpace()
    const alignState = selectionStroke?.alignState
    const weightState = selectionStroke?.weightState
    const hasSameWeight =
        !weightState?.typeState.value ||
        weightState?.typeState.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_ALL
    const weightValueStr = hasSameWeight
        ? `${keepDecimal(weightState?.allBorderState.value ?? 0, 2)}px`
        : [
              weightState?.topBorderState.value,
              weightState?.rightBorderState.value,
              weightState?.bottomBorderState.value,
              weightState?.leftBorderState.value,
          ]
              .map((v) => `${keepDecimal(v ?? 0, 2)}px`)
              .join(',')

    const weightIsZero = hasSameWeight
        ? isNear(weightState?.allBorderState.value ?? 0, 0)
        : isNear(weightState.topBorderState.value ?? 0, 0) &&
          isNear(weightState.rightBorderState.value ?? 0, 0) &&
          isNear(weightState.bottomBorderState.value ?? 0, 0) &&
          isNear(weightState.leftBorderState.value ?? 0, 0)

    const isStyle =
        selectionStroke?.paintState.type == Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_STYLE
    const stylePaintsItem = useMemo(() => {
        if (isStyle) {
            const paints =
                selectionStroke?.paintState.paintStyleNode?.paints?.filter((p) => p.visible && !isNear(p.opacity, 0)) ??
                []
            const styleName = selectionStroke?.paintState.paintStyleNode?.name
            const variables = selectionStroke?.paintState.paintStyleNode?.variables ?? []
            return { styleName, paints, variables }
        } else {
            return undefined
        }
    }, [
        isStyle,
        selectionStroke?.paintState.paintStyleNode?.name,
        selectionStroke?.paintState.paintStyleNode?.paints,
        selectionStroke?.paintState.paintStyleNode?.variables,
    ])
    const paints = selectionStroke?.paintState.strokes.filter((p) => p.visible && !isNear(p.opacity, 0)) ?? []
    const paintLength = stylePaintsItem?.paints?.length ?? paints.length ?? 0

    if ((paintLength == 0 && !isStyle) || weightIsZero) {
        return <></>
    }
    return (
        <div className={style.panel} data-testid="design-mode-inspect-stroke">
            <div className={style.panelTitle}>
                <div className={style.title} data-testid="inspect-stroke">
                    {translation('Borders')}
                </div>
                <div className={style.panelCopyActionControls}>
                    <ColorModePicker></ColorModePicker>
                </div>
            </div>

            <div className={classnames(style.propertyColorRow, style.borderPropertyRow, style.basePropertyRow)}>
                <span className={style.borderIcon}>
                    <IconStrokeWeight />
                </span>
                <div className={classnames(style.properyPairsContainer, style.borderWeightValue)}>
                    <CopyableRow copyValue={weightValueStr}>
                        <span className={style.paintColor}>{weightValueStr}</span>
                    </CopyableRow>
                </div>
                <div className={style.properyPairsContainer}>
                    <CopyableRow copyValue={strokeAlignToShow[alignState?.value as StrokeAlign]}>
                        <span>{!isNil(alignState?.value) && strokeAlignToShow[alignState?.value as StrokeAlign]}</span>
                    </CopyableRow>
                </div>
            </div>
            {stylePaintsItem && (
                <div className="px-4">
                    <StyleColorsV2 items={[stylePaintsItem]} colorSpace={colorSpace} isStroke maxWidth={216} />
                </div>
            )}
            {!stylePaintsItem && (
                <div className="px-4">
                    <UnStyleColorsV2
                        paints={paints ?? []}
                        colorSpace={colorSpace}
                        variables={selectionStroke?.paintState.variables ?? []}
                        isStroke
                        maxWidth={216}
                    />
                </div>
            )}
        </div>
    )
}
