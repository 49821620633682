type Listener = (...args: any[]) => void

export class EventEmitter {
    private listeners: Map<string, Listener[]> = new Map()

    on(event: string, listener: Listener) {
        const listeners = this.listeners.get(event) || []
        listeners.push(listener)
        this.listeners.set(event, listeners)
    }

    emit(event: string, ...args: any[]) {
        if (!this.listeners.has(event)) {
            return
        }

        const listeners = this.listeners.get(event) || []
        listeners.slice().forEach((listener) => listener(...args))
    }

    off(event: string, listener: Listener) {
        if (!this.listeners.has(event)) {
            return
        }

        const listeners = this.listeners.get(event)!
        this.listeners.set(
            event,
            listeners.filter((l) => l !== listener)
        )
    }

    clear() {
        this.listeners.clear()
    }
}
