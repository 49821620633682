/* eslint-disable no-restricted-imports */
import { groupBy, isNil } from 'lodash-es'
import { getFontNameUid } from '../../document/util/font'
import { environment } from '../../environment'
import { WkCLog } from '../../kernel/clog/wukong/instance'
import { GetAppVersion } from '../../kernel/request/app-version'
import { isVersionGreaterThanOrEqualTo } from '../../utils/version'

import {
    LOCAL_FONT_FILE_SERVER_URL,
    LOCAL_FONT_LIST_SERVER_URL,
    LOCAL_FONT_LIST_SERVER_URL_V1,
    LOCAL_FONT_LIST_SERVER_URL_V2,
} from './font-config'
import {
    LocalFontPluginStatus,
    type FontInfoExt,
    type FontNameExt,
    type FontPluginVersionInfo,
    type LocalFontInfo,
    type LocalFonts,
    type LocalFontsAndAppVersion,
} from './interface'
import { parseVariableFontStyle } from './util'

const getLocalStyle = (localFontInfos: LocalFontInfo[]): FontNameExt[] => {
    const styles: FontNameExt[] = []

    const variableTypefacesMap = new Map<string, LocalFontInfo>()

    localFontInfos.forEach((fontInfo) => {
        if (!fontInfo.variable) {
            styles.push(fontInfo)
            return
        } else {
            /** 获取相同 style 的可变字体包中的实例最多的字体 map, 避免 style 相同但来源不同情况下不能注册字体导致的渲染问题 */
            const key = getFontNameUid(fontInfo)
            if (variableTypefacesMap.has(key)) {
                const oldFontInfo = variableTypefacesMap.get(key)!
                if (oldFontInfo.variationInstances.length < fontInfo.variationInstances.length) {
                    variableTypefacesMap.set(key, fontInfo)
                }
            } else {
                variableTypefacesMap.set(key, fontInfo)
            }
        }
    })

    variableTypefacesMap.forEach((fontInfo) => {
        fontInfo.variationInstances.forEach((instance) => {
            styles.push(
                parseVariableFontStyle(fontInfo.variationInstances, fontInfo.variationAxes, {
                    ...fontInfo,
                    style: instance.name,
                    localizedStyle: instance.localizedName,
                })
            )
        })
    })

    const uniqueStyles = Object.values(
        styles.reduce((acc, current) => {
            const key = getFontNameUid(current)

            if (!acc[key] || (!acc[key].variable && current.variable)) {
                acc[key] = current
            }

            return acc
        }, {} as Record<string, FontNameExt>)
    )

    return uniqueStyles
}

export const formatLocalFontInfo = (localFontInfos: LocalFontInfo[]): FontInfoExt[] => {
    if (environment.isAbroad) {
        // 数据来源处统一处理: 海外版强制使用英文名family + style
        localFontInfos.forEach((fontInfo) => {
            fontInfo.localizedFamily = fontInfo.family
            fontInfo.localizedStyle = fontInfo.style
        })
    }

    const familyGroup = groupBy(localFontInfos, (f) => f.family)

    const fontInfos: FontInfoExt[] = Object.keys(familyGroup)
        // 不允许使用Apple Color Emoji字体，否则会导致使用该字体的文本无法正常渲染渲染emoji
        .filter((family) => family !== 'Apple Color Emoji')
        .map((family: string) => {
            const infos = familyGroup[family]
            return {
                family: family,
                localizedFamily: infos[0].localizedFamily,
                styles: getLocalStyle(infos),
            }
        })

    return fontInfos
}

export const getLocalFontFileUrl = (path?: string) => {
    return path ? LOCAL_FONT_FILE_SERVER_URL + '?path=' + encodeURIComponent(path) : ''
}

const HTTP_TIMEOUT = 15000
const createAbortController = (duration: number) => {
    const controller = new AbortController()
    const timerId = setTimeout(() => controller.abort(), duration)

    return { signal: controller.signal, clear: () => clearTimeout(timerId) }
}

export const fetchLocalFonts = (): Promise<LocalFonts | null> => {
    const { signal, clear } = createAbortController(HTTP_TIMEOUT)

    return fetch(LOCAL_FONT_LIST_SERVER_URL(), { signal })
        .then((resp) => resp.json() as any as LocalFonts)
        .catch(() => {
            return fetch(LOCAL_FONT_LIST_SERVER_URL_V1, { signal })
                .then((resp) => resp.json() as any as LocalFonts)
                .catch(() => null)
        })
        .finally(() => clear())
}
/**
 *
 * @returns 获取本地字体列表
 */
export const fetchLocalFontInfo = (): Promise<LocalFontsAndAppVersion> => {
    WkCLog.log(`fetchLocalFontInfo start, url = ${LOCAL_FONT_LIST_SERVER_URL_V2}`)
    const startTime = Date.now()
    const { signal, clear } = createAbortController(HTTP_TIMEOUT)
    const isMac = navigator.userAgent.includes('Mac')

    const fetchFont = (
        url: string,
        catchCallback?: () => Promise<Omit<LocalFontsAndAppVersion, 'pluginVersionInfo'>>
    ) => {
        return fetch(url, { signal })
            .then((resp) => resp.json() as any as LocalFonts)
            .then((resp: LocalFonts) => {
                WkCLog.log(
                    `fetchLocalFontInfo end, url = ${url}, duration = ${Date.now() - startTime}, fontInfo size = ${
                        resp?.fontInfos?.length
                    }`
                )
                if (resp && Array.isArray(resp.fontInfos)) {
                    return {
                        fonts: formatLocalFontInfo(resp.fontInfos),
                        appVersion: resp.appVersion ?? (isMac ? '1.2.0' : '0.7.0'),
                        pluginStatus: LocalFontPluginStatus.INSTALLED,
                    }
                }
                WkCLog.log(`fetchLocalFontInfo fail, url = ${url}, resp = ${JSON.stringify(resp)}`)
                return {
                    fonts: [],
                    appVersion: resp.appVersion ?? (isMac ? '1.2.0' : '0.7.0'),
                    pluginStatus: LocalFontPluginStatus.BROKEN,
                }
            })
            .catch((e) => {
                if (catchCallback) {
                    return catchCallback()
                } else {
                    WkCLog.log(`fetchLocalFontInfo fail, url = ${url}, error = ${e}`)
                    return {
                        fonts: [],
                        appVersion: undefined,
                        pluginStatus: LocalFontPluginStatus.NOT_FOUND,
                    }
                }
            })
            .finally(() => clear())
    }

    const fetchLocalFontsPromise = fetchFont(LOCAL_FONT_LIST_SERVER_URL_V2, () => {
        return fetchFont(LOCAL_FONT_LIST_SERVER_URL_V1)
    })

    return fetchLocalFontsPromise.then((resp) => {
        return getFontPluginNewVersionData(resp.appVersion)
            .then((versionInfo) => {
                if (!isNil(resp.appVersion) && versionInfo.upgradeType == 'force') {
                    resp.fonts = []
                }
                return { ...resp, pluginVersionInfo: versionInfo }
            })
            .catch(() => {
                return {
                    ...resp,
                    pluginStatus: LocalFontPluginStatus.SILENT_IGNORE, // 静默处理
                    pluginVersionInfo: {
                        versionUrl: '',
                        versionDesc: '',
                        showReminder: false,
                        upgradeType: 'none',
                    },
                }
            })
    })
}

/**
 * 获取最新版本地字体插件信息， 并返回是否需要更新版本/下载新版本
 */

export async function getFontPluginNewVersionData(appVersion: string | undefined) {
    if (window.localBridge) {
        return {
            versionUrl: '',
            versionDesc: '',
            showReminder: false,
            upgradeType: 'none',
        } as FontPluginVersionInfo
    }

    const isMac = navigator.userAgent.includes('Mac')
    const productId = environment.isAbroad ? (isMac ? 41000015 : 41000016) : isMac ? 41000013 : 41000014
    const platform = isMac ? 'mac' : 'win'

    return new GetAppVersion(productId, appVersion ?? '0.0.0', platform).start().then((data) => {
        const version = data?.currentVersion?.version
        const installUrl = data?.currentVersion?.url
        const forceUpdate = data?.alert?.forceUpdate ?? false
        // 当前有安装 && 安装的版本<当前版本 && !forceUpdate
        const optionUpgrade =
            !isNil(version) &&
            !isNil(installUrl) &&
            !isNil(appVersion) &&
            !forceUpdate &&
            !isVersionGreaterThanOrEqualTo(appVersion, version)
        return {
            versionUrl: installUrl,
            versionDesc: data?.alert?.alertText ?? '',
            showReminder: optionUpgrade,
            upgradeType: forceUpdate ? 'force' : optionUpgrade ? 'option' : 'none',
        } as FontPluginVersionInfo
    })
}
