/**
 * @owner: jiangzg@kanyun.com
 */
import { useCallback, useMemo, useState } from 'react'
import { environment } from '../../../../app/src/environment'
// eslint-disable-next-line import/no-restricted-paths
import { Feedbacks } from '../../../../app/src/kernel/request/feedback'
import { getMotiffName, isValidEmail } from '../../../../util/src'
import { InputV2 } from '../inputs'
import { WKButton } from '../wk-button/button'
import { WKDialog } from '../wk-dialog'
import { WKPopover } from '../wk-popover'
import { WKToast } from '../wk-toast'
import classes from './feedback.module.less'
import { translation } from './feedback.translation'
import qrCodeImg from './images/motiff-feedback-qr-code.webp'
// eslint-disable-next-line import/no-restricted-paths
import { UserVOV2 } from '../../../../app/src/kernel/interface/account'
// eslint-disable-next-line import/no-restricted-paths
import { CollectInfoRequest } from '../../../../app/src/kernel/request/collect-info'
// eslint-disable-next-line import/no-restricted-paths
import { Sentry } from '../../../../app/src/kernel/sentry'

export interface FeedbackPropsV2 {
    isOpen: boolean
    setIsOpen: (nextIsOpen: boolean) => void
    userInfo: UserVOV2
}

export function FeedBack(props: FeedbackPropsV2) {
    const { isOpen } = props

    const {
        isLengthOverflow,
        maxSize,
        disabled,
        email,
        emailError,
        description,
        onChangeEmail,
        onBlurEmail,
        onChangeDescription,
        sendFeedback,
        handleClose,
    } = useFeedback(props)

    const isAbroad = environment.isAbroad

    return (
        <WKDialog
            title={translation('SendUsA')}
            visible={isOpen}
            showTitle={true}
            width={400}
            onCancel={handleClose}
            footer={null}
            bodyStyle={{ padding: 0 }}
        >
            <div className="relative flex p-6 pb-4">
                <div className="flex flex-col w-full">
                    <div className="w-full">
                        <InputV2
                            size="medium"
                            placeholder={translation('Email')}
                            value={email}
                            onChange={onChangeEmail}
                            onBlur={onBlurEmail}
                            error={{ show: emailError, tipMessage: translation('InvalidEmail') }}
                            dataTestIds={{
                                input: 'feedback-email',
                            }}
                        />
                    </div>
                    <div className="mt-5 w-full">
                        <InputV2.Textarea
                            height={148}
                            placeholder={translation('YourMessage')}
                            value={description}
                            onChange={onChangeDescription}
                            autoFocus
                            dataTestIds={{
                                textarea: 'feedback-description',
                            }}
                        />
                    </div>
                    {isLengthOverflow ? (
                        <div className={classes.sizeTip}>{`${description.length}/${maxSize}`}</div>
                    ) : null}
                    <div className="flex justify-between items-center mt-25px">
                        <div>{!isAbroad && <ContactUsInfo />}</div>
                        <WKButton
                            onClick={sendFeedback}
                            type="primary"
                            disabled={disabled}
                            dataTestId="feedback-submit-btn"
                        >
                            {translation('Send')}
                        </WKButton>
                    </div>
                </div>
            </div>
        </WKDialog>
    )
}

const ContactUsInfo = () => {
    return (
        <WKPopover
            placement="bottom-center"
            contents={
                <div className={classes.qrCode}>
                    <img alt={translation('qrCodeAlt')} src={qrCodeImg} data-testid={'feedback-contact-qrcode'} />
                </div>
            }
            contentClassName="p-0"
            triggerTestId="feedback-contact-us-info"
        >
            <span className={classes.link}>
                <a>{translation('ContactUs', { name: getMotiffName() })}</a>
            </span>
        </WKPopover>
    )
}

function useFeedback(props: FeedbackPropsV2) {
    const { setIsOpen, userInfo } = props

    const [email, setEmail] = useState(userInfo.userBrief.email)
    const [emailError, setEmailError] = useState(false)
    const [description, setDescription] = useState('')
    const maxSize = 5000

    const disabled = description.length < 1 || description.length > maxSize || email.length < 1

    const onChangeEmail = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        setEmail((e.target as HTMLInputElement).value)
        setEmailError(false)
    }, [])

    const onBlurEmail = useCallback(() => {
        setEmailError(!isValidEmail(email))
    }, [email])

    const onChangeDescription = useCallback((e: React.FormEvent<HTMLTextAreaElement>) => {
        setDescription((e.target as HTMLTextAreaElement).value)
    }, [])

    const isLengthOverflow = useMemo(() => {
        return description.length > maxSize
    }, [description.length, maxSize])

    const handleClose = useCallback(() => {
        setIsOpen(false)
        setDescription('')
    }, [setIsOpen])

    const sendFeedback = useCallback(() => {
        if (!isValidEmail(email)) {
            setEmailError(true)
            return
        }

        const params = {
            username: userInfo.userBrief.nickname,
            userEmail: userInfo.userBrief.email,
            contactEmail: email,
            description,
        }

        fetchFeedback(params, environment.isAbroad)
            .then(() => {
                WKToast.show(translation('SendSucceeded'))
            })
            .catch((e) => {
                Sentry.captureException(e)
            })

        handleClose()
    }, [description, email, handleClose, userInfo.userBrief.email, userInfo.userBrief.nickname])

    return {
        isLengthOverflow,
        maxSize,
        disabled,
        email,
        emailError,
        description,
        onChangeEmail,
        onBlurEmail,
        onChangeDescription,
        sendFeedback,
        handleClose,
    }
}

interface AbroadFeedbackParam {
    username: string
    contactEmail: string
    userEmail: string
    description: string
}

function fetchFeedback(params: AbroadFeedbackParam, isAbroad: boolean) {
    if (isAbroad) {
        return new CollectInfoRequest({
            scene: 'abroadFeedback',
            submitData: JSON.stringify(params),
        }).start()
    } else {
        // 国内的反馈走 atom，只需要 contactEmail 和 description
        const reqJson = JSON.stringify({
            email: params.contactEmail,
            description: params.description,
        })
        return new Feedbacks(reqJson).start()
    }
}
