import { FC } from 'react'
import { Skeleton } from '../../../../../main/loading-container/skeleton'
import styles from './right.module.less'

export const RightAreaSkeleton: FC = () => {
    return (
        <div className={styles['right-area-container']}>
            <div className={styles['skeleton-top-container']}>
                {[0, 1, 2].map((key) => (
                    <Skeleton className={styles.skeleton} type="rectangle" width="32" key={key} />
                ))}
            </div>
            <div className={styles['skeleton-middle-container']}>
                {['48', '184'].map((width, index) => (
                    <Skeleton className={styles.skeleton} type="rectangle" width={width} key={index} />
                ))}
            </div>
            <div className={styles['skeleton-bottom-container']}>
                {['136', '104', '104', '64', '104'].map((width, index) => (
                    <div className={styles['skeleton-box']} key={index}>
                        <Skeleton type="round" />
                        <Skeleton className={styles.skeleton} type="rectangle" width={width} />
                    </div>
                ))}
            </div>
        </div>
    )
}
