import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    SwapLibrary: '替换组件库',
    Unknown: '未知组件库',
    SwapLibrary_synonyms1: '替换库',
    ChooseLibrary: '选择库',
} as const

export const enTranslation = {
    SwapLibrary: 'Swap library',
    Unknown: 'Unknown',
    SwapLibrary_synonyms1: 'Swap library',
    ChooseLibrary: 'Choose library',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
