/* eslint-disable no-restricted-imports */
import {
    DevModeLayerPanelCancelHoverCommand,
    DevModeLayerPanelCommandClickWasmCall,
    DevModeLayerPanelEnterHoverCommand,
    DevModeLayerPanelLeftClickWasmCall,
    DevModeLayerPanelMoveToSelectedNode,
    DevModeLayerPanelRightClickWasmCall,
    DevModeLayerPanelShiftClickWasmCall,
    Wukong,
} from '@wukong/bridge-proto'
import classNames from 'classnames'
import { FC, MouseEventHandler, useCallback, useMemo } from 'react'
import { isWindows } from '../../../../../../kernel/util/ua'
import { EditorDataTestId } from '../../../../../../window'
import { useCommand } from '../../../../../context/document-context'
import { blurActiveElement } from '../../../../../utils/active-element'
import { RightClickMenuWrapper } from '../../../../right-click-menu-wrapper'
import { DevModeLayerPanelAssetPreview } from '../dev-mode-render-item-asset-preview'
import { DevModeLayerPanelRenderItemSizeInfo } from '../dev-mode-render-item-size-info'
import { DevModeLayerPanelRenderItemTextContent } from '../dev-mode-render-item-text-content'
import { DevModeLayerPanelIcon } from '../render-item-icon'
import { DevModeLayerPanelIndent } from '../render-item-indent'
import { LayerPanelRenderItemName } from '../render-item-name'
import { RenderItemBackground } from '../type'
import styles from './index.module.less'

interface LayerPanelRenderItemProps {
    item: Wukong.DocumentProto.VDevModeLayerPanelNode
    index: number
}

function getRenderItemBackground(item: Wukong.DocumentProto.VDevModeLayerPanelNode): RenderItemBackground {
    if (item.isSelected) {
        return RenderItemBackground.Selected
    }
    if (item.isAncestorSelected) {
        return RenderItemBackground.AncesterSelected
    }
    return RenderItemBackground.None
}

export const LayerPanelRenderItem: FC<LayerPanelRenderItemProps> = ({ item, index }) => {
    const command = useCommand()

    // 处理 renderItem 的鼠标事件
    const handleMouseDown: MouseEventHandler<HTMLDivElement> = useCallback(
        (event) => {
            blurActiveElement()
            const { button, ctrlKey, metaKey, shiftKey } = event
            // 响应左键点击
            if (button === 0 && (!ctrlKey || isWindows())) {
                // command 点击
                // windows 上为 ctrl 点击
                if ((!isWindows() && metaKey) || (isWindows() && ctrlKey)) {
                    command.DEPRECATED_invokeBridge(
                        DevModeLayerPanelCommandClickWasmCall,
                        Wukong.DocumentProto.BridgeProtoString.create({
                            value: item.id,
                        })
                    )
                    command.commitUndo()
                    return
                }
                // shift 点击
                if (shiftKey) {
                    command.DEPRECATED_invokeBridge(
                        DevModeLayerPanelShiftClickWasmCall,
                        Wukong.DocumentProto.BridgeProtoString.create({
                            value: item.id,
                        })
                    )
                    command.commitUndo()
                    return
                }
                // 普通点击，只在非 select 状态下响应 mouseDown 点击
                if (!item.isSelected) {
                    command.DEPRECATED_invokeBridge(
                        DevModeLayerPanelLeftClickWasmCall,
                        Wukong.DocumentProto.BridgeProtoString.create({
                            value: item.id,
                        })
                    )
                    command.commitUndo()
                }

                // 尝试移动视窗以在画布区内显示选中的节点
                command.DEPRECATED_invokeBridge(
                    DevModeLayerPanelMoveToSelectedNode,
                    Wukong.DocumentProto.BridgeProtoString.create({ value: item.id })
                )

                return
            }
            // 响应右键点击
            // windows 不支持 ctrl+点击 唤起右键菜单
            if ((button === 0 && ctrlKey && !isWindows()) || button === 2) {
                command.DEPRECATED_invokeBridge(
                    DevModeLayerPanelRightClickWasmCall,
                    Wukong.DocumentProto.BridgeProtoString.create({
                        value: item.id,
                    })
                )
            }
        },
        [command, item.id, item.isSelected]
    )

    // 点击选中节点，取消非选中节点的选中
    const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(
        (event) => {
            const { button, metaKey, shiftKey, ctrlKey } = event
            if ((item.isSelected || item.isAncestorSelected) && !metaKey && !shiftKey && button === 0 && !ctrlKey) {
                command.DEPRECATED_invokeBridge(
                    DevModeLayerPanelLeftClickWasmCall,
                    Wukong.DocumentProto.BridgeProtoString.create({
                        value: item.id,
                    })
                )
                command.commitUndo()
            }
        },
        [command, item.id, item.isAncestorSelected, item.isSelected]
    )

    const handleMouseEnter = useCallback(() => {
        command.DEPRECATED_invokeBridge(
            DevModeLayerPanelEnterHoverCommand,
            Wukong.DocumentProto.BridgeProtoString.create({
                value: item.id,
            })
        )
    }, [command, item.id])

    const handleMouseLeave = useCallback(() => {
        command.DEPRECATED_invokeBridge(DevModeLayerPanelCancelHoverCommand)
    }, [command])

    const background = useMemo<RenderItemBackground>(() => getRenderItemBackground(item), [item])

    return useMemo(
        () => (
            <RightClickMenuWrapper
                source={Wukong.DocumentProto.RightClickSource.RIGHT_CLICK_SOURCE_DEV_MODE_LAYER_PANEL}
                stopPropagation
                dataTestId={EditorDataTestId.LayerPanelContextMenu}
            >
                <div
                    className={classNames(styles.layerPanelRenderItem, styles[background], {
                        [styles.hovered]: !item.isSelected && item.isHovered,
                    })}
                    data-testid={`layer-panel-render-item-${index}`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onMouseDown={handleMouseDown}
                    onClick={handleClick}
                >
                    {/* 层级占位，同时负责展开收起的图标 */}
                    <DevModeLayerPanelIndent
                        indent={item.indent}
                        expandStatus={item.expandStatus}
                        id={item.id!}
                        isAsset={item.isCommonAsset || item.isImageAsset}
                    />
                    <DevModeLayerPanelAssetPreview
                        id={item.id!}
                        isImageAsset={item.isImageAsset}
                        isCommonAsset={item.isCommonAsset}
                        darkBackgroud={item.needDarkBackgroundForAsset}
                        useCoverFit={item.needCoverFitForAsset}
                    />
                    {/* 图标 icon */}
                    <DevModeLayerPanelIcon
                        nodeIcon={item.nodeIcon as Wukong.DocumentProto.NodeIconData}
                        isSelected={item.isSelected}
                        isPurple={item.isPurple}
                        isFontBold={item.isFontBold}
                        shouldShowIcon={item.shouldShowIcon}
                        isAsset={item.isCommonAsset || item.isImageAsset}
                    />
                    {/* 名称 */}
                    <LayerPanelRenderItemName
                        name={item.name}
                        isFontBold={item.isFontBold}
                        isPurple={item.isPurple}
                        isVariant={item.isVariant}
                    />
                    {/* 文本内容 */}
                    <DevModeLayerPanelRenderItemTextContent textContent={item.textContent} />
                    {/* 大小信息 */}
                    <DevModeLayerPanelRenderItemSizeInfo assetSizeInfo={item.assetSizeInfo} />
                </div>
            </RightClickMenuWrapper>
        ),
        [item, background, index, handleMouseEnter, handleMouseLeave, handleMouseDown, handleClick]
    )
}
