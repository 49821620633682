import { Wukong } from '@wukong/bridge-proto'
import { useState } from 'react'
import { ColorSpace } from '../../../design/blend/color-picker/utils/color-translate'
import { StylePaintRender } from '../paint-render-v2'
import { ViewAllV2 } from '../view-all-v2'
import InspectSelectionFillItem = Wukong.DocumentProto.IInspectSelectionFillItem

export const StyleColorsV2 = ({
    items,
    colorSpace,
    isStroke,
    dataTestId,
    maxWidth,
}: {
    items: InspectSelectionFillItem[]
    colorSpace: ColorSpace
    isStroke?: boolean
    dataTestId?: string
    maxWidth?: number
}) => {
    const _items = items.map((item) => ({
        ...item,
        paints: item.paints?.slice().reverse(),
    }))
    const [paintLimit, setPaintLimit] = useState(5)
    const allPaintsCount = _items.reduce((acc, item) => acc + (item.styleName ? 1 : item.paints!.length), 0)
    const expandAll = allPaintsCount == paintLimit

    let lastRenderItemCount = 0 // 最后一个的 InspectSelectionFillItem 需要展示的个数
    let itemLimit = expandAll ? _items.length : 0 // 需要渲染的 InspectSelectionFillItem 个数
    let renderPaintCnt = 0

    if (!expandAll) {
        for (const item of _items) {
            if (item.styleName) {
                renderPaintCnt++
            } else {
                if (item.paints!.length + renderPaintCnt >= paintLimit) {
                    lastRenderItemCount = paintLimit - renderPaintCnt
                    renderPaintCnt = paintLimit
                } else {
                    renderPaintCnt += item.paints!.length
                }
            }
            itemLimit++

            if (renderPaintCnt >= paintLimit) {
                break
            }
        }
    }

    return (
        <>
            <div className="flex flex-col gap-16px" data-testid={dataTestId}>
                {_items.slice(0, itemLimit).map((item, idx) => {
                    return (
                        <StylePaintRender
                            key={idx}
                            data={item}
                            colorSpace={colorSpace}
                            renderCount={
                                expandAll
                                    ? item.paints?.length ?? 0
                                    : idx == itemLimit - 1
                                    ? lastRenderItemCount
                                    : item.paints?.length ?? 0
                            }
                            isStroke={isStroke}
                            maxWidth={maxWidth}
                        />
                    )
                })}
            </div>
            <ViewAllV2
                limit={paintLimit}
                count={allPaintsCount}
                onChange={setPaintLimit}
                dataTestId="style-color-view-all"
            />
        </>
    )
}
