import { FeatureSwitchConfig, SwitchEnvironmentScopeType, SwitchStrategyType } from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'prototype-spring',
    owner: '',
    description: '',
    strategies: [
        {
            env: SwitchEnvironmentScopeType.TESTING,
            config: { type: SwitchStrategyType.ALL_USER },
        },
    ],
    alwaysDisableInTests: true,
}
export default Config
