import { useEffect, useState } from 'react'
import { Checkbox, DraggablePopupV2, WKButton } from '../../../../../ui-lib/src'
import { useLoadableCanOpenDesktop } from '../../../external-store/atoms/desktop-open'
import { openCurrentPageInDesktop } from '../../../main/desktop/desktop'
import { LocalStorageKey } from '../../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../../web-storage/local-storage/storage'
import style from './navigate-to-desktop-popup.module.less'
import { translation } from './navigate-to-desktop-popup.translation'

export function NavigateToDesktopPopup() {
    const [visible, setVisible] = useState<boolean>(
        !enhancedLocalStorage.getSerializedItem(LocalStorageKey.HideNavDesktopPopup)
    )

    const [selected, setSelected] = useState<boolean>(true)

    const loadableCanOpen = useLoadableCanOpenDesktop()

    const [top, setTop] = useState<number>(window.innerHeight - 120 - 24)
    const [left, setLeft] = useState<number>((window.innerWidth - 400) / 2)

    const onClose = () => {
        setVisible(false)
        enhancedLocalStorage.setSerializedItem(LocalStorageKey.HideNavDesktopPopup, true)
    }

    const onOk = () => {
        openCurrentPageInDesktop()
        enhancedLocalStorage.setSerializedItem(LocalStorageKey.AlwaysOpenInApp, selected)
        onClose()
    }

    useEffect(() => {
        const handleResize = () => {
            setTop(window.innerHeight - 120 - 24)
            setLeft((window.innerWidth - 400) / 2)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    if (
        loadableCanOpen.state === 'loading' ||
        loadableCanOpen.state === 'hasError' ||
        !loadableCanOpen.data ||
        window.localBridge
    ) {
        return null
    }

    return (
        <DraggablePopupV2
            visible={visible}
            header={translation('Title')}
            bodyClassName={style.popupBody}
            closable={true}
            footerClassName={style.footerContainer}
            position={{
                top,
                left,
            }}
            onCancel={onClose}
            footer={
                <div className={style.footerWrapper}>
                    <Checkbox
                        size="small"
                        label={translation('Preferance')}
                        checked={selected}
                        onChange={() => {
                            setSelected(!selected)
                        }}
                    />
                    <div>
                        <WKButton type="secondary" onClick={onClose} data-testid="draggable-popup-cancel">
                            {translation('Cancel')}
                        </WKButton>
                        <WKButton
                            type="primary"
                            className={style.okBtn}
                            onClick={onOk}
                            data-testid="draggable-popup-ok"
                        >
                            {translation('Confirm')}
                        </WKButton>
                    </div>
                </div>
            }
        ></DraggablePopupV2>
    )
}
