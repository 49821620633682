import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    UpdateOrSwitch: '升级或切换浏览器',
    DownloadOrUpgrade:
        '当前浏览器版本过低。为了保证你在 {{name}}的使用体验，请升级当前浏览器版本或切换至以下符合要求的浏览器。',
} as const

export const enTranslation = {
    UpdateOrSwitch: 'Update or switch browsers',
    DownloadOrUpgrade: 'Upgrade your current browser or switch to the following compliant browsers.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
