import React from 'react'

interface TabElementSpanProps extends React.HTMLAttributes<HTMLElement> {
    as?: 'span'
    disabled?: boolean
}
interface TabElementButtonProps extends React.ButtonHTMLAttributes<HTMLElement> {
    as?: 'button'
}

export type TabElementProps = TabElementSpanProps | TabElementButtonProps

export type TabElementRef = HTMLElement

export const TabElement = React.forwardRef(function Component(props: TabElementProps, ref: React.Ref<TabElementRef>) {
    return React.createElement(props.as ?? 'span', {
        ...props,
        ref,
        key: String(props.disabled),
        ...(props.as === 'button'
            ? { type: 'button' }
            : {
                  tabIndex: props.disabled ? -1 : 0,
              }),
        style: {
            position: 'absolute',
            pointerEvents: 'none',
            outline: 'none',
            border: 0,
            padding: 0,
            opacity: 0,
        },
        'data-forbid-shortcuts': true,
    })
})
