import { isMotiffApp } from '../../../../util/src'

declare global {
    interface Window {
        webkit?: {
            messageHandlers?: {
                motiffBridge?: {
                    postMessage?: (msg: MessageType) => void
                }
            }
        }
        androidMessenger?: {
            toggleFullscreen?: (value: boolean) => void
            toggleOrientationLock?: (value: boolean) => void
            showLaunchView?: () => void
            hideLaunchView?: () => void
            openPolicyFile?: (value: string) => void
            openKeybroadHeight?: (height: number) => void
            openLinkByNativeBrowser?: (value: string) => void
            requestGoogleAuth?: (value: string) => void
        }

        onMotiffBridgeCallback?: (data: string) => void
        onGrantCodeChange?: (grantCode: string) => void
    }
}

interface MessageType {
    methodName: string
    args: any
}

function postAppleMessage(msg: MessageType) {
    if (isMotiffApp() && window.webkit?.messageHandlers?.motiffBridge?.postMessage) {
        window.webkit.messageHandlers.motiffBridge.postMessage(msg)
    }
}

function postAndroidMessage(invoker: () => void) {
    if (isMotiffApp() && window.androidMessenger) {
        invoker()
    }
}

function decodeBase64(base64: string) {
    return new TextDecoder().decode(Uint8Array.from(window.atob(base64), (m) => m.codePointAt(0)!))
}

const createBridgeCallback = (() => {
    let uid = 0
    const callbackMap = new Map<string, (data: any) => void>()

    window.onMotiffBridgeCallback = (base64: string) => {
        try {
            const { callbackId, data } = JSON.parse(decodeBase64(base64)) as { callbackId: string; data: any }
            if (callbackMap.has(callbackId)) {
                callbackMap.get(callbackId)!(data)
                callbackMap.delete(callbackId)
            } else {
                console.error('onMotiffBridgeCallback invalid callbackId:', callbackId, data)
            }
        } catch (e) {
            console.error('onMotiffBridgeCallback invalid data:', e, base64)
        }
    }

    return <T>() => {
        uid = uid === Number.MAX_SAFE_INTEGER ? 1 : uid + 1
        const callbackId = String(uid)
        let deferedResolve: (data: T) => void
        const promise = new Promise<T>((resolve) => (deferedResolve = resolve))
        callbackMap.set(callbackId, (data: T) => deferedResolve(data))
        return { callbackId, promise } as const
    }
})()

export const jsbridge = {
    toggleFullscreen: (value: boolean) => {
        postAppleMessage({ methodName: 'toggleFullscreen', args: value })
        postAndroidMessage(() => {
            window.androidMessenger?.toggleFullscreen?.(value)
        })
    },

    toggleOrientationLock: (value: boolean) => {
        postAppleMessage({ methodName: 'toggleOrientationLock', args: value })
        postAndroidMessage(() => {
            window.androidMessenger?.toggleOrientationLock?.(value)
        })
    },

    showLaunchView: () => {
        postAppleMessage({ methodName: 'showLaunchView', args: true })
        postAndroidMessage(() => {
            window.androidMessenger?.showLaunchView?.()
        })
    },

    hideLaunchView: () => {
        postAppleMessage({ methodName: 'hideLaunchView', args: true })
        postAndroidMessage(() => {
            window.androidMessenger?.hideLaunchView?.()
        })
    },

    openPolicyFile: (url: string) => {
        postAndroidMessage(() => {
            window.androidMessenger?.openPolicyFile?.(url)
        })
    },

    openKeybroadHeight: (height: number) => {
        // 调起键盘的时候，通知 app 高度
        postAndroidMessage(() => {
            window.androidMessenger?.openKeybroadHeight?.(height)
        })
    },

    openLinkByNativeBrowser: (url: string) => {
        postAppleMessage({ methodName: 'openLinkByNativeBrowser', args: url })
        postAndroidMessage(() => {
            window.androidMessenger?.openLinkByNativeBrowser?.(url)
        })
    },

    requestGoogleAuth: (): Promise<{ success: boolean; token: string }> => {
        const { callbackId, promise } = createBridgeCallback<{ success: boolean; token: string }>()
        postAppleMessage({ methodName: 'requestGoogleAuth', args: { callbackId } })
        postAndroidMessage(() => {
            window.androidMessenger?.requestGoogleAuth?.(callbackId)
        })
        return promise
    },
}
