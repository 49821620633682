import {
    SetPrototypeAnimationEasingFunctionEditHistory,
    SetPrototypeAnimationEasingTypeEditHistory,
    SetPrototypeAnimationTransitionDurationEditHistory,
    SetPrototypeAnimationTransitionShouldSmartAnimateEditHistory,
    SetPrototypeAnimationTransitionTypeEditHistory,
    Wukong,
} from '@wukong/bridge-proto'
import { cloneDeep } from 'lodash-es'
import { safeCall } from '../../../../../../../ui-lib/src'
import { CommitType } from '../../../../../document/command/commit-type'
import { featureSwitchManager } from '../../../../../kernel/switch/core'
import { useCommand } from '../../../../context/document-context'
import { EventEmitter } from '../../../../utils/event-emmitter'
import type { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import type { ScrubbableInputProps } from '../../../atom/inputs/components/scrubbable-input'
import { parseString } from '../../../atom/inputs/utils/parse-string'
import type { Value } from '../../../atom/inputs/utils/type'
import { MIXED_TYPE, PrototypeInteractionWithNodeId } from '../../prototype-interaction/constants'
import type { CubicBezierNumber } from './action-animation-preview'
import {
    getSpringFunctionByDurationOffset,
    getSpringStableDurationByFunc,
    isSpringEasingType,
} from './animation-easing-function/spring-util'

import EasingType = Wukong.DocumentProto.EasingType

export interface AnimationTransitionTypeCommandProps {
    data: PrototypeInteractionWithNodeId[]
    onChange: (
        data: PrototypeInteractionWithNodeId[],
        options?: {
            commitType: CommitType
        }
    ) => void
    actionIndex: number
    springEventEmitter: EventEmitter
}

export function useAnimationTransitionTypeCommand(props: AnimationTransitionTypeCommandProps) {
    const command = useCommand()
    const onChange = (value: Wukong.DocumentProto.TransitionType | typeof MIXED_TYPE) => {
        if (value === MIXED_TYPE) {
            return
        }

        command.DEPRECATED_invokeBridge(SetPrototypeAnimationTransitionTypeEditHistory, { value })
        props.onChange(
            cloneDeep(props.data).map((interaction) => {
                const action = interaction.actions[props.actionIndex]
                action.transitionType = value
                if (!action.easingFunction?.length) {
                    const newEasingFunction = getDefaultEasingFunctionByType(action.easingType!)
                    action.easingFunction = newEasingFunction ?? []
                }
                return interaction
            })
        )
    }

    return { onChange }
}

export function getDefaultEasingFunctionByType(easingType: EasingType): CubicBezierNumber | undefined {
    if (featureSwitchManager.isEnabled('prototype-spring')) {
        return getDefaultEasingFunctionByTypeV2(easingType)
    }
    return getDefaultEasingFunctionByTypeV1(easingType)
}

function getDefaultEasingFunctionByTypeV1(easingType: EasingType): CubicBezierNumber | undefined {
    switch (easingType) {
        case EasingType.EASING_TYPE_IN_CUBIC:
            return [0.42, 0, 1, 1]
        case EasingType.EASING_TYPE_OUT_CUBIC:
            return [0, 0, 0.58, 1]
        case EasingType.EASING_TYPE_INOUT_CUBIC:
            return [0.42, 0, 0.58, 1]
        case EasingType.EASING_TYPE_LINEAR:
            return [0, 0, 1, 1]
        case EasingType.EASING_TYPE_IN_BACK_CUBIC:
            return [0.3, -0.05, 0.7, -0.5]
        case EasingType.EASING_TYPE_OUT_BACK_CUBIC:
            return [0.45, 1.45, 0.8, 1]
        case EasingType.EASING_TYPE_INOUT_BACK_CUBIC:
            return [0.7, -0.4, 0.4, 1.4]
        case EasingType.EASING_TYPE_CUSTOM_CUBIC:
            return undefined
        default:
            throw new Error(`[getDefaultEasingFunctionByType] Unsupported easing type: ${easingType}`)
    }
}

function getDefaultEasingFunctionByTypeV2(easingType: EasingType): CubicBezierNumber | undefined {
    switch (easingType) {
        case EasingType.EASING_TYPE_IN_CUBIC:
            return [0.42, 0, 1, 1]
        case EasingType.EASING_TYPE_OUT_CUBIC:
            return [0, 0, 0.58, 1]
        case EasingType.EASING_TYPE_INOUT_CUBIC:
            return [0.42, 0, 0.58, 1]
        case EasingType.EASING_TYPE_LINEAR:
            return [0, 0, 1, 1]
        case EasingType.EASING_TYPE_IN_BACK_CUBIC:
            return [0.3, -0.05, 0.7, -0.5]
        case EasingType.EASING_TYPE_OUT_BACK_CUBIC:
            return [0.45, 1.45, 0.8, 1]
        case EasingType.EASING_TYPE_INOUT_BACK_CUBIC:
            return [0.7, -0.4, 0.4, 1.4]
        case EasingType.EASING_TYPE_CUSTOM_CUBIC:
            return [0, 0, 0.58, 1]

        case EasingType.EASING_TYPE_GENTLE_SPRING:
            return [1, 100, 15, 0]
        case EasingType.EASING_TYPE_SPRING_PRESET_ONE:
            return [1, 300, 20, 0]
        case EasingType.EASING_TYPE_SPRING_PRESET_TWO:
            return [1, 600, 15, 0]
        case EasingType.EASING_TYPE_SPRING_PRESET_THREE:
            return [1, 80, 20, 0]
        case EasingType.EASING_TYPE_CUSTOM_SPRING:
            return [1, 100, 15, 0]
        default:
            throw new Error(`[getDefaultEasingFunctionByType] Unsupported easing type: ${easingType}`)
    }
}

export function useAnimationEasingTypeCommand(
    props: AnimationTransitionTypeCommandProps,
    state: Wukong.DocumentProto.IEasingTypePropValue
) {
    const command = useCommand()
    const onChange = (newValue: EasingType | typeof MIXED_TYPE, isCurrentEasingTypeMixed: boolean) => {
        if (newValue === MIXED_TYPE) {
            return
        }

        let newEasingFunction: CubicBezierNumber | undefined
        if (
            newValue === EasingType.EASING_TYPE_CUSTOM_CUBIC &&
            (isCurrentEasingTypeMixed || isSpringEasingType(state.value))
        ) {
            newEasingFunction = getDefaultEasingFunctionByType(EasingType.EASING_TYPE_OUT_CUBIC)!
        } else if (newValue === EasingType.EASING_TYPE_CUSTOM_SPRING) {
            newEasingFunction =
                !isCurrentEasingTypeMixed && isSpringEasingType(state.value)
                    ? undefined
                    : getDefaultEasingFunctionByType(EasingType.EASING_TYPE_CUSTOM_SPRING)
        } else {
            if (featureSwitchManager.isEnabled('prototype-spring')) {
                // NOTE: 保留easingFunction，不设置为默认值
                newEasingFunction =
                    newValue === EasingType.EASING_TYPE_CUSTOM_CUBIC
                        ? undefined
                        : getDefaultEasingFunctionByTypeV2(newValue)
            } else {
                newEasingFunction = getDefaultEasingFunctionByType(newValue)
            }
        }

        command.DEPRECATED_invokeBridge(SetPrototypeAnimationEasingTypeEditHistory, { value: newValue })
        if (newEasingFunction) {
            command.DEPRECATED_invokeBridge(SetPrototypeAnimationEasingFunctionEditHistory, {
                value: newEasingFunction,
            })
        }

        props.onChange(
            cloneDeep(props.data).map((interaction) => {
                const action = interaction.actions[props.actionIndex]
                const currentEasingType = action.easingType!
                action.easingType = newValue

                if (newEasingFunction) {
                    action.easingFunction = newEasingFunction
                    if (isSpringEasingType(newValue)) {
                        // 设置为 spring 后，需要根据 spring 的稳定时间来设置 duration
                        action.transitionDuration = Math.round(getSpringStableDurationByFunc(newEasingFunction) * 1000)
                    }
                } else if (action.easingFunction?.length !== 4) {
                    action.easingFunction = getDefaultEasingFunctionByType(currentEasingType) || []
                }
                return interaction
            })
        )

        if (isSpringEasingType(newValue)) {
            props.springEventEmitter.emit('startSpringPreview')
        }
    }

    return { onChange }
}

export function useAnimationTransitionDurationCommand(
    props: AnimationTransitionTypeCommandProps
): ScrubbableInputProps {
    const command = useCommand()
    const formatter = {
        parse(inputValue: string, oldValue?: Value) {
            const formatInputValue = (value: string) => {
                value = value.trim()
                const result = value.replace(/m?s/gi, '')
                let scale = 1
                if (value.match(/\d+(.\d+)?\s*s/)) {
                    scale = 1000
                }

                return {
                    value: result,
                    scale,
                }
            }

            if (Array.isArray(oldValue)) {
                const inputValues = inputValue.split(',')
                return oldValue.map((v, index) => {
                    const result = formatInputValue(inputValues[index] ?? inputValues[0])
                    return Math.round(parseString(result.value, v) * result.scale)
                })
            } else {
                const result = formatInputValue(inputValue)
                return Math.round(parseString(result.value, oldValue) * result.scale)
            }
        },
        format: (value: Value) => (Array.isArray(value) ? value.map((v) => `${v}ms`).join(',') : `${value}ms`),
    }

    const onChange = (value: Value) => {
        if (typeof value !== 'number') {
            return
        }

        command.DEPRECATED_invokeBridge(SetPrototypeAnimationTransitionDurationEditHistory, { value })
        props.onChange(
            cloneDeep(props.data).map((interaction) => {
                const action = interaction.actions[props.actionIndex]
                action.transitionDuration = value
                if (isSpringEasingType(action.easingType) && action.easingFunction?.length === 4) {
                    action.easingFunction = getSpringFunctionByDurationOffset(
                        action.easingFunction as [number, number, number, number],
                        value / 1000
                    )
                }
                return interaction
            })
        )

        props.springEventEmitter.emit('startSpringPreview')
    }

    const mixedMathHandler = {
        getScrubStartValueMixed: () =>
            new Map<string, Value>(
                props.data.map((interaction) => [
                    interaction.id!,
                    interaction.actions[props.actionIndex].transitionDuration!,
                ])
            ),
        onScrubChangeAllMixed: (values: Map<string, Value>) => {
            const result = Object.fromEntries(values) as Record<string, number>
            props.onChange(
                cloneDeep(props.data).map((interaction) => {
                    const action = interaction.actions[props.actionIndex]
                    action.transitionDuration = result[interaction.id!]
                    return interaction
                })
            )
        },
        onChangeMixed: (parse: (value: any) => void, options?: InputOptionsForUndoSquash) => {
            const res: Record<string, number> = {}
            new Map<string, number>(
                props.data.map((interaction) => [
                    interaction.id!,
                    interaction.actions[props.actionIndex].transitionDuration!,
                ])
            ).forEach((value, key) => {
                res[key] = safeCall(parse, value) ?? value
            })
            props.onChange(
                cloneDeep(props.data).map((interaction) => {
                    const action = interaction.actions[props.actionIndex]
                    action.transitionDuration = safeCall(parse, action.transitionDuration) ?? action.transitionDuration
                    return interaction
                }),
                options
                    ? {
                          commitType: options.commitType,
                      }
                    : undefined
            )
        },
    }

    return {
        formatter,
        onChange,
        mixedMathHandler,
    }
}

export function useAnimationShouldSmartAnimateCommand(props: AnimationTransitionTypeCommandProps) {
    const command = useCommand()
    const onChange = (value: boolean) => {
        command.DEPRECATED_invokeBridge(SetPrototypeAnimationTransitionShouldSmartAnimateEditHistory, { value })
        props.onChange(
            cloneDeep(props.data).map((interaction) => {
                const action = interaction.actions[props.actionIndex]
                action.transitionShouldSmartAnimate = value
                return interaction
            })
        )
    }

    return { onChange }
}

export function useAnimationEasingFunctionCommand(props: AnimationTransitionTypeCommandProps) {
    const command = useCommand()
    const onChangeCubicBezier = (easingFunction: number[], options?: InputOptionsForUndoSquash) => {
        command.DEPRECATED_invokeBridge(SetPrototypeAnimationEasingFunctionEditHistory, {
            value: easingFunction,
        })

        props.onChange(
            cloneDeep(props.data).map((interaction) => {
                const action = interaction.actions[props.actionIndex]
                if (action.easingFunction) {
                    action.easingFunction = easingFunction
                }
                return interaction
            }),
            options
        )
    }
    const onChange = (
        value: Value,
        index: number,
        oldEasingFunction: number[],
        options?: InputOptionsForUndoSquash
    ) => {
        if (typeof value !== 'number') {
            return
        }

        const newEasingFunction = oldEasingFunction.slice()
        newEasingFunction[index] = value
        onChangeCubicBezier(newEasingFunction, options)
    }

    return { onChange, onChangeCubicBezier }
}

export function useAnimationEasingSpringCommand(props: AnimationTransitionTypeCommandProps) {
    const command = useCommand()

    const onChangeSpring = (
        { duration, easingFunction }: { duration: number; easingFunction: number[] },
        option?: InputOptionsForUndoSquash
    ) => {
        command.DEPRECATED_invokeBridge(SetPrototypeAnimationEasingFunctionEditHistory, {
            value: easingFunction,
        })

        props.onChange(
            cloneDeep(props.data).map((interaction) => {
                const action = interaction.actions[props.actionIndex]
                action.transitionDuration = duration
                action.easingFunction = easingFunction
                return interaction
            }),
            option
        )
    }
    return { onChangeSpring }
}
