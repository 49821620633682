import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    OpenInNewTab: '在新标签页打开',
} as const

export const enTranslation = {
    OpenInNewTab: 'Open in new tab',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
