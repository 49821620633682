/**
 * 选择字体浮层弹框高度优化
 * https://motiff.cn/file/mWSr9MPU0fYGyhOOM3uQf3z?nodeId=32%3A12809
 * 1. 页面高度 < 445px 时，浮层跟随页面高度变化，上下padding=8px
 * 2. 字体选择框距页面底部 < 396px 时，浮层上移，浮层高度380px，下padding=8px
 * 3. 字体选择框距页面底部 >=396px 且 <= 616px 时，浮层高度跟随页面高度变化，下padding=8px
 * 4. 字体选择框距页面底部 > 616px 时，浮层高度600px，距离字体选择框8px
 */

import { useCallback, useLayoutEffect, useRef, useState } from 'react'

/**
 * 顶部导航栏高度
 */
const TOP_BAR_HEIGHT = 49

/**
 * 浮层根据页面高度变化时的页面高度
 */
const WIN_HEIGHT_MIN = 445

/**
 * 浮层根据字体选择框位置高度变化时的底部高度MIN
 */
const BOTTOM_REST_MIN = 396

/**
 * 浮层根据字体选择框位置高度变化时的底部高度MAX
 */
const BOTTOM_REST_MAX = 616

/**
 * 列表容器的最大高度 600 - 40 - 48
 */
const FONT_SELECT_MAX_HEIGHT = 512

/**
 * 列表容器的弹性固定高度 380 - 40 - 48
 */
const FONT_SELECT_FLEX_HEIGHT = 292

/**
 * 列表容器的最小高度 30
 */
const FONT_SELECT_MIN_HEIGHT = 30

/**
 * 顶部TAB高度
 */
const TAB_HEIGHT = 40

/**
 * 顶部搜索框高度
 */
const SEARCH_INPUT_HEIGHT = 48

/**
 * 顶部间距8
 */
const MARGIN_TOP = 8

/**
 * 底部间距8
 */
const MARGIN_BOTTOM = 8

/**
 * @returns
 * @param expand 是否展开浮层
 * @param tabPosition 浮层顶部位置
 * @param selectMaxHeight 计算列表最大高度以适应当前窗口高度
 */

export function useFontSelectInput(props?: { onChangeExpand?: (expand: boolean) => void }) {
    const divRef = useRef<HTMLDivElement>(null)
    const [expand, _setExpand] = useState(false)
    const [tabPosition, setTabPosition] = useState<number>(0)
    const [maxHeight, setMaxHeight] = useState(150)

    const setExpand = useCallback(
        (nextExpand: boolean) => {
            _setExpand(nextExpand)
            props?.onChangeExpand?.(nextExpand)
        },
        [props]
    )

    const getPositionInfo = (bottom: number) => {
        const winHeight = window.document.body.clientHeight
        const bottomRest = winHeight - bottom
        let position = bottom
        let height = FONT_SELECT_MAX_HEIGHT

        if (winHeight < WIN_HEIGHT_MIN) {
            position = TOP_BAR_HEIGHT
            height = Math.max(
                winHeight - TOP_BAR_HEIGHT - TAB_HEIGHT - SEARCH_INPUT_HEIGHT - MARGIN_TOP - MARGIN_BOTTOM,
                FONT_SELECT_MIN_HEIGHT
            )
        } else if (bottomRest < BOTTOM_REST_MIN) {
            position = winHeight - BOTTOM_REST_MIN
            height = FONT_SELECT_FLEX_HEIGHT
        } else if (bottomRest >= BOTTOM_REST_MIN && bottomRest <= BOTTOM_REST_MAX) {
            height = bottomRest - TAB_HEIGHT - SEARCH_INPUT_HEIGHT - MARGIN_TOP - MARGIN_BOTTOM
        }

        return { position, height }
    }

    const toggleExpand = useCallback(() => {
        if (divRef.current) {
            const { bottom } = divRef.current.getBoundingClientRect()
            const { position, height } = getPositionInfo(bottom)
            setTabPosition(position)
            setMaxHeight(height)
        }
        setExpand(!expand)
    }, [expand, setExpand])

    /** NOTE: useLayoutEffect防止高度闪烁 */
    useLayoutEffect(() => {
        if (!expand) {
            return
        }
        const onResize = () => {
            if (!divRef.current) {
                return
            }
            const { bottom } = divRef.current.getBoundingClientRect()
            const { position, height } = getPositionInfo(bottom)
            setTabPosition(position)
            setMaxHeight(height)
        }

        // 首次立即执行一次
        onResize()

        window.addEventListener('resize', onResize)
        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [expand])

    return {
        selectMaxHeight: maxHeight,
        divRef,
        tabPosition,
        expand,
        setExpand,
        toggleExpand,
    }
}
