import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    ProhibitExportTitle: '导出不可用',
    ProhibitExportContent: '文件已被拥有可编辑权限的成员设置为禁止查看者复制、导出和分享',
} as const

export const enTranslation = {
    ProhibitExportTitle: 'Export disabled',
    ProhibitExportContent: 'Someone with edit access has disabled exporting, copying, and sharing from this file.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
