import type { HTMLAttributes } from 'react'
import * as React from 'react'
import style from './inline-tabs.module.less'
import classNames from 'classnames'

interface InlineTabsProps extends HTMLAttributes<HTMLDivElement> {
    dataTestId?: string
    isHideSplitLine?: boolean
}

function _InlineTabs(
    { className, isHideSplitLine, dataTestId, ...otherProps }: InlineTabsProps,
    ref: React.Ref<HTMLDivElement>
) {
    return (
        <div
            ref={ref}
            className={classNames(style.inlineTabs, className, { [style.isHideSplitLine]: isHideSplitLine })}
            {...otherProps}
            data-testid={dataTestId}
        >
            {otherProps.children}
        </div>
    )
}

interface ItemProps extends HTMLAttributes<HTMLDivElement> {
    isActive?: boolean
    dataTestId?: string
}

function _Item({ isActive, dataTestId, className, ...otherProps }: ItemProps, ref: React.Ref<HTMLDivElement>) {
    return (
        <div
            ref={ref}
            className={classNames(style.item, className, { [style.active]: isActive })}
            data-testid={dataTestId}
            {...otherProps}
        >
            {otherProps.children}
        </div>
    )
}

const ItemRef = React.forwardRef<HTMLDivElement, ItemProps>(_Item)
const InlineTabsRef = React.forwardRef<HTMLDivElement, InlineTabsProps>(_InlineTabs)
const InlineTabs: typeof InlineTabsRef & { Item: typeof ItemRef } = Object.assign(InlineTabsRef, { Item: ItemRef })

export { InlineTabs }
