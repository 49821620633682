import { translation } from './font-local-plugin-installer.translation'
/* eslint-disable no-restricted-imports */
import { useCallback } from 'react'
import { useMount } from 'react-use'
import { WKButton } from '../../../../../../../../ui-lib/src'
import { WKFrogTask } from '../../../../../../share/wk-frog-task'
import styles from './font-local-plugin-installer.module.less'

export function LocalFontPluginInstaller(props: { url: string }) {
    const onClick = useCallback(() => {
        WKFrogTask.fontSelector.downloadLocalFontsPlugin()
        window.open(props.url, '_blank')
    }, [props.url])

    useMount(() => {
        WKFrogTask.fontSelector.exposeDownloadLocalFontsPlugin()
    })

    return (
        <div>
            <div className={styles.tip}>{translation('PleaseDownloadAnd')}</div>
            <div className={styles.install}>
                <WKButton type="secondary" onClick={onClick}>
                    {translation('DownloadInstallerTo')}
                </WKButton>
            </div>
        </div>
    )
}

export function LocalFontPluginUpdater(props: { url: string }) {
    const onClick = useCallback(() => {
        WKFrogTask.fontSelector.downloadLocalFontsPlugin()
        window.open(props.url, '_blank')
    }, [props.url])

    useMount(() => {
        WKFrogTask.fontSelector.exposeDownloadLocalFontsPlugin()
    })

    return (
        <div className={styles.container}>
            <div className={styles.useNewVersion}>{translation('UseNewVersion')}</div>
            <div>
                <WKButton type="primary" size="medium" onClick={onClick}>
                    {translation('Update')}
                </WKButton>
            </div>
        </div>
    )
}
