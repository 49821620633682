import { domLocation, RouteToken } from '../../../../../util/src'
import { ResourceType, RoleStatus } from '../../../kernel/interface/type'
import { QueryOrganizationSummary } from '../../../kernel/request/organizations'
import { GetWorkspaceSeatApplications, QuerySeatApplications } from '../../../kernel/request/seat'
import { GetWorkspaceSummaryById } from '../../../kernel/request/workspace'
import { featureSwitchManager } from '../../../kernel/switch'
import { SpaceNotifyFlow } from './notify-flow'
import { getSpaceStore, setSpaceStore, SpaceStoreType, type OrganizationManageSummaryStore } from './types'

export const generateOrganizationManageSummaryState = (
    set: setSpaceStore,
    get: getSpaceStore
): OrganizationManageSummaryStore => {
    return {
        summary: undefined,
        workspaceSummary: undefined,
        applicationList: undefined,
        loading: true,
        summaryLoading: true,
        seatApplicationsLoading: true,
        fetchOrgSummary: async () => {
            const orgId = get().organizationStore.organization.id
            await Promise.all([
                new QueryOrganizationSummary(orgId)
                    .start()
                    .then((summary) => {
                        set((state) => {
                            state.organizationManageSummaryStore.summary = summary
                        })
                    })
                    .catch(() => {}),
                new QuerySeatApplications(ResourceType.Organization, orgId)
                    .start()
                    .then((applicationList) => {
                        set((state) => {
                            state.organizationManageSummaryStore.applicationList = applicationList
                        })
                    })
                    .catch(() => {}),
            ])
        },
        fetchOrgSummaryWithLoading: async () => {
            set((state) => {
                state.organizationManageSummaryStore.loading = true
            })
            await get().organizationManageSummaryStore.fetchOrgSummary()
            set((state) => {
                state.organizationManageSummaryStore.loading = false
            })
        },
        fetchOrgSummaryData: async (options: {
            fetchSummary?: boolean
            fetchApplications?: boolean
            withLoading: boolean
        }) => {
            const { fetchSummary = true, fetchApplications = true, withLoading } = options

            if (withLoading) {
                set((state) => {
                    if (fetchSummary) state.organizationManageSummaryStore.summaryLoading = true
                    if (fetchApplications) state.organizationManageSummaryStore.seatApplicationsLoading = true
                })
            }

            try {
                const orgId = get().organizationStore.organization.id
                const promises = []

                if (fetchSummary) {
                    promises.push(
                        new QueryOrganizationSummary(orgId).start().then((summary) => {
                            set((state) => {
                                state.organizationManageSummaryStore.summary = summary
                            })
                        })
                    )
                }

                if (fetchApplications) {
                    promises.push(
                        new QuerySeatApplications(ResourceType.Organization, orgId).start().then((applicationList) => {
                            set((state) => {
                                state.organizationManageSummaryStore.applicationList = applicationList
                            })
                        })
                    )
                }

                await Promise.all(promises)
            } catch (e) {
            } finally {
                if (withLoading) {
                    set((state) => {
                        if (fetchSummary) state.organizationManageSummaryStore.summaryLoading = false
                        if (fetchApplications) state.organizationManageSummaryStore.seatApplicationsLoading = false
                    })
                }
            }
        },
        fetchWorkspaceSummaryData: async (
            workspaceId: string,
            options: {
                fetchSummary?: boolean
                fetchApplications?: boolean
                withLoading: boolean
            }
        ) => {
            const { fetchSummary = true, fetchApplications = true, withLoading } = options

            if (withLoading) {
                set((state) => {
                    if (fetchSummary) state.organizationManageSummaryStore.summaryLoading = true
                    if (fetchApplications) state.organizationManageSummaryStore.seatApplicationsLoading = true
                })
            }

            try {
                if (!workspaceId) return

                const promises = []

                if (fetchSummary) {
                    promises.push(
                        new GetWorkspaceSummaryById(workspaceId).start().then((summary) => {
                            set((state) => {
                                state.organizationManageSummaryStore.workspaceSummary = summary
                            })
                        })
                    )
                }

                if (fetchApplications) {
                    const orgId = get().organizationStore.organization.id
                    promises.push(
                        new GetWorkspaceSeatApplications(orgId, workspaceId).start().then((applicationList) => {
                            set((state) => {
                                state.organizationManageSummaryStore.applicationList = applicationList
                            })
                        })
                    )
                }

                await Promise.all(promises)
            } catch (e) {
            } finally {
                if (withLoading) {
                    set((state) => {
                        if (fetchSummary) state.organizationManageSummaryStore.summaryLoading = false
                        if (fetchApplications) state.organizationManageSummaryStore.seatApplicationsLoading = false
                    })
                }
            }
        },
    }
}

const isInOrganizationSummaryPage = () => domLocation().pathname.includes(`${RouteToken.Admin}/summary`)

export const syncOrganizationManageSummaryState = (store: SpaceStoreType, notifyFlow: SpaceNotifyFlow) => {
    notifyFlow.addRelationChangeCallbackWithoutAccess(() => {
        if (isInOrganizationSummaryPage()) {
            const isOrgAdmin = store.getState().organizationStore.organization.role === RoleStatus.Admin
            if (isOrgAdmin) {
                store.getState().organizationManageSummaryStore.fetchOrgSummaryData({ withLoading: false })
            }
        }
    })

    notifyFlow.addPropertyChangeCallbackWithoutDocAndUser(() => {
        if (isInOrganizationSummaryPage()) {
            const isOrgAdmin = store.getState().organizationStore.organization.role === RoleStatus.Admin
            if (isOrgAdmin) {
                store.getState().organizationManageSummaryStore.fetchOrgSummaryData({ withLoading: false })
            }
        }
    })

    notifyFlow.addSeatTypeOrSeatApplicationRelationChangeCallback(() => {
        if (!featureSwitchManager.isEnabled('organization-plus') || !isInOrganizationSummaryPage()) return
        const { adminManagedWorkspaces, currentSelectedWorkspaceIdInAdmin } = store.getState().managedWorkspaceStore

        if (adminManagedWorkspaces.length > 0 && currentSelectedWorkspaceIdInAdmin) {
            store
                .getState()
                .organizationManageSummaryStore.fetchWorkspaceSummaryData(currentSelectedWorkspaceIdInAdmin, {
                    withLoading: false,
                })
        }
    })
}
