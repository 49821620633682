import {
    MonoIconCommonErrorLine16,
    MonoIconCommonErrorLine24,
    MonoIconCommonInfoLine16,
    MonoIconCommonInfoLine24,
    MonoIconCommonSuccessLine16,
    MonoIconCommonSuccessLine24,
    MonoIconCommonWarningLine16,
    MonoIconCommonWarningLine24,
} from '../../icons-v2'
import { AlertColor } from './type'

export const getBackgroundColor = (color: AlertColor = 'default') => {
    switch (color) {
        case 'default':
            return 'bg-$wk-brand-1'
        case 'success':
            return 'bg-$wk-green-1'
        case 'warning':
            return 'bg-$wk-yellow-1'
        case 'error':
            return 'bg-$wk-red-1'
        case 'deepBlue':
            return 'bg-$wk-brand-7'
        case 'deepGreen':
            return 'bg-$wk-green-7'
        case 'deepYellow':
            return 'bg-$wk-yellow-7'
        case 'deepRed':
            return 'bg-$wk-red-8'
    }
}
const getPresetIconColor = (color: AlertColor = 'default') => {
    switch (color) {
        case 'default':
            return 'color-$wk-brand-7'
        case 'success':
            return 'color-$wk-green-7'
        case 'warning':
            return 'color-$wk-yellow-9'
        case 'error':
            return 'color-$wk-red-8'
        case 'deepBlue':
        case 'deepGreen':
        case 'deepRed':
            return 'color-white'
        case 'deepYellow':
            return 'color-$wk-v2-label-color-gray-13'
    }
}

export const getPresetIcon = (color: AlertColor = 'default', size: 16 | 24) => {
    const textColor = getPresetIconColor(color)
    const classnames = `${textColor} `
    switch (color) {
        case 'default':
        case 'deepBlue': {
            if (size == 16) {
                return <MonoIconCommonInfoLine16 className={classnames} />
            }
            return <MonoIconCommonInfoLine24 className={classnames} />
        }
        case 'success':
        case 'deepGreen':
            if (size == 16) {
                return <MonoIconCommonSuccessLine16 className={classnames} />
            }
            return <MonoIconCommonSuccessLine24 className={classnames} />
        case 'warning':
        case 'deepYellow':
            if (size == 16) {
                return <MonoIconCommonWarningLine16 className={classnames} />
            }
            return <MonoIconCommonWarningLine24 className={classnames} />
        case 'error':
        case 'deepRed':
            if (size == 16) {
                return <MonoIconCommonErrorLine16 className={classnames} />
            }
            return <MonoIconCommonErrorLine24 className={classnames} />
    }
}

export const getCloseButtonType = (color: AlertColor = 'default') => {
    switch (color) {
        case undefined:
        case 'default':
        case 'success':
        case 'warning':
        case 'error':
            return 'secondary' as const
        case 'deepBlue':
        case 'deepGreen':
        case 'deepRed':
            return 'white' as const
        case 'deepYellow':
            return 'primary' as const
    }
}

export const getTitleFontColor = (color: AlertColor = 'default') => {
    switch (color) {
        case 'default':
        case 'success':
        case 'warning':
        case 'error':
        case undefined:
        case 'deepYellow':
            return 'primary'
        case 'deepBlue':
        case 'deepGreen':
        case 'deepRed':
            return 'white'
    }
}
