export type PopupPosition =
    | 'center center'
    | 'top left'
    | 'top center'
    | 'top right'
    | 'right top'
    | 'right center'
    | 'right bottom'
    | 'bottom left'
    | 'bottom center'
    | 'bottom right'
    | 'left top'
    | 'left center'
    | 'left bottom'

export enum Direction {
    Top = 'top',
    Right = 'right',
    Bottom = 'bottom',
    Left = 'left',
    Center = 'center',
}
export interface RectContainer {
    top: number
    right: number
    bottom: number
    left: number
}

export interface RectContent {
    width: number
    height: number
}

type TopEdge = number
type RightEdge = number
type BottomEdge = number
type LeftEdge = number
export type MinDistanceNearEdge = [TopEdge, RightEdge, BottomEdge, LeftEdge]
