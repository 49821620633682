import { Wukong } from '@wukong/bridge-proto'
import { FontName } from '../../../../document/node/node'
import { isEqualFontName } from '../../../../document/util/font'
import { useFontManagerService } from '../../../context/document-context'

/**
 * NOTE: 导入文档包含的FontName与用户的字体可能不一致，根据fontName的核心字段匹配到用户的字体信息，比如figma导入的文档缺失localizedStyle
 */
export const useUserFontNameFirst = (fontName: FontName | Wukong.DocumentProto.IFontName | null = null) => {
    const fontManagerService = useFontManagerService()
    const family2FontInfoMap = fontManagerService.states.use.family2FontInfoMapState()

    if (!fontName) {
        return null
    }

    const fontInfo = family2FontInfoMap[fontName.family!]
    return (
        (fontInfo?.styles || []).find((v) => isEqualFontName(v, fontName)) ??
        ({
            weight: 400,
            width: 5,
            italic: false,
            // NOTE: 默认填充localizedFamily, localizedStyle
            localizedFamily: fontName.family,
            localizedStyle: fontName.style,
            ...fontName,
        } as FontName)
    )
}
