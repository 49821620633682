/* eslint-disable no-restricted-imports */
import { useCallback, useMemo } from 'react'
import { Tooltip } from '../../../../../../ui-lib/src'
import { CommentReaction, CommentUser } from '../../../../kernel/interface/comment'
import { UsualEmoji } from './common'
import classes from './reply-emoji-item.module.less'

export interface ReplyEmojiItemProps {
    emojiInfo: UsualEmoji
    reactions: CommentReaction[]
    currentUserId: CommentUser['id']
    onClickEmoji?: (emojiInfo: UsualEmoji) => void
}
export function ReplyEmojiItem(props: ReplyEmojiItemProps) {
    const { emojiInfo, reactions, currentUserId, onClickEmoji } = props

    const namesInfo = useMemo(() => {
        const names: string[] = []
        for (const reaction of reactions) {
            if (reaction.owner.id === currentUserId) {
                names.unshift(reaction.owner.nickname)
            } else {
                names.push(reaction.owner.nickname)
            }
        }
        return Object.assign({ names }, transformNames(names))
    }, [reactions, currentUserId])

    const _onClickEmoji = useCallback(() => {
        onClickEmoji?.(emojiInfo)
    }, [emojiInfo, onClickEmoji])

    return (
        <Tooltip title={namesInfo.names.join('，')} inactivation={!namesInfo.isOver}>
            <span className={classes.replyEmojiItem} onClick={_onClickEmoji}>
                <span className={classes.emoji}>{emojiInfo.emoji}</span>
                <span className={classes.splitLine}></span>
                <span className={classes.names}>{namesInfo.showString}</span>
            </span>
        </Tooltip>
    )
}

export function transformNames(names: string[]) {
    let namesString = names.join('，')
    let isOver = false
    if (namesString.length > 15) {
        isOver = true
        const namesStringPrev = namesString.slice(0, 12)
        const namesStringNext = namesString.slice(12)
        if (/^，/.test(namesStringNext)) {
            const overNum = names.length - namesStringPrev.split('，').length
            namesString = namesStringPrev + ` +${overNum}`
        } else {
            const overNum = namesStringNext.split('，').length
            const validNum = names.length - overNum
            const nameList = [...names]
            nameList.length = validNum
            namesString = nameList.join('，') + ` +${overNum}`
        }
    }
    return {
        showString: namesString,
        isOver,
    }
}
