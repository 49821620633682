import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Style: '样式',
    ViewColorStyle: '查看颜色样式',
    CreateColorStyle: '创建颜色样式',
    EditColorStyle: '编辑颜色样式',
    Variable: '变量',
    ColorStyles: '颜色样式',
    ColorStyles_synonyms1: '多层颜色样式',
    Image: '图片',
    AngularGradient: '角度渐变',
    DiamondGradient: '菱形渐变',
    LinearGradient: '线性渐变',
    RadialGradient: '径向渐变',
    StyleName: '样式名称',
} as const

export const enTranslation = {
    Style: 'Style',
    ViewColorStyle: 'View color style',
    Variable: 'Variable',
    CreateColorStyle: 'Create new color style',
    EditColorStyle: 'Edit color style',
    ColorStyles: 'Color styles',
    ColorStyles_synonyms1: 'Color styles',
    Image: 'Image',
    AngularGradient: 'Angular gradient',
    DiamondGradient: 'Diamond gradient',
    LinearGradient: 'Linear gradient',
    RadialGradient: 'Radial gradient',
    StyleName: 'Style name',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
