import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    All: '全部',
    Text: '文本',
    Frame: '容器',
    Group: '组',
    Component: '组件',
    Instance: '实例',
    Image: '图片',
    Shape: '形状',
    Other: '其他',
    MatchCase: '区分大小写',
    fUWyLG: '（西文）',
    WholeWords: '整词匹配',
} as const

export const enTranslation = {
    All: 'All',
    Text: 'Text',
    Frame: 'Frame',
    Group: 'Group',
    Component: 'Component',
    Instance: 'Instance',
    Image: 'Image',
    Shape: 'Shape',
    Other: 'Other',
    MatchCase: 'Match case',
    fUWyLG: '',
    WholeWords: 'Whole words',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
