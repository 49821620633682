import { isEqual } from 'lodash-es'
import { useEffect, useRef, useState } from 'react'
import { useDeepCompareEffect } from 'react-use'

// 圆弧属性的隐藏状态控制
export const useArcHiddenState = (hidden: boolean, passIds?: string[]) => {
    const [localHidden, setLocalHidden] = useState(true)
    const ref = useRef<string[]>()

    useDeepCompareEffect(() => {
        if (!isEqual(ref.current, passIds)) {
            // 切换选区就重置状态
            setLocalHidden(hidden)
            ref.current = passIds
        }
    }, [passIds, hidden])

    useEffect(() => {
        // 如果wasm传值是 '不隐藏'，那么就将本地状态也置为不隐藏 并且忽略传值为 '隐藏' 时的情况
        if (!hidden) {
            setLocalHidden(false)
        }
    }, [hidden])

    return localHidden
}
