import { SVGAttributes, useMemo, forwardRef } from 'react'
export const MulticolorIconCommonFigmaFile48 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 9 5.99963)"
                d="M0 1.5C0 0.671573 0.671573 0 1.5 0L20.1476 0C20.6922 0 21.2132 0.222077 21.5903 0.614937L29.4428 8.79456C29.8003 9.16701 30 9.66331 30 10.1796L30 34.5C30 35.3284 29.3284 36 28.5 36L1.5 36C0.671573 36 0 35.3284 0 34.5L0 1.5Z"
                fillRule="nonzero"
                fill="rgb(230, 232, 234)"
            />
            <defs>
                <mask id={ids[0]} style={{ maskType: 'alpha' }}>
                    <path
                        transform="matrix(1 0 0 1 9 5.99963)"
                        d="M0 1.5C0 0.671573 0.671573 0 1.5 0L20.1476 0C20.6922 0 21.2132 0.222077 21.5903 0.614937L29.4428 8.79456C29.8003 9.16701 30 9.66331 30 10.1796L30 34.5C30 35.3284 29.3284 36 28.5 36L1.5 36C0.671573 36 0 35.3284 0 34.5L0 1.5Z"
                        fillRule="nonzero"
                        fill="rgb(0, 0, 0)"
                    />
                </mask>
            </defs>
            <g mask={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 25.995 6)"
                    d="M4 2C4 0.89543 3.10457 0 2 0L0 0L13 0L13 14L13 11C13 9.89543 12.1046 9 11 9L6 9C4.89543 9 4 8.10457 4 7L4 2Z"
                    fillRule="nonzero"
                    fill="rgb(218, 220, 224)"
                />
            </g>
            <path
                transform="matrix(1 0 0 1 24 22.4999)"
                d="M0 3.75002C0 1.68225 1.68224 0 3.75 0C5.81776 0 7.5 1.68225 7.5 3.75002C7.5 5.81778 5.81776 7.50003 3.75 7.50003C1.68224 7.50003 0 5.81778 0 3.75002Z"
                fillRule="nonzero"
                fill="rgb(26, 188, 254)"
            />
            <path
                transform="matrix(1 0 0 1 16.5 30.0002)"
                d="M0 3.75002C0 1.68225 1.69014 0 3.76761 0L7.5 0L7.5 3.75002C7.5 5.81778 5.80986 7.50003 3.73239 7.50003C1.65493 7.50003 0 5.81778 0 3.75002L0 3.75002Z"
                fillRule="nonzero"
                fill="rgb(10, 207, 131)"
            />
            <path
                transform="matrix(1 0 0 1 24 15)"
                d="M0 0L0 7.50003L3.75 7.50003C5.81776 7.50003 7.5 5.80989 7.5 3.73241C7.5 1.65494 5.81776 0 3.75 0L0 0Z"
                fillRule="nonzero"
                fill="rgb(255, 114, 98)"
            />
            <path
                transform="matrix(1 0 0 1 16.5 15)"
                d="M0 3.75002C0 5.81778 1.69014 7.50003 3.76761 7.50003L7.5 7.50003L7.5 0L3.73239 0C1.65493 0 0 1.68225 0 3.75002L0 3.75002Z"
                fillRule="nonzero"
                fill="rgb(242, 78, 30)"
            />
            <path
                transform="matrix(1 0 0 1 16.5 22.4999)"
                d="M0 3.75002C0 5.81778 1.69014 7.50003 3.76761 7.50003L7.5 7.50003L7.5 0L3.73239 0C1.65493 0 0 1.68225 0 3.75002L0 3.75002Z"
                fillRule="nonzero"
                fill="rgb(162, 89, 255)"
            />
        </svg>
    )
})
export const MulticolorIconCommonComponentsFile48 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 9 6)"
                d="M0 1.5C0 0.671573 0.671573 0 1.5 0L20.3787 0C20.7765 0 21.158 0.158036 21.4393 0.43934L29.5607 8.56066C29.842 8.84196 30 9.22349 30 9.62132L30 34.5C30 35.3284 29.3284 36 28.5 36L1.5 36C0.671573 36 0 35.3284 0 34.5L0 1.5Z"
                fillRule="nonzero"
                fill="rgb(204, 95, 255)"
            />
            <defs>
                <mask id={ids[0]} style={{ maskType: 'alpha' }}>
                    <path
                        transform="matrix(1 0 0 1 9 6)"
                        d="M0 1.5C0 0.671573 0.671573 0 1.5 0L20.3787 0C20.7765 0 21.158 0.158036 21.4393 0.43934L29.5607 8.56066C29.842 8.84196 30 9.22349 30 9.62132L30 34.5C30 35.3284 29.3284 36 28.5 36L1.5 36C0.671573 36 0 35.3284 0 34.5L0 1.5Z"
                        fillRule="nonzero"
                        fill="rgb(0, 0, 0)"
                    />
                </mask>
            </defs>
            <g mask={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 25.5 6)"
                    d="M0 0L2.85322 0L0 0ZM13.4539 0L2.85322 0L3 0C3.82843 0 4.5 0.671573 4.5 1.5L4.5 7.5C4.5 8.32842 5.17157 9 6 9L12.0001 9C12.7676 9 13.4004 9.57633 13.4894 10.3198L13.4539 0ZM2.85322 0L0 0L2.85322 0ZM13.5004 13.5004L13.5001 10.4999C13.5001 10.4389 13.4965 10.3788 13.4894 10.3198L13.5004 13.5004Z"
                    fillRule="nonzero"
                    fill="rgb(255, 255, 255)"
                    opacity="0.3"
                />
            </g>
            <path
                transform="matrix(1 0 0 1 13.5 18.0207)"
                d="M18.2852 0.522272C17.3292 0.0938373 16.2843 -0.0710043 15.1507 0.0277466C14.1194 0.117577 13.1296 0.412107 12.1812 0.911334C11.5322 1.25293 10.9691 1.65489 10.4919 2.11722C10.0154 1.65498 9.45397 1.25317 8.80754 0.911799C7.86165 0.412295 6.87458 0.117616 5.84632 0.0277643C4.71582 -0.0710211 3.67293 0.0938551 2.71766 0.522393C1.64804 1.00223 0.780144 1.77516 0.113976 2.84118L0 3.23864L0 17.3421L1.28986 17.8628C1.57817 17.5638 1.95835 17.2963 2.4304 17.0602C3.01539 16.7676 3.65142 16.5762 4.33848 16.4861C6.17 16.2456 8.08996 16.7439 10.0984 17.9808L10.8849 17.9808C12.8935 16.7438 14.8164 16.2455 16.6534 16.4861C17.3428 16.5763 17.9809 16.7678 18.5677 17.0606C19.0415 17.2969 19.4223 17.5643 19.7101 17.8628L21 17.3421L21 3.23864L20.887 2.84277C20.2241 1.77598 19.3568 1.00247 18.2852 0.522272ZM16.8482 14.9988C15.0217 14.7596 13.1529 15.1156 11.2417 16.0667L11.2417 3.5058C11.6687 3.01113 12.2148 2.58876 12.8799 2.23867C13.65 1.8333 14.4503 1.59443 15.2808 1.52209C16.1558 1.44587 16.9528 1.56888 17.6718 1.89111C18.4061 2.22016 19.0155 2.74318 19.5 3.46016L19.5 15.8561C19.4147 15.809 19.3271 15.7631 19.2372 15.7183C18.5003 15.3507 17.7039 15.1108 16.8482 14.9988ZM9.74166 3.50573C9.31452 3.01061 8.76967 2.58811 8.10709 2.23821C7.33985 1.83305 6.54274 1.59433 5.71574 1.52207C4.84432 1.44592 4.04961 1.5689 3.33162 1.89099C2.5979 2.22014 1.98736 2.74352 1.5 3.46114L1.5 15.855C1.58428 15.8084 1.67076 15.763 1.75944 15.7186C2.49484 15.3508 3.28945 15.1109 4.14325 14.9988C5.96513 14.7597 7.83126 15.1156 9.74166 16.0666L9.74166 3.50573Z"
                fillRule="evenodd"
                fill="rgb(255, 255, 255)"
            />
        </svg>
    )
})
export const MulticolorIconCommonSketchFile48 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 3 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 9 5.99963)"
                d="M0 1.5C0 0.671573 0.671573 0 1.5 0L20.1476 0C20.6922 0 21.2132 0.222077 21.5903 0.614937L29.4428 8.79456C29.8003 9.16701 30 9.66331 30 10.1796L30 34.5C30 35.3284 29.3284 36 28.5 36L1.5 36C0.671573 36 0 35.3284 0 34.5L0 1.5Z"
                fillRule="nonzero"
                fill="rgb(230, 232, 234)"
            />
            <defs>
                <mask id={ids[0]} style={{ maskType: 'alpha' }}>
                    <path
                        transform="matrix(1 0 0 1 9 5.99963)"
                        d="M0 1.5C0 0.671573 0.671573 0 1.5 0L20.1476 0C20.6922 0 21.2132 0.222077 21.5903 0.614937L29.4428 8.79456C29.8003 9.16701 30 9.66331 30 10.1796L30 34.5C30 35.3284 29.3284 36 28.5 36L1.5 36C0.671573 36 0 35.3284 0 34.5L0 1.5Z"
                        fillRule="nonzero"
                        fill="rgb(0, 0, 0)"
                    />
                </mask>
            </defs>
            <g mask={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 26.49 6.00012)"
                    d="M4 2C4 0.89543 3.10457 0 2 0L0 0L13 0L13 14L13 11C13 9.89543 12.1046 9 11 9L6 9C4.89543 9 4 8.10457 4 7L4 2Z"
                    fillRule="nonzero"
                    fill="rgb(218, 220, 224)"
                />
            </g>
            <defs>
                <clipPath id={ids[1]}>
                    <path
                        transform="matrix(1 0 0 1 12 15)"
                        d="M4.5 0C2.01472 0 0 2.01472 0 4.5L0 19.5C0 21.9853 2.01472 24 4.5 24L19.5 24C21.9853 24 24 21.9853 24 19.5L24 4.5C24 2.01472 21.9853 0 19.5 0L4.5 0Z"
                        fillRule="nonzero"
                    />
                </clipPath>
            </defs>
            <g clipPath={`url(#${ids[1]})`}>
                <defs>
                    <clipPath id={ids[2]}>
                        <path
                            transform="matrix(1 0 0 1 8.99963 11.9993)"
                            d="M3.75009 0C1.67897 0 0 1.67897 0 3.75009L0 26.2506C0 28.3218 1.67897 30.0007 3.75009 30.0007L26.2506 30.0007C28.3218 30.0007 30.0007 28.3218 30.0007 26.2506L30.0007 3.75009C30.0007 1.67897 28.3218 0 26.2506 0L3.75009 0Z"
                            fillRule="nonzero"
                        />
                    </clipPath>
                </defs>
                <g clipPath={`url(#${ids[2]})`}>
                    <path
                        transform="matrix(1 0 0 1 12.1871 16.686)"
                        d="M11.8129 21.4782L0 7.70304L5.10558 0.727649L11.8129 0L18.5203 0.727649L23.6258 7.70304L11.8129 21.4782Z"
                        fillRule="nonzero"
                        fill="rgb(255, 174, 0)"
                    />
                    <path
                        transform="matrix(1 0 0 1 12.1871 24.3857)"
                        d="M11.8129 13.7751L0 0L23.6258 0L11.8129 13.7751Z"
                        fillRule="nonzero"
                        fill="rgb(236, 108, 0)"
                    />
                    <path
                        transform="matrix(1 0 0 1 16.9649 24.3857)"
                        d="M7.03269 13.7751L0 0L14.0654 0L7.03269 13.7751Z"
                        fillRule="nonzero"
                        fill="rgb(255, 174, 0)"
                    />
                    <path
                        transform="matrix(1 0 0 1 16.9649 16.686)"
                        d="M7.03269 0L0 7.70304L14.0654 7.70304L7.03269 0Z"
                        fillRule="nonzero"
                        fill="rgb(255, 239, 180)"
                    />
                    <path
                        transform="matrix(1 0 0 1 12.1871 17.4147)"
                        d="M5.10558 0L2.47771 3.51278L0 6.97539L4.83028 6.97539L5.10558 0ZM18.5203 0L21.1481 3.51278L23.6258 6.97539L18.7956 6.97539L18.5203 0Z"
                        fillRule="nonzero"
                        fill="rgb(255, 174, 0)"
                    />
                    <path
                        transform="matrix(1 0 0 1 16.9649 16.686)"
                        d="M0.325356 0.727649L0 7.70304L7.03269 0L0.325356 0.727649ZM13.74 0.727649L14.0654 7.70304L7.03269 0L13.74 0.727649Z"
                        fillRule="nonzero"
                        fill="rgb(254, 211, 5)"
                    />
                </g>
            </g>
        </svg>
    )
})
export const MulticolorIconCommonFont48 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 9 6)"
                d="M0 1.5C0 0.671573 0.671573 0 1.5 0L20.3787 0C20.7765 0 21.158 0.158036 21.4393 0.43934L29.5607 8.56066C29.842 8.84196 30 9.2235 30 9.62132L30 34.5C30 35.3284 29.3284 36 28.5 36L1.5 36C0.671573 36 0 35.3284 0 34.5L0 1.5Z"
                fillRule="nonzero"
                fill="rgb(230, 232, 234)"
            />
            <defs>
                <mask id={ids[0]} style={{ maskType: 'alpha' }}>
                    <path
                        transform="matrix(1 0 0 1 9 6)"
                        d="M0 1.5C0 0.671573 0.671573 0 1.5 0L20.3787 0C20.7765 0 21.158 0.158036 21.4393 0.43934L29.5607 8.56066C29.842 8.84196 30 9.2235 30 9.62132L30 34.5C30 35.3284 29.3284 36 28.5 36L1.5 36C0.671573 36 0 35.3284 0 34.5L0 1.5Z"
                        fillRule="nonzero"
                        fill="rgb(0, 0, 0)"
                    />
                </mask>
            </defs>
            <g mask={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 27 6)"
                    d="M3 1.5C3 0.671573 2.32843 0 1.5 0L0 0L12 0L12 12L12 10.5C12 9.67157 11.3284 9 10.5 9L4.5 9C3.67157 9 3 8.32843 3 7.5L3 1.5Z"
                    fillRule="nonzero"
                    fill="rgb(218, 220, 224)"
                />
            </g>
            <path
                transform="matrix(1 0 0 1 13.5 7.5)"
                d="M9.10246 9.00366L3.37053 24L5.99553 24L7.3593 20.2419L13.6398 20.2419L15.0036 24L17.6286 24L11.8967 9.00366L9.10246 9.00366ZM8.09758 18.2271L10.4662 11.6543L10.5534 11.6543L12.9067 18.2271L8.09758 18.2271Z"
                fillRule="nonzero"
                fill="rgb(130, 138, 153)"
            />
        </svg>
    )
})
export const MulticolorIconCommonUnknown48 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 9 6)"
                d="M0 1.5C0 0.671573 0.671573 0 1.5 0L20.3787 0C20.7765 0 21.158 0.158036 21.4393 0.43934L29.5607 8.56066C29.842 8.84196 30 9.2235 30 9.62132L30 34.5C30 35.3284 29.3284 36 28.5 36L1.5 36C0.671573 36 0 35.3284 0 34.5L0 1.5Z"
                fillRule="nonzero"
                fill="rgb(230, 232, 234)"
            />
            <defs>
                <mask id={ids[0]} style={{ maskType: 'alpha' }}>
                    <path
                        transform="matrix(1 0 0 1 9 6)"
                        d="M0 1.5C0 0.671573 0.671573 0 1.5 0L20.3787 0C20.7765 0 21.158 0.158036 21.4393 0.43934L29.5607 8.56066C29.842 8.84196 30 9.2235 30 9.62132L30 34.5C30 35.3284 29.3284 36 28.5 36L1.5 36C0.671573 36 0 35.3284 0 34.5L0 1.5Z"
                        fillRule="nonzero"
                        fill="rgb(0, 0, 0)"
                    />
                </mask>
            </defs>
            <g mask={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 27 6)"
                    d="M3 1.5C3 0.671573 2.32843 0 1.5 0L0 0L12 0L12 12L12 10.5C12 9.67157 11.3284 9 10.5 9L4.5 9C3.67157 9 3 8.32843 3 7.5L3 1.5Z"
                    fillRule="nonzero"
                    fill="rgb(218, 220, 224)"
                />
            </g>
            <path
                transform="matrix(1 0 0 1 13.5 7.5)"
                d="M10.7318 8.71143C9.17838 8.71143 7.95816 9.15234 7.05582 10.0342C6.13297 10.916 5.69205 12.1362 5.69205 13.6897L8.08633 13.6897C8.08633 12.7874 8.25552 12.0952 8.60928 11.6082C9.02969 11.0442 9.68082 10.7725 10.5832 10.7725C11.2958 10.7725 11.8649 10.957 12.2648 11.3569C12.6442 11.7568 12.8544 12.3054 12.8544 12.9976C12.8544 13.5205 12.6647 14.0229 12.2853 14.4895L12.0341 14.7817C10.6703 15.9968 9.85001 16.8787 9.57828 17.4478C9.28091 18.0168 9.15787 18.709 9.15787 19.5037L9.15787 19.801L11.5727 19.801L11.5727 19.5037C11.5727 19.0012 11.6752 18.5398 11.8854 18.1194C12.0751 17.74 12.3468 17.3862 12.7262 17.0889C13.7362 16.207 14.3412 15.6431 14.5309 15.4329C15.0385 14.7612 15.3102 13.8999 15.3102 12.8489C15.3102 11.5671 14.8898 10.5623 14.049 9.82397C13.2082 9.07031 12.0956 8.71143 10.7318 8.71143ZM10.3524 20.8315C9.89102 20.8315 9.51163 20.9751 9.21939 21.2725C8.88101 21.5647 8.73746 21.9441 8.73746 22.4055C8.73746 22.8669 8.88101 23.2463 9.21939 23.5386C9.51163 23.8308 9.89102 24 10.3524 24C10.8139 24 11.1933 23.8513 11.5265 23.5591C11.8239 23.2668 11.9931 22.8669 11.9931 22.4055C11.9931 21.9441 11.8239 21.5647 11.5265 21.2725C11.2138 20.9751 10.8139 20.8315 10.3524 20.8315Z"
                fillRule="nonzero"
                fill="rgb(130, 138, 153)"
            />
        </svg>
    )
})
export const MulticolorIconCommonFolderBg48 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 2 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 0 0)"
                d="M3 0C1.34315 0 0 1.34315 0 3L0 45C0 46.6569 1.34315 48 3 48L45 48C46.6569 48 48 46.6569 48 45L48 3C48 1.34315 46.6569 0 45 0L3 0Z"
                fillRule="nonzero"
                fill="rgb(224, 239, 255)"
            />
            <path
                transform="matrix(1 0 0 1 9.99961 11.9996)"
                d="M2.4 0C1.07452 0 0 1.07452 0 2.4L0 21.6C0 22.9255 1.07452 24 2.4 24L25.6 24C26.9255 24 28 22.9255 28 21.6L28 5.97475C28 4.64927 26.9255 3.57475 25.6 3.57475L13.651 3.57475C12.9113 3.57475 12.2129 3.23365 11.7582 2.65026L10.4129 0.924495C9.95814 0.341098 9.25976 0 8.52006 0L2.4 0Z"
                fillRule="nonzero"
                fill="rgb(89, 147, 255)"
            />
            <defs>
                <mask id={ids[0]} style={{ maskType: 'alpha' }}>
                    <path
                        transform="matrix(1 0 0 1 9.99961 11.9996)"
                        d="M2.4 0C1.07452 0 0 1.07452 0 2.4L0 21.6C0 22.9255 1.07452 24 2.4 24L25.6 24C26.9255 24 28 22.9255 28 21.6L28 5.97475C28 4.64927 26.9255 3.57475 25.6 3.57475L13.651 3.57475C12.9113 3.57475 12.2129 3.23365 11.7582 2.65026L10.4129 0.924495C9.95814 0.341098 9.25976 0 8.52006 0L2.4 0Z"
                        fillRule="nonzero"
                        fill="rgb(0, 0, 0)"
                    />
                </mask>
            </defs>
            <g mask={`url(#${ids[0]})`} />
            <path
                transform="matrix(1 0 0 1 9.99995 20.0002)"
                d="M0 2.4C0 1.07452 1.07452 0 2.4 0L25.6 0C26.9255 0 28 1.07452 28 2.4L28 13.6C28 14.9255 26.9255 16 25.6 16L2.4 16C1.07452 16 0 14.9255 0 13.6L0 2.4Z"
                fillRule="nonzero"
                fill="rgb(120, 172, 255)"
            />
            <defs>
                <mask id={ids[1]} style={{ maskType: 'alpha' }}>
                    <path
                        transform="matrix(1 0 0 1 9.99995 20.0002)"
                        d="M0 2.4C0 1.07452 1.07452 0 2.4 0L25.6 0C26.9255 0 28 1.07452 28 2.4L28 13.6C28 14.9255 26.9255 16 25.6 16L2.4 16C1.07452 16 0 14.9255 0 13.6L0 2.4Z"
                        fillRule="nonzero"
                        fill="rgb(0, 0, 0)"
                    />
                </mask>
            </defs>
            <g mask={`url(#${ids[1]})`} />
        </svg>
    )
})
