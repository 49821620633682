import { FC, PropsWithChildren, useEffect, useLayoutEffect, useRef } from 'react'
import { useUpdateEffect } from 'react-use'
import { KeyboardEventHandlerId, KeyboardReceiverProps, keyboardReceiverStore } from '../store'

/**
 * 负责 keyboard 事件的注册监听和取消注册
 */
export const KeyboardReceiver: FC<PropsWithChildren<KeyboardReceiverProps>> = ({
    children,
    keyCode,
    onKeydown,
    onKeyup,
    triggerOnlyByPropagation,
    className = '',
    style,
}) => {
    // 初始化一个 receiverId
    const id = useRef(keyboardReceiverStore.getId()).current
    // 记录 parentId
    const ancestorReceiverIdRef = useRef<KeyboardEventHandlerId | undefined>()

    // 在第一次渲染时入栈，在卸载时出栈
    useEffect(() => {
        keyboardReceiverStore.registerKeyboardEventHandler({
            id,
            keyCode,
            onKeydown,
            onKeyup,
            triggerOnlyByPropagation,
        })

        // 卸载
        return () => {
            keyboardReceiverStore.unregisterKeyboardEventHandler(id, ancestorReceiverIdRef.current)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // 在 prop 更新时更新 handler
    useUpdateEffect(() => {
        keyboardReceiverStore.updateKeyboardReceiver({
            id,
            keyCode,
            onKeydown,
            onKeyup,
            triggerOnlyByPropagation,
        })
    }, [keyCode, onKeydown, onKeyup, triggerOnlyByPropagation])

    // 在渲染完后获取 ancestorReceiverId
    useLayoutEffect(() => {
        ancestorReceiverIdRef.current = keyboardReceiverStore.findParentReceiverById(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div id={id} className={className} style={style}>
            {children}
        </div>
    )
}
