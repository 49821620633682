import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Overview: '概述',
    Customers: '客户',
    Products: '产品',
    Settings: '设置',
    'Search...': '搜索...',
    Dashboard: '仪表板',
    'Jan 20, 2023 - Feb 09, 2023': '2023年1月20日 - 2023年2月9日',
    Download: '下载',
    Analytics: '分析',
    Reports: '报告',
    Notifications: '通知',
    'Total Revenue': '总收入',
    '+20.1% from last month': '较上月 +20.1%',
    Subscriptions: '订阅',
    '+180.1% from last month': '较上月 +180.1%',
    Sales: '销售',
    '+19% from last month': '较上月 +19%',
    'Active Now': '当前活跃',
    '+201 since last hour': '较上小时 +201',
    'Recent Sales': '最近销售',
    'You made 265 sales this month.': '您本月完成了265笔销售。',
    Jan: '一月',
    Feb: '二月',
    Mar: '三月',
    Apr: '四月',
    May: '五月',
    Jun: '六月',
    Jul: '七月',
    Aug: '八月',
    Sep: '九月',
    Oct: '十月',
    Nov: '十一月',
    Dec: '十二月',
    'Olivia Martin': '张丽华',
    'olivia.martin@email.com': 'zhanglihua@email.com',
    '+$1,999.00': '+¥13,993.00',
    'Jackson Lee': '李明',
    'jackson.lee@email.com': 'liming@email.com',
    '+$39.00': '+¥273.00',
    'Isabella Nguyen': '王芳',
    'isabella.nguyen@email.com': 'wangfang@email.com',
    '+$299.00': '+¥2,093.00',
    'William Kim': '刘伟',
    'will@email.com': 'liuwei@email.com',
    '+$99.00': '+¥693.00',
    'Sofia Davis': '陈雪',
    'sofia.davis@email.com': 'chenxue@email.com',
    '$45,231.89': '¥45,231.89',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    Overview: 'Overview',
    Customers: 'Customers',
    Products: 'Products',
    Settings: 'Settings',
    'Search...': 'Search...',
    Dashboard: 'Dashboard',
    'Jan 20, 2023 - Feb 09, 2023': 'Jan 20, 2023 - Feb 09, 2023',
    Download: 'Download',
    Analytics: 'Analytics',
    Reports: 'Reports',
    Notifications: 'Notifications',
    'Total Revenue': 'Total Revenue',
    '+20.1% from last month': '+20.1% from last month',
    Subscriptions: 'Subscriptions',
    '+180.1% from last month': '+180.1% from last month',
    Sales: 'Sales',
    '+19% from last month': '+19% from last month',
    'Active Now': 'Active Now',
    '+201 since last hour': '+201 since last hour',
    'Recent Sales': 'Recent Sales',
    'You made 265 sales this month.': 'You made 265 sales this month.',
    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Apr',
    May: 'May',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: 'Dec',
    'Olivia Martin': 'Olivia Martin',
    'olivia.martin@email.com': 'olivia.martin@email.com',
    '+$1,999.00': '+$1,999.00',
    'Jackson Lee': 'Jackson Lee',
    'jackson.lee@email.com': 'jackson.lee@email.com',
    '+$39.00': '+$39.00',
    'Isabella Nguyen': 'Isabella Nguyen',
    'isabella.nguyen@email.com': 'isabella.nguyen@email.com',
    '+$299.00': '+$299.00',
    'William Kim': 'William Kim',
    'will@email.com': 'will@email.com',
    '+$99.00': '+$99.00',
    'Sofia Davis': 'Sofia Davis',
    'sofia.davis@email.com': 'sofia.davis@email.com',
    '$45,231.89': '$45,231.89',
}

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
