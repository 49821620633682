import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    PublishingLibrary: '正在发布组件库',
    PleaseTryAgain: '请稍后再试',
    SuccessfullyPublishedLibrary: '发布更新成功',
    SuccessfullyPublishedLibrary_synonyms1: '组件库已发布',
    HDcKoP: '当前文档位于「草稿」，不允许发布组件库',
    TeamsAreNot: '当前文档位于「其他团队」下，不允许按「企业」发布组件库',
    FailedToPublish: '发布更新失败',
    FailedToPublish_synonyms1: '发布组件库失败',
    PublishingLibrary_synonyms1: '正在发布更新',
} as const

export const enTranslation = {
    PublishingLibrary: 'Publishing library',
    PleaseTryAgain: 'try again later.',
    SuccessfullyPublishedLibrary: 'Successfully published library updates',
    SuccessfullyPublishedLibrary_synonyms1: 'Successfully published library ',
    HDcKoP: '当前文档位于「草稿」，不允许发布组件库',
    TeamsAreNot: 'You cannot publish library to the organization in the External teams',
    FailedToPublish: 'An error occurred while publishing',
    FailedToPublish_synonyms1: 'An error occurred while publishing',
    PublishingLibrary_synonyms1: 'Publishing library',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
