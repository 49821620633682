/* eslint-disable no-restricted-imports */
import { DragEventHandler, HTMLAttributes, useCallback } from 'react'
import { cmdChangePopupState } from '../../../document/command/document-command'
import { PopupStateType } from '../../../document/node/node'
import { useCanvasCursor, useMouseOnCanvas } from '../../../main/app-context'
import { RootComponentId } from '../../../main/canvas/canvas'
import { useViewState } from '../../../view-state-bridge'
import { EditorDataTestId } from '../../../window'
import { useCommand, useHandler } from '../../context/document-context'
import styles from './drop-canvas-area.module.less'
import { dropToCanvasHandlerV2 } from './drop-to-canvas-handler'

interface DropImageProps extends HTMLAttributes<HTMLDivElement> {}

export function DropToCanvasArea(props: DropImageProps) {
    const { className } = props
    const handler = useHandler()
    const cursorManager = useCanvasCursor()
    const mouseOnCanvas = useMouseOnCanvas()
    const docReadonly = useViewState('docReadonly')
    const popupState = useViewState('popupState')
    const command = useCommand()

    const onDragOver: DragEventHandler<HTMLDivElement> = useCallback((e) => {
        e.preventDefault()
    }, [])

    const onDrop: DragEventHandler<HTMLDivElement> = useCallback(
        (e) => {
            e.preventDefault()
            if (docReadonly) {
                return
            }
            handler.handle(dropToCanvasHandlerV2, e)
        },
        [handler, docReadonly]
    )

    const overlayRefCallback = useCallback(
        (e: HTMLDivElement | null) => {
            e ? cursorManager.attachCanvasTarget(e) : cursorManager.detachCanvasTarget()
            mouseOnCanvas.provideCanvasViewElement(e)
        },
        [cursorManager, mouseOnCanvas]
    )

    const onCanvasMouseDown = useCallback(() => {
        if (popupState?.type === PopupStateType.POPUP_STATE_TYPE_CURRENT_PAGE_BACKGROUND_EDIT) {
            command.invoke(cmdChangePopupState, {
                type: PopupStateType.POPUP_STATE_TYPE_NONE,
                multiPopup: [],
                reciprocalIndex: -1,
            })
        }
    }, [popupState?.type, command])

    return (
        <div
            ref={overlayRefCallback}
            onDragOver={onDragOver}
            onDrop={onDrop}
            className={`${styles.container} ${className ?? ''}`}
            id={RootComponentId.CanvasOverlay}
            data-testid={EditorDataTestId.CanvasOverlay}
            onMouseDown={onCanvasMouseDown}
        ></div>
    )
}
