import { DELETE, GET, POST } from '@tutor/network-core'
import type { LibraryId } from '../interface/component-style-library-id'
import type { DefaultLibrarySubscription, LibrarySubscriptionVO } from '../interface/library'
import { ResourceType } from '../interface/type'
import { BaseCommonRequest } from './base-request'

/**
 * 获取某文档订阅的组件库列表
 */
@GET
export class GetLibrarySubscription extends BaseCommonRequest<LibrarySubscriptionVO[]> {
    constructor(private readonly docId: string) {
        super()
    }

    public override requestUrl() {
        return `library-subscriptions/${this.docId}`
    }
}

/**
 * 订阅某个组件库
 */
@POST
export class SubscribeLibrary extends BaseCommonRequest<boolean> {
    constructor(private readonly docId: string, private readonly libraryId: string) {
        super()
    }

    public override requestUrl() {
        return `library-subscriptions/${this.docId}/${this.libraryId}`
    }
}

/**
 * 取消订阅某个组件库
 */
@DELETE
export class UnSubscribeLibrary extends BaseCommonRequest<boolean> {
    constructor(private readonly docId: string, private readonly libraryId: string) {
        super()
    }

    public override requestUrl() {
        return `library-subscriptions/${this.docId}/${this.libraryId}`
    }
}

/**
 * 订阅某个默认组件库
 */
@POST
export class SubscribeDefaultLibrary extends BaseCommonRequest<void> {
    constructor(
        private readonly resourceId: string,
        private resourceType: ResourceType,
        private readonly libraryId: LibraryId
    ) {
        super()
    }

    public override requestArgument() {
        return {
            resourceType: this.resourceType,
        }
    }

    public override requestUrl() {
        return `library-subscriptions/default/${this.resourceId}/${this.libraryId}`
    }
}

/**
 * 取消订阅某个默认组件库
 */
@DELETE
export class UnSubscribeDefaultLibrary extends BaseCommonRequest<void> {
    constructor(
        private readonly resourceId: string,
        private resourceType: ResourceType,
        private readonly libraryId: LibraryId
    ) {
        super()
    }

    public override requestArgument() {
        return {
            resourceType: this.resourceType,
        }
    }

    public override requestUrl() {
        return `library-subscriptions/default/${this.resourceId}/${this.libraryId}`
    }
}

/**
 * 获取默认组件库列表
 */
@GET
export class GetDefaultLibrarySubscription extends BaseCommonRequest<DefaultLibrarySubscription[]> {
    constructor(private readonly resourceId: string, private resourceType: ResourceType) {
        super()
    }

    public override requestArgument() {
        return {
            resourceType: this.resourceType,
        }
    }

    public override requestUrl() {
        return `library-subscriptions/default/${this.resourceId}`
    }
}
