import { MonoIconCommonDevMode16 } from '../../../../../ui-lib/src'
import styles from './index.module.less'
import { translation } from './index.translation'
export function DevModeTips({ hide }: { hide?: () => void }) {
    return (
        <div className={`mx-4 px-4 py-3 ${styles.tipsBody}`} data-testid="dev-mode-share-tips">
            <div className={styles.tipsIcon}>
                <MonoIconCommonDevMode16 />
            </div>
            <div className={styles.tipsText}>{translation('TsFwaoiDM')}</div>
            <div className={styles.tipsConfirm} onClick={hide} data-testid="dev-mode-share-tips-hide">
                {translation('GotIt')}
            </div>
        </div>
    )
}
