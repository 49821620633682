/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import React, {
    createContext,
    HTMLAttributes,
    isValidElement,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { TabElement } from '../../../../../../ui-lib/src'
import { ToKeyCode } from '../../../../document/util/keycode'
import { isKey, KeyboardCode } from '../../../../kernel/keyboard/keyboard-event-handler'
import { KeyboardReceiver } from '../../../../main/keyboard-receiver/component'
import { IconGroup } from './icon-group'
import classes from './icon-group.module.less'

/**
 * context
 */

interface SelectIconGroupContextProp {
    disabled: boolean
    onClickIcon: (value: any) => void
    setPreselectValue: (value: undefined) => void
}

const defaultSelectIconGroupContext: SelectIconGroupContextProp = {
    disabled: false,
    onClickIcon: () => {},
    setPreselectValue: () => {},
}

const SelectIconGroupContext = createContext<SelectIconGroupContextProp>(defaultSelectIconGroupContext)

/**
 * 支持，上下左右，enter，esc键的IconGroup
 */

interface SelectIconGroupProps extends HTMLAttributes<HTMLDivElement> {
    onClickIcon: (value: any) => void
    disabled?: boolean
    dataTestId?: string
    optionValue?: any[]
}

function isValidValue(value: any): boolean {
    return value !== undefined
}

function _SelectIconGroup(
    { className, disabled, dataTestId, children, onClickIcon, optionValue, ...otherProps }: SelectIconGroupProps,
    ref: React.Ref<HTMLDivElement>
) {
    const optionValueBychildType = useMemo(() => {
        const optionValue_: any[] = []
        React.Children.forEach(children, (child) => {
            if (isValidElement(child) && child.type === IconGroup.Item) {
                const value = child.props.value
                isValidValue(value) && optionValue_.push(value)
            }
        })
        return optionValue_
    }, [children])

    const optionValueSet = useMemo(() => {
        return optionValue ?? optionValueBychildType
    }, [optionValue, optionValueBychildType])

    const [preSelectValue, setPreSelectValue] = useState()

    const _onKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (!disabled && document.activeElement && document.activeElement.className === 'tabElemnt-iconGroup') {
                if (
                    isKey(e, KeyboardCode.ENTER) ||
                    isKey(e, KeyboardCode.ARROW_DOWN) ||
                    isKey(e, KeyboardCode.ARROW_UP) ||
                    isKey(e, KeyboardCode.ARROW_LEFT) ||
                    isKey(e, KeyboardCode.ARROW_RIGHT) ||
                    isKey(e, KeyboardCode.SPACE)
                ) {
                    const deltaIndex = isKey(e, KeyboardCode.ARROW_UP) || isKey(e, KeyboardCode.ARROW_LEFT) ? -1 : 1
                    const currentIndex = optionValueSet.findIndex((v) => v === preSelectValue)
                    const nextIndex =
                        deltaIndex + currentIndex < 0
                            ? deltaIndex + currentIndex + optionValueSet.length
                            : (deltaIndex + currentIndex) % optionValueSet.length
                    setPreSelectValue(optionValueSet[nextIndex])
                    onClickIcon(optionValueSet[nextIndex])
                    return false
                } else if (isKey(e, KeyboardCode.ESCAPE)) {
                    ;(document.activeElement as any)?.blur()
                    return false
                }
            }

            return true
        },
        [disabled, onClickIcon, optionValueSet, preSelectValue]
    )

    return (
        <div ref={ref} className={classnames(classes.container, [className])} data-testid={dataTestId} {...otherProps}>
            <KeyboardReceiver keyCode={ToKeyCode.All} onKeydown={_onKeyDown}>
                <TabElement disabled={disabled} className="tabElemnt-iconGroup" />
            </KeyboardReceiver>
            <div className={classes.childrenContainer}>
                <SelectIconGroupContext.Provider
                    value={{ disabled: !!disabled, setPreselectValue: setPreSelectValue, onClickIcon }}
                >
                    {children}
                </SelectIconGroupContext.Provider>
            </div>
        </div>
    )
}

interface SelectIconGroupItemProps extends HTMLAttributes<HTMLDivElement> {
    value: any
    selected?: boolean
    icon?: React.ReactNode
    dataTestId?: string
}

function _SelectIconGroupItem(props: SelectIconGroupItemProps, ref: React.Ref<HTMLDivElement>) {
    const { value, className, selected, icon, dataTestId, onClick, onMouseDown, ...otherProps } = props
    const { disabled, setPreselectValue, onClickIcon } = useContext(SelectIconGroupContext)

    useEffect(() => {
        selected && setPreselectValue(value)
    })

    const _onClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            !disabled && onClickIcon(value)
            !disabled && onClick?.(e)
        },
        [disabled, onClickIcon, value, onClick]
    )

    const _onMouseDown = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            !disabled && onMouseDown?.(e)
        },
        [onMouseDown, disabled]
    )

    return (
        <div
            ref={ref}
            className={classnames(classes.item, [className], {
                [classes.selected]: selected,
                [classes.disabled]: disabled,
            })}
            onClick={_onClick}
            onMouseDown={_onMouseDown}
            data-testid={dataTestId}
            data-is-disabled={disabled}
            {...otherProps}
        >
            {icon ?? props.children}
        </div>
    )
}

const SelectIconGroupRef = React.forwardRef(_SelectIconGroup)
const SelectIconGroupItemRef = React.forwardRef(_SelectIconGroupItem)

export const SelectIconGroup = Object.assign(SelectIconGroupRef, { Item: SelectIconGroupItemRef })
