import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    ATeamMust: '{{projectName}}不可为空',
    EnterTeamName: '请输入',
} as const

export const enTranslation = {
    ATeamMust: 'A {{projectName}} must have a name.',
    EnterTeamName: '',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
