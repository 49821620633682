/* eslint-disable no-restricted-imports */
import * as Sentry from '@sentry/react'
import { Wasm as WasmIntegration } from '@yuanfudao/sentry-wasm'
import { getSentryVersion, isCypress } from '../../../../util/src'
import { environment } from '../../environment'
import { MetricCollector } from '../metric-collector'

let sentryEnabled = true

// 在关闭 sentry 时延迟执行，保证已经在队列中的事件都能被 flush 出去
const CLOSE_TIMEOUT = 2000

export class SkipSentryError extends Error {}

export function tryInitSentry() {
    // eslint-disable-next-line no-process-env
    if (!process.env.ENABLE_SENTRY) {
        return
    }
    if (isCypress()) {
        return
    }
    const options: Sentry.BrowserOptions = {
        dsn: environment.sentryDsn,
        release: getSentryVersion(),
        integrations: [new WasmIntegration()],
        maxBreadcrumbs: 100,
        sampleRate: 1,
        autoSessionTracking: false, // 关闭 sentry 的性能监控
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        beforeSend: async (event, hint) => {
            if (!sentryEnabled) {
                return null
            }

            if (hint.originalException instanceof SkipSentryError) {
                return null
            }

            // set handled to false for all exceptions
            event.exception?.values?.forEach((value) => {
                if (value.mechanism !== undefined) {
                    value.mechanism.handled = false
                } else {
                    ;(value.mechanism as any) = { handled: false }
                }
            })
            if (!event.tags) {
                event.tags = {}
            }
            event.tags.clientType = MetricCollector.getClientType()
            return event
        },
        ignoreErrors: [
            'Failed to fetch',
            /^RequestFailed.*/,
            /^failed to report metrics 401.*/,
            'TimeoutError: Transaction timed out due to inactivity.',
            /^Request timed out.*/,
            /^Network Error.*/,
            /.*chrome-extension.*/,
            "Failed to execute 'exitFullscreen' on 'Document': Document not active",
            /Request has been cancelled.*/,
            /NetworkError.*/,
            /Unable to preload CSS.*/,
            /.*QuotaExceededError.*/,
            /motiff_network_error.*/,
            /^SentryIgnoreError:.*/,
            "Can't find variable: WeixinJSBridge",
            /^UnknownError: .*/,
            "Failed to execute 'removeChild' on 'Node'",
            'AbortError: The connection was closed.',
            /.*GPU Lost.*/,
            'Error: Method not found',
            /.*init wasm failed.*/,
            /abort due to route id.*/,
            /AbortError.*/,
            /^ImageLibWorkerError: .*/,
            /^PreloadError: .*/,
        ],
    }

    Sentry.init(options)
}

export function enableSentry() {
    sentryEnabled = true
}

export function disableSentry(timeout = CLOSE_TIMEOUT) {
    sentryEnabled = false
    Sentry.close(timeout)
}

export { Sentry }
