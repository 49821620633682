import { useState } from 'react'
import { featureSwitchManager } from '../core'
import { FeatureSwitch } from '@wukong/feature-switch'

/**
 * 在 react 里获取某个开关，当开关不存在时会 throw error
 * @param featureSwitch
 * @returns
 */
export const useFeatureSwitch = (featureSwitch: FeatureSwitch): boolean => {
    const [flag] = useState<boolean>(featureSwitchManager.isEnabled(featureSwitch))
    return flag
}
