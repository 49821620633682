import { POST } from '@tutor/network-core'
import { DocID } from '../interface/type'
import { BaseNotifyRequest } from './base-request'

@POST
export class PubMirrorNotifyEvent extends BaseNotifyRequest<void> {
    constructor(private readonly docId: DocID, private readonly frameId: string, private readonly frameName: string) {
        super()
    }

    public override requestUrl() {
        return 'pub-notify-event/mirror'
    }

    public override requestArgument() {
        return {
            docId: this.docId,
            frameId: this.frameId,
            frameName: this.frameName,
        }
    }
}
