import { command } from 'ccstate'
import { TraceableAbortSignal } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { transaction } from '../../../../../../util/src/abort-controller/traceable-transaction'
import { RESET$ } from '../../../../../../util/src/ccstate-helper/action'
import { traceable } from '../../../../../../util/src/ccstate-helper/traceable'
import { EffectController } from '../../../../../../util/src/effect-controller'
import { MetricCollector } from '../../../../kernel/metric-collector'
import { editorCanvas$, refreshEditorCanvas$ } from '../../../atoms/create-canvas'
import { destroyEditorController$, setupEditorContext$ } from '../../../atoms/editor-context'
import { setupEditor$ } from '../../../atoms/setup-editor'
import { afterOnDocument$ } from '../../5-after-on-document/web'
import { afterSetupEditor$ } from './after-setup-editor'

export const startSetupEditorFlow$ = traceable(
    'hulh01@kanyun.com',
    command(async ({ get, set }, signal: TraceableAbortSignal) => {
        const { act } = transaction(signal)

        act('reset Metric', () => {
            MetricCollector.reset()

            return () => {
                MetricCollector.onExitEditor()
            }
        })

        const canvas = get(editorCanvas$)

        act('reset canvas when exit editor', () => {
            return () => {
                set(refreshEditorCanvas$)
            }
        })

        // TODO(hulh01) 去除 controller
        const controller = new EffectController('Lifecycle.AfterPrepareCanvas.PrepareEditorContext')

        const editorContext = await set(setupEditor$, signal, canvas, controller)

        signal.throwIfAborted()

        act('bridge crash callback', () => {
            const fn = editorContext.bridge.states.subscribe(() => {
                set(destroyEditorController$)
            })

            return () => {
                fn()
            }
        })

        act('set editor to global', () => {
            set(setupEditorContext$, editorContext)

            return () => {
                set(setupEditorContext$, RESET$)
            }
        })

        act('inject ondocument callback', () => {
            const callback = (loaded: boolean) => {
                if (loaded) {
                    set(afterOnDocument$, signal)

                    editorContext.viewStateBridge.unregister('documentLoaded', callback)
                }
            }

            editorContext.viewStateBridge.register('documentLoaded', callback)

            return () => {
                editorContext.viewStateBridge.unregister('documentLoaded', callback)
            }
        })

        act('destroy controller after leave editor', () => {
            return () => {
                controller.destroy()
            }
        })

        await set(afterSetupEditor$, signal, editorContext)
    })
)
