import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    iLWYnK: '评论内容过长，最多{{length}}字',
} as const

export const enTranslation = {
    iLWYnK: 'Exceeds maximum length limit of {{length}} characters',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string | number>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
