/* eslint-disable no-restricted-imports */
import { Request, RequestCommonConfig, RequestExecutor, ResponseType } from '@tutor/network-core'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { environment, HttpPrefixKey, IN_JEST_TEST } from '../../environment'
import { WkCLog } from '../clog/wukong/instance'

Request.requestExecutor = new RequestExecutor()
Request.commonConfig = new RequestCommonConfig()

export class BaseRequest<T = void> extends Request<T> {
    private signal: TraceableAbortSignal | undefined

    /**
     * 网络请求的发送支持 signal 参数，当 signal 被 abort 后，会在请求结束 / 错误时优先抛出 AbortError
     * @param signal
     */
    public startWithSignal(signal: TraceableAbortSignal): Promise<T>
    public startWithSignal(signal: TraceableAbortSignal, unwrap: false): Promise<Request>
    public startWithSignal(signal: TraceableAbortSignal, unwrap?: boolean): Promise<T | Request> {
        this.signal = signal
        return unwrap !== undefined ? super.start(false) : super.start()
    }

    public override requestDidSuccess(): void {
        this.signal?.throwIfAborted()
    }

    public override requestWillStart(): void {}

    public override requestDidFailed(error: Error): boolean {
        this.signal?.throwIfAborted()

        WkCLog.log('motiff_network_error', {
            value: `failed to request, url: ${this.requestUrl}, error: ${error}`,
        })
        window.dispatchEvent(new CustomEvent('request-error', { detail: { statusCode: this.responseStatusCode } }))
        return false
    }

    public override requestTimeout(): number {
        if (IN_JEST_TEST) {
            return 0
        } else {
            return super.requestTimeout()
        }
    }
}

export class BaseCommonRequest<T = any> extends BaseRequest<T> {
    public override baseUrl(): string {
        return environment.httpPrefixes[HttpPrefixKey.COMMON_API]
    }

    public override responseType() {
        return ResponseType.JSON
    }
}

export class BaseNotifyRequest<T = any> extends BaseRequest<T> {
    public override baseUrl(): string {
        return environment.httpPrefixes[HttpPrefixKey.NOTIFY_API]
    }

    public override responseType() {
        return ResponseType.JSON
    }
}

export class SynergyConnectorRequest<T = any> extends BaseRequest<T> {
    public override baseUrl(): string {
        return environment.httpPrefixes[HttpPrefixKey.SYNERGY_CONNECTOR_API]
    }

    public override responseType() {
        return ResponseType.Arraybuffer
    }
}

export class InnerRequest<T = any> extends BaseRequest<T> {
    public override baseUrl(): string {
        return environment.httpPrefixes[HttpPrefixKey.INNER_API]
    }

    public override responseType() {
        return ResponseType.JSON
    }
}
