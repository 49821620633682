import { Position } from '../../../../../../../ui-lib/src'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useCommand } from '../../../../context/document-context'
import { StyleColorViewOnly } from '../../../design/styles/style-color/style-color'
import { StyleTextViewOnly } from '../../../design/styles/style-text/style-text'
import { cmdChangePopupState } from '../../../../../document/command/document-command'
import { NodeId, PopupStateType } from '../../../../../document/node/node'

function PaintStyleSelectionPanel(prop: {
    width: number
    position: Position
    open: boolean
    handleClickOnStyleItem: (styleId: string) => void
    selectedStyleId: string
}) {
    const { width, selectedStyleId, position, open, handleClickOnStyleItem } = prop

    return (
        <StyleColorViewOnly
            width={width}
            open={open}
            position={position}
            selectStyleId={selectedStyleId}
            selectStyleNodeKey={''}
            onChangeStyle={handleClickOnStyleItem}
            isMixed={false}
        />
    )
}

function TextStyleSelectionPanel(prop: {
    width: number
    position: Position
    open: boolean
    handleClickOnStyleItem: (styleId: string) => void
    onClose: () => void
    selectedStyleId: string
}) {
    const { width, selectedStyleId, position, open, handleClickOnStyleItem } = prop

    return (
        <StyleTextViewOnly
            position={position}
            open={open}
            selectStyleId={selectedStyleId}
            onChangeStyle={(v) => {
                handleClickOnStyleItem(v)
            }}
            width={width}
        />
    )
}

export function useStyleSelect<T extends HTMLElement>(
    type: 'TEXT' | 'COLOR',
    setSelectedStyleId: (styleId: NodeId) => void,
    selectedStyleId: NodeId
) {
    const command = useCommand()

    const ref = useRef<T>()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    let panelPosition = { top: 0, left: 0 }
    let panelWidth = 276

    if (ref.current) {
        const { bottom, right } = ref.current.getBoundingClientRect()
        panelPosition = { top: bottom + 8, left: right }
        panelWidth = ref.current.clientWidth
    }

    const [styleSelectionPanelStatus, setStyleSelectionPanelStatus] = useState<boolean>(false)

    const closeStyleSelectionPanel = useCallback(() => {
        if (styleSelectionPanelStatus) {
            setStyleSelectionPanelStatus(false)
        }
    }, [styleSelectionPanelStatus])

    // mouseDown 于样式选择面板之外时, 关闭样式选择面板
    useEffect(() => {
        document.addEventListener('mousedown', closeStyleSelectionPanel)

        return () => {
            document.removeEventListener('mousedown', closeStyleSelectionPanel)
        }
    }, [closeStyleSelectionPanel])

    const openStyleSelectionPanel = useCallback(() => {
        if (type === 'COLOR') {
            command.invoke(cmdChangePopupState, {
                type: PopupStateType.POPUP_STATE_TYPE_A_I_DESIGN_LINT_PAINT_STYLE,
                reciprocalIndex: -1,
                multiPopup: [],
            })
        } else if (type === 'TEXT') {
            command.invoke(cmdChangePopupState, {
                type: PopupStateType.POPUP_STATE_TYPE_A_I_DESIGN_LINT_TEXT_STYLE,
                reciprocalIndex: -1,
                multiPopup: [],
            })
        }

        setStyleSelectionPanelStatus(true)
    }, [command, setStyleSelectionPanelStatus, type])

    const toggleStyleSelectionPanel = useCallback(
        (_: MouseEvent) => {
            if (styleSelectionPanelStatus) {
                closeStyleSelectionPanel()
            } else {
                openStyleSelectionPanel()
            }
        },
        [closeStyleSelectionPanel, openStyleSelectionPanel, styleSelectionPanelStatus]
    )

    const refCallback = useCallback(
        (element: T | null) => {
            if (element) {
                ref.current = element
                element.addEventListener('click', toggleStyleSelectionPanel)
            } else if (ref.current) {
                ref.current?.removeEventListener('click', toggleStyleSelectionPanel)
            }
        },
        [ref, toggleStyleSelectionPanel]
    )

    const StyleSelect = useCallback(() => {
        return type === 'COLOR' ? (
            <div>
                <PaintStyleSelectionPanel
                    width={panelWidth}
                    position={panelPosition}
                    open={styleSelectionPanelStatus}
                    handleClickOnStyleItem={(styleId: NodeId) => {
                        setSelectedStyleId(styleId)
                        setStyleSelectionPanelStatus(false)
                    }}
                    selectedStyleId={selectedStyleId}
                />
            </div>
        ) : (
            <TextStyleSelectionPanel
                width={panelWidth}
                position={panelPosition}
                onClose={closeStyleSelectionPanel}
                open={styleSelectionPanelStatus}
                handleClickOnStyleItem={(styleId: NodeId) => {
                    setSelectedStyleId(styleId)
                    setStyleSelectionPanelStatus(false)
                }}
                selectedStyleId={selectedStyleId}
            />
        )
    }, [
        closeStyleSelectionPanel,
        panelPosition,
        panelWidth,
        selectedStyleId,
        setSelectedStyleId,
        setStyleSelectionPanelStatus,
        styleSelectionPanelStatus,
        type,
    ])

    return { StyleSelect, refToAttachStyleSelect: refCallback, openStyleSelectionPanel, closeStyleSelectionPanel }
}
