import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    All: '全部',
    Top: '上',
    Bottom: '下',
    Left: '左',
    Right: '右',
    Custom: '自定义',
} as const

export const enTranslation = {
    All: 'All',
    Top: 'Top',
    Bottom: 'Bottom',
    Left: 'Left',
    Right: 'Right',
    Custom: 'Custom',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
