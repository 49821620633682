import { Wukong } from '@wukong/bridge-proto'
import constate from 'constate'
import { isNil } from 'lodash-es'
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react'
import { useLazyLoad } from '../../../../../util/src'
import {
    ComponentGetVO,
    ComponentSetGetVO,
    StyleGetVO,
    StyleWithoutDocIdGetVO,
} from '../../../kernel/interface/component-style'
import type { LibraryThumbnailKeyItem } from '../../../ui/component/component-style-library-v2/library-service/library-thumbnail-service/library-thumbnail-service'
import classes from './library-thumbnail-image.module.less'

export interface LibraryThumbnailImageProps {
    thumbnailData: Wukong.DocumentProto.IThumbnailData | undefined | null
    fontMissing?: boolean
    className?: string
    isImageLoaded?: boolean
}

export function buildThumbnailDataFromVO(
    vo: StyleWithoutDocIdGetVO | StyleGetVO | ComponentGetVO | ComponentSetGetVO
): Wukong.DocumentProto.IThumbnailData {
    return {
        isLocal: false,
        id: vo.nodeId,
        contentHash: vo.contentHash,
        url: vo.thumbnailDataPath,
        type: 'type' in vo ? vo.type : Wukong.DocumentProto.NodeType.NODE_TYPE_COMPONENT,
        width: 'minNodeWidth' in vo ? vo.minNodeWidth : null,
        height: 'minNodeHeight' in vo ? vo.minNodeHeight : null,
        effectStyleIconType:
            'effectStyleIconType' in vo
                ? (vo.effectStyleIconType as Wukong.DocumentProto.EffectStyleIconType | undefined)
                : null,
        layoutGridStyleIconType:
            'layoutGridStyleIconType' in vo
                ? (vo.layoutGridStyleIconType as Wukong.DocumentProto.LayoutGridStyleIconType | undefined)
                : null,
    }
}

export function buildThumbnailDataFromLocalPaintStyle(style: {
    id: string
    contentHash: string
}): Wukong.DocumentProto.IThumbnailData {
    return {
        isLocal: true,
        id: style.id,
        contentHash: style.contentHash,
        type: Wukong.DocumentProto.NodeType.NODE_TYPE_PAINT_STYLE,
    }
}

export function buildThumbnailDataFromLocalTextStyle(style: {
    id: string
    contentHash: string
}): Wukong.DocumentProto.IThumbnailData {
    return {
        isLocal: true,
        id: style.id,
        contentHash: style.contentHash,
        type: Wukong.DocumentProto.NodeType.NODE_TYPE_TEXT_STYLE,
    }
}

const LibraryThumbnailImageRetryDuration = 300
// 远端图片加载逻辑
export function useRemoteLibraryThumbnailImage({
    thumbnailData: generateThumbnailData,
    fontMissing = false,
    className,
}: LibraryThumbnailImageProps) {
    const fakeIntersectionDomRef = useRef<HTMLDivElement>(null)
    const {
        loadThumbnailDataFromCache,
        loadThumbnailData: originLoadThumbnailData,
        eraseThumbnailDataCache,
        getBrowserOnline,
    } = useLibraryThumbnailImageShareConfig()
    const [loadErrorId, setLoadErrorId] = useState<number>()
    const [thumbnailData, setThumbnailData] = useState<LibraryThumbnailKeyItem | null>(() =>
        loadThumbnailDataFromCache(generateThumbnailData ?? null)
    )
    const onImageLoad = useCallback(() => {
        setLoadErrorId(0)
    }, [])

    const onImageLoadError = useCallback(() => {
        setLoadErrorId((prev) => (prev ?? 0) + 2)
    }, [])

    const loadThumbnailData = useCallback(
        async (clearCache = false, isDestroy?: () => boolean) => {
            if (!generateThumbnailData) {
                return
            }

            try {
                clearCache && generateThumbnailData.url && eraseThumbnailDataCache(generateThumbnailData.url)

                const result = await originLoadThumbnailData(generateThumbnailData)
                // 当前进程已被销毁，不再更新 data
                if (isDestroy?.()) {
                    return
                }
                setThumbnailData(result)
            } catch {
                setTimeout(() => {
                    // 当前进程已被销毁，不再响应 error
                    if (isDestroy?.()) {
                        return
                    }
                    onImageLoadError()
                }, LibraryThumbnailImageRetryDuration)
            }
        },
        [eraseThumbnailDataCache, generateThumbnailData, onImageLoadError, originLoadThumbnailData]
    )

    useEffect(() => {
        let handleOnline: () => void
        let destroyed = false

        if (loadErrorId) {
            if (!getBrowserOnline()) {
                // 加载失败且离线时，监听网络恢复后，再重新加载
                handleOnline = () => {
                    window.removeEventListener('online', handleOnline)
                    loadThumbnailData(false, () => destroyed)
                }
                window.addEventListener('online', handleOnline)
            } else {
                // 非首次加载失败且在线时，清除缓存，并立刻重新加载
                loadThumbnailData(loadErrorId > 1, () => destroyed)
            }
        }

        return () => {
            destroyed = true
            handleOnline && window.removeEventListener('online', handleOnline)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadErrorId])

    const startLoad = useCallback(() => {
        setLoadErrorId((prev) => (prev === undefined ? 1 : prev))
    }, [])

    useLazyLoad({ targetRef: fakeIntersectionDomRef, loadFn: startLoad }, [])

    return {
        thumbnailData,
        imageSrc: thumbnailData?.data || '',
        imageStyle: getLibraryThumbnailImgStyle({
            ...(thumbnailData ?? {}),
            fontMissing,
        }),
        onImageLoadError,
        onImageLoad,
        fakeIntersectionDomRef,
        className: className,
        isLoaded: loadErrorId === 0,
    }
}

// 本地图片加载逻辑
export function useLocalLibraryThumbnailImage({
    thumbnailData: _thumbnailData,
    fontMissing = false,
    className,
    isImageLoaded,
}: LibraryThumbnailImageProps) {
    const fakeIntersectionDomRef = useRef<HTMLDivElement>(null)
    const { loadThumbnailDataFromCache, loadThumbnailData: originLoadThumbnailData } =
        useLibraryThumbnailImageShareConfig()
    const [thumbnailData, setThumbnailData] = useState<LibraryThumbnailKeyItem | null>(() =>
        loadThumbnailDataFromCache(_thumbnailData ?? null)
    )
    const [changeFlag, setChangeFlag] = useState<number>(0)

    const loadThumbnailData = useCallback(
        async (isDestroyed: () => boolean) => {
            if (!_thumbnailData || (!isNil(isImageLoaded) && !isImageLoaded)) {
                return
            }

            const result = await originLoadThumbnailData(_thumbnailData)

            if (!isDestroyed()) {
                setThumbnailData(result)
            }
        },
        [_thumbnailData, isImageLoaded, originLoadThumbnailData]
    )

    useEffect(() => {
        let destroyed = false

        if (changeFlag) {
            loadThumbnailData(() => destroyed)
        }

        return () => {
            destroyed = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeFlag])

    useEffect(() => {
        if (_thumbnailData?.contentHash) {
            setChangeFlag((prev) => (prev ? prev + 1 : prev))
        }
    }, [_thumbnailData?.contentHash, isImageLoaded])

    const startLoad = useCallback(async () => {
        setChangeFlag((prev) => prev + 1)
    }, [])

    useLazyLoad({ targetRef: fakeIntersectionDomRef, loadFn: startLoad }, [])

    return {
        thumbnailData,
        imageSrc: thumbnailData ? `data:image/png;base64, ${thumbnailData.data}` : '',
        imageStyle: getLibraryThumbnailImgStyle({
            ...(thumbnailData ?? {}),
            fontMissing,
        }),
        fakeIntersectionDomRef,
        className: className,
        isLoaded: true,
    }
}

function getLibraryThumbnailImgStyle({
    type,
    width,
    height,
    fontMissing,
}: {
    type?: Wukong.DocumentProto.NodeType
    width?: number | null
    height?: number | null
    fontMissing?: boolean | null
}): {
    style: CSSProperties
    className?: string
    withBorder?: boolean
} {
    if (isNil(type) || fontMissing || type === Wukong.DocumentProto.NodeType.NODE_TYPE_TEXT_STYLE) {
        return { style: {}, className: '' }
    }
    if (
        type === Wukong.DocumentProto.NodeType.NODE_TYPE_PAINT_STYLE ||
        type === Wukong.DocumentProto.NodeType.NODE_TYPE_EFFECT_STYLE ||
        type == Wukong.DocumentProto.NodeType.NODE_TYPE_LAYOUT_GRID_STYLE
    ) {
        return {
            className: classes.styleIconThumbnailBg,
            style: {},
            withBorder: true,
        }
    }

    // 当节点实际尺寸（width * height）在容器里里放得下，则保持原尺寸；反之放不下，则等比例缩放至容器内的最大尺寸
    return {
        style: {
            width: width || 'auto',
            height: height || 'auto',
            objectFit: 'contain',
        },
    }
}

// 为了在工作台、编辑器复用组件库的组件，提供此 context 用于灵活获取配置
function useLibraryThumbnailImageShareConfigHook(props: {
    loadThumbnailDataFromCache: (arg: Wukong.DocumentProto.IThumbnailData | null) => LibraryThumbnailKeyItem | null
    loadThumbnailData: (arg: Wukong.DocumentProto.IThumbnailData) => Promise<LibraryThumbnailKeyItem | null>
    eraseThumbnailDataCache: (thumbnailDataPath: string) => void
    getBrowserOnline: () => boolean
}) {
    return props
}

export const [LibraryThumbnailImageShareConfigProvider, useLibraryThumbnailImageShareConfig] = constate(
    useLibraryThumbnailImageShareConfigHook
)
