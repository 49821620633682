import { translation } from './download-progress-message.translation'
/* eslint-disable no-restricted-imports */
import { useEffect, useState } from 'react'
import { CircleProgress, useAnimation } from '../../../../../../../ui-lib/src'
import style from '../tool-sync-status.module.less'

export function DownloadProgressMessage({
    progress,
    onProgressEnd,
    progressMsg,
    onCancel,
}: {
    progress: number
    onProgressEnd: () => void
    progressMsg: string
    onCancel: () => void
}) {
    const [syncProgress, setSyncProgress] = useState(0)

    const scheduler = useAnimation(100)
    useEffect(() => {
        scheduler.current.updateHooks({
            onAnimationUpdate: setSyncProgress,
        })
    }, [scheduler])

    useEffect(() => {
        if (typeof progress === 'number' && progress >= 0) {
            scheduler.current?.updateTarget(progress)
        }
    }, [progress, scheduler])

    useEffect(() => {
        if (syncProgress >= 100) {
            onProgressEnd()
        }
    }, [onProgressEnd, syncProgress])

    return (
        <div className={style.syncToast} style={{ padding: 0 }}>
            <>
                <CircleProgress progress={syncProgress} color="white" size={12} className={style.toastIcon} />

                <div className={style.toastText} data-testid="sync-progress-ongoing">
                    {progressMsg}
                </div>
                <div className={style.cancelBtn} onClick={onCancel}>
                    {translation('Cancel')}
                </div>
            </>
        </div>
    )
}
