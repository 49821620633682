import { Wukong } from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import { Select } from '../../../../../../ui-lib/src'
import { useLibraryComponentService } from '../../../../main/app-context'
import {
    LocalVariableSetItem,
    RemoteVariableSetItem,
    VariableModeSelectProps,
    VariableSetModeSelectionType,
} from './interface'
import { ModeConflictWarning } from './mode-conflict-icon'
import { getAutoModeName, handleConflictToast } from './util'
import { translation } from './variable-mode-inspect-select.translation'

export const VariableModeDisplaySelect = (
    props: {
        item: LocalVariableSetItem | RemoteVariableSetItem
    } & Pick<VariableModeSelectProps, 'source' | 'onChange'>
) => {
    const { item, source, onChange } = props
    const ids = useMemo(() => {
        return item.isLocal ? [(item as LocalVariableSetItem).id] : (item as RemoteVariableSetItem).localNodeIds
    }, [item])

    const getLabel = useCallback(
        (set: LocalVariableSetItem | RemoteVariableSetItem) => {
            switch (set.modeSelectionState.selectionType) {
                case VariableSetModeSelectionType.VARIABLE_SET_MODE_SELECTION_TYPE_DEFAULT:
                    return getAutoModeName(set.autoMode, source)
                case VariableSetModeSelectionType.VARIABLE_SET_MODE_SELECTION_TYPE_VALUE:
                    return set.modeSelectionState.isDeleted
                        ? translation('ModeDeleted')
                        : set.modeSelectionState.selectedMode.modeName
                case VariableSetModeSelectionType.VARIABLE_SET_MODE_SELECTION_TYPE_MIXED:
                    return translation('Mixed')
            }
        },
        [source]
    )

    const getType = useCallback((state: Wukong.DocumentProto.IVVariableSetModeSelectionState) => {
        switch (state.selectionType) {
            case VariableSetModeSelectionType.VARIABLE_SET_MODE_SELECTION_TYPE_DEFAULT:
                return 'auto'
            case VariableSetModeSelectionType.VARIABLE_SET_MODE_SELECTION_TYPE_VALUE:
                return state.isDeleted ? 'deleted' : 'value'
            case VariableSetModeSelectionType.VARIABLE_SET_MODE_SELECTION_TYPE_MIXED:
                return 'mixed'
        }
    }, [])

    const {
        libraryModalRouterService: { goToRemoteLibraryChangeList },
    } = useLibraryComponentService()

    const _onChange = (value: {
        variableSetIds: string[]
        mode: Wukong.DocumentProto.IVariableSetMode | undefined
        type: 'value' | 'mixed' | 'auto'
        conflictType: Wukong.DocumentProto.VariableSetModeConflictType | undefined
    }) => {
        onChange({ variableSetIds: value.variableSetIds, modeId: value.mode?.modeId ?? undefined, type: value.type })
        if (!isNil(value.conflictType) && !isNil(value.mode)) {
            handleConflictToast(value.mode, value.conflictType, goToRemoteLibraryChangeList)
        }
    }

    return (
        <Select.NormalSingleLevel
            label={getLabel(item)}
            value={{
                variableSetIds: ids,
                mode: getType(item.modeSelectionState) === 'value' ? item.modeSelectionState.selectedMode : undefined,
                type: getType(item.modeSelectionState),
                conflictType: item.variableModes.find(
                    (mode) => mode.variableSetMode.modeId === item.modeSelectionState.selectedMode?.modeId
                )?.conflictType,
            }}
            onChange={_onChange}
            maxWidth={420}
        >
            <Select.NormalSingleLevel.Option
                value={{
                    variableSetIds: ids,
                    mode: undefined,
                    type: 'mixed',
                    conflictType: undefined,
                }}
                hidden={getType(item.modeSelectionState) !== 'mixed'}
            >
                {translation('Mixed')}
            </Select.NormalSingleLevel.Option>

            <Select.NormalSingleLevel.Option
                value={{
                    variableSetIds: ids,
                    mode: undefined,
                    type: 'auto',
                    conflictType: undefined,
                }}
                splitLineBottom
            >
                {getAutoModeName(item.autoMode, source)}
            </Select.NormalSingleLevel.Option>
            {item.variableModes.map((mode) => (
                <Select.NormalSingleLevel.Option
                    key={mode.variableSetMode.modeId}
                    value={{
                        variableSetIds: ids,
                        mode: mode.variableSetMode,
                        type: 'value',
                        conflictType: mode.conflictType,
                    }}
                    backwardIcon={<ModeConflictWarning mode={mode} />}
                >
                    {mode.variableSetMode.modeName}
                </Select.NormalSingleLevel.Option>
            ))}
            <Select.NormalSingleLevel.Option
                value={{ variableSetIds: ids, mode: undefined, type: 'deleted', conflictType: undefined }}
                disabled={true}
                hidden={!item.modeSelectionState.isDeleted}
            >
                {translation('ModeDeleted')}
            </Select.NormalSingleLevel.Option>
        </Select.NormalSingleLevel>
    )
}
