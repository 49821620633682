/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { toMixed } from '../../../../../../../ui-lib/src'
import { DeepRequired, useViewState, ViewStateTokenTypeMap } from '../../../../../view-state-bridge'
import { TextValueUnit } from '../../../atom/inputs/utils/type'

export type SelectionTextState = ReturnType<typeof useTextState>

const isNormalType = (type: Wukong.DocumentProto.VTextStateType | null) => {
    return type == Wukong.DocumentProto.VTextStateType.V_TEXT_STATE_TYPE_NORMAL
}

export type VariationState = DeepRequired<Wukong.DocumentProto.IVTextFontVariationState>
const computeVariationState = (textState: ViewStateTokenTypeMap['selectionText'] | undefined) => {
    const hasTextInSelection = textState?.hasTextSelection
    if (!hasTextInSelection) {
        return {
            variationInfo: { hasValue: false } as VariationState,
            tabKey: Wukong.DocumentProto.TextSettingTabKey.TEXT_SETTING_TAB_KEY_BASICS,
            customStyleState: [],
        }
    }

    return {
        variationInfo: textState.variationInfo,
        tabKey: textState.tabKey,
        customStyleState: textState.customStyleState.styleToCustomAxes,
    }
}

const computeFontNameState = (textState: ViewStateTokenTypeMap['selectionText'] | undefined) => {
    const hasTextInSelection = textState?.hasTextSelection

    if (!hasTextInSelection) {
        return {
            isFontNameMixed: true,
            selectedFontName: null,

            isFamilyMixed: true,
            selectedFamily: '',
            selectedLocalizedFamily: '',
            selectedFontNames: [],
        } as const
    }

    const isFamilyMixed = !isNormalType(textState.fontFamilyName.type)
    const isFontNameMixed = !isNormalType(textState.fontName.type)

    const selectedFamily = isFamilyMixed ? toMixed() : textState.fontFamilyName.family
    const selectedLocalizedFamily = isFamilyMixed ? toMixed() : textState.fontFamilyName.localizedFamily
    const selectedFontName = isFontNameMixed
        ? null
        : {
              ...textState.fontName.value,
              localizedFamily: textState.fontName.value.family,
              localizedStyle: textState.fontName.value.style,
          }

    const selectedFontCustomAxes = isFontNameMixed
        ? null
        : {
              customAxesStyle: textState.fontName.customAxesStyle,
              customAxesStyleDisplay: textState.fontName.customAxesStyleDisplay,
          }

    const selectedFontNames = textState.selectionFontNames.map((item) => ({
        ...item,
        localizedFamily: item.family,
        localizedStyle: item.style,
    }))

    return {
        isFontNameMixed,
        selectedFontName,

        isFamilyMixed,
        selectedFamily,
        selectedLocalizedFamily,
        selectedFontNames,
        selectedFontCustomAxes,
    } as const
}

export const useTextState = () => {
    const textState = useViewState('selectionText')

    const hasTextInSelection = textState?.hasTextSelection

    const paragraphSpacing =
        hasTextInSelection && isNormalType(textState.paragraphSpacing.type)
            ? ({
                  mixed: false,
                  value: textState.paragraphSpacing.value,
              } as const)
            : ({
                  mixed: true,
              } as const)

    const paragraphIndent =
        hasTextInSelection && isNormalType(textState.paragraphIndent.type)
            ? ({
                  mixed: false,
                  value: textState.paragraphIndent.value,
              } as const)
            : ({
                  mixed: true,
              } as const)

    const textAlignHorizontal =
        hasTextInSelection && isNormalType(textState.textAlignHorizontal.type)
            ? ({
                  value: textState.textAlignHorizontal.value,
              } as const)
            : ({
                  value: null,
              } as const)

    const textAlignVertical =
        hasTextInSelection && isNormalType(textState.textAlignVertical.type)
            ? ({
                  value: textState.textAlignVertical.value,
              } as const)
            : ({
                  value: null,
              } as const)

    const textAutoResize =
        hasTextInSelection && isNormalType(textState.textAutoResize.type)
            ? ({
                  mixed: false,
                  value: textState.textAutoResize.value,
              } as const)
            : ({
                  mixed: true,
              } as const)
    const textTruncation =
        hasTextInSelection && isNormalType(textState.textTruncation.type)
            ? {
                  value: textState.textTruncation.value,
              }
            : ({
                  value: null,
              } as const)

    const maxLines =
        hasTextInSelection && isNormalType(textState.maxLines.type)
            ? ({
                  mixed: false,
                  value: textState.maxLines.value,
              } as const)
            : ({
                  mixed: true,
              } as const)

    const fontSize =
        hasTextInSelection && isNormalType(textState.fontSize.type)
            ? ({
                  mixed: false,
                  value: textState.fontSize.value,
              } as const)
            : ({
                  mixed: true,
              } as const)

    const lineHeight =
        hasTextInSelection && isNormalType(textState.lineHeight.type)
            ? ({
                  mixed: false,
                  value: textState.lineHeight.value as unknown as TextValueUnit,
              } as const)
            : ({
                  mixed: true,
              } as const)

    const lineHeightAutoValue =
        hasTextInSelection && isNormalType(textState.lineHeightAutoValue.type)
            ? ({
                  mixed: false,
                  value: textState.lineHeightAutoValue.value,
              } as const)
            : ({
                  mixed: true,
              } as const)

    const letterSpacing =
        hasTextInSelection && isNormalType(textState.letterSpacing.type)
            ? ({
                  mixed: false,
                  value: textState.letterSpacing.value as unknown as TextValueUnit,
              } as const)
            : ({
                  mixed: true,
              } as const)

    const textDecoration =
        hasTextInSelection && isNormalType(textState.textDecoration.type)
            ? ({
                  mixed: false,
                  value: textState.textDecoration.value,
              } as const)
            : ({ mixed: true, value: null } as const)

    const textCase =
        hasTextInSelection && isNormalType(textState.textCase.type)
            ? ({ mixed: false, value: textState.textCase.value } as const)
            : ({ mixed: true, value: null } as const)

    const textStyleType = hasTextInSelection ? textState.textStyleType : null
    const missFontInfo = hasTextInSelection ? textState.missFontInfo : { isMissStyle: false, isMissFamily: false }

    return {
        hasTextInSelection,
        paragraphSpacing,
        paragraphIndent,
        textAlignHorizontal,
        textAlignVertical,
        textAutoResize,
        textTruncation,
        maxLines,

        textStyleType,

        /* -------------------------------------------------------------------------- */

        fontSize,
        lineHeight,
        lineHeightAutoValue,
        letterSpacing,
        textDecoration,
        textCase,
        missFontInfo,

        /* -------------------------------------------------------------------------- */
        ...computeFontNameState(textState),
        /* -------------------------------------------------------------------------- */
        ...computeVariationState(textState),
    }
}
