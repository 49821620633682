import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { MonoIconMenuColorVariable12, Tooltip } from '../../../../../../../ui-lib/src'
import { PureBlockStruct } from '../../../atom/color-block/pure'
import { ColorSpace } from '../../blend/color-picker/utils/color-translate'
import { OverlayJsx2 } from '../../styles/style-tooltip/style-tooltip'
import classes from './color-variable-grid-v2.module.less'

export interface ColorVariableGridItemV2Props {
    className?: string
    name: string
    description: string
    color: Wukong.DocumentProto.IRGBA | null | undefined
    colorSpace: ColorSpace
    isSelected: boolean
    onClick: () => void
}
export function ColorVariableGridItemV2(props: ColorVariableGridItemV2Props) {
    const { className, name, description, color, colorSpace, isSelected, onClick } = props
    return (
        <Tooltip overlay={<OverlayJsx2 titleText={name} contentText={description} />}>
            <div
                className={classnames(classes.variable, className, {
                    [classes.selected]: isSelected,
                })}
                onMouseDown={(e) => e.stopPropagation()} // e.stopPropagation() 阻止拖拽
                onClick={onClick}
                data-testid="variable-item"
            >
                {color ? (
                    <PureBlockStruct
                        rgb={color}
                        colorSpace={colorSpace}
                        opacity={color.a / 255}
                        size={28}
                        className={classes.colorBlock}
                    />
                ) : (
                    <div className={classes.mixed}>
                        <MonoIconMenuColorVariable12 />
                    </div>
                )}
            </div>
        </Tooltip>
    )
}
