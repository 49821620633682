import { MonoIconCommonHigh16, MonoIconCommonMedium16, MonoIconCommonStandard16 } from '../../../../../../ui-lib/src'
import { translation } from './remix-design-system-contrast.translation'
import { SelectingButton } from './selecting-button'
import { useAIGenUIInContext } from './use-ai-gen-ui'

export function RemixDesignSystemContrast() {
    const { updateRemixConfig, tempRemixConfig } = useAIGenUIInContext()

    if (tempRemixConfig === null) {
        return null
    }

    return (
        <div className="flex flex-col gap-2 mb-0.5">
            <span className="m-0 p-0 wk-text-12 wk-font-semibold">{translation('contrast')}</span>
            <div className="flex flex-row gap-2">
                <SelectingButton
                    icon={<MonoIconCommonStandard16 className="w-4 h-4" />}
                    onClick={() => updateRemixConfig('contrast', 'Standard')}
                    isSelected={'Standard' === tempRemixConfig.get('contrast')}
                    extraClass="w-full flex flex-row items-center px-3 py-1.5"
                >
                    {translation('standard')}
                </SelectingButton>
                <SelectingButton
                    icon={<MonoIconCommonMedium16 className="w-4 h-4" />}
                    onClick={() => updateRemixConfig('contrast', 'Medium')}
                    isSelected={'Medium' === tempRemixConfig.get('contrast')}
                    extraClass="w-full flex flex-row items-center px-3 py-1.5"
                >
                    {translation('medium')}
                </SelectingButton>
                <SelectingButton
                    icon={<MonoIconCommonHigh16 className="w-4 h-4" />}
                    onClick={() => updateRemixConfig('contrast', 'High')}
                    isSelected={'High' === tempRemixConfig.get('contrast')}
                    extraClass="w-full flex flex-row items-center px-3 py-1.5"
                >
                    {translation('high')}
                </SelectingButton>
            </div>
        </div>
    )
}
