import { translation } from './menu.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { WKMenuItemType } from '../../../../../ui-lib/src'
import { useCallback } from 'react'
import { useViewState } from '../../../view-state-bridge'
import { useMenuContextState } from '../top-area/menu/context/menu-context'

const DocumentColorProfile = Wukong.DocumentProto.DocumentColorProfile

export const ColorProfileMenuStringResources = {
    Current: {
        [DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B]: 'sRGB',
        [DocumentColorProfile.DOCUMENT_COLOR_PROFILE_DISPLAY_P3]: 'Display P3',
    },
    Second: {
        [DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B]: `${translation('ChangeTo')} Display P3...`,
        [DocumentColorProfile.DOCUMENT_COLOR_PROFILE_DISPLAY_P3]: `${translation('ChangeTo')} sRGB...`,
    },
}
const R = ColorProfileMenuStringResources

export function useColorProfileMenuCore(): {
    colorProfile: Wukong.DocumentProto.DocumentColorProfile
    currentName: string
    nextName: string
    openToNext: () => void
} {
    const colorProfile =
        useViewState('documentColorProfile')?.profile ?? DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B
    const { changeColorProfileDialogState } = useMenuContextState()
    const _openToNext = changeColorProfileDialogState.openToNext

    const openToNext = useCallback(() => {
        _openToNext(
            colorProfile === DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B
                ? DocumentColorProfile.DOCUMENT_COLOR_PROFILE_DISPLAY_P3
                : DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B
        )
    }, [colorProfile, _openToNext])

    return {
        colorProfile,
        currentName: R.Current[colorProfile],
        nextName: R.Second[colorProfile],
        openToNext,
    }
}

export function useColorProfileMenu(title: string): () => WKMenuItemType {
    const docReadonly = useViewState('docReadonly')
    const colorProfile =
        useViewState('documentColorProfile')?.profile ?? DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B
    const { changeColorProfileDialogState } = useMenuContextState()
    const openToNext = changeColorProfileDialogState.openToNext

    const create = useCallback((): WKMenuItemType => {
        return {
            name: title,
            shortCut: '',
            disable: false,
            hidden: docReadonly,
            position: 'right top',
            children: [
                {
                    name: R.Current[colorProfile],
                    shortCut: '',
                    disable: false,
                    handler: () => {},
                    activity: true,
                },
                {
                    name: R.Second[colorProfile],
                    shortCut: '',
                    disable: false,
                    handler: () => {
                        openToNext(
                            colorProfile === DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B
                                ? DocumentColorProfile.DOCUMENT_COLOR_PROFILE_DISPLAY_P3
                                : DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B
                        )
                    },
                },
            ],
        }
    }, [title, colorProfile, docReadonly, openToNext])

    return create
}
