import {
    PluginApiCreateExternalPromiseJS,
    PluginApiResolveExternalPromiseCommandJS,
    Wukong,
} from '@wukong/bridge-proto'
import { IBasicBridge } from '../../kernel/bridge/basic-bridge'

export class LegacyPromiseStore {
    externalPromiseId2Callback = new Map<number, (_arg?: string | Uint8Array) => void>()
    externalPromiseId2Promise = new Map<number, Promise<string | Uint8Array | undefined>>()
    cid = 0

    constructor(private readonly bridge: IBasicBridge) {
        bridge.bind(PluginApiCreateExternalPromiseJS, () => {
            const { id } = this.createExternalPromise()

            return { value: id }
        })

        bridge.bind(PluginApiResolveExternalPromiseCommandJS, ({ id, type, buffer }) => {
            return this.invokeExternalPromiseCallback(id, type, buffer)
        })
    }

    createExternalPromise = () => {
        const id = this.cid++
        const promise = new Promise<string | Uint8Array | undefined>((resolve, _reject) => {
            this.externalPromiseId2Callback.set(id, resolve)
        })

        this.externalPromiseId2Promise.set(id, promise)

        return {
            id,
            promise,
        }
    }

    getExternalPromiseById = (id: number) => {
        const promise = this.externalPromiseId2Promise.get(id)

        if (promise === undefined) {
            throw new Error(`External promise with id ${id} not found`)
        }

        return promise
    }

    invokeExternalPromiseCallback = (
        id: number,
        type: Wukong.DocumentProto.ExternalPromiseType,
        buffer: Uint8Array
    ) => {
        const callback = this.externalPromiseId2Callback.get(id)

        if (callback) {
            // proto decode
            switch (type) {
                case Wukong.DocumentProto.ExternalPromiseType.EXTERNAL_PROMISE_TYPE_VOID:
                    {
                        callback()
                    }
                    break
                case Wukong.DocumentProto.ExternalPromiseType.EXTERNAL_PROMISE_TYPE_STRING:
                    {
                        const newBuffer = new ArrayBuffer(buffer.length)
                        const zeroOffsetTypedArray = new Uint8Array(newBuffer)
                        zeroOffsetTypedArray.set(buffer)

                        const arg = Wukong.DocumentProto.BridgeProtoString.decodeDelimited(zeroOffsetTypedArray).value
                        callback(arg)
                    }
                    break
                case Wukong.DocumentProto.ExternalPromiseType.EXTERNAL_PROMISE_TYPE_BYTES:
                    {
                        callback(buffer)
                    }
                    break
                default:
                    throw new Error(`Unknown external promise type: ${type}`)
            }

            this.externalPromiseId2Callback.delete(id)
        }
    }
}
