import { lazy } from 'react'
import { RouteToken } from '../../../../util/src'
import { CCStateRouteObject } from '../../../../util/src/abort-controller/signal-browser-router'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { setupPageSignal$ } from '../../external-store/atoms/page-signal'
import { bootstrapWebappFlow$ } from '../../external-store/lifecycle/1-create-app/bootstrap/web'
import { AppRoot } from '../../main/app-root'

import { command } from 'ccstate'
import { LoaderFunctionArgs } from 'react-router-dom'
import { startDocAuthFlow$ } from '../../external-store/lifecycle/3-doc-auth/web'
import { startSetupEditorFlow$ } from '../../external-store/lifecycle/4-setup-editor/web'
import { createEditorSignal$ } from '../../external-store/atoms/editor-context'

const DocAuthGuard = lazy(() =>
    import('../../auth/doc-auth-guard').then((module) => Promise.resolve({ default: module.DocAuthGuard }))
)

const SSOConsume = lazy(() =>
    import('../../auth/sso-consume/sso-consume').then((module) => Promise.resolve({ default: module.SSOConsume }))
)

const Space = lazy(() =>
    import('../../space').then(({ SpaceContainer }) => Promise.resolve({ default: SpaceContainer }))
)

const AbroadInvoice = lazy(() =>
    import('../../abroad-invoice').then(({ AbroadInvoiceContainer }) =>
        Promise.resolve({ default: AbroadInvoiceContainer })
    )
)

const PresentAppRoot = lazy(() =>
    import('../../prototype/present-ui/root').then((resolved) => Promise.resolve({ default: resolved.PresentAppRoot }))
)

const InvitePage = lazy(() => import('../../auth/invite').then(({ Invite }) => Promise.resolve({ default: Invite })))

const GetRescourcePage = lazy(() =>
    import('../../auth/get-resource/get-resource').then(({ GetResource }) => Promise.resolve({ default: GetResource }))
)

const AppAuth = lazy(() =>
    import('../../auth/app-auth').then((resolved) => Promise.resolve({ default: resolved.AppAuth }))
)

const DocumentAccess = lazy(() =>
    import('../../auth/document-access').then((resolved) => Promise.resolve({ default: resolved.DocumentAccess }))
)

const RestoreTeam = lazy(() =>
    import('../../auth/restore-team').then((resolved) => Promise.resolve({ default: resolved.RestoreTeam }))
)

const Application = lazy(() =>
    import('../../space/app/application').then((resolved) => Promise.resolve({ default: resolved.Application }))
)

const SeatApplication = lazy(() =>
    import('../../space/app/seat-application').then((resolved) =>
        Promise.resolve({ default: resolved.SeatApplication })
    )
)

const FigmaOauth = lazy(() =>
    import('../../space/app/figma-oauth').then((module) => Promise.resolve({ default: module.FigmaOauth }))
)

const ForceUpgrade = lazy(() =>
    import('../../space/app/force-upgrade').then((module) => Promise.resolve({ default: module.ForceUpgrade }))
)

const Workbench = lazy(() =>
    import('../../space/app/workbench').then((module) => Promise.resolve({ default: module.Workbench }))
)

const Admin = lazy(() =>
    import('../../space/app/workbench/admin/index').then((module) => Promise.resolve({ default: module.AdminEntry }))
)

// 用于需要初始化 page 且需要鉴权的页面
const setupPage$ = command(async ({ get, set }, signal: TraceableAbortSignal) => {
    await get(bootstrapWebappFlow$)

    set(setupPageSignal$, signal)

    return null
})

export const webappRouteConfigs: CCStateRouteObject[] = [
    {
        path: RouteToken.FileWithDocId,
        element: (
            <DocAuthGuard>
                <AppRoot />
            </DocAuthGuard>
        ),
        setup$: command(async ({ set }, signal: TraceableAbortSignal, args: LoaderFunctionArgs) => {
            const editorSignal = set(createEditorSignal$, signal)

            // 进入编辑器的流程：
            // 1. 切换 page signal
            await set(setupPage$, editorSignal)

            // 2. 文档鉴权
            // 该过程是非阻塞的，可以让用户提前看到骨架屏，同时也避免了用户能够多次调用 setup 的问题（ticket 的获取需要时间，该时间超过我们设置 navigate debounce 时间）
            set(startDocAuthFlow$, editorSignal, args).then((authSuccessed) => {
                if (authSuccessed) {
                    set(startSetupEditorFlow$, editorSignal)
                }
            })

            return null
        }),
    },
    {
        path: RouteToken.PrototypeWithDocId,
        element: (
            <DocAuthGuard>
                <PresentAppRoot />
            </DocAuthGuard>
        ),
        setup$: command(async ({ set }, signal: TraceableAbortSignal, args: LoaderFunctionArgs) => {
            const editorSignal = set(createEditorSignal$, signal)

            // 进入原型预览的流程：
            // 1. 切换 page signal
            await set(setupPage$, editorSignal)

            // 2. 文档鉴权
            set(startDocAuthFlow$, editorSignal, args)

            return null
        }),
    },
    {
        path: RouteToken.Invoice,
        element: <AbroadInvoice />,
        setup$: setupPage$,
    },
    {
        path: RouteToken.Space,
        element: <Space />,
        setup$: setupPage$,
    },
    {
        path: RouteToken.GetResource,
        element: <GetRescourcePage />,
        setup$: setupPage$,
    },
    {
        path: RouteToken.DocAccessWithDocId,
        element: <DocumentAccess />,
        setup$: setupPage$,
    },
    {
        path: RouteToken.Invite,
        element: <InvitePage />,
        setup$: setupPage$,
    },
    {
        path: RouteToken.RestoreTeam,
        element: <RestoreTeam />,
        setup$: setupPage$,
    },
    {
        path: RouteToken.Application,
        element: <Application />,
        setup$: setupPage$,
    },
    {
        path: RouteToken.SeatApplication,
        element: <SeatApplication />,
        setup$: setupPage$,
    },
    {
        path: RouteToken.SSOConsume,
        element: <SSOConsume />,
        setup$: setupPage$,
    },
    {
        path: RouteToken.FigmaOauth,
        element: <FigmaOauth />,
        setup$: setupPage$,
    },
    {
        path: RouteToken.ForceUpgrade,
        element: <ForceUpgrade />,
        setup$: setupPage$,
    },
    {
        path: RouteToken.Workbench,
        element: <Workbench />,
        setup$: setupPage$,
    },
    {
        path: RouteToken.Manage,
        element: <Admin />,
        setup$: setupPage$,
    },
    {
        path: `${RouteToken.AppAuth}/:authId`,
        element: <AppAuth />,
        setup$: setupPage$,
    },
]
