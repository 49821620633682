import { useViewState } from '../../../../../view-state-bridge'
import { DevModeInspectBoxModel } from '../dev-mode-inspect-box-model/box-model'
import { DevModeInspectMode } from '../dev-mode-inspect-mode'
import { CodeBlock } from './code-block'
export function DevModeInspectCode() {
    const codeViewState = useViewState('devModeInspectCodeViewState')
    const codeTypeViewState = useViewState('devModeInspectCodeTypeViewState')

    if (!codeViewState || !codeTypeViewState) {
        return null
    }

    return (
        <div data-testid="dev-mode-inspect-code">
            <DevModeInspectBoxModel />
            <DevModeInspectMode className="-mt-10px mb-16px" />
            <div className="mx-4">
                {codeViewState.codeBlocks.map((codeBlock, index) => {
                    return (
                        <CodeBlock
                            key={codeBlock.code + index}
                            codeBlock={codeBlock}
                            codeType={codeTypeViewState.codeType}
                        />
                    )
                })}
            </div>
        </div>
    )
}
