import { translation } from './vector.translation'
/* eslint-disable no-restricted-imports */
import {
    GetVectorPanelParsedValueCommand,
    UpdateVectorPanelCornerRadiusCommand,
    updateVectorPanelHandleMirrorCommand,
    updateVectorPanelXCommand,
    updateVectorPanelYCommand,
    Wukong,
} from '@wukong/bridge-proto'
import classnames from 'classnames'
import { useCallback, useEffect, type HTMLAttributes } from 'react'
import { IconCoordinateX, IconCoordinateY, IconCorners2, IconVectorEdit, Select } from '../../../../../../ui-lib/src'
import { useBridge, useCommand } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { SingleGrid } from '../../atom/grid/single-grid'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { ScrubbableInputNumber } from '../../atom/inputs/scrubbable-input-number'
import { Value } from '../../atom/inputs/utils/type'
import { Title } from '../../atom/title/title'
import style from './attribute.module.less'
import VectorHandleMirror = Wukong.DocumentProto.VectorHandleMirror
import BridgeProtoFloat = Wukong.DocumentProto.BridgeProtoFloat
import Args_updateVectorPanelHandleMirrorCommand = Wukong.DocumentProto.Args_updateVectorPanelHandleMirrorCommand

const handleMirroringToShow: { [key in VectorHandleMirror]: string } = {
    [VectorHandleMirror.VECTOR_HANDLE_MIRROR_NONE]: translation('NoMirroring'),
    [VectorHandleMirror.VECTOR_HANDLE_MIRROR_ANGLE]: translation('MirrorAngle'),
    [VectorHandleMirror.VECTOR_HANDLE_MIRROR_ANGLE_AND_LENGTH]: translation('MirrorAngle_synonyms1'),
}

interface VectorProps extends HTMLAttributes<HTMLDivElement> {}

export function Vector(props: VectorProps) {
    const { className } = props

    const vectorPanel = useViewState('vectorPanel')

    const command = useCommand()

    const bridge = useBridge()

    const { x, y, cornerRadius, handleMirror } = vectorPanel!

    useEffect(() => {
        // unbind
        return () => {
            bridge.unbind(GetVectorPanelParsedValueCommand)
        }
    }, [bridge])

    const updateGetVectorPanelParsedValueCommand = useCallback(
        (parseFn: (value: Value) => Value) => {
            bridge.bind(GetVectorPanelParsedValueCommand, ({ value }) => {
                return BridgeProtoFloat.create({
                    value: parseFn(value!) as number,
                })
            })
        },
        [bridge]
    )

    const handleXChange = (value: Value, options?: InputOptionsForUndoSquash, shouldParseValue = false) => {
        if (typeof value === 'number') {
            command.DEPRECATED_invokeBridge(
                updateVectorPanelXCommand,
                Wukong.DocumentProto.Arg_updateVectorPanelXYCommand.create({
                    value,
                    shouldParseValue,
                })
            )
            command.commitUndo(options?.commitType)
        }
    }

    const handleYChange = (value: Value, options?: InputOptionsForUndoSquash, shouldParseValue = false) => {
        if (typeof value === 'number') {
            command.DEPRECATED_invokeBridge(
                updateVectorPanelYCommand,
                Wukong.DocumentProto.Arg_updateVectorPanelXYCommand.create({
                    value,
                    shouldParseValue,
                })
            )
            command.commitUndo(options?.commitType)
        }
    }

    const handleMirrorChange = (value: any) => {
        if (typeof value === 'number' && value !== handleMirror.value) {
            command.DEPRECATED_invokeBridge(
                updateVectorPanelHandleMirrorCommand,
                Args_updateVectorPanelHandleMirrorCommand.create({ value })
            )
            command.commitUndo()
        }
    }

    const handleCornerRadiusChange = (value: Value, options?: InputOptionsForUndoSquash) => {
        if (typeof value === 'number' && value !== cornerRadius.value) {
            command.DEPRECATED_invokeBridge(UpdateVectorPanelCornerRadiusCommand, { value })
            command.commitUndo(options?.commitType)
        }
    }

    return (
        <div className={classnames('pt-8px pb-12px', className)} data-testid="vector-panel">
            <Title>
                <Title.Left>{translation('Vector')}</Title.Left>
            </Title>
            <SingleGrid>
                <SingleGrid.Item start={5} span={22}>
                    <ScrubbableInputNumber
                        icon={<IconCoordinateX />}
                        isMixed={x.mixed}
                        value={x.value}
                        onChange={handleXChange}
                        disabled={typeof x.value !== 'number' && !x.mixed}
                        defaultShowEmpty={typeof x.value !== 'number'}
                        scrubbingDisabled={x.mixed}
                        // @ts-expect-error
                        mixedMathHandler={{
                            onChangeMixed: (parse, options) => {
                                updateGetVectorPanelParsedValueCommand(parse)
                                handleXChange(0, options, true)
                            },
                        }}
                        testId={'vector-x-input'}
                    />
                </SingleGrid.Item>
                <SingleGrid.Item start={29} span={22}>
                    <ScrubbableInputNumber
                        icon={<IconCoordinateY />}
                        isMixed={y.mixed}
                        value={y.value}
                        onChange={handleYChange}
                        disabled={typeof y.value !== 'number' && !y.mixed}
                        defaultShowEmpty={typeof y.value !== 'number'}
                        scrubbingDisabled={y.mixed}
                        // @ts-expect-error
                        mixedMathHandler={{
                            onChangeMixed: (parse, options) => {
                                updateGetVectorPanelParsedValueCommand(parse)
                                handleYChange(0, options, true)
                            },
                        }}
                    />
                </SingleGrid.Item>
            </SingleGrid>
            <SingleGrid>
                <SingleGrid.Item start={5} span={46}>
                    <Select.NormalSingleLevel
                        isMixed={handleMirror.mixed}
                        icon={<IconVectorEdit />}
                        value={handleMirror.value}
                        label={handleMirroringToShow[handleMirror.value as VectorHandleMirror]}
                        onChange={handleMirrorChange}
                        minWidth={216}
                    >
                        {Object.keys(handleMirroringToShow).map((key) => {
                            const handleMirroring: VectorHandleMirror = Number(key)
                            return (
                                <Select.NormalSingleLevel.Option
                                    value={handleMirroring}
                                    key={key}
                                    backwardIcon={''}
                                    className={style.itemVector}
                                >
                                    {handleMirroringToShow[handleMirroring]}
                                </Select.NormalSingleLevel.Option>
                            )
                        })}
                    </Select.NormalSingleLevel>
                </SingleGrid.Item>
            </SingleGrid>
            <SingleGrid>
                <SingleGrid.Item start={5} span={22}>
                    <ScrubbableInputNumber
                        icon={<IconCorners2 />}
                        isMixed={cornerRadius.mixed}
                        value={cornerRadius.value}
                        onChange={handleCornerRadiusChange}
                        min={0}
                        defaultShowEmpty={typeof cornerRadius.value !== 'number'}
                        scrubbingDisabled={cornerRadius.mixed}
                    />
                </SingleGrid.Item>
            </SingleGrid>
        </div>
    )
}
