import { translation } from './document-missing-font-modal.translation'
/* eslint-disable no-restricted-imports */
import { useEffect } from 'react'
import { Scrollbar, WKTextButton } from '../../../../../../ui-lib/src'
import { WKLoading } from '../../../../../../ui-lib/src/components/wk-loading/wk-loading'
import { isEnglishLanguage } from '../../../../../../util/src'
import { MetricCollector, MetricName } from '../../../../kernel/metric-collector'
import { usePlanAndUserStateService } from '../../../../main/app-context'
import { navigateToPricing } from '../../../../utils/payment'
import { isActiveOrgPlan } from '../../../../utils/plan-status-checker'
import { WK } from '../../../../window'
import { useDocumentMissingFont } from '../../../context/document-missing-font-context'
import styles from './document-missing-font-modal.module.less'
import { FontMissModal } from './font-miss-modal'
import { FontMissRow } from './font-miss-row'
import { FontPluginInstallAlert } from './font-plugin-install-alert'
import { useFontMiss } from './use-font-miss'

export function DocumentMissingFontModal() {
    const {
        isOpenFontMissDialog,
        closeDialog,
        fonts,
        missFont,
        inspectMissFont,
        replaceMissFont,
        uid2CountMap,
        uid2FullFontName,
        fontMissingDialogState,
    } = useDocumentMissingFont()

    const { targetFontNames, setTargetFontFamily, setTargetFontName } = useFontMiss(missFont, fonts, uid2FullFontName)

    const planAndUserStateService = usePlanAndUserStateService()
    const planAndUserState = planAndUserStateService.useZustandStore.use.planAndUserState()

    const hasActiveOrgPlan = planAndUserState && isActiveOrgPlan(planAndUserState)

    useEffect(() => {
        WK.setAllMissFonts = () => {
            if (isOpenFontMissDialog && missFont && fonts.length) {
                Object.keys(missFont).forEach((key) => {
                    setTargetFontFamily(key, fonts[0].family)
                    setTargetFontName(key, fonts[0].styles[0])
                })
            }
        }

        return () => {
            delete WK.setAllMissFonts
        }
    }, [missFont, fonts, setTargetFontFamily, setTargetFontName, isOpenFontMissDialog])

    const onSubmit = () => {
        if (Object.keys(targetFontNames).length <= 0) {
            return
        }
        const start = performance.now()
        replaceMissFont(targetFontNames)
        closeDialog()
        MetricCollector.pushMetric(MetricName.SET_MISS_FONT_DURATION, performance.now() - start)
    }

    if (!isOpenFontMissDialog) {
        return null
    }

    if (fontMissingDialogState === 'LOADING' || Object.keys(missFont).length <= 0) {
        return (
            <FontMissModal onOk={() => {}} onCancel={closeDialog} onOkDisabled={true} emptyFooter={true}>
                <div className={styles.load} data-testid="font-miss-loading">
                    {fontMissingDialogState === 'LOADING' ? (
                        <WKLoading size="large" noText />
                    ) : (
                        <div className={styles.text}>
                            <div>{translation('EmptyMissingFonts1')}</div>
                            <div>{translation('EmptyMissingFonts2')}</div>
                        </div>
                    )}
                </div>
            </FontMissModal>
        )
    }

    return (
        <FontMissModal onOk={onSubmit} onCancel={closeDialog} onOkDisabled={Object.keys(targetFontNames).length <= 0}>
            <Scrollbar autoHeight autoHeightMin={0} autoHeightMax={408}>
                <div style={{ padding: '16px 24px', zIndex: 'unset' }} data-testid="font-miss-modal-filled">
                    <FontPluginInstallAlert />
                    <div className={styles.header}>
                        <div className={styles.left}>{translation('MissingFonts_synonyms1')}</div>
                        <div className={styles.right}>{translation('Replacement')}</div>
                    </div>
                    <div className={styles.rows}>
                        {Object.entries(missFont).map(([missFontNameUid, fontName]) => (
                            <FontMissRow
                                key={missFontNameUid}
                                missFontNameUid={missFontNameUid}
                                missFontName={fontName}
                                usingCount={uid2CountMap[missFontNameUid]}
                                targetFontName={targetFontNames[missFontNameUid]}
                                onInspectMissFont={(v) => inspectMissFont(v)}
                                onSelectedFamily={setTargetFontFamily}
                                onSelectedFontName={setTargetFontName}
                                onChangeExpand={undefined}
                            />
                        ))}
                    </div>
                    {!hasActiveOrgPlan && (
                        <div className={styles.upgradeTeamTip} data-testid="upgrade-team-tip">
                            <span>
                                {translation('UpgradeTeamTip')}
                                {isEnglishLanguage() ? '\u00A0' : ''}
                            </span>
                            <WKTextButton size={12} type="primary" onClick={navigateToPricing}>
                                {translation('LearnMore')}
                            </WKTextButton>
                        </div>
                    )}
                </div>
            </Scrollbar>
        </FontMissModal>
    )
}
