/* eslint-disable no-restricted-imports */
import { IconMarginBottom, IconMarginLeft, IconMarginRight, IconMarginTop } from '../../../../../../../../ui-lib/src'

export const svgFlexVertical = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99995 3H6.99995L6.99995 10.7929L4.85347 8.64645L4.14636 9.35355L7.14636 12.3536L7.49992 12.7071L7.85347 12.3536L10.8535 9.35355L10.1464 8.64645L7.99995 10.7929L7.99995 3Z"
            fill="#1B1F26"
        />
    </svg>
)

export const svgFlexHorizental = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 6.99992L3 7.99992L10.7929 7.99992L8.64645 10.1464L9.35355 10.8535L12.3536 7.85352L12.7071 7.49996L12.3536 7.14641L9.35355 4.14641L8.64645 4.85352L10.7929 6.99992L3 6.99992Z"
            fill="#828A99"
        />
    </svg>
)

export const singlePadding = {
    vertical: <IconMarginTop />,
    right: <IconMarginRight />,
    bottom: <IconMarginBottom />,
    horizontal: <IconMarginLeft />,
}

// 对齐面板的 icon
export const svgPadBgDot = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="6" y="6" width="4" height="4" rx="2" fill="#1B1F26" />
    </svg>
)

export const svgPadFixedSpacingHAlignTop = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3904_2571)">
            <rect width="4" height="10" rx="1" fill="#1B1F26" />
            <rect x="6" width="4" height="16" rx="1" fill="#1B1F26" />
            <rect x="12" width="4" height="8" rx="1" fill="#1B1F26" />
        </g>
        <defs>
            <clipPath id="clip0_3904_2571">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
export const svgPadFixedSpacingHAlignCenter = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3904_2575)">
            <rect y="3" width="4" height="10" rx="1" fill="#1B1F26" />
            <rect x="6" width="4" height="16" rx="1" fill="#1B1F26" />
            <rect x="12" y="4" width="4" height="8" rx="1" fill="#1B1F26" />
        </g>
        <defs>
            <clipPath id="clip0_3904_2575">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
export const svgPadFixedSpacingHAlignBottom = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3904_2579)">
            <rect y="6" width="4" height="10" rx="1" fill="#1B1F26" />
            <rect x="6" width="4" height="16" rx="1" fill="#1B1F26" />
            <rect x="12" y="8" width="4" height="8" rx="1" fill="#1B1F26" />
        </g>
        <defs>
            <clipPath id="clip0_3904_2579">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export const svgPadFixedSpacingVAlignLeft = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3904_2583)">
            <rect width="10" height="4" rx="1" fill="#1B1F26" />
            <rect y="6" width="16" height="4" rx="1" fill="#1B1F26" />
            <rect y="12" width="8" height="4" rx="1" fill="#1B1F26" />
        </g>
        <defs>
            <clipPath id="clip0_3904_2583">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
export const svgPadFixedSpacingVAlignCenter = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3904_2587)">
            <rect x="3" width="10" height="4" rx="1" fill="#1B1F26" />
            <rect y="6" width="16" height="4" rx="1" fill="#1B1F26" />
            <rect x="4" y="12" width="8" height="4" rx="1" fill="#1B1F26" />
        </g>
        <defs>
            <clipPath id="clip0_3904_2587">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
export const svgPadFixedSpacingVAlignRight = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3904_2591)">
            <rect x="6" width="10" height="4" rx="1" fill="#1B1F26" />
            <rect y="6" width="16" height="4" rx="1" fill="#1B1F26" />
            <rect x="8" y="12" width="8" height="4" rx="1" fill="#1B1F26" />
        </g>
        <defs>
            <clipPath id="clip0_3904_2591">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export const svgPadFixedSpacingWrapAlignLeft = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            transform="matrix(1 1.60812e-16 -1.60812e-16 1 4.82437e-16 1)"
            d="M1 0C0.447715 0 0 0.447715 0 1L0 5C0 5.55228 0.447715 6 1 6L3 6C3.55228 6 4 5.55228 4 5L4 1C4 0.447715 3.55228 0 3 0L1 0Z"
            fillRule="nonzero"
            fill="rgb(69, 73, 89)"
        />
        <path
            transform="matrix(1 1.60812e-16 -1.60812e-16 1 6 1)"
            d="M1 0C0.447715 0 0 0.447715 0 1L0 5C0 5.55228 0.447715 6 1 6L3 6C3.55228 6 4 5.55228 4 5L4 1C4 0.447715 3.55228 0 3 0L1 0Z"
            fillRule="nonzero"
            fill="rgb(69, 73, 89)"
        />
        <path
            transform="matrix(1 1.60812e-16 -1.60812e-16 1 12 1)"
            d="M1 0C0.447715 0 0 0.447715 0 1L0 5C0 5.55228 0.447715 6 1 6L3 6C3.55228 6 4 5.55228 4 5L4 1C4 0.447715 3.55228 0 3 0L1 0Z"
            fillRule="nonzero"
            fill="rgb(69, 73, 89)"
        />
        <path
            transform="matrix(1 1.60812e-16 -1.60812e-16 1 4.82437e-16 9)"
            d="M1 0C0.447715 0 0 0.447715 0 1L0 5C0 5.55228 0.447715 6 1 6L3 6C3.55228 6 4 5.55228 4 5L4 1C4 0.447715 3.55228 0 3 0L1 0Z"
            fillRule="nonzero"
            fill="rgb(69, 73, 89)"
        />
        <path
            transform="matrix(1 1.60812e-16 -1.60812e-16 1 6 9)"
            d="M1 0C0.447715 0 0 0.447715 0 1L0 5C0 5.55228 0.447715 6 1 6L3 6C3.55228 6 4 5.55228 4 5L4 1C4 0.447715 3.55228 0 3 0L1 0Z"
            fillRule="nonzero"
            fill="rgb(69, 73, 89)"
        />
    </svg>
)
export const svgPadFixedSpacingWrapAlignCenter = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            transform="matrix(1 1.60812e-16 -1.60812e-16 1 4.82437e-16 1)"
            d="M1 0C0.447715 0 0 0.447715 0 1L0 5C0 5.55228 0.447715 6 1 6L3 6C3.55228 6 4 5.55228 4 5L4 1C4 0.447715 3.55228 0 3 0L1 0Z"
            fillRule="nonzero"
            fill="rgb(69, 73, 89)"
        />
        <path
            transform="matrix(1 1.60812e-16 -1.60812e-16 1 6 1)"
            d="M1 0C0.447715 0 0 0.447715 0 1L0 5C0 5.55228 0.447715 6 1 6L3 6C3.55228 6 4 5.55228 4 5L4 1C4 0.447715 3.55228 0 3 0L1 0Z"
            fillRule="nonzero"
            fill="rgb(69, 73, 89)"
        />
        <path
            transform="matrix(1 1.60812e-16 -1.60812e-16 1 12 1)"
            d="M1 0C0.447715 0 0 0.447715 0 1L0 5C0 5.55228 0.447715 6 1 6L3 6C3.55228 6 4 5.55228 4 5L4 1C4 0.447715 3.55228 0 3 0L1 0Z"
            fillRule="nonzero"
            fill="rgb(69, 73, 89)"
        />
        <path
            transform="matrix(1 1.60812e-16 -1.60812e-16 1 3 9)"
            d="M1 0C0.447715 0 0 0.447715 0 1L0 5C0 5.55228 0.447715 6 1 6L3 6C3.55228 6 4 5.55228 4 5L4 1C4 0.447715 3.55228 0 3 0L1 0Z"
            fillRule="nonzero"
            fill="rgb(69, 73, 89)"
        />
        <path
            transform="matrix(1 1.60812e-16 -1.60812e-16 1 9 9)"
            d="M1 0C0.447715 0 0 0.447715 0 1L0 5C0 5.55228 0.447715 6 1 6L3 6C3.55228 6 4 5.55228 4 5L4 1C4 0.447715 3.55228 0 3 0L1 0Z"
            fillRule="nonzero"
            fill="rgb(69, 73, 89)"
        />
    </svg>
)
export const svgPadFixedSpacingWrapAlignRight = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            transform="matrix(1 1.60812e-16 -1.60812e-16 1 4.82437e-16 1)"
            d="M1 0C0.447715 0 0 0.447715 0 1L0 5C0 5.55228 0.447715 6 1 6L3 6C3.55228 6 4 5.55228 4 5L4 1C4 0.447715 3.55228 0 3 0L1 0Z"
            fillRule="nonzero"
            fill="rgb(69, 73, 89)"
        />
        <path
            transform="matrix(1 1.60812e-16 -1.60812e-16 1 6 1)"
            d="M1 0C0.447715 0 0 0.447715 0 1L0 5C0 5.55228 0.447715 6 1 6L3 6C3.55228 6 4 5.55228 4 5L4 1C4 0.447715 3.55228 0 3 0L1 0Z"
            fillRule="nonzero"
            fill="rgb(69, 73, 89)"
        />
        <path
            transform="matrix(1 1.60812e-16 -1.60812e-16 1 12 1)"
            d="M1 0C0.447715 0 0 0.447715 0 1L0 5C0 5.55228 0.447715 6 1 6L3 6C3.55228 6 4 5.55228 4 5L4 1C4 0.447715 3.55228 0 3 0L1 0Z"
            fillRule="nonzero"
            fill="rgb(69, 73, 89)"
        />
        <path
            transform="matrix(1 1.60812e-16 -1.60812e-16 1 6 9)"
            d="M1 0C0.447715 0 0 0.447715 0 1L0 5C0 5.55228 0.447715 6 1 6L3 6C3.55228 6 4 5.55228 4 5L4 1C4 0.447715 3.55228 0 3 0L1 0Z"
            fillRule="nonzero"
            fill="rgb(69, 73, 89)"
        />
        <path
            transform="matrix(1 1.60812e-16 -1.60812e-16 1 12 9)"
            d="M1 0C0.447715 0 0 0.447715 0 1L0 5C0 5.55228 0.447715 6 1 6L3 6C3.55228 6 4 5.55228 4 5L4 1C4 0.447715 3.55228 0 3 0L1 0Z"
            fillRule="nonzero"
            fill="rgb(69, 73, 89)"
        />
    </svg>
)

export const svgPadAutoSpacingHAlignLong = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="6" width="4" height="16" rx="1" fill="#1B1F26" />
    </svg>
)

export const svgPadAutoSpacingVAlignLong = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3904_2621)">
            <rect y="10" width="4" height="16" rx="1" transform="rotate(-90 0 10)" fill="#1B1F26" />
        </g>
        <defs>
            <clipPath id="clip0_3904_2621">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

// 下面三个一组，构成自动间距下不同排列方向的 icon 组
// 水平顶部
export const svgPadAutoSpacingHAlignTopLeft = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="6" width="4" height="10" rx="1" fill="#1B1F26" />
    </svg>
)
export const svgPadAutoSpacingHAlignTopCenter = svgPadAutoSpacingHAlignLong
export const svgPadAutoSpacingHAlignTopRight = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="6" width="4" height="8" rx="1" fill="#1B1F26" />
    </svg>
)

// 水平居中对齐
export const svgPadAutoSpacingHAlignCenterLeft = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="6" y="3" width="4" height="10" rx="1" fill="#1B1F26" />
    </svg>
)
export const svgPadAutoSpacingHAlignCenterCenter = svgPadAutoSpacingHAlignLong
export const svgPadAutoSpacingHAlignCenterRight = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="6" y="4" width="4" height="8" rx="1" fill="#1B1F26" />
    </svg>
)

// 水平底部
export const svgPadAutoSpacingHAlignBottomLeft = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="6" y="6" width="4" height="10" rx="1" fill="var(--wk-brand-7)" />
    </svg>
)
export const svgPadAutoSpacingHAlignBottomCenter = svgPadAutoSpacingHAlignLong
export const svgPadAutoSpacingHAlignBottomRight = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="6" y="8" width="4" height="8" rx="1" fill="var(--wk-brand-7)" />
    </svg>
)

// 垂直左对齐
export const svgPadAutoSpacingVAlignLeftTop = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3904_2609)">
            <rect y="6" width="10" height="4" rx="1" fill="#1B1F26" />
        </g>
        <defs>
            <clipPath id="clip0_3904_2609">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
export const svgPadAutoSpacingVAlignLeftCenter = svgPadAutoSpacingVAlignLong
export const svgPadAutoSpacingVAlignLeftBottom = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3904_2611)">
            <rect x="8" y="6" width="4" height="8" rx="1" transform="rotate(90 8 6)" fill="#1B1F26" />
        </g>
        <defs>
            <clipPath id="clip0_3904_2611">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

// 垂直居中对齐
export const svgPadAutoSpacingVAlignCenterTop = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3" y="10" width="4" height="10" rx="1" transform="rotate(-90 3 10)" fill="#1B1F26" />
    </svg>
)
export const svgPadAutoSpacingVAlignCenterCenter = svgPadAutoSpacingVAlignLong
export const svgPadAutoSpacingVAlignCenterBottom = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="10" width="4" height="8" rx="1" transform="rotate(-90 4 10)" fill="#1B1F26" />
    </svg>
)

// 垂直右对齐
export const svgPadAutoSpacingVAlignRightTop = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="6" y="10" width="4" height="10" rx="1" transform="rotate(-90 6 10)" fill="#1B1F26" />
    </svg>
)
export const svgPadAutoSpacingVAlignRightCenter = svgPadAutoSpacingVAlignLong
export const svgPadAutoSpacingVAlignRightBottom = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="8" y="10" width="4" height="8" rx="1" transform="rotate(-90 8 10)" fill="#1B1F26" />
    </svg>
)

// 换行水平自动间距
export const svgPadAutoSpacingWrapAlignS = (
    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="6" viewBox="0 0 4 6">
        <path
            transform="matrix(1 1.60812e-16 -1.60812e-16 1 0 0)"
            d="M1 0C0.447715 0 0 0.447715 0 1L0 5C0 5.55228 0.447715 6 1 6L3 6C3.55228 6 4 5.55228 4 5L4 1C4 0.447715 3.55228 0 3 0L1 0Z"
            fillRule="nonzero"
            fill="rgb(69, 73, 89)"
        />
    </svg>
)
export const svgPadAutoSpacingWrapAlignM = (
    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="10" viewBox="0 0 4 10">
        <path
            transform="matrix(1 1.60812e-16 -1.60812e-16 1 0 0)"
            d="M1 0C0.447715 0 0 0.447715 0 1L0 9C0 9.55228 0.447715 10 1 10L3 10C3.55228 10 4 9.55228 4 9L4 1C4 0.447715 3.55228 0 3 0L1 0Z"
            fillRule="nonzero"
            fill="rgb(69, 73, 89)"
        />
    </svg>
)
export const svgPadAutoSpacingWrapAlignL = (
    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="16" viewBox="0 0 4 16">
        <path
            transform="matrix(1 1.60812e-16 -1.60812e-16 1 0 0)"
            d="M1 0C0.447715 0 0 0.447715 0 1L0 15C0 15.5523 0.447715 16 1 16L3 16C3.55228 16 4 15.5523 4 15L4 1C4 0.447715 3.55228 0 3 0L1 0Z"
            fillRule="nonzero"
            fill="rgb(69, 73, 89)"
        />
    </svg>
)

// 文本基线对齐 icon
export const svgPadAlignToBaseLine = (
    <svg width="12" height="25" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.914 0.147999L0 13H2.25L3.42 9.778H8.802L9.972 13H12.222L7.308 0.147999H4.914ZM4.05 8.05L6.084 2.416H6.156L8.172 8.05H4.05Z"
            fill="var(--wk-brand-7)"
        />
    </svg>
)

export const svgPadAlignToBaseLineBgDot = (
    <svg width="12" height="25" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="4" width="4" height="4" rx="2" fill="#C2C3C4" />
    </svg>
)

export const svgPadAlignToBaseLineLeft = (
    <svg width="12" height="25" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="2" width="4" height="10" rx="1" fill="var(--wk-brand-7)" />
    </svg>
)

export const svgPadAlignToBaseLineRight = (
    <svg width="12" height="25" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="6" width="4" height="6" rx="1" fill="var(--wk-brand-7)" />
    </svg>
)

// 排列方向 icon
export const svgLayoutVertical = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99995 3H6.99995L6.99995 10.7929L4.85347 8.64645L4.14636 9.35355L7.14636 12.3536L7.49992 12.7071L7.85347 12.3536L10.8535 9.35355L10.1464 8.64645L7.99995 10.7929L7.99995 3Z"
            fill="#1B1F26"
        />
    </svg>
)

export const svgLayoutHorizental = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 6.99992L3 7.99992L10.7929 7.99992L8.64645 10.1464L9.35355 10.8535L12.3536 7.85352L12.7071 7.49996L12.3536 7.14641L9.35355 4.14641L8.64645 4.85352L10.7929 6.99992L3 6.99992Z"
            fill="#1B1F26"
        />
    </svg>
)

// 高级设置
export const svgSwitchNone = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 7L12 7V8L4 8V7Z" fill="#1B1F26" />
    </svg>
)

export const svgZIndexOne = (
    <svg width="49" height="46" viewBox="0 0 49 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_3904_3590)">
            <rect width="46" height="46" rx="23" fill="white" />
            <path
                d="M21.9766 26H23.2949V17.5449H21.9824L19.7559 19.127V20.416L21.8828 18.8984H21.9766V26Z"
                fill="black"
            />
            <rect x="0.5" y="0.5" width="45" height="45" rx="22.5" stroke="#1B1F26" />
        </g>
        <defs>
            <filter
                id="filter0_d_3904_3590"
                x="0"
                y="0"
                width="49"
                height="46"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3904_3590" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3904_3590" result="shape" />
            </filter>
        </defs>
    </svg>
)

export const svgZIndexTwo = (
    <svg width="49" height="46" viewBox="0 0 49 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_3904_3592)">
            <rect width="46" height="46" rx="23" fill="white" />
            <path
                d="M19.9082 26H25.6152V24.875H21.373L21.707 25.1035V24.5117L21.3672 25.1035L23.5 22.9766C24.0117 22.4688 24.4102 22.0352 24.6953 21.6758C24.9844 21.3164 25.1875 20.9863 25.3047 20.6855C25.4219 20.3848 25.4805 20.0742 25.4805 19.7539V19.7363C25.4805 19.2715 25.3613 18.8594 25.123 18.5C24.8848 18.1367 24.5586 17.8535 24.1445 17.6504C23.7344 17.4434 23.2676 17.3398 22.7441 17.3398C22.1777 17.3398 21.6758 17.4531 21.2383 17.6797C20.8008 17.9023 20.459 18.2129 20.2129 18.6113C19.9668 19.0059 19.8418 19.4609 19.8379 19.9766L19.8438 19.9883L21.0859 19.9941V19.9707C21.0859 19.6621 21.1523 19.3926 21.2852 19.1621C21.418 18.9316 21.6035 18.752 21.8418 18.623C22.0801 18.4941 22.3555 18.4297 22.668 18.4297C22.9766 18.4297 23.2422 18.4922 23.4648 18.6172C23.6875 18.7383 23.8574 18.9082 23.9746 19.127C24.0957 19.3418 24.1562 19.5879 24.1562 19.8652V19.877C24.1562 20.1074 24.1133 20.3223 24.0273 20.5215C23.9453 20.7207 23.8008 20.9473 23.5938 21.2012C23.3867 21.4512 23.0957 21.7715 22.7207 22.1621L19.9082 25.1035V26Z"
                fill="black"
            />
            <rect x="0.5" y="0.5" width="45" height="45" rx="22.5" stroke="#1B1F26" />
        </g>
        <defs>
            <filter
                id="filter0_d_3904_3592"
                x="0"
                y="0"
                width="49"
                height="46"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3904_3592" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3904_3592" result="shape" />
            </filter>
        </defs>
    </svg>
)

export const svgZIndexThree = (
    <svg width="49" height="46" viewBox="0 0 49 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_3904_3594)">
            <rect width="46" height="46" rx="23" fill="white" />
            <path
                d="M22.8906 26.1582C23.4922 26.1582 24.0215 26.0527 24.4785 25.8418C24.9395 25.627 25.3008 25.332 25.5625 24.957C25.8242 24.5781 25.9551 24.1465 25.9551 23.6621V23.6504C25.9551 23.0566 25.7656 22.5801 25.3867 22.2207C25.0078 21.8613 24.5078 21.6562 23.8867 21.6055V21.5762C24.2305 21.5059 24.5352 21.3828 24.8008 21.207C25.0703 21.0273 25.2812 20.8047 25.4336 20.5391C25.5898 20.2695 25.668 19.959 25.668 19.6074V19.5957C25.668 19.1582 25.5527 18.7754 25.3223 18.4473C25.0918 18.1152 24.7676 17.8555 24.3496 17.668C23.9316 17.4805 23.4414 17.3867 22.8789 17.3867C22.3281 17.3867 21.8418 17.4863 21.4199 17.6855C21.002 17.8809 20.666 18.1562 20.4121 18.5117C20.1621 18.8633 20.0137 19.2793 19.9668 19.7598L19.9609 19.8184H21.2031L21.209 19.7656C21.2363 19.4961 21.3223 19.2656 21.4668 19.0742C21.6113 18.8789 21.8047 18.7285 22.0469 18.623C22.2891 18.5176 22.5664 18.4648 22.8789 18.4648C23.1992 18.4648 23.4707 18.5176 23.6934 18.623C23.9199 18.7246 24.0918 18.873 24.209 19.0684C24.3262 19.2598 24.3848 19.4922 24.3848 19.7656V19.7773C24.3848 20.0391 24.3164 20.2715 24.1797 20.4746C24.043 20.6777 23.8535 20.8379 23.6113 20.9551C23.3691 21.0684 23.0859 21.125 22.7617 21.125H21.7773V22.1562H22.8027C23.3652 22.1562 23.8086 22.2852 24.1328 22.543C24.457 22.7969 24.6191 23.1621 24.6191 23.6387V23.6504C24.6191 23.9238 24.5469 24.168 24.4023 24.3828C24.2578 24.5938 24.0566 24.7598 23.7988 24.8809C23.541 24.998 23.2383 25.0566 22.8906 25.0566C22.3672 25.0566 21.9414 24.9355 21.6133 24.6934C21.2852 24.4512 21.0996 24.1387 21.0566 23.7559L21.0508 23.6914H19.7852L19.791 23.7676C19.834 24.2324 19.9863 24.6445 20.248 25.0039C20.5098 25.3633 20.8652 25.6465 21.3145 25.8535C21.7637 26.0566 22.2891 26.1582 22.8906 26.1582Z"
                fill="black"
            />
            <rect x="0.5" y="0.5" width="45" height="45" rx="22.5" stroke="#1B1F26" />
        </g>
        <defs>
            <filter
                id="filter0_d_3904_3594"
                x="0"
                y="0"
                width="49"
                height="46"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3904_3594" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3904_3594" result="shape" />
            </filter>
        </defs>
    </svg>
)

export const svgBaselineA = (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.058 0.147999L0 13H1.584L2.952 9.4H8.856L10.224 13H11.826L6.768 0.147999H5.058ZM3.42 8.158L5.886 1.732H5.958L8.388 8.158H3.42Z"
            fill="black"
        />
    </svg>
)

export const svgBaselineG = (
    <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.95 0.442C3.654 0.442 2.628 0.891999 1.872 1.81C1.116 2.674 0.756 3.772 0.756 5.14C0.756 6.49 1.116 7.588 1.872 8.452C2.628 9.334 3.654 9.784 4.95 9.784C6.354 9.784 7.398 9.154 8.082 7.912V9.55C8.082 11.602 7.146 12.646 5.274 12.646C4.428 12.646 3.798 12.484 3.384 12.178C2.97 11.89 2.7 11.422 2.592 10.792H1.152C1.314 11.8 1.728 12.556 2.394 13.06C3.042 13.564 4.014 13.816 5.274 13.816C8.1 13.816 9.522 12.322 9.522 9.352V0.694H8.082V2.296C7.398 1.054 6.354 0.442 4.95 0.442ZM5.166 1.63C6.084 1.63 6.804 1.954 7.344 2.638C7.848 3.25 8.1 4.096 8.1 5.14C8.1 6.148 7.848 6.976 7.344 7.606C6.804 8.254 6.084 8.578 5.148 8.578C4.14 8.578 3.402 8.218 2.898 7.534C2.448 6.922 2.232 6.112 2.232 5.14C2.232 4.096 2.484 3.25 2.988 2.638C3.51 1.954 4.23 1.63 5.166 1.63Z"
            fill="black"
        />
    </svg>
)
