/* eslint-disable no-restricted-imports */
import { Wukong, GetLineHeightCommand } from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { ColorMode } from '../../../document/node/node'
import type { HandlerProvider } from '../../../main/handler-provider/handler-provider'
import { DeepRequired } from '../../../view-state-bridge'
import { toFixed } from '../../utils/to-fixed'
import type { RGB } from '../design/blend/color-picker/color-type'
import { float2hex, rgb2hex, rgb2hsl, rgb2hsv } from '../design/blend/color-picker/utils/color-translate'

export function rgb2css(r: number, g: number, b: number, a?: number): string {
    r = Math.round(r)
    g = Math.round(g)
    b = Math.round(b)
    return !isNil(a) ? `rgba(${r},${g},${b},${a})` : `rgb(${r},${g},${b})`
}

export const getColorValue = (colorMode: ColorMode, rgb: RGB, opacity?: number) => {
    const formatOpacity = toFixed(opacity, 2)
    let result
    switch (colorMode) {
        case ColorMode.HEX: {
            result = (
                '#' +
                rgb2hex(rgb.r, rgb.g, rgb.b) +
                (formatOpacity === 1 ? '' : float2hex(formatOpacity * 255))
            ).toUpperCase()
            break
        }
        case ColorMode.RGB: {
            result = rgb2css(rgb.r, rgb.g, rgb.b, formatOpacity)
            break
        }
        case ColorMode.CSS: {
            result = rgb2css(rgb.r, rgb.g, rgb.b, formatOpacity)
            break
        }
        case ColorMode.HSL: {
            // 模式切换不会改变色相
            const { h, s, l } = rgb2hsl(rgb.r / 255, rgb.g / 255, rgb.b / 255)
            result = `hsla(${toFixed(h, 0)},${toFixed(s * 100, 2)}%, ${toFixed(l * 100, 2)}%, ${formatOpacity})`
            break
        }
        case ColorMode.HSB: {
            // 模式切换不会改变色相
            const { h, s, v } = rgb2hsv(rgb.r / 255, rgb.g / 255, rgb.b / 255)
            result = `hsba(${toFixed(h, 0)},${toFixed(s * 100, 2)}%, ${toFixed(v * 100, 2)}%, ${formatOpacity})`
            break
        }
    }
    return result ? result.split(',').join(', ') : ''
}

export function tranformColorMode(colorMode: ColorMode): string {
    switch (colorMode) {
        case ColorMode.HEX:
            return 'Hex'
        case ColorMode.CSS:
            return 'CSS'
        case ColorMode.HSB:
            return 'HSB'
        case ColorMode.HSL:
            return 'HSL'
        case ColorMode.RGB:
            return 'RGB'
        default:
            return 'Hex'
    }
}

export function computeLineHeight(
    handler: HandlerProvider,
    lineHeight: DeepRequired<Wukong.DocumentProto.ILineHeight>,
    fontName: DeepRequired<Wukong.DocumentProto.IFontName>,
    fontSize: number
) {
    let result = lineHeight.value
    if (lineHeight.unit === Wukong.DocumentProto.NumberUnit.NUMBER_UNIT_AUTO) {
        const lineHeightValue = handler.handle(
            (context, params) => {
                return context.bridge.call(GetLineHeightCommand, params)
            },
            { fontName: fontName, fontSize: fontSize }
        ).value
        if (lineHeightValue) {
            result = lineHeightValue
        }
    } else if (lineHeight.unit === Wukong.DocumentProto.NumberUnit.NUMBER_UNIT_PERCENT) {
        result = (fontSize * lineHeight.value) / 100
    }
    return result
}
