import classnames from 'classnames'
import { forwardRef, useImperativeHandle, useRef } from 'react'
import { IconArrowDown12 } from '../../../svg-icon/12/controls'
import { ExplicitUndefined } from '../../../utils/explicit-undefined'
import { toMixed } from '../../../utils/mixed-value'
import { TriggerContainer, TriggerContainerProps } from './base-components/trigger-container'
import { TriggerFocus, TriggerFocusProps, TriggerFocusRef } from './base-components/trigger-focus'
import classes from './trigger-svg.module.less'
import { TriggerBaseProps, TriggerBaseRef } from './type'
import { useCommonTrigger } from './use-common-trigger'

export interface TriggerSvgProps extends TriggerBaseProps {
    // TriggerContainerProps
    classNameWhenOpen?: TriggerContainerProps['classNameWhenOpen']
    isOpenPick: TriggerContainerProps['isOpenPick']
    // 私有配置
    label?: React.ReactNode // 触发器展示的内容，是svg图形
    isMixed?: boolean // 对应文本 【多个值】
}

export interface TriggerSvgRef extends TriggerBaseRef {}

function _TriggerSvg(props: TriggerSvgProps, ref?: React.Ref<TriggerSvgRef>) {
    const {
        className,
        disabled,
        dataTestIds,
        openAction,
        closeAction,
        classNameWhenOpen,
        isOpenPick,
        label,
        isMixed,
        ...otherProps
    } = props
    const triggerRef = useRef<TriggerFocusRef>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const commonTrigger = useCommonTrigger({ isOpenPick, openAction, closeAction })

    useImperativeHandle(
        ref,
        () => ({
            getTriggerElement: () => triggerRef.current?.getContainer(),
            triggerRect: () => triggerRef.current?.getContainer().getBoundingClientRect()!,
            focus: () => triggerRef.current?.getFocusElement().focus(),
            getContainer: () => containerRef.current!,
        }),
        []
    )
    return (
        <TriggerContainer<ExplicitUndefined<TriggerContainerProps>>
            ref={containerRef}
            className={classnames(classes.template, [className], {
                [classes.disabled]: disabled,
            })}
            disabled={disabled}
            dataTestIds={dataTestIds}
            classNameWhenOpen={classNameWhenOpen}
            isOpenPick={isOpenPick}
            {...otherProps}
        >
            <TriggerFocus<ExplicitUndefined<TriggerFocusProps>>
                ref={triggerRef}
                className={classes.trigger}
                dataTestIds={dataTestIds}
                disabled={disabled}
                onClick={commonTrigger.click}
                onMouseDown={commonTrigger.mousedown}
                onEnter={openAction}
                onBlankSpace={openAction}
                outlineMode={undefined}
            >
                <div className={classes.label}>{isMixed ? toMixed() : label}</div>
                <span className={classes.arrow}>
                    <IconArrowDown12 />
                </span>
            </TriggerFocus>
            {props.children}
        </TriggerContainer>
    )
}

export const TriggerSvg = forwardRef(_TriggerSvg)
