import { translation } from './show-memory-alert-dialog.translation'
import { wkDialogConfirm } from '../../../../../ui-lib/src'
import { domLocation } from '../../../../../util/src'
import { MEMORY_USAGE_THRESHOLD_WARNING } from './use-memory-usage-value'

export function showMemoryAlertDialog() {
    wkDialogConfirm.warning({
        closable: false,
        okText: translation('Reload'),
        onOk: () => domLocation().reload(),
        title: translation('dPvlJH'),
        cancelButtonProps: {
            style: { display: 'none' },
        },
        content: (
            <>
                <p>{translation('pVoWCd')}</p>
                <p>
                    {translation('cgbChw')} {(MEMORY_USAGE_THRESHOLD_WARNING * 100).toFixed(0)}
                    %。
                </p>
            </>
        ),
    })
}
