import { UpdateLibraryReplaceModalCheckedWasmCall, Wukong } from '@wukong/bridge-proto'
import { CSSProperties, useCallback } from 'react'
import { useCommand } from '../../../../main/app-context'
import { ComponentViewerItem } from '../../../../share/component-style-library/library-component-viewer/library-component-viewer'
import type { StyleViewItem } from '../../../../share/component-style-library/library-style-viewer/library-style-viewer'
import { DeepRequired } from '../../../../view-state-bridge'

export const generateDisplayItemCss = (
    displayItem: Wukong.DocumentProto.IVLibraryReplaceModalDisplayItem
): CSSProperties => ({
    height: `${displayItem.height}px`,
    top: `${displayItem.top}px`,
})

function useLibraryReplaceCheckBox(props: {
    displayItem: Omit<DeepRequired<Wukong.DocumentProto.IVLibraryReplaceModalDisplayItem>, 'target'> &
        Partial<Pick<DeepRequired<Wukong.DocumentProto.IVLibraryReplaceModalDisplayItem>, 'target'>>
}) {
    const command = useCommand()

    const onToggleChecked = useCallback(() => {
        command.DEPRECATED_invokeBridge(UpdateLibraryReplaceModalCheckedWasmCall, {
            key: {
                displayType: props.displayItem.displayType,
                publishId: props.displayItem.publishId,
                remoteId: props.displayItem.remoteId,
            },
            checked: !props.displayItem.checked,
        })
    }, [
        command,
        props.displayItem.displayType,
        props.displayItem.publishId,
        props.displayItem.remoteId,
        props.displayItem.checked,
    ])

    return {
        onToggleChecked,
    }
}

export function useLibraryReplaceStyleItem(props: Parameters<typeof useLibraryReplaceCheckBox>[0]) {
    const originViewerItem = buildStyleViewerItemFromReplaceItem(props.displayItem.origin)
    const targetViewerItem = props.displayItem.target
        ? buildStyleViewerItemFromReplaceItem(props.displayItem.target)
        : undefined

    const { onToggleChecked } = useLibraryReplaceCheckBox(props)

    return {
        originViewerItem,
        targetViewerItem,
        onToggleChecked,
    }
}

export function useLibraryReplaceComponentItem(props: Parameters<typeof useLibraryReplaceCheckBox>[0]) {
    const originViewerItem = buildComponentViewerItemFromReplaceItem(props.displayItem.origin)
    const targetViewerItem = props.displayItem.target
        ? buildComponentViewerItemFromReplaceItem(props.displayItem.target)
        : undefined

    const { onToggleChecked } = useLibraryReplaceCheckBox(props)

    return {
        originViewerItem,
        targetViewerItem,
        onToggleChecked,
    }
}

function buildComponentViewerItemFromReplaceItem(
    item: DeepRequired<Wukong.DocumentProto.IVLibraryReplaceModalItem>
): ComponentViewerItem {
    return {
        isLocal: item.thumbnailData.isLocal,
        name: item.name,
        thumbnailData: item.thumbnailData,
        backgroundColor: item.backgroundColor,
    } as ComponentViewerItem
}

function buildStyleViewerItemFromReplaceItem(
    item: DeepRequired<Wukong.DocumentProto.IVLibraryReplaceModalItem>
): StyleViewItem {
    return {
        isLocal: item.thumbnailData.isLocal,
        name: item.name,
        thumbnailData: item.thumbnailData,
        fontMissing: item.fontMissing,
    } as StyleViewItem
}
