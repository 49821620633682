import { MemberPermission, RoleStatus } from '../../../kernel/interface/type'
import { GetDocRoleStatus, GetFolderRoleStatus, RoleStatusWeight } from '../../../kernel/interface/user-roles'
import { AccessAction, AccessDropdownType, AccessMenuKey, ShareDialogType } from '../types'

export function getAccessMenuV2({
    type,
    role,
    isDraft,
    shareDialogType,
    dimission,
    hidePrototype,
}: {
    type: AccessDropdownType
    role: RoleStatus
    isDraft: boolean
    shareDialogType: ShareDialogType
    dimission: boolean
    hidePrototype: boolean
}): (AccessMenuKey | 'divider')[] {
    const validateKey = (key: RoleStatus) => {
        // 只能修改不高于自己的权限
        // 草稿箱不能转移所有者权限
        return (
            RoleStatusWeight[role] >= RoleStatusWeight[key] &&
            (!isDraft || (isDraft && key !== RoleStatus.Owner)) &&
            key
        )
    }

    switch (type) {
        case AccessDropdownType.Parent:
            return [
                MemberPermission.Inherit,
                'divider',
                MemberPermission.Edit,
                MemberPermission.View,
                'divider',
                MemberPermission.None,
            ]
        case AccessDropdownType.Member:
            switch (shareDialogType) {
                // 草稿能转移所有者权限 && 不能设置查看原型
                case ShareDialogType.Doc: {
                    const roles: Array<RoleStatus | AccessAction | 'divider'> = GetDocRoleStatus()
                        .filter((r) => RoleStatusWeight[r] <= RoleStatusWeight[role])
                        .filter((r) => r !== RoleStatus.None)
                        .filter((r) => (dimission ? r != RoleStatus.ViewPrototype : true))
                        .filter((r) => (isDraft || hidePrototype ? r !== RoleStatus.ViewPrototype : true))
                        .filter((r) => (isDraft ? r !== RoleStatus.Owner : true))
                    roles.push('divider')
                    roles.push(AccessAction.Remove)
                    return roles
                }
                case ShareDialogType.Folder: {
                    const roles: Array<RoleStatus | AccessAction | 'divider'> = GetFolderRoleStatus()
                        .filter((r) => RoleStatusWeight[r] <= RoleStatusWeight[role])
                        .filter((r) => r !== RoleStatus.None)
                    roles.push('divider')
                    roles.push(AccessAction.Remove)
                    return roles
                }
            }
            break
        case AccessDropdownType.Invited:
            return [
                validateKey(RoleStatus.Editor),
                validateKey(RoleStatus.Viewer),
                'divider',
                AccessAction.ReInvite,
                AccessAction.DeleteInvite,
            ].filter((v) => v) as AccessMenuKey[]

        case AccessDropdownType.Self:
            return [role, 'divider', AccessAction.Leave]
        case AccessDropdownType.Public:
            return [RoleStatus.Editor, RoleStatus.Viewer]
        default:
            return []
    }
}

// 有权限的人都可以邀请成员 但是只能邀请为不高于 Math.min(当前角色, editor) 并且没有 none
// hidePrototype 草稿或者离职继承的时候要隐藏这个选项
export const getInviteMemberMenuItems = (role: RoleStatus, hidePrototype: boolean, type: ShareDialogType) => {
    // 可邀请的最大权限
    const maxRoleWeight = Math.min(RoleStatusWeight[role], RoleStatusWeight[RoleStatus.Editor])
    if (type == ShareDialogType.Doc) {
        return GetDocRoleStatus()
            .filter((r) => {
                return RoleStatusWeight[r] <= maxRoleWeight && r !== RoleStatus.None
            })
            .filter((r) => (hidePrototype ? r !== RoleStatus.ViewPrototype : true))
    } else {
        return GetFolderRoleStatus().filter((r) => {
            return RoleStatusWeight[r] <= maxRoleWeight && r !== RoleStatus.None
        })
    }
}
