import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    NoColorsAvailable: '本文件没有颜色样式',
    NoMatchesFound: '没有找到该颜色样式',
    ColorStyle: '颜色样式',
    Search: '搜索',
} as const

export const enTranslation = {
    NoColorsAvailable: 'This file has no color styles',
    NoMatchesFound: 'No results found',
    ColorStyle: 'Color styles',
    Search: 'Search',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
