import { Wukong } from '@wukong/bridge-proto'
import { Title } from '../../atom/title/title'
import { useAutoLayoutState } from '../../design-panel-v2/attributes/components/auto-layout'
import { translation } from './constraints.translation'
import { EditArea } from './edit-area/edit-area'
import { useConstraints } from './use-constraints'

import ConstraintType = Wukong.DocumentProto.ConstraintType

export function Constraints() {
    const {
        show,
        horizontalValue,
        horizontalMixed,
        verticalMixed,
        verticalValue,
        disabled,
        onChangeHorizontal,
        onChangeVertical,
    } = useConstraints()
    const autoLayoutState = useAutoLayoutState()

    return show ? (
        <div className={'pt-8px pb-12px'}>
            <Title>
                <Title.Left>{translation('Constraints')}</Title.Left>
            </Title>
            <EditArea
                disabled={disabled}
                horizontal={{ value: horizontalValue ?? ConstraintType.CONSTRAINT_TYPE_MIN, mixed: !!horizontalMixed }}
                vertical={{ value: verticalValue ?? ConstraintType.CONSTRAINT_TYPE_MIN, mixed: !!verticalMixed }}
                onChangeHorizontal={onChangeHorizontal}
                onChangeVertical={onChangeVertical}
                disableVerticalFlexItems={autoLayoutState.attrState?.disableHeightFlexConstraint}
                disableHorizontalFlexItems={autoLayoutState.attrState?.disableWidthFlexConstraint}
            />
        </div>
    ) : null
}
