import { translation } from './issue-modal.translation'
/* eslint-disable no-restricted-imports */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Checkbox, InputV2, MonoIconCommonWarning24, WKDialog } from '../../../../../../ui-lib/src'
import { getMotiffName, isEnglishLanguage } from '../../../../../../util/src'
import { environment } from '../../../../environment'
import classes from './issue-modal.module.less'

export interface IssusModalProps {
    type: 'crash' | 'bug'
    visible: boolean
    onOK: (message: string) => void
    onCancel: () => void
    onClose?: () => void
    dataTestIds?: {
        oKButton?: string
        cancelBtn?: string
        textarea?: string
    }
}
export function IssusModal(props: IssusModalProps) {
    const { type, visible, dataTestIds, onOK, onCancel, onClose } = props
    const [description, setDescription] = useState('')
    const [checked, setChecked] = useState(true)
    const [loading, setLoading] = useState<boolean>(false)
    const loadingTimer = useRef<NodeJS.Timeout>()

    const onClickOK = useCallback(() => {
        setLoading(true)
        loadingTimer.current = setTimeout(() => {
            onClose?.()
            setLoading(false)
        }, 2000)
        onOK(description)
    }, [description, onOK, onClose])

    const closable = useMemo(() => {
        return type === 'bug'
    }, [type])

    const title = useMemo(() => {
        if (type === 'bug') {
            return translation('yxcOTc')
        }
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <MonoIconCommonWarning24 style={{ color: 'var(--wk-red-7)', margin: '-1px 8px -1px -3px' }} />
                <span>{translation('VzSpOC', { name: getMotiffName() })}</span>
            </div>
        )
    }, [type])

    useEffect(() => {
        return () => {
            if (loadingTimer.current) {
                clearTimeout(loadingTimer.current)
                setLoading(false)
            }
        }
    }, [])

    return (
        <WKDialog
            visible={visible}
            closable={closable}
            title={title}
            width={464}
            onCancel={onCancel}
            cancelText={type === 'bug' ? translation('mQtsMy') : translation('mgGmil')}
            onOk={onClickOK}
            cancelButtonProps={{ dataTestId: dataTestIds?.cancelBtn }}
            okText={loading ? translation('eNbpXi') : translation('XQZPJn')}
            okButtonProps={{ loading: loading, disabled: !checked, dataTestId: dataTestIds?.oKButton }}
            titlePadding16
        >
            <div>
                {type === 'crash' ? (
                    <div className={classes.crashTitleMessage}>{translation('PdGsQj', { name: getMotiffName() })}</div>
                ) : null}
                <div className={classes.descriptionTitle}>{translation('XgjzFE')}</div>
                <div className={classes.description}>
                    <InputV2.Textarea
                        placeholder={`${translation('cfXEyR')}`}
                        value={description}
                        onChange={(e) => setDescription((e.target as HTMLTextAreaElement).value)}
                        dataTestIds={{ textarea: dataTestIds?.textarea }}
                        height={80}
                        autoFocus
                    ></InputV2.Textarea>
                </div>
                <div className={classes.checkboxContainer}>
                    <Checkbox
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                        label={
                            <div className={classes.label}>
                                <span className={classes.labelStart}>*</span>
                                <span className={classes.labelText}>{translation('wByvxi')}</span>
                            </div>
                        }
                        className={`${classes.checkbox} -ml-1px! py-3px`}
                        size="medium"
                    />
                    <div className={classes.checkMessage} data-testid="issue-modal-description">
                        {isEnglishLanguage() ? (
                            <>
                                Sending reported errors and operational data of the file to our R&D team will help us to
                                resolve the issue faster. The information you provide will be used in accordance with
                                our{' '}
                                <a href={`${environment.host}/terms`} target="_blank" rel="noreferrer">
                                    Trems of Service
                                </a>{' '}
                                and{' '}
                                <a href={`${environment.host}/privacy`} target="_blank" rel="noreferrer">
                                    Privacy Policy
                                </a>
                                .
                            </>
                        ) : (
                            <>
                                发送文件的报错信息和操作数据至 {getMotiffName()}
                                研发团队，将有助于我们更快速地解决问题。我们会根据{' '}
                                <a href={`${environment.host}/terms`} target="_blank" rel="noreferrer">
                                    服务协议
                                </a>{' '}
                                和{' '}
                                <a href={`${environment.host}/privacy`} target="_blank" rel="noreferrer">
                                    隐私政策
                                </a>{' '}
                                使用您提供的信息。
                            </>
                        )}
                    </div>
                </div>
            </div>
        </WKDialog>
    )
}
