import { Rect, TOUR_ARROW_OFFSET, TOUR_ARROW_WIDTH, WKTourPlacement } from './type'

export function getElementsRect(elements: HTMLElement[]) {
    if (!elements.length) {
        return
    }
    let rect: Rect | undefined
    elements.forEach((v) => {
        const { top, left, bottom, right, width, height } = v.getBoundingClientRect()
        if (!rect) {
            rect = { top, left, bottom, right, width, height }
        } else {
            rect.top = Math.min(rect.top, top)
            rect.left = Math.min(rect.left, left)
            rect.bottom = Math.max(rect.bottom, bottom)
            rect.right = Math.max(rect.right, right)
            rect.width = rect.right - rect.left
            rect.height = rect.bottom - rect.top
        }
    })
    return rect
}

function calculateVerticalPosition({
    placement,
    triggerRect,
    contentRect,
    worldRect,
}: {
    placement: WKTourPlacement
    triggerRect: Rect
    contentRect: Rect
    worldRect: Rect
}) {
    const { top: triggerTop, left: triggerLeft, bottom: triggerBottom, width: triggerWidth } = triggerRect
    const { width: contentWidth, height: contentHeight } = contentRect
    const triggerCenter = triggerLeft + triggerWidth / 2
    const newPosition = { top: 0, left: 0 }
    const posArr = placement.split('-')
    const isBottom = posArr[0] === 'bottom'
    let newPlacement = placement

    if (isBottom) {
        newPosition.top = triggerBottom + TOUR_ARROW_WIDTH / 2
    } else {
        newPosition.top = triggerTop - contentHeight - TOUR_ARROW_WIDTH / 2
    }

    // top-right
    const leftPos1 = triggerCenter - TOUR_ARROW_OFFSET - TOUR_ARROW_WIDTH / 2
    // top-center
    const leftPos2 = triggerCenter - contentWidth / 2
    // top-left
    const leftPos3 = triggerCenter + TOUR_ARROW_OFFSET + TOUR_ARROW_WIDTH / 2 - contentWidth

    switch (placement) {
        case 'top-right':
        case 'bottom-right':
            newPosition.left = leftPos1
            // 右侧超出屏幕，左侧有空间
            if (leftPos1 + contentWidth > worldRect.right) {
                if (leftPos2 >= worldRect.left && leftPos2 + contentWidth <= worldRect.right) {
                    newPlacement = isBottom ? 'bottom-center' : 'top-center'
                    newPosition.left = leftPos2
                } else if (leftPos3 >= worldRect.left) {
                    newPlacement = isBottom ? 'bottom-left' : 'top-left'
                    newPosition.left = leftPos3
                }
            }
            break
        case 'top-center':
        case 'bottom-center':
            newPosition.left = leftPos2
            // 左侧超出屏幕，右侧有空间
            if (leftPos2 < worldRect.left && leftPos1 + contentWidth <= worldRect.right) {
                newPlacement = isBottom ? 'bottom-right' : 'top-right'
                newPosition.left = leftPos1
            } else if (leftPos2 + contentWidth > worldRect.right && leftPos3 >= worldRect.left) {
                // 右侧超出屏幕，左侧有空间
                newPlacement = isBottom ? 'bottom-left' : 'top-left'
                newPosition.left = leftPos3
            }
            break
        case 'top-left':
        case 'bottom-left':
            newPosition.left = leftPos3
            // 左侧超出屏幕，右侧有空间
            if (leftPos3 < worldRect.left) {
                if (leftPos2 >= worldRect.left && leftPos2 + contentWidth <= worldRect.right) {
                    newPlacement = isBottom ? 'bottom-center' : 'top-center'
                    newPosition.left = leftPos2
                } else if (leftPos1 + contentWidth <= worldRect.right) {
                    newPlacement = isBottom ? 'bottom-right' : 'top-right'
                    newPosition.left = leftPos1
                }
            }
            break
        default:
            break
    }
    return { newPlacement, newPosition }
}

function calculateHorizontalPosition({
    placement,
    triggerRect,
    contentRect,
    worldRect,
}: {
    placement: WKTourPlacement
    triggerRect: Rect
    contentRect: Rect
    worldRect: Rect
}) {
    const { top: triggerTop, left: triggerLeft, right: triggerRight, height: triggerHeight } = triggerRect
    const { width: contentWidth, height: contentHeight } = contentRect
    const triggerCenter = triggerTop + triggerHeight / 2
    const newPosition = { top: 0, left: 0 }
    const posArr = placement.split('-')
    const isRight = posArr[0] === 'right'
    let newPlacement = placement

    if (isRight) {
        newPosition.left = triggerRight + TOUR_ARROW_WIDTH / 2
    } else {
        newPosition.left = triggerLeft - contentWidth - TOUR_ARROW_WIDTH / 2
    }

    // left-bottom
    const topPos1 = triggerCenter - TOUR_ARROW_OFFSET - TOUR_ARROW_WIDTH / 2
    // left-center
    const topPos2 = triggerCenter - contentHeight / 2
    // left-top
    const topPos3 = triggerCenter + TOUR_ARROW_OFFSET + TOUR_ARROW_WIDTH / 2 - contentHeight

    switch (placement) {
        case 'left-bottom':
        case 'right-bottom':
            newPosition.top = topPos1
            // 下方超出屏幕，上方有空间
            if (topPos1 + contentHeight > worldRect.bottom) {
                if (topPos2 >= worldRect.top && topPos2 + contentHeight <= worldRect.bottom) {
                    newPlacement = isRight ? 'right-center' : 'left-center'
                    newPosition.top = topPos2
                } else if (topPos3 >= worldRect.top) {
                    newPlacement = isRight ? 'right-top' : 'left-top'
                    newPosition.top = topPos3
                }
            }
            break
        case 'left-center':
        case 'right-center':
            newPosition.top = topPos2
            // 上方超出屏幕，下方有空间
            if (topPos2 < worldRect.top && topPos1 + contentHeight <= worldRect.bottom) {
                newPlacement = isRight ? 'right-bottom' : 'left-bottom'
                newPosition.top = topPos1
            } else if (topPos2 + contentHeight > worldRect.bottom && topPos3 >= worldRect.top) {
                // 下方超出屏幕，上方有空间
                newPlacement = isRight ? 'right-top' : 'left-top'
                newPosition.top = topPos3
            }
            break
        case 'left-top':
        case 'right-top':
            newPosition.top = topPos3
            // 上方超出屏幕，下方有空间
            if (topPos3 < worldRect.top) {
                if (topPos2 >= worldRect.top && topPos2 + contentHeight <= worldRect.bottom) {
                    newPlacement = isRight ? 'right-center' : 'left-center'
                    newPosition.top = topPos2
                } else if (topPos1 + contentHeight <= worldRect.bottom) {
                    newPlacement = isRight ? 'right-bottom' : 'left-bottom'
                    newPosition.top = topPos1
                }
            }
            break
        default:
            break
    }
    return { newPlacement, newPosition }
}

/**
 * 计算 TourStep 位置
 * @returns {newPlacement, newPosition}
 */
export function calculateContentRect({
    placement,
    triggerRect,
    contentRect,
    worldRect,
}: {
    placement: WKTourPlacement
    triggerRect: Rect
    contentRect: Rect
    worldRect: Rect
}) {
    const posArr = placement.split('-')
    const isVertical = posArr[0] === 'top' || posArr[0] === 'bottom'
    const isBottom = posArr[0] === 'bottom'
    const isRight = posArr[0] === 'right'
    const topRest = triggerRect.top - worldRect.top
    const bottomRest = worldRect.bottom - triggerRect.bottom
    const leftRest = triggerRect.left - worldRect.left
    const rightRest = worldRect.right - triggerRect.right

    // 上下展开的气泡
    if (isVertical) {
        const contentHeight = contentRect.height + TOUR_ARROW_WIDTH / 2
        // 气泡出现在上方
        if (
            (isBottom && bottomRest < contentHeight && topRest >= contentHeight) ||
            (!isBottom && (topRest >= contentHeight || bottomRest < contentHeight))
        ) {
            const { newPlacement, newPosition } = calculateVerticalPosition({
                placement: placement.replace('bottom', 'top') as WKTourPlacement,
                triggerRect,
                worldRect,
                contentRect,
            })
            return { newPlacement, newPosition }
        } else {
            const { newPlacement, newPosition } = calculateVerticalPosition({
                placement: placement.replace('top', 'bottom') as WKTourPlacement,
                triggerRect,
                worldRect,
                contentRect,
            })
            return { newPlacement, newPosition }
        }
    } else {
        // 左右展开的气泡
        const contentWidth = contentRect.width + TOUR_ARROW_WIDTH / 2
        // 气泡出现在左侧
        if (
            (isRight && rightRest < contentWidth && leftRest >= contentWidth) ||
            (!isRight && (leftRest >= contentWidth || rightRest < contentWidth))
        ) {
            const { newPlacement, newPosition } = calculateHorizontalPosition({
                placement: placement.replace('right', 'left') as WKTourPlacement,
                triggerRect,
                worldRect,
                contentRect,
            })
            return { newPlacement, newPosition }
        } else {
            const { newPlacement, newPosition } = calculateHorizontalPosition({
                placement: placement.replace('left', 'right') as WKTourPlacement,
                triggerRect,
                worldRect,
                contentRect,
            })
            return { newPlacement, newPosition }
        }
    }
}
