import { DELETE, GET } from '@tutor/network-core'
import { DocID, DocPrototypeVO, DocWithAuthorityVO, OrgID } from '../interface/type'
import { omitByValue } from '../util/omit-by-value'
import { BaseCommonRequest } from './base-request'

// 获取用户的最近访问文件列表
@GET
export class GetUserRecentRecordRequest extends BaseCommonRequest<
    { visitedTime: number; document: DocWithAuthorityVO }[]
> {
    constructor(private readonly orgId?: OrgID) {
        super()
    }
    public override requestUrl() {
        return 'recents'
    }
    public override requestArgument() {
        return omitByValue({
            orgId: this.orgId,
        })
    }
}

// 批量删除文档浏览记录
@DELETE
export class BatchRemoveOpenRecordRequest extends BaseCommonRequest {
    constructor(private readonly docIds: DocID[]) {
        super()
    }

    public override requestUrl() {
        return 'recents/batch'
    }

    public override requestBody() {
        return this.docIds
    }
}

// 获取用户的最近访问原型文件列表
@GET
export class GetUserRecentPrototypeRecordRequest extends BaseCommonRequest<
    { visitedTime: number; prototype: DocPrototypeVO }[]
> {
    constructor(private readonly orgId?: OrgID) {
        super()
    }
    public override requestUrl() {
        return 'recents/prototype'
    }
    public override requestArgument() {
        return omitByValue({
            orgId: this.orgId,
        })
    }
    // TODO: prototype 服务端还没实现这个接口
    public override requestDidFailed(): boolean {
        return false
    }
}

// 批量删除原型文档浏览记录
@DELETE
export class BatchDeletePrototypeOpenRecordRequest extends BaseCommonRequest {
    constructor(private readonly prototypeIds: string[]) {
        super()
    }

    public override requestUrl() {
        return 'recents/prototype/batch'
    }

    public override requestBody() {
        return this.prototypeIds
    }
}
