import { ImageDpiInfo, kDefaultDPI } from './png-chunks'
import { ImageFormat } from './types'

// https://en.wikipedia.org/wiki/JPEG_File_Interchange_Format
const APP0_DATA_LEN = 14
const SOI = 0xffd8
const APP0_MARKER = 0xffe0
const APP0_IDENTIFIER = 'JFIF\0'

export function isValidJpgFile(array: ArrayBuffer) {
    const dataView = new DataView(array)
    // 只简单检查长度和SOI头是否合法
    if (array.byteLength <= APP0_DATA_LEN) {
        return false
    }

    const matchSOI = dataView.getUint16(0) == SOI
    return matchSOI
}

const CHUNK_PREFIX = 'ICC_PROFILE\0'

function isICCChunkData(chunk: Uint8Array) {
    return String.fromCharCode.apply(null, [...chunk.subarray(0, CHUNK_PREFIX.length)]) === CHUNK_PREFIX
}

// 0xFFE0 ~ 0xFFEF 是 Application-sepcific chunk，ICC_Profile 在 APP2 内
function isAPP0(data: Uint8Array, offset: number) {
    return data[offset] === 0xff && data[offset + 1] === 0xe0
}
function isAPP1(data: Uint8Array, offset: number) {
    return data[offset] === 0xff && data[offset + 1] === 0xe1
}
function isAPP2(data: Uint8Array, offset: number) {
    return data[offset] === 0xff && data[offset + 1] === 0xe2
}

function tryParseAPP2(data: Uint8Array, offset: number) {
    // Marker(2 Bytes) + Size(2 Bytes) + Detail Data(Size - 2 Bytes)
    if (!isAPP2(data, offset)) {
        return null
    }

    const applicationSpecChunk = data.subarray(offset)
    const size = (applicationSpecChunk[2] << 8) | applicationSpecChunk[3]
    const content = applicationSpecChunk.subarray(4, size + 2)

    return {
        size,
        content,
        nextOffset: offset + size + 2,
    }
}

function removeJPEGICCPChunk(source: Uint8Array) {
    const shouldSkipRange: Array<[number, number]> = []
    for (let i = 0; i < source.length && i + 1 < source.length; ) {
        const parsed = tryParseAPP2(source, i)
        if (parsed) {
            if (isICCChunkData(parsed.content)) {
                shouldSkipRange.push([i, parsed.nextOffset])
            }
            i = parsed.nextOffset
        } else {
            i++
        }
    }
    if (shouldSkipRange.length === 0) {
        return source
    }

    // 额外写一个空的 range，使得自然的把图片最后的数据也塞进去
    shouldSkipRange.push([source.length, source.length])

    const totalLength = source.length - shouldSkipRange.reduce((prev, curr) => prev + (curr[1] - curr[0]), 0)
    const ret = new Uint8Array(totalLength)

    let offset = 0
    let newImageOffset = 0
    for (const [l, r] of shouldSkipRange) {
        if (l > offset) {
            const data = source.subarray(offset, l)
            ret.set(data, newImageOffset)
            newImageOffset += data.length
        }
        offset = r
    }

    return ret
}

// 这段函数改自 Figma，原理具体可以看看这些文章
// https://cloud.tencent.com/developer/article/1427939
// https://stackoverflow.com/questions/61758329/jpg-how-to-read-extract-data-from-icc-profile-section-app2
export function removeJPEGICCChunksAndGetMeta(source: Uint8Array): {
    imageWithoutColorSpace: Uint8Array
    colorProfileRawData: Uint8Array
    format: ImageFormat | null
    hasGamma: boolean
} {
    interface ICCProfileChunk {
        markerSeqNumber: number
        totalNumberOfMarkers: number
        iccProfileData: Uint8Array
    }

    function joinICCProfileChunks(toJoinICCProfileChunks: Record<number, ICCProfileChunk>) {
        let toJoinList: Array<Uint8Array> = []
        for (const [u, p] of Object.entries(toJoinICCProfileChunks)) {
            if (toJoinList.length === 0) {
                toJoinList = new Array(p.totalNumberOfMarkers).fill(null)
            }
            toJoinList[Number(u) - 1] = p.iccProfileData
        }
        if (!toJoinList.every(Boolean)) return null

        const totalLength = toJoinList.reduce((u, p) => u + p.length, 0)
        const ret = new Uint8Array(totalLength)
        let offset = 0
        for (const u of toJoinList) {
            ret.set(u, offset)
            offset += u.length
        }
        return ret.length ? ret : null
    }

    function getICCChunkData(chunk: Uint8Array) {
        if (!isICCChunkData(chunk)) {
            return null
        }

        const markerSeqNumber = chunk[CHUNK_PREFIX.length]
        const totalNumberOfMarkers = chunk[CHUNK_PREFIX.length + 1]
        const iccProfileData = chunk.subarray(CHUNK_PREFIX.length + 2, chunk.length)

        return {
            markerSeqNumber,
            totalNumberOfMarkers,
            iccProfileData,
        }
    }

    function getApplicationSpecChunks(source_: Uint8Array) {
        const ret: Array<Uint8Array> = []
        for (let i = 0; i < source_.length && i + 1 < source_.length; ) {
            const parsed = tryParseAPP2(source_, i)
            if (parsed) {
                ret.push(parsed.content)
                i = parsed.nextOffset
            } else {
                i++
            }
        }
        return ret
    }

    const imageWithoutColorSpace = removeJPEGICCPChunk(source)
    let colorProfileRawData = new Uint8Array()
    try {
        const toJoinICCProfileChunks: Record<number, ICCProfileChunk> = {}
        const app2Chunks = getApplicationSpecChunks(source)
        for (const chunk of app2Chunks) {
            const iccChunk = getICCChunkData(chunk)
            if (iccChunk) {
                toJoinICCProfileChunks[iccChunk.markerSeqNumber] = iccChunk
            }
        }
        const _colorProfileRawData = joinICCProfileChunks(toJoinICCProfileChunks)
        if (_colorProfileRawData) {
            colorProfileRawData = _colorProfileRawData
        }
    } catch (e) {
        console.error(e)
    }

    return {
        imageWithoutColorSpace,
        colorProfileRawData,
        format: ImageFormat.Jpg,
        hasGamma: false,
    }
}

export async function getJpegFileDPI(image: Blob): Promise<ImageDpiInfo> {
    const dpiInfo: ImageDpiInfo = { xDPI: kDefaultDPI, yDPI: kDefaultDPI }
    const arrayBuffer = await image.arrayBuffer()
    const dataView = new DataView(arrayBuffer)

    if (!isValidJpgFile(arrayBuffer)) {
        return dpiInfo
    }

    let offset = 2 // 跳过SOI
    const marker = dataView.getUint16(offset)
    const length = dataView.getUint16(offset + 2)
    offset += 2

    if (marker == APP0_MARKER && length >= APP0_DATA_LEN) {
        const decoder = new TextDecoder('utf-8')
        const app0Identifier = decoder.decode(new Uint8Array(arrayBuffer, offset + 2, 5))
        if (app0Identifier === APP0_IDENTIFIER) {
            const densityUnit = dataView.getUint8(offset + 9)
            const xDensity = dataView.getUint16(offset + 10)
            const yDensity = dataView.getUint16(offset + 12)
            // 0: no unit 1: pixels per inch 2:pixels per centimeter
            dpiInfo.xDPI = Math.round(densityUnit === 2 ? xDensity * 2.54 : xDensity)
            dpiInfo.yDPI = Math.round(densityUnit === 2 ? yDensity * 2.54 : yDensity)
        }
    }

    return dpiInfo
}

export function replaceJPEGICCChunk(source: Uint8Array, iccp: Uint8Array) {
    const findJPEGApp2ToInsertPosition = (data: Uint8Array) => {
        // SOI
        if (!(data[0] === 0xff && data[1] === 0xd8)) {
            return -1
        }
        // 是 App0 或 App1
        let offset = 2
        while (offset + 1 < data.length) {
            if (!isAPP0(data, offset) && !isAPP1(data, offset)) {
                break
            }

            const applicationSpecChunk = data.subarray(offset)
            const size = (applicationSpecChunk[2] << 8) | applicationSpecChunk[3]
            offset += size + 2
        }
        return offset
    }

    const insertedPos = findJPEGApp2ToInsertPosition(source)
    if (insertedPos === -1) {
        return source
    }

    source = removeJPEGICCPChunk(source)

    const _app2IccpSegmentData = new Uint8Array(CHUNK_PREFIX.length + 2 + iccp.length)
    {
        let offset = 0
        _app2IccpSegmentData.set(new Uint8Array(CHUNK_PREFIX.split('').map((v) => v.charCodeAt(0))), offset)
        offset += CHUNK_PREFIX.length
        // markerSeqNumber, totalNumberOfMarkers
        _app2IccpSegmentData.set(new Uint8Array([1, 1]), offset)
        offset += 2
        // iccp
        _app2IccpSegmentData.set(iccp, offset)
    }

    const app2IccpSegment = new Uint8Array(_app2IccpSegmentData.byteLength + 4)
    {
        let offset = 0
        // APP2
        app2IccpSegment.set(new Uint8Array([0xff, 0xe2]), offset)
        offset += 2
        // Size
        const size = _app2IccpSegmentData.byteLength + 2
        app2IccpSegment.set(new Uint8Array([size >> 8, size & 255]), offset)
        offset += 2
        // Data
        app2IccpSegment.set(_app2IccpSegmentData, offset)
    }

    const ret = new Uint8Array(source.byteLength + app2IccpSegment.byteLength)
    {
        let offset = 0
        ret.set(source.subarray(0, insertedPos), offset)
        offset += insertedPos
        ret.set(app2IccpSegment, offset)
        offset += app2IccpSegment.byteLength
        ret.set(source.subarray(insertedPos), offset)
    }
    return ret
}
