/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { EditorTopBar } from '../../../share/editor-top-bar/wrapper'
import { useViewState } from '../../../view-state-bridge'
import { CoactorMemberList } from '../cooperation'
import { useDetail } from '../detail/use-detail'
import { DesignModeMenuWrapper, DevModeMenuWrapper } from './menu/menu-wrapper'
import { DesignModeViewMenu, DevModeViewMenu } from './menu/view-menu'
import {
    DevModeUnavailableAlertBanner,
    ExceedUsageLimitForFreeTeamBanner,
    FreezeAlertBanner,
} from './payment-alert-banner'
import { ToolAiReplaceComponent } from './tool-ai-replace-component/tool-ai-replace-component'
import { ToolExtend } from './tool-extend/tool-extend'
import { ToolFontMiss } from './tool-font-miss/tool-font-miss'
import { ToolSyncStatus } from './tool-sync-status/tool-sync-status'
import { ToolTitle } from './tool-title/tool-title'
import { ToolUser } from './tool-user/tool-user'
import { DesignModeToolbar, DevModeToolbar } from './toolbar/toolbar'
import { TopAreaContext } from './top-area-context'
import { PrototypePresent } from './top-preview'
import { SwitchEditorTypeIcon } from './top-switch-dev'
import { useTopArea } from './use-top-area'

export function TopArea() {
    const { isNotTitle, contextValue, docReadonly, editorTopBarRef } = useTopArea()
    const { onMouseDown } = useDetail()

    const editorType = useViewState('editorType')
    const isDevMode = editorType === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV
    const hideTitle = !isDevMode && isNotTitle

    return (
        <TopAreaContext.Provider value={contextValue}>
            <EditorTopBar
                ref={editorTopBarRef}
                isNotTitle={hideTitle}
                onMouseDown={onMouseDown}
                left={
                    <>
                        {isDevMode ? <DevModeMenuWrapper /> : <DesignModeMenuWrapper />}
                        {isDevMode ? <DevModeToolbar /> : <DesignModeToolbar />}
                    </>
                }
                center={<>{hideTitle ? <ToolExtend /> : <ToolTitle isDevMode={isDevMode} />}</>}
                right={
                    <>
                        {!docReadonly && <ToolAiReplaceComponent />}
                        {!docReadonly && !isDevMode && <ToolFontMiss />}
                        <ToolSyncStatus />
                        <CoactorMemberList />
                        <ToolUser isDevMode={isDevMode} />
                        <SwitchEditorTypeIcon />
                        <PrototypePresent />
                        {isDevMode ? <DevModeViewMenu /> : <DesignModeViewMenu />}
                    </>
                }
            />
            <DevModeUnavailableAlertBanner isDevMode={isDevMode} />
            <FreezeAlertBanner isDevMode={isDevMode} />
            {!isDevMode && <ExceedUsageLimitForFreeTeamBanner />}
        </TopAreaContext.Provider>
    )
}
