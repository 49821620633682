import { DELETE, GET, POST } from '@tutor/network-core'
import type { FontInfoVO, FontPackageReplaceVO, FontPackageVO, PostscriptFamilyStyleVO } from '../interface/font'
import type { FileCosAuthorizationVO } from '../interface/image-cos'
import { BaseCommonRequest, InnerRequest } from './base-request'

@POST
export class CreateFontRequest extends BaseCommonRequest<FontInfoVO> {
    constructor(private readonly fontInfoVO: Omit<FontInfoVO, 'id'>) {
        super()
    }

    public override requestUrl() {
        return 'fonts'
    }

    public override requestBody() {
        return this.fontInfoVO
    }
}

/**
 * 替换企业字体信息
 */

@POST
export class ReplaceOrgFontRequest extends BaseCommonRequest<FontInfoVO> {
    constructor(private readonly id: number, private readonly fontInfoVO: Omit<FontInfoVO, 'id'>) {
        super()
    }

    public override requestUrl() {
        return `fonts/${this.id}/replace/org`
    }

    public override requestBody() {
        return this.fontInfoVO
    }
}
@POST
export class ReplaceFontRequest extends BaseCommonRequest<FontInfoVO> {
    constructor(private readonly id: number, private readonly fontInfoVO: Omit<FontInfoVO, 'id'>) {
        super()
    }

    public override requestUrl() {
        return `fonts/${this.id}/replace`
    }

    public override requestBody() {
        return this.fontInfoVO
    }
}

@DELETE
export class DeleteOrgFontRequest extends BaseCommonRequest<void> {
    constructor(private readonly id: number) {
        super()
    }

    public override requestUrl() {
        return `fonts/${this.id}/org`
    }
}

@DELETE
export class DeleteFontRequest extends BaseCommonRequest<void> {
    constructor(private readonly id: number) {
        super()
    }

    public override requestUrl() {
        return `fonts/${this.id}`
    }
}
/**
 * 申请企业字体文件上传文件权限
 */

@GET
export class GetOrgFontFileUploadAuth extends BaseCommonRequest<FileCosAuthorizationVO> {
    constructor(private readonly format: string, private readonly orgId: string) {
        super()
    }

    public override requestUrl() {
        return `fonts/upload-auth/files/org`
    }

    public override requestArgument() {
        return { format: this.format, orgId: this.orgId }
    }
}

@GET
export class GetFontFileUploadAuth extends BaseCommonRequest<FileCosAuthorizationVO> {
    constructor(private readonly format: string, private readonly teamId: string) {
        super()
    }

    public override requestUrl() {
        return `fonts/upload-auth/files`
    }

    public override requestArgument() {
        return { format: this.format, teamId: this.teamId }
    }
}

@GET
export class GetSystemFontFileUploadAuth extends InnerRequest<FileCosAuthorizationVO> {
    constructor(private readonly format: string) {
        super()
    }

    public override requestUrl() {
        return `admin/fonts/upload-auth/files`
    }

    public override requestArgument() {
        return { format: this.format }
    }
}
/**
 * 申请企业字体缩略图文件上传文件权限
 */

@GET
export class GetOrgFontThumbnailUploadAuth extends BaseCommonRequest<FileCosAuthorizationVO[]> {
    constructor(private readonly format: string, private readonly count: number, private readonly orgId: string) {
        super()
    }

    public override requestUrl() {
        return `fonts/upload-auth/thumbnails/org`
    }

    public override requestArgument() {
        return { format: this.format, count: this.count, orgId: this.orgId }
    }
}
@GET
export class GetSystemFontThumbnailUploadAuth extends InnerRequest<FileCosAuthorizationVO[]> {
    constructor(private readonly format: string, private readonly count: number) {
        super()
    }

    public override requestUrl() {
        return `admin/fonts/upload-auth/thumbnails`
    }

    public override requestArgument() {
        return { format: this.format, count: this.count }
    }
}
/**
 * 添加企业字体信息
 */
@POST
export class CreateOrgFontRequest extends BaseCommonRequest<FontInfoVO> {
    constructor(private readonly fontInfoVO: Omit<FontInfoVO, 'id'>) {
        super()
    }

    public override requestUrl() {
        return 'fonts/org'
    }

    public override requestBody() {
        return this.fontInfoVO
    }
}

@POST
export class CreateSystemFontRequest extends InnerRequest<FontInfoVO> {
    constructor(private readonly fontInfoVO: Omit<FontInfoVO, 'id'>) {
        super()
    }

    public override requestUrl() {
        return 'admin/fonts'
    }

    public override requestBody() {
        return this.fontInfoVO
    }
}

@GET
export class GetFontThumbnailUploadAuth extends BaseCommonRequest<FileCosAuthorizationVO[]> {
    constructor(private readonly format: string, private readonly count: number, private readonly teamId: string) {
        super()
    }

    public override requestUrl() {
        return `fonts/upload-auth/thumbnails`
    }

    public override requestArgument() {
        return { format: this.format, count: this.count, teamId: this.teamId }
    }
}

@POST
export class UploadPostscriptFamilyStyle extends BaseCommonRequest<PostscriptFamilyStyleVO[]> {
    constructor(private readonly vos: PostscriptFamilyStyleVO[]) {
        super()
    }

    public override requestUrl() {
        return 'fonts/postscript-family-style/upload'
    }

    public override requestBody() {
        return this.vos
    }
}

@GET
export class GetPostscriptFamilyStyle extends BaseCommonRequest<PostscriptFamilyStyleVO[]> {
    public override requestUrl() {
        return 'fonts/postscript-family-style'
    }
}
/**
 * 批量获取团队共享字体信息, 在编辑器内获取团队共享字体的时候需要带上docId
 */
@GET
export class GetTeamFontList extends BaseCommonRequest<FontInfoVO[]> {
    constructor(private readonly teamId: string, private readonly docId?: string) {
        super()
    }
    public override requestUrl() {
        return 'fonts/team'
    }
    public override requestArgument() {
        return { teamId: this.teamId, docId: this.docId ?? '' }
    }
}

/**
 * 批量获取云端共享字体信息
 */
@GET
export class GetSystemFontList extends BaseCommonRequest<FontInfoVO[]> {
    public override requestUrl() {
        return 'fonts/system'
    }
}

/**
 * 批量获取企业共享字体信息, 在编辑器内获取企业共享字体的时候需要带上docId
 */
@GET
export class GetOrgFontList extends BaseCommonRequest<FontInfoVO[]> {
    constructor(private readonly orgId: string, private readonly docId?: string) {
        super()
    }
    public override requestUrl() {
        return 'fonts/org'
    }
    public override requestArgument() {
        return { orgId: this.orgId, docId: this.docId ?? '' }
    }
}
/**
 * 添加系统字体包
 */
@POST
export class AddSystemFontPackage extends InnerRequest<FontInfoVO[]> {
    constructor(private readonly fontPackageVO: Omit<FontPackageVO, 'orgId' | 'teamId'>) {
        super()
    }
    public override requestUrl() {
        return 'admin/fonts/package'
    }

    public override requestBody() {
        return this.fontPackageVO
    }
}
/**
 * 添加企业字体包
 */
@POST
export class AddOrgFontPackage extends BaseCommonRequest<FontInfoVO[]> {
    constructor(private readonly fontPackageVO: FontPackageVO) {
        super()
    }
    public override requestUrl() {
        return 'fonts/org/package'
    }
    public override requestBody() {
        return this.fontPackageVO
    }
}

/**
 * 删除企业字体包
 */

@DELETE
export class DeleteOrgFontPackage extends BaseCommonRequest<void> {
    constructor(private readonly resourceId: string, private readonly orgId: string) {
        super()
    }

    public override requestUrl() {
        return `fonts/org/package`
    }

    public override requestArgument(): { [key: string]: string } {
        return { resourceId: this.resourceId, orgId: this.orgId }
    }
}

/**
 * 添加团队字体包
 */

@POST
export class AddTeamFontPackage extends BaseCommonRequest<FontInfoVO[]> {
    constructor(private readonly fontPackageVO: FontPackageVO) {
        super()
    }

    public override requestUrl() {
        return `fonts/team/package`
    }

    public override requestBody() {
        return this.fontPackageVO
    }
}

/**
 * 删除团队字体包
 */
@DELETE
export class DeleteTeamFontPackage extends BaseCommonRequest<void> {
    constructor(private readonly resourceId: string, private readonly teamId: string) {
        super()
    }

    public override requestUrl() {
        return `fonts/team/package`
    }

    public override requestArgument(): { [key: string]: string } {
        return { resourceId: this.resourceId, teamId: this.teamId }
    }
}

/**
 * 替换团队字体包
 */
@POST
export class ReplaceTeamFontPackage extends BaseCommonRequest<FontInfoVO[]> {
    constructor(private readonly fontPackageVO: FontPackageReplaceVO) {
        super()
    }

    public override requestUrl() {
        return `fonts/team/package/replace`
    }

    public override requestBody() {
        return this.fontPackageVO
    }
}

/**
 * 替换企业字体包
 */
@POST
export class ReplaceOrgFontPackage extends BaseCommonRequest<FontInfoVO[]> {
    constructor(private readonly fontPackageReplaceVO: FontPackageReplaceVO) {
        super()
    }

    public override requestUrl() {
        return `fonts/org/package/replace`
    }

    public override requestBody() {
        return this.fontPackageReplaceVO
    }
}
