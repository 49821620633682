import { translation } from './effect-item.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import * as React from 'react'
import { useCallback } from 'react'
import {
    DraggablePopupV2,
    IconDelete16,
    IconEffect,
    IconEyes,
    IconEyesClose,
    Select,
    Tooltip,
} from '../../../../../../ui-lib/src'
import { Effect, EffectType } from '../../../../document/node/node'
import { usePosition } from '../../../utils/use-position'
import { IconButton } from '../../atom/button/icon-button'
import { SingleGrid } from '../../atom/grid/single-grid'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { BlendModeSelect } from '../blend-mode/blend-mode-select'
import { ColorSpace } from '../blend/color-picker/utils/color-translate'
import { EffectEditV2 } from './effect-edit-v2'
import style from './effect-item.module.less'

const EffectTypeLabel = new Map<EffectType, string>([
    [EffectType.InnerShadow, translation('InnerShadow')],
    [EffectType.DropShadow, translation('DropShadow')],
    [EffectType.LayerBlur, translation('LayerBlur')],
    [EffectType.BackgroundBlur, translation('BackgroundBlur')],
])

interface EffectItemProps {
    data: Effect
    colorSpace: ColorSpace
    selected?: boolean
    isOpen?: boolean
    hasBackgroundBlur?: boolean
    hasLayerBlur?: boolean
    enableSpread?: boolean
    onChangeData?: (obj: { [key: string]: any }, options?: InputOptionsForUndoSquash) => void
    onChangeVisible?: (visible: boolean) => void
    onClickSub?: () => void
    onChangeModalVisible?: (visible: boolean) => void
    onChangeBlendMode?: (blendMode: Wukong.DocumentProto.BlendMode) => void
    isMini?: boolean
    disabled?: boolean
    variables?: Wukong.DocumentProto.ISingleVariable[]
    floatVariables?: Wukong.DocumentProto.ISingleFloatVariable[]
    onDetachColorVar?: () => void
}
function EffectItemInternal(props: EffectItemProps, outRef: React.Ref<HTMLDivElement>) {
    const {
        data,
        colorSpace,
        selected,
        isOpen,
        hasBackgroundBlur,
        hasLayerBlur,
        enableSpread,
        onChangeData,
        onChangeVisible,
        onChangeBlendMode,
        onClickSub,
        onChangeModalVisible,
        onDetachColorVar,
        isMini,
        disabled,
        variables,
        floatVariables,
    } = props
    const { position, reactRef } = usePosition({ isOpen, outRef })

    const titleName = EffectTypeLabel.get(data.type)
    const onClose = useCallback(() => {
        onChangeModalVisible?.(false)
    }, [onChangeModalVisible])

    const onClickOpenModal = useCallback(() => {
        if (disabled) return

        onChangeModalVisible?.(!isOpen)
    }, [disabled, isOpen, onChangeModalVisible])

    const onChangeType = useCallback(
        (type: EffectType) => {
            onChangeData?.({ type })
        },
        [onChangeData]
    )

    const onChangeMoreEdit = useCallback(
        (value: { [key: string]: any }, options?: InputOptionsForUndoSquash) => {
            onChangeData?.(value, options)
        },
        [onChangeData]
    )

    const _onChangeVisible = useCallback(() => {
        if (disabled) return

        onChangeModalVisible?.(false)
        onChangeVisible?.(!data.visible)
    }, [disabled, onChangeModalVisible, onChangeVisible, data.visible])

    const disableSelectOption = useCallback(
        (type: EffectType, selectedType: EffectType) => {
            const existBackgroundBlur = type === EffectType.BackgroundBlur && hasBackgroundBlur && type !== selectedType
            const existLayerBlur = type === EffectType.LayerBlur && hasLayerBlur && type !== selectedType
            return existBackgroundBlur || existLayerBlur
        },
        [hasBackgroundBlur, hasLayerBlur]
    )

    const onClickDelete = useCallback(() => {
        if (disabled) return

        onClickSub?.()
    }, [disabled, onClickSub])

    return (
        <div ref={reactRef}>
            <SingleGrid
                className={classnames(style.eventPenetrate, selected ? style.selected : '', style.v2)}
                style={isMini ? { gridTemplateColumns: 'repeat(60, 4px)' } : undefined}
            >
                <SingleGrid.Item start={5} span={isMini ? 44 : 46} data-simple-drag-ignore>
                    <SingleGrid
                        className={style.backgroundGrid}
                        style={isMini ? { gridTemplateColumns: `repeat(44, 4px)` } : undefined}
                    >
                        <SingleGrid.Item start={1} span={7}>
                            <Tooltip title={translation('EffectSettings')}>
                                <IconButton
                                    icon={<IconEffect />}
                                    selected={false}
                                    disabled={disabled}
                                    onClick={onClickOpenModal}
                                    className={classnames(style.effectIcon, {
                                        [style.closeEyeStyle]: !data.visible,
                                    })}
                                    data-testid={'effect-modal-button'}
                                />
                            </Tooltip>
                        </SingleGrid.Item>
                        <SingleGrid.Item start={8} span={isMini ? 30 : 32}>
                            <Select.NormalSingleLevel
                                value={data.type}
                                hoverMoveToRight
                                disabled={disabled}
                                isDisabledStyle={!data.visible}
                                label={EffectTypeLabel.get(data.type)}
                                onChange={onChangeType}
                                onClick={onClose}
                                dataTestIds={{
                                    triggerContainer: 'effect-type-selection',
                                    triggerFocus: 'effect-type-trigger-focus',
                                }}
                                minWidth={124}
                            >
                                {[...EffectTypeLabel.entries()].map((value) => {
                                    const type = value[0]
                                    return (
                                        <Select.NormalSingleLevel.Option
                                            value={type}
                                            key={type}
                                            backwardIcon={''}
                                            disabled={disableSelectOption(type, data.type)}
                                            data-testid={`effect-type-selection-${type}`}
                                        >
                                            {EffectTypeLabel.get(type)}
                                        </Select.NormalSingleLevel.Option>
                                    )
                                })}
                            </Select.NormalSingleLevel>
                        </SingleGrid.Item>
                        <SingleGrid.Item start={isMini ? 38 : 40} span={7}>
                            <IconButton
                                icon={data.visible ? <IconEyes /> : <IconEyesClose />}
                                disabled={disabled}
                                selected={false}
                                onChange={_onChangeVisible}
                                className={style.eyeIcon}
                                dataTestId="effect-item-visible-button"
                            />
                        </SingleGrid.Item>
                    </SingleGrid>
                </SingleGrid.Item>
                <SingleGrid.Item start={isMini ? 49 : 51} span={12} horizontalCenter>
                    <IconButton
                        icon={<IconDelete16 />}
                        disabled={disabled}
                        selected={false}
                        onClick={onClickDelete}
                        data-simple-drag-ignore
                        dataTestId="effect-item-delete-button"
                    />
                </SingleGrid.Item>
            </SingleGrid>
            {isOpen ? (
                <DraggablePopupV2
                    visible={isOpen}
                    position={position}
                    onCancel={onClose}
                    styleType="editor"
                    footer={null}
                    bodyClassName="p-0"
                    positionRightBase
                    header={
                        <div className="py-1.5 pl-4 pr-9.5 flex items-center wk-text-12 wk-font-semibold">
                            {titleName}
                            {(data.type === EffectType.DropShadow || data.type === EffectType.InnerShadow) && (
                                <div className={style.blendMode2}>
                                    <BlendModeSelect
                                        value={data?.blendMode ?? undefined}
                                        onChange={onChangeBlendMode}
                                        onlyIcon
                                        dataTestIds={{ select: 'effect-blend-mode-select' }}
                                    ></BlendModeSelect>
                                </div>
                            )}
                        </div>
                    }
                >
                    <EffectEditV2
                        data={data as unknown as Wukong.DocumentProto.IEffect}
                        variables={variables}
                        floatVariables={floatVariables}
                        colorSpace={colorSpace}
                        enableSpread={enableSpread}
                        onChangeEffect={onChangeMoreEdit}
                        onDetachColorVar={onDetachColorVar}
                    />
                </DraggablePopupV2>
            ) : null}
        </div>
    )
}

export const EffectItem = React.forwardRef(EffectItemInternal)
