import { translation } from './comp-candidate.translation'
/* eslint-disable no-restricted-imports */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Rect, RenderCandidateNode } from './type'
import { PreviewManager } from './utils'

import { isEnglishLanguage } from '../../../../../../util/src'
import { CopilotCommandType, useAiMagicCommands } from './commands'
import { logs } from './logs'

import { debounce } from 'lodash-es'
import type { AIMagicState } from '.'
import { ScrollView } from '../../../../../../ui-lib/src'
import { WKLoading } from '../../../../../../ui-lib/src/components/wk-loading/wk-loading'
import { LocalStorageKey } from '../../../../web-storage/local-storage/config'
import { useLocalStorageState } from '../../../../web-storage/local-storage/hooks'
import { AIMagicIds } from './ai-magic-ids'
import { MagicIcon } from './icons'
import styles from './index.module.less'
import { useHoveringSwitch } from './use-hovering-switch'

const AllCateName = 'All'

const CategoryMapper: Record<string, string> = {
    ALL: AllCateName,
    BUTTON: 'Button',
    BADGE: 'Badge',
    LIST: 'List',
    TAG: 'Tag',
    INPUT: 'Input',
    CHECKBOX: 'Checkbox',
    SWITCH: 'Switch',
    CARD: 'Card',
    TOAST: 'Toast',
    TABS: 'Tabs',
    'NAVIGATION BAR': 'Navigation Bar',
    'TAB BAR': 'TabBar',
    PICKER: 'Select',
    AVATAR: 'Avatar',
    DIALOG: 'Dialog',
    OTHERS: 'Others',
}
function getCandidateItemSize(candidates: RenderCandidateNode[]) {
    let allIsSmall = true
    let allIsLarge = true
    let allIsLong = true
    let allIsNarrow = true

    for (const c of candidates) {
        allIsSmall &&= c.width < 69 && c.height < 69
        allIsLarge &&= c.width > 370 && c.height > 370
        allIsLong &&= c.width > c.height * 3
        allIsNarrow &&= c.height > c.width * 2
    }

    if (allIsSmall) {
        // 全都很小
        return {
            type: 'small',
            width: 85,
            height: 85,
        }
    } else if (allIsLarge) {
        // 全都很大
        return {
            type: 'large',
            width: 182,
            height: 182,
        }
    } else if (allIsLong) {
        // 全都很长
        return {
            type: 'long',
            width: 182,
            height: 60,
        }
    } else if (allIsNarrow) {
        // 全都很窄
        return {
            type: 'narrow',
            width: 85,
            height: 170,
        }
    }

    return {
        // 九宫格
        type: 'random',
        width: 118,
        height: 118,
    }
}

export interface AIMagicCompProps {
    magicState: AIMagicState
}

/**
 * 组件文本{translation('Input')}与候选区域的状态
 */
function useCompCandidateState(props: AIMagicCompProps) {
    const visible = props.magicState.magicMode === 'comp'
    const [compInput, _setCompInput] = useState('')
    const [showCompInput, setShowCompInput] = useState(false)

    function setCompInput(input: string) {
        _setCompInput(input)

        // 魔法框{translation('yaesoL')}输入
        logs.AiMagicBound.componentInputName()
    }

    // 组件类型{translation('Input')}
    const compInputRef = useRef<HTMLInputElement>(null)

    // 用户文本{translation('Input')}
    const textInputRef = useRef<HTMLInputElement>(null)

    // 绘制魔法框后自动聚焦到{translation('Input')}
    useEffect(() => {
        if (props.magicState.showCandidate && visible) {
            textInputRef.current?.focus()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.magicState.showCandidate, visible])

    function onCompKeydown(event: any) {
        const e = event as KeyboardEvent
        const dom = compInputRef.current!
        if (e.key === 'Backspace' || e.key === 'Delete') {
            // 如果在组件{translation('Input')}最左侧{translation('NVlkts')}删除{translation('nqfHeL')}
            if (dom.selectionStart === 0 && dom.selectionStart === dom.selectionEnd) {
                if (compInput.length > 0) {
                    // 如果{translation('Input')}中有内容，则全选内容
                    dom.setSelectionRange(0, compInput.length)
                } else {
                    // 如果{translation('Input')}中没有内容，则删除组件{translation('Input')}
                    setShowCompInput(false)
                    // 并 focus 到文本{translation('Input')}
                    textInputRef.current?.focus()
                }
                e.preventDefault()
            }
        } else if (e.key === 'ArrowRight') {
            // 在组件{translation('Input')}最右侧，{translation('NVlkts')}右{translation('nqfHeL')}，跳转到文字{translation('Input')}
            if (dom.selectionEnd === dom.selectionStart && dom.selectionStart === compInput.length) {
                textInputRef.current?.focus()
                e.preventDefault()
            }
        }
    }

    const [textInput, _setTextInput] = useState(props.magicState.textInput)
    function setTextInput(input: string) {
        _setTextInput(input)
        props.magicState.setTextInput(input)

        // 魔法框文本输入
        logs.AiMagicBound.componentInputText()
    }
    const [compTriggerCount, setCompTriggerCount] = useLocalStorageState(LocalStorageKey.CompTriggerCount, 0)
    const showTextPlaceHolder = textInput.length === 0
    const showComptShortcutTip = compTriggerCount < 3

    function setMaxTextInputWidth() {
        // 设置文本{translation('Input')}的最大宽度
        const dom = textInputRef.current
        if (!dom) return
        dom.style.maxWidth = `${dom.parentElement?.clientWidth ?? 200}px`
    }

    function onTextInput(input: string) {
        setTextInput(input)
        setMaxTextInputWidth()
    }

    function onTextKeydown(event: any) {
        const e = event as KeyboardEvent
        const dom = textInputRef.current!
        if (e.key === '/' && !showCompInput) {
            // {translation('NVlkts')} [ {translation('nqfHeL')}，展示组件{translation('Input')}，并自动 focus 到{translation('Input')}
            setShowCompInput(true)
            e.preventDefault()

            // 当用户触发次数超过三次之后，就不会再提示快捷{translation('nqfHeL')}
            if (compTriggerCount < 3) {
                setCompTriggerCount(compTriggerCount + 1)
            }
        } else if (e.key === 'ArrowLeft') {
            // 在文字{translation('Input')}最左侧，{translation('NVlkts')}左{translation('nqfHeL')}，跳转到组件{translation('Input')}
            if (dom.selectionEnd === dom.selectionStart && dom.selectionStart === 0) {
                compInputRef.current?.focus()
                e.preventDefault()
            }
        }
    }

    // 候选{translation('List')}
    const [loading, setLoading] = useState(true)

    // 没有在手机屏内画框
    const [notMobileScreen, setNotMobileScreen] = useState(false)

    // 获取分类
    const [allCandidates, setAllCandidates] = useState<RenderCandidateNode[]>([])
    const categories = useMemo(() => {
        const uniqueCates = Array.from(new Set(allCandidates.map((c) => c.category))).slice(0, 3)
        // 确保 others 在最后
        const othersCate = uniqueCates.filter((c) => c.toUpperCase() === 'OTHERS')
        const anyCates = uniqueCates.filter((c) => c.toUpperCase() !== 'OTHERS')
        const sortedCates = anyCates.concat(othersCate)
        return [AllCateName, ...sortedCates]
    }, [allCandidates])
    const showCategories = categories.length > 2 && !loading && !notMobileScreen
    const showCandidateList = true

    // 获取判断是否选中分类
    const [selectedCategories, setSelectedCategories] = useState(new Set<string>([AllCateName]))
    const candidates = allCandidates.filter((c) => {
        if (selectedCategories.has(AllCateName)) {
            return true
        } else {
            return selectedCategories.has(c.category)
        }
    })

    // 空状态与候选项大小
    const noCandidates = candidates.length === 0
    const candidateSize = useMemo(() => getCandidateItemSize(candidates), [candidates])

    // 用于预览候选组件
    const commands = useAiMagicCommands()
    const insertInfo = useRef({
        parentNodeId: '',
        mobileScreenNodeId: '',
    })
    const lastPreviewNodeId = useRef('')
    const encodedNodeBlobInfo = useRef('')

    const previewImages = async (
        res: Awaited<ReturnType<CopilotCommandType['requestAiCopilot']>>,
        isCancelled: () => boolean,
        worldRect = props.magicState.worldRect
    ) => {
        if (!res.candidates || isCancelled()) {
            return
        }
        const imageResults = await commands.getImageOfFigNodes(res.candidates, res.nodeBlobInfo!, worldRect)
        if (!imageResults) return

        // 获取文本节点的准确偏移位置，从结果中过滤出文本类型的候选节点
        const textNodePositions = await commands.getTextPosition({
            traceId: res.traceId,
            positionQueryList: res.candidates
                .filter((c) => c.category === 'text')
                .map((c, i) => ({
                    componentData: c.componentData,
                    category: c.category,
                    boundingBox: imageResults.images![i].textBounds as Rect,
                    extendInfos: c.extendInfos,
                })),
        })

        res.candidates.forEach((c) => {
            const result = textNodePositions.get(c.componentData)
            if (!result) return
            c.position = result.position
        })

        const nextCandidates = res.candidates.map((node, i) => ({
            width: imageResults.images![i].width!,
            height: imageResults.images![i].height!,
            image: commands.getImageUrlFromBase64(imageResults.images![i].image!),
            ...node,
        }))
        setAllCandidates(nextCandidates)
    }

    // 当用户有交互时，禁用预览图更新操作，防止卡顿
    useEffect(() => {
        const onMouseMove = () => {
            PreviewManager.disablePreview()
        }

        window.addEventListener('mousemove', onMouseMove)

        const onWindowBlur = () => {
            // 窗口失焦时，不更新预览图
            PreviewManager.disabled = true
        }
        const onWindowFocus = () => {
            // 窗口恢复焦点
            PreviewManager.disabled = false
        }

        window.addEventListener('blur', onWindowBlur)
        window.addEventListener('focus', onWindowFocus)

        return () => {
            window.removeEventListener('mousemove', onMouseMove)
            window.removeEventListener('blur', onWindowBlur)
            window.removeEventListener('focus', onWindowFocus)
        }
    }, [])

    const latestRequestId = useRef(0)
    const cancelRequestAiCopilot = useRef(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const requestAiCopilot = useCallback(
        debounce(
            (rect: Rect, _textInput: string, _compInput: string) => {
                cancelRequestAiCopilot.current = false
                setNotMobileScreen(false)
                setAllCandidates([])
                const requestId = (latestRequestId.current += 1)
                const isValidRequest = () => requestId === latestRequestId.current

                commands
                    .requestAiCopilot(rect, _textInput, _compInput)
                    .then(async (res) => {
                        if (cancelRequestAiCopilot.current || !isValidRequest()) {
                            return
                        }

                        if (res.noMobileScreenData) {
                            setNotMobileScreen(true)
                            return
                        }

                        if (!res?.candidates?.length) {
                            setAllCandidates([])
                            // 组件魔法框无候选
                            logs.AiMagicBound.componentNoCandidate()
                            return
                        }

                        // 暂存信息
                        insertInfo.current.mobileScreenNodeId = res.info.mobileScreenNodeId ?? ''
                        insertInfo.current.parentNodeId = res.info.parentId ?? ''
                        encodedNodeBlobInfo.current = JSON.stringify(res.nodeBlobInfo)

                        const previewTask = async () =>
                            await previewImages(res, () => {
                                return cancelRequestAiCopilot.current
                            })
                        await previewTask()
                        PreviewManager.addPreview(previewTask)
                    })
                    .catch(() => {
                        if (isValidRequest()) {
                            setAllCandidates([])
                        }
                    })
                    .finally(() => {
                        if (isValidRequest()) {
                            setLoading(false)
                        }
                    })

                return {
                    cancel() {
                        if (isValidRequest()) {
                            setLoading(false)
                        }
                    },
                }
            },
            300,
            {
                trailing: true,
            }
        ),
        []
    )

    // 请求 ai copilot 服务
    useEffect(() => {
        PreviewManager.clearPreview()
        if (!props.magicState.showCandidate || !visible) {
            PreviewManager.clearPreview()
            return
        }
        setLoading(true)
        const handler = requestAiCopilot(props.magicState.worldRect, textInput, compInput)

        return () => {
            cancelRequestAiCopilot.current = true
            handler?.cancel()
            setAllCandidates([])
            PreviewManager.clearPreview()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, compInput, textInput, ...Object.values(props.magicState.worldRect), props.magicState.showCandidate])

    const previewItem = (candidate: RenderCandidateNode) => {
        const previewNodeId = commands.applyAiCopilotResultTo(
            insertInfo.current.parentNodeId,
            insertInfo.current.mobileScreenNodeId,
            candidate,
            encodedNodeBlobInfo.current,
            true,
            props.magicState.worldRect
        )
        lastPreviewNodeId.current = previewNodeId.nodeId ?? ''
    }

    const unpreview = () => {
        if (lastPreviewNodeId.current) {
            commands.removeNode(lastPreviewNodeId.current)
        }
    }

    const applyItem = (candidate: RenderCandidateNode) => {
        unpreview()
        commands.exitCopilot()
        setTimeout(() => {
            commands.applyAiCopilotResultTo(
                insertInfo.current.parentNodeId,
                insertInfo.current.mobileScreenNodeId,
                candidate,
                encodedNodeBlobInfo.current,
                false,
                props.magicState.worldRect
            )
            commands.decreaseLabCredits()
        }, 1)
    }

    const onClickCategory = useCallback(
        function (cate: string) {
            const newSelected = new Set(selectedCategories)
            if (!newSelected.has(cate)) {
                newSelected.clear()
                newSelected.add(cate)
            }
            setSelectedCategories(newSelected)
        },
        [selectedCategories]
    )

    return {
        visible,

        // 组件{translation('Input')}
        compInputRef,
        showCompInput,
        compInput,
        onCompInput: setCompInput,
        onCompKeydown,

        // 文字{translation('Input')}
        textInputRef,
        textInput,
        onTextInput,
        showTextPlaceHolder,
        onTextKeydown,
        showComptShortcutTip,

        // 候选{translation('List')}
        loading,
        notMobileScreen,
        showCandidateList,
        categories,
        showCategories,
        selectedCategories,
        onClickCategory,

        candidates,
        noCandidates,
        candidateSize,

        previewItem,
        applyItem,
        unpreview,
    }
}

export function AIMagicCompCandidate(props: AIMagicCompProps) {
    const candidateState = useCompCandidateState(props)
    const hoveringSwitch = useHoveringSwitch({ setShowSwitch: props.magicState.setShowSwitch })

    return (
        <>
            <div className={styles['prompt-input']} data-testid={AIMagicIds.prompt.comp.main}>
                <div className={styles['prompt-input-icon']}>
                    <MagicIcon />
                    <div
                        className={styles['icon-hotarea']}
                        onMouseOver={hoveringSwitch.onMouseInSwitchArea}
                        onMouseOut={hoveringSwitch.onMouseOutSwitchArea}
                        data-testid={AIMagicIds.prompt.comp.icon}
                    ></div>
                </div>
                {candidateState.showCompInput && (
                    <input
                        data-testid={AIMagicIds.prompt.comp.compInput}
                        className={styles['prompt-input-component']}
                        placeholder={translation('yaesoL')}
                        ref={candidateState.compInputRef}
                        onInput={(e) => candidateState.onCompInput(e.currentTarget.value)}
                        onKeyDown={candidateState.onCompKeydown}
                        value={candidateState.compInput}
                        autoFocus
                    />
                )}
                <div
                    className={`${styles['prompt-input-text']} ${
                        candidateState.showComptShortcutTip && styles['prompt-input-animated-text']
                    }`}
                >
                    <input
                        ref={candidateState.textInputRef}
                        onInput={(e) => candidateState.onTextInput(e.currentTarget.value)}
                        onKeyDown={candidateState.onTextKeydown}
                        value={candidateState.textInput}
                        data-testid={AIMagicIds.prompt.comp.textInput}
                    />
                    {candidateState.showTextPlaceHolder && (
                        <>
                            <span className={styles.placeholder}>{translation('OZXHpR')}</span>
                            {candidateState.showComptShortcutTip && (
                                <span className={styles.placeholder}>{translation('ShortCut')}</span>
                            )}
                        </>
                    )}
                </div>
            </div>
            {candidateState.showCandidateList && (
                <>
                    <div className={styles['prompt-candidate']} data-testid={AIMagicIds.prompt.comp.candidate.main}>
                        {candidateState.showCategories && (
                            <div
                                className={`${styles['prompt-candidate-categories']}`}
                                data-testid={AIMagicIds.prompt.comp.candidate.category}
                            >
                                {candidateState.categories.map((c, i) => {
                                    const isSelected = candidateState.selectedCategories.has(c)
                                    const upperCate = c.trim().toUpperCase()
                                    const predefinedCategory = CategoryMapper[upperCate]

                                    let cateName = predefinedCategory ? translation(predefinedCategory as any) : c
                                    if (!isEnglishLanguage() && c !== AllCateName) {
                                        cateName += ` ${predefinedCategory}`
                                    }

                                    return (
                                        <div
                                            key={i}
                                            className={
                                                styles['prompt-candidate-category'] +
                                                ` ${isSelected && styles['prompt-candidate-category-selected']}`
                                            }
                                            data-testid={AIMagicIds.prompt.comp.candidate.cateItem(i)}
                                            onClick={() => candidateState.onClickCategory(c)}
                                        >
                                            {cateName}
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                        <ScrollView
                            scrollbar={{
                                autoHeight: true,
                                autoHeightMax: 420,
                            }}
                        >
                            {(candidateState.noCandidates ||
                                candidateState.notMobileScreen ||
                                candidateState.loading) && (
                                <div
                                    className={styles['prompt-candidate-empty']}
                                    data-testid={AIMagicIds.prompt.comp.candidate.empty}
                                >
                                    {candidateState.loading ? (
                                        <WKLoading noText />
                                    ) : candidateState.notMobileScreen ? (
                                        <div>{translation('FRVSnh')}</div>
                                    ) : candidateState.noCandidates ? (
                                        <div>
                                            <div>{translation('tguavM')}</div>
                                            <div>{translation('xhqXyh')}</div>
                                        </div>
                                    ) : null}
                                </div>
                            )}
                            {!(candidateState.noCandidates || candidateState.loading) && (
                                <div
                                    className={styles['prompt-candidate-list']}
                                    style={{
                                        gridTemplateColumns: `repeat(auto-fill, ${candidateState.candidateSize.width}px)`,
                                    }}
                                    data-testid={AIMagicIds.prompt.comp.candidate.list}
                                >
                                    {candidateState.candidates.map((c, i) => {
                                        return (
                                            <div
                                                className={styles['prompt-candidate-item']}
                                                key={i}
                                                data-testid={AIMagicIds.prompt.comp.candidate.item(i)}
                                                onClick={() => {
                                                    candidateState.applyItem(c)
                                                    // 点击组件魔法框候选
                                                    logs.AiMagicBound.componentSelectSuccess({ rank: i })
                                                }}
                                                onMouseEnter={() => candidateState.previewItem(c)}
                                                onMouseLeave={() => candidateState.unpreview()}
                                                style={{
                                                    width: candidateState.candidateSize.width,
                                                    height: candidateState.candidateSize.height,
                                                }}
                                            >
                                                <img
                                                    src={c.image}
                                                    style={{
                                                        maxWidth: c.width,
                                                        maxHeight: c.height,
                                                    }}
                                                ></img>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </ScrollView>
                    </div>
                </>
            )}
        </>
    )
}
