import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Created: '创建于',
    Edited: '修改于',
} as const

export const enTranslation = {
    Created: 'Created',
    Edited: 'Edited',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
