/* eslint-disable no-restricted-imports */

import { domLocation, RouteToken } from '../../../../../util/src'
import { TeamInOrgVO, UserBriefVO } from '../../../kernel/interface/type'
import { GetWorkspaceAdmins, GetWorkspaceDetail } from '../../../kernel/request/workspace'
import { SpaceNotifyFlow } from './notify-flow'
import { getSpaceStore, setSpaceStore, SpaceStoreType, WorkspaceDetailStore } from './types'

export const generateWorkspaceDetailState = (set: setSpaceStore, get: getSpaceStore): WorkspaceDetailStore => {
    return {
        teams: [],
        admins: [],
        loading: true,
        initData: async (workspaceId: string) => {
            set((state) => {
                state.workspaceDetailStore.workspaceId = workspaceId
                state.workspaceDetailStore.loading = true
            })
            await get().workspaceDetailStore.refresh()
            set((state) => {
                state.workspaceDetailStore.loading = false
            })
        },
        refresh: async () => {
            const workspaceId = get().workspaceDetailStore.workspaceId
            if (workspaceId) {
                const isGuest = get().organizationStore.organization.guest

                if (isGuest) {
                    const detail = await new GetWorkspaceDetail(workspaceId).start().catch(() => ({
                        workspace: undefined,
                        teams: [],
                    }))
                    set((state) => {
                        state.workspaceDetailStore.workspace = detail.workspace
                        state.workspaceDetailStore.teams = detail.teams
                    })
                } else {
                    const [detail, admins] = await Promise.all([
                        new GetWorkspaceDetail(workspaceId).start(),
                        new GetWorkspaceAdmins(workspaceId).start(),
                    ]).catch(() => {
                        return [
                            {
                                workspace: undefined,
                                teams: [],
                            },
                            [],
                        ] as [
                            {
                                workspace: undefined
                                teams: TeamInOrgVO[]
                            },
                            UserBriefVO[]
                        ]
                    })
                    set((state) => {
                        state.workspaceDetailStore.workspace = detail.workspace
                        state.workspaceDetailStore.teams = detail.teams
                        state.workspaceDetailStore.admins = admins
                    })
                }
            }
        },
        clear: () => {
            set((state) => {
                state.workspaceDetailStore.loading = true
                state.workspaceDetailStore.workspaceId = undefined
                state.workspaceDetailStore.workspace = undefined
                state.workspaceDetailStore.teams = []
                state.workspaceDetailStore.admins = []
            })
        },
    }
}

const workspaceDetailPageRegex = new RegExp(`/${RouteToken.Workspace}/([^/]+)$`)
const inWorkspaceDetailPage = () => domLocation().pathname.match(workspaceDetailPageRegex)
export const syncWorkspaceDetailState = (store: SpaceStoreType, notifyFlow: SpaceNotifyFlow) => {
    notifyFlow.addRelationChangeCallbackWithoutAccess(() => {
        if (inWorkspaceDetailPage()) {
            store.getState().workspaceDetailStore.refresh()
        }
    })
    notifyFlow.addPropertyChangeCallbackWithoutDocAndUser(() => {
        if (inWorkspaceDetailPage()) {
            store.getState().workspaceDetailStore.refresh()
        }
    })
    notifyFlow.addCreateWorkspaceCallback(() => {
        if (inWorkspaceDetailPage()) {
            store.getState().workspaceDetailStore.refresh()
        }
    })
    notifyFlow.addRemoveWorkspaceCallback(() => {
        if (inWorkspaceDetailPage()) {
            store.getState().workspaceDetailStore.refresh()
        }
    })
    notifyFlow.addUpdateWorkspaceCallback(() => {
        if (inWorkspaceDetailPage()) {
            store.getState().workspaceDetailStore.refresh()
        }
    })
}
