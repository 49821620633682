import { isWindows } from '../../../../kernel/util/ua'

export const fileSep = () => (isWindows() ? '\\' : '/')

export function isPluginDevelopmentEnabled() {
    return (
        window.localBridge?.updatePluginMenu &&
        window.localBridge?.readLocalFile &&
        window.localBridge?.bindClickPluginMenuItem &&
        window.localBridge?.bindRequestFromWindow &&
        window.localBridge?.registerLocalFileChange &&
        window.localBridge?.unregisterLocalFileChange &&
        window.localBridge?.openLocalFileFolder
    )
}
