import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    PluginOwner: '所有者',
    PluginEditor: '可更新',
} as const

export const enTranslation = {
    PluginOwner: 'Owner',
    PluginEditor: 'Can update',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
