/* eslint-disable no-restricted-imports */
import { PreExportFinish } from '@wukong/bridge-proto'
import { Bridge } from '../../kernel/bridge/bridge'
// 导出前对是否下载了原始图片的检查

export class PreExportService {
    private exportFn: null | (() => void) = () => {}
    constructor(private bridge: Bridge) {
        this.bridge.bind(PreExportFinish, () => {
            this.exportFn?.()
        })
    }

    public setFn(fn: () => void) {
        this.exportFn = fn
    }

    destroy() {
        this.bridge.unbind(PreExportFinish)
        this.exportFn = null
    }
}
