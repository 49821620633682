/* eslint-disable no-restricted-imports */
import { WKToast } from '../../../../../../ui-lib/src'
import { getCommitId } from '../../../../../../util/src'
import { disableSentry, tryInitSentry } from '../../../../kernel/sentry'
import { featureSwitchManager } from '../../../../kernel/switch/core'
import { WK } from '../../../../window'
import { showLifecycleTracePanel } from '../../trace/lifecycle-trace-panel'
import {
    AppState,
    AppStateNeedsAsyncResource,
    CallbackInjector,
    LifecycleTraceInfo,
    StateCallbackInjector,
    appStateNeedsAsyncResource,
} from '../../types'
import { EditorContextUnion, MirrorContext } from '../../types/editor'

type CommonCreateAppCallbackInjector = <T extends EditorContextUnion>(
    injector: StateCallbackInjector<AppState.CreateApp, T>
) => void

type MirrorAndPreviewCreateAppCallbackInjector = CallbackInjector<AppState.CreateApp, MirrorContext>

export const injectTrace: CommonCreateAppCallbackInjector = (injector) => {
    injector('inject trace to WK', ({ getTraceInfo }) => {
        WK.showTracePanel = () => {
            showLifecycleTracePanel(getTraceInfo())
        }

        WK.getUnsolvedResource = () => {
            const traceInfo: LifecycleTraceInfo = getTraceInfo()
            const unsolvedResourceList: string[] = []
            if (appStateNeedsAsyncResource.includes(traceInfo.currentState as any)) {
                Object.entries(
                    traceInfo.appState2AsyncResourceComplete[
                        traceInfo.currentState as unknown as AppStateNeedsAsyncResource
                    ]
                ).forEach(([resource, complete]) => {
                    if (!complete) {
                        unsolvedResourceList.push(resource)
                    }
                })
            }
            return unsolvedResourceList.join(',')
        }

        return () => {
            delete WK.showTracePanel
            delete WK.getUnsolvedResource
        }
    })
}

export const injectFeatureSwitch: CommonCreateAppCallbackInjector = (injector) => {
    injector('init featureSwitch', (_) => {
        featureSwitchManager.init()

        return () => {
            featureSwitchManager.clearLocalSwitches()
        }
    })
}

export const injectGlobal: CommonCreateAppCallbackInjector = (injector) => {
    injector('inject global toast', () => {
        WKToast.injectGlobal()

        return () => {
            WKToast.ejectGlobal()
        }
    })
}

export const injectSentry: CommonCreateAppCallbackInjector = (injector) => {
    injector('enable sentry', () => {
        tryInitSentry()

        return () => {
            disableSentry()
        }
    })
}

export const injectCommitId: CommonCreateAppCallbackInjector = (injector) => {
    injector('add commit id to window', () => {
        WK.commitId = getCommitId() ?? ''

        return () => {
            delete WK.commitId
        }
    })
}

/**
 * mirror afterCreate callback
 */
export const injectMirrorAndPreviewAfterCreateCallback: MirrorAndPreviewCreateAppCallbackInjector = (injector) => {
    ;[
        injectTrace, //
        injectFeatureSwitch,
        injectGlobal,
        injectSentry,
        injectCommitId,
    ].forEach((inject) => inject(injector))
}
