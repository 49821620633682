/* eslint-disable no-restricted-imports */
import { IsFeatureEnabled } from '@wukong/bridge-proto'
import {
    checkUserInGroup,
    FeatureSwitch,
    FeatureSwitchConfigs,
    FeatureSwitchKeys,
    isSwitchKeyValid,
    SwitchEnvironmentScopeType,
    SwitchStrategy,
    SwitchStrategyType,
    UserGroupType,
} from '@wukong/feature-switch'
import sha1 from 'js-sha1'
import { getInitialDocSchemaVersion } from '../../../../../util/src/schema-version'
import { environment } from '../../../environment'
import { LocalStorageKey } from '../../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../../web-storage/local-storage/storage'
import { WK } from '../../../window'
import { Bridge } from '../../bridge/bridge'
import { debugLog, isDebugEnabled } from '../../debug'
import { featureSwitchMinClientVersion, fulledSwitchsForClient, isDisableInClient } from './client-feature-config'

export class FeatureSwitchManager {
    /**
     * 开关服务是否连接到 bridge
     */
    private bridge: Bridge | null = null

    private isMaintainer = false

    get connected(): boolean {
        return this.bridge !== null
    }

    // 来自 localStorage 的开关
    private localSwitches: Partial<Record<FeatureSwitch, boolean>> = {}
    // 来自开关配置的开关
    private switches: Partial<Record<FeatureSwitch, boolean>> = {}

    private randomLottery(userId: string, percent: number) {
        const hashCode = sha1(userId) as string
        const hashCodeLastChar = hashCode[hashCode.length - 1]
        const hashNum = parseInt(hashCodeLastChar, 16)
        return hashNum <= 16 * (percent / 100)
    }

    private handleStrategyCoverage(userId: string, switchName: string, strategy: SwitchStrategy) {
        if (!isSwitchKeyValid(switchName)) {
            return
        }
        if (
            strategy.env === SwitchEnvironmentScopeType.ALL ||
            (strategy.env === SwitchEnvironmentScopeType.TESTING && environment.featureSwitchEnv === 'testing') ||
            (strategy.env === SwitchEnvironmentScopeType.PRODUCTION && environment.featureSwitchEnv === 'production')
        ) {
            switch (strategy.config.type) {
                case SwitchStrategyType.ALL_USER:
                    this.switches[switchName] = true
                    break
                case SwitchStrategyType.SPECIFIC_SUFFIX:
                    if (strategy.config.suffix.some((suffix) => userId.endsWith(suffix))) {
                        this.switches[switchName] = true
                    }
                    break
                case SwitchStrategyType.SPECIFIC_USER_LIST:
                    if ('userList' in strategy.config) {
                        if (strategy.config.userList.includes(sha1(userId))) {
                            this.switches[switchName] = true
                        }
                    }
                    if ('group' in strategy.config) {
                        if (checkUserInGroup(userId, strategy.config.group)) {
                            this.switches[switchName] = true
                        }
                    }
                    break
                case SwitchStrategyType.PERCENTAGE_RANDOM:
                    if (this.randomLottery(userId, strategy.config.percent)) {
                        this.switches[switchName] = true
                    }
                    break
            }
        }
    }

    /**
     * 初始化开关，此阶段可以访问 localStorage 中存储的 key
     */
    public init(): void {
        debugLog('FeatureSwitch inited', featureSwitchManager.getSnapshot())

        WK.overrideFeatureSwitch = featureSwitchManager.override
        WK.getFeatureSwitches = () => featureSwitchManager.getSnapshot()
        WK.saveLocalEnabledFeatures = (...featureKeys: string[]) => {
            featureSwitchManager.saveLocalEnabledFeatures(featureKeys)
        }
        WK.saveLocalDisabledFeatures = (...featureKeys: string[]) => {
            featureSwitchManager.saveLocalDisabledFeatures(featureKeys)
        }
    }

    /**
     * @description 注入 user 信息，同时装填开关配置的开关状态
     * @param userId
     * @returns
     */
    public injectUserEmail(email: string): void {
        this.isMaintainer = [UserGroupType.PM, UserGroupType.RD, UserGroupType.MO, UserGroupType.QA].some((type) =>
            checkUserInGroup(email, type)
        )
        FeatureSwitchConfigs.forEach((featureSwitch) => {
            if (!isSwitchKeyValid(featureSwitch.name)) {
                return
            }
            featureSwitch.strategies.forEach((strategy) => {
                this.handleStrategyCoverage(email, featureSwitch.name, strategy)
            })
        })
        this.loadLocalEnabledFeatures()
        this.loadLocalDisabledFeatures()

        this.syncElectronSwitches()
    }

    public saveLocalEnabledFeatures(featureKeys: string[]) {
        if (featureKeys.length) {
            enhancedLocalStorage.setSerializedItem(LocalStorageKey.LocalEnableFeatures, featureKeys.join(','))
        } else {
            enhancedLocalStorage.removeItem(LocalStorageKey.LocalEnableFeatures)
        }
    }

    public saveLocalDisabledFeatures(featureKeys: string[]) {
        if (featureKeys.length) {
            enhancedLocalStorage.setSerializedItem(LocalStorageKey.LocalDisabledFeatures, featureKeys.join(','))
        } else {
            enhancedLocalStorage.removeItem(LocalStorageKey.LocalDisabledFeatures)
        }
    }

    private loadLocalEnabledFeatures() {
        const enabledFeatureKeys = enhancedLocalStorage.getSerializedItem(LocalStorageKey.LocalEnableFeatures)
        if (!enabledFeatureKeys) {
            return
        }
        console.warn(
            `!!! 注意本地强制打开了这些开关: ${enabledFeatureKeys} 。 本地测试完成之后记得用 localStorage.removeItem('LOCAL_ENABLED_FEATURES'); 删掉`
        )
        for (const part of enabledFeatureKeys.split(',')) {
            const key = part.trim()
            if (isSwitchKeyValid(key)) {
                this.localSwitches[key] = true
            } else {
                console.warn(`开关未声明: ${key}`)
            }
        }
        if (this.isEnabled('allow-debug-log')) {
            isDebugEnabled.value = true
        }
    }

    private loadLocalDisabledFeatures() {
        const disabledFeatureKeys = enhancedLocalStorage.getSerializedItem(LocalStorageKey.LocalDisabledFeatures)
        if (!disabledFeatureKeys) {
            return
        }
        console.warn(
            `!!! 注意本地强制关闭了这些开关: ${disabledFeatureKeys} 。 本地测试完成之后记得用 localStorage.removeItem('LOCAL_DISABLED_FEATURES'); 删掉`
        )
        for (const part of disabledFeatureKeys.split(',')) {
            const key = part.trim()
            if (isSwitchKeyValid(key)) {
                this.localSwitches[key] = false
            } else {
                console.warn(`开关未声明: ${key}`)
            }
        }
    }

    // 向客户端同步开关
    private syncElectronSwitches() {
        const snapshot = this.getSnapshot()

        for (const feature of fulledSwitchsForClient) {
            snapshot[feature as FeatureSwitch] = true
        }

        for (const feature in featureSwitchMinClientVersion) {
            if (feature in snapshot && isDisableInClient(feature as FeatureSwitch)) {
                // 当客户端小于某个版本时不支持
                snapshot[feature as FeatureSwitch] = false
            }
        }
        window.localBridge?.syncFeatures?.(snapshot)
    }

    // 清理客户端开关
    private clearElectronSwitches() {
        window.localBridge?.clearFeatures?.()
    }

    // 清理来自开关配置的开关
    public clearSwitches(): void {
        this.switches = {}
        this.clearElectronSwitches()

        debugLog('clear switches')
    }

    // 清理本地开关
    public clearLocalSwitches(): void {
        this.localSwitches = {}

        debugLog('clear local switches')
        delete WK.overrideFeatureSwitch
        delete WK.getFeatureSwitches
        delete WK.saveLocalEnabledFeatures
        delete WK.saveLocalDisabledFeatures
    }

    /**
     * @description 获取所有开关
     */
    public getSnapshot() {
        const snapshot: Partial<Record<FeatureSwitch, boolean>> = {}
        for (const feature of FeatureSwitchKeys) {
            snapshot[feature] = this.isEnabled(feature)
        }
        return { ...snapshot, ...this.localSwitches }
    }

    /**
     * @description 获取所有开关，用 map 存储
     */
    public toMap() {
        const map = new Map<FeatureSwitch, boolean>()
        for (const feature of FeatureSwitchKeys) {
            map.set(feature, featureSwitchManager.isEnabled(feature))
        }
        return map
    }

    /**
     * @description 获取开关
     * 除非是 debug 开关，否则必须在就绪后调用
     * @param featureSwitch
     * @returns
     */
    public isEnabled(featureSwitch: FeatureSwitch): boolean {
        if (featureSwitch in this.localSwitches) {
            return !!this.localSwitches[featureSwitch]
        }

        return !!this.switches[featureSwitch]
    }

    /**
     * @description 建立 wasm 连接
     * @param bridge
     */
    public connect(bridge: Bridge) {
        this.bridge = bridge
        this.bridge.bind(IsFeatureEnabled, (arg) => {
            const key = arg.value
            if (!isSwitchKeyValid(key)) {
                return { key, isEnabled: false }
            }
            return { key, isEnabled: this.isEnabled(key) }
        })
    }

    /**
     * @description 关闭开关服务
     * 断开 wasm 连接，不会清空开关数据销毁 unleash，数据和 unleash 在整个 app 中始终有效
     * @returns
     */
    public disconnect() {
        if (!this.bridge) {
            return
        }
        this.bridge.unbind(IsFeatureEnabled)
        this.bridge = null
    }

    /**
     * @description 覆盖某个开关数据
     * 建议只在测试中使用
     * @param featureSwitch
     * @param value
     */
    public override = (featureSwitch: FeatureSwitch, value: boolean) => {
        this.localSwitches[featureSwitch] = value
    }

    /**
     * 判断当前注册的用户是否是维护者
     * PM && RD && QA && MO
     * @returns
     */
    public isCurrentUserMaintainer = () => {
        return this.isMaintainer
    }

    public isContextEnabled = (featureSwitch: FeatureSwitch): boolean => {
        // 工作台用户新建/导入文档版本
        const initialDocSchemaVersion = getInitialDocSchemaVersion()
        // 编辑器文档版本
        const currentDocSchemaVersion = WK.schemaVersion()
        // NOTE: 用完记得及时删除
        if (window.location.pathname.includes('/files')) {
            return initialDocSchemaVersion >= 12
        } else {
            return currentDocSchemaVersion >= 12
        }
        return false
    }
}

export const featureSwitchManager = new FeatureSwitchManager()
