import { MotiffAutoSaveSchema } from '../../../web-storage/indexed-db/config/schema'
import { translation } from './auto-save-db-v2.translation'
import { wkDialogConfirm } from '../../../../../ui-lib/src'
import { domLocation } from '../../../../../util/src'
import { IDBPDatabase } from 'idb'
import { openEnhancedIndexedDB } from '../../../web-storage/indexed-db/storage'
import { IndexedDBName } from '../../../web-storage/indexed-db/config'
import { WkCLog } from '../../../kernel/clog/wukong/instance'

export const DB_NAME = 'motiff-autosave-v2'
export const OFFLINE_SESSIONS_STORE = 'offline-sessions'
export const OFFLINE_SESSIONS_INDEX = 'session-index'
export const NODE_CHANGES_STORE = 'node-changes'
export const REFERENCED_NODES_STORE = 'referenced-nodes'
export const BLOB_CHANGES_STORE = 'blob-changes'
const DB_VERSION = 2

export async function openAutoSavedDB(version = DB_VERSION): Promise<IDBPDatabase<MotiffAutoSaveSchema>> {
    const db = await openEnhancedIndexedDB({
        name: IndexedDBName.MotiffAutoSaveV2,
        version,
        callback: {
            upgrade: (database, oldVersion, newVersion) => {
                WkCLog.log(`openDB: ${DB_NAME}, upgrade version from ${oldVersion} to ${newVersion}`)
                if (!database.objectStoreNames.contains(OFFLINE_SESSIONS_STORE)) {
                    const offlineSessionStore = database.createObjectStore(OFFLINE_SESSIONS_STORE, {
                        keyPath: 'id',
                        autoIncrement: true,
                    })
                    offlineSessionStore.createIndex(OFFLINE_SESSIONS_INDEX, ['userId', 'docId', 'sid'], {
                        unique: true,
                    })
                }

                if (!database.objectStoreNames.contains(NODE_CHANGES_STORE)) {
                    database.createObjectStore(NODE_CHANGES_STORE, {
                        keyPath: ['offlineSessionId', 'nodeId'],
                    })
                }

                if (!database.objectStoreNames.contains(BLOB_CHANGES_STORE)) {
                    database.createObjectStore(BLOB_CHANGES_STORE, {
                        keyPath: ['offlineSessionId', 'blobId'],
                    })
                }

                if (!database.objectStoreNames.contains(REFERENCED_NODES_STORE)) {
                    database.createObjectStore(REFERENCED_NODES_STORE, {
                        keyPath: ['offlineSessionId', 'nodeId'],
                    })
                }
            },
            blocked: () => {
                WkCLog.log(`离线数据库被阻塞, 无法打开`)
                wkDialogConfirm.warning({
                    closable: false,
                    okButtonProps: {
                        hidden: true,
                    },
                    cancelButtonProps: {
                        hidden: true,
                    },
                    title: translation('LTBgtr'),
                })
            },
        },
    })

    db.onversionchange = (e) => {
        if (e.newVersion && e.newVersion > e.oldVersion) {
            db.close()
            domLocation().reload()
        }
    }

    return db
}

export async function openAutoSavedDbWithRetry() {
    let retryTimes = 0,
        error = null
    for (; retryTimes < 5; ) {
        try {
            return await openAutoSavedDB()
        } catch (e) {
            WkCLog.log(`Failed to open ${DB_NAME} , retryTimes=${retryTimes}, error=${e}`)
            error = e
        }
        retryTimes++
    }
    throw error
}
