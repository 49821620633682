/* eslint-disable no-restricted-imports */
import {
    EnterHistoryModeCommand,
    GetCurrentPageIdCommand,
    GetOriginWasmStatusCommand,
    HistoryModeReloadFailedResourcesCommand,
    LeaveHistoryModeWasmCall,
    LoadFontInJsCommand,
    OnAnimationFrame,
    OnBatchOperationsUnderHistoryModeCommand,
    OnBridgeStatusChange,
    OnDocIdChange,
    OnDocumentCommand,
    OnSessionIdChange,
    OnUserIdChange,
    OnUserRoleChange,
    ResetGpuStateCommand,
    ResetSelectionAndHistoryVersionIdCommand,
    ResetWasmStatusCommand,
    SetCurrentPageWasmCall,
    SetOperateSystemWasmCall,
    SetReleaseVersion,
    Wukong,
    ZoomToAllCommandForJs,
} from '@wukong/bridge-proto'
import { useGet, useSet } from 'ccstate-react'
import { getCommitId } from '../../../../util/src'
import { useUserInfoContext } from '../../auth'
import { DO_NOT_USE_THIS_cmdSetRenderViewport } from '../../document/command/canvas-resize-command'
import { CommitType } from '../../document/command/commit-type'
import { WebSocketStatus } from '../../document/synergy/web-socket-bridge'
import { useCanvas } from '../../external-store/atoms/create-canvas'
import { defaultFonts$ } from '../../external-store/atoms/preload-fonts'
import { useTicket } from '../../external-store/atoms/ticket'
import { useDocReadonly } from '../../external-store/context/doc-info'
import { WkCLog } from '../../kernel/clog/wukong/instance'
import { ReplayDBPrefix } from '../../kernel/interface/replay'
import { RoleStatus } from '../../kernel/interface/type'
import { RoleStatusWeight } from '../../kernel/interface/user-roles'
import { GetHistoryVersionData } from '../../kernel/request/history-version'
import { EditorService } from '../../kernel/service/editor-service'
import { featureSwitchManager } from '../../kernel/switch/core'
import { getOS } from '../../kernel/util/ua'
import {
    useAppContext,
    useBridge,
    useCommand,
    useCooperationService,
    useDocId,
    useHistoryService,
    useLibraryComponentService,
    useNotifyService,
    usePluginHostService,
    usePluginService,
    useResetRafCount,
    useWebsocketBridge,
} from '../app-context'
import { resetWasm$ } from '../lifecycle/setup-editor/editor'
import { useHistoryModeLoading, useInHistoryWasm, useLastArchiveFromSinglePage } from './context/history-mode-context'
import { useLayerPanelScrollTop } from './context/layer-panel-scroll'
import { getRouteHistoryVersionIdAndNodeId } from './utlis'

// 将任务放到下一个宏任务来进行
const queueTask = (task: () => void) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            task()
            resolve(1)
        }, 0)
    })
}

export const useEnterHistoryMode = () => {
    const commandInvoker = useCommand()
    const { scrollTop: layerPanelScrollTop } = useLayerPanelScrollTop()
    const cooperationService = useCooperationService()
    const pluginService = usePluginService()
    const pluginHostService = usePluginHostService()
    const notifyService = useNotifyService()
    const {
        libraryModalRouterService: { closeLibraryAllModal },
    } = useLibraryComponentService()

    const fn = () => {
        // 尝试关掉 plugin 弹窗
        pluginService.closeModal()
        // 尝试关闭私有 plguin
        if (pluginHostService.currentRunningPlugin) {
            motiff.closePlugin()
        }
        // 关闭私有插件开发面板
        pluginService.states.getState().updateIsOpenPluginDevelopment(false)

        // 尝试关掉组件库弹窗
        closeLibraryAllModal()

        // 进入历史版本状态
        commandInvoker.DEPRECATED_invokeBridge(
            EnterHistoryModeCommand,
            Wukong.DocumentProto.Args_EnterHistoryModeCommand.create({
                windowInnerHeight: window.innerHeight,
                layerPanelScrollTop,
            })
        )

        // 暂停协同光标判断
        cooperationService.cooperationUserActiveService.pause()

        // 断掉 notify-connector
        notifyService.disconnect()

        // 埋点
        WkCLog.log('enter history mode')
    }

    return fn
}

const useSwitch2BaseLine = () => {
    const commandInvoker = useCommand()
    const bridge = useBridge()
    const resetRafCount = useResetRafCount()

    const webSocketBridge = useWebsocketBridge()

    const { setInHistoryWasm } = useInHistoryWasm()

    const { setLastArchiveFromSinglePage } = useLastArchiveFromSinglePage()

    const fn = async () => {
        // 获取切换前的 pageId，目的是让切换前后的 pageId 尽量一致
        const lastPageId = commandInvoker.DEPRECATED_invokeBridge(GetCurrentPageIdCommand).value

        // 获取切换前的菜单状态，为了新的 wasm 也保持一致的状态
        const originMenuStatus = commandInvoker.DEPRECATED_invokeBridge(GetOriginWasmStatusCommand)

        // 获取切换前路由上的 nodeId
        const { nodeId } = getRouteHistoryVersionIdAndNodeId()

        // 重置 rafCount，避免切换回基线时，RafCount 未清空导致渲染错误
        resetRafCount()

        // 切换回基线的 wasm
        bridge.resetHistoryModeEditorService()

        // 历史模式复用了 canvas，必须重置 wasm 的 GPU state
        commandInvoker.invokeBridge(CommitType.Noop, ResetGpuStateCommand)

        // 重置 animationFrame 中的局部变量，保证渲染
        commandInvoker.DEPRECATED_invokeBridge(OnAnimationFrame)

        // 重连协同，重连时要判断当前是否是 connected
        const status = webSocketBridge.getCurrentStatus()
        if (status !== WebSocketStatus.Connected && status !== WebSocketStatus.Connecting) {
            webSocketBridge.enableAutoReconnect()
            webSocketBridge.reconnect()
        }

        // 切换 js state 状态，标明当前处于基线的 wasm
        setInHistoryWasm(false)

        // 尝试切换到上一个版本所处的 pageId 处
        commandInvoker.DEPRECATED_invokeBridge(SetCurrentPageWasmCall, {
            nodeId: lastPageId,
        })

        // 尝试选中当前的 nodeId，并切换历史版本 id 到 undefined
        commandInvoker.DEPRECATED_invokeBridge(ResetSelectionAndHistoryVersionIdCommand, {
            nodeId,
            historyVersionId: undefined,
            pageId: lastPageId,
        })

        // 重新加载失败的图片资源和字体
        commandInvoker.DEPRECATED_invokeBridge(HistoryModeReloadFailedResourcesCommand)

        // 缩放画布到合适位置
        commandInvoker.DEPRECATED_invokeBridge(ZoomToAllCommandForJs)

        // 重置菜单状态
        commandInvoker.DEPRECATED_invokeBridge(ResetWasmStatusCommand, originMenuStatus)

        // 切换回基线时，需要标明本次的修改不是返回的单页数据
        setLastArchiveFromSinglePage(false)
    }

    return fn
}

export const useLeaveHistoryMode = () => {
    const commandInvoker = useCommand()

    const { scrollTop: layerPanelScrollTop } = useLayerPanelScrollTop()

    const cooperationService = useCooperationService()
    const switch2BaseLine = useSwitch2BaseLine()

    const { inHistoryWasm } = useInHistoryWasm()
    const notifyService = useNotifyService()

    const docReadonly = useDocReadonly()

    const fn = async () => {
        // 只有在历史版本的 wasm 时才需要重置到基线，否则直接离开历史版本状态即可
        if (inHistoryWasm) {
            switch2BaseLine()
        }

        // 离开历史版本状态
        commandInvoker.DEPRECATED_invokeBridge(
            LeaveHistoryModeWasmCall,
            Wukong.DocumentProto.Args_LeaveHistoryModeCommand.create({
                docReadonly,
                layerPanelScrollTop,
            })
        )

        // 继续协同光标判断
        cooperationService.cooperationUserActiveService.continue()

        // 重连 notify-connector
        notifyService.reconnect()
    }

    return fn
}

// 约定 id 为 -1 时为基线版本
const BASE_LINE_VERSION = -1
const HISTORY_SID = Math.pow(2, 31) - 1
export const useSwitchHistoryVersion = () => {
    const bridge = useBridge()
    const commandInvoker = useCommand()
    const canvas = useCanvas()
    const docId = useDocId()
    const resetRafCount = useResetRafCount()
    const { userInfo } = useUserInfoContext()
    const { historyModeLoading, setHistoryModeLoading } = useHistoryModeLoading()
    const { scrollTop: layerPanelScrollTop } = useLayerPanelScrollTop()
    const webSocketBridge = useWebsocketBridge()
    const { setInHistoryWasm } = useInHistoryWasm()
    const historyService = useHistoryService()

    const { lastArchiveFromSinglePage, setLastArchiveFromSinglePage } = useLastArchiveFromSinglePage()

    const switch2BaseLine = useSwitch2BaseLine()
    const asyncWasm = useSet(resetWasm$)
    const defaultFontsPromise = useGet(defaultFonts$)

    const ticket = useTicket()

    const editorContext = useAppContext()

    // 回退到当前版本
    const reset2BaseLine = () => {
        switch2BaseLine()

        // 这里进入历史版本为了触发 layer / page 的重渲染
        commandInvoker.DEPRECATED_invokeBridge(
            EnterHistoryModeCommand,
            Wukong.DocumentProto.Args_EnterHistoryModeCommand.create({
                windowInnerHeight: window.innerHeight,
                layerPanelScrollTop,
            })
        )
    }

    const switch2HistoryLine = async (currentDocumentVersionId: number, lastDocumentVersionId: number) => {
        // 获取切换前的 pageId，目的是让切换前后的 pageId 尽量一致
        const targetPageId = commandInvoker.DEPRECATED_invokeBridge(GetCurrentPageIdCommand).value

        // 获取切换前的菜单状态，为了新的 wasm 也保持一致的状态
        const originMenuStatus = commandInvoker.DEPRECATED_invokeBridge(GetOriginWasmStatusCommand)

        // 获取切换前路由上的 nodeId
        const { nodeId } = getRouteHistoryVersionIdAndNodeId()

        // 获取目标 version 数据
        const versionData = await new GetHistoryVersionData(
            currentDocumentVersionId,
            lastArchiveFromSinglePage.current,
            lastDocumentVersionId === BASE_LINE_VERSION ? undefined : lastDocumentVersionId,
            targetPageId!
        )
            .start()
            .then(async (arrayBuffer) => {
                return Wukong.ServerProto.SynergyMessageProto.decode(new Uint8Array(arrayBuffer))
            })

        // 每次获取数据时，如果上一次下发的是分页数据，需要再下一次更改时通知服务端为分页数据
        setLastArchiveFromSinglePage(!!versionData.pagedArchive)

        // 如果接收的数据不是 diff 数据，则需要重置 wasm
        if (!versionData.onlyDiff) {
            // 标明进入 historyWasm
            setInHistoryWasm(true)

            // 切换到历史版本时，断掉 socket 连接，待回退到基线时重新连接
            webSocketBridge.disableAutoReconnect()
            await webSocketBridge.disconnectUnderHistoryMode()

            // 生成新的 wasm
            const newEditor = await asyncWasm()

            // 生成新的 editorService，用于分离 bridge 调用
            const newEditorService = new EditorService(newEditor)

            // 开启新的 replayer
            await bridge.initRecording(docId, ReplayDBPrefix + newEditorService.clientId)

            // 重置 RafCount
            resetRafCount()

            // 开启新的 wasm，新 wasm 使用同一份 canvas
            await newEditorService.initCanvas(canvas)

            // 更换 bridge 里的指针，让 bridge 调用切向新的 wasm
            bridge.resetHistoryModeEditorService(newEditorService)

            newEditorService.startEditor2(
                ticket?.schemaVersion ??
                    Wukong.DocumentProto.SupportedSchemaVersionRange.SUPPORTED_SCHEMA_VERSION_RANGE_MIN
            )

            if (!featureSwitchManager.isEnabled('do-not-set-canvas-size-on-after-editor-setup')) {
                commandInvoker.invoke(DO_NOT_USE_THIS_cmdSetRenderViewport, canvas)
            }

            // 加载默认字体
            for (const font of await defaultFontsPromise) {
                commandInvoker.DEPRECATED_invokeBridge(LoadFontInJsCommand, {
                    url: font.url,
                    size: font.data.length,
                })
            }

            // 初始化 document 数据，参与方状态为只读
            commandInvoker.DEPRECATED_invokeBridge(OnDocIdChange, { value: docId })
            commandInvoker.DEPRECATED_invokeBridge(OnUserIdChange, { value: userInfo.userId })
            commandInvoker.DEPRECATED_invokeBridge(OnUserRoleChange, { value: RoleStatusWeight[RoleStatus.Viewer] })
            commandInvoker.DEPRECATED_invokeBridge(OnBridgeStatusChange, { value: 1 })
            commandInvoker.DEPRECATED_invokeBridge(OnSessionIdChange, { value: HISTORY_SID })
            commandInvoker.DEPRECATED_invokeBridge(SetReleaseVersion, {
                value: getCommitId() ?? 'unknown',
            })
            // 执行 onDocument
            commandInvoker.DEPRECATED_invokeBridge(OnDocumentCommand, {
                payload: versionData.payload,
                docId,
                finalFragment: versionData.finalFragment ?? true,
                checksum: versionData.checksum,
                docHash: versionData.docHash,
                index: versionData.index ?? {},
                opLogs: versionData.opLogs ?? [],
                compressType: versionData.compressType ?? Wukong.DocumentProto.CompressType.COMPRESS_TYPE_NO_COMPRESS,
            })

            // 重置可用字体
            editorContext.fontManagerService?.startSyncUsableFontInfos()

            // 设置 os
            commandInvoker.DEPRECATED_invokeBridge(
                SetOperateSystemWasmCall,
                Wukong.DocumentProto.Args_SetOperateSystemCommand.create({
                    value: getOS(),
                })
            )

            // 进入历史版本状态
            commandInvoker.DEPRECATED_invokeBridge(
                EnterHistoryModeCommand,
                Wukong.DocumentProto.Args_EnterHistoryModeCommand.create({
                    windowInnerHeight: window.innerHeight,
                    layerPanelScrollTop,
                })
            )

            // 缩放画布到合适位置
            commandInvoker.DEPRECATED_invokeBridge(ZoomToAllCommandForJs)

            // 重置 Route 上的historyVersionId，同时尝试选中 nodeId / targetPageId
            commandInvoker.DEPRECATED_invokeBridge(ResetSelectionAndHistoryVersionIdCommand, {
                historyVersionId: String(currentDocumentVersionId),
                nodeId,
                pageId: targetPageId,
            })

            // 重置菜单状态
            commandInvoker.DEPRECATED_invokeBridge(ResetWasmStatusCommand, originMenuStatus)
        } else {
            // 如果是 diff 数据，直接 apply batchOpeartion
            commandInvoker.DEPRECATED_invokeBridge(OnBatchOperationsUnderHistoryModeCommand, {
                opLogs: versionData.opLogs ?? [],
                compressType: versionData.compressType ?? Wukong.DocumentProto.CompressType.COMPRESS_TYPE_NO_COMPRESS,
            })

            // 缩放画布到合适位置
            commandInvoker.DEPRECATED_invokeBridge(ZoomToAllCommandForJs)

            // 重置 Route 上的historyVersionId，同时尝试选中 nodeId / targetPageId
            commandInvoker.DEPRECATED_invokeBridge(ResetSelectionAndHistoryVersionIdCommand, {
                historyVersionId: String(currentDocumentVersionId),
                nodeId,
                pageId: targetPageId,
            })
        }
    }

    const fn = async (currentDocumentVersionId: number, lastDocumentVersionId: number) => {
        // 开始加载
        setHistoryModeLoading(true)
        try {
            // 切换回基线版本时
            if (currentDocumentVersionId === BASE_LINE_VERSION) {
                reset2BaseLine()
            } else {
                // 切换到目标版本
                await switch2HistoryLine(currentDocumentVersionId, lastDocumentVersionId)
            }
            historyService.switchHistoryVersionSuccess(currentDocumentVersionId)
        } catch (e) {
            console.error(e)
            historyService.switchHistoryVersionFailure(currentDocumentVersionId, lastDocumentVersionId)
        }
        // 结束加载
        setHistoryModeLoading(false)
        // 根据路由更新面板的响应状态，wasm 写入 route 是异步的，故在下一个宏任务里更新面板状态
        await queueTask(() => {
            historyService.updateCurrentHistoryVersionId()
        })
    }

    return {
        switchHistoryVersion: fn,
        loading: historyModeLoading,
    }
}
