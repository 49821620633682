import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    UnknownLibrary: '未知库',
    UnknownLibrary_synonyms1: '未知组件库',
    NoEffect: '没有效果样式',
    BrowseLibraries: '浏览团队组件库',
    NoStyles: '没有找到该效果样式',
    Search: '搜索',
} as const

export const enTranslation = {
    UnknownLibrary: 'Unknown library',
    UnknownLibrary_synonyms1: 'Unknown library',
    NoEffect: 'No effect styles',
    BrowseLibraries: 'Browse libraries',
    NoStyles: 'No results found',
    Search: 'Search',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
