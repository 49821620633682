import { useCallback, useEffect, useState } from 'react'
import { useInterval } from 'react-use'
import { WKButton } from '../../../../../../../ui-lib/src'
import { GetLoginOtpEmail } from '../../../../../kernel/request/account'
import { translation } from './verify-code-but.translation'

const TIMEOUT = 60
export const CountdownButton = (props: { onError: (code: number) => void; onOtpToken: (otpToken: string) => void }) => {
    const { onError, onOtpToken } = props
    const [count, setCount] = useState(TIMEOUT)
    const [run, setRun] = useState(false)
    useEffect(() => {
        if (!run) {
            setCount(TIMEOUT)
        }
    }, [run])
    useInterval(
        () => {
            if (count == 0) {
                setRun(false)
                return
            }
            setCount((c) => c - 1)
        },
        run ? 1000 : null
    )
    const getOtp = useCallback(async () => {
        try {
            setRun(true)
            const { otpToken } = await new GetLoginOtpEmail().start()
            onOtpToken(otpToken)
        } catch (e) {
            const code = (e as any).businessStatus
            onError(code)
            setRun(false)
        }
    }, [onError, onOtpToken])
    return (
        <WKButton
            type="secondary"
            className="h-min! w-25"
            data-testid="otp-but"
            onClick={getOtp}
            disabled={run}
            htmlType="button"
        >
            {run ? translation('resend-in', { count: String(count) }) : translation('send-code')}
        </WKButton>
    )
}
