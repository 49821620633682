import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Web: 'Web 预设',
    iOS: 'iOS 预设',
    Android: 'Android 预设',
} as const

export const enTranslation = {
    Web: 'Web',
    iOS: 'iOS',
    Android: 'Android',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
