import { Wukong } from '@wukong/bridge-proto'
import { getShadow, parseRGBA } from './utils'

export function addEffects(partialNode: Wukong.DocumentProto.IPartialNode, boxShadow: string) {
    const shadows = getShadow(boxShadow)

    let hasAnySpread = false
    // 处理完需要反转, 因为我们是后面盖前面, html是前面盖后面
    partialNode.effects = shadows
        .map((shadow) => {
            if (shadow.spreadRadius !== '0px') {
                hasAnySpread = true
            }
            return {
                type: shadow.isInsert
                    ? Wukong.DocumentProto.EffectType.EFFECT_TYPE_INNER_SHADOW
                    : Wukong.DocumentProto.EffectType.EFFECT_TYPE_DROP_SHADOW,
                visible: true,
                opacity: parseRGBA(shadow.color).a,
                color: {
                    r: parseRGBA(shadow.color).r,
                    g: parseRGBA(shadow.color).g,
                    b: parseRGBA(shadow.color).b,
                    a: parseRGBA(shadow.color).a * 255,
                },
                offset: {
                    x: +shadow.offsetX.replace('px', ''),
                    y: +shadow.offsetY.replace('px', ''),
                },
                radius: +shadow.blurRadius.replace('px', ''),
                spread: +shadow.spreadRadius.replace('px', ''),
            }
        })
        .reverse()
    if (hasAnySpread) {
        // 如果阴影里存在扩展, 就得裁剪, 不裁剪的不显示扩散
        // 不存在扩散不意味着不裁剪, 因为可能在别的地方overflow:hidden导致了裁剪
        partialNode.clipsContent = true
    }
    if (partialNode.fills?.[0].opacity === 0) {
        // 如果要渲染出阴影, 背景透明度必须>0.1%, 不知道为啥一些case里设置0.1001也不行, 至少要0.2才可以, 现在都设置了0.2
        partialNode.fills[0].opacity = 0.002
    }
}

export function tryAddStrokes(
    synergyNode: Wukong.DocumentProto.ISynergyNode,
    borderColor: string,
    computedStyle: CSSStyleDeclaration
) {
    const borderTopWidth = +computedStyle.borderTopWidth.replace('px', '')
    const borderRightWidth = +computedStyle.borderRightWidth.replace('px', '')
    const borderBottomWidth = +computedStyle.borderBottomWidth.replace('px', '')
    const borderLeftWidth = +computedStyle.borderLeftWidth.replace('px', '')
    if (borderTopWidth || borderRightWidth || borderBottomWidth || borderLeftWidth) {
        synergyNode.partialNode!.borderStrokeWeightsIndependent = true
        synergyNode.partialNode!.strokeAlign = Wukong.DocumentProto.StrokeAlign.STROKE_ALIGN_INSIDE
        synergyNode.partialNode!.borderTopWeight = borderTopWidth
        synergyNode.partialNode!.borderRightWeight = borderRightWidth
        synergyNode.partialNode!.borderBottomWeight = borderBottomWidth
        synergyNode.partialNode!.borderLeftWeight = borderLeftWidth
        synergyNode.partialNode!.strokes = [
            {
                type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
                visible: true,
                opacity: parseRGBA(borderColor).a,
                blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
                color: {
                    r: parseRGBA(borderColor).r,
                    g: parseRGBA(borderColor).g,
                    b: parseRGBA(borderColor).b,
                },
            },
        ]
        if (computedStyle.borderStyle === 'dashed') {
            const borderWeight = Math.max(borderTopWidth, borderRightWidth, borderBottomWidth, borderLeftWidth)
            const lineLength = borderWeight >= 4 ? borderWeight * 2 : borderWeight * 3
            const spaceLength = borderWeight >= 4 ? borderWeight : borderWeight * 2
            synergyNode.partialNode!.dashPattern = [lineLength, spaceLength]
        } else if (computedStyle.borderStyle === 'dotted') {
            const borderWeight = Math.max(borderTopWidth, borderRightWidth, borderBottomWidth, borderLeftWidth)
            synergyNode.partialNode!.dashPattern = [borderWeight, borderWeight]
        }
    }
}
