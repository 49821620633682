import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    FileDuplicated: '文件已创建副本',
    ToDrafts: '至草稿',
    ProhibitDuplicate: '当前文件被设置为禁止查看者创建文件副本',
} as const

export const enTranslation = {
    FileDuplicated: 'File duplicated',
    ToDrafts: ' to drafts',
    ProhibitDuplicate: 'This file settings disable viewers from duplicating.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
