import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    CopyLinkTo: '复制页面链接',
    RenamePage: '重命名',
    DuplicatePage: '创建页面副本',
    DeletePage: '删除页面',
    Pages: '页面',
    'Find…': '查找',
    AddNewPage: '添加页面',
    OpenPageList: '展开页面列表',
    ClosePageList: '收起页面列表',
} as const

export const enTranslation = {
    CopyLinkTo: 'Copy link to page',
    RenamePage: 'Rename page',
    DuplicatePage: 'Duplicate page',
    DeletePage: 'Delete page',
    Pages: 'Pages',
    'Find…': 'Find...',
    AddNewPage: 'Add new page',
    OpenPageList: 'Expand page list',
    ClosePageList: 'Collapse page list',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
