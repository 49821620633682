import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Libraries: '引用组件库',
    LocalLibrary: '本地组件库',
} as const

export const enTranslation = {
    Libraries: 'Shared libraries',
    LocalLibrary: 'Local library',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
