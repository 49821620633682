import { forwardRef, useImperativeHandle, useRef } from 'react'
import { ExplicitUndefined } from '../../../utils/explicit-undefined'
import { SelectOption } from '../picks/single-level/select-option'
import { SingleLevel, SingleLevelProps, SingleLevelRef } from '../picks/single-level/single-level'
import { TriggerIcon, TriggerIconRef } from '../triggers/trigger-icon'
import { SelectSingleLevelProps, SelectTriggerIconProps } from './type'
import { useCommentSelect } from './use-comment-select'

export type SelectIconSingleLevelProps = SelectTriggerIconProps & SelectSingleLevelProps

export interface SelectIconSingleLevelRef {
    close: () => void
    open: () => void
}

export function _SelectIconSingleLevel(props: SelectIconSingleLevelProps, ref?: React.Ref<SelectIconSingleLevelRef>) {
    const pickRef = useRef<SingleLevelRef>(null)
    const triggerRef = useRef<TriggerIconRef>(null)
    const {
        pickRest,
        value,
        mainValue,
        placement,
        triggerRect,
        worldRect,
        isMinWidthFromTrigger = true,
        minWidth,
        width,
        maxWidth,
        isMixed,
        disableDefaultPreselect,
        removeTopPadding,
        removeBottomPadding,
        onKeyboard,
        onChange,
        onClose,
        onOpen,
        openStateToBeFalse,
        openStateToBeTrue,
        onChangePreselect,
        ...otherProps
    } = props

    const commentSelectState = useCommentSelect({
        triggerRect,
        triggerRef,
        pickRef,
        onOpen,
        onClose,
    })

    useImperativeHandle(
        ref,
        () => ({
            close: () => pickRef.current?.close(),
            open: () => pickRef.current?.open(),
        }),
        []
    )

    return (
        <TriggerIcon
            ref={triggerRef}
            {...otherProps}
            isOpenPick={commentSelectState.isOpenPick}
            openAction={commentSelectState.openAction}
            closeAction={commentSelectState.closeAction}
        >
            <SingleLevel<ExplicitUndefined<SingleLevelProps>>
                pickRest={pickRest}
                optionTemplate={SelectOption}
                dataTestIds={props.dataTestIds}
                triggerRect={commentSelectState.triggerRect}
                closeByESC={commentSelectState.closeByESC}
                onClose={commentSelectState.onSelectClose}
                onOpen={commentSelectState.onSelectOpen}
                ref={pickRef}
                value={value}
                mainValue={mainValue}
                placement={placement}
                worldRect={worldRect}
                isMinWidthFromTrigger={isMinWidthFromTrigger}
                minWidth={minWidth}
                width={width}
                maxWidth={maxWidth}
                onKeyboard={onKeyboard}
                onChange={onChange}
                onChangePreselect={onChangePreselect}
                isMixed={isMixed}
                removeTopPadding={removeTopPadding}
                removeBottomPadding={removeBottomPadding}
                isOpenState={undefined}
                openStateToBeFalse={openStateToBeFalse}
                openStateToBeTrue={openStateToBeTrue}
                getPortalElement={commentSelectState.getPortalElement}
                disableDefaultPreselect={disableDefaultPreselect}
                removeMask={undefined}
                isAllowEmptyOption={undefined}
                autoAdjustTop={undefined}
            >
                {props.children}
            </SingleLevel>
        </TriggerIcon>
    )
}
const forwardSelectIconSingleLevel = forwardRef(_SelectIconSingleLevel)
export const SelectIconSingleLevel: typeof forwardSelectIconSingleLevel & { Option: typeof SelectOption } =
    Object.assign(forwardSelectIconSingleLevel, {
        Option: SelectOption,
    })
