/* eslint-disable no-restricted-imports */
import { FunctionComponent } from 'react'
import { NodeId } from '../../../../../../document/node/node'
import { useRightPanelWidth } from '../../../../../../main/layout/layout-context'
import { useNodePreview } from '../../../../design/export/hooks/use-node-preview'
import { PreviewImageSize } from '../../../../design/export/util'
import styles from './preview.module.less'

export interface NodePreviewProps {
    ids: NodeId[]
}

export const NodePreview: FunctionComponent<NodePreviewProps> = (props: NodePreviewProps) => {
    const rightPanelWidth = useRightPanelWidth()
    const size = rightPanelWidth - 32 // 16 * 2 padding

    const base64 = useNodePreview(props.ids, size as PreviewImageSize)

    return (
        <div className={styles.previewImg}>
            <img
                src={base64 ?? ''}
                data-testid="dev-mode-export-preview"
                style={{ pointerEvents: 'none', maxWidth: `${size}px`, maxHeight: `${size}px` }}
            />
        </div>
    )
}
