import {
    MonoIconCommon16,
    MonoIconCommonchat16,
    MonoIconCommoneditLayer16,
    MonoIconCommonQuestion16,
    MonoIconCommonstarFall16,
} from '../../../../../../ui-lib/src'
import { useUserInfoContext } from '../../../../auth'
import { ConversationType } from '../../../../kernel/interface/chatbot'
import { useAppContext } from '../../../../main/app-context'
import { ChatbotFunctionEntryButton } from '../ui-component/chatbot-function-entry-button'
import { translation } from './home-page.translation'

export const ChatbotHomePage = () => {
    const chatbotService = useAppContext().chatbotService
    const { userInfo } = useUserInfoContext()

    return (
        <div className="px-6 pt-4 pd-6 flex flex-col justify-center items-center gap-8">
            <div className="flex flex-col justify-start items-start gap-2 color-$wk-v2-label-color-gray-13">
                <div className="wk-text-22 font-600 text-left">
                    {translation('GreetingTitle', { name: userInfo.userBrief.nickname })}
                </div>
                <div className="text-13px lh-22px font-400 text-left">{translation('GreetingDescription')}</div>
            </div>
            <div className="flex flex-wrap gap-3">
                <ChatbotFunctionEntryButton
                    icon={<MonoIconCommonchat16 />}
                    title={translation('Ask')}
                    description={translation('AskDescription')}
                    onClick={() => {
                        chatbotService.openNewConversationPage(ConversationType.ASK, translation('Ask'))
                    }}
                    data-testid="start-ask-dialogue"
                />
                <ChatbotFunctionEntryButton
                    icon={<MonoIconCommoneditLayer16 />}
                    title={translation('Editor')}
                    description={translation('EditorDescription')}
                    onClick={() => {
                        chatbotService.openNewConversationPage(ConversationType.EXECUTE, translation('Editor'))
                    }}
                    data-testid="start-editor-dialogue"
                />
                <ChatbotFunctionEntryButton
                    icon={<MonoIconCommonstarFall16 />}
                    title={translation('SearchIdeas')}
                    description={translation('SearchIdeasDescription')}
                    onClick={() => {
                        chatbotService.openNewConversationPage(
                            ConversationType.IDEA_DISCOVERY,
                            translation('SearchIdeas')
                        )
                    }}
                />
                <ChatbotFunctionEntryButton
                    icon={<MonoIconCommon16 />}
                    title={translation('SearchAssets')}
                    description={translation('SearchAssetsDescription')}
                    onClick={() => {
                        chatbotService.openNewConversationPage(ConversationType.SEARCH, translation('SearchAssets'))
                    }}
                />
                <ChatbotFunctionEntryButton
                    icon={<MonoIconCommonQuestion16 />}
                    title={translation('Help')}
                    description={translation('HelpDescription')}
                    onClick={() => {
                        chatbotService.openNewConversationPage(ConversationType.USE_HELP, translation('Help'))
                    }}
                />
            </div>
        </div>
    )
}
