export enum SortType {
    Time = 'time',
    Unread = 'unread',
}

export enum ShowFilterType {
    Resolved = 'resolved',
    Relative = 'relative',
    CurrentPage = 'current-page',
}

export enum NoticeType {
    All = 'all',
    Relative = 'relative',
    None = 'none',
}
