import { DBSchema } from 'idb'
import {
    DownloadedFontsSchema,
    MotiffAutoSaveSchema,
    MotiffUserConfigSchema,
    PluginSchema,
    RecordReplaySchema,
    SavedResourcesSchema,
    TestCanRemoveSchema,
    TestSchema,
    UploadFontsSchema,
} from './schema'

/**
 * IndexedDB 数据库名
 */
export enum IndexedDBName {
    // ------------- For Test ------------- //
    Test = 'test',
    TestCanRemove = 'testCanRemove',

    // ------------- Web ------------- //
    MotiffAutoSaveV2 = 'motiff-autosave-v2',
    SavedResources = 'saved-resources',
    MotiffUserConifg = 'motiff-user-config',
    Plugin = 'motiff-plugin',
    RecordReplay = 'RecordReplay',
    UploadedFonts = 'UploadedFonts',
    DownloadedFonts = 'DownloadedFonts',
}

/**
 * IndexedDBName 的可否被删除的配置
 * 当超限时优先删除可以被删除的配置
 */
export const IndexedDBName2CanRemove: Record<IndexedDBName, boolean> = {
    [IndexedDBName.Test]: false,
    [IndexedDBName.TestCanRemove]: true,
    [IndexedDBName.MotiffAutoSaveV2]: false,
    [IndexedDBName.SavedResources]: false,
    [IndexedDBName.MotiffUserConifg]: false,
    [IndexedDBName.Plugin]: false,
    [IndexedDBName.RecordReplay]: true,
    [IndexedDBName.UploadedFonts]: true,
    [IndexedDBName.DownloadedFonts]: true,
}

/**
 * 数据表类型配置
 * 在使用动态 DBName 时，其 schema 配置按照 DB 前缀获取
 */
export interface IndexedDBName2DBSchema extends Record<IndexedDBName, DBSchema> {
    [IndexedDBName.Test]: TestSchema
    [IndexedDBName.TestCanRemove]: TestCanRemoveSchema
    [IndexedDBName.MotiffAutoSaveV2]: MotiffAutoSaveSchema
    [IndexedDBName.SavedResources]: SavedResourcesSchema
    [IndexedDBName.MotiffUserConifg]: MotiffUserConfigSchema
    [IndexedDBName.Plugin]: PluginSchema
    [IndexedDBName.RecordReplay]: RecordReplaySchema
    [IndexedDBName.UploadedFonts]: UploadFontsSchema
    [IndexedDBName.DownloadedFonts]: DownloadedFontsSchema
}
