import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Name: '名称',
    Description: '描述',
    ProvideName: '请输入变量名称',
    ProvideDescription: '请输入变量描述',
} as const

export const enTranslation = {
    Name: 'Name',
    Description: 'Description',
    ProvideName: 'New variable',
    ProvideDescription: 'What is it for?',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
