import { EffectStyleIcon } from './effect-style-icon'
import { useRef } from 'react'
import { StylePaint } from '../../styles/style-paint/style-paint'
import { useEffectContext } from '../context'

export function EffectStyleItem() {
    const {
        selectionEffectStyle,
        selectionEffectStyleIcon,
        showEffectStyleSelectModal,
        removeEffectStyle,
        detachEffectStyle,
        openEffectStyleSelectModal,
    } = useEffectContext()

    const itemRef = useRef<HTMLDivElement>(null)

    const onOpenSelectModal = () => {
        if (itemRef.current) {
            openEffectStyleSelectModal(itemRef.current.getBoundingClientRect())
        }
    }

    return selectionEffectStyle ? (
        <StylePaint
            type="effect"
            ref={itemRef}
            name={selectionEffectStyle.styleName}
            description={selectionEffectStyle.description}
            thumbnail={<EffectStyleIcon iconType={selectionEffectStyleIcon}></EffectStyleIcon>}
            isOpen={showEffectStyleSelectModal}
            onClickThumbnailName={onOpenSelectModal}
            onClickCutLink={() => detachEffectStyle(selectionEffectStyle.styleId)}
            onClickSub={() => removeEffectStyle()}
        />
    ) : null
}
