import { useViewState } from '../../../../../view-state-bridge'
import { DropdownV2, MonoIconToolbarMenu24, WKMenuConfig } from '../../../../../../../ui-lib/src'
import { frogExposeToolsMenusAndActionsMenuDisplay } from '../../../../utils/tools-actions-frog'
import { MenuItemRender } from './menu-item-render'
import { useMenu } from './use-menu'
import { transformMenuConfig } from './utils'
import { Wukong } from '@wukong/bridge-proto'

export interface MenuV2Props {
    onVisible?: (visible: boolean) => void
    config: WKMenuConfig
}
export function MenuV2(props: MenuV2Props) {
    const { onChange } = useMenu()
    const docReadonly = useViewState('docReadonly')
    const isHistoryMode = useViewState('historyMode')
    const state = useViewState('topArea')
    const isEditVector = state?.isEditVector
    const editorType = useViewState('editorType')

    return (
        <DropdownV2.MenuMultiLevel
            label={<MonoIconToolbarMenu24 />}
            items={transformMenuConfig(props.config)}
            onOpen={() => {
                frogExposeToolsMenusAndActionsMenuDisplay({
                    doc_mode: {
                        is_readonly: !!docReadonly,
                        is_dev_mode: editorType === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV,
                        is_history_version: !!isHistoryMode,
                        is_vector: !!isEditVector,
                    },
                    displayed_menu: '主菜单',
                })
                props.onVisible?.(true)
            }}
            onClose={() => props.onVisible?.(false)}
            renderItem={MenuItemRender}
            onChange={onChange}
            dataTestIds={{
                triggerFocus: 'menu-button',
                captureKeyboard: 'menu-item-container',
                scrollContainer: 'scrollContainer',
            }}
            style={{ padding: '0 8px 0 6px' }}
        />
    )
}
