import { translation } from './attribute-panel.translation'
/* eslint-disable no-restricted-imports */
import { HTMLAttributes, useMemo, useState } from 'react'
import { Scrollbar, Tooltip } from '../../../../../../../ui-lib/src'
import { ReactComponent as IconStrokeCapButt } from '../../../../assets/plugins/iconpark/icon-stroke-cap-butt.svg'
import { ReactComponent as IconStrokeCapRound } from '../../../../assets/plugins/iconpark/icon-stroke-cap-round.svg'
import { ReactComponent as IconStrokeCapSquare } from '../../../../assets/plugins/iconpark/icon-stroke-cap-square.svg'
import { ReactComponent as IconStrokeJoinBevel } from '../../../../assets/plugins/iconpark/icon-stroke-join-bevel.svg'
import { ReactComponent as IconStrokeJoinMiter } from '../../../../assets/plugins/iconpark/icon-stroke-join-miter.svg'
import { ReactComponent as IconStrokeJoinRound } from '../../../../assets/plugins/iconpark/icon-stroke-join-round.svg'
import { SelectIconGroup } from '../../../atom/button/select-button-group'
import {
    IconColorStyle,
    StrokeLinecap,
    StrokeLinejoin,
    iconSizeDefaultIndex,
    iconSizeValues,
    strokeWidthDefaultIndex,
    strokeWidthValues,
} from '../config'
import styles from '../index.module.less'
import { ColorStyleSelectPanel } from './color-style-select-panel'
import { InputAndSelector } from './input-and-select'

export interface AttributePanelProps extends HTMLAttributes<HTMLDivElement> {
    setSize: (size: number) => void
    setStrokeWidth: (strokeWidth: number) => void
    setColorStyle: (colorStyle: IconColorStyle) => void
    setStrokeLinecap: (strokeLinecap: StrokeLinecap) => void
    setStrokeLinejoin: (strokeLinejoin: StrokeLinejoin) => void
    dataTestIds?: {
        SizeSelector?: string
        StrokeWidthSelector?: string
        StrokeCapRoundBtn?: string
        StrokeCapButtBtn?: string
        StrokeCapSquareBtn?: string
        StrokeJoinRoundBtn?: string
        StrokeJoinMiterBtn?: string
        StrokeJoinBevelBtn?: string
        colorStyleSelector?: string
        colorInput?: string
        colorSelectorPanel?: string
    }
}

export const AttributePanel = (props: AttributePanelProps) => {
    const { setSize, setStrokeWidth, setColorStyle, setStrokeLinecap, setStrokeLinejoin, dataTestIds } = props

    return (
        <Scrollbar height="100%">
            <CellContainer title={translation('IconSize')}>
                <InputAndSelector
                    values={iconSizeValues}
                    defaultIndex={iconSizeDefaultIndex}
                    onChange={(v) => setSize(v as number)}
                    inputTestId={dataTestIds?.SizeSelector}
                />
            </CellContainer>

            <CellContainer title={translation('StrokeWidth')}>
                <InputAndSelector
                    values={strokeWidthValues}
                    defaultIndex={strokeWidthDefaultIndex}
                    onChange={(v) => setStrokeWidth(v as number)}
                    inputTestId={dataTestIds?.StrokeWidthSelector}
                />
            </CellContainer>

            <CellContainer title={translation('IconTheme')}>
                <ColorStyleSelectPanel
                    setColorStyle={setColorStyle}
                    dataTestIds={{
                        colorStyleSelector: dataTestIds?.colorStyleSelector,
                        colorInput: dataTestIds?.colorInput,
                        colorSelectorPanel: dataTestIds?.colorSelectorPanel,
                    }}
                />
            </CellContainer>

            <CellContainer>
                <StrokeLineSelector
                    style={{ marginBottom: '8px' }}
                    setValue={(v) => setStrokeLinecap(v as StrokeLinecap)}
                    optionValue={[
                        {
                            value: StrokeLinecap.ROUND,
                            icon: <IconStrokeCapRound />,
                            name: translation('Round'),
                            dataTestId: dataTestIds?.StrokeCapRoundBtn,
                        },
                        {
                            value: StrokeLinecap.BUTT,
                            icon: <IconStrokeCapButt />,
                            name: translation('Normal'),
                            dataTestId: dataTestIds?.StrokeCapButtBtn,
                        },
                        {
                            value: StrokeLinecap.SQUARE,
                            icon: <IconStrokeCapSquare />,
                            name: translation('Square'),
                            dataTestId: dataTestIds?.StrokeCapSquareBtn,
                        },
                    ]}
                />
                <StrokeLineSelector
                    setValue={(v) => setStrokeLinejoin(v as StrokeLinejoin)}
                    optionValue={[
                        {
                            value: StrokeLinejoin.ROUND,
                            icon: <IconStrokeJoinRound />,
                            name: translation('Round_synonyms1'),
                            dataTestId: dataTestIds?.StrokeJoinRoundBtn,
                        },
                        {
                            value: StrokeLinejoin.MITER,
                            icon: <IconStrokeJoinMiter />,
                            name: translation('Miter'),
                            dataTestId: dataTestIds?.StrokeJoinMiterBtn,
                        },
                        {
                            value: StrokeLinejoin.BEVEL,
                            icon: <IconStrokeJoinBevel />,
                            name: translation('Bevel'),
                            dataTestId: dataTestIds?.StrokeJoinBevelBtn,
                        },
                    ]}
                />
            </CellContainer>
        </Scrollbar>
    )
}

interface strokeLineSelectorProps extends HTMLAttributes<HTMLDivElement> {
    setValue: (value: string) => void
    optionValue: { value: string; icon: React.ReactNode; name: string; dataTestId?: string }[]
    style?: React.CSSProperties
}

const StrokeLineSelector = (props: strokeLineSelectorProps) => {
    const { setValue, optionValue, style } = props

    const [value, setPrivateValue] = useState(optionValue[0].value)
    const values = useMemo(() => optionValue.map((item) => item.value), [optionValue])
    const _setValue = (v: string) => {
        setPrivateValue(v)
        setValue(v)
    }

    return (
        <SelectIconGroup
            style={{ height: '28px', ...style }}
            onClickIcon={_setValue}
            optionValue={values}
            // 避免触发可拖拽弹窗的拖拽
            data-disabled-drag-move={'true'}
        >
            {optionValue.map((item, index) => {
                return (
                    <Tooltip title={item.name} key={index}>
                        <SelectIconGroup.Item
                            value={item.value}
                            icon={item.icon}
                            selected={value === item.value}
                            dataTestId={item.dataTestId}
                        />
                    </Tooltip>
                )
            })}
        </SelectIconGroup>
    )
}

interface CellContainerProps extends HTMLAttributes<HTMLDivElement> {
    title?: string
}
const CellContainer = (props: CellContainerProps) => {
    const { title } = props

    return (
        <div className={styles.cellContainer}>
            {title ? <span className={styles.title}>{title}</span> : null}
            {props.children}
        </div>
    )
}
