import classnames from 'classnames'
import { Scrollbar, WKUserAvatar } from '../../../../../../ui-lib/src'
import { UserBriefVO } from '../../../../kernel/interface/type'
import classesCheckbox from './pay-apply-checkbox-group.module.less'
import classes from './pay-apply-user-list.module.less'

export interface PayApplyUserListProps {
    users: UserBriefVO[]
}
export function PayApplyUserList(props: PayApplyUserListProps) {
    const { users } = props

    return (
        <div
            className={classnames(classesCheckbox.checkboxes, classes.container)}
            data-testid="pay-apply-invite-user-list"
        >
            <div className={classesCheckbox.checkboxGroup}>
                <Scrollbar autoHeight autoHeightMin={60} autoHeightMax={206}>
                    <div className={classesCheckbox.height8}></div>
                    {users.map((v) => (
                        <div key={v.id} className={classnames(classesCheckbox.checkboxOne, classes.checkboxOne)}>
                            <WKUserAvatar className={classesCheckbox.avatar} userInfo={v} size={24} />
                            <div className={classesCheckbox.userInfo}>
                                <span className={classesCheckbox.name}>{v.nickname}</span>
                                <span className={classesCheckbox.email}>{v.email}</span>
                            </div>
                        </div>
                    ))}
                    <div className={classesCheckbox.height8}></div>
                </Scrollbar>
            </div>
        </div>
    )
}
