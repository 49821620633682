import { translation } from './font-plugin-install-alert.translation'
/* eslint-disable no-restricted-imports */
import { WKAlert, WKButton } from '../../../../../../ui-lib/src'
import { useFontManagerService } from '../../../context/document-context'
import styles from './font-plugin-install-alert.module.less'

export function FontPluginInstallAlert() {
    const fontManagerService = useFontManagerService()
    const { versionUrl, pluginStatus } = fontManagerService.states.use.pluginDetails()

    switch (pluginStatus) {
        case 'installed':
        case 'silentIgnore':
        case 'optionUpdated':
            return <div className={styles.message}>{translation('TheFollowingFonts')}</div>
        case 'uninstalled':
        case 'forceUpdated': {
            const forceUpgrade = pluginStatus === 'forceUpdated'
            const title = forceUpgrade ? translation('UpdateTitle') : translation('ZsrELj')
            const button = forceUpgrade ? translation('UpdateButton') : translation('ljMATz')
            const message = forceUpgrade ? translation('TheFollowingFonts') : translation('yathDl')

            return (
                <>
                    <div className={styles.alert}>
                        <WKAlert.WithoutTitle
                            suffix={
                                <div className={styles.button}>
                                    <WKButton
                                        size="small"
                                        onClick={() => window.open(versionUrl, '_blank')}
                                        type="primary"
                                    >
                                        {button}
                                    </WKButton>
                                </div>
                            }
                            rounded
                        >
                            {title}
                        </WKAlert.WithoutTitle>
                    </div>
                    <div className={styles.message}>{message}</div>
                </>
            )
        }
    }
}
