import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    'Find...': '查找...',
    'FindAndReplace...': '替换...',
    Edit: '编辑',
    CopyAsText: '复制为文本',
    CopyAs: '复制为',
    CopyLink: '复制链接',
    PasteOverSelection: '粘贴至当前选中',
    PasteToReplace: '粘贴替换',
    Duplicate: '创建副本',
    Delete: '删除',
    CopyProperties: '复制属性',
    PasteProperties: '粘贴属性',
    PickColor: '吸取颜色',
    ProhibitCopy: '当前文件被设置为禁止查看者复制',
} as const

export const enTranslation = {
    'Find...': 'Find...',
    'FindAndReplace...': 'Find and replace...',
    Edit: 'Edit',
    CopyAsText: 'Copy as text',
    CopyAs: 'Copy as',
    CopyLink: 'Copy link',
    PasteOverSelection: 'Paste over selection',
    PasteToReplace: 'Paste to replace',
    Duplicate: 'Duplicate',
    Delete: 'Delete',
    CopyProperties: 'Copy properties',
    PasteProperties: 'Paste properties',
    PickColor: 'Pick color',
    ProhibitCopy: 'This file settings disable viewers from copying.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
