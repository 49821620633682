import { useState } from 'react'
import { WKButton } from '../../../../../../ui-lib/src'
import { AntdUISample } from './antd-ui-samples'
import { AutoMobileUISample } from './auto-mobile-ui-samples'
import { AutoWebUISample } from './auto-web-ui-samples'
import { translation } from './design-system-sample-and-component.translation'
import { IOSUISample } from './ios-ui-samples'
import { MaterialUISample } from './material-ui-samples'
import { ShadcnUISampleOrOverview } from './shadcn-ui-samples'
import { useAIGenUIInContext } from './use-ai-gen-ui'

type TabOption = 'sample' | 'component'

interface TabButtonProps {
    label: string
    isSelected: boolean
    onClick: () => void
}

function TabButton({ label, isSelected, onClick }: TabButtonProps) {
    return (
        <WKButton
            type="text"
            style={{ margin: 0 }}
            className={`${
                isSelected
                    ? 'bg-white text-[var(--wk-gray-13)] wk-font-semibold hover:bg-white hover:text-[var(--wk-gray-13)] wk-gray-border-outer'
                    : 'text-[var(--wk-gray-11)] bg-transparent'
            } w-full text-center wk-text-12 cursor-default m-0 wk-font-regular`}
            onClick={onClick}
        >
            {label}
        </WKButton>
    )
}

export function SampleAndComponentBar({
    selected,
    setSelected,
}: {
    selected: TabOption
    setSelected: React.Dispatch<React.SetStateAction<TabOption>>
}) {
    const { tempRemixDesignSystem } = useAIGenUIInContext()
    const isShadcn = tempRemixDesignSystem === 'shadcn UI'

    if (isShadcn) {
        return (
            <div
                className={`flex flex-row rounded-1.25 w-fit ${isShadcn ? 'h-7' : 'h-7'} bg-[var(--wk-gray-1)] p-0.5 ${
                    isShadcn ? 'gap-0.5' : ''
                }`}
            >
                {isShadcn && (
                    <>
                        <TabButton
                            label={translation('samplePage')}
                            isSelected={selected === 'sample'}
                            onClick={() => setSelected('sample')}
                        />

                        <TabButton
                            label={translation('stylingOverview')}
                            isSelected={selected === 'component'}
                            onClick={() => setSelected('component')}
                        />
                    </>
                )}
            </div>
        )
    }

    return (
        <div className="flex flex-row w-fit h-8 border-1.25 justify-center items-center">
            <span className="bg-white text-[var(--wk-gray-13)] w-full text-center wk-text-12 cursor-default m-0 wk-font-semibold">
                {translation('samplePage')}
            </span>
        </div>
    )
}

function Components() {
    const { tempRemixDesignSystem } = useAIGenUIInContext()
    switch (tempRemixDesignSystem) {
        case 'shadcn UI':
            return <ShadcnUISampleOrOverview type="overview" />
        default:
            return null
    }
}

function Samples() {
    const { tempRemixDesignSystem, tempRemixPlatform } = useAIGenUIInContext()

    switch (tempRemixDesignSystem) {
        case 'shadcn UI':
            return <ShadcnUISampleOrOverview type="sample" />
        case 'Apple HIG':
            return <IOSUISample />
        case 'custom':
            if (tempRemixPlatform === 'app') {
                return <AutoMobileUISample />
            } else if (tempRemixPlatform === 'web') {
                return <AutoWebUISample />
            }
            return null
        case 'Material Design 3':
            return <MaterialUISample />
        case 'Ant Design':
            return <AntdUISample />
        default:
            return null
    }
}

export function SampleAndComponents() {
    const [selected, setSelected] = useState<TabOption>('sample')
    return (
        <div className="w-155.75 px-4 pb-4 pt-3.5 gap-2 flex flex-col items-center z-0 overflow-hidden">
            <SampleAndComponentBar selected={selected} setSelected={setSelected} />
            <div className="w-full h-112 bg-[var(--wk-gray-3)] flex flex-col items-center rounded-1 justify-center z-[-2] overflow-hidden">
                {selected === 'sample' ? <Samples /> : <Components />}
            </div>
        </div>
    )
}
