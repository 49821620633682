import { FC } from 'react'
import classnames from 'classnames'
import { WKFormItemProps, WKFormProps } from './type'
import { WKTypography } from '../wk-typography'

export const WKForm: FC<WKFormProps> = ({ children, className, ...props }) => {
    const _className = classnames('flex flex-col gap-4', className)
    return (
        <form {...props} className={_className}>
            {children}
        </form>
    )
}

export const WKFormItem: FC<WKFormItemProps> = ({ labelProps, label, children, className }) => {
    return (
        <div className={classnames('flex flex-col gap-2', className)}>
            {label && (
                <WKTypography.Text weight="medium" {...labelProps}>
                    {label}
                </WKTypography.Text>
            )}
            {children}
        </div>
    )
}
