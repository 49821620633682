import { Wukong } from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { LibraryComponentNode } from './library-node'
import { BaseNode, NodeType, SceneNode, StackMode, StackPositioning } from './node'

export function isSceneNode(node: BaseNode | null): node is SceneNode {
    return !!node && node.type !== NodeType.Document && node.type !== NodeType.Page
}

export function isInstanceChild(node: BaseNode | null): boolean {
    const parentNode = node?.getParent()
    if (!parentNode || parentNode.type === NodeType.Page || parentNode.type === NodeType.Document) {
        return false
    }
    if (parentNode?.type === NodeType.Instance) {
        return true
    }
    return isInstanceChild(parentNode)
}

export function isNodeInAutoLayout(node: BaseNode | null): boolean {
    const parentNode = node?.getParent()

    if (
        parentNode?.type === NodeType.Frame ||
        parentNode?.type === NodeType.Component ||
        parentNode?.type === NodeType.ComponentSet
    ) {
        if (
            parentNode?.stackMode !== StackMode.None &&
            (node as SceneNode).stackPositioning !== StackPositioning.Absolute
        ) {
            return true
        }
    }
    return false
}

export function isComponentOrSetNode(node: BaseNode | null): node is LibraryComponentNode {
    return !!node && (node.type === NodeType.Component || node.type === NodeType.ComponentSet)
}

export function isStyleNode(nodeType: Wukong.DocumentProto.NodeType | undefined | null) {
    return (
        !isNil(nodeType) &&
        [
            Wukong.DocumentProto.NodeType.NODE_TYPE_EFFECT_STYLE,
            Wukong.DocumentProto.NodeType.NODE_TYPE_PAINT_STYLE,
            Wukong.DocumentProto.NodeType.NODE_TYPE_TEXT_STYLE,
            Wukong.DocumentProto.NodeType.NODE_TYPE_LAYOUT_GRID_STYLE,
        ].includes(nodeType)
    )
}
