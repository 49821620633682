/* eslint-disable no-restricted-imports */
import * as React from 'react'
import { HTMLAttributes, useCallback, useMemo } from 'react'
import { TabElement } from '../../../../../../ui-lib/src'
import style from './icon-button.module.less'

export interface IconButtonProps extends Omit<HTMLAttributes<HTMLSpanElement>, 'onChange'> {
    icon?: React.ReactNode
    selectedIcon?: React.ReactNode
    selected: boolean
    disabled?: boolean
    deepColor?: boolean
    onChange?: (value: boolean) => void
    afterKeydown?: (e: React.KeyboardEvent<HTMLSpanElement>) => void
    tabTestId?: string
    dataTestId?: string
    dataTestValue?: boolean
}
function _IconButton(
    {
        selected,
        onChange,
        className,
        selectedIcon,
        icon,
        disabled,
        onClick,
        deepColor,
        onKeyDown,
        afterKeydown,
        onMouseDown,
        tabTestId,
        dataTestId,
        dataTestValue,
        ...otherProps
    }: IconButtonProps,
    ref: React.Ref<HTMLSpanElement>
) {
    const _onMouseDown = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation()
            !disabled && onChange?.(!selected)
            onMouseDown?.(e)
        },
        [disabled, onChange, selected, onMouseDown]
    )
    const _onKeyDown = useCallback(
        (e: any) => {
            if (e.keyCode === 13) {
                e.stopPropagation()
                !disabled && onChange?.(!selected)
                !disabled && onClick?.(e as any)
                !disabled && afterKeydown?.(e)
            } else if (e.keyCode === 27) {
                e.stopPropagation()
                e.target.blur()
            }

            !disabled && onKeyDown?.(e)
        },
        [disabled, onKeyDown, onChange, selected, onClick, afterKeydown]
    )
    const _onClick = useCallback(
        (e: React.MouseEvent<HTMLSpanElement>) => {
            e.stopPropagation()
            !disabled && onClick?.(e)
        },
        [onClick, disabled]
    )

    const containerClassName = useMemo(() => {
        let _containerClassName = style.span
        if (selected) {
            if (deepColor) {
                _containerClassName += ' ' + style.selected_deep
            } else {
                _containerClassName += ' ' + style.selected_shallow
            }
        }
        disabled && (_containerClassName += ' ' + style.disabled)
        className && (_containerClassName += ' ' + className)
        return _containerClassName
    }, [className, deepColor, disabled, selected])

    return (
        <span
            ref={ref}
            className={containerClassName}
            onMouseDown={_onMouseDown}
            onKeyDown={_onKeyDown}
            onClick={_onClick}
            data-testid={dataTestId}
            data-testvalue={dataTestValue}
            {...otherProps}
        >
            <TabElement disabled={disabled} className={style.focusElement} data-testid={tabTestId}></TabElement>
            {selected ? selectedIcon ?? icon : icon ?? selectedIcon}
        </span>
    )
}
/**
 * @deprecated 使用 WKIconButton
 */
export const IconButton = React.forwardRef(_IconButton)
