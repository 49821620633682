import { translation } from './inspect-typography.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { uniqWith } from 'lodash-es'
import { useCallback, useMemo, useState } from 'react'
import { useViewState } from '../../../view-state-bridge'
import { useHandler } from '../../context/document-context'
import {
    getOtherAxisLabel,
    getWeight,
    isItalAxisItalic,
    isItalic,
    isSlntAxisItalic,
    tryGetAxisOpticalSize,
    tryGetAxisWeight,
} from '../../utils/inspect-text-helper'
import { toFixed } from '../../utils/to-fixed'
import { CopyableRow } from './comp/copyable-row'
import { ViewAll } from './comp/view-all'
import styles from './inspect.module.less'
import { computeLineHeight } from './utils'

const getExampleFontSize = (fontSize: number) => {
    if (fontSize) {
        if (fontSize >= 16) {
            return 13
        } else if (fontSize >= 12) {
            return 12
        } else if (fontSize >= 10) {
            return 10
        } else {
            return 8
        }
    }
}

interface TextProperty {
    styleName: string
    weight: number
    italic: boolean
    localizedStyle: string
    family: string
    textStyle: string
    fontSize: number
    lineHeight: number
    letterSpacing: number
    paragraphSpacing: number
    textAlignHorizontal: Wukong.DocumentProto.TextAlignHorizontal
    detachOpticalSizeFromFontSize: boolean
    fontVariations: Wukong.DocumentProto.IFontVariation[]
}

const getProperyList = (segment: TextProperty) => {
    const result = [
        {
            name: translation('Font'),
            value: segment.family,
        },
        {
            name: translation('Weight'),
            value: String(toFixed(tryGetAxisWeight(segment.fontVariations) ?? segment.weight, 2)),
        },
    ]

    if (segment.italic || isItalAxisItalic(segment.fontVariations) || isSlntAxisItalic(segment.fontVariations)) {
        result.push({
            name: translation('Style'),
            value: translation('Italic'),
        })
    }

    result.push(
        {
            name: translation('Size'),
            value: toFixed(segment.fontSize, 2) + 'px',
        },
        {
            name: translation('LineHeight'),
            value: toFixed(segment.lineHeight, 2) + 'px',
        }
    )

    if (segment.letterSpacing !== 0) {
        result.push({
            name: translation('Letter'),
            value: toFixed(segment.letterSpacing, 2) + 'px',
        })
    }

    if (segment.paragraphSpacing !== 0) {
        result.push({
            name: translation('ParagraphSpacing'),
            value: toFixed(segment.paragraphSpacing, 2) + 'px',
        })
    }

    let alignHorizontalDisplay = ''
    switch (segment.textAlignHorizontal) {
        case Wukong.DocumentProto.TextAlignHorizontal.TEXT_ALIGN_HORIZONTAL_LEFT:
            alignHorizontalDisplay = translation('Left')
            break
        case Wukong.DocumentProto.TextAlignHorizontal.TEXT_ALIGN_HORIZONTAL_CENTER:
            alignHorizontalDisplay = translation('Center')
            break
        case Wukong.DocumentProto.TextAlignHorizontal.TEXT_ALIGN_HORIZONTAL_RIGHT:
            alignHorizontalDisplay = translation('Right')
            break
        default:
            break
    }

    if (alignHorizontalDisplay !== translation('Left')) {
        result.push({
            name: translation('Align'),
            value: alignHorizontalDisplay,
        })
    }

    const opszSize = tryGetAxisOpticalSize(segment.detachOpticalSizeFromFontSize, segment.fontVariations)
    if (opszSize != null) {
        result.push({
            name: translation('OpticalSize'),
            value: toFixed(opszSize, 2) + 'px',
        })
    }

    const otherAxisLabel = getOtherAxisLabel(segment.fontVariations)
    if (otherAxisLabel) {
        result.push({
            name: translation('Variation'),
            value: otherAxisLabel,
        })
    }

    const { styleName, weight, fontSize } = segment
    return [result, { styleName, weight, fontSize }] as const
}

export function InspectTypography() {
    const handler = useHandler()
    const [limit, setLimit] = useState<number>(2)
    const limitChange = useCallback((_limit: number) => setLimit(_limit), [])
    const viewState = useViewState('inspectSelectionTextTypographyViewState')
    const styleNameMap = viewState?.textStyleNodeNameMap

    const styledTextSegments = useMemo(() => {
        return uniqWith(
            (viewState?.textStyles || [])
                .map((style) => {
                    return {
                        styleName: styleNameMap?.[style.textStyleId] ?? '',
                        weight: getWeight(style.fontName, viewState?.fontMetaData),
                        italic: isItalic(style.fontName, viewState?.fontMetaData),
                        paragraphSpacing: viewState?.paragraphSpacing || 0,
                        localizedStyle: style.fontName.style,
                        family: style.fontName.family,
                        textStyle: style.fontName.style,
                        fontSize: style.fontSize,
                        lineHeight: computeLineHeight(handler, style.lineHeight, style.fontName, style.fontSize),
                        letterSpacing: style.letterSpacing.value,
                        textAlignHorizontal: viewState!.textAlignHorizontal,
                        detachOpticalSizeFromFontSize: style.detachOpticalSizeFromFontSize,
                        fontVariations: style.fontVariations ?? [],
                    }
                })
                .map((segment) => getProperyList(segment)),
            (a, b) => {
                return (
                    a[0].length == b[0].length &&
                    a[0].every((val, idx) => val.name === b[0][idx].name && val.value === b[0][idx].value)
                )
            }
        )
    }, [viewState, handler, styleNameMap])

    if (!viewState?.show || styledTextSegments.length == 0) {
        return null
    }

    return (
        <div className={styles.panel} data-testid={'design-inspect-text'}>
            <div className={styles.panelTitle}>{translation('Typography')}</div>
            {styledTextSegments.map(([proList, extraInfo], idx) => {
                if (idx + 1 <= limit) {
                    return (
                        <div key={idx} className={styles.segmentsProperty}>
                            <div className={classnames(styles.propertyRowNarrow, styles.basePropertyRow)}>
                                <span
                                    className={classnames(styles.propertyName, 'text-black')}
                                    style={{
                                        fontFamily: proList[0].value,
                                        fontWeight: extraInfo.weight,
                                        fontSize: getExampleFontSize(extraInfo.fontSize),
                                    }}
                                >
                                    {translation('Ag')}
                                </span>
                                <div className={styles.properyPairsContainer}>
                                    <CopyableRow copyValue={extraInfo.styleName}>
                                        <span className={styles.propertyValue}>{extraInfo.styleName}</span>
                                    </CopyableRow>
                                </div>
                            </div>
                            {proList.map((p) => (
                                <div key={p.name} className="flex px-4 wk-text-12 py-2px">
                                    <div className="w-88px shrink-0 color-$wk-gray-7">{p.name}</div>
                                    <CopyableRow copyValue={p.value + ''} className="overflow-hidden">
                                        <div className="flex-1 truncate color-$wk-v2-label-color-gray-13">
                                            {p.value}
                                        </div>
                                    </CopyableRow>
                                </div>
                            ))}
                        </div>
                    )
                }
            })}
            {<ViewAll limit={limit} count={styledTextSegments.length} onChange={limitChange}></ViewAll>}
        </div>
    )
}
