/* eslint-disable no-restricted-imports */
import { useCallback } from 'react'
import { useHistoryService } from '../../../../../main/app-context'
import { MessageModalProps } from '../../../history-file/message-modal/message-modal'
import { useMenuContextState } from '../context/menu-context'

export function useSaveHistoryVersion() {
    const { setMessageModalProps } = useMenuContextState()
    const historyService = useHistoryService()

    const saveHistoryVersion = useCallback(() => {
        const nextState: MessageModalProps = {
            visible: true,
            type: 'save',
            defaultName: '',
            defaultDescription: '',
            onOK: (v) => {
                historyService.requestSaveVersion(v.name, v.description)
            },
        }
        setMessageModalProps(nextState)
    }, [historyService, setMessageModalProps])

    return saveHistoryVersion
}
