import { translation } from './candidate-style-font.translation'
/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DraggablePopupV2, InputV2, Position, ScrollView } from '../../../../../../ui-lib/src'
import { cmdChangePopupState } from '../../../../document/command/document-command'
import { cmdChangeAttrPanelStyleEditorState } from '../../../../document/command/node-props-command'
import { ITextStyle, PopupStateType, TextStyleNode } from '../../../../document/node/node'
import { ToKeyCode } from '../../../../document/util/keycode'
import { KeyboardReceiver } from '../../../../main/keyboard-receiver/component'
import { WKFrogTask } from '../../../../share/wk-frog-task'
import { useViewState } from '../../../../view-state-bridge'
import { LibraryStyleTestId } from '../../../../window'
import { useCommand } from '../../../context/document-context'
import { isSearchMatched, searchMatchedFilter } from '../../../utils/search-sort'
import { CommonStyleInfo, groupStyleVOByName, parseStyleName } from '../../design/styles/get-style-nodes-info-map'
import { useKeyboardStyleStyleList } from '../../design/styles/hooks/use-keyboard-style-list'
import { modifiedMultiPopup } from '../../design/styles/modified-multi-popup'
import { TextStyleEditor } from '../../design/styles/style-panel/text-style-editor/text-style-editor'
import { StyleTextListItem } from '../../design/styles/style-text/style-text-list-item'
import { createITextStyle } from '../../design/styles/style-text/style-text-utils'
import { UnknowStyleTextListItem } from '../../design/styles/style-text/unknow-style-text-list-item'
import style from './candidate-style-color.module.less'

interface StyleTextProps {
    open?: boolean
    position?: Position
    selectStyleId?: string
    selectTextStyleNode?: TextStyleNode
    isMissFamily?: boolean
    onChangeStyle?: (styleId: string, isCreateStyle: boolean, name: string) => void
}

interface UnknowStyleGroup {
    libraryId?: string
    libraryName?: string
    docId?: string
    value: TextStyleNode
    groups: Map<string, TextStyleNode[]>
}

export function CandidateStyleFont(props: StyleTextProps & { onClose: () => void; width?: number }): JSX.Element {
    const { open, position, selectStyleId, onChangeStyle } = props

    const command = useCommand()
    const containerRef = useRef<HTMLDivElement>(null)
    const hasMoved = useRef<boolean>(false)
    const [searchStr, setSearchStr] = useState<string>('')
    const [styleIdEditor, setStyleIdEditor] = useState<string>('')
    const [positionEditor, setPositionEditor] = useState<Position>()
    const popupState = useViewState('popupState')
    const attrPanelStyleEditorState = useViewState('attrPanelStyleEditorState')

    const { localStyleGroups, inUseUnknownStyleNodeItems } = useViewState('libraryTextStyleState', {
        localStyleGroups: [],
        inUseUnknownStyleNodeItems: [],
        subscribedRemoteItems: [],
        inUseOtherRemoteItems: [],
        fakeFieldToForceUpdate: false,
    })

    const localStyleMap = useMemo(() => {
        return localStyleGroups
            .filter((group) => group.items.length > 0)
            .map((group) => {
                return {
                    ...group,
                    items: group.items.map((item): CommonStyleInfo & ITextStyle & { contentHash: string } => {
                        const { styleName, groupName } = parseStyleName(item.name)
                        return {
                            id: item.id,
                            name: item.name,
                            description: item.description,
                            styleName,
                            groupName,
                            ...createITextStyle(item as unknown as TextStyleNode),
                            contentHash: item.contentHash,
                            missFontInfo: item.missFontInfo ?? { isMissStyle: false, isMissFamily: false },
                        }
                    }),
                }
            })
    }, [localStyleGroups])

    const showStyleMap = useMemo(() => {
        const infoMap: typeof localStyleMap = []
        if (!open) {
            return infoMap
        }

        return localStyleMap
            .map((group) => ({
                ...group,
                items: searchMatchedFilter(group.items, searchStr, (item) => item.name),
            }))
            .filter((group) => group.items.length > 0)
    }, [localStyleMap, open, searchStr])

    const isNoStyle = localStyleMap.length === 0 && inUseUnknownStyleNodeItems.length === 0

    const unknownStyleGroup: UnknowStyleGroup | undefined = useMemo(() => {
        const unknowStyleNodes = inUseUnknownStyleNodeItems as unknown as TextStyleNode[]

        for (const value of unknowStyleNodes) {
            if (value.id !== selectStyleId) continue
            if (!isSearchMatched(value.name, searchStr)) continue

            return {
                libraryId: '',
                libraryName: translation('UnknownLibrary'),
                docId: value.publishFile,
                value,
                groups: groupStyleVOByName<TextStyleNode>([value]),
            }
        }
        return undefined
    }, [inUseUnknownStyleNodeItems, searchStr, selectStyleId])

    const openStyleEditor = useMemo(() => {
        const multiPopup = popupState?.multiPopup
        if (!multiPopup || multiPopup.length === 0) {
            return false
        }
        return multiPopup.some((v) => v.type === PopupStateType.POPUP_STATE_TYPE_TEXT_STYLE_EDITOR)
    }, [popupState?.multiPopup])

    const onFirstMove = useCallback(() => {
        hasMoved.current = true
    }, [])

    const setDocumentPopupState = useCallback(
        (openPopup: boolean) => {
            const _popupState = modifiedMultiPopup(
                popupState,
                PopupStateType.POPUP_STATE_TYPE_TEXT_STYLE_EDITOR,
                openPopup
                    ? {
                          type: PopupStateType.POPUP_STATE_TYPE_TEXT_STYLE_EDITOR,
                          reciprocalIndex: -1,
                          multiPopup: [],
                      }
                    : undefined
            )
            command.invoke(cmdChangePopupState, _popupState)
        },
        [command, popupState]
    )
    const onChangeSearchInput = useCallback((value: string) => {
        WKFrogTask.textStyle._recordSearchContent(value)
        setSearchStr(value)
    }, [])

    const onCloseEditor = useCallback(() => {
        setStyleIdEditor('')
        setDocumentPopupState(false)
        command.invoke(cmdChangeAttrPanelStyleEditorState, {
            editingStyleId: '',
            openFromModule: attrPanelStyleEditorState?.openFromModule,
        })
    }, [command, setDocumentPopupState, attrPanelStyleEditorState?.openFromModule])

    const onOpenEditor = useCallback(
        (styleId: string, isCreate?: boolean) => {
            command.invoke(cmdChangeAttrPanelStyleEditorState, {
                openFromModule: attrPanelStyleEditorState?.openFromModule,
                editingStyleId: styleId,
                isCreate,
            })
            setDocumentPopupState(true)
            setStyleIdEditor(styleId)
        },
        [command, setDocumentPopupState, attrPanelStyleEditorState?.openFromModule]
    )

    const isSearchState = useRef<() => boolean>()
    isSearchState.current = () => !!searchStr

    const onClickApplyStyle = useCallback(
        ({ id, name }: any) => {
            if (isSearchState.current?.()) {
                WKFrogTask.textStyle.searchResults()
            } else {
                WKFrogTask.textStyle.textStyleList()
            }
            onCloseEditor()
            onChangeStyle?.(id, !hasMoved.current, name)
        },
        [onChangeStyle, onCloseEditor]
    )

    const onClickHoverIcon = useCallback(
        ({ id }: any, containerDomRect: DOMRect) => {
            styleIdEditor === id ? onCloseEditor() : onOpenEditor(id)
            setPositionEditor({ left: containerDomRect.left, top: containerDomRect.top })
        },
        [onCloseEditor, onOpenEditor, styleIdEditor]
    )

    useEffect(() => {
        if (!open) {
            setSearchStr('')
            hasMoved.current = false
        }
    }, [open])

    const noSearchResult = useMemo(() => {
        return showStyleMap.length === 0 && !unknownStyleGroup?.groups.size
    }, [showStyleMap.length, unknownStyleGroup?.groups.size])

    const maxPreSelectIndex = useMemo(() => {
        let optionSum = 0
        for (const { items } of showStyleMap) {
            optionSum += items.length ?? 0
        }
        if (unknownStyleGroup) {
            for (const [_, styles] of unknownStyleGroup.groups) {
                optionSum += styles.length ?? 0
            }
        }
        return Math.max(optionSum - 1, 0)
    }, [showStyleMap, unknownStyleGroup])

    const keyboardStyleStyleList = useKeyboardStyleStyleList({ maxPreSelectIndex })
    const renderItems = () => {
        let optionIndex = 0
        const _selectStyleId = selectStyleId ? selectStyleId : undefined
        return (
            <>
                {showStyleMap.map(({ name, items }, index) => (
                    <div key={index}>
                        {name ? <div className={style.h2}>{name}</div> : null}
                        <div>
                            {items.map((item) => (
                                <StyleTextListItem
                                    key={item.id}
                                    item={item}
                                    openStyleId={open && openStyleEditor ? styleIdEditor : undefined}
                                    onSelectItem={onClickApplyStyle}
                                    onClickHoverIcon={onClickHoverIcon}
                                    dataTestId={`${LibraryStyleTestId.LocalTextStyleItem}-${item.id}`}
                                    index={optionIndex++}
                                    selectStyleId={_selectStyleId}
                                    setPreselectIndex={keyboardStyleStyleList.setPreselectIndex}
                                    trySetPreselectIndex={keyboardStyleStyleList.trySetPreselectIndex}
                                    recordEnterCallback={keyboardStyleStyleList.recordEnterCallback}
                                />
                            ))}
                        </div>
                        <div className={style.paddingBottom8}></div>
                    </div>
                ))}
                {!!unknownStyleGroup?.groups.size && (
                    <div key={unknownStyleGroup.docId}>
                        <div
                            className={classnames(style.h1, {
                                [style.splitLine]: !!showStyleMap.length,
                            })}
                        >
                            {unknownStyleGroup.libraryName}
                        </div>
                        {[...unknownStyleGroup.groups.entries()].map(([groupName, styles], index) => (
                            <div key={index}>
                                {groupName ? <div className={style.h2}>{groupName}</div> : null}
                                {styles.map((item) => (
                                    <UnknowStyleTextListItem
                                        key={item.id}
                                        item={item}
                                        docId={unknownStyleGroup.docId || ''}
                                        openStyleId={open && openStyleEditor ? styleIdEditor : undefined}
                                        onSelectItem={onClickApplyStyle}
                                        onClickHoverIcon={onClickHoverIcon}
                                        index={optionIndex++}
                                        selectStyleId={_selectStyleId}
                                        setPreselectIndex={keyboardStyleStyleList.setPreselectIndex}
                                        trySetPreselectIndex={keyboardStyleStyleList.trySetPreselectIndex}
                                        recordEnterCallback={keyboardStyleStyleList.recordEnterCallback}
                                    />
                                ))}
                                <div className={style.paddingBottom8}></div>
                            </div>
                        ))}
                    </div>
                )}
            </>
        )
    }

    return (
        <div>
            <DraggablePopupV2
                visible={open}
                position={position}
                closable={false}
                width={props.width ? props.width : 216}
                onFirstMove={onFirstMove}
                header={<div>{translation('TextStyle')}</div>}
                headerClassName={style.header}
                bodyClassName="p-0"
                footer={null}
                onCancel={props.onClose}
            >
                {isNoStyle ? (
                    <div className={style.emptyStyle}>
                        <div className={style.emptyStyle_text}>{translation('NoTextStyles')}</div>
                    </div>
                ) : (
                    <div className={style.contentContainer} ref={containerRef}>
                        <InputV2.Search
                            style={{ margin: 8 }}
                            onSearch={onChangeSearchInput}
                            autoFocus
                            onInput={keyboardStyleStyleList.onInput}
                            onKeyDown={keyboardStyleStyleList.onKeyDown}
                            data-testid={'text-style-search'}
                            placeholder={translation('Search')}
                        />
                        {noSearchResult ? (
                            <div className={style.emptyStyle}>
                                <div className={style.emptyStyle_text}>{translation('NoStylesMatch')}</div>
                            </div>
                        ) : (
                            <ScrollView
                                className={classnames(style.content)}
                                key={`${open}`}
                                selectKey={keyboardStyleStyleList.preselectIndex}
                                ref={keyboardStyleStyleList.scrollViewRef}
                                block="nearest"
                                scrollbar={{ autoHeight: true, autoHeightMin: 0, autoHeightMax: 352 }}
                            >
                                <KeyboardReceiver keyCode={ToKeyCode.All} onKeydown={keyboardStyleStyleList.onKeyDown}>
                                    {renderItems()}
                                </KeyboardReceiver>
                            </ScrollView>
                        )}
                    </div>
                )}
            </DraggablePopupV2>
            <TextStyleEditor
                styleId={styleIdEditor}
                visible={open && openStyleEditor}
                position={{
                    top: positionEditor?.top ?? 0,
                    left: (positionEditor?.left ?? 0) + 240 + (props.width ? props.width : 216),
                }}
                isRemoteStyle={false}
                onCancel={onCloseEditor}
                onClickCreate={onCloseEditor}
            />
        </div>
    )
}
