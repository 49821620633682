import classNames from 'classnames'
import { FC, HTMLAttributes, ReactNode, useMemo } from 'react'
import { MonoIconCommonClose16 } from '../../icons-v2'
import { WKIconButton } from '../wk-button/icon-button'
import { WKTypography } from '../wk-typography/index'
import { AlertColor } from './type'
import { getBackgroundColor, getCloseButtonType, getPresetIcon, getTitleFontColor } from './util'

interface WKAlertBaseProps extends HTMLAttributes<HTMLDivElement> {
    color?: AlertColor
    rounded?: boolean
    closeable?: boolean
    suffix?: ReactNode
    onClose?: () => void
    icon?: ReactNode
    iconWrapClassName?: string
    closeButtonTestId?: string
}

interface WithTitleProps extends Omit<WKAlertBaseProps, 'children'> {
    title: string
    desc: string
    descClassName?: string
    titleSize?: 12 | 14
}

export const WithTitle: FC<WithTitleProps> = (props) => {
    const {
        color,
        rounded,
        className,
        desc,
        descClassName,
        title,
        titleSize = 12,
        iconWrapClassName,
        closeable,
        suffix,
        icon,
        onClose,
        closeButtonTestId,
        ...rest
    } = props
    const bgClassname = getBackgroundColor(color)

    const presetIcon = useMemo(() => {
        switch (titleSize) {
            case 12:
                return getPresetIcon(color, 16)
            case 14:
                return getPresetIcon(color, 24)
        }
    }, [color, titleSize])
    const _icon = useMemo(() => {
        // 用户传入自定义图标
        if (icon) {
            return icon
        } else {
            if (icon == undefined) {
                // 如果没传就用预设图标
                return presetIcon
            }
            return null
        }
    }, [icon, presetIcon])
    const _iconWrapClassName = useMemo(() => {
        switch (titleSize) {
            case 12:
                return classNames('shrink-0 py-1.5', iconWrapClassName)
            case 14:
                return classNames('shrink-0 py-1', iconWrapClassName)
        }
    }, [iconWrapClassName, titleSize])
    const titleClassName = useMemo(() => {
        switch (titleSize) {
            case 12:
                return 'py-1'
            case 14:
                return 'py-5px'
        }
    }, [titleSize])

    const titleLevel = useMemo(() => {
        switch (titleSize) {
            case 12:
                return 5
            case 14:
                return 4
        }
    }, [titleSize])
    const titleFontColor = getTitleFontColor(color)
    const descFontColor = useMemo(() => {
        switch (color) {
            case 'default':
            case 'success':
            case 'warning':
            case 'error':
            case undefined:
            case 'deepYellow':
                return 'secondary'
            case 'deepBlue':
            case 'deepGreen':
            case 'deepRed':
                return 'white'
        }
    }, [color])

    const closebuttonType = getCloseButtonType(color)
    const closebuttonSize = useMemo(() => {
        switch (titleSize) {
            case 12:
                return 'medium'
            case 14:
                return 'large'
        }
    }, [titleSize])

    const _suffix = useMemo(() => {
        // 传入自定义后缀，自定义后缀要求默认垂直居中
        if (suffix) {
            return <div className="h-full flex items-center">{suffix}</div>
        } else {
            // 如果没传自定义后传 并且 要求展示关闭按钮
            if (closeable) {
                return (
                    <div>
                        <WKIconButton
                            data-testid={closeButtonTestId}
                            onClick={onClose}
                            size={closebuttonSize}
                            type={closebuttonType}
                            icon={<MonoIconCommonClose16 />}
                        ></WKIconButton>
                    </div>
                )
            } else {
                return null
            }
        }
    }, [closeable, closebuttonSize, closebuttonType, onClose, suffix, closeButtonTestId])
    return (
        <div
            className={classNames(
                'py-3 px-4 flex gap-2',
                rounded && 'rounded-$wk-radius-default',
                bgClassname,
                className
            )}
            {...rest}
        >
            {/* 图标 */}
            <div className={_iconWrapClassName}>{_icon}</div>
            <div className="flex flex-1 flex-col gap-2px">
                <WKTypography.Title color={titleFontColor} className={titleClassName} level={titleLevel}>
                    {title}
                </WKTypography.Title>
                <WKTypography.Paragraph color={descFontColor} className={descClassName}>
                    {desc}
                </WKTypography.Paragraph>
            </div>
            {_suffix && <div>{_suffix}</div>}
        </div>
    )
}

interface WithoutTitleProps extends WKAlertBaseProps {
    titleClassName?: string
    suffixIconSelfStart?: boolean
}
export const WithoutTitle: FC<WithoutTitleProps> = (props) => {
    const {
        className,
        titleClassName,
        color,
        rounded,
        icon,
        closeable,
        children,
        onClose,
        iconWrapClassName,
        suffix,
        suffixIconSelfStart,
        closeButtonTestId,
        ...rest
    } = props
    const presetIcon = getPresetIcon(color, 16)
    const bgClassname = getBackgroundColor(color)
    const _icon = useMemo(() => {
        // 用户传入自定义图标
        if (icon) {
            return icon
        } else {
            if (icon == undefined) {
                // 如果没传就用预设图标
                return presetIcon
            }
            return null
        }
    }, [icon, presetIcon])
    const closebuttonType = getCloseButtonType(color)
    const fontColor = getTitleFontColor(color)
    const _suffix = useMemo(() => {
        // 传入自定义后缀
        if (suffix) {
            return <div className={suffixIconSelfStart ? 'flex py-1.5 self-start' : undefined}>{suffix}</div>
        } else {
            // 如果没传自定义后传 并且 要求展示关闭按钮
            if (closeable) {
                return (
                    <div>
                        <WKIconButton
                            data-testid={closeButtonTestId}
                            onClick={onClose}
                            type={closebuttonType}
                            icon={<MonoIconCommonClose16 />}
                        ></WKIconButton>
                    </div>
                )
            } else {
                return null
            }
        }
    }, [suffix, suffixIconSelfStart, closeable, closeButtonTestId, onClose, closebuttonType])
    return (
        <div
            className={classNames(
                'p-2 flex gap-2 items-center',
                rounded && 'rounded-$wk-radius-default',
                bgClassname,
                className
            )}
            {...rest}
        >
            <div className={classNames('flex pl-2 py-1.5', iconWrapClassName)}>{_icon}</div>
            <WKTypography.Paragraph color={fontColor} className={classNames('flex-1 wk-text-12 py-1', titleClassName)}>
                {children}
            </WKTypography.Paragraph>
            {_suffix}
        </div>
    )
}
