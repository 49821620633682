import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Space: '范围：',
    Owner: '所有者：',
    SortBy: '排序：',
    AllSpace: '所有空间',
    CurrentFolder: '当前项目',
    CurrentTeam: '当前团队',
    AnyUser: '任意用户',
    Myself: '我自己',
    Similarity: '相关度',
    EditTime: '最近修改',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    Space: 'Space: ',
    Owner: 'Owner: ',
    SortBy: 'Sort by: ',
    AllSpace: 'All',
    CurrentFolder: 'Current Project',
    CurrentTeam: 'Current Team',
    AnyUser: 'Any user',
    Myself: 'Myself',
    Similarity: 'Relevance',
    EditTime: 'Last modified',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
