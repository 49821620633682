import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    NoSelected: '当前选中范围内没有可导出的切图, 请在右边栏的导出模块中点击 + 添加导出项',
    Selected: '已选中（{{numerator}}/{{denominator}}）',
    Export: '导出',
    Export_synonyms1: '批量导出',
} as const

export const enTranslation = {
    NoSelected:
        'The selection doesn’t have any export settings yet. Click + in the export section of the properties panel to add one.',
    Selected: '{{numerator}} of {{denominator}} selected',
    Export: 'Export',
    Export_synonyms1: 'Export',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string | number>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
