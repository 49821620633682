import { Wukong } from '@wukong/bridge-proto'
import { useMemo } from 'react'
import { useViewState } from '../../../../view-state-bridge'

export function useFieldConsumptionFloatVariable({
    field,
}: {
    field: Wukong.DocumentProto.VariableField
}): Wukong.DocumentProto.ISingleFloatVariable | null {
    const variableConsumptionMapState = useViewState('variableConsumptionMapState')
    return useMemo(() => {
        return getFieldConsumptionFloatVariable(field, variableConsumptionMapState)
    }, [variableConsumptionMapState, field])
}

export function getFieldConsumptionFloatVariable(
    field: Wukong.DocumentProto.VariableField,
    variableConsumptionMapState?: Wukong.DocumentProto.IVariableConsumptionMapState
): Wukong.DocumentProto.ISingleFloatVariable | null {
    if (!variableConsumptionMapState) {
        return null
    }

    const spaceItem = variableConsumptionMapState.items.find((item) => item.field === field)
    if (!spaceItem) {
        return null
    }

    return (
        variableConsumptionMapState.floatVariables.find((item) => item.id === spaceItem.variableData?.value.alias) ??
        null
    )
}
