import { ExplicitUndefinedSome } from '../../../../../../ui-lib/src'
import {
    ResourceType,
    RoleStatus,
    SeatType,
    TriggerSeatApplicationSceneVO,
    UserBriefVO,
} from '../../../../kernel/interface/type'
import { PayApplyDialogContainerProps } from './pay-apply-dialog-container'

// 弹窗类型
export enum PayApplyDialogType {
    AutoUpgrade = 'autoUpgrade',
    Admin = 'admin',
    Normal = 'normal',
    Invite = 'invite',
}

// 席位类型
export enum PayApplyDialogSeatType {
    Design = 'design',
    Dev = 'dev',
}

// 席位类型
export enum PayApplyDialogUserType {
    Normal = 'normal', // 普通用户
    External = 'external', // 外部用户
}

export interface ExternalUserInfo {
    type: PayApplyDialogUserType.External
}

export interface NormalUserInfo {
    type: PayApplyDialogUserType.Normal
}

export interface PayApplyDialogCommonProps {
    seatType: PayApplyDialogSeatType
}

export interface AutoPayApplyProps extends PayApplyDialogCommonProps {
    type: PayApplyDialogType.AutoUpgrade
    dialogProps: ExplicitUndefinedSome<PayApplyDialogContainerProps, 'title' | 'okText'>
    description: React.ReactNode // 一般使用默认的也就是undefined，但极个别场景需要自定义
    httpProps: {
        resourceType: ResourceType
        resourceId: string
        triggerScene: TriggerSeatApplicationSceneVO
    }
}

export interface AdminPayApplyProps extends PayApplyDialogCommonProps {
    type: PayApplyDialogType.Admin
    dialogProps: ExplicitUndefinedSome<PayApplyDialogContainerProps, 'title' | 'okText'>
    description: React.ReactNode // 不同的调用地方文案不一样
    httpProps: {
        resourceType: ResourceType
        resourceId: string
        triggerScene: TriggerSeatApplicationSceneVO
    }
}

export interface NormalPayApplyProps extends PayApplyDialogCommonProps {
    type: PayApplyDialogType.Normal
    dialogProps?: PayApplyDialogContainerProps
    descriptionPrefix: React.ReactNode // 操作描述。一般是字符串
    isAllowGracePeriod: boolean //是否有宽限期
    isEnterprise: boolean // 是否是高级企业版
    userInfo: ExternalUserInfo | NormalUserInfo
    unresolvedSeatTypes: SeatType[]
    repeatDialogProps: {
        dialogTitle: string // 这里要求必填。因为不同的调用地方文案不一样
    }
    httpProps: {
        resourceType: ResourceType
        resourceId: string
        triggerScene: TriggerSeatApplicationSceneVO
        orgId: string // 高级企业版需要 orgId
    }
}
export interface InvitePayApplyProps extends PayApplyDialogCommonProps {
    type: PayApplyDialogType.Invite
    dialogProps: ExplicitUndefinedSome<PayApplyDialogContainerProps, 'title' | 'okText'>
    users: UserBriefVO[] // 邀请的用户信息
    isHideUserList: boolean
    description: React.ReactNode // 不同的调用地方文案不一样
    httpProps: {
        resourceType: ResourceType
        resourceId: string
        role: RoleStatus
        inviteRedirectUrl: string | undefined
    }
}

export type PayApplyDialogStructProps =
    | AutoPayApplyProps
    | AdminPayApplyProps
    | NormalPayApplyProps
    | InvitePayApplyProps
