import { useMemo } from 'react'
import { useViewState } from '../../../../view-state-bridge'
import { getModeList, ModeItem } from '../comp/mode-item'
import style from '../inspect.module.less'
import { translation } from './index.translation'

export function InspectMode() {
    const variableModeEntries = useViewState('variableModeEntriesInSelect')
    const modes = useMemo(() => {
        return getModeList(variableModeEntries)
    }, [variableModeEntries])

    if (!modes.length) {
        return null
    }

    return (
        <div className={style.panel}>
            <div className={style.panelTitle}>
                <div className={style.title} data-testid="inspect-mode">
                    {translation('Modes')}
                </div>
            </div>
            {modes.map((mode) => (
                <ModeItem key={`${mode.collectionName}-${mode.modeName}`} mode={mode} />
            ))}
        </div>
    )
}
