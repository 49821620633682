/* eslint-disable no-restricted-imports */
import { useCallback, useState } from 'react'
import { NodeId } from '../../../../../document/node/node'
import { useCommand } from '../../../../context/document-context'
import { getNodesPreviewBase64ImageWithFixedSize, PreviewImageSize } from '../util'

export function useNodePreviewBase64() {
    const command = useCommand()
    const [base64, setBase64] = useState<string | null>(null)

    const reFetch = useCallback(
        (ids: NodeId[], size: PreviewImageSize) => {
            const img = getNodesPreviewBase64ImageWithFixedSize(command, ids, size)
            if (img) {
                setBase64(img)
                return img
            }
            return null
        },
        [command]
    )

    return {
        base64,
        reFetch,
        setBase64,
    }
}
