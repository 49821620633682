import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    ConstrainProportions: '锁定比例',
    MinWidthPlaceHolder: '最小值',
    MaxWidthPlaceHolder: '最大值',
    MinHeightPlaceHolder: '最小值',
    MaxHeightPlaceHolder: '最大值',
    MinWidthToolTip: '宽度最小值',
    MaxWidthToolTip: '宽度最大值',
    MinHeightToolTip: '高度最小值',
    MaxHeightToolTip: '高度最大值',
    AddMinWidth: '添加最小值...',
    AddMaxWidth: '添加最大值...',
    RemoveMinWidth: '移除最小值',
    RemoveMaxWidth: '移除最大值',
    AddMinHeight: '添加最小值...',
    AddMaxHeight: '添加最大值...',
    RemoveMinHeight: '移除最小值',
    RemoveMaxHeight: '移除最大值',
} as const

export const enTranslation = {
    ConstrainProportions: 'Constrain proportions',
    MinWidthPlaceHolder: 'Min W',
    MaxWidthPlaceHolder: 'Max W',
    MinHeightPlaceHolder: 'Min H',
    MaxHeightPlaceHolder: 'Max H',
    MinWidthToolTip: 'Min width',
    MaxWidthToolTip: 'Max width',
    MinHeightToolTip: 'Min height ',
    MaxHeightToolTip: 'Max height',
    AddMinWidth: 'Add min width...',
    AddMaxWidth: 'Add max width...',
    RemoveMinWidth: 'Remove min width',
    RemoveMaxWidth: 'Remove max width',
    AddMinHeight: 'Add min height...',
    AddMaxHeight: 'Add max height...',
    RemoveMinHeight: 'Remove min height',
    RemoveMaxHeight: 'Remove max height',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
