import { ClogDomain, Environment, HttpPrefixKey } from '../types'

export const formalAbroadProductionEnvironment: Environment = {
    isAbroad: true,
    isDev: false,
    isProduction: true,
    googleClientId: '798541415482-lr7c5veb7rkga1g9sdimlvfs6npj2npt.apps.googleusercontent.com',
    host: 'https://motiff.com',
    wssPrefix: 'wss://motiff-synergy.motiff.com/synergy',
    ossCdnPath: 'https://static.motiffcontent.com/',
    publicResourcePrefix: 'app/public/resource',
    sentryDsn: 'https://b6da2edcfaae4e5d9ed27fb7f6e7fd9c@o1401900.ingest.sentry.io/6733320',
    clogConfig: {
        site: 'motiff',
        domain: ClogDomain.Com,
        queryUrl: 'https://octopus.zhenguanyu.com/#/logs/search?env=online',
    },
    featureSwitchEnv: 'production',
    httpPrefixes: {
        [HttpPrefixKey.COMMON_API]: 'https://api.motiff.com/wukong-api/api',
        [HttpPrefixKey.NOTIFY_API]: 'https://api.motiff.com/wukong-notify/api',
        [HttpPrefixKey.SYNERGY_CONNECTOR_API]: 'https://api.motiff.com/motiff-synergy-connector',
        [HttpPrefixKey.SYNERGY_ADMIN_API]: 'https://api.motiff.com/motiff-coordinator/admin',
        [HttpPrefixKey.ADMIN_API]: 'https://api-inner.motiff.com/wukong-api-admin/api/',
        [HttpPrefixKey.INNER_API]: 'https://api-inner.motiff.com/wukong-api/api',
        [HttpPrefixKey.BOLT_API]: 'https://bolt.yuanfudao.com/bolt-pay-gateway/api',
        [HttpPrefixKey.AI_GEN_API]: 'https://motiff-ai-gen.motiff.com/api',
    },
    availableHostsForEmailLoginApi: ['motiff.com', 'beta.motiff.com', 'staging.motiff.com'],
    uiPrefixCls: 'wukong-ui',
    figmaClientId: 'i4IKWddXv3PbTL9bxDd4NT',
    figmaCallbackUrl: 'https://motiff.com/figma-oauth',
    mirrorBasePath: 'mirror',
    notifyWssPrefix: 'wss://motiff-notify.motiff.com/notify-connector',
    mirrorWssPrefix: 'wss://api.motiff.com/motiff-preview-connector/preview',
    fontPluginDaemonHost: 'https://daemon.motiff.com',
    fontPluginLocalHost: 'http://127.0.0.1',
    onboardingHelpDocUrl: {
        frame: 'https://motiff.com/help/docs/articles/38036382480896',
        vector: 'https://motiff.com/help/docs/articles/37711961443072',
        comment: 'https://motiff.com/help/docs/sections/37712087272192',
        share: 'https://motiff.com/help/docs/sections/37711902722816',
        coator: 'https://motiff.com/help/docs/sections/49569364184328',
        library: 'https://motiff.com/help/docs/articles/37712074689280',
        prototype: 'https://motiff.com/help/docs/sections/232648479013384',
        device: 'https://motiff.com/help/docs/sections/232662316033280',
        devModeStart: 'https://motiff.com/help/docs/sections/232648479013382#4-code%20settings',
        devmodeInspect: 'https://motiff.com/help/docs/sections/232648479013382#3-advanced%20inspect',
        devmodeExport: 'https://motiff.com/help/docs/sections/232648479013382#7-export',
        variable: 'https://motiff.com/help/docs/articles/305390387245587',
    },
    helpDocsUrl: {
        home: 'https://motiff.com/help/docs',
        color: 'https://motiff.com/help/docs/sections/64851546582028',
        image: 'https://motiff.com/help/docs/articles/37711974025984',
        component: 'https://motiff.com/help/docs/articles/37712032746240',
        webgl: 'https://motiff.com/help/others/49991877417216',
        aiCredit: 'https://motiff.com/help/docs/sections/232928398489604',
        quota: 'https://motiff.com/help/others/266701503819272',
        ssoPath: '/help/docs/sections/267353940428032',
        stateManagement: '',
        motiffAPI: '',
        variable: 'https://motiff.com/help/docs/articles/305390387245589',
        organizationGuest: 'https://motiff.com/help/docs/sections/101470896999680',
    },
}
