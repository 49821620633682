import {
    CheckShouldBlockDevMode,
    GetShowEditorUnAccessibleBannerFromJs,
    SwitchEditorTypeCommand,
    UpdateUserPlanStatus,
    Wukong,
} from '@wukong/bridge-proto'
import { createSelectors, createStore } from '../../../../../util/src'

import { TraceableAbortSignal } from '../../../../../util/src/abort-controller/traceable-abort-controller'
import { EffectController } from '../../../../../util/src/effect-controller'
import { CommandInvoker } from '../../../document/command/command-invoker'
import { CommitType } from '../../../document/command/commit-type'
import { Ticket } from '../../../document/synergy/synergy-ticket'
import { getURLSearchParams } from '../../../document/util/url'
import { IN_JEST_TEST } from '../../../environment'
import { EmBridge } from '../../../kernel/bridge/em-bridge'
import { MessageContentType } from '../../../kernel/interface/notify'
import {
    DocID,
    DocWithAuthorityVO,
    OrgID,
    PlanAndUserStatesInfo,
    PlanAndUserStateVO,
    ResourceType,
    SettlementVO,
    UserID,
} from '../../../kernel/interface/type'
import { WsPropertyChangeMessage, WsRelationChangeMessage } from '../../../kernel/notify/notify-message'
import { NotifyService } from '../../../kernel/notify/notify-service'
import { GetAllPayExpiredSettlements } from '../../../kernel/request/contract'
import { getDocRequest } from '../../../kernel/request/document'
import { QueryOrgAndUserState } from '../../../kernel/request/organizations'
import { QueryTeamAndUserState } from '../../../kernel/request/team'
import { featureSwitchManager } from '../../../kernel/switch'
import { ServiceClass } from '../../../kernel/util/service-class'
import {
    getAlertOfDevModeUnavailableInfo,
    getExceedUsageLimitForFreeTeamShown,
    getFreezeAlertInEditorShown,
} from '../../../ui/component/top-area/payment-alert-banner'
import { getDevModeAccessibleByUserStatus, getUserRole, UserRole } from '../../../utils/payment'
import { createFileManager } from '../../create-file-manager'

const TWO_HOURS = 2 * 60 * 60 * 1000

export interface PlanAndUserStateZustandStore {
    planInAllTeams: PlanAndUserStateVO[]
    planAndUserState: PlanAndUserStateVO | undefined
    modeAccessible: { isDevModeAccessible: boolean }
    hasLoadedUserPlanInfo: boolean
    showDevModeBlockedModal: boolean
    userRole: UserRole
    unpaidSettlementsMap: { [key: string]: SettlementVO[] }
}

export class PlanAndUserStateService extends ServiceClass {
    private privateDocInfo: DocWithAuthorityVO | undefined
    private shouldCheckDevModeAccessible = false
    private unpaidReqTimer: NodeJS.Timeout | undefined
    private unSubscribe: () => void = () => {}
    private docId: DocID

    // v2
    private zustandStore = createStore<PlanAndUserStateZustandStore>(() => ({
        planInAllTeams: [],
        planAndUserState: undefined,
        modeAccessible: { isDevModeAccessible: false },
        hasLoadedUserPlanInfo: false,
        showDevModeBlockedModal: false,
        userRole: UserRole.Guest,
        unpaidSettlementsMap: {},
    }))
    useZustandStore = createSelectors(this.zustandStore)

    constructor(
        private readonly signal: TraceableAbortSignal,
        private commandInvoker: CommandInvoker,
        protected override readonly bridge: EmBridge,
        private readonly userId: UserID,
        private readonly isPrototype = false, // 原型预览
        private readonly isSandbox = false, // 沙盒环境
        controller: EffectController,
        private readonly ticket: Ticket,
        private readonly notify: NotifyService,
        private readonly planAndUserStatesInfo?: PlanAndUserStatesInfo
    ) {
        super(bridge)
        controller.onCleanup(() => this.destroy())

        this.docId = ticket.docId

        this.notify.sendSubscribeProto(this.ticket.orgId, subscribeParam(this.ticket.orgId, this.userId))
        this.notify.onConnectChangeWithSignal(this.signal, ({ sessionId }) => {
            if (sessionId) {
                this.notify.sendSubscribeProto(this.ticket.orgId, subscribeParam(this.ticket.orgId, this.userId))
            }
        })

        this.bindJsCall(GetShowEditorUnAccessibleBannerFromJs, (isDevMode) => {
            return { value: this.isShowEditorUnAccessibleBannerInFirstTick(!!isDevMode.value) }
        })
        this.bindJsCall(CheckShouldBlockDevMode, () => {
            return { value: this.checkShouldBlockDevMode() }
        })

        if (!this.isSandbox) {
            this.injectCreateFileCallBack(() => {
                this.initPlanAndUserState()
                this.initNotifyChangeListeners()
            })

            // 服务端暂时没有账单数据的通知下发，因此先定时轮询调用接口更新数据「每两小时一次」
            if (!IN_JEST_TEST) {
                // Jest 中运行该代码会导致测试超时，在 Jest 环境下不启动定时器
                this.unpaidReqTimer = setInterval(() => {
                    this.generateUnpaidSettlements()
                }, TWO_HOURS)
            }
        }
    }

    public override destroy() {
        super.destroy()
        this.unSubscribe()
        this.unpaidReqTimer && clearInterval(this.unpaidReqTimer)
    }

    private injectCreateFileCallBack(fn: () => void) {
        if (createFileManager.isCreatingFile()) {
            createFileManager.injectCreateFileCallBack(fn)
        } else {
            fn()
        }
    }

    // 设置监听 ws 下行消息
    private initNotifyChangeListeners = () => {
        // 关系变更
        const planChanges = (change: Wukong.NotifyProto.BusinessEntityRelationChange | undefined): boolean => {
            return (
                change?.relation?.relation === Wukong.NotifyProto.Relation.AUTHORIZATION &&
                change?.changeType === Wukong.NotifyProto.RelationChangeType.UPDATE &&
                (change?.relation?.one?.entityType === Wukong.NotifyProto.EntityType.TEAM ||
                    change?.relation?.one?.entityType === Wukong.NotifyProto.EntityType.ORG) &&
                change?.relation?.another?.entityType === Wukong.NotifyProto.EntityType.USER &&
                change?.relation?.another?.entityId === this.userId + ''
            )
        }

        const currentDocBelongToChange = (
            change: Wukong.NotifyProto.BusinessEntityRelationChange | undefined
        ): boolean => {
            return (
                change?.relation?.relation === Wukong.NotifyProto.Relation.BELONG_TO &&
                change?.relation?.one?.entityType === Wukong.NotifyProto.EntityType.DOC &&
                change?.relation?.one?.entityId === this.docId &&
                change?.relation?.another?.entityType === Wukong.NotifyProto.EntityType.FOLDER
            )
        }

        const currentAuthorizationChange = (
            change: Wukong.NotifyProto.BusinessEntityRelationChange | undefined
        ): boolean => {
            return (
                change?.changeType === Wukong.NotifyProto.RelationChangeType.UPDATE &&
                change?.relation?.relation === Wukong.NotifyProto.Relation.AUTHORIZATION &&
                change?.relation?.one?.entityType === Wukong.NotifyProto.EntityType.USER &&
                change?.relation?.one?.entityId === this.userId + '' &&
                change?.relation?.another?.entityType === Wukong.NotifyProto.EntityType.DOC
            )
        }
        // 属性变更
        const propertyChange = (change: Wukong.NotifyProto.BusinessEntityPropertiesChange | undefined): boolean => {
            if (
                change?.businessEntity?.entityType === Wukong.NotifyProto.EntityType.ORG ||
                change?.businessEntity?.entityType === Wukong.NotifyProto.EntityType.TEAM
            ) {
                // 对于 name 和 icon 的修改，直接忽略
                if (change.changedProperties.name || change.changedProperties.icon) {
                    return false
                }

                // 对于 publicStatus 的修改，只关心文件所在的团队
                if (
                    !!change.changedProperties.publicStatus &&
                    change.businessEntity.entityId !== this.privateDocInfo?.teamId
                ) {
                    return false
                }

                // 对于其他团队下的变更通知，只关心当前用户有席位的团队的变更通知，其他情况不再发无效请求
                if (
                    change.businessEntity.entityType === Wukong.NotifyProto.EntityType.TEAM &&
                    change.businessEntity.ancestors?.[0].entityType === Wukong.NotifyProto.EntityType.ORG &&
                    change.businessEntity.ancestors?.[0].entityId === '-1'
                ) {
                    const planTeams = this.zustandStore.getState().planInAllTeams
                    const isInPlanTeams = planTeams.some(
                        (plan) =>
                            plan.resourceType === ResourceType.Team &&
                            plan.resourceId === change.businessEntity?.entityId
                    )
                    if (!isInPlanTeams) {
                        return false
                    }
                }
                return true
            } else {
                return false
            }
        }
        // 工作区管理员身份变更
        const workspaceUserAuthorizationChange = (
            change: Wukong.NotifyProto.BusinessEntityRelationChange | undefined
        ): boolean => {
            if (!featureSwitchManager.isEnabled('organization-plus')) {
                return false
            }
            const isRemoveWorkspace =
                change?.changeType === Wukong.NotifyProto.RelationChangeType.REMOVE &&
                change?.relation?.relation == Wukong.NotifyProto.Relation.BELONG_TO &&
                change?.relation?.one?.entityType == Wukong.NotifyProto.EntityType.WORKSPACE &&
                change?.relation?.another?.entityType == Wukong.NotifyProto.EntityType.ORG
            const isWorkspaceUserAuthUpdated =
                change?.changeType === Wukong.NotifyProto.RelationChangeType.UPDATE &&
                change?.relation?.relation == Wukong.NotifyProto.Relation.AUTHORIZATION &&
                change?.relation?.one?.entityType == Wukong.NotifyProto.EntityType.WORKSPACE &&
                change?.relation?.another?.entityType == Wukong.NotifyProto.EntityType.USER
            return isRemoveWorkspace || isWorkspaceUserAuthUpdated
        }

        this.notify.onBusinessMessageChangeWithSignal(this.signal, (proto) => {
            let change = false
            if (proto.businessCode === WsRelationChangeMessage.code) {
                const body = WsRelationChangeMessage.bodyType.decode(proto.payload)
                change =
                    planChanges(body) ||
                    currentDocBelongToChange(body) ||
                    currentAuthorizationChange(body) ||
                    workspaceUserAuthorizationChange(body)
            } else if (proto.businessCode === WsPropertyChangeMessage.code) {
                const body = WsPropertyChangeMessage.bodyType.decode(proto.payload)
                change = propertyChange(body)
            }

            if (change) {
                this.generateUserPlanAndState(this.docId)
            }
        })
    }

    // 发送研发模式状态到 wasm
    private sendDevAccessibleToWasm = (newValue: { isDevModeAccessible: boolean } | undefined) => {
        if (newValue) {
            this.commandInvoker.invokeBridge(
                CommitType.Noop,
                UpdateUserPlanStatus,
                Wukong.DocumentProto.PaymentUserPlanStatus.create({
                    devModeAvailable: newValue.isDevModeAccessible,
                })
            )
        }
    }

    public initModeStatus = () => {
        this.unSubscribe = this.zustandStore.subscribe(
            (state) => state.modeAccessible,
            (modeAccessible) => {
                this.sendDevAccessibleToWasm(modeAccessible)
            }
        )
        this.sendDevAccessibleToWasm(this.zustandStore.getState().modeAccessible)
    }

    public setShowDevModeBlockedModal = (value: boolean) => {
        this.zustandStore.setState({
            showDevModeBlockedModal: value,
        })
    }

    private checkShouldBlockDevMode = () => {
        if (this.privateDocInfo) {
            const draftInOtherTeams = this.privateDocInfo.draft && this.privateDocInfo.orgId === '-1'
            if (draftInOtherTeams) {
                this.setShowDevModeBlockedModal(true)
            }
            return draftInOtherTeams
        }

        const planAndUserStatesInfo = this.planAndUserStatesInfo
        if (planAndUserStatesInfo && this.ticket) {
            const draftInOtherTeams = planAndUserStatesInfo.docIsDraft && this.ticket.orgId === '-1'
            if (draftInOtherTeams) {
                this.setShowDevModeBlockedModal(true)
            }
            return draftInOtherTeams
        }
        // 没有找到席位信息，没有获取到文本数据，程序走到此的情况为：新建草稿且在没有获取文档数据前进入了研发模式
        this.shouldCheckDevModeAccessible = true
        return false
    }

    private initPlanAndUserState = async () => {
        if (this.planAndUserStatesInfo) {
            this.zustandStore.setState({
                planAndUserState: this.planAndUserStatesInfo.doc,
                planInAllTeams: this.planAndUserStatesInfo.teams,
                hasLoadedUserPlanInfo: true,
            })
        }
        await this.generateUserPlanAndState(this.docId, !this.planAndUserStatesInfo)
        this.generateUnpaidSettlements()
    }

    private isShowEditorUnAccessibleBannerInFirstTick = (isDevMode: boolean) => {
        const planAndUserState = this.zustandStore.getState().planAndUserState
        return (
            getAlertOfDevModeUnavailableInfo(planAndUserState, isDevMode, this.userId).show ||
            getFreezeAlertInEditorShown(isDevMode, this.ticket) ||
            getExceedUsageLimitForFreeTeamShown(isDevMode, this.ticket)
        )
    }

    // 该请求函数前置依赖「当前履约套餐信息」，在编辑器内若要获取最新付费信息，需要再次调用该函数
    public generateUnpaidSettlements = async () => {
        const planInfo = this.zustandStore.getState().planAndUserState
        if (!planInfo) {
            return
        }

        const { resourceType, resourceId } = planInfo

        if (resourceType && resourceId) {
            const res = await new GetAllPayExpiredSettlements(
                resourceType as ResourceType.Organization | ResourceType.Team,
                [resourceId]
            ).start()
            this.zustandStore.setState({ unpaidSettlementsMap: res })
        }
    }

    public generateUserPlanAndState = async (docId: string, refetchPayment = true) => {
        const docInfo = await getDocRequest(docId, this.isPrototype)
        this.privateDocInfo = docInfo

        // 新建草稿进入编辑器到真正获取文档数据，用户可能进入了研发模式！这是需要退出并弹出弹窗
        if (this.shouldCheckDevModeAccessible) {
            if (getURLSearchParams().editorType === 'dev' && docInfo.orgId == '-1' && docInfo.draft) {
                this.commandInvoker.invokeBridge(CommitType.Noop, SwitchEditorTypeCommand)
            }
            this.shouldCheckDevModeAccessible = false
        }

        // fetch payment state
        if (refetchPayment) {
            if (docInfo.orgId !== '-1') {
                await new QueryOrgAndUserState(docInfo.orgId).start().then((res) => {
                    this.zustandStore.setState({ planAndUserState: res })
                })
            } else {
                await new QueryTeamAndUserState().start().then((res) => {
                    this.zustandStore.setState({
                        planInAllTeams: res,
                        planAndUserState: docInfo.teamId
                            ? res.find(
                                  (plan) =>
                                      plan.resourceType === ResourceType.Team && plan.resourceId === docInfo.teamId
                              )
                            : undefined,
                    })
                })
            }
        }

        // fetch user role
        const userRole = await getUserRole(docInfo.orgId, docInfo.teamId ?? '')
        this.zustandStore.setState({ hasLoadedUserPlanInfo: true, userRole: userRole })

        // mode accessible
        this.processAccessible()
    }

    private processAccessible = () => {
        const isDevModeAccessible = getDevModeAccessibleByUserStatus(this.zustandStore.getState().planAndUserState)
        this.zustandStore.setState({
            modeAccessible: { isDevModeAccessible: isDevModeAccessible },
        })
    }
}

const subscribeParam = (orgId: OrgID, userId: UserID) => {
    return {
        [MessageContentType.RelationChange]: {
            filters: [
                {
                    filterParameters: [
                        {
                            name: 'entityType',
                            value: Wukong.NotifyProto.EntityType.ORG - 1 + '',
                        },
                        {
                            name: 'entityId',
                            value: orgId,
                        },
                    ],
                },
                {
                    filterParameters: [
                        {
                            name: 'entityType',
                            value: Wukong.NotifyProto.EntityType.USER - 1 + '',
                        },
                        {
                            name: 'entityId',
                            value: userId + '',
                        },
                    ],
                },
            ],
        },
        [MessageContentType.PropertyChange]: {
            filters: [
                {
                    filterParameters: [
                        {
                            name: 'entityType',
                            value: Wukong.NotifyProto.EntityType.ORG - 1 + '',
                        },
                        {
                            name: 'entityId',
                            value: orgId,
                        },
                    ],
                },
            ],
        },
    }
}
