import { ReplayEventEmitter } from './replay-event-emitter'

/**
 * @experimental 用于替换Subject
 */
export class SimpleEventEmitter<T> extends ReplayEventEmitter<T> {
    constructor() {
        super(0)
    }
}
