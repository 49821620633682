import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    CreateBoolProp: '创建布尔属性',
    ApplyBoolProp: '应用布尔属性',
    CreateProp: '创建属性...',
} as const

export const enTranslation = {
    CreateBoolProp: 'Create boolean property',
    ApplyBoolProp: 'Apply boolean property',
    CreateProp: 'Create property...',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
