import { Wukong } from '@wukong/bridge-proto'
import { getFieldItem } from '../../../variable/float-variable/utils'

export function isAnyStrokeWeightMixed(
    variableConsumptionMapState?: Wukong.DocumentProto.IVariableConsumptionMapState
) {
    if (!variableConsumptionMapState) {
        return false
    }
    const strokeWeightItems = [
        Wukong.DocumentProto.VariableField.STROKE_WEIGHT,
        Wukong.DocumentProto.VariableField.BORDER_TOP_WEIGHT,
        Wukong.DocumentProto.VariableField.BORDER_RIGHT_WEIGHT,
        Wukong.DocumentProto.VariableField.BORDER_BOTTOM_WEIGHT,
        Wukong.DocumentProto.VariableField.BORDER_LEFT_WEIGHT,
    ].map((field) => getFieldItem(field, variableConsumptionMapState))

    return strokeWeightItems.some(
        (item) => item?.type === Wukong.DocumentProto.VariableConsumptionItemType.VARIABLE_CONSUMPTION_ITEM_TYPE_MIXED
    )
}
