/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import React, { useCallback } from 'react'
import classes from './rectangle-schedule.module.less'

export interface RectangleScheduleProps {
    className?: string
    style?: React.CSSProperties
    sumStep: number
    keyStep: number // 这步开始变色
    currentStep: number
}

export function RectangleSchedule(props: RectangleScheduleProps) {
    const { className, style, sumStep, currentStep, keyStep } = props

    const getBlockColor = useCallback(
        (step: number) => {
            if (step > currentStep) {
                return 'gray'
            }
            if (currentStep >= keyStep) {
                return 'yellow'
            }
            return 'blue'
        },
        [currentStep, keyStep]
    )

    return (
        <div className={classnames(classes.rectangleSchedule, className)} style={style}>
            {Array.from({ length: sumStep }).map((_, index) => (
                <RectangleScheduleBlock key={index} color={getBlockColor(index + 1)}></RectangleScheduleBlock>
            ))}
        </div>
    )
}

interface RectangleScheduleBlockProps {
    color?: 'gray' | 'blue' | 'yellow'
}
function RectangleScheduleBlock(props: RectangleScheduleBlockProps) {
    const { color = 'gray' } = props
    return <div className={classnames(classes.block, classes[color])}></div>
}
