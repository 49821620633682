import { MotiffApi } from '@motiffcom/plugin-api-types'
import {
    MarkEvalJsBeginCommand,
    MarkEvalJsEndCommand,
    PluginApiEventNotifyCommandJSV2,
    PluginApiSetEventNotificationStatus,
    PluginCancelEventNotifierCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { IBasicBridge } from '../kernel/bridge/basic-bridge'
import { origin2String, property2String } from './plugin-event-utils'

type EventName =
    | 'documentchange'
    | 'selectionchange'
    | 'nodechange'
    | 'message'
    | 'stylechange'
    | 'run'
    | 'close'
    | 'currentpagechange'
    | 'drop'
    | 'generate'
    | 'preferenceschange'

const debounce = (callback: (...args: any[]) => void, wait = 100) => {
    let timeoutId: NodeJS.Timeout | null = null

    return (...args: any[]) => {
        if (timeoutId) {
            clearTimeout(timeoutId)
        }

        timeoutId = setTimeout(() => {
            callback(...args)
        }, wait)
    }
}

const type2EventName: { [key: number]: EventName } = {
    [Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_DOCUMENT_CHANGE]: 'documentchange',
    [Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_SELECTION_CHANGE]: 'selectionchange',
    [Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_RUN]: 'run',
    [Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_CLOSE]: 'close',
    [Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_CURRENT_PAGE_CHANGE]: 'currentpagechange',
    [Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_DROP]: 'drop',
    [Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_NODE_CHANGE]: 'nodechange',
}

export function enableEventDispatchV2(
    bridge: IBasicBridge,
    documentChangeCallback: (
        e: { ownerPage?: string; type: string; origin: string; id: string; properties?: string[] }[]
    ) => void,
    nodeChangeCallback: (
        e: { ownerPage?: string; type: string; origin: string; id: string; properties?: string[] }[]
    ) => void,
    selectionCallback: () => void,
    pageCallback: () => void,
    eventHandlers: Map<EventName, unknown[]> // 仅需感知特定 EventName 的 EventHandler 数量
) {
    // eslint-disable-next-line prefer-const
    let eventCollection: Wukong.DocumentProto.IArg_PluginEvent[] = []

    const debouncedEventHandler = debounce(() => {
        bridge.call(
            PluginApiSetEventNotificationStatus,
            Wukong.DocumentProto.Arg_PluginEventNotificationStatus.create({
                status: Wukong.DocumentProto.PluginEventNotificationStatus
                    .PLUGIN_EVENT_NOTIFICATION_STATUS_EXECUTING_CALLBACK,
            })
        )
        bridge.call(MarkEvalJsBeginCommand)

        eventCollection.forEach(({ documentChanges, type }) => {
            executeEventCallbacks(type, documentChanges)
            // cancel event notifier if no available handler existed
            if (eventHandlers.get(type2EventName[type])?.length === 0) {
                bridge.call(
                    PluginCancelEventNotifierCommand,
                    Wukong.DocumentProto.Arg_MonitorEvents.create({
                        event: [type],
                    })
                )
            }

            // nodechange
            if (
                type === Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_DOCUMENT_CHANGE &&
                eventHandlers.get('nodechange')?.length === 0
            ) {
                bridge.call(
                    PluginCancelEventNotifierCommand,
                    Wukong.DocumentProto.Arg_MonitorEvents.create({
                        event: [Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_NODE_CHANGE],
                    })
                )
            }
        })

        bridge.call(MarkEvalJsEndCommand)
        bridge.call(
            PluginApiSetEventNotificationStatus,
            Wukong.DocumentProto.Arg_PluginEventNotificationStatus.create({
                status: Wukong.DocumentProto.PluginEventNotificationStatus.PLUGIN_EVENT_NOTIFICATION_STATUS_NONE,
            })
        )
        // clear
        eventCollection.length = 0
    }, 100)

    const eventNotifyCommandJSImpl = (arg: Wukong.DocumentProto.IArg_PluginCallbackEventCollection) => {
        for (const eventToBeAdded of arg.events) {
            if (eventToBeAdded.type !== Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_DOCUMENT_CHANGE) {
                // not support merging of non-documentChange event yet
                eventCollection.push(eventToBeAdded)
            } else {
                // merge documentChange
                let eventAddedToCollection = false

                for (const eventInCollection of eventCollection) {
                    if (eventInCollection.type === eventToBeAdded.type) {
                        // merge documentChange if id & origin & type are same

                        for (const documentChangeToBeAdded of eventToBeAdded.documentChanges) {
                            let documentChangeAdded = false

                            for (const documentChangeInCollection of eventInCollection.documentChanges) {
                                if (
                                    documentChangeInCollection.id === documentChangeToBeAdded.id &&
                                    documentChangeInCollection.origin === documentChangeToBeAdded.origin &&
                                    documentChangeInCollection.type === documentChangeToBeAdded.type
                                ) {
                                    documentChangeInCollection.properties.push(...documentChangeToBeAdded.properties)

                                    // deduplicate properties
                                    documentChangeInCollection.properties = [
                                        ...new Set(documentChangeInCollection.properties),
                                    ]

                                    documentChangeAdded = true

                                    break
                                }
                            }

                            if (!documentChangeAdded) {
                                eventInCollection.documentChanges.push(documentChangeToBeAdded)
                            }
                        }

                        eventAddedToCollection = true
                        break
                    }
                }

                if (!eventAddedToCollection) {
                    eventCollection.push(eventToBeAdded)
                }
            }
        }

        debouncedEventHandler()
    }

    bridge.bind(PluginApiEventNotifyCommandJSV2, eventNotifyCommandJSImpl)

    const executeEventCallbacks = (
        type: Wukong.DocumentProto.PluginEventType,
        documentChanges: Wukong.DocumentProto.IArg_PluginDocumentChange[]
    ) => {
        // TODO(jiangjk): extract logic below to a separate function with event type as template parameter

        switch (type) {
            case Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_SELECTION_CHANGE:
                {
                    selectionCallback()
                }
                break
            case Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_CURRENT_PAGE_CHANGE:
                {
                    pageCallback()
                }
                break
            case Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_CLOSE:
                break
            case Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_RUN:
                break
            case Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_DOCUMENT_CHANGE:
                {
                    const documentChangeEvent: {
                        documentChanges: {
                            ownerPage?: string
                            type: string
                            origin: string
                            id: string
                            properties?: string[]
                        }[]
                    } = {
                        documentChanges: [],
                    }
                    documentChanges.forEach((change) => {
                        switch (change.type) {
                            case Wukong.DocumentProto.PluginDocumentChangeType
                                .PLUGIN_DOCUMENT_CHANGE_TYPE_CREATE_CHANGE: {
                                const args = {
                                    id: change.id as string,
                                    origin: origin2String[change.origin],
                                    type: 'CREATE',
                                    ownerPage: change.ownerPage ?? undefined,
                                }

                                documentChangeEvent.documentChanges.push(args)
                                break
                            }
                            case Wukong.DocumentProto.PluginDocumentChangeType
                                .PLUGIN_DOCUMENT_CHANGE_TYPE_DELETE_CHANGE: {
                                const args = {
                                    id: change.id as string,
                                    origin: origin2String[change.origin],
                                    type: 'DELETE',
                                    ownerPage: change.ownerPage ?? undefined,
                                }

                                documentChangeEvent.documentChanges.push(args)
                                break
                            }
                            case Wukong.DocumentProto.PluginDocumentChangeType
                                .PLUGIN_DOCUMENT_CHANGE_TYPE_PROPERTY_CHANGE: {
                                const args = {
                                    id: change.id as string,
                                    origin: origin2String[change.origin],
                                    type: 'PROPERTY_CHANGE',
                                    // sort by alphabetic order
                                    properties: change.properties.map((property) => property2String[property]).sort(),
                                    ownerPage: change.ownerPage ?? undefined,
                                }

                                documentChangeEvent.documentChanges.push(args)
                                break
                            }
                            case Wukong.DocumentProto.PluginDocumentChangeType
                                .PLUGIN_DOCUMENT_CHANGE_TYPE_STYLE_CREATE_CHANGE: {
                                const args = {
                                    id: change.id as string,
                                    origin: origin2String[change.origin],
                                    type: 'STYLE_CREATE',
                                    ownerPage: change.ownerPage ?? undefined,
                                }

                                documentChangeEvent.documentChanges.push(args)
                                break
                            }
                            case Wukong.DocumentProto.PluginDocumentChangeType
                                .PLUGIN_DOCUMENT_CHANGE_TYPE_STYLE_DELETE_CHANGE: {
                                const args = {
                                    id: change.id as string,
                                    origin: origin2String[change.origin],
                                    type: 'STYLE_DELETE',
                                    ownerPage: change.ownerPage ?? undefined,
                                }

                                documentChangeEvent.documentChanges.push(args)
                                break
                            }
                            case Wukong.DocumentProto.PluginDocumentChangeType
                                .PLUGIN_DOCUMENT_CHANGE_TYPE_STYLE_PROPERTY_CHANGE: {
                                const args = {
                                    id: change.id as string,
                                    origin: origin2String[change.origin],
                                    type: 'STYLE_PROPERTY_CHANGE',
                                    // sort by alphabetic order
                                    properties: change.properties
                                        .map((property) => property2String[property])
                                        .sort() as MotiffApi.StyleChangeProperty[],
                                    ownerPage: change.ownerPage ?? undefined,
                                }

                                documentChangeEvent.documentChanges.push(args)
                                break
                            }
                            default:
                        }
                    })
                    documentChangeCallback(documentChangeEvent.documentChanges)
                    nodeChangeCallback(documentChangeEvent.documentChanges)
                }
                break
            case Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_DROP:
                throw new Error('call executeDropEventHandlers instead')
            default:
        }
    }
}
