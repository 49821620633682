/* eslint-disable no-restricted-imports */
import { DispatchKeyboardEventCommand, WindowEventTrace, Wukong } from '@wukong/bridge-proto'
import { CommandInvoker } from '../../../document/command/command-invoker'
import { Bridge } from '../../../kernel/bridge/bridge'
import { debugLog } from '../../../kernel/debug'
import { KeyboardCode } from '../../../kernel/keyboard/keyboard-event-handler'

import KeyboardEventType = Wukong.DocumentProto.KeyEventType
import KeyCode = Wukong.DocumentProto.KeyCode

function generateElementTraceInfo(element?: EventTarget | null) {
    if (!element) {
        return 'null'
    }
    const el = element as HTMLElement
    return `${el.tagName?.toUpperCase()} id(${el.id}) testid(${el.getAttribute?.('data-testid')})`
}

export function generateWasmKeyboardEvent(
    type: KeyboardEventType,
    e: KeyboardEvent,
    source = Wukong.DocumentProto.KeyboardEventTraceSource.KEYBOARD_EVENT_TRACE_SOURCE_UNKNOWN
) {
    const keyCode = generateKeyCodeToWasm(e)
    const event: Wukong.DocumentProto.IKeyboardEvent = {
        type,
        key: e.key,
        code: e.code,
        keyCode: keyCode,
        ctrlKey: e.ctrlKey,
        shiftKey: e.shiftKey,
        altKey: e.altKey,
        metaKey: e.metaKey,
        repeat: e.repeat,
        source: source,
        eventTarget: generateElementTraceInfo(e.target),
        documentActiveElement: generateElementTraceInfo(document.activeElement),
    }
    return event
}

function generateKeyCodeToWasm(e: KeyboardEvent) {
    if (KeyboardCodeToKeyCode.has(e.code as KeyboardCode)) {
        return KeyboardCodeToKeyCode.get(e.code as KeyboardCode)
    } else {
        debugLog(`未知键盘码 code: ${e.code}, keyCode: ${e.keyCode}, key: ${e.key}`)
        return e.keyCode ?? 0
    }
}

const KeyboardCodeToKeyCode = new Map<KeyboardCode, KeyCode>([
    [KeyboardCode.A, KeyCode.KEY_CODE_A],
    [KeyboardCode.B, KeyCode.KEY_CODE_B],
    [KeyboardCode.C, KeyCode.KEY_CODE_C],
    [KeyboardCode.D, KeyCode.KEY_CODE_D],
    [KeyboardCode.E, KeyCode.KEY_CODE_E],
    [KeyboardCode.F, KeyCode.KEY_CODE_F],
    [KeyboardCode.G, KeyCode.KEY_CODE_G],
    [KeyboardCode.H, KeyCode.KEY_CODE_H],
    [KeyboardCode.I, KeyCode.KEY_CODE_I],
    [KeyboardCode.J, KeyCode.KEY_CODE_J],
    [KeyboardCode.K, KeyCode.KEY_CODE_K],
    [KeyboardCode.L, KeyCode.KEY_CODE_L],
    [KeyboardCode.M, KeyCode.KEY_CODE_M],
    [KeyboardCode.N, KeyCode.KEY_CODE_N],
    [KeyboardCode.O, KeyCode.KEY_CODE_O],
    [KeyboardCode.P, KeyCode.KEY_CODE_P],
    [KeyboardCode.Q, KeyCode.KEY_CODE_Q],
    [KeyboardCode.R, KeyCode.KEY_CODE_R],
    [KeyboardCode.S, KeyCode.KEY_CODE_S],
    [KeyboardCode.T, KeyCode.KEY_CODE_T],
    [KeyboardCode.U, KeyCode.KEY_CODE_U],
    [KeyboardCode.V, KeyCode.KEY_CODE_V],
    [KeyboardCode.W, KeyCode.KEY_CODE_W],
    [KeyboardCode.X, KeyCode.KEY_CODE_X],
    [KeyboardCode.Y, KeyCode.KEY_CODE_Y],
    [KeyboardCode.Z, KeyCode.KEY_CODE_Z],
    [KeyboardCode.SPACE, KeyCode.KEY_CODE_SPACE],

    // 主键盘数字
    [KeyboardCode.NUM_0, KeyCode.KEY_CODE_NUM0],
    [KeyboardCode.NUM_1, KeyCode.KEY_CODE_NUM1],
    [KeyboardCode.NUM_2, KeyCode.KEY_CODE_NUM2],
    [KeyboardCode.NUM_3, KeyCode.KEY_CODE_NUM3],
    [KeyboardCode.NUM_4, KeyCode.KEY_CODE_NUM4],
    [KeyboardCode.NUM_5, KeyCode.KEY_CODE_NUM5],
    [KeyboardCode.NUM_6, KeyCode.KEY_CODE_NUM6],
    [KeyboardCode.NUM_7, KeyCode.KEY_CODE_NUM7],
    [KeyboardCode.NUM_8, KeyCode.KEY_CODE_NUM8],
    [KeyboardCode.NUM_9, KeyCode.KEY_CODE_NUM9],

    // F 键
    [KeyboardCode.F1, KeyCode.KEY_CODE_F1],
    [KeyboardCode.F2, KeyCode.KEY_CODE_F2],
    [KeyboardCode.F3, KeyCode.KEY_CODE_F3],
    [KeyboardCode.F4, KeyCode.KEY_CODE_F4],
    [KeyboardCode.F5, KeyCode.KEY_CODE_F5],
    [KeyboardCode.F6, KeyCode.KEY_CODE_F6],
    [KeyboardCode.F7, KeyCode.KEY_CODE_F7],
    [KeyboardCode.F8, KeyCode.KEY_CODE_F8],
    [KeyboardCode.F9, KeyCode.KEY_CODE_F9],
    [KeyboardCode.F10, KeyCode.KEY_CODE_F10],
    [KeyboardCode.F11, KeyCode.KEY_CODE_F11],
    [KeyboardCode.F12, KeyCode.KEY_CODE_F12],

    // 运算符及标点符号
    [KeyboardCode['+='], KeyCode.KEY_CODE_EQUALS],
    [KeyboardCode['-'], KeyCode.KEY_CODE_DASH],
    [KeyboardCode['/?'], KeyCode.KEY_CODE_FORWARD_SLASH],
    [KeyboardCode['\\|'], KeyCode.KEY_CODE_BACKSLASH],
    [KeyboardCode['"'], KeyCode.KEY_CODE_QUOTE],
    [KeyboardCode['~'], KeyCode.KEY_CODE_TILDE],
    [KeyboardCode['['], KeyCode.KEY_CODE_OPEN_BRACKET],
    [KeyboardCode[']'], KeyCode.KEY_CODE_CLOSED_BRACKET],
    [KeyboardCode[',<'], KeyCode.KEY_CODE_COMMA],
    [KeyboardCode['.>'], KeyCode.KEY_CODE_PERIOD],
    [KeyboardCode[';:'], KeyCode.KEY_CODE_SEMI_COLON],

    // 功能键
    [KeyboardCode.ESCAPE, KeyCode.KEY_CODE_ESC],
    [KeyboardCode.BACKSPACE, KeyCode.KEY_CODE_BACK_SPACE],
    [KeyboardCode.ENTER, KeyCode.KEY_CODE_ENTER],
    [KeyboardCode.INSERT, KeyCode.KEY_CODE_INSERT],
    [KeyboardCode.DELETE, KeyCode.KEY_CODE_DELETE],
    [KeyboardCode.HOME, KeyCode.KEY_CODE_HOME],
    [KeyboardCode.END, KeyCode.KEY_CODE_END],
    [KeyboardCode.PAGE_UP, KeyCode.KEY_CODE_PAGE_UP],
    [KeyboardCode.PAGE_DOWN, KeyCode.KEY_CODE_PAGE_DOWN],
    [KeyboardCode.ARROW_LEFT, KeyCode.KEY_CODE_LEFT_ARROW],
    [KeyboardCode.ARROW_RIGHT, KeyCode.KEY_CODE_RIGHT_ARROW],
    [KeyboardCode.ARROW_UP, KeyCode.KEY_CODE_UP_ARROW],
    [KeyboardCode.ARROW_DOWN, KeyCode.KEY_CODE_DOWN_ARROW],
    [KeyboardCode.TAB, KeyCode.KEY_CODE_TAB],
    [KeyboardCode.NUM_LOCK, KeyCode.KEY_CODE_NUM_LOCK],
    [KeyboardCode.CAPSLOCK, KeyCode.KEY_CODE_CAPE_LOCK],

    // 修饰键
    [KeyboardCode.SHIFT_LEFT, KeyCode.KEY_CODE_SHIFT],
    [KeyboardCode.SHIFT_RIGHT, KeyCode.KEY_CODE_SHIFT],
    [KeyboardCode.CTRL_LEFT, KeyCode.KEY_CODE_CONTROL],
    [KeyboardCode.CTRL_RIGHT, KeyCode.KEY_CODE_CONTROL],
    [KeyboardCode.ALT_LEFT, KeyCode.KEY_CODE_ALT],
    [KeyboardCode.ALT_RIGHT, KeyCode.KEY_CODE_ALT],
    [KeyboardCode.META_LEFT, KeyCode.KEY_CODE_META_LEFT],
    [KeyboardCode.META_RIGHT, KeyCode.KEY_CODE_META_RIGHT],

    // 小键盘
    [KeyboardCode.PAD_0, KeyCode.KEY_CODE_NUM0],
    [KeyboardCode.PAD_1, KeyCode.KEY_CODE_NUM1],
    [KeyboardCode.PAD_2, KeyCode.KEY_CODE_NUM2],
    [KeyboardCode.PAD_3, KeyCode.KEY_CODE_NUM3],
    [KeyboardCode.PAD_4, KeyCode.KEY_CODE_NUM4],
    [KeyboardCode.PAD_5, KeyCode.KEY_CODE_NUM5],
    [KeyboardCode.PAD_6, KeyCode.KEY_CODE_NUM6],
    [KeyboardCode.PAD_7, KeyCode.KEY_CODE_NUM7],
    [KeyboardCode.PAD_8, KeyCode.KEY_CODE_NUM8],
    [KeyboardCode.PAD_9, KeyCode.KEY_CODE_NUM9],
    [KeyboardCode['PAD+'], KeyCode.KEY_CODE_EQUALS],
    [KeyboardCode['PAD-'], KeyCode.KEY_CODE_DASH],
    [KeyboardCode['PAD*'], KeyCode.KEY_CODE_MULTIPLY],
    [KeyboardCode['PAD/'], KeyCode.KEY_CODE_FORWARD_SLASH],
    [KeyboardCode.PAD_ENTER, KeyCode.KEY_CODE_ENTER],
    [KeyboardCode['PAD.'], KeyCode.KEY_CODE_PERIOD],

    // 未指定的键位
    [KeyboardCode.F13, KeyCode.KEY_CODE_DO_NOT_USE_ME_ZERO],
    [KeyboardCode.CONTEXT_MENU, KeyCode.KEY_CODE_DO_NOT_USE_ME_ZERO],
])

export function dispatchKeyboardEventToWasm(
    command: CommandInvoker,
    e: KeyboardEvent,
    source?: Wukong.DocumentProto.KeyboardEventTraceSource
) {
    const eventType =
        e.type === 'keydown'
            ? KeyboardEventType.KEY_EVENT_TYPE_KEY_DOWN
            : e.type === 'keyup'
            ? KeyboardEventType.KEY_EVENT_TYPE_KEY_UP
            : KeyboardEventType.KEY_EVENT_TYPE_UNKNOWN
    const event = generateWasmKeyboardEvent(eventType, e, source)
    const continuation = command.DEPRECATED_invokeBridge(DispatchKeyboardEventCommand, event)
    if (continuation?.preventDefault) {
        e.preventDefault()
    }
}

function boo2Int(b: boolean) {
    return b ? 1 : 0
}

function generateEventData(e: Event) {
    if (e instanceof KeyboardEvent) {
        return `${e.code} meta(${boo2Int(e.metaKey)}) ctrl(${boo2Int(e.ctrlKey)}) shift(${boo2Int(
            e.shiftKey
        )}) alt(${boo2Int(e.altKey)}) repeat(${boo2Int(e.repeat)})`
    }
    if (e instanceof MouseEvent || e instanceof PointerEvent) {
        return `button(${e.button}) buttons(${e.buttons}) meta(${boo2Int(e.metaKey)}) ctrl(${boo2Int(
            e.ctrlKey
        )}) shift(${boo2Int(e.shiftKey)}) alt(${boo2Int(e.altKey)})`
    }
    return ''
}

export function traceWindowEvent<E extends Event>(bridge: Bridge, e: E) {
    bridge.call(WindowEventTrace, {
        eventType: e.type,
        eventTarget: generateElementTraceInfo(e.target),
        documentActiveElement: generateElementTraceInfo(document.activeElement),
        data: generateEventData(e),
    })
}
