/* eslint-disable no-restricted-imports */
import classNames from 'classnames'
import { useCallback, useRef } from 'react'
import { isRightClick, ScrollView } from '../../../../../../../ui-lib/src'
import { Paint } from '../../../../../document/node/node'
import { ToKeyCode } from '../../../../../document/util/keycode'
import { useLazyItem } from '../hooks/use-lazy-item'
import { StyleListItemProps } from '../style-items-layout/style-list-item'
import { StyleToolTip } from '../style-tooltip/style-tooltip'
import { LocalStyleColorListItemThumnail } from './local-style-color-list-item-thumbnail'
import style from './style-color-grid-item.module.less'

export function StyleColorGridItem(props: StyleListItemProps<{ paints: readonly Paint[]; contentHash: string }>) {
    const { item, onSelectItem, onContextMenu, isRightClickStyle } = props
    const containerRef = useRef<HTMLDivElement>(null)
    const { visible } = useLazyItem(containerRef)

    const _onMouseDown: React.MouseEventHandler<HTMLDivElement> = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (isRightClick(e)) {
                return
            }
            onSelectItem?.(item)
        },
        [item, onSelectItem]
    )

    const _onContextMenu: React.MouseEventHandler<HTMLDivElement> = useCallback(
        (e) => {
            e.stopPropagation()
            e.preventDefault()
            onContextMenu?.(item, e)
        },
        [item, onContextMenu]
    )
    const _onKeydown = useCallback(
        (e: any) => {
            if (e.keyCode === ToKeyCode.Enter) {
                e.stopPropagation()
                _onMouseDown(e)
            }
        },
        [_onMouseDown]
    )

    return (
        <StyleToolTip titleText={item.name} contentText={item.description}>
            <ScrollView.Item
                ref={containerRef}
                uniqueKey={item.id}
                onContextMenu={_onContextMenu}
                onMouseDown={_onMouseDown}
                selectClassName={style.selectItem}
                className={classNames(
                    style.gridItem,
                    isRightClickStyle && style['right-click-menu-visible'],
                    props.className
                )}
                onKeyDown={_onKeydown}
                data-testid={item.id}
            >
                {visible ? (
                    <LocalStyleColorListItemThumnail
                        id={props.item.id}
                        contentHash={props.item.contentHash}
                        paints={props.item.paints}
                        className={style.color}
                    />
                ) : null}
            </ScrollView.Item>
        </StyleToolTip>
    )
}
