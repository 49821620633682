import classnames from 'classnames'
import { useMemo, useRef } from 'react'
import { DraggablePopupV2, InputV2, ScrollView } from '../../../../../../ui-lib/src'
import { ToKeyCode } from '../../../../document/util/keycode'
import { KeyboardReceiver } from '../../../../main/keyboard-receiver/component'
import { ClientPosittion } from '../../design/effects/context'
import { UnknownEffectStyleListItem } from '../../design/effects/effect-style/effect-style-select/unknown-select-group'
import {
    SelectEffectStyleContextProvider,
    useEffectStyleSelectHandler,
    useEffectStyleSelectList,
} from '../../design/effects/effect-style/effect-style-select/use-effect-style-select'
import { useKeyboardStyleStyleList } from '../../design/styles/hooks/use-keyboard-style-list'
import { CandidateEffectStyleListItem } from './candidate-effect-style-list-item'
import style from './candidate-style-color.module.less'
import { translation } from './candidate-style-effect.translation'

export function CandidateStyleEffect(
    props: Parameters<typeof SelectCandidateEffectStyleModalInternal>[0] & { width?: number }
) {
    return (
        <SelectEffectStyleContextProvider>
            <SelectCandidateEffectStyleModalInternal {...props} />
        </SelectEffectStyleContextProvider>
    )
}

function SelectCandidateEffectStyleModalInternal(props: {
    onClose: () => void
    position: ClientPosittion
    isMixed: boolean
    onChangeStyle: (styleId: string, isCreateStyle: boolean, name: string) => void
    selectStyleId?: string
    width?: number
}) {
    const { onSearch } = useEffectStyleSelectHandler()
    const { list, unknownList, empty } = useEffectStyleSelectList()

    const hasMoved = useRef<boolean>(false)

    const needSplitLineWithUnknownListItem = (index: number) => {
        if (index > 0) {
            return true
        }
        const hasItemBeforeUnknown = list.length > 0
        return hasItemBeforeUnknown
    }

    const maxPreSelectIndex = useMemo(() => {
        let optionSum = 0
        for (const group of list) {
            optionSum += group.effectStyles.length
        }
        for (const group of unknownList) {
            optionSum += group.effectStyles.length
        }
        return Math.max(optionSum - 1, 0)
    }, [list, unknownList])

    const keyboardStyleStyleList = useKeyboardStyleStyleList({ maxPreSelectIndex })

    const renderItems = () => {
        let optionIndex = 0
        const _selectStyleId = props.selectStyleId || undefined
        return (
            <>
                {list.length ? (
                    <div>
                        {list.map((group, index) => (
                            <div key={index}>
                                {group.groupName ? <div className={style.h2}>{group.groupName}</div> : null}
                                <div>
                                    {group.effectStyles.map((v) => (
                                        <CandidateEffectStyleListItem
                                            item={v}
                                            key={v.effectStyle.id}
                                            index={optionIndex++}
                                            selectStyleId={_selectStyleId}
                                            setPreselectIndex={keyboardStyleStyleList.setPreselectIndex}
                                            trySetPreselectIndex={keyboardStyleStyleList.trySetPreselectIndex}
                                            recordEnterCallback={keyboardStyleStyleList.recordEnterCallback}
                                            onClick={props.onChangeStyle}
                                            width={props.width}
                                        ></CandidateEffectStyleListItem>
                                    ))}
                                </div>
                            </div>
                        ))}
                        <div className={style.paddingBottom8}></div>
                    </div>
                ) : null}
                {unknownList.length ? (
                    <div>
                        <div
                            className={classnames(style.h1, {
                                [style.splitLine]: needSplitLineWithUnknownListItem(0),
                            })}
                        >
                            {translation('UnknownLibrary')}
                        </div>
                        {unknownList.map((group, index) => (
                            <div key={index}>
                                {group.groupName ? <div className={style.h2}>{group.groupName}</div> : null}
                                <div>
                                    {group.effectStyles.map((v) => (
                                        <UnknownEffectStyleListItem
                                            item={v}
                                            key={v.effectStyle.id}
                                            index={optionIndex++}
                                            selectStyleId={_selectStyleId}
                                            setPreselectIndex={keyboardStyleStyleList.setPreselectIndex}
                                            trySetPreselectIndex={keyboardStyleStyleList.trySetPreselectIndex}
                                            recordEnterCallback={keyboardStyleStyleList.recordEnterCallback}
                                        ></UnknownEffectStyleListItem>
                                    ))}
                                </div>
                            </div>
                        ))}
                        <div className={style.paddingBottom8}></div>
                    </div>
                ) : null}
            </>
        )
    }

    return (
        <DraggablePopupV2
            visible
            position={props.position}
            closable={false}
            width={props.width ? props.width : 216}
            onFirstMove={() => (hasMoved.current = true)}
            header={<div>{translation('EffectStyle')}</div>}
            headerClassName={style.header}
            bodyClassName="p-0"
            footer={null}
            onCancel={props.onClose}
        >
            {empty ? (
                <div className={style.emptyStyle}>
                    <div className={style.emptyStyle_text}>{translation('NoEffect')}</div>
                </div>
            ) : (
                <div className={style.contentContainer}>
                    <InputV2.Search
                        style={{ margin: 8 }}
                        onSearch={onSearch}
                        autoFocus
                        onInput={keyboardStyleStyleList.onInput}
                        onKeyDown={keyboardStyleStyleList.onKeyDown}
                        placeholder={translation('Search')}
                    />
                    {list.length === 0 && unknownList.length === 0 ? (
                        <div className={style.emptyStyle}>
                            <div className={style.emptyStyle_text}>{translation('NoStyles')}</div>
                        </div>
                    ) : (
                        <ScrollView
                            className={classnames(style.content)}
                            key="list"
                            selectKey={keyboardStyleStyleList.preselectIndex}
                            ref={keyboardStyleStyleList.scrollViewRef}
                            block="nearest"
                            scrollbar={{ autoHeight: true, autoHeightMin: 0, autoHeightMax: 352 }}
                        >
                            <KeyboardReceiver keyCode={ToKeyCode.All} onKeydown={keyboardStyleStyleList.onKeyDown}>
                                {renderItems()}
                            </KeyboardReceiver>
                        </ScrollView>
                    )}
                </div>
            )}
        </DraggablePopupV2>
    )
}
