import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    VariableSetting: '可变字体设置...',
} as const

export const enTranslation = {
    VariableSetting: 'Variable font axes...',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
