import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    triggerConflict: '由于其他交互会先执行，当前交互将不会生效',
} as const

export const enTranslation = {
    triggerConflict: 'This interaction will not be triggered as another one will occur first.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
