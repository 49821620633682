import type { IBasisTranscoderExports, KTX2File } from '../third-party/basis_transcoder/tc_trans'
import { TextureCompressionFormat } from '../types'

export const enum BasisTextureFormat {
    RGBA32 = 13,
    ASTC_4x4 = 10,
    BC7 = 6,
}

function toBasisTextureFormat(fmt: TextureCompressionFormat): BasisTextureFormat {
    switch (fmt) {
        case TextureCompressionFormat.None:
            return BasisTextureFormat.RGBA32
        case TextureCompressionFormat.ASTC_4x4:
            return BasisTextureFormat.ASTC_4x4
        case TextureCompressionFormat.BC7:
            return BasisTextureFormat.BC7
    }
}

function toCompressedTexture(ktx2File: KTX2File, format: BasisTextureFormat): Uint8Array {
    if (!ktx2File.isValid()) {
        throw Error(`unsupported ktx2File`)
    }

    const width = ktx2File.getWidth()
    const height = ktx2File.getHeight()
    const is_uastc = ktx2File.isUASTC()
    const levels = ktx2File.getLevels()

    if (!width || !height || !levels) {
        throw Error(`invalid ktx2 file`)
    }
    if (!is_uastc) {
        throw Error(`should be UASTC`)
    }

    if (!ktx2File.startTranscoding()) {
        throw Error('startTranscoding failed')
    }

    const dstSize = ktx2File.getImageTranscodedSizeInBytes(0, 0, 0, format)
    const dst = new Uint8Array(dstSize)

    if (!ktx2File.transcodeImage(dst, 0, 0, 0, format, 0, -1, -1)) {
        throw Error('transcodeImage failed')
    }

    return dst
}

export function ktx2ToCompressedTexture(
    cx: IBasisTranscoderExports,
    data: Uint8Array,
    format: TextureCompressionFormat
): Uint8Array {
    cx.initializeBasis()

    const ktx2File = new cx.KTX2File(data)

    try {
        return toCompressedTexture(ktx2File, toBasisTextureFormat(format))
    } finally {
        ktx2File.close()
        ktx2File.delete()
    }
}
