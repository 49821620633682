/**
 * @owner: chenxiangbj@kanyun.com
 */
import classnames from 'classnames'
import { FC, useCallback, useMemo } from 'react'
import { InputV2, UserInfoBlock, WKButton } from '../../../../ui-lib/src'
import { TagItem } from '../../../../ui-lib/src/components/inputs/tag-input/tag-input'
import { emailRegExp } from '../../../../util/src'
import { UserBriefVO } from '../../kernel/interface/type'
import { translation } from './index.translation'
import { useMemberTagInput } from './use-member-tag-input'

interface UserRenderItemProps {
    user: UserBriefVO
}

export const UserRenderItem: FC<UserRenderItemProps> = (props) => {
    return (
        <div data-testid={`member-input-option`} className="py-3 px-4">
            <UserInfoBlock userBrief={props.user} disableEmailCopy />
        </div>
    )
}

type UserBriefVOAndUnregisterVO = UserBriefVO & { unregistered?: boolean }

interface InviteMemberInputV2Props {
    disabled?: boolean
    placeholder: string
    onOk: (user: UserBriefVOAndUnregisterVO[]) => void
    tool: JSX.Element
    requestFn: (query: string) => Promise<UserBriefVO[]>
    disableShowFirstValidate?: boolean // 禁用不变标签直接支持发送的功能
    emailSuffixes?: string[] // 支持的邮箱后缀
    customGenerateTag?: (
        tag: TagItem<UserBriefVOAndUnregisterVO | string>
    ) => TagItem<UserBriefVOAndUnregisterVO | string> // 对组件提供的生成标签的方法返回值进行二次处理
    defaultUser?: UserBriefVO
    className?: string
}

export const InviteMemberInputV2: FC<InviteMemberInputV2Props> = ({
    disabled,
    requestFn,
    placeholder,
    onOk,
    tool,
    customGenerateTag,
    emailSuffixes,
    defaultUser,
    disableShowFirstValidate,
    className,
}) => {
    const { query, tagInputRef, defaultTags, setQuery, tags, setTags, items, generateTag } = useMemberTagInput({
        requestFn,
        emailSuffixes,
        customGenerateTag,
        defaultUser,
    })

    const buttonDisable = tags.length == 0 || !!tags.find((o) => o.color == 'red')

    const invite = useCallback(() => {
        const users = tags.map((o) => o.customProps as UserBriefVOAndUnregisterVO)
        onOk(users)
    }, [onOk, tags])

    // 输入完整邮箱，并且能搜到唯一用户，那么不变标签也可以直接邀请
    const showFirstValidate = useMemo(() => {
        if (disableShowFirstValidate) {
            return false
        }
        if (tags.length > 0) {
            return false
        }
        if (!emailRegExp.test(query)) {
            return false
        }
        if (emailSuffixes) {
            return emailSuffixes.find((o) => query.endsWith(o))
        } else {
            return true
        }
    }, [tags, query, emailSuffixes, disableShowFirstValidate])

    const firstInvite = useCallback(() => {
        if (items.length == 0) {
            onOk([{ email: query, unregistered: true } as UserBriefVOAndUnregisterVO])
        } else {
            onOk(items)
        }
    }, [items, onOk, query])

    return (
        <div className={classnames('flex gap-2 items-center', className)} data-testid="invite-member-input">
            <InputV2.Tag
                autoFocus
                autoHeight
                ref={tagInputRef}
                placeholder={placeholder}
                defaultTags={defaultTags}
                disabled={disabled}
                tool={tool}
                className="flex-1"
                listProps={{
                    items,
                    renderItem: (v) => <UserRenderItem user={v.item} />,
                }}
                onChangeValue={setQuery}
                onChangeTag={setTags}
                dataTestIds={{
                    input: 'member-input',
                }}
                generateTag={generateTag}
            ></InputV2.Tag>
            {showFirstValidate ? (
                <WKButton className="h-min" onClick={firstInvite} type={'primary'}>
                    {translation('SendInvite')}
                </WKButton>
            ) : (
                <WKButton className="h-min" onClick={invite} type={'primary'} disabled={buttonDisable}>
                    {translation('SendInvite')}
                </WKButton>
            )}
        </div>
    )
}
