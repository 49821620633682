// https://developer.chrome.com/blog/keyboard-focusable-scrollers#from_chrome_124_with_focusable_scrollers
// https://developer.chrome.com/blog/new-in-chrome-127#keyboard-focusable-scroll-containers
// chrome 124 版本之后，没有任何可聚焦子孙的可滚动容器，没有 tabIndex 也可以聚焦，127 版本改成了点击可聚焦
// 此时 el.getAttribute('tabIndex') 为 null，但 el.tabIndex = -1
// 这里我们认为「除了浏览器默认的可聚焦元素外，其余只有手动设置了 tabIndex 属性，才是符合预期的可聚焦元素」
// 因为目前只有 chrome 有这种情况，考虑到和其它浏览器行为一致，我们先通过这种方式来判断

// https://github.com/testing-library/user-event/blob/6fa71631f8273c59023b59ba3befd204b8cd47d9/src/utils.js#L221-L237
const FOCUSABLE_SELECTOR = [
    'input:not([disabled])',
    'button:not([disabled])',
    'select:not([disabled])',
    'textarea:not([disabled])',
    '[contenteditable=""]',
    '[contenteditable="true"]',
    'a[href]',
    '[tabindex]:not([disabled])',
].join(', ')
export function isFocusableElement(element: HTMLElement) {
    return element?.matches(FOCUSABLE_SELECTOR)
}
