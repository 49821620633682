import { translation } from './line-icon-wrapper.translation'
/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { HTMLAttributes, useCallback, useMemo, useRef, useState } from 'react'
import {
    IconHistoryCancelPublish,
    IconHistoryNormal,
    IconHistoryPublish,
    IconMote,
    PopupProps,
    ScrollView,
    Tooltip,
} from '../../../../../../../ui-lib/src'
import { IconButton } from '../../../atom/button/icon-button'
import { useClickVersion, useIsSelectVersion } from '../history-list-provider'
import { MoreMenu } from '../more-menu'
import { AutoItem, AutoItemGroup, CurrentItem, IconType, ItemType, UserItem } from '../type'
import classes from './line-icon-wrapper.module.less'

export interface LineIconWrapperProps extends HTMLAttributes<HTMLDivElement> {
    item: UserItem | AutoItem | AutoItemGroup | CurrentItem
    hideUpperHalf?: boolean
    hideLowerHalf?: boolean
    dataTestIds?: {
        container?: string
        iconWhite?: string
        icon?: string
        moreButton?: string
    }
}

export function LineIconWrapper(props: LineIconWrapperProps) {
    const { item, hideUpperHalf, hideLowerHalf, className, onContextMenu, onClick, dataTestIds, ...otherProps } = props
    const isSelectVersion = useIsSelectVersion()
    const clickVersion = useClickVersion()
    const lineIconWrapperRef = useRef<HTMLDivElement>(null)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isSelectIcon, setIsSelectIcon] = useState<boolean>(false)
    const [rectContainer, setRectContainer] = useState<PopupProps['rectContainer']>()

    const switchOpen = useCallback(() => {
        const isWillClose = isOpen
        isWillClose && setIsSelectIcon(false)
        setIsOpen(!isOpen)
    }, [isOpen])

    const icon = useMemo(() => {
        switch (item.iconType) {
            case IconType.Public:
                return <IconHistoryPublish />
            case IconType.CancelPublic:
                return <IconHistoryCancelPublish />
            case IconType.Normal:
                return <IconHistoryNormal />
            default:
                return null
        }
    }, [item.iconType])

    const isAllowMenu = useCallback((_item: LineIconWrapperProps['item']): _item is UserItem | AutoItem => {
        return _item.type === ItemType.UserItem || _item.type === ItemType.AutoItem
    }, [])

    const _onContextMenu = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (isAllowMenu(item)) {
                setRectContainer({
                    top: e.pageY - 8,
                    left: e.pageX,
                    bottom: e.pageY - 8,
                    right: e.pageX,
                })
                switchOpen()
            }
            onContextMenu?.(e)
        },
        [isAllowMenu, item, onContextMenu, switchOpen]
    )

    const _onClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (
                item.type === ItemType.UserItem ||
                item.type === ItemType.AutoItem ||
                item.type === ItemType.CurrentItem
            ) {
                clickVersion(item)
            }
            onClick?.(e)
        },
        [clickVersion, item, onClick]
    )

    const onClickMoreIcon = useCallback(
        (e: React.MouseEvent<HTMLSpanElement>) => {
            if (isAllowMenu(item)) {
                const iconElement = e.currentTarget as HTMLSpanElement
                setRectContainer(iconElement.getBoundingClientRect())
                setIsSelectIcon(true)
                switchOpen()
            }
        },
        [isAllowMenu, item, switchOpen]
    )

    const uniqueKey = useMemo(() => {
        if (item.type === ItemType.UserItem || item.type === ItemType.AutoItem || item.type === ItemType.CurrentItem) {
            return item.versionId
        }
        return NaN
    }, [item])

    return (
        <>
            <ScrollView.Item
                uniqueKey={uniqueKey}
                ref={lineIconWrapperRef}
                className={classnames(classes.lineIconWrapper, [className], {
                    [classes.hideUpperHalf]: hideUpperHalf,
                    [classes.hideLowerHalf]: hideLowerHalf,
                    [classes.isSelected]:
                        item.type === ItemType.AutoItemGroup ? false : isSelectVersion(item.versionId),
                    [classes.isOpen]: isOpen,
                })}
                onContextMenu={_onContextMenu}
                onClick={_onClick}
                data-testid={dataTestIds?.container}
                {...otherProps}
            >
                <div className={classes.line}></div>
                {/* 这个 icon - white 存在的意义就是为了辅助截取线段 */}
                {icon !== null ? (
                    <div
                        className={classes.icon}
                        style={{ backgroundColor: 'white' }}
                        data-testid={dataTestIds?.iconWhite}
                    ></div>
                ) : null}
                {icon !== null ? (
                    <div className={classes.icon} data-testid={dataTestIds?.icon}>
                        {icon}
                    </div>
                ) : null}
                <div className={classes.contentContainer}>
                    <div className={classes.content}>{props.children}</div>
                    {isAllowMenu(item) ? (
                        <div className={classes.tools}>
                            <Tooltip title={translation('MoreOptions')}>
                                <IconButton
                                    selected={isSelectIcon}
                                    icon={<IconMote />}
                                    deepColor
                                    onClick={onClickMoreIcon}
                                    dataTestId={dataTestIds?.moreButton}
                                />
                            </Tooltip>
                        </div>
                    ) : null}
                </div>
            </ScrollView.Item>
            {isAllowMenu(item) ? (
                <MoreMenu item={item} isOpen={isOpen} onClose={switchOpen} rectContainer={rectContainer} />
            ) : null}
        </>
    )
}
