import { ReactRootRender } from '../react-root-render/react-root-render'
import { PayApplyDialogStruct } from './pay-apply-dialog-struct/pay-apply-dialog-struct'
import { PayApplyDialogStructProps } from './pay-apply-dialog-struct/type'

export type PayApplyDialogProps = PayApplyDialogStructProps

export const PayApplyDialog = new ReactRootRender<PayApplyDialogProps>((props, state) => {
    const { dialogProps, ...otherProps } = props
    const onCancel = () => {
        dialogProps?.onCancel?.()
        state.close()
    }
    const onOk = () => {
        dialogProps?.onOk?.()
        state.close()
    }
    return (
        <PayApplyDialogStruct
            {...otherProps}
            dialogProps={{ ...dialogProps, title: dialogProps?.title, okText: dialogProps?.okText, onCancel, onOk }}
        />
    )
})
