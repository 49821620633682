import {
    CloseLibraryReplaceModalWasmCall,
    StartLibraryReplaceWasmCall,
    UpdateLibraryReplaceModalScrollableSizeCommand,
    UpdateLibraryReplaceModalScrollTopCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { AnimationEventHandler, useCallback, useEffect, useState } from 'react'
import { usePrevious } from '../../../../../../util/src'
import { useCommand, useLibraryComponentService } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { useLibraryModalConfigContext } from '../../../context/library-component-style/library-modal-config'
import { useVirtualListContainer } from '../../../hooks/virtual-list'
import styles from '../library-list-modal/library-list-modal-remote/library-publish-modal-remote.module.less'
import { useLibraryRemoteUsedAndMissingListContext } from '../library-remote-used-list/use-library-remote-used-hook'

export function useLibraryReplaceDetail(props: Wukong.DocumentProto.ILibraryIdInfo) {
    const command = useCommand()
    const libraryReplaceModalViewState = useViewState('libraryReplaceModalViewState')
    const libraryRemoteUsedAndMissingListContext = useLibraryRemoteUsedAndMissingListContext()
    const {
        libraryModalRouterService: {
            goToRemoteLibraryDetail,
            goToRemoteLibraryMissingList,
            goToRemoteLibraryHome,
            closeLibraryMainModal,
        },
    } = useLibraryComponentService()
    const {
        mainModalConfig: { routePath },
    } = useLibraryModalConfigContext()
    const prevRoutePath = usePrevious(routePath)

    const goBack = useCallback(() => {
        if (!props.documentId) {
            return
        }

        if (
            prevRoutePath ===
            Wukong.DocumentProto.LibraryMainModalRoutePathType
                .LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REMOTE_MISS_LIBRARY_DETAIL
        ) {
            // 缺失组件库数量不足 2 个时，直接 back 到首页
            if (libraryRemoteUsedAndMissingListContext.missingLibraryInfoList.length > 1) {
                goToRemoteLibraryMissingList()
            } else {
                goToRemoteLibraryHome()
            }
        } else if (
            prevRoutePath ==
            Wukong.DocumentProto.LibraryMainModalRoutePathType.LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REMOTE_LIBRARY_DETAIL
        ) {
            if (props.libraryId) {
                goToRemoteLibraryDetail(props.libraryId, props.documentId)
            } else {
                goToRemoteLibraryHome()
            }
        } else {
            goToRemoteLibraryHome()
        }

        command.DEPRECATED_invokeBridge(CloseLibraryReplaceModalWasmCall)
    }, [
        command,
        goToRemoteLibraryDetail,
        goToRemoteLibraryHome,
        goToRemoteLibraryMissingList,
        libraryRemoteUsedAndMissingListContext.missingLibraryInfoList.length,
        prevRoutePath,
        props.documentId,
        props.libraryId,
    ])

    const handleResize = useCallback(
        (height: number) => {
            command.DEPRECATED_invokeBridge(
                UpdateLibraryReplaceModalScrollableSizeCommand,
                Wukong.DocumentProto.Arg_UpdateVirtualScrollableSize.create({
                    width: 464,
                    height,
                })
            )
        },
        [command]
    )

    const handleScroll = useCallback(
        (scrollTop: number) => {
            command.DEPRECATED_invokeBridge(
                UpdateLibraryReplaceModalScrollTopCommand,
                Wukong.DocumentProto.BridgeProtoInt.create({
                    value: scrollTop,
                })
            )
        },
        [command]
    )

    const { ref: containerRef } = useVirtualListContainer({
        onResize: handleResize,
        onScroll: handleScroll,
        deps: [],
    })

    const startReplace = useCallback(() => {
        command.DEPRECATED_invokeBridge(StartLibraryReplaceWasmCall)
        closeLibraryMainModal()
    }, [closeLibraryMainModal, command])

    const onAnimationEnd: AnimationEventHandler<HTMLDivElement> = useCallback((event) => {
        if (event.currentTarget.classList.contains(styles.show)) {
            event.currentTarget.classList.remove(styles.show)
        }
    }, [])

    const [selectedTargetName, _setSelectedTargetName] = useState<string>()
    const setSelectedTargetName = useCallback(
        (value: string | undefined) => {
            _setSelectedTargetName(value)
            // 切换目标库时重置滚动区
            containerRef.current?.scrollToTop()
        },
        [containerRef]
    )

    // 切换原始库时清空目标库并重置滚动区
    useEffect(() => {
        setSelectedTargetName(undefined)
        if (containerRef.current?.getScrollTop()) {
            containerRef.current?.scrollToTop()
        }
    }, [containerRef, props.documentId, setSelectedTargetName])

    // 当前组件库在文档中的使用数量为 0 时，自动关闭替换弹窗
    useEffect(() => {
        if (
            libraryReplaceModalViewState &&
            !libraryReplaceModalViewState.displayList.length &&
            routePath ===
                Wukong.DocumentProto.LibraryMainModalRoutePathType
                    .LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REPLACE_LIBRARY_DETAIL
        ) {
            closeLibraryMainModal()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeLibraryMainModal, libraryReplaceModalViewState?.displayList.length])

    return {
        goBack,
        containerRef,
        libraryReplaceModalViewState,
        startReplace,
        onAnimationEnd,
        selectedTargetName,
        setSelectedTargetName,
    }
}
