import {
    GetAndClearRenderDurationBuckets,
    GetComputedPropsReadCountAndRestCounterCommand,
    JsCallSignatures,
    MethodSignature,
    Wukong,
} from '@wukong/bridge-proto'
import { createSelectors, createStore } from '../../../../util/src'
import { ClassWithEffect, EffectController } from '../../../../util/src/effect-controller'
import { WasmCallMonitorItem } from '../../../../util/src/wasm-call-monitor'
import { environment, IN_JEST_TEST } from '../../environment'
import { BridgeMetricCollector, RangeBucket } from '../../metric'
import { WK } from '../../window'
import { debugWarn } from '../debug'
import { ReplayDBPrefix } from '../interface/replay'
import { Metric, MetricCollector, MetricName } from '../metric-collector'
import { createRecordingDb, recordBridgeCall, uploadCrashedRecordings } from '../recording'
import { enableSentry, Sentry } from '../sentry'
import { CanvasStateType } from '../service/canvas-types'
import type { EditorService } from '../service/editor-service'
import { EditorServiceState, HEAPDest } from '../service/editor-service'
import { featureSwitchManager } from '../switch/core'
import { getCrashType } from '../util/crash'
import { BasicBridge, BasicBridgeBindOptions, BridgeCallDelegate, copyBytes } from './basic-bridge'
import type { Bridge, CrashData } from './bridge'

import DocumentProto = Wukong.DocumentProto

import JsCallCode = DocumentProto.JsCallCode
const AllJsCallSignatures = Object.values(JsCallSignatures)
const JsCallCodeToNameMap = (Object.keys(JsCallCode) as Array<keyof typeof JsCallCode>).reduce(
    (memo: Record<number, keyof typeof JsCallCode>, curr: keyof typeof JsCallCode) => {
        memo[JsCallCode[curr]] = curr
        return memo
    },
    {}
)

// import WasmCallCode = DocumentProto.WasmCallCode
// const WasmCallCodeToNameMap = (Object.keys(WasmCallCode) as Array<keyof typeof WasmCallCode>).reduce(
//     (memo: Record<number, keyof typeof WasmCallCode>, curr: keyof typeof WasmCallCode) => {
//         memo[WasmCallCode[curr]] = curr
//         return memo
//     },
//     {}
// )

const DURATION_BUCKETS = ['1', '2', '4', '8', '16', '32', '64', '128', '256', '512', '+Inf']

const isSandBox = window.location.href.includes('sandbox')

const METRIC_REPORT_INTERVAL = 60000
export class EmBridge extends ClassWithEffect implements Bridge, BridgeCallDelegate {
    private _destroyed = false
    private _rejectCounter = 0
    private _bridgeMetricCollector: BridgeMetricCollector = new BridgeMetricCollector()
    private _reportMetricTimer: ReturnType<typeof setTimeout> = 0 as any
    private disableRecord = false
    states = createSelectors(
        createStore<{
            crashed: CrashData | null
            setCrashed: (crashed: CrashData | null) => void
        }>(
            (set) => ({
                crashed: null,
                setCrashed: (crashed: CrashData | null) => {
                    set(() => ({ crashed }))
                },
            }),
            environment.isDev
        )
    )

    // 在历史版本下会存在多个 wasm，这里需要区分一下 bridge 的调用
    private historyModeEditorService?: EditorService
    private basicBridgeImpl!: BasicBridge

    constructor(private editorService: EditorService, controller: EffectController) {
        super(controller)
        WK.wasmCall = this.call
        WK.jsCall = this.jsCall
        enableSentry()
        this.basicBridgeImpl = new BasicBridge(this)
    }

    wasmCall(methodCode: number, arg: Uint8Array | undefined): Uint8Array | undefined {
        const argPtr = arg ? this.currentEditorService.copy1dArray(arg, HEAPDest.HEAPU8) : 0

        const retPtr =
            this.currentEditorService.wasmCall(methodCode, argPtr, this.currentEditorService._bridgeCallCounter) >>> 0 // 对于大于 2gb 的指针， 返回的负数要转回正数

        if (argPtr) {
            this.currentEditorService.free(argPtr)
        }
        if (retPtr === 0) {
            return undefined
        }

        const retBuf = this.currentEditorService.HEAPU8.subarray(retPtr)
        return retBuf
    }

    get clientId() {
        return this.currentEditorService.clientId
    }

    get currentRecordingName() {
        return ReplayDBPrefix + this.clientId
    }

    get currentEditorService() {
        return this.historyModeEditorService ? this.historyModeEditorService : this.editorService
    }

    get inHistoryMode() {
        return !!this.historyModeEditorService
    }

    resetHistoryModeEditorService(service?: EditorService): void {
        this.historyModeEditorService?.destroy()
        this.historyModeEditorService = service
    }

    async initRecording(docId: string, recordingName?: string) {
        if (!indexedDB.databases || IN_JEST_TEST) {
            // 对 firefox 不开启录制
            return
        }
        try {
            await createRecordingDb(recordingName ?? this.currentRecordingName, docId)
            if (this.controller.aborted) {
                return
            }
            // upload in the background without wait
            uploadCrashedRecordings()
        } catch (e) {
            Sentry.captureException(e)
        }
    }

    disableRecording() {
        this.disableRecord = true
    }

    beforeJsCall(encodedArg: Uint8Array | undefined, method: MethodSignature<any, any>, arg?: any) {
        this.record(method, {
            action: Wukong.DocumentProto.BridgeAction.BRIDGE_ACTION_JS_CALL_BEGIN,
            code: method.code,
            args: method.code === JsCallCode.JCC_sendBatchOperation && encodedArg ? encodedArg! : undefined,
            perfNow: performance.now(),
        })
    }

    afterJsCall(ret: Uint8Array | undefined, method: MethodSignature<any, any>) {
        this.record(method, {
            action: Wukong.DocumentProto.BridgeAction.BRIDGE_ACTION_JS_CALL_END,
            code: method.code,
            ret: ret ? copyBytes(ret) : undefined,
            perfNow: performance.now(),
        })
    }

    bind = <RET, ARG>(
        method: MethodSignature<RET, ARG>,
        fn: (arg: ARG) => RET,
        options?: BasicBridgeBindOptions
    ): void => {
        if (this.basicBridgeImpl.methods[method.code]) {
            debugWarn(`${method.code} 已经绑定，建议检查重复绑定的场景是否合理`)
        }
        this.basicBridgeImpl.bind(method, fn, options)
    }

    unbind = <RET, ARG>(method: MethodSignature<RET, ARG>): void => {
        this.basicBridgeImpl.unbind(method)
    }

    // 检测 wasm 是否成功启动
    private checkWasmReady(): void {
        const editorServiceState = this.editorService.getState()
        if (editorServiceState === EditorServiceState.Prepare) {
            throw new Error('当前的 wasm 不处于可调用状态，wasm 尚未启动')
        }
    }

    call = <RET, ARG>(method: MethodSignature<RET, ARG>, arg?: ARG): RET => {
        this.checkWasmReady()
        return this.internalCall(method, arg)
    }

    beforeWasmCall(encodedArg: Uint8Array | undefined, method: MethodSignature<any, any>, arg?: any) {
        this.recordWasmCallBegin(encodedArg, method, arg)
    }

    // 不要 catch 异常，否则没有异常堆栈，不方便 debug
    private internalCall = <RET, ARG>(method: MethodSignature<RET, ARG>, arg?: ARG): RET => {
        if (this.warnDestroyed(method.code)) {
            return undefined as any
        }

        this.requestReportMetric()

        const begin = performance.now()
        let ret = undefined as any
        try {
            ret = this.basicBridgeImpl.call(method, arg)
        } catch (e: any) {
            this._bridgeMetricCollector.incErrorCounter(method.code)
            this.crash(e)
            ret = undefined as any
        } finally {
            const end = performance.now()
            this.record(method, {
                action: Wukong.DocumentProto.BridgeAction.BRIDGE_ACTION_WASM_CALL_END,
                perfNow: end,
            })
        }
        if (featureSwitchManager.isEnabled('motiff-debugger') && WK.wasmCallLogsMonitorStatus) {
            const motiffScopeMoinitorBlackList = WK.wasmCallLogsMonitorBlackList ?? []
            const safeStringify = (obj: any) => {
                return JSON.stringify(obj, function (key, value) {
                    if (value instanceof HTMLElement) {
                        return '不支持HTML元素展开'
                    }
                    if (value instanceof Window) {
                        return '不支持Window展开'
                    }
                    return value
                })
            }
            if (!motiffScopeMoinitorBlackList.includes(Wukong.DocumentProto.WasmCallCode[method.code])) {
                WK.wasmCallLogs = [
                    {
                        id: crypto.randomUUID(),
                        timestamp: new Date().toLocaleString(),
                        method: Wukong.DocumentProto.WasmCallCode[method.code],
                        args: safeStringify(arg),
                        ret: safeStringify(ret),
                    } as WasmCallMonitorItem,
                    ...(WK.wasmCallLogs ?? []),
                ]
            }
        }

        this._bridgeMetricCollector.insertRequestDuration(method.code, performance.now() - begin)
        return ret
    }

    private requestReportMetric() {
        if (WK.e2e) {
            return
        }

        if (this._reportMetricTimer) {
            return
        }

        this._reportMetricTimer = setTimeout(this.reportMetric.bind(this), METRIC_REPORT_INTERVAL)
    }

    private getMethodName(code: number) {
        return Wukong.DocumentProto.WasmCallCode[code] || 'unknown_' + code
    }

    private reportMetric() {
        // reportComputedPropMetric 会发起一个 wasm call，如果先 clear timer，会导致再启一个 timer
        // 从而当用户无操作的时候，也会不断上报
        // 所以先 reportComputedPropMetric，再 clear timer
        const computedPropMetrics = this.collectComputedPropMetric()
        const renderDurationMetrics = this.renderDurationMetric()

        clearTimeout(this._reportMetricTimer)
        this._reportMetricTimer = 0 as any

        const bridgeCallMetrics = this.collectBridgeCallMetric()

        MetricCollector.reportMetrics([...computedPropMetrics, ...renderDurationMetrics, ...bridgeCallMetrics])
    }

    private collectComputedPropMetric(): Metric[] {
        const counters = this.call(GetComputedPropsReadCountAndRestCounterCommand)?.counters
        if (!counters) {
            return []
        }
        const metrics: Metric[] = []
        for (const [computedPropName, readCount] of Object.entries(counters)) {
            metrics.push({
                metricName: MetricName.COMPUTED_PROP_READ_COUNT,
                value: readCount,
                extraLabelName2Value: {
                    computedPropName: computedPropName,
                },
            })
        }
        return metrics
    }

    private collectBridgeCallMetric(): Metric[] {
        const report = this._bridgeMetricCollector.report()
        const metrics: Metric[] = []
        // 遍历 report.duration.entries，转成 Metric
        for (const [methodCode, buckets] of report.duration.entries()) {
            const bucketByLe = new Map<string, RangeBucket>()
            buckets.forEach((bucket) => {
                const le = bucket.max === Infinity ? '+Inf' : String(bucket.max)
                bucketByLe.set(le, bucket)
            })

            // 累积每个 bucket 的 count
            let accCount = 0
            DURATION_BUCKETS.forEach((le) => {
                const bucket = bucketByLe.get(le)
                accCount += bucket?.count || 0
                metrics.push({
                    metricName: MetricName.WASM_BRIDGE_CALL_DURATION,
                    value: accCount,
                    extraLabelName2Value: {
                        method: this.getMethodName(methodCode),
                        le,
                    },
                })
                metrics.push({
                    metricName: MetricName.WASM_BRIDGE_CALL_V2_DURATION,
                    value: bucket?.count || 0,
                    extraLabelName2Value: {
                        method: this.getMethodName(methodCode),
                    },
                })
            })

            metrics.push({
                metricName: MetricName.WASM_BRIDGE_CALL_COUNT,
                value: report.requestCounter.get(methodCode) || 0,
                extraLabelName2Value: {
                    method: this.getMethodName(methodCode),
                },
            })

            metrics.push({
                metricName: MetricName.WASM_BRIDGE_CALL_SUM,
                value: report.durationSum.get(methodCode) || 0,
                extraLabelName2Value: {
                    method: this.getMethodName(methodCode),
                },
            })
        }

        for (const [methodCode, count] of report.errorCounter.entries()) {
            metrics.push({
                metricName: MetricName.WASM_BRIDGE_CALL_ERROR_COUNT,
                value: count,
                extraLabelName2Value: {
                    method: this.getMethodName(methodCode),
                },
            })
        }

        return metrics
    }

    private renderDurationMetric(): Metric[] {
        const buckets = this.call(GetAndClearRenderDurationBuckets)?.buckets
        if (!buckets) {
            return []
        }
        const metrics: Metric[] = []
        for (const [index, count] of buckets.entries()) {
            metrics.push({
                metricName: MetricName.RENDER_UNTIL_STABLE_DURATION,
                value: count,
                extraLabelName2Value: {
                    // 和 RenderDurationMetric.cpp 逻辑保持一致
                    le: Math.pow(2, index + 4),
                },
            })
        }
        return metrics
    }

    private warnDestroyed(code: number) {
        if (!this._destroyed) {
            return false
        }

        // 防止刷屏
        this._rejectCounter += 1
        if (this._rejectCounter < 100) {
            console.warn('wasm 已经关闭，不应该存在新的调用。reject call: ', code)
        }
        return true
    }

    private recordWasmCallBegin<RET, ARG>(
        encodedArg: Uint8Array | undefined,
        method: MethodSignature<RET, ARG>,
        arg: ARG | undefined
    ) {
        this._bridgeMetricCollector.incRequestCounter(method.code)
        if (!encodedArg) {
            this.record(method, {
                action: Wukong.DocumentProto.BridgeAction.BRIDGE_ACTION_WASM_CALL_BEGIN,
                code: method.code,
                perfNow: performance.now(),
            })
            return
        }

        this.record(method, {
            action: Wukong.DocumentProto.BridgeAction.BRIDGE_ACTION_WASM_CALL_BEGIN,
            code: method.code,
            args: this.encodeWasmCallBegin(method, arg, encodedArg),
            perfNow: performance.now(),
        })
    }

    crash = (e: Error) => {
        console.error(e)
        // 只捕获第一个 crash 的信息
        if (this.states.getState().crashed == null) {
            const crashType = getCrashType(e, this.isGpuLost())
            this.states.getState().setCrashed({ error: e, recordingName: this.currentRecordingName, crashType })
        }
        // 但会在浏览器中抛出每个 crash 信息
        throw e
    }

    private record(method: MethodSignature<any, any>, bridgeCall: Wukong.DocumentProto.IBridgeCall) {
        if (this.disableRecord) {
            return
        }

        if (!method.skipRecording && this.currentRecordingName && !IN_JEST_TEST) {
            recordBridgeCall(this.currentRecordingName, this.currentEditorService._bridgeCallCounter++, bridgeCall)
        }
    }

    private encodeWasmCallBegin(method: MethodSignature<any, any>, arg: any, encodedArg: Uint8Array) {
        if (method.argumentType === Wukong.DocumentProto.Arg_loadFont) {
            return method.argumentType.encodeDelimited({ ...arg, data: null })!.finish()
        }
        return copyBytes(encodedArg)
    }

    jsCall = (methodCode: number, argPtr: number): number => {
        if (this.warnDestroyed(methodCode)) {
            return 0
        }

        const method = this.basicBridgeImpl.methods[methodCode]
        if (!method) {
            if (!isSandBox) {
                const errMsg = `请求的 JS 方法 ${methodCode} 不存在`
                console.error(errMsg)
                Sentry.captureException(new Error(errMsg))
            }
            return 0
        }

        const ret = this.basicBridgeImpl.jsCall(methodCode, this.currentEditorService.HEAPU8.subarray(argPtr))
        if (ret) {
            return this.currentEditorService.copy1dArray(ret, HEAPDest.HEAPU8)
        } else {
            return 0
        }
    }

    destroy = () => {
        if (this._destroyed) {
            return
        }
        this._destroyed = true
        this._bridgeMetricCollector.destroy()
        if (this._reportMetricTimer) {
            clearTimeout(this._reportMetricTimer)
        }
        this.unbindAllJsCalls()
        delete WK.wasmCall
        delete WK.jsCall
    }

    // NOTE: 在销毁前自动解绑所有 jsCall
    private unbindAllJsCalls() {
        AllJsCallSignatures.forEach((signature: MethodSignature<any, any>) => {
            this.unbind(signature)
        })
        const unbindedCodes = Object.keys(this.basicBridgeImpl.methods)
        if (unbindedCodes.length !== 0) {
            // 这里仍报错，理论上不会出现，AllJsCallSignatures 就是全集了
            throw new Error(
                '关闭 wasm 时存在未解绑的 JsCall: ' +
                    unbindedCodes.map((code) => JsCallCodeToNameMap[parseInt(code, 10)]).join(',')
            )
        }
    }

    get destroyed() {
        return this._destroyed
    }

    // NOTE: 没有绑定的自动绑定空实现。空实现应被视为「不需要实现」所以不应该被实际调用到，调用到会警告（报错目前会挂，说明有预期之外的调用）
    autofillUnbindedJsCallsWithErrorCallback() {
        AllJsCallSignatures.forEach((signature) => {
            if (!this.basicBridgeImpl.methods[signature.code]) {
                this.bind(signature as MethodSignature<any, any>, () => {
                    if (!environment.isProduction) {
                        console.warn(`JsCall: ${JsCallCodeToNameMap[signature.code]} 未实现`)
                    }
                    return {}
                })
            }
        })
    }

    public isGpuLost() {
        if (this.currentEditorService.getState() != EditorServiceState.Ready) {
            // 如果 wasm 未初始化，或者已经销毁，则不是 gpu lost
            return false
        }

        const state = this.currentEditorService.getCanvasState()
        if (!state) {
            // 如果未创建出 canvas state，则不是 gpu lost
            return false
        }

        switch (state.type) {
            case CanvasStateType.WebGL:
                // 对于 WebGL，context lost 是 gpu lost
                return state.context.isContextLost()
            case CanvasStateType.WebGPU:
                // 对于 WebGPU，device lost 是 gpu lost
                return !state.device
            case CanvasStateType.Null:
                // 如果 canvas state 为 null，则不是 gpu lost
                return false
            default:
                // 其他情况，认为不是 gpu lost
                // 目前不存在其他情况
                return false
        }
    }
}
