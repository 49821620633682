/* eslint-disable no-restricted-imports */
import { useCallback } from 'react'
import { useCooperationService } from '../../../../main/app-context'

export function useCooperationCursor() {
    const { useZustandStore } = useCooperationService()
    const coactorSessionIds = useZustandStore.use.coactorSessionIds()
    return { coactorSessionIds }
}

export function useCooperationCursorItem(props: { coactorSessionId: number }) {
    const { handleAppendOrRemoveCoactorCursor } = useCooperationService()

    const mountOrUnmountCoactorCursorDom = useCallback(
        (element: HTMLElement | null) => {
            handleAppendOrRemoveCoactorCursor(props.coactorSessionId, element)
        },
        [handleAppendOrRemoveCoactorCursor, props.coactorSessionId]
    )

    return { mountOrUnmountCoactorCursorDom }
}
