import { SetPrototypeTransitionResetScrollPositionEditHistory, Wukong } from '@wukong/bridge-proto'
import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DraggablePopupV2, Position } from '../../../../../../ui-lib/src'
import { featureSwitchManager } from '../../../../kernel/switch/core'
import { useLeftMargin, useTopMargin } from '../../../../main/layout/layout-context'
import { useCommand } from '../../../context/document-context'
import { usePrototypeInteractionContext } from '../../../context/prototype-interaction-context'
import { useImperativeViewport } from '../../../hooks/use-imperative-viewport'
import { EventEmitter } from '../../../utils/event-emmitter'
import { toCanvasRelativePosition } from '../../../utils/viewport'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { useEventWheel } from '../../comment/comment-service/use-event-wheel'
import {
    getInteractionTypeList,
    InteractionActionType,
    InteractionsWithEventType,
    MIXED_TYPE,
    PrototypeInteractionSelectedTargetNode,
    PrototypeInteractionTargetNode,
    PrototypeInteractionWithNodeId,
} from '../prototype-interaction/constants'
import {
    canSelectOuterTargets,
    canSelectTargets,
    changeInteractionActionType,
    getActionData,
    getResetScrollPositionState,
} from '../prototype-interaction/utils'
import { ActionAnimationPreview, type CubicBezierNumber } from './interaction-action-animation/action-animation-preview'
import { AnimationEasingFunctionInput } from './interaction-action-animation/animation-easing-function/animation-easing-function'
import { AnimationEasingSpringFunction } from './interaction-action-animation/animation-easing-function/animation-easing-spring-function'
import { isSpringEasingType } from './interaction-action-animation/animation-easing-function/spring-util'
import { AnimationEasingTypeAndDuration } from './interaction-action-animation/animation-easing-type-and-duration/animation-easing-type-and-duration'
import { InteractionActionTransitionTypeSelect } from './interaction-action-animation/animation-transition-type/animation-transition-type'
import { TransitionShouldSmartAnimateCheckbox } from './interaction-action-animation/transition-should-smart-animate/transition-should-smart-animate'
import type { AnimationTransitionTypeCommandProps } from './interaction-action-animation/use-action-animation-commands'
import { useInteractionActionAnimationViewState } from './interaction-action-animation/use-action-animation-view-state'
import { InteractionActionSelect } from './interaction-action/interaction-action-select'
import { InteractionActionTargetSelect } from './interaction-action/interaction-action-target-select'
import { InteractionActionOpenLink } from './interaction-action/open-link'
import { InteractionActionOverlaySettings } from './interaction-action/overlay-settings'
import { InteractionActionScrollTo } from './interaction-action/scroll-to'
import { InteractionTrigger } from './interaction-trigger'
import { StateManagement } from './state-management'

import InteractionType = Wukong.DocumentProto.InteractionType
import ScrollToTargetNodeList = Wukong.DocumentProto.IScrollToTargetNodeList
import OverlaySetting = Wukong.DocumentProto.IOverlaySetting

const DispatchWheelToCanvas = ({ children }: PropsWithChildren) => {
    const contentRef = useRef<HTMLDivElement | null>(null)
    const { dispatchWheelToCanvas } = useEventWheel()
    useEffect(() => {
        const contentEl = contentRef.current
        if (!contentEl) {
            return
        }
        return dispatchWheelToCanvas(contentEl)
    }, [dispatchWheelToCanvas])

    return <div ref={contentRef}>{children}</div>
}

interface InteractionPopupProps {
    data: PrototypeInteractionWithNodeId[]
    triggers: InteractionsWithEventType[]
    targetNodes: PrototypeInteractionTargetNode[]
    scrollToTargets?: ScrollToTargetNodeList
    selectedTargetNode: PrototypeInteractionSelectedTargetNode
    overlaySetting?: OverlaySetting
    isPresetMobile?: boolean
    openFrom: 'panel' | 'canvas'
    position?: Position
    canvasPopupPosition?: Wukong.DocumentProto.IPopupPosition
    onChange: (data: PrototypeInteractionWithNodeId[], options?: InputOptionsForUndoSquash) => void
    onCancel: () => void
    onMouseEnterTarget?: (id: string) => void
    onMouseLeaveTarget?: () => void
    onChangeOverlayPositionType: (id: string, positionType: Wukong.DocumentProto.OverlayPositionType) => void
    onChangeOverlayBackgroundInteraction: (
        id: string,
        backgroundInteraction: Wukong.DocumentProto.OverlayBackgroundInteraction
    ) => void
    onChangeOverlayBackgroundAppearance: (
        id: string,
        backgroundAppearance: Wukong.DocumentProto.IOverlayBackgroundAppearance,
        options?: InputOptionsForUndoSquash
    ) => void
}

const InteractionPopup = ({
    data,
    triggers,
    targetNodes,
    scrollToTargets,
    selectedTargetNode,
    overlaySetting,
    isPresetMobile,
    openFrom,
    canvasPopupPosition,
    position,
    onChange,
    onCancel,
    onMouseEnterTarget,
    onMouseLeaveTarget,
    onChangeOverlayPositionType,
    onChangeOverlayBackgroundInteraction,
    onChangeOverlayBackgroundAppearance,
}: InteractionPopupProps) => {
    const [disabledAnimation, setDisabledAnimation] = useState(false)
    const triggerType = useMemo(() => {
        const type = data[0].event.interactionType ?? InteractionType.INTERACTION_TYPE_NONE
        if (data.some((v) => v.event.interactionType !== type)) {
            return MIXED_TYPE
        }
        return type
    }, [data])
    const actionData = useMemo(() => {
        return getActionData({
            interactions: data,
        })
    }, [data])
    const actionType = actionData.type
    const isShowTargetSelect = useMemo(() => {
        if (actionType && canSelectOuterTargets(actionType)) {
            return true
        } else if (actionType === MIXED_TYPE) {
            return actionData.typeList.every((v) => canSelectTargets(v))
        }
        return false
    }, [actionData, actionType])
    const disabledTriggerTypes = useMemo(() => {
        const typeList = triggers.map((v) => v.event.interactionType)
        if (triggerType === MIXED_TYPE) {
            if (data.every((v) => v.sourceNodeId === data[0].sourceNodeId)) {
                return getInteractionTypeList()
                    .flat()
                    .filter(
                        (v) =>
                            v !== InteractionType.INTERACTION_TYPE_NONE && v !== InteractionType.INTERACTION_TYPE_DRAG
                    )
            } else {
                return []
            }
        }

        switch (actionType) {
            // openlink 只支持 click, mouse up 事件
            case InteractionActionType.OpenLink:
                return getInteractionTypeList()
                    .flat()
                    .filter(
                        (v) =>
                            (typeList.includes(v) && v !== triggerType) ||
                            (v !== InteractionType.INTERACTION_TYPE_ON_CLICK &&
                                v !== InteractionType.INTERACTION_TYPE_NONE &&
                                v !== InteractionType.INTERACTION_TYPE_MOUSE_UP)
                    )
            // back 不支持 hover, press 事件
            case InteractionActionType.Back:
                return getInteractionTypeList()
                    .flat()
                    .filter(
                        (v) =>
                            (typeList.includes(v) &&
                                v !== triggerType &&
                                v !== InteractionType.INTERACTION_TYPE_NONE &&
                                v !== InteractionType.INTERACTION_TYPE_DRAG) ||
                            v === InteractionType.INTERACTION_TYPE_ON_HOVER ||
                            v === InteractionType.INTERACTION_TYPE_ON_PRESS
                    )
            default:
                return getInteractionTypeList()
                    .flat()
                    .filter(
                        (v) =>
                            typeList.includes(v) &&
                            v !== triggerType &&
                            v !== InteractionType.INTERACTION_TYPE_NONE &&
                            v !== InteractionType.INTERACTION_TYPE_DRAG
                    )
        }
    }, [triggerType, triggers, actionType, data])
    const animationState = useInteractionActionAnimationViewState({
        isInteractionEventMixed: triggerType === MIXED_TYPE,
        isTransitionNodeIdsEmpty: selectedTargetNode.isEmpty,
        selectedInteractions: data,
        actionIndex: 0,
    })

    const [springEventEmitter] = useState(() => {
        return new EventEmitter()
    })
    const commandProps: AnimationTransitionTypeCommandProps = {
        data,
        onChange,
        actionIndex: 0,
        springEventEmitter,
    }
    const selectedTargetNodeId = selectedTargetNode.selectedNodes[0]?.id ?? ''

    const onChangeType = useCallback(
        (type: InteractionActionType) => {
            onChange(data.map((v) => changeInteractionActionType(type, v)))
        },
        [data, onChange]
    )
    const onChangeTarget = useCallback(
        (id: string) => {
            const newInteractions = data.map((v) => {
                const newAction = v.actions[0] ? { ...v.actions[0] } : ({} as Wukong.DocumentProto.IPrototypeAction)
                newAction.transitionNodeID = id
                return { ...v, actions: [newAction] }
            })
            onChange(newInteractions)
        },
        [data, onChange]
    )
    const onChangeLink = useCallback(
        (link: string) => {
            const newInteractions = data.map((v) => {
                const newAction = v.actions[0] ? { ...v.actions[0] } : ({} as Wukong.DocumentProto.IPrototypeAction)
                newAction.connectionURL = link
                return { ...v, actions: [newAction] }
            })
            onChange(newInteractions)
        },
        [data, onChange]
    )
    const onChangeOpenInNewTab = useCallback(
        (openInNewTab: boolean) => {
            const newInteractions = data.map((v) => {
                const newAction = v.actions[0] ? { ...v.actions[0] } : ({} as Wukong.DocumentProto.IPrototypeAction)
                newAction.openUrlInNewTab = openInNewTab
                return { ...v, actions: [newAction] }
            })
            onChange(newInteractions)
        },
        [data, onChange]
    )

    const command = useCommand()
    const resetScrollPositionState = useMemo(() => {
        return getResetScrollPositionState(data)
    }, [data])
    const onChangeResetScrollPosition = useCallback(
        (reset: boolean) => {
            command.DEPRECATED_invokeBridge(SetPrototypeTransitionResetScrollPositionEditHistory, { value: reset })
            const newInteractions = data.map((v) => {
                const newAction = v.actions[0] ? { ...v.actions[0] } : ({} as Wukong.DocumentProto.IPrototypeAction)
                newAction.transitionResetScrollPosition = reset
                return { ...v, actions: [newAction] }
            })
            onChange(newInteractions)
        },
        [data, onChange, command]
    )

    const continuousChangeStart = useCallback(() => {
        setDisabledAnimation(true)
    }, [])
    const continuousChangeEnd = useCallback(() => {
        setDisabledAnimation(false)
    }, [])

    const marginTop = useTopMargin()
    const marginLeft = useLeftMargin()
    const popupWidth = 240
    const offsetTop = 20
    const needSyncCanvasPosition = openFrom === 'canvas'
    const { viewport } = useImperativeViewport({ sync: needSyncCanvasPosition })
    const { left, top } = toCanvasRelativePosition({
        left: canvasPopupPosition?.x ?? 0,
        top: canvasPopupPosition?.y ?? 0,
        viewport,
    })

    const popupPosition = needSyncCanvasPosition
        ? {
              left: left + marginLeft + popupWidth / 2,
              top: top + marginTop + offsetTop,
          }
        : position
    const [hasMoved, setHasMoved] = useState(false)
    const zIndex = useMemo(() => {
        return openFrom === 'canvas' && !hasMoved ? 9 : undefined
    }, [openFrom, hasMoved])

    return (
        <DispatchWheelToCanvas>
            <DraggablePopupV2
                visible
                styleType="editor"
                position={popupPosition}
                header={
                    <InteractionTrigger
                        data={data}
                        type={triggerType}
                        disabledTypes={disabledTriggerTypes}
                        isPresetMobile={isPresetMobile}
                        onChange={onChange}
                    />
                }
                footer={null}
                bodyClassName="px-0 pt-2 pb-3"
                closeClassName="top-5!"
                positionRightBase
                testId="prototype-interaction-popup"
                onCancel={onCancel}
                style={{ zIndex }}
                useRawPosition={openFrom === 'canvas'}
                onFirstMove={() => setHasMoved(true)}
            >
                {actionType && (
                    <>
                        <div className="flex py-2">
                            <InteractionActionSelect type={actionType} onChange={onChangeType} />
                        </div>
                        {isShowTargetSelect && (
                            <InteractionActionTargetSelect
                                targetNodes={targetNodes}
                                selectedTargetNode={selectedTargetNode}
                                onChange={onChangeTarget}
                                onMouseEnterTarget={onMouseEnterTarget}
                                onMouseLeaveTarget={onMouseLeaveTarget}
                            />
                        )}
                        {actionType === InteractionActionType.OpenLink && (
                            <InteractionActionOpenLink
                                onChange={onChangeLink}
                                onChangeOpenInNewTab={onChangeOpenInNewTab}
                                openInNewTab={actionData.openInNewTab}
                                connectionUrls={actionData.connectionUrls}
                            />
                        )}
                        {actionType === InteractionActionType.ScrollTo && (
                            <InteractionActionScrollTo
                                data={data}
                                scrollToTargets={scrollToTargets}
                                selectedTargetNode={selectedTargetNode}
                                onChange={onChange}
                                onChangeTarget={onChangeTarget}
                                onMouseEnterTarget={onMouseEnterTarget}
                                onMouseLeaveTarget={onMouseLeaveTarget}
                            />
                        )}
                        {animationState && (
                            <>
                                <InteractionActionTransitionTypeSelect
                                    interactionType={triggerType}
                                    actionType={actionType}
                                    state={animationState.transitionType}
                                    commandProps={commandProps}
                                />
                                {featureSwitchManager.isEnabled('prototype-smart-animation') && (
                                    <TransitionShouldSmartAnimateCheckbox
                                        state={animationState.transitionShouldSmartAnimate}
                                        commandProps={commandProps}
                                    />
                                )}
                                <AnimationEasingTypeAndDuration
                                    easingType={animationState.easingType}
                                    transitionDuration={animationState.transitionDuration}
                                    commandProps={commandProps}
                                />
                                {featureSwitchManager.isEnabled('prototype-spring') &&
                                animationState.easingType.show &&
                                animationState.showPreviewArea &&
                                isSpringEasingType(animationState.easingType.value) ? (
                                    <AnimationEasingSpringFunction
                                        animationState={animationState}
                                        commandProps={commandProps}
                                    />
                                ) : null}
                                {!isSpringEasingType(animationState.easingType.value) && (
                                    <AnimationEasingFunctionInput
                                        state={animationState.easingFunction}
                                        commandProps={commandProps}
                                        continuousChangeStart={continuousChangeStart}
                                        continuousChangeEnd={continuousChangeEnd}
                                    />
                                )}
                                {animationState.showPreviewArea &&
                                    !isSpringEasingType(animationState.easingType.value) && (
                                        <ActionAnimationPreview
                                            transitionShouldSmartAnimate={animationState.transitionShouldSmartAnimate}
                                            durationMs={animationState.transitionDuration.value}
                                            transitionType={animationState.transitionType.value}
                                            easingFunction={animationState.easingFunction.value as CubicBezierNumber}
                                            disabledAnimation={disabledAnimation}
                                        />
                                    )}
                            </>
                        )}
                        {actionType === InteractionActionType.OpenOverlay &&
                        overlaySetting?.isShow &&
                        selectedTargetNodeId ? (
                            <InteractionActionOverlaySettings
                                type={triggerType}
                                positionType={overlaySetting.positionType}
                                backgroundInteraction={overlaySetting.backgroundInteraction}
                                backgroundAppearance={overlaySetting.backgroundAppearance}
                                onChangePositionType={(v) => onChangeOverlayPositionType(selectedTargetNodeId, v)}
                                onChangeBackgroundInteraction={(v) =>
                                    onChangeOverlayBackgroundInteraction(selectedTargetNodeId, v)
                                }
                                onChangeBackgroundAppearance={(v, opt) =>
                                    onChangeOverlayBackgroundAppearance(selectedTargetNodeId, v, opt)
                                }
                            />
                        ) : null}
                        {resetScrollPositionState.show && (
                            <StateManagement
                                checked={resetScrollPositionState.checked}
                                onChangeResetScrollPosition={onChangeResetScrollPosition}
                            />
                        )}
                    </>
                )}
            </DraggablePopupV2>
        </DispatchWheelToCanvas>
    )
}

export const PrototypeInteractionPopup = () => {
    const {
        isPresetMobile,
        showTriggers,
        openPopup,
        openCanvasPopup,
        popupPosition,
        canvasPopupPositionV2,
        outerTargetNodes,
        selectedInteractions,
        scrollToTargets,
        selectedTargetNode,
        overlaySetting,
        onPopupChange,
        onClosePopup,
        targetNodeEnterHover,
        targetNodeCancelHover,
        onChangeOverlayPositionType,
        onChangeOverlayBackgroundInteraction,
        onChangeOverlayBackgroundAppearance,
    } = usePrototypeInteractionContext()

    return (
        <>
            {(openPopup || openCanvasPopup) && selectedInteractions.length ? (
                <InteractionPopup
                    data={selectedInteractions}
                    triggers={showTriggers}
                    targetNodes={outerTargetNodes}
                    openFrom={openCanvasPopup ? 'canvas' : 'panel'}
                    position={popupPosition}
                    canvasPopupPosition={canvasPopupPositionV2}
                    scrollToTargets={scrollToTargets}
                    selectedTargetNode={selectedTargetNode}
                    overlaySetting={overlaySetting}
                    isPresetMobile={isPresetMobile}
                    onChange={onPopupChange}
                    onCancel={onClosePopup}
                    onMouseEnterTarget={targetNodeEnterHover}
                    onMouseLeaveTarget={targetNodeCancelHover}
                    onChangeOverlayPositionType={onChangeOverlayPositionType}
                    onChangeOverlayBackgroundInteraction={onChangeOverlayBackgroundInteraction}
                    onChangeOverlayBackgroundAppearance={onChangeOverlayBackgroundAppearance}
                />
            ) : null}
        </>
    )
}
