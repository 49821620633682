/* eslint-disable no-restricted-imports */
import { WKFrog } from '../../../kernel/frog'
import { WKFrogTaskStateService } from '../../../share/wk-frog-task'

export function getLdap() {
    return WKFrogTaskStateService.getUserVOV2()?.userBrief.email ?? ''
}

export const logs = {
    Plugins: {
        skewtool(customExtend: { ldap: string }) {
            WKFrog.addFrogRecord({
                url: '/expose/Plugins/skewtool',
                eventId: 22194,
                eventName: 'skewtool',
                eventAction: 'expose',
                customExtend,
            })
        },
        arctext(customExtend: { ldap: string }) {
            WKFrog.addFrogRecord({
                url: '/expose/Plugins/arctext',
                eventId: 22195,
                eventName: 'arctext',
                eventAction: 'expose',
                customExtend,
            })
        },
        rotatecopy(customExtend: { ldap: string }) {
            WKFrog.addFrogRecord({
                url: '/expose/Plugins/rotatecopy',
                eventId: 22196,
                eventName: 'rotatecopy',
                eventAction: 'expose',
                customExtend,
            })
        },
        looper(customExtend: { ldap: string }) {
            WKFrog.addFrogRecord({
                url: '/expose/Plugins/looper',
                eventId: 22197,
                eventName: 'looper',
                eventAction: 'expose',
                customExtend,
            })
        },
        ae(customExtend: { ldap: string }) {
            WKFrog.addFrogRecord({
                url: '/expose/Plugins/ae',
                eventId: 22198,
                eventName: 'ae',
                eventAction: 'expose',
                customExtend,
            })
        },
        fillruleeditor(customExtend: { ldap: string }) {
            WKFrog.addFrogRecord({
                url: '/expose/Plugins/fillruleeditor',
                eventId: 22199,
                eventName: 'fillruleeditor',
                eventAction: 'expose',
                customExtend,
            })
        },
        unsplash(customExtend: { ldap: string }) {
            WKFrog.addFrogRecord({
                url: '/expose/Plugins/unsplash',
                eventId: 22200,
                eventName: 'unsplash',
                eventAction: 'expose',
                customExtend,
            })
        },
        iconpark(customExtend: { ldap: string }) {
            WKFrog.addFrogRecord({
                url: '/expose/Plugins/iconpark',
                eventId: 22201,
                eventName: 'iconpark',
                eventAction: 'expose',
                customExtend,
            })
        },
        skewtoolReset(customExtend: { ldap: string }) {
            WKFrog.addFrogRecord({
                url: '/click/Plugins/skewtoolReset',
                eventId: 22202,
                eventName: 'skewtoolReset',
                eventAction: 'click',
                customExtend,
            })
        },
        skewtoolApply(customExtend: { ldap: string; layer_id: string }) {
            WKFrog.addFrogRecord({
                url: '/click/Plugins/skewtoolApply',
                eventId: 22203,
                eventName: 'skewtoolApply',
                eventAction: 'click',
                customExtend,
            })
        },
        arctextCreate(customExtend: { ldap: string }) {
            WKFrog.addFrogRecord({
                url: '/click/Plugins/arctextCreate',
                eventId: 22204,
                eventName: 'arctextCreate',
                eventAction: 'click',
                customExtend,
            })
        },
        looperApply(customExtend: { ldap: string; layer_id: string }) {
            WKFrog.addFrogRecord({
                url: '/click/Plugins/looperApply',
                eventId: 22205,
                eventName: 'looperApply',
                eventAction: 'click',
                customExtend,
            })
        },
        rotatecopyStartCopy(customExtend: { ldap: string }) {
            WKFrog.addFrogRecord({
                url: '/click/Plugins/rotatecopyStartCopy',
                eventId: 22206,
                eventName: 'rotatecopyStartCopy',
                eventAction: 'click',
                customExtend,
            })
        },
        rotatecopyCancel(customExtend: { ldap: string }) {
            WKFrog.addFrogRecord({
                url: '/click/Plugins/rotatecopyCancel',
                eventId: 22207,
                eventName: 'rotatecopyCancel',
                eventAction: 'click',
                customExtend,
            })
        },
        rotatecopyDone(customExtend: { ldap: string }) {
            WKFrog.addFrogRecord({
                url: '/click/Plugins/rotatecopyDone',
                eventId: 22208,
                eventName: 'rotatecopyDone',
                eventAction: 'click',
                customExtend,
            })
        },
        aeRasterization(customExtend: { ldap: string }) {
            WKFrog.addFrogRecord({
                url: '/click/Plugins/aeRasterization',
                eventId: 22209,
                eventName: 'aeRasterization',
                eventAction: 'click',
                customExtend,
            })
        },
        aeExport(customExtend: { ldap: string; layer_id: string; connect_ae: '0' | '1' }) {
            WKFrog.addFrogRecord({
                url: '/click/Plugins/aeExport',
                eventId: 22210,
                eventName: 'aeExport',
                eventAction: 'click',
                customExtend,
            })
        },
        aeHelpDocumentLink(customExtend: { ldap: string }) {
            WKFrog.addFrogRecord({
                url: '/click/Plugins/aeHelpDocumentLink',
                eventId: 22211,
                eventName: 'aeHelpDocumentLink',
                eventAction: 'click',
                customExtend,
            })
        },
        fillruleeditorStatusOne(customExtend: { ldap: string }) {
            WKFrog.addFrogRecord({
                url: '/expose/Plugins/fillruleeditorStatusOne',
                eventId: 22212,
                eventName: 'fillruleeditorStatusOne',
                eventAction: 'expose',
                customExtend,
            })
        },
        fillruleeditorStatusTwo(customExtend: { ldap: string }) {
            WKFrog.addFrogRecord({
                url: '/expose/Plugins/fillruleeditorStatusTwo',
                eventId: 22213,
                eventName: 'fillruleeditorStatusTwo',
                eventAction: 'expose',
                customExtend,
            })
        },
        unsplashSearch(customExtend: { ldap: string; query: string }) {
            WKFrog.addFrogRecord({
                url: '/event/Plugins/unsplashSearch',
                eventId: 22214,
                eventName: 'unsplashSearch',
                eventAction: 'event',
                customExtend,
            })
        },
        unsplashInsertPicture(customExtend: { ldap: string }) {
            WKFrog.addFrogRecord({
                url: '/event/Plugins/unsplashInsertPicture',
                eventId: 22215,
                eventName: 'unsplashInsertPicture',
                eventAction: 'event',
                customExtend,
            })
        },
        unsplashRandomInsert(customExtend: { ldap: string }) {
            WKFrog.addFrogRecord({
                url: '/click/Plugins/unsplashRandomInsert',
                eventId: 22216,
                eventName: 'unsplashRandomInsert',
                eventAction: 'click',
                customExtend,
            })
        },
        unsplashPresetTheme(customExtend: { ldap: string; preset_button: string }) {
            WKFrog.addFrogRecord({
                url: '/click/Plugins/unsplashPresetTheme',
                eventId: 22217,
                eventName: 'unsplashPresetTheme',
                eventAction: 'click',
                customExtend,
            })
        },
        unsplashOfficialWebsiteLink(customExtend: { ldap: string }) {
            WKFrog.addFrogRecord({
                url: '/click/Plugins/unsplashOfficialWebsiteLink',
                eventId: 22218,
                eventName: 'unsplashOfficialWebsiteLink',
                eventAction: 'click',
                customExtend,
            })
        },
        iconparkInsertIcon(customExtend: { ldap: string }) {
            WKFrog.addFrogRecord({
                url: '/event/Plugins/iconparkInsertIcon',
                eventId: 22223,
                eventName: 'iconparkInsertIcon',
                eventAction: 'event',
                customExtend,
            })
        },
        iconparkSearch(customExtend: { ldap: string }) {
            WKFrog.addFrogRecord({
                url: '/event/Plugins/iconparkSearch',
                eventId: 22224,
                eventName: 'iconparkSearch',
                eventAction: 'event',
                customExtend,
            })
        },
    },
}
