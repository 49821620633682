import { WKFrog } from '../../kernel/frog'

// 支付流程-埋点
export const upgradePayment = {
    OrgSelectTeams() {
        WKFrog.addFrogRecord({
            url: '/expose/UpgradePayment/OrgSelectTeams',
            eventId: 26858,
            eventName: 'OrgSelectTeams',
            eventAction: 'expose',
        })
    },
    OrgAdjustSeats() {
        WKFrog.addFrogRecord({
            url: '/expose/UpgradePayment/OrgAdjustSeats',
            eventId: 26859,
            eventName: 'OrgAdjustSeats',
            eventAction: 'expose',
        })
    },
    OrgPayment() {
        WKFrog.addFrogRecord({
            url: '/expose/UpgradePayment/OrgPayment',
            eventId: 26860,
            eventName: 'OrgPayment',
            eventAction: 'expose',
        })
    },
    OrgPaymentClickPurchase() {
        WKFrog.addFrogRecord({
            url: '/click/UpgradePayment/OrgPaymentClickPurchase',
            eventId: 26864,
            eventName: 'OrgPaymentClickPurchase',
            eventAction: 'click',
        })
    },
    OrgPaymentPopup() {
        WKFrog.addFrogRecord({
            url: '/expose/UpgradePayment/OrgPaymentPopup',
            eventId: 26865,
            eventName: 'OrgPaymentPopup',
            eventAction: 'expose',
        })
    },
    OrgComplete() {
        WKFrog.addFrogRecord({
            url: '/expose/UpgradePayment/OrgComplete',
            eventId: 26861,
            eventName: 'OrgComplete',
            eventAction: 'expose',
        })
    },
    ProSelectTeamPopup() {
        WKFrog.addFrogRecord({
            url: '/expose/UpgradePayment/ProSelectTeamPopup',
            eventId: 26866,
            eventName: 'ProSelectTeamPopup',
            eventAction: 'expose',
        })
    },
    ProAdjustSeats() {
        WKFrog.addFrogRecord({
            url: '/expose/UpgradePayment/ProAdjustSeats',
            eventId: 26867,
            eventName: 'ProAdjustSeats',
            eventAction: 'expose',
        })
    },
    ProPayment() {
        WKFrog.addFrogRecord({
            url: '/expose/UpgradePayment/ProPayment',
            eventId: 26868,
            eventName: 'ProPayment',
            eventAction: 'expose',
        })
    },
    ProPaymentClickPurchase() {
        WKFrog.addFrogRecord({
            url: '/click/UpgradePayment/ProPaymentClickPurchase',
            eventId: 26869,
            eventName: 'ProPaymentClickPurchase',
            eventAction: 'click',
        })
    },
    ProPaymentPopup() {
        WKFrog.addFrogRecord({
            url: '/expose/UpgradePayment/ProPaymentPopup',
            eventId: 26870,
            eventName: 'ProPaymentPopup',
            eventAction: 'expose',
        })
    },
    ProComplete() {
        WKFrog.addFrogRecord({
            url: '/expose/UpgradePayment/ProComplete',
            eventId: 26871,
            eventName: 'ProComplete',
            eventAction: 'expose',
        })
    },
}
