import { FC } from 'react'
import { Skeleton } from '../../../../../main/loading-container/skeleton'
import styles from './top.module.less'
import classNames from 'classnames'

export const TopAreaSkeleton: FC = () => {
    return (
        <div className={styles['top-area-container']}>
            <div className="flex items-center flex-1">
                <Skeleton type="rectangle" width="36" height="20" className="ml-16px mr-22px" />
                <Skeleton type="rectangle" width="280" height="20" />
            </div>

            <div className="flex items-center justify-center flex-1">
                <Skeleton type="rectangle" width="130" height="20" />
            </div>

            <div className="flex items-center flex-1 justify-end">
                <Skeleton type="round" width="24" height="24" />
                <Skeleton type="rectangle" width="120" height="20" className="ml-16px mr-19px" />
                <Skeleton type="rectangle" width="92" height="20" className="mr-11px" />
            </div>
        </div>
    )
}
