import { Wukong } from '@wukong/bridge-proto'
import { GetNonFunctionKeys, PartialAttributes, PreserveNonFunction, keys } from '../util/typing'
import {
    BaseNode,
    BooleanOperationNode,
    ComponentNode,
    ComponentSetNode,
    DocumentNode,
    EffectStyleNode,
    EllipseNode,
    FrameNode,
    GroupNode,
    InstanceNode,
    LineNode,
    NodeType,
    PageNode,
    PaintStyleNode,
    ParentInfo,
    PolygonNode,
    RectangleNode,
    StarNode,
    StyleContainerNode,
    TextNode,
    TextStyleNode,
    VectorNode,
} from './node'

export type PartialNode = Partial<
    PreserveNonFunction<
        Omit<DocumentNode, 'type' | 'parentInfo'> &
            Omit<PageNode, 'type' | 'parentInfo'> &
            Omit<FrameNode, 'type' | 'parentInfo'> &
            Omit<GroupNode, 'type' | 'parentInfo'> &
            Omit<BooleanOperationNode, 'type' | 'parentInfo'> &
            Omit<VectorNode, 'type' | 'parentInfo'> &
            Omit<StarNode, 'type' | 'parentInfo'> &
            Omit<LineNode, 'type' | 'parentInfo'> &
            Omit<EllipseNode, 'type' | 'parentInfo'> &
            Omit<PolygonNode, 'type' | 'parentInfo'> &
            Omit<RectangleNode, 'type' | 'parentInfo'> &
            Omit<TextNode, 'type' | 'parentInfo'> &
            Omit<ComponentSetNode, 'type' | 'parentInfo'> &
            Omit<ComponentNode, 'type' | 'parentInfo'> &
            Omit<InstanceNode, 'type' | 'parentInfo'> &
            Omit<PaintStyleNode, 'type' | 'parentInfo'> &
            Omit<EffectStyleNode, 'type' | 'parentInfo'> &
            Omit<TextStyleNode, 'type' | 'parentInfo'> &
            Omit<StyleContainerNode, 'type' | 'parentInfo'> & {
                type: NodeType
                parentInfo: ParentInfo | null
            }
    >
>

export type NodeAttributes = PartialAttributes<PartialNode>

export type NodeProperties = GetNonFunctionKeys<Required<BaseNode>>

function convertProtoPropToTsProp(propKey: keyof typeof Wukong.DocumentProto.NodeProps): NodeProperties {
    return propKey.slice(3) as NodeProperties
}

type NodePropsType = Record<NodeProperties, number> & Record<number, NodeProperties>

/**
 * 存储了所有 {@link Wukong.DocumentProto.NodeProps} 里 enum_key 和 enum_value 的映射转换
 * 其中 enum_key 是 {@link NodeProperties}，即 {@link BaseNode} 中所有的属性
 *
 * example:
 *  NodeProps.autoRename = 101,
 *  NodeProps.101 = 'autoRename'
 */
export const NodeProps: NodePropsType = keys(Wukong.DocumentProto.NodeProps).reduce((prev, curr) => {
    const prop = convertProtoPropToTsProp(curr)

    prev[prop] = Wukong.DocumentProto.NodeProps[curr]
    prev[Wukong.DocumentProto.NodeProps[curr]] = prop
    return prev
}, {} as NodePropsType)

export function decodeNodeJson(jsonStr: string) {
    const nodePair = JSON.parse(jsonStr) as [Wukong.DocumentProto.NodeProps, any][]
    const node: NodeAttributes = {}
    nodePair.forEach((pair) => {
        node[NodeProps[pair[0]]] = pair[1]
    })
    return node
}
