import { domLocation, RouteToken } from '../../../../../util/src'
import { GetOrganizationWorkspaceTeamList } from '../../../kernel/request/organizations'
import { SpaceNotifyFlow } from './notify-flow'
import { getSpaceStore, OrganizationManageOperationStore, setSpaceStore, SpaceStoreType } from './types'

export const generateOrganizationManageOperationState = (
    set: setSpaceStore,
    get: getSpaceStore
): OrganizationManageOperationStore => {
    return {
        // 企业下所有工作区以及工作区下的团队[仅管理员可请求]
        workspaceWithTeams: [],
        fetchWorkspaceWithTeams: async () => {
            const orgId = get().organizationStore.organization.id
            const list = await new GetOrganizationWorkspaceTeamList(orgId).start()
            set((state) => {
                state.organizationManageOperationStore.workspaceWithTeams = list
            })
        },
    }
}

const isInOrganizationOperationPage = () => domLocation().pathname.includes(`${RouteToken.Admin}/activity`)

export const syncOrganizationManageOperationState = (store: SpaceStoreType, notifyFlow: SpaceNotifyFlow) => {
    notifyFlow.addCreateWorkspaceCallback(() => {
        if (isInOrganizationOperationPage()) {
            store.getState().organizationManageOperationStore.fetchWorkspaceWithTeams()
        }
    })
    notifyFlow.addRemoveWorkspaceCallback(() => {
        if (isInOrganizationOperationPage()) {
            store.getState().organizationManageOperationStore.fetchWorkspaceWithTeams()
        }
    })
    notifyFlow.addUpdateWorkspaceCallback(() => {
        if (isInOrganizationOperationPage()) {
            store.getState().organizationManageOperationStore.fetchWorkspaceWithTeams()
        }
    })
    notifyFlow.addTeamRelationChangeCallback(() => {
        if (isInOrganizationOperationPage()) {
            store.getState().organizationManageOperationStore.fetchWorkspaceWithTeams()
        }
    })
}
