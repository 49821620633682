import { ImageLibWorker } from './image-lib'
import type { ImageFormat, ImageLibContext } from './types'

export { compressBitmapSync, decompressBitmapSync } from './bitmap-compress'
export { getJpegFileDPI } from './jpeg-parser'
export { getPNGFileDPI, kDefaultDPI, type ImageDpiInfo } from './png-chunks'

import { ColorSpace } from '../types'
import { isJest } from './utils'

export function removeICCChunksAndGetMeta(cx: ImageLibContext, source: Uint8Array) {
    return ImageLibWorker.get().removeICCChunksAndGetMeta(cx, source)
}

export function replaceImageICCToDisplayP3(cx: ImageLibContext, data: Uint8Array, type: ImageFormat) {
    return ImageLibWorker.get().replaceImageICCToDisplayP3(cx, data, type)
}

export function decode(cx: ImageLibContext, buffer: ArrayBuffer) {
    return ImageLibWorker.get().decode(cx, buffer)
}

export async function preloadImageLib(cx: ImageLibContext) {
    // 测试中也不能跑
    if (cx.env.isDev || isJest()) {
        return Promise.resolve()
    }

    // 不阻塞
    return ImageLibWorker.get().preload(cx)
}

export function encodeCompressPng(
    cx: ImageLibContext,
    data: Uint8Array,
    width: number,
    height: number,
    colorProfile: ColorSpace
) {
    if (isJest()) {
        // jest 不支持 worker，mock 一个空返回用来测导出流程
        return Promise.resolve(new Uint8Array())
    }
    return ImageLibWorker.get().encodeCompressPng(cx, data, width, height, colorProfile)
}

export function encodeAvif(
    cx: ImageLibContext,
    data: Uint8Array,
    width: number,
    height: number,
    colorProfile: ColorSpace
) {
    if (isJest()) {
        // jest 不支持 worker，mock 一个空返回用来测导出流程
        return Promise.resolve(new Uint8Array())
    }
    return ImageLibWorker.get().encodeAvif(cx, data, width, height, colorProfile)
}

export function decompressBitmap(cx: ImageLibContext, data: Uint8Array) {
    return ImageLibWorker.get().decompressBitmap(cx, data)
}
