import { getTranslationValue } from '../../../../../../../../../../util/src/i18n'

export const zhTranslation = {
    Auto: '自动',
    Mixed: '多个值',
    CommonVerticalGap: '间距',
    CommonHorizontalGap: '间距',
    VerticalGap: '垂直间距',
    HorizontalGap: '水平间距',
    ApplyVariable: '使用变量',
    DetachVariable: '分离变量',
} as const

export const enTranslation = {
    Auto: 'Auto',
    Mixed: 'Mixed',
    CommonVerticalGap: 'Vertical gap between items',
    CommonHorizontalGap: 'Horizontal gap between items',
    VerticalGap: 'Vertical gap between items',
    HorizontalGap: 'Horizontal gap between items',
    ApplyVariable: 'Apply variable',
    DetachVariable: 'Detach variable',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
