import { useMemo } from 'react'
import { capitalizeFirstLetter, timeDesc3 } from '../../../../../../../util/src'
import { LineIconWrapper } from '../line-icon-wrapper/line-icon-wrapper'
import { AutoItem, IconType } from '../type'
import { UserList } from '../user-list/user-list'
import classes from './auto-item-component.module.less'

export interface AutoItemComponentProps {
    item: AutoItem
    isFirstItem: boolean
    isLastItem: boolean
}
export function AutoItemComponent(props: AutoItemComponentProps) {
    const { item, isFirstItem, isLastItem } = props

    const itemWithIcon = useMemo(() => {
        return { ...item, iconType: IconType.Normal }
    }, [item])

    return (
        <LineIconWrapper
            item={isLastItem ? itemWithIcon : item}
            hideUpperHalf={isFirstItem}
            hideLowerHalf={isLastItem}
            dataTestIds={{
                container: 'history-list-item',
            }}
        >
            <div className={classes.timeContainer}>
                <div className={classes.time}>{capitalizeFirstLetter(timeDesc3(item.time))}</div>
            </div>
            <UserList primaryUser={item.primaryUser} secondaryUsers={item.secondaryUsers} />
        </LineIconWrapper>
    )
}
