export namespace MotiffVM {
    export type PrototypeProp<T> = Omit<PropertyDescriptor, 'get' | 'set'> & {
        key: string
        get?: () => T
        set?: (value: T) => void
        value?: T
    }
    export type FullPrototypeProp<T> = Required<PrototypeProp<T>>
    export type GetPropType<T> = T extends PrototypeProp<infer U> ? U : unknown
    export type Prototype = Record<string, PrototypeProp<any>>
    export interface EvalResult<T> {
        success: boolean
        handle?: T
        message?: string
        error?: Error
    }

    export function defineProperty<T extends Record<string, any>>(
        targetObject: T,
        props: {
            [k in keyof T]?: MotiffVM.PrototypeProp<NonNullable<T[k]>>
        }
    ) {
        Object.entries(props).forEach(([key, prop]) => {
            // 为每个属性更新名称
            prop.key = key
            Object.defineProperty(targetObject, key, prop)
        })
    }

    export const vm = {
        logstr(msg: string) {
            return `[Motiff VM] ${msg}`
        },
        safeEval<T extends any>(code: string) {
            const result: EvalResult<T> = {
                success: false,
            }

            try {
                result.handle = eval(code)
                result.success = true
            } catch (e) {
                result.success = false
                result.error = e as Error
                result.message = (e as Error).message
            }

            return result
        },
        newObject(name: string, props: Prototype) {
            const result = vm.safeEval<object>(`(function ${name}() {}).prototype`)
            if (!result.success) {
                console.error(result)
                throw Error(this.logstr('failed to new object ' + name))
            }

            const instance = Object.create(result.handle!)
            defineProperty(instance, props)
            return instance
        },
    }

    export type VM = typeof vm
}
