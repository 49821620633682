import {
    FeatureSwitchConfig,
    SwitchEnvironmentScopeType,
    SwitchStrategyType,
    UserGroupType,
} from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'paste-from-figma',
    owner: '',
    description: '',
    strategies: [
        {
            config: {
                type: SwitchStrategyType.SPECIFIC_SUFFIX,
                suffix: ['@kanyun.com'],
            },
            env: SwitchEnvironmentScopeType.ALL,
        },
        {
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                group: UserGroupType.FENBI,
            },
            env: SwitchEnvironmentScopeType.ALL,
        },
        {
            config: { type: SwitchStrategyType.SPECIFIC_USER_LIST, group: UserGroupType.ODA },
            env: SwitchEnvironmentScopeType.ALL,
        },
        {
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                group: UserGroupType.BASIC_RD_ALL,
            },
            env: SwitchEnvironmentScopeType.ALL,
        },
        {
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                group: UserGroupType.CYPRESS,
            },
            env: SwitchEnvironmentScopeType.ALL,
        },
        {
            config: {
                type: SwitchStrategyType.SPECIFIC_SUFFIX,
                suffix: ['@bytedance.com'],
            },
            env: SwitchEnvironmentScopeType.PRODUCTION,
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
