import { WKDialog, WKTextButton } from '../../../../../ui-lib/src'
import { domLocation, getMotiffName } from '../../../../../util/src'
import { useComponentService } from '../../../main/app-context'
import { useMenuBack } from '../top-area/menu/menu-config/back'
import { translation } from './jump-to-figma-style-modal.translation'

export function JumpToFigmaStyleModal() {
    const componentService = useComponentService()
    const jumpToFigmaStyleModal = componentService.states.use.jumpToFigmaStyleModalState()
    const visible = jumpToFigmaStyleModal.visible

    const { link } = useMenuBack()

    const openComponentMigration = () => {
        window.open(`${domLocation().origin}${link}?open-lib-migration=true`, '_blank')
    }

    return (
        <WKDialog
            visible={visible}
            okText={translation('Ok')}
            bodyStyle={{ padding: 0 }}
            title={translation('UnableGoToStyleFile')}
            cancelButtonProps={{ style: { display: 'none' } }}
            onOk={componentService.closeJumpToFigmaStyleModal}
            onCancel={componentService.closeJumpToFigmaStyleModal}
            width={400}
            testId="jump-figma-style-dialog"
        >
            <div className="px-6 py-5">
                {translation('UnableGoToMainDesc1')}
                <WKTextButton type={'primary'} size={12} onClick={openComponentMigration}>
                    {translation('ComponentMigration')}
                </WKTextButton>
                {translation('UnableGoToMainDesc2', { name: getMotiffName() })}
            </div>
        </WKDialog>
    )
}
