import { translation } from './canvas-search-result-list.translation'
/* eslint-disable no-restricted-imports */
import {
    CanvasSearchResultScrollToLayerItemCommand,
    UpdateCanvasSearchResultViewportHeight,
    UpdateCanvasSearchResultViewportScrollTop,
    Wukong,
} from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Scrollbar, WKTextButton } from '../../../../../ui-lib/src'
import { isMetaKey, isShiftKey } from '../../../kernel/util/hotkey'
import { isCombo, isKey, KeyboardCode, ModifierKeysCombo } from '../../../kernel/keyboard/keyboard-event-handler'
import { useCanvasSearchService, useCommand } from '../../../main/app-context'
import {
    shouldPassThisEventToWasmWhenCanvasSearchResultFocused,
    shouldPassThisEventToWasmWhenCanvasSearchResultFocusedOnlyForView,
} from '../../../main/service/focus-view/keyboard-event-prevent-or-pass'
import { dispatchKeyboardEventToWasm } from '../../../main/service/focus-view/keyboard-event-to-wasm'
import { useViewState } from '../../../view-state-bridge'
import { useClipboardService } from '../../context/document-context'
import { useVirtualListContainer } from '../../hooks/virtual-list'
import { CanvasSearchPageHeader } from './canvas-search-page-header'
import { CanvasSearchResultLayerItem } from './canvas-search-result-layer-item'
import { CanvasSearchResultListVirtualItem } from './canvas-search-result-list-virtual-item'
import styles from './canvas-search-result-list.module.less'
import { CanvasSearchParamModel, CanvasSearchResultModel } from './use-canvas-search-model'

interface ModelProps {
    canvasSearchParamModel: CanvasSearchParamModel
    canvasSearchResultModel: CanvasSearchResultModel
}

function useTempMultiSelectionMode(canvasSearchResultModel: CanvasSearchResultModel) {
    const keydownData = useRef({ metaKey: false, shiteKey: false })
    const updateTempMultiSelectionMode = (event: React.KeyboardEvent) => {
        if (event.type == 'keydown') {
            if (isMetaKey(event.nativeEvent)) {
                keydownData.current.metaKey = true
            }
            if (isShiftKey(event.nativeEvent)) {
                keydownData.current.shiteKey = true
            }
        } else {
            if (isMetaKey(event.nativeEvent)) {
                keydownData.current.metaKey = false
            }
            if (isShiftKey(event.nativeEvent)) {
                keydownData.current.shiteKey = false
            }
        }

        if (event.metaKey || event.shiftKey) {
            canvasSearchResultModel.modelCommand.enterTempMultiSelectionMode()
        } else {
            canvasSearchResultModel.modelCommand.leaveTempMultiSelectionMode()
        }
    }

    return { updateTempMultiSelectionMode }
}

export function CanvasSearchResultList({ canvasSearchParamModel, canvasSearchResultModel }: ModelProps) {
    const modelState = canvasSearchResultModel.modelState!
    const totalCount = Number(modelState.totalCount)
    const [needTopBorder, setNeedTopBorder] = useState(false)

    const canvasSearchService = useCanvasSearchService()

    const command = useCommand()
    const handleResize = useCallback(
        async (width: number, height: number) => {
            command.DEPRECATED_invokeBridge(UpdateCanvasSearchResultViewportHeight, { value: height })
        },
        [command]
    )

    const handleScroll = useCallback(
        (scrollTop: number) => {
            setNeedTopBorder(scrollTop != 0)
            command.DEPRECATED_invokeBridge(UpdateCanvasSearchResultViewportScrollTop, { value: scrollTop })
        },
        [command]
    )

    const { ref: containerRef } = useVirtualListContainer({
        onResize: handleResize,
        onScroll: handleScroll,
        deps: [],
    })

    const isCurrentPageScope =
        canvasSearchParamModel.modelState.scope ==
        Wukong.DocumentProto.CanvasSearchScope.CANVAS_SEARCH_SCOPE_CURRENT_PAGE

    const pageList = modelState.renderItems.map((renderItem) => {
        return (
            <CanvasSearchResultListVirtualItem
                key={renderItem.itemIndex}
                itemIndex={renderItem.itemIndex}
                itemHeight={renderItem.itemHeight}
                itemStartY={renderItem.itemStartY}
            >
                {renderItem.type ==
                Wukong.DocumentProto.CanvasSearchResultRenderItemType.CANVAS_SEARCH_RESULT_RENDER_ITEM_TYPE_HEADER ? (
                    <CanvasSearchPageHeader
                        title={renderItem.headerItem.name}
                        count={Number(renderItem.headerItem.totalCount)}
                        expand={renderItem.headerItem.expandStatus}
                        toggleExpand={() =>
                            canvasSearchResultModel.modelCommand.toggleExpand(renderItem.headerItem.pageId)
                        }
                    ></CanvasSearchPageHeader>
                ) : (
                    <CanvasSearchResultLayerItem
                        layerItem={renderItem.layerItem}
                        nodeIcon={renderItem.nodeIcon}
                        selected={renderItem.isSelected}
                        indirectSelected={renderItem.isIndirectSelected}
                        hovered={renderItem.isHovered}
                        canvasSearchResultModel={canvasSearchResultModel}
                        canvasSearchParamModel={canvasSearchParamModel}
                    ></CanvasSearchResultLayerItem>
                )}
            </CanvasSearchResultListVirtualItem>
        )
    })

    const { updateTempMultiSelectionMode } = useTempMultiSelectionMode(canvasSearchResultModel)

    const clipboardService = useClipboardService()

    const handleKeydownForCommon = (event: React.KeyboardEvent): boolean => {
        if (isCombo(event.nativeEvent, ModifierKeysCombo.META) && isKey(event.nativeEvent, KeyboardCode.F)) {
            canvasSearchService.invokeFocusInputAndSelectAll()
            event.preventDefault()
            return true
        }

        if (isKey(event.nativeEvent, KeyboardCode.ENTER)) {
            canvasSearchResultModel.modelCommand.onEnterKeyDown(isCombo(event.nativeEvent, ModifierKeysCombo.SHIFT))
            event.preventDefault()
            return true
        }

        if (isKey(event.nativeEvent, KeyboardCode.ARROW_UP)) {
            canvasSearchResultModel.modelCommand.prevLayerItem()
            event.preventDefault()
            return true
        }

        if (isKey(event.nativeEvent, KeyboardCode.ARROW_DOWN)) {
            canvasSearchResultModel.modelCommand.nextLayerItem()
            event.preventDefault()
            return true
        }

        if (isCombo(event.nativeEvent, ModifierKeysCombo.META) && isKey(event.nativeEvent, KeyboardCode.C)) {
            // TODO(chenyn): 焦点系统支持不过滤复制粘贴事件后, 不需要主动调用clipboardService
            clipboardService.copy()
            event.preventDefault()
            return true
        }

        if (isKey(event.nativeEvent, KeyboardCode.SPACE)) {
            // 允许默认翻页行为
            return true
        }

        if (isCombo(event.nativeEvent, ModifierKeysCombo.META) && isKey(event.nativeEvent, KeyboardCode.A)) {
            canvasSearchResultModel.modelCommand.selectAll()
            event.preventDefault()
            return true
        }

        if (isCombo(event.nativeEvent, ModifierKeysCombo.META) && isKey(event.nativeEvent, KeyboardCode.S)) {
            // 阻止默认保存弹窗
            event.preventDefault()
            return true
        }

        if (isKey(event.nativeEvent, KeyboardCode.ESCAPE)) {
            canvasSearchParamModel.modelCommand.exitSearch()
            event.preventDefault()
            return true
        }
        return false
    }

    const handleKeydownOnlyForView = (event: React.KeyboardEvent) => {
        // NOTE: 阻止冒泡, 防止同一次event被KeyboardReceiver重复转发到wasm
        event.stopPropagation()
        if (handleKeydownForCommon(event)) {
            return
        }

        if (shouldPassThisEventToWasmWhenCanvasSearchResultFocusedOnlyForView(event.nativeEvent)) {
            dispatchKeyboardEventToWasm(
                command,
                event.nativeEvent,
                Wukong.DocumentProto.KeyboardEventTraceSource.KEYBOARD_EVENT_TRACE_SOURCE_CANVAS_SEARCH
            )
        }
    }

    const handleKeydownForEditor = (event: React.KeyboardEvent) => {
        // NOTE: 阻止冒泡, 防止同一次event被KeyboardReceiver重复转发到wasm
        event.stopPropagation()
        updateTempMultiSelectionMode(event)
        if (handleKeydownForCommon(event)) {
            return
        }

        if (isCombo(event.nativeEvent, ModifierKeysCombo.META) && isKey(event.nativeEvent, KeyboardCode.V)) {
            clipboardService.paste()
            event.preventDefault()
        } else if (isCombo(event.nativeEvent, ModifierKeysCombo.META) && isKey(event.nativeEvent, KeyboardCode.X)) {
            clipboardService.cut()
            event.preventDefault()
        } else if (isKey(event.nativeEvent, KeyboardCode.DELETE) || isKey(event.nativeEvent, KeyboardCode.BACKSPACE)) {
            canvasSearchResultModel.modelCommand.deleteSeleted()
            event.preventDefault()
        } else if (shouldPassThisEventToWasmWhenCanvasSearchResultFocused(event.nativeEvent)) {
            dispatchKeyboardEventToWasm(
                command,
                event.nativeEvent,
                Wukong.DocumentProto.KeyboardEventTraceSource.KEYBOARD_EVENT_TRACE_SOURCE_CANVAS_SEARCH
            )
        }
    }

    const handleKeydown = (event: React.KeyboardEvent) => {
        if (canvasSearchParamModel.enableEdit) {
            handleKeydownForEditor(event)
        } else {
            handleKeydownOnlyForView(event)
        }
    }

    const handleKeyup = (event: React.KeyboardEvent) => {
        updateTempMultiSelectionMode(event)
    }

    useEffect(() => {
        canvasSearchService.setResultListScrollTopToCallback((value) => {
            containerRef.current?.scrollTop(value)
        })
    }, [canvasSearchService, containerRef])

    const canvasSearchResultLayerItemForScroll = useViewState('canvasSearchResultLayerItemForScroll')
    useEffect(() => {
        if (!canvasSearchResultLayerItemForScroll) {
            return
        }

        // NOTE: 需要等待Items渲染完成并更新完实际高度之后才能更准确的计算出滚动位置
        const reqId = requestAnimationFrame(() => {
            command.DEPRECATED_invokeBridge(
                CanvasSearchResultScrollToLayerItemCommand,
                canvasSearchResultLayerItemForScroll
            )
        })

        return () => cancelAnimationFrame(reqId)
    }, [command, canvasSearchResultLayerItemForScroll])

    return (
        <div className={classNames(styles.root, { [styles.topBorder]: needTopBorder })}>
            <Scrollbar ref={containerRef}>
                <div
                    tabIndex={-1}
                    onKeyDown={handleKeydown}
                    onKeyUp={handleKeyup}
                    style={{ height: `${modelState.renderItemTotalHeight}px`, overflow: 'auto', position: 'relative' }}
                >
                    {pageList}
                </div>
                {isCurrentPageScope && modelState.hasResultOnOtherPages ? (
                    <div
                        className={`${styles.otherPageResult} ${totalCount > 0 ? '' : styles.emptyResult}`}
                        onClick={() =>
                            canvasSearchParamModel.modelCommand.updateSearchScope(
                                Wukong.DocumentProto.CanvasSearchScope.CANVAS_SEARCH_SCOPE_DOC
                            )
                        }
                    >
                        <WKTextButton type="primary" size={12}>
                            {translation('SeeResultsOn')}
                        </WKTextButton>
                    </div>
                ) : null}
            </Scrollbar>
        </div>
    )
}
