/* eslint-disable @typescript-eslint/ban-types */

export const INTERNAL_SYMBOL = Symbol('i')
const FUNCTION_SYMBOL = Symbol('f')

export interface MainVMHandle {
    [INTERNAL_SYMBOL]: any
}

export type CppVMHandle = number

export type Handle = MainVMHandle | CppVMHandle

export interface DefinePropDescriptor {
    configurable?: boolean
    enumerable?: boolean
    writable?: boolean
    value?: Handle
    get?: () => Handle
    set?: (value: Handle) => void
}

interface CallSucceed {
    type: 'SUCCESS'
    handle: Handle
}

interface CallFailed {
    type: 'FAILURE'
    error: any
}

export type CallResult = CallSucceed | CallFailed

export type VMFunction = (...args: Handle[]) => Handle | void

export function isFunction(obj: any) {
    return typeof obj === 'object' && obj !== null && FUNCTION_SYMBOL in obj
}

export function getFunctionHandle(obj: any) {
    if (isFunction(obj)) {
        return obj[FUNCTION_SYMBOL]
    }
    return undefined
}

export abstract class BaseVM {
    destroyed = false

    abstract vmType: 'mainVM' | 'cppVM'
    abstract undefined: Handle
    abstract global: Handle
    abstract null: Handle
    abstract typeof(handle: Handle): string
    abstract isNumber(handle: Handle): boolean
    abstract getNumber(handle: Handle): number
    abstract newNumber(value: number): Handle
    abstract isString(handle: Handle): boolean
    abstract getString(handle: Handle): string
    abstract newString(value: string): Handle
    abstract isBoolean(handle: Handle): boolean
    abstract getBoolean(handle: Handle): boolean
    abstract newBoolean(value: boolean): Handle
    abstract toBoolean(value: Handle): boolean
    abstract isArray(handle: Handle): boolean
    abstract newArray(): Handle
    abstract isObject(handle: Handle): boolean
    abstract newObject(protoHandle?: Handle): Handle
    abstract isFunction(handle: Handle): boolean
    abstract isNull(handle: Handle): boolean
    abstract isUndefined(handle: Handle): boolean
    abstract isArrayBuffer(handle: Handle): boolean
    abstract getArrayBuffer(handle: Handle): ArrayBuffer
    abstract newArrayBuffer(value: ArrayBuffer): Handle
    abstract isUint8Array(handle: Handle): boolean
    abstract getUint8Array(handle: Handle): Uint8Array
    abstract newUint8Array(value: Uint8Array): Handle
    abstract isEqual(left: Handle, right: Handle): boolean
    abstract newFunction(name: string, func: VMFunction): Handle
    abstract newPromise(): {
        promise: Handle
        resolve: (value: Handle) => void
        reject: (reason: Handle) => void
    }
    abstract getProp(objHandle: Handle, prop: string): Handle
    abstract setProp(objHandle: Handle, prop: string, valueHandle: Handle): void
    abstract defineProp(objHandle: Handle, prop: string, descriptor: DefinePropDescriptor): void
    abstract callFunction(funcHandle: Handle, thisArgHandle: Handle, ...argHandles: Handle[]): CallResult
    abstract getKeys(objHandle: Handle): string[]
    abstract evalCode(code: string): CallResult
    abstract retainHandle(handle: Handle): void
    abstract releaseHandle(handle: Handle): void

    defineFunction(objHandle: Handle, name: string, func: VMFunction) {
        this.defineProp(objHandle, name, {
            value: this.newFunction(name, func),
            enumerable: false,
        })
    }

    getNumberValue(objHandle: Handle, key: string) {
        return this.getNumber(this.getProp(objHandle, key))
    }

    getStringValue(objHandle: Handle, key: string) {
        return this.getString(this.getProp(objHandle, key))
    }

    destroy() {
        this.destroyed = true
    }

    isDestroyed() {
        return this.destroyed
    }

    deepWrapHandle(value_: any) {
        let value = value_
        // 开个口子，如果传来的是 protobuf 的 message，则先转换为 json
        if (value_ && typeof value_.toJSON === 'function') {
            value = value.toJSON()
        }
        switch (typeof value) {
            case 'undefined':
                return this.undefined
            case 'boolean':
                return this.newBoolean(value)
            case 'number':
                return this.newNumber(value)
            case 'string':
                return this.newString(value)
            case 'object': {
                if (null === value) return this.null
                if (value instanceof Array) {
                    const array = this.newArray()
                    for (let i = 0; i < value.length; i++)
                        this.setProp(array, i.toString(), this.deepWrapHandle(value[i]))
                    return array
                }
                if (value instanceof ArrayBuffer) return this.newArrayBuffer(value)
                if (value instanceof Uint8Array) return this.newUint8Array(value)

                const n = this.newObject()
                for (const r in value) this.setProp(n, r, this.deepWrapHandle(value[r]))
                return n
            }
            default:
                throw Error(`${typeof value} can not be wrapped`)
        }
    }

    deepUnWrapHandle(e: Handle): any {
        switch (this.typeof(e)) {
            case 'undefined':
                return undefined
            case 'boolean':
                return this.getBoolean(e)
            case 'number':
                return this.getNumber(e)
            case 'string':
                return this.getString(e)
            case 'object': {
                if (this.isNull(e)) return null
                if (this.isArray(e)) {
                    const array = []
                    const length = this.getNumber(this.getProp(e, 'length'))
                    for (let i = 0; i < length; i++) array.push(this.deepUnWrapHandle(this.getProp(e, i.toString())))
                    return array
                }
                if (this.isArrayBuffer(e)) return this.getArrayBuffer(e)
                if (this.isUint8Array(e)) return this.getUint8Array(e)
                const object: { [key: string]: any } = {}
                for (const key of this.getKeys(e)) {
                    object[key] = this.deepUnWrapHandle(this.getProp(e, key))
                }
                return object
            }
            case 'function': {
                return {
                    [FUNCTION_SYMBOL]: e,
                }
            }
            default:
                throw Error(`${this.typeof(e)} can not be unwrapped`)
        }
    }

    registerPromise<T>(promise: Promise<T>) {
        return new Promise<T>((resolve, reject) => {
            promise.then(
                (value) => {
                    if (!this.isDestroyed()) resolve(value)
                },
                (error) => {
                    if (!this.isDestroyed()) reject(error)
                }
            )
        })
    }
}
