import { Wukong } from '@wukong/bridge-proto'
import { FC, useRef } from 'react'
import { RGB, SolidPaint } from '../../../../../../document/node/node'
import { HexInput } from '../../../../atom/inputs/hex-input'
import { hex2rgb, rgb2hex } from '../../../../design/blend/color-picker/utils/color-translate'
import styles from './index.module.less'

interface ColorInputProps {
    value: RGB
    onChange: (value: RGB) => void
}

export const ColorInput: FC<ColorInputProps> = ({ value, onChange }) => {
    const paint: SolidPaint = {
        type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
        color: value,
        visible: true,
    }

    const inputColorRef = useRef<HTMLInputElement>(null)

    return (
        <div className={styles.container}>
            <div
                className={styles['color-input-container']}
                onClick={() => {
                    inputColorRef.current?.click()
                }}
            >
                <input
                    ref={inputColorRef}
                    className={styles['color-input']}
                    type="color"
                    value={'#' + rgb2hex(value.r, value.g, value.b)}
                    onChange={(e) => {
                        onChange(hex2rgb(e.target.value))
                    }}
                    onClick={(e) => e.stopPropagation()} // 父节点重新点击了这个input, 防止safari 系统颜色拾取器无法取值
                />
            </div>

            <HexInput
                className={styles['hex-input']}
                value={rgb2hex(paint.color.r, paint.color.g, paint.color.b)}
                onChange={(value_) => {
                    onChange(hex2rgb(value_ as string))
                }}
            />
        </div>
    )
}
