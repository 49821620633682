import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    All: '全部可用属性',
    Fill: '填充',
    FrameFill: '容器',
    ShapeFill: '形状',
    TextFill: '文本',
    Stroke: '描边',
    Effect: '效果',
} as const

export const enTranslation = {
    All: 'All supported properties',
    Fill: 'Fill',
    FrameFill: 'Frame',
    ShapeFill: 'Shape',
    TextFill: 'Text',
    Stroke: 'Stroke',
    Effect: 'Effects',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
