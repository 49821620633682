import { WKconfirmProps, wkDialogConfirm } from '.'

export const asyncConfirm = ({ onOk, onClose, ...rest }: WKconfirmProps) =>
    new Promise<void>((resolve, reject) => {
        wkDialogConfirm.show({
            ...rest,
            onOk: async () => {
                onOk?.()
                resolve()
            },
            onClose: async () => {
                onClose?.()
                reject('confirm-modal-wrap-promise')
            },
        })
    })
