/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { useMemo } from 'react'
import {
    DraggablePopupPropsV2,
    DraggablePopupV2,
    SimpleDrag,
    SimpleDragProps,
} from '../../../../../../../../ui-lib/src'
import { Effect } from '../../../../../../document/node/node'
import { InputOptionsForUndoSquash } from '../../../../atom/inputs/components/formatted-input'
import { ColorSpace } from '../../../blend/color-picker/utils/color-translate'
import { EffectItem } from '../../../effects/effect-item'
import { CommentAttributeTitle } from '../common-attribute-title/comment-attribute-title'
import { CommonFooter } from '../common-footer/common-footer'
import { CommonNameDescription } from '../common-name-description/common-name-description'
import { CommonThumbnail } from '../common-thumbnail/common-thumbnail'
import classes from './effect-style-panel.module.less'

export interface EffectStyleData {
    id: string
    name: string
    description: string
    effects: Effect[]
    variables?: Wukong.DocumentProto.ISingleVariable[]
    floatVariables?: Wukong.DocumentProto.ISingleFloatVariable[]
}

export type PickDraggablePopupProps = Pick<DraggablePopupPropsV2, 'visible' | 'position' | 'header' | 'onCancel'>

export interface EffectStylePanelProps extends PickDraggablePopupProps {
    effectStyleData: EffectStyleData
    colorSpace: ColorSpace
    openFrom: 'create' | 'remote' | 'normal'
    hideCommonFooter?: boolean
    selectEffectIndexList?: number[]
    variables?: Wukong.DocumentProto.ISingleVariable[]
    floatVariables?: Wukong.DocumentProto.ISingleFloatVariable[]
    onChangeName: (v: string) => void
    onChangeDescription: (v: string) => void
    onClickTitle: () => void
    onClickAddEffect: () => void
    onSelectChange: SimpleDragProps<Effect>['onSelectChange']
    onDragDone: SimpleDragProps<Effect>['onDragDone']
    isOpenEffectPanel: (effectIndex: number) => boolean
    onChangeEffect: (effectIndex: number, obj: { [key: string]: any }, options?: InputOptionsForUndoSquash) => void
    onChangeModalVisible: (effectIndex: number, v: boolean) => void
    onChangeVisible: (effectIndex: number, v: boolean) => void
    onChangeBlendMode: (effectIndex: number, blendMode: Wukong.DocumentProto.BlendMode) => void
    onClickSub: (effectIndex: number) => void
    onClickRemoteStyleLink?: (e: React.MouseEvent) => void
    onClickCreate?: () => void
    onDetachEffectColorVar?: (effectIndex: number) => void
}

export function EffectStylePanel<T extends EffectStylePanelProps = EffectStylePanelProps>(props: T) {
    const { effectStyleData, openFrom, hideCommonFooter } = props

    const isRemoteStyle = useMemo(() => openFrom === 'remote', [openFrom])
    const isCreateStyle = useMemo(() => openFrom === 'create', [openFrom])

    return (
        <DraggablePopupV2
            visible={props.visible}
            position={props.position}
            positionRightBase
            header={props.header}
            bodyClassName={classnames(classes.body, {
                [classes.existCreate]: isCreateStyle,
                [classes.existRemote]: isRemoteStyle,
            })}
            footer={
                hideCommonFooter ? null : (
                    <CommonFooter
                        isCreateStyle={isCreateStyle}
                        isRemoteStyle={isRemoteStyle}
                        onClickJumpLink={props.onClickRemoteStyleLink}
                        onClickCreateButton={props.onClickCreate}
                    />
                )
            }
            notUseDefaultFooterClassName
            onCancel={props.onCancel}
            enableScrollBar
            styleType="editor"
        >
            <CommonThumbnail type="effect" data={effectStyleData.effects} />
            <CommonNameDescription
                isReadOnly={isRemoteStyle}
                name={effectStyleData.name}
                description={effectStyleData.description}
                onChangeName={props.onChangeName}
                onChangeDescription={props.onChangeDescription}
                key={effectStyleData.id}
                testId="effect-style-name-description"
            />
            <CommentAttributeTitle
                grayTheme={!effectStyleData.effects.length}
                onClickTitle={props.onClickTitle}
                onClickAddIcon={props.onClickAddEffect}
                disabledIcon={isRemoteStyle}
            />
            <EffectStylePanelList {...props}></EffectStylePanelList>
        </DraggablePopupV2>
    )
}

function EffectStylePanelList<T extends EffectStylePanelProps = EffectStylePanelProps>(props: T) {
    const { effectStyleData, colorSpace, openFrom, selectEffectIndexList = [] } = props

    const isRemoteStyle = useMemo(() => openFrom === 'remote', [openFrom])

    const effects = effectStyleData.effects.slice().reverse()
    const selectedIndexes = selectEffectIndexList.map((index) => effects.length - index - 1)
    const onSelectChange = (indexes: number[], e: React.MouseEvent) => {
        props.onSelectChange?.(
            indexes.map((index) => effects.length - index - 1),
            e
        )
    }
    const onDragDone = (items: Effect[], indexes: number[]) => {
        props.onDragDone?.(
            items.slice().reverse(),
            indexes.map((index) => effects.length - index - 1)
        )
    }
    const isOpenEffectPanel = (index: number) => {
        return props.isOpenEffectPanel(effects.length - index - 1)
    }
    const onChangeEffect = (index: number) => (obj: { [key: string]: any }, options?: InputOptionsForUndoSquash) => {
        props.onChangeEffect(effects.length - index - 1, obj, options)
    }
    const onChangeModalVisible = (index: number) => (v: boolean) => {
        props.onChangeModalVisible(effects.length - index - 1, v)
    }
    const onChangeVisible = (index: number) => (v: boolean) => {
        props.onChangeVisible(effects.length - index - 1, v)
    }
    const onChangeBlendMode = (index: number) => (blendMode: Wukong.DocumentProto.BlendMode) => {
        props.onChangeBlendMode(effects.length - index - 1, blendMode)
    }
    const onClickDelete = (index: number) => () => {
        props.onClickSub(effects.length - index - 1)
    }
    const onDetachEffectColorVar = (index: number) => () => {
        props.onDetachEffectColorVar?.(effects.length - index - 1)
    }

    return (
        <SimpleDrag
            selectedIndexList={selectedIndexes}
            onSelectChange={onSelectChange}
            items={effects}
            onDragDone={onDragDone}
            disabled={isRemoteStyle}
            onMouseDown={(e) => e.stopPropagation()}
            dataTestIds={{
                simpleDrag: 'effect-style-editor-list',
            }}
            // 避免触发可拖拽弹窗的拖拽
            data-disabled-drag-move={'true'}
        >
            {effects.map((effect, index) => (
                <SimpleDrag.Item
                    key={index}
                    itemIndex={index}
                    dataTestIds={{ item: 'effect-style-drag-item', dragIcon: 'effect-style-drag-icon' }}
                >
                    <EffectItem
                        key={index}
                        selected={selectedIndexes.includes(index)}
                        data={effect}
                        colorSpace={colorSpace}
                        variables={props.variables}
                        floatVariables={props.floatVariables}
                        isOpen={isOpenEffectPanel(index)}
                        onChangeData={onChangeEffect(index)}
                        onChangeModalVisible={onChangeModalVisible(index)}
                        onChangeVisible={onChangeVisible(index)}
                        onChangeBlendMode={onChangeBlendMode(index)}
                        onClickSub={onClickDelete(index)}
                        onDetachColorVar={onDetachEffectColorVar(index)}
                        isMini={true}
                        disabled={isRemoteStyle}
                    />
                </SimpleDrag.Item>
            ))}
        </SimpleDrag>
    )
}
