import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    ViewEffectStyle: '查看效果样式',
    CreateNewEffect: '创建效果样式',
    EditEffectStyle: '编辑效果样式',
} as const

export const enTranslation = {
    ViewEffectStyle: 'View effect style',
    CreateNewEffect: 'Create new effect style',
    EditEffectStyle: 'Edit effect style',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
