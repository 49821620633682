import { useState } from 'react'
import { useThrottleFn } from 'react-use'

export const isEllipsisActive = (element: HTMLElement) => {
    // scrollWidth取值偶现1px误差
    return element.scrollWidth - 1 > element.clientWidth
}

export const useIsEllipsisActive = (element?: HTMLElement | null) => {
    const [r, setR] = useState(false)
    useThrottleFn((ele) => (ele ? setR(isEllipsisActive(ele)) : setR(false)), 1000, [element])
    return r
}
