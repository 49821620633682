import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import { useMemo, useRef, useState } from 'react'
import { useViewState } from '../../../../../../view-state-bridge'
import { IconButton } from '../../../../atom/button/icon-button'
import { SingleGrid } from '../../../../atom/grid/single-grid'
import { Title } from '../../../../atom/title/title'

import aiPowered from '../../../../../assets/ai/ai-powered.png'
import * as icons from './icons'
import style from './index.module.less'
import {
    AutoLayoutDisplayType,
    AutoLayoutEditType,
    AutoLayoutState,
    AutoLayoutWHType,
    AutoLayoutWHValue,
} from './types'

import { Wukong } from '@wukong/bridge-proto'
import {
    IconAdd16,
    IconDelete16,
    IconIndependentPadding,
    IconMote,
    MonoIconPanelChangeDirection16,
    Tooltip,
} from '../../../../../../../../ui-lib/src'
import { SelectIconGroup } from '../../../../atom/button/select-button-group'
import { formateToFixed2 } from '../../../../atom/inputs/utils/format'
import { useAutoLayoutCommand } from './command'
import { AutoLayoutAdvance } from './components/advance'
import { AlignPad } from './components/align-pad'
import { CounterSpaceInput } from './components/all-space-input/counter-space-input'
import { SpaceInput } from './components/all-space-input/space-input'
import { FloatInput } from './components/float-input'
import { PaddingInput, formatPadding } from './components/padding-input'

export type FlexState = ReturnType<typeof getFlexState>

/**
 * 自动布局容器属性
 * @param state
 */
function getFlexState(state: AutoLayoutState) {
    const flex = state.flex

    const vertical = {
        isMixed: flex.padding.vertical.isMixed || flex.padding.bottom.isMixed,
        value: [...new Set(flex.padding.vertical.value.concat(flex.padding.bottom.value))],
    }

    const horizontal = {
        isMixed: flex.padding.horizontal.isMixed || flex.padding.right.isMixed,
        value: [...new Set(flex.padding.horizontal.value.concat(flex.padding.right.value))],
    }

    // 如果左和右，上和下任意一对不一致，则需要展示独立边距
    const isIndepentPadding = vertical.value.length > 1 || horizontal.value.length > 1

    return {
        ...state.advance,
        ...state.flex,
        isIndepentPadding,
        vertical,
        horizontal,
    }
}

function getDisplayState(state: AutoLayoutState) {
    const title = state.displayType !== AutoLayoutDisplayType.LD_Disabled
    const body = state.displayType === AutoLayoutDisplayType.LD_Show
    const edit = state.editType != AutoLayoutEditType.ED_None
    const isAdd = state.editType === AutoLayoutEditType.ED_Add
    const show = title || body

    return {
        title,
        body,
        edit,
        isAdd,
        show,
    }
}

export function useAutoLayoutState(): AutoLayoutState & { disabled: boolean; aiPowered: boolean } {
    const panelStateUnderAILayout = useViewState('panelStateUnderAILayout')
    const autoLayoutState = useViewState('autoLayout') as AutoLayoutState
    return {
        ...autoLayoutState,
        disabled: !!panelStateUnderAILayout?.disableAutoLayout,
        aiPowered: !!panelStateUnderAILayout?.displayAIPoweredBadge,
    }
}

export const WHValueText: Record<AutoLayoutWHValue, string | null> = {
    [AutoLayoutWHValue.WH_Fill]: translation('FillContainer'),
    [AutoLayoutWHValue.WH_Fixed]: null,
    [AutoLayoutWHValue.WH_Hug]: translation('HugContents'),
    [AutoLayoutWHValue.WH_Mixed]: null,
}

export function useAutoLayoutText() {
    const state = useAutoLayoutState()
    const textMapper = (type: 'widthType' | 'heightType') => (origin: string) => {
        const value = state.wh[type]

        if (WHValueText[value]) {
            return `${WHValueText[value]}${translation('LeftBracket')}${origin}${translation('RightBracket')}`
        }
        return origin
    }
    const padding = state.flex.padding
    const paddingHasValue =
        padding.vertical.value.length > 0 &&
        padding.horizontal.value.length > 0 &&
        padding.right.value.length > 0 &&
        padding.bottom.value.length > 0
    const stackSpacingValue =
        state.flex.stackSpacing.value.length == 0 ? '' : formateToFixed2(state.flex.stackSpacing.value[0]) + 'px'

    const stackCounterSpacing =
        state.flex.stackCounterSpacing.value.length == 0
            ? ''
            : formateToFixed2(state.flex.stackCounterSpacing.value[0]) + 'px'

    return {
        isAutoLayout: state.wh.whType === AutoLayoutWHType.WH_Parent,
        width: textMapper('widthType'),
        height: textMapper('heightType'),
        space:
            !state.flex.stackPrimaryAlignItems.isMixed &&
            state.flex.stackPrimaryAlignItems.value ===
                Wukong.DocumentProto.StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_SPACE_EVENTLY
                ? translation('Auto')
                : stackSpacingValue,
        counterSpacing:
            !state.flex.stackCounterAlignContent.isMixed &&
            state.flex.stackCounterAlignContent.value ===
                Wukong.DocumentProto.StackCounterAlignContent.STACK_COUNTER_ALIGN_CONTENT_AUTO
                ? translation('Auto')
                : stackCounterSpacing,
        padding: paddingHasValue
            ? formatPadding([
                  padding.vertical.value[0],
                  padding.right.value[0],
                  padding.bottom.value[0],
                  padding.horizontal.value[0],
              ])
                  .map((v) => v + 'px')
                  .join(' ')
            : '',
    }
}

/**
 * 自动布局的浮动输入框
 */
export const AutoLayoutFloatInput = () => {
    const state = useAutoLayoutState()

    return <FloatInput state={state} />
}

export const AIPoweredBadge = () => {
    return (
        <div style={{ marginLeft: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img height={16} src={aiPowered}></img>
        </div>
    )
}

export const AutoLayout = () => {
    const state = useAutoLayoutState()
    const command = useAutoLayoutCommand(state)

    const flex = getFlexState(state)
    const display = getDisplayState(state)

    const [independentPadding, setIndependentPadding] = useState(flex.isIndepentPadding)
    const [position, setPosition] = useState<{ left: number; top: number }>()
    const titleRef = useRef<HTMLDivElement>(null)
    const toggleAdvancePanel = () => {
        command.setAdvancePanel(!state.advance.show)
        setPosition(titleRef.current?.getBoundingClientRect())
    }

    const toggleAutoLayout = (isTitle: boolean) => {
        if (display.isAdd) {
            command.addAutoLayout()
        } else if (!isTitle) {
            // 删除自动布局时，只响应 icon button
            command.removeAutoLayout()
        }
    }

    const options = useMemo(
        () => [
            {
                value: Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_VERTICAL,
                icon: icons.svgLayoutVertical,
                name: translation('VerticalLayout'),
                testId: 'auto-layout-stack-mode-vertical',
            },
            {
                value: Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_HORIZONTAL,
                icon: icons.svgLayoutHorizental,
                name: translation('HorizontalLayout'),
                testId: 'auto-layout-stack-mode-horizontal',
            },

            {
                value: Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_WRAP,
                icon: <MonoIconPanelChangeDirection16 />,
                name: translation('Wrap'),
                testId: 'auto-layout-stack-mode-wrap',
            },
        ],
        []
    )

    const optionValue = useMemo(() => options.map((item) => item.value), [options])

    return display.show ? (
        <div className={style.block} ref={titleRef} data-testid="autolayout-module">
            {display.title && (
                <Title className={style.title} onClick={() => toggleAutoLayout(true)} grayTheme={!display.body}>
                    <Title.Left>
                        <div style={{ display: 'flex' }}>
                            <div>{translation('AutoLayout')}</div>
                            {state.aiPowered ? <AIPoweredBadge></AIPoweredBadge> : null}
                        </div>
                    </Title.Left>
                    <Title.Right>
                        {display.edit && (
                            <IconButton
                                icon={display.isAdd ? <IconAdd16 /> : <IconDelete16 />}
                                selected={false}
                                onClick={() => toggleAutoLayout(false)}
                                dataTestId="add-auto-layout"
                                disabled={state.disabled}
                                tabTestId="add-auto-layout-tab-focus"
                            />
                        )}
                    </Title.Right>
                </Title>
            )}

            {display.body && (
                <div className="mb-1">
                    <SingleGrid>
                        <SingleGrid.Item start={5} span={22}>
                            <SelectIconGroup
                                optionValue={optionValue}
                                className={style.iconGroup}
                                disabled={state.disabled || state.attrState.disableAutoLayoutStackMode}
                                onClickIcon={(value) => command.setStackMode(value)}
                            >
                                {options.map((item) => (
                                    <Tooltip key={item.value} title={item.name} canMouseDownClose>
                                        <SelectIconGroup.Item
                                            icon={item.icon}
                                            value={item.value}
                                            selected={item.value === flex.stackMode}
                                            className={style.iconGroupItem}
                                            dataTestId={item.testId}
                                        ></SelectIconGroup.Item>
                                    </Tooltip>
                                ))}
                            </SelectIconGroup>
                        </SingleGrid.Item>
                        <SingleGrid.Item start={29} span={22} style={{ alignItems: 'flex-start' }}>
                            <AlignPad state={state} />
                        </SingleGrid.Item>
                        <SingleGrid.Item start={51} span={12} horizontalCenter>
                            <Tooltip title={translation('AdvancedAutoLayout')} canMouseDownClose>
                                <IconButton
                                    icon={<IconMote />}
                                    selected={state.advance.show}
                                    deepColor
                                    onChange={toggleAdvancePanel}
                                ></IconButton>
                            </Tooltip>
                        </SingleGrid.Item>
                    </SingleGrid>
                    {flex.stackMode === Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_WRAP ? (
                        <>
                            <SingleGrid>
                                <Tooltip title={translation('HorizontalGap')} canMouseDownClose>
                                    <SingleGrid.Item start={5} span={22}>
                                        <SpaceInput state={state} />
                                    </SingleGrid.Item>
                                </Tooltip>
                            </SingleGrid>
                            <SingleGrid>
                                <Tooltip title={translation('VerticalGap')} canMouseDownClose>
                                    <SingleGrid.Item start={5} span={22}>
                                        <CounterSpaceInput state={state} />
                                    </SingleGrid.Item>
                                </Tooltip>
                            </SingleGrid>
                        </>
                    ) : flex.stackMode ===
                      Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_VERTICAL ? (
                        <SingleGrid>
                            <Tooltip title={translation('CommonVerticalGap')} canMouseDownClose>
                                <SingleGrid.Item start={5} span={22}>
                                    <SpaceInput state={state} />
                                </SingleGrid.Item>
                            </Tooltip>
                        </SingleGrid>
                    ) : (
                        <SingleGrid>
                            <Tooltip title={translation('CommonHorizontalGap')} canMouseDownClose>
                                <SingleGrid.Item start={5} span={22}>
                                    <SpaceInput state={state} />
                                </SingleGrid.Item>
                            </Tooltip>
                        </SingleGrid>
                    )}
                    {independentPadding ? (
                        <>
                            <SingleGrid>
                                <Tooltip title={translation('LeftPadding')} canMouseDownClose>
                                    <SingleGrid.Item start={5} span={22}>
                                        <PaddingInput state={state} type="left" />
                                    </SingleGrid.Item>
                                </Tooltip>

                                <Tooltip title={translation('TopPadding')} canMouseDownClose>
                                    <SingleGrid.Item start={29} span={22}>
                                        <PaddingInput state={state} type="top" />
                                    </SingleGrid.Item>
                                </Tooltip>
                                <SingleGrid.Item start={51} span={12} horizontalCenter>
                                    <Tooltip title={translation('IndividualPadding')} canMouseDownClose>
                                        <IconButton
                                            icon={<IconIndependentPadding />}
                                            selected={independentPadding}
                                            onChange={(val) => setIndependentPadding(val)}
                                        ></IconButton>
                                    </Tooltip>
                                </SingleGrid.Item>
                            </SingleGrid>
                            <SingleGrid>
                                <Tooltip title={translation('RightPadding')} canMouseDownClose>
                                    <SingleGrid.Item start={5} span={22}>
                                        <PaddingInput state={state} type="right" />
                                    </SingleGrid.Item>
                                </Tooltip>
                                <Tooltip title={translation('BottomPadding')} canMouseDownClose>
                                    <SingleGrid.Item start={29} span={22}>
                                        <PaddingInput state={state} type="bottom" />
                                    </SingleGrid.Item>
                                </Tooltip>
                            </SingleGrid>
                        </>
                    ) : (
                        <SingleGrid>
                            <Tooltip title={translation('HorizontalPadding')} canMouseDownClose>
                                <SingleGrid.Item start={5} span={22}>
                                    <PaddingInput state={state} type="horizontal" />
                                </SingleGrid.Item>
                            </Tooltip>
                            <Tooltip title={translation('VerticalPadding')} canMouseDownClose>
                                <SingleGrid.Item start={29} span={22}>
                                    <PaddingInput state={state} type="vertical" />
                                </SingleGrid.Item>
                            </Tooltip>
                            <SingleGrid.Item start={51} span={12} horizontalCenter>
                                <Tooltip title={translation('IndividualPadding')} canMouseDownClose>
                                    <IconButton
                                        icon={<IconIndependentPadding />}
                                        selected={independentPadding}
                                        onChange={(val) => setIndependentPadding(val)}
                                    ></IconButton>
                                </Tooltip>
                            </SingleGrid.Item>
                        </SingleGrid>
                    )}
                </div>
            )}
            <AutoLayoutAdvance
                isOpen={state.advance.show}
                close={() => {
                    command.setAdvancePanel(false)
                }}
                state={state}
                position={position}
                disabled={state.disabled}
            />
        </div>
    ) : null
}
