import type { History } from 'history'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { WKMenuConfig, wkDialogConfirm } from '../../../../../../../ui-lib/src'
import {
    RouteToken,
    domLocation,
    generateRouterPath,
    getMotiffName,
    useAsyncRetry,
    useWKNavigate,
    useWindow,
} from '../../../../../../../util/src'
import { GetOrganizations } from '../../../../../kernel/request/organizations'
import { CheckClientReleaseRequestV2 } from '../../../../../kernel/request/release'
import { createFileManager } from '../../../../../main/create-file-manager'
import { ForceUpgradeScene } from '../../../../../space/app/force-upgrade/constants'
import { useImageDownloadContext } from '../../../../context/document-context'
import { useDocInfoContext } from '../../../../context/top-area-context'
import { translation } from './back.translation'
import { useBackNotify } from './use-back-notify'

export const useMenuBack = () => {
    const navigate = useWKNavigate()
    const imageDownloadContext = useImageDownloadContext()
    const blockStatus = imageDownloadContext.states.use.blockStatusState()

    // 当新建文档时，不允许用户在未完成时退出
    const [disabled, setDisabled] = useState(createFileManager.isCreatingFile())

    useEffectOnce(() => {
        if (createFileManager.isCreatingFile()) {
            createFileManager.injectCreateFileCallBack(() => {
                setDisabled(false)
            })
        }
    })

    const { folderData, docData, isShow: hasFolderRight } = useDocInfoContext()
    const { value: organizations, retry } = useAsyncRetry(() => new GetOrganizations().start(), [])
    useBackNotify(retry)
    const docOrgId = docData?.orgId
    const hasOrganizationRight = organizations?.some((o) => o.id == docOrgId)
    const currentUrl = domLocation().href
    const handlePopState = useCallback(() => {
        wkDialogConfirm.show({
            title: translation('UnableToLeave'),
            content: translation('ThisFileHas', { name: getMotiffName() }),
            okText: translation('OK'),
            hideCancelButton: true,
        })
        // 防止通过浏览器UI的前进/后退时，实际url与展示url不一致
        window.history.replaceState({}, '', currentUrl)
    }, [currentUrl])

    const { navigator } = useContext(NavigationContext) || { navigator: {} }

    useEffect(() => {
        let unlock: undefined | (() => void)

        if (blockStatus && (navigator as History).block) {
            unlock = (navigator as History).block(() => {})
            window.addEventListener('popstate', handlePopState)
        }

        return () => {
            unlock?.()
            window.removeEventListener('popstate', handlePopState)
        }
    }, [blockStatus, handlePopState, navigator])

    // 这里要记住文件的组织，以便{translation('BackToFiles')}工作台时时正确的
    const link = useMemo(() => {
        let prefix = ''
        if (hasOrganizationRight && docOrgId !== '-1') {
            prefix = `${RouteToken.OrganizationWithoutOrgId}/${docOrgId}`
        } else {
            prefix = `${RouteToken.OrganizationWithoutOrgId}`
        }
        if (!hasFolderRight) {
            return `${prefix}/${RouteToken.Recent}`
        } else {
            return docData?.draft
                ? `${prefix}/${RouteToken.Drafts}`
                : `${prefix}/${RouteToken.Project}/${folderData?.id}`
        }
    }, [docData?.draft, docOrgId, folderData?.id, hasFolderRight, hasOrganizationRight])

    const { openByPathName } = useWindow()

    const menuConfig = useMemo<WKMenuConfig>(
        () => [
            {
                linkInfo: { href: `/${generateRouterPath(link)}` },
                testId: 'menu-back',
                name: translation('BackToFiles'),
                disable: disabled,
                handler: async (e) => {
                    const hasNotUploadedImages = imageDownloadContext.hasNotUploadedImages()
                    if (hasNotUploadedImages) {
                        imageDownloadContext.retryUploadImageToServer()
                        wkDialogConfirm.show({
                            title: translation('UnableToLeave'),
                            content: translation('ThisFileHas', { name: getMotiffName() }),
                            okText: translation('OK'),
                            hideCancelButton: true,
                        })
                        return
                    }
                    // setLocalStorage()
                    if (!e?.metaKey && !e?.shiftKey) {
                        try {
                            const needForceUpgrade = !(
                                await new CheckClientReleaseRequestV2(ForceUpgradeScene.Workbench).start()
                            ).available
                            if (needForceUpgrade) {
                                return openByPathName(link, '_self')
                            }
                        } catch {}
                        navigate(link)
                    }
                },
            },
        ],
        [link, disabled, imageDownloadContext, navigate, openByPathName]
    )
    return { menuConfig, link: `/${link}` }
}
