import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    OpenFile: '打开文件',
    Insert: '置入画布',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    OpenFile: 'Open File',
    Insert: 'Insert',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
