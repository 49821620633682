import { Wukong } from '@wukong/bridge-proto'
import { ExportSettings } from '../../../../../document/node/node'
import {
    constraintValueScale,
    ExportConfig,
    appendFileType,
    isBase64,
    removeDotPrefix,
} from '../../../design/export/util'

import ExportSettingsConstraintsType = Wukong.DocumentProto.ExportConstraintType
import ExportFormatType = Wukong.DocumentProto.ExportFormatType
import PresetCodeType = Wukong.DocumentProto.PresetCodeType
import FileType = Wukong.DocumentProto.FileType

export function getExportFileFolderName(value: number, needFolder: boolean): string {
    if (!needFolder) {
        return ''
    }

    switch (value) {
        case 1000:
            return 'drawable-mdpi/'
        case 1500:
            return 'drawable-hdpi/'
        case 2000:
            return 'drawable-xhdpi/'
        case 3000:
            return 'drawable-xxhdpi/'
        case 4000:
            return 'drawable-xxxhdpi/'
        default:
            return ''
    }
}

function getNodeFileName(
    nameCount: Map<string, number>,
    name: string,
    setting: ExportSettings,
    needFolder: boolean
): readonly [string, boolean] {
    const format = setting.format ?? ExportFormatType.EXPORT_FORMAT_TYPE_PNG
    const folderName = getExportFileFolderName(setting.constraint?.value ?? 1, needFolder)
    const nameWithoutSuffix = removeDotPrefix(`${folderName}${name}${setting.suffix ?? ''}`)
    const duplicate = nameCount.has(nameWithoutSuffix)
    const count = duplicate ? nameCount.get(nameWithoutSuffix)! + 1 : 0
    nameCount.set(nameWithoutSuffix, count)
    let fileNameWithoutSuffix = nameWithoutSuffix
    if (duplicate) {
        fileNameWithoutSuffix += `(${count})`
    }
    const fileName = appendFileType(fileNameWithoutSuffix, format)
    return [fileName, duplicate] as const
}

export function singleNodeMultiExportConfig(
    nameCount: Map<string, number>,
    node: string[],
    name: string,
    exportSettings: ExportSettings[],
    needFolder = false,
    isCompress = false
): ExportConfig[] {
    const configs: ExportConfig[] = []
    for (const setting of exportSettings) {
        const [fileName, duplicate] = getNodeFileName(nameCount, name, setting, needFolder)
        const item = {
            fileName,
            node,
            name,
            duplicate,
            isBase64: isBase64(setting.format),
            format: setting.format ?? ExportFormatType.EXPORT_FORMAT_TYPE_PNG,
            type: setting?.constraint?.type ?? ExportSettingsConstraintsType.EXPORT_CONSTRAINT_TYPE_SCALE,
            value: (setting?.constraint?.value ?? constraintValueScale) / constraintValueScale,
            isCompress: isCompress,
        }
        configs.push(item)
    }
    return configs
}

export function multiNodesMultiExportSettings(
    nameCount: Map<string, number>,
    nodeCollection: string[][],
    names: string[],
    exportSettings: ExportSettings[],
    isCompress: boolean,
    presetCodeType?: PresetCodeType,
    fileType?: FileType
): ExportConfig[] {
    const needFolder = isNeedFileFolder(nodeCollection.length, exportSettings.length, presetCodeType, fileType)
    let configs: ExportConfig[] = []
    for (let i = 0; i < nodeCollection.length; i += 1) {
        const node = nodeCollection[i]
        const name = names[i]
        configs = configs.concat(
            singleNodeMultiExportConfig(nameCount, node, name, exportSettings, needFolder, isCompress)
        )
    }
    return configs
}

/**
 * @brief 导出的文件是否需要文件夹
 *
 * 只有 1. 预设类型为 android
 * 并且 2. 文件类型为 png、jpg、webp
 * 并且 3. 导出的文件数大于 1 时
 * 才需要文件夹
 */
function isNeedFileFolder(
    nodeSize: number,
    settingSize: number,
    presetCodeType?: PresetCodeType,
    fileType?: FileType
): boolean {
    if (
        presetCodeType === PresetCodeType.PRESET_CODE_TYPE_ANDROID &&
        isNotSVGOrPDF(fileType) &&
        isMultiFile(nodeSize, settingSize)
    ) {
        return true
    }
    return false
}

function isNotSVGOrPDF(fileType?: FileType): boolean {
    return fileType !== FileType.FILE_TYPE_SVG && fileType !== FileType.FILE_TYPE_PDF
}

function isMultiFile(nodeSize: number, settingSize: number) {
    return nodeSize > 1 || settingSize > 1
}
