import { Wukong } from '@wukong/bridge-proto'
import {
    domLocation,
    generateRouterPath,
    isEnglishLanguage,
    RouteToken,
    WebGLRenderingContextCompat,
} from '../../../../util/src'
import { EffectController } from '../../../../util/src/effect-controller'
import { WukongEditor } from '../../editor'
import { GL } from '../../editor/wk-wasm-app'
import { IN_JEST_TEST } from '../../environment'
import { getVideoCardInfo } from '../../main/gpu/utils'
import { LocalStorageKey } from '../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../web-storage/local-storage/storage'
import { SessionStorageKey } from '../../web-storage/session-storage/config'
import { enhancedSessionStorage } from '../../web-storage/session-storage/storage'
import { WkCLog } from '../clog/wukong/instance'
import { featureSwitchManager } from '../switch'
import { getChromeVersion, isFirefox, isWindows } from '../util/ua'
import { CanvasState, CanvasStateType, WebGLCanvasState } from './canvas-types'

declare global {
    interface Window {
        clientId?: number
    }
}

export enum HEAPDest {
    HEAP8 = 'HEAP8',
    HEAP16 = 'HEAP16',
    HEAP32 = 'HEAP32',
    HEAPU8 = 'HEAPU8',
    HEAPU16 = 'HEAPU16',
    HEAPU32 = 'HEAPU32',
    HEAPF32 = 'HEAPF32',
    HEAPF64 = 'HEAPF64',
}

export type MemType = Uint8Array | Uint16Array | Uint32Array

export enum EditorServiceState {
    Prepare,
    Ready,
    Destroyed,
}

export type BackendType = 'WebGL1' | 'WebGL2' | 'WebGPU' | 'Null' | 'Failed'

export class EditorService {
    private state = EditorServiceState.Prepare
    private jsStackCache = new Map<string, number>()

    public _bridgeCallCounter = 2

    public clientId: number

    private canvasState: CanvasState | null = null

    constructor(private native: WukongEditor, controller?: EffectController) {
        controller?.onCleanup(() => this.destroy())
        this.destroy = this.destroy.bind(this)
        window.clientId = window.clientId
            ? window.clientId
            : new Date().getTime() * 1000 + Math.floor(Math.random() * 1000)
        // 一个 clientId 代表了一个 wasm client 的生命周期，目前用于获取 replay 以及通过日志排查问题，可以接受一定概率的冲突
        this.clientId = window.clientId
    }

    public async initCanvas(canvas: HTMLCanvasElement, attributes?: Record<string, any>) {
        if (this.canvasState) {
            throw new Error('Canvas already initialized')
        }

        if (IN_JEST_TEST) {
            this.canvasState = {
                type: CanvasStateType.Null,
                canvas,
            }
            this.logGpuBackend('Null')
            return
        }

        if (
            featureSwitchManager.isEnabled('render-webgpu') &&
            !featureSwitchManager.isEnabled('webgpu-blacklist') &&
            (featureSwitchManager.isEnabled('dev-webgpu') || EditorService.shouldUseWebGPU())
        ) {
            const result = await EditorService.createWebGPUContext()
            if (this.state == EditorServiceState.Destroyed) {
                return
            }
            if (result) {
                const adapterId = this.native.WebGPU.mgrAdapter.create(result.adapter)
                const queueId = this.native.WebGPU.mgrQueue.create(result.device.queue)
                const deviceId = this.native.WebGPU.mgrDevice.create(result.device, {
                    queueId: queueId,
                })
                this.canvasState = {
                    type: CanvasStateType.WebGPU,
                    canvas,
                    adapterId,
                    deviceId,
                    queueId,
                    device: result.device,
                    findTexture: (textureHandle: number) => this.native.WebGPU.mgrTexture.get(textureHandle),
                    findBuffer: (bufferHandle: number) => this.native.WebGPU.mgrBuffer.get(bufferHandle),
                }
                this.logGpuBackend('WebGPU')
                return
            } else {
                WkCLog.log('WK_RENDER_WEBGPU_FAILED')
            }
            // 不支持 WebGPU，降级为 WebGL
        }

        let version = typeof WebGL2RenderingContext !== 'undefined' ? 2 : 1
        let state = this.createWebGLContext(this.native.GL, canvas, version, attributes)
        if (state) {
            this.canvasState = state
            this.logGpuBackend(version == 2 ? 'WebGL2' : 'WebGL1')
            return
        }

        if (version == 2) {
            WkCLog.log('WK_RENDER_WEBGL2_FAILED')
            // WebGL2 创建失败，再试试 WebGL1
            version = 1
            state = this.createWebGLContext(this.native.GL, canvas, version, attributes)
            if (state) {
                this.canvasState = state
                this.logGpuBackend('WebGL1')
                return
            }
        }

        // 无法创建 WebGLContext，返回工作台
        WkCLog.log('WK_RENDER_CREATE_CONTEXT_FAILED')
        this.logGpuBackend('Failed')
        enhancedLocalStorage.setSerializedItem(LocalStorageKey.WebGLSupport, '0')
        domLocation().replace('/' + generateRouterPath(RouteToken.Recent))
    }

    private logGpuBackend(backend: BackendType) {
        console.info(`[Render] GPU Backend: ${backend}`)
        WkCLog.log('WK_RENDER_GPU_BACKEND', { backend })
    }

    private static shouldUseWebGPU() {
        // Session 中的禁用不会取消
        if (enhancedSessionStorage.getItem(SessionStorageKey.DisableWebGPU) == 'true') {
            return false
        }

        // 仅在 Chrome 130 及以上版本允许启用 WebGPU
        const chromeVersion = getChromeVersion()
        if (!chromeVersion || chromeVersion < 130) {
            return false
        }

        const renderer = getVideoCardInfo()?.renderer ?? ''

        for (const keyword of [
            // Apple Silicon 不启用 WebGPU，会导致性能劣化
            // ANGLE (Apple, ANGLE Metal Renderer: Apple M1, Unspecified Version)
            'Apple M',
            // 部分 Intel 显卡在 WebGPU 下极易 Device Lost
            'Intel(R) HD Graphics Family',
            'Intel(R) UHD Graphics (0x0000A720)',
            'Intel(R) UHD Graphics (0x0000A721)',
            'Intel(R) HD Graphics 620',
            'Intel(R) HD Graphics 630',
            'Intel(R) HD Graphics 4600',
        ]) {
            if (renderer.includes(keyword)) {
                return false
            }
        }

        return true
    }

    private static async safeRequestAdapter(gpu: GPU, options?: GPURequestAdapterOptions) {
        try {
            return await gpu.requestAdapter(options)
        } catch (e) {
            return null
        }
    }

    private static async safeRequestDevice(adapter: GPUAdapter, options?: GPUDeviceDescriptor) {
        try {
            return await adapter.requestDevice(options)
        } catch (e) {
            return null
        }
    }

    private static async createWebGPUContext() {
        if (!navigator.gpu) {
            return null
        }
        const adapter = await EditorService.safeRequestAdapter(navigator.gpu, {
            powerPreference: 'high-performance',
        })
        if (!adapter) {
            return null
        }
        // 这里设置 required features
        // c++ 中需要单独设置
        const features: GPUFeatureName[] = []
        const featuresToCheck: GPUFeatureName[] = [
            'dual-source-blending',
            'texture-compression-astc',
            'texture-compression-bc',
        ]
        for (const f of featuresToCheck) {
            if (adapter.features.has(f)) {
                features.push(f)
            }
        }
        // 这里设置 required limits
        // c++ 中需要单独设置
        const limits = {
            maxTextureDimension2D: adapter.limits.maxTextureDimension2D,
            minUniformBufferOffsetAlignment: adapter.limits.minUniformBufferOffsetAlignment,
        }
        const device = await EditorService.safeRequestDevice(adapter, {
            requiredFeatures: features,
            requiredLimits: limits,
        })
        if (!device) {
            return null
        }
        device.addEventListener('uncapturederror', (e) => {
            console.error(e)
        })
        return { adapter, device }
    }

    public async onWebGPUDeviceLost() {
        if (this.canvasState?.type != CanvasStateType.WebGPU) {
            return
        }

        // 释放之前的资源
        if (this.canvasState.queueId) {
            this.native.WebGPU.mgrQueue.release(this.canvasState.queueId)
        }
        if (this.canvasState.deviceId) {
            this.native.WebGPU.mgrDevice.release(this.canvasState.deviceId)
        }
        if (this.canvasState.adapterId) {
            this.native.WebGPU.mgrAdapter.release(this.canvasState.adapterId)
        }
        this.canvasState.device = null
        this.canvasState.queueId = 0
        this.canvasState.deviceId = 0
        this.canvasState.adapterId = 0

        if (!navigator.gpu) {
            // WebGPU 居然没了
            return
        }

        // 尝试 6 次
        const canvas = this.canvasState.canvas
        for (let i = 0; i < 6; i++) {
            // 先等 500ms
            await new Promise((r) => setTimeout(r, 500))
            if (this.state == EditorServiceState.Destroyed) {
                return
            }
            const result = await EditorService.createWebGPUContext()
            // @ts-expect-error
            if (this.state == EditorServiceState.Destroyed) {
                return
            }
            if (!result) {
                continue
            }
            const adapterId = this.native.WebGPU.mgrAdapter.create(result.adapter)
            const queueId = this.native.WebGPU.mgrQueue.create(result.device.queue)
            const deviceId = this.native.WebGPU.mgrDevice.create(result.device, {
                queueId: queueId,
            })
            this.canvasState = {
                type: CanvasStateType.WebGPU,
                canvas,
                adapterId,
                deviceId,
                queueId,
                device: result.device,
                findTexture: (textureHandle: number) => this.native.WebGPU.mgrTexture.get(textureHandle),
                findBuffer: (bufferHandle: number) => this.native.WebGPU.mgrBuffer.get(bufferHandle),
            }
            return
        }
    }

    private static createWebGLContextAttributes(version: number, attributes?: Record<string, any>) {
        // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/getContext
        // https://emscripten.org/docs/api_reference/html5.h.html#c.EmscriptenWebGLContextAttributes
        return {
            // 需要有 alpha，和 WebGPU 保持一致
            alpha: true,
            depth: false,
            stencil: false,
            // 不需要抗锯齿，内部实现抗锯齿
            antialias: false,
            // 使用默认值
            premultipliedAlpha: true,
            // 使用默认值
            preserveDrawingBuffer: false,
            // 高性能
            powerPreference: 'high-performance',
            // 使用默认值
            failIfMajorPerformanceCaveat: false,
            majorVersion: version,
            // 启用 extensions
            enableExtensionsByDefault: true,
            // 使用默认值
            explicitSwapControl: false,
            // 不需要离屏
            renderViaOffscreenBackBuffer: false,
            // 降低延迟
            // https://developer.chrome.com/blog/desynchronized/
            // [WK-15281][WK-15323] windows 下禁用 desynchronized，因为会导致画布区黑屏闪烁
            desynchronized: isWindows() || isFirefox() ? false : true,
            ...attributes,
        }
    }

    private createWebGLContext(
        gl: GL,
        canvas: HTMLCanvasElement,
        version: number,
        attributes?: Record<string, any>
    ): WebGLCanvasState | null {
        const handle = gl.createContext(canvas, EditorService.createWebGLContextAttributes(version, attributes))
        if (!handle) {
            // 无法创建 context
            return null
        }
        gl.makeContextCurrent(handle)
        if (!gl.currentContext?.GLctx) {
            // GLctx 不存在
            gl.deleteContext(handle)
            return null
        }
        return {
            type: CanvasStateType.WebGL,
            canvas,
            handle,
            version,
            context: gl.currentContext?.GLctx as WebGLRenderingContextCompat,
            findTexture: (textureHandle: number) => this.native.GL.textures[textureHandle] ?? null,
            findBuffer: (bufferHandle: number) => this.native.GL.buffers[bufferHandle] ?? null,
        }
    }

    get HEAPU8() {
        return this.native.HEAPU8
    }

    public getCanvasState(): CanvasState | null {
        return this.canvasState
    }

    public getBackendType(): BackendType {
        switch (this.canvasState?.type) {
            case CanvasStateType.WebGL:
                return typeof WebGL2RenderingContext !== 'undefined' &&
                    this.canvasState.context instanceof WebGL2RenderingContext
                    ? 'WebGL2'
                    : 'WebGL1'
            case CanvasStateType.WebGPU:
                return 'WebGPU'
            case CanvasStateType.Null:
                return 'Null'
            default:
                return 'Failed'
        }
    }

    public getJsStack() {
        Error.stackTraceLimit = 30
        const jsStack = new Error().stack!
        let ptr = this.jsStackCache.get(jsStack)
        if (ptr == undefined) {
            this.jsStackCache.set(jsStack, (ptr = this.native.allocateUTF8(jsStack)))
        }
        return ptr
    }

    public startEditor2(schemaVersion: number) {
        this.native._startWukong2(IN_JEST_TEST, !isEnglishLanguage(), schemaVersion)
        this.state = EditorServiceState.Ready
    }

    public decompressPayload(payload: Uint8Array, payloadCompressType: Wukong.DocumentProto.CompressType) {
        const payloadPtr = this.copy1dArray(payload, HEAPDest.HEAP8)
        this.native._decompressPayload(payloadPtr, payload.length, payloadCompressType)
        // decompressed 会临时被 wasm 保留在其 heap 中， 需要及时拷贝出来，下次再 decompress 就会销毁上次的结果
        const decompressedPayloadLength = this.native._decompressPayload_ret_len()
        const decompressedPayloadPtr = this.native._decompressPayload_ret_ptr()
        const decompressedPayload = this.HEAPU8.subarray(
            decompressedPayloadPtr,
            decompressedPayloadPtr + decompressedPayloadLength
        )
        const newBuffer = new ArrayBuffer(decompressedPayloadLength)
        const copiedArray = new Uint8Array(newBuffer)
        copiedArray.set(decompressedPayload)
        return copiedArray
    }

    public copy1dArray(arr: MemType, dest: HEAPDest) {
        if (!arr?.length) {
            return 0
        }

        const bytesPerElement = this.native[dest].BYTES_PER_ELEMENT

        // 不要 free，这个内存的所有权已经转移给了 font
        const ptr = this.native._malloc(arr.length * bytesPerElement)

        this.native[dest].set(arr, ptr / bytesPerElement)
        return ptr
    }

    public wasmCall(methodCode: number, argPtr: number, wasmCallIndex: number): number {
        return this.native._wasm_call(methodCode, argPtr, wasmCallIndex)
    }

    public free(ptr: number) {
        this.native._free(ptr)
    }

    public destroy() {
        for (const ptr of this.jsStackCache.values()) {
            this.native._free(ptr)
        }

        if (this.canvasState?.type === CanvasStateType.WebGL) {
            this.native.GL.deleteContext(this.canvasState.handle)
        } else if (this.canvasState?.type === CanvasStateType.WebGPU) {
            if (this.canvasState.device) {
                this.canvasState.device.destroy()
            }
            if (this.canvasState.queueId) {
                this.native.WebGPU.mgrQueue.release(this.canvasState.queueId)
            }
            if (this.canvasState.deviceId) {
                this.native.WebGPU.mgrDevice.release(this.canvasState.deviceId)
            }
            if (this.canvasState.adapterId) {
                this.native.WebGPU.mgrAdapter.release(this.canvasState.adapterId)
            }
            this.canvasState.device = null
            this.canvasState.queueId = 0
            this.canvasState.deviceId = 0
            this.canvasState.adapterId = 0
        }
        this.canvasState = null
        this.jsStackCache.clear()
        this.native = undefined as any
        this.state = EditorServiceState.Destroyed
    }

    public getState(): EditorServiceState {
        return this.state
    }
}
