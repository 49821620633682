import { domLocation } from '../../../../../util/src'
import { OrganizationVO, PayPlanType, RoleStatus } from '../../../kernel/interface/type'
import { GetOrganizations } from '../../../kernel/request/organizations'
import { featureSwitchManager } from '../../../kernel/switch/core'
import { WK } from '../../../window'
import { SpaceNotifyFlow } from './notify-flow'
import { getSpaceStore, OrganizationStore, setSpaceStore, SpaceStore, SpaceStoreType } from './types'
import { throttle } from 'lodash-es'

export const generateOrganizationState = (set: setSpaceStore, get: getSpaceStore): OrganizationStore => ({
    organization: {} as OrganizationVO, // 可以默认一定存在， 因为有guard保证
    organizations: [],
    user: {
        nickname: '',
        id: 0,
        avatarId: '',
        email: '',
        desensitizedPhone: '',
        avatarBackgroundColor: '',
    },
    setUser: (user) => {
        set((state) => {
            state.organizationStore.user = user
        })
    },
    setOrganization: async (organization: OrganizationVO) => {
        if (featureSwitchManager.isEnabled('organization-plus') && organization.planType === PayPlanType.enterprise) {
            await get().managedWorkspaceStore.fetch(organization)
        }
        set((state) => {
            state.organizationStore.organization = organization
            if (featureSwitchManager.isEnabled('async-request-schema-version')) {
                WK.orgId = organization.id
            }
        })
        get().teamsFoldersStore.fetch()
        get().favoriteDocsStore.fetch()
        get().planAndUserStateStore.fetch({ needFetchUnpaidSettlements: true })
    },
    setOrganizations: (organizations: OrganizationVO[]) => {
        set((state) => {
            state.organizationStore.organizations = organizations
        })
    },
    changeUserAvatar: (avatarId) => {
        set((state) => {
            if (state.organizationStore.user) {
                state.organizationStore.user.avatarId = avatarId
            }
        })
    },
    changeUserNickname: (nickname) => {
        set((state) => {
            if (state.organizationStore.user) {
                state.organizationStore.user.nickname = nickname
            }
        })
    },
    _refreshData: async (isNotify) => {
        const orgs = await new GetOrganizations(isNotify).start()
        const currOrgId = get().organizationStore.organization.id
        // 如果用户不在组织了，就要跳走
        if (orgs.findIndex((o) => o.id === currOrgId) === -1) {
            domLocation().reload()
            return
        }
        set((state) => {
            state.organizationStore.organizations = orgs
            const org = orgs.find((o) => o.id == currOrgId)
            if (org) {
                state.organizationStore.organization = org
            }
        })
    },
})

export const currDraftIdSelector = (state: SpaceStore) => {
    return state.organizationStore.organization.draftFolderId
}

export const isOrgAdminSelector = (state: SpaceStore) => {
    return state.organizationStore.organization.role == RoleStatus.Admin
}

// 是组织还是个人
export const isOrgSelector = (state: SpaceStore) => {
    return state.organizationStore.organization.id !== '-1' && !state.organizationStore.organization.guest
}

export const syncOrganizationState = (store: SpaceStoreType, notifyFlow: SpaceNotifyFlow) => {
    const debounceFetch = throttle(() => {
        store.getState().organizationStore._refreshData(true)
    }, 500)

    notifyFlow.addUserBriefChangeCallback((res) => {
        if (res?.avatarId) {
            store.getState().organizationStore.changeUserAvatar(res.avatarId)
        }
        if (res?.nickname) {
            store.getState().organizationStore.changeUserNickname(res.nickname)
        }
    })
    notifyFlow.addRelationChangeCallbackWithoutAccess(() => {
        debounceFetch()
    })
    notifyFlow.addPropertyChangeCallbackWithoutDocAndUser(() => {
        store.getState().organizationStore._refreshData(true)
    })
}
