import { ComponentGetVO } from '../../../kernel/interface/component-style'
import { ComponentSetId } from '../../../kernel/interface/component-style-library-id'
import { LibraryContentVO } from '../../../kernel/interface/library'

// 剔除 libraryContent 中的已删除和已移动的组件 & 样式
export function deleteRemovedAndMovedVOInLibraryContent(libraryContent: LibraryContentVO): LibraryContentVO {
    return {
        ...libraryContent,
        styles: libraryContent.styles.filter(
            (vo) => !vo.removed && !libraryContent.libraryMovedInfos.styleMoveRemappings[vo.id]
        ),
        components: libraryContent.components.filter(
            (vo) =>
                !vo.removed && vo.containingPageId && !libraryContent.libraryMovedInfos.componentMoveRemappings[vo.id]
        ),
        componentSets: libraryContent.componentSets.filter(
            (vo) =>
                !vo.removed && vo.containingPageId && !libraryContent.libraryMovedInfos.componentMoveRemappings[vo.id]
        ),
        componentSetId2ChildrenComponents: Object.entries(libraryContent.componentSetId2ChildrenComponents).reduce(
            (res, [setId, variants]) => {
                const newVariants = variants.filter(
                    (vo) =>
                        !vo.removed &&
                        vo.containingPageId &&
                        vo.containerNodeId &&
                        !libraryContent.libraryMovedInfos.componentMoveRemappings[vo.id]
                )
                if (newVariants.length) {
                    res[setId] = newVariants
                }
                return res
            },
            {} as Record<ComponentSetId, ComponentGetVO[]>
        ),
    }
}
