import { useRef } from 'react'
import { AIMagicSwitchProps } from './type'

export const SwitchTimeOutManager = {
    SWITCH_CLOSE_TIMEOUT_MS: 100, // 热区弹窗关闭延迟
    hideSwitchTimeoutId: null as ReturnType<typeof setTimeout> | null,
    clearTimeout() {
        if (this.hideSwitchTimeoutId) {
            clearTimeout(this.hideSwitchTimeoutId)
            this.hideSwitchTimeoutId = null
        }
    },
    runDeferredTask(task: () => void) {
        this.hideSwitchTimeoutId = setTimeout(task, this.SWITCH_CLOSE_TIMEOUT_MS)
    },
}

export function useHoveringSwitch(props: Pick<AIMagicSwitchProps, 'setShowSwitch'>) {
    const hoveringSwitch = useRef(false)

    // 魔法框切换热区
    function onMouseInSwitchArea() {
        SwitchTimeOutManager.clearTimeout()
        hoveringSwitch.current = true
        props.setShowSwitch(true)
    }
    function onMouseOutSwitchArea() {
        SwitchTimeOutManager.clearTimeout()
        hoveringSwitch.current = false
        SwitchTimeOutManager.runDeferredTask(() => {
            if (!hoveringSwitch.current) {
                props.setShowSwitch(false)
            }
        })
    }

    return { onMouseInSwitchArea, onMouseOutSwitchArea }
}
