import { translation } from './effects.translation'
/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { useRef } from 'react'
import { IconAdd16, IconStyle, Tooltip } from '../../../../../../ui-lib/src'
import { IconButton } from '../../atom/button/icon-button'
import { SingleGrid } from '../../atom/grid/single-grid'
import { Title } from '../../atom/title/title'
import style from '../fills/fills.module.less'
import { EffectContextProvider, useEffectContext } from './context'
import { EffectStyleItem } from './effect-style/effect-style-item'
import { SelectEffectStyleModal } from './effect-style/effect-style-select'
import { EffectsList } from './effects-list'

function EffectSectionTitle() {
    const {
        selectionEffectIsNone,
        insertEffectForSelection,
        showEffectStyleSelectModal,
        showEffectControl,
        showEffectStyleControl,
        openEffectStyleSelectModal,
        closeEffectStyleSelectModal,
        showEffectStyle,
        replaceEffectStyleWithSingleDefaultEffect,
    } = useEffectContext()

    const onClickTitle = () => {
        if (selectionEffectIsNone) {
            insertEffectForSelection()
        }
    }

    const showEffectOperations = showEffectControl || showEffectStyleControl

    const buttonRef = useRef<HTMLSpanElement | null>(null)

    const onToggleSelectModal = () => {
        if (!showEffectStyleSelectModal) {
            if (buttonRef.current) {
                openEffectStyleSelectModal(buttonRef.current.getBoundingClientRect())
            }
        } else {
            closeEffectStyleSelectModal()
        }
    }

    const onClickAddPaint = () => {
        if (showEffectStyle) {
            replaceEffectStyleWithSingleDefaultEffect()
        } else {
            insertEffectForSelection()
        }
    }

    return (
        <Title
            grayTheme={selectionEffectIsNone}
            onClick={onClickTitle}
            className={selectionEffectIsNone && !showEffectStyleSelectModal ? style.emptyTitle : ''}
            data-testid={'effect-title'}
        >
            <Title.Left>{translation('Effect')}</Title.Left>
            {showEffectOperations ? (
                <Title.Right>
                    {showEffectStyleControl ? (
                        <Tooltip title={translation('Style')}>
                            <IconButton
                                ref={buttonRef}
                                icon={<IconStyle />}
                                selected={showEffectStyleSelectModal}
                                deepColor
                                onClick={onToggleSelectModal}
                                className={style.styleIcon}
                                data-testid={'add-effect-style'}
                            />
                        </Tooltip>
                    ) : null}
                    {showEffectControl ? (
                        <IconButton
                            icon={<IconAdd16 />}
                            selected={false}
                            onClick={onClickAddPaint}
                            data-testid={'effect-add'}
                        />
                    ) : null}
                </Title.Right>
            ) : null}
        </Title>
    )
}

function EffectSectionBody() {
    const { selectionEffectIsNone, selectionEffectIsMixed, showEffectStyle, showEffectList } = useEffectContext()

    return selectionEffectIsNone ? null : selectionEffectIsMixed ? (
        <SingleGrid>
            <SingleGrid.Item start={5} span={55} className={style.mixedTipText}>
                {translation('Click')} + {translation('ToReplace')}
            </SingleGrid.Item>
        </SingleGrid>
    ) : showEffectStyle ? (
        <EffectStyleItem></EffectStyleItem>
    ) : showEffectList ? (
        <EffectsList></EffectsList>
    ) : null
}

function EffectStyleSelect() {
    const {
        showEffectStyleSelectModal,
        effectStyleSelectModalPosition,
        closeEffectStyleSelectModal,
        selectionEffectIsMixed,
    } = useEffectContext()

    return showEffectStyleSelectModal ? (
        <SelectEffectStyleModal
            onClose={closeEffectStyleSelectModal}
            position={effectStyleSelectModalPosition!}
            isMixed={selectionEffectIsMixed}
        ></SelectEffectStyleModal>
    ) : null
}

function EffectsInternal() {
    const { selectionEffectIsNone } = useEffectContext()

    return (
        <div className={classnames('pt-8px', selectionEffectIsNone ? 'pb-8px' : 'pb-12px')}>
            <EffectSectionTitle></EffectSectionTitle>
            <EffectSectionBody></EffectSectionBody>
            <EffectStyleSelect></EffectStyleSelect>
        </div>
    )
}

export function Effects() {
    return (
        <EffectContextProvider>
            <EffectsInternal></EffectsInternal>
        </EffectContextProvider>
    )
}
