import {
    AIReplacePreviewGenerateDataCommand,
    ApplyAIComponentReplaceCommand,
    BuildAIReplaceNodesDataCommand,
    CurrentPageSetSelectionCommandWasmCall,
    SetSelectionMaybeJumpPageCommand,
    ValidateAIReplaceSceneCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { useCallback, useEffect, useState } from 'react'
import {
    MonoIconCommonArrowLeft16,
    MonoIconCommonArrowRight16,
    MonoIconPanelMainComponentOut16,
    WKButton,
    WKToast,
} from '../../../../../../ui-lib/src'
import { WKFrog } from '../../../../kernel/frog'
import {
    CandidateComponentPreviewInfo,
    CandidateComponentPreviewInfoResponse,
} from '../../../../kernel/request/candidate-component'
import {
    AIComponentReplaceEntry,
    AIComponentReplaceScene,
    AIComponentReplaceSnapshot,
} from '../../../../main/ai-service/typings'
import { useAppContext, useCommand, useComponentService } from '../../../../main/app-context'
import { MotiffSandbox, SandBoxManager } from '../../../../sandbox'
import { IconButton } from '../../atom/button/icon-button'
import styles from './tool-ai-replace-component-preview.module.less'
import { translation } from './tool-ai-replace-component-preview.translation'

interface AiReplaceResponseItem {
    icon: { [k: string]: string }
    text: { [k: string]: string }
}

export function ToolAiReplaceComponentPreview({
    onBack,
    sceneItem,
    snapshots,
    onConfirm,
    isStyle,
    isSelectPage,
}: {
    onBack: () => void
    sceneItem: AIComponentReplaceEntry
    snapshots: Record<number, AIComponentReplaceSnapshot>
    onConfirm: (id: number, version: number) => void
    isStyle: boolean
    isSelectPage: boolean
}) {
    const aiService = useAppContext().aiService
    const command = useCommand()
    const [currentPage, setCurrentPage] = useState(1)
    const [allPage, setAllPage] = useState(1)
    const [snap, setSnap] = useState<AIComponentReplaceSnapshot>()
    const [nodeIdsArray, setNodeIdsArray] = useState<Wukong.DocumentProto.INodeIdsWrapper[]>()
    const [aiJsonPromise, setAiJsonPromise] = useState<Promise<CandidateComponentPreviewInfoResponse>>()
    const componentService = useComponentService()

    const [loading, setLoading] = useState(false)
    const [manualLoading, setManualLoading] = useState(false)

    const [imgSrc, setImgSrc] = useState<string>('')
    const [lockPage, setLockPage] = useState(false)

    const [sandboxTimer, setSandboxTimer] = useState<NodeJS.Timeout>()

    useEffect(() => {
        return () => {
            if (sandboxTimer) {
                clearInterval(sandboxTimer)
            }
        }
    }, [sandboxTimer])

    useEffect(() => {
        setImgSrc('')
    }, [currentPage])

    useEffect(() => {
        if (!sceneItem) {
            return
        }
        const currentSnap = snapshots[sceneItem.substituteContentId]
        if (!currentSnap) {
            return
        }
        setSnap(currentSnap)
        setAllPage(sceneItem.items.length)
        if (currentPage > sceneItem.items.length) {
            setCurrentPage(sceneItem.items.length)
        }
    }, [sceneItem, snapshots, currentPage, setCurrentPage])

    const reGenerateSnapshot = useCallback(
        (nodeId: string, sandbox: MotiffSandbox) => {
            const ret = sandbox.commands.ReGenerateSnapshotCommand.invoke({
                nodeId,
            })
            setImgSrc(ret.imgBase64)
        },
        [setImgSrc]
    )

    const sendToSandBox = async (
        currentSnap: AIComponentReplaceSnapshot,
        currentNodeIdsArray: Wukong.DocumentProto.INodeIdsWrapper[],
        currentItem: AIComponentReplaceScene,
        aiResList: AiReplaceResponseItem[]
    ) => {
        if (!currentSnap) {
            return
        }
        const replaceDataRet = command.DEPRECATED_invokeBridge(AIReplacePreviewGenerateDataCommand, {
            nodeId: currentItem.containerIds[0],
        })

        //  获取替换目标组件数据
        const libraryResponse = await aiService
            .getLibraryResourceDownloader()
            .fetchFile(currentSnap.nodeProtoDataResourceId)
        if (!libraryResponse) {
            return
        }
        const protoArr = new Uint8Array(await libraryResponse.arrayBuffer())
        const exportedDocument = Wukong.DocumentProto.SerializedExportedDocument.decode(protoArr)
        const param = Wukong.DocumentProto.ApplyAIComponentReplaceCommandParam.create({
            exportedDocument,
            toCreateNodeId: currentSnap.nodeId,
            nodeIdsArray: currentNodeIdsArray,
            aiReplaceResults: aiResList,
        })

        const sandbox = await SandBoxManager.sharedInstance
        const sandBoxRet = sandbox.commands.AIReplacePreviewSnapshotCommand.invoke({
            protoDataBase64: replaceDataRet.protoData,
            containerId: currentItem.containerIds[0],
            replaceData: param,
        })

        setImgSrc(sandBoxRet.imgBase64)
        clearInterval(sandboxTimer)
        const timer = setInterval(() => {
            reGenerateSnapshot(sandBoxRet.newId, sandbox)
        }, 2000)
        setSandboxTimer(timer)
    }

    useEffect(() => {
        if (!snap) {
            return
        }

        const currentItem = sceneItem.items[currentPage - 1]
        if (!currentItem) {
            return
        }

        const nodeIdsArr = currentItem.nodeIds.map((subArray) => ({ value: subArray }))

        const validateRet = command.DEPRECATED_invokeBridge(ValidateAIReplaceSceneCommand, {
            container: currentItem.containerIds[0],
            nodeIdsArray: nodeIdsArr,
        })

        if (!validateRet.success) {
            switch (validateRet.errorType) {
                case Wukong.DocumentProto.ValidateAIReplaceSceneErrorType.VALIDATE_A_I_REPLACE_SCENE_ERROR_TYPE_NO_NODE:
                    isSelectPage
                        ? WKToast.show(translation('PageLayerDeleted'))
                        : WKToast.show(translation('FileLayerDeleted'))
                    break
                case Wukong.DocumentProto.ValidateAIReplaceSceneErrorType
                    .VALIDATE_A_I_REPLACE_SCENE_ERROR_TYPE_IN_INSTANCE:
                    WKToast.show(translation('CantReplaceWithinInstances'))
                    break
                default:
                    break
            }
            onConfirm(currentItem.id, currentItem.version)
            return
        }

        const validatedNodeIdsArr = validateRet.resultArray

        setNodeIdsArray(validatedNodeIdsArr)

        command.DEPRECATED_invokeBridge(SetSelectionMaybeJumpPageCommand, {
            selection: [currentItem.containerIds[0]],
            leftShift: true,
        })

        const ret = command.DEPRECATED_invokeBridge(BuildAIReplaceNodesDataCommand, {
            nodeIdsArray: validatedNodeIdsArr,
        })

        command.DEPRECATED_invokeBridge(CurrentPageSetSelectionCommandWasmCall, {
            selection: validatedNodeIdsArr.flatMap((item) => item.value),
        })
        command.commitUndo()

        if (!ret.jsonDataArray || ret.jsonDataArray.length === 0) {
            return
        }

        const componentJsonResourceId = snap.nodeJsonDataResourceId

        setAiJsonPromise(
            new CandidateComponentPreviewInfo(componentJsonResourceId, ret.jsonDataArray).start().then(async (res) => {
                if (res?.previewInfo) {
                    try {
                        const aiResList: AiReplaceResponseItem[] = JSON.parse(res.previewInfo)
                        sendToSandBox(snap, validatedNodeIdsArr, currentItem, aiResList)
                    } catch (e) {}
                }

                return res
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [command, currentPage, isSelectPage, onConfirm, sceneItem.items, snap])

    const handleReplaceBtnClick = useCallback(
        async (isManual = false) => {
            if (!snap) {
                return
            }
            const response = await aiService.getLibraryResourceDownloader().fetchFile(snap.nodeProtoDataResourceId)
            if (!response) {
                return
            }

            const protoArr = new Uint8Array(await response.arrayBuffer())

            const exportedDocument = Wukong.DocumentProto.SerializedExportedDocument.decode(protoArr)
            if (isStyle) {
                const param = Wukong.DocumentProto.ApplyAIComponentReplaceCommandParam.create({
                    exportedDocument,
                    toCreateNodeId: snap.nodeId,
                    nodeIdsArray,
                    aiReplaceResults: [],
                })
                command.DEPRECATED_invokeBridge(ApplyAIComponentReplaceCommand, param)
                command.commitUndo()
            } else {
                const res = await aiJsonPromise
                if (res?.previewInfo) {
                    try {
                        const aiResList: AiReplaceResponseItem[] = JSON.parse(res.previewInfo)
                        const param = Wukong.DocumentProto.ApplyAIComponentReplaceCommandParam.create({
                            exportedDocument,
                            toCreateNodeId: snap.nodeId,
                            nodeIdsArray,
                            aiReplaceResults: aiResList,
                            isManual,
                        })
                        command.DEPRECATED_invokeBridge(ApplyAIComponentReplaceCommand, param)
                        command.commitUndo()
                    } catch (e) {}
                }
            }
        },
        [snap, aiService, nodeIdsArray, aiJsonPromise, command, isStyle]
    )

    const handleReplaceBtnClickWrapper = useCallback(
        async (isManual = false) => {
            if (!lockPage) {
                if (isManual) {
                    setManualLoading(true)
                } else {
                    setLoading(true)
                }
                await handleReplaceBtnClick(isManual)
                WKFrog.addFrogRecord({
                    url: '/click/AIReplacesComponent/ClickStartReciveReplace',
                    eventId: 26848,
                    eventAction: 'click',
                    eventName: 'ClickStartReciveReplace',
                })

                if (isManual) {
                    setManualLoading(false)
                } else {
                    setLoading(false)
                }
                setLockPage(true)
            } else {
                setLockPage(false)
                const item = sceneItem.items[currentPage - 1]
                if (item) {
                    // 这是向server发送替换的请求, 调试时可以注释掉, 让一个场景可以反复替换
                    onConfirm(item.id, item.version)
                }
            }
        },
        [handleReplaceBtnClick, setLoading, setLockPage, lockPage, currentPage, onConfirm, sceneItem.items]
    )

    const jumpToComponent = useCallback(() => {
        const currentSnap = snapshots[sceneItem.substituteContentId]
        if (!currentSnap) {
            return
        }
        componentService.jumpToOriginDocument({
            docId: currentSnap.docId,
            nodeId: currentSnap.nodeId,
            fromFig: false,
            name: '',
            isShape: currentSnap.type === 'component',
        })
    }, [componentService, sceneItem, snapshots])

    return (
        <div className={styles.sceneWrapper}>
            <div className={styles.sceneHeader}>
                <IconButton
                    selected={false}
                    onClick={onBack}
                    icon={<MonoIconCommonArrowLeft16 className={styles.forceSvgGray13} />}
                    className={`${styles.backBtn} `}
                />
                <div className={styles.text}>{snap?.name ?? ''}</div>
                <IconButton
                    selected={false}
                    onClick={() => {
                        jumpToComponent()
                    }}
                    icon={<MonoIconPanelMainComponentOut16 className={styles.forceSvgGray13} />}
                />
            </div>
            <div className={styles.sceneBody} draggable={false}>
                {imgSrc ? (
                    <img src={`data:image/png;base64,${imgSrc}`} className={styles.img} draggable={false} />
                ) : (
                    <div className={styles.loading}>{translation('Loading')}...</div>
                )}
                {currentPage !== 1 && !lockPage && (
                    <IconButton
                        selected={false}
                        className={styles.btn}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        icon={<MonoIconCommonArrowLeft16 className={styles.forceSvgWhite} />}
                    />
                )}
                {currentPage !== allPage && !lockPage && (
                    <IconButton
                        selected={false}
                        className={`${styles.btn} ${styles.next}`}
                        onClick={() => setCurrentPage(currentPage + 1)}
                        icon={<MonoIconCommonArrowRight16 className={styles.forceSvgWhite} />}
                    />
                )}
            </div>
            <div className={styles.sceneFooter}>
                <div className={styles.pageNum}>
                    {currentPage}/{allPage}
                </div>
                <div className={styles.buttonWrapper}>
                    {!isStyle && !lockPage && (
                        <WKButton
                            type="secondary"
                            onClick={() => handleReplaceBtnClickWrapper(true)}
                            size="medium"
                            loading={manualLoading}
                            disabled={loading}
                        >
                            {translation('ManualReplacement')}
                        </WKButton>
                    )}
                    <WKButton
                        type="primary"
                        onClick={() => handleReplaceBtnClickWrapper()}
                        size="medium"
                        loading={loading}
                        disabled={manualLoading}
                    >
                        {lockPage ? translation('Next') : translation('SmartReplacement')}
                    </WKButton>
                </div>
            </div>
        </div>
    )
}
