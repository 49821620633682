import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Portrait: '纵向',
    Landscape: '横向',
    ResizeTo: '适应内容大小',
} as const

export const enTranslation = {
    Portrait: 'Portrait',
    Landscape: 'Landscape',
    ResizeTo: 'Resize to fit',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
