const definedMinWidthWithoutBackwardIcon = 132
const definedMinWidthWithBackwardIcon = 200
const definedMaxWidth = 460

export function getOptionMinWidth(hasBackwardIcon: boolean) {
    return hasBackwardIcon ? definedMinWidthWithBackwardIcon : definedMinWidthWithoutBackwardIcon
}

export function getOptionMaxWidth(maxWidth?: number) {
    return Math.max(definedMinWidthWithoutBackwardIcon, definedMinWidthWithBackwardIcon, maxWidth ?? definedMaxWidth)
}
