import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    RecentVersions: '当前仅展示最近 30 天的历史版本，付费团队可以无限查看历史版本，请将文件移动至付费团队。[[button]]',
    UnlimitedVersions: '付费团队可以无限查看历史版本，请将文件移动至付费团队。[[button]]',
    RecentVersionsUpgrade: '当前仅展示最近 30 天的历史版本，[[button]]到付费版可以无限查看历史版本。',
    UnlimitedVersionsUpgrade: '[[button]]到付费版可以无限查看历史版本。',
    ViewMoreHistory: '查看更多历史版本',
    UnlimitedHistory: '无限历史版本',
    MoveFile: '移动文件',
    LearnMore: '了解更多',
    Upgrade: '升级',
    PayUpgradeDialogTitle: '升级至专业版以查看更多历史版本',
    PayUpgradeDialogSubTitle: '基础版仅支持查看 30 天内的历史版本，升级专业版后可无限查看历史版本。',
    OnlyShowVersionWithin30D: '仅展示 30 天内的历史版本',
    ThreeFileOneProject: '3 个文件和 1 个项目',
    ThreePagesPerFile: '每个文件 3 个页面',
    UnlimitedVersions1: '无限历史版本',
    UnlimitedFilesAndProjects: '无限文件和项目',
    UnlimitedPagesInFiles: '无限页面',
} as const

export const enTranslation = {
    RecentVersions: 'Unlimited version history is available for paid teams. [[button]]',
    UnlimitedVersions: 'Unlimited version history is available for paid teams. [[button]]',
    RecentVersionsUpgrade: '[[button]] to view versions past 30 days',
    UnlimitedVersionsUpgrade: '[[button]] to view versions past 30 days',
    ViewMoreHistory: 'Need to travel back in time?',
    UnlimitedHistory: 'Everything else is history.',
    MoveFile: 'Move file',
    LearnMore: 'Learn more',
    Upgrade: 'Upgrade to a Professional plan',
    PayUpgradeDialogTitle: 'Want to see more version history? ',
    PayUpgradeDialogSubTitle:
        'The Starter plan only supports viewing the history version from the last 30 days. Upgrade to Professional plan to view unlimited version history.',
    OnlyShowVersionWithin30D: 'Show version history within 30 days',
    ThreeFileOneProject: '3 design files and 1 project',
    ThreePagesPerFile: '3 pages per design file',
    UnlimitedVersions1: 'Unlimited version history',
    UnlimitedFilesAndProjects: 'Unlimited files and projects',
    UnlimitedPagesInFiles: 'Unlimited pages in design files',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
