import classNames from 'classnames'
import { forwardRef, useCallback } from 'react'
import { useRadioIconButtonGroupContext } from './use-radio-icon-group-context'

export interface WKRadioIconButtonProps {
    value: string
    icon: React.ReactNode
    /**
     * @defaultValue: false
     */
    disabled?: boolean
    preventDefaultFocus?: boolean
    dataTestId?: string
}

const styles = {
    base: ['flex-none inline-flex flex-row items-center justify-center rounded-$wk-radius-default box-border'],
    checked: ['bg-$wk-white border-solid border-1 border-$wk-gray-13-8', 'has-[:disabled]:color-$wk-gray-6'],
    unchecked: ['hover:bg-$wk-gray-13-4', 'has-[:disabled]:color-$wk-gray-6'],
    size: {
        24: 'w-20px h-20px',
        28: 'w-24px h-24px',
    },
}

export const WKRadioIconButton = forwardRef(function Component(
    props: WKRadioIconButtonProps,
    ref?: React.Ref<HTMLLabelElement>
) {
    const { value, icon, disabled = false, preventDefaultFocus, dataTestId } = props
    const { size, name, computedValue, onRadioItemClick, activeClassName } = useRadioIconButtonGroupContext()
    const checked = computedValue === value

    const labelClassNames = classNames(
        styles.base,
        styles.size[size],
        styles[checked ? 'checked' : 'unchecked'],
        checked && activeClassName
    )

    const onClick = useCallback(
        (e: React.MouseEvent) => {
            if (preventDefaultFocus) {
                // 阻止默认的点击label触发里面 radio input 的 onChange 和 input 的 focus 事件
                e.preventDefault()
                onRadioItemClick(value)
            }
        },
        [preventDefaultFocus, onRadioItemClick, value]
    )

    return (
        <label ref={ref} className={labelClassNames} data-testid={dataTestId ?? value} onClick={onClick}>
            <input
                className="border-none m-0 p-0 w-0 h-0 opacity-0"
                type="radio"
                name={name}
                value={value}
                checked={checked}
                disabled={disabled}
                onChange={() => onRadioItemClick(value)}
            />
            {icon}
        </label>
    )
})
