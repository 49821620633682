import { UpdateInstanceBubbledWasmCall, Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import React from 'react'
import { MonoIconCommonRemove16, MonoIconLayerInstance16, WKIconButton } from '../../../../../../../../../../ui-lib/src'
import { Checkbox } from '../../../../../../../../../../ui-lib/src/components/checkbox'
import Tooltip from '../../../../../../../../../../ui-lib/src/components/tooltip/tooltip'
import { CommitType } from '../../../../../../../../document/command/commit-type'
import { useCommand } from '../../../../../../../context/document-context'
import styles from './index.module.less'
import { translation } from './index.translation'

enum InstanceOrderPosition {
    StartOrMiddle,
    End,
}

export function PublicPropInstanceHeader(props: {
    isPublic: boolean
    instance: Wukong.DocumentProto.IPublicPropInstance
    hovered: boolean
    mergedIds: string[]
    resetInstanceHovered: () => void
    allowDelete?: boolean
}) {
    const { isPublic, instance, mergedIds, hovered, allowDelete, resetInstanceHovered } = props
    const command = useCommand()

    const toggleCheck = (force?: boolean) => {
        let newChecked = !isPublic
        if (force !== undefined) {
            newChecked = force
        }
        command.invokeBridge(CommitType.CommitUndo, UpdateInstanceBubbledWasmCall, {
            nodeIds: mergedIds,
            value: newChecked,
        })
        resetInstanceHovered()
        ;(document.activeElement as HTMLElement)?.blur()
    }

    if (allowDelete) {
        if (hovered) {
            return (
                <div className={styles.allowDeleteTreeHoveredItem}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <MonoIconLayerInstance16 className={styles.prefixIcon} />
                        <div className={styles.instanceName}>{instance.name}</div>
                    </div>
                    <Tooltip title={translation('removeInstance')}>
                        <WKIconButton
                            icon={<MonoIconCommonRemove16 />}
                            onClick={() => toggleCheck(false)}
                            className={styles.deleteBtn}
                        />
                    </Tooltip>
                </div>
            )
        } else {
            return (
                <div className={styles.allowDeleteTreeInstanceItem}>
                    <MonoIconLayerInstance16 />
                    <div className={styles.instanceName}>{instance.name}</div>
                </div>
            )
        }
    }
    return (
        <div className={styles.treeInstanceItem} onClick={() => toggleCheck()}>
            <Checkbox size="small" checked={isPublic} testid={`public-instance-checkbox-${instance.nodeId}`} />
            <div className={styles.instanceName}>{instance.name}</div>
        </div>
    )
}

export function StartOrMiddleIcon() {
    return (
        <div style={{ display: 'flex' }}>
            <div
                style={{
                    width: '6px',
                    height: '20px',
                    borderRight: '1px solid var(--wk-gray-3)',
                }}
            />
            <div style={{ width: '6px', height: '20px' }}>
                <div
                    style={{
                        width: '6px',
                        height: '10px',
                        borderBottom: '1px solid var(--wk-gray-3)',
                    }}
                />
                <div
                    style={{
                        width: '6px',
                        height: '10px',
                    }}
                />
            </div>
        </div>
    )
}

export function EndIcon() {
    return (
        <div style={{ display: 'flex' }}>
            <div
                style={{
                    width: '6px',
                    height: '20px',
                }}
            ></div>
            <div style={{ width: '6px', height: '20px' }}>
                <div
                    style={{
                        width: '6px',
                        height: '10px',
                        borderLeft: '1px solid var(--wk-gray-3)',
                        borderBottom: '1px solid var(--wk-gray-3)',
                    }}
                />
                <div
                    style={{
                        width: '6px',
                        height: '10px',
                    }}
                />
            </div>
        </div>
    )
}

export function PublicPropInstanceBody(props: {
    pos: InstanceOrderPosition
    instance: Wukong.DocumentProto.IPublicPropInstance
}) {
    const { instance, pos } = props

    return (
        <div className={styles.treeInstanceItem}>
            {pos === InstanceOrderPosition.StartOrMiddle ? <StartOrMiddleIcon /> : <EndIcon />}

            <div className={classNames(styles.instanceName, styles.subtree)}>{instance.name}</div>
        </div>
    )
}

export function SinglePublicPropInstanceChain(props: {
    chain: Wukong.DocumentProto.IPublicPropInstanceChain
    index: number
    allowDelete?: boolean
    onInstanceHovered: (nodeIds: string[], index: number) => void
    hovered: boolean
}) {
    const { chain, index, allowDelete, onInstanceHovered, hovered } = props
    return (
        <div
            onMouseEnter={() => onInstanceHovered(chain.mergedIds, index)}
            onPointerLeave={() => onInstanceHovered([], -1)}
        >
            <PublicPropInstanceHeader
                isPublic={chain.isPublic}
                instance={chain.instances[0]}
                mergedIds={chain.mergedIds}
                hovered={hovered}
                resetInstanceHovered={() => onInstanceHovered([], -1)}
                allowDelete={allowDelete}
            />
            {chain.instances.map((instance, innerIndex) => {
                if (!innerIndex) {
                    return null
                }
                return (
                    <React.Fragment key={instance.nodeId}>
                        <div className={innerIndex == 1 ? styles.treeInstanceHeadGap : styles.treeInstanceGap} />
                        <PublicPropInstanceBody
                            pos={
                                innerIndex == chain.instances.length - 1
                                    ? InstanceOrderPosition.End
                                    : InstanceOrderPosition.StartOrMiddle
                            }
                            instance={instance}
                        />
                    </React.Fragment>
                )
            })}
        </div>
    )
}
