import { FC, useEffect, useRef } from 'react'
import { useEffectOnce } from 'react-use'
import { wkNotification } from '../../../../../ui-lib/src'
import { WKTour } from '../../../../../ui-lib/src/components/wk-tour'
import { getMotiffName } from '../../../../../util/src'
import { WKFrog } from '../../../kernel/frog'
import { OnboardingPopupStatus, OnboardingScene, OnboardingStatus } from '../../../kernel/interface/onboarding'
import { useShowDevModeReminder } from '../../../ui/component/top-area/payment-alert-banner/hooks'
import { onboardingService } from '../../service/onboarding-service'
import { devModeTourSteps } from './config'
import { translation } from './index.translation'

export const DevModeOnboarding: FC = () => {
    // 研发模式的引导和降级引导互斥，当存在降级引导是不展示普通引导
    const showReminder = useShowDevModeReminder()

    return showReminder ? null : <DevModeOnboardingSteps />
}

const DevModeOnboardingSteps: FC = () => {
    const popupStatus = onboardingService.useZustandStore.use.popupStatus()
    const onboardingStep = onboardingService.useZustandStore.use.step()
    const notification = useRef<undefined | { close: () => void }>()

    const onUnload = () => {
        onboardingService.pageUnload(OnboardingScene.DevMode)
    }

    useEffectOnce(() => {
        onboardingService.loaded(OnboardingScene.DevMode)
        window.addEventListener('unload', onUnload)

        return () => {
            window.removeEventListener('unload', onUnload)
        }
    })

    const onClose = () => {
        onboardingService.packingFrog(OnboardingScene.DevMode, 'closed')
        onboardingService.end(OnboardingScene.DevMode, OnboardingStatus.Closed)
    }

    const onChange = () => {
        onboardingService.handleNextStep(OnboardingScene.DevMode)
    }

    const onClickStart = () => {
        WKFrog.addFrogRecord({
            url: '/click/DevTutorial/devGuide0Start',
            eventId: 26721,
            currentPage: 'DevTutorial',
            eventAction: 'click',
            eventName: 'devGuide0Start',
        })
        onboardingService.restart(OnboardingScene.DevMode)
    }

    const onClickClose = () => {
        WKFrog.addFrogRecord({
            url: '/click/DevTutorial/devGuide0Refuse',
            eventId: 26723,
            currentPage: 'DevTutorial',
            eventAction: 'click',
            eventName: 'devGuide0Refuse',
        })
        onboardingService.end(OnboardingScene.DevMode, OnboardingStatus.Closed)
    }

    const onClickDontNeed = () => {
        WKFrog.addFrogRecord({
            url: '/click/DevTutorial/devGuide0Close',
            eventId: 26722,
            currentPage: 'DevTutorial',
            eventAction: 'click',
            eventName: 'devGuide0Close',
        })
        onboardingService.end(OnboardingScene.DevMode, OnboardingStatus.Closed)
    }

    useEffect(() => {
        if (popupStatus === OnboardingPopupStatus.Notify) {
            WKFrog.addFrogRecord({
                url: '/expose/DevTutorial/devGuide0',
                eventId: 26720,
                currentPage: 'DevTutorial',
                eventAction: 'expose',
                eventName: 'devGuide0',
            })
            notification.current = wkNotification.show({
                title: translation('FollowMotiff', { name: getMotiffName() }),
                content: translation('Content'),
                okText: translation('Start'),
                cancelText: translation('No'),
                onOk: onClickStart,
                onClose: onClickClose,
                onCancel: onClickDontNeed,
                duration: 0,
                okButtonProps: {
                    white: true,
                },
                cancelButtonProps: {
                    white: true,
                },
                wrapClassName: '!bg-$wk-brand-7',
                titleClassName: '!color-white',
                contentClassName: '!color-white',
                closeIconProps: { type: 'white' },
            })

            return notification.current?.close
        } else {
            notification.current?.close()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [popupStatus])

    if (popupStatus === OnboardingPopupStatus.DevModeTour) {
        return (
            <>
                {devModeTourSteps.map((step, idx) => (
                    <img key={idx} src={step.image || ''} style={{ display: 'none' }} />
                ))}
                <WKTour
                    open
                    draggable
                    current={onboardingStep}
                    steps={devModeTourSteps}
                    zIndex={800}
                    onClose={onClose}
                    onChange={onChange}
                />
            </>
        )
    }

    return null
}
