/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { DeepRequired } from '../../../view-state-bridge'
import { useMemo } from 'react'
import { textColorIsDark } from './text-color-is-dark'
import { useMemoryUsageValue } from './use-memory-usage-value'
import { useUsedMemory } from './context/used-memory-context'

export function useMemoryUsageBar(
    memoryUsage?: Wukong.DocumentProto.IVMemoryUsage,
    background?: DeepRequired<Wukong.DocumentProto.IPaint>
) {
    const isDark = useMemo(() => textColorIsDark(background), [background])
    const { usedMemory } = useUsedMemory()
    const computedUsedMemory = useMemoryUsageValue(usedMemory)
    return {
        show: !!memoryUsage?.show, // 是否展示
        nodeCount: memoryUsage?.sceneNodesCount || 0, // 图层数量
        memoryUsedPercent: computedUsedMemory.memoryUsedPercent, // 内存占用百分比，是 0.x 的小数而非字符串
        memoryUsedValue: computedUsedMemory.memoryUsedValue, // 内存占用量，单位是 GB 的小数
        isDark, // 是否使用深色模式
    }
}
