export const ErrorSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="240" height="144" viewBox="0 0 240 144">
        <path
            transform="matrix(1 0 0 1 15.4701 102.604)"
            d="M16.9975 0C7.61002 0 0 7.61002 0 16.9975L0 17.023C0 26.4104 7.61002 33.9949 16.9975 33.9949L192.062 33.9949C201.45 33.9949 209.06 26.4104 209.06 17.023L209.06 16.9975C209.06 7.61002 201.45 0 192.062 0L16.9975 0Z"
            fillRule="nonzero"
            fill="rgb(244, 245, 245)"
        />
        <path
            transform="matrix(1 0 0 1 44.2126 11.1345)"
            d="M6.71923 0C3.0083 0 0 3.0083 0 6.71923L0 97.3277C0 101.039 3.0083 104.047 6.71923 104.047L144.856 104.047C148.566 104.047 151.575 101.039 151.575 97.3277L151.575 6.71923C151.575 3.0083 148.566 0 144.856 0L6.71923 0Z"
            fillRule="nonzero"
            fill="rgb(255, 255, 255)"
        />
        <path
            transform="matrix(1 0 0 1 44.2126 11.1345)"
            d="M-0 6.71923C0 3.0083 3.0083 -0 6.71923 -0L144.856 0C148.566 0 151.575 3.0083 151.575 6.71923L151.575 97.3277C151.575 101.039 148.566 104.047 144.856 104.047L6.71923 104.047C3.0083 104.047 -0 101.039 -0 97.3277L0 6.71923ZM6.71923 1.5C3.83673 1.5 1.5 3.83673 1.5 6.71923L1.5 97.3277C1.5 100.21 3.83673 102.547 6.71923 102.547L144.856 102.547C147.738 102.547 150.075 100.21 150.075 97.3277L150.075 6.71923C150.075 3.83673 147.738 1.5 144.856 1.5L6.71923 1.5Z"
            fillRule="nonzero"
            fill="rgb(130, 138, 153)"
        />
        <path
            transform="matrix(1 0 0 1 50.2292 17.0276)"
            d="M2.23974 0C1.00277 0 0 1.00277 0 2.23974L0 10.8365C0 11.455 0.501384 11.9564 1.11987 11.9564L138.558 11.9564C139.177 11.9564 139.678 11.455 139.678 10.8365L139.678 2.23974C139.678 1.00277 138.676 0 137.439 0L2.23974 0Z"
            fillRule="nonzero"
            fill="rgb(232, 232, 232)"
        />
        <path
            transform="matrix(1 0 0 1 50.2292 104.703)"
            d="M0 0L139.678 0L139.678 4.14711L0 4.14711L0 0Z"
            fillRule="nonzero"
            fill="rgb(247, 247, 247)"
        />
        <path
            transform="matrix(1 0 0 1 50.2292 41.4169)"
            d="M0 0L28.5507 0L28.5507 61.2383L0 61.2383L0 0Z"
            fillRule="nonzero"
            fill="rgb(247, 247, 247)"
        />
        <path
            transform="matrix(1 0 0 1 161.357 41.4169)"
            d="M0 0L28.5507 0L28.5507 61.2383L0 61.2383L0 0Z"
            fillRule="nonzero"
            fill="rgb(247, 247, 247)"
        />
        <path
            transform="matrix(1 0 0 1 57.051 20.7676)"
            d="M2.23974 0C3.47672 0 4.47949 1.00277 4.47949 2.23974C4.47949 3.47672 3.47672 4.47949 2.23974 4.47949C1.00277 4.47949 0 3.47672 0 2.23974C0 1.00277 1.00277 0 2.23974 0Z"
            fillRule="nonzero"
            fill="rgb(218, 220, 224)"
        />
        <path
            transform="matrix(1 0 0 1 65.6758 20.7676)"
            d="M2.23974 0C3.47672 0 4.47949 1.00277 4.47949 2.23974C4.47949 3.47672 3.47672 4.47949 2.23974 4.47949C1.00277 4.47949 0 3.47672 0 2.23974C0 1.00277 1.00277 0 2.23974 0Z"
            fillRule="nonzero"
            fill="rgb(218, 220, 224)"
        />
        <path
            transform="matrix(1 0 0 1 74.3005 20.7676)"
            d="M2.23974 0C3.47672 0 4.47949 1.00277 4.47949 2.23974C4.47949 3.47672 3.47672 4.47949 2.23974 4.47949C1.00277 4.47949 0 3.47672 0 2.23974C0 1.00277 1.00277 0 2.23974 0Z"
            fillRule="nonzero"
            fill="rgb(218, 220, 224)"
        />
        <path
            transform="matrix(1 0 0 1 50.2292 31.8979)"
            d="M0 0L139.678 0L139.678 7.12034L0 7.12034L0 0Z"
            fillRule="nonzero"
            fill="rgb(247, 247, 247)"
        />
        <path
            transform="matrix(1 0 0 1 157.218 82.79)"
            d="M0.00416892 0.464942C-0.0446918 0.114774 0.346194 -0.12953 0.639358 0.0740566L10.8268 7.18329C11.1281 7.39502 11.0141 7.86733 10.6558 7.92434L5.33813 8.68982C5.22412 8.70611 5.1264 8.76311 5.06125 8.86084L2.3902 12.6394C2.17847 12.9407 1.70615 12.8267 1.65729 12.4602L0.00416892 0.464942Z"
            fillRule="nonzero"
            fill="rgb(130, 138, 153)"
        />
        <path
            transform="matrix(1 0 0 1 82.7816 127.41)"
            d="M0 0L0 -1.5L74.4368 -1.5L74.4368 0L0 0Z"
            fillRule="nonzero"
            fill="rgb(130, 138, 153)"
        />
        <path
            transform="matrix(1 0 0 1 100.305 87.9921)"
            d="M9.62823 0L0 22.2149L19.2008 11.7259L19.2008 0L9.62823 0Z"
            fillRule="nonzero"
            fill="rgb(233, 233, 233)"
        />
        <path
            transform="matrix(-1 0 0 1 139.489 87.9921)"
            d="M9.90667 0L0 22.2215L19.4894 11.7259L19.4894 0L9.90667 0Z"
            fillRule="nonzero"
            fill="rgb(233, 233, 233)"
        />
        <path
            transform="matrix(1 0 0 1 120 65.411)"
            d="M-0.75 61.4626L-0.75 0L0.75 0L0.75 61.4626L-0.75 61.4626Z"
            fillRule="nonzero"
            fill="rgb(130, 138, 153)"
        />
        <path
            transform="matrix(0 -1 1 0 86.8289 75.54)"
            d="M0.439231 2.60471Q-0.0544818 3.00099 -0.5159 3.43827C-0.816549 3.72319 -0.829299 4.1979 -0.544378 4.49855C-0.259456 4.7992 0.215251 4.81195 0.5159 4.52703Q0.932555 4.13217 1.37817 3.77449Q3.34573 2.19532 5.7426 1.42012Q8.0774 0.664995 10.5381 0.760431Q13.0071 0.856186 15.2795 1.7961Q17.6269 2.76702 19.4841 4.52703C19.7848 4.81195 20.2595 4.7992 20.5444 4.49855C20.8293 4.1979 20.8165 3.72319 20.5159 3.43827Q18.457 1.48711 15.8528 0.409982Q13.3328 -0.632306 10.5963 -0.738442Q7.86931 -0.844203 5.281 -0.00709063Q2.62183 0.852942 0.439231 2.60471Z"
            fillRule="evenodd"
            fill="rgb(130, 138, 153)"
        />
        <path
            transform="matrix(0 -1 1 0 95.8115 72.54)"
            d="M10.4433 1.4199Q12.1531 2.11323 13.4697 3.42983C13.7626 3.72272 14.2374 3.72272 14.5303 3.42983C14.8232 3.13694 14.8232 2.66206 14.5303 2.36917Q12.9986 0.837478 11.007 0.0298474Q9.08394 -0.75 7 -0.75Q4.91606 -0.75 2.99297 0.0298473Q1.00136 0.837478 -0.530333 2.36917C-0.823221 2.66206 -0.823221 3.13694 -0.53033 3.42983C-0.237439 3.72272 0.237439 3.72272 0.53033 3.42983Q1.84693 2.11322 3.55666 1.4199Q5.20863 0.75 7 0.75Q8.79137 0.75 10.4433 1.4199Z"
            fillRule="evenodd"
            fill="rgb(130, 138, 153)"
        />
        <path
            transform="matrix(0 -1 1 0 103.711 69.54)"
            d="M5.84683 1.10924Q6.76341 1.48093 7.46967 2.18719C7.76256 2.48008 8.23744 2.48008 8.53033 2.18719C8.82322 1.8943 8.82322 1.41942 8.53033 1.12653Q7.60899 0.205189 6.41052 -0.280811Q5.25351 -0.75 4 -0.75Q2.74649 -0.75 1.58948 -0.280811Q0.391014 0.205188 -0.53033 1.12653C-0.823221 1.41942 -0.823221 1.8943 -0.53033 2.18719C-0.237439 2.48008 0.23744 2.48008 0.53033 2.18719Q1.23659 1.48093 2.15317 1.10924Q3.03905 0.75 4 0.75Q4.96095 0.75 5.84683 1.10924Z"
            fillRule="evenodd"
            fill="rgb(130, 138, 153)"
        />
        <path
            transform="matrix(-0 -1 -1 0 153.171 75.54)"
            d="M0.439231 2.60471Q-0.0544818 3.00099 -0.5159 3.43827C-0.816549 3.72319 -0.829299 4.1979 -0.544378 4.49855C-0.259456 4.7992 0.215251 4.81195 0.5159 4.52703Q0.932555 4.13217 1.37817 3.77449Q3.34573 2.19532 5.7426 1.42012Q8.0774 0.664995 10.5381 0.760431Q13.0071 0.856186 15.2795 1.7961Q17.6269 2.76702 19.4841 4.52703C19.7848 4.81195 20.2595 4.7992 20.5444 4.49855C20.8293 4.1979 20.8165 3.72319 20.5159 3.43827Q18.457 1.48711 15.8528 0.409982Q13.3328 -0.632306 10.5963 -0.738442Q7.86931 -0.844203 5.281 -0.00709063Q2.62183 0.852942 0.439231 2.60471Z"
            fillRule="evenodd"
            fill="rgb(130, 138, 153)"
        />
        <path
            transform="matrix(-0 -1 -1 0 144.188 72.54)"
            d="M10.4433 1.4199Q12.1531 2.11323 13.4697 3.42983C13.7626 3.72272 14.2374 3.72272 14.5303 3.42983C14.8232 3.13694 14.8232 2.66206 14.5303 2.36917Q12.9986 0.837478 11.007 0.0298474Q9.08394 -0.75 7 -0.75Q4.91606 -0.75 2.99297 0.0298473Q1.00136 0.837478 -0.530333 2.36917C-0.823221 2.66206 -0.823221 3.13694 -0.53033 3.42983C-0.237439 3.72272 0.237439 3.72272 0.53033 3.42983Q1.84693 2.11322 3.55666 1.4199Q5.20863 0.75 7 0.75Q8.79137 0.75 10.4433 1.4199Z"
            fillRule="evenodd"
            fill="rgb(130, 138, 153)"
        />
        <path
            transform="matrix(-0 -1 -1 0 136.289 69.54)"
            d="M5.84683 1.10924Q6.76341 1.48093 7.46967 2.18719C7.76256 2.48008 8.23744 2.48008 8.53033 2.18719C8.82322 1.8943 8.82322 1.41942 8.53033 1.12653Q7.60899 0.205189 6.41052 -0.280811Q5.25351 -0.75 4 -0.75Q2.74649 -0.75 1.58948 -0.280811Q0.391014 0.205188 -0.53033 1.12653C-0.823221 1.41942 -0.823221 1.8943 -0.53033 2.18719C-0.237439 2.48008 0.23744 2.48008 0.53033 2.18719Q1.23659 1.48093 2.15317 1.10924Q3.03905 0.75 4 0.75Q4.96095 0.75 5.84683 1.10924Z"
            fillRule="evenodd"
            fill="rgb(130, 138, 153)"
        />
        <path
            transform="matrix(1 0 0 1 109.933 87.5748)"
            d="M0 -0.75L19.623 -0.75L19.623 0.75L0 0.75L0 -0.75Z"
            fillRule="nonzero"
            fill="rgb(130, 138, 153)"
        />
        <path
            transform="matrix(1 0 0 1 93.3918 66.1985)"
            d="M-0.687271 60.333L25.803 -0.300264L27.1758 -0.304075L54.0679 60.3292L52.6967 60.9374L25.8046 0.304075L26.4902 0L27.1775 0.300264L0.687271 60.9336L-0.687271 60.333Z"
            fillRule="nonzero"
            fill="rgb(130, 138, 153)"
        />
        <path
            transform="matrix(1 0 0 1 100.113 100.042)"
            d="M-0.363456 10.3826L19.9257 -0.857695L40.1576 10.3831L39.4291 11.6943L19.5609 0.655606L19.9252 0L20.2886 0.656049L0.363456 11.6947L-0.363456 10.3826Z"
            fillRule="nonzero"
            fill="rgb(130, 138, 153)"
        />
        <path
            transform="matrix(1 0 0 1 114.399 59.8103)"
            d="M5.60071 0C8.69389 0 11.2014 2.50752 11.2014 5.60071C11.2014 8.69389 8.69389 11.2014 5.60071 11.2014C2.50752 11.2014 0 8.69389 0 5.60071C0 2.50752 2.50752 0 5.60071 0Z"
            fillRule="nonzero"
            fill="rgb(130, 138, 153)"
        />
        <path
            transform="matrix(1 0 0 1 114.399 59.8103)"
            d="M5.60071 -3Q7.34852 -3 8.94941 -2.32288Q10.4934 -1.66983 11.6823 -0.480911Q12.8712 0.708001 13.5243 2.252Q14.2014 3.85289 14.2014 5.60071Q14.2014 7.34852 13.5243 8.94941Q12.8712 10.4934 11.6823 11.6823Q10.4934 12.8712 8.94941 13.5243Q7.34852 14.2014 5.60071 14.2014Q3.85289 14.2014 2.252 13.5243Q0.708001 12.8712 -0.480912 11.6823Q-1.66982 10.4934 -2.32288 8.94941Q-3 7.34852 -3 5.60071Q-3 3.85289 -2.32288 2.252Q-1.66983 0.708002 -0.480911 -0.480912Q0.708002 -1.66982 2.252 -2.32288Q3.85289 -3 5.60071 -3ZM11.2014 5.60071C11.2014 2.50752 8.69389 0 5.60071 0C2.50752 0 0 2.50752 0 5.60071C0 8.69389 2.50752 11.2014 5.60071 11.2014C8.69389 11.2014 11.2014 8.69389 11.2014 5.60071Z"
            fillRule="evenodd"
            fill="rgb(255, 255, 255)"
        />
        <path
            transform="matrix(1 0 0 1 26.562 76.3325)"
            d="M9 4.5C9 2.01472 6.98528 0 4.5 0C2.01472 0 0 2.01472 0 4.5C0 6.98528 2.01472 9 4.5 9C6.98528 9 9 6.98528 9 4.5ZM3.33273 1.73514Q3.88866 1.5 4.5 1.5Q5.11134 1.5 5.66727 1.73514Q6.20538 1.96274 6.62132 2.37868Q7.03726 2.79462 7.26486 3.33273Q7.5 3.88866 7.5 4.5Q7.5 5.11134 7.26486 5.66727Q7.03726 6.20538 6.62132 6.62132Q6.20538 7.03726 5.66727 7.26486Q5.11134 7.5 4.5 7.5Q3.88866 7.5 3.33273 7.26486Q2.79462 7.03726 2.37868 6.62132Q1.96274 6.20538 1.73514 5.66727Q1.5 5.11134 1.5 4.5Q1.5 3.88866 1.73514 3.33273Q1.96274 2.79462 2.37868 2.37868Q2.79462 1.96274 3.33273 1.73514Z"
            fillRule="evenodd"
            fill="rgb(218, 220, 224)"
        />
        <path
            transform="matrix(1 0 0 1 201.747 41.5)"
            d="M9 4.5C9 2.01472 6.98528 0 4.5 0C2.01472 0 0 2.01472 0 4.5C0 6.98528 2.01472 9 4.5 9C6.98528 9 9 6.98528 9 4.5ZM3.33273 1.73514Q3.88866 1.5 4.5 1.5Q5.11134 1.5 5.66727 1.73514Q6.20538 1.96274 6.62132 2.37868Q7.03726 2.79462 7.26486 3.33273Q7.5 3.88866 7.5 4.5Q7.5 5.11134 7.26486 5.66727Q7.03726 6.20538 6.62132 6.62132Q6.20538 7.03726 5.66727 7.26486Q5.11134 7.5 4.5 7.5Q3.88866 7.5 3.33273 7.26486Q2.79462 7.03726 2.37868 6.62132Q1.96274 6.20538 1.73514 5.66727Q1.5 5.11134 1.5 4.5Q1.5 3.88866 1.73514 3.33273Q1.96274 2.79462 2.37868 2.37868Q2.79462 1.96274 3.33273 1.73514Z"
            fillRule="evenodd"
            fill="rgb(218, 220, 224)"
        />
        <path
            transform="matrix(1 0 0 1 14.3624 12.2947)"
            d="M0.53033 -0.53033L9.53033 8.46967L8.46967 9.53033L-0.53033 0.53033L0.53033 -0.53033Z"
            fillRule="nonzero"
            fill="rgb(218, 220, 224)"
        />
        <path
            transform="matrix(0.866025 -0.5 0.5 0.866025 9.76782 87.8081)"
            d="M0.53033 -0.53033L9.53033 8.46967L8.46967 9.53033L-0.53033 0.53033L0.53033 -0.53033Z"
            fillRule="nonzero"
            fill="rgb(218, 220, 224)"
        />
        <path
            transform="matrix(-0.934608 -0.355678 -0.355678 0.934608 228.062 84.4456)"
            d="M0.53033 -0.53033L9.53033 8.46967L8.46967 9.53033L-0.53033 0.53033L0.53033 -0.53033Z"
            fillRule="nonzero"
            fill="rgb(218, 220, 224)"
        />
        <path
            transform="matrix(-0.965926 0.258819 0.258819 0.965926 208.417 6.00513)"
            d="M0.53033 -0.53033L9.53033 8.46967L8.46967 9.53033L-0.53033 0.53033L0.53033 -0.53033Z"
            fillRule="nonzero"
            fill="rgb(218, 220, 224)"
        />
        <path
            transform="matrix(1 0 0 1 22.6124 10.0447)"
            d="M0.53033 -0.53033L5.03033 3.96967L3.96967 5.03033L-0.53033 0.53033L0.53033 -0.53033Z"
            fillRule="nonzero"
            fill="rgb(218, 220, 224)"
        />
        <path
            transform="matrix(-0.934608 -0.355678 -0.355678 0.934608 221.151 79.4084)"
            d="M0.53033 -0.53033L5.03033 3.96967L3.96967 5.03033L-0.53033 0.53033L0.53033 -0.53033Z"
            fillRule="nonzero"
            fill="rgb(218, 220, 224)"
        />
        <path
            transform="matrix(1 0 0 1 95.9879 4.97192)"
            d="M24.0121 0C37.2737 0 48.0243 10.7506 48.0243 24.0121C48.0243 37.2737 37.2737 48.0243 24.0121 48.0243C10.7506 48.0243 0 37.2737 0 24.0121C0 10.7506 10.7506 0 24.0121 0Z"
            fillRule="nonzero"
            fill="rgb(130, 138, 153)"
        />
        <path
            transform="matrix(1 0 0 1 95.9879 4.97192)"
            d="M24.0121 -1.5Q29.2016 -1.5 33.9431 0.505487Q38.5217 2.44207 42.052 5.97233Q45.5822 9.50259 47.5188 14.0812Q49.5243 18.8227 49.5243 24.0121Q49.5243 29.2016 47.5188 33.9431Q45.5822 38.5217 42.052 42.052Q38.5217 45.5822 33.9431 47.5188Q29.2016 49.5243 24.0121 49.5243Q18.8227 49.5243 14.0812 47.5188Q9.50259 45.5822 5.97233 42.052Q2.44207 38.5217 0.505487 33.9431Q-1.5 29.2016 -1.5 24.0121Q-1.5 18.8227 0.505487 14.0812Q2.44208 9.50259 5.97233 5.97233Q9.50259 2.44208 14.0812 0.505487Q18.8227 -1.5 24.0121 -1.5ZM24.0121 1.5Q19.4311 1.5 15.2499 3.2685Q11.2102 4.97715 8.09366 8.09366Q4.97715 11.2102 3.2685 15.2499Q1.5 19.4311 1.5 24.0121Q1.5 28.5932 3.2685 32.7744Q4.97715 36.8141 8.09366 39.9306Q11.2102 43.0471 15.2499 44.7558Q19.4311 46.5243 24.0121 46.5243Q28.5932 46.5243 32.7744 44.7558Q36.8141 43.0471 39.9306 39.9306Q43.0471 36.8141 44.7558 32.7744Q46.5243 28.5932 46.5243 24.0121Q46.5243 19.4311 44.7558 15.2499Q43.0471 11.2102 39.9306 8.09366Q36.8141 4.97715 32.7744 3.2685Q28.5932 1.5 24.0121 1.5Z"
            fillRule="nonzero"
            fill="rgb(255, 255, 255)"
        />
        <path
            transform="matrix(1 0 0 1 112.118 15.1862)"
            d="M5.91149 17.7345L5.91149 13.7935C5.91149 13.2493 6.10387 12.7849 6.48863 12.4001C6.87339 12.0154 7.33784 11.823 7.88198 11.823C8.97025 11.823 9.89915 11.4382 10.6687 10.6687C11.4382 9.89915 11.823 8.97025 11.823 7.88198C11.823 6.79371 11.4382 5.86481 10.6687 5.09528C9.89915 4.32575 8.97025 3.94099 7.88198 3.94099C6.79371 3.94099 5.86481 4.32575 5.09528 5.09528C4.32575 5.86481 3.94099 6.79371 3.94099 7.88198C3.94099 8.97025 3.05876 9.85248 1.9705 9.85248C0.88223 9.85248 0 8.97025 0 7.88198C0 6.8138 0.206739 5.79092 0.620216 4.81336C1.01917 3.87013 1.58196 3.03521 2.30858 2.30858C3.03521 1.58196 3.87013 1.01917 4.81335 0.620216C5.79092 0.206739 6.8138 0 7.88198 0C8.95016 0 9.97304 0.206739 10.9506 0.620216C11.8938 1.01917 12.7288 1.58196 13.4554 2.30858C14.182 3.03521 14.7448 3.87013 15.1437 4.81336C15.5572 5.79092 15.764 6.8138 15.764 7.88198C15.764 8.95016 15.5572 9.97304 15.1437 10.9506C14.7448 11.8938 14.182 12.7288 13.4554 13.4554C12.7288 14.182 11.8938 14.7448 10.9506 15.1437C10.5907 15.296 10.2247 15.4202 9.85248 15.5164L9.85248 17.7345C9.85248 18.8227 8.97025 19.705 7.88198 19.705C6.79372 19.705 5.91149 18.8227 5.91149 17.7345Z"
            fillRule="nonzero"
            fill="rgb(255, 255, 255)"
        />
        <path
            transform="matrix(1 0 0 1 117.537 36.8615)"
            d="M2.46312 4.92624C3.82345 4.92624 4.92624 3.82345 4.92624 2.46312C4.92624 1.10278 3.82345 0 2.46312 0C1.10279 0 0 1.10278 0 2.46312C0 3.82345 1.10279 4.92624 2.46312 4.92624Z"
            fillRule="nonzero"
            fill="rgb(255, 255, 255)"
        />
        <path
            transform="matrix(1 0 0 1 95.9878 5)"
            d="M24.0121 0C37.2737 0 48.0243 10.7506 48.0243 24.0121C48.0243 37.2737 37.2737 48.0243 24.0121 48.0243C10.7506 48.0243 0 37.2737 0 24.0121C0 10.7506 10.7506 0 24.0121 0Z"
            fillRule="nonzero"
            fill="rgb(235, 69, 28)"
        />
        <path
            transform="matrix(1 0 0 1 95.9878 5)"
            d="M24.0121 -1.5Q29.2016 -1.5 33.9431 0.505487Q38.5217 2.44207 42.052 5.97233Q45.5822 9.50259 47.5188 14.0812Q49.5243 18.8227 49.5243 24.0121Q49.5243 29.2016 47.5188 33.9431Q45.5822 38.5217 42.052 42.052Q38.5217 45.5822 33.9431 47.5188Q29.2016 49.5243 24.0121 49.5243Q18.8227 49.5243 14.0812 47.5188Q9.50259 45.5822 5.97233 42.052Q2.44207 38.5217 0.505487 33.9431Q-1.5 29.2016 -1.5 24.0121Q-1.5 18.8227 0.505487 14.0812Q2.44208 9.50259 5.97233 5.97233Q9.50259 2.44208 14.0812 0.505487Q18.8227 -1.5 24.0121 -1.5ZM24.0121 1.5Q19.4311 1.5 15.2499 3.2685Q11.2102 4.97715 8.09366 8.09366Q4.97715 11.2102 3.2685 15.2499Q1.5 19.4311 1.5 24.0121Q1.5 28.5932 3.2685 32.7744Q4.97715 36.8141 8.09366 39.9306Q11.2102 43.0471 15.2499 44.7558Q19.4311 46.5243 24.0121 46.5243Q28.5932 46.5243 32.7744 44.7558Q36.8141 43.0471 39.9306 39.9306Q43.0471 36.8141 44.7558 32.7744Q46.5243 28.5932 46.5243 24.0121Q46.5243 19.4311 44.7558 15.2499Q43.0471 11.2102 39.9306 8.09366Q36.8141 4.97715 32.7744 3.2685Q28.5932 1.5 24.0121 1.5Z"
            fillRule="nonzero"
            fill="rgb(255, 255, 255)"
        />
        <path
            transform="matrix(1 0 0 1 117.998 15)"
            d="M2 0C0.89543 0 0 0.89543 0 2L0 17C0 18.1046 0.892431 19 1.997 19L2 19C3.10457 19 4 18.1046 4 17L4 2C4 0.89543 3.10757 0 2.003 0L2 0Z"
            fillRule="nonzero"
            fill="rgb(255, 255, 255)"
        />
        <path
            transform="matrix(1 0 0 1 117.498 36)"
            d="M2.5 0C3.88071 0 5 1.11929 5 2.5C5 3.88071 3.88071 5 2.5 5C1.11929 5 0 3.88071 0 2.5C0 1.11929 1.11929 0 2.5 0Z"
            fillRule="nonzero"
            fill="rgb(255, 255, 255)"
        />
    </svg>
)
