import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Fill: '填充',
    Style: '样式',
    StyleAndVariable: '样式与变量',
    Click: '点击',
    ToReplace: '替换多个属性值',
} as const

export const enTranslation = {
    Fill: 'Fill',
    Style: 'Style',
    StyleAndVariable: 'Styles and variables',
    Click: 'Click',
    ToReplace: 'to replace mixed content',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
