import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Selected: '已选中',
    SelectALayer: '请选中一个图层后，开始旋转复制',
    Start: '开始复制',
    Layer: '应用图层',
    Count: '重复数量',
    Radius: '半径长度',
    DistanceFromGeometric: '初始图层几何中心到旋转中心的距离',
    Sweep: '旋转范围',
    AngleRangeFor: '旋转复制的角度范围',
    Cancel: '取消',
    Done: '完成',
    RotateCopies: '旋转复制',
} as const

export const enTranslation = {
    Selected: 'Selected',
    SelectALayer: 'Select a layer first.',
    Start: 'Start',
    Layer: 'Layer',
    Count: 'Count',
    Radius: 'Radius',
    DistanceFromGeometric: 'Distance from geometric center to origin',
    Sweep: 'Sweep',
    AngleRangeFor: 'Angle range for rotating copies',
    Cancel: 'Cancel',
    Done: 'Done',
    RotateCopies: 'Rotate copies',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
