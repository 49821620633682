import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useMemo, useRef } from 'react'
import { isEnglishLanguage } from '../../../../../../../util/src'
import { ToKeyCode } from '../../../../../document/util/keycode'
import { KeyboardReceiver } from '../../../../../main/keyboard-receiver/component'
import { SingleGrid } from '../../../atom/grid/single-grid'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { ScrubbableInputPercent } from '../../../atom/inputs/scrubbable-input-percent'
import { Value } from '../../../atom/inputs/utils/type'
import { useRenderColorSpace } from '../../../color-profile'
import { ColorVarItem } from '../../../color-var-item'
import { ColorSpace, RGBA } from '../../../design/blend/color-picker/utils/color-translate'
import { ColorInteraction } from '../../../design/color-interaction/color-interaction'
import { ColorInteractionFrom } from '../../../design/color-interaction/type'
import { PaintIconColor } from '../../../paint-icon-color/paint-icon-color'
import classes from './index.module.less'
import { translation } from './index.translation'

export function VariableValue({
    variable,
    collection,
    selectedModeId,
    onChangeColor,
    onChangeColorVar,
    onChangeSelectedModeId,
}: {
    variable: Wukong.DocumentProto.ILocalVariable
    collection: Wukong.DocumentProto.ILocalVariableSet | null
    selectedModeId: string
    onChangeColor: (variableId: string, modeId: string) => (val: RGBA, options?: InputOptionsForUndoSquash) => void
    onChangeColorVar: (variableId: string, modeId: string) => (value: Wukong.DocumentProto.IVariableAliasData) => void
    onChangeSelectedModeId: (modeId: string) => void
}) {
    const colorSpace = useRenderColorSpace()

    return (
        <div className={classNames(classes.container, isEnglishLanguage() && classes.en)} data-testid="variable-value">
            <div className={classes.title}>{translation('Value')}</div>
            {collection?.modes.map((mode, index) => {
                if (!mode.modeId) {
                    return null
                }
                return (
                    <VariableValueItem
                        key={index}
                        selected={selectedModeId === mode.modeId}
                        mode={mode}
                        variable={variable}
                        colorSpace={colorSpace}
                        onChangeColor={onChangeColor(variable.id, mode.modeId)}
                        onChangeColorVar={onChangeColorVar(variable.id, mode.modeId)}
                        onChangeSelectedModeId={onChangeSelectedModeId}
                    />
                )
            })}
        </div>
    )
}

function VariableValueItem({
    selected,
    mode,
    variable,
    colorSpace,
    onChangeColor,
    onChangeColorVar,
    onChangeSelectedModeId,
}: {
    selected: boolean
    mode: Wukong.DocumentProto.IVariableSetMode
    variable: Wukong.DocumentProto.ILocalVariable
    colorSpace: ColorSpace
    onChangeColor: (val: RGBA, options?: InputOptionsForUndoSquash) => void
    onChangeColorVar: (value: Wukong.DocumentProto.IVariableAliasData) => void
    onChangeSelectedModeId: (modeId: string) => void
}) {
    const { modeId, modeName } = mode
    const value = modeId ? variable.dataValues?.[modeId] : undefined
    const isAliasData = value?.dataType === Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_ALIAS
    const colorVar = useMemo(() => {
        if (value && isAliasData) {
            return {
                id: value.alias,
                name: value.aliasName,
                isSoftDeleted: value.isSoftDeleted,
            } as Wukong.DocumentProto.ISingleVariable
        }
    }, [value, isAliasData])
    const paint = useMemo(() => {
        if (value) {
            return {
                type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
                opacity: value.resolvedValue.colorValue.a / 255,
                color: {
                    r: value.resolvedValue.colorValue.r,
                    g: value.resolvedValue.colorValue.g,
                    b: value.resolvedValue.colorValue.b,
                },
                visible: true,
                colorVar: isAliasData
                    ? ({
                          value: {
                              alias: value.alias,
                          },
                          dataType: value.dataType,
                          resolvedDataType: value.resolvedDataType,
                      } as Wukong.DocumentProto.IVariableAliasData)
                    : undefined,
            } as Wukong.DocumentProto.IPaint
        }
    }, [value, isAliasData])
    const paintRef = useRef<HTMLDivElement>(null)

    const onChangeOpacity = (val: Value, options?: InputOptionsForUndoSquash) => {
        if (typeof val === 'number' && paint?.color) {
            const rgba = { ...paint.color, a: (val / 100) * 255 } as RGBA
            onChangeColor(rgba, options)
        }
    }

    const onClickDetachColorVar = () => {
        if (value) {
            onChangeColor(value.resolvedValue.colorValue)
            // 分离变量时，关闭颜色弹窗
            onChangeSelectedModeId('')
        }
    }

    if (!value || !paint) {
        return null
    }

    return (
        <div
            data-testid="variable-value-item"
            className={classNames(classes.line, selected && classes.selected)}
            onPointerDown={(e) => e.stopPropagation()}
        >
            <div className={classes.label}>{modeName}</div>
            <div ref={paintRef}>
                {colorVar ? (
                    <ColorVarItem
                        paint={paint}
                        colorSpace={colorSpace}
                        variable={colorVar}
                        selected={selected}
                        width={144}
                        onClickDetach={onClickDetachColorVar}
                        onClickItem={() => onChangeSelectedModeId(modeId ?? '')}
                    />
                ) : (
                    <SingleGrid className={classes.backgroundGrid}>
                        <SingleGrid.Item start={1} span={23}>
                            <PaintIconColor
                                paint={paint}
                                colorSpace={colorSpace}
                                onChangeColor={(rgb) => onChangeColor({ ...rgb, a: value.resolvedValue.colorValue.a })}
                                onClickIcon={() => onChangeSelectedModeId(modeId ?? '')}
                                isWidth={92}
                                dataTestIds={{
                                    hexInput: 'variable-value-item-hex-input',
                                }}
                            />
                        </SingleGrid.Item>
                        <SingleGrid.Item start={24} span={13}>
                            <ScrubbableInputPercent
                                value={paint?.opacity ?? undefined}
                                onChange={onChangeOpacity}
                                min={0}
                                max={100}
                                testId="variable-value-item-opacity-input"
                            />
                        </SingleGrid.Item>
                    </SingleGrid>
                )}
            </div>
            {selected && (
                <KeyboardReceiver keyCode={ToKeyCode.Esc} onKeydown={() => (onChangeSelectedModeId(''), false)}>
                    <ColorInteraction
                        position={{
                            left: (paintRef.current?.getBoundingClientRect().left ?? 0) + 6,
                            top: paintRef.current?.getBoundingClientRect().bottom ?? 0,
                        }}
                        positionRightBase={false}
                        from={ColorInteractionFrom.LOCAL_VARIABLE_EDIT}
                        paint={paint}
                        onCancel={() => onChangeSelectedModeId('')}
                        onChangePaintType={() => {}}
                        onChangeBlendMode={() => {}}
                        onChangeColor={(rgb, options) =>
                            onChangeColor({ ...rgb, a: value.resolvedValue.colorValue.a }, options)
                        }
                        onChangeOpacity={(opacity, options) => onChangeOpacity(opacity * 100, options)}
                        onChangeColorVar={onChangeColorVar}
                        onChangeImagePaint={() => {}}
                        onChangeColorStops={() => {}}
                        onChangeTransform={() => {}}
                        enterClose={() => {}}
                        onChangeStyle={() => {}}
                        onChangePaints={() => {}}
                        styleId={undefined}
                        onClickCreateStyleButton={undefined}
                    />
                </KeyboardReceiver>
            )}
        </div>
    )
}
