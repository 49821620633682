import { Position } from '../../../../../../ui-lib/src'
import { useEditingEffectStyle } from '../styles/hooks/use-editing-style'
import { EffectStyleEditor } from '../styles/style-panel/effect-style-editor/effect-style-editor'

export interface StyleViewerProps {
    open?: boolean
    position: Position
    onClose?: () => void
}

export const EffectStyleViewer = (props: StyleViewerProps) => {
    const { open, position, onClose } = props
    const style = useEditingEffectStyle()

    if (!style) {
        return null
    }
    return (
        <EffectStyleEditor
            visible={open}
            position={position}
            onCancel={onClose}
            styleId={style.id}
            isRemoteStyle={true}
            hideCommonFooter={true}
        />
    )
}
