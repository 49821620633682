import { Wukong } from '@wukong/bridge-proto'
import { useCallback, useMemo } from 'react'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { parseString } from '../../../atom/inputs/utils/parse-string'
import { Value } from '../../../atom/inputs/utils/type'
import {
    InteractionTypeWithMixed,
    MIXED_TYPE,
    PrototypeInteractionWithNodeId,
} from '../../prototype-interaction/constants'
import { canShowDelayInput, getDelayData } from '../../prototype-interaction/utils'

import InteractionType = Wukong.DocumentProto.InteractionType

const formatInputValue = (value: string) => {
    value = value.trim()
    const result = value.replace(/m?s/gi, '')
    let scale = 1
    if (value.match(/\d+(.\d+)?\s*s/)) {
        scale = 1000
    }

    return {
        value: result,
        scale,
    }
}
const nearZero = 0.00001

export const useDelayTime = ({
    type,
    data,
    onChange,
}: {
    type: InteractionTypeWithMixed
    data: PrototypeInteractionWithNodeId[]
    onChange: (data: PrototypeInteractionWithNodeId[], options?: InputOptionsForUndoSquash) => void
}) => {
    const isShowDelay = useMemo(() => {
        return canShowDelayInput(type)
    }, [type])
    const delayTime = useMemo(() => {
        if (!isShowDelay) {
            return 0
        }
        const delayData = getDelayData(data)
        return type === InteractionType.INTERACTION_TYPE_AFTER_TIMEOUT
            ? delayData.transitionTimeout
            : delayData.interactionDuration
    }, [data, isShowDelay, type])
    const value = useMemo(() => {
        if (!delayTime || delayTime === MIXED_TYPE) {
            return 0
        }
        return delayTime * 1000
    }, [delayTime])
    const isMixed = useMemo(() => {
        return delayTime === MIXED_TYPE
    }, [delayTime])

    const formatter = useMemo(() => {
        const min = type === InteractionType.INTERACTION_TYPE_AFTER_TIMEOUT ? 1 : nearZero
        return {
            parse(inputValue: string, oldValue?: Value) {
                const val = inputValue.split(',')[0] ?? ''
                const result = formatInputValue(val)
                const oldVal = Array.isArray(oldValue) ? oldValue[0] : oldValue
                const newVal = parseString(result.value, oldVal) * result.scale

                return newVal <= min ? min : newVal
            },
            format: (v: Value) => (typeof v === 'number' && v !== 0 ? `${Math.round(v)}ms` : ''),
        }
    }, [type])

    const onChangeDelayTime = useCallback(
        (v: Value) => {
            if (typeof v !== 'number') {
                return
            }
            const newVal = v === 0 ? nearZero : v / 1000
            const newInteractions = data.map((interaction) => {
                const newEvent = { ...interaction.event }
                if (type === InteractionType.INTERACTION_TYPE_AFTER_TIMEOUT) {
                    newEvent.transitionTimeout = newVal
                } else {
                    newEvent.interactionDuration = newVal
                }
                return { ...interaction, event: newEvent }
            })
            onChange(newInteractions)
        },
        [data, type, onChange]
    )

    return {
        isShowDelay,
        value,
        isMixed,
        formatter,
        onChangeDelayTime,
    }
}
