import { translation } from './coactor-synergy-role-toast-service.translation'
/* eslint-disable no-restricted-imports */
import { Wukong, ChangeExceedEditorToastStatus } from '@wukong/bridge-proto'
import { wkDialogConfirm, WKToast } from '../../../../../../ui-lib/src'
import { Bridge } from '../../../../kernel/bridge/bridge'
import { ServiceClass } from '../../../../kernel/util/service-class'

export class CoactorSynergyRoleToastService extends ServiceClass {
    private toastId?: string
    private modalDestroyFn?: () => void

    constructor(protected override readonly bridge: Bridge) {
        super(bridge)
        this.bindJsCall(ChangeExceedEditorToastStatus, this.onChangeUserSynergyRoleToastStatus)
    }

    public override destroy(): void {
        super.destroy()
        this.closeToastAndModal()
    }

    private onChangeUserSynergyRoleToastStatus = ({ value }: Wukong.DocumentProto.IBridgeProtoBoolean) => {
        if (value) {
            this.toastId ??= WKToast.show(translation('GJsCWH'), {
                duration: -1,
                secondButton: {
                    type: 'button',
                    text: translation('LearnMore'),
                    onClick: () => {
                        this.closeToastAndModal()
                        this.modalDestroyFn = wkDialogConfirm.info({
                            title: translation('UFQTsQ'),
                            content: `${translation('OCDDWA')}`,
                            okText: translation('YJRYty'),
                            cancelButtonProps: {
                                hidden: true,
                            },
                        }).destroy
                    },
                },
                firstButton: { type: 'x' },
                onClose: () => {
                    this.closeToastAndModal()
                },
            })
        } else {
            this.closeToastAndModal()
        }
    }

    private closeToastAndModal = () => {
        this.toastId && WKToast.close(this.toastId)
        this.toastId = undefined

        this.modalDestroyFn?.()
        this.modalDestroyFn = undefined
    }
}
