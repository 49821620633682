import { useState } from 'react'
import {
    MonoIconCommon16,
    MonoIconCommonchat16,
    MonoIconCommonDelete16,
    MonoIconCommoneditLayer16,
    MonoIconCommonQuestion16,
    MonoIconCommonstarFall16,
    Scrollbar,
    WKIconButton,
} from '../../../../../../ui-lib/src'
import { ConversationMetaVO, ConversationType } from '../../../../kernel/interface/chatbot'
import { useAppContext } from '../../../../main/app-context'

export const ChatbotHistory = ({ onCoversationChange }: { onCoversationChange: () => void }) => {
    const chatbotService = useAppContext().chatbotService
    const allConversationsMeta = chatbotService.dataStore.use.allConversationsMeta()
    const currentConversationId = chatbotService.stateStore.use.currentConversationId()

    const orderedConversationsMeta = Object.values(allConversationsMeta).sort((a, b) => b.lastTime - a.lastTime)

    return (
        <Scrollbar autoHeight autoHeightMax={430}>
            <div className="box-border w-320px px-2 py-2 flex flex-col">
                {orderedConversationsMeta.length !== 0 ? (
                    orderedConversationsMeta.map((conversationMeta, index) => (
                        <ConversationItem
                            key={index}
                            conversationMeta={conversationMeta}
                            isActive={currentConversationId === conversationMeta.conversationId}
                            onCoversationChange={onCoversationChange}
                        />
                    ))
                ) : (
                    <div className="wk-text-12 font-400 color-$wk-v2-label-color-gray-8 h-128px flex items-center justify-center">
                        暂无历史记录
                    </div>
                )}
            </div>
        </Scrollbar>
    )
}

const ConversationItem = ({
    conversationMeta,
    isActive,
    onCoversationChange,
}: {
    conversationMeta: ConversationMetaVO
    isActive: boolean
    onCoversationChange: () => void
}) => {
    const chatbotService = useAppContext().chatbotService
    const [isHover, setIsHover] = useState(false)

    return (
        <div
            className={`px-3 py-2 rounded-3px flex flex-row justify-start items-center gap-2 ${
                isActive ? 'bg-$wk-brand-1' : 'hover:bg-$wk-v2-fill-color-gray-1'
            }`}
            onClick={async () => {
                await chatbotService.changeCurrentConversation(conversationMeta.conversationId)
                onCoversationChange()
            }}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <ConversationIcon conversationType={conversationMeta.conversationType} />
            <div className="flex-1 text-13px font-400 lh-22px color-$wk-v2-label-color-gray-13 truncate">
                {conversationMeta.conversationTitle}
            </div>
            {isHover && (
                <WKIconButton
                    className="my--1px"
                    size="small"
                    icon={<MonoIconCommonDelete16 className="color-$wk-v2-label-color-gray-13" />}
                    onClick={(e) => {
                        e.stopPropagation()
                        chatbotService.deleteConversation(conversationMeta.conversationId)
                    }}
                />
            )}
        </div>
    )
}

const ConversationIcon = ({ conversationType }: { conversationType: ConversationType }) => {
    const icon = (() => {
        switch (conversationType) {
            case ConversationType.ASK:
                return <MonoIconCommonchat16 />
            case ConversationType.EXECUTE:
                return <MonoIconCommoneditLayer16 />
            case ConversationType.SEARCH:
                return <MonoIconCommon16 />
            case ConversationType.IDEA_DISCOVERY:
                return <MonoIconCommonstarFall16 />
            case ConversationType.USE_HELP:
                return <MonoIconCommonQuestion16 />
        }
    })()
    return <div className="color-$wk-brand-7 py-3px h-4 w-4">{icon}</div>
}
