import { translation } from './tool-title.translation'
/* eslint-disable no-restricted-imports */
import { isNil } from 'lodash-es'
import { useMemo, useState } from 'react'
import { Tooltip, WKButton } from '../../../../../../ui-lib/src'
import { useUserInfoContext } from '../../../../auth'
import { useTicket } from '../../../../external-store/atoms/ticket'
import { useDocReadonly } from '../../../../external-store/context/doc-info'
import { PayPlanType, RoleStatus, SeatType } from '../../../../kernel/interface/type'
import { compareRole } from '../../../../kernel/interface/user-roles'
import { useDevModeAvailabilityVerificationService, usePlanAndUserStateService } from '../../../../main/app-context'
import { PayApplySeatOfEditDocs } from '../../../../share/payment/pay-seat-apply-edit-doc'
import { checkHasSeatByUserSeatStatus, getUserRole, getUserSeatStatus, UserSeatStatus } from '../../../../utils/payment'
import { isActiveOrgPlan, isActiveProPlan } from '../../../../utils/plan-status-checker'
import { useViewState } from '../../../../view-state-bridge'
import { useDocInfoContext } from '../../../context/top-area-context'
import { DocNameInput, DocNameV2 } from './doc-name'
import { DocNameFreeTag } from './doc-name-free-tag'
import { FolderName } from './folder-name'
import { ToolTitleMenu, ToolTitleMenuType } from './tool-title-menu'
import style from './tool-title.module.less'

export function TopTitle({ isDevMode, onRenameDoc }: { isDevMode: boolean; onRenameDoc: () => void }) {
    const { docData } = useDocInfoContext()
    const ticket = useTicket()

    const docReadonly = useViewState('docReadonly')

    const canEditName = useMemo(() => {
        if (docData) {
            return compareRole(docData.role, RoleStatus.Viewer) > 0 && !docReadonly
        }
        return false
    }, [docData, docReadonly])

    const onClickEdit = () => {
        if (canEditName) {
            onRenameDoc()
        }
    }

    const clickMenuCallback = (type: ToolTitleMenuType) => {
        if (type === ToolTitleMenuType.Rename) {
            onClickEdit()
        }
    }

    // history mode 时 无论当前用户是不是只读，docReadonly都是只读。
    // 但【{translation('ViewOnly')}】的文字产品期望只在用户只读时展示
    const isReadonly = useDocReadonly()

    const canEditDocByUpgradeSeat = ticket?.canEditDocByUpgradeSeat

    const planAndUserStateService = usePlanAndUserStateService()

    const devModeAvailablieyVerificationService = useDevModeAvailabilityVerificationService()
    const isDevModeAccessible = devModeAvailablieyVerificationService.useZustandStore.use.isDevModeAvailable()

    const hideToolTitleMenu = isDevMode && !isDevModeAccessible

    const isFree = planAndUserStateService.useZustandStore.use.planAndUserState()?.planType === PayPlanType.starter

    return (
        <div className={style.container}>
            <FolderName />
            <DocNameV2 onClickName={onClickEdit} />
            {docData?.createdTime && !hideToolTitleMenu && (
                <ToolTitleMenu clickCallback={clickMenuCallback} isDevMode={isDevMode} />
            )}
            {!docData?.draft && isFree ? <DocNameFreeTag /> : null}
            {isDevMode ? (
                <span className={style['dev-mode-text']} data-testid="dev-mode-text">
                    {translation('DevMode')}
                </span>
            ) : canEditDocByUpgradeSeat ? (
                <EditFile />
            ) : isReadonly ? (
                <ViewOnlyTag />
            ) : null}
        </div>
    )
}

function EditFile() {
    const { userInfo } = useUserInfoContext()
    const { docData } = useDocInfoContext()

    const planAndUserStateService = usePlanAndUserStateService()
    const planAndUserState = planAndUserStateService.useZustandStore.use.planAndUserState()

    if (!docData || isNil(planAndUserState)) {
        return <></>
    }

    const userSeatStatus = getUserSeatStatus(planAndUserState, SeatType.designer)
    const inActiveResource = isActiveProPlan(planAndUserState) || isActiveOrgPlan(planAndUserState)
    const show = inActiveResource && !checkHasSeatByUserSeatStatus(userSeatStatus)

    const handleClick = async () => {
        if (userSeatStatus != UserSeatStatus.NeedWaitToBeApproved) {
            const userRole = await getUserRole(docData.orgId, docData.teamId ?? '')
            PayApplySeatOfEditDocs({
                userRole,
                userSeatStatus,
                docData,
                unresolvedSeatTypes: planAndUserState.unresolvedApplicationSeatTypes ?? [],
                userId: userInfo.userId,
                isEnterprise: planAndUserState.planType === PayPlanType.enterprise,
                isSomeWorkspaceAdmin: !!planAndUserState.adminWorkspaceIds?.length,
            })
        }
    }

    return show ? (
        <div className="ml-4">
            <Tooltip
                title={translation('ApplyingToolTip')}
                maxWidth={400}
                inactivation={userSeatStatus != UserSeatStatus.NeedWaitToBeApproved}
                addContainer={{}}
            >
                <WKButton
                    type="secondary"
                    size="small"
                    onClick={handleClick}
                    disabled={userSeatStatus == UserSeatStatus.NeedWaitToBeApproved}
                    dataTestId={'edit-file'}
                >
                    {userSeatStatus != UserSeatStatus.NeedWaitToBeApproved ? (
                        <span>{translation('ApplyEditFile')}</span>
                    ) : (
                        <span>{translation('Applying')}</span>
                    )}
                </WKButton>
            </Tooltip>
        </div>
    ) : (
        <></>
    )
}

function ViewOnlyTag() {
    const ticket = useTicket()
    const showTooltip = ticket?.draftAndOwnerShareEditLimited && !ticket.planFreeze
    const tooltipText = showTooltip ? translation('ViewOnlyTooltip') : ''
    const content = (
        <span className={style['readonly-text']} data-testid="readonly-text">
            {translation('ViewOnly')}
        </span>
    )

    return showTooltip ? <Tooltip title={tooltipText}>{content}</Tooltip> : content
}

export function ToolTitle(props: { isDevMode: boolean }) {
    const [isRenameDoc, setRenameDoc] = useState(false)

    return isRenameDoc ? (
        <DocNameInput onEditDone={() => setRenameDoc(false)} />
    ) : (
        <TopTitle isDevMode={props.isDevMode} onRenameDoc={() => setRenameDoc(true)} />
    )
}
