import { HttpPrefixKey, environment } from '../../../../environment'
/* eslint-disable @typescript-eslint/no-dynamic-delete */
import {
    CurrentPageSetSelectionCommandWasmCall,
    ExportFigJsonCommand,
    GetAISearchNodeDataCommand,
    OpenChatbotModal,
    RunPluginScriptCommand,
    Wukong,
    ZoomToFitNodesAtMaxScaleByIdsCommand,
} from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { Position, WKToast } from '../../../../../../ui-lib/src'
import { createImmerStore, createSelectors, createStore } from '../../../../../../util/src'
import { ClassWithEffect, EffectController } from '../../../../../../util/src/effect-controller'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import { CommitType } from '../../../../document/command/commit-type'
import { debugLog } from '../../../../kernel/debug'
import { Bridge } from '../../../../kernel/bridge/bridge'
import {
    AnswerReactionType,
    ConversationId,
    ConversationMetaVO,
    ConversationStatus,
    ConversationType,
    ConversationVO,
    DialogueAnswer,
    DialogueAnswerStatus,
    DialogueData,
    DialogueId,
    DialogueInput,
    JavaScriptOp,
    MessageItemContentProps,
    MessageItemProps,
    createJointKey,
} from '../../../../kernel/interface/chatbot'
import { featureSwitchManager } from '../../../../kernel/switch/core'
import { DeepRequired, ViewStateBridge } from '../../../../view-state-bridge'
import { getNodesPreviewBase64Image } from '../../design/export/util'
import { AgentAnswerType, getAgentAnswerType } from '../chatbot-component/type'
import {
    fetchCreateChatbotConversation,
    fetchDeleteConversation,
    fetchGetUserConversationHistory,
    fetchGetWholeConversation,
    fetchSendChatbotMessage,
    fetchUpdateDialogueReaction,
} from '../chatbot-request'
import { defaultChatbotDebugSetting } from '../ui-component/debug-component/debug-setting-default'

export enum PageType {
    Home = 'home', // 首页
    Conversation = 'conversation', // 对话
    InspireSearchPopup = 'InspireSearchPopup', // 灵感搜索临时弹窗
    NodesPopup = 'nodesPopup', // 资产搜索临时弹窗
}

export interface PageState {
    pageType: PageType
    title?: string
    size?: 'large' | 'small'
    sourceDialogue?: DialogueData // 临时弹窗的来源对话
}

export class ChatBotService extends ClassWithEffect {
    stateStore = createSelectors(
        createStore<{
            popupOpened: boolean // 弹框是否打开
            popupTopCenterPosition: Position | undefined
            pageStack: PageState[]
            pendingMessages: MessageItemContentProps[] | undefined
            pendingMessageThumbnailBase64: string | undefined
            currentConversationId: ConversationId | undefined
            draftConversationType: ConversationType | undefined
            allConversationStatuses: ConversationStatus // 对话状态，用作展示
            chatbotDebugSetting: typeof defaultChatbotDebugSetting
        }>(() => ({
            popupOpened: false,
            popupTopCenterPosition: undefined,
            pageStack: [],
            pendingMessages: undefined,
            pendingMessageThumbnailBase64: undefined,
            currentConversationId: undefined,
            draftConversationType: undefined,
            allConversationStatuses: {},
            chatbotDebugSetting: defaultChatbotDebugSetting,
        }))
    )

    dataStore = createSelectors(
        createImmerStore<{
            // 所有对话的完整数据
            allConversations: Record<ConversationId, ConversationVO>
            // 所有会话的简介元数据，服务端接口不会一次性返回所有完整数据，在切换时再请求完整数据（allConversations ⊆ allConversationsMeta）
            allConversationsMeta: Record<ConversationId, ConversationMetaVO>
        }>(() => ({
            allConversations: {},
            allConversationsMeta: {},
        }))
    )

    private continueSendWhenFailed = false

    constructor(
        controller: EffectController,
        private bridge: Bridge,
        private readonly commandInvoker: CommandInvoker,
        private readonly viewStateBridge: ViewStateBridge,
        private readonly docId: string
    ) {
        super(controller)
        this.init()
    }

    /*
     * 临时方案
     * AI 生成代码执行的上下文
     * 仅供 AI 所生成的 js 代码使用
     */
    private setupMotiffAI = () => {
        let conversationId: string | undefined = undefined
        let dialogueId: string | undefined = undefined

        let nodesToBeFocused: string[] | undefined = undefined
        let editingStatus: string | undefined = undefined

        const collectChangedNode = (nodeIds: string[]) => {
            if (!this.stateStore.getState().chatbotDebugSetting.focusV2) {
                const changedNodeIds = [
                    ...this.stateStore.getState().allConversationStatuses[createJointKey(conversationId, dialogueId)]
                        .answerStatus.changedNodeIds,
                    ...nodeIds,
                ]

                this.updateConversationStatus(conversationId, dialogueId, {
                    pending: false,
                    success: true,
                    error: null,
                    changedNodeIds,
                })
            }
        }

        const setEditingStatus = (status: string | undefined, nodeIds: string[] | undefined) => {
            editingStatus = status
            nodesToBeFocused = nodeIds
        }

        const motiffAI: {
            fetch: (url: string, data: string) => Promise<Response>
            setConversationId: (conversationId: string) => void
            setDialogueId: (dialogueId: string) => void
            collectChangedNode: (nodeIds: string[]) => void
            exportJson: (nodeId?: string) => string
            setEditingStatus: (status: string, nodeIds: string[]) => void
            resetEditingStatus: () => void
            getEditingStatus: () => { editingStatus: string | undefined; nodesToBeFocused: string[] | undefined }
            finishWithNodesToBeFocused: (nodeIds: string[]) => void
            finishWithError: (errorMessage: string) => void
        } = {
            fetch: (url: string, data: string) => {
                const queryParams = new URLSearchParams({
                    conversationId: conversationId!,
                    dialogueId: dialogueId!,
                })

                const prefix = environment.httpPrefixes[HttpPrefixKey.COMMON_API]
                url = `${prefix}/ai/bot/custom/process`

                return fetch(`${url}?${queryParams.toString()}`, {
                    method: 'POST',
                    credentials: 'include',
                    body: data,
                })
            },
            setConversationId: (conversationId_: string) => {
                conversationId = conversationId_
            },
            setDialogueId: (dialogueId_: string) => {
                dialogueId = dialogueId_
            },
            collectChangedNode,
            exportJson: (nodeId?: string) => {
                const exportedJson = this.commandInvoker.invokeBridge(CommitType.Noop, ExportFigJsonCommand, {
                    value: nodeId,
                })
                return JSON.parse(exportedJson.value ?? '{}')
            },
            setEditingStatus,
            resetEditingStatus: () => {
                editingStatus = undefined
                nodesToBeFocused = undefined
            },
            getEditingStatus: () => {
                return { editingStatus, nodesToBeFocused }
            },
            finishWithNodesToBeFocused: (nodeIds: string[]) => {
                if (this.stateStore.getState().chatbotDebugSetting.focusV2) {
                    setEditingStatus('finished', nodeIds)

                    this.updateConversationStatus(conversationId, dialogueId, {
                        pending: false,
                        success: true,
                        error: null,
                        changedNodeIds: nodeIds,
                    })
                }
            },
            finishWithError: (errorMessage: string) => {
                if (this.stateStore.getState().chatbotDebugSetting.focusV2) {
                    setEditingStatus('finished', [])
                    this.updateConversationStatus(conversationId, dialogueId, {
                        pending: false,
                        success: false,
                        error: '执行失败',
                        changedNodeIds: [],
                    })

                    if (errorMessage && this.continueSendWhenFailed) {
                        this.handleClickSend([{ text: errorMessage }])
                    }
                }
            },
        }

        return motiffAI
    }

    private init = () => {
        this.bridge.bind(OpenChatbotModal, () => {
            this.showModal()
        })
    }

    destroy = () => {
        this.bridge.unbind(OpenChatbotModal)
    }

    showModal = () => {
        this.fetchUpdateConversationMeta()
        this.stateStore.setState({
            popupOpened: true,
        })

        // 初始化 MotiffAI
        ;(window as any).motiffAI = this.setupMotiffAI()
    }

    closeModal = () => {
        this.stateStore.setState({
            popupOpened: false,
        })

        // 销毁 MotiffAI
        if ('motiffAI' in window) {
            delete (window as any).motiffAI
        }
    }

    openNewConversationPage = (conversationType: ConversationType, title?: string) => {
        this.stateStore.setState({
            pageStack: [{ pageType: PageType.Conversation, title }],
            currentConversationId: undefined,
            draftConversationType: conversationType,
        })
    }

    openAssetSearchResultPopup = (dialogueId?: string) => {
        const currentConversationId = this.stateStore.getState().currentConversationId
        const allConversations = this.dataStore.getState().allConversations
        const currentConversation = currentConversationId ? allConversations[currentConversationId] : null
        if (!currentConversation || !dialogueId) {
            return
        }

        const sourceDialogue = currentConversation.conversationDataList.find((item) => item.dialogueId === dialogueId)
        if (!sourceDialogue) {
            return
        }

        if (
            sourceDialogue.questionInput.messages.at(-1)!.content.every((item) => !item.image) &&
            sourceDialogue.questionInput.nodeIds.length !== 1
        ) {
            // 未上传图片且选区不等于 1，无法触发资产搜索展示
            WKToast.error('请选中单个图层/上传图片来搜索相似的内容')
            return
        }

        this.stateStore.setState({
            pageStack: [
                ...this.stateStore.getState().pageStack,
                { pageType: PageType.NodesPopup, title: '搜索结果', size: 'large', sourceDialogue },
            ],
        })
    }

    openInspireSearchPopup = (dialogueId?: string) => {
        const currentConversationId = this.stateStore.getState().currentConversationId
        const allConversations = this.dataStore.getState().allConversations
        const currentConversation = currentConversationId ? allConversations[currentConversationId] : null
        if (!currentConversation || !dialogueId) {
            return
        }

        const sourceDialogue = currentConversation.conversationDataList.find((item) => item.dialogueId === dialogueId)
        if (!sourceDialogue) {
            return
        }

        this.stateStore.setState({
            pageStack: [
                ...this.stateStore.getState().pageStack,
                { pageType: PageType.InspireSearchPopup, title: 'AI 灵感搜索', size: 'large', sourceDialogue },
            ],
        })
    }

    returnPage = () => {
        if (this.stateStore.getState().pageStack.length > 1) {
            // 关闭临时弹窗
            this.stateStore.setState({
                pageStack: this.stateStore.getState().pageStack.slice(0, -1),
            })
        } else if (this.stateStore.getState().pageStack.length === 1) {
            this.returnToHomePage()
        } else {
            // 本身就在首页，do nothing
        }
    }

    focusChangedNodes = (conversationId?: ConversationId, dialogueId?: string) => {
        const changedNodeIds =
            this.stateStore.getState().allConversationStatuses[createJointKey(conversationId, dialogueId)].answerStatus
                .changedNodeIds

        debugLog('focusChangedNodes', changedNodeIds, conversationId, dialogueId)

        this.commandInvoker.invokeBridge(CommitType.CommitUndo, CurrentPageSetSelectionCommandWasmCall, {
            selection: changedNodeIds,
        })

        this.commandInvoker.invokeBridge(CommitType.Noop, ZoomToFitNodesAtMaxScaleByIdsCommand, {
            nodeIds: changedNodeIds,
        })
    }

    private returnToHomePage = () => {
        this.stateStore.setState({
            pageStack: [],
            currentConversationId: undefined,
            draftConversationType: undefined,
            pendingMessages: undefined,
            pendingMessageThumbnailBase64: undefined,
        })
    }

    // 执行 AI 所生成 js 代码
    private executeJsCode = (jsCode: string) => {
        const res = this.commandInvoker.invokeBridge(CommitType.Noop, RunPluginScriptCommand, {
            script: jsCode,
            vars: {},
        })

        return res
    }

    private createConversation = async (
        messages: MessageItemContentProps[],
        conversationType: ConversationType,
        selectedNodeIds?: string[],
        seletedNodeThumbnailBase64?: string
    ) => {
        const dialogueInput = this.generCurrentDialogueInput(messages, selectedNodeIds, seletedNodeThumbnailBase64)
        const res = await fetchCreateChatbotConversation(this.docId, dialogueInput, conversationType)
        this.dataStore.setState((draft) => {
            draft.allConversations[res.conversationMeta.conversationId] = res
            draft.allConversationsMeta[res.conversationMeta.conversationId] = res.conversationMeta
        })

        return res
    }

    getCurrentConversationType = (): ConversationType | undefined => {
        const currentConversationId = this.stateStore.getState().currentConversationId
        const draftConversationType = this.stateStore.getState().draftConversationType

        if (currentConversationId) {
            const conversation = this.dataStore.getState().allConversations[currentConversationId]
            if (!conversation) {
                return undefined
            }
            return conversation.conversationMeta.conversationType
        } else {
            if (isNil(draftConversationType)) {
                return undefined
            }
            return draftConversationType
        }
    }

    private generCurrentDialogueInput = (
        newMessages: MessageItemContentProps[],
        selectedNodeIds?: string[],
        seletedNodeThumbnailBase64?: string
    ) => {
        const conversationType = this.getCurrentConversationType()!

        const userMessages: MessageItemContentProps[] = [
            ...newMessages,
            ...(featureSwitchManager.isEnabled('chatbot-v02') ? this.getEverySelectionNodeInfo(selectedNodeIds) : []),
        ]
        const dialogueInput: DialogueInput = {
            docId: this.docId,
            pageId: this.viewStateBridge.getDefaultValue('currentPageId'),
            entryName: conversationType,
            nodeIds: selectedNodeIds ?? [],
            messages: [
                ...this.getCurrentWholeHistoryMessages(),
                {
                    role: 'user',
                    content: userMessages,
                },
            ],
            selectionThumbnail: seletedNodeThumbnailBase64,
        }
        return dialogueInput
    }

    private getEverySelectionNodeInfo = (selectedNodeIds?: string[]): MessageItemContentProps[] => {
        if (!selectedNodeIds) {
            return []
        }

        return selectedNodeIds.map((id) => {
            const node = motiff.getNodeById(id)

            if (!node) {
                return { nodeInfo: { id, type: '', name: '', image: '' } }
            }

            return {
                nodeInfo: { id, type: node.type, name: node.name, image: this.getNodePrivewBase64(id) },
            }
        })
    }

    private getNodePrivewBase64 = (nodeId: string) => {
        const constraint = {
            type: Wukong.DocumentProto.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_SCALE,
            value: 1,
        }

        return getNodesPreviewBase64Image(this.commandInvoker, [nodeId], constraint)
    }

    private getCurrentWholeHistoryMessages = () => {
        const currentConversationId = this.stateStore.getState().currentConversationId
        if (currentConversationId) {
            const oldConversation = this.dataStore.getState().allConversations[currentConversationId]
            if (oldConversation) {
                const temp = oldConversation.conversationDataList.reduce((acc, item) => {
                    if (item.questionInput?.messages) {
                        acc.push(...item.questionInput.messages)
                    }
                    if (item.questionAnswer?.messages) {
                        acc.push(...item.questionAnswer.messages)
                    }
                    return acc
                }, [] as MessageItemProps[])
                return temp
            }
        }
        return []
    }

    private sendDialogue = async (
        conversationId: ConversationId,
        messages: MessageItemContentProps[],
        selectedNodeIds?: string[],
        seletedNodeThumbnailBase64?: string
    ) => {
        const conversation = this.dataStore.getState().allConversations[conversationId]
        if (!conversation) {
            return
        }

        const dialogueInput = this.generCurrentDialogueInput(messages, selectedNodeIds, seletedNodeThumbnailBase64)
        const res = await fetchSendChatbotMessage(
            conversationId,
            this.docId,
            dialogueInput,
            conversation.conversationMeta.conversationType
        )

        this.dataStore.setState((draft) => {
            draft.allConversations[conversationId] = {
                ...conversation,
                conversationMeta: {
                    ...conversation.conversationMeta,
                    lastTime: Date.now(),
                },
                conversationDataList: [
                    ...conversation.conversationDataList,
                    {
                        dialogueId: res.dialogueId,
                        questionInput: {
                            ...dialogueInput,
                            messages: [...dialogueInput.messages.slice(-1)],
                        },
                        questionAnswer: res.questionAnswer,
                        answerReactionType: AnswerReactionType.NONE,
                    },
                ],
            }
        })

        return res
    }

    /*
     * 增量更新
     */
    private updateConversationStatus = (
        conversationId?: ConversationId,
        dialogueId?: string,
        answerStatus?: DialogueAnswerStatus
    ) => {
        const allConversationStatuses = this.stateStore.getState().allConversationStatuses
        if (!conversationId || !dialogueId) {
            return
        }

        const { answerStatus: originalAnswerStatus } =
            allConversationStatuses[createJointKey(conversationId, dialogueId)]

        this.stateStore.setState({
            allConversationStatuses: {
                ...allConversationStatuses,
                [createJointKey(conversationId, dialogueId)]: {
                    answerStatus: answerStatus ?? originalAnswerStatus,
                },
            },
        })
    }

    /*
     * 初始化对话状态
     */
    private setAsDefaultPendingConversationStatus = (conversationId: ConversationId, dialogueId: string) => {
        if (!conversationId || !dialogueId) {
            return
        }

        this.stateStore.setState({
            allConversationStatuses: {
                ...this.stateStore.getState().allConversationStatuses,
                [createJointKey(conversationId, dialogueId)]: {
                    answerStatus: { pending: true, success: false, error: null, changedNodeIds: [] },
                },
            },
        })
    }

    /*
     * 尝试触发文档编辑能力
     * 1. 检查对话类型
     * 2. 执行 AI 所生成的 js 代码
     * 3. 更新对话状态
     */
    private tryTriggerEdittingOfDocument = (
        conversationId: ConversationId,
        dialogueId: string,
        answer: DialogueAnswer
    ) => {
        // 若 ops 字段非空，则执行 AI 所生成的 js 代码
        const answerType = getAgentAnswerType(answer)
        if (answerType === AgentAnswerType.EXECUTE) {
            this.updateConversationStatus(conversationId, dialogueId, {
                pending: true,
                success: false,
                error: null,
                changedNodeIds: [],
            })

            setTimeout(() => {
                // motiff.once 获取执行 js 代码时被修改了的图层

                // 设置对话 id 和对话 id, 用于 MotiffAI.fetch
                ;(window as any).motiffAI.setConversationId(conversationId)
                ;(window as any).motiffAI.setDialogueId(dialogueId)

                // 重置运行状态, 用于判断是否完成
                ;(window as any).motiffAI.resetEditingStatus()

                const preScript = `
                    motiff.once('documentchange', (changes) => {
                        window.motiffAI.collectChangedNode(changes.documentChanges.map((item) => {
                            if ('style' in item) {
                                return item.style.id
                            } else {
                                return item.node.id
                            }
                        }))
                    })

                    // 触发 documentchange 事件, 避免 AI 所生成代码无法触发
                    // 应忽略此的修改事件
                    motiff.createPaintStyle().remove()
                `

                if (!this.executeJsCode(preScript).success) {
                    console.error('[记录修改图层] 初始化失败')
                }

                if (!this.stateStore.getState().chatbotDebugSetting.focusV2) {
                    //TODO(jiangjk): 当前仅同步执行的 js 代码的 error 可被捕获
                    const execRes = this.executeJsCode((answer.ops[0] as JavaScriptOp).code)
                    if (execRes.success) {
                        this.updateConversationStatus(conversationId, dialogueId, {
                            pending: false,
                            success: true,
                            error: null,
                            changedNodeIds: [],
                        })
                    } else {
                        this.updateConversationStatus(conversationId, dialogueId, {
                            pending: false,
                            success: false,
                            error: '执行失败',
                            changedNodeIds: [],
                        })
                        if (
                            featureSwitchManager.isEnabled('chatbot-v02') &&
                            this.continueSendWhenFailed &&
                            execRes.error
                        ) {
                            this.handleClickSend([{ text: execRes.error }])
                        }
                    }
                } else {
                    const execRes = this.executeJsCode((answer.ops[0] as JavaScriptOp).code)
                    if (execRes.success) {
                        // 若 20s 后仍未完成，则视为完成
                        setTimeout(() => {
                            const { editingStatus, _ } = (window as any).motiffAI.getEditingStatus()

                            if (editingStatus === undefined) {
                                this.updateConversationStatus(conversationId, dialogueId, {
                                    pending: false,
                                    success: true,
                                    error: null,
                                    changedNodeIds: [],
                                })
                            }
                        }, 20000)
                    } else {
                        this.updateConversationStatus(conversationId, dialogueId, {
                            pending: false,
                            success: false,
                            error: '执行失败',
                            changedNodeIds: [],
                        })
                        if (
                            featureSwitchManager.isEnabled('chatbot-v02') &&
                            this.continueSendWhenFailed &&
                            execRes.error
                        ) {
                            this.handleClickSend([{ text: execRes.error }])
                        }
                    }
                }
            }, 1000)
        }
    }

    handleClickSend = async (
        message: MessageItemContentProps[],
        selectedNodeIds?: string[],
        seletedNodeThumbnailBase64?: string,
        continueSendWhenFailed?: boolean
    ) => {
        try {
            this.continueSendWhenFailed = continueSendWhenFailed ?? false
            this.stateStore.setState({
                pendingMessages: message,
                pendingMessageThumbnailBase64: seletedNodeThumbnailBase64,
            })

            const conversationType = this.getCurrentConversationType()!
            const currentConversationId = this.stateStore.getState().currentConversationId

            if (!currentConversationId) {
                const res = await this.createConversation(
                    message,
                    conversationType,
                    selectedNodeIds,
                    seletedNodeThumbnailBase64
                )
                this.stateStore.setState({
                    currentConversationId: res.conversationMeta.conversationId,
                })

                this.setAsDefaultPendingConversationStatus(
                    res.conversationMeta.conversationId,
                    res.conversationDataList[0].dialogueId
                )

                this.tryTriggerEdittingOfDocument(
                    res.conversationMeta.conversationId,
                    res.conversationDataList[0].dialogueId,
                    res.conversationDataList[0].questionAnswer
                )
            } else {
                const res = await this.sendDialogue(
                    currentConversationId,
                    message,
                    selectedNodeIds,
                    seletedNodeThumbnailBase64
                )

                if (res) {
                    this.setAsDefaultPendingConversationStatus(currentConversationId, res.dialogueId)
                    this.tryTriggerEdittingOfDocument(currentConversationId, res.dialogueId, res.questionAnswer)
                }
            }
        } catch (error) {
            console.error(error)
        } finally {
            this.stateStore.setState({
                pendingMessages: undefined,
                pendingMessageThumbnailBase64: undefined,
            })
        }
    }

    fetchUpdateConversationMeta = async () => {
        const res = await fetchGetUserConversationHistory()
        this.dataStore.setState((draft) => {
            draft.allConversationsMeta = res.reduce((acc, item) => {
                acc[item.conversationId] = item
                return acc
            }, {} as Record<ConversationId, ConversationMetaVO>)
        })
    }

    fetchUpdateWholeConversation = async (conversationId: ConversationId) => {
        const res = await fetchGetWholeConversation(conversationId)
        this.dataStore.setState((draft) => {
            draft.allConversations[conversationId] = res
        })
    }

    deleteConversation = async (conversationId: ConversationId) => {
        await fetchDeleteConversation(conversationId)
        this.dataStore.setState((draft) => {
            const newAllConversations = { ...draft.allConversations }
            delete newAllConversations[conversationId]
            draft.allConversations = newAllConversations

            const newAllConversationsMeta = { ...draft.allConversationsMeta }
            delete newAllConversationsMeta[conversationId]
            draft.allConversationsMeta = newAllConversationsMeta
        })

        if (this.stateStore.getState().currentConversationId === conversationId) {
            this.returnToHomePage()
        }
    }

    updateDialogueReaction = async (
        conversationId: ConversationId,
        dialogueId: DialogueId,
        reactionType: AnswerReactionType
    ) => {
        this.dataStore.setState((draft) => {
            draft.allConversations[conversationId].conversationDataList.find(
                (item) => item.dialogueId === dialogueId
            )!.answerReactionType = reactionType
        })
        await fetchUpdateDialogueReaction(conversationId, dialogueId, reactionType)
    }

    resendLastDialogue = async (conversationId: ConversationId) => {
        const conversation = this.dataStore.getState().allConversations[conversationId]
        if (!conversation) {
            return
        }
        const lastDialogue = conversation.conversationDataList.at(-1)
        if (!lastDialogue) {
            return
        }

        const messages = lastDialogue.questionInput.messages.at(-1)
        if (!messages) {
            return
        }

        await this.handleClickSend(
            messages.content,
            lastDialogue.questionInput.nodeIds,
            lastDialogue.questionInput.selectionThumbnail,
            true
        )
    }

    changeCurrentConversation = async (conversationId: ConversationId) => {
        await this.fetchUpdateWholeConversation(conversationId)
        const conversation = this.dataStore.getState().allConversations[conversationId]
        if (!conversation) {
            return
        }

        this.stateStore.setState({
            pageStack: [{ pageType: PageType.Conversation, title: conversation.conversationMeta.conversationTitle }],
            currentConversationId: conversationId,
            draftConversationType: undefined,
        })
    }

    getAISearchNode = (nodeId: string): DeepRequired<Wukong.DocumentProto.IAISearchNode> | null => {
        const ret = this.commandInvoker.invokeBridge(CommitType.Noop, GetAISearchNodeDataCommand, {
            value: nodeId,
        })
        if (!ret.node) {
            return null
        }

        return {
            nodeId: ret.node.nodeId!,
            name: ret.node.name,
            iconData: {
                type: ret.node.iconData!.type!,
                path: ret.node.iconData!.path!,
            },
            isPurple: ret.node.isPurple,
        }
    }

    // ------------- debug utils ------------- //
    exportCurrentDialogueAsFile = () => {
        const currentConversationId = this.stateStore.getState().currentConversationId
        if (currentConversationId) {
            const currentConversation = this.dataStore.getState().allConversations[currentConversationId]

            // download as json file
            const json = JSON.stringify(currentConversation, null, 4)
            const blob = new Blob([json], { type: 'application/json' })
            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = `${currentConversationId}.json`
            a.click()
        }
    }

    getDialogueAnswerByDialogueId = (conversationId: ConversationId, dialogueId: DialogueId) => {
        const conversation = this.dataStore.getState().allConversations[conversationId]
        if (!conversation) {
            return undefined
        }
        return conversation.conversationDataList.find((item) => item.dialogueId === dialogueId)?.questionAnswer
    }

    generDialogueInputByDialogueId = (
        conversationId: ConversationId,
        dialogueId: DialogueId
    ): DialogueInput | undefined => {
        const conversation = this.dataStore.getState().allConversations[conversationId]
        if (!conversation) {
            return undefined
        }

        // Find the target dialogue index
        const dialogueIndex = conversation.conversationDataList.findIndex((item) => item.dialogueId === dialogueId)
        if (dialogueIndex === -1) {
            return undefined
        }

        // Get all messages up to the target dialogue
        const messages = conversation.conversationDataList.slice(0, dialogueIndex + 1).reduce((acc, item) => {
            if (item.questionInput?.messages) {
                acc.push(...item.questionInput.messages)
            }
            // exclude target's answer
            if (item.questionAnswer?.messages && item.dialogueId !== dialogueId) {
                acc.push(...item.questionAnswer.messages)
            }
            return acc
        }, [] as MessageItemProps[])

        const targetDialogue = conversation.conversationDataList[dialogueIndex]

        return {
            docId: this.docId,
            pageId: targetDialogue.questionInput.pageId,
            entryName: conversation.conversationMeta.conversationType,
            nodeIds: targetDialogue.questionInput.nodeIds,
            messages: messages,
            selectionThumbnail: targetDialogue.questionInput.selectionThumbnail,
        }
    }
}
