import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    CopiedToClipboard: '已复制到剪贴板',
    Copy: '复制',
    Show: '显示被折叠的 {{count}} 行代码',
} as const

export const enTranslation = {
    CopiedToClipboard: 'Copied to clipboard',
    Copy: 'Copy',
    Show: 'Show {{count}} more lines of code',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string | number>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
