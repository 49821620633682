import { Wukong } from '@wukong/bridge-proto'
import { FC, useMemo } from 'react'
import { LayerPanelRenderItem } from '../render-item'
import styles from './index.module.less'

interface LayerPanelRenderListProps {
    renderList: Wukong.DocumentProto.VDevModeLayerPanelNode[]
    translateHeight: number
    totalHeight: number
}

export const LayerPanelRenderList: FC<LayerPanelRenderListProps> = ({ renderList, translateHeight, totalHeight }) => {
    return useMemo(
        () => (
            <>
                <div className={styles['fake-scroll-area']} style={{ height: translateHeight + 'px' }} />
                <div
                    data-testid={`layer-panel-render-list`}
                    style={{
                        height: totalHeight - translateHeight + 'px',
                    }}
                >
                    {renderList.map((item, index) => (
                        <LayerPanelRenderItem
                            item={item as Wukong.DocumentProto.VDevModeLayerPanelNode}
                            index={index}
                            key={item.id}
                        />
                    ))}
                </div>
            </>
        ),
        [translateHeight, renderList, totalHeight]
    )
}
