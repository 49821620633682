/* eslint-disable no-restricted-imports */
import { UpdateSelectionStrokeDashGapCommand } from '@wukong/bridge-proto'
import { useCallback } from 'react'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { Value } from '../../../atom/inputs/utils/type'

export function useStrokeDashGap() {
    const command = useCommand()
    const selectionStroke = useViewState('selectionStrokeV2')
    const dashGapState = selectionStroke?.dashGapState
    const onChangeDashGap = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value !== 'number') {
                return
            }

            command.DEPRECATED_invokeBridge(UpdateSelectionStrokeDashGapCommand, { strokeDashGap: value })
            command.commitUndo(options?.commitType)
        },
        [command]
    )

    return {
        dashGapState,
        onChangeDashGap,
    }
}
