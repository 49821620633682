import { useCallback } from 'react'
import {
    IconAbsolutePosition,
    MonoIconPanelHeighFix16,
    MonoIconPanelHeightFitContent16,
    MonoIconPanelHeightFullContainer16,
    MonoIconPanelWidthFitContent16,
    MonoIconPanelWidthFix16,
    MonoIconPanelWidthFullContainer16,
    Select,
    Tooltip,
    type SelectNormalSingleLevelProps,
} from '../../../../../../../../../ui-lib/src'
import { IconButton } from '../../../../../atom/button/icon-button'
import { SingleGrid } from '../../../../../atom/grid/single-grid'
import { useAutoLayoutCommand, type CommandType } from '../command'
import { AutoLayoutState, AutoLayoutVisualDirection, AutoLayoutWHType, AutoLayoutWHValue } from '../types'
import { useHoverAutolayoutMenuItem } from '../use-hover-autolayout-menu-item'
import { translation } from './size-input.translation'

// 宽高类型值
interface WHValueItem {
    value: AutoLayoutWHValue
    label: string
    optionName: string
    disabled: boolean
    icon: JSX.Element
    disableReason?: string
}

export const getWHTable = (type: AutoLayoutVisualDirection = 'w') => ({
    [AutoLayoutWHValue.WH_Fill]: {
        label: translation('FillValue'),
        optionName: translation('FillContainer'),
        icon: type === 'w' ? <MonoIconPanelWidthFullContainer16 /> : <MonoIconPanelHeightFullContainer16 />,
    },
    [AutoLayoutWHValue.WH_Fixed]: {
        label: translation('FixedValue'),
        optionName: type === 'w' ? translation('FixedWidth') : translation('FixedHeight'),
        icon: type === 'w' ? <MonoIconPanelWidthFix16 /> : <MonoIconPanelHeighFix16 />,
    },
    [AutoLayoutWHValue.WH_Hug]: {
        label: translation('HugValue'),
        optionName: translation('HugContents'),
        icon: type === 'w' ? <MonoIconPanelWidthFitContent16 /> : <MonoIconPanelHeightFitContent16 />,
    },
    [AutoLayoutWHValue.WH_Mixed]: {
        label: translation('Mixed'),
        optionName: translation('Mixed'),
        icon: type === 'w' ? <MonoIconPanelWidthFix16 /> : <MonoIconPanelHeighFix16 />,
    },
})

export type SizeInputProps = AutoLayoutState & {
    disableSizeMode?: boolean
    disableAbsolutePosition?: boolean
}

function useSizeInputProps(state: SizeInputProps) {
    const wh = state.wh

    const shouldDisplayWH = wh.shouldDisplayWH
    const shouldDisplayAbsolute = wh.shouldDisplayAbsolute

    // 处理宽度
    const widthValues: AutoLayoutWHValue[] = [AutoLayoutWHValue.WH_Fixed]
    const heightValues: AutoLayoutWHValue[] = [AutoLayoutWHValue.WH_Fixed]

    // 如果是自动布局子元素
    if (wh.whType !== AutoLayoutWHType.WH_Parent) {
        widthValues.push(AutoLayoutWHValue.WH_Fill)
        heightValues.push(AutoLayoutWHValue.WH_Fill)
    }

    // 如果是自动布局容器
    if (wh.whType !== AutoLayoutWHType.WH_Child) {
        widthValues.push(AutoLayoutWHValue.WH_Hug)
        heightValues.push(AutoLayoutWHValue.WH_Hug)
    }

    const widthItems: WHValueItem[] = widthValues.map((v) => ({
        value: v,
        disabled: false,
        ...getWHTable('w')[v],
    }))

    const heightItems: WHValueItem[] = heightValues.map((v) => ({
        value: v,
        disabled: false,
        ...getWHTable('h')[v],
    }))

    // 如果部分是容器，部分是子元素，则只有固定宽高可以选中
    if (wh.whType === AutoLayoutWHType.WH_Either) {
        for (const items of [widthItems, heightItems]) {
            for (const item of items) {
                item.disabled = item.value !== AutoLayoutWHValue.WH_Fixed
            }
        }
    }

    if (wh.childVerticalFillContainerDisabled) {
        const fillItemIndex = heightItems.findIndex((item) => item.value === AutoLayoutWHValue.WH_Fill)
        if (fillItemIndex > -1) {
            heightItems[fillItemIndex].disabled = true
            heightItems[fillItemIndex].disableReason = translation('DisableFillContainerTooltip')
        }
    }

    return {
        shouldDisplayWH,
        shouldDisableWH: state.disableSizeMode,
        width: {
            type: 'w' as AutoLayoutVisualDirection,
            value: wh.widthType,
            options: widthItems,
            ...getWHTable('w')[wh.widthType],
        },
        height: {
            type: 'h' as AutoLayoutVisualDirection,
            value: wh.heightType,
            options: heightItems,
            ...getWHTable('h')[wh.heightType],
        },
        isAbsolute: !wh.isPositionMixed && wh.isPositionAbsolute,
        shouldDisableAbsolute: wh.isPositionDisabled || state.disableAbsolutePosition,
        shouldDisplayAbsolute,
    }
}

export const sizeInputIds = {
    container: 'autolayout-size',
    absBtn: 'autolayout-abs-btn',
    option: (type: AutoLayoutVisualDirection) => `autolayout-size-${type}`,
    value: (value: AutoLayoutWHValue) => `autolayout-size-${value}`,
    disabledValue: (value: AutoLayoutWHValue) => `autolayout-size-disabled-value-${value}`,
}

/**
 * 自动布局宽高类型组件
 * @param state
 * @returns
 */
export function useSizeInput(state: SizeInputProps) {
    const wh = useSizeInputProps(state)
    const command = useAutoLayoutCommand(state)
    const getWorldRect = useCallback(
        () => ({
            left: 0,
            top: 0,
            right: document.documentElement.clientWidth - 40,
            bottom: document.documentElement.clientHeight,
        }),
        []
    )

    const [widthType, heightType] = wh.shouldDisplayWH
        ? [
              <SizeSelectInput
                  key={sizeInputIds.option(wh.width.type)}
                  whState={wh.width}
                  command={command}
                  disabled={wh.shouldDisableWH}
                  placement="over"
              />,
              <SizeSelectInput
                  key={sizeInputIds.option(wh.height.type)}
                  whState={wh.height}
                  command={command}
                  disabled={wh.shouldDisableWH}
                  placement="over"
                  worldRect={getWorldRect}
              />,
          ]
        : [null, null]

    const absPosBtn = wh.shouldDisplayAbsolute ? (
        <Tooltip title={translation('AbsolutePosition')}>
            <IconButton
                icon={<IconAbsolutePosition />}
                selected={wh.isAbsolute}
                dataTestId={sizeInputIds.absBtn}
                onClick={() => command.setAbsolute(!wh.isAbsolute)}
                disabled={wh.shouldDisableAbsolute}
            ></IconButton>
        </Tooltip>
    ) : null

    return { widthType, heightType, absPosBtn }
}

function SizeSelectInput({
    whState,
    command,
    disabled,
    placement,
    worldRect,
}: {
    whState: ReturnType<typeof useSizeInputProps>['width'] | ReturnType<typeof useSizeInputProps>['height']
    command: CommandType
    disabled?: boolean
    placement: SelectNormalSingleLevelProps['placement']
    worldRect?: SelectNormalSingleLevelProps['worldRect']
}) {
    const { hoverMenu, unHoverMenu } = useHoverAutolayoutMenuItem<WHValueItem>({
        hideMenu: false,
        hoverMenu: (option) => command.hoverWHValue(whState.type, option.value),
    })

    return (
        <Select.NormalSingleLevel
            value={whState.value}
            label={whState.label}
            icon={whState.icon}
            dataTestIds={{ triggerFocus: sizeInputIds.option(whState.type) }}
            key={sizeInputIds.option(whState.type)}
            onChange={(v) => {
                command.setWHValue(whState.type, v)
            }}
            disabled={disabled}
            isSmallArrow
            placement={placement}
            worldRect={worldRect}
        >
            {whState.options.map((option) => (
                <Select.NormalSingleLevel.Option
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                    forwardIcon={option.icon}
                    data-testid={
                        option.disabled ? sizeInputIds.disabledValue(option.value) : sizeInputIds.value(option.value)
                    }
                    tooltipTitle={option.disableReason}
                    placement="left"
                    tooltipInactivation={false}
                    onMouseOver={() => hoverMenu(option)}
                    onMouseOut={unHoverMenu}
                >
                    {option.optionName}
                </Select.NormalSingleLevel.Option>
            ))}
        </Select.NormalSingleLevel>
    )
}

/**
 * 这个组件仅供测试使用
 * @param props
 * @returns
 */
export function SizeInput(props: { state: SizeInputProps }) {
    const { widthType, heightType, absPosBtn } = useSizeInput(props.state)

    return (
        <>
            <SingleGrid testId={sizeInputIds.container}>
                <SingleGrid.Item start={5} span={22}>
                    {widthType}
                </SingleGrid.Item>
                <SingleGrid.Item start={29} span={22}>
                    {heightType}
                </SingleGrid.Item>
                <SingleGrid.Item start={54} span={6}>
                    {absPosBtn}
                </SingleGrid.Item>
            </SingleGrid>
        </>
    )
}
