import { useCallback, useEffect, useMemo, useRef } from 'react'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { RangeArea } from '../../../atom/range/range-area'
import { ColorSpace, toCSSBackgroundColor } from './utils/color-translate'

interface HueRangeProps {
    hue: number // [0-360]
    width: number
    height: number
    colorSpace: ColorSpace
    className?: string
    onChange?: (hue: number, options?: InputOptionsForUndoSquash) => void
}

export function HueRange(props: HueRangeProps) {
    const { hue, width, height, colorSpace, className, onChange } = props
    const canvasHue = useRef<HTMLCanvasElement>(null)

    const hueHandleColor = useMemo(() => {
        const { r, g, b } = getRgbByHue(hue)
        return { background: toCSSBackgroundColor({ r, g, b }, colorSpace) }
    }, [hue, colorSpace])

    // 绘制 s 区域
    useEffect(() => {
        if (!canvasHue.current) return
        const ctx =
            colorSpace === 'srgb'
                ? canvasHue.current.getContext('2d')
                : canvasHue.current.getContext('2d', {
                      colorSpace,
                  })
        if (!ctx) return
        let { width: _width, height: _height } = canvasHue.current.getBoundingClientRect()
        _width = Math.floor(_width)
        _height = Math.floor(_height)
        canvasHue.current.width = _width
        canvasHue.current.height = _height
        const linearGradient = ctx.createLinearGradient(0, 0, _width, 0)
        linearGradient.addColorStop(0, '#ff0000')
        linearGradient.addColorStop(1 / _width, '#ff0000')
        linearGradient.addColorStop(1 / 6, '#ffff00')
        linearGradient.addColorStop(2 / 6, '#00ff00')
        linearGradient.addColorStop(3 / 6, '#00ffff')
        linearGradient.addColorStop(4 / 6, '#0000ff')
        linearGradient.addColorStop(5 / 6, '#ff00ff')
        linearGradient.addColorStop((_width - 1) / _width, '#ff0000')
        linearGradient.addColorStop(1, '#ff0000')
        ctx.fillStyle = linearGradient
        ctx.fillRect(0, 0, _width, _height)
    }, [colorSpace])

    // 通过拖动圆圈 修改色相
    const onChangeHue = useCallback(
        (value: any, options?: InputOptionsForUndoSquash) => {
            const { rateX } = value
            onChange?.(rateX * 360, options)
        },
        [onChange]
    )

    const rangeStyle = useMemo(() => {
        return { width: `${width}px`, height: `${height}px`, borderRadius: `${height / 2}px` }
    }, [height, width])

    const canvasStyle = useMemo(() => {
        return { borderRadius: `${height / 2}px` }
    }, [height])

    return (
        <RangeArea
            style={rangeStyle}
            onHandleMove={onChangeHue}
            handleStyle={hueHandleColor}
            canvasStyle={canvasStyle}
            forbidOverflow={true}
            rateX={hue / 360}
            rateY={0.5}
            className={className}
            testId="hue-rage-area"
        >
            <canvas ref={canvasHue} width={width} height={height} style={{ width: '100%', height: '100%' }} />
        </RangeArea>
    )
}

function getRgbByHue(hue: number) {
    const percent = Math.min(Math.max(hue, 0), 360) / 360
    let r = 255,
        g = 255,
        b = 255
    if (percent <= 1 / 6) {
        r = 255
        g = percent * 6 * 255
        b = 0
    } else if (percent <= 2 / 6) {
        r = (2 - percent * 6) * 255
        g = 255
        b = 0
    } else if (percent <= 3 / 6) {
        r = 0
        g = 255
        b = (percent * 6 - 2) * 255
    } else if (percent <= 4 / 6) {
        r = 0
        g = (4 - percent * 6) * 255
        b = 255
    } else if (percent <= 5 / 6) {
        r = (percent * 6 - 4) * 255
        g = 0
        b = 255
    } else if (percent <= 6 / 6) {
        r = 255
        g = 0
        b = (1 - percent) * 255
    }
    return { r, g, b }
}
