import { translation } from './use-copy-file.translation'
/* eslint-disable no-restricted-imports */
import { WKToast } from '../../../../../../ui-lib/src'
import { domLocation, generateRouterPath, RouteToken } from '../../../../../../util/src'
import { BusinessStatusCode } from '../../../../kernel/interface/request-error-code'
import { BatchDuplicateDocRequest } from '../../../../kernel/request/document'
import { RequestResponseErrorHandler } from '../../../../kernel/request/error-handler'
import { openIndependentWindow } from '../../../../kernel/util/open-window'
import { usePlanAndUserStateService } from '../../../../main/app-context'
import { PayPreCheck } from '../../../../share/payment/pay-pre-check'
import { handleBusinessStatusInCopyDocs } from '../../../../share/payment/pay-upgrade-handle-business-status'
import { useDocInfoContext } from '../../../context/top-area-context'

export function useCopyFile() {
    const { docData } = useDocInfoContext()
    const planAndUserStateService = usePlanAndUserStateService()
    const planAndUserState = planAndUserStateService.useZustandStore.use.planAndUserState()

    const copyFile = async () => {
        if (!docData) {
            return
        }
        const canCopyDoc = await PayPreCheck.checkDuplicateDocLimit({
            orgId: docData.orgId,
            docs: [docData],
            initUpgradePlan: undefined,
            getPlan: () => planAndUserState,
        })
        if (!canCopyDoc) {
            return
        }

        const copyDoc = await new BatchDuplicateDocRequest([docData.id])
            .start()
            .then((doc) => {
                return doc
            })
            .catch((e) => {
                const businessStatus = RequestResponseErrorHandler(e).businessStatus
                handleBusinessStatusInCopyDocs(businessStatus, docData.teamId)
                if (businessStatus === BusinessStatusCode.ProhibitCopy) {
                    WKToast.show(translation('ProhibitDuplicate'))
                }
                return undefined
            })

        if (copyDoc) {
            WKToast.show(`${translation('FileDuplicated')}${copyDoc[0].draft ? translation('ToDrafts') : ''}`)
            openIndependentWindow(`${domLocation().origin}/${generateRouterPath(RouteToken.File)}/${copyDoc[0].id}`)
        }
    }

    return { copyFile }
}
