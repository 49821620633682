import { useFontManagerService } from '../../../../../context/document-context'

export function usePluginInstallPoint() {
    const fontManagerService = useFontManagerService()

    const { pluginStatus } = fontManagerService.states.use.pluginDetails()
    const pluginInstallPointShowIfPossible = fontManagerService.states.use.pluginInstallPointShowIfPossible()

    const showPluginPoint =
        pluginInstallPointShowIfPossible && (pluginStatus === 'uninstalled' || pluginStatus === 'forceUpdated')

    const markPointShown = () => {
        if (showPluginPoint) {
            fontManagerService.setPluginInstallPointUnShown()
        }
    }

    return {
        showPluginPoint,
        markPointShown,
    }
}
