import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Effect: '效果',
    Style: '样式',
    Click: '点击',
    ToReplace: '替换多个属性值',
} as const

export const enTranslation = {
    Effect: 'Effects',
    Style: 'Style',
    Click: 'Click',
    ToReplace: 'to replace mixed content',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
