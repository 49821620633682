export function trimInputValue(el: HTMLInputElement) {
    const value = el.value.toString()
    const valueTrim = value.trim()
    if (value.length !== valueTrim.length) {
        const valueTrimStart = value.trimStart()
        const rangSize = Math.max(Number(el.selectionEnd) - Number(el.selectionStart), 0)
        const selectionStart = Math.max(Number(el.selectionStart) - (value.length - valueTrimStart.length), 0)
        const selectionEnd = Math.min(selectionStart + rangSize, valueTrim.length)
        el.value = valueTrim
        el.setSelectionRange(selectionStart, selectionEnd)
    }
    return valueTrim
}
