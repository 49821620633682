import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    PluginInfoUpdated: '插件信息已更新',
    EditPluginInfo: '编辑插件信息',
    Update: '更新',
    Icon: '图标',
    Name: '名称',
    PluginInfoUpdatedFailed: '插件信息更新失败',
} as const

export const enTranslation = {
    PluginInfoUpdated: 'Plugin details updated',
    EditPluginInfo: 'Edit plugin details',
    Icon: 'Icon',
    Update: 'Update',
    Name: 'Name',
    PluginInfoUpdatedFailed: 'An error occurred while updating plugin details.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
