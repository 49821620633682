import { POST } from '@tutor/network-core'
import { DocID } from '../interface/type'
import { GetLibraryOperationInfoRequest, GetLibraryOperationInfoResponse } from '../interface/library-operation'
import { BaseCommonRequest } from './base-request'

/**
 * 根据 componentIds 和 styleIds 获取到对应最新更新操作的元数据信息
 */
@POST
export class GetLatestLibraryOperation extends BaseCommonRequest<GetLibraryOperationInfoResponse> {
    constructor(private readonly content: GetLibraryOperationInfoRequest, private readonly thisDocId: DocID) {
        super()
    }

    public override requestBody() {
        return this.content
    }

    public override requestArgument() {
        return { thisDocId: this.thisDocId }
    }

    public override requestUrl() {
        return `libraries/operations`
    }
}
