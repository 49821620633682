import { useFontManagerService } from '../../../../../context/document-context'
import { FontSelect } from '../font-select/font-select'
import { translation } from './font-team-tab-item.translation'
import { FontTeamUploader, LearnMoreSharedFont } from './font-team-uploader'

export function FontSharedTabItem({
    isDraft,
    teamId,
    selectMaxHeight,
}: {
    isDraft: boolean
    teamId: string
    selectMaxHeight: number
}) {
    const fontManagerService = useFontManagerService()
    const sharedFonts = fontManagerService.states.use.filteredSharedFontsState()

    return (
        <FontSelect
            fontInfos={sharedFonts}
            placeholder={translation('SearchSharedFonts')}
            selectMaxHeight={selectMaxHeight}
        >
            {sharedFonts.length ? null : isDraft ? (
                <LearnMoreSharedFont desc={translation('LearnMoreSharedFonts')} />
            ) : (
                <FontTeamUploader teamId={teamId} />
            )}
        </FontSelect>
    )
}
