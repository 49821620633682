import { Wukong } from '@wukong/bridge-proto'
import { forwardRef } from 'react'
import { OmitToJSON } from '../../../../../../../ui-lib/src'
import { ImagePaint } from '../../../../../document/node/node'
import { BlendColor } from '../../blend/blend-color/blend-color'
import { BlendImage } from '../../blend/blend-image/blend-image'
import { BlendGradient } from '../blend-gradient/blend-gradient'
import { ColorCustomTools } from '../color-custom-tools/color-custom-tools'
import { ColorLibraries } from '../color-libraries/color-libraries'
import { ColorPanelProps } from '../type'
import { ColorPanelContainerV2 } from './color-panel-container-v2'
import { ColorPanelTitle } from './color-panel-title'
import { ColorPanelRef, useColorPanel } from './use-color-panel'

function _ColorPanel(props: ColorPanelProps, ref: React.Ref<ColorPanelRef>) {
    const {
        originalProps,
        derivedProps,
        paint,
        activeTitle,
        colorSpace,
        colorPanelContainerRef,
        colorPanelContainerV2Ref,
        onFirstMove,
        setActiveTitle,
        onChangePaintMode,
        onChangeBlendMode,
        onChangeColor,
        onChangeOpacity,
        onChangeImagePaint,
        onChangeColorStops,
        onChangeTransform,
        onChangePaintType,
        onChangeColorVar,
        onChangeStyle,
    } = useColorPanel(props, ref)

    return (
        <ColorPanelContainerV2
            ref={colorPanelContainerV2Ref}
            position={originalProps.position}
            positionRightBase={props.positionRightBase}
            style={{ zIndex: props.zIndex }}
            header={
                <ColorPanelTitle
                    from={originalProps.from}
                    activeKey={activeTitle}
                    onActiveTabChange={setActiveTitle}
                    onClickAddIcon={originalProps.onClickAddIcon}
                    onClickCloseIcon={originalProps.onCancel}
                />
            }
            onFirstMove={onFirstMove}
            data-testid="color-panel-container"
        >
            {activeTitle === 'libraries' ? (
                <ColorLibraries
                    from={originalProps.from}
                    selectStyleId={originalProps.styleId}
                    selectStyleNodeKey={originalProps.styleKey ?? ''}
                    selectColorVariableNodeId={paint.colorVar?.value.alias ?? undefined}
                    onChangeStyle={onChangeStyle}
                    onChangeColorVar={onChangeColorVar}
                    onClickEditStyle={originalProps.onClickEditStyle}
                />
            ) : activeTitle === 'custom' ? (
                <div>
                    {derivedProps.hideTools ? null : (
                        <ColorCustomTools
                            paint={paint as OmitToJSON<Wukong.DocumentProto.Paint>}
                            hideImage={derivedProps.hideImage}
                            onChangePaintMode={onChangePaintMode}
                            onChangeBlendMode={onChangeBlendMode}
                        />
                    )}
                    {paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT ? (
                        <BlendColor
                            color={paint.color!}
                            colorSpace={colorSpace}
                            opacity={paint.opacity ?? 1}
                            onChangeColor={onChangeColor}
                            onChangeOpacity={onChangeOpacity}
                        />
                    ) : paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_LINEAR ||
                      paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_RADIAL ||
                      paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_ANGULAR ||
                      paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_DIAMOND ? (
                        <BlendGradient
                            type={paint.type}
                            colorStops={paint.gradientStops as OmitToJSON<Wukong.DocumentProto.ColorStop>[]}
                            transform={paint.gradientTransform as OmitToJSON<Wukong.DocumentProto.Transform>}
                            onChangeColorStops={onChangeColorStops}
                            onChangeTransform={onChangeTransform}
                            onChangePaintType={onChangePaintType}
                        />
                    ) : paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_IMAGE_PAINT ? (
                        <BlendImage
                            data={paint as OmitToJSON<Wukong.DocumentProto.Paint> as ImagePaint}
                            onChangeImagePaint={onChangeImagePaint}
                            enterClose={originalProps.enterClose}
                        />
                    ) : null}
                </div>
            ) : null}
        </ColorPanelContainerV2>
    )
}

export const ColorPanel = forwardRef(_ColorPanel)
