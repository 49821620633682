import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    DevMode: '研发模式',
    DevModeTip: '使用「研发模式」，请将文件移动到付费团队中。',
    MoveFile: '移动文件',
} as const

export const enTranslation = {
    DevMode: 'Develop Mode',
    DevModeTip: 'To use Develop Mode in this file, move it to a paid team.',
    MoveFile: 'Move file',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
