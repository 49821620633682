import { ApplyPickedColorCommand } from '@wukong/bridge-proto'
import { useCallback, useEffect, useRef } from 'react'
import { useUpdateEffect } from 'react-use'
import { cmdChangeColorPick } from '../../../document/command/document-command'
import { ColorPickCommandType, RGB } from '../../../document/node/node'
import { useFeatureSwitch } from '../../../kernel/switch'
import { useViewState } from '../../../view-state-bridge'
import { useCommand } from '../../context/document-context'

export function useEyeDropper(onChangeColor?: (value: RGB) => void) {
    const eyeDropperState = useViewState('colorPick')
    const command = useCommand()
    const flag = useFeatureSwitch('wk-44265')

    const onChangeEyeDropper = useCallback(() => {
        command.invoke(cmdChangeColorPick, ColorPickCommandType.SwitchColorPickVisible)
    }, [command])

    const pickColorEnd = useCallback(() => {
        command.invoke(cmdChangeColorPick, ColorPickCommandType.PickColorEnd)
    }, [command])

    // 默认在 wasm 侧应用取色器取色，有弹窗内场景需要js侧应用取色时调用此函数，应用后不再走 wasm 侧取色器取色流程
    const applyPickColor = useCallback(
        (callback?: (value: RGB) => void) => {
            const rgb = eyeDropperState?.pickColor
            if (rgb && callback) {
                callback(rgb)
                pickColorEnd()
            }
        },
        [pickColorEnd, eyeDropperState?.pickColor]
    )

    const colorChangeByDropper = useRef<() => void>()
    colorChangeByDropper.current = () => {
        const rgb = eyeDropperState?.pickColor
        if (!rgb) {
            return
        }
        onChangeColor?.(rgb)
    }

    // 待废弃: 这块逻辑需要迁移到应用取色器的组件中
    useUpdateEffect(() => {
        if (!flag) {
            colorChangeByDropper.current?.()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eyeDropperState?.isExecutePickColor])

    return {
        eyeDropperState,
        onChangeEyeDropper,
        pickColorEnd,
        applyPickColor,
    }
}

export function usePickColor() {
    const command = useCommand()
    const { eyeDropperState } = useEyeDropper()

    useEffect(() => {
        command.DEPRECATED_invokeBridge(ApplyPickedColorCommand)
        command.commitUndo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eyeDropperState?.isExecutePickColor])
}
