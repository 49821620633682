/**
 * @owner: chenxiangbj@kanyun.com
 */
import classNames from 'classnames'
import { ReactNode, useMemo } from 'react'
import { WKUserAvatar, WKUserAvatarProps } from '../wk-avatar/wk-user-avatar'
import { WKTypography } from '../wk-typography'
import { translation } from './index.translation'

export const UserInfoBlock = ({
    userBrief,
    self,
    tags,
    className,
    nameSize,
    disableEmailCopy,
    avatarSize,
    nameClass,
}: {
    userBrief: WKUserAvatarProps['userInfo'] & { email: string }
    self?: boolean
    tags?: ReactNode
    className?: string
    disableEmailCopy?: boolean
    nameSize?: 14 | 12
    avatarSize?: 32 | 40 | 24
    nameClass?: string
}) => {
    const emailString = `${userBrief.email} ${userBrief.waitRegister ? translation('PendingJoin') : ''}`
    const computedNameClass = useMemo(() => {
        switch (nameSize) {
            case 12:
                return 'wk-text-12'
            case undefined:
            case 14:
                return 'wk-text-14'
        }
    }, [nameSize])
    return (
        <div className={classNames(className, `flex items-center max-w-full`)}>
            <WKUserAvatar size={avatarSize ?? 32} userInfo={userBrief} />
            <div className="flex flex-col flex-1 pl-2 overflow-hidden">
                {!userBrief.waitRegister && (
                    <div
                        data-testid="user-info-block-name"
                        className={classNames(`truncate w-min max-w-full wk-font-medium`, computedNameClass, nameClass)}
                    >
                        {userBrief.nickname}{' '}
                        {self && (
                            <WKTypography.Text color="placeholder" data-testid="user-info-block-self">
                                {translation('You')}
                            </WKTypography.Text>
                        )}
                        {tags}
                    </div>
                )}
                <WKTypography.Text
                    color="placeholder"
                    className={classNames('w-fit wk-break-word', disableEmailCopy && 'truncate')}
                    copyable={!disableEmailCopy}
                >
                    {emailString}
                </WKTypography.Text>
            </div>
        </div>
    )
}
