/* eslint-disable no-restricted-imports */
import { ToggleShowCommentCommand } from '@wukong/bridge-proto'
import { useCallback } from 'react'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { CommentSearchDOM, CommentSearchDOMProps } from './comment-search-dom'

export function CommentSearch(props: Omit<CommentSearchDOMProps, 'isHideComment' | 'onClickHideComment'>) {
    const commandInvoker = useCommand()
    const showComment = useViewState('showComments')

    const onClickHideComment = useCallback(() => {
        commandInvoker.DEPRECATED_invokeBridge(ToggleShowCommentCommand)
    }, [commandInvoker])

    return <CommentSearchDOM isHideComment={!showComment} onClickHideComment={onClickHideComment} {...props} />
}
