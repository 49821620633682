import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import {
    NodePreviewSubscribeCommand,
    NodePreviewUnsubscribeCommand,
    Wukong,
    ZoomToFitNodesAtMaxScaleByIdsCommand,
} from '@wukong/bridge-proto'
import { default as classNames, default as classnames } from 'classnames'
import { useCallback, useEffect, useMemo } from 'react'
import { useWindowSize } from 'react-use'
import { Checkbox, DraggablePopupV2, Scrollbar, WKButton } from '../../../../../../ui-lib/src'
import { useRightPanelWidth } from '../../../../main/layout/layout-context'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { ExportSetting } from '../inspect/dev-mode-inspect-export'
import { BatchExportItem } from './batch-export-item'
import style from './batch-export.module.less'
import { useDevModeBatchExport, useShowDevModeBatchExport } from './use-dev-mode-batch-export'
import FileType = Wukong.DocumentProto.FileType

const useDevModeBatchExportModalPosition = () => {
    const rightPanelWidth = useRightPanelWidth()
    const { width } = useWindowSize()
    return { top: 8, left: width - rightPanelWidth - 9 }
}

export const DevModeBatchExportModal = () => {
    const { closeBatchExport } = useDevModeBatchExport()
    const { showBatchExport } = useShowDevModeBatchExport()
    const position = useDevModeBatchExportModalPosition()

    return (
        <DraggablePopupV2
            visible={showBatchExport}
            onCancel={closeBatchExport}
            position={position}
            style={{ zIndex: 11 }}
            header={
                <div className={classnames([style.batchExportModalTitle, 'wk-text-12', 'wk-font-semibold'])}>
                    {translation('Export')}
                </div>
            }
            testId="dev-mode-batch-export-modal"
            closeTestId="dev-mode-batch-export-modal-close"
            bodyClassName="p-0"
            styleType="editor"
            positionRightBase
            width={280}
            footer={null}
        >
            <BatchExportItemContainer />
        </DraggablePopupV2>
    )
}

const BatchExportItemContainer = () => {
    const command = useCommand()
    const { height } = useWindowSize()
    const {
        handleBatchExport,
        totalBatchExportItemContents,
        selectAllOrNone,
        select,
        unSelect,
        selected,
        quanlity,
        count,
        indeterminate,
        allChecked,
        exportSetting,
    } = useDevModeBatchExport()

    const exportViewState = useViewState('devModeInspectExportViewState')
    const scrollHeight = useMemo(() => {
        let exportSettingHeight = 40 + 36

        if (
            exportViewState?.fileType !== FileType.FILE_TYPE_SVG &&
            exportViewState?.fileType !== FileType.FILE_TYPE_PDF
        ) {
            const scaleSize = Object.keys(exportViewState?.scaleConfig ?? {}).length
            exportSettingHeight += 16 + scaleSize * 20 + (scaleSize - 1) * 8
            exportSettingHeight += 44 // 压缩高度
        }

        // 视窗高度 - 顶部导航栏高度 - 2*8px padding - 弹窗 title 高度 - 已选中 - 底部按钮 - 导出设置
        return height - 48 - 2 * 8 - 48 - 36 - 64 - exportSettingHeight
    }, [exportViewState?.fileType, exportViewState?.scaleConfig, height])

    const batchExportEnabled = useMemo(() => {
        if (count === 0 || quanlity === 0) {
            return false
        }

        if (!exportSetting || exportSetting.exportSettings.length === 0) {
            return false
        }

        return true
    }, [count, exportSetting, quanlity])

    const onClick = useCallback(
        (id: string) => {
            command.DEPRECATED_invokeBridge(ZoomToFitNodesAtMaxScaleByIdsCommand, { nodeIds: [id] })
        },
        [command]
    )

    useEffect(() => {
        command.DEPRECATED_invokeBridge(NodePreviewSubscribeCommand)

        return () => {
            command.DEPRECATED_invokeBridge(NodePreviewUnsubscribeCommand)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalBatchExportItemContents, command])

    return (
        <>
            {totalBatchExportItemContents.length > 0 ? (
                <>
                    <div className={classnames(style.totalCheckBox)} data-testid={'dev-mode-batch-export-total-check'}>
                        <Checkbox
                            checked={allChecked}
                            onChange={(flag) => selectAllOrNone(flag ? 'all' : 'none')}
                            indeterminate={indeterminate}
                            label={
                                <span className="ml-1">
                                    {translation('Selected', { count: count, quality: quanlity })}
                                </span>
                            }
                        />
                    </div>
                    <Scrollbar autoHeightMax={scrollHeight} autoHeight>
                        <div className={classNames(style.batchExportItemsContainer)}>
                            {totalBatchExportItemContents.map((config) => (
                                <BatchExportItem
                                    key={config.nodeIds.join('-')}
                                    config={config}
                                    selected={selected}
                                    select={select}
                                    unSelect={unSelect}
                                    onClick={onClick}
                                />
                            ))}
                        </div>
                    </Scrollbar>
                </>
            ) : (
                <article className="text-gray wk-text-12 py-6 px-4">{translation('NoSelectedLayers')}</article>
            )}

            <div className={classNames(style.batchExportSettingContainer)}>
                <span>{translation('Settings')}</span>
                <ExportSetting />
            </div>
            <BatchExportBtn disabled={!batchExportEnabled} handleBatchExport={handleBatchExport} />
        </>
    )
}

interface BatchExportBtnProps {
    disabled: boolean
    handleBatchExport: () => void
}

const BatchExportBtn = (props: BatchExportBtnProps) => {
    const { disabled, handleBatchExport } = props

    return (
        <footer className={classnames(style.batchExportModalFooter)}>
            <WKButton
                disabled={disabled}
                type="primary"
                onClick={handleBatchExport}
                dataTestId={'dev-mode-batch-export-btn'}
            >
                {translation('Export_synonyms1')}
            </WKButton>
        </footer>
    )
}
