import { Wukong } from '@wukong/bridge-proto'
import { domLocation, getCommitId } from '../../../../util/src'
import { openEnhancedIndexedDB } from '../../web-storage/indexed-db/storage'
import { IndexedDBName } from '../../web-storage/indexed-db/config'
import { getReplayId } from '../interface/replay'
import { memoryReportService } from '../memory-report'

export const RECORD_REPLAY_DB_VERSION = 1

const activeWorkers: Map<string, Promise<void>> = new Map()
const jobQueues: Map<
    string,
    {
        bridgeCall: Wukong.DocumentProto.IBridgeCall
        bridgeCallIdx: number
    }[]
> = new Map()
const failedToSetDBs = new Set<string>()

export async function createRecordingDb(dbName: string, docId: string) {
    const db = await openEnhancedIndexedDB({
        name: {
            name: IndexedDBName.RecordReplay,
            suffix: `_${getReplayId(dbName)}`,
        },
        version: RECORD_REPLAY_DB_VERSION,
        callback: {
            upgrade: (database) => {
                database.createObjectStore('BridgeCall')
            },
            blocked: () => {
                console.warn('open RecordReplay database blocked')
            },
        },
    })

    const tx = db.transaction(['BridgeCall'], 'readwrite')

    await tx.objectStore('BridgeCall').put(
        {
            action: Wukong.DocumentProto.BridgeAction.BRIDGE_ACTION_OPEN_DOC,
            docId,
            timestamp: new Date().getTime(),
            url: domLocation().href,
            perfNow: performance.now(),
            release: getCommitId(),
        },
        1
    )
}

export async function openRecordingDb(dbName: string) {
    const db = await openEnhancedIndexedDB({
        name: {
            name: IndexedDBName.RecordReplay,
            suffix: `_${getReplayId(dbName)}`,
        },
        version: RECORD_REPLAY_DB_VERSION,
        callback: {
            upgrade: (database, oldVersion) => {
                if (oldVersion === 0) {
                    database.createObjectStore('BridgeCall')
                } else {
                    throw new Error('recording version mismatch: ' + oldVersion)
                }
            },
            blocked: () => {
                console.warn('open RecordReplay database blocked')
            },
        },
    })

    return db
}

export function recordBridgeCall(dbName: string, bridgeCallIdx: number, bridgeCall: Wukong.DocumentProto.IBridgeCall) {
    if (bridgeCall.code) {
        memoryReportService.recordLastPeriodWasmCall(bridgeCall.code)
    }
    if (failedToSetDBs.has(dbName)) {
        return
    }
    let queue = jobQueues.get(dbName)
    if (!queue) {
        jobQueues.set(dbName, (queue = []))
    }
    if (queue.length > 88000) {
        return
    }
    queue.push({ bridgeCallIdx, bridgeCall })
    if (!activeWorkers.get(dbName)) {
        activeWorkers.set(dbName, worker(dbName))
    }
}

async function worker(dbName: string) {
    try {
        const db = await openRecordingDb(dbName)
        while (true) {
            const tx = db.transaction(['BridgeCall'], 'readwrite')
            const store = tx.objectStore('BridgeCall')
            const queue = jobQueues.get(dbName)!
            if (!queue) {
                return
            }
            if (queue.length === 0) {
                return
            }
            const copied = [...queue]
            queue.length = 0

            for (const { bridgeCall, bridgeCallIdx } of copied) {
                await store.put(bridgeCall, bridgeCallIdx)
            }
        }
    } catch (e) {
        console.error('failed to save bridge calls to recording', e)
        failedToSetDBs.add(dbName)
        indexedDB.deleteDatabase(dbName)
        jobQueues.delete(dbName)
    } finally {
        activeWorkers.delete(dbName)
    }
}

export const cleanReplayStaticVariables = () => {
    failedToSetDBs.clear()
    jobQueues.clear()
    activeWorkers.clear()
}
