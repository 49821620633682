import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    MboXZt: '属性',
    EKRUlL: '代码',
} as const

export const enTranslation = {
    MboXZt: 'Properties',
    EKRUlL: 'Code',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
