export interface FilePickerConfig {
    readonly multiple: boolean
    readonly accept: string
}

const DefaultPickerConfig: FilePickerConfig = {
    multiple: false,
    accept: '*/*',
}

export function filePicker(config: FilePickerConfig = DefaultPickerConfig): Promise<FileList | null> {
    return new Promise((resolve) => {
        const input = window.document.createElement('input')
        input.type = 'file'
        input.multiple = config.multiple
        input.accept = config.accept
        input.onchange = () => {
            input.onchange = null
            resolve(input.files)
        }

        input.click()
    })
}
