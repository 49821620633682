import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    DashCap: '虚线样式',
    None: '默认',
    Square: '方形',
    Round: '圆形',
} as const

export const enTranslation = {
    DashCap: 'Dash cap',
    None: 'None',
    Square: 'Square',
    Round: 'Round',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
