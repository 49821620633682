import type { CommandInvoker } from '../../document/command/command-invoker'
import { ImageDownloadContext } from '../../document/command/image-download-context'
import type { ExecutableDocumentRoot, ReadonlyDocumentRoot } from '../../document/document-bridge/document-root'
import { Bridge } from '../../kernel/bridge/bridge'
import type { LibraryComponentService } from '../../ui'
import { ExecutableClipboardService } from '../clipboard/clipboard-service/clipboard-service-interface'
import { UserConfigService } from '../user-config/user-config-service'

export interface HandlerContext {
    readonly documentRoot: ExecutableDocumentRoot
    readonly commandInvoker: CommandInvoker
    readonly imageDownloadContext: ImageDownloadContext
    readonly clipboardService: ExecutableClipboardService
    readonly libraryComponentService: LibraryComponentService
    readonly bridge: Bridge
    readonly userConfigService: UserConfigService
}

export type Handler<E extends Event | any | null = null, R = void> = (ctx: HandlerContext, event: E, others?: any) => R

export class HandlerProvider {
    private readonly context: HandlerContext
    constructor(
        documentRoot: ReadonlyDocumentRoot,
        commandInvoker: CommandInvoker,
        clipboardService: ExecutableClipboardService,
        public bridge: Bridge,
        libraryComponentService: LibraryComponentService,
        userConfigService: UserConfigService,
        imageDownloadContext: ImageDownloadContext
    ) {
        this.context = {
            documentRoot,
            commandInvoker,
            clipboardService,
            bridge,
            libraryComponentService,
            userConfigService,
            imageDownloadContext,
        }
    }

    public handle<E extends Event | any | null = null, R = void>(handler: Handler<E, R>, event: E, others?: any) {
        const ret = handler(this.context, event, others)

        return ret
    }
}
