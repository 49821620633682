import { throttle } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import styles from '../wk-popover/internal/index.module.less'

export const usePopoverContextHook = () => {
    // popover组件弹窗状态
    const [popoverState, setPopoverState] = useState<string[]>([])
    const [popoverCounter, setPopoverCounter] = useState(0)

    const openPopover = useCallback(() => {
        const id = `popover-${popoverCounter}`
        setPopoverState([id])
        setPopoverCounter((state) => state + 1)
        return id
    }, [popoverCounter])

    const closePopover = useCallback((id: string) => {
        setPopoverState((state) => state.filter((item) => item !== id))
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onScroll = useCallback(
        throttle((e: Event) => {
            const target = e.target as HTMLDivElement
            if (target?.closest?.(`.${styles.popover}`)) {
                return
            }
            setPopoverState([])
        }, 50),
        []
    )

    useEffect(() => {
        if (!popoverState.length) {
            return
        }
        window.addEventListener('scroll', onScroll, true)
        return () => {
            window.removeEventListener('scroll', onScroll, true)
        }
    }, [onScroll, popoverState])

    return {
        popoverState,
        openPopover,
        closePopover,
    }
}
