import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    CopyLink: '复制链接',
    MaskAsRead: '标为已读',
    MaskAsUnread: '标为未读',
    Delete: '删除',
    CommentActions: '更多操作',
} as const

export const enTranslation = {
    CopyLink: 'Copy link',
    MaskAsRead: 'Mask as read',
    MaskAsUnread: 'Mask as unread',
    Delete: 'Delete',
    CommentActions: 'Comment actions',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
