/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { DeepRequired } from '../../../../../view-state-bridge'
import { useLayoutEffect, useRef, useState } from 'react'
import { useCanvasRect } from '../hooks/use-canvas-rect'

const CanvasMargin = 20
const PopupHeight = 32
const PopupMargin = 8

export function usePopupPosition(hoveringState: DeepRequired<Wukong.DocumentProto.IHyperlinkHoverPopupState>): {
    left: number
    top: number
    containerRef: React.RefObject<HTMLDivElement>
    positionType: 'top' | 'bottom'
    gapHeight: number
} {
    const canvasRect = useCanvasRect()
    const containerRef = useRef<HTMLDivElement>(null)
    const [containerWidth, setContainerWidth] = useState(0)

    useLayoutEffect(() => {
        if (containerRef.current) {
            setContainerWidth(containerRef.current.getBoundingClientRect().width)
        }
    }, [])

    let cameraLeft = hoveringState.cameraX
    if (cameraLeft < containerWidth / 2 + CanvasMargin) {
        cameraLeft = containerWidth / 2 + CanvasMargin
    } else if (cameraLeft + containerWidth / 2 + CanvasMargin > canvasRect.width) {
        cameraLeft = canvasRect.width - containerWidth / 2 - CanvasMargin
    }
    const left = canvasRect.left + cameraLeft

    let positionType: 'top' | 'bottom' = 'top'
    let cameraTop = hoveringState.cameraY - PopupHeight - hoveringState.boxHeight / 2 - PopupMargin
    if (cameraTop < CanvasMargin) {
        cameraTop = hoveringState.cameraY + hoveringState.boxHeight / 2 + PopupMargin
        positionType = 'bottom'
    }
    const top = canvasRect.top + cameraTop

    return {
        left,
        top,
        containerRef,
        positionType,
        gapHeight: PopupMargin + hoveringState.boxOutSet + 1,
    }
}
