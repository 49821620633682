import { getTranslationValue } from '../i18n'

export const zhTranslation = {
    ThatEmailAnd: '账号或密码错误',
    ThisEmailIs: '邮箱已注册',
    FailedToSend: '验证码配额不足',
    IncorrectVerificationCode: '验证码校验不通过',
    ThisEmailIs_synonyms1: '邮箱未注册',
    FailedToSend_synonyms1: '获取验证码过于频繁，',
    PleaseTryAgain: '请稍后再试',
    PleaseEnterA: '邮箱格式错误',
    TheAccountHas: '当前邮箱暂未开通试用，',
    IfYouWant: '如需申请试用请访问',
    OfficialSite: '官网',
    TheOperationIs: '操作过于频繁，',
    FailedToSend_synonyms1_synonyms2: '获取验证码失败，',
    TheAccountHas_synonyms1: '账号已被冻结，',
    WantToUnfreeze: '解除冻结请',
    ResetPassword: '重置密码',
    ssoOnlyLogin: '你的企业已设置需要使用 SAML SSO 登录',
    googleOnlyLogin: '你的企业已设置需要使用 Google 账号登录',
} as const

export const enTranslation = {
    ThatEmailAnd: 'That email and password combination is incorrect.',
    ThisEmailIs: 'This email is already taken',
    FailedToSend: 'An error occurred while sending verification code.',
    IncorrectVerificationCode: 'Incorrect verification code',
    ThisEmailIs_synonyms1: 'This email has not been registered.',
    FailedToSend_synonyms1: 'Failed to send verification code.',
    PleaseTryAgain: 'please try again later',
    PleaseEnterA: 'Enter a valid email address.',
    TheAccountHas: 'The account has not been opened for trial yet.',
    IfYouWant: 'If you want to apply for trial, please visit',
    OfficialSite: 'Official site',
    TheOperationIs: 'The operation is too frequent.',
    FailedToSend_synonyms1_synonyms2: 'An error occurred while sending verification code.',
    TheAccountHas_synonyms1: 'The account has been frozen.',
    WantToUnfreeze: 'Want to unfreeze it?',
    ResetPassword: 'Reset password',
    ssoOnlyLogin: 'Your organization requires logging in with SAML SSO.',
    googleOnlyLogin: 'Your organization requires logging in with a Google account.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
