import { Wukong } from '@wukong/bridge-proto'

const OperateSystemType = Wukong.DocumentProto.OperateSystemType
export const OSTypeString = {
    [OperateSystemType.OPERATE_SYSTEM_TYPE_NONE]: 'unknown',
    [OperateSystemType.OPERATE_SYSTEM_TYPE_WINDOWS]: 'windows',
    [OperateSystemType.OPERATE_SYSTEM_TYPE_MAC]: 'mac',
    [OperateSystemType.OPERATE_SYSTEM_TYPE_LINUX]: 'linux',
    [OperateSystemType.OPERATE_SYSTEM_TYPE_IOS]: 'ios',
    [OperateSystemType.OPERATE_SYSTEM_TYPE_ANDROID]: 'android',
}

export enum BrowserType {
    UNKNOWN,
    CHROME,
    SAFARI,
    FIREFOX,
    EDGE,
    IE,
}

export const getOS = () => {
    const userAgent = window.navigator.userAgent.toLowerCase()
    const macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i
    const windowsPlatforms = /(win32|win64|windows|wince)/i
    const iosPlatforms = /(iphone|ipad|ipod)/i
    let os = OperateSystemType.OPERATE_SYSTEM_TYPE_NONE

    if (macosPlatforms.test(userAgent)) {
        os = OperateSystemType.OPERATE_SYSTEM_TYPE_MAC
    } else if (iosPlatforms.test(userAgent)) {
        os = OperateSystemType.OPERATE_SYSTEM_TYPE_IOS
    } else if (windowsPlatforms.test(userAgent)) {
        os = OperateSystemType.OPERATE_SYSTEM_TYPE_WINDOWS
    } else if (/android/.test(userAgent)) {
        os = OperateSystemType.OPERATE_SYSTEM_TYPE_ANDROID
    } else if (!os && /linux/.test(userAgent)) {
        os = OperateSystemType.OPERATE_SYSTEM_TYPE_LINUX
    }

    return os
}

export const getOSType = () => {
    const os = getOS()
    return OSTypeString[os]
}

export const isWindows = () => getOS() === OperateSystemType.OPERATE_SYSTEM_TYPE_WINDOWS

export const isLinux = () => getOS() === OperateSystemType.OPERATE_SYSTEM_TYPE_LINUX

export const isWindowsOrLinux = () => isWindows() || isLinux()

export const isMac = () => getOS() === OperateSystemType.OPERATE_SYSTEM_TYPE_MAC

export const isSafari = () => navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1

export const isChrome = () => navigator.userAgent.indexOf('Chrome') !== -1

export const isEdge = () => navigator.userAgent.indexOf('Edg') !== -1

export const isFirefox = () => navigator.userAgent.toLowerCase().indexOf('firefox') !== -1

export const isDesktop = () => {
    const os = getOS()
    return (
        os === OperateSystemType.OPERATE_SYSTEM_TYPE_WINDOWS ||
        os === OperateSystemType.OPERATE_SYSTEM_TYPE_MAC ||
        os === OperateSystemType.OPERATE_SYSTEM_TYPE_LINUX
    )
}

export const getEdgeVersion = () => {
    // Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/115.0.0.0 Safari/537.36 Edg/115.0.1901.188
    if (isEdge()) {
        const match = /Edg\/(\d+)/.exec(navigator.userAgent)
        return match?.[1] ? +match[1] : null
    }
    return null
}

export const getChromeVersion = () => {
    // Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36
    if (isChrome()) {
        const match = /Chrome\/(\d+)/.exec(navigator.userAgent)
        return match?.[1] ? +match[1] : null
    }
    return null
}

export const getSafariVersion = () => {
    // Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Safari/605.1.15
    if (isSafari()) {
        const match = /Version\/(\d+\.\d+)/.exec(navigator.userAgent)
        return match?.[1] ? +match[1] : null
    }
    return null
}

export const getFirefoxVersion = () => {
    // Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:123.0) Gecko/20100101 Firefox/123.0
    if (isFirefox()) {
        const match = /Firefox\/(\d+\.\d+)/.exec(navigator.userAgent)
        return match?.[1] ? +match[1] : null
    }
    return null
}

export const isEditorSupport = () => {
    if (isDesktop()) {
        // edge 需要在 chrome 和 safari之前检测
        const edgeVersion = getEdgeVersion()
        if (edgeVersion) {
            return edgeVersion >= 88
        }

        const chromeVersion = getChromeVersion()
        if (chromeVersion) {
            return chromeVersion >= 87
        }

        const safariVersion = getSafariVersion()
        if (safariVersion) {
            return safariVersion >= 14.1
        }

        // 其他浏览器(如火狐)暂不处理,返回true
        return true
    }
    // 移动端浏览器不处理,返回true
    return true
}

export const isCypress = () => {
    return window.navigator.userAgent.includes('Cypress')
}
