/* eslint-disable no-restricted-imports */
import { UpdateSelectionFillPaintsCommand, Wukong } from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { useCallback, useMemo, useState } from 'react'
import { Position } from '../../../../../../ui-lib/src'
import { CommitType } from '../../../../document/command/commit-type'
import { cmdChangePopupState } from '../../../../document/command/document-command'
import { cmdChangeAttrPanelStyleEditorState } from '../../../../document/command/node-props-command'
import { cmdUpdateSelectedReverseIndex } from '../../../../document/command/page-command'
import {
    AttrPanelStyleEditorState,
    ColorStop,
    ImagePaint,
    Paint,
    PopupState,
    PopupStateType,
    RGB,
    Transform,
} from '../../../../document/node/node'
import { DeepRequired, useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { isGradientType } from '../../../utils/switch-paint-type'
import { useGradientEdit } from '../../../utils/use-gradient-edit'
import { useImageEdit } from '../../../utils/use-image-edit'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { getRightColorStopIndex } from '../blend/blend-gradient/gradient-picker'
import { getDefaultSolidPaint } from '../color-interaction/utils'
import { changePaintType } from '../common/use-paint-type'
import { useFillViewModal } from './hooks/use-fill-model'

import PaintType = Wukong.DocumentProto.PaintType

export function useFills() {
    const popupState = useViewState('popupState')
    const attrPanelStyleEditorState = useViewState('attrPanelStyleEditorState')
    const { startEditingImage, endEditingImage } = useImageEdit()
    const { startEditingGradient, endEditingGradient } = useGradientEdit()

    const [stylePosition, setStylePosition] = useState<Position>()
    const openStyle = useMemo(() => {
        return (
            popupState?.type === PopupStateType.POPUP_STATE_TYPE_PAINT_STYLE &&
            attrPanelStyleEditorState?.openFromModule ===
                Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_FILL
        )
    }, [attrPanelStyleEditorState?.openFromModule, popupState?.type])

    const command = useCommand()
    const { modelState, modelCommand } = useFillViewModal()
    const selectedFills = useViewState('selectedFills')

    const setDocumentState = useCallback(
        (openPopup: boolean) => {
            let _attrPanelStyleEditorState: AttrPanelStyleEditorState | null = null
            const _popupState: PopupState = {
                type: PopupStateType.POPUP_STATE_TYPE_NONE,
                reciprocalIndex: -1,
                multiPopup: [],
            }
            if (openPopup) {
                _attrPanelStyleEditorState = {
                    openFromModule: Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_FILL,
                    openFromNodeId: [...(modelState?.passIds ?? [])],
                    selectReverseIndex: [],
                    isCreate: false,
                }
                _popupState.type = PopupStateType.POPUP_STATE_TYPE_PAINT_STYLE
            }

            command.invoke(cmdChangeAttrPanelStyleEditorState, _attrPanelStyleEditorState)
            command.invoke(cmdChangePopupState, _popupState)
        },
        [command, modelState?.passIds]
    )

    const switchOpenStyleState = useCallback(
        (state?: boolean) => {
            const _openStyle = state !== undefined ? state : !openStyle
            setDocumentState(_openStyle)
        },
        [openStyle, setDocumentState]
    )

    const styleInfo = useMemo(() => {
        if (modelState.type == 'style' && modelState.fillStyleId && modelState.paintStyleNode) {
            return [modelState.paintStyleNode]
        }
        return []
    }, [modelState])

    const tryIntoGradientImageStateFromStyle = useCallback(() => {
        if (modelState.type !== 'normal' || modelState.paints?.length !== 1) {
            return
        }
        const paint = modelState.paints[0]
        const nodeId = modelState.passIds?.[0]
        if (
            paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_LINEAR ||
            paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_RADIAL ||
            paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_ANGULAR ||
            paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_DIAMOND
        ) {
            const index = getRightColorStopIndex(paint.gradientStops)
            startEditingGradient(
                nodeId,
                [
                    {
                        type: Wukong.DocumentProto.PaintPositionType.PAINT_POSITION_TYPE_FILL,
                        index: 0,
                    },
                ],
                index
            )
        } else if (paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_IMAGE_PAINT) {
            startEditingImage([nodeId], Wukong.DocumentProto.EditingPaintType.EDITING_PAINT_TYPE_FILL, 0, true)
        }
    }, [modelState.paints, modelState.passIds, modelState.type, startEditingGradient, startEditingImage])

    const onClickStyle = useCallback(
        (e: any) => {
            const { top } = e.currentTarget.getBoundingClientRect()
            const { clientWidth } = document.documentElement
            setStylePosition({ top: top - 6, left: clientWidth - 248 })
            switchOpenStyleState()
            if (!openStyle) {
                tryIntoGradientImageStateFromStyle()
            }
        },
        [openStyle, switchOpenStyleState, tryIntoGradientImageStateFromStyle]
    )

    const isOpen = (index: number) => {
        if (popupState && popupState.type === PopupStateType.POPUP_STATE_TYPE_FILLS && modelState.type == 'normal') {
            return popupState.reciprocalIndex === index
        }
        return false
    }

    const updatePaintProperties = (obj: { [key: string]: any }, index: number) => {
        if (modelState.type !== 'normal') {
            return
        }
        if (obj.colorVar === undefined) {
            obj.colorVar = undefined
        }
        const nextPaint = modelState.paints.slice()
        if (obj.type !== undefined) {
            nextPaint.splice(index, 1, obj as Paint)
            modelCommand.changeFillPaints(nextPaint)
        } else {
            nextPaint[index] = { ...nextPaint[index], visible: true, ...obj }
            modelCommand.changeFillPaints(nextPaint)
        }
    }

    const onSelectChange = (itemsIndex: number[]) => {
        if (modelState.type !== 'normal') {
            return
        }
        command.invoke(cmdUpdateSelectedReverseIndex, 'fills', itemsIndex)
        command.commitUndo()
    }

    const onDragDone = (items: Paint[], selectedIndexList: number[]) => {
        command.invoke(cmdUpdateSelectedReverseIndex, 'fills', selectedIndexList)
        modelCommand.changeFillPaints(items)
        command.commitUndo()
    }

    const onClickSub = (index: number) => () => {
        if (modelState.type !== 'normal') {
            return
        }

        const newPaints = modelState.paints.slice()
        newPaints.splice(index, 1)
        if (popupState?.type === PopupStateType.POPUP_STATE_TYPE_FILLS) {
            command.invoke(cmdUpdateSelectedReverseIndex, 'clear', [])
        }
        modelCommand.changeFillPaints(newPaints)
        command.commitUndo()
    }

    const onClickAddPaint = () => {
        if (modelState.type === 'none' || modelState.type === 'style') {
            return
        }
        modelCommand.updateSelectionFillPaintForSameOrAdd()
        command.commitUndo()
    }

    const onClickTitle = () => {
        if (modelState.type === 'normal' && modelState.paints.length === 0) {
            onClickAddPaint()
        }
    }

    const onChangeColorStops = (index: number) => (v: ColorStop[], options?: InputOptionsForUndoSquash) => {
        updatePaintProperties({ gradientStops: v }, index)
        command.commitUndo(options?.commitType)
    }

    const onChangeTransform = (index: number) => (v: Transform) => {
        updatePaintProperties({ gradientTransform: v }, index)
        command.commitUndo()
    }

    const onChangeVisible = (index: number) => (visible: boolean) => {
        updatePaintProperties({ visible }, index)
        command.commitUndo()
    }

    const onChangeColor = (index: number) => (color: RGB, options?: InputOptionsForUndoSquash) => {
        updatePaintProperties({ color }, index)
        command.commitUndo(options?.commitType)
    }

    const onChangeOpacity = (index: number) => (value: string | number, options?: InputOptionsForUndoSquash) => {
        updatePaintProperties({ opacity: Number(value) }, index)
        command.commitUndo(options?.commitType)
    }

    const onChangeBlendMode = (index: number) => (value: string | number) => {
        updatePaintProperties({ blendMode: Number(value) }, index)
        command.commitUndo()
    }

    const onDetachColorVar = (index: number) => () => {
        updatePaintProperties({ colorVar: null }, index)
        command.commitUndo()
    }

    const onChangeColorVar = (index: number) => (colorVar: Wukong.DocumentProto.IVariableAliasData) => {
        updatePaintProperties({ ...getDefaultSolidPaint(), colorVar }, index)
        command.commitUndo()
    }

    const onChangePaints2ColorVar = (colorVar: Wukong.DocumentProto.IVariableAliasData) => {
        command.invokeBridge(
            CommitType.CommitUndo,
            UpdateSelectionFillPaintsCommand,
            Wukong.DocumentProto.UpdateSelectionPaintParam.create({
                paints: [{ ...getDefaultSolidPaint(), colorVar }],
                skipEmpty: false,
            })
        )
    }
    const onChangePaints2 = (paints: Wukong.DocumentProto.IPaint[]) => {
        command.invokeBridge(
            CommitType.CommitUndo,
            UpdateSelectionFillPaintsCommand,
            Wukong.DocumentProto.UpdateSelectionPaintParam.create({
                paints,
                skipEmpty: false,
            })
        )
        if (paints[0].type === PaintType.PAINT_TYPE_IMAGE_PAINT) {
            startEditingImage(
                modelState.passIds ?? [],
                Wukong.DocumentProto.EditingPaintType.EDITING_PAINT_TYPE_FILL,
                0
            )
            return
        }
        const nodeId = modelState.passIds?.[0]
        if (nodeId) {
            startEditingGradient(nodeId, [
                {
                    type: Wukong.DocumentProto.PaintPositionType.PAINT_POSITION_TYPE_FILL,
                    index: 0,
                },
            ])
        }
    }

    const onChangePaints = (paints: Wukong.DocumentProto.IPaint[]) => {
        modelCommand.changeFillPaints(paints as Paint[])
        command.commitUndo()
    }

    const canShowWasmGradientControl = useMemo(() => {
        return modelState.type == 'normal' && modelState.passIds.length === 1
    }, [modelState])

    const onChangePaintType =
        (index: number) => (nextPaintType: PaintType, paint: Wukong.DocumentProto.IPaint | undefined | null) => {
            if (modelState.type !== 'normal') {
                return
            }

            const currentPaintType = modelState.paints[index]?.type
            if (isNil(currentPaintType)) {
                return
            }

            const reciprocalIndex = index
            modelCommand.changeFillPaints(
                changePaintType(
                    modelState.paints as DeepRequired<Wukong.DocumentProto.IPaint>[],
                    index,
                    nextPaintType,
                    paint as DeepRequired<Wukong.DocumentProto.IPaint>
                )
            )

            if (nextPaintType === PaintType.PAINT_TYPE_IMAGE_PAINT) {
                startEditingImage(
                    modelState.passIds,
                    Wukong.DocumentProto.EditingPaintType.EDITING_PAINT_TYPE_FILL,
                    reciprocalIndex
                )
            } else if (currentPaintType === PaintType.PAINT_TYPE_IMAGE_PAINT) {
                endEditingImage()
            }

            if (canShowWasmGradientControl) {
                if (isGradientType(currentPaintType) && !isGradientType(nextPaintType)) {
                    endEditingGradient()
                } else if (isGradientType(nextPaintType) && !isGradientType(currentPaintType)) {
                    startEditingGradient(modelState.passIds[0], [
                        {
                            type: Wukong.DocumentProto.PaintPositionType.PAINT_POSITION_TYPE_FILL,
                            index: reciprocalIndex,
                        },
                    ])
                }
            }
            command.commitUndo()
        }

    const onChangeImagePaint = (index: number) => (data: ImagePaint, options?: InputOptionsForUndoSquash) => {
        updatePaintProperties(data, index)
        command.commitUndo(options?.commitType)
    }

    const onChangeModalVisible = (index: number) => (visible: boolean) => {
        if (modelState.type !== 'normal') {
            return
        }

        const currentPaintType = modelState.paints[index]?.type
        if (visible) {
            const reciprocalIndex = index
            command.invoke(cmdChangePopupState, {
                type: PopupStateType.POPUP_STATE_TYPE_FILLS,
                reciprocalIndex,
                multiPopup: [],
            })

            if (currentPaintType === PaintType.PAINT_TYPE_IMAGE_PAINT) {
                startEditingImage(
                    modelState.passIds,
                    Wukong.DocumentProto.EditingPaintType.EDITING_PAINT_TYPE_FILL,
                    reciprocalIndex
                )
            } else if (canShowWasmGradientControl && isGradientType(currentPaintType)) {
                startEditingGradient(modelState.passIds[0], [
                    {
                        type: Wukong.DocumentProto.PaintPositionType.PAINT_POSITION_TYPE_FILL,
                        index: reciprocalIndex,
                    },
                ])
            }
        } else if (isOpen(index)) {
            if (currentPaintType === PaintType.PAINT_TYPE_IMAGE_PAINT) {
                endEditingImage()
            } else if (isGradientType(currentPaintType)) {
                endEditingGradient()
            }
            command.invoke(cmdChangePopupState, {
                type: PopupStateType.POPUP_STATE_TYPE_NONE,
                reciprocalIndex: -1,
                multiPopup: [],
            })
        }
    }

    const isEmptyFill = modelState.type === 'none' || (modelState.type === 'normal' && modelState.paints.length === 0)

    const onChangeStyle = useCallback(
        (styleNodeId: string, needClosePopup: boolean) => {
            modelCommand.updateFillStyleId(styleNodeId)
            if (needClosePopup) {
                switchOpenStyleState(false)
            }
            command.commitUndo()
        },
        [command, modelCommand, switchOpenStyleState]
    )

    return {
        selectedFills,
        modelState,
        isEmptyFill,
        styleInfo,
        onSelectChange,
        onDragDone,
        onClickSub,
        onClickAddPaint,
        onClickTitle,
        onChangeVisible,
        onChangeColor,
        onChangeOpacity,
        onChangeImagePaint,
        onChangeModalVisible,
        isOpen,
        onChangePaintType,
        onChangeColorStops,
        onChangeTransform,
        onClickDeleteStyle: modelCommand.deleteSelectionFillStyleId,
        onClickCutLink: modelCommand.detachFillStyleId,
        onDetachColorVar,
        onChangeStyle,
        onChangeBlendMode,
        openStyle,
        stylePosition,
        onClickStyle,
        switchOpenStyleState,
        onChangeColorVar,
        onChangePaints2ColorVar,
        onChangePaints,
        onChangePaints2,
    }
}
