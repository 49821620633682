import { DELETE, GET, POST } from '@tutor/network-core'
import { getCommitId, queryStringify } from '../../../../util/src'
import { shouldApplyBetaForceUpgrade } from '../../utils/release'
import {
    CheckResultVO,
    ClientReleaseCreateVO,
    ClientReleaseGetVO,
    ReleaseConfigCreateVO,
    ReleaseConfigCreateVOV2,
    ReleaseConfigGetVO,
    ReleaseConfigGetVOV2,
} from '../interface/release'
import { BaseCommonRequest, InnerRequest } from './base-request'

/**
 * 查询强制升级配置
 *
 * * __method__: GET
 * * __path__: /wukong-api/{client}/admin/release-config
 * * __tags__: AdminController
 */
@GET
export class GetReleaseConfigsRequest extends InnerRequest<ReleaseConfigGetVO[]> {
    public override requestUrl() {
        return 'admin/release-config'
    }
}

/**
 * 根据场景类别查询强制升级配置
 *
 * * __method__: GET
 * * __path__: /wukong-api/{client}/admin/release-config?scene={scene}
 * * __tags__: AdminController
 */
@GET
export class GetReleaseConfigsRequestV2 extends InnerRequest<ReleaseConfigGetVOV2[]> {
    constructor(private readonly scene: string) {
        super()
    }

    public override requestUrl() {
        return `admin/release-config?${queryStringify({ scene: this.scene })}`
    }
}

/**
 * 添加强制升级配置
 *
 * * __method__: POST
 * * __path__: /wukong-api/{client}/admin/release-config
 * * __tags__: AdminController
 */
@POST
export class CreateReleaseConfigRequest extends InnerRequest<void> {
    constructor(private readonly body: ReleaseConfigCreateVO) {
        super()
    }

    public override requestUrl() {
        return 'admin/release-config'
    }

    public override requestBody() {
        return this.body
    }
}

/**
 * 添加强制升级配置
 *
 * * __method__: POST
 * * __path__: /wukong-api/{client}/admin/release-config
 * * __tags__: AdminController
 */
@POST
export class CreateReleaseConfigRequestV2 extends InnerRequest<void> {
    constructor(private readonly body: ReleaseConfigCreateVOV2) {
        super()
    }

    public override requestUrl() {
        return 'admin/release-config'
    }

    public override requestBody() {
        return this.body
    }
}

/**
 * 删除强制升级配置
 *
 * * __method__: DELETE
 * * __path__: /wukong-api/{client}/admin/release-config/{id}
 * * __tags__: AdminController
 */
@DELETE
export class DeleteReleaseConfigRequest extends InnerRequest<void> {
    constructor(private readonly id: number) {
        super()
    }

    public override requestUrl() {
        return `admin/release-config/${this.id}`
    }
}

/**
 * 查询客户端版本
 *
 * * __method__: GET
 * * __path__: /wukong-api/{client}/admin/client-release
 * * __tags__: AdminController
 */
@GET
export class GetAllClientReleasesRequest extends InnerRequest<ClientReleaseGetVO[]> {
    constructor(private readonly forLatest: boolean) {
        super()
    }

    public override requestUrl() {
        return `admin/client-release` + (this.forLatest ? '?forLatest=true' : '')
    }
}

/**
 * 新增客户端版本
 *
 * * __method__: POST
 * * __path__: /wukong-api/{client}/admin/client-release
 * * __tags__: AdminController
 */
@POST
export class CreateClientReleasesRequest extends InnerRequest<void> {
    constructor(private readonly body: ClientReleaseCreateVO) {
        super()
    }

    public override requestUrl() {
        return `admin/client-release`
    }

    public override requestBody() {
        return this.body
    }
}

/**
 * 检查客户端版本号是否可用, 分场景
 *
 * * __method__: GET
 * * __path__: /wukong-api/{client}/client-release/check/{release}?beta={beta}&scene={scene}
 * * __tags__: client-release-controller
 */
@GET
export class CheckClientReleaseRequestV2 extends BaseCommonRequest<CheckResultVO> {
    constructor(private readonly scene: string) {
        super()
    }

    public override requestUrl() {
        // 是否使用 beta 强升策略
        const forceLatestVersion = shouldApplyBetaForceUpgrade()
        return `client-release/check/${getCommitId() ?? ''}?${queryStringify({
            beta: forceLatestVersion,
            scene: this.scene,
        })}`
    }

    public override requestDidFailed(): boolean {
        return false
    }
}
