import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    Title: '在桌面客户端打开此文件',
    Confirm: '在客户端打开',
    Cancel: '忽略',
    Preferance: '总是在客户端打开文件',
} as const

export const enTranslation = {
    Title: 'Open this file in the desktop app?',
    Confirm: 'Open in app',
    Cancel: 'Dismiss',
    Preferance: 'Always open in the app',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
