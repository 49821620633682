import { TraceableAbortController, TraceableAbortSignal } from './traceable-abort-controller'
import { anySignal } from './abort-any'
import {
    createBrowserRouter,
    createMemoryRouter,
    DataStrategyFunction,
    DataStrategyMatch,
    RouteObject,
} from 'react-router-dom'

export type RouterOptions = Parameters<typeof createBrowserRouter>[1] | Parameters<typeof createMemoryRouter>[1]
type RouterDecorator = (routes: RouteObject[], options?: RouterOptions) => [RouteObject[], RouterOptions]

type DataFunctionValue = Response | NonNullable<unknown> | null
type DataFunctionReturnValue = Promise<DataFunctionValue> | DataFunctionValue

export function createSignalRouterDecorator(signal: TraceableAbortSignal): RouterDecorator {
    const ctrlMap = new Map<string, TraceableAbortController>()

    return function (routes: RouteObject[], options?: RouterOptions) {
        const fillLoader = (route: RouteObject): RouteObject =>
            Object.assign({}, route, {
                loader: route.loader ?? true,
                children: route.children?.map(fillLoader),
            })

        return [
            routes.map(fillLoader),
            Object.assign({}, options, {
                dataStrategy: async function ({ matches }: { matches: DataStrategyMatch[] }) {
                    // 当 root 级别的 controller 被 abort 时，则不再进行后续的 loader 执行
                    signal.throwIfAborted()

                    const matchRouteIds = new Set<string>(matches.map((m) => m.route.id))
                    const ctrlsToRemove = Array.from(ctrlMap.keys()).filter((k) => !matchRouteIds.has(k))

                    ctrlsToRemove.forEach((k) => {
                        ctrlMap.get(k)?.abort(`abort due to route id ${k} removed`)
                        ctrlMap.delete(k)
                    })

                    const matchesToLoad = matches.filter((m) => m.shouldLoad)
                    const results = await Promise.all(
                        matchesToLoad.map((match) => {
                            let ctrl = ctrlMap.get(match.route.id)

                            ctrl?.abort(`abort due to route id ${match.route.id} removed`)
                            ctrl = new TraceableAbortController(`route-${match.route.id}`)
                            ctrlMap.set(match.route.id, ctrl)

                            const routeSignal = anySignal([signal, ctrl.signal])

                            return match.resolve((handler: (ctx?: unknown) => DataFunctionReturnValue) => {
                                return handler({ signal: routeSignal })
                            })
                        })
                    )

                    return results.reduce(
                        (acc: Record<string, unknown>, result: unknown, i: number) =>
                            Object.assign(acc, {
                                [matchesToLoad[i].route.id]: result,
                            }),
                        {}
                    )
                } as DataStrategyFunction,
            }),
        ]
    }
}
