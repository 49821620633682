import {
    UpdateSelectionTextNodeDetachOpsizeFromFontSizeCommand,
    UpdateSelectionTextNodeFontVariationCommand,
    UpdateTextStyleNodeDetachOpsizeFromFontSizeCommand,
    UpdateTextStyleNodeFontVariationCommand,
    Wukong,
} from '@wukong/bridge-proto'
import classnames from 'classnames'
import { isNil } from 'lodash-es'
import { useCallback, useMemo, useState } from 'react'
import { Checkbox, Scrollbar, WKCollapse, WKCollapseItem } from '../../../../../../ui-lib/src'
import { onlyCapitalizeFirstLetter, useElementResize } from '../../../../../../util/src'
import { CommitType } from '../../../../document/command/commit-type'
import { StandardAxisTag2Name } from '../../../../space/app/team-font/internal/font-util'
import { DeepRequired } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { ScrubbableInputNumber } from '../../atom/inputs/scrubbable-input-number'
import { Value } from '../../atom/inputs/utils/type'
import { RangeV2 } from '../../atom/range-v2'
import { VariationState } from './hooks/use-text-state'
import styles from './text-setting-variable.module.less'
import { translation } from './text-setting-variable.translation'
import { fourByteStringToNumber, skFourByteTagToString } from './utils'
/*
Slant, Width, Weight, 自定义轴（以字体顺序为准）, Optical size。
*/
export type InstanceAxesInfo = DeepRequired<Wukong.DocumentProto.IVFontVariationInstanceAxes>

const SlntTag = fourByteStringToNumber('slnt')
const WeightTag = fourByteStringToNumber('wght')
const WidthTag = fourByteStringToNumber('wdth')
const OpticalSizeTag = fourByteStringToNumber('opsz')
const ItalicTag = fourByteStringToNumber('ital')

function getSortedAxes(variationInfo: InstanceAxesInfo[]): {
    sortedVisibleAxes: InstanceAxesInfo[]
    sortedHiddenAxes: InstanceAxesInfo[]
} {
    function findCertainTag(tag: number) {
        return variationInfo.find((item) => item.axisTag === tag)
    }

    function pushToSortedArray(
        info: InstanceAxesInfo,
        sortedOfVisible: InstanceAxesInfo[],
        sortedOfHidden: InstanceAxesInfo[]
    ) {
        if (info.hidden) {
            sortedOfHidden.push(info)
        } else {
            sortedOfVisible.push(info)
        }
    }

    const sortedAxesArrayVisible: InstanceAxesInfo[] = []
    const sortedAxesArrayHidden: InstanceAxesInfo[] = []

    const slntTag = findCertainTag(SlntTag)
    if (!isNil(slntTag)) {
        pushToSortedArray(slntTag, sortedAxesArrayVisible, sortedAxesArrayHidden)
    }
    const widthTag = findCertainTag(WidthTag)
    if (!isNil(widthTag)) {
        pushToSortedArray(widthTag, sortedAxesArrayVisible, sortedAxesArrayHidden)
    }
    const weightTag = findCertainTag(WeightTag)
    if (!isNil(weightTag)) {
        pushToSortedArray(weightTag, sortedAxesArrayVisible, sortedAxesArrayHidden)
    }

    const filteredAxes = variationInfo.filter(
        (item) =>
            item.axisTag != SlntTag &&
            item.axisTag != WeightTag &&
            item.axisTag != WidthTag &&
            item.axisTag != OpticalSizeTag &&
            item.axisTag != ItalicTag
    )
    filteredAxes.forEach((item) => {
        pushToSortedArray(item, sortedAxesArrayVisible, sortedAxesArrayHidden)
    })

    const opszTag = findCertainTag(OpticalSizeTag)
    if (!isNil(opszTag)) {
        pushToSortedArray(opszTag, sortedAxesArrayVisible, sortedAxesArrayHidden)
    }
    return { sortedVisibleAxes: sortedAxesArrayVisible, sortedHiddenAxes: sortedAxesArrayHidden }
}

export interface TextVariableProps {
    variationInfo: VariationState
    styleNodeId: string | undefined // 判断是否展示的样式节点的轴信息
    readonly: boolean
}

export function TextVariable(props: TextVariableProps) {
    const { variationInfo, styleNodeId, readonly } = props
    const { sortedVisibleAxes, sortedHiddenAxes } = getSortedAxes(variationInfo.variationAxisValue)

    const command = useCommand()
    const handleChangeValue = useCallback(
        (axisTag: number, axisName: string, value: number, options?: InputOptionsForUndoSquash) => {
            if (isNil(styleNodeId)) {
                command.DEPRECATED_invokeBridge(UpdateSelectionTextNodeFontVariationCommand, {
                    axisTag,
                    axisName,
                    value,
                })
            } else {
                command.DEPRECATED_invokeBridge(UpdateTextStyleNodeFontVariationCommand, {
                    styleId: styleNodeId,
                    fontVariation: { axisTag, axisName, value },
                })
            }
            command.commitUndo(options?.commitType)
        },
        [command, styleNodeId]
    )

    const handleChangeDetachOpsz = useCallback(
        (value: boolean) => {
            if (isNil(styleNodeId)) {
                command.DEPRECATED_invokeBridge(UpdateSelectionTextNodeDetachOpsizeFromFontSizeCommand, {
                    value: !value,
                })
            } else {
                command.DEPRECATED_invokeBridge(UpdateTextStyleNodeDetachOpsizeFromFontSizeCommand, {
                    styleId: styleNodeId,
                    detachOpticalSizeFromFontSize: !value,
                })
            }
            command.commitUndo()
        },
        [command, styleNodeId]
    )

    const detachOpticalSizeFromFontSizeState = useMemo(
        () => variationInfo.detachOpticalSizeFromFontSize,
        [variationInfo.detachOpticalSizeFromFontSize]
    )

    const sortedVisibleAxesItems = sortedVisibleAxes.map((axes) => (
        <TextVariableAxesItem
            key={axes.axisTag}
            axesInfo={axes}
            readonly={readonly}
            splitValues={variationInfo.variationAxisTickValues[skFourByteTagToString(axes.axisTag)].value}
            onChangeValue={handleChangeValue}
            onChangeDetachOpsz={handleChangeDetachOpsz}
            detachOpticalSizeFromFontSizeState={detachOpticalSizeFromFontSizeState}
        />
    ))

    const sortedHiddenAxesItems = sortedHiddenAxes.map((axes) => (
        <TextVariableAxesItem
            key={axes.axisTag}
            axesInfo={axes}
            readonly={readonly}
            splitValues={variationInfo.variationAxisTickValues[skFourByteTagToString(axes.axisTag)].value}
            onChangeValue={handleChangeValue}
            onChangeDetachOpsz={handleChangeDetachOpsz}
            detachOpticalSizeFromFontSizeState={detachOpticalSizeFromFontSizeState}
        />
    ))

    const [element, setElement] = useState<HTMLDivElement | null>(null)
    const { resizeHeight } = useElementResize({
        element,
        maxContainerHeight: 382,
        otherHeight: 0,
    })

    return (
        <Scrollbar autoHeight autoHeightMax={resizeHeight}>
            <div ref={(el) => setElement(el)}>
                <div className={styles.sortedVisibleContainer}>{sortedVisibleAxesItems}</div>
                {sortedHiddenAxes.length > 0 && (
                    <div className={styles.sortedHiddenContainer}>
                        <WKCollapse selectionMode="single">
                            <WKCollapseItem title={translation('AdditionalAxes')} value="1" disableHeaderHover>
                                <div className={styles.sortedHiddenItems} data-testid={'text-variable-axes-hidden'}>
                                    {sortedHiddenAxesItems}
                                </div>
                            </WKCollapseItem>
                        </WKCollapse>
                    </div>
                )}
            </div>
        </Scrollbar>
    )
}

function TextVariableAxesItem(props: {
    axesInfo: InstanceAxesInfo
    splitValues: number[]
    readonly: boolean
    onChangeValue: (axisTag: number, axisName: string, value: number, options?: InputOptionsForUndoSquash) => void
    onChangeDetachOpsz: (value: boolean) => void
    detachOpticalSizeFromFontSizeState: Wukong.DocumentProto.VDetachOpticalSizeFromFontSize
}) {
    const {
        axesInfo,
        splitValues: splitValues_,
        detachOpticalSizeFromFontSizeState,
        onChangeValue,
        onChangeDetachOpsz,
        readonly,
    } = props

    const axesName = useMemo(() => {
        const axisTagName = skFourByteTagToString(axesInfo.axisTag)
        if (['slnt', 'opsz', 'wght', 'wdth'].includes(axisTagName)) {
            return StandardAxisTag2Name[axisTagName as keyof typeof StandardAxisTag2Name]
        } else {
            return onlyCapitalizeFirstLetter(axisTagName)
        }
    }, [axesInfo.axisTag])

    const stepSize = useMemo(() => {
        const axisRange = axesInfo.maxValue - axesInfo.minValue
        if (axisRange <= 3) {
            return 0.01
        } else if (axisRange <= 6) {
            return 0.1
        } else {
            return 1
        }
    }, [axesInfo.maxValue, axesInfo.minValue])

    const splitValues = useMemo(() => {
        const indexSet = new Set()
        for (let i = 0; i < splitValues_.length; i++) {
            if (!indexSet.has(i)) {
                for (
                    let j = i + 1;
                    j < splitValues_.length && !(splitValues_[j] - splitValues_[i] > 15 * stepSize);
                    j++
                ) {
                    indexSet.add(i)
                }
            }
        }
        return splitValues_.filter((_, index) => !indexSet.has(index))
    }, [splitValues_, stepSize])

    return (
        <>
            <div className={styles.singleAxesDesc}>
                <span className={styles.text}>{axesName}</span>
                <div className="w-16 h-7">
                    <ScrubbableInputNumber
                        disabled={readonly}
                        value={axesInfo.value}
                        isMixed={axesInfo.isMixed}
                        onChange={(value: Value, options?: InputOptionsForUndoSquash) =>
                            onChangeValue(axesInfo.axisTag, axesInfo.name, value as number, options)
                        }
                        min={axesInfo.minValue}
                        max={axesInfo.maxValue}
                        leftScrubbable={!axesInfo.isMixed}
                        rightScrubbable={!axesInfo.isMixed}
                        notUseUserConfig
                    />
                </div>
            </div>
            <div className={classnames(styles.sliderItem, { [styles.opszItem]: axesInfo.axisTag == OpticalSizeTag })}>
                <RangeV2
                    disabled={readonly}
                    curValue={axesInfo.value}
                    minValue={axesInfo.minValue}
                    maxValue={axesInfo.maxValue}
                    defaultValue={axesInfo.defaultValue}
                    spiltValues={splitValues}
                    isMixed={axesInfo.isMixed}
                    step={stepSize}
                    onChange={(v: number) =>
                        onChangeValue(axesInfo.axisTag, axesInfo.name, v, { commitType: CommitType.Noop })
                    }
                />
            </div>
            {axesInfo.axisTag == OpticalSizeTag && (
                <Checkbox
                    disabled={readonly}
                    checked={
                        detachOpticalSizeFromFontSizeState !=
                        Wukong.DocumentProto.VDetachOpticalSizeFromFontSize.V_DETACH_OPTICAL_SIZE_FROM_FONT_SIZE_DETACH
                    }
                    indeterminate={
                        detachOpticalSizeFromFontSizeState ==
                        Wukong.DocumentProto.VDetachOpticalSizeFromFontSize.V_DETACH_OPTICAL_SIZE_FROM_FONT_SIZE_MIXED
                    }
                    label={<span className={styles.text}>{translation('AutoSettingOptical')}</span>}
                    size="small"
                    onChange={onChangeDetachOpsz}
                />
            )}
        </>
    )
}
