import { Wukong } from '@wukong/bridge-proto'
import { RouteToken, domLocation } from '../../../../../util/src'
import { signalInterval } from '../../../../../util/src/abort-controller/timers'
import { TraceableAbortSignal } from '../../../../../util/src/abort-controller/traceable-abort-controller'
import { transaction } from '../../../../../util/src/abort-controller/traceable-transaction'
import { MessageContentType } from '../../../kernel/interface/notify'
import { WsPropertyChangeMessage, WsRelationChangeMessage } from '../../../kernel/notify/notify-message'
import { NotifyService } from '../../../kernel/notify/notify-service'
import { messageCenterService } from '../message-center'

const param = (orgId: string, userId: number) => {
    const filters = [
        {
            filterParameters: [
                {
                    name: 'entityType',
                    value: Wukong.NotifyProto.EntityType.ORG - 1 + '',
                },
                {
                    name: 'entityId',
                    value: orgId,
                },
            ],
        },
        {
            filterParameters: [
                {
                    name: 'entityType',
                    value: Wukong.NotifyProto.EntityType.USER - 1 + '',
                },
                {
                    name: 'entityId',
                    value: userId + '',
                },
            ],
        },
    ]
    return {
        [MessageContentType.PropertyChange]: {
            filters,
        },
        [MessageContentType.RelationChange]: {
            filters,
        },
    }
}

export class SpaceNotifyService {
    public connected = false

    public propertyChangeCallbackSet = new Set<(msg: Wukong.NotifyProto.BusinessEntityPropertiesChange) => void>()
    public addPropertyChangeCallback(callback: (msg: Wukong.NotifyProto.BusinessEntityPropertiesChange) => void) {
        this.propertyChangeCallbackSet.add(callback)
    }

    private relationChangeCallbackSet = new Set<(msg: Wukong.NotifyProto.BusinessEntityRelationChange) => void>()
    public addRelationChangeCallback(callback: (msg: Wukong.NotifyProto.BusinessEntityRelationChange) => void) {
        this.relationChangeCallbackSet.add(callback)
    }

    public addRelationChangeCallbackWithSignal(
        signal: TraceableAbortSignal,
        callback: (msg: Wukong.NotifyProto.BusinessEntityRelationChange) => void
    ) {
        const { act } = transaction(signal)

        act('addRelationChangeCallbackWithSignal', () => {
            this.relationChangeCallbackSet.add(callback)
            return () => this.relationChangeCallbackSet.delete(callback)
        })
    }

    private spaceIntervalCallbackSet = new Set<() => void>()
    public addSpaceIntervalCallback(callback: () => void) {
        this.spaceIntervalCallbackSet.add(callback)
    }

    constructor(private readonly notifyService: NotifyService, private readonly signal: TraceableAbortSignal) {
        notifyService.onBusinessMessageChangeWithSignal(this.signal, (proto) => {
            if (proto.businessCode == WsPropertyChangeMessage.code && this.connected) {
                this.propertyChangeCallbackSet.forEach((callback) =>
                    callback(WsPropertyChangeMessage.bodyType.decode(proto.payload))
                )
            }
        })

        notifyService.onBusinessMessageChangeWithSignal(this.signal, (proto) => {
            if (proto.businessCode == WsRelationChangeMessage.code && this.connected) {
                this.relationChangeCallbackSet.forEach((callback) =>
                    callback(WsRelationChangeMessage.bodyType.decode(proto.payload))
                )
            }
        })

        signalInterval(
            () => {
                if (!domLocation().pathname.includes(`/${RouteToken.File}/`) && this.connected) {
                    this.spaceIntervalCallbackSet.forEach((callback) => callback())
                }
            },
            1000 * 60,
            { signal: this.signal }
        )

        this.signal.addEventListener('abort', () => {
            this.connected = false
            messageCenterService.disconnectSocket()
            this.spaceIntervalCallbackSet.clear()
        })
    }

    connect(currOrgId: string, userId: number) {
        this.notifyService.sendSubscribeProto(currOrgId, param(currOrgId, userId))
        this.notifyService.onConnectChangeWithSignal(this.signal, (sessionId) => {
            if (sessionId) {
                this.notifyService.sendSubscribeProto(currOrgId, param(currOrgId, userId))
                this.connected = true
            }
        })
        messageCenterService.connectSocket(this.notifyService, currOrgId, userId)
    }
}
