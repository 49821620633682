/* eslint-disable no-restricted-imports */

import { domLocation, RouteToken } from '../../../../../util/src'
import { GetUnassignedTeamsInEnterprise } from '../../../kernel/request/team'
import { SpaceNotifyFlow } from './notify-flow'
import { getSpaceStore, setSpaceStore, SpaceStoreType, WorkspaceUnassignedStore } from './types'

export const generateWorkspaceUnassignedState = (set: setSpaceStore, get: getSpaceStore): WorkspaceUnassignedStore => {
    return {
        teams: [],
        loading: true,
        initData: async () => {
            set((state) => {
                state.workspaceUnassignedStore.loading = true
            })
            await get().workspaceUnassignedStore.refresh()
            set((state) => {
                state.workspaceUnassignedStore.loading = false
            })
        },
        refresh: async () => {
            const orgId = get().organizationStore.organization.id
            const teams = await new GetUnassignedTeamsInEnterprise(orgId).start()
            set((state) => {
                state.workspaceUnassignedStore.teams = teams
            })
        },
        clear: () => {
            set((state) => {
                state.workspaceUnassignedStore.loading = true
                state.workspaceUnassignedStore.teams = []
            })
        },
    }
}

const inWorkspaceUnassignedPage = () => domLocation().pathname.includes(`/${RouteToken.WorkspaceUnassigned}`)
export const syncWorkspaceUnassignedState = (store: SpaceStoreType, notifyFlow: SpaceNotifyFlow) => {
    // 协同
    notifyFlow.addRelationChangeCallbackWithoutAccess(() => {
        if (inWorkspaceUnassignedPage()) {
            store.getState().workspaceUnassignedStore.refresh()
        }
    })
    notifyFlow.addPropertyChangeCallbackWithoutDocAndUser(() => {
        if (inWorkspaceUnassignedPage()) {
            store.getState().workspaceUnassignedStore.refresh()
        }
    })
}
