import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    LineHeight: '行高',
    LetterSpacing: '字间距',
    ParagraphSpacing: '段间距',
    Decoration: '文字装饰',
    None: '无',
    Underline: '下划线',
    Strokethrough: '删除线',
    MoreSettings: '更多设置',
    TypeSettings: '文本设置',
} as const

export const enTranslation = {
    LineHeight: 'Line height',
    LetterSpacing: 'Letter spacing',
    ParagraphSpacing: 'Paragraph spacing',
    Decoration: 'Decoration',
    None: 'None',
    Underline: 'Underline',
    Strokethrough: 'Strokethrough',
    MoreSettings: 'Type settings',
    TypeSettings: 'Type settings',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
