import { Wukong } from '@wukong/bridge-proto'
import { isEqual } from 'lodash-es'
import { keepDecimal } from '../../../../../../../util/src'
import {
    InteractionActionType,
    MIXED_TYPE,
    type InteractionActionTypeWithMixed,
    type PrototypeInteractionWithNodeId,
} from '../../prototype-interaction/constants'
import { getActionData } from '../../prototype-interaction/utils'
import type { CubicBezierNumber } from './action-animation-preview'
import { getSpringDurationHandleOffset, isSpringEasingType } from './animation-easing-function/spring-util'
import { getDefaultEasingFunctionByType } from './use-action-animation-commands'

import InteractionActionPropValueType = Wukong.DocumentProto.InteractionActionPropValueType
import TransitionType = Wukong.DocumentProto.TransitionType
import EasingType = Wukong.DocumentProto.EasingType

// 对 ViewState(selectionPrototypeInteraction) 中动画相关字段进行二次封装，用于 ui 展示
export function useInteractionActionAnimationViewState(props: {
    isInteractionEventMixed: boolean
    isTransitionNodeIdsEmpty: boolean
    selectedInteractions: PrototypeInteractionWithNodeId[]
    actionIndex: number
}): Wukong.DocumentProto.VPrototypeInteractionActionAnimation | undefined {
    if (props.isTransitionNodeIdsEmpty || props.isInteractionEventMixed) {
        return undefined
    }

    const state = Wukong.DocumentProto.VPrototypeInteractionActionAnimation.create()

    const actionType = getActionData({
        interactions: props.selectedInteractions,
        actionIndex: props.actionIndex,
    }).type
    props.selectedInteractions.map((interaction) => {
        const action = interaction.actions[props.actionIndex]
        computeActionAnimationTransitionType(action, actionType, state.transitionType)
        computeActionAnimationTransitionShouldSmartAnimate(
            action,
            actionType,
            state.transitionType,
            state.transitionShouldSmartAnimate
        )
        computeActionAnimationEasingTypeAndFunction(
            action,
            actionType,
            state.transitionType,
            state.easingType,
            state.easingFunction
        )
        computeTransitionDuration(action, actionType, state.transitionType, state.transitionDuration)
        computeShowPreviewArea(state)
    })

    return state
}

// 带方向的动画类型
function computeActionAnimationTransitionType(
    action: Wukong.DocumentProto.IPrototypeAction,
    actionType: InteractionActionTypeWithMixed | undefined,
    state: Wukong.DocumentProto.ITransitionTypeProtoValue
): void {
    switch (actionType) {
        case InteractionActionType.NavigateTo:
        case InteractionActionType.ScrollTo:
        case InteractionActionType.OpenOverlay:
        case InteractionActionType.SwapOverlay:
        case MIXED_TYPE:
            state.show = true
            break
        default:
            return
    }

    switch (state.valueType) {
        case InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_EMPTY:
            state.valueType = InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_NORMAL
            state.value = action.transitionType!
            break
        case InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_NORMAL:
            if (state.value != action.transitionType) {
                state.valueType = InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_MIXED
            }
            break
        default:
            break
    }
}

// Animate matching layers
function computeActionAnimationTransitionShouldSmartAnimate(
    action: Wukong.DocumentProto.IPrototypeAction,
    actionType: InteractionActionTypeWithMixed | undefined,
    transitionType: Wukong.DocumentProto.ITransitionTypeProtoValue,
    state: Wukong.DocumentProto.ITransitionShouldSmartAnimate
): void {
    if (
        !transitionType.show ||
        transitionType.valueType != InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_NORMAL
    ) {
        state.show = false
        return
    }

    if (actionType !== InteractionActionType.NavigateTo) {
        state.show = false
        return
    }

    switch (transitionType.value) {
        case TransitionType.TRANSITION_TYPE_SLIDE_FROM_LEFT:
        case TransitionType.TRANSITION_TYPE_SLIDE_FROM_RIGHT:
        case TransitionType.TRANSITION_TYPE_SLIDE_FROM_TOP:
        case TransitionType.TRANSITION_TYPE_SLIDE_FROM_BOTTOM:
        case TransitionType.TRANSITION_TYPE_PUSH_FROM_LEFT:
        case TransitionType.TRANSITION_TYPE_PUSH_FROM_RIGHT:
        case TransitionType.TRANSITION_TYPE_PUSH_FROM_TOP:
        case TransitionType.TRANSITION_TYPE_PUSH_FROM_BOTTOM:
        case TransitionType.TRANSITION_TYPE_MOVE_FROM_LEFT:
        case TransitionType.TRANSITION_TYPE_MOVE_FROM_RIGHT:
        case TransitionType.TRANSITION_TYPE_MOVE_FROM_TOP:
        case TransitionType.TRANSITION_TYPE_MOVE_FROM_BOTTOM:
        case TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_LEFT:
        case TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_RIGHT:
        case TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_TOP:
        case TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_BOTTOM:
        case TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_LEFT:
        case TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_RIGHT:
        case TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_TOP:
        case TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_BOTTOM:
        case TransitionType.TRANSITION_TYPE_SCROLL_ANIMATE:
            state.show = true
            break
        default:
            return
    }

    switch (state.valueType) {
        case InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_EMPTY:
            state.valueType = InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_NORMAL
            state.value = action.transitionShouldSmartAnimate!
            break
        case InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_NORMAL:
            if (state.value !== action.transitionShouldSmartAnimate) {
                state.valueType = InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_MIXED
                state.value = false
            }
            break
        default:
            break
    }
}

function formatEasingFunction(easingFunction: number[]): CubicBezierNumber | undefined {
    return easingFunction.length >= 4
        ? (easingFunction.slice(0, 4).map((value) => keepDecimal(value, 2, true)) as CubicBezierNumber)
        : undefined
}

// 缓动效果 + 贝塞尔参数
function computeActionAnimationEasingTypeAndFunction(
    action: Wukong.DocumentProto.IPrototypeAction,
    actionType: InteractionActionTypeWithMixed | undefined,
    transitionType: Wukong.DocumentProto.ITransitionTypeProtoValue,
    easingTypeState: Wukong.DocumentProto.IEasingTypePropValue,
    easingFunctionState: Wukong.DocumentProto.IEasingFunctionPropValue
): void {
    if (
        !actionType ||
        actionType === MIXED_TYPE ||
        !transitionType.show ||
        (transitionType.valueType == InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_NORMAL &&
            transitionType.value == TransitionType.TRANSITION_TYPE_INSTANT_TRANSITION)
    ) {
        easingTypeState.show = false
        easingFunctionState.show = false
        return
    }

    const easingFunction =
        formatEasingFunction(action.easingFunction!) ?? getDefaultEasingFunctionByType(action.easingType!)!

    easingTypeState.show = true
    switch (easingTypeState.valueType) {
        case InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_EMPTY:
            easingTypeState.valueType = InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_NORMAL
            easingTypeState.value = action.easingType!

            easingFunctionState.valueType = InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_NORMAL
            easingFunctionState.value = easingFunction
            easingFunctionState.show =
                action.easingType === EasingType.EASING_TYPE_CUSTOM_CUBIC ||
                action.easingType === EasingType.EASING_TYPE_CUSTOM_SPRING
            break
        case InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_NORMAL:
            if (easingTypeState.value !== action.easingType || !isEqual(easingFunctionState.value, easingFunction)) {
                easingTypeState.valueType = InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_MIXED

                easingFunctionState.valueType = InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_MIXED
                easingFunctionState.show = false
            }
            break
        default:
            break
    }
}

// 时长
function computeTransitionDuration(
    action: Wukong.DocumentProto.IPrototypeAction,
    actionType: InteractionActionTypeWithMixed | undefined,
    transitionType: Wukong.DocumentProto.ITransitionTypeProtoValue,
    state: Wukong.DocumentProto.ITransitionDurationPropValue
): void {
    if (
        !actionType ||
        actionType === MIXED_TYPE ||
        !transitionType.show ||
        (transitionType.valueType == InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_NORMAL &&
            transitionType.value == TransitionType.TRANSITION_TYPE_INSTANT_TRANSITION)
    ) {
        state.show = false
        return
    }

    state.show = true
    switch (state.valueType) {
        case InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_EMPTY:
            state.valueType = InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_NORMAL
            state.value = getSpringDuration(action)
            break
        case InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_NORMAL: {
            if (state.value !== getSpringDuration(action)) {
                state.valueType = InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_MIXED
            }
            break
        }
        default:
            break
    }
}

// 是否展示动画预览区域（动画类型 + 缓动类型 + 时长 均处于显示状态 & 非 mixed）
function computeShowPreviewArea(state: Wukong.DocumentProto.VPrototypeInteractionActionAnimation): void {
    state.showPreviewArea =
        state.transitionType.show &&
        state.transitionType.valueType === InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_NORMAL &&
        state.easingType.show &&
        state.easingType.valueType === InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_NORMAL &&
        state.transitionDuration.show &&
        state.transitionDuration.valueType === InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_NORMAL
}

function getSpringDuration(action: Wukong.DocumentProto.IPrototypeAction) {
    if (isSpringEasingType(action.easingType) && action.easingFunction?.length === 4) {
        return Math.round(
            getSpringDurationHandleOffset(action.easingFunction as [number, number, number, number]) * 1000
        )
    }
    return action.transitionDuration
}
