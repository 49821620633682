import { AbortError } from './traceable-abort-controller'

export const ignoreWindowAbortError = () => {
    if (typeof window !== 'undefined') {
        const oldOnerror = window.onerror
        const oldOnunhandledrejection = window.onunhandledrejection

        // ignore throw error
        window.onerror = function (_0, _1, _2, _3, error) {
            if (isAbortError(error)) {
                console.error(error)
                return true
            }
            return false
        }

        // ignore error in promise
        window.onunhandledrejection = function (event) {
            if (isAbortError(event.reason)) {
                console.error(event.reason)
                event.preventDefault()
                return
            }
        }

        return () => {
            window.onerror = oldOnerror
            window.onunhandledrejection = oldOnunhandledrejection
        }
    } else {
        return () => {}
    }
}

export const isAbortError = (error: unknown): boolean => {
    return (
        (error instanceof Error || error instanceof DOMException || error instanceof AbortError) &&
        error.name === 'AbortError'
    )
}

export const throwIfAbort = (error: unknown) => {
    if (isAbortError(error)) {
        throw error
    }
}
