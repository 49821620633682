/* eslint-disable no-restricted-imports */
import { MouseEvent } from 'react'
import { RouteToken, domLocation } from '../../../../../util/src'
import { FolderIdWithDocVO } from '../../../kernel/interface/type'
import { RequestResponseErrorHandler } from '../../../kernel/request/error-handler'
import * as TeamRequest from '../../../kernel/request/team'
import { SpaceNotifyFlow } from './notify-flow'
import { SpaceStoreType, TeamPageStore, getSpaceStore, setSpaceStore } from './types'

export const generateTeamPageState = (set: setSpaceStore, get: getSpaceStore): TeamPageStore => {
    return {
        editUsers: [],
        folderDocs: [],
        docListMap: {},
        selectedInfo: {},
        docListMapLoading: true,
        editUserLoading: true,
        cleartSelectedInfo: (e: MouseEvent) => {
            const target = e.target as HTMLElement
            const mask = document.querySelector('[data-testid="space-context-menu"]')
            if (mask?.contains(target)) {
                return
            }
            set((state) => {
                state.teamPageStore.selectedInfo = {}
            })
        },
        setSelectedInfo: (info) => {
            set((state) => {
                state.teamPageStore.selectedInfo = info
            })
        },
        setTeamId: (teamId) => {
            get().teamPageStore.getEditUsers(teamId)
            get().teamPageStore.getDocListMap(teamId)
            set((state) => {
                state.teamPageStore.teamId = teamId
            })
        },
        getEditUsers: async (teamId?: string) => {
            if (teamId) {
                set((state) => {
                    state.teamPageStore.editUserLoading = true
                })
                const editUsers = await new TeamRequest.GetTeamEditUsers(teamId).start().catch((error) => {
                    if (RequestResponseErrorHandler(error).businessStatus === 8888) {
                        throw error
                    } else {
                        return []
                    }
                })
                set((state) => {
                    state.teamPageStore.editUsers = editUsers
                    state.teamPageStore.editUserLoading = false
                })
            } else
                set((state) => {
                    state.teamPageStore.editUsers = []
                    state.teamPageStore.editUserLoading = false
                })
        },
        _getDocListMap: async (teamId) => {
            if (teamId) {
                const folderDocs = await new TeamRequest.GetTeamFoldersAndDocs(teamId).start().catch((error) => {
                    if (RequestResponseErrorHandler(error).businessStatus === 8888) {
                        throw error
                    } else {
                        return [] as FolderIdWithDocVO[]
                    }
                })
                folderDocs.forEach((item) => {
                    item.documentWithAuthorityVOList.sort((a, b) => b.lastEditedTime - a.lastEditedTime)
                })

                if (teamId == get().teamPageStore.teamId) {
                    set((state) => {
                        state.teamPageStore.folderDocs = folderDocs
                    })
                }
            } else {
                set((state) => {
                    state.teamPageStore.folderDocs = []
                })
            }
        },
        getDocListMap: async (teamId) => {
            if (teamId) {
                set((state) => {
                    state.teamPageStore.docListMapLoading = true
                })
                await get().teamPageStore._getDocListMap(teamId)
                set((state) => {
                    state.teamPageStore.docListMapLoading = false
                })
            } else {
                set((state) => {
                    state.teamPageStore.docListMapLoading = false
                    state.teamPageStore.docListMap = {}
                })
            }
        },
    }
}

export const syncTeamPageState = (store: SpaceStoreType, notifyFlow: SpaceNotifyFlow) => {
    notifyFlow.addDocNameChangeCallback(() => {
        if (domLocation().pathname.includes(RouteToken.Team)) {
            const teamId = store.getState().teamPageStore.teamId
            store.getState().teamPageStore._getDocListMap(teamId)
        }
    })
    notifyFlow.addPropertyChangeCallbackWithoutDocAndUser(() => {
        const teamId = store.getState().teamPageStore.teamId
        store.getState().teamPageStore._getDocListMap(teamId)
    })
    notifyFlow.addDocRelationChangeCallback((msg) => {
        if (domLocation().pathname.includes(RouteToken.Team)) {
            const folderId = msg.folderId
            const teamId = store
                .getState()
                .teamsFoldersStore.teamsFolders.find((o) =>
                    o.folderWithAuthorityVOList.map((f) => f.id).includes(folderId)
                )?.teamWithAuthorityVO?.id
            store.getState().teamPageStore.getDocListMap(teamId)
            if (teamId) {
                store.getState().teamPageStore.getDocListMap(teamId)
            }
        }
    })
    notifyFlow.addSpaceIntervalCallback(() => {
        const teamId = store.getState().teamPageStore.teamId
        store.getState().teamPageStore._getDocListMap(teamId)
    })
}
