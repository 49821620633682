import { wkDialogConfirm } from '../../../../ui-lib/src'
import {
    DocWithAuthorityVO,
    FolderID,
    PayPlanType,
    PlanAndUserStateVO,
    ResourceType,
    RoleStatus,
    SeatType,
    TriggerSeatApplicationSceneType,
    UserBriefVO,
    UserID,
} from '../../kernel/interface/type'
import { compareRole } from '../../kernel/interface/user-roles'
import { GetBatchInviteValidateInfo } from '../../kernel/request/user-roles'
import { featureSwitchManager } from '../../kernel/switch'
import { getUserRole, getUserSeatStatus, UserRole, UserSeatStatus } from '../../utils/payment'
import { inOtherTeamDraftBox, isActiveOrgPlan, isActiveProPlan } from '../../utils/plan-status-checker'
import { PayApplyDialog } from './pay-apply-dialog/pay-apply-dialog'
import {
    PayApplyDialogSeatType,
    PayApplyDialogType,
    PayApplyDialogUserType,
} from './pay-apply-dialog/pay-apply-dialog-struct/type'
import { translation } from './pay-seat-apply-share-doc.translation'
import { getDescription } from './util'

export function PayApplySeatOfShareDocPromise(props: {
    planInfo: PlanAndUserStateVO | undefined
    docInfo: DocWithAuthorityVO
    userId: UserID
    email2InviteeInfo:
        | {
              [key: string]: UserBriefVO
          }
        | undefined
    handles?: {
        setMoveModal?: (v: boolean) => void
        setShareModal?: (v: boolean) => void
        // todo 以后删掉
        refreshMembersList?: () => void
    }
    scene: 'shareDoc' | 'shareDocEditAuth'
    draftFolderId: FolderID | undefined
}) {
    return new Promise<void>((resolve, reject) => {
        PayApplySeatOfShareDoc({ ...props, resolve, reject })
    })
}

function showAdminUpgradeInviteeToDesignSeatInTeamDocModal(props: {
    docInfo: DocWithAuthorityVO
    email2InviteeInfo:
        | {
              [key: string]: UserBriefVO
          }
        | undefined
    handles?: {
        setShareModal?: (v: boolean) => void
        refreshMembersList?: () => void
    }
    resolve: (value: void | PromiseLike<void>) => void
    reject: (reason?: any) => void
}) {
    const { docInfo, email2InviteeInfo, handles, reject } = props
    handles?.setShareModal?.(false)
    PayApplyDialog.show({
        type: PayApplyDialogType.Invite,
        seatType: PayApplyDialogSeatType.Design,
        httpProps: {
            resourceType: ResourceType.Document,
            resourceId: docInfo.id,
            role: RoleStatus.Editor,
            inviteRedirectUrl: undefined,
        },
        users: email2InviteeInfo ? Object.values(email2InviteeInfo) : [],
        isHideUserList: false,
        dialogProps: {
            title: translation('ShareDoc'),
            okText: undefined,
            onOk: () => {
                reject('pay-seat-apply-share-doc') // 在申请席位弹窗中已经为成员和自己升级席位，用的是邀请接口，这里直接 reject，外部函数不用继续往下走了
                handles?.setShareModal?.(true)
                handles?.refreshMembersList?.()
            },
            onCancel: () => {
                reject('pay-seat-apply-share-doc')
                handles?.setShareModal?.(true)
            },
        },
        description: translation('AdminUpgradeMembersSeatForDoc'),
    })
}

function showAdminUpgradeSelfDialog(props: {
    handles?: {
        setShareModal?: (v: boolean) => void
    }
    httpProps: {
        resourceType: ResourceType
        resourceId: string
        triggerScene: {
            scene: TriggerSeatApplicationSceneType
            docId: string
            userId: number
        }
        orgId: string
    }
    scene: 'shareDoc' | 'shareDocEditAuth'
    resolve: (value: void | PromiseLike<void>) => void
    reject: (reason?: any) => void
}) {
    const { handles, reject, httpProps, resolve, scene } = props

    handles?.setShareModal?.(false)
    PayApplyDialog.show({
        type: PayApplyDialogType.Admin,
        seatType: PayApplyDialogSeatType.Design,
        httpProps,
        dialogProps: {
            title: scene === 'shareDoc' ? translation('ConfirmShareDraft') : translation('ConfirmShareDraftEditAuth'),
            okText: undefined,
            onOk: () => {
                resolve()
                handles?.setShareModal?.(true)
            },
            onCancel: () => {
                reject('pay-seat-apply-share-doc')
                handles?.setShareModal?.(true)
            },
        },
        description: getDescription('design', true),
    })
}

async function PayApplySeatOfShareDoc(props: {
    planInfo: PlanAndUserStateVO | undefined
    docInfo: DocWithAuthorityVO
    userId: UserID
    email2InviteeInfo:
        | {
              [key: string]: UserBriefVO
          }
        | undefined
    handles?: {
        setMoveModal?: (v: boolean) => void
        setShareModal?: (v: boolean) => void
        refreshMembersList?: () => void
    }
    resolve: (value: void | PromiseLike<void>) => void
    reject: (reason?: any) => void
    scene: 'shareDoc' | 'shareDocEditAuth'
    draftFolderId: FolderID | undefined
}) {
    const { planInfo, docInfo, userId, handles, email2InviteeInfo, resolve, reject, scene, draftFolderId } = props

    const isAdminInPlan = compareRole(planInfo?.userRoleInResource ?? RoleStatus.None, RoleStatus.Admin) >= 0
    const isSomeWorkspaceAdmin =
        featureSwitchManager.isEnabled('organization-plus') &&
        planInfo &&
        planInfo.planType === PayPlanType.enterprise &&
        planInfo.adminWorkspaceIds.length > 0

    const needApplyDesignSeatForInvitee =
        email2InviteeInfo && Object.values(email2InviteeInfo).some((v) => v.seatType !== SeatType.designer)

    // 检查是否存在以下情况的被邀请者：
    // 1. 未分配工作区，或
    // 2. 分配了工作区但不是设计席位且当前管理员无权限管理该工作区
    const hasNonDesignerInExternalWorkspace =
        featureSwitchManager.isEnabled('organization-plus') &&
        planInfo &&
        email2InviteeInfo &&
        Object.values(email2InviteeInfo).some(
            (v) =>
                !v.workspaceId ||
                (v.seatType !== SeatType.designer && !planInfo?.adminWorkspaceIds.includes(v.workspaceId))
        )

    if (planInfo && isActiveOrgPlan(planInfo)) {
        if (!planInfo) {
            reject('pay-seat-apply-share-doc')
            return
        }

        if (docInfo.draft && docInfo.folderId === draftFolderId) {
            const shareDraftDocEditHttpProps = {
                resourceType: planInfo.resourceType,
                resourceId: planInfo.resourceId,
                triggerScene: {
                    scene: TriggerSeatApplicationSceneType.ShareDraftDocEditPermission,
                    docId: docInfo.id,
                    userId: userId,
                },
                orgId: docInfo.orgId,
            }

            if (isAdminInPlan) {
                const isCurrUserDesignSeat = planInfo.userSeatType === SeatType.designer
                if (needApplyDesignSeatForInvitee) {
                    // 付费企业管理员升级自己和成员为设计席位
                    showAdminUpgradeInviteeToDesignSeatInTeamDocModal(props)
                    return
                } else if (!isCurrUserDesignSeat) {
                    // 付费企业管理员升级自己为设计席位
                    handles?.setShareModal?.(false)
                    PayApplyDialog.show({
                        type: PayApplyDialogType.Admin,
                        seatType: PayApplyDialogSeatType.Design,
                        httpProps: shareDraftDocEditHttpProps,
                        dialogProps: {
                            title:
                                scene === 'shareDoc'
                                    ? translation('ConfirmShareDraft')
                                    : translation('ConfirmShareDraftEditAuth'),
                            okText: undefined,
                            onOk: () => {
                                resolve()
                                handles?.setShareModal?.(true)
                            },
                            onCancel: () => {
                                reject('pay-seat-apply-share-doc')
                                handles?.setShareModal?.(true)
                            },
                        },
                        description: getDescription('design', true),
                    })
                    return
                } else {
                    resolve()
                }
            } else if (isSomeWorkspaceAdmin) {
                const isCurrUserDesignSeat = planInfo.userSeatType === SeatType.designer
                if (needApplyDesignSeatForInvitee && !hasNonDesignerInExternalWorkspace) {
                    // 高级企业版工作区管理员升级自己和成员为设计席位
                    showAdminUpgradeInviteeToDesignSeatInTeamDocModal(props)
                    return
                } else if (!isCurrUserDesignSeat) {
                    // 高级版工作区管理员升级自己为设计席位
                    showAdminUpgradeSelfDialog({ ...props, httpProps: shareDraftDocEditHttpProps })
                    return
                } else {
                    resolve()
                }
            } else {
                const userSeatStatus = getUserSeatStatus(planInfo, SeatType.designer)
                switch (userSeatStatus) {
                    case UserSeatStatus.CanApplyAutoUpgrade: {
                        // 有宽限期&自动升级
                        handles?.setShareModal?.(false)
                        PayApplyDialog.show({
                            type: PayApplyDialogType.AutoUpgrade,
                            seatType: PayApplyDialogSeatType.Design,
                            httpProps: shareDraftDocEditHttpProps,
                            dialogProps: {
                                title:
                                    scene === 'shareDoc'
                                        ? translation('ConfirmShareDraft')
                                        : translation('ConfirmShareDraftEditAuth'),
                                okText: undefined,
                                onOk: () => {
                                    resolve()
                                    handles?.setShareModal?.(true)
                                },
                                onCancel: () => {
                                    reject('pay-seat-apply-share-doc')
                                    handles?.setShareModal?.(true)
                                },
                            },
                            description: getDescription('design', false),
                        })
                        return
                    }
                    case UserSeatStatus.NeedReApply: // 无宽限期&&需要重新申请
                    case UserSeatStatus.NeedWaitToBeApproved: // 无宽限期&&已申请&&还未审批
                    case UserSeatStatus.CanApplyGracePeriod: {
                        // 未申请过&有宽限期
                        const userRole = await getUserRole(docInfo.orgId, docInfo.teamId ?? '')
                        handles?.setShareModal?.(false)
                        PayApplyDialog.show({
                            type: PayApplyDialogType.Normal,
                            isAllowGracePeriod: userSeatStatus == UserSeatStatus.CanApplyGracePeriod,
                            isEnterprise: planInfo.planType === PayPlanType.enterprise,
                            userInfo: {
                                type:
                                    userRole === UserRole.Guest
                                        ? PayApplyDialogUserType.External
                                        : PayApplyDialogUserType.Normal,
                            },
                            seatType: PayApplyDialogSeatType.Design,
                            httpProps: shareDraftDocEditHttpProps,
                            descriptionPrefix:
                                scene === 'shareDoc'
                                    ? translation('ShareDraftPrefix')
                                    : translation('ShareDraftEditPrefix'),
                            unresolvedSeatTypes: planInfo.unresolvedApplicationSeatTypes,
                            repeatDialogProps: {
                                dialogTitle:
                                    scene === 'shareDoc'
                                        ? translation('CannotShareDraft')
                                        : translation('CannotShareDraftEdit'),
                            },
                            dialogProps: {
                                onOk: () => {
                                    userSeatStatus == UserSeatStatus.CanApplyGracePeriod
                                        ? resolve()
                                        : reject('pay-seat-apply-share-doc')
                                    handles?.setShareModal?.(true)
                                },
                                onCancel: () => {
                                    reject('pay-seat-apply-share-doc')
                                    handles?.setShareModal?.(true)
                                },
                            },
                        })
                        return
                    }
                    case UserSeatStatus.InGracePeriod:
                    case UserSeatStatus.InAutoUpgrade:
                    case UserSeatStatus.InPermanentSeat: {
                        resolve()
                        break
                    }
                }
            }
        } else if (docInfo.draft && docInfo.folderId !== draftFolderId && scene === 'shareDoc') {
            // 分享文件限制场景会额外检查非草稿箱owner分享草稿时查看草稿owner是否有设计席位
            const { orgDraftFolderOwner } = await new GetBatchInviteValidateInfo(
                ResourceType.Document,
                docInfo.id,
                []
            ).start()
            if (orgDraftFolderOwner && orgDraftFolderOwner.seatType !== SeatType.designer) {
                const { nickname, email } = orgDraftFolderOwner.userBriefVO
                reject('pay-seat-apply-share-doc')
                wkDialogConfirm.show({
                    wrapperTestId: 'share-other-draft-limit-dialog',
                    title: translation('CannotShareDraft'),
                    content: translation('ShareOthersDraftLimit', { ownerName: nickname, ownerEmail: email }),
                    okText: translation('IKnow'),
                    hideCancelButton: true,
                })
            } else {
                resolve()
            }
            return
        } else if (
            !docInfo.draft &&
            (isAdminInPlan || (isSomeWorkspaceAdmin && !hasNonDesignerInExternalWorkspace)) &&
            needApplyDesignSeatForInvitee
        ) {
            // 若用户是企业管理员并且文件为团队文件，管理员直接升级被邀请人[非设计席位]为设计席位
            showAdminUpgradeInviteeToDesignSeatInTeamDocModal(props)
        } else {
            resolve()
        }
        return
    } else if (planInfo && isActiveProPlan(planInfo)) {
        // 若用户是企业/团队管理员并且文件为团队文件，管理员直接升级被邀请人[非设计席位]为设计席位
        if (!docInfo.draft && isAdminInPlan && needApplyDesignSeatForInvitee) {
            showAdminUpgradeInviteeToDesignSeatInTeamDocModal(props)
        } else {
            resolve()
        }
        return
    } else if (inOtherTeamDraftBox(planInfo, docInfo)) {
        const { draftAndOwnerShareEditLimited } = await new GetBatchInviteValidateInfo(
            ResourceType.Document,
            docInfo.id,
            []
        ).start()

        if (draftAndOwnerShareEditLimited) {
            // 若在付费团队中没有设计席位，则需要先将草稿移动到付费团队中并且后续逻辑不执行，移动弹窗没有权限则正常报错
            handles?.setMoveModal?.(true)
            handles?.setShareModal?.(false)
            reject('pay-seat-apply-share-doc')
        } else {
            resolve()
        }
        return
    } else {
        return resolve()
    }
}
