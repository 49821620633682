import { onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from 'web-vitals'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { WkCLog } from '../clog/wukong/instance'
import { environment } from '../../environment'

/**
 * 用于收集用户侧的应用体验信息
 */
export class WebVitalsCollector {
    private queue: { value: number; rating: string; name: string }[] = []

    constructor(signal: TraceableAbortSignal) {
        window.addEventListener(
            'visibilitychange',
            () => {
                this.flushQueue()
            },
            { signal }
        )

        onTTFB(this.enqueue.bind(this, 'TTFB'))

        onFCP(this.enqueue.bind(this, 'FCP'))

        onLCP(this.enqueue.bind(this, 'LCP'))

        onFID(this.enqueue.bind(this, 'FID'))

        onCLS(this.enqueue.bind(this, 'CLS'))

        onINP(this.enqueue.bind(this, 'INP'))
    }

    private enqueue(name: string, metric: { delta: number; rating: string }): void {
        this.queue.push({
            name,
            value: metric.delta,
            rating: metric.rating,
        })
    }

    private flushQueue(): void {
        if (this.queue.length > 0) {
            this.queue.forEach((metric) => {
                WkCLog.throttleLog('motiff-web-vitals', {
                    ...metric,
                    isAbroad: environment.isAbroad,
                })
            })
            this.queue = []
        }
    }
}
