export enum MessageContentType {
    Comment = 1,
    // 2 是工作台
    // 3 是组件库
    Mirror = 4,
    PropertyChange = 5,
    RelationChange = 6,
    MessageCenter = 7, // 消息中心
}

export interface FilterParameterVO {
    name: string
    value: string
}

export interface MessageRequestParamVO {
    /**
     * NOTE: 具体值与 notify_message.proto 中的 BusinessCode 不一致
     */
    messageContentType: MessageContentType
    /**
     * 服务端是 int64
     */
    sessionId: string
    /**
     *
     */
    filterParameters: FilterParameterVO[]
}
export interface FilterVO {
    filterParameters: FilterParameterVO[]
}
export type SubscribeFilters = { [key in MessageContentType]?: FilterVO[] }

export interface SubscribeAndEnableNotifyRequestVO {
    sessionId: string
    subscribeFilters: SubscribeFilters
}
