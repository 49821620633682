import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { InputV2, WKButton, WKDialog, WKDialogProps } from '../../../../../../ui-lib/src'
import { ToKeyCode } from '../../../../document/util/keycode'
import { KeyboardReceiver } from '../../../../main/keyboard-receiver/component'
import classes from './message-modal.module.less'
import { translation } from './message-modal.translation'
export interface MessageModalProps extends Pick<WKDialogProps, 'visible'> {
    type?: 'save' | 'add' | 'edit'
    defaultName?: string
    defaultDescription?: string
    onOK?: (params: { name: string; description: string }) => void
    onCancel?: () => void
    dataTestIds?: {
        modal?: string
        cancelButton?: string
        okButton?: string
        nameInput?: string
        descriptionTextarea?: string
    }
}
export function MessageModal(props: MessageModalProps) {
    const { visible, type = 'save', defaultName, defaultDescription, dataTestIds, onOK, onCancel } = props
    const [name, setName] = useState<string>(defaultName ?? '')
    const [description, setDescription] = useState<string>(defaultDescription ?? '')

    const title = useMemo(() => {
        switch (type) {
            case 'edit':
                return translation('NameThisVersion')
            case 'add':
                return translation('AddToVersion')
            case 'save':
            default:
                return translation('AddToVersion_synonyms1')
        }
    }, [type])

    const _onCancel = useCallback(() => {
        onCancel?.()
        return false
    }, [onCancel])

    const _onOk = useCallback(() => {
        onOK?.({ name, description })
    }, [description, name, onOK])

    const onChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
    }, [])

    const nameOverflow = useMemo(() => {
        return {
            isOverflow: name.length > 100,
            tipText: `${translation('ExceededTheCharacter')}（${name.length}/100）`,
        }
    }, [name.length])

    const descriptionOverflow = useMemo(() => {
        return {
            isOverflow: description.length > 1000,
            tipText: `${translation('ExceededTheCharacter')}（${description.length}/1000）`,
        }
    }, [description.length])

    const disableSaveBtn = useMemo(() => {
        return nameOverflow.isOverflow || descriptionOverflow.isOverflow || name.length === 0
    }, [descriptionOverflow.isOverflow, name.length, nameOverflow.isOverflow])

    return (
        <WKDialog
            visible={!!visible}
            title={title}
            onCancel={_onCancel}
            onOk={_onOk}
            bodyStyle={{ padding: 0 }}
            testId={dataTestIds?.modal}
            footer={
                <>
                    <WKButton
                        data-testid={dataTestIds?.cancelButton}
                        htmlType="button"
                        onClick={_onCancel}
                        type="secondary"
                    >
                        {translation('Cancel')}
                    </WKButton>
                    <WKButton
                        data-testid={dataTestIds?.okButton}
                        htmlType={'button'}
                        type="primary"
                        onClick={_onOk}
                        disabled={disableSaveBtn}
                    >
                        {type === 'add'
                            ? translation('Save')
                            : type === 'edit'
                            ? translation('Save_synonyms1')
                            : translation('Save_synonyms1_synonyms2')}
                    </WKButton>
                </>
            }
        >
            <KeyboardReceiver keyCode={ToKeyCode.Esc} onKeydown={_onCancel}>
                <div className={classes.context}>
                    <InputV2
                        value={name}
                        onChange={onChangeName}
                        placeholder={translation('Title')}
                        dataTestIds={{ input: dataTestIds?.nameInput }}
                        autoFocus
                        error={{ show: nameOverflow.isOverflow, tipMessage: nameOverflow.tipText }}
                    />
                    <div style={{ marginTop: 16 }}>
                        <InputV2.Textarea
                            value={description}
                            onChange={(e) => setDescription((e.target as HTMLTextAreaElement).value)}
                            placeholder={translation('DescribeWhatChanged')}
                            dataTestIds={{ textarea: dataTestIds?.descriptionTextarea }}
                            height={90}
                            error={{ show: descriptionOverflow.isOverflow, tipMessage: descriptionOverflow.tipText }}
                        />
                    </div>
                </div>
            </KeyboardReceiver>
        </WKDialog>
    )
}
