import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { useEffect, useRef, useState } from 'react'
import { InputV2, MonoIconCommonClose16, SearchInputRef, WKIconButton } from '../../../../../ui-lib/src'
import { isCombo, isKey, KeyboardCode, ModifierKeysCombo } from '../../../kernel/keyboard/keyboard-event-handler'
import { useCanvasSearchService, useCommand } from '../../../main/app-context'
import { shouldPassThisEventToWasmWhenCanvasSearchInputFocused } from '../../../main/service/focus-view/keyboard-event-prevent-or-pass'
import { dispatchKeyboardEventToWasm } from '../../../main/service/focus-view/keyboard-event-to-wasm'
import { CanvasSearchReplace } from './canvas-search-replace'
import { CanvasSearchResult } from './canvas-search-result'
import { CanvasSearchResultLoading } from './canvas-search-result-loading'
import { CanvasSearchSetting } from './canvas-search-setting'
import { CanvasSettingButton } from './canvas-setting-button'
import styles from './index.module.less'
import { useCanvasSearchParamModel, useCanvasSearchResultModel } from './use-canvas-search-model'

export function CanvasSearchPanel() {
    const canvasSearchParamModel = useCanvasSearchParamModel()
    const canvasSearchResultModel = useCanvasSearchResultModel()
    const searchInputRef = useRef<SearchInputRef>(null)
    const canvasSearchService = useCanvasSearchService()
    const command = useCommand()

    const isSearchMode =
        canvasSearchParamModel.modelState?.mode === Wukong.DocumentProto.CanvasSearchMode.CANVAS_SEARCH_MODE_SEARCH

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.nativeEvent.isComposing) {
            return
        }
        // NOTE: 阻止冒泡, 防止同一次event被KeyboardReceiver重复转发到wasm
        event.stopPropagation()

        if (isKey(event.nativeEvent, KeyboardCode.ESCAPE)) {
            canvasSearchParamModel.modelCommand.exitSearch()
        } else if (isKey(event.nativeEvent, KeyboardCode.ENTER)) {
            canvasSearchResultModel.modelCommand.onEnterKeyDown(isCombo(event.nativeEvent, ModifierKeysCombo.SHIFT))
        } else if (isKey(event.nativeEvent, KeyboardCode.ARROW_UP)) {
            canvasSearchResultModel.modelCommand.prevLayerItem()
            event.preventDefault()
        } else if (isKey(event.nativeEvent, KeyboardCode.ARROW_DOWN)) {
            canvasSearchResultModel.modelCommand.nextLayerItem()
            event.preventDefault()
        } else if (shouldPassThisEventToWasmWhenCanvasSearchInputFocused(event.nativeEvent)) {
            dispatchKeyboardEventToWasm(
                command,
                event.nativeEvent,
                Wukong.DocumentProto.KeyboardEventTraceSource.KEYBOARD_EVENT_TRACE_SOURCE_CANVAS_SEARCH
            )
        }
    }

    const [searchInputValue, setSearchValue] = useState(canvasSearchParamModel.modelState.value)
    // 通过onChange获取最新的input value, 与当前正在搜索的内容不一致时则说明还没有触发onSearch
    const isLoading = searchInputValue !== canvasSearchParamModel.modelState.value

    useEffect(() => {
        canvasSearchService.setFocusInputCallback(() => {
            const input = searchInputRef.current?.getInputElement()
            input?.focus()
            input?.select()
        })
    }, [canvasSearchService])

    return (
        <div className={styles.root} data-testid="canvas-search-panel">
            <div className={styles.searchBar}>
                <div className={styles.searchHeader}>
                    <div className={styles.searchContainer}>
                        <InputV2.Search
                            rootClassName={styles.search}
                            ref={searchInputRef}
                            placeholder={isSearchMode ? translation('Find…') : translation('Find…_synonyms1')}
                            autoFocus
                            debounce
                            debounceTime={200}
                            onKeyDown={handleKeyDown}
                            defaultValue={canvasSearchParamModel.modelState.value}
                            onSearch={(value) => canvasSearchParamModel.modelCommand.updateSearchValue(value)}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onClickClearBtn={() => setSearchValue('')}
                            dataTestIds={{ input: 'canvas-search-input', clearIcon: 'icon-close-svg' }}
                        />
                    </div>
                    <CanvasSettingButton
                        canvasSearchParamModel={canvasSearchParamModel}
                        canvasSearchResultModel={canvasSearchResultModel}
                    />
                    <WKIconButton
                        className={styles.close}
                        size="medium"
                        type="primary"
                        icon={<MonoIconCommonClose16 />}
                        onClick={canvasSearchParamModel.modelCommand.exitSearch}
                    ></WKIconButton>
                </div>
                <CanvasSearchSetting canvasSearchParamModel={canvasSearchParamModel} />
                <CanvasSearchReplace
                    canvasSearchParamModel={canvasSearchParamModel}
                    canvasSearchResultModel={canvasSearchResultModel}
                />
            </div>
            {isLoading ? (
                <CanvasSearchResultLoading />
            ) : (
                <CanvasSearchResult
                    key={canvasSearchParamModel.searchUid}
                    canvasSearchParamModel={canvasSearchParamModel}
                    canvasSearchResultModel={canvasSearchResultModel}
                />
            )}
        </div>
    )
}
