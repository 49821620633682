import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Image: '图片',
} as const

export const enTranslation = {
    Image: 'Image',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
