import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    ReplaceComponent: '替换组件',
    ReplaceStyle: '替换样式',
    ReplaceTo: '替换为',
    Selected: '选中 ',
    CandidateStyle: ' 个候选样式',
    CandidateComponent: ' 个候选组件',
    Replace: '替换',
    ChooseComponent: '选择组件',
    ChooseColorStyle: '选择样式',
    ChooseFontStyle: '选择样式',
    ChooseEffectStyle: '选择样式',
} as const

export const enTranslation = {
    ReplaceComponent: 'Replace component',
    ReplaceStyle: 'Replace styles',
    ReplaceTo: 'Replace with',
    Selected: 'Selected ',
    CandidateStyle: ' candidate {{style}}',
    CandidateComponent: ' candidate {{component}}',
    Replace: 'Replace',
    ChooseComponent: 'Choose a component',
    ChooseColorStyle: 'Choose a color style',
    ChooseFontStyle: 'Choose a font style',
    ChooseEffectStyle: 'Choose an effect style',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
