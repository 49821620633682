/* eslint-disable no-restricted-imports */
import { CreateVersionParams, HistoryVersionInfo } from '../../../kernel/interface/history-version'
import {
    ApplyHistoryVersion,
    CopyHistoryVersionAsNewFile,
    CreateVersion,
    EditHistoryVersionInfo,
    GetHistoryVersion,
} from '../../../kernel/request/history-version'

export const fetchGetHistoryVersion = (
    docId: string,
    pageSize: number,
    startId = -1,
    onlyMine = false,
    includeAutoSave = true
) => {
    return new GetHistoryVersion(docId, pageSize, startId, onlyMine, includeAutoSave).start()
}

export const fetchCopyHistoryVersionAsNewFile = (docId: string, versionId: number) => {
    return new CopyHistoryVersionAsNewFile(docId, versionId).start()
}

export const fetchCreateVersion = (createInfo: CreateVersionParams) => {
    return new CreateVersion({ ...createInfo }).start()
}

export const fetchApplyHistoryVersion = (docId: string, versionId: number) => {
    return new ApplyHistoryVersion(docId, versionId).start()
}

export const fetchEditHistoryVersionInfo = (docId: string, versionId: number, info: HistoryVersionInfo) => {
    return new EditHistoryVersionInfo(docId, versionId, info).start()
}
