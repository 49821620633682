import { isNil } from 'lodash-es'
import { WKItemType, WKMenuConfig, WKMenuItemType } from '../../menu-type'

/**
 * 对传入的 menuConfig 进行处理
 */
export function formatMenuList(list: WKMenuConfig): WKMenuConfig {
    const formatList = list.reduce((res, item, index) => {
        const prevItem: WKMenuItemType | null = res.length && index ? res[res.length - 1] : null
        // 隐藏的 item 不显示
        if (isHidden(item.hidden)) {
            return res
        }

        // divider 不可相邻，且不可在首
        if (
            'type' in item &&
            item.type === WKItemType.Divider &&
            (!prevItem || ('type' in prevItem && prevItem.type == item.type))
        ) {
            return res
        }

        // groupHeader 不可相邻
        if (
            'type' in item &&
            item.type === WKItemType.GroupHeader &&
            prevItem &&
            'type' in prevItem &&
            prevItem.type == item.type
        ) {
            return res
        }

        // 不允许 groupHeader > divider 的情况
        if (
            prevItem &&
            'type' in prevItem &&
            prevItem.type == WKItemType.GroupHeader &&
            'type' in item &&
            item.type === WKItemType.Divider
        ) {
            return res
        }

        return [...res, item]
    }, [] as WKMenuConfig)

    // divider & groupHeader 不可在尾
    const lastItem: WKMenuItemType | null = formatList.length ? formatList[formatList.length - 1] : null
    if (lastItem && 'type' in lastItem && [WKItemType.Divider, WKItemType.GroupHeader].includes(lastItem.type)) {
        formatList.pop()
    }

    return formatList
}

export const isHidden = (hidden?: boolean | (() => boolean)) => {
    if (isNil(hidden)) {
        return false
    }
    if (typeof hidden === 'function') {
        return hidden()
    }
    return hidden
}
