import { type Command, type Computed, type State } from 'ccstate'

type RipplingTypes = State<any> | Computed<any> | Command<any, any[]> | [Computed<any>, Command<any, any[]>]

type TraceableTypes<T> = T & { author: string }

type ReturnTraceableTypes<T> = T extends [Computed<any>, Command<any, any[]>]
    ? [TraceableTypes<T[0]>, TraceableTypes<T[1]>]
    : TraceableTypes<T>

/**
 * 提供可追踪的 Rippling 封装
 * @param atom
 * @param author
 * @returns
 */
export function traceable<T extends RipplingTypes>(author: string, atom: T): ReturnTraceableTypes<T> {
    if (Array.isArray(atom)) {
        return atom.map((item) => traceable(author, item)) as ReturnTraceableTypes<T>
    }

    return Object.assign(atom, { author }) as ReturnTraceableTypes<T>
}
