import { translation } from './antd-sample-html.translation'

export const SampleHTML = (cssURL: string) => `
  <link rel="stylesheet" href="${cssURL}">
  <style>
  .ant-text-base {
    color: var(--ant-text-base);
}

.ant-bg-base {
    background-color: var(--ant-bg-base);
}

:where(.css-zlwal1).ant-input-outlined {
    background: var(--ant-bg-base);
    border-width: 1px;
    border-style: solid;
    border-color: var(--ant-border-color);
}

:where(.css-zlwal1).ant-input-outlined:focus,
:where(.css-zlwal1).ant-input-outlined:focus-within {
    border-color: var(--ant-primary);
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    outline: 0;
    background-color: var(--ant-bg-base);
}

:where(.css-zlwal1).ant-input-outlined:hover {
    border-color: var(--ant-primary);
    background-color: var(--ant-bg-base);
}

:where(.css-zlwal1).ant-picker-outlined {
    background: var(--ant-bg-base);
    border-width: 1px;
    border-style: solid;
    border-color: var(--ant-border-color);
}

:where(.css-zlwal1).ant-picker-outlined:focus,
:where(.css-zlwal1).ant-picker-outlined:focus-within {
    border-color: var(--ant-primary);
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    outline: 0;
    background-color: var(--ant-bg-base);
}

:where(.css-zlwal1).ant-picker-outlined:hover {
    border-color: var(--ant-primary);
    background-color: var(--ant-bg-base);
}

:where(.css-zlwal1).ant-menu-light,
:where(.css-zlwal1).ant-menu-light > .ant-menu {
    color: rgba(0, 0, 0, 0.88);
    background: var(--ant-bg-base);
}

:where(.css-zlwal1).ant-card {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: var(--ant-text-base);
    font-size: var(--font-size-base);
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: var(--font-family);
    position: relative;
    background: var(--ant-bg-base);
    border-radius: calc(var(--base-radius) + 2px);
}

:where(.css-zlwal1).ant-layout-header {
    height: 64px;
    padding: 0 32px;
    color: var(--ant-text-base);
    line-height: 64px;
}

/* hover 主标题 */
:where(.css-zlwal1).ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected,
:where(.css-zlwal1).ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-item-selected,
:where(.css-zlwal1).ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-selected,
:where(.css-zlwal1).ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-submenu-selected {
    color: var(--ant-primary);
    background-color: transparent;
}

:where(.css-zlwal1).ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after,
:where(.css-zlwal1).ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-item:hover::after,
:where(.css-zlwal1).ant-menu-light.ant-menu-horizontal > .ant-menu-submenu:hover::after,
:where(.css-zlwal1).ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-submenu:hover::after,
:where(.css-zlwal1).ant-menu-light.ant-menu-horizontal > .ant-menu-item-active::after,
:where(.css-zlwal1).ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-item-active::after,
:where(.css-zlwal1).ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-active::after,
:where(.css-zlwal1).ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-submenu-active::after,
:where(.css-zlwal1).ant-menu-light.ant-menu-horizontal > .ant-menu-item-open::after,
:where(.css-zlwal1).ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-item-open::after,
:where(.css-zlwal1).ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-open::after,
:where(.css-zlwal1).ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-submenu-open::after {
    border-bottom-width: 2px;
    border-bottom-color: var(--ant-primary);
}

:where(.css-zlwal1).ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after,
:where(.css-zlwal1).ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-item-selected::after,
:where(.css-zlwal1).ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-selected::after,
:where(.css-zlwal1).ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-submenu-selected::after {
    border-bottom-width: 2px;
    border-bottom-color: var(--ant-primary);
}

/* hover 副标题 */
:where(.css-zlwal1).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--ant-primary);
    text-shadow: 0 0 0.25px currentcolor;
}

:where(.css-zlwal1).ant-tabs .ant-tabs-tab:hover {
    color: var(--ant-primary);
}

:where(.css-zlwal1).ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible),
:where(.css-zlwal1).ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible),
:where(.css-zlwal1).ant-tabs .ant-tabs-tab-btn:active,
:where(.css-zlwal1).ant-tabs .ant-tabs-tab-remove:active {
    color: var(--ant-primary);
}

/* text */

:where(.css-zlwal1).ant-tabs .ant-tabs-tab {
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-flex;
    align-items: center;
    padding: 12px 0;
    font-size: var(--font-size-base);
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    color: var(--ant-text-base);
}

:where(.css-zlwal1).ant-menu-light .ant-menu-item,
:where(.css-zlwal1).ant-menu-light > .ant-menu .ant-menu-item,
:where(.css-zlwal1).ant-menu-light .ant-menu-submenu-title,
:where(.css-zlwal1).ant-menu-light > .ant-menu .ant-menu-submenu-title {
    color: var(--ant-text-base);
}

:where(.css-zlwal1).ant-card .ant-card-head {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 56px;
    margin-bottom: -1px;
    padding: 0 24px;
    color: var(--ant-text-base);
    font-weight: 600;
    font-size: calc(var(--font-size-base) * 1.142);
    background: transparent;
    border-bottom: 1px solid var(--ant-border-color);
    border-radius: 8px 8px 0 0;
}

:where(.css-zlwal1).ant-list .ant-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    color: var(--ant-text-base);
}

:where(.css-zlwal1).ant-menu-light .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected):hover,
:where(.css-zlwal1).ant-menu-light
    > .ant-menu
    .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected):hover,
:where(.css-zlwal1).ant-menu-light
    .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected)
    > .ant-menu-submenu-title:hover,
:where(.css-zlwal1).ant-menu-light
    > .ant-menu
    .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected)
    > .ant-menu-submenu-title:hover {
    color: var(--ant-text-base);
}

/* button */
:where(.css-zlwal1).ant-btn-variant-solid {
    color: #fff;
    background: var(--ant-primary);
}

:where(.css-zlwal1).ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):hover {
    background: var(--ant-primary);
    color: #fff;
}

/* font */
:where(.css-zlwal1).ant-layout {
    font-family: var(--font-family);
    font-size: var(--font-size-base);
    box-sizing: border-box;
}

:where(.css-zlwal1).ant-tabs {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: var(--font-size-base);
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: var(--font-family);
    display: flex;
}

:where(.css-zlwal1).ant-menu {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: var(--font-size-base);
    line-height: 0;
    list-style: none;
    font-family: var(--font-family);
    margin-bottom: 0;
    padding-inline-start: 0;
    outline: none;
    transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}

:where(.css-zlwal1).ant-list {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: var(--font-size-base);
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: var(--font-family);
    position: relative;
}


/* radius */
:where(.css-zlwal1).ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px 4px;
  color: rgba(0, 0, 0, 0.88);
  font-size: var(--font-size-base);
  line-height: 1;
  list-style: none;
  font-family: var(--font-family);
  position: relative;
  display: inline-flex;
  align-items: center;
  border-radius: var(--base-radius);
  transition: border 0.2s, box-shadow 0.2s, background 0.2s;
}

:where(.css-zlwal1).ant-input-affix-wrapper {
  position: relative;
  display: inline-flex;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: var(--font-size-base);
  line-height: 1.5714285714285714;
  border-radius: var(--base-radius);
  transition: all 0.2s;
}

:where(.css-zlwal1).ant-btn {
  font-size: var(--font-size-base);
  height: 32px;
  padding: 0px 15px;
  border-radius: var(--base-radius);
}

/* font */
/*
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
*/

:where(.css-zlwal1).ant-card .ant-card-extra {
  margin-inline-start: auto;
  color: rgba(0, 0, 0, 0.88);
  font-weight: normal;
  font-size: var(--font-size-base);
}

.text-base {
  font-size: calc(var(--font-size-base) * 1.142);
  line-height: calc(var(--font-size-base) * 1.714);
}

.text-lg {
  font-size: calc(var(--font-size-base) * 1.285);
  line-height: calc(var(--font-size-base) * 2);
}

.text-sm {
  font-size: calc(var(--font-size-base) * 0.875);
  line-height: calc(var(--font-size-base) * 1.428);
}

.text-xl {
  font-size: calc(var(--font-size-base) * 1.428);
  line-height: calc(var(--font-size-base) * 2);
}

.text-xs {
  font-size: calc(var(--font-size-base) * 0.857);
  line-height: calc(var(--font-size-base) * 1.142);
}

:where(.css-zlwal1).ant-layout-content {
  flex: auto;
  color: var(--ant-text-base);
  min-height: 0;
}

:where(.css-zlwal1).ant-card-bordered {
  border: 1px solid var(--ant-border-color);
}

:where(.css-zlwal1).ant-tabs-top > .ant-tabs-nav::before,
:where(.css-zlwal1).ant-tabs-bottom > .ant-tabs-nav::before,
:where(.css-zlwal1).ant-tabs-top > div > .ant-tabs-nav::before,
:where(.css-zlwal1).ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 0px solid var(--ant-border-color);
  content: '';
}

.border-header {
  border-color: var(--ant-border-color);
}

.subtitle-gray {
  color: var(--subtitle-color);
}

[data-theme="dark"] {
  --input-icon-color:rgb(107 114 128 / var(--tw-text-opacity, 1));
  --avatar-color: rgba(255, 255, 255, 0.25);
  --ant-border-color: rgba(255, 255, 255, 0.10);
  --axis-color: #666666;
  --input-placeholder-color:rgb(255, 255, 255, 0.25);
  --subtitle-color: #a3a3a3;
}

[data-theme="light"] {
  --input-icon-color:rgb(107 114 128 / var(--tw-text-opacity, 1));
  --avatar-color: rgba(0, 0, 0, 0.25);
  --ant-border-color: #f0f0f0;
  --axis-color: #cccccc;
  --input-placeholder-color:rgb(107 114 128 / var(--tw-text-opacity, 1));
  --subtitle-color: rgb(75 85 99 / var(--tw-text-opacity, 1));;
}

:where(.css-zlwal1).ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: var(--avatar-color);
  border: 1px solid transparent;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.input-placeholder-color::placeholder {
  color: var(--input-placeholder-color, rgba(0, 0, 0, 0.25));
  opacity: 1;
}


:where(.css-zlwal1).ant-picker .ant-picker-input > input::placeholder {
  color: var(--input-placeholder-color, rgba(0, 0, 0, 0.25));
  user-select: none;
}

  </style>

  <div id="root" class="w-full pointer-events-none">
    <div class="ant-layout min-h-[900px] ant-bg-base css-zlwal1">
      <header
        class="border-header ant-layout-header flex justify-between items-center ant-bg-base px-6 h-16 border-b border-[#f0f0f0] w-[1440px] mx-auto css-zlwal1">
        <div class="flex items-center gap-2"><span class="ant-avatar ant-avatar-circle ant-avatar-icon css-zlwal1"
            style="width: 32px; height: 32px; font-size: calc(var(--font-size-base) * 1.142);"><span role="img" aria-label="user"
              class="anticon anticon-user"><svg viewBox="64 64 896 896" focusable="false" data-icon="user" width="1em"
                height="1em" fill="currentColor" aria-hidden="true">
                <path
                  d="M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z">
                </path>
              </svg></span></span><span class="font-medium">${translation(
                  'Olivia Martin'
              )}</span><span role="img" aria-label="down"
            class="anticon anticon-down text-xs ml-1"><svg viewBox="64 64 896 896" focusable="false" data-icon="down"
              width="1em" height="1em" fill="currentColor" aria-hidden="true">
              <path
                d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z">
              </path>
            </svg></span></div>
        <ul
          class="ant-menu-overflow ant-menu ant-menu-root ant-menu-horizontal ant-menu-light flex-1 justify-center border-0 css-zlwal1"
          role="menu" tabindex="0" data-menu-list="true">
          <li class="ant-menu-overflow-item ant-menu-item ant-menu-item-selected ant-menu-item-only-child"
            role="menuitem" tabindex="-1" aria-describedby=":r1:" data-menu-id="rc-menu-uuid-18393-1-overview"
            style="opacity: 1; order: 0;"><span class="ant-menu-title-content">${translation('Overview')}</span></li>
          <li class="ant-menu-overflow-item ant-menu-item ant-menu-item-only-child" role="menuitem" tabindex="-1"
            aria-describedby=":r3:" data-menu-id="rc-menu-uuid-18393-1-customers" style="opacity: 1; order: 1;"><span
              class="ant-menu-title-content">${translation('Customers')}</span></li>
          <li class="ant-menu-overflow-item ant-menu-item ant-menu-item-only-child" role="menuitem" tabindex="-1"
            aria-describedby=":r5:" data-menu-id="rc-menu-uuid-18393-1-products" style="opacity: 1; order: 2;"><span
              class="ant-menu-title-content">${translation('Products')}</span></li>
          <li class="ant-menu-overflow-item ant-menu-item ant-menu-item-only-child" role="menuitem" tabindex="-1"
            aria-describedby=":r7:" data-menu-id="rc-menu-uuid-18393-1-settings" style="opacity: 1; order: 3;"><span
              class="ant-menu-title-content">${translation('Settings')}</span></li>
          <li
            class="ant-menu-overflow-item ant-menu-overflow-item-rest ant-menu-submenu ant-menu-submenu-horizontal ant-menu-submenu-disabled"
            aria-hidden="true" role="none"
            style="opacity: 0; height: 0px; overflow-y: hidden; order: 2147483647; pointer-events: none; position: absolute;">
            <div role="menuitem" class="ant-menu-submenu-title" aria-expanded="false" aria-haspopup="true"
              data-menu-id="rc-menu-uuid-18393-1-rc-menu-more" aria-controls="rc-menu-uuid-18393-1-rc-menu-more-popup"
              aria-disabled="true"><span role="img" aria-label="ellipsis" class="anticon anticon-ellipsis"><svg
                  viewBox="64 64 896 896" focusable="false" data-icon="ellipsis" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M176 511a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0z">
                  </path>
                </svg></span><i class="ant-menu-submenu-arrow"></i></div>
          </li>
        </ul>
        <div aria-hidden="true" style="display: none;"></div>
        <div class="flex items-center gap-4"><span
            class="ant-input-affix-wrapper css-zlwal1 ant-input-outlined w-60"><span class="ant-input-prefix"><span
                role="img" aria-label="search" class="anticon anticon-search text-gray-400"><svg viewBox="64 64 896 896"
                  focusable="false" data-icon="search" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                  <path
                    d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z">
                  </path>
                </svg></span></span><input placeholder=${translation(
                    'Search...'
                )} class="input-placeholder-color ant-input css-zlwal1" type="text"
              value=""></span></div>
      </header>
      <main class="ant-layout-content w-[1440px] mx-auto p-8 ant-bg-base css-zlwal1">
        <div class="flex justify-between items-center mb-6">
          <h1 class="text-2xl font-bold m-0">${translation('Dashboard')}</h1>
          <div class="flex items-center gap-4">
            <div class="ant-picker ant-picker-range ant-picker-outlined css-zlwal1 w-72">
              <div class="ant-picker-input"><input class="input-placeholder-color" aria-invalid="false" autocomplete="off" size="12"
                  placeholder=${translation('Start date')} date-range="start" value=""></div>
              <div class="ant-picker-range-separator"><span aria-label="to" class="ant-picker-separator"><span
                    role="img" aria-label="swap-right" class="anticon anticon-swap-right text-gray-400"><svg viewBox="0 0 1024 1024"
                      focusable="false" data-icon="swap-right" width="1em" height="1em" fill="currentColor"
                      aria-hidden="true">
                      <path
                        d="M873.1 596.2l-164-208A32 32 0 00684 376h-64.8c-6.7 0-10.4 7.7-6.3 13l144.3 183H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h695.9c26.8 0 41.7-30.8 25.2-51.8z">
                      </path>
                    </svg></span></span></div>
              <div class="ant-picker-input"><input class="input-placeholder-color" aria-invalid="false" autocomplete="off" size="12"
                  placeholder=${translation('End date')} date-range="end" value=""></div>
              <div class="ant-picker-active-bar" style="position: absolute; width: 0px;"></div><span
                class="ant-picker-suffix"><span role="img" aria-label="calendar" class="anticon anticon-calendar text-gray-400"><svg
                    viewBox="64 64 896 896" focusable="false" data-icon="calendar" width="1em" height="1em"
                    fill="currentColor" aria-hidden="true">
                    <path
                      d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z">
                    </path>
                  </svg></span></span>
            </div><button type="button"
              class="ant-btn css-zlwal1 ant-btn-primary ant-btn-color-primary ant-btn-variant-solid"><span>${translation(
                  'Download'
              )}</span></button>
          </div>
        </div>
        <div class="ant-tabs ant-tabs-top mb-6 css-zlwal1">
          <div role="tablist" aria-orientation="horizontal" class="ant-tabs-nav">
            <div class="ant-tabs-nav-wrap">
              <div class="ant-tabs-nav-list" style="transform: translate(0px, 0px);">
                <div data-node-key="overview" class="ant-tabs-tab ant-tabs-tab-active">
                  <div role="tab" aria-selected="true" class="ant-tabs-tab-btn" tabindex="0" id="rc-tabs-0-tab-overview"
                    aria-controls="rc-tabs-0-panel-overview">${translation('Overview')}</div>
                    <div class="ant-tabs-ink-bar ant-tabs-ink-bar-animated"
                      style="width: 100%; background-color: var(--ant-primary);">
                    </div>
                </div>
                <div data-node-key="analytics" class="ant-tabs-tab">
                  <div role="tab" aria-selected="false" class="ant-tabs-tab-btn" tabindex="-1"
                    id="rc-tabs-0-tab-analytics" aria-controls="rc-tabs-0-panel-analytics">${translation(
                        'Analytics'
                    )}</div>
                </div>
                <div data-node-key="reports" class="ant-tabs-tab">
                  <div role="tab" aria-selected="false" class="ant-tabs-tab-btn" tabindex="-1"
                    id="rc-tabs-0-tab-reports" aria-controls="rc-tabs-0-panel-reports">${translation('Reports')}</div>
                </div>
                <div data-node-key="notifications" class="ant-tabs-tab">
                  <div role="tab" aria-selected="false" class="ant-tabs-tab-btn" tabindex="-1"
                    id="rc-tabs-0-tab-notifications" aria-controls="rc-tabs-0-panel-notifications">${translation(
                        'Notifications'
                    )}</div>
                </div>
            </div>
            <div class="ant-tabs-nav-operations ant-tabs-nav-operations-hidden"><button type="button"
                class="ant-tabs-nav-more" aria-haspopup="listbox" aria-controls="rc-tabs-0-more-popup"
                id="rc-tabs-0-more" aria-expanded="false" style="visibility: hidden; order: 1;"><span role="img"
                  aria-label="ellipsis" class="anticon anticon-ellipsis"><svg viewBox="64 64 896 896" focusable="false"
                    data-icon="ellipsis" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                    <path
                      d="M176 511a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0z">
                    </path>
                  </svg></span></button></div>
          </div>
          <div class="ant-tabs-content-holder">
            <div class="ant-tabs-content ant-tabs-content-top">
              <div role="tabpanel" tabindex="0" aria-hidden="false" class="ant-tabs-tabpane ant-tabs-tabpane-active"
                id="rc-tabs-0-panel-overview" aria-labelledby="rc-tabs-0-tab-overview"></div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-4 gap-4 mb-6">
          <div class="ant-card ant-card-bordered shadow-sm css-zlwal1">
            <div class="ant-card-body">
              <div class="flex justify-between items-start">
                <div>
                  <p class="text-sm subtitle-gray m-0">${translation('Total Revenue')}</p>
                  <h3 class="text-2xl font-semibold my-1">${translation('$45,231.89')}</h3>
                  <p class="text-sm text-green-500 m-0">${translation('+20.1% from last month')}</p>
                </div><span role="img" aria-label="dollar" class="anticon anticon-dollar text-xl text-gray-400"><svg
                    viewBox="64 64 896 896" focusable="false" data-icon="dollar" width="1em" height="1em"
                    fill="currentColor" aria-hidden="true">
                    <path
                      d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372zm47.7-395.2l-25.4-5.9V348.6c38 5.2 61.5 29 65.5 58.2.5 4 3.9 6.9 7.9 6.9h44.9c4.7 0 8.4-4.1 8-8.8-6.1-62.3-57.4-102.3-125.9-109.2V263c0-4.4-3.6-8-8-8h-28.1c-4.4 0-8 3.6-8 8v33c-70.8 6.9-126.2 46-126.2 119 0 67.6 49.8 100.2 102.1 112.7l24.7 6.3v142.7c-44.2-5.9-69-29.5-74.1-61.3-.6-3.8-4-6.6-7.9-6.6H363c-4.7 0-8.4 4-8 8.7 4.5 55 46.2 105.6 135.2 112.1V761c0 4.4 3.6 8 8 8h28.4c4.4 0 8-3.6 8-8.1l-.2-31.7c78.3-6.9 134.3-48.8 134.3-124-.1-69.4-44.2-100.4-109-116.4zm-68.6-16.2c-5.6-1.6-10.3-3.1-15-5-33.8-12.2-49.5-31.9-49.5-57.3 0-36.3 27.5-57 64.5-61.7v124zM534.3 677V543.3c3.1.9 5.9 1.6 8.8 2.2 47.3 14.4 63.2 34.4 63.2 65.1 0 39.1-29.4 62.6-72 66.4z">
                    </path>
                  </svg></span>
              </div>
            </div>
          </div>
          <div class="ant-card ant-card-bordered shadow-sm css-zlwal1">
            <div class="ant-card-body">
              <div class="flex justify-between items-start">
                <div>
                  <p class="text-sm subtitle-gray m-0">${translation('Subscriptions')}</p>
                  <h3 class="text-2xl font-semibold my-1">${translation('+2350')}</h3>
                  <p class="text-sm text-green-500 m-0">${translation('+180.1% from last month')}</p>
                </div><span role="img" aria-label="usergroup-add"
                  class="anticon anticon-usergroup-add text-xl text-gray-400"><svg viewBox="64 64 896 896"
                    focusable="false" data-icon="usergroup-add" width="1em" height="1em" fill="currentColor"
                    aria-hidden="true">
                    <path
                      d="M892 772h-80v-80c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v80h-80c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h80v80c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-80h80c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM373.5 498.4c-.9-8.7-1.4-17.5-1.4-26.4 0-15.9 1.5-31.4 4.3-46.5.7-3.6-1.2-7.3-4.5-8.8-13.6-6.1-26.1-14.5-36.9-25.1a127.54 127.54 0 01-38.7-95.4c.9-32.1 13.8-62.6 36.3-85.6 24.7-25.3 57.9-39.1 93.2-38.7 31.9.3 62.7 12.6 86 34.4 7.9 7.4 14.7 15.6 20.4 24.4 2 3.1 5.9 4.4 9.3 3.2 17.6-6.1 36.2-10.4 55.3-12.4 5.6-.6 8.8-6.6 6.3-11.6-32.5-64.3-98.9-108.7-175.7-109.9-110.8-1.7-203.2 89.2-203.2 200 0 62.8 28.9 118.8 74.2 155.5-31.8 14.7-61.1 35-86.5 60.4-54.8 54.7-85.8 126.9-87.8 204a8 8 0 008 8.2h56.1c4.3 0 7.9-3.4 8-7.7 1.9-58 25.4-112.3 66.7-153.5 29.4-29.4 65.4-49.8 104.7-59.7 3.8-1.1 6.4-4.8 5.9-8.8zM824 472c0-109.4-87.9-198.3-196.9-200C516.3 270.3 424 361.2 424 472c0 62.8 29 118.8 74.2 155.5a300.95 300.95 0 00-86.4 60.4C357 742.6 326 814.8 324 891.8a8 8 0 008 8.2h56c4.3 0 7.9-3.4 8-7.7 1.9-58 25.4-112.3 66.7-153.5C505.8 695.7 563 672 624 672c110.4 0 200-89.5 200-200zm-109.5 90.5C690.3 586.7 658.2 600 624 600s-66.3-13.3-90.5-37.5a127.26 127.26 0 01-37.5-91.8c.3-32.8 13.4-64.5 36.3-88 24-24.6 56.1-38.3 90.4-38.7 33.9-.3 66.8 12.9 91 36.6 24.8 24.3 38.4 56.8 38.4 91.4-.1 34.2-13.4 66.3-37.6 90.5z">
                    </path>
                  </svg></span>
              </div>
            </div>
          </div>
          <div class="ant-card ant-card-bordered shadow-sm css-zlwal1">
            <div class="ant-card-body">
              <div class="flex justify-between items-start">
                <div>
                  <p class="text-sm subtitle-gray m-0">${translation('Sales')}</p>
                  <h3 class="text-2xl font-semibold my-1">+12,234</h3>
                  <p class="text-sm text-green-500 m-0">${translation('+19% from last month')}</p>
                </div><span role="img" aria-label="shopping-cart"
                  class="anticon anticon-shopping-cart text-xl text-gray-400"><svg viewBox="0 0 1024 1024"
                    focusable="false" data-icon="shopping-cart" width="1em" height="1em" fill="currentColor"
                    aria-hidden="true">
                    <path
                      d="M922.9 701.9H327.4l29.9-60.9 496.8-.9c16.8 0 31.2-12 34.2-28.6l68.8-385.1c1.8-10.1-.9-20.5-7.5-28.4a34.99 34.99 0 00-26.6-12.5l-632-2.1-5.4-25.4c-3.4-16.2-18-28-34.6-28H96.5a35.3 35.3 0 100 70.6h125.9L246 312.8l58.1 281.3-74.8 122.1a34.96 34.96 0 00-3 36.8c6 11.9 18.1 19.4 31.5 19.4h62.8a102.43 102.43 0 00-20.6 61.7c0 56.6 46 102.6 102.6 102.6s102.6-46 102.6-102.6c0-22.3-7.4-44-20.6-61.7h161.1a102.43 102.43 0 00-20.6 61.7c0 56.6 46 102.6 102.6 102.6s102.6-46 102.6-102.6c0-22.3-7.4-44-20.6-61.7H923c19.4 0 35.3-15.8 35.3-35.3a35.42 35.42 0 00-35.4-35.2zM305.7 253l575.8 1.9-56.4 315.8-452.3.8L305.7 253zm96.9 612.7c-17.4 0-31.6-14.2-31.6-31.6 0-17.4 14.2-31.6 31.6-31.6s31.6 14.2 31.6 31.6a31.6 31.6 0 01-31.6 31.6zm325.1 0c-17.4 0-31.6-14.2-31.6-31.6 0-17.4 14.2-31.6 31.6-31.6s31.6 14.2 31.6 31.6a31.6 31.6 0 01-31.6 31.6z">
                    </path>
                  </svg></span>
              </div>
            </div>
          </div>
          <div class="ant-card ant-card-bordered shadow-sm css-zlwal1">
            <div class="ant-card-body">
              <div class="flex justify-between items-start">
                <div>
                  <p class="text-sm subtitle-gray m-0">${translation('Active Now')}</p>
                  <h3 class="text-2xl font-semibold my-1">+573</h3>
                  <p class="text-sm text-green-500 m-0">${translation('+201 since last hour')}</p>
                </div><span role="img" aria-label="line-chart"
                  class="anticon anticon-line-chart text-xl text-gray-400"><svg viewBox="64 64 896 896"
                    focusable="false" data-icon="line-chart" width="1em" height="1em" fill="currentColor"
                    aria-hidden="true">
                    <path
                      d="M888 792H200V168c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v688c0 4.4 3.6 8 8 8h752c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM305.8 637.7c3.1 3.1 8.1 3.1 11.3 0l138.3-137.6L583 628.5c3.1 3.1 8.2 3.1 11.3 0l275.4-275.3c3.1-3.1 3.1-8.2 0-11.3l-39.6-39.6a8.03 8.03 0 00-11.3 0l-230 229.9L461.4 404a8.03 8.03 0 00-11.3 0L266.3 586.7a8.03 8.03 0 000 11.3l39.5 39.7z">
                    </path>
                  </svg></span>
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-5 gap-6">
          <div class="col-span-3">
            <div class="ant-card ant-card-bordered shadow-sm css-zlwal1">
              <div class="ant-card-head">
                <div class="ant-card-head-wrapper">
                  <div class="ant-card-head-title">${translation('Overview')}</div>
                </div>
              </div>
              <div class="ant-card-body">
                <div class="recharts-wrapper" style="position: relative; cursor: default; width: 700px; height: 300px;">
                  <svg class="recharts-surface" width="700" height="300" viewBox="0 0 700 300"
                    style="width: 100%; height: 100%;">
                    <title></title>
                    <desc></desc>
                    <defs>
                      <clipPath id="recharts1-clip">
                        <rect x="60" y="10" height="240" width="640"></rect>
                      </clipPath>
                    </defs>
                    <g class="recharts-cartesian-grid">
                      <g class="recharts-cartesian-grid-horizontal">
                        <line stroke-dasharray="3 3" stroke="var(--axis-color)" fill="none" x="60" y="10" width="640" height="240"
                          x1="60" y1="250" x2="700" y2="250"></line>
                        <line stroke-dasharray="3 3" stroke="var(--axis-color)" fill="none" x="60" y="10" width="640" height="240"
                          x1="60" y1="190" x2="700" y2="190"></line>
                        <line stroke-dasharray="3 3" stroke="var(--axis-color)" fill="none" x="60" y="10" width="640" height="240"
                          x1="60" y1="130" x2="700" y2="130"></line>
                        <line stroke-dasharray="3 3" stroke="var(--axis-color)" fill="none" x="60" y="10" width="640" height="240"
                          x1="60" y1="70" x2="700" y2="70"></line>
                        <line stroke-dasharray="3 3" stroke="var(--axis-color)" fill="none" x="60" y="10" width="640" height="240"
                          x1="60" y1="10" x2="700" y2="10"></line>
                      </g>
                    </g>
                    <g class="recharts-layer recharts-cartesian-axis recharts-xAxis xAxis">
                      <line orientation="bottom" width="640" height="30" x="60" y="250"
                        class="recharts-cartesian-axis-line" stroke="#666" fill="none" x1="60" y1="250" x2="700"
                        y2="250">
                      </line>
                      <g class="recharts-cartesian-axis-ticks">
                        <g class="recharts-layer recharts-cartesian-axis-tick">
                          <line orientation="bottom" width="640" height="30" x="60" y="250"
                            class="recharts-cartesian-axis-tick-line" stroke="#666" fill="none" x1="86.66666666666667"
                            y1="256" x2="86.66666666666667" y2="250"></line><text orientation="bottom" width="640"
                            height="30" stroke="none" x="86.66666666666667" y="258"
                            class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="middle" fill="#666">
                            <tspan x="86.66666666666667" dy="0.71em">Jan</tspan>
                          </text>
                        </g>
                        <g class="recharts-layer recharts-cartesian-axis-tick">
                          <line orientation="bottom" width="640" height="30" x="60" y="250"
                            class="recharts-cartesian-axis-tick-line" stroke="#666" fill="none" x1="140" y1="256"
                            x2="140" y2="250"></line><text orientation="bottom" width="640" height="30" stroke="none"
                            x="140" y="258" class="recharts-text recharts-cartesian-axis-tick-value"
                            text-anchor="middle" fill="#666">
                            <tspan x="140" dy="0.71em">Feb</tspan>
                          </text>
                        </g>
                        <g class="recharts-layer recharts-cartesian-axis-tick">
                          <line orientation="bottom" width="640" height="30" x="60" y="250"
                            class="recharts-cartesian-axis-tick-line" stroke="#666" fill="none" x1="193.33333333333334"
                            y1="256" x2="193.33333333333334" y2="250"></line><text orientation="bottom" width="640"
                            height="30" stroke="none" x="193.33333333333334" y="258"
                            class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="middle" fill="#666">
                            <tspan x="193.33333333333334" dy="0.71em">Mar</tspan>
                          </text>
                        </g>
                        <g class="recharts-layer recharts-cartesian-axis-tick">
                          <line orientation="bottom" width="640" height="30" x="60" y="250"
                            class="recharts-cartesian-axis-tick-line" stroke="#666" fill="none" x1="246.66666666666666"
                            y1="256" x2="246.66666666666666" y2="250"></line><text orientation="bottom" width="640"
                            height="30" stroke="none" x="246.66666666666666" y="258"
                            class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="middle" fill="#666">
                            <tspan x="246.66666666666666" dy="0.71em">Apr</tspan>
                          </text>
                        </g>
                        <g class="recharts-layer recharts-cartesian-axis-tick">
                          <line orientation="bottom" width="640" height="30" x="60" y="250"
                            class="recharts-cartesian-axis-tick-line" stroke="#666" fill="none" x1="300.00000000000006"
                            y1="256" x2="300.00000000000006" y2="250"></line><text orientation="bottom" width="640"
                            height="30" stroke="none" x="300.00000000000006" y="258"
                            class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="middle" fill="#666">
                            <tspan x="300.00000000000006" dy="0.71em">May</tspan>
                          </text>
                        </g>
                        <g class="recharts-layer recharts-cartesian-axis-tick">
                          <line orientation="bottom" width="640" height="30" x="60" y="250"
                            class="recharts-cartesian-axis-tick-line" stroke="#666" fill="none" x1="353.33333333333337"
                            y1="256" x2="353.33333333333337" y2="250"></line><text orientation="bottom" width="640"
                            height="30" stroke="none" x="353.33333333333337" y="258"
                            class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="middle" fill="#666">
                            <tspan x="353.33333333333337" dy="0.71em">Jun</tspan>
                          </text>
                        </g>
                        <g class="recharts-layer recharts-cartesian-axis-tick">
                          <line orientation="bottom" width="640" height="30" x="60" y="250"
                            class="recharts-cartesian-axis-tick-line" stroke="#666" fill="none" x1="406.6666666666667"
                            y1="256" x2="406.6666666666667" y2="250"></line><text orientation="bottom" width="640"
                            height="30" stroke="none" x="406.6666666666667" y="258"
                            class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="middle" fill="#666">
                            <tspan x="406.6666666666667" dy="0.71em">Jul</tspan>
                          </text>
                        </g>
                        <g class="recharts-layer recharts-cartesian-axis-tick">
                          <line orientation="bottom" width="640" height="30" x="60" y="250"
                            class="recharts-cartesian-axis-tick-line" stroke="#666" fill="none" x1="460.00000000000006"
                            y1="256" x2="460.00000000000006" y2="250"></line><text orientation="bottom" width="640"
                            height="30" stroke="none" x="460.00000000000006" y="258"
                            class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="middle" fill="#666">
                            <tspan x="460.00000000000006" dy="0.71em">Aug</tspan>
                          </text>
                        </g>
                        <g class="recharts-layer recharts-cartesian-axis-tick">
                          <line orientation="bottom" width="640" height="30" x="60" y="250"
                            class="recharts-cartesian-axis-tick-line" stroke="#666" fill="none" x1="513.3333333333334"
                            y1="256" x2="513.3333333333334" y2="250"></line><text orientation="bottom" width="640"
                            height="30" stroke="none" x="513.3333333333334" y="258"
                            class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="middle" fill="#666">
                            <tspan x="513.3333333333334" dy="0.71em">Sep</tspan>
                          </text>
                        </g>
                        <g class="recharts-layer recharts-cartesian-axis-tick">
                          <line orientation="bottom" width="640" height="30" x="60" y="250"
                            class="recharts-cartesian-axis-tick-line" stroke="#666" fill="none" x1="566.6666666666666"
                            y1="256" x2="566.6666666666666" y2="250"></line><text orientation="bottom" width="640"
                            height="30" stroke="none" x="566.6666666666666" y="258"
                            class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="middle" fill="#666">
                            <tspan x="566.6666666666666" dy="0.71em">Oct</tspan>
                          </text>
                        </g>
                        <g class="recharts-layer recharts-cartesian-axis-tick">
                          <line orientation="bottom" width="640" height="30" x="60" y="250"
                            class="recharts-cartesian-axis-tick-line" stroke="#666" fill="none" x1="620" y1="256"
                            x2="620" y2="250"></line><text orientation="bottom" width="640" height="30" stroke="none"
                            x="620" y="258" class="recharts-text recharts-cartesian-axis-tick-value"
                            text-anchor="middle" fill="#666">
                            <tspan x="620" dy="0.71em">Nov</tspan>
                          </text>
                        </g>
                        <g class="recharts-layer recharts-cartesian-axis-tick">
                          <line orientation="bottom" width="640" height="30" x="60" y="250"
                            class="recharts-cartesian-axis-tick-line" stroke="#666" fill="none" x1="673.3333333333334"
                            y1="256" x2="673.3333333333334" y2="250"></line><text orientation="bottom" width="640"
                            height="30" stroke="none" x="673.3333333333334" y="258"
                            class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="middle" fill="#666">
                            <tspan x="673.3333333333334" dy="0.71em">Dec</tspan>
                          </text>
                        </g>
                      </g>
                    </g>
                    <g class="recharts-layer recharts-cartesian-axis recharts-yAxis yAxis">
                      <line orientation="left" width="60" height="240" x="0" y="10" class="recharts-cartesian-axis-line"
                        stroke="#666" fill="none" x1="60" y1="10" x2="60" y2="250"></line>
                      <g class="recharts-cartesian-axis-ticks">
                        <g class="recharts-layer recharts-cartesian-axis-tick">
                          <line orientation="left" width="60" height="240" x="0" y="10"
                            class="recharts-cartesian-axis-tick-line" stroke="#666" fill="none" x1="54" y1="250" x2="60"
                            y2="250"></line><text orientation="left" width="60" height="240" stroke="none" x="52"
                            y="250" class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="end"
                            fill="#666">
                            <tspan x="52" dy="0.355em">0</tspan>
                          </text>
                        </g>
                        <g class="recharts-layer recharts-cartesian-axis-tick">
                          <line orientation="left" width="60" height="240" x="0" y="10"
                            class="recharts-cartesian-axis-tick-line" stroke="#666" fill="none" x1="54" y1="190" x2="60"
                            y2="190"></line><text orientation="left" width="60" height="240" stroke="none" x="52"
                            y="190" class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="end"
                            fill="#666">
                            <tspan x="52" dy="0.355em">1500</tspan>
                          </text>
                        </g>
                        <g class="recharts-layer recharts-cartesian-axis-tick">
                          <line orientation="left" width="60" height="240" x="0" y="10"
                            class="recharts-cartesian-axis-tick-line" stroke="#666" fill="none" x1="54" y1="130" x2="60"
                            y2="130"></line><text orientation="left" width="60" height="240" stroke="none" x="52"
                            y="130" class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="end"
                            fill="#666">
                            <tspan x="52" dy="0.355em">3000</tspan>
                          </text>
                        </g>
                        <g class="recharts-layer recharts-cartesian-axis-tick">
                          <line orientation="left" width="60" height="240" x="0" y="10"
                            class="recharts-cartesian-axis-tick-line" stroke="#666" fill="none" x1="54" y1="70" x2="60"
                            y2="70"></line><text orientation="left" width="60" height="240" stroke="none" x="52" y="70"
                            class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="end" fill="#666">
                            <tspan x="52" dy="0.355em">4500</tspan>
                          </text>
                        </g>
                        <g class="recharts-layer recharts-cartesian-axis-tick">
                          <line orientation="left" width="60" height="240" x="0" y="10"
                            class="recharts-cartesian-axis-tick-line" stroke="#666" fill="none" x1="54" y1="10" x2="60"
                            y2="10"></line><text orientation="left" width="60" height="240" stroke="none" x="52"
                            y="10.5" class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="end"
                            fill="#666">
                            <tspan x="52" dy="0.355em">6000</tspan>
                          </text>
                        </g>
                      </g>
                    </g>
                    <g class="recharts-layer recharts-bar">
                      <g class="recharts-layer recharts-bar-rectangles">
                        <g class="recharts-layer">
                          <g class="recharts-layer recharts-bar-rectangle">
                            <path x="65.33333333333333" y="70" width="42" height="180" radius="0" fill="var(--ant-primary)"
                              class="recharts-rectangle" d="M 65.33333333333333,70 h 42 v 180 h -42 Z"></path>
                          </g>
                          <g class="recharts-layer recharts-bar-rectangle">
                            <path x="118.66666666666667" y="190" width="42" height="60" radius="0" fill="var(--ant-primary)"
                              class="recharts-rectangle" d="M 118.66666666666667,190 h 42 v 60 h -42 Z"></path>
                          </g>
                          <g class="recharts-layer recharts-bar-rectangle">
                            <path x="172.00000000000003" y="138" width="42" height="112" radius="0" fill="var(--ant-primary)"
                              class="recharts-rectangle" d="M 172.00000000000003,138 h 42 v 112 h -42 Z"></path>
                          </g>
                          <g class="recharts-layer recharts-bar-rectangle">
                            <path x="225.33333333333334" y="210" width="42" height="40" radius="0" fill="var(--ant-primary)"
                              class="recharts-rectangle" d="M 225.33333333333334,210 h 42 v 40 h -42 Z"></path>
                          </g>
                          <g class="recharts-layer recharts-bar-rectangle">
                            <path x="278.6666666666667" y="190" width="42" height="60" radius="0" fill="var(--ant-primary)"
                              class="recharts-rectangle" d="M 278.6666666666667,190 h 42 v 60 h -42 Z"></path>
                          </g>
                          <g class="recharts-layer recharts-bar-rectangle">
                            <path x="332" y="58" width="42" height="192" radius="0" fill="var(--ant-primary)"
                              class="recharts-rectangle" d="M 332,58 h 42 v 192 h -42 Z"></path>
                          </g>
                          <g class="recharts-layer recharts-bar-rectangle">
                            <path x="385.3333333333333" y="82" width="42" height="168" radius="0" fill="var(--ant-primary)"
                              class="recharts-rectangle" d="M 385.3333333333333,82 h 42 v 168 h -42 Z"></path>
                          </g>
                          <g class="recharts-layer recharts-bar-rectangle">
                            <path x="438.6666666666667" y="202" width="42" height="48" radius="0" fill="var(--ant-primary)"
                              class="recharts-rectangle" d="M 438.6666666666667,202 h 42 v 48 h -42 Z"></path>
                          </g>
                          <g class="recharts-layer recharts-bar-rectangle">
                            <path x="492" y="122" width="42" height="128" radius="0" fill="var(--ant-primary)"
                              class="recharts-rectangle" d="M 492,122 h 42 v 128 h -42 Z"></path>
                          </g>
                          <g class="recharts-layer recharts-bar-rectangle">
                            <path x="545.3333333333334" y="170.00000000000003" width="42" height="79.99999999999997"
                              radius="0" fill="var(--ant-primary)" class="recharts-rectangle"
                              d="M 545.3333333333334,170.00000000000003 h 42 v 79.99999999999997 h -42 Z"></path>
                          </g>
                          <g class="recharts-layer recharts-bar-rectangle">
                            <path x="598.6666666666667" y="110" width="42" height="140" radius="0" fill="var(--ant-primary)"
                              class="recharts-rectangle" d="M 598.6666666666667,110 h 42 v 140 h -42 Z"></path>
                          </g>
                          <g class="recharts-layer recharts-bar-rectangle">
                            <path x="652.0000000000001" y="178" width="42" height="72" radius="0" fill="var(--ant-primary)"
                              class="recharts-rectangle" d="M 652.0000000000001,178 h 42 v 72 h -42 Z"></path>
                          </g>
                        </g>
                      </g>
                      <g class="recharts-layer"></g>
                    </g>
                  </svg>
                  <div tabindex="-1"
                    class="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom"
                    style="visibility: hidden; pointer-events: none; position: absolute; top: 0px; left: 0px; transform: translate(60px, 10px);">
                    <div class="recharts-default-tooltip"
                      style="margin: 0px; padding: 10px; background-color: rgb(255, 255, 255); border: 1px solid rgb(204, 204, 204); white-space: nowrap;">
                      <p class="recharts-tooltip-label" style="margin: 0px;"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-2">
            <div class="ant-card ant-card-bordered shadow-sm css-zlwal1">
              <div class="ant-card-head">
                <div class="ant-card-head-wrapper">
                  <div class="ant-card-head-title">${translation('Recent Sales')}</div>
                  <div class="ant-card-extra"><span class="text-gray-500">${translation(
                      'You made 265 sales this month.'
                  )}</span></div>
                </div>
              </div>
              <div class="ant-card-body">
                <div class="ant-list ant-list-split css-zlwal1">
                  <div class="ant-spin-nested-loading css-zlwal1">
                    <div class="ant-spin-container">
                      <ul class="ant-list-items">
                        <li class="ant-list-item flex justify-between py-3">
                          <div class="flex items-center gap-3"><span
                              class="ant-avatar ant-avatar-circle ant-avatar-icon css-zlwal1"><span role="img"
                                aria-label="user" class="anticon anticon-user"><svg viewBox="64 64 896 896"
                                  focusable="false" data-icon="user" width="1em" height="1em" fill="currentColor"
                                  aria-hidden="true">
                                  <path
                                    d="M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z">
                                  </path>
                                </svg></span></span>
                            <div>
                              <p class="m-0 font-medium">${translation('Olivia Martin')}</p>
                              <p class="m-0 text-sm text-gray-500">${translation('olivia.martin@email.com')}</p>
                            </div>
                          </div><span class="font-semibold">${translation('+$1,999.00')}</span>
                        </li>
                        <li class="ant-list-item flex justify-between py-3">
                          <div class="flex items-center gap-3"><span
                              class="ant-avatar ant-avatar-circle ant-avatar-icon css-zlwal1"><span role="img"
                                aria-label="user" class="anticon anticon-user"><svg viewBox="64 64 896 896"
                                  focusable="false" data-icon="user" width="1em" height="1em" fill="currentColor"
                                  aria-hidden="true">
                                  <path
                                    d="M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z">
                                  </path>
                                </svg></span></span>
                            <div>
                              <p class="m-0 font-medium">${translation('Jackson Lee')}</p>
                              <p class="m-0 text-sm text-gray-500">${translation('jackson.lee@email.com')}</p>
                            </div>
                          </div><span class="font-semibold">${translation('+$39.00')}</span>
                        </li>
                        <li class="ant-list-item flex justify-between py-3">
                          <div class="flex items-center gap-3"><span
                              class="ant-avatar ant-avatar-circle ant-avatar-icon css-zlwal1"><span role="img"
                                aria-label="user" class="anticon anticon-user"><svg viewBox="64 64 896 896"
                                  focusable="false" data-icon="user" width="1em" height="1em" fill="currentColor"
                                  aria-hidden="true">
                                  <path
                                    d="M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z">
                                  </path>
                                </svg></span></span>
                            <div>
                              <p class="m-0 font-medium">${translation('Isabella Nguyen')}</p>
                              <p class="m-0 text-sm text-gray-500">${translation('isabella.nguyen@email.com')}</p>
                            </div>
                          </div><span class="font-semibold">${translation('+$299.00')}</span>
                        </li>
                        <li class="ant-list-item flex justify-between py-3">
                          <div class="flex items-center gap-3"><span
                              class="ant-avatar ant-avatar-circle ant-avatar-icon css-zlwal1"><span role="img"
                                aria-label="user" class="anticon anticon-user"><svg viewBox="64 64 896 896"
                                  focusable="false" data-icon="user" width="1em" height="1em" fill="currentColor"
                                  aria-hidden="true">
                                  <path
                                    d="M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z">
                                  </path>
                                </svg></span></span>
                            <div>
                              <p class="m-0 font-medium">${translation('William Kim')}</p>
                              <p class="m-0 text-sm text-gray-500">${translation('will@email.com')}</p>
                            </div>
                          </div><span class="font-semibold">${translation('+$99.00')}</span>
                        </li>
                        <li class="ant-list-item flex justify-between py-3">
                          <div class="flex items-center gap-3"><span
                              class="ant-avatar ant-avatar-circle ant-avatar-icon css-zlwal1"><span role="img"
                                aria-label="user" class="anticon anticon-user"><svg viewBox="64 64 896 896"
                                  focusable="false" data-icon="user" width="1em" height="1em" fill="currentColor"
                                  aria-hidden="true">
                                  <path
                                    d="M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z">
                                  </path>
                                </svg></span></span>
                            <div>
                              <p class="m-0 font-medium">${translation('Sofia Davis')}</p>
                              <p class="m-0 text-sm text-gray-500">${translation('sofia.davis@email.com')}</p>
                            </div>
                          </div><span class="font-semibold">${translation('+$39.00')}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
`
