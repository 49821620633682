import { command, type Command } from 'ccstate'
import { createBrowserRouter, LoaderFunctionArgs, RouteObject } from 'react-router-dom'
import { RESET$ } from '../ccstate-helper/action'
import { traceable } from '../ccstate-helper/traceable'
import { createSignalRouterDecorator, RouterOptions } from './signal-react-router'
import { TraceableAbortSignal } from './traceable-abort-controller'

export type CCStateRouteObject = Omit<RouteObject, 'loader' | 'children'> & {
    setup$?:
        | Command<null, [TraceableAbortSignal, LoaderFunctionArgs]>
        | Command<Promise<null>, [TraceableAbortSignal, LoaderFunctionArgs]>
        | Command<Response, [TraceableAbortSignal, LoaderFunctionArgs]>
        | Command<Promise<Response>, [TraceableAbortSignal, LoaderFunctionArgs]>
        | Command<NonNullable<unknown>, [TraceableAbortSignal, LoaderFunctionArgs]>
        | Command<Promise<NonNullable<unknown>>, [TraceableAbortSignal, LoaderFunctionArgs]>
        | Command<null, [typeof RESET$]>
        | Command<Promise<null>, [typeof RESET$]>
        | Command<Response, [typeof RESET$]>
        | Command<Promise<Response>, [typeof RESET$]>
        | Command<NonNullable<unknown>, [typeof RESET$]>
        | Command<Promise<NonNullable<unknown>>, [typeof RESET$]>
    children?: CCStateRouteObject[]
}

/**
 * 创建一个由 createSignalRouterDecorator 驱动的 browserRouter
 * 每一层级的 loader 共享一个 signal
 * 当发生路由切换时，如：A1/B1/C1 切换到 A2，A1/B1/C1 的 signal 会被 abort
 * 而 A1/B1/C1 切换到 A1/B1/C2 时，只有 C2 的 signal 会被 abort
 * 此方法填入的 routes 应该是个静态的
 *
 * setupAtom 是被 atom 包装的 loader，其实现 & 行为和 loader 一致
 * 如果返回一个 async，视图会等待结束后再进行渲染
 * 但不同的是，setup$ 仅在 setup 时触发一次，等于 loader + shouldRevalidate: false 的组合
 */
export const createSignalBrowserRouter$ = traceable(
    'hulh01@kanyun.com',
    command(({ set }, signal: TraceableAbortSignal, routes: CCStateRouteObject[], options?: RouterOptions) => {
        const signalRouterDecorator = createSignalRouterDecorator(signal)

        const transfromAtomToLoader = (route: CCStateRouteObject): RouteObject => {
            const setup$ = route.setup$

            return Object.assign({}, route, {
                setupAtom: undefined,
                loader: (args: LoaderFunctionArgs, ctx: unknown) => {
                    if (setup$) {
                        // @ts-expect-error
                        return set(setup$, (ctx as { signal: TraceableAbortSignal }).signal, args)
                    }
                    return null
                },
                children: route.children?.map(transfromAtomToLoader),
                shouldRevalidate: route.shouldRevalidate ?? (() => false),
            }) as RouteObject
        }

        return createBrowserRouter(...signalRouterDecorator(routes.map(transfromAtomToLoader), options))
    })
)
