import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { useMemo } from 'react'
import { MonoIconCommonEnterRight16 } from '../../../../../../../../ui-lib/src'
import { useLibraryComponentService } from '../../../../../../main/app-context'
import { LibraryTestId } from '../../../../../../window'
import classes from './preview-library-folder-item.module.less'

export function PreviewLibraryFolderItem(props: {
    folder: Wukong.DocumentProto.IVLibraryAssetPanelFolderItem | null | undefined
}) {
    const { libraryAssetPanelExpandService } = useLibraryComponentService()

    const title = useMemo(
        () => (
            <>
                {!!props.folder?.prefixes?.length && (
                    <span className={classes.titlePrefix}>{props.folder.prefixes.join(' / ')}&nbsp;/&nbsp;</span>
                )}
                <span className={classes.titleSuffix}>{props.folder?.suffixes?.join(' / ')}</span>
            </>
        ),
        [props.folder?.prefixes, props.folder?.suffixes]
    )

    const onToggleCollapse = () => {
        libraryAssetPanelExpandService.changeExpandStatus(props.folder?.expandKey, !props.folder?.expand)
    }

    return props.folder ? (
        <div
            className={classnames(classes.container, { [classes.expanded]: props.folder?.expand })}
            onClick={onToggleCollapse}
            style={{ height: props.folder?.height ?? 0, top: props.folder?.top ?? 0 }}
            data-testid={LibraryTestId.AssetPanel.FolderItem}
        >
            <MonoIconCommonEnterRight16 className={classes.arrow} />
            <span className={classes.title}>{title}</span>
        </div>
    ) : (
        <></>
    )
}
