import { translation } from './comments.translation'
/* eslint-disable no-restricted-imports */
import { useCallback } from 'react'
import { ScrollView } from '../../../../../../ui-lib/src'
import { useCommentService } from '../../../../main/app-context'
import { CommentDataTestId } from '../../../../window'
import {
    useActiveCommentId,
    useCommentNoticeType,
    useCommentSearchString,
    useCommentShowFilters,
    useCommentsList,
    useCommentSortType,
} from '../comment-service/comment-service-hook'
import { Comment } from './comment'
import { CommentSearch } from './comment-search'
import classes from './comments.module.less'
import { NoCommentsYet } from './no-comments-yet'
import { ShowFilterType, SortType } from './type'

export function Comments() {
    const commentService = useCommentService()
    const activeCommentId = useActiveCommentId()
    const commentsList = useCommentsList()
    const sortType = useCommentSortType()
    const showFilters = useCommentShowFilters()
    const value = useCommentSearchString()
    const commentNoticeType = useCommentNoticeType()

    const onSortByTime = useCallback(() => {
        commentService.setCommentSortType(SortType.Time)
    }, [commentService])

    const onSortByUnread = useCallback(() => {
        commentService.setCommentSortType(SortType.Unread)
    }, [commentService])

    const onShowTypeChange = useCallback(
        (filter: ShowFilterType[]) => {
            commentService.setCommentShowFilters(filter)
        },
        [commentService]
    )

    const tryCloseOpenCommentDetail = useCallback(() => {
        if (commentService.hasOpenComment() && !commentService.checkMouseEventExist()) {
            commentService.closeOpenComment()
        }
    }, [commentService])

    return (
        <div className={classes.commentPanel} data-testid="comment-panel">
            <CommentSearch
                sortType={sortType}
                showFilters={showFilters}
                noticeType={commentNoticeType}
                value={value}
                onChange={commentService.setCommentSearchString}
                onSortByTime={onSortByTime}
                onSortByUnread={onSortByUnread}
                onShowTypeChange={onShowTypeChange}
                onSwitchNoticeType={commentService.setCommentNoticeType}
                onClickIcon={tryCloseOpenCommentDetail}
            />
            {commentsList.length > 0 ? (
                <ScrollView selectKey={activeCommentId} block="start" data-testid={CommentDataTestId.CommentPanel}>
                    {commentsList.map(({ commentMetaData }) => (
                        <ScrollView.Item
                            key={commentMetaData.id}
                            uniqueKey={commentMetaData.id}
                            data-testid={CommentDataTestId.CommentPanelItem}
                        >
                            <Comment meta={commentMetaData} />
                        </ScrollView.Item>
                    ))}
                </ScrollView>
            ) : value ? (
                <div className={classes.emptyResult}>{translation('NoCommentsMatched')}</div>
            ) : (
                <NoCommentsYet />
            )}
        </div>
    )
}
