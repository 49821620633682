import { GET, POST, PUT, ResponseType } from '@tutor/network-core'
import { DocWithAuthorityVO } from '../interface/type'
import {
    CreateVersionParams,
    GetHistoryVersionResult,
    HistoryVersion,
    HistoryVersionInfo,
} from '../interface/history-version'
import { BaseCommonRequest } from './base-request'

// 分页查询文档版本历史
@GET
export class GetHistoryVersion extends BaseCommonRequest<GetHistoryVersionResult> {
    constructor(
        private docId: string,
        private pageSize: number,
        private startId = -1,
        private onlyMine = false,
        private includeAutoSave = true
    ) {
        super()
    }

    public override requestArgument() {
        return {
            pageSize: this.pageSize,
            startId: this.startId,
            onlyMine: this.onlyMine.toString(),
            includeAutoSave: this.includeAutoSave.toString(),
        }
    }

    public override requestUrl() {
        return `documents/version/${this.docId}`
    }
}

// 将指定版本复制为新文档
@POST
export class CopyHistoryVersionAsNewFile extends BaseCommonRequest<DocWithAuthorityVO> {
    constructor(private docId: string, private versionId: number) {
        super()
    }

    public override requestArgument() {
        return { versionId: this.versionId }
    }

    public override requestUrl() {
        return `documents/version/${this.docId}/copy`
    }
}

// 创建新版本
@POST
export class CreateVersion extends BaseCommonRequest<HistoryVersion> {
    constructor(private createInfo: CreateVersionParams) {
        super()
    }

    public override requestBody() {
        return this.createInfo
    }

    public override requestUrl() {
        return `documents/version`
    }
}

// 将文档恢复到指定版本
@PUT
export class ApplyHistoryVersion extends BaseCommonRequest<void> {
    constructor(private docId: string, private versionId: number) {
        super()
    }

    public override requestBody() {
        return {
            docId: this.docId,
            versionId: this.versionId,
        }
    }

    public override requestUrl() {
        return `documents/version`
    }
}

// 修改版本名称和描述
@PUT
export class EditHistoryVersionInfo extends BaseCommonRequest<HistoryVersion> {
    constructor(private docId: string, private versionId: number, private info: HistoryVersionInfo) {
        super()
    }

    public override requestBody() {
        return {
            docId: this.docId,
            versionId: this.versionId,
            ...this.info,
        }
    }

    public override requestUrl() {
        return `documents/version/name-desc`
    }
}

// 分页查询文档版本历史
@GET
export class GetHistoryVersionData extends BaseCommonRequest<ArrayBuffer> {
    constructor(
        private currentDocumentVersionId: number,
        private lastTimeReturnPagedArchive: boolean,
        private lastDocumentVersionId?: number,
        private currentPageNodeId?: string
    ) {
        super()
    }

    public override requestArgument() {
        return {
            currentDocumentVersionId: this.currentDocumentVersionId,
            lastTimeReturnPagedArchive: String(this.lastTimeReturnPagedArchive),
            lastDocumentVersionId: this.lastDocumentVersionId!,
            currentPageNodeId: this.currentPageNodeId!,
        }
    }

    public override requestUrl() {
        return 'documents/version/history-context'
    }

    public override responseType() {
        return ResponseType.Arraybuffer
    }
}
