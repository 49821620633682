import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    NoResultsIn: '无搜索结果',
} as const

export const enTranslation = {
    NoResultsIn: 'No results in this file',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
