/* eslint-disable no-restricted-imports */
import { InspectMissingFontsCommand, TextReplaceFontsCommand, Wukong } from '@wukong/bridge-proto'
import { keyBy } from 'lodash-es'
import { useCallback, useEffect, useMemo } from 'react'
import { isNotNullOrUndefined } from '../../../../util/src'
import { FontInfo, FontName } from '../../document/node/node'
import { getFontNameUid, getFontNameUidSet } from '../../document/util/font'
import { useAppContext } from '../../main/app-context'
import { useViewState } from '../../view-state-bridge'
import { MissFont } from '../component/top-area/tool-font-miss/interface'
import { useCommand } from './document-context'

export function useDocumentMissFont(fonts: FontInfo[], openDialog: () => void) {
    const command = useCommand()
    const documentUsingFontName = useViewState('documentUsingFontName', { keep: true, infos: [] })
    const { uid2CountMap, uid2FullFontName } = useMemo(() => {
        const countMap: Record<string, number> = {}
        const fullFontNameMap: Record<string, Wukong.DocumentProto.IFullFontName> = {}
        documentUsingFontName.infos.forEach((fontInfo) => {
            const uid = getFontNameUid(fontInfo.fontName)
            countMap[uid] = fontInfo.usedNodeCount
            fullFontNameMap[uid] = fontInfo.fullFontName
        })
        return {
            uid2CountMap: countMap,
            uid2FullFontName: fullFontNameMap,
        }
    }, [documentUsingFontName])

    const availableFontNameUidSet = useMemo(() => getFontNameUidSet(fonts), [fonts])
    const fontManager = useAppContext().fontManagerService

    /**
     * 提供文本节点是否可编辑的方法给wasm
     */
    useEffect(() => {
        fontManager.setHandleTextMissingFontCallback(() => openDialog())
        fontManager.setOpenFontMissingDialogCallback(openDialog)
        fontManager.setCheckFontMissingCallback((proto) => {
            const fontName = proto! as FontName
            const missing = !availableFontNameUidSet.has(getFontNameUid(fontName))
            return { value: missing }
        })
    }, [availableFontNameUidSet, fontManager, openDialog])

    const missFont: MissFont = useMemo(() => {
        const missFontNames = documentUsingFontName.infos
            .map((item) => item.fontName as FontName)
            .filter((fontName) => !availableFontNameUidSet.has(getFontNameUid(fontName)))
            .map((item) => ({ ...item, localizedStyle: item.style, localizedFamily: item.family }))

        return keyBy(missFontNames, getFontNameUid)
    }, [documentUsingFontName.infos, availableFontNameUidSet])

    const inspectMissFont = useCallback(
        (missFontName: FontName) => {
            command.DEPRECATED_invokeBridge(InspectMissingFontsCommand, missFontName)
            command.commitUndo()
        },
        [command]
    )

    const replaceMissFont = useCallback(
        (targetFontNames: MissFont) => {
            const replaceMissingFonts = Object.entries(targetFontNames)
                .map(([missFontNameUid, targetFont]) => {
                    if (missFont[missFontNameUid]) {
                        return {
                            fromFontName: missFont[missFontNameUid],
                            toFontName: targetFont,
                        }
                    }
                })
                .filter(isNotNullOrUndefined)
            command.DEPRECATED_invokeBridge(TextReplaceFontsCommand, { replaceMissingFonts })
            command.commitUndo()
        },
        [command, missFont]
    )

    return {
        missFont,
        inspectMissFont,
        replaceMissFont,
        uid2CountMap,
        uid2FullFontName,
    }
}
