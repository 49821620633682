import { Wukong } from '@wukong/bridge-proto'
import { useViewState, type DeepRequired } from '../../../../../view-state-bridge'
import { DevModeBoxModelTestId } from '../../../../../window'
import classes from './box-model.module.less'
import { BorderRadiusBox } from './layout/border-radius'
import { CommonAssetContentBox, ContentBox, ImageAssetContentBox } from './layout/content'
import { MarginBox } from './layout/margin'
import { PaddingBox } from './layout/padding'
import { TextBox } from './text/text'

export function DevModeInspectBoxModel() {
    const codeViewState = useViewState('devModeInspectBoxModelViewState')

    switch (codeViewState?.showType) {
        case Wukong.DocumentProto.DevModeInspectBoxModelShowType.DEV_MODE_INSPECT_BOX_MODEL_SHOW_TYPE_LAYOUT:
            return <LayoutBoxModel value={codeViewState.layoutValue} />
        case Wukong.DocumentProto.DevModeInspectBoxModelShowType.DEV_MODE_INSPECT_BOX_MODEL_SHOW_TYPE_TEXT:
            return <TextBoxModel value={codeViewState.textValue} />
        case Wukong.DocumentProto.DevModeInspectBoxModelShowType.DEV_MODE_INSPECT_BOX_MODEL_SHOW_TYPE_COMMON_ASSET:
            return <CommonAssetBoxModel value={codeViewState.commonAssetValue} />
        case Wukong.DocumentProto.DevModeInspectBoxModelShowType.DEV_MODE_INSPECT_BOX_MODEL_SHOW_TYPE_IMAGE_ASSET:
            return <ImageAssetBoxModel value={codeViewState.imageAssetValue} />
        default:
            return <></>
    }
}

function LayoutBoxModel(props: { value: DeepRequired<Wukong.DocumentProto.IDevModeInspectBoxModelLayout> }) {
    return (
        <div className={classes.layoutBoxModelContainer} data-testid={DevModeBoxModelTestId.Container}>
            <MarginBox value={props.value}>
                <BorderRadiusBox value={props.value}>
                    <PaddingBox value={props.value}>
                        <ContentBox value={props.value} />
                    </PaddingBox>
                </BorderRadiusBox>
            </MarginBox>
        </div>
    )
}

function TextBoxModel(props: { value: DeepRequired<Wukong.DocumentProto.IDevModeInspectBoxModelText> }) {
    return (
        <div className={classes.layoutBoxModelContainer} data-testid={DevModeBoxModelTestId.Container}>
            <TextBox value={props.value} />
        </div>
    )
}

function CommonAssetBoxModel(props: { value: DeepRequired<Wukong.DocumentProto.IDevModeInspectBoxModelCommonAsset> }) {
    return (
        <div className={classes.layoutBoxModelContainer} data-testid={DevModeBoxModelTestId.Container}>
            <MarginBox value={props.value}>
                <BorderRadiusBox value={props.value}>
                    <CommonAssetContentBox value={props.value} />
                </BorderRadiusBox>
            </MarginBox>
        </div>
    )
}

function ImageAssetBoxModel(props: { value: DeepRequired<Wukong.DocumentProto.IDevModeInspectBoxModelImageAsset> }) {
    return (
        <div className={classes.layoutBoxModelContainer} data-testid={DevModeBoxModelTestId.Container}>
            <MarginBox value={props.value}>
                <BorderRadiusBox value={props.value}>
                    <ImageAssetContentBox value={props.value} />
                </BorderRadiusBox>
            </MarginBox>
        </div>
    )
}
