import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    CurrentVersion: '当前版本',
    ComponentsPublished: '组件库发布',
    ComponentsUnpublished: '组件库取消发布',
    ComponentsPublished_synonyms1: '组件库发布更新',
    AddToVersion: '添加历史版本成功',
    AddToVersion_synonyms1: '添加历史版本失败',
    SaveToVersion: '保存为历史版本成功',
    SaveToVersion_synonyms1: '保存为历史版本失败',
    EditVersionInfo: '编辑版本信息失败',
    DeleteVersionInfo: '清除版本信息失败',
    RestoreThisVersion: '恢复到此版本失败',
    LinkCopiedTo: '版本链接已复制',
    Viewing: '正在预览',
    SwitchFailed: '切换到 {{name}} 失败',
} as const

export const enTranslation = {
    CurrentVersion: 'Current version',
    ComponentsPublished: 'Components published',
    ComponentsUnpublished: 'Components unpublished',
    ComponentsPublished_synonyms1: 'Components published',
    AddToVersion: 'Version added',
    AddToVersion_synonyms1: 'An error occurred while adding to version history',
    SaveToVersion: 'Version saved',
    SaveToVersion_synonyms1: 'An error occurred while saving to version history',
    EditVersionInfo: 'An error occurred while editing version info',
    DeleteVersionInfo: 'An error occurred while deleting version info',
    RestoreThisVersion: 'An error occurred while restoring',
    LinkCopiedTo: 'Link copied to clipboard',
    Viewing: 'Viewing',
    SwitchFailed: 'An error occurred while switching to {{name}}',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
