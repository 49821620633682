/* eslint-disable no-restricted-imports */
import { useCallback } from 'react'
import { createEffectStylePreview } from '../../../document/command/preview-thumbnail'
import { Effect } from '../../../document/node/node'
import { useCommand } from '../../../main/app-context'
import { useThumbnailCache } from './use-thumbnail-cache'

export interface EffectStylePreviewThumbnailProps {
    width: number
    height: number
    effects: readonly Effect[]
    multiple?: number
}

/**
 * @description 效果样式 预览缩略图
 */
export function useEffectStylePreviewThumbnail(props: EffectStylePreviewThumbnailProps) {
    const { width, height, effects, multiple } = props
    const command = useCommand()

    const getThumbnailMethod = useCallback(
        (_props: EffectStylePreviewThumbnailProps) => {
            const _multiple = _props.multiple ?? (_props.width / 240) * window.devicePixelRatio
            return command.invoke(createEffectStylePreview, {
                width: _props.width,
                height: _props.height,
                multiple: _multiple,
                effects: _props.effects as Effect[],
            })
        },
        [command]
    )
    return useThumbnailCache({ width, height, effects, multiple }, getThumbnailMethod)
}
