import { ExpandSpecificSimplifyInstance, Wukong } from '@wukong/bridge-proto'
import { useCallback, useMemo } from 'react'
import { MonoIconCommonMoreVertical16 } from '../../../../../../../ui-lib/src'
import { CommitType } from '../../../../../document/command/commit-type'
import { useCommand } from '../../../../context/document-context'
import { LayerPanelDragSeeAllLayersItem } from '../../render-item-drag/drag-see-all-layers-item'
import { LayerPanelItemIndent } from '../../render-item-indent'
import { RenderItemBackground } from '../../type'
import styles from './index.module.less'
import { translation } from './index.translation'

interface LayerPanelSeeAllLayersItemProps {
    index: number
    info: Wukong.DocumentProto.IVLayerPanelSeeAllLayers
    prevItem: Wukong.DocumentProto.VLayerPanelItemInfo | undefined
    nextItem: Wukong.DocumentProto.VLayerPanelItemInfo | undefined
}

const LayerPanelExpandStatus = Wukong.DocumentProto.VLayerPanelNodeExpandStauts

export function LayerPanelSeeAllLayersItem({ index, info, prevItem, nextItem }: LayerPanelSeeAllLayersItemProps) {
    const command = useCommand()

    const onClickSeeAllLayers = useCallback(() => {
        command.invokeBridge(CommitType.Noop, ExpandSpecificSimplifyInstance, { value: info.seeAllLayerId })
    }, [command, info.seeAllLayerId])

    return useMemo(
        () => (
            <LayerPanelDragSeeAllLayersItem item={info} preItem={prevItem} nextItem={nextItem} index={index}>
                <div
                    data-testid={`layer-panel-see-all-layers-${index}`}
                    className={styles.seeAllLayers}
                    onClick={onClickSeeAllLayers}
                    onMouseDown={(e) => e.stopPropagation()} // 点击 seeAllLayers 阻止冒泡，不改变选区
                >
                    <LayerPanelItemIndent
                        indent={info.indent}
                        maskTypes={[]}
                        expandStatus={LayerPanelExpandStatus.V_LAYER_PANEL_NODE_EXPAND_STAUTS_NONE}
                        renderItemBackground={RenderItemBackground.AncesterSelected}
                        id={info.seeAllLayerId!}
                    />
                    <MonoIconCommonMoreVertical16 />
                    <span>{translation('seeAllLayers')}</span>
                </div>
            </LayerPanelDragSeeAllLayersItem>
        ),
        [info, index, prevItem, nextItem, onClickSeeAllLayers]
    )
}
