/* eslint-disable no-restricted-imports */
import {
    AttachStrokeWeightFloatVarCommand,
    DetachStrokeWeightFloatVarCommand,
    SetStrokeWeightForSelectionCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { isEqual } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { CommitType } from '../../../../../document/command/commit-type'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { Value } from '../../../atom/inputs/utils/type'
import { getFieldFloatVariable, getFieldItem } from '../../../variable/float-variable/utils'
import { isAnyStrokeWeightMixed } from './utils'

const allFourIndependentWeightsEqual = (selectionStroke: Wukong.DocumentProto.IVSelectionStrokeState) =>
    selectionStroke.weightState.topBorderState?.value === selectionStroke.weightState.rightBorderState?.value &&
    selectionStroke.weightState.topBorderState?.value === selectionStroke.weightState.bottomBorderState?.value &&
    selectionStroke.weightState.topBorderState?.value === selectionStroke.weightState.leftBorderState?.value

export interface StrokeAllBorderWeightRef {
    setTypeState: (typeState: Wukong.DocumentProto.ISelectionStrokeIndependentTypeState | undefined) => void
}

export function useStrokeAllBorderWeight(props: {
    isOpenFourIndependentWeight: boolean
    typeState: Wukong.DocumentProto.ISelectionStrokeIndependentTypeState | undefined
}) {
    const command = useCommand()
    const selectionStroke = useViewState('selectionStrokeV2')
    const [allBorderState, setAllBorderState] = useState<Wukong.DocumentProto.ISelectionStrokeIndependentWeightState>()

    const checkNeedCustomHandle = useCallback(
        () =>
            props?.isOpenFourIndependentWeight &&
            selectionStroke?.weightState.allBorderState.type ===
                Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_NORMAL &&
            props.typeState?.value !== Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_ALL &&
            !allFourIndependentWeightsEqual(selectionStroke),
        [props?.isOpenFourIndependentWeight, props.typeState?.value, selectionStroke]
    )

    useEffect(() => {
        // 打开四边输入框后，只要四边值不相等，则全线重输入框为 Mixed
        if (checkNeedCustomHandle()) {
            setAllBorderState({
                type: Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED,
            })
        } else {
            setAllBorderState(selectionStroke?.weightState.allBorderState)
        }
    }, [selectionStroke?.weightState.allBorderState, checkNeedCustomHandle])

    const onChangeWeight = (value: Value, options?: InputOptionsForUndoSquash) => {
        if (typeof value !== 'number') {
            return
        }

        // 打开四边输入框后，全线重输入框生效到所有边
        if (checkNeedCustomHandle()) {
            command.DEPRECATED_invokeBridge(SetStrokeWeightForSelectionCommand, {
                borderTopWeight: value,
                borderRightWeight: value,
                borderBottomWeight: value,
                borderLeftWeight: value,
            })
        } else if (props.typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_TOP) {
            command.DEPRECATED_invokeBridge(SetStrokeWeightForSelectionCommand, {
                borderTopWeight: value,
            })
        } else if (
            props.typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_RIGHT
        ) {
            command.DEPRECATED_invokeBridge(SetStrokeWeightForSelectionCommand, {
                borderRightWeight: value,
            })
        } else if (
            props.typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_BOTTOM
        ) {
            command.DEPRECATED_invokeBridge(SetStrokeWeightForSelectionCommand, {
                borderBottomWeight: value,
            })
        } else if (props.typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_LEFT) {
            command.DEPRECATED_invokeBridge(SetStrokeWeightForSelectionCommand, {
                borderLeftWeight: value,
            })
        } else {
            command.DEPRECATED_invokeBridge(SetStrokeWeightForSelectionCommand, {
                borderAllWeight: value,
            })
        }
        command.commitUndo(options?.commitType)
    }

    return {
        allBorderState,
        onChangeWeight,
    }
}

export function useStrokeAllBorderWeightV2({
    isOpenFourIndependentWeight,
    typeState,
}: {
    isOpenFourIndependentWeight: boolean
    typeState: Wukong.DocumentProto.ISelectionStrokeIndependentTypeState | undefined
}) {
    const command = useCommand()
    const selectionStroke = useViewState('selectionStrokeV2')
    const variableConsumptionMapState = useViewState('variableConsumptionMapState')
    const checkNeedCustomHandle = useCallback(
        () =>
            isOpenFourIndependentWeight &&
            selectionStroke?.weightState.allBorderState.type ===
                Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_NORMAL &&
            typeState?.value !== Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_ALL &&
            !allFourIndependentWeightsEqual(selectionStroke),
        [isOpenFourIndependentWeight, typeState?.value, selectionStroke]
    )
    const allBorderState: Wukong.DocumentProto.ISelectionStrokeIndependentWeightState | undefined = useMemo(() => {
        if (checkNeedCustomHandle()) {
            return {
                type: Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED,
            }
        } else {
            return selectionStroke?.weightState.allBorderState
        }
    }, [selectionStroke?.weightState.allBorderState, checkNeedCustomHandle])

    const allBorderVariable = useMemo(() => {
        if (allBorderState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED) {
            return undefined
        }
        const baseItem = getFieldItem(Wukong.DocumentProto.VariableField.STROKE_WEIGHT, variableConsumptionMapState)
        // 如果有线重变量是mixed，则返回 undefined
        if (isAnyStrokeWeightMixed(variableConsumptionMapState)) {
            return undefined
        }
        const baseVariable = getFieldFloatVariable(
            Wukong.DocumentProto.VariableField.STROKE_WEIGHT,
            variableConsumptionMapState
        )
        const topBorderVariable = getFieldFloatVariable(
            Wukong.DocumentProto.VariableField.BORDER_TOP_WEIGHT,
            variableConsumptionMapState
        )
        const rightBorderVariable = getFieldFloatVariable(
            Wukong.DocumentProto.VariableField.BORDER_RIGHT_WEIGHT,
            variableConsumptionMapState
        )
        const bottomBorderVariable = getFieldFloatVariable(
            Wukong.DocumentProto.VariableField.BORDER_BOTTOM_WEIGHT,
            variableConsumptionMapState
        )
        const leftBorderVariable = getFieldFloatVariable(
            Wukong.DocumentProto.VariableField.BORDER_LEFT_WEIGHT,
            variableConsumptionMapState
        )
        const isIndependentVariableEqual =
            isEqual(topBorderVariable, rightBorderVariable) &&
            isEqual(topBorderVariable, bottomBorderVariable) &&
            isEqual(topBorderVariable, leftBorderVariable)
        if (checkNeedCustomHandle()) {
            if (isIndependentVariableEqual && (!baseItem || isEqual(baseVariable, topBorderVariable))) {
                return topBorderVariable
            }
        } else if (typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_TOP) {
            if (!baseItem || isEqual(baseVariable, topBorderVariable)) {
                return topBorderVariable
            }
        } else if (typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_RIGHT) {
            if (!baseItem || isEqual(baseVariable, rightBorderVariable)) {
                return rightBorderVariable
            }
        } else if (typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_BOTTOM) {
            if (!baseItem || isEqual(baseVariable, bottomBorderVariable)) {
                return bottomBorderVariable
            }
        } else if (typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_LEFT) {
            if (!baseItem || isEqual(baseVariable, leftBorderVariable)) {
                return leftBorderVariable
            }
        } else {
            if (typeState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_EMPTY) {
                return baseVariable
            } else if (isIndependentVariableEqual && (!baseItem || isEqual(baseVariable, topBorderVariable))) {
                return topBorderVariable
            }
        }
    }, [allBorderState, variableConsumptionMapState, typeState?.value, typeState?.type, checkNeedCustomHandle])

    const onChangeWeight = (value: Value, options?: InputOptionsForUndoSquash) => {
        if (typeof value !== 'number') {
            return
        }

        const commitType = options?.commitType ?? CommitType.CommitUndo
        // 打开四边输入框后，全线重输入框生效到所有边
        if (checkNeedCustomHandle()) {
            command.invokeBridge(commitType, SetStrokeWeightForSelectionCommand, {
                borderTopWeight: value,
                borderRightWeight: value,
                borderBottomWeight: value,
                borderLeftWeight: value,
            })
        } else if (typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_TOP) {
            command.invokeBridge(commitType, SetStrokeWeightForSelectionCommand, {
                borderTopWeight: value,
            })
        } else if (typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_RIGHT) {
            command.invokeBridge(commitType, SetStrokeWeightForSelectionCommand, {
                borderRightWeight: value,
            })
        } else if (typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_BOTTOM) {
            command.invokeBridge(commitType, SetStrokeWeightForSelectionCommand, {
                borderBottomWeight: value,
            })
        } else if (typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_LEFT) {
            command.invokeBridge(commitType, SetStrokeWeightForSelectionCommand, {
                borderLeftWeight: value,
            })
        } else {
            command.invokeBridge(commitType, SetStrokeWeightForSelectionCommand, {
                borderAllWeight: value,
            })
        }
    }

    const onAttachFloatVar = (id: string) => {
        const floatVar: Wukong.DocumentProto.IVariableAliasData = {
            dataType: Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_ALIAS,
            resolvedDataType: Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT,
            value: { alias: id },
        }
        if (checkNeedCustomHandle()) {
            command.invokeBridge(CommitType.CommitUndo, AttachStrokeWeightFloatVarCommand, {
                field: Wukong.DocumentProto.VariableField.STROKE_WEIGHT,
                variableData: floatVar,
                onlyIndependentStroke: false,
            })
        } else if (typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_TOP) {
            command.invokeBridge(CommitType.CommitUndo, AttachStrokeWeightFloatVarCommand, {
                field: Wukong.DocumentProto.VariableField.BORDER_TOP_WEIGHT,
                variableData: floatVar,
                onlyIndependentStroke: false,
            })
        } else if (typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_RIGHT) {
            command.invokeBridge(CommitType.CommitUndo, AttachStrokeWeightFloatVarCommand, {
                field: Wukong.DocumentProto.VariableField.BORDER_RIGHT_WEIGHT,
                variableData: floatVar,
                onlyIndependentStroke: false,
            })
        } else if (typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_BOTTOM) {
            command.invokeBridge(CommitType.CommitUndo, AttachStrokeWeightFloatVarCommand, {
                field: Wukong.DocumentProto.VariableField.BORDER_BOTTOM_WEIGHT,
                variableData: floatVar,
                onlyIndependentStroke: false,
            })
        } else if (typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_LEFT) {
            command.invokeBridge(CommitType.CommitUndo, AttachStrokeWeightFloatVarCommand, {
                field: Wukong.DocumentProto.VariableField.BORDER_LEFT_WEIGHT,
                variableData: floatVar,
                onlyIndependentStroke: false,
            })
        } else {
            command.invokeBridge(CommitType.CommitUndo, AttachStrokeWeightFloatVarCommand, {
                field: Wukong.DocumentProto.VariableField.STROKE_WEIGHT,
                variableData: floatVar,
                onlyIndependentStroke: false,
            })
        }
    }

    const onDetachFloatVar = () => {
        if (checkNeedCustomHandle()) {
            command.invokeBridge(CommitType.CommitUndo, DetachStrokeWeightFloatVarCommand, {
                field: Wukong.DocumentProto.VariableField.STROKE_WEIGHT,
                onlyIndependentStroke: false,
            })
        } else if (typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_TOP) {
            command.invokeBridge(CommitType.CommitUndo, DetachStrokeWeightFloatVarCommand, {
                field: Wukong.DocumentProto.VariableField.BORDER_TOP_WEIGHT,
                onlyIndependentStroke: false,
            })
        } else if (typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_RIGHT) {
            command.invokeBridge(CommitType.CommitUndo, DetachStrokeWeightFloatVarCommand, {
                field: Wukong.DocumentProto.VariableField.BORDER_RIGHT_WEIGHT,
                onlyIndependentStroke: false,
            })
        } else if (typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_BOTTOM) {
            command.invokeBridge(CommitType.CommitUndo, DetachStrokeWeightFloatVarCommand, {
                field: Wukong.DocumentProto.VariableField.BORDER_BOTTOM_WEIGHT,
                onlyIndependentStroke: false,
            })
        } else if (typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_LEFT) {
            command.invokeBridge(CommitType.CommitUndo, DetachStrokeWeightFloatVarCommand, {
                field: Wukong.DocumentProto.VariableField.BORDER_LEFT_WEIGHT,
                onlyIndependentStroke: false,
            })
        } else {
            command.invokeBridge(CommitType.CommitUndo, DetachStrokeWeightFloatVarCommand, {
                field: Wukong.DocumentProto.VariableField.STROKE_WEIGHT,
                onlyIndependentStroke: false,
            })
        }
    }

    return {
        allBorderState,
        allBorderVariable,
        onChangeWeight,
        onAttachFloatVar,
        onDetachFloatVar,
    }
}
