/* eslint-disable no-restricted-imports */
import { SetCurrentUserActiveStateWasmCall, Wukong } from '@wukong/bridge-proto'
import { signalTimeout } from '../../../../../../util/src/abort-controller/timers'
import { TraceableAbortSignal } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { createSignalSwitch } from '../../../../../../util/src/abort-controller/traceable-signal-switch'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import type { WebSocketBridge } from '../../../../document/synergy/web-socket-bridge'

export class CooperationUserActiveService {
    private readonly disconnectCountdown = 60000
    private pauseState = false

    private isOnline = false
    private lastActive: boolean | null = null

    constructor(
        private readonly commandInvoker: CommandInvoker,
        private readonly webSocketBridge: WebSocketBridge,
        private readonly signal: TraceableAbortSignal
    ) {}

    public init = () => {
        const signalSwitch = createSignalSwitch(this.signal)
        document.addEventListener('visibilitychange', signalSwitch(this.handleVisibilitychange), {
            signal: this.signal,
        })

        this.webSocketBridge.onSynergyStateChangeWithSignal(this.signal, (state) => {
            this.isOnline = state === Wukong.DocumentProto.SynergyState.SYNERGY_STATE_ONLINE
            this.updateCurrentUserActiveState()
        })
    }

    private updateCurrentUserActiveState = () => {
        if (this.pauseState) {
            return
        }

        const active = this.isOnline && !(document.hidden || (window.localBridge && window.electronHidden))
        if (this.lastActive !== active) {
            this.commandInvoker.DEPRECATED_invokeBridge(SetCurrentUserActiveStateWasmCall, {
                value: active,
            })
            this.lastActive = active
        }
    }

    public pause = () => {
        this.pauseState = true
        this.updateCurrentUserActiveState()
    }

    public continue = () => {
        this.pauseState = false
        this.updateCurrentUserActiveState()
    }

    private onToggleVisibilityChange = () => {
        this.updateCurrentUserActiveState()
    }

    private handleVisibilitychange = (signal: TraceableAbortSignal) => {
        if (document.hidden || (window.localBridge && window.electronHidden)) {
            signalTimeout(
                () => {
                    this.onToggleVisibilityChange()
                },
                this.disconnectCountdown,
                { signal }
            )
        } else {
            this.onToggleVisibilityChange()
        }
    }
}
