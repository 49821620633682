/* eslint-disable no-restricted-imports */
import {
    CancelPlacingImageCommandWasmCall,
    ChangeEditorModeCommandForDevMode,
    ClearEditingVectorWasmCall,
    PlaceAllImagesCommand,
} from '@wukong/bridge-proto'
import { useCallback, useMemo, useRef, useState } from 'react'
import {
    MonoIconToolbarAIDuplication24,
    MonoIconToolbarArrow24,
    MonoIconToolbarFrame24,
    MonoIconToolbarImage24,
    MonoIconToolbarLine24,
    MonoIconToolbarMagic24,
    MonoIconToolbarMove24,
    MonoIconToolbarOval24,
    MonoIconToolbarPen24,
    MonoIconToolbarPlugin24,
    MonoIconToolbarRectangle24,
    MonoIconToolbarSlice24,
    MonoIconToolbarStar24,
    MonoIconToolbarTriangle24,
    MonoIconToolbarZoom24,
} from '../../../../../../ui-lib/src'
import { cmdChangePopupState, cmdSwitchEditorMode } from '../../../../document/command/document-command'
import { EditorMode, PopupStateType } from '../../../../document/node/node'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand, useImageDownloadContext } from '../../../context/document-context'
import { selectImageToolbarHandler } from '../../../handler/select-image-handler'
import { useCommentUnread } from '../../comment/comment-service/comment-service-hook'
import {
    frogEventToolsMenusAndActionsBasicAction,
    frogEventToolsMenusAndActionsToolSelect,
} from '../../../utils/tools-actions-frog'

interface IconEditor {
    icon: any
    editorMode: EditorMode
}
export function useToolbar() {
    const command = useCommand()
    const imageDownloadContext = useImageDownloadContext()
    const state = useViewState('topArea')
    const hasUnreadComment = useCommentUnread()
    const editorMode = state?.editorMode as EditorMode | undefined
    const isEditVector = state?.isEditVector
    const cacheAdjustTool = useRef<IconEditor>()
    const cacheRangeTool = useRef<IconEditor>()
    const cacheAiTool = useRef<IconEditor>()
    const cacheAiToolV2 = useRef<IconEditor>()
    const cacheDrawTool = useRef<IconEditor>()
    const cachePenTool = useRef<IconEditor>()
    const [isPluginPopup, setIsPluginPopup] = useState(false)
    const [changeToPlaceImage, setChangeToPlaceImage] = useState(false)
    const docReadonly = useViewState('docReadonly')
    const isHistoryMode = useViewState('historyMode')

    const adjustTool = useMemo<IconEditor>(() => {
        switch (editorMode) {
            case EditorMode.EM_Move:
                return { icon: <MonoIconToolbarMove24 />, editorMode: EditorMode.EM_Move }
            case EditorMode.EM_Scale:
                return { icon: <MonoIconToolbarZoom24 />, editorMode: EditorMode.EM_Scale }
            default:
                return cacheAdjustTool.current
                    ? cacheAdjustTool.current
                    : { icon: <MonoIconToolbarMove24 />, editorMode: EditorMode.EM_Move }
        }
    }, [editorMode])
    cacheAdjustTool.current = adjustTool

    const rangeTool = useMemo<IconEditor>(() => {
        switch (editorMode) {
            case EditorMode.EM_Frame:
                return { icon: <MonoIconToolbarFrame24 />, editorMode: EditorMode.EM_Frame }
            case EditorMode.EM_Slice:
                return { icon: <MonoIconToolbarSlice24 />, editorMode: EditorMode.EM_Slice }
            default:
                return cacheRangeTool.current
                    ? cacheRangeTool.current
                    : { icon: <MonoIconToolbarFrame24 />, editorMode: EditorMode.EM_Frame }
        }
    }, [editorMode])
    cacheRangeTool.current = rangeTool

    const aiTool = useMemo<IconEditor>(() => {
        switch (editorMode) {
            case EditorMode.EM_Copilot:
                return { icon: <MonoIconToolbarMagic24></MonoIconToolbarMagic24>, editorMode: EditorMode.EM_Copilot }
            case EditorMode.EM_AiDuplicate:
                return {
                    icon: <MonoIconToolbarAIDuplication24></MonoIconToolbarAIDuplication24>,
                    editorMode: EditorMode.EM_AiDuplicate,
                }
            default:
                return cacheAiTool.current
                    ? cacheAiTool.current
                    : { icon: <MonoIconToolbarMagic24></MonoIconToolbarMagic24>, editorMode: EditorMode.EM_Copilot }
        }
    }, [editorMode])
    cacheAiTool.current = aiTool

    const aiToolV2 = useMemo<IconEditor | undefined>(() => {
        switch (editorMode) {
            case EditorMode.EM_Copilot:
                return { icon: <MonoIconToolbarMagic24></MonoIconToolbarMagic24>, editorMode: EditorMode.EM_Copilot }
            case EditorMode.EM_AiDuplicate:
                return {
                    icon: <MonoIconToolbarAIDuplication24></MonoIconToolbarAIDuplication24>,
                    editorMode: EditorMode.EM_AiDuplicate,
                }
            default:
                return undefined
        }
    }, [editorMode])
    cacheAiToolV2.current = aiToolV2

    const drawTool = useMemo<IconEditor>(() => {
        if (changeToPlaceImage) {
            setChangeToPlaceImage(false)
            return { icon: <MonoIconToolbarImage24 />, editorMode: EditorMode.EM_PlaceImage }
        }
        switch (editorMode) {
            case EditorMode.EM_Rectangle:
                return { icon: <MonoIconToolbarRectangle24 />, editorMode: EditorMode.EM_Rectangle }
            case EditorMode.EM_Line:
                return { icon: <MonoIconToolbarLine24 />, editorMode: EditorMode.EM_Line }
            case EditorMode.EM_Arrow:
                return { icon: <MonoIconToolbarArrow24 />, editorMode: EditorMode.EM_Arrow }
            case EditorMode.EM_Ellipse:
                return { icon: <MonoIconToolbarOval24 />, editorMode: EditorMode.EM_Ellipse }
            case EditorMode.EM_Polygon:
                return { icon: <MonoIconToolbarTriangle24 />, editorMode: EditorMode.EM_Polygon }
            case EditorMode.EM_Star:
                return { icon: <MonoIconToolbarStar24 />, editorMode: EditorMode.EM_Star }
            default:
                return cacheDrawTool.current
                    ? cacheDrawTool.current
                    : { icon: <MonoIconToolbarRectangle24 />, editorMode: EditorMode.EM_Rectangle }
        }
    }, [editorMode, changeToPlaceImage])
    cacheDrawTool.current = drawTool

    const penTool = useMemo<IconEditor>(() => {
        switch (editorMode) {
            case EditorMode.EM_PEN:
                return { icon: <MonoIconToolbarPen24 />, editorMode: EditorMode.EM_PEN }
            // todo():没有 另一种 pencil mode
            // case -1:
            // return { icon: <IconPencil />, editorMode: -1 }
            default:
                return cachePenTool.current
                    ? cachePenTool.current
                    : { icon: <MonoIconToolbarPen24 />, editorMode: EditorMode.EM_PEN }
        }
    }, [editorMode])
    cachePenTool.current = penTool

    const pluginTool: IconEditor = {
        icon: <MonoIconToolbarPlugin24 />,
        editorMode: EditorMode.EM_Move,
    }

    const isSelect = useCallback(
        (mode: EditorMode) => {
            return !isPluginPopup && mode === editorMode
        },
        [isPluginPopup, editorMode]
    )

    // TODO(chenyn) 这个 cmd 迁移到 wasm「点击工具栏修改编辑模式」
    const onSwitchChange = (mode: string | number) => {
        frogEventToolsMenusAndActionsToolSelect({
            current_tool: mode,
            previous_tool: editorMode,
            tool_select_way: '工具栏点击',
            doc_mode: {
                is_readonly: !!docReadonly,
                is_dev_mode: false,
                is_history_version: !!isHistoryMode,
                is_vector: !!isEditVector,
            },
        })
        let nextEditorMode = Number.isNaN(Number(mode)) ? EditorMode.EM_Move : Number(mode)

        if (nextEditorMode === EditorMode.EM_MenuPlaceImage) {
            setChangeToPlaceImage(true)
            nextEditorMode = EditorMode.EM_Move
            onSwitchImage()
        }

        if (nextEditorMode === EditorMode.EM_Frame || nextEditorMode === EditorMode.EM_Comment) {
            command.invoke(cmdChangePopupState, {
                type: PopupStateType.POPUP_STATE_TYPE_NONE,
                reciprocalIndex: -1,
                multiPopup: [],
            })
        }

        if (editorMode === EditorMode.EM_Comment && nextEditorMode === EditorMode.EM_Comment) {
            command.invoke(cmdSwitchEditorMode, EditorMode.EM_Move)
        } else {
            command.invoke(cmdSwitchEditorMode, nextEditorMode)
        }
    }

    const onSwitchChangeForDevMode = (mode: string | number) => {
        frogEventToolsMenusAndActionsToolSelect({
            current_tool: mode,
            previous_tool: editorMode,
            tool_select_way: '工具栏点击',
            doc_mode: {
                is_readonly: !!docReadonly,
                is_dev_mode: true,
                is_history_version: !!isHistoryMode,
                is_vector: !!isEditVector,
            },
        })
        command.DEPRECATED_invokeBridge(ChangeEditorModeCommandForDevMode, {
            editorMode: Number.isNaN(Number(mode)) ? EditorMode.EM_DevModeInspect : Number(mode),
        })
    }

    const onPlaceAll = () => {
        command.DEPRECATED_invokeBridge(PlaceAllImagesCommand)
        command.commitUndo()
    }

    const onCancelPlacing = () => {
        command.DEPRECATED_invokeBridge(CancelPlacingImageCommandWasmCall)
    }

    const onClickDone = () => {
        command.DEPRECATED_invokeBridge(ClearEditingVectorWasmCall)
        onSwitchChange(EditorMode.EM_Move)
        command.commitUndo()
        frogEventToolsMenusAndActionsBasicAction({
            action_name: '退出矢量编辑状态',
            operation_way: '工具栏-完成按钮',
        })
    }

    const onSwitchImage = selectImageToolbarHandler(command, imageDownloadContext)

    const onPluginPopupChange = (popup: boolean) => {
        setIsPluginPopup(popup)
    }

    const viewHistoryDone = () => {
        onSwitchChange(EditorMode.EM_Move)
    }

    const viewHistoryDoneForDevMode = () => {
        onSwitchChangeForDevMode(EditorMode.EM_DevModeInspect)
    }

    return {
        adjustTool,
        rangeTool,
        aiTool,
        aiToolV2,
        drawTool,
        penTool,
        pluginTool,
        editorMode,
        isEditVector,
        hasUnreadComment,
        isPluginPopup,
        onSwitchChange,
        onSwitchChangeForDevMode,
        onSwitchImage,
        isSelect,
        onClickDone,
        onPlaceAll,
        onCancelPlacing,
        onPluginPopupChange,
        viewHistoryDone,
        viewHistoryDoneForDevMode,
    }
}
