import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    UnknownLibrary: '未知组件库',
    OpenFile: '打开文件',
    MatchTo: '匹配到',
} as const

export const enTranslation = {
    UnknownLibrary: 'Unknown library',
    OpenFile: 'Open file',
    MatchTo: 'Match to ',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
