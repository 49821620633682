import { createRoot } from 'react-dom/client'

export const createRootRender = () => {
    const div = document.createElement('div')
    div.setAttribute('style', 'width: 100vw; height: 100vh')
    div.id = 'root'
    document.body.append(div)

    const root = createRoot(div)

    return {
        render: root.render.bind(root),
        cleanup: () => {
            root.unmount()
            document.body.removeChild(div)
        },
    }
}
