import { useState } from 'react'
import { InputV2, WKDialog, WKToast } from '../../../../../../../ui-lib/src'
import { usePluginService } from '../../../../../main/app-context'
import { PluginDevStatus, usePluginDevelopment } from '../use-plugin-development'
import { fileSep } from '../util'
import { translation } from './plugin-dev-create.translation'

export function PluginDevelopmentCreate() {
    const pluginService = usePluginService()
    const [pluginName, setPluginName] = useState('')
    const { setPluginDevStatus, createNewPlugin, setCreatedPluginPath } = usePluginDevelopment()
    const [loading, setLoading] = useState<boolean>(false)
    const [nameTooLong, setNameTooLong] = useState<boolean>(false)

    const onClickCreate = async () => {
        if (!pluginName) {
            return
        }

        if (pluginName.length > 50) {
            setNameTooLong(true)
            return
        }

        setLoading(true)
        const createdPlugin = await createNewPlugin(pluginName)
        if (createdPlugin) {
            pluginService.states
                .getState()
                .updateLocalPlugins([...pluginService.states.getState().localPlugins, createdPlugin])
            pluginService.states
                .getState()
                .updateEditablePluginIds([...pluginService.states.getState().editablePluginIds, createdPlugin.id])

            setPluginDevStatus(PluginDevStatus.CreateSuccess)
            setCreatedPluginPath(`${createdPlugin.path!}${fileSep()}manifest.json`)
        } else {
            WKToast.error(translation('CreatePluginFailed'))
        }
        setLoading(false)
    }

    return (
        <WKDialog
            title={translation('CreatePlugin')}
            visible={true}
            showTitle={true}
            width={400}
            onCancel={() => {
                setPluginDevStatus(PluginDevStatus.Main)
            }}
            onCloseIconCancel={() => {
                pluginService.states.getState().updateIsOpenPluginDevelopment(false)
                setPluginDevStatus(PluginDevStatus.Main)
            }}
            onOk={onClickCreate}
            okButtonProps={{ disabled: !pluginName, loading }}
            okText={translation('CreateToLocal')}
            bodyStyle={{ padding: 0 }}
        >
            <div className="p-24px">
                <InputV2
                    autoFocus
                    placeholder={translation('PleaseInputPluginName')}
                    onChange={(e) => {
                        setNameTooLong(false)
                        setPluginName(e.target.value)
                    }}
                    dataTestIds={{
                        input: 'plugin-dev-create-input',
                    }}
                    error={{
                        show: nameTooLong,
                        tipMessage: translation('PluginNameTooLong'),
                    }}
                />
            </div>
        </WKDialog>
    )
}
