import { translation } from './dialog-footer.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { WKTextButton } from '../../../../../ui-lib/src'
import classNames from 'classnames'
import styles from './dialog-footer.module.less'
import { jumpToCenter, SupportedDisplayLevel } from './hook'
import { CheckboxCheckedIcon, WarningLineIcon } from './icons'

export interface ColorProfieDialogFooterProps {
    colorProfile: Wukong.DocumentProto.DocumentColorProfile
    deviceSupportLevel: SupportedDisplayLevel
    isHostSupport: boolean
    warningDeviceNotSupportText: Record<Wukong.DocumentProto.DocumentColorProfile, string>
    warningOtherDeviceSupportText: Record<Wukong.DocumentProto.DocumentColorProfile, string>
    deviceSupportText?: Record<Wukong.DocumentProto.DocumentColorProfile, string>
    warningHostNotSupportText: Record<Wukong.DocumentProto.DocumentColorProfile, string>
    warningShowMoreLink: string | null
}

export function isShowColorProfieDialogFooter(
    deviceSupportLevel: SupportedDisplayLevel,
    isHostSupport: boolean,
    showDeviceSupport: boolean
) {
    const showFooter =
        deviceSupportLevel.level !== 'current' ||
        (showDeviceSupport && deviceSupportLevel.level === 'current') ||
        !isHostSupport
    return showFooter
}

export function ColorProfieDialogFooter({
    colorProfile,
    deviceSupportLevel,
    isHostSupport,
    warningDeviceNotSupportText,
    warningOtherDeviceSupportText,
    warningHostNotSupportText,
    deviceSupportText,
    warningShowMoreLink,
}: ColorProfieDialogFooterProps) {
    const isDeviceSupoort = deviceSupportLevel.level === 'current'

    return (
        <>
            {!isHostSupport && (
                <div className={styles.colorProfileDialogFooter}>
                    <WarningLineIcon className={styles.colorProfileInfoLineIcon} />
                    <div className={styles.text}>{warningHostNotSupportText[colorProfile]}</div>
                </div>
            )}
            {isHostSupport && !isDeviceSupoort && (
                <div className={styles.colorProfileDialogFooter}>
                    <WarningLineIcon className={styles.colorProfileInfoLineIcon} />
                    {deviceSupportLevel.level === 'none' && (
                        <div className={styles.text}>
                            {warningDeviceNotSupportText[colorProfile]}
                            {Boolean(warningShowMoreLink) && (
                                <WKTextButton type="primary" size={12} onClick={jumpToCenter}>
                                    {translation('LearnMore')}
                                </WKTextButton>
                            )}
                        </div>
                    )}
                    {deviceSupportLevel.level === 'other' && (
                        <div className={styles.text}>
                            {warningOtherDeviceSupportText[colorProfile].replace('{name}', deviceSupportLevel.name)}
                            {Boolean(warningShowMoreLink) && (
                                <WKTextButton type="primary" size={12} onClick={jumpToCenter}>
                                    {translation('LearnMore')}
                                </WKTextButton>
                            )}
                        </div>
                    )}
                </div>
            )}
            {deviceSupportText && isDeviceSupoort && isHostSupport && (
                <div className={styles.colorProfileDialogFooter}>
                    <CheckboxCheckedIcon className={classNames([styles.colorProfileInfoLineIcon, styles.info])} />
                    <div className={styles.text}>{deviceSupportText[colorProfile]}</div>
                </div>
            )}
        </>
    )
}
