import { translation } from './tool-user.translation'
/* eslint-disable no-restricted-imports */
import { useCallback, useMemo, useState } from 'react'
import { WKButton } from '../../../../../../ui-lib/src'
import { RoleStatus, SeatType } from '../../../../kernel/interface/type'
import { SpaceLogs } from '../../../../kernel/service/space-frog-service/logs'
import { isLoginExpired } from '../../../../kernel/util/expire-login'
import { usePlanAndUserStateService } from '../../../../main/app-context'
import { useUserConfigStateV2 } from '../../../../main/user-config/user-config-hook'
import { BatchMoveDocModal } from '../../../../share/batch-move-doc-modal'
import { PayPreCheck } from '../../../../share/payment/pay-pre-check'
import { ShareDialog } from '../../../../share/share-dialog'
import { ShareDialogType, SpecialShareDialogModeType } from '../../../../share/share-dialog/types'
import { isActiveProPlan } from '../../../../utils/plan-status-checker'
import { useDocInfoContext } from '../../../context/top-area-context'
import { Switch } from '../../atom/switch/switch'
import style from './tool-user.module.less'

export function ToolUser({ isDevMode }: { isDevMode?: boolean }) {
    const [visible, setVisible] = useState<boolean>(false)
    const [batchMoveModalVisible, setBatchMoveModalVisible] = useState<boolean>(false)

    const {
        access,
        shareDialogMembers,
        currentUser,
        docData,
        folderData,
        updateDocShareData,
        domainList,
        getCurrentDocOrganization,
    } = useDocInfoContext()

    const planAndUserStateService = usePlanAndUserStateService()
    const teamPlanOfSharingDoc = planAndUserStateService.useZustandStore.use.planAndUserState()
    const planInAllTeams = planAndUserStateService.useZustandStore.use.planInAllTeams()

    const onClickShare = useCallback(async () => {
        if (!(await isLoginExpired(false)) && document.getElementsByClassName('expireTimeModalPop').length <= 0) {
            if (
                !docData ||
                !(await PayPreCheck.checkShareDocBtn({
                    planInfo: teamPlanOfSharingDoc,
                    docInfo: docData,
                    userId: currentUser?.userId!,
                    draftFolderId: getCurrentDocOrganization()?.draftFolderId,
                }))
            ) {
                return
            }
            updateDocShareData()
            SpaceLogs.EditorFileOperations.share()
            setVisible(true)
        }
    }, [updateDocShareData, teamPlanOfSharingDoc, docData, currentUser, getCurrentDocOrganization])

    const [showDevModeShareTipsV2, setShowDevModeShareTipsV2] = useUserConfigStateV2('showDevModeShareTips')
    const onHideDevModeShareTipsCallbackV2 = useCallback(() => {
        setShowDevModeShareTipsV2(false)
    }, [setShowDevModeShareTipsV2])

    const onHideDevModeShareTipsCallback = onHideDevModeShareTipsCallbackV2

    const showDevModeShareTips = showDevModeShareTipsV2

    const hasPayingTeamsWithDesignSeat = useMemo(() => {
        return (planInAllTeams || []).some((plan) => isActiveProPlan(plan) && plan.userSeatType === SeatType.designer)
    }, [planInAllTeams])

    const isDocOwner = useMemo(() => {
        return shareDialogMembers.members.some(
            (m) => m.actualRole === RoleStatus.Owner && m.userId === currentUser?.userId
        )
    }, [currentUser?.userId, shareDialogMembers.members])

    const showMoveDraftTipBanner =
        docData?.orgId === '-1' && docData?.draft && isDocOwner && !hasPayingTeamsWithDesignSeat

    return (
        <>
            <Switch>
                <WKButton
                    type="primary"
                    className={style.button}
                    onClick={onClickShare}
                    id={'share-btn'}
                    dataTestId="share-btn"
                >
                    {translation('Share')}
                </WKButton>
            </Switch>
            {!!docData && batchMoveModalVisible && (
                <BatchMoveDocModal
                    docs={[docData]}
                    onClose={() => {
                        setBatchMoveModalVisible(false)
                    }}
                    forceHideTipBanner={false}
                    organization={getCurrentDocOrganization()!}
                />
            )}
            {!!docData && visible && (
                <ShareDialog
                    domainList={domainList}
                    orgId={docData.orgId}
                    type={ShareDialogType.Doc}
                    dimission={!!folderData?.draftFolderFromDimission}
                    resourceName={docData?.name || ''}
                    visible={visible}
                    onCancel={() => setVisible(false)}
                    access={access}
                    members={shareDialogMembers}
                    isDraft={docData?.draft}
                    currentUser={currentUser}
                    docData={docData}
                    refreshMembersList={updateDocShareData}
                    specialModeType={isDevMode ? SpecialShareDialogModeType.DevMode : undefined}
                    showDevModeShareTips={showDevModeShareTips}
                    hideDevModeShareTipsCallback={onHideDevModeShareTipsCallback}
                    teamPlanOfSharingDoc={teamPlanOfSharingDoc}
                    showShareDraftTipBanner={showMoveDraftTipBanner}
                    moveDraftIntoTeam={() => {
                        setVisible(false)
                        setBatchMoveModalVisible(true)
                    }}
                    shareDocLimitAction={{
                        setShareModal: setVisible,
                        setMoveModal: setBatchMoveModalVisible,
                    }}
                    draftFolderId={getCurrentDocOrganization()?.draftFolderId}
                />
            )}
        </>
    )
}
