import type { INavigatorClipboardSupports } from '../clipboard/clipboard-service/navigator-clipboard-service'
import type { IControlParagraphSupports } from '../control-paragraph/support-interface'
import type { IColorProfileSupports } from '../service/color-profile-service'

declare global {
    interface Window {
        isIntegrationTest: boolean
        _supports: ISupports | undefined
    }

    let isIntegrationTest: boolean
    let _supports: ISupports | undefined
}

export interface ISupports {
    colorProfileSupports?: IColorProfileSupports
    navigatorClipboardSupports?: INavigatorClipboardSupports
    controlParagraphService?: IControlParagraphSupports
}

export const getIntegrationTestSupports = () => {
    if (window.isIntegrationTest) {
        return window._supports
    }
    return undefined
}

export type { INavigatorClipboardSupports } from '../clipboard/clipboard-service/navigator-clipboard-service'
export type { IColorProfileSupports } from '../service/color-profile-service'
