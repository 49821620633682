import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    Cancel: '取消',
    Confirm: '确定',
} as const

export const enTranslation = {
    Cancel: 'Cancel',
    Confirm: 'Confirm',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
