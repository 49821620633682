import { DELETE, GET, POST, PUT } from '@tutor/network-core'
import {
    PluginInvitationSearchResponse,
    PluginPublishRequestV0,
    PluginUserMembersVO,
    PluginUserRoleInviteRequest,
    PluginUserRoleInviteResponse,
    PluginVO,
} from '../interface/plugin'
import { PluginUserRoleEnum } from '../interface/wukong-message-center'
import { BaseCommonRequest } from './base-request'

@PUT
export class CreatePlugin extends BaseCommonRequest<PluginVO> {
    constructor(private orgId: string) {
        super()
    }

    public override requestArgument() {
        return { orgId: this.orgId }
    }

    public override requestUrl() {
        return `plugin`
    }
}

@POST
export class PublishPlugin extends BaseCommonRequest<PluginVO> {
    constructor(private pluginId: string, private readonly params: PluginPublishRequestV0) {
        super()
    }

    public override requestBody() {
        const form = new FormData()
        form.append('updateRequest', JSON.stringify(this.params.updateRequest))
        if (this.params.manifestContent) {
            const manifestFile = new File([this.params.manifestContent], 'manifest', { type: 'text/plain' })
            form.append('manifestContent', manifestFile)
        }
        if (this.params.codeContent) {
            const codeFile = new File([this.params.codeContent], 'codeContent', { type: 'text/plain' })
            form.append('codeContent', codeFile)
        }

        return form
    }
    public override requestUrl() {
        return `plugin/${this.pluginId}/publish`
    }
}

@GET
export class GetEditablePlugins extends BaseCommonRequest<PluginVO[]> {
    constructor(private orgId: string) {
        super()
    }

    public override requestArgument() {
        return { orgId: this.orgId }
    }

    public override requestUrl() {
        return `plugin/editable`
    }
}

@GET
export class GetPublishedPlugins extends BaseCommonRequest<PluginVO[]> {
    constructor(private orgId: string) {
        super()
    }

    public override requestArgument() {
        return { orgId: this.orgId }
    }

    public override requestUrl() {
        return `plugin/published`
    }
}

@POST
export class GetPublishedPluginsByIds extends BaseCommonRequest<PluginVO[]> {
    constructor(private orgId: string, private ids: string[]) {
        super()
    }

    public override requestArgument() {
        return { orgId: this.orgId }
    }

    public override requestBody() {
        return this.ids
    }

    public override requestUrl() {
        return `plugin/published/by-ids`
    }
}

@POST
export class UnpublishPlugin extends BaseCommonRequest<void> {
    constructor(private pluginId: string) {
        super()
    }

    public override requestUrl() {
        return `plugin/${this.pluginId}/unpublish`
    }
}

// 获取插件的用户权限列表
@GET
export class GetPluginMembers extends BaseCommonRequest<PluginUserMembersVO> {
    constructor(private pluginId: string) {
        super()
    }

    public override requestUrl() {
        return `plugin/user-roles/${this.pluginId}/members`
    }
}

// 搜索企业成员用于邀请
@GET
export class QueryPluginInviteMembers extends BaseCommonRequest<PluginInvitationSearchResponse> {
    constructor(private pluginId: string, private queryStr: string) {
        super()
    }

    public override requestArgument() {
        return { queryStr: this.queryStr }
    }

    public override requestUrl() {
        return `plugin/user-roles/${this.pluginId}/invite/search`
    }
}

// 邀请用户
@POST
export class InvitePluginMembers extends BaseCommonRequest<PluginUserRoleInviteResponse> {
    constructor(private pluginId: string, private readonly params: PluginUserRoleInviteRequest) {
        super()
    }

    public override requestBody() {
        return this.params
    }
    public override requestUrl() {
        return `plugin/user-roles/${this.pluginId}/invite`
    }
}

// 更新用户的角色。
@POST
export class UpdatePluginMemberRole extends BaseCommonRequest<void> {
    constructor(private pluginId: string, private readonly params: { userId: string; newRole: PluginUserRoleEnum }) {
        super()
    }

    public override requestBody() {
        return this.params
    }
    public override requestUrl() {
        return `plugin/user-roles/${this.pluginId}`
    }
}
// 移除用户
@DELETE
export class RemovePluginMemberRole extends BaseCommonRequest<void> {
    constructor(private pluginId: string, private readonly params: { userId: string; originRole: PluginUserRoleEnum }) {
        super()
    }

    public override requestBody() {
        return this.params
    }
    public override requestUrl() {
        return `plugin/user-roles/${this.pluginId}`
    }
}
