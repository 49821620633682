import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    NoFrameToPresent: '原型文件需要至少一个容器图层',
    FailedToCreatePrototype: '创建原型文件请求失败',
} as const

export const enTranslation = {
    NoFrameToPresent: 'A prototype needs to have at least one frame.',
    FailedToCreatePrototype: 'Failed to create prototype.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
