import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Settings: '设置',
    Name: '姓名',
    Change: '修改',
    Setting: '设置',
    Email: '邮箱',
    Password: '密码',
    SetAPassword: '已设置，可以通过账号密码登录',
    ChangePhoto: '修改头像',
    Library: '组件库',
    LibraryDesc: '为所有草稿启用组件库',
    SsoLogin: '你的企业已设置需要使用 SAML SSO 登录',
    GoogleLogin: '你的企业已设置需要使用 Google 登录',
} as const

export const enTranslation = {
    Settings: 'Settings',
    Name: 'Name',
    Change: 'Change',
    Setting: 'Setting',
    Email: 'Email',
    Password: 'Password',
    SetAPassword: 'Password has been set. You can log in with your password.',
    ChangePhoto: 'Change photo',
    Library: 'Library',
    LibraryDesc: 'Enable libraries for all files in your drafts.',
    SsoLogin: 'Your organization requires logging in with SAML SSO.',
    GoogleLogin: 'Your organization requires logging in with Google.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
