import { forwardRef } from 'react'
import {
    TriggerArrow as SelectTriggerArrow,
    TriggerArrowProps as SelectTriggerArrowProps,
    TriggerArrowRef as SelectTriggerArrowRef,
} from '../../selects/triggers/trigger-arrow'

export interface TriggerArrowProps extends SelectTriggerArrowProps {}
export interface TriggerArrowRef extends SelectTriggerArrowRef {}

function _TriggerArrow(props: TriggerArrowProps, ref?: React.Ref<TriggerArrowRef>) {
    return (
        <SelectTriggerArrow ref={ref} {...props}>
            {props.children}
        </SelectTriggerArrow>
    )
}

export const TriggerArrow = forwardRef(_TriggerArrow)
