import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    ErrorMsg: 'AI 一致性检查失败，请稍后重试',
    SettingRuleErrorMsg: '规则设置失败，请稍后重试',
    SettingLibraryErrorMsg: '检查库设置失败，请稍后重试',
} as const

export const enTranslation = {
    ErrorMsg: 'An error occurred while consistency checking',
    SettingRuleErrorMsg: 'An error occurred while setting rules',
    SettingLibraryErrorMsg: 'An error occurred while setting libraries',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
