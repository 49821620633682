import classnames from 'classnames'
import React, { HTMLAttributes, forwardRef } from 'react'
import classes from './trigger-container.module.less'

export interface TriggerContainerProps {
    className?: string
    disabled?: boolean
    isOpenPick?: boolean
    classNameWhenOpen?: string
    dataTestIds?: { triggerContainer?: string }
}
export function _TriggerContainer<T extends TriggerContainerProps = TriggerContainerProps>(
    props: T & HTMLAttributes<HTMLDivElement>,
    ref?: React.Ref<HTMLDivElement>
) {
    const { className, disabled, isOpenPick, classNameWhenOpen, dataTestIds, ...otherProps } = props

    return (
        <div
            ref={ref}
            className={classnames(classes.triggerContainer, [className], {
                [classes.disabled]: disabled,
                [classNameWhenOpen ?? '']: isOpenPick && classNameWhenOpen,
            })}
            {...otherProps}
            data-testid={dataTestIds?.triggerContainer}
        >
            {props.children}
        </div>
    )
}

export const TriggerContainer = forwardRef(_TriggerContainer) as <
    T extends TriggerContainerProps = TriggerContainerProps
>(
    props: T & HTMLAttributes<HTMLDivElement> & { ref?: React.Ref<HTMLDivElement> }
) => React.ReactElement
