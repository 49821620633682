import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    Font: '字体',
    Weight: '字重',
    Size: '字号',
    LineHeight: '行高',
    Letter: '字间距',
    Left: '左对齐',
    Center: '居中对齐',
    Right: '右对齐',
    Align: '水平对齐方式',
    Typography: '文本',
    Ag: '永',
    Style: '字体样式',
    Italic: 'italic',
    ParagraphSpacing: '段间距',
    OpticalSize: 'Optical Size',
    Variation: '可变值',
} as const

export const enTranslation = {
    Font: 'Font',
    Weight: 'Weight',
    Size: 'Size',
    LineHeight: 'Line height',
    Letter: 'Letter',
    Left: 'Left',
    Center: 'Center',
    Right: 'Right',
    Align: 'Align',
    Typography: 'Typography',
    Ag: 'Ag',
    Style: 'Style',
    Italic: 'italic',
    ParagraphSpacing: 'Paragraph',
    OpticalSize: 'Optical Size',
    Variation: 'Variation',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
