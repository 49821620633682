import { translation } from './types.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { ProtoType } from '../../../../view-state-bridge'
import DocumentProto = Wukong.DocumentProto

import InstanceResetType = DocumentProto.InstanceResetType
import CompSetPropConflictType = DocumentProto.CompSetPropConflictType
import CompSetPropConflictSelectionType = DocumentProto.CompSetPropConflictSelectionType
import ComponentPropType = DocumentProto.ComponentPropType
import ComponentPropNodeField = Wukong.DocumentProto.ComponentPropNodeField
import VComponentPropType = DocumentProto.VComponentPropType
import InstanceSwapPreferredValueType = DocumentProto.InstanceSwapPreferredValueType
import ValidateInstanceSwapValueScene = DocumentProto.ValidateInstanceSwapValueScene
import MultiHoverBorderColorType = DocumentProto.MultiHoverBorderColorType

export type ComponentPanelState = ProtoType<DocumentProto.IComponentPanelState>
export type ComponentContextMenuItemState = ProtoType<DocumentProto.IComponentContextMenuItemState>
export type ComponentState = ProtoType<DocumentProto.IComponentState>
export type CandidateComponentState = ProtoType<DocumentProto.ICandidateComponentState>
export type VariantState = ProtoType<DocumentProto.IVariantState>
export type InstanceState = ProtoType<DocumentProto.IInstanceState>
export type ComponentPropState = ProtoType<DocumentProto.IVComponentPropStore>

export type CompSetPropConflict = ProtoType<DocumentProto.ICompSetPropConflict>
export type ComponentSetProp = ProtoType<DocumentProto.IComponentSetProp>
export type VariantProp = ProtoType<DocumentProto.IVariantProp>

export type ReplaceInstanceState = ProtoType<DocumentProto.IReplaceInstanceState>
export type ReplaceInstanceLibraryList = ReplaceInstanceState['allLibraryList']
export type ReplaceInstanceSearchResults = ProtoType<DocumentProto.IRet_SearchReplaceInstance>['results']
export type ReplaceInstanceNodeData = ProtoType<DocumentProto.IReplaceInstanceNodeData>
export type ReplaceInstanceTree = ProtoType<DocumentProto.IReplaceInstanceTree>

export type VInstanceCompProp = ProtoType<DocumentProto.IVInstanceCompProp>
export type VComponentPropDef = ProtoType<DocumentProto.IVComponentPropDef>
export type VComponentPropPill = ProtoType<DocumentProto.IVComponentPropPill>
export type ComponentPropEditPopupVal = ProtoType<DocumentProto.ComponentPropEditPopupVal>

export type VariableData = ProtoType<DocumentProto.IVariableData>
export type VariableAnyValue = ProtoType<DocumentProto.IVariableAnyValue>
export type Arg_cmdEditComponentPropData = DocumentProto.IArg_cmdEditComponentPropData
export type InstanceSwapPreferredValue = ProtoType<DocumentProto.IInstanceSwapPreferredValue>
export type ComponentPropPreferredValues = ProtoType<DocumentProto.IComponentPropPreferredValues>
export type Arg_validateInstanceSwapValue = DocumentProto.IArg_validateInstanceSwapValue
export type Arg_cmdReorderComponentPropDefs = DocumentProto.IArg_cmdReorderComponentPropDefs

export type VComponentDescUnit = ProtoType<DocumentProto.IVComponentDescUnit>

export const buildBooleanMapKey = (values: string[]) =>
    values
        .map((v) => v.toLowerCase())
        .sort()
        .join('')
export const BOOLEAN_VALUES = new Map(
    [
        ['否', '是'],
        ['off', 'on'],
        ['no', 'yes'],
        ['false', 'true'],
        ['关', '开'],
    ].map((v) => [
        buildBooleanMapKey(v),
        {
            false: v[0],
            true: v[1],
        },
    ])
)

/**
 * 判断{translation('True')}{translation('False')}为布尔选项
 **/
export function isBooleanOption(options: string[]) {
    const key = buildBooleanMapKey(options)

    if (!BOOLEAN_VALUES.has(key)) {
        return null
    }

    const targetOption = BOOLEAN_VALUES.get(key)!

    /**
     * 获取原始的布尔值文本
     */
    function getOriginOption(option: boolean) {
        return options.find((x) => x.toLowerCase() == targetOption[option ? 'true' : 'false'])
    }

    /**
     * 判断给定的文本{translation('True')}{translation('False')}为 true
     */
    function checked(originOption: string) {
        return originOption.toLowerCase() === targetOption.true
    }

    return {
        checked,
        getOriginOption,
    }
}

const CONFLICT_TIP_TEXT: Record<string, string> = {
    [`${CompSetPropConflictSelectionType.COMP_SET_PROP_CONFLICT_SELECTION_TYPE_SOME_VARIANTS}-${CompSetPropConflictType.COMP_SET_PROP_CONFLICT_TYPE_INVALID_NAME}`]:
        translation('InvalidNameOfSomeVariants'),
    [`${CompSetPropConflictSelectionType.COMP_SET_PROP_CONFLICT_SELECTION_TYPE_THIS_VARIANT}-${CompSetPropConflictType.COMP_SET_PROP_CONFLICT_TYPE_INVALID_NAME}`]:
        translation('InvalidNameOfThisVariant'),
    [`${CompSetPropConflictSelectionType.COMP_SET_PROP_CONFLICT_SELECTION_TYPE_THESE_VARIANTS}-${CompSetPropConflictType.COMP_SET_PROP_CONFLICT_TYPE_INVALID_NAME}`]:
        translation('InvalidNameOfTheseVariants'),
    [`${CompSetPropConflictSelectionType.COMP_SET_PROP_CONFLICT_SELECTION_TYPE_THIS_INSTANCE}-${CompSetPropConflictType.COMP_SET_PROP_CONFLICT_TYPE_INVALID_NAME}`]:
        translation('InvalidNameOfThisInstance'),
    [`${CompSetPropConflictSelectionType.COMP_SET_PROP_CONFLICT_SELECTION_TYPE_THESE_INSTANCES}-${CompSetPropConflictType.COMP_SET_PROP_CONFLICT_TYPE_INVALID_NAME}`]:
        translation('InvalidNameOfTheseInstances'),
    [`${CompSetPropConflictSelectionType.COMP_SET_PROP_CONFLICT_SELECTION_TYPE_SOME_VARIANTS}-${CompSetPropConflictType.COMP_SET_PROP_CONFLICT_TYPE_REPEAT_NAME}`]:
        translation('SamePropValOfSomeVariants'),
    [`${CompSetPropConflictSelectionType.COMP_SET_PROP_CONFLICT_SELECTION_TYPE_THIS_VARIANT}-${CompSetPropConflictType.COMP_SET_PROP_CONFLICT_TYPE_REPEAT_NAME}`]:
        translation('SamePropValOfThisVariant'),
    [`${CompSetPropConflictSelectionType.COMP_SET_PROP_CONFLICT_SELECTION_TYPE_THESE_VARIANTS}-${CompSetPropConflictType.COMP_SET_PROP_CONFLICT_TYPE_REPEAT_NAME}`]:
        translation('SamePropValOfTheseVariants'),
    [`${CompSetPropConflictSelectionType.COMP_SET_PROP_CONFLICT_SELECTION_TYPE_THIS_INSTANCE}-${CompSetPropConflictType.COMP_SET_PROP_CONFLICT_TYPE_REPEAT_NAME}`]:
        translation('SamePropValOfThisVariant'),
    [`${CompSetPropConflictSelectionType.COMP_SET_PROP_CONFLICT_SELECTION_TYPE_THESE_INSTANCES}-${CompSetPropConflictType.COMP_SET_PROP_CONFLICT_TYPE_REPEAT_NAME}`]:
        translation('SamePropValOfTheseVariants'),
    [`${CompSetPropConflictSelectionType.COMP_SET_PROP_CONFLICT_SELECTION_TYPE_SOME_VARIANTS}-${CompSetPropConflictType.COMP_SET_PROP_CONFLICT_TYPE_MISSING_PROP}`]:
        translation('MissingPropValOfSomeVariants'),
    [`${CompSetPropConflictSelectionType.COMP_SET_PROP_CONFLICT_SELECTION_TYPE_THIS_VARIANT}-${CompSetPropConflictType.COMP_SET_PROP_CONFLICT_TYPE_MISSING_PROP}`]:
        translation('MissingPropValOfThisVariant'),
    [`${CompSetPropConflictSelectionType.COMP_SET_PROP_CONFLICT_SELECTION_TYPE_THESE_VARIANTS}-${CompSetPropConflictType.COMP_SET_PROP_CONFLICT_TYPE_MISSING_PROP}`]:
        translation('MissingPropValOfTheseVariants'),
    [`${CompSetPropConflictSelectionType.COMP_SET_PROP_CONFLICT_SELECTION_TYPE_THIS_INSTANCE}-${CompSetPropConflictType.COMP_SET_PROP_CONFLICT_TYPE_MISSING_PROP}`]:
        translation('MissingPropValOfThisVariant'),
    [`${CompSetPropConflictSelectionType.COMP_SET_PROP_CONFLICT_SELECTION_TYPE_THESE_INSTANCES}-${CompSetPropConflictType.COMP_SET_PROP_CONFLICT_TYPE_MISSING_PROP}`]:
        translation('MissingPropValOfTheseVariants'),
}

const ACTION_TEXT = {
    [CompSetPropConflictSelectionType.COMP_SET_PROP_CONFLICT_SELECTION_TYPE_SOME_VARIANTS]:
        translation('SelectInvalidVariants'),
    [CompSetPropConflictSelectionType.COMP_SET_PROP_CONFLICT_SELECTION_TYPE_THIS_VARIANT]: null,
    [CompSetPropConflictSelectionType.COMP_SET_PROP_CONFLICT_SELECTION_TYPE_THESE_VARIANTS]: null,
    [CompSetPropConflictSelectionType.COMP_SET_PROP_CONFLICT_SELECTION_TYPE_THIS_INSTANCE]: null,
    [CompSetPropConflictSelectionType.COMP_SET_PROP_CONFLICT_SELECTION_TYPE_THESE_INSTANCES]: null,
}

export function getConflictText(conflict: CompSetPropConflict) {
    const conflictTipKey = `${conflict.selectionType}-${conflict.type}`
    return {
        tipText: conflictTipKey in CONFLICT_TIP_TEXT ? CONFLICT_TIP_TEXT[conflictTipKey] : '',
        actionText: ACTION_TEXT[conflict.selectionType],
    }
}

export {
    ComponentPropNodeField,
    ComponentPropType,
    CompSetPropConflictSelectionType,
    CompSetPropConflictType,
    InstanceResetType,
    InstanceSwapPreferredValueType,
    MultiHoverBorderColorType,
    ValidateInstanceSwapValueScene,
    VComponentPropType,
}

export interface IEditBoolDef {
    id: string
    name: string
    type: ComponentPropType
    varValue: VariableData
}

export const convertVirtualComponentPropTypeToField = (type: VComponentPropType) => {
    switch (type) {
        case VComponentPropType.V_COMPONENT_PROP_TYPE_BOOL:
            return ComponentPropNodeField.COMPONENT_PROP_NODE_FIELD_VISIBLE
        case VComponentPropType.V_COMPONENT_PROP_TYPE_TEXT:
            return ComponentPropNodeField.COMPONENT_PROP_NODE_FIELD_TEXT_DATA
        case VComponentPropType.V_COMPONENT_PROP_TYPE_INSTANCE_SWAP:
            return ComponentPropNodeField.COMPONENT_PROP_NODE_FIELD_OVERRIDDEN_SYMBOL_ID
        default:
            throw new Error('不支持的组件属性')
    }
}
