import { Wukong } from '@wukong/bridge-proto'
import { TextStyleNode } from '../../../../../document/node/node'
import { DeepRequired, useViewState } from '../../../../../view-state-bridge'

export function useEditingTextStyle() {
    const state = useViewState('attrPanelEditingStyleViewState')
    if (state?.editingTextStyle) {
        return {
            textStyleNode: state.editingTextStyle.basic as unknown as TextStyleNode,
            variantState: {
                variationInfo: state.editingTextStyle.variationInfo,
                customStyleState: state.editingTextStyle.customStyleState,
                customAxesStyle: state.editingTextStyle.customAxesStyle,
                customAxesStyleDisplay: state.editingTextStyle.customAxesStyleDisplay,
            } as Omit<DeepRequired<Wukong.DocumentProto.IVTextStyleNodeState>, 'basic'>,
        }
    }
    return null
}

export function useEditingPaintStyle() {
    const state = useViewState('attrPanelEditingStyleViewState')
    if (state?.editingPaintStyle) {
        return state.editingPaintStyle
    }
    return null
}

export function useEditingEffectStyle() {
    const state = useViewState('attrPanelEditingStyleViewState')
    if (state?.editingEffectStyle) {
        return state.editingEffectStyle
    }
    return null
}

export function useEditingLayoutGridStyle() {
    const state = useViewState('attrPanelEditingStyleViewState')
    if (state?.editingLayoutGridStyle) {
        return state.editingLayoutGridStyle
    }
    return null
}
