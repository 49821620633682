import { WkCLog } from '../../../kernel/clog/wukong/instance'
import { LocalStorageKey, LocalStorageKey2Config, LocalStorageKey2Schema } from '../config'
import { LocalStorageSupportKey } from '../types'

/**
 * LocalStorage 的增强封装
 * 严格管理 key 的写入
 * 写入 key 时会做超限处理，优先删除低优先级的 KV 后再次写入
 * 限定 api 使用
 */
class EnhancedLocalStorage {
    private storage: Storage
    private lowLevelKeys: LocalStorageKey[] = Object.entries(LocalStorageKey2Config)
        .filter(([_, { canRemove }]) => !!canRemove)
        .map(([key]) => key) as LocalStorageKey[]

    constructor() {
        this.storage = window.localStorage
    }

    /**
     * 从 localStorage 中移除低优先级的存储，以获取重试的可能
     */
    private deleteLowLevelKey(): void {
        const keys = this.getKeys()

        // 清除掉低优先级的 key
        keys.forEach((key) => {
            if (this.lowLevelKeys.some((lowLevelKey) => key.startsWith(lowLevelKey))) {
                this.storage.removeItem(key)
            }
        })
    }

    /**
     * 拼接 key 和 suffix
     * @param key
     * @returns
     */
    private combineKey<T extends LocalStorageKey>(key: LocalStorageSupportKey<T>): string {
        if (typeof key === 'string') {
            return key
        }
        return key.key + key.suffix
    }

    /**
     * 从混合 key 中分离出具有类型表征的 key
     * @param key
     * @returns
     */
    private getKeyFromCombinedKey<T extends LocalStorageKey>(key: LocalStorageSupportKey<T>): LocalStorageKey {
        return typeof key === 'string' ? key : key.key
    }

    /**
     * @deprecated
     * set item with string value
     * @param key
     * @param value
     * @param shouldRetry
     */
    public setItem<T extends LocalStorageKey>(key: LocalStorageSupportKey<T>, value: string, shouldRetry = true): void {
        try {
            this.storage.setItem(this.combineKey(key), value)
        } catch (e) {
            if (!shouldRetry) {
                WkCLog.log(`WebStorage set item failed, type is: localStorage, value is: ${value}, error: ${e}`)
            } else {
                // 当第一次设置存储失败时，尝试从 low level 的 key 中删除一些存储后再次填入
                this.deleteLowLevelKey()
                this.setItem(key, value, false)
            }
        }
    }

    /**
     * @deprecated
     * set item with string value
     * @param key
     * @param defaultValue
     * @returns
     */
    public getItem<T extends LocalStorageKey>(key: LocalStorageSupportKey<T>): string | null {
        return this.storage.getItem(this.combineKey(key))
    }

    /**
     * 配置一个 kv 的存储，value 会做序列化后存储
     * value 在存入前会尝试用配置的 validator 做校验，如果校验成功才会设置
     * @param key
     * @param value
     * @returns
     */
    public setSerializedItem<T extends LocalStorageKey>(
        key: LocalStorageSupportKey<T>,
        value: LocalStorageKey2Schema[T],
        shouldRetry = true
    ): void {
        const valid = LocalStorageKey2Config[this.getKeyFromCombinedKey(key)].validator(value)
        const combinedKey = this.combineKey(key)

        if (!valid) {
            console.error(`localStorage set invalid value for ${combinedKey} : ${value}`)
            return
        }

        try {
            this.storage.setItem(combinedKey, JSON.stringify(value))
        } catch (e) {
            if (!shouldRetry) {
                WkCLog.log(`WebStorage set item failed, type is: localStorage, value is: ${value}, error: ${e}`)
            } else {
                // 当第一次设置存储失败时，尝试从 low level 的 key 中删除一些存储后再次填入
                this.deleteLowLevelKey()
                this.setSerializedItem(key, value, false)
            }
        }
    }

    /**
     * 获取一个 kv 的存储，没找到的结果为 null（如果填入 defaultValue 则为 defaultValue）
     * 如果从外部获取到的值无法通过配置的 validator，则会返回配置的 fallback 值
     * @param key
     * @param defaultValue
     * @returns
     */
    public getSerializedItem<T extends LocalStorageKey>(
        key: LocalStorageSupportKey<T>
    ): LocalStorageKey2Schema[T] | null
    public getSerializedItem<T extends LocalStorageKey>(
        key: LocalStorageSupportKey<T>,
        defaultValue: LocalStorageKey2Schema[T]
    ): LocalStorageKey2Schema[T]
    public getSerializedItem<T extends LocalStorageKey>(
        key: LocalStorageSupportKey<T>,
        defaultValue?: LocalStorageKey2Schema[T]
    ): LocalStorageKey2Schema[T] | null {
        const value = this.storage.getItem(this.combineKey(key))

        if (value === null && defaultValue !== undefined) {
            return defaultValue
        }

        if (value === null) {
            return null
        }

        const config = LocalStorageKey2Config[this.getKeyFromCombinedKey(key)]

        try {
            const parsedValue = JSON.parse(value!)
            // 需要通过合法性校验才能够返回
            return config.validator(parsedValue) ? parsedValue : config.fallbackValue
        } catch (e) {
            WkCLog.log(`WebStorage parsed value failed, type is: localStorage, value is: ${value}, error: ${e}`)
            return config.fallbackValue
        }
    }

    /**
     * 清除某个 key
     * @param key
     */
    public removeItem<T extends LocalStorageKey>(key: LocalStorageSupportKey<T>): void {
        this.storage.removeItem(this.combineKey(key))
    }

    /**
     * 获取当前存储下的 keys
     */
    public getKeys(): string[] {
        const keys = []
        for (let i = 0; i < this.storage.length; i++) {
            keys.push(this.storage.key(i) as string)
        }
        return keys
    }

    /**
     * Danger
     * 清除所有 key
     */
    public clear(): void {
        this.storage.clear()
    }
}

export const enhancedLocalStorage = new EnhancedLocalStorage()
