import { useState } from 'react'
import ReactJson from 'react-json-view'
import { DialogueAnswer, DialogueInput } from '../../../../../kernel/interface/chatbot'

export const DebugDialogueDetail = ({
    close,
    dialogueData,
}: {
    close: () => void
    dialogueData: {
        dialogueInput?: DialogueInput
        dialogueAnswer?: DialogueAnswer
    }
}) => {
    const [selectedItem, setSelectedItem] = useState('dialogueInput')

    const leftPanelTitles = Object.keys(dialogueData)

    const [isCollapsed, setIsCollapsed] = useState(true) // 是否折叠

    return (
        <>
            <div className="fixed inset-0 z-50 overflow-y-auto">
                <div className="flex min-h-screen items-center justify-center">
                    <div className="fixed inset-0 bg-black opacity-30" onClick={close} />

                    <div className="relative mx-auto max-w-5xl rounded bg-white shadow-lg flex">
                        {/* Left Panel - Dialogue List */}
                        <div className="w-64 border-r p-4 max-h-[80vh] overflow-y-auto">
                            <h4 className="text-sm font-medium mb-2">Dialogue</h4>
                            {leftPanelTitles.map((title, index) => (
                                <div
                                    key={title}
                                    className={`p-2 cursor-pointer rounded mb-1 text-sm ${
                                        selectedItem === title ? 'bg-blue-100 text-blue-700' : 'hover:bg-gray-100'
                                    }`}
                                    onClick={() => {
                                        setSelectedItem(title)
                                    }}
                                >
                                    {title}
                                </div>
                            ))}
                        </div>

                        {/* Right Panel - Debug Info */}
                        <div className="p-6">
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-lg font-medium">Debug Information</h3>
                                <button className="text-gray-500 hover:text-gray-700" onClick={close}>
                                    ✕
                                </button>
                            </div>
                            <div className="flex gap-2 mb-2">
                                <button
                                    className="rounded bg-gray-200 px-4 py-1 text-sm hover:bg-gray-300"
                                    onClick={() => setIsCollapsed(!isCollapsed)}
                                >
                                    {isCollapsed ? 'Expand All' : 'Collapse All'}
                                </button>
                            </div>
                            <div className="h-96 w-[700px] rounded border p-2 font-mono overflow-y-auto">
                                <ReactJson
                                    src={JSON.parse(
                                        JSON.stringify(dialogueData[selectedItem as keyof typeof dialogueData], null, 2)
                                    )}
                                    style={{ paddingTop: '4px' }}
                                    collapsed={isCollapsed}
                                    displayDataTypes={false}
                                    enableClipboard={true}
                                    name={selectedItem}
                                />
                            </div>
                            <button
                                className="mt-4 rounded bg-gray-200 px-4 py-2 hover:bg-gray-300"
                                onClick={() => {
                                    const text = JSON.stringify(
                                        dialogueData[selectedItem as keyof typeof dialogueData],
                                        null,
                                        2
                                    )
                                    navigator.clipboard.writeText(text)
                                }}
                            >
                                Copy to Clipboard
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
