import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    Basics: '基础',
    Variable: '可变字体轴',
    Decoration: '文字装饰',
    None: '无',
    Underline: '下划线',
    Strokethrough: '删除线',
} as const

export const enTranslation = {
    Basics: 'Basics',
    Variable: 'Variable',
    Decoration: 'Decoration',
    None: 'None',
    Underline: 'Underline',
    Strokethrough: 'Strokethrough',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
