import {
    ApplyAIComponentReplaceCommand,
    CurrentPageSetSelectionCommandWasmCall,
    FilterPageNodesCommand,
    Wukong,
    ZoomToSelectionWasmCall,
} from '@wukong/bridge-proto'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
    DraggablePopupV2,
    MonoIconPanelTarget16,
    MulticolorIconCommonAIReplaceLibrary16,
    Scrollbar,
    Tooltip,
    WKButton,
    WKPopover,
} from '../../../../../../ui-lib/src'
import { isEnglishLanguage } from '../../../../../../util/src'
import { WKFrog } from '../../../../kernel/frog'
import { CandidateComponentResolve } from '../../../../kernel/request/candidate-component'
import { CallAIComponentReplaceListRequest } from '../../../../main/ai-service/requests'
import { AIComponentReplaceEntry, AIComponentReplaceResponse } from '../../../../main/ai-service/typings'
import { useAppContext, useCommand, useDocId } from '../../../../main/app-context'
import { SandBoxManager } from '../../../../sandbox'
import { useViewState } from '../../../../view-state-bridge'
import { ReactComponent as ArrowRightSvg } from '../../../assets/ai/arrow-right.svg'
import { EllipsisTooltipWrapper } from '../../ai/candidate-component/ellipsis-tool-tip-wrapper'
import { IconButton } from '../../atom/button/icon-button'
import { useMenuContextState } from '../menu/context/menu-context'
import { ToolAiReplaceComponentImg } from './tool-ai-replace-component-img'
import { ToolAiReplaceComponentPreview } from './tool-ai-replace-component-preview'
import styles from './tool-ai-replace-component.module.less'
import { translation } from './tool-ai-replace-component.translation'

interface StyleUseCount {
    useTimes: number // 使用次数
    useCount: number // 使用节点数量之和
}

export function ToolAiReplaceComponent() {
    const aiService = useAppContext().aiService

    const [allList, setAllList] = useState<AIComponentReplaceResponse | null>(null)

    const editorType = useViewState('editorType')

    const isDevMode = useMemo(() => editorType === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV, [editorType])

    const [selectStyle, setSelectStyle] = useState(true)
    const [selectPage, setSelectPage] = useState(true)
    const [sceneItem, setSceneItem] = useState<AIComponentReplaceEntry | null>()
    const command = useCommand()
    const currentPageId = useViewState('currentPageId')
    const docReadOnly = useViewState('docReadonly')
    const docId = useDocId()

    const { isOpenAiReplaceComponent, setIsOpenAiReplaceComponent, setIsOpenFontMissDialog } = useMenuContextState()

    const openDialog = useCallback(() => {
        setIsOpenAiReplaceComponent(true)
        WKFrog.addFrogRecord({
            url: '/click/AIReplacesComponent/ClickRecieveReplace',
            eventId: 26847,
            eventAction: 'click',
            eventName: 'ClickRecieveReplace',
        })
        setIsOpenFontMissDialog(false)
    }, [setIsOpenAiReplaceComponent, setIsOpenFontMissDialog])

    const [styleList, setStyleList] = useState<AIComponentReplaceEntry[]>([])
    const [componentList, setComponentList] = useState<AIComponentReplaceEntry[]>([])

    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        if (!docReadOnly) {
            new CallAIComponentReplaceListRequest(docId).start().then((data) => {
                setAllList(data)
                if (Object.keys(data.snapshots).length > 0) {
                    // 有替换项就提前初始化沙盒
                    if (typeof requestIdleCallback === 'function') {
                        requestIdleCallback(() => {
                            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                            SandBoxManager.sharedInstance
                        })
                    } else {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        SandBoxManager.sharedInstance
                    }
                }
            })
        }
    }, [docId, docReadOnly])

    useEffect(() => {
        if (!allList) {
            return
        }
        if (!initialized) {
            setInitialized(true)
            if (allList.styleSubstituteEntries.length === 0 && allList.componentSubstituteEntries.length > 0) {
                setSelectStyle(false)
            }
        }
    }, [allList, initialized, setInitialized, setSelectStyle])

    const handleDetailClick = useCallback(
        (item: AIComponentReplaceEntry) => {
            setSceneItem(item)
        },
        [setSceneItem]
    )

    const getSubstituteThumbnailId = useCallback(
        (substituteContentId: number) => {
            if (!allList) {
                return null
            }
            return allList.snapshots[substituteContentId].thumbnailResourceId
        },
        [allList]
    )
    const getSubstituteItem = useCallback(
        (substituteContentId: number) => {
            if (!allList) {
                return null
            }
            return allList.snapshots[substituteContentId]
        },
        [allList]
    )

    const getName = useCallback(
        (substituteContentId: number) => {
            if (!allList) {
                return null
            }
            return allList.snapshots?.[substituteContentId]?.name
        },
        [allList]
    )

    const handleLocate = useCallback(
        (item: AIComponentReplaceEntry) => {
            if (!item.items.length) {
                return
            }
            let allNodes: string[] = []
            item.items.forEach((single) => {
                allNodes = allNodes.concat(single.nodeIds.flat())
            })
            command.DEPRECATED_invokeBridge(CurrentPageSetSelectionCommandWasmCall, {
                selection: allNodes,
            })
            command.DEPRECATED_invokeBridge(ZoomToSelectionWasmCall)
        },
        [command]
    )

    const getReplaceEntryItemSize = useCallback((item: AIComponentReplaceEntry) => {
        return item.items.reduce((acc, scene) => acc + scene.nodeIds.flat().length, 0)
    }, [])

    const getStyleSortWeight = useCallback((type: string) => {
        if (type === 'color') {
            return 1
        }
        if (type === 'font') {
            return 2
        }
        if (type === 'effect') {
            return 3
        }
        return 0
    }, [])

    const sortStyleList = useCallback(
        (list: AIComponentReplaceEntry[]) => {
            const substituteContentIdCount = new Map<number, StyleUseCount>()

            // 遍历 styleList，并统计替代内容快照节点 ID 的出现次数和总重复次数
            for (const style of list) {
                if (style.substituteContentId) {
                    if (!substituteContentIdCount.has(style.substituteContentId)) {
                        substituteContentIdCount.set(style.substituteContentId, { useTimes: 0, useCount: 0 })
                    }
                    const count = substituteContentIdCount.get(style.substituteContentId)!
                    count.useTimes++
                    count.useCount += getReplaceEntryItemSize(style)
                    substituteContentIdCount.set(style.substituteContentId, count)
                }
            }
            list.sort((a, b) => {
                // 首先按照类型排序
                if (a.type !== b.type) {
                    return getStyleSortWeight(a.type) - getStyleSortWeight(b.type)
                }

                // 如果类型相同，则按照 substituteContentSnapshotNodeId 相同数量和 num 进行排序
                if (a.substituteContentId !== b.substituteContentId) {
                    const countA = substituteContentIdCount.get(a.substituteContentId)!
                    const countB = substituteContentIdCount.get(b.substituteContentId)!
                    if (countA.useTimes !== countB.useTimes) {
                        // 比较被替换组件的使用次数，使用次数多的排在前面
                        return countB.useTimes - countA.useTimes
                    } else {
                        // 使用次数一样时，比较整组引用次数之和，引用次数多的排前面
                        return countB.useCount - countA.useCount
                    }
                }

                // 如果 toReplaceId 相同数量相同，则按照 num 排序
                return getReplaceEntryItemSize(b) - getReplaceEntryItemSize(a)
            })
        },
        [getReplaceEntryItemSize, getStyleSortWeight]
    )

    const getFilteredList = useCallback(
        (list: AIComponentReplaceEntry[]) => {
            const allContainerIds: string[] = []
            list.forEach((styleScene) => {
                styleScene.items.forEach((item) => {
                    allContainerIds.push(item.containerIds[0])
                })
            })
            const filteredIds = command.DEPRECATED_invokeBridge(FilterPageNodesCommand, {
                nodeIds: allContainerIds,
            })

            const fiteredList: AIComponentReplaceEntry[] = []
            list.forEach((currentScene) => {
                const items = currentScene.items.filter((item) => filteredIds.nodeIds.includes(item.containerIds[0]))
                if (items.length) {
                    fiteredList.push({ ...currentScene, items })
                }
            })
            return fiteredList
        },
        [command]
    )

    const handleConfirm = useCallback(
        (id: number, version: number) => {
            if (!allList) {
                return
            }
            let showList = allList.componentSubstituteEntries
            if (!sceneItem || !showList) {
                return
            }

            const substituteIndex = showList.findIndex(
                (item) => sceneItem.candidateThumbnailResourceId === item.candidateThumbnailResourceId
            )

            if (sceneItem.items.length == 1) {
                setSceneItem(null)
                showList.splice(substituteIndex, 1)
            } else {
                const sceneIndex = sceneItem.items.findIndex((item) => item.id === id)
                sceneItem.items.splice(sceneIndex, 1)
                const newSceneItem = { ...sceneItem }
                setSceneItem(newSceneItem)
                showList.splice(substituteIndex, 1, newSceneItem)
            }
            if (selectPage) {
                showList = getFilteredList(showList)
            }
            setComponentList(showList)
            // 这是向server发送替换的请求, 调试时可以注释掉, 让一个场景可以反复替换
            new CandidateComponentResolve([id], version).start()
        },
        [allList, sceneItem, selectPage, getFilteredList]
    )

    const handleReplaceClick = useCallback(
        async (item: AIComponentReplaceEntry) => {
            if (!allList) {
                return
            }
            const currentSnap = allList.snapshots[item.substituteContentId]
            if (!currentSnap) {
                return
            }
            WKFrog.addFrogRecord({
                url: '/click/AIReplacesComponent/ClickStartReciveReplace',
                eventId: 26848,
                eventAction: 'click',
                eventName: 'ClickStartReciveReplace',
            })
            const response = await aiService
                .getLibraryResourceDownloader()
                .fetchFile(currentSnap.nodeProtoDataResourceId)
            if (!response) {
                return
            }

            const protoArr = new Uint8Array(await response.arrayBuffer())

            const exportedDocument = Wukong.DocumentProto.SerializedExportedDocument.decode(protoArr)

            let nodeIdsArray: Wukong.DocumentProto.INodeIdsWrapper[] = []
            for (const scene of item.items) {
                const sceneArray = scene.nodeIds.map((subArray) => ({ value: subArray }))
                nodeIdsArray = nodeIdsArray.concat(sceneArray)
            }
            const param = Wukong.DocumentProto.ApplyAIComponentReplaceCommandParam.create({
                exportedDocument,
                toCreateNodeId: currentSnap.nodeId,
                nodeIdsArray,
                aiReplaceResults: [],
            })
            command.DEPRECATED_invokeBridge(ApplyAIComponentReplaceCommand, param)
            command.commitUndo()

            const versionMap = new Map<number, number[]>()
            for (const scene of item.items) {
                const ids = versionMap.get(scene.version) || []
                ids.push(scene.id)
                versionMap.set(scene.version, ids)
            }
            for (const [version, ids] of versionMap) {
                // 这是向server发送替换的请求, 调试时可以注释掉, 让一个场景可以反复替换
                new CandidateComponentResolve(ids, version).start()
            }
            let showList = allList.styleSubstituteEntries
            const substituteIndex = showList.findIndex(
                (innerItem) => item.candidateThumbnailResourceId === innerItem.candidateThumbnailResourceId
            )
            if (substituteIndex === -1) {
                return
            }
            const substituteSenceIds = item.items.map((scene) => scene.id)
            const substituteItem = showList[substituteIndex]
            substituteItem.items = substituteItem.items.filter((scene) => !substituteSenceIds.includes(scene.id))
            if (substituteItem.items.length === 0) {
                showList.splice(substituteIndex, 1)
            } else {
                showList.splice(substituteIndex, 1, substituteItem)
            }
            if (selectPage) {
                showList = getFilteredList(showList)
            }
            sortStyleList(showList)
            setStyleList(showList)
        },
        [allList, aiService, command, selectPage, getFilteredList, sortStyleList]
    )

    useEffect(() => {
        if (!allList) {
            setStyleList([])
            setComponentList([])
            return
        }
        if (selectPage) {
            if (selectStyle) {
                const fiteredList = getFilteredList(allList.styleSubstituteEntries)
                sortStyleList(fiteredList)
                setStyleList(fiteredList)
            } else {
                const fiteredList = getFilteredList(allList.componentSubstituteEntries)
                setComponentList(fiteredList)
            }
        } else {
            const list = [...allList.styleSubstituteEntries]
            sortStyleList(list)
            setStyleList(list)
            setComponentList(allList.componentSubstituteEntries)
        }
    }, [
        allList,
        selectStyle,
        setStyleList,
        setComponentList,
        selectPage,
        command,
        currentPageId,
        sortStyleList,
        getFilteredList,
    ])

    if (
        isDevMode ||
        !allList ||
        (!allList.componentSubstituteEntries.length && !allList.styleSubstituteEntries.length)
    ) {
        return <></>
    }

    return (
        <>
            <WKPopover
                contents={translation('ReplaceStyleComponent')}
                contentClassName={styles.popoverContentContainer}
                placement="bottom-center"
            >
                <div className={styles.root} onClick={openDialog} data-testid="apply-ai-replace-icon">
                    <div className={styles.icon}>
                        <MulticolorIconCommonAIReplaceLibrary16 />
                    </div>
                </div>
            </WKPopover>
            {isOpenAiReplaceComponent ? (
                <DraggablePopupV2
                    visible
                    width={464}
                    bodyClassName="pt-0 pr-0 pb-4 pl-0"
                    testId="ai-replace-component-modal"
                    position={{ top: 57, left: window.innerWidth - 257 - 464 }}
                    onCancel={() => {
                        setIsOpenAiReplaceComponent(false)
                    }}
                    footer={null}
                    header={
                        <div className={styles.header}>
                            <div
                                className={selectStyle ? styles.active : ''}
                                onClick={() => {
                                    setSelectStyle(true)
                                    setSceneItem(null)
                                }}
                            >
                                {translation('ReplaceStyle')}
                            </div>
                            <div
                                className={`${!selectStyle ? styles.active : ''} `}
                                onClick={() => {
                                    setSelectStyle(false)
                                    setSceneItem(null)
                                }}
                            >
                                {translation('ReplaceComponent')}
                            </div>
                        </div>
                    }
                >
                    {!sceneItem ? (
                        <>
                            <div className={styles.pageTabWrapper}>
                                <div className={selectPage ? styles.active : ''} onClick={() => setSelectPage(true)}>
                                    {translation('CurrentPage')}
                                </div>
                                <div
                                    className={`${!selectPage ? styles.active : ''} `}
                                    onClick={() => setSelectPage(false)}
                                >
                                    {translation('CurrentFile')}
                                </div>
                            </div>
                            <Scrollbar autoHeight autoHeightMin={486} autoHeightMax={486} height={486}>
                                <div style={{ padding: '16px 20px 16px 24px', zIndex: 'unset' }}>
                                    {selectStyle && styleList ? (
                                        styleList.length ? (
                                            styleList.map((item, index) => (
                                                <div
                                                    key={item.candidateThumbnailResourceId}
                                                    className={styles.replaceStyleWrapper}
                                                >
                                                    <Tooltip
                                                        title={`${translation(
                                                            'SelectLayers1'
                                                        )}${getReplaceEntryItemSize(item)}${translation(
                                                            'SelectLayers2'
                                                        )}`}
                                                    >
                                                        <IconButton
                                                            className={styles.locateIcon}
                                                            selected={false}
                                                            icon={
                                                                <MonoIconPanelTarget16
                                                                    className={styles.forceSvgGray13}
                                                                />
                                                            }
                                                            onClick={() => handleLocate(item)}
                                                        />
                                                    </Tooltip>
                                                    <div className={styles.replaceImgWrapper}>
                                                        <ToolAiReplaceComponentImg
                                                            imgId={item.candidateThumbnailResourceId}
                                                            item={getSubstituteItem(item.substituteContentId)}
                                                            isStyle
                                                            isGrid={item.type === 'color'}
                                                        />
                                                        {item.candidateName && (
                                                            <EllipsisTooltipWrapper
                                                                text={item.candidateName}
                                                                width={isEnglishLanguage() ? 111 : 122}
                                                            />
                                                        )}
                                                    </div>

                                                    <ArrowRightSvg className={styles.noShrink}></ArrowRightSvg>

                                                    <div className={styles.replaceImgWrapper}>
                                                        <ToolAiReplaceComponentImg
                                                            imgId={getSubstituteThumbnailId(item.substituteContentId)}
                                                            item={getSubstituteItem(item.substituteContentId)}
                                                            isStyle
                                                            isRadius={item.type === 'color'}
                                                            isGrid={item.type === 'color'}
                                                        />
                                                        <EllipsisTooltipWrapper
                                                            text={getName(item.substituteContentId) ?? ''}
                                                            width={isEnglishLanguage() ? 111 : 122}
                                                        />
                                                    </div>
                                                    <WKButton
                                                        type="secondary"
                                                        onClick={() => handleReplaceClick(item)}
                                                        size="small"
                                                        dataTestId={`ai-replace-btn-${index}`}
                                                    >
                                                        {translation('Replace')}
                                                    </WKButton>
                                                </div>
                                            ))
                                        ) : (
                                            <div className={styles.empty}>
                                                {selectPage
                                                    ? translation('NoReplacePageStyle')
                                                    : translation('NoReplaceFileStyle')}
                                            </div>
                                        )
                                    ) : componentList.length ? (
                                        componentList.map((item) => (
                                            <div
                                                key={item.candidateThumbnailResourceId}
                                                className={styles.replaceComponentWrapper}
                                            >
                                                <ToolAiReplaceComponentImg
                                                    imgId={item.candidateThumbnailResourceId}
                                                    item={getSubstituteItem(item.substituteContentId)}
                                                />
                                                <ArrowRightSvg className={styles.noShrink}></ArrowRightSvg>
                                                <div className={styles.replaceImgWrapper}>
                                                    <ToolAiReplaceComponentImg
                                                        imgId={getSubstituteThumbnailId(item.substituteContentId)}
                                                        item={getSubstituteItem(item.substituteContentId)}
                                                    />
                                                    <EllipsisTooltipWrapper
                                                        text={getName(item.substituteContentId) ?? ''}
                                                        width={isEnglishLanguage() ? 155 : 160}
                                                    />
                                                </div>
                                                <WKButton
                                                    type="secondary"
                                                    onClick={() => handleDetailClick(item)}
                                                    size="small"
                                                >
                                                    {translation('View')}
                                                </WKButton>
                                            </div>
                                        ))
                                    ) : (
                                        <div className={styles.empty}>
                                            {selectPage
                                                ? translation('NoReplacePageComponent')
                                                : translation('NoReplaceFileComponent')}
                                        </div>
                                    )}
                                </div>
                            </Scrollbar>
                        </>
                    ) : (
                        <ToolAiReplaceComponentPreview
                            snapshots={allList?.snapshots ?? {}}
                            sceneItem={sceneItem}
                            onBack={() => setSceneItem(null)}
                            onConfirm={handleConfirm}
                            isStyle={selectStyle}
                            isSelectPage={selectPage}
                        ></ToolAiReplaceComponentPreview>
                    )}
                </DraggablePopupV2>
            ) : null}
        </>
    )
}
