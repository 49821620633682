import classNames from 'classnames'
import { Tooltip, useEllipsisTooltip } from '../../../../../../../ui-lib/src'
import { CopyableRow } from '../common/copyable-row'
import style from './index.module.less'

export function DevModeProperty({ property }: { property: string }) {
    const { inactivation, ellipsisRef, onmouseenter } = useEllipsisTooltip<HTMLDivElement>()

    return (
        <Tooltip title={property} inactivation={inactivation}>
            <div className={classNames(style.property, style.variable)} ref={ellipsisRef} onMouseEnter={onmouseenter}>
                {property}
            </div>
        </Tooltip>
    )
}

export function DevModePropertyValue({ value }: { value: string }) {
    const { inactivation, ellipsisRef, onmouseenter } = useEllipsisTooltip<HTMLDivElement>()

    return (
        <Tooltip title={value} inactivation={inactivation}>
            <div className={style.propertyValueContainer} ref={ellipsisRef} onMouseEnter={onmouseenter}>
                <CopyableRow copyValue={value} style={{ height: 'var(--wk-line-height-body)' }}>
                    <span className={style.propertyValue}>{value}</span>
                </CopyableRow>
            </div>
        </Tooltip>
    )
}
