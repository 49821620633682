import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    SelectNode: '请先选中一个想要检索的图层',
    SelectedNode: '已选中图层「',
    SelectedNodeSuffix: '」',
    StartSearch: '开始查找',
    UploadImage: '上传想要检索的图片',
    Or: '或',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    SelectNode: 'Select a layer you want to search',
    SelectedNode: 'Selected "',
    SelectedNodeSuffix: '"',
    StartSearch: 'Search',
    UploadImage: 'Upload an image you want to search',
    Or: 'or',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
