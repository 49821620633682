export const generateRouterPrefix = (): string => {
    // eslint-disable-next-line no-process-env
    let routerPrefix = process.env.ROUTER_PREFIX || ''
    if (routerPrefix.startsWith('commit') || routerPrefix.endsWith('-release/')) {
        // 如果是 review/commit/release 分支，prefix 增加 app 的标志，用于 nginx 来匹配 try_files
        routerPrefix = routerPrefix + 'app/'
    }
    return routerPrefix
}
/**
 * 由于采用了 browserRouter，故生成 routerPath 需要带上 prefix，用于兼容测试和 reivew 环境
 * @param route
 */
export const generateRouterPath = (path: string): string => {
    return `${generateRouterPrefix()}${path}`
}
