/**
 * @owner: jiangzg@kanyun.com
 */
import { useMemo } from 'react'

interface Props {
    /**
     * 进度百分比 75 80 ...
     */
    percent: number
}

export const Progress = ({ percent }: Props) => {
    const containerStyle: React.CSSProperties = {
        borderRadius: '3px',
        backgroundColor: 'var(--wk-gray-3)',
        height: '6px',
        overflow: 'hidden',
    }

    const innerStyle: React.CSSProperties = {
        borderRadius: '3px',
        backgroundColor: 'var(--wk-brand-7)',
        height: '100%',
        transitionProperty: 'width',
        transitionDuration: '1s',
        width: percent + '%',
    }

    return (
        <div style={containerStyle}>
            <div style={innerStyle}></div>
        </div>
    )
}

interface CircleProgressProps {
    progress: number // 进度值，[0, 1] 之间的小数
    size?: number // 尺寸
    color?: string // 颜色
    thickness?: number // 环形内径，设置为 0 则为圆形
    className?: string
}

function polarToCartesian(cx: number, cy: number, radius: number, degrees: number) {
    const angleInRadians = ((degrees - 90) * Math.PI) / 180.0

    return {
        x: cx + radius * Math.cos(angleInRadians),
        y: cy + radius * Math.sin(angleInRadians),
    }
}

export function CircleProgress(props: CircleProgressProps) {
    const progress = props.progress
    const size = props.size ?? 40
    const thickness = props.thickness ?? size

    const opts = useMemo(
        () => ({
            cx: size,
            cy: size,
            radius: size,
            startAngle: 0,
            endAngle: Math.ceil((progress / 100) * 360),
            thickness,
        }),
        [progress, thickness, size]
    )

    const path = useMemo(() => {
        const start = polarToCartesian(opts.cx, opts.cy, opts.radius, opts.endAngle)
        const end = polarToCartesian(opts.cx, opts.cy, opts.radius, opts.startAngle)
        const flag = opts.endAngle - opts.startAngle <= 180 ? 0 : 1

        const cutout = opts.radius - opts.thickness
        const innerStart = polarToCartesian(opts.cx, opts.cy, cutout, opts.endAngle)
        const innerEnd = polarToCartesian(opts.cx, opts.cy, cutout, opts.startAngle)

        const pathItems = [
            ['M', start.x, start.y],
            ['A', opts.radius, opts.radius, 0, flag, 0, end.x, end.y],
            ['L', opts.cx, opts.cy],
            ['Z'],
            ['M', innerStart.x, innerStart.y],
            ['A', cutout, cutout, 0, flag, 0, innerEnd.x, innerEnd.y],
            ['L', opts.cx, opts.cy],
            ['Z'],
        ].map((v) => v.join(' '))

        return pathItems.join(' ')
    }, [opts])

    return (
        <svg
            viewBox={`0 0 ${size * 2} ${size * 2}`}
            width={size}
            height={size}
            className={props.className}
            data-testid="circle-progress-svg"
        >
            {progress < 100 ? (
                <path fill={props.color ?? 'black'} stroke="none" fillRule="evenodd" d={path} />
            ) : (
                <circle cx={opts.cx} cy={opts.cy} r={opts.radius} fill={props.color} />
            )}
        </svg>
    )
}
