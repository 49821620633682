import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    p: '专业版',
    y: '年',
    m: '月',
    currentPlan: '当前版本',
    up: '升级至专业版',
    chooseTrial: '选择专业版（试用中）',
    mp: '最受欢迎',
} as const

export const enTranslation = {
    p: 'Professional',
    y: 'year',
    m: 'month',
    currentPlan: 'Current Plan',
    up: 'Upgrade to Professional',
    chooseTrial: 'Choose Professional (In trial)',
    mp: 'Most Popular',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
