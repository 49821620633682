interface AePath {
    points: number[][]
    inTangents: number[][]
    outTangents: number[][]
    closed: boolean
}

// AEUX 不能处理二次贝塞尔，这里先将二次贝塞尔转成三次
const quadraticToCubic = (d: string): string => {
    // 将路径分为多个子路径
    const pathArray = d.split(/(?=[MCZ])/)

    const newPathArray = pathArray.map((subpath) => {
        // 将子路径分为多个指令
        const commandArray: any = subpath.match(/[MLHVCSQTAZ][^MLHVCSQTAZ]*/g)
        let preCommand = 'M'

        const newCommandArray = commandArray.map((command: string) => {
            // 解析指令参数
            const params = command.slice(1).split(/[\s,]/).map(Number)
            // 不是二次贝塞尔曲线指令，原样返回
            if (command[0] !== 'Q') {
                return command
            }
            // 二次贝塞尔曲线控制点坐标
            const [x1, y1, x2, y2] = params
            // 上一个指令（默认是 M）
            const prevCommand = preCommand.slice(0, 1)
            // 起点坐标
            const x0 = prevCommand === 'C' ? params[2] * 2 - params[4] : params[0]
            const y0 = prevCommand === 'C' ? params[3] * 2 - params[5] : params[1]
            // 新控制点坐标 1
            const cx1 = x0 + (2 / 3) * (x1 - x0)
            const cy1 = y0 + (2 / 3) * (y1 - y0)
            // 新控制点坐标 2
            const cx2 = x2 + (2 / 3) * (x1 - x2)
            const cy2 = y2 + (2 / 3) * (y1 - y2)
            // 计算出新的三次贝萨尔坐标
            const newCommand = `C${cx1},${cy1} ${cx2},${cy2} ${x2},${y2}`
            preCommand = newCommand
            return newCommand
        })
        return newCommandArray.join('')
    })

    return newPathArray.join('')
}

// from aeux
// 具体的转化逻辑不明.. 无法通过代码分析，下面代码均为拷贝实现
export const parseSvg = (svg: string): AePath => {
    const pathObj: AePath = {
        points: [],
        inTangents: [],
        outTangents: [],
        closed: false,
    }

    let shouldClosePath = false

    // used as a negative offset when pulling coords from SVG
    let minX = 9999999999999
    let minY = 9999999999999

    // add line breaks between SVG commands
    const path = quadraticToCubic(svg)
        .replace(/\s*([mlvhqczMLVHQCZ])\s*/g, '\n$1 ')
        .replace(/,/g, ' ')
        .replace(/ +/g, ' ')

    const strings = path.split('\n')
    strings.splice(strings.length - 1, 0, strings.splice(0, 1)[0])
    strings.splice(strings.length - 1, 0, strings.splice(0, 1)[0])
    strings.splice(strings.length - 1, 0, strings.splice(0, 1)[0])
    strings.reverse()

    // check for closed
    const tempPointList = []

    for (let string of strings) {
        string = string.trim() // remove white space

        const op = string.substring(0, 1)
        const terms = string.substring(2).trim().split(' ')

        // start or straight line
        if (op == 'L' || op == 'M') {
            const x = parseFloat(terms[0])
            const y = parseFloat(terms[1])
            tempPointList.push([x, y])
        }
        // curve
        if (op == 'C') {
            const x = parseFloat(terms[4])
            const y = parseFloat(terms[5])
            tempPointList.push([x, y])
        }
    }

    // store all points/tangents
    for (let i = 0; i < strings.length; i++) {
        const string = strings[i].trim() // remove white space

        if (+string < 1) {
            continue
        } // skip if empty

        const op = string.substring(0, 1)
        const terms = string.substring(2).trim().split(' ')

        // check closing
        if (op == 'Z') {
            shouldClosePath = true
        }

        // start or straight line
        if (!pathObj.closed && op == 'M') {
            // calc min x,y for offset
            minX = Math.min(minX, +terms[0])
            minY = Math.min(minY, +terms[1])

            // add coords to pathObj
            pathObj.points.push([parseFloat(terms[0]), parseFloat(terms[1])])
            pathObj.inTangents.push([0, 0])
            pathObj.outTangents.push([0, 0])
        }
        // start or straight line
        if (op == 'L') {
            // calc min x,y for offset
            minX = Math.min(minX, +terms[0])
            minY = Math.min(minY, +terms[1])

            // add coords to pathObj
            // pathObj.points.push(terms);
            pathObj.points.push([parseFloat(terms[0]), parseFloat(terms[1])])
            pathObj.inTangents.push([0, 0])
            pathObj.outTangents.push([0, 0])
        }
        // // curve
        if (op == 'C') {
            // calc min x,y for offset
            minX = Math.min(minX, +terms[4])
            minY = Math.min(minY, +terms[5])

            // add coords to pathObj
            pathObj.points.push([parseFloat(terms[4]), parseFloat(terms[5])])
            pathObj.inTangents.push([+terms[0], +terms[1]])

            const outTangent = [+terms[2] - +terms[4], +terms[3] - +terms[5]]
            pathObj.outTangents.push(outTangent)
        }

        // horizontal/vertical line
        // 在 Motiff 的 path 中应该不会存在 V/H 的 path
        if (op == 'H') {
            // 这里源码中将一个 string[] 做 parseFloat，不知道什么意思
            const len = parseFloat(terms as unknown as string)

            try {
                const lastTerms = strings[i + 1].substring(2).trim().split(' ')
                pathObj.points.push([len, +lastTerms[1]])
            } catch (e) {
                const lastTerms = strings[0].substring(2).trim().split(' ')
                pathObj.points.push([len, +lastTerms[1]])
            }
            pathObj.inTangents.push([0, 0])
            pathObj.outTangents.push([0, 0])
        }
        if (op == 'V') {
            // 这里源码中将一个 string[] 做 parseFloat，不知道什么意思
            const len = parseFloat(terms as unknown as string)
            try {
                const lastTerms = strings[i + 1].substring(2).trim().split(' ')
                pathObj.points.push([+lastTerms[0], len])
            } catch (e) {
                const lastTerms = strings[0].substring(2).trim().split(' ')
                pathObj.points.push([+lastTerms[0], len])
            }
            pathObj.inTangents.push([0, 0])
            pathObj.outTangents.push([0, 0])
        }
    }
    pathObj.inTangents.splice(0, 0, pathObj.inTangents.splice(pathObj.inTangents.length - 1, 1)[0])

    for (let j = 0; j < pathObj.points.length; j++) {
        const point = pathObj.points[j]
        const inTangent = pathObj.inTangents[j]

        // if inTangents is not zero
        if (inTangent[0] != 0 || inTangent[1] != 0) {
            pathObj.inTangents[j] = [inTangent[0] - point[0], inTangent[1] - point[1]]
        }
    }

    // rotate the points and tangents back to their original points when not closing the path
    if (!shouldClosePath) {
        for (let i = 0; i < pathObj.points.length - 2; i++) {
            pathObj.points.unshift(pathObj.points.pop()!)
            pathObj.inTangents.unshift(pathObj.inTangents.pop()!)
            pathObj.outTangents.unshift(pathObj.outTangents.pop()!)
        }
    }
    // remove duplicate points when closing the path
    if (shouldClosePath) {
        pathObj.closed = true

        // loop through coords and if the current coord matches the previous coord
        for (let k = 0; k < pathObj.points.length; k++) {
            if (
                k > 0 &&
                pathObj.points[k][0] == pathObj.points[k - 1][0] &&
                pathObj.points[k][1] == pathObj.points[k - 1][1]
            ) {
                pathObj.points.splice(k, 1)
                pathObj.inTangents.splice(k, 1)
                pathObj.outTangents.splice(k - 1, 1)
            }
        }
    }

    return pathObj
}
