import { useState } from 'react'
import { InputV2, Select, WKButton } from '../../../../../../../../../ui-lib/src'
import { PopupStateType } from '../../../../../../../document/node/node'
import { SingleGrid } from '../../../../../atom/grid/single-grid'
import { Arg_cmdEditComponentPropData, ComponentPropType, VComponentPropDef, VariableAnyValue } from '../../../types'
import { ICreateComponentProp } from '../../component-prop/hook'
import styles from './index.module.less'
import { translation } from './index.translation'

const BoolPropSelectOptions = [
    {
        key: 'display',
        label: translation('Visible'),
        value: true,
    },
    {
        key: 'hide',
        label: translation('Hidden'),
        value: false,
    },
]

export function CreateCompPropValAreaOfBoolean(props: {
    popupData: VComponentPropDef
    popupType: PopupStateType
    closePopup: () => void
    createProp: (propVal: ICreateComponentProp) => void
    createAndApplyProp: (propVal: ICreateComponentProp) => void
}) {
    const { closePopup, popupType, popupData, createAndApplyProp, createProp } = props

    const [boolName, setBoolName] = useState<string>(popupData.name)
    const [boolVal, setBoolVal] = useState<boolean>(popupData.varValue.value.boolValue)

    const onSelectChange = (value: boolean) => {
        setBoolVal(value)
    }

    const confirm = () => {
        const propData = {
            name: boolName,
            type: ComponentPropType.COMPONENT_PROP_TYPE_BOOL,
            value: {
                boolValue: boolVal,
            } as VariableAnyValue,
        }

        if (popupType === PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_BOOL_PROP_OF_LAYER_AREA) {
            createAndApplyProp(propData)
        } else if (popupType === PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_BOOL_PROP_OF_PROP_AREA) {
            createProp(propData)
        }
        closePopup()
    }

    return (
        <>
            <div className={styles.createCompPropValArea}>
                <SingleGrid>
                    <SingleGrid.Item start={5} span={16}>
                        {translation('PropertyName')}
                    </SingleGrid.Item>
                    <SingleGrid.Item start={21} span={36}>
                        <InputV2
                            size="small"
                            autoFocus
                            dataTestIds={{ input: 'create-bool-prop-name-input' }}
                            value={boolName}
                            onChange={(e) => setBoolName(e.target.value)}
                        />
                    </SingleGrid.Item>
                </SingleGrid>
                <SingleGrid>
                    <SingleGrid.Item start={5} span={16}>
                        {translation('PropertyValue')}
                    </SingleGrid.Item>
                    <SingleGrid.Item start={21} span={36}>
                        <Select.NormalSingleLevel
                            label={boolVal ? translation('Visible') : translation('Hidden')}
                            minWidth={144}
                            value={boolVal}
                            onChange={onSelectChange}
                        >
                            {BoolPropSelectOptions.map((option) => (
                                <Select.NormalSingleLevel.Option key={option.key} value={option.value}>
                                    {option.label}
                                </Select.NormalSingleLevel.Option>
                            ))}
                        </Select.NormalSingleLevel>
                    </SingleGrid.Item>
                </SingleGrid>
            </div>
            <div className={styles.createCompPropConfirm}>
                <WKButton type="primary" dataTestId="create-prop-confirm" onClick={confirm} disabled={!boolName}>
                    {translation('CreateProp')}
                </WKButton>
            </div>
        </>
    )
}

export function UpdateCompPropValAreaOfBoolean(props: {
    popupData: VComponentPropDef
    updateProp: (propVal: Arg_cmdEditComponentPropData) => void
}) {
    const { popupData, updateProp } = props

    const onInputBlur = (value: string) => {
        if (!popupData || !value || value === popupData.name) {
            return
        }

        updateProp({
            propId: popupData.id,
            name: value,
        })
    }

    const onSelectChange = (value: boolean) => {
        if (!popupData || value === popupData.varValue.value.boolValue) {
            return
        }

        updateProp({
            propId: popupData.id,
            value: {
                boolValue: value,
            } as VariableAnyValue,
        })
    }

    return (
        <div className={styles.updateCompPropValArea}>
            <SingleGrid>
                <SingleGrid.Item start={5} span={16}>
                    {translation('PropertyName')}
                </SingleGrid.Item>
                <SingleGrid.Item start={21} span={36}>
                    <InputV2
                        autoFocus
                        size="small"
                        dataTestIds={{ input: 'update-bool-prop-name-input' }}
                        value={popupData.name}
                        onBlur={(e) => onInputBlur(e.target.value)}
                    />
                </SingleGrid.Item>
            </SingleGrid>
            <SingleGrid>
                <SingleGrid.Item start={5} span={16}>
                    {translation('PropertyValue')}
                </SingleGrid.Item>
                <SingleGrid.Item start={21} span={36}>
                    <Select.NormalSingleLevel
                        label={popupData.varValue.value.boolValue ? translation('Visible') : translation('Hidden')}
                        minWidth={144}
                        value={popupData.varValue.value.boolValue}
                        onChange={onSelectChange}
                        dataTestIds={{ triggerFocus: 'update-bool-prop-select' }}
                    >
                        {BoolPropSelectOptions.map((option) => (
                            <Select.NormalSingleLevel.Option key={option.key} value={option.value}>
                                {option.label}
                            </Select.NormalSingleLevel.Option>
                        ))}
                    </Select.NormalSingleLevel>
                </SingleGrid.Item>
            </SingleGrid>
        </div>
    )
}
