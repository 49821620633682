import { useMemo } from 'react'
import style from './color-line.module.less'

export interface ColorLineProps {
    width: number
    height: number
    selected?: boolean
    disabled?: boolean
    testId?: string
}

export function ColorLine(props: ColorLineProps) {
    const { width, height, selected, disabled } = props

    const divStyle = useMemo(() => {
        let _width = width,
            _height = height
        if (selected) {
            const isWidth = width <= height
            if (isWidth) {
                _width += 2
            } else {
                _height += 2
            }
        }
        return { width: _width, height: _height }
    }, [width, height, selected])

    const divClassName = useMemo(() => {
        let _divClassName = style.colorLine
        selected && (_divClassName += ' ' + style.selected)
        disabled && (_divClassName += ' ' + style.disabled)
        return _divClassName
    }, [selected, disabled])

    return <div style={divStyle} className={divClassName} data-testid={props.testId}></div>
}
