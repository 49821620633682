export function isFlex(node: Element) {
    const computedStyle = window.getComputedStyle(node, null)

    const isDisplayFlex =
        (computedStyle.display === 'flex' || computedStyle.display === 'inline-flex') &&
        !computedStyle.flexDirection.includes('reverse')

    if (!isDisplayFlex) {
        return false
    }

    const children = Array.prototype.slice.call(node.children)
    const hasAbsoultChild = children.some((item: Element) => getComputedStyle(item).position === 'absolute')
    const hasRelativeChild = children.some((item: Element) => getComputedStyle(item).position === 'relative')
    const hasZindexChild = children.some((item: Element) => getComputedStyle(item).zIndex !== 'auto')

    return !(hasAbsoultChild && hasRelativeChild) && !hasZindexChild
}

export function getJustifyContent(justifyContent: string) {
    switch (justifyContent) {
        case 'center':
            return 'center'
        case 'end':
        case 'flex-end':
        case 'right':
            return 'end'
        case 'space-between':
            return 'space-between'
        case 'space-around':
        case 'space-evenly':
            return 'space-evenly'
        case 'stretch':
            return 'stretch'
        default:
            return 'start'
    }
}
