/* eslint-disable no-restricted-imports */
import { useCallback, useMemo } from 'react'
import { cmdChangePopupState } from '../../../../../document/command/document-command'
import { PopupStateType } from '../../../../../document/node/node'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'

export function useStrokeMoreSetting() {
    const popupState = useViewState('popupState')
    const isOpen = useMemo(
        () => popupState && popupState.type === PopupStateType.POPUP_STATE_TYPE_STROKES_ADVANCE,
        [popupState]
    )
    const command = useCommand()

    const changePopupState = useCallback(
        (openModal: boolean) => {
            if (openModal) {
                command.invoke(cmdChangePopupState, {
                    type: PopupStateType.POPUP_STATE_TYPE_STROKES_ADVANCE,
                    reciprocalIndex: -1,
                    multiPopup: [],
                })
            } else {
                command.invoke(cmdChangePopupState, {
                    type: PopupStateType.POPUP_STATE_TYPE_NONE,
                    reciprocalIndex: -1,
                    multiPopup: [],
                })
            }
        },
        [command]
    )

    const onClickMoreIcon = useCallback(() => {
        if (isOpen) {
            changePopupState(false)
        } else {
            changePopupState(true)
        }
    }, [isOpen, changePopupState])

    const onClose = useCallback(() => {
        changePopupState?.(false)
    }, [changePopupState])

    return {
        isOpen,
        onClose,
        onClickMoreIcon,
    }
}
