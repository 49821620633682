/**
 * @owner: loushuangzhanbj@kanyun.com
 */
import { SelectArrowEmpty } from './select/select-arrow-empty'
import { SelectArrowMultiLevel } from './select/select-arrow-multi-level'
import { SelectArrowSingleLevel } from './select/select-arrow-single-level'
import { SelectEmptyEmpty } from './select/select-empty-empty'
import { SelectIconEmpty } from './select/select-icon-empty'
import { SelectIconMultiLevel } from './select/select-icon-multi-level'
import { SelectIconSingleLevel } from './select/select-icon-single-level'
import { SelectMinimalEmpty } from './select/select-minimal-empty'
import { SelectMinimalMultiLevel } from './select/select-minimal-multi-level'
import { SelectMinimalSingleLevel } from './select/select-minimal-single-level'
import { SelectNormalEmpty } from './select/select-normal-empty'
import { SelectNormalMultiLevel } from './select/select-normal-multi-level'
import { SelectNormalSingleLevel } from './select/select-normal-single-level'
import { SelectSvgEmpty } from './select/select-svg-empty'
import { SelectSvgMultiLevel } from './select/select-svg-multi-level'
import { SelectSvgSingleLevel } from './select/select-svg-single-level'

export type { DataNode, RenderStructNodeItem, RenderStructNodeItemProps } from './picks/multi-level/type'
export { existPrevDataItem, isHiddenDataNode } from './picks/multi-level/utils'
export type { SelectArrowEmptyProps, SelectArrowEmptyRef } from './select/select-arrow-empty'
export type { SelectArrowMultiLevelProps } from './select/select-arrow-multi-level'
export type { SelectArrowSingleLevelProps } from './select/select-arrow-single-level'
export type { SelectDefaultCustomNode } from './select/select-default-custom-render'
export type { SelectEmptyEmptyProps, SelectEmptyEmptyRef } from './select/select-empty-empty'
export type { SelectIconEmptyProps, SelectIconEmptyRef } from './select/select-icon-empty'
export type { SelectIconMultiLevelProps } from './select/select-icon-multi-level'
export type { SelectIconSingleLevelProps, SelectIconSingleLevelRef } from './select/select-icon-single-level'
export type { SelectMinimalEmptyProps, SelectMinimalEmptyRef } from './select/select-minimal-empty'
export type { SelectMinimalMultiLevelProps } from './select/select-minimal-multi-level'
export type { SelectMinimalSingleLevelProps, SelectMinimalSingleLevelRef } from './select/select-minimal-single-level'
export type { SelectNormalEmptyProps, SelectNormalEmptyRef } from './select/select-normal-empty'
export type { SelectNormalMultiLevelProps } from './select/select-normal-multi-level'
export type { SelectNormalSingleLevelProps } from './select/select-normal-single-level'
export type { SelectSvgEmptyProps, SelectSvgEmptyRef } from './select/select-svg-empty'
export type { SelectSvgMultiLevelProps } from './select/select-svg-multi-level'
export type { SelectSvgSingleLevelProps } from './select/select-svg-single-level'
export * from './select/type'
export * from './type'

export const Select = {
    EmptyEmpty: SelectEmptyEmpty,
    SvgEmpty: SelectSvgEmpty,
    SvgMultiLevel: SelectSvgMultiLevel,
    SvgSingleLevel: SelectSvgSingleLevel,
    NormalEmpty: SelectNormalEmpty,
    NormalMultiLevel: SelectNormalMultiLevel,
    NormalSingleLevel: SelectNormalSingleLevel,
    MinimalEmpty: SelectMinimalEmpty,
    MinimalMultiLevel: SelectMinimalMultiLevel,
    MinimalSingleLevel: SelectMinimalSingleLevel,
    IconEmpty: SelectIconEmpty,
    IconMultiLevel: SelectIconMultiLevel,
    IconSingleLevel: SelectIconSingleLevel,
    ArrowEmpty: SelectArrowEmpty,
    ArrowMultiLevel: SelectArrowMultiLevel,
    ArrowSingleLevel: SelectArrowSingleLevel,
}
