import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { FC, useMemo } from 'react'
import { LayerPanelDragSectionItem } from '../render-item-drag/drag-section-item'
import { LayerPanelItemIndent } from '../render-item-indent'
import { RenderItemBackground } from '../type'
import styles from './index.module.less'
import { translation } from './render-section-item.translation'

interface LayerPanelRenderSectionItemProps {
    sectionItem: Wukong.DocumentProto.VLayerPanelSection
    prevItem: Wukong.DocumentProto.VLayerPanelItemInfo | undefined
    nextItem: Wukong.DocumentProto.VLayerPanelItemInfo | undefined
    index: number
}

function getRenderItemBackground(item: Wukong.DocumentProto.VLayerPanelSection): RenderItemBackground {
    if (item.isAncestorSelected) {
        return RenderItemBackground.AncesterSelected
    }
    return RenderItemBackground.None
}

export const LayerPanelRenderSectionItem: FC<LayerPanelRenderSectionItemProps> = ({
    sectionItem,
    prevItem,
    nextItem,
    index,
}) => {
    const background = useMemo<RenderItemBackground>(() => getRenderItemBackground(sectionItem), [sectionItem])

    const sectionNameClassName = useMemo<string>(() => {
        let className_ = styles.sectionName
        if (sectionItem.isInComponentLike) {
            className_ += ` ${styles.purple}`
        }
        return className_
    }, [sectionItem.isInComponentLike])
    return useMemo(
        () => (
            <LayerPanelDragSectionItem item={sectionItem} preItem={prevItem} nextItem={nextItem} index={index}>
                <div
                    className={classNames(styles.section, styles[background])}
                    data-testid={`layer-panel-render-item-${index}`}
                >
                    <LayerPanelItemIndent
                        indent={sectionItem.indent}
                        maskTypes={[]}
                        expandStatus={
                            Wukong.DocumentProto.VLayerPanelNodeExpandStauts.V_LAYER_PANEL_NODE_EXPAND_STAUTS_NONE
                        }
                        id={sectionItem.sectionId!}
                        renderItemBackground={background}
                    />
                    <span className={sectionNameClassName}>
                        {sectionItem.type == Wukong.DocumentProto.SectionType.SECTION_TYPE_FIXED
                            ? translation('Fixed')
                            : translation('Scrolls')}
                    </span>
                </div>
            </LayerPanelDragSectionItem>
        ),
        [background, index, nextItem, prevItem, sectionItem, sectionNameClassName]
    )
}
