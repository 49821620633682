import { Wukong } from '@wukong/bridge-proto'
import { featureSwitchManager } from '../../../../../kernel/switch/core'
import { useSelectedVariablesEditorContext } from '../selected-variables-editor-context'
import { VariableCodeSyntax } from '../variable-code-syntax'
import { VariableNameDescription } from '../variable-name-description'
import { VariableValue } from '../variable-value'
import { VariableValue as VariableValueV2 } from '../variable-value/index-v2'
import { VariablesPublishHidden } from '../variables-publish-hidden'

export function SelectedVariablesEditorDetail({
    variables,
    collection,
}: {
    variables: Wukong.DocumentProto.ILocalVariable[]
    collection: Wukong.DocumentProto.ILocalVariableSet | null
}) {
    const {
        selectedModeId,
        publishHiddenValue,
        onChangeName,
        onChangeDescription,
        onChangeColor,
        onChangeColorVar,
        onAddCodeSyntax,
        onChangeCodeSyntax,
        onRemoveCodeSyntax,
        onChangePublishHidden,
        onChangeSelectedModeId,
    } = useSelectedVariablesEditorContext()
    const isSingleVariable = variables.length === 1
    const variable = variables[0]

    if (!variable) {
        return null
    }

    return (
        <div data-testid="selected-variables-editor-detail">
            {isSingleVariable && (
                <>
                    <VariableNameDescription
                        name={variable.name}
                        description={variable.description}
                        onChangeName={(value) => onChangeName(variable, value)}
                        onChangeDescription={(value) => onChangeDescription(variable, value)}
                    />
                    {featureSwitchManager.isEnabled('float-variable') ? (
                        <VariableValueV2 variable={variable} collection={collection} />
                    ) : (
                        <VariableValue
                            variable={variable}
                            collection={collection}
                            selectedModeId={selectedModeId}
                            onChangeColor={onChangeColor}
                            onChangeColorVar={onChangeColorVar}
                            onChangeSelectedModeId={onChangeSelectedModeId}
                        />
                    )}
                    <VariableCodeSyntax
                        codeSyntax={variable.codeSyntax}
                        onAdd={onAddCodeSyntax(variable.id, variable.name)}
                        onChange={onChangeCodeSyntax(variable.id)}
                        onRemove={onRemoveCodeSyntax(variable.id)}
                    />
                </>
            )}
            {publishHiddenValue && (
                <VariablesPublishHidden value={publishHiddenValue} onChange={onChangePublishHidden} />
            )}
        </div>
    )
}
