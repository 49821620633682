import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    ButtonInThe: '创建组件，请选择一个图层，然后单击工具栏中「创建组件」按钮 。',
    LearnMore: '了解更多',
    UseLibrariesTo: '使用组件库可以快速访问其他文件中的组件。',
    'ExploreLibraries...': '查看组件库',
} as const

export const enTranslation = {
    ButtonInThe: 'Create new component: Select a layer and click the "Create component" button in the toolbar.',
    LearnMore: 'Learn more',
    UseLibrariesTo:
        'Use libraries: Add shared libraries to quickly access components in other files, or publish a library to share assets with your teams.',
    'ExploreLibraries...': 'Explore libraries...',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
