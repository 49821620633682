export type IconItemId = number
export interface IconSearchItem {
    id: IconItemId
    tags: string[]
}

const SPLIT_CHAR = ' '

export class SearchHelper {
    private dataCache = new Map<IconItemId, string>()

    constructor(data: Map<IconItemId, IconSearchItem>) {
        for (const [itemId, item] of data.entries()) {
            this.dataCache.set(itemId, item.tags.join(SPLIT_CHAR))
        }
    }

    search(word: string): IconItemId[] {
        if (word.length === 0) {
            return [...this.dataCache.keys()]
        }
        if (word.includes(SPLIT_CHAR)) {
            return []
        }
        const result: IconItemId[] = []
        for (const [itemId, tags] of this.dataCache.entries()) {
            if (tags.includes(word)) {
                result.push(itemId)
            }
        }
        return result
    }
}
