/* eslint-disable no-restricted-imports */
import { useEffect, useRef } from 'react'
import { Point, Rect } from './type'

export function getLocalScreenPosition(dom: HTMLDivElement, p: Point) {
    const rect = dom.getBoundingClientRect()
    const x = p.x - rect.left
    const y = p.y - rect.top
    return { x, y }
}

export function pointsToRect(start: Point, end: Point): Rect {
    return {
        x: Math.min(start.x, end.x),
        y: Math.min(start.y, end.y),
        width: Math.abs(start.x - end.x),
        height: Math.abs(start.y - end.y),
    }
}

/**
 *
 * 用于处理文本自动宽度的逻辑
 *
 * @param fontSize -
 * @param extraPadding -
 * @param deps -
 * @returns
 */
export function useAutoWidthTextInput(fontSize = 12, extraPadding = 18, deps: any[] = []) {
    const domRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        const dom = domRef.current
        if (!dom) return

        const mesureId = `__WK_MEASURE_${Math.random()}`
        const getOrCreateMeasureDom = () => {
            let measureDom = document.getElementById(mesureId)
            if (!measureDom) {
                measureDom = document.createElement('span')
                measureDom.id = mesureId
                measureDom.style.fontSize = `${fontSize}px`
                measureDom.style.pointerEvents = 'none'
                measureDom.style.opacity = '1'
                document.body.append(measureDom)
            }
            measureDom.innerText = dom.value
            return measureDom
        }

        const syncWidth = () => {
            const measureDom = getOrCreateMeasureDom()
            const targetWidth = measureDom.offsetWidth + extraPadding
            dom.style.width = `${targetWidth}px`
        }

        dom.addEventListener('input', syncWidth)

        return () => {
            dom.removeEventListener('input', syncWidth)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps)

    return domRef
}

export const PreviewManager = {
    disabled: false,

    intervalTime: 2000,
    previewCounter: 0,
    maxPreview: 10, // 最多只会触发 10 次预览

    disableTimeoutId: null as ReturnType<typeof setTimeout> | null,
    previewIntervalId: null as ReturnType<typeof setInterval> | null,
    preview: () => {},

    clearPreview() {
        this.preview = () => {}
        this.previewCounter = 0
        this.disabled = false
        if (this.previewIntervalId) {
            clearInterval(this.previewIntervalId)
            this.previewIntervalId = null
        }
    },
    addPreview(previewTask: () => void) {
        // 增加预览任务
        this.preview = previewTask
        this.previewCounter = 0
        this.runPreviewInterval()
    },
    disablePreview() {
        this.disabled = true
        // 一段时间后自动启用
        if (this.disableTimeoutId) {
            clearTimeout(this.disableTimeoutId)
        }
        this.disableTimeoutId = setTimeout(() => {
            this.disabled = false
        }, 300)
    },
    runPreviewInterval() {
        if (this.previewIntervalId) {
            clearInterval(this.previewIntervalId)
        }
        this.previewIntervalId = setInterval(() => {
            if (!this.disabled && this.previewCounter < this.maxPreview) {
                this.preview()
                this.previewCounter += 1
            }
        }, this.intervalTime)
    },
}
