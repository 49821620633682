import { translation } from './more-comment-settings.translation'
/* eslint-disable no-restricted-imports */
import { useCallback } from 'react'
import { DropdownIconSingleLevelProps, DropdownV2, MonoIconPanelMore16, Tooltip } from '../../../../../../ui-lib/src'

enum MoreCommentSettingsType {
    CopyLink = 'copy-link',
    SetReadSetUnread = 'set-read-set-unread',
    DeleteComment = 'delete-comment',
}

export interface MoreCommentSettingsV2Props
    extends Omit<DropdownIconSingleLevelProps, 'dataTestIds' | 'label' | 'onChange'> {
    isOwner?: boolean
    isUnread?: boolean
    onCopyLink?: () => void
    onDeleteComment?: () => void
    onSetUnread?: () => void
    onSetRead?: () => void
    dataTestIds?: {
        readItem?: string
        unReadItem?: string
        copyLinkItem?: string
        deleteItem?: string
    } & DropdownIconSingleLevelProps['dataTestIds']
}

export function MoreCommentSettingsV2(props: MoreCommentSettingsV2Props) {
    const { isOwner, isUnread, onCopyLink, onSetUnread, onSetRead, onDeleteComment, ...otherProps } = props

    const handleToggleChange = useCallback(
        (value: MoreCommentSettingsType) => {
            switch (value) {
                case MoreCommentSettingsType.CopyLink:
                    onCopyLink?.()
                    break
                case MoreCommentSettingsType.SetReadSetUnread:
                    props.isUnread ? onSetRead?.() : onSetUnread?.()
                    break
                case MoreCommentSettingsType.DeleteComment:
                    onDeleteComment?.()
                    break
            }
        },
        [onCopyLink, onDeleteComment, onSetRead, onSetUnread, props.isUnread]
    )

    return (
        <Tooltip title={translation('CommentActions')} triggerRefKey="getTriggerElement">
            <DropdownV2.IconSingleLevel label={<MonoIconPanelMore16 />} onChange={handleToggleChange} {...otherProps}>
                {isUnread ? (
                    <DropdownV2.IconSingleLevel.Option
                        value={MoreCommentSettingsType.SetReadSetUnread}
                        dataTestId={props.dataTestIds?.readItem}
                    >
                        {translation('MaskAsRead')}
                    </DropdownV2.IconSingleLevel.Option>
                ) : (
                    <DropdownV2.IconSingleLevel.Option
                        value={MoreCommentSettingsType.SetReadSetUnread}
                        dataTestId={props.dataTestIds?.unReadItem}
                    >
                        {translation('MaskAsUnread')}
                    </DropdownV2.IconSingleLevel.Option>
                )}
                <DropdownV2.IconSingleLevel.Option
                    value={MoreCommentSettingsType.CopyLink}
                    dataTestId={props.dataTestIds?.copyLinkItem}
                >
                    {translation('CopyLink')}
                </DropdownV2.IconSingleLevel.Option>
                {isOwner ? (
                    <DropdownV2.IconSingleLevel.Option
                        value={MoreCommentSettingsType.DeleteComment}
                        dataTestId={props.dataTestIds?.deleteItem}
                    >
                        {translation('Delete')}
                    </DropdownV2.IconSingleLevel.Option>
                ) : null}
            </DropdownV2.IconSingleLevel>
        </Tooltip>
    )
}
