import { getTranslationValue } from './i18n'

export const zhTranslation = {
    DeletedOn: '删除于',
    EditedOn: '修改于',
    single: '确定要将文件「{{fileName}}」移动至{{folderName}}？',
    muilt: '确定要将 {{fileCount}} 个文件移动至{{folderName}}？',
} as const

export const enTranslation = {
    DeletedOn: 'Deleted',
    EditedOn: 'Edited',
    single: 'Are you sure you want to move {{fileName}} to {{folderName}}?',
    muilt: 'Are you sure you want to move {{fileCount}} files to {{folderName}}?',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
