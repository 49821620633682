import { environment } from '../../../../environment'
import { FontVariant, FontVariantAxes, FontVariantInstance } from '../../../../main/font/interface'
import { translation } from './font-util.translation'
import type { FontFileType, FontMeta, LocalFontFileInfo } from './interface'
import { FontNames, LocalizedName, parse, Path, type Font } from './opentype/opentype'
import { ttc2ttf } from './ttc2ttf'

const getUid = (() => {
    let id = 1000
    return () => ++id
})()

export const parseTTCFontFile = async (file: File) => {
    const buffer = await file.arrayBuffer()

    return await ttc2ttf(buffer)
}

/**
 * 字体名称预览图最大宽度
 */
const FAMILY_SAMPLE_MAX_WIDTH = 184

export const parseFontFile = async (file: File): Promise<LocalFontFileInfo> => {
    const buffer = await file.arrayBuffer()
    return createLocalFontFileInfo(buffer, parseFileType(file), file)
}

export const parseFontBuffer = (fileType: FontFileType, buffer: ArrayBuffer): LocalFontFileInfo => {
    return createLocalFontFileInfo(buffer, fileType, null)
}

const createLocalFontFileInfo = (buffer: ArrayBuffer, fileType: FontFileType, file: File | null): LocalFontFileInfo => {
    const font = parse(buffer)
    const fontMeta = parseFontMeta(font)

    const familySampleBlob = checkFontRenderCompletely(font, fontMeta.localizedFamily)
        ? createFontSampleImage(font, fontMeta.localizedFamily, 12, 20, FAMILY_SAMPLE_MAX_WIDTH)
        : null
    const sampleBlob = createFontSampleImage(font, getBasicSampleContent(font), 18, 32)

    return {
        localId: getUid(),
        file: file || new File([buffer], fontMeta.localizedFamily + ' ' + fontMeta.localizedStyle),
        fileType,
        familySampleBlob,
        sampleBlob,
        fontMeta,
    }
}

/**
 * 解析字体文件类型
 * @param file
 */
const parseFileType = (file: File) => {
    return file.name.split('.').pop()?.toLocaleLowerCase() as LocalFontFileInfo['fileType']
}

// NOTE: @types/opentype.js 缺少部分字段
type PlatformFontNames = Partial<FontNames> & {
    preferredFamily?: LocalizedName
    preferredSubfamily?: LocalizedName
}
export const parseFontMeta = (font: Font): FontMeta => {
    const { family, localizedFamily, style, localizedStyle } = parseFontName(font)

    const variationInfo = parseFontVariant(font)
    return {
        version: parseFontVersion(getFontInfo(font, ['version'], ['en', 'zh'])),
        postScriptName: getFontInfo(font, ['postScriptName'], ['en', 'zh']),

        family,
        localizedFamily,
        style,
        localizedStyle,
        italic: font.tables.post.italicAngle !== 0 || (font.tables.os2.fsSelection & 0x01) == 1,
        width: font.tables.os2.usWidthClass,
        weight: font.tables.os2.usWeightClass,
        variationInfo,
    }
}

const parseFontName = (font: Font) => {
    if (environment.isAbroad) {
        const family = getFontInfo(font, ['preferredFamily', 'fontFamily'], ['en'])
        const style = getFontInfo(font, ['preferredSubfamily', 'fontSubfamily'], ['en'])
        return { family, localizedFamily: family, style, localizedStyle: style } as const
    } else {
        const family = getFontInfo(font, ['preferredFamily', 'fontFamily'], ['en'])
        const localizedFamily = getFontInfo(font, ['preferredFamily', 'fontFamily'], ['zh'])
        const style = getFontInfo(font, ['preferredSubfamily', 'fontSubfamily'], ['en'])
        const localizedStyle = getFontInfo(font, ['preferredSubfamily', 'fontSubfamily'], ['zh'])

        return {
            family: family || localizedFamily,
            localizedFamily: localizedFamily || family,
            style: style || localizedStyle,
            localizedStyle: localizedStyle || style,
        } as const
    }
}

const getFontInfo = (font: Font, attrs: (keyof PlatformFontNames)[], languages: string[]) => {
    const platforms: PlatformFontNames[] = [font.names.windows || {}, font.names.macintosh || {}]

    for (const platform of platforms) {
        for (const attr of attrs) {
            for (const language of languages) {
                const value = platform[attr]?.[language]
                if (value) return value
            }
        }
    }

    return ''
}

/**
 * 解析字体文件version
 * 目前发现存在以下格式:
 * 1. Version 2.004;hotconv 1.0.118;makeotfexe 2.5.65603
 * 1. 17.0d1e2
 * 1. 3.3;20b39288a
 * @param version
 */
const parseFontVersion = (version: string) => {
    const first = (version || '').split(';')[0]
    if (first) {
        return first.replace('Version ', '')
    }
    return version
}

export const CN_SAMPLE_CONTENT = translation('Rag123')
export const EN_SAMPLE_CONTENT = 'Rag 123'
export const getBasicSampleContent = (font: Font): string => {
    return checkFontRenderCompletely(font, CN_SAMPLE_CONTENT) ? CN_SAMPLE_CONTENT : EN_SAMPLE_CONTENT
}

const getPathWithEllipsis = (
    font: Font,
    content: string,
    baseline: number,
    fontSize: number,
    width: number,
    isEllipse = false
): Path => {
    const path = font.getPath(content + (isEllipse ? '...' : ''), 0, baseline, fontSize)
    const { x2 } = path.getBoundingBox()

    // NOTE: 字体名称长度一般较短, 直接简单的通过每次移除一个字符进行测算
    if (width > 0 && x2 > width) {
        return getPathWithEllipsis(font, content.substring(0, content.length - 1), baseline, fontSize, width, true)
    }
    return path
}

const checkFontRenderCompletely = (font: Font, content: string) => {
    return content.split('').every((char) => {
        const glyph = font.charToGlyph(char)
        return glyph?.unicode !== undefined && glyph.unicode !== 0 && glyph.name !== '.notdef'
    })
}

/**
 * 生成字体预览图 (svg格式)
 * @param font
 * @param content
 * @param svgHeight
 * @param svgWidth 宽度 <=0 表示不限制宽度
 * @returns
 */
const createFontSampleImage = (
    font: Font,
    content: string,
    fontSize: number,
    svgHeight: number,
    svgWidth = 0
): Blob | null => {
    const height = ((font.ascender - font.descender) * fontSize) / font.unitsPerEm
    const baseline = (font.ascender * fontSize) / font.unitsPerEm + (svgHeight - height) / 2
    const path = getPathWithEllipsis(font, content, baseline, fontSize, svgWidth)
    const { x2 } = path.getBoundingBox()
    if (x2 <= 0) {
        return null
    }
    const pathSvg = path.toSVG(2)
    const svgXML = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="${x2}" height="${svgHeight}">${pathSvg}</svg>`

    return new Blob([svgXML], { type: 'image/svg+xml' })
}

/**
 * 解析可变字体信息
 */

export enum StandardAxisTag2Name {
    'wght' = 'Weight',
    'wdth' = 'Width',
    'ital' = 'Italic',
    'slnt' = 'Slant',
    'opsz' = 'Optical Size',
}

export const parseFontVariant = (font: Font): FontVariant | null => {
    const fvar = font.tables.fvar
    if (!fvar) {
        return null
    }

    const getAxisName = (axis: any) => {
        if (['wght', 'wdth', 'ital', 'slnt', 'opsz'].includes(axis.tag)) {
            return StandardAxisTag2Name[axis.tag as keyof typeof StandardAxisTag2Name]
        } else {
            return axis.tag
        }
    }

    const axes: FontVariantAxes[] = fvar.axes.map((axis: any) => {
        return {
            axisTag: axis.tag,
            name: getAxisName(axis), // 可变轴只获取英文名
            minValue: axis.minValue,
            maxValue: axis.maxValue,
            defaultValue: axis.defaultValue,
            hidden: !!(axis.flags & 1),
        } as FontVariantAxes
    })

    const instances: FontVariantInstance[] = fvar.instances.map((instance: any) => {
        return {
            name: instance.name.en,
            localizedName: environment.isAbroad ? instance.name.en : instance.name.zh ?? instance.name.en,
            axes: instance.coordinates,
        }
    })

    return { axes, instances }
}
