import { ClogDomain, Environment, HttpPrefixKey } from '../types'

export const productionEnvironment: Environment = {
    isAbroad: false,
    isProduction: true,
    googleClientId: 'none',
    isDev: false,
    host: 'https://motiff.cn',
    wssPrefix: 'wss://motiff-synergy.motiff.cn/synergy',
    ossCdnPath: 'https://motiff.fbcontent.cn/',
    publicResourcePrefix: 'app/public/resource',
    sentryDsn: 'https://b6da2edcfaae4e5d9ed27fb7f6e7fd9c@sentry-saas.motiff.cn/6733320',
    clogConfig: {
        site: 'yuanfudao',
        domain: ClogDomain.Com,
        queryUrl: 'https://octopus.zhenguanyu.com/#/logs/search?env=online',
    },
    featureSwitchEnv: 'production',
    httpPrefixes: {
        [HttpPrefixKey.COMMON_API]: 'https://api.motiff.cn/wukong-api/api',
        [HttpPrefixKey.NOTIFY_API]: 'https://api.motiff.cn/wukong-notify/api',
        [HttpPrefixKey.SYNERGY_CONNECTOR_API]: 'https://api.motiff.cn/motiff-synergy-connector',
        [HttpPrefixKey.SYNERGY_ADMIN_API]: 'https://api.motiff.cn/motiff-coordinator/admin',
        [HttpPrefixKey.ADMIN_API]: 'https://api-inner.motiff.cn/wukong-api-admin/api/',
        [HttpPrefixKey.INNER_API]: 'https://api-inner.motiff.cn/wukong-api/api',
        [HttpPrefixKey.BOLT_API]: 'https://bolt.yuanfudao.com/bolt-pay-gateway/api',
        [HttpPrefixKey.AI_GEN_API]: 'https://motiff-ai-gen.motiff.cn/api',
    },
    availableHostsForEmailLoginApi: ['motiff.cn', 'kanyun.motiff.cn', 'beta.motiff.cn', 'staging.motiff.cn'],
    uiPrefixCls: 'wukong-ui',
    figmaClientId: 'i4IKWddXv3PbTL9bxDd4NT',
    figmaCallbackUrl: 'https://motiff.cn/figma-oauth',
    mirrorBasePath: 'mirror',
    notifyWssPrefix: 'wss://motiff-notify.motiff.cn/notify-connector',
    mirrorWssPrefix: 'wss://api.motiff.cn/motiff-preview-connector/preview',
    fontPluginDaemonHost: 'https://daemon.motiff.com',
    fontPluginLocalHost: 'http://127.0.0.1',
    onboardingHelpDocUrl: {
        frame: 'https://motiff.cn/help/docs/articles/88036382480896',
        vector: 'https://motiff.cn/help/docs/articles/87711961443072',
        comment: 'https://motiff.cn/help/docs/articles/87712087272192',
        share: 'https://motiff.cn/help/docs/sections/87711902722816',
        coator: 'https://motiff.cn/help/docs/articles/99569364184328',
        library: 'https://motiff.cn/help/docs/articles/100783506244352',
        prototype: 'https://motiff.cn/help/docs/sections/232640631466294',
        device: 'https://motiff.cn/help/docs/sections/232662316033281',
        devModeStart: 'https://motiff.cn/help/docs/sections/223860030368261#0-设置代码',
        devmodeInspect: 'https://motiff.cn/help/docs/sections/223860030368260#4-查看标注',
        devmodeExport: 'https://motiff.cn/help/docs/sections/223860030368260#6-导出',
        variable: 'https://motiff.cn/help/docs/articles/305390366273793',
    },
    helpDocsUrl: {
        home: 'https://motiff.cn/help/docs',
        color: 'https://motiff.cn/help/docs/sections/114851546582028',
        image: 'https://motiff.cn/help/docs/articles/87711974025984',
        component: 'https://motiff.cn/help/docs/articles/87712032746240',
        webgl: 'https://motiff.cn/help/others/99991877417216',
        aiCredit: 'https://motiff.cn/help/docs/sections/232982865729549',
        quota: 'https://motiff.cn/help/others/266640468297220',
        ssoPath: '/help/docs/sections/267344884918528',
        stateManagement: '',
        motiffAPI: 'https://motiff.cn/help/others/269261270998789',
        variable: 'https://motiff.cn/help/docs/articles/305390366273796',
        organizationGuest: 'https://motiff.cn/help/docs/sections/151470896999680',
    },
}
