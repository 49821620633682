import { Wukong } from '@wukong/bridge-proto'
import { useEffect, useState } from 'react'
import { EffectStyleIconType } from '../../../../kernel/interface/component-style'
import { useAppContext } from '../../../../main/app-context'
import { EffectStyleIcon } from '../../design/effects/effect-style/effect-style-icon'
import style from './candidate-component-img.module.less'
import { EllipsisTooltipWrapper } from './ellipsis-tool-tip-wrapper'

export function CandidateComponentImg({ id, isStyle }: { id: string; isStyle?: boolean }) {
    const aiService = useAppContext().aiService
    const [imageBlob, setImageBlob] = useState<string | null>(null)
    const [showGrid, setShowGrid] = useState(false)
    const [effectStyleIconType, setEffectStyleIconType] = useState<Wukong.DocumentProto.EffectStyleIconType>()
    const [name, setName] = useState<string>('')
    useEffect(() => {
        const res = aiService.generateCandidateComponentThumbnail(id)
        setImageBlob(res.buffer)
        setShowGrid(res.showGrid)
        setEffectStyleIconType(res.effectStyleIconType)
        setName(res.name)
    }, [id, aiService, setImageBlob, setShowGrid, setEffectStyleIconType, setName])
    return (
        <div className={style.container}>
            <div
                className={`${style.imgWrapper} ${isStyle ? style.isStyle : ''} ${showGrid ? style.gridBg : ''}`}
                draggable={false}
            >
                {effectStyleIconType ? (
                    <EffectStyleIcon iconType={effectStyleIconType as unknown as EffectStyleIconType} />
                ) : (
                    <img src={`data:image/png;base64, ${imageBlob}`} draggable={false} />
                )}
            </div>
            {name && <EllipsisTooltipWrapper text={name} />}
        </div>
    )
}
