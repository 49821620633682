import { translation } from './batch-export-modal.translation'
/* eslint-disable no-restricted-imports */
import { ZoomToFitNodesAtMaxScaleByIdsCommand } from '@wukong/bridge-proto'
import { useCallback } from 'react'
import { useWindowSize } from 'react-use'
import { Checkbox, DraggablePopupV2, Scrollbar, WKButton } from '../../../../../../ui-lib/src'
import { useCommand } from '../../../../main/app-context'
import { BatchExportItem, BatchExportItemContent, BatchExportItemProps } from './batch-export-item'
import style from './batch-export-modal.module.less'
import { useBatchExport } from './hooks/use-batch-export'

const BatchExportItemContainer = ({
    contents,
    ...rest
}: { contents: BatchExportItemContent[] } & Omit<BatchExportItemProps, 'config' | 'onClick'>) => {
    const command = useCommand()
    const { height } = useWindowSize()

    const onClick = useCallback(
        (id: string) => {
            command.DEPRECATED_invokeBridge(ZoomToFitNodesAtMaxScaleByIdsCommand, { nodeIds: [id] })
        },
        [command]
    )

    if (contents.length > 0) {
        return (
            <Scrollbar autoHeightMax={height - 170} autoHeight>
                <div className={style['batch-export-modal-configs-container']}>
                    {contents.map((c) => (
                        <BatchExportItem key={c.fileName} config={c} {...rest} onClick={onClick} />
                    ))}
                </div>
            </Scrollbar>
        )
    } else {
        return <article className="text-gray wk-text-12 py-6 px-4">{translation('NoSelected')}</article>
    }
}

interface BatchExportFooterProps {
    checked: boolean
    onChange: ((checked: boolean) => void) | ((checked: boolean) => Promise<void>)
    count: number
    quanlity: number
    indeterminate: boolean
    exportChecked: () => void
}

const BatchExportFooter = (props: BatchExportFooterProps) => {
    const selectedArticleTextclass = props.quanlity === 0 ? 'text-gray-light' : ''
    return (
        <footer className={style['batch-export-modal-footer']}>
            <Checkbox
                checked={props.checked}
                onChange={props.onChange}
                disabled={props.quanlity === 0}
                indeterminate={props.indeterminate}
            />
            <span className={selectedArticleTextclass}>
                {translation('Selected', { numerator: props.count, denominator: props.quanlity })}
            </span>
            <WKButton
                disabled={props.quanlity === 0 || props.count === 0}
                onClick={props.exportChecked}
                type="primary"
                dataTestId={'batch-export-btn'}
            >
                {translation('Export')}
            </WKButton>
        </footer>
    )
}

export interface BatchExportModalProps {}

const BatchExportModal = (_props: BatchExportModalProps) => {
    const {
        isOpen,
        close,
        position,
        quanlity,
        count,
        allChecked,
        indeterminate,
        exportChecked,
        selectAllOrNone,
        contents,
        select,
        selected,
        unSelect,
        isSelected,
    } = useBatchExport()

    return (
        <DraggablePopupV2
            visible={isOpen}
            onCancel={close}
            position={position}
            style={{ zIndex: 11 }}
            header={
                <div className={`${style['batch-export-modal-title']} wk-text-12 wk-font-semibold`}>
                    {translation('Export_synonyms1')}
                </div>
            }
            styleType="editor"
            testId="batch-export-modal"
            bodyClassName="p-0"
            footer={
                <BatchExportFooter
                    quanlity={quanlity}
                    count={count}
                    checked={allChecked}
                    indeterminate={indeterminate}
                    exportChecked={() => {
                        exportChecked()
                    }}
                    onChange={(flag) => selectAllOrNone(flag ? 'all' : 'none')}
                />
            }
            positionRightBase
            width={280}
        >
            <BatchExportItemContainer
                contents={contents}
                select={select}
                selected={selected}
                unSelect={unSelect}
                isSelected={isSelected}
            />
        </DraggablePopupV2>
    )
}
export default BatchExportModal
