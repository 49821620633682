import { translation } from './style-list-item-v2.translation'
/* eslint-disable no-restricted-imports */
import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { IconAdjust, Tooltip, isRightClick } from '../../../../../../../ui-lib/src'
import { IconButton } from '../../../atom/button/icon-button'
import { CommonStyleInfo } from '../get-style-nodes-info-map'
import { StyleToolTip } from '../style-tooltip/style-tooltip'
import style from './style-list-item-v2.module.less'

export interface StyleListItemV2Props<T> {
    item: CommonStyleInfo & T
    openStyleId?: string
    className?: string
    disabledOnClick?: boolean
    children?: React.ReactNode
    dataTestId?: string
    onSelectItem?: (item: CommonStyleInfo & T) => void
    onClickHoverIcon?: (item: CommonStyleInfo & T, containerDomRect: DOMRect) => void
    onContextMenu?: (item: CommonStyleInfo & T, e: any) => void
    isRightClickStyle?: boolean
    isReadonly?: boolean
    isSelected?: boolean
    isPreselect?: boolean
    onMouseMove?: () => void
    onMouseLeave?: () => void
    index: number
    recordEnterCallback: (index: number, callback: () => void) => void
}
export function StyleListItemV2<T>(props: StyleListItemV2Props<T>) {
    const {
        item,
        openStyleId,
        className,
        disabledOnClick,
        onSelectItem,
        onClickHoverIcon,
        onContextMenu,
        dataTestId,
        isRightClickStyle,
        isReadonly = false,
        isSelected = false,
        isPreselect = false,
        onMouseMove,
        onMouseLeave,
    } = props
    const containerRef = useRef<HTMLDivElement>(null)

    const _onMouseDown: React.MouseEventHandler<HTMLDivElement> = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (disabledOnClick || isRightClick(e)) {
                return
            }
            onSelectItem?.(item)
        },
        [item, onSelectItem, disabledOnClick]
    )

    const _onClickHoverIcon: React.MouseEventHandler<HTMLDivElement> = useCallback(() => {
        if (!containerRef.current) {
            return
        }
        const containerDomRect = containerRef.current.getBoundingClientRect()
        onClickHoverIcon?.(item, containerDomRect)
    }, [item, onClickHoverIcon])

    const _onContextMenu: React.MouseEventHandler<HTMLDivElement> = useCallback(
        (e) => {
            e.stopPropagation()
            e.preventDefault()
            onContextMenu?.(item, e)
        },
        [item, onContextMenu]
    )

    const isOpenEditing = useMemo(() => {
        return item.id === openStyleId
    }, [item.id, openStyleId])

    useEffect(() => {
        props.recordEnterCallback(props.index, () => !disabledOnClick && onSelectItem?.(item))
    }, [disabledOnClick, item, onSelectItem, props])

    return (
        <div
            ref={containerRef}
            onContextMenu={_onContextMenu}
            data-testid={dataTestId}
            onMouseDown={_onMouseDown}
            className={classNames(style.listItem, [className], {
                [style['right-click-menu-visible']]: isRightClickStyle,
                [style.selectItem]: isSelected,
                [style.preselect]: isPreselect,
            })}
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
        >
            <>
                <StyleToolTip
                    titleText={item.name}
                    contentText={item.description}
                    triggerRect={() => containerRef.current!.getBoundingClientRect()}
                >
                    <div className={style.left} data-testid={item.id}>
                        {props.children}
                    </div>
                </StyleToolTip>
                {onClickHoverIcon && (
                    <div className={classNames(style.right, { [style.showRight]: isOpenEditing })}>
                        <Tooltip title={isReadonly ? translation('ViewStyle') : translation('EditStyle')}>
                            <IconButton
                                icon={<IconAdjust />}
                                selected={false}
                                className={classNames(style.adjust, { [style.selected]: isOpenEditing })}
                                onClick={_onClickHoverIcon}
                                dataTestId="style-list-item-adjust-button"
                            />
                        </Tooltip>
                    </div>
                )}
            </>
        </div>
    )
}
