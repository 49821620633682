import { useId, useState } from 'react'

export interface RadioGroupStateProps {
    name?: string
    defaultValue?: string
    value?: string
    onValueChange?: (value: string) => void
}

export const useRadioGroupState = (props: RadioGroupStateProps) => {
    const { name, defaultValue, value, onValueChange } = props
    const id = useId()

    const [editingValue, setEditingValue] = useState(defaultValue)

    const isControlled = value !== undefined
    const computedValue = isControlled ? value : editingValue

    const onRadioItemClick = (radioValue: string) => {
        if (!isControlled) {
            setEditingValue(radioValue)
            onValueChange?.(radioValue)
        } else {
            onValueChange?.(radioValue)
        }
    }

    return {
        name: name ?? `radio-group-name-${id}`,
        computedValue,
        onRadioItemClick,
    } as const
}
