import { useEffect, useState } from 'react'
import { MonoIconCommonPicError48 } from '../../../icons-v2'

export interface WKImageProps {
    src: string
    width?: React.CSSProperties['width']
    height?: React.CSSProperties['height']
    objectFit: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
    backgroundColor?: string
    backgroundMosaic?: 'sm' | 'lg'
    fallback?: { type: 'color'; value?: string } | { type: 'icon'; label: string }
}

const styles = {
    objectFit: {
        fill: 'object-fill',
        contain: 'object-contain',
        cover: 'object-cover',
        none: 'object-none',
        'scale-down': 'object-scale-down',
    },
}

const mosaicBackgroundStyle = {
    sm: {
        backgroundColor: 'var(--wk-white)',
        backgroundImage:
            'linear-gradient(135deg, #e6e6e6 25%, transparent 0, transparent 75%, #e6e6e6 0), linear-gradient(135deg, #e6e6e6 25%, transparent 0, transparent 75%, #e6e6e6 0)',
        backgroundPosition: '0 0, 3px 3px',
        backgroundSize: '6px 6px',
    },
    lg: {
        backgroundColor: 'var(--wk-white)',
        backgroundImage:
            'linear-gradient(135deg, #e6e6e6 25%, transparent 0, transparent 75%, #e6e6e6 0), linear-gradient(135deg, #e6e6e6 25%, transparent 0, transparent 75%, #e6e6e6 0)',
        backgroundPosition: '0 0, 8px 8px',
        backgroundSize: '16px 16px',
    },
}

export const WKImage = (props: WKImageProps) => {
    const { src, width, height, objectFit, backgroundColor = '', backgroundMosaic = '', fallback } = props
    const [error, setError] = useState(false)

    const handleError = () => {
        setError(true)
    }

    useEffect(() => {
        setError(false)
    }, [src])

    if (error) {
        if (!fallback) {
            return null
        }

        switch (fallback.type) {
            case 'color':
                return (
                    <div
                        className="inline-flex bg-$wk-gray-3"
                        style={{ backgroundColor: fallback.value, width: width, height }}
                    ></div>
                )
            case 'icon':
                return (
                    <div
                        className="inline-flex flex-col items-center justify-center gap-4px wk-font-regular wk-text-14 color-$wk-v2-label-color-gray-8 bg-$wk-gray-3"
                        style={{ width, height }}
                    >
                        <MonoIconCommonPicError48 />
                        <div>{fallback.label}</div>
                    </div>
                )
        }
    } else {
        return (
            <img
                key={src}
                className={styles.objectFit[objectFit]}
                style={{ backgroundColor, ...(backgroundMosaic ? mosaicBackgroundStyle[backgroundMosaic] : {}) }}
                src={src}
                width={width}
                height={height}
                onError={handleError}
            />
        )
    }
}
