import {
    LayerPanelCancelEditingWasmCall,
    LayerPanelEnterEditingCommandWasmCall,
    LayerPanelRenameCommandWasmCall,
    LayerPanelShiftTabRenameCommand,
    LayerPanelTabRenameCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { FC, useCallback, useMemo } from 'react'
import { CommitType } from '../../../../document/command/commit-type'
import { NodeId } from '../../../../document/node/node'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { isValidKeyValueString } from '../../design/component/utils/parse-component-set'
import { DoubleClickInput } from '../../page-panel/components/double-click-input'
import styles from './index.module.less'
import VLayerPanelVisibleStatus = Wukong.DocumentProto.VLayerPanelVisibleStatus

interface LayerPanelRenderItemNameProps {
    id: NodeId
    name: string
    isComponentInsideComponentSet: boolean
    isEditing: boolean
    isFontBold: boolean
    isPurple: boolean
    visibleStatus: VLayerPanelVisibleStatus
    layerUsingModes: Wukong.DocumentProto.IVariableLayerModeStateVector
}

export const LayerPanelRenderItemName: FC<LayerPanelRenderItemNameProps> = ({
    id,
    name,
    isComponentInsideComponentSet,
    isEditing,
    isFontBold,
    isPurple,
    visibleStatus,
    layerUsingModes,
}) => {
    const command = useCommand()

    const docReadonly = useViewState('docReadonly')

    const handleRename = useCallback(
        (value: string) => {
            if (value === name || docReadonly) {
                command.invokeBridge(CommitType.Noop, LayerPanelCancelEditingWasmCall, { value: id })
            } else {
                command.invokeBridge(CommitType.CommitUndo, LayerPanelRenameCommandWasmCall, {
                    nodeId: id,
                    name: value,
                })
            }
        },
        [command, id, name, docReadonly]
    )

    const handleEnterEditing = useCallback(() => {
        if (docReadonly) {
            return
        }
        command.invokeBridge(CommitType.Noop, LayerPanelEnterEditingCommandWasmCall, { value: id })
    }, [command, id, docReadonly])

    // Tab 保存名称
    const handleKeyDown = useCallback(
        (event: KeyboardEvent, value: string) => {
            if (docReadonly) {
                return
            }
            if (event.key === 'Tab') {
                event.preventDefault()
                event.stopPropagation()
                if (event.shiftKey) {
                    command.invokeBridge(CommitType.CommitUndo, LayerPanelShiftTabRenameCommand, {
                        nodeId: id,
                        name: value,
                    })
                } else {
                    command.invokeBridge(CommitType.CommitUndo, LayerPanelTabRenameCommand, {
                        nodeId: id,
                        name: value,
                    })
                }
            }
        },
        [command, id, docReadonly]
    )

    // 如果是组件集包裹的组件，需要从 a=b 的命名中摘出 b
    const displayName = useMemo(() => {
        if (isComponentInsideComponentSet && isValidKeyValueString(name)) {
            return name
                .split(',')
                .map((value) => value.split('=')[1])
                .join(',')
        }
        return name
    }, [name, isComponentInsideComponentSet])

    const inputClassName = useMemo<string | undefined>(() => {
        if (isEditing) {
            return isFontBold ? styles.strong : undefined
        }
        if (
            visibleStatus === VLayerPanelVisibleStatus.V_LAYER_PANEL_VISIBLE_STATUS_ANCESTOR_INVISIBLE ||
            visibleStatus === VLayerPanelVisibleStatus.V_LAYER_PANEL_VISIBLE_STATUS_INVISIBLE ||
            visibleStatus === VLayerPanelVisibleStatus.V_LAYER_PANEL_VISIBLE_STATUS_INVISIBLE_FOR_DOC_READ_ONLY
        ) {
            return isPurple ? styles.purpleShallow : styles.shallow
        }
        if (isFontBold) {
            return styles.strong
        }
        if (isPurple) {
            return styles.purple
        }
    }, [isPurple, isFontBold, visibleStatus, isEditing])

    return useMemo(
        () => (
            <div
                data-testid="layer-panel-item-name"
                className={styles['render-item-name']}
                onDoubleClick={handleEnterEditing}
            >
                <DoubleClickInput
                    displayName={displayName}
                    value={name}
                    isEditing={isEditing}
                    onChange={handleRename}
                    handleKeyDown={handleKeyDown}
                    className={inputClassName}
                    isPurple={isPurple}
                    visibleStatus={visibleStatus}
                    layerUsingModes={layerUsingModes}
                />
            </div>
        ),
        [
            displayName,
            handleEnterEditing,
            handleKeyDown,
            handleRename,
            inputClassName,
            isEditing,
            isPurple,
            layerUsingModes,
            name,
            visibleStatus,
        ]
    )
}
