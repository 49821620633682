import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Properties: '属性',
    Component: '组件',
} as const

export const enTranslation = {
    Properties: 'Properties',
    Component: 'Component',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
