import { RouteToken, domLocation } from '../../../../../util/src'
import { ResourceType } from '../../../kernel/interface/type'
import { GetContractSettlementV2 } from '../../../kernel/request/contract'
import { SpaceNotifyFlow } from './notify-flow'
import { useSpaceStore } from './space-state'
import { SpaceStoreType, getSpaceStore, setSpaceStore, type TeamSettlementStore } from './types'

// 其他团队-专业版-账单信息
export const generateTeamSettlementState = (set: setSpaceStore, get: getSpaceStore): TeamSettlementStore => {
    return {
        data: {},
        loading: true,
        _fetchData: async () => {
            const orgId = get().organizationStore.organization.id
            const teamId = get().teamPageStore.teamId

            if (!teamId) {
                return
            }

            if (orgId !== '-1') {
                return set((state) => {
                    state.teamSettlementStore.data = {}
                })
            }

            const contractSettlement = await new GetContractSettlementV2(teamId, ResourceType.Team)
                .start()
                .catch(() => undefined)

            set((state) => {
                state.teamSettlementStore.data[teamId] = contractSettlement
            })
        },
        fetchData: async () => {
            set((state) => {
                state.teamSettlementStore.loading = true
            })
            await get().teamSettlementStore._fetchData()
            set((state) => {
                state.teamSettlementStore.loading = false
            })
        },
    }
}

const isInTeamSettlementPage = () =>
    !!domLocation().pathname.match(
        new RegExp(`${RouteToken.OrganizationWithoutOrgId}/${RouteToken.Team}/\\w+/settlement`)
    )
export const syncTeamSettlementState = (store: SpaceStoreType, notifyFlow: SpaceNotifyFlow) => {
    notifyFlow.addSeatTypeOrSeatApplicationRelationChangeCallback((msg) => {
        if (msg.resourceId == useSpaceStore.getState().teamPageStore.teamId && isInTeamSettlementPage()) {
            store.getState().teamSettlementStore._fetchData()
        }
    })
}
