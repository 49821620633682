import {
    ClickOnCommentCommand,
    ClickOnDraftCommentCommand,
    DispatchMouseEventCommand,
    MouseEnterCommentCommand,
    MouseLeaveCommentCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import { CommentId } from '../../../../kernel/interface/comment'
import {
    CanvasMouseEventTypeMap,
    translateClientRelativePositionToTargetOffsetPosition,
} from '../../../../main/canvas/canvas-event-handler'

export interface CommentMouseEventParam {
    type: 'mouseenter' | 'mouseleave' | 'mouseclick' | 'mousemove' | 'mouseup'
    commentId?: CommentId
}

function resolveMouseEventParam(e: MouseEvent, canvas: HTMLCanvasElement) {
    const position = translateClientRelativePositionToTargetOffsetPosition(e, canvas)
    const param: Wukong.DocumentProto.IArg_dispatchMouseEvent = {
        clientX: position.x,
        clientY: position.y,
        movementX: e.movementX,
        movementY: e.movementY,
        altKey: e.altKey,
        ctrlKey: e.ctrlKey,
        metaKey: e.metaKey,
        shiftKey: e.shiftKey,
    }
    return param
}

export function handleCommentMouseEvent(
    e: MouseEvent,
    param: CommentMouseEventParam,
    commandInvoker: CommandInvoker,
    canvas: HTMLCanvasElement
) {
    const event = resolveMouseEventParam(e, canvas)
    switch (param.type) {
        case 'mouseenter': {
            if (param.commentId) {
                commandInvoker.DEPRECATED_invokeBridge(MouseEnterCommentCommand, { value: `${param.commentId}` })
            }
            break
        }
        case 'mouseleave': {
            if (param.commentId) {
                commandInvoker.DEPRECATED_invokeBridge(MouseLeaveCommentCommand)
            }
            break
        }
        case 'mouseclick': {
            if (param.commentId) {
                commandInvoker.DEPRECATED_invokeBridge(ClickOnCommentCommand, { value: `${param.commentId}` })
            } else {
                commandInvoker.DEPRECATED_invokeBridge(ClickOnDraftCommentCommand)
            }
            break
        }
        case 'mousemove': {
            commandInvoker.DEPRECATED_invokeBridge(DispatchMouseEventCommand, {
                type: CanvasMouseEventTypeMap.mousemove,
                ...event,
            })
            break
        }
        case 'mouseup': {
            commandInvoker.DEPRECATED_invokeBridge(DispatchMouseEventCommand, {
                type: CanvasMouseEventTypeMap.mouseup,
                ...event,
            })
            break
        }
        default:
            break
    }
}

export interface CommentMovedParam {
    type: Wukong.DocumentProto.MovingTargetType
    commentId?: CommentId
    position: Required<Wukong.DocumentProto.ITranslateWorldPosition>
}
