export namespace Complexity {
    /**
     * 数字前缀是为了能进行比较
     */
    export enum Type {
        C = '0: o(1)',
        N = '1: o(n)',
        NLOGN = '2: o(nlogn)',
        N2 = '3: o(n^2)',
        N3 = '4: o(n^3)',
    }

    const MapFns: Array<[Type, (_: number) => number]> = [
        [Type.C, (_) => 1],
        [Type.N, (x) => x],
        [Type.NLOGN, (x) => Math.log10(x)],
        [Type.N2, (x) => x * x],
        [Type.N3, (x) => x * x * x],
    ]

    type Seq = [number, number][]

    /**
     *  检测时间序列复杂度
     * @param arr [(数量 x, 耗时 y)]
     * @return 复杂度类型
     */
    export function predict(arr: Seq) {
        if (arr.length === 0) return Type.C

        let retType = Type.C
        let minError = Infinity

        for (const [name, fn] of MapFns) {
            const predY: number[] = []
            const Y: number[] = []

            for (const [x, y] of arr) {
                predY.push(fn(x))
                Y.push(y)
            }

            // 求解 y = a * predY 中的常数系数
            const a = lsq(Y, predY)

            // 计算均方误差
            let error = 0
            for (let i = 0; i < predY.length; ++i) {
                const yp = predY[i] * a
                const d = Y[i] - yp
                error += d * d
            }

            error /= Y.length

            if (error < minError) {
                minError = error
                retType = name
            }
        }

        return retType
    }

    /**
     * 使用最小二乘法求解 y = a * predY 的参数的解析解
     * @link https://en.wikipedia.org/wiki/Least_squares#Example
     *
     * @return std::pair<double, double>
     */
    export function lsq(y: number[], predY: number[]) {
        let yp_y_sum = 0,
            yp_sq_sum = 0

        for (let i = 0; i < y.length; ++i) {
            yp_y_sum += predY[i] * y[i]
            yp_sq_sum += predY[i] * predY[i]
        }

        return yp_y_sum / yp_sq_sum
    }

    export class Timer {
        /**
         * 开始 timer
         * @param count sample count
         */
        public start(count: number) {
            this.times.push([count, performance.now()])
        }

        /**
         * 结束 timer
         * @param shouldNorm 如果是 true，则会对每对数据 (count, t) 执行 t = t / count
         */
        public end(shouldNorm = false) {
            const n = this.times.length
            if (n > 0) {
                const count = shouldNorm ? this.times[n - 1][0] : 1
                this.times[n - 1][1] = performance.now() - this.times[n - 1][1]
                this.times[n - 1][1] /= count
            }
        }

        public push(x: number, y: number) {
            this.times.push([x, y])
        }

        get result() {
            return this.times
        }

        private times: [number, number][] = []
    }

    export type RangeType = '+' | '*'

    export function range(start = 0, end = 1, step = 10, stepType: RangeType = '*') {
        const ret = []
        for (let i = start; i <= end; i = stepType === '*' ? step * i : step + i) {
            ret.push(i)
        }

        return ret
    }
}
