import { useMemo } from 'react'
import { WKButton } from '../../../../../../../../ui-lib/src'
import { useViewState } from '../../../../../../view-state-bridge'
import { useFontManagerService } from '../../../../../context/document-context'
import { useFontSelectContext } from '../font-select-context'
import { FontSelect } from '../font-select/font-select'
import styles from './font-global-tab-item.module.less'
import { translation } from './font-global-tab-item.translation'

export function FontGlobalTabItem({ selectMaxHeight }: { selectMaxHeight: number }) {
    const fontManagerService = useFontManagerService()
    const fonts = fontManagerService.states.use.allFontInfosState()
    const { versionUrl, showReminder, versionDesc, pluginStatus } = fontManagerService.states.use.pluginDetails()
    const textSearch = useViewState('textSearch', { value: '', id: '' })
    const optionUpgrade = pluginStatus === 'optionUpdated'

    const { searchStringBelongTo } = useFontSelectContext()
    const textSearchString = useMemo(() => {
        return searchStringBelongTo === textSearch.id ? textSearch.value : ''
    }, [searchStringBelongTo, textSearch.id, textSearch.value])

    if (optionUpgrade && showReminder && textSearchString === '') {
        return (
            <FontSelect fontInfos={fonts} placeholder={translation('SearchFonts')} selectMaxHeight={selectMaxHeight}>
                <div className={styles.container}>
                    <div className={styles.newVersion}>{translation('FindNewVerson')}</div>
                    {versionDesc.length ? <div className={styles.newVersionDetail}>{versionDesc}</div> : null}
                    <div className={styles.button}>
                        <WKButton
                            type="secondary"
                            size="medium"
                            onClick={() => fontManagerService.closePluginReminder()}
                        >
                            {translation('NextTime')}
                        </WKButton>
                        <WKButton
                            type="primary"
                            size="medium"
                            onClick={() => {
                                window.open(versionUrl, '_blank')
                            }}
                        >
                            {translation('Update')}
                        </WKButton>
                    </div>
                </div>
            </FontSelect>
        )
    }

    return (
        <FontSelect
            fontInfos={fonts}
            showRecent
            placeholder={translation('SearchFonts')}
            selectMaxHeight={selectMaxHeight}
        ></FontSelect>
    )
}
