import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    ComponentProp: '组件属性',
    CreateCompProp: '创建组件属性',
    VariantProp: '变体',
    BoolProp: '布尔',
    TextProp: '文本',
    InstanceSwapProp: '实例替换',
    PublicProp: '展示组件属性',
    NestedInstance: '内部实例的属性',
} as const

export const enTranslation = {
    ComponentProp: 'Properties',
    CreateCompProp: 'Create component property',
    VariantProp: 'Variant',
    BoolProp: 'Boolean',
    TextProp: 'Text',
    InstanceSwapProp: 'Instance swap',
    PublicProp: 'Expose properties from',
    NestedInstance: 'Nested instances',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
