type ValidSearchParamValue = string | number | boolean | null | undefined
export type ValidSearchParams = Record<string, ValidSearchParamValue>

// 简易版 query-string.stringify
export function queryStringify(params: ValidSearchParams): string {
    return Object.entries(params)
        .reduce(
            (ret, [key, value]) => [
                ...ret,
                // ...(!value && value !== 0 ? [] : [`${encodeURIComponent(key)}=${encodeURIComponent(value)}`]),
                ...(!value && value !== 0 ? [] : [`${key}=${value}`]),
            ],
            [] as string[]
        )
        .join('&')
}
