import { domLocation } from '../../../../../util/src'
import { openEnhancedIndexedDB } from '../../../web-storage/indexed-db/storage'
import { IndexedDBName } from '../../../web-storage/indexed-db/config'
import { PluginSchema } from '../../../web-storage/indexed-db/config/schema'
import { IDBPDatabase } from 'idb'

export const PLUGIN_CLIENT_QUOTA_STORE = 'client-quota'
export const PLUGIN_CLIENT_STORAGE_STORE = 'client-storage'
export const PLUGIN_CLIENT_QUOTA_LIMIT = 1024 * 1024 // 1M
const DB_VERSION = 1

export async function openPluginDB(version = DB_VERSION): Promise<IDBPDatabase<PluginSchema>> {
    const db = await openEnhancedIndexedDB({
        version,
        name: IndexedDBName.Plugin,
        callback: {
            upgrade: (database) => {
                database.createObjectStore(PLUGIN_CLIENT_QUOTA_STORE)
                database.createObjectStore(PLUGIN_CLIENT_STORAGE_STORE)
            },
        },
    })

    db.onversionchange = (e) => {
        if (e.newVersion && e.newVersion > e.oldVersion) {
            db.close()
            domLocation().reload()
        }
    }

    return db
}
