import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    XOffset: 'X 轴偏移',
    YOffset: 'Y 轴偏移',
    None: '无',
} as const

export const enTranslation = {
    XOffset: 'X-offset',
    YOffset: 'Y-offset',
    None: 'None',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
