import { translation } from './svg-stroke-cap.translation'
/* eslint-disable no-restricted-imports */
// label-short svg
import { ReactComponent as SvgShortNone } from '../../../../assets/strokes/short/short-none.svg'
import { ReactComponent as SvgShortStartCircle } from '../../../../assets/strokes/short/short-start-circle.svg'
import { ReactComponent as SvgShortStartDiamondArrow } from '../../../../assets/strokes/short/short-start-diamond-arrow.svg'
import { ReactComponent as SvgShortStartLineArrow } from '../../../../assets/strokes/short/short-start-line-arrow.svg'
import { ReactComponent as SvgShortStartReversedTriangle } from '../../../../assets/strokes/short/short-start-reversed-triangle.svg'
import { ReactComponent as SvgShortStartRound } from '../../../../assets/strokes/short/short-start-round.svg'
import { ReactComponent as SvgShortStartSquare } from '../../../../assets/strokes/short/short-start-square.svg'
import { ReactComponent as SvgShortStartTriangleArrow } from '../../../../assets/strokes/short/short-start-triangle-arrow.svg'

import { ReactComponent as SvgShortEndCircle } from '../../../../assets/strokes/short/short-end-circle.svg'
import { ReactComponent as SvgShortEndDiamondArrow } from '../../../../assets/strokes/short/short-end-diamond-arrow.svg'
import { ReactComponent as SvgShortEndLineArrow } from '../../../../assets/strokes/short/short-end-line-arrow.svg'
import { ReactComponent as SvgShortEndReversedTriangle } from '../../../../assets/strokes/short/short-end-reversed-triangle.svg'
import { ReactComponent as SvgShortEndRound } from '../../../../assets/strokes/short/short-end-round.svg'
import { ReactComponent as SvgShortEndSquare } from '../../../../assets/strokes/short/short-end-square.svg'
import { ReactComponent as SvgShortEndTriangleArrow } from '../../../../assets/strokes/short/short-end-triangle-arrow.svg'

// label-long svg
import { ReactComponent as SvgLongStartCircle } from '../../../../assets/strokes/long/long-start-circle.svg'
import { ReactComponent as SvgLongNone } from '../../../../assets/strokes/long/none.svg'
import { ReactComponent as SvgLongStartDiamondArrow } from '../../../../assets/strokes/long/start-diamond-arrow.svg'
import { ReactComponent as SvgLongStartLineArrow } from '../../../../assets/strokes/long/start-line-arrow.svg'
import { ReactComponent as SvgLongStartReversedTriangle } from '../../../../assets/strokes/long/start-reversed-triangle.svg'
import { ReactComponent as SvgLongStartRound } from '../../../../assets/strokes/long/start-round.svg'
import { ReactComponent as SvgLongStartSquare } from '../../../../assets/strokes/long/start-square.svg'
import { ReactComponent as SvgLongStartTriangleArrow } from '../../../../assets/strokes/long/start-triangle-arrow.svg'

import { ReactComponent as SvgLongEndDiamondArrow } from '../../../../assets/strokes/long/end-diamond-arrow.svg'
import { ReactComponent as SvgLongEndLineArrow } from '../../../../assets/strokes/long/end-line-arrow.svg'
import { ReactComponent as SvgLongEndReversedTriangle } from '../../../../assets/strokes/long/end-reversed-triangle.svg'
import { ReactComponent as SvgLongEndRound } from '../../../../assets/strokes/long/end-round.svg'
import { ReactComponent as SvgLongEndSquare } from '../../../../assets/strokes/long/end-square.svg'
import { ReactComponent as SvgLongEndTriangleArrow } from '../../../../assets/strokes/long/end-triangle-arrow.svg'
import { ReactComponent as SvgLongEndCircle } from '../../../../assets/strokes/long/long-end-circle.svg'

// label-long-all svg
import { ReactComponent as SvgLongAllCircle } from '../../../../assets/strokes/long/all/all-circle.svg'
import { ReactComponent as SvgLongAllDiamondArrow } from '../../../../assets/strokes/long/all/all-diamond-arrow.svg'
import { ReactComponent as SvgLongAllLineArrow } from '../../../../assets/strokes/long/all/all-line-arrow.svg'
import { ReactComponent as SvgLongAllReversedTriangle } from '../../../../assets/strokes/long/all/all-reversed-triangle.svg'
import { ReactComponent as SvgLongAllRound } from '../../../../assets/strokes/long/all/all-round.svg'
import { ReactComponent as SvgLongAllSquare } from '../../../../assets/strokes/long/all/all-square.svg'
import { ReactComponent as SvgLongAllTriangleArrow } from '../../../../assets/strokes/long/all/all-triangle-arrow.svg'

// default svg
import { ReactComponent as SvgStrokeEndPointCircle } from '../../../../assets/strokes/default/end-circle.svg'
import { ReactComponent as SvgStrokeStartPointCircle } from '../../../../assets/strokes/default/start-circle.svg'

import { StrokeCap } from '../../../../../document/node/node'

import {
    IconStrokeEndPointDiamond,
    IconStrokeEndPointLineArrow,
    IconStrokeEndPointReversedTriangle,
    IconStrokeEndPointRound,
    IconStrokeEndPointSquare,
    IconStrokeEndPointTriangle,
    IconStrokeNone,
    IconStrokeStartPointDiamond,
    IconStrokeStartPointLine,
    IconStrokeStartPointReversedTriangle,
    IconStrokeStartPointRound,
    IconStrokeStartPointSquare,
    IconStrokeStartPointTriangle,
} from '../../../../../../../ui-lib/src'

interface IconGroup {
    name: string
    start: JSX.Element
    startShort: JSX.Element
    startLong: JSX.Element
    end: JSX.Element
    endShort: JSX.Element
    endLong: JSX.Element
    allLong: JSX.Element
}

const strokeCapIconMaps = new Map<StrokeCap, IconGroup>([
    [
        StrokeCap.STROKE_CAP_NONE,
        {
            name: translation('None'),
            start: <IconStrokeNone key={StrokeCap.STROKE_CAP_NONE} />,
            startShort: <SvgShortNone key={StrokeCap.STROKE_CAP_NONE} />,
            startLong: <SvgLongNone key={StrokeCap.STROKE_CAP_NONE} />,
            end: <IconStrokeNone key={StrokeCap.STROKE_CAP_NONE} />,
            endShort: <SvgShortNone key={StrokeCap.STROKE_CAP_NONE} />,
            endLong: <SvgLongNone key={StrokeCap.STROKE_CAP_NONE} />,
            allLong: <SvgLongNone key={StrokeCap.STROKE_CAP_NONE} />,
        },
    ],
    [
        StrokeCap.STROKE_CAP_ARROW_LINES,
        {
            name: translation('LineArrow'),
            start: <IconStrokeStartPointLine key={StrokeCap.STROKE_CAP_ARROW_LINES} />,
            startShort: <SvgShortStartLineArrow key={StrokeCap.STROKE_CAP_ARROW_LINES} />,
            startLong: <SvgLongStartLineArrow key={StrokeCap.STROKE_CAP_ARROW_LINES} />,
            end: <IconStrokeEndPointLineArrow key={StrokeCap.STROKE_CAP_ARROW_LINES} />,
            endShort: <SvgShortEndLineArrow key={StrokeCap.STROKE_CAP_ARROW_LINES} />,
            endLong: <SvgLongEndLineArrow key={StrokeCap.STROKE_CAP_ARROW_LINES} />,
            allLong: <SvgLongAllLineArrow key={StrokeCap.STROKE_CAP_ARROW_LINES} />,
        },
    ],
    [
        StrokeCap.STROKE_CAP_TRIANGLE_FILLED,
        {
            name: translation('TriangleArrow'),
            start: <IconStrokeStartPointTriangle key={StrokeCap.STROKE_CAP_TRIANGLE_FILLED} />,
            startShort: <SvgShortStartTriangleArrow key={StrokeCap.STROKE_CAP_TRIANGLE_FILLED} />,
            startLong: <SvgLongStartTriangleArrow key={StrokeCap.STROKE_CAP_TRIANGLE_FILLED} />,
            end: <IconStrokeEndPointTriangle key={StrokeCap.STROKE_CAP_TRIANGLE_FILLED} />,
            endShort: <SvgShortEndTriangleArrow key={StrokeCap.STROKE_CAP_TRIANGLE_FILLED} />,
            endLong: <SvgLongEndTriangleArrow key={StrokeCap.STROKE_CAP_TRIANGLE_FILLED} />,
            allLong: <SvgLongAllTriangleArrow key={StrokeCap.STROKE_CAP_TRIANGLE_FILLED} />,
        },
    ],
    [
        StrokeCap.STROKE_CAP_ARROW_EQUILATERAL,
        {
            name: translation('ReversedTriangle'),
            start: <IconStrokeStartPointReversedTriangle key={StrokeCap.STROKE_CAP_ARROW_EQUILATERAL} />,
            startShort: <SvgShortStartReversedTriangle key={StrokeCap.STROKE_CAP_ARROW_EQUILATERAL} />,
            startLong: <SvgLongStartReversedTriangle key={StrokeCap.STROKE_CAP_ARROW_EQUILATERAL} />,
            end: <IconStrokeEndPointReversedTriangle key={StrokeCap.STROKE_CAP_ARROW_EQUILATERAL} />,
            endShort: <SvgShortEndReversedTriangle key={StrokeCap.STROKE_CAP_ARROW_EQUILATERAL} />,
            endLong: <SvgLongEndReversedTriangle key={StrokeCap.STROKE_CAP_ARROW_EQUILATERAL} />,
            allLong: <SvgLongAllReversedTriangle key={StrokeCap.STROKE_CAP_ARROW_EQUILATERAL} />,
        },
    ],
    [
        StrokeCap.STROKE_CAP_CIRCLE_FILLED,
        {
            name: translation('CircleArrow'),
            start: <SvgStrokeStartPointCircle key={StrokeCap.STROKE_CAP_CIRCLE_FILLED} />,
            startShort: <SvgShortStartCircle key={StrokeCap.STROKE_CAP_CIRCLE_FILLED} />,
            startLong: <SvgLongStartCircle key={StrokeCap.STROKE_CAP_CIRCLE_FILLED} />,
            end: <SvgStrokeEndPointCircle key={StrokeCap.STROKE_CAP_CIRCLE_FILLED} />,
            endShort: <SvgShortEndCircle key={StrokeCap.STROKE_CAP_CIRCLE_FILLED} />,
            endLong: <SvgLongEndCircle key={StrokeCap.STROKE_CAP_CIRCLE_FILLED} />,
            allLong: <SvgLongAllCircle key={StrokeCap.STROKE_CAP_CIRCLE_FILLED} />,
        },
    ],
    [
        StrokeCap.STROKE_CAP_DIAMOND_FILLED,
        {
            name: translation('DiamondArrow'),
            start: <IconStrokeStartPointDiamond key={StrokeCap.STROKE_CAP_DIAMOND_FILLED} />,
            startShort: <SvgShortStartDiamondArrow key={StrokeCap.STROKE_CAP_DIAMOND_FILLED} />,
            startLong: <SvgLongStartDiamondArrow key={StrokeCap.STROKE_CAP_DIAMOND_FILLED} />,
            end: <IconStrokeEndPointDiamond key={StrokeCap.STROKE_CAP_DIAMOND_FILLED} />,
            endShort: <SvgShortEndDiamondArrow key={StrokeCap.STROKE_CAP_DIAMOND_FILLED} />,
            endLong: <SvgLongEndDiamondArrow key={StrokeCap.STROKE_CAP_DIAMOND_FILLED} />,
            allLong: <SvgLongAllDiamondArrow key={StrokeCap.STROKE_CAP_DIAMOND_FILLED} />,
        },
    ],
    [
        StrokeCap.STROKE_CAP_ROUND,
        {
            name: translation('Round'),
            start: <IconStrokeStartPointRound key={StrokeCap.STROKE_CAP_ROUND} />,
            startShort: <SvgShortStartRound key={StrokeCap.STROKE_CAP_ROUND} />,
            startLong: <SvgLongStartRound key={StrokeCap.STROKE_CAP_ROUND} />,
            end: <IconStrokeEndPointRound key={StrokeCap.STROKE_CAP_ROUND} />,
            endShort: <SvgShortEndRound key={StrokeCap.STROKE_CAP_ROUND} />,
            endLong: <SvgLongEndRound key={StrokeCap.STROKE_CAP_ROUND} />,
            allLong: <SvgLongAllRound key={StrokeCap.STROKE_CAP_ROUND} />,
        },
    ],
    [
        StrokeCap.STROKE_CAP_SQUARE,
        {
            name: translation('Square'),
            start: <IconStrokeStartPointSquare key={StrokeCap.STROKE_CAP_SQUARE} />,
            startShort: <SvgShortStartSquare key={StrokeCap.STROKE_CAP_SQUARE} />,
            startLong: <SvgLongStartSquare key={StrokeCap.STROKE_CAP_SQUARE} />,
            end: <IconStrokeEndPointSquare key={StrokeCap.STROKE_CAP_SQUARE} />,
            endShort: <SvgShortEndSquare key={StrokeCap.STROKE_CAP_SQUARE} />,
            endLong: <SvgLongEndSquare key={StrokeCap.STROKE_CAP_SQUARE} />,
            allLong: <SvgLongAllSquare key={StrokeCap.STROKE_CAP_SQUARE} />,
        },
    ],
])

export function getStrokeCapIconMaps() {
    return strokeCapIconMaps
}
