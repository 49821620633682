import {
    FeatureSwitchConfig,
    SwitchEnvironmentScopeType,
    SwitchStrategyType,
    UserGroupType,
} from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'export-ano',
    owner: '',
    description: '',
    strategies: [
        {
            env: SwitchEnvironmentScopeType.TESTING,
            config: { type: SwitchStrategyType.ALL_USER },
        },
        {
            env: SwitchEnvironmentScopeType.PRODUCTION,
            config: { type: SwitchStrategyType.SPECIFIC_USER_LIST, group: UserGroupType.CYPRESS },
        },
        {
            env: SwitchEnvironmentScopeType.PRODUCTION,
            config: { type: SwitchStrategyType.SPECIFIC_SUFFIX, suffix: ['@kanyun.com'] },
        },
        {
            env: SwitchEnvironmentScopeType.PRODUCTION,
            config: { type: SwitchStrategyType.SPECIFIC_SUFFIX, suffix: ['@bytedance.com'] },
        },
        {
            env: SwitchEnvironmentScopeType.PRODUCTION,
            config: { type: SwitchStrategyType.SPECIFIC_SUFFIX, suffix: ['@motiff.com'] },
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
