import { ColorStop } from '../../../../../document/node/node'

export function getColorStopByPosition(colorStops: Readonly<ColorStop[]>, position: number) {
    let smallerColorStop: ColorStop | undefined
    let biggerColorStop: ColorStop | undefined
    let minColorStop: ColorStop = colorStops[0]
    let maxColorStop: ColorStop = colorStops[0]
    for (const colorStop of colorStops) {
        if (colorStop.position <= position && (!smallerColorStop || colorStop.position > smallerColorStop.position)) {
            smallerColorStop = colorStop
        }
        if (colorStop.position >= position && (!biggerColorStop || colorStop.position < biggerColorStop.position)) {
            biggerColorStop = colorStop
        }
        if (colorStop.position < minColorStop.position) {
            minColorStop = colorStop
        } else if (colorStop.position > maxColorStop.position) {
            maxColorStop = colorStop
        }
    }
    if (!smallerColorStop) {
        const color = minColorStop.color
        return { position, color: { r: color.r, g: color.g, b: color.b, a: color.a } }
    }
    if (!biggerColorStop) {
        const color = maxColorStop.color
        return { position, color: { r: color.r, g: color.g, b: color.b, a: color.a } }
    }
    const range = biggerColorStop.position - smallerColorStop.position
    if (range <= 0) {
        return smallerColorStop
    } else {
        const rate = (position - smallerColorStop.position) / range
        const r = smallerColorStop.color.r + (biggerColorStop.color.r - smallerColorStop.color.r) * rate
        const g = smallerColorStop.color.g + (biggerColorStop.color.g - smallerColorStop.color.g) * rate
        const b = smallerColorStop.color.b + (biggerColorStop.color.b - smallerColorStop.color.b) * rate
        const a = smallerColorStop.color.a + (biggerColorStop.color.a - smallerColorStop.color.a) * rate
        return { position, color: { r, g, b, a } }
    }
}
export function getMinPositionIndex(colorStops: readonly ColorStop[]) {
    let _index = 0,
        minPosition = 1
    colorStops.forEach((colorStop, index) => {
        if (minPosition > colorStop.position || index === 0) {
            minPosition = colorStop.position
            _index = index
        }
    })
    return _index
}

export function copyColorStop(colorStop: ColorStop) {
    const colorStopCopy: ColorStop = {
        position: colorStop.position,
        color: {
            r: colorStop.color.r,
            g: colorStop.color.g,
            b: colorStop.color.b,
            a: colorStop.color.a,
        },
    }
    return colorStopCopy
}

export function getCenterColorStop(colorStop1: ColorStop, colorStop2: ColorStop) {
    const centerColorStop: ColorStop = {
        position: (colorStop1.position + colorStop2.position) / 2,
        color: {
            r: (colorStop1.color.r + colorStop2.color.r) / 2,
            g: (colorStop1.color.g + colorStop2.color.g) / 2,
            b: (colorStop1.color.b + colorStop2.color.b) / 2,
            a: (colorStop1.color.a + colorStop2.color.a) / 2,
        },
    }
    return centerColorStop
}
