// 用进制位标记altKey、metaKey、shiftKey、ctrlKey这四个特殊键
export function altMetaShiftCtrlByteFlag(e: React.KeyboardEvent) {
    return (e.altKey ? 256 : 0) | (e.metaKey ? 512 : 0) | (e.shiftKey ? 1024 : 0) | (e.ctrlKey ? 2048 : 0)
}

// 没有altKey、metaKey、shiftKey、ctrlKey按下或者给定的 t对应的键的组合按下
export function isOnlyPressedSpecialKey(e: React.KeyboardEvent, targetByteFlag: number) {
    const byteFlag = altMetaShiftCtrlByteFlag(e)
    return byteFlag === 0 || (byteFlag & ~targetByteFlag) == 0
}
