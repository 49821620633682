import { useCallback, useMemo } from 'react'
import { IconSubtract16 } from '../../../svg-icon/16/common'
import { CustomItem, CustomItemProps } from '../../custom-item/custom-item'
import { RenderStructNodeItem, RenderStructNodeItemProps } from '../../selects/picks/multi-level/type'
import { isHiddenDataNode } from '../../selects/picks/multi-level/utils'
import { getOptionMinWidth } from '../picks/option-width'

type OmitCustomItemProps = Omit<
    CustomItemProps,
    'splitLineTop' | 'splitLineBottom' | 'dataTestId' | 'disabled' | 'isHover' | 'addRightPadding'
>
export interface DropdownDefaultCustomNode {
    autoSplitLine?: boolean
    shortcut?: string
    customItemProps?: OmitCustomItemProps
}

export const DropdownDefaultRenderItem: RenderStructNodeItem<DropdownDefaultCustomNode> = (
    props: RenderStructNodeItemProps<DropdownDefaultCustomNode>
) => {
    const {
        data: { name, disabled, dataTestId, customNode = {} },
        isExtend,
        isPreselect,
        hasChild,
        getSameLevelDataList,
        getIndexInSameLevelDataList,
    } = props
    const { customItemProps, autoSplitLine, shortcut } = customNode

    const hasSelectConfig = useMemo(() => {
        const sameLevelDateList = getSameLevelDataList()
        return sameLevelDateList.some((dataNode) => {
            if (isHiddenDataNode(dataNode)) {
                return false
            }
            const hasConfig = dataNode.customNode?.customItemProps?.isSelect !== undefined
            return hasConfig
        })
    }, [getSameLevelDataList])

    const backwardIcon = useMemo(() => {
        return hasChild ? <IconSubtract16 /> : shortcut ?? customItemProps?.backwardIcon
    }, [hasChild, shortcut, customItemProps?.backwardIcon])

    const shouldShowSplitLine = useCallback(() => {
        const list = getSameLevelDataList()
        const selfIndex = getIndexInSameLevelDataList()
        const checkForward = () => {
            const length = list.length
            let checkIndex = selfIndex - 1
            while (checkIndex < length && checkIndex >= 0) {
                const dataNode = list[checkIndex]
                if (dataNode && !isHiddenDataNode(dataNode)) {
                    return dataNode.customNode?.autoSplitLine ? false : true
                }
                checkIndex--
            }
            return false
        }
        const checkBackward = () => {
            const length = list.length
            let checkIndex = selfIndex + 1
            while (checkIndex < length) {
                const dataNode = list[checkIndex]
                if (dataNode && !isHiddenDataNode(dataNode) && !dataNode.customNode?.autoSplitLine) {
                    return true
                }
                checkIndex++
            }
            return false
        }
        return checkForward() && checkBackward()
    }, [getIndexInSameLevelDataList, getSameLevelDataList])

    return autoSplitLine ? (
        shouldShowSplitLine() ? (
            <div
                data-testid={dataTestId}
                style={{ marginTop: 7, marginBottom: 8, borderBottom: '1px solid var(--wk-gray-13-6)' }}
            ></div>
        ) : (
            <></>
        )
    ) : (
        <CustomItem
            {...customItemProps}
            dataTestId={dataTestId}
            disabled={disabled}
            isHover={isPreselect || isExtend}
            backwardIcon={backwardIcon}
            addRightPadding
            isSelect={hasSelectConfig ? !!customItemProps?.isSelect : undefined}
            style={{
                ...customItemProps?.style,
                minWidth: getOptionMinWidth(!!backwardIcon),
                userSelect: 'none',
                ...(hasChild ? { paddingRight: 12 } : {}),
            }}
            selectContainerStyle={{
                ...customItemProps?.selectContainerStyle,
                marginLeft: -8,
            }}
            backwardIconStyle={{ paddingLeft: 16 }}
        >
            {name}
        </CustomItem>
    )
}
