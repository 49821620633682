import { Wukong } from '@wukong/bridge-proto'
import { useMemo } from 'react'
import { PrimitiveVariablePanelBase } from '../../design/primitive-variable/components/primitive-variable-panel-base'

// 数值变量选择器，与场景无关
export function FloatValueEditingPopupPure({
    position,
    onCancel,
    onVariableSelected,
    variableData,
}: {
    position: { top: number; left: number }
    onCancel: () => void
    onVariableSelected: (id: string) => void
    variableData: Wukong.DocumentProto.ILocalVariableData
}) {
    const selectedSingleVariable = useMemo(() => {
        if (variableData.dataType !== Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_ALIAS) {
            return null
        }
        return {
            id: variableData.alias,
            name: variableData.aliasName,
            setId: variableData.aliasSetId,
            key: variableData.key,
            isLocal: variableData.isLocal,
            isSoftDeleted: variableData.isSoftDeleted,
            floatValue: variableData.resolvedValue.floatValue, // 如果被删除，则要以这个 value 展示未知变量的值
        } as Wukong.DocumentProto.ISingleFloatVariable
    }, [variableData])
    return (
        <PrimitiveVariablePanelBase
            position={position}
            onCancel={onCancel}
            onVariableSelected={onVariableSelected}
            selectedSingleVariable={
                selectedSingleVariable
                    ? {
                          type: 'float',
                          variable: selectedSingleVariable,
                      }
                    : undefined
            }
            requiredTypes={[Wukong.DocumentProto.PrimitiveVariableType.PRIMITIVE_VARIABLE_TYPE_FLOAT]}
            requiredScopes={[Wukong.DocumentProto.VariableScope.ALL_SCOPES]}
            hideVariableValue
        />
    )
}
