import React, { forwardRef } from 'react'
import {
    MultiLevel as SelectMultiLevel,
    MultiLevelProps as SelectMultiLevelProps,
    MultiLevelRef as SelectMultiLevelRef,
} from '../../../selects/picks/multi-level/multi-level'
import { CustomNode } from '../../../selects/picks/multi-level/type'
import { getOptionMaxWidth } from '../option-width'

export interface MultiLevelProps<T extends CustomNode> extends SelectMultiLevelProps<T> {}

export interface MultiLevelRef extends SelectMultiLevelRef {}

function _MultiLevel<T extends CustomNode, K extends MultiLevelProps<T> = MultiLevelProps<T>>(
    props: K,
    ref?: React.Ref<MultiLevelRef>
) {
    const { placement = 'bottom left', maxWidth, ...otherProps } = props

    return (
        <SelectMultiLevel<T> placement={placement} maxWidth={getOptionMaxWidth(maxWidth)} {...otherProps} ref={ref} />
    )
}

export const MultiLevel = forwardRef(_MultiLevel) as <
    T extends CustomNode = CustomNode,
    K extends MultiLevelProps<T> = MultiLevelProps<T>
>(
    props: K & { ref?: React.Ref<MultiLevelRef> }
) => React.ReactElement
