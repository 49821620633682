/* eslint-disable no-restricted-imports */

import { RouteToken, domLocation } from '../../../../../util/src'
import { GetOrganizationAdmins } from '../../../kernel/request/organizations'
import * as TeamRequest from '../../../kernel/request/team'
import { SpaceNotifyFlow } from './notify-flow'
import { AllTeamsStore, SpaceStoreType, getSpaceStore, setSpaceStore } from './types'

export const generateAllTeamsState = (set: setSpaceStore, get: getSpaceStore): AllTeamsStore => {
    return {
        admins: [],
        teams: [],
        loading: false,
        initData: async () => {
            const orgId = get().organizationStore.organization.id
            set((state) => {
                state.allTeamsStore.loading = true
            })
            new GetOrganizationAdmins(orgId).start().then((admins) => {
                set((state) => {
                    state.allTeamsStore.admins = admins
                })
            })
            new TeamRequest.GetTeamsInOrg(orgId).start().then((teams) => {
                set((state) => {
                    state.allTeamsStore.teams = teams
                })
            })
            set((state) => {
                state.allTeamsStore.loading = false
            })
        },
    }
}
const inTeamsPage = () => domLocation().pathname.includes(`/${RouteToken.Teams}`)
export const syncAllTeamsState = (store: SpaceStoreType, notifyFlow: SpaceNotifyFlow) => {
    // 协同
    notifyFlow.addRelationChangeCallbackWithoutAccess(() => {
        if (inTeamsPage()) {
            store.getState().allTeamsStore.initData()
        }
    })
    notifyFlow.addPropertyChangeCallbackWithoutDocAndUser(() => {
        if (inTeamsPage()) {
            store.getState().allTeamsStore.initData()
        }
    })
}
