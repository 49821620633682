import classnames from 'classnames'
import { forwardRef, useImperativeHandle, useRef } from 'react'
import { ExplicitUndefined } from '../../../utils/explicit-undefined'
import { TriggerContainer, TriggerContainerProps } from './base-components/trigger-container'
import { TriggerFocus, TriggerFocusProps, TriggerFocusRef } from './base-components/trigger-focus'
import classes from './trigger-empty.module.less'
import { TriggerBaseProps, TriggerBaseRef } from './type'
import { useCommonTrigger } from './use-common-trigger'

export interface TriggerEmptyProps extends TriggerBaseProps {
    // TriggerContainerProps
    classNameWhenOpen?: TriggerContainerProps['classNameWhenOpen']
    isOpenPick: TriggerContainerProps['isOpenPick']
    // 私有配置
    label?: React.ReactNode // tab 时，此元素处于高亮框内
    minorLabel?: React.ReactNode // tab 时，此元素处于高亮框外
    triggerClassName?: string
    disabledDefaultFontStyle?: boolean
    disabledDefaultSvgStyle?: boolean
    // TODO: 移除下面这个字段
    disabledDefaultHoverStyle?: boolean
}
export interface TriggerEmptyRef extends TriggerBaseRef {}

function _TriggerEmpty(props: TriggerEmptyProps, ref?: React.Ref<TriggerEmptyRef>) {
    const {
        className,
        disabled,
        dataTestIds,
        openAction,
        closeAction,
        classNameWhenOpen,
        isOpenPick,
        label,
        minorLabel,
        triggerClassName,
        disabledDefaultFontStyle,
        disabledDefaultSvgStyle,
        disabledDefaultHoverStyle,
        ...otherProps
    } = props

    const triggerRef = useRef<TriggerFocusRef>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const commonTrigger = useCommonTrigger({ isOpenPick, openAction, closeAction })

    useImperativeHandle(
        ref,
        () => ({
            getTriggerElement: () => triggerRef.current?.getContainer(),
            triggerRect: () => triggerRef.current?.getContainer().getBoundingClientRect()!,
            focus: () => triggerRef.current?.getFocusElement().focus(),
            getContainer: () => containerRef.current!,
        }),
        []
    )
    return (
        <TriggerContainer<ExplicitUndefined<TriggerContainerProps>>
            ref={containerRef}
            className={classnames(classes.template, [className], {
                [classes.disabled]: disabled,
                [classes.defaultFontStyle]: !disabledDefaultFontStyle,
                [classes.defaultSvgStyle]: !disabledDefaultSvgStyle,
                [classes.defaultHoverStyle]: !disabledDefaultHoverStyle,
            })}
            disabled={disabled}
            dataTestIds={dataTestIds}
            classNameWhenOpen={classNameWhenOpen}
            isOpenPick={isOpenPick}
            {...otherProps}
        >
            {minorLabel ? minorLabel : null}
            <TriggerFocus<ExplicitUndefined<TriggerFocusProps>>
                ref={triggerRef}
                className={classnames(classes.trigger, [triggerClassName])}
                dataTestIds={dataTestIds}
                disabled={disabled}
                onClick={commonTrigger.click}
                onMouseDown={commonTrigger.mousedown}
                onEnter={openAction}
                onBlankSpace={openAction}
                outlineMode={undefined}
            >
                {label}
            </TriggerFocus>
            {props.children}
        </TriggerContainer>
    )
}

export const TriggerEmpty = forwardRef(_TriggerEmpty)
