import classnames from 'classnames'
import { cloneDeep } from 'lodash-es'
import React, { forwardRef, useCallback, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useMount, useUpdateEffect } from 'react-use'
import { MonoIconCommonError16 } from '../../../icons-v2'
import { Resize } from '../../resize/resize'
import { ScrollView, ScrollViewRef } from '../../scrollbars'
import { TagProps } from '../../tag'
import { HtmlInput } from '../html-input/html-input'
import { HtmlTextarea, HtmlTextareaRef } from '../html-textarea/html-textarea'
import { PopUpSelectList, PopUpSelectListProps, PopUpSelectListRef } from '../pop-up-select-list/pop-up-select-list'
import { OnceCallbackWithSignal } from '../utils/once-callback-with-signal'
import classes from './tag-input.module.less'
import { TagItem } from './tag-item'

export interface TagItem<K = undefined> {
    name: TagProps['name']
    color: Exclude<TagProps['color'], Exclude<TagProps['color'], 'black' | 'red'>>
    customProps?: K
}

export interface ListProps<T = any> extends Omit<PopUpSelectListProps<T>, 'onSelectItem'> {}

export interface TagInputProps<K = undefined, T = any> {
    className?: string
    width?: number
    minWidth?: number
    maxWidth?: number
    style?: React.CSSProperties
    disabled?: boolean
    autoFocus?: boolean
    placeholder?: string
    tool?: JSX.Element // 会替换默认的清除工具
    autoHeight?: boolean // false 表示单行， true表示换行
    autoCollapse?: boolean // autoHeight=true条件下，true表示失焦自动折叠成一行。输入的值会被清空
    defaultTags?: TagItem<K>[] // 默认填充的tags
    listProps?: ListProps<T> & {
        visible?: boolean // 【输入框聚焦后】搜索区域是否展示（输入框不聚焦，始终不展示）。默认是列表项不为空
    } // 搜索列表的配置
    dataTestIds?: { root?: string; clearIcon?: string; input?: string }
    generateTag: (param: string | T) => TagItem<K> | void // 通过输入或者选择推荐项而生成标签的方法
    onChangeTag?: (tags: TagItem<K>[]) => void // 标签数变化时的回调
    onChangeValue?: (value: string) => void // 输入区域内的内容发生变化时的回调
}
export interface TagInputRef<K = undefined> {
    appendTag: (...tags: (TagItem<K> | undefined)[]) => void
    deleteAllTags: () => void
}
const definedMinHeight = 24 * 1 + 4 * 2 // 设计定义1行
const definedMaxHeight = 24 * 10 + 4 * 11 // 设计定义10行
const textareaLineHeight = 20
const flagForCollapse = 'role_tag'
function _TagInput<K = undefined, T = string>(props: TagInputProps<K, T>, ref: React.Ref<TagInputRef<K>>) {
    const {
        className,
        width,
        minWidth,
        maxWidth,
        style,
        disabled,
        autoFocus,
        placeholder,
        defaultTags = [],
        listProps,
        autoHeight,
        autoCollapse,
        tool,
        dataTestIds,
        generateTag,
        onChangeTag,
        onChangeValue,
    } = props
    const [tags, setTags] = useState<TagItem<K>[]>(() => cloneDeep(defaultTags))
    const [textboxHasValue, setTextboxHasValue] = useState<boolean>(false)
    const [firstLineTagCount, setFirstLineTagCount] = useState<number>(tags.length)
    const [isFocusinRoot, setIsFocusInRoot] = useState<boolean>(false)
    const [isFocusTextbox, setIsFocusTextbox] = useState<boolean>(false)
    const [interactiveKey, setInteractiveKey] = useState<number>(-1)
    const [tagMaxWidth, setTagMaxWidth] = useState<number>()
    const [cutTagMaxWidth, setCutTagMaxWidth] = useState<number>(0)
    const popUpSelectListRef = useRef<PopUpSelectListRef<any>>(null)
    const rootRef = useRef<HTMLDivElement>(null)
    const scrollViewRef = useRef<ScrollViewRef>(null)
    const contentRef = useRef<HTMLDivElement>(null)
    const textboxAreaRef = useRef<HTMLDivElement>(null)
    const htmlTextareaRef = useRef<HtmlTextareaRef>(null)
    const htmlInputRef = useRef<HTMLInputElement>(null)
    const onChangeValueOnceFromCollapsed = useRef<OnceCallbackWithSignal<(value: string) => void>>()
    if (!onChangeValueOnceFromCollapsed.current) {
        onChangeValueOnceFromCollapsed.current = new OnceCallbackWithSignal()
    }
    onChangeValueOnceFromCollapsed.current?.setCallback((v) => onChangeValue?.(v))

    const rootStyle = useMemo((): React.CSSProperties => {
        return { ...style, width, minWidth, maxWidth }
    }, [maxWidth, minWidth, style, width])

    const isShowTipMessage = useMemo(() => {
        if (!isFocusTextbox) {
            return false
        }
        if (listProps?.visible !== undefined) {
            return listProps.visible
        }
        return !!listProps?.items?.length
    }, [isFocusTextbox, listProps])

    const isCollapsed = useMemo(() => {
        return autoHeight && autoCollapse && !isFocusinRoot
    }, [autoCollapse, autoHeight, isFocusinRoot])

    const tagsForRender = useMemo(() => {
        if (isCollapsed) {
            return tags.filter((_, i) => i < firstLineTagCount)
        }
        return tags
    }, [firstLineTagCount, isCollapsed, tags])

    const resetInputWidth = useCallback(() => {
        const htmlInput = htmlInputRef.current
        if (!htmlInput) {
            return
        }
        const parent = htmlInput.parentElement
        const parentWidth = parent?.style.width ?? ''
        if (parent) {
            parent.style.width = window.getComputedStyle(htmlInput, 'style').width
        }
        htmlInput.style.width = ''
        htmlInput.style.width = `${htmlInput.scrollWidth}px`
        if (parent) {
            parent.style.width = parentWidth
        }
        // input 要向左延展
        scrollViewRef.current?.updateView('end')
    }, [])

    const resetTextareaWidth = useCallback(() => {
        if (!htmlTextareaRef.current || !textboxAreaRef.current || !contentRef.current) {
            return
        }
        const textarea = htmlTextareaRef.current.getTextareaElement()
        const contentRect = contentRef.current.getBoundingClientRect()
        const minCharacterWidth = 2
        const isSignificantlyExceed = textarea.value.length * minCharacterWidth >= contentRect.width
        const textboxArea = textboxAreaRef.current
        if (isSignificantlyExceed) {
            textboxArea.style.minWidth = '100%'
            htmlTextareaRef.current?.updateAutoHeight()
            return
        }
        textboxArea.style.minWidth = ''
        htmlTextareaRef.current?.updateAutoHeight()
        const textareaRect = htmlTextareaRef.current.getTextareaElement().getBoundingClientRect()
        if (textareaRect.height >= textareaLineHeight * 2) {
            textboxArea.style.minWidth = '100%'
            htmlTextareaRef.current?.updateAutoHeight()
        }
    }, [])

    const getTextboxElement = useCallback(() => {
        return htmlInputRef.current ?? htmlTextareaRef.current?.getTextareaElement()
    }, [])

    const resetTextboxWidth = useCallback(() => {
        resetInputWidth()
        resetTextareaWidth()
    }, [resetInputWidth, resetTextareaWidth])

    const clearTextboxValue = useCallback(
        (skipReset?: boolean) => {
            const typeInElement = getTextboxElement()
            if (!typeInElement?.value) {
                return
            }
            typeInElement.value = ''
            setTextboxHasValue(false)
            if (!skipReset) {
                resetTextboxWidth()
            }
            onChangeValue?.('')
        },
        [getTextboxElement, onChangeValue, resetTextboxWidth]
    )

    const updateTagMaxWidth = useCallback(() => {
        setTagMaxWidth(contentRef.current?.clientWidth)
    }, [])

    const updateTags = useCallback(
        (nextTags: TagItem<K>[], shouldClearInputValue: boolean) => {
            setTags(nextTags)
            setFirstLineTagCount(nextTags.length)
            onChangeTag?.(cloneDeep(nextTags))
            if (shouldClearInputValue) {
                clearTextboxValue(true)
            }
            setTimeout(() => resetTextboxWidth(), 0)
        },
        [clearTextboxValue, onChangeTag, resetTextboxWidth]
    )

    const appendTag = useCallback(
        (...additionalTags: (TagItem<K> | void)[]) => {
            const validTags = additionalTags.filter((tag) => tag) as TagItem<K>[]
            if (validTags.length) {
                updateTags([...tags, ...validTags], true)
            }
        },
        [tags, updateTags]
    )

    const deleteAllTags = useCallback(() => {
        updateTags([], false)
        setInteractiveKey(-1)
    }, [updateTags])

    const deleteTag = useCallback(
        (deleteTagIndex: number, deleteType: 'mouse' | 'keyboard') => {
            if (deleteTagIndex >= tags.length || deleteTagIndex < 0) {
                return
            }
            updateTags(
                tags.filter((_, index) => index !== deleteTagIndex),
                false
            )
            if (deleteType === 'mouse') {
                setInteractiveKey(NaN)
                rootRef.current?.contains(document.activeElement) && (document.activeElement as HTMLElement).blur()
                return
            }
            if (deleteTagIndex > interactiveKey) {
                return
            }
            if (deleteTagIndex < interactiveKey || deleteTagIndex !== 0) {
                return setInteractiveKey(interactiveKey - 1)
            }
            if (tags.length === 1) {
                return setInteractiveKey(-1)
            } else {
                return setInteractiveKey(0)
            }
        },
        [interactiveKey, tags, updateTags]
    )

    const move2left = useCallback(() => {
        setInteractiveKey(interactiveKey === -1 ? tags.length - 1 : Math.max(interactiveKey - 1, 0))
    }, [interactiveKey, tags.length])

    const move2right = useCallback(() => {
        if (interactiveKey === -1) {
            return
        }
        const nextInteractiveKey = interactiveKey + 1
        setInteractiveKey(nextInteractiveKey >= tags.length ? -1 : nextInteractiveKey)
    }, [interactiveKey, tags.length])

    const onKeyDownRoot = useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.code !== 'ArrowLeft' && e.code !== 'ArrowRight' && e.code !== 'Delete' && e.code !== 'Backspace') {
                return
            }
            const tagName = (e.target as HTMLElement).tagName.toLocaleLowerCase()
            if (tagName === 'input' || tagName === 'textarea') {
                const element = e.target as HTMLInputElement | HTMLTextAreaElement
                if (element.selectionStart !== 0 || element.selectionEnd !== element.selectionStart) {
                    return
                }
                if (e.code === 'Delete' || e.code === 'Backspace') {
                    move2left()
                    return
                }
            }
            if (e.code === 'ArrowLeft') {
                move2left()
            } else if (e.code === 'ArrowRight') {
                move2right()
            }
        },
        [move2left, move2right]
    )

    const onClickRoot = useCallback(
        (e: React.MouseEvent) => {
            if (e.currentTarget.contains(document.activeElement) && e.currentTarget !== document.activeElement) {
                return
            }
            getTextboxElement()?.focus()
        },
        [getTextboxElement]
    )
    const onFocusRoot = () => {
        if (isCollapsed) {
            // 清除 输入框里的 +n
            clearTextboxValue()
        }
        setIsFocusInRoot(true)
    }
    const onBlurRoot = () => {
        setTimeout(() => {
            setIsFocusInRoot(!!rootRef.current?.contains(document.activeElement))
        }, 0)
    }

    const onKeyDownTextbox = useCallback(
        (e: React.KeyboardEvent) => {
            const element = e.target as HTMLInputElement | HTMLTextAreaElement
            if (e.code === 'Escape') {
                if (element.value) {
                    clearTextboxValue()
                } else {
                    setTimeout(() => element.blur(), 0)
                }
            } else if (e.code === 'ArrowUp') {
                if (popUpSelectListRef.current?.move2top()) {
                    e.preventDefault() // 阻止输入框默认的关闭移动
                }
            } else if (e.code === 'ArrowDown') {
                if (popUpSelectListRef.current?.move2bottom()) {
                    e.preventDefault() // 阻止输入框默认的关闭移动
                }
            } else if (e.code === 'Enter') {
                e.preventDefault() // 阻止输入框默认的的换行
                const item = popUpSelectListRef.current?.getSelectItem()
                if (item) {
                    appendTag(generateTag(item))
                } else if (element.value) {
                    appendTag(generateTag(element.value))
                }
            }
        },
        [clearTextboxValue, appendTag, generateTag]
    )

    const onFocusTextbox = useCallback(() => {
        setIsFocusTextbox(true)
        setInteractiveKey(-1)
    }, [])

    const onBlurTextbox = useCallback(() => {
        setIsFocusTextbox(false)
    }, [])

    const onChangeTextbox = useCallback(
        (e: React.FormEvent) => {
            const element = e.target as HTMLInputElement | HTMLTextAreaElement
            onChangeValue?.(element.value)
        },
        [onChangeValue]
    )

    const onInputTextarea = useCallback(
        (e: React.FormEvent<HTMLTextAreaElement>) => {
            const element = e.target as HTMLTextAreaElement
            const lineBreak = /\n/g
            const value = element.value
            if (lineBreak.test(value)) {
                element.value = value.replaceAll(lineBreak, '')
            }
            setTextboxHasValue(!!element.value)
            resetTextareaWidth()
        },
        [resetTextareaWidth]
    )

    const onInputInput = useCallback(
        (e: React.FormEvent<HTMLInputElement>) => {
            const element = e.target as HTMLInputElement
            setTextboxHasValue(!!element.value)
            resetInputWidth()
        },
        [resetInputWidth]
    )

    const onSelectItem = useCallback(
        (item: T) => {
            appendTag(generateTag(item))
        },
        [generateTag, appendTag]
    )
    useLayoutEffect(() => {
        if (!isCollapsed) {
            setCutTagMaxWidth(0)
            onChangeValueOnceFromCollapsed.current?.reset()
            return
        }
        const el = getTextboxElement()
        const textboxArea = textboxAreaRef.current
        const tagElements = contentRef.current?.getElementsByClassName(flagForCollapse) as HTMLCollectionOf<HTMLElement>
        if (!el || !textboxArea || !tagElements) {
            return
        }
        let firstLineBottom = NaN
        let firstLineTagCountToBe = 0
        let isFirstLine = true
        for (const tagElement of tagElements) {
            isNaN(firstLineBottom) && (firstLineBottom = tagElement.offsetTop + tagElement.clientHeight)
            if (tagElement.offsetTop >= firstLineBottom) {
                isFirstLine = false
            }
            if (isFirstLine) {
                firstLineTagCountToBe = firstLineTagCountToBe + 1
            } else {
                tagElement.style.display = 'none'
            }
        }
        onChangeValueOnceFromCollapsed.current?.addSign(!!el.value)
        el.value = tags.length > firstLineTagCountToBe ? `+${tags.length - tagsForRender.length}` : ''
        setTextboxHasValue(!!el.value)
        resetTextboxWidth()
        if (textboxArea.offsetTop >= firstLineBottom) {
            if (firstLineTagCountToBe === 1) {
                // 这里的情况有点复杂，就是第一个tag已经超长了放不下任何内容了，此时需要减少第一个tag的宽度以便能放下+n 或者折叠后的输入区
                const fontSize = parseInt(window.getComputedStyle(el).fontSize)
                setCutTagMaxWidth(Math.max(1, el.value.length) * fontSize)
                setFirstLineTagCount(1)
            } else {
                setFirstLineTagCount(firstLineTagCountToBe - 1)
            }
        } else {
            setFirstLineTagCount(firstLineTagCountToBe)
        }
        onChangeValueOnceFromCollapsed.current?.execCallback('')
    }, [firstLineTagCount, getTextboxElement, isCollapsed, resetTextboxWidth, tags.length, tagsForRender.length])

    useMount(updateTagMaxWidth)

    useUpdateEffect(() => {
        scrollViewRef.current?.updateView()
    }, [tags.length])

    useUpdateEffect(() => {
        if (interactiveKey === -1) {
            getTextboxElement()?.focus()
        }
    }, [getTextboxElement, interactiveKey])

    useMount(() => autoFocus && getTextboxElement()?.focus())

    useImperativeHandle(ref, () => ({ appendTag, deleteAllTags }), [appendTag, deleteAllTags])

    return (
        <div
            ref={rootRef}
            style={rootStyle}
            className={classnames(classes.root, [className], {
                [classes.autoHeight]: autoHeight,
                [classes.isFocusTextbox]: isFocusTextbox,
                [classes.hasTag]: !!tagsForRender.length,
                [classes.disabled]: disabled,
            })}
            tabIndex={-1}
            onClick={onClickRoot}
            onFocus={onFocusRoot}
            onBlur={onBlurRoot}
            onKeyDown={onKeyDownRoot}
            data-testid={dataTestIds?.root}
        >
            <div className={classes.editArea}>
                <div className={classes.hoverLayer}>
                    <ScrollView
                        selectKey={interactiveKey}
                        block="nearest"
                        ref={scrollViewRef}
                        scrollbar={{
                            autoHeight: true,
                            autoHeightMin: definedMinHeight,
                            autoHeightMax: autoHeight ? definedMaxHeight : definedMinHeight,
                            hideScrollbar: !autoHeight,
                        }}
                        className={classes.left}
                        isHorizontal={!autoHeight}
                    >
                        <div className={classes.content} style={{ minHeight: definedMinHeight }} ref={contentRef}>
                            <Resize onChangeResize={updateTagMaxWidth} />
                            {!tagsForRender.length && !textboxHasValue && placeholder ? (
                                <div className={classes.placeholder}>{placeholder}</div>
                            ) : null}
                            {tagsForRender.map((tag, index) => (
                                <TagItem
                                    className={flagForCollapse}
                                    name={tag.name}
                                    color={tag.color}
                                    key={index}
                                    index={index}
                                    maxWidth={isCollapsed && tagMaxWidth ? tagMaxWidth - cutTagMaxWidth : tagMaxWidth}
                                    interactiveKey={interactiveKey}
                                    disabled={disabled}
                                    onDelete={deleteTag}
                                    onSelect={setInteractiveKey}
                                />
                            ))}
                            <ScrollView.Item
                                uniqueKey={-1}
                                className={classes.textboxArea}
                                ref={textboxAreaRef}
                                style={isCollapsed ? { pointerEvents: 'none' } : undefined}
                            >
                                {autoHeight ? (
                                    <HtmlTextarea
                                        ref={htmlTextareaRef}
                                        autoHeight
                                        autoHeightBaseHeight={textareaLineHeight}
                                        className={classes.textarea}
                                        disabled={disabled}
                                        data-testid={dataTestIds?.input}
                                        onKeyDown={onKeyDownTextbox}
                                        onFocus={onFocusTextbox}
                                        onBlur={onBlurTextbox}
                                        onInput={onInputTextarea}
                                        onChange={onChangeTextbox}
                                    ></HtmlTextarea>
                                ) : (
                                    <HtmlInput
                                        ref={htmlInputRef}
                                        className={classes.input}
                                        disabled={disabled}
                                        onKeyDown={onKeyDownTextbox}
                                        data-testid={dataTestIds?.input}
                                        onFocus={onFocusTextbox}
                                        onBlur={onBlurTextbox}
                                        onChange={onChangeTextbox}
                                        onInput={onInputInput}
                                    />
                                )}
                            </ScrollView.Item>
                        </div>
                    </ScrollView>
                    <div className={classes.right} onMouseDown={(e) => e.preventDefault()}>
                        {tool ? (
                            tool
                        ) : tags.length ? (
                            <MonoIconCommonError16
                                onClick={deleteAllTags}
                                className={classes.clearIcon}
                                data-testid={dataTestIds?.clearIcon}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
            <div className={classes.selectList}>
                {isShowTipMessage ? (
                    <PopUpSelectList {...listProps} ref={popUpSelectListRef} onSelectItem={onSelectItem} />
                ) : null}
            </div>
        </div>
    )
}

export const TagInput = forwardRef(_TagInput) as <K = undefined, T = any>(
    props: TagInputProps<K, T> & { ref?: React.Ref<TagInputRef<K>> }
) => React.ReactElement
