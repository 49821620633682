import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    Search: '搜索',
    SearchResult: '搜索结果',
    ComponentNotFound: '没有找到该组件',
    ShowAsList: '列表视图',
    ShowAsGrid: '网格视图',
    NoLocalComponents: '本地没有组件',
    BrowseLibraries: '浏览团队组件库',
} as const

export const enTranslation = {
    Search: 'Search',
    SearchResult: 'Search results',
    ComponentNotFound: 'No results found',
    ShowAsList: 'Show as list',
    ShowAsGrid: 'Show as grid',
    NoLocalComponents: 'No components',
    BrowseLibraries: 'Browse libraries',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
