/* eslint-disable no-restricted-imports */
import { useSelectionService } from '../../../main/app-context'
/**
 * @deprecated 不要在使用了，这个不会响应撤销
 */
export function useSelectionState() {
    const selectionService = useSelectionService()
    const changeSelectionInfo = selectionService.states.use.changeSelectionInfo()
    return { changeSelectionInfo }
}
