import { useCallback, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { Step } from './step'
import { TOUR_CONTAINER_CLASSNAME, WKTourProps } from './type'

export const WKTour = (props: WKTourProps) => {
    const { open, zIndex, steps, current, draggable, theme, showArrow, hideProgress, onClose, onChange, onFinish } =
        props
    const [tourContainer, setTourContainer] = useState<HTMLDivElement>()
    const step = steps[current]

    const onClickNext = useCallback(() => {
        const nextIndex = current + 1
        onChange?.(nextIndex)

        if (nextIndex === steps.length) {
            onFinish?.()
        }
    }, [current, steps, onChange, onFinish])

    const onClickPrev = useCallback(() => {
        const prevIndex = current - 1
        onChange?.(prevIndex)
    }, [current, onChange])

    const getTourContainer = useCallback(() => {
        if (!tourContainer) {
            const container = document.querySelector(`.${TOUR_CONTAINER_CLASSNAME}`) as HTMLDivElement
            if (container) {
                setTourContainer(container)
                return container
            } else {
                const div = document.createElement('div')
                div.classList.add(TOUR_CONTAINER_CLASSNAME)
                document.body.appendChild(div)
                setTourContainer(div)
                return div
            }
        }
        return tourContainer
    }, [tourContainer])

    const renderTour = useMemo(() => {
        if (!open || !step) {
            return null
        }

        const content = (
            <Step
                key={`tour-${current}`}
                showArrow={showArrow}
                {...step}
                current={current}
                length={steps.length}
                zIndex={zIndex}
                draggable={draggable}
                theme={theme}
                hideProgress={hideProgress}
                onClickNext={onClickNext}
                onClickPrev={onClickPrev}
                onClose={() => {
                    onClose?.(current)
                }}
            />
        )

        return createPortal(content, getTourContainer())
    }, [
        open,
        step,
        current,
        steps,
        zIndex,
        draggable,
        theme,
        showArrow,
        hideProgress,
        onClickNext,
        onClose,
        getTourContainer,
        onClickPrev,
    ])

    return <>{renderTour}</>
}
