import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    EnterPassword: '输入密码',
} as const

export const enTranslation = {
    EnterPassword: 'Password',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
