import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    ThisPluginLets: '本插件用于编辑矢量图形的填充规则和路径方向，填充规则即用于确定矢量图形的被填充区域的规则。',
    'Even-oddRule': '奇偶规则',
    'Non-zeroRule': '非零规则',
    CertainExportFormats:
        '若将矢量图形直接导出到填充规则不同的应用中，其填充区域可能变化。由于 {{name}}默认应用「奇偶规则」，我们可使用本插件进行预处理，以保证导出的文件在「非零规则」的平台中正常显示。',
    ToUse: '使用方法',
    SelectAVector: '选中一个矢量图形；',
    ClickOnA: '在插件面板中点击形状填充区域以切换填充规则；',
    ClickOnA_synonyms1:
        '切换至「非零规则」后，点击面板中填充效果不符合预期的区域的轮廓，以切换其路径方向，即可让填充效果看起来正常。',
    FillRuleEditor: '填充规则编辑',
} as const

export const enTranslation = {
    ThisPluginLets:
        'This plugin lets you edit the fill rules of a vector object. Fill rules determine which parts of a vector outline are filled. The fill rules have different behavior when a vector outline overlaps:',
    'Even-oddRule': 'Even-odd rule',
    'Non-zeroRule': 'Non-zero rule',
    CertainExportFormats:
        'Certain export formats only support the non-zero fill rule. You can use this plugin to manually convert even-odd to non-zero to make the exporters for these formats work.',
    ToUse: 'To use',
    SelectAVector: 'Select a vector object.',
    ClickOnA: 'Click on a fill to toggle between none-zero and even-odd.',
    ClickOnA_synonyms1:
        'Click on a loop to reverse the orientation, which is sometimes necessary to get holes to look correct.',
    FillRuleEditor: 'Fill rule editor',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
