import { translation } from './index.translation'

import {
    AimLibraryNodeWasmCall,
    ChangeLibraryPublishModalControlExpandCommand,
    LibraryPublishModalPublishCheckedCommand,
    UpdateLibraryPublishModalDescriptionCommand,
    UpdateLibraryPublishModalFilterTextCommand,
    UpdateLibraryPublishModalScrollableSizeCommand,
    UpdateLibraryPublishModalScrollTopCommand,
    Wukong,
} from '@wukong/bridge-proto'
import classNames from 'classnames'
import { isNil } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { InputV2, Scrollbar, WKButton, WKDialog, WKIconButtonProps } from '../../../../../../ui-lib/src'
import { useElementResize } from '../../../../../../util/src'
import { NodeId } from '../../../../document/node/node'
import { useCommand, useLibraryComponentService, usePlanAndUserStateService } from '../../../../main/app-context'
import { useDocFolderTeamData } from '../../../../main/layout/layout-context'
import { PayUpgradePublishLibrary } from '../../../../share/payment/pay-upgrade-scene-modal'
import { isStarterPlan } from '../../../../utils/plan-status-checker'
import { useViewState } from '../../../../view-state-bridge'
import { LibraryTestId } from '../../../../window'
import { useLibraryModalConfigContext } from '../../../context/library-component-style/library-modal-config'
import { useVirtualListContainer } from '../../../hooks/virtual-list'
import { ChangeSelectListV2, HiddenList, InvalidList, UnchangeListV2, VariableDetail } from './components/list'
import { ShareScopeSelect } from './components/share-scope'
import styles from './index.module.less'

export function CurrentDocLibraryPublishModal() {
    const { docData, teamData } = useDocFolderTeamData()
    const {
        states,
        libraryModalRouterService: { closeLibraryPublishModal, closeLibraryAllModal },
    } = useLibraryComponentService()
    const { resizeHeight } = useElementResize({ height: 596, minHeight: 200, otherHeight: 204 })
    const command = useCommand()
    const currentDocRemoteLibraryContent = states.use.currentDocRemoteLibraryContentState()
    const [hasPublished, setHasPublished] = useState(false)
    const viewState = useViewState('publishModal')
    const [showVariableSetDetail, setShowVariableSetDetail] = useState<
        Wukong.DocumentProto.IVPublishModalChangeItem | Wukong.DocumentProto.IVPublishModalCommonItem | null
    >(null)
    const {
        publishModalConfig: { show: visible },
    } = useLibraryModalConfigContext()
    const filterText = viewState?.filterText ?? ''
    const isNotResult = Boolean(
        viewState?.filterText &&
            !viewState.hasChanges &&
            !viewState.hasUnchanges &&
            !viewState.hasInvalids &&
            !viewState.hasHiddens
    )

    useEffect(() => {
        if (!viewState?.publishing) {
            setHasPublished(!!currentDocRemoteLibraryContent?.library.shared)
        }
    }, [currentDocRemoteLibraryContent, viewState?.publishing, visible])

    const handleResize = useCallback(
        (width: number, height: number) => {
            // 必须加 setTimeout（否则右边 3 个步骤会造成 wasm crash：wasm->js 虚拟滚动内容，js->wasm 动态调整窗口尺寸，wasm->js 虚拟滚动内容）
            setTimeout(() => {
                command.DEPRECATED_invokeBridge(
                    UpdateLibraryPublishModalScrollableSizeCommand,
                    Wukong.DocumentProto.Arg_UpdateLibraryPublishModalScrollableSize.create({
                        width,
                        height,
                    })
                )
            }, 0)
        },
        [command]
    )

    const handleScroll = useCallback(
        (scrollTop: number) => {
            command.DEPRECATED_invokeBridge(
                UpdateLibraryPublishModalScrollTopCommand,
                Wukong.DocumentProto.Arg_UpdateLibraryPublishModalScrollTop.create({
                    value: scrollTop,
                })
            )
        },
        [command]
    )

    const { ref: containerRef } = useVirtualListContainer({
        onResize: handleResize,
        onScroll: handleScroll,
        deps: [visible, isNotResult],
    })

    const handleFilterChange = useCallback(
        (v: string) => {
            command.DEPRECATED_invokeBridge(
                UpdateLibraryPublishModalFilterTextCommand,
                Wukong.DocumentProto.BridgeProtoString.create({
                    value: v,
                })
            )
        },
        [command]
    )

    const onTextareaChange = useCallback(
        (v: string) => {
            command.DEPRECATED_invokeBridge(
                UpdateLibraryPublishModalDescriptionCommand,
                Wukong.DocumentProto.BridgeProtoString.create({
                    value: v,
                })
            )
        },
        [command]
    )

    const [shareScopeType, setShareScopeType] = useState(
        Wukong.DocumentProto.LibraryShareScopeType.LIBRARY_SHARE_SCOPE_TYPE_TEAM
    )
    useEffect(() => {
        const value = viewState?.shareScopeType
        if (!isNil(value)) {
            setShareScopeType(value)
        }
    }, [viewState?.shareScopeType])

    const planAndUserStateService = usePlanAndUserStateService()
    const planInfo = planAndUserStateService.useZustandStore.use.planAndUserState()
    const onPublish = useCallback(() => {
        if (planInfo && isStarterPlan(planInfo)) {
            return PayUpgradePublishLibrary({
                teamId: planInfo?.resourceId,
            })
        }
        command.DEPRECATED_invokeBridge(LibraryPublishModalPublishCheckedCommand, { shareScopeType: shareScopeType })
    }, [command, shareScopeType, planInfo])

    const onClickAim = useCallback(
        (targetId: NodeId) => {
            command.DEPRECATED_invokeBridge(
                AimLibraryNodeWasmCall,
                Wukong.DocumentProto.Arg_AimLibraryNode.create({
                    id: targetId,
                })
            )
            closeLibraryAllModal()
        },
        [command, closeLibraryAllModal]
    )
    const showDetail = useCallback(
        (change: Wukong.DocumentProto.IVPublishModalChangeItem | Wukong.DocumentProto.IVPublishModalCommonItem) => {
            setShowVariableSetDetail(change)
        },
        []
    )

    useEffect(() => {
        if (!visible) {
            setShowVariableSetDetail(null)
        }
    }, [visible])

    const variableSetToVariableChangeMap = useMemo(() => {
        const map: Record<string, Wukong.DocumentProto.IVPublishModalChangeItem[]> = {}
        for (const change of viewState?.variableChanges ?? []) {
            if (change.thumbnailData?.type === Wukong.DocumentProto.NodeType.NODE_TYPE_VARIABLE) {
                if (!change.variableSetId) {
                    continue
                }
                if (!map[change.variableSetId]) {
                    map[change.variableSetId] = []
                }
                map[change.variableSetId].push(change)
            }
        }
        return map
    }, [viewState?.variableChanges])

    const variableSetToVariableHiddenMap = useMemo(() => {
        const map: Record<string, Wukong.DocumentProto.IVPublishModalCommonItem[]> = {}
        for (const hidden of viewState?.variableHiddens ?? []) {
            if (hidden.thumbnailData?.type === Wukong.DocumentProto.NodeType.NODE_TYPE_VARIABLE) {
                if (!hidden.variableSetId) {
                    continue
                }
                if (!map[hidden.variableSetId]) {
                    map[hidden.variableSetId] = []
                }
                map[hidden.variableSetId].push(hidden)
            }
        }
        return map
    }, [viewState?.variableHiddens])

    return visible ? (
        <WKDialog
            cancelIconProps={
                {
                    ['data-testid']: LibraryTestId.PublishModal.CloseBtn,
                } as Partial<WKIconButtonProps>
            }
            className={styles.container}
            width={464}
            visible
            title={hasPublished ? translation('PublishLibrary') : translation('PublishLibrary_synonyms1')}
            bodyStyle={{ padding: 0 }}
            footer={
                <div className={styles.footer}>
                    {!filterText && (
                        <div style={{ marginBottom: 16 }}>
                            <InputV2.Textarea
                                height={52}
                                onChange={(e) => onTextareaChange((e.target as HTMLTextAreaElement).value)}
                                placeholder={translation('DescriptionOfChanges')}
                            />
                        </div>
                    )}
                    <div className={styles.operation}>
                        {docData && (
                            <div className={classNames(styles.leftside, 'overflow-hidden')}>
                                <div className={`${styles.towhom} ${styles.towhomSmallMarginBottom}`}>
                                    <ShareScopeSelect
                                        docId={docData.id}
                                        orgId={docData.orgId}
                                        teamPublicStatus={teamData?.publicStatus}
                                        selected={shareScopeType}
                                        onChange={setShareScopeType}
                                    />
                                </div>
                            </div>
                        )}
                        <div className={styles.rightside}>
                            <WKButton type="secondary" className={styles.button} onClick={closeLibraryPublishModal}>
                                {translation('Cancel')}
                            </WKButton>
                            <WKButton
                                loading={viewState?.publishing}
                                disabled={!viewState?.checkedCount}
                                className={styles.button}
                                type="primary"
                                onClick={onPublish}
                                data-testid={LibraryTestId.PublishModal.SubmitBtn}
                            >
                                {hasPublished ? translation('Publish2') : translation('Publish')}
                            </WKButton>
                        </div>
                    </div>
                </div>
            }
            onCancel={closeLibraryPublishModal}
        >
            <div
                className={styles.body}
                style={{ height: `${resizeHeight}px` }}
                data-testid={LibraryTestId.PublishModal.Body}
            >
                {showVariableSetDetail ? (
                    <></>
                ) : (
                    <div className={styles.search}>
                        <InputV2.Search
                            placeholder={translation('Search')}
                            onSearch={handleFilterChange}
                            onBlur={(e) => handleFilterChange(e.target.value.trim())}
                            dataTestIds={{ clearIcon: 'icon-close-svg' }}
                        />
                    </div>
                )}

                {!isNotResult ? (
                    <div className={`${styles.list} ${showVariableSetDetail && styles.noPaddingTop}`}>
                        <Scrollbar className={styles.scrollContainer} ref={containerRef}>
                            {showVariableSetDetail ? (
                                <div className={styles.scrollContainerScrollable}>
                                    <VariableDetail
                                        variableSet={showVariableSetDetail}
                                        onBack={() => setShowVariableSetDetail(null)}
                                        variableChanges={
                                            variableSetToVariableChangeMap[showVariableSetDetail.nodeId] ?? []
                                        }
                                        variableHiddens={
                                            variableSetToVariableHiddenMap[showVariableSetDetail.nodeId] ?? []
                                        }
                                    />
                                </div>
                            ) : (
                                <div
                                    className={styles.scrollContainerScrollable}
                                    style={{
                                        height: viewState?.containerHeight,
                                    }}
                                >
                                    <InvalidList
                                        setCollapse={(val) => {
                                            command.DEPRECATED_invokeBridge(
                                                ChangeLibraryPublishModalControlExpandCommand,
                                                Wukong.DocumentProto.Arg_ChangeLibraryPublishModalControlExpand.create({
                                                    type: Wukong.DocumentProto.LibraryPublishModalControlType
                                                        .LIBRARY_PUBLISH_MODAL_CONTROL_TYPE_INVALID,
                                                    expand: val,
                                                })
                                            )
                                        }}
                                        filterText={filterText}
                                        displayList={viewState?.invalids ?? []}
                                        onClickAim={onClickAim}
                                        control={viewState?.invalidControl}
                                        hasContent={viewState?.hasInvalids}
                                    />
                                    <ChangeSelectListV2
                                        setCollapse={(val) => {
                                            command.DEPRECATED_invokeBridge(
                                                ChangeLibraryPublishModalControlExpandCommand,
                                                Wukong.DocumentProto.Arg_ChangeLibraryPublishModalControlExpand.create({
                                                    type: Wukong.DocumentProto.LibraryPublishModalControlType
                                                        .LIBRARY_PUBLISH_MODAL_CONTROL_TYPE_CHANGED,
                                                    expand: val,
                                                })
                                            )
                                        }}
                                        showDetailFn={showDetail}
                                        filterText={filterText}
                                        changes={viewState?.changes ?? []}
                                        allChangeCount={viewState?.allChangeCount ?? 0}
                                        checkCount={viewState?.checkedCount ?? 0}
                                        control={viewState?.changedControl}
                                        variableSetTitle={viewState?.changedVariableSetTitle}
                                        styleTitle={viewState?.changedStyleTitle}
                                        componentTitle={viewState?.changedComponentTitle}
                                        hasContent={viewState?.hasChanges}
                                        variableSetToVariableChangeMap={variableSetToVariableChangeMap}
                                        variableSetToVariableHiddenMap={variableSetToVariableHiddenMap}
                                    />
                                    <UnchangeListV2
                                        setCollapse={(val) => {
                                            command.DEPRECATED_invokeBridge(
                                                ChangeLibraryPublishModalControlExpandCommand,
                                                Wukong.DocumentProto.Arg_ChangeLibraryPublishModalControlExpand.create({
                                                    type: Wukong.DocumentProto.LibraryPublishModalControlType
                                                        .LIBRARY_PUBLISH_MODAL_CONTROL_TYPE_UNCHANGE,
                                                    expand: val,
                                                })
                                            )
                                        }}
                                        filterText={filterText}
                                        list={viewState?.unchanges ?? []}
                                        control={viewState?.unchangeControl}
                                        hasContent={viewState?.hasUnchanges}
                                        showDetailFn={showDetail}
                                        variableSetToVariableHiddenMap={variableSetToVariableHiddenMap}
                                    />
                                    <HiddenList
                                        setCollapse={(val) => {
                                            command.DEPRECATED_invokeBridge(
                                                ChangeLibraryPublishModalControlExpandCommand,
                                                Wukong.DocumentProto.Arg_ChangeLibraryPublishModalControlExpand.create({
                                                    type: Wukong.DocumentProto.LibraryPublishModalControlType
                                                        .LIBRARY_PUBLISH_MODAL_CONTROL_TYPE_HIDDEN,
                                                    expand: val,
                                                })
                                            )
                                        }}
                                        filterText={filterText}
                                        list={viewState?.hiddens ?? []}
                                        control={viewState?.hiddenControl}
                                        tipControl={viewState?.hiddenTip}
                                        hasContent={viewState?.hasHiddens}
                                    />
                                </div>
                            )}
                        </Scrollbar>
                    </div>
                ) : (
                    <div className={styles.empty}>{translation('NoResultsFor', { filterText })}</div>
                )}
            </div>
        </WKDialog>
    ) : (
        <></>
    )
}
