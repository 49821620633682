import {
    AttachCornerRadiusFloatVarCommand,
    DetachCornerRadiusFloatVarCommand,
    ExpandSelectionIndependentCornerRadiusCommand,
    UpdateSelectionCornerRadiusCommand,
    UpdateSelectionIndependentCornerRadiusCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { isEqual, isNil } from 'lodash-es'
import { useMemo } from 'react'
import { CommitType } from '../../../../document/command/commit-type'
import { cmdChangePopupState } from '../../../../document/command/document-command'
import { PopupStateType } from '../../../../document/node/node'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { Value } from '../../atom/inputs/utils/type'
import { getFieldFloatVariable } from '../../variable/float-variable/utils'

export function useCornerRadius() {
    const command = useCommand()

    const cornerRadius = useViewState('cornerRadius')
    const cornerSmoothing = useViewState('cornerSmoothing')
    const independentCornerRadius = useViewState('independentCornerRadius')
    const defaultCornerRadius = { mixed: false, value: 0 }
    const topLeftRadius = independentCornerRadius?.site1 || defaultCornerRadius
    const topRightRadius = independentCornerRadius?.site2 || defaultCornerRadius
    const bottomRightRadius = independentCornerRadius?.site3 || defaultCornerRadius
    const bottomLeftRadius = independentCornerRadius?.site4 || defaultCornerRadius

    const variableConsumptionMapState = useViewState('variableConsumptionMapState')
    const topLeftRadiusVariable = useMemo(() => {
        if (topLeftRadius.mixed) {
            return
        }
        const floatVariable = getFieldFloatVariable(
            Wukong.DocumentProto.VariableField.RECTANGLE_TOP_LEFT_CORNER_RADIUS,
            variableConsumptionMapState
        )
        return floatVariable
    }, [variableConsumptionMapState, topLeftRadius.mixed])
    const topRightRadiusVariable = useMemo(() => {
        if (topRightRadius.mixed) {
            return
        }
        const floatVariable = getFieldFloatVariable(
            Wukong.DocumentProto.VariableField.RECTANGLE_TOP_RIGHT_CORNER_RADIUS,
            variableConsumptionMapState
        )
        return floatVariable
    }, [variableConsumptionMapState, topRightRadius.mixed])
    const bottomRightRadiusVariable = useMemo(() => {
        if (bottomRightRadius.mixed) {
            return
        }
        const floatVariable = getFieldFloatVariable(
            Wukong.DocumentProto.VariableField.RECTANGLE_BOTTOM_RIGHT_CORNER_RADIUS,
            variableConsumptionMapState
        )
        return floatVariable
    }, [variableConsumptionMapState, bottomRightRadius.mixed])
    const bottomLeftRadiusVariable = useMemo(() => {
        if (bottomLeftRadius.mixed) {
            return
        }
        const floatVariable = getFieldFloatVariable(
            Wukong.DocumentProto.VariableField.RECTANGLE_BOTTOM_LEFT_CORNER_RADIUS,
            variableConsumptionMapState
        )
        return floatVariable
    }, [variableConsumptionMapState, bottomLeftRadius.mixed])
    const cornerRadiusVariable = useMemo(() => {
        if (cornerRadius?.mixed) {
            return
        }
        const base = getFieldFloatVariable(
            Wukong.DocumentProto.VariableField.CORNER_RADIUS,
            variableConsumptionMapState
        )
        if (
            !base &&
            isEqual(topLeftRadiusVariable, topRightRadiusVariable) &&
            isEqual(topLeftRadiusVariable, bottomRightRadiusVariable) &&
            isEqual(topLeftRadiusVariable, bottomLeftRadiusVariable)
        ) {
            return topLeftRadiusVariable
        } else {
            return base
        }
    }, [
        cornerRadius?.mixed,
        topLeftRadiusVariable,
        topRightRadiusVariable,
        bottomRightRadiusVariable,
        bottomLeftRadiusVariable,
        variableConsumptionMapState,
    ])

    const setNodeProperty = (key: string, v: number | string | boolean | null, options?: InputOptionsForUndoSquash) => {
        const commitType = options?.commitType ?? CommitType.CommitUndo
        switch (key) {
            case 'cornerRadius': {
                if (!isNil(v)) {
                    command.invokeBridge(commitType, UpdateSelectionCornerRadiusCommand, {
                        value: Number(v),
                    })
                }
                break
            }
            case 'independentCorners': {
                command.invokeBridge(commitType, ExpandSelectionIndependentCornerRadiusCommand, { value: !!v })
                break
            }
            case 'topLeftRadius': {
                if (!isNil(v)) {
                    command.invokeBridge(commitType, UpdateSelectionIndependentCornerRadiusCommand, {
                        type: 'site1',
                        value: Number(v),
                    })
                }
                break
            }
            case 'topRightRadius': {
                if (!isNil(v)) {
                    command.invokeBridge(commitType, UpdateSelectionIndependentCornerRadiusCommand, {
                        type: 'site2',
                        value: Number(v),
                    })
                }
                break
            }
            case 'bottomLeftRadius': {
                if (!isNil(v)) {
                    command.invokeBridge(commitType, UpdateSelectionIndependentCornerRadiusCommand, {
                        type: 'site4',
                        value: Number(v),
                    })
                }
                break
            }
            case 'bottomRightRadius': {
                if (!isNil(v)) {
                    command.invokeBridge(commitType, UpdateSelectionIndependentCornerRadiusCommand, {
                        type: 'site3',
                        value: Number(v),
                    })
                }
                break
            }
            case 'cornerSmoothing': {
                const type = v ? PopupStateType.POPUP_STATE_TYPE_CORNER_SMOOTHING : PopupStateType.POPUP_STATE_TYPE_NONE
                command.invoke(cmdChangePopupState, {
                    type,
                    reciprocalIndex: -1,
                    multiPopup: [],
                })
                break
            }
        }
    }

    const onChange = (key: string) => (v: number | string | boolean, options?: InputOptionsForUndoSquash) => {
        setNodeProperty(key, v, options)
    }

    const onChangeNew = (key: string) => {
        return (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value === 'number') {
                setNodeProperty(key, value, options)
            }
        }
    }

    const onAttachFloatVar = (id: string, field: Wukong.DocumentProto.VariableField) => {
        const floatVar: Wukong.DocumentProto.IVariableAliasData = {
            dataType: Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_ALIAS,
            resolvedDataType: Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT,
            value: { alias: id },
        }

        command.invokeBridge(CommitType.CommitUndo, AttachCornerRadiusFloatVarCommand, {
            field,
            variableData: floatVar,
        })
    }

    const onDetachFloatVar = (field: Wukong.DocumentProto.VariableField) => {
        command.invokeBridge(CommitType.CommitUndo, DetachCornerRadiusFloatVarCommand, {
            field,
        })
    }

    return {
        cornerRadius,
        cornerSmoothing,
        independentCornerRadius,
        topLeftRadius,
        topRightRadius,
        bottomLeftRadius,
        bottomRightRadius,
        cornerRadiusVariable,
        topLeftRadiusVariable,
        topRightRadiusVariable,
        bottomRightRadiusVariable,
        bottomLeftRadiusVariable,
        onChange,
        onChangeNew,
        onAttachFloatVar,
        onDetachFloatVar,
    }
}
