import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    AddEmoji: '表情',
    Cancel: '取消',
    Save: '保存',
    Submit: '发送',
} as const

export const enTranslation = {
    AddEmoji: 'Add emoji',
    Cancel: 'Cancel',
    Save: 'Save',
    Submit: 'Submit',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
