import { forwardRef, useImperativeHandle, useRef } from 'react'
import { ExplicitUndefined } from '../../../utils/explicit-undefined'
import { useCommentSelect } from '../../selects/select/use-comment-select'
import { SelectOption } from '../picks/single-level/select-option'
import { SingleLevel, SingleLevelProps, SingleLevelRef } from '../picks/single-level/single-level'
import { TriggerIcon, TriggerIconRef } from '../triggers/trigger-icon'
import { DropdownSingleLevelProps, DropdownTriggerIconProps } from './type'

export interface DropdownIconSingleLevelRef {
    close: () => void
    open: () => void
    getTriggerElement: () => HTMLElement | null | undefined
}

export type DropdownIconSingleLevelProps = DropdownTriggerIconProps & DropdownSingleLevelProps

function _DropdownIconSingleLevel(props: DropdownIconSingleLevelProps, ref?: React.Ref<DropdownIconSingleLevelRef>) {
    const pickRef = useRef<SingleLevelRef>(null)
    const triggerRef = useRef<TriggerIconRef>(null)
    const {
        pickRest,
        placement,
        triggerRect,
        worldRect,
        isMinWidthFromTrigger = true,
        maxWidth,
        removeMask,
        removeTopPadding,
        removeBottomPadding,
        onKeyboard,
        onChange,
        onClose,
        onOpen,
        onChangePreselect,
        ...otherProps
    } = props

    const commentSelectState = useCommentSelect({
        triggerRect,
        triggerRef,
        pickRef,
        onOpen,
        onClose,
    })
    useImperativeHandle(
        ref,
        () => ({
            close: () => pickRef.current?.close(),
            open: () => pickRef.current?.open(),
            getTriggerElement: () => triggerRef.current?.getTriggerElement(),
        }),
        []
    )

    return (
        <TriggerIcon
            ref={triggerRef}
            {...otherProps}
            isOpenPick={commentSelectState.isOpenPick}
            openAction={commentSelectState.openAction}
            closeAction={commentSelectState.closeAction}
        >
            <SingleLevel<ExplicitUndefined<SingleLevelProps>>
                pickRest={pickRest}
                optionTemplate={SelectOption}
                dataTestIds={props.dataTestIds}
                removeMask={removeMask}
                triggerRect={commentSelectState.triggerRect}
                closeByESC={commentSelectState.closeByESC}
                onClose={commentSelectState.onSelectClose}
                onOpen={commentSelectState.onSelectOpen}
                ref={pickRef}
                placement={placement}
                worldRect={worldRect}
                isMinWidthFromTrigger={isMinWidthFromTrigger}
                maxWidth={maxWidth}
                onKeyboard={onKeyboard}
                onChange={onChange}
                onChangePreselect={onChangePreselect}
                disableDefaultPreselect={true}
                getPortalElement={commentSelectState.getPortalElement}
                isAllowEmptyOption
                removeTopPadding={removeTopPadding}
                removeBottomPadding={removeBottomPadding}
                isOpenState={undefined}
                openStateToBeFalse={undefined}
                openStateToBeTrue={undefined}
                isMixed={undefined}
                minWidth={undefined}
                width={undefined}
                value={undefined}
                mainValue={undefined}
                autoAdjustTop={undefined}
            >
                {props.children}
            </SingleLevel>
        </TriggerIcon>
    )
}

const forwardRefComponent = forwardRef(_DropdownIconSingleLevel)

export const DropdownIconSingleLevel: typeof forwardRefComponent & { Option: typeof SelectOption } = Object.assign(
    forwardRefComponent,
    {
        Option: SelectOption,
    }
)
