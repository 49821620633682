/* eslint-disable no-restricted-imports */
import constate from 'constate'
import { useState } from 'react'

export const LibraryAll = 'library-all'
export const LibraryLocal = 'library-local'

function useEditorContextState() {
    const [libraryPreselected, setLibraryPreselected] = useState('')
    return {
        colorLibraryPreselected: libraryPreselected,
        setColorLibraryPreselected: setLibraryPreselected,

        // NOTE: 共享组件库弹窗选择记录
        libraryPreselected,
        setLibraryPreselected,
    } as const
}

export const [EditorContextProvider, useEditorContext] = constate(useEditorContextState)
