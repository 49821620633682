import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    SendSucceeded: '提交成功',
    SendUsA: '给我们留言',
    Email: '请输入邮箱',
    InvalidEmail: '邮箱格式错误',
    YourMessage: '请输入留言内容',
    Send: '提交',
    ContactUs: '添加 {{name}}团队企业微信',
    qrCodeAlt: '联系运维',
} as const

export const enTranslation = {
    SendSucceeded: 'Feedback submitted',
    SendUsA: 'Send feedback',
    Email: 'Email',
    InvalidEmail: 'Please provide a valid email address',
    YourMessage: 'Describe your feedback here',
    Send: 'Send',
    ContactUs: 'Contact us',
    qrCodeAlt: '',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
