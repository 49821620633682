import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    xzs: '选择适合你的计划',

    me: '基础设计功能',
    upf: '个人文件：不限数量',
    mf: '可协作文件：3 个',
    dvh: '文件历史版本：30 天',
    bi: '基础标注功能（研发可查看）',

    umf: '可协作文件：不限数量',
    uvh: '文件历史版本：永久',
    tcl: '团队组件库',
    spp: '私密项目和文件',
    dm: '研发模式',

    es: '包含基础版所有能力，以及',
    ut: '企业内团队：不限数量',
    ads: '企业级设计系统',
    sft: '团队共享字体',
    ca: '企业级账号与权限管理',
    ccm: '中心化资产管理',
    al: '活动日志',

    ep: '包含专业版所有能力，以及',
    fn: '限时免费',
    tm: '{{times}} 次/月',
    tat: 'AI 工具箱',
    ar: 'AI 复制',
    ala: 'AI 布局',
    tds: 'AI 设计系统',
    ad: 'AI 设计系统创建',
    ds: 'AI 设计系统维护',
    ac: 'AI 一致性检查',
    tml: 'Motiff 妙多实验室',
    ag: 'AI 生成 UI',
    am: 'AI 魔法框',
} as const

export const enTranslation = {
    xzs: "Choose a plan that's right for you",

    me: 'Motiff editor',
    upf: 'Unlimited personal files',
    mf: '3 Motiff files',
    dvh: '30 days version history',
    bi: 'Basic inspection',

    umf: 'Unlimited Motiff files',
    uvh: 'Unlimited version history',
    tcl: 'Team component library',
    spp: 'Shared and private projects',
    dm: 'Develop Mode',

    es: 'Everything in Starter, plus',
    ut: 'Unlimited teams',
    ads: 'Advanced design systems',
    sft: 'Shared fonts for teams',
    ca: 'Centralized administration',
    ccm: 'Centralized content management',
    al: 'Activity logs',

    ep: 'Everything in Professional, plus',
    fn: 'Free now',
    tm: '{{times}} times/month',
    tat: 'AI Toolbox',
    ar: 'AI Reduplication',
    ala: 'AI Layout',
    tds: 'AI Design System',
    ad: 'AI Design System Creator',
    ds: 'AI Design System Maintainer',
    ac: 'AI Consistency Checker',
    tml: 'Motiff Lab',
    ag: 'AI Generates UI',
    am: 'AI Magic Box',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
