import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    DevelopPrivatePluginPanel: '开发企业私有插件',
    OpenOrCloseConsole: '打开/关闭控制台',
    AdvancedOptions: '高级选项',
    UseDeveloperVM: '使用开发人员 VM',
    HotReload: '启用热重载',
    MoreInformation: '插件开发问题可查阅 ',
    DeveloperDocument: '{{name}}开发者文档',
    CreatePlugin: '新建插件',
    ImportPlugin: '导入插件',
    NoPlugin: '暂无插件',
} as const

export const enTranslation = {
    DevelopPrivatePluginPanel: 'Develop private organization plugin',
    OpenOrCloseConsole: 'Show/Hide console',
    AdvancedOptions: 'Advanced options',
    UseDeveloperVM: 'Use developer VM',
    HotReload: 'Hot reload plugin',
    MoreInformation: 'More information can be found in ',
    DeveloperDocument: 'the {{name}} Developer Guide',
    CreatePlugin: 'Create new plugin',
    ImportPlugin: 'Import plugin',
    NoPlugin: 'No plugins',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
