import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    'Tech Analyst': '技术分析师',
    Dashboard: '仪表板',
    Overview: '概述',
    Customers: '客户',
    Products: '产品',
    Settings: '设置',
    Search: '搜索',
    Download: '下载',
    'Jan 20, 2023 - Feb 09, 2023': '2023年1月20日 - 2023年2月9日',
    Analytics: '分析',
    Reports: '报告',
    Notifications: '通知',
    Subscriptions: '订阅',
    Sales: '销售',
    'Active Now': '当前活跃',
    'Recent Sales': '最近销售',
    'You made 265 sales this month.': '你这个月做了265笔销售。',
    '+$1,999.00': '+$1,999.00',
    'Emma Wilson': '艾玛·威尔逊',
    'emma.wilson@email.com': 'emma.wilson@email.com',
    '+$39.00': '+$39.00',
    'Ryan Park': '瑞安·帕克',
    'ryan.park@email.com': 'ryan.park@email.com',
    '+$299.00': '+$299.00',
    'Sarah Miller': '莎拉·米勒',
    'sarah.miller@email.com': 'sarah.miller@email.com',
    '+$99.00': '+$99.00',
    'David Thompson': '大卫·汤普森',
    'david.thompson@email.com': 'david.thompson@email.com',
    'Marcus Chen': '马克·陈',
    'marcus.chen@email.com': 'marcus.chen@email.com',
    'Total Revenue': '总收入',
    '+20.1% from last month': '从上个月 +20.1%',
    '+180.1% from last month': '从上个月 +180.1%',
    '+19% from last month': '从上个月 +19%',
    '+201 since last hour': '自从上次小时 +201',
    Area: '区域',
    'Security Level': '安全级别',
    'Severity 2': '严重性 2',
    Subject: '主题',
    'I need help with...': '我需要帮助...',
    Description: '描述',
    Cancel: '取消',
    Submit: '提交',
    Billing: '账单',
    'Anyone with the link can view this document.': '任何拥有链接的人都可以查看此文档。',
    'Share this document': '分享此文档',
    'Copy Link': '复制链接',
    'People with access': '有权访问的人员',
    'What area are you having problems with?': '您在哪个区域遇到问题？',
    'Report an issue': '报告问题',
    'Please include all information relevant to your issue.': '请包含与您的问题相关的所有信息。',
    'June 2023': '2023年6月',
    Su: '日',
    Mo: '一',
    Tu: '二',
    We: '三',
    Th: '四',
    Fr: '五',
    Sa: '六',
    'Move Goal': '移动目标',
    'Set your daily activity goal.': '设置您的每日活动目标。',
    Decrease: '减少',
    Increase: '增加',
    'Calories/day': '卡路里/天',
    'Exercise Minutes': '锻炼分钟',
    'Your exercise minutes are ahead of where you normally': '您的锻炼分钟数超过了您通常的水平',
    'are.': '。',
    'Team Members': '团队成员',
    'Invite your team members to collaborate.': '邀请您的团队成员协作。',
    Owner: '所有者',
    Member: '成员',
    'm@example.com': 'm@example.com',
    'p@example.com': 'p@example.com',
    'r@example.com': 'r@example.com',
    's@example.com': 's@example.com',
    'Cookie Settings': 'Cookie 设置',
    'Manage your cookie settings here.': '在这里管理您的 Cookie 设置。',
    'These cookies are essential in order to use the website and use its features.':
        '这些 Cookie 是使用网站和其功能所必需的。',
    'These cookies allow the website to provide personalized functionality.': '这些 Cookie 允许网站提供个性化功能。',
    'These cookies help to improve the performance of the website.': '这些 Cookie 有助于提高网站的性能。',
    'Save preferences': '保存偏好',
    'Payment Method': '支付方式',
    'Add a new payment method to your account.': '添加新的支付方式到您的账户。',
    Card: '卡',
    Paypal: '贝宝',
    Apple: '苹果',
    Name: '姓名',
    Email: '电子邮件',
    Phone: '电话',
    'First Last': '名字 姓氏',
    City: '城市',
    'Card number': '卡号',
    CVC: 'CVC',
    Continue: '继续',
    Expires: '过期',
    Year: '年份',
    'Sofia Davis': '索菲亚·戴维斯',
    'New message': '新消息',
    'Type your message...': '输入您的消息...',
    'Create an account': '创建账户',
    'Enter your email below to create your account': '在下方输入您的电子邮件以创建您的账户',
    'Hi, how can I help you today?': '你好，今天我能帮你什么忙？',
    "Hey, I'm having trouble with my account.": '嘿，我账户有问题。',
    'What seems to be the problem?': '有什么问题吗？',
    "I can't log in.": '我无法登录。',
    'Create account': '创建账户',
    'Set Goal': '设置目标',
    Payments: '支付',
    'Manage your payments.': '管理您的付款。',
    Columns: '列',
    'Filter emails...': '过滤电子邮件...',
    Amount: '金额',
    Status: '状态',
    success: '成功',
    'ken99@example.com': 'ken99@example.com',
    '$316.00': '$316.00',
    processing: '处理中',
    'Monserrat44@example.com': 'Monserrat44@example.com',
    '$837.00': '$837.00',
    'Abe45@example.com': 'Abe45@example.com',
    '$242.00': '$242.00',
    pending: '待处理',
    'carmella@example.com': 'carmella@example.com',
    '$721.00': '$721.00',
    failed: '失败',
    '0 of 4 row(s) selected.': '0 行已选择。',
    'Select all': '选择全部',
    'Deselect all': '取消选择全部',
    'Can edit': '可以编辑',
    'Can view': '可以查看',
    Link: '链接',
    Previous: '上一页',
    Next: '下一页',
    'Olivia Martin': '奥利维亚·马丁',
    'b@example.com': 'b@example.com',
    'Isabella Nguyen': '伊莎贝拉·阮',
    'Strictly Necessary': '严格必要',
    'Functional Cookies': '功能性 Cookie',
    'Performance Cookies': '性能 Cookie',
    'Or continue with': '或继续使用',
    Google: '谷歌',
    GitHub: 'GitHub',
    Password: '密码',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    'Tech Analyst': 'Tech Analyst',
    Dashboard: 'Dashboard',
    Overview: 'Overview',
    Customers: 'Customers',
    Products: 'Products',
    Settings: 'Settings',
    Search: 'Search',
    Download: 'Download',
    'Jan 20, 2023 - Feb 09, 2023': 'Jan 20, 2023 - Feb 09, 2023',
    Analytics: 'Analytics',
    Reports: 'Reports',
    Notifications: 'Notifications',
    Subscriptions: 'Subscriptions',
    Sales: 'Sales',
    'Active Now': 'Active Now',
    'Recent Sales': 'Recent Sales',
    'You made 265 sales this month.': 'You made 265 sales this month.',
    '+$1,999.00': '+$1,999.00',
    'Emma Wilson': 'Emma Wilson',
    'emma.wilson@email.com': 'emma.wilson@email.com',
    '+$39.00': '+$39.00',
    'Ryan Park': 'Ryan Park',
    'ryan.park@email.com': 'ryan.park@email.com',
    '+$299.00': '+$299.00',
    'Sarah Miller': 'Sarah Miller',
    'sarah.miller@email.com': 'sarah.miller@email.com',
    '+$99.00': '+$99.00',
    'David Thompson': 'David Thompson',
    'david.thompson@email.com': 'david.thompson@email.com',
    'Marcus Chen': 'Marcus Chen',
    'marcus.chen@email.com': 'marcus.chen@email.com',
    'Total Revenue': 'Total Revenue',
    '+20.1% from last month': '+20.1% from last month',
    '+180.1% from last month': '+180.1% from last month',
    '+19% from last month': '+19% from last month',
    '+201 since last hour': '+201 since last hour',
    Area: 'Area',
    'Security Level': 'Security Level',
    'Severity 2': 'Severity 2',
    Subject: 'Subject',
    'I need help with...': 'I need help with...',
    Description: 'Description',
    Cancel: 'Cancel',
    Submit: 'Submit',
    Billing: 'Billing',
    'Anyone with the link can view this document.': 'Anyone with the link can view this document.',
    'Share this document': 'Share this document',
    'Copy Link': 'Copy Link',
    'People with access': 'People with access',
    'What area are you having problems with?': 'What area are you having problems with?',
    'Report an issue': 'Report an issue',
    'Please include all information relevant to your issue.': 'Please include all information relevant to your issue.',
    'June 2023': 'June 2023',
    Su: 'Su',
    Mo: 'Mo',
    Tu: 'Tu',
    We: 'We',
    Th: 'Th',
    Fr: 'Fr',
    Sa: 'Sa',
    'Move Goal': 'Move Goal',
    'Set your daily activity goal.': 'Set your daily activity goal.',
    Decrease: 'Decrease',
    Increase: 'Increase',
    'Calories/day': 'Calories/day',
    'Exercise Minutes': 'Exercise Minutes',
    'Your exercise minutes are ahead of where you normally': 'Your exercise minutes are ahead of where you normally',
    'are.': 'are.',
    'Team Members': 'Team Members',
    'Invite your team members to collaborate.': 'Invite your team members to collaborate.',
    Owner: 'Owner',
    Member: 'Member',
    'm@example.com': 'm@example.com',
    'p@example.com': 'p@example.com',
    'r@example.com': 'r@example.com',
    's@example.com': 's@example.com',
    'Cookie Settings': 'Cookie Settings',
    'Manage your cookie settings here.': 'Manage your cookie settings here.',
    'These cookies are essential in order to use the website and use its features.':
        'These cookies are essential in order to use the website and use its features.',
    'These cookies allow the website to provide personalized functionality.':
        'These cookies allow the website to provide personalized functionality.',
    'These cookies help to improve the performance of the website.':
        'These cookies help to improve the performance of the website.',
    'Save preferences': 'Save preferences',
    'Payment Method': 'Payment Method',
    'Add a new payment method to your account.': 'Add a new payment method to your account.',
    Card: 'Card',
    Paypal: 'Paypal',
    Apple: 'Apple',
    Name: 'Name',
    Email: 'Email',
    Phone: 'Phone',
    'First Last': 'First Last',
    City: 'City',
    'Card number': 'Card number',
    CVC: 'CVC',
    Continue: 'Continue',
    Expires: 'Expires',
    Year: 'Year',
    'Sofia Davis': 'Sofia Davis',
    'New message': 'New message',
    'Type your message...': 'Type your message...',
    'Create an account': 'Create an account',
    'Enter your email below to create your account': 'Enter your email below to create your account',
    'Hi, how can I help you today?': 'Hi, how can I help you today?',
    "Hey, I'm having trouble with my account.": "Hey, I'm having trouble with my account.",
    'What seems to be the problem?': 'What seems to be the problem?',
    "I can't log in.": "I can't log in.",
    'Create account': 'Create account',
    'Set Goal': 'Set Goal',
    Payments: 'Payments',
    'Manage your payments.': 'Manage your payments.',
    Columns: 'Columns',
    'Filter emails...': 'Filter emails...',
    Amount: 'Amount',
    Status: 'Status',
    success: 'success',
    'ken99@example.com': 'ken99@example.com',
    '$316.00': '$316.00',
    processing: 'processing',
    'Monserrat44@example.com': 'Monserrat44@example.com',
    '$837.00': '$837.00',
    'Abe45@example.com': 'Abe45@example.com',
    '$242.00': '$242.00',
    pending: 'pending',
    'carmella@example.com': 'carmella@example.com',
    '$721.00': '$721.00',
    failed: 'failed',
    '0 of 4 row(s) selected.': '0 of 4 row(s) selected.',
    'Select all': 'Select all',
    'Deselect all': 'Deselect all',
    'Can edit': 'Can edit',
    'Can view': 'Can view',
    Link: 'Link',
    Previous: 'Previous',
    Next: 'Next',
    'Olivia Martin': 'Olivia Martin',
    'b@example.com': 'b@example.com',
    'Isabella Nguyen': 'Isabella Nguyen',
    'Strictly Necessary': 'Strictly Necessary',
    'Functional Cookies': 'Functional Cookies',
    'Performance Cookies': 'Performance Cookies',
    'Or continue with': 'Or continue with',
    Google: 'Google',
    GitHub: 'GitHub',
    Password: 'Password',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
