export enum RenderItemBackground {
    None = '',
    Normal = 'bg-normal',
    Selected = 'bg-selected',
    AncesterSelected = 'bg-ancestor-selected',
    InsideAILayout = 'bg-inside-ai-layout',
}

export enum RenderItemIsHovered {
    None = '',
    Hovered = 'hovered',
}

export enum RenderItemHoveredBackground {
    None = '',
    Normal = 'hovered-normal',
    Purple = 'hovered-purple',
}
