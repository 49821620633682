import { CommentAvatar, CommentAvatarProps } from '../comment-avatar/comment-avatar'
import { CommentEditor, CommentEditorProps } from '../comment-editor/comment-editor'
import classes from './reply-editor.module.less'

export interface ReplyEditorProps extends CommentAvatarProps, CommentEditorProps {
    dataTestIds?: {
        CommentEditor?: string
        CommentEditorInput?: string
    }
}

export function ReplyEditor(props: ReplyEditorProps) {
    return (
        <div className={classes.replyEditor}>
            <div className={classes.avatarContainer}>
                <CommentAvatar {...props} />
            </div>
            <div className={classes.editorContainer}>
                <CommentEditor {...props} className={classes.editor} />
            </div>
        </div>
    )
}
