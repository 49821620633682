/* eslint-disable no-restricted-imports */
import { DelayTimer } from '../../../../../util/src'
import { ClogConfig, ClogDomain, IN_JEST_TEST } from '../../../environment'
import { isDebugEnabled } from '../../debug'
import { LogValue } from '../core'
import { CLOGCATEGORY, CLOGENV, CLOGHOST } from '../core/config/environment'
import { Log, LogParam, LogParams } from '../core/log'
import { getCommonHeader } from '../core/util'
import { getCurrentUrl } from './util'

type LogData = Record<string, string | number | boolean>

enum WUKONG_HEADER_KEYS {
    UserId = 'userId',
    DocumentId = 'documentId',
    SessionId = 'sessionId',
    ClientId = 'clientId',
    Release = 'release',
    Vendor = 'vendor',
    Renderer = 'renderer',
}

const wukongEnvMapper = (
    clogConfig: ClogConfig
): {
    env: CLOGENV
    site: string
} => {
    const { domain, site } = clogConfig
    let env: CLOGENV
    switch (domain) {
        case ClogDomain.Biz: {
            env = CLOGENV.BIZ
            break
        }
        case ClogDomain.Ws: {
            env = CLOGENV.WS
            break
        }
        case ClogDomain.Com: {
            env = CLOGENV.COM
            break
        }
    }
    return {
        env,
        site,
    }
}

export class WukongCLog {
    private _MAX_THROTTLE_LOG_DATA_COUNT = 200
    private _THRTTLE_LOG_DURATION_MS = 10000

    private logInstance_: Log
    private _throttleLogTimer: DelayTimer
    private _throttleLogData: LogParam[] = []

    constructor(clogConfig: ClogConfig) {
        const { env, site } = wukongEnvMapper(clogConfig)
        this.logInstance_ = new Log(CLOGHOST.CLOG, env, CLOGCATEGORY.WUKONG, site, 'wukong-editor')
        this._throttleLogTimer = new DelayTimer(this._THRTTLE_LOG_DURATION_MS, () => {
            this.flushThrottledLogImpl()
        })
    }

    private getNormalizedData(description: string, data: LogData = {}): LogValue {
        return {
            ...getCommonHeader(),
            ...data,
            description,
        }
    }

    public throttleLog(description: string, data: LogData): void {
        if (IN_JEST_TEST) {
            return
        }
        // 先在console打印一下内容
        if (isDebugEnabled.value) {
            if (data) {
                this.logInstance_.devLog('[msg]', description, data)
            } else {
                this.logInstance_.devLog('[msg]', description)
            }
        }

        this._throttleLogData.push({
            url: getCurrentUrl(),
            values: this.getNormalizedData(description, data),
        })
        if (this._throttleLogData.length >= this._MAX_THROTTLE_LOG_DATA_COUNT) {
            this.flushThrottledLogImpl()
        } else {
            this._throttleLogTimer.ensureStarted()
        }
    }

    public flushThrottledLog() {
        if (IN_JEST_TEST) {
            return
        }
        this.flushThrottledLogImpl()
    }

    public async log(description: string, data?: LogData | LogData[]): Promise<void> {
        if (IN_JEST_TEST) {
            return
        }
        // 先在console打印一下内容
        if (isDebugEnabled.value) {
            if (data) {
                this.logInstance_.devLog('[msg]', description, data)
            } else {
                this.logInstance_.devLog('[msg]', description)
            }
        }

        if (Array.isArray(data)) {
            const logParams: LogParams = data.map((subItem) => ({
                url: getCurrentUrl(),
                values: this.getNormalizedData(description, subItem),
            }))

            await this.logInstance_.sendLog(logParams)
        } else {
            const logParams: LogParams = {
                url: getCurrentUrl(),
                values: this.getNormalizedData(description, data),
            }

            await this.logInstance_.sendLog(logParams)
        }
    }

    public setUser(id: string | number | null) {
        if (id === null) {
            this.logInstance_.header.remove(WUKONG_HEADER_KEYS.UserId)
        } else {
            this.logInstance_.header.add(WUKONG_HEADER_KEYS.UserId, typeof id === 'string' ? id : id.toString())
        }
    }

    public setDocumentId(id: string | null) {
        if (id === null) {
            this.logInstance_.header.remove(WUKONG_HEADER_KEYS.DocumentId)
        } else {
            this.logInstance_.header.add(WUKONG_HEADER_KEYS.DocumentId, id)
        }
    }

    public setSessionId(sid: number | null) {
        if (sid === null) {
            this.logInstance_.header.remove(WUKONG_HEADER_KEYS.SessionId)
        } else {
            this.logInstance_.header.add(WUKONG_HEADER_KEYS.SessionId, sid.toString())
        }
    }

    public setClientId(clientId: number | null) {
        if (clientId === null) {
            this.logInstance_.header.remove(WUKONG_HEADER_KEYS.ClientId)
        } else {
            this.logInstance_.header.add(WUKONG_HEADER_KEYS.ClientId, clientId.toString())
        }
    }

    public setRelease(release: string) {
        this.logInstance_.header.add(WUKONG_HEADER_KEYS.Release, release)
    }

    public setVendor(vendor: string | null) {
        if (vendor === null) {
            this.logInstance_.header.remove(WUKONG_HEADER_KEYS.Vendor)
        } else {
            this.logInstance_.header.add(WUKONG_HEADER_KEYS.Vendor, vendor)
        }
    }

    public setRenderer(renderer: string | null) {
        if (renderer === null) {
            this.logInstance_.header.remove(WUKONG_HEADER_KEYS.Renderer)
        } else {
            this.logInstance_.header.add(WUKONG_HEADER_KEYS.Renderer, renderer)
        }
    }

    private flushThrottledLogImpl() {
        this._throttleLogTimer.destroy()
        if (this._throttleLogData.length === 0) {
            return
        }

        const copied = this._throttleLogData.splice(0, this._throttleLogData.length)
        this.logInstance_.sendLog(copied)
    }
}
