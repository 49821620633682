/* eslint-disable no-restricted-imports */
import {
    CancelLastOpenHyperlink,
    ClearHoverPopupHyperlink,
    IsValidUrl as jsCallIsValidUrl,
    MakeHoverPopupHyperlinkActive,
    MouseEnterHyperlink,
    MouseLeaveHyperlink,
    OpenHyperlinkDelayCommand,
    OpenHyperlinkNodeWasmCall,
    ParseHyperlink,
    Wukong,
} from '@wukong/bridge-proto'
import { isValidUrl, tryGetNodeIdInCurrentDocument } from '../../document/util/url'
import { Bridge } from '../../kernel/bridge/bridge'
import { AiService } from '../ai-service'
import { tryOpenPlaygroundLink } from './playground-service'

const DoubleClickIntervalMillis = 500

export class HyperlinkService {
    private lastOpenHyperlinkTimerId: NodeJS.Timeout | undefined
    private lastOpenHyperlinkTime = 0

    private lastEnteringHyperlinkTimerId: NodeJS.Timeout | undefined
    private lastLeavingHyperlinkTimerId: NodeJS.Timeout | undefined

    constructor(
        private readonly bridge: Bridge,
        private readonly aiService: AiService,
        private readonly orgId: string
    ) {
        this.bindWasmCallJs()
    }

    private bindWasmCallJs = () => {
        this.bridge.bind(OpenHyperlinkDelayCommand, this.openHyperlinkDelay)
        this.bridge.bind(CancelLastOpenHyperlink, this.cancelLastOpenHyperlink)

        this.bridge.bind(MouseEnterHyperlink, this.mouseEnterHyperlink)
        this.bridge.bind(MouseLeaveHyperlink, this.mouseLeaveHyperlink)
        this.bridge.bind(ParseHyperlink, this.parseHyperlink)
        this.bridge.bind(jsCallIsValidUrl, this.isValidUrl)
    }

    private openHyperlinkDelay = (value: Wukong.DocumentProto.IHyperlink) => {
        this.cancelLastOpenHyperlink()

        const isDbClick = Date.now() - this.lastOpenHyperlinkTime < DoubleClickIntervalMillis
        this.lastOpenHyperlinkTime = Date.now()
        if (isDbClick) {
            return
        }

        this.lastOpenHyperlinkTimerId = setTimeout(() => {
            this.openHyperlink(value)
        }, DoubleClickIntervalMillis)
    }

    public openHyperlink = ({ url, nodeId }: Wukong.DocumentProto.IHyperlink) => {
        if (nodeId) {
            this.bridge.call(OpenHyperlinkNodeWasmCall, {
                url: '',
                nodeId: nodeId,
            })
        } else if (url) {
            if (url.startsWith('motiff')) {
                this.aiService.openComponentDetailPage(url)
            } else {
                if (tryOpenPlaygroundLink(url, this.orgId)) {
                    return
                }
                window.open(url, '_blank')
            }
        }
    }

    private cancelLastOpenHyperlink = () => {
        clearTimeout(this.lastOpenHyperlinkTimerId)
    }

    public mouseEnterHyperlink = () => {
        clearTimeout(this.lastLeavingHyperlinkTimerId)
        clearTimeout(this.lastEnteringHyperlinkTimerId)
        this.lastLeavingHyperlinkTimerId = undefined
        this.lastEnteringHyperlinkTimerId = undefined

        this.lastEnteringHyperlinkTimerId = setTimeout(() => {
            this.bridge.call(MakeHoverPopupHyperlinkActive)
        }, 500)
    }

    public mouseLeaveHyperlink = () => {
        clearTimeout(this.lastEnteringHyperlinkTimerId)
        if (!this.lastLeavingHyperlinkTimerId) {
            // 延时500ms再消失，快速移入到弹窗区域时保持展示
            this.lastLeavingHyperlinkTimerId = setTimeout(() => {
                this.lastLeavingHyperlinkTimerId = undefined
                this.bridge.call(ClearHoverPopupHyperlink)
            }, 500)
        }
    }

    private parseHyperlink = (url: Wukong.DocumentProto.IBridgeProtoString): Wukong.DocumentProto.IHyperlink => {
        const nodeId = tryGetNodeIdInCurrentDocument(url.value ?? '')
        if (nodeId) {
            return { url: '', nodeId }
        } else {
            return { url: url.value, nodeId: '' }
        }
    }

    private isValidUrl = (url: Wukong.DocumentProto.IBridgeProtoString): Wukong.DocumentProto.IBridgeProtoBoolean => {
        return { value: isValidUrl(url.value ?? '') }
    }

    public destroy = () => {
        this.bridge.unbind(OpenHyperlinkDelayCommand)
        this.bridge.unbind(CancelLastOpenHyperlink)
        this.bridge.unbind(MouseEnterHyperlink)
        this.bridge.unbind(MouseLeaveHyperlink)
        this.bridge.unbind(ParseHyperlink)
        this.bridge.unbind(jsCallIsValidUrl)

        this.cancelLastOpenHyperlink()
        clearTimeout(this.lastLeavingHyperlinkTimerId)
        clearTimeout(this.lastEnteringHyperlinkTimerId)
    }
}
