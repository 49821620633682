import { GET, PATCH, PUT } from '@tutor/network-core'
import type {
    AccessDocVO,
    AccessFolderVO,
    AccessTeamVO,
    DocumentPublicAccessVO,
    TeamLinkOperationTypeEnum,
    UpdateInheritAccessVO,
    TeamID,
    RoleStatus,
} from '../interface/type'
import { BaseCommonRequest } from './base-request'

// 根据团队id获取团队的权限配置信息
@GET
export class GetTeamAccess extends BaseCommonRequest<AccessTeamVO> {
    constructor(private readonly teamId: string) {
        super()
    }

    public override requestUrl() {
        return `accesses/teams/${this.teamId}`
    }
}

// 根据操作类型来更新团队邀请链接并返回,支持生成/重置/禁用,禁用时返回空字符串
@PUT
export class UpdateTeamAccess extends BaseCommonRequest<string> {
    constructor(
        private readonly teamId: TeamID,
        private readonly teamLinkOperationTypeEnum: TeamLinkOperationTypeEnum,
        private readonly roleEnum: RoleStatus
    ) {
        super()
    }
    public override requestBody() {
        return {
            roleEnum: this.roleEnum,
            teamLinkOperationTypeEnum: this.teamLinkOperationTypeEnum,
        }
    }
    public override requestUrl() {
        return `accesses/teams/${this.teamId}`
    }
}

// 根据项目id获取项目的权限配置信息
@GET
export class GetFolderAccess extends BaseCommonRequest<AccessFolderVO> {
    constructor(private readonly folderId: string) {
        super()
    }

    public override requestUrl() {
        return `accesses/folders/${this.folderId}`
    }
    public override requestDidFailed(): boolean {
        return false
    }
}

// 更新项目的继承权限
@PATCH
export class UpdateFolderAccess extends BaseCommonRequest {
    constructor(private readonly folderId: string, private readonly params: UpdateInheritAccessVO) {
        super()
    }

    public override requestUrl() {
        return `accesses/folders/${this.folderId}`
    }

    public override requestBody() {
        return this.params
    }
}

// 根据文件id获取文件的权限配置信息
@GET
export class GetDocumentAccess extends BaseCommonRequest<AccessDocVO> {
    constructor(private readonly docId: string) {
        super()
    }

    public override requestUrl() {
        return `accesses/documents/${this.docId}`
    }
    public override requestDidFailed(): boolean {
        return false
    }
}

// 更新文件的继承权限
@PATCH
export class UpdateDocumentAccess extends BaseCommonRequest {
    constructor(private readonly docId: string, private readonly params: UpdateInheritAccessVO) {
        super()
    }

    public override requestUrl() {
        return `accesses/documents/${this.docId}`
    }

    public override requestBody() {
        return this.params
    }
}

// 更新文件的公开访问权限配置
@PUT
export class UpdateDocumentPublicAccess extends BaseCommonRequest {
    constructor(private readonly docId: string, private readonly params: DocumentPublicAccessVO) {
        super()
    }

    public override requestUrl() {
        return `accesses/documents/${this.docId}/public`
    }

    public override requestBody() {
        return this.params
    }

    public override requestDidFailed() {
        return false
    }
}

// 更新'允许可查看的人创建副本，复制，导出页面，frame或图层'
@PATCH
export class UpdateDocumentCopyAccess extends BaseCommonRequest {
    constructor(private readonly docId: string, private readonly copyable: boolean) {
        super()
    }

    public override additionalRequestHeaders() {
        return {
            'Content-Type': 'application/json',
        }
    }

    public override requestUrl() {
        return `accesses/documents/${this.docId}/copy`
    }

    public override requestBody() {
        return JSON.stringify(this.copyable)
    }
}

@GET
export class CheckDocumentCopyAccess extends BaseCommonRequest {
    constructor(private readonly docId: string) {
        super()
    }
    public override requestUrl() {
        return `accesses/documents/${this.docId}/copy-check`
    }

    public override requestDidFailed() {
        return false
    }
}
