import { Wukong } from '@wukong/bridge-proto'
import { LibraryComponentViewEditorConfigProvider } from '../../../../../../share/component-style-library/library-component-viewer/context'
import { LibraryComponentViewer } from '../../../../../../share/component-style-library/library-component-viewer/library-component-viewer'
import { useViewState } from '../../../../../../view-state-bridge'
import classes from './preview-library-component-item.module.less'

export function PreviewLibraryComponentItem(props: {
    component: Wukong.DocumentProto.IVLibraryAssetPanelComponentItem | null | undefined
}) {
    const isListLayout =
        useViewState('libraryAssetPanel')?.displayMode ===
        Wukong.DocumentProto.VLibraryAssetPanelDisplayMode.V_LIBRARY_ASSET_PANEL_DISPLAY_MODE_LIST

    const style = (() => {
        const { fullWidth, width, height, top, left } = props.component ?? {}

        const ret: React.CSSProperties = {
            position: 'absolute',
            top: top ?? 0,
            height: height ?? 0,
        }
        if (fullWidth) {
            ret.width = '100%'
            ret.left = 0
            ret.right = 0
        } else {
            ret.width = width ?? 0
            ret.left = left ?? 0
        }
        return ret
    })()

    const innerStyle = ((): React.CSSProperties | undefined => {
        const { fullWidth, innerWidth, innerHeight } = props.component ?? {}

        if (fullWidth) {
            return undefined
        }
        return {
            width: innerWidth ?? 0,
            height: innerHeight ?? 0,
        }
    })()

    return props.component ? (
        <LibraryComponentViewEditorConfigProvider draggable>
            <LibraryComponentViewer
                component={props.component}
                className={classes.container}
                style={style}
                innerStyle={innerStyle}
                isListLayout={isListLayout}
            />
        </LibraryComponentViewEditorConfigProvider>
    ) : (
        <></>
    )
}
