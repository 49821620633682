import { useCallback, useMemo } from 'react'
import { useLocalStorageState } from '../../web-storage/local-storage/hooks'
import { LocalStorageKey } from '../../web-storage/local-storage/config'
import { ShowType } from './types'

export const useListShowType = (uid: string, defalutShowType: ShowType) => {
    const defaulSortState = useMemo(() => ({ showType: defalutShowType }), [defalutShowType])
    const [data, setData] = useLocalStorageState(
        {
            key: LocalStorageKey.ListShowType,
            suffix: `-${uid}`,
        },
        defaulSortState
    )
    const setShowType = useCallback(
        (showType: ShowType) => {
            setData({ showType: showType })
        },
        [setData]
    )
    return { showType: data.showType, setShowType }
}
