import { domLocation } from './dom-env'
import { queryStringify, ValidSearchParams } from './query-string'

export function validUrl(url: string) {
    if (url.startsWith('mailto:')) {
        return url
    }

    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = 'http://' + url
    }

    return url
}

export function openLinkOnWeb(url: string, openUrlInNewTab: boolean) {
    try {
        // 尝试创建一个新的 URL 对象以验证 URL
        new URL(url)

        if (!openUrlInNewTab) {
            window.location.href = url
        } else {
            window.open(url, '_blank')
        }
    } catch (e) {
        console.error('Invalid URL:', url)
    }
}

/**
 * 给定一个query对象，返回拼接后的url
 * query中的值为空时，不拼接到url上
 */
export function generateUrlByQuery(params: ValidSearchParams, path?: string) {
    const newPath = path || `${domLocation().origin}${domLocation().pathname}`
    const qs = queryStringify(params)
    return `${newPath}${qs && '?' + qs}`
}

/**
 * 获取 url 中的 docId
 * @returns
 */
export const getUrlDocId = (): string | undefined => {
    const pathName = domLocation().pathname

    let docId = undefined

    if (/.*\/file\/(\w+)$/.test(pathName)) {
        docId = pathName.replace(/.*\/file\/(\w+)$/, '$1')
    }

    return docId
}

/**
 * 提供安全的 decodeURIComponent，否则 data 解析错误会有 error
 * @param data
 * @returns
 */
export function safeDecodeUriComponent(data: string): string | undefined
export function safeDecodeUriComponent(data: string, fallbackValue: string): string
export function safeDecodeUriComponent(data: string, fallbackValue?: string): string | undefined {
    try {
        return decodeURIComponent(data)
    } catch {
        return fallbackValue
    }
}
