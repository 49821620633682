/* eslint-disable no-restricted-imports */
import {
    DetachEffectStyleForSelectionCommand,
    RemoveEffectStyleForSelectionCommand,
    UpdateEffectsForSelectionCommand,
    Wukong,
} from '@wukong/bridge-proto'
import constate from 'constate'
import { useMemo, useState } from 'react'
import { cmdChangePopupState } from '../../../../document/command/document-command'
import { cmdUpdateSelectedReverseIndex } from '../../../../document/command/page-command'
import { Effect, EffectType, NodeId, PopupStateType } from '../../../../document/node/node'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { getEffectStyleIcon } from './effect-style/effect-style-icon'

export interface ClientPosittion {
    left: number
    top: number
}

export function getDefaultEffects(): Effect {
    return {
        type: Wukong.DocumentProto.EffectType.EFFECT_TYPE_DROP_SHADOW,
        color: { r: 0, g: 0, b: 0, a: 51 },
        offset: { x: 0, y: 4 },
        radius: 4,
        spread: 0,
        visible: true,
        blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
        showShadowBehindNode: false,
    } as any as Effect // FIXME(type)
}

function useEffectContextInternal() {
    const commandInvoker = useCommand()

    // view state
    const selectionEffectViewState = useViewState('selectionEffect')

    const selectionEffectListSelectedReversedIndexes = useViewState('selectedEffects')

    const popupState = useViewState('popupState')

    // state
    const [effectStyleSelectModalPosition, setEffectStyleSelectModalPosition] = useState<ClientPosittion | null>(null)

    // computed
    const selectionEffectType =
        selectionEffectViewState?.type ?? Wukong.DocumentProto.VSelectionEffectType.V_SELECTION_EFFECT_TYPE_NONE

    const selectionEffectIsMixed =
        selectionEffectType === Wukong.DocumentProto.VSelectionEffectType.V_SELECTION_EFFECT_TYPE_MIXED

    const selectionEffectIsNone =
        selectionEffectType === Wukong.DocumentProto.VSelectionEffectType.V_SELECTION_EFFECT_TYPE_NONE

    const showEffectList =
        selectionEffectType === Wukong.DocumentProto.VSelectionEffectType.V_SELECTION_EFFECT_TYPE_NORMAL

    const showEffectStyle =
        selectionEffectType === Wukong.DocumentProto.VSelectionEffectType.V_SELECTION_EFFECT_TYPE_STYLE

    const selectionEffectList = useMemo(
        () => (selectionEffectViewState?.effects ?? []) as any as Effect[],
        [selectionEffectViewState]
    )

    const selectionVariables = selectionEffectViewState?.variables ?? []
    const selectionFloatVariables = selectionEffectViewState?.floatVariables ?? []

    const openedEffectListIndex = useMemo(() => {
        if (!popupState || popupState.type !== PopupStateType.POPUP_STATE_TYPE_EFFECTS) {
            return -1
        }
        return popupState.reciprocalIndex
    }, [popupState])

    const selectionEffectStyle = selectionEffectViewState?.effectStyle

    const selectionEffectStyleIcon = getEffectStyleIcon(selectionEffectStyle?.effects ?? [])

    const showEffectControl = selectionEffectViewState?.enableInsertEffectOperation ?? true

    const showEffectStyleControl = selectionEffectViewState?.enableSelectEffectStyleOperation ?? true

    const enableEffectsSpread = selectionEffectViewState?.enableEffectsSpread ?? true

    const showEffectStyleSelectModal = popupState?.type === PopupStateType.POPUP_STATE_TYPE_EFFECT_STYLE_SELECT

    const hasBackgroundBlur = useMemo(() => {
        if (selectionEffectIsMixed || !selectionEffectList.length) {
            return false
        }
        return selectionEffectList.some((effect) => effect.type === EffectType.BackgroundBlur)
    }, [selectionEffectList, selectionEffectIsMixed])

    const hasLayerBlur = useMemo(() => {
        if (selectionEffectIsMixed || !selectionEffectList.length) {
            return false
        }
        return selectionEffectList.some((effect) => effect.type === EffectType.LayerBlur)
    }, [selectionEffectList, selectionEffectIsMixed])

    const effectsToCreateStyle = useMemo(() => {
        return (showEffectList ? selectionEffectList : selectionEffectStyle?.effects ?? []) as any as Effect[]
    }, [selectionEffectList, selectionEffectStyle?.effects, showEffectList])

    // handler
    const updateEffectsForSelection = (
        effects: Effect[],
        options?: InputOptionsForUndoSquash,
        clearSelected?: boolean
    ) => {
        clearSelected = clearSelected === undefined ? false : clearSelected

        // 更新时反转（ 含新增、删除、排序、修改属性 ）
        commandInvoker.DEPRECATED_invokeBridge(UpdateEffectsForSelectionCommand, {
            effects: effects as any as Wukong.DocumentProto.IEffect[],
            clearSelected,
        })
        commandInvoker.commitUndo(options?.commitType)
    }

    const effectListSelectedChange = (itemsIndex: number[]) => {
        commandInvoker.invoke(cmdUpdateSelectedReverseIndex, 'effects', itemsIndex)
    }

    const adjustEffectListOrder = (effects: Effect[], indexes: number[]) => {
        commandInvoker.invoke(cmdUpdateSelectedReverseIndex, 'effects', indexes)
        updateEffectsForSelection(effects)
    }

    const removeEffectForSelection = (index: number) => {
        if (!selectionEffectList.length) {
            return
        }
        if (!selectionEffectList[index]) {
            return
        }
        const newEffects = [...selectionEffectList]
        newEffects.splice(index, 1)
        updateEffectsForSelection(newEffects, undefined, true)
    }

    const insertEffectForSelection = () => {
        const newEffect = getDefaultEffects()
        const newEffects = [...selectionEffectList, newEffect]
        updateEffectsForSelection(newEffects, undefined, true)
    }

    const updateEffectDetail = (effect: { [key: string]: any }, index: number, options?: InputOptionsForUndoSquash) => {
        if (!selectionEffectList.length) {
            return
        }
        const oldEffectValue = selectionEffectList[index]
        if (!oldEffectValue) {
            return
        }
        const newEffects = [...selectionEffectList]
        if (effect.type !== undefined && effect.type !== oldEffectValue.type) {
            newEffects[index] = { ...getDefaultEffects(), ...oldEffectValue, ...effect }
        } else {
            newEffects[index] = { ...oldEffectValue, ...effect }
        }
        updateEffectsForSelection(newEffects, options)
    }

    const openEffectEditingModal = (index: number) => {
        const reciprocalIndex = index
        commandInvoker.invoke(cmdChangePopupState, {
            type: PopupStateType.POPUP_STATE_TYPE_EFFECTS,
            reciprocalIndex,
            multiPopup: [],
        })
    }

    const closeEffectEditingModal = () => {
        commandInvoker.invoke(cmdChangePopupState, {
            type: PopupStateType.POPUP_STATE_TYPE_NONE,
            reciprocalIndex: -1,
            multiPopup: [],
        })
    }

    const removeEffectStyle = () => {
        commandInvoker.DEPRECATED_invokeBridge(RemoveEffectStyleForSelectionCommand)
        commandInvoker.commitUndo()
    }

    const detachEffectStyle = (effectStyleId: NodeId) => {
        commandInvoker.DEPRECATED_invokeBridge(DetachEffectStyleForSelectionCommand, { value: effectStyleId })
        commandInvoker.commitUndo()
    }

    const replaceEffectStyleWithSingleDefaultEffect = () => {
        removeEffectStyle()
        insertEffectForSelection()
        commandInvoker.commitUndo()
    }

    const openEffectStyleSelectModal = (rect: DOMRect) => {
        commandInvoker.invoke(cmdChangePopupState, {
            type: PopupStateType.POPUP_STATE_TYPE_EFFECT_STYLE_SELECT,
            reciprocalIndex: -1,
            multiPopup: [],
        })
        const { bottom } = rect
        const { clientWidth } = document.documentElement
        setEffectStyleSelectModalPosition({ top: bottom + 8, left: clientWidth - 16 })
    }

    const closeEffectStyleSelectModal = () => {
        commandInvoker.invoke(cmdChangePopupState, {
            type: PopupStateType.POPUP_STATE_TYPE_NONE,
            reciprocalIndex: -1,
            multiPopup: [],
        })
        setEffectStyleSelectModalPosition({ left: 0, top: 0 })
    }

    return {
        selectionEffectListSelectedReversedIndexes,
        selectionEffectIsNone, // 是否有效果
        selectionEffectIsMixed, // 是否为 mixed 数据
        showEffectList, // 是否展示为效果列表
        selectionEffectList, // 效果列表数据
        effectListSelectedChange, // 选中效果列表中的列表项
        adjustEffectListOrder, // 更改效果列表顺序
        hasBackgroundBlur, // 是否已含有背景模糊
        hasLayerBlur, // 是否已含有图层模糊
        removeEffectForSelection, // 移除一条效果
        insertEffectForSelection, // 新增一条效果
        updateEffectDetail, // 更改一条效果属性
        openEffectEditingModal, // 打开效果编辑弹框
        closeEffectEditingModal, // 关闭效果编辑弹框
        openedEffectListIndex, // 效果列表中被编辑的样式下标
        showEffectStyle, // 是否展示为效果样式
        selectionEffectStyle, // 效果样式数据
        selectionEffectStyleIcon, // 效果样式的图标
        showEffectControl, // 是否展示「+」按钮
        showEffectStyleControl, // 是否展示「样式选择」按钮
        showEffectStyleSelectModal, // 是否展示选择效果样式弹框
        effectStyleSelectModalPosition, // 效果样式弹框的弹出位置
        removeEffectStyle, // 为选区移除样式效果
        detachEffectStyle, // 为选区解绑样式效果并应用为样式
        replaceEffectStyleWithSingleDefaultEffect, // 为选区将效果样式替换为默认效果
        openEffectStyleSelectModal, // 打开效果样式弹框
        closeEffectStyleSelectModal, // 关闭效果样式弹框
        effectsToCreateStyle, // 用于创建新样式所用的效果列表
        enableEffectsSpread, // 是否可设置「扩展」
        selectionVariables, // effects 相关的变量列表
        selectionFloatVariables, // effects 相关的浮点变量列表
    }
}

// TODO(chenyn): 拆一拆
export const [EffectContextProvider, useEffectContext] = constate(useEffectContextInternal)
