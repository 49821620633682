import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import type { HTMLAttributes } from 'react'
import { forwardRef, useCallback } from 'react'
import { toCSSBackgroundColor } from '../../design/blend/color-picker/utils/color-translate'
import style from './color-block.module.less'

interface ColorBlackProps extends HTMLAttributes<HTMLDivElement>, PureBlockStructProps {
    dataTestId?: string
    disabledTab?: boolean
}
function _PureBlock(props: ColorBlackProps, ref: React.ForwardedRef<HTMLDivElement>) {
    const {
        rgb,
        colorSpace,
        className,
        opacity,
        dataTestId,
        disabledTab,
        size,
        onClick,
        onKeyDown,
        onMouseDown,
        ...otherProps
    } = props

    const _onKeyDown = useCallback(
        (e: any) => {
            if (e.keyCode === 13) {
                e.stopPropagation()
                onMouseDown?.(e)
            }
            onKeyDown?.(e)
        },
        [onMouseDown, onKeyDown]
    )

    const _onClick = useCallback(
        (e: any) => {
            e.stopPropagation()
            onClick?.(e)
        },
        [onClick]
    )

    const _onMouseDown = useCallback(
        (e: any) => {
            e.preventDefault()
            onMouseDown?.(e)
        },
        [onMouseDown]
    )

    return (
        <div
            ref={ref}
            className={`${style.container} ${className ?? ''}`}
            {...otherProps}
            onKeyDown={_onKeyDown}
            onClick={_onClick}
            onMouseDown={_onMouseDown}
            data-testid={dataTestId ?? 'pure-block'}
        >
            {disabledTab ? null : <div tabIndex={0} className={style.focusElement}></div>}
            <PureBlockStruct opacity={opacity} rgb={rgb} colorSpace={colorSpace} size={size} />
        </div>
    )
}

export const PureBlock = forwardRef(_PureBlock)

interface PureBlockStructProps {
    opacity?: number
    rgb: Wukong.DocumentProto.IRGB
    colorSpace: 'srgb' | 'display-p3'
    size?: number
    className?: string
}
export function PureBlockStruct(props: PureBlockStructProps) {
    const { opacity = 1, rgb, colorSpace, size, className } = props
    return (
        <div
            className={classnames(style.pure, className)}
            style={{ backgroundColor: toCSSBackgroundColor(rgb, colorSpace), height: size, width: size }}
        >
            <div className={classnames(style.transparent, 'mosaic-3')} style={{ opacity: 1 - opacity }}></div>
        </div>
    )
}
