import { useCallback, useEffect, useState } from 'react'
import { fileDialog } from '../../../../../../ui-lib/src'
import { IMAGE_PATH_PREFIX } from '../../../../document/config/image-config'
import { WkCLog } from '../../../../kernel/clog/wukong/instance'
import { GetPrivateUploadAuthorization } from '../../../../kernel/request/upload'
import { useFeatureSwitch } from '../../../../kernel/switch/hooks'
import { useAIGenUIFrog } from './ai-gen-ui-princi'
import { useAIGenUIExamples } from './use-ai-gen-ui-example-prompts'
import { featureSwitchManager } from '../../../../kernel/switch'

const imageFileSizeValid = (file: File) => {
    if (file.size > 5 * 1024 * 1024) {
        return false
    }
    return true
}

const imageFileFormatValid = (file: File) => {
    if (
        file.type !== 'image/png' &&
        file.type !== 'image/jpg' &&
        file.type !== 'image/webp' &&
        file.type !== 'image/jpeg'
    ) {
        return false
    }
    return true
}

function base64ToBlob(base64String: string, contentType = 'image/png'): Blob {
    // Extract the base64 data part if the string contains a data URL prefix
    const base64Data = base64String.includes('base64,') ? base64String.split('base64,')[1] : base64String

    // Decode the base64 string to binary
    const byteCharacters = atob(base64Data)

    // Create an array buffer to hold the binary data
    const byteArrays: Uint8Array[] = []

    // Process the binary data in chunks to avoid memory issues
    for (let i = 0; i < byteCharacters.length; i += 1024) {
        const slice = byteCharacters.slice(i, i + 1024)

        const byteNumbers = new Array(slice.length)
        for (let j = 0; j < slice.length; j++) {
            byteNumbers[j] = slice.charCodeAt(j)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
    }

    // Create and return the Blob from the binary data
    return new Blob(byteArrays, { type: contentType })
}

export function useAIGenUIInputs(initOption: {
    initPrompt?: string
    image?: string
    styleConfigID?: number
    imageMetadata?: {
        type: string
        name: string
        size: number
    }
}) {
    const [prompt, setPrompt] = useState(initOption.initPrompt ?? '')
    const [imageFile, setImageFile] = useState<File | null>(null)
    const [uploadedImageUrl, setUploadedImageUrl] = useState<string | null>(null)
    const [uploadedStatus, setUploadedStatus] = useState<
        'pending' | 'success' | 'failed' | 'over-size' | 'file-format-restricted' | null
    >(null)

    // Use initial image metadata if provided
    const [imageMetadata, setImageMetadata] = useState<{
        type: string
        name: string
        size: number
    } | null>(initOption.imageMetadata ?? null)

    const [waitForResult, setWaitForResult] = useState(false)
    const [shouldRetry, setShouldRetry] = useState<false | 'one-failed' | 'either-failed'>(false)
    const maxLimitPrompt = useFeatureSwitch('ai-gen-ui-max-limit-prompt')
    const MAX_PROMPT_LENGTH = maxLimitPrompt ? 200_000 : 9999

    // Disable when
    // 1. Prompt is empty and image is not uploaded
    // 2. Prompt is trimmed empty
    const promptIsEmpty = prompt.length === 0 || prompt.trim().length === 0
    // 3. Prompt is too long
    const promptInvalid = prompt.length >= MAX_PROMPT_LENGTH

    // 4. Image is uploading
    // 5. Image is failed
    // 6. Image is over-size
    const imageNotUploaded = uploadedStatus === null
    const imageIsUploading = uploadedStatus === 'pending'
    const imageIsFailed = uploadedStatus === 'failed'
    const imageIsOverSize = uploadedStatus === 'over-size'
    const imageIsFormatRestricted = uploadedStatus === 'file-format-restricted'

    const imageInValid = imageIsUploading || imageIsFailed || imageIsOverSize || imageIsFormatRestricted

    const inputInvalid = promptInvalid || imageInValid || (promptIsEmpty && imageNotUploaded)

    // Disable Gen Button When:
    const disableGen = waitForResult || inputInvalid

    // If Exists Image, Disable Upload Image
    const disableUploadImage = imageFile !== null || uploadedStatus !== null

    const { examples, nextExample } = useAIGenUIExamples()

    const { clickAiGenUITryExampleButton } = useAIGenUIFrog()

    const applyExample = useCallback(() => {
        clickAiGenUITryExampleButton()
        if (!waitForResult) {
            setShouldRetry(false)
        }
        setPrompt(nextExample())
    }, [clickAiGenUITryExampleButton, nextExample, setPrompt, setShouldRetry, waitForResult])

    const uploadToOSS = useCallback(async (file: File) => {
        setUploadedStatus('pending')
        const imageArrBuf = await file.arrayBuffer()
        try {
            const { method, resourceId, contentType, ossUrl } = await new GetPrivateUploadAuthorization({
                format: file.type.split('/')[1],
            }).start()
            WkCLog.log('[AI GEN UI] UPLOAD IMAGE TO OSS', {
                fileType: file.type,
                format: file.type.split('/')[1],
                method,
                resourceId,
                contentType,
                ossUrl,
            })
            await fetch(ossUrl, {
                method,
                headers: {
                    'Content-Type': contentType,
                },
                body: imageArrBuf,
            })
            const img_url = IMAGE_PATH_PREFIX + resourceId
            setUploadedImageUrl(img_url)
            setUploadedStatus('success')

            // Set image metadata
            setImageMetadata({
                type: file.type,
                name: file.name,
                size: file.size,
            })

            return file
        } catch (e) {
            WkCLog.log('[AI GEN UI] UPLOAD IMAGE FAILED', { error: `${e}` })
            setUploadedStatus('failed')
            return null
        }
    }, [])

    useEffect(() => {
        if (
            initOption.image &&
            initOption.imageMetadata &&
            featureSwitchManager.isEnabled('ai-gen-config-platform-and-design-system')
        ) {
            try {
                // The initOption.image is base64 encoded string, then create image file
                const fileType = initOption.imageMetadata?.type || 'image/png'
                const fileName = initOption.imageMetadata?.name || 'image.png'
                const fileSize = initOption.imageMetadata?.size || 0

                const imageBlob = base64ToBlob(initOption.image)

                const base64ImageFile = new File([imageBlob], fileName, { type: fileType })
                setImageFile(base64ImageFile)
                setImageMetadata({
                    type: fileType,
                    name: fileName,
                    size: fileSize,
                })

                if (!imageFileSizeValid(base64ImageFile)) {
                    setUploadedStatus('over-size')
                } else if (!imageFileFormatValid(base64ImageFile)) {
                    setUploadedStatus('file-format-restricted')
                } else {
                    uploadToOSS(base64ImageFile)
                }
            } catch (error) {
                WkCLog.log('[AI GEN UI] Invalid image from AIGenWebSiteConfig', { error: `${error}` })
                setUploadedStatus('failed')
            }
        }
    }, [initOption.image, initOption.imageMetadata, uploadToOSS])

    const triggerPasteImage = useCallback(
        (e: React.ClipboardEvent<HTMLDivElement>) => {
            if (disableUploadImage) {
                return
            }
            const items = [...e.clipboardData.items]
            const firstImage = items.find((item) => item.type.startsWith('image/'))
            if (firstImage) {
                const file = firstImage.getAsFile()
                if (file) {
                    setImageFile(file)
                    setImageMetadata({
                        type: file.type,
                        name: file.name || 'pasted_image.png',
                        size: file.size,
                    })
                    if (!imageFileSizeValid(file)) {
                        setUploadedStatus('over-size')
                    } else if (!imageFileFormatValid(file)) {
                        setUploadedStatus('file-format-restricted')
                    } else {
                        uploadToOSS(file)
                    }
                }
            }
        },
        [disableUploadImage, uploadToOSS]
    )

    const triggerDragImage = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            if (disableUploadImage) {
                return
            }
            const items = [...e.dataTransfer.items]
            const firstImage = items.find((item) => item.type.startsWith('image/'))
            if (firstImage) {
                const file = firstImage.getAsFile()
                if (file) {
                    setImageFile(file)
                    setImageMetadata({
                        type: file.type,
                        name: file.name || 'dragged_image.png',
                        size: file.size,
                    })
                    if (!imageFileSizeValid(file)) {
                        setUploadedStatus('over-size')
                    } else if (!imageFileFormatValid(file)) {
                        setUploadedStatus('file-format-restricted')
                    } else {
                        uploadToOSS(file)
                    }
                }
            }
        },
        [disableUploadImage, uploadToOSS]
    )

    const triggerUploadImage = useCallback(async () => {
        if (disableUploadImage) {
            return
        }
        const files = await fileDialog({
            multiple: false,
            accept:
                'image/*' +
                // WebP
                ',.webp' +
                // heif
                ',.heif,.heifs,.heic,.heics,.avci,.avcs,.avif,.avifs',
        }).catch((_) => {
            return null
        })
        if (files?.length) {
            const file = files[0]
            setImageFile(file)
            setImageMetadata({
                type: file.type,
                name: file.name,
                size: file.size,
            })
            if (!imageFileSizeValid(file)) {
                setUploadedStatus('over-size')
            } else if (!imageFileFormatValid(file)) {
                setUploadedStatus('file-format-restricted')
            } else {
                uploadToOSS(file)
            }
        }
    }, [disableUploadImage, uploadToOSS])

    const triggerRemoveImage = useCallback(() => {
        if (!waitForResult) {
            setImageFile(null)
            setUploadedImageUrl(null)
            setUploadedStatus(null)
            setImageMetadata(null)
            setShouldRetry(false)
        }
    }, [setImageFile, waitForResult])

    return {
        examples,
        prompt,
        imageFile,
        uploadedImageUrl,
        uploadedStatus,
        imageMetadata,
        waitForResult,
        shouldRetry,
        MAX_PROMPT_LENGTH,
        disableGen,
        disableUploadImage,
        setPrompt,
        setImageFile,
        setUploadedStatus,
        setUploadedImageUrl,
        setImageMetadata,
        setWaitForResult,
        setShouldRetry,
        applyExample,
        triggerPasteImage,
        triggerDragImage,
        triggerUploadImage,
        triggerRemoveImage,
    }
}
