import { POST } from '@tutor/network-core'
import { AISearchRequest, AISearchResponse } from '../interface/ai-search'
import { BaseCommonRequest } from './base-request'
@POST
export class AISearch extends BaseCommonRequest<AISearchResponse> {
    constructor(private request: AISearchRequest, private imageData: File) {
        super()
    }

    public override requestUrl() {
        return `ai/search`
    }

    public override requestBody() {
        const form = new FormData()
        form.append('request', JSON.stringify(this.request))
        form.append('imageData', this.imageData)
        return form
    }
}
