import { forwardRef } from 'react'
import {
    TriggerMinimal as SelectTriggerMinimal,
    TriggerMinimalProps as SelectTriggerMinimalProps,
    TriggerMinimalRef as SelectTriggerMinimalRef,
} from '../../selects/triggers/trigger-minimal'

export interface TriggerMinimalProps extends SelectTriggerMinimalProps {}
export interface TriggerMinimalRef extends SelectTriggerMinimalRef {}

function _TriggerMinimal(props: TriggerMinimalProps, ref?: React.Ref<TriggerMinimalRef>) {
    return (
        <SelectTriggerMinimal ref={ref} {...props}>
            {props.children}
        </SelectTriggerMinimal>
    )
}

export const TriggerMinimal = forwardRef(_TriggerMinimal)
