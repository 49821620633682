import { translation } from './expire-login-pop.translation'
/* eslint-disable no-restricted-imports */
import { wkDialogConfirm } from '../../../../ui-lib/src'
import { domLocation } from '../../../../util/src'

export function DetectExpireLoginPop() {
    if (document.getElementsByClassName('expireTimeModalPop').length <= 0) {
        hiddenOtherDialog()

        wkDialogConfirm.warning({
            closable: false,
            title: translation('AccountLoginStatus'),
            okText: translation('Refresh'),
            className: 'expireTimeModalPop',
            onClose() {
                domLocation().reload()
            },
            onOk() {
                domLocation().reload()
            },
        })
    }
}

function hiddenOtherDialog() {
    const dialogs = document.querySelectorAll(`[role="dialog"]`)
    for (const dialog of dialogs) {
        const root = dialog.parentElement
        if (root && root !== document.body) {
            root.style.display = 'none'
        }
        ;(dialog as HTMLElement).style.display = 'none'
    }
}
