import { MotiffApi } from '@motiffcom/plugin-api-types'
import {
    LogPluginScriptErrorJS,
    PluginApiCancelNotifyJS,
    PluginApiCreateExternalPromise,
    PluginApiCreateImageJS,
    PluginApiDropEventNeeded,
    PluginApiEventNotifyCommand,
    PluginApiExportAsyncJS,
    PluginApiGetFullAvatarImageUrlJS,
    PluginApiImportRemoteLibNodeByKeyAsyncCallbackCommand,
    PluginApiImportRemoteLibNodeByKeyAsyncCommandJs,
    PluginApiNotifyCallback,
    PluginApiNotifyJS,
    PluginApiResolveExternalPromiseCommand,
    PluginApiSaveEventsToHostServiceContextCommandJS,
    PluginApiSaveVersionHistoryCallbackCommand,
    PluginApiSaveVersionHistoryCommandJS,
    PluginApiShowUICommandJS,
    PluginApiUICloseJS,
    PluginApiUIHideJS,
    PluginApiUIRepositionJS,
    PluginApiUIResizeJS,
    PluginApiUIShowJS,
    Wukong,
} from '@wukong/bridge-proto'
import { WKToast } from '../../../../ui-lib/src'
import { createImmerStore, createSelectors } from '../../../../util/src'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { transaction } from '../../../../util/src/abort-controller/traceable-transaction'
import { WukongEditor } from '../../editor'
import { IN_JEST_TEST } from '../../environment'
import { Bridge } from '../../kernel/bridge/bridge'
import { getFullAvatarImageUrl } from '../../kernel/request/upload'
import { RawMemAccessService } from '../../main/service/raw-mem-access-service'
import { CppVM } from '../../plugin-vm/cpp-vm'
import { MainVM } from '../../plugin-vm/main-vm'
import { PluginAPIContext } from '../../plugin-vm/plugin-api-context-impl'
import { LibraryResourceOssClientType } from '../../share/component-style-library/service/library-resource-downloader'
import { LibraryComponentService } from '../../ui/component/component-style-library-v2/library-service/library-component-service'
import { nodeToBlob } from '../../ui/component/design/export/util'
import { HistoryService } from '../../ui/component/history-file/history-service/history-service'
import { PluginId } from '../../ui/component/plugin/plugin-development/template/type'
import { ViewStateBridge } from '../../view-state-bridge'
import { CanvasRenderBridge } from '../bridge/canvas-render-bridge'
import { CommandInvoker } from '../command/command-invoker'
import { CommitType } from '../command/commit-type'
import { ImageDownloadContext } from '../command/image-download-context'
import { readFromFile } from '../util/file'
import { IPluginHostService, ShowUIOptions } from './plugin-host-service-interface'

interface PluginUIState {
    isOpen: boolean
    visible: boolean
    width: number
    height: number
    title: string
    position:
        | {
              x: number
              y: number
          }
        | undefined
    iconUrl: string | undefined
}

export interface LoadPluginOptions {
    rawCode: string
    id: PluginId
    name: string
    iconUrl?: string
    useSandbox: boolean
    runningMode: 'default' | 'inspect' | 'codegen'
}

declare global {
    interface Window {
        motiff: MotiffApi.PluginAPI
    }

    const motiff: MotiffApi.PluginAPI
}

export class PluginHostService implements IPluginHostService {
    private zustandStore = createImmerStore<PluginUIState>(() => ({
        isOpen: false,
        visible: false,
        width: 0,
        height: 0,
        title: '',
        position: undefined,
        iconUrl: undefined,
    }))
    public useZustandStore = createSelectors(this.zustandStore)

    private containerDiv?: HTMLDivElement

    private renderIframe?: HTMLIFrameElement

    private iframeLoaded: Promise<void> | undefined

    private notifyIdMap = new Map<number, string>()

    private loadPluginOptions?: LoadPluginOptions

    private closePluginCallbacks = new Set<() => void>()

    private currentPluginApiContext!: PluginAPIContext

    private mainVMPluginApiContext!: PluginAPIContext | undefined

    private cppVMPluginApiContext?: PluginAPIContext

    private dropEventCallback: ((event: DragEvent) => boolean) | undefined

    constructor(
        private readonly bridge: Bridge,
        private commandInvoker: CommandInvoker,
        private userId: number,
        private historyService: HistoryService,
        private imageDownloadContext: ImageDownloadContext,
        private readonly canvasRenderBridge: CanvasRenderBridge,
        private readonly rawMemAccessService: RawMemAccessService,
        private signal: TraceableAbortSignal,
        private readonly libraryComponentService: LibraryComponentService,
        private editor: WukongEditor,
        private readonly docReadonly: boolean,
        private readonly viewStateBridge: ViewStateBridge
    ) {
        ;(window as any).pluginHostService = this
        const { act } = transaction(signal)

        act('init pluginHostServuce', () => {
            this.closePluginCallbacks = new Set()

            this.mainVMPluginApiContext = new PluginAPIContext(new MainVM(), this.bridge, this)
            this.mainVMPluginApiContext.createAPI()
            this.currentPluginApiContext = this.mainVMPluginApiContext

            if (window.localBridge) {
                ;(window as any).figma = motiff
            }

            return () => {
                this.closePluginCallbacks.clear()
                delete (window as any).motiff
                delete (window as any).figma
            }
        })

        this.bridge.bind(
            PluginApiShowUICommandJS,
            (arg) => {
                this.showUI(arg.html, {
                    visible: arg.visible,
                    width: arg.width,
                    height: arg.height,
                    title: arg.title,
                    position: arg.position as { x: number; y: number } | undefined,
                })
            },
            { signal: this.signal }
        )

        this.bridge.bind(
            PluginApiUIShowJS,
            () => {
                this.show()
            },
            { signal: this.signal }
        )

        this.bridge.bind(
            PluginApiUIHideJS,
            () => {
                this.hide()
            },
            { signal: this.signal }
        )

        this.bridge.bind(
            PluginApiUICloseJS,
            () => {
                this.close()
            },
            { signal: this.signal }
        )

        this.bridge.bind(
            PluginApiUIResizeJS,
            (arg) => {
                this.resize(arg.width, arg.height)
            },
            { signal: this.signal }
        )

        this.bridge.bind(
            PluginApiUIRepositionJS,
            (arg) => {
                this.reposition(arg.x, arg.y)
            },
            { signal: this.signal }
        )

        this.bridge.bind(
            PluginApiNotifyJS,
            (arg) => {
                let buttonClicked = false
                const toastArg = {
                    duration: arg.timeout as any,
                    firstButton:
                        arg.buttonActionId !== -1
                            ? {
                                  type: 'button' as const,
                                  text: arg.buttonText,
                                  onClick: () => {
                                      buttonClicked = true
                                      this.commandInvoker.invokeBridge(CommitType.Noop, PluginApiNotifyCallback, {
                                          buttonActionId: arg.buttonActionId,
                                          onDequeueCallbackId: arg.onDequeueCallbackId,
                                          dequeueReason: 'action_button_click',
                                      })
                                      this.notifyIdMap.delete(arg.cancelId)
                                  },
                              }
                            : undefined,
                    onClose: () => {
                        if (buttonClicked) {
                            return
                        }
                        this.commandInvoker.invokeBridge(CommitType.Noop, PluginApiNotifyCallback, {
                            buttonActionId: -1,
                            onDequeueCallbackId: arg.onDequeueCallbackId,
                            dequeueReason: 'timeout',
                        })
                        this.notifyIdMap.delete(arg.cancelId)
                    },
                }
                let key: string
                if (arg.error) {
                    key = WKToast.error(arg.message, toastArg)
                } else {
                    key = WKToast.show(arg.message, toastArg)
                }

                this.notifyIdMap.set(arg.cancelId, key)
            },
            { signal: this.signal }
        )

        this.bridge.bind(
            PluginApiCancelNotifyJS,
            (arg) => {
                if (!arg.value) {
                    return
                }
                const key = this.notifyIdMap.get(arg.value)
                if (key) {
                    WKToast.close(key)
                }
                this.notifyIdMap.delete(arg.value)
            },
            { signal: this.signal }
        )

        this.bridge.bind(
            PluginApiSaveVersionHistoryCommandJS,
            (arg) => {
                this.historyService
                    .requestAddVersionForPluginApi(arg.title, arg.description)
                    .then((res) => {
                        this.commandInvoker.invokeBridge(CommitType.Noop, PluginApiSaveVersionHistoryCallbackCommand, {
                            id: res.id.toString(),
                            success: true,
                            cbId: arg.cbId,
                        })
                    })
                    .catch(() => {
                        this.commandInvoker.invokeBridge(CommitType.Noop, PluginApiSaveVersionHistoryCallbackCommand, {
                            id: '',
                            success: false,
                            cbId: arg.cbId,
                        })
                    })
            },
            { signal: this.signal }
        )

        this.bridge.bind(
            PluginApiImportRemoteLibNodeByKeyAsyncCommandJs,
            (arg) => {
                let ossClientType = LibraryResourceOssClientType.Component
                switch (arg.importNodeType) {
                    case Wukong.DocumentProto.PluginApiImportLibNodeType.PLUGIN_API_IMPORT_LIB_NODE_TYPE_COMPONENT:
                    case Wukong.DocumentProto.PluginApiImportLibNodeType.PLUGIN_API_IMPORT_LIB_NODE_TYPE_COMPONENT_SET:
                        ossClientType = LibraryResourceOssClientType.Component
                        break
                    case Wukong.DocumentProto.PluginApiImportLibNodeType.PLUGIN_API_IMPORT_LIB_NODE_TYPE_STYLE:
                        ossClientType = LibraryResourceOssClientType.Style
                        break
                }

                this.libraryComponentService.libraryNodeDataService
                    .fetchRemoteExportedDocument({
                        remoteDocId: arg.remoteDocId,
                        remoteNodeId: arg.remoteNodeId,
                        nodeDataPath: arg.nodeDataPath,
                        ossClientType,
                        isLocal: false,
                        localNodeId: null,
                    })
                    .then((res) => {
                        this.commandInvoker.invokeBridge(
                            CommitType.CommitUndo,
                            PluginApiImportRemoteLibNodeByKeyAsyncCallbackCommand,
                            {
                                cbId: arg.cbId,
                                success: true,
                                importNodeType: arg.importNodeType,
                                key: arg.key,
                                toCreateNodeId: res.toCreateNodeId,
                                exportedDocument: res.exportedDocument,
                            }
                        )
                    })
                    .catch(() => {
                        this.commandInvoker.invokeBridge(
                            CommitType.Noop,
                            PluginApiImportRemoteLibNodeByKeyAsyncCallbackCommand,
                            {
                                cbId: arg.cbId,
                                success: false,
                                importNodeType: arg.importNodeType,
                                key: '',
                                toCreateNodeId: '',
                                exportedDocument: {},
                            }
                        )
                    })
            },
            {
                signal: this.signal,
            }
        )

        this.bridge.bind(
            PluginApiCreateImageJS,
            (arg) => {
                const hash = this.imageDownloadContext.addImageSourceForPlugin(arg.value!)

                return {
                    hash,
                    error: {
                        message: undefined,
                    },
                }
            },
            { signal: this.signal }
        )

        this.bridge.bind(
            LogPluginScriptErrorJS,
            (arg) => {
                console.warn(arg.value)
            },
            { signal: this.signal }
        )

        bridge.bind(PluginApiExportAsyncJS, this.exportAsync, { signal: this.signal })

        bridge.bind(
            PluginApiGetFullAvatarImageUrlJS,
            (arg) => {
                return Wukong.DocumentProto.BridgeProtoString.create({
                    value: getFullAvatarImageUrl(arg.value!, { minify: true, width: 60 }),
                })
            },
            { signal: this.signal }
        )

        const enableHostServiceContextEventStore = () => {
            const batches: Wukong.DocumentProto.IArg_PluginCallbackEventCollection[] = []
            let setTimeoutId: NodeJS.Timeout | undefined = undefined

            const appendEvents = (arg: Wukong.DocumentProto.IArg_PluginCallbackEventCollection) => {
                if (setTimeoutId) {
                    clearTimeout(setTimeoutId)
                    setTimeoutId = undefined
                }

                batches.push(arg)

                setTimeoutId = setTimeout(() => {
                    // 此处仅同步消费 batches, 不生产 batches (不触发 appendEvents)

                    // dispatch events by call a WCC
                    this.commandInvoker.invokeBridge(CommitType.Noop, PluginApiEventNotifyCommand, {
                        events: batches.flatMap((e) => e.events),
                    })

                    // clear batches
                    batches.length = 0
                }, 0)
            }

            return appendEvents
        }

        bridge.bind(PluginApiSaveEventsToHostServiceContextCommandJS, enableHostServiceContextEventStore(), {
            signal: this.signal,
        })

        document.addEventListener('drop', this.pluginDropListener, {
            capture: true,
            signal: this.signal,
        })
    }

    getUserId() {
        return this.userId.toString()
    }

    getPluginId() {
        if (IN_JEST_TEST) {
            return 'jest-plugin-id'
        }
        return this.loadPluginOptions?.id ?? ''
    }

    isDocReadonly() {
        return this.docReadonly
    }

    get currentRunningPlugin() {
        return this.loadPluginOptions
    }

    convertDataTransferItemListToDropItemArray = async (items?: DataTransferItemList) => {
        if (!items) {
            return []
        }

        const dropItems: {
            type: string
            data: string
        }[] = []

        const getDataTransferItem = (item: DataTransferItem): Promise<string> => {
            return new Promise((resolve, _reject) => {
                item.getAsString((content) => {
                    resolve(content)
                })
            })
        }

        if (items !== undefined) {
            for (const item of items) {
                if (item.kind === 'string') {
                    // must copy type first, because after item to be read, type will be empty
                    const type = item.type
                    const data = await getDataTransferItem(item)
                    dropItems.push({
                        type,
                        data,
                    })
                }
            }
        }

        return dropItems
    }

    convertFileListToFileArray = (files?: FileList) => {
        if (!files) {
            return []
        }

        const dropFiles: {
            name: string
            type: string
            getBytesAsyncPromiseId: number
            getTextAsyncPromiseId: number
        }[] = []

        if (files !== undefined) {
            for (const file of files) {
                const getBytesAsyncPromiseId = this.commandInvoker.invokeBridge(
                    CommitType.Noop,
                    PluginApiCreateExternalPromise
                ).value!
                const getTextAsyncPromiseId = this.commandInvoker.invokeBridge(
                    CommitType.Noop,
                    PluginApiCreateExternalPromise
                ).value!

                file.arrayBuffer().then((buffer: ArrayBuffer) => {
                    this.commandInvoker.invokeBridge(CommitType.Noop, PluginApiResolveExternalPromiseCommand, {
                        id: getBytesAsyncPromiseId,
                        type: Wukong.DocumentProto.ExternalPromiseType.EXTERNAL_PROMISE_TYPE_BYTES,
                        buffer: new Uint8Array(buffer),
                    })
                })

                file.text().then((text: string) => {
                    this.commandInvoker.invokeBridge(CommitType.Noop, PluginApiResolveExternalPromiseCommand, {
                        id: getTextAsyncPromiseId,
                        type: Wukong.DocumentProto.ExternalPromiseType.EXTERNAL_PROMISE_TYPE_STRING,
                        buffer: Wukong.DocumentProto.BridgeProtoString.encodeDelimited({
                            value: text,
                        }).finish(),
                    })
                })

                dropFiles.push({
                    name: file.name,
                    type: file.type,
                    getBytesAsyncPromiseId,
                    getTextAsyncPromiseId,
                })
            }
        }
        return dropFiles
    }

    onIframeMessage = async (msg: MessageEvent) => {
        // jest 的 msg.source 始终是空

        if (IN_JEST_TEST || msg.source === this.renderIframe?.contentWindow) {
            if (this.currentPluginApiContext) {
                this.currentPluginApiContext.onIframeMessage(msg)
                return
            }
        }
    }

    onContainerDivMount = (div: HTMLDivElement | null) => {
        this.containerDiv = div ?? undefined
        if (this.containerDiv) {
            this.tryAppendRenderIframe(this.renderIframe, this.containerDiv)
        }
    }

    async postMessageToIframe(data: any) {
        if (!this.renderIframe) return

        await this.iframeLoaded

        this.renderIframe.contentWindow?.postMessage(
            {
                pluginMessage: data,
            },
            '*'
        )
    }

    registerDropEventCallback(callback: ((event: DragEvent) => boolean) | undefined) {
        this.dropEventCallback = callback
    }

    showUI(html: string, options?: ShowUIOptions) {
        if (this.renderIframe) {
            this.renderIframe.remove()
            this.renderIframe = undefined
        }
        window.addEventListener('message', this.onIframeMessage, { signal: this.signal })

        const width = Math.max(options?.width ?? 300, 70)
        const height = Math.max(options?.height ?? 200, 0)

        const renderIframe = document.createElement('iframe')
        renderIframe.src = 'about:blank'
        this.iframeLoaded = new Promise((res) => {
            renderIframe.onload = () => {
                if (!IN_JEST_TEST) {
                    renderIframe.contentDocument!.open()
                    renderIframe.contentDocument!.write(html.replace(/<!DOCTYPE html>/i, ''))
                    renderIframe.contentDocument!.close()
                }
                res()
            }
        })

        this.renderIframe = renderIframe

        renderIframe.allow = [
            `camera 'none'`,
            `microphone 'none'`,
            `display-capture 'none'`,
            `clipboard-write 'none'`,
        ].join(';')
        renderIframe.style.border = 'none'
        renderIframe.style.margin = '0'
        renderIframe.style.padding = '0'
        renderIframe.style.backgroundColor = 'white'
        renderIframe.style.width = `${width}px`
        renderIframe.style.height = `${height}px`
        renderIframe.dataset.testid = 'plugin-iframe'

        this.zustandStore.setState((store) => {
            store.isOpen = true
            store.visible = options?.visible ?? true
            store.width = width
            store.height = height
            store.title = options?.title?.length ? options?.title : this.loadPluginOptions?.name ?? '未知插件'
            store.position = options?.position
            store.iconUrl = this.loadPluginOptions?.iconUrl
        })

        this.tryAppendRenderIframe(this.renderIframe, this.containerDiv)
    }

    tryAppendRenderIframe(iframe: HTMLIFrameElement | undefined, container: HTMLDivElement | undefined) {
        if (!iframe) {
            return
        }

        if (container) {
            iframe.style.display = 'block'
            container.appendChild(iframe)
        }
    }

    closePlugin(msg?: string) {
        this.zustandStore.setState((store) => {
            store.isOpen = false
            store.visible = false
        })
        this.loadPluginOptions = undefined
        this.renderIframe = undefined
        if (msg) {
            WKToast.show(msg)
        }
        this.closePluginCallbacks.forEach((callback) => {
            callback()
        })
    }

    show() {
        this.zustandStore.setState((store) => {
            store.visible = true
        })
    }

    hide() {
        this.zustandStore.setState((store) => {
            store.visible = false
        })
    }

    close() {
        this.hide()
        this.renderIframe = undefined
    }

    resize(width: number, height: number) {
        this.zustandStore.setState((store) => {
            store.width = width
            store.height = height
        })
        this.renderIframe!.style.width = `${width}px`
        this.renderIframe!.style.height = `${height}px`
    }

    reposition(x: number, y: number) {
        this.zustandStore.setState((store) => {
            store.position = {
                x,
                y,
            }
        })
    }

    public bindClosePluginCallback = (callback: () => void) => {
        this.closePluginCallbacks.add(callback)
    }

    public loadPlugin(options: LoadPluginOptions) {
        motiff.closePlugin()
        setTimeout(() => {
            this.loadPluginOptions = options
            const scopedCode = `(async () => { var window = undefined; var figma = globalThis.motiff; \n ${options.rawCode} \n })()`
            this.prepareVM(options.useSandbox)
            this.currentPluginApiContext!.evalCode(scopedCode)
        })
    }

    //
    private pluginDropListener = async (event: DragEvent) => {
        const canvasOverlay = document.getElementById('WKC-Overlay')
        if (canvasOverlay && event.target === canvasOverlay) {
            // offsetX/Y 即为以 canvas 为参考系 的 drop 事件的坐标
            if (this.commandInvoker.invokeBridge(CommitType.Noop, PluginApiDropEventNeeded).value) {
                const ret = this.dropEventCallback?.(event)

                // 根据事件回调的返回值，决定是否阻止默认行为
                if (!ret) {
                    event.preventDefault()
                    event.stopPropagation()
                }
            }
        }
    }

    private exportAsync = (args: Wukong.DocumentProto.IArg_PluginApiExportSetting) => {
        const promiseId = this.commandInvoker.invokeBridge(CommitType.Noop, PluginApiCreateExternalPromise).value!

        const exportSettingsForNodeToBlob = {
            command: this.commandInvoker,
            canvasRenderBridge: this.canvasRenderBridge,
            rawMemAccessService: this.rawMemAccessService,
            nodeIds: [args.id],
            format: Wukong.DocumentProto.ExportFormatType.EXPORT_FORMAT_TYPE_PNG,
            constraint: {
                type: Wukong.DocumentProto.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_SCALE,
                value: 1,
            },
            colorProfile: 0,
            isCompresImage: false,
        }

        // format
        switch (args.format) {
            case 'WEBP':
                exportSettingsForNodeToBlob.format = Wukong.DocumentProto.ExportFormatType.EXPORT_FORMAT_TYPE_WEBP
                break
            case 'AVIF':
                exportSettingsForNodeToBlob.format = Wukong.DocumentProto.ExportFormatType.EXPORT_FORMAT_TYPE_AVIF
                break
            case 'PNG':
                exportSettingsForNodeToBlob.format = Wukong.DocumentProto.ExportFormatType.EXPORT_FORMAT_TYPE_PNG
                break
            case 'JPG':
                exportSettingsForNodeToBlob.format = Wukong.DocumentProto.ExportFormatType.EXPORT_FORMAT_TYPE_JPG
                break
            case 'PDF':
                exportSettingsForNodeToBlob.format = Wukong.DocumentProto.ExportFormatType.EXPORT_FORMAT_TYPE_PDF
                break
            case 'SVG':
                exportSettingsForNodeToBlob.format = Wukong.DocumentProto.ExportFormatType.EXPORT_FORMAT_TYPE_SVG
                break
            case 'SVG_STRING':
                // treat as SVG, need to decode binary data to string later
                exportSettingsForNodeToBlob.format = Wukong.DocumentProto.ExportFormatType.EXPORT_FORMAT_TYPE_SVG
                break
            default:
                throw new Error(`Unsupported export format: ${args.format}`)
        }

        // colorProfile
        if (args.colorProfile) {
            switch (args.colorProfile) {
                case 'SRGB':
                    exportSettingsForNodeToBlob.colorProfile =
                        Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B
                    break
                case 'DISPLAY_P3_V4':
                    exportSettingsForNodeToBlob.colorProfile =
                        Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILE_DISPLAY_P3
                    break
                default:
                    throw new Error(`Unsupported color profile: ${args.colorProfile}`)
            }
        }

        // constraint
        if (args.constraint) {
            exportSettingsForNodeToBlob.constraint.value = args.constraint.value

            switch (args.constraint.type) {
                case 'SCALE':
                    exportSettingsForNodeToBlob.constraint.type =
                        Wukong.DocumentProto.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_SCALE
                    break
                case 'WIDTH':
                    exportSettingsForNodeToBlob.constraint.type =
                        Wukong.DocumentProto.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_WIDTH
                    break
                case 'HEIGHT':
                    exportSettingsForNodeToBlob.constraint.type =
                        Wukong.DocumentProto.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_HEIGHT
                    break
                default:
                    throw new Error(`Unsupported constraint type: ${args.constraint.type}`)
            }
        }

        nodeToBlob(
            exportSettingsForNodeToBlob.command,
            exportSettingsForNodeToBlob.canvasRenderBridge,
            exportSettingsForNodeToBlob.rawMemAccessService,
            exportSettingsForNodeToBlob.nodeIds,
            exportSettingsForNodeToBlob.format,
            exportSettingsForNodeToBlob.constraint,
            exportSettingsForNodeToBlob.colorProfile,
            exportSettingsForNodeToBlob.isCompresImage
        )
            // TODO(jiangjk): avoid convert blob to array buffer
            .then((blob) => blob?.arrayBuffer()) // Convert blob to array buffer
            .then((buffer) => {
                this.commandInvoker.invokeBridge(CommitType.Noop, PluginApiResolveExternalPromiseCommand, {
                    id: promiseId,
                    type: Wukong.DocumentProto.ExternalPromiseType.EXTERNAL_PROMISE_TYPE_BYTES,
                    buffer: new Uint8Array(buffer!), // Convert buffer to Uint8Array
                })
            })

        return {
            value: promiseId,
        }
    }

    private prepareVM(useSandbox: boolean) {
        if (useSandbox) {
            this.cppVMPluginApiContext = new PluginAPIContext(new CppVM(this.editor, this.bridge), this.bridge, this)
            this.cppVMPluginApiContext.createAPI()
            this.currentPluginApiContext = this.cppVMPluginApiContext
        } else {
            this.mainVMPluginApiContext = new PluginAPIContext(new MainVM(), this.bridge, this)
            this.mainVMPluginApiContext.createAPI()
            this.currentPluginApiContext = this.mainVMPluginApiContext
        }
    }

    // appendOnly 是测试专用参数
    // 对业务而言，appendOnly 始终是 false，这个方法只会在加载插件时被调用一次
    public loadPluginScript(pluginScript: string, pluginId: string, useSandbox = true, appendOnly = false) {
        if (!appendOnly) {
            this.prepareVM(useSandbox)
        }
        this.currentPluginApiContext!.evalCode(
            `globalThis.figma = globalThis.motiff; (async function main() {var window = undefined;  ${pluginScript} })()`
        )
    }

    public async loadPackedPluginFromFile(pluginId = 'testPlugin', useSandbox = true) {
        const fileContent = await readFromFile('.js')
        this.loadPluginScript(fileContent, pluginId, useSandbox)
    }

    getCurrentEditorType() {
        const type = this.viewStateBridge.getDefaultValue('editorType')
        if (type === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV) {
            return 'dev'
        } else {
            return 'design'
        }
    }

    getCurrentRunningMode() {
        return this.loadPluginOptions?.runningMode ?? 'default'
    }
}
