import { translation } from './scale-center.translation'
import { Wukong } from '@wukong/bridge-proto'
import { useCallback, useMemo } from 'react'

import style from './scale-center.module.less'

import LayoutOrigin = Wukong.DocumentProto.LayoutOrigin

interface ScaleCenterProps {
    layoutOrigin?: LayoutOrigin
    onChange?: (origin: LayoutOrigin) => void
}

export function ScaleCenter(props: ScaleCenterProps) {
    const { layoutOrigin = LayoutOrigin.LAYOUT_ORIGIN_TOP_LEFT, onChange } = props

    const currentLayoutOriginName = useMemo(() => {
        return getScaleCenterPointName(layoutOrigin)
    }, [layoutOrigin])

    return (
        <div className={style.container}>
            <div className={style.grid}>
                <GridItem
                    selfOrigin={LayoutOrigin.LAYOUT_ORIGIN_TOP_LEFT}
                    selectedOrigin={layoutOrigin}
                    onChange={onChange}
                />
                <GridItem
                    selfOrigin={LayoutOrigin.LAYOUT_ORIGIN_TOP}
                    selectedOrigin={layoutOrigin}
                    onChange={onChange}
                />
                <GridItem
                    selfOrigin={LayoutOrigin.LAYOUT_ORIGIN_TOP_RIGHT}
                    selectedOrigin={layoutOrigin}
                    onChange={onChange}
                />
                <GridItem
                    selfOrigin={LayoutOrigin.LAYOUT_ORIGIN_LEFT}
                    selectedOrigin={layoutOrigin}
                    onChange={onChange}
                />
                <GridItem
                    selfOrigin={LayoutOrigin.LAYOUT_ORIGIN_CENTER}
                    selectedOrigin={layoutOrigin}
                    onChange={onChange}
                />
                <GridItem
                    selfOrigin={LayoutOrigin.LAYOUT_ORIGIN_RIGHT}
                    selectedOrigin={layoutOrigin}
                    onChange={onChange}
                />
                <GridItem
                    selfOrigin={LayoutOrigin.LAYOUT_ORIGIN_BOTTOM_LEFT}
                    selectedOrigin={layoutOrigin}
                    onChange={onChange}
                />
                <GridItem
                    selfOrigin={LayoutOrigin.LAYOUT_ORIGIN_BOTTOM}
                    selectedOrigin={layoutOrigin}
                    onChange={onChange}
                />
                <GridItem
                    selfOrigin={LayoutOrigin.LAYOUT_ORIGIN_BOTTOM_RIGHT}
                    selectedOrigin={layoutOrigin}
                    onChange={onChange}
                />
                <div className={style.top}></div>
                <div className={style.bottom}></div>
                <div className={style.left}></div>
                <div className={style.right}></div>
            </div>
            <div className={style.description}>{currentLayoutOriginName}</div>
        </div>
    )
}

export interface GridItemProps {
    selfOrigin: LayoutOrigin
    selectedOrigin: LayoutOrigin
    onChange?: (origin: LayoutOrigin) => void
}
export function GridItem(props: GridItemProps) {
    const { selfOrigin, selectedOrigin, onChange } = props

    const onClick = useCallback(() => {
        onChange?.(selfOrigin)
    }, [onChange, selfOrigin])

    return (
        <div
            onClick={onClick}
            className={`${style.gridItem} ${selfOrigin === selectedOrigin ? style.selected : ''} ${style.v2}`}
        ></div>
    )
}

function getScaleCenterPointName(layoutOrigin: LayoutOrigin) {
    switch (layoutOrigin) {
        case LayoutOrigin.LAYOUT_ORIGIN_TOP_LEFT:
            return translation('ScaleTopLeft')
        case LayoutOrigin.LAYOUT_ORIGIN_TOP:
            return translation('ScaleTop')
        case LayoutOrigin.LAYOUT_ORIGIN_TOP_RIGHT:
            return translation('ScaleTopRight')
        case LayoutOrigin.LAYOUT_ORIGIN_LEFT:
            return translation('ScaleLeft')
        case LayoutOrigin.LAYOUT_ORIGIN_CENTER:
            return translation('ScaleCenter')
        case LayoutOrigin.LAYOUT_ORIGIN_RIGHT:
            return translation('ScaleRight')
        case LayoutOrigin.LAYOUT_ORIGIN_BOTTOM_LEFT:
            return translation('ScaleBottomLeft')
        case LayoutOrigin.LAYOUT_ORIGIN_BOTTOM:
            return translation('ScaleBottom')
        case LayoutOrigin.LAYOUT_ORIGIN_BOTTOM_RIGHT:
            return translation('ScaleBottomRight')
        default:
            return ''
    }
}
