/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { signalAnimationFrame, signalMicrotask } from '../../../../util/src/abort-controller/timers'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { isSafari } from '../../kernel/util/ua'
import { ViewStateBridge } from '../../view-state-bridge'
import { handleMouseEventForDevMode } from '../dev-mode/dev-mode-mouse-event'
import type { HandlerProvider } from '../handler-provider/handler-provider'
import type { ModifierKeys } from './canvas-event-handler'
import { handleCanvasMouseEvent, missingMiddleUpEventHandler } from './canvas-event-handler'

export class CanvasEventDelegator {
    constructor(
        private readonly viewStateBridge: ViewStateBridge,
        private readonly handlerProvider: HandlerProvider,
        private readonly canvas: HTMLCanvasElement,
        private readonly signal: TraceableAbortSignal
    ) {}

    protected sendMoveEvent = (e: MouseEvent) => {
        this.signal.throwIfAborted()
        if (this.viewStateBridge.getDefaultValue('editorType') === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV) {
            handleMouseEventForDevMode(this.handlerProvider.bridge, e)
        } else {
            this.handlerProvider.handle(handleCanvasMouseEvent, [e, this.canvas])
        }
    }

    protected sendMouseEventAfterEnter = (e: MouseEvent) => {
        if (missingMiddleUpEventHandler.shouldFakeMouseUpEventWhenEnterDocument(e)) {
            this.sendMoveEvent(missingMiddleUpEventHandler.fakeMouseUpEvent(e))
        }
    }

    protected resendMouseEvent = (keys: ModifierKeys = {}) => {
        // NOTE: 新模式不执行 resend
        if (this.viewStateBridge.getDefaultValue('editorType') === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV) {
            return
        }
        this.handlerProvider.handle(handleCanvasMouseEvent, [keys, this.canvas])
    }

    private onKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Shift') {
            this.resendMouseEvent({
                shiftKey: true,
            })
        } else if (e.key === 'Alt') {
            this.resendMouseEvent({
                altKey: true,
            })
        } else if (e.key === 'Meta') {
            this.resendMouseEvent({
                metaKey: true,
            })
        } else if (e.key === 'Control') {
            this.resendMouseEvent({
                ctrlKey: true,
            })
        }
    }

    private onKeyUp = (e: KeyboardEvent) => {
        if (e.key === 'Shift') {
            this.resendMouseEvent({
                shiftKey: false,
            })
        } else if (e.key === 'Alt') {
            this.resendMouseEvent({
                altKey: false,
            })
        } else if (e.key === 'Meta') {
            this.resendMouseEvent({
                metaKey: false,
            })
        } else if (e.key === 'Control') {
            this.resendMouseEvent({
                ctrlKey: false,
            })
        }
    }

    private autoResendMouseMoveOnViewportChanged = () => {
        signalMicrotask(
            () => {
                // NOTE: 新模式不执行 resend
                if (
                    this.viewStateBridge.getDefaultValue('editorType') ===
                    Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV
                ) {
                    return
                }
                this.resendMouseEvent()
            },
            { signal: this.signal }
        )
    }

    private moveLock = false
    private throttleMouseMove = (e: MouseEvent) => {
        if (isSafari()) {
            if (this.moveLock) {
                return
            }
            this.moveLock = true

            signalAnimationFrame(
                () => {
                    this.moveLock = false
                    this.sendMoveEvent(e)
                },
                { signal: this.signal }
            )
        } else {
            this.sendMoveEvent(e)
        }
    }

    public enable = () => {
        this.viewStateBridge.register('currentViewport', this.autoResendMouseMoveOnViewportChanged)

        window.addEventListener('mousemove', this.throttleMouseMove, { signal: this.signal })
        window.addEventListener('mousedown', this.sendMoveEvent, { signal: this.signal, capture: true })
        window.addEventListener('mouseup', this.sendMoveEvent, { signal: this.signal })
        window.addEventListener('contextmenu', this.sendMoveEvent, { signal: this.signal })
        window.addEventListener('keydown', this.onKeyDown, { signal: this.signal })
        window.addEventListener('keyup', this.onKeyUp, { signal: this.signal })

        missingMiddleUpEventHandler.reset()

        document.addEventListener('mouseenter', this.sendMouseEventAfterEnter, { signal: this.signal })
    }

    public disable = () => {
        this.viewStateBridge.unregister('currentViewport', this.autoResendMouseMoveOnViewportChanged)
    }
}
