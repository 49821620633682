import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    RequestedTooFrequently: '申请过于频繁，请稍候再试',
    ThisRequestHas: '当前申请已被处理',
} as const

export const enTranslation = {
    RequestedTooFrequently: 'You have requested too many times. Try again later.',
    ThisRequestHas: 'This request has already been handled.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
