import { translation } from './layout-grid-style-editor.translation'
/* eslint-disable no-restricted-imports */
import {
    ApplyCreateStyle,
    CancelCreateStyle,
    CreateLayoutGridStyleNode,
    UpdateLayoutGridStyleDescriptionCommand,
    UpdateLayoutGridStyleLayoutGridsCommand,
    UpdateLayoutGridStyleNameCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DraggablePopupPropsV2, ExplicitUndefined } from '../../../../../../../../ui-lib/src'
import { cmdChangePopupState } from '../../../../../../document/command/document-command'
import { cmdChangeAttrPanelStyleEditorState } from '../../../../../../document/command/node-props-command'
import { PopupStateType, SolidPaint } from '../../../../../../document/node/node'
import { useViewState } from '../../../../../../view-state-bridge'
import { useCommand } from '../../../../../context/document-context'
import { InputOptionsForUndoSquash } from '../../../../atom/inputs/components/formatted-input'
import { Value } from '../../../../atom/inputs/utils/type'
import { Position } from '../../../../comment/type'
import { useLayoutGridModel } from '../../../../design-panel-v2/layout-grid/use-layout-grid-model'
import { ColorSpace } from '../../../blend/color-picker/utils/color-translate'
import { useEditingLayoutGridStyle } from '../../hooks/use-editing-style'
import { modifiedMultiPopup } from '../../modified-multi-popup'
import {
    LayoutGridStyleData,
    LayoutGridStylePanel,
    LayoutGridStylePanelProps,
} from '../layout-grid-style-panel/layout-grid-style-panel'
import { createCutLayoutGridStyle, getDefaultLayoutGrid } from '../utils'

export interface LayoutGridStyleEditorProps extends DraggablePopupPropsV2 {
    colorSpace: ColorSpace
    styleId: string
    isRemoteStyle?: boolean
    onOpenSourceDocument?: (e: any) => void
    isReadOnly?: boolean
    createStyle?: {
        isCreate: boolean
        isMixed?: boolean
        layoutGrids?: Wukong.DocumentProto.ILayoutGrid[]
    }
    effectCreateStyle?: (styleId: string) => void
    onClickCreate?: () => void
}

export interface StyleNodeInfo {
    id: string
    name: string
    description: string
    layoutGrids: Wukong.DocumentProto.ILayoutGrid[]
    floatVariables: Wukong.DocumentProto.ISingleFloatVariable[]
}

function getLayoutGridStyleNodeInfo(node?: Wukong.DocumentProto.IVLayoutGridStyleNode | null): StyleNodeInfo {
    const _layoutGridStyleNodeInfo: StyleNodeInfo = {
        id: '',
        name: '',
        description: '',
        layoutGrids: [],
        floatVariables: [],
    }
    if (node) {
        _layoutGridStyleNodeInfo.name = node.name
        _layoutGridStyleNodeInfo.description = node.description ?? ''
        _layoutGridStyleNodeInfo.layoutGrids = node.layoutGrids.slice()
        _layoutGridStyleNodeInfo.floatVariables = node.floatVariables.slice()
    }
    return _layoutGridStyleNodeInfo
}

function shouldUpdatePopupPosition(
    layoutGridStyleNode: Wukong.DocumentProto.IVLayoutGridStyleNode | null,
    layoutGrids: Wukong.DocumentProto.ILayoutGrid[]
) {
    return !!layoutGridStyleNode && layoutGridStyleNode.layoutGrids.length == layoutGrids.length
}

export function LayoutGridStyleEditor(props: LayoutGridStyleEditorProps) {
    const {
        visible,
        styleId,
        position,
        isRemoteStyle,
        colorSpace,
        onOpenSourceDocument,
        onCancel,
        createStyle,
        effectCreateStyle,
    } = props
    const layoutGridStyleNode = useEditingLayoutGridStyle()
    const attrPanelStyleEditorState = useViewState('attrPanelStyleEditorState')
    const popupState = useViewState('popupState')
    const adding = useRef<boolean>(false)
    const [popOpen, setPopOpen] = useState<boolean | undefined>()
    const [popPosition, setPopPosition] = useState<Position | undefined>()
    const alreadyApplyCreateRef = useRef<boolean>(false)
    const [styleNodeInfo, setStyleNodeInfo] = useState<LayoutGridStyleData>(getLayoutGridStyleNodeInfo())
    const [selectLayoutGrids, setSelectLayoutGrids] = useState<Wukong.DocumentProto.ILayoutGrid[]>([])
    const effectCreateStyleRef = useRef<LayoutGridStyleEditorProps['effectCreateStyle']>()
    effectCreateStyleRef.current = effectCreateStyle

    const selectLayoutGridIndexList = useMemo(() => {
        const length = styleNodeInfo.layoutGrids.length - 1
        if (length === -1 || !attrPanelStyleEditorState?.selectReverseIndex) {
            return []
        }
        return attrPanelStyleEditorState.selectReverseIndex
    }, [attrPanelStyleEditorState?.selectReverseIndex, styleNodeInfo.layoutGrids.length])

    const updateStyleEditorState = useCallback(() => {
        const styleNode = layoutGridStyleNode
        if (!styleNode) {
            return
        }

        const _styleNodeInfo = getLayoutGridStyleNodeInfo(styleNode)
        setStyleNodeInfo(_styleNodeInfo)
        if (!attrPanelStyleEditorState) {
            return
        }
        const { selectReverseIndex } = attrPanelStyleEditorState
        const layoutGrids = _styleNodeInfo.layoutGrids

        if (selectReverseIndex) {
            const _selectLayoutGrids = layoutGrids.filter((_, index) => selectLayoutGridIndexList.includes(index))
            setSelectLayoutGrids(_selectLayoutGrids)
        }
    }, [attrPanelStyleEditorState, layoutGridStyleNode, selectLayoutGridIndexList])

    const command = useCommand()
    const { modelCommand } = useLayoutGridModel()
    const onChangeDescription = useCallback(
        (description: string) => {
            command.DEPRECATED_invokeBridge(
                UpdateLayoutGridStyleDescriptionCommand,
                Wukong.DocumentProto.UpdateLayoutGridStyleNodeDescriptionParam.create({
                    styleNode: styleId,
                    description,
                })
            )
            command.commitUndo()
        },
        [command, styleId]
    )
    const onChangeName = useCallback(
        (name: string) => {
            command.DEPRECATED_invokeBridge(
                UpdateLayoutGridStyleNameCommand,
                Wukong.DocumentProto.UpdateLayoutGridStyleNodeNameParam.create({
                    styleNode: styleId,
                    name,
                })
            )
            command.commitUndo()
        },
        [command, styleId]
    )

    const updateLayoutGrids = useCallback(
        (styleIds: string[], layoutGrids: Wukong.DocumentProto.ILayoutGrid[]) => {
            styleIds.forEach((v) => {
                command.DEPRECATED_invokeBridge(
                    UpdateLayoutGridStyleLayoutGridsCommand,
                    Wukong.DocumentProto.UpdateLayoutGridStyleLayoutGridsCommandParam.create({
                        styleNode: v,
                        layoutGrids: layoutGrids,
                    })
                )
            })
        },
        [command]
    )

    const onClickAddLayoutGrid = useCallback(() => {
        adding.current = true
        const layoutGrids = styleNodeInfo.layoutGrids
        const newLayoutGrids = layoutGrids.length ? [...layoutGrids, getDefaultLayoutGrid()] : [getDefaultLayoutGrid()]

        updateLayoutGrids([styleId], newLayoutGrids)
        adding.current = false
        command.commitUndo()
    }, [command, styleId, styleNodeInfo.layoutGrids, updateLayoutGrids])

    const onSelectChange = useCallback(
        (itemsIndex: number[]) => {
            command.invoke(cmdChangeAttrPanelStyleEditorState, {
                ...attrPanelStyleEditorState,
                selectReverseIndex: itemsIndex,
            })
        },
        [attrPanelStyleEditorState, command]
    )

    const onDragDone = useCallback(
        (items: Wukong.DocumentProto.ILayoutGrid[], indexes: number[]) => {
            command.invoke(cmdChangeAttrPanelStyleEditorState, {
                ...attrPanelStyleEditorState,
                selectReverseIndex: indexes,
            })
            modelCommand.changeLayoutGridStyleNode(styleId, items)
            command.commitUndo()
        },
        [attrPanelStyleEditorState, command, modelCommand, styleId]
    )

    const onClickTitle = useCallback(() => {
        if (styleNodeInfo.layoutGrids.length > 0 || adding.current) {
            return
        }
        onClickAddLayoutGrid()
        command.commitUndo()
    }, [command, onClickAddLayoutGrid, styleNodeInfo.layoutGrids.length])

    const onChangeVisible = (index: number, v: boolean) => {
        const nextLayoutGrids = styleNodeInfo.layoutGrids
        const layoutGridAtIndex = nextLayoutGrids.at(index)!
        nextLayoutGrids.splice(index, 1, { ...layoutGridAtIndex, visible: v })
        modelCommand.changeLayoutGridStyleNode(styleId, nextLayoutGrids)
        command.commitUndo()
    }

    const onClickSub = (index: number) => {
        const newLayoutGrids = styleNodeInfo.layoutGrids.filter((v, i) => index !== i)
        updateLayoutGrids([styleId], newLayoutGrids)
        command.commitUndo()
    }

    const isOpenStyleEditor = useCallback(
        (index: number) => {
            if (!visible) {
                return false
            }
            const multiPop = popupState?.multiPopup
            if (!multiPop || multiPop.length === 0) {
                return false
            }
            const reciprocalIndex = index
            return multiPop.some(
                (v) =>
                    v.type === PopupStateType.POPUP_STATE_TYPE_LAYOUT_GRID_STYLE_EDITOR &&
                    v.reciprocalIndex === reciprocalIndex
            )
        },
        [popupState?.multiPopup, visible]
    )

    const setDocumentPopupState = useCallback(
        (openPopup: boolean, reciprocalIndex = -1) => {
            const _popupState = modifiedMultiPopup(
                popupState,
                PopupStateType.POPUP_STATE_TYPE_LAYOUT_GRID_STYLE_EDITOR,
                openPopup
                    ? {
                          type: PopupStateType.POPUP_STATE_TYPE_LAYOUT_GRID_STYLE_EDITOR,
                          reciprocalIndex,
                          multiPopup: [],
                      }
                    : undefined
            )
            command.invoke(cmdChangePopupState, _popupState)
            return _popupState
        },
        [command, popupState]
    )

    const onChangeModalVisible = useCallback(
        (index: number, v: boolean) => {
            if (styleNodeInfo.layoutGrids.length <= 0) {
                return
            }
            if (v) {
                const reciprocalIndex = index
                setDocumentPopupState(true, reciprocalIndex)
                const freshAttrPanelStyleEditorState = attrPanelStyleEditorState
                    ? { ...attrPanelStyleEditorState, selectReverseIndex: [reciprocalIndex] }
                    : undefined
                command.invoke(cmdChangeAttrPanelStyleEditorState, freshAttrPanelStyleEditorState || null)
            } else {
                command.invoke(cmdChangeAttrPanelStyleEditorState, {
                    ...attrPanelStyleEditorState,
                    selectReverseIndex: [],
                })
                setDocumentPopupState(false)
            }
        },
        [attrPanelStyleEditorState, command, setDocumentPopupState, styleNodeInfo.layoutGrids.length]
    )

    const updateSingleLayoutGridAttr = (obj: { [key: string]: any }, index: number) => {
        const nextLayoutGrids = styleNodeInfo.layoutGrids?.slice()
        const layoutGridAtIndex = nextLayoutGrids?.at(index)!
        nextLayoutGrids.splice(index, 1, { ...layoutGridAtIndex, ...obj })
        modelCommand.changeLayoutGridStyleNode(styleId, nextLayoutGrids)
    }
    const onChangeLayoutPattern = (index: number, pattern: Wukong.DocumentProto.LayoutGridPattern) => {
        updateSingleLayoutGridAttr({ pattern: pattern }, index)
        command.commitUndo()
    }
    const onChangeSolidPaint = (index: number, value: SolidPaint, options?: InputOptionsForUndoSquash) => {
        updateSingleLayoutGridAttr({ paints: value, visible: true }, index)
        command.commitUndo(options?.commitType)
    }
    const onChangeSectionSize = (index: number, value: Value, options?: InputOptionsForUndoSquash) => {
        updateSingleLayoutGridAttr({ sectionSize: value, sectionSizeVar: null, visible: true }, index)
        command.commitUndo(options?.commitType)
    }

    const onChangeSectionSizeVar = (index: number, value: string | null, options?: InputOptionsForUndoSquash) => {
        if (!value) {
            updateSingleLayoutGridAttr({ sectionSizeVar: null, visible: true }, index)
        } else {
            updateSingleLayoutGridAttr(
                {
                    sectionSizeVar: {
                        dataType: Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_ALIAS,
                        resolvedDataType:
                            Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT,
                        value: { alias: value },
                    },
                    visible: true,
                },
                index
            )
        }
        command.commitUndo(options?.commitType)
    }

    const onChangeCount = (index: number, value: Value, options?: InputOptionsForUndoSquash) => {
        updateSingleLayoutGridAttr({ count: value, countVar: null, visible: true }, index)
        command.commitUndo(options?.commitType)
    }
    const onChangeCountVar = (index: number, value: string | null, options?: InputOptionsForUndoSquash) => {
        if (!value) {
            updateSingleLayoutGridAttr({ countVar: null, visible: true }, index)
        } else {
            updateSingleLayoutGridAttr(
                {
                    countVar: {
                        dataType: Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_ALIAS,
                        resolvedDataType:
                            Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT,
                        value: { alias: value },
                    },
                    visible: true,
                },
                index
            )
        }
        command.commitUndo(options?.commitType)
    }

    const onChangeAlign = (index: number, align: Wukong.DocumentProto.RowsColsLayoutGridAlign) => {
        updateSingleLayoutGridAttr({ align: align, visible: true }, index)
        command.commitUndo()
    }

    const onChangeGutterSize = (index: number, value: Value, options?: InputOptionsForUndoSquash) => {
        updateSingleLayoutGridAttr({ gutterSize: value, gutterSizeVar: null, visible: true }, index)
        command.commitUndo(options?.commitType)
    }
    const onChangeGutterSizeVar = (index: number, value: string | null, options?: InputOptionsForUndoSquash) => {
        if (!value) {
            updateSingleLayoutGridAttr({ gutterSizeVar: null, visible: true }, index)
        } else {
            updateSingleLayoutGridAttr(
                {
                    gutterSizeVar: {
                        dataType: Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_ALIAS,
                        resolvedDataType:
                            Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT,
                        value: { alias: value },
                    },
                    visible: true,
                },
                index
            )
        }
        command.commitUndo(options?.commitType)
    }

    const onChangeOffset = (index: number, value: Value, options?: InputOptionsForUndoSquash) => {
        updateSingleLayoutGridAttr({ offset: value, offsetVar: null, visible: true }, index)
        command.commitUndo(options?.commitType)
    }
    const onChangeOffsetVar = (index: number, value: string | null, options?: InputOptionsForUndoSquash) => {
        if (!value) {
            updateSingleLayoutGridAttr({ offsetVar: null, visible: true }, index)
        } else {
            updateSingleLayoutGridAttr(
                {
                    offsetVar: {
                        dataType: Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_ALIAS,
                        resolvedDataType:
                            Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT,
                        value: { alias: value },
                    },
                    visible: true,
                },
                index
            )
        }
        command.commitUndo(options?.commitType)
    }

    const onCreateStyle = () => {
        alreadyApplyCreateRef.current = true
        if (!layoutGridStyleNode?.name) {
            onChangeName(analyzeLayoutGrids(styleNodeInfo.layoutGrids).placeholder)
        }
        command.DEPRECATED_invokeBridge(ApplyCreateStyle, { nodeId: styleId })
        props.onClickCreate?.()
        command.commitUndo()
    }

    useEffect(() => {
        updateStyleEditorState()
    }, [updateStyleEditorState])

    useEffect(() => {
        if (!visible) {
            setPopOpen(false)
            return
        }
        if (shouldUpdatePopupPosition(layoutGridStyleNode, styleNodeInfo.layoutGrids)) {
            setPopOpen(true)
            setPopPosition(position)
        }
    }, [layoutGridStyleNode, position, styleNodeInfo.layoutGrids, visible])

    useEffect(() => {
        let layoutGridStyleId: string | null | undefined
        if (createStyle?.isCreate) {
            const layoutGridStyle = command.DEPRECATED_invokeBridge(
                CreateLayoutGridStyleNode,
                createCutLayoutGridStyle(createStyle.isMixed ? {} : { layoutGrids: createStyle.layoutGrids })
            )
            layoutGridStyleId = layoutGridStyle.nodeId
            effectCreateStyleRef.current?.(layoutGridStyleId!)
        }
        return () => {
            if (createStyle?.isCreate && !alreadyApplyCreateRef.current && !!layoutGridStyleId) {
                command.DEPRECATED_invokeBridge(CancelCreateStyle, { nodeId: layoutGridStyleId })
            }
        }
    }, [command, createStyle?.isCreate, createStyle?.isMixed, createStyle?.layoutGrids])

    return (
        <LayoutGridStylePanel<ExplicitUndefined<LayoutGridStylePanelProps>>
            openFrom={props.isRemoteStyle ? 'remote' : createStyle?.isCreate ? 'create' : 'normal'}
            visible={popOpen}
            position={popPosition}
            header={
                isRemoteStyle
                    ? translation('ViewGridStyle')
                    : createStyle?.isCreate
                    ? translation('CreateGridStyle')
                    : translation('EditGridStyle')
            }
            onCancel={onCancel}
            layoutGridStyleData={styleNodeInfo}
            colorSpace={colorSpace}
            isReadOnly={props.isReadOnly}
            selectLayoutGridIndexList={selectLayoutGridIndexList}
            onChangeName={onChangeName}
            onChangeDescription={onChangeDescription}
            onClickTitle={onClickTitle}
            onClickAddLayoutGrid={onClickAddLayoutGrid}
            onSelectChange={onSelectChange}
            onDragDone={onDragDone}
            isOpenLayoutGridPanel={isOpenStyleEditor}
            onChangeModalVisible={onChangeModalVisible}
            onChangeVisible={onChangeVisible}
            onChangeLayoutPattern={onChangeLayoutPattern}
            onChangeSolidPaint={onChangeSolidPaint}
            onChangeSectionSize={onChangeSectionSize}
            onChangeSectionSizeVar={onChangeSectionSizeVar}
            onChangeCount={onChangeCount}
            onChangeCountVar={onChangeCountVar}
            onChangeAlign={onChangeAlign}
            onChangeGutterSize={onChangeGutterSize}
            onChangeGutterSizeVar={onChangeGutterSizeVar}
            onChangeOffset={onChangeOffset}
            onChangeOffsetVar={onChangeOffsetVar}
            onClickSub={onClickSub}
            onClickRemoteStyleLink={onOpenSourceDocument}
            onClickCreate={onCreateStyle}
        ></LayoutGridStylePanel>
    )
}

interface AnalyzeLayoutGrids {
    placeholder: string
}
function analyzeLayoutGrids(layoutGrids?: Wukong.DocumentProto.ILayoutGrid[]): AnalyzeLayoutGrids {
    const result: AnalyzeLayoutGrids = { placeholder: translation('GridStyles') }
    if (!layoutGrids?.length) {
        return result
    }

    if (layoutGrids.length === 1) {
        result.placeholder =
            layoutGrids[0].pattern === Wukong.DocumentProto.LayoutGridPattern.LAYOUT_GRID_PATTERN_GRID
                ? translation('Grid')
                : translation('Grid_synonyms1')
    } else if (layoutGrids.length > 1) {
        result.placeholder = translation('Grid_synonyms1_synonyms2')
    }
    return result
}
