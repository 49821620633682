import { WKTypography } from '../../../../../ui-lib/src'
import { LibraryControlListItem } from '../library-control-list-item/library-control-list-item'
import type { CustomLibraryTeamInfo } from '../service/library-remote-search-service'
import styles from './library-team-info-list-item.module.less'

// 单个团队下所有组件库列表
export function LibraryTeamInfoListItem(props: { libraryTeamInfo: CustomLibraryTeamInfo }) {
    return (
        <div>
            <WKTypography.Paragraph color="placeholder" className={styles.title}>
                {props.libraryTeamInfo.name}
            </WKTypography.Paragraph>
            {props.libraryTeamInfo.libraryList.map((library) => (
                <LibraryControlListItem library={library} key={library.id} />
            ))}
        </div>
    )
}
