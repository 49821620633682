/* eslint-disable no-restricted-imports */
import { useCallback } from 'react'
import { ToKeyCode } from '../../../../../document/util/keycode'
import { isCombo, isKey, KeyboardCode, ModifierKeysCombo } from '../../../../../kernel/keyboard/keyboard-event-handler'
import { KeyboardReceiver } from '../../../../../main/keyboard-receiver/component'
import { useViewState } from '../../../../../view-state-bridge'
import { useHistoryToast } from '../../../history-file/history-toast/use-history-toast'
import { MessageModal } from '../../../history-file/message-modal/message-modal'
import { useMenuContextState } from '../context/menu-context'
import { useSaveHistoryVersion } from './use-save-history-version'

export function SaveHistoryVersion() {
    const { messageModalProps } = useMenuContextState()
    const saveHistoryVersion = useSaveHistoryVersion()
    const docReadonly = useViewState('docReadonly')
    useHistoryToast()

    const keydown = useCallback(
        (e: KeyboardEvent) => {
            const needHandle =
                !docReadonly &&
                // 前者是为了兼容 jest 测试，后者是为了兼容 cy 测试
                (isKey(e, KeyboardCode.S) || e.keyCode === ToKeyCode.S) &&
                isCombo(e, ModifierKeysCombo.META_ALT)
            if (needHandle) {
                saveHistoryVersion()
                return false
            }
            return true
        },
        [docReadonly, saveHistoryVersion]
    )

    return (
        <KeyboardReceiver keyCode={ToKeyCode.All} onKeydown={keydown}>
            {messageModalProps.visible ? (
                <MessageModal {...messageModalProps} dataTestIds={{ modal: 'history-version-dialog' }} />
            ) : null}
        </KeyboardReceiver>
    )
}
