import { Wukong } from '@wukong/bridge-proto'
import { featureSwitchManager } from '../../../kernel/switch/core'
import { useAppContext } from '../../../main/app-context'
import { useViewState } from '../../../view-state-bridge/use-view-state'

// 本地变量弹框是否打开
export function useEditorOpened() {
    return featureSwitchManager.isEnabled('variable-wasm-refactor') ? _useEditorOpenedV2() : _useEditorOpenedV1()
}
function _useEditorOpenedV1() {
    const service = useAppContext().variableService.localVariableEditorService
    return service.stateStore.use.popupOpened()
}
function _useEditorOpenedV2() {
    return useViewState('showLocalVariableEditor')
}

// 本地变量编辑状态
export function useEditingDetailState() {
    return featureSwitchManager.isEnabled('variable-wasm-refactor')
        ? _useEditingDetailStateV2()
        : _useEditingDetailStateV1()
}
function _useEditingDetailStateV1() {
    const service = useAppContext().variableService.localVariableEditorService
    return service.stateStore.use.selectedVariablesEditorOpened()
}
function _useEditingDetailStateV2() {
    const editingState = useViewState('localVariableEditorEditingState')
    return editingState?.type ===
        Wukong.DocumentProto.LocalVariableEditorEditingType.LOCAL_VARIABLE_EDITOR_EDITING_TYPE_DETAIL
        ? editingState.position
        : null
}

// 本地合集编辑状态
export function useRenamingCollectionState() {
    return featureSwitchManager.isEnabled('variable-wasm-refactor')
        ? _useRenamingCollectionStateV2()
        : _useRenamingCollectionStateV1()
}
function _useRenamingCollectionStateV1() {
    const service = useAppContext().variableService.localVariableEditorService
    return service.stateStore.use.renameCollectionState()
}
function _useRenamingCollectionStateV2() {
    const renamingState = useViewState('localVariableEditorRenamingState')
    return renamingState?.type ===
        Wukong.DocumentProto.LocalVariableEditorRenamingType.LOCAL_VARIABLE_EDITOR_RENAMING_TYPE_COLLECTION
        ? renamingState.renamingCollectionId
        : null
}

// 本地合集列表
export function useCollectionList() {
    return featureSwitchManager.isEnabled('variable-wasm-refactor') ? _useCollectionListV2() : _useCollectionListV1()
}
function _useCollectionListV1() {
    const service = useAppContext().variableService.localVariableEditorService
    return service.dataStore.use.collectionList()
}
function _useCollectionListV2() {
    return useViewState('localVariableCollection')?.collections ?? []
}

// 当前选中合集
export function useSelectedCollection() {
    return featureSwitchManager.isEnabled('variable-wasm-refactor')
        ? _useSelectedCollectionV2()
        : _useSelectedCollectionV1()
}
function _useSelectedCollectionV1() {
    const service = useAppContext().variableService.localVariableEditorService
    return service.dataStore.use.selectedCollection()
}
function _useSelectedCollectionV2() {
    return useViewState('localVariableCollection')?.currentCollection ?? null
}

// 本地变量总数
export function useVariablesCount() {
    return featureSwitchManager.isEnabled('variable-wasm-refactor') ? _useVariablesCountV2() : _useVariablesCountV1()
}
function _useVariablesCountV1() {
    const service = useAppContext().variableService.localVariableEditorService
    return service.dataStore.use.variablesCount()
}
function _useVariablesCountV2() {
    return useViewState('localVariableCount')?.value ?? 0
}

// 选中合集的变量总数
export function useSelectedCollectionVariablesCount() {
    return featureSwitchManager.isEnabled('variable-wasm-refactor')
        ? _useSelectedCollectionVariablesCountV2()
        : _useSelectedCollectionVariablesCountV1()
}
function _useSelectedCollectionVariablesCountV1() {
    const service = useAppContext().variableService.localVariableEditorService
    return service.dataStore.use.selectedCollectionVariablesCount()
}
function _useSelectedCollectionVariablesCountV2() {
    return useViewState('localVariableGroup')?.variablesInCollectionCount ?? 0
}

// 选中的变量
export function useSelectedVariables() {
    return featureSwitchManager.isEnabled('variable-wasm-refactor')
        ? _useSelectedVariablesV2()
        : _useSelectedVariablesV1()
}
function _useSelectedVariablesV1() {
    const service = useAppContext().variableService.localVariableEditorService
    return service.dataStore.use.selectedVariables()
}
function _useSelectedVariablesV2() {
    return (
        useViewState('localVariableTable')
            ?.items.filter((item) => item.variableSelected)
            .map((item) => item.variableData) ?? []
    )
}
