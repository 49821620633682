import { HTMLAttributes, ReactNode } from 'react'
import { WKButtonProps } from '../wk-button/button'

export interface Position {
    top: number
    left: number
}

export interface PositionRange {
    minTop: number | null
    maxTop: number | null
    minLeft: number | null
    maxLeft: number | null
}

export interface Range {
    top?: number | null
    left?: number | null
    bottom?: number | null
    right?: number | null
}

export interface DraggablePopupPropsV2 extends HTMLAttributes<HTMLDivElement> {
    visible?: boolean // 是否展示弹窗，默认为false
    header?: string | ReactNode | null // 弹窗标题，null为不展示header
    closable?: boolean // 是否显示右上角的关闭按钮，默认为true
    position?: Position // 弹窗的位置，默认弹窗居中，top: 15vh
    positionRightBase?: boolean // position的left是否以右边为基准，默认为false
    useRawPosition?: boolean // 是否使用原始的position，不做屏幕自适应，默认为false
    range?: Range // 弹窗的可拖动范围，null为不限制，默认左右为width/2，上48，下不限制
    width?: number | string // 弹窗的宽度
    height?: number | string // 弹窗的高度
    footer?: ReactNode | null // 弹窗footer，null为不展示footer
    okText?: string // 确认按钮的文案，默认为'确定'
    cancelText?: string // 取消按钮的文案，默认为'取消'
    okButtonProps?: Partial<WKButtonProps> // 确认按钮的props
    cancelButtonProps?: Partial<WKButtonProps> // 取消按钮的props
    enableScrollBar?: boolean // 是否允许弹窗body内部滚动，默认为false
    minScrollHeight?: number // 弹窗body内部滚动的最小高度，默认为150
    maxScrollHeight?: number // 弹窗body内部滚动的最大高度
    styleType?: 'modal' | 'editor' // 样式类型，默认为modal
    headerClassName?: string // popup header的className
    closeClassName?: string // popup header close 的className
    bodyClassName?: string // popup body的className
    footerClassName?: string // popup footer的className
    notUseDefaultFooterClassName?: boolean
    testId?: string // popup container的data-testid
    closeTestId?: string // popup close的data-testid
    headerTestId?: string // popup header的data-testid
    bodyTestId?: string // popup body的data-testid
    footerTestId?: string // popup footer的data-testid
    overflowVisible?: boolean // 是否允许弹窗内容溢出
    mask?: boolean // 是否显示mask，点击mask触发onCancel，默认为false
    keepStableTop?: boolean // 在没移动的前提下触发的重新计算，新的top值不会比原来的大。避免在交互中弹窗上下移动
    onOk?: () => void // 点击确定按钮的回调
    onCancel?: () => void // 点击关闭/取消按钮的回调
    onFirstMove?: () => void // 第一次拖拽的回调
    onMove?: (position: Position) => void
}

export interface DraggablePopupRef {
    getPopupElement: () => HTMLDivElement | null // 获取弹窗元素
    setPosition: (position: Position) => void // 手动设置弹窗位置
}

export const DISTANCE_TO_TOP = 56
export const DISTANCE_TO_BOTTOM = 8
export const DISTANCE_TO_LEFT = 8
export const DISTANCE_TO_RIGHT = 8
export const MIN_SCROLL_HEIGHT = 150

export const calcResizePosition = ({
    width,
    height,
    top,
    left,
}: {
    width: number
    height: number
    top: number
    left: number
}): Position => {
    const { innerWidth, innerHeight } = window
    let nextTop = top
    let nextLeft = left
    if (top < DISTANCE_TO_TOP) {
        nextTop = DISTANCE_TO_TOP
    } else if (nextTop + height > innerHeight - DISTANCE_TO_BOTTOM) {
        nextTop = Math.max(innerHeight - height - DISTANCE_TO_BOTTOM, DISTANCE_TO_TOP)
    }
    if (left < DISTANCE_TO_LEFT) {
        nextLeft = DISTANCE_TO_LEFT
    } else if (nextLeft + width > innerWidth - DISTANCE_TO_RIGHT) {
        nextLeft = Math.max(innerWidth - width - DISTANCE_TO_RIGHT, DISTANCE_TO_LEFT)
    }

    return { top: nextTop, left: nextLeft }
}

export const calcPositionRange = ({ range = {}, width, height }: { range?: Range; width: number; height: number }) => {
    const { innerWidth, innerHeight } = window
    const {
        top: rangeTop = 48,
        left: rangeLeft = -width / 2,
        bottom: rangeBottom = null,
        right: rangeRight = -width / 2,
    } = range

    return {
        minTop: rangeTop,
        maxTop: rangeBottom !== null ? innerHeight - rangeBottom - height : null,
        minLeft: rangeLeft,
        maxLeft: rangeRight !== null ? innerWidth - rangeRight - width : null,
    }
}

export const calcPositionWithRange = (left: number, top: number, positionRange?: PositionRange) => {
    if (!positionRange) {
        return { top, left }
    }
    const { minTop, maxTop, minLeft, maxLeft } = positionRange
    const nextLeft =
        minLeft !== null && maxLeft !== null
            ? Math.min(Math.max(left, minLeft), maxLeft)
            : minLeft !== null
            ? Math.max(left, minLeft)
            : maxLeft !== null
            ? Math.min(left, maxLeft)
            : left
    const nextTop =
        minTop !== null && maxTop !== null
            ? Math.min(Math.max(top, minTop), maxTop)
            : minTop !== null
            ? Math.max(top, minTop)
            : maxTop !== null
            ? Math.min(top, maxTop)
            : top
    return { left: nextLeft, top: nextTop }
}

export const calcInnerPosition = (width: number, position?: Position, positionRightBase?: boolean) => {
    return {
        top: position ? position.top : 0.15 * window.innerHeight,
        left: position
            ? positionRightBase
                ? position.left - width
                : position.left
            : 0.5 * (window.innerWidth - width),
    }
}

export const calcInitPosition = ({
    headerHeight,
    footerHeight,
    contentRect,
    position,
    enableScrollBar,
    minScrollHeight,
    maxScrollHeight,
    useRawPosition,
}: {
    headerHeight: number
    footerHeight: number
    contentRect: { width: number; height: number }
    position: Position
    enableScrollBar: boolean
    minScrollHeight: number
    maxScrollHeight?: number
    useRawPosition?: boolean
}) => {
    if (useRawPosition) {
        return {
            newPosition: position,
            newMaxHeight: contentRect.height,
        }
    }

    const popupHeight = headerHeight + footerHeight + contentRect.height
    const newPosition = calcResizePosition({
        width: contentRect.width,
        height: popupHeight,
        top: position.top,
        left: position.left,
    })

    let newMaxHeight = contentRect.height

    if (enableScrollBar) {
        if (newPosition.top + popupHeight + DISTANCE_TO_BOTTOM > window.innerHeight) {
            newMaxHeight = window.innerHeight - newPosition.top - headerHeight - footerHeight - DISTANCE_TO_BOTTOM
        }
        newMaxHeight = Math.max(newMaxHeight, minScrollHeight)
        if (maxScrollHeight) {
            newMaxHeight = Math.min(newMaxHeight, maxScrollHeight)
        }
    }

    return { newPosition, newMaxHeight }
}
