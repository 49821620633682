import { domLocation, generateRouterPath } from '../../../util/src'

export const SANDBOX_PATH = '/' + generateRouterPath('sandbox')

type NonFunctionKeys<T> = {
    [K in keyof T]: T[K] extends (...args: any[]) => any ? never : K
}
type NonFunctionFields<T> = Pick<T, keyof NonFunctionKeys<T>>

/**
 * 用于拦截默认的 console 输出，在所有输出前面增加通用前缀
 * @param _console
 * @param prefix
 */
export function injectConsole(_console: typeof window.console, prefix: string) {
    const injectFunc = <T extends (..._: any) => any>(func: T): T => {
        return ((...args: any) => {
            func(prefix, ...args)
        }) as T
    }

    type ConsoleFuncName = keyof NonFunctionFields<typeof _console>
    const injectFuncs: ConsoleFuncName[] = ['log', 'info', 'error', 'warn']

    injectFuncs.forEach((name) => (_console[name] = injectFunc(_console[name] as any)))
}

/**
 * 沙盒页面的规则
 * ${host}/sandbox
 */
export const isSandboxPath = () => {
    return domLocation().pathname.startsWith(SANDBOX_PATH)
}
