import { Wukong } from '@wukong/bridge-proto'
import { cloneDeep } from 'lodash-es'
import { useMemo, useState } from 'react'
import { WKCollapse, WKCollapseItem, WKToast } from '../../../../../../../../ui-lib/src'
import { PopupStateType } from '../../../../../../document/node/node'
import { useViewState } from '../../../../../../view-state-bridge'
import { useComponentInstanceCommand } from '../../commands'
import {
    ComponentPropType,
    MultiHoverBorderColorType,
    ReplaceInstanceNodeData,
    ValidateInstanceSwapValueScene,
    VariantProp,
    VInstanceCompProp,
} from '../../types'
import { ComponentPicker, ComponentPickerTrigger } from '../component-picker'
import { useComponentProp } from '../component-prop/hook'
import { InstanceAssignmentItem } from '../instance-assignment-item'
import { InstanceVariantPropItem } from '../instance-variant-prop'
import styles from './index.module.less'
import { translation } from './index.translation'

export function NestedInstanceAssignmentList(props: {
    index: number
    chain: Wukong.DocumentProto.IPublicPropInstanceChain
    updateAssignment: (value: Wukong.DocumentProto.IArg_updateNodeValAboutCompPropInInstance) => void
    toggleComponentPickerVisible: (triggerRef: React.RefObject<HTMLDivElement>, instanceSwap: VInstanceCompProp) => void
    highlightNodesByIds: (nodeIds: string[], color?: MultiHoverBorderColorType) => void
    updatePublicVariantProps: (instanceId: string, props: VariantProp[]) => void
}) {
    const {
        index,
        chain,
        updateAssignment,
        toggleComponentPickerVisible,
        highlightNodesByIds,
        updatePublicVariantProps,
    } = props

    return (
        <>
            {chain.instances.map((instance, instanceIndex) => {
                if (!(instance.compPropValues.length + instance.variantValues.length)) {
                    return null
                }
                return (
                    <WKCollapseItem
                        key={`${index}-${instanceIndex}`}
                        title={instance.name}
                        value={`${index}-${instanceIndex}`}
                        titleWeight="regular"
                        itemIndent={12}
                        onHoverTitleEnter={() => {
                            highlightNodesByIds([instance.nodeId!])
                        }}
                        onHoverTitleLeave={() => {
                            highlightNodesByIds([])
                        }}
                        disableHeaderHover
                    >
                        {instance.variantValues.map((prop, propIndex) => {
                            const onChangePropValue = (newPropValue: string | number) => {
                                const varProps = cloneDeep(instance.variantValues)
                                if (
                                    newPropValue &&
                                    newPropValue.toString().trim().length > 0 &&
                                    newPropValue !== prop.value
                                ) {
                                    varProps[propIndex].value = newPropValue.toString()
                                    updatePublicVariantProps(instance.nodeId!, varProps as VariantProp[])
                                }
                            }
                            return (
                                <InstanceVariantPropItem
                                    key={propIndex}
                                    prop={prop as VariantProp}
                                    onChangePropValue={onChangePropValue}
                                />
                            )
                        })}
                        {instance.compPropValues.map((propValue, propIndex) => (
                            <InstanceAssignmentItem
                                key={propIndex}
                                appliedProp={propValue as VInstanceCompProp}
                                index={propIndex}
                                updateAssignment={updateAssignment}
                                toggleComponentPickerVisible={toggleComponentPickerVisible}
                                highlightNodesByIds={highlightNodesByIds}
                            />
                        ))}
                    </WKCollapseItem>
                )
            })}
        </>
    )
}

export function InstanceAssignmentList() {
    const {
        instanceCompProps,
        popupType,
        publicPropChains,
        instancePanelLocateDefId,
        updateAssignment,
        toggleComponentPicker,
        validateInstanceSwapValue,
        highlightNodesByIds,
        updatePublicVariantProps,
        resetInstancePanelLocateDefId,
        locateInstanceOfRef,
    } = useComponentProp()

    const { updateVariantProps } = useComponentInstanceCommand()
    const replacePanelState = useViewState('replaceInstanceState')
    const [componentPickerPosition, setComponentPickerPosition] = useState<{ left: number; top: number } | undefined>()
    const [editingInstanceSwap, setEditingInstanceSwap] = useState<VInstanceCompProp | undefined>()

    const closeComponentPicker = () => {
        toggleComponentPicker(PopupStateType.POPUP_STATE_TYPE_NONE)
        setComponentPickerPosition(undefined)
        setEditingInstanceSwap(undefined)
    }

    const toggleComponentPickerVisible = (
        triggerRef: React.RefObject<HTMLDivElement>,
        instanceSwap: VInstanceCompProp
    ) => {
        if (
            popupType === PopupStateType.POPUP_STATE_TYPE_INSTANCE_SWAP_ASSIGNMENT_COMPONENT_PICKER &&
            instanceSwap?.defId === editingInstanceSwap?.defId
        ) {
            closeComponentPicker()
        } else if (triggerRef.current && instanceSwap) {
            const position = triggerRef.current.getBoundingClientRect()
            const { clientWidth } = document.documentElement
            setEditingInstanceSwap(instanceSwap)
            setComponentPickerPosition({ top: position.top + 36, left: clientWidth - 232 })
            toggleComponentPicker(
                PopupStateType.POPUP_STATE_TYPE_INSTANCE_SWAP_ASSIGNMENT_COMPONENT_PICKER,
                instanceSwap
            )
        }
    }

    const defaultPublicPropChainAssignmentList = useMemo(() => {
        const selection: string[] = []
        publicPropChains.forEach((chain, index) => {
            chain.instances.forEach((instance, instanceIndex) => {
                selection.push(`${index}-${instanceIndex}`)
            })
        })
        return selection
    }, [publicPropChains])

    const state = useViewState('componentPanelState')
    if (!state) {
        return null
    }

    const variantProps = state.variantState.variantProps
    if (!(instanceCompProps.length + publicPropChains.length + variantProps.length)) {
        return null
    }

    return (
        <div className={styles.instancePropList} data-testid="instance-prop-list">
            {variantProps.length > 0 && (
                <div data-testid="instance-variant-prop-list">
                    {variantProps.map((prop, index) => {
                        const onChangePropValue = (newPropValue: string | number) => {
                            const varProps = cloneDeep(variantProps)
                            if (
                                newPropValue &&
                                newPropValue.toString().trim().length > 0 &&
                                newPropValue !== prop.value
                            ) {
                                varProps[index].value = newPropValue.toString()
                                updateVariantProps(varProps)
                            }
                        }
                        return <InstanceVariantPropItem key={index} prop={prop} onChangePropValue={onChangePropValue} />
                    })}
                </div>
            )}

            {instanceCompProps.length > 0 && (
                <div data-testid="instance-assignment-list">
                    {instanceCompProps.map((appliedProp, index) => (
                        <InstanceAssignmentItem
                            key={appliedProp.defId}
                            appliedProp={appliedProp}
                            index={index}
                            tmpLocateDefId={instancePanelLocateDefId}
                            resetInstancePanelLocateDefId={resetInstancePanelLocateDefId}
                            updateAssignment={updateAssignment}
                            toggleComponentPickerVisible={toggleComponentPickerVisible}
                            highlightNodesByIds={highlightNodesByIds}
                            locateInstanceOfRef={locateInstanceOfRef}
                        />
                    ))}
                </div>
            )}
            {publicPropChains.length > 0 && (
                <WKCollapse selectionMode="multiple" defaultSelection={defaultPublicPropChainAssignmentList}>
                    <div className={styles.publicPropChainAssignmentList}>
                        {publicPropChains.map((chain, index) => (
                            <NestedInstanceAssignmentList
                                index={index}
                                key={index}
                                chain={chain}
                                updateAssignment={updateAssignment}
                                toggleComponentPickerVisible={toggleComponentPickerVisible}
                                highlightNodesByIds={highlightNodesByIds}
                                updatePublicVariantProps={updatePublicVariantProps}
                            />
                        ))}
                    </div>
                </WKCollapse>
            )}
            {popupType === PopupStateType.POPUP_STATE_TYPE_INSTANCE_SWAP_ASSIGNMENT_COMPONENT_PICKER &&
                replacePanelState && (
                    <ComponentPicker
                        key={editingInstanceSwap?.defId} // 加 key 防止复用，点击其他 assignment 无法更新 position
                        state={replacePanelState}
                        position={componentPickerPosition}
                        onCancel={closeComponentPicker}
                        triggerScene={ComponentPickerTrigger.InstanceSwapValue}
                        handleThumbnailClick={(value: ReplaceInstanceNodeData & { docId: string }) => {
                            if (editingInstanceSwap) {
                                if (
                                    validateInstanceSwapValue({
                                        scene: ValidateInstanceSwapValueScene.VALIDATE_INSTANCE_SWAP_VALUE_SCENE_UPDATE_ASSIGNMENT,
                                        newInstanceSwapValue: value.nodeId,
                                        affectedNodeIds: editingInstanceSwap.nodeIds,
                                    })
                                ) {
                                    updateAssignment(
                                        Wukong.DocumentProto.Arg_updateNodeValAboutCompPropInInstance.create({
                                            type: ComponentPropType.COMPONENT_PROP_TYPE_INSTANCE_SWAP,
                                            ids: editingInstanceSwap.nodeIds,
                                            mainComponentId: value.nodeId,
                                        }),
                                        (success: boolean) =>
                                            !success && WKToast.show(translation('ComponentCanNotContainItself'))
                                    )
                                } else {
                                    WKToast.show(translation('ComponentCanNotContainItself'))
                                }
                            }
                        }}
                    />
                )}
        </div>
    )
}
