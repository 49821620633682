import { WKForm, WKFormItem } from '../../../../../../../ui-lib/src/components/wk-form/index'
import { FormCompNormalInput } from '../../../../../../../ui-lib/src/components/wk-form/form-component'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { asyncModal, WKButton, WKDivider, WKToast } from '../../../../../../../ui-lib/src'
import { WKTypography } from '../../../../../../../ui-lib/src/components/wk-typography/index'
import { DeleteAccountV2 } from '../../../../../kernel/request/account'
import { domLocation, getMotiffName } from '../../../../../../../util/src'
import { translation } from './email-validate-modal.translation'

type EmailValidateModalReturnType = 'deleteSuccess' | 'deleteFailed'

const Dot = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4">
        <path
            transform="matrix(1 0 0 1 0 0)"
            d="M2 0C3.10457 0 4 0.89543 4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0Z"
            fillRule="nonzero"
            fill="rgb(69, 73, 89)"
        />
    </svg>
)

const ListItem = ({ children }: { children: React.ReactNode }) => {
    return (
        <div className="flex gap-1">
            <div className="shrink-0 p-1px flex h-5 items-center">
                <Dot />
            </div>
            <WKTypography.Text color="secondary">{children}</WKTypography.Text>
        </div>
    )
}

const EmailValidateModalBody = ({
    verifyToken,
    email,
    resolve,
}: {
    verifyToken: string
    email: string
    resolve: (value: EmailValidateModalReturnType) => void
}) => {
    // email 是当前登录的邮箱
    const schema = z.object({
        email: z
            .string()
            .min(1, '')
            .refine((_) => _ === email, ''),
    })
    type DataType = z.infer<typeof schema>
    const {
        handleSubmit,
        control,
        formState: { isValid, isSubmitting },
        clearErrors,
    } = useForm<DataType>({
        resolver: zodResolver(schema),
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        defaultValues: {
            email: '',
        },
    })
    const deleteAccount = async () => {
        try {
            await new DeleteAccountV2(verifyToken).start()
            domLocation().replace('/login')
            // resolve('deleteSuccess')
        } catch (e) {
            WKToast.error(translation('DeleteAccountFailed'))
            resolve('deleteFailed')
        }
    }

    return (
        <div className="flex flex-col">
            <div className="flex flex-col gap-5 px-6 pt-5 pb-6">
                <div className="flex flex-col gap-2">
                    <div>
                        <WKTypography.Text className="!inline" color="primary" weight="semibold">
                            {translation('AreYouSure', { name: getMotiffName() })}
                        </WKTypography.Text>
                        <WKTypography.Text className="!inline" color="secondary">
                            {translation('CanNotUndo')}
                        </WKTypography.Text>
                    </div>
                    <div className="flex flex-col gap-1">
                        <ListItem>{translation('tip1')}</ListItem>
                        <ListItem>{translation('tip2')}</ListItem>
                        <ListItem>{translation('tip3')}</ListItem>
                        <ListItem>{translation('tip4')}</ListItem>
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <WKTypography.Text>{translation('PleaseInputEmail')}</WKTypography.Text>
                    <WKForm
                        onChange={() => {
                            clearErrors()
                        }}
                        id="deleteAccountFormId"
                        onSubmit={handleSubmit(deleteAccount)}
                    >
                        <WKFormItem label="">
                            <FormCompNormalInput
                                inputProps={{
                                    autoFocus: true,
                                    placeholder: translation('InputEmail'),
                                }}
                                control={control}
                                name="email"
                            ></FormCompNormalInput>
                        </WKFormItem>
                    </WKForm>
                </div>
            </div>
            <WKDivider />
            <div className="flex px-6 py-4 items-center">
                <div className="flex-1" />
                <div className="flex items-center">
                    <WKButton type="secondary" onClick={() => resolve('deleteFailed')}>
                        {translation('Cancel')}
                    </WKButton>
                    <WKButton
                        type="primary"
                        formId="deleteAccountFormId"
                        htmlType="submit"
                        data-testid="confirm-button"
                        danger
                        disabled={!isValid}
                        loading={isSubmitting}
                    >
                        {translation('Delete')}
                    </WKButton>
                </div>
            </div>
        </div>
    )
}

export const emailValidateModal = (verifyToken: string, email: string) =>
    asyncModal<EmailValidateModalReturnType>({
        title: translation('CancelAccount'),
        bodyStyle: {
            padding: 0,
        },
        closeWhenHrefChanged: true,
        onCancel: (resolve) => {
            resolve('deleteFailed')
        },
        childrenFn: (resolve) => <EmailValidateModalBody email={email} verifyToken={verifyToken} resolve={resolve} />,
        footer: null,
    })
