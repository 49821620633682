import { useEffect, useState } from 'react'

interface Size {
    width: number
    height: number
}

interface UseResizeHandleProps {
    initialSize: Size
    minSize: Size
}

export const useResizeHandle = ({ initialSize, minSize }: UseResizeHandleProps) => {
    const [isDragging, setIsDragging] = useState(false)
    const [size, setSize] = useState(initialSize)

    useEffect(() => {
        if (!isDragging) return

        const handleMouseMove = (e: PointerEvent) => {
            if (e.buttons === 0) {
                // 检查鼠标按钮是否已释放
                setIsDragging(false)
                return
            }

            setSize((prev) => {
                const newSize = {
                    width: Math.max(minSize.width, prev.width + e.movementX),
                    height: Math.max(minSize.height, prev.height + e.movementY),
                }
                return newSize
            })
        }

        const handleMouseUp = () => {
            setIsDragging(false)
        }

        document.addEventListener('pointermove', handleMouseMove)
        document.addEventListener('pointerup', handleMouseUp)
        document.addEventListener('pointercancel', handleMouseUp)

        return () => {
            document.removeEventListener('pointermove', handleMouseMove)
            document.removeEventListener('pointerup', handleMouseUp)
            document.removeEventListener('pointercancel', handleMouseUp)
        }
    }, [isDragging, minSize])

    const handleResizeStart = (e: React.PointerEvent) => {
        e.stopPropagation()
        setIsDragging(true)
    }

    return {
        size,
        handleResizeStart,
    }
}
