import type { MethodSignature } from '@wukong/bridge-proto'
import { Reader } from '@wukong/bridge-proto'

export interface BasicBridgeBindOptions {
    signal?: AbortSignal // 传入一个 signal，当 signal abort 时自动进行 unbind
}
export interface IBasicBridge {
    call: <RET, ARG>(method: MethodSignature<RET, ARG>, arg?: ARG) => RET
    bind: <RET, ARG>(method: MethodSignature<RET, ARG>, fn: (arg: ARG) => RET, options?: BasicBridgeBindOptions) => void
    unbind: <RET, ARG>(method: MethodSignature<RET, ARG>) => void
}

export function copyBytes(array: Uint8Array) {
    const newBuffer = new ArrayBuffer(array.length)
    const copiedArray = new Uint8Array(newBuffer)
    copiedArray.set(array)
    return copiedArray
}

class CopyBytesReader extends Reader {
    override bytes(): Uint8Array {
        return copyBytes(super.bytes())
    }
}

export interface BridgeCallDelegate {
    wasmCall: (methodCode: number, arg: Uint8Array | undefined) => Uint8Array | undefined

    beforeWasmCall?: (encodedArg: Uint8Array | undefined, method: MethodSignature<any, any>, arg?: any) => void

    beforeJsCall?: (encodedArg: Uint8Array | undefined, method: MethodSignature<any, any>, arg?: any) => void

    afterJsCall?: (ret: Uint8Array | undefined, method: MethodSignature<any, any>) => void
}

// 只包含 bridge 编解码和 wasm 函数调用的最基本实现
export class BasicBridge implements IBasicBridge {
    public methods: Record<number, (arg: Uint8Array | undefined) => Uint8Array | undefined> = {}

    constructor(private wasmCallImpl: BridgeCallDelegate) {}

    call = <RET, ARG>(method: MethodSignature<RET, ARG>, arg?: ARG): RET => {
        const writer = method.argumentType.encodeDelimited((arg || {}) as ARG)
        const wasmCallArg = writer ? writer.finish() : undefined
        this.wasmCallImpl.beforeWasmCall?.(wasmCallArg, method, arg)
        const retPtr = this.wasmCallImpl.wasmCall(method.code, wasmCallArg)

        if (!retPtr) {
            return undefined as any
        }

        const reader = new Reader(retPtr)
        return method.returnType.decodeDelimited(reader)
    }

    bind = <RET, ARG>(
        method: MethodSignature<RET, ARG>,
        fn: (arg: ARG) => RET,
        options?: BasicBridgeBindOptions
    ): void => {
        this.methods[method.code] = (argBytes: Uint8Array | undefined) => {
            const reader = argBytes ? new CopyBytesReader(argBytes) : undefined
            const arg = method.argumentType.decodeDelimited(reader)
            this.wasmCallImpl.beforeJsCall?.(
                reader ? copyBytes(argBytes!.subarray(0, reader.pos)) : undefined,
                method,
                arg
            )

            const ret = fn(arg)
            const writer = method.returnType.encodeDelimited(ret)

            if (writer) {
                const retData = writer.finish()
                this.wasmCallImpl.afterJsCall?.(retData, method)
                return retData
            } else {
                this.wasmCallImpl.afterJsCall?.(undefined, method)
                return undefined
            }
        }

        if (options?.signal) {
            if (options.signal.aborted) {
                return
            }

            options.signal.addEventListener(
                'abort',
                () => {
                    this.unbind(method)
                },
                { once: true }
            )
        }
    }

    unbind = <RET, ARG>(method: MethodSignature<RET, ARG>): void => {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete this.methods[method.code]
    }

    jsCall(methodCode: number, arg: Uint8Array | undefined): Uint8Array | undefined {
        const method = this.methods[methodCode]
        if (!method) {
            return undefined
        }

        return method(arg)
    }
}
