export class DelayTimer {
    private _id: number | null = null
    constructor(private _ms: number, private _callback: () => void) {}

    public ensureStarted() {
        if (this._id === null) {
            this._id = window.setTimeout(() => {
                try {
                    this._callback()
                } finally {
                    this._id = null
                }
            }, this._ms)
        }
    }

    public destroy() {
        if (this._id !== null) {
            window.clearTimeout(this._id)
        }
        this._id = null
    }

    public advanceAndDestroy() {
        if (this._id !== null) {
            this.destroy()
            this._callback()
        }
    }
}
