import {
    CreateControlFontCommand,
    CreateControlTextBitmapCommand,
    MarkControlFontCommand,
    MeasureControlTextWidthCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { Bridge } from '../../kernel/bridge/bridge'
import { WkCLog } from '../../kernel/clog/wukong/instance'
import { getIntegrationTestSupports } from '../supports'
import type { IControlParagraphSupports } from './support-interface'
import { NativeControlParagraphSupports } from './support-native'

export class ControlParagraphService {
    private destroyed = false
    private _supports: IControlParagraphSupports

    constructor(private readonly bridge: Bridge) {
        this._supports =
            getIntegrationTestSupports()?.controlParagraphService ?? new NativeControlParagraphSupports(WkCLog.log)

        bridge.bind(CreateControlFontCommand, this.createControlFont)
        bridge.bind(MeasureControlTextWidthCommand, this.measureControlTextWidth)
        bridge.bind(CreateControlTextBitmapCommand, this.createControlTextBitmap)
    }

    private createControlFont = (font: Wukong.DocumentProto.IControlParagraphFont) => {
        return this._supports
            .createControlFont(font)
            .then(() => {
                if (!this.destroyed) {
                    this.bridge.call(MarkControlFontCommand, { value: font.id })
                }
            })
            .catch(() => {
                if (!this.destroyed) {
                    WkCLog.log('control font meta load failed')
                }
            })
    }

    private measureControlTextWidth = (arg: Wukong.DocumentProto.IArg_MeasureControlTextWidth) => {
        return this._supports.measureControlTextWidth(arg)
    }

    private createControlTextBitmap = (
        arg: Wukong.DocumentProto.IArg_GetCanvasGlyphBitmap
    ): Wukong.DocumentProto.ISerBitmap => {
        return this._supports.createControlTextBitmap(arg)
    }

    destroy() {
        this.destroyed = true
        this.bridge.unbind(CreateControlFontCommand)
        this.bridge.unbind(MeasureControlTextWidthCommand)
        this.bridge.unbind(CreateControlTextBitmapCommand)
    }
}
