import {
    PluginApiImportRemoteLibNodeByKeyAsyncCallbackCommandJs,
    PluginApiImportRemoteLibNodeByKeyAsyncCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { IBasicBridge } from '../../kernel/bridge/basic-bridge'
import { IPluginAPIContext } from '../plugin-api-context-interface'
import { Handle } from '../vm-interface'
import { ZodTypes } from '../zod-type'

export function createImportLibNodeByKeyImpl(apiObject: Handle, bridge: IBasicBridge, ctx: IPluginAPIContext) {
    let importComponentByKeyCbId = 1
    const importComponentByKeyCbMap = new Map<
        number,
        { resolve: (res: Handle) => void; reject: (reason: Handle) => void }
    >()

    let importComponentSetByKeyCbId = 1
    const importComponentSetByKeyCbMap = new Map<
        number,
        { resolve: (res: Handle) => void; reject: (reason: Handle) => void }
    >()

    let importStyleByKeyCbId = 1
    const importStyleByKeyCbMap = new Map<
        number,
        { resolve: (res: Handle) => void; reject: (reason: Handle) => void }
    >()

    bridge.bind(PluginApiImportRemoteLibNodeByKeyAsyncCallbackCommandJs, (arg) => {
        const getCallbackAndErrorMsg = (importNodeType: Wukong.DocumentProto.PluginApiImportLibNodeType) => {
            switch (importNodeType) {
                case Wukong.DocumentProto.PluginApiImportLibNodeType.PLUGIN_API_IMPORT_LIB_NODE_TYPE_COMPONENT:
                    return {
                        cb: importComponentByKeyCbMap.get(arg.cbId),
                        errorMsg: '[PluginAPI] in importComponentByKeyAsync: failed to import component by key',
                        map: importComponentByKeyCbMap,
                        type: 'node' as const,
                    }
                case Wukong.DocumentProto.PluginApiImportLibNodeType.PLUGIN_API_IMPORT_LIB_NODE_TYPE_COMPONENT_SET:
                    return {
                        cb: importComponentSetByKeyCbMap.get(arg.cbId),
                        errorMsg: '[PluginAPI] in importComponentSetByKeyAsync: failed to import component set by key',
                        map: importComponentSetByKeyCbMap,
                        type: 'node' as const,
                    }
                case Wukong.DocumentProto.PluginApiImportLibNodeType.PLUGIN_API_IMPORT_LIB_NODE_TYPE_STYLE:
                    return {
                        cb: importStyleByKeyCbMap.get(arg.cbId),
                        errorMsg: '[PluginAPI] in importStyleByKeyAsync: failed to import style by key',
                        map: importStyleByKeyCbMap,
                        type: 'style' as const,
                    }
                default:
                    return { cb: null, errorMsg: '', map: null }
            }
        }

        const { cb, errorMsg, map, type } = getCallbackAndErrorMsg(arg.importNodeType)

        if (cb) {
            if (arg.success) {
                if (type === 'node') {
                    cb.resolve(ctx.createNodeHandle(arg.nodeId!))
                } else if (type === 'style') {
                    cb.resolve(ctx.createStyleNodeHandle(arg.nodeId!))
                }
            } else {
                cb.reject(ctx.vm.newString(errorMsg))
            }
            map?.delete(arg.cbId)
        }
    })

    ctx.defineVmFunction({
        objhandle: apiObject,
        key: 'importComponentByKeyAsync',
        func: (key_: Handle) => {
            const key = ctx.unwrapAndValidate({
                handle: key_,
                type: ZodTypes.String,
                key: 'importComponentByKeyAsync',
            })
            const { promise, resolve, reject } = ctx.vm.newPromise()
            const cbId = importComponentByKeyCbId++

            const ret = ctx.callBridge(PluginApiImportRemoteLibNodeByKeyAsyncCommand, {
                cbId,
                key,
                importNodeType:
                    Wukong.DocumentProto.PluginApiImportLibNodeType.PLUGIN_API_IMPORT_LIB_NODE_TYPE_COMPONENT,
            })
            if (ret.error?.message) {
                reject(ctx.vm.newString('[PluginAPI] in importComponentByKeyAsync: Unable to import component by key'))
                return promise
            }

            importComponentByKeyCbMap.set(cbId, { resolve, reject })
            return promise
        },
    })

    ctx.defineVmFunction({
        objhandle: apiObject,
        key: 'importComponentSetByKeyAsync',
        func: (key_: Handle) => {
            const key = ctx.vm.getString(key_)
            const { promise, resolve, reject } = ctx.vm.newPromise()
            const cbId = importComponentSetByKeyCbId++
            const ret = ctx.callBridge(PluginApiImportRemoteLibNodeByKeyAsyncCommand, {
                cbId,
                key,
                importNodeType:
                    Wukong.DocumentProto.PluginApiImportLibNodeType.PLUGIN_API_IMPORT_LIB_NODE_TYPE_COMPONENT_SET,
            })
            if (ret.error?.message) {
                reject(
                    ctx.vm.newString(
                        '[PluginAPI] in importComponentSetByKeyAsync: Unable to import component set by key'
                    )
                )
                return promise
            }
            importComponentSetByKeyCbMap.set(cbId, { resolve, reject })
            return promise
        },
    })

    ctx.defineVmFunction({
        objhandle: apiObject,
        key: 'importStyleByKeyAsync',
        func: (key_: Handle) => {
            const key = ctx.vm.getString(key_)
            const { promise, resolve, reject } = ctx.vm.newPromise()
            const cbId = importStyleByKeyCbId++
            const ret = ctx.callBridge(PluginApiImportRemoteLibNodeByKeyAsyncCommand, {
                cbId,
                key,
                importNodeType: Wukong.DocumentProto.PluginApiImportLibNodeType.PLUGIN_API_IMPORT_LIB_NODE_TYPE_STYLE,
            })
            if (ret.error?.message) {
                reject(ctx.vm.newString('[PluginAPI] in importStyleByKeyAsync: Unable to import style by key'))
                return promise
            }
            importStyleByKeyCbMap.set(cbId, { resolve, reject })
            return promise
        },
    })
}
