import { translation } from './data.translation'
export enum KeyType {
    Key,
    Split,
}

export interface Key {
    type: KeyType
    char: string
}
export interface Item {
    name: string
    macKeys: Key[]
    winKeys: Key[]
}

// TODO(jiangjk): 应可与 wukong/wukong-web/packages/app/src/kernel/interface/shortcut-key.ts 使用统一的快捷键描述
export const toolTabLeft: Item[] = [
    {
        name: translation('MoveTool'),
        macKeys: [{ type: KeyType.Key, char: 'V' }],
        winKeys: [{ type: KeyType.Key, char: 'V' }],
    },
    {
        name: translation('FrameTool'),
        macKeys: [{ type: KeyType.Key, char: 'F' }],
        winKeys: [{ type: KeyType.Key, char: 'F' }],
    },
    {
        name: translation('PenTool'),
        macKeys: [{ type: KeyType.Key, char: 'P' }],
        winKeys: [{ type: KeyType.Key, char: 'P' }],
    },
    {
        name: translation('TextTool'),
        macKeys: [{ type: KeyType.Key, char: 'T' }],
        winKeys: [{ type: KeyType.Key, char: 'T' }],
    },
]
export const toolTabCenter: Item[] = [
    {
        name: translation('RectangleTool'),
        macKeys: [{ type: KeyType.Key, char: 'R' }],
        winKeys: [{ type: KeyType.Key, char: 'R' }],
    },
    {
        name: translation('EllipseTool'),
        macKeys: [{ type: KeyType.Key, char: 'O' }],
        winKeys: [{ type: KeyType.Key, char: 'O' }],
    },
    {
        name: translation('LineTool'),
        macKeys: [{ type: KeyType.Key, char: 'L' }],
        winKeys: [{ type: KeyType.Key, char: 'L' }],
    },
    {
        name: translation('ArrowTool'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: 'L' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: 'L' },
        ],
    },
    {
        name: translation('PlaceImage...'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'K' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: 'K' },
        ],
    },
]
export const toolTabRight: Item[] = [
    {
        name: translation('AddComment'),
        macKeys: [{ type: KeyType.Key, char: 'C' }],
        winKeys: [{ type: KeyType.Key, char: 'C' }],
    },
    {
        name: translation('PickColor'),
        macKeys: [
            { type: KeyType.Key, char: 'I' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: '⌃' },
            { type: KeyType.Key, char: 'C' },
        ],
        winKeys: [{ type: KeyType.Key, char: 'I' }],
    },
    {
        name: translation('Scale'),
        macKeys: [{ type: KeyType.Key, char: 'K' }],
        winKeys: [{ type: KeyType.Key, char: 'K' }],
    },
    {
        name: translation('HandTool'),
        macKeys: [{ type: KeyType.Key, char: 'H' }],
        winKeys: [{ type: KeyType.Key, char: 'H' }],
    },
    {
        name: translation('SliceTool'),
        macKeys: [{ type: KeyType.Key, char: 'S' }],
        winKeys: [{ type: KeyType.Key, char: 'S' }],
    },
]

export const viewTabLeft = (prototype: boolean): Item[] => {
    const res = [
        {
            name: `${translation('Show')}/${translation('HideUI')}`,
            macKeys: [
                { type: KeyType.Key, char: '⌘' },
                { type: KeyType.Key, char: '\\' },
            ],
            winKeys: [
                { type: KeyType.Key, char: 'Ctrl' },
                { type: KeyType.Key, char: '\\' },
            ],
        },
        {
            name: translation('OpenLayersPanel'),
            macKeys: [
                { type: KeyType.Key, char: '⌥' },
                { type: KeyType.Key, char: '1' },
            ],
            winKeys: [
                { type: KeyType.Key, char: 'Alt' },
                { type: KeyType.Key, char: '1' },
            ],
        },
        {
            name: translation('Libraries'),
            macKeys: [
                { type: KeyType.Key, char: '⌥' },
                { type: KeyType.Key, char: '2' },
            ],
            winKeys: [
                { type: KeyType.Key, char: 'Alt' },
                { type: KeyType.Key, char: '2' },
            ],
        },
    ]
    if (prototype) {
        res.splice(
            3,
            0,
            {
                name: translation('OpenDesignPanel'),
                macKeys: [
                    { type: KeyType.Key, char: '⌥' },
                    { type: KeyType.Key, char: '8' },
                ],
                winKeys: [
                    { type: KeyType.Key, char: 'Alt' },
                    { type: KeyType.Key, char: '8' },
                ],
            },
            {
                name: translation('OpenPrototypePanel'),
                macKeys: [
                    { type: KeyType.Key, char: '⌥' },
                    { type: KeyType.Key, char: '9' },
                ],
                winKeys: [
                    { type: KeyType.Key, char: 'Alt' },
                    { type: KeyType.Key, char: '9' },
                ],
            }
        )
    }
    return res
}

export const viewTabCenter: Item[] = [
    {
        name: translation('OpenDevMode'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: 'D' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: 'D' },
        ],
    },
    {
        name: translation('MultiplayerCursors'),
        macKeys: [
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: '\\' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: '\\' },
        ],
    },
    {
        name: translation('Rulers'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: 'R' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: 'R' },
        ],
    },
    {
        name: translation('LayoutGrids'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: 'G' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: 'G' },
        ],
    },
    {
        name: translation('PixelGrid'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: "'" },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: "'" },
        ],
    },
]

export const viewTabRight: Item[] = [
    {
        name: translation('Pan'),
        macKeys: [
            { type: KeyType.Key, char: 'Space' },
            { type: KeyType.Key, char: translation('Drag') },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Space' },
            { type: KeyType.Key, char: translation('Drag') },
        ],
    },
    {
        name: translation('ZoomInOut'),
        macKeys: [
            { type: KeyType.Key, char: '+' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: '-' },
        ],
        winKeys: [
            { type: KeyType.Key, char: '+' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: '-' },
        ],
    },
    {
        name: `${translation('ZoomTo')} 100%`,
        macKeys: [
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: '0' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: '0' },
        ],
    },
    {
        name: translation('ZoomToFit'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: '1' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: '1' },
        ],
    },
    {
        name: translation('ZoomToSelection'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: '2' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: '2' },
        ],
    },
]

export const textTabLeft: Item[] = [
    {
        name: translation('Bold'),
        macKeys: [
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'B' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'B' },
        ],
    },
    {
        name: translation('Italic'),
        macKeys: [
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'I' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'I' },
        ],
    },
    {
        name: translation('Underline'),
        macKeys: [
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'U' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'U' },
        ],
    },
    {
        name: translation('Strikethrough'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'X' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: 'X' },
        ],
    },
    {
        name: translation('CreateLink'),
        macKeys: [
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'K' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'K' },
        ],
    },
]

export const textTabCenter: Item[] = [
    {
        name: translation('TextAlignLeft'),
        macKeys: [
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'L' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: 'L' },
        ],
    },
    {
        name: translation('TextAlignCenter'),
        macKeys: [
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'T' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: 'T' },
        ],
    },
    {
        name: translation('TextAlignRight'),
        macKeys: [
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'R' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: 'R' },
        ],
    },
    {
        name: translation('TextAlignJustified'),
        macKeys: [
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'J' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: 'J' },
        ],
    },
]

export const textTabRight: Item[] = [
    {
        name: translation('AdjustFontSize'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: '<' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: '>' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: '<' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: '>' },
        ],
    },
    {
        name: translation('AdjustFontWeight'),
        macKeys: [
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: '<' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: '>' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: '<' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: '>' },
        ],
    },
    {
        name: translation('AdjustLetterSpacing'),
        macKeys: [
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: '<' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: '>' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: '<' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: '>' },
        ],
    },
    {
        name: translation('AdjustLineHeight'),
        macKeys: [
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: '<' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: '>' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: '<' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: '>' },
        ],
    },
]

export const vectorTabLeft: Item[] = [
    {
        name: translation('PenTool'),
        macKeys: [{ type: KeyType.Key, char: 'P' }],
        winKeys: [{ type: KeyType.Key, char: 'P' }],
    },
    {
        name: translation('MoveTool'),
        macKeys: [{ type: KeyType.Key, char: 'V' }],
        winKeys: [{ type: KeyType.Key, char: 'V' }],
    },
    {
        name: translation('PaintBucket'),
        macKeys: [{ type: KeyType.Key, char: 'B' }],
        winKeys: [{ type: KeyType.Key, char: 'B' }],
    },
    {
        name: translation('BendTool'),
        macKeys: [{ type: KeyType.Key, char: '⌘' }],
        winKeys: [{ type: KeyType.Key, char: 'Ctrl' }],
    },
]
export const vectorTabCenter: Item[] = [
    {
        name: translation('JoinSelection'),
        macKeys: [
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'J' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'J' },
        ],
    },
    {
        name: translation('SmoothJoinSelection'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'J' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: 'J' },
        ],
    },
    {
        name: translation('DeleteAndHeal'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: '⌫' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: '⌫' },
        ],
    },
]
export const vectorTabRight: Item[] = [
    {
        name: `${translation('sldEML')}`,
        macKeys: [],
        winKeys: [],
    },
    {
        name: translation('MirrorAngleAnd'),
        macKeys: [{ type: KeyType.Key, char: '⌘' }],
        winKeys: [{ type: KeyType.Key, char: 'Ctrl' }],
    },
    {
        name: translation('NoMirroring'),
        macKeys: [{ type: KeyType.Key, char: '⌥' }],
        winKeys: [{ type: KeyType.Key, char: 'Alt' }],
    },
]

export const componentTabLeft: Item[] = [
    {
        name: translation('Libraries'),
        macKeys: [
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: '2' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: '2' },
        ],
    },
    {
        name: translation('TeamLibrary'),
        macKeys: [
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'O' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: 'O' },
        ],
    },
]

export const componentTabCenter: Item[] = [
    {
        name: translation('CreateComponent'),
        macKeys: [
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'K' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: 'K' },
        ],
    },
    {
        name: translation('DetachInstance'),
        macKeys: [
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'B' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: 'B' },
        ],
    },
]

export const componentTabRight: Item[] = [
    { name: `${translation('WhileInsertingA')}`, macKeys: [], winKeys: [] },
    {
        name: translation('SwapComponentInstance'),
        macKeys: [{ type: KeyType.Key, char: '⌥' }],
        winKeys: [{ type: KeyType.Key, char: 'Alt' }],
    },
]

export const selectionTabLeft: Item[] = [
    {
        name: translation('SelectAll'),
        macKeys: [
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'A' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'A' },
        ],
    },
    {
        name: translation('SelectInverse'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'A' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: 'A' },
        ],
    },
    {
        name: translation('SelectNone'),
        macKeys: [{ type: KeyType.Key, char: '⎋ Esc' }],
        winKeys: [{ type: KeyType.Key, char: '⎋ Esc' }],
    },
]
export const selectionTabCenter: Item[] = [
    {
        name: translation('SelectChildren'),
        macKeys: [{ type: KeyType.Key, char: '↩︎ Enter' }],
        winKeys: [{ type: KeyType.Key, char: '↩︎ Enter' }],
    },
    {
        name: translation('SelectParent'),
        macKeys: [
            { type: KeyType.Key, char: '\\' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: '↩︎ Enter' },
        ],
        winKeys: [
            { type: KeyType.Key, char: '\\' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: '↩︎ Enter' },
        ],
    },
    {
        name: translation('SelectNextSibling'),
        macKeys: [{ type: KeyType.Key, char: '⇥ Tab' }],
        winKeys: [{ type: KeyType.Key, char: '⇥ Tab' }],
    },
    {
        name: translation('SelectPreviousSibling'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: '⇥ Tab' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: '⇥ Tab' },
        ],
    },
]
export const selectionTabRight: Item[] = [
    {
        name: translation('DeepSelect'),
        macKeys: [
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: translation('WhileClicking...') },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: translation('WhileClicking...') },
        ],
    },
    {
        name: translation('DeepSelectWithin'),
        macKeys: [
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: translation('Drag') },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: translation('Drag') },
        ],
    },
]

export const transformTabLeft: Item[] = [
    {
        name: translation('FlattenSelection'),
        macKeys: [
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'E' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'E' },
        ],
    },
    {
        name: translation('OutlineStroke'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'O' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: 'O' },
        ],
    },
    {
        name: translation('UseAsMask'),
        macKeys: [
            { type: KeyType.Key, char: '⌃' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'M' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: 'M' },
        ],
    },
    {
        name: `${translation('EditShape')}/${translation('Image')}`,
        macKeys: [{ type: KeyType.Key, char: '↩︎ Enter' }],
        winKeys: [{ type: KeyType.Key, char: '↩︎ Enter' }],
    },
]
export const transformTabCenter: Item[] = [
    {
        name: translation('FlipHorizontal'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: 'H' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: 'H' },
        ],
    },
    {
        name: translation('FlipVertical'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: 'V' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: 'V' },
        ],
    },
    {
        name: translation('Resize'),
        macKeys: [
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: translation('ArrowKeys') },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: translation('ArrowKeys') },
        ],
    },
]
export const transformTabRight: Item[] = [
    { name: translation('WhileResizing...'), macKeys: [], winKeys: [] },
    {
        name: translation('ResizeFromCenter'),
        macKeys: [{ type: KeyType.Key, char: '⌥' }],
        winKeys: [{ type: KeyType.Key, char: 'Alt' }],
    },
    {
        name: translation('ResizeProportionally'),
        macKeys: [{ type: KeyType.Key, char: '⇧' }],
        winKeys: [{ type: KeyType.Key, char: 'Shift' }],
    },
    {
        name: `${translation('Crop(images)')}/${translation('IgnoreConstraints(frames)')}`,
        macKeys: [{ type: KeyType.Key, char: '⌘' }],
        winKeys: [{ type: KeyType.Key, char: 'Ctrl' }],
    },
]

export const layerTabLeft: Item[] = [
    {
        name: translation('GroupSelection'),
        macKeys: [
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'G' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'G' },
        ],
    },
    {
        name: translation('UngroupSelection'),
        macKeys: [
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: '⌫' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: '⌫' },
        ],
    },
    {
        name: translation('FrameSelection'),
        macKeys: [
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'G' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: 'G' },
        ],
    },
]
export const layerTabCenter: Item[] = [
    {
        name: translation('RemoveFill'),
        macKeys: [
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: '/' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: '/' },
        ],
    },
    {
        name: translation('RemoveStroke'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: '/' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: '/' },
        ],
    },
    {
        name: translation('SwapFillAnd'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: 'X' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: 'X' },
        ],
    },
    {
        name: `${translation('Show')}/${translation('HideSelection')}`,
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'H' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: 'H' },
        ],
    },
    {
        name: `${translation('Lock')}/${translation('UnlockSelection')}`,
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'L' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: 'L' },
        ],
    },
]
export const layerTabRight: Item[] = [
    {
        name: `${translation('SetOpacityTo')} 0%`,
        macKeys: [
            { type: KeyType.Key, char: '0' },
            { type: KeyType.Key, char: '0' },
        ],
        winKeys: [
            { type: KeyType.Key, char: '0' },
            { type: KeyType.Key, char: '0' },
        ],
    },
    {
        name: `${translation('SetOpacityTo')} 10%`,
        macKeys: [{ type: KeyType.Key, char: '1' }],
        winKeys: [{ type: KeyType.Key, char: '1' }],
    },
    {
        name: `${translation('SetOpacityTo')} 50%`,
        macKeys: [{ type: KeyType.Key, char: '5' }],
        winKeys: [{ type: KeyType.Key, char: '5' }],
    },
    {
        name: `${translation('SetOpacityTo')} 100%`,
        macKeys: [{ type: KeyType.Key, char: '0' }],
        winKeys: [{ type: KeyType.Key, char: '0' }],
    },
]

export const editTabLeft: Item[] = [
    {
        name: translation('Copy'),
        macKeys: [
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'C' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'C' },
        ],
    },
    {
        name: translation('Cut'),
        macKeys: [
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'X' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'X' },
        ],
    },
    {
        name: translation('Paste'),
        macKeys: [
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'V' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'V' },
        ],
    },
]
export const editTabCenter: Item[] = [
    {
        name: translation('PasteToReplace'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'R' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: 'R' },
        ],
    },
    {
        name: translation('PasteOverSelection'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'V' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: 'V' },
        ],
    },
    {
        name: translation('Duplicate'),
        macKeys: [
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'D' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'D' },
        ],
    },
    {
        name: translation('DragToDuplicate'),
        macKeys: [
            { type: KeyType.Key, char: ' ⌥' },
            { type: KeyType.Key, char: translation('Drag') },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: translation('Drag') },
        ],
    },
]
export const editTabRight: Item[] = [
    {
        name: `${translation('CopyAs')} PNG`,
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'C' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: 'C' },
        ],
    },
    {
        name: translation('CopyProperties'),
        macKeys: [
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'C' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: 'C' },
        ],
    },
    {
        name: translation('PasteProperties'),
        macKeys: [
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'V' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: 'V' },
        ],
    },
    {
        name: translation('RenameSelection'),
        macKeys: [
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'R' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'R' },
        ],
    },
]

export const sortTabLeft: Item[] = [
    {
        name: translation('BringForward'),
        macKeys: [
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: ']' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: ']' },
        ],
    },
    {
        name: translation('SendBackward'),
        macKeys: [
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: '[' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: '[' },
        ],
    },
    {
        name: translation('BringToFront'),
        macKeys: [{ type: KeyType.Key, char: ']' }],
        winKeys: [{ type: KeyType.Key, char: ']' }],
    },
    {
        name: translation('SendToBack'),
        macKeys: [{ type: KeyType.Key, char: '[' }],
        winKeys: [{ type: KeyType.Key, char: '[' }],
    },
]
export const sortTabCenter: Item[] = [
    {
        name: `${translation('AlignLeftAndRight')}`,
        macKeys: [
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: 'A' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: 'D' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: 'A' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: 'D' },
        ],
    },
    {
        name: `${translation('AlignTop')}/${translation('Bottom')}`,
        macKeys: [
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: 'W' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: 'S' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: 'W' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: 'S' },
        ],
    },
    {
        name: translation('AlignCenters'),
        macKeys: [
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: 'H' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: 'V' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: 'H' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: 'V' },
        ],
    },
    {
        name: translation('DistributeSpacing'),
        macKeys: [
            { type: KeyType.Key, char: '⌃' },
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: 'H' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: 'V' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: 'H' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: 'V' },
        ],
    },
    {
        name: translation('TidyUp'),
        macKeys: [
            { type: KeyType.Key, char: '⌃' },
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: 'T' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: 'T' },
        ],
    },
]

export const sortTabRight: Item[] = [
    {
        name: translation('AddAutoLayout'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: 'A' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: 'A' },
        ],
    },
    {
        name: translation('RemoveAutoLayout'),
        macKeys: [
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: 'A' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: 'A' },
        ],
    },
]
