// 这个文件必须用 tsx 后缀，用.ts的话 uno 会跳过解析
import { WKTextProps } from './type'

export const getFontSize = (size: WKTextProps['size']) => {
    switch (size) {
        case 14:
            return 'wk-text-14'
        case 16:
            return 'wk-text-16'
        case 18:
            return 'wk-text-18'
        case 22:
            return 'wk-text-22'
        case 28:
            return 'wk-text-28'
        case 12:
        default:
            return 'wk-text-12'
    }
}

export const getFontWeight = (weight: WKTextProps['weight']) => {
    switch (weight) {
        case 'medium':
            return 'wk-font-medium'
        case 'semibold':
            return 'wk-font-semibold'
        case 'regular':
        default:
            return 'wk-font-regular'
    }
}

export const getFontColor = (color: WKTextProps['color']) => {
    switch (color) {
        case 'secondary':
            return 'color-$wk-gray-11'
        case 'placeholder':
            return 'color-$wk-v2-label-color-gray-8'
        case 'disabled':
            return 'color-$wk-gray-6'
        case 'error':
            return 'color-$wk-red-8'
        case 'success':
            return 'color-$wk-green-8'
        case 'warning':
            return 'color-$wk-yellow-7'
        case 'white':
            return 'color-white'
        default:
        case 'primary':
            return 'color-$wk-v2-label-color-gray-13'
    }
}
