import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Inspect: '标注',
    Comments: '评论',
} as const

export const enTranslation = {
    Inspect: 'Inspect',
    Comments: 'Comments',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
