import { sleep } from '../../../../../util/src'
import { createSwitchTask, signalDebounce } from '../../../../../util/src/abort-controller/signal-task'
import {
    TraceableAbortController,
    TraceableAbortSignal,
} from '../../../../../util/src/abort-controller/traceable-abort-controller'
import { ReplayEventEmitter } from '../../../../../util/src/event-emitter/replay-event-emitter'
import type { NetworkService } from '../../../document/synergy/network-service'
import { WkCLog } from '../../../kernel/clog/wukong/instance'
import { LibraryCredentialsVO, OssCredentials } from '../../../kernel/interface/oss'
import { CloudAuthClient } from '../../../kernel/request/cloud-auth-client'
import { GetLibraryCredentialsRequest } from '../../../kernel/request/library'
import { ServiceClass } from '../../../kernel/util/service-class'

const MIN_1 = 60000
const FETCH_TIMEOUT = 3000 // 授权接口请求时长上限

export enum LibraryResourceOssClientType {
    Component = 'componentOssClient',
    Style = 'styleOssClient',
    Library = 'libraryOssClient',
    Variable = 'variableOssClient',
    VariableSet = 'variableCollectionOssClient',
}

const LibraryResourceOssClientType2PropConfig: Record<
    LibraryResourceOssClientType,
    {
        pathPropName: keyof Pick<
            LibraryCredentialsVO,
            'componentPath' | 'stylePath' | 'libraryPath' | 'variablePath' | 'variableCollectionPath'
        >
        credentialsPropName: keyof Pick<
            LibraryCredentialsVO,
            | 'componentCredentials'
            | 'styleCredentials'
            | 'libraryCredentials'
            | 'variableCredentials'
            | 'variableCollectionCredentials'
        >
    }
> = {
    [LibraryResourceOssClientType.Component]: {
        pathPropName: 'componentPath',
        credentialsPropName: 'componentCredentials',
    },
    [LibraryResourceOssClientType.Style]: {
        pathPropName: 'stylePath',
        credentialsPropName: 'styleCredentials',
    },
    [LibraryResourceOssClientType.Library]: {
        pathPropName: 'libraryPath',
        credentialsPropName: 'libraryCredentials',
    },
    [LibraryResourceOssClientType.Variable]: {
        pathPropName: 'variablePath',
        credentialsPropName: 'variableCredentials',
    },
    [LibraryResourceOssClientType.VariableSet]: {
        pathPropName: 'variableCollectionPath',
        credentialsPropName: 'variableCollectionCredentials',
    },
}

// 组件库资源下载
export class LibraryResourceDownloader extends ServiceClass {
    // NOTE: 分多个 ossClient 是因为 server 历史数据对 component 和 style 就是分目录存储，暂时无法处理
    private ossClientMap = new Map<LibraryResourceOssClientType, CloudAuthClient>()

    private expirationTime = 0

    private libraryCredentialsV1$ = new ReplayEventEmitter<LibraryCredentialsVO>(1)

    private online = false
    private requestLibraryCredentials: () => void
    private refresh: () => void

    constructor(private networkService: NetworkService, private signal: TraceableAbortSignal) {
        super()
        this.requestLibraryCredentials = createSwitchTask(this.signal, async (signal: TraceableAbortSignal) => {
            if (!this.online) {
                return
            }
            new GetLibraryCredentialsRequest()
                .startWithSignal(signal)
                .then((ret) => {
                    this.libraryCredentialsV1$.next(ret)
                })
                .catch(() => {})
        })
        this.refresh = signalDebounce(
            this.signal,
            () => {
                this.requestLibraryCredentials()
            },
            300
        )
        this.init()
    }

    private init = () => {
        this.networkService.onBrowserOnlineChangeWithSignal(this.signal, (online) => {
            this.online = online
            this.requestLibraryCredentials()
        })

        this.libraryCredentialsV1$.onWithSignal(this.signal, (newlibraryCredentials) => {
            this.expirationTime = Math.min(
                new Date(newlibraryCredentials.styleCredentials.expiration).getTime(),
                new Date(newlibraryCredentials.componentCredentials.expiration).getTime()
            )
        })
    }

    private resetLibraryCredentials = async (needWaitFirstValue?: boolean) => {
        this.ossClientMap.clear()

        const prevLibraryCredentials = needWaitFirstValue ? await this.initLibraryCredentials().catch(() => null) : null
        if (this.isDestroy) {
            return
        }
        this.refresh()
        const fetchLibraryCredentials = async (): Promise<LibraryCredentialsVO> => {
            return new Promise((resolve) => {
                const checkExpiration = (componentCredentials: OssCredentials, styleCredentials: OssCredentials) => {
                    return (
                        !prevLibraryCredentials ||
                        (componentCredentials.expiration !== prevLibraryCredentials.componentCredentials.expiration &&
                            styleCredentials.expiration !== prevLibraryCredentials.styleCredentials.expiration)
                    )
                }
                const controller = new TraceableAbortController('LibraryResourceDownloader.fetchLibraryCredentials')
                this.libraryCredentialsV1$.onWithSignal(controller.signal, (libraryCredentials) => {
                    if (checkExpiration(libraryCredentials.componentCredentials, libraryCredentials.styleCredentials)) {
                        resolve(libraryCredentials)
                        controller.abort('LibraryResourceDownloader.fetchLibraryCredentials success')
                    }
                })
            })
        }

        const newLibraryCredentials = await Promise.race([fetchLibraryCredentials(), sleep(FETCH_TIMEOUT)])

        if (this.isDestroy) {
            return
        }

        if (!newLibraryCredentials) {
            return
        }

        if (typeof newLibraryCredentials === 'string') {
            WkCLog.log(
                `[oss] reset libraryCredentials timeout (online=${
                    this.networkService.states.getState().browserOnlineState ? 'true' : 'false'
                }, current=${JSON.stringify(prevLibraryCredentials?.componentCredentials)})`
            )
            throw new Error('[oss] reset libraryCredentials timeout')
        }

        WkCLog.log(
            `[oss] reset libraryCredentials success (current=${JSON.stringify(
                newLibraryCredentials.componentCredentials
            )})`
        )
    }

    private initLibraryCredentials = async () => {
        const libraryCredentials = await Promise.race([
            this.libraryCredentialsV1$.getFirstValue(this.signal),
            sleep(FETCH_TIMEOUT),
        ])

        if (typeof libraryCredentials === 'string') {
            WkCLog.log(
                `[oss] init libraryCredentials timeout (online=${
                    this.networkService.states.getState().browserOnlineState ? 'true' : 'false'
                })`
            )
            throw new Error('[oss] init libraryCredentials timeout')
        }

        return libraryCredentials
    }

    private refreshLibraryCredentials = async () => {
        // 过期则重置超时时间
        if (!this.expirationTime || Date.now() > this.expirationTime - MIN_1) {
            await this.resetLibraryCredentials(!!this.expirationTime)
        }
    }

    private getOssClient = async (ossClientType: LibraryResourceOssClientType) => {
        await this.refreshLibraryCredentials()
        const libraryCredentials = await this.initLibraryCredentials()
        const { pathPropName, credentialsPropName } = LibraryResourceOssClientType2PropConfig[ossClientType]
        if (!this.ossClientMap.has(ossClientType)) {
            this.ossClientMap.set(
                ossClientType,
                new CloudAuthClient({
                    dirPath: libraryCredentials[pathPropName],
                    bucket: libraryCredentials.bucket,
                    region: libraryCredentials.region,
                    credentials: libraryCredentials[credentialsPropName],
                })
            )
            WkCLog.log(`[oss] init ${ossClientType} by ${JSON.stringify(libraryCredentials[credentialsPropName])}`)
        }
        return this.ossClientMap.get(ossClientType)!
    }

    public fetchFile = async (ossClientType: LibraryResourceOssClientType, path: string): Promise<Response> => {
        const fetch = async () => await (await this.getOssClient(ossClientType)).fetchFileByPath(path)
        try {
            return await fetch()
        } catch {
            WkCLog.log('[oss] fetchFile 开始兜底: ' + path)
            try {
                // 报错可能因为 credential 过期导致，重置 credential
                await this.resetLibraryCredentials()
                return await fetch()
            } catch (err) {
                WkCLog.log('[oss] fetchFile 兜底失败: ' + path)
                throw err
            }
        }
    }

    public fetchUrl = async (ossClientType: LibraryResourceOssClientType, path: string): Promise<string> => {
        const fetch = async () => (await this.getOssClient(ossClientType)).fetchUrlByPath(path)
        try {
            return await fetch()
        } catch {
            WkCLog.log('[oss] fetchUrl 开始兜底: ' + path)
            try {
                // 报错可能因为 credential 过期导致，重置 credential
                await this.resetLibraryCredentials()
                return await fetch()
            } catch (err) {
                WkCLog.log('[oss] fetchUrl 兜底失败: ' + path)
                throw err
            }
        }
    }
}
