import { command, computed, state } from 'ccstate'
import { getWasmDownloadUrl } from '../../../../../util/src'
import initWukong, { WukongEditor } from '../../../editor'
import { IN_JEST_TEST } from '../../../environment'
import { wasmBlobUrl$ } from '../../../external-store/atoms/preload-wasm'
import { WkCLog } from '../../../kernel/clog/wukong/instance'

let wukong: WukongEditor | undefined

export const setupWasmByBlob = async (wasmBlobUrl: Promise<string>) => {
    if (IN_JEST_TEST && wukong) {
        return wukong
    }
    try {
        const url = await wasmBlobUrl
        wukong = await initWukong({
            locateFile: () => url,
        })
        // 在每次重新加载 wasm heap 时清理
        cleanupEmscriptenGlobalMethod()
        return wukong
    } catch {
        return setupWasm()
    }
}

export const [wasm$, resetWasm$] = (() => {
    const refresh$ = state(0)

    const innerWasm$ = computed(async (get) => {
        get(refresh$)

        if (IN_JEST_TEST && wukong) {
            return wukong
        }
        try {
            const url = await get(wasmBlobUrl$)
            wukong = await initWukong({
                locateFile: () => url,
            })
            return wukong
        } catch (e) {
            return setupWasm()
        }
    })

    return [
        innerWasm$,
        command(({ set, get }) => {
            set(refresh$, (value) => value + 1)
            cleanupEmscriptenGlobalMethod()

            return get(innerWasm$)
        }),
    ]
})()

const setupWasm = async (retryTimes = 3): Promise<WukongEditor> => {
    if (retryTimes === 0) {
        WkCLog.log('init wasm failed after retry')
        throw new Error('init wasm failed')
    }

    try {
        wukong = await initWukong({
            locateFile: (file: any) => {
                return getWasmDownloadUrl(file)
            },
        })
        return wukong
    } catch {
        return setupWasm(retryTimes - 1)
    }
}

const cleanupEmscriptenGlobalMethod = () => {
    ;[
        'inetPton4',
        'inetNtop4',
        'inetPton6',
        'inetNtop6',
        'readSockaddr',
        'writeSockaddr',
        'getHostByName',
        'traverseStack',
        'getCallstack',
        'emscriptenLog',
        'convertPCtoSourceLocation',
        'runMainThreadEmAsm',
        'jstoi_s',
        'listenOnce',
        'autoResumeAudioContext',
        'getDynCaller',
        'dynCall',
        'runtimeKeepalivePush',
        'runtimeKeepalivePop',
        'asmjsMangle',
        'getNativeTypeSize',
        'STACK_SIZE',
        'STACK_ALIGN',
        'POINTER_SIZE',
        'ASSERTIONS',
        'writeI53ToI64Clamped',
        'writeI53ToI64Signaling',
        'writeI53ToU64Clamped',
        'writeI53ToU64Signaling',
        'convertI32PairToI53',
        'convertI32PairToI53Checked',
        'convertU32PairToI53',
        'uleb128Encode',
        'sigToWasmTypes',
        'generateFuncType',
        'convertJsFunctionToWasm',
        'getEmptyTableSlot',
        'updateTableMap',
        'getFunctionAddress',
        'addFunction',
        'removeFunction',
        'reallyNegative',
        'unSign',
        'strLen',
        'reSign',
        'formatString',
        'intArrayToString',
        'AsciiToString',
        'getSocketFromFD',
        'getSocketAddress',
        'registerKeyEventCallback',
        'maybeCStringToJsString',
        'findEventTarget',
        'findCanvasEventTarget',
        'getBoundingClientRect',
        'fillMouseEventData',
        'registerMouseEventCallback',
        'registerWheelEventCallback',
        'registerUiEventCallback',
        'registerFocusEventCallback',
        'fillDeviceOrientationEventData',
        'registerDeviceOrientationEventCallback',
        'fillDeviceMotionEventData',
        'registerDeviceMotionEventCallback',
        'screenOrientation',
        'fillOrientationChangeEventData',
        'registerOrientationChangeEventCallback',
        'fillFullscreenChangeEventData',
        'registerFullscreenChangeEventCallback',
        'JSEvents_requestFullscreen',
        'JSEvents_resizeCanvasForFullscreen',
        'registerRestoreOldStyle',
        'hideEverythingExceptGivenElement',
        'restoreHiddenElements',
        'setLetterbox',
        'softFullscreenResizeWebGLRenderTarget',
        'doRequestFullscreen',
        'fillPointerlockChangeEventData',
        'registerPointerlockChangeEventCallback',
        'registerPointerlockErrorEventCallback',
        'requestPointerLock',
        'fillVisibilityChangeEventData',
        'registerVisibilityChangeEventCallback',
        'registerTouchEventCallback',
        'fillGamepadEventData',
        'registerGamepadEventCallback',
        'registerBeforeUnloadEventCallback',
        'fillBatteryEventData',
        'battery',
        'registerBatteryEventCallback',
        'setCanvasElementSize',
        'getCanvasElementSize',
        'checkWasiClock',
        'wasiRightsToMuslOFlags',
        'wasiOFlagsToMuslOFlags',
        'createDyncallWrapper',
        'setImmediateWrapped',
        'clearImmediateWrapped',
        'polyfillSetImmediate',
        'getPromise',
        'makePromise',
        'makePromiseCallback',
        'ExceptionInfo',
        'exception_addRef',
        'exception_decRef',
        'setMainLoop',
        '_setNetworkCallback',
        'emscriptenWebGLGetUniform',
        'emscriptenWebGLGetVertexAttrib',
        '__glGetActiveAttribOrUniform',
        'writeGLArray',
        'registerWebGlEventCallback',
        'runAndAbortIfError',
        'SDL_unicode',
        'SDL_ttfContext',
        'SDL_audio',
        'GLFW_Window',
        'emscriptenWebGLGetIndexed',
        'ALLOC_NORMAL',
        'ALLOC_STACK',
        'allocate',
        'writeStringToMemory',
        'writeAsciiToMemory',
        'fetchDeleteCachedData',
        'fetchLoadCachedData',
        'fetchCacheData',
        'fetchXHR',
        'init_embind',
        'throwUnboundTypeError',
        'ensureOverloadTable',
        'exposePublicSymbol',
        'replacePublicSymbol',
        'getBasestPointer',
        'registerInheritedInstance',
        'unregisterInheritedInstance',
        'getInheritedInstance',
        'getInheritedInstanceCount',
        'getLiveInheritedInstances',
        'getTypeName',
        'heap32VectorToArray',
        'requireRegisteredType',
        'enumReadValueFromPointer',
        'runDestructors',
        'newFunc',
        'craftInvokerFunction',
        'embind__requireFunction',
        'genericPointerToWireType',
        'constNoSmartPtrRawPointerToWireType',
        'nonConstNoSmartPtrRawPointerToWireType',
        'init_RegisteredPointer',
        'RegisteredPointer',
        'RegisteredPointer_getPointee',
        'RegisteredPointer_destructor',
        'RegisteredPointer_deleteObject',
        'RegisteredPointer_fromWireType',
        'runDestructor',
        'releaseClassHandle',
        'detachFinalizer',
        'attachFinalizer',
        'makeClassHandle',
        'init_ClassHandle',
        'ClassHandle',
        'ClassHandle_isAliasOf',
        'throwInstanceAlreadyDeleted',
        'ClassHandle_clone',
        'ClassHandle_delete',
        'ClassHandle_isDeleted',
        'ClassHandle_deleteLater',
        'flushPendingDeletes',
        'setDelayFunction',
        'RegisteredClass',
        'shallowCopyInternalPointer',
        'downcastPointer',
        'upcastPointer',
        'validateThis',
        'getStringOrSymbol',
        'craftEmvalAllocator',
        'emval_get_global',
        'emval_lookupTypes',
        'emval_allocateDestructors',
        'emval_addMethodCaller',
    ].forEach((prop) => {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete (window as any)[prop]
    })
    delete (window as any).buffer
}
