import { Wukong } from '@wukong/bridge-proto'

type OnBatchOperation = (proto: Wukong.ServerProto.SynergyMessageProto) => void

export interface BatchOperationManager {
    onBatchOperation: OnBatchOperation
    clearBatchOperationQueueWhenDisconnected: () => void
}

/**
 * Mirror 环境下，间隔一定时间(delayTime，默认为 1s) 或者 积累的操作(batchOperationQueue) 大于 maxByteSize(默认为 1MB) 时，统一执行一次 BatchOperation
 */
export class MirrorBatchOperationManager implements BatchOperationManager {
    private readonly batchOperationQueue: Wukong.ServerProto.SynergyMessageProto[] = []
    private lastFlushTime = 0
    private batchOperationByteSize = 0
    private flushTimer = 0
    static readonly defaultDelayTime = 1000 // 1s
    static readonly defaultMaxByteSize = 1024 * 1024 // 1MB

    constructor(
        private readonly performBatchOperation: OnBatchOperation,
        private readonly delayTime = MirrorBatchOperationManager.defaultDelayTime,
        private readonly maxByteSize = MirrorBatchOperationManager.defaultMaxByteSize
    ) {}

    onBatchOperation(proto: Wukong.ServerProto.SynergyMessageProto) {
        this.batchOperationQueue.push(proto)
        this.batchOperationByteSize += proto.payload.byteLength
        const currentTime = performance.now()

        clearTimeout(this.flushTimer)
        if (this.isFlushBatchOperation(currentTime)) {
            this.flushBatchOperation()
        } else {
            this.flushTimer = setTimeout(() => {
                this.flushBatchOperation()
            }, this.lastFlushTime + this.delayTime - currentTime) as unknown as number
        }
    }

    clearBatchOperationQueueWhenDisconnected() {
        this.batchOperationQueue.length = 0
        this.batchOperationByteSize = 0
    }

    private isFlushBatchOperation(currentTime: number): boolean {
        if (currentTime - this.lastFlushTime > this.delayTime) {
            return true
        }

        if (this.batchOperationByteSize > this.maxByteSize) {
            return true
        }

        return false
    }

    private flushBatchOperation() {
        for (const batchOperation of this.batchOperationQueue) {
            this.performBatchOperation(batchOperation)
        }

        this.lastFlushTime = performance.now()
        this.batchOperationQueue.length = 0
        this.batchOperationByteSize = 0
    }
}
