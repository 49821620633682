/* eslint-disable no-restricted-imports */
import { GetDocOnlineUsers } from '../../../kernel/request/doc-users'
import { SpaceNotifyFlow } from './notify-flow'
import { DocOnlineUserStore, SpaceStoreType, getSpaceStore, setSpaceStore } from './types'

const delayedCallback = <T>(callback: (ids: T[]) => void, delayTime = 200) => {
    const queuedIds: T[] = []
    let timer: NodeJS.Timeout
    return (id: T) => {
        clearTimeout(timer)
        queuedIds.push(id)
        timer = setTimeout(() => {
            callback(queuedIds)
            queuedIds.length = 0
        }, delayTime)
    }
}

export const generateDocOnlineUserState = (set: setSpaceStore, get: getSpaceStore): DocOnlineUserStore => {
    const fetchOnlineUsers = delayedCallback(async (docIds: string[]) => {
        const unfechedDocIds = docIds.filter((docId) => {
            return !get().docOnlineUserStore.docOnlineUserVO?.[docId]
        })
        if (unfechedDocIds.length) {
            get().docOnlineUserStore.updateOnlineUsers(unfechedDocIds)
        }
    })

    return {
        docOnlineUserVO: {},
        clearDocOnlineUserVO: () => {
            set(() => ({ docOnlineUserVO: undefined }))
        },
        updateOnlineUsers: async (docIds: string[]) => {
            try {
                const res = await new GetDocOnlineUsers(docIds).start()
                set((state) => {
                    const docOnlineUserVO = state.docOnlineUserStore.docOnlineUserVO
                        ? { ...state.docOnlineUserStore.docOnlineUserVO, ...res }
                        : res
                    state.docOnlineUserStore.docOnlineUserVO = docOnlineUserVO
                })
            } catch (e) {}
        },
        fetchOnlineUsers: fetchOnlineUsers,
    }
}

export const syncDocOnlineUsers = (store: SpaceStoreType, notifyFlow: SpaceNotifyFlow) => {
    notifyFlow.addDocOnlineUserChangeCallback((id) => {
        const docList = store.getState().docListPageStore.docList
        if (docList.some((doc) => doc.id == id)) {
            store.getState().docOnlineUserStore.updateOnlineUsers([id])
        }
    })
}
