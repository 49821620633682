import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    ColorStyles: '颜色样式',
    TextStyles: '文本样式',
    EffectStyles: '效果样式',
    GridStyles: '布局网格样式',
    CreateStyle: '创建样式',
    TeamLibrary: '团队组件库',
    ShowAsList: '显示为列表',
    ShowAsGrid: '显示为网格',
} as const

export const enTranslation = {
    ColorStyles: 'Color styles',
    TextStyles: 'Text styles',
    EffectStyles: 'Effect styles',
    GridStyles: 'Grid styles',
    CreateStyle: 'Create style',
    TeamLibrary: 'Libraries',
    ShowAsList: 'Show as list',
    ShowAsGrid: 'Show as grid',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
