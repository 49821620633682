/* eslint-disable no-restricted-imports */
import { useMemo } from 'react'
import { WKItemType } from '../../../../../../ui-lib/src'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { MainMenu } from './main-menu'
import { useMenuBack } from './menu-config/back'
import { useMotiffDebugger } from './menu-config/debugger'
import { useDesignModeMenuEdit, useDevModeMenuEdit } from './menu-config/edit'
import { useDesignModeMenuFile, useDevModeMenuFile } from './menu-config/file'
import { useDesignModeMenuHelpAccount, useDevModeMenuHelpAccount } from './menu-config/help-account'
import { useInvokeDesktopMenu } from './menu-config/invoke-desktop'
import { useMenuObject } from './menu-config/object'
import { useDesignModeMenuPreference, useDevModeMenuPreference } from './menu-config/preference'
import { useMenuSort } from './menu-config/sort'
import { useDesignModeMenuView, useDevModeMenuView } from './menu-config/view'

export function DesignModeMenuWrapper() {
    const command = useCommand()

    const docReadonly = useViewState('docReadonly')

    const { menuConfig: menuBack } = useMenuBack()

    const menuObject = useMenuObject(command)

    const menuView = useDesignModeMenuView(command)

    const menuEdit = useDesignModeMenuEdit(command)

    const menuSort = useMenuSort(command)

    const menuPreference = useDesignModeMenuPreference(command)

    const menuFile = useDesignModeMenuFile(command)

    const menuHelpAccount = useDesignModeMenuHelpAccount(command)

    const menuDebugger = useMotiffDebugger()

    const menuDesktop = useInvokeDesktopMenu()

    const config = useMemo(
        () =>
            docReadonly
                ? menuBack.concat(
                      menuFile,
                      menuEdit,
                      menuView,
                      { type: WKItemType.Divider },
                      menuPreference,
                      menuHelpAccount,
                      menuDebugger,
                      menuDesktop
                  )
                : menuBack.concat(
                      menuFile,
                      menuEdit,
                      menuView,
                      menuObject,
                      menuSort,
                      { type: WKItemType.Divider },
                      menuPreference,
                      menuHelpAccount,
                      menuDebugger,
                      menuDesktop
                  ),
        [
            docReadonly,
            menuBack,
            menuFile,
            menuEdit,
            menuView,
            menuPreference,
            menuHelpAccount,
            menuObject,
            menuSort,
            menuDebugger,
            menuDesktop,
        ]
    )

    return <MainMenu config={config}></MainMenu>
}

export function DevModeMenuWrapper() {
    const command = useCommand()

    const { menuConfig: menuBack } = useMenuBack()

    const menuView = useDevModeMenuView(command)

    const menuEdit = useDevModeMenuEdit(command)

    const menuPreference = useDevModeMenuPreference(command)

    const menuFile = useDevModeMenuFile(command)

    const menuHelpAccount = useDevModeMenuHelpAccount(command)

    const menuDebugger = useMotiffDebugger()

    const menuDesktop = useInvokeDesktopMenu()

    const config = useMemo(
        () =>
            menuBack.concat(
                menuFile,
                menuEdit,
                menuView,
                { type: WKItemType.Divider },
                menuPreference,
                menuHelpAccount,
                menuDebugger,
                menuDesktop
            ),
        [menuBack, menuFile, menuEdit, menuView, menuPreference, menuHelpAccount, menuDebugger, menuDesktop]
    )

    return <MainMenu config={config}></MainMenu>
}
