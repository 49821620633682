import { DELETE, GET, POST, PUT } from '@tutor/network-core'
import { BaseCommonRequest } from './base-request'

@POST
export class AIGenAddTask extends BaseCommonRequest<{ taskId: string }> {
    constructor(
        private docId: string,
        private prompt: string,
        private styleConfigId?: number,
        private configId?: number,
        private version?: string,
        private promptImageUrl?: string,
        private features?: string
    ) {
        super()
    }

    public override requestUrl() {
        return `ai/ai-gen/${this.docId}/gen`
    }

    public override requestBody() {
        return {
            prompt: this.prompt,
            configId: this.configId,
            version: this.version,
            promptImageUrl: this.promptImageUrl,
            features: this.features,
            styleConfigId: this.styleConfigId,
        }
    }
}

@POST
export class AIGenFeatures extends BaseCommonRequest<{
    result: string | undefined
}> {
    constructor(
        private docId: string,
        private prompt: string,
        private promptImageUrl: string | null,
        private version?: string
    ) {
        super()
    }

    public override requestUrl() {
        return `ai/ai-gen/${this.docId}/features`
    }

    public override requestBody() {
        return {
            prompt: this.prompt,
            version: this.version,
            promptImageUrl: this.promptImageUrl ?? undefined,
        }
    }
}

@GET
export class AIGenGetTaskStatus extends BaseCommonRequest<{
    success: boolean
    finished: boolean
    result?: string | null
}> {
    constructor(private taskId: string) {
        super()
    }

    public override requestUrl() {
        return `ai/ai-gen/${this.taskId}`
    }
}

@GET
export class AIGenExamples extends BaseCommonRequest<string[]> {
    public override requestUrl() {
        return `ai/ai-gen/examples`
    }
}

@PUT
export class AIGenPlayground extends BaseCommonRequest<{ docId: string }> {
    public override requestUrl(): string {
        return 'ai/ai-gen/playground'
    }
}

@POST
export class AIGenAllInOnePlayground extends BaseCommonRequest<{ docId: string }> {
    constructor(private orgId?: string) {
        super()
    }
    public override requestUrl(): string {
        return 'ai/ai-gen/all-in-one-playground'
    }
    public override requestBody() {
        return {
            orgId: this.orgId,
        }
    }
}

export interface StyleConfig {
    platform: string
    designSystem: string
    name: string
    id: number
    config: string
    builtin: boolean
}

@GET
export class AIGenUserDesignSystems extends BaseCommonRequest<{
    builtinConfigs: StyleConfig[]
    userConfigs: StyleConfig[]
    lastUsedConfig: number
}> {
    public override requestUrl(): string {
        return 'ai/ai-gen/style-configs'
    }
}

@POST
export class AIGenCreateDesignSystem extends BaseCommonRequest<string> {
    constructor(private platform: string, private designSystem: string, private name: string, private config: string) {
        super()
    }

    public override requestUrl(): string {
        return 'ai/ai-gen/style-configs'
    }

    public override requestBody() {
        return { name: this.name, config: this.config, platform: this.platform, designSystem: this.designSystem }
    }
}

@PUT
export class AIGenModifyDesignSystem extends BaseCommonRequest<void> {
    constructor(
        private id: number,
        private platform?: string,
        private designSystem?: string,
        private name?: string,
        private config?: string
    ) {
        super()
    }

    public override requestUrl(): string {
        return `ai/ai-gen/style-configs/${this.id}`
    }

    public override requestBody() {
        return Object.fromEntries(
            Object.entries({
                name: this.name,
                config: this.config,
                platform: this.platform,
                designSystem: this.designSystem,
            }).filter(([_, v]) => v !== undefined)
        )
    }
}

@DELETE
export class AIGenDeleteDesignSystem extends BaseCommonRequest {
    constructor(private id: number) {
        super()
    }

    public override requestUrl(): string {
        return `ai/ai-gen/style-configs/${this.id}`
    }
}

@POST
export class AIGenSelectDesignSystem extends BaseCommonRequest<void> {
    constructor(private id: number) {
        super()
    }

    public override requestUrl(): string {
        return `ai/ai-gen/style-configs/selection`
    }

    public override requestBody() {
        return {
            id: this.id,
        }
    }
}

@POST
export class AIGenMetric extends BaseCommonRequest<void> {
    constructor(private docId: string, private metricName: string, private metricValue: string) {
        super()
    }

    public override requestUrl(): string {
        return `ai/ai-gen/metrics`
    }

    public override requestBody() {
        return {
            docId: this.docId,
            metricName: this.metricName,
            metricValue: this.metricValue,
        }
    }
}
