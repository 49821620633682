import { LocateNodeInDocumentForDebugOnly } from '@wukong/bridge-proto'
import { CommandInvoker } from '../../document/command/command-invoker'
import { WK } from '../../window'

export function registerLocateNodeCommand(commandInvoker: CommandInvoker) {
    WK.locate = (nodeId: string) => {
        commandInvoker.DEPRECATED_invokeBridge(LocateNodeInDocumentForDebugOnly, { value: nodeId })
    }
    return () => delete WK.locate
}
