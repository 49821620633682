/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'

export type NodeId = string
export type StyleId = string | Mixed
export type TextStyleId = string

import EditorMode = Wukong.DocumentProto.EditorMode
export { EditorMode }

export interface Viewport {
    // canvas 元素的宽高，不随 zoom 变化
    readonly width: number
    readonly height: number
    // canvas 左上角原点的世界坐标
    readonly x: number
    readonly y: number
    // canvas 视窗的缩放比例。zoom > 1 为放大
    readonly zoom: number
}

export interface Transform {
    readonly scaleX: number
    readonly skewX: number
    readonly translateX: number
    readonly skewY: number
    readonly scaleY: number
    readonly translateY: number
}

export interface Vector {
    readonly x: number
    readonly y: number
}

export interface Line {
    readonly start: Vector
    readonly end: Vector
}

export interface Rect {
    readonly x: number
    readonly y: number
    readonly width: number
    readonly height: number
}

export interface RGB {
    readonly r: number
    readonly g: number
    readonly b: number
}

export interface RGBA {
    readonly r: number
    readonly g: number
    readonly b: number
    readonly a: number
}

export interface FontName {
    readonly family: string
    readonly style: string
    readonly localizedFamily: string
    readonly localizedStyle: string
    readonly width: number
    readonly weight: number
    readonly italic: boolean
}

export interface ColorPick {
    readonly isDisplay: boolean
}

export enum ColorPickCommandType {
    Null = Wukong.DocumentProto.ColorPickCommandType.CP_Null,
    DisplayColorPick = Wukong.DocumentProto.ColorPickCommandType.CP_DisplayColorPick,
    DismissColorPick = Wukong.DocumentProto.ColorPickCommandType.CP_DismissColorPick,
    OpenContinuePick = Wukong.DocumentProto.ColorPickCommandType.CP_OpenContinuePick,
    CloseContinuePick = Wukong.DocumentProto.ColorPickCommandType.CP_CloseContinuePick,
    SwitchColorPickVisible = Wukong.DocumentProto.ColorPickCommandType.CP_SwitchColorPickVisible,
    PickColorEnd = Wukong.DocumentProto.ColorPickCommandType.CP_PickColorEnd,
    EnablePick = Wukong.DocumentProto.ColorPickCommandType.CP_EnablePick,
    DisablePick = Wukong.DocumentProto.ColorPickCommandType.CP_DisablePick,
    ConflictAppear = Wukong.DocumentProto.ColorPickCommandType.CP_ConflictAppear,
    ConflictDismiss = Wukong.DocumentProto.ColorPickCommandType.CP_ConflictDismiss,
}

export enum ColorMode {
    HEX = Wukong.DocumentProto.ColorMode.COLOR_MODE_H_E_X,
    RGB = Wukong.DocumentProto.ColorMode.COLOR_MODE_R_G_B,
    CSS = Wukong.DocumentProto.ColorMode.COLOR_MODE_C_S_S,
    HSL = Wukong.DocumentProto.ColorMode.COLOR_MODE_H_S_L,
    HSB = Wukong.DocumentProto.ColorMode.COLOR_MODE_H_S_B,
}

export enum TextCase {
    Original,
    Upper,
    Lower,
    Title,
}

export enum TextDecoration {
    None,
    Underline,
    Strikethrough,
}

export enum TextTruncation {
    Disabled,
    Ending,
}

export enum EffectType {
    DropShadow,
    InnerShadow,
    LayerBlur,
    BackgroundBlur,
}

export interface DropShadowEffect {
    readonly type: EffectType.DropShadow
    readonly color: RGBA
    readonly offset: Vector
    readonly radius: number
    readonly spread: number
    readonly visible: boolean
    readonly blendMode: Wukong.DocumentProto.BlendMode
    readonly showShadowBehindNode: boolean
}

export interface InnerShadowEffect {
    readonly type: EffectType.InnerShadow
    readonly color: RGBA
    readonly offset: Vector
    readonly radius: number
    readonly spread: number
    readonly visible: boolean
    readonly blendMode: Wukong.DocumentProto.BlendMode
}

export interface BlurEffect {
    readonly type: EffectType.BackgroundBlur | EffectType.LayerBlur
    readonly radius: number
    readonly visible: boolean
}

export type Effect = DropShadowEffect | InnerShadowEffect | BlurEffect

export interface Constraints {
    readonly horizontal: Wukong.DocumentProto.ConstraintType
    readonly vertical: Wukong.DocumentProto.ConstraintType
}

export interface ColorStop {
    readonly position: number
    readonly color: RGBA
}

export interface ImageFilters {
    readonly exposure: number
    readonly contrast: number
    readonly saturation: number
    readonly temperature: number
    readonly tint: number
    readonly highlights: number
    readonly shadows: number
}

export interface SolidPaint {
    readonly type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT
    readonly color: RGB
    readonly visible?: boolean
    readonly opacity?: number
    readonly blendMode?: Wukong.DocumentProto.BlendMode
}

export interface GradientPaint {
    readonly type:
        | Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_LINEAR
        | Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_RADIAL
        | Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_ANGULAR
        | Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_DIAMOND
    readonly gradientTransform: Transform
    readonly gradientStops: ColorStop[]
    readonly visible?: boolean
    readonly opacity?: number
    readonly blendMode?: Wukong.DocumentProto.BlendMode
}

export interface ImagePaint {
    readonly type: Wukong.DocumentProto.PaintType.PAINT_TYPE_IMAGE_PAINT
    readonly scaleMode: Wukong.DocumentProto.ScaleMode
    readonly imageHash: string | null
    readonly imageName: string | null
    readonly imageTransform?: Transform
    readonly scalingFactor?: number
    readonly rotation?: number
    readonly filters?: ImageFilters
    readonly visible?: boolean
    readonly opacity?: number
    readonly blendMode?: Wukong.DocumentProto.BlendMode
    readonly previewHash?: string
}

export interface IEmojiPaint {
    readonly type: Wukong.DocumentProto.PaintType.PAINT_TYPE_EMOJI
    readonly visible?: boolean
    readonly opacity?: number
    readonly blendMode?: Wukong.DocumentProto.BlendMode
    readonly emojiCodePoints?: number[]
}

// TODO(lisw) 待替换成 Wukong.DocumentProto.IPaint
export type Paint = SolidPaint | GradientPaint | ImagePaint | IEmojiPaint

export enum GuideAxis {
    X,
    Y,
}

export interface Guide {
    readonly axis: GuideAxis
    readonly offset: number
}

export interface LayoutGrid {}

export enum WindingRule {
    Nonzero,
    Evenodd,
    None,
}

export type ExportSettings = Wukong.DocumentProto.IExportSettings

export interface VectorVertex {
    readonly x: number
    readonly y: number
    readonly strokeCap?: Wukong.DocumentProto.StrokeCap
    readonly strokeJoin?: Wukong.DocumentProto.StrokeJoin
    readonly cornerRadius?: number
    readonly handleMirroring?: HandleMirroring
}

export interface VectorSegment {
    readonly start: number
    readonly end: number
    readonly tangentStart?: Vector
    readonly tangentEnd?: Vector
}

export interface VectorNetworkLoop {
    readonly segmentIndices: ReadonlyArray<number>
}

export interface VectorRegion {
    readonly windingRule: WindingRule
    readonly loops: ReadonlyArray<VectorNetworkLoop>
}

export interface VectorNetwork {
    readonly vertices: ReadonlyArray<VectorVertex>
    readonly segments: ReadonlyArray<VectorSegment>
    readonly regions?: ReadonlyArray<VectorRegion>
}

export interface VectorPath {
    readonly windingRule: WindingRule
    readonly data: string
}

export interface VectorVertexIndex {
    vertexIndex: number
}

export interface VectorTangentIndex {
    segmentIndex: number
    isStart: boolean
}

export enum NumberUnit {
    Pixels,
    Percent,
    Auto,
}

export enum ConstraintUnit {
    Width,
    Height,
    Scale,
}

export interface LetterSpacing {
    readonly value: number
    readonly unit: NumberUnit
}

export interface LineHeight {
    readonly value: number
    readonly unit: NumberUnit
}

export enum HyperlinkTargetType {
    Url,
    Node,
}

export interface HyperlinkTarget {
    readonly type: HyperlinkTargetType
    readonly value: string
}

export enum TextListOptionsType {
    Ordered,
    Unordered,
    None,
}

export interface TextListOptions {
    readonly type: TextListOptionsType
}

export interface Font {
    readonly fontName: FontName
}

export interface FontVariation {
    readonly axisTag: number
    readonly axisName: string
    readonly value: number
}

export interface StyledTextSegment {
    readonly characters: string
    readonly start: number
    readonly end: number
    readonly fontSize: number
    readonly fontName: FontName
    readonly textDecoration: TextDecoration
    readonly textCase: TextCase
    readonly lineHeight: LineHeight
    readonly letterSpacing: LetterSpacing
    readonly fills: ReadonlyArray<Paint>
    readonly textStyleId?: string
    readonly fillStyleId?: string
    readonly textStyleEditedProps?: number[] // 调试用
    readonly fontVariations?: ReadonlyArray<FontVariation>
}

export enum PosType {
    BeforeChar,
    AfterChar,
}

export interface TextRange {
    readonly nodeId: NodeId
    readonly start: number
    readonly end: number
    readonly startPosType: number
    readonly endPosType: number
}

export enum OverflowDirection {
    None,
    Horizontal,
    Vertical,
    Both,
}

export enum PublishStatus {
    Unpublished,
    Current,
    Changed,
}

interface BaseNodeMixin {
    readonly id: NodeId
    readonly name: string
    // parentId: NodeId | null
    // orderIndex: Uint8Array
    readonly copiedFromId: string | null
    readonly parentInfo: ParentInfo | null
    readonly editedProps: number[] | null
    getParentId: () => NodeId | null
    getParent: () => ContainerNode | null
    getAbsoluteTransform: () => Transform
    getAbsoluteRect: () => Rect | null
}

export interface ParentInfo {
    readonly parentId: NodeId
    readonly orderIndex: Uint8Array
}

interface ContainerNodeMixin {
    readonly expanded: boolean
    getChildren: () => ReadonlyArray<BaseNode>
    append: (child: PageNode | SceneNode, insertPosition?: number) => void
}

interface SceneNodeMixin {
    readonly visible: boolean
    readonly locked: boolean
}

interface ConstraintMixin {
    readonly constraints?: Constraints
}

import StrokeCap = Wukong.DocumentProto.StrokeCap
import StrokeJoin = Wukong.DocumentProto.StrokeJoin
import StrokeAlign = Wukong.DocumentProto.StrokeAlign

export { StrokeAlign, StrokeCap, StrokeJoin }

export enum HandleMirroring {
    None,
    Angle,
    AngleAndLength,
}

export type Mixed = null

export interface LayoutMixin {
    readonly relativeTransform: Transform
    readonly width: number
    readonly height: number
    readonly constrainProportions?: boolean
    readonly stackChildAlignSelf?: StackCounterAlign
    readonly stackChildPrimaryGrow?: number
    readonly stackPositioning?: StackPositioning
}

interface BlendMixin {
    readonly opacity: number
    readonly blendMode: Wukong.DocumentProto.BlendMode
    readonly mask: boolean
    readonly maskOutline: boolean
    readonly effects: ReadonlyArray<Effect>
    readonly effectStyleId?: string
}

interface ContainerMixin {}

interface LineStrokeCapsMixin {
    readonly startCap: Wukong.DocumentProto.StrokeCap
    readonly endCap: Wukong.DocumentProto.StrokeCap
}

interface MinimalStrokesMixin {
    readonly strokes: ReadonlyArray<Paint>
    readonly strokeStyleId?: StyleId
    readonly strokeWeight: number
    readonly strokeJoin: Wukong.DocumentProto.StrokeJoin | Mixed
    readonly strokeAlign: Wukong.DocumentProto.StrokeAlign
    readonly strokeMiterAngle: number
    readonly dashPattern: ReadonlyArray<number>
    readonly dashCap: Wukong.DocumentProto.StrokeCap
    readonly borderStrokeWeightsIndependent?: boolean
}

export interface RectangleBorderWeightMixin {
    /** PartialNode borderTopWeight */
    readonly borderTopWeight?: number | null

    /** PartialNode borderRightWeight */
    readonly borderRightWeight?: number | null

    /** PartialNode borderBottomWeight */
    readonly borderBottomWeight?: number | null

    /** PartialNode borderLeftWeight */
    readonly borderLeftWeight?: number | null
}

interface MinimalFillsMixin {
    readonly fills: ReadonlyArray<Paint> | Mixed
    readonly fillStyleId?: StyleId
}

interface CornerMixin {
    readonly cornerRadius: number | Mixed
    readonly cornerSmoothing: number
}

interface RectangleCornerMixin {
    readonly topLeftRadius: number
    readonly topRightRadius: number
    readonly bottomLeftRadius: number
    readonly bottomRightRadius: number
}

interface IndependentCornersMixin {
    readonly independentCorners: boolean
}

interface ExportMixin {
    readonly exportSettings: ReadonlyArray<ExportSettings>

    exportAsync: (settings?: ExportSettings) => Promise<Uint8Array>
}

interface FramePrototypingMixin {
    readonly overflowDirection: OverflowDirection
    readonly numberOfFixedChildren: number
}

export interface DocumentationLink {
    readonly uri: string
}

interface DefaultShapeMixin
    extends BaseNodeMixin,
        SceneNodeMixin,
        BlendMixin,
        LayoutMixin,
        MinimalFillsMixin,
        MinimalStrokesMixin,
        ExportMixin {
    aiPoweredReason: number
}

export enum StackPositioning {
    Auto,
    Absolute,
}

export enum StackMode {
    None,
    Horizontal,
    Vertical,
}

export enum StackAlign {
    Min,
    Center,
    Max,
    Baseline,
}

export enum StackJustify {
    Min,
    Center,
    Max,
    SpaceEvently,
}

export enum StackCounterAlign {
    Min,
    Center,
    Max,
    Stretch,
    Auto,
    Baseline,
}

export enum StackSize {
    Fixed,
    ResizeToFit,
    ResizeToFitWithImplicitSize,
}

interface BaseFrameMixin
    extends BaseNodeMixin,
        SceneNodeMixin,
        ContainerMixin,
        CornerMixin,
        RectangleCornerMixin,
        IndependentCornersMixin,
        BlendMixin,
        ConstraintMixin,
        LayoutMixin,
        ExportMixin {
    readonly stackMode: StackMode
    readonly stackPrimarySizing: StackSize // applicable only if stackMode != "NONE"
    readonly stackCounterSizing: StackSize // applicable only if stackMode != "NONE"
    readonly stackPrimaryAlignItems: StackJustify // applicable only if stackMode != "NONE"
    readonly stackCounterAlignItems: StackAlign // applicable only if stackMode != "NONE"
    readonly stackHorizontalPadding: number // applicable only if stackMode != "NONE"
    readonly stackPaddingRight: number // applicable only if stackMode != "NONE"
    readonly stackVerticalPadding: number // applicable only if stackMode != "NONE"
    readonly stackPaddingBottom: number // applicable only if stackMode != "NONE"
    readonly stackSpacing: number // applicable only if stackMode != "NONE"
    readonly stackReverseZIndex: boolean // applicable only if stackMode != "NONE"
    readonly layoutGrids: ReadonlyArray<LayoutGrid>
    readonly gridStyleId: string
    readonly clipsContent: boolean
    readonly guides: ReadonlyArray<Guide>
    readonly childrenIds: string
}

interface DefaultFrameMixin extends BaseFrameMixin, FramePrototypingMixin, ContainerNodeMixin {}

interface PublishMixin {
    readonly publishFile?: string
    readonly publishId?: string
    readonly fromFig?: boolean
}

export enum InsertCharacterPosition {
    Before,
    After,
}

export interface UndoStatus {
    readonly undoable: boolean
    readonly redoable: boolean
}

export type AttrPanelStyleEditorState = Omit<Wukong.DocumentProto.AttrPanelStyleEditorState, 'toJSON'>

/**
 * document 与服务端的同步状态
 */
export enum SyncStatus {
    UpToDate,
    OutOfDate,
    Syncing,
}

/**
 * 本地操作 indexDB 存储状态
 */
export enum RepositoryStatus {
    Unknown,
    Null,
    NonNull,
}

export enum BoundsControlElementType {
    None,
    Vertex,
    Segment,
    Rotation,
    Target,
}

export interface EditingTextNodeState {
    readonly nodeId: NodeId
    readonly cursorX: number
    readonly cursorY: number
    readonly textStyle: TextStyle
    readonly blur?: boolean
}

export interface HandToolDraggingState {
    readonly startOffsetX: number
    readonly startOffsetY: number
    readonly startViewportX: number
    readonly startViewportY: number
}

export interface EditingVectorState {
    readonly nodeId: string
    readonly nodeType: NodeType
    readonly selectedVertexIndices: number[]
    readonly selectedSegmentIndices: number[]
    readonly selectedTangentIndices: number[]
    readonly selectedRegionIndices: number[]
    readonly activeTangentIndices: number[]
    readonly selectableTangentIndices: number[]
    readonly isSelectedPendingTangent: boolean
}

export enum EditingPaintType {
    Fill = Wukong.DocumentProto.EditingPaintType.EDITING_PAINT_TYPE_FILL,
    Stroke = Wukong.DocumentProto.EditingPaintType.EDITING_PAINT_TYPE_STROKE,
}

export interface EditingPaint {
    readonly nodeIds: ReadonlyArray<NodeId>
    readonly type: EditingPaintType | null
    readonly paintIndex: number | null
    readonly scaleMode: number | null
}

export interface PaintPosition {
    readonly type: EditingPaintType
    readonly index: number
}

import PopupStateType = Wukong.DocumentProto.PopupStateType
type PopupState = Omit<Wukong.DocumentProto.PopupState, 'toJSON'>

export { PopupStateType }
export type { PopupState }

export interface PlacingImageInfo {
    readonly imageName: string
    readonly imageHash: string
}

export interface PlacingImageState {
    readonly imageInfos: ReadonlyArray<PlacingImageInfo>
}

import ImageState = Wukong.DocumentProto.ImageState
import ImageStates = Wukong.DocumentProto.ImageStates
import { ComponentId } from '../../kernel/interface/component-style'
export { ImageState, ImageStates }

export interface EditingNameState {
    readonly nodeIds: ReadonlyArray<NodeId>
}

export enum FontWeight {
    InvisibleWeight = Wukong.DocumentProto.FontWeight.INVISIBLE_WEIGHT,
    ThinWeight = Wukong.DocumentProto.FontWeight.THIN_WEIGHT,
    ExtraLightWeight = Wukong.DocumentProto.FontWeight.EXTRA_LIGHT_WEIGHT,
    LightWeight = Wukong.DocumentProto.FontWeight.LIGHT_WEIGHT,
    NormalWeight = Wukong.DocumentProto.FontWeight.NORMAL_WEIGHT,
    MediumWeight = Wukong.DocumentProto.FontWeight.MEDIUM_WEIGHT,
    SemiBoldWeight = Wukong.DocumentProto.FontWeight.SEMI_BOLD_WEIGHT,
    BoldWeight = Wukong.DocumentProto.FontWeight.BOLD_WEIGHT,
    ExtraBoldWeight = Wukong.DocumentProto.FontWeight.EXTRA_BOLD_WEIGHT,
    BlackWeight = Wukong.DocumentProto.FontWeight.BLACK_WEIGHT,
    ExtraBlackWeight = Wukong.DocumentProto.FontWeight.EXTRA_BLACK_WEIGHT,
}

export enum FontWidth {
    InvisibleWidth = Wukong.DocumentProto.FontWidth.INVISIBLE_WIDTH,
    UltraCondensedWidth = Wukong.DocumentProto.FontWidth.ULTRA_CONDENSED_WIDTH,
    ExtraCondensedWidth = Wukong.DocumentProto.FontWidth.EXTRA_CONDENSED_WIDTH,
    CondensedWidth = Wukong.DocumentProto.FontWidth.CONDENSED_WIDTH,
    SemiCondensedWidth = Wukong.DocumentProto.FontWidth.SEMI_CONDENSED_WIDTH,
    NormalWidth = Wukong.DocumentProto.FontWidth.NORMAL_WIDTH,
    SemiExpandedWidth = Wukong.DocumentProto.FontWidth.SEMI_EXPANDED_WIDTH,
    ExpandedWidth = Wukong.DocumentProto.FontWidth.EXPANDED_WIDTH,
    ExtraExpandedWidth = Wukong.DocumentProto.FontWidth.EXTRA_EXPANDED_WIDTH,
    UltraExpandedWidth = Wukong.DocumentProto.FontWidth.ULTRA_EXPANDED_WIDTH,
}

export enum FontSlant {
    UprightSlant = Wukong.DocumentProto.FontSlant.UPRIGHT_SLANT,
    ItalicSlant = Wukong.DocumentProto.FontSlant.ITALIC_SLANT,
    ObliqueSlant = Wukong.DocumentProto.FontSlant.OBLIQUE_SLANT,
}

export interface FontInfo {
    readonly family: string
    readonly localizedFamily: string
    readonly styles: FontName[]
}

////////////////////////////////////////////////////////////////////////////////
// Nodes

export interface DocumentNode extends BaseNodeMixin, ContainerNodeMixin {
    readonly type: NodeType.Document
    // parentId: null
    readonly currentPageId: NodeId | null
    readonly editorMode: EditorMode
    readonly quickSwitchToHandTool: boolean
    readonly mouseDownWorldPosition: Vector | null
    readonly mouseWorldPosition: Vector | null
    readonly mouseInDragging: boolean
    readonly mouseDown: boolean
    readonly rotationInDragging: number
    readonly insertNodeState:
        | {
              parentNodeId: NodeId
              startPosition: Vector
              currentNodeId: NodeId | undefined
              startRelativeTransform: Transform | undefined
              startInverseCameraTransform?: Transform
          }
        | undefined
    readonly editingTextNodeState: EditingTextNodeState | undefined
    readonly handToolDraggingState: HandToolDraggingState | null
    readonly editingVectorState: EditingVectorState | undefined
    readonly editingPaint: EditingPaint | undefined
    readonly placingImageState?: PlacingImageState
    readonly imageStates: ImageStates | undefined
    readonly fullscreen: boolean // 全屏模式
    readonly showSidebar: boolean // 左边栏
    readonly showRuler: boolean // 标尺
    readonly showMultiplayerCursor: boolean // 多人光标
    readonly showComment: boolean // 评论
    readonly thumbnailId?: string
    readonly enablePixelGrid: boolean // 像素网格
    readonly enableLayoutGrid: boolean // 布局网格
    readonly popupState?: PopupState
    readonly availableFonts: FontInfo[] // 可用字体
    readonly snapToPixel: boolean
    readonly rightClickState: Required<Wukong.DocumentProto.IRightClickState> | null
    readonly dragMoveMode: Wukong.DocumentProto.DragMoveMode
    readonly syncStatus: SyncStatus
    readonly syncProgress: number
    readonly editingNameState: EditingNameState
    readonly selectedGradientColorStopIndex: number
    readonly shouldActiveVariantPropsInput: boolean
    readonly attrPanelStyleEditorState: AttrPanelStyleEditorState | null
    readonly debouncedMovingBoundsPositionHold: Wukong.DocumentProto.DebouncedMovingBoundsPositionHold
    readonly aiPoweredNodeId: string | null
}

export interface PageNode extends BaseNodeMixin, ContainerNodeMixin {
    readonly type: NodeType.Page
    readonly backgrounds: ReadonlyArray<Paint>

    readonly selection: ReadonlyArray<NodeId>
    readonly selectionRect: Rect | null
    readonly selectedTextRange: TextRange | null
    readonly viewport: Viewport | null
    readonly hoveredNodeId: string | null
    readonly hoveredElId: string | null
    readonly lastCreatedNodeId: boolean | null
    readonly targetFrameId: string | null
    readonly currentViewingFrameId: string | null
    readonly guides: ReadonlyArray<Guide>
    readonly selectedGuideIndex: number
    readonly recommendAutoLayout?: boolean
}

export interface FrameNode
    extends DefaultFrameMixin,
        MinimalFillsMixin,
        MinimalStrokesMixin,
        RectangleBorderWeightMixin {
    readonly type: NodeType.Frame
}

export interface GroupNode
    extends BaseNodeMixin,
        SceneNodeMixin,
        ContainerMixin,
        BlendMixin,
        LayoutMixin,
        ConstraintMixin,
        ContainerNodeMixin,
        ExportMixin {
    readonly type: NodeType.Group
}

export interface RectangleNode
    extends DefaultShapeMixin,
        ConstraintMixin,
        CornerMixin,
        RectangleCornerMixin,
        IndependentCornersMixin,
        RectangleBorderWeightMixin {
    readonly type: NodeType.Rectangle
    readonly imageName: string
}

export interface LineNode extends DefaultShapeMixin, ConstraintMixin, LineStrokeCapsMixin {
    readonly type: NodeType.Line
}

export interface EllipseNode extends DefaultShapeMixin, ConstraintMixin, CornerMixin {
    readonly type: NodeType.Ellipse
    readonly startingAngle: number
    readonly endingAngle: number
    readonly innerRadius: number
}

export interface PolygonNode extends DefaultShapeMixin, ConstraintMixin, CornerMixin {
    readonly type: NodeType.Polygon
    readonly pointCount: number
}

export interface StarNode extends DefaultShapeMixin, ConstraintMixin, CornerMixin {
    readonly type: NodeType.Star
    readonly pointCount: number
    readonly innerRadius: number
}

export interface VectorNode extends DefaultShapeMixin, ConstraintMixin, CornerMixin {
    readonly type: NodeType.Vector
    readonly vectorNetwork: VectorNetwork
    readonly handleMirroring: HandleMirroring | Mixed
}

export enum TextAlignHorizontal {
    Left,
    Center,
    Right,
    Justified,
}

export enum TextAlignVertical {
    Top,
    Center,
    Bottom,
}

export enum TextAutoResize {
    None,
    WidthAndHeight,
    Height,
}

export interface TextNode extends DefaultShapeMixin, ConstraintMixin {
    readonly type: NodeType.Text
    readonly hasMissingFont: boolean
    readonly textAlignHorizontal: TextAlignHorizontal
    readonly textAlignVertical: TextAlignVertical
    readonly textAutoResize: TextAutoResize
    readonly paragraphIndent: number
    readonly paragraphSpacing: number
    readonly autoRename: boolean
    readonly characters: string

    readonly fontSize: number
    readonly fontName: FontName
    readonly lineHeight: LineHeight
    readonly letterSpacing: LetterSpacing
    readonly textDecoration: TextDecoration
    readonly textCase: TextCase
    readonly textStyleId?: string
    readonly fillStyleId?: string

    readonly styledTextSegments: StyledTextSegment[]
}

// 改成继承 FrameNode
export interface ComponentSetNode
    extends DefaultFrameMixin,
        MinimalFillsMixin,
        MinimalStrokesMixin,
        PublishMixin,
        RectangleBorderWeightMixin {
    readonly type: NodeType.ComponentSet

    readonly description: string | null
    readonly key: string | null
    readonly contentHash: string | null
    readonly publishHidden: boolean | null
}

export interface ComponentNode
    extends DefaultFrameMixin,
        MinimalFillsMixin,
        MinimalStrokesMixin,
        PublishMixin,
        RectangleBorderWeightMixin {
    readonly type: NodeType.Component

    readonly variantProperties: { [property: string]: string } | null
    readonly description: string | null
    readonly key: ComponentId | null
    readonly contentHash: string | null
    readonly publishHidden: boolean | null
}

export interface InstanceNode
    extends DefaultFrameMixin,
        MinimalFillsMixin,
        MinimalStrokesMixin,
        RectangleBorderWeightMixin {
    readonly type: NodeType.Instance
    readonly mainComponentId: NodeId
    readonly scaleFactor: number
    readonly sizeOverride: Wukong.DocumentProto.SizeOverride

    readonly variantProperties: { [property: string]: string } | null
}

export interface PaintStyleNode extends BaseNodeMixin, PublishMixin {
    readonly type: NodeType.PaintStyle
    readonly paints: ReadonlyArray<Paint>
    readonly description: string | null
    readonly key: string | null
    readonly contentHash: string | null
    readonly publishHidden: boolean | null
    readonly visible: boolean | null
}

export interface EffectStyleNode extends BaseNodeMixin, PublishMixin {
    readonly type: NodeType.EffectStyle
    readonly effects: ReadonlyArray<Effect>
    readonly description: string | null
    readonly key: string | null
    readonly contentHash: string | null
    readonly publishHidden: boolean | null
    readonly visible: boolean | null
}

export interface LayoutGridStyleNode extends BaseNodeMixin, PublishMixin {
    readonly type: NodeType.LayoutGridStyle
    readonly layoutGrids: ReadonlyArray<Wukong.DocumentProto.ILayoutGrid>
    readonly description: string | null
    readonly key: string | null
    readonly contentHash: string | null
    readonly publishHidden: boolean | null
    readonly visible: boolean | null
}

export interface ITextStyle {
    readonly fontSize: number
    readonly fontName: FontName
    readonly textDecoration: TextDecoration
    readonly lineHeight: LineHeight
    readonly letterSpacing: LetterSpacing
    readonly fills?: ReadonlyArray<Paint>
    readonly textStyleId?: string
    readonly fillStyleId?: string
    readonly textCase?: TextCase
    readonly fontVariations?: ReadonlyArray<FontVariation>
    readonly detachOpticalSizeFromFontSize?: boolean
    readonly missFontInfo?: Wukong.DocumentProto.IVMissFontInfo
}

export interface TextStyleNode extends BaseNodeMixin, PublishMixin {
    readonly type: NodeType.TextStyle
    readonly description: string | null
    readonly key: string | null
    readonly contentHash: string | null
    readonly publishHidden: boolean | null
    readonly paragraphIndent: number
    readonly paragraphSpacing: number
    readonly visible: boolean | null

    readonly fontSize: number
    readonly fontName: FontName
    readonly textDecoration: TextDecoration
    readonly lineHeight: LineHeight
    readonly letterSpacing: LetterSpacing
    readonly fills?: ReadonlyArray<Paint>
    readonly textStyleId?: string
    readonly fillStyleId?: string
    readonly fontVariations?: ReadonlyArray<FontVariation>
    readonly detachOpticalSizeFromFontSize?: boolean
    readonly missFontInfo?: Wukong.DocumentProto.VMissFontInfo
}

export interface StyleContainerNode extends BaseNodeMixin, ContainerMixin, ContainerNodeMixin {
    readonly type: NodeType.StyleContainer
}

export enum BooleanOperation {
    Union,
    Intersect,
    Subtract,
    Exclude,
}

export interface BooleanOperationNode extends DefaultShapeMixin, ConstraintMixin, CornerMixin, ContainerNodeMixin {
    readonly type: NodeType.BooleanOperation
    readonly booleanOperation: BooleanOperation
}

export type BaseNode = DocumentNode | PageNode | SceneNode | StyleNode

export type StyleNode = PaintStyleNode | EffectStyleNode | TextStyleNode | LayoutGridStyleNode | StyleContainerNode

export type SceneNode =
    | FrameNode
    | GroupNode
    | BooleanOperationNode
    | VectorNode
    | StarNode
    | LineNode
    | EllipseNode
    | PolygonNode
    | RectangleNode
    | TextNode
    | ComponentSetNode
    | ComponentNode
    | InstanceNode

// 这里 container 表示「 有 children 」的 node
export type ContainerNode =
    | DocumentNode
    | PageNode
    | FrameNode
    | GroupNode
    | BooleanOperationNode
    | ComponentNode
    | ComponentSetNode
    | InstanceNode
    | StyleContainerNode
export type ContainerNodeOnPage = Exclude<Exclude<ContainerNode, DocumentNode>, StyleContainerNode>
export type ContainerSceneNode = Exclude<ContainerNodeOnPage, PageNode>
export type ContainerComponentNode = ComponentNode | ComponentSetNode | InstanceNode
export type ContainerConstraints = Exclude<
    ContainerNode,
    DocumentNode | PageNode | GroupNode | BooleanOperationNode | StyleContainerNode
>

export interface NodeTypeMap {
    [NodeType.Vector]: VectorNode
    [NodeType.Document]: DocumentNode
    [NodeType.Rectangle]: RectangleNode
    [NodeType.Star]: StarNode
    [NodeType.Polygon]: PolygonNode
    [NodeType.Line]: LineNode
    [NodeType.Ellipse]: EllipseNode
    [NodeType.Page]: PageNode
    [NodeType.Document]: DocumentNode
    [NodeType.Frame]: FrameNode
    [NodeType.Group]: GroupNode
    [NodeType.BooleanOperation]: BooleanOperationNode
    [NodeType.Text]: TextNode
    [NodeType.ComponentSet]: ComponentSetNode
    [NodeType.Component]: ComponentNode
    [NodeType.Instance]: InstanceNode
    [NodeType.PaintStyle]: PaintStyleNode
    [NodeType.EffectStyle]: EffectStyleNode
    [NodeType.TextStyle]: TextStyleNode
    [NodeType.LayoutGridStyle]: LayoutGridStyleNode
    [NodeType.StyleContainer]: StyleContainerNode
}

export type NodeCreatePartialProps<T extends NodeType> = Partial<Omit<NodeTypeMap[T], 'type'>>

export enum NodeType {
    Vector,
    Document,
    Rectangle,
    Star,
    Polygon,
    Line,
    Ellipse,
    Page,
    Frame,
    Group,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    BooleanOperation,
    Text,
    ComponentSet,
    Component,
    Instance,
    PaintStyle,
    TextStyle,
    EffectStyle,
    LayoutGridStyle,
    StyleContainer,
}

////////////////////////////////////////////////////////////////////////////////
// Styles
export enum StyleType {
    Paint,
    Text,
    Effect,
    Grid,
}

interface TextStyleMixin {
    readonly fontSize: number
    readonly textDecoration: TextDecoration
    readonly fontName: FontName
    readonly letterSpacing: LetterSpacing
    readonly lineHeight: LineHeight
    readonly textCase: TextCase
    readonly fills: ReadonlyArray<Paint>
    readonly fillStyleId?: string
}

export type TextStyle = TextStyleMixin & { readonly textStyleId?: string }
