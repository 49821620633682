import {
    AddExportImage,
    AddImagesInSVG,
    AddImageSourceCommand,
    DownloadAllFailedImageRetCommand,
    DownloadImageRetCommand,
    FreeBitmapCommand,
    GetLoadedImageBitmapBytesCommand,
    OnGetLoadedImageBitmapBytesCommand,
    OnPreparedUploadTextureCommand,
    PrepareUploadTextureCommand,
    PushBitmapCommand,
    RequestDownloadImageCommand,
    UpdateExportImageNum,
    UpdateImagePreivewHash,
    UploadBitmapToGPUCommand,
    Wukong,
} from '@wukong/bridge-proto'
import sha1 from 'js-sha1'
import { isNil } from 'lodash-es'
import { createSelectors, createStore, DelayTimer, sleep } from '../../../../util/src'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { transaction } from '../../../../util/src/abort-controller/traceable-transaction'
import { BehaviorEventEmitter } from '../../../../util/src/event-emitter/behavior-event-emitter'
import { environment, HttpPrefixKey } from '../../environment'
import {
    bitmapCompatToDecoded,
    ColorSpace,
    createImageBitmapCompat,
    decodeControlImageWithScaleDown,
    decompressBitmap,
    decompressBitmapSync,
    drawableToBytes,
    drawImageBitmapCompatToCanvas,
    getJpegFileDPI,
    getPNGFileDPI,
    ImageBitmapCompat,
    ImageDecodedBitmapHelpers,
    ImageDecodedBitmapType,
    ImageDpiInfo,
    ImageTotalDecompressedDecodedBitmap,
    kDefaultDPI,
    removeICCChunksAndGetMeta,
    TextureCompressionFormat,
    transcodeKtx2,
    transcodeKtx2Sync,
    tryGetCompressedImageAndDiscardGammaIfCompressed,
} from '../../image-lib'
import { getImageLibContext, toTextureCompression } from '../../image-lib/adapter'
import { EmBridge } from '../../kernel/bridge/em-bridge'
import { WkCLog } from '../../kernel/clog/wukong/instance'
import { mutateImagesObjectStoreV2 } from '../../kernel/db/resource-db'
import { debugLog } from '../../kernel/debug'
import { AWSImageProcessService } from '../../kernel/request/aws-image-process'
import { GetPrivateUploadAuthorization, getUploadHeader } from '../../kernel/request/upload'
import { Sentry } from '../../kernel/sentry'
import { CanvasStateType } from '../../kernel/service/canvas-types'
import { EditorService } from '../../kernel/service/editor-service'
import { featureSwitchManager } from '../../kernel/switch/core'
import { ServiceClass } from '../../kernel/util/service-class'
import { isSynergyStateOffline } from '../../kernel/util/synergy-state'
import { WK } from '../../window/wk-object'
import { EMOJI_PATH_PREFIX, IMAGE_PATH_PREFIX } from '../config/image-config'
import type { ExportImageProcess, OfflineImageProcess } from '../document-bridge/types'
import { WebSocketBridge } from '../synergy/web-socket-bridge'
import { BitmapUploader, UploadImageToGPUParams } from './bitmap-uploader'
import { CommandInvoker } from './command-invoker'
import { CommitType } from './commit-type'
import { ImagePixelsStore, isBitmapValid } from './image-pixels-store'

interface UploadImageDataErrorRusult {
    sucess: false
}

interface UploadImageDataSucessRusult {
    sucess: true
    imageInfo: Wukong.DocumentProto.ImageInfo
    hasCompressed: boolean
}

type UploadImageDataRusult = UploadImageDataErrorRusult | UploadImageDataSucessRusult

interface UploadData {
    ossUrl: string
    method: string
    contentType: string
    imageFileBytes: ArrayBuffer
}

const MAX_PREVIEW_SIZE = 512

const NO_FILE_FLAG = 'NoSuchKey'
const DECODE_ERROR_FLAG = 'DecodeError'

const toColorProfile = (colorSpace: ColorSpace): Wukong.DocumentProto.DocumentColorProfile => {
    return colorSpace === ColorSpace.DisplayP3
        ? Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILE_DISPLAY_P3
        : Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B
}

export function toUploadImageToGPUParams(arg: Wukong.DocumentProto.IArg_UploadBitmapToGPU): UploadImageToGPUParams {
    return {
        format: toTextureCompression(arg.format),
        chunkIndexLeft: arg.chunkIndexLeft,
        chunkIndexRight: arg.chunkIndexRight,
        resourcePixelsHandle: arg.resourcePixelsHandle,
        textureHandle: arg.textureHandle,
    }
}

export class ImageDownloadContext extends ServiceClass {
    public states = createSelectors(
        createStore<{
            offlineImageNumState: OfflineImageProcess
            exportImageNumState: ExportImageProcess
            blockStatusState: boolean
        }>(
            () => ({
                offlineImageNumState: {
                    succeeded: 0,
                    total: 0,
                },
                exportImageNumState: {
                    succeeded: 0,
                    total: 0,
                },
                blockStatusState: false,
            }),
            environment.isDev
        )
    )

    private _bitmapStore = new ImagePixelsStore({
        maxDropOriginThreshold: 1 << 30, // 1 GB
        minDropOriginThreshold: 200 << 20, // 200 MB
        decompressBitmap: async (data) => {
            return decompressBitmap(getImageLibContext(), data)
        },
        decompressBitmapSync: (data) => decompressBitmapSync(data),
        transcodeKtxSync: (data: Uint8Array, format: TextureCompressionFormat) => {
            return transcodeKtx2Sync(data, format)
        },
        transcodeKtx: async (data: Uint8Array, format: TextureCompressionFormat) => {
            return transcodeKtx2(getImageLibContext(), data, format)
        },
        log: (stats) => {
            WkCLog.throttleLog('WK_IMAGE_BITMAP_STORE', stats)
        },
    })
    private _bitmapUploader: BitmapUploader

    private uploadDataSet: Set<UploadData> = new Set()
    private postTimeout: Map<string, NodeJS.Timeout> = new Map()

    private downloadRets: Wukong.DocumentProto.DownloadImageResult[] = []
    private _deferredCallDownloadRetTimer: DelayTimer

    private _localGenerateds: Map<string, Promise<readonly [Wukong.DocumentProto.ImageInfo, Blob] | null>> = new Map()

    // 离线图片数量
    private offlineImageNum$$ = this.buildBehaviorSubject<OfflineImageProcess>({
        succeeded: 0,
        total: 0,
    })

    public onOfflineImageNumChangeWithSignal = (
        signal: TraceableAbortSignal,
        fn: (offlineNum: OfflineImageProcess) => void
    ) => {
        const { act } = transaction(signal)
        act('onOfflineImageNumChange', () => {
            const subscription = this.offlineImageNum$$.subscribe(fn)
            return () => subscription.unsubscribe()
        })
    }

    // 导出图片数量
    private exportImageNumEventEmitter = new BehaviorEventEmitter<ExportImageProcess>({
        succeeded: 0,
        total: 0,
    })

    public onExportImageNumChange = (signal: TraceableAbortSignal, fn: (exportNum: ExportImageProcess) => void) => {
        this.exportImageNumEventEmitter.onWithSignal(signal, fn)
    }

    constructor(
        protected override readonly bridge: EmBridge,
        private readonly commandInvoker: CommandInvoker,
        private readonly docId: string,
        // sandbox 中不会传入 webSocketBridge
        private readonly webSocketBridge: WebSocketBridge | undefined,
        private readonly ossImageMaxSize: number | null,
        signal: TraceableAbortSignal
    ) {
        super(bridge)
        // 当开启纹理压缩时需要打开
        // tryInitBasisTranscoder()

        this._bitmapUploader = new BitmapUploader(
            () => this.bridge.currentEditorService.getCanvasState(),
            this._bitmapStore,
            (msg) => WkCLog.throttleLog('WK_BITMAP_UPLOADER', { msg })
        )
        this.bindJsCall(UploadBitmapToGPUCommand, this.uploadImageToGPUSync.bind(this))
        this.bindJsCall(RequestDownloadImageCommand, (arg) => {
            this.downloadImageFromServer(arg)
        })
        this.bindJsCall(AddImagesInSVG, this.handleImagesInSVG.bind(this))
        this.bindJsCall(AddExportImage, this.addExportImageNum.bind(this))
        this.bindJsCall(UpdateExportImageNum, this.updateExportImageNum.bind(this))
        this.bindJsCall(PushBitmapCommand, (arg) => {
            const chunk = new Uint8Array(arg.data)
            const bitmap: ImageTotalDecompressedDecodedBitmap = {
                type: ImageDecodedBitmapType.Bytes,
                width: arg.width,
                height: arg.height,
                data: [
                    {
                        y: 0,
                        width: arg.width,
                        height: arg.height,
                        chunk,
                        compressed: null,
                    },
                ],
            }
            const handle = this._bitmapStore.addBitmap(arg.imageHash, bitmap)
            const ret: Wukong.DocumentProto.IRet_PushBitmap = {
                resourcePixelsHandle: handle,
                chunkBytesList: [chunk.byteLength],
            }
            return ret
        })
        this.bindJsCall(FreeBitmapCommand, (arg) => {
            this._bitmapStore.removeBitmap(arg.value!)
            this._localGenerateds.delete(arg.value!)
        })
        this.bindJsCall(PrepareUploadTextureCommand, this.onPrepareUploadTexture.bind(this))

        this.bindJsCall(GetLoadedImageBitmapBytesCommand, this.getLoadedImageBitmapBytes.bind(this))

        if (this.webSocketBridge) {
            const onSynergyStateChange = (state: Wukong.DocumentProto.SynergyState) => {
                if (state !== Wukong.DocumentProto.SynergyState.SYNERGY_STATE_ONLINE) {
                    return
                }
                this.uploadAllOfflineImage()
                this.bridge.call(DownloadAllFailedImageRetCommand)

                this.retryUploadImageToServer()
            }
            this.webSocketBridge.onSynergyStateChangeWithSignal(signal, onSynergyStateChange)
        }

        this._deferredCallDownloadRetTimer = new DelayTimer(16, () => {
            // stats
            this.bridge.call(
                DownloadImageRetCommand,
                Wukong.DocumentProto.DownloadImageBatchResult.create({ results: this.downloadRets })
            )
            this.downloadRets = []
        })

        WK.printBitmapDiagnosis = (id: string) => {
            this.printBitmapDiagnosis(id)
        }
    }

    public init = () => {}

    public override destroy() {
        super.destroy()
        this._deferredCallDownloadRetTimer.destroy()
        this._bitmapUploader.destroy()
        this._bitmapStore.destroy()
        this._localGenerateds.clear()
        delete WK.printBitmapDiagnosis
    }

    public printBitmapDiagnosis(imageHash: string) {
        this._bitmapStore.printDiagnosisByImageHash(imageHash, (...args) => {
            console.info(...args)
        })
    }

    private async getIsOffline() {
        // sandbox 中不会传入 webSocketBridge
        if (!this.webSocketBridge) {
            return null
        }

        const isOffline = isSynergyStateOffline(
            await this.webSocketBridge.getCurrentSynergyState(Wukong.DocumentProto.SynergyState.SYNERGY_STATE_OFFLINE)
        )
        return isOffline
    }

    private async saveImageToPersistent(blob: Blob, name: string, overrideImageHash: string | null) {
        const isOffline = await this.getIsOffline()
        if (isOffline === null) {
            return null
        }

        if (isOffline) {
            return await this.saveImageOffline(blob, name, overrideImageHash)
        } else {
            return await this.uploadImageToServer(blob, name, overrideImageHash)
        }
    }

    /**
     * @brief 添加一个图片资源
     *  1. 将图片上传到服务器
     *  2. 在 wasm 中添加该图片资源
     *  3. 将图片上传到 GPU(AddImageSourceCommand 会调用 uploadImageToGPUSync)
     */
    public async addImageSource(blob: Blob, name: string): Promise<UploadImageDataRusult> {
        const imageInfoWrap = await this.saveImageToPersistent(blob, name, null)
        if (!imageInfoWrap) {
            return new Promise<UploadImageDataRusult>(() => {})
        }

        if (!this._bitmapStore.has(imageInfoWrap.imageInfo.resourcePixelsHandle)) {
            return { sucess: false }
        }

        let dpiInfo: ImageDpiInfo = { xDPI: kDefaultDPI, yDPI: kDefaultDPI }
        if (blob.type === 'image/png') {
            dpiInfo = await getPNGFileDPI(blob)
        } else if (blob.type === 'image/jpeg') {
            dpiInfo = await getJpegFileDPI(blob)
        }
        imageInfoWrap.imageInfo.xDPI = dpiInfo.xDPI
        imageInfoWrap.imageInfo.yDPI = dpiInfo.yDPI

        this.bridge.call(AddImageSourceCommand, imageInfoWrap.imageInfo)
        return {
            sucess: true,
            imageInfo: imageInfoWrap.imageInfo,
            hasCompressed: imageInfoWrap.hasCompressed,
        }
    }

    public addImageSourceForPlugin(imageData: Uint8Array): string {
        const blob = new Blob([imageData], { type: 'image/png' })

        const base64 = Buffer.from(imageData).toString('base64')

        const hash = sha1(base64) + Math.random().toString().slice(8) + '.png'

        const promise = this.saveImageToPersistent(blob, 'plugin-image', hash).then((v) => {
            if (v?.imageInfo) {
                return [v.imageInfo, blob] as const
            }
            return null
        })

        this._localGenerateds.set(hash, promise)

        return hash
    }

    public async uploadAllOfflineImage() {
        try {
            const list = await mutateImagesObjectStoreV2(async (store) => {
                const ret: Array<[any, string[]]> = []
                const keys = await store.getAllKeys()
                for (const key of keys) {
                    const data = await store.get(key)
                    ret.push([data, key as string[]])
                }
                return ret
            })
            for (const [data, key] of list) {
                this.uploadOfflineImage(data.data, key as string[])
            }
        } catch (e) {
            Sentry.captureException(e)
        }
    }

    private deferredCallDownloadRet(ret: Wukong.DocumentProto.DownloadImageResult) {
        this.downloadRets.push(ret)
        this._deferredCallDownloadRetTimer.ensureStarted()
    }

    private async uploadOfflineImage(data: { type: string; bytes: Uint8Array }, storeKey: string[]) {
        let old = this.offlineImageNum$$.getValue()
        this.offlineImageNum$$.next({
            succeeded: old.succeeded,
            total: old.total + 1,
        })
        try {
            const blob = new Blob([data.bytes])
            const type = data.type
            const fileName = storeKey[1]
            const { method, resourceId, contentType, ossUrl } = await new GetPrivateUploadAuthorization({
                format: type,
                fileName,
            }).start()
            const imageFileBytes = await blob.arrayBuffer()
            await this.postImageToServer({
                ossUrl,
                method,
                contentType,
                imageFileBytes,
            })
            const compressedImageBitmap = await this.createCompressedImageBitmap(blob)
            if (!compressedImageBitmap) {
                return null
            }
            const { image: imageBitmap, colorSpace, hasGamma } = compressedImageBitmap
            const resourcePixelsHandle = this._bitmapStore.addBitmap(resourceId, imageBitmap)

            const imageResult = Wukong.DocumentProto.DownloadImageResult.create({
                success: true,
                imageId: resourceId,
                imageWidth: imageBitmap.width || 0,
                imageHeight: imageBitmap.height || 0,
                baseUrl: environment.httpPrefixes[HttpPrefixKey.COMMON_API],
                colorProfile: toColorProfile(colorSpace),
                resourcePixelsHandle,
                hasGamma,
                chunkBytesList: ImageDecodedBitmapHelpers.createChunkBytesList(imageBitmap),
                usingKtx: imageBitmap.type === ImageDecodedBitmapType.Ktx,
            })
            this.deferredCallDownloadRet(imageResult)
        } catch (e) {
            WkCLog.log(`上传离线图片失败. ${e}`)
        } finally {
            mutateImagesObjectStoreV2(async (store) => {
                await store.delete(storeKey)
            })
            old = this.offlineImageNum$$.getValue()
            this.offlineImageNum$$.next({
                succeeded: old.succeeded + 1,
                total: old.total,
            })
        }
    }

    // 监控server返回重复id
    private resourceIdWatcher = new Set()
    /**
     * @brief 上传预览图
     */
    private async uploadPreviewImage(
        image: {
            width: number
            height: number
        },
        file: File | Blob,
        format: string
    ) {
        const { previewWidth, previewHeight } = this.reduceImageSize(image.width, image.height)

        const {
            method: previewMethod,
            resourceId: previewResourceId,
            contentType: previewContentType,
            ossUrl,
        } = await new GetPrivateUploadAuthorization({
            format,
        }).start()

        if (this.resourceIdWatcher.has(previewResourceId)) {
            this.bridge.crash(new Error('duplicate resourceId from server'))
        } else if (this.resourceIdWatcher.size >= 10) {
            this.resourceIdWatcher.clear()
            this.resourceIdWatcher.add(previewResourceId)
        } else {
            this.resourceIdWatcher.add(previewResourceId)
        }

        const uploadAwaited = this.uploadPreviewImageInner(
            file,
            previewWidth,
            previewHeight,
            previewMethod,
            previewContentType,
            ossUrl
        )
        if (featureSwitchManager.isEnabled('js-image-post-await')) {
            await uploadAwaited
        }

        return previewResourceId
    }

    private async uploadPreviewImageInner(
        file: Blob | File,
        previewWidth: number,
        previewHeight: number,
        previewMethod: string,
        previewContentType: string,
        previewOssUrl: string
    ) {
        const previewImageFile = await tryGetCompressedImageAndDiscardGammaIfCompressed(
            getImageLibContext(),
            file,
            previewWidth,
            previewHeight
        )
        const previewImageFileBytes = await previewImageFile.file.arrayBuffer()
        const postAwaited = this.postImageToServer({
            ossUrl: previewOssUrl,
            method: previewMethod,
            contentType: previewContentType,
            imageFileBytes: previewImageFileBytes,
        })
        if (featureSwitchManager.isEnabled('js-image-post-await')) {
            await postAwaited
        }
    }

    private async loadImageObjectStoreData(imageId: string, isEmoji: boolean) {
        // emoji不是用户传的,不会在DB里,不需要查DB
        if (!isEmoji) {
            // 如果数据库连不上仍然要走在线加载的逻辑
            try {
                const storeKey = [this.docId, imageId.split('.')[0]]
                return await mutateImagesObjectStoreV2(async (store) => {
                    return await store.get(storeKey)
                })
            } catch {}
        }
        return undefined
    }

    private async generateImageURL(imageId: string, isEmoji: boolean) {
        // NOTE: 服务端有实现类似的图片下载逻辑，下面图片地址规则如果变化，需要通知 huangling
        if (isEmoji) {
            return EMOJI_PATH_PREFIX + imageId
        } else {
            let url = IMAGE_PATH_PREFIX + imageId
            // NOTE: 预览时通过oss缩放限制下载图片的尺寸，超过限制下载失败不展示
            if (this.ossImageMaxSize) {
                if (environment.isAbroad) {
                    url = await new AWSImageProcessService(`private/resource/image/${imageId}`, {
                        resize: {
                            width: this.ossImageMaxSize,
                            height: this.ossImageMaxSize,
                            fit: 'inside',
                            withoutEnlargement: true,
                        },
                    })
                        .start()
                        .then(({ url: newUrl }) => newUrl)
                } else {
                    url += `?x-oss-process=image/resize,l_${this.ossImageMaxSize}`
                }
            }
            return url
        }
    }

    private async generatePreviewImageAndUpdate(imageId: string, imageWidth: number, imageHeight: number, blob: Blob) {
        const pos = imageId.lastIndexOf('.')
        const format = pos < 0 ? 'jpg' : imageId.slice(pos + 1)
        let previewId = ''
        if ((imageWidth > MAX_PREVIEW_SIZE || imageHeight > MAX_PREVIEW_SIZE) && format) {
            previewId = await this.uploadPreviewImage(
                {
                    width: imageWidth,
                    height: imageHeight,
                },
                blob,
                format
            ).catch(() => {
                return ''
            })
        }
        this.bridge.call(
            UpdateImagePreivewHash,
            Wukong.DocumentProto.UpdatePreviewHashParam.create({
                imageHash: imageId,
                previewHash: previewId,
            })
        )
    }

    private async handleIfLocalGenerated(arg: Wukong.DocumentProto.IImageId) {
        const imageId = arg.imageId!
        if (!this._localGenerateds.has(imageId)) {
            return false
        }

        const generated = await this._localGenerateds.get(imageId)!
        if (generated) {
            const [imageInfo, blob] = generated

            if (arg.shouldGeneratePreview) {
                this.getIsOffline().then(async (isOffline) => {
                    if (isOffline !== null && !isOffline) {
                        await this.generatePreviewImageAndUpdate(
                            imageId,
                            imageInfo.imageWidth,
                            imageInfo.imageHeight,
                            blob
                        )
                    }
                })
            }

            this.deferredCallDownloadRet(
                Wukong.DocumentProto.DownloadImageResult.create({
                    success: true,
                    imageId: imageId,
                    imageWidth: imageInfo.imageWidth,
                    imageHeight: imageInfo.imageHeight,
                    baseUrl: environment.httpPrefixes[HttpPrefixKey.COMMON_API],
                    colorProfile: imageInfo.colorProfile,
                    resourcePixelsHandle: imageInfo.resourcePixelsHandle,
                    hasGamma: imageInfo.hasGamma,
                    chunkBytesList: imageInfo.chunkBytesList,
                    usingKtx: imageInfo.usingKtx,
                })
            )
        } else {
            this.deferredCallDownloadRet(
                Wukong.DocumentProto.DownloadImageResult.create({
                    success: false,
                    imageId: imageId,
                    imageWidth: 0,
                    imageHeight: 0,
                    errorType: Wukong.DocumentProto.DownloadErrorType.DOWNLOAD_ERROR_TYPE_DECODE,
                })
            )
        }
        this._localGenerateds.delete(imageId)
        return true
    }

    /**
     * @brief 从服务器下载图片
     */
    public async downloadImageFromServer(arg: Wukong.DocumentProto.IImageId) {
        // 记录调用下载时的 clientId，为了比对下载成功后的 clientId 是否一致
        const clientId = this.bridge.currentEditorService.clientId

        const imageId = arg.imageId

        if (!imageId) {
            return
        }

        const handledLocalGenerated = await this.handleIfLocalGenerated(arg)
        if (handledLocalGenerated) {
            return
        }

        const storeData = await this.loadImageObjectStoreData(imageId, arg.isEmoji!)

        try {
            if (!storeData) {
                const url: string = await this.generateImageURL(imageId, arg.isEmoji!)

                const response = await fetch(url)
                if (!response.ok) {
                    const text = await response.text()
                    if (text?.includes(NO_FILE_FLAG)) {
                        throw new Error(NO_FILE_FLAG)
                    }
                    throw new Error(`bad status code: ${response.status}`)
                }
                const blob = await response.blob()
                const compressedImageBitmap = await this.createCompressedImageBitmap(blob)
                if (!compressedImageBitmap) {
                    return null
                }
                const { image: imageBitmap, colorSpace, hasGamma } = compressedImageBitmap

                // 如果下载后图片的已经不在处于当前的 clientId，则不再执行上传后的回调
                // 切换 wasm 之间可能继续执行老的异步任务，但这些异步任务回调不应该在影响新的客户端
                if (clientId !== this.bridge.currentEditorService.clientId) {
                    return
                }
                if (this.isDestroy) {
                    return
                }

                if (!isBitmapValid(imageBitmap)) {
                    throw new Error('Invalid ImageBitmap')
                }

                const imageWidth = imageBitmap.width
                const imageHeight = imageBitmap.height

                if (arg.shouldGeneratePreview) {
                    await this.generatePreviewImageAndUpdate(imageId, imageWidth, imageHeight, blob)
                }
                const resourcePixelsHandle = this._bitmapStore.addBitmap(imageId, imageBitmap)

                const imageResult = Wukong.DocumentProto.DownloadImageResult.create({
                    success: true,
                    imageId: imageId,
                    imageWidth: imageWidth,
                    imageHeight: imageHeight,
                    baseUrl: environment.httpPrefixes[HttpPrefixKey.COMMON_API],
                    colorProfile: toColorProfile(colorSpace),
                    resourcePixelsHandle,
                    hasGamma,
                    chunkBytesList: ImageDecodedBitmapHelpers.createChunkBytesList(imageBitmap),
                    usingKtx: imageBitmap.type === ImageDecodedBitmapType.Ktx,
                })
                this.deferredCallDownloadRet(imageResult)
            } else {
                if (!this._bitmapStore.hasByImageHash(imageId)) {
                    const compressedImageBitmap = await this.createCompressedImageBitmap(
                        new Blob([storeData.data.bytes])
                    )
                    if (!compressedImageBitmap) {
                        return null
                    }
                    const { image, colorSpace, hasGamma } = compressedImageBitmap
                    const resourcePixelsHandle = this._bitmapStore.addBitmap(imageId, image)
                    const imageWidth = image.width
                    const imageHeight = image.height

                    const imageResult = Wukong.DocumentProto.DownloadImageResult.create({
                        success: true,
                        imageId: imageId,
                        imageWidth: imageWidth,
                        imageHeight: imageHeight,
                        baseUrl: environment.httpPrefixes[HttpPrefixKey.COMMON_API],
                        colorProfile: toColorProfile(colorSpace),
                        resourcePixelsHandle,
                        hasGamma,
                        chunkBytesList: ImageDecodedBitmapHelpers.createChunkBytesList(image),
                        usingKtx: image.type === ImageDecodedBitmapType.Ktx,
                    })
                    this.deferredCallDownloadRet(imageResult)
                }
            }
        } catch (e: any) {
            debugLog(e)

            const imageResult = Wukong.DocumentProto.DownloadImageResult.create({
                success: false,
                imageId: imageId,
                imageWidth: 0,
                imageHeight: 0,
                errorType: e
                    ? e.message === NO_FILE_FLAG
                        ? Wukong.DocumentProto.DownloadErrorType.DOWNLOAD_ERROR_TYPE_NO_FILE
                        : e.message === DECODE_ERROR_FLAG
                        ? Wukong.DocumentProto.DownloadErrorType.DOWNLOAD_ERROR_TYPE_DECODE
                        : Wukong.DocumentProto.DownloadErrorType.DOWNLOAD_ERROR_TYPE_NONE
                    : Wukong.DocumentProto.DownloadErrorType.DOWNLOAD_ERROR_TYPE_NONE,
            })
            this.deferredCallDownloadRet(imageResult)
        }
    }

    private addExportImageNum(proto: Wukong.DocumentProto.IExportImagesNumParams) {
        this.exportImageNumEventEmitter.next({
            succeeded: 0,
            total: proto.num || 0,
            isCanceled: false,
        })
    }

    private updateExportImageNum() {
        const old = this.exportImageNumEventEmitter.getValue()
        this.exportImageNumEventEmitter.next({
            succeeded: old.succeeded + 1,
            total: old.total,
            isCanceled: old.isCanceled,
        })
    }

    public cancelExportImage = () => {
        this.exportImageNumEventEmitter.next({
            succeeded: 0,
            total: 0,
            isCanceled: true,
        })
    }

    private async onPrepareUploadTexture(arg: Wukong.DocumentProto.IArg_PrepareUploadTexture) {
        // 保证异步
        await sleep(0)
        if (this.isDestroy) {
            return
        }

        try {
            await this._bitmapStore.prepareOriginBitmapsAsync(
                arg.items.map((item) => ({
                    chunkIndexLeft: item.chunkIndexLeft,
                    chunkIndexRight: item.chunkIndexRight,
                    imageHash: item.imageHash,
                    format: toTextureCompression(item.format),
                }))
            )
            this.bridge.call(OnPreparedUploadTextureCommand, {
                items: arg.items,
            })
        } catch (e) {
            this.bridge.call(OnPreparedUploadTextureCommand, {
                items: [],
            })
            const errMsg = typeof e === 'string' ? e : e instanceof Error ? e.message : 'unknown error'
            WkCLog.log(`[onPrepareUploadTexture] error occurs`, {
                errMsg,
            })
            throw e
        }
    }

    private async getLoadedImageBitmapBytes(arg: Wukong.DocumentProto.IArg_GetBitmapBytes) {
        // 保证异步
        await sleep(0)
        if (this.isDestroy) {
            return
        }

        const callbackOnFail = () => {
            if (this.isDestroy) {
                return
            }
            this.commandInvoker.invokeBridge(CommitType.Noop, OnGetLoadedImageBitmapBytesCommand, {
                callbackId: arg.callbackId,
                resourcePixelsHandle: arg.resourcePixelsHandle,
                success: false,
                buffer: new Uint8Array(),
            })
        }

        try {
            const item = this._bitmapStore.getDrawable(
                arg.resourcePixelsHandle,
                TextureCompressionFormat.None,
                null,
                null
            )
            if (!item) {
                callbackOnFail()
                return
            }
            const buffer = await drawableToBytes(getImageLibContext(), item)
            if (this.isDestroy) {
                return
            }
            if (!buffer) {
                callbackOnFail()
                return
            }
            this.commandInvoker.invokeBridge(CommitType.Noop, OnGetLoadedImageBitmapBytesCommand, {
                callbackId: arg.callbackId,
                resourcePixelsHandle: arg.resourcePixelsHandle,
                success: true,
                buffer,
            })
        } catch (err) {
            callbackOnFail()
        }
    }

    /**
     * @brief 将图片上传到 GPU
     */
    private uploadImageToGPUSync(arg: Wukong.DocumentProto.IArg_UploadBitmapToGPU) {
        this._bitmapUploader.uploadImageToGPUSync(toUploadImageToGPUParams(arg))
    }

    private handleImagesInSVG(arg: Wukong.DocumentProto.IArg_uploadImages) {
        // 注意需要同步
        const BASE64_IMAGE_REG = /^data:(image\/[\w]+);base64,/

        ;(arg.data ?? []).forEach((item) => {
            const { id, data, name: imageHash } = item
            if (isNil(id) || isNil(data) || !imageHash) {
                return
            }

            const base64Res = BASE64_IMAGE_REG.exec(data)
            if (!base64Res) {
                return
            }

            const encoded = data.slice(base64Res[0].length)
            const type = base64Res[1]
            const buffer = Buffer.from(encoded, 'base64')
            const blob = new Blob([buffer], {
                type,
            })

            const promise = this.saveImageToPersistent(blob, 'image', imageHash)
                .then((v) => {
                    if (v?.imageInfo) {
                        return [v.imageInfo, blob] as const
                    }
                    return null
                })
                .catch(() => null)
            this._localGenerateds.set(imageHash, promise)
        })
    }

    /**
     * @brief 离线存储图片
     */
    private async saveImageOffline(
        blob: Blob,
        name: string,
        overrideImageHash: string | null
    ): Promise<{
        imageInfo: Wukong.DocumentProto.ImageInfo
        hasCompressed: boolean
    } | null> {
        async function generateHash(imageFileBytes: ArrayBuffer) {
            const hashArray = await crypto.subtle.digest('SHA-1', imageFileBytes)
            const poorHash = Array.from(new Uint8Array(hashArray))
                .map((byte) => {
                    // 将哈希值转换为十六进制字符串
                    return byte.toString(16).padStart(2, '0')
                })
                .join('')
            const hash = poorHash + Math.random().toString().slice(8)
            return [poorHash, hash]
        }

        const imageFile = await tryGetCompressedImageAndDiscardGammaIfCompressed(getImageLibContext(), blob)

        const hasCompressed = imageFile.hasCompressed

        const imageFileBytes = await imageFile.file.arrayBuffer()

        const compressedImageBitmap = await this.createCompressedImageBitmap(imageFile.file)
        if (!compressedImageBitmap) {
            return null
        }
        const { image, colorSpace, hasGamma } = compressedImageBitmap

        const suffix = blob.type.split('/')[1]

        let poorHash = ''
        let hash = ''
        if (overrideImageHash) {
            ;[poorHash] = await generateHash(imageFileBytes)
            hash = overrideImageHash.replace(/\..+$/, '')
        } else {
            ;[poorHash, hash] = await generateHash(imageFileBytes)
        }

        mutateImagesObjectStoreV2(async (store) => {
            await store.put({
                documentId: this.docId,
                hash,
                data: {
                    type: suffix,
                    bytes: new Uint8Array(imageFileBytes),
                },
            })
        })

        if (!isBitmapValid(image)) {
            throw new Error('Invalid ImageBitmap')
        }

        const fileName = hash + '.' + suffix
        const resourcePixelsHandle = this._bitmapStore.addBitmap(fileName, image)

        let previewId = ''
        // 如果图片尺寸超标，生成并上传预览图
        if (image.width > MAX_PREVIEW_SIZE || image.height > MAX_PREVIEW_SIZE) {
            const { previewWidth, previewHeight } = this.reduceImageSize(image.width, image.height)
            const previewImageFile = await tryGetCompressedImageAndDiscardGammaIfCompressed(
                getImageLibContext(),
                imageFile.file,
                previewWidth,
                previewHeight
            )

            const previewImageFileBytes = await previewImageFile.file.arrayBuffer()
            const previewHash = poorHash + Math.random().toString().slice(8)
            mutateImagesObjectStoreV2(async (previewStore) => {
                await previewStore.put({
                    documentId: this.docId,
                    hash: previewHash,
                    data: {
                        type: suffix,
                        bytes: new Uint8Array(previewImageFileBytes),
                    },
                })
            })
            previewId = previewHash + '.' + suffix
        }

        return {
            imageInfo: Wukong.DocumentProto.ImageInfo.create({
                imageId: fileName,
                imageName: name,
                imageWidth: image.width,
                imageHeight: image.height,
                baseUrl: environment.httpPrefixes[HttpPrefixKey.COMMON_API],
                previewId,
                colorProfile: toColorProfile(colorSpace),
                resourcePixelsHandle,
                hasGamma,
                chunkBytesList: ImageDecodedBitmapHelpers.createChunkBytesList(image),
                usingKtx: image.type === ImageDecodedBitmapType.Ktx,
            }),
            hasCompressed,
        }
    }

    private reduceImageSize(width: number, height: number) {
        while (width > MAX_PREVIEW_SIZE || height > MAX_PREVIEW_SIZE) {
            width /= 2
            height /= 2
        }
        return {
            previewWidth: Math.ceil(width),
            previewHeight: Math.ceil(height),
        }
    }

    public retryUploadImageToServer() {
        this.postTimeout.forEach((item) => {
            clearTimeout(item)
        })
        this.postTimeout.clear()

        const optionsArr: UploadData[] = []
        this.uploadDataSet.forEach((item) => {
            optionsArr.push(item)
            this.uploadDataSet.delete(item)
        })
        optionsArr.forEach((item) => {
            this.postImageToServer(item)
        })
    }

    private maxSendLogTimes = 10
    private lastSendTime = Date.now()

    private async postImageToServer(options: UploadData, times = 1) {
        const key = options.ossUrl.substring(options.ossUrl.lastIndexOf('/') + 1, options.ossUrl.indexOf('?'))

        const reqAwaited = fetch(options.ossUrl, {
            method: options.method,
            headers: getUploadHeader({ contentType: options.contentType }),
            body: options.imageFileBytes,
        })
            .then((responseOss) => {
                if (!responseOss.ok) {
                    throw new Error(`bad status code: ${responseOss.status}`)
                }
                this.uploadDataSet.delete(options)
                this.postTimeout.delete(key)
                this.checkBlock()
            })
            .catch((e) => {
                console.error(`image: ${key},upload failed at ${times} times: ${e ?? e.message}`)
                if (times == 5 && Date.now() - this.lastSendTime > 15000 && this.maxSendLogTimes > 0) {
                    console.error(new Error('many images upload failed'))
                    this.lastSendTime = Date.now()
                    this.maxSendLogTimes--
                }
                if (times < 10) {
                    const timer = setTimeout(() => {
                        this.uploadDataSet.delete(options)
                        this.postImageToServer(options, times + 1)
                    }, Math.pow(2, times - 1) * 1000)
                    this.postTimeout.set(key, timer)
                } else {
                    this.postTimeout.delete(key)
                }
            })
        if (featureSwitchManager.isEnabled('js-image-post-await')) {
            await reqAwaited
        }
        this.uploadDataSet.add(options)
        this.checkBlock()
    }
    checkBlock() {
        this.states.setState({
            blockStatusState: this.hasNotUploadedImages(),
        })
    }

    public hasNotUploadedImages() {
        return this.uploadDataSet.size !== 0
    }

    /**
     * @brief 将图片上传到服务器
     */
    private async uploadImageToServer(
        blob: Blob,
        name: string,
        overrideImageHash: string | null
    ): Promise<{
        imageInfo: Wukong.DocumentProto.ImageInfo
        hasCompressed: boolean
    } | null> {
        const imageFile = await tryGetCompressedImageAndDiscardGammaIfCompressed(getImageLibContext(), blob)

        const hasCompressed = imageFile.hasCompressed

        const format = blob.type.split('/')[1]
        const data: { format: string; fileName?: string } = {
            format,
        }
        if (overrideImageHash) {
            data.fileName = overrideImageHash.replace(/\..+$/, '')
        }

        const imageFileBytes = await imageFile.file.arrayBuffer()
        const compressedImageBitmap = await this.createCompressedImageBitmap(imageFile.file)
        if (!compressedImageBitmap) {
            return null
        }
        const { image, colorSpace, hasGamma } = compressedImageBitmap

        if (!overrideImageHash) {
            const { method, resourceId, contentType, ossUrl } = await new GetPrivateUploadAuthorization(data).start()
            const resourcePixelsHandle = this._bitmapStore.addBitmap(resourceId, image)

            // 上传
            this.postImageToServer({ ossUrl, method, contentType, imageFileBytes })
            let previewId = ''
            if (image.width > MAX_PREVIEW_SIZE || image.height > MAX_PREVIEW_SIZE) {
                previewId = await this.uploadPreviewImage(image, imageFile.file, format)
            }

            return {
                imageInfo: Wukong.DocumentProto.ImageInfo.create({
                    imageId: resourceId,
                    imageName: name,
                    imageWidth: image.width,
                    imageHeight: image.height,
                    baseUrl: environment.httpPrefixes[HttpPrefixKey.COMMON_API],
                    previewId,
                    colorProfile: toColorProfile(colorSpace),
                    resourcePixelsHandle,
                    hasGamma,
                    chunkBytesList: ImageDecodedBitmapHelpers.createChunkBytesList(image),
                    usingKtx: image.type === ImageDecodedBitmapType.Ktx,
                }),
                hasCompressed,
            }
        } else {
            const resourcePixelsHandle = this._bitmapStore.addBitmap(overrideImageHash, image)

            // 上传
            Promise.resolve().then(async () => {
                const { method, contentType, ossUrl } = await new GetPrivateUploadAuthorization(data).start()
                this.postImageToServer({ ossUrl, method, contentType, imageFileBytes })
            })

            return {
                imageInfo: Wukong.DocumentProto.ImageInfo.create({
                    imageId: overrideImageHash,
                    imageName: name,
                    imageWidth: image.width,
                    imageHeight: image.height,
                    baseUrl: environment.httpPrefixes[HttpPrefixKey.COMMON_API],
                    previewId: null,
                    colorProfile: toColorProfile(colorSpace),
                    resourcePixelsHandle,
                    hasGamma,
                    chunkBytesList: ImageDecodedBitmapHelpers.createChunkBytesList(image),
                    usingKtx: image.type === ImageDecodedBitmapType.Ktx,
                }),
                hasCompressed,
            }
        }
    }

    private static getMaxTextureSize(editorService: EditorService) {
        const state = editorService.getCanvasState()
        if (state?.type == CanvasStateType.WebGL) {
            return state.context.getParameter(state.context.MAX_TEXTURE_SIZE) ?? 2048
        } else if (state?.type == CanvasStateType.WebGPU) {
            return state.device?.limits.maxTextureDimension2D ?? 8192
        } else {
            // 默认值
            return 4096
        }
    }

    private async createCompressedImageBitmap(image: Blob): Promise<{
        image: ImageTotalDecompressedDecodedBitmap
        colorSpace: ColorSpace
        hasGamma: boolean
    } | null> {
        const maxTextureSize = ImageDownloadContext.getMaxTextureSize(this.bridge.currentEditorService)

        // 删除 png 中的 iCCP gAMA，和 figma 保持一致
        let array: Uint8Array = new Uint8Array(await image.arrayBuffer())
        const meta = await removeICCChunksAndGetMeta(getImageLibContext(), array)
        array = meta.image
        const colorSpace = meta.isDisplayP3 ? ColorSpace.DisplayP3 : ColorSpace.sRGB

        let imageBitmap: ImageBitmapCompat
        try {
            imageBitmap = await createImageBitmapCompat(getImageLibContext(), new Blob([array]))
        } catch (e) {
            throw new Error(DECODE_ERROR_FLAG)
        }

        // 宽高都在最大大小范围内，无需压缩
        if (imageBitmap.width > maxTextureSize || imageBitmap.height > maxTextureSize) {
            imageBitmap = await this.compressUsingCanvas(imageBitmap, maxTextureSize)
        }

        try {
            const decodedResult = await bitmapCompatToDecoded(getImageLibContext(), imageBitmap)
            if (!decodedResult.success) {
                throw new Error(DECODE_ERROR_FLAG)
            }

            return {
                image: decodedResult.data,
                colorSpace,
                hasGamma: meta.hasGamma,
            }
        } catch (e) {
            console.error(e)
            return null
        }
    }

    public async fetchAndDecodeControlImage(fetcher: () => Promise<Blob>): Promise<{
        width: number
        height: number
        data: Uint8Array
    }> {
        const maxTextureSize = ImageDownloadContext.getMaxTextureSize(this.bridge.currentEditorService)
        const blob = await fetcher()
        return decodeControlImageWithScaleDown(getImageLibContext(), blob, maxTextureSize)
    }

    private async compressUsingCanvas(
        imageBitmap: ImageBitmapCompat,
        maxTextureSize: number
    ): Promise<{
        width: number
        height: number
        data: ImageData
    }> {
        // 计算压缩后的宽高
        let compressedWidth = 0
        let compressedHeight = 0
        if (imageBitmap.width > imageBitmap.height) {
            compressedWidth = maxTextureSize
            compressedHeight = Math.max(1, Math.round((maxTextureSize * imageBitmap.height) / imageBitmap.width))
        } else {
            compressedWidth = Math.max(1, Math.round((maxTextureSize * imageBitmap.width) / imageBitmap.height))
            compressedHeight = maxTextureSize
        }

        // 使用 canvas 压缩
        const canvas = document.createElement('canvas')
        if (canvas == null) {
            // 无法创建 canvas，认为解码失败
            throw new Error(DECODE_ERROR_FLAG)
        }
        canvas.width = compressedWidth
        canvas.height = compressedHeight
        const context = canvas.getContext('2d', {
            alpha: true,
            // 使用 CPU 渲染
            willReadFrequently: true,
        })
        if (context == null) {
            // 无法创建 context，认为解码失败
            throw new Error(DECODE_ERROR_FLAG)
        }

        drawImageBitmapCompatToCanvas(context, imageBitmap, 0, 0, compressedWidth, compressedHeight)

        const compressedImageData = context.getImageData(0, 0, compressedWidth, compressedHeight)
        return {
            width: compressedImageData.width,
            height: compressedImageData.height,
            data: compressedImageData,
        }
    }
}
