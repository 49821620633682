/* eslint-disable no-restricted-imports */
import { EndEditingGradientWasmCall, StartEditingGradientCommand, Wukong } from '@wukong/bridge-proto'
import { useCallback } from 'react'
import { useCommand } from '../context/document-context'

export function useGradientEdit() {
    const command = useCommand()

    const startEditingGradient = useCallback(
        (
            gradientNodeId: string,
            positions: Wukong.DocumentProto.IPaintPosition[],
            selectedGradientColorStopIndex?: number
        ) => {
            const params: Wukong.DocumentProto.IStartEditingGradientCommandParam = {
                gradientNodeId,
                positions,
                selectedGradientColorStopIndex,
            }
            command.DEPRECATED_invokeBridge(
                StartEditingGradientCommand,
                params as unknown as Wukong.DocumentProto.StartEditingGradientCommandParam
            )
        },
        [command]
    )

    const endEditingGradient = useCallback(() => {
        command.DEPRECATED_invokeBridge(EndEditingGradientWasmCall)
    }, [command])

    return { startEditingGradient, endEditingGradient }
}
