import { useMemo } from 'react'

const MAX_USED_MEMORY = 4 // G

export function getMemoryUsedRawValue(usedMemory: number) {
    return usedMemory / (1024 * 1024 * 1024)
}

export function getMemoryUsedPercent(usedMemory: number) {
    return usedMemory / MAX_USED_MEMORY
}

export function useMemoryUsageValue(usedMemory: number) {
    const memoryUsedRawValue = useMemo(() => getMemoryUsedRawValue(usedMemory), [usedMemory])
    const memoryUsedValue = useMemo(() => memoryUsedRawValue.toFixed(2), [memoryUsedRawValue])
    const memoryUsedPercent = useMemo(() => getMemoryUsedPercent(memoryUsedRawValue), [memoryUsedRawValue])

    return { memoryUsedRawValue, memoryUsedValue, memoryUsedPercent }
}

// 判定阈值
export const MEMORY_USAGE_THRESHOLD_WARNING = 0.6 // 大于等于这个比例展示警告
export const MEMORY_USAGE_THRESHOLD_DANGER = 0.75 // 大于等于这个比例展示严重警告
export const MEMORY_USAGE_THRESHOLD_FORBIDDEN = 0.95 // 大于等于这个比例弹框并停止编辑器工作

export enum MemoryUsageAlertType {
    NONE, // 无提示
    WARNING, // 提示
    DANGER, // 警告
    FORBIDDEN, // 弹窗禁止操作
}

export function getAlertTypeByUsage(usage: number) {
    return usage < MEMORY_USAGE_THRESHOLD_WARNING
        ? MemoryUsageAlertType.NONE
        : usage < MEMORY_USAGE_THRESHOLD_DANGER
        ? MemoryUsageAlertType.WARNING
        : usage < MEMORY_USAGE_THRESHOLD_FORBIDDEN
        ? MemoryUsageAlertType.DANGER
        : MemoryUsageAlertType.FORBIDDEN
}
