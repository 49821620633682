import { translation } from './tool-extend-bool.translation'
import { UpdateTopExtendBoolMenuVisibleCommand, Wukong } from '@wukong/bridge-proto'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import {
    BicolorIconToolbarExclude24,
    BicolorIconToolbarIntersect24,
    BicolorIconToolbarSubtract24,
    DropdownV2,
    MonoIconToolbarFlatten24,
    MonoIconToolbarUnion24,
    WKItemType,
    WKMenuConfig,
} from '../../../../../../../ui-lib/src'
import { useCommand } from '../../../../../main/app-context'
import { GlobalEditorActionConfigMap } from '../../../../../main/global/global-editor-action-config/global-editor-action-config'
import { useViewState } from '../../../../../view-state-bridge'
import { useEditorActionStatusSubMenuItem } from '../../menu/hook/editor-action-status-menu'
import { useMenu } from '../../menu/menu-v2/use-menu'
import { transformMenuConfig } from '../../menu/menu-v2/utils'
import '../tool-extend.less'
import ActionEnabledType = Wukong.DocumentProto.ActionEnabledType
import { frogEventToolsMenusAndActionsBasicAction } from '../../../../utils/tools-actions-frog'

// {translation('BooleanGroups')} & 拼合快捷工具
export function ToolExtendBool() {
    const command = useCommand()
    const showBoolTool = useViewState('actionEnabledUnionStatus')
    const { onChange } = useMenu()

    const [editorActionType, setEditorActionType] = useState<ActionEnabledType>(
        ActionEnabledType.ACTION_ENABLED_TYPE_UNION
    )

    const boolCalc = useMemo<{
        icon: ReactNode
        editorActionType: ActionEnabledType
        iconTooltipTitle: string | undefined
        iconTooltipShortcut: string | undefined
    }>(() => {
        switch (editorActionType) {
            case ActionEnabledType.ACTION_ENABLED_TYPE_FLATTEN:
                return {
                    icon: <MonoIconToolbarFlatten24 data-testid="icon-flatten-svg" />,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_FLATTEN,
                    iconTooltipTitle:
                        GlobalEditorActionConfigMap[ActionEnabledType.ACTION_ENABLED_TYPE_FLATTEN]?.displayName,
                    iconTooltipShortcut:
                        GlobalEditorActionConfigMap[ActionEnabledType.ACTION_ENABLED_TYPE_FLATTEN]?.keyboardShortcut
                            ?.shortcutLabel,
                }
            case ActionEnabledType.ACTION_ENABLED_TYPE_SUBTRACT:
                return {
                    icon: <BicolorIconToolbarSubtract24 data-testid="icon-subtract-svg" />,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_SUBTRACT,
                    iconTooltipTitle:
                        GlobalEditorActionConfigMap[ActionEnabledType.ACTION_ENABLED_TYPE_SUBTRACT]?.displayName,
                    iconTooltipShortcut:
                        GlobalEditorActionConfigMap[ActionEnabledType.ACTION_ENABLED_TYPE_SUBTRACT]?.keyboardShortcut
                            ?.shortcutLabel,
                }
            case ActionEnabledType.ACTION_ENABLED_TYPE_INTERSECT:
                return {
                    icon: <BicolorIconToolbarIntersect24 data-testid="icon-intersect-svg" />,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_INTERSECT,
                    iconTooltipTitle:
                        GlobalEditorActionConfigMap[ActionEnabledType.ACTION_ENABLED_TYPE_INTERSECT]?.displayName,
                    iconTooltipShortcut:
                        GlobalEditorActionConfigMap[ActionEnabledType.ACTION_ENABLED_TYPE_INTERSECT]?.keyboardShortcut
                            ?.shortcutLabel,
                }
            case ActionEnabledType.ACTION_ENABLED_TYPE_EXCLUDE:
                return {
                    icon: <BicolorIconToolbarExclude24 data-testid="icon-exclude-svg" />,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_EXCLUDE,
                    iconTooltipTitle:
                        GlobalEditorActionConfigMap[ActionEnabledType.ACTION_ENABLED_TYPE_EXCLUDE]?.displayName,
                    iconTooltipShortcut:
                        GlobalEditorActionConfigMap[ActionEnabledType.ACTION_ENABLED_TYPE_EXCLUDE]?.keyboardShortcut
                            ?.shortcutLabel,
                }
            case ActionEnabledType.ACTION_ENABLED_TYPE_UNION:
            default:
                return {
                    icon: <MonoIconToolbarUnion24 data-testid="icon-union-svg" />,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_UNION,
                    iconTooltipTitle:
                        GlobalEditorActionConfigMap[ActionEnabledType.ACTION_ENABLED_TYPE_UNION]?.displayName,
                    iconTooltipShortcut:
                        GlobalEditorActionConfigMap[ActionEnabledType.ACTION_ENABLED_TYPE_UNION]?.keyboardShortcut
                            ?.shortcutLabel,
                }
        }
    }, [editorActionType])

    const onIconClick = useCallback(() => {
        setEditorActionType(boolCalc.editorActionType)
        GlobalEditorActionConfigMap[boolCalc.editorActionType]?.viewCommand(command)
        frogEventToolsMenusAndActionsBasicAction({
            action_name: '使用并集',
            operation_way: '上下文工具',
        })
    }, [boolCalc.editorActionType, command])

    const menuItems: WKMenuConfig = [
        useEditorActionStatusSubMenuItem({
            command,
            editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_UNION,
            hiddenWhenDisabled: true,
            handlerCallback: () => {
                setEditorActionType(ActionEnabledType.ACTION_ENABLED_TYPE_UNION)
                frogEventToolsMenusAndActionsBasicAction({
                    action_name: '使用并集',
                    operation_way: '上下文工具-下拉菜单',
                })
            },
            forwardIcon: <MonoIconToolbarUnion24 />,
            testId: 'bool-union',
        }),
        useEditorActionStatusSubMenuItem({
            command,
            editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_SUBTRACT,
            hiddenWhenDisabled: true,
            handlerCallback: () => {
                setEditorActionType(ActionEnabledType.ACTION_ENABLED_TYPE_SUBTRACT)
                frogEventToolsMenusAndActionsBasicAction({
                    action_name: '使用减去顶层',
                    operation_way: '上下文工具-下拉菜单',
                })
            },
            forwardIcon: <BicolorIconToolbarSubtract24 className={'subtract'} />,
            testId: 'bool-subtract',
        }),
        useEditorActionStatusSubMenuItem({
            command,
            editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_INTERSECT,
            hiddenWhenDisabled: true,
            handlerCallback: () => {
                setEditorActionType(ActionEnabledType.ACTION_ENABLED_TYPE_INTERSECT)
                frogEventToolsMenusAndActionsBasicAction({
                    action_name: '使用交集',
                    operation_way: '上下文工具-下拉菜单',
                })
            },
            forwardIcon: <BicolorIconToolbarIntersect24 className="intersect" />,
            testId: 'bool-intersect',
        }),
        useEditorActionStatusSubMenuItem({
            command,
            editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_EXCLUDE,
            hiddenWhenDisabled: true,
            handlerCallback: () => {
                setEditorActionType(ActionEnabledType.ACTION_ENABLED_TYPE_EXCLUDE)
                frogEventToolsMenusAndActionsBasicAction({
                    action_name: '使用排除重叠',
                    operation_way: '上下文工具-下拉菜单',
                })
            },
            forwardIcon: <BicolorIconToolbarExclude24 className="exclude" />,
            testId: 'bool-exclude',
        }),
        { type: WKItemType.Divider },
        useEditorActionStatusSubMenuItem({
            command,
            editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_FLATTEN,
            hiddenWhenDisabled: true,
            handlerCallback: () => {
                setEditorActionType(ActionEnabledType.ACTION_ENABLED_TYPE_FLATTEN)
                frogEventToolsMenusAndActionsBasicAction({
                    action_name: '使用拼合',
                    operation_way: '上下文工具-下拉菜单',
                })
            },
            forwardIcon: <MonoIconToolbarFlatten24 />,
            testId: 'flatten',
        }),
    ]

    const onOpen = useCallback(() => {
        command.DEPRECATED_invokeBridge(UpdateTopExtendBoolMenuVisibleCommand, { value: true })
    }, [command])

    const onClose = useCallback(() => {
        command.DEPRECATED_invokeBridge(UpdateTopExtendBoolMenuVisibleCommand, { value: false })
    }, [command])

    useEffect(() => {
        if (!showBoolTool) {
            // 加 setTimeout 0 避免出现 flush 期间有 more dirty nodes（https://wkong.atlassian.net/browse/WK-20923）
            setTimeout(() => {
                command.DEPRECATED_invokeBridge(UpdateTopExtendBoolMenuVisibleCommand, { value: false })
            }, 0)
        }
    }, [showBoolTool, command])

    return showBoolTool ? (
        <DropdownV2.MenuMultiLevel
            style={{ borderRadius: 'var(--wk-radius-none)' }}
            placement="bottom center"
            label={boolCalc.icon}
            items={transformMenuConfig(menuItems, { forwardIconToForwardChildren: true })}
            onOpen={onOpen}
            onClose={onClose}
            onChange={onChange}
            dataTestIds={{
                triggerFocus: 'expand-bool',
                container: 'popup',
            }}
            tooltipProps={{ title: translation('BooleanGroups') }}
            labelMoveOut={{
                tooltipProps: { title: boolCalc.iconTooltipTitle, shortcut: boolCalc.iconTooltipShortcut },
                dataTestId: 'bool-btn',
                onClick: onIconClick,
            }}
        />
    ) : null
}
