import classnames from 'classnames'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { useMount, useUpdateEffect } from 'react-use'
import { usePointerCapture } from '../../../utils/hook/use-pointer-capture'
import { Scrollbar, ScrollbarRef } from '../../scrollbars'
import { HtmlTextarea, HtmlTextareaRef } from '../html-textarea/html-textarea'
import { ResizeIcon } from './resize-icon'
import classes from './textarea.module.less'

/**
 * @description 这里没有继承 HTMLTextareaElement 的属性。因为一些属性的表现和设计预期的不一致。所以如果缺少想要的属性就手动添加并确认表现与预期一致
 */
export interface TextareaProps {
    value?: string // 如果指定value,组件就是受控组件
    defaultValue?: string
    placeholder?: string
    height?: number
    minHeight?: number
    maxHeight?: number
    autoFocus?: boolean
    autoHeight?: boolean // 根据输入内容自动调整高度
    resize?: boolean // 是否可以拖拽改变textarea大小(高度)
    disabled?: boolean
    count?: {
        show?: boolean // 是否展示字数提示
        max?: number // 展示字数时的最大字数（分母）
        tipMessage?: string
        onOverflow?: (isOverflow: boolean) => void
    }
    // count中的字数超出也是一种error,当error.show === true时优先级低于error
    error?: {
        show?: boolean
        tipMessage?: string // 报错的提示文字
    }
    dataTestIds?: {
        root?: string
        textarea?: string
        count?: string
        resize?: string
        scrollbar?: string
        tool?: string
    }
    onChange?: (e: React.FormEvent<HTMLTextAreaElement>) => void
    onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
    onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
    onInput?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
    onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void
}

export interface TextAreaOperations {
    focus: () => void
    selectAll: () => void
    scrollToBottom: () => void
    getTextareaElement: () => HTMLTextAreaElement
}

export const Textarea = forwardRef(function InnerTextArea(props: TextareaProps, ref: React.Ref<TextAreaOperations>) {
    const {
        value,
        defaultValue = '',
        placeholder,
        height,
        minHeight,
        maxHeight,
        autoHeight,
        resize,
        disabled,
        count,
        error,
        dataTestIds,
        autoFocus,
        onChange,
        onFocus,
        onBlur,
        onInput,
        onKeyDown,
        ...otherProps
    } = props
    const editAreaRef = useRef<HTMLDivElement>(null)
    const htmlTextareaRef = useRef<HtmlTextareaRef>(null)
    const [innerValue, setInnerValue] = useState<string>(defaultValue)
    const [resizeHeight, setResizeHeight] = useState<number>(height ?? 0)

    const textareaValue = useMemo(() => {
        return value !== undefined ? value : innerValue
    }, [innerValue, value])

    const getHeightFromTextareaRows = useCallback((row: number) => {
        const lineHeight = 20
        const paddingTop = 6
        const paddingBottom = 6
        return lineHeight * row + paddingTop + paddingBottom
    }, [])

    const toolHeight = useMemo(() => {
        return count?.show ? 22 : 0
    }, [count?.show])

    const clampMinMaxHeight = useMemo(() => {
        const oneLineHeight = getHeightFromTextareaRows(1) + toolHeight
        const oneHundredLineHeight = getHeightFromTextareaRows(100) + toolHeight
        const clampMinHeight =
            minHeight === undefined
                ? oneLineHeight
                : Math.min(Math.max(minHeight ?? oneLineHeight, oneLineHeight), oneHundredLineHeight)
        const clampMaxHeight = Math.min(
            Math.max(maxHeight ?? oneHundredLineHeight, clampMinHeight),
            oneHundredLineHeight
        )
        return { clampMinHeight, clampMaxHeight }
    }, [getHeightFromTextareaRows, maxHeight, minHeight, toolHeight])

    const editAreaMinHeight = useMemo(() => {
        const { clampMinHeight, clampMaxHeight } = clampMinMaxHeight
        return Math.min(Math.max(resize ? resizeHeight : clampMinHeight, clampMinHeight), clampMaxHeight)
    }, [clampMinMaxHeight, resize, resizeHeight])

    const editAreaHeight = useMemo(() => {
        if (resize || autoHeight) {
            return undefined
        }
        const { clampMinHeight, clampMaxHeight } = clampMinMaxHeight
        return Math.min(Math.max(height ?? clampMinHeight, clampMinHeight), clampMaxHeight)
    }, [autoHeight, clampMinMaxHeight, height, resize])

    const editAreaMaxHeight = useMemo(() => {
        const { clampMinHeight, clampMaxHeight } = clampMinMaxHeight
        return Math.max(Math.min(resize ? resizeHeight : clampMaxHeight, clampMaxHeight), clampMinHeight)
    }, [clampMinMaxHeight, resize, resizeHeight])

    const scrollbarMinHeight = useMemo(() => {
        return editAreaMinHeight - toolHeight
    }, [editAreaMinHeight, toolHeight])

    const scrollbarHeight = useMemo(() => {
        return editAreaHeight === undefined ? undefined : editAreaHeight - toolHeight
    }, [editAreaHeight, toolHeight])

    const scrollbarMaxHeight = useMemo(() => {
        return editAreaMaxHeight - toolHeight
    }, [editAreaMaxHeight, toolHeight])

    const countTipText = useMemo(() => {
        return count?.max !== undefined ? `${textareaValue.length}/${count?.max}` : textareaValue.length
    }, [count?.max, textareaValue])

    const isCountOverflow = useMemo(() => {
        return count?.max !== undefined ? textareaValue.length > count.max : false
    }, [count?.max, textareaValue])

    const isError = useMemo(() => {
        if (error?.show) {
            return true
        }
        return count?.show ? isCountOverflow : false
    }, [count?.show, error?.show, isCountOverflow])

    const errorTipMessage = useMemo(() => {
        if (error?.show) {
            return error?.tipMessage ?? ''
        }
        return count?.show ? count?.tipMessage ?? '' : ''
    }, [count?.show, count?.tipMessage, error?.show, error?.tipMessage])

    const _onChange = useCallback(
        (e: React.FormEvent<HTMLTextAreaElement>) => {
            const el = e.target as HTMLTextAreaElement
            setInnerValue(el.value)
            onChange?.(e)
        },
        [onChange]
    )

    const _onKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
            const inputElement = e.target as HTMLTextAreaElement
            if (e.code === 'Escape' || e.key === 'Escape') {
                // input和textarea的失焦处理放到setTimeout是这么考量的（失焦的行为应该是最后发生的）
                // - 直接失效后续的onKeyDown里的逻辑拿到的document.activeElement不是textarea。会让人疑惑为什么发生keydown事件，焦点却不在该元素上。
                // - 基于上面的情况，直接失焦也会让一些全局捕获事件的逻辑变得稍微奇怪。比如在聚焦状态下的键盘事件会进入document.activeElement===body的逻辑中
                // - 失焦作为最后的行为也为在esc期间修改一些react状态并在最后的提交中提交提供了机会。case见normal-input组件
                setTimeout(() => inputElement?.blur(), 0)
            }
            onKeyDown?.(e)
        },
        [onKeyDown]
    )

    const onMouseDownTool = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
    }, [])
    const capturing = useCallback((e: React.PointerEvent) => {
        if (!editAreaRef.current) {
            return
        }
        const rect = editAreaRef.current.getBoundingClientRect()
        setResizeHeight(e.clientY - rect.top)
    }, [])

    const { pointerdown, pointermove, pointerup } = usePointerCapture({ capturing })

    useMount(() => {
        const textareaHtml = htmlTextareaRef.current?.getTextareaElement()
        if (autoFocus && textareaHtml) {
            const len = textareaHtml.value.length
            textareaHtml.focus()
            textareaHtml.setSelectionRange(len, len)
        }
    })

    useUpdateEffect(() => {
        htmlTextareaRef.current?.updateAutoHeight()
    }, [value, height, minHeight, maxHeight])

    useEffect(() => {
        count?.onOverflow?.(isCountOverflow)
    }, [isCountOverflow, count])

    useImperativeHandle(
        ref,
        () => {
            return {
                focus: () => {
                    htmlTextareaRef.current?.getTextareaElement().focus()
                },
                selectAll: () => {
                    htmlTextareaRef.current?.setSelectionRange(
                        0,
                        htmlTextareaRef.current.getTextareaElement().value.length ?? null,
                        'forward'
                    )
                },
                scrollToBottom: () => {
                    scrollRef.current?.scrollToBottom()
                },
                getTextareaElement: () => htmlTextareaRef.current?.getTextareaElement() as HTMLTextAreaElement,
            }
        },
        []
    )

    const scrollRef = useRef<ScrollbarRef>(null)

    return (
        <div
            className={classnames(classes.root, {
                [classes.isError]: isError,
                [classes.isCountOverflow]: isCountOverflow,
                [classes.disabled]: disabled,
            })}
            data-testid={dataTestIds?.root}
        >
            <div
                ref={editAreaRef}
                className={classes.editArea}
                style={{
                    minHeight: editAreaHeight ?? editAreaMinHeight,
                    maxHeight: editAreaHeight ?? editAreaMaxHeight,
                }}
            >
                <Scrollbar
                    ref={scrollRef}
                    autoHeight
                    autoHeightMin={scrollbarHeight ?? scrollbarMinHeight}
                    autoHeightMax={scrollbarHeight ?? scrollbarMaxHeight}
                    data-testid={dataTestIds?.scrollbar}
                >
                    <HtmlTextarea
                        {...otherProps}
                        ref={htmlTextareaRef}
                        data-testid={dataTestIds?.textarea}
                        className={classes.textarea}
                        autoHeight
                        autoFocus={autoFocus}
                        autoHeightBaseHeight={scrollbarMinHeight}
                        style={{
                            minHeight: Math.max(scrollbarMinHeight, scrollbarHeight ?? scrollbarMinHeight),
                        }}
                        disabled={disabled}
                        placeholder={placeholder}
                        value={textareaValue}
                        onChange={_onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onInput={onInput}
                        onKeyDown={_onKeyDown}
                    ></HtmlTextarea>
                </Scrollbar>
                <div
                    data-testid={dataTestIds?.tool}
                    className={classes.tool}
                    style={{ height: toolHeight }}
                    onMouseDown={onMouseDownTool}
                >
                    {count?.show ? (
                        <span
                            className={classes.showCount}
                            style={{ right: resize ? 12 : 4 }}
                            data-testid={dataTestIds?.count}
                        >
                            {countTipText}
                        </span>
                    ) : null}
                    {resize ? (
                        <span
                            className={classes.resizeIcon}
                            data-testid={dataTestIds?.resize}
                            onPointerDown={pointerdown}
                            onPointerMove={pointermove}
                            onPointerUp={pointerup}
                        >
                            <ResizeIcon />
                        </span>
                    ) : null}
                </div>
            </div>
            {isError ? <div className={classes.errorTipMessage}>{errorTipMessage}</div> : null}
        </div>
    )
})
