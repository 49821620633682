import { IDBPObjectStore } from 'idb'
import { IndexedDBName, IndexedDBName2DBSchema } from '../config'
import { enhanceIDBSet } from '../utils'

/**
 * 增强版本的 IDBPObjectStore 实例
 * proxy 了一些写入方法以实现超限重试和 metric 的能力
 *  - add
 *  - put
 * @param db
 * @returns
 */
export const enhanceObjectStore = <T extends IndexedDBName>(
    store: IDBPObjectStore<IndexedDBName2DBSchema[T]>
): IDBPObjectStore<IndexedDBName2DBSchema[T]> => {
    return new Proxy(store, {
        get: (
            target: IDBPObjectStore<IndexedDBName2DBSchema[T]>,
            key: keyof IDBPObjectStore<IndexedDBName2DBSchema[T]>
        ) => {
            const method = target[key]

            if (typeof method === 'function') {
                return (...args: any[]) => {
                    // @ts-expect-error
                    const methodWithArgs = method.bind(target, ...args)

                    switch (key) {
                        case 'add':
                        case 'put': {
                            return enhanceIDBSet(methodWithArgs as any, ...args)
                        }
                        default: {
                            return methodWithArgs()
                        }
                    }
                }
            } else {
                return method
            }
        },
    })
}
