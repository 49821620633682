export function extractErrorMessage(err: unknown) {
    if (err) {
        if (err instanceof Error) {
            return err.message
        }
        if (typeof err === 'string') {
            return err
        }
    }
    return `${err}`
}
