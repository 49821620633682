import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { Tooltip, useEllipsisTooltip, WKToast, WKTypography } from '../../../../../../../ui-lib/src'
import { GradientPaint, ImagePaint, SolidPaint, Paint as WPaint } from '../../../../../document/node/node'
import { useColorModeServiceV2, useCommand } from '../../../../../main/app-context'
import { toFixed } from '../../../../utils/to-fixed'
import { ColorSpace, float2hex } from '../../../design/blend/color-picker/utils/color-translate'
import { PaintStyleThumbnail } from '../../../design/styles/style-color/style-thumbnail'
import { PaintIcon } from '../../../paint-icon-color/paint-icon/paint-icon'
import { paintTypeToShow } from '../common/inspect-type'
import { getColorValueV2 } from '../common/utils'
import style from '../inspect.module.less'
import { translation } from './paint-render.translation'
import RGB = Wukong.DocumentProto.IRGB
import InspectSelectionFillItem = Wukong.DocumentProto.IInspectSelectionFillItem
import PaintType = Wukong.DocumentProto.PaintType
import Paint = Wukong.DocumentProto.IPaint
import { execCommandCopy } from '../../../../../../../util/src/copy'

export const ColorRenderV2 = (props: { rgb: RGB; opacity?: number | null; className?: string }) => {
    const cmd = useCommand()
    const colorMode = useColorModeServiceV2().states.use.colorModeState()
    const { rgb, opacity } = props
    let code = getColorValueV2(cmd, colorMode, rgb, opacity ?? undefined)
    // argb 需要转换为 # 开头
    if (code.startsWith('0x')) {
        code = code.replace('0x', '#')
    }
    const formatOpacity = toFixed((opacity ?? 0) * 100, 2)
    const { inactivation, ellipsisRef, onmouseenter, onmouseleave } = useEllipsisTooltip<HTMLDivElement>()
    if (colorMode === Wukong.DocumentProto.DevAndReadonlyColorMode.DEV_AND_READONLY_COLOR_MODE_H_E_X) {
        return (
            <WKTypography.Text
                copyable
                copyText={code + (opacity === 1 ? '' : float2hex((opacity ?? 0) * 255).toUpperCase())}
                className={classNames(props.className, style.devModeInspectTypographyText)}
            >
                <span className={classNames('inline-block', formatOpacity !== 100 && 'w-16')}>{code}</span>
                {formatOpacity !== 100 && <span className="ml-2">{formatOpacity}%</span>}
            </WKTypography.Text>
        )
    }
    return (
        <Tooltip title={code} inactivation={inactivation}>
            <div
                className="px-1 wk-text-12 wk-font-regular truncate"
                onMouseEnter={onmouseenter}
                onMouseLeave={onmouseleave}
                ref={ellipsisRef}
            >
                <span
                    className="hover:(ring-4 ring-$wk-gray-13-4 bg-$wk-gray-13-4) rounded-3px"
                    onClick={() => {
                        execCommandCopy(code)
                        WKToast.show(translation('CopiedToClipboard'))
                    }}
                >
                    {code}
                </span>
            </div>
        </Tooltip>
    )
}

export const ImagePaintRender = ({
    paint,
    colorSpace,
    className,
}: {
    paint: ImagePaint
    colorSpace: ColorSpace
    className?: string
}) => {
    const opacity = toFixed((paint.opacity ?? 0) * 100, 2)
    return (
        <div className={'flex items-center h-6 ' + (className ? className : 'mt-2')}>
            <PaintIcon className={style.paintIconIndependent} paint={paint} colorSpace={colorSpace}></PaintIcon>
            <span>
                <span className="inline-block w-16">{translation('Image')}</span>
                {opacity !== 100 && <span className="ml-2">{opacity}%</span>}
            </span>
        </div>
    )
}

export const SolidPaintRender = ({
    paint,
    colorSpace,
    className,
}: {
    paint: SolidPaint
    colorSpace: ColorSpace
    className?: string
}) => {
    return (
        <div className={'flex items-center h-6 ' + (className ? className : 'mt-2')}>
            <PaintIcon className={style.paintIconIndependent} paint={paint} colorSpace={colorSpace}></PaintIcon>
            <ColorRenderV2 rgb={paint.color} opacity={paint.opacity}></ColorRenderV2>
        </div>
    )
}

export const GradientPaintRender = ({
    paint,
    colorSpace,
    className,
}: {
    paint: GradientPaint
    colorSpace: ColorSpace
    className?: string
}) => {
    const colors = paint.gradientStops
    return (
        <div>
            <div className={'flex items-center h-6 ' + (className ? className : 'mt-2')}>
                <PaintIcon className={style.paintIconIndependent} paint={paint} colorSpace={colorSpace}></PaintIcon>
                <WKTypography.Text
                    copyable
                    copyText={paintTypeToShow[paint.type]}
                    className={style.devModeInspectTypographyText}
                >
                    {paintTypeToShow[paint.type]}
                </WKTypography.Text>
            </div>
            {colors
                .sort((a, b) => a.position - b.position)
                .map((color, idx) => {
                    return (
                        <div key={idx} className="pt-1 pl-8">
                            <ColorRenderV2 rgb={color.color} opacity={(color.color.a / 255) * (paint.opacity ?? 1)} />
                        </div>
                    )
                })}
        </div>
    )
}
export const NormalPaintRender = ({
    paint,
    colorSpace,
    className,
}: {
    paint: Paint
    colorSpace: ColorSpace
    className?: string
}) => {
    if (paint.type == PaintType.PAINT_TYPE_SOLID_PAINT) {
        const color = paint.color
        return (
            <>
                {!!color && (
                    <SolidPaintRender paint={paint as SolidPaint} colorSpace={colorSpace} className={className} />
                )}
            </>
        )
    }
    if (
        [
            PaintType.PAINT_TYPE_GRADIENT_ANGULAR,
            PaintType.PAINT_TYPE_GRADIENT_DIAMOND,
            PaintType.PAINT_TYPE_GRADIENT_LINEAR,
            PaintType.PAINT_TYPE_GRADIENT_RADIAL,
        ].includes(paint.type!)
    ) {
        return <GradientPaintRender paint={paint as GradientPaint} colorSpace={colorSpace} className={className} />
    }
    if (paint.type == PaintType.PAINT_TYPE_IMAGE_PAINT) {
        return <ImagePaintRender paint={paint as ImagePaint} colorSpace={colorSpace} className={className} />
    }
    return <></>
}

const StylePaintRenderItem = ({
    styleInfo,
    paints,
    colorSpace,
}: {
    styleInfo: { styleName: string; styleDescription: string }
    paints?: Paint[] | null
    colorSpace: ColorSpace
}) => {
    return (
        <div>
            <div className="flex items-center mt-4 mb-2">
                <PaintStyleThumbnail
                    className="w-4 h-4 inline-block mr-4 shrink-0"
                    paints={paints as readonly WPaint[]}
                    width={16}
                    height={16}
                />
                <div className={style.paintStyleName}>
                    <WKTypography.Text copyable copyText={styleInfo.styleName} style={{ padding: '2px 4px' }}>
                        {styleInfo.styleName}
                    </WKTypography.Text>
                </div>
            </div>
            <div className={styleInfo.styleName ? style.styleItemBodyWithDivider : style.styleItemBody}>
                <div className="ml-2">
                    {styleInfo.styleDescription && (
                        <div className={style.paintStyleDescription} tabIndex={-1}>
                            <span className="wk-text-12  wk-font-regular">{styleInfo.styleDescription}</span>
                        </div>
                    )}
                    {paints?.map((paint, idx) => {
                        return <NormalPaintRender paint={paint} colorSpace={colorSpace} key={idx} className="mt-1" />
                    })}
                </div>
            </div>
        </div>
    )
}

export const StylePaintRender = ({
    data,
    colorSpace,
    renderCount,
}: {
    data: InspectSelectionFillItem
    colorSpace: ColorSpace
    renderCount: number
}) => {
    // styleName 说明是真的样式
    if (data.styleName) {
        return (
            <StylePaintRenderItem
                paints={data.paints}
                styleInfo={{ styleName: data.styleName, styleDescription: data.styleDescription ?? '' }}
                colorSpace={colorSpace}
            />
        )
    } else {
        return (
            <>
                {data.paints?.slice(0, renderCount).map((paint, idx) => {
                    return <NormalPaintRender key={idx} paint={paint} colorSpace={colorSpace} className="mt-4" />
                })}
            </>
        )
    }
}
