import { ONE_DAY } from '../../../../util/src'
import constate from 'constate'
import { startOfDay } from 'date-fns'
import { useCallback, useRef, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import { SelectNormalEmptyRef } from '../selects'
import { DatepickerSelectType, WKDatepickerProps, WKDatepickerValue } from './data'

const range2selectType = (range: WKDatepickerValue) => {
    const start = range.start
    const end = startOfDay(range.end).getTime()
    const today = startOfDay(new Date()).getTime()
    if (end == today) {
        if (start === end) {
            return DatepickerSelectType.Today
        }
        if (start == today - ONE_DAY) {
            return DatepickerSelectType.Yesterday
        }
        if (start == today - ONE_DAY * 6) {
            return DatepickerSelectType.Last7Days
        }
        if (start == today - ONE_DAY * 29) {
            return DatepickerSelectType.Last30Days
        }
        return DatepickerSelectType.CustomRange
    } else {
        return DatepickerSelectType.CustomRange
    }
}

// 默认值只能传 标签值 不允许自定义, 虽然也可以传
const useWKDatepicker = (props: WKDatepickerProps) => {
    // 日期类型 今天、昨天、最近7天、最近30天、自定义，当选择自定义时，显示日期范围选择
    const [range, setRange] = useState<WKDatepickerValue>(props.value)
    // 是否显示日期范围选项
    const onChangeRef = useRef(props.onChange)

    const [showCustomRange, setShowCustomRange] = useState(false)
    const selectType = showCustomRange ? DatepickerSelectType.CustomRange : range2selectType(props.value)
    const selectNormalEmptyRef = useRef<SelectNormalEmptyRef>(null)

    useUpdateEffect(() => {
        const type = range2selectType(range)
        onChangeRef.current(range, type)
    }, [range])

    const updateRange = useCallback((param: WKDatepickerValue) => {
        setRange(param)
        selectNormalEmptyRef.current?.close()
    }, [])

    return {
        ...props,
        selectNormalEmptyRef,
        selectType,
        setRange,
        updateRange,
        range,
        showCustomRange,
        setShowCustomRange,
    }
}
export const [WKDatepickerProvider, useWKDatepickerContext] = constate(useWKDatepicker)
