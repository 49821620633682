import { MotiffApi } from '@motiffcom/plugin-api-types'
import {
    AiCreateAutoLayoutCommand,
    ApplyAiDuplicateCommand,
    CallAiCreateAutoLayoutCommand,
    CallAiDuplicateCommand,
    CallAiLayout,
    CallAiLayoutCommand,
    CheckAiCreditsCommand,
    CheckIsAIRecognizeDocumentCommand,
    CleanAIRecognizeDataCommand,
    EnableAIPoweredCommand,
    EnterAILayoutCommand,
    EvalJsScriptCommand,
    ExportFigJsonCommand,
    ExportImageToCanvas,
    ExportToHTML,
    GenerateCandidateComponentThumbnailCommand,
    GenerateStyleCandidateComponentThumbnailCommand,
    GetImageCdnCommand,
    HideTooltip,
    MarkEvalJsBeginCommand,
    MarkEvalJsEndCommand,
    PrepareExportImageCommand,
    RunPluginScriptCommand,
    ShowTooltip,
    TrySwitchToAiDuplicateCommand,
    UnlockAILayoutCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { WKToast } from '../../../../ui-lib/src'
import { createImmerStore, createSelectors } from '../../../../util/src'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { CanvasRenderBridge } from '../../document/bridge/canvas-render-bridge'
import { CommandInvoker } from '../../document/command/command-invoker'
import { CommitType } from '../../document/command/commit-type'
import { cmdSwitchEditorMode } from '../../document/command/document-command'
import { EditorMode } from '../../document/node/node'
import type { NetworkService } from '../../document/synergy/network-service'
import { environment } from '../../environment'
import { Bridge } from '../../kernel/bridge/bridge'
import { debugLog } from '../../kernel/debug'
import { WKFrog } from '../../kernel/frog'
import { NotifyService } from '../../kernel/notify/notify-service'
import { featureSwitchManager } from '../../kernel/switch'
import { openIndependentWindow } from '../../kernel/util/open-window'
import { MotiffVM } from '../../plugin-vm/vm'
import { AILibraryResourceDownloader } from '../../ui/component/component-style-library-v2/library-service/ai-library-resource-downloader'
import { LocalStorageKey } from '../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../web-storage/local-storage/storage'
import {
    GetAIRecognizeResult,
    GetAiComponentDataRequest,
    GetAiComponentDataValidate,
    GetExtractionDownloadUrl,
} from '../ai-recognize/requests'
import { ColorProfileService } from '../service/color-profile-service'
import { featureGuideService } from '../service/feature-guide/feature-guide-service'
import { translation } from './index.translation'
import {
    AICopilotV2Request,
    AICopilotV2TextPositionRequest,
    AICopilotV2ValidateRequest,
    CallAIAlignRequest,
    CallAICreateAutolayoutRequest,
    CallAILayoutRequest,
    CallAiDuplicateRequest,
    DecreaceUserAiCreditsRequest,
    GetUserAiCreditsRequest,
} from './requests'
import {
    AIComponentReplaceSnapshot,
    AICopilotParam,
    AICopilotV2,
    AiRecognizeResult,
    AiScene,
    CallAILayoutRequestParam,
    Cluster,
    ComponentCandidate,
    TooltipData,
    UserAiSceneCreditsVO,
} from './typings'

export class AiService {
    // new zustand store
    private zustandStore = createImmerStore<{
        currentComponentCandidate: ComponentCandidate | null
        isComponentCandidateDetailModalOpen: boolean
        currentTooltipData: TooltipData | null
        aiToolBoxCredits: UserAiSceneCreditsVO
        aiDesignSystemCredits: UserAiSceneCreditsVO
        aiLabCredits: UserAiSceneCreditsVO
        enableMagicCopilot: boolean
        showAiDuplicateReferencesModal: boolean
        showAIGenUIModal: {
            initPrompt?: string
            image?: string
            styleConfigID?: number
            styleConfigName?: string
            imageMetadata?: { type: string; name: string; size: number }
        } | null
        showAIGenPrototypeModal: boolean
        aiGenPrototypeHtml: string
        aiGenPrototypeLoading: boolean
        showAiSearchModal: boolean
    }>(() => ({
        currentComponentCandidate: null,
        isComponentCandidateDetailModalOpen: false,
        currentTooltipData: null,
        aiToolBoxCredits: {
            aiScene: AiScene.ToolBox,
            userCredits: 500,
            limitation: 500,
        },
        aiDesignSystemCredits: {
            aiScene: AiScene.DesignSystem,
            userCredits: 10,
            limitation: 10,
        },
        aiLabCredits: {
            aiScene: AiScene.LAB,
            userCredits: 50,
            limitation: 50,
        },
        enableMagicCopilot: false,
        showAiDuplicateReferencesModal: false,
        showAIGenUIModal: null,
        showAIGenPrototypeModal: false,
        aiGenPrototypeHtml: '',
        aiGenPrototypeLoading: false,
        showAiSearchModal: false,
    }))
    public useZustandStore = createSelectors(this.zustandStore)

    private aiRecognizeResult?: Promise<void>
    private aiComponentMap: Map<string, ComponentCandidate> = new Map()
    private libraryResourceDownloader: AILibraryResourceDownloader | null = null
    private styleThumbnailCache: Map<string, Wukong.DocumentProto.IGenerateCandidateComponentThumbnailRet> = new Map()
    public openBySwitchToAiDuplicate = false

    private aiCreditToastKey: string | undefined
    private cancelPropertyChangeSubscription?: () => void

    async fetchUserAiCredits() {
        const credits = await new GetUserAiCreditsRequest().start()
        this.zustandStore.setState((store) => {
            store.aiToolBoxCredits = credits.sceneCredits.TOOLBOX
            store.aiDesignSystemCredits = credits.sceneCredits.DESIGN_SYSTEM
            store.aiLabCredits = credits.sceneCredits.LAB
        })
    }

    decreaseAiToolBoxCredits(sendRequest = false) {
        const aiToolBoxCredits = this.zustandStore.getState().aiToolBoxCredits
        this.zustandStore.setState((store) => {
            store.aiToolBoxCredits = {
                ...aiToolBoxCredits,
                userCredits: aiToolBoxCredits.userCredits - 1,
            }
        })
        if (sendRequest) {
            new DecreaceUserAiCreditsRequest(AiScene.ToolBox).start()
        }
    }

    decreaseAiDesignSystemCredits(sendRequest = false) {
        const aiDesignSystemCredits = this.zustandStore.getState().aiDesignSystemCredits
        this.zustandStore.setState((store) => {
            store.aiDesignSystemCredits = {
                ...aiDesignSystemCredits,
                userCredits: aiDesignSystemCredits.userCredits - 1,
            }
        })
        if (sendRequest) {
            new DecreaceUserAiCreditsRequest(AiScene.DesignSystem).start()
        }
    }

    decreaseAiLabCredits(sendRequest = false) {
        const aiLabCredits = this.zustandStore.getState().aiLabCredits
        this.zustandStore.setState((store) => {
            store.aiLabCredits = {
                ...aiLabCredits,
                userCredits: aiLabCredits.userCredits - 1,
            }
        })
        if (sendRequest) {
            new DecreaceUserAiCreditsRequest(AiScene.LAB).start()
        }
    }

    async fetchAiRecognizeResult() {
        const extractionResult = await new GetExtractionDownloadUrl(this.docId).start()
        const resultUrl = extractionResult.aiExtractResultUrl ?? extractionResult.classificationResultUrl
        if (!resultUrl) {
            return
        }

        const handleCluster = (cluster: Cluster) => {
            cluster.componentCandidates.forEach((candidate) => {
                this.aiComponentMap.set(candidate.id, candidate)
            })
        }

        try {
            const aiRecognizeResult = await new GetAIRecognizeResult(resultUrl).start()
            let aiResult: AiRecognizeResult
            if (aiRecognizeResult.aiResult) {
                aiResult = aiRecognizeResult.aiResult
            } else {
                aiResult = aiRecognizeResult
            }
            aiResult.pages.forEach((page) => {
                page.clusters.forEach(handleCluster)
            })
            aiResult.colorPage?.gradientColorClusters?.forEach(handleCluster)
            aiResult.colorPage?.pureColorClusters?.forEach(handleCluster)
            aiResult.effectPage?.backgroundBlurCluster && handleCluster(aiResult.effectPage?.backgroundBlurCluster)
            aiResult.effectPage?.dropShadowCluster && handleCluster(aiResult.effectPage?.dropShadowCluster)
            aiResult.effectPage?.innerShadowCluster && handleCluster(aiResult.effectPage?.innerShadowCluster)
            aiResult.effectPage?.layerBlurCluster && handleCluster(aiResult.effectPage?.layerBlurCluster)
            aiResult.iconPage?.clusters?.forEach(handleCluster)
            aiResult.textPage?.clusters?.forEach(handleCluster)
        } catch (_) {
            // noop
        }
    }

    async openComponentDetailPage(url: string) {
        const regex = /componentId=([^&]*)/
        const match = regex.exec(url)

        if (match) {
            const componentId = match[1]
            this.openComponentCandidate(componentId)
        }
    }

    async tryFetchSingleComponentData(componentId: string) {
        try {
            return await new GetAiComponentDataRequest(componentId).start()
        } catch (_) {
            return undefined
        }
    }

    setShowAiDuplicateReferencesModal(show: boolean, openBySwitchToAiDuplicate = false) {
        this.zustandStore.setState((store) => {
            store.showAiDuplicateReferencesModal = show
        })
        this.openBySwitchToAiDuplicate = openBySwitchToAiDuplicate
    }

    async openAIGenPrototypeModal(show: boolean, canvasRenderBridge: CanvasRenderBridge) {
        this.zustandStore.setState((store) => {
            store.showAIGenPrototypeModal = show
            store.aiGenPrototypeLoading = show
        })
        const nodeIds = motiff.currentPage.selection.map((node) => node.id)
        this.commandInvoker.invokeBridge(CommitType.Noop, PrepareExportImageCommand)
        const images = await Promise.all(
            nodeIds.map(async (node) => {
                const { id } = this.commandInvoker.invokeBridge(CommitType.Noop, ExportImageToCanvas, {
                    nodeIds: [node],
                    imageFormat: Wukong.DocumentProto.ImageFormat.IMAGE_FORMAT_PNG,
                    isCompress: false,
                    forceClip: true,
                    constraint: {
                        type: Wukong.DocumentProto.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_SCALE,
                        value: 2,
                    },
                    colorProfile: this.colorProfileService.states.use.colorProfileState(),
                })
                if (!id) {
                    return null
                }

                const image = await canvasRenderBridge.fetchImageBlob(id)
                if (image === null) {
                    return null
                }
                return [node, image] as const
            })
        )
        const maybeJobs = images.filter((v) => v !== null) as unknown as [string, Blob][]
        const jobs = maybeJobs.map(async ([node, image]) => {
            const { data } = this.commandInvoker.invokeBridge(CommitType.Noop, ExportToHTML, {
                nodeIds: [node],
                enableFlex: true,
                withJpg: false,
                extraMetaInfoKeys: [],
                extraMetaInfoValues: [],
                withVariants: false,
            })

            const urlRes = await fetch(
                'https://wukong.yuanfudao.biz/wukong-api/api/admin/ai/ai-gen/upload-auth?format=png',
                {
                    method: 'get',
                }
            )
            const { url, contentType, resourceId } = await urlRes.json()
            const uploadRes = await fetch(url, {
                method: 'put',
                headers: {
                    'Content-Type': contentType,
                },
                body: image,
            })
            await uploadRes.text()
            const img_url = `https://motiff-dev.fbcontent.cn/private/resource/image/${resourceId}`
            return { html: data, img_url }
        })
        const data = await Promise.all(jobs)
        const res = await fetch('https://motiff-ai-gen.yuanfudao.biz/api/prototype/gen', {
            method: 'post',
            body: JSON.stringify({
                data,
            }),
        })
        const renderedHtml = await res.text()
        this.zustandStore.setState((store) => {
            store.aiGenPrototypeHtml = renderedHtml
            store.aiGenPrototypeLoading = false
        })
    }

    closeAIGenPrototypeModal() {
        this.zustandStore.setState((store) => {
            store.showAIGenPrototypeModal = false
        })
    }

    openAIGenUIModal(
        initPrompt?: string,
        landingPageOption?: {
            styleConfigID?: number
            styleConfigName?: string
            image?: string
            imageMetadata?: {
                type: string
                name: string
                size: number
            }
        }
    ) {
        this.zustandStore.setState({
            showAIGenUIModal: {
                initPrompt,
                styleConfigID: landingPageOption?.styleConfigID,
                styleConfigName: landingPageOption?.styleConfigName,
                image: landingPageOption?.image,
                imageMetadata: landingPageOption?.imageMetadata,
            },
        })
    }

    closeAIGenUIModal() {
        this.zustandStore.setState((store) => {
            store.showAIGenUIModal = null
        })
    }

    async openComponentCandidate(id: string) {
        this.zustandStore.setState((store) => {
            store.isComponentCandidateDetailModalOpen = true
        })
        const ret: ComponentCandidate | undefined = await this.tryFetchSingleComponentData(id)
        if (ret) {
            this.zustandStore.setState((store) => {
                store.currentComponentCandidate = ret
            })
        } else {
            if (!this.aiRecognizeResult) {
                this.aiRecognizeResult = this.fetchAiRecognizeResult()
            }
            await this.aiRecognizeResult

            if (this.aiComponentMap.has(id)) {
                this.zustandStore.setState((store) => {
                    store.currentComponentCandidate = this.aiComponentMap.get(id) ?? null
                })
            }
        }
    }

    async getAiComponentData(id: string) {
        const ret: ComponentCandidate | undefined = await this.tryFetchSingleComponentData(id)
        if (ret) {
            return ret
        }

        if (!this.aiRecognizeResult) {
            this.aiRecognizeResult = this.fetchAiRecognizeResult()
        }
        await this.aiRecognizeResult

        return this.aiComponentMap.get(id)
    }

    generateCandidateComponentThumbnail(id: string) {
        const res = this.commandInvoker.DEPRECATED_invokeBridge(GenerateCandidateComponentThumbnailCommand, {
            id: id,
        })
        return {
            buffer: res.thumbnail.buffer ?? '',
            showGrid: res.showGrid,
            effectStyleIconType: res.thumbnail.effectStyleIconType ?? undefined,
            name: res.name,
        }
    }

    closeComponentCandidate() {
        this.zustandStore.setState((store) => {
            store.isComponentCandidateDetailModalOpen = false
            store.currentComponentCandidate = null
        })
    }

    tryFixAICandidateData() {
        new GetAiComponentDataValidate(this.docId)
            .start()
            .then((res) => {
                if (res && !res.validated) {
                    this.commandInvoker.DEPRECATED_invokeBridge(CleanAIRecognizeDataCommand)
                }
            })
            .catch(() => {})
    }

    constructor(
        private readonly signal: TraceableAbortSignal,
        private bridge: Bridge,
        private commandInvoker: CommandInvoker,
        private readonly networkService: NetworkService,
        private readonly colorProfileService: ColorProfileService,
        private docId: string,
        private docReadonly: boolean,
        private orgId: string,
        private notifyService: NotifyService
    ) {
        this.bindJSCall()
        ;(window as any).aiService = this
        this.requestValidateCopilotStatus()

        this.fetchUserAiCredits()

        notifyService.onUserPropertyChangeWithSignal(this.signal, this.orgId, (data) => {
            if (data.changedProperties.aiScene) {
                this.fetchUserAiCredits()
            }
        })

        this.aiGenLanding()
    }

    aiGenLanding() {
        const storedPrompt = enhancedLocalStorage.getSerializedItem(LocalStorageKey.LandingPagePrompt)
        enhancedLocalStorage.removeItem(LocalStorageKey.LandingPagePrompt)
        if (featureSwitchManager.isEnabled('ai-gen-config-platform-and-design-system')) {
            const landingPageOption = enhancedLocalStorage.getSerializedItem(LocalStorageKey.AIGenWebSiteConfig)
            if (landingPageOption || storedPrompt) {
                enhancedLocalStorage.removeItem(LocalStorageKey.AIGenWebSiteConfig)
                this.openAIGenUIModal(storedPrompt ?? undefined, landingPageOption ?? undefined)
            }
        } else {
            if (storedPrompt) {
                this.openAIGenUIModal(storedPrompt)
            }
        }
    }

    handleOpenTooltipJsCall(params: { x: number; y: number }) {
        this.zustandStore.setState((store) => {
            store.currentTooltipData = { show: true, x: params.x, y: params.y }
        })
    }
    handleCloseTooltipJsCall() {
        this.zustandStore.setState((store) => {
            store.currentTooltipData = { show: false, x: 0, y: 0 }
        })
    }

    bindJSCall() {
        this.bridge.bind(CallAiLayout, this.callAiLayout.bind(this))
        this.bridge.bind(EvalJsScriptCommand, this.evalAiScript.bind(this))
        this.bridge.bind(GetImageCdnCommand, () => {
            return {
                value: '',
            }
        })
        this.bridge.bind(CallAiLayoutCommand, this.callAiAlign.bind(this))
        this.bridge.bind(CallAiCreateAutoLayoutCommand, this.callAiCreateAutolayout.bind(this))
        this.bridge.bind(CallAiDuplicateCommand, this.callAiDuplicate.bind(this))
        this.bridge.bind(ShowTooltip, this.handleOpenTooltipJsCall.bind(this))
        this.bridge.bind(HideTooltip, this.handleCloseTooltipJsCall.bind(this))
        this.bridge.bind(CheckIsAIRecognizeDocumentCommand, this.tryFixAICandidateData.bind(this))
        this.bridge.bind(CheckAiCreditsCommand, (param) => {
            return {
                value: this.checkAiCredits(param),
            }
        })
        this.bridge.bind(TrySwitchToAiDuplicateCommand, this.trySwitchToAiDuplicate.bind(this))
    }

    destroy() {
        this.bridge.unbind(CallAiLayout)
        this.bridge.unbind(EvalJsScriptCommand)
        this.bridge.unbind(GetImageCdnCommand)
        this.bridge.unbind(CallAiLayoutCommand)
        this.bridge.unbind(CallAiCreateAutoLayoutCommand)
        this.bridge.unbind(CallAiDuplicateCommand)
        this.bridge.unbind(ShowTooltip)
        this.bridge.unbind(HideTooltip)
        this.bridge.unbind(CheckIsAIRecognizeDocumentCommand)
        this.bridge.unbind(CheckAiCreditsCommand)
        this.bridge.unbind(TrySwitchToAiDuplicateCommand)
        this.libraryResourceDownloader?.destroy()
        this.cancelPropertyChangeSubscription?.()
        ;(window as any).aiService = undefined
    }

    callAiAlign(param: Wukong.DocumentProto.ICallAiAlignRequest) {
        const toastText = translation('gasxPn')
        const selection = window.motiff.currentPage.selection
        const handleFailed = () => {
            WKToast.error(toastText)
            if (selection.length > 1) {
                WKFrog.addFrogRecord({
                    url: '/event/AiAutoLayout/tidyFailedMulti',
                    eventId: 23628,
                    eventAction: 'event',
                    eventName: 'tidyFailedMulti',
                })
            } else {
                WKFrog.addFrogRecord({
                    url: '/event/AiAutoLayout/tidyFailedSingle',
                    eventId: 23626,
                    eventAction: 'event',
                    eventName: 'tidyFailedSingle',
                })
            }
        }
        new CallAIAlignRequest(param)
            .start()
            .then((layoutResult) => {
                const aiResult = layoutResult.aiResult
                if (aiResult?.success) {
                    const responseJsScript = aiResult?.jsScript
                    debugLog('[AILayout Request Result]', responseJsScript)
                    if (responseJsScript) {
                        try {
                            this.evalAiScript(responseJsScript)
                            window.motiff.currentPage.selection = selection
                            window.motiff.commitUndo()
                            if (selection.length > 1) {
                                WKFrog.addFrogRecord({
                                    url: '/event/AiAutoLayout/tidySuccessMulti',
                                    eventId: 23627,
                                    eventAction: 'event',
                                    eventName: 'tidySuccessMulti',
                                })
                            } else {
                                WKFrog.addFrogRecord({
                                    url: '/event/AiAutoLayout/tidySuccessSingle',
                                    eventId: 23625,
                                    eventAction: 'event',
                                    eventName: 'tidySuccessSingle',
                                })
                            }
                        } catch (e: any) {
                            handleFailed()
                            console.error(e.message)
                        }
                    } else {
                        handleFailed()
                        console.error('responseJsScript 为空')
                    }
                } else {
                    handleFailed()
                    console.error('[AILayout Request Error]', aiResult)
                }
            })
            .catch(() => {
                handleFailed()
            })
    }

    callAiCreateAutolayout(param: Wukong.DocumentProto.ICallAiAlignRequest) {
        const toastText = translation('gasxPn')
        const selection = window.motiff.currentPage.selection
        const handleFailed = () => {
            WKToast.error(toastText)
            if (selection.length > 1) {
                WKFrog.addFrogRecord({
                    url: '/event/AiAutoLayout/startFailedMulti',
                    eventId: 23624,
                    eventAction: 'event',
                    eventName: 'startFailedMulti',
                })
            } else {
                WKFrog.addFrogRecord({
                    url: '/event/AiAutoLayout/startFailedSingle',
                    eventId: 23622,
                    eventAction: 'event',
                    eventName: 'startFailedSingle',
                })
            }
        }
        const tid = setTimeout(() => {
            WKToast.show(translation('SmartlyAddingAutolayout'))
        }, 500)
        new CallAICreateAutolayoutRequest(param)
            .start()
            .then((layoutResult) => {
                const aiResult = layoutResult.aiResult
                if (aiResult?.success) {
                    const responseJsScript = aiResult?.jsScript
                    debugLog('[AILayout Request Result]', responseJsScript)
                    if (responseJsScript) {
                        try {
                            this.evalAiScript(responseJsScript)
                            window.motiff.commitUndo()
                            if (selection.length > 1) {
                                WKToast.show(translation('mGYSQl'))
                                WKFrog.addFrogRecord({
                                    url: '/event/AiAutoLayout/startSuccessMulti',
                                    eventId: 23623,
                                    eventAction: 'event',
                                    eventName: 'startSuccessMulti',
                                })
                            } else {
                                WKToast.show(translation('LayerAutolayout', { name: selection[0].name }))
                                WKFrog.addFrogRecord({
                                    url: '/event/AiAutoLayout/startSuccessSingle',
                                    eventId: 23621,
                                    eventAction: 'event',
                                    eventName: 'startSuccessSingle',
                                })
                            }

                            this.decreaseAiToolBoxCredits()
                        } catch (e: any) {
                            handleFailed()
                            console.error(e.message)
                        }
                    } else {
                        handleFailed()
                        console.error('responseJsScript 为空')
                    }
                } else {
                    handleFailed()
                    console.error('[AILayout Request Error]', aiResult)
                }
            })
            .catch(() => {
                handleFailed()
            })
            .finally(() => {
                clearTimeout(tid)
            })
    }

    aiDuplicateImplv2(param: Wukong.DocumentProto.IAiDuplicateParam) {
        const toastId = WKToast.show(translation('InDuplication'), { duration: -1 })

        const handleFailed = () => {
            WKToast.close(toastId)
            WKToast.error(translation('DuplicateFailed'))
        }
        let selection = [...window.motiff.currentPage.selection]

        Promise.all(
            param.nodeInfos!.map((nodeInfo) => {
                return new CallAICreateAutolayoutRequest({
                    docId: param.docId,
                    rootFrameJson: nodeInfo.nodeJson,
                    nodeIds: [nodeInfo.nodeId!],
                }).start()
            })
        )
            .then((layoutResults) => {
                const nodeIdMap: Record<string, string> = {}
                for (let i = 0; i < layoutResults.length; i++) {
                    const layoutResult = layoutResults[i]
                    const nodeInfo = param.nodeInfos![i]
                    const aiResult = layoutResult.aiResult

                    // AI 可能会重新创建最外层 frame，从而改变了之前选中的待复制的节点，这里记录原节点 id 和 ai 新创建的节点 id 之间的映射关系
                    if (aiResult?.success) {
                        const responseJsScript = aiResult?.jsScript
                        responseJsScript.vars.add_ai_powered = 'true'
                        if (responseJsScript) {
                            try {
                                const newId = this.evalAiScript(responseJsScript).result
                                if (newId) {
                                    nodeIdMap[nodeInfo.nodeId!] = newId as any as string
                                }
                            } catch (e: any) {
                                handleFailed()
                                console.error(e.message)
                            }
                        } else {
                            handleFailed()
                            console.error('responseJsScript 为空')
                        }
                    } else {
                        handleFailed()
                        console.error('[AILayout Request Error]', aiResult)
                    }
                }
                Object.keys(nodeIdMap).forEach((oldId) => {
                    selection = selection.filter((node) => node.id !== oldId)
                    selection.push(window.motiff.getNodeById(nodeIdMap[oldId]) as MotiffApi.SceneNode)
                })
                window.motiff.currentPage.selection = selection
                return nodeIdMap
            })
            .then((nodeIdMap) => {
                Promise.all(
                    param.nodeInfos!.map((nodeInfo) => {
                        return new CallAiDuplicateRequest({
                            docId: param.docId!,
                            duplicateCount: nodeInfo.duplicateCount!,
                            nodeJson: this.commandInvoker.DEPRECATED_invokeBridge(ExportFigJsonCommand, {
                                value: nodeIdMap[nodeInfo.nodeId!] ? nodeIdMap[nodeInfo.nodeId!] : nodeInfo.nodeId,
                            }).value!,
                        }).start()
                    })
                )
                    .catch(() => {
                        this.commandInvoker.DEPRECATED_invokeBridge(ApplyAiDuplicateCommand, {
                            aiDuplicateResults: [],
                            idMap: {},
                        })
                        window.motiff.commitUndo()
                        handleFailed()
                        return undefined
                    })
                    .then((aiResults) => {
                        if (!aiResults) {
                            return
                        }
                        this.commandInvoker.DEPRECATED_invokeBridge(ApplyAiDuplicateCommand, {
                            aiDuplicateResults: aiResults.map((t, index) => {
                                return {
                                    nodeId: nodeIdMap[param.nodeInfos![index].nodeId!]
                                        ? nodeIdMap[param.nodeInfos![index].nodeId!]
                                        : param.nodeInfos![index].nodeId!,
                                    encodedData: JSON.stringify(t),
                                }
                            }),
                            idMap: nodeIdMap,
                        })
                        window.motiff.commitUndo()
                        WKToast.close(toastId)
                        WKToast.show(translation('DuplicateSucceed'))

                        this.decreaseAiToolBoxCredits()
                    })
            })
    }

    callAiDuplicate(param: Wukong.DocumentProto.IAiDuplicateParam) {
        this.aiDuplicateImplv2(param)
    }

    callAiLayout(param: Wukong.DocumentProto.ICallAILayoutRequest) {
        const toastText = translation('gasxPn')
        const handleFailed = () => {
            WKFrog.addFrogRecord({
                url: '/event/AiLayout/startFailed',
                eventId: 20853,
                currentPage: 'AiLayout',
                eventAction: 'event',
                eventName: 'startFailed',
            })
            WKToast.error(toastText)
        }
        const tid = setTimeout(() => {
            WKToast.show(translation('EnablingStructuredTemporarily'))
        }, 500)
        new CallAILayoutRequest(param as CallAILayoutRequestParam)
            .start()
            .then((layoutResult) => {
                const aiResult = layoutResult.aiResult
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
                if (aiResult?.supported === false) {
                    WKToast.show(translation('AILayoutMobileSupportHint'))
                } else if (aiResult?.success) {
                    const responseJsScript = aiResult?.jsScript
                    debugLog('[AILayout Request Result]', responseJsScript)
                    if (responseJsScript) {
                        try {
                            const selection = window.motiff.currentPage.selection
                            this.commandInvoker.DEPRECATED_invokeBridge(RunPluginScriptCommand, {
                                script: responseJsScript.script,
                                vars: responseJsScript.vars,
                            })
                            window.motiff.currentPage.selection = selection
                            this.commandInvoker.DEPRECATED_invokeBridge(EnableAIPoweredCommand, {
                                rootId: aiResult.layoutFrameId ?? param.rootFrameId,
                            })
                            window.motiff.commitUndo()
                            this.decreaseAiToolBoxCredits()
                        } catch (e: any) {
                            handleFailed()
                            console.error(e.message)
                        }
                    } else {
                        handleFailed()
                        console.error('responseJsScript 为空')
                    }
                } else {
                    handleFailed()
                    console.error('[AILayout Request Error]', aiResult)
                }
            })
            .catch(() => {
                handleFailed()
            })
            .finally(() => {
                clearTimeout(tid)
                this.commandInvoker.DEPRECATED_invokeBridge(UnlockAILayoutCommand)
            })
    }

    async requestAICopilotV2(param: AICopilotParam) {
        const res = await new AICopilotV2Request(param).start()

        try {
            const copilotResult = JSON.parse(res.aiResult) as AICopilotV2.CandidateV2[]

            return {
                aiResult: copilotResult,
                nodes: res.nodes,
                blobs: res.blobs,
                traceId: res.traceId,
            } as AICopilotV2.Response
        } catch {
            console.error('[AI] copilot v2 ', res)
        }
    }

    async requestAICopilotTextPosition(param: AICopilotV2.TextPositionParam) {
        const res = await new AICopilotV2TextPositionRequest(param).start()

        try {
            const copilotResult = JSON.parse(res.aiResult) as AICopilotV2.TextPositionResponse
            return copilotResult
        } catch {
            console.error('[AI] copilot text position', res)
        }
    }

    // 用于执行 wasm 侧的 js 代码
    evalAiScript(script: Wukong.DocumentProto.IArg_evalJsScript) {
        // 拼接执行上下文
        const varsScript = `const vars = ${JSON.stringify(script.vars)};`
        const code = [varsScript, script.script].join('\n')
        this.commandInvoker.invokeBridge(CommitType.Noop, MarkEvalJsBeginCommand)
        const res = MotiffVM.vm.safeEval(code)
        debugLog('[MotiffVM] eval:\n', code, res)
        this.commandInvoker.invokeBridge(CommitType.Noop, MarkEvalJsEndCommand)
        return {
            result: typeof res.handle === 'string' ? res.handle : JSON.stringify(res.handle),
            success: res.success,
            error: res.error?.message ?? '',
        }
    }

    async requestValidateCopilotStatus() {
        new AICopilotV2ValidateRequest(this.docId)
            .start()
            .then((res) => {
                debugLog('[Magic] 是否开启', res)
                this.zustandStore.setState((store) => {
                    store.enableMagicCopilot = res.open
                })
            })
            .catch(() => {
                debugLog('[Magic] 获取开启状态失败')
            })
    }

    getLibraryResourceDownloader() {
        if (!this.libraryResourceDownloader) {
            this.libraryResourceDownloader = new AILibraryResourceDownloader(this.networkService, this.signal)
        }
        return this.libraryResourceDownloader
    }

    async getStyleThumbnail(item: AIComponentReplaceSnapshot) {
        const key = item.nodeProtoDataResourceId + '-' + item.nodeId
        if (this.styleThumbnailCache.has(key)) {
            return this.styleThumbnailCache.get(key)
        }
        //  获取替换目标组件数据
        const libraryResponse = await this.getLibraryResourceDownloader().fetchFile(item.nodeProtoDataResourceId)
        if (!libraryResponse) {
            return
        }
        const protoArr = new Uint8Array(await libraryResponse.arrayBuffer())
        const exportedDocument = Wukong.DocumentProto.SerializedExportedDocument.decode(protoArr)

        const res = this.commandInvoker.DEPRECATED_invokeBridge(GenerateStyleCandidateComponentThumbnailCommand, {
            exportedDocument,
            toCreateNodeId: item.nodeId,
        })
        this.styleThumbnailCache.set(key, res)
        return res
    }

    isAiToolBoxEnabled() {
        return this.zustandStore.getState().aiToolBoxCredits.userCredits > 0
    }

    isAiLabEnabled() {
        return this.zustandStore.getState().aiLabCredits.userCredits > 0
    }

    isAiDesignSystemEnabled() {
        return this.zustandStore.getState().aiDesignSystemCredits.userCredits > 0
    }

    private showAiCreditToast(str: string) {
        if (this.aiCreditToastKey) {
            WKToast.close(this.aiCreditToastKey)
        }
        this.aiCreditToastKey = WKToast.show(str, {
            duration: -1,
            firstButton: { type: 'x' },
            secondButton: {
                type: 'button',
                text: translation('LearnMore'),
                onClick: () => {
                    openIndependentWindow(
                        environment.isAbroad
                            ? 'https://motiff.com/help/docs/sections/232928398489604'
                            : 'https://motiff.cn/help/docs/sections/232982865729549'
                    )
                },
            },
        })
    }

    tryUseAiToolBox() {
        const enable = this.isAiToolBoxEnabled()
        if (!enable) {
            this.showAiCreditToast(translation('ToolBoxToast'))
        }
        return enable
    }

    tryUseAiDesignSystem() {
        const enable = this.isAiDesignSystemEnabled()
        if (!enable) {
            this.showAiCreditToast(translation('AiDSToast'))
        }
        return enable
    }

    tryUseAiLab() {
        const enable = this.isAiLabEnabled()
        if (!enable) {
            this.showAiCreditToast(translation('AiLabToast'))
        }
        return enable
    }

    async trySwitchToAiDuplicate() {
        if (this.tryUseAiToolBox()) {
            setTimeout(() => {
                this.commandInvoker.invoke(cmdSwitchEditorMode, EditorMode.EM_AiDuplicate)
                featureGuideService.tryStart('aiDuplicate')
            })
        }
    }

    trySwitchToAiCopilot() {
        if (this.tryUseAiLab()) {
            this.commandInvoker.invoke(cmdSwitchEditorMode, EditorMode.EM_Copilot)
        }
    }

    tryEnterAiLayout() {
        if (this.tryUseAiToolBox()) {
            this.commandInvoker.DEPRECATED_invokeBridge(EnterAILayoutCommand)
        }
    }

    trySmartAddAutoLayout() {
        if (this.tryUseAiToolBox()) {
            this.commandInvoker.DEPRECATED_invokeBridge(AiCreateAutoLayoutCommand)
        }
    }

    tryEnterAiSearch() {
        if (this.tryUseAiToolBox()) {
            this.zustandStore.setState((store) => {
                store.showAiSearchModal = true
            })
        }
    }

    exitAiSearch() {
        this.zustandStore.setState((store) => {
            store.showAiSearchModal = false
        })
    }

    checkAiCredits(param: Wukong.DocumentProto.IAiCheckCreditsParam) {
        if (param.aiScene === Wukong.DocumentProto.AiScene.AI_SCENE_AI_TOOL_BOX) {
            return this.tryUseAiToolBox()
        } else if (param.aiScene === Wukong.DocumentProto.AiScene.AI_SCENE_AI_DESIGN_SYSTEM) {
            return this.tryUseAiDesignSystem()
        } else if (param.aiScene === Wukong.DocumentProto.AiScene.AI_SCENE_AI_LAB) {
            return this.tryUseAiLab()
        }
        return false
    }
}
