import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    CancelAccount: '注销账号',
    Cancel: '注销',
    CancelAccountDesc: '注销你的 Motiff 妙多账号，相关数据将被永久删除',
    Organization: '企业版',
    Professional: '专业版',
    Enterprise: '高级企业版',
    UnableToDeleteAccount: '无法注销账号',
    UnableToDeleteAccountDesc: '你是以下专业版团队或企业中的唯一管理员，注销账号前，请先转交或删除以下资产。',
    member: '成员',
    Ok: '我知道了',
} as const

export const enTranslation = {
    CancelAccount: 'Delete account',
    Cancel: 'Delete',
    CancelAccountDesc: 'Permanently delete your account and related data.',
    Organization: 'Organization',
    Professional: 'Professional',
    Enterprise: 'Enterprise',
    UnableToDeleteAccount: 'Unable to delete account',
    UnableToDeleteAccountDesc:
        'You are the only admin of the following team or organization. Before deleting your account, you must transfer or delete the assests listed below.',
    member: 'member',
    Ok: 'OK',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
