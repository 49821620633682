// eslint-disable-next-line import/named
import { mat3, ReadonlyMat3 } from 'gl-matrix'
import { Rect, Transform, Vector } from '../node/node'
import { toFixFloat } from './number'
import { getRectVertices } from './rect'

export function makeTransform(x: number, y: number, rad = 0): Transform {
    return new Matrix().rotate(rad).translateX(x).translateY(y).valueOf()
}

export interface CompositeTransform {
    scaleX: number
    scaleY: number
    translateX: number
    translateY: number
    rotation: number
    skew: number
    flip: boolean
}

export function decomposeTransform(transform: Transform): CompositeTransform {
    const scaleX = Math.sqrt(transform.scaleX * transform.scaleX + transform.skewY * transform.skewY)
    const translateX = transform.translateX
    const translateY = transform.translateY
    let scaleY, skew, flip, sinR, cosR

    if (scaleX === 0) {
        sinR = 0
        cosR = 1
    } else {
        sinR = transform.skewY / scaleX
        cosR = transform.scaleX / scaleX
    }

    const rotation = Math.atan2(sinR, cosR)

    const sySkew = transform.scaleY * cosR - transform.skewX * sinR

    if (sySkew === 0) {
        skew = Math.PI / 2
        if (cosR === 0) {
            flip = !(transform.scaleY >= 0 && sinR >= 0)
            scaleY = Math.sqrt(transform.scaleY * transform.scaleY + transform.skewX * transform.skewX) / Math.abs(sinR)
        } else {
            flip = false
            scaleY = transform.skewX / cosR
        }
    } else {
        skew = Math.atan((transform.scaleY * sinR + transform.skewX * cosR) / sySkew)
        flip = sySkew < 0
        scaleY = Math.abs(sySkew) / Math.cos(skew)
    }
    return {
        scaleX,
        scaleY,
        translateX,
        translateY,
        rotation,
        skew,
        flip,
    }
}

export function composeTransform(compositeTransform: CompositeTransform): Transform {
    const cosRo = Math.cos(compositeTransform.rotation)
    const sinRo = Math.sin(compositeTransform.rotation)
    const cosSk = Math.cos(compositeTransform.skew)
    const sinSk = Math.sin(compositeTransform.skew)
    const flip = compositeTransform.flip ? -1 : 1

    const scaleX = compositeTransform.scaleX * cosRo
    const scaleY = flip * compositeTransform.scaleY * (sinRo * sinSk + cosRo * cosSk)
    const translateX = compositeTransform.translateX
    const translateY = compositeTransform.translateY
    const skewY = compositeTransform.scaleX * sinRo
    const skewX = flip * compositeTransform.scaleY * (cosRo * sinSk - sinRo * cosSk)

    return { scaleX, scaleY, translateX, translateY, skewX, skewY }
}

/**
 * 将 mat3 结构转化为 Transform 结构
 * @param m
 * @returns
 */
function getTransformFromMat3(m: mat3) {
    return {
        scaleX: m[0],
        skewX: m[1],
        translateX: m[2],
        skewY: m[3],
        scaleY: m[4],
        translateY: m[5],
    }
}

/**
 * 将 Transform 结构转化为 mat3 结构
 * @param t
 * @returns
 */
function getMat3FromTransform(t: Transform): ReadonlyMat3 {
    return [t.scaleX, t.skewX, t.translateX, t.skewY, t.scaleY, t.translateY, 0, 0, 1]
}

/**
 * 创建 Transform 结构下的单位矩阵
 */
export function createIdentityTransform(): Readonly<Transform> {
    return {
        scaleX: 1,
        scaleY: 1,
        translateX: 0,
        translateY: 0,
        skewX: 0,
        skewY: 0,
    }
}

/**
 * 创建 mat3 结构下的的单位矩阵
 * @returns
 */
export function createIdentityMat3Array(): mat3 {
    return [1, 0, 0, 0, 1, 0, 0, 0, 1]
}

/**
 * 矩阵包含以下结构的数据
 *
 * | scaleX , skewX  , translateX |
 * | skewY  , scaleY , translateY |
 * | 0      , 0      , 1          |
 *
 * 矩阵左乘是行变换
 * 矩阵右乘是列变换
 *
 * 左乘是在相同参考系（ 坐标系 ）下对矩阵或向量进行变换后得到的新坐标
 * 右乘是在矩阵或向量不动的情况下对参考系（ 坐标系 ）进行变换后得到的新坐标
 *
 * 假如要用矩阵变换一个向量（ 任何二维中的图形都可以用若干向量来描述其位置 ），则需要左乘一个变换矩阵
 * 例如对于向量 (dx, dy)，则得到的新向量是 (scaleX * dx + skewX * y + translateX, skewY * dx + scaleY * y + translateY)
 * 因为有如下的计算过程：
 * | scaleX , skewX  , translateX |   | dx |   | scaleX * dx + skewX * y  + translateX |
 * | skewY  , scaleY , translateY | * | dy | = | skewY * dx  + scaleY * y + translateY |
 * | 0      , 0      , 1          |   | 1  |   | 1                                     |
 *
 * 矩阵的变化和向量的变换类似
 *
 * 对应 Skia 提供的 SkMatrix 的接口
 * matrix.postConcat(other)，语义上是用 other 对 matrix 进行变化，计算上是用 matrix 左乘 other，即 res = other * matrix
 * matrix.preConcat(other)，语义上是用 other 改变 matrix 所在的坐标系，计算上是用 matrix 右乘 other，即 res = matrix * other
 *
 * 为了证明这个理解正确，粘贴一段 Skia 的函数签名：

        Sets SkMatrix to SkMatrix other multiplied by SkMatrix.
        This can be thought of mapping by other after applying SkMatrix.

        Given:

                     | J K L |           | A B C |
            Matrix = | M N O |,  other = | D E F |
                     | P Q R |           | G H I |

        This will set SkMatrix to:

                             | A B C |   | J K L |   | AJ+BM+CP AK+BN+CQ AL+BO+CR |
            other * Matrix = | D E F | * | M N O | = | DJ+EM+FP DK+EN+FQ DL+EO+FR |
                             | G H I |   | P Q R |   | GJ+HM+IP GK+HN+IQ GL+HO+IR |

        @param other  SkMatrix on left side of multiply expression
        SkMatrix& postConcat(const SkMatrix& other);

 * 在 JS 中我们借助 gl-mat3 这个库来实现矩阵乘法
 * 其接口 multiply 的函数签名可以看出 multiply(out, a, b) 计算上是 b * a（ 源码链接 @link https://github.com/toji/gl-matrix/blob/abb59a175f5c4b4020d3c18b830825fe865570f0/src/mat3.js ）
 * 因此如果要实现 a.postConcat(b) 则调用应为 multiply(out, a, b)
 */
export class Matrix {
    protected transform: Transform

    constructor(transform?: Transform) {
        if (transform) {
            this.transform = {
                scaleX: transform.scaleX,
                scaleY: transform.scaleY,
                translateX: transform.translateX,
                translateY: transform.translateY,
                skewX: transform.skewX,
                skewY: transform.skewY,
            }
        } else {
            this.transform = createIdentityTransform()
        }
    }

    /**
     * 获得矩阵的 Transform 数据，注意是数据拷贝，不会影响矩阵本身
     * @returns
     */
    valueOf(): Transform {
        return {
            translateX: toFixFloat(this.transform.translateX),
            translateY: toFixFloat(this.transform.translateY),
            scaleX: toFixFloat(this.transform.scaleX),
            scaleY: toFixFloat(this.transform.scaleY),
            skewX: toFixFloat(this.transform.skewX),
            skewY: toFixFloat(this.transform.skewY),
        }
    }

    /**
     * 获得矩阵变换在 X 轴上的偏移距离
     * @returns
     */
    getTranslateX(): number {
        return this.transform.translateX
    }

    /**
     * 获得矩阵变换在 Y 轴上的偏移距离
     * @returns
     */
    getTranslateY(): number {
        return this.transform.translateY
    }

    /**
     * 获得矩阵变换在 X 轴上的缩放倍数
     * @returns
     */
    getScaleX() {
        return this.transform.scaleX
    }

    /**
     * 获得矩阵变换在 Y 轴上的缩放倍数
     * @returns
     */
    getScaleY() {
        return this.transform.scaleY
    }

    /**
     * 获得矩阵变换在 X 轴上的倾斜弧度
     * @returns
     */
    getSkewX(): number {
        return this.transform.skewX
    }

    /**
     * 获得矩阵变换在 Y 轴上的倾斜弧度
     * @returns
     */
    getSkewY(): number {
        return this.transform.skewY
    }

    /**
     * 获得矩阵变换的旋转弧度
     * @returns
     */
    getRotation(): number {
        return decomposeTransform(this.transform).rotation
    }

    /**
     * 判断矩阵变换是否做了翻转，包含沿 X 或 Y 轴
     * @returns
     */
    isFlipped(): boolean {
        return decomposeTransform(this.transform).flip
    }

    /**
     * 将矩阵变换在 X 轴上移动一定距离
     * @param x
     * @returns
     */
    translateX(x: number): Matrix {
        const decomposite = decomposeTransform(this.transform)
        decomposite.translateX += x
        this.transform = composeTransform(decomposite)
        return this
    }

    /**
     * 将矩阵变换在 Y 轴上移动一定距离
     * @param y
     * @returns
     */
    translateY(y: number): Matrix {
        const decomposite = decomposeTransform(this.transform)
        decomposite.translateY += y
        this.transform = composeTransform(decomposite)
        return this
    }

    /**
     * 将矩阵变换沿顺时针旋转一定弧度
     * @param rad
     * @returns
     */
    rotate(rad: number): Matrix {
        const res = createIdentityMat3Array()
        mat3.rotate(res, getMat3FromTransform(this.transform), -rad) // mat3.rotate 默认的是逆时针
        this.transform = getTransformFromMat3(res)
        return this
    }

    /**
     * 将矩阵变换在 X 轴方向上倾斜给定的弧度
     * @param rad
     * @returns
     */
    setSkewX(rad: number): Matrix {
        const res = createIdentityMat3Array()
        mat3.multiply(res, getMat3FromTransform(this.transform), [1, 0, 0, 0, Math.cos(rad), 0, 0, 0, 1])
        mat3.multiply(res, res, [1, Math.tan(rad), 0, 0, 1, 0, 0, 0, 1])
        this.transform = getTransformFromMat3(res)
        return this
    }

    /**
     * 将矩阵变换在 Y 轴方向上倾斜给定的弧度
     * @param rad
     * @returns
     */
    setSkewY(rad: number): Matrix {
        const res = createIdentityMat3Array()
        mat3.multiply(res, getMat3FromTransform(this.transform), [Math.cos(rad), 0, 0, 0, 1, 0, 0, 0, 1])
        mat3.multiply(res, res, [1, 0, 0, Math.tan(rad), 1, 0, 0, 0, 1])
        this.transform = getTransformFromMat3(res)
        return this
    }

    /**
     * 将矩阵变换沿 X 轴翻转，即 X 轴坐标不变，Y 轴坐标取反
     * @returns
     */
    flipX(): Matrix {
        const res = createIdentityMat3Array()
        mat3.multiply(res, getMat3FromTransform(this.transform), [1, 0, 0, 0, -1, 0, 0, 0, 1])
        this.transform = getTransformFromMat3(res)
        return this
    }

    /**
     * 将矩阵变换沿 Y 轴翻转，即 Y 轴坐标不变，X 轴坐标取反
     * @returns
     */
    flipY(): Matrix {
        const res = createIdentityMat3Array()
        mat3.multiply(res, getMat3FromTransform(this.transform), [-1, 0, 0, 0, 1, 0, 0, 0, 1])
        this.transform = getTransformFromMat3(res)
        return this
    }

    /**
     * 将矩阵进行求逆操作
     * @returns
     */
    invert(): Matrix {
        const res = createIdentityMat3Array()
        mat3.invert(res, getMat3FromTransform(this.transform))
        this.transform = getTransformFromMat3(res)
        return this
    }

    /**
     * 将矩阵左乘一个给定的矩阵
     * @param matrix
     * @returns
     */
    preConcat(matrix: Matrix): Matrix {
        const res = createIdentityMat3Array()
        mat3.multiply(res, getMat3FromTransform(matrix.valueOf()), getMat3FromTransform(this.transform))
        this.transform = getTransformFromMat3(res)
        return this
    }

    /**
     * 将矩阵右乘一个给定的矩阵
     * @param matrix
     * @returns
     */
    postConcat(matrix: Matrix): Matrix {
        const res = createIdentityMat3Array()
        mat3.multiply(res, getMat3FromTransform(this.transform), getMat3FromTransform(matrix.valueOf()))
        this.transform = getTransformFromMat3(res)
        return this
    }

    /**
     * 用矩阵变换一个 vector 向量（ 变换点没有意义 ），得到新的向量
     * @param vector
     * @returns
     */
    mapVector(vector: Readonly<Vector>): Vector {
        const { scaleX, scaleY, translateX, translateY, skewX, skewY } = this.transform
        const { x, y } = vector
        const cX = scaleX * x + skewX * y + translateX
        const cY = skewY * x + scaleY * y + translateY
        return { x: toFixFloat(cX), y: toFixFloat(cY) }
    }

    /**
     * 用矩阵变换一个 rect 矩形，得到的是该矩形 4 个顶点在变换后的坐标，顺序与原先保持一致，为从左上开始顺时针
     * 特别注意，因为矩阵变换可能导致旋转，所以得到的结果从位置上看并不一定是第一个点在最左上的位置
     * @param rect
     * @returns
     */
    mapRectVertices(rect: Readonly<Rect>): Vector[] {
        const vertices = getRectVertices(rect)
        return vertices.map((vertex) => this.mapVector(vertex))
    }

    /**
     * 用矩阵变换一个 rect 矩形，得到的是 rect 的外接正矩形的 bound
     * 特别注意，使用此方法得到 rect 后，再次对这个 rect 进行变换，与一开始直接使用两次变换的复合矩阵对原 rect 进行变换的结果不同，因为中间结果为外接 rect
     * @param rect
     * @returns
     */
    mapRect(rect: Readonly<Rect>): Rect {
        const verticesAfterMap = this.mapRectVertices(rect)
        const x = Math.min(...verticesAfterMap.map((vertex) => vertex.x))
        const y = Math.min(...verticesAfterMap.map((vertex) => vertex.y))
        const width = Math.max(...verticesAfterMap.map((vertex) => vertex.x)) - x
        const height = Math.max(...verticesAfterMap.map((vertex) => vertex.y)) - y
        return {
            x: toFixFloat(x),
            y: toFixFloat(y),
            width: toFixFloat(width),
            height: toFixFloat(height),
        }
    }
}
