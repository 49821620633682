import { Wukong } from '@wukong/bridge-proto'
import { sleep, type WebGLRenderingContextCompat } from '../../../../util/src'

import { isMacOs, isMobile } from 'react-device-detect'
import { WkCLog } from '../../kernel/clog/wukong/instance'
import { CanvasStateType, type CanvasState } from '../../kernel/service/canvas-types'
import { getChromeVersion } from '../../kernel/util/ua'
import { CanvasInspector } from './canvas-inspector'

export enum DetectResult {
    HasBug = 'HasBug',
    Pass = 'Pass',
    Error = 'Error',
}

function scopeDetector(version: number, f: (ctx: WebGLRenderingContextCompat) => boolean): DetectResult {
    // 这个地方会修改 canvas 属性，故不使用 GlobalCanvas
    let canvas: HTMLCanvasElement | null = null

    try {
        canvas = document.createElement('canvas')
        const ctx = version == 2 ? canvas.getContext('webgl2') : canvas.getContext('webgl')
        if (!ctx) {
            throw Error('cannot get context')
        }

        const detectedResult = f(ctx)
        canvas.remove()
        return detectedResult ? DetectResult.HasBug : DetectResult.Pass
    } catch (e) {
        if (canvas) {
            canvas.remove()
        }
        return DetectResult.Error
    }
}

function unwrapOptional<T>(x: T | null | undefined): T {
    if (x === null || x === undefined) {
        throw Error('nil')
    }
    return x
}

function buildWebGLProgram(
    ctx: WebGLRenderingContextCompat,
    vertexShaderSource: string,
    fragmentShaderSource: string,
    attributes: Array<[number, string]>
): WebGLProgram {
    const program = unwrapOptional(ctx.createProgram())
    const vertexShader = unwrapOptional(ctx.createShader(ctx.VERTEX_SHADER))
    ctx.shaderSource(vertexShader, vertexShaderSource)
    ctx.compileShader(vertexShader)
    ctx.attachShader(program, vertexShader)
    const fragmentShader = unwrapOptional(ctx.createShader(ctx.FRAGMENT_SHADER))
    ctx.shaderSource(fragmentShader, fragmentShaderSource)
    ctx.compileShader(fragmentShader)
    ctx.attachShader(program, fragmentShader)

    attributes.forEach(([loc, name]) => {
        ctx.bindAttribLocation(program, loc, name)
    })
    ctx.linkProgram(program)
    return program
}

function bindAndCreateTexture(ctx: WebGLRenderingContextCompat, width: number, height: number, minMag: number) {
    const texture = unwrapOptional(ctx.createTexture())
    ctx.bindTexture(ctx.TEXTURE_2D, texture)
    ctx.texParameteri(ctx.TEXTURE_2D, ctx.TEXTURE_MAG_FILTER, minMag)
    ctx.texParameteri(ctx.TEXTURE_2D, ctx.TEXTURE_MIN_FILTER, minMag)
    ctx.texParameteri(ctx.TEXTURE_2D, ctx.TEXTURE_WRAP_S, ctx.CLAMP_TO_EDGE)
    ctx.texParameteri(ctx.TEXTURE_2D, ctx.TEXTURE_WRAP_T, ctx.CLAMP_TO_EDGE)
    ctx.bindTexture(ctx.TEXTURE_2D, texture)
    ctx.texImage2D(ctx.TEXTURE_2D, 0, ctx.RGBA, width, height, 0, ctx.RGBA, ctx.UNSIGNED_BYTE, null)
    return texture
}

function detectViewportBugImpl(ctx: WebGLRenderingContextCompat) {
    const solidBlackProgram = buildWebGLProgram(
        ctx,
        `
    precision highp float;
    attribute vec2 pos;
    void main(){
    gl_Position=vec4(pos*2.-1.,0.,1.);
    }
    `,
        `
    precision highp float;
    void main(){
    gl_FragColor=vec4(0., 0., 0., 1.);
    }
    `,
        [[0, 'pos']]
    )

    const glBuffer = ctx.createBuffer()
    ctx.bindBuffer(ctx.ARRAY_BUFFER, glBuffer)
    // uniform vertexs
    const vertexData = new Float32Array([0, 0, 1, 0, 0, 1, 1, 1])
    ctx.bufferData(ctx.ARRAY_BUFFER, vertexData.length * 4, ctx.DYNAMIC_DRAW)
    ctx.bufferSubData(ctx.ARRAY_BUFFER, 0, vertexData)
    ctx.enableVertexAttribArray(0)
    ctx.vertexAttribPointer(0, 2, ctx.FLOAT, false, 8, 0)

    ctx.canvas.width = 10
    ctx.canvas.height = 1

    const frameBuffer = ctx.createFramebuffer()
    ctx.bindFramebuffer(ctx.FRAMEBUFFER, frameBuffer)
    const frameBufferTexture = bindAndCreateTexture(ctx, ctx.canvas.width, ctx.canvas.height, ctx.LINEAR)

    ctx.framebufferTexture2D(ctx.FRAMEBUFFER, ctx.COLOR_ATTACHMENT0, ctx.TEXTURE_2D, frameBufferTexture, 0)
    ctx.useProgram(solidBlackProgram)

    // viewport 1
    ctx.bindFramebuffer(ctx.FRAMEBUFFER, frameBuffer)
    ctx.viewport(0, 0, (ctx.canvas.width / 2) | 0, ctx.canvas.height)
    ctx.drawArrays(ctx.TRIANGLE_STRIP, 0, 4)

    // viewport 2
    ctx.bindFramebuffer(ctx.FRAMEBUFFER, null)
    ctx.viewport(0, 0, (ctx.canvas.width / 2) | 0, ctx.canvas.height)
    ctx.drawArrays(ctx.TRIANGLE_STRIP, 0, 4)

    const buf = new Uint8Array(4)
    ctx.readPixels(((ctx.canvas.width / 2) | 0) + 2, 0, 1, 1, ctx.RGBA, ctx.UNSIGNED_BYTE, buf)
    return buf[3] !== 0
}

function detectDrawClearReorderingBugImpl(ctx: WebGLRenderingContextCompat) {
    ctx.canvas.width = 1
    ctx.canvas.height = 1

    const texture = bindAndCreateTexture(ctx, ctx.canvas.width, ctx.canvas.height, ctx.NEAREST)

    ctx.bindTexture(ctx.TEXTURE_2D, texture)
    const framebuffer = ctx.createFramebuffer()
    ctx.bindFramebuffer(ctx.FRAMEBUFFER, framebuffer)
    ctx.framebufferTexture2D(ctx.FRAMEBUFFER, ctx.COLOR_ATTACHMENT0, ctx.TEXTURE_2D, texture, 0)

    const glBuffer = ctx.createBuffer()
    ctx.bindBuffer(ctx.ARRAY_BUFFER, glBuffer)
    ctx.bufferData(ctx.ARRAY_BUFFER, 32, ctx.DYNAMIC_DRAW)
    ctx.bufferSubData(ctx.ARRAY_BUFFER, 0, new Float32Array([1, 1, 1, -1, -1, 1, -1, -1]))
    ctx.vertexAttribPointer(0, 2, ctx.FLOAT, false, 8, 0)
    ctx.enableVertexAttribArray(0)
    ctx.bindBuffer(ctx.ARRAY_BUFFER, glBuffer)

    const fromTextureProgram = buildWebGLProgram(
        ctx,
        `
    precision highp float;
    attribute vec2 pos;
    varying vec2 uv;
    void main(){
      uv = (pos + vec2(1.0)) / 2.0;
      gl_Position=vec4(pos*2.-1.,0.,1.);
    }
    `,
        `
    precision highp float;
    uniform sampler2D texture;
    varying vec2 uv;
    void main(){
      gl_FragColor=texture2D(texture, uv);
    }
    `,
        [[0, 'pos']]
    )
    const solidBlueProgram = buildWebGLProgram(
        ctx,
        `
    precision highp float;
    attribute vec2 pos;
    void main(){
      gl_Position=vec4(pos,0.,1.);
    }
    `,
        `
    precision highp float;
    void main(){
      gl_FragColor=vec4(0.,0.,1.,1.);
    }
    `,
        [[0, 'pos']]
    )

    ctx.clearColor(1, 0, 0, 1)
    ctx.bindFramebuffer(ctx.FRAMEBUFFER, framebuffer)
    ctx.useProgram(solidBlueProgram)
    ctx.drawArrays(ctx.TRIANGLE_STRIP, 0, 4)

    ctx.bindFramebuffer(ctx.FRAMEBUFFER, null)
    ctx.useProgram(fromTextureProgram)
    ctx.drawArrays(ctx.TRIANGLE_STRIP, 0, 4)

    ctx.bindFramebuffer(ctx.FRAMEBUFFER, framebuffer)
    ctx.clear(ctx.COLOR_BUFFER_BIT)

    ctx.bindFramebuffer(ctx.FRAMEBUFFER, null)
    ctx.useProgram(fromTextureProgram)
    ctx.drawArrays(ctx.TRIANGLE_STRIP, 0, 4)

    const buf = new Uint8Array(4)
    ctx.readPixels(0, 0, 1, 1, ctx.RGBA, ctx.UNSIGNED_BYTE, buf)
    return buf[0] !== 255
}

function hasDrawClearReorderingBug(version: number) {
    const userAgent = navigator.userAgent
    const hasBug = ['Version/15.4 Safari/605.1.15'].some((spec) => userAgent.indexOf(spec) >= 0)
    if (hasBug) {
        return DetectResult.HasBug
    }
    return scopeDetector(version, detectDrawClearReorderingBugImpl)
}

function hasUnexpectedCompositingBug() {
    const userAgent = navigator.userAgent
    const isApple14 = ['iPhone OS 14_0', 'iPhone OS 14_1', 'iPad; CPU OS 14_0', 'iPad; CPU OS 14_1', 'Version/14'].some(
        (spec) => userAgent.indexOf(spec) >= 0
    )
    const isTouch = navigator.maxTouchPoints > 0
    return isApple14 && isTouch
}

function shouldApplyMobileOptimizations() {
    return isMobile || (isMacOs && navigator.maxTouchPoints > 0)
}

export function findWebGLWorkaround(version: number): Wukong.DocumentProto.IGPUWorkaround {
    const viewportBug = scopeDetector(version, detectViewportBugImpl)
    const drawClearReorderingBug = hasDrawClearReorderingBug(version)
    const unexpectedCompositingBug = hasUnexpectedCompositingBug() ? DetectResult.HasBug : DetectResult.Pass
    const applyMobileOptimizations = shouldApplyMobileOptimizations()

    WkCLog.throttleLog(
        'GL_GRAPHIC_BUGS',
        JSON.parse(
            JSON.stringify({ viewportBug, drawClearReorderingBug, unexpectedCompositingBug, applyMobileOptimizations })
        )
    )

    return {
        glViewportBug: viewportBug !== DetectResult.Pass,
        glDrawClearReorderingBug: drawClearReorderingBug !== DetectResult.Pass,
        glUnexpectedCompositingBug: unexpectedCompositingBug !== DetectResult.Pass,
        mobileOptimizations: applyMobileOptimizations,
    }
}

export function findWebGPUWorkaround(): Wukong.DocumentProto.IGPUWorkaround {
    return {
        glViewportBug: false,
        glDrawClearReorderingBug: false,
        glUnexpectedCompositingBug: false,
        mobileOptimizations: shouldApplyMobileOptimizations(),
    }
}

export async function detectWebgpuSyncRead() {
    interface HasBug {
        result: 'HasBug'
        x: number
        y: number
        expected: number
        actual: number
    }

    const size = 8
    function checkEqual(dst: Uint8Array): HasBug | { result: 'Pass' } {
        function checkRegion(x: number, y: number, w: number, h: number, color: number): HasBug | null {
            for (let l = x; l < x + w; l++) {
                for (let t = y; t < y + h; t++) {
                    const index = (t * size + l) * 4
                    const expected = color | 0
                    const actual = (dst[index] << 24) | (dst[index + 1] << 16) | (dst[index + 2] << 8) | dst[index + 3]
                    if (expected !== actual) {
                        return {
                            result: 'HasBug',
                            x: l,
                            y: t,
                            expected,
                            actual,
                        }
                    }
                }
            }
            return null
        }
        if (dst.byteLength !== size * size * 4) {
            return {
                result: 'HasBug',
                x: -1,
                y: -1,
                expected: 0,
                actual: 0,
            }
        }

        return (
            checkRegion(1, 1, 2, 2, 0x22a7f07f) ||
            checkRegion(5, 5, 2, 2, 0xf647473f) ||
            checkRegion(3, 0, 5, 5, 0xff) ||
            checkRegion(0, 3, 5, 5, 0xff) || { result: 'Pass' }
        )
    }
    async function workImpl(): Promise<{ result: 'Invalid' } | HasBug | { result: 'Pass' }> {
        const TEXTURE_HANDLE = 0

        const dst = new Uint8Array(size * size * 4)

        const canvas = document.createElement('canvas')
        canvas.width = size
        canvas.height = size

        const renderer = new WebGPURectRenderer(canvas)
        await renderer.init()
        renderer.drawRects([
            { x: 1, y: 1, width: 2, height: 2, colorHex: 0x22a7f07f },
            { x: 5, y: 5, width: 2, height: 2, colorHex: 0xf647473f },
        ])
        const device = renderer.getDevice()
        const texture = renderer.getTexture()

        const inspector = new CanvasInspector({
            getCanvasState: (): CanvasState | null => {
                return {
                    type: CanvasStateType.WebGPU,
                    canvas: canvas,
                    adapterId: 0,
                    deviceId: 0,
                    queueId: 0,
                    device,
                    findTexture: (textureHandle: number): GPUTexture | null => {
                        return textureHandle === TEXTURE_HANDLE ? texture : null
                    },
                    findBuffer: (): GPUBuffer | null => {
                        return null
                    },
                }
            },
            startSetTimeout: (f: () => void, ms: number): void => {
                setTimeout(f, ms)
            },
            getHeap: (): Uint8Array => dst,
            log: (desc, arg) => WkCLog.throttleLog(`${desc}_DETECT`, arg),
        })

        inspector.readSyncWGPU({
            x: 0,
            y: 0,
            width: size,
            height: size,
            dataOffset: 0,
            textureHandle: TEXTURE_HANDLE,
        })
        return checkEqual(dst)
    }

    try {
        // 保证异步
        await sleep(0)

        const chromeVersion = getChromeVersion()
        if (!chromeVersion || chromeVersion < 130) {
            return
        }

        const glResult = await workImpl().catch(() => {
            return {
                result: 'Error',
            }
        })
        WkCLog.throttleLog('WK_WEBGPU_SYNC_READ', {
            result: glResult.result,
            resultDetail: JSON.stringify(glResult),
            unexpected: false,
            detectVersion: 3,
        })
    } catch (_e) {
        WkCLog.throttleLog('WK_WEBGPU_SYNC_READ', {
            result: 'Error',
            unexpected: true,
            detectVersion: 3,
        })
    }
}

class WebGPURectRenderer {
    private device: GPUDevice | null = null
    private context: GPUCanvasContext | null = null
    private pipeline: GPURenderPipeline | null = null
    private vertexBuffer: GPUBuffer | null = null
    private uniformBuffer: GPUBuffer | null = null
    private uniformBindGroup: GPUBindGroup | null = null

    private rectangles: { x: number; y: number; width: number; height: number; colorHex: number }[] = []
    public initialized = false
    private texture: GPUTexture | null = null

    constructor(private canvas: HTMLCanvasElement) {}

    public async init(): Promise<void> {
        if (!navigator.gpu) {
            throw Error('WebGPU is unsupported')
        }

        const adapter = await navigator.gpu.requestAdapter()
        if (!adapter) {
            throw Error('failed to get adapter')
        }

        this.device = await adapter.requestDevice()
        if (!this.device) {
            throw Error('failed to get device')
        }

        this.context = this.canvas.getContext('webgpu')

        const format = 'rgba8unorm' as const

        this.context!.configure({
            device: this.device,
            format: format,
            alphaMode: 'premultiplied',
        })

        // 创建纹理
        this.texture = this.device.createTexture({
            size: [this.canvas.width, this.canvas.height, 1],
            format,
            usage: GPUTextureUsage.RENDER_ATTACHMENT | GPUTextureUsage.TEXTURE_BINDING | GPUTextureUsage.COPY_SRC,
        })

        // 创建 shader
        const shaderModule = this.device.createShaderModule({
            code: `
          struct VertexOutput {
            @builtin(position) position: vec4f,
            @location(0) color: vec4f,
          };

          struct Uniforms {
            viewportSize: vec2f,
          };

          @group(0) @binding(0) var<uniform> uniforms: Uniforms;

          @vertex
          fn vertexMain(@location(0) position: vec2f,
                        @location(1) color: vec4f) -> VertexOutput {
            var output: VertexOutput;
            // 转换为标准化设备坐标 (-1到1)
            output.position = vec4f(
              position.x / uniforms.viewportSize.x * 2.0 - 1.0,
              1.0 - position.y / uniforms.viewportSize.y * 2.0,
              0.0, 1.0);
            output.color = color;
            return output;
          }

          @fragment
          fn fragmentMain(input: VertexOutput) -> @location(0) vec4f {
            return input.color;
          }
        `,
        })

        // 创建均匀缓冲区 (viewport size)
        this.uniformBuffer = this.device.createBuffer({
            size: 8, // 2个32位浮点数
            usage: GPUBufferUsage.UNIFORM | GPUBufferUsage.COPY_DST,
        })

        // 更新均匀缓冲区
        this.device.queue.writeBuffer(this.uniformBuffer, 0, new Float32Array([this.canvas.width, this.canvas.height]))

        // 创建绑定组布局
        const bindGroupLayout = this.device.createBindGroupLayout({
            entries: [
                {
                    binding: 0,
                    visibility: GPUShaderStage.VERTEX,
                    buffer: { type: 'uniform' },
                },
            ],
        })

        // 创建绑定组
        this.uniformBindGroup = this.device.createBindGroup({
            layout: bindGroupLayout,
            entries: [
                {
                    binding: 0,
                    resource: { buffer: this.uniformBuffer },
                },
            ],
        })

        // 创建渲染管线
        this.pipeline = this.device.createRenderPipeline({
            layout: this.device.createPipelineLayout({
                bindGroupLayouts: [bindGroupLayout],
            }),
            vertex: {
                module: shaderModule,
                entryPoint: 'vertexMain',
                buffers: [
                    {
                        arrayStride: 24, // 2个浮点数(位置) + 4个浮点数(颜色)
                        attributes: [
                            {
                                // 位置
                                shaderLocation: 0,
                                offset: 0,
                                format: 'float32x2',
                            },
                            {
                                // 颜色
                                shaderLocation: 1,
                                offset: 8,
                                format: 'float32x4',
                            },
                        ],
                    },
                ],
            },
            fragment: {
                module: shaderModule,
                entryPoint: 'fragmentMain',
                targets: [{ format }],
            },
            primitive: {
                topology: 'triangle-list',
            },
        })

        // 创建顶点缓冲区
        this.vertexBuffer = this.device.createBuffer({
            size: 6 * 24 * 10, // 每个矩形有6个顶点，每个顶点24字节，预先分配10个矩形的空间
            usage: GPUBufferUsage.VERTEX | GPUBufferUsage.COPY_DST,
        })

        this.initialized = true
    }

    public getDevice() {
        if (!this.device) {
            throw Error('device is null')
        }
        return this.device
    }

    public getTexture() {
        if (!this.device) {
            throw Error('texture is null')
        }
        return this.texture
    }

    // 绘制矩形
    public drawRects(rects: Array<{ x: number; y: number; width: number; height: number; colorHex: number }>): void {
        if (!this.initialized) {
            console.warn('not initialized yet')
            return
        }

        this.rectangles.push(...rects)
        this.render()
    }

    // 渲染所有矩形到纹理
    private render(): void {
        if (!this.initialized || this.rectangles.length === 0) return

        const verticesPerRect = 6 // 每个矩形6个顶点
        const floatsPerVertex = 6 // 每个顶点6个浮点数(2个位置+4个颜色)
        const vertices = new Float32Array(this.rectangles.length * verticesPerRect * floatsPerVertex)

        // 定义矩形的两个三角形的顶点索引偏移 (相对于矩形左上角)
        const triangleVertices: [number, number][] = [
            // 第一个三角形: 左上, 右上, 左下
            [0, 0],
            [1, 0],
            [0, 1],
            // 第二个三角形: 左下, 右上, 右下
            [0, 1],
            [1, 0],
            [1, 1],
        ]

        this.rectangles.forEach((rect, rectIndex) => {
            const { x, y, width, height, colorHex } = rect

            // 转换颜色
            const color = [
                ((colorHex >> 24) & 0xff) / 255,
                ((colorHex >> 16) & 0xff) / 255,
                ((colorHex >> 8) & 0xff) / 255,
                (colorHex & 0xff) / 255,
            ]

            // 计算该矩形在数组中的基础索引
            const baseIndex = rectIndex * verticesPerRect * floatsPerVertex

            // 为矩形的6个顶点填充数据
            for (let i = 0; i < triangleVertices.length; i++) {
                const vertexIndex = baseIndex + i * floatsPerVertex
                const [xOffset, yOffset] = triangleVertices[i]

                // 位置坐标
                vertices[vertexIndex] = x + xOffset * width
                vertices[vertexIndex + 1] = y + yOffset * height

                // 颜色值
                vertices[vertexIndex + 2] = color[0]
                vertices[vertexIndex + 3] = color[1]
                vertices[vertexIndex + 4] = color[2]
                vertices[vertexIndex + 5] = color[3]
            }
        })

        // 更新顶点缓冲区
        this.device!.queue.writeBuffer(this.vertexBuffer!, 0, vertices)

        // 创建渲染通道并执行绘制到纹理
        const commandEncoder = this.device!.createCommandEncoder()
        const renderPass = commandEncoder.beginRenderPass({
            colorAttachments: [
                {
                    view: this.texture!.createView(),
                    loadOp: 'clear',
                    clearValue: [0.0, 0.0, 0.0, 1.0],
                    storeOp: 'store',
                },
            ],
        })

        renderPass.setPipeline(this.pipeline!)
        renderPass.setBindGroup(0, this.uniformBindGroup!)
        renderPass.setVertexBuffer(0, this.vertexBuffer!)
        renderPass.draw(verticesPerRect * this.rectangles.length)
        renderPass.end()

        this.device!.queue.submit([commandEncoder.finish()])
    }

    // 清除所有矩形
    public clear(): void {
        this.rectangles = []
        this.render()
    }
}
