import { Wukong } from '@wukong/bridge-proto'
import { OmitToJSON, Position } from '../../../../../../ui-lib/src'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { BlendColorProps } from '../blend/blend-color/blend-color'
import { BlendImageProps } from '../blend/blend-image/blend-image'
import { CommentJumpLinkProps } from '../styles/style-panel/common-jump-link/comment-jump-link'

export enum ColorInteractionFrom {
    FILL, // 填充 ：：
    FILL_PAINT, // 填充下的paint
    STROKE, // 描边 ：：
    STROKE_PAINT, // 描边下的paint
    STYLE_PAINT, // 样式里的paint
    COLOR_STOP, // 渐变颜色
    BACKGROUND, // 背景
    GRID, // 网格
    EFFECT, // 效果
    SELECTION_COLOR, // 选中颜色
    SELECTION_COLOR_FAKE_STYLE, // 选中颜色下panit的样式入口
    LOCAL_VARIABLE_EDIT, // 本地面板编辑
    LOCAL_VARIABLE_CREATE_ALIAS, // 本地面板创建引用
}

export enum PaintMode {
    COLOR = 'color',
    GRADIENT = 'gradient',
    IMAGE = 'image',
}

export enum ColorPanelTitleActiveKey {
    CUSTOM = 'custom',
    LIBRARIES = 'libraries',
}

export interface ColorPanelTitleProps {
    from: ColorInteractionFrom
    activeKey: ColorPanelTitleActiveKey
    onActiveTabChange: (activeKey: ColorPanelTitleProps['activeKey']) => void
    onClickAddIcon: ((titlePosition: Position) => void) | undefined
    onClickCloseIcon: () => void
}

export interface ColorCustomToolsProps {
    paint: OmitToJSON<Wukong.DocumentProto.Paint>
    hideImage: boolean
    onChangePaintMode: (paintMode: PaintMode) => void
    onChangeBlendMode: (blendMode: Wukong.DocumentProto.BlendMode) => void
}

export interface BlendGradientProps {
    type:
        | Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_LINEAR
        | Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_RADIAL
        | Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_ANGULAR
        | Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_DIAMOND
    colorStops: OmitToJSON<Wukong.DocumentProto.ColorStop>[]
    transform: OmitToJSON<Wukong.DocumentProto.Transform>
    onChangePaintType: (value: Wukong.DocumentProto.PaintType) => void
    onChangeColorStops: (
        colorStops: OmitToJSON<Wukong.DocumentProto.ColorStop>[],
        options?: InputOptionsForUndoSquash
    ) => void
    onChangeTransform?: (transform: OmitToJSON<Wukong.DocumentProto.Transform>) => void
}

export type ColorPanelProps = ColorInteractionProps & {
    positionRightBase?: boolean
    onClickAddIcon: ColorPanelTitleProps['onClickAddIcon'] | undefined
    onClickEditStyle: ColorLibrariesProps['onClickEditStyle']
}

export interface ColorVariableCreateProps {
    paints: Wukong.DocumentProto.IPaint[]
    onClickCreateColorVariable: (colorVar: Wukong.DocumentProto.IVariableAliasData) => void
}

export type ColorPanelSubsetProps = Omit<ColorPanelProps, 'onClickAddIcon' | 'onClickEditStyle'>

export interface ColorSetProps {
    zIndex?: number
    from?: ColorInteractionFrom
    position: Position
    isCreateStyle: boolean
    allwaysUseStyleTab: boolean // 样式tab文案总是「样式」
    isRemoteStyle: boolean
    paints: Wukong.DocumentProto.IPaint[]
    hideStyleTab: boolean
    hideVariableTab: boolean
    hideCommonFooter: boolean
    onCancel: () => void
    onCreateStyle: ((styleId: string) => void) | undefined
    onCreateColorVariable: ColorVariableCreateProps['onClickCreateColorVariable']
    onClickCreateButton: (() => void) | undefined
    onClickRemoteStyleLink: CommentJumpLinkProps['onClick']
}

export interface ColorLibrariesProps {
    from: ColorInteractionFrom
    selectColorVariableNodeId: string | undefined
    selectStyleId: string | undefined
    selectStyleNodeKey: string
    onChangeStyle: ((styleId: string) => void) | undefined
    onChangeColorVar: (colorVar: Wukong.DocumentProto.IVariableAliasData) => void
    onClickEditStyle: (
        position: Position,
        remoteStyleInfo: { styleId?: string; docId?: string; fromFig?: boolean; name?: string } | undefined
    ) => void
}
export interface ColorInteractionCommon {
    zIndex?: number
    from: ColorInteractionFrom
    position: Position // 定位
    positionRightBase?: boolean
    styleId: string | undefined // 预选状态
    styleKey?: string // 预选状态
    onChangeStyle?: TypeOnChangeStyle
    onChangeColorVar: (colorVar: Wukong.DocumentProto.IVariableAliasData) => void
    onChangePaints: ((paints: Wukong.DocumentProto.IPaint[]) => void) | undefined
    onClickCreateStyleButton: (() => void) | undefined // 创建样式是在点击创建样式按钮前做的，这个回调帮助清理一些打开状态
    // title
    onCancel: () => void
    // 颜色面板 颜色切换
    onChangePaintType: (
        paintType: Wukong.DocumentProto.PaintType,
        paint: Wukong.DocumentProto.IPaint | undefined | null
    ) => void
    onChangeBlendMode: ColorCustomToolsProps['onChangeBlendMode']
    // 颜色
    onChangeColor: BlendColorProps['onChangeColor']
    onChangeOpacity: BlendColorProps['onChangeOpacity']
    // 渐变
    onChangeColorStops: BlendGradientProps['onChangeColorStops']
    onChangeTransform: BlendGradientProps['onChangeTransform']
    // 图片
    onChangeImagePaint: BlendImageProps['onChangeImagePaint']
    enterClose: BlendImageProps['enterClose']
}
export type TypeOnChangeStyle = (styleId: string, needClosePopup: boolean, isCreate?: boolean) => void
export interface ColorInteractionPaint extends ColorInteractionCommon {
    from: ColorInteractionFrom.FILL_PAINT | ColorInteractionFrom.STROKE_PAINT
    paints: Wukong.DocumentProto.IPaint[] // 创建样式
    paint: Wukong.DocumentProto.IPaint
    // 样式
    onChangeStyle: TypeOnChangeStyle
}

export interface ColorInteractionColorStyle extends ColorInteractionCommon {
    from: ColorInteractionFrom.COLOR_STOP | ColorInteractionFrom.STYLE_PAINT
    paint: Wukong.DocumentProto.IPaint
}

export interface ColorInteractionStyle extends ColorInteractionCommon {
    from: ColorInteractionFrom.FILL | ColorInteractionFrom.STROKE
    paints: Wukong.DocumentProto.IPaint[] // 创建样式
    // 样式
    onChangeStyle: TypeOnChangeStyle
}

export interface ColorInteractionBackground extends ColorInteractionCommon {
    from: ColorInteractionFrom.BACKGROUND
    paint: Wukong.DocumentProto.IPaint
}

export interface ColorInteractionGrid extends ColorInteractionCommon {
    from: ColorInteractionFrom.GRID
    paint: Wukong.DocumentProto.IPaint
}

export interface ColorInteractionEffect extends ColorInteractionCommon {
    from: ColorInteractionFrom.EFFECT
    paint: Wukong.DocumentProto.IPaint
}

export interface ColorInteractionSelectionColor extends ColorInteractionCommon {
    from: ColorInteractionFrom.SELECTION_COLOR | ColorInteractionFrom.SELECTION_COLOR_FAKE_STYLE
    paints: Wukong.DocumentProto.IPaint[]
    onChangeStyle: TypeOnChangeStyle
}

export interface ColorInteractionLocalLibrary extends ColorInteractionCommon {
    from: ColorInteractionFrom.LOCAL_VARIABLE_EDIT | ColorInteractionFrom.LOCAL_VARIABLE_CREATE_ALIAS
    paint: Wukong.DocumentProto.IPaint
}

export type ColorInteractionProps =
    | ColorInteractionPaint
    | ColorInteractionStyle
    | ColorInteractionColorStyle
    | ColorInteractionBackground
    | ColorInteractionGrid
    | ColorInteractionEffect
    | ColorInteractionSelectionColor
    | ColorInteractionLocalLibrary
