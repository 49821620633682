import { getTranslationValue } from '../../../../../../../../../util/src/i18n'

export const zhTranslation = {
    PropertyName: '属性名',
    PropertyValue: '属性值',
    CreateProperty: '创建属性',
} as const

export const enTranslation = {
    PropertyName: 'Name',
    PropertyValue: 'Value',
    CreateProperty: 'Create property',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
