/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { useMemo } from 'react'
import {
    DraggablePopupPropsV2,
    DraggablePopupV2,
    SimpleDrag,
    SimpleDragProps,
} from '../../../../../../../../ui-lib/src'
import { SolidPaint } from '../../../../../../document/node/node'
import { InputOptionsForUndoSquash } from '../../../../atom/inputs/components/formatted-input'
import { Value } from '../../../../atom/inputs/utils/type'
import { GridComponet } from '../../../../design-panel-v2/layout-grid/layotu-grid-componet'
import { ColorSpace } from '../../../blend/color-picker/utils/color-translate'
import { CommentAttributeTitle } from '../common-attribute-title/comment-attribute-title'
import { CommonFooter } from '../common-footer/common-footer'
import { CommonNameDescription } from '../common-name-description/common-name-description'
import classes from './layout-grid-style-panel.module.less'

export interface LayoutGridStyleData {
    id: string
    name: string
    description: string
    layoutGrids: Wukong.DocumentProto.ILayoutGrid[]
    floatVariables: Wukong.DocumentProto.ISingleFloatVariable[]
}

export type PickDraggablePopupProps = Pick<DraggablePopupPropsV2, 'visible' | 'position' | 'header' | 'onCancel'>

export interface LayoutGridStylePanelProps extends PickDraggablePopupProps {
    layoutGridStyleData: LayoutGridStyleData
    colorSpace: ColorSpace
    openFrom: 'create' | 'remote' | 'normal'
    selectLayoutGridIndexList?: number[]
    isReadOnly?: boolean
    onChangeName: (v: string) => void
    onChangeDescription: (v: string) => void
    onClickTitle: () => void
    onClickAddLayoutGrid: () => void
    onSelectChange: SimpleDragProps<Wukong.DocumentProto.ILayoutGrid>['onSelectChange']
    onDragDone: SimpleDragProps<Wukong.DocumentProto.ILayoutGrid>['onDragDone']
    isOpenLayoutGridPanel: (index: number) => boolean
    onChangeModalVisible: (index: number, v: boolean) => void
    onChangeVisible: (index: number, v: boolean) => void
    onChangeLayoutPattern: (index: number, v: Wukong.DocumentProto.LayoutGridPattern) => void
    onChangeSolidPaint: (index: number, v: SolidPaint, options?: InputOptionsForUndoSquash) => void
    onChangeSectionSize: (index: number, value: Value, options?: InputOptionsForUndoSquash) => void
    onChangeSectionSizeVar: (index: number, value: string | null, options?: InputOptionsForUndoSquash) => void
    onChangeCount: (index: number, value: Value, options?: InputOptionsForUndoSquash) => void
    onChangeCountVar: (index: number, value: string | null, options?: InputOptionsForUndoSquash) => void
    onChangeAlign: (index: number, value: Wukong.DocumentProto.RowsColsLayoutGridAlign) => void
    onChangeGutterSize: (index: number, value: Value, options?: InputOptionsForUndoSquash) => void
    onChangeGutterSizeVar: (index: number, value: string | null, options?: InputOptionsForUndoSquash) => void
    onChangeOffset: (index: number, value: Value, options?: InputOptionsForUndoSquash) => void
    onChangeOffsetVar: (index: number, value: string | null, options?: InputOptionsForUndoSquash) => void
    onClickSub: (index: number) => void
    onClickRemoteStyleLink?: (e: React.MouseEvent) => void
    onClickCreate?: () => void
}

export function LayoutGridStylePanel<T extends LayoutGridStylePanelProps = LayoutGridStylePanelProps>(props: T) {
    const { layoutGridStyleData, openFrom = [] } = props

    const isRemoteStyle = useMemo(() => openFrom === 'remote', [openFrom])
    const isCreateStyle = useMemo(() => openFrom === 'create', [openFrom])

    return (
        <DraggablePopupV2
            visible={props.visible}
            position={props.position}
            positionRightBase
            header={props.header}
            bodyClassName={classnames(classes.body, {
                [classes.existCreate]: isCreateStyle,
                [classes.existRemote]: isRemoteStyle,
            })}
            footer={
                <CommonFooter
                    isCreateStyle={isCreateStyle}
                    isRemoteStyle={isRemoteStyle && !props.isReadOnly}
                    onClickJumpLink={props.onClickRemoteStyleLink}
                    onClickCreateButton={props.onClickCreate}
                />
            }
            notUseDefaultFooterClassName
            onCancel={props.onCancel}
            enableScrollBar
            styleType="editor"
        >
            <CommonNameDescription
                isReadOnly={isRemoteStyle}
                name={layoutGridStyleData?.name}
                description={layoutGridStyleData?.description}
                onChangeName={props.onChangeName}
                onChangeDescription={props.onChangeDescription}
                key={layoutGridStyleData?.id}
                testId="layout-grid-style-name-description"
            />
            <CommentAttributeTitle
                grayTheme={!layoutGridStyleData.layoutGrids.length}
                onClickTitle={props.onClickTitle}
                onClickAddIcon={props.onClickAddLayoutGrid}
                disabledIcon={isRemoteStyle}
            />
            <LayoutGridStylePanelList {...props}></LayoutGridStylePanelList>
        </DraggablePopupV2>
    )
}

function LayoutGridStylePanelList<T extends LayoutGridStylePanelProps = LayoutGridStylePanelProps>(props: T) {
    const { layoutGridStyleData, colorSpace, openFrom, selectLayoutGridIndexList = [] } = props

    const isRemoteStyle = useMemo(() => openFrom === 'remote', [openFrom])

    const layoutGrids = layoutGridStyleData.layoutGrids.slice().reverse()
    const selectedIndexes = selectLayoutGridIndexList.map((index) => layoutGrids.length - index - 1)
    const onSelectChange = (indexes: number[], e: React.MouseEvent) => {
        props.onSelectChange?.(
            indexes.map((index) => layoutGrids.length - index - 1),
            e
        )
    }
    const onDragDone = (items: Wukong.DocumentProto.ILayoutGrid[], indexes: number[]) => {
        props.onDragDone?.(
            items.slice().reverse(),
            indexes.map((index) => layoutGrids.length - index - 1)
        )
    }
    const isOpenLayoutGridPanel = (index: number) => {
        return props.isOpenLayoutGridPanel(layoutGrids.length - index - 1)
    }
    const onChangeModalVisible = (index: number) => (v: boolean) => {
        props.onChangeModalVisible(layoutGrids.length - index - 1, v)
    }
    const onChangeVisible = (index: number) => (v: boolean) => {
        props.onChangeVisible(layoutGrids.length - index - 1, v)
    }
    const onChangeLayoutPattern = (index: number) => (pattern: Wukong.DocumentProto.LayoutGridPattern) => {
        props.onChangeLayoutPattern(layoutGrids.length - index - 1, pattern)
    }
    const onChangeSolidPaint = (index: number) => (value: SolidPaint, options?: InputOptionsForUndoSquash) => {
        props.onChangeSolidPaint(layoutGrids.length - index - 1, value, options)
    }
    const onChangeSectionSize = (index: number) => (value: Value, options?: InputOptionsForUndoSquash) => {
        props.onChangeSectionSize(layoutGrids.length - index - 1, value, options)
    }

    const onChangeSectionSizeVar = (index: number) => (value: string | null, options?: InputOptionsForUndoSquash) => {
        props.onChangeSectionSizeVar(layoutGrids.length - index - 1, value, options)
    }

    const onChangeCount = (index: number) => (value: Value, options?: InputOptionsForUndoSquash) => {
        props.onChangeCount(layoutGrids.length - index - 1, value, options)
    }

    const onChangeCountVar = (index: number) => (value: string | null, options?: InputOptionsForUndoSquash) => {
        props.onChangeCountVar(layoutGrids.length - index - 1, value, options)
    }

    const onChangeAlign = (index: number) => (align: Wukong.DocumentProto.RowsColsLayoutGridAlign) => {
        props.onChangeAlign(layoutGrids.length - index - 1, align)
    }

    const onChangeGutterSize = (index: number) => (value: Value, options?: InputOptionsForUndoSquash) => {
        props.onChangeGutterSize(layoutGrids.length - index - 1, value, options)
    }

    const onChangeGutterSizeVar = (index: number) => (value: string | null, options?: InputOptionsForUndoSquash) => {
        props.onChangeGutterSizeVar(layoutGrids.length - index - 1, value, options)
    }

    const onChangeOffset = (index: number) => (value: Value, options?: InputOptionsForUndoSquash) => {
        props.onChangeOffset(layoutGrids.length - index - 1, value, options)
    }

    const onChangeOffsetVar = (index: number) => (value: string | null, options?: InputOptionsForUndoSquash) => {
        props.onChangeOffsetVar(layoutGrids.length - index - 1, value, options)
    }

    const onClickDelete = (index: number) => () => {
        props.onClickSub(layoutGrids.length - index - 1)
    }

    return (
        <SimpleDrag
            selectedIndexList={selectedIndexes}
            onSelectChange={onSelectChange}
            items={layoutGrids}
            onDragDone={onDragDone}
            disabled={isRemoteStyle}
            onMouseDown={(e) => e.stopPropagation()}
            // 避免触发可拖拽弹窗的拖拽
            data-disabled-drag-move={'true'}
        >
            {layoutGrids.map((layoutGrid, index) => (
                <SimpleDrag.Item key={index} itemIndex={index}>
                    <GridComponet
                        layoutGrid={layoutGrid}
                        variables={layoutGridStyleData.floatVariables}
                        colorSpace={colorSpace}
                        isOpen={isOpenLayoutGridPanel(index)}
                        onChangeVisible={onChangeVisible(index)}
                        onClickSub={onClickDelete(index)}
                        selected={selectedIndexes.includes(index)}
                        onChangeLayoutPattern={onChangeLayoutPattern(index)}
                        onChangeModalVisible={onChangeModalVisible(index)}
                        onChangeSolidPaint={onChangeSolidPaint(index)}
                        onChangeSectionSize={onChangeSectionSize(index)}
                        onChangeSectionSizeVar={onChangeSectionSizeVar(index)}
                        onChangeCount={onChangeCount(index)}
                        onChangeCountVar={onChangeCountVar(index)}
                        onChangeAlign={onChangeAlign(index)}
                        onChangeGutterSize={onChangeGutterSize(index)}
                        onChangeGutterSizeVar={onChangeGutterSizeVar(index)}
                        onChangeOffset={onChangeOffset(index)}
                        onChangeOffsetVar={onChangeOffsetVar(index)}
                        isMini={true}
                        disabled={isRemoteStyle}
                    ></GridComponet>
                </SimpleDrag.Item>
            ))}
        </SimpleDrag>
    )
}
