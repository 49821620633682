import {
    AddSelectionPrototypeInteraction,
    ClearHoverProtoTypeInteractionWasmCall,
    EndEditingPrototypeInteraction,
    PrototypeInteractionTargetCancelHover,
    PrototypeInteractionTargetEnterHover,
    ResetSelectedInstancesByTypeCommand,
    SetHoverProtoTypeInteractionWasmCall,
    StartEditingPrototypeInteraction,
    UpdateOverlayBackgroundAppearance,
    UpdateOverlayBackgroundInteraction,
    UpdateOverlayPositionType,
    UpdatePrototypeSelectedInteractionKeys,
    UpdateSelectionPrototypeInteraction,
    Wukong,
} from '@wukong/bridge-proto'
import constate from 'constate'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Position } from '../../../../ui-lib/src'
import { cmdChangePopupState } from '../../document/command/document-command'
import { useViewState } from '../../view-state-bridge'
import { InputOptionsForUndoSquash } from '../component/atom/inputs/components/formatted-input'
import { InstanceResetType } from '../component/design-panel-v2/component-instance-panel/types'
import {
    InteractionsWithEventType,
    PrototypeInteractionWithNodeId,
} from '../component/prototype/prototype-interaction/constants'
import {
    generateInteractionsWithEventType,
    generateTriggersConflictList,
    getAddPrototypeInteractionType,
} from '../component/prototype/prototype-interaction/utils'
import { useCommand } from './document-context'

export const usePrototypeInteraction = () => {
    const command = useCommand()
    const [panelPopupPosition, setPanelPopupPosition] = useState<Position>()
    const interactionsRef = useRef<HTMLDivElement>(null)
    const state = useViewState('selectionPrototypeInteraction')
    const popupState = useViewState('popupState')
    const prototypeDevice = useViewState('prototypeDevice')
    const isPresetMobile = useMemo(() => {
        const device = prototypeDevice?.config?.presetIdentifier ?? ''
        return /iphone|android|ipad|watch/i.test(device)
    }, [prototypeDevice])
    const isShow = state?.isShow ?? false
    const isEmpty = state?.isEmpty ?? true
    const isSingleNode = state?.isSingleNode ?? true
    const disabledDragging = state?.disabledDragging ?? false
    const isShowResetButton = state?.isShowResetButton ?? false
    const allTargetNodes = useMemo(() => state?.targetNodes ?? [], [state])
    const scrollToTargets = useMemo(() => state?.scrollToTargetNodeList, [state])
    const selectedTargetNode = useMemo(
        () => state?.selectedTargetNodeList[0] ?? { isEmpty: true, isMixed: false, selectedNodes: [] },
        [state]
    )
    const overlaySetting = useMemo(() => state?.overlaySetting, [state])
    const interactions = useMemo(() => state?.interactions ?? [], [state])
    // 最外层父容器list
    const outerTargetNodes = useMemo(() => state?.outerTargetNodes ?? [], [state])
    const interactionsMap = useMemo(() => {
        const map = new Map<string, PrototypeInteractionWithNodeId>(
            interactions.map((val) => [`${val.sourceNodeId}~${val.id}`, val])
        )
        return map
    }, [interactions])
    // 正在编辑的交互keys
    const selectedInteractionKeys = useMemo(() => state?.selectedInteractionKeys ?? [], [state])
    const triggers = useMemo(() => {
        if (interactions.length) {
            return generateInteractionsWithEventType(interactions, isSingleNode)
        }
        return []
    }, [interactions, isSingleNode])
    // InteractionItem 展示，倒序
    const showTriggers = useMemo(() => {
        return [...triggers].reverse()
    }, [triggers])
    const triggersConflictList = useMemo(() => {
        return generateTriggersConflictList(showTriggers)
    }, [showTriggers])

    // 是否打开交互设置弹窗
    const openPopup = useMemo(() => {
        return popupState?.type === Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_PROTOTYPE_INTERACTION
    }, [popupState])
    // 是否打开画布区交互设置弹窗
    const openCanvasPopup = useMemo(() => {
        return (
            popupState?.type === Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_CANVAS_PROTOTYPE_INTERACTION &&
            !state?.forceHideCanvasPopup
        )
    }, [popupState, state?.forceHideCanvasPopup])

    const popupPosition = useMemo(() => {
        if (openPopup) {
            return panelPopupPosition
        }
        return
    }, [openPopup, panelPopupPosition])

    // 选中的交互list
    const selectedInteractions = useMemo(() => {
        if ((openPopup || openCanvasPopup) && interactions.length) {
            const res: PrototypeInteractionWithNodeId[] = []
            selectedInteractionKeys.forEach((pair) => {
                const key = `${pair.sourceNodeId}~${pair.interactionId}`
                if (interactionsMap.has(key)) {
                    res.push(interactionsMap.get(key) as PrototypeInteractionWithNodeId)
                }
            })
            return res
        }
        return []
    }, [openPopup, openCanvasPopup, selectedInteractionKeys, interactionsMap, interactions.length])
    const selectedIndexList = useMemo(() => {
        const res: number[] = []
        if (selectedInteractionKeys.length) {
            showTriggers.forEach((trigger, index) => {
                if (
                    trigger.interactions.some((v) =>
                        selectedInteractionKeys.some(
                            (k) => k.sourceNodeId === v.sourceNodeId && k.interactionId === v.id
                        )
                    )
                ) {
                    res.push(index)
                }
            })
        }
        return res
    }, [selectedInteractionKeys, showTriggers])

    const onAddInteraction = useCallback(
        (pos?: Position) => {
            if (!openPopup) {
                setPanelPopupPosition(pos)
            }

            const interactionType = getAddPrototypeInteractionType(triggers)
            command.DEPRECATED_invokeBridge(AddSelectionPrototypeInteraction, {
                interactionType,
                popupStateType: Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_PROTOTYPE_INTERACTION,
            })
            command.commitUndo()
        },
        [command, triggers, openPopup]
    )

    const onClickTitle = useCallback(
        (pos?: Position) => {
            if (isEmpty) {
                onAddInteraction(pos)
            }
        },
        [isEmpty, onAddInteraction]
    )

    const onClickAdd = useCallback(
        (pos?: Position) => {
            onAddInteraction(pos)
        },
        [onAddInteraction]
    )

    const onClickRemove = useCallback(
        (e: React.MouseEvent, index: number) => {
            const length = triggers.length
            const newInteractions = triggers.map((v, idx) => (idx === length - index - 1 ? [] : v.interactions)).flat()
            command.DEPRECATED_invokeBridge(UpdateSelectionPrototypeInteraction, { interactions: newInteractions })
            command.commitUndo()
        },
        [triggers, command]
    )

    const onOpenPopup = useCallback(
        (indexList: number[], pos?: Position) => {
            if (!openPopup) {
                setPanelPopupPosition(pos)
            }

            const keys = indexList
                .map((index) =>
                    showTriggers[index].interactions.map((v) => ({ sourceNodeId: v.sourceNodeId, interactionId: v.id }))
                )
                .flat()
            command.DEPRECATED_invokeBridge(StartEditingPrototypeInteraction, {
                interactionKeys: keys,
                popupStateType: Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_PROTOTYPE_INTERACTION,
            })
        },
        [command, showTriggers, openPopup]
    )

    const onPopupChange = useCallback(
        (data: PrototypeInteractionWithNodeId[], option?: InputOptionsForUndoSquash) => {
            const newInteractionsMap = new Map<string, PrototypeInteractionWithNodeId>(
                data.map((val) => [`${val.sourceNodeId}~${val.id}`, val])
            )
            const newInteractions: PrototypeInteractionWithNodeId[] = []
            interactions.forEach((v) => {
                if (newInteractionsMap.has(`${v.sourceNodeId}~${v.id}`)) {
                    newInteractions.push(
                        newInteractionsMap.get(`${v.sourceNodeId}~${v.id}`) as PrototypeInteractionWithNodeId
                    )
                } else {
                    newInteractions.push(v)
                }
            })
            command.DEPRECATED_invokeBridge(UpdateSelectionPrototypeInteraction, { interactions: newInteractions })
            command.commitUndo(option?.commitType)
        },
        [command, interactions]
    )

    const onClosePopup = useCallback(() => {
        command.DEPRECATED_invokeBridge(EndEditingPrototypeInteraction)
    }, [command])

    const onMouseEnterItem = useCallback(
        (data: InteractionsWithEventType) => {
            command.DEPRECATED_invokeBridge(SetHoverProtoTypeInteractionWasmCall, {
                keys: data.interactions.map((v) => ({
                    interactionId: v.id,
                    sourceNodeId: v.sourceNodeId,
                })),
            })
        },
        [command]
    )

    const onMouseLeaveItem = useCallback(() => {
        command.DEPRECATED_invokeBridge(ClearHoverProtoTypeInteractionWasmCall)
    }, [command])

    const onSelectItem = useCallback(
        (list: number[], e: React.MouseEvent) => {
            const target = e.target as HTMLElement
            const content = target.closest('[data-testid="interaction-item-content"]')
            const icon = target.closest('[data-testid="interaction-drag-icon"]')
            if (content) {
                const { top, left } = content.getBoundingClientRect()
                onOpenPopup(list, { top: top - 4, left: left - 16 })
            }
            if (icon) {
                const keys = list
                    .map((index) =>
                        showTriggers[index].interactions.map((v) => ({
                            sourceNodeId: v.sourceNodeId,
                            interactionId: v.id,
                        }))
                    )
                    .flat()
                command.DEPRECATED_invokeBridge(UpdatePrototypeSelectedInteractionKeys, {
                    interactionKeys: keys,
                })
                command.invoke(cmdChangePopupState, {
                    type: Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_NONE,
                    reciprocalIndex: -1,
                    multiPopup: [],
                })
            }
        },
        [command, onOpenPopup, showTriggers]
    )

    const onDragDone = useCallback(
        (items: InteractionsWithEventType[]) => {
            const newTriggers = [...items].reverse()
            const newInteractions = newTriggers.map((v) => v.interactions).flat()
            command.DEPRECATED_invokeBridge(UpdateSelectionPrototypeInteraction, { interactions: newInteractions })
            command.commitUndo()
        },
        [command]
    )

    const targetNodeEnterHover = useCallback(
        (value: string) => {
            command.DEPRECATED_invokeBridge(PrototypeInteractionTargetEnterHover, {
                value,
            })
        },
        [command]
    )

    const targetNodeCancelHover = useCallback(() => {
        command.DEPRECATED_invokeBridge(PrototypeInteractionTargetCancelHover)
    }, [command])

    const onChangeOverlayPositionType = useCallback(
        (nodeId: string, positionType: Wukong.DocumentProto.OverlayPositionType) => {
            command.DEPRECATED_invokeBridge(UpdateOverlayPositionType, { nodeId, positionType })
            command.commitUndo()
        },
        [command]
    )

    const onChangeOverlayBackgroundInteraction = useCallback(
        (nodeId: string, backgroundInteraction: Wukong.DocumentProto.OverlayBackgroundInteraction) => {
            command.DEPRECATED_invokeBridge(UpdateOverlayBackgroundInteraction, { nodeId, backgroundInteraction })
            command.commitUndo()
        },
        [command]
    )

    const onChangeOverlayBackgroundAppearance = useCallback(
        (
            nodeId: string,
            backgroundAppearance: Wukong.DocumentProto.IOverlayBackgroundAppearance,
            options?: InputOptionsForUndoSquash
        ) => {
            command.DEPRECATED_invokeBridge(UpdateOverlayBackgroundAppearance, { nodeId, backgroundAppearance })
            command.commitUndo(options?.commitType)
        },
        [command]
    )

    const resetInstancePrototypeInteractions = useCallback(() => {
        command.DEPRECATED_invokeBridge(ResetSelectedInstancesByTypeCommand, {
            type: InstanceResetType.INSTANCE_RESET_TYPE_PROTOTYPE_INTERACTIONS,
        })
        command.commitUndo()
    }, [command])

    return {
        isShow,
        isEmpty,
        disabledDragging,
        isShowResetButton,
        isPresetMobile,
        showTriggers,
        triggersConflictList,
        openPopup,
        openCanvasPopup,
        popupPosition,
        /**
         * 画布区打开的弹窗原始位置信息
         */
        canvasPopupPositionV2: state?.popupPosition,
        allTargetNodes,
        outerTargetNodes,
        selectedInteractions,
        selectedIndexList,
        scrollToTargets,
        selectedTargetNode,
        overlaySetting,
        interactionsRef,
        onClickTitle,
        onClickAdd,
        onPopupChange,
        onClosePopup,
        onMouseEnterItem,
        onMouseLeaveItem,
        onSelectItem,
        onClickRemove,
        onDragDone,
        targetNodeEnterHover,
        targetNodeCancelHover,
        onChangeOverlayPositionType,
        onChangeOverlayBackgroundInteraction,
        onChangeOverlayBackgroundAppearance,
        resetInstancePrototypeInteractions,
    }
}

export const [PrototypeInteractionProvider, usePrototypeInteractionContext] = constate(usePrototypeInteraction)
