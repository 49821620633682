import { Wukong } from '@wukong/bridge-proto'
import { createSelectors, createStore } from '../../../../util/src'
import { ServiceClass } from '../../kernel/util/service-class'
import {
    getSessionColorModeV2,
    setSessionColorModeV2,
} from '../../ui/component/design/blend/color-picker/use-color-picker'

export class ColorModeServiceV2 extends ServiceClass {
    public states = createSelectors(
        createStore<{
            colorModeState: Wukong.DocumentProto.DevAndReadonlyColorMode
        }>(() => ({
            colorModeState:
                getSessionColorModeV2() ||
                Wukong.DocumentProto.DevAndReadonlyColorMode.DEV_AND_READONLY_COLOR_MODE_H_E_X,
        }))
    )

    public setColorMode = (colorMode: Wukong.DocumentProto.DevAndReadonlyColorMode) => {
        setSessionColorModeV2(colorMode)
        this.states.setState({ colorModeState: colorMode })
    }
}
