// eslint-disable-next-line import/default, import/no-duplicates
import workerUrl from './interval.worker?worker&url'

export const intervalWorker = async (duration: number, isDev: boolean) => {
    let worker: Worker
    if (isDev) {
        const IntervalWorker = (await import('./interval.worker?worker')).default
        worker = new IntervalWorker()
    } else {
        const blob = await fetch(workerUrl).then((data) => data.blob())
        worker = new Worker(window.URL.createObjectURL(blob), {
            type: 'module',
        })
    }
    worker.postMessage({ duration })
    return worker
}
