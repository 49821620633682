/**
 * @owner: loushuangzhanbj@kanyun.com
 */
import { forwardRef, HTMLAttributes, useCallback, useRef } from 'react'
import { isRightClick } from '../../utils/is-right-click'
import { isDefaultTabbableElement } from '../tab-loop/tab-loop'

export interface LeftTop {
    left: number
    top: number
}

export interface DragMoveBoxProps extends HTMLAttributes<HTMLDivElement> {
    onMoveStart?: (e: React.PointerEvent<HTMLDivElement>) => void
    onMoving?: (nextLeftTop: LeftTop, e: React.PointerEvent<HTMLDivElement>) => void
    onMoveEnd?: () => void
    dragMoveBasePoint?: LeftTop // 不传这个值onMoving的nextLeftTop是左上角点的位置，给定这个值就是这个值所描述点的位置
    closeMove?: boolean // true 时，不会触发onMoveStart、onMoving、onMoveEnd
    minMovePixel?: number // 点击时可能会伴随轻微的移动，低于这个值的偏移并不会触发 onMoving
    dataTestId?: string
}
function _DragMoveBox(props: DragMoveBoxProps, outRef: React.Ref<HTMLDivElement>) {
    const {
        onMoveStart,
        onMoving,
        onMoveEnd,
        dragMoveBasePoint,
        minMovePixel = 4, // 产品定义值为4px
        onPointerDown,
        onPointerMove,
        onPointerUpCapture,
        onLostPointerCapture,
        closeMove,
        dataTestId,
        ...otherProps
    } = props
    const offset = useRef<LeftTop>({ left: 0, top: 0 })
    const capturePointerId = useRef<number>(NaN)
    const hasMoved = useRef<boolean>(false)
    const pointerDownLeftTop = useRef<LeftTop>({ left: NaN, top: NaN })

    const shouldCancelDrag = useCallback(
        (e: React.PointerEvent<HTMLDivElement>) => {
            if (closeMove || isRightClick(e)) {
                return true
            }
            if (isNaN(pointerDownLeftTop.current.left) || isNaN(pointerDownLeftTop.current.top)) {
                return true
            }
            const endElement = e.currentTarget as HTMLElement
            let startElement: HTMLElement | null = e.target as HTMLElement
            while (true) {
                if (startElement === null) {
                    return false
                }
                if (!endElement.contains(startElement)) {
                    return true
                }
                if (startElement.dataset.disabledDragMove === 'true') {
                    return true
                }
                if (isDefaultTabbableElement(startElement.nodeName.toLowerCase())) {
                    return true
                }
                if (endElement === startElement) {
                    return false
                }
                startElement = startElement.parentElement
            }
        },
        [closeMove]
    )

    const _onPointerDown2 = useCallback(
        (e: React.PointerEvent<HTMLDivElement>) => {
            hasMoved.current = false
            pointerDownLeftTop.current = { left: e.clientX, top: e.clientY }
            e.stopPropagation()
            onPointerDown?.(e)
            const element = e.currentTarget as HTMLDivElement
            const rect = element.getBoundingClientRect()
            const { left, top } = dragMoveBasePoint ?? { left: rect.left, top: rect.top }
            offset.current = { left: e.clientX - left, top: e.clientY - top }
        },
        [dragMoveBasePoint, onPointerDown]
    )

    const _onPointerMove2 = useCallback(
        (e: React.PointerEvent<HTMLDivElement>) => {
            onPointerMove?.(e)
            if (e.buttons % 2 !== 1) {
                // 左键没按下就返回
                return
            }
            const element = e.currentTarget as HTMLDivElement
            if (hasMoved.current) {
                if (!element.hasPointerCapture(e.pointerId) || closeMove) {
                    return
                }
                e.stopPropagation()
                onMoving?.(
                    {
                        left: e.clientX - offset.current.left,
                        top: e.clientY - offset.current.top,
                    },
                    e
                )
            } else {
                if (shouldCancelDrag(e)) {
                    return
                }
                element.setPointerCapture(e.pointerId)
                capturePointerId.current = e.pointerId
                const delta = Math.max(
                    Math.abs(e.clientX - pointerDownLeftTop.current.left),
                    Math.abs(e.clientY - pointerDownLeftTop.current.top)
                )
                if (delta >= minMovePixel) {
                    hasMoved.current = true
                    onMoveStart?.(e)
                }
            }
        },
        [closeMove, minMovePixel, onMoveStart, onMoving, onPointerMove, shouldCancelDrag]
    )

    const _onPointerUpCapture = useCallback(
        (e: React.PointerEvent<HTMLDivElement>) => {
            onPointerUpCapture?.(e)
            pointerDownLeftTop.current = { left: NaN, top: NaN }
        },
        [onPointerUpCapture]
    )

    const _onLostPointerCapture2 = useCallback(
        (e: React.PointerEvent<HTMLDivElement>) => {
            onMoveEnd?.()
            onLostPointerCapture?.(e)
            pointerDownLeftTop.current = { left: NaN, top: NaN }
        },
        [onLostPointerCapture, onMoveEnd]
    )

    return (
        <div
            ref={outRef}
            onPointerDown={_onPointerDown2}
            onPointerMove={_onPointerMove2}
            onPointerUpCapture={_onPointerUpCapture}
            onLostPointerCapture={_onLostPointerCapture2}
            data-testid={dataTestId}
            {...otherProps}
        >
            {props.children}
        </div>
    )
}
/**
 * @description 给子元素添加 data-disabled-drag-move="true" 可以避免触发拖拽
 */
export const DragMoveBox = forwardRef(_DragMoveBox)
