import { IDBPDatabase, openDB } from 'idb'
import { IndexedDBName, IndexedDBName2DBSchema } from '../config'
import { IndexedDBConfig } from '../types'
import { combineIndexedDBName } from '../utils'
import { enhanceDB } from './db'

/**
 * 增强版 indexedDB 实现
 * powered by idb
 * 提供异步 operations
 * 提供超限的降级策略
 * 限制 DBName & StoreKey
 * @param config
 * @returns
 */
export const openEnhancedIndexedDB = async <T extends IndexedDBName>(
    config: IndexedDBConfig<T>
): Promise<IDBPDatabase<IndexedDBName2DBSchema[T]>> => {
    const db = await openDB(
        combineIndexedDBName(config.name),
        config.version,
        config.callback
            ? {
                  ...config.callback,
                  upgrade: (database: IDBPDatabase<IndexedDBName2DBSchema[T]>, ...args) => {
                      // wrapper upgrade to proxy setter
                      config.callback?.upgrade?.(enhanceDB<T>(database), ...args)
                  },
              }
            : undefined
    )

    return enhanceDB(db)
}
