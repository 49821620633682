import { useCallback, useMemo } from 'react'
import { IconSubtract16 } from '../../../svg-icon/16/common'
import { CustomItem, CustomItemProps } from '../../custom-item/custom-item'
import { DataNode, RenderStructNodeItem, RenderStructNodeItemProps } from '../picks/multi-level/type'
import { isHiddenDataNode } from '../picks/multi-level/utils'

type OmitCustomItemProps = Omit<
    CustomItemProps,
    | 'dataTestId'
    | 'splitLineTop'
    | 'splitLineBottom'
    | 'isSelect'
    | 'forwardIcon'
    | 'forwardIconClassName'
    | 'selectContainerClassName'
    | 'addRightPadding'
    | 'backwardIcon'
    | 'isHover'
    | 'disabled'
>
export interface SelectDefaultCustomNode {
    isSelect?: boolean
    autoSplitLine?: boolean
    shortcut?: string
    customItemProps?: OmitCustomItemProps
}

export const SelectDefaultRenderItem: RenderStructNodeItem<SelectDefaultCustomNode> = (
    props: RenderStructNodeItemProps<SelectDefaultCustomNode>
) => {
    const {
        data: { name, disabled, dataTestId, customNode = {} },
        isExtend,
        isPreselect,
        getSameLevelDataList,
        getIndexInSameLevelDataList,
    } = props
    const { customItemProps, autoSplitLine, shortcut } = customNode
    const isExistSelectChild = useCallback((dataNodes?: DataNode<SelectDefaultCustomNode>[]): boolean => {
        if (!dataNodes) {
            return false
        }
        return dataNodes.some((dataNode) => {
            return dataNode.customNode?.isSelect || isExistSelectChild(dataNode.children)
        })
    }, [])

    const isSelect = useMemo(() => {
        return props.data.customNode?.isSelect || isExistSelectChild(props.data.children)
    }, [isExistSelectChild, props.data.children, props.data.customNode?.isSelect])

    const shouldShowSplitLine = useCallback(() => {
        const list = getSameLevelDataList()
        const selfIndex = getIndexInSameLevelDataList()
        const checkForward = () => {
            const length = list.length
            let checkIndex = selfIndex - 1
            while (checkIndex < length && checkIndex >= 0) {
                const dataNode = list[checkIndex]
                if (dataNode && !isHiddenDataNode(dataNode)) {
                    return dataNode.customNode?.autoSplitLine ? false : true
                }
                checkIndex--
            }
            return false
        }
        const checkBackward = () => {
            const length = list.length
            let checkIndex = selfIndex + 1
            while (checkIndex < length) {
                const dataNode = list[checkIndex]
                if (dataNode && !isHiddenDataNode(dataNode) && !dataNode.customNode?.autoSplitLine) {
                    return true
                }
                checkIndex++
            }
            return false
        }
        return checkForward() && checkBackward()
    }, [getIndexInSameLevelDataList, getSameLevelDataList])

    return autoSplitLine ? (
        shouldShowSplitLine() ? (
            <div style={{ marginTop: 7, marginBottom: 8, borderBottom: '1px solid var(--wk-gray-13-6)' }}></div>
        ) : (
            <></>
        )
    ) : (
        <CustomItem
            {...customItemProps}
            data-testid={dataTestId}
            isSelect={isSelect}
            forwardIcon={undefined}
            forwardIconClassName={''}
            selectContainerClassName={''}
            addRightPadding
            backwardIcon={props.hasChild ? <IconSubtract16 /> : shortcut}
            isHover={isPreselect || isExtend}
            disabled={disabled}
            style={{ ...customItemProps?.style, userSelect: 'none', ...(props.hasChild ? { paddingRight: 12 } : {}) }}
        >
            {name}
        </CustomItem>
    )
}
