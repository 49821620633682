import { SessionStorageKey } from '../../../web-storage/session-storage/config/index'
import { useCallback, useEffect, useRef } from 'react'
import { Serializable, serialize } from '../serialize'
import { SyncChannel } from '../sync-channel'
import { enhancedSessionStorage } from '../../../web-storage/session-storage/storage'

export type ChannelType = 'broadcaseChannel' | 'storage'

export interface Option<T> {
    save?: boolean
    // cleanUp saved when exist
    cleanUp?: boolean
    channelType?: ChannelType
    onMessage?: (msg: T) => void
}

// we will not check the message info, please verify it!
// save means when use BroadcastChannel, current message will store in storage
export function useOnSync<T extends Serializable>(name: SessionStorageKey, option?: Option<T>) {
    const save = option?.save ?? false
    const syncChannel = useRef<SyncChannel<T> | null>(null)
    const recevied = useRef<T[]>([])
    const currentlyRecevied = useRef<T | null>(null)

    const sendMessage = useCallback(
        (msg: T) => {
            if (syncChannel.current !== null) {
                syncChannel.current.postMessage(msg)
                if (save) {
                    enhancedSessionStorage.setItem(name, serialize(msg))
                }
            }
        },
        [name, save]
    )

    useEffect(() => {
        const onMessage = (ev: MessageEvent<T>) => {
            recevied.current.push(ev.data)
            currentlyRecevied.current = ev.data
            if (option?.onMessage) {
                option.onMessage(ev.data)
            }
        }
        if (syncChannel.current === null) {
            syncChannel.current = new SyncChannel<T>(name)
            syncChannel.current.addEventListener('message', onMessage)
        }
        return () => {
            if (syncChannel.current) {
                syncChannel.current.removeEventListener('message', onMessage)
                syncChannel.current.close()
                syncChannel.current = null
            }
        }
    }, [name, option, save])

    return [sendMessage, { recevied, currentlyRecevied, close }] as const
}
