import { domLocation, RouteToken } from '../../../../../util/src'
import { OrganizationVO, PayPlanType, RoleStatus } from '../../../kernel/interface/type'
import { GetCurrentUserManagedWorkspaces } from '../../../kernel/request/workspace'
import { featureSwitchManager } from '../../../kernel/switch'
import { sortByString } from '../../util/sort'
import { SortOrder } from '../../util/types'
import { SpaceNotifyFlow } from './notify-flow'
import { getSpaceStore, ManagedWorkspaceStore, setSpaceStore, SpaceStore, SpaceStoreType } from './types'

const isInOrganizationSummaryPage = () => domLocation().pathname.includes(`${RouteToken.Admin}/summary`)

export const generateManagedWorkspaceState = (set: setSpaceStore, get: getSpaceStore): ManagedWorkspaceStore => ({
    allAccessibleWorkspaces: [], // 用户可访问的所有工作区(包括企业管理员权限获得的工作区，role 可能为 none)
    adminManagedWorkspaces: [], // 用户作为工作区管理员直接管理的工作区
    currentSelectedWorkspaceIdInAdmin: undefined, // 用户作为工作区管理员时，当前在企业管理中选中的工作区 id

    fetch: async (organization: OrganizationVO) => {
        if (!featureSwitchManager.isEnabled('organization-plus')) {
            return
        }
        const { id: orgId, planType, guest } = organization
        if (planType !== PayPlanType.enterprise || guest) {
            return
        }

        const workspaces = (await new GetCurrentUserManagedWorkspaces(orgId).start()).sort((a, b) => {
            return sortByString(a.name, b.name, SortOrder.Ascending)
        })

        // 企业管理员可访问所有工作区，工作区管理员只能访问指定工作区
        const adminManagedWorkspaces = workspaces.filter((w) => w.role === RoleStatus.Admin)
        set((state) => {
            state.managedWorkspaceStore.allAccessibleWorkspaces = workspaces
            state.managedWorkspaceStore.adminManagedWorkspaces = adminManagedWorkspaces
        })

        if (isInOrganizationSummaryPage()) {
            // 工作区管理员工作区变更时需要拉当前最新选择的工作区数据
            const { currentSelectedWorkspaceIdInAdmin } = get().managedWorkspaceStore
            const currentSelectedWorkspace = adminManagedWorkspaces.find(
                (w) => w.workspaceId === currentSelectedWorkspaceIdInAdmin
            )
            if (currentSelectedWorkspace && currentSelectedWorkspaceIdInAdmin) {
                get().organizationManageSummaryStore.fetchWorkspaceSummaryData(currentSelectedWorkspaceIdInAdmin, {
                    withLoading: false,
                })
            }
        }
    },
    setCurrentSelectedWorkspaceIdInAdmin: (workspaceId: string) => {
        set((state) => {
            state.managedWorkspaceStore.currentSelectedWorkspaceIdInAdmin = workspaceId
        })
    },
})

export const syncManagedWorkspaceState = (store: SpaceStoreType, notifyFlow: SpaceNotifyFlow) => {
    notifyFlow.addCreateWorkspaceCallback(() => {
        const { organization } = store.getState().organizationStore
        if (organization.planType === PayPlanType.enterprise) {
            store.getState().managedWorkspaceStore.fetch(organization)
        }
    })
    notifyFlow.addRemoveWorkspaceCallback(() => {
        const { organization } = store.getState().organizationStore
        if (organization.planType === PayPlanType.enterprise) {
            store.getState().managedWorkspaceStore.fetch(organization)
        }
    })
    notifyFlow.addUpdateWorkspaceCallback(() => {
        const { organization } = store.getState().organizationStore
        if (organization.planType === PayPlanType.enterprise) {
            store.getState().managedWorkspaceStore.fetch(organization)
        }
    })
}

export const hasManageableWorkspaceSelector = (state: SpaceStore) => {
    if (!featureSwitchManager.isEnabled('organization-plus')) {
        return false
    }
    return state.managedWorkspaceStore.adminManagedWorkspaces.length > 0
}
