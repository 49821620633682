import { getTranslationValue } from './i18n'

export const zhTranslation = {
    JustNow: '刚刚',
    MinuteAgo: '分钟前',
    MinutesAgo: '分钟前',
    HourAgo: '小时前',
    HoursAgo: '小时前',
    Today: '今天',
    Yesterday: '昨天',
    HourMinute: 'H:mm',
    MonthDay: 'M月D日',
    MonthDayHourMinute: 'M月D日 H:mm',
    YearMonthDay: 'Y年M月D日',
    YearMonthDay2: 'Y-MM-DD',
    YearMonthDayHourMinute: 'Y年M月D日 H:mm',
    Detail: 'Y-MM-DD HH:mm',
} as const

export const enTranslation = {
    JustNow: 'just now',
    MinuteAgo: 'minute ago',
    MinutesAgo: 'minutes ago',
    HourAgo: 'hour ago',
    HoursAgo: 'hours ago',
    Today: 'today',
    Yesterday: 'yesterday',
    HourMinute: 'h:mm A',
    MonthDay: 'MMM D',
    MonthDayHourMinute: 'MMM D, h:mm A',
    YearMonthDay: 'MMM D, Y',
    YearMonthDay2: 'MMM D, Y',
    YearMonthDayHourMinute: 'MMM D, Y, h:mm A',
    Detail: 'Y-MM-DD hh:mm A',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
