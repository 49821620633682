/* eslint-disable no-restricted-imports */
import { useViewState } from '../../../../view-state-bridge'
import styles from './index.module.less'

export function LayerPanelAILayoutColorfulLine() {
    const lineState = useViewState('layerPanelAILayoutColorLine')
    return (
        <>
            {lineState?.lines.map((line, i) => {
                return (
                    <div
                        key={i}
                        className={styles.colorfulLine}
                        style={{
                            height: `${lineState.lines[i].lineSize ?? 0}px`,
                            transform: `translateY(${lineState.lines[i].lineTopOffset ?? 0}px)`,
                        }}
                    ></div>
                )
            })}
        </>
    )
}
