import classNames from 'classnames'
import { usePageType } from '../../../../../space/app/loading-container/use-page-type'
import { BlockUnit } from '../../../../../space/app/loading-container/layout/block-unit'
import { MonoIconControlArrowDown12 } from '../../../../../../../ui-lib/src/icons-v2/mono/12'
import styles from './top.module.less'

export const TopBar = () => {
    const pageType = usePageType()

    return (
        <div className={classNames('flex flex-wrap gap-x-8 gap-y-2 px-8 items-center justify-between py-5')}>
            <div className={classNames('flex-1', pageType.isFolder ? '1024:max-w-400px' : '960:max-w-400px')}>
                <BlockUnit className="w-full h-8" />
            </div>

            <div
                className={classNames(
                    'flex items-center gap-x-4 gap-y-2 w-full justify-end flex-wrap',
                    pageType.isFolder ? '1024:w-auto' : '960:w-auto'
                )}
            >
                <BlockUnit className="w-131px h-8" />

                <BlockUnit className="w-92px h-8" />

                <div className="flex items-center gap-x-4 relative left-2px">
                    <div className={styles['split-line']} />

                    <BlockUnit className="w-32px h-8" />

                    <div className="flex items-center gap-2">
                        <div className="bg-$wk-gray-2 rounded-50% w-32px h-32px" />

                        <MonoIconControlArrowDown12 className="color-$wk-v2-label-color-gray-8 relative -left-2px opacity-50" />
                    </div>
                </div>
            </div>
        </div>
    )
}
