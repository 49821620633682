/* eslint-disable no-restricted-imports */
import { SetStrokeCapForSelectionWasmCall, SwitchStrokeEndPointCapCommand, Wukong } from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { useCallback, useMemo, useRef } from 'react'
import { useDeepEqual } from '../../../../../../../util/src/hooks/use-deep-equal'
import { StrokeCap } from '../../../../../document/node/node'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'

function isTipEndPointType(
    strokeCap: StrokeCap
): strokeCap is StrokeCap.STROKE_CAP_ROUND | StrokeCap.STROKE_CAP_SQUARE {
    return strokeCap === StrokeCap.STROKE_CAP_ROUND || strokeCap === StrokeCap.STROKE_CAP_SQUARE
}

export function useStrokeEndPoint() {
    const command = useCommand()
    const selectionStroke = useViewState('selectionStrokeV2')
    const endPointStyleState = useDeepEqual(selectionStroke?.endPointStyleState)
    const isShow = useMemo(
        () =>
            !isNil(selectionStroke?.paintState?.type) &&
            selectionStroke?.paintState?.type !== Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_EMPTY,
        [selectionStroke?.paintState?.type]
    )
    const showAsPair = useMemo(
        () =>
            endPointStyleState?.showType ===
            Wukong.DocumentProto.SelectionStrokeEndPointShowType.SELECTION_STROKE_END_POINT_SHOW_TYPE_PAIR,
        [endPointStyleState?.showType]
    )
    const showAsSingle = useMemo(
        () =>
            endPointStyleState?.showType ===
                Wukong.DocumentProto.SelectionStrokeEndPointShowType.SELECTION_STROKE_END_POINT_SHOW_TYPE_SINGLE ||
            endPointStyleState?.showType ===
                Wukong.DocumentProto.SelectionStrokeEndPointShowType.SELECTION_STROKE_END_POINT_SHOW_TYPE_DISABLED,
        [endPointStyleState?.showType]
    )

    const mainEndPointRef = useRef<'start' | 'end'>()
    const needChangeOtherCap = useCallback(
        (type: 'start' | 'end', strokeCap: Wukong.DocumentProto.StrokeCap) => {
            if (
                !isTipEndPointType(strokeCap) ||
                endPointStyleState?.showType !==
                    Wukong.DocumentProto.SelectionStrokeEndPointShowType.SELECTION_STROKE_END_POINT_SHOW_TYPE_PAIR ||
                endPointStyleState?.pairState?.startState.type !==
                    Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_NORMAL ||
                endPointStyleState?.pairState?.endState.type !==
                    Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_NORMAL ||
                (mainEndPointRef.current && type !== mainEndPointRef.current)
            ) {
                mainEndPointRef.current = undefined
                return false
            }
            if (mainEndPointRef.current === undefined) {
                if (
                    endPointStyleState?.pairState &&
                    endPointStyleState?.pairState?.startState.capValue ===
                        endPointStyleState.pairState?.endState.capValue &&
                    (isTipEndPointType(endPointStyleState.pairState?.startState.capValue) ||
                        endPointStyleState.pairState?.startState.capValue ===
                            Wukong.DocumentProto.StrokeCap.STROKE_CAP_NONE)
                ) {
                    mainEndPointRef.current = type
                    return true
                }
                const isSameAfterChangedStart =
                    type === 'start' && strokeCap === endPointStyleState?.pairState?.endState.capValue
                const isSameAfterChangedEnd =
                    type === 'end' && strokeCap === endPointStyleState?.pairState?.startState.capValue
                if (isSameAfterChangedStart || isSameAfterChangedEnd) {
                    mainEndPointRef.current = type
                    return false
                }
            }

            return mainEndPointRef.current === type
        },
        [endPointStyleState]
    )

    const onChangeStartCap = useCallback(
        (strokeCap: Wukong.DocumentProto.StrokeCap) => {
            command.DEPRECATED_invokeBridge(
                SetStrokeCapForSelectionWasmCall,
                needChangeOtherCap('start', strokeCap) ? { allPointCap: strokeCap } : { startPointCap: strokeCap }
            )
            command.commitUndo()
        },
        [command, needChangeOtherCap]
    )

    const onChangeEndCap = useCallback(
        (strokeCap: Wukong.DocumentProto.StrokeCap) => {
            command.DEPRECATED_invokeBridge(
                SetStrokeCapForSelectionWasmCall,
                needChangeOtherCap('end', strokeCap) ? { allPointCap: strokeCap } : { endPointCap: strokeCap }
            )
            command.commitUndo()
        },
        [command, needChangeOtherCap]
    )

    const isShowSwitchIcon = useMemo(
        () =>
            endPointStyleState?.showType ===
                Wukong.DocumentProto.SelectionStrokeEndPointShowType.SELECTION_STROKE_END_POINT_SHOW_TYPE_PAIR &&
            endPointStyleState?.pairState?.startState.type ===
                Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_NORMAL &&
            endPointStyleState?.pairState?.endState.type ===
                Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_NORMAL &&
            endPointStyleState?.pairState?.startState.capValue !== endPointStyleState?.pairState?.endState.capValue,
        [
            endPointStyleState?.showType,
            endPointStyleState?.pairState?.startState.type,
            endPointStyleState?.pairState?.startState.capValue,
            endPointStyleState?.pairState?.endState.type,
            endPointStyleState?.pairState?.endState.capValue,
        ]
    )

    const onClickSwitch = useCallback(() => {
        if (isShowSwitchIcon) {
            command.DEPRECATED_invokeBridge(SwitchStrokeEndPointCapCommand)
            command.commitUndo()
        }
    }, [command, isShowSwitchIcon])

    const onChangeSingleCap = useCallback(
        (strokeCap: Wukong.DocumentProto.StrokeCap) => {
            command.DEPRECATED_invokeBridge(SetStrokeCapForSelectionWasmCall, { allPointCap: strokeCap })
            command.commitUndo()
        },
        [command]
    )

    return {
        isShow,
        isShowSwitchIcon,
        endPointStyleState,
        showAsPair,
        showAsSingle,
        onChangeStartCap,
        onChangeEndCap,
        onClickSwitch,
        onChangeSingleCap,
    }
}
