/**
 * @owner: wangshuleibj@kanyun.com
 */
import constate from 'constate'
import { useEffect } from 'react'
import { wkDialogConfirm } from '../wk-confirm'
import { usePopoverContextHook } from './popover-context'

interface ModalCallbackOption {
    id: string
    title?: string
}

interface LibConfigProviderProps {
    onModalCallback?: (v: boolean | undefined, opt: ModalCallbackOption) => void
}

function useLibConfigContextHook(config: LibConfigProviderProps) {
    const popoverContext = usePopoverContextHook()
    useEffect(() => {
        wkDialogConfirm.init({ onVisibleChange: config.onModalCallback })
    }, [config.onModalCallback])
    return {
        ...config,
        popoverContext,
    }
}

export const [LibConfigProvider, usePopoverContext, useModalContext] = constate(
    useLibConfigContextHook,
    (ctx) => ctx.popoverContext,
    (ctx) => ({ onModalCallback: ctx.onModalCallback })
)
