import { WebGLRenderingContextCompat } from '../../../../util/src'

export interface WebGLGlobalCanvas {
    canvas: HTMLCanvasElement
    context: WebGLRenderingContextCompat
}

export class GlobalCanvas {
    private static webgl_: WebGLGlobalCanvas | null = null

    static webgl(): WebGLGlobalCanvas | null {
        // 如果 context lost，需要重新创建
        if (this.webgl_) {
            if (this.webgl_.context.isContextLost()) {
                this.webgl_ = null
            }
        }

        if (!this.webgl_) {
            const canvas = document.createElement('canvas')
            canvas.width = 1
            canvas.height = 1
            const context2 = canvas.getContext('webgl2')
            if (context2) {
                this.webgl_ = { canvas, context: context2 }
            } else {
                const context1 = canvas.getContext('webgl')
                if (context1) {
                    this.webgl_ = { canvas, context: context1 }
                }
            }
        }

        return this.webgl_
    }
}

interface Pod {
    cx: GPUCanvasContext | null
    size: number
}

export class GlobalWebGPUCanvas {
    private _pods: Pod[] = [
        {
            cx: null,
            size: 1,
        },
        {
            cx: null,
            size: 256,
        },
        {
            cx: null,
            size: 2048,
        },
    ]
    private static _inst: GlobalWebGPUCanvas | null = null

    private _createWebgpuContext(w: number, h: number): GPUCanvasContext | null {
        const el = document.createElement('canvas')
        el.width = w
        el.height = h
        return el.getContext('webgpu')
    }

    private _getFromPod(pod: Pod): GPUCanvasContext | null {
        if (pod.cx) {
            return pod.cx
        }

        pod.cx = this._createWebgpuContext(pod.size, pod.size)
        return pod.cx
    }

    private _get(width: number, height: number): GPUCanvasContext | null {
        if (width === height) {
            const pod = this._pods.find((t) => t.size === width && t.size === height)
            if (pod) {
                return this._getFromPod(pod)
            }
        }
        return this._createWebgpuContext(width, height)
    }

    public static context(width: number, height: number): GPUCanvasContext | null {
        return GlobalWebGPUCanvas.inst()._get(width, height)
    }

    private static inst(): GlobalWebGPUCanvas {
        if (!GlobalWebGPUCanvas._inst) {
            GlobalWebGPUCanvas._inst = new GlobalWebGPUCanvas()
        }
        return GlobalWebGPUCanvas._inst
    }
}
