import { Wukong } from '@wukong/bridge-proto'
import { isRightClick } from '../../../../ui-lib/src'
import { useCallback } from 'react'
import { useRightClickOpen } from '../../ui/component/top-area/menu/right-click-menu/right-click-menu'
import RightClickSource = Wukong.DocumentProto.RightClickSource

export function useHandleRightClickEvent(source: RightClickSource) {
    const open = useRightClickOpen()

    const handle = useCallback(
        (event: React.MouseEvent) => {
            if (isRightClick(event)) {
                event.preventDefault()
                event.stopPropagation()

                open({
                    source,
                    position: {
                        left: event.clientX,
                        top: event.clientY + 8,
                    },
                })
            }
        },
        [open, source]
    )

    return handle
}
