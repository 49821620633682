/* eslint-disable no-restricted-imports */
import { showImageResizeTip } from '../../../../../ui-lib/src'
import { CommandInvoker } from '../../../document/command/command-invoker'
import { showPasteImageFailureModal } from '../../../document/command/image-command'
import { ImageDownloadContext } from '../../../document/command/image-download-context'
import { WkCLog } from '../../../kernel/clog/wukong/instance'
import { getIntegrationTestSupports } from '../../supports'
import { PLAIN_TEXT_SLUG, WkDataTransfer, isImage } from './clipboard-data'
import { ClipboardDataFile, ClipboardDataItem, ClipboardDataPromiseFile } from './clipboard-data-types'
import { extractErrorMessage } from './clipboard-util'
import { ExecClipboardCommandHelper } from './exec-clipboard-command-helper'
import { INavigatorClipboardSupports, NavigatorClipboardService } from './navigator-clipboard-service'

export class ClipboardDataManager {
    private eventDataTransfer: DataTransfer | null = null
    private navigatorSupports: INavigatorClipboardSupports
    public dataTransfer: WkDataTransfer

    constructor(private commandInvoker: CommandInvoker, private imageDownloadContext: ImageDownloadContext) {
        this.dataTransfer = new WkDataTransfer(commandInvoker)
        this.navigatorSupports =
            getIntegrationTestSupports()?.navigatorClipboardSupports ?? new NavigatorClipboardService()
    }

    public writeClipboardItems(items: ClipboardDataItem[]): boolean {
        if (this.eventDataTransfer) {
            for (const item of items) {
                this.eventDataTransfer.setData(item.type, item.content)
            }
            this.dataTransfer.rebuildItems(items)
            return true
        }

        // 作为降级方案，只能复制一条纯文本信息
        const getPossibleText = () => {
            const plainTextItem = items.find((item) => item.type === PLAIN_TEXT_SLUG)
            if (plainTextItem) {
                return plainTextItem.content
            }
            return null
        }
        const copyPossibleText = () => {
            const possibleText = getPossibleText()
            if (possibleText) {
                ExecClipboardCommandHelper.execCommandToCopy(possibleText)
            }
        }

        if (this.navigatorSupports.doesClipboardApiAvailable()) {
            this.navigatorSupports.writeToClipboard(items).catch((e) => {
                console.error('fallback', e)
                copyPossibleText()
            })
            this.dataTransfer.rebuildItems(items)
            return true
        }
        this.dataTransfer.rebuildItems(items)
        copyPossibleText()

        // 返回值没有作用，因为无法在同步时间内知道是否真的粘贴进入了剪切板
        return true
    }

    public async writeClipboardFiles(files: ClipboardDataFile[]): Promise<boolean> {
        if (this.eventDataTransfer) {
            files.forEach((file) => {
                const nativeFile = new File([file.buffer], file.name, {
                    type: file.type,
                })
                this.eventDataTransfer!.items.add(nativeFile)
            })
            return true
        }

        if (this.navigatorSupports.doesClipboardApiAvailable()) {
            await this.navigatorSupports.writeToClipboard(files)
            this.dataTransfer.rebuildFiles(files)
            return true
        }
        return false
    }

    public async writeClipboardPromiseFiles(promiseFiles: ClipboardDataPromiseFile[]): Promise<boolean> {
        if (this.navigatorSupports.doesClipboardApiAvailable()) {
            await this.navigatorSupports.writeToClipboardPromiseFiles(promiseFiles)

            const files: ClipboardDataFile[] = []
            for (const promiseFile of promiseFiles) {
                const blob = await promiseFile.buffer
                files.push({
                    type: promiseFile.type,
                    name: promiseFile.name,
                    buffer: blob,
                    size: blob.byteLength,
                })
            }
            this.dataTransfer.rebuildFiles(files)
            return true
        }
        return false
    }

    public keepClipboardEventForWritting(ev: ClipboardEvent, executor: () => void) {
        this.eventDataTransfer = ev.clipboardData
        executor()
        this.eventDataTransfer = null
    }

    public async tryUpdateDataTransferItemsFromClipboardEvent(ev: ClipboardEvent): Promise<void> {
        const dataTransfer = ev.clipboardData
        if (dataTransfer) {
            await this.dataTransfer.rebuildItemsFromDataTransfer(dataTransfer)
        }
    }

    public async tryUpdateDataTransferForPasteEvent(event: ClipboardEvent) {
        if (!event.clipboardData) {
            return
        }

        const hasOneEmptyFile = (() => {
            if (event.clipboardData.files.length === 1 && event.clipboardData.items.length !== 0) {
                const size = event.clipboardData.files[0].size
                return size === 0
            }
            return false
        })()

        if (event.clipboardData.files.length === 0 || hasOneEmptyFile) {
            await this.dataTransfer.rebuildItemsFromDataTransfer(event.clipboardData)
            this.dataTransfer.trace('update-data-transfer-by-event')
            return
        }

        const onePNG = event.clipboardData.files.length === 1 && event.clipboardData.files[0].type === 'image/png'
        // 先读取 clipboard event
        await this.dataTransfer.rebuildItemsAndFiles(event.clipboardData)
        if (onePNG) {
            try {
                // 再读取 Navigator Clipboard API
                const clipboardItems = await this.navigatorSupports.readFromClipboard()
                const newDataTransfer = new WkDataTransfer(this.commandInvoker)
                await newDataTransfer.rebuildItemsAndFilesFromClipboardItems(clipboardItems)
                if (this.handleDataTransferWebPng(newDataTransfer)) {
                    this.dataTransfer = newDataTransfer
                }
            } catch (e) {
                console.warn(e)
            }
        }
        await this.prepareImageInfos(this.dataTransfer)
        this.dataTransfer.trace('update-data-transfer-by-event')
    }

    public async tryUpdateDataTransferFromClipboardApi() {
        try {
            const clipboardItems = await this.navigatorSupports.readFromClipboard()
            await this.dataTransfer.rebuildItemsAndFilesFromClipboardItems(clipboardItems)
            this.handleDataTransferWebPng(this.dataTransfer)
            await this.prepareImageInfos(this.dataTransfer)
            this.dataTransfer.trace('update-data-transfer-by-navigator')
        } catch (e) {
            console.warn('cannot read data from clipboard api', e)
            this.dataTransfer.trace('update-data-transfer-by-navigator')
            WkCLog.throttleLog('WK_CLIPBOARD', {
                scope: 'update-data-transfer-by-navigator-error',
                error: extractErrorMessage(e),
            })
        }
    }

    private handleDataTransferWebPng(dataTransfer: WkDataTransfer) {
        const types = dataTransfer.files.map((f) => f.type)
        if (types.length === 2 && types.includes('image/png') && types.includes('web image/png')) {
            dataTransfer.files = dataTransfer.files
                .filter((f) => f.type === 'web image/png')
                .map((f) => ({ ...f, type: 'image/png' }))
            return true
        }
        return false
    }

    private async prepareImageInfos(dataTransfer: WkDataTransfer) {
        let hasCompressed = false
        let handledPastingImageFailure = false
        dataTransfer.imageInfos = []
        await Promise.all(
            dataTransfer.files.map(async (file) => {
                if (isImage(file.type)) {
                    try {
                        const blob = dataTransfer.getBlobByBlobId(file.blobId)
                        if (!blob) {
                            throw Error('failed to get blob')
                        }

                        const result = await this.imageDownloadContext.addImageSource(blob, file.name)
                        if (!result.sucess) {
                            throw Error('addImageSource fail')
                        }
                        if (result.hasCompressed) {
                            hasCompressed = true
                        }
                        dataTransfer.imageInfos.push({
                            imageInfo: result.imageInfo,
                            blobId: file.blobId,
                            blobSize: blob.size,
                        })
                    } catch (_e) {
                        WkCLog.throttleLog('WK_CLIPBOARD', {
                            scope: 'paste-prepare-image-infos',
                            error: extractErrorMessage(_e),
                        })

                        // WK-14172: 部分图片文件失败时，粘贴操作需要继续
                        if (handledPastingImageFailure) {
                            return
                        }
                        showPasteImageFailureModal()
                        handledPastingImageFailure = true
                    }
                }
            })
        )
        if (hasCompressed) {
            showImageResizeTip()
        }
    }
}
