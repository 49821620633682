import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    ViewerSeat: '查看席位',
    DeveloperSeat: '研发席位',
    DesignerSeat: '设计席位',
} as const

export const enTranslation = {
    ViewerSeat: 'Viewer seat',
    DeveloperSeat: 'Developer seat',
    DesignerSeat: 'Designer seat',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
