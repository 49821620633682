import { GET } from '@tutor/network-core'
import { OrgID } from '../interface/type'
import { BaseCommonRequest } from './base-request'

@GET
export class GetSupportSchemaVersionByOrgId extends BaseCommonRequest<{ schemaVersion: number }> {
    constructor(private readonly orgId: OrgID) {
        super()
    }
    public override requestUrl() {
        return `organizations/${this.orgId}/new-doc-schema-version`
    }
}
