import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    ApplyVariable: '使用变量',
    UnbindVariable: '分离变量',
    VariableOverLimit: '变量超限',
} as const

export const enTranslation = {
    ApplyVariable: 'Apply variable',
    UnbindVariable: 'Detach variable',
    VariableOverLimit: 'Value limit exceeded',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
