import { isWindowsOrLinux } from '../util/ua'

export const enum ModifierKeysCombo {
    NONE = 0,
    META = 512,
    META_ALT = 768,
    META_SHIFT = 1536,
    META_SHIFT_ALT = 1792,
    META_CTRL = 2560,
    META_CTRL_ALT = 2816,
    META_CTRL_SHIFT = 3584,
    META_CTRL_SHIFT_ALT = 3840,
    CTRL = 2048,
    CTRL_ALT = 2304,
    CTRL_SHIFT = 3072,
    CTRL_SHIFT_ALT = 3328,
    ALT_SHIFT = 1280,
    ALT = 256,
    SHIFT = 1024,
}
function generateModifierKeysCombo(e: Pick<KeyboardEvent, 'altKey' | 'metaKey' | 'shiftKey' | 'ctrlKey'>) {
    return (e.altKey ? 256 : 0) | (e.metaKey ? 512 : 0) | (e.shiftKey ? 1024 : 0) | (e.ctrlKey ? 2048 : 0)
}

// 修饰键组合匹配，严格匹配
export function isCombo(e: KeyboardEvent, expected: ModifierKeysCombo) {
    const event = {
        altKey: e.altKey,
        metaKey: e.metaKey,
        shiftKey: e.shiftKey,
        ctrlKey: e.ctrlKey,
    }
    if (isWindowsOrLinux()) {
        event.metaKey = e.ctrlKey
        event.ctrlKey = e.metaKey
    }
    const combo = generateModifierKeysCombo(event)
    return combo === expected
}

export enum KeyboardCode {
    // 英文字符
    A = 'KeyA',
    B = 'KeyB',
    C = 'KeyC',
    D = 'KeyD',
    E = 'KeyE',
    F = 'KeyF',
    G = 'KeyG',
    H = 'KeyH',
    I = 'KeyI',
    J = 'KeyJ',
    K = 'KeyK',
    L = 'KeyL',
    M = 'KeyM',
    N = 'KeyN',
    O = 'KeyO',
    P = 'KeyP',
    Q = 'KeyQ',
    R = 'KeyR',
    S = 'KeyS',
    T = 'KeyT',
    U = 'KeyU',
    V = 'KeyV',
    W = 'KeyW',
    X = 'KeyX',
    Y = 'KeyY',
    Z = 'KeyZ',
    SPACE = 'Space',
    // 主键盘数字
    NUM_0 = 'Digit0',
    NUM_1 = 'Digit1',
    NUM_2 = 'Digit2',
    NUM_3 = 'Digit3',
    NUM_4 = 'Digit4',
    NUM_5 = 'Digit5',
    NUM_6 = 'Digit6',
    NUM_7 = 'Digit7',
    NUM_8 = 'Digit8',
    NUM_9 = 'Digit9',
    // 小键盘数字
    PAD_0 = 'Numpad0',
    PAD_1 = 'Numpad1',
    PAD_2 = 'Numpad2',
    PAD_3 = 'Numpad3',
    PAD_4 = 'Numpad4',
    PAD_5 = 'Numpad5',
    PAD_6 = 'Numpad6',
    PAD_7 = 'Numpad7',
    PAD_8 = 'Numpad8',
    PAD_9 = 'Numpad9',
    // F 键
    F1 = 'F1',
    F2 = 'F2',
    F3 = 'F3',
    F4 = 'F4',
    F5 = 'F5',
    F6 = 'F6',
    F7 = 'F7',
    F8 = 'F8',
    F9 = 'F9',
    F10 = 'F10',
    F11 = 'F11',
    F12 = 'F12',
    F13 = 'F13',
    // 运算符及标点符号
    '+=' = 'Equal',
    '-' = 'Minus',
    'PAD+' = 'NumpadAdd',
    'PAD-' = 'NumpadSubtract',
    'PAD*' = 'NumpadMultiply',
    'PAD/' = 'NumpadDivide',
    '/?' = 'Slash',
    '\\|' = 'Backslash',
    '"' = 'Quote',
    '~' = 'Backquote',
    '[' = 'BracketLeft',
    ']' = 'BracketRight',
    ',<' = 'Comma',
    '.>' = 'Period',
    'PAD.' = 'NumpadDecimal',
    ';:' = 'Semicolon',
    // 功能键
    ESCAPE = 'Escape',
    BACKSPACE = 'Backspace',
    ENTER = 'Enter',
    'PAD_ENTER' = 'NumpadEnter',
    INSERT = 'Insert',
    DELETE = 'Delete',
    HOME = 'Home',
    END = 'End',
    PAGE_UP = 'PageUp',
    PAGE_DOWN = 'PageDown',
    ARROW_LEFT = 'ArrowLeft',
    ARROW_RIGHT = 'ArrowRight',
    ARROW_UP = 'ArrowUp',
    ARROW_DOWN = 'ArrowDown',
    TAB = 'Tab',
    NUM_LOCK = 'NumLock',
    CAPSLOCK = 'CapsLock',
    CONTEXT_MENU = 'ContextMenu',
    // 修饰键
    SHIFT_LEFT = 'ShiftLeft',
    SHIFT_RIGHT = 'ShiftRight',
    CTRL_LEFT = 'ControlLeft',
    CTRL_RIGHT = 'ControlRight',
    ALT_LEFT = 'AltLeft',
    ALT_RIGHT = 'AltRight',
    META_LEFT = 'MetaLeft',
    META_RIGHT = 'MetaRight',
}

// 是否有 meta/ctrl 键
export function hasCmd(e: KeyboardEvent) {
    const event = {
        altKey: e.altKey,
        metaKey: e.metaKey,
        shiftKey: e.shiftKey,
        ctrlKey: e.ctrlKey,
    }
    if (isWindowsOrLinux()) {
        event.metaKey = e.ctrlKey
        event.ctrlKey = e.metaKey
    }
    return event.metaKey || event.ctrlKey
}

// 全键位匹配，满足其一即可
export function isKey(e: KeyboardEvent, key: KeyboardCode | KeyboardCode[]) {
    return (Array.isArray(key) ? key : [key]).some((k) => e.code === k)
}

// 兼容小键盘的数字键
export function isKeyAsNumber(e: KeyboardEvent, num: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9) {
    return isKey(e, [KeyboardCode[`NUM_${num}`], KeyboardCode[`PAD_${num}`]])
}

// 兼容小键盘的 + - * / 键
export function isKeyAsOperator(e: KeyboardEvent, operator: '+' | '-' | '*' | '/') {
    switch (operator) {
        case '+':
            return isKey(e, [KeyboardCode['+='], KeyboardCode['PAD+']])
        case '-':
            return isKey(e, [KeyboardCode['-'], KeyboardCode['PAD-']])
        case '*':
            return isKey(e, KeyboardCode['PAD*'])
        case '/':
            return isKey(e, [KeyboardCode['/?'], KeyboardCode['PAD/']])
    }
}

// 兼容小键盘的 . 键
export function isKeyAsDecimal(e: KeyboardEvent) {
    return isKey(e, [KeyboardCode['.>'], KeyboardCode['PAD.']])
}

// 兼容小键盘的 enter 键
export function isKeyAsEnter(e: KeyboardEvent) {
    return isKey(e, [KeyboardCode.ENTER, KeyboardCode.PAD_ENTER])
}

export function isUndoEvent(e: KeyboardEvent) {
    return isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.Z)
}

export function isUndoRedoEvent(e: KeyboardEvent) {
    return (isCombo(e, ModifierKeysCombo.META) || isCombo(e, ModifierKeysCombo.META_SHIFT)) && isKey(e, KeyboardCode.Z)
}
