import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    NoMirroring: '不对称',
    MirrorAngle: '角度对称',
    MirrorAngle_synonyms1: '角度和长度对称',
    Vector: '矢量',
} as const

export const enTranslation = {
    NoMirroring: 'No mirroring',
    MirrorAngle: 'Mirror angle',
    MirrorAngle_synonyms1: 'Mirror angle and length',
    Vector: 'Vector',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
