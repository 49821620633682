import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    Untitled: '未命名',
    MissingFonts: '字体缺失',
    VariableCount: '{{count}} 个变量',
    SearchVariableCount: '匹配到变量 {{count}}',
} as const

export const enTranslation = {
    Untitled: 'Untitled',
    MissingFonts: 'Missing fonts',
    VariableCount: '{{count}} variable{{suffix}}',
    SearchVariableCount: 'Match to {{count}} variable{{suffix}}',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
