// 这里放研发模式代码相关的工具
import { UpdateDevModeCodeConfigCommand, UpdateDevModeInspectCodeTypeCommand, Wukong } from '@wukong/bridge-proto'
import { cloneDeep } from 'lodash-es'
import { useCallback } from 'react'
import { useAppContext } from '../../../../../main/app-context'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'
import CodeType = Wukong.DocumentProto.DevModeInspectCodeType
type CodeConfigKey = keyof Wukong.DocumentProto.IDevModeInspectCodeConfig

export const CodeType2CodeConfigKey: Record<CodeType, CodeConfigKey> = {
    [CodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS]: 'css',
    [CodeType.iOS_UI_Kit]: 'ios',
    [CodeType.iOS_Swift_UI]: 'swift',
    [CodeType.Android_XML]: 'android',
    [CodeType.Android_Compose]: 'compose',
}

export const DevModeCodeTypeOptions = [
    CodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS,
    CodeType.iOS_UI_Kit,
    CodeType.Android_XML,
]

export const DevModeCodeTypeLabels: Record<CodeType, string> = {
    [CodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS]: 'CSS',
    [CodeType.iOS_UI_Kit]: 'iOS',
    [CodeType.iOS_Swift_UI]: 'Swift',
    [CodeType.Android_XML]: 'Android',
    [CodeType.Android_Compose]: 'Compose',
}

export const DevModeCodeTypeLabelsV2: Record<CodeType, string> = {
    [CodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS]: 'CSS',
    [CodeType.iOS_UI_Kit]: 'UIKit',
    [CodeType.iOS_Swift_UI]: 'SwiftUI',
    [CodeType.Android_XML]: 'XML',
    [CodeType.Android_Compose]: 'Compose',
}

// 新版型代码类型选项 是多级菜单
export const DevModeCodeTypeOptionsV2 = [
    {
        name: DevModeCodeTypeLabelsV2[CodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS],
        customNode: {
            value: CodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS,
        },
    },
    {
        name: 'iOS',
        children: [
            {
                name: DevModeCodeTypeLabelsV2[CodeType.iOS_UI_Kit],
                customNode: {
                    value: CodeType.iOS_UI_Kit,
                },
            },
            {
                name: DevModeCodeTypeLabelsV2[CodeType.iOS_Swift_UI],
                customNode: {
                    value: CodeType.iOS_Swift_UI,
                },
            },
        ],
    },
    {
        name: 'Android',
        children: [
            {
                name: DevModeCodeTypeLabelsV2[CodeType.Android_XML],
                customNode: {
                    value: CodeType.Android_XML,
                },
            },
            {
                name: DevModeCodeTypeLabelsV2[CodeType.Android_Compose],
                customNode: {
                    value: CodeType.Android_Compose,
                },
            },
        ],
    },
]

export const CssUnit2lable: Record<Wukong.DocumentProto.CssUnit, string> = {
    [Wukong.DocumentProto.CssUnit.CSS_UNITPX]: 'px',
    [Wukong.DocumentProto.CssUnit.CSS_UNITREM]: 'rem',
}

export const CssUnitOptions = [Wukong.DocumentProto.CssUnit.CSS_UNITPX, Wukong.DocumentProto.CssUnit.CSS_UNITREM]

export const IOSUnit2lable: Record<Wukong.DocumentProto.IOSUnit, string> = {
    [Wukong.DocumentProto.IOSUnit.I_O_S_UNITPX]: 'px',
    [Wukong.DocumentProto.IOSUnit.I_O_S_UNITPT]: 'pt',
}

export const IOSUnitOptions = [Wukong.DocumentProto.IOSUnit.I_O_S_UNITPT, Wukong.DocumentProto.IOSUnit.I_O_S_UNITPX]

export const AndroidUnit2lable: Record<Wukong.DocumentProto.AndroidUnit, string> = {
    [Wukong.DocumentProto.AndroidUnit.ANDROID_UNITPX]: 'px',
    [Wukong.DocumentProto.AndroidUnit.ANDROID_UNITDP]: 'dp/sp',
}

export const AndroidUnitOptions = [
    Wukong.DocumentProto.AndroidUnit.ANDROID_UNITDP,
    Wukong.DocumentProto.AndroidUnit.ANDROID_UNITPX,
]

export const DevModeCodeConfigRatioOptions = [
    Wukong.DocumentProto.CodeRatio.CODE_RATIO_ONE_THIRD,
    Wukong.DocumentProto.CodeRatio.CODE_RATIO_ONE_HALF,
    Wukong.DocumentProto.CodeRatio.CODE_RATIO_ONE,
    Wukong.DocumentProto.CodeRatio.CODE_RATIO_TWO,
    Wukong.DocumentProto.CodeRatio.CODE_RATIO_THREE,
]

export const DevModeCodeConfigRatioLabels: Record<Wukong.DocumentProto.CodeRatio, string> = {
    [Wukong.DocumentProto.CodeRatio.CODE_RATIO_ONE_THIRD]: '1/3',
    [Wukong.DocumentProto.CodeRatio.CODE_RATIO_ONE_HALF]: '1/2',
    [Wukong.DocumentProto.CodeRatio.CODE_RATIO_ONE]: '1',
    [Wukong.DocumentProto.CodeRatio.CODE_RATIO_TWO]: '2',
    [Wukong.DocumentProto.CodeRatio.CODE_RATIO_THREE]: '3',
}

type ChangeCodeConfigParams =
    | Wukong.DocumentProto.ICssCodeConfig
    | Wukong.DocumentProto.IIOSCodeConfig
    | Wukong.DocumentProto.AndroidCodeConfig

export const useChangeDevModeInspectCodeType = () => {
    const command = useCommand()
    const { codeConfig } = useViewState('devModeCodeConfig')!
    const userConfigService = useAppContext().userConfigService
    const changeType = useCallback(
        (_codeType: CodeType) => {
            command.DEPRECATED_invokeBridge(UpdateDevModeInspectCodeTypeCommand, { codeType: _codeType })
        },
        [command]
    )
    const changeCodeConfig = useCallback(
        <T extends CodeType>(codeType: T, param: ChangeCodeConfigParams) => {
            const config = cloneDeep(codeConfig)
            config[CodeType2CodeConfigKey[codeType]] = { ...config[CodeType2CodeConfigKey[codeType]], ...param }
            command.DEPRECATED_invokeBridge(UpdateDevModeCodeConfigCommand, { config })
            userConfigService.updateConfig2('devModeInspectCodeConfig', config)
        },
        [codeConfig, command, userConfigService]
    )
    return { changeType, changeCodeConfig }
}
