const shallowEqual = <T>(prev: T, curr: T) => prev === curr

export const distinctFn = <T>(fn: (value: T) => void, comparator: (prev: T, curr: T) => boolean = shallowEqual) => {
    let cache:
        | {
              has: false
          }
        | {
              has: true
              lastValue: T
          } = {
        has: false,
    }

    return (value: T): void => {
        if (cache.has) {
            if (!comparator(cache.lastValue, value)) {
                fn(value)
            }
        } else {
            fn(value)
        }

        cache = { has: true, lastValue: value }
    }
}
