/* eslint-disable no-restricted-imports */
import { CreateFrameTemplateCommand, Wukong } from '@wukong/bridge-proto'
import { useCallback, useMemo } from 'react'
import { useCommand } from '../../../../main/app-context'
import { useUserConfigStateV2 } from '../../../../main/user-config/user-config-hook'
import { FrameTemplateDOM } from './frame-template-dom'
import { FrameTemplateGroupName, FrameTemplateItem, getFrameTemplateData } from './template-data'

export function FrameTemplate() {
    const command = useCommand()
    const [openKeyV2, setOpenKeyV2] = useUserConfigStateV2('frameTemplateExpandItem')

    const toggleOpen = useCallback(
        (data: FrameTemplateGroupName) => {
            setOpenKeyV2(openKeyV2 === data.code ? '' : data.code)
        },
        [openKeyV2, setOpenKeyV2]
    )

    const applyTemplate = useCallback(
        (data: FrameTemplateItem) => {
            command.DEPRECATED_invokeBridge(
                CreateFrameTemplateCommand,
                Wukong.DocumentProto.FrameTemplateSizeName.create(data)
            )
            command.commitUndo()
        },
        [command]
    )

    return useMemo(
        () => (
            <FrameTemplateDOM
                openKey={openKeyV2 ?? ''}
                data={getFrameTemplateData()}
                toggleOpen={toggleOpen}
                applyTemplate={applyTemplate}
            />
        ),
        [openKeyV2, toggleOpen, applyTemplate]
    )
}
