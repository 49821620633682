import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Outline: '线性',
    Filled: '填充',
    'Two-tone': '双色',
    'Multi-color': '多色',
} as const

export const enTranslation = {
    Outline: 'Outline',
    Filled: 'Filled',
    'Two-tone': 'Two-tone',
    'Multi-color': 'Multi-color',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
