import classnames from 'classnames'
import { useMemo } from 'react'
import { EffectStyleThumbnail, EffectStyleThumbnailProps } from '../../../effects/effect-style/effect-style-thumbnail'
import { PaintStyleThumbnail, PaintStyleThumbnailProps } from '../../style-color/style-thumbnail'
import { TextStyleThumbnail, TextStyleThumbnailDOM, TextStyleThumbnailProps } from '../../style-text/style-thumbnail'
import classes from './common-thumbnail.module.less'

type TextStyleThumbnail = { data: TextStyleThumbnailProps['styleData'] } & { type: 'text' }
type PaintStyleThumbnail = { data: PaintStyleThumbnailProps['paints'] } & { type: 'paint' }
type EffectStyleThumbnail = { data: EffectStyleThumbnailProps['effects'] } & { type: 'effect' }

export type CommonThumbnailProps = (TextStyleThumbnail | PaintStyleThumbnail | EffectStyleThumbnail) & {
    width?: number
    height?: number
}
export function CommonThumbnail(props: CommonThumbnailProps) {
    const width = props.width ?? 240
    const height = props.height ?? 120

    const style = useMemo(() => ({ width, height }), [width, height])

    const thumbnail = useMemo(() => {
        switch (props.type) {
            case 'text':
                return <TextStyleThumbnailDOM styleData={props.data} width={width} height={height} mode="preview" />
            case 'paint':
                return (
                    <PaintStyleThumbnail
                        paints={props.data}
                        width={width}
                        height={height}
                        multiple={2}
                        mode="preview"
                    />
                )
            case 'effect':
                return <EffectStyleThumbnail effects={props.data} width={width} height={height} />
        }
    }, [props.type, props.data, width, height])

    return (
        <div
            className={classnames(classes.thumbnailContainer, {
                ['mosaic-8']: props.type === 'effect' || props.type === 'paint',
                [classes.pure]: !(props.type === 'effect' || props.type === 'paint'),
            })}
            style={style}
        >
            {thumbnail}
        </div>
    )
}
