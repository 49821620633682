import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    InGOlQ: '编辑失败',
    rJkbhc: '回复失败',
    cjYUjt: '标为已读失败',
    bOpBff: '标为未读失败',
    ENxogC: '标为解决失败',
    LyfVCa: '标为未解决失败',
    QlOqkM: '删除评论失败',
    hGzgLY: '删除失败',
    uSXmHF: '表情回应失败',
    LinkCopiedTo: '已复制评论链接',
} as const

export const enTranslation = {
    InGOlQ: 'An error occurred while editing',
    rJkbhc: 'An error occurred while replying',
    cjYUjt: 'An error occurred while masking as read',
    bOpBff: 'An error occurred while masking as unread',
    ENxogC: 'An error occurred while masking as resolved',
    LyfVCa: 'An error occurred while masking as unresolved',
    QlOqkM: 'An error occurred while deleting the comment',
    hGzgLY: 'An error occurred while deleting',
    uSXmHF: 'An error occurred while adding reaction',
    LinkCopiedTo: 'Link copied to clipboard',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
