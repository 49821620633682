import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    GetDesktopApp: '下载桌面客户端',
    OpenInDesktopApp: '在桌面客户端打开此文件',
} as const

export const enTranslation = {
    GetDesktopApp: 'Get desktop app',
    OpenInDesktopApp: 'Open in desktop app',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
