import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    'LeaveTeam...': '离开团队',
    Owner: '所有者',
    CopyLink: '复制链接',
    Rename: '重命名',
    ChangeIcon: '修改图标',
    ChangeTeamVisibility: '修改公开性',
    ChangeTeamAccess: '修改团队访问设置',
    TransferResourses: '资产交接',
    'DeleteTeam...': '删除团队',
} as const

export const enTranslation = {
    'LeaveTeam...': 'Leave team...',
    Owner: 'Owner',
    CopyLink: 'Copy link',
    Rename: 'Rename',
    ChangeIcon: 'Change icon',
    ChangeTeamVisibility: 'Change org access',
    ChangeTeamAccess: 'Change team discovery settings',
    TransferResourses: 'Transfer assets',
    'DeleteTeam...': 'Delete team...',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
