import { Checkbox } from '../../../../../../../ui-lib/src'
import { VariablePublishHiddenValue } from '../utils'
import { translation } from './index.translation'

export function VariablesPublishHidden({
    value,
    onChange,
}: {
    value: VariablePublishHiddenValue
    onChange: (checked: boolean) => void
}) {
    return (
        <div data-testid="variables-publish-hidden" className="py-3 px-4 flex box-border items-center">
            <Checkbox
                containerTestId={`publish-hidden-checkbox-${
                    value.mixed ? 'mixed' : value.value ? 'checked' : 'unchecked'
                }`}
                checked={value.value}
                indeterminate={value.mixed}
                label={translation('HideWhenPublishing')}
                onChange={onChange}
            />
        </div>
    )
}
