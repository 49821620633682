import React from 'react'
import classes from './transition-view.module.less'

export interface TransitionViewProps {}
export function TransitionView(props: React.PropsWithChildren<TransitionViewProps>) {
    return (
        <div className={classes.transitionView}>
            {props.children}
            <div className={classes.transitionViewBorderLine}></div>
        </div>
    )
}
