import { SetTextValueForSelectionCommand } from '@wukong/bridge-proto'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
    DropdownV2,
    InputV2,
    MonoIconPanelApplyProperty16,
    Tooltip,
    WKIconButton,
} from '../../../../../../../ui-lib/src'
import { TextAreaOperations } from '../../../../../../../ui-lib/src/components/inputs/textarea/textarea'
import { CommitType } from '../../../../../document/command/commit-type'
import { PopupStateType } from '../../../../../document/node/node'
import { useCommand } from '../../../../context/document-context'
import { SingleGrid } from '../../../atom/grid/single-grid'
import { useComponentProp } from '../../../design-panel-v2/component-instance-panel/components/component-prop/hook'
import { ComponentPropNodeField, VComponentPropType } from '../../../design-panel-v2/component-instance-panel/types'
import styles from './index.module.less'
import { translation } from './index.translation'

const CreateTextPropBtnAlias = 'create-text-prop-btn'

export function TextPropArea() {
    const {
        toggleComponentPropPopup,
        applyProp,
        showApplyIconForCreateTextProp,
        showApplyIconForApplyTextProp,
        showComponentTextArea,
        componentPropDefs,
        sharedTextValUnit,
    } = useComponentProp()

    const command = useCommand()
    const [textVal, setTextVal] = useState<string>(sharedTextValUnit.val)
    const textAreaRef = useRef<TextAreaOperations>(null)

    // 画布区域文本值变化时，更新文本区域 textArea 值
    useEffect(() => {
        setTextVal(sharedTextValUnit.val)
    }, [sharedTextValUnit.val])

    const textPropList = useMemo(() => {
        return (
            componentPropDefs.filter((compProp) => compProp.type === VComponentPropType.V_COMPONENT_PROP_TYPE_TEXT) ||
            []
        )
    }, [componentPropDefs])

    const [dropdownVisible, setDropdownVisible] = useState(false)

    if (!showComponentTextArea) {
        return null
    }

    const onSelectChange = (value: string): void => {
        // value 为 [xx:xx]形式 / CreateTextPropBtnAlias
        if (value !== CreateTextPropBtnAlias) {
            applyProp(ComponentPropNodeField.COMPONENT_PROP_NODE_FIELD_TEXT_DATA, value)
        } else {
            openTextAreaCreateTextPropPopup()
        }
    }

    const openTextAreaCreateTextPropPopup = () => {
        toggleComponentPropPopup(PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_TEXT_PROP_OF_TEXT_AREA)
    }

    return (
        <SingleGrid
            testId="text-area-prop-panel"
            className={styles.textAreaPropPanel}
            onKeyDown={(e) => e.stopPropagation()}
        >
            <SingleGrid.Item start={5} span={46}>
                <InputV2.Textarea
                    ref={textAreaRef}
                    autoHeight
                    maxHeight={104}
                    value={textVal}
                    onFocus={() => {
                        textAreaRef.current?.selectAll()
                        sharedTextValUnit.isMixed && setTextVal('')
                    }}
                    onBlur={(e) => {
                        let value = e.target.value
                        if (sharedTextValUnit.isMixed && value === '') {
                            value = sharedTextValUnit.val
                        }
                        setTextVal(value)
                        if (value !== sharedTextValUnit.val) {
                            command.invokeBridge(CommitType.CommitUndo, SetTextValueForSelectionCommand, { value })
                        }
                    }}
                    onInput={(e) => setTextVal(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault() // 阻止 textArea 回车换行的默认行为
                            const inputElement = e.target as HTMLTextAreaElement
                            inputElement.blur() // 触发失焦事件
                        }
                    }}
                    dataTestIds={{ textarea: 'text-prop-value-textarea' }}
                />
            </SingleGrid.Item>
            <SingleGrid.Item start={51} span={12} horizontalCenter>
                {showApplyIconForCreateTextProp && (
                    <Tooltip title={translation('CreateTextProp')}>
                        <WKIconButton
                            data-testid="text-prop-apply-icon-for-create"
                            icon={<MonoIconPanelApplyProperty16 />}
                            onClick={openTextAreaCreateTextPropPopup}
                        />
                    </Tooltip>
                )}
                {showApplyIconForApplyTextProp && (
                    <Tooltip title={translation('ApplyTextProp')} triggerRefKey="getTriggerElement">
                        <DropdownV2.IconSingleLevel
                            label={
                                <MonoIconPanelApplyProperty16
                                    className={dropdownVisible ? undefined : styles.grayIcon}
                                />
                            }
                            dataTestIds={{ triggerFocus: 'text-prop-apply-icon-for-apply' }}
                            onChange={onSelectChange}
                            onClick={() => setDropdownVisible(!dropdownVisible)}
                            maxWidth={228}
                            placement="bottom right"
                        >
                            {textPropList.map((textProp) => (
                                <DropdownV2.IconSingleLevel.Option key={textProp.id} value={textProp.id}>
                                    {textProp.name}
                                </DropdownV2.IconSingleLevel.Option>
                            ))}
                            <DropdownV2.IconSingleLevel.Option splitLineTop value={CreateTextPropBtnAlias}>
                                {translation('CreateProp')}
                            </DropdownV2.IconSingleLevel.Option>
                        </DropdownV2.IconSingleLevel>
                    </Tooltip>
                )}
            </SingleGrid.Item>
        </SingleGrid>
    )
}
