import { useCanvasRect } from '../hooks/use-canvas-rect'
import { HyperlinkModel } from './use-hyperlink-model'

const InputWidth = 240
const InputHeight = 32

export function usePopupPosition(modelState: HyperlinkModel['modelState']) {
    const canvasRect = useCanvasRect()

    if (!modelState?.show) {
        return {
            transformX: -10000000,
            transformY: -10000000,
        }
    }

    return {
        transformX: canvasRect.left + modelState.cameraX - InputWidth / 2,
        transformY: canvasRect.top + modelState.cameraY - InputHeight / 2,
    }
}
