import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { FC, useEffect, useRef } from 'react'
import { InputV2 } from '../../../../../../../ui-lib/src'
import { NormalInputRef } from '../../../../../../../ui-lib/src/components/inputs/normal-input/normal-input'
import { LayerPanelItemMode } from '../../../layer-panel/render-item-mode'
import styles from './index.module.less'

interface DoubleClickInputProps {
    isEditing: boolean
    value: string
    onChange: (value: string) => void
    handleKeyDown?: (event: KeyboardEvent, value: string) => void
    displayName?: string
    className?: string
    isPurple?: boolean
    visibleStatus?: Wukong.DocumentProto.VLayerPanelVisibleStatus
    layerUsingModes?: Wukong.DocumentProto.IVariableLayerModeStateVector
}

export const DoubleClickInput: FC<DoubleClickInputProps> = (props) => {
    const { isEditing, value, onChange, handleKeyDown, displayName, className } = props

    const InputV2Ref = useRef<NormalInputRef>(null)

    useEffect(() => {
        if (isEditing) {
            InputV2Ref.current?.getInputElement()?.setSelectionRange(0, -1)
            InputV2Ref.current?.getInputElement()?.focus({ preventScroll: true })
        }
    }, [isEditing])

    return (
        <div className={styles.doubleClickInputContainer}>
            {isEditing ? (
                <InputV2
                    ref={InputV2Ref}
                    size="small"
                    className={className}
                    value={value}
                    onBlur={(e) => onChange(e.target.value)}
                    onKeyDown={(e) => (
                        e.stopPropagation(), handleKeyDown?.(e.nativeEvent, (e.target as HTMLInputElement).value)
                    )}
                    onMouseDown={(e) => e.stopPropagation()}
                />
            ) : (
                <div data-testid="normal-value" className={classNames(styles.normalValueNew, className)}>
                    <span className={styles.normalValueText}>{displayName ?? value}</span>
                    {props.layerUsingModes?.states.length ? (
                        <LayerPanelItemMode
                            modes={props.layerUsingModes!}
                            isPurple={props.isPurple!}
                            visibleStatus={props.visibleStatus!}
                        />
                    ) : null}
                </div>
            )}
        </div>
    )
}
