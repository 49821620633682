import { translation } from './ai-recognize-modal.translation'
/* eslint-disable no-restricted-imports */
import classNames from 'classnames'
import { cloneDeep } from 'lodash-es'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useMount } from 'react-use'
import {
    Checkbox,
    MulticolorIconCommonFolder24,
    ScrollView,
    Scrollbar,
    Tooltip,
    WKButton,
    WKCollapse,
    WKCollapseItem,
    WKDialog,
    WKLogo,
    WKToast,
} from '../../../../../ui-lib/src'
import {
    RouteToken,
    RouteTokenValue,
    domLocation,
    generateRouterPath,
    isEnglishLanguage,
    timeDesc,
    useIsEllipsisActive,
} from '../../../../../util/src'
import { WkCLog } from '../../../kernel/clog/wukong/instance'
import { WKFrog } from '../../../kernel/frog/frog-service'
import { FolderWithAuthorityVO, TeamWithAuthorityVO, DocWithAuthorityVO } from '../../../kernel/interface/type'
import { OnboardingScene } from '../../../kernel/interface/onboarding'
import { QueryDocFromFolderRequest } from '../../../kernel/request/document'
import { openIndependentWindow } from '../../../kernel/util/open-window'
import { AIRecognizeStatus, ExtractionFailedStatus, createAIRecognizeTask } from '../../../main/ai-recognize/requests'
import { useAppContext } from '../../../main/app-context'
import { onboardingService } from '../../../main/service/onboarding-service'
import { useAiRecognizeModalState } from '../../../ui/component/component-style-library-v2/ai-library-maintain-modal/ai-recognize-modal'
import failedIcon from '../../assets/failed.png'
import succeedIcon from '../../assets/succeed.png'
import { DocListPageSortKey, FlatDoc, SortOrder } from '../../util/types'
import { DocThumbnail } from '../document/doc-thumbnail'
import { getSortedDocs } from '../document/sort-menu/menu-utils'
import { currDraftIdSelector } from '../zustand/organization-state'
import { useSpaceStore } from '../zustand/space-state'
import { teamFoldersSelector, teamId2FolderSelector } from '../zustand/teams-folders-state'
import type { OnboardingDoc } from './ai-recognize-entry'
import classes from './ai-recognize-modal.module.less'
import { WorkspaceWithTeamAndFolderVO } from 'packages/app/src/kernel/interface/workspace'

enum CurrentModalContent {
    ChooseFile,
    Recognizing,
    RecognizeSucceed,
    RecognizeFailed,
}

const FolderItem = ({
    selectedFolder,
    folder,
    selectedFolderDocsMap,
    itemIndent,
    onSelectedFolderChanged,
}: {
    selectedFolder: string | undefined
    folder: FolderWithAuthorityVO
    selectedFolderDocsMap: Map<string, Set<string>>
    onSelectedFolderChanged: (id: string) => void
    itemIndent: number
}) => {
    const countSize = selectedFolderDocsMap.get(folder.id)?.size ?? 0

    const headerRef = useRef<HTMLDivElement>(null)
    const isEllipsis = useIsEllipsisActive(headerRef.current)

    return (
        <Tooltip title={folder.name} inactivation={!isEllipsis}>
            <div
                className={classNames([
                    'flex items-center pl-3 h-8',
                    classes.FolderItem,
                    selectedFolder === folder.id ? classes.Selected : undefined,
                ])}
                key={folder.id}
                onClick={() => onSelectedFolderChanged(folder.id)}
            >
                <MulticolorIconCommonFolder24 style={{ marginLeft: itemIndent }} />
                <div className={classNames(['ml-1 truncate', classes.FolderName])} ref={headerRef}>
                    {folder.name}
                </div>
                {countSize > 0 ? (
                    <div className={classNames(['text-gray', classes.Count])}>{countSize > 99 ? '99+' : countSize}</div>
                ) : undefined}
            </div>
        </Tooltip>
    )
}

const DocItem = ({
    selectedAIDocs,
    doc,
    onHover,
    onLeave,
    onClick,
    hoverredDocId,
}: {
    selectedAIDocs: Set<string>
    doc: FlatDoc
    onHover: (id: string) => void
    onLeave: () => void
    onClick: (doc: FlatDoc) => void
    hoverredDocId: string | undefined
}) => {
    const headerRef = useRef<HTMLDivElement>(null)
    const isEllipsis = useIsEllipsisActive(headerRef.current)

    return (
        <div
            className={classNames([classes.Item, selectedAIDocs.has(doc.id) ? classes.Selected : undefined])}
            key={doc.id}
            id={`ai-item-${doc.id}`}
            onClick={() => onClick(doc)}
            onMouseOver={() => {
                onHover(doc.id)
            }}
            onMouseLeave={() => {
                onLeave()
            }}
        >
            <DocThumbnail
                url={doc.thumbnailUrl}
                thumbnailNodeId={doc.thumbnailNodeId}
                canvasBackgroundColor={doc.canvasBackgroundColor}
            ></DocThumbnail>
            <Tooltip title={doc.name} inactivation={!isEllipsis}>
                <div className={classNames(['wk-font-medium truncate mt-2', classes.Title])} ref={headerRef}>
                    {doc.name}
                </div>
            </Tooltip>
            <div className="flex items-center">
                <div className="text-gray">
                    {`${translation('EditedTo')} ${timeDesc(Math.max(doc.lastEditedTime, doc.createdTime))}`}
                </div>
                {hoverredDocId === doc.id || selectedAIDocs.has(doc.id) ? (
                    <Checkbox className="ml-auto" checked={selectedAIDocs.has(doc.id)} />
                ) : undefined}
            </div>
        </div>
    )
}

export const RecognizeSuccessContent = ({
    layerCount,
    onClickOK,
}: {
    layerCount: number
    folder: FolderWithAuthorityVO
    onClickOK: (e: React.MouseEvent<HTMLElement>) => void
}) => {
    return (
        <div className={classNames([classes.InRecognzingContainer, 'py-12'])}>
            <img className={classes.SucceedImg} src={succeedIcon}></img>
            <div className={classNames([classes.RecognizingText, 'text-black wk-font-medium wk-text-16'])}>
                AI {translation('RecognitionComplete')}
            </div>
            <div className={classNames([classes.LayerCountContainer, 'wk-font-regular', 'wk-text-12', 'mt-1'])}>
                <div>{translation('Detected')}&nbsp;</div>
                <div className={classNames(['wk-font-semibold', 'wk-text-12'])}>
                    {isEnglishLanguage() ? `${(layerCount! / 1000).toFixed(1)}k` : (layerCount! / 10000).toFixed(1)}
                </div>
                <div>&nbsp;{translation('10kLayers')}</div>
            </div>
            <div
                className={classNames([
                    isEnglishLanguage() ? classes.ResultFolderTextEn : classes.ResultFolderText,
                    'mt-1',
                ])}
            >
                {translation('Draft')}
            </div>
            <WKButton type={'primary'} className={classes.BottomButton} onClick={onClickOK}>
                {translation('View')}
            </WKButton>
        </div>
    )
}

interface ErrorDescription {
    title: string
    subTitle: string
}

export const RecognizeFailedContent = ({
    onClickOK,
    failedStatus,
}: {
    onClickOK: (e: React.MouseEvent<HTMLElement>) => void
    failedStatus: ExtractionFailedStatus | undefined
}) => {
    const errorDescription: ErrorDescription = {
        title: translation('RecognitionFailureDue'),
        subTitle: translation('PleaseTryAgain'),
    }
    if (failedStatus === ExtractionFailedStatus.DocTooLarge) {
        errorDescription.title = translation('TheFileIs')
        errorDescription.subTitle = `${translation('PleaseSplitIt')}`
    } else if (failedStatus === ExtractionFailedStatus.ServerBusy) {
        errorDescription.title = translation('CurrentServerBusy')
        errorDescription.subTitle = translation('PleaseTryAgain')
    } else if (failedStatus === ExtractionFailedStatus.TooManyDocsInRequest) {
        errorDescription.title = translation('ExcessiveNumberOf')
        errorDescription.subTitle = `${translation('PleaseReduceTo')} 6 ${translation('TryAgain')}`
    } else if (failedStatus === ExtractionFailedStatus.TooManyRunningQuestOneUser) {
        errorDescription.title = translation('YouHaveAn')
        errorDescription.subTitle = translation('PleaseTryAgain_synonyms1')
    } else if (failedStatus === ExtractionFailedStatus.HasNoScreen) {
        errorDescription.title = translation('NoMobileInterface')
        errorDescription.subTitle = translation('PleaseSelectThe')
    }
    return (
        <div className={classNames([classes.InRecognzingContainer, classes.FailedContainer])}>
            <img className={classes.FailedImg} src={failedIcon}></img>
            <div className={classNames([classes.FailedRecognizingText, 'text-black wk-font-medium wk-text-16'])}>
                <div>{errorDescription.title}</div>
                <div>{errorDescription.subTitle}</div>
            </div>
            <WKButton type={'primary'} className={classes.BottomButton} onClick={onClickOK}>
                {translation('GotIt')}
            </WKButton>
        </div>
    )
}

export const RecognizingContent = ({
    onClickOK,
}: {
    onClickOK: (e: React.MouseEvent<HTMLElement>) => void
    status: AIRecognizeStatus
}) => {
    return (
        <div className={classNames([classes.InRecognzingContainer, classes.LoadingContainer])}>
            <WKLogo.WithText height={24} type={'normal'} />
            <div className={classes.PrograssBar}>
                <div className={classes.Inside}>
                    <div className={classes.Flight}></div>
                </div>
            </div>
            <div className={classNames([classes.RecognizingText, 'text-black wk-font-medium wk-text-16'])}>
                AI {translation('Recognizing')}...
            </div>
            <WKButton type={'secondary'} className={classes.BottomButton} onClick={onClickOK}>
                {translation('Cancel')}
            </WKButton>
        </div>
    )
}

export function AiRecognizeModalDataImpl(props: {
    onCancel: () => void
    onClickCancelRecognize: () => void
    visible: boolean
    isEnterprise: boolean
    onboardingDocs?: OnboardingDoc[]
    closeOnboarding?: (isFinish?: boolean) => void
    onScrollList?: () => void
    teamOrderedList: TeamWithAuthorityVO[]
    teamId2FavoritesFolderList: Record<string, FolderWithAuthorityVO[]>
    docList: ReadonlyArray<DocWithAuthorityVO> | undefined
    currDraftId: string
    isInDraft: boolean | undefined
    currentFolder: FolderWithAuthorityVO | undefined
    routeToken: RouteTokenValue | undefined
    decreaseAiDesignSystemCredits: () => void
    workspaces: readonly WorkspaceWithTeamAndFolderVO[]
}) {
    const {
        onboardingDocs,
        closeOnboarding,
        onScrollList,
        teamOrderedList,
        teamId2FavoritesFolderList,
        docList,
        currDraftId,
        currentFolder,
        routeToken,
        isEnterprise,
        workspaces,
        isInDraft,
    } = props
    // 控制当前显示的内容
    const [currentModalContent, setCurrentModalContent] = useState(CurrentModalContent.ChooseFile)

    const [currentDocList, setCurrentDocList] = useState<readonly DocWithAuthorityVO[]>()
    const folder = currentFolder
    const currentFolderId = folder?.id ?? currDraftId
    const [selectedFolder, setSelectedFolder] = useState<string>()
    const [selectedAIDocs, setSelectedAIDocs] = useState<Set<string>>(new Set<string>())
    const [recognizeResultDoc, setRecognizeResultDoc] = useState<string>()
    const [selectedFolderDocsMap, setSelectedFolderDocsMap] = useState<Map<string, Set<string>>>(
        new Map<string, Set<string>>()
    )
    const [hoverredDocId, setHoverredDocId] = useState<string>()
    const [layerCount, setLayoutCount] = useState<number>()
    const [aiRecognizeStatus, setAiRecognizeStatus] = useState<AIRecognizeStatus>()
    const [startTimestamp, setStartTimestamp] = useState<number>(Date.now())
    const [failedStatus, setFailedStatus] = useState<ExtractionFailedStatus>()
    const isInEditor = routeToken === undefined

    useMount(() => {
        WKFrog.addFrogRecord({
            url: '/expose/aiDesignSystem/SelelFilesPopup',
            eventId: 27707,
            currentPage: 'AIDesigSystem',
            customExtend: {
                ai_creator_location: isInEditor ? 'editor' : 'space',
            },
            eventAction: 'expose',
            eventName: 'SelelFilesPopup',
        })
    })

    useEffect(() => {
        if (currentFolderId) {
            setSelectedFolder(currentFolderId)
        } else if (isInDraft && currDraftId) {
            setSelectedFolder(currDraftId)
        }
    }, [currDraftId, currentFolderId, isInDraft])

    const folderIdToSaveResult = currDraftId

    const titleText = useMemo(() => {
        if (currentModalContent === CurrentModalContent.ChooseFile) {
            return translation('Title')
        }
        return ''
    }, [currentModalContent])

    const modalWidth = useMemo(() => {
        if (currentModalContent === CurrentModalContent.ChooseFile) {
            return 1080
        } else {
            return 400
        }
    }, [currentModalContent])

    const isSelectAll = useMemo(() => {
        let ret = true
        if (currentDocList) {
            for (const doc of currentDocList) {
                if (!selectedAIDocs.has(doc.id)) {
                    ret = false
                    break
                }
            }
        } else {
            return false
        }

        return ret
    }, [currentDocList, selectedAIDocs])

    const isIndeterminate = useMemo(() => {
        if (isSelectAll) {
            return false
        }
        let ret = false
        if (currentDocList) {
            for (const doc of currentDocList) {
                if (selectedAIDocs.has(doc.id)) {
                    ret = true
                    break
                }
            }
        }

        return ret
    }, [currentDocList, isSelectAll, selectedAIDocs])

    const sortedDocList = useMemo((): readonly FlatDoc[] => {
        if (currentDocList) {
            return getSortedDocs(currentDocList, {
                sortKey: DocListPageSortKey.Edited,
                sortOrder: SortOrder.Ascending,
            })
        } else {
            return []
        }
    }, [currentDocList])

    const maskClosable = useMemo(() => {
        if (
            currentModalContent === CurrentModalContent.Recognizing ||
            currentModalContent === CurrentModalContent.ChooseFile
        ) {
            return false
        }
        return true
    }, [currentModalContent])

    const hideFooterLine = useMemo(() => {
        if (currentModalContent === CurrentModalContent.ChooseFile) {
            return false
        }
        return true
    }, [currentModalContent])

    const showCloseIcon = useMemo(() => {
        return currentModalContent !== CurrentModalContent.Recognizing
    }, [currentModalContent])

    const draftSelectedFilesCount = useMemo(() => {
        if (!currDraftId) {
            return 0
        }
        return selectedFolderDocsMap.get(currDraftId)?.size ?? 0
    }, [currDraftId, selectedFolderDocsMap])

    const cancelRecognizeTask = useRef<() => void>()

    useEffect(() => {
        return () => {
            cancelRecognizeTask.current?.()
            if (cancelRecognizeTask.current) {
                WKFrog.addFrogRecord({
                    url: '/click/aiDesigSystem/clickCancelRecognizeButton',
                    eventId: 20803,
                    currentPage: 'AIDesigSystem',
                    customExtend: {
                        ai_creator_location: isInEditor ? 'editor' : 'space',
                    },
                    eventAction: 'click',
                    eventName: 'clickCancelRecognizeButton',
                })
            }
        }
    }, [isInEditor])

    const onboarding = onboardingService
    const [onboardingScrolled, setOnboardingScrolled] = useState(false)

    const docListCallback = useCallback(
        (node: HTMLDivElement | null) => {
            if (node && onboardingDocs) {
                onboarding.startFlow(OnboardingScene.Ai)
            }
            if (node && onboardingDocs?.length && !onboardingScrolled) {
                const target = document.getElementById(`ai-item-${onboardingDocs[0].docId}`)
                if (target) {
                    setOnboardingScrolled(true)
                }
            }
        },
        [onboardingDocs, onboardingScrolled, onboarding]
    )

    const onClickOk = useCallback(async () => {
        if (currentModalContent === CurrentModalContent.ChooseFile && currentFolderId) {
            setStartTimestamp(Date.now())
            WKFrog.addFrogRecord({
                url: '/click/Files/aiDesignSystemStart',
                eventId: 20726,
                customExtend: {
                    fileID: JSON.stringify(Array.from(selectedAIDocs)),
                },
                currentPage: 'Files',
                eventAction: 'click',
                eventName: 'aiDesignSystemStart',
            })

            WKFrog.addFrogRecord({
                url: '/click/aiDesignSystem/clickBeginRecognizeButton',
                eventId: 20801,
                customExtend: {
                    fileID: JSON.stringify(Array.from(selectedAIDocs)),
                    ai_creator_location: isInEditor ? 'editor' : 'space',
                },
                currentPage: 'aiDesignSystem',
                eventAction: 'click',
                eventName: 'clickBeginRecognizeButton',
            })
            closeOnboarding?.()
            setCurrentModalContent(CurrentModalContent.Recognizing)
            try {
                const task = await createAIRecognizeTask(Array.from(selectedAIDocs), folderIdToSaveResult!)
                if (task.error !== undefined) {
                    if (task.error.status === ExtractionFailedStatus.DocSchemaVersionNotSame) {
                        WKToast.error(translation('DocSchemaVersionNotSame'))
                        WkCLog.log('AiRecognize', {
                            traceEventName: 'INFO_SCHEMA_VERSION_NOT_MATCH',
                            traceEventKey: 1386768,
                            scenario: 'AiRecognize',
                            docIds: task.error.docIds.join(','),
                            folderId: task.error.folderId,
                        })
                    }
                    setFailedStatus(task.error.status)
                    setCurrentModalContent(CurrentModalContent.RecognizeFailed)
                    return
                }
                cancelRecognizeTask.current = task.cancel
                task.getTaskStatus((status) => {
                    if (status.status === 'ALL_FINISHED') {
                        cancelRecognizeTask.current = undefined
                        const duration = Date.now() - startTimestamp
                        WKFrog.addFrogRecord({
                            url: '/event/Files/aiDesignSystemGenerate',
                            eventId: 20727,
                            customExtend: {
                                fileID: JSON.stringify(Array.from(selectedAIDocs)),
                                ai_creator_location: isInEditor ? 'editor' : 'space',
                            },
                            currentPage: 'Files',
                            eventAction: 'event',
                            eventName: 'aiDesignSystemGenerate',
                        })
                        WKFrog.addFrogRecord({
                            url: '/time/Files/aiDesignSystemGenerateDuration',
                            eventId: 20728,
                            customExtend: {
                                duration,
                                fileID: JSON.stringify(Array.from(selectedAIDocs)),
                            },
                            duration,
                            currentPage: 'Files',
                            eventAction: 'time',
                            eventName: 'aiDesignSystemGenerateDuration',
                        })
                        WKFrog.addFrogRecord({
                            url: '/time/aiDesignSystem/GenerateDuration',
                            eventId: 21202,
                            customExtend: {
                                duration,
                                fileID: JSON.stringify(Array.from(selectedAIDocs)),
                            },
                            duration,
                            currentPage: 'aiDesignSystem',
                            eventAction: 'time',
                            eventName: 'GenerateDuration',
                        })
                        setCurrentModalContent(CurrentModalContent.RecognizeSucceed)
                        setRecognizeResultDoc(status.docId)
                        setLayoutCount(status.layerCount)
                        props.decreaseAiDesignSystemCredits()
                    } else if (status.status === 'TERMINATE') {
                        cancelRecognizeTask.current = undefined
                        setCurrentModalContent(CurrentModalContent.RecognizeFailed)
                    } else if (status.status === 'AI_NOT_FOUND_SCREEN') {
                        cancelRecognizeTask.current = undefined
                        setFailedStatus(ExtractionFailedStatus.HasNoScreen)
                        setCurrentModalContent(CurrentModalContent.RecognizeFailed)
                    } else {
                        setAiRecognizeStatus(status.status)
                    }
                })
            } catch (err) {
                cancelRecognizeTask.current = undefined
                setCurrentModalContent(CurrentModalContent.RecognizeFailed)
            }
        } else if (currentModalContent === CurrentModalContent.RecognizeSucceed) {
            openIndependentWindow(
                `${domLocation().origin}/${generateRouterPath(RouteToken.File)}/${recognizeResultDoc}?ai-recognize=1`
            )
            props.onCancel()
        } else if (currentModalContent === CurrentModalContent.Recognizing) {
            props.onClickCancelRecognize()
        } else {
            props.onCancel()
        }
    }, [
        currentModalContent,
        currentFolderId,
        selectedAIDocs,
        closeOnboarding,
        folderIdToSaveResult,
        startTimestamp,
        isInEditor,
        props,
        recognizeResultDoc,
    ])

    const onClickCancel = useCallback(() => {
        props.onCancel()
    }, [props])

    const deleteDocInFolderDocsMap = (doc: FlatDoc) => {
        selectedFolderDocsMap.get(doc.folderId!)?.delete(doc.id)
    }

    const addDocInFolderDocsMap = (doc: FlatDoc) => {
        if (!selectedFolderDocsMap.get(doc.folderId)) {
            selectedFolderDocsMap.set(doc.folderId, new Set<string>())
        }
        selectedFolderDocsMap.get(doc.folderId)!.add(doc.id)
    }

    const toggleSelectAll = () => {
        if (!isSelectAll) {
            currentDocList?.forEach((doc) => {
                selectedAIDocs.add(doc.id)
                addDocInFolderDocsMap(doc)
            })
        } else {
            currentDocList?.forEach((doc) => {
                selectedAIDocs.delete(doc.id)
                deleteDocInFolderDocsMap(doc)
            })
        }

        setSelectedFolderDocsMap(cloneDeep(selectedFolderDocsMap))
        setSelectedAIDocs(cloneDeep(selectedAIDocs))
    }

    const toggleSelectDoc = (doc: FlatDoc) => {
        if (selectedAIDocs.has(doc.id)) {
            selectedAIDocs.delete(doc.id)
            setSelectedAIDocs(cloneDeep(selectedAIDocs))

            deleteDocInFolderDocsMap(doc)
            setSelectedFolderDocsMap(cloneDeep(selectedFolderDocsMap))
        } else {
            selectedAIDocs.add(doc.id)
            setSelectedAIDocs(cloneDeep(selectedAIDocs))

            addDocInFolderDocsMap(doc)
            setSelectedFolderDocsMap(cloneDeep(selectedFolderDocsMap))
        }
    }

    const footer = useMemo(() => {
        if (currentModalContent === CurrentModalContent.ChooseFile) {
            return (
                <div className={classes.AiChooseDocModalFooter}>
                    <div className="flex items-center">
                        <div className="text-black">{translation('ZjTSOj')}&nbsp;</div>
                        <div className="wk-font-medium text-black">{selectedAIDocs.size}</div>
                        <div className="text-black">
                            &nbsp;{translation('FilesSelected', { file: selectedAIDocs.size !== 1 ? 'files' : 'file' })}
                        </div>
                    </div>
                    <div className={classes.ButtonArea}>
                        <WKButton type="secondary" onClick={onClickCancel}>
                            {translation('Cancel_synonyms1')}
                        </WKButton>
                        <WKButton
                            type="primary"
                            disabled={selectedAIDocs.size === 0}
                            onClick={onClickOk}
                            dataTestId="start-ai-recognize"
                        >
                            {translation('Start')}
                        </WKButton>
                    </div>
                </div>
            )
        }

        return null
    }, [currentModalContent, onClickCancel, onClickOk, selectedAIDocs.size])

    const title = useMemo(() => {
        if (currentModalContent === CurrentModalContent.ChooseFile) {
            return (
                <div className={classes.TitleContainer}>
                    <div>{titleText}</div>
                </div>
            )
        } else {
            return undefined
        }
    }, [currentModalContent, titleText])

    useEffect(() => {
        if (onboardingDocs?.length) {
            onboardingDocs.forEach((doc) => {
                selectedAIDocs.add(doc.docId)
                addDocInFolderDocsMap({ id: doc.docId, folderId: doc.folderId } as unknown as FlatDoc)
            })
            setSelectedFolderDocsMap(cloneDeep(selectedFolderDocsMap))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onboardingDocs])

    useEffect(() => {
        if (docList && selectedFolder === currentFolderId && routeToken !== RouteToken.Recent && !onboardingDocs) {
            setCurrentDocList(docList)
        }
    }, [selectedFolder, currentFolderId, routeToken, docList, onboardingDocs])

    useEffect(() => {
        if (
            selectedFolder &&
            (!(selectedFolder === currentFolderId && routeToken !== RouteToken.Recent && !onboardingDocs) || !docList)
        ) {
            setCurrentDocList(undefined)
            new QueryDocFromFolderRequest(selectedFolder).start().then((docs) => {
                setCurrentDocList(docs.filter((d) => d.copyable))
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFolder])

    return (
        <WKDialog
            visible={props.visible}
            closable={showCloseIcon}
            bodyStyle={{
                padding: 0,
            }}
            title={title}
            hideFooterLine={hideFooterLine}
            okText={translation('SelectFiles')}
            onOk={onClickOk}
            onCancel={onClickCancel}
            width={modalWidth}
            showTitle={!!title}
            maskClosable={maskClosable}
            footer={footer}
            testId="ai-recognize-modal"
            centered={true}
        >
            {currentModalContent === CurrentModalContent.RecognizeSucceed && (
                <RecognizeSuccessContent
                    folder={folder!}
                    layerCount={layerCount!}
                    onClickOK={onClickOk}
                ></RecognizeSuccessContent>
            )}
            {currentModalContent === CurrentModalContent.RecognizeFailed && (
                <RecognizeFailedContent onClickOK={onClickOk} failedStatus={failedStatus}></RecognizeFailedContent>
            )}
            {currentModalContent === CurrentModalContent.Recognizing && (
                <RecognizingContent onClickOK={onClickOk} status={aiRecognizeStatus ?? 'INIT'}></RecognizingContent>
            )}
            {currentModalContent === CurrentModalContent.ChooseFile ? (
                <div className={classes.AiChooseDocContainer}>
                    <div className={classes.FolderListContainer}>
                        {selectedFolder && (
                            <Scrollbar>
                                <div id={classes.FolderList}>
                                    <div
                                        className={classNames([
                                            classes.DraftContainer,
                                            selectedFolder === currDraftId ? classes.Selected : undefined,
                                            classes.FolderItem,
                                        ])}
                                        onClick={() => setSelectedFolder(currDraftId)}
                                    >
                                        <div className={classNames(['wk-font-semibold text-black'])}>
                                            {translation('Draft')}
                                        </div>
                                        {draftSelectedFilesCount > 0 ? (
                                            <div className={classNames(['text-gray', classes.Count])}>
                                                {draftSelectedFilesCount > 99 ? '99+' : draftSelectedFilesCount}
                                            </div>
                                        ) : undefined}
                                    </div>
                                    {isEnterprise ? (
                                        <WKCollapse
                                            key={folder?.id} //这里加 key 的原因是，在编辑器内，folder 是异步传过来的，如果不触发列表 render，那么这个折叠面板不会展开
                                            style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
                                            selectionMode="multiple"
                                            defaultSelection={folder?.workspaceId ? [folder?.workspaceId] : []}
                                        >
                                            {workspaces.map(
                                                ({ workspaceWithAuthorityVO, teamsWithFolderAndAuthorityVO }) => {
                                                    return (
                                                        <WKCollapseItem
                                                            key={workspaceWithAuthorityVO.workspaceId}
                                                            value={workspaceWithAuthorityVO.workspaceId}
                                                            itemRadius="default"
                                                            title={
                                                                workspaceWithAuthorityVO.name || translation('Others')
                                                            }
                                                        >
                                                            <WKCollapse
                                                                selectionMode="multiple"
                                                                defaultSelection={
                                                                    folder?.teamId ? [folder?.teamId] : []
                                                                }
                                                            >
                                                                {teamsWithFolderAndAuthorityVO.map((item) => {
                                                                    const team = item.teamWithAuthorityVO
                                                                    const folderList = item.folderWithAuthorityVOList
                                                                    return (
                                                                        <WKCollapseItem
                                                                            key={team.id}
                                                                            itemIndent={12}
                                                                            itemRadius="default"
                                                                            className="pt-1"
                                                                            value={team.id}
                                                                            title={team.name}
                                                                        >
                                                                            {folderList.length > 0 ? (
                                                                                folderList.map((v) => {
                                                                                    return (
                                                                                        <FolderItem
                                                                                            key={v.id}
                                                                                            itemIndent={12}
                                                                                            folder={v}
                                                                                            onSelectedFolderChanged={
                                                                                                setSelectedFolder
                                                                                            }
                                                                                            selectedFolder={
                                                                                                selectedFolder
                                                                                            }
                                                                                            selectedFolderDocsMap={
                                                                                                selectedFolderDocsMap
                                                                                            }
                                                                                        />
                                                                                    )
                                                                                })
                                                                            ) : (
                                                                                <div
                                                                                    className={classNames([
                                                                                        'h-7 pl-10 pt-1 text-gray-light',
                                                                                    ])}
                                                                                >
                                                                                    {translation('NoProjects')}
                                                                                </div>
                                                                            )}
                                                                        </WKCollapseItem>
                                                                    )
                                                                })}
                                                            </WKCollapse>
                                                        </WKCollapseItem>
                                                    )
                                                }
                                            )}
                                        </WKCollapse>
                                    ) : (
                                        <WKCollapse
                                            style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
                                            selectionMode="multiple"
                                            defaultSelection={folder?.teamId ? [folder?.teamId] : []}
                                        >
                                            {teamOrderedList?.map((team) => {
                                                const folderList = teamId2FavoritesFolderList[team.id]
                                                return (
                                                    <WKCollapseItem
                                                        key={team.id}
                                                        value={team.id}
                                                        title={team.name}
                                                        itemRadius="default"
                                                        tooltipProps={{
                                                            title: team.name,
                                                        }}
                                                    >
                                                        {folderList.length > 0 ? (
                                                            folderList.map((v) => {
                                                                return (
                                                                    <FolderItem
                                                                        itemIndent={0}
                                                                        key={v.id}
                                                                        folder={v}
                                                                        onSelectedFolderChanged={setSelectedFolder}
                                                                        selectedFolder={selectedFolder}
                                                                        selectedFolderDocsMap={selectedFolderDocsMap}
                                                                    />
                                                                )
                                                            })
                                                        ) : (
                                                            <div
                                                                className={classNames([
                                                                    'h-7 pl-10 pt-1 text-gray-light',
                                                                ])}
                                                            >
                                                                {translation('NoProjects')}
                                                            </div>
                                                        )}
                                                    </WKCollapseItem>
                                                )
                                            })}
                                        </WKCollapse>
                                    )}
                                </div>
                            </Scrollbar>
                        )}
                    </div>
                    {currentDocList && currentDocList.length > 0 ? (
                        <div className={classes.DocListContainer}>
                            <Checkbox
                                className="mt-3 mb-1 ml-5 w-fit"
                                checked={isSelectAll}
                                onChange={toggleSelectAll}
                                indeterminate={isIndeterminate}
                                label={translation('CheckAll')}
                                size="medium"
                            />

                            <div onClick={toggleSelectAll}></div>
                            <ScrollView
                                selectKey={!onboardingScrolled && onboardingDocs?.[0]?.docId}
                                block="start"
                                scrollbar={{ onScroll: onScrollList }}
                            >
                                <div className={classNames([classes.ListContainer])}>
                                    <div className={classNames([classes.List])} ref={docListCallback}>
                                        {sortedDocList.map((doc) => {
                                            return (
                                                <ScrollView.Item uniqueKey={doc.id} key={doc.id}>
                                                    <DocItem
                                                        doc={doc}
                                                        hoverredDocId={hoverredDocId}
                                                        onClick={(d) => toggleSelectDoc(d)}
                                                        onHover={(id) => setHoverredDocId(id)}
                                                        onLeave={() => setHoverredDocId(undefined)}
                                                        selectedAIDocs={selectedAIDocs}
                                                    />
                                                </ScrollView.Item>
                                            )
                                        })}
                                    </div>
                                    <div className={classes.Spacer}></div>
                                </div>
                            </ScrollView>
                        </div>
                    ) : currentDocList && currentDocList.length === 0 ? (
                        <div className={classNames(['wk-text-16 wk-font-semibold', classes.EmptyText])}>
                            {translation('NoFiles')}
                        </div>
                    ) : undefined}
                </div>
            ) : undefined}
        </WKDialog>
    )
}

export const AiRecognizeModal = (props: {
    onCancel: () => void
    onClickCancelRecognize: () => void
    visible: boolean
    isEnterprise: boolean
    onboardingDocs?: OnboardingDoc[]
    closeOnboarding?: (isFinish?: boolean) => void
    onScrollList?: () => void
}) => {
    const teamOrderedList = useSpaceStore.use.teamsFoldersStore().teamsFolders.map((o) => o.teamWithAuthorityVO)
    const workspaces = useSpaceStore.use.teamsFoldersStore().workspaces
    const teamId2FavoritesFolderList = useSpaceStore(teamId2FolderSelector)
    const docList = useSpaceStore.use.docListPageStore().docList
    const currDraftId = useSpaceStore(currDraftIdSelector)
    const paramsFolderId = useParams().folderId
    const folders = useSpaceStore(teamFoldersSelector)
    const routeToken = useSpaceStore.use.docListPageStore().routeInfo?.routeToken
    const folderId = props.onboardingDocs?.length ? props.onboardingDocs[0].folderId : paramsFolderId
    const currentFolder = folders.find((o) => o.id == folderId)
    return AiRecognizeModalDataImpl({
        ...props,
        workspaces,
        teamOrderedList,
        teamId2FavoritesFolderList,
        docList,
        currDraftId,
        currentFolder,
        isInDraft: undefined,
        routeToken,
        decreaseAiDesignSystemCredits: () => {},
    })
}

export const AiRecognizeModalForInEditor = (props: {
    onCancel: () => void
    onClickCancelRecognize: () => void
    visible: boolean
    isEnterprise: boolean
}) => {
    const state = useAiRecognizeModalState(props.isEnterprise)
    const aiService = useAppContext().aiService

    return AiRecognizeModalDataImpl({
        ...props,
        workspaces: state.teamFolderInfo.value?.workspaces ?? [],
        teamOrderedList: state.teamFolderInfo.value?.teamOrderedList ?? [],
        teamId2FavoritesFolderList: state.teamFolderInfo.value?.teamId2FavoritesFolderList ?? {},
        docList: undefined,
        currentFolder: state.teamFolderInfo.value?.currentFolder,
        currDraftId: state.currDraftId,
        routeToken: undefined,
        isInDraft: state.isInDraft,
        decreaseAiDesignSystemCredits: () => {
            aiService.decreaseAiDesignSystemCredits()
        },
    })
}
