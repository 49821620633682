export const MagicIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.1575" height="16" viewBox="0 0 16.1575 16">
        <path
            transform="matrix(1 0 0 1 0.055603 0.356445)"
            d="M2.52477 1.43033C2.57302 1.41562 2.61078 1.37773 2.62556 1.32966L2.99964 0.107458C3.04349 -0.0356309 3.24628 -0.0356309 3.29014 0.107458L3.66424 1.32966C3.67901 1.37773 3.71677 1.41562 3.76502 1.43033L4.98698 1.80417C5.13023 1.84828 5.13022 2.05076 4.98698 2.09487L3.76502 2.46871C3.71677 2.48342 3.67901 2.52131 3.66424 2.56995L3.29014 3.79158C3.24628 3.93467 3.04349 3.93467 2.99964 3.79158L2.62556 2.56995C2.61078 2.52131 2.57302 2.48342 2.52477 2.46871L1.30286 2.09487C1.1596 2.05076 1.1596 1.84828 1.30286 1.80417L2.52477 1.43033ZM8.97651 5.10258C8.9345 5.24001 8.82706 5.34747 8.68979 5.38932L5.21364 6.45372C4.80611 6.57871 4.80611 7.1556 5.21363 7.28002L8.68979 8.34442C8.82706 8.38628 8.9345 8.49373 8.97651 8.63117L10.0407 12.1072C10.1655 12.5149 10.7424 12.5149 10.8671 12.1072L11.9314 8.63117C11.9734 8.49373 12.0808 8.38628 12.2181 8.34442L15.6944 7.28002C16.1019 7.1556 16.1019 6.57871 15.6944 6.45372L12.2181 5.38932C12.0808 5.34747 11.9734 5.24001 11.9314 5.10258L10.8671 1.62658C10.7424 1.2188 10.1655 1.2188 10.0407 1.62658L8.97651 5.10258ZM2.2192 11.3001C2.19627 11.3748 2.13767 11.4336 2.0628 11.4562L0.166716 12.0365C-0.0555663 12.1049 -0.0555751 12.4194 0.166716 12.4872L2.0628 13.0681C2.13768 13.0907 2.19627 13.1495 2.2192 13.2242L2.79966 15.1205C2.86772 15.3428 3.18239 15.3428 3.25044 15.1205L3.83094 13.2242C3.85387 13.1495 3.91246 13.0907 3.98734 13.0681L5.88348 12.4872C6.10576 12.4194 6.10576 12.1049 5.88348 12.0365L3.98733 11.4562C3.91246 11.433 3.85387 11.3748 3.83094 11.2995L3.25044 9.40374C3.18239 9.18147 2.86772 9.18147 2.79967 9.40374L2.2192 11.3001Z"
            fillRule="evenodd"
            fill="rgb(69, 73, 89)"
        />
    </svg>
)

export const TextIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            transform="matrix(1 0 0 1 2 2.40002)"
            d="M0.15 0C0.0671573 0 0 0.0671575 0 0.15L0 1.725C0 1.80784 0.0671571 1.875 0.15 1.875L4.875 1.875L4.875 11.625C4.875 11.8321 5.04289 12 5.25 12L6.75 12C6.95711 12 7.125 11.8321 7.125 11.625L7.125 1.875L11.85 1.875C11.9328 1.875 12 1.80784 12 1.725L12 0.15C12 0.0671575 11.9328 0 11.85 0L7.125 0L4.875 0L0.15 0Z"
            fillRule="evenodd"
            fill="rgb(69, 73, 89)"
        />
    </svg>
)

export const LoadingIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
            transform="matrix(1 0 0 1 4 4)"
            d="M8 0L8 1Q6.54206 1 5.31162 1.50967Q4.08117 2.01933 3.05025 3.05025Q2.01933 4.08117 1.50967 5.31162Q1 6.54206 1 8Q1 9.45794 1.50967 10.6884Q2.01933 11.9188 3.05025 12.9497Q4.08117 13.9807 5.31162 14.4903Q6.54206 15 8 15Q9.45794 15 10.6884 14.4903Q11.9188 13.9807 12.9497 12.9497Q13.9807 11.9188 14.4903 10.6884Q15 9.45794 15 8L16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0Z"
            fillRule="evenodd"
            fill="rgb(130, 138, 153)"
        >
            <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 8 8"
                to="360 8 8"
                repeatCount="indefinite"
            />
        </path>
    </svg>
)
