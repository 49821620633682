import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    nmf: '需要更多文件？',
    wm: '升级至专业版以移动此项目',
    so: '基础版的团队下仅能创建 1 个项目，升级专业版后可以无限创建项目。',
    mtf: '当前项目中「文件」数量大于 3，不能将其移动至基础版团队中。',
    mtp: '当前项目中文件的「页面」数量大于 3，不能将其移动至基础版团队中。',

    p: '1 个项目',
    f: '3 个文件',
    pef: '每个文件 3 个页面',

    up: '无限项目',
    uf: '无限文件',
    upd: '无限页面',
} as const

export const enTranslation = {
    nmf: 'Need more files?',
    wm: 'Want to move this project?',
    so: 'The Starter plan only comes with 1 project. Upgrade to Professional plan to create unlimited number of projects. ',
    mtf: 'The number of files in this project is more than 3. You cannot move it to teams on the Starter plan. ',
    mtp: 'The number of file pages is more than 3 in the current project. You cannot move it to teams on the Starter plan.',

    p: '1 project',
    f: '3 design files',
    pef: '3 pages per design file',

    up: 'Unlimited projects',
    uf: 'Unlimited files',
    upd: 'Unlimited pages in design files',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
