/* eslint-disable no-restricted-imports */
import { FolderWithDocAndAuthority, PublicStatus } from '../../../kernel/interface/type'
import * as TeamRequest from '../../../kernel/request/team'
import { SpaceNotifyFlow } from './notify-flow'
import { ReadOnlyTeamStore, SpaceStoreType, getSpaceStore, setSpaceStore } from './types'

export const generateReadOnlyTeamState = (set: setSpaceStore, get: getSpaceStore): ReadOnlyTeamStore => {
    return {
        team: undefined,
        folderDocs: undefined,
        setReadonlyTeam: async (team) => {
            let folderDocs: FolderWithDocAndAuthority[] | undefined = undefined
            if (!get().organizationStore.organization.guest && team.publicStatus == PublicStatus.OPEN) {
                folderDocs = await new TeamRequest.GetTeamFoldersAndDocsNotMember(team.id).start()
            }
            set((state) => {
                state.readOnlyTeamStore.team = { ...team, readonly: true }
                state.readOnlyTeamStore.folderDocs = folderDocs
            })
        },
        clear() {
            set((state) => {
                state.readOnlyTeamStore.team = undefined
                state.readOnlyTeamStore.folderDocs = undefined
            })
        },
        _refresh: () => {
            const teamId = get().readOnlyTeamStore.team?.id
            if (teamId) {
                new TeamRequest.GetTeamRequestV2(teamId).start().then((team) => {
                    get().readOnlyTeamStore.setReadonlyTeam(team)
                })
            }
        },
    }
}

export const syncReadOnlyTeamState = (store: SpaceStoreType, notifyFlow: SpaceNotifyFlow) => {
    notifyFlow.addRelationChangeCallbackWithoutAccess(() => {
        if (store.getState().readOnlyTeamStore.team != undefined) {
            store.getState().readOnlyTeamStore._refresh()
        }
    })
}
