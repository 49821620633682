import { translation } from './sort.translation'
/* eslint-disable no-restricted-imports */
import {
    Wukong,
    ArrangeAlignCommand,
    ArrangeDistributeCommand,
    ArrangePackCommand,
    RoundToPixelCommand,
} from '@wukong/bridge-proto'
import { WKItemType, WKMenuConfig } from '../../../../../../../ui-lib/src'
import { CommandInvoker } from '../../../../../document/command/command-invoker'
import { useViewState } from '../../../../../view-state-bridge'
import { getAlignModeName, getAlignModeShortCut } from '../../utils'
import { generateSubMenuConfigUnderSelectionSort } from './util'
import { ShortcutKey, shortcutLabelMap } from '../../../../../kernel/interface/shortcut-key'

export const useMenuSort = (command: CommandInvoker): WKMenuConfig => {
    const isPixelAlignEnabled = useViewState('actionEnabledCanUsePixelAlignStatus')
    return [
        {
            name: translation('Arrange'),
            position: 'right top',
            testId: 'main-menu-sort',
            children: [
                generateSubMenuConfigUnderSelectionSort(
                    !isPixelAlignEnabled,
                    command,
                    translation('RoundToPixel'),
                    '',
                    true,
                    RoundToPixelCommand,
                    true
                ),
                { type: WKItemType.Divider },
                generateSubMenuConfigUnderSelectionSort(
                    !useViewState('actionEnabledCanUseAlignStatus'),
                    command,
                    getAlignModeName(Wukong.DocumentProto.AlignType.ALIGN_TYPE_LEFT),
                    getAlignModeShortCut(Wukong.DocumentProto.AlignType.ALIGN_TYPE_LEFT),
                    true,
                    ArrangeAlignCommand,
                    true,
                    {
                        type: Wukong.DocumentProto.AlignType.ALIGN_TYPE_LEFT,
                    }
                ),
                generateSubMenuConfigUnderSelectionSort(
                    !useViewState('actionEnabledCanUseAlignStatus'),
                    command,
                    getAlignModeName(Wukong.DocumentProto.AlignType.ALIGN_TYPE_HORIZONTAL_CENTERS),
                    getAlignModeShortCut(Wukong.DocumentProto.AlignType.ALIGN_TYPE_HORIZONTAL_CENTERS),
                    true,
                    ArrangeAlignCommand,
                    true,
                    {
                        type: Wukong.DocumentProto.AlignType.ALIGN_TYPE_HORIZONTAL_CENTERS,
                    }
                ),
                generateSubMenuConfigUnderSelectionSort(
                    !useViewState('actionEnabledCanUseAlignStatus'),
                    command,
                    getAlignModeName(Wukong.DocumentProto.AlignType.ALIGN_TYPE_RIGHT),
                    getAlignModeShortCut(Wukong.DocumentProto.AlignType.ALIGN_TYPE_RIGHT),
                    true,
                    ArrangeAlignCommand,
                    true,
                    {
                        type: Wukong.DocumentProto.AlignType.ALIGN_TYPE_RIGHT,
                    }
                ),
                generateSubMenuConfigUnderSelectionSort(
                    !useViewState('actionEnabledCanUseAlignStatus'),
                    command,
                    getAlignModeName(Wukong.DocumentProto.AlignType.ALIGN_TYPE_TOP),
                    getAlignModeShortCut(Wukong.DocumentProto.AlignType.ALIGN_TYPE_TOP),
                    true,
                    ArrangeAlignCommand,
                    true,
                    {
                        type: Wukong.DocumentProto.AlignType.ALIGN_TYPE_TOP,
                    }
                ),
                generateSubMenuConfigUnderSelectionSort(
                    !useViewState('actionEnabledCanUseAlignStatus'),
                    command,
                    getAlignModeName(Wukong.DocumentProto.AlignType.ALIGN_TYPE_VERTICAL_CENTERS),
                    getAlignModeShortCut(Wukong.DocumentProto.AlignType.ALIGN_TYPE_VERTICAL_CENTERS),
                    true,
                    ArrangeAlignCommand,
                    true,
                    {
                        type: Wukong.DocumentProto.AlignType.ALIGN_TYPE_VERTICAL_CENTERS,
                    }
                ),
                generateSubMenuConfigUnderSelectionSort(
                    !useViewState('actionEnabledCanUseAlignStatus'),
                    command,
                    getAlignModeName(Wukong.DocumentProto.AlignType.ALIGN_TYPE_BOTTOM),
                    getAlignModeShortCut(Wukong.DocumentProto.AlignType.ALIGN_TYPE_BOTTOM),
                    true,
                    ArrangeAlignCommand,
                    true,
                    {
                        type: Wukong.DocumentProto.AlignType.ALIGN_TYPE_BOTTOM,
                    }
                ),
                { type: WKItemType.Divider },
                generateSubMenuConfigUnderSelectionSort(
                    !useViewState('actionEnabledCanUsePackStatus'),
                    command,
                    translation('PackHorizontal'),
                    '',
                    true,
                    ArrangePackCommand,
                    true,
                    {
                        type: Wukong.DocumentProto.PackType.PACK_TYPE_HORIZONTAL,
                    }
                ),
                generateSubMenuConfigUnderSelectionSort(
                    !useViewState('actionEnabledCanUsePackStatus'),
                    command,
                    translation('PackVertical'),
                    '',
                    true,
                    ArrangePackCommand,
                    true,
                    {
                        type: Wukong.DocumentProto.PackType.PACK_TYPE_VERTICAL,
                    }
                ),
                { type: WKItemType.Divider },
                generateSubMenuConfigUnderSelectionSort(
                    !useViewState('actionEnabledCanUseDistributeHVStatus'),
                    command,
                    translation('DistributeHorizontalSpacing'),
                    shortcutLabelMap[ShortcutKey.DistributeHorizontalSpacing],
                    true,
                    ArrangeDistributeCommand,
                    true,
                    {
                        type: Wukong.DocumentProto.DistributeType.DISTRIBUTE_TYPE_HORIZONTAL_SPACING,
                    }
                ),
                generateSubMenuConfigUnderSelectionSort(
                    !useViewState('actionEnabledCanUseDistributeHVStatus'),
                    command,
                    translation('DistributeVerticalSpacing'),
                    shortcutLabelMap[ShortcutKey.DistributeVerticalSpacing],
                    true,
                    ArrangeDistributeCommand,
                    true,
                    {
                        type: Wukong.DocumentProto.DistributeType.DISTRIBUTE_TYPE_VERTICAL_SPACING,
                    }
                ),
                { type: WKItemType.Divider },
                generateSubMenuConfigUnderSelectionSort(
                    !useViewState('actionEnabledCanUseDistributeDirectionStatus'),
                    command,
                    translation('DistributeLeft'),
                    '',
                    true,
                    ArrangeDistributeCommand,
                    true,
                    {
                        type: Wukong.DocumentProto.DistributeType.DISTRIBUTE_TYPE_LEFT,
                    }
                ),
                generateSubMenuConfigUnderSelectionSort(
                    !useViewState('actionEnabledCanUseDistributeHVStatus'),
                    command,
                    translation('DistributeHorizontalCenters'),
                    '',
                    true,
                    ArrangeDistributeCommand,
                    true,
                    {
                        type: Wukong.DocumentProto.DistributeType.DISTRIBUTE_TYPE_HORIZONTAL_CENTERS,
                    }
                ),
                generateSubMenuConfigUnderSelectionSort(
                    !useViewState('actionEnabledCanUseDistributeDirectionStatus'),
                    command,
                    translation('DistributeRight'),
                    '',
                    true,
                    ArrangeDistributeCommand,
                    true,
                    {
                        type: Wukong.DocumentProto.DistributeType.DISTRIBUTE_TYPE_RIGHT,
                    }
                ),
                generateSubMenuConfigUnderSelectionSort(
                    !useViewState('actionEnabledCanUseDistributeDirectionStatus'),
                    command,
                    translation('DistributeTop'),
                    '',
                    true,
                    ArrangeDistributeCommand,
                    true,
                    {
                        type: Wukong.DocumentProto.DistributeType.DISTRIBUTE_TYPE_TOP,
                    }
                ),
                generateSubMenuConfigUnderSelectionSort(
                    !useViewState('actionEnabledCanUseDistributeHVStatus'),
                    command,
                    translation('DistributeVerticalCenters'),
                    '',
                    true,
                    ArrangeDistributeCommand,
                    true,
                    {
                        type: Wukong.DocumentProto.DistributeType.DISTRIBUTE_TYPE_VERTICAL_CENTERS,
                    }
                ),
                generateSubMenuConfigUnderSelectionSort(
                    !useViewState('actionEnabledCanUseDistributeDirectionStatus'),
                    command,
                    translation('DistributeBottom'),
                    '',
                    true,
                    ArrangeDistributeCommand,
                    true,
                    {
                        type: Wukong.DocumentProto.DistributeType.DISTRIBUTE_TYPE_BOTTOM,
                    }
                ),
            ],
        },
    ]
}
