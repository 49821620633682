import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    EditStyle: '编辑样式',
    ColorStyles: '颜色样式',
    CreateNewColor: '创建颜色样式',
} as const

export const enTranslation = {
    EditStyle: 'Edit style',
    ColorStyles: 'Color styles',
    CreateNewColor: 'Create color style',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
