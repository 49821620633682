import { KeyboardEvent, MouseEvent, createContext } from 'react'

export const optionElementClassName = `motiff_select_option`
export const selectElementClassName = `motiff_select_option_selected`
export const preselectClassName = 'motiff_option_preselect'

export interface SingleLevelContextProps {
    preselectValue: any
    selectOptionsValue: any[] | any
    selectElementClassName: string
    optionElementClassName: string
    preselectClassName: string
    onChange: (value: any, e?: KeyboardEvent | MouseEvent) => void
    setPreselectValueByMouse: (value: any) => void
}
export const defaultSingleLevelContext: SingleLevelContextProps = {
    selectOptionsValue: undefined,
    preselectValue: undefined,
    selectElementClassName,
    optionElementClassName,
    preselectClassName,
    onChange: () => {},
    setPreselectValueByMouse: () => {},
}
export const SingleLevelContext = createContext<SingleLevelContextProps>(defaultSingleLevelContext)
