export interface UsualEmoji {
    emoji: string
    id: string
}

export const usualEmojis: UsualEmoji[] = [
    { emoji: '😂', id: ':joy:' },
    { emoji: '😭', id: ':sob:' },
    { emoji: '😍', id: ':heart_eyes:' },
    { emoji: '👌', id: ':ok_hand:' },
    { emoji: '👍', id: ':+1:' },
    { emoji: '👎', id: ':-1:' },
    { emoji: '👏', id: ':clap:' },
    { emoji: '🙏', id: ':pray:' },
    { emoji: '✅', id: ':white_check_mark:' },
]
