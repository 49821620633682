import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    You: '（你）',
    Viewer: '访客',
    Pending: '待接受邀请',
    ActualRoleOwner: '由于当前用户是{{type}}所有者，所以仍然可以编辑{{type}}。',
    ActualRoleEditor: '由于当前用户在{{type}}上被邀请为「可编辑」，所以仍然可以编辑{{type}}。',
    ActualRoleViewer: '由于当前用户在{{type}}上被邀请为「可查看」，所以无法编辑{{type}}。',
    ActualRoleViewPrototype: '由于当前用户在文件上被邀请为「可查看原型」，所以只能查看被分享的原型。',
    LinkShare: '当前用户通过链接分享获得了文件「{{role}}」权限。',
    ShowLess: '收起',
    ShowMore: '展开',
    SeatRequestPending: '席位申请中...审批前可使用 14 天',
    CannotEdit: '由于当前用户没有设计席位，所以无法编辑文件。',
    CannotEditWithLink: '当前用户通过链接分享获得了文件「可编辑」权限，但因为没有设计席位，所以无法编辑文件。',
    PendingJoin: ' (待加入)',
    File: '文件',
    Project: '项目',
    OrgMember: '',
    Anyone: '',
    Edit: '可编辑',
    View: '可查看',
    TeamMemberHide: '团队成员的访问权限不可见',
    ProjectMemberHide: '项目成员的访问权限不可见',
} as const

export const enTranslation = {
    You: ' (You)',
    Viewer: 'Guest',
    Pending: 'Pending',
    ActualRoleOwner: 'This person can edit this {{type}} because they are the owner of this {{type}}.',
    ActualRoleEditor:
        'This person can edit this {{type}} because they have been invited to this {{type}} as an editor.',
    ActualRoleViewer:
        'This person cannot edit this {{type}} because they have been invited to this {{type}} as a viewer.',
    ActualRoleViewPrototype:
        'This person can only view shared prototypes because they have been invited to this file as a prototype viewer.',
    LinkShare: 'This person can {{role}} this file because it is set to allow editing by {{scope}} with link.',
    ShowLess: 'Show less',
    ShowMore: 'Show more',
    SeatRequestPending: 'Upgrade request pending... Available for 14 days before approval',
    CannotEdit: 'This person cannot edit files because they do not have a "Designer seat".',
    CannotEditWithLink:
        'This person has edit access via link sharing, but they cannot edit files because they do not have a "Designer seat".',
    DraftOwnerLimit:
        'The actual permissions is Can View, because the draft owner has been restricted to share edit permissions.',
    PendingJoin: ' (Pending)',
    File: 'file',
    Project: 'project',
    OrgMember: 'org member',
    Anyone: 'anyone',
    Edit: 'edit',
    View: 'view',
    TeamMemberHide: "Team member's access is not visible",
    ProjectMemberHide: "Project member's access is not visible",
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
