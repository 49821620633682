import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    SearchFonts: '搜索全部字体',
    FindNewVerson: '发现新版本本地字体助手',
    NextTime: '下次再说',
    Update: '立即更新',
} as const

export const enTranslation = {
    SearchFonts: 'Search fonts',
    FindNewVerson: 'New font agent version available',
    NextTime: 'Later',
    Update: 'Upgrade now',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
