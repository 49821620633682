/*
    r、g、b、s、v -> [0,1]
    h -> [0,360)
*/
export interface HSV {
    h: number
    s: number
    v: number
}
export function rgb2hsv(r: number, g: number, b: number): HSV {
    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    let h: number
    let s: number

    if (max === min) {
        h = 0
    } else if (max === r) {
        if (g >= b) {
            h = (60 * (g - b)) / (max - min)
        } else {
            h = (60 * (g - b)) / (max - min) + 360
        }
    } else if (max === g) {
        h = (60 * (b - r)) / (max - min) + 120
    } else {
        h = (60 * (r - g)) / (max - min) + 240
    }

    if (max === min) {
        s = 0
    } else {
        s = 1 - min / max
    }

    return { h, s, v: max }
}
