import { Wukong } from '@wukong/bridge-proto'
import { useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import { PrototypeTestId } from '../../../../../window'
import { FadeSlideEffects, FadeSlideEffectsProps } from './animation-effects/fade-slide-effects'
import { SmartAnimationEffects } from './animation-effects/smart-animation-effects'
import { TransitionEffects, TransitionEffectsProps } from './animation-effects/transition-effects'
import classes from './interaction-action-animation.module.less'
import { TransitionContainerProps } from './transition/transition-container'

export type CubicBezierNumber = [number, number, number, number]
export interface ActionAnimationPreviewProps {
    transitionType: Wukong.DocumentProto.TransitionType
    easingFunction: CubicBezierNumber
    durationMs: number
    delayMs?: number
    disabledAnimation: boolean
    transitionShouldSmartAnimate: Wukong.DocumentProto.ITransitionShouldSmartAnimate
}
export function ActionAnimationPreview(props: ActionAnimationPreviewProps) {
    const { transitionType, easingFunction, durationMs, delayMs, disabledAnimation, transitionShouldSmartAnimate } =
        props
    const [hasUpdate, setHasUpdate] = useState<boolean>(false)

    const disabledCssAnimation = useMemo(() => {
        return hasUpdate ? disabledAnimation : true
    }, [disabledAnimation, hasUpdate])

    const propsUnion = useMemo(
        () =>
            generateTransitionPropsUnion(
                transitionType,
                generateTransition(easingFunction, durationMs, delayMs),
                {
                    disabledPaly: disabledCssAnimation,
                    shouldStartTransition: () => setHasUpdate(true),
                },
                transitionShouldSmartAnimate
            ),
        [delayMs, disabledCssAnimation, durationMs, easingFunction, transitionType, transitionShouldSmartAnimate]
    )

    useUpdateEffect(() => setHasUpdate(true))

    const animationComponent = useMemo(() => {
        switch (propsUnion?.componentName) {
            case 'FadeSlideEffects':
                return <FadeSlideEffects key={transitionType} {...propsUnion.props} />
            case 'TransitionEffects':
                return <TransitionEffects key={transitionType} {...propsUnion.props} />
            case 'SmartAnimationEffects':
                return <SmartAnimationEffects key={transitionType} {...propsUnion.props} />
            default:
                return null
        }
    }, [propsUnion, transitionType])

    return (
        <div
            className={classes.animationPreviewArea}
            data-testid={PrototypeTestId.InteractionPopup.AnimationPreviewArea}
        >
            {animationComponent}
        </div>
    )
}

function generateTransition(easingFunction: CubicBezierNumber, durationMs?: number, delayMs?: number) {
    return `all ${durationMs ?? 200}ms cubic-bezier(${easingFunction.join(', ')}) ${delayMs ?? 0}ms`
}

export function generateTransitionPropsUnion(
    transitionType: Wukong.DocumentProto.TransitionType,
    transition: ReturnType<typeof generateTransition>,
    restProps?: {
        disabledPaly?: boolean
        shouldStartTransition?: TransitionContainerProps['shouldStartTransition']
    },
    transitionShouldSmartAnimate?: Wukong.DocumentProto.ITransitionShouldSmartAnimate
):
    | { componentName: 'FadeSlideEffects'; props: FadeSlideEffectsProps }
    | { componentName: 'TransitionEffects'; props: TransitionEffectsProps }
    | { componentName: 'SmartAnimationEffects'; props: SmartAnimationEffects }
    | undefined {
    let fadeSlideEffectsProps: FadeSlideEffectsProps | undefined = undefined
    let transitionEffectsProps: TransitionEffectsProps | undefined = undefined

    switch (transitionType) {
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_DISSOLVE:
            transitionEffectsProps = { block: {} }
            break
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_MOVE_FROM_LEFT:
            fadeSlideEffectsProps = { upperBlock: { from: 'left', to: 'center' } }
            break
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_SCROLL_ANIMATE:
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_MOVE_FROM_RIGHT:
            fadeSlideEffectsProps = { upperBlock: { from: 'right', to: 'center' } }
            break
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_MOVE_FROM_TOP:
            fadeSlideEffectsProps = { upperBlock: { from: 'top', to: 'center' } }
            break
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_MOVE_FROM_BOTTOM:
            fadeSlideEffectsProps = { upperBlock: { from: 'bottom', to: 'center' } }
            break
        // toRight 给的是 TRANSITION_TYPE_MOVE_OUT_TO_LEFT
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_LEFT:
            fadeSlideEffectsProps = {
                upperBlock: { from: 'center', to: 'right', browHeight: 8 },
                lowerBlock: { browHeight: 16 },
            }
            break
        // toLeft 给的是 TRANSITION_TYPE_MOVE_OUT_TO_RIGHT
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_RIGHT:
            fadeSlideEffectsProps = {
                upperBlock: { from: 'center', to: 'left', browHeight: 8 },
                lowerBlock: { browHeight: 16 },
            }
            break
        // toBottom 给的是 TRANSITION_TYPE_MOVE_OUT_TO_TOP
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_TOP:
            fadeSlideEffectsProps = {
                upperBlock: { from: 'center', to: 'bottom', browHeight: 8 },
                lowerBlock: { browHeight: 16 },
            }
            break
        // toTop 给的是 TRANSITION_TYPE_MOVE_OUT_TO_BOTTOM
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_BOTTOM:
            fadeSlideEffectsProps = {
                upperBlock: { from: 'center', to: 'top', browHeight: 8 },
                lowerBlock: { browHeight: 16 },
            }
            break
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_PUSH_FROM_LEFT:
            fadeSlideEffectsProps = {
                upperBlock: { from: 'left', to: 'center', backgroundColor: 'white' },
                lowerBlock: { from: 'center', to: 'right' },
            }
            break
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_PUSH_FROM_RIGHT:
            fadeSlideEffectsProps = {
                upperBlock: { from: 'right', to: 'center', backgroundColor: 'white' },
                lowerBlock: { from: 'center', to: 'left' },
            }
            break
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_PUSH_FROM_TOP:
            fadeSlideEffectsProps = {
                upperBlock: { from: 'top', to: 'center', backgroundColor: 'white' },
                lowerBlock: { from: 'center', to: 'bottom' },
            }
            break
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_PUSH_FROM_BOTTOM:
            fadeSlideEffectsProps = {
                upperBlock: { from: 'bottom', to: 'center', backgroundColor: 'white' },
                lowerBlock: { from: 'center', to: 'top' },
            }
            break
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_SLIDE_FROM_LEFT:
            fadeSlideEffectsProps = {
                upperBlock: { from: 'left', to: 'center' },
                lowerBlock: { from: 'center', to: 'near-right' },
            }
            break
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_SLIDE_FROM_RIGHT:
            fadeSlideEffectsProps = {
                upperBlock: { from: 'right', to: 'center' },
                lowerBlock: { from: 'center', to: 'near-left' },
            }
            break
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_SLIDE_FROM_TOP:
            fadeSlideEffectsProps = {
                upperBlock: { from: 'top', to: 'center' },
                lowerBlock: { from: 'center', to: 'near-bottom' },
            }
            break
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_SLIDE_FROM_BOTTOM:
            fadeSlideEffectsProps = {
                upperBlock: { from: 'bottom', to: 'center' },
                lowerBlock: { from: 'center', to: 'near-top' },
            }
            break
        // toRight 给的是 TRANSITION_TYPE_SLIDE_OUT_TO_LEFT
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_LEFT:
            fadeSlideEffectsProps = {
                upperBlock: { from: 'center', to: 'right', browHeight: 8 },
                lowerBlock: { from: 'near-left', to: 'center', browHeight: 16 },
            }
            break
        // toLeft 给的是 TRANSITION_TYPE_SLIDE_OUT_TO_RIGHT
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_RIGHT:
            fadeSlideEffectsProps = {
                upperBlock: { from: 'center', to: 'left', browHeight: 8 },
                lowerBlock: { from: 'near-right', to: 'center', browHeight: 16 },
            }
            break
        // toBottom 给的是 TRANSITION_TYPE_SLIDE_OUT_TO_TOP
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_TOP:
            fadeSlideEffectsProps = {
                upperBlock: { from: 'center', to: 'bottom', browHeight: 8 },
                lowerBlock: { from: 'near-top', to: 'center', browHeight: 16 },
            }
            break
        // toTop 给的是 TRANSITION_TYPE_SLIDE_OUT_TO_BOTTOM
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_BOTTOM:
            fadeSlideEffectsProps = {
                upperBlock: { from: 'center', to: 'top', browHeight: 8 },
                lowerBlock: { from: 'near-bottom', to: 'center', browHeight: 16 },
            }
            break
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_MAGIC_MOVE:
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_INSTANT_TRANSITION:
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_SMART_ANIMATE:
        case Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_FADE:
        default:
            break
    }

    const animationPaly = restProps?.disabledPaly ? false : undefined
    if (fadeSlideEffectsProps) {
        fadeSlideEffectsProps.shouldStartTransition = restProps?.shouldStartTransition
        fadeSlideEffectsProps.upperBlock.transition = transition
        fadeSlideEffectsProps.upperBlock.play = animationPaly
        if (fadeSlideEffectsProps.lowerBlock) {
            fadeSlideEffectsProps.lowerBlock.transition = transition
            fadeSlideEffectsProps.lowerBlock.play = animationPaly
        }

        const showSmartHeader =
            transitionShouldSmartAnimate &&
            ((transitionShouldSmartAnimate.valueType ===
                Wukong.DocumentProto.InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_NORMAL &&
                transitionShouldSmartAnimate.value) ||
                transitionShouldSmartAnimate.valueType ===
                    Wukong.DocumentProto.InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_MIXED)
        if (showSmartHeader) {
            fadeSlideEffectsProps.upperBlock.browHeight = 0
            if (fadeSlideEffectsProps.lowerBlock) {
                fadeSlideEffectsProps.lowerBlock.browHeight = 0
            }

            fadeSlideEffectsProps.smartHeaderBlock = {
                transition: transition,
                play: animationPaly,
            }
        }
        return { componentName: 'FadeSlideEffects', props: fadeSlideEffectsProps }
    }

    if (transitionEffectsProps) {
        transitionEffectsProps.shouldStartTransition = restProps?.shouldStartTransition
        transitionEffectsProps.block.transition = transition
        transitionEffectsProps.block.play = animationPaly
        return { componentName: 'TransitionEffects', props: transitionEffectsProps }
    }

    if (transitionType === Wukong.DocumentProto.TransitionType.TRANSITION_TYPE_SMART_ANIMATE) {
        return {
            componentName: 'SmartAnimationEffects',
            props: {
                shouldStartTransition: restProps?.shouldStartTransition,
                block: { transition, play: animationPaly },
            },
        }
    }
}
