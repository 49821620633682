import { debugLog } from '../../app/src/kernel/debug'

class EffectControllerError extends Error {
    constructor(description: string) {
        super(description)
        this.name = 'EffectControllerError'
    }
}

/**
 * @deprecated
 * 创建一个 controller，包含 abort 和 cleanup 的功能
 * 在调用 controller.destroy 时，会先 abort 掉由 abortSignal 控制的所有 async task，然后按照注册的顺序执行 cleanup
 */
export class EffectController {
    private static _controllerId = 0
    private static _controllerMap = new Map<string, EffectController>()
    static controllerKeys() {
        return Array.from(EffectController._controllerMap.keys())
    }

    readonly id: string
    constructor(label?: string) {
        this.id = `${EffectController._controllerId++}:${label ?? '~'}`
        EffectController._controllerMap.set(this.id, this)
        debugLog(`Create Controller (${this.id})`)
    }

    private abortController = new AbortController()
    get aborted() {
        return this.abortController.signal.aborted
    }
    onCleanup = (cleanupCallback: (reason?: string) => void) => {
        // 如果挂载时已经销毁，则会报错，不应该在销毁后再挂载任何清理函数，应该在外部阻止继续执行
        if (this.aborted) {
            throw new EffectControllerError('Cannot add any effect to an already destroyed controller.')
        }
        const cleanupOnce = () => {
            cleanupCallback(this.abortController.signal.reason)
            this.abortController.signal.removeEventListener('abort', cleanupOnce)
        }
        // 挂载时默认 once 为 true，即只执行一次，执行后或 abort 后会自行清理
        this.abortController.signal.addEventListener('abort', cleanupOnce, { once: true })
    }
    destroy(reason?: string) {
        debugLog(`Destroy Controller (${this.id})`)
        // 不应该重复销毁一个 controller 多次，如果重新开启一个流程，应该重建一个新的 controller
        if (this.aborted) {
            throw new EffectControllerError('Cannot destroy an already destroyed controller.')
        }
        this.abortController.abort(reason)
        EffectController._controllerMap.delete(this.id)
    }
    // 创建一个会跟随自己销毁的子 controller
    createChildController(id?: string) {
        const controller = new EffectController(id)
        this.onCleanup(() => {
            // 子 controller 允许自己先销毁，所以父 controller 销毁时会检查并跳过
            if (controller.aborted) {
                return
            }
            controller.destroy()
        })
        return controller
    }
}

/**
 * @deprecated
 * 支持在 controller cleanup 时 destroy 的基类
 */
export abstract class ClassWithEffect {
    protected controller: EffectController
    constructor(controller: EffectController) {
        this.controller = controller
        debugLog(`Create Service (${this.constructor.name}) in Controller (${this.controller.id})`)
        controller.onCleanup(() => {
            debugLog(`Destroy Service (${this.constructor.name}) in Controller (${this.controller.id})`)
            this.destroy()
        })
    }
    abstract destroy(): void
}

/**
 * @deprecated
 */
export function setTimeoutWithController(callback: () => void, timeout: number, controller: EffectController) {
    const sto = setTimeout(callback, timeout)
    controller.onCleanup(() => clearTimeout(sto))
    return sto
}
