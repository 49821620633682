import { Wukong } from '@wukong/bridge-proto'
import { toFixed } from './to-fixed'

const getFontMeta = (fontName: Wukong.DocumentProto.IFontName, fontMetaData?: Wukong.DocumentProto.IFontMetaData[]) => {
    return (fontMetaData || []).find((v) => v.key?.family === fontName.family && v.key?.style === fontName.style)
}

export const getWeight = (
    fontName: Wukong.DocumentProto.IFontName,
    fontMetaData?: Wukong.DocumentProto.IFontMetaData[]
) => {
    const item = getFontMeta(fontName, fontMetaData)
    return item?.fontWeight || 0
}

export const isItalic = (
    fontName: Wukong.DocumentProto.IFontName,
    fontMetaData?: Wukong.DocumentProto.IFontMetaData[]
) => {
    const item = getFontMeta(fontName, fontMetaData)
    return item?.fontStyle == Wukong.DocumentProto.FontStyle.FONT_STYLE_ITALIC
}

export function createAxisTag(a: string, b: string, c: string, d: string) {
    return (a.charCodeAt(0) << 24) | (b.charCodeAt(0) << 16) | (c.charCodeAt(0) << 8) | d.charCodeAt(0)
}

export function axisTagToString(axisTag: number) {
    return (
        String.fromCharCode((axisTag >> 24) & 0xff) +
        String.fromCharCode((axisTag >> 16) & 0xff) +
        String.fromCharCode((axisTag >> 8) & 0xff) +
        String.fromCharCode(axisTag & 0xff)
    )
}

export const tryGetAxisWeight = (fontVariations: Wukong.DocumentProto.IFontVariation[]) => {
    return fontVariations.find((v) => v.axisTag === createAxisTag('w', 'g', 'h', 't'))?.value ?? null
}

export const isItalAxisItalic = (fontVariations: Wukong.DocumentProto.IFontVariation[]): boolean => {
    const italAxis = fontVariations.find((v) => v.axisTag === createAxisTag('i', 't', 'a', 'l'))
    return (italAxis?.value ?? 0) !== 0
}

export const isSlntAxisItalic = (fontVariations: Wukong.DocumentProto.IFontVariation[]): boolean => {
    const slantAxis = fontVariations.find((v) => v.axisTag === createAxisTag('s', 'l', 'n', 't'))
    return (slantAxis?.value ?? 0) !== 0
}

export const tryGetAxisOpticalSize = (
    detachOpticalSizeFromFontSize: boolean,
    fontVariations: Wukong.DocumentProto.IFontVariation[]
) => {
    if (!detachOpticalSizeFromFontSize) {
        return null
    }

    return fontVariations.find((v) => v.axisTag === createAxisTag('o', 'p', 's', 'z'))?.value ?? null
}

/**
 * 文本属性可变值展示内容
 */
export const getOtherAxisLabel = (fontVariations: Wukong.DocumentProto.IFontVariation[]) => {
    return fontVariations
        .filter((v) => !['opsz', 'wght'].includes(axisTagToString(v.axisTag!)))
        .map((v) => `${axisTagToString(v.axisTag!)}: ${toFixed(v.value ?? 0, 2)}`)
        .join(', ')
}
