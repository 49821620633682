import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { Select } from '../../../../../../ui-lib/src'
import style from './layout-title.module.less'
import { translation } from './layout-title.translation'

const layoutTypeToShow: { [key in Wukong.DocumentProto.LayoutGridPattern]: string } = {
    [Wukong.DocumentProto.LayoutGridPattern.LAYOUT_GRID_PATTERN_GRID]: translation('Grid'),
    [Wukong.DocumentProto.LayoutGridPattern.LAYOUT_GRID_PATTERN_ROWS]: translation('Rows'),
    [Wukong.DocumentProto.LayoutGridPattern.LAYOUT_GRID_PATTERN_COLUMNS]: translation('Columns'),
}

export interface LayoutTitleProps {
    data: Wukong.DocumentProto.ILayoutGrid
    onChangeLayoutPattern?: (pattern: Wukong.DocumentProto.LayoutGridPattern) => void
}

export function LayoutTitle(props: LayoutTitleProps) {
    const { data, onChangeLayoutPattern } = props

    return (
        <div className={classnames(style.root)}>
            <div className={style.titleContainer}>
                <Select.MinimalSingleLevel
                    value={data.pattern}
                    label={layoutTypeToShow[data.pattern!]}
                    onChange={onChangeLayoutPattern}
                    minWidth={88}
                    weight="semibold"
                    dataTestIds={{
                        container: 'layout-title-container',
                        triggerFocus: 'layout-title-trigger-focus',
                    }}
                >
                    {Object.keys(layoutTypeToShow).map((key) => {
                        const layoutType: Wukong.DocumentProto.LayoutGridPattern = Number(key)
                        return (
                            <Select.MinimalSingleLevel.Option
                                value={layoutType}
                                key={key}
                                backwardIcon={''}
                                dataTestId={`layout-title-${layoutType}`}
                            >
                                {layoutTypeToShow[layoutType]}
                            </Select.MinimalSingleLevel.Option>
                        )
                    })}
                </Select.MinimalSingleLevel>
            </div>
        </div>
    )
}
