/**
 * @author: jiangzg@kanyun.com
 */
import classNames from 'classnames'
import { CollapseContextProvider, WKCollapseContextProps } from './use-collapse'

export interface WKCollapseProps extends WKCollapseContextProps {
    /**
     * @default false
     */
    divider?: boolean
    children?: React.ReactNode
    style?: React.CSSProperties
}

export const WKCollapse = ({
    selectionMode,
    defaultSelection,
    selection,
    onSelectionChange,
    children,
    divider = false,
    style,
}: WKCollapseProps) => {
    return (
        <CollapseContextProvider
            selectionMode={selectionMode}
            defaultSelection={defaultSelection}
            selection={selection}
            onSelectionChange={onSelectionChange}
        >
            <div
                className={classNames('w-full divide-y-1 divide-x-0 divide-solid divide-$wk-gray-3', {
                    'divide-none': !divider,
                })}
                style={style}
            >
                {children}
            </div>
        </CollapseContextProvider>
    )
}
