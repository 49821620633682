import { translation } from './stroke-cap-select.translation'
/* eslint-disable no-restricted-imports */
import { isNil } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import { Select, toMixed } from '../../../../../../../ui-lib/src'
import { StrokeCap } from '../../../../../document/node/node'
import { EditorDataTestId } from '../../../../../window'
import { getStrokeCapIconMaps } from './svg-stroke-cap'

type iconOptionDirection = 'start' | 'end'
export interface StrokeCapSelectProps {
    strokeCap?: StrokeCap
    iconOptionDirection: iconOptionDirection
    iconLabel: 'short' | 'long' | 'long-all'
    mixed: boolean
    disabled?: boolean
    onChange?: (strokeCap: StrokeCap) => void
}
export function StrokeCapSelect(props: StrokeCapSelectProps) {
    const { strokeCap, mixed, iconOptionDirection, iconLabel, disabled, onChange } = props

    const strokeCapOptionsInfo = useMemo(() => {
        return createStrokeCapOptionsInfo(iconOptionDirection)
    }, [iconOptionDirection])

    const strokeCapIconMaps = useMemo(() => getStrokeCapIconMaps(), [])

    const getSelectLabel = useCallback(
        (_strokeCap: StrokeCap | undefined) => {
            if (mixed) {
                return toMixed()
            }

            if (!isNil(_strokeCap)) {
                if (iconLabel === 'long-all') {
                    return strokeCapIconMaps.get(_strokeCap)?.allLong
                }
                if (iconLabel === 'short') {
                    return iconOptionDirection === 'start'
                        ? strokeCapIconMaps.get(_strokeCap)?.startShort
                        : strokeCapIconMaps.get(_strokeCap)?.endShort
                }
                if (iconLabel === 'long') {
                    return iconOptionDirection === 'start'
                        ? strokeCapIconMaps.get(_strokeCap)?.startLong
                        : strokeCapIconMaps.get(_strokeCap)?.endLong
                }
            }

            return undefined
        },
        [iconLabel, iconOptionDirection, mixed, strokeCapIconMaps]
    )

    return (
        <Select.NormalSingleLevel
            disabled={disabled}
            value={strokeCap}
            isMixed={mixed}
            onChange={onChange}
            label={getSelectLabel(strokeCap) ?? translation('None')}
            minWidth={184}
            dataTestIds={{
                triggerContainer: EditorDataTestId.StrokeAttr.CapSelects,
                triggerFocus: EditorDataTestId.StrokeAttr.CapSelectTriggers,
            }}
        >
            {strokeCapOptionsInfo.map((info) => (
                <Select.NormalSingleLevel.Option
                    value={info.strokeCap}
                    key={info.strokeCap}
                    forwardIcon={info.optionIcon}
                    backwardIcon={''}
                    splitLineTop={info.splitLineTop}
                >
                    {info.name}
                </Select.NormalSingleLevel.Option>
            ))}
        </Select.NormalSingleLevel>
    )
}

function needSplitLine(strokeCap: StrokeCap) {
    switch (strokeCap) {
        case StrokeCap.STROKE_CAP_ROUND:
            return true
        default:
            return false
    }
}

interface OptionIconInfo {
    strokeCap: StrokeCap
    optionIcon: JSX.Element
    name: string
    splitLineTop: boolean
}
function createStrokeCapOptionsInfo(type: iconOptionDirection) {
    const isStart = type === 'start'
    const strokeCapOptionsInfo: OptionIconInfo[] = []
    for (const [strokeCap, iconGroup] of getStrokeCapIconMaps()) {
        strokeCapOptionsInfo.push({
            strokeCap,
            optionIcon: isStart ? iconGroup.start : iconGroup.end,
            name: iconGroup.name,
            splitLineTop: needSplitLine(strokeCap),
        })
    }
    return strokeCapOptionsInfo
}
