import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    FailedToAcquire: '获取上传权限失败，如果你使用了网络代理，请关闭代理后重试。',
} as const

export const enTranslation = {
    FailedToAcquire: "Failed to acquire upload permissions. If you're using a network proxy, turn it off and retry.",
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
