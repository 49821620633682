import { translation } from './export-setting-item.translation'
/* eslint-disable no-restricted-imports */
import {
    DeleteExportSettingsToSelectionCommand,
    UpdateExportSettingsToSelectionCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { FunctionComponent, useCallback } from 'react'
import { useEffectOnce } from 'react-use'
import { IconDelete16, InputV2, Select } from '../../../../../../ui-lib/src'
import { CommitType } from '../../../../document/command/commit-type'
import { ConstraintUnit, ExportSettings } from '../../../../document/node/node'
import { ExportTestId, WK } from '../../../../window'
import { useCommand } from '../../../context/document-context'
import { IconButton } from '../../atom/button/icon-button'
import { SingleGrid } from '../../atom/grid/single-grid'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { ScrubbableInputExportConstraint } from '../../atom/inputs/scrubbable-input-export-constraint'
import { charFromUnit, parse } from '../../atom/inputs/utils/use-export-constraint-formatter-tool'
import styles from './export.module.less'
import { constraintValueScale, exportTypeToShow } from './util'
import ExportSettingsConstraintsType = Wukong.DocumentProto.ExportConstraintType
import ExportFormatType = Wukong.DocumentProto.ExportFormatType

export interface ExportSettingItemProps {
    exportSetting: ExportSettings
    idx: number
}

const scaleToShow = new Map<string, string>([
    ['0.5x', '0.5x'],
    ['0.75x', '0.75x'],
    ['1x', '1x'],
    ['1.5x', '1.5x'],
    ['2x', '2x'],
    ['3x', '3x'],
    ['4x', '4x'],
    ['512w', '512w'],
    ['512h', '512h'],
])

const ConstraintTypeToUnit = (type: ExportSettingsConstraintsType | null | undefined): ConstraintUnit => {
    switch (type) {
        case ExportSettingsConstraintsType.EXPORT_CONSTRAINT_TYPE_HEIGHT:
            return ConstraintUnit.Height
        case ExportSettingsConstraintsType.EXPORT_CONSTRAINT_TYPE_WIDTH:
            return ConstraintUnit.Width
        default:
            return ConstraintUnit.Scale
    }
}

const ConstraintUnitToType = (type: ConstraintUnit | null | undefined): ExportSettingsConstraintsType => {
    switch (type) {
        case ConstraintUnit.Height:
            return ExportSettingsConstraintsType.EXPORT_CONSTRAINT_TYPE_HEIGHT
        case ConstraintUnit.Width:
            return ExportSettingsConstraintsType.EXPORT_CONSTRAINT_TYPE_WIDTH
        default:
            return ExportSettingsConstraintsType.EXPORT_CONSTRAINT_TYPE_SCALE
    }
}

// 如果要更改 constraint，需要将对应的类型添加到该数组里
const ExportFormatOfChangeConstraint = [
    ExportFormatType.EXPORT_FORMAT_TYPE_JPG,
    ExportFormatType.EXPORT_FORMAT_TYPE_PNG,
    ExportFormatType.EXPORT_FORMAT_TYPE_WEBP,
    ExportFormatType.EXPORT_FORMAT_TYPE_AVIF,
]

const stringfyConstaintValue = (_constraintValue: { value: number; unit: ConstraintUnit }) => {
    return `${_constraintValue.value}${charFromUnit(_constraintValue.unit)}`
}

export const ExportSettingItem: FunctionComponent<ExportSettingItemProps> = ({ exportSetting, idx }) => {
    const constraintValue = {
        value: (exportSetting?.constraint?.value ?? constraintValueScale) / constraintValueScale,
        unit: ConstraintTypeToUnit(exportSetting?.constraint?.type),
    }
    const command = useCommand()

    const disableConstraint = !ExportFormatOfChangeConstraint.includes(exportSetting?.format!)

    const handleUpdateAttr = useCallback(
        (
            index: number,
            attr: string,
            value: number | string | typeof constraintValue,
            options: InputOptionsForUndoSquash
        ) => {
            if (exportSetting) {
                let temp: Wukong.DocumentProto.IExportSettings = {
                    ...exportSetting,
                }
                if (attr === 'constraint' && typeof value === 'object') {
                    const type = ConstraintUnitToType(value.unit)
                    temp = { ...temp, constraint: { value: value.value * 1000, type } }
                } else {
                    temp = { ...temp, [attr]: value }
                }
                if (!ExportFormatOfChangeConstraint.includes(temp?.format!)) {
                    temp = {
                        ...temp,
                        constraint: {
                            value: 1 * constraintValueScale,
                            type: ExportSettingsConstraintsType.EXPORT_CONSTRAINT_TYPE_SCALE,
                        },
                    }
                }
                command.invokeBridge(options.commitType, UpdateExportSettingsToSelectionCommand, {
                    index,
                    setting: temp,
                })
            }
        },
        [command, exportSetting]
    )

    const handleDelete = useCallback(
        (value: number) => {
            command.DEPRECATED_invokeBridge(DeleteExportSettingsToSelectionCommand, {
                value,
            })
            command.commitUndo()
        },
        [command]
    )

    const onChange = useCallback(
        (value: { value: number; unit: ConstraintUnit }, options: InputOptionsForUndoSquash): void => {
            handleUpdateAttr(idx, 'constraint', value, options)
        },
        [handleUpdateAttr, idx]
    )

    useEffectOnce(() => {
        WK.changeExportFormat = (format: number) => {
            handleUpdateAttr(0, 'format', format, { commitType: CommitType.CommitUndo })
        }

        return () => {
            delete WK.changeExportFormat
        }
    })

    return (
        <SingleGrid testId="export-setting-item">
            <SingleGrid.Item start={5} span={15}>
                <Select.ArrowSingleLevel
                    isSmallArrow
                    value={stringfyConstaintValue(constraintValue)}
                    disabled={disableConstraint}
                    onChange={(value: string): void => {
                        const parsed = parse(value, constraintValue)
                        handleUpdateAttr(idx, 'constraint', parsed, { commitType: CommitType.CommitUndo })
                    }}
                    label={
                        <ScrubbableInputExportConstraint
                            value={constraintValue}
                            onChange={onChange}
                            noRightPadding
                            disabled={disableConstraint}
                        />
                    }
                    dataTestIds={{
                        triggerContainer: 'size-select',
                        tabElement: 'open-export-size-select',
                        container: 'export-size-select-options',
                    }}
                >
                    {!scaleToShow.get(stringfyConstaintValue(constraintValue)) ? (
                        <Select.ArrowSingleLevel.Option
                            splitLineBottom
                            value={stringfyConstaintValue(constraintValue)}
                            key={stringfyConstaintValue(constraintValue)}
                        >
                            {stringfyConstaintValue(constraintValue)}
                        </Select.ArrowSingleLevel.Option>
                    ) : null}
                    {[...scaleToShow.keys()].map((key, index) => {
                        return (
                            <Select.ArrowSingleLevel.Option value={key} key={index}>
                                {scaleToShow.get(key)}
                            </Select.ArrowSingleLevel.Option>
                        )
                    })}
                </Select.ArrowSingleLevel>
            </SingleGrid.Item>
            <SingleGrid.Item start={22} span={12}>
                <InputV2
                    size="small"
                    value={exportSetting?.suffix ?? ''}
                    placeholder={translation('Suffix')}
                    onBlur={(e) =>
                        handleUpdateAttr(idx, 'suffix', e.target.value, { commitType: CommitType.CommitUndo })
                    }
                />
            </SingleGrid.Item>
            <SingleGrid.Item start={36} span={15}>
                <Select.NormalSingleLevel
                    value={exportSetting?.format}
                    label={!isNil(exportSetting?.format) && exportTypeToShow.get(exportSetting.format)}
                    onChange={(value): void =>
                        handleUpdateAttr(idx, 'format', value, { commitType: CommitType.CommitUndo })
                    }
                >
                    {[...exportTypeToShow.keys()].map((key) => {
                        return (
                            <Select.NormalSingleLevel.Option
                                className={styles.select_option}
                                value={key}
                                key={key}
                                backwardIcon={''}
                                data-testid={ExportTestId.ExportType + '-' + exportTypeToShow.get(key)}
                            >
                                {exportTypeToShow.get(key)}
                            </Select.NormalSingleLevel.Option>
                        )
                    })}
                </Select.NormalSingleLevel>
            </SingleGrid.Item>
            <SingleGrid.Item start={51} span={12} horizontalCenter>
                <IconButton
                    dataTestId={`delete-${idx}-export-setting`}
                    icon={<IconDelete16 />}
                    selected={false}
                    onClick={(): void => handleDelete(idx)}
                />
            </SingleGrid.Item>
        </SingleGrid>
    )
}
