import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    LocalVariableSet: '本地变量',
    UnknownVariableSet: '未知库',
    Mixed: '多个值',
    ModeDeleted: '已删除的模式',
    VariableModeSelectTooltip: '修改变量模式',
} as const

export const enTranslation = {
    LocalVariableSet: 'Local variables',
    UnknownVariableSet: 'Unknown library',
    Mixed: 'Mixed',
    ModeDeleted: 'Deleted mode',
    VariableModeSelectTooltip: 'Change variable modes',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
