import { useCallback, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import { InputV2, WKButton, WKDialog, WKDialogProps } from '../../../../../ui-lib/src'
import { toast } from '../../../../../ui-lib/src/components/wk-toast/toast'
import { AuthenticatePhone, GetOtpPhone, OtpUsage } from '../../../kernel/request/account'
import { RequestResponseErrorHandler } from '../../../kernel/request/error-handler'
import { ReactRootRender } from '../../payment/react-root-render/react-root-render'

export interface RealNameVerificationStructProps
    extends Omit<WKDialogProps, 'title' | 'children' | 'testId' | 'bodyStyle'> {}

function RealNameVerificationStruct(props: RealNameVerificationStructProps) {
    const { cancelButtonProps, okButtonProps, onOk, ...otherProps } = props
    const [phone, setPhone] = useState('')
    const [phoneError, setPhoneError] = useState('')
    const [code, setCode] = useState('')
    const [codeError, setCodeError] = useState('')
    const [countdown, setCountdown] = useState(0)
    const [loading, setLoading] = useState(false)

    const checkPhone = useCallback(() => {
        if (!phone) {
            setPhoneError('请输入手机号')
            return false
        }
        if (!/^1[3-9]\d{9}$/.test(phone)) {
            setPhoneError('手机号格式错误')
            return false
        }
        setPhoneError('')
        return true
    }, [phone])

    const onChangePhone = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneError('')
        setPhone(e.target.value)
    }, [])
    const onChangeCode = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setCodeError('')
        setCode(e.target.value)
    }, [])

    const onSendCode = useCallback(() => {
        if (!checkPhone()) {
            return
        }
        setCountdown(59)
        new GetOtpPhone(phone, OtpUsage.authenticate)
            .start()
            .then(() => {
                toast.show('手机号验证码已发送，有效期 10 分钟')
            })
            .catch((e) => {
                const msg = RequestResponseErrorHandler(e)
                if (msg.businessStatus === 40013) {
                    toast.error('获取验证码过于频繁，请稍后再试。')
                } else {
                    toast.error('获取验证码失败')
                }
            })
    }, [checkPhone, phone])

    const _onOk = useCallback(() => {
        if (!checkPhone()) {
            return
        }
        setLoading(true)
        new AuthenticatePhone({ phone, otp: code })
            .start()
            .then(() => {
                toast.show('实名认证已完成')
                onOk?.()
            })
            .catch((e) => {
                const msg = RequestResponseErrorHandler(e)
                if (msg.businessStatus === 40007) {
                    setCodeError('验证码错误')
                } else {
                    toast.error('验证失败')
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }, [checkPhone, code, phone, onOk])

    useUpdateEffect(() => {
        let timer: NodeJS.Timeout
        if (countdown > 0) {
            timer = setTimeout(() => {
                setCountdown(countdown - 1)
            }, 1000)
        }
        return () => {
            clearTimeout(timer)
        }
    }, [countdown])

    return (
        <WKDialog
            {...otherProps}
            title="身份认证"
            onOk={_onOk}
            cancelButtonProps={{ dataTestId: 'cancel', ...cancelButtonProps }}
            okButtonProps={{
                dataTestId: 'ok',
                ...okButtonProps,
                disabled: !phone || !code,
                loading,
            }}
            testId="real-name-verification"
            bodyStyle={{ padding: '20px 24px 24px' }}
        >
            <div className="flex flex-col gap-row-16px">
                <div data-testid="content">请使用实名手机号进行认证。</div>
                <InputV2
                    dataTestIds={{ root: 'phone-input-root', input: 'phone-input' }}
                    placeholder="输入手机号"
                    value={phone}
                    onChange={onChangePhone}
                    error={{ show: !!phoneError, tipMessage: phoneError }}
                />
                <div className="flex items-center gap-8px">
                    <InputV2
                        dataTestIds={{ root: 'code-input-root', input: 'code-input' }}
                        placeholder="输入验证码"
                        value={code}
                        onChange={onChangeCode}
                        error={{ show: !!codeError, tipMessage: codeError }}
                        rootClassName="flex-1"
                    />
                    <WKButton
                        dataTestId="send-code"
                        type="secondary"
                        className="flex-none"
                        onClick={onSendCode}
                        disabled={countdown > 0}
                    >
                        {countdown > 0 ? `${countdown}s 后重新获取` : '发送验证码'}
                    </WKButton>
                </div>
            </div>
        </WKDialog>
    )
}

export const RealNameVerification = new ReactRootRender<Omit<RealNameVerificationStructProps, 'visible'>>(
    (props, state) => {
        const onCancel = () => {
            props?.onCancel?.()
            state.close()
        }
        const onOk = () => {
            props?.onOk?.()
            state.close()
        }
        return <RealNameVerificationStruct {...props} onCancel={onCancel} onOk={onOk} visible />
    }
)
