import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    LTBgtr: '离线数据库无法打开，请关闭所有该网站的浏览器窗口后重新打开当前页面',
} as const

export const enTranslation = {
    LTBgtr: 'Unable to open the offline database. Close all browser tabs for this site and reload the page',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
