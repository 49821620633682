import constate from 'constate'

interface Props {
    getScrollContainer: () => HTMLElement | null | undefined
}

const _useContainer = ({ getScrollContainer }: Props) => {
    return {
        getScrollContainer,
    }
}

export const [ScrollContainerProvider, useScrollContainerRef] = constate(_useContainer, (ctx) => ctx)
