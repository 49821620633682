import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    RecentlyUsed: '最近使用',
    AllEmojis: '全部表情',
} as const

export const enTranslation = {
    RecentlyUsed: 'Recently used',
    AllEmojis: 'All emojis',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
