/**
 * 对于本身或者祖先节点有data-copyable属性的target，不进行处理。因为需要走浏览器的默认行为
 * @param event
 * @returns
 */
export const isEventTargetCopyable = (event: Event) => {
    let current = event.target
    while (current) {
        if ((current as HTMLElement).getAttribute?.('data-copyable')) {
            return true
        }
        current = (current as HTMLElement).parentNode
    }
    return false
}
