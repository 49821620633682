export const BasicRdAll = [
    'f60e805d8ac53117369ef63077dbd96b097f6159',
    'bb106601a0fcca27f379ca7e6b4864378c73988b',
    '225eb66e04c4fbf5474dc54c786bcd347796f116',
    'a016fbbfa2e137ebffd52005dccbad8d7e6df177',
    'd7bd1df2675e57005a7596c0197a4bd31ecbc950',
    '57434b1c0afd109373efd66ab0cc5ed1569a4333',
    'c1176341f154e1b575d6013c43e7ac2a8889355b',
    '4d0c1e32c00069c6a39541d2dcf2a0d7f7322487',
    'e526a6e6c506c08c65fa39d76a5530e7e337623d',
    '2ce4665f28bf253877c3c2193651c43cab63066a',
    'ab2d23165c5824b0cccd163b090c5986cf788253',
    'fa41bf23ad54135e8310a19dcc012fbfb70d29f2',
    '572e8113bc837e3d897438f0c7bb1f4fd64b3abe',
    '67a1f8eef52da0343c0c4948e56c92f09cfd1c6f',
    '815c1bc60a12c15d19e320526962b4a6281d3342',
    'd8e03439cca68b90004a1be5bd696abef01955d6',
    '93b2d50b70abd736fe8799361dc94170a0598567',
    '296f6b16cb8026ef8a005f943c791536317bc20e',
    '9d6bf699ed20fc7bfe83d4b392dae8722b702339',
    '940909b82fdc5bbd708e5fe2c403e9ab9355c267',
    '20dff2aaca522ac44bf55db0acda5212222716a4',
    'ef0783d98dc23b86402b3eb9437dd9c86e101ba5',
    '83d894b6e504d771fef8bc2adc7dd6950aef31ae',
    '47615744535cfdba139a5e1f3726e14b717df803',
    'db9032c736bb9e54586a883471cfd0d3c203d8c1',
    'c25a7c619bd3d207ef342fb9328434439de70bd6',
    '7a97a942c20b4bf74c901e9f34772c5a9b699f44',
    'a276a0a764a2156e196a98d8ab0bd2efa7688881',
    '69c6218e34eb1eb2eeb50d612650bbd0027b943e',
    'b90d3727f903bc29e7785c6fa6def25a62f45b3b',
    '69362cdfa622a5d3e0312ca637fbfeb03e4fc0aa',
    '1ccf399dc7448f43145bf04127246e820a7b2e2b',
    '15ec0e98b04aa6e2234692ab566876347627f567',
    'e84422849b14819e3355db2154c7f40e3413f527',
    '98ed395c2d9ec53c2c394574ad92cf4461ba65f7',
    'b7ac244a9ce47ff2a082eb37ac7fbdf8a7c92092',
    'b02d0d9ae7de3736f97b6f1c5b162ac7dde63a1a',
    '284bfd4ffe2964c808afb1c38e602a392bf6adaf',
    'ca1760ba36c9d4b87ae7b35c0cbae47b4ff59a9a',
    '0943557b08daec7e73cc41811b0ae4cedd000b6a',
    '80b7759e6e7a313000efcb1d4297b0e4c7a53eff',
    '4ec1663a76757c645d04b63c612a84e2a6c4e38b',
    '75a281f9c6d924088235795efc836946d962852e',
    '020a7fe802ad65fa38f416b1c4775e0ab2f1ec51',
    '137d0ec285db6de87bbb9bcb1f7c12e83a34b179',
    '6f2a586ce90b01e986c4b79e89fc78fe796229e4',
    'ba359f8f7cabb59bea6a1c75fceeffdef1f40827',
    '07de847f05c0a1ab68862dd89660254ba39d540c',
    '22f9c521b91cec11b3c4c8e5681d4d2fda4b8f1e',
    'd0c8eba207a28fa653ddf5c63cd69bf98472440f',
    '187f4103b95a657d2e5cd7845bbbc84b4f577a62',
    'd7e45b450b5cd8fb9d806cfa0ace29746b996408',
    '14f4742c842da9c4df500abf7791e8edf6a7345d',
    '216a0a529993e408ddd1a7b86fb0865297b9f9fd',
    '6e0d717d148ad555a886889dd29521beb6c4f86c',
    'cff50cb5a26acb11aea4dac603e98ca5f6d6a213',
    'a33872d486c5180a931f00bfe0e7f5315f7db58c',
    'e8e21ac5205ad1e3bcea29ae5a75c9b2dba4b785',
    '354b7762289268d1d33a9d364bd6c1bfdd0534ca',
    'b5eaf31c971de693540363e52e40142898b9ec88',
    'c069c83b2798facaf1fe3c9a6c730f64d5fae6dd',
    'a2c0199b5d34e5b9fd73c148b260dbe0f2a671be',
    'fe1503ac811a31745d4d2edbfc2eabdfdea62141',
    '848154b842d852a02cd56aae93f06e95617a3f5b',
    '09e22046c1b30e2f7f2520b5eef9874ac2f4552f',
    'd4e6d65ccee16576632fc308e13961f9497dcb3b',
    'c2520912ef296753265cdcdcf7564f496c39c41a',
    'c30feaa18ee340c2e1ee53c1be7782a102880385',
    'eaad43d6d44d8c0cce306502d5e95e403d044e88',
    'dab35ffb6d93e8ecee258dca6c7f5d98bb7e4ea6',
    '4ce085bb6f6b614222957706acdb09f8b31e4d9a',
    '744f848c90cfc7f61fc3e354ec3be2b6d7c02215',
    'a7624e611527d04a2ae1f97d1d24c8e51b7f0f22',
    '431e38ea8171d09f044f723ac6d4e626b7317929',
    'de7719f2c695c2fcaf41c8e0da526430f2c40124',
    'c7d69af05df339ff708bdf00010afbdf75b67ef0',
    'f4329e38d29ca61c732ad7975142a6c3807ab492',
    '10db57bda86e184505f3826e897fbf96bde321e0',
    '18aab5995460387397ff45fbf8b20579f44facee',
    'be906adb70d13d846749b58c8d4a59edcbb7d9ae',
    '37b4208d9301d2607fcb9a638988a7b569fa92f5',
    '67001c05d873b3128d9f09cb7faa91c12c9d4aef',
    'b8f3469a07f51f655211c08ad148f09565e5b288',
    '070b8ebb3062d72c936483aad3c89f7067f11507',
    '5fbc10511ed3350efb551d03ac523a05f77dfb22',
    '0c56bdf75d4c7fa0d6b7812ec1705408edd34f6c',
    '7e0a41831dcc0d46b6bc3965590112752b6e62cd',
    'e843ec065b7a75338e9b6ecd8c6be1a78392b46e',
    '23a648c20ad6b90165e5d407a1d982c636b6126a',
    '0d8245131d181bff305b212b07265a08dbd3bff5',
    '3c88da4287e52ba91876b7795c3b02cbfc76ddaa',
    'a6b263bdd5df87096c7db4a192e2490c50203f2e',
    '1fab519bd1ecbb8e6c15d53a7759ee4e9394d879',
    '2dc4a7dcfb76e9fd291a7a626e4f5d8ad267b68b',
    '83937c36d2594d47587e42455e99fd1b04ddf73e',
    '0653e0ecc4df69a1bb2c1dece50c5cb9bca46656',
    '08ac0f16bed1e112f441fbbe775ff11a1957821a',
]
