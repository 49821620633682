import { useCallback, useState } from 'react'
import { wkDialogConfirm } from '../../../../../../ui-lib/src'
import { domLocation, timeDesc5 } from '../../../../../../util/src'
import { environment } from '../../../../environment'
import { SettlementVO, ResourceType, RoleStatus } from '../../../../kernel/interface/type'
import { GetAllPayExpiredSettlements } from '../../../../kernel/request/contract'
import { GetTeamRequestV2 } from '../../../../kernel/request/team'
import { WKFrogTask } from '../../../wk-frog-task'
import {
    OrganizationSubmitEditorProps,
    OrganizationSubmitSpaceProps,
    PayUpgradeDialogFrom,
    ProfessionalSubmitEditorProps,
    ProfessionalSubmitSpaceProps,
    type StarterSubmitSpaceProps,
} from './type'
import { translation } from './use-pay-upgrade-submit.translation'

export function usePayUpgradeSubmit() {
    const [hidePayUpgradeDialogContainer, setHidePayUpgradeDialogContainer] = useState<boolean>(false)
    const [isLoadingProfessional, setIsLoadingProfessional] = useState<boolean>(false)
    const [isLoadingOrganization, setIsLoadingOrganization] = useState<boolean>(false)
    const submitNoPermission = useCallback((close?: () => void) => {
        setHidePayUpgradeDialogContainer(true)
        wkDialogConfirm.info({
            title: translation('ca'),
            content: translation('uu'),
            okText: translation('ok'),
            closeWhenHrefChanged: true,
            hideCancelButton: true,
            wrapperTestId: 'pay-upgrade-no-permission-dialog-container',
            onOk: () => (setHidePayUpgradeDialogContainer(false), close?.()),
            onClose: () => setHidePayUpgradeDialogContainer(false),
        })
    }, [])

    const submitPlanFreezeAlert = useCallback((settlement: SettlementVO, close?: () => void) => {
        setHidePayUpgradeDialogContainer(true)
        wkDialogConfirm.show({
            title: translation('yh'),
            content: translation('ct', { time: timeDesc5(settlement.billingCycleDay) }),
            okText: translation('py'),
            closeWhenHrefChanged: true,
            hideCancelButton: true,
            wrapperTestId: 'pay-plan-freeze-alert-dialog-container',
            onOk: () => {
                setHidePayUpgradeDialogContainer(false)
                close?.()
                window.open(settlement.invoiceUrl, '_blank')
            },
            onClose: () => setHidePayUpgradeDialogContainer(false),
        })
    }, [])

    const checkTeamAndSubmit = useCallback(
        (teamId: string, submit: () => void, close?: () => void) => {
            return new GetTeamRequestV2(teamId)
                .start()
                .then(async (res) => {
                    if (
                        res.role === RoleStatus.Admin ||
                        res.role === RoleStatus.Owner ||
                        res.role === RoleStatus.Editor
                    ) {
                        const allPayExpiredSettlements = environment.isAbroad
                            ? await new GetAllPayExpiredSettlements(ResourceType.Team, [teamId]).start()
                            : undefined
                        const teamPayExpiredSettlements = allPayExpiredSettlements?.[teamId]
                        const payExpiredSettlement = teamPayExpiredSettlements?.[teamPayExpiredSettlements.length - 1]
                        if (payExpiredSettlement) {
                            submitPlanFreezeAlert(payExpiredSettlement, close)
                        } else {
                            submit()
                        }
                    } else {
                        submitNoPermission(close)
                    }
                })
                .catch()
        },
        [submitNoPermission, submitPlanFreezeAlert]
    )

    const submitStarter = useCallback(
        (params: StarterSubmitSpaceProps) => {
            if (isLoadingProfessional || isLoadingOrganization) {
                return
            }
            if (params.from === PayUpgradeDialogFrom.Space) {
                params.submit()
            }
        },
        [isLoadingOrganization, isLoadingProfessional]
    )

    const submitProfessional = useCallback(
        (params: ProfessionalSubmitEditorProps | ProfessionalSubmitSpaceProps, close?: () => void) => {
            if (isLoadingProfessional) {
                return
            }
            WKFrogTask.payment.ClickPopupUpgrade()
            const submit = () => {
                close?.()
                if (params.from === PayUpgradeDialogFrom.Editor) {
                    window.open(
                        `${domLocation().origin}/files/drafts?plan=GROUP${
                            params.teamId ? `&planTeamId=${params.teamId}` : ''
                        }`,
                        '_blank'
                    )
                } else if (params.from === PayUpgradeDialogFrom.Space) {
                    params.submit()
                }
            }
            if (params.teamId) {
                setIsLoadingProfessional(true)
                checkTeamAndSubmit(params.teamId, submit, close).finally(() => setIsLoadingProfessional(false))
            } else {
                submit()
            }
        },
        [checkTeamAndSubmit, isLoadingProfessional]
    )

    const submitOrganization = useCallback(
        (params: OrganizationSubmitEditorProps | OrganizationSubmitSpaceProps, close?: () => void) => {
            if (isLoadingOrganization) {
                return
            }
            WKFrogTask.payment.ClickPopupUpgrade()
            const submit = () => {
                close?.()
                if (params.from === PayUpgradeDialogFrom.Editor) {
                    window.open(`${domLocation().origin}/files/drafts?plan=ORG`, '_blank')
                } else if (params.from === PayUpgradeDialogFrom.Space) {
                    params.submit()
                }
            }
            if (params.teamId) {
                setIsLoadingOrganization(true)
                checkTeamAndSubmit(params.teamId, submit, close).finally(() => setIsLoadingOrganization(false))
            } else {
                submit()
            }
        },
        [checkTeamAndSubmit, isLoadingOrganization]
    )

    return { hidePayUpgradeDialogContainer, submitProfessional, submitOrganization, submitStarter }
}
