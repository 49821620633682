/* eslint-disable no-restricted-imports */
import { CreateCanvasToRender, FinishRendering, RenderToCanvas, Wukong } from '@wukong/bridge-proto'
import { ClassWithEffect, EffectController } from '../../../../util/src/effect-controller'
import { replaceImageICCToDisplayP3 } from '../../image-lib'
import { getImageLibContext, toImageFormat } from '../../image-lib/adapter'
import { EmBridge } from '../../kernel/bridge/em-bridge'
import { WkCLog } from '../../kernel/clog/wukong/instance'
import { SkipSentryError } from '../../kernel/sentry'

import ImageFormat = Wukong.DocumentProto.ImageFormat

interface CanvasRenderData {
    canvas: HTMLCanvasElement
    context: CanvasRenderingContext2D
    colorProfile: Wukong.DocumentProto.DocumentColorProfile
}

interface ImageBlobInfo {
    file: Blob
    width: number
    height: number
}

interface ImageMeta {
    width: number
    height: number
}

function createCanvasRenderData(
    width: number,
    height: number,
    colorProfile: Wukong.DocumentProto.DocumentColorProfile
): CanvasRenderData | null {
    try {
        const canvas = document.createElement('canvas')
        if (canvas == null) {
            return null
        }
        canvas.width = width
        canvas.height = height

        const context = canvas.getContext('2d', {
            alpha: true,
            willReadFrequently: true,
        })
        if (context == null) {
            return null
        }
        return { canvas, context, colorProfile }
    } catch {
        return null
    }
}

function getCanvasToBlobType(imageFormat: ImageFormat) {
    switch (imageFormat) {
        case ImageFormat.IMAGE_FORMAT_PNG:
            return 'image/png'
        case ImageFormat.IMAGE_FORMAT_JPG:
            return 'image/jpeg'
        case ImageFormat.IMAGE_FORMAT_WEBP:
            return 'image/webp'
        default:
            return ''
    }
}

class SkipSentryAndWKClogError extends SkipSentryError {
    constructor(message: string) {
        super(message)
        WkCLog.log(message)
    }
}

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/canvas#maximum_canvas_size
export class CanvasBoundsExceedError extends SkipSentryAndWKClogError {}
export class CanvasPutImageDataError extends SkipSentryAndWKClogError {}

export class CanvasRenderBridge extends ClassWithEffect {
    private nextId = 1
    private imageMetaMap: { [id: number]: { width: number; height: number } } = {}
    private dataMap: { [id: number]: CanvasRenderData } = {}
    private promiseMap: { [id: number]: Promise<ImageBlobInfo> } = {}

    constructor(private bridge: EmBridge, controller: EffectController) {
        super(controller)
        bridge.bind(CreateCanvasToRender, this.onCreateCanvasToRender)
        bridge.bind(RenderToCanvas, this.onRenderToCanvas)
        bridge.bind(FinishRendering, this.onFinishRendering)
    }

    public destroy() {
        this.bridge.unbind(CreateCanvasToRender)
        this.bridge.unbind(RenderToCanvas)
        this.bridge.unbind(FinishRendering)
    }

    public fetchImageBlob(resourceId: number): Promise<Blob> | null {
        const result = this.promiseMap[resourceId]
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete this.promiseMap[resourceId]
        return result.then((r) => r.file)
    }

    public fetchImageInfo(resourceId: number): Promise<ImageBlobInfo> | null {
        const result = this.promiseMap[resourceId]
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete this.promiseMap[resourceId]
        return result
    }

    public fetchImageMeta(resourceId: number): ImageMeta | null {
        const result = this.imageMetaMap[resourceId]
        return result
    }

    private onCreateCanvasToRender = (arg: Wukong.DocumentProto.IArg_createCanvasToRender) => {
        if (arg.width == null || arg.width <= 0 || arg.height == null || arg.height <= 0) {
            return Wukong.DocumentProto.Ret_createCanvasToRender.create({ id: 0 })
        }

        const data = createCanvasRenderData(
            arg.width,
            arg.height,
            // 这里直接传什么都没用
            Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B
        )
        if (data == null) {
            return Wukong.DocumentProto.Ret_createCanvasToRender.create({ id: 0 })
        }

        const id = this.nextId
        this.nextId += 1
        this.dataMap[id] = data
        this.imageMetaMap[id] = {
            width: arg.width!,
            height: arg.height!,
        }

        return Wukong.DocumentProto.Ret_createCanvasToRender.create({ id })
    }

    private onRenderToCanvas = (arg: Wukong.DocumentProto.IArg_renderToCanvas) => {
        if (
            arg.id == null ||
            arg.x == null ||
            arg.y == null ||
            arg.width == null ||
            arg.height == null ||
            arg.ptr == null ||
            arg.length == null
        ) {
            return
        }
        const data = this.dataMap[arg.id]
        if (data == null) {
            return
        }

        const clampedArray = new Uint8ClampedArray(
            this.bridge.currentEditorService.HEAPU8.buffer,
            arg.ptr >>> 0,
            arg.length
        )
        try {
            const imageData = new ImageData(clampedArray, arg.width, arg.height)
            data.context.putImageData(imageData, arg.x, arg.y)
        } catch (e) {
            throw new CanvasPutImageDataError(
                `HTMLCanvasElement.putImageData failed: imageDataSize-${arg.length} imageDimension-(${arg.width}, ${arg.height}) canvasDimension-(${data.canvas.width}, ${data.canvas.height}) putImageOffset-(${arg.x}, ${arg.y})`
            )
        }

        data.colorProfile = arg.colorProfile ?? Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B
    }

    private onFinishRendering = (arg: Wukong.DocumentProto.IArg_finishRendering) => {
        if (arg.id == null) {
            return Wukong.DocumentProto.Ret_finishRendering.create({ resourceId: 0 })
        }
        const data = this.dataMap[arg.id]
        if (data == null) {
            return Wukong.DocumentProto.Ret_finishRendering.create({ resourceId: 0 })
        }

        this.promiseMap[arg.id] = (async () => {
            const file = await new Promise<Blob | null>((res) => {
                if (arg.isCompress) {
                    data.canvas.toBlob(res, getCanvasToBlobType(arg.imageFormat ?? ImageFormat.IMAGE_FORMAT_PNG), 0.1)
                } else {
                    data.canvas.toBlob(res, getCanvasToBlobType(arg.imageFormat ?? ImageFormat.IMAGE_FORMAT_PNG), 0.92)
                }
            })
            if (!file) {
                throw new CanvasBoundsExceedError(
                    `HTMLCanvasElement.toBlob failed: isCompress-${arg.isCompress} imageFormat-${arg.imageFormat} canvasDimension-(${data.canvas.width}, ${data.canvas.height})`
                )
            }

            if (data.colorProfile === Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILE_DISPLAY_P3) {
                const next = await file
                    .arrayBuffer()
                    .then((buf) =>
                        replaceImageICCToDisplayP3(
                            getImageLibContext(),
                            new Uint8Array(buf),
                            toImageFormat(arg.imageFormat ?? ImageFormat.IMAGE_FORMAT_PNG)
                        )
                    )
                return { file: new Blob([next]), width: data.canvas.width, height: data.canvas.height }
            } else {
                return { file, width: data.canvas.width, height: data.canvas.height }
            }
        })()

        return Wukong.DocumentProto.Ret_finishRendering.create({ resourceId: arg.id })
    }
}
