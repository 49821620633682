import { fontSelector } from './font-selector'
import { payment } from './payment'
import { textStyle } from './text-style'
import { upgradePayment } from './upgrade-payment'

export { UpgradePopupType } from './payment'

export const WKFrogTask = {
    fontSelector,
    textStyle,
    upgradePayment,
    payment,
}

export { WKFrogTaskStateService } from './wk-frog-task-state-service'
