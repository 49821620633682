import { translation } from './doc-name-free-tag.translation'
/* eslint-disable no-restricted-imports */
import { useCallback, useMemo, useRef, useState } from 'react'
import {
    DropdownNoTriggerEmptyRef,
    DropdownV2,
    MonoIconCommonCheckboxChecked16,
    Tag,
    TagRef,
    WKButton,
} from '../../../../../../ui-lib/src'
import { PayPlanType } from '../../../../kernel/interface/type'
import { PayPreCheck } from '../../../../share/payment/pay-pre-check'
import { PayUpgradeDialog } from '../../../../share/payment/pay-upgrade-dialog/pay-upgrade-dialog'
import {
    PayUpgradeDialogFrom,
    PayUpgradeDialogType,
} from '../../../../share/payment/pay-upgrade-dialog/pay-upgrade-dialog-struct/type'
import { UpgradePopupType, WKFrogTask } from '../../../../share/wk-frog-task'
import { useDocInfoContext } from '../../../context/top-area-context'
import classes from './doc-name-free-tag.module.less'
import { RectangleSchedule } from './rectangle-schedule'

export function DocNameFreeTag() {
    const [currentStep, setCurrentStep] = useState(0)
    const ref = useRef<DropdownNoTriggerEmptyRef>(null)
    const tagRef = useRef<TagRef>(null)
    const { folderData } = useDocInfoContext()

    const rectangleScheduleProps = useMemo(() => {
        const sumStep = 3
        return {
            sumStep,
            keyStep: 3,
            currentStep: Math.min(currentStep, sumStep),
        }
    }, [currentStep])

    const privileges = useMemo(
        () => [{ name: translation('up') }, { name: translation('sl') }, { name: translation('uv') }],
        []
    )

    const getTriggerRect = useCallback(() => {
        const rect = tagRef.current?.getRootElement()?.getBoundingClientRect()
        if (rect) {
            return { top: 0, left: rect.left, right: rect.right, bottom: 48 }
        }
        return { top: 0, left: 0, right: 0, bottom: 48 }
    }, [])

    const onClickTag = useCallback(async () => {
        if (folderData?.teamId) {
            const res = await PayPreCheck.getTeamResourceState(folderData.teamId)
            if (res) {
                setCurrentStep(res.docCount)
            }
        }
        ref.current?.open()
    }, [folderData?.teamId])

    const onClickButton = useCallback(() => {
        ref.current?.close()
        WKFrogTask.payment.UpgradePopupType(UpgradePopupType.ClickTopTag)
        PayUpgradeDialog.show({
            type: PayUpgradeDialogType.All,
            currentPlanType: PayPlanType.starter,
            organizationProps: {
                submitProps: {
                    from: PayUpgradeDialogFrom.Editor,
                    teamId: folderData?.teamId,
                },
            },
            professionalProps: {
                submitProps: {
                    from: PayUpgradeDialogFrom.Editor,
                    teamId: folderData?.teamId,
                },
            },
        })
    }, [folderData?.teamId])

    return (
        <>
            <Tag
                ref={tagRef}
                name={translation('f')}
                color="blue"
                structure="surface"
                onClick={onClickTag}
                data-testid="doc-name-free-tag"
                hoverable
                className={classes.freeTag}
            />
            <DropdownV2.NoTriggerEmpty
                triggerRect={getTriggerRect}
                ref={ref}
                dataTestIds={{ container: 'doc-name-free-popup' }}
            >
                <div className={classes.content} data-disabled-drag-move="true">
                    <RectangleSchedule {...rectangleScheduleProps} />
                    <div className={classes.title} data-testid="doc-name-free-popup-title">
                        {translation('sf', {
                            currentStep: String(rectangleScheduleProps.currentStep),
                            sumStep: String(rectangleScheduleProps.sumStep),
                        })}
                    </div>
                    <div className={classes.subtitle} data-testid="doc-name-free-popup-subtitle">
                        {translation('sp', {
                            sumStep: String(rectangleScheduleProps.sumStep),
                        })}
                    </div>
                    <div className={classes.privileges}>
                        {privileges.map((v, index) => (
                            <div key={index} className={classes.privilege} data-testid={`prefix-privilege-${index}`}>
                                <span className={classes.icon}>
                                    <MonoIconCommonCheckboxChecked16 />
                                </span>
                                <span>{v.name}</span>
                            </div>
                        ))}
                    </div>
                    <WKButton type="primary" onClick={onClickButton} className={classes.button}>
                        {translation('cp')}
                    </WKButton>
                </div>
            </DropdownV2.NoTriggerEmpty>
        </>
    )
}
