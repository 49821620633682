import constate from 'constate'
import { RadioGroupStateProps, useRadioGroupState } from '../common/use-radio-group-state'

export interface WKRadioCardGroupContextProps extends RadioGroupStateProps {}

const useRadioCardGroup = (props: WKRadioCardGroupContextProps) => {
    const radioGroupState = useRadioGroupState(props)

    return {
        size: 16,
        ...radioGroupState,
    } as const
}

export const [RadioCardGroupProvider, useRadioCardGroupContext] = constate(useRadioCardGroup)
