/**
 * @owner: chenxiangbj@kanyun.com
 */
import { translation } from './index.translation'
import { charCodeLengthValidatorV2 } from '../../../../util/src'

// eslint-disable-next-line import/no-restricted-paths
import { contentAuditError } from '../../../../app/src/kernel/request/error-handler'
import { WKDialog } from '../wk-dialog'
import { WKForm } from '../wk-form'
import { FormCompNormalInput } from '../wk-form/form-component'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { asyncModal } from '../wk-dialog/async-modal'
import { FC } from 'react'

interface OneInputModalProps {
    title?: string
    visible: boolean
    onCancel: () => void
    onSubmit: (value: string) => Promise<void>
    initialValue?: string
    valueLabel: string
    okText?: string
    maxCharCodeLength: number
    lowerCaseName: string // 'project' 'team'
}

const OneInputModal = (props: OneInputModalProps) => {
    interface FormValues {
        value: string
    }

    const validate = z
        .object({
            value: z.string().min(1, { message: `${translation('ATeamMust', { projectName: props.lowerCaseName })}` }),
        })
        .superRefine((data, ctx) => {
            const msg = charCodeLengthValidatorV2(data.value, props.maxCharCodeLength, props.valueLabel)
            if (msg) {
                ctx.addIssue({
                    path: ['value'],
                    code: z.ZodIssueCode.custom,
                    message: msg,
                })
            }
        })

    const {
        control,
        handleSubmit,
        setError,
        formState: { isSubmitting },
    } = useForm<FormValues>({
        resolver: zodResolver(validate),
        defaultValues: {
            value: props.initialValue,
        },
    })

    const onSubmit = handleSubmit(async (data) => {
        try {
            await props.onSubmit(data.value)
        } catch (e: any) {
            const msg = e?.req ? contentAuditError(e) : e?.message
            setError('value', { message: msg })
        }
    })

    return (
        <WKDialog
            onCancel={props.onCancel}
            title={props.title}
            visible={props.visible}
            hideFooterLine={true}
            okText={props.okText}
            cancelIconProps={{ 'data-testid': 'close-icon' } as any}
            okButtonProps={{
                htmlType: 'submit',
                loading: isSubmitting,
                formId: 'one-input-modal-form',
            }}
        >
            <WKForm id="one-input-modal-form" onSubmit={onSubmit}>
                <FormCompNormalInput
                    name={'value'}
                    control={control}
                    inputProps={{
                        dataTestIds: {
                            input: `${props.valueLabel}-input`,
                        },
                        placeholder: `${translation('EnterTeamName')}${props.valueLabel}`,
                        autoFocus: true,
                    }}
                />
            </WKForm>
        </WKDialog>
    )
}

export { OneInputModal }

const RenameModalBody: FC<{
    lowerCaseName: string
    maxCharCodeLength: number
    valueLabel: string
    initialValue: string
    resolve: (value: boolean) => void
    destroy: () => void
    onSubmit: (value: string) => Promise<void>
}> = (props) => {
    interface FormValues {
        value: string
    }

    const validate = z
        .object({
            value: z.string().min(1, { message: `${translation('ATeamMust', { projectName: props.lowerCaseName })}` }),
        })
        .superRefine((data, ctx) => {
            const msg = charCodeLengthValidatorV2(data.value, props.maxCharCodeLength, props.valueLabel)
            if (msg) {
                ctx.addIssue({
                    path: ['value'],
                    code: z.ZodIssueCode.custom,
                    message: msg,
                })
            }
        })

    const { control, handleSubmit, setError } = useForm<FormValues>({
        resolver: zodResolver(validate),
        defaultValues: {
            value: props.initialValue,
        },
    })

    const onSubmit = handleSubmit(async (data) => {
        try {
            await props.onSubmit(data.value)
            props.resolve(true)
            props.destroy()
        } catch (e: any) {
            const msg = e?.req ? contentAuditError(e) : e?.message
            setError('value', { message: msg })
        }
    })
    return (
        <WKForm id="rename-modal-form" onSubmit={onSubmit}>
            <FormCompNormalInput
                name={'value'}
                control={control}
                inputProps={{
                    dataTestIds: {
                        input: `${props.valueLabel}-input`,
                    },
                    placeholder: `${translation('EnterTeamName')}${props.valueLabel}`,
                    autoFocus: true,
                }}
            />
        </WKForm>
    )
}

export const renameModal = ({
    onSubmit,
    lowerCaseName,
    maxCharCodeLength,
    valueLabel,
    initialValue,
    title,
}: {
    onSubmit: (value: string) => Promise<void>
    lowerCaseName: string
    maxCharCodeLength: number
    valueLabel: string
    initialValue: string
    title: string
}) =>
    asyncModal<boolean>({
        title,
        disableAutoDestroy: true,
        childrenFn(resolve, update, destroy) {
            return (
                <RenameModalBody
                    destroy={destroy}
                    resolve={resolve}
                    onSubmit={onSubmit}
                    lowerCaseName={lowerCaseName}
                    maxCharCodeLength={maxCharCodeLength}
                    valueLabel={valueLabel}
                    initialValue={initialValue}
                />
            )
        },
        onCancel(resolve, destroy) {
            resolve(false)
            destroy()
        },
        okButtonProps: {
            formId: 'rename-modal-form',
            htmlType: 'submit',
        },
    })
