import { ScrubInfo } from './type'
import { UserConfig } from './user-config'

export function valueFilter(value: number, resolution?: number) {
    return resolution ? (resolution === 1 ? Math.round(value) : Math.round(value / resolution) * resolution) : value
}
export function valueFilter10(scrubInfo: ScrubInfo, value: number) {
    return valueFilter(value, scrubInfo.shiftKey ? 10 : undefined)
}

export function valueFilter15(scrubInfo: ScrubInfo, value: number) {
    return valueFilter(value, scrubInfo.shiftKey ? 15 : undefined)
}

export function valueFilterUserConfig(scrubInfo: ScrubInfo, value: number, userConfig: UserConfig) {
    const nextValue = valueFilter(
        value,
        scrubInfo.shiftKey ? userConfig.bigNudgeAmount : userConfig.smallNudgeAmount / 2
    )
    return scrubInfo.shiftKey ? nextValue : Math.round(nextValue)
}
