import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    EditObject: '编辑',
    CreateComponent: '创建组件',
    CropImage: '裁切图片',
    ResetAllChanges: '重置所有修改',
    Component: '组件',
    CreateMultipleComponents: '创建多个组件',
    CreateComponentSet: '创建变体组件',
    UseAsMask: '设为蒙版',
    RemoveMask: '取消蒙版',
    Layout: '布局',
    Quit: '退出方式',
    Quit_synonyms1: '退出',
    QuitAndSave: '退出并保存',
    AddVariant: '添加变体',
} as const

export const enTranslation = {
    EditObject: 'Edit object',
    CreateComponent: 'Create component',
    CropImage: 'Crop image',
    ResetAllChanges: 'Reset all changes',
    Component: 'Components',
    CreateMultipleComponents: 'Create multiple components',
    CreateComponentSet: 'Create component set',
    UseAsMask: 'Use as mask',
    RemoveMask: 'Remove mask',
    Layout: 'layout',
    Quit: 'Quit',
    Quit_synonyms1: 'Quit',
    QuitAndSave: 'Quit and save',
    AddVariant: 'Add Variant',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
