import { IPluginAPIContext, NodePropDescriptor } from '../plugin-api-context-interface'
import { Handle } from '../vm-interface'

export abstract class BaseNodePrototype {
    nodeCache = new Map<string, Handle>()

    constructor(protected ctx: IPluginAPIContext) {}

    createNamedPrototype(name: string) {
        const evalResult = this.ctx.vm.evalCode(`(function ${name}() {}).prototype`)
        if (evalResult.type !== 'SUCCESS') {
            throw new Error('evalCode failed')
        }
        return evalResult.handle
    }

    createNodePrototype(name: string, type: string, descriptors: NodePropDescriptor[]) {
        const proto = this.createNamedPrototype(name)
        this.ctx.vm.retainHandle(proto)
        this.ctx.vm.defineProp(proto, 'type', {
            enumerable: true,
            writable: false,
            value: this.ctx.vm.newString(type),
        })
        for (const prop of descriptors) {
            if (prop.func) {
                this.ctx.defineVmFunction({
                    objhandle: proto,
                    key: prop.key!,
                    func: prop.func,
                })
            } else {
                this.ctx.defineVmProp({
                    objhandle: proto,
                    key: prop.key!,
                    get: prop.get,
                    set: prop.set,
                    enumerable: true,
                    canUseInReadonly: prop.canUseInReadonly,
                })
            }
        }

        return proto
    }

    abstract createNodePrototypes(): void

    abstract createNodeHandle(id: string): Handle
}
