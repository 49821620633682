import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import {
    CloseMultipleRenameModalCommand,
    MultipleRenameCommand,
    SetMultipleRenameParamsWasmCall,
    Wukong,
} from '@wukong/bridge-proto'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { InputV2, InputV2Ref, WKButton, WKDialog } from '../../../../../ui-lib/src'
import { ToKeyCode } from '../../../document/util/keycode'
import { useCommand } from '../../../main/app-context'
import { useViewState } from '../../../view-state-bridge'
import style from './index.module.less'

export const MultipleRenameModal: FC = () => {
    const command = useCommand()

    const inputV2Ref = useRef<InputV2Ref>(null)

    const closeModal = useCallback(() => {
        command.DEPRECATED_invokeBridge(CloseMultipleRenameModalCommand)
    }, [command])

    const { preview, enableRename } = useViewState('multipleRename', { preview: '', enableRename: false })

    const singleSelection = useViewState('singleSelection')

    const tilte = useMemo(() => {
        if (!singleSelection) {
            return ''
        }
        return `${translation('Rename')} ${singleSelection.length} ${translation('Layers')}`
    }, [singleSelection])

    const previewList = useMemo(() => {
        return preview.split('\n')
    }, [preview])

    const [search, setSearch] = useState<undefined | string>(undefined)
    const [rename, setRename] = useState<undefined | string>(undefined)
    const [startNum, setStartNum] = useState(1)

    const disabledStartNum = useMemo(() => {
        if (!rename) {
            return true
        }
        return !/\$[n|N]/.test(rename)
    }, [rename])

    const addReg2Rename = (reg: string) => {
        const input = inputV2Ref.current?.getInputElement()
        if (!input) {
            return
        }
        const selectionStart = input.selectionStart
        const selectionEnd = input.selectionEnd
        setRename((name) => {
            const newRename = name ?? ''
            // 插入
            if (selectionStart === selectionEnd) {
                return newRename.substring(0, selectionStart!) + reg + newRename.substring(selectionStart!)
            }
            // 替换
            return newRename.substring(0, selectionStart!) + reg + newRename.substring(selectionEnd!)
        })
        // focus 到添加位置
        input.focus()
        setTimeout(() => {
            if (input) {
                input.setSelectionRange(selectionStart! + reg.length, selectionStart! + reg.length)
            }
        }, 10)
    }

    useEffect(() => {
        command.DEPRECATED_invokeBridge(
            SetMultipleRenameParamsWasmCall,
            Wukong.DocumentProto.MultipleRenameParams.create({
                rename,
                search,
                startNum,
            })
        )
    }, [command, rename, search, startNum])

    const handleRename = () => {
        if (enableRename) {
            command.DEPRECATED_invokeBridge(MultipleRenameCommand)
            command.commitUndo()
        }
    }

    const startHint = useMemo(() => {
        if (rename && /\$N/.test(rename) && !/\$n/.test(rename)) {
            return translation('StartAscendingFrom')
        }
        return translation('StartSequenceAt')
    }, [rename])

    const Footer = () => {
        return (
            <>
                <WKButton type="secondary" onClick={closeModal}>
                    {translation('Cancel')}
                </WKButton>
                <WKButton type="primary" onClick={handleRename} disabled={!enableRename} data-testid="rename-btn">
                    {translation('Rename_synonyms1')}
                </WKButton>
            </>
        )
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === ToKeyCode.Enter) {
            handleRename()
        }
    }

    return (
        <WKDialog
            title={tilte}
            visible={true}
            cancelText={translation('Cancel')}
            okText={translation('Rename_synonyms1')}
            width={536}
            onCancel={closeModal}
            footer={<Footer />}
            bodyStyle={{ paddingTop: 16, position: 'relative' }}
            hideFooterLine
        >
            <div className={style.container} data-testid="multiple-rename-modal">
                <div className={style.preview} data-testid="preview">
                    <div className={style.previewTitle}>{translation('Preview')}</div>
                    {previewList.map((text, index) => (
                        <div className={style.previewWord} key={index}>
                            <SplitText text={text} />
                        </div>
                    ))}
                </div>
                <div className={style.inputParams}>
                    <div className={style.inputContainer}>
                        <InputV2
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder={`${translation('Match')}`}
                            data-testid="search-input"
                        />
                    </div>

                    <div className={style.inputContainer}>
                        <InputV2
                            ref={inputV2Ref}
                            value={rename}
                            onChange={(e) => setRename(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder={translation('Rename_synonyms2')}
                            autoFocus
                            data-testid="rename-input"
                        />
                    </div>

                    <div className={style.inputContainerSmall}>
                        <WKButton
                            type="secondary"
                            data-testid="name-btn"
                            size="small"
                            className={style.margin8}
                            onClick={() => {
                                addReg2Rename('$&')
                            }}
                        >
                            {translation('CurrentName')}
                        </WKButton>
                        <WKButton
                            type="secondary"
                            size="small"
                            data-testid="ascending-order-btn"
                            className={style.margin8}
                            onClick={() => {
                                addReg2Rename('$nn')
                            }}
                        >
                            {translation('Number')}↑
                        </WKButton>
                        <WKButton
                            data-testid="descending-order-btn"
                            type="secondary"
                            size="small"
                            onClick={() => {
                                addReg2Rename('$NN')
                            }}
                        >
                            {translation('Number')}↓
                        </WKButton>
                    </div>

                    <div className={style.inputContainerSmall}>
                        <span style={{ color: 'var(--wk-gray-11)' }}>{startHint}</span>
                        <InputV2
                            size="small"
                            rootClassName={style.inputStartNum}
                            style={{ display: 'inline-block' }}
                            disabled={disabledStartNum}
                            value={startNum}
                            onKeyDown={handleKeyDown}
                            onBlur={(e) => {
                                const val = e.target.value
                                if (val === '') {
                                    return
                                }
                                let number = Number(val)
                                if (!isNaN(number)) {
                                    if (number < 0) {
                                        number = 0
                                    }
                                    if (!Number.isInteger(number)) {
                                        number = Math.round(number)
                                    }
                                    setStartNum(number)
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </WKDialog>
    )
}

const SplitText: FC<{ text: string }> = ({ text }) => {
    const ref = useRef<HTMLSpanElement>(null)

    const [shouldSplitText, setShouldSplitText] = useState(false)

    useEffect(() => {
        if (ref.current) {
            setShouldSplitText(ref.current.getBoundingClientRect().width > 136)
        }
    }, [text])

    return (
        <>
            <span ref={ref} className={style.computedTextWidth}>
                {text}
            </span>
            <>
                <span className={style.left}>{text}</span>
                {shouldSplitText && (
                    <span className={style.right}>
                        &nbsp;
                        <span className={style.rightPosition}>{text}</span>
                    </span>
                )}
            </>
        </>
    )
}
