/* eslint-disable no-restricted-imports */
import {
    AutoLayoutMoveChildren,
    CompoundAdjustLayoutWasmCall,
    DeleteStyleNodeCommand,
    ShowStartEditingHandlerForPaintStyleCommand,
    TextInputCommand,
    UpdateAttrPanelEditorStateCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { AttrPanelStyleEditorState, NodeId, PopupState, SceneNode } from '../node/node'
import { isInstanceChild, isNodeInAutoLayout, isSceneNode } from '../node/node-assertion'
import { calculateXY } from '../util/layout'
import { BridgeCommand } from './command-invoker'

// 合并 updateNodex/updatenodeY ?
const cmdUpdateNodeX: BridgeCommand<[nodeId: NodeId, x: number]> = ({ documentRoot, commandInvoker }, nodeId, x) => {
    const node = documentRoot.getNodeById(nodeId)

    if (!isSceneNode(node) || isInstanceChild(node)) {
        return
    }

    commandInvoker.DEPRECATED_invokeBridge(CompoundAdjustLayoutWasmCall, {
        nodeId,
        positionX: x,
    })
}

const cmdUpdateNodeY: BridgeCommand<[nodeId: NodeId, y: number]> = ({ documentRoot, commandInvoker }, nodeId, y) => {
    const node = documentRoot.getNodeById(nodeId)

    if (!isSceneNode(node) || isInstanceChild(node)) {
        return
    }

    commandInvoker.DEPRECATED_invokeBridge(CompoundAdjustLayoutWasmCall, {
        nodeId,
        positionY: y,
    })
}

export const cmdMoveSelectionUp: BridgeCommand<[shiftKey: boolean]> = ({ documentRoot, commandInvoker }, shiftKey) => {
    const selection = documentRoot.currentPage()?.selection
    if (!selection?.length) {
        return
    }

    const nodes = selection.map((id) => documentRoot.getNodeById(id) as SceneNode)

    const hasNonAutoLayout = nodes.some((v) => !isNodeInAutoLayout(v))
    if (!hasNonAutoLayout) {
        commandInvoker.DEPRECATED_invokeBridge(AutoLayoutMoveChildren, {
            type: Wukong.DocumentProto.AutoLayoutMoveChildrenType.AUTO_LAYOUT_MOVE_CHILDREN_TYPE_UP,
            altKey: false,
        })
        return
    }

    nodes.forEach((node) => {
        if (isNodeInAutoLayout(node)) {
            return
        }
        const { y } = calculateXY(node)
        commandInvoker.invoke(cmdUpdateNodeY, node.id, y - (shiftKey ? 10 : 1))
    })
}

export const cmdMoveSelectionDown: BridgeCommand<[shiftKey: boolean]> = (
    { documentRoot, commandInvoker },
    shiftKey
) => {
    const selection = documentRoot.currentPage()?.selection
    if (!selection?.length) {
        return
    }

    const nodes = selection.map((id) => documentRoot.getNodeById(id) as SceneNode)

    const hasNonAutoLayout = nodes.some((v) => !isNodeInAutoLayout(v))
    if (!hasNonAutoLayout) {
        commandInvoker.DEPRECATED_invokeBridge(AutoLayoutMoveChildren, {
            type: Wukong.DocumentProto.AutoLayoutMoveChildrenType.AUTO_LAYOUT_MOVE_CHILDREN_TYPE_DOWN,
            altKey: false,
        })
        return
    }

    nodes.forEach((node) => {
        if (isNodeInAutoLayout(node)) {
            return
        }
        const { y } = calculateXY(node)
        commandInvoker.invoke(cmdUpdateNodeY, node.id, y + (shiftKey ? 10 : 1))
    })
}

export const cmdMoveSelectionLeft: BridgeCommand<[shiftKey: boolean]> = (
    { documentRoot, commandInvoker },
    shiftKey
) => {
    const selection = documentRoot.currentPage()?.selection
    if (!selection?.length) {
        return
    }

    const nodes = selection.map((id) => documentRoot.getNodeById(id) as SceneNode)

    const hasNonAutoLayout = nodes.some((v) => !isNodeInAutoLayout(v))
    if (!hasNonAutoLayout) {
        commandInvoker.DEPRECATED_invokeBridge(AutoLayoutMoveChildren, {
            type: Wukong.DocumentProto.AutoLayoutMoveChildrenType.AUTO_LAYOUT_MOVE_CHILDREN_TYPE_LEFT,
            altKey: false,
        })
        return
    }

    nodes.forEach((node) => {
        if (isNodeInAutoLayout(node)) {
            return
        }
        const { x } = calculateXY(node)
        commandInvoker.invoke(cmdUpdateNodeX, node.id, x - (shiftKey ? 10 : 1))
    })
}

export const cmdMoveSelectionRight: BridgeCommand<[shiftKey: boolean]> = (
    { documentRoot, commandInvoker },
    shiftKey
) => {
    const selection = documentRoot.currentPage()?.selection
    if (!selection?.length) {
        return
    }

    const nodes = selection.map((id) => documentRoot.getNodeById(id) as SceneNode)

    const hasNonAutoLayout = nodes.some((v) => !isNodeInAutoLayout(v))
    if (!hasNonAutoLayout) {
        commandInvoker.DEPRECATED_invokeBridge(AutoLayoutMoveChildren, {
            type: Wukong.DocumentProto.AutoLayoutMoveChildrenType.AUTO_LAYOUT_MOVE_CHILDREN_TYPE_RIGHT,
            altKey: false,
        })
        return
    }

    nodes.forEach((node) => {
        if (isNodeInAutoLayout(node)) {
            return
        }
        const { x } = calculateXY(node)
        commandInvoker.invoke(cmdUpdateNodeX, node.id, x + (shiftKey ? 10 : 1))
    })
}

export const cmdTextInputChange: BridgeCommand<[mode: Wukong.DocumentProto.InputMode, value?: string | null]> = (
    { bridge },
    mode,
    value
) => {
    bridge.call(TextInputCommand, { mode, value })
}

export const cmdDeleteStyleNode: BridgeCommand<[styleId: NodeId]> = ({ commandInvoker }, styleId) => {
    commandInvoker.DEPRECATED_invokeBridge(
        DeleteStyleNodeCommand,
        Wukong.DocumentProto.BridgeProtoString.create({
            value: styleId,
        })
    )
}

export const cmdChangeAttrPanelStyleEditorState: BridgeCommand<
    [
        attrs: null | {
            editingStyleId?: AttrPanelStyleEditorState['editingStyleId']
            openFromModule?: AttrPanelStyleEditorState['openFromModule']
            openFromNodeId?: AttrPanelStyleEditorState['openFromNodeId']
            selectReverseIndex?: AttrPanelStyleEditorState['selectReverseIndex']
            isCreate?: AttrPanelStyleEditorState['isCreate']
        }
    ]
> = ({ commandInvoker }, attrs) => {
    const defaultParam = {
        openFromModule: Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_NONE,
        openFromNodeId: [],
        selectReverseIndex: [],
        isCreate: false,
    }
    const param = attrs ? { ...defaultParam, ...attrs } : defaultParam
    commandInvoker.DEPRECATED_invokeBridge(
        UpdateAttrPanelEditorStateCommand,
        Wukong.DocumentProto.UpdateAttrPanelStyleEditorStateParam.create(param)
    )
}

export const wasmShowStartEditingHandlerForPaintStyle: BridgeCommand<
    [
        type: 'image' | 'gradient',
        freshPopupState?: PopupState,
        freshAttrPanelStyleEditorState?: AttrPanelStyleEditorState
    ]
> = ({ commandInvoker }, type, freshPopupState, freshAttrPanelStyleEditorState) => {
    commandInvoker.DEPRECATED_invokeBridge(
        ShowStartEditingHandlerForPaintStyleCommand,
        Wukong.DocumentProto.Arg_cmdShowStartEditingHandlerForPaintStyle.create({
            type,
            freshPopupState,
            freshAttrPanelStyleEditorState,
        })
    )
}
