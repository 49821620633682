import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
    Checkbox,
    MonoIconCanvasLink16,
    MonoIconCommonReset16,
    MonoIconPanelAdjust16,
    Select,
    SelectIconSingleLevelRef,
    WKButton,
    WKDivider,
    WKIconButton,
    WKToast,
    WKTypography,
} from '../../../../../ui-lib/src'
import {
    domLocation,
    execCommandCopy,
    generateRouterPath,
    generateUrlByQuery,
    isEnglishLanguage,
    joinStringListWithComma,
    randomString,
    RouteToken,
} from '../../../../../util/src'
import { BusinessStatusCode } from '../../../kernel/interface/request-error-code'
import {
    DocumentPublicAccessVO,
    DocWithAuthorityVO,
    FolderID,
    PlanAndUserStateVO,
    RoleStatus,
} from '../../../kernel/interface/type'
import { compareRole } from '../../../kernel/interface/user-roles'
import { UpdateDocumentCopyAccess, UpdateDocumentPublicAccess } from '../../../kernel/request/accesses'
import { RequestResponseErrorHandler } from '../../../kernel/request/error-handler'
import { LinkPublicOptionType, SpaceLogs } from '../../../kernel/service/space-frog-service/logs'
import { isActiveOrgPlan, isActiveProPlan, isStarterPlan } from '../../../utils/plan-status-checker'
import { PayPreCheck } from '../../payment/pay-pre-check'
import { LinkRoleDropDown } from '../link-role-dropdown'
import { useRealNameVerificationTip } from '../real-name-verification/real-name-verificatio-tip'
import { AccessVO, CurrentUser, ShareDialogType, SpecialShareDialogModeType } from '../types'
import { usePrototypePresentShareUtil } from '../use-prototype-present-share'
import classes from './index.module.less'
import { translation } from './index.translation'

const defaultPublicAccess: DocumentPublicAccessVO = {
    publicAccessible: false,
    publicAccessRole: RoleStatus.Viewer,
    passwordRequired: false,
    password: '',
}

export const PublicAccess = (props: {
    access: AccessVO
    currentUser: CurrentUser
    docData?: DocWithAuthorityVO
    domainList?: string[]
    shareDialogType: ShareDialogType
    dimission: boolean
    refreshMembersList?: () => Promise<void>
    specialModeType?: SpecialShareDialogModeType
    teamPlanOfSharingDoc: PlanAndUserStateVO | undefined
    publicLinkSharing: boolean
    canClickPasswordRequired: () => boolean
    shareDocLimitAction?: {
        setMoveModal: (v: boolean) => void
        setShareModal: (v: boolean) => void
    }
    draftFolderId: FolderID | undefined
    onRealNameVerificationStart: () => void
    onRealNameVerificationEnd: () => void
}) => {
    const {
        access,
        currentUser,
        docData,
        shareDialogType,
        dimission,
        refreshMembersList,
        domainList,
        specialModeType,
        teamPlanOfSharingDoc,
        publicLinkSharing,
        canClickPasswordRequired,
        shareDocLimitAction,
        draftFolderId,
        onRealNameVerificationStart,
        onRealNameVerificationEnd,
    } = props
    const { publicAccess, id = '' } = access
    const isEditable = useMemo(() => {
        return compareRole(currentUser.role, RoleStatus.Viewer) > 0
    }, [currentUser])
    const searchParams = new URLSearchParams(domLocation().search)
    const nodeId = searchParams.get('nodeId') || ''

    const { getPrototypePresentShareLink } = usePrototypePresentShareUtil(id)
    const { shouldGoToRealNameVerification } = useRealNameVerificationTip({
        onRealNameVerificationStart,
        onRealNameVerificationEnd,
    })

    const getCopyLink = () => {
        if (specialModeType === SpecialShareDialogModeType.PrototypePresent) {
            return getPrototypePresentShareLink()
        } else {
            return generateUrlByQuery(
                {
                    nodeId: encodeURIComponent(nodeId),
                    type: specialModeType === SpecialShareDialogModeType.DevMode ? 'dev' : 'design',
                },
                `${domLocation().origin}/${generateRouterPath(RouteToken.File)}/${id}`
            )
        }
    }
    // 本地展示的publicAccess
    const [publicSettings, setPublicSettings] = useState<DocumentPublicAccessVO>(publicAccess || defaultPublicAccess)

    const updatePublicAccess = async (params: DocumentPublicAccessVO, successMsg?: string) => {
        try {
            // FIXME(zhengyifei): 在 hare-dialog-access-password.test.ts 中，这里的 id 会是空字符串，导致请求错误
            await new UpdateDocumentPublicAccess(id ? id : undefined!, params).start()
            refreshMembersList?.()
            successMsg && WKToast.show(successMsg)
            setPublicSettings(params)
            return true
        } catch (e) {
            const msg = RequestResponseErrorHandler(e)
            // 国内其他团队下，用户分享任何人时未认证手机号，错误码：40025
            if (msg.businessStatus === 40025) {
                shouldGoToRealNameVerification()
            } else {
                WKToast.error(
                    msg.status === 403 && msg.businessStatus === BusinessStatusCode.IllagelSharingPermission
                        ? translation('DisabledByAdminToast')
                        : translation('FailedToOpen')
                )
            }
            setPublicSettings(publicAccess || defaultPublicAccess)
            return false
        }
    }

    const onClickSwitch = async () => {
        const newAccessible = !publicAccess?.publicAccessible
        const params = newAccessible
            ? {
                  publicAccessRole: RoleStatus.Viewer,
                  publicAccessible: true,
                  orgMemberOnly: docData?.orgId !== '-1',
                  password: '',
                  passwordRequired: false,
              }
            : {
                  publicAccessRole: RoleStatus.None,
                  publicAccessible: false,
                  orgMemberOnly: false,
                  password: '',
                  passwordRequired: false,
              }
        if (newAccessible) {
            SpaceLogs.FileShareAndPermission.openFilePublicAccess()
        } else {
            SpaceLogs.FileShareAndPermission.closeFilePublicAccess()
        }
        setPublicSettings(params)
        updatePublicAccess(params)
    }

    const onChangePublicAccessOrganizationRole = async (orgMemberOnly: boolean, publicAccessRole: RoleStatus) => {
        if (publicAccess) {
            if (
                shareDialogType === ShareDialogType.Doc &&
                publicAccessRole === RoleStatus.Editor &&
                !(await PayPreCheck.checkShareDoc({
                    doc: docData!,
                    invitedUsers: [],
                    userId: currentUser.userId,
                    actions: shareDocLimitAction,
                    draftFolderId,
                }))
            ) {
                return
            }
            const params = {
                ...publicAccess,
                orgMemberOnly,
                publicAccessRole,
            }
            if (publicAccess.publicAccessRole !== publicAccessRole) {
                SpaceLogs.FileShareAndPermission.setFilePublicScope({
                    link_public_option: orgMemberOnly
                        ? publicAccessRole === RoleStatus.Editor
                            ? LinkPublicOptionType.OrgEditor
                            : LinkPublicOptionType.OrgViewer
                        : publicAccessRole === RoleStatus.Editor
                        ? LinkPublicOptionType.AnyoneEditor
                        : LinkPublicOptionType.AnyoneViewer,
                })
            }
            updatePublicAccess(params)
            setPublicSettings(params)
        }
    }

    const [delayOnchangeState, setDelayOnchangeState] = useState<{
        flag: boolean
        orgMemberOnly: boolean
        publicAccessRole: RoleStatus
    }>()

    const onChangeDirectly = async (orgMemberOnly: boolean, publicAccessRole: RoleStatus) => {
        if (
            shareDialogType === ShareDialogType.Doc &&
            publicAccessRole === RoleStatus.Editor &&
            !(await PayPreCheck.checkShareDoc({
                doc: docData!,
                invitedUsers: [],
                userId: currentUser.userId,
                actions: shareDocLimitAction,
                draftFolderId,
            }))
        ) {
            return
        }
        updatePublicAccess({
            publicAccessRole,
            publicAccessible: true,
            orgMemberOnly,
            password: '',
            passwordRequired: false,
        })
        setDelayOnchangeState({ flag: true, orgMemberOnly, publicAccessRole })
    }

    const delayOnChange = () => {
        if (delayOnchangeState?.flag) {
            setDelayOnchangeState(undefined)
            onChangePublicAccessOrganizationRole(delayOnchangeState.orgMemberOnly, delayOnchangeState.publicAccessRole)
        }
    }
    const delayOnchangeRef = useRef(delayOnChange)
    delayOnchangeRef.current = delayOnChange

    useEffect(() => {
        if (publicAccess) {
            delayOnchangeRef.current?.()
        }
    }, [publicAccess])

    const hideAccessPassword = docData?.draft && !publicAccess?.passwordRequired

    const onClickPasswordRequired = async () => {
        if (!canClickPasswordRequired()) {
            return
        }
        if (publicAccess) {
            const newPasswordRequired = !publicAccess.passwordRequired
            const newPassword = newPasswordRequired ? publicSettings.password || randomString(6) : ''
            const params = {
                ...publicAccess,
                passwordRequired: newPasswordRequired,
                password: newPassword,
            }
            if (newPasswordRequired) {
                SpaceLogs.FileShareAndPermission.needPassword()
            }
            setPublicSettings(params)
            updatePublicAccess(params)
        }
    }

    const onClickResetPassword = async () => {
        if (publicAccess) {
            const newPassword = randomString(6)
            const params = {
                ...publicAccess,
                password: newPassword,
            }
            SpaceLogs.FileShareAndPermission.resetPassword()
            setPublicSettings(params)
            updatePublicAccess(params)
        }
    }

    const onClickCopyLink = () => {
        const copyLink = getCopyLink()
        let string = isEnglishLanguage() ? `${copyLink} "${docData?.name}"` : `${copyLink} 「${docData?.name}」`
        if (!hideAccessPassword && publicAccess?.passwordRequired) {
            string += ` ${translation('Password')}${publicAccess.password}`
            SpaceLogs.FileShareAndPermission.copyLinkAndPasswordInDialog()
        } else {
            SpaceLogs.FileShareAndPermission.copyLink2InDialog()
        }
        execCommandCopy(string)
        WKToast.show(translation('LinkCopied'))
    }

    useEffect(() => {
        if (publicAccess) {
            setPublicSettings(publicAccess)
        }
    }, [publicAccess])

    const renderAccessPassword = () => {
        if (hideAccessPassword) {
            return null
        }

        return isEditable ? (
            <div
                data-testid="public-access-password-wrapper"
                className={classNames([classes.line, '!py-0', classes.lineCap, classes.needPassword])}
            >
                <Checkbox
                    className={classes.checkbox}
                    testid="public-access-password-required"
                    checked={publicSettings.passwordRequired}
                    onChange={onClickPasswordRequired}
                    label={translation('NeedPassword')}
                />

                <div
                    data-testid="public-access-password-input-wrapper"
                    className={classNames(
                        'flex items-center w-23 justify-between bg-$wk-v2-fill-color-gray-1 py-0.5 rounded-$wk-radius-default',
                        !publicSettings.passwordRequired && 'opacity-0'
                    )}
                    style={!publicSettings.passwordRequired ? { display: 'none' } : undefined}
                >
                    <WKTypography.Text color="placeholder" className="pl-1.5" data-testid="public-access-password">
                        {publicSettings.password}
                    </WKTypography.Text>
                    <WKIconButton
                        type={'primary'}
                        onClick={onClickResetPassword}
                        data-testid="public-access-password-reset"
                        icon={<MonoIconCommonReset16 />}
                    />
                </div>
            </div>
        ) : publicSettings.passwordRequired ? (
            <div className={classNames(classes.line, classes.lineCap)}>
                <Checkbox className="mr-2" checked={true} disabled={true} label={translation('NeedPassword')} />
                <div
                    data-testid="public-access-password"
                    className={classNames(
                        'flex items-center w-23 justify-between bg-$wk-v2-fill-color-gray-1 h-8 rounded-$wk-radius-default',
                        !publicSettings.passwordRequired && 'opacity-0'
                    )}
                >
                    <WKTypography.Text color="placeholder" className="pl-1.5">
                        {publicSettings.password}
                    </WKTypography.Text>
                </div>
            </div>
        ) : null
    }

    const [showDropDown, setShowDropDown] = useState(false)
    const [waitAccessSignal, setWaitAccessSignal] = useState(true)
    const ref = useRef<SelectIconSingleLevelRef>(null)

    const updateMemeberList = useCallback(() => {
        // 仅打开时走网络请求
        if (showDropDown) {
            setWaitAccessSignal(true)
            return
        }
        refreshMembersList?.().finally(() => {
            setWaitAccessSignal(false)
            if (!showDropDown) {
                setTimeout(() => {
                    ref.current?.open()
                }, 0)
            }
        })
    }, [refreshMembersList, showDropDown])

    const inActiveResource =
        teamPlanOfSharingDoc && (isActiveProPlan(teamPlanOfSharingDoc) || isActiveOrgPlan(teamPlanOfSharingDoc))
    const isFreePlan = (teamPlanOfSharingDoc && isStarterPlan(teamPlanOfSharingDoc)) ?? false

    return (
        <>
            <div className={classNames(classes.publicAccessSetting, classes.publicAccessSetting2)}>
                <div className={classes.title}>
                    <span>{translation('LinkSharingRange')}</span>
                </div>
                {publicSettings.publicAccessible ? (
                    <div className={classNames('flex items-center')} data-testid="public-accessible">
                        <div className="w-0 flex-1 mr-4">
                            <div className={classNames(classes.line, classes.lineCap)}>
                                {docData && (
                                    <LinkRoleDropDown
                                        orgId={docData.orgId}
                                        isEditable={!!isEditable}
                                        dimission={dimission}
                                        isDraft={!!docData.draft}
                                        orgMemberOnly={!!publicSettings.orgMemberOnly}
                                        publicAccessRole={publicSettings.publicAccessRole}
                                        onChange={onChangePublicAccessOrganizationRole}
                                        publicLinkSharing={publicLinkSharing}
                                        hidePrototype={isFreePlan}
                                        onlyShare={false}
                                        onChangeOnlyShare={onClickSwitch}
                                    />
                                )}
                            </div>
                            {isEditable && domainList && !!publicSettings.orgMemberOnly && (
                                <div
                                    className={classNames(
                                        'color-$wk-v2-label-color-gray-8 wk-text-12 mb-2',
                                        classes.lineCap
                                    )}
                                >
                                    {translation('MembersHaveEmails')}{' '}
                                    {joinStringListWithComma(domainList.map((str) => `${str}`))}
                                </div>
                            )}
                            {renderAccessPassword()}
                        </div>
                    </div>
                ) : (
                    <div className={'flex items-center'} data-testid="public-accessible">
                        <div className="w-0 flex-1 mr-4">
                            <div className={classNames(classes.line, classes.lineCap)}>
                                {docData?.orgId ? (
                                    <LinkRoleDropDown
                                        orgId={docData?.orgId}
                                        isEditable={!!isEditable}
                                        dimission={dimission}
                                        isDraft={!!docData?.draft}
                                        orgMemberOnly={!!publicSettings.orgMemberOnly}
                                        publicAccessRole={publicSettings.publicAccessRole}
                                        onChange={onChangeDirectly}
                                        publicLinkSharing={publicLinkSharing}
                                        hidePrototype={isFreePlan}
                                        onlyShare={true}
                                        onChangeOnlyShare={() => {}}
                                    />
                                ) : null}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <WKDivider className="-mx-6" style={{ width: 'initial' }} />
            <div className={classes.copyAccessSetting} style={{ padding: '12px 0' }}>
                <WKButton
                    type="secondary"
                    size="medium"
                    icon={<MonoIconCanvasLink16 />}
                    onClick={onClickCopyLink}
                    data-testid="public-access-copy-link-button"
                >
                    {translation('CopyLink')}
                    {!hideAccessPassword && publicSettings.passwordRequired ? translation('AndPassword') : ''}
                </WKButton>
                {inActiveResource && (
                    <div data-testid={'prohibit-copy-export-select'}>
                        <Select.IconSingleLevel
                            label={<MonoIconPanelAdjust16 />}
                            value={access.copyable ? 1 : 0}
                            className="size-6"
                            onChange={async () => {
                                await new UpdateDocumentCopyAccess(access.id ?? '', !access.copyable).start()
                                await refreshMembersList?.()
                            }}
                            onClick={updateMemeberList}
                            openStateToBeTrue={() => waitAccessSignal}
                            onOpen={() => setShowDropDown(true)}
                            onClose={() => setShowDropDown(false)}
                            placement="bottom right"
                            ref={ref}
                        >
                            <Select.IconSingleLevel.Option key={'title'} value={-1} disabled={true}>
                                {translation('Advance')}
                            </Select.IconSingleLevel.Option>
                            <Select.IconSingleLevel.Option key={'copyable'} value={1} disabled={!isEditable}>
                                {translation('Copyable')}
                            </Select.IconSingleLevel.Option>
                        </Select.IconSingleLevel>
                    </div>
                )}
            </div>
        </>
    )
}
