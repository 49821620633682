import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import {
    InputV2,
    InputV2Ref,
    MonoIconPanelTarget16,
    SimpleDrag,
    Tooltip,
    WKButton,
} from '../../../../../../../../../ui-lib/src'
import { isEnglishLanguage } from '../../../../../../../../../util/src/i18n'
import { PopupStateType } from '../../../../../../../document/node/node'
import { useViewState } from '../../../../../../../view-state-bridge'
import { IconButton } from '../../../../../atom/button/icon-button'
import { SingleGrid } from '../../../../../atom/grid/single-grid'
import { useComponentInstanceCommand } from '../../../commands'
import { VComponentPropDef, type ComponentPropEditPopupVal } from '../../../types'
import { ICreateComponentProp } from '../../component-prop/hook'
import styles from './index.module.less'
import { translation } from './index.translation'

export function CreateVariantPropPopupContent(props: {
    popupData: VComponentPropDef
    popupType: PopupStateType
    closePopup: () => void
    createProp: (propVal: ICreateComponentProp) => void
    createAndApplyProp: (propVal: ICreateComponentProp) => void
}) {
    const { popupData } = props
    const [variantPropName, setVariantPropName] = useState<string>(popupData.name)
    const [variantPropVal, setVariantPropVal] = useState<string>(translation('Default'))
    const commands = useComponentInstanceCommand()
    const state = useViewState('componentPanelState')
    const variantState = state?.variantState
    const confirm = () => {
        commands.updateCompSetProps(
            (variantState?.compSetProps ?? []).concat([
                {
                    name: variantPropName,
                    options: [variantPropVal],
                    orderIndex: variantState?.compSetProps.length ?? 0,
                    optionCounter: {},
                },
            ])
        )
        props.closePopup()
    }
    return (
        <div className="pt-2">
            <SingleGrid>
                <SingleGrid.Item start={5} span={16}>
                    {translation('PropertyName')}
                </SingleGrid.Item>
                <SingleGrid.Item start={21} span={36}>
                    <InputV2
                        dataTestIds={{ input: 'create-variant-prop-name-input' }}
                        autoFocus
                        size="small"
                        width={144}
                        value={variantPropName}
                        onChange={(e: any) => {
                            setVariantPropName(e.target.value)
                        }}
                    />
                </SingleGrid.Item>
            </SingleGrid>
            <SingleGrid className={styles.variantValueAreaRow}>
                <SingleGrid.Item start={5} span={16}>
                    {translation('PropertyValue')}
                </SingleGrid.Item>
                <SingleGrid.Item start={21} span={36}>
                    <InputV2.Textarea
                        dataTestIds={{ textarea: 'create-variant-prop-value-textarea' }}
                        autoHeight
                        minHeight={28}
                        maxHeight={108}
                        placeholder={translation('Default')}
                        onChange={(e: any) => {
                            setVariantPropVal(e.target.value)
                        }}
                        value={variantPropVal}
                    />
                </SingleGrid.Item>
            </SingleGrid>
            <div className={styles.compPropPopupConfirm}>
                <WKButton type="primary" dataTestId="create-prop-confirm" onClick={confirm} disabled={!variantPropName}>
                    {translation('CreateProperty')}
                </WKButton>
            </div>
        </div>
    )
}

export function VariantPropName(props: { value: string; onNameChanged: (newName: string) => void }) {
    const { value, onNameChanged } = props
    const [inputEditing, setInputEditing] = useState<boolean>(true)
    const [inputVal, setInputVal] = useState<string>(value)
    const inputRef = useRef<InputV2Ref>(null)

    const handleBlur = (e: any) => {
        setInputEditing(false)
        if (e.target.value) {
            onNameChanged(e.target.value)
            setInputVal(e.target.value)
        } else {
            setInputVal(value)
        }
    }

    if (inputEditing) {
        return (
            <div
                className={classNames(styles.variantPropEditingTitle)}
                data-testid={'variant-prop-input-name-' + value}
            >
                <InputV2 size="small" ref={inputRef} value={inputVal} autoFocus onBlur={handleBlur} />
            </div>
        )
    }
    return (
        <div
            className={classNames(styles.variantPropNormalTitle)}
            onClick={() => {
                setInputEditing(true)
            }}
            data-testid={'variant-prop-name-' + value}
        >
            {value}
        </div>
    )
}

export function VariantPropValueItem(props: {
    value: string
    variantAmount: number
    index: number
    selected: boolean
    editing: boolean
    onValueChanged: (oldValue: string, newValue: string) => void
    onSelectSpecificVariantValueNodes: (value: string) => void
    onHoverSpecificVariantValueNodes: (value: string) => void
    onEditingChanged: (focus: boolean) => void
}) {
    const {
        value,
        variantAmount,
        selected,
        editing,
        index,
        onValueChanged,
        onSelectSpecificVariantValueNodes,
        onHoverSpecificVariantValueNodes,
        onEditingChanged,
    } = props

    const [hovered, setHovered] = useState<boolean>(false)
    return (
        <SimpleDrag.Item itemIndex={index} dragIconClassName={styles.dragIconClassName}>
            <div
                onMouseEnter={() => {
                    setHovered(true)
                    onHoverSpecificVariantValueNodes(value)
                }}
                onMouseLeave={() => {
                    setHovered(false)
                    onHoverSpecificVariantValueNodes('')
                }}
            >
                <VariantPropValueItemContent
                    value={value}
                    variantAmount={variantAmount}
                    hovered={hovered}
                    selected={selected}
                    editing={editing}
                    onValueChanged={onValueChanged}
                    onSelectSpecificVariantValueNodes={onSelectSpecificVariantValueNodes}
                    onHoverSpecificVariantValueNodes={onHoverSpecificVariantValueNodes}
                    onEditingChanged={onEditingChanged}
                />
            </div>
        </SimpleDrag.Item>
    )
}

export function VariantPropValueItemContent(props: {
    value: string
    variantAmount: number
    hovered: boolean
    selected: boolean
    editing: boolean
    onValueChanged: (oldValue: string, newValue: string) => void
    onSelectSpecificVariantValueNodes: (value: string) => void
    onHoverSpecificVariantValueNodes: (value: string) => void
    onEditingChanged: (focus: boolean) => void
}) {
    const {
        value,
        variantAmount,
        hovered,
        selected,
        editing,
        onValueChanged,
        onSelectSpecificVariantValueNodes,
        onHoverSpecificVariantValueNodes,
        onEditingChanged,
    } = props

    const [inputVal, setInputVal] = useState<string>(value)
    const inputRef = useRef<InputV2Ref>(null)

    useEffect(() => {
        setInputVal(value)
    }, [value])

    const handleBlur = (e: any) => {
        onEditingChanged(false)
        if (e.target.value) {
            onValueChanged(value, e.target.value)
            setInputVal(e.target.value)
        } else {
            setInputVal(value)
        }
    }

    if (editing) {
        return (
            <div className={classNames(styles.variantPropEditingValueItem, selected ? styles.selected : '')}>
                <InputV2
                    size="small"
                    ref={inputRef}
                    value={inputVal}
                    autoFocus
                    onBlur={handleBlur}
                    dataTestIds={{ input: 'variant-prop-input-value-' + value }}
                />
            </div>
        )
    }
    if (hovered) {
        return (
            <div
                className={classNames(styles.variantPropHoverValueItem, selected ? styles.selected : '')}
                onMouseLeave={() => {
                    onHoverSpecificVariantValueNodes('')
                }}
                onClick={() => {
                    onEditingChanged(true)
                }}
            >
                <div className={styles.variantPropHoverValueBody} data-testid={'variant-prop-value-' + value}>
                    {value}
                </div>
                <Tooltip
                    title={
                        translation('SelectVariants', { amount: variantAmount.toString() }) +
                        (isEnglishLanguage() && variantAmount > 1 ? 's' : '')
                    }
                >
                    <IconButton
                        dataTestId="component-prop-defs-item-edit"
                        className={'w-6 h-6'}
                        selected={false}
                        icon={<MonoIconPanelTarget16 />}
                        onClick={(e: any) => {
                            onSelectSpecificVariantValueNodes(value)
                            e.stopPropagation()
                        }}
                    />
                </Tooltip>
            </div>
        )
    }
    return (
        <div
            className={classNames(styles.variantPropNormalValueItem, selected ? styles.selected : '')}
            data-testid={'variant-prop-value-' + value}
        >
            {value}
        </div>
    )
}

export function UpdateVariantPropPopupContent(props: {
    popupData: VComponentPropDef
    closePopup: () => void
    modifyVariantName: (popupStateType: PopupStateType, editPopupVal?: ComponentPropEditPopupVal) => void
    selectNodesBySpecificVariantValue: (propName: string, propValue: string) => void
    hoverNodesBySpecificVariantValue: (propName: string, propValue: string) => void
}) {
    const {
        popupData,
        closePopup,
        modifyVariantName,
        selectNodesBySpecificVariantValue,
        hoverNodesBySpecificVariantValue,
    } = props

    const variantState = useViewState('componentPanelState')?.variantState
    const compSetProps = variantState?.compSetProps ?? []
    const commands = useComponentInstanceCommand()

    const onNameChanged = (newName: string) => {
        commands.updateCompSetProps(
            compSetProps.map((prop) => {
                if (prop.name === popupData.name) {
                    prop.name = newName
                }
                return prop
            })
        )
        modifyVariantName(PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_VARIANT_PROP, {
            name: newName,
            variantOptions: popupData.variantOptions,
        } as ComponentPropEditPopupVal)
    }

    const onValueChanged = (oldValue: string, newValue: string) => {
        commands.updateCompSetProps(
            compSetProps.map((prop) => {
                if (prop.name === popupData.name) {
                    prop.options = prop.options.map((value) => {
                        if (value == oldValue) {
                            return newValue
                        }
                        return value
                    })
                }
                return prop
            })
        )
    }

    const [selectedDragItems, setSelectedDragItems] = useState<number[]>([])

    const onValueOrderChanged = (newOptions: Wukong.DocumentProto.IVariantOption[], selectedIndexList: number[]) => {
        commands.reorderSelectedComponentSetPropOptions({
            selectedVariantPropKey: popupData.name,
            newOptions: newOptions.map((option) => option.optionValue),
        })
        setSelectedDragItems(selectedIndexList)
    }
    const onSelectSpecificVariantValueNodes = (propValue: string) => {
        hoverNodesBySpecificVariantValue('', '')
        selectNodesBySpecificVariantValue(popupData.name, propValue)
    }
    const onHoverSpecificVariantValueNodes = (propValue: string) => {
        if (!propValue) {
            hoverNodesBySpecificVariantValue('', '')
            return
        }
        hoverNodesBySpecificVariantValue(popupData.name, propValue)
    }

    const [editingValueIndex, setEditingValueIndex] = useState<number | undefined>()

    useEffect(() => {
        if (!popupData.name) {
            closePopup()
        }
    }, [popupData.name, closePopup])
    return (
        <div className="pb-2" data-testid="variant-prop-edit-panel">
            <VariantPropName value={popupData.name} onNameChanged={onNameChanged} />
            <div
                className={styles.variantPropValueContent}
                onMouseMove={(e) => {
                    e.stopPropagation()
                }}
            >
                <SimpleDrag
                    selectedIndexList={selectedDragItems}
                    items={popupData.variantOptions}
                    onSelectChange={(items) => {
                        setSelectedDragItems(items)
                    }}
                    onDragDone={onValueOrderChanged}
                    data-testid={'component-prop-variant-prop-list'}
                    onPointerDown={(e) => e.stopPropagation()} // 防止最外层弹窗被拖拽
                >
                    {popupData.variantOptions.map((variantOption, propIndex) => {
                        return (
                            <VariantPropValueItem
                                key={propIndex}
                                variantAmount={variantOption.variantAmount}
                                index={propIndex}
                                value={variantOption.optionValue}
                                editing={propIndex === editingValueIndex}
                                selected={selectedDragItems.includes(propIndex)}
                                onValueChanged={onValueChanged}
                                onSelectSpecificVariantValueNodes={onSelectSpecificVariantValueNodes}
                                onHoverSpecificVariantValueNodes={onHoverSpecificVariantValueNodes}
                                onEditingChanged={(focus) => {
                                    if (focus) {
                                        setEditingValueIndex(propIndex)
                                        setSelectedDragItems([propIndex])
                                    } else {
                                        setEditingValueIndex(undefined)
                                        setSelectedDragItems([])
                                    }
                                }}
                            />
                        )
                    })}
                </SimpleDrag>
            </div>
        </div>
    )
}
