import classNames from 'classnames'
import React, { forwardRef, Key, ReactElement, ReactNode, Ref, useCallback, useEffect, useMemo, useState } from 'react'
import { isNotNullOrUndefined } from '../../../../util/src'
import classes from './tabs.module.less'

/**
 * Tabs 组件
 * https://motiff.com/file/X2IivEo4v6LnEKukTXiMz6E?nodeId=334279%3A834&type=design
 */
export interface TabItemProps {
    readonly tabKey: Key
    readonly label: ReactNode
    readonly children?: ReactNode
    readonly className?: string
    readonly showPoint?: boolean
    readonly disabled?: boolean
}

type TabChild = ReactElement<TabItemProps> | null | undefined

export interface TabProps {
    /**
     * 默认选中的 Tab key
     */
    readonly defaultActiveKey?: Key

    readonly activeKey?: Key

    /**
     * 子节点的label必须唯一
     */
    readonly children: TabChild[]

    readonly onActiveTabChange?: (tabKey: Key) => void

    readonly size?: 'small' | 'medium'
    readonly type?: 'text' | 'line'

    /**
     * 对齐方式，默认左对齐
     */
    readonly align?: 'left' | 'center'

    /**
     * tabs-item 间距，默认不加间距，单位px
     */
    readonly gap?: number

    readonly className?: string
    // tab 标签样式
    readonly navListWrapClassName?: string
    readonly navListClassName?: string
    readonly navItemClassName?: string

    /**
     * Tabs-line 选中条颜色
     */
    readonly lineBackgroundColor?: string

    /**
     * 被隐藏时是否保持 dom 节点
     */
    readonly keepInactiveTabPane?: boolean

    /**
     * 是否支持聚焦
     */
    readonly focusable?: boolean

    /**
     * tabs 位置，默认 top
     */
    readonly tabPosition?: 'top' | 'left'

    readonly rightExpandNode?: ReactNode
    readonly dataTestId?: string
    readonly disabledTabPanelDrag?: boolean
}

interface TabNavItemProps extends TabItemProps {
    readonly className?: string
    readonly active: boolean
    readonly focusable?: boolean
    readonly lineBackgroundColor?: string
    readonly onClick: (tabKey: Key) => void
}

type TabTitleRef = HTMLDivElement
const _Tabs = (
    {
        activeKey,
        defaultActiveKey,
        onActiveTabChange,
        children,
        size = 'small',
        type = 'text',
        align = 'left',
        gap,
        className,
        navListWrapClassName,
        navListClassName,
        navItemClassName,
        lineBackgroundColor,
        keepInactiveTabPane,
        focusable,
        tabPosition = 'top',
        rightExpandNode,
        dataTestId,
        disabledTabPanelDrag,
    }: TabProps,
    ref: Ref<TabTitleRef>
) => {
    const [currentTabKey, setCurrentTabKey] = useState<Key | undefined>(activeKey ?? defaultActiveKey)

    const validTabKey = useMemo(() => {
        return isNotNullOrUndefined(activeKey) ? activeKey : currentTabKey
    }, [currentTabKey, activeKey])

    const onNavClick = useCallback(
        (tabKey: Key): void => {
            setCurrentTabKey(tabKey)
            onActiveTabChange?.(tabKey)
        },
        [onActiveTabChange]
    )

    // NOTE: 过滤非法TabItem
    const validChildren = useMemo(() => children.filter(isNotNullOrUndefined), [children])

    const tabNavItemList = validChildren.map((item) => (
        <TabNavItem
            key={item.props.tabKey}
            className={classNames(classes[`${size}-${type}-nav-item`], navItemClassName)}
            label={item.props.label}
            active={item.props.tabKey === validTabKey}
            onClick={onNavClick}
            tabKey={item.props.tabKey}
            showPoint={item.props.showPoint}
            disabled={item.props.disabled}
            focusable={focusable}
            lineBackgroundColor={lineBackgroundColor}
        ></TabNavItem>
    ))

    return (
        <div className={classNames(classes.tab, classes[`tab-${tabPosition}`], className)} data-testid={dataTestId}>
            <div className={classNames(classes.navListWrapper, navListWrapClassName)}>
                <div
                    data-testid="tab-nav-list"
                    className={classNames(
                        classes.navList,
                        classes[`${size}-${type}-nav-list`],
                        classes[align],
                        navListClassName
                    )}
                    ref={ref}
                    style={{ gap: gap && `${gap}px` }}
                >
                    {tabNavItemList}
                </div>
                <div className={classes.navRightExpandContainer}>{rightExpandNode}</div>
            </div>
            {validChildren.map((item) => (
                <TabItemWrapper
                    key={item.props.tabKey}
                    currentTabKey={validTabKey}
                    keepInactiveTabPane={keepInactiveTabPane}
                    disabledTabPanelDrag={disabledTabPanelDrag}
                >
                    {item}
                </TabItemWrapper>
            ))}
        </div>
    )
}

function TabNavItem({
    label,
    active,
    onClick,
    className,
    tabKey,
    showPoint,
    disabled,
    focusable,
    lineBackgroundColor,
}: TabNavItemProps) {
    const _onClick = useCallback(() => {
        if (!disabled) {
            onClick(tabKey)
        }
    }, [disabled, tabKey, onClick])
    const _onKeyDown = useCallback(
        (e: React.KeyboardEvent) => {
            if (!disabled && (e.code === 'Enter' || e.code === 'Space')) {
                onClick(tabKey)
            }
        },
        [disabled, tabKey, onClick]
    )
    return (
        <div
            data-testid="tab-nav-item"
            className={classNames(
                classes.navItem,
                active ? classes.active : '',
                className,
                disabled ? classes.disabled : ''
            )}
            onClick={_onClick}
            onKeyDown={_onKeyDown}
        >
            {focusable && !disabled ? <div tabIndex={0} className={classes.focusElement}></div> : null}
            <div className={classNames(showPoint ? classes.showPoint : '', 'flex items-center')}>{label}</div>
            <div className={classes.line} style={{ backgroundColor: lineBackgroundColor }} />
        </div>
    )
}

function TabItemWrapper({
    currentTabKey,
    children,
    keepInactiveTabPane,
    disabledTabPanelDrag,
}: {
    currentTabKey: Key | undefined
    children: ReactElement<TabItemProps>
    keepInactiveTabPane: boolean | undefined
    disabledTabPanelDrag?: boolean
}) {
    const [isMounte, setIsMounte] = useState(false)
    const visible = currentTabKey === children.props.tabKey

    useEffect(() => {
        setIsMounte((prev) => (prev ? prev : visible))
    }, [visible])

    return keepInactiveTabPane ? (
        isMounte ? (
            <div
                className={classNames('overflow-hidden flex-auto', { hidden: !visible }, children.props.className)}
                data-disabled-drag-move={disabledTabPanelDrag ? 'true' : undefined}
            >
                {children}
            </div>
        ) : (
            <></>
        )
    ) : visible ? (
        <>{children}</>
    ) : (
        <></>
    )
}

export const Tabs = forwardRef(_Tabs)
export function TabItem({ children }: TabItemProps) {
    return <>{children}</>
}
