export const Oda = [
    '67beb6057ba5513c92e0841aaaa82ddca7d3ea37',
    '617b04deceb1f369c3a2a4b298072604223ad7cd',
    'e3f3555e4e41aa426539214cf43d8a144c8d5831',
    'd436135520a575bd8acadefbc885496957917ec9',
    '974f0d63da87d1d243e97cadf90d524ffe403ca9',
    '69d09340757c1358dd81e01fbc19febde508c48c',
    '2dd739935e8a16d4c7f7e7614350752ef0b6bdcc',
    '934357627d864392f99536ee069e0b8316dd74bd',
    'a37fad84b67132d34e9f51284754fada309fcb83',
    '9ed18c7b519e19d30993b776b65a6ef6fb9ab227',
    'ed12faf0b21721a0d7edf097d944d1657d8a1a03',
    'ba9331bd72ba8fc41188f9579f99e08f30b4729d',
    '3bbed1be709446a0747061ca4b0d4e8717130cd8',
    'a5b632bc6fbaf0adea92f16d75ecebb7ad23f005',
    '85623293ef154bbcf4264b701ddf661493cfd20a',
    '7d7254f5cb75edf2470c63822af22025cb597647',
    'd5f73fc9f1f7ed651c7afafb9b4407025175d1b9',
    'bdb9585e5dff5253e86a1e01e8fedaae38fee051',
    '2fe92ffc1238ec33780daf689ad665b6ae356c37',
    '647c6642f3aa46fd01473d837b2fc22dba5d361d',
    'c62f50ac5c826cf3052cee0a2d0bfded194f9c78',
    '75a2e2de96d40cf41c15a1e2ff9436cee755e6a5',
    '62ba2ca580cb2d1a86e7856c2f95c8b4796d2524',
    '4327e7fc25491c0ce0fd22d1849256686fb829e8',
    'd03c29bf13b90c41696a4da40f3eb0e7754b23f9',
]
