import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    AVulnerabilityHas: '检测到当前版本存在隐患，{{name}}已修复了问题并发布了新的版本，请重新加载以升级至最新版本。',
    NeedRefreshMotiff: 'Motiff 发布了新的版本，请重新加载以升级至最新版本。',
    Update: '重新加载',
} as const

export const enTranslation = {
    AVulnerabilityHas:
        'Your version is out of date. For security and optimal performance, click Reload to update to the latest version.',
    NeedRefreshMotiff: 'Your version is out of date, click Reload to update to the latest version.',
    Update: 'Reload',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
