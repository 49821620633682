import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    SelectLayersFirst: '请选择要导出的图层',
    ExportSelectionTo: '导出选中图层到',
    Exporting: '正在导出',
    FailedToConnect: '连接 AE 失败',
    ExportSuccessfully: '已导出图层',
    FailedToExport: '导出图层失败',
    Options: '预处理',
    RasterizeLayerOn: '导出时栅格化该图层',
    Actions: '导出',
    ExportReferenceImage: '导出图层效果图',
    MotiffTo: '导出到',
} as const

export const enTranslation = {
    SelectLayersFirst: 'Select layers first',
    ExportSelectionTo: 'Export selection to',
    Exporting: 'Exporting',
    FailedToConnect: 'Failed to connect AE',
    ExportSuccessfully: 'Export successfully',
    FailedToExport: 'Failed to export',
    Options: 'Options',
    RasterizeLayerOn: 'Rasterize layer on export',
    Actions: 'Actions',
    ExportReferenceImage: 'Export reference image',
    MotiffTo: 'Motiff to',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
