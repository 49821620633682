/* eslint-disable no-restricted-imports */
import { useCallback } from 'react'
import {
    createGradientThumbnail,
    createImageThumbnail,
    createPaintStylePreview,
    createPaintStyleThumbnail,
} from '../../../document/command/preview-thumbnail'
import { GradientPaint, ImagePaint, Paint } from '../../../document/node/node'
import { useCommand } from '../../../main/app-context'
import { getPaintsHash } from '../get-paints-hash'
import { useImageLoaded } from './use-image-loaded'
import { useThumbnailCache } from './use-thumbnail-cache'

export interface PaintStyleThumbnailProps {
    width: number
    height: number
    paints: readonly Paint[]
    multiple?: number
}

/**
 * @description 颜色样式的小圆圈缩略图
 */
export function usePaintStyleThumbnail(props: PaintStyleThumbnailProps) {
    const { width, height, paints, multiple } = props
    const command = useCommand()
    const { isImageLoaded } = useImageLoaded(getPaintsHash(paints))

    const getThumbnailMethod = useCallback(
        (_props: PaintStyleThumbnailProps & { isImageLoaded: boolean }) => {
            if (!_props.isImageLoaded) {
                return
            }
            return command.invoke(createPaintStyleThumbnail, {
                width: _props.width * (_props.multiple ?? 1),
                height: _props.height * (_props.multiple ?? 1),
                paints: _props.paints as Paint[],
            })
        },
        [command]
    )
    return useThumbnailCache({ width, height, paints, multiple, isImageLoaded }, getThumbnailMethod)
}

export type PaintStylePreviewThumbnailProps = PaintStyleThumbnailProps

/**
 * @description 颜色样式编辑|创建的预览缩略图
 */
export function usePaintStylePreviewThumbnail(props: PaintStylePreviewThumbnailProps) {
    const { width, height, paints, multiple } = props
    const command = useCommand()
    const { isImageLoaded } = useImageLoaded(getPaintsHash(props.paints))

    const getThumbnailMethod = useCallback(
        (_props: PaintStylePreviewThumbnailProps & { isImageLoaded: boolean }) => {
            if (!_props.isImageLoaded) {
                return
            }
            return command.invoke(createPaintStylePreview, {
                width: _props.width * (_props.multiple ?? 1),
                height: _props.height * (_props.multiple ?? 1),
                paints: _props.paints as Paint[],
            })
        },
        [command]
    )
    return useThumbnailCache({ width, height, paints, multiple, isImageLoaded }, getThumbnailMethod)
}

export interface PaintImageThumbnailProps {
    width: number
    height: number
    paint: Readonly<ImagePaint>
    multiple?: number
    isStopUpdate?: boolean
}
/**
 * @description paint编辑 - ImagePaint 的预览缩略图
 */
export function usePaintImageThumbnail(props: PaintImageThumbnailProps) {
    const { width, height, paint, multiple, isStopUpdate } = props
    const command = useCommand()
    const { isImageLoaded } = useImageLoaded(paint.imageHash)
    const { isImageLoaded: isImageLoaded2 } = useImageLoaded(paint.previewHash)

    const getThumbnailMethod = useCallback(
        (_props: PaintImageThumbnailProps & { isImageLoaded: boolean }, cache?: string) => {
            if (_props.isStopUpdate) {
                return cache ?? ''
            }
            if (!_props.isImageLoaded) {
                return ''
            }
            return command.invoke(createImageThumbnail, {
                width: _props.width * (_props.multiple ?? 1),
                height: _props.height * (_props.multiple ?? 1),
                imagePaint: _props.paint,
            })
        },
        [command]
    )
    return useThumbnailCache(
        { width, height, paint, multiple, isStopUpdate, isImageLoaded: isImageLoaded || isImageLoaded2 },
        getThumbnailMethod
    )
}

export interface PaintGradientThumbnailProps {
    width: number
    height: number
    paint: Readonly<GradientPaint>
    multiple?: number
    isStopUpdate?: boolean
}
/**
 * @description paint编辑 - ImagePaint 的预览缩略图
 */
export function usePaintGradientThumbnail(props: PaintGradientThumbnailProps) {
    const { width, height, paint, multiple, isStopUpdate } = props
    const command = useCommand()
    const getThumbnailMethod = useCallback(
        (_props: PaintGradientThumbnailProps, cache?: string) => {
            if (_props.isStopUpdate) {
                return cache ?? ''
            }
            return command.invoke(createGradientThumbnail, {
                width: _props.width * (_props.multiple ?? 1),
                height: _props.height * (_props.multiple ?? 1),
                gradientPaint: _props.paint,
            })
        },
        [command]
    )
    return useThumbnailCache({ width, height, paint, multiple, isStopUpdate }, getThumbnailMethod)
}
