import {
    FeatureSwitchConfig,
    SwitchEnvironmentScopeType,
    SwitchStrategyType,
    UserGroupType,
} from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'compute-and-check-document-hash',
    owner: '',
    description: '',
    strategies: [
        {
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                group: UserGroupType.MOTIFF_GROUP,
            },
            env: SwitchEnvironmentScopeType.ALL,
        },
        {
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                userList: ['b1cbeff7dcbdcb3b31baee5b65d5476fcca34f06'],
            },
            env: SwitchEnvironmentScopeType.ALL,
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
