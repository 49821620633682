import { LayerPanelExpandClickCommand, LayerPanelOptionExpandClickCommand, Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { FC, MouseEventHandler, useCallback, useMemo } from 'react'
import { MonoIconCommonEnterDown16, MonoIconCommonEnterRight16 } from '../../../../../../ui-lib/src'
import { CommitType } from '../../../../document/command/commit-type'
import { NodeId } from '../../../../document/node/node'
import { useCommand } from '../../../context/document-context'
import { RenderItemBackground } from '../type'
import styles from './index.module.less'

import VLayerPanelNodeExpandStauts = Wukong.DocumentProto.VLayerPanelNodeExpandStauts

interface LayerPanelItemExpandProps {
    id: NodeId
    expandStatus: VLayerPanelNodeExpandStauts
    renderItemBackground: RenderItemBackground
}

function getRenderItemExpandBackground(
    renderItemBackground: RenderItemBackground,
    expandStatus: VLayerPanelNodeExpandStauts
): RenderItemBackground {
    if (expandStatus === VLayerPanelNodeExpandStauts.V_LAYER_PANEL_NODE_EXPAND_STAUTS_NONE) {
        return RenderItemBackground.None
    }
    if (renderItemBackground === RenderItemBackground.None) {
        return RenderItemBackground.Normal
    }
    return renderItemBackground
}

export const LayerPanelItemExpand: FC<LayerPanelItemExpandProps> = ({ expandStatus, id, renderItemBackground }) => {
    const command = useCommand()

    const getExpandIcon = useCallback(() => {
        switch (expandStatus) {
            case VLayerPanelNodeExpandStauts.V_LAYER_PANEL_NODE_EXPAND_STAUTS_NONE: {
                return null
            }
            case VLayerPanelNodeExpandStauts.V_LAYER_PANEL_NODE_EXPAND_STAUTS_EXPANDED: {
                return <MonoIconCommonEnterDown16 className="color-$wk-gray-6" />
            }
            case VLayerPanelNodeExpandStauts.V_LAYER_PANEL_NODE_EXPAND_STAUTS_UN_EXPANDED: {
                return <MonoIconCommonEnterRight16 className="color-$wk-gray-6" />
            }
        }
    }, [expandStatus])

    const handleMouseDown: MouseEventHandler<HTMLDivElement> = useCallback(
        (event) => {
            if (expandStatus === VLayerPanelNodeExpandStauts.V_LAYER_PANEL_NODE_EXPAND_STAUTS_NONE) {
                return
            }
            event.stopPropagation()
            if (event.altKey) {
                // Option 展开
                command.invokeBridge(CommitType.Noop, LayerPanelOptionExpandClickCommand, {
                    value: id,
                })
            } else {
                // 普通展开
                command.invokeBridge(CommitType.Noop, LayerPanelExpandClickCommand, {
                    value: id,
                })
            }
        },
        [command, id, expandStatus]
    )

    const expandIconClassName = useMemo<RenderItemBackground>(
        () => getRenderItemExpandBackground(renderItemBackground, expandStatus),
        [renderItemBackground, expandStatus]
    )

    return useMemo(
        () => (
            <div
                data-testid="layer-panel-item-expand"
                className={classNames(styles['render-item-expand'], styles[expandIconClassName])}
                onMouseDown={handleMouseDown}
            >
                {getExpandIcon()}
            </div>
        ),
        [expandIconClassName, getExpandIcon, handleMouseDown]
    )
}
