/* eslint-disable no-restricted-imports */
import { useCallback, useEffect, useMemo } from 'react'
import { CommitType } from '../../../../../document/command/commit-type'
import type { ColorStop } from '../../../../../document/node/node'
import { PureBlock } from '../../../atom/color-block/pure'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { ColorSpace } from '../color-picker/utils/color-translate'
import style from './gradient-block.module.less'

export interface GradientBlockProps {
    index: number
    selectedIndex: number
    selectedActive?: boolean
    colorStop: ColorStop
    colorSpace: ColorSpace
    onClickBlock?: (index: number, colorStop: ColorStop, altKey: boolean) => void
    onDeleteBlock?: (index: number, colorStop: ColorStop) => void
    onChangeIncrease?: (index: number, colorStop: ColorStop, options?: InputOptionsForUndoSquash) => void
    onChangeDecrease?: (index: number, colorStop: ColorStop, options?: InputOptionsForUndoSquash) => void
}
export function GradientBlock(props: GradientBlockProps) {
    const {
        index,
        selectedIndex,
        selectedActive,
        colorStop,
        colorSpace,
        onDeleteBlock,
        onClickBlock,
        onChangeIncrease,
        onChangeDecrease,
    } = props

    const isSelect = useMemo(() => {
        return selectedIndex === index
    }, [index, selectedIndex])

    const positionStyle = useMemo(() => {
        return { left: `${colorStop.position * 100}%` }
    }, [colorStop.position])

    const blockClassName = useMemo(() => {
        let _blockClassName = style.colorTipBlock
        if (isSelect) {
            _blockClassName += ' ' + style.select
            if (selectedActive) {
                _blockClassName += ' ' + style.active
            }
        }
        return _blockClassName
    }, [isSelect, selectedActive])

    const onPointerDownColorTip = useCallback(
        (e: any) => {
            onClickBlock?.(index, colorStop, e.altKey)
        },
        [onClickBlock, index, colorStop]
    )

    const onKeyDownPureBlock = useCallback(
        (e: any) => {
            if (!isSelect) {
                return
            }
            // backspace & delete
            if (e.keyCode === 8 || e.keyCode === 46) {
                e.stopPropagation()
                return onDeleteBlock?.(index, colorStop)
            }
            // ↑ & →
            if (e.keyCode === 38 || e.keyCode === 39) {
                e.stopPropagation()
                return onChangeIncrease?.(index, colorStop, { commitType: CommitType.CommitSquash })
            }
            // ↓ & ←
            if (e.keyCode === 40 || e.keyCode === 37) {
                e.stopPropagation()
                return onChangeDecrease?.(index, colorStop, { commitType: CommitType.CommitSquash })
            }
        },
        [isSelect, onDeleteBlock, index, colorStop, onChangeIncrease, onChangeDecrease]
    )

    useEffect(() => {
        document.addEventListener('keydown', onKeyDownPureBlock, false)
        return () => {
            document.removeEventListener('keydown', onKeyDownPureBlock)
        }
    }, [onKeyDownPureBlock])

    return (
        <div onPointerDown={onPointerDownColorTip} className={blockClassName} key={index} style={positionStyle}>
            <div className={style.arrow}></div>
            <PureBlock
                rgb={{ ...colorStop.color }}
                colorSpace={colorSpace}
                opacity={colorStop.color.a / 255}
                onKeyDown={onKeyDownPureBlock}
                dataTestId={`gradient-block-${index}`}
            />
        </div>
    )
}
