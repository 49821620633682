/* eslint-disable no-restricted-imports */
import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { useMount } from 'react-use'
import { InputV2, Tooltip, useEllipsisTooltip, useWindowResize, WKToast } from '../../../../../../ui-lib/src'
import { docTitleValidator } from '../../../../../../util/src'
import { RoleStatus } from '../../../../kernel/interface/type'
import { contentAuditError } from '../../../../kernel/request/error-handler'
import { SpaceLogs } from '../../../../kernel/service/space-frog-service/logs'
import { setHtmlTitleWithBrandSuffix } from '../../../../utils/html'
import { useViewState } from '../../../../view-state-bridge'
import { useDocInfoContext } from '../../../context/top-area-context'
import { TopAreaContext } from '../top-area-context'
import style from './tool-title.module.less'
import { compareRole } from '../../../../kernel/interface/user-roles'

export const DocNameV2 = ({ onClickName }: { onClickName: () => void }) => {
    const { docData } = useDocInfoContext()
    const docReadonly = useViewState('docReadonly')
    const { inactivation, ellipsisRef, onmouseenter, onmouseleave } = useEllipsisTooltip<HTMLDivElement>()

    const canEditName = useMemo(() => {
        if (docData) {
            return compareRole(docData.role, RoleStatus.Viewer) > 0 && !docReadonly
        }
        return false
    }, [docData, docReadonly])

    const _onEditStart = () => {
        if (canEditName) {
            SpaceLogs.FileOperations.clickNameToRenameFile()
            onClickName?.()
        }
    }

    useEffect(() => {
        if (docData?.name) {
            setHtmlTitleWithBrandSuffix(docData?.name)
        }
    }, [docData?.name])

    return (
        <span data-testid="doc-name" onClick={_onEditStart} className={style.docNameContainer}>
            <Tooltip title={docData?.name} inactivation={inactivation}>
                <div
                    className={style.content}
                    ref={ellipsisRef}
                    onMouseEnter={onmouseenter}
                    onMouseLeave={onmouseleave}
                >
                    {docData?.name}
                </div>
            </Tooltip>
        </span>
    )
}

export const DocNameInput = ({ onEditDone }: { onEditDone: () => void }) => {
    const { docData, updateDocName, fetchDocData } = useDocInfoContext()
    const { editorTopBarRef } = useContext(TopAreaContext)
    const ref = useRef<HTMLInputElement>(null)

    const saveHandler = useCallback(
        async (name: string) => {
            name = name.trim()
            try {
                if (name !== docData?.name) {
                    await docTitleValidator(name)
                    await updateDocName(name)
                    await fetchDocData()
                }
                onEditDone()
            } catch (e: any) {
                const msg = e?.req ? contentAuditError(e) : e?.message
                if (msg) {
                    WKToast.error(msg)
                }
                onEditDone()
            }
        },
        [docData?.name, onEditDone, updateDocName, fetchDocData]
    )

    const adjustRenameInputStyle = useCallback(() => {
        if (!editorTopBarRef?.current || !ref.current) {
            return
        }
        const { getLeftElement, getRightElement, getCenterContentElement } = editorTopBarRef.current
        const leftElement = getLeftElement()
        const rightElement = getRightElement()
        const centerContentElement = getCenterContentElement()
        if (!leftElement || !rightElement || !centerContentElement) {
            return
        }
        centerContentElement.style.margin = '0px'
        const string2Number = (s: string) => {
            let n = 0
            return s ? ((n = parseInt(s)), isNaN(n) ? 0 : n) : 0
        }
        const leftStyle = window.getComputedStyle(leftElement)
        const rightStyle = window.getComputedStyle(rightElement)
        const leftWidth =
            leftElement.getBoundingClientRect().width +
            string2Number(leftStyle.marginLeft) +
            string2Number(leftStyle.marginRight)
        const rightWidth =
            rightElement.getBoundingClientRect().width +
            string2Number(rightStyle.marginLeft) +
            string2Number(rightStyle.marginRight)
        const inputWidth = Math.max(100, window.document.body.clientWidth - leftWidth - rightWidth)
        ref.current.style.boxSizing = 'border-box'
        ref.current.style.width = inputWidth + 'px'
        const addPadding = Math.min(leftWidth - rightWidth, inputWidth)
        if (addPadding > 0) {
            ref.current.style.paddingRight = addPadding + 'px'
        } else if (addPadding < 0) {
            ref.current.style.paddingLeft = addPadding + 'px'
        }
        const inputScrollWidth = ref.current.scrollWidth - ref.current.clientWidth
        if (inputScrollWidth < 1) {
            return
        }
        const newAddPadding = Math.max(0, addPadding - inputScrollWidth)
        if (addPadding > 0) {
            ref.current.style.paddingRight = newAddPadding + 'px'
        } else if (addPadding < 0) {
            ref.current.style.paddingLeft = newAddPadding + 'px'
        } else {
            ref.current.style.paddingRight = ''
            ref.current.style.paddingLeft = ''
        }
    }, [editorTopBarRef])

    useWindowResize(adjustRenameInputStyle)

    useLayoutEffect(adjustRenameInputStyle, [adjustRenameInputStyle])

    useMount(() => {
        ref.current?.focus()
        ref.current?.select()
    })

    return (
        <InputV2.Html
            className={style.input}
            ref={ref}
            defaultValue={docData?.name}
            onInput={adjustRenameInputStyle}
            onBlur={(event) => saveHandler(event.target.value)}
            onKeyDown={(e) => {
                if (e.code === 'Enter') {
                    saveHandler((e.target as HTMLInputElement).value)
                } else if (e.code === 'Escape') {
                    onEditDone()
                }
            }}
            data-testid="doc-name-input"
            autoComplete="off"
            autoCorrect="false"
            autoCapitalize="false"
            spellCheck="false"
        />
    )
}
