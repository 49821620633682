import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Button: '按钮',
    Badge: '徽标',
    List: '列表',
    Tag: '标签',
    Input: '输入框',
    Checkbox: '多选框',
    Switch: '开关',
    Card: '卡片',
    Toast: '轻提示',
    TabBar: '选项卡',
    'Navigation Bar': '导航栏',
    Tabs: '标签栏',
    Select: '选择器',
    Avatar: '头像',
    Dialog: '对话框',
    Others: '其他',
    yaesoL: '组件名称',
    OZXHpR: '请输入内容',
    FRVSnh: '请在手机尺寸内画框',
    tguavM: '没有找到匹配的内容',
    xhqXyh: '请尝试更换关键词搜索',
    ShortCut: '按下 / 键，可唤起组件输入',
    All: '全部',
} as const

export const enTranslation = {
    Button: 'Button',
    Badge: 'Badge',
    List: 'List',
    Tag: 'Tag',
    Input: 'Input',
    Checkbox: 'Checkbox',
    Switch: 'Switch',
    Card: 'Card',
    Toast: 'Toast',
    TabBar: 'Tab Bar',
    'Navigation Bar': 'Navigation Bar',
    Tabs: 'Tabs',
    Select: 'Select',
    Avatar: 'Avatar',
    Dialog: 'Dialog',
    Others: 'Others',
    yaesoL: 'Components',
    OZXHpR: 'Write contents here',
    FRVSnh: 'Click and drag within a mobile phone-sized frame.',
    tguavM: 'No result',
    xhqXyh: 'try agian',
    ShortCut: "Press '/' for components",
    All: 'All',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
