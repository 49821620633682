import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    HelpAndAccount: '帮助与账号',
    HelpPage: '帮助文档',
    VideoTutorials: '视频教程',
    KeyboardShortcuts: '快捷键指南',
    ResetOnboarding: '重置新手引导',
    SubmitFeedback: '用户反馈',
    SendFeedback: '用户反馈',
    ReportAnIssue: '发送错误报告',
    TermsOfService: '服务协议',
    PrivacyPolicy: '隐私政策',
    AccountSettings: '账号管理',
    LogOut: '退出登录',
    ReleaseNotes: '更新日志',
} as const

export const enTranslation = {
    HelpAndAccount: 'Help and account',
    HelpPage: 'Help center',
    VideoTutorials: ' Video tutorials',
    KeyboardShortcuts: 'Keyboard shortcuts',
    ResetOnboarding: 'Reset onboarding',
    SubmitFeedback: 'Submit feedback',
    SendFeedback: 'Send feedback',
    ReportAnIssue: 'Report an issue',
    TermsOfService: 'Terms of Service',
    PrivacyPolicy: 'Privacy Policy',
    AccountSettings: 'Account settings',
    LogOut: 'Log out',
    ReleaseNotes: 'Release notes',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
