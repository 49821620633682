import { translation } from './advance.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { useMemo, useState } from 'react'
import { DraggablePopupV2, IconOptionOn, Select } from '../../../../../../../../../ui-lib/src'
import { IconGroup } from '../../../../../atom/button/icon-group'
import { SingleGrid } from '../../../../../atom/grid/single-grid'
import { useAutoLayoutCommand } from '../command'
import * as icons from '../icons'
import { AutoLayoutState, type AdvanceState, type ValidAdvanceItem } from '../types'
import { useDerivedState } from '../utils'
import style from './advance.module.less'

type AdvanceItem = 'none' | ValidAdvanceItem
interface Position {
    left: number
    top: number
}

type OptionValue = 'enabled' | 'disabled' | 'mixed'

export const advanceTitles: { [k in ValidAdvanceItem]: string } = {
    isReversedZIndex: translation('CanvasStacking'),
    isAlignToTextBaseline: translation('AlignTextBaseline'),
}

function useAdvanceOption(
    state: AdvanceState,
    key: ValidAdvanceItem,
    [enabled, disbaled, mixed]: [string?, string?, string?],
    setHoveringItem: (_: AdvanceItem) => void
) {
    const value: OptionValue = state[key].isMixed ? 'mixed' : state[key].value ? 'enabled' : 'disabled'
    const names: Record<OptionValue, string> = {
        enabled: enabled ?? '',
        disabled: disbaled ?? '',
        mixed: mixed ?? '',
    }
    const [hoverOptionItem, setHoverOptionItem] = useDerivedState<OptionValue>(value)
    const hoverOption = useMemo(
        () => ({
            active: hoverOptionItem === 'enabled',
            update: (item: OptionValue) => {
                setHoverOptionItem(item)
            },
            reset: () => {
                setHoverOptionItem(value)
            },
            valid: hoverOptionItem != 'mixed',
        }),
        [hoverOptionItem, setHoverOptionItem, value]
    )

    const hoverSelf = {
        onMouseEnter: () => setHoveringItem(key),
        onMouseLeave: () => setHoveringItem('none'),
    }

    return {
        title: advanceTitles[key],
        value,
        names,
        options: ['disabled', 'enabled'] as OptionValue[],
        preview: value !== 'mixed',
        hoverOption,
        hoverSelf,
    }
}

function useAdvanceOptions(state: AutoLayoutState): {
    hoveringItem: AdvanceItem
    disableAlignToTextBaseline: boolean
} & {
    [k in ValidAdvanceItem]: ReturnType<typeof useAdvanceOption>
} {
    const [hoveringItem, setHoverItem] = useState<AdvanceItem>('none')
    const disableAlignToTextBaseline = ![
        Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_HORIZONTAL,
        Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_WRAP,
    ].includes(state.flex.stackMode)

    const ret = {
        hoveringItem,
        disableAlignToTextBaseline,
        isReversedZIndex: useAdvanceOption(
            state.advance,
            'isReversedZIndex',
            [translation('FirstOnTop'), translation('LastOnTop'), translation('Mixed')],
            setHoverItem
        ),
        isAlignToTextBaseline: useAdvanceOption(state.advance, 'isAlignToTextBaseline', [], setHoverItem),
    }

    if (disableAlignToTextBaseline) {
        ret.isAlignToTextBaseline.value = 'disabled'
    }

    ret.isReversedZIndex.options.reverse()

    return ret
}

interface PreviewProps {
    active: boolean
}

function PreviewZIndex(props: PreviewProps) {
    return (
        <div
            className={props.active ? style.previewZIndexActive : style.previewZIndex}
            data-testid="preview-isReversedZIndex"
        >
            {[icons.svgZIndexOne, icons.svgZIndexTwo, icons.svgZIndexThree].map((icon, i) => (
                <div className={style.zIndexItem} key={i}>
                    {icon}
                </div>
            ))}
        </div>
    )
}

function PreviewBaseline(props: PreviewProps) {
    return (
        <div
            className={props.active ? style.previewBaselineActive : style.previewBaseline}
            data-testid="preview-isAlignToTextBaseline"
        >
            {[icons.svgBaselineA, '', icons.svgBaselineG].map((x, i) => (
                <div key={i} className={style.baselineItem}>
                    {x}
                </div>
            ))}
        </div>
    )
}

const baselineIcons = {
    enabled: <IconOptionOn />,
    disabled: icons.svgSwitchNone,
    mixed: null,
}
export function AutoLayoutAdvance(props: {
    isOpen: boolean
    close: () => void
    state: AutoLayoutState
    position?: Position
    disabled?: boolean
}) {
    const options = useAdvanceOptions(props.state)
    const command = useAutoLayoutCommand(props.state)

    const shouldPreview = (item: ValidAdvanceItem) => {
        return options.hoveringItem === item && options[item].hoverOption.valid
    }

    return (
        <DraggablePopupV2
            visible={props.isOpen}
            onCancel={props.close}
            position={props.position}
            header={translation('AdvancedAutoLayout')}
            footer={null}
            positionRightBase
            bodyClassName={`p-0 ${style.advance}`}
            styleType="editor"
        >
            <div className={style.advanceBody}>
                <div className={style.advancePreview}>
                    {shouldPreview('isReversedZIndex') ? (
                        <PreviewZIndex active={options.isReversedZIndex.hoverOption.active} />
                    ) : shouldPreview('isAlignToTextBaseline') ? (
                        <PreviewBaseline active={options.isAlignToTextBaseline.hoverOption.active} />
                    ) : (
                        <div className={style.noPreview}>{translation('Preview')}</div>
                    )}
                </div>

                <div className={style.advanceItems}>
                    {(['isReversedZIndex'] as ValidAdvanceItem[]).map((item) => {
                        const advanceItem = options[item]
                        return (
                            <SingleGrid {...advanceItem.hoverSelf} key={item} testId={item}>
                                <SingleGrid.Item start={5} span={28}>
                                    {advanceItem.title}
                                </SingleGrid.Item>
                                <SingleGrid.Item start={34} span={23}>
                                    <Select.NormalSingleLevel
                                        value={advanceItem.value}
                                        dataTestIds={{ triggerFocus: `${item}-value` }}
                                        minWidth={104}
                                        label={advanceItem.names[advanceItem.value]}
                                        disabled={props.disabled}
                                    >
                                        {options[item].options.map((optionItem) => (
                                            <Select.NormalSingleLevel.Option
                                                data-testid={`${item}-${optionItem}`}
                                                value={optionItem}
                                                key={optionItem}
                                                onMouseOver={() => advanceItem.hoverOption.update(optionItem)}
                                                onMouseOut={() => advanceItem.hoverOption.reset()}
                                                onClick={() => command.setAdvance(item, optionItem === 'enabled')}
                                            >
                                                {advanceItem.names[optionItem]}
                                            </Select.NormalSingleLevel.Option>
                                        ))}
                                    </Select.NormalSingleLevel>
                                </SingleGrid.Item>
                            </SingleGrid>
                        )
                    })}
                    <SingleGrid {...options.isAlignToTextBaseline.hoverSelf} testId="isAlignToTextBaseline">
                        <SingleGrid.Item start={5} span={28}>
                            {options.isAlignToTextBaseline.title}
                        </SingleGrid.Item>
                        <SingleGrid.Item start={43} span={14}>
                            <IconGroup
                                dataTestId="isAlignToTextBaseline-value"
                                disabled={props.disabled || options.disableAlignToTextBaseline}
                            >
                                {options.isAlignToTextBaseline.options.map((item) => {
                                    return (
                                        <IconGroup.Item
                                            dataTestId={`isAlignToTextBaseline${item}`}
                                            icon={baselineIcons[item]}
                                            key={item}
                                            onClick={() => {
                                                if (options.disableAlignToTextBaseline) return
                                                command.setAdvance('isAlignToTextBaseline', item === 'enabled')
                                            }}
                                            onMouseOver={() => options.isAlignToTextBaseline.hoverOption.update(item)}
                                            onMouseOut={() =>
                                                options.isAlignToTextBaseline.hoverOption.update(
                                                    options.isAlignToTextBaseline.value
                                                )
                                            }
                                            selected={item === options.isAlignToTextBaseline.value}
                                        />
                                    )
                                })}
                            </IconGroup>
                        </SingleGrid.Item>
                    </SingleGrid>
                </div>
            </div>
        </DraggablePopupV2>
    )
}
