import constate from 'constate'
import { domLocation, generateRouterPath, RouteToken } from '../../../../../util/src'
import { useOnSync } from '../../../kernel/util/hooks/use-sync-channel'
import { SessionStorageKey } from '../../../web-storage/session-storage/config/index'

const useSyncUserBetweenPages = () => {
    const [syncLogin] = useOnSync<number>(SessionStorageKey.UserTriggerLogin, {
        onMessage: (msg) => {
            if (msg === 1) {
                domLocation().replace(`/${generateRouterPath(RouteToken.Recent)}`)
            }
        },
    })

    const [syncLogout] = useOnSync<number>(SessionStorageKey.UserTriggerLogin, {
        save: true,
        onMessage: (msg) => {
            if (msg === 1) {
                domLocation().replace(`/${RouteToken.LoginDoNotUse}`)
            }
        },
    })

    return { syncLogin, syncLogout } as const
}

export const [SyncUserProvider, useSyncUser] = constate(useSyncUserBetweenPages, (ctx) => ctx)
