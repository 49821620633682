import { translation } from './view-menu-config.translation'
/* eslint-disable no-restricted-imports */
import {
    ToggleEnabledLayoutGridWasmCall,
    ToggleEnablePixelGridWasmCall,
    ToggleShowCommentCommand,
    ToggleShowMultiplayerCursorWasmCall,
    ToggleShowRulerWasmCall,
    ToggleShowSidebarCommandForJs,
    ToggleSnapToPixelWasmCall,
} from '@wukong/bridge-proto'
import { useMemo } from 'react'
import { WKItemType, WKMenuConfig } from '../../../../../../ui-lib/src'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import { cmdToggleFullscreen } from '../../../../document/command/fullscreen-command'
import {
    cmdZoomAtCameraCenter,
    cmdZoomInByLevelAtCameraCenter,
    cmdZoomOutByLevelAtCameraCenter,
    cmdZoomToAll,
} from '../../../../document/command/viewport-command'
import { useViewState } from '../../../../view-state-bridge'
import { generateSubMenuConfigStatic, generateSubMenuConfigWithActivity } from './menu-config/util'
import { ShortcutKey, shortcutLabelMap } from '../../../../kernel/interface/shortcut-key'

export function useDesignModeViewMenuConfig(commandInvoker: CommandInvoker): WKMenuConfig {
    const globalState = useViewState('globalState')

    const fullscreen = !!globalState?.fullscreen
    const showSidebar = !!globalState?.showSidebar
    const snapToPixel = !!globalState?.snapToPixel
    const enablePixelGrid = !!globalState?.enablePixelGrid
    const showRuler = globalState?.showRuler

    const showComment = !!useViewState('showComments')
    const showMultiplayerCursor = !!useViewState('showMultiplayerCursor')
    const showLayoutgrid = !!globalState?.enableLayoutGrid

    return useMemo<WKMenuConfig>(
        () => [
            generateSubMenuConfigStatic(
                commandInvoker,
                translation('ZoomIn'),
                shortcutLabelMap[ShortcutKey.ZoomIn],
                false,
                cmdZoomInByLevelAtCameraCenter
            ),
            generateSubMenuConfigStatic(
                commandInvoker,
                translation('ZoomOut'),
                shortcutLabelMap[ShortcutKey.ZoomOut],
                false,
                cmdZoomOutByLevelAtCameraCenter
            ),
            generateSubMenuConfigStatic(
                commandInvoker,
                `${translation('ZoomTo')} 50%`,
                '',
                false,
                cmdZoomAtCameraCenter,
                0.5
            ),
            generateSubMenuConfigStatic(
                commandInvoker,
                `${translation('ZoomTo')} 100%`,
                shortcutLabelMap[ShortcutKey.ZoomTo100Percent],
                false,
                cmdZoomAtCameraCenter,
                1
            ),
            generateSubMenuConfigStatic(
                commandInvoker,
                `${translation('ZoomTo')} 200%`,
                '',
                false,
                cmdZoomAtCameraCenter,
                2
            ),
            generateSubMenuConfigStatic(
                commandInvoker,
                translation('ZoomToFit'),
                shortcutLabelMap[ShortcutKey.ZoomToFit],
                false,
                cmdZoomToAll
            ),
            { type: WKItemType.Divider },
            {
                name: translation('SnapToPixels'),
                shortCut: shortcutLabelMap[ShortcutKey.SnapToPixelGrid],
                activity: snapToPixel,
                disable: false,
                handler: () => commandInvoker.DEPRECATED_invokeBridge(ToggleSnapToPixelWasmCall),
            },
            {
                name: translation('PixelGrid'),
                shortCut: shortcutLabelMap[ShortcutKey.PixelGrid],
                activity: enablePixelGrid,
                disable: false,
                handler: () => commandInvoker.DEPRECATED_invokeBridge(ToggleEnablePixelGridWasmCall),
            },
            {
                name: translation('LayoutGrids'),
                shortCut: shortcutLabelMap[ShortcutKey.LayoutGrid],
                activity: showLayoutgrid,
                disable: false,
                handler: () => commandInvoker.DEPRECATED_invokeBridge(ToggleEnabledLayoutGridWasmCall),
            },
            {
                name: translation('Rulers'),
                shortCut: shortcutLabelMap[ShortcutKey.Rulers],
                activity: showRuler,
                disable: false,
                handler: () => commandInvoker.DEPRECATED_invokeBridge(ToggleShowRulerWasmCall),
            },
            { type: WKItemType.Divider },
            generateSubMenuConfigWithActivity(
                false,
                commandInvoker,
                translation('Comments'),
                shortcutLabelMap[ShortcutKey.ShowComment],
                showComment,
                true,
                ToggleShowCommentCommand
            ),
            {
                name: translation('MultiplayerCursors'),
                shortCut: shortcutLabelMap[ShortcutKey.ShowMultiplayerCursor],
                activity: showMultiplayerCursor,
                disable: false,
                handler: () => commandInvoker.DEPRECATED_invokeBridge(ToggleShowMultiplayerCursorWasmCall),
            },
            generateSubMenuConfigWithActivity(
                false,
                commandInvoker,
                translation('ShowUI'),
                shortcutLabelMap[ShortcutKey.ToggleFullScreen],
                !fullscreen,
                false,
                cmdToggleFullscreen
            ),
            {
                name: translation('ShowLeftSidebar'),
                shortCut: shortcutLabelMap[ShortcutKey.ShowLeftSidebar],
                activity: showSidebar,
                disable: false,
                handler: () => commandInvoker.DEPRECATED_invokeBridge(ToggleShowSidebarCommandForJs),
            },
        ],
        [
            commandInvoker,
            snapToPixel,
            enablePixelGrid,
            showRuler,
            showLayoutgrid,
            showComment,
            showMultiplayerCursor,
            fullscreen,
            showSidebar,
        ]
    )
}

export function useDevModeViewMenuConfig(commandInvoker: CommandInvoker): WKMenuConfig {
    const globalState = useViewState('globalState')

    const fullscreen = !!globalState?.fullscreen
    const showSidebar = !!globalState?.showSidebar
    const enablePixelGrid = !!globalState?.enablePixelGrid
    const showRuler = globalState?.showRuler

    const showComment = !!useViewState('showComments')
    const showMultiplayerCursor = !!useViewState('showMultiplayerCursor')
    const showLayoutgrid = !!globalState?.enableLayoutGrid

    return useMemo<WKMenuConfig>(
        () => [
            generateSubMenuConfigStatic(
                commandInvoker,
                translation('ZoomIn'),
                shortcutLabelMap[ShortcutKey.ZoomIn],
                false,
                cmdZoomInByLevelAtCameraCenter
            ),
            generateSubMenuConfigStatic(
                commandInvoker,
                translation('ZoomOut'),
                shortcutLabelMap[ShortcutKey.ZoomOut],
                false,
                cmdZoomOutByLevelAtCameraCenter
            ),
            generateSubMenuConfigStatic(
                commandInvoker,
                `${translation('ZoomTo')} 50%`,
                '',
                false,
                cmdZoomAtCameraCenter,
                0.5
            ),
            generateSubMenuConfigStatic(
                commandInvoker,
                `${translation('ZoomTo')} 100%`,
                shortcutLabelMap[ShortcutKey.ZoomTo100Percent],
                false,
                cmdZoomAtCameraCenter,
                1
            ),
            generateSubMenuConfigStatic(
                commandInvoker,
                `${translation('ZoomTo')} 200%`,
                '',
                false,
                cmdZoomAtCameraCenter,
                2
            ),
            generateSubMenuConfigStatic(
                commandInvoker,
                translation('ZoomToFit'),
                shortcutLabelMap[ShortcutKey.ZoomToFit],
                false,
                cmdZoomToAll
            ),
            { type: WKItemType.Divider },
            {
                name: translation('PixelGrid'),
                shortCut: shortcutLabelMap[ShortcutKey.PixelGrid],
                activity: enablePixelGrid,
                disable: false,
                handler: () => commandInvoker.DEPRECATED_invokeBridge(ToggleEnablePixelGridWasmCall),
            },
            {
                name: translation('LayoutGrids'),
                shortCut: shortcutLabelMap[ShortcutKey.LayoutGrid],
                activity: showLayoutgrid,
                disable: false,
                handler: () => commandInvoker.DEPRECATED_invokeBridge(ToggleEnabledLayoutGridWasmCall),
            },
            {
                name: translation('Rulers'),
                shortCut: shortcutLabelMap[ShortcutKey.Rulers],
                activity: showRuler,
                disable: false,
                handler: () => commandInvoker.DEPRECATED_invokeBridge(ToggleShowRulerWasmCall),
            },
            { type: WKItemType.Divider },
            generateSubMenuConfigWithActivity(
                false,
                commandInvoker,
                translation('Comments'),
                shortcutLabelMap[ShortcutKey.ShowComment],
                showComment,
                true,
                ToggleShowCommentCommand
            ),
            {
                name: translation('MultiplayerCursors'),
                shortCut: shortcutLabelMap[ShortcutKey.ShowMultiplayerCursor],
                activity: showMultiplayerCursor,
                disable: false,
                handler: () => commandInvoker.DEPRECATED_invokeBridge(ToggleShowMultiplayerCursorWasmCall),
            },
            generateSubMenuConfigWithActivity(
                false,
                commandInvoker,
                translation('ShowUI'),
                shortcutLabelMap[ShortcutKey.ToggleFullScreen],
                !fullscreen,
                false,
                cmdToggleFullscreen
            ),
            {
                name: translation('ShowLeftSidebar'),
                shortCut: shortcutLabelMap[ShortcutKey.ShowLeftSidebar],
                activity: showSidebar,
                disable: false,
                handler: () => commandInvoker.DEPRECATED_invokeBridge(ToggleShowSidebarCommandForJs),
            },
        ],
        [
            commandInvoker,
            enablePixelGrid,
            showRuler,
            showLayoutgrid,
            showComment,
            showMultiplayerCursor,
            fullscreen,
            showSidebar,
        ]
    )
}
