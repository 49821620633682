/* eslint-disable no-restricted-imports */
import { GetBestFullFontNameCommand, Wukong } from '@wukong/bridge-proto'
import { useEffect, useState } from 'react'
import { FontInfo, FontName, FontWeight, FontWidth } from '../../../../document/node/node'
import { getFontNameUid, getFontNameUidSet, isEqualFontStyle } from '../../../../document/util/font'
import { useCommand } from '../../../../main/app-context'
import { MissFont } from './interface'

function findBestFontName(missFontName: FontName, fontInfo: FontInfo): FontName {
    const matchedFontName = fontInfo.styles.find((fontName) => isEqualFontStyle(fontName, missFontName))
    if (matchedFontName) {
        return matchedFontName
    }

    return (
        fontInfo.styles.find(
            (fontName) =>
                fontName.weight === FontWeight.NormalWeight &&
                !fontName.italic &&
                fontName.width === FontWidth.NormalWidth
        ) ?? fontInfo.styles[0]
    )
}

/**
 * 替换字体面板状态hook, 管理替换 被替换字体状态
 */
export function useFontMiss(
    missFont: MissFont,
    fontInfos: FontInfo[],
    uid2FullFontName: Record<string, Wukong.DocumentProto.IFullFontName>
) {
    const [targetFontNames, setTargetFontNames] = useState<MissFont>({})
    const command = useCommand()

    const setTargetFontName = (missFontNameUid: string, fontName: FontName) => {
        setTargetFontNames((old) => ({ ...old, [missFontNameUid]: fontName }))
    }

    const setTargetFontFamily = (missFontNameUid: string, family: string) => {
        const targetFontInfo = fontInfos.find((info) => info.family === family)
        if (!targetFontInfo) {
            return
        }

        const missFamily = missFont[missFontNameUid].family
        Object.entries(missFont).forEach(([uid, fontName]) => {
            if (missFamily == fontName.family) {
                const { fullFontName } = command.DEPRECATED_invokeBridge(GetBestFullFontNameCommand, {
                    family,
                    fontName: fontName,
                    fullFontName: uid2FullFontName[uid],
                })
                if (fullFontName) {
                    // NOTE: wasm返回的FullFontName不包含localizedFamily locolizedStyle, 需要映射为字体列表中的FontName
                    const targetFontName = targetFontInfo.styles.find(
                        (item) => item.family == fullFontName.family && item.style == fullFontName.style
                    )
                    targetFontName && setTargetFontName(uid, targetFontName)
                }
            }
        })
    }

    // missFont、fontInfos变化时清理状态, 避免状态不一致
    useEffect(() => {
        setTargetFontNames((value) => {
            const missFontUidSet = new Set(Object.keys(missFont))
            const fontNameUidSet = getFontNameUidSet(fontInfos)
            return Object.fromEntries(
                Object.entries(value).filter(([uid, fontName]) => {
                    return missFontUidSet.has(uid) && fontNameUidSet.has(getFontNameUid(fontName))
                })
            )
        })
    }, [missFont, fontInfos])

    return { targetFontNames, setTargetFontFamily, setTargetFontName }
}
