import { isNumber } from 'lodash-es'
import { useCallback } from 'react'
import { NavigateOptions, useNavigate } from 'react-router'
import { generateRouterPath } from '../route'

export const useWKNavigate = () => {
    const _navigate = useNavigate()
    const navigate = useCallback(
        (param: string | number, options?: NavigateOptions) => {
            if (isNumber(param)) {
                _navigate(param)
            } else {
                let link = param
                if (link.startsWith('/')) {
                    link = link.replace(/^\//, '')
                }
                _navigate(`/${generateRouterPath(link)}`, options)
            }
        },
        [_navigate]
    )
    return navigate
}
