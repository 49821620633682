import type { Wukong } from '@wukong/bridge-proto'
import { AnimationEasingTypeSelect } from '../animation-easing-type/animation-easing-type'
import { AnimationTransitionDuration } from '../animation-transition-duration/animation-transition-duration'
import classes from '../interaction-action-animation.module.less'
import type { AnimationTransitionTypeCommandProps } from '../use-action-animation-commands'

export function AnimationEasingTypeAndDuration(props: {
    easingType: Wukong.DocumentProto.IEasingTypePropValue
    transitionDuration: Wukong.DocumentProto.ITransitionDurationPropValue
    commandProps: AnimationTransitionTypeCommandProps
}) {
    return props.easingType.show || props.transitionDuration.show ? (
        <div className={classes.animationContainer}>
            <AnimationEasingTypeSelect state={props.easingType} commandProps={props.commandProps} />
            <AnimationTransitionDuration state={props.transitionDuration} commandProps={props.commandProps} />
        </div>
    ) : (
        <></>
    )
}
