import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    InnerShadow: '内阴影',
    DropShadow: '外阴影',
    LayerBlur: '图层模糊',
    BackgroundBlur: '背景模糊',
    EffectSettings: '效果设置',
} as const

export const enTranslation = {
    InnerShadow: 'Inner shadow',
    DropShadow: 'Drop shadow',
    LayerBlur: 'Layer blur',
    BackgroundBlur: 'Background blur',
    EffectSettings: 'Effect settings',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
