export enum SwitchEnvironmentScopeType {
    ALL = 'All',
    TESTING = 'Testing',
    PRODUCTION = 'Production',
}

export enum UserGroupType {
    RD = '研发',
    PM = '产品',
    QA = '测试',
    UX = '设计',
    MO = '运营',
    MOTIFF_GROUP = 'Motiff 团队',
    MONITOR_ALL = '大监控团队All',
    MONITOR_RD = '大监控-研发',
    MONITOR_PM = '大监控-产品',
    MONITOR_UX = '大监控-设计',
    BASIC_RD_ALL = '基础研发团队All',
    CYPRESS = 'Cypress 测试账号',
    ODA = '猿印',
    AI_LAB = 'AI Lab',
    FENBI = '粉笔',
    INLAND_REST_ORG_USER = '零碎邮箱后缀的国内企业付费用户',
}

export enum SwitchStrategyType {
    ALL_USER = '全部用户',
    SPECIFIC_SUFFIX = '指定邮箱后缀',
    SPECIFIC_USER_LIST = '指定用户列表',
    PERCENTAGE_RANDOM = '用户百分比',
}

interface AllUserConfig {
    type: SwitchStrategyType.ALL_USER
}

interface UserGroupConfig {
    type: SwitchStrategyType.SPECIFIC_USER_LIST
    group: UserGroupType
}

interface SpecificSuffixConfig {
    type: SwitchStrategyType.SPECIFIC_SUFFIX
    suffix: string[]
}

interface SpecificUserListConfig {
    type: SwitchStrategyType.SPECIFIC_USER_LIST
    userList: string[]
}

type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
    ? Acc[number]
    : Enumerate<N, [...Acc, Acc['length']]>
type IntRange<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>

interface PercentageRandomConfig {
    type: SwitchStrategyType.PERCENTAGE_RANDOM
    percent: IntRange<0, 101>
}

type SwitchStrategyConfig =
    | AllUserConfig
    | UserGroupConfig
    | SpecificSuffixConfig
    | SpecificUserListConfig
    | PercentageRandomConfig

export interface SwitchStrategy {
    config: SwitchStrategyConfig
    env: SwitchEnvironmentScopeType
}
export interface FeatureSwitchConfig {
    name: string
    owner: string
    description: string
    strategies: SwitchStrategy[]
    alwaysDisableInTests: boolean
    isRolledOut?: boolean
    isExternalized?: boolean
}

// 开关是否已经全量
export function isFeatureSwitchRolledOut(config: FeatureSwitchConfig) {
    return (
        config.strategies &&
        config.strategies.some((strategy) => {
            return (
                strategy.config.type === SwitchStrategyType.ALL_USER &&
                [SwitchEnvironmentScopeType.ALL, SwitchEnvironmentScopeType.PRODUCTION].includes(strategy.env)
            )
        })
    )
}

// 开关是否已经对外部用户生效
export function isFeatureSwitchExternalized(config: FeatureSwitchConfig) {
    return (
        config.strategies &&
        config.strategies.some((strategy) => {
            if (strategy.env === SwitchEnvironmentScopeType.TESTING) {
                return false
            }
            switch (strategy.config.type) {
                case SwitchStrategyType.ALL_USER:
                    return true
                case SwitchStrategyType.SPECIFIC_USER_LIST:
                    if ('userList' in strategy.config) {
                        return strategy.config.userList.some((user) => !user.endsWith('@kanyun.com'))
                    }
                    if ('group' in strategy.config) {
                        return ![
                            UserGroupType.AI_LAB,
                            UserGroupType.CYPRESS,
                            UserGroupType.MO,
                            UserGroupType.MOTIFF_GROUP,
                            UserGroupType.RD,
                            UserGroupType.PM,
                            UserGroupType.QA,
                            UserGroupType.UX,
                        ].includes(strategy.config.group)
                    }
                    return false
                case SwitchStrategyType.SPECIFIC_SUFFIX:
                    return true
                case SwitchStrategyType.PERCENTAGE_RANDOM:
                    return strategy.config.percent > 0
            }
        })
    )
}
