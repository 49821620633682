import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    PublishLibrary: '发布更新',
    PublishLibrary_synonyms1: '发布组件库',
    DescriptionOfChanges: '请输入更新描述',
    Selected: '已选中',
    Cancel: '取消',
    Publish: '发布',
    Search: '搜索',
    NoResultsFor: '无“{{filterText}}”搜索结果',
    Publish2: '发布更新',
} as const

export const enTranslation = {
    PublishLibrary: 'Publish library',
    PublishLibrary_synonyms1: 'Publish library',
    DescriptionOfChanges: 'Description of changes (optional)',
    Selected: 'Selected',
    Cancel: 'Cancel',
    Publish: 'Publish',
    Search: 'Search',
    NoResultsFor: "No results for '{{filterText}}'",
    Publish2: 'Publish',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
