import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    loadError: '页面加载出错',
    getHelp: '请重试或联系我们以获得进一步帮助',
    refresh: '刷新页面',
    sendReport: '发送错误报告',
    visit: '访问我们的',
    helpCenter: '帮助页面',
} as const

export const enTranslation = {
    loadError: 'Something went wrong',
    getHelp: 'Please try again or get in touch with our team for further help',
    refresh: 'Reload this page',
    sendReport: 'Report an issue',
    visit: 'Visit our',
    helpCenter: ' Help page',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
