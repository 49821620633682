import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    LearnMore: '了解更多',
} as const

export const enTranslation = {
    LearnMore: 'Learn more',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
