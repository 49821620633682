import { translation } from './canvas-setting-button.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { ReactElement } from 'react'
import {
    DropdownDefaultCustomNode,
    DropdownV2,
    MonoIconCommonAll16,
    MonoIconCommonMore16,
    MonoIconLayerComponent16,
    MonoIconLayerExampleImage16,
    MonoIconLayerExamplePolygon16,
    MonoIconLayerFrame16,
    MonoIconLayerInstance16,
    MonoIconLayerType16,
    MonoIconPanelAdjust16,
    Tooltip,
} from '../../../../../ui-lib/src'
import { DeepRequired } from '../../../view-state-bridge'
import styles from './canvas-setting-button.module.less'
import { CanvasSearchParamModel, CanvasSearchResultModel } from './use-canvas-search-model'

enum SettingKey {
    SearchMode,
    ReplaceMode,
    AllNode,
    TextNode,
    FrameNode,
    ComponentNode,
    InstanceNode,
    ImageNode,
    ShapeNode,
    OtherNode,
    MatchCase,
    WholeWords,
}

type CountKey = keyof DeepRequired<Wukong.DocumentProto.ICanvasSearchResultCountSummary>
interface NodeTypeFilterSettingItem {
    key: SettingKey
    nodeTypeFilter: Wukong.DocumentProto.CanvasSearchNodeTypeFilter
    label: string
    icon: ReactElement
    countKey: CountKey
}

const NodeTypeFilterSettingItemConfig: NodeTypeFilterSettingItem[] = [
    {
        key: SettingKey.AllNode,
        nodeTypeFilter: Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_ALL,
        label: translation('All'),
        icon: <MonoIconCommonAll16 className={styles.itemIcon} />,
        countKey: 'all',
    },
    {
        key: SettingKey.TextNode,
        nodeTypeFilter: Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_TEXT,
        label: translation('Text'),
        icon: <MonoIconLayerType16 className={styles.itemIcon} />,
        countKey: 'textCount',
    },
    {
        key: SettingKey.FrameNode,
        nodeTypeFilter: Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_FRAME,
        label: `${translation('Frame')}/${translation('Group')}`,
        icon: <MonoIconLayerFrame16 className={styles.itemIcon} />,
        countKey: 'frameCount',
    },
    {
        key: SettingKey.ComponentNode,
        nodeTypeFilter: Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_COMPONENT,
        label: translation('Component'),
        icon: <MonoIconLayerComponent16 className={styles.itemIcon} />,
        countKey: 'componentCount',
    },
    {
        key: SettingKey.InstanceNode,
        nodeTypeFilter: Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_INSTANCE,
        label: translation('Instance'),
        icon: <MonoIconLayerInstance16 className={styles.itemIcon} />,
        countKey: 'instanceCount',
    },
    {
        key: SettingKey.ImageNode,
        nodeTypeFilter: Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_IMAGE,
        label: translation('Image'),
        icon: <MonoIconLayerExampleImage16 className={styles.itemIcon} />,
        countKey: 'imageCount',
    },
    {
        key: SettingKey.ShapeNode,
        nodeTypeFilter: Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_SHAPE,
        label: translation('Shape'),
        icon: <MonoIconLayerExamplePolygon16 className={styles.itemIcon} />,
        countKey: 'shapeCount',
    },
    {
        key: SettingKey.OtherNode,
        nodeTypeFilter: Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_OTHER,
        label: translation('Other'),
        icon: <MonoIconCommonMore16 className={styles.itemIcon} />,
        countKey: 'otherCount',
    },
]

interface Props {
    canvasSearchParamModel: CanvasSearchParamModel
    canvasSearchResultModel: CanvasSearchResultModel
}

function getNodeTypeCount(canvasSearchResultModel: CanvasSearchResultModel, countKey: CountKey): string {
    const count = canvasSearchResultModel.modelState?.countSummary[countKey] ?? 0
    return count > 0 ? String(count) : ''
}

export function CanvasSettingButton({ canvasSearchParamModel, canvasSearchResultModel }: Props) {
    const isReplaceMode =
        canvasSearchParamModel.modelState.mode == Wukong.DocumentProto.CanvasSearchMode.CANVAS_SEARCH_MODE_REPLACE

    const onEnterChange = (key: SettingKey) => {
        switch (key) {
            case SettingKey.SearchMode:
                canvasSearchParamModel.modelCommand.updateSearchMode(
                    Wukong.DocumentProto.CanvasSearchMode.CANVAS_SEARCH_MODE_SEARCH
                )
                break
            case SettingKey.ReplaceMode:
                canvasSearchParamModel.modelCommand.updateSearchMode(
                    Wukong.DocumentProto.CanvasSearchMode.CANVAS_SEARCH_MODE_REPLACE
                )
                break
            case SettingKey.AllNode:
                canvasSearchParamModel.modelCommand.updateNodeFilterType(
                    Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_ALL
                )
                break
            case SettingKey.TextNode:
                canvasSearchParamModel.modelCommand.updateNodeFilterType(
                    Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_TEXT
                )
                break
            case SettingKey.FrameNode:
                canvasSearchParamModel.modelCommand.updateNodeFilterType(
                    Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_FRAME
                )
                break
            case SettingKey.ComponentNode:
                canvasSearchParamModel.modelCommand.updateNodeFilterType(
                    Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_COMPONENT
                )
                break
            case SettingKey.InstanceNode:
                canvasSearchParamModel.modelCommand.updateNodeFilterType(
                    Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_INSTANCE
                )
                break
            case SettingKey.ImageNode:
                canvasSearchParamModel.modelCommand.updateNodeFilterType(
                    Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_IMAGE
                )
                break
            case SettingKey.ShapeNode:
                canvasSearchParamModel.modelCommand.updateNodeFilterType(
                    Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_SHAPE
                )
                break
            case SettingKey.OtherNode:
                canvasSearchParamModel.modelCommand.updateNodeFilterType(
                    Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_OTHER
                )
                break
            case SettingKey.MatchCase:
                canvasSearchParamModel.modelCommand.updateMatchCase(!canvasSearchParamModel.modelState.matchCase)
                break
            case SettingKey.WholeWords:
                canvasSearchParamModel.modelCommand.updateWholeWords(!canvasSearchParamModel.modelState.wholeWords)
                break
        }
    }

    return (
        <Tooltip title={translation('Settings')} triggerRefKey="getTriggerElement">
            <DropdownV2.IconMultiLevel<DropdownDefaultCustomNode & { handler?: () => void }>
                style={{ marginLeft: 8 }}
                label={<MonoIconPanelAdjust16 data-testid="canvas-search-setting-button" />}
                placement="bottom center"
                onChange={(v) => v.customNode?.handler?.()}
                onKeyboard={(e) => e.code === 'Escape' && e.stopPropagation()}
                items={[
                    {
                        name: translation('Find'),
                        hidden: !canvasSearchParamModel.enableEdit,
                        dataTestId: 'canvas-search-select-search-mode',
                        customNode: {
                            handler: () => onEnterChange(SettingKey.SearchMode),
                            customItemProps: {
                                isSelect:
                                    canvasSearchParamModel.modelState.mode ==
                                    Wukong.DocumentProto.CanvasSearchMode.CANVAS_SEARCH_MODE_SEARCH,
                            },
                        },
                    },
                    {
                        name: translation('Replace'),
                        hidden: !canvasSearchParamModel.enableEdit,
                        dataTestId: 'canvas-search-select-replace-mode',
                        customNode: {
                            handler: () => onEnterChange(SettingKey.ReplaceMode),
                            customItemProps: {
                                isSelect:
                                    canvasSearchParamModel.modelState.mode ==
                                    Wukong.DocumentProto.CanvasSearchMode.CANVAS_SEARCH_MODE_REPLACE,
                            },
                        },
                    },
                    {
                        name: '',
                        disabled: true,
                        customNode: {
                            autoSplitLine: true,
                        },
                    },
                    ...NodeTypeFilterSettingItemConfig.map((v) => ({
                        name: v.label,
                        hidden: isReplaceMode,
                        dataTestId: v.countKey,
                        customNode: {
                            handler: () => onEnterChange(v.key),
                            shortcut: getNodeTypeCount(canvasSearchResultModel, v.countKey),
                            customItemProps: {
                                forwardIcon: v.icon,
                                isSelect: canvasSearchParamModel.modelState.nodeFilterType == v.nodeTypeFilter,
                            },
                        },
                    })),
                    {
                        name: '',
                        disabled: true,
                        customNode: {
                            autoSplitLine: true,
                        },
                    },
                    {
                        name: `${translation('MatchCase')}${translation('dqJWAK')}`,
                        customNode: {
                            handler: () => onEnterChange(SettingKey.MatchCase),
                            customItemProps: {
                                isSelect: canvasSearchParamModel.modelState.matchCase,
                            },
                        },
                    },
                    {
                        name: `${translation('WholeWords')}${translation('dqJWAK')}`,
                        customNode: {
                            handler: () => onEnterChange(SettingKey.WholeWords),
                            customItemProps: {
                                isSelect: canvasSearchParamModel.modelState.wholeWords,
                            },
                        },
                    },
                ]}
            />
        </Tooltip>
    )
}
