import { useCallback, useMemo, useState } from 'react'
import { InputV2, Select } from '../../../../../../../ui-lib/src'

export interface InputAndSelectorProps {
    values: number[] | string[]
    defaultIndex: number
    onChange: (value: number | string) => void
    inputTestId?: string
}

export const InputAndSelector = (props: InputAndSelectorProps) => {
    const { values, defaultIndex, onChange, inputTestId } = props

    const defaultValue = useMemo(() => values[defaultIndex], [values, defaultIndex])
    const valuesSet = useMemo(() => new Set([...values]), [values])

    const [curValue, setCurValue] = useState(defaultValue)

    const _onChange = useCallback(
        (value: string | number) => {
            if (typeof defaultValue === 'number' && typeof value === 'string') {
                value = parseInt(value)
            }

            if (valuesSet.has(value)) {
                setCurValue(value)
                onChange(value)
            }
        },
        [defaultValue, onChange, valuesSet]
    )

    return (
        <Select.ArrowSingleLevel
            value={curValue}
            onChange={_onChange}
            label={
                <InputV2
                    size="small"
                    value={curValue}
                    onBlur={(e) => _onChange(e.target.value)}
                    data-testid={inputTestId}
                    removeRightPadding
                />
            }
            minWidth={88}
        >
            {values.map((value, index) => {
                return (
                    <Select.ArrowSingleLevel.Option value={value} key={index} backwardIcon={''}>
                        {value}
                    </Select.ArrowSingleLevel.Option>
                )
            })}
        </Select.ArrowSingleLevel>
    )
}
