import { DropImageToCenterCommand, Wukong } from '@wukong/bridge-proto'
import { useRef, useState } from 'react'
import { Scrollbar, WKButton } from '../../../../../../ui-lib/src'
import { CommitType } from '../../../../document/command/commit-type'
import { cmdCreateImages } from '../../../../document/command/image-command'
import { InspireSearchOp } from '../../../../kernel/interface/chatbot'
import { useAppContext, useCommand, useImageDownloadContext } from '../../../../main/app-context'
import { ChatbotDialogContainer } from '../ui-component/chatbot-container'

const SearchResultItem = ({ imageUrl, width, height }: { imageUrl: string; width: number; height: number }) => {
    const [isHovered, setIsHovered] = useState(false)

    const command = useCommand()
    const imageDownloadContext = useImageDownloadContext()

    const imgRef = useRef<HTMLImageElement>(null)

    const handleInsertImage = (url: string) => {
        return async () => {
            const httpsUrl = url.replace(/^http:\/\//, 'https://')
            const blob = await fetch(httpsUrl, {
                method: 'GET',
            }).then((res) => res.blob())

            const result = await Promise.all(
                command.invoke(cmdCreateImages, imageDownloadContext, [{ blob: blob, id: url }])
            )

            command.invokeBridge(
                CommitType.CommitUndo,
                DropImageToCenterCommand,
                Wukong.DocumentProto.DropImageToCenterParam.create({
                    imageInfos: result.map((item) => item.imageInfo),
                })
            )
        }
    }

    return (
        <div
            style={{ position: 'relative', width, height }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className="box-border rounded-[3px] overflow-hidden border border-$wk-gray-13-8"
        >
            <img ref={imgRef} src={imageUrl} alt="inspire search" style={{ width: '100%', height: '100%' }} />
            {isHovered && (
                <div className="absolute top-2 right-2 bg-white rounded-[3px] overflow-hidden">
                    <WKButton type="secondary" size="small" onClick={handleInsertImage(imageUrl)}>
                        置入画布
                    </WKButton>
                </div>
            )}
        </div>
    )
}

export const ChatbotAIInspireSearch = () => {
    const chatbotService = useAppContext().chatbotService
    const currentDialogue = useAppContext().chatbotService.stateStore.use.pageStack().at(-1)?.sourceDialogue

    const imageUrls = (currentDialogue?.questionAnswer.ops[0] as InspireSearchOp).imageUrls

    return (
        <ChatbotDialogContainer
            size={'large'}
            title={'搜索结果'}
            showReturnButton={true}
            onClose={() => chatbotService.closeModal()}
            onReturn={() => chatbotService.returnPage()}
        >
            <div style={{ height: '700px' }}>
                <Scrollbar style={{ flex: '1 1 auto' }}>
                    <div className="p-6">
                        <div className="flex flex-wrap gap-4">
                            {imageUrls.map((url) => (
                                <SearchResultItem imageUrl={url} width={178} height={388} key={url} />
                            ))}
                        </div>
                    </div>
                </Scrollbar>
            </div>
        </ChatbotDialogContainer>
    )
}
