import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    ShareFileTitle: '无法分享文件',
    MoveFileTitle: '无法移动文件',

    OrgInvoiceOverdueContentOfMember: '当前企业因为账单逾期已被锁定，请联系企业管理员了解详情。',
    OrgInvoiceOverdueContentOfAdmin: '当前企业因为账单逾期已被锁定。',

    TeamInvoiceOverdueContentOfMember: '该专业版团队因为账单逾期已被锁定，请联系团队管理员了解详情。',
    TeamInvoiceOverdueContentOfAdmin: '该专业版团队因为账单逾期已被锁定。',

    OrgTrialExpiryContentOfMember: '当前企业因为试用到期已被锁定，请联系企业管理员了解详情。',
    OrgTrialExpiryContentOfAdmin: '当前企业因为试用到期已被锁定。',

    Iknow: '我知道了',
} as const

export const enTranslation = {
    ShareFileTitle: 'Unable to share files',
    MoveFileTitle: 'Unable to move files',

    OrgInvoiceOverdueContentOfMember:
        'The current organization has been locked because of an overdue invoice. Ask your organization admin for more details.',
    OrgInvoiceOverdueContentOfAdmin: 'The current organization has been locked because of an overdue invoice.',

    TeamInvoiceOverdueContentOfMember:
        'This Professional team has been locked because of an overdue invoice. Ask your team admin for more details. ',
    TeamInvoiceOverdueContentOfAdmin: 'This Professional team has been locked because of an overdue invoice. ',

    OrgTrialExpiryContentOfMember:
        'The current organization has been locked because the trial has expired. Ask your organization admin for more details.',
    OrgTrialExpiryContentOfAdmin: 'The current organization has been locked because the trial has expired.',

    Iknow: 'OK',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
