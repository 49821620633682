import { Effect, EffectStyleNode, EffectType } from '../../../document/node/node'
import { EffectStyleIconType, StyleGetVO } from '../../../kernel/interface/component-style'
import { translation } from './effect-style.translation'

export interface EffectStylesGroupRemote {
    groupName: string
    effectStyles: EffectStyleDetailRemote[]
}

export interface EffectStyleDetailRemote {
    effectStyle: StyleGetVO
    styleName: string
    groupName: string
    iconType: EffectStyleIconType
}

export interface EffectStylesGroup {
    groupName: string
    effectStyles: EffectStyleDetail[]
}

export interface EffectStyleDetail {
    effectStyle: EffectStyleNode
    styleName: string
    groupName: string
    iconType: EffectStyleIconType
}

export function generateEffectStylePlaceHolderHint(effects: ReadonlyArray<Effect>): string {
    if (!effects.length) {
        return translation('EffectStyles')
    }
    if (effects.length > 1) {
        return translation('MultipleEffects')
    }
    const effect = effects[0]
    switch (effect.type) {
        case EffectType.BackgroundBlur:
            return translation('BackgroundBlur')
        case EffectType.LayerBlur:
            return translation('LayerBlur')
        case EffectType.DropShadow:
            return translation('DropShadow')
        case EffectType.InnerShadow:
            return translation('InnerShadow')
    }
}
