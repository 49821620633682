import { featureSwitchManager } from '../../../../../kernel/switch/core'
import { useViewState } from '../../../../../view-state-bridge'
import { InspectPrototypeFlows } from '../../../inspect/inspect-prototype-flows'
import { PrototypeFlows } from '../../../prototype/prototype-flows/prototype-flows'

export const DevModePrototypeFlows = () => {
    const flows = useViewState('prototypeFlowList', { list: [] })?.list
    const readnoly = useViewState('docReadonly')
    if (!flows.length) {
        return <></>
    }
    if (readnoly) {
        return <InspectPrototypeFlows bottomBorder topBorder={false} inDevMode />
    }
    return (
        <PrototypeFlows
            showVisibleButton={featureSwitchManager.isEnabled('dev-prototype')}
            disableRename={true}
            disableDelete={true}
        />
    )
}
