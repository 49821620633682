import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Auto: '自动',
} as const

export const enTranslation = {
    Auto: 'Auto',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
