/* eslint-disable no-restricted-imports */
import { EndEditingImageWasmCall, StartEditingImageCommand, Wukong } from '@wukong/bridge-proto'
import { useCallback } from 'react'
import { CommitType } from '../../document/command/commit-type'
import { useCommand } from '../context/document-context'

export function useImageEdit() {
    const command = useCommand()

    const startEditingImage = useCallback(
        (
            nodeIds: string[],
            type: Wukong.DocumentProto.EditingPaintType,
            index: number,
            notModifyPopupState?: boolean,
            editingStyleId?: string
        ) => {
            command.invokeBridge(
                CommitType.Noop,
                StartEditingImageCommand,
                Wukong.DocumentProto.StartEditingImageCommandParam.create({
                    nodeIds,
                    type,
                    index,
                    notModifyPopupState,
                    editingStyleId: editingStyleId && editingStyleId.length > 0 ? editingStyleId : null,
                })
            )
        },
        [command]
    )

    const endEditingImage = useCallback(() => {
        command.invokeBridge(CommitType.Noop, EndEditingImageWasmCall)
    }, [command])

    return { startEditingImage, endEditingImage }
}
