/* eslint-disable no-restricted-imports */
import { Dispatch, FC, MouseEventHandler, SetStateAction, useRef, useState } from 'react'
import useEvent, { ListenerType1 } from 'react-use/lib/useEvent'
import { useAppContext } from '../../../../../main/app-context'
import styles from './index.module.less'

interface LeftSidePanelResizeLineProps {
    computedWidth: number
    setWidth: Dispatch<SetStateAction<number>>
}

const MaxLeftPanelWidth = 500
const MinLeftPanelWidth = 248

export function getWidthInRange(width: number) {
    return width < MinLeftPanelWidth ? MinLeftPanelWidth : width > MaxLeftPanelWidth ? MaxLeftPanelWidth : width
}

export const LeftSidePanelResizeLine: FC<LeftSidePanelResizeLineProps> = ({ computedWidth, setWidth }) => {
    const startRef = useRef<{
        startWidth: number
        startMouseClientX: number
    } | null>(null)

    const [resizeLineLeft, setResizeLineLeft] = useState<string>(computedWidth - 3 + 'px')

    const [resizeLineWidth, setResizeLineWidth] = useState<string>('6px')

    const handleMouseDown = (e: MouseEvent) => {
        setResizeLineLeft('0')
        setResizeLineWidth('100%')
        setWidth(computedWidth)
        startRef.current = {
            startWidth: computedWidth,
            startMouseClientX: e.clientX,
        }
    }

    const handleMouseUp = () => {
        setResizeLineLeft(computedWidth - 3 + 'px')
        setResizeLineWidth('6px')
        startRef.current = null
    }

    const userConfigService = useAppContext().userConfigService

    const handleMouseMove: MouseEventHandler<HTMLDivElement> = (e) => {
        setWidth((width: number) => {
            if (startRef.current) {
                const newWidth = getWidthInRange(
                    startRef.current.startWidth + (e.clientX - startRef.current.startMouseClientX)
                )
                userConfigService.updateConfig2('leftPanelWidth', newWidth)
                return newWidth
            }
            return width
        })
    }

    useEvent<ListenerType1>('mouseup', handleMouseUp, window, { capture: true })

    useEvent<ListenerType1>('mousemove', handleMouseMove)

    return (
        <div
            style={{ left: resizeLineLeft, width: resizeLineWidth }}
            className={styles.leftSideResizeLine}
            onMouseDown={(e) => handleMouseDown(e.nativeEvent)}
        ></div>
    )
}
