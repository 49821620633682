import classNames from 'classnames'
import { MonoIconCommonNumberVariable16 } from '../../../../../../../ui-lib/src/icons-v2'

export interface FloatVariableItem {
    name: string
    value: number | string
    hideVariableValue?: boolean
    isSelected: boolean
    isPreselect: boolean
    onClick: () => void
    onMouseEnter: () => void
    onMouseLeave: () => void
    dataTestId?: {
        container?: string
        name?: string
        value?: string
    }
}

export const FloatVariableItem = ({
    name,
    value,
    hideVariableValue,
    isSelected,
    isPreselect,
    onClick,
    onMouseEnter,
    onMouseLeave,
    dataTestId,
}: FloatVariableItem) => {
    return (
        <div
            data-testid={dataTestId?.container}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={classNames('flex flex-row items-center h-32px px-16px gap-8px ', {
                'bg-gray-light': !isSelected && isPreselect,
                'bg-$wk-v2-fill-color-brand-2': isSelected,
            })}
            data-selected={isSelected}
            onClick={onClick}
        >
            <MonoIconCommonNumberVariable16 className="flex-none" />
            <div className="flex-1 flex flex-row items-center justify-between gap-8px overflow-hidden">
                <div
                    data-testid={dataTestId?.name}
                    className="grow-1 overflow-hidden max-w-132px wk-font-regular wk-text-12 text-ellipsis text-nowrap text-black"
                >
                    {name}
                </div>
                {!hideVariableValue && (
                    <div
                        data-testid={dataTestId?.value}
                        className="shrink-0 overflow-hidden max-w-113px wk-font-regular wk-text-12 text-ellipsis text-nowrap text-gray"
                    >
                        {value}
                    </div>
                )}
            </div>
        </div>
    )
}
