import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    ezHbgp: '请输入内容',
    QPADHG: '请在手机尺寸内画框',
    UUJhjY: '没有找到匹配的内容',
    DapWZQ: '请尝试更换关键词搜索',
} as const

export const enTranslation = {
    ezHbgp: 'Write contents here',
    QPADHG: 'Click and drag within a mobile phone-sized frame',
    UUJhjY: 'No result',
    DapWZQ: 'try agian',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
