import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Search: '搜索',
    wuvOAM: '搜索结果',
    lOvDrz: '没有找到该组件',
    NoComponent: '本文件没有组件',
} as const

export const enTranslation = {
    Search: 'Search',
    wuvOAM: 'Search results',
    lOvDrz: 'No results found',
    NoComponent: 'This file has no components',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
