import { translation } from './component-detail-modal.translation'
/* eslint-disable no-restricted-imports */
import classNames from 'classnames'
import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react'
import { DropdownV2, IconLoading48, Scrollbar, Tooltip, WKDialog } from '../../../../../../ui-lib/src'
import { timeDesc, useIsEllipsisActive } from '../../../../../../util/src'
import { ToKeyCode } from '../../../../document/util/keycode'
import { DocWithAuthorityVO } from '../../../../kernel/interface/type'
import { GetDocRequest } from '../../../../kernel/request/document'
import { useFullImageUrl } from '../../../../kernel/request/upload'
import { isWindows } from '../../../../kernel/util/ua'
import { ComponentCandidate, ComponentData } from '../../../../main/ai-service/typings'
import { useAppContext } from '../../../../main/app-context'
import { CursorMode } from '../../../../main/cursor/cursor'
import { KeyboardReceiver } from '../../../../main/keyboard-receiver/component'
import docIcon from '../../../assets/ai/doc.png'
import downArrow from '../../../assets/ai/downArrow.png'
import jumpDisable from '../../../assets/ai/jump-disable.png'
import jump from '../../../assets/ai/jump.png'
import leftArrow from '../../../assets/ai/left-arrow.png'
import rightArrow from '../../../assets/ai/right-arrow.png'
import zoomIn from '../../../assets/ai/zoomIn.png'
import zoomOut from '../../../assets/ai/zoomOut.png'
import classes from './component-detail-modal.module.less'

interface DocComponentData {
    docId: string
    components: ComponentData[]
}

interface ImageViewerData {
    imgUrl: string
    componentData: ComponentData[]
}

interface ViewPort {
    x: number
    y: number
    scale: number
    width: number
    height: number
}

const DocList = ({
    docComponentDatas,
    selectedDoc,
    onSelectionChanged,
}: {
    docComponentDatas: DocComponentData[]
    selectedDoc: string | undefined
    onSelectionChanged: (d: DocComponentData) => void
}) => {
    const docNameRef = useRef<HTMLDivElement>(null)
    const isEllipsis = useIsEllipsisActive(docNameRef.current)

    return (
        <div className={classes.DocList}>
            <Scrollbar>
                <div className={classes.Header}>
                    <div className={classNames(['wk-text-12', 'wk-font-medium'])}>{translation('Documentation')}</div>
                </div>
                <div className={classes.DocContainer}>
                    {docComponentDatas.map((docComponentData) => {
                        return (
                            <Tooltip
                                title={docComponentData.components[0].docName}
                                inactivation={!isEllipsis}
                                key={docComponentData.docId}
                            >
                                <div
                                    className={classNames([
                                        classes.DocItem,
                                        selectedDoc == docComponentData.docId ? classes.Selected : '',
                                    ])}
                                    onClick={() => {
                                        onSelectionChanged(docComponentData)
                                    }}
                                >
                                    <img className={classes.DocImg} src={docIcon}></img>
                                    <div
                                        ref={docNameRef}
                                        className={classNames([classes.DocName, 'wk-text-12', 'wk-font-regular'])}
                                    >
                                        {docComponentData.components[0].docName}
                                    </div>
                                    <div className={classNames([classes.Count, 'wk-text-12', 'wk-font-regular'])}>
                                        {docComponentData.components.length > 99
                                            ? '99+'
                                            : docComponentData.components.length}
                                    </div>
                                </div>
                            </Tooltip>
                        )
                    })}
                </div>
            </Scrollbar>
        </div>
    )
}

const ImageViewer = ({
    componentDatas,
    inPlayGround,
}: {
    componentDatas: ImageViewerData[]
    inPlayGround: boolean
}) => {
    const [currentIndex, setCurrentIndex] = useState<number>(0)
    const containerDiv = useRef<HTMLDivElement>(null)
    const appContext = useAppContext()

    const [imgStyle, setImgStyle] = useState<CSSProperties>()
    const [imgUrl, setImgUrl] = useState<string>()
    const [highlightDivStyle, setHighlightDivStyle] = useState<CSSProperties[]>([])
    const [viewPort, setViewPort] = useState<ViewPort>({ x: 0, y: 0, scale: 1, width: 0, height: 0 })
    const [isLoadingImage, setIsLoadingImage] = useState<boolean>(true)
    const [initialViewPort, setInitialViewPort] = useState<ViewPort>()
    const [shouldShowHighlight, setShouldShowHighlight] = useState<boolean>(false)

    const zoomAtPoint = (scale: number, point: { x: number; y: number }) => {
        const newScale = Math.max(0.02, Math.min(viewPort.scale * scale, 256))
        if (Math.abs(newScale - viewPort.scale) < 0.0001) {
            return
        }
        const deltaX = point.x - viewPort.x
        const deltaY = point.y - viewPort.y
        let newTranslateX = 0
        let newTranslateY = 0
        newTranslateX = viewPort.x + (1 - scale) * deltaX
        newTranslateY = viewPort.y + (1 - scale) * deltaY

        setViewPort({
            x: newTranslateX,
            y: newTranslateY,
            scale: newScale,
            width: viewPort.width,
            height: viewPort.height,
        })
    }

    useEffect(() => {
        containerDiv.current?.focus()
        appContext.cursorManager.attachCustomTarget(containerDiv.current!)
        appContext.cursorManager.disconnectCursor()
        appContext.cursorManager.updateCursor({
            mode: CursorMode.CURSOR_MODE_HAND_LOOSE,
        })
        return () => {
            appContext.cursorManager.detachCustomTarget()
            appContext.cursorManager.connectCursor()
        }
    }, [appContext.cursorManager])

    const showHighlightTimeout = useRef<NodeJS.Timeout>()
    useEffect(() => {
        if (!componentDatas[currentIndex]) {
            return
        }
        const imageLoadingTimeout = setTimeout(() => {
            setIsLoadingImage(true)
        }, 500)
        const containerWidth = containerDiv.current!.clientWidth
        const containerHeight = containerDiv.current!.clientHeight
        const containerRatio = containerWidth / containerHeight
        const imgId = componentDatas[currentIndex].imgUrl
        const img = new Image()
        img.src = imgId
        setShouldShowHighlight(false)
        clearTimeout(showHighlightTimeout.current)
        img.onload = () => {
            clearTimeout(imageLoadingTimeout)
            const imgRatio = img.naturalWidth / img.naturalHeight
            const scale =
                imgRatio > containerRatio ? containerWidth / img.naturalWidth : containerHeight / img.naturalHeight
            const scaledWidth = img.naturalWidth * scale
            const scaledHeight = img.naturalHeight * scale
            const translateX = (containerWidth - scaledWidth) / 2
            const translateY = containerHeight - scaledHeight
            const viewportForInit = {
                x: translateX,
                y: translateY,
                scale,
                width: scaledWidth,
                height: scaledHeight,
            }
            setInitialViewPort(viewportForInit)
            setImgUrl(img.src)
            requestAnimationFrame(() => {
                setViewPort(viewportForInit)
                setIsLoadingImage(false)
            })
            showHighlightTimeout.current = setTimeout(() => {
                setShouldShowHighlight(true)
            }, 300)
        }
    }, [componentDatas, currentIndex])

    useEffect(() => {
        setCurrentIndex(0)
    }, [componentDatas])

    useEffect(() => {
        if (viewPort && componentDatas.length) {
            if (currentIndex >= componentDatas.length) {
                return
            }
            setHighlightDivStyle(
                componentDatas[currentIndex].componentData.map((d) => {
                    let width = d.highlightArea.width
                    let height = d.highlightArea.height
                    let x = d.highlightArea.x
                    let y = d.highlightArea.y
                    if (width < 8) {
                        const oldWidth = width
                        width = 8
                        x -= (8 - oldWidth) / 2
                    }
                    if (height < 8) {
                        const oldHeight = height
                        height = 8
                        y -= (8 - oldHeight) / 2
                    }
                    return {
                        width: `${width * viewPort.scale}px`,
                        height: `${height * viewPort.scale}px`,
                        left: `${x * viewPort.scale + viewPort.x}px`,
                        top: `${y * viewPort.scale + viewPort.y}px`,
                    }
                })
            )
        }
    }, [componentDatas, currentIndex, viewPort])

    useEffect(() => {
        setImgStyle({
            transformOrigin: 'left top',
            transform: `translate(${viewPort.x}px, ${viewPort.y}px) scale(${viewPort.scale})`,
        })
    }, [viewPort])

    const isMouseDown = useRef<boolean>(false)
    const mouseDownPostion = useRef<{ x: number; y: number }>({ x: 0, y: 0 })

    useEffect(() => {
        const onMouseDown = (e: MouseEvent) => {
            if (e.target != containerDiv.current) {
                return
            }
            isMouseDown.current = true
            mouseDownPostion.current = { x: e.clientX, y: e.clientY }
            appContext.cursorManager.attachCustomTarget(containerDiv.current!)
            appContext.cursorManager.disconnectCursor()
            appContext.cursorManager.updateCursor({
                mode: CursorMode.CURSOR_MODE_HAND_HOLD,
            })
        }

        const onMouseMove = (e: MouseEvent) => {
            if (isMouseDown.current) {
                const newTranslateX = viewPort.x + e.movementX
                const newTranslateY = viewPort.y + e.movementY
                setViewPort({
                    x: newTranslateX,
                    y: newTranslateY,
                    scale: viewPort.scale,
                    width: viewPort.width,
                    height: viewPort.height,
                })
            }
        }

        const onMouseUp = (_: MouseEvent) => {
            appContext.cursorManager.attachCustomTarget(containerDiv.current!)
            appContext.cursorManager.disconnectCursor()
            appContext.cursorManager.updateCursor({
                mode: CursorMode.CURSOR_MODE_HAND_LOOSE,
            })
            isMouseDown.current = false
        }

        window.addEventListener('mousedown', onMouseDown)
        window.addEventListener('mousemove', onMouseMove)
        window.addEventListener('mouseup', onMouseUp)

        return () => {
            window.removeEventListener('mousedown', onMouseDown)
            window.removeEventListener('mousemove', onMouseMove)
            window.removeEventListener('mouseup', onMouseUp)
        }
    }, [appContext.cursorManager, viewPort.height, viewPort.scale, viewPort.width, viewPort.x, viewPort.y])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onWheel = (e: WheelEvent) => {
        e.preventDefault()
        if (e.metaKey || e.ctrlKey) {
            if (Math.abs(e.deltaY) > 0) {
                zoomAtPoint(e.deltaY > 0 ? 0.9 : 1.1, {
                    x: e.clientX - containerDiv.current!.getBoundingClientRect().x,
                    y: e.clientY - containerDiv.current!.getBoundingClientRect().y,
                })
            }
        } else {
            let deltaX = e.deltaX
            let deltaY = e.deltaY
            if (isWindows() && e.shiftKey) {
                deltaX = e.deltaY
                deltaY = e.deltaX
            }
            const newTranslateX = viewPort.x - deltaX * 0.6
            const newTranslateY = viewPort.y - deltaY * 0.6
            setViewPort({
                x: newTranslateX,
                y: newTranslateY,
                scale: viewPort.scale,
                width: viewPort.width,
                height: viewPort.height,
            })
        }
    }

    useEffect(() => {
        const containerDivValue = containerDiv.current
        containerDivValue?.addEventListener('wheel', onWheel, { passive: false })

        return () => {
            containerDivValue?.removeEventListener('wheel', onWheel)
        }
    }, [onWheel])

    const onJumpToFile = () => {
        const targetComponent = componentDatas[currentIndex].componentData[0]
        appContext.componentService.jumpToOriginDocument({
            docId: targetComponent.docId,
            nodeId: targetComponent.commonAncestorId ?? targetComponent.containerIds[0],
        })
    }

    const zoomInAtCenter = () => {
        zoomAtPoint(2, {
            x: containerDiv.current!.clientWidth / 2,
            y: containerDiv.current!.clientHeight / 2,
        })
    }

    const zoomOutAtCenter = () => {
        zoomAtPoint(0.5, {
            x: containerDiv.current!.clientWidth / 2,
            y: containerDiv.current!.clientHeight / 2,
        })
    }

    const [dropDownVisible, setDropDownVisible] = useState<boolean | undefined>(undefined)

    useEffect(() => {
        if (dropDownVisible === false) {
            setDropDownVisible(undefined)
        }
    }, [dropDownVisible])

    const onKeyDown = (e: KeyboardEvent | React.KeyboardEvent) => {
        if (e.key === '=') {
            e.preventDefault()
            zoomInAtCenter()
        } else if (e.key === '-') {
            e.preventDefault()
            zoomOutAtCenter()
        } else if (e.key === '!' && e.shiftKey) {
            e.preventDefault()
            setViewPort(initialViewPort!)
        }

        return false
    }

    const onChange = (v: 'ZoomIn' | 'ZoomOut' | 'ZoomToFit') => {
        switch (v) {
            case 'ZoomIn':
                return zoomInAtCenter()
            case 'ZoomOut':
                return zoomOutAtCenter()
            case 'ZoomToFit':
                return setViewPort(initialViewPort!)
        }
    }
    return (
        <KeyboardReceiver keyCode={ToKeyCode.All} onKeydown={onKeyDown}>
            <div className={classes.ImageViewer} ref={containerDiv} tabIndex={0}>
                {isLoadingImage && (
                    <div className={classes.LoadingContainer}>
                        <IconLoading48 className="animate-spin"></IconLoading48>
                        <div className={classNames([classes.Text, 'wk-text-14', 'wk-font-regular'])}>
                            {translation('ImageLoading')}...
                        </div>
                    </div>
                )}
                <img className={classes.Img} src={imgUrl} style={imgStyle}></img>
                <div className={classes.PageControl}>
                    <div className={classNames(['wk-text-12', 'wk-font-regular'])}>
                        {' '}
                        {`${currentIndex + 1}/${componentDatas.length}`}{' '}
                    </div>
                </div>
                {currentIndex !== 0 && (
                    <div
                        className={classes.LeftPageButton}
                        onClick={() => {
                            setCurrentIndex(currentIndex - 1)
                        }}
                    >
                        <img src={leftArrow}></img>
                    </div>
                )}
                {currentIndex !== componentDatas.length - 1 && (
                    <div
                        className={classes.RightPageButton}
                        onClick={() => {
                            setCurrentIndex(currentIndex + 1)
                        }}
                    >
                        <img src={rightArrow}></img>
                    </div>
                )}
                {!isLoadingImage &&
                    imgUrl &&
                    shouldShowHighlight &&
                    highlightDivStyle.map((item, i) => {
                        return <div className={classNames([classes.Highlight])} key={i} style={item}></div>
                    })}
                <div className={classes.ToolBar}>
                    <Tooltip title={translation('ZoomOut')}>
                        <div className={classes.Button} onClick={zoomOutAtCenter}>
                            <img src={zoomOut}></img>
                        </div>
                    </Tooltip>
                    <DropdownV2.EmptySingleLevel
                        placement="top center"
                        style={{ width: 'unset', height: 'unset' }}
                        classNameWhenOpen={dropDownVisible ? classes.Open : undefined}
                        label={
                            <div className={classes.Status}>
                                <div className={classNames(['wk-text-12', 'wk-font-regular'])}>
                                    {Math.floor(viewPort.scale * 100) + '%'}
                                </div>
                                <img className={classes.DropDown} src={downArrow}></img>
                            </div>
                        }
                        onOpen={() => setDropDownVisible(true)}
                        onClose={() => setDropDownVisible(false)}
                        onKeyboard={onKeyDown}
                        onChange={onChange}
                    >
                        <DropdownV2.IconSingleLevel.Option value="ZoomIn" backwardIcon="+">
                            {translation('ZoomIn')}
                        </DropdownV2.IconSingleLevel.Option>
                        <DropdownV2.IconSingleLevel.Option value="ZoomOut" backwardIcon="-">
                            {translation('ZoomOut')}
                        </DropdownV2.IconSingleLevel.Option>
                        <DropdownV2.IconSingleLevel.Option value="ZoomToFit" backwardIcon="⇧1">
                            {translation('ZoomToFit')}
                        </DropdownV2.IconSingleLevel.Option>
                    </DropdownV2.EmptySingleLevel>
                    <Tooltip title={translation('ZoomIn')}>
                        <div className={classes.Button} onClick={zoomInAtCenter}>
                            <img src={zoomIn}></img>
                        </div>
                    </Tooltip>
                    <div className={classes.Divider}></div>
                    {inPlayGround ? (
                        <Tooltip title={translation('NotAvaiable')}>
                            <div className={classes.ButtonDisabled}>
                                <img src={jumpDisable}></img>
                            </div>
                        </Tooltip>
                    ) : (
                        <Tooltip title={translation('GoToMain')}>
                            <div className={classes.Button} onClick={onJumpToFile}>
                                <img src={jump}></img>
                            </div>
                        </Tooltip>
                    )}
                </div>
            </div>
        </KeyboardReceiver>
    )
}

const ComponentDetail = ({
    componentData,
    docVO,
    inPlayGround,
}: {
    componentData: DocComponentData
    docVO: DocWithAuthorityVO | undefined | null
    inPlayGround: boolean
}) => {
    // 按 imgId 分组
    const imageViewerDatas = useMemo(() => {
        const map = new Map<string, ComponentData[]>()
        componentData.components.forEach((component) => {
            if (!map.has(component.screenshotId)) {
                map.set(component.screenshotId, [])
            }

            map.get(component.screenshotId)?.push(component)
        })
        return Array.from(map.entries()).map((t) => {
            return {
                imgUrl: t[0],
                componentData: t[1],
            } as ImageViewerData
        })
    }, [componentData.components])

    const imageUrl = useFullImageUrl(docVO?.teamIcon, {
        minify: true,
        width: 24,
    })

    const getSubTitle = () => {
        if (docVO?.draft) {
            return translation('Draft')
        } else {
            if (docVO?.teamName) {
                return `${docVO.teamName} / ${docVO.folderName}`
            } else {
                return docVO!.folderName
            }
        }
    }

    return (
        <div className={classes.ComponentDetail}>
            <div className={classNames([classes.Title, 'wk-text-14', 'wk-font-medium'])}>
                {componentData.components[0].docName}
            </div>
            <div className={classNames([classes.SubTitle, 'wk-text-12', 'wk-font-regular'])}>
                {docVO && (
                    <>
                        {docVO.teamIcon && <img className={classes.TeamIcon} src={imageUrl}></img>}
                        <div>{getSubTitle()}</div>
                        <div className={classes.Divider}></div>
                        <div>
                            {translation('Edited')} {timeDesc(Math.max(docVO.lastEditedTime, docVO.createdTime))}
                        </div>
                    </>
                )}
                {docVO === null && <div>{translation('NoAccessPermission')}</div>}
            </div>
            <ImageViewer inPlayGround={inPlayGround} componentDatas={imageViewerDatas}></ImageViewer>
        </div>
    )
}

const AiComponentDetailModalContent = ({ componentCandidate }: { componentCandidate: ComponentCandidate }) => {
    const [currentComponentData, setCurrentComponentData] = useState<DocComponentData>()
    const [docInfo, setDocInfo] = useState<DocWithAuthorityVO | null | undefined>()

    const docComponentDatas = useMemo(() => {
        const allComponentDatas = [componentCandidate.componentData, ...componentCandidate.repeats]
        const componentDocMap = new Map<string, ComponentData[]>()
        allComponentDatas.forEach((componentData) => {
            if (!componentDocMap.has(componentData.docId)) {
                componentDocMap.set(componentData.docId, [])
            }
            componentDocMap.get(componentData.docId)?.push(componentData)
        })
        const ret: DocComponentData[] = []
        componentDocMap.forEach((componentDatas, docId) => {
            ret.push({
                docId,
                components: componentDatas,
            })
        })
        ret.sort((a, b) => {
            return a.components.length - b.components.length
        })

        return ret
    }, [componentCandidate])

    useEffect(() => {
        if (currentComponentData) {
            new GetDocRequest(currentComponentData.docId)
                .start()
                .then((docVO) => {
                    setDocInfo(docVO)
                })
                .catch(() => {
                    setDocInfo(null)
                })
        }
    }, [currentComponentData])

    useEffect(() => {
        setCurrentComponentData(docComponentDatas[0])
    }, [docComponentDatas])

    return (
        <div className={classes.Container}>
            <DocList
                docComponentDatas={docComponentDatas}
                selectedDoc={currentComponentData?.docId}
                onSelectionChanged={(data) => setCurrentComponentData(data)}
            ></DocList>
            {currentComponentData && (
                <ComponentDetail
                    inPlayGround={componentCandidate.inPlayGround}
                    componentData={currentComponentData}
                    docVO={docInfo}
                ></ComponentDetail>
            )}
        </div>
    )
}

export const AiComponentDetailModal = () => {
    const appContext = useAppContext()
    const currentComponentCandidate = appContext.aiService.useZustandStore.use.currentComponentCandidate()
    const canOpenModal = appContext.aiService.useZustandStore.use.isComponentCandidateDetailModalOpen()

    if (!canOpenModal) {
        return null
    }

    return (
        <WKDialog
            visible={true}
            onCancel={() => {
                appContext.aiService.closeComponentCandidate()
            }}
            bodyStyle={{ padding: 0 }}
            width={1080}
            footer={null}
            hideFooterLine={true}
            title={translation('Details')}
            centered={true}
        >
            {currentComponentCandidate ? (
                <AiComponentDetailModalContent
                    componentCandidate={currentComponentCandidate}
                ></AiComponentDetailModalContent>
            ) : (
                <div className={classes.ContentLoadingContainer}>
                    <IconLoading48 className="animate-spin"></IconLoading48>
                    <div className={classNames([classes.Text, 'wk-text-14', 'wk-font-regular'])}>
                        {translation('DetailsLoading')}
                    </div>
                </div>
            )}
        </WKDialog>
    )
}
