import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    AIGenUI: 'AI 生成 UI',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    AIGenUI: 'AI Generates UI',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
