import { MonoIconCommonClose16 } from '../../../../../ui-lib/src'
import { DEFAULT_TOP_BANNER_HEIGHT } from '../../../main/layout/layout-default-value'
import { useUsedMemory } from './context/used-memory-context'
import { translation } from './memory-usage-alert-banner.translation'
import { useMemoryUsageAlertBanner } from './use-memory-usage-alert-banner'

// banner 包围间距
const MARGIN_X = 24
const MARGIN_Y = 12

// banner 图示与标题间距
const MARGIN_BETWEEN = 24

// 关闭按钮
const CLOSE_ICON_SIZE = 32

// banner 标题
const BAR_TITLE_HEIGHT = 32
const BAR_TITLE_FONTSIZE = 14
const BAR_TITLE_FONT_WEIGHT = 500

// banner 副标题
const BAR_DESC_TEXT_HEIGHT = 22
const BAR_DESC_TEXT_FONTSIZE = 12
const BAR_DESC_FONT_WEIGHT = 400

// banner 图示
const BAR_ICON_WIDTH = 100
const BAR_ICON_HEIGHT = 40
const BAR_ICON_FONT_SIZE = 16
const BAR_ICON_FONT_WEIGHT = 500
const BAR_ICON_SLIDE_HEIGHT = 14
const BAR_ICON_SLIDE_INNER_HEIGHT = 8

export function MemoryUsageAlertBanner() {
    const { usedMemory } = useUsedMemory()
    const memoryUsageBannerState = useMemoryUsageAlertBanner(usedMemory)
    if (!memoryUsageBannerState.show) {
        return null
    }
    return (
        <div
            style={{
                height: DEFAULT_TOP_BANNER_HEIGHT,
                backgroundColor: memoryUsageBannerState.backgroundColor,
                color: memoryUsageBannerState.textColor,
            }}
        >
            <div
                style={{
                    height: DEFAULT_TOP_BANNER_HEIGHT - 2 * MARGIN_Y,
                    width: `calc(100% - ${MARGIN_X * 2}px)`,
                    marginLeft: MARGIN_X,
                    marginRight: MARGIN_X,
                    marginTop: MARGIN_Y,
                    marginBottom: MARGIN_Y,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        width: BAR_ICON_WIDTH,
                        height: BAR_ICON_HEIGHT,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div style={{ fontSize: BAR_ICON_FONT_SIZE, fontWeight: BAR_ICON_FONT_WEIGHT }}>
                        {(memoryUsageBannerState.memoryUsedPercent * 100).toFixed(2)}%
                    </div>
                    <div style={{ marginTop: 'auto', height: BAR_ICON_SLIDE_HEIGHT, position: 'relative' }}>
                        <div
                            style={{
                                height: BAR_ICON_SLIDE_INNER_HEIGHT,
                                marginTop: (BAR_ICON_SLIDE_HEIGHT - BAR_ICON_SLIDE_INNER_HEIGHT) / 2,
                                border: `1px solid ${memoryUsageBannerState.textColor}`,
                            }}
                        >
                            <div
                                style={{
                                    height: '100%',
                                    backgroundColor: memoryUsageBannerState.textColor,
                                    width: `${memoryUsageBannerState.memoryUsedPercent * 100}%`,
                                }}
                            ></div>
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: `${(memoryUsageBannerState.threshold * 100).toFixed(0)}%`,
                                height: '100%',
                                border: `1px solid ${memoryUsageBannerState.textColor}`,
                            }}
                        ></div>
                    </div>
                </div>
                <div style={{ flex: 1, marginLeft: MARGIN_BETWEEN }}>
                    <div
                        style={{
                            display: 'flex',
                            height: BAR_TITLE_HEIGHT,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{ fontSize: BAR_TITLE_FONTSIZE, fontWeight: BAR_TITLE_FONT_WEIGHT, flex: 1 }}>
                            {translation('GjQlXC')}
                        </div>
                        {memoryUsageBannerState.closable ? (
                            <div
                                style={{
                                    height: CLOSE_ICON_SIZE,
                                    width: CLOSE_ICON_SIZE,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <MonoIconCommonClose16 onClick={memoryUsageBannerState.closeBanner} />
                            </div>
                        ) : null}
                    </div>

                    <div
                        style={{
                            fontSize: BAR_DESC_TEXT_FONTSIZE,
                            fontWeight: BAR_DESC_FONT_WEIGHT,
                            height: BAR_DESC_TEXT_HEIGHT,
                        }}
                    >
                        {translation('TJmWXw', { memory: `${(memoryUsageBannerState.threshold * 100).toFixed(0)}%。` })}
                    </div>
                </div>
            </div>
        </div>
    )
}
