import { LayoutGridStyleNode } from '../../../../../document/node/node'
import { LibraryStyleTestId } from '../../../../../window'
import { getStyleShortName } from '../../style/style-util'
import { CommonStyleInfo } from '../get-style-nodes-info-map'
import { StyleListItem, StyleListItemKeyboardProps } from '../style-items-layout/style-list-item'
import style from './style-layout-grid-list-item.module.less'
import { LayoutGridStyleThumbnail } from './style-thumbnail'

export interface UnknowRemoteLayoutGridStyleProps {
    item: LayoutGridStyleNode
    docId: string
    selectStyleId?: string
    openStyleId?: string
    className?: string
    disabledOnClick?: boolean
    children?: React.ReactNode
    onSelectItem: (item: CommonStyleInfo) => void
    onClickHoverIcon: (
        item: CommonStyleInfo,
        containerDomRect: DOMRect,
        remoteStyleId?: string,
        docId?: string,
        name?: string,
        fromFig?: boolean
    ) => void
    onContextMenu?: (
        item: CommonStyleInfo,
        e: any,
        remoteStyleId?: string,
        docId?: string,
        name?: string,
        fromFig?: boolean
    ) => void
    isRightClickStyle?: boolean
}

export function UnknowStyleColorListItem(props: UnknowRemoteLayoutGridStyleProps & StyleListItemKeyboardProps) {
    const { item, openStyleId, onSelectItem, onClickHoverIcon, onContextMenu, isRightClickStyle } = props
    const { id, name, fromFig } = props.item
    const docId = props.docId || props.item.publishFile

    return (
        <StyleListItem
            item={{
                ...item,
                groupName: '',
                styleName: '',
            }}
            isReadonly
            dataTestId={`${LibraryStyleTestId.UnknownLayoutGridStyleItem}-${id}`}
            openStyleId={openStyleId}
            onSelectItem={onSelectItem}
            onClickHoverIcon={async (itemValue, containerDomRect) => {
                onClickHoverIcon(itemValue, containerDomRect, item.publishId, docId, name, fromFig)
            }}
            onContextMenu={(itemValue, e) => {
                onContextMenu?.(itemValue, e, item.publishId, docId, name, fromFig)
            }}
            isRightClickStyle={isRightClickStyle}
            index={props.index}
            selectStyleId={props.selectStyleId}
            setPreselectIndex={props.setPreselectIndex}
            trySetPreselectIndex={props.trySetPreselectIndex}
            recordEnterCallback={props.recordEnterCallback}
        >
            <LayoutGridStyleThumbnail
                className={style.thumbnail}
                layoutGrids={props.item.layoutGrids}
                reversed={true}
            />
            <div className={style.name}>{getStyleShortName(name || '')}</div>
        </StyleListItem>
    )
}
