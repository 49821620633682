import { WKTextButton, WKToast } from '../../../../../../../ui-lib/src'
import classNames from 'classnames'
import { translation } from './index.translation'

import { useState } from 'react'
import { useViewState } from '../../../../../view-state-bridge'
import { useHandler } from '../../../../context/document-context'
import { copyText } from '../../../../handler/copy-handler'
import style from '../inspect.module.less'

export function DevModeInspectContent() {
    const handler = useHandler()
    const contentViewState = useViewState('devModeInspectContentViewState')
    const [isHover, setIsHover] = useState(false)

    if (!contentViewState?.show || !contentViewState.characters) {
        return null
    }

    return (
        <div className={style.contentPanel} data-testid="dev-mode-inspect-content">
            <div className={style.contentPanelTitle}>
                <div className={style.contentTitle}>{translation('Content')}</div>
                <WKTextButton
                    onClick={() => {
                        handler.handle(copyText, contentViewState.characters)
                        WKToast.show(translation('CopiedToClipboard'))
                    }}
                    className={style.copyButton}
                    size={12}
                    onMouseOver={() => {
                        setIsHover(true)
                    }}
                    onMouseLeave={() => {
                        setIsHover(false)
                    }}
                    type="primary"
                    data-testid="content-copy-button"
                >
                    {translation('Copy')}
                </WKTextButton>
            </div>
            <div className={classNames(style.contentWrap)}>
                <div className={isHover ? style.hoverButton : ''}>
                    <span tabIndex={-1} className={style.text}>
                        {contentViewState.characters}
                    </span>
                </div>
            </div>
        </div>
    )
}
