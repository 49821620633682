import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    ThereIsContent: '更新中',
    UpdateFailed: '更新失败',
    UpdateSuccessful: '更新成功',
    Updating: '正在更新',
    'Auto-subscriptionFailsAfter': '引用移动更新后自动订阅失败',
} as const

export const enTranslation = {
    ThereIsContent: 'Updating',
    UpdateFailed: 'An error occurred while updating',
    UpdateSuccessful: 'Successfully updated',
    Updating: 'Updating',
    'Auto-subscriptionFailsAfter': ' An error occurred while enabling automatically after citing mobile updates',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
