import { translation } from './file.translation'
export async function readFromFile(fileType = 'application/json'): Promise<string> {
    return new Promise((resolve, reject) => {
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = fileType

        input.addEventListener('change', () => {
            const file = input.files?.[0]
            if (!file) {
                reject(new Error(translation('PleaseSelectA')))
                return
            }

            const reader = new FileReader()

            reader.addEventListener('load', () => {
                const content = reader.result as string
                resolve(content)
            })

            reader.addEventListener('error', () => {
                reject(new Error(translation('FailedToRead')))
            })

            reader.readAsText(file)
        })

        input.click()
    })
}

export function saveToFile(content: string, filename: string) {
    const blob = new Blob([content], { type: 'text/plain' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = filename
    link.click()
    URL.revokeObjectURL(url)
}
