import { Wukong } from '@wukong/bridge-proto'
import { CommandInvoker } from '../../../../../document/command/command-invoker'
import { Bridge } from '../../../../../kernel/bridge/bridge'
import { ServiceClass } from '../../../../../kernel/util/service-class'
import type { ColorProfileService } from '../../../../../main/service/color-profile-service'
import { LibraryResourceDownloader } from '../../../../../share/component-style-library/service/library-resource-downloader'
import type { LibraryThumbnailKeyItem } from '../../../../../share/component-style-library/service/library-thumbnail-service-type'
import { RemoteLibraryNodeThumbnailService } from '../../../../../share/component-style-library/service/remote-library-thumbnail-service'
import { LocalLibraryNodeThumbnailService } from './local-library-thumbnail-service'
export type { LibraryThumbnailKeyItem }

export class LibraryNodeThumbnailService extends ServiceClass {
    private localLibraryNodeThumbnailService: LocalLibraryNodeThumbnailService
    private remoteLibraryNodeThumbnailService: RemoteLibraryNodeThumbnailService

    constructor(
        private readonly commandInvoker: CommandInvoker,
        protected override readonly bridge: Bridge,
        private readonly colorProfileService: ColorProfileService,
        private readonly libraryResourceDownloader: LibraryResourceDownloader
    ) {
        super(bridge)
        this.localLibraryNodeThumbnailService = new LocalLibraryNodeThumbnailService(
            this.commandInvoker,
            this.bridge,
            this.colorProfileService,
            this.libraryResourceDownloader
        )
        this.remoteLibraryNodeThumbnailService = new RemoteLibraryNodeThumbnailService(this.libraryResourceDownloader)
    }

    public override destroy() {
        super.destroy()
        this.localLibraryNodeThumbnailService.destroy()
        this.remoteLibraryNodeThumbnailService.destroy()
    }

    public loadThumbnailData = async (
        arg: Wukong.DocumentProto.IThumbnailData
    ): Promise<LibraryThumbnailKeyItem | null> => {
        if (arg.isLocal) {
            return this.localLibraryNodeThumbnailService.loadThumbnailData(arg)
        } else {
            return this.remoteLibraryNodeThumbnailService.loadThumbnailData(arg)
        }
    }

    public loadThumbnailDataFromCache = (
        arg: Wukong.DocumentProto.IThumbnailData | null
    ): LibraryThumbnailKeyItem | null => {
        if (arg?.isLocal) {
            return this.localLibraryNodeThumbnailService.loadThumbnailDataFromCache(arg)
        } else {
            return this.remoteLibraryNodeThumbnailService.loadThumbnailDataFromCache(arg)
        }
    }

    public eraseThumbnailDataCache = (thumbnailDataPath: string) => {
        return this.remoteLibraryNodeThumbnailService.eraseThumbnailDataCache(thumbnailDataPath)
    }
}
