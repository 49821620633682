/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { useId } from 'react'
import type { Paint, SolidPaint } from '../../../../../document/node/node'
import { LibraryThumbnailImage } from '../../../../../share/component-style-library/library-thumbnail-image'
import { buildThumbnailDataFromLocalPaintStyle } from '../../../../../share/component-style-library/library-thumbnail-image/use-library-thumbnail-image-v2'
import { getPaintsHash } from '../../../../utils/get-paints-hash'
import { useImageLoaded } from '../../../../utils/thumbnail-cache/use-image-loaded'
import styles from './local-style-color-list-item-thumbnail.module.less'

interface SingleSolidPaintStyleThumbnailProps {
    paint: SolidPaint
    className?: string
}

function SingleSolidPaintStyleThumbnail({ paint, className }: SingleSolidPaintStyleThumbnailProps): JSX.Element {
    const { opacity = 0, color } = paint
    const id = useId()
    return (
        <div className={`${styles.singleSolidPaint} ${className}`} data-testid="single-solid-paint-style-thumbnail">
            <svg className={styles.circle} viewBox="0 0 32 32">
                <circle cx="16" cy="16" r="16" fill={`rgba(${color.r}, ${color.g}, ${color.b}, ${opacity})`}></circle>
            </svg>
            <div
                className={classnames(styles.boardBackground, 'mosaic-3')}
                style={{ opacity: 1 - opacity, clipPath: `url("#${id}")` }}
            ></div>
            <svg width="0" height="0">
                <defs>
                    <clipPath id={`${id}`} clipPathUnits="objectBoundingBox">
                        <path d="M 0.146 0.146 A 0.5 0.5 225 0 1 0.854 0.854"></path>
                    </clipPath>
                </defs>
            </svg>
            <svg className={styles.border} viewBox="0 0 32 32">
                <circle cx="16" cy="16" r="15.5" fill="none" strokeWidth="1"></circle>
            </svg>
        </div>
    )
}

export function LocalStyleColorListItemThumnail({
    id,
    contentHash,
    paints,
    className,
}: {
    id: string
    contentHash: string
    paints: readonly Paint[]
    className: string
}) {
    const { isImageLoaded } = useImageLoaded(getPaintsHash(paints))
    if (paints.length === 1 && paints[0].type == Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT) {
        return <SingleSolidPaintStyleThumbnail paint={paints[0] as SolidPaint} className={className} />
    }

    return (
        <LibraryThumbnailImage
            className={className}
            thumbnailData={buildThumbnailDataFromLocalPaintStyle({ id, contentHash })}
            isImageLoaded={isImageLoaded}
        />
    )
}
