import { useEffect, useMemo, useRef } from 'react'
import { showMemoryAlertDialog } from './show-memory-alert-dialog'
import { MemoryUsageAlertType, getAlertTypeByUsage, useMemoryUsageValue } from './use-memory-usage-value'

export function useMemoryUsageCrashDialog(usedMemory: number) {
    const { memoryUsedPercent } = useMemoryUsageValue(usedMemory)
    const alertType = useMemo(() => getAlertTypeByUsage(memoryUsedPercent), [memoryUsedPercent])
    const hasInvokeAlertDialog = useRef(false)
    useEffect(() => {
        if (!hasInvokeAlertDialog.current) {
            if (alertType === MemoryUsageAlertType.FORBIDDEN) {
                hasInvokeAlertDialog.current = true
                showMemoryAlertDialog()
            }
        }
    }, [alertType])
}
