import { Controller, Control, FieldValues, Path } from 'react-hook-form'
import { NormalInput, NormalInputProps } from '../inputs/normal-input/normal-input'
import { PasswordInput, PasswordInputProps } from '../inputs/password-input/password-input'
import { Textarea, TextareaProps } from '../inputs/textarea/textarea'
import { isNil } from 'lodash-es'
import { Select, SelectNormalSingleLevelProps } from '../selects'
import { SelectOptionProps } from '../selects/picks/single-level/select-option'
import { Checkbox } from '../checkbox'
import { WKRadio, WKRadioGroup, WKRadioGroupProps, WKRadioProps } from '../wk-radio'
// ui-lib 中的组件如果要当做表单组件使用，必须在这里包一层改为受控组件之后再导出使用
// 需要支持 reset

export const FormCompNormalInput = <T extends FieldValues>({
    name,
    control,
    inputProps,
}: {
    name: Path<T>
    control: Control<T>
    inputProps?: NormalInputProps
}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, formState }) => {
                const { ...rest } = inputProps ?? {}
                const errorMsg = formState.errors[name]?.message as string
                return (
                    <NormalInput
                        {...rest}
                        {...field}
                        error={{
                            show: !!errorMsg,
                            tipMessage: errorMsg,
                        }}
                        ref={(r) => {
                            // @ts-expect-error
                            field.ref = r?.getInputElement() as HTMLInputElement
                        }}
                    />
                )
            }}
        />
    )
}

export const FormCompPasswordInput = <T extends FieldValues>({
    name,
    control,
    inputProps,
}: {
    name: Path<T>
    control: Control<T>
    inputProps?: PasswordInputProps
}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, formState }) => {
                const { ...rest } = inputProps ?? {}
                const value = isNil(field.value) ? '' : field.value
                const errorMsg = formState.errors[name]?.message as string
                return (
                    <PasswordInput
                        {...rest}
                        {...field}
                        value={value}
                        error={{
                            show: !!errorMsg,
                            tipMessage: errorMsg,
                        }}
                        ref={(r) => {
                            // @ts-expect-error
                            field.ref = r?.getInputElement() as HTMLInputElement
                        }}
                    />
                )
            }}
        />
    )
}

export const FormCompTextarea = <T extends FieldValues>({
    name,
    control,
    inputProps,
}: {
    name: Path<T>
    control: Control<T>
    inputProps?: TextareaProps
}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, formState }) => {
                const { ...rest } = inputProps ?? {}
                const value = isNil(field.value) ? '' : field.value
                const errorMsg = formState.errors[name]?.message as string
                return (
                    <Textarea
                        {...rest}
                        {...field}
                        value={value}
                        error={{
                            show: !!errorMsg,
                            tipMessage: errorMsg,
                        }}
                        ref={(r) => {
                            // @ts-expect-error
                            field.ref = r?.getTextareaElement()!
                        }}
                    />
                )
            }}
        />
    )
}

export const FormCompSelectNormalSingleLevel = <T extends FieldValues>({
    name,
    control,
    options,
    onChange,
    selectProps,
}: {
    name: Path<T>
    control: Control<T>
    // 有 label 优先用 label 没有用 children
    options: (SelectOptionProps & { label?: string })[]
    onChange?: (value: T[Path<T>]) => void
    selectProps?: Omit<SelectNormalSingleLevelProps, 'children' | 'value' | 'onChange'>
}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, formState }) => {
                const selected = options.find((option) => option.value === field.value)
                const label = selected?.label ?? selected?.children
                const errorMsg = formState.errors[name]?.message as string
                return (
                    <Select.NormalSingleLevel
                        {...selectProps}
                        error={{
                            show: !!errorMsg,
                            message: errorMsg,
                        }}
                        value={field.value}
                        label={label}
                        onChange={(e) => {
                            field.onChange(e)
                            onChange?.(e)
                        }}
                    >
                        {options.map((option) => (
                            <Select.NormalSingleLevel.Option key={JSON.stringify(option.value)} {...option} />
                        ))}
                    </Select.NormalSingleLevel>
                )
            }}
        />
    )
}

export const FormCompCheckboxGroup = <T extends FieldValues>({
    name,
    control,
    options,
}: {
    name: Path<T>
    control: Control<T>
    options: { label: string; value: string | number }[]
}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, formState }) => {
                const value = (field.value as Array<string | number>) ?? []
                const errorMsg = formState.errors[name]?.message as string
                return (
                    <div className="flex gap-1 flex-col">
                        <div className="flex gap-x-4 flex-wrap" onBlur={field.onBlur}>
                            {options.map((option) => (
                                <Checkbox
                                    autoBlur={false}
                                    key={option.value}
                                    onChange={(checked) => {
                                        if (checked) {
                                            field.onChange([...value, option.value])
                                        } else {
                                            field.onChange(value.filter((v) => v !== option.value))
                                        }
                                    }}
                                    checked={value.includes(option.value)}
                                    label={option.label}
                                />
                            ))}
                        </div>
                        {errorMsg && <div className="wk-text-12 color-$wk-red-8">{errorMsg}</div>}
                    </div>
                )
            }}
        />
    )
}

export const FormCompRadioGroup = <T extends FieldValues>({
    name,
    control,
    options,
    radioGroupProps,
}: {
    name: Path<T>
    control: Control<T>
    options: WKRadioProps[]
    radioGroupProps?: Omit<WKRadioGroupProps, 'children' | 'value' | 'onValueChange'>
}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, formState }) => {
                const errorMsg = formState.errors[name]?.message as string
                return (
                    <div onBlur={field.onBlur}>
                        <WKRadioGroup
                            verticalGap={16}
                            value={field.value ?? ''}
                            onValueChange={field.onChange}
                            {...radioGroupProps}
                        >
                            {options.map((option) => (
                                <WKRadio key={option.value} {...option} />
                            ))}
                        </WKRadioGroup>
                        {errorMsg && <div className="wk-text-12 color-$wk-red-8">{errorMsg}</div>}
                    </div>
                )
            }}
        />
    )
}
