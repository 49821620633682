/* eslint-disable no-restricted-imports */
import { forwardRef } from 'react'
import { ToKeyCode } from '../../../../document/util/keycode'
import { KeyboardReceiver } from '../../../../main/keyboard-receiver/component'
import { ColorPanel } from '../color-interaction/color-panel/color-panel'
import { ColorInteractionFrom } from '../color-interaction/type'
import { PaintStruct } from './paint-struct'
import { PaintProps, usePaint } from './use-paint'

export const ColorStylePaint = forwardRef(function Component(props: PaintProps, outRef: React.Ref<HTMLDivElement>) {
    const {
        switchModalFalse,
        switchModalState,
        onChangePaintOpacity,
        onChangePaintVisible,
        onClickDetachColorVar,
        position,
        reactRef,
        paint,
        colorVar,
    } = usePaint(props, outRef)

    return (
        <>
            <PaintStruct
                ref={reactRef}
                className={props.className}
                paint={paint}
                colorVar={colorVar}
                colorSpace={props.colorSpace}
                selected={props.selected}
                isMini={props.isMini}
                disabled={props.disabled}
                onChangeColor={props.onChangeColor}
                onClickColorBlock={switchModalState}
                onClickColorInput={switchModalFalse}
                onClose={switchModalFalse}
                onChangeOpacity={onChangePaintOpacity}
                onChangeVisible={onChangePaintVisible}
                onClickSub={props.onClickSub}
                onDetachColorVar={onClickDetachColorVar}
            />

            {props.isOpen && position && (
                <KeyboardReceiver keyCode={ToKeyCode.Esc} onKeydown={() => (switchModalFalse(), false)}>
                    <ColorPanel
                        from={ColorInteractionFrom.STYLE_PAINT}
                        position={position}
                        onCancel={switchModalFalse}
                        paint={paint}
                        onChangePaintType={props.onChangePaintType!}
                        onChangeBlendMode={props.onChangeBlendMode!}
                        onChangeColor={props.onChangeColor}
                        onChangeOpacity={props.onChangeOpacity}
                        onChangeImagePaint={props.onChangeImagePaint}
                        onChangeColorStops={props.onChangeColorStops!}
                        onChangeTransform={props.onChangeTransform}
                        enterClose={switchModalFalse}
                        styleId={undefined}
                        onClickAddIcon={undefined}
                        onClickEditStyle={() => {}}
                        onChangeColorVar={props.onChangeColorVar}
                        onChangePaints={undefined}
                        onClickCreateStyleButton={undefined}
                    />
                </KeyboardReceiver>
            )}
        </>
    )
})
