/* eslint-disable no-restricted-imports */
import { POST, ResponseType } from '@tutor/network-core'
import { domLocation, getReleaseTag } from '../../../../util/src'
import { DocID, RoleStatus } from '../../kernel/interface/type'
import { BaseCommonRequest } from '../../kernel/request/base-request'

/**
 * 通过服务端 HTTP 接口得到的 UserProfile 结构
 */
export interface Ticket {
    needPassword?: boolean // 需要输入密码访问文件
    documentName?: string
    userId: number
    docId: DocID
    secretKey?: string
    ticketToken?: string
    orgId: string
    role: RoleStatus
    wsUrl?: string
    clientId?: number
    release: string // 客户端版本号
    orgRole?: RoleStatus.Admin | RoleStatus.Editor | RoleStatus.None
    domainMatched?: boolean
    /**
     * 值为true时，表示当前文档在草稿箱下，当前用户不是文档所属草稿箱owner，并且文档所属的草稿箱所有者被限制分享编辑权限
     */
    draftAndOwnerShareEditLimited: boolean
    /**
     * 值为true时，表示用户当前只能进只读模式，但是可以通过升级席位来编辑文档
     */
    canEditDocByUpgradeSeat: boolean
    /**
     * 履约是否已锁定，值为true时表示这个履约单位下所有资源只读，此时canEditDocByUpgradeSeat和draftAndOwnerShareEditLimited字段无效
     */
    planFreeze: boolean
    /**
     *  废弃!!!无法响应协同. 值为true表示当前文档在企业或者其他团队的团队下，并且当前用户对企业或者团队有管理权限
     */
    manager: boolean
    /**
     * 值为true表示当前资源使用超限
     */
    exceedUsageLimit: boolean
    /**
     * 值为true表示当前文档在企业或者其他团队的团队下，并且当前可以通过付费进入编辑模式，原因是当前账单逾期锁定或者基础版团队使用超限
     */
    canEditDocByUpgradePlan: boolean
    /**
     * 值为true表示当前文档所属的企业或者团队是试用状态
     */
    trial: boolean
    schemaVersion: number
}

export interface GetTicketRequestOptions {
    password?: string
    clientId?: number
    mirror?: boolean
    scene?: string
    switchesSnapshot?: string
}

/**
 * 通过 DocId 获取 Ticket
 */
@POST
export class GetTicketByDocId extends BaseCommonRequest<Ticket> {
    constructor(private readonly docId: DocID, private readonly options?: GetTicketRequestOptions) {
        super()
    }

    override requestUrl(): string {
        return `ticket`
    }

    override responseType(): ResponseType {
        return ResponseType.JSON
    }

    public override requestArgument(): { [p: string]: string | number } | undefined {
        const query = domLocation().hash.split('?')[1]
        const params = new URLSearchParams(query)

        const args: { [key: string]: string } = { docId: this.docId }
        if (this.options?.password) {
            args.password = this.options.password
        }
        if (this.options?.clientId) {
            args.clientId = this.options.clientId.toString()
        }
        if (this.options?.mirror !== undefined) {
            args.mirror = String(this.options.mirror)
        }
        if (this.options?.scene !== undefined) {
            args.scene = this.options.scene
        }

        const debugRole = params.get('_debug_role_')
        if (debugRole) {
            args._debug_role_ = debugRole
        }

        const flag = getReleaseTag()
        if (flag) {
            args.release = flag
        }
        return args
    }

    public override requestBody() {
        return {
            featureSwitches: this.options?.switchesSnapshot,
        }
    }
}
