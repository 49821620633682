export const Ux = [
    'a276a0a764a2156e196a98d8ab0bd2efa7688881',
    '09e22046c1b30e2f7f2520b5eef9874ac2f4552f',
    'd0c8eba207a28fa653ddf5c63cd69bf98472440f',
    'dab35ffb6d93e8ecee258dca6c7f5d98bb7e4ea6',
    '216a0a529993e408ddd1a7b86fb0865297b9f9fd',
    'a33872d486c5180a931f00bfe0e7f5315f7db58c',
    'bb106601a0fcca27f379ca7e6b4864378c73988b',
    'c069c83b2798facaf1fe3c9a6c730f64d5fae6dd',
    '15ec0e98b04aa6e2234692ab566876347627f567',
    '1ccf399dc7448f43145bf04127246e820a7b2e2b',
    '4ce085bb6f6b614222957706acdb09f8b31e4d9a',
    'fe1503ac811a31745d4d2edbfc2eabdfdea62141',
    'e84422849b14819e3355db2154c7f40e3413f527',
    '0943557b08daec7e73cc41811b0ae4cedd000b6a',
    '6dde650ae86a3eb320ae6482a28411a0fb6c3414',
    'a5a628b6f09d94f5ef30707fa81c5842ff7289c4',
    'f28932d38332f3dc6e215c7d4788af2582710ccf',
]
