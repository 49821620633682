import { assertNever } from '../../../../../util/src'
import { translation } from './library-component-viewer-context-menu.translation'

// 组件库-组件缩略图的右键菜单项相关配置
export enum LibraryComponentViewerContextMenuType {
    Jump2Document = 'jump2Document', // {translation('GoToMain')} doc
    PublishHidden = 'publishHidden', // 发布时隐藏
    PublishShow = 'publishShow', // 发布时展示
}

function LibraryComponentViewerContextMenuType2Label(type: LibraryComponentViewerContextMenuType) {
    switch (type) {
        case LibraryComponentViewerContextMenuType.Jump2Document:
            return translation('GoToMain')
        case LibraryComponentViewerContextMenuType.PublishShow:
            return translation('ShowWhenPublishing')
        case LibraryComponentViewerContextMenuType.PublishHidden:
            return translation('HideWhenPublishing')
        default:
            return assertNever(type, translation('KWgoOK'))
    }
}

export function getLibraryComponentViewerContextMenuItemsByTypes(types: LibraryComponentViewerContextMenuType[]) {
    return types.map((type) => ({ key: type, label: LibraryComponentViewerContextMenuType2Label(type) }))
}
