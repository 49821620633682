import { filePicker } from '../../../../util/src'
import type { CommandInvoker } from '../../document/command/command-invoker'
import { cmdImagesSelected } from '../../document/command/image-command'
import { ImageDownloadContext } from '../../document/command/image-download-context'

function pickImages(commandInvoker: CommandInvoker, imageDownloadContext: ImageDownloadContext) {
    filePicker({
        multiple: true,
        accept:
            'image/jpeg,image/png,image/gif,image/bmp,image/webp,image/svg+xml' +
            // WebP
            ',.webp' +
            // heif
            ',.heif,.heifs,.heic,.heics,.avci,.avcs,.avif,.avifs',
    }).then((files) => {
        if (!files?.length) {
            return
        }

        commandInvoker.invoke(cmdImagesSelected, imageDownloadContext, files)
    })
}

export const selectImageToolbarHandler = (
    commandInvoker: CommandInvoker,
    imageDownloadContext: ImageDownloadContext
) => {
    return () => {
        pickImages(commandInvoker, imageDownloadContext)
    }
}

export const selectImageShortcutHandler = (
    commandInvoker: CommandInvoker,
    imageDownloadContext: ImageDownloadContext
) => {
    return (event: KeyboardEvent) => {
        event.preventDefault()
        pickImages(commandInvoker, imageDownloadContext)

        return false
    }
}
