import { PayPlanType } from '../../../../../kernel/interface/type'
import { PrivilegeCard, PrivilegeCardProps } from './privilege-card'
import { translation } from './privilege-card-starter.translation'

export function PrivilegeCardStarter({
    currentPlanType,
    onSubmit,
    ...otherProps
}: Pick<PrivilegeCardProps, 'className' | 'privileges'> & {
    currentPlanType: PayPlanType.professional | PayPlanType.starter
    onSubmit?: () => void
}) {
    return (
        <PrivilegeCard
            {...otherProps}
            name={translation('Starter')}
            price={undefined}
            buttonProps={{
                type: 'secondary',
                children:
                    currentPlanType === PayPlanType.starter ? translation('CurrentPlan') : translation('ChooseStarter'),
                disabled: currentPlanType === PayPlanType.starter,
                onClick: onSubmit,
            }}
            dataTestIds={{
                title: 'starter-title',
                price: 'starter-price',
                button: 'starter-button',
                privileges: 'starter-privileges',
                prefixPrivilege: 'starter-privilege',
            }}
        />
    )
}
