import { translation } from './comment-dom.translation'
/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { useCallback, useMemo, useState } from 'react'
import { Badge, IconResolve, Tooltip } from '../../../../../../ui-lib/src'
import { capitalizeFirstLetter, timeDesc2 } from '../../../../../../util/src'
import { CommentUser } from '../../../../kernel/interface/comment'
import { IconButton } from '../../atom/button/icon-button'
import { CommentAvatar } from '../comment-avatar/comment-avatar'
import { deleteCommentConfirmModal } from '../common-component/delete-comment-modal'
import { MoreCommentSettingsV2, MoreCommentSettingsV2Props } from '../common-component/more-comment-settings'
import { ShowMessage } from '../show-message/show-message'
import classes from './comment-dom.module.less'

export interface CommentDOMProps {
    usersMap: Map<CommentUser['id'], CommentUser>
    isActive?: boolean
    isHover?: boolean
    isUnread?: boolean
    isResolved?: boolean
    isOwner?: boolean
    isHideResolvedIcon?: boolean
    showUsers: CommentUser[]
    showUsersOverlaySize: number
    nickname: string
    createdTime: number
    message: string
    repliesSum: number
    seq: number
    pageName?: string
    onCopyLink?: () => void
    onSetUnread?: () => void
    onSetRead?: () => void
    onSetResolve?: () => void
    onSetUnResolve?: () => void
    onDeleteComment?: () => void
    onClickComment: () => void
    dataTestIds?: {
        container?: string
        resolvedIcon?: string
    } & MoreCommentSettingsV2Props['dataTestIds']
}

export function CommentDOM(props: CommentDOMProps) {
    const { onCopyLink, onSetUnread, onSetRead, onSetResolve, onSetUnResolve, onDeleteComment } = props
    const [isOpenMoreTool, setIsOpenMoreTool] = useState<boolean>(false)

    const switchOpen = useCallback(() => {
        setIsOpenMoreTool(!isOpenMoreTool)
    }, [isOpenMoreTool])

    const _onCopyLink = useCallback(() => {
        onCopyLink?.()
    }, [onCopyLink])

    const _onSetUnread = useCallback(() => {
        onSetUnread?.()
    }, [onSetUnread])

    const _onSetRead = useCallback(() => {
        onSetRead?.()
    }, [onSetRead])

    const toggleResolveComment = useCallback(() => {
        if (props.isResolved) {
            onSetUnResolve?.()
        } else {
            onSetResolve?.()
        }
    }, [props.isResolved, onSetUnResolve, onSetResolve])

    const openModal = useCallback(async () => {
        deleteCommentConfirmModal({ onOk: onDeleteComment })
    }, [onDeleteComment])

    return useMemo(
        () => (
            <div
                data-testid={props.dataTestIds?.container}
                className={classnames(classes.comment, {
                    [classes.hover]: props.isHover,
                    [classes.active]: props.isActive,
                    [classes.unread]: props.isUnread,
                    [classes.openPopup]: isOpenMoreTool,
                })}
                onClick={props.onClickComment}
            >
                <div className={classes.commentContent}>
                    <div className={classes.title}>
                        <Badge.Dot className={classes.dot} />
                        <div className={classes.avatarContent}>
                            {props.showUsersOverlaySize ? (
                                <span
                                    className={classnames(classes.moreCommentAvatar, classes.commentAvatar)}
                                    style={{
                                        width: props.showUsersOverlaySize.toString().length * 8 + 16,
                                    }}
                                >
                                    +{props.showUsersOverlaySize}
                                </span>
                            ) : null}
                            {props.showUsers?.map((user) => (
                                <CommentAvatar key={user.id} userInfo={user} className={classes.commentAvatar} />
                            ))}
                        </div>
                        <div className={classes.ctrl} onClick={(e) => e.stopPropagation()}>
                            <MoreCommentSettingsV2
                                placement="bottom right"
                                className={classes.svgMore}
                                classNameTriggerFocus={classes.triggerFocus}
                                isOwner={props.isOwner}
                                isUnread={props.isUnread}
                                onClose={switchOpen}
                                onOpen={switchOpen}
                                onCopyLink={_onCopyLink}
                                onSetRead={_onSetRead}
                                onSetUnread={_onSetUnread}
                                onDeleteComment={openModal}
                                dataTestIds={props.dataTestIds}
                            />
                            {props.isHideResolvedIcon ? null : (
                                <Tooltip
                                    title={
                                        props.isResolved
                                            ? translation('MaskAsUnresolved')
                                            : translation('MaskAsResolved')
                                    }
                                >
                                    <IconButton
                                        className={classnames(classes.svgResolve, {
                                            [classes.resolved]: props.isResolved,
                                        })}
                                        selected={false}
                                        icon={<IconResolve />}
                                        onClick={toggleResolveComment}
                                        dataTestId={props.dataTestIds?.resolvedIcon}
                                    />
                                </Tooltip>
                            )}
                        </div>
                    </div>
                    <div className={classes.content}>
                        <div className={classes.contentTitle}>
                            <div className={classes.name}>{props.nickname}</div>
                            <div className={classes.time}>{capitalizeFirstLetter(timeDesc2(props.createdTime))}</div>
                        </div>
                        <div className={classes.message}>
                            <ShowMessage
                                message={props.message}
                                usersMap={props.usersMap}
                                className={classes.forbidSelect}
                            />
                        </div>
                        {props.repliesSum > 0 ? (
                            <div className={classes.repliesSum}>
                                {translation('Replies', {
                                    reply: props.repliesSum === 1 ? 'reply' : 'replies',
                                    count: props.repliesSum,
                                })}
                            </div>
                        ) : null}
                    </div>
                    <div className={classes.footer}>
                        <span>
                            #{props.seq} · {props.pageName}
                        </span>
                    </div>
                </div>
            </div>
        ),
        [
            props.dataTestIds,
            props.isHover,
            props.isActive,
            props.isUnread,
            props.onClickComment,
            props.showUsersOverlaySize,
            props.showUsers,
            props.isHideResolvedIcon,
            props.isResolved,
            props.nickname,
            props.createdTime,
            props.message,
            props.usersMap,
            props.repliesSum,
            props.seq,
            props.pageName,
            props.isOwner,
            isOpenMoreTool,
            switchOpen,
            toggleResolveComment,
            _onCopyLink,
            _onSetRead,
            _onSetUnread,
            openModal,
        ]
    )
}
