import { Wukong } from '@wukong/bridge-proto'
import { createSelectors, createStore } from '../../../../util/src'
import { getChromeVersion, isChrome, isFirefox, isMac, isSafari, isWindows } from '../../kernel/util/ua'
import { PluginExported } from '../../ui/component/plugin/type'
import { ReactComponent as HandleWheelDebugIcon } from './handle-wheel-debug-icon.svg'

const isIpadOS = /Macintosh/i.test(navigator.userAgent) && !!(navigator.maxTouchPoints && navigator.maxTouchPoints > 1)
const isIpad = !/iP(hone|od)/i.test(navigator.userAgent) && (/iPad/i.test(navigator.userAgent) || isIpadOS)
const isChromeVersionGreaterThan118 = () => isChrome() && getChromeVersion()! > 118

// delta 数值的边界
export const DefaultDeltaAbsRange = 120
const getDeltaAbsValue = (value: number, max: number) => Math.min(max, Math.max(max * -1, value))

// 暂不处理 chrome os
const isChromeOS = () => false

// 暂时不清楚这个判定的来源和场景
const isPinchZoomFixDisabled = () => false

// https://developer.mozilla.org/en-US/docs/Web/API/WheelEvent
declare global {
    interface WheelEvent {
        wheelDeltaX: number
        wheelDeltaY: number
    }
}

// https://www.notion.so/kanyun/Figma-handleWheel-225ba5f6f0f7451c85a4410fe70ed1c5
export class HandleWheelEvent {
    private lastMultiplier = 0
    private lastTimestamp = 0
    private lastDirectionX = 0
    private lastDirectionY = 0

    private debugStore = createStore<DebugStore>((set) => ({
        isNonPixelDeltaMode: false,
        isWheelDeltaSupported: false,
        isFractionalDelta: false,
        isLikelyTrackpad: false,
        originalDelta: [0, 0],
        finalDelta: [0, 0],
        deltaAbsRange: DefaultDeltaAbsRange,
        changeDeltaAbsRange: (v: number) =>
            set({
                deltaAbsRange: v,
            }),
    }))
    constructor() {
        window.wukong.wheelEventDebugger = createSelectors(this.debugStore).use
    }
    destroy() {
        delete window.wukong.wheelEventDebugger
    }

    handleWheel(e: WheelEvent) {
        let deltaX = e.deltaX
        let deltaY = e.deltaY
        const isNonPixelDeltaMode = e.deltaMode !== 0 // DOM_DELTA_PIXEL
        if (!isMac() && e.shiftKey) {
            deltaX = e.deltaY
            deltaY = e.deltaX
        }

        let wheelDeltaX = 0
        let wheelDeltaY = 0
        const isWheelDeltaSupported = e.wheelDeltaY != undefined
        if (isWheelDeltaSupported) {
            wheelDeltaX = e.wheelDeltaX
            wheelDeltaY = e.wheelDeltaY
            if (!isMac() && e.shiftKey) {
                wheelDeltaX = e.wheelDeltaY
                wheelDeltaY = e.wheelDeltaX
            }
        }

        let isLikelyTrackpad = !e.ctrlKey
        const isFractionalDelta = !Number.isInteger(deltaX) || !Number.isInteger(deltaY)
        if (isIpad) {
            isLikelyTrackpad = true
        } else {
            if (isSafari() || isChrome()) {
                isLikelyTrackpad = !isFractionalDelta
            } else if (isFirefox()) {
                isLikelyTrackpad = !isFractionalDelta && deltaY % 16 !== 0
                if (deltaX !== 0 && deltaX !== 0) {
                    isLikelyTrackpad = true
                }
            }
        }

        const devicePixelRatio = window.devicePixelRatio
        if (!isChromeVersionGreaterThan118()) {
            deltaX /= devicePixelRatio
            deltaY /= devicePixelRatio
            wheelDeltaX /= devicePixelRatio
            wheelDeltaY /= devicePixelRatio
        }
        if (isIpad) {
            deltaX /= devicePixelRatio
            deltaY /= devicePixelRatio
        }

        if (isChromeOS()) {
            if (e.ctrlKey && wheelDeltaY % 120 === 0) {
                deltaY *= 5
            }
        } else if (isWindows() && (isPinchZoomFixDisabled() || !isFractionalDelta)) {
            if (isChrome()) {
                deltaX = -wheelDeltaX
                deltaY = -wheelDeltaY
            } else if (isFirefox() && isNonPixelDeltaMode) {
                const FIREFOX_LINE_HEIGHT = 40
                deltaX *= FIREFOX_LINE_HEIGHT
                deltaY *= FIREFOX_LINE_HEIGHT
            }

            const lowResCutoff = 100 - 1
            const lowResMouse = Math.abs(deltaX) >= lowResCutoff || Math.abs(deltaY) >= lowResCutoff
            if (lowResMouse) {
                deltaX /= 120
                deltaY /= 120
                const timestamp = e.timeStamp / 1000
                const directionX = Math.sign(deltaX)
                const directionY = Math.sign(deltaY)
                let multiplier = 8
                if (
                    timestamp - this.lastTimestamp < 0.05 &&
                    directionX == this.lastDirectionX &&
                    directionY == this.lastDirectionY
                ) {
                    multiplier = this.lastMultiplier * 2.5
                }

                deltaX *= multiplier
                deltaY *= multiplier

                this.lastMultiplier = multiplier
                this.lastTimestamp = timestamp
                this.lastDirectionX = directionX
                this.lastDirectionY = directionY
            }
        }

        const deltaAbsRange = this.debugStore.getState().deltaAbsRange
        deltaX = getDeltaAbsValue(deltaX, deltaAbsRange)
        deltaY = getDeltaAbsValue(deltaY, deltaAbsRange)

        this.debugStore.setState({
            isNonPixelDeltaMode,
            isWheelDeltaSupported,
            isFractionalDelta,
            isLikelyTrackpad,
            originalDelta: [e.deltaX, e.deltaY],
            finalDelta: [deltaX, deltaY],
        })

        return {
            deltaX,
            deltaY,
            isLikelyTrackpad,
        }
    }
}

// debug 功能
type NonOptional<T> = T extends undefined ? never : T
type NonOptionalDebugger = NonOptional<typeof window.wukong.wheelEventDebugger>
type DebugStore = { [key in keyof NonOptionalDebugger]: ReturnType<NonOptionalDebugger[key]> }

// debug 弹框
function WheelEventDebugModal() {
    const store = window.wukong.wheelEventDebugger
    if (!store) {
        return <>初始化错误</>
    }
    const isNonPixelDeltaMode = store.isNonPixelDeltaMode()
    const isWheelDeltaSupported = store.isWheelDeltaSupported()
    const isFractionalDelta = store.isFractionalDelta()
    const isLikelyTrackpad = store.isLikelyTrackpad()
    const [originalDeltaX, originalDeltaY] = store.originalDelta()
    const [finalDeltaX, finalDeltaY] = store.finalDelta()

    const deltaAbsRange = store.deltaAbsRange()
    const updateDeltaAbsRange = store.changeDeltaAbsRange()
    return (
        <div className="p-[12px] flex flex-col gap-[6px]">
            <>
                <div className="flex justify-between items-center">
                    <span>
                        <b>isChromeVersionGreaterThan118</b>
                        <pre>{`(isChrome && chromeVersion > 118 )`}</pre>
                    </span>
                    <span className="text-gray">{String(isChromeVersionGreaterThan118())}</span>
                </div>
                <div className="flex justify-between items-center">
                    <span>
                        <b>isNonPixelDeltaMode</b>
                        <pre>(e.deltaMode !== 0)</pre>
                    </span>
                    <span className="text-gray">{String(isNonPixelDeltaMode)}</span>
                </div>
                <div className="flex justify-between items-center">
                    <span>
                        <b>DPR</b>
                        <pre>(window.devicePixelRatio)</pre>
                    </span>
                    <span className="text-gray">{String(window.devicePixelRatio)}</span>
                </div>
                <div className="flex justify-between items-center">
                    <span>
                        <b>isWheelDeltaSupported</b>
                        <pre>(e.wheelDelta != undefined)</pre>
                    </span>
                    <span className="text-gray">{String(isWheelDeltaSupported)}</span>
                </div>
                <div className="flex justify-between items-center">
                    <span>
                        <b>isFractionalDelta</b>
                        <pre>(!isInteger(delta))</pre>
                    </span>
                    <span className="text-gray">{String(isFractionalDelta)}</span>
                </div>
                <div className="flex justify-between items-center">
                    <span>
                        <b>isLikelyTrackpad</b>
                    </span>
                    <span className="text-gray">{String(isLikelyTrackpad)}</span>
                </div>
                <div className="flex justify-between items-center">
                    <span>
                        <b>原始事件中的 delta 数值</b>
                    </span>
                    <span className="text-gray">
                        ({originalDeltaX}, {originalDeltaY})
                    </span>
                </div>
                <div className="flex justify-between items-center">
                    <span>
                        <b>矫正后的 delta 数值</b>
                    </span>
                    <span className="text-gray">
                        ({finalDeltaX}, {finalDeltaY})
                    </span>
                </div>
                <div className="flex justify-between items-center">
                    <span>
                        <b>正负 delta 最大限制范围</b>
                    </span>
                    <input type="number" value={deltaAbsRange} onChange={(e) => updateDeltaAbsRange(+e.target.value)} />
                </div>
            </>
        </div>
    )
}

export const WheelEventDebugPlugin: PluginExported = {
    manifest: {
        key: Wukong.DocumentProto.PluginType.PLUGIN_TYPE_WHEEL_EVENT_DEBUG,
        name: '滚动事件微调工具',
        width: 500,
        height: 500,
        icon: <HandleWheelDebugIcon />,
    },
    Component: WheelEventDebugModal,
}
