import { Wukong } from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { WKToast } from '../../../../../../ui-lib/src'
import { environment } from '../../../../environment'
import { openIndependentWindow } from '../../../../kernel/util/open-window'
import { VariableSetModeConflictType } from './interface'
import { translation } from './util.translation'

export const getAutoModeName = (autoMode: Wukong.DocumentProto.IAutoModeInfo, source: 'page' | 'layer') => {
    const prefix = source === 'page' ? translation('Default') : translation('Auto')

    if (autoMode.isMixed) {
        return translation('Name', { pre: prefix, mode: translation('Mixed') })
    } else {
        return isNil(autoMode.mode) ? prefix : translation('Name', { pre: prefix, mode: autoMode.mode.modeName })
    }
}

export const handleConflictToast = (
    mode: Wukong.DocumentProto.IVariableSetMode,
    conflictType: Wukong.DocumentProto.VariableSetModeConflictType,
    goToRemoteLibraryChangeList: () => void
) => {
    switch (conflictType) {
        case VariableSetModeConflictType.VARIABLE_SET_MODE_CONFLICT_TYPE_UPDATE: {
            const title = translation('PleaseUpdate', { ModeName: mode.modeName })
            WKToast.show(title, {
                duration: 5000,
                firstButton: {
                    type: 'button',
                    text: translation('CheckUpdate'),
                    onClick: () => goToRemoteLibraryChangeList(),
                },
            })
            break
        }
        case VariableSetModeConflictType.VARIABLE_SET_MODE_CONFLICT_TYPE_PUBLISH: {
            const title = translation('PleasePublish', { ModeName: mode.modeName })
            WKToast.show(title, {
                duration: 5000,
                firstButton: {
                    type: 'button',
                    text: translation('LearnMore'),
                    onClick: () => {
                        openIndependentWindow(environment.helpDocsUrl.variable)
                    },
                },
            })
            break
        }
        case VariableSetModeConflictType.VARIABLE_SET_MODE_CONFLICT_TYPE_NONE: {
            break
        }
    }
}
