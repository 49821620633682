import { translation } from './index.translation'
import { WKButton } from '../../../../../../ui-lib/src/components/wk-button/button'
import { WKDialog } from '../../../../../../ui-lib/src/components/wk-dialog'
import { FC } from 'react'
import { QuotaExccededModalProps } from '../types'
import styles from './index.module.less'
import { Hint } from '../assets/hint'
import { environment } from '../../../../environment'
import { domLocation } from '../../../../../../util/src/dom-env'
import { Dot } from '../assets/dot'
import { isEnglishLanguage } from '../../../../../../util/src/i18n'
import { useEvent } from 'react-use'

export const OfflineModal: FC<QuotaExccededModalProps> = ({ close }) => {
    // 当恢复 online 状态时关闭弹窗
    useEvent('online', () => {
        close()
    })

    return (
        <WKDialog
            titleRender={
                <div
                    className={styles['title-wrapper']}
                    style={{ alignItems: isEnglishLanguage() ? 'flex-start' : 'center' }}
                >
                    <Hint />
                    <div className={styles.title}>{translation('title')}</div>
                </div>
            }
            bodyStyle={{ paddingTop: '12px' }}
            footer={
                <WKButton
                    type="primary"
                    onClick={() => {
                        domLocation().reload()
                    }}
                >
                    {translation('reload')}
                </WKButton>
            }
            maskClosable={false}
            hideFooterLine
            hideTitleLine
            visible
        >
            <div className={styles['description-wrapper']}>
                <div className={styles.description}>
                    <span className={styles['description-first']}>{translation('description')}</span>

                    <div
                        className={styles['description-flex']}
                        style={{ alignItems: isEnglishLanguage() ? 'baseline' : 'center' }}
                    >
                        <Dot />
                        <div style={{ marginLeft: '4px' }}>{translation('description2')}</div>
                    </div>

                    <div
                        className={styles['description-flex']}
                        style={{ alignItems: isEnglishLanguage() ? 'baseline' : 'center' }}
                    >
                        <Dot />
                        <div style={{ marginLeft: '4px' }}>
                            <span>{translation('description3')}</span>
                            <a href={`${environment.helpDocsUrl.quota}`} target="_black">
                                {translation('learnMore')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </WKDialog>
    )
}
