import { EffectController } from '../../../util/src/effect-controller'
import { WukongEditor } from '../editor/wk-wasm-app'
import { EmBridge } from './bridge/em-bridge'
import { WkCLog } from './clog/wukong/instance'
import { EditorService } from './service/editor-service'

export const initKernelContext = (wukongEditor: WukongEditor, controller: EffectController) => {
    const editorService = new EditorService(wukongEditor, controller)
    const bridge = new EmBridge(editorService, controller)

    return {
        editorService,
        bridge,
    }
}

export const setupKernel = (editor: WukongEditor, controller: EffectController) => {
    const { editorService, bridge } = initKernelContext(editor, controller)
    return {
        editorService,
        bridge,
        destroy: () => {
            WkCLog.log(`destroy editor and bridge`)
            editorService.destroy()
            bridge.destroy()
        },
    }
}
