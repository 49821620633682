import { createSelectors, createStore } from '../../../../util/src'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { BehaviorEventEmitter } from '../../../../util/src/event-emitter/behavior-event-emitter'
import { environment } from '../../environment'

export class NetworkService {
    public states = createSelectors(
        createStore<{
            browserOnlineState: boolean
        }>(
            () => ({
                browserOnlineState: true,
            }),
            environment.isDev
        )
    )

    private browserOnlineEventEmitter = new BehaviorEventEmitter<boolean>(true)
    public onBrowserOnlineChangeWithSignal = (signal: TraceableAbortSignal, callback: (online: boolean) => void) => {
        this.browserOnlineEventEmitter.onWithSignal(signal, callback)
    }

    constructor(signal: TraceableAbortSignal) {
        /**
         * 判断当前浏览器是否是通网状态
         * 不能用 navigator.onLine：返回 false 时不一定可信: chrome 中即使联网也有可能返回 false；返回 true 时不一定可信: 只能连上局域网则也可能返回 true
         *
         * @link https://developer.mozilla.org/en-US/docs/Web/API/Navigator/onLine
         * @link https://stackoverflow.com/questions/47447357/chrome-navigator-online-is-incorrectly-false
         */
        window.addEventListener(
            'online',
            () => {
                this.updateBrowserOnlineState(true)
            },
            { signal }
        )

        window.addEventListener(
            'offline',
            () => {
                this.updateBrowserOnlineState(false)
            },
            { signal }
        )
    }

    private updateBrowserOnlineState = (online: boolean) => {
        this.browserOnlineEventEmitter.next(online)

        this.states.setState({ browserOnlineState: online })
    }
}
