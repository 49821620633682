import { Wukong } from '@wukong/bridge-proto'
import { useCallback, useState } from 'react'
import { useMount } from 'react-use'
import {
    MonoIconCommonAdd16,
    MonoIconPanelRotate16,
    MonoIconPanelStrokePointChange16,
    OmitToJSON,
    Scrollbar,
    Select,
    Tooltip,
    usePointerCapture,
    WKIconButton,
} from '../../../../../../../ui-lib/src'
import { CommitType } from '../../../../../document/command/commit-type'
import { cmdSelectedGradientColorStopIndex } from '../../../../../document/command/document-command'
import { Matrix } from '../../../../../document/util/matrix'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { Title } from '../../../atom/title/title'
import { useRenderColorSpace } from '../../../color-profile'
import { getRightColorStopIndex } from '../../blend/blend-gradient/gradient-picker'
import { Ribbon } from '../../blend/blend-gradient/ribbon'
import { copyColorStop, getCenterColorStop, getMinPositionIndex } from '../../blend/blend-gradient/utils'
import { paintTypeToShow } from '../../blend/blend-title'
import { BlendGradientProps } from '../type'
import classes from './blend-gradient.module.less'
import { translation } from './blend-gradient.translation'
import { GradientBlock } from './gradient-block'
import { PaintColorStop } from './paint-color-stop'

export function BlendGradient(props: BlendGradientProps) {
    const { type, colorStops, transform, onChangeColorStops, onChangeTransform } = props
    const selectedGradientColorStopIndex = useViewState('selectedGradientColorStopIndex') || 0
    const colorSpace = useRenderColorSpace()
    const command = useCommand()
    const [isOpen, setIsOpen] = useState(false)

    const switchSelectedGradientColorStopIndex = useCallback(
        (index: number) => {
            if (selectedGradientColorStopIndex === index) {
                return
            }
            command.invoke(cmdSelectedGradientColorStopIndex, index)
        },
        [command, selectedGradientColorStopIndex]
    )

    const onClickSwitch = useCallback(() => {
        const newColorStops = colorStops.map((colorStop) =>
            Object.assign({}, colorStop, { position: 1 - colorStop.position })
        )
        onChangeColorStops?.(newColorStops)
        command.commitUndo()
    }, [colorStops, command, onChangeColorStops])

    const onClickRotate = useCallback(() => {
        let center = { x: 0, y: 0 }
        if (type == Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_LINEAR) {
            center.x = 0.5
            center.y = 0.5
        } else {
            center.x = 0.75
            center.y = 0.5
        }

        const matrix = new Matrix(transform)
        center = matrix.mapVector(center)

        const newTransform = new Matrix(transform)
            .translateX(-center.x)
            .translateY(-center.y)
            .rotate(Math.PI / 2)
            .translateX(center.x)
            .translateY(center.y)
            .valueOf()
        onChangeTransform?.(newTransform)
        command.commitUndo()
    }, [type, transform, onChangeTransform, command])

    const onAddColorStop = useCallback(
        (colorStop: OmitToJSON<Wukong.DocumentProto.ColorStop>) => {
            const newColorStops: OmitToJSON<Wukong.DocumentProto.ColorStop>[] = [...colorStops, colorStop]
            onChangeColorStops?.(newColorStops)
            switchSelectedGradientColorStopIndex(newColorStops.length - 1)
        },
        [colorStops, onChangeColorStops, switchSelectedGradientColorStopIndex]
    )
    const onClickBlock = useCallback(
        (index: number, colorStop: OmitToJSON<Wukong.DocumentProto.ColorStop>, altKey: boolean) => {
            if (altKey) {
                const newColorStop = copyColorStop(colorStop)
                onAddColorStop(newColorStop)
            } else {
                switchSelectedGradientColorStopIndex(index)
            }
        },
        [onAddColorStop, switchSelectedGradientColorStopIndex]
    )

    const onClickAddColorStop = useCallback(() => {
        let newColorStop: OmitToJSON<Wukong.DocumentProto.ColorStop>
        if (colorStops.length === 1) {
            newColorStop = copyColorStop(colorStops[0])
            if (newColorStop.position > 0.5) {
                newColorStop.position = 0
            } else {
                newColorStop.position = 1
            }
        } else {
            const currentSelectedColorStop =
                colorStops[selectedGradientColorStopIndex] ?? colorStops[colorStops.length - 1]
            if (!currentSelectedColorStop) {
                return
            }
            let leftColorStop: OmitToJSON<Wukong.DocumentProto.ColorStop> | undefined
            let rightColorStop: OmitToJSON<Wukong.DocumentProto.ColorStop> | undefined
            colorStops.forEach((colorStop, index) => {
                if (index === selectedGradientColorStopIndex) {
                    return
                }
                if (colorStop.position < currentSelectedColorStop.position) {
                    if (!leftColorStop) {
                        leftColorStop = colorStop
                    } else if (colorStop.position > leftColorStop.position) {
                        leftColorStop = colorStop
                    }
                } else if (colorStop.position > currentSelectedColorStop.position) {
                    if (!rightColorStop) {
                        rightColorStop = colorStop
                    } else if (colorStop.position < rightColorStop.position) {
                        rightColorStop = colorStop
                    }
                } else {
                    if (index < selectedGradientColorStopIndex) {
                        leftColorStop = colorStop
                    } else {
                        rightColorStop = colorStop
                    }
                }
            })
            newColorStop = rightColorStop
                ? getCenterColorStop(currentSelectedColorStop, rightColorStop)
                : leftColorStop
                ? getCenterColorStop(currentSelectedColorStop, leftColorStop)
                : copyColorStop(currentSelectedColorStop)
        }
        onAddColorStop(newColorStop)
    }, [colorStops, onAddColorStop, selectedGradientColorStopIndex])

    const onDeleteColorStop = useCallback(
        (index: number) => {
            if (colorStops.length < 2) {
                return
            }
            const newColorStops = colorStops.filter((v, site) => index !== site)
            const nextIndex = getMinPositionIndex(newColorStops)

            switchSelectedGradientColorStopIndex(nextIndex)
            onChangeColorStops?.(newColorStops)
        },
        [colorStops, onChangeColorStops, switchSelectedGradientColorStopIndex]
    )

    const onChangeColorStop = useCallback(
        (index: number, colorStop: OmitToJSON<Wukong.DocumentProto.ColorStop>, options?: InputOptionsForUndoSquash) => {
            const nextColorStops = [...colorStops].map((v, site) => (site === index ? colorStop : v))
            onChangeColorStops?.(nextColorStops, options)
        },
        [colorStops, onChangeColorStops]
    )

    const onClickColorBlock = useCallback(
        (index: number) => {
            switchSelectedGradientColorStopIndex(index)
            if (index === selectedGradientColorStopIndex) {
                setIsOpen((v) => !v)
            } else {
                setIsOpen(true)
            }
        },
        [selectedGradientColorStopIndex, switchSelectedGradientColorStopIndex]
    )

    const capturing = useCallback(
        (e: React.PointerEvent) => {
            const selectColorStop = colorStops[selectedGradientColorStopIndex]
            if (!selectColorStop) {
                return
            }
            let canvasWidth = e.currentTarget.clientWidth
            if (canvasWidth === 0) {
                // 为了修测试而写
                canvasWidth = e.currentTarget.getBoundingClientRect().width
            }
            const offsetX = Math.min(canvasWidth, Math.max(0, e.nativeEvent.offsetX))
            const position = offsetX / canvasWidth
            onChangeColorStop(
                selectedGradientColorStopIndex,
                { ...selectColorStop, position },
                { commitType: CommitType.Noop }
            )
        },
        [colorStops, onChangeColorStop, selectedGradientColorStopIndex]
    )

    const captureEnd = useCallback(() => {
        command.commitUndo()
    }, [command])

    const { pointerdown, pointermove, pointerup } = usePointerCapture({ capturing, captureEnd })

    useMount(() => {
        const index = getRightColorStopIndex(colorStops)
        switchSelectedGradientColorStopIndex(index)
    })

    return (
        <div data-testid="blend-gradient">
            <Scrollbar autoHeight autoHeightMin={0} autoHeightMax={567}>
                <div className={classes.gradient}>
                    <div className={classes.header}>
                        <Select.MinimalSingleLevel
                            value={type}
                            label={paintTypeToShow[type]}
                            onChange={props.onChangePaintType}
                            dataTestIds={{ triggerFocus: 'blend-gradient-select-trigger' }}
                        >
                            <Select.MinimalSingleLevel.Option
                                value={Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_LINEAR}
                            >
                                {paintTypeToShow[Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_LINEAR]}
                            </Select.MinimalSingleLevel.Option>
                            <Select.MinimalSingleLevel.Option
                                value={Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_RADIAL}
                            >
                                {paintTypeToShow[Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_RADIAL]}
                            </Select.MinimalSingleLevel.Option>
                            <Select.MinimalSingleLevel.Option
                                value={Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_ANGULAR}
                            >
                                {paintTypeToShow[Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_ANGULAR]}
                            </Select.MinimalSingleLevel.Option>
                            <Select.MinimalSingleLevel.Option
                                value={Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_DIAMOND}
                            >
                                {paintTypeToShow[Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_DIAMOND]}
                            </Select.MinimalSingleLevel.Option>
                        </Select.MinimalSingleLevel>
                        <div>
                            <Tooltip title={translation('Flip')}>
                                <WKIconButton
                                    icon={<MonoIconPanelStrokePointChange16 />}
                                    onClick={onClickSwitch}
                                    data-testid="icon-stroke-point-change"
                                />
                            </Tooltip>
                            <Tooltip title={translation('Rotate') + ' 90°'}>
                                <WKIconButton
                                    icon={<MonoIconPanelRotate16 />}
                                    onClick={onClickRotate}
                                    data-testid="icon-rotate"
                                />
                            </Tooltip>
                        </div>
                    </div>
                    <div className={classes.gradientLine} data-disabled-drag-move={'true'}>
                        <div
                            className={classes.canvas}
                            onPointerUp={pointerup}
                            onPointerMove={pointermove}
                            onPointerDown={pointerdown}
                            onMouseDown={(e) => e.stopPropagation()}
                        >
                            {colorStops.map((colorStop, index) => (
                                <GradientBlock
                                    key={index}
                                    isSelect={index === selectedGradientColorStopIndex}
                                    colorStop={colorStop}
                                    colorSpace={colorSpace}
                                    dataTestId={`gradient-block-${index}`}
                                    onClickBlock={(altKey) => onClickBlock(index, colorStop, altKey)}
                                    onDeleteBlock={() => onDeleteColorStop(index)}
                                    onChangeColorStop={(v, options) => onChangeColorStop(index, v, options)}
                                />
                            ))}
                            <Ribbon
                                width={190}
                                height={12}
                                colorStops={colorStops}
                                isCycle={type == Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_ANGULAR}
                                onAddColorStop={onAddColorStop}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.data}>
                    <Title>
                        <Title.Left>{translation('Stops')}</Title.Left>
                        <Title.Right>
                            <WKIconButton
                                icon={<MonoIconCommonAdd16 />}
                                onClick={onClickAddColorStop}
                                data-testid="add-color-stop"
                            />
                        </Title.Right>
                    </Title>
                    <div onMouseDown={(e) => e.stopPropagation()}>
                        {colorStops
                            .map((colorStop, index) => ({ colorStop, index }))
                            .sort((a, b) => a.colorStop.position - b.colorStop.position)
                            .map(({ colorStop, index }) => (
                                <PaintColorStop
                                    key={index}
                                    colorStop={colorStop}
                                    colorSpace={colorSpace}
                                    hideDeleteIcon={colorStops.length === 1}
                                    isSelect={index === selectedGradientColorStopIndex}
                                    isOpen={isOpen && index === selectedGradientColorStopIndex}
                                    onClickColorBlock={() => onClickColorBlock(index)}
                                    onClickEmptySpace={() => switchSelectedGradientColorStopIndex(index)}
                                    onClickDelete={() => onDeleteColorStop(index)}
                                    onChangeColorStop={(v, options) => onChangeColorStop(index, v, options)}
                                />
                            ))}
                    </div>
                </div>
            </Scrollbar>
        </div>
    )
}
