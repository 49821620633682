import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    ComponentProp: '组件属性',
} as const

export const enTranslation = {
    ComponentProp: 'Component properties',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
