/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { useMemo } from 'react'
import { PopupStateType } from '../../../../document/node/node'
import { useViewState } from '../../../../view-state-bridge'
import { useRenderColorSpace } from '../../color-profile'
import { LayoutGridStyleEditor } from '../styles/style-panel/layout-grid-style-editor/layout-grid-style-editor'
import { EffectStyleViewer } from './effect-style-viewer'
import { EditStyle } from './hooks/use-local-style-model-v2'
import { StyleViewer } from './style-viewer'
import { TextStyleViewer } from './text-style-viewer'

interface Props {
    editStyle: EditStyle
    positionEditor: {
        left: number
        top: number
    }
    onClose: () => void
}

export function LocalStyleViewer({ editStyle, positionEditor, onClose }: Props) {
    const popupState = useViewState('popupState')
    const colorSpace = useRenderColorSpace()
    const openStyleEditor = useMemo(() => {
        return popupState?.type === PopupStateType.POPUP_STATE_TYPE_STYLE_VIEWER
    }, [popupState?.type])

    if (!editStyle) {
        return null
    }

    if (editStyle.type === Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_PAINT) {
        return <StyleViewer open={!!editStyle && openStyleEditor} position={positionEditor} onClose={onClose} />
    } else if (editStyle.type === Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_EFFECT) {
        return <EffectStyleViewer open={!!editStyle && openStyleEditor} position={positionEditor} onClose={onClose} />
    } else if (editStyle.type === Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_TEXT) {
        return <TextStyleViewer open={!!editStyle && openStyleEditor} position={positionEditor} onClose={onClose} />
    } else if (editStyle.type === Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_LAYOUT_GRID) {
        return (
            <LayoutGridStyleEditor
                styleId={editStyle.editingStyleId}
                visible={!!editStyle && openStyleEditor}
                position={positionEditor}
                colorSpace={colorSpace}
                onCancel={onClose}
                isReadOnly={true}
                isRemoteStyle={true}
            />
        )
    }
    return null
}
