import { FeatureSwitchConfig, SwitchEnvironmentScopeType, SwitchStrategyType } from '../feature-switch-config'

const Config: FeatureSwitchConfig = {
    name: 'do-not-set-canvas-size-on-after-editor-setup',
    owner: '',
    description: '',
    strategies: [
        {
            config: {
                type: SwitchStrategyType.SPECIFIC_SUFFIX,
                suffix: ['@kanyun.com'],
            },
            env: SwitchEnvironmentScopeType.ALL,
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
