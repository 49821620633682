import { ResourceType, RoleStatus, SeatType, UserBriefVO } from './type'
import { translation } from './wukong-message-center.translation'

export type MessageId = number

export enum MessageType {
    CommentCreate = 1, // 新的评论
    CommentReply = 2, // 评论回复
    CommentMention = 3, // 评论提及
    ApplyJoinRequest = 4, // 申请加入的 请求
    ApplyJoinResponse = 5, // 申请加入的 回复
    Invitation = 6, // 邀请加入
    AssetHandover = 7, // 资产转交
    // 这里没有 8。
    SeatApplication = 9, // 席位申请
    SeatApplyResolved = 10, // 审批结果反馈
    SeatUpdate = 11, // 席位更新
    PluginEditInvitation = 12, // 插件编辑邀请
}

export enum CommentMessageDataType {
    Create = 0,
    Reply = 1,
    Mention = 2,
}

export enum PluginUserRoleEnum {
    Owner = 'OWNER',
    Editor = 'EDITOR',
}
export const pluginUserRoleLabel = {
    [PluginUserRoleEnum.Owner]: translation('PluginOwner'),
    [PluginUserRoleEnum.Editor]: translation('PluginEditor'),
}

// 资源信息
export interface ResourceBriefVO {
    id: string
    name: string
    iconOrThumb: string // "图标或缩略图，可能为空"
    resourceType: ResourceType
    iconBackgroundColor?: string // 团队颜色
    backGroundColor?: string // ResourceType.Document 时存在
    thumbnailInfo?: string // ResourceType.Document 时存在
}

export interface MessageDataComment {
    commentType: CommentMessageDataType
    id: number // 业务记录主键id
    docId: string
    docName: string
    docBackgroundColor: string
    thumbnail: string // 缩略图链接
    messageText: string // 评论内容
    mentionedUsers: UserBriefVO[]
    resolved: boolean
    createdTime: number
    nodeId: string // 评论绑定的id
    pageId: string // 评论绑定的pageId
    parentId: number // 即评论id
}

export interface MessageDataApplyJoinRequest {
    id: number // 主键id
    resourceBriefVO: ResourceBriefVO
    requestUserBrief: UserBriefVO // 申请用户信息
    roleToBe: RoleStatus
    reason: string // 申请附言/理由
}

export interface MessageDataApplyJoinResponse {
    joinRequestMessageData: MessageDataApplyJoinRequest
    processUserBrief: UserBriefVO // 处理人信息
    approved: boolean // 处理结果，true,通过；false，拒绝
    roleActual: RoleStatus // 最终成为的角色，仅 approved = true 时有效
}

export interface MessageDataInvitation {
    resourceBriefVO: ResourceBriefVO
    inviteUserBrief: UserBriefVO // 邀请用户信息
    roleInvitedToBe: RoleStatus
    inviteRedirectUrl: string // 原型文件的邀请跳转链接
}

export interface MessageDataAssetHandover {
    handoverFromUserBrief: UserBriefVO // 转出人信息
    processUserBrief: UserBriefVO // 处理人信息
    handoverToUserBrief: UserBriefVO //接收人信息
    handoverFromTeamBrief: ResourceBriefVO //转出组信息
    resourceBriefVOs: ResourceBriefVO[] //转移资源列表
}

export interface MessageDataSeatApplication {
    id: number
    requestUserVO: UserBriefVO // 申请人信息
    resourceBriefVO: ResourceBriefVO // 履约单位信息
    autoUpgrade: boolean
    seatType: SeatType
    gracePeriodDay: number //试用期时间
    reason: string //申请原因，仅autoUpgrade为false时填值
}

export interface MessageDataSeatApplyResolved {
    operatorUserVO: UserBriefVO //操作人信息
    requestUserVO: UserBriefVO // 申请人信息
    resourceBriefVO: ResourceBriefVO //履约单位信息
    approved: boolean //申请审批情况，true代表同意，false代表拒绝
    seatType: SeatType //申请的席位
}

export interface MessageDataSeatUpdate {
    operatorUserVO: UserBriefVO // 操作人信息
    operatedUserVO: UserBriefVO // 被操作人信息
    resourceBriefVO: ResourceBriefVO
    upgrade: boolean
    actualSeatType: SeatType
}
export interface MessageCommonProperties {
    orgId: string
    id: MessageId
    receiveUser: UserBriefVO
    publishUser: UserBriefVO
    createTime: number
    read: boolean
}
export interface MessageDataPluginEditInvitation {
    pluginId: string
    pluginName: string
    role: PluginUserRoleEnum
    inviteUser: UserBriefVO
    orgResource: ResourceBriefVO
}
export interface MessageComment extends MessageCommonProperties {
    messageType: MessageType.CommentCreate | MessageType.CommentReply | MessageType.CommentMention
    messageData: MessageDataComment
}

export interface MessageApplyJoinRequest extends MessageCommonProperties {
    messageType: MessageType.ApplyJoinRequest
    messageData: MessageDataApplyJoinRequest
}

export interface MessageApplyJoinResponse extends MessageCommonProperties {
    messageType: MessageType.ApplyJoinResponse
    messageData: MessageDataApplyJoinResponse
}

export interface MessageInvitation extends MessageCommonProperties {
    messageType: MessageType.Invitation
    messageData: MessageDataInvitation
}

export interface MessageAssetHandover extends MessageCommonProperties {
    messageType: MessageType.AssetHandover
    messageData: MessageDataAssetHandover
}

export interface MessageSeatApplication extends MessageCommonProperties {
    messageType: MessageType.SeatApplication
    messageData: MessageDataSeatApplication
}

export interface MessageSeatApplyResolved extends MessageCommonProperties {
    messageType: MessageType.SeatApplyResolved
    messageData: MessageDataSeatApplyResolved
}

export interface MessageSeatUpdate extends MessageCommonProperties {
    messageType: MessageType.SeatUpdate
    messageData: MessageDataSeatUpdate
}

export interface MessagePluginEditInvitation extends MessageCommonProperties {
    messageType: MessageType.PluginEditInvitation
    messageData: MessageDataPluginEditInvitation
}

export type Message =
    | MessageComment
    | MessageApplyJoinRequest
    | MessageApplyJoinResponse
    | MessageInvitation
    | MessageAssetHandover
    | MessageSeatApplication
    | MessageSeatApplyResolved
    | MessageSeatUpdate
    | MessagePluginEditInvitation
