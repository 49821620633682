import { fetchCreatePlugin } from '../plugin-request'
import { fileSep } from '../util'
import AppTemplate from './files/App.tsx.template?raw'
import MainTemplate from './files/main.ts.template?raw'
import PackageJsonTemplate from './files/package.json.template?raw'
import ReadmeMdTemplate from './files/README.md.template?raw'
import TsconfigJsonTemplate from './files/tsconfig.json.template?raw'
import TypeDefinitionTemplate from './files/types.d.ts.template?raw'
import WebpackConfigTemplate from './files/webpack.config.js.template?raw'
import { LocalPlugin, PluginManifest, TemplatePluginFile } from './type'
export enum DesignTemplateFileName {
    APP_TSX = 'App.tsx',
    MAIN_TS = 'main.ts',
    PACKAGE_JSON = 'package.json',
    README_MD = 'README.md',
    TSCONFIG_JSON = 'tsconfig.json',
    TYPE_DEFINITION_TS = 'types.d.ts',
    WEBPACK_CONFIG_JS = 'webpack.config.js',
    MANIFEST_JSON = 'manifest.json',
}

const designTemplateFiles: TemplatePluginFile[] = [
    {
        fileName: DesignTemplateFileName.APP_TSX,
        fileContent: AppTemplate,
    },
    {
        fileName: DesignTemplateFileName.MAIN_TS,
        fileContent: MainTemplate,
    },
    {
        fileName: DesignTemplateFileName.MANIFEST_JSON,
        fileContent: {
            name: '',
            id: '',
            main: 'dist/code.js',
            ui: 'dist/ui.html',
        },
    },
    {
        fileName: DesignTemplateFileName.PACKAGE_JSON,
        fileContent: PackageJsonTemplate,
    },
    {
        fileName: DesignTemplateFileName.README_MD,
        fileContent: ReadmeMdTemplate,
    },
    {
        fileName: DesignTemplateFileName.TSCONFIG_JSON,
        fileContent: TsconfigJsonTemplate,
    },
    {
        fileName: DesignTemplateFileName.TYPE_DEFINITION_TS,
        fileContent: TypeDefinitionTemplate,
    },
    {
        fileName: DesignTemplateFileName.WEBPACK_CONFIG_JS,
        fileContent: WebpackConfigTemplate,
    },
]

async function createTemplateFiles(pluginName: string, orgId: string): Promise<TemplatePluginFile[] | undefined> {
    const createdPlugin = await fetchCreatePlugin(orgId).catch(() => {
        return undefined
    })

    if (!createdPlugin) {
        return undefined
    }

    return designTemplateFiles.map((tempFile) => {
        if (typeof tempFile.fileContent === 'string') {
            return tempFile
        }

        if (tempFile.fileName === DesignTemplateFileName.MANIFEST_JSON) {
            tempFile.fileContent.id = createdPlugin.id
            tempFile.fileContent.name = pluginName
        }

        return tempFile
    })
}

export async function saveTemplatePlugin(pluginName: string, orgId: string): Promise<LocalPlugin | undefined> {
    const templateFiles = await createTemplateFiles(pluginName, orgId)

    if (!templateFiles) {
        return undefined
    }

    const manifest = templateFiles.find((tempFile) => tempFile.fileName === DesignTemplateFileName.MANIFEST_JSON)
        ?.fileContent as PluginManifest

    const deskTopExportFiles: {
        name: string
        blob: Blob
    }[] = templateFiles.map((tempFile) => {
        if (typeof tempFile.fileContent === 'string') {
            return {
                name: `${pluginName}${fileSep()}${tempFile.fileName}`,
                blob: new Blob([tempFile.fileContent], { type: 'text/plain' }),
            }
        }
        return {
            name: `${pluginName}${fileSep()}${tempFile.fileName}`,
            blob: new Blob([JSON.stringify(tempFile.fileContent, null, 2)], { type: 'application/json' }),
        }
    })

    const savePath = await window.localBridge?.exportFiles(deskTopExportFiles)

    if (!savePath) {
        return undefined
    }

    return {
        id: manifest.id,
        name: manifest.name,
        path: `${savePath}${fileSep()}${pluginName}`,
        manifest,
        lastEditTime: Date.now(),
    }
}
