import { FeatureSwitchConfig, SwitchEnvironmentScopeType, SwitchStrategyType } from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'show-performance-score',
    owner: '',
    description: '',
    strategies: [
        {
            env: SwitchEnvironmentScopeType.TESTING,
            config: { type: SwitchStrategyType.SPECIFIC_USER_LIST, userList: ['225eb66e04c4fbf5474dc54c786bcd347796f116'] },
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
