import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    ViewGridStyle: '查看布局网格样式',
    CreateGridStyle: '创建布局网格样式',
    EditGridStyle: '编辑布局网格样式',
    GridStyles: '布局网格样式',
    Grid: '网格',
    Grid_synonyms1: '栅格',
    Grid_synonyms1_synonyms2: '多层布局网格样式',
} as const

export const enTranslation = {
    ViewGridStyle: 'View grid style',
    CreateGridStyle: 'Create new grid style',
    EditGridStyle: 'Edit grid style',
    GridStyles: 'Grid styles',
    Grid: 'Grid',
    Grid_synonyms1: 'Grid',
    Grid_synonyms1_synonyms2: 'Grid',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
