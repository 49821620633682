import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    BelongingComponent: '所属组件',
    SelectComponent: '选中组件',
} as const

export const enTranslation = {
    BelongingComponent: 'Parent component',
    SelectComponent: 'Select component',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
