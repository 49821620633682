import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    GoToMain: '跳转至组件母版',
    SelectInstance: '选中实例',
    InstanceOptions: '更多操作',
    DetachInstance: '分离实例',
    PushChangesTo: '覆盖组件母版',
    NoChangeTo: '没有要重置的属性',
    Reset: '重置',
    RestoreMainComponent: '恢复组件',
    ResetAssignment: '重置{{propName}}属性',
    showMoreDesc: '查看全部',
} as const

export const enTranslation = {
    GoToMain: 'Go to main component',
    SelectInstance: 'Select instance',
    InstanceOptions: 'Instance options',
    DetachInstance: 'Detach instance',
    PushChangesTo: 'Push changes to main component',
    NoChangeTo: 'No changes to reset',
    Reset: 'Reset ',
    RestoreMainComponent: 'Restore main component',
    ResetAssignment: 'Reset {{propName}} property',
    showMoreDesc: 'Show all',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
