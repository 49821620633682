import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    StateManagement: '状态管理',
    ResetScrollPosition: '重置滚动位置',
    Description: '未设置该选项时，在拥有相同图层名或前缀的最外层容器之间滚动位置将会被保留。',
    LearnMore: '了解更多',
} as const

export const enTranslation = {
    StateManagement: 'State management',
    ResetScrollPosition: 'Reset scroll position',
    Description:
        'The scroll position will be preserved when the top-level frames have the same names or matching prefixes.',
    LearnMore: 'Learn more',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
