import { useState } from 'react'
import {
    MonoIconToolbarAICheck24,
    MonoIconToolbarAICreator24,
    MonoIconToolbarAIDuplication24,
    MonoIconToolbarAIGeneratesUI24,
    MonoIconToolbarAILayout24,
    MonoIconToolbarAIMaintainer24,
    MonoIconToolbarMagic24,
} from '../../../../../../ui-lib/src'
import { environment } from '../../../../environment'
import { PaySwitch } from './pay-switch/pay-switch'
import { PayUpgradeDialogContainer } from './pay-upgrade-dialog-container/pay-upgrade-dialog-container'
import classes from './price-comparison-all.module.less'
import { translation } from './price-comparison-all.translation'
import { PrivilegeCardOrganization } from './privilege-card/privilege-card-organization'
import { PrivilegeCardProfessional } from './privilege-card/privilege-card-professional'
import { PrivilegeCardStarter } from './privilege-card/privilege-card-starter'
import { ToolCard, ToolCardProps } from './tool-card/tool-card'
import { PriceComparisonAllProps } from './type'
import { usePayUpgradePrice } from './use-pay-upgrade-price'
import { usePayUpgradeSubmit } from './use-pay-upgrade-submit'

export function PriceComparisonAll(props: PriceComparisonAllProps) {
    const { dialogProps, professionalProps, organizationProps, starterProps, ...otherProps } = props
    const { price } = usePayUpgradePrice()
    const { hidePayUpgradeDialogContainer, submitProfessional, submitOrganization, submitStarter } =
        usePayUpgradeSubmit()
    const [payment, setPayment] = useState<'monthly' | 'annual'>('annual')

    return (
        <PayUpgradeDialogContainer
            width={960}
            hide={hidePayUpgradeDialogContainer}
            {...dialogProps}
            containerClassName={classes['dialog-bg']}
            maskClosable
        >
            <div className={classes.title} data-testid="price-comparison-title">
                {translation('xzs')}
            </div>
            <div className={classes.subtitle} data-testid="price-comparison-subtitle">
                {environment.isAbroad ? (
                    <PaySwitch
                        className={classes.paySwitch}
                        left={{
                            active: payment === 'monthly',
                            content: (
                                <div>
                                    <span>Monthly</span>
                                </div>
                            ),
                            onClick: () => setPayment('monthly'),
                        }}
                        right={{
                            active: payment === 'annual',
                            content: (
                                <div>
                                    <span>Yearly</span>
                                    <span
                                        style={{
                                            marginLeft: 4,
                                            color: payment === 'annual' ? 'var(--wk-red-6)' : undefined,
                                        }}
                                    >
                                        (Save up to 20%)
                                    </span>
                                </div>
                            ),
                            onClick: () => setPayment('annual'),
                        }}
                    />
                ) : (
                    '*所有版本需按年付费'
                )}
            </div>
            <div className={classes.body}>
                <PrivilegeCardStarter
                    {...otherProps}
                    className={classes.privilegeCard}
                    onSubmit={starterProps ? () => submitStarter(starterProps.submitProps) : undefined}
                    privileges={[
                        { name: translation('me'), enable: true },
                        { name: translation('upf'), enable: true },
                        { name: translation('mf'), enable: true },
                        { name: translation('dvh'), enable: true },
                        { name: translation('bi'), enable: true },
                    ]}
                />
                <PrivilegeCardProfessional
                    {...otherProps}
                    className={classes.privilegeCard}
                    privilegesTitle={translation('es')}
                    privileges={[
                        { name: translation('umf'), enable: true },
                        { name: translation('uvh'), enable: true },
                        { name: translation('tcl'), enable: true },
                        { name: translation('spp'), enable: true },
                        { name: translation('dm'), enable: true },
                    ]}
                    payment={payment}
                    mainPriceAnnual={price.professionDesignYear}
                    mainPriceMonthly={price.professionDesignMonthly}
                    subPriceAnnual={price.professionDevYear}
                    subPriceMonthly={price.professionDevMonthly}
                    onSubmit={() => submitProfessional(professionalProps.submitProps, dialogProps?.onOk)}
                />
                <PrivilegeCardOrganization
                    {...otherProps}
                    className={classes.privilegeCard}
                    privilegesTitle={translation('ep')}
                    privileges={[
                        { name: translation('ut'), enable: true },
                        { name: translation('ads'), enable: true },
                        { name: translation('sft'), enable: true },
                        { name: translation('ca'), enable: true },
                        { name: translation('ccm'), enable: true },
                        { name: translation('al'), enable: true },
                    ]}
                    payment={payment}
                    mainPriceAnnual={price.organizationDesignYear}
                    subPriceAnnual={price.organizationDevYear}
                    onSubmit={() => submitOrganization(organizationProps.submitProps, dialogProps?.onOk)}
                    onClickYear={() => setPayment('annual')}
                />
            </div>
            <div className={classes.footer}>
                <div className={classes.footerContent}>
                    <div className={classes.card}>
                        <div className={classes.cardTitle}>
                            <span className={classes.cardName}>Motiff AI</span>
                            <span className={classes.cardLabel}>
                                <span className={classes.cardLabelContent}>Beta</span>
                            </span>
                        </div>
                        <span className={classes.freeNow}>{translation('fn')}</span>
                    </div>
                    {toolCards.map((v, index) => (
                        <ToolCard key={index} {...v} />
                    ))}
                </div>
            </div>
        </PayUpgradeDialogContainer>
    )
}

export const toolCards: ToolCardProps[] = [
    {
        name: translation('tat'),
        description: translation('tm', { times: '500' }),
        tools: [
            {
                icon: <MonoIconToolbarAIDuplication24 />,
                name: translation('ar'),
            },
            {
                icon: <MonoIconToolbarAILayout24 />,
                name: translation('ala'),
            },
        ],
        dataTestIds: {
            name: 'tool1-name',
            description: 'tool1-description',
            prefixTool: 'tool1',
        },
    },
    {
        name: translation('tds'),
        description: translation('tm', { times: '10' }),
        tools: [
            {
                icon: <MonoIconToolbarAICreator24 />,
                name: translation('ad'),
            },
            {
                icon: <MonoIconToolbarAIMaintainer24 />,
                name: translation('ds'),
            },
            {
                icon: <MonoIconToolbarAICheck24 />,
                name: translation('ac'),
            },
        ],
        dataTestIds: {
            name: 'tool2-name',
            description: 'tool2-description',
            prefixTool: 'tool2',
        },
    },
    {
        name: translation('tml'),
        description: translation('tm', { times: '50' }),
        tools: [
            {
                icon: <MonoIconToolbarAIGeneratesUI24 />,
                name: translation('ag'),
            },
            {
                icon: <MonoIconToolbarMagic24 />,
                name: translation('am'),
            },
        ],
        dataTestIds: {
            name: 'tool3-name',
            description: 'tool3-description',
            prefixTool: 'tool3',
        },
    },
]
