import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    SlgWOj: '未命名',
} as const

export const enTranslation = {
    SlgWOj: 'Untitled',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
