export const IconFolder = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 5L2 3H7.38197L8.38197 5L2 5ZM8.27639 2.55279L9.5 5L14 5C14.5523 5 15 5.44772 15 6V14C15 14.5523 14.5523 15 14 15L2 15C1.44772 15 1 14.5523 1 14V6V5V3C1 2.44772 1.44772 2 2 2H7.38197C7.76074 2 8.107 2.214 8.27639 2.55279ZM2 14L2 6L14 6V14L2 14Z"
            fill="#1B1F26"
        />
    </svg>
)

export const IconBack = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 3L6 8L11 13" stroke="#828A99" />
    </svg>
)

export const IconForward = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 3L11 8L6 13" stroke="#828A99" />
    </svg>
)
