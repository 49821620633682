import {
    BatchUpdateBaseAttributeCommand,
    BatchUpdateMinSizeAndMaxSizeWasmCall,
    UpdateSelectionBaseAttributeCommand,
    UpdateSelectionConstrainProportionsCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { safeCall } from '../../../../../../ui-lib/src'
import { CommitType } from '../../../../document/command/commit-type'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { Value } from '../../atom/inputs/utils/type'
import { useMinMaxSizeDropdownInputProps } from './min-max-size'

export function useAttrAboutWidthHeight() {
    const command = useCommand()
    const constrainProportions = useViewState('constrainProportions')
    const state = useViewState('baseAttributeV2')
    const minMaxSizeSelectInputProps = useMinMaxSizeDropdownInputProps()

    const onChangeConstrainProportions = (v: boolean) => {
        command.invokeBridge(CommitType.CommitUndo, UpdateSelectionConstrainProportionsCommand, { value: !!v })
    }
    /** 宽度 start */
    const onChangeWidth = (value: Value, options?: InputOptionsForUndoSquash) => {
        if (typeof value === 'number') {
            command.invokeBridge(options?.commitType ?? CommitType.Noop, UpdateSelectionBaseAttributeCommand, {
                w: value,
            })
        }
    }
    const onChangeMixedWidth = (parse: (value: Value) => Value, options?: InputOptionsForUndoSquash) => {
        if (state?.w.values) {
            const res: Record<string, number> = {}
            Object.entries(state.w.values).forEach(([name, value]) => {
                res[name] = safeCall(parse, value) ?? value
            })
            command.invokeBridge(options?.commitType ?? CommitType.Noop, BatchUpdateBaseAttributeCommand, {
                type: 'w',
                values: res,
            })
        }
    }
    const onScrubChangeAllMixedWidth = (values: Map<string, Value>) => {
        command.invokeBridge(CommitType.Noop, BatchUpdateBaseAttributeCommand, {
            type: 'w',
            values: Object.fromEntries(values) as Record<string, number>,
        })
    }
    /** 宽度 end */
    /** 高度 start */
    const onChangeHeight = (value: Value, options?: InputOptionsForUndoSquash) => {
        if (typeof value === 'number') {
            command.invokeBridge(options?.commitType ?? CommitType.Noop, UpdateSelectionBaseAttributeCommand, {
                h: value,
            })
        }
    }
    const onChangeMixedHeight = (parse: (value: Value) => Value, options?: InputOptionsForUndoSquash) => {
        if (state?.h.values) {
            const res: Record<string, number> = {}
            Object.entries(state.h.values).forEach(([name, value]) => {
                res[name] = safeCall(parse, value) ?? value
            })
            command.invokeBridge(options?.commitType ?? CommitType.Noop, BatchUpdateBaseAttributeCommand, {
                type: 'h',
                values: res,
            })
        }
    }
    const onScrubChangeAllMixedHeight = (values: Map<string, Value>) => {
        minMaxSizeSelectInputProps.markForceShowMaxHeight()
        command.invokeBridge(CommitType.Noop, BatchUpdateBaseAttributeCommand, {
            type: 'h',
            values: Object.fromEntries(values) as Record<string, number>,
        })
    }
    /* 高度 end */
    /** 最小宽度 start */
    const onChangeMinWidth = (value: Value, options?: InputOptionsForUndoSquash) => {
        minMaxSizeSelectInputProps.markForceShowMinWidth()
        if (typeof value === 'number' && state?.minW.values) {
            command.invokeBridge(options?.commitType ?? CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
                params: Object.keys(state?.minW.values).map((nodeId) => ({
                    nodeId,
                    value,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_WIDTH,
                })),
            })
        }
    }
    const onChangeMixedMinWidth = (parse: (value: Value) => Value, options?: InputOptionsForUndoSquash) => {
        minMaxSizeSelectInputProps.markForceShowMinWidth()
        if (state?.minW.values) {
            command.invokeBridge(options?.commitType ?? CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
                params: Object.entries(state?.minW.values).map(([nodeId, value]) => ({
                    nodeId,
                    value: safeCall(parse, value) ?? value,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_WIDTH,
                })),
            })
        }
    }
    const onScrubChangeAllMixedMinWidth = (values: Map<string, Value>) => {
        minMaxSizeSelectInputProps.markForceShowMinWidth()
        command.invokeBridge(CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
            params: (Object.entries(values) as [string, number][]).map(([id, value]) => ({
                id,
                value,
                type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                    .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_WIDTH,
            })),
        })
    }
    /** 最小宽度 end */
    /** 最小高度 start */
    const onChangeMinHeight = (value: Value, options?: InputOptionsForUndoSquash) => {
        minMaxSizeSelectInputProps.markForceShowMinHeight()
        if (typeof value === 'number' && state?.minH.values) {
            command.invokeBridge(options?.commitType ?? CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
                params: Object.keys(state?.minH.values).map((nodeId) => ({
                    nodeId,
                    value,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_HEIGHT,
                })),
            })
        }
    }
    const onChangeMixedMinHeight = (parse: (value: Value) => Value, options?: InputOptionsForUndoSquash) => {
        minMaxSizeSelectInputProps.markForceShowMinHeight()
        if (state?.minH.values) {
            command.invokeBridge(options?.commitType ?? CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
                params: Object.entries(state?.minH.values).map(([nodeId, value]) => ({
                    nodeId,
                    value: safeCall(parse, value) ?? value,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_HEIGHT,
                })),
            })
        }
    }
    const onScrubChangeAllMixedMinHeight = (values: Map<string, Value>) => {
        minMaxSizeSelectInputProps.markForceShowMinHeight()
        command.invokeBridge(CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
            params: (Object.entries(values) as [string, number][]).map(([id, value]) => ({
                id,
                value,
                type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                    .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_HEIGHT,
            })),
        })
    }
    /** 最小高度 end */
    /** 最大宽度 start */
    const onChangeMaxWidth = (value: Value, options?: InputOptionsForUndoSquash) => {
        minMaxSizeSelectInputProps.markForceShowMaxWidth()
        if (typeof value === 'number' && state?.maxW.values) {
            command.invokeBridge(options?.commitType ?? CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
                params: Object.keys(state?.maxW.values).map((nodeId) => ({
                    nodeId,
                    value: value || NaN,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_WIDTH,
                })),
            })
        }
    }
    const onChangeMixedMaxWidth = (parse: (value: Value) => Value, options?: InputOptionsForUndoSquash) => {
        minMaxSizeSelectInputProps.markForceShowMaxWidth()
        if (state?.maxW.values) {
            command.invokeBridge(options?.commitType ?? CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
                params: Object.entries(state?.maxW.values).map(([nodeId, value]) => ({
                    nodeId,
                    value: safeCall(parse, value) ?? value,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_WIDTH,
                })),
            })
        }
    }
    const onScrubChangeAllMixedMaxWidth = (values: Map<string, Value>) => {
        minMaxSizeSelectInputProps.markForceShowMaxWidth()
        command.invokeBridge(CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
            params: (Object.entries(values) as [string, number][]).map(([id, value]) => ({
                id,
                value: value || NaN,
                type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                    .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_WIDTH,
            })),
        })
    }
    /** 最大宽度 end */
    /** 最大高度 start */
    const onChangeMaxHeight = (value: Value, options?: InputOptionsForUndoSquash) => {
        minMaxSizeSelectInputProps.markForceShowMaxHeight()
        if (typeof value === 'number' && state?.maxH.values) {
            command.invokeBridge(options?.commitType ?? CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
                params: Object.keys(state?.maxH.values).map((nodeId) => ({
                    nodeId,
                    value: value || NaN,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_HEIGHT,
                })),
            })
        }
    }
    const onChangeMixedMaxHeight = (parse: (value: Value) => Value, options?: InputOptionsForUndoSquash) => {
        minMaxSizeSelectInputProps.markForceShowMaxHeight()
        if (state?.maxH.values) {
            command.invokeBridge(options?.commitType ?? CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
                params: Object.entries(state?.maxH.values).map(([nodeId, value]) => ({
                    nodeId,
                    value: safeCall(parse, value) ?? value,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_HEIGHT,
                })),
            })
        }
    }
    const onScrubChangeAllMixedMaxHeight = (values: Map<string, Value>) => {
        minMaxSizeSelectInputProps.markForceShowMaxHeight()
        command.invokeBridge(CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
            params: (Object.entries(values) as [string, number][]).map(([id, value]) => ({
                id,
                value: value || NaN,
                type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                    .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_HEIGHT,
            })),
        })
    }
    /** 最大高度 end */

    return {
        constrainProportions,
        onChangeConstrainProportions,
        state,
        minMaxSizeSelectInputProps,
        onChangeWidth,
        onChangeHeight,
        onChangeMinWidth,
        onChangeMaxWidth,
        onChangeMinHeight,
        onChangeMaxHeight,
        onChangeMixedWidth,
        onChangeMixedHeight,
        onChangeMixedMinWidth,
        onChangeMixedMinHeight,
        onChangeMixedMaxWidth,
        onChangeMixedMaxHeight,
        onScrubChangeAllMixedWidth,
        onScrubChangeAllMixedHeight,
        onScrubChangeAllMixedMinWidth,
        onScrubChangeAllMixedMaxWidth,
        onScrubChangeAllMixedMinHeight,
        onScrubChangeAllMixedMaxHeight,
    }
}
