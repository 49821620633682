import {
    MonoIconCommonMore16,
    MonoIconCommonReset16,
    MonoIconCommonthumbsDown16,
    MonoIconCommonthumbsDownFill16,
    MonoIconCommonthumbsUp16,
    MonoIconCommonthumbsUpFill16,
    WKIconButton,
} from '../../../../../../ui-lib/src'

import { createRoot } from 'react-dom/client'
import { featureSwitchManager } from '../../../../kernel/switch/core'
import { AnswerReactionType, ConversationId, DialogueId } from '../../../../kernel/interface/chatbot'
import { useAppContext } from '../../../../main/app-context'
import { DebugDialogueDetail } from '../ui-component/debug-component/debug-dialogue-detail'
import { AgentAnswerType, getAgentAnswerType } from './type'

export const AgentAnswerReaction = ({
    conversationId,
    dialogueId,
    isLastDialogue,
}: {
    conversationId?: ConversationId
    dialogueId?: DialogueId
    isLastDialogue?: boolean
}) => {
    const chatbotService = useAppContext().chatbotService
    const allConversations = chatbotService.dataStore.use.allConversations()
    const conversation = conversationId ? allConversations[conversationId] : null
    const curDialogue = dialogueId
        ? conversation?.conversationDataList.find((item) => item.dialogueId === dialogueId)
        : null

    const showDebugBtn = featureSwitchManager.isEnabled('chatbot-debug')

    if (!conversationId || !dialogueId || !curDialogue) {
        return null
    }

    const curAnswerType = getAgentAnswerType(curDialogue.questionAnswer)
    const showResend = curAnswerType === AgentAnswerType.DIALOGUE && isLastDialogue
    const curReactionType = curDialogue.answerReactionType

    const onReaction = (reactionType: AnswerReactionType) => {
        chatbotService.updateDialogueReaction(conversationId, dialogueId, reactionType)
    }

    const onResend = () => {
        chatbotService.resendLastDialogue(conversationId)
    }

    const onReactLike = () => {
        if (curReactionType === AnswerReactionType.LIKE) {
            onReaction(AnswerReactionType.NONE)
        } else {
            onReaction(AnswerReactionType.LIKE)
        }
    }

    const onReactDislike = () => {
        if (curReactionType === AnswerReactionType.DISLIKE) {
            onReaction(AnswerReactionType.NONE)
        } else {
            onReaction(AnswerReactionType.DISLIKE)
        }
    }

    return (
        <div className="flex flex-row items-center justify-start gap-1">
            {showResend && <WKIconButton size="small" icon={<MonoIconCommonReset16 />} onClick={onResend} />}
            {showResend && <div className="w-1px h-4 bg-$wk-gray-3"></div>}

            <div className="flex flex-row">
                <WKIconButton
                    size="small"
                    icon={
                        curReactionType === AnswerReactionType.LIKE ? (
                            <MonoIconCommonthumbsUpFill16 />
                        ) : (
                            <MonoIconCommonthumbsUp16 />
                        )
                    }
                    onClick={onReactLike}
                />
                <WKIconButton
                    size="small"
                    className="ml-0!"
                    icon={
                        curReactionType === AnswerReactionType.DISLIKE ? (
                            <MonoIconCommonthumbsDownFill16 />
                        ) : (
                            <MonoIconCommonthumbsDown16 />
                        )
                    }
                    onClick={onReactDislike}
                />
                {showDebugBtn && (
                    <WKIconButton
                        size="small"
                        className="ml-0!"
                        icon={<MonoIconCommonMore16 />}
                        onClick={() => {
                            const debugDialogueDetailContainer = document.createElement('div')
                            debugDialogueDetailContainer.id = 'debug-dialogue-detail'

                            document.body.appendChild(debugDialogueDetailContainer)
                            const root = createRoot(debugDialogueDetailContainer)

                            const dialogueData = {
                                dialogueInput: chatbotService.generDialogueInputByDialogueId(
                                    conversationId,
                                    dialogueId
                                ),
                                dialogueAnswer: chatbotService.getDialogueAnswerByDialogueId(
                                    conversationId,
                                    dialogueId
                                ),
                            }

                            root.render(
                                <DebugDialogueDetail
                                    close={() => {
                                        root.unmount()
                                        debugDialogueDetailContainer.remove()
                                    }}
                                    dialogueData={dialogueData}
                                />
                            )
                        }}
                    />
                )}
            </div>
        </div>
    )
}
