import { useCallback, useRef } from 'react'
import { TriggerContainerProps } from './base-components/trigger-container'
import { TriggerBaseProps } from './type'

export interface UseCommonTriggerProps
    extends Pick<TriggerBaseProps, 'openAction' | 'closeAction'>,
        Pick<TriggerContainerProps, 'isOpenPick'> {}
export function useCommonTrigger(props: UseCommonTriggerProps) {
    const nextClickWillToClose = useRef<boolean>(false)

    const mousedown = useCallback(() => {
        nextClickWillToClose.current = !!props.isOpenPick
    }, [props.isOpenPick])

    const click = useCallback(() => {
        if (nextClickWillToClose.current) {
            props.closeAction?.()
        } else {
            props.openAction?.()
        }
    }, [props])
    return { mousedown, click }
}
