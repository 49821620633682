import { useFeatureSwitch } from '../../../../kernel/switch'
import style from './ai-gen-ui-header.module.less'
import { translation } from './ai-gen-ui-header.translation'
import { WKIconButton, MonoIconCommonSlack16, WKPopover } from '../../../../../../ui-lib/src'
import { environment } from '../../../../environment'

export function AIGenUIHeader() {
    const enableAIOnboarding = useFeatureSwitch('ai-gen-onboarding-design-system') && environment.isAbroad
    return (
        <div className="flex flex-row justify-between items-center pr-13">
            <div className={style['ai-gen-ui-header']}>
                <h1 className="m-0">{translation('AIGenUI')}</h1>
            </div>
            {enableAIOnboarding && (
                <WKPopover
                    placement="top-center"
                    showDelayTime={100}
                    contents={
                        <div className="flex flex-col w-full shrink-0 h-17.5 rounded-1 gap-2">
                            <h2 className="p-0 m-0">Join the Motiff Community on Slack now!</h2>
                            <div>
                                <p className="p-0 m-0">1. Unlock extra times→ Generate 3X more</p>
                                <p className="p-0 m-0">2. Learn insider tips → Generate like a pro</p>
                            </div>
                        </div>
                    }
                >
                    <WKIconButton
                        onClick={() => {
                            window.open(
                                'https://join.slack.com/t/motiffcommunity/shared_invite/zt-31koqta1d-vwx0RmEfWC3~~iI6QWUUYA',
                                '_blank'
                            )
                        }}
                        icon={<MonoIconCommonSlack16 />}
                    />
                </WKPopover>
            )}
        </div>
    )
}
