import { DELETE, GET, POST, PUT } from '@tutor/network-core'
import type {
    AssetHandoverVO,
    CanUpgradeTeamAndUserStateVO,
    FolderIdWithDocVO,
    FolderWithAuthorityVO,
    FolderWithDocAndAuthority,
    OrgID,
    PayPlanType,
    PlanAndUserStateVO,
    PublicStatus,
    SeatUpgradeType,
    TeamBriefForRestore,
    TeamBriefVOForAssignWorkspace,
    TeamID,
    TeamInOrgVO,
    TeamResourceStateVO,
    TeamSearchGetVO,
    TeamSeatVO,
    TeamSetVO,
    TeamUpdateVO,
    TeamWithAuthorityVO,
    TeamWithFolderAndAuthorityVO,
    UserBriefVO,
    UserID,
    UserOperationVO,
} from '../interface/type'
import { WorkspaceWithAuthorityVO, WorkspaceWithTeamAndFolderVO } from '../interface/workspace'
import { omitByValue } from '../util/omit-by-value'
import { BaseCommonRequest } from './base-request'
import { requestDidFailedHandler } from './error-handler'

// 创建一个团队
@POST
export class CreateTeamRequest extends BaseCommonRequest<TeamWithAuthorityVO> {
    constructor(private readonly initialTeam: TeamSetVO) {
        super()
    }

    public override requestUrl() {
        return 'teams'
    }

    public override requestBody() {
        return this.initialTeam
    }

    public override requestDidFailed(err: any): boolean {
        return requestDidFailedHandler(err, this.responseStatusCode)
    }
}

// 获取所有的团队和项目
@GET
export class GetTeamsFoldersRequest extends BaseCommonRequest<TeamWithFolderAndAuthorityVO[]> {
    constructor(private readonly orgId: string, private readonly additionalRequestHeader?: Record<string, string>) {
        super()
    }

    public override requestDidFailed(): boolean {
        // 401 的错误码不过滤，要正常向外发送事件，用于弹出登录状态错误的弹窗
        if (this.responseStatusCode == 401) {
            window.dispatchEvent(new CustomEvent('request-error', { detail: { statusCode: this.responseStatusCode } }))
        }
        return false
    }
    public override additionalRequestHeaders(): { [key: string]: string } | undefined {
        if (this.additionalRequestHeader) {
            return this.additionalRequestHeader
        }
        return undefined
    }

    public override requestUrl() {
        return 'teams/folders'
    }
    public override requestArgument() {
        return { orgId: this.orgId }
    }
}

// 高级企业版专用
@GET
export class GetTeamsFoldersEnterpriseRequest extends BaseCommonRequest<WorkspaceWithTeamAndFolderVO[]> {
    constructor(private readonly orgId: string, private readonly additionalRequestHeader?: Record<string, string>) {
        super()
    }

    public override requestDidFailed(): boolean {
        // 401 的错误码不过滤，要正常向外发送事件，用于弹出登录状态错误的弹窗
        if (this.responseStatusCode == 401) {
            window.dispatchEvent(new CustomEvent('request-error', { detail: { statusCode: this.responseStatusCode } }))
        }
        return false
    }
    public override additionalRequestHeaders(): { [key: string]: string } | undefined {
        if (this.additionalRequestHeader) {
            return this.additionalRequestHeader
        }
        return undefined
    }

    public override requestUrl() {
        return 'teams/folders/enterprise'
    }
    public override requestArgument() {
        return { orgId: this.orgId }
    }
}

// 获取一个团队的信息
@GET
export class GetTeamRequestV2 extends BaseCommonRequest<TeamWithAuthorityVO> {
    constructor(private readonly teamId: TeamID) {
        super()
    }

    public override requestDidFailed(): boolean {
        return false
    }

    public override requestUrl() {
        return `teams/v2/${this.teamId}`
    }
}

// 获取团队列表
@GET
export class GetTeamsRequest extends BaseCommonRequest<TeamWithAuthorityVO[]> {
    constructor(private readonly orgId?: OrgID) {
        super()
    }
    public override requestUrl() {
        return 'teams'
    }
    public override requestArgument() {
        return omitByValue({
            orgId: this.orgId,
        })
    }
}

// 更新一个团队的信息
@PUT
export class UpdateTeamRequest extends BaseCommonRequest<boolean> {
    constructor(private readonly teamId: TeamID, private readonly team: TeamUpdateVO) {
        super()
    }

    public override requestUrl() {
        return `teams/${this.teamId}`
    }

    public override requestBody() {
        return this.team
    }

    public override requestDidFailed(err: any): boolean {
        return requestDidFailedHandler(err, this.responseStatusCode)
    }
}

// 根据团队邀请链接查询团队
@GET
export class GetTeamByInviteLinkKey extends BaseCommonRequest<TeamWithAuthorityVO> {
    constructor(private readonly uniqueKey: string) {
        super()
    }
    public override requestUrl() {
        return `teams/query`
    }
    public override requestArgument() {
        return { uniqueKey: this.uniqueKey }
    }
}
// 查询团队最近编辑列表（不包括自己）
@GET
export class GetTeamEditUsers extends BaseCommonRequest<UserBriefVO[]> {
    constructor(private readonly teamId: TeamID) {
        super()
    }
    public override requestUrl() {
        return `teams/${this.teamId}/edit-users`
    }
    public override requestDidFailed(): boolean {
        return false
    }
}

// 查看团队下的资产交接内容
@GET
export class GetTeamAssetHandover extends BaseCommonRequest<AssetHandoverVO[]> {
    constructor(private readonly teamId: TeamID) {
        super()
    }
    public override requestUrl() {
        return `teams/${this.teamId}/asset-handover`
    }
    public override requestDidFailed() {
        return false
    }
}

// 移动文件时 查询有效项目
@GET
export class GetTeamsFoldersForMoveDoc extends BaseCommonRequest<{
    sharedFolders: FolderWithAuthorityVO[]
    teamsAndFolders: TeamWithFolderAndAuthorityVO[]
}> {
    constructor(private readonly docIds: string[], private queryString: string) {
        super()
    }
    public override requestArgument() {
        return { queryString: this.queryString, docIds: this.docIds.join(',') }
    }
    public override requestUrl() {
        return `teams/query/for-move-doc`
    }
}

// 移动文件时 查询有效项目
@GET
export class GetTeamsFoldersForMoveDocInEnterprise extends BaseCommonRequest<{
    sharedFolders: FolderWithAuthorityVO[]
    workspaceWithTeamAndFolderList: WorkspaceWithTeamAndFolderVO[]
}> {
    constructor(private readonly docIds: string[], private queryString: string) {
        super()
    }
    public override requestArgument() {
        return { queryString: this.queryString, docIds: this.docIds.join(',') }
    }
    public override requestUrl() {
        return `teams/query/for-move-doc-in-enterprise`
    }
}

// 移动文件时 查询有效项目
@GET
export class GetTeamsForMoveFolder extends BaseCommonRequest<TeamWithAuthorityVO[]> {
    constructor(private readonly folderId: string, private queryString: string) {
        super()
    }
    public override requestArgument() {
        return { folderId: this.folderId, queryString: this.queryString }
    }
    public override requestUrl() {
        return `teams/query/for-move-folder`
    }
}

// 高级企业版 移动文件时 查询有效项目
@GET
export class GetTeamsForMoveFolderInEnterprise extends BaseCommonRequest<
    Array<{
        workspaceWithAuthorityVO: WorkspaceWithAuthorityVO
        teamsWithAuthority: TeamWithAuthorityVO[]
    }>
> {
    constructor(private readonly folderId: string, private queryString: string) {
        super()
    }
    public override requestArgument() {
        return { folderId: this.folderId, queryString: this.queryString }
    }
    public override requestUrl() {
        return `teams/query/for-move-folder-in-enterprise`
    }
}

//在团队下指定交接人时搜索交接人
@GET
export class GetTeamAssetHandoverUser extends BaseCommonRequest<UserBriefVO[]> {
    constructor(private readonly teamId: TeamID, private queryString: string) {
        super()
    }
    public override requestArgument() {
        return { queryString: this.queryString }
    }
    public override requestUrl() {
        return `teams/${this.teamId}/handover-user`
    }
}
//指定交接人进行资产交接
@POST
export class PostHandoverUser extends BaseCommonRequest<TeamWithAuthorityVO> {
    constructor(private teamId: string, private previousOwner: number, private newOwner: number) {
        super()
    }

    public override requestUrl() {
        return `teams/${this.teamId}/asset-handover`
    }

    public override requestBody() {
        return {
            previousOwner: this.previousOwner,
            newOwner: this.newOwner,
        }
    }
}

// 这个接口删除的团队是可以恢复的
@DELETE
export class DeleteTeamV2 extends BaseCommonRequest {
    constructor(private teamId: string) {
        super()
    }

    public override requestUrl() {
        return `teams/v2/${this.teamId}`
    }
    public override additionalRequestHeaders(): { [key: string]: string } | undefined {
        return {
            'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'Time-Zone-Offset': new Date().getTimezoneOffset().toString(),
        }
    }
}

// 恢复团队时 查询团队的基本信息
@GET
export class GetRestoreTeamInfo extends BaseCommonRequest<TeamBriefForRestore> {
    constructor(private teamId: string) {
        super()
    }

    public override requestUrl() {
        return `teams/restore/brief/${this.teamId}`
    }
}

// 恢复删除的团队
@POST
export class RestoreTeam extends BaseCommonRequest {
    constructor(private teamId: string) {
        super()
    }

    public override requestUrl() {
        return `teams/restore/${this.teamId}`
    }
}

// 查询团队下面的项目和文件
@GET
export class GetTeamFoldersAndDocs extends BaseCommonRequest<FolderIdWithDocVO[]> {
    constructor(private teamId: string) {
        super()
    }

    public override requestUrl() {
        return `teams/v2/${this.teamId}/folderId-to-documents`
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 无团队权限时查询团队下面的项目和文件
@GET
export class GetTeamFoldersAndDocsNotMember extends BaseCommonRequest<FolderWithDocAndAuthority[]> {
    constructor(private teamId: string) {
        super()
    }

    public override requestUrl() {
        return `teams/${this.teamId}/folder-to-documents`
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 查询团队下面的项目和文件
@GET
export class GetTeamsInOrg extends BaseCommonRequest<TeamInOrgVO[]> {
    constructor(private orgId: string) {
        super()
    }

    public override requestUrl() {
        return `teams/in-org`
    }
    public override requestArgument() {
        return { orgId: this.orgId }
    }
}

// 高级企业版查询用户加入的团队 「你的团队」
@GET
export class GetTeamsInEnterprise extends BaseCommonRequest<TeamInOrgVO[]> {
    constructor(private readonly orgId: OrgID) {
        super()
    }

    public override requestUrl() {
        return `teams/in-enterprise`
    }

    public override requestArgument() {
        return { orgId: this.orgId }
    }
}

// 根据条件搜索团队
@GET
export class TeamSearchRequest extends BaseCommonRequest<TeamSearchGetVO> {
    constructor(private queryString: string, private orgId: OrgID, private pageSize?: number) {
        super()
    }

    public override requestUrl() {
        return 'teams/search'
    }

    public override requestArgument() {
        return omitByValue({ queryString: this.queryString, orgId: this.orgId, pageSize: this.pageSize })
    }
}

// 查询用户在其他团队下有席位的团队的团队履约状态、用户当前是否有宽限期等
@GET
export class QueryTeamAndUserState extends BaseCommonRequest<PlanAndUserStateVO[]> {
    constructor(private readonly isNotify?: boolean) {
        super()
    }
    public override additionalRequestHeaders(): { [key: string]: string } | undefined {
        if (this.isNotify) {
            return {
                'X-QoS-Level': 'notify',
            }
        }
        return undefined
    }
    public override requestUrl() {
        return 'teams/state'
    }
}

// 修改团队席位升级方式
@PUT
export class UpdateTeamSeatUpgradeType extends BaseCommonRequest<void> {
    constructor(
        private readonly teamId: TeamID,
        private readonly seatUpgradeType: Exclude<SeatUpgradeType, SeatUpgradeType.Unknown>
    ) {
        super()
    }

    public override requestBody() {
        return {
            teamId: this.teamId,
            seatUpgradeType: this.seatUpgradeType,
        }
    }

    public override requestUrl() {
        return `teams/seat-upgrade-type`
    }
}

@PUT
export class UpdateTeamWorkspace extends BaseCommonRequest<void> {
    constructor(private readonly teamIds: TeamID[], private readonly workspaceId: string) {
        super()
    }

    public override requestBody() {
        return {
            teamIds: this.teamIds,
            workspaceId: this.workspaceId,
        }
    }

    public override requestUrl() {
        return `teams/workspace`
    }
}

// 查询团队席位列表
@GET
export class QueryTeamSeats extends BaseCommonRequest<TeamSeatVO[]> {
    constructor(private readonly teamId: TeamID, private readonly queryString?: string) {
        super()
    }

    public override requestArgument() {
        return omitByValue({
            teamId: this.teamId,
            queryString: this.queryString,
        })
    }

    public override requestUrl() {
        return 'teams/seats'
    }

    public override additionalRequestHeaders(): { [key: string]: string } | undefined {
        return {
            'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'Time-Zone-Offset': new Date().getTimezoneOffset().toString(),
        }
    }
}

//  查询当前团队下的资源使用量，用于免费团队中检查能否新增项目、文档。只能查其他团队下的团队。
@GET
export class GetTeamResourceState extends BaseCommonRequest<TeamResourceStateVO> {
    constructor(private readonly teamId: TeamID) {
        super()
    }

    public override requestArgument() {
        return omitByValue({
            teamId: this.teamId,
        })
    }

    public override requestUrl() {
        return 'teams/resource-state'
    }
}

// 查询一个席位在团队下的最近5条活动日志
@GET
export class QueryTeamSeatOperations extends BaseCommonRequest<UserOperationVO[]> {
    constructor(private readonly teamId: TeamID, private readonly userId: UserID) {
        super()
    }

    public override requestArgument() {
        return {
            teamId: this.teamId,
            userId: this.userId,
        }
    }

    public override requestUrl() {
        return 'teams/seats/operations'
    }
}

// 查询用户在其他团队下能升级的团队和团队信息
@GET
export class QueryCanUpgradeTeams extends BaseCommonRequest<CanUpgradeTeamAndUserStateVO> {
    constructor(private readonly planType: PayPlanType) {
        super()
    }

    public override requestArgument() {
        return {
            planType: this.planType,
        }
    }

    public override requestUrl() {
        return 'teams/can-upgrade'
    }
}

@PUT
export class BatchUpdateTeamPublicStatus extends BaseCommonRequest {
    constructor(private readonly ids: TeamID[], private readonly publicStatus: PublicStatus) {
        super()
    }

    public override requestBody() {
        return {
            ids: this.ids,
            publicStatus: this.publicStatus,
        }
    }

    public override requestUrl() {
        return 'teams/public-status'
    }
}

// 工作区详情页，获取高级企业版未分配的团队列表
@GET
export class GetUnassignedTeamsInEnterprise extends BaseCommonRequest<TeamInOrgVO[]> {
    constructor(private readonly orgId: OrgID) {
        super()
    }

    public override requestUrl() {
        return 'teams/unassigned-in-enterprise'
    }

    public override requestArgument() {
        return { orgId: this.orgId }
    }
}

// 批量删除团队
@DELETE
export class BatchDeleteTeam extends BaseCommonRequest {
    constructor(private readonly ids: TeamID[]) {
        super()
    }
    public override additionalRequestHeaders(): { [key: string]: string } | undefined {
        return {
            'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'Time-Zone-Offset': new Date().getTimezoneOffset().toString(),
        }
    }

    public override requestUrl() {
        return 'teams/v2'
    }

    public override requestBody() {
        return { ids: this.ids }
    }
}

@GET
export class QueryUnassignTeamsInWorkspace extends BaseCommonRequest<TeamBriefVOForAssignWorkspace[]> {
    constructor(private readonly workspaceId: string, private readonly queryString?: string) {
        super()
    }

    public override requestArgument() {
        return {
            workspaceId: this.workspaceId,
            queryString: this.queryString ?? '',
        }
    }

    public override requestUrl() {
        return `teams/unassigned/workspace`
    }
}

// 高级企业版，查询未分配的团队列表
@GET
export class QueryUnassignTeamsInEnterprise extends BaseCommonRequest<TeamBriefVOForAssignWorkspace[]> {
    constructor(private readonly orgId: OrgID, private readonly queryString?: string) {
        super()
    }

    public override requestArgument() {
        return omitByValue({
            orgId: this.orgId,
            queryString: this.queryString,
        })
    }

    public override requestUrl() {
        return `teams/unassigned/enterprise`
    }
}
