import { Wukong } from '@wukong/bridge-proto'
import { KeyboardEventHandler, useMemo, useRef } from 'react'
import {
    IconSpacingHorizontal,
    IconSpacingVertical,
    MonoIconPanelLink16,
    MonoIconPanelVariable16,
    Select,
    SelectArrowSingleLevelProps,
} from '../../../../../../../../../../ui-lib/src'
import { featureSwitchManager } from '../../../../../../../../kernel/switch'
import { EditorDataTestId } from '../../../../../../../../window'
import { ScrubbableInput, ScrubbableInputProps } from '../../../../../../atom/inputs/components/scrubbable-input'
import { formateToFixed2 } from '../../../../../../atom/inputs/utils/format'
import { parseString } from '../../../../../../atom/inputs/utils/parse-string'
import { valueFilterUserConfig } from '../../../../../../atom/inputs/utils/value-filter'
import { useFieldConsumptionFloatVariable } from '../../../../../../design/common/use-field-variable-consumption'
import { useFloatVariablePanel } from '../../../../../../design/primitive-variable/use-float-variable-panel'
import { useAutoLayoutCommand, type CommandType } from '../../command'
import { AutoLayoutHoverItem, AutoLayoutState } from '../../types'
import { useHoverAutolayoutMenuItem } from '../../use-hover-autolayout-menu-item'
import { translation } from './space-input.translation'

const AutoValue = 'auto'
const MixedValue = 'mixed'

function useSpaceInputProps(
    state: AutoLayoutState,
    isFloat?: boolean
): {
    inputProps: ScrubbableInputProps
    selectProps: SelectArrowSingleLevelProps & {
        options: { value: string | number; label: string; disabled?: boolean }[]
    }
} {
    const command = useAutoLayoutCommand(state)
    const onBatchUpdateCommonSpace: CommandType['setStackSpace'] = (payload, _, options) =>
        command.setStackSpace(payload, !isFloat, options)
    const { hoverMenu, unHoverMenu } = useHoverAutolayoutMenuItem({
        hideMenu: !isFloat,
        hoverMenu: () => {
            if (state.flex.stackMode === Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_VERTICAL) {
                command.hoverAutoLayoutMenu(AutoLayoutHoverItem.AUTO_LAYOUT_HOVER_MENU_ITEM_VERTICAL_GAP)
            } else {
                command.hoverAutoLayoutMenu(AutoLayoutHoverItem.AUTO_LAYOUT_HOVER_MENU_ITEM_HORIZONTAL_GAP)
            }
        },
    })

    const isAutoSpacing = useMemo(
        () =>
            !state.flex.stackPrimaryAlignItems.isMixed &&
            state.flex.stackPrimaryAlignItems.value ===
                Wukong.DocumentProto.StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_SPACE_EVENTLY,
        [state.flex.stackPrimaryAlignItems.isMixed, state.flex.stackPrimaryAlignItems.value]
    )

    const spacingPropValue = state.flex.stackSpacing

    // 1. 都是 auto 间距，不应该 mixed
    // 2. 部分是 auto 间距，应该 mixed
    // 3. 都不是 auto 间距，按值计算
    const isMixed = !isAutoSpacing && spacingPropValue.isMixed

    const commonValue = spacingPropValue.value[0] // 非 mixed 值情况下的共有值
    const spaces: number[] = isMixed
        ? spacingPropValue.value
        : new Array(state.autoLayoutParentIds.length).fill(commonValue)

    const __DEBUG_INPUT = 114514
    let lastInputAuto = false

    // [WK-12768] auto 间距时，故意给值加上一个不会展示出来的小数点，来强制触发 onchange 事件
    const initialValue = isAutoSpacing ? commonValue + 0.000191981 : commonValue

    return {
        inputProps: {
            value: initialValue,
            isMixed,
            onChange(value, options) {
                if (lastInputAuto) {
                    onBatchUpdateCommonSpace('auto')
                    lastInputAuto = false
                } else {
                    onBatchUpdateCommonSpace(value, false, options)
                }
            },
            mixedMathHandler: {
                onChangeMixed(parse, options) {
                    // 处理用户输入情况下 mixed 变更
                    const changedMixedValues = parse(spaces)
                    onBatchUpdateCommonSpace(changedMixedValues as number[], false, options)
                },
                getScrubStartValueMixed() {
                    // 获取 mixed 初始值
                    return new Map(state.autoLayoutParentIds.map((id, i) => [id, spaces[i]]))
                },
                onScrubChangeAllMixed(map) {
                    // 处理拖动情况下的 mixed 变更
                    const changedMixedValues = state.autoLayoutParentIds.map((id) => map.get(id)!)
                    onBatchUpdateCommonSpace(changedMixedValues as number[])
                },
            },
            formatter: {
                parse(input, _oldValue) {
                    input = input.trim().toLocaleLowerCase()
                    const inputAuto = !input.length || input.includes('auto') || input.includes('自动')
                    if (inputAuto) {
                        lastInputAuto = true
                        return __DEBUG_INPUT // 这个值仅用于出 bug 时定位 bug 用
                    }

                    if (_oldValue && Array.isArray(_oldValue)) {
                        return _oldValue.map((x) => parseString(input, x))
                    }

                    const res = parseString(input, _oldValue)

                    return res
                },
                format(value) {
                    if (isAutoSpacing) {
                        return translation('Auto')
                    }

                    return formateToFixed2(value)
                },
                isEqual(a, b) {
                    return a === b
                },
            },
            resolution: 1,
            valueFilter: valueFilterUserConfig,
        },
        selectProps: {
            isMixed,
            value: isMixed ? MixedValue : isAutoSpacing ? AutoValue : commonValue,
            options: [
                ...(isMixed ? [] : [{ value: commonValue, label: `${commonValue}` }]),
                { value: AutoValue, label: translation('Auto') },
            ],
            onChange(value) {
                if (value === AutoValue) {
                    return onBatchUpdateCommonSpace(AutoValue)
                }
                const formatValue = +value
                if (!isNaN(formatValue)) {
                    return onBatchUpdateCommonSpace(formatValue, false)
                }
            },
            onMouseEnter: hoverMenu,
            onMouseLeave: unHoverMenu,
        },
    }
}

function SpaceInputV1(props: {
    state: AutoLayoutState
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>
    isFloat?: boolean
}) {
    const {
        selectProps: { onMouseEnter, onMouseLeave, options, ...selectProps },
        inputProps,
    } = useSpaceInputProps(props.state, props.isFloat)

    const renderInput = () => (
        <ScrubbableInput
            icon={
                props.state.flex.stackMode ===
                Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_VERTICAL ? (
                    <IconSpacingVertical />
                ) : (
                    <IconSpacingHorizontal />
                )
            }
            autoFocus={props.isFloat}
            onKeyDown={props.onKeyDown}
            testId={EditorDataTestId.AutoLayout.SpaceInput('space')}
            {...inputProps}
        />
    )

    return props.isFloat ? (
        renderInput()
    ) : (
        <Select.ArrowSingleLevel
            isSmallArrow
            label={
                <div className="w-full h-full" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                    {renderInput()}
                </div>
            }
            dataTestIds={{ triggerFocus: EditorDataTestId.AutoLayout.SpaceSelectTrigger('space') }}
            {...selectProps}
        >
            {options.map((option) => (
                <Select.ArrowSingleLevel.Option
                    key={option.value}
                    disabled={option.disabled}
                    value={option.value}
                    backwardIcon={''}
                    data-testid={EditorDataTestId.AutoLayout.SpaceSelectOptionItem('space')}
                >
                    {option.label}
                </Select.ArrowSingleLevel.Option>
            ))}
        </Select.ArrowSingleLevel>
    )
}

function useSpaceInputPropsV2(
    state: AutoLayoutState,
    isFloat?: boolean
): {
    inputProps: ScrubbableInputProps
    selectProps: SelectArrowSingleLevelProps & {
        options: { value: string | number; label: string; disabled?: boolean }[]
    }
    spaceVarPanel: ReturnType<typeof useFloatVariablePanel>
    detechStackSpacingVar: () => void
} {
    const command = useAutoLayoutCommand(state)

    const onBatchUpdateCommonSpace: CommandType['setStackSpace'] = (payload, _, options) =>
        command.setStackSpace(payload, !isFloat, options)

    const { hoverMenu, unHoverMenu } = useHoverAutolayoutMenuItem({
        hideMenu: !isFloat,
        hoverMenu: () => {
            if (state.flex.stackMode === Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_VERTICAL) {
                command.hoverAutoLayoutMenu(AutoLayoutHoverItem.AUTO_LAYOUT_HOVER_MENU_ITEM_VERTICAL_GAP)
            } else {
                command.hoverAutoLayoutMenu(AutoLayoutHoverItem.AUTO_LAYOUT_HOVER_MENU_ITEM_HORIZONTAL_GAP)
            }
        },
    })

    const isAutoSpacing = useMemo(
        () =>
            !state.flex.stackPrimaryAlignItems.isMixed &&
            state.flex.stackPrimaryAlignItems.value ===
                Wukong.DocumentProto.StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_SPACE_EVENTLY,
        [state.flex.stackPrimaryAlignItems.isMixed, state.flex.stackPrimaryAlignItems.value]
    )

    const spacingPropValue = state.flex.stackSpacing

    // 1. 都是 auto 间距，不应该 mixed
    // 2. 部分是 auto 间距，应该 mixed
    // 3. 都不是 auto 间距，按值计算
    const isMixed = !isAutoSpacing && spacingPropValue.isMixed

    const commonValue = spacingPropValue.value[0] // 非 mixed 值情况下的共有值
    const spaces: number[] = isMixed
        ? spacingPropValue.value
        : new Array(state.autoLayoutParentIds.length).fill(commonValue)

    const __DEBUG_INPUT = 114514
    let lastInputAuto = false

    // [WK-12768] auto 间距时，故意给值加上一个不会展示出来的小数点，来强制触发 onchange 事件
    const initialValue = isAutoSpacing ? commonValue + 0.000191981 : commonValue

    const variableValue = isAutoSpacing || isMixed ? 0 : commonValue
    const selectedVariable = useFieldConsumptionFloatVariable({
        field: Wukong.DocumentProto.VariableField.STACK_SPACING,
    })
    const spaceVarPanel = useFloatVariablePanel({
        requiredScopes: [Wukong.DocumentProto.VariableScope.ALL_SCOPES],
        selectedVariable: isMixed ? null : selectedVariable,
        selectedVariableFallbackFloatValue: variableValue,
        createEnable: true,
        defaultCreateValue: variableValue,
        hideIconBindUnbind: true,
        onVariableSelected: (id) => {
            command.setStackSpacingVar(id)
        },
        onVariableDetach: () => {
            command.detechStackSpacingVar()
        },
    })

    return {
        inputProps: {
            value: initialValue,
            isMixed,
            onChange(value, options) {
                if (lastInputAuto) {
                    onBatchUpdateCommonSpace('auto')
                    lastInputAuto = false
                } else {
                    onBatchUpdateCommonSpace(value, false, options)
                }
            },
            mixedMathHandler: {
                onChangeMixed(parse, options) {
                    // 处理用户输入情况下 mixed 变更
                    const changedMixedValues = parse(spaces)
                    onBatchUpdateCommonSpace(changedMixedValues as number[], false, options)
                },
                getScrubStartValueMixed() {
                    // 获取 mixed 初始值
                    return new Map(state.autoLayoutParentIds.map((id, i) => [id, spaces[i]]))
                },
                onScrubChangeAllMixed(map) {
                    // 处理拖动情况下的 mixed 变更
                    const changedMixedValues = state.autoLayoutParentIds.map((id) => map.get(id)!)
                    onBatchUpdateCommonSpace(changedMixedValues as number[])
                },
            },
            formatter: {
                parse(input, _oldValue) {
                    input = input.trim().toLocaleLowerCase()
                    const inputAuto = !input.length || input.includes('auto') || input.includes('自动')
                    if (inputAuto) {
                        lastInputAuto = true
                        return __DEBUG_INPUT // 这个值仅用于出 bug 时定位 bug 用
                    }

                    if (_oldValue && Array.isArray(_oldValue)) {
                        return _oldValue.map((x) => parseString(input, x))
                    }

                    const res = parseString(input, _oldValue)

                    return res
                },
                format(value) {
                    if (isAutoSpacing) {
                        return translation('Auto')
                    }

                    return formateToFixed2(value)
                },
                isEqual(a, b) {
                    return a === b
                },
            },
            resolution: 1,
            valueFilter: valueFilterUserConfig,
        },
        selectProps: {
            isMixed,
            value: isMixed ? MixedValue : isAutoSpacing ? AutoValue : commonValue,
            options: [
                ...(isMixed ? [] : [{ value: commonValue, label: `${commonValue}` }]),
                { value: AutoValue, label: translation('Auto') },
            ],
            onChange(value) {
                if (value === AutoValue) {
                    return onBatchUpdateCommonSpace(AutoValue)
                }
                const formatValue = +value
                if (!isNaN(formatValue)) {
                    return onBatchUpdateCommonSpace(formatValue, false)
                }
            },
            onMouseEnter: hoverMenu,
            onMouseLeave: unHoverMenu,
        },
        spaceVarPanel,
        detechStackSpacingVar: () => {
            command.detechStackSpacingVar()
        },
    }
}

export function SpaceInputV2(props: {
    state: AutoLayoutState
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>
    isFloat?: boolean
}) {
    const {
        selectProps: { onMouseEnter, onMouseLeave, options, ...selectProps },
        inputProps,
        spaceVarPanel,
        detechStackSpacingVar,
    } = useSpaceInputPropsV2(props.state, props.isFloat)

    const renderInput = () => (
        <>
            <ScrubbableInput
                icon={
                    props.state.flex.stackMode ===
                    Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_VERTICAL ? (
                        <IconSpacingVertical />
                    ) : (
                        <IconSpacingHorizontal />
                    )
                }
                autoFocus={props.isFloat}
                onKeyDown={props.onKeyDown}
                testId={EditorDataTestId.AutoLayout.SpaceInput('space')}
                {...inputProps}
                useVariable={props.isFloat ? undefined : spaceVarPanel.useVariable}
            />
            {props.isFloat ? null : spaceVarPanel.renderPanel()}
        </>
    )
    const countSelectRef = useRef<HTMLDivElement>(null)

    return props.isFloat ? (
        renderInput()
    ) : (
        <>
            <Select.ArrowSingleLevel
                isSmallArrow
                label={
                    <div
                        className="w-full h-full"
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        ref={countSelectRef}
                    >
                        {renderInput()}
                    </div>
                }
                dataTestIds={{ triggerFocus: EditorDataTestId.AutoLayout.SpaceSelectTrigger('space') }}
                {...selectProps}
                onChange={(value) => {
                    if (value === 'apply-variable') {
                        if (countSelectRef.current) {
                            const rect = countSelectRef.current.getBoundingClientRect()
                            spaceVarPanel.openPicker({
                                top: rect.bottom + 8,
                                left: rect.left,
                            })
                        }
                    } else if (value === 'detach-variable') {
                        detechStackSpacingVar()
                    } else {
                        selectProps.onChange?.(value)
                    }
                }}
            >
                {options.map((option) => (
                    <Select.ArrowSingleLevel.Option
                        key={option.value}
                        disabled={option.disabled}
                        value={option.value}
                        backwardIcon={''}
                        data-testid={EditorDataTestId.AutoLayout.SpaceSelectOptionItem('space')}
                    >
                        {option.label}
                    </Select.ArrowSingleLevel.Option>
                ))}

                {spaceVarPanel.hasRequiredVariable ? (
                    <Select.ArrowSingleLevel.Option
                        value={'apply-variable'}
                        key={'apply-variable'}
                        forwardIcon={<MonoIconPanelVariable16 />}
                        backwardIcon={''}
                        splitLineTop
                        dataTestId="space-input-apply-variable"
                    >
                        {translation('ApplyVariable')}
                    </Select.ArrowSingleLevel.Option>
                ) : null}
                {spaceVarPanel.hasSelectedVariable ? (
                    <Select.ArrowSingleLevel.Option
                        value={'detach-variable'}
                        key={'detach-variable'}
                        forwardIcon={<MonoIconPanelLink16 />}
                        backwardIcon={''}
                        splitLineTop={!spaceVarPanel.hasRequiredVariable}
                        dataTestId="space-input-detach-variable"
                    >
                        {translation('DetachVariable')}
                    </Select.ArrowSingleLevel.Option>
                ) : null}
            </Select.ArrowSingleLevel>
        </>
    )
}

export function SpaceInput(props: {
    state: AutoLayoutState
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>
    isFloat?: boolean
}) {
    if (featureSwitchManager.isEnabled('float-variable')) {
        return <SpaceInputV2 {...props} />
    } else {
        return <SpaceInputV1 {...props} />
    }
}
