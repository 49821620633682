import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Review: '查看',
    Dismiss: '忽略',
} as const

export const enTranslation = {
    Review: 'Review',
    Dismiss: 'Dismiss',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
