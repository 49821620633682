import { SwitchEditorTypeCommand, Wukong } from '@wukong/bridge-proto'
import { useEffect } from 'react'
import { useViewState } from '../../../view-state-bridge'
import { useCommand } from '../../context/document-context'
import { Switch } from '../atom/switch/switch'
import { logs, SwitchModeWay } from '../dev-mode/logs'
import { DevModeSwitch } from '../dev-mode/switch/dev-mode-switch'

export function SwitchEditorTypeIcon() {
    const editorType = useViewState('editorType')
    const command = useCommand()
    const isDevMode = editorType === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV
    const switchEditorType = () => {
        isDevMode
            ? logs.DevMode.enterDesignMode({ enter_designmode_way: SwitchModeWay.Toolbar })
            : logs.DevMode.enterDevMode({ enter_devmode_way: SwitchModeWay.Toolbar })
        command.DEPRECATED_invokeBridge(SwitchEditorTypeCommand)
    }
    useEffect(() => {
        if (window.localBridge?.bindToggleDevMode) {
            window.localBridge.bindToggleDevMode((isEnter: boolean) => {
                if (isEnter === isDevMode) {
                    return
                }
                command.DEPRECATED_invokeBridge(SwitchEditorTypeCommand)
            })
        }
    }, [command, isDevMode])

    return (
        <Switch>
            <DevModeSwitch onClick={switchEditorType} testid="dev-mode-switch" checked={isDevMode} />
        </Switch>
    )
}
