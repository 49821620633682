import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    DialogDescriptionPrefixInOrg: '在团队中导入文件',
    DialogDescriptionPrefixInTeam: '导入文件到专业版团队',

    DialogOkTextOfAdminOrAutoGrade: '导入',
    DialogCancelTextOfAdminOrAutoGrade: '取消',

    DialogTitleOfAdminOrAutoGradeInOrg: '确定要导入文件到团队中？',
    DialogTitleOfAdminOrAutoGradeInTeam: '确定要导入文件到专业版团队？',

    DialogContentOfAdmin:
        '这是一个计费动作，作为管理员，你将升级自己为「设计席位」。如果你不想升级席位，请导入到草稿。',
    DialogContentOfAutoGrade: '这是一个计费动作，你将升级自己为「设计席位」。如果你不想升级席位，请导入到草稿。',
} as const

export const enTranslation = {
    DialogDescriptionPrefixInOrg: 'To import files to a team',
    DialogDescriptionPrefixInTeam: 'To import files to a Professional team',

    DialogOkTextOfAdminOrAutoGrade: 'Import',
    DialogCancelTextOfAdminOrAutoGrade: 'Cancel',
    DialogTitleOfAdminOrAutoGradeInOrg: 'Are you sure you want to import files to a team?',
    DialogTitleOfAdminOrAutoGradeInTeam: 'Are you sure you want to import files to a Professional team?',

    DialogContentOfAdmin:
        'This is a billable event, as an admin, you will be upgraded to a "Designer seat". If you prefer not to upgrade, import the files to drafts instead.',
    DialogContentOfAutoGrade:
        'This is a billable event, you will be upgraded to a "Designer seat". If you prefer not to upgrade, import the files to drafts instead.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
