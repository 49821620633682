/* eslint-disable no-restricted-imports */
import { MouseEvent, useCallback, useEffect, useMemo, useRef } from 'react'
import { Wukong } from '@wukong/bridge-proto'
import { useCommentService } from '../../../../main/app-context'
import { useHandleRightClickEvent } from '../../../../main/canvas/use-right-click'
import { useViewState } from '../../../../view-state-bridge'
import { CommentDataTestId } from '../../../../window'
import { CanvasWrapper } from '../../canvas-wrapper'
import { Bubbles } from '../comment-bubble/bubbles'
import { CommentDetail } from '../comment-detail/comment-detail'
import { CommentDetailOverlayContent } from '../comment-detail/comment-detail-overlay-content'
import {
    useActiveCommentId,
    useCanvasCommentsMap,
    useCreateComment,
    useDrawingPosition,
} from '../comment-service/comment-service-hook'
import { useEventCanvas } from '../comment-service/use-event-canvas'
import { useEventWheel } from '../comment-service/use-event-wheel'
import { CommentToast } from '../comment-toast/comment-toast'
import { ContentCreating } from '../create-comment/content-creating'
import { ContentCreatingOverlayContent } from '../create-comment/content-creating-overlay-content'
import { PositionCreating } from '../create-comment/position-creating'
import { Overlay } from '../overlay/overlay'

import RightClickSource = Wukong.DocumentProto.RightClickSource

export function CommentContent() {
    const commentService = useCommentService()
    const activeCommentId = useActiveCommentId()
    const canvasCommentsMap = useCanvasCommentsMap()
    const createComment = useCreateComment()
    const drawingPosition = useDrawingPosition()
    const commentInteractive = useViewState('commentInteractive', true)

    const { dispatchWheelToCanvas } = useEventWheel()

    useEventCanvas()

    const detailComment = useMemo(() => {
        return canvasCommentsMap?.get(activeCommentId ?? NaN)
    }, [activeCommentId, canvasCommentsMap])

    const contentRef = useRef<HTMLDivElement | null>(null)

    const handleBubble = useHandleRightClickEvent(RightClickSource.RIGHT_CLICK_SOURCE_BUBBLE)
    const handleRightClickBubble = useCallback(
        (e: MouseEvent) => {
            e.preventDefault()
            handleBubble(e)
        },
        [handleBubble]
    )

    useEffect(() => {
        const contentEl = contentRef.current
        if (!contentEl) {
            return
        }
        const remove = dispatchWheelToCanvas(contentEl)
        return () => {
            remove()
        }
    }, [dispatchWheelToCanvas])

    return (
        <div ref={contentRef} data-testid={CommentDataTestId.CommentContent}>
            <CanvasWrapper>
                <Overlay
                    ref={commentService.overlayPositionService.setOverlayElement}
                    preventMouseEvent={!commentInteractive}
                >
                    <Bubbles handleRightClickBubble={handleRightClickBubble} />
                    {drawingPosition ? (
                        <PositionCreating
                            startPosition={drawingPosition.startPosition}
                            endPosition={drawingPosition.endPosition}
                        />
                    ) : null}
                    {createComment ? <ContentCreatingOverlayContent /> : null}
                    {detailComment ? <CommentDetailOverlayContent comment={detailComment} /> : null}
                </Overlay>
                {createComment ? <ContentCreating key={createComment.id} /> : null}
                {detailComment ? <CommentDetail comment={detailComment} /> : null}
                <CommentToast />
            </CanvasWrapper>
        </div>
    )
}
