import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    NoProjects: '还没有任何项目',
    EditedTo: '修改于',
    RecognitionComplete: '识别完成',
    Detected: '共检测',
    '10kLayers': '万个图层',
    View: '查看结果',
    RecognitionFailureDue: '网络异常导致识别失败',
    PleaseTryAgain: '请稍后再试',
    TheFileIs: '文件过大',
    PleaseSplitIt: '请拆分成 40 万图层以下的文件再试',
    CurrentServerBusy: '当前服务器繁忙',
    ExcessiveNumberOf: '文件数量过多',
    PleaseReduceTo: '请减少至 6 个以下再试',
    YouHaveAn: '你有正在进行的识别任务',
    PleaseTryAgain_synonyms1: '请在任务结束后再试',
    NoMobileInterface: '未检测到界面',
    PleaseSelectThe: '请重新选择文件后再试',
    GotIt: '我知道了',
    Recognizing: '正在识别',
    Cancel: '取消识别',
    SelectFiles: '选择识别文件',
    PTQXkb: '已选择',
    FilesSelected: '个文件',
    Cancel_synonyms1: '取消',
    Start: '开始识别',
    Subtitle: '扫描设计文件，发现设计系统缺少或需要更新的组件与样式。',
    CheckAll: '全选',
    NoFiles: '还没有任何文件',
    Draft: '草稿',
    HideLowUsage: '新发现样式过多时，隐藏使用次数较低的样式',
    SkipCasesWithDifferentLineHeightOnly: '跳过仅行高与现有样式不同的情况',
    Preferences: '维护规则',
    FreePageNotEnough: '文件免费页面不足，无法保存维护结果',
    PleaseDeletePage: '请删除页面后再试',
    OverFreePageHint: '已使用 3 个免费页面, 无法开始 AI 设计系统维护。',
    HasNoComponents: '本文件内没有样式或组件，无法进行 AI 设计系统维护。',
    ComponentsCount:
        '本文件包含 {{componentCount}} 个组件，{{styleCount}} 个样式。选择设计文件并开始扫描，以发现新组件与新样式。',
    Onboard1:
        '发起 AI 设计系统维护，可以将从所选文件中提取的新设计元素与当前文件内已有样式和组件进行对比，发现需要新增或修改的元素。',
    Onboard2: '选择用于识别的文件，点击右下角「开始识别」试试吧。',
    AiLibraryMaintain: 'AI 设计系统维护',
    DocSchemaVersionNotSame: '文档数据升级中，请稍后再试',
    Others: '其他',
} as const

export const enTranslation = {
    NoProjects: 'No projects',
    EditedTo: 'Edited',
    RecognitionComplete: 'Recognition Complete',
    Detected: 'Detected',
    '10kLayers': 'layers',
    View: 'View results',
    RecognitionFailureDue: 'Unable to recognize due to network error.',
    PleaseTryAgain: 'Try again later',
    TheFileIs: 'The file is too large,',
    PleaseSplitIt: 'split it into smaller files less than 400k layers and try again.',
    CurrentServerBusy: 'Current server busy',
    ExcessiveNumberOf: 'Excessive number of files',
    PleaseReduceTo: 'Reduce to fewer than 6 files and try again.',
    YouHaveAn: 'You have an ongoing recognition task,',
    PleaseTryAgain_synonyms1: 'Try again after task completion',
    NoMobileInterface: 'No interface is detected,',
    PleaseSelectThe: 'reselect the file and try again.',
    GotIt: 'OK',
    Recognizing: 'Recognizing',
    Cancel: 'Cancel',
    SelectFiles: 'Select files',
    PTQXkb: '',
    FilesSelected: '{{file}} selected',
    Cancel_synonyms1: 'Cancel',
    Start: 'Start recognizing',
    Subtitle:
        'Scan design files to find components and styles that are missing or need updates within the design system. ',
    CheckAll: 'Select all',
    NoFiles: 'No files',
    Draft: 'Draft',
    HideLowUsage: 'When too many new styles are discovered, hide the less frequently used ones. ',
    SkipCasesWithDifferentLineHeightOnly: 'Ignore cases where only row height differs from current styles. ',
    Preferences: 'Preferences',
    FreePageNotEnough: 'Insufficient free pages, unable to save maintenance results.',
    PleaseDeletePage: 'Please delete a page and try again.',
    OverFreePageHint: 'All 3 free pages used, unable to start AI design system maintenance.',
    HasNoComponents: 'This file has no styles or components for AI design system maintainence.',
    ComponentsCount:
        'This file has {{componentCount}} components and {{styleCount}} styles. Select design files and start scanning to discover new components and styles.',
    Onboard1:
        'AI design system maintenance allows you to extract new design elements from selected files,  compare them with existing styles and components in the current file, and find elements that need to be added or modified.',
    Onboard2: 'Select files for recognition and click "Start recognizing" at the button right corner to try it.',
    AiLibraryMaintain: 'AI Design System Maintainer',
    DocSchemaVersionNotSame: 'Document data is being upgraded. Try again later.',
    Others: 'Others',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
