import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Blur: '模糊',
    Spread: '扩展',
    ShowBehind: '显示在透明区域后面',
} as const

export const enTranslation = {
    Blur: 'Blur',
    Spread: 'Spread',
    ShowBehind: 'Show behind transparent areas',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
