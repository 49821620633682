import { Wukong } from '@wukong/bridge-proto'
import { forwardRef, useImperativeHandle } from 'react'
import { SingleGrid } from '../../../atom/grid/single-grid'
import { ScrubbableInputNumber } from '../../../atom/inputs/scrubbable-input-number'
import { StrokeIndependentOptionMap } from '../stroke-independent-type/stroke-independent-type-icon'
import classes from './stroke-four-independent-weight.module.less'
import { useStrokeFourIndependentWeight } from './use-stroke-four-independent-weight'

export interface StrokeFourIndependentWeightRef {
    openFourIndependentWeight: () => void
    closeFourIndependentWeight: () => void
}

// 四边单边线重输入框
export const StrokeFourIndependentWeight = forwardRef<StrokeFourIndependentWeightRef>((_, ref) => {
    const {
        leftBorderState,
        rightBorderState,
        topBorderState,
        bottomBorderState,
        onChangeTopWeight,
        onChangeLeftWeight,
        onChangeBottomWeight,
        onChangeRightWeight,
        showInputGroup,
        setShowInputGroup,
    } = useStrokeFourIndependentWeight()

    useImperativeHandle(
        ref,
        () => ({
            openFourIndependentWeight() {
                setShowInputGroup(true)
            },
            closeFourIndependentWeight() {
                setShowInputGroup(false)
            },
        }),
        [setShowInputGroup]
    )

    return showInputGroup ? (
        <div className={classes.containerNewSvg}>
            <SingleGrid style={{ gridTemplateColumns: 'repeat(124, 2px)' }}>
                <SingleGrid.Item start={9} span={35}>
                    <ScrubbableInputNumber
                        icon={
                            StrokeIndependentOptionMap.get(
                                Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_LEFT
                            )?.icon
                        }
                        isMixed={
                            leftBorderState?.type ===
                            Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                        }
                        value={leftBorderState?.value}
                        onChange={onChangeLeftWeight}
                        min={0}
                        scrubbingDisabled={
                            leftBorderState?.type ===
                            Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                        }
                    />
                </SingleGrid.Item>
                <SingleGrid.Item start={48} span={35}>
                    <ScrubbableInputNumber
                        icon={
                            StrokeIndependentOptionMap.get(
                                Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_TOP
                            )?.icon
                        }
                        isMixed={
                            topBorderState?.type ===
                            Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                        }
                        value={topBorderState?.value}
                        onChange={onChangeTopWeight}
                        min={0}
                        scrubbingDisabled={
                            topBorderState?.type ===
                            Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                        }
                    />
                </SingleGrid.Item>
            </SingleGrid>
            <SingleGrid style={{ gridTemplateColumns: 'repeat(124, 2px)' }}>
                <SingleGrid.Item start={9} span={35}>
                    <ScrubbableInputNumber
                        icon={
                            StrokeIndependentOptionMap.get(
                                Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_RIGHT
                            )?.icon
                        }
                        isMixed={
                            rightBorderState?.type ===
                            Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                        }
                        value={rightBorderState?.value}
                        onChange={onChangeRightWeight}
                        min={0}
                        scrubbingDisabled={
                            rightBorderState?.type ===
                            Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                        }
                    />
                </SingleGrid.Item>
                <SingleGrid.Item start={48} span={35}>
                    <ScrubbableInputNumber
                        icon={
                            StrokeIndependentOptionMap.get(
                                Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_BOTTOM
                            )?.icon
                        }
                        isMixed={
                            bottomBorderState?.type ===
                            Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                        }
                        value={bottomBorderState?.value}
                        onChange={onChangeBottomWeight}
                        min={0}
                        scrubbingDisabled={
                            bottomBorderState?.type ===
                            Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                        }
                    />
                </SingleGrid.Item>
            </SingleGrid>
        </div>
    ) : (
        <></>
    )
})

StrokeFourIndependentWeight.displayName = 'StrokeFourIndependentWeight'
