import { translation } from './component-style-list-layout-switch.translation'

import { UpdateLibraryAssetPanelDisplayModeCommand, Wukong } from '@wukong/bridge-proto'
import { IconGrid, IconList, Tooltip } from '../../../../../../../ui-lib/src'
import { useCommand } from '../../../../../main/app-context'
import { useViewState } from '../../../../../view-state-bridge'
import { IconButton } from '../../../atom/button/icon-button'
import style from '../library-dialog-entry-btn/library-dialog-entry-btn.module.less'

// 控制组件库列表布局展示
export function ComponentStyleListLayoutSwitch() {
    const command = useCommand()
    const libraryAssetPanel = useViewState('libraryAssetPanel')
    const layout = libraryAssetPanel?.displayMode
    const toggleLayout = () => {
        command.DEPRECATED_invokeBridge(
            UpdateLibraryAssetPanelDisplayModeCommand,
            Wukong.DocumentProto.Arg_UpdateLibraryAssetPanelDisplayMode.create({
                value:
                    layout ===
                    Wukong.DocumentProto.VLibraryAssetPanelDisplayMode.V_LIBRARY_ASSET_PANEL_DISPLAY_MODE_GRID
                        ? Wukong.DocumentProto.VLibraryAssetPanelDisplayMode.V_LIBRARY_ASSET_PANEL_DISPLAY_MODE_LIST
                        : Wukong.DocumentProto.VLibraryAssetPanelDisplayMode.V_LIBRARY_ASSET_PANEL_DISPLAY_MODE_GRID,
            })
        )
    }
    return (
        <Tooltip
            title={
                layout === Wukong.DocumentProto.VLibraryAssetPanelDisplayMode.V_LIBRARY_ASSET_PANEL_DISPLAY_MODE_GRID
                    ? translation('ShowAsList')
                    : translation('ShowAsGrid')
            }
        >
            <IconButton
                icon={
                    layout ===
                    Wukong.DocumentProto.VLibraryAssetPanelDisplayMode.V_LIBRARY_ASSET_PANEL_DISPLAY_MODE_GRID
                        ? IconList()
                        : IconGrid()
                }
                selected={false}
                onClick={toggleLayout}
                className={style.fill}
                disabled={!libraryAssetPanel?.filterText && !libraryAssetPanel?.hasContent}
            />
        </Tooltip>
    )
}
