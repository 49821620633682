import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    ManagePermissions: '管理权限',
    Yci: '你可以邀请企业内的其他成员来更新此插件。',
    Ecs: '输入姓名、邮箱，回车输入多个',
    Leave: '离开',
    Remove: '移除',
    You: '（你）',
    LeaveConfirmTitle: '确定离开插件「{{pluginName}}」？',
    LeaveConfirmContent: '离开后，你将失去该插件的更新权限。',
    RemoveConfirmTitle: '确定从插件「{{pluginName}}」移除「{{userName}}」？',
    RemoveConfirmContent: '移除后，该成员将失去该插件的更新权限。',
    OwnerConfirmTitle: '确定要将插件「{{pluginName}}」的所有者权限转移给「{{userName}}」？',
    OwnerConfirmContent: '此操作无法撤销。',
    TransferOwnership: '转移所有者',
} as const

export const enTranslation = {
    ManagePermissions: 'Manage permissions',
    Yci: 'You can invite other members in the organization to update this resource.',
    Ecs: 'Email, comma separated',
    Leave: 'Leave',
    Remove: 'Remove',
    You: '(You)',
    LeaveConfirmTitle: 'Are you sure you want to leave plugin "{{pluginName}}"?',
    LeaveConfirmContent: 'You will lose update permission to this plugin.',
    RemoveConfirmTitle: 'Are you sure you want to remove "{{userName}}" from "{{pluginName}}"?',
    RemoveConfirmContent: 'After removal, this member will lose update permission to this plugin.',
    OwnerConfirmTitle: 'Are you sure you want to make "{{userName}}" the owner of "{{pluginName}}"?',
    OwnerConfirmContent: 'You cannot undo this action.',
    TransferOwnership: 'Transfer ownership',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
