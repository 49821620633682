import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    Name: '名称',
    NewStyle: '请输入样式名称',
    Description: '描述',
    WhatIsIt: '请输入样式描述',
} as const

export const enTranslation = {
    Name: 'Name',
    NewStyle: 'New style',
    Description: 'Description',
    WhatIsIt: 'What is it for?',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
