import { GetSingleMissLibraryContentCommand, Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
    IconMissingLibrarySvg,
    MonoIconCommonArrowLeft16,
    MonoIconCommonArrowRight16,
    Scrollbar,
    WKDivider,
} from '../../../../../../ui-lib/src'
import { isEnglishLanguage } from '../../../../../../util/src'
import { LibraryId } from '../../../../kernel/interface/component-style-library-id'
import { DocID } from '../../../../kernel/interface/type'
import { useCommand, useLibraryComponentService } from '../../../../main/app-context'
import { LibraryComponentViewEditorConfigProvider } from '../../../../share/component-style-library/library-component-viewer/context'
import {
    LibraryComponentListLayout,
    LibraryComponentViewer,
} from '../../../../share/component-style-library/library-component-viewer/library-component-viewer'
import { ComponentViewerItem } from '../../../../share/component-style-library/library-component-viewer/use-library-component-viewer'
import styles from '../../../../share/component-style-library/library-control-list-item-detail/library-control-list-item-detail.module.less'
import {
    LibraryStyleListLayout,
    LibraryStyleViewer,
    type StyleViewItem,
} from '../../../../share/component-style-library/library-style-viewer/library-style-viewer'
import { LibraryTestId } from '../../../../window'
import { useLibraryModalConfigContext } from '../../../context/library-component-style/library-modal-config'
import { useLibraryRemoteUsedAndMissingListContext } from '../library-remote-used-list/use-library-remote-used-hook'
import classes from './library-remote-missing-list.module.less'
import { translation } from './library-remote-missing-list.translation'

// 缺失组件库列表
export function LibraryRemoteMissingList(props: { className?: string }) {
    const { missingLibraryInfoList } = useLibraryRemoteUsedAndMissingListContext()
    const {
        libraryModalRouterService: { goToRemoteLibraryHome, closeLibraryMainModal },
    } = useLibraryComponentService()
    const {
        mainModalConfig: { routePath },
    } = useLibraryModalConfigContext()

    // 当缺失组件库列表为空时，直接关闭弹窗
    useEffect(() => {
        if (
            !missingLibraryInfoList.length &&
            routePath ==
                Wukong.DocumentProto.LibraryMainModalRoutePathType
                    .LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REMOTE_MISS_LIBRARY_DETAIL
        ) {
            closeLibraryMainModal()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [missingLibraryInfoList.length, closeLibraryMainModal])

    return (
        <div
            className={classnames(styles.container, props.className)}
            data-testid={LibraryTestId.HomeModal.MissingList}
        >
            <div className={styles.topContainer}>
                <div className="flex items-center" onClick={goToRemoteLibraryHome}>
                    <MonoIconCommonArrowLeft16 className="color-$wk-v2-label-color-gray-8" />
                    <span className={styles.docNameContainer}>
                        <IconMissingLibrarySvg className={styles.titleIcon} />
                        {translation('MissingLibraries')}
                    </span>
                </div>
            </div>
            <WKDivider className={styles.divider} />
            <Scrollbar className={styles.scrollWrapper}>
                {missingLibraryInfoList.map((libraryInfo) => (
                    <LibraryRemoteMissingListItem
                        key={libraryInfo.id}
                        documentId={libraryInfo.docId}
                        documentName={libraryInfo.documentName}
                        libraryId={libraryInfo.id}
                    />
                ))}
            </Scrollbar>
        </div>
    )
}

function LibraryRemoteMissingListItem(props: {
    documentId: DocID
    documentName: string | undefined
    libraryId: LibraryId | undefined
}) {
    const command = useCommand()
    const [missingContent, setMissingContent] = useState<Wukong.DocumentProto.IRet_GetSingleMissLibraryContent>()
    const {
        libraryModalRouterService: { goToLibraryReplaceDetail },
    } = useLibraryComponentService()

    const onClickTitle = useCallback(() => {
        goToLibraryReplaceDetail(props.documentId, props.documentName, props.libraryId)
    }, [goToLibraryReplaceDetail, props.documentId, props.documentName, props.libraryId])

    useEffect(() => {
        setMissingContent(
            command.DEPRECATED_invokeBridge(GetSingleMissLibraryContentCommand, {
                documentId: props.documentId,
                libraryId: props.libraryId,
            })
        )
    }, [props.documentId, command, props.libraryId])

    const title = useMemo(() => {
        if (props.documentName) {
            return props.documentName
        }

        const texts: string[] = []
        if (missingContent?.missingComponents?.length) {
            texts.push(
                `${missingContent?.missingComponents.length} ${
                    isEnglishLanguage()
                        ? missingContent?.missingComponents.length === 1
                            ? 'component'
                            : 'components'
                        : '个组件'
                }`
            )
        }
        if (missingContent?.missingStyles?.length) {
            texts.push(
                `${missingContent?.missingStyles.length} ${
                    isEnglishLanguage() ? (missingContent?.missingStyles.length === 1 ? 'style' : 'styles') : '个样式'
                }`
            )
        }
        if (texts.length) {
            return isEnglishLanguage()
                ? `Missing library with ${texts.join(isEnglishLanguage() ? ', ' : '，')}`
                : `${texts.join(isEnglishLanguage() ? ', ' : '，')}缺失`
        }
        return ''
    }, [props.documentName, missingContent?.missingComponents?.length, missingContent?.missingStyles?.length])

    return (
        <>
            <div
                className={classnames(styles.docTitle, 'flex', 'space-between', 'items-center')}
                data-testid={LibraryTestId.HomeModal.MissingItem(title)}
                onClick={onClickTitle}
            >
                <span className="overflow-hidden overflow-ellipsis flex-auto">{title}</span>
                <MonoIconCommonArrowRight16 className="color-$wk-v2-label-color-gray-8 flex-none fill-none" />
            </div>
            <MissingLibraryContentStyleDetailViewer missingStyles={missingContent?.missingStyles ?? []} />
            <MissingLibraryContentComponentDetailViewer missingComponents={missingContent?.missingComponents ?? []} />
            <WKDivider className={styles.detailDivider} />
        </>
    )
}

// 缺失组件库-组件列表预览
function MissingLibraryContentComponentDetailViewer(props: {
    missingComponents: Wukong.DocumentProto.IMissLibraryComponentOrStyleItem[]
}) {
    return (
        <LibraryComponentListLayout
            maxChildrenLength={props.missingComponents.length}
            className={styles.libraryComponentListContainer}
        >
            <LibraryComponentViewEditorConfigProvider draggable={false}>
                {props.missingComponents.map((missingComponent) => (
                    <LibraryComponentViewer
                        key={missingComponent.renderKey}
                        component={
                            {
                                isLocal: false,
                                ...missingComponent,
                            } as unknown as ComponentViewerItem
                        }
                        innerStyle={{ width: 92, height: 92 }}
                        disableRightClick
                    />
                ))}
            </LibraryComponentViewEditorConfigProvider>
        </LibraryComponentListLayout>
    )
}

// 缺失组件库-样式列表预览
function MissingLibraryContentStyleDetailViewer(props: {
    missingStyles: Wukong.DocumentProto.IMissLibraryComponentOrStyleItem[]
}) {
    return (
        <LibraryStyleListLayout
            maxChildrenLength={props.missingStyles.length}
            className={classes.libraryStyleListContainer}
        >
            {props.missingStyles.map((missingStyle) => (
                <LibraryStyleViewer key={missingStyle.renderKey} style={missingStyle as StyleViewItem} />
            ))}
        </LibraryStyleListLayout>
    )
}
