import { Wukong } from '@wukong/bridge-proto'
import type { DeepRequired } from '../../../../util/src'
export type { DeepRequired }

export type CallAILayoutRequestParam = Wukong.DocumentProto.CallAILayoutRequest

export interface AILayoutRequestResponseV2 {
    aiResult: AILayoutResult
}

export interface AILayoutResult {
    success: boolean
    jsScript: JsScript
    supported: boolean
    layoutFrameId: string
}

interface Vars {
    [key: string]: string
}
interface JsScript {
    script: string // js 脚本模板
    vars: Vars
}

export type AILayoutCmdArg = Wukong.DocumentProto.Arg_ApplayAILayoutCommand

interface Rect {
    x: number
    y: number
    width: number
    height: number
}

export interface AICopilotParam {
    indexId: string
    prompt: string
    intentType: string
    screenRootData: string
    screenImg: string
    boundingBox: Rect
    parentId: string
    docId: string
}

interface Point {
    x: number
    y: number
}

export interface Candidate {
    category: string // 候选组件的类别
    nodeData: string // 候选组件的 json 数据, 根节点的 x，y 是计算后插入的位置
    position: Point
}

interface Rect {
    x: number
    y: number
    width: number
    height: number
}

export interface ComponentData {
    docId: string
    docName: string
    screenshotId: string
    nodeIds: string[]
    commonAncestorId?: string
    containerIds: string[]
    highlightArea: Rect
}

export interface ComponentCandidate {
    id: string
    componentData: ComponentData
    repeats: ComponentData[]
    fromPublishedComponentFile: boolean
    inPlayGround: boolean
}

export interface ValidateState {
    validated: boolean
}

export interface TooltipData {
    show: boolean
    x: number
    y: number
}

export interface Cluster {
    componentCandidates: ComponentCandidate[]
}

interface Page {
    name: string
    clusters: Cluster[]
}

export interface AiRecognizeResult {
    pages: Page[]
    colorPage?: {
        pureColorClusters: Cluster[]
        gradientColorClusters: Cluster[]
    }
    textPage?: {
        clusters: Cluster[]
    }
    effectPage?: {
        dropShadowCluster: Cluster
        innerShadowCluster: Cluster
        layerBlurCluster: Cluster
        backgroundBlurCluster: Cluster
    }
    iconPage?: {
        clusters: Cluster[]
    }
}

export interface AiRecognizeResultWrapper {
    aiResult: AiRecognizeResult
}

export namespace AiDuplicate {
    export interface IndexData {
        indexId: string
        key: string
    }

    export interface DuplicateNodeInfo {
        indexDataList: IndexData[]
        jsScript: JsScript
    }
    export interface AiDuplicateResult {
        duplicatedNodeInfos: DuplicateNodeInfo[]
    }

    export interface AiDuplicateResponse {
        aiResult: AiDuplicateResult
        nodes: Record<string, string>
        blobs: Record<string, string>
    }
}

export namespace AICopilotV2 {
    export interface ComponentDataV2 {
        docId: string // 推荐节点所在的 doc id
        nodeId: string // 推荐节点的 node id
    }

    // eslint-disable-next-line @typescript-eslint/no-shadow
    export type JsScript = DeepRequired<Wukong.DocumentProto.IArg_evalJsScript>

    export interface CandidateV2 {
        category: string // 候选组件的类别
        componentData: ComponentDataV2 // 候选组件的数据
        position: Point
        jsScript: JsScript
        extendInfos: string
    }

    export interface RawResponse {
        aiResult: string
        traceId: string // 用于追踪请求
        nodes: Record<string, Record<string, string>>
        blobs: Record<string, Record<string, string>>
    }

    export interface Response {
        traceId: string
        aiResult: CandidateV2[]
        nodes: Record<string, Record<string, string>>
        blobs: Record<string, Record<string, string>>
    }

    export type NodeBlobInfo = Pick<Response, 'nodes' | 'blobs'>

    export interface ValidateResponse {
        open: boolean
    }

    export interface TextPositionParam {
        traceId: string // 用于追踪请求
        docId: string // 当前文档 id
        indexId: string // 用户 id
        positionQueryList: TextPositionQuery[] // 用于查询文本对齐位置的数据
    }

    export interface TextPositionQuery {
        componentData: ComponentDataV2 // 文本组件的 id 信息
        category: string // 候选类型
        boundingBox: Rect // 文本组件的边框
        extendInfos: string // 附加的额外信息，原路返回
    }

    export interface TextPositionRawResponse {
        aiResult: string // json 字符串，内容为 TextPositionResponse
    }

    export type TextPositionResponse = TextPositionData[] // 带有位置信息的候选节点

    export interface TextPositionData {
        componentData: ComponentDataV2 // 文本组件的 id 信息
        position: Point // 位置信息
    }
}

export interface AIComponentReplaceResponse {
    componentSubstituteEntries: AIComponentReplaceEntry[]
    styleSubstituteEntries: AIComponentReplaceEntry[]
    snapshots: Record<number, AIComponentReplaceSnapshot>
}

export interface AIComponentReplaceEntry {
    candidateThumbnailResourceId: string
    substituteContentId: number
    items: AIComponentReplaceScene[]
    candidateName: string
    type: string
}
export interface AIComponentReplaceScene {
    containerIds: string[]
    id: number
    nodeIds: string[][]
    version: number
}
export interface AIComponentReplaceSnapshot {
    docId: string
    id: number
    name: string
    nodeId: string
    nodeJsonDataResourceId: string
    nodeProtoDataResourceId: string
    thumbnailResourceId: string
    type: string
}

export enum AiScene {
    ToolBox = 'TOOLBOX',
    DesignSystem = 'DESIGN_SYSTEM',
    LAB = 'LAB',
}

export interface UserAiSceneCreditsVO {
    aiScene: AiScene
    userCredits: number // 剩余次数
    limitation: number // 该场景上限
}

export interface UserAiCreditsVO {
    sceneCredits: {
        TOOLBOX: UserAiSceneCreditsVO
        DESIGN_SYSTEM: UserAiSceneCreditsVO
        LAB: UserAiSceneCreditsVO
    }
}
