export function blobToDataURL(blob: Blob): Promise<string | null> {
    return new Promise((resolve, reject) => {
        const file = new FileReader()
        const controller = new AbortController()
        const { signal } = controller
        file.addEventListener(
            'load',
            () => {
                const result = file.result
                if (typeof result === 'string' || result === null) {
                    resolve(result)
                } else {
                    reject(new Error('unreachable code: get array buffer rather then data url'))
                }
            },
            { signal }
        )
        file.addEventListener(
            'error',
            () => {
                reject(file.error)
            },
            { signal }
        )
        file.readAsDataURL(blob)
    })
}

export function base64ToBlob(rawData?: string | null): Blob | null {
    if (!rawData) {
        return null
    }

    const buffer = Buffer.from(rawData, 'base64')
    return new Blob([buffer], {
        type: 'application/octet-stream',
    })
}
