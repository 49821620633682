/* eslint-disable no-restricted-imports */
import { useAppContext, useCanvasCursor } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { useAiMagicCommands } from './commands'
import { logs } from './logs'

import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { EditorMode, Viewport } from '../../../../document/node/node'

import { featureSwitchManager } from '../../../../kernel/switch/core'
import { SandBoxManager } from '../../../../sandbox'
import { useEventWheel } from '../../comment/comment-service/use-event-wheel'
import { AIMagicIds } from './ai-magic-ids'
import { AIMagicCompCandidate } from './comp-candidate'
import { MagicIcon, TextIcon } from './icons'
import styles from './index.module.less'
import { AIMagicTextCandidate } from './text-candidate'
import { AIMagicMode, AIMagicSwitchProps, DragType, Point } from './type'
import { useHoveringSwitch } from './use-hovering-switch'
import { getLocalScreenPosition, pointsToRect } from './utils'

function useDrag(targetDom: RefObject<HTMLDivElement | null>) {
    const [startPoint, setStartPoint] = useState<Point>()
    const [endPoint, setEndPoint] = useState<Point>()
    const [isDragging, setIsDragging] = useState(false)

    useEffect(() => {
        // 只接受在画布区内部的鼠标事件
        const valid = (e: MouseEvent) => {
            if (!targetDom.current) return false
            const localPoint = getLocalScreenPosition(targetDom.current, {
                x: e.clientX,
                y: e.clientY,
            })
            return localPoint.x > 0 && localPoint.y > 0
        }

        const onMouseDown = (e: MouseEvent) => {
            if (!valid(e)) return
            setStartPoint({ x: e.clientX, y: e.clientY })
            setEndPoint({ x: e.clientX, y: e.clientY })
            setIsDragging(true)
        }
        const onMouseMove = (e: MouseEvent) => {
            if (!isDragging || !valid(e)) return
            setEndPoint({ x: e.clientX, y: e.clientY })
        }
        const onMouseUp = () => {
            setIsDragging(false)
            // unBindEvents()
        }

        function bindEvents() {
            window.addEventListener('pointerdown', onMouseDown)
            window.addEventListener('pointermove', onMouseMove)
            window.addEventListener('pointerup', onMouseUp)
        }

        function unBindEvents() {
            window.removeEventListener('pointerdown', onMouseDown)
            window.removeEventListener('pointermove', onMouseMove)
            window.removeEventListener('pointerup', onMouseUp)
        }

        bindEvents()

        return () => unBindEvents()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDragging])

    return {
        startPoint,
        endPoint,
        valid: !!startPoint && !!endPoint,
        dx: (endPoint?.x ?? 0) - (startPoint?.x ?? 0),
        dy: (endPoint?.y ?? 0) - (startPoint?.y ?? 0),
        isDragging,
    }
}

export type AIMagicState = ReturnType<typeof useAiMagicState>

export function useAiMagicState(defaultMode: AIMagicMode = 'comp', viewport: Viewport) {
    const domRef = useRef<HTMLDivElement>(null)
    const rectRef = useRef<HTMLDivElement>(null)
    const sizeTipRef = useRef<HTMLDivElement>(null) // 尺寸展示元素，需要与矩形框对齐

    const [dragType, setDragType] = useState<DragType>('rb')

    // 魔法框输入模式
    const [magicMode, _setMagicMode] = useState<AIMagicMode>(defaultMode)

    function setMagicMode(mode: AIMagicMode) {
        _setMagicMode(mode)
        if (mode === 'comp') {
            logs.AiMagicBound.switchToComponent()
        } else if (mode === 'text') {
            logs.AiMagicBound.switchToText()
        }
    }

    // 快捷键进入
    useEffect(() => {
        const mode = defaultMode
        if (mode === 'comp') {
            logs.AiMagicBound.componentToolFocus({ interaction_type: 'hotkey' })
        } else if (mode === 'text') {
            logs.AiMagicBound.textToolFocus({ interaction_type: 'hotkey' })
        }
    }, [defaultMode])

    // 魔法框是否展示切换按钮
    const [showSwitch, setShowSwitch] = useState(false)
    // 组件模式和文本模式通用的文本输入框
    const [textInput, setTextInput] = useState('')

    const dragState = useDrag(domRef)
    const [dragStage, setDragStage] = useState<'init' | 'create' | 'resize'>('init')
    const [worldStartPoint, setWorldStartPoint] = useState<Point>({ x: 0, y: 0 })
    const [worldEndPoint, setWorldEndPoint] = useState<Point>({ x: 0, y: 0 })
    const showCandidate = dragStage === 'resize' && dragType === 'none'

    const [initialScale, setInitialScale] = useState(1)

    // 用于存储拖拽矩形时的信息
    const initialDragCenterState = useRef({
        worldStartPoint,
        worldEndPoint,
    })

    const onControllerDragStart = (t: DragType) => {
        setDragType(t)
        if (t === 'all') {
            initialDragCenterState.current = {
                worldStartPoint: { ...worldStartPoint },
                worldEndPoint: { ...worldEndPoint },
            }
        }
    }

    const screen2world = useCallback(
        (p: Point) => {
            const s = viewport!.zoom
            return {
                x: p.x / s + viewport!.x,
                y: p.y / s + viewport!.y,
            }
        },
        [viewport]
    )

    const world2screen = useCallback(
        (p: Point) => {
            const s = viewport!.zoom
            return {
                x: (p.x - viewport!.x) * s,
                y: (p.y - viewport!.y) * s,
            }
        },
        [viewport]
    )

    const screenStartPoint = world2screen(worldStartPoint)
    const screenEndPoint = world2screen(worldEndPoint)
    const screenRect = pointsToRect(screenStartPoint, screenEndPoint)
    // const screenRect: Rect = { x: 100, y: 100, width: 200, height: 200 }
    const showRect = screenRect.width * screenRect.height > 0
    const worldRect = pointsToRect(worldStartPoint, worldEndPoint)
    const textScale = (viewport?.zoom ?? initialScale) / initialScale

    // 转发鼠标事件
    const { dispatchWheelToCanvas } = useEventWheel()
    useEffect(() => {
        if (!rectRef.current) return
        const destroy = dispatchWheelToCanvas(rectRef.current)

        return () => destroy()
    }, [dispatchWheelToCanvas, showRect])

    // 同步光标状态到矩形框
    function onHoveringController(hoveringController: DragType) {
        commands.changeCursor(dragStage !== 'resize' ? dragStage : hoveringController)
    }

    // 处理鼠标指针
    const commands = useAiMagicCommands()
    useEffect(() => {
        commands.changeCursor(dragStage !== 'resize' ? dragStage : dragType)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dragStage, dragType, screenRect])

    useEffect(() => {
        if (dragState.isDragging) {
            // 开始拖动
            if (dragStage === 'init') {
                setDragStage('create')
            }
        } else if (dragStage !== 'init') {
            if (dragStage === 'create') {
                setInitialScale(viewport?.zoom ?? 1) // 记录当前的缩放状态
                setTimeout(() => {
                    setDragStage('resize')
                })
            }

            // 结束拖动
            setDragType('none')

            // 绘制完成打点
            if (magicMode === 'comp') {
                logs.AiMagicBound.componentDrawSuccess()
            } else if (magicMode === 'text') {
                logs.AiMagicBound.textDrawSuccess()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dragState.isDragging])

    useEffect(() => {
        if (!dragState.valid || !domRef.current || dragType === 'none') return

        // 获取世界坐标
        const { startPoint, endPoint } = dragState
        const localScreenStartPoint = getLocalScreenPosition(domRef.current, startPoint!)
        const localScreenEndPoint = getLocalScreenPosition(domRef.current, endPoint!)
        const worldDragState = {
            startPoint: screen2world(localScreenStartPoint),
            endPoint: screen2world(localScreenEndPoint),
        }

        if (dragStage === 'create') {
            // 如果是在新建 prompt 输入框
            setWorldStartPoint(worldDragState.startPoint)
            setWorldEndPoint(worldDragState.endPoint)
        } else if (dragStage === 'resize') {
            // 如果是在调整 prompt 输入框大小
            let nextStartPoint = worldStartPoint
            let nextEndPoint = worldEndPoint
            if (dragType === 'rb') {
                nextEndPoint = worldDragState.endPoint
            } else if (dragType === 'lt') {
                nextStartPoint = worldDragState.endPoint
            } else if (dragType === 'rt') {
                nextStartPoint.y = worldDragState.endPoint.y
                nextEndPoint.x = worldDragState.endPoint.x
            } else if (dragType === 'lb') {
                nextStartPoint.x = worldDragState.endPoint.x
                nextEndPoint.y = worldDragState.endPoint.y
            } else if (dragType === 'l') {
                nextStartPoint.x = worldDragState.endPoint.x
            } else if (dragType === 'r') {
                nextEndPoint.x = worldDragState.endPoint.x
            } else if (dragType === 't') {
                nextStartPoint.y = worldDragState.endPoint.y
            } else if (dragType === 'b') {
                nextEndPoint.y = worldDragState.endPoint.y
            } else if (dragType === 'all') {
                const dx = worldDragState.endPoint.x - worldDragState.startPoint.x
                const dy = worldDragState.endPoint.y - worldDragState.startPoint.y
                nextStartPoint.x = initialDragCenterState.current.worldStartPoint.x + dx
                nextStartPoint.y = initialDragCenterState.current.worldStartPoint.y + dy
                nextEndPoint.x = initialDragCenterState.current.worldEndPoint.x + dx
                nextEndPoint.y = initialDragCenterState.current.worldEndPoint.y + dy
            }
            setWorldStartPoint(nextStartPoint)
            setWorldEndPoint(nextEndPoint)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dragStage, dragState.dx, dragState.dy])

    useEffect(() => {
        // 检查是否需要禁用
        const onClick = (e: MouseEvent) => {
            let dom = e.target as HTMLElement | null
            let clickCopilot = false

            while (dom && dom !== document.body) {
                clickCopilot ||= dom.id === AIMagicIds.main
                dom = dom.parentElement
            }

            if (!clickCopilot && dragStage === 'resize' && !dragState.isDragging) {
                commands.exitCopilot()
                // 点击其他地方，退出魔法框
                if (magicMode === 'comp') {
                    logs.AiMagicBound.componentQuit()
                } else if (magicMode === 'text') {
                    logs.AiMagicBound.textQuit()
                }
            }
        }

        window.addEventListener('click', onClick)
        return () => window.removeEventListener('click', onClick)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dragStage, dragState.isDragging, magicMode])

    // 计算候选列表的位置
    const candidatePosition = useMemo(() => {
        if (!viewport) {
            return { x: 0, y: 0 }
        }

        const y = screenRect.y
        let x = screenRect.x + screenRect.width + 16
        const candidateWidth = 400

        if (x + candidateWidth > viewport.width) {
            x = screenRect.x - 16 - candidateWidth
        }

        return { x, y }
    }, [viewport, screenRect])

    // 尺寸指示框居中对齐
    const sizeTipOffset = useMemo(() => {
        if (!sizeTipRef.current) return 0
        const parentWidth = screenRect.width
        const selfWidth = sizeTipRef.current.clientWidth
        const offset = (parentWidth - selfWidth) / 2
        return offset
    }, [screenRect])

    return {
        magicMode,
        setMagicMode,

        // 切换框状态
        showSwitch,
        setShowSwitch,

        // 通用文本输入状态，用于共享两种模式的文本输入内容
        textInput,
        setTextInput,

        showRect,
        domRef,
        rectRef,
        sizeTipRef,
        sizeTipOffset,
        screenRect,
        screenStartPoint,
        screenEndPoint,
        textScale,
        worldRect,
        showCandidate,
        candidatePosition,
        onControllerDragStart,
        dragStage,
        isDragging: dragState.isDragging,
        onHoveringController,
    }
}

const DRAG_AREA = 4
const DRAG_AREAS = [
    {
        type: 'all',
        style: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
        },
    },
    {
        type: 'l',
        style: {
            position: 'absolute',
            left: -DRAG_AREA,
            top: 0,
            width: DRAG_AREA * 2,
            height: '100%',
        },
    },
    {
        type: 't',
        style: {
            position: 'absolute',
            left: 0,
            top: -DRAG_AREA,
            width: '100%',
            height: DRAG_AREA * 2,
        },
    },
    {
        type: 'r',
        style: {
            position: 'absolute',
            right: -DRAG_AREA,
            top: 0,
            width: DRAG_AREA * 2,
            height: '100%',
        },
    },
    {
        type: 'b',
        style: {
            position: 'absolute',
            right: 0,
            bottom: -DRAG_AREA,
            width: '100%',
            height: DRAG_AREA * 2,
        },
    },
    {
        type: 'lt',
        style: {
            position: 'absolute',
            top: -DRAG_AREA,
            left: -DRAG_AREA,
            width: DRAG_AREA * 2,
            height: DRAG_AREA * 2,
        },
    },
    {
        type: 'rt',
        style: {
            position: 'absolute',
            top: -DRAG_AREA,
            right: -DRAG_AREA,
            width: DRAG_AREA * 2,
            height: DRAG_AREA * 2,
        },
    },
    {
        type: 'rb',
        style: {
            position: 'absolute',
            bottom: -DRAG_AREA,
            right: -DRAG_AREA,
            width: DRAG_AREA * 2,
            height: DRAG_AREA * 2,
        },
    },
    {
        type: 'lb',
        style: {
            position: 'absolute',
            left: -DRAG_AREA,
            bottom: -DRAG_AREA,
            width: DRAG_AREA * 2,
            height: DRAG_AREA * 2,
        },
    },
] as const

export function AIMagicRect(props: { state: AIMagicState }) {
    const state = props.state
    const cursorManager = useCanvasCursor()
    useEffect(() => {
        if (state.rectRef.current) {
            cursorManager.attachCustomTarget(state.rectRef.current, false)
        }

        return () => cursorManager.detachCustomTarget()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div
            className={styles['ai-magic-content']}
            style={{
                position: 'absolute',
                left: state.screenRect.x,
                top: state.screenRect.y,
            }}
            data-testid={AIMagicIds.rect}
        >
            <div
                className={styles['ai-magic-rect']}
                ref={state.rectRef}
                data-testid={AIMagicIds.rectInner}
                style={{
                    position: 'relative',
                    width: state.screenRect.width,
                    height: state.screenRect.height,
                    pointerEvents: state.isDragging ? 'none' : 'all',
                }}
            >
                {DRAG_AREAS.map((dragArea, index) => (
                    <div
                        key={index}
                        className={styles[`ai-magic-${dragArea.type}`]}
                        style={dragArea.style}
                        onPointerDown={() => {
                            state.onControllerDragStart(dragArea.type)
                        }}
                        onPointerEnter={() => state.onHoveringController(dragArea.type)}
                        onPointerOut={() => state.onHoveringController('none')}
                        data-testid={AIMagicIds.rectDrag(dragArea.type)}
                    ></div>
                ))}
            </div>

            <div
                className={styles['ai-magic-size-tip']}
                ref={state.sizeTipRef}
                style={{
                    left: `${state.sizeTipOffset}px`,
                }}
            >
                {state.worldRect.width.toFixed(0)} × {state.worldRect.height.toFixed(0)}
            </div>
        </div>
    )
}

export function AIMagicSwitch(props: AIMagicSwitchProps) {
    const state = useHoveringSwitch(props)

    return (
        <div
            className={styles.switch}
            onMouseOver={state.onMouseInSwitchArea}
            onMouseOut={state.onMouseOutSwitchArea}
            data-testid={AIMagicIds.switch.main}
        >
            <div className={styles['switch-container']}>
                <div
                    className={`${styles['switch-btn']} ${props.magicMode === 'comp' && styles['switch-btn-active']}`}
                    onClick={() => props.changeMagicMode('comp')}
                    data-testid={AIMagicIds.switch.comp}
                >
                    <MagicIcon />
                </div>
                <div className={styles['switch-divider']}></div>
                <div
                    className={`${styles['switch-btn']} ${props.magicMode === 'text' && styles['switch-btn-active']}`}
                    onClick={() => props.changeMagicMode('text')}
                    data-testid={AIMagicIds.switch.text}
                >
                    <TextIcon />
                </div>
            </div>
        </div>
    )
}

export function AIMagicContent(props: { defaultMode: AIMagicMode; viewport: Viewport }) {
    const state = useAiMagicState(props.defaultMode, props.viewport)

    return (
        <div data-testid={AIMagicIds.main} className={styles['ai-magic']} id={AIMagicIds.main} ref={state.domRef}>
            {state.showRect && (
                <>
                    <AIMagicRect state={state} />
                    <div
                        className={styles.prompt}
                        data-testid={AIMagicIds.prompt.main}
                        style={{
                            position: 'absolute',
                            left: state.candidatePosition.x,
                            top: state.candidatePosition.y,
                            display: state.showCandidate ? 'block' : 'none',
                        }}
                    >
                        {state.showSwitch && (
                            <AIMagicSwitch
                                magicMode={state.magicMode}
                                changeMagicMode={state.setMagicMode}
                                setShowSwitch={state.setShowSwitch}
                            />
                        )}

                        {state.magicMode === 'comp' && <AIMagicCompCandidate magicState={state} />}
                        {state.magicMode === 'text' && <AIMagicTextCandidate magicState={state} />}
                    </div>
                </>
            )}
        </div>
    )
}

export function AIMagicContentWrapper(props: {
    editorMode?: EditorMode
    enableMagicCopilot: boolean
    viewport: Viewport
}) {
    const show = props.editorMode === EditorMode.EM_Copilot && props.enableMagicCopilot
    const lastOptionM = useRef(false)
    const commands = useAiMagicCommands()

    useEffect(() => {
        const isCopilot = props.editorMode === EditorMode.EM_Copilot

        // option m 的临时解决方案
        const onKeyDown = (e: KeyboardEvent) => {
            lastOptionM.current = e.code === 'KeyM' && e.altKey

            if (e.code === 'Escape' && isCopilot) {
                // esc 退出
                logs.AiMagicBound.componentQuit()
                commands.exitCopilot()
            }
        }
        document.addEventListener('keydown', onKeyDown)

        return () => document.removeEventListener('keydown', onKeyDown)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.editorMode])

    if (!show) return null

    if (featureSwitchManager.isEnabled('sandbox')) {
        SandBoxManager.initSharedSandBox()
    }

    return <AIMagicContent defaultMode={lastOptionM.current ? 'text' : 'comp'} viewport={props.viewport} />
}

//ai 魔法框需求
export function AIMagic() {
    const editorMode = useViewState('editorModeState')
    const aiService = useAppContext().aiService
    const enableMagicCopilot = aiService.useZustandStore.use.enableMagicCopilot()
    const viewport = useViewState('currentViewport')

    if (!viewport) return null
    return (
        <AIMagicContentWrapper
            editorMode={editorMode?.editorMode}
            enableMagicCopilot={enableMagicCopilot}
            viewport={viewport}
        ></AIMagicContentWrapper>
    )
}
