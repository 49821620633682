import { useEffect } from 'react'
import { GetSupportSchemaVersionByOrgId } from '../kernel/request/schema'
import { WK } from '../window'

export function SchemaVersionUpdater() {
    useEffect(() => {
        const fetchSchemaVersion = async () => {
            const { schemaVersion } = await new GetSupportSchemaVersionByOrgId(WK.orgId ?? '-1').start()
            WK.initialDocSchemaVersion = schemaVersion
        }

        fetchSchemaVersion()

        const intervalId = setInterval(fetchSchemaVersion, 60000)

        return () => clearInterval(intervalId)
    }, [])

    return null
}
