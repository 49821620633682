import { translation } from './use-history-toast.translation'
/* eslint-disable no-restricted-imports */
import { useCallback, useEffect, useRef } from 'react'
import { useUnmount } from 'react-use'
import { WKToast } from '../../../../../../ui-lib/src'
import { useHistoryService } from '../../../../main/app-context'
import { ToastFail } from '../history-service/history-service'

export function useHistoryToast() {
    const historyService = useHistoryService()
    const toastFailList = historyService.useZustandStore.use.toastFailList()
    const aliveToast = useRef<Map<number, string>>(new Map())

    const createFailedToast = useCallback(
        (toastFailId: number, toastFail: ToastFail) => {
            if (aliveToast.current.has(toastFailId)) {
                return
            }
            const onClose = () => {
                WKToast.close(aliveToast.current.get(toastFailId) ?? '')
                aliveToast.current.delete(toastFailId)
                historyService.removeToastFailList(toastFailId)
            }
            const onRetry = () => {
                WKToast.close(aliveToast.current.get(toastFailId) ?? '')
                aliveToast.current.delete(toastFailId)
                historyService.retryToastFailList(toastFailId)
            }
            const toastId = WKToast.error(toastFail.title, {
                firstButton: { type: 'x', onClick: onClose },
                secondButton: { type: 'button', text: translation('Retry'), onClick: onRetry },
                duration: -1,
            })
            aliveToast.current.set(toastFailId, toastId)
        },
        [historyService]
    )

    useEffect(() => {
        for (const [id, value] of toastFailList.entries()) {
            createFailedToast(id, value)
        }
    }, [createFailedToast, toastFailList])

    useUnmount(() => {
        for (const toastIds of aliveToast.current.values()) {
            WKToast.close(toastIds)
        }
    })
}
