import classnames from 'classnames'
import { isNil } from 'lodash-es'
import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import { IconArrowDown12 } from '../../../svg-icon/12/controls'
import { ExplicitUndefined } from '../../../utils/explicit-undefined'
import { toMixed } from '../../../utils/mixed-value'
import { TriggerContainer, TriggerContainerProps } from './base-components/trigger-container'
import { TriggerFocus, TriggerFocusProps, TriggerFocusRef } from './base-components/trigger-focus'
import classes from './trigger-normal.module.less'
import { translation } from './trigger-normal.translation'
import { TriggerBaseProps, TriggerBaseRef } from './type'
import { useCommonTrigger } from './use-common-trigger'

export interface TriggerNormalProps extends TriggerBaseProps {
    // TriggerContainerProps
    classNameWhenOpen?: TriggerContainerProps['classNameWhenOpen']
    isOpenPick: TriggerContainerProps['isOpenPick']
    // 私有配置
    label?: React.ReactNode // 触发器展示的内容，基本是文字
    placeholder?: string
    icon?: React.ReactNode // 前置图标
    iconStyle?: React.CSSProperties
    arrow?: React.ReactNode // 后置图标
    arrowClassName?: string
    isSmallArrow?: boolean
    hoverMoveToRight?: boolean // hover时 箭头移到右侧
    ellipsis?: boolean // 内容超长时以【...】的形式展示
    isMixed?: boolean // 对应文本 【多个值】
    isDisabledStyle?: boolean // 可以通过这个参数使用disabled的样式而不传入disabled=true
    isHover?: boolean // hover 的样式
    height?: '28px' | '32px' // select 的高度
    error?: {
        show: boolean // 是否是错误状态
        message?: string // 底部展示关于 错误的描述性文字提示
    }
}

export interface TriggerNormalRef extends TriggerBaseRef {}

function _TriggerNormal(props: TriggerNormalProps, ref?: React.Ref<TriggerNormalRef>) {
    const {
        className,
        disabled,
        dataTestIds,
        openAction,
        closeAction,
        classNameWhenOpen,
        isOpenPick,
        label,
        placeholder = translation('PleaseSelect'),
        icon,
        iconStyle,
        arrow,
        arrowClassName,
        isSmallArrow,
        hoverMoveToRight,
        ellipsis = true,
        isMixed,
        isDisabledStyle,
        isHover,
        height = '28px',
        error,
        ...otherProps
    } = props
    const triggerRef = useRef<TriggerFocusRef>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const commonTrigger = useCommonTrigger({ isOpenPick, openAction, closeAction })

    const showPlaceholder = useMemo(() => {
        return !isMixed && (isNil(label) || label === '')
    }, [isMixed, label])

    useImperativeHandle(
        ref,
        () => ({
            getTriggerElement: () => triggerRef.current?.getContainer(),
            triggerRect: () => triggerRef.current?.getContainer().getBoundingClientRect()!,
            focus: () => triggerRef.current?.getFocusElement().focus(),
            getContainer: () => containerRef.current!,
        }),
        []
    )
    return (
        <TriggerContainer<ExplicitUndefined<TriggerContainerProps>>
            ref={containerRef}
            className={classnames(classes.template, [className], {
                [classes.disabled]: disabled,
                [classes.isError]: error?.show,
                [classes.isDisabledStyle]: isDisabledStyle,
                [classes.hoverMoveToRight]: hoverMoveToRight,
                [classes.ellipsis]: ellipsis,
                [classes.isHover]: isHover || (hoverMoveToRight && isOpenPick),
                [classes.placeholder]: showPlaceholder,
            })}
            disabled={disabled}
            dataTestIds={dataTestIds}
            classNameWhenOpen={classNameWhenOpen}
            isOpenPick={isOpenPick}
            {...otherProps}
        >
            <TriggerFocus<ExplicitUndefined<TriggerFocusProps>>
                ref={triggerRef}
                className={classes.trigger}
                dataTestIds={dataTestIds}
                disabled={disabled}
                onClick={commonTrigger.click}
                onMouseDown={commonTrigger.mousedown}
                onEnter={openAction}
                onBlankSpace={openAction}
                style={{ height }}
                outlineMode={undefined}
            >
                {icon ? (
                    <span className={classes.icon} style={iconStyle}>
                        {icon}
                    </span>
                ) : null}
                <div className={classes.label}>{isMixed ? toMixed() : showPlaceholder ? placeholder : label}</div>
                <span className={classnames(classes.arrow, { [classes.isSmallArrow]: isSmallArrow }, arrowClassName)}>
                    {arrow ?? <IconArrowDown12 />}
                </span>
            </TriggerFocus>
            {error?.show && error.message ? <div className={classes.errorMessage}>{error.message}</div> : null}
            {props.children}
        </TriggerContainer>
    )
}

export const TriggerNormal = forwardRef(_TriggerNormal)
