import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import { ToggleShowShortcutPanelWasmCall } from '@wukong/bridge-proto'
import { useCallback, useMemo, useState } from 'react'
import { useCommand } from '../../../../main/app-context'
import { DEFAULT_BOTTOM_BANNER_HEIGHT } from '../../../../main/layout/layout-default-value'
import classes from '../shortcut-guide.module.less'
import { TabContainer } from '../tab-container'
import { Tab, Tabs, TabsProps } from '../tabs/tabs'
import { copyTabCenter, selectionTabCenter, toolTabCenter, viewTabLeft, viewTabRight } from './data'

export interface ShortcutGuidePanelTab {
    key: string
    name: string
    component: JSX.Element
}

export function DevModeShortcutGuidePanel() {
    const command = useCommand()
    const tabs = useMemo((): TabsProps['tabs'] => {
        return [
            {
                key: '1',
                name: translation('Tools'),
                component: <TabContainer center={toolTabCenter} />,
            },
            {
                key: '2',
                name: translation('View'),
                component: <TabContainer left={viewTabLeft} right={viewTabRight} />,
            },
            {
                key: '3',
                name: translation('Copy'),
                component: <TabContainer center={copyTabCenter} />,
            },
            {
                key: '4',
                name: translation('Selection'),
                component: <TabContainer center={selectionTabCenter} />,
            },
        ]
    }, [])

    const [activeKey, setActiveKey] = useState<TabsProps['activeKey']>(tabs[0].key)

    const onClickClose = useCallback(() => {
        command.DEPRECATED_invokeBridge(ToggleShowShortcutPanelWasmCall)
    }, [command])

    const onSwitchTab = useCallback((tab: Tab) => {
        setActiveKey(tab.key)
    }, [])

    return (
        <Tabs
            className={classes.shortcutGuide}
            style={{ height: DEFAULT_BOTTOM_BANNER_HEIGHT }}
            tabs={tabs}
            activeKey={activeKey}
            onClickClose={onClickClose}
            onSwitchTab={onSwitchTab}
            dataTestId="dev-mode-shortcut-guide-panel"
        />
    )
}
