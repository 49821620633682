import { getTranslationValue } from '../../../../../../../../../util/src/i18n'

export const zhTranslation = {
    PropertyName: '属性名',
    PropertyValue: '属性值',
    createComponentProp: '创建组件属性',
    Visible: '展示',
    Hidden: '隐藏',
    CreateProp: '创建属性',
} as const

export const enTranslation = {
    PropertyName: 'Name',
    PropertyValue: 'Value',
    createComponentProp: 'Create component property',
    Visible: 'True',
    Hidden: 'False',
    CreateProp: 'Create property',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
