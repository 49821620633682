import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    SelectInvalidVariants: '选择这些变体',
    SelectInvalidInstances: '选择这些实例',
    InvalidNameOfSomeVariants: '有些变体存在无效命名，请检查命名结构是否为：属性=属性值。',
    InvalidNameOfThisVariant: '这个变体存在无效命名，请检查命名结构是否为：属性=属性值。',
    InvalidNameOfTheseVariants: '这些变体存在无效命名，请检查命名结构是否为：属性=属性值。',
    InvalidNameOfThisInstance: '这个实例存在无效命名，请检查它组件母版的命名结构是否为：属性=属性值。',
    InvalidNameOfTheseInstances: '这些实例存在无效命名，请检查它组件母版的命名结构是否为：属性=属性值。',
    SamePropValOfSomeVariants: '有些变体存在相同的属性值，修改这些变体的属性值来解决冲突。',
    SamePropValOfThisVariant: '这个变体的与其他变体的属性值冲突，修改这个变体的属性值来解决冲突。',
    SamePropValOfTheseVariants: '这些变体的属性值冲突，修改这些变体的属性值来解决冲突。',
    MissingPropValOfSomeVariants: '有些变体存在缺失的属性值，请修改属性值来解决冲突。',
    MissingPropValOfThisVariant: '这个变体存在缺失的属性值，请修改属性值来解决冲突。',
    MissingPropValOfTheseVariants: '这些变体存在缺失的属性值，请修改属性值来解决冲突。',
} as const

export const enTranslation = {
    SelectInvalidVariants: 'Select conflicting variants',
    SelectInvalidInstances: 'Select conflicting variants',
    InvalidNameOfSomeVariants: 'Some layers have invalid names. Prop=Value, Prop=Value, ...',
    InvalidNameOfThisVariant:
        'The name of this layer is invalid. Make sure all variable names follow this format: Prop=Value, Prop=Value, ...',
    InvalidNameOfTheseVariants:
        'These layers have invalid naming. Make sure all variable names follow this format: Prop=Value, Prop=Value, ...',
    InvalidNameOfThisInstance:
        'The name of this layer is invalid. Make sure all variable names follow this format: Prop=Value, Prop=Value, ...',
    InvalidNameOfTheseInstances:
        'These layers have invalid naming. Make sure all variable names follow this format: Prop=Value, Prop=Value, ...',
    SamePropValOfSomeVariants:
        'Some variants have the same property values applied. Change the applied values on these variables to resolve the issue.',
    SamePropValOfThisVariant:
        'The properties and values of this variant conflict with others. Change the applied values on this variable to resolve the issue.',
    SamePropValOfTheseVariants:
        'The properties and values of these variants conflict with each other. Change the applied values on these variables to resolve the issue.',
    MissingPropValOfSomeVariants: 'Some variants are missing properties.',
    MissingPropValOfThisVariant: 'This variant is missing properties.',
    MissingPropValOfTheseVariants: 'These variants are missing properties.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
