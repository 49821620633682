import constate from 'constate'
import { RadioGroupStateProps, useRadioGroupState } from '../common/use-radio-group-state'

export interface WKRadioButtonGroupContextProps extends RadioGroupStateProps {
    size: 'sm' | 'md'
}

const useRadioButtonGroup = (props: WKRadioButtonGroupContextProps) => {
    const { size } = props
    const radioGroupState = useRadioGroupState(props)

    return {
        size,
        ...radioGroupState,
    }
}

export const [RadioButtonGroupProvider, useRadioButtonGroupContext] = constate(useRadioButtonGroup)
