/* eslint-disable no-restricted-imports */
import { WKToast } from '../../../../../ui-lib/src'
import { domLocation, RouteToken } from '../../../../../util/src'
import {
    CreateWorkspaceRequest,
    UpdateWorkspaceRequest,
    WorkspaceInOrgManageVO,
} from '../../../kernel/interface/workspace'
import {
    CreateWorkspace,
    DeleteWorkspace,
    GetOrganizationWorkspaces,
    UpdateBatchWorkspaceVisibility,
    UpdateWorkspace,
} from '../../../kernel/request/workspace'
import { featureSwitchManager } from '../../../kernel/switch'
import { SpaceNotifyFlow } from './notify-flow'
import { translation } from './organization-manage-workspace-state.translation'
import { getSpaceStore, OrganizationManageWorkspaceStore, setSpaceStore, SpaceStoreType } from './types'
export const generateOrganizationManageWorkspaceState = (
    set: setSpaceStore,
    get: getSpaceStore
): OrganizationManageWorkspaceStore => {
    return {
        tableData: [],
        tableLoading: false,
        tableQueryString: '',
        _fetchTableData: async (queryString?: string) => {
            if (!featureSwitchManager.isEnabled('organization-plus')) {
                return
            }
            set((state) => {
                state.organizationManageWorkspaceStore.tableLoading = true
            })
            const organization = get().organizationStore.organization
            const response = await new GetOrganizationWorkspaces(organization.id, queryString).start().catch((_) => {
                return []
            })
            const data: WorkspaceInOrgManageVO[] = []
            response.sort((a, b) => a.name.localeCompare(b.name))
            for (const v of response) {
                if (v.workspaceId === '-1') {
                    data.unshift(v)
                } else {
                    data.push(v)
                }
            }
            set((state) => {
                state.organizationManageWorkspaceStore.tableData = data
                state.organizationManageWorkspaceStore.tableLoading = false
            })
        },
        initTableData: () => {
            set((state) => {
                state.organizationManageWorkspaceStore.tableQueryString = ''
            })
            get().organizationManageWorkspaceStore._fetchTableData()
        },
        updateTableData: () => {
            get().organizationManageWorkspaceStore._fetchTableData(
                get().organizationManageWorkspaceStore.tableQueryString
            )
        },
        updateTableQueryString: (queryString: string) => {
            set((state) => {
                state.organizationManageWorkspaceStore.tableQueryString = queryString
            })
            get().organizationManageWorkspaceStore._fetchTableData(queryString)
        },
        createWorkspace: async (workspace: CreateWorkspaceRequest) => {
            new CreateWorkspace(workspace).start()
        },
        updateWorkspace: async (workspace: UpdateWorkspaceRequest) => {
            new UpdateWorkspace(workspace).start()
        },
        deleteWorkspace: async (workspaceIds: string[]) => {
            new DeleteWorkspace(workspaceIds).start()
        },
        updateWorkspaceVisibility: async (workspaceIds: string[], visibility: boolean) => {
            const data = get().organizationManageWorkspaceStore.tableData
            set((state) => {
                state.organizationManageWorkspaceStore.tableData = data.map((v) => {
                    if (workspaceIds.includes(v.workspaceId)) {
                        return { ...v, visibility }
                    }
                    return v
                })
            })
            new UpdateBatchWorkspaceVisibility({ workspaceIds, visibility }).start().then(() => {
                WKToast.show(
                    translation('WorkspaceVisibilityChanged', {
                        count: workspaceIds.length.toString(),
                        visibility: visibility ? translation('visible') : translation('hidden'),
                        s: workspaceIds.length > 1 ? 's' : '',
                        hasHave: workspaceIds.length > 1 ? 'have' : 'has',
                    })
                )
            })
        },
    }
}

const isInOrganizationWorkspacePage = () => domLocation().pathname.includes(`${RouteToken.Admin}/workspaces`)

export const syncOrganizationManageWorkspaceState = (store: SpaceStoreType, notifyFlow: SpaceNotifyFlow) => {
    notifyFlow.addCreateWorkspaceCallback(() => {
        if (isInOrganizationWorkspacePage()) {
            store.getState().organizationManageWorkspaceStore.updateTableData()
        }
    })
    notifyFlow.addRemoveWorkspaceCallback(() => {
        if (isInOrganizationWorkspacePage()) {
            store.getState().organizationManageWorkspaceStore.updateTableData()
        }
    })
    notifyFlow.addUpdateWorkspaceCallback(() => {
        if (isInOrganizationWorkspacePage()) {
            store.getState().organizationManageWorkspaceStore.updateTableData()
        }
    })
}
