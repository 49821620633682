import { CANVAS_ID } from '../../../../../main/canvas/canvas'

export function useCanvasRect() {
    const canvas = document.getElementById(CANVAS_ID)
    const rect = canvas?.getBoundingClientRect()

    return {
        left: rect?.left ?? 0,
        top: rect?.top ?? 0,
        width: rect?.width ?? 0,
        height: rect?.height ?? 0,
    }
}
