import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    ImageSizeNotMatch: '选择的图片不符合尺寸要求（128×128 px）',
    ImageSizeTip: '128×128 像素的图片',
} as const

export const enTranslation = {
    ImageSizeNotMatch: 'Selected image must be 128×128 px.',
    ImageSizeTip: '128×128 px',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
