import classnames from 'classnames'
import { isNil, omit } from 'lodash-es'
import type { HTMLAttributes } from 'react'
import * as React from 'react'
import style from './single-grid.module.less'

interface SingleGridProps extends HTMLAttributes<HTMLDivElement> {
    testId?: string
}

function _SingleGrid(props: SingleGridProps, ref: React.Ref<HTMLDivElement>) {
    const { className } = props
    const otherProps = omit(props, 'className', 'testId')
    return (
        <div ref={ref} className={classnames(style.grid, [className])} {...otherProps} data-testid={props.testId}>
            {props.children}
        </div>
    )
}

interface ItemProps extends HTMLAttributes<HTMLDivElement> {
    start?: number
    span?: number
    horizontalCenter?: boolean
}

function _Item(props: ItemProps, ref: any) {
    const { start, span, className, style: customStyle, horizontalCenter } = props
    const otherProps = omit(props, 'className', 'style', 'start', 'span', 'horizontalCenter')
    const innerStyle: any = { ...customStyle }
    if (!isNil(start)) {
        innerStyle.gridColumnStart = start
    }
    if (!isNil(span)) {
        innerStyle.gridColumnEnd = `span ${span}`
    }
    if (horizontalCenter) {
        innerStyle.justifyContent = 'center'
    }
    return (
        <div ref={ref} className={classnames(style.grid_item, [className])} {...otherProps} style={innerStyle}>
            {props.children}
        </div>
    )
}

const ItemRef = React.forwardRef<HTMLDivElement, ItemProps>(_Item)
const SingleGridRef = React.forwardRef<HTMLDivElement, SingleGridProps>(_SingleGrid)
const SingleGrid: typeof SingleGridRef & { Item: typeof ItemRef } = Object.assign(SingleGridRef, { Item: ItemRef })

export { SingleGrid }
