import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useMount, useUpdateEffect } from 'react-use'
import { ExplicitUndefined } from '../../../../utils/explicit-undefined'
import { CaptureKeyboard, CaptureKeyboardProps, CaptureKeyboardRef } from '../../capture-keyboard/capture-keyboard'
import { PopUpScroll, PopUpScrollProps, PopUpScrollRef } from '../../pop-up-scroll/pop-up-scroll'
import { Rect } from '../../type'
import { PickBaseProps, pickComponentNames } from '../type'
import { useComment } from '../use-comment'
import { RenderStructNode, RenderStructNodeProps } from './render-struct-node'
import { CustomNode, DataNode, StructNode } from './type'
import {
    getKeyWhenMove2Bottom,
    getKeyWhenMove2Left,
    getKeyWhenMove2Right,
    getKeyWhenMove2Top,
    getSameLevelStructNodesByStructNodeKey,
    hasChildWithStructNode,
    isPointInQuadrilateral,
    transformDateNodes2StructNodes,
} from './utils'

type PickPopUpScrollProps = Omit<
    PopUpScrollProps,
    | 'visibleClassName'
    | 'visibleTrigger'
    | 'onClickMask'
    | 'overMajorClassName'
    | 'overMinorClassName'
    | 'children'
    | 'dataTestIds'
    | 'isNotCoverTriggerRect'
    | 'className'
>
export interface MultiLevelProps<T extends CustomNode> extends PickBaseProps, PickPopUpScrollProps {
    dataTestIds?: PopUpScrollProps['dataTestIds'] & CaptureKeyboardProps['dataTestIds']
    items: DataNode<T>[]
    onChange?: (item: DataNode<T>, options: { e: React.MouseEvent | React.KeyboardEvent }) => void // 在叶子节点上点击或者回车时调用
    renderItemPrev?: RenderStructNodeProps<T>['renderItemPrev'] // 默认使用这个绘制分割线
    renderItem: RenderStructNodeProps<T>['renderItem'] // 默认使用CustomItem做为结构主体
    renderItemNext?: RenderStructNodeProps<T>['renderItemNext'] // 默认使用这个绘制分割线
}

export interface MultiLevelRef {
    open: () => void
    close: () => void
}

function _MultiLevel<T extends CustomNode, K extends MultiLevelProps<T> = MultiLevelProps<T>>(
    props: K,
    ref?: React.Ref<MultiLevelRef>
) {
    const {
        items,
        closeByESC,
        openStateToBeFalse,
        openStateToBeTrue,
        onChange,
        onOpen,
        onClose,
        getPortalElement,
        onKeyboard,
    } = props
    const [isOpenState, setIsOpenState] = useState(false)
    const [preselectKey, setPreselectKey] = useState<string>() // 预选、高亮、提交的依据
    const [interactiveKey, setInteractiveKey] = useState<string>() // 层级打开的依据
    const [interactiveAction, setInteractiveAction] = useState<'mouse' | 'keyboard'>() // 层级打开的依据 - 交互方式
    const captureKeyboardRef = useRef<CaptureKeyboardRef>(null)
    const mousePositionRef = useRef({ x: 0, y: 0 })
    const popUpScrollRef = useRef<PopUpScrollRef>(null)
    const skipItemRef = useRef<{
        structNodeKey?: string
        itemRect?: Rect
        itemChildrenRect?: Rect
        clientX: number
        clientY: number
        speed: number
        timeStamp: number
    }>({
        clientX: 0,
        clientY: 0,
        speed: 0,
        timeStamp: 0,
    })

    const { addOverflowHiddenToBody, resetOverflowStatusToBody } = useComment()

    const visibleClassName = useMemo(() => {
        return `visibleClassName_${Date.now()}`
    }, [])

    const structNodes = useMemo(() => {
        return transformDateNodes2StructNodes(items)
    }, [items])

    const getSameLevelDataList = useCallback(() => {
        return structNodes.map((structNode) => structNode.data)
    }, [structNodes])

    const getChildPortalElement = useCallback(() => {
        return captureKeyboardRef.current?.getContainer()
    }, [])

    const open = useCallback(() => {
        if (isOpenState || openStateToBeTrue?.()) {
            return
        }
        setInteractiveKey('0x0')
        addOverflowHiddenToBody()
        setIsOpenState(true)
        onOpen?.()
    }, [addOverflowHiddenToBody, isOpenState, onOpen, openStateToBeTrue])

    const close = useCallback(() => {
        if (!isOpenState || openStateToBeFalse?.()) {
            return
        }
        setInteractiveKey(undefined)
        setPreselectKey(undefined)
        resetOverflowStatusToBody()
        setIsOpenState(false)
        onClose?.()
    }, [isOpenState, onClose, openStateToBeFalse, resetOverflowStatusToBody])

    const updateOpenStateFromOutside = useCallback(() => {
        if (props.isOpenState === false) {
            close()
        } else if (props.isOpenState === true) {
            open()
        }
    }, [close, open, props.isOpenState])

    useMount(updateOpenStateFromOutside)

    useUpdateEffect(updateOpenStateFromOutside, [updateOpenStateFromOutside])

    const needSkipEnterLeaveEvent = useCallback(() => {
        const { structNodeKey, itemRect, itemChildrenRect, clientX, clientY, speed } = skipItemRef.current
        if (!itemChildrenRect || !itemRect || !structNodeKey || structNodeKey !== preselectKey || speed < 100) {
            return false
        }
        const isCenter =
            (itemRect.left <= clientX && clientX <= itemChildrenRect.left) ||
            (itemRect.right >= clientX && clientX >= itemChildrenRect.right)
        if (!isCenter) {
            return false
        }
        if (
            itemChildrenRect.left >= itemRect.left &&
            isPointInQuadrilateral(
                { x: itemChildrenRect.left, y: itemChildrenRect.top },
                { x: itemChildrenRect.left, y: itemChildrenRect.bottom },
                { x: itemRect.left, y: itemRect.top },
                { x: itemRect.left, y: itemRect.bottom },
                { x: clientX, y: clientY }
            )
        ) {
            return true
        }
        if (
            itemChildrenRect.right <= itemRect.right &&
            isPointInQuadrilateral(
                { x: itemChildrenRect.right, y: itemChildrenRect.top },
                { x: itemChildrenRect.right, y: itemChildrenRect.bottom },
                { x: itemRect.right, y: itemRect.top },
                { x: itemRect.right, y: itemRect.bottom },
                { x: clientX, y: clientY }
            )
        ) {
            return true
        }
        return false
    }, [preselectKey])

    const onFocusLeave = useCallback(() => {
        close()
    }, [close])

    const tryRestoreFocusElement = useCallback(() => {
        if (!captureKeyboardRef.current || captureKeyboardRef.current.getContainer().contains(document.activeElement)) {
            return
        }
        captureKeyboardRef.current.focus()
    }, [])

    const mousemoveMultiLevel = useCallback(
        (e: React.MouseEvent) => {
            const { clientX, clientY, timeStamp } = e
            mousePositionRef.current = { x: e.clientX, y: e.clientY }
            const deltaX = clientX - skipItemRef.current.clientX
            const deltaDistance = Math.abs(deltaX)
            if (deltaDistance !== 0) {
                const deltaTimeStamp = (e.timeStamp - skipItemRef.current.timeStamp) / 1000
                skipItemRef.current.speed = deltaTimeStamp ? deltaDistance / deltaTimeStamp : Infinity
                skipItemRef.current.clientX = clientX
                skipItemRef.current.clientY = clientY
                skipItemRef.current.timeStamp = timeStamp
            }
            tryRestoreFocusElement()
        },
        [tryRestoreFocusElement]
    )

    const skipEnterLeaveFromScroll = useCallback(
        (e: React.MouseEvent) => {
            // 处理的情况是，鼠标放到列表上，通过键盘滚动。不跳过的效果是选择的节点会跳到鼠标的位置
            return (
                interactiveAction === 'keyboard' &&
                e.clientX === mousePositionRef.current.x &&
                e.clientY === mousePositionRef.current.y
            )
        },
        [interactiveAction]
    )

    const mousemoveStructNode = useCallback(
        (structNode: StructNode<T>) => {
            setInteractiveAction('mouse')
            if (structNode.key === preselectKey) {
                return
            }
            if (needSkipEnterLeaveEvent()) {
                return
            }
            setPreselectKey(structNode.key)
            setInteractiveKey(structNode.key)
        },
        [needSkipEnterLeaveEvent, preselectKey]
    )

    const enterStructNode = useCallback(
        (structNode: StructNode<T>, e: React.MouseEvent) => {
            if (skipEnterLeaveFromScroll(e)) {
                return
            }
            mousemoveStructNode(structNode)
        },
        [skipEnterLeaveFromScroll, mousemoveStructNode]
    )

    const leaveStructNode = useCallback(
        (structNode: StructNode<T>, e: React.MouseEvent, itemRect?: Rect, itemChildrenRect?: Rect) => {
            if (skipEnterLeaveFromScroll(e)) {
                return
            }
            if (itemRect && itemChildrenRect) {
                skipItemRef.current.structNodeKey = structNode.key
                skipItemRef.current.itemRect = itemRect
                skipItemRef.current.itemChildrenRect = itemChildrenRect
            }
            if (needSkipEnterLeaveEvent()) {
                return
            }
            setPreselectKey(undefined)
        },
        [skipEnterLeaveFromScroll, needSkipEnterLeaveEvent]
    )

    const clickStructNode = useCallback(
        (structNode: StructNode<T>, e: React.MouseEvent) => {
            if (!hasChildWithStructNode(structNode)) {
                close()
                onChange?.(structNode.data, { e })
            }
        },
        [close, onChange]
    )

    const move2Top = useCallback(() => {
        const nextKey = getKeyWhenMove2Top(structNodes, interactiveKey, preselectKey)
        if (nextKey) {
            setPreselectKey(nextKey)
            setInteractiveKey(nextKey)
        }
    }, [interactiveKey, preselectKey, structNodes])

    const move2Bottom = useCallback(() => {
        const nextKey = getKeyWhenMove2Bottom(structNodes, interactiveKey, preselectKey)
        if (nextKey) {
            setPreselectKey(nextKey)
            setInteractiveKey(nextKey)
        }
    }, [interactiveKey, preselectKey, structNodes])

    const move2Left = useCallback(() => {
        const nextKey = getKeyWhenMove2Left(interactiveKey, preselectKey)
        if (nextKey) {
            setPreselectKey(nextKey)
            setInteractiveKey(nextKey)
        }
    }, [interactiveKey, preselectKey])

    const move2Right = useCallback(() => {
        const nextKey = getKeyWhenMove2Right(structNodes, interactiveKey, preselectKey)
        if (nextKey) {
            setPreselectKey(nextKey)
            setInteractiveKey(nextKey)
        }
    }, [interactiveKey, preselectKey, structNodes])

    const onEscKey = useCallback(() => {
        close()
        closeByESC?.()
    }, [close, closeByESC])

    const onEnterKey = useCallback(
        (e: React.KeyboardEvent) => {
            const info = getSameLevelStructNodesByStructNodeKey(structNodes, preselectKey)
            const node = info?.list[info.index]
            if (!node) {
                return
            }
            if (hasChildWithStructNode(node)) {
                move2Right()
            } else {
                close()
                onChange?.(node.data, { e })
            }
        },
        [close, move2Right, onChange, preselectKey, structNodes]
    )

    const onSpaceKey = useCallback(
        (e: React.KeyboardEvent) => {
            onEnterKey(e)
        },
        [onEnterKey]
    )

    const _onKeyboardEvent = useCallback(
        (e: React.KeyboardEvent) => {
            if (e.keyCode === 38 || e.code === 'ArrowUp') {
                setInteractiveAction('keyboard')
                move2Top()
            } else if (e.keyCode === 40 || e.code === 'ArrowDown') {
                setInteractiveAction('keyboard')
                move2Bottom()
            } else if (e.keyCode === 37 || e.code === 'ArrowLeft') {
                setInteractiveAction('keyboard')
                move2Left()
            } else if (e.keyCode === 39 || e.code === 'ArrowRight') {
                setInteractiveAction('keyboard')
                move2Right()
            } else if (e.keyCode === 27 || e.code === 'Escape') {
                setInteractiveAction('keyboard')
                onEscKey()
            } else if (e.keyCode === 13 || e.code === 'Enter') {
                setInteractiveAction('keyboard')
                onEnterKey(e)
            } else if (e.keyCode === 32 || e.code === 'Space') {
                setInteractiveAction('keyboard')
                onSpaceKey(e)
            }
            onKeyboard?.(e)
        },
        [move2Bottom, move2Left, move2Right, move2Top, onEnterKey, onEscKey, onKeyboard, onSpaceKey]
    )

    useImperativeHandle(ref, () => ({ open, close }), [close, open])

    return isOpenState
        ? ReactDOM.createPortal(
              <CaptureKeyboard<ExplicitUndefined<CaptureKeyboardProps>>
                  dataTestIds={props.dataTestIds}
                  ref={captureKeyboardRef}
                  onKeyboardEvent={_onKeyboardEvent}
                  onMouseMove={mousemoveMultiLevel}
                  onFocusLeave={onFocusLeave}
                  getFocusElement={() => popUpScrollRef.current?.getKeyboardElement()}
                  data-component-name={pickComponentNames.multiLevel}
                  className={props.pickRest?.classNameRoot}
                  onMouseDown={props.pickRest?.onMouseDown}
              >
                  <PopUpScroll<ExplicitUndefined<PopUpScrollProps>>
                      className={props.pickRest?.firstPopUpScrollProps?.className}
                      ref={popUpScrollRef}
                      worldRect={props.worldRect}
                      triggerRect={props.triggerRect}
                      placement={props.placement}
                      isMinWidthFromTrigger={props.isMinWidthFromTrigger}
                      minWidth={props.minWidth}
                      width={props.width}
                      maxWidth={props.maxWidth}
                      dataTestIds={props.dataTestIds}
                      visibleClassName={visibleClassName}
                      visibleTrigger={preselectKey}
                      onClickMask={close}
                      removeMask={props.removeMask}
                      removeTopPadding={props.removeTopPadding}
                      removeBottomPadding={props.removeBottomPadding}
                      autoAdjustTop={props.autoAdjustTop}
                      overMajorClassName={undefined}
                      overMinorClassName={undefined}
                      isNotCoverTriggerRect={undefined}
                  >
                      {structNodes.map((structNode, index) => (
                          <RenderStructNode<T, ExplicitUndefined<RenderStructNodeProps<T>>>
                              renderItemPrev={props.renderItemPrev}
                              renderItem={props.renderItem}
                              renderItemNext={props.renderItemNext}
                              isMinWidthFromTrigger={props.isMinWidthFromTrigger}
                              worldRect={props.worldRect}
                              minWidth={props.minWidth}
                              width={props.width}
                              maxWidth={props.maxWidth}
                              dataTestIds={props.dataTestIds}
                              key={structNode.key}
                              structNode={structNode}
                              preselectKey={preselectKey}
                              interactiveKey={interactiveKey}
                              interactiveAction={interactiveAction}
                              visibleClassName={visibleClassName}
                              visibleTrigger={preselectKey}
                              enterStructNode={enterStructNode}
                              mousemoveStructNode={mousemoveStructNode}
                              leaveStructNode={leaveStructNode}
                              clickStructNode={clickStructNode}
                              getPortalElement={getChildPortalElement}
                              getSameLevelDataList={getSameLevelDataList}
                              getIndexInSameLevelDataList={() => index}
                              isNotCoverTriggerRect={true}
                          />
                      ))}
                  </PopUpScroll>
              </CaptureKeyboard>,
              getPortalElement?.() ?? document.body
          )
        : null
}

export const MultiLevel = forwardRef(_MultiLevel) as <
    T extends CustomNode = CustomNode,
    K extends MultiLevelProps<T> = MultiLevelProps<T>
>(
    props: K & { ref?: React.Ref<MultiLevelRef> }
) => React.ReactElement
