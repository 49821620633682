import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    UsedStyle: '使用的样式',
    NewStyle: '新的样式',
    NoneFound: '无新样式',
    UsedComponent: '使用的组件',
    NewComponent: '新的组件',
    NoneFound_synonyms1: '无新组件',
} as const

export const enTranslation = {
    UsedStyle: 'Used style',
    NewStyle: 'New style',
    NoneFound: 'None found',
    UsedComponent: 'Used component',
    NewComponent: 'New component',
    NoneFound_synonyms1: 'None found',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
