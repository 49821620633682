import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { CSSProperties, FC, useEffect, useMemo, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import { useLeftPanelWidth } from '../../../../main/layout/layout-context'
import { LayerPanelRenderNodeItem } from '../render-item/render-node-item'
import styles from './index.module.less'

interface TopLevelFixedFrameItemProps {
    layerPanelContainerRef: () => HTMLDivElement
    topLevelFixedFrame: Wukong.DocumentProto.TopLevelFixedFrame
    hasScrolled: boolean
    layerUsingModes: Wukong.DocumentProto.IVariableLayerModeStateVector
}

export const TopLevelFixedFrameItem: FC<TopLevelFixedFrameItemProps> = ({
    layerPanelContainerRef,
    topLevelFixedFrame,
    hasScrolled,
    layerUsingModes,
}) => {
    const [containerViewPortTop, setContainerViewPortTop] = useState(0)
    const computedWidth = useLeftPanelWidth()

    useEffect(() => {
        const element = layerPanelContainerRef()
        if (!element) return

        const handleResize = () => {
            if (!element) return
            setContainerViewPortTop(element.getBoundingClientRect().top)
        }

        const observer = new ResizeObserver(handleResize)
        observer.observe(element)

        return () => observer.disconnect()
    }, [layerPanelContainerRef])

    const topLevelFixedStyle = useMemo<CSSProperties | undefined>(() => {
        if (topLevelFixedFrame) {
            const { fixedNode, yPosition } = topLevelFixedFrame
            if (fixedNode && containerViewPortTop) {
                return {
                    top: `${containerViewPortTop + yPosition}px`,
                    width: `${computedWidth}px`,
                }
            }
        }
        return undefined
    }, [topLevelFixedFrame, computedWidth, containerViewPortTop])

    const topLevelFixedClassName = useMemo<string>(() => {
        if (topLevelFixedFrame) {
            const { fixedNode, yPosition } = topLevelFixedFrame
            if (fixedNode) {
                return yPosition || !hasScrolled ? styles.fixed : `${styles.fixed} ${styles.shadow}`
            }
        }
        return ''
    }, [topLevelFixedFrame, hasScrolled])

    return useMemo(() => {
        if (topLevelFixedFrame) {
            const { fixedNode } = topLevelFixedFrame
            return (
                <>
                    {fixedNode && (
                        <div
                            style={topLevelFixedStyle}
                            className={classNames(topLevelFixedClassName)}
                            data-testid={`top-level-fixed-frame`}
                        >
                            <LayerPanelRenderNodeItem
                                index={0}
                                prevItem={undefined}
                                nextItem={undefined}
                                nodeItem={fixedNode as Wukong.DocumentProto.VLayerPanelNode}
                                key={fixedNode.id}
                                layerUsingModes={layerUsingModes}
                            />
                        </div>
                    )}
                </>
            )
        }
        return null
    }, [topLevelFixedFrame, topLevelFixedStyle, topLevelFixedClassName, layerUsingModes])
}
