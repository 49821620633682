import { SVGAttributes, useState } from 'react'
import {
    DraggablePopupV2,
    MonoIconCommonSettings16,
    MonoIconPanelTidyUpGrid16,
    Tooltip,
    WKButton,
    WKIconButton,
} from '../../../../../../ui-lib/src'
import { useMenuContextState } from '../../top-area/menu/context/menu-context'
import { useResizeHandle } from './hook/use-resize-handle'
import styles from './index.module.less'
import { WasmCallMonitorPanel } from './wasm-call-monitor'

const MotiffScopeIcon = (props: SVGAttributes<SVGSVGElement>) => (
    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" {...props}>
        <path
            d="M640 213.333333m-42.666667 0a42.666667 42.666667 0 1 0 85.333334 0 42.666667 42.666667 0 1 0-85.333334 0Z"
            fill="#586889"
        ></path>
        <path
            d="M469.333333 21.333333h128v85.333334h-128zM469.333333 576h128v85.333333h-128zM789.333333 725.333333v64H106.666667v-64h682.666666z"
            fill="#586889"
        ></path>
        <path
            d="M597.333333 106.666667a42.794667 42.794667 0 0 1 42.666667 42.666666v21.333334a42.666667 42.666667 0 0 0 0 85.333333v320H426.666667V149.333333a42.794667 42.794667 0 0 1 42.666666-42.666666z"
            fill="#6B95FF"
        ></path>
        <path
            d="M560.213333 874.666667h-11.093333a58.24 58.24 0 0 0-57.386667 48.426666 39.061333 39.061333 0 0 0-1.066666 10.026667V1002.666667h128v-69.546667A58.538667 58.538667 0 0 0 560.213333 874.666667zM512 448V106.666667h-42.666667a42.794667 42.794667 0 0 0-42.666666 42.666666v426.666667h213.333333v-85.333333h-85.333333a42.666667 42.666667 0 0 1-42.666667-42.666667z"
            fill="#567BFF"
        ></path>
        <path
            d="M512 21.333333a42.666667 42.666667 0 0 0 42.666667 42.666667h42.666666v42.666667h-128V21.333333zM512 661.333333v-21.333333a42.666667 42.666667 0 0 1 42.666667-42.666667h42.666666v-21.333333h-128v85.333333z"
            fill="#2B3E5B"
        ></path>
        <path
            d="M640 213.333333m-21.333333 0a21.333333 21.333333 0 1 0 42.666666 0 21.333333 21.333333 0 1 0-42.666666 0Z"
            fill="#2B3E5B"
        ></path>
        <path d="M106.666667 725.333333h682.666666v21.333334H106.666667z" fill="#7989AF"></path>
        <path
            d="M560.213333 874.666667h-11.093333a58.069333 58.069333 0 0 0-44.992 21.333333H512a42.666667 42.666667 0 0 1 42.666667 42.666667 42.666667 42.666667 0 0 0 42.666666 42.666666h21.333334v-48.213333A58.538667 58.538667 0 0 0 560.213333 874.666667z"
            fill="#6B95FF"
        ></path>
        <path
            d="M346.837333 757.333333a10.666667 10.666667 0 0 0 10.666667 10.666667h320a10.666667 10.666667 0 1 0 0-21.333333h-320a10.666667 10.666667 0 0 0-10.666667 10.666666zM709.504 757.333333a10.666667 10.666667 0 0 0 10.666667 10.666667h21.333333a10.666667 10.666667 0 1 0 0-21.333333h-21.333333a10.666667 10.666667 0 0 0-10.666667 10.666666z"
            fill="#65769C"
        ></path>
        <path
            d="M569.072102 947.126746a24.490667 17.6 44.98 1 0 24.881469-24.898846 24.490667 17.6 44.98 1 0-24.881469 24.898846Z"
            fill="#F6FAFD"
        ></path>
        <path
            d="M547.729932 179.148089a24.490667 17.6 44.98 1 0 24.881469-24.898845 24.490667 17.6 44.98 1 0-24.881469 24.898845Z"
            fill="#F6FAFD"
        ></path>
        <path
            d="M548.517839 204.915833a12.245333 8.810667 44.98 1 0 12.455814-12.464513 12.245333 8.810667 44.98 1 0-12.455814 12.464513Z"
            fill="#F6FAFD"
        ></path>
        <path d="M618.666667 1024h-128a21.333333 21.333333 0 0 1-21.333334-21.333333v-69.589334A79.850667 79.850667 0 0 1 549.098667 853.333333h11.136A79.850667 79.850667 0 0 1 640 933.077333V1002.666667a21.333333 21.333333 0 0 1-21.333333 21.333333z m-106.666667-42.666667h85.333333v-48.256A37.141333 37.141333 0 0 0 560.234667 896h-11.136A37.141333 37.141333 0 0 0 512 933.077333zM448 192h-42.666667V149.333333a64 64 0 0 1 64-64h128a64 64 0 0 1 64 64v21.333334h-42.666666V149.333333a21.333333 21.333333 0 0 0-21.333334-21.333333h-128a21.333333 21.333333 0 0 0-21.333333 21.333333zM640 597.333333H426.666667a21.333333 21.333333 0 0 1-21.333334-21.333333V234.666667h42.666667v320h170.666667v-42.666667h42.666666v64a21.333333 21.333333 0 0 1-21.333333 21.333333z"></path>
        <path d="M618.666667 256h42.666666v213.333333h-42.666666zM597.333333 128h-128a21.333333 21.333333 0 0 1-21.333333-21.333333V21.333333a21.333333 21.333333 0 0 1 21.333333-21.333333h128a21.333333 21.333333 0 0 1 21.333334 21.333333v85.333334a21.333333 21.333333 0 0 1-21.333334 21.333333z m-106.666666-42.666667h85.333333V42.666667h-85.333333zM789.333333 810.666667H106.666667a21.333333 21.333333 0 0 1-21.333334-21.333334v-64a21.333333 21.333333 0 0 1 21.333334-21.333333h64v42.666667H128v21.333333h640v-21.333333H298.666667v-42.666667h490.666666a21.333333 21.333333 0 0 1 21.333334 21.333333v64a21.333333 21.333333 0 0 1-21.333334 21.333334z"></path>
        <path d="M213.333333 704h42.666667v42.666667h-42.666667zM640 277.333333a64 64 0 1 1 64-64 64 64 0 0 1-64 64z m0-85.333333a21.333333 21.333333 0 1 0 21.333333 21.333333 21.333333 21.333333 0 0 0-21.333333-21.333333zM746.666667 981.333333h42.666666v42.666667h-42.666666zM64 981.333333h640v42.666667H64z"></path>
        <path d="M763.733333 253.866667A187.157333 187.157333 0 0 0 682.666667 234.666667V192a229.141333 229.141333 0 0 1 99.52 23.466667zM576 960a295.104 295.104 0 0 1-151.808-45.952l22.954667-35.968A250.581333 250.581333 0 0 0 576 917.333333c244.032 0 362.666667-125.610667 362.666667-384 0-92.010667-60.821333-203.712-141.461334-259.84l24.384-35.008C914.154667 302.933333 981.333333 426.88 981.333333 533.333333c0 283.114667-136.384 426.666667-405.333333 426.666667zM393.066667 890.56A137.152 137.152 0 0 1 341.333333 789.333333h42.666667a98.88 98.88 0 0 0 37.504 69.44z"></path>
        <path d="M597.333333 682.666667h-128a21.333333 21.333333 0 0 1-21.333333-21.333334v-85.333333a21.333333 21.333333 0 0 1 21.333333-21.333333h128a21.333333 21.333333 0 0 1 21.333334 21.333333v85.333333a21.333333 21.333333 0 0 1-21.333334 21.333334z m-106.666666-42.666667h85.333333v-42.666667h-85.333333z"></path>
    </svg>
)

const WasmCallMonitorHeader = (props: { updateShowSetting: (value: boolean) => void; showSetting: boolean }) => {
    return (
        <div className={styles.inspectorHeader}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <MotiffScopeIcon />
            </div>
            <div className={styles.title}> WasmCallMonitor </div>
            {props.showSetting ? (
                <WKButton type="primary" onClick={() => props.updateShowSetting(false)}>
                    回主页
                </WKButton>
            ) : (
                <div className="flex items-center gap-1">
                    <div>黑名单设置 </div>
                    <Tooltip title={'常用属性配置'}>
                        <WKIconButton
                            className="group"
                            data-testid="setting-icon"
                            onClick={() => props.updateShowSetting(true)}
                            icon={
                                <MonoIconCommonSettings16 className="transition-transform duration-300 group-hover:rotate-90" />
                            }
                        ></WKIconButton>
                    </Tooltip>
                </div>
            )}
        </div>
    )
}

const TITLE_HEIGHT = 44

export const WasmCallMonitor = () => {
    const { size, handleResizeStart } = useResizeHandle({
        initialSize: { width: 800, height: 400 },
        minSize: { width: 330, height: 200 },
    })
    const { showWasmCallMonitor, setShowWasmCallMonitor } = useMenuContextState()

    const [showSetting, setShowSetting] = useState(false)
    return (
        <DraggablePopupV2
            style={{
                display: showWasmCallMonitor ? 'block' : 'none',
            }}
            onCancel={() => {
                setShowWasmCallMonitor(false)
            }}
            visible={true}
            header={<WasmCallMonitorHeader updateShowSetting={setShowSetting} showSetting={showSetting} />}
            footer={null}
            range={undefined}
            bodyClassName="p-0"
            width={size.width}
            height={size.height}
            maxScrollHeight={size.height - TITLE_HEIGHT}
            enableScrollBar={true}
            testId="motiff-node-scope"
            styleType="editor"
        >
            <WasmCallMonitorPanel height={size.height - TITLE_HEIGHT} showSetting={showSetting} />
            <MonoIconPanelTidyUpGrid16 className={styles.resizeHandle} onPointerDown={handleResizeStart} />
        </DraggablePopupV2>
    )
}
