import { inflate } from 'pako'
import { buf as crc32Buf } from '../crc32'
import { P3_HEADER } from './p3-header'
import { ImageFormat } from './types'

export interface Chunk {
    type: string
    data: Uint8Array
    crc: number
}

export interface ImageDpiInfo {
    xDPI: number
    yDPI: number
}

const kPngHeader = [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a]
const kChunkExtraLength = 4 * 3
const kDPIConvertFactor = 39.37008
export const kDefaultDPI = 72

function readChunk(arr: Uint8Array, index: number): Chunk {
    const nextElement = function () {
        return arr[index++]
    }

    const getNextInt32 = function () {
        let int8Arr = new Uint8Array(4)
        int8Arr = int8Arr.map(nextElement).reverse()
        const int32Arr = new Int32Array(int8Arr.buffer)
        return int32Arr[0]
    }

    // Length
    const length = getNextInt32()

    // Chunk Type Code
    let type = ''
    for (let i = 0; i < 4; i++) {
        type += String.fromCharCode(arr[index++])
    }

    // Chunk Data
    const data = arr.slice(index, index + length)
    index += length

    // CRC
    const crc = getNextInt32()

    return {
        type: type,
        data: data,
        crc: crc,
    }
}

export function isPNG(arr: Uint8Array): boolean {
    if (arr.length < kPngHeader.length) {
        return false
    }
    for (let i = 0; i < kPngHeader.length; ++i) {
        if (arr[i] != kPngHeader[i]) {
            return false
        }
    }
    return true
}

export function getChunks(arr: Uint8Array): Chunk[] {
    if (arr.length < kPngHeader.length) {
        return []
    }
    for (let i = 0; i < kPngHeader.length; ++i) {
        if (arr[i] != kPngHeader[i]) {
            return []
        }
    }

    let index = kPngHeader.length
    const chunks = []
    while (index < arr.length) {
        const chunk = readChunk(arr, index)
        chunks.push(chunk)
        index += chunk.data.length + kChunkExtraLength
    }

    return chunks
}

export function toPNG(chunks: Chunk[]): Uint8Array {
    const getCharCode = function (char: string) {
        return char.charCodeAt(0)
    }

    const getInt8ArrFromInt32 = function (num: number) {
        const lengthArr = new Int32Array(1)
        lengthArr[0] = num
        const arr = new Int8Array(lengthArr.buffer)
        return arr.reverse()
    }

    let length = kPngHeader.length
    chunks.forEach((chunk) => {
        length += chunk.data.length + kChunkExtraLength
    })

    const output = new Uint8Array(length)
    let index = 0
    // Write Header
    output.set(kPngHeader, index)
    index += 8
    // Write Chunks
    chunks.forEach((chunk) => {
        // Write Length
        output.set(getInt8ArrFromInt32(chunk.data.length), index)
        index += 4
        // Write Chunk Type Code
        output.set(Array.from(chunk.type).map(getCharCode), index)
        index += 4
        // Write Chunk Data
        output.set(chunk.data, index)
        index += chunk.data.length
        // Write CRC
        output.set(getInt8ArrFromInt32(chunk.crc), index)
        index += 4
    })

    return output
}

export async function getPNGFileDPI(image: Blob): Promise<ImageDpiInfo> {
    const array = new Uint8Array(await image.arrayBuffer())
    const pHYsChunk = getChunks(array).filter((chunk) => chunk.type === 'pHYs')
    const dpiInfo: ImageDpiInfo = { xDPI: kDefaultDPI, yDPI: kDefaultDPI }
    // No pHYs chunk
    if (pHYsChunk.length !== 0 && pHYsChunk[0].data.length >= 8) {
        const dataView = new DataView(pHYsChunk[0].data.buffer)
        dpiInfo.xDPI = Math.round(dataView.getInt32(0) / kDPIConvertFactor)
        dpiInfo.yDPI = Math.round(dataView.getInt32(4) / kDPIConvertFactor)
    }

    return dpiInfo
}

// http://www.libpng.org/pub/png/spec/1.2/PNG-Chunks.html
// 4.2.2.4. iCCP Embedded ICC profile
// Profile name:       1-79 bytes (character string)
// Null separator:     1 byte
// Compression method: 1 byte
// Compressed profile: n bytes
function parseICCPInChunk(iccChunkData: Uint8Array) {
    const ProfileNameLengthRange = {
        Min: 1,
        Max: 79,
    }
    function parseNullSeparatorIndex() {
        const NullSeparatorAsByte = '\0'.charCodeAt(0)
        let targetIndex: number | null = null
        for (let i = ProfileNameLengthRange.Min; i < 1 + ProfileNameLengthRange.Max && i < iccChunkData.length; ++i)
            if (iccChunkData[i] === NullSeparatorAsByte) {
                targetIndex = i
                break
            }
        if (targetIndex === null) {
            throw new Error("Couldn't find null separator")
        }
        if (iccChunkData[targetIndex] !== NullSeparatorAsByte) {
            throw new Error('Null separator is not the null character')
        }
        return targetIndex
    }
    const nullSeparatorIndex = parseNullSeparatorIndex()
    const compressionMethodIndex = nullSeparatorIndex + 1
    if (iccChunkData[compressionMethodIndex] !== 0) {
        // deflate 是 0
        throw new Error(`Compression method is not deflate, it's ${iccChunkData[compressionMethodIndex]}`)
    }
    const dataStartIndex = compressionMethodIndex + 1
    const data = iccChunkData.subarray(dataStartIndex)
    return inflate(data)
}

export function removePNGICCChunksAndGetMeta(source: Uint8Array): {
    imageWithoutColorSpace: Uint8Array
    colorProfileRawData: Uint8Array
    format: ImageFormat | null
    hasGamma: boolean
} {
    if (!isPNG(source)) {
        return {
            imageWithoutColorSpace: source,
            colorProfileRawData: new Uint8Array(),
            format: null,
            hasGamma: false,
        }
    }
    const chunks = getChunks(source)
    const colorProfileChunk = chunks.find((chunk) => chunk.type === 'iCCP')
    const hasGamma = Boolean(chunks.find((chunk) => chunk.type === 'gAMA'))
    const cleanChunks = chunks.filter((chunk) => !['iCCP', 'gAMA'].includes(chunk.type))

    const imageWithoutColorSpace = toPNG(cleanChunks)
    let colorProfileRawData = new Uint8Array()

    try {
        const _colorProfileRawData = colorProfileChunk ? parseICCPInChunk(colorProfileChunk.data) : null
        if (_colorProfileRawData) {
            colorProfileRawData = _colorProfileRawData
        }
    } catch (e) {
        console.error(e)
    }

    return {
        imageWithoutColorSpace,
        colorProfileRawData,
        format: ImageFormat.Png,
        hasGamma,
    }
}

export function replacePNGICCChunk(source: Uint8Array, deflatedICCP: Uint8Array) {
    const iccChunkData = new Uint8Array(P3_HEADER.byteLength + deflatedICCP.byteLength)
    iccChunkData.set(P3_HEADER)
    iccChunkData.set(deflatedICCP, P3_HEADER.byteLength)

    const toCRC32Buf = new Uint8Array(iccChunkData.byteLength + 4)
    toCRC32Buf.set(iccChunkData, 4)
    'iCCP'.split('').forEach((c, i) => {
        toCRC32Buf[i] = c.charCodeAt(0)
    })

    const iCCPChunk: Chunk = {
        type: 'iCCP',
        data: iccChunkData,
        crc: crc32Buf(toCRC32Buf),
    }

    const chunks = getChunks(source)
    const cleanChunks = chunks.filter((chunk) => !['iCCP', 'gAMA', 'sRGB'].includes(chunk.type))

    const idatChunkIndex = cleanChunks.findIndex((c) => c.type === 'IDAT')
    if (idatChunkIndex === -1) {
        cleanChunks.push(iCCPChunk)
    } else {
        cleanChunks.splice(idatChunkIndex, 0, iCCPChunk)
    }

    return toPNG(cleanChunks)
}
