import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    AddAComment: '输入评论',
    Reply: '输入回复',
} as const

export const enTranslation = {
    AddAComment: 'Add a comment',
    Reply: 'Reply',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
