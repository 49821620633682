import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    PlaceHolder: '输入你的 UI 需求...',
    RetryText: '生成失败, 请重试',
    RetryTextSubset: '一个结果生成失败, 请重试',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    PlaceHolder: 'Enter your UI requirements...',
    RetryText: 'Failed to generate results. Try again. ',
    RetryTextSubset: 'One of the two generated results failed. Try again.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
