/* eslint-disable no-restricted-imports */
import {
    AddExportSettingsToSelectionCommand,
    DeleteAllExportSettingsToSelectionCommand,
    UpdateDevModeExportCompressCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { useCallback, useMemo } from 'react'
import { usePageSignal } from '../../../../../external-store/atoms/page-signal'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'
import { useExportByConfigs } from '../../../design/export/hooks/use-export'
import { useExportPreparation } from '../../../design/export/hooks/use-export-preparation'
import { isExportWebP } from '../../../design/export/util'
import { logs } from '../../logs'
import { multiNodesMultiExportSettings } from './utils'
import ExportFormatType = Wukong.DocumentProto.ExportFormatType
import PresetCodeType = Wukong.DocumentProto.PresetCodeType
import FileType = Wukong.DocumentProto.FileType

export function useDevModeExport() {
    const { presetCodeType, fileType, exportSetting } = useDevModeExportSetting()
    const { isCompress } = useDevModeExportCompress()

    const exportByConfigs = useExportByConfigs()
    const { prepare, done } = useExportPreparation()
    const pageSignal = usePageSignal()

    const exportSelection = useCallback(async () => {
        if (!exportSetting) {
            return
        }

        addExportFrog(
            presetCodeType!,
            fileType!,
            exportSetting.exportSettings.map((s) => s.constraint.value),
            isCompress
        )

        const nameCount = new Map()
        const configs = multiNodesMultiExportSettings(
            nameCount,
            exportSetting.selectionNodeIds.map((id) => [id]),
            exportSetting.selectionNodeNames,
            exportSetting.exportSettings,
            isCompress,
            presetCodeType,
            fileType
        )
        const id = await prepare(pageSignal)
        await exportByConfigs(configs)
        if (id) {
            done(id)
        }
    }, [exportSetting, isCompress, presetCodeType, fileType, prepare, exportByConfigs, done, pageSignal])

    return {
        exportSelection,
    } as const
}

function addExportFrog(presetCodeType: PresetCodeType, fileType: FileType, scalesValue: number[], isCompress: boolean) {
    const export_preset_type = (() => {
        switch (presetCodeType) {
            case PresetCodeType.PRESET_CODE_TYPE_WEB:
                return 'Web'
            case PresetCodeType.PRESET_CODE_TYPE_I_O_S:
                return 'iOS'
            case PresetCodeType.PRESET_CODE_TYPE_ANDROID:
                return 'Android'
        }
    })()
    const export_scale = scalesValue
        .map((scale) => {
            switch (scale) {
                case 1000:
                    return '1x'
                case 2000:
                    return '2x'
                case 3000:
                    return '3x'
                case 4000:
                    return '4x'
                case 1500:
                    return '1.5x'
            }
        })
        .join(',')

    const export_file_type = (() => {
        switch (fileType) {
            case FileType.FILE_TYPE_PNG:
                return 'PNG'
            case FileType.FILE_TYPE_JPEG:
                return 'JPG'
            case FileType.FILE_TYPE_PDF:
                return 'PDF'
            case FileType.FILE_TYPE_WEBP:
                return 'WebP'
            case FileType.FILE_TYPE_AVIF:
                return 'AVIF'
            case FileType.FILE_TYPE_SVG:
                return 'SVG'
        }
    })()

    const export_compression = isCompress ? 'compressed' : 'uncompressed'

    logs.DevMode.exportExecute({ export_preset_type, export_file_type, export_scale, export_compression })
}

export function useDevModeExportSetting() {
    const inspectExportViewState = useViewState('devModeInspectExportViewState')
    const presetCodeType = inspectExportViewState?.presetCodeType
    const fileType = inspectExportViewState?.fileType
    const exportSetting = useMemo(() => {
        if (!inspectExportViewState) {
            return {
                selectionNodeIds: [],
                selectionNodeNames: [],
                exportSettings: [],
            }
        }

        // 不支持 webp 时降级为 PNG 处理
        const newSettings = inspectExportViewState.exportSetting.exportSettings.map((setting) => {
            if (!isExportWebP() && setting.format === ExportFormatType.EXPORT_FORMAT_TYPE_WEBP) {
                return {
                    ...setting,
                    format: ExportFormatType.EXPORT_FORMAT_TYPE_PNG,
                }
            }

            return setting
        })
        return { ...inspectExportViewState.exportSetting, exportSettings: newSettings }
    }, [inspectExportViewState])

    return {
        presetCodeType,
        fileType,
        exportSetting,
    }
}

export function useShowDevModeExportDetail() {
    const command = useCommand()

    const isShowDetail = useViewState('showDevModeInspectExport', false)

    const showDetail = useCallback(() => {
        if (isShowDetail) {
            return
        }
        command.DEPRECATED_invokeBridge(AddExportSettingsToSelectionCommand)
        command.commitUndo()
    }, [command, isShowDetail])

    const closeDetail = useCallback(() => {
        if (!isShowDetail) {
            return
        }
        command.DEPRECATED_invokeBridge(DeleteAllExportSettingsToSelectionCommand)
        command.commitUndo()
    }, [command, isShowDetail])

    return {
        isShowDetail,
        showDetail,
        closeDetail,
    } as const
}

export function useDevModeExportCompress() {
    const inspectExportViewState = useViewState('devModeInspectExportViewState')
    const isCompress = inspectExportViewState?.isCompress ?? false
    const command = useCommand()

    // 研发模式默认不压缩

    const setIsCompress = useCallback(
        (value: boolean) => {
            command.DEPRECATED_invokeBridge(UpdateDevModeExportCompressCommand, { value })
        },
        [command]
    )

    return {
        isCompress,
        setIsCompress,
    }
}
