import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    ToMovePasted: '有组件被粘贴到本文件，发布更新后可迁移引用关系',
    ToMovePasted_synonyms1: '有样式被粘贴到本文件，发布更新后可迁移引用关系',
    SomeComponentsWere: '引用的组件被迁移，更新以关联迁移后的组件',
    SomeStylesWere: '引用的样式被迁移，更新以关联迁移后的样式',
    SomeComponentsAnd: '引用的组件和样式被迁移，更新以关联迁移后的组件和样式',
    ComponentsAndStyles: '引用的组件和样式有更新',
    ComponentsUpdatesAvailable: '引用的组件有更新',
    StylesUpdatesAvailable: '引用的样式有更新',
    VariablesUpdatesAvailable: '引用的变量有更新',
    VariablesAndStylesUpdatesAvailable: '引用的样式和变量有更新',
    VariablesAndComponentsUpdatesAvailable: '引用的组件和变量有更新',
    VariablesAndComponentsAndStylesUpdatesAvailable: '引用的组件、样式和变量有更新',
} as const

export const enTranslation = {
    ToMovePasted: 'To move pasted components to this file, publish a library update.',
    ToMovePasted_synonyms1: 'To move pasted styles to this file, publish a library update.',
    SomeComponentsWere: 'Some components were moved. Updates are available.',
    SomeStylesWere: 'Some styles were moved. Updates are available.',
    SomeComponentsAnd: 'Some components and styles were moved. Updates are available.',
    ComponentsAndStyles: 'Components and styles updates available',
    ComponentsUpdatesAvailable: 'Components updates available',
    StylesUpdatesAvailable: 'Styles updates available',
    VariablesUpdatesAvailable: 'Variables updates available',
    VariablesAndStylesUpdatesAvailable: 'Styles and variables updates available',
    VariablesAndComponentsUpdatesAvailable: 'Components and variables updates available',
    VariablesAndComponentsAndStylesUpdatesAvailable: 'Components, styles and variables updates available',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
