import { signalInterval } from '../../../../util/src/abort-controller/timers'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { environment } from '../../environment'
import { WkCLog } from '../clog/wukong/instance'
import { GetGoogleAuthSwitch } from '../request/client-utils'

export class NetworkAnalysis {
    private readonly TRACE_DURATION = 1000 * 60 * 5 // 5分钟

    constructor(signal: TraceableAbortSignal) {
        this.metricNetworkAnalysis(signal)

        signalInterval(
            () => {
                this.metricNetworkAnalysis(signal)
            },
            this.TRACE_DURATION,
            { signal }
        )
    }

    private async metricNetworkAnalysis(signal: TraceableAbortSignal) {
        const start = performance.now()

        await new GetGoogleAuthSwitch().start()
        signal.throwIfAborted()

        const duration = performance.now() - start

        WkCLog.log('motiff-network-analysis', {
            duration,
            isAbroad: environment.isAbroad,
        })
    }
}
