import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    FileColorProfile: '颜色配置文件',
    FailedToCreate: '文件创建失败',
    Export: '批量导出',
    File: '文件',
    NewFile: '新建文件',
    Duplicate: '创建文件副本',
    SaveToVersion: '保存为历史版本',
    HideVersionHistory: '退出历史版本',
    ShowVersionHistory: '查看历史版本',
    ExportFramesToPDF: '导出容器为 PDF 文件...',
    SaveAs: '导出为 Sketch 文件...',
    SaveAsFigma: '导出为 Figma 文件...',
    Untitled: '未命名',
} as const

export const enTranslation = {
    FileColorProfile: 'File color profile',
    FailedToCreate: 'An error occurred while creating file',
    Export: 'Export',
    File: 'File',
    NewFile: 'New file',
    Duplicate: 'Duplicate',
    SaveToVersion: 'Save to version history',
    HideVersionHistory: 'Hide version history',
    ShowVersionHistory: 'Show version history',
    ExportFramesToPDF: 'Export frames to PDF...',
    SaveAs: 'Save as Sketch file...',
    SaveAsFigma: 'Save as Figma file...',
    Untitled: 'Untitled',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
