import classNames from 'classnames'
import React, { HTMLAttributes, forwardRef } from 'react'
import { useElementRef } from '../../../utils/hook/use-element-ref'
import classes from './scrollbar.module.less'

export interface ViewProps extends HTMLAttributes<HTMLDivElement> {
    componentRefMounted?: (el: HTMLDivElement | null) => void
}

function _RenderView(props: ViewProps, ref?: React.Ref<HTMLDivElement>) {
    const { componentRefMounted, ...otherProps } = props
    const { reactRef } = useElementRef(ref, componentRefMounted)
    return <div {...otherProps} ref={reactRef}></div>
}
export const RenderView = forwardRef(_RenderView)

function _RenderTrackHorizontal(
    { className, ...otherProps }: HTMLAttributes<HTMLDivElement>,
    ref?: React.Ref<HTMLDivElement>
) {
    return <div className={classNames(classes.trackHorizontal, [className])} {...otherProps} ref={ref} />
}
export const RenderTrackHorizontal = forwardRef(_RenderTrackHorizontal)

function _RenderTrackVertical(
    { className, ...otherProps }: HTMLAttributes<HTMLDivElement>,
    ref?: React.Ref<HTMLDivElement>
) {
    return <div className={classNames(classes.trackVertical, [className])} {...otherProps} ref={ref} />
}
export const RenderTrackVertical = forwardRef(_RenderTrackVertical)

export function renderThumbHorizontal({ className, ...otherProps }: HTMLAttributes<HTMLDivElement>) {
    return <div className={classNames(classes.thumbHorizontal, [className])} {...otherProps} />
}

export function renderThumbVertical({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
    return <div className={classNames(classes.thumbVertical, [className])} {...props} />
}
