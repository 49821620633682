import { GlobalCanvas } from './global-canvas'

export interface GraphicCardInfo {
    vendor: string
    renderer: string
}

export function getVideoCardInfo(): GraphicCardInfo | null {
    try {
        const webgl = GlobalCanvas.webgl()
        if (!webgl) {
            return null
        }
        const debugInfo = webgl.context.getExtension('WEBGL_debug_renderer_info')
        if (!debugInfo) {
            return null
        }
        return {
            vendor: webgl.context.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL),
            renderer: webgl.context.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL),
        }
    } catch (e) {
        return null
    }
}
