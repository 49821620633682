import classNames from 'classnames'
import { HTMLAttributes, useCallback, useRef } from 'react'
import { MulticolorIconCommonMissingVariable16 } from '../../../../../../ui-lib/src'
import Tooltip from '../../../../../../ui-lib/src/components/tooltip/tooltip'
import { useIsEllipsisActive } from '../../../../../../util/src'
import styles from './index.module.less'

interface ColorVarNameProps extends HTMLAttributes<HTMLDivElement> {
    name: string
    hovered?: boolean
    disabled?: boolean
    selected?: boolean
    deleted?: boolean
    className?: string
    nameClassName?: string
    maxWidth?: number
    onClickOnTag?: (e: React.MouseEvent) => void
    onHoverName?: (hovered: boolean) => void
}

export function ColorVarName({
    name,
    hovered,
    disabled,
    selected,
    deleted,
    className,
    nameClassName,
    maxWidth,
    onMouseDown,
    onClickOnTag,
    onHoverName,
    ...rest
}: ColorVarNameProps) {
    const ref = useRef<HTMLDivElement>(null)
    const isEllipsis = useIsEllipsisActive(ref.current)
    // 变量名称左侧省略，需要反转
    const reverseName = name.split('').reverse().join('')

    const _onMouseDown = useCallback(
        (e: any) => {
            e.preventDefault()
            onMouseDown?.(e)
        },
        [onMouseDown]
    )

    return (
        <div
            className={classNames(
                styles.colorVarName,
                hovered && styles.hovered,
                selected && styles.selected,
                disabled && styles.disabled,
                className
            )}
            data-testid="color-var-name"
            {...rest}
            onMouseDown={_onMouseDown}
            style={{ maxWidth }}
        >
            <Tooltip title={name} inactivation={!isEllipsis}>
                <div
                    className={classNames(styles.name, deleted && styles.deleted, nameClassName)}
                    ref={ref}
                    onClick={onClickOnTag}
                    onMouseEnter={() => onHoverName?.(true)}
                    onMouseLeave={() => onHoverName?.(false)}
                >
                    {deleted && (
                        <div className={styles.deletedIcon}>
                            <MulticolorIconCommonMissingVariable16 />
                        </div>
                    )}
                    <span>{reverseName}</span>
                </div>
            </Tooltip>
        </div>
    )
}
