import type { CookStringType } from './parse-string-type'
import {
    CalculateState,
    ErrorType,
    OperatorType,
    associativity,
    calculateState2ErrorType,
    inputOperator,
    inputOperator2operatorListIndex,
    operatorList,
    operatorListIndex,
} from './parse-string-type'

/**
 *
 * @param inputString
 * @param oldValue
 * @returns number | Error
 */
export function parseString(inputString: string | number, oldValue?: number | string) {
    oldValue = preHandleOldValue(oldValue)
    const r = tryParse(String(inputString), oldValue)
    if (r.value !== null && isFinite(r.value)) {
        return r.value
    }
    throw new CustomCalculateError('Could not parse input', r.error)
}
export function preHandleOldValue(value: any) {
    if (typeof value !== 'number' && typeof value !== 'string') {
        return undefined
    }
    return value === '' || isNaN(Number(value)) ? undefined : Number(value)
}
export function tryParse(inputString: string, oldValue?: number) {
    const cookStringProductList: CookStringType[] = []
    const splitStringList = splitString(inputString)
    const r = cookString(splitStringList, cookStringProductList)
    if (r !== null) {
        return { value: null, error: r }
    }
    const cookStringNumberList = [],
        cookStringOperatorStack = []
    for (const cookStringProduct of cookStringProductList) {
        switch (cookStringProduct.type) {
            case OperatorType.NUMBER: {
                cookStringNumberList.push(cookStringProduct.value)
                break
            }
            case OperatorType.MIXED_VARIABLE: {
                if (oldValue === undefined) {
                    return { value: null, error: { type: ErrorType.EVAL_NO_CURRENT_VALUE } }
                }
                // 此刻等价于以 oldValue 的值替代 mixed
                cookStringNumberList.push(oldValue)
                break
            }
            case OperatorType.OPERATOR: {
                const operatorDetail = operatorList[cookStringProduct.operator]
                switch (cookStringProduct.operator) {
                    case operatorListIndex.UNARY_PLUS:
                        break
                    case operatorListIndex.PAREN_L: {
                        cookStringOperatorStack.push(cookStringProduct)
                        break
                    }
                    case operatorListIndex.PAREN_R: {
                        if (!cookStringOperatorStack.length) {
                            return { value: null, error: { type: 3, position: cookStringProduct.position } }
                        }
                        for (; cookStringOperatorStack.length; ) {
                            const c = cookStringOperatorStack[cookStringOperatorStack.length - 1]
                            if (c.operator === operatorListIndex.PAREN_L) {
                                cookStringOperatorStack.pop()
                                break
                            } else {
                                const onceResult = onceCalculate(c.operator, cookStringNumberList, oldValue)
                                if (onceResult !== CalculateState.SUCCESS) {
                                    return { value: null, error: { type: calculateState2ErrorType[onceResult] } }
                                }
                                cookStringOperatorStack.pop()
                            }
                            if (!cookStringOperatorStack.length)
                                return { value: null, error: { type: 3, position: cookStringProduct.position } }
                        }
                        break
                    }
                    default: {
                        for (let l; (l = cookStringOperatorStack[cookStringOperatorStack.length - 1]); ) {
                            if (operatorDetail.associativity === associativity.LEFT)
                                if (operatorDetail.precedence <= operatorList[l.operator].precedence) {
                                    const onceResult = onceCalculate(l.operator, cookStringNumberList, oldValue)
                                    if (onceResult !== CalculateState.SUCCESS) {
                                        return { value: null, error: { type: calculateState2ErrorType[onceResult] } }
                                    }
                                    cookStringOperatorStack.pop()
                                } else break
                            else if (operatorDetail.associativity === associativity.RIGHT)
                                if (operatorDetail.precedence < operatorList[l.operator].precedence) {
                                    const onceResult = onceCalculate(l.operator, cookStringNumberList, oldValue)
                                    if (onceResult !== CalculateState.SUCCESS) {
                                        return { value: null, error: { type: calculateState2ErrorType[onceResult] } }
                                    }
                                    cookStringOperatorStack.pop()
                                } else break
                            else {
                                return { value: null, error: { type: ErrorType.EVAL_UNKNOWN } }
                            }
                        }
                        cookStringOperatorStack.push(cookStringProduct)
                        break
                    }
                }
                break
            }
            default:
                return { value: null, error: { type: ErrorType.SYNTAX_UNRECOGNIZED } }
        }
    }
    for (; cookStringOperatorStack.length; ) {
        const s = cookStringOperatorStack.pop()!
        if (s.operator === operatorListIndex.PAREN_L || s.operator === operatorListIndex.PAREN_R) {
            return { value: null, error: { type: 3, position: inputString.length } }
        }
        const onceResult = onceCalculate(s.operator, cookStringNumberList, oldValue)
        if (onceResult !== CalculateState.SUCCESS) {
            return { value: null, error: { type: calculateState2ErrorType[onceResult] } }
        }
    }
    return cookStringNumberList.length !== 1
        ? {
              value: null,
              error: { type: ErrorType.SYNTAX_UNRECOGNIZED },
          }
        : {
              value: cookStringNumberList[0],
              error: null,
          }
}
// 把输入的字符串按指定规则切分
export function splitString(str: string): string[] {
    str = str.replace(/多个值/g, 'mixed')
    const regexp = /(mixed|px|[+*()^/%x-]|(?:\d+[.,]?\d*|[.,]?\d+)(?:e[+-]?\d+)?|(?:\s+))/g
    return str.toLowerCase().split(regexp)
}
// 把字符串拆分细化标注
export function cookString(stringList: string[], stringPartDetailList: CookStringType[]) {
    if (stringList.length === 0) {
        return { type: ErrorType.SYNTAX_EMPTY }
    }
    let stringIndex = 0
    for (let n = 0; n < stringList.length; n++) {
        const stringSplitValue = stringList[n]
        const stringSplitValueLength = stringSplitValue.length
        if (n % 2) {
            // eslint-disable-next-line no-prototype-builtins
            const operatorFlag: number = inputOperator.hasOwnProperty(stringSplitValue)
                ? (inputOperator as any)[stringSplitValue]
                : 0
            const operator: number =
                operatorFlag in inputOperator2operatorListIndex
                    ? (inputOperator2operatorListIndex as any)[operatorFlag]
                    : 0
            const c: CookStringType = {
                text: stringSplitValue,
                type: operatorFlag !== 0 ? OperatorType.OPERATOR : OperatorType.NONE,
                operator,
                position: stringIndex,
                value: NaN,
            }
            if (c.type === OperatorType.NONE && /^(?:\d+[.,]?\d*|[.,]?\d+)(?:[eE][+-]?\d+)?$/.test(stringSplitValue)) {
                c.type = OperatorType.NUMBER
                c.value = +stringSplitValue.replace(',', '.')
            } else if (/(mixed)/.test(stringSplitValue)) {
                c.type = OperatorType.MIXED_VARIABLE
            }
            if (c.type !== OperatorType.NONE) {
                stringPartDetailList.push(c)
            }
        } else if (stringSplitValueLength > 0) {
            return {
                type: ErrorType.SYNTAX_UNRECOGNIZED,
                position: stringIndex,
                length: stringSplitValueLength,
            }
        }
        stringIndex += stringSplitValueLength
    }
    if (stringPartDetailList.length === 0) {
        return { type: ErrorType.SYNTAX_EMPTY }
    }
    for (let n = 0, prevStringPartDetail = null; n < stringPartDetailList.length; n++) {
        const currStringPartDetail = stringPartDetailList[n]
        const isNegPos = currStringPartDetail.operator === 3 || currStringPartDetail.operator === 4
        if (isNegPos && (prevStringPartDetail === null || auxiliaryJudgmentNegPos(prevStringPartDetail.operator))) {
            // 把加减号 变为 正负号
            currStringPartDetail.operator = currStringPartDetail.operator === 3 ? 7 : 8
        }
        prevStringPartDetail = currStringPartDetail
    }
    return null
}

function auxiliaryJudgmentNegPos(operator: number) {
    const e = operatorList[operator]
    // 左括号（ 或 单双目运算符 并上 左联
    return e && (operator === 1 || e.degree === 2 || (e.degree === 1 && e.associativity === associativity.LEFT))
}

function onceCalculate(operatorDetailIndex: number, numList: number[], oldValue?: number) {
    if (numList.length < operatorList[operatorDetailIndex].degree) {
        return CalculateState.EXPECTED_MORE_ARGUMENTS
    }
    switch (operatorDetailIndex) {
        case operatorListIndex.NONE:
        case operatorListIndex.PAREN_L:
        case operatorListIndex.PAREN_R:
            return CalculateState.UNEXPECTED_TYPE
        case operatorListIndex.ADD:
        case operatorListIndex.SUBTRACT:
        case operatorListIndex.MULTIPLY:
        case operatorListIndex.DIVIDE:
        case operatorListIndex.EXPONENT: {
            const r = numList.pop()!,
                i = numList.pop()!
            switch (operatorDetailIndex) {
                case operatorListIndex.ADD:
                    numList.push(i + r)
                    break
                case operatorListIndex.SUBTRACT:
                    numList.push(i - r)
                    break
                case operatorListIndex.MULTIPLY:
                    numList.push(i * r)
                    break
                case operatorListIndex.DIVIDE:
                    numList.push(i / r)
                    break
                case operatorListIndex.EXPONENT: {
                    const n = Math.pow(i, r)
                    if (isNaN(n)) {
                        return CalculateState.IMAGINARY
                    }
                    numList.push(n)
                    break
                }
            }
            break
        }
        case operatorListIndex.UNARY_PLUS:
        case operatorListIndex.PIXELS:
            break
        case operatorListIndex.UNARY_MINUS: {
            numList.push(-numList.pop()!)
            break
        }
        case operatorListIndex.PERCENT: {
            if (!oldValue && oldValue !== 0) {
                return CalculateState.NO_CURRENT_VALUE
            }
            numList.push((oldValue * numList.pop()!) / 100)
            break
        }
        case operatorListIndex.X: {
            if (!oldValue && oldValue !== 0) {
                return CalculateState.NO_CURRENT_VALUE
            }
            numList.push(oldValue * numList.pop()!)
            break
        }
    }
    return CalculateState.SUCCESS
}

export class CustomCalculateError extends Error {
    public errorInfo: { type: number; [key: string]: any } | null
    constructor(e: any, o: { type: number; [key: string]: any } | null) {
        super(e)
        this.errorInfo = o
    }
}
