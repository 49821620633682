/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import type { HTMLAttributes } from 'react'
import { useCallback, useMemo } from 'react'
import { usePosition } from '../../../utils/use-position'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { Value } from '../../atom/inputs/utils/type'
import type { BlendContentProps } from '../blend/blend-content'
import { ColorPanelProps } from '../color-interaction/type'
import { PaintStructProps } from './paint-struct'

export interface PaintProps
    extends Pick<
            BlendContentProps,
            'onChangeColor' | 'onChangeOpacity' | 'onChangeImagePaint' | 'onChangeColorStops' | 'onChangeTransform'
        >,
        HTMLAttributes<HTMLDivElement>,
        Pick<PaintStructProps, 'paint' | 'colorSpace' | 'selected' | 'isMini' | 'disabled' | 'onClickSub'> {
    isOpen?: boolean
    variables?: Wukong.DocumentProto.ISingleVariable[]
    onChangeModalVisible?: (visible: boolean) => void
    onChangeBlendMode?: (blendMode: Wukong.DocumentProto.BlendMode) => void
    onChangeVisible: (visible: boolean) => void
    onDetachColorVar?: () => void
    onChangeColorVar: (colorVar: Wukong.DocumentProto.IVariableAliasData) => void
    onChangePaintType: ColorPanelProps['onChangePaintType']
}

export function usePaint(props: PaintProps, outRef: React.Ref<HTMLDivElement>) {
    const { paint, isOpen, variables, onChangeVisible, onChangeOpacity, onChangeModalVisible, onDetachColorVar } = props
    const { position, reactRef, htmlRef, setPosition } = usePosition({ isOpen, outRef })

    const colorVar = useMemo(() => {
        if (paint.colorVar && variables?.length) {
            return variables.find((v) => v.id === paint.colorVar?.value.alias)
        }
    }, [paint.colorVar, variables])

    const switchModalFalse = useCallback(() => {
        onChangeModalVisible?.(false)
    }, [onChangeModalVisible])

    const switchModalState = useCallback(() => {
        if (!isOpen) {
            setPosition(htmlRef.current?.getBoundingClientRect())
        }
        onChangeModalVisible?.(!isOpen)
    }, [htmlRef, isOpen, onChangeModalVisible, setPosition])

    const onChangePaintOpacity = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value === 'number') {
                onChangeOpacity?.(value / 100, options)
            }
        },
        [onChangeOpacity]
    )

    const onChangePaintVisible = useCallback(() => {
        onChangeModalVisible?.(false)
        onChangeVisible?.(!paint.visible)
    }, [onChangeModalVisible, onChangeVisible, paint.visible])

    const onClickDetachColorVar = useCallback(() => {
        onDetachColorVar?.()
        // 分离变量时，关闭颜色弹窗
        switchModalFalse()
    }, [onDetachColorVar, switchModalFalse])

    return {
        switchModalFalse,
        switchModalState,
        onChangePaintOpacity,
        onChangePaintVisible,
        onClickDetachColorVar,
        position,
        reactRef,
        paint,
        colorVar,
    }
}
