import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Pages: '页面',
    'Find…': '查找',
    OpenPageList: '展开页面列表',
    ClosePageList: '收起页面列表',
} as const

export const enTranslation = {
    Pages: 'Pages',
    'Find…': 'Find…',
    OpenPageList: 'Expand page list',
    ClosePageList: 'Collapse page list',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
