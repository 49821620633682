import { computed } from 'ccstate'
import { traceable } from '../../../../util/src/ccstate-helper/traceable'
import { getDocReadonly } from '../utils/doc-readonly'
import { ticket$ } from './ticket'

// 从 ticket 中获取 doc 的只读状态
export const isDocReadonly$ = traceable(
    'hulh01@kanyun.com',
    computed(async (get) => {
        const ticket = await get(ticket$)

        if (!ticket) {
            return undefined
        }

        return getDocReadonly(ticket.role)
    })
)
