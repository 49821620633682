import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    PluginCreateSuccess: '插件创建成功',
    YouCan: '你可以根据 ',
    DeveloperDocument: '{{name}}开发者文档',
    StartDeveloping: '，开始开发你的插件。',
    ViewLocalFile: '查看本地文件',
    Done: '完成',
} as const

export const enTranslation = {
    PluginCreateSuccess: 'Plugin created successfully',
    YouCan: 'You can start developing your plugin by following ',
    DeveloperDocument: 'the {{name}} Developer Guide',
    StartDeveloping: '.',
    ViewLocalFile: 'View local files',
    Done: 'Done',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
