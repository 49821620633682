/**
 * @owner: loushuangzhanbj@kanyun.com
 */
import { isNil } from 'lodash-es'
import * as React from 'react'
import { HTMLAttributes, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { BottomScrollArrow, TopScrollArrow } from '../scroll-arrow/arrow'
import { useScrollArrow } from '../scroll-arrow/use-scroll-arrow'
import style from './popup.module.less'
import type { MinDistanceNearEdge, PopupPosition, RectContainer, RectContent } from './type'
import { calculatePopupPosition, overflowAdjust } from './utils'

export interface PopupProps extends HTMLAttributes<HTMLDivElement> {
    trigger?: JSX.Element
    handle?: HTMLElement | null
    isOpen?: boolean
    position?: PopupPosition
    needTail?: boolean
    hiddenTail?: boolean
    needModal?: boolean
    rectContainer?: RectContainer // 容器的位置默认由trigger|handle计算，也可以直接指定
    subMenuItem?: boolean
    delayCalculation?: boolean
    minDistanceNearEdge?: MinDistanceNearEdge
    arrowBorderWidth?: number
    contentClassName?: string
    skipOverflowAdjust?: boolean
    onClose?: () => void
    onPopupContentClick?: () => void
    dataTestId?: string
}

const gap = 34
const defaultMinDistanceNearEdge: MinDistanceNearEdge = [8, 8, 8, 8]

function _Popup(props: PopupProps, ref?: React.Ref<HTMLDivElement>) {
    const {
        className,
        children,
        trigger,
        handle,
        isOpen,
        needTail,
        hiddenTail = true,
        needModal,
        rectContainer,
        subMenuItem,
        delayCalculation,
        minDistanceNearEdge = defaultMinDistanceNearEdge,
        arrowBorderWidth = 10,
        onClose,
        onPopupContentClick,
        contentClassName,
        position = 'bottom center',
        dataTestId,
        skipOverflowAdjust,
        ...otherProps
    } = props
    const [arrowStyle, setArrowStyle] = useState<HTMLAttributes<HTMLDivElement>['style']>({ opacity: 0 })
    const [contentStyle, setContentStyle] = useState<HTMLAttributes<HTMLDivElement>['style']>({
        opacity: 0,
    })
    const [arrowDirectionClassName, setArrowDirectionClassName] = useState<string>()
    const contentRef = useRef<HTMLDivElement>(null)
    const triggerRef = useRef<any>(null)
    const scrollRef = useRef<HTMLDivElement>(null)
    const [open, setOpen] = useState<boolean>(false)
    const [contentMaxHeight, setContentMaxHeight] = useState<number>()
    const animationFrameId = useRef<number>(-1)

    const {
        topScrollArrowShow,
        bottomScrollArrowShow,
        setTopScrollArrowShow,
        setBottomScrollArrowShow,
        StartScrollUp,
        StopScrollUp,
        StartScrollDown,
        StopScrollDown,
        onScroll,
    } = useScrollArrow({ scrollRef })

    useEffect(() => {
        setOpen(!!isOpen)
        return () => {
            setOpen(false)
        }
    }, [isOpen])

    const calculate = useCallback(() => {
        const handleDOM = triggerRef.current ?? handle
        if (!handleDOM || !contentRef.current) return
        const container: RectContainer = rectContainer ?? handleDOM.getBoundingClientRect()
        const content: RectContent = contentRef.current.getBoundingClientRect()
        const offset = needTail ? arrowBorderWidth : 0
        const {
            contentPos,
            arrowPos,
            arrowDirectionClassName: _arrowDirectionClassName,
        } = calculatePopupPosition(position, container, content, offset)
        const { amendX, amendY } = overflowAdjust(
            position,
            content.width,
            content.height,
            contentPos.top!,
            contentPos.left!,
            minDistanceNearEdge,
            skipOverflowAdjust
        )
        if (!isNil(contentPos.left)) {
            contentPos.left += subMenuItem ? amendX : amendX
        }
        if (!isNil(contentPos.top)) {
            contentPos.top += subMenuItem ? amendY - 8 : amendY - 2
        }
        setContentStyle(contentPos)
        setArrowStyle(arrowPos)
        setArrowDirectionClassName(_arrowDirectionClassName)
    }, [
        handle,
        rectContainer,
        needTail,
        arrowBorderWidth,
        position,
        minDistanceNearEdge,
        skipOverflowAdjust,
        subMenuItem,
    ])

    const onClickModal = useCallback<React.MouseEventHandler<HTMLDivElement>>(
        (event) => {
            event.stopPropagation()
            onClose?.()
            setTopScrollArrowShow(false)
            setBottomScrollArrowShow(false)
            setContentMaxHeight(undefined)
        },
        [onClose, setBottomScrollArrowShow, setTopScrollArrowShow]
    )

    const adjustContextPosition = useCallback(() => {
        const contentElement = contentRef.current
        if (!open || !contentElement) {
            return
        }
        if (delayCalculation) {
            window.cancelAnimationFrame(animationFrameId.current)
            animationFrameId.current = window.requestAnimationFrame(() => {
                animationFrameId.current = window.requestAnimationFrame(() => {
                    calculate()
                })
            })
        } else {
            calculate()
        }
    }, [calculate, open, delayCalculation])

    const triggerJsx = useCallback(() => {
        return trigger ? React.cloneElement(trigger, { ref: triggerRef }) : null
    }, [trigger])

    const container = triggerRef.current ?? handle

    const resizeHandler = useCallback(() => {
        if (scrollRef.current) {
            const winHeight = window.innerHeight
            const { top } = scrollRef.current.getBoundingClientRect()
            const scrollHeight = scrollRef.current.scrollHeight
            if (top + scrollHeight + 16 > winHeight) {
                setContentMaxHeight(winHeight - top - 16)
                setBottomScrollArrowShow(true)
            } else {
                setBottomScrollArrowShow(false)
                setTopScrollArrowShow(false)
                setContentMaxHeight(undefined)
            }
        }
    }, [scrollRef, setBottomScrollArrowShow, setTopScrollArrowShow])
    useEffect(() => {
        resizeHandler()
        window.addEventListener('resize', resizeHandler)
        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [resizeHandler])

    useEffect(() => {
        setTimeout(() => {
            if (scrollRef.current && open) {
                if (scrollRef.current.scrollHeight > scrollRef.current.clientHeight) {
                    setBottomScrollArrowShow(true)
                }
            }
        })
    }, [open, setBottomScrollArrowShow])

    useLayoutEffect(() => {
        adjustContextPosition()
        // eslint-disable-next-line
    }, [children, adjustContextPosition])

    useEffect(() => {
        if (!open) {
            setTopScrollArrowShow(false)
            setBottomScrollArrowShow(false)
            setContentMaxHeight(undefined)
        }
    }, [open, setTopScrollArrowShow, setBottomScrollArrowShow])

    return (
        <>
            {triggerJsx()}
            {open && container
                ? ReactDOM.createPortal(
                      <div
                          className={`${style.popup} ${className ?? ''}`}
                          onMouseDown={(e) => e.stopPropagation()}
                          onClick={(e) => e.stopPropagation()}
                          onPointerDown={(e) => e.stopPropagation()}
                          {...otherProps}
                          ref={ref}
                      >
                          {needTail && !hiddenTail ? (
                              <div className={`${style.arrow} ${arrowDirectionClassName}`} style={arrowStyle}></div>
                          ) : null}
                          <div
                              className={`${style.content} ${contentClassName ?? ''}`}
                              style={contentStyle}
                              ref={contentRef}
                              data-testid={dataTestId}
                          >
                              <div
                                  ref={scrollRef}
                                  onClick={onPopupContentClick}
                                  onScroll={onScroll}
                                  className={style.items}
                                  style={{
                                      maxHeight: contentMaxHeight ?? `${window.document.body.clientHeight - gap}px`,
                                  }}
                              >
                                  {children}
                              </div>
                              <TopScrollArrow
                                  onMouseIn={StartScrollUp}
                                  onMouseOut={StopScrollUp}
                                  hidden={!topScrollArrowShow}
                              />
                              <BottomScrollArrow
                                  onMouseIn={StartScrollDown}
                                  onMouseOut={StopScrollDown}
                                  hidden={!bottomScrollArrowShow}
                              />
                          </div>
                          {needModal ? <div className={style.modal} onMouseDown={onClickModal}></div> : null}
                      </div>,
                      container
                  )
                : null}
        </>
    )
}

export const Popup = React.forwardRef(_Popup)
