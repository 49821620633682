import { translation } from './clipboard-service.translation'
/* eslint-disable no-restricted-imports */
import {
    CanvasClipboardCopyCommand,
    CanvasClipboardCopyPropertiesCommand,
    CanvasClipboardCopySelectionAsLinkCommand,
    CanvasClipboardCopySelectionAsPNGCommand,
    CanvasClipboardCopySelectionAsSVGCommand,
    CanvasClipboardCopySelectionAsTextCommand,
    CanvasClipboardCutCommand,
    CanvasClipboardPasteCommand,
    CanvasClipboardPasteHereCommand,
    CanvasClipboardPasteOverSelectionCommand,
    CanvasClipboardPastePropertiesCommand,
    CanvasClipboardPasteToReplaceCommand,
    GetDocReadonlyCommand,
    PreExportCommand,
    StartClipboardPaste,
    StartCopy,
    Wukong,
} from '@wukong/bridge-proto'
import { WKToast } from '../../../../../ui-lib/src'
import { sleep } from '../../../../../util/src'
import { TraceableAbortSignal } from '../../../../../util/src/abort-controller/traceable-abort-controller'
import { CommandInvoker } from '../../../document/command/command-invoker'
import { ImageDownloadContext } from '../../../document/command/image-download-context'
import { Bridge } from '../../../kernel/bridge/bridge'
import { WkCLog } from '../../../kernel/clog/wukong/instance'
import { WsPropertyChangeMessage } from '../../../kernel/notify/notify-message'
import { NotifyService } from '../../../kernel/notify/notify-service'
import { CheckDocumentCopyAccess } from '../../../kernel/request/accesses'
import { RequestResponseErrorHandler } from '../../../kernel/request/error-handler'
import { isFirefox } from '../../../kernel/util/ua'
import { ToastTestId, WK } from '../../../window'
import { focusViewTraceLog } from '../../service/focus-view/focus-view-trace-log'
import { PreExportService } from '../../service/pre-export-service'
import { PLAIN_TEXT_SLUG } from './clipboard-data'
import { ClipboardDataManager } from './clipboard-data-manager'
import { WKClipboardError, WKClipboardErrorType } from './clipboard-data-types'
import type {
    ClipboardEventFilter,
    ClipboardEventWithModifierKeys,
    IClipboardEventHandler,
} from './clipboard-event-handler'
import { ClipboardEventHandler } from './clipboard-event-handler'
import { parseFigmaClipboardSceneDataToExportedDocument } from './clipboard-figma'
import { ExecutableClipboardService } from './clipboard-service-interface'
import { extractErrorMessage } from './clipboard-util'
import { ClipboardWasmConnector } from './clipboard-wasm-connector'

export class ClipboardService implements ExecutableClipboardService {
    private clipboardEventHandler: IClipboardEventHandler
    private copyHasBeenProhibited = false

    destroy() {
        delete WK.experiment1
        this.clipboardEventHandler.destroy()
        this.wasmConnector.destroy()

        this.bridge.unbind(StartCopy)
        this.bridge.unbind(StartClipboardPaste)
    }

    constructor(
        private signal: TraceableAbortSignal,
        private clipboardDataManager: ClipboardDataManager,
        private commandInvoker: CommandInvoker,
        private wasmConnector: ClipboardWasmConnector,
        clipboardEventValidator: ClipboardEventFilter = () => true,
        private preExportService: PreExportService,
        private readonly bridge: Bridge,
        private docId: string,
        private notifyService: NotifyService,
        private imageDownloadContext: ImageDownloadContext
    ) {
        WK.experiment1 = async (content) => {
            return parseFigmaClipboardSceneDataToExportedDocument(this.commandInvoker, content, false)
        }
        this.destroy = this.destroy.bind(this)

        this.bridge.bind(StartCopy, (arg) => {
            this.copy(arg.type!)
        })
        this.bridge.bind(StartClipboardPaste, (arg) => {
            this.paste(arg.type!)
        })

        new CheckDocumentCopyAccess(this.docId).start().catch((e) => {
            const msg = RequestResponseErrorHandler(e)
            if (msg.status === 403) {
                this.copyHasBeenProhibited = true
            }
        })

        // 属性变更
        this.notifyService.onBusinessMessageChangeWithSignal(this.signal, (proto) => {
            if (proto.businessCode !== WsPropertyChangeMessage.code) {
                return
            }

            const body = WsPropertyChangeMessage.bodyType.decode(proto.payload)
            if (body.businessEntity?.entityType !== Wukong.NotifyProto.EntityType.DOC) {
                return
            }

            if (body.businessEntity?.entityId !== this.docId) {
                return
            }

            if (body.changedProperties.copyable !== undefined) {
                new CheckDocumentCopyAccess(this.docId)
                    .start()
                    .then(() => {
                        this.copyHasBeenProhibited = false
                    })
                    .catch((e) => {
                        const msg = RequestResponseErrorHandler(e)
                        if (msg.status === 403) {
                            this.copyHasBeenProhibited = true
                        }
                    })
            }
        })

        this.clipboardEventHandler = new ClipboardEventHandler(clipboardEventValidator, bridge)

        const onCopyEvent = (event: ClipboardEvent) => {
            if (!event.clipboardData) {
                return
            }
            const tId = WKToast.loading(`${translation('Duplicating')}...`, {
                duration: -1,
                delay: 500,
            })

            try {
                this.clipboardDataManager.keepClipboardEventForWritting(event, () => {
                    this.dispatchCopyCommand(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_NORMAL)
                        .then(() => {
                            WKToast.close(tId)
                        })
                        .catch((err) => {
                            this.handleCopyError(err, Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_NORMAL)
                        })
                })

                this.clipboardDataManager
                    .tryUpdateDataTransferItemsFromClipboardEvent(event)
                    .then(() => {
                        WKToast.close(tId)
                    })
                    .catch((err) => {
                        this.handleCopyError(err, Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_NORMAL)
                    })
            } catch (err) {
                this.handleCopyError(err, Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_NORMAL)
            } finally {
                WKToast.close(tId)
            }

            event.preventDefault()
        }

        this.clipboardEventHandler.onCopyEventWithSignal(this.signal, onCopyEvent)

        const onCutEvent = (event: ClipboardEvent) => {
            if (!event.clipboardData) {
                return
            }

            this.clipboardDataManager.keepClipboardEventForWritting(event, () => {
                this.dispatchCutCommand()
            })

            this.clipboardDataManager.tryUpdateDataTransferItemsFromClipboardEvent(event)

            event.preventDefault()
        }

        this.clipboardEventHandler.onCutEventWithSignal(this.signal, onCutEvent)

        const onPasteEvent = (e: ClipboardEventWithModifierKeys) => {
            const event = e.event
            event.preventDefault()

            // 对于 shift 粘贴，不走 paste 事件
            if (e.shiftKey) {
                return
            }

            const dataTransfer = event.clipboardData
            if (!dataTransfer) {
                return
            }

            const isAllFileImage = Array.prototype.every.call(dataTransfer.items, (item) => {
                return item.type.startsWith('image/') && !item.type.startsWith('image/svg')
            })
            let tId: string

            if (dataTransfer.types.toString() === 'Files' && isAllFileImage) {
                // 如果粘贴内容只有图片,2秒后出现{translation('AddingImage')}弹窗
                tId = WKToast.loading(`${translation('AddingImage')}...`, {
                    duration: -1,
                    delay: 2000,
                })
            } else {
                tId = WKToast.loading(`${translation('Pasting')}...`, {
                    duration: -1,
                    delay: 500,
                })
            }
            const postUpdateDataTransfer = async () => {
                await sleep(0)
                this.dispatchPasteCommand(Wukong.DocumentProto.ClipboardPasteType.CLIPBOARD_PASTE_TYPE_NORMAL)
                WKToast.close(tId)
            }

            this.clipboardDataManager
                .tryUpdateDataTransferForPasteEvent(event)
                .then(postUpdateDataTransfer)
                .catch((err) => {
                    this.handlePasteError(err, tId)
                })
        }

        this.clipboardEventHandler.onPasteEventWithSignal(this.signal, onPasteEvent)
    }

    private async innerCopy(type?: Wukong.DocumentProto.ClipboardCopyType) {
        const exportNum = this.imageDownloadContext.states.getState().exportImageNumState
        if (
            (type === Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_P_N_G ||
                type === Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_S_V_G) &&
            exportNum.isCanceled
        ) {
            return
        }
        // 不能在 wasm 侧 toast，同步时间内不能运行 JS 代码，会导致 WKToast 不显示
        let copyingToastId: string | null = null
        if (type === Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_P_N_G) {
            copyingToastId = WKToast.loading(`${translation('CopyingAs')} PNG`)
        } else if (type === Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_S_V_G) {
            copyingToastId = WKToast.loading(`${translation('CopyingAs')} SVG`)
        }
        await sleep(0)
        this.dispatchCopyCommand(type ?? Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_NORMAL)
            .then(async () => {
                await sleep(300)
                if (type === Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_P_N_G) {
                    WKToast.show(`${translation('CopiedAs')} PNG`)
                } else if (type === Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_S_V_G) {
                    WKToast.show(`${translation('CopiedAs')} SVG`)
                } else if (type === Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_LINK) {
                    WKToast.show(translation('LinkCopied'), { duration: 5000 })
                }
            })
            .catch((err) => {
                this.handleCopyError(err, type ?? Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_NORMAL)
            })
            .finally(() => {
                if (copyingToastId !== null) {
                    WKToast.close(copyingToastId)
                }
            })
    }

    registerHandlers = () => {
        this.clipboardEventHandler.registerHandlers()
    }

    copy = async (type?: Wukong.DocumentProto.ClipboardCopyType) => {
        if (
            (type === Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_P_N_G ||
                type === Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_S_V_G) &&
            isFirefox()
        ) {
            return
        }
        const isOffline = !navigator.onLine
        if (
            (type === Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_P_N_G ||
                type === Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_S_V_G) &&
            !isOffline
        ) {
            this.preExportService.setFn(async () => {
                await this.innerCopy(type)
            })
            // 旧的调用方式调用新的WASM_CALL时会报序列化错误，注意更新调用即可
            this.commandInvoker.DEPRECATED_invokeBridge(PreExportCommand)
        } else {
            await this.innerCopy(type)
        }
    }

    copyText = async (text: string) => {
        this.clipboardDataManager.writeClipboardItems([
            {
                type: PLAIN_TEXT_SLUG,
                content: text,
            },
        ])
    }

    cut = async () => {
        if (this.bridge.call(GetDocReadonlyCommand).value) {
            return
        }
        this.dispatchCutCommand()
    }

    paste = async (type?: Wukong.DocumentProto.ClipboardPasteType) => {
        WkCLog.throttleLog('WK_CLIPBOARD', {
            scope: 'paste-command',
            checkpoint: 'start',
        })
        if (this.bridge.call(GetDocReadonlyCommand).value) {
            return
        }

        const tId = WKToast.loading(`${translation('Pasting')}...`, { duration: -1, delay: 500 })
        await sleep(0)

        try {
            await this.clipboardDataManager.tryUpdateDataTransferFromClipboardApi()
            this.dispatchPasteCommand(type ?? Wukong.DocumentProto.ClipboardPasteType.CLIPBOARD_PASTE_TYPE_NORMAL)
            WKToast.close(tId)
        } catch (err) {
            this.handlePasteError(err, tId)
        }

        WkCLog.throttleLog('WK_CLIPBOARD', {
            scope: 'paste-command',
            checkpoint: 'end',
        })
    }

    updateDataTransferFromClipboardApi = () =>
        this.clipboardDataManager.tryUpdateDataTransferFromClipboardApi().catch(() => {})

    private dispatchCutCommand = () => {
        focusViewTraceLog('[ClipboardService] dispatch cut command')
        this.commandInvoker.DEPRECATED_invokeBridge(CanvasClipboardCutCommand)
        this.commandInvoker.commitUndo()
    }

    private dispatchCopyCommand = async (type: Wukong.DocumentProto.ClipboardCopyType) => {
        focusViewTraceLog('[ClipboardService] dispatch copy command')
        if (this.copyHasBeenProhibited) {
            throw new WKClipboardError(WKClipboardErrorType.ProhibitCopy, null)
        }
        switch (type) {
            case Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_PROPERTY:
                this.commandInvoker.DEPRECATED_invokeBridge(CanvasClipboardCopyPropertiesCommand)
                break
            case Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_TEXT:
                this.commandInvoker.DEPRECATED_invokeBridge(CanvasClipboardCopySelectionAsTextCommand)
                break
            case Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_P_N_G: {
                const asyncTask = this.wasmConnector.asyncTaskManager.newTask()
                this.commandInvoker.DEPRECATED_invokeBridge(CanvasClipboardCopySelectionAsPNGCommand, {
                    asyncId: asyncTask.id,
                })
                await asyncTask.waitAndDestroy()
                break
            }
            case Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_S_V_G:
                this.commandInvoker.DEPRECATED_invokeBridge(CanvasClipboardCopySelectionAsSVGCommand)
                break
            case Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_LINK:
                this.commandInvoker.DEPRECATED_invokeBridge(CanvasClipboardCopySelectionAsLinkCommand)
                break
            case Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_NORMAL:
            default:
                this.commandInvoker.DEPRECATED_invokeBridge(CanvasClipboardCopyCommand)
                break
        }
    }

    private dispatchPasteCommand = (type: Wukong.DocumentProto.ClipboardPasteType) => {
        focusViewTraceLog('[ClipboardService] dispatch paste command')
        switch (type) {
            case Wukong.DocumentProto.ClipboardPasteType.CLIPBOARD_PASTE_TYPE_NORMAL:
                this.commandInvoker.DEPRECATED_invokeBridge(CanvasClipboardPasteCommand, {
                    matchStyle: false,
                })
                break
            case Wukong.DocumentProto.ClipboardPasteType.CLIPBOARD_PASTE_TYPE_TEXT_MATCH_STYLE:
                this.commandInvoker.DEPRECATED_invokeBridge(CanvasClipboardPasteCommand, {
                    matchStyle: true,
                })
                break
            case Wukong.DocumentProto.ClipboardPasteType.CLIPBOARD_PASTE_TYPE_PROPERTY:
                this.commandInvoker.DEPRECATED_invokeBridge(CanvasClipboardPastePropertiesCommand)
                break
            case Wukong.DocumentProto.ClipboardPasteType.CLIPBOARD_PASTE_TYPE_PASTE_HERE:
                this.commandInvoker.DEPRECATED_invokeBridge(CanvasClipboardPasteHereCommand)
                break
            case Wukong.DocumentProto.ClipboardPasteType.CLIPBOARD_PASTE_TYPE_PASTE_TO_REPLACE:
                this.commandInvoker.DEPRECATED_invokeBridge(CanvasClipboardPasteToReplaceCommand)
                break
            case Wukong.DocumentProto.ClipboardPasteType.CLIPBOARD_PASTE_TYPE_PASTE_OVER_SELECTION:
                this.commandInvoker.DEPRECATED_invokeBridge(CanvasClipboardPasteOverSelectionCommand)
                break
            default:
                return
        }
        this.commandInvoker.commitUndo()
    }

    private handleCopyError = (err: unknown, copyType: Wukong.DocumentProto.ClipboardCopyType) => {
        const handleAndReportErrorInCommon = () => {
            const msg = err instanceof Error ? err.message : `${err}`
            WkCLog.log('WK_COPY_ERROR', { msg })
            if (copyType === Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_P_N_G) {
                WKToast.error(translation('CopyPNGFailed'))
            } else {
                WKToast.error(translation('CopyFailed'))
            }
        }

        if (err) {
            if (err instanceof WKClipboardError) {
                switch (err.type) {
                    case WKClipboardErrorType.CopyImageLimitSize:
                        WKToast.error(translation('CopyPNGFailedByLimit'), {
                            duration: -1,
                            firstButton: { type: 'x', dataTestId: ToastTestId.CloseButton },
                        })
                        break
                    case WKClipboardErrorType.ProhibitCopy:
                        WKToast.show(translation('ProhibitCopy'))
                        break
                    case WKClipboardErrorType.NavigatorApi: {
                        handleAndReportErrorInCommon()
                        break
                    }
                }
            } else {
                handleAndReportErrorInCommon()
            }
        }
    }

    private handlePasteError = (err: unknown, toastId: string | null) => {
        if (toastId !== null) {
            WKToast.close(toastId)
        }
        if (err) {
            console.error(err)
            WKToast.error(translation('PasteFailed'), { dataTestIds: { toast: ToastTestId.ErrorMessage } })

            WkCLog.throttleLog('WK_CLIPBOARD', {
                scope: 'paste-error',
                error: extractErrorMessage(err),
            })
        }
    }
}
