import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    PleaseUpdate: '{{ModeName}} 无法作用于一些图层，请检查组件库更新',
    PleasePublish: '{{ModeName}} 无法作用于一些图层，请重新发布组件库',
    LearnMore: '了解更多',
    CheckUpdate: '检查更新',
    ModeDeleted: '已删除的模式',
} as const

export const enTranslation = {
    PleaseUpdate: '{{ModeName}} cannot be applied to some layers. Check library updates.',
    PleasePublish: '{{ModeName}} cannot be applied to some layers.  Republish the library.',
    LearnMore: 'Learn more',
    CheckUpdate: 'Check updates',
    ModeDeleted: 'Deleted mode',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
