import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Starter: '基础版',
    Free: '免费',
    CurrentPlan: '当前版本',
    ChooseStarter: '选择基础版',
} as const

export const enTranslation = {
    Starter: 'Starter',
    Free: 'Free',
    CurrentPlan: 'Current Plan',
    ChooseStarter: 'Choose Starter',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
