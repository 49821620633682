/* eslint-disable no-console */
import { MarkEvalJsBeginCommand, MarkEvalJsEndCommand } from '@wukong/bridge-proto'
import { WukongEditor } from '../editor'
import { IBasicBridge } from '../kernel/bridge/basic-bridge'
import { BaseVM, CallResult, DefinePropDescriptor, Handle, VMFunction } from './vm-interface'

const JS_TYPE = ['unknown', 'undefined', 'null', 'boolean', 'number', 'string', 'symbol', 'object', 'function']

export class CppVM extends BaseVM {
    vmType = 'cppVM' as const
    private isInFlushPendingMicroTasks = false
    private jsFunctionId = 1
    private jsFunctionMap = new Map<number, { name: string; func: VMFunction }>()
    private setupConsole!: SetupConsole
    private setupTimer!: SetupTimer
    private promiseFactory!: number

    constructor(private native: WukongEditor, private bridge: IBasicBridge) {
        super()
        this.native._qjs_createNewJsContext()
        this.native.qjs_callFunctionBack = (thisPtr: number, args: number[], funcId: number, hasException: number) => {
            const func = this.jsFunctionMap.get(funcId)
            if (!func) {
                return this.undefined
            }

            while (args.length < func.func.length) {
                args.push(this.undefined)
            }

            try {
                const ret = func.func.apply(thisPtr, args)
                if (ret === undefined) {
                    return this.undefined
                }
                return ret as number
            } catch (error: any) {
                let errorMessage = ''
                if (error?.message) {
                    errorMessage = `: ${error.message}`
                }
                this.native.HEAPU8[hasException] = 1
                return this.newString(`in ${func.name} ${errorMessage}`)
            }
        }

        this.setupConsole = new SetupConsole(this)
        this.setupTimer = new SetupTimer(this)
        this.promiseFactory = this.evalCodeRaw(`(Promise => () => {
            let resolve, reject, promise = new Promise((res, rej) => {
              resolve = res, reject = rej;
            });
            return {resolve, reject, promise};
          })(Promise)`)
        this.retainHandle(this.promiseFactory)
    }

    override destroy() {
        super.destroy()
        this.setupTimer.destroy()
        this.native._qjs_destroyJsContext()
    }

    flushPendingMicroTasks() {
        if (!this.isInFlushPendingMicroTasks) {
            this.isInFlushPendingMicroTasks = true
            Promise.resolve().then(() => {
                this.isInFlushPendingMicroTasks = false
                this.bridge.call(MarkEvalJsBeginCommand)
                this.native._qjs_flushMicrotasks()
                this.bridge.call(MarkEvalJsEndCommand)
            })
        }
    }

    ptrToString(ptr: number) {
        const length = this.native.HEAP32[ptr >>> 2]
        let text = ''
        for (let i = 0, p = (ptr + 4) >>> 1; i < length; i++, p++) {
            text += String.fromCharCode(this.native.HEAP16[p])
        }
        this.native._free(ptr)
        return text
    }

    stringToPtr(str: string) {
        const ptr = this.native._malloc(4 + 2 * str.length)
        this.native.HEAP32[ptr >>> 2] = str.length
        for (let i = 0, p = (ptr + 4) >>> 1; i < str.length; i++, p++) {
            this.native.HEAP16[p] = str.charCodeAt(i)
        }
        return ptr
    }

    intArrayToPtr(arr: number[]) {
        const ptr = this.native._malloc(4 + 4 * arr.length)
        this.native.HEAP32[ptr >>> 2] = arr.length
        this.native.HEAP32.set(new Int32Array(arr), (ptr + 4) >>> 2)
        return ptr
    }

    ptrToIntArray(ptr: number) {
        const length = this.native.HEAP32[ptr >>> 2]
        const start = (ptr + 4) >>> 2
        const array = this.native.HEAP32.slice(start, start + length)
        this.native._free(ptr)
        return array
    }

    uint8ArrayToPtr(value: Uint8Array) {
        const ptr = this.native._malloc(4 + value.length)
        this.native.HEAP32[ptr >>> 2] = value.length
        this.native.HEAPU8.set(value, ptr + 4)
        return ptr
    }

    ptrToUint8Array(ptr: number) {
        const length = this.native.HEAP32[ptr >>> 2]
        const start = ptr + 4
        const array = this.native.HEAPU8.slice(start, start + length)
        this.native._free(ptr)
        return array
    }

    arrayBufferToPtr(value: ArrayBuffer) {
        const ptr = this.native._malloc(4 + value.byteLength)
        this.native.HEAP32[ptr >>> 2] = value.byteLength
        this.native.HEAPU8.set(new Uint8Array(value), ptr + 4)
        return ptr
    }

    ptrToArrayBuffer(ptr: number) {
        const length = this.native.HEAP32[ptr >>> 2]
        const start = ptr + 4
        const array = this.native.HEAPU8.slice(start, start + length)
        this.native._free(ptr)
        return array.buffer
    }

    get undefined(): number {
        this.flushPendingMicroTasks()
        return this.native._qjs_getUndefinedHandle()
    }
    get global(): number {
        this.flushPendingMicroTasks()
        return this.native._qjs_getGlobalHandle()
    }
    get null(): number {
        this.flushPendingMicroTasks()
        return this.native._qjs_getNullHandle()
    }

    override typeof(handle: number): string {
        this.flushPendingMicroTasks()
        const type = this.native._qjs_typeofHandle(handle)
        return JS_TYPE[type]
    }
    override isNumber(handle: number): boolean {
        this.flushPendingMicroTasks()
        return this.native._qjs_isNumber(handle)
    }
    override getNumber(handle: number): number {
        this.flushPendingMicroTasks()
        return this.native._qjs_getNumber(handle)
    }
    override newNumber(value: number): number {
        this.flushPendingMicroTasks()
        return this.native._qjs_newNumber(value)
    }
    override isString(handle: number): boolean {
        this.flushPendingMicroTasks()
        return this.native._qjs_isString(handle)
    }
    override getString(handle: number): string {
        this.flushPendingMicroTasks()
        return this.ptrToString(this.native._qjs_getString(handle))
    }
    override newString(value: string): number {
        this.flushPendingMicroTasks()
        return this.native._qjs_newString(this.stringToPtr(value))
    }
    override isBoolean(handle: number): boolean {
        this.flushPendingMicroTasks()
        return this.native._qjs_isBoolean(handle)
    }
    override getBoolean(handle: number): boolean {
        this.flushPendingMicroTasks()
        return !!this.native._qjs_getBoolean(handle)
    }
    override newBoolean(value: boolean): Handle {
        this.flushPendingMicroTasks()
        return this.native._qjs_newBoolean(value)
    }
    override toBoolean(value: number): boolean {
        this.flushPendingMicroTasks()
        return !!this.native._qjs_toBoolean(value)
    }
    override isArray(handle: number): boolean {
        this.flushPendingMicroTasks()
        return this.native._qjs_isArray(handle)
    }
    override newArray(): Handle {
        this.flushPendingMicroTasks()
        return this.native._qjs_newArray()
    }
    override isObject(handle: number): boolean {
        this.flushPendingMicroTasks()
        return this.native._qjs_isObject(handle)
    }
    override newObject(protoHandle?: number): number {
        this.flushPendingMicroTasks()
        if (protoHandle) {
            return this.native._qjs_newObjectWithPrototype(protoHandle)
        }
        return this.native._qjs_newObject()
    }
    override isFunction(handle: number): boolean {
        this.flushPendingMicroTasks()
        return this.native._qjs_isFunction(handle)
    }
    override isNull(handle: number): boolean {
        this.flushPendingMicroTasks()
        return this.native._qjs_isNull(handle)
    }
    override isUndefined(handle: number): boolean {
        this.flushPendingMicroTasks()
        return this.native._qjs_isUndefined(handle)
    }
    override isArrayBuffer(handle: number): boolean {
        this.flushPendingMicroTasks()
        return this.native._qjs_isArrayBuffer(handle)
    }
    override getArrayBuffer(handle: number): ArrayBuffer {
        this.flushPendingMicroTasks()
        return this.ptrToArrayBuffer(this.native._qjs_getArrayBuffer(handle))
    }
    override newArrayBuffer(value: ArrayBuffer): number {
        this.flushPendingMicroTasks()
        return this.native._qjs_newArrayBuffer(this.arrayBufferToPtr(value))
    }
    override isUint8Array(handle: number): boolean {
        this.flushPendingMicroTasks()
        return this.native._qjs_isUint8Array(handle)
    }
    override getUint8Array(handle: number): Uint8Array {
        this.flushPendingMicroTasks()
        return this.ptrToUint8Array(this.native._qjs_getUint8Array(handle))
    }
    override newUint8Array(value: Uint8Array): number {
        this.flushPendingMicroTasks()
        return this.native._qjs_newUint8Array(this.uint8ArrayToPtr(value))
    }
    override isEqual(left: number, right: number): boolean {
        this.flushPendingMicroTasks()
        return this.native._qjs_isEqual(left, right)
    }
    override newFunction(name: string, func: VMFunction): number {
        this.flushPendingMicroTasks()
        const id = this.jsFunctionId++
        this.jsFunctionMap.set(id, { name, func })
        return this.native._qjs_newFunction(this.stringToPtr(name), id)
    }
    override newPromise(): { promise: Handle; resolve: (value: Handle) => void; reject: (reason: Handle) => void } {
        const promiseObjResult = this.callFunction(this.promiseFactory, this.null)
        if (promiseObjResult.type === 'FAILURE') {
            throw new Error('Failed to create promise')
        }
        const promiseObj = promiseObjResult.handle as number
        const resolveHandle = this.getProp(promiseObj, 'resolve')
        const rejectHandle = this.getProp(promiseObj, 'reject')
        let isSettled = false

        // Helper to cleanup handles after promise settles
        const cleanupHandles = () => {
            if (!isSettled) {
                isSettled = true
                this.releaseHandle(resolveHandle)
                this.releaseHandle(rejectHandle)
            }
        }

        // Retain handles until promise settles
        this.retainHandle(resolveHandle)
        this.retainHandle(rejectHandle)

        return {
            promise: this.getProp(promiseObj, 'promise'),
            resolve: (value) => {
                if (!isSettled) {
                    this.callFunction(resolveHandle, this.null, value as number)
                    cleanupHandles()
                }
            },
            reject: (reason) => {
                if (!isSettled) {
                    this.callFunction(rejectHandle, this.null, reason as number)
                    cleanupHandles()
                }
            },
        }
    }
    override getProp(objHandle: number, prop: string): number {
        this.flushPendingMicroTasks()
        return this.native._qjs_getPropStr(objHandle, this.stringToPtr(prop))
    }
    override setProp(objHandle: number, prop: string, valueHandle: number): void {
        this.flushPendingMicroTasks()
        this.native._qjs_setPropStr(objHandle, this.stringToPtr(prop), valueHandle)
    }

    getProto(handle: number): number {
        this.flushPendingMicroTasks()
        return this.native._qjs_getProto(handle)
    }

    override defineProp(objHandle: number, prop: string, descriptor: DefinePropDescriptor): void {
        this.flushPendingMicroTasks()
        if ('value' in descriptor && descriptor.value !== undefined) {
            this.native._qjs_definePropStr(
                objHandle,
                this.stringToPtr(prop),
                descriptor.value ? (descriptor.value as number) : this.undefined,
                this.undefined,
                this.undefined,
                descriptor.configurable ?? false,
                descriptor.enumerable ?? false,
                true
            )
        } else {
            this.native._qjs_definePropStr(
                objHandle,
                this.stringToPtr(prop),
                this.undefined,
                descriptor.get ? this.newFunction('get_' + prop, descriptor.get) : this.undefined,
                descriptor.set ? this.newFunction('set_' + prop, descriptor.set) : this.undefined,
                descriptor.configurable ?? false,
                descriptor.enumerable ?? false,
                false
            )
        }
    }
    override callFunction(funcHandle: number, thisArgHandle: number, ...argHandlesParam: number[]): CallResult {
        this.flushPendingMicroTasks()
        const argHandles = argHandlesParam ? argHandlesParam : []
        const argPtrs = this.intArrayToPtr(argHandles)

        try {
            const ret = this.native._qjs_callFunction(funcHandle, thisArgHandle, argPtrs)
            if (ret) {
                return { type: 'SUCCESS', handle: ret }
            }
            return { type: 'FAILURE', error: ret }
        } catch (e) {
            console.error(e)
            return { type: 'FAILURE', error: e }
        }
    }
    override getKeys(objHandle: number): string[] {
        this.flushPendingMicroTasks()
        const ptr = this.native._qjs_getOwnKeys(objHandle)
        const keyHandles = this.ptrToIntArray(ptr)
        const keys = Array.from(keyHandles).map((keyHandle) => this.ptrToString(keyHandle))
        return keys
    }

    evalCodeRaw(code: string): number {
        this.flushPendingMicroTasks()
        return this.native._qjs_evalCode(this.stringToPtr(code))
    }

    override evalCode(code: string): CallResult {
        this.flushPendingMicroTasks()
        const ptr = this.stringToPtr(code)
        try {
            const ret = this.native._qjs_evalCode(ptr)
            if (ret) {
                return { type: 'SUCCESS', handle: ret }
            }
            return { type: 'FAILURE', error: ret }
        } catch (e) {
            console.error(e)
            return { type: 'FAILURE', error: e }
        }
    }
    override retainHandle(handle: number): void {
        this.flushPendingMicroTasks()
        this.native._qjs_retainHandle(handle)
    }
    override releaseHandle(handle: number): void {
        this.flushPendingMicroTasks()
        this.native._qjs_releaseHandle(handle)
    }
}

interface HandleToValueMap {
    get: (handle: number) => number | undefined
    set: (handle: number, value: any) => void
}

class SetupConsole {
    getOwnPropertyDescriptors!: number
    hasOwnProperty!: number
    newMap!: number
    mapGet!: number
    mapSet!: number
    valuesToHandles = new Map<any, number>()
    constructor(private vm: CppVM) {
        this.initializeVMFunctions()
        this.setupConsoleFunctions()
    }

    setupConsoleFunctions() {
        const consoleFunctions = {
            log: console.log.bind(console),
            error: console.error.bind(console),
            warn: console.warn.bind(console),
            info: console.info.bind(console),
            clear: console.clear.bind(console),
            assert: console.assert.bind(console),
        }

        const consoleObject = this.vm.newObject()
        this.vm.setProp(this.vm.global, 'console', consoleObject)
        for (const [key, func] of Object.entries(consoleFunctions)) {
            this.vm.setProp(
                consoleObject,
                key,
                this.vm.newFunction(key, (...args) => {
                    try {
                        func(...this.cloneArguments(args as number[]))
                    } catch (error) {
                        console.error(error)
                        throw error
                    }
                })
            )
        }
    }

    cloneArguments(args: number[]) {
        const handleToValueMap = this.createHandleToValueMap()
        return args.map((arg) => this.cloneValue(handleToValueMap, arg))
    }

    initializeVMFunctions() {
        this.getOwnPropertyDescriptors = this.vm.evalCodeRaw('Object.getOwnPropertyDescriptors')
        this.hasOwnProperty = this.vm.evalCodeRaw('Object.hasOwnProperty')
        this.newMap = this.vm.evalCodeRaw('(Map => () => new Map)(Map)')
        this.mapGet = this.vm.evalCodeRaw('Map.prototype.get')
        this.mapSet = this.vm.evalCodeRaw('Map.prototype.set')

        this.vm.retainHandle(this.getOwnPropertyDescriptors)
        this.vm.retainHandle(this.hasOwnProperty)
        this.vm.retainHandle(this.newMap)
        this.vm.retainHandle(this.mapGet)
        this.vm.retainHandle(this.mapSet)
    }

    createHandleToValueMap(): HandleToValueMap {
        const mapHandleResult = this.vm.callFunction(this.newMap, this.vm.null)
        if (mapHandleResult.type === 'FAILURE') {
            throw new Error('Failed to create map')
        }
        const mapHandle = mapHandleResult.handle as number
        const valuesArray: any[] = []

        return {
            get: (handle: number) => {
                const indexHandleResult = this.vm.callFunction(this.mapGet, mapHandle, handle)
                if (indexHandleResult.type === 'FAILURE') {
                    throw new Error('Failed to get index')
                }
                const indexHandle = indexHandleResult.handle as number
                return this.vm.isNumber(indexHandle) ? valuesArray[this.vm.getNumber(indexHandle)] : undefined
            },
            set: (handle: number, value: any) => {
                const index = valuesArray.length
                this.vm.callFunction(this.mapSet, mapHandle, handle, this.vm.newNumber(index))
                valuesArray.push(value)
            },
        }
    }

    cloneUint8Array(handleToValueMap: HandleToValueMap, valueHandle: number) {
        const uint8Array = this.vm.getUint8Array(valueHandle)
        handleToValueMap.set(valueHandle, uint8Array)
        return uint8Array
    }

    cloneFunction(handleToValueMap: HandleToValueMap, valueHandle: number) {
        let functionName = ''

        try {
            functionName = this.vm.getString(this.vm.getProp(valueHandle, 'name'))
        } catch (error) {}

        const functionBody =
            /^[a-zA-Z_$][a-zA-Z_$0-9]*$/.test(functionName) &&
            !new Set([
                'arguments',
                'await',
                'break',
                'case',
                'catch',
                'class',
                'const',
                'continue',
                'debugger',
                'default',
                'delete',
                'do',
                'else',
                'enum',
                'eval',
                'export',
                'extends',
                'false',
                'finally',
                'for',
                'function',
                'if',
                'implements',
                'import',
                'in',
                'instanceof',
                'interface',
                'let',
                'new',
                'null',
                'package',
                'private',
                'protected',
                'public',
                'return',
                'static',
                'super',
                'switch',
                'this',
                'throw',
                'true',
                'try',
                'typeof',
                'var',
                'void',
                'while',
                'with',
                'yield',
            ]).has(functionName)
                ? `return function ${functionName}() {}`
                : 'return function() {}'

        // eslint-disable-next-line @typescript-eslint/no-implied-eval
        const clonedFunction = Function(functionBody)()
        handleToValueMap.set(valueHandle, clonedFunction)
        return clonedFunction
    }

    createGetterFunction(getterHandle: number, objectHandle: number) {
        const vm = this.vm
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const clonerInstance = this

        return function () {
            const thisHandle = clonerInstance.valuesToHandles.get(this) || objectHandle
            const resultHandle = vm.callFunction(getterHandle, thisHandle)
            if (resultHandle.type === 'FAILURE') {
                throw new Error('Failed to get result')
            }
            const handleToValueMap = clonerInstance.createHandleToValueMap()

            return clonerInstance.cloneValue(handleToValueMap, resultHandle.handle as number)
        }
    }

    cloneValue(handleToValueMap: HandleToValueMap, valueHandle: number) {
        const cachedValue = handleToValueMap.get(valueHandle)
        if (cachedValue !== undefined) return cachedValue

        switch (this.vm.typeof(valueHandle)) {
            case 'undefined':
                return
            case 'boolean':
                return this.vm.getBoolean(valueHandle)
            case 'number':
                return this.vm.getNumber(valueHandle)
            case 'string':
                return this.vm.getString(valueHandle)
            default:
                return this.cloneComplexValue(handleToValueMap, valueHandle)
        }
    }

    cloneComplexValue(handleToValueMap: HandleToValueMap, valueHandle: number) {
        const vm = this.vm

        if (vm.isNull(valueHandle)) return null
        if (vm.isUint8Array(valueHandle)) return this.cloneUint8Array(handleToValueMap, valueHandle)
        if (vm.isFunction(valueHandle)) return this.cloneFunction(handleToValueMap, valueHandle)
        if (vm.isArray(valueHandle)) return this.cloneArray(handleToValueMap, valueHandle)

        return this.cloneObjectWithProperties(handleToValueMap, valueHandle)
    }

    cloneArray(handleToValueMap: HandleToValueMap, valueHandle: number) {
        const vm = this.vm
        const length = vm.getNumber(vm.getProp(valueHandle, 'length'))
        const array: any[] = []

        handleToValueMap.set(valueHandle, array)

        for (let index = 0; index < length; index++) {
            array.push(this.cloneValue(handleToValueMap, vm.getProp(valueHandle, index.toString())))
        }

        return array
    }

    cloneObjectWithProperties(handleToValueMap: HandleToValueMap, objectHandle: number) {
        const vm = this.vm
        const descriptorsHandleResult = vm.callFunction(this.getOwnPropertyDescriptors, vm.null, objectHandle)
        if (descriptorsHandleResult.type === 'FAILURE') {
            throw new Error('Failed to get descriptors')
        }
        const descriptorsHandle = descriptorsHandleResult.handle as number
        const clonedObject: any = {}

        handleToValueMap.set(objectHandle, clonedObject)
        vm.retainHandle(objectHandle)
        this.valuesToHandles.set(clonedObject, objectHandle)

        const prototypeHandle = vm.getProto(objectHandle)
        Object.setPrototypeOf(clonedObject, this.cloneValue(handleToValueMap, prototypeHandle))

        for (const key of vm.getKeys(descriptorsHandle)) {
            const descriptorHandle = vm.getProp(descriptorsHandle, key)
            const isEnumerable = vm.getBoolean(vm.getProp(descriptorHandle, 'enumerable'))
            const getterHandle = vm.getProp(descriptorHandle, 'get')
            const setterHandle = vm.getProp(descriptorHandle, 'set')
            const hasGetter = !vm.isUndefined(getterHandle)
            const hasSetter = !vm.isUndefined(setterHandle)

            if (!hasGetter && !hasSetter) {
                Object.defineProperty(clonedObject, key, {
                    value: this.cloneValue(handleToValueMap, vm.getProp(objectHandle, key)),
                    enumerable: isEnumerable,
                })
                continue
            }

            const propertyDescriptor: PropertyDescriptor = { enumerable: isEnumerable }

            if (hasGetter) {
                vm.retainHandle(getterHandle)
                propertyDescriptor.get = this.createGetterFunction(getterHandle, objectHandle)
            }

            if (hasSetter) {
                propertyDescriptor.set = () => {}
            }

            Object.defineProperty(clonedObject, key, propertyDescriptor)
        }

        return clonedObject
    }
}

class SetupTimer {
    timeroutMap = new Map<number, { release: () => void; realId: number }>()
    intervalMap = new Map<number, { release: () => void; realId: number }>()
    timerId = 0

    constructor(private vm: CppVM) {
        this.vm.setProp(
            this.vm.global,
            'setTimeout',
            this.vm.newFunction('setTimeout', (callback, delay, ...args) => {
                const { release, callback: executeCallback } = this.createCallbackHandler(
                    callback as number,
                    args as number[]
                )
                const delayInMs = this.vm.getNumber(delay as number)
                const id = this.timerId++

                const realId = setTimeout(() => {
                    this.timeroutMap.delete(realId as any)
                    executeCallback()
                    release()
                }, delayInMs)

                this.timeroutMap.set(id, { release, realId: realId as any })
                return this.vm.newNumber(id)
            })
        )

        this.vm.setProp(
            this.vm.global,
            'clearTimeout',
            this.vm.newFunction('clearTimeout', (id) => {
                const numericId = this.vm.getNumber(id as number)
                const timeoutEntry = this.timeroutMap.get(numericId)
                if (timeoutEntry !== undefined) {
                    this.timeroutMap.delete(numericId)
                    clearTimeout(timeoutEntry.realId)
                    timeoutEntry.release()
                }
                return this.vm.undefined
            })
        )

        this.vm.setProp(
            this.vm.global,
            'setInterval',
            this.vm.newFunction('setInterval', (callback, interval, ...args) => {
                const { release, callback: executeCallback } = this.createCallbackHandler(
                    callback as number,
                    args as number[]
                )
                const intervalInMs = this.vm.getNumber(interval as number)
                const id = this.timerId++

                const realId = setInterval(executeCallback, intervalInMs)
                this.intervalMap.set(id, { release, realId: realId as any })
                return this.vm.newNumber(id)
            })
        )

        this.vm.setProp(
            this.vm.global,
            'clearInterval',
            this.vm.newFunction('clearInterval', (id) => {
                const numericId = this.vm.getNumber(id as number)
                const intervalEntry = this.intervalMap.get(numericId)
                if (intervalEntry !== undefined) {
                    this.intervalMap.delete(numericId)
                    clearInterval(intervalEntry.realId)
                    intervalEntry.release()
                }
                return this.vm.undefined
            })
        )
    }

    createCallbackHandler(callback: number, args: number[]) {
        if (this.vm.isFunction(callback)) {
            // Retain callback and args to prevent garbage collection
            this.vm.retainHandle(callback)
            this.vm.retainHandle(callback)
            args.forEach((arg) => this.vm.retainHandle(arg))

            let isReleased = false
            return {
                release: () => {
                    if (!isReleased && !this.vm.isDestroyed()) {
                        isReleased = true
                        this.vm.releaseHandle(callback)
                        args.forEach((arg) => this.vm.releaseHandle(arg))
                    }
                },
                callback: () => {
                    if (!isReleased && !this.vm.isDestroyed()) {
                        this.vm.callFunction(callback, this.vm.global, ...args)
                    }
                },
            }
        } else {
            throw new Error('Callback is not a function')
        }
    }

    destroy() {
        this.timeroutMap.forEach(({ realId }) => clearTimeout(realId))
        this.timeroutMap.clear()
        this.intervalMap.forEach(({ realId }) => clearInterval(realId))
        this.intervalMap.clear()
    }
}
