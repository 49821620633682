import { FC } from 'react'
import { IconCheckboxChecked } from '../../../svg-icon/16/common'

interface DatepickerMenuItemProps {
    checked: boolean
    name: string | number
    'data-testid'?: string
    onClick: () => void
}

export const DatepickerMenuItem: FC<DatepickerMenuItemProps> = (props) => {
    const { checked, name, onClick } = props
    return (
        <button
            data-testid={props['data-testid']}
            className="h-7 hover:bg-$wk-brand-7 hover:color-white items-center flex wk-text-12 bg-transparent border-none outline-none w-full box-border"
            onClick={onClick}
        >
            <IconCheckboxChecked className={`px-2 ${checked ? '' : 'opacity-0'}`} />
            <span>{name}</span>
        </button>
    )
}
