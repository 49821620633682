import { translation } from './align.translation'
/* eslint-disable no-restricted-imports */
import {
    AiAlignCommand,
    ArrangeAlignCommand,
    ArrangeDistributeCommand,
    TidyUpCommand,
    Wukong,
} from '@wukong/bridge-proto'
import classnames from 'classnames'
import { useCallback } from 'react'
import {
    DropdownV2,
    IconAiAlign,
    IconArrowDown12,
    IconDistributeHorizontalSpacing,
    IconDistributeVerticalSpacing,
    IconLayoutAlignHorizontalCenters,
    IconLayoutAlignLeft,
    IconLayoutAlignRight,
    IconLayoutAlignVerticalCenters,
    IconLayoutDown,
    IconLayoutTop,
    IconTidyUpDisabled,
    IconTidyUpHorizontal,
    IconTidyUpMultiDim,
    IconTidyUpVertical,
    Tooltip,
} from '../../../../../../ui-lib/src'
import { featureSwitchManager } from '../../../../kernel/switch/core'
import { dynamicSwitchService } from '../../../../main/service/dynamic-switch-service'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { IconButton } from '../../atom/button/icon-button'
import { getAlignModeName, getAlignModeShortCut } from '../../top-area/utils'
import style from './align.module.less'
import { ShortcutKey, shortcutLabelMap } from '../../../../kernel/interface/shortcut-key'

export function Align({ isSinglePanel }: { isSinglePanel: boolean }) {
    const cmd = useCommand()
    const arrangeState = useViewState('arrangeState')

    const setArrangeAlignMode = useCallback(
        (mode: Wukong.DocumentProto.AlignTypeCommandParam['type']) => {
            return () => {
                cmd.DEPRECATED_invokeBridge(ArrangeAlignCommand, {
                    type: mode,
                })
                cmd.commitUndo()
            }
        },
        [cmd]
    )

    const setArrangeDistributeMode = useCallback(
        (mode: Wukong.DocumentProto.DistributeTypeCommandParam['type']) => {
            cmd.DEPRECATED_invokeBridge(ArrangeDistributeCommand, {
                type: mode,
            })
            cmd.commitUndo()
        },
        [cmd]
    )

    const setTidyUp = useCallback(
        (mode: Wukong.DocumentProto.TidyUpCommandParam['type']) => {
            cmd.DEPRECATED_invokeBridge(TidyUpCommand, {
                type: mode,
            })
            cmd.commitUndo()
        },
        [cmd]
    )

    const setAiAlign = useCallback(() => {
        cmd.DEPRECATED_invokeBridge(AiAlignCommand, {
            alignType: Wukong.DocumentProto.AiAlignType.AI_ALIGN_TYPE_SINGLE_ELEMENT,
        })
    }, [cmd])

    return (
        <div
            className={classnames(style.grid, {
                [style.singlePanel]: isSinglePanel,
            })}
        >
            <Tooltip
                title={getAlignModeName(Wukong.DocumentProto.AlignType.ALIGN_TYPE_LEFT)}
                shortcut={getAlignModeShortCut(Wukong.DocumentProto.AlignType.ALIGN_TYPE_LEFT)}
            >
                <IconButton
                    className={style.svgColorAdjust}
                    selected={false}
                    icon={<IconLayoutAlignLeft />}
                    disabled={!arrangeState?.canUseAlign}
                    onClick={setArrangeAlignMode(Wukong.DocumentProto.AlignType.ALIGN_TYPE_LEFT)}
                    dataTestId="align-left"
                />
            </Tooltip>
            <Tooltip
                title={getAlignModeName(Wukong.DocumentProto.AlignType.ALIGN_TYPE_HORIZONTAL_CENTERS)}
                shortcut={getAlignModeShortCut(Wukong.DocumentProto.AlignType.ALIGN_TYPE_HORIZONTAL_CENTERS)}
            >
                <IconButton
                    className={style.svgColorAdjust}
                    selected={false}
                    icon={<IconLayoutAlignHorizontalCenters />}
                    disabled={!arrangeState?.canUseAlign}
                    onClick={setArrangeAlignMode(Wukong.DocumentProto.AlignType.ALIGN_TYPE_HORIZONTAL_CENTERS)}
                />
            </Tooltip>
            <Tooltip
                title={getAlignModeName(Wukong.DocumentProto.AlignType.ALIGN_TYPE_RIGHT)}
                shortcut={getAlignModeShortCut(Wukong.DocumentProto.AlignType.ALIGN_TYPE_RIGHT)}
            >
                <IconButton
                    className={style.svgColorAdjust}
                    selected={false}
                    icon={<IconLayoutAlignRight />}
                    disabled={!arrangeState?.canUseAlign}
                    onClick={setArrangeAlignMode(Wukong.DocumentProto.AlignType.ALIGN_TYPE_RIGHT)}
                />
            </Tooltip>
            <Tooltip
                title={getAlignModeName(Wukong.DocumentProto.AlignType.ALIGN_TYPE_TOP)}
                shortcut={getAlignModeShortCut(Wukong.DocumentProto.AlignType.ALIGN_TYPE_TOP)}
            >
                <IconButton
                    className={style.svgColorAdjust}
                    selected={false}
                    icon={<IconLayoutTop />}
                    disabled={!arrangeState?.canUseAlign}
                    onClick={setArrangeAlignMode(Wukong.DocumentProto.AlignType.ALIGN_TYPE_TOP)}
                />
            </Tooltip>
            <Tooltip
                title={getAlignModeName(Wukong.DocumentProto.AlignType.ALIGN_TYPE_VERTICAL_CENTERS)}
                shortcut={getAlignModeShortCut(Wukong.DocumentProto.AlignType.ALIGN_TYPE_VERTICAL_CENTERS)}
            >
                <IconButton
                    className={style.svgColorAdjust}
                    selected={false}
                    icon={<IconLayoutAlignVerticalCenters />}
                    disabled={!arrangeState?.canUseAlign}
                    onClick={setArrangeAlignMode(Wukong.DocumentProto.AlignType.ALIGN_TYPE_VERTICAL_CENTERS)}
                />
            </Tooltip>
            <Tooltip
                title={getAlignModeName(Wukong.DocumentProto.AlignType.ALIGN_TYPE_BOTTOM)}
                shortcut={getAlignModeShortCut(Wukong.DocumentProto.AlignType.ALIGN_TYPE_BOTTOM)}
            >
                <IconButton
                    className={style.svgColorAdjust}
                    selected={false}
                    icon={<IconLayoutDown />}
                    disabled={!arrangeState?.canUseAlign}
                    onClick={setArrangeAlignMode(Wukong.DocumentProto.AlignType.ALIGN_TYPE_BOTTOM)}
                />
            </Tooltip>
            <Tooltip title={translation('MoreOptions')} triggerRefKey="getTriggerElement">
                <DropdownV2.EmptySingleLevel
                    style={{ height: 'unset', pointerEvents: 'all' }}
                    triggerClassName={style.triggerClassName}
                    className={style.moreOption}
                    placement="bottom right"
                    disabled={!arrangeState?.canUseDistribute}
                    dataTestIds={{ triggerFocus: 'align-more-option' }}
                    label={
                        <>
                            <span className={`${style.svgColorAdjust} ${style.moreOption_left}`}>
                                {arrangeState?.tidyUpType ? (
                                    arrangeState.tidyUpType === Wukong.DocumentProto.TidyType.TIDY_TYPE_HORIZONTAL ? (
                                        <IconTidyUpHorizontal />
                                    ) : arrangeState.tidyUpType === Wukong.DocumentProto.TidyType.TIDY_TYPE_VERTICAL ? (
                                        <IconTidyUpVertical />
                                    ) : (
                                        <IconTidyUpMultiDim />
                                    )
                                ) : (
                                    <IconDistributeHorizontalSpacing />
                                )}
                            </span>
                            <span className={style.moreOption_right}>
                                <IconArrowDown12 />
                            </span>
                        </>
                    }
                    onChange={(val) => {
                        if (val.type === 'distribute') {
                            setArrangeDistributeMode(val.val)
                        } else if (val.type === 'aiAlign') {
                            setAiAlign()
                        } else {
                            setTidyUp(val.val)
                        }
                    }}
                >
                    <DropdownV2.EmptySingleLevel.Option
                        value={{
                            type: 'tidy',
                            val: arrangeState?.tidyUpType,
                        }}
                        forwardChildren={
                            arrangeState?.tidyUpType ? (
                                arrangeState.tidyUpType === Wukong.DocumentProto.TidyType.TIDY_TYPE_HORIZONTAL ? (
                                    <IconTidyUpHorizontal className={`${style.setSvg}`} />
                                ) : arrangeState.tidyUpType === Wukong.DocumentProto.TidyType.TIDY_TYPE_VERTICAL ? (
                                    <IconTidyUpVertical className={`${style.setSvg}`} />
                                ) : (
                                    <IconTidyUpMultiDim className={`${style.setSvg}`} />
                                )
                            ) : (
                                <IconTidyUpDisabled className={`${style.setSvg} ${style.svgDisabled}`} />
                            )
                        }
                        disabled={!arrangeState?.tidyUpType}
                        backwardIcon={shortcutLabelMap[ShortcutKey.TidyUp]}
                        dataTestId="tidy-up"
                    >
                        {translation('TidyUp')}
                    </DropdownV2.EmptySingleLevel.Option>
                    <DropdownV2.EmptySingleLevel.Option
                        value={{
                            type: 'distribute',
                            val: Wukong.DocumentProto.DistributeType.DISTRIBUTE_TYPE_HORIZONTAL_SPACING,
                        }}
                        forwardChildren={<IconDistributeHorizontalSpacing className={style.setSvg} />}
                        backwardIcon={shortcutLabelMap[ShortcutKey.DistributeHorizontalSpacing]}
                        dataTestId="distribute-horizontal-spacing"
                    >
                        {translation('DistributeHorizontal')}
                    </DropdownV2.EmptySingleLevel.Option>
                    <DropdownV2.EmptySingleLevel.Option
                        value={{
                            type: 'distribute',
                            val: Wukong.DocumentProto.DistributeType.DISTRIBUTE_TYPE_VERTICAL_SPACING,
                        }}
                        forwardChildren={<IconDistributeVerticalSpacing className={style.setSvg} />}
                        backwardIcon={shortcutLabelMap[ShortcutKey.DistributeVerticalSpacing]}
                    >
                        {translation('DistributeVertical')}
                    </DropdownV2.EmptySingleLevel.Option>
                    {(dynamicSwitchService.isFeatureEnabled('ai-align') ||
                        featureSwitchManager.isEnabled('ai-align')) && (
                        <DropdownV2.EmptySingleLevel.Option
                            value={{
                                type: 'aiAlign',
                            }}
                            forwardChildren={<IconAiAlign className={style.setSvg} />}
                            backwardIcon={shortcutLabelMap[ShortcutKey.AiAlign]}
                        >
                            AI {translation('Align')}
                        </DropdownV2.EmptySingleLevel.Option>
                    )}
                </DropdownV2.EmptySingleLevel>
            </Tooltip>
        </div>
    )
}
