import { BasisTranscoder } from './image-lib/basis-transcoder'
import { ImageLibWorker } from './image-lib/image-lib'
import { ImageLibContext } from './image-lib/types'
import { isJest } from './image-lib/utils'
import { TextureCompressionFormat } from './types'

export function tryInitBasisTranscoder(cx: ImageLibContext) {
    if (isJest()) {
        return
    }

    BasisTranscoder.get().ensureInitialized(cx.env.wasmDownloadPrefix)
}

export function transcodeKtx2Sync(data: Uint8Array, format: TextureCompressionFormat): Uint8Array {
    return BasisTranscoder.get().transcodeSync(data, format)
}

export function transcodeKtx2(
    cx: ImageLibContext,
    data: Uint8Array,
    format: TextureCompressionFormat
): Promise<Uint8Array> {
    return ImageLibWorker.get().transcodeKtx2(cx, data, format)
}
