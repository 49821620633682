import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Tip1: '存在同属性样式但未使用',
    Tip2: '没有同属性样式',
    Tip1Extra: '在设计系统内有相同属性的颜色样式，但未使用。',
    Tip2Extra: '在设计系统内没有相同属性的颜色样式。',
    ReplaceAll: '全部替换',
    ReplaceWith: '替换为',

    ToastMessageBegin: '本页面内 ',
    ToastMessageSingleEnd: ' 个图层已替换',
    ToastMessagePluralEnd: ' 个图层已替换',

    Image: '图片',
    LinearGradient: '线性渐变',
    RadialGradient: '径向渐变',
    AngularGradient: '角度渐变',
    DiamondGradient: '菱形渐变',

    EmptyPrompt: '未发现颜色一致性问题',
} as const

export const enTranslation = {
    Tip1: 'Unused same-property styles available',
    Tip2: 'No same-property styles',
    Tip1Extra: 'Unused color styles with the same properties are available in the design system.',
    Tip2Extra: 'No color styles with the same properties are found in the design system.',
    ReplaceAll: 'Replace all',
    ReplaceWith: 'Replace with',

    ToastMessageBegin: '',
    ToastMessageSingleEnd: ' layer in this page replaced',
    ToastMessagePluralEnd: ' layers in this page replaced',

    Image: 'Image',
    LinearGradient: 'Linear gradient',
    RadialGradient: 'Radial gradient',
    AngularGradient: 'Angular gradient',
    DiamondGradient: 'Diamond gradient',

    EmptyPrompt: 'No color consistency issues found',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
