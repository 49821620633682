import { MotiffApi } from '@motiffcom/plugin-api-types'
import { Wukong } from '@wukong/bridge-proto'

export const documentChange2String: Record<Wukong.DocumentProto.PluginDocumentChangeType, string> = {
    [Wukong.DocumentProto.PluginDocumentChangeType.PLUGIN_DOCUMENT_CHANGE_TYPE_NONE]: 'NONE',
    [Wukong.DocumentProto.PluginDocumentChangeType.PLUGIN_DOCUMENT_CHANGE_TYPE_CREATE_CHANGE]: 'CREATE',
    [Wukong.DocumentProto.PluginDocumentChangeType.PLUGIN_DOCUMENT_CHANGE_TYPE_DELETE_CHANGE]: 'DELETE',
    [Wukong.DocumentProto.PluginDocumentChangeType.PLUGIN_DOCUMENT_CHANGE_TYPE_PROPERTY_CHANGE]: 'PROPERTY_CHANGE',
    [Wukong.DocumentProto.PluginDocumentChangeType.PLUGIN_DOCUMENT_CHANGE_TYPE_STYLE_CREATE_CHANGE]: 'STYLE_CREATE',
    [Wukong.DocumentProto.PluginDocumentChangeType.PLUGIN_DOCUMENT_CHANGE_TYPE_STYLE_DELETE_CHANGE]: 'STYLE_DELETE',
    [Wukong.DocumentProto.PluginDocumentChangeType.PLUGIN_DOCUMENT_CHANGE_TYPE_STYLE_PROPERTY_CHANGE]:
        'STYLE_PROPERTY_CHANGE',
}

export const property2String: Record<Wukong.DocumentProto.PluginDocumentChangeProperty, MotiffApi.NodeChangeProperty> =
    {
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_NAME]: 'name',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_WIDTH]: 'width',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_HEIGHT]: 'height',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_BACKGROUNDS]: 'backgrounds',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_RELATIVE_TRANSFORM]:
            'relativeTransform',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_POINT_COUNT]: 'pointCount',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_MIN_WIDTH]: 'minWidth',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_MAX_WIDTH]: 'maxWidth',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_MIN_HEIGHT]: 'minHeight',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_MAX_HEIGHT]: 'maxHeight',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_VISIBLE]: 'visible',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_GUIDES]: 'guides',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_CHARACTERS]: 'characters',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_STROKE_MITER_LIMIT]:
            'strokeMiterLimit',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_X]: 'x',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_Y]: 'y',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_FILLS]: 'fills',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_FONT_SIZE]: 'fontSize',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_FONT_NAME]: 'fontName',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_LINE_HEIGHT]: 'lineHeight',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_LETTER_SPACING]:
            'letterSpacing',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_TEXT_CASE]: 'textCase',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_TEXT_ALIGN_HORIZONTAL]:
            'textAlignHorizontal',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_TEXT_ALIGN_VERTICAL]:
            'textAlignVertical',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_PARENT]: 'parent',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_LOCKED]: 'locked',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_PARAGRAPH_INDENT]:
            'paragraphIndent',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_PARAGRAPH_SPACING]:
            'paragraphSpacing',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_TEXT_DECORATION]:
            'textDecoration',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_TEXT_AUTO_RESIZE]:
            'textAutoResize',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_PLUGIN_DATA]: 'pluginData',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_CONSTRAINTS]: 'constraints',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_OPACITY]: 'opacity',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_BLEND_MODE]: 'blendMode',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_LAYOUT_GRIDS]: 'layoutGrids',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_EFFECTS]: 'effects',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_EXPORT_SETTINGS]:
            'exportSettings',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_INNER_RADIUS]: 'innerRadius',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_AUTO_RENAME]: 'autoRename',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_TEXT_TRUNCATION]:
            'textTruncation',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_MAX_LINES]: 'maxLines',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_TOP_LEFT_RADIUS]:
            'topLeftRadius',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_TOP_RIGHT_RADIUS]:
            'topRightRadius',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_BOTTOM_LEFT_RADIUS]:
            'bottomLeftRadius',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_BOTTOM_RIGHT_RADIUS]:
            'bottomRightRadius',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_CONSTRAIN_PROPORTIONS]:
            'constrainProportions',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_STROKES]: 'strokes',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_STROKE_WEIGHT]:
            'strokeWeight',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_STROKE_ALIGN]: 'strokeAlign',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_STROKE_JOIN]: 'strokeJoin',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_BOOLEAN_OPERATION]:
            'booleanOperation',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_DASH_PATTERN]: 'dashPattern',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_HANDLE_MIRRORING]:
            'handleMirroring',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_CORNER_RADIUS]:
            'cornerRadius',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_CORNER_SMOOTHING]:
            'cornerSmoothing',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_CLIPS_CONTENT]:
            'clipsContent',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_TYPE]: 'type',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_EXPANDED]: 'expanded',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_HYPERLINK]: 'hyperlink',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_FILL_STYLE_ID]:
            'fillStyleId',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_STROKE_STYLE_ID]:
            'strokeStyleId',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_TEXT_STYLE_ID]:
            'textStyleId',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_EFFECT_STYLE_ID]:
            'effectStyleId',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_GRID_STYLE_ID]:
            'gridStyleId',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_DESCRIPTION]: 'description',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_ARC_DATA]: 'arcData',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_STROKE_CAP]: 'strokeCap',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_OVERLAY_POSITION_TYPE]:
            'overlayPositionType',
        [Wukong.DocumentProto.PluginDocumentChangeProperty
            .PLUGIN_DOCUMENT_CHANGE_PROPERTY_OVERLAY_BACKGROUND_INTERACTION]: 'overlayBackgroundInteraction',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_OVERLAY_BACKGROUND]:
            'overlayBackground',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_PADDING_LEFT]: 'paddingLeft',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_PADDING_TOP]: 'paddingTop',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_PADDING_RIGHT]:
            'paddingRight',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_PADDING_BOTTOM]:
            'paddingBottom',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_ITEM_SPACING]: 'itemSpacing',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_COUNTER_AXIS_SPACING]:
            'counterAxisSpacing',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_LAYOUT_ALIGN]: 'layoutAlign',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_COUNTER_AXIS_SIZING_MODE]:
            'counterAxisSizingMode',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_PRIMARY_AXIS_SIZING_MODE]:
            'primaryAxisSizingMode',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_PRIMARY_AXIS_ALIGN_ITEMS]:
            'primaryAxisAlignItems',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_COUNTER_AXIS_ALIGN_ITEMS]:
            'counterAxisAlignItems',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_COUNTER_AXIS_ALIGN_CONTENT]:
            'counterAxisAlignContent',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_LAYOUT_GROW]: 'layoutGrow',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_ITEM_REVERSE_Z_INDEX]:
            'itemReverseZIndex',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_STROKE_BOTTOM_WEIGHT]:
            'strokeBottomWeight',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_STROKE_LEFT_WEIGHT]:
            'strokeLeftWeight',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_STROKE_RIGHT_WEIGHT]:
            'strokeRightWeight',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_REACTIONS]: 'reactions',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_WIDGET_SYNCED_STATE]:
            'widgetSyncedState',
        [Wukong.DocumentProto.PluginDocumentChangeProperty
            .PLUGIN_DOCUMENT_CHANGE_PROPERTY_COMPONENT_PROPERTY_DEFINITIONS]: 'componentPropertyDefinitions',
        [Wukong.DocumentProto.PluginDocumentChangeProperty
            .PLUGIN_DOCUMENT_CHANGE_PROPERTY_COMPONENT_PROPERTY_REFERENCES]: 'componentPropertyReferences',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_COMPONENT_PROPERTIES]:
            'componentProperties',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_STROKE_TOP_WEIGHT]:
            'strokeTopWeight',
        [Wukong.DocumentProto.PluginDocumentChangeProperty.PLUGIN_DOCUMENT_CHANGE_PROPERTY_STYLED_TEXT_SEGMENTS]:
            'styledTextSegments',
    }

export const origin2String: Record<Wukong.DocumentProto.PluginDocumentChangeOrigin, 'REMOTE' | 'LOCAL'> = {
    [Wukong.DocumentProto.PluginDocumentChangeOrigin.PLUGIN_DOCUMENT_CHANGE_ORIGIN_REMOTE]: 'REMOTE',
    [Wukong.DocumentProto.PluginDocumentChangeOrigin.PLUGIN_DOCUMENT_CHANGE_ORIGIN_LOCAL]: 'LOCAL',
}

export const type2String: Record<Wukong.DocumentProto.PluginEventType, string> = {
    [Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_SELECTION_CHANGE]: 'SELECTIONCHANGE',
    [Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_CURRENT_PAGE_CHANGE]: 'CURRENTPAGECHANGE',
    [Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_DOCUMENT_CHANGE]: 'DOCUMENTCHANGE',
    [Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_TEXT_REVIEW]: 'TEXTREVIEW',
    [Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_DROP]: 'DROP',
    [Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_CLOSE]: 'CLOSE',
    [Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_RUN]: 'RUN',
    [Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_STYLE_CHANGE]: 'STYLECHANGE',
    [Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_NODE_CHANGE]: 'NODECHANGE',
    [Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_TIMER_START]: 'TIMERSTART',
    [Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_TIMER_PAUSE]: 'TIMERPAUSE',
    [Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_TIMER_STOP]: 'TIMERSTOP',
    [Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_TIMER_DONE]: 'TIMERDONE',
    [Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_TIMER_RESUME]: 'TIMERRESUME',
    [Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_TIMER_ADJUST]: 'TIMERADJUST',
}

export const typeStringToEnum: Record<string, Wukong.DocumentProto.PluginEventType> = {
    selectionchange: Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_SELECTION_CHANGE,
    currentpagechange: Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_CURRENT_PAGE_CHANGE,
    documentchange: Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_DOCUMENT_CHANGE,
    close: Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_CLOSE,
    run: Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_RUN,
    drop: Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_DROP,
    nodechange: Wukong.DocumentProto.PluginEventType.PLUGIN_EVENT_TYPE_NODE_CHANGE,
}
