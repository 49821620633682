import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Previous: '上一条',
    Next: '下一条',
    MarkAsUnresolved: '标为未解决',
    MaskAsResolved: '标为已解决',
    DockToSide: '固定至左上角',
} as const

export const enTranslation = {
    Previous: 'Previous',
    Next: 'Next',
    MarkAsUnresolved: 'Mark as unresolved',
    MaskAsResolved: 'Mask as resolved',
    DockToSide: 'Dock to side',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
