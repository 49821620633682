/**
 * 将输入的 Url 转化为 pathName & search
 * @param url
 * @returns
 */
export function parseUrl(url: string) {
    const urlObj = new URL(url)

    return {
        pathname: urlObj.pathname,
        search: urlObj.search,
    }
}
