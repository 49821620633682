import { translation } from './tool-extend.translation'
/* eslint-disable no-restricted-imports */
import {
    AddVariantForSelectedComponentWasmCall,
    BatchComponentSelectionCommand,
    ComponentSelectionCommand,
    ComponentSetSelectionCommand,
    EnterAILayoutCommand,
    ExitAILayoutCommand,
    SaveExitAILayoutCommand,
    ToggleMaskSelectionCommand,
} from '@wukong/bridge-proto'
import {
    DropdownV2,
    MonoIconToolbarAddVariant24,
    MonoIconToolbarComponent24,
    MonoIconToolbarCompReset24,
    MonoIconToolbarCrop24,
    MonoIconToolbarEditVector24,
    MonoIconToolbarMask24,
} from '../../../../../../ui-lib/src'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { Switch } from '../../atom/switch/switch'
import { HyperlinkSwitchItem } from './hyperlink-switch-item'
import { ToolExtendBool } from './tool-extend-bool/tool-extend-bool'
import style from './tool-extend.module.less'
import { useToolExtend } from './use-tool-extend'
import { frogEventToolsMenusAndActionsBasicAction } from '../../../utils/tools-actions-frog'
import { ShortcutKey, shortcutLabelMap } from '../../../../kernel/interface/shortcut-key'
import { featureGuideService } from '../../../../main/service/feature-guide/feature-guide-service'

enum ToolExtendType {
    CreateComponent,
    BatchCreateComponent,
    CreateComponentSet,
    ToggleMaskSelection,
    EnterAILayout,
    ExitAILayout,
    SaveExitAILayout,
}

export function ToolExtend() {
    const {
        isEditingSinglePaint,
        showCropTool,
        onClickCrop,
        showComponentTool,
        showResetComponent,
        showEditTool,
        onClickResetProperties,
        onClickEditVector,
    } = useToolExtend()
    const command = useCommand()
    const [showSingleComponentTool, showMultipleComponentTool] = showComponentTool

    const showUseAsMask = useViewState('actionEnabledUseAsMaskStatus')

    const showAddVariant = useViewState('actionEnabledAddVariant')

    const showCancelMask = useViewState('actionEnabledCancelMaskStatus')

    const createComponent = () => {
        command.DEPRECATED_invokeBridge(ComponentSelectionCommand)
        command.commitUndo()
    }
    const batchCreateComponent = () => {
        command.DEPRECATED_invokeBridge(BatchComponentSelectionCommand)
        command.commitUndo()
    }
    const createComponentSet = () => {
        command.DEPRECATED_invokeBridge(ComponentSetSelectionCommand)
        command.commitUndo()
    }
    const toggleMaskSelection = () => {
        command.DEPRECATED_invokeBridge(ToggleMaskSelectionCommand)
        command.commitUndo()
    }
    const addVariant = () => {
        command.DEPRECATED_invokeBridge(AddVariantForSelectedComponentWasmCall)
        command.commitUndo()
    }

    const enterAILayoutCommand = () => {
        command.DEPRECATED_invokeBridge(EnterAILayoutCommand)
    }
    const exitAILayoutCommand = () => {
        command.DEPRECATED_invokeBridge(ExitAILayoutCommand)
    }
    const saveExitAILayoutCommand = () => {
        command.DEPRECATED_invokeBridge(SaveExitAILayoutCommand)
    }

    const onEnterChange = (value: ToolExtendType) => {
        switch (value) {
            case ToolExtendType.CreateComponent: {
                createComponent()
                break
            }
            case ToolExtendType.BatchCreateComponent: {
                batchCreateComponent()
                break
            }
            case ToolExtendType.CreateComponentSet: {
                createComponentSet()
                break
            }
            case ToolExtendType.ToggleMaskSelection: {
                toggleMaskSelection()
                break
            }
            case ToolExtendType.EnterAILayout: {
                enterAILayoutCommand()
                break
            }
            case ToolExtendType.ExitAILayout: {
                exitAILayoutCommand()
                break
            }
            case ToolExtendType.SaveExitAILayout: {
                saveExitAILayoutCommand()
                break
            }
        }
    }

    return (
        <Switch className={style.container}>
            {isEditingSinglePaint ? (
                <>
                    {showEditTool && (
                        <Switch.Item
                            className={style.switchItem44}
                            onMouseDown={() => {
                                onClickEditVector()
                                frogEventToolsMenusAndActionsBasicAction({
                                    action_name: '进入矢量编辑状态',
                                    operation_way: '上下文工具',
                                })
                            }}
                            tooltipTitle={translation('EditObject')}
                            useTabFocus={true}
                            onKeyboardClick={onClickEditVector}
                        >
                            <MonoIconToolbarEditVector24 />
                        </Switch.Item>
                    )}

                    <Switch.Item
                        className={style.switchItem44}
                        onMouseDown={createComponent}
                        testid="create-component"
                        tooltipTitle={translation('CreateComponent')}
                        tooltipShortcut={shortcutLabelMap[ShortcutKey.ComponentSelection]}
                        useTabFocus={true}
                        onKeyboardClick={createComponent}
                    >
                        <MonoIconToolbarComponent24 />
                    </Switch.Item>
                    <Switch.Item
                        className={style.switchItem44}
                        onMouseDown={onClickCrop}
                        tooltipTitle={translation('CropImage')}
                        useTabFocus={true}
                        onKeyboardClick={onClickCrop}
                        testid="crop-image"
                    >
                        <MonoIconToolbarCrop24 />
                    </Switch.Item>
                </>
            ) : (
                <>
                    {showEditTool && (
                        <Switch.Item
                            className={style.switchItem44}
                            onMouseDown={() => {
                                onClickEditVector()
                                frogEventToolsMenusAndActionsBasicAction({
                                    action_name: '进入矢量编辑状态',
                                    operation_way: '上下文工具',
                                })
                            }}
                            tooltipTitle={translation('EditObject')}
                            useTabFocus={true}
                            onKeyboardClick={onClickEditVector}
                        >
                            <MonoIconToolbarEditVector24 />
                        </Switch.Item>
                    )}
                    {showResetComponent && (
                        <Switch.Item
                            className={style.switchItem44}
                            onMouseDown={() => {
                                onClickResetProperties()
                                frogEventToolsMenusAndActionsBasicAction({
                                    action_name: '重置所有修改',
                                    operation_way: '上下文工具',
                                })
                            }}
                            tooltipTitle={translation('ResetAllChanges')}
                            useTabFocus={true}
                            onKeyboardClick={onClickResetProperties}
                        >
                            <MonoIconToolbarCompReset24 />
                        </Switch.Item>
                    )}
                    {showSingleComponentTool && (
                        <Switch.Item
                            className={style.switchItem44}
                            onMouseDown={() => {
                                createComponent()
                                frogEventToolsMenusAndActionsBasicAction({
                                    action_name: '创建组件',
                                    operation_way: '上下文工具',
                                })
                            }}
                            testid="create-component"
                            tooltipTitle={translation('CreateComponent')}
                            tooltipShortcut={shortcutLabelMap[ShortcutKey.ComponentSelection]}
                            useTabFocus={true}
                            onKeyboardClick={createComponent}
                        >
                            <MonoIconToolbarComponent24 />
                        </Switch.Item>
                    )}
                    {showMultipleComponentTool && (
                        <DropdownV2.MenuSingleLevel
                            style={{ borderRadius: 'var(--wk-radius-none)' }}
                            placement="bottom center"
                            onChange={onEnterChange}
                            label={<MonoIconToolbarComponent24 />}
                            tooltipProps={{ title: translation('Component') }}
                            labelMoveOut={{
                                tooltipProps: {
                                    title: translation('CreateComponent'),
                                    shortcut: shortcutLabelMap[ShortcutKey.ComponentSelection],
                                },
                                onClick: createComponent,
                            }}
                            dataTestIds={{ triggerFocus: 'create-component-arrow' }}
                        >
                            <DropdownV2.MenuSingleLevel.Option
                                value={ToolExtendType.CreateComponent}
                                onClick={() => {
                                    frogEventToolsMenusAndActionsBasicAction({
                                        action_name: '创建组件',
                                        operation_way: '上下文工具-下拉菜单',
                                    })
                                }}
                                backwardIcon={shortcutLabelMap[ShortcutKey.ComponentSelection]}
                            >
                                {translation('CreateComponent')}
                            </DropdownV2.MenuSingleLevel.Option>
                            <DropdownV2.MenuSingleLevel.Option
                                value={ToolExtendType.BatchCreateComponent}
                                onClick={() => {
                                    frogEventToolsMenusAndActionsBasicAction({
                                        action_name: '创建多个组件',
                                        operation_way: '上下文工具-下拉菜单',
                                    })
                                }}
                            >
                                {translation('CreateMultipleComponents')}
                            </DropdownV2.MenuSingleLevel.Option>
                            <DropdownV2.MenuSingleLevel.Option
                                value={ToolExtendType.CreateComponentSet}
                                onClick={() => {
                                    frogEventToolsMenusAndActionsBasicAction({
                                        action_name: '创建变体组件',
                                        operation_way: '上下文工具-下拉菜单',
                                    })
                                }}
                            >
                                {translation('CreateComponentSet')}
                            </DropdownV2.MenuSingleLevel.Option>
                        </DropdownV2.MenuSingleLevel>
                    )}

                    {showAddVariant && (
                        <Switch.Item
                            className={style.switchItem44}
                            onMouseDown={() => {
                                addVariant()
                                frogEventToolsMenusAndActionsBasicAction({
                                    action_name: '添加变体',
                                    operation_way: '上下文工具',
                                })
                            }}
                            data-testid="add-variant"
                            tooltipTitle={translation('AddVariant')}
                            useTabFocus={true}
                            onKeyboardClick={addVariant}
                        >
                            <MonoIconToolbarAddVariant24 />
                        </Switch.Item>
                    )}
                    {showUseAsMask && (
                        <Switch.Item
                            className={style.switchItem44}
                            onMouseDown={() => {
                                toggleMaskSelection()
                                frogEventToolsMenusAndActionsBasicAction({
                                    action_name: '创建蒙版',
                                    operation_way: '上下文工具',
                                })
                                featureGuideService.tryStart('mask')
                            }}
                            data-testid="mask-tool"
                            tooltipTitle={translation('UseAsMask')}
                            tooltipShortcut={shortcutLabelMap[ShortcutKey.UseAsMask]}
                            useTabFocus={true}
                            onKeyboardClick={toggleMaskSelection}
                        >
                            <MonoIconToolbarMask24 />
                        </Switch.Item>
                    )}
                    {showCancelMask && (
                        <Switch.Item
                            className={style.switchItem44}
                            onMouseDown={() => {
                                toggleMaskSelection()
                                frogEventToolsMenusAndActionsBasicAction({
                                    action_name: '取消蒙版',
                                    operation_way: '上下文工具',
                                })
                            }}
                            data-testid="cancel-mask-tool"
                            tooltipTitle={translation('RemoveMask')}
                            tooltipShortcut={shortcutLabelMap[ShortcutKey.UseAsMask]}
                            useTabFocus={true}
                            onKeyboardClick={toggleMaskSelection}
                        >
                            <MonoIconToolbarMask24 />
                        </Switch.Item>
                    )}
                    {/* <Switch.Item className={style.switchItem44}>{svgLink}</Switch.Item> */}
                    {showCropTool && (
                        <Switch.Item
                            className={style.switchItem44}
                            onMouseDown={(e) => {
                                onClickCrop(e)
                                frogEventToolsMenusAndActionsBasicAction({
                                    action_name: '进入图片裁切状态',
                                    operation_way: '上下文工具',
                                })
                            }}
                            tooltipTitle={translation('CropImage')}
                            useTabFocus={true}
                            onKeyboardClick={onClickCrop}
                            testid="crop-image"
                        >
                            <MonoIconToolbarCrop24 />
                        </Switch.Item>
                    )}
                    <HyperlinkSwitchItem />
                    <ToolExtendBool />
                </>
            )}
        </Switch>
    )
}
