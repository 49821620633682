import { command } from 'ccstate'
import { TraceableAbortSignal } from '../../../../../../../util/src/abort-controller/traceable-abort-controller'
import { transaction } from '../../../../../../../util/src/abort-controller/traceable-transaction'
import { traceable } from '../../../../../../../util/src/ccstate-helper/traceable'
import { domLocation } from '../../../../../../../util/src/dom-env'
import { generateRouterPath } from '../../../../../../../util/src/route'
import { environment, IN_JEST_TEST } from '../../../../../environment'
import { Lifecycle } from '../../../../../main/lifecycle/chore'
import { injectMirrorAndPreviewAfterCreateCallback } from '../../../../../main/lifecycle/hooks/after-create/mirror-and-preview'
import { injectMirrorAndPreviewLoginCallback } from '../../../../../main/lifecycle/hooks/after-login/mirror-and-preview'
import { mirrorPreviewLifecycleInterface } from '../../../../../main/lifecycle/interface'
import { AppState, CallbackInjector } from '../../../../../main/lifecycle/types'
import { EditorContextUnion, MirrorContext } from '../../../../../main/lifecycle/types/editor'
import { bootstrapCommon$ } from '../common'

/**
 * pathname符合以下规则为移动端预览页面
 * 1. ${host}/mirror
 * 2. ${host}/mirror/xxx/xxx
 */
export const isMirrorPath = (): boolean => {
    const mirrorPathPrefix = '/' + generateRouterPath(environment.mirrorBasePath)
    return domLocation().pathname === mirrorPathPrefix || domLocation().pathname.startsWith(mirrorPathPrefix + '/')
}

function dynamicInjectCallback<S extends AppState, K extends EditorContextUnion>(
    lifecycle: Lifecycle<K>,
    appState: S,
    injects: CallbackInjector<S, K>
) {
    injects(lifecycle.generateStateCallbackInjector(appState))
}

export const bootstrapMirrorApp$ = traceable(
    'hulh01@kanyun.com',
    command(async ({ set }, signal: TraceableAbortSignal) => {
        set(bootstrapCommon$, signal)

        const { act } = transaction(signal)

        // preview 和 mirror 共用
        const lifecycle = new Lifecycle<MirrorContext>(false, false)
        dynamicInjectCallback(lifecycle, AppState.CreateApp, injectMirrorAndPreviewAfterCreateCallback)
        dynamicInjectCallback(lifecycle, AppState.Login, injectMirrorAndPreviewLoginCallback)

        mirrorPreviewLifecycleInterface.startApp(lifecycle)

        if (IN_JEST_TEST) {
            act('inject context to test', () => {
                ;(window as any).mirrorLifecycleCtx = mirrorPreviewLifecycleInterface.getContext()

                return () => {
                    ;(window as any).mirrorLifecycleCtx = undefined
                }
            })
        }

        return null
    })
)
