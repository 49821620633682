import { Wukong } from '@wukong/bridge-proto'
import { useRef } from 'react'
import Tooltip from '../../../../../../../ui-lib/src/components/tooltip/tooltip'
import { useIsEllipsisActive } from '../../../../../../../util/src'
import { CopyableRow } from '../copyable-row'
import style from './index.module.less'

export function getModeList(variableModeEntries?: Wukong.DocumentProto.IVariableModeEntriesState) {
    const localModes =
        variableModeEntries?.localVariableSets
            .filter(
                (v) =>
                    v.modeSelectionState.selectionType ===
                    Wukong.DocumentProto.VariableSetModeSelectionType.VARIABLE_SET_MODE_SELECTION_TYPE_VALUE
            )
            .map((item) => ({
                collectionName: item.name,
                modeName: item.modeSelectionState.selectedMode.modeName,
            })) ?? []
    const remoteModes =
        variableModeEntries?.remoteVariableSets.flatMap((group) =>
            group.items
                .filter(
                    (v) =>
                        v.modeSelectionState.selectionType ===
                        Wukong.DocumentProto.VariableSetModeSelectionType.VARIABLE_SET_MODE_SELECTION_TYPE_VALUE
                )
                .map((item) => ({
                    collectionName: group.docName,
                    modeName: item.modeSelectionState.selectedMode.modeName,
                }))
        ) ?? []
    const unknownModes =
        variableModeEntries?.unknownVariableSets
            .filter(
                (v) =>
                    v.modeSelectionState.selectionType ===
                    Wukong.DocumentProto.VariableSetModeSelectionType.VARIABLE_SET_MODE_SELECTION_TYPE_VALUE
            )
            .map((item) => ({
                collectionName: item.name,
                modeName: item.modeSelectionState.selectedMode.modeName,
            })) ?? []
    return [...localModes, ...unknownModes, ...remoteModes]
}

export function ModeItem({ mode }: { mode: { collectionName: string; modeName: string } }) {
    const ref = useRef<HTMLSpanElement>(null)
    const isEllipsis = useIsEllipsisActive(ref.current)
    return (
        <div className={style.propertyRowNarrow}>
            <Tooltip title={mode.collectionName} inactivation={!isEllipsis}>
                <span className={style.propertyName} ref={ref}>
                    {mode.collectionName}
                </span>
            </Tooltip>
            <CopyableRow copyValue={mode.modeName} className={style.propertyValue}>
                {mode.modeName}
            </CopyableRow>
        </div>
    )
}
