import { MouseEvent, useState } from 'react'
import { Rect } from '../../../../../../../../ui-lib/src'

export function useComponentPropDefItemRightClick() {
    const [visible, setVisible] = useState<boolean>(false)
    const [rect, setRect] = useState<Rect>({ top: 0, left: 0, right: 0, bottom: 0 })

    const handleContextMenu = (e: MouseEvent<HTMLDivElement>, preCheckFn = () => true) => {
        if (!preCheckFn()) {
            return
        }

        const { clientX, clientY } = e
        setRect({ top: clientY + 8, left: clientX, right: clientX, bottom: clientY + 8 })
        setVisible(true)
    }

    const closeDropdown = () => setVisible(false)

    return {
        visible,
        rect,
        handleContextMenu,
        closeDropdown,
    }
}
