import { useMemo } from 'react'
import { getAlertOfDevModeUnavailableInfo } from '.'
import { useUserInfoContext } from '../../../../auth'
import { usePlanAndUserStateService } from '../../../../main/app-context'

export const useShowDevModeReminder = () => {
    const { userInfo } = useUserInfoContext()

    const planAndUserStateService = usePlanAndUserStateService()
    const planAndUserState = planAndUserStateService.useZustandStore.use.planAndUserState()

    const showReminder = useMemo(
        () => getAlertOfDevModeUnavailableInfo(planAndUserState, true, userInfo.userId).show,
        [planAndUserState, userInfo.userId]
    )

    return showReminder
}
