import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    ChangeTheANGLE:
        '检测到当前浏览器正在使用 OpenGL 图形后端，这可能会导致一些性能问题。请切换到 Metal 后端以获得更好的体验。',
    LearnMore: '了解更多',
} as const

export const enTranslation = {
    ChangeTheANGLE:
        'The current browser is using the OpenGL graphics backend, which may cause performance issues. Switch to the Metal backend for a better experience.',
    LearnMore: 'Learn more',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
