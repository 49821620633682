import { useSet } from 'ccstate-react'
import constate from 'constate'
import { logout$ } from '../../../external-store/atoms/authenticate'
import { usePageSignal } from '../../../external-store/atoms/page-signal'
import { updateUserInfo$ } from '../../../external-store/atoms/user-info'
import { UserVOV2 } from '../../../kernel/interface/account'

const useUserInfoInternal = ({ userInfo }: { userInfo: UserVOV2 }) => {
    const logout = useSet(logout$)
    const updateUserInfo = useSet(updateUserInfo$)
    const pageSignal = usePageSignal()

    return {
        userInfo,
        logout: () => {
            logout?.(pageSignal)
        },
        updateUserInfo: () => {
            updateUserInfo()
        },
    }
}

export const [UserInfoProvider, useUserInfoContext] = constate(useUserInfoInternal)
