/* eslint-disable no-restricted-imports */
import { UpdateCoactorObservingCommand, Wukong } from '@wukong/bridge-proto'
import { useCallback, useEffect, useState } from 'react'
import { useUserInfoContext } from '../../../../auth'
import { useCommand, useHistoryService } from '../../../../main/app-context'
import { DeepRequired, useViewState } from '../../../../view-state-bridge'

function isCoactorMembershipMapEqual(
    map1: DeepRequired<Wukong.DocumentProto.IVMembershipMap['value']> | undefined,
    map2: DeepRequired<Wukong.DocumentProto.IVMembershipMap['value']> | undefined
): boolean {
    if (!map1 && !map2) {
        return true
    }

    if (!map1 || !map2) {
        return false
    }

    const map1ItemList = Object.values(map1)
    if (Object.keys(map2).length !== map1ItemList.length) {
        return false
    }

    for (const item1 of map1ItemList) {
        const item2 = map2[item1.sessionId]
        if (!item2) {
            return false
        }
        if (item2.role !== item1.role) {
            return false
        }
        // 协作者 inactive 后又 active 时，sessionId 不变，但 colorIndex 会变化
        if (item2.colorIndex != item1.colorIndex) {
            return false
        }
    }

    return true
}

// 协作者列表
export function useCoactorMemberList() {
    const command = useCommand()

    const historyService = useHistoryService()
    const isHistoryMode = historyService.useZustandStore.use.isHistoryMode()

    const [activeUserList, setActiveUserList] = useState<DeepRequired<Wukong.DocumentProto.IVActiveUser>[]>([])
    const [moreActiveUserList, setMoreActiveUserList] = useState<DeepRequired<Wukong.DocumentProto.IVActiveUser>[]>([])

    const { userInfo } = useUserInfoContext()

    const _coactorMembershipMap = useViewState('coactorMembershipMap')
    const [coactorMembershipMap, setCoactorMembershipMap] = useState<
        DeepRequired<Wukong.DocumentProto.IVMembershipMap> | undefined
    >(_coactorMembershipMap)

    useEffect(() => {
        setCoactorMembershipMap((prev) =>
            isCoactorMembershipMapEqual(prev?.value, _coactorMembershipMap?.value) ? prev : _coactorMembershipMap
        )
    }, [_coactorMembershipMap])

    // 切换观察者模式
    const onToggleObservingMode = useCallback(
        (user: DeepRequired<Wukong.DocumentProto.IVActiveUser>) => {
            if (isHistoryMode) {
                return
            }
            switch (user.role) {
                case Wukong.DocumentProto.ActiveUserRole.ACTIVE_USER_ROLE_CURRENT_MYSELF:
                case Wukong.DocumentProto.ActiveUserRole.ACTIVE_USER_ROLE_OBSERVED: {
                    command.DEPRECATED_invokeBridge(UpdateCoactorObservingCommand, {
                        value: null,
                    })
                    break
                }
                default: {
                    command.DEPRECATED_invokeBridge(UpdateCoactorObservingCommand, {
                        value: user.sessionId,
                    })
                    break
                }
            }
        },
        [command, isHistoryMode]
    )

    // 用户列表排序（自己 > 观察者 > 其他）
    useEffect(() => {
        // 在历史版本下 mock 个人头像【进入历史版本会断掉协同，此时没有下发 memberShip，需要自己 mock】
        if (isHistoryMode) {
            const { nickname, avatarId } = userInfo.userBrief
            const mockMySelf = Wukong.DocumentProto.VActiveUser.create({
                nickname,
                avatarId,
                color: Wukong.DocumentProto.RGB.create({
                    r: 250,
                    g: 80,
                    b: 92,
                }),
                role: Wukong.DocumentProto.ActiveUserRole.ACTIVE_USER_ROLE_CURRENT_MYSELF,
            }) as DeepRequired<Wukong.DocumentProto.IVActiveUser>
            setActiveUserList([mockMySelf])
            setMoreActiveUserList([])
            return
        }

        if (coactorMembershipMap?.value) {
            const userList = Object.values(coactorMembershipMap.value)
            let currentMyselfIndex = -1
            let currentObservedIndex = -1

            for (let index = 0; index < userList.length; index++) {
                const userRole = userList[index].role
                if (userRole === Wukong.DocumentProto.ActiveUserRole.ACTIVE_USER_ROLE_CURRENT_MYSELF) {
                    currentMyselfIndex = index
                } else if (userRole === Wukong.DocumentProto.ActiveUserRole.ACTIVE_USER_ROLE_OBSERVED) {
                    currentObservedIndex = index
                }

                if (currentMyselfIndex > -1 && currentObservedIndex > -1) {
                    break
                }
            }

            let currentMySelf: DeepRequired<Wukong.DocumentProto.IVActiveUser> | undefined
            let currentObserved: DeepRequired<Wukong.DocumentProto.IVActiveUser> | undefined

            if (currentMyselfIndex > -1) {
                currentMySelf = userList[currentMyselfIndex]
                userList.splice(currentMyselfIndex, 1)
            }

            if (currentObservedIndex > -1) {
                const realCurrentObservedIndex =
                    currentObservedIndex < currentMyselfIndex ? currentObservedIndex : currentObservedIndex - 1
                currentObserved = userList[realCurrentObservedIndex]
                userList.splice(realCurrentObservedIndex, 1)
            }

            userList.sort(
                (user1, user2) => (user1.entryTime as unknown as number) - (user2.entryTime as unknown as number)
            )

            if (currentObserved) {
                userList.splice(0, 0, currentObserved)
            }

            if (currentMySelf) {
                userList.splice(0, 0, currentMySelf)
            }

            if (userList.length > 3) {
                setActiveUserList(userList.slice(0, 2))
                setMoreActiveUserList(userList.slice(2))
            } else {
                setActiveUserList(userList)
                setMoreActiveUserList([])
            }
        }
    }, [coactorMembershipMap, isHistoryMode, userInfo.userBrief])

    return {
        activeUserList,
        moreActiveUserList,
        onToggleObservingMode,
    }
}
