import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    ShowAsList: '列表视图',
    ShowAsGrid: '网格视图',
} as const

export const enTranslation = {
    ShowAsList: 'Show as list',
    ShowAsGrid: 'Show as grid',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
