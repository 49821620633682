import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    'Find…': '查找',
    'Find…_synonyms1': '查找文本内容',
} as const

export const enTranslation = {
    'Find…': 'Find',
    'Find…_synonyms1': 'Find',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
