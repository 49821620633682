import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import { GetSelectionNodeIdsCommandForWasm, SkewSelectedNodesCommand, Wukong } from '@wukong/bridge-proto'
import { useEffect, useState } from 'react'
import { WKButton } from '../../../../../../ui-lib/src'
import { isNotNullOrUndefined } from '../../../../../../util/src'
import { useCommand } from '../../../../main/app-context'
import { ReactComponent as SkewToolsIcon } from '../../../assets/plugins/skew-tools.svg'
import type { ScrubbableInputProps } from '../../atom/inputs/components/scrubbable-input'
import { ScrubbableInputDegree } from '../../atom/inputs/scrubbable-input-degree'
import { Range } from '../../atom/range/range'
import { getLdap, logs } from '../log'
import { Manifest, PluginExported, PluginModalProps } from '../type'
import style from './index.module.less'

const RANGE_MIN = -60
const RANGE_MAX = 60
const baseInputProps: Partial<ScrubbableInputProps> = {
    min: RANGE_MIN,
    max: RANGE_MAX,
    leftScrubbable: false,
    rightScrubbable: false,
}

export const manifest: Manifest = {
    key: Wukong.DocumentProto.PluginType.PLUGIN_TYPE_SKEW_TOOL,
    name: translation('Skew'),
    width: 280,
    height: 408,
    icon: <SkewToolsIcon />,
}

export function PopupModal({ closeModal }: PluginModalProps) {
    // 统计曝光次数
    useEffect(() => {
        // trace
        {
            logs.Plugins.skewtool({ ldap: getLdap() })
        }
    }, [])

    const [x, setX] = useState(0)
    const [y, setY] = useState(0)
    const [noSelection, setNoSelection] = useState(false)
    const command = useCommand()

    const transformValueToRate = (value: number) => {
        return (value - RANGE_MIN) / (RANGE_MAX - RANGE_MIN)
    }
    const transformRateToValue = (rate: number) => {
        return (RANGE_MAX - RANGE_MIN) * rate + RANGE_MIN
    }
    const transformDegToRad = (deg: number) => {
        return (deg / 180) * Math.PI
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const transformRadToDeg = (rad: number) => {
        return (rad / Math.PI) * 180
    }

    const resetSkew = () => {
        // trace
        {
            logs.Plugins.skewtoolReset({ ldap: getLdap() })
        }

        setX(0)
        setY(0)
        command.DEPRECATED_invokeBridge(SkewSelectedNodesCommand, {
            skewX: 0,
            skewY: 0,
        })
        motiff.commitUndo()
    }
    const skew = (changedX: number | undefined, changedY: number | undefined) => {
        if (isNotNullOrUndefined(changedX)) {
            changedX = Math.round(changedX)
        }
        if (isNotNullOrUndefined(changedY)) {
            changedY = Math.round(changedY)
        }

        let skewX = x
        if (isNotNullOrUndefined(changedX)) {
            skewX = changedX
            setX(changedX)
        }

        let skewY = y
        if (isNotNullOrUndefined(changedY)) {
            skewY = changedY
            setY(changedY)
        }

        const ret = command.DEPRECATED_invokeBridge(SkewSelectedNodesCommand, {
            skewX,
            skewY,
        })
        setNoSelection(Boolean(ret.noSelection))
        motiff.commitUndo()
    }

    const applyModal = () => {
        // trace
        {
            const selectionIds = command.DEPRECATED_invokeBridge(GetSelectionNodeIdsCommandForWasm).value ?? []
            logs.Plugins.skewtoolApply({ ldap: getLdap(), layer_id: selectionIds.join(',') })
        }

        skew(x, y)
        closeModal()
    }

    return (
        <div className={style.skewToolModalBody}>
            <div className={style.area}>
                <div className={style.diagramBackground}></div>
                <div
                    className={style.diagram}
                    style={{
                        // 因为放大了两倍所以要 scale(0.5)
                        transform: `scale(0.5) skew(${-x}deg,${-y}deg)`,
                    }}
                ></div>
                <Range
                    className={`${style.skewBar} ${style.horizontal}`}
                    rate={transformValueToRate(x)}
                    onChange={(rate: number) => {
                        skew(transformRateToValue(rate), undefined)
                    }}
                />
                <Range
                    vertical
                    className={`${style.skewBar} ${style.vertical}`}
                    rate={transformValueToRate(y)}
                    onChange={(rate: number) => {
                        skew(undefined, transformRateToValue(rate))
                    }}
                />
            </div>
            {noSelection && <div className={style.errorTip}>{translation('SelectALayer')}</div>}
            <div>
                <div className={`${style.inputRow} ${style.first}`}>
                    <div>{translation('HorizontalSkew')}</div>
                    <ScrubbableInputDegree
                        {...baseInputProps}
                        className={style.input}
                        value={transformDegToRad(x)}
                        onChange={(v) => {
                            if (typeof v === 'number') {
                                skew(v, undefined)
                            }
                        }}
                        testId="skew-tool-horizon-input"
                    />
                </div>
                <div className={style.inputRow}>
                    <div>{translation('VerticalSkew')}</div>
                    <ScrubbableInputDegree
                        min={RANGE_MIN}
                        max={RANGE_MAX}
                        {...baseInputProps}
                        className={style.input}
                        value={transformDegToRad(y)}
                        onChange={(v) => {
                            if (typeof v === 'number') {
                                skew(undefined, v)
                            }
                        }}
                        testId="skew-tool-vertical-input"
                    />
                </div>
            </div>
            <div className={style.panel}>
                <WKButton type="secondary" className={style.button} onClick={resetSkew}>
                    {translation('Reset')}
                </WKButton>
                <WKButton type="primary" className={style.button} onClick={applyModal}>
                    {translation('Apply')}
                </WKButton>
            </div>
        </div>
    )
}

export const exported: PluginExported = {
    manifest,
    Component: PopupModal,
}
