/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Position } from '../../../../../../ui-lib/src'
import { cmdChangePopupState } from '../../../../document/command/document-command'
import { cmdChangeAttrPanelStyleEditorState } from '../../../../document/command/node-props-command'
import {
    AttrPanelStyleEditorState,
    GradientPaint,
    PopupState,
    PopupStateType,
    SolidPaint,
} from '../../../../document/node/node'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { useSelectionPaintModel } from './hooks/use-selection-paint-model'

export enum ChangeStyleType {
    Fill,
    Stroke,
}

export type ModifyStyleIdsFrom =
    | {
          type: 'style'
          originStyleId: string
          styleId: string
          styleNodeKey: string
      }
    | {
          type: 'paint'
          originPaint: SolidPaint | GradientPaint
          paint: SolidPaint | GradientPaint
      }

export function useStyleSelector() {
    const command = useCommand()
    const popupState = useViewState('popupState')
    const attrPanelStyleEditorState = useViewState('attrPanelStyleEditorState')
    const openStyle = useMemo(() => {
        return (
            popupState?.type === PopupStateType.POPUP_STATE_TYPE_PAINT_STYLE &&
            attrPanelStyleEditorState?.openFromModule ===
                Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_SELECT_COLOR
        )
    }, [attrPanelStyleEditorState?.openFromModule, popupState?.type])

    const [stylePosition, setStylePosition] = useState<Position>()
    const [currentOpenStyleId, setCurrentOpenStyleId] = useState<string | undefined>()
    const { modelState } = useSelectionPaintModel()

    const setDocumentState = useCallback(
        (openPopup: boolean) => {
            let _attrPanelStyleEditorState: AttrPanelStyleEditorState | null = null
            const _popupState: PopupState = {
                type: PopupStateType.POPUP_STATE_TYPE_NONE,
                reciprocalIndex: -1,
                multiPopup: [],
            }
            if (openPopup) {
                _attrPanelStyleEditorState = {
                    openFromModule: Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_SELECT_COLOR,
                    openFromNodeId: modelState.uniqueSelectionNodeId ? [modelState.uniqueSelectionNodeId] : [],
                    selectReverseIndex: [],
                    isCreate: false,
                }
                _popupState.type = PopupStateType.POPUP_STATE_TYPE_PAINT_STYLE
            }

            command.invoke(cmdChangeAttrPanelStyleEditorState, _attrPanelStyleEditorState)
            command.invoke(cmdChangePopupState, _popupState)
        },
        [command, modelState.uniqueSelectionNodeId]
    )

    const switchOpenStyleState = useCallback(
        (state?: boolean) => {
            const _openStyle = state !== undefined ? state : !openStyle
            setDocumentState(_openStyle)
        },
        [openStyle, setDocumentState]
    )

    const onClickStyle = useCallback(
        (e: any, styleId?: string) => {
            const { top } = e.currentTarget.getBoundingClientRect()
            const { clientWidth } = document.documentElement
            setStylePosition({ top: top - 4, left: clientWidth - 248 })
            switchOpenStyleState(isNil(styleId) || styleId !== currentOpenStyleId)
            setCurrentOpenStyleId(styleId === currentOpenStyleId ? undefined : styleId)
        },
        [currentOpenStyleId, switchOpenStyleState]
    )

    useEffect(() => {
        // 点击其他区域关闭窗口时自动清理CurrentOpenStyleId，保证openStyle与CurrentOpenStyleId状态一致
        if (!openStyle) {
            setCurrentOpenStyleId(undefined)
        }
    }, [openStyle])

    const { modelCommand } = useSelectionPaintModel()
    const { cmdUpdateFreezeSelectionPaint } = modelCommand

    useEffect(() => {
        cmdUpdateFreezeSelectionPaint(openStyle)
    }, [cmdUpdateFreezeSelectionPaint, openStyle])

    const onChangeStyle = (
        styleNodeId: string,
        needClosePopup: boolean,
        modifyFrom: ModifyStyleIdsFrom,
        isCreate?: boolean
    ) => {
        if (modifyFrom.type == 'style') {
            modelCommand.cmdSelectionColorsReplaceStyle(modifyFrom.originStyleId, styleNodeId)

            if (needClosePopup) {
                switchOpenStyleState(false)
            }
        } else {
            modelCommand.cmdSelectionColorsReplaceColorByStyle(modifyFrom.originPaint, styleNodeId)
            // NOTE: paint替换为style直接关闭弹窗
            if (isCreate) {
                return
            }
            switchOpenStyleState(false)
        }
    }

    const onClickCutLink = (srcStyleId: string) => {
        modelCommand.cmdSelectionColorsDetachStyle(srcStyleId)
        // NOTE: 解绑样式直接关闭弹窗
        switchOpenStyleState(false)
    }

    const onChangeStyleWithColor = (
        srcStyleId: string,
        dstColor: Wukong.DocumentProto.IPaint,
        options?: InputOptionsForUndoSquash
    ) => {
        modelCommand.cmdSelectionColorsReplaceStyleWithColor(srcStyleId, dstColor, options)
        // NOTE: 样式替换颜色直接关闭弹窗
        switchOpenStyleState(false)
    }

    const close = () => {
        switchOpenStyleState(false)
    }

    return {
        openStyle,
        stylePosition,
        currentOpenStyleId,
        onChangeStyle,
        onClickStyle,
        onClickCutLink,
        onChangeStyleWithColor,
        close,
        command,
    }
}
