import { z } from 'zod'
import { IPluginAPIContext } from '../plugin-api-context-interface'
import { ZodTypes } from '../zod-type'

export function createUIApi(ctx: IPluginAPIContext) {
    const vm = ctx.vm
    const hostService = ctx.hostService
    const apiObject = vm.newObject()

    ctx.defineVmFunction({
        objhandle: apiObject,
        key: 'show',
        func: () => {
            hostService.show()
        },
    })

    ctx.defineVmFunction({
        objhandle: apiObject,
        key: 'hide',
        func: () => {
            hostService.hide()
        },
    })

    ctx.defineVmFunction({
        objhandle: apiObject,
        key: 'close',
        func: () => {
            hostService.close()
        },
    })

    ctx.defineVmFunction({
        objhandle: apiObject,
        key: 'resize',
        func: (width_, height_) => {
            const width = ctx.unwrapAndValidate({
                handle: width_,
                key: 'resize width',
                type: ZodTypes.PositiveInteger,
            })
            const height = ctx.unwrapAndValidate({
                handle: height_,
                key: 'resize height',
                type: ZodTypes.PositiveInteger,
            })
            hostService.resize(width, height)
        },
    })

    ctx.defineVmFunction({
        objhandle: apiObject,
        key: 'reposition',
        func: (x_, y_) => {
            const x = ctx.unwrapAndValidate({
                handle: x_,
                key: 'reposition x',
                type: ZodTypes.FiniteNumber,
            })
            const y = ctx.unwrapAndValidate({
                handle: y_,
                key: 'reposition y',
                type: ZodTypes.FiniteNumber,
            })
            hostService.reposition(x, y)
        },
    })

    ctx.addEventHandlersTo(apiObject, ['message'])
    ctx.defineVmProp({
        objhandle: apiObject,
        key: 'onmessage',
        enumerable: false,
        get: () => {
            return ctx.onMessageCallback || vm.undefined
        },
        set: (callback_) => {
            let callback

            if (vm.isNull(callback_) || vm.isUndefined(callback_)) {
                callback = undefined
            } else if (vm.isFunction(callback_)) {
                callback = callback_
            } else {
                throw Error('onmessage must be a function')
            }

            if (ctx.onMessageCallback) {
                vm.releaseHandle(ctx.onMessageCallback)
            }

            ctx.onMessageCallback = callback

            if (ctx.onMessageCallback) {
                vm.retainHandle(ctx.onMessageCallback)
            }
        },
    })

    const optionsSchema = z
        .object({
            origin: z.string().optional(),
        })
        .optional()

    ctx.defineVmFunction({
        objhandle: apiObject,
        key: 'postMessage',
        func: (message_, options_) => {
            // NOTE: 暂时用不到 options
            let options = ctx.unwrapAndValidate({
                handle: options_,
                key: 'postMessage options',
                type: optionsSchema,
            })

            if (!options) {
                options = {}
            }
            if (!options.origin) {
                options.origin = '*'
            }
            hostService.postMessageToIframe(vm.deepUnWrapHandle(message_))
        },
    })
    return apiObject
}
