/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { MouseEventHandler, useMemo } from 'react'
import { PopupStateType } from '../../../../../document/node/node'
import { useViewState } from '../../../../../view-state-bridge'
import { useDragContext } from '../drag/drag-context'
import { isItemSelected } from '../utils'
import {
    ILocalStyleItem,
    LocalStyleModel,
    getItemKeyByStyleId,
    getLocalStyleItemKey,
    getNextSelectionByMouseDown,
    isLocalStyleItemEqual,
} from './use-local-style-model-v2'

interface CommonStyleListItemProps {
    type: Wukong.DocumentProto.LocalStyleSelectionType
    itemKeyType: 'text' | 'paint' | 'effect' | 'layoutgrid'
    style: { [key: string]: string }
    itemList: ILocalStyleItem[]
    relatedSelectionItems: ILocalStyleItem[]
    item: ILocalStyleItem
    model: LocalStyleModel
    hover: string | undefined
    isReadonly?: boolean
}

export const useStyleListItem = (props: CommonStyleListItemProps) => {
    const {
        type,
        itemKeyType,
        item,
        itemList,
        relatedSelectionItems,
        model: { modelCommand, isEditingName, selection },
        hover,
        style,
        isReadonly,
    } = props

    const popupState = useViewState('popupState')

    const { dragStartRef, dragTargetInfo, dropInfo, dropContainerInfo } = useDragContext()

    const isItemSelectedWithType = () => {
        return selection.type === type && isItemSelected(item, selection)
    }

    const isAncestorSelected = () => {
        return selection.type === type && relatedSelectionItems.find((v) => isLocalStyleItemEqual(v, item))
    }

    const handleMouseDown: MouseEventHandler<HTMLElement> = (event) => {
        const { button, metaKey, shiftKey, ctrlKey } = event
        if (isReadonly) {
            event.stopPropagation()
            modelCommand.clearSelection()
            return
        }
        // 普通点击在已选中的Item时 取消选中由MouseUp去处理
        if (!metaKey && !shiftKey && button === 0 && !ctrlKey && (isItemSelectedWithType() || isAncestorSelected())) {
            return
        }
        modelCommand.updateSelection(getNextSelectionByMouseDown(event, selection, type, itemList, item))
    }

    const handleMouseUp: MouseEventHandler<HTMLElement> = (event) => {
        const { button, metaKey, shiftKey, ctrlKey } = event
        // 如果没有拖拽，则需要通过MouseUp处理选中逻辑
        if (
            !isReadonly &&
            !dragStartRef.current &&
            !metaKey &&
            !shiftKey &&
            button === 0 &&
            !ctrlKey &&
            (isItemSelectedWithType() || isAncestorSelected())
        ) {
            modelCommand.updateSelection(getNextSelectionByMouseDown(event, selection, type, itemList, item))
        }
    }

    const itemKey = getLocalStyleItemKey(itemKeyType, item)
    // 拖拽时禁用Hover样式
    const isHover = !dragTargetInfo && !dropInfo && hover === itemKey
    const isDropContainer = item.isFolder && dropContainerInfo && isLocalStyleItemEqual(dropContainerInfo, item)

    const itemClassNames = [style.row]
    if (isItemSelectedWithType()) {
        itemClassNames.push(style.main_selected)
    } else if (isAncestorSelected()) {
        itemClassNames.push(style.selected)
    } else if (isHover && !isEditingName) {
        itemClassNames.push(style.hover)
    } else if (isDropContainer) {
        itemClassNames.push(style.drop_container)
    }

    const isEditingItemName = modelCommand.isEditingItemName(type, item)
    const rowRightStyle =
        item.level >= 6 && (isHover || isEditingItemName)
            ? isEditingItemName
                ? { width: '218px' }
                : { minWidth: 218 - Math.min(6, item.level) * 24 + 'px', maxWidth: '214px' }
            : { width: 218 - Math.min(6, item.level) * 24 + 'px' }

    const isOpenPopup = useMemo(() => {
        return (
            (popupState?.type === PopupStateType.POPUP_STATE_TYPE_STYLE_VIEWER ||
                popupState?.type === PopupStateType.POPUP_STATE_TYPE_STYLE_MANAGE) &&
            itemKey === getItemKeyByStyleId(itemKeyType, selection.styleIds[0])
        )
    }, [popupState, itemKey, itemKeyType, selection.styleIds])

    return {
        itemKey,
        handleMouseDown,
        handleMouseUp,
        itemClassNames,
        isHover,
        isEditingItemName,
        rowRightStyle,
        isOpenPopup,
    }
}
