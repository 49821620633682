import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    jZimJh: '图片超过最大尺寸限制，已调整至合适尺寸',
    MAaoUM: '关于图片',
    LearnMore: '了解更多',
} as const

export const enTranslation = {
    jZimJh: 'Image resized',
    MAaoUM: 'About image',
    LearnMore: 'Learn more',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
