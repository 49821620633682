import { translation } from './blend-mode.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { useRef } from 'react'
import { useDeepCompareEffect } from 'react-use'
import { IconEyes, IconEyesClose } from '../../../../../../ui-lib/src'
import { isEnglishLanguage } from '../../../../../../util/src'
import { PopupStateType } from '../../../../document/node/node'
import { useViewState } from '../../../../view-state-bridge'
import { IconButton } from '../../atom/button/icon-button'
import { SingleGrid } from '../../atom/grid/single-grid'
import { ScrubbableInputPercent } from '../../atom/inputs/scrubbable-input-percent'
import { Title } from '../../atom/title/title'
import { BoolComponentPropPill } from '../../design-panel-v2/component-instance-panel/components/component-prop-pill'
import { ComponentPropDragPopup } from '../../design-panel-v2/component-instance-panel/components/component-prop-popup'
import style from '../paint/paint-struct.module.less'
import { VariableModeInspect } from '../variable-mode/variable-mode-inspect'
import { VariableModeSelect } from '../variable-mode/variable-mode-select'
import { BlendModeSelect } from './blend-mode-select'
import blendModeStyle from './blend-mode.module.less'
import { BoolPropApplyButton } from './bool-prop-apply-button'
import { useBlendMode } from './use-blend-mode'

export function BlendMode(): JSX.Element {
    const {
        blendModeViewState,
        onChangeBlendMode,
        onChangeOpacity2,
        onChangeVisible,
        docReadonly,
        onChangeVariableMode,
    } = useBlendMode()
    const timestamp = useRef<number>(0)
    const containerRef = useRef<HTMLDivElement>(null)
    const variableModeEntries = useViewState('variableModeEntriesInSelect')

    // fix WK-7843
    useDeepCompareEffect(() => {
        timestamp.current = 0
    }, [blendModeViewState?.passIds])

    const popupType = useViewState('popupState')

    if (docReadonly) {
        return <></>
    }

    return (
        <div className={'pt-8px pb-12px'} ref={containerRef}>
            <Title>
                <Title.Left>{translation('Layer')}</Title.Left>
                <Title.Right>
                    <div className={blendModeStyle.titleRight}>
                        <BoolPropApplyButton />
                        {variableModeEntries?.show && (
                            <VariableModeSelect
                                source="layer"
                                data={variableModeEntries as Wukong.DocumentProto.IVariableModeEntriesState}
                                onChange={onChangeVariableMode}
                            />
                        )}
                    </div>
                </Title.Right>
            </Title>
            {popupType?.type === PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_BOOL_PROP_OF_LAYER_AREA && (
                <ComponentPropDragPopup position={containerRef.current?.getBoundingClientRect()} />
            )}
            <SingleGrid>
                <SingleGrid.Item start={5} span={46}>
                    <SingleGrid className={style.backgroundGrid}>
                        <SingleGrid.Item start={1} span={26}>
                            <BlendModeSelect
                                isDisabledStyle={!blendModeViewState?.visible}
                                isMixed={blendModeViewState?.multiBlendMode}
                                value={blendModeViewState?.blendMode ?? undefined}
                                onChange={onChangeBlendMode}
                                removeIcon={isEnglishLanguage()}
                                arrowClassName={blendModeStyle.arrowClassName}
                            ></BlendModeSelect>
                        </SingleGrid.Item>
                        <SingleGrid.Item start={27} span={13}>
                            <ScrubbableInputPercent
                                isMixed={blendModeViewState?.multiOpacity}
                                value={blendModeViewState?.opacity}
                                onChange={onChangeOpacity2}
                                mixedMathHandler={{
                                    getScrubStartValueMixed: () =>
                                        new Map([
                                            [
                                                'opacity',
                                                typeof blendModeViewState?.opacity === 'number'
                                                    ? blendModeViewState?.opacity * 100
                                                    : 100,
                                            ],
                                        ]),
                                    onScrubChangeAllMixed: (v) => onChangeOpacity2(v?.get('opacity')!),
                                    onChangeMixed: (fn) =>
                                        onChangeOpacity2(
                                            fn(
                                                typeof blendModeViewState?.opacity === 'number'
                                                    ? blendModeViewState?.opacity * 100
                                                    : 100
                                            )
                                        ),
                                }}
                                min={0}
                                max={100}
                                disabled={!blendModeViewState?.visible}
                                testId="blend-opacity"
                                labelTestId="blend-opacity-scrub"
                            />
                        </SingleGrid.Item>
                        <SingleGrid.Item start={40} span={7}>
                            {blendModeViewState?.multiVisible ? (
                                <IconButton
                                    disabled={blendModeViewState.disableVisible}
                                    selected={false}
                                    icon={<IconEyes />}
                                    onChange={() => {
                                        onChangeVisible(true)
                                    }}
                                    className={style.eyeIcon}
                                />
                            ) : (
                                <IconButton
                                    disabled={blendModeViewState?.disableVisible}
                                    selected={false}
                                    icon={blendModeViewState?.visible ? <IconEyes /> : <IconEyesClose />}
                                    onChange={() => onChangeVisible(!blendModeViewState?.visible)}
                                    className={style.eyeIcon}
                                />
                            )}
                        </SingleGrid.Item>
                    </SingleGrid>
                </SingleGrid.Item>
            </SingleGrid>
            {blendModeViewState?.multiVisible && (
                <div className={blendModeStyle.tip}>
                    {translation('Click')}
                    <span className={blendModeStyle.svg}>
                        <IconEyes />
                    </span>
                    {translation('ToReplace')}
                </div>
            )}

            <BoolComponentPropPill />
            <VariableModeInspect
                source={'layer'}
                data={variableModeEntries as Wukong.DocumentProto.IVariableModeEntriesState}
                onChange={onChangeVariableMode}
            />
        </div>
    )
}
