import { forwardRef } from 'react'
import { DraggablePopupPropsV2, DraggablePopupRef, DraggablePopupV2 } from '../../../../../../../ui-lib/src'

export interface ColorPanelContainerProps extends DraggablePopupPropsV2 {}

function _ColorPanelContainer(
    props: React.PropsWithChildren<ColorPanelContainerProps>,
    ref: React.Ref<DraggablePopupRef>
) {
    return (
        <DraggablePopupV2
            {...props}
            visible
            styleType="editor"
            footer={null}
            bodyClassName="p-0"
            positionRightBase={props.positionRightBase !== false}
            overflowVisible
            closable={false}
            keepStableTop
            ref={ref}
        >
            {props.children}
        </DraggablePopupV2>
    )
}

export const ColorPanelContainerV2 = forwardRef<DraggablePopupRef, ColorPanelContainerProps>(_ColorPanelContainer)
