import { Wukong } from '@wukong/bridge-proto'
import { HyperlinkCreator } from './hyperlink-creator'
import { HyperlinkEditor } from './hyperlink-editor'
import { useHyperlinkModel } from './use-hyperlink-model'

export function HyperlinkEditorPopup() {
    const model = useHyperlinkModel()

    if (!model.modelState) {
        return null
    }

    if (model.modelState.mode == Wukong.DocumentProto.HyperlinkEditingMode.HYPERLINK_EDITING_MODE_CREATE) {
        return <HyperlinkCreator model={model} />
    } else if (model.modelState.mode == Wukong.DocumentProto.HyperlinkEditingMode.HYPERLINK_EDITING_MODE_EDIT) {
        return <HyperlinkEditor model={model} />
    } else {
        return null
    }
}
