/* eslint-disable no-restricted-imports */
import {
    DeleteSelectionFillStyleIdCommandWasmCall,
    DetachSelectionFillStyleIdCommandWasmCall,
    UpdateSelectionFillPaintForSameOrAddCommand,
    UpdateSelectionFillPaintsCommand,
    UpdateSelectionFillPaintsForEmptyCommand,
    UpdateSelectionFillStyleIdCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { useCallback, useMemo } from 'react'
import { Paint } from '../../../../../document/node/node'
import { useCommand } from '../../../../../main/app-context'
import { useViewState } from '../../../../../view-state-bridge'

const useFillViewState = () => {
    const selectionFill = useViewState('selectionFill')

    return useMemo(() => {
        if (!selectionFill) {
            return { type: 'none' } as const
        }

        switch (selectionFill.type) {
            case Wukong.DocumentProto.SelectionFillType.SELECTION_FILL_TYPE_NORMAL: {
                return {
                    type: 'normal',
                    passIds: selectionFill.passIds ?? ([] as string[]),
                    paintStyleNode: null,
                    failIds: selectionFill.failIds ?? ([] as string[]),
                    paints: selectionFill.fills as Paint[],
                    variables: selectionFill.variables ?? [],
                } as const
            }
            case Wukong.DocumentProto.SelectionFillType.SELECTION_FILL_TYPE_STYLE: {
                return {
                    type: 'style',
                    passIds: selectionFill.passIds ?? ([] as string[]),
                    paintStyleNode: selectionFill.paintStyleNode,
                    failIds: selectionFill.failIds ?? ([] as string[]),
                    fillStyleId: selectionFill.fillStyleId,
                    variables: selectionFill.variables ?? [],
                } as const
            }
            case Wukong.DocumentProto.SelectionFillType.SELECTION_FILL_TYPE_MIXED:
                return {
                    type: 'mixed',
                } as const
            case Wukong.DocumentProto.SelectionFillType.SELECTION_FILL_TYPE_EMPTY: {
                // 选中节点没有填充的情况统一当中 normal 处理
                if (selectionFill.passIds.length || selectionFill.failIds.length) {
                    return {
                        type: 'normal',
                        passIds: selectionFill.passIds ?? ([] as string[]),
                        paintStyleNode: null,
                        failIds: selectionFill.failIds ?? ([] as string[]),
                        paints: selectionFill.fills as Paint[],
                        variables: selectionFill.variables ?? [],
                    } as const
                }
                return { type: 'none' } as const
            }
        }
    }, [selectionFill])
}

export const useFillViewModal = () => {
    const modelState = useFillViewState()
    const command = useCommand()

    const changeFillPaints = useCallback(
        (paints: readonly Paint[]) => {
            command.DEPRECATED_invokeBridge(
                UpdateSelectionFillPaintsCommand,
                Wukong.DocumentProto.UpdateSelectionPaintParam.create({
                    paints: paints as Wukong.DocumentProto.Paint[],
                })
            )
        },
        [command]
    )
    const detachFillStyleId = useCallback(
        (paints: readonly Paint[]) => {
            command.DEPRECATED_invokeBridge(
                DetachSelectionFillStyleIdCommandWasmCall,
                Wukong.DocumentProto.UpdateSelectionPaintParam.create({
                    paints: paints as Wukong.DocumentProto.Paint[],
                })
            )
            command.commitUndo()
        },
        [command]
    )
    const updateFillStyleId = useCallback(
        (fillStyleId: string) => {
            command.DEPRECATED_invokeBridge(
                UpdateSelectionFillStyleIdCommand,
                Wukong.DocumentProto.BridgeProtoString.create({ value: fillStyleId })
            )
        },
        [command]
    )
    const deleteSelectionFillStyleId = useCallback(() => {
        command.DEPRECATED_invokeBridge(DeleteSelectionFillStyleIdCommandWasmCall)
        command.commitUndo()
    }, [command])
    const changeFillPaintsForEmpty = useCallback(
        (paints: readonly Paint[]) => {
            command.DEPRECATED_invokeBridge(
                UpdateSelectionFillPaintsForEmptyCommand,
                Wukong.DocumentProto.UpdateSelectionPaintParam.create({
                    paints: paints as Wukong.DocumentProto.Paint[],
                })
            )
        },
        [command]
    )
    const updateSelectionFillPaintForSameOrAdd = useCallback(() => {
        command.DEPRECATED_invokeBridge(UpdateSelectionFillPaintForSameOrAddCommand)
    }, [command])

    return {
        modelState,
        modelCommand: {
            changeFillPaints,
            detachFillStyleId,
            updateFillStyleId,
            deleteSelectionFillStyleId,
            changeFillPaintsForEmpty,
            updateSelectionFillPaintForSameOrAdd,
        },
    }
}
