import { translation } from './stroke-mode.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { IconStrokeDotLine, IconStrokeNone, Select } from '../../../../../../../ui-lib/src'
import { EditorDataTestId } from '../../../../../window'
import { SingleGrid } from '../../../atom/grid/single-grid'
import style from '../advance-stroke/advance-stroke.module.less'
import { useStrokeMode } from './use-stroke-mode'

export function StrokeMode() {
    const { modeState, switchMode } = useStrokeMode()
    return (
        <SingleGrid className={style.grid}>
            <SingleGrid.Item
                start={5}
                span={28}
                className={
                    modeState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_DISABLED
                        ? style.disabled
                        : undefined
                }
            >
                {translation('StrokeStyle')}
            </SingleGrid.Item>
            <SingleGrid.Item start={35} span={22}>
                <Select.NormalSingleLevel
                    value={modeState?.value}
                    onChange={switchMode}
                    isMixed={modeState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED}
                    disabled={
                        modeState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_DISABLED
                    }
                    icon={
                        modeState?.value === Wukong.DocumentProto.StrokeModeType.STROKE_MODE_TYPE_SOLID ? (
                            <IconStrokeNone />
                        ) : modeState?.value === Wukong.DocumentProto.StrokeModeType.STROKE_MODE_TYPE_DASH ? (
                            <IconStrokeDotLine />
                        ) : undefined
                    }
                    label={
                        modeState?.value === Wukong.DocumentProto.StrokeModeType.STROKE_MODE_TYPE_SOLID
                            ? translation('Solid')
                            : modeState?.value === Wukong.DocumentProto.StrokeModeType.STROKE_MODE_TYPE_DASH
                            ? translation('Dash')
                            : ''
                    }
                    minWidth={112}
                    dataTestIds={{ triggerFocus: EditorDataTestId.StrokeAttr.ModeSelectTrigger }}
                    iconStyle={{ marginRight: 0 }}
                >
                    <Select.NormalSingleLevel.Option
                        value={Wukong.DocumentProto.StrokeModeType.STROKE_MODE_TYPE_SOLID}
                        backwardIcon={''}
                        forwardIcon={<IconStrokeNone />}
                    >
                        {translation('Solid')}
                    </Select.NormalSingleLevel.Option>
                    <Select.NormalSingleLevel.Option
                        value={Wukong.DocumentProto.StrokeModeType.STROKE_MODE_TYPE_DASH}
                        backwardIcon={''}
                        forwardIcon={<IconStrokeDotLine />}
                    >
                        {translation('Dash')}
                    </Select.NormalSingleLevel.Option>
                </Select.NormalSingleLevel>
            </SingleGrid.Item>
        </SingleGrid>
    )
}
