import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    gUpQFe: '已选中',
    Selected: '个图层',
} as const

export const enTranslation = {
    gUpQFe: '',
    Selected: 'Selected',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
