/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { IconStrokeWeight } from '../../../../../../../ui-lib/src'
import { forwardRef, useImperativeHandle, useState } from 'react'
import { EditorDataTestId } from '../../../../../window'
import { ScrubbableInputNumber } from '../../../atom/inputs/scrubbable-input-number'
import { StrokeAllBorderWeightRef, useStrokeAllBorderWeight } from './use-stroke-all-border-weight'

// 全线重输入框
export const StrokeAllBorderWeight = forwardRef<
    StrokeAllBorderWeightRef,
    {
        isOpenFourIndependentWeight: boolean
    }
>((props, ref) => {
    const [typeState, setTypeState] = useState<Wukong.DocumentProto.ISelectionStrokeIndependentTypeState | undefined>()
    const { allBorderState, onChangeWeight } = useStrokeAllBorderWeight({ ...props, typeState })

    useImperativeHandle(
        ref,
        () => ({
            setTypeState,
        }),
        []
    )

    return allBorderState &&
        allBorderState.type !== Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_EMPTY ? (
        <ScrubbableInputNumber
            labelTestId={EditorDataTestId.StrokeAttr.WeightInputLabel}
            icon={<IconStrokeWeight />}
            isMixed={allBorderState.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED}
            value={allBorderState.value || undefined}
            onChange={onChangeWeight}
            min={0}
            scrubbingDisabled={
                allBorderState.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
            }
            testId={EditorDataTestId.StrokeAttr.WeightInput}
        />
    ) : (
        <></>
    )
})

StrokeAllBorderWeight.displayName = 'StrokeAllBorderWeight'
