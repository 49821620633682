import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Zoom: '缩放',
    ViewOptions: '查看选项',
} as const

export const enTranslation = {
    Zoom: 'Zoom',
    ViewOptions: 'view options',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
