/* eslint-disable no-restricted-imports */
import { GET } from '@tutor/network-core'
import { DocID } from '../../kernel/interface/type'
import { BaseCommonRequest } from '../../kernel/request/base-request'

export interface SchemaVersion {
    schemaVersion: number
}

@GET
export class GetSchemaVersionByDocId extends BaseCommonRequest<SchemaVersion> {
    constructor(private readonly docId: DocID) {
        super()
    }

    override requestUrl(): string {
        return `doc-meta/schema-version/${this.docId}`
    }
}
