import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Style: '样式',
    StyleAndVariable: '样式与变量',
    SelectItemsUsing: '选中使用该颜色的 {{usingNodeCount}} 个图层',
} as const

export const enTranslation = {
    Style: 'Style',
    StyleAndVariable: 'Styles and variables',
    SelectItemsUsing: 'Select {{usingNodeCount}} {{item}} using this color',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
