import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    Layers: '图层',
    Options: '更多操作',
    CollapseAllLayers: '折叠所有图层',
} as const

export const enTranslation = {
    Layers: 'Layers',
    Options: 'Options',
    CollapseAllLayers: 'Collapse all layers',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
