import { StoreProvider } from 'ccstate-react'
import { lazy, StrictMode, Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { RouterProvider } from 'react-router-dom'
import { anySignal } from '../../../../../util/src/abort-controller/abort-any'
import { ignoreWindowAbortError } from '../../../../../util/src/abort-controller/abort-error'
import { createSignalBrowserRouter$ } from '../../../../../util/src/abort-controller/signal-browser-router'
import { TraceableAbortSignal } from '../../../../../util/src/abort-controller/traceable-abort-controller'
import { transaction } from '../../../../../util/src/abort-controller/traceable-transaction'
import { generateRouterPath } from '../../../../../util/src/route'
import { RouteToken } from '../../../../../util/src/routes'
import { webappRouteConfigs } from '../../../app-layout/route/route'
import { environment } from '../../../environment'
import { isCypress } from '../../../kernel/util/ua'
import { WebVitalsCollector } from '../../../kernel/web-vitals'
import { $initStore } from '../../store'
import { bootstrapCommon$ } from './bootstrap/common'
import { bootstrapMirrorApp$ } from './bootstrap/mirror'
import { setupBootstrapWebappFlow$ } from './bootstrap/web'
import { FallbackComponent } from './error-boundary'
import { Skeleton } from './skeleton'

const MirrorApp = lazy(() => import('../../../mirror').then(({ App }) => Promise.resolve({ default: App })))
const SandBoxApp = lazy(() =>
    import('../../../sandbox').then(({ SandBoxPage }) => Promise.resolve({ default: SandBoxPage }))
)
const WebApp = lazy(() =>
    import('../../../app-layout/layout').then(({ Layout }) => Promise.resolve({ default: Layout }))
)

/**
 * WebApp & Jest Context 创建 APP 入口
 * 由路由的 loader 开始启动 bootstrap
 * @param signal
 * @param render
 */
export function createApp<R>(signal: TraceableAbortSignal, render: (node: React.ReactNode) => R): R {
    const store$ = $initStore()

    const { act } = transaction(signal)

    act('ignore window abort error', () => {
        // 拦截所有的 AbortError
        const cleanup = ignoreWindowAbortError()

        return () => {
            cleanup()
        }
    })

    act('polyfill AbortSignal.any', () => {
        const oldAny = AbortSignal.any

        // 当前浏览器支持力度不够，polyfill AbortSignal.any
        AbortSignal.any = anySignal

        return () => {
            AbortSignal.any = oldAny
        }
    })

    // add webvitals
    if (!environment.isDev && !isCypress()) {
        new WebVitalsCollector(signal)
    }

    const router = store$.set(createSignalBrowserRouter$, signal, [
        {
            path: generateRouterPath(environment.mirrorBasePath) + '/*',
            element: (
                <Suspense fallback={<></>}>
                    <MirrorApp />
                </Suspense>
            ),
            setup$: bootstrapMirrorApp$,
        },
        {
            path: generateRouterPath('sandbox'),
            element: (
                <StoreProvider value={store$}>
                    <Suspense fallback={<></>}>
                        <SandBoxApp />
                    </Suspense>
                </StoreProvider>
            ),
            setup$: bootstrapCommon$,
        },
        {
            path: generateRouterPath(RouteToken.LoginDoNotUse),
            element: <div>noop</div>,
        },
        {
            path: generateRouterPath('*'),
            children: webappRouteConfigs,
            element: (
                <StoreProvider value={store$}>
                    <ErrorBoundary fallbackRender={({ error }) => <FallbackComponent error={error} />}>
                        <Skeleton>
                            <WebApp />
                        </Skeleton>
                    </ErrorBoundary>
                </StoreProvider>
            ),
            setup$: setupBootstrapWebappFlow$,
        },
    ])

    return render(
        <StrictMode>
            <RouterProvider router={router} />
        </StrictMode>
    )
}
