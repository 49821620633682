import {
    FeatureSwitchConfig,
    SwitchEnvironmentScopeType,
    SwitchStrategyType,
    UserGroupType,
} from '../feature-switch-config'

const Config: FeatureSwitchConfig = {
    name: 'desktop-update',
    owner: '',
    description: '',
    strategies: [
        {
            config: {
                type: SwitchStrategyType.SPECIFIC_SUFFIX,
                suffix: [
                    '@kanyun.com',
                    '@kanzhun.com',
                    '@fenbi.com',
                    '@bytedance.com',
                    '@yyinedu.com',
                    '@gridcoffee.com',
                    '@skypeople.com',
                ],
            },
            env: SwitchEnvironmentScopeType.ALL,
        },
        {
            config: { type: SwitchStrategyType.SPECIFIC_USER_LIST, group: UserGroupType.INLAND_REST_ORG_USER },
            env: SwitchEnvironmentScopeType.ALL,
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
