import { Wukong } from '@wukong/bridge-proto'

export function getFieldFloatVariable(
    field: Wukong.DocumentProto.VariableField,
    variableConsumptionMapState?: Wukong.DocumentProto.IVariableConsumptionMapState
) {
    if (!variableConsumptionMapState) {
        return
    }
    const { items, floatVariables } = variableConsumptionMapState
    const item = items.find((v) => v.field === field)
    const floatVariable = floatVariables.find((v) => v.id === item?.variableData?.value.alias)
    return floatVariable
}

export function getFieldItem(
    field: Wukong.DocumentProto.VariableField,
    variableConsumptionMapState?: Wukong.DocumentProto.IVariableConsumptionMapState
) {
    if (!variableConsumptionMapState) {
        return
    }
    const { items } = variableConsumptionMapState
    const item = items.find((v) => v.field === field)
    return item
}
