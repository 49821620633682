import { WKToast } from '../../../../../../../../ui-lib/src/components/wk-toast'
import { PopupStateType } from '../../../../../../document/node/node'
import { ComponentPropNodeField, ValidateInstanceSwapValueScene, type ComponentPropEditPopupVal } from '../../types'
import { useComponentProp } from '../component-prop/hook'
import { BaseInstanceSwapPropPill, convertRealComponentPropType2Virtual } from './base-component-prop-pill'
import style from './index.module.less'
import { translation } from './instance-swap-component-prop-pill.translation'

// 暂时只有 Normal 状态的版本，其他状态的版本暂时不需要
export function InstanceSwapComponentPropPill() {
    const {
        componentPropDefs,
        sharedInstanceSwapPropDefOfSelectedNodes,
        detachProp,
        applyProp,
        locateComponentProp,
        toggleComponentPropPopup,
        validateInstanceSwapValue,
    } = useComponentProp()

    return (
        <BaseInstanceSwapPropPill
            className={{ container: style.instanceSwapPropPillContainer }}
            dataTestId="instance-swap-prop-pill"
            propDefs={componentPropDefs}
            displayProp={sharedInstanceSwapPropDefOfSelectedNodes}
            pillStatus="normal"
            zombiePillAddBtn={false}
            detachProp={() => {
                detachProp(sharedInstanceSwapPropDefOfSelectedNodes.detachDatas)
            }}
            applyProp={(defId: string) => {
                if (
                    validateInstanceSwapValue({
                        scene: ValidateInstanceSwapValueScene.VALIDATE_INSTANCE_SWAP_VALUE_SCENE_APPLY_INSTANCE_SWAP,
                        newInstanceSwapPropId: defId,
                        affectedNodeIds: [],
                    })
                ) {
                    applyProp(ComponentPropNodeField.COMPONENT_PROP_NODE_FIELD_OVERRIDDEN_SYMBOL_ID, defId)
                } else {
                    WKToast.show(translation('ComponentCanNotContainItself'))
                }
            }}
            createProp={() => {
                toggleComponentPropPopup(
                    PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_INSTANCE_SWAP_PROP_OF_INSTANCE_AREA
                )
            }}
            editProp={() => {
                const shouldOpenEditPopup = locateComponentProp(sharedInstanceSwapPropDefOfSelectedNodes.editedPropId)
                if (shouldOpenEditPopup.value) {
                    toggleComponentPropPopup(PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_INSTANCE_SWAP_PROP, {
                        id: sharedInstanceSwapPropDefOfSelectedNodes.editedPropId,
                        name: sharedInstanceSwapPropDefOfSelectedNodes.name,
                        type: convertRealComponentPropType2Virtual(sharedInstanceSwapPropDefOfSelectedNodes.type),
                        varValue: sharedInstanceSwapPropDefOfSelectedNodes.varValue,
                        preferredValues: sharedInstanceSwapPropDefOfSelectedNodes.preferredValues,
                    } as ComponentPropEditPopupVal)
                }
            }}
            zombiePillAddProp={() => []}
            disabledPillLocateProp={() => {}}
        />
    )
}
