import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    DesignSystem: '设计系统',
    SingleComponent: ' 个组件',
    PluralComponent: ' 个组件',
    Comma: '，',
    SingleStyle: ' 个样式',
    PluralStyle: ' 个样式',
    LocalLibrary: '本地组件库',
    EmptyPromptFirstLine: '没有可使用的设计系统',
    EmptyPromotSecondLine: '请在发布组件库后再试',
} as const

export const enTranslation = {
    DesignSystem: 'Design systems',
    SingleComponent: ' component',
    PluralComponent: ' components',
    Comma: ', ',
    SingleStyle: ' style',
    PluralStyle: ' styles',
    LocalLibrary: 'Local library',
    EmptyPromptFirstLine: 'No design systems available',
    EmptyPromotSecondLine: 'Please try again after publishing a library',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
