import {
    FeatureSwitchConfig,
    SwitchEnvironmentScopeType,
    SwitchStrategyType,
    UserGroupType,
} from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'wk-admin',
    owner: '',
    description: '',
    strategies: [
        {
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                userList: [
                    '14f4742c842da9c4df500abf7791e8edf6a7345d',
                    'c25a7c619bd3d207ef342fb9328434439de70bd6',
                    'ba359f8f7cabb59bea6a1c75fceeffdef1f40827',
                    '18aab5995460387397ff45fbf8b20579f44facee',
                    'ab2d23165c5824b0cccd163b090c5986cf788253',
                    '7a97a942c20b4bf74c901e9f34772c5a9b699f44',
                    '815c1bc60a12c15d19e320526962b4a6281d3342',
                    '2ce4665f28bf253877c3c2193651c43cab63066a',
                    '37b4208d9301d2607fcb9a638988a7b569fa92f5',
                    '572e8113bc837e3d897438f0c7bb1f4fd64b3abe',
                    '93b2d50b70abd736fe8799361dc94170a0598567',
                    'd7bd1df2675e57005a7596c0197a4bd31ecbc950',
                    '9d6bf699ed20fc7bfe83d4b392dae8722b702339',
                    '20dff2aaca522ac44bf55db0acda5212222716a4',
                    'b90d3727f903bc29e7785c6fa6def25a62f45b3b',
                    '7e0a41831dcc0d46b6bc3965590112752b6e62cd',
                    '6f2a586ce90b01e986c4b79e89fc78fe796229e4',
                    'cdce0e4e6269452c5f72cbf6025b2d53649cf6b4',
                    '57434b1c0afd109373efd66ab0cc5ed1569a4333',
                    '354b7762289268d1d33a9d364bd6c1bfdd0534ca',
                    '940909b82fdc5bbd708e5fe2c403e9ab9355c267',
                    '414812dc17b993dd5c3576d9f6287166fb993f11',
                    '67001c05d873b3128d9f09cb7faa91c12c9d4aef',
                    'cff50cb5a26acb11aea4dac603e98ca5f6d6a213',
                    'ef0783d98dc23b86402b3eb9437dd9c86e101ba5',
                    'a7624e611527d04a2ae1f97d1d24c8e51b7f0f22',
                    'c2520912ef296753265cdcdcf7564f496c39c41a',
                    'f60e805d8ac53117369ef63077dbd96b097f6159',
                    '744f848c90cfc7f61fc3e354ec3be2b6d7c02215',
                    '22f9c521b91cec11b3c4c8e5681d4d2fda4b8f1e',
                    'a2c0199b5d34e5b9fd73c148b260dbe0f2a671be',
                    'b02d0d9ae7de3736f97b6f1c5b162ac7dde63a1a',
                    '07de847f05c0a1ab68862dd89660254ba39d540c',
                    '83d894b6e504d771fef8bc2adc7dd6950aef31ae',
                    '96a0470e759201f48ceb2d2f9f134df24a508b59',
                    'f5789553a28d25cf9e3a29f1183d92ec1d0c678c',
                    '98a64953c2f72dcc99fa13393e388ffb5c0f5f1c',
                    '32e0168e82e551a6413c5d3c2fef2492855021ae',
                    '905875fc0919c2dae30154a89c9722e9de6cb2fe',
                    '69362cdfa622a5d3e0312ca637fbfeb03e4fc0aa',
                    '3b340595c90d00f4b79756a837bfdfd492ac091d',
                    '08ac0f16bed1e112f441fbbe775ff11a1957821a',
                    'a1b0352e6ad2e5cb790ad61edf482d9e92b73429',
                    'b60b4cb8ac913b2f117ba425e190902d66e87af1',
                    'b685a88b95ce4e90918c79c562470bf01ec5c1cb',
                    '7278dd2fcb881e28064dd9572405f6079db31f66',
                    'a33872d486c5180a931f00bfe0e7f5315f7db58c',
                    '848154b842d852a02cd56aae93f06e95617a3f5b',
                    '57434b1c0afd109373efd66ab0cc5ed1569a4333',
                    'd7e45b450b5cd8fb9d806cfa0ace29746b996408',
                    '3c80e31e15ffc00ad42f561d834062e3aa1163f1',
                    'b67959a4aed14cd4cfb9c523449d62f3165ce575',
                    'f7963b3f7e23de6c70e2dbe090cbce97183df403',
                    'cc448a05f972eaa52d0745e77d4b9fa4a9cafbc5',
                    '5fbc10511ed3350efb551d03ac523a05f77dfb22',
                ],
            },
            env: SwitchEnvironmentScopeType.TESTING,
        },
        {
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                userList: [
                    '14f4742c842da9c4df500abf7791e8edf6a7345d',
                    'ab2d23165c5824b0cccd163b090c5986cf788253',
                    '7a97a942c20b4bf74c901e9f34772c5a9b699f44',
                    'a276a0a764a2156e196a98d8ab0bd2efa7688881',
                    'ba359f8f7cabb59bea6a1c75fceeffdef1f40827',
                    '225eb66e04c4fbf5474dc54c786bcd347796f116',
                    '815c1bc60a12c15d19e320526962b4a6281d3342',
                    '9d6bf699ed20fc7bfe83d4b392dae8722b702339',
                    '93b2d50b70abd736fe8799361dc94170a0598567',
                    'c25a7c619bd3d207ef342fb9328434439de70bd6',
                    '20dff2aaca522ac44bf55db0acda5212222716a4',
                    'b90d3727f903bc29e7785c6fa6def25a62f45b3b',
                    '2ce4665f28bf253877c3c2193651c43cab63066a',
                    '37b4208d9301d2607fcb9a638988a7b569fa92f5',
                    '940909b82fdc5bbd708e5fe2c403e9ab9355c267',
                    '67001c05d873b3128d9f09cb7faa91c12c9d4aef',
                    'cdce0e4e6269452c5f72cbf6025b2d53649cf6b4',
                    'a7624e611527d04a2ae1f97d1d24c8e51b7f0f22',
                    'f60e805d8ac53117369ef63077dbd96b097f6159',
                    '744f848c90cfc7f61fc3e354ec3be2b6d7c02215',
                    '22f9c521b91cec11b3c4c8e5681d4d2fda4b8f1e',
                    'a2c0199b5d34e5b9fd73c148b260dbe0f2a671be',
                    'b02d0d9ae7de3736f97b6f1c5b162ac7dde63a1a',
                    '6f2a586ce90b01e986c4b79e89fc78fe796229e4',
                    '07de847f05c0a1ab68862dd89660254ba39d540c',
                    '83d894b6e504d771fef8bc2adc7dd6950aef31ae',
                    '96a0470e759201f48ceb2d2f9f134df24a508b59',
                    '137d0ec285db6de87bbb9bcb1f7c12e83a34b179',
                    '6e909ef52e272280feb55b200ced3b15f8bfb0f4',
                    'f5789553a28d25cf9e3a29f1183d92ec1d0c678c',
                    '98a64953c2f72dcc99fa13393e388ffb5c0f5f1c',
                    '32e0168e82e551a6413c5d3c2fef2492855021ae',
                    '905875fc0919c2dae30154a89c9722e9de6cb2fe',
                    '69362cdfa622a5d3e0312ca637fbfeb03e4fc0aa',
                    '3b340595c90d00f4b79756a837bfdfd492ac091d',
                    '08ac0f16bed1e112f441fbbe775ff11a1957821a',
                    'a1b0352e6ad2e5cb790ad61edf482d9e92b73429',
                    'b60b4cb8ac913b2f117ba425e190902d66e87af1',
                    'b685a88b95ce4e90918c79c562470bf01ec5c1cb',
                    'e56926dedc5d02235dc4a2809172e5f8364aa094',
                    '7278dd2fcb881e28064dd9572405f6079db31f66',
                    'a33872d486c5180a931f00bfe0e7f5315f7db58c',
                    '848154b842d852a02cd56aae93f06e95617a3f5b',
                    '57434b1c0afd109373efd66ab0cc5ed1569a4333',
                    'd7e45b450b5cd8fb9d806cfa0ace29746b996408',
                    '3c80e31e15ffc00ad42f561d834062e3aa1163f1',
                    'b67959a4aed14cd4cfb9c523449d62f3165ce575',
                    'f7963b3f7e23de6c70e2dbe090cbce97183df403',
                    'cc448a05f972eaa52d0745e77d4b9fa4a9cafbc5',
                    '5fbc10511ed3350efb551d03ac523a05f77dfb22',
                ],
            },
            env: SwitchEnvironmentScopeType.PRODUCTION,
        },
        {
            config: { type: SwitchStrategyType.SPECIFIC_USER_LIST, group: UserGroupType.RD },
            env: SwitchEnvironmentScopeType.ALL,
        },
        {
            config: { type: SwitchStrategyType.SPECIFIC_USER_LIST, group: UserGroupType.QA },
            env: SwitchEnvironmentScopeType.ALL,
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
