import { Wukong } from '@wukong/bridge-proto'

// 计算节点放置的 indent
export const caculatePlaceNodeIndent = (
    currentItemIndent: number,
    minIndent: number,
    selectionMinIndent: number,
    movementIndent: number
): number => {
    // 如果没有人为变更过 movement, 则默认放置到选中节点的最小层级
    const noMovementIndent = Math.max(minIndent, Math.min(currentItemIndent, selectionMinIndent))
    if (movementIndent === 0) {
        return noMovementIndent
    }
    // 否则放置到添加位移 indent 后的合适位置
    return Math.max(minIndent, Math.min(noMovementIndent + movementIndent, currentItemIndent))
}

export const LayerPanelItemType2Height = {
    [Wukong.DocumentProto.LayerPanelItemType.LAYER_PANEL_ITEM_TYPE_NODE]: 32,
    [Wukong.DocumentProto.LayerPanelItemType.LAYER_PANEL_ITEM_TYPE_SECTION]: 28,
    [Wukong.DocumentProto.LayerPanelItemType.LAYER_PANEL_ITEM_TYPE_SEE_ALL_LAYERS]: 32,
}

export const getItemIndent = (item_: Wukong.DocumentProto.VLayerPanelItemInfo | undefined) => {
    if (item_) {
        switch (item_.type) {
            case Wukong.DocumentProto.LayerPanelItemType.LAYER_PANEL_ITEM_TYPE_NODE:
                return item_.layerPanelNodeInfo.indent
            case Wukong.DocumentProto.LayerPanelItemType.LAYER_PANEL_ITEM_TYPE_SECTION:
                return item_.layerPanelSectionInfo.indent
            case Wukong.DocumentProto.LayerPanelItemType.LAYER_PANEL_ITEM_TYPE_SEE_ALL_LAYERS:
                return item_.layerPanelSeeAllLayersInfo.indent
        }
    }
    return 0
}

export const getItemId = (item_: Wukong.DocumentProto.VLayerPanelItemInfo | undefined): string => {
    if (item_) {
        switch (item_.type) {
            case Wukong.DocumentProto.LayerPanelItemType.LAYER_PANEL_ITEM_TYPE_NODE:
                return item_.layerPanelNodeInfo.id!
            case Wukong.DocumentProto.LayerPanelItemType.LAYER_PANEL_ITEM_TYPE_SECTION:
                return item_.layerPanelSectionInfo.sectionId!
            case Wukong.DocumentProto.LayerPanelItemType.LAYER_PANEL_ITEM_TYPE_SEE_ALL_LAYERS:
                return item_.layerPanelSeeAllLayersInfo.seeAllLayerId!
        }
    }
    return ''
}

export const getItemInfoByTopHeight = (
    topHeight: number,
    renderList: Wukong.DocumentProto.VLayerPanelItemInfo[],
    itemTopHeightMap: Map<string, number>
) => {
    let left = 0
    let right = renderList.length - 1
    while (left <= right) {
        const mid = Math.floor(left + (right - left) / 2)
        const midTopHeight = itemTopHeightMap.get(getItemId(renderList[mid]))!
        if (midTopHeight < topHeight) {
            left = mid + 1
        } else if (midTopHeight > topHeight) {
            right = mid - 1
        } else {
            return renderList[Math.max(mid - 1, 0)]
        }
    }

    return renderList[Math.max(right, 0)]
}
