import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    maskMode: '蒙版类型',
    maskModeDescription: '你可以使用「透明蒙版」或「轮廓蒙版」来创建不同的遮罩效果。',
    aiDuplicate: '使用 AI 复制，快速创建内容不同的副本',
    aiDuplicateDescription: '选中需要创建副本的图层，拖动图层右下角的手柄即可开始 AI 复制。',
    done: '我知道了',
    spaceFavoritesDesc: '现在你可以在这里查看收藏的文件，点击即可快速打开文件。',
    sharedProjectsDesc: '共享项目现在可以在这里查看。',
    aiGenDesignSystemTitle: 'AI 生成 UI 支持选择预设风格与改编',
    aiGenDesignSystemDescription:
        '选择预设风格或个性化改编，让你的 AI 生成界面更稳定、更一致。现在，移动端已支持选用 Material Design，网页端可选用 Ant Design 或 shadcn/ui。',
    aiGenPrimaryBtnText: '去练习场体验',
    aiGenKnowMoreBtnText: '了解更多',
} as const

export const enTranslation = {
    maskMode: 'Mask mode',
    maskModeDescription: 'You can switch between “Alpha Mask” and “Outline Mask” for different masking effects.',
    aiDuplicate: 'Use AI Reduplication to create different copies',
    aiDuplicateDescription: 'Select layer and drag the handle to start AI Reduplication.',
    done: 'Done',
    spaceFavoritesDesc: 'Now you can view your favorite files here and quickly open them with a click.',
    sharedProjectsDesc: 'Shared projects can now be viewed here.',
    aiGenDesignSystemTitle: 'AI Generates UI now supports styling presets selection and remix',
    aiGenDesignSystemDescription:
        'Choose styling presets or remix your own stylings for more stable and consistent AI-generated interfaces. Mobile now supports Material Design, and web offers Ant Design and shadcn/ui.',
    aiGenPrimaryBtnText: 'Explore Playground',
    aiGenKnowMoreBtnText: 'Learn more',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
