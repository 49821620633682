import { gzip } from 'pako'
import { IN_JEST_TEST } from '../../../environment'
import { debugLog } from '../../debug'
import { IEntry, IKeyValue, IPostData, PostData } from '../protobuf/proto'
import { getApiPath } from './config/clog-param'
import { CLOGCATEGORY, CLOGENV, CLOGHOST } from './config/environment'
import { getHost, syncHost } from './config/host'
import Config from './config/index'
import { Header, HeaderValue } from './header'
import LS from './local-storage'
import { LogValue } from './type'
import { generateTraceId, seqId, transObjectToKeyValue } from './util'

export interface LogParam {
    url: string
    values: IKeyValue[] | LogValue
}

export type LogParams = LogParam | LogParam[]

export interface WebLogParam {
    url: string
    content: string | { [key: string]: any }
    extras?: IKeyValue[] | { [key: string]: string }
}

/**
 * 为日志增加失败次数，增加_RetryCountBeforeSuccess
 * @param entries 日志数据
 */
function increaseEntryCount(logData: IPostData) {
    const entries = logData.entries as IEntry[]
    entries.forEach((entry) => {
        const values = entry.keyValues || []
        const item = values.find((value) => {
            return value.key === '_RetryCountBeforeSuccess'
        })
        if (item) {
            item.value = `${Number(item.value) + 1}`
        } else {
            values.push({
                key: '_RetryCountBeforeSuccess',
                value: '1',
            })
            entry.keyValues = values
        }
    })
    return logData
}

/**
 * 格式化输出 IEntry[]
 * @param param 日志数据
 */
export function formatEntries(param: LogParams, category: CLOGCATEGORY) {
    let data = []
    if (Array.isArray(param)) {
        data = param
    } else {
        data = [param]
    }

    // 格式化数据
    const entries = data.map((logParam) => {
        const values = logParam.values
        let keyValues = values
        if (!Array.isArray(values)) {
            keyValues = transObjectToKeyValue(values)
        }
        // check value, transfer to string
        ;(keyValues as Array<IKeyValue>).forEach((item) => {
            item.value = `${item.value}`
        })
        return {
            timestamp: Date.now(),
            seqId: seqId(),
            url: logParam.url,
            category,
            keyValues: keyValues as IKeyValue[],
        }
    })
    return entries
}

/**
 * 发送请求
 * @param data
 */
export function sendRequest(url: string, data: IPostData) {
    const pbData = PostData.create(data)
    const postData = PostData.encode(pbData).finish()
    const bodyData = gzip(postData)

    return new Promise<void>((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                const status = xhr.status
                if (status > 199 && status < 300) {
                    resolve()
                } else {
                    reject({
                        status,
                        message: xhr.statusText,
                    })
                }
            }
        }
        xhr.onabort = () =>
            reject({
                message: 'abort',
            })
        xhr.onerror = () =>
            reject({
                message: 'error',
            })
        xhr.ontimeout = () =>
            reject({
                message: 'timeout',
            })
        xhr.timeout = 10000 // 10s

        xhr.open('POST', url, true)

        xhr.setRequestHeader('Content-Type', 'application/x-protobuf;charset=UTF-8; delimited=false')
        xhr.setRequestHeader('Content-Encoding', 'gzip')

        xhr.send(bodyData)
    })
}

export class Log {
    private localCache: Array<IPostData> = []
    private localTimer = 0
    private localSending = false
    private localCacheLoadingPromise!: Promise<any>

    public header: Header = new Header() // 通用字段
    public hostname!: string // 请求主机

    public throttleLog!: (log: LogParams) => void

    /**
     * 管理配置参数
     * @param host carp or clog
     * @param env  ws, biz or com（ws和biz都会走biz域名）
     * @param category  tutor or conan
     * @param project custom project name
     */
    constructor(
        private host: CLOGHOST,
        private env: CLOGENV,
        private category: CLOGCATEGORY,
        private site: string,
        private project: string
    ) {
        if (IN_JEST_TEST) {
            return
        }
        this.hostname = getHost(host, env, site)
        this.throttleLog = this.throttle()

        const cached = LS.get(this.localKey)
        if (cached) {
            // clog 不存 local storage 给本地字体上传腾空间
            LS.remove(this.localKey)
            this.localCache = cached
            this.localCacheLoadingPromise = Promise.resolve()
        } else {
            this.localCacheLoadingPromise = Promise.resolve()
        }

        // sync host
        setTimeout(() => {
            syncHost(this.host, this.env, this.updateUrl, this.site)
        }, 5000)
    }

    // localStorage缓存key
    get localKey() {
        return `tutor_clog_${this.env}_${this.host}_${this.site}_cache`
    }

    // 请求路径
    get apiPath() {
        return getApiPath(this.host)
    }

    // 上传日志url
    get uploadUrl() {
        return `${this.hostname}${this.apiPath.UPLOAD}?${this.search}`
    }

    // host更新url
    get updateUrl() {
        return `${this.hostname}${this.apiPath.UPDATE}?${this.search}`
    }

    get headerValues() {
        const busiHeader = this.header.get() as HeaderValue
        const headerValues = transObjectToKeyValue(busiHeader)
        // stringify
        headerValues.forEach((item) => {
            if (typeof item.value === 'object') {
                item.value = JSON.stringify(item.value)
            } else {
                item.value = `${item.value}`
            }
        })
        return headerValues as IKeyValue[]
    }

    get search() {
        return `category=${this.category}&project=${encodeURIComponent(this.project)}`
    }

    public async ensureLocalCacheLoaded() {
        await this.localCacheLoadingPromise.finally(() => Promise.resolve())
    }

    /**
     * 立即发送日志数据
     * @param params 日志格式
     */
    public async sendLog(params: LogParams) {
        if (!navigator.onLine) {
            return
        }

        await this.ensureLocalCacheLoaded()

        const logData = {
            header: {
                keyValues: this.headerValues,
            },
            entries: formatEntries(params, this.category),
        }
        const request = sendRequest(this.uploadUrl, logData)
        await request
            .then(() => {
                // 触发再次上传，失败不重试，防止雪崩
                this.setLocalTimer()
            })
            .catch(async (e) => {
                // 失败处理
                console.error(e)

                increaseEntryCount(logData)

                // FIXME: 如果发送失败，暂时丢弃，等重新设计内存占用更低、性能更好的方案后再恢复失败重试
                // 缓存logData，防止header丢失； 导致发送时无法合并日志
                // this.localCache = this.localCache.concat(logData)
                // // 缓存长度限制在1000条内, 不能无限增长
                // if (this.localCache.length > Config.MaxLocalLog) {
                //     console.error(
                //         `LocalCacheLog limits ${Config.MaxLocalLog}, discard:`,
                //         this.localCache.slice(0, -Config.MaxLocalLog)
                //     )
                //
                //     this.localCache = this.localCache.slice(-Config.MaxLocalLog)
                // }
                // await this.localLogStore.set(this.localKey, this.localCache)
            })
    }

    /**
     * 清缓存日志定时器
     * @param time timeout时间
     */
    private setLocalTimer(time?: number) {
        if (this.localTimer) {
            return
        }
        this.localTimer = window.setTimeout(() => {
            this.localTimer = 0
            this.sendLocalCache()
        }, time || 2000) // 2s
    }

    /**
     * 发送缓存日志
     */
    public async sendLocalCache() {
        // 正在发送
        if (this.localSending) {
            return
        }
        await this.ensureLocalCacheLoaded()
        this.devLog('[sendLocalCache]: localCache size=', this.localCache.length)
        // 检查是否有cache数据
        if (this.localCache.length > 0) {
            const logData = this.localCache[0] // 取1条数据

            this.localSending = true

            const request = sendRequest(this.uploadUrl, logData as IPostData)

            request
                .then(async () => {
                    // 移除上传过的数据
                    this.localCache = this.localCache.slice(1)

                    this.localSending = false
                    if (this.localCache.length > 0) {
                        this.setLocalTimer(100) // 100ms 发送一条
                    }
                })
                .catch(async () => {
                    // 更新上传次数统计
                    increaseEntryCount(logData)

                    // 失败了就不重试了，等待下一次成功后再重试
                })
        }
    }

    /**
     * 生成节流功能的方法
     * @param timeout 超时时间
     * @param maxNum 最大日志数
     */
    private throttle(timeout: number = Config.ThrottleMs, maxNum: number = Config.MaxSendLog) {
        let _throttleTimer: any = null
        let _throttleCache = new Array<LogParam>()
        return (logs: LogParams) => {
            _throttleCache = _throttleCache.concat(logs)

            if (_throttleTimer) {
                // 达到最大数量，发送一次
                if (_throttleCache.length > maxNum) {
                    this.devLog(`1s内日志数达到${maxNum}触发请求`, _throttleCache.length)

                    const maxLogs = _throttleCache.slice(0, maxNum)
                    this.sendLog(maxLogs)
                    _throttleCache = _throttleCache.slice(maxNum)
                }
                return
            }

            _throttleTimer = setTimeout(() => {
                _throttleTimer = null
                this.sendLog(_throttleCache)
                _throttleCache = []
            }, timeout)
        }
    }

    /**
     * 生成追加traceId功能的方法
     * @param traceId 跟踪标识
     */
    public trace(traceId?: string) {
        const _traceId = traceId ? traceId : generateTraceId()
        this.devLog('_traceId', _traceId)

        return (logs: LogParams) => {
            let params = new Array<LogParam>()
            params = params.concat(logs)

            params.forEach((param) => {
                if (Array.isArray(param.values)) {
                    param.values.push({
                        key: '_traceId_',
                        value: `${_traceId}`,
                    })
                } else {
                    param.values._traceId_ = `${_traceId}`
                }
            })

            this.throttleLog(params)
        }
    }

    /**
     * 测试日志，方便验证
     * @param args
     */
    public devLog(...args: any[]) {
        debugLog(new Date().toISOString().slice(11, 23), '[clog]', ...args)
    }
}
