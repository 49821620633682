import { ToggleRightClickMenuCommand, Wukong } from '@wukong/bridge-proto'
import constate from 'constate'
import { useCallback, useState } from 'react'
import { WKMenuConfig } from '../../../../../../../ui-lib/src'
import { useCommand } from '../../../../../main/app-context'
import { useViewState } from '../../../../../view-state-bridge'
import { frogExposeToolsMenusAndActionsMenuDisplay } from '../../../../utils/tools-actions-frog'
import { MenuRightClickV2 } from '../menu-v2/menu-right-click-v2'
import {
    useAIComponentPublishPanelRightClickMenuConfig,
    useDesignModePagePanelRightClickMenuConfig,
    useDesignModeRightClickCtrlOrCmdConfig,
    useDesignPanelRightClickMenuConfig,
    useDevModeLayerPanelRightClickMenuConfig,
    useDevModePagePanelRightClickMenuConfig,
    useDevModeRightClickCtrlOrCmdConfig,
    useDevModeRightClickMenuConfig,
    useRightClickBubbbleConfig,
    useRightClickMenuConfig,
    useRightCLickMotiffScopeMenuConfig,
} from './right-click-menu-config'
import classes from './right-click-menu.module.less'

import RightClickSource = Wukong.DocumentProto.RightClickSource
import { featureSwitchManager } from '../../../../../kernel/switch/core'

export interface PopupOpenStateParam {
    position: { top: number; left: number }
    menuConfig?: WKMenuConfig
    source: RightClickSource
    mouseEvent?: { ctrlKey: boolean; metaKey: boolean; button: number }
    dataTestId?: string
}

export type PopupOpenState =
    | {
          opened: true
      }
    | { opened: false }

function useRightClickState() {
    const command = useCommand()
    const [popUpTestId, setPopUpTestId] = useState<string | undefined>(undefined)

    const onOpen = useCallback(
        (state: PopupOpenStateParam) => {
            command.DEPRECATED_invokeBridge(
                ToggleRightClickMenuCommand,
                Wukong.DocumentProto.RightClickMenuState.create({
                    source: state.source,
                    opened: true,
                    position: Wukong.DocumentProto.Vector.create({ x: state.position.left, y: state.position.top }),
                })
            )
            setPopUpTestId(state.dataTestId)
        },
        [command]
    )

    const onClose = useCallback(() => {
        {
            command.DEPRECATED_invokeBridge(
                ToggleRightClickMenuCommand,
                Wukong.DocumentProto.RightClickMenuState.create({
                    source: RightClickSource.RIGHT_CLICK_SOURCE_NONE,
                    opened: false,
                })
            )
            setPopUpTestId(undefined)
        }
    }, [command])
    return {
        onOpen,
        onClose,
        popUpTestId,
    }
}

export const [RightClickContextProvider, useRightClickOpen, useRightClickClose, usePopUpTestId] = constate(
    useRightClickState,
    (ctx) => ctx.onOpen,
    (ctx) => ctx.onClose,
    (ctx) => ctx.popUpTestId
)

export function RightClickMenu() {
    const rightClickMenuState = useViewState('rightClickMenuState')
    const docReadonly = useViewState('docReadonly')
    const isHistoryMode = useViewState('historyMode')
    const state = useViewState('topArea')
    const isEditVector = state?.isEditVector
    const editorType = useViewState('editorType')
    const isMotiffScopeEnabled = featureSwitchManager.isEnabled('motiff-debugger')
    if (!rightClickMenuState?.opened) {
        return null
    }

    switch (rightClickMenuState.source) {
        case RightClickSource.RIGHT_CLICK_SOURCE_CANVAS_WITH_CTR_OR_CMD:
            return <RightClickSourceCanvasWithCtrOrCmd />
        case RightClickSource.RIGHT_CLICK_SOURCE_CANVAS: {
            frogExposeToolsMenusAndActionsMenuDisplay({
                doc_mode: {
                    is_readonly: !!docReadonly,
                    is_dev_mode: editorType === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV,
                    is_history_version: !!isHistoryMode,
                    is_vector: !!isEditVector,
                },
                displayed_menu: '上下文菜单（画布）',
            })
            return <RightClickSourceCanvas />
        }
        case RightClickSource.RIGHT_CLICK_SOURCE_DESIGN_MODE_LAYER_PANEL: {
            frogExposeToolsMenusAndActionsMenuDisplay({
                doc_mode: {
                    is_readonly: !!docReadonly,
                    is_dev_mode: editorType === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV,
                    is_history_version: !!isHistoryMode,
                    is_vector: !!isEditVector,
                },
                displayed_menu: '上下文菜单（图层列表）',
            })
            return <RightClickSourceDesignModeLayerPanel />
        }
        case RightClickSource.RIGHT_CLICK_SOURCE_MOTIFF_SCOPE:
            return isMotiffScopeEnabled ? <RightClickSourceMotiffScope /> : null
        case RightClickSource.RIGHT_CLICK_SOURCE_DESIGN_PANEL:
            return <RightClickSourceDesignPanel />
        case RightClickSource.RIGHT_CLICK_SOURCE_BUBBLE:
            return <RightClickSourceBubble />
        case RightClickSource.RIGHT_CLICK_SOURCE_DESIGN_MODE_PAGE_PANEL: {
            frogExposeToolsMenusAndActionsMenuDisplay({
                doc_mode: {
                    is_readonly: !!docReadonly,
                    is_dev_mode: editorType === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV,
                    is_history_version: !!isHistoryMode,
                    is_vector: !!isEditVector,
                },
                displayed_menu: '上下文菜单（页面列表）',
            })
            return <RightClickSourceDesignModePagePanel />
        }
        case RightClickSource.RIGHT_CLICK_SOURCE_DEV_MODE_PAGE_PANEL: {
            frogExposeToolsMenusAndActionsMenuDisplay({
                doc_mode: {
                    is_readonly: !!docReadonly,
                    is_dev_mode: editorType === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV,
                    is_history_version: !!isHistoryMode,
                    is_vector: !!isEditVector,
                },
                displayed_menu: '上下文菜单（页面列表）',
            })
            return <RightClickSourceDevModePagePanel />
        }
        case RightClickSource.RIGHT_CLICK_SOURCE_DEV_MODE_LAYER_PANEL: {
            frogExposeToolsMenusAndActionsMenuDisplay({
                doc_mode: {
                    is_readonly: !!docReadonly,
                    is_dev_mode: editorType === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV,
                    is_history_version: !!isHistoryMode,
                    is_vector: !!isEditVector,
                },
                displayed_menu: '上下文菜单（图层列表）',
            })
            return <RightClickSourceDevModeLayerPanel />
        }
        case RightClickSource.RIGHT_CLICK_SOURCE_A_I_COMPONENT_PUBLISH_PANEL:
            return <RightClickSourceAIComponentPublishPanel />
        case RightClickSource.RIGHT_CLICK_SOURCE_NONE:
            return null
        case RightClickSource.RIGHT_CLICK_SOURCE_TOP_BAR:
            return null
    }
}

function RightClickSourceCommon({ config }: { config: WKMenuConfig }) {
    const rightClickMenuState = useViewState('rightClickMenuState')
    const onClose = useRightClickClose()
    const popUpTestId = usePopUpTestId()

    return (
        <MenuRightClickV2
            position={{ left: rightClickMenuState?.position.x!, top: rightClickMenuState?.position.y! }}
            onClose={onClose}
            config={config}
            forwardIconClassName={classes.svgFillColor}
            dataTestIds={{ captureKeyboard: popUpTestId }}
        />
    )
}

function RightClickSourceCanvasWithCtrOrCmd() {
    const isDevMode = useViewState('editorType') === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV
    const designModeCtrlOrCmdConfig = useDesignModeRightClickCtrlOrCmdConfig()
    const devModeCtrlOrCmdConfig = useDevModeRightClickCtrlOrCmdConfig()

    const ctrlOrCmdConfig = isDevMode ? devModeCtrlOrCmdConfig : designModeCtrlOrCmdConfig
    const config = ctrlOrCmdConfig.length ? ctrlOrCmdConfig : null
    if (!config) {
        return null
    }

    return <RightClickSourceCommon config={config} />
}

function RightClickSourceCanvas() {
    const isDevMode = useViewState('editorType') === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV
    const selectionConfig = useRightClickMenuConfig()
    const devModeSelectionConfig = useDevModeRightClickMenuConfig()
    const config = isDevMode ? devModeSelectionConfig : selectionConfig

    return <RightClickSourceCommon config={config} />
}
function RightClickSourceMotiffScope() {
    const config = useRightCLickMotiffScopeMenuConfig()
    return <RightClickSourceCommon config={config} />
}

function RightClickSourceDesignModeLayerPanel() {
    const config = useRightClickMenuConfig()
    return <RightClickSourceCommon config={config} />
}

function RightClickSourceDesignPanel() {
    const config = useDesignPanelRightClickMenuConfig()
    return <RightClickSourceCommon config={config} />
}

function RightClickSourceBubble() {
    const config = useRightClickBubbbleConfig()
    return <RightClickSourceCommon config={config} />
}

function RightClickSourceDesignModePagePanel() {
    const config = useDesignModePagePanelRightClickMenuConfig()
    return <RightClickSourceCommon config={config} />
}

function RightClickSourceDevModePagePanel() {
    const config = useDevModePagePanelRightClickMenuConfig()
    return <RightClickSourceCommon config={config} />
}

function RightClickSourceDevModeLayerPanel() {
    const config = useDevModeLayerPanelRightClickMenuConfig()
    return <RightClickSourceCommon config={config} />
}

function RightClickSourceAIComponentPublishPanel() {
    const config = useAIComponentPublishPanelRightClickMenuConfig()
    return <RightClickSourceCommon config={config} />
}
