import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    PublishSuccess: '插件发布成功',
    AllMembersCanUse: '现在，企业「{{orgName}}」下的所有成员均可使用「{{pluginName}}」。你还可以在',
    ManagePermission: '「管理权限」',
    InviteMembers: '中，邀请企业内的其他成员一起来更新该插件。',
    Done: '完成',
} as const

export const enTranslation = {
    PublishSuccess: 'Plugin published successfully',
    AllMembersCanUse: `"{{pluginName}}" is now available to members of "{{orgName}}". You can also `,
    ManagePermission: 'manage permissions',
    InviteMembers: ' to invite other members in the organization to update this resouce.',
    Done: 'Done',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
