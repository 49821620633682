import { PluginVO } from '../../../../../kernel/interface/plugin'

export interface TemplatePluginFile {
    fileName: string
    fileContent: string | Record<string, any>
}

export interface PluginManifest {
    name: string
    id: string
    main: string
    ui: string
}

export type PluginId = string

export enum PluginLocalManifestFileError {
    NotFoundError = 1,
    ReadFileError,
    NoNameError,
    NoIdError,
    NoMainError,
    NoUiError,
    PermissionDeniedError,
}

export interface LocalPlugin {
    id: PluginId
    name: string
    lastEditTime: number
    manifest?: PluginManifest
    path?: string // 插件目录路径
    publishInfo?: PluginVO
    localManifestFileError?: PluginLocalManifestFileError
}

export interface PluginPublishDraft {
    iconInfo?: PluginIconInfo
    name: string
    id: PluginId
    path: string
}

export interface PluginIconInfo {
    imageData: string
    format: string
}
