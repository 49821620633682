import { PublishSelectedNodesCommand } from '@wukong/bridge-proto'
import { useMemo, useRef } from 'react'
import {
    InputV2,
    MonoIconLayerComponent16,
    MonoIconPanelAdjust16,
    Tooltip,
    WKButton,
    WKIconButton,
} from '../../../../../../../../ui-lib/src'
import { CommitType } from '../../../../../../document/command/commit-type'
import { ComponentPropTestId, ComponentTestIds } from '../../../../../../window'
import { useCommand } from '../../../../../context/document-context'
import { useDocInfoContext } from '../../../../../context/top-area-context'
import { SingleGrid } from '../../../../atom/grid/single-grid'
import { OpenLibraryPublishModalSource } from '../../../../component-style-library-v2/library-service/library-modal-router-service'
import { useLibraryModalRouter } from '../../../../component-style-library-v2/library-service/use-library-modal-router'
import { ComponentState } from '../../types'
import { ComponentConfigPopup } from '../component-config-popup'
import { useComponentTitlePanel } from './hook'
import styles from './index.module.less'
import { translation } from './index.translation'

export function ComponentTitlePanel(props: { state: ComponentState }) {
    const { name, shouldDisplay, descUnit, simplifyInstancePanels, showPublishButton } = props.state
    const {
        popupVisible,
        updateComponentName,
        openComponentConfigPopup,
        closeComponentConfigPopup,
        updateComponentDesc,
        updateSimplifyInstancePanels,
    } = useComponentTitlePanel()
    const containerRef = useRef<HTMLDivElement>(null)

    const position = useMemo(() => {
        if (popupVisible && containerRef.current) {
            const { left, top } = containerRef.current.getBoundingClientRect()
            return { left, top }
        }
    }, [popupVisible])

    const { openLibraryPublishModalIfPaid } = useLibraryModalRouter()
    const { docData } = useDocInfoContext()
    const command = useCommand()

    // 组件属性开关开启&&展示组件面板，则渲染
    if (!shouldDisplay) {
        return null
    }

    const handleInputBlur = (e: any) => {
        if (e.target.value !== name) {
            updateComponentName(e.target.value)
        }
    }

    const handleIconButtonClick = () => {
        popupVisible ? closeComponentConfigPopup() : openComponentConfigPopup()
    }

    const publishSelectedComponents = () => {
        command.invokeBridge(CommitType.Noop, PublishSelectedNodesCommand)
        openLibraryPublishModalIfPaid(OpenLibraryPublishModalSource.SelectionPublishButton, !!docData?.draft)
    }

    return (
        <div
            data-testid={ComponentPropTestId.ComponentTitlePanel.panel}
            ref={containerRef}
            className={styles.componentTitlePanel}
            onKeyDown={(e: any) => {
                e.stopPropagation()
            }}
        >
            <SingleGrid>
                <SingleGrid.Item start={5} span={46}>
                    <InputV2
                        dataTestIds={{ input: ComponentPropTestId.ComponentTitlePanel.nameInput }}
                        size="small"
                        width={184}
                        forwardIcon={<MonoIconLayerComponent16 />}
                        value={name}
                        onBlur={handleInputBlur}
                        ellipsis
                    />
                </SingleGrid.Item>
                <SingleGrid.Item start={51} span={12} horizontalCenter>
                    <Tooltip title={translation('ComponentConfig')}>
                        <WKIconButton
                            onMouseDown={(e) => e.stopPropagation()}
                            icon={<MonoIconPanelAdjust16 />}
                            data-testid={ComponentPropTestId.ComponentTitlePanel.adjustConfigBtn}
                            type={popupVisible ? 'deepBlue' : 'secondary'}
                            onClick={handleIconButtonClick}
                        />
                    </Tooltip>
                </SingleGrid.Item>
            </SingleGrid>
            {!!descUnit.desc.length && (
                <SingleGrid>
                    <SingleGrid.Item start={5} span={54}>
                        <div
                            data-testid={ComponentPropTestId.ComponentTitlePanel.descPreview}
                            className={styles.componentDescPreview}
                        >
                            {descUnit.desc}
                        </div>
                    </SingleGrid.Item>
                </SingleGrid>
            )}
            {showPublishButton && (
                <SingleGrid className={styles.publishComponentBtn}>
                    <SingleGrid.Item start={5} span={54}>
                        <WKButton
                            type="secondary"
                            style={{ width: '100%' }}
                            onClick={publishSelectedComponents}
                            data-testid={ComponentTestIds.publishBtn}
                        >
                            {translation('PublishSelectedComponents')}
                        </WKButton>
                    </SingleGrid.Item>
                </SingleGrid>
            )}
            {popupVisible && (
                <ComponentConfigPopup
                    type="common"
                    commonDescUnit={descUnit}
                    position={position}
                    onClose={closeComponentConfigPopup}
                    onDescTextAreaChange={updateComponentDesc}
                    updateSimplifyInstancePanels={updateSimplifyInstancePanels}
                    simplifyInstancePanels={simplifyInstancePanels}
                />
            )}
        </div>
    )
}
