import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    des: '设计席位',
    dev: '研发席位',
    free: '免费',
    sa: '查看全部功能',
} as const

export const enTranslation = {
    des: 'Designer',
    dev: 'Developer',
    free: 'Free',
    sa: 'See all features',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
