import { UserID } from '../../../../kernel/interface/type'
import { LocalStorageKey } from '../../../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../../../web-storage/local-storage/storage'

export const userCloseReminderInGracePeriod = (userId: UserID, resourceId: string) => {
    enhancedLocalStorage.setSerializedItem(
        {
            key: LocalStorageKey.CloseDevModeRemainder,
            suffix: `_${userId}_IN_${resourceId!}`,
        },
        true
    )
}
export const userHasCloseReminderInGracePeriod = (userId: UserID, resourceId: string) => {
    return !!enhancedLocalStorage.getSerializedItem({
        key: LocalStorageKey.CloseDevModeRemainder,
        suffix: `_${userId}_IN_${resourceId!}`,
    })
}

/**
 * boldText : 加粗, normalText: 不加粗
 */
export function formattedText(boldText: string, normalText: string) {
    return (
        <>
            <span style={{ fontWeight: `var(--wk-font-weight-semibold)` }}>{boldText}</span>
            <span>{normalText}</span>
        </>
    )
}
