/* eslint-disable no-restricted-imports */
import { ChangeTextSearchCommand, ExtendSimplifyInstanceRightPanel, Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import React, { useCallback, useRef, useState } from 'react'
import { useUnmount } from 'react-use'
import { Scrollbar, ScrollbarRef, WKButton } from '../../../../../ui-lib/src'
import { documentLoaded$ } from '../../../external-store/atoms/editor-context'
import { appStore$ } from '../../../external-store/store'
import { useCommand } from '../../../main/app-context'
import { useViewState } from '../../../view-state-bridge'
import { AutoLayout } from '../design-panel-v2/attributes/components/auto-layout'
import { Mask } from '../design-panel-v2/attributes/components/mask'
import { ComponentInstancePanel } from '../design-panel-v2/component-instance-panel'
import { LayoutGrid } from '../design-panel-v2/layout-grid'
import { LocalVariable } from '../variable/local-variable'
import { Align } from './align/align'
import { Attribute } from './attribute/attribute'
import { Vector } from './attribute/vector'
import { Background } from './background'
import { BelongingComponentPanel } from './belonging-component'
import { BlendMode } from './blend-mode/blend-mode'
import { Constraints } from './constraints/constraints'
import { ScrollContainerProvider } from './design-scroll-container-context'
import { translation } from './design-translation'
import style from './design.module.less'
import { Effects } from './effects/effects'
import { Export } from './export/export'
import { Fills } from './fills/fills'
import { FrameTemplate } from './frame-template/frame-template'
import { Scale } from './scale/scale'
import { SelectColor } from './select-color/select-color'
import { StrokeV2 } from './strokes-v2/index'
import { StylesWrap } from './style/style-wrap'
import { Text } from './text/text'

export interface DesignProps {
    className?: string
    isSignlePanel?: boolean
}

export function CollapsePanel(designPanelItemShow: Wukong.DocumentProto.IVDesignPanelItemShow) {
    const command = useCommand()

    if (designPanelItemShow.showMoreProperties) {
        return (
            <div className={classnames(style.collapseOption)}>
                <WKButton
                    type="secondary"
                    style={{ width: '100%' }}
                    onClick={() => {
                        command.DEPRECATED_invokeBridge(ExtendSimplifyInstanceRightPanel)
                    }}
                    data-testid={'extend-simplify-instance-right-panel-btn'}
                >
                    {translation('ShowMoreProperities')}
                </WKButton>
            </div>
        )
    }

    return ExtensionPanel(designPanelItemShow)
}

export function ExtensionPanel(designPanelItemShow: Wukong.DocumentProto.IVDesignPanelItemShow) {
    const {
        autoLayout,
        constraints,
        gridLayout,
        mask,
        blendMode,
        selectColor,
        stroke,
        fills,
        exportPanel,
        effects,
        text,
        background,
        stylesWrap,
        frameTemplate,
        exportPagePanel,
        localVariables,
    } = designPanelItemShow

    return (
        <>
            {autoLayout && <AutoLayout />}
            {constraints && <Constraints />}
            {gridLayout && <LayoutGrid />}
            {mask && <Mask />}
            {blendMode && <BlendMode />}
            {text && <Text />}
            {fills && <Fills />}
            {stroke && <StrokeV2 />}
            {selectColor && <SelectColor />}
            {effects && <Effects />}
            {exportPanel ? <Export /> : <></>}
            {background && <Background className={classnames(style.background)} />}
            {localVariables && <LocalVariable />}
            {stylesWrap && <StylesWrap />}
            {exportPagePanel ? <Export /> : <></>}
            {frameTemplate && <FrameTemplate />}
        </>
    )
}

export function Design(props: DesignProps) {
    const { className, isSignlePanel = false } = props
    const designPanelItemShow = useViewState('designPanelItemShow')!
    const [isScroll, setIsScroll] = useState<boolean>(false)
    const scrollbarRef = useRef<ScrollbarRef>(null)
    const command = useCommand()

    const { scale, align, vector, attributes, component, belongingComponentOrSet } = designPanelItemShow

    const onScroll = useCallback((e: React.UIEvent) => {
        setIsScroll(e.currentTarget.scrollTop !== 0)
    }, [])

    useUnmount(() => {
        if (appStore$.get(documentLoaded$)) {
            command.DEPRECATED_invokeBridge(ChangeTextSearchCommand, { value: '', id: '' })
        }
    })

    return (
        <>
            {isScroll ? <div className={style.splitLine}></div> : null}
            <Scrollbar onScroll={onScroll} ref={scrollbarRef}>
                <div
                    className={classnames(style.container, isSignlePanel ? style.firstChildBorderTop0 : '', [
                        className,
                    ])}
                    data-testid={'inspect-design'}
                >
                    <ScrollContainerProvider
                        getScrollContainer={() => scrollbarRef.current?.getContainerElement() ?? null}
                    >
                        {scale && <Scale />}
                        {align && <Align isSinglePanel={isSignlePanel} />}
                        {vector && <Vector />}
                        {attributes && <Attribute />}
                        {belongingComponentOrSet && <BelongingComponentPanel />}
                        {component && <ComponentInstancePanel />}
                        <CollapsePanel {...designPanelItemShow} />
                    </ScrollContainerProvider>
                </div>
                <div className={style.safeArea}></div>
            </Scrollbar>
        </>
    )
}
