import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import {
    DropImageToCenterCommand,
    FillNodesWithImagesCommand,
    GetSelectionNodeIdsCommandForWasm,
    Wukong,
} from '@wukong/bridge-proto'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { InputV2, Scrollbar, SearchInputRef, showImageResizeTip, WKButton, WKToast } from '../../../../../../ui-lib/src'
import { useCommand } from '../../../../main/app-context'

import { Manifest, PluginExported, PluginModalProps } from '../type'

import styles from './index.module.less'
import { topics } from './util/config'
import topicIcons from './util/topic-icons'
import { Language, Photo, SearchResultManager, unsplashService } from './util/unsplash-api'

import { cmdCreateImages } from '../../../../document/command/image-command'
import { Sentry } from '../../../../kernel/sentry'
import { ReactComponent as UnsplashIcon } from '../../../assets/plugins/unsplash.svg'
import { ReactComponent as ExclamationMark } from '../../../assets/plugins/unsplash/exclamation-mark.svg'
import { useImageDownloadContext } from '../../../context/document-context'
import { getLdap, logs } from '../log'
import { ImageWithBlurhash } from './blurhash'
import { downloadImages } from './util/download-image'

const Prefix = (content: any) => `unsplash-${content}`
export const UnsplashTestIds = {
    BottomLabel: Prefix('bottom-label'),
    TopicNature: Prefix('topic-nature'),
    SearchResult: Prefix('search-result'),
    DeleteX: Prefix('delete-x'),
}

export const manifest: Manifest = {
    key: Wukong.DocumentProto.PluginType.PLUGIN_TYPE_UNSPLASH,
    name: translation('Unsplash'),
    width: 400,
    height: 621,
    icon: <UnsplashIcon />,
}

const resolveUnsplashError = (error: any) => {
    if (error instanceof Error) {
        const parsedError = JSON.parse(error.message)

        // 来自 unsplash 的未知错误 (非 Motiff 服务端错误), 上报 sentry
        // Motiff服务端自身错误已记录于日志中, 无需上报
        if ('businessStatus' in parsedError) {
            if (parsedError.businessStatus === 91004) {
                WKToast.show(translation('ReachedUsageFrequencyLimit'))
            }
        } else if ('errors' in parsedError) {
            // known unsplash error cases
            // case 1: {"errors":["No photos found."]}
            if (parsedError.errors.length === 1 && parsedError.errors[0] === 'No photos found.') {
                console.error(parsedError.errors[0])
            } else {
                // report unknown unsplash error
                console.error('unknown unsplash error: ', parsedError)
                Sentry.captureException(parsedError)
            }
        }
    }
}

let searchResultManager: SearchResultManager

let allowInsertion = true
function insertImagesToCanvas(
    command: any,
    rawInfos: { imageInfo: Wukong.DocumentProto.ImageInfo; hasCompressed: boolean }[]
) {
    let hasCompressed = false

    const imageInfos = rawInfos.map((value) => {
        if (value.hasCompressed) {
            hasCompressed = true
        }
        return value.imageInfo
    })

    if (allowInsertion) {
        const selectionNodeIds = command.DEPRECATED_invokeBridge(GetSelectionNodeIdsCommandForWasm).value ?? []

        if (selectionNodeIds.length > 0) {
            // 存在选区则填充选区
            command.DEPRECATED_invokeBridge(
                FillNodesWithImagesCommand,
                Wukong.DocumentProto.FillNodesWithImagesParam.create({
                    nodeIds: selectionNodeIds,
                    imageInfos: imageInfos,
                })
            )
        } else {
            // 无选区则直接插入图片
            command.DEPRECATED_invokeBridge(
                DropImageToCenterCommand,
                Wukong.DocumentProto.DropImageToCenterParam.create({
                    imageInfos,
                })
            )
        }

        if (hasCompressed) {
            showImageResizeTip()
        }
        motiff.commitUndo()
    }
}

function QueryEditingAndSearch({
    search,
    switchToTopicSelection,
}: {
    search: (query: string) => void
    switchToTopicSelection: () => void
}) {
    const searchInputRef = useRef<SearchInputRef>(null)
    const [inputEmpty, setInputEmpty] = useState(true)

    return (
        <div className={styles.searchEditingArea}>
            <InputV2.Search
                ref={searchInputRef}
                autoFocus
                style={{ flex: 'auto' }}
                placeholder={translation('SearchUnsplash')}
                onSearch={(v) => setInputEmpty(!v)}
                onClickClearBtn={switchToTopicSelection}
                onKeyDown={(e) => {
                    if (e.key == 'Enter') {
                        search((e.target as HTMLInputElement).value)
                    }
                }}
            />

            <WKButton
                disabled={inputEmpty}
                onClick={() => {
                    search(searchInputRef.current?.getInputElement().value ?? '')
                }}
                type={'primary'}
            >
                {translation('Search')}
            </WKButton>
        </div>
    )
}

function SingleSearchResult({ photo, handle, onLoad }: { photo: Photo; handle: any; onLoad?: () => void }) {
    const [progress, setProgress] = useState(1)
    const [insideOverlay, setInsideOverlay] = useState(false)

    return (
        <div
            onMouseEnter={() => {
                setInsideOverlay(true)
            }}
            onMouseLeave={() => {
                setInsideOverlay(false)
            }}
        >
            <ImageWithBlurhash
                src={photo.urls.small}
                hash={photo.blur_hash as string}
                width={172}
                height={photo.height * (172 / photo.width)}
                onLoadCallback={onLoad}
            />

            <div
                className={styles.imageOverlayOuter}
                data-testid={UnsplashTestIds.SearchResult + '-' + photo.id}
                style={{ pointerEvents: progress != 1 ? 'none' : 'auto' }}
                onClick={() => {
                    // trace
                    {
                        logs.Plugins.unsplashInsertPicture({ ldap: getLdap() })
                    }

                    handle(setProgress)
                }}
            >
                {progress != 1 && <div className={styles.imageOverlayPersistent}></div>}
                {progress == 1 && <div className={styles.imageOverlay}></div>}

                <a
                    target="_blank"
                    rel="noreferrer"
                    className={styles.user}
                    style={{ visibility: insideOverlay || progress != 1 ? 'visible' : 'hidden' }}
                    href={photo.user.links.html}
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                >
                    {photo.user.name}
                </a>

                <div
                    className={styles.circleOuter}
                    style={{
                        visibility: progress != 1 ? 'visible' : 'hidden',
                    }}
                >
                    <RotatingCircle colour="var(--wk-gray-6)" />
                </div>
            </div>
        </div>
    )
}

function SearchResult({
    urlList,
    flip,
    getRandom,
    loadState,
}: {
    urlList: Photo[]
    flip: () => void
    getRandom: (count: number) => Promise<{ url: string; id: string; trackUrl: string }[]>
    loadState: { state: boolean; set: (state: boolean) => void }
}) {
    const command = useCommand()
    const imageDownloadContext = useImageDownloadContext()

    // infinite scroll
    const { ref, inView } = useInView({
        threshold: 0,
    })
    useEffect(() => {
        if (inView) {
            flip()
        }
    }, [flip, inView])

    const [progress, setProgress] = useState(1)

    return (
        <div
            data-testid={UnsplashTestIds.SearchResult}
            style={{ position: 'relative' }}
            className="flex flex-col gap-4"
        >
            <WKButton
                disabled={progress != 1 || urlList.length === 0}
                style={{ width: '100%', height: '34px' }}
                type="secondary"
                onClick={() => {
                    // trace
                    {
                        logs.Plugins.unsplashRandomInsert({ ldap: getLdap() })
                    }

                    const selectionIds = command.DEPRECATED_invokeBridge(GetSelectionNodeIdsCommandForWasm).value ?? []
                    setProgress(0)
                    getRandom(Math.max(1, selectionIds.length))
                        .then((infos) => {
                            if (infos.length > 0) {
                                const promiseImageBlobs = downloadImages(
                                    infos.map((item) => {
                                        return { downloadUrl: item.url, trackUrl: item.trackUrl }
                                    })
                                )

                                return Promise.all(promiseImageBlobs).then((blobs) => {
                                    const blobsInfos = blobs.map((blob: Blob, idx: number) => {
                                        return { blob: blob, id: infos[idx].id }
                                    })
                                    return Promise.all(
                                        command.invoke(cmdCreateImages, imageDownloadContext, blobsInfos)
                                    )
                                })
                            } else {
                                return Promise.resolve(null)
                            }
                        })
                        .then((rawInfos) => {
                            if (rawInfos) {
                                insertImagesToCanvas(command, rawInfos)
                            }
                        })
                        .finally(() => {
                            setProgress(1)
                        })
                }}
            >
                <div className="flex items-center justify-center">
                    {progress != 1 && <RotatingCircle colour="var(--wk-v2-label-color-gray-8)" />}
                    {translation('InsertRandom')}
                </div>
            </WKButton>
            {!loadState.state && (
                <div
                    style={{
                        position: 'absolute',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '300px',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <RotatingCircle colour="var(--wk-v2-label-color-gray-8)" />
                </div>
            )}

            {loadState.state && urlList.length === 0 && (
                <div className="flex justify-center items-center color-$wk-v2-label-color-gray-8 wk-text-12 wk-font-medium pt-[50px]">
                    {translation('NoResults')}
                </div>
            )}

            {urlList.length > 0 && (
                <div className={styles.grid}>
                    {urlList.map((photo, index) => {
                        const tenthFromLast = index === urlList.length - 10
                        const isFirst = index === 0
                        return (
                            <div
                                ref={tenthFromLast ? ref : null}
                                key={photo.id}
                                style={{
                                    width: '172px',
                                    position: 'relative',
                                    gridRowEnd: `span ${Math.ceil(photo.height / (photo.width / 172) + 8)}`,
                                }}
                            >
                                <SingleSearchResult
                                    photo={photo}
                                    handle={(setPercentage: (p: number) => void) => {
                                        setPercentage(0)
                                        const promiseImageBlobs = downloadImages([
                                            {
                                                downloadUrl: photo.urls.regular,
                                                trackUrl: photo.links.download_location,
                                            },
                                        ])
                                        Promise.all(promiseImageBlobs)
                                            .then((blobs) => {
                                                if (blobs.length > 0) {
                                                    const blobsInfos = blobs.map((blob: Blob) => {
                                                        return { blob: blob, id: photo.id }
                                                    })

                                                    return Promise.all(
                                                        command.invoke(
                                                            cmdCreateImages,
                                                            imageDownloadContext,
                                                            blobsInfos
                                                        )
                                                    )
                                                } else {
                                                    return Promise.resolve(null)
                                                }
                                            })
                                            .then((rawInfos) => {
                                                if (rawInfos) {
                                                    insertImagesToCanvas(command, rawInfos)
                                                }
                                            })
                                            .finally(() => {
                                                setPercentage(1)
                                            })
                                    }}
                                    onLoad={
                                        isFirst
                                            ? () => {
                                                  loadState.set(true)
                                              }
                                            : undefined
                                    }
                                />
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

function getRandomInt(min: number, max: number) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
}

function SingleTopic({
    topicInfo,
    insertRandom,
}: {
    topicInfo: string[]
    insertRandom: (topic: string, handler: (p: number) => void) => void
}) {
    const [progress, setProgress] = useState<number>(1)

    const getTopic = useCallback(() => {
        if (topicInfo[0] == translation('Random')) {
            return topics[getRandomInt(1, topics.length - 1)][1]
        } else {
            return topicInfo[1]
        }
    }, [topicInfo])

    const handleClick = useCallback(() => {
        insertRandom(getTopic(), setProgress)
    }, [insertRandom, getTopic])

    return (
        <div
            data-testid={topicInfo[0] == translation('Nature') ? UnsplashTestIds.TopicNature : undefined}
            className={styles.topicButton}
            style={{ pointerEvents: progress != 1 ? 'none' : 'auto' }}
            onClick={() => {
                // trace
                {
                    logs.Plugins.unsplashPresetTheme({ ldap: getLdap(), preset_button: topicInfo[1] })
                }

                handleClick()
            }}
        >
            <div style={{ height: '32px', width: '100%', display: 'flex', alignItems: 'center' }}>
                <img
                    src={topicIcons[topicInfo[1] as keyof typeof topicIcons]}
                    width={32}
                    height={32}
                    style={{ borderRadius: '3px' }}
                />
                <div className={styles.topicTitle}>{topicInfo[0]}</div>
            </div>

            <div className={styles.progressBarOverlay}>
                <div
                    className={styles.progressBar}
                    style={{
                        visibility: progress != 1 ? 'visible' : 'hidden',
                        width: `${Math.floor(progress * 100)}%`,
                    }}
                ></div>
                <div className={styles.percentage} style={{ visibility: progress != 1 ? 'visible' : 'hidden' }}>
                    {Math.floor(progress * 100)}%
                </div>
            </div>
        </div>
    )
}

function TopicSelectionArea({
    getRandom,
}: {
    getRandom: (query: string, count: number) => Promise<{ url: string; id: string; trackUrl: string }[]>
}) {
    const command = useCommand()
    const imageDownloadContext = useImageDownloadContext()

    const insertRandom = (query: string, setProgress: (progress: number) => void) => {
        setProgress(0)
        const selectionIds = command.DEPRECATED_invokeBridge(GetSelectionNodeIdsCommandForWasm).value ?? []
        getRandom(query, Math.max(1, selectionIds.length))
            .then(async (infos) => {
                if (infos.length > 0) {
                    const factor = Math.random()

                    const promiseImageBlobs = downloadImages(
                        infos.map((item) => {
                            return { downloadUrl: item.url, trackUrl: item.trackUrl }
                        }),
                        (p: number) => {
                            setProgress((0.9 + 0.1 * factor) * p)
                        }
                    )

                    return Promise.all(promiseImageBlobs).then((imageBlobs) => {
                        const blobsInfos = imageBlobs.map((blob: Blob, idx: number) => {
                            return { blob: blob, id: infos[idx].id }
                        })
                        return Promise.all(command.invoke(cmdCreateImages, imageDownloadContext, blobsInfos))
                    })
                } else {
                    return Promise.resolve(null)
                }
            })
            .then((rawInfos) => {
                if (rawInfos) {
                    insertImagesToCanvas(command, rawInfos)
                }
            })
            .finally(() => {
                setProgress(1)
            })
    }

    return (
        <div className="flex flex-col gap-4">
            <div className={styles.topicSelectionTitle}>{translation('InsertRandom')}</div>
            <div className={styles.grid}>
                {topics.map((pair, index) => {
                    return <SingleTopic key={index} topicInfo={pair} insertRandom={insertRandom} />
                })}
            </div>
        </div>
    )
}

const RotatingCircle = ({ colour }: { colour: string }) => {
    return (
        <svg
            style={{
                width: '24px',
                height: '24px',
                transform: `rotate(-90deg)`,
                animation: 'spin 1s linear infinite',
            }}
            viewBox="0 0 200 200"
        >
            <circle
                strokeLinecap="round"
                cx="100"
                cy="100"
                r="50"
                fill="none"
                strokeWidth="10"
                stroke={colour}
                strokeDasharray={`${0.75 * 314} ${(1 - 0.75) * 314}`}
            />
        </svg>
    )
}

const BottomLabel = ({ handleClick }: { handleClick: () => void }) => {
    return (
        <div
            data-testid={UnsplashTestIds.BottomLabel}
            className={styles.lowerHalf}
            onDoubleClick={() => {
                handleClick()
            }}
        >
            <ExclamationMark />
            &nbsp;&nbsp;{translation('SourcedFrom')}&nbsp;
            <a
                target="_blank"
                href={'https://unsplash.com/'}
                rel="noreferrer"
                onMouseDown={() => {
                    // trace
                    {
                        logs.Plugins.unsplashOfficialWebsiteLink({ ldap: getLdap() })
                    }
                }}
            >
                Unsplash
            </a>
            {translation('qwYbVv')}&nbsp;&nbsp;
        </div>
    )
}

export function Unsplash(_: PluginModalProps) {
    // 统计曝光次数
    useEffect(() => {
        // trace
        {
            logs.Plugins.unsplash({ ldap: getLdap() })
        }
    }, [])

    useEffect(() => {
        allowInsertion = true
        return () => {
            allowInsertion = false
        }
    }, [])

    const [imageUrlList, setImageUrlList] = useState<Photo[]>([])
    const [currentQuery, setCurrentQuery] = useState<string>('')
    const [loaded, setLoaded] = useState(true)

    const flip = () => {
        async function fetchData() {
            searchResultManager.flipToNext()

            searchResultManager
                .getItems()
                .then((results) => {
                    setImageUrlList(imageUrlList.concat(results))
                })
                .catch((error) => {
                    resolveUnsplashError(error)
                })
        }

        fetchData()
    }

    const search = (query: string) => {
        setImageUrlList([])
        setLoaded(false)
        setShouldShowTopicSelection(false)
        setCurrentQuery(query)

        async function fetchData() {
            searchResultManager = unsplashService.search(query, Language.ChineseSimplified, 30)

            try {
                const results = await searchResultManager.getItems()
                setImageUrlList(results)
            } catch (error) {
                resolveUnsplashError(error)
            } finally {
                setLoaded(true)
            }
        }

        fetchData()
    }

    // if any error occurs, return an empty array
    const getRandom = async (query: string, count = 1) => {
        // for error tolerance
        let photos: Photo[] = []

        try {
            photos = await unsplashService.getRandom(query, count)
        } catch (error) {
            resolveUnsplashError(error)
        }

        return photos.map((photo) => ({
            url: photo.urls.regular,
            id: photo.id,
            trackUrl: photo.links.download_location,
        }))
    }

    const getRandomWithCurrentQuery = useCallback(
        (count = 1) => {
            return getRandom(currentQuery, count)
        },
        [currentQuery]
    )

    const [shouldShowTopicSelection, setShouldShowTopicSelection] = useState(true)

    return (
        <div>
            <QueryEditingAndSearch
                search={(args) => {
                    // query 为空时，不进行搜索
                    if (args !== '') {
                        // trace
                        {
                            logs.Plugins.unsplashSearch({ ldap: getLdap(), query: args })
                        }

                        return search(args)
                    }
                }}
                switchToTopicSelection={() => {
                    setShouldShowTopicSelection(true)
                }}
            />
            <Scrollbar autoHeight autoHeightMin={510} autoHeightMax={510}>
                <div className={styles.upperHalf}>
                    {shouldShowTopicSelection ? (
                        <TopicSelectionArea getRandom={getRandom} />
                    ) : (
                        <SearchResult
                            urlList={imageUrlList}
                            flip={flip}
                            getRandom={getRandomWithCurrentQuery}
                            loadState={{ state: loaded, set: setLoaded }}
                        />
                    )}
                </div>
            </Scrollbar>

            <BottomLabel
                handleClick={() => {
                    setShouldShowTopicSelection(!shouldShowTopicSelection)
                }}
            />
        </div>
    )
}

export default {
    manifest,
    Component: Unsplash,
} as unknown as PluginExported
