/* eslint-disable no-restricted-imports */
import { useMemo } from 'react'
import { Effect } from '../../../../../document/node/node'
import { base64BlackWhite_240_120 } from '../../../../assets/common/common-base64'
import { useEffectStylePreviewThumbnail } from '../../../../utils/thumbnail-cache/effects-thumbnail'

export interface EffectStyleThumbnailProps {
    className?: string
    effects: readonly Effect[]
    width: number
    height: number
    multiple?: number
}
export function EffectStyleThumbnail(props: EffectStyleThumbnailProps): JSX.Element {
    const { effects, width, height, multiple } = props
    const effectUrl = useEffectStylePreviewThumbnail({
        width,
        height,
        multiple,
        effects,
    })
    const thumbnailSrc = useMemo(() => {
        if (effectUrl) {
            return effectUrl
        }
        return base64BlackWhite_240_120
    }, [effectUrl])

    return <img src={thumbnailSrc} className={props.className} />
}
