import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    'UhOh...We': '无法打开文件',
    GotIt: '我知道了',
    ImprovePerformanceBy: '检测到当前浏览器未使用硬件加速模式，可能会导致操作异常卡顿。请使用硬件加速模式以提升性能',
    LearnMore: '了解更多',
} as const

export const enTranslation = {
    'UhOh...We': 'Unable to open the file',
    GotIt: 'OK',
    ImprovePerformanceBy:
        'Your browser has not enabled hardware acceleration. Enable it to reduce latency and improve performance.',
    LearnMore: 'Learn more',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
