import { translation } from './reply-item.translation'
/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Tooltip, useEllipsisTooltip } from '../../../../../../ui-lib/src'
import { capitalizeFirstLetter, timeDesc2 } from '../../../../../../util/src'
import { CommentReply, CommentUser } from '../../../../kernel/interface/comment'
import { IconButton } from '../../atom/button/icon-button'
import { CommentAvatar, CommentAvatarProps } from '../comment-avatar/comment-avatar'
import { ShowMessage, ShowMessageProps } from '../show-message/show-message'
import { UsualEmoji } from './common'
import { ReplyEmoji } from './reply-emoji'
import { ReplyEmojiPick } from './reply-emoji-pick'
import classes from './reply-item.module.less'
import { ReplyMoreSettingV2 } from './reply-more-setting'

export interface ReplyItemProps extends Pick<ShowMessageProps, 'usersMap'>, Pick<CommentAvatarProps, 'pending'> {
    commentReply: CommentReply
    currentUserId: CommentUser['id']
    isComment?: boolean
    onDeleteReply?: (commentReply: CommentReply) => void
    onEditCommentAndReply?: (commentReply: CommentReply) => void
    onFastReply?: (commentReply: CommentReply) => void
    onClickEmoji?: (commentReply: CommentReply, emojiInfo: UsualEmoji) => void
    onPopupOpen?: () => void
    onPopupClose?: () => void
    dataTestId?: string
}
export function ReplyItem(props: ReplyItemProps) {
    const fastReplyRef = useRef<HTMLSpanElement>(null)
    const [hasPopupOpen, setHasPopupOpen] = useState<boolean>(false)
    const { inactivation, ellipsisRef, onmouseenter } = useEllipsisTooltip()
    const onPopupOpen = useCallback(() => {
        setHasPopupOpen(true)
        props.onPopupOpen?.()
    }, [props])

    const onPopupClose = useCallback(() => {
        setHasPopupOpen(false)
        props.onPopupClose?.()
    }, [props])

    const isCreateByCurrentUser = useMemo(() => {
        return props.currentUserId === props.commentReply.owner.id
    }, [props.commentReply.owner.id, props.currentUserId])

    const isEdited = useMemo(() => {
        const { updatedTime, createdTime, lastEditTime } = props.commentReply
        const isBeforeAddLastEditTime = !lastEditTime || lastEditTime === new Date('1970-07-01 00:00:00').getTime()
        if (isBeforeAddLastEditTime) {
            return updatedTime > createdTime
        }
        return lastEditTime > createdTime
    }, [props.commentReply])

    const onDeleteReply = useCallback(() => {
        props.onDeleteReply?.(props.commentReply)
    }, [props])

    const onEditCommentAndReply = useCallback(() => {
        props.onEditCommentAndReply?.(props.commentReply)
    }, [props])

    const onFastReply = useCallback(() => {
        props.onFastReply?.(props.commentReply)
    }, [props])

    const onClickEmoji = useCallback(
        (emojiInfo: UsualEmoji) => {
            props.onClickEmoji?.(props.commentReply, emojiInfo)
        },
        [props]
    )

    return (
        <div
            className={classnames(
                classes.replyItem,
                { [classes.hasPopupOpen]: hasPopupOpen },
                { [classes.pending]: props.pending }
            )}
            data-testid={props.dataTestId}
        >
            <div className={classes.replyInfo}>
                <div className={classes.replyInfoLeft}>
                    <CommentAvatar userInfo={props.commentReply.owner} pending={props.pending} />
                    <Tooltip title={props.commentReply.owner.nickname} inactivation={inactivation}>
                        <span className={classes.nameContainer} onMouseEnter={onmouseenter}>
                            <span className={classes.name} ref={ellipsisRef}>
                                {props.commentReply.owner.nickname}
                            </span>
                        </span>
                    </Tooltip>
                    <span className={classes.time}>
                        {capitalizeFirstLetter(timeDesc2(props.commentReply.createdTime))}
                    </span>
                    {isEdited ? <span className={classes.editState}>{translation('Edited')}</span> : null}
                </div>
                <div className={classes.replyInfoRight}>
                    {isCreateByCurrentUser ? (
                        <ReplyMoreSettingV2
                            className={classes.icon}
                            classNameTriggerFocus={classes.triggerFocus}
                            omitDeleteItem={props.isComment}
                            onDeleteReply={onDeleteReply}
                            onEditCommentAndReply={onEditCommentAndReply}
                            onOpen={onPopupOpen}
                            onClose={onPopupClose}
                        />
                    ) : null}
                    <ReplyEmojiPick
                        className={classes.icon}
                        onEmojiPickStart={onPopupOpen}
                        onEmojiPickEnd={onPopupClose}
                        onEmojiPick={onClickEmoji}
                    />
                    <Tooltip title={translation('Reply')}>
                        <IconButton
                            ref={fastReplyRef}
                            className={classnames(classes.icon, classes.replyIcon)}
                            selected={false}
                            icon={<SvgComment maskId={props.commentReply.id} />}
                            onClick={onFastReply}
                            onKeyDown={(e) => e.preventDefault()}
                            onPointerUp={(e) => e.stopPropagation()}
                        />
                    </Tooltip>
                </div>
            </div>
            <div className={classes.replyContent}>
                <div>
                    <ShowMessage message={props.commentReply.message} usersMap={props.usersMap} />
                </div>
                <ReplyEmoji
                    reactions={props.commentReply.reactions}
                    currentUserId={props.currentUserId}
                    onClickEmoji={onClickEmoji}
                />
            </div>
        </div>
    )
}

export function SvgComment(props: { maskId: number }) {
    const id = useMemo(() => {
        return `comment-path-mask-id-${props.maskId}`
    }, [props.maskId])
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id={id} fill="white">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8V15H8Z"
                />
            </mask>
            <path
                d="M1 15H0V16H1V15ZM14 8C14 11.3137 11.3137 14 8 14V16C12.4183 16 16 12.4183 16 8H14ZM8 2C11.3137 2 14 4.68629 14 8H16C16 3.58172 12.4183 0 8 0V2ZM2 8C2 4.68629 4.68629 2 8 2V0C3.58172 0 0 3.58172 0 8H2ZM0 8V15H2V8H0ZM1 16H8V14H1V16Z"
                fill="#1B1F26"
                mask={`url(#${id})`}
            />
            <path d="M5.5 6L10.5 6V7H5.5V6Z" fill="#1B1F26" />
            <path d="M5.5 9H8.5V10H5.5V9Z" fill="#1B1F26" />
        </svg>
    )
}
