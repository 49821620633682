import { useCallback, useMemo } from 'react'
import { ImageState } from '../../../document/node/node'
import { useViewState } from '../../../view-state-bridge'

type Hash = string | null | undefined

export function useImageLoaded(sourceHash: Hash | Hash[]) {
    const imageStates = useViewState('imageStates')

    const isLoaded = useCallback(
        (imageHash: string | null | undefined) => {
            if (!imageStates || !imageHash) {
                return false
            }
            return imageStates.imageStates[imageHash] === ImageState.IMAGE_STATE_SUCCESSFUL
        },
        [imageStates]
    )

    const isImageLoaded = useMemo(() => {
        if (Array.isArray(sourceHash)) {
            return sourceHash.every((hash) => isLoaded(hash))
        } else {
            return isLoaded(sourceHash)
        }
    }, [isLoaded, sourceHash])

    return { isImageLoaded }
}
