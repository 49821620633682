/* eslint-disable no-restricted-imports */
import { SyncCommentsMetaCommand } from '@wukong/bridge-proto'
import { createSelectors, createStore } from '../../../../../../util/src'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import { CommentUUID, CreateCommentThreadArgs } from '../../../../kernel/interface/comment'
import { Sentry } from '../../../../kernel/sentry'
import { fetchReleaseComment } from '../comment-request'
import { Comment } from '../type'

export class CreateCommentService {
    constructor(protected readonly commandInvoker: CommandInvoker) {}

    private zustandStore = createStore(() => ({
        pendingComments: new Map<CommentUUID, Comment>(),
        failedComments: new Map<CommentUUID, Comment>(),
    }))
    useZustandStore = createSelectors(this.zustandStore)

    // feature switch
    getPendingComments() {
        return this.zustandStore.getState().pendingComments
    }
    private setPendingComments(value: Map<CommentUUID, Comment>) {
        this.zustandStore.setState({
            pendingComments: value,
        })
    }
    getFailedComments() {
        return this.zustandStore.getState().failedComments
    }
    private setFailedComments(value: Map<CommentUUID, Comment>) {
        this.zustandStore.setState({
            failedComments: value,
        })
    }

    storePendingComment(comment: Comment) {
        const map = this.getPendingComments()
        map.set(comment.commentMetaData.uuid, comment)
        this.setPendingComments(map)
    }

    tryRemovePendingComment(commentUUID: CommentUUID) {
        const map = this.getPendingComments()
        if (!map.has(commentUUID)) {
            return
        }

        const commentId = map.get(commentUUID)!.commentMetaData.id
        map.delete(commentUUID)
        this.setPendingComments(map)
        this.commandInvoker.DEPRECATED_invokeBridge(SyncCommentsMetaCommand, {
            removedComments: [String(commentId)],
        })
    }

    addFailedComment(comment: Comment) {
        const map = this.getFailedComments()
        map.set(comment.commentMetaData.uuid, comment)
        this.setFailedComments(new Map(map))
    }

    tryRemoveFailedComment(commmentUUID: CommentUUID) {
        const map = this.getFailedComments()
        if (map.delete(commmentUUID)) {
            this.setFailedComments(new Map(map))
        }
    }

    async requestCreateComment(comment: Comment) {
        this.storePendingComment(comment)
        const meta = comment.commentMetaData
        const commentInfo: CreateCommentThreadArgs = {
            docId: meta.docId,
            page: meta.page,
            uuid: meta.uuid,
            message: meta.message,
            messageText: meta.messageText,
            positionVO: meta.position,
            mentionedUserIds: meta.mentionedUsers.map((user) => user.id),
        }
        try {
            const commentThread = await fetchReleaseComment(commentInfo)
            this.tryRemovePendingComment(comment.commentMetaData.uuid)
            const res: Comment = { commentMetaData: commentThread, overlayPosition: comment.overlayPosition }
            return res
        } catch (e) {
            this.tryRemovePendingComment(comment.commentMetaData.uuid)
            this.addFailedComment(comment)
            Sentry.captureException(e)
            return undefined
        }
    }
}
