/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import type { Position } from '../../../../ui-lib/src'
import { ColorMode } from '../../document/node/node'
import { ComponentPickerViewMode } from '../../ui/component/design-panel-v2/component-instance-panel/components/component-picker/type'
import { FrameTemplateGroupNameCode } from '../../ui/component/design/frame-template/template-data'
import { PluginEnableConfig } from '../../ui/component/plugin/plugin-management/types'
import { PluginsPosition } from '../../ui/component/plugin/type'
import { LocalStorageKey } from '../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../web-storage/local-storage/storage'
import { DEFAULT_LEFT_PANEL_WIDTH, DEV_MODE_RIGHT_PANEL_WIDTH } from '../layout/layout-default-value'
import { getUserConfigStore } from './user-config-db'
import { defaultDevModeInspectCodeConfig, defaultDevModeInspectExportUserConfig } from './user-config-default-value'

import CodeType = Wukong.DocumentProto.CodeType
export interface UserConfigTypeNew extends Wukong.DocumentProto.IUserConfig2 {
    bigNudgeAmount?: number // 大微调量
    prototypingVisibility?: boolean // 是否展原型
    devModeInspectCodeType?: Wukong.DocumentProto.DevModeInspectCodeType // 研发模式标注面板代码 0: Css ｜ 1: Ios ｜ 2: Android
    devModeInspectType?: Wukong.DocumentProto.DevModeInspectType // 研发模式标注面板类型 0: 代码 ｜ 1: 属性
    smallNudgeAmount?: number // 小微调量
    snapToPixel?: boolean // 是否启用像素对齐
    enableLayoutGrid?: boolean // 是否启用网格布局
    showSlice?: boolean
    inspectCodeType?: CodeType // 代码标注类型
    lastAILayoutNodeId?: string // 上次退出时的 AI 布局容器
    showSidebar?: boolean // 是否显示左边栏
    showRuler?: boolean // 是否展示标尺layout-context.tsx
    inverseZoomDirection?: boolean // 是否反转缩放方向
    enablePixelGrid?: boolean // 是否展示像素网格
    leftPanelWidth: number // 左边栏宽度

    frameTemplateExpandItem: string // frame 模板展开项
    fontStyle: Wukong.DocumentProto.TextNodeStyle // 上次使用字体
    devModeRightPanelWidth: number // 研发模式右边栏宽度
    stylePickerListLayout: boolean // 颜色样式展示模式
    colorMode: ColorMode // 色彩空间
    pagePanelCollapsed: boolean | null // page 列表是否展开, 未null时有默认行为
    pagePanelCustomHeightFlag: boolean // page 列表是否自定义高度
    pagePanelHeight: number // page 列表高度
    lastUsedPluginId: string // 最近使用的插件
    pluginsPosition: PluginsPosition // 插件窗口的位置
    looperPluginData: Wukong.DocumentProto.IPluginLooperData // looper 插件数据
    aeExportImage: boolean // ae 是否导出图片
    libraryAssetPanelExpandKeys: string[] // 资产面板展开目录
    colorProfile: Wukong.DocumentProto.DocumentColorProfile // 颜色配置
    ctrlClickTriggerContextMenu: boolean // ctrl 点击唤起上下文菜单
    devModeInspectExportUserConfig: Wukong.DocumentProto.IDevModeInspectExportUserConfig // 研发模式标注面板导出配置
    devModeInspectCodeConfig: Wukong.DocumentProto.IDevModeInspectCodeConfig // 研发模式标注面板导出配置
    showDevModeShareTips: boolean
    // 原型预览：
    presentLeftFlowPanelCustomHeightFlag: boolean // present 模式左侧流程面板是否自定义高度
    presentLeftFlowPanelHeight: number // present 模式左侧流程面板高度
    presentLeftFlowPanelWidth: number // 左边栏宽度
    // 切片
    // AI生成UIModal位置
    AIGenUIModalPos?: Position
    componentPickerViewMode: ComponentPickerViewMode // 实例替换面板视图模式
    // 插件
    pluginEnableConfig: PluginEnableConfig
    pluginDevUseSandbox: boolean
    pluginDevUseHotReload: boolean
}

enum UserConfigScope {
    GLOBAL = 1,
    DOC,
    DOC_AUTH,
}

// TODO(yangming) 下面那些USER_CONFIG_KEY_AND_STORAGE, SEND_TO_WASM_KEY等等常量都可以迁移进来
type ValidateType<T> = {
    [K in keyof Required<T>]: {
        validate: (value: unknown) => boolean
        defaultValue: T[K]
        migrated: boolean
        scope: UserConfigScope
    }
}

const USER_CONFIG_SETTINGS: ValidateType<UserConfigTypeNew> = {
    // owner: yangmingbj03@kanyun.com
    enablePixelGrid: {
        migrated: true,
        validate: (value) => typeof value === 'boolean',
        defaultValue: true,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: chenxiangbj@kanyun.com
    prototypingVisibility: {
        migrated: true,
        validate: (value) => typeof value === 'boolean',
        defaultValue: false,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: loushuangzhanbj@kanyun.com
    frameTemplateExpandItem: {
        migrated: true,
        validate: (value) => {
            switch (value as FrameTemplateGroupNameCode) {
                case FrameTemplateGroupNameCode.IPad:
                case FrameTemplateGroupNameCode.Phone:
                case FrameTemplateGroupNameCode.PC:
                case FrameTemplateGroupNameCode.SmartWatch:
                case FrameTemplateGroupNameCode.SocialMedia:
                case FrameTemplateGroupNameCode.Paper:
                case FrameTemplateGroupNameCode.Other:
                    return true
            }
            return false
        },
        defaultValue: '',
        scope: UserConfigScope.GLOBAL,
    },
    // owner: chenxiangbj@kanyun.com
    inspectCodeType: {
        migrated: true,
        validate: (value) =>
            [CodeType.CODE_TYPE_ANDROID, CodeType.CODE_TYPE_I_O_S, CodeType.CODE_TYPE_CSS].includes(value as CodeType),
        defaultValue: CodeType.CODE_TYPE_CSS,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: yangmingbj03@kanyun.com
    snapToPixel: {
        migrated: true,
        validate: (value) => typeof value === 'boolean',
        defaultValue: true,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: yangmingbj03@kanyun.com
    showRuler: {
        migrated: true,
        validate: (value) => typeof value === 'boolean',
        defaultValue: false,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: yangmingbj03@kanyun.com
    enableLayoutGrid: {
        migrated: true,
        validate: (value) => typeof value === 'boolean',
        defaultValue: false,
        scope: UserConfigScope.DOC_AUTH,
    },
    // owner: yangmingbj03@kanyun.com
    showSidebar: {
        migrated: true,
        validate: (value) => typeof value === 'boolean',
        defaultValue: true,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: yangmingbj03@kanyun.com
    fontStyle: {
        migrated: true,
        validate: (value) =>
            !!value &&
            typeof value === 'object' &&
            'style' in value &&
            'textAlignHorizontal' in value &&
            'textAlignVertical' in value &&
            'textAutoResize' in value &&
            'paragraphIndent' in value &&
            'paragraphSpacing' in value &&
            'textStyleId' in value,
        defaultValue: Wukong.DocumentProto.TextNodeStyle.create(),
        scope: UserConfigScope.GLOBAL,
    },
    // owner: yangmingbj03@kanyun.com
    leftPanelWidth: {
        migrated: true,
        validate: (value) => typeof value === 'number',
        defaultValue: DEFAULT_LEFT_PANEL_WIDTH,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: chenyn01@kanyun.com
    devModeRightPanelWidth: {
        migrated: true,
        validate: (value) => typeof value === 'number',
        defaultValue: DEV_MODE_RIGHT_PANEL_WIDTH,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: yangmingbj03@kanyun.com
    stylePickerListLayout: {
        migrated: true,
        validate: (value) => typeof value === 'boolean',
        defaultValue: true,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: yangmingbj03@kanyun.com
    colorMode: {
        migrated: true,
        validate: (value) => typeof value === 'number' && Object.values(ColorMode).includes(value as ColorMode),
        defaultValue: ColorMode.HEX,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: yangmingbj03@kanyun.com
    pagePanelCollapsed: {
        migrated: true,
        validate: (value) => typeof value === 'boolean' || value === null,
        defaultValue: null,
        scope: UserConfigScope.DOC,
    },
    // owner: yangmingbj03@kanyun.com
    pagePanelCustomHeightFlag: {
        migrated: true,
        validate: (value) => typeof value === 'boolean',
        defaultValue: false,
        scope: UserConfigScope.DOC,
    },
    // owner: yangmingbj03@kanyun.com
    pagePanelHeight: {
        migrated: true,
        validate: (value) => typeof value === 'number',
        defaultValue: 0,
        scope: UserConfigScope.DOC,
    },
    // owner: yangmingbj03@kanyun.com
    lastUsedPluginId: {
        migrated: true,
        validate: (value) => typeof value === 'string' && value.length <= 100,
        defaultValue: '',
        scope: UserConfigScope.DOC,
    },
    // owner: chenpeihao@kanyun.com
    pluginsPosition: {
        migrated: true,
        validate: (value) =>
            !!value && typeof value === 'object' && Object.keys(value).every((key) => typeof key === 'string'),
        defaultValue: {},
        scope: UserConfigScope.GLOBAL,
    },
    // owner: hulh01@kanyun.com
    looperPluginData: {
        migrated: true,
        validate: (value: unknown) => !!value && typeof value === 'object' && 'repeatTime' in value,
        defaultValue: {
            repeatTime: 20,
            translateX: 0,
            translateY: 0,
            width: 0,
            height: 0,
            rotate: {
                mode: 0,
                degree: 0,
                sine: 1,
            },
            opacity: {
                apply: false,
                start: 1,
                end: 0,
            },
            fills: {
                apply: false,
                start: {
                    r: 0,
                    g: 0,
                    b: 0,
                },
                end: {
                    r: 0,
                    g: 0,
                    b: 0,
                },
            },
            strokes: {
                applyColor: false,
                startColor: {
                    r: 0,
                    g: 0,
                    b: 0,
                },
                endColor: {
                    r: 0,
                    g: 0,
                    b: 0,
                },
                applyBorderWeight: false,
                startBorderWeight: 1,
                endBorderWeight: 0.1,
            },
        },
        scope: UserConfigScope.GLOBAL,
    },
    // owner: hulh01@kanyun.com
    aeExportImage: {
        migrated: true,
        validate: (value) => typeof value === 'boolean',
        defaultValue: false,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: jiangjieke@kanyun.com
    inverseZoomDirection: {
        migrated: true,
        validate: (value) => typeof value === 'boolean',
        defaultValue: false,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: loushuangzhanbj@kanyun.com
    smallNudgeAmount: {
        migrated: true,
        validate: (value) => typeof value === 'number',
        defaultValue: 1,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: loushuangzhanbj@kanyun.com
    bigNudgeAmount: {
        migrated: true,
        validate: (value) => typeof value === 'number',
        defaultValue: 10,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: yangmingbj03@kanyun.com
    lastAILayoutNodeId: {
        migrated: true,
        validate: (value) => typeof value === 'string' && /^\d+:\d+$/.test(value),
        defaultValue: '',
        scope: UserConfigScope.DOC,
    },
    // owner: lisw@kanyun.com
    libraryAssetPanelExpandKeys: {
        migrated: true,
        validate: (value) => Array.isArray(value) && value.every((item) => typeof item === 'string'),
        defaultValue: [],
        scope: UserConfigScope.DOC,
    },
    // owner: chenpeihao@kanyun.com
    colorProfile: {
        migrated: true,
        validate: (value) => typeof value == 'number' && !Number.isNaN(value) && Number.isFinite(value) && value >= 0,
        defaultValue: Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: zangbingjie@kanyun.com
    ctrlClickTriggerContextMenu: {
        migrated: true,
        validate: (value) => typeof value === 'boolean',
        defaultValue: false,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: lizhaohuibj01@kanyun.com
    devModeInspectType: {
        migrated: true,
        validate: (value) =>
            [
                Wukong.DocumentProto.DevModeInspectType.DEV_MODE_INSPECT_TYPE_CODE,
                Wukong.DocumentProto.DevModeInspectType.DEV_MODE_INSPECT_TYPE_PROPERTY,
            ].includes(value as Wukong.DocumentProto.DevModeInspectType),
        defaultValue: Wukong.DocumentProto.DevModeInspectType.DEV_MODE_INSPECT_TYPE_CODE,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: lizhaohuibj01@kanyun.com
    devModeInspectCodeType: {
        migrated: true,
        validate: (value) =>
            [
                Wukong.DocumentProto.DevModeInspectCodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS,
                Wukong.DocumentProto.DevModeInspectCodeType.Android_XML,
                Wukong.DocumentProto.DevModeInspectCodeType.Android_Compose,
                Wukong.DocumentProto.DevModeInspectCodeType.iOS_Swift_UI,
                Wukong.DocumentProto.DevModeInspectCodeType.iOS_UI_Kit,
            ].includes(value as Wukong.DocumentProto.DevModeInspectCodeType),
        defaultValue: Wukong.DocumentProto.DevModeInspectCodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: lizhaohuibj01@kanyun.com
    devModeInspectExportUserConfig: {
        migrated: true,
        validate: (value: unknown) =>
            !!value &&
            typeof value === 'object' &&
            'cssConfig' in value &&
            'iosConfig' in value &&
            'androidConfig' in value,
        defaultValue: defaultDevModeInspectExportUserConfig,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: chenxiangbj@kanyun.com
    devModeInspectCodeConfig: {
        migrated: true,
        validate: (value: unknown) => !!value && typeof value === 'object' && 'css' in value,
        defaultValue: defaultDevModeInspectCodeConfig,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: guanzhenzhi@kanyun.com
    showDevModeShareTips: {
        migrated: true,
        validate: (value) => typeof value === 'boolean',
        defaultValue: true,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: lizhaohuibj01@kanyun.com
    presentLeftFlowPanelCustomHeightFlag: {
        migrated: true,
        validate: (value) => typeof value === 'boolean',
        defaultValue: false,
        scope: UserConfigScope.DOC,
    },
    // owner: lizhaohuibj01@kanyun.com
    presentLeftFlowPanelHeight: {
        migrated: true,
        validate: (value) => typeof value == 'number',
        defaultValue: 0,
        scope: UserConfigScope.DOC,
    },
    // owner: lizhaohuibj01@kanyun.com
    presentLeftFlowPanelWidth: {
        migrated: true,
        validate: (value) => typeof value == 'number',
        defaultValue: DEFAULT_LEFT_PANEL_WIDTH,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: hulh01@kanyun.com
    showSlice: {
        migrated: true,
        validate: (value) => typeof value === 'boolean',
        defaultValue: true,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: chaibowen@kanyun.com
    AIGenUIModalPos: {
        migrated: true,
        validate: (value): value is Position | undefined =>
            typeof value == undefined ||
            (typeof value === 'object' &&
                value !== null &&
                Object.hasOwn(value, 'top') &&
                Object.hasOwn(value, 'left') &&
                typeof (value as Position).top === 'number' &&
                typeof (value as Position).left === 'number'),
        defaultValue: undefined,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: zhengyihui@kanyun.com
    componentPickerViewMode: {
        migrated: true,
        validate: (v): v is ComponentPickerViewMode => v === 'grid' || v === 'list',
        defaultValue: 'grid',
        scope: UserConfigScope.GLOBAL,
    },
    // owner: lizhaohuibj01@kanyun.com
    pluginEnableConfig: {
        migrated: true,
        validate: (value: unknown) =>
            !!value && typeof value === 'object' && Object.keys(value).every((key) => typeof key === 'string'),
        defaultValue: {},
        scope: UserConfigScope.GLOBAL,
    },
    // owner: lizhaohuibj01@kanyun.com
    pluginDevUseSandbox: {
        migrated: true,
        validate: (value) => typeof value === 'boolean',
        defaultValue: true,
        scope: UserConfigScope.GLOBAL,
    },
    // owner: lizhaohuibj01@kanyun.com
    pluginDevUseHotReload: {
        migrated: true,
        validate: (value) => typeof value === 'boolean',
        defaultValue: true,
        scope: UserConfigScope.GLOBAL,
    },
}

export function isMigrated(key: keyof UserConfigTypeNew) {
    return USER_CONFIG_SETTINGS[key].migrated
}

export function doValidate(key: keyof UserConfigTypeNew, value: unknown) {
    return USER_CONFIG_SETTINGS[key].validate(value)
}

export const USER_CONFIG_DEFAULT_VALUES2 = (Object.keys(USER_CONFIG_SETTINGS) as Array<keyof UserConfigTypeNew>).reduce(
    (acc, key) => {
        ;(acc[key] as UserConfigTypeNew[typeof key]) = USER_CONFIG_SETTINGS[key].defaultValue
        return acc
    },
    {} as UserConfigTypeNew
)

// [how_to_add_user_config] (0/n) 选择在 GLOBAL_CONFIG_KEYS, DOC_CONFIG_KEYS, DOC_AUTH_CONFIG_KEYS 中添加一个 key
// 如果是全局配置项，不需要在 USER_CONFIG_DEFAULT_VALUES 中添加默认值，该映射关系是为了兼容处理老的 key

// 代码中使用 UserConfig 通用 key 及其默认值
export const USER_CONFIG_DEFAULT_VALUES = {
    enablePixelGrid: '1', // 是否展示像素网格
    prototypingVisibility: '', // 是否展原型
    frameTemplateExpandItem: '', // frame 模板展开项
    inspectCodeType: CodeType.CODE_TYPE_CSS + '', // 代码标注类型
    snapToPixel: '1', // 是否启用像素对齐
    showRuler: '0', // 是否展示标尺
    enableLayoutGrid: '0', // 是否启用网格布局
    showSidebar: '1', // 是否显示左边栏
    fontStyle: '', // 上次使用字体
    leftPanelWidth: DEFAULT_LEFT_PANEL_WIDTH + '', // 左边栏宽度
    devModeRightPanelWidth: DEV_MODE_RIGHT_PANEL_WIDTH + '', // 研发模式右边栏宽度
    stylePickerListLayout: '1', // 颜色样式展示模式
    colorMode: ColorMode.HEX + '', // 色彩空间
    pagePanelCollapsed: '-1', // page 列表是否展开
    pagePanelCustomHeightFlag: '0', // page 列表是否自定义高度
    pagePanelHeight: '0', // page 列表高度
    lastUsedPluginId: '', // 最近使用的插件
    pluginsPosition: '{}', // 插件窗口的位置
    looperPluginData:
        '{"repeatTime":20,"translateX":0,"translateY":0,"width":0,"height":0,"rotate":{"mode":0,"degree":0,"sine":1},"opacity":{"apply":false,"start":1,"end":0},"fills":{"apply":false,"start":{"r":0,"g":0,"b":0},"end":{"r":0,"g":0,"b":0}},"strokes":{"applyColor":false,"startColor":{"r":0,"g":0,"b":0},"endColor":{"r":0,"g":0,"b":0},"applyBorderWeight":false,"startBorderWeight":1,"endBorderWeight":0.1}}', // looper 插件数据
    aeExportImage: '0', // ae 是否导出图片
    inverseZoomDirection: '0', // 是否反转缩放方向
    smallNudgeAmount: '1', // 小微调量
    bigNudgeAmount: '10', // 大微调量
    lastAILayoutNodeId: '', // 上次退出时的 AI 布局容器
    libraryAssetPanelExpandKeys: '', // 资产面板展开目录
    colorProfile: '', // 颜色配置
    ctrlClickTriggerContextMenu: '0', // ctrl 点击唤起上下文菜单
    devModeInspectType: '0', // 研发模式标注面板类型 0: 代码 ｜ 1: 属性
    devModeInspectCodeType: '0', // 研发模式标注面板代码 0: Css ｜ 1: Ios ｜ 2: Android
    devModeInspectExportUserConfig: JSON.stringify(defaultDevModeInspectExportUserConfig), // 研发模式标注面板导出配置
    devModeInspectCodeConfig: JSON.stringify(defaultDevModeInspectCodeConfig), // 研发模式标注面板导出配置
    showDevModeShareTips: '1',
    // 原型预览：
    presentLeftFlowPanelCustomHeightFlag: '0', // present 模式左侧流程面板是否自定义高度
    presentLeftFlowPanelHeight: '0', // present 模式左侧流程面板高度
    presentLeftFlowPanelWidth: DEFAULT_LEFT_PANEL_WIDTH + '', // 左边栏宽度
    // 切片
    showSlice: '1',
    // AI生成UIModal位置
    AIGenUIModalPos: '{}',
    componentPickerViewMode: 'grid', // 实例替换面板视图模式
    pluginEnableConfig: '{}', // 插件使用情况
    pluginDevUseSandbox: '1', // 插件开发是否使用沙盒
    pluginDevUseHotReload: '1', // 插件开发是否使用热更新
} as const

export type UserConfigKey = keyof typeof USER_CONFIG_DEFAULT_VALUES

export type sendToWasmKeyType = keyof Wukong.DocumentProto.IUserConfig2

// 需要传给wasm的key
export const SEND_TO_WASM_KEY: sendToWasmKeyType[] = [
    'bigNudgeAmount',
    'prototypingVisibility',
    'devModeInspectCodeType',
    'devModeInspectType',
    'smallNudgeAmount',
    'snapToPixel',
    'enableLayoutGrid',
    'showSlice',
    'inspectCodeType',
    'lastAILayoutNodeId',
    'showSidebar',
    'showRuler',
    'inverseZoomDirection',
    'enablePixelGrid',
]

export type UserConfigSendToWasm = keyof typeof SEND_TO_WASM_KEY

export const UserConfigKeyMap = Object.keys(USER_CONFIG_DEFAULT_VALUES).reduce((prev, curr) => {
    // @ts-expect-error
    prev[curr] = curr
    return prev
}, {} as Record<UserConfigKey, string>)
// UserConfig 本地数据中存的都是 string
// UserConfig 不负责处理存储值的意义，因此如 boolean 和 number 的转换需要各个场景自己做
export type UserConfigs = { [key in UserConfigKey]: string }

export function getDefaultUserConfigs(): UserConfigs {
    return { ...USER_CONFIG_DEFAULT_VALUES }
}

export function getDefaultUserConfigs2(): UserConfigTypeNew {
    return { ...USER_CONFIG_DEFAULT_VALUES2 }
}

const USER_CONFIG_KEYS = Object.keys(USER_CONFIG_DEFAULT_VALUES)

export function isUserConfigKey(key: string): key is UserConfigKey {
    return USER_CONFIG_KEYS.includes(key)
}

// 全局配置项,对所有文件生效
export const GLOBAL_CONFIG_KEYS = Object.keys(USER_CONFIG_SETTINGS).filter((key) => {
    return USER_CONFIG_SETTINGS[key as keyof UserConfigTypeNew].scope === UserConfigScope.GLOBAL
}) as UserConfigKey[]

// UserConfigKey 到 LocalStorageItem 存储的名称映射，服务端存储的 key 与此无关
// 只有 GlobalConfig 需要设置
// 新的数据项不建议设置这个，在转换时会默认使用 key 本身
const USER_CONFIG_KEY_AND_STORAGE: [UserConfigKey, string][] = [
    ['enablePixelGrid', 'enable-pixel-grid'],
    ['prototypingVisibility', 'prototyping-visibility'],
    ['snapToPixel', 'snap-to-pixel'],
    ['showRuler', 'show-ruler'],
    ['showSidebar', 'show-sidebar'],
    ['fontStyle', 'default_text_style_v2'],
    ['leftPanelWidth', 'left-panel-width'],
    ['stylePickerListLayout', 'style-picker-list-layout'],
    ['colorMode', 'color-mode'],
    ['inspectCodeType', 'inspect-code-type'],
    ['frameTemplateExpandItem', 'frame-template-expand-item'],
    ['pluginsPosition', 'plugins-position'],
    ['looperPluginData', 'looper-plugin-data'],
    ['aeExportImage', 'ae-export-image'],
    ['showDevModeShareTips', 'show-dev-mode-share-tips'],
]

const KeyToStorage = USER_CONFIG_KEY_AND_STORAGE.reduce((map, row) => {
    map[row[0]] = row[1]
    return map
}, {} as { [key in UserConfigKey]: string })
export function getStorageByKey(key: UserConfigKey) {
    return KeyToStorage[key] ?? key
}

const StorageToKey = USER_CONFIG_KEY_AND_STORAGE.reduce((map, row) => {
    map[row[1]] = row[0]
    return map
}, {} as { [key: string]: UserConfigKey })

export function getKeyByStorage(stoarge: string) {
    return StorageToKey[stoarge] ?? stoarge
}

export function isGlobalConfigKey(key: string): key is UserConfigKey {
    return isUserConfigKey(key) && GLOBAL_CONFIG_KEYS.includes(key)
}

// 合并 LocalStorage 与默认值组成 GlobalConfig 的全集
export function getDefaultGlobalConfigsWithStorage2() {
    const configs = getDefaultUserConfigs2()

    GLOBAL_CONFIG_KEYS.forEach((key: keyof UserConfigTypeNew) => {
        if (isMigrated(key)) {
            const value = enhancedLocalStorage.getSerializedItem(getStorageByKey(key) as LocalStorageKey)
            if (value !== null) {
                ;(configs[key] as UserConfigTypeNew[keyof UserConfigTypeNew]) = value
            }
        }
    })

    return configs
}

// 合并 LocalStorage 与默认值组成全集
export async function getDefaultUserConfigsWithStorage2(docId: string, authStr: string) {
    const configs = getDefaultGlobalConfigsWithStorage2()
    try {
        const store = await getUserConfigStore()
        const docKeyValue = await store.get([STORAGE_PREFIX + docId])
        if (docKeyValue) {
            const ret = JSON.parse(docKeyValue.value)
            Object.keys(ret).forEach((key) => ((configs[getKeyByStorage(key)] as UserConfigKey) = ret[key]))
        }
        const authKeyValue = await store.get([STORAGE_PREFIX + authStr])
        if (authKeyValue) {
            const ret = JSON.parse(authKeyValue.value)
            Object.keys(ret).forEach((key) => ((configs[getKeyByStorage(key)] as UserConfigKey) = ret[key]))
        }
    } catch {}

    return configs
}

// 文件配置项，只对当前文件生效
export const DOC_CONFIG_KEYS = Object.keys(USER_CONFIG_SETTINGS).filter((key) => {
    return USER_CONFIG_SETTINGS[key as keyof UserConfigTypeNew].scope === UserConfigScope.DOC
}) as UserConfigKey[]

export function isDocConfigKey(key: string): key is UserConfigKey {
    return isUserConfigKey(key) && DOC_CONFIG_KEYS.includes(key)
}

// 权限配置项，对相同权限的文件生效
export const DOC_AUTH_CONFIG_KEYS = Object.keys(USER_CONFIG_SETTINGS).filter((key) => {
    return USER_CONFIG_SETTINGS[key as keyof UserConfigTypeNew].scope === UserConfigScope.DOC_AUTH
}) as UserConfigKey[]

export function isDocAuthConfigKey(key: string): key is UserConfigKey {
    return isUserConfigKey(key) && DOC_AUTH_CONFIG_KEYS.includes(key)
}

export const STORAGE_PREFIX = 'PREF_'
