import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Tip1: '存在同属性样式但未使用',
    Tip2: '没有同属性样式',
    Tip1Extra: '在设计系统内有相同属性的文本样式，但未使用。',
    Tip2Extra: '在设计系统内没有相同属性的文本样式。',

    ReplaceAll: '全部替换',
    ReplaceWith: '替换为',

    LineHeight: '行高',
    LetterSpacing: '字间距',
    ParagraphSpacing: '段间距',
    Decoration: '文字装饰',
    None: '无',
    Underline: '下划线',
    Strokethrough: '删除线',

    ToastMessageBegin: '本页面内 ',
    ToastMessageSingleEnd: ' 个图层已替换',
    ToastMessagePluralEnd: ' 个图层已替换',

    EmptyPrompt: '未发现文本一致性问题',
} as const

export const enTranslation = {
    Tip1: 'Unused same-property styles available',
    Tip2: 'No same-property styles',
    Tip1Extra: 'Unused text styles with the same properties are available in the design system.',
    Tip2Extra: 'No text styles with the same properties are found in the design system.',

    ReplaceAll: 'Replace all',
    ReplaceWith: 'Replace with',

    LineHeight: 'Line height',
    LetterSpacing: 'Letter spacing',
    ParagraphSpacing: 'Paragraph spacing',
    Decoration: 'Decoration',
    None: 'None',
    Underline: 'Underline',
    Strokethrough: 'Strokethrough',

    ToastMessageBegin: '',
    ToastMessageSingleEnd: ' layer in this page replaced',
    ToastMessagePluralEnd: ' layers in this page replaced',

    EmptyPrompt: 'No text consistency issues found',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
