import { FeatureSwitchConfig, SwitchEnvironmentScopeType, SwitchStrategyType } from '../feature-switch-config'

const Config: FeatureSwitchConfig = {
    name: 'dev-mode-track-mouse-event-processor',
    owner: '',
    description: '',
    strategies: [
        {
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                userList: [],
            },
            env: SwitchEnvironmentScopeType.ALL,
        },
    ],
    alwaysDisableInTests: true,
}
export default Config
