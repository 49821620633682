import { FeatureSwitchKeys } from './keys'

export {
    SwitchEnvironmentScopeType,
    SwitchStrategyType,
    UserGroupType,
    isFeatureSwitchRolledOut,
    isFeatureSwitchExternalized,
} from './feature-switch-config'
export type { SwitchStrategy, FeatureSwitchConfig } from './feature-switch-config'
export { FeatureSwitchConfigs } from './features'
export { FeatureSwitchKeys } from './keys'
export { checkUserInGroup } from './check-user'

export type FeatureSwitch = typeof FeatureSwitchKeys[number]

export function isSwitchKeyValid(key: any): key is FeatureSwitch {
    return FeatureSwitchKeys.includes(key)
}
