import { wkDialogConfirm } from '../../../../ui-lib/src'
import { domLocation, getCommitId, getMotiffName } from '../../../../util/src'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { WebSocketBridge } from '../../document/synergy/web-socket-bridge'
import { documentLoaded$ } from '../../external-store/atoms/editor-context'
import { appStore$ } from '../../external-store/store'
import { WkCLog } from '../../kernel/clog/wukong/instance'
import { CheckClientReleaseRequestV2 } from '../../kernel/request/release'
import { ForceUpgradeScene } from '../../space/app/force-upgrade/constants'
import { translation } from './force-upgrade-reload-service.translation'

const ClientReleaseDefaultValue = {
    available: false,
    content: translation('AVulnerabilityHas', { name: getMotiffName() }),
}
export class ForceUpgradeReloadService {
    private modalDestoryFn?: () => void

    constructor(
        private readonly signal: TraceableAbortSignal,
        private readonly isMirror: boolean,
        private readonly webSocketBridge: WebSocketBridge
    ) {
        this.initForceUpgrade(signal)
        this.initForceReloadPage(signal)
    }

    // 强升
    private initForceUpgrade = (signal: TraceableAbortSignal) => {
        this.webSocketBridge.onTriggerKickCodeReleaseNotValidWithSignal(signal, async () => {
            try {
                const { available, content } = await new CheckClientReleaseRequestV2(ForceUpgradeScene.Editor)
                    .start()
                    .catch(() => ClientReleaseDefaultValue)

                // 这个接口的 available 信息不完全可靠, 当收到 kickCode 的时候就明确一定要刷新页面
                const title = available ? ClientReleaseDefaultValue.content : content

                if (this.isMirror) {
                    WkCLog.log('[force-upgrade] auto reload because of mirror mode')
                    // mirror 模式 reload 不会被其他逻辑遮挡，故直接 return，不需要执行后续步骤
                    return domLocation().reload()
                }

                WkCLog.log('[force-upgrade] destory editor', {
                    release: getCommitId() ?? '',
                })

                if (document.hidden || (window.localBridge && window.electronHidden)) {
                    WkCLog.log('[force-upgrade] auto reload because of hidden')
                    return domLocation().reload()
                }
                if (!appStore$.get(documentLoaded$)) {
                    WkCLog.log('[force-upgrade] auto reload because of document not loaded')
                    return domLocation().reload()
                }

                this.modalDestoryFn?.()
                this.modalDestoryFn = wkDialogConfirm.warning({
                    closable: false,
                    okText: translation('Update'),
                    cancelButtonProps: { style: { display: 'none' } },
                    title: title ?? '',
                    onOk: () => {
                        WkCLog.log('[force-upgrade] user click modal to reload')
                        domLocation().reload()
                    },
                }).destroy
                WkCLog.log('[force-upgrade] show modal')
            } catch (err) {
                WkCLog.log('[force-upgrade] error')
                console.error('[force-upgrade] error', err)
            }
        })
    }

    // 强制刷新
    private initForceReloadPage = (signal: TraceableAbortSignal) => {
        this.webSocketBridge.onTriggerRefreshPageWithSignal(signal, () => {
            // 预览时直接刷新页面
            if (this.isMirror) {
                return domLocation().reload()
            }
            domLocation().reload()
        })

        this.webSocketBridge.onTriggerRefreshPageWithDialogWithSignal(signal, () => {
            // 预览时直接刷新页面
            if (this.isMirror) {
                return domLocation().reload()
            }
            // 处于导入预渲染或后台时直接刷新页面
            if (domLocation().href.includes('isAdvanceRender') || document.hidden) {
                return domLocation().reload()
            }
            this.modalDestoryFn?.()
            this.modalDestoryFn = wkDialogConfirm.warning({
                closable: false,
                okText: translation('Update'),
                cancelButtonProps: { style: { display: 'none' } },
                title: translation('NeedRefreshMotiff'),
                onOk: () => {
                    WkCLog.log('[force-upgrade] user click modal to reload')
                    domLocation().reload()
                },
            }).destroy
        })
    }
}
