/* eslint-disable no-restricted-imports */
import { useEffect, useMemo, useRef, useState } from 'react'
import { FontName } from '../../../../../document/node/node'
import { getFontNameUid, getFontNameUidSet, isEqualFontName } from '../../../../../document/util/font'
import { useViewState } from '../../../../../view-state-bridge'
import { useFontManagerService } from '../../../../context/document-context'

interface Props {
    isStyleNodeOrMixed: boolean
    isFamilyMixed: boolean
    isFontNameMixed: boolean
    missFontFamily: boolean
    missFontStyle: boolean
    selectedFamily: string
    selectedFontName: FontName | null
}

export type LoadingType = 'none' | 'font-family' | 'font-style'

export function useFontLoading({
    isStyleNodeOrMixed,
    isFamilyMixed,
    isFontNameMixed,
    missFontFamily,
    missFontStyle,
    selectedFamily,
    selectedFontName,
}: Props) {
    const [fontLoading, setFontLoading] = useState<{ type: LoadingType }>({ type: 'none' })
    const availableFonts = useViewState('availableFonts')?.availableFonts

    const fontManagerService = useFontManagerService()
    const localFontInfos = fontManagerService.states.use.localFontInfosState()
    const localFontNameUidSet = useMemo(() => getFontNameUidSet(localFontInfos), [localFontInfos])

    const needShowFontLoading = useMemo(() => {
        if (isStyleNodeOrMixed || isFamilyMixed || isFontNameMixed || missFontFamily || missFontStyle) {
            return false
        }

        if (!selectedFontName || localFontNameUidSet.has(getFontNameUid(selectedFontName))) {
            return false
        }

        return !availableFonts?.find((v) => isEqualFontName(v, selectedFontName))
    }, [
        isStyleNodeOrMixed,
        isFamilyMixed,
        isFontNameMixed,
        missFontFamily,
        missFontStyle,
        selectedFontName,
        availableFonts,
        localFontNameUidSet,
    ])

    const lastSelectedFamily = useRef<string>('')

    useEffect(() => {
        setFontLoading({ type: 'none' })
        if (!needShowFontLoading) {
            return
        }

        const type = lastSelectedFamily.current == selectedFamily ? 'font-style' : 'font-family'
        const timer = setTimeout(() => {
            setFontLoading({ type })
        }, 1000)
        return () => clearTimeout(timer)
    }, [needShowFontLoading, selectedFamily, selectedFontName?.style])

    useEffect(() => {
        if (isStyleNodeOrMixed || isFamilyMixed) {
            lastSelectedFamily.current = ''
        } else {
            lastSelectedFamily.current = selectedFamily
        }
    }, [isStyleNodeOrMixed, isFamilyMixed, selectedFamily])

    return fontLoading
}
