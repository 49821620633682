export enum BusinessStatusCode {
    // 设置不合法的文档分享权限
    IllagelSharingPermission = 41003,
    // 权限不足
    PermissionDenied = 41004,
    // 文档禁止拷贝
    ProhibitCopy = 41008,
    // 该文档的组件库已经创建
    LibraryDuplicateCreated = 46002,
    // 组件库文档在草稿箱中不能进行发布操作
    LibraryDocumentInDraftFolderCanNotPublish = 46009,
    // 组件库分享范围超限
    LibrarySharedScopeOverLimit = 46010,
    // 审核没有通过
    AuditNotPass = 73001,
    // 审核过程中因为某些原因失败
    AuditFailed = 73002,
    // 席位申请不存在
    ApplicationNoExist = 80001,
    // 席位申请已存在
    ApplicationAlreadyExist = 80002,
    // 存在更高席位申请，申请已重复
    ApplicationAlreadyApply = 80003,
    // 当前席位申请已被处理
    ApplicationAlreadyResolved = 80004,
    // 由于未付费或席位限制导致功能不可用
    Unavailable = 82001,
    // 文档页面数量超过限制
    DocPagesOverPlanLimit = 82002,
    // 重复创建企业合约
    OrganizationContractAlreadyExist = 83001,
    // 管理员正在升级当前团队/企业
    ContractHasBeenLocked = 85001,
    // 删除团队时发现有欠费
    TeamContractHasNotBeenPaid = 92000,
    // 删除团队时发现有未预约取消的合约
    TeamContractHasNotBeenCanceled = 92001,
}
