import { translation } from './export.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { IconAdd16, WKButton } from '../../../../../../ui-lib/src'
import { isCypress } from '../../../../../../util/src'
import { showPasteImageFailureModal } from '../../../../document/command/image-command'
import { ExportSettings } from '../../../../document/node/node'
import { benchmarkService } from '../../../../kernel/benchmark'
import { CrashFrogType } from '../../../../kernel/interface/performance'
import { MetricCollector, MetricName } from '../../../../kernel/metric-collector'
import { useCopyCheckService } from '../../../../main/app-context'
import { ExportTestId } from '../../../../window'
import { IconButton } from '../../atom/button/icon-button'
import { Title } from '../../atom/title/title'
import { Expand } from '../component/expand'
import { NodePreview } from '../component/node-preview'
import { ProhibitExportPanel } from './batch-export-prohibit-panel'
import { ExportSettingItem } from './export-setting-item'
import { useExport } from './hooks/use-export'
const ExportFormatType = Wukong.DocumentProto.ExportFormatType

const exportFormatType2Label = {
    [ExportFormatType.EXPORT_FORMAT_TYPE_JPG]: 'jpg',
    [ExportFormatType.EXPORT_FORMAT_TYPE_PDF]: 'pdf',
    [ExportFormatType.EXPORT_FORMAT_TYPE_SVG]: 'svg',
    [ExportFormatType.EXPORT_FORMAT_TYPE_PNG]: 'png',
    [ExportFormatType.EXPORT_FORMAT_TYPE_WEBP]: 'webp',
    [ExportFormatType.EXPORT_FORMAT_TYPE_AVIF]: 'avif',
}

export interface ExportProps {
    renderAddItem?: ReactElement // 渲染提供的添加组件，不存在时渲染默认的
}

export function Export(props: ExportProps): JSX.Element {
    const { renderAddItem } = props

    const { addExportSetting, exportSettings, nodeIds, selectLayer, exportSelection, exportWholePage, exportPage } =
        useExport()

    const handleAddClick = useCallback(() => {
        addExportSetting()
    }, [addExportSetting])

    const [showExportSuccess, setShowExportSuccess] = useState(false)

    const [showProhibitExportPanel, setShowProhibitExportPanel] = useState<boolean | undefined>()

    const copyCheckService = useCopyCheckService()
    const copyHasBeenProhibited = copyCheckService.useZustandStore.use.copyHasBeenProhibited()
    const handleExport = useCallback(async () => {
        if (copyHasBeenProhibited) {
            setShowProhibitExportPanel(true)
            return
        }
        try {
            const start = performance.now()
            exportPage ? await exportWholePage() : await exportSelection()
            if (isCypress()) {
                setShowExportSuccess(true)
                MetricCollector.pushMetric(MetricName.EXPORT_DURATION, performance.now() - start)
            }
            exportSettings?.forEach((exportSetting) => {
                benchmarkService.benchmarkCrash(CrashFrogType.Export, false, {
                    tag: exportFormatType2Label[exportSetting.format],
                })
            })
        } catch (e) {
            exportSettings?.forEach((exportSetting) => {
                benchmarkService.benchmarkCrash(CrashFrogType.Export, true, {
                    tag: exportFormatType2Label[exportSetting.format],
                })
            })
            throw e
        }
    }, [copyHasBeenProhibited, exportPage, exportSelection, exportSettings, exportWholePage])

    useEffect(() => {
        setShowProhibitExportPanel(copyHasBeenProhibited)
    }, [copyHasBeenProhibited])

    if (showPasteImageFailureModal === undefined) {
        return <></>
    }

    return showProhibitExportPanel ? (
        <ProhibitExportPanel />
    ) : (
        <div className={classnames('pt-8px pb-8px')} data-testid="export-component">
            {renderAddItem ? (
                renderAddItem
            ) : (
                <div
                    onClick={() => {
                        if (!exportSettings || exportSettings.length === 0) {
                            handleAddClick()
                        }
                    }}
                >
                    <Title grayTheme={!(exportSettings && exportSettings?.length > 0)}>
                        <Title.Left>{translation('Export')}</Title.Left>
                        <Title.Right>
                            <IconButton
                                icon={<IconAdd16 />}
                                selected={false}
                                onClick={handleAddClick}
                                data-testid={ExportTestId.AddExportBtn}
                            />
                        </Title.Right>
                    </Title>
                </div>
            )}
            {exportSettings?.map((exportSetting: ExportSettings, idx: number) => (
                <ExportSettingItem key={idx} idx={idx} exportSetting={exportSetting} />
            ))}
            {exportSettings && exportSettings?.length > 0 && (
                <WKButton
                    type="secondary"
                    onClick={handleExport}
                    data-testid={ExportTestId.ExportBtn}
                    ellipsis
                    style={{ width: 'calc(100% - 32px)', margin: '8px 16px' }}
                >
                    {selectLayer}
                </WKButton>
            )}
            {!exportPage && exportSettings && exportSettings.length > 0 && nodeIds.length === 1 && (
                <Expand title={translation('Preview')}>
                    <NodePreview ids={nodeIds} size={432} className="mb-8px" />
                </Expand>
            )}
            {showExportSuccess && <div data-testid="export-success">success!</div>}
        </div>
    )
}
