import { omit } from 'lodash-es'
import type { HTMLAttributes } from 'react'
import { useCallback } from 'react'
import style from './color-block.module.less'

interface ImageBlockProps extends HTMLAttributes<HTMLImageElement> {
    src?: string
}
const nilSrc =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAExJREFUSEtjlGJk/M+ABTz98webMIM0CwtWcVzqGUctgIXXMA6i/3//Yk1FpKYWXOoZRy2ApaJhHESjRcUIKCpoHsmjRcUIKCpoHckA9l6o/aV9zvEAAAAASUVORK5CYII='

function ImageBlock(props: ImageBlockProps) {
    const { src, className, onClick, onKeyDown, onMouseDown } = props
    const otherProps = omit(props, 'className', 'src', 'onKeyDown', 'onClick', 'onMouseDown')

    const _onKeyDown = useCallback(
        (e: any) => {
            if (e.keyCode === 13) {
                e.stopPropagation()
                onMouseDown?.(e)
            }
            onKeyDown?.(e)
        },
        [onKeyDown, onMouseDown]
    )

    const _onClick = useCallback(
        (e: any) => {
            e.stopPropagation()
            onClick?.(e)
        },
        [onClick]
    )

    const _onMouseDown = useCallback(
        (e: any) => {
            e.preventDefault()
            onMouseDown?.(e)
        },
        [onMouseDown]
    )

    return (
        <div
            className={`${style.container} ${className ?? ''}`}
            {...otherProps}
            onKeyDown={_onKeyDown}
            onClick={_onClick}
            onMouseDown={_onMouseDown}
        >
            <div tabIndex={0} className={style.focusElement}></div>
            <div className={`${style.image} ${style.whiteBg}`}>
                <img src={src ? src : nilSrc} />
            </div>
        </div>
    )
}

export { ImageBlock }
