import classnames from 'classnames'
import { WKTextButton } from '../../../../../../../ui-lib/src'
import { translation } from './index.translation'

interface PropType {
    limit: number
    count: number
    onChange: (limit: number) => void
    className?: string
    dataTestId?: string
}

export function ViewAllV2(props: PropType) {
    return (
        <>
            {props.count > props.limit && (
                <WKTextButton
                    type="primary"
                    size={12}
                    className={classnames('!mt-3', props.className)}
                    onClick={() => props.onChange(props.count)}
                    data-testid={props.dataTestId}
                >
                    {translation('SeeAll', { count: props.count })}
                </WKTextButton>
            )}
        </>
    )
}
