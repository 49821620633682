/* eslint-disable no-restricted-imports */
import { CopyWasmMemToWebBlob, Wukong } from '@wukong/bridge-proto'
import { EmBridge } from '../../kernel/bridge/em-bridge'
import { ServiceClass } from '../../kernel/util/service-class'

export class RawMemAccessService extends ServiceClass {
    constructor(protected override bridge: EmBridge, private blobs = new Map<number, Blob>(), private index = 0) {
        super(bridge)
        this.bridge.bind(CopyWasmMemToWebBlob, (arg) => {
            return this.getWasmMemAsBlob(arg)
        })
    }

    public getWasmMemAsBlob(arg: Wukong.DocumentProto.IParam_CopyWasmMemToWebBlob) {
        const offset = arg.offset
        const len = arg.len
        const service = this.bridge.currentEditorService
        if (
            (typeof offset !== 'number' && typeof len !== 'number') ||
            !offset ||
            !len ||
            offset !== offset ||
            len !== len
        ) {
            throw new Error('invalid call args to getWasmMemAsBlob!')
        }
        if (offset < 0 || len < 0) {
            throw new Error('unreachable code!')
        }
        if (offset >= service.HEAPU8.byteLength || offset + len >= service.HEAPU8.byteLength) {
            throw new Error('index out of bounds!')
        }
        const buf = this.mutSlice(offset, len)
        const blobID = this.index
        this.blobs.set(blobID, new Blob([buf]))
        this.index += 1
        return {
            blobID,
        }
    }

    public copiedSlice(offset: number, len: number): Uint8Array {
        const service = this.bridge.currentEditorService
        const slice = service.HEAPU8.slice(offset, len)
        return slice
    }

    protected mutSlice(offset: number, len: number): DataView {
        const service = this.bridge.currentEditorService
        const slice = new DataView(service.HEAPU8.buffer, offset, len)
        return slice
    }

    public getBlobAndDelete(id: number): Blob | null {
        const blob = this.getBlob(id)
        this.blobs.delete(id)
        return blob
    }

    public getBlob(id: number): Blob | null {
        const blob = this.blobs.get(id) ?? null
        if (blob === null) {
            return null
        }
        return blob
    }

    override destroy() {
        this.bridge.unbind(CopyWasmMemToWebBlob)
        this.blobs.clear()
        this.index = 0
    }
}
