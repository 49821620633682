import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    'Tech Analyst': '技术分析师',
    Dashboard: '仪表板',
    Overview: '概述',
    Customers: '客户',
    Products: '产品',
    Settings: '设置',
    Search: '搜索',
    Download: '下载',
    'Jan 20, 2023 - Feb 09, 2023': '2023年1月20日 - 2023年2月9日',
    Analytics: '分析',
    Reports: '报告',
    Notifications: '通知',
    Subscriptions: '订阅',
    Sales: '销售',
    'Active Now': '当前活跃',
    'Recent Sales': '最近销售',
    'You made 265 sales this month.': '你这个月做了265笔销售。',
    '+$1,999.00': '+$1,999.00',
    'Emma Wilson': '艾玛·威尔逊',
    'emma.wilson@email.com': 'emma.wilson@email.com',
    '+$39.00': '+$39.00',
    'Ryan Park': '瑞安·帕克',
    'ryan.park@email.com': 'ryan.park@email.com',
    '+$299.00': '+$299.00',
    'Sarah Miller': '莎拉·米勒',
    'sarah.miller@email.com': 'sarah.miller@email.com',
    '+$99.00': '+$99.00',
    'David Thompson': '大卫·汤普森',
    'david.thompson@email.com': 'david.thompson@email.com',
    'Marcus Chen': '马克·陈',
    'marcus.chen@email.com': 'marcus.chen@email.com',
    'Total Revenue': '总收入',
    '+20.1% from last month': '从上个月 +20.1%',
    '+180.1% from last month': '从上个月 +180.1%',
    '+19% from last month': '从上个月 +19%',
    '+201 since last hour': '自从上次小时 +201',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    'Tech Analyst': 'Tech Analyst',
    Dashboard: 'Dashboard',
    Overview: 'Overview',
    Customers: 'Customers',
    Products: 'Products',
    Settings: 'Settings',
    Search: 'Search',
    Download: 'Download',
    'Jan 20, 2023 - Feb 09, 2023': 'Jan 20, 2023 - Feb 09, 2023',
    Analytics: 'Analytics',
    Reports: 'Reports',
    Notifications: 'Notifications',
    Subscriptions: 'Subscriptions',
    Sales: 'Sales',
    'Active Now': 'Active Now',
    'Recent Sales': 'Recent Sales',
    'You made 265 sales this month.': 'You made 265 sales this month.',
    '+$1,999.00': '+$1,999.00',
    'Emma Wilson': 'Emma Wilson',
    'emma.wilson@email.com': 'emma.wilson@email.com',
    '+$39.00': '+$39.00',
    'Ryan Park': 'Ryan Park',
    'ryan.park@email.com': 'ryan.park@email.com',
    '+$299.00': '+$299.00',
    'Sarah Miller': 'Sarah Miller',
    'sarah.miller@email.com': 'sarah.miller@email.com',
    '+$99.00': '+$99.00',
    'David Thompson': 'David Thompson',
    'david.thompson@email.com': 'david.thompson@email.com',
    'Marcus Chen': 'Marcus Chen',
    'marcus.chen@email.com': 'marcus.chen@email.com',
    'Total Revenue': 'Total Revenue',
    '+20.1% from last month': '+20.1% from last month',
    '+180.1% from last month': '+180.1% from last month',
    '+19% from last month': '+19% from last month',
    '+201 since last hour': '+201 since last hour',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
