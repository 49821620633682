import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    NudgeAmount: '微调量',
    SmallNudge: '方向键微调',
    BigNudge: 'Shift + 方向键微调',
} as const

export const enTranslation = {
    NudgeAmount: 'Nudge amount',
    SmallNudge: 'Small nudge',
    BigNudge: 'Big nudge',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
