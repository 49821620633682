import { useState } from 'react'
import { translation } from './use-plugin-dev-publish-validate.translation'

export interface ValidStatus {
    nameErrorText?: string
    iconErrorText?: string
    idError?: boolean
}

export function usePluginDevPublishValidate() {
    const [validStatus, setValidStatus] = useState<ValidStatus>({})

    const validate = (name: string, iconSrc?: string): boolean => {
        const validRes: ValidStatus = {}
        if (!name) {
            validRes.nameErrorText = translation('PleaseSetPluginName')
        }

        if (name.length > 50) {
            validRes.nameErrorText = translation('PluginNameTooLong')
        }

        if (!iconSrc) {
            validRes.iconErrorText = translation('PleaseSetPluginIcon')
        }

        if (validRes.nameErrorText || validRes.iconErrorText) {
            setValidStatus((status) => ({ ...status, ...validRes }))
            return false
        }

        return true
    }

    return {
        validStatus,
        setValidStatus,
        validate,
    }
}
