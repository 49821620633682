import { Wukong } from '@wukong/bridge-proto'
import { KeyboardEvent } from 'react'
import { Tooltip } from '../../../../../../../../../ui-lib/src'
import { isKey, KeyboardCode } from '../../../../../../../kernel/keyboard/keyboard-event-handler'
import { CANVAS_ID } from '../../../../../../../main/canvas/canvas'
import { useAutoLayoutCommand } from '../command'
import { AutoLayoutSpaceInputType, AutoLayoutState } from '../types'
import { CounterSpaceInput } from './all-space-input/counter-space-input'
import { SpaceInput } from './all-space-input/space-input'
import style from './float-input.module.less'
import { translation } from './float-input.translation'
import { PaddingInput, PaddingInputType, testIds as paddingTestIds } from './padding-input'

export type FloatInputType = PaddingInputType | 'space' | 'counterSpace' | 'none'

const mapEnumToType: Record<AutoLayoutSpaceInputType, FloatInputType> = {
    [AutoLayoutSpaceInputType.FLOAT_SPACE_INPUT_TYPE_ALL]: 'all',
    [AutoLayoutSpaceInputType.FLOAT_SPACE_INPUT_TYPE_TOP]: 'top',
    [AutoLayoutSpaceInputType.FLOAT_SPACE_INPUT_TYPE_RIGHT]: 'right',
    [AutoLayoutSpaceInputType.FLOAT_SPACE_INPUT_TYPE_BOTTOM]: 'bottom',
    [AutoLayoutSpaceInputType.FLOAT_SPACE_INPUT_TYPE_LEFT]: 'left',
    [AutoLayoutSpaceInputType.FLOAT_SPACE_INPUT_TYPE_VERTICAL]: 'vertical',
    [AutoLayoutSpaceInputType.FLOAT_SPACE_INPUT_TYPE_HORIZONTAL]: 'horizontal',
    [AutoLayoutSpaceInputType.FLOAT_SPACE_INPUT_TYPE_SPACE]: 'space',
    [AutoLayoutSpaceInputType.FLOAT_SPACE_INPUT_TYPE_COUNTER_SPACE]: 'counterSpace',
    [AutoLayoutSpaceInputType.FLOAT_SPACE_INPUT_TYPE_NONE]: 'none',
}

const FloatInputTitle: Record<FloatInputType, string> = {
    all: translation('AllPadding'),
    top: translation('TopPadding'),
    right: translation('RightPadding'),
    bottom: translation('BottomPadding'),
    left: translation('LeftPadding'),
    vertical: translation('VerticalPadding'),
    horizontal: translation('HorizontalPadding'),
    space: '',
    counterSpace: translation('VerticalGap'),
    none: '',
}

function useFloatInputState(state: AutoLayoutState) {
    const canvas = document.getElementById(CANVAS_ID)
    const w = canvas?.getBoundingClientRect().left ?? 0
    const h = canvas?.getBoundingClientRect().top ?? 0
    const floatInputState = state.floatInput
    const type = mapEnumToType[floatInputState.type]

    // 浮动输入框的 tooltip title
    let title = FloatInputTitle[type]
    if (type === 'space') {
        if (state.flex.stackMode === Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_VERTICAL) {
            title = translation('FloatVerticalGap')
        } else {
            title = translation('FloatHorizontalGap')
        }
    }

    return {
        type,
        left: floatInputState.cameraX + w - 40,
        top: floatInputState.cameraY + h - 16,
        title,
    }
}

export const floatIds = {
    container: 'float-input-container',
    paddingInput: paddingTestIds.input,
    toolTip: 'float-input-tooltip',
}

export function FloatInput(props: { state: AutoLayoutState }) {
    const state = useFloatInputState(props.state)
    const command = useAutoLayoutCommand(props.state)

    if (state.type === 'none') return null

    const onTabKey = (e: KeyboardEvent<HTMLInputElement>) => {
        if (isKey(e.nativeEvent, [KeyboardCode.TAB])) {
            command.nextSpaceInput(e.shiftKey)

            e.preventDefault()
            e.stopPropagation()
        }

        if (isKey(e.nativeEvent, [KeyboardCode.ENTER, KeyboardCode.ESCAPE])) {
            command.clearSpaceInput()
        }
    }

    return (
        <div
            className={style.floatInputContainer}
            style={{
                transform: `translate(${state.left}px, ${state.top}px)`,
            }}
            data-testid={floatIds.container}
        >
            <Tooltip title={state.title} dataTestIds={{ tooltip: floatIds.toolTip }} canMouseDownClose>
                <div className={style.floatInput} key={state.type}>
                    {state.type === 'space' ? (
                        <SpaceInput state={props.state} onKeyDown={onTabKey} isFloat />
                    ) : state.type === 'counterSpace' ? (
                        <CounterSpaceInput state={props.state} onKeyDown={onTabKey} isFloat />
                    ) : (
                        <PaddingInput state={props.state} type={state.type} onKeyDown={onTabKey} isFloat />
                    )}
                </div>
            </Tooltip>
        </div>
    )
}
