// from npm fetch-api-progress
export class FetchProgressEvent {
    readonly lengthComputable: boolean
    readonly loaded: number
    readonly total?: number

    constructor(options: { lengthComputable: boolean; loaded: number; total?: number }) {
        this.lengthComputable = options.lengthComputable
        this.loaded = options.loaded
        this.total = options.total
    }
}

export function trackResponseProgress(response: Response, onProgress: (arg0: FetchProgressEvent) => void) {
    if (!onProgress) {
        return response
    }

    const isSupported = typeof Response !== 'undefined' && typeof ReadableStream !== 'undefined'
    if (!response.body || !isSupported) {
        return response
    }

    const reader = response.body.getReader()

    let loaded = 0
    const contentLength = response.headers.get('content-length')
    const contentEncoding = response.headers.get('content-encoding')
    const contentCompressed = contentEncoding && !/^identity$/i.test(contentEncoding)
    const total = contentLength && !contentCompressed ? parseInt(contentLength, 10) : undefined

    const stream = new ReadableStream({
        start(controller) {
            // Report 0 progress
            onProgress(
                new FetchProgressEvent({
                    lengthComputable: typeof total !== 'undefined',
                    loaded,
                    total,
                })
            )
            ;(async function read() {
                const { done, value } = await reader.read()
                if (done) {
                    // Report 100% progress
                    onProgress(
                        new FetchProgressEvent({
                            lengthComputable: typeof total !== 'undefined',
                            loaded,
                            total,
                        })
                    )

                    controller.close()
                    return
                }

                if (value) {
                    loaded += value.length

                    onProgress(
                        new FetchProgressEvent({
                            lengthComputable: typeof total !== 'undefined',
                            loaded,
                            total,
                        })
                    )
                }

                controller.enqueue(value)
                read()
            })()
        },
    })

    return new Response(stream, response)
}

export async function readResponse(response: Response) {
    const chunks = []
    const reader = response.body!.getReader()
    for await (const chunk of readChunks(reader)) {
        chunks.push(chunk)
    }
    return new Blob(chunks, { type: response.headers.get('content-type')! })
}

export function readChunks(reader: ReadableStreamDefaultReader<Uint8Array>) {
    return {
        async *[Symbol.asyncIterator]() {
            let readResult = await reader.read()
            while (!readResult.done) {
                yield readResult.value
                readResult = await reader.read()
            }
        },
    }
}
