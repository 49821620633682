import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    ArcText: '圆弧文字',
    PleaseSelectA: '选中单个文本图层后使用',
    Sweep: '圆心角',
    Radius: '半径',
    Rotation: '旋转',
    FollowTheCurve: '跟随路径',
    ReverseTheDirection: '路径翻转',
    Generate: '生成',
} as const

export const enTranslation = {
    ArcText: 'ArcText',
    PleaseSelectA: 'Please select a text layer.',
    Sweep: 'Sweep',
    Radius: 'Radius',
    Rotation: 'Rotation',
    FollowTheCurve: 'Follow the curve',
    ReverseTheDirection: 'Reverse the direction',
    Generate: 'Generate',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
