import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    HelpAndResources: '帮助',
} as const

export const enTranslation = {
    HelpAndResources: 'Help and resources',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
