import { useState } from 'react'
import { batchInviteUserRequest } from '../../../batch-invite-user-request/batch-invite-user-request'
import classes from './invite-pay-apply.module.less'
import { PayApplyDialogContainer1, PayApplyDialogContainer2 } from './pay-apply-dialog-container'
import { PayApplyUserList } from './pay-apply-user-list'
import { InvitePayApplyProps } from './type'
import { getSeatTypeFromPayApplyDialogSeatType } from './utils'

export function InvitePayApply(props: InvitePayApplyProps) {
    const { seatType, dialogProps, httpProps, description, users, isHideUserList } = props
    const [loading, setLoading] = useState<boolean>(false)
    const onOk = async () => {
        setLoading(true)
        await batchInviteUserRequest(
            httpProps.resourceType,
            httpProps.resourceId,
            users.map((v) => v.email),
            httpProps.role,
            getSeatTypeFromPayApplyDialogSeatType(seatType),
            httpProps.inviteRedirectUrl
        ).finally(() => {
            setLoading(false)
        })
        dialogProps?.onOk?.()
    }

    return isHideUserList ? (
        <PayApplyDialogContainer1
            {...dialogProps}
            onOk={onOk}
            okButtonProps={{ ...dialogProps?.okButtonProps, loading }}
            dataTestIds={{ description: 'invite-pay-apply-dialog' }}
        >
            {description}
        </PayApplyDialogContainer1>
    ) : (
        <PayApplyDialogContainer2
            {...dialogProps}
            onOk={onOk}
            okButtonProps={{ ...dialogProps?.okButtonProps, loading }}
        >
            <div data-testid="invite-pay-apply-dialog" className={classes.description}>
                {description}
            </div>
            <PayApplyUserList
                users={users.filter((u) => u.seatType !== getSeatTypeFromPayApplyDialogSeatType(seatType))}
            />
        </PayApplyDialogContainer2>
    )
}
