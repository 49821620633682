import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    ConfirmShareDraftEditAuth: '确定要分享草稿编辑权限？',
    ConfirmShareDraft: '确定要分享草稿？',
    ShareDoc: '分享文件',
    AdminUpgradeMembersSeatForDoc:
        '确定为成员添加文件「可编辑」权限？这是一个计费动作，作为管理员，你将升级以下成员为「设计席位」。',
    CannotShareDraftEdit: '无法分享草稿',
    CannotShareDraft: '无法分享草稿',
    ShareDraftEditPrefix: '分享草稿编辑权限',
    ShareDraftPrefix: '分享草稿',
    IKnow: '我知道了',
    ShareOthersDraftLimit: '分享草稿需要草稿箱所有者 {{ownerName}}（{{ownerEmail}}）拥有「设计席位」。',
} as const

export const enTranslation = {
    ConfirmShareDraftEditAuth: "Are you sure you want to share a draft's edit access?",
    ConfirmShareDraft: 'Are you sure you want to share drafts?',
    ShareDoc: 'Share file',
    AdminUpgradeMembersSeatForDoc: `Are you sure you want to share edit access to others? This is a billable event. As an admin, you will upgrade them to "Designer seat".`,
    CannotShareDraftEdit: `Unable to share a draft's edit access`,
    CannotShareDraft: 'Unable to share drafts',
    ShareDraftEditPrefix: `To share a draft's edit access`,
    ShareDraftPrefix: 'To share drafts',
    IKnow: 'OK',
    ShareOthersDraftLimit:
        'To share drafts, the owner of the drafts folder {{ownerName}} (ownerEmail) must have a "Designer seat".',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
