import { useCallback } from 'react'
import { useParams } from 'react-router'
import { WKToast } from '../../../../../../../ui-lib/src'
import { sleep } from '../../../../../../../util/src'
import { TraceableAbortSignal } from '../../../../../../../util/src/abort-controller/traceable-abort-controller'
import { PostExportRecord } from '../../../../../kernel/request/document'
import { useCommand, usePreExportService, useSynergySyncStatusService } from '../../../../../main/app-context'
import { useImageDownloadContext } from '../../../../context/document-context'
import { getOriginImage } from '../util'
import { translation } from './use-export-preparation.translation'

async function recordExport(signal: TraceableAbortSignal, docId: string): Promise<string> {
    new PostExportRecord(docId).startWithSignal(signal)
    await sleep(300)
    signal.throwIfAborted()
    const toastId = WKToast.loading(`${translation('Exporting')}...`, { duration: -1 })
    await sleep(300)
    signal.throwIfAborted()
    return toastId
}

export function useExportPreparation() {
    const command = useCommand()
    const imageDownloadContext = useImageDownloadContext()
    const preExportService = usePreExportService()
    const param = useParams()
    const synergySyncStatusService = useSynergySyncStatusService()
    const docId = param.docId

    const prepare = useCallback(
        async (signal: TraceableAbortSignal, nodeIds?: string[]) => {
            if (!docId) {
                return
            }

            if (!synergySyncStatusService.states.getState().isSynergyOfflineState) {
                await getOriginImage(preExportService, command, nodeIds)
                signal.throwIfAborted()
            }

            const toastId = await recordExport(signal, docId!)

            if (imageDownloadContext.states.getState().exportImageNumState.isCanceled) {
                return
            }

            return toastId
        },
        [docId, synergySyncStatusService.states, imageDownloadContext.states, preExportService, command]
    )

    const done = useCallback(async (toastId: string) => {
        await sleep(500)
        WKToast.close(toastId)
    }, [])

    return { prepare, done }
}
