/**
 * @owner: loushuangzhanbj@kanyun.com
 */
import classnames from 'classnames'
import {
    forwardRef,
    HTMLAttributes,
    PropsWithChildren,
    ReactNode,
    Ref,
    useCallback,
    useImperativeHandle,
    useLayoutEffect,
    useRef,
} from 'react'
import { Resize } from '../resize/resize'
import classes from './auto-slide-in-center.module.less'

export interface AutoSlideInCenterProps extends HTMLAttributes<HTMLDivElement> {
    left?: ReactNode
    center?: ReactNode
    right?: ReactNode
    leftClassName?: string
    centerClassName?: string
    centerContentClassName?: string
    rightClassName?: string
    dataTestIds?: {
        container?: string
        left?: string
        center?: string
        centerContent?: string
        right?: string
    }
}

export interface AutoSlideInCenterRef {
    getContainerElement: () => HTMLElement | null
    getLeftElement: () => HTMLElement | null
    getCenterElement: () => HTMLElement | null
    getCenterContentElement: () => HTMLElement | null
    getRightElement: () => HTMLElement | null
    reCalculate: () => void
}

/**
 * @description 放置在中心的内容，会自动的尽量保持居中的位置。目前对应的场景就是编辑器工具栏的布局和toast|notification|help的联合布局
 * */
function _AutoSlideInCenter(props: PropsWithChildren<AutoSlideInCenterProps>, ref?: Ref<AutoSlideInCenterRef>) {
    const {
        left,
        center,
        right,
        className,
        leftClassName,
        centerClassName,
        centerContentClassName,
        rightClassName,
        dataTestIds,
        ...otherProps
    } = props
    const containerRef = useRef<HTMLDivElement>(null)
    const leftRef = useRef<HTMLDivElement>(null)
    const centerRef = useRef<HTMLDivElement>(null)
    const centerContentRef = useRef<HTMLDivElement>(null)
    const rightRef = useRef<HTMLDivElement>(null)

    const resizeCallback = useCallback(() => {
        if (!leftRef.current || !centerRef.current || !centerContentRef.current || !rightRef.current) {
            return
        }
        centerContentRef.current.style.marginLeft = '0px'
        const centerWidth = centerRef.current.getBoundingClientRect().width
        const centerContentWidth = centerContentRef.current.getBoundingClientRect().width
        const leftWidth = leftRef.current.getBoundingClientRect().width
        const rightWidth = rightRef.current.getBoundingClientRect().width
        if (centerContentWidth >= centerWidth) {
            return
        }
        const emptySize = centerWidth - centerContentWidth
        let adjustHorizontalSize = emptySize / 2 + (rightWidth - leftWidth) / 2
        adjustHorizontalSize = Math.min(emptySize, Math.max(0, adjustHorizontalSize))
        centerContentRef.current.style.marginLeft = `${adjustHorizontalSize}px`
    }, [])

    useLayoutEffect(() => {
        resizeCallback()
    }, [resizeCallback])

    useImperativeHandle(
        ref,
        () => ({
            getContainerElement: () => containerRef.current,
            getLeftElement: () => leftRef.current,
            getCenterElement: () => centerRef.current,
            getCenterContentElement: () => centerContentRef.current,
            getRightElement: () => rightRef.current,
            reCalculate: resizeCallback,
        }),
        [resizeCallback]
    )

    return (
        <div
            className={classnames(classes.container, [className])}
            {...otherProps}
            ref={containerRef}
            data-testid={dataTestIds?.container}
        >
            <div className={classnames(classes.left, [leftClassName])} ref={leftRef} data-testid={dataTestIds?.left}>
                {left}
            </div>
            <div
                className={classnames(classes.center, [centerClassName])}
                ref={centerRef}
                data-testid={dataTestIds?.center}
            >
                <Resize onChangeResize={resizeCallback} />
                <div
                    className={classnames(classes.centerContent, [centerContentClassName])}
                    ref={centerContentRef}
                    data-testid={dataTestIds?.centerContent}
                >
                    <Resize onChangeResize={resizeCallback} />
                    {center}
                </div>
            </div>
            <div
                className={classnames(classes.right, [rightClassName])}
                ref={rightRef}
                data-testid={dataTestIds?.right}
            >
                {right}
            </div>
        </div>
    )
}

export const AutoSlideInCenter = forwardRef(_AutoSlideInCenter)
