/* eslint-disable no-restricted-imports */
import { GET, POST, PUT, ResponseType } from '@tutor/network-core'
import { sleep } from '../../../../util/src'
import { DocID } from '../../kernel/interface/type'
import { BaseCommonRequest, BaseRequest } from '../../kernel/request/base-request'
import { ComponentCandidate, ValidateState } from '../ai-service/typings'
import { DownloadUrl } from './data-types'

/**
 * 根据 documentId 获取 AI 识别结果下载地址
 */
@GET
export class GetAiRecognizeDownloadUrl extends BaseCommonRequest<DownloadUrl> {
    constructor(private readonly docId: DocID) {
        super()
    }

    public override requestUrl(): string {
        return `ai-classification/` + this.docId
    }

    public override responseType(): ResponseType {
        return ResponseType.JSON
    }
}

/**
 * 根据 documentId 获取 AI 识别结果下载地址
 */
@GET
export class GetAIRecognizeResult extends BaseRequest<any> {
    constructor(private readonly downloadUrl: string) {
        super()
    }

    public override withCredentials(): boolean {
        return false
    }

    public override requestUrl(): string {
        return this.downloadUrl
    }

    public override responseType(): ResponseType {
        return ResponseType.JSON
    }
}

@GET
export class GetAiLibraryMaintainTemplate extends BaseRequest<string> {
    constructor(private readonly downloadUrl: string) {
        super()
    }

    public override withCredentials(): boolean {
        return false
    }

    public override requestUrl(): string {
        return this.downloadUrl
    }

    public override responseType(): ResponseType {
        return ResponseType.Text
    }
}

export type AIRecognizeStatus =
    | 'INIT'
    | 'ALL_FINISHED'
    | 'TERMINATE'
    | 'AI_RENDER_FINISHED'
    | 'AI_CLASSIFICATION_FINISHED'
    | 'AI_FINISHED'
    | 'AI_NOT_FOUND_SCREEN'

export interface AiMaintainTask {
    taskId: string
    maintainTaskStatus: AIRecognizeStatus
    layerCount: string
    notFoundScreen: boolean
}

export interface ExtractionTask {
    taskId: string
    extractionTaskStatus: AIRecognizeStatus
    taskResultRelateDocId: string
    taskResultRelateFolderId: string
    layerCount: string
    taskResultRelateDocName: string
    taskResultRelateFolderName: string
    taskResultRelateDocPath: string
    taskResultRelateFolderPath: string
}

export interface ExtractionFailedError {
    status: ExtractionFailedStatus
    docIds: string[]
    folderId: string
}

export enum ExtractionFailedStatus {
    Unknown = 0,
    TooManyDocsInRequest = 71001,
    TooManyRunningQuestOneUser = 71002,
    ServerBusy = 71003,
    DocTooLarge = 71004,
    DocSchemaVersionNotSame = 71005,
    HasNoScreen = 79999,
    FreeUserForbidden = 99888,
}

export interface ExtractionFailedResponse {
    businessStatus: ExtractionFailedStatus
    message: string
    timestamp: number
    status: number
}

@PUT
export class StartExtractionTask extends BaseCommonRequest<ExtractionTask> {
    constructor(
        private readonly docIds: string[],
        private readonly folderId: string,
        private readonly version?: number
    ) {
        super()
    }

    override requestArgument(): { [key: string]: string | number } | undefined {
        const result: { [key: string]: string | number } = {
            docIds: this.docIds.join(','),
            folderId: this.folderId,
        }
        if (this.version) {
            result.version = this.version
        }
        return result
    }

    override requestUrl(): string {
        return 'ai/extraction-task'
    }
}

@PUT
export class StartExtractionTaskForLibraryMaintain extends BaseCommonRequest<AiMaintainTask> {
    constructor(
        private readonly docIds: string[],
        private readonly folderId: string,
        private readonly currentLibraryDocId: string,
        private readonly preferences: AiLibraryMaintainPreferences
    ) {
        super()
    }

    override requestArgument(): { [key: string]: string | number } | undefined {
        return {
            docIds: this.docIds.join(','),
            folderId: this.folderId,
            currentLibraryDocId: this.currentLibraryDocId,
        }
    }

    override requestBody() {
        return this.preferences
    }

    override requestUrl(): string {
        return 'ai/library-maintain'
    }
}

@GET
export class GetExtractionTaskForLibraryMaintain extends BaseCommonRequest<AiMaintainTask> {
    constructor(private readonly taskId: string) {
        super()
    }

    override requestUrl(): string {
        return `ai/library-maintain/${this.taskId}`
    }
}

@GET
export class GetExtractionTask extends BaseCommonRequest<ExtractionTask> {
    constructor(private readonly taskId: string) {
        super()
    }

    override requestUrl(): string {
        return `ai/extraction-task/${this.taskId}`
    }
}

@GET
export class GetExtractionDownloadUrl extends BaseCommonRequest<DownloadUrl> {
    constructor(private readonly docId: DocID) {
        super()
    }

    public override requestUrl(): string {
        return `ai/extraction-task/doc/${this.docId}`
    }
}

@GET
export class GetAiMaintainReusltDownloadUrl extends BaseCommonRequest<DownloadUrl> {
    constructor(private readonly taskId: string) {
        super()
    }

    public override requestUrl(): string {
        return `ai/library-maintain/task/${this.taskId}`
    }
}

@POST
export class CancelExtractionTask extends BaseCommonRequest {
    constructor(private readonly taskId: string) {
        super()
    }

    public override requestUrl(): string {
        return `ai/extraction-task/${this.taskId}/cancel`
    }
}

@POST
export class CancelLibraryMaintainTask extends BaseCommonRequest {
    constructor(private readonly taskId: string) {
        super()
    }

    public override requestUrl(): string {
        return `ai/library-maintain/${this.taskId}/cancel`
    }
}

export interface AiLibraryMaintainPreferences {
    hideLowUsage: boolean
    skipCasesWithDifferentLineHeightOnly: boolean
}

@PUT
export class UpdateAiLibraryMaintainPreferencesRequest extends BaseCommonRequest {
    constructor(private readonly docId: string, private readonly preferences: AiLibraryMaintainPreferences) {
        super()
    }

    public override requestUrl(): string {
        return `ai/library-maintain/preferences`
    }

    override requestBody() {
        return this.preferences
    }

    override requestArgument(): { [key: string]: string | number } | undefined {
        return {
            docId: this.docId,
        }
    }
}

@GET
export class GetAiLibraryMaintainPreferencesRequest extends BaseCommonRequest<AiLibraryMaintainPreferences> {
    constructor(private readonly docId: string) {
        super()
    }

    public override requestUrl(): string {
        return `ai/library-maintain/preferences`
    }

    override requestArgument(): { [key: string]: string | number } | undefined {
        return {
            docId: this.docId,
        }
    }
}

@GET
export class GetAiComponentDataRequest extends BaseCommonRequest<ComponentCandidate> {
    constructor(private readonly componentId: string) {
        super()
    }

    public override requestUrl(): string {
        return `ai/component-candidate/componentId`
    }

    override requestArgument(): { [key: string]: string | number } | undefined {
        return {
            componentId: this.componentId,
        }
    }
}

@GET
export class GetAiComponentDataValidate extends BaseCommonRequest<ValidateState> {
    constructor(private readonly docId: DocID) {
        super()
    }

    public override requestUrl(): string {
        return `ai/library-substitute/validate`
    }

    override requestArgument(): { [key: string]: string | number } | undefined {
        return {
            docId: this.docId,
        }
    }
}

@POST
export class SetAiComponentDataValidate extends BaseCommonRequest<ValidateState> {
    constructor(private readonly docId: DocID) {
        super()
    }

    public override requestUrl(): string {
        return `ai/library-substitute/validate`
    }

    override requestArgument(): { [key: string]: string | number } | undefined {
        return {
            docId: this.docId,
        }
    }
}

interface AIRecognizeResult {
    status: AIRecognizeStatus
    docId?: string
    layerCount?: number
}

export async function createAIRecognizeTask(docIds: string[], folderId: string) {
    let error: ExtractionFailedError | undefined
    const request: StartExtractionTask = new StartExtractionTask(docIds, folderId, 2)
    let task: ExtractionTask
    let cancelled = false
    try {
        task = await request.start()
    } catch (e) {
        const status = (request.responseObject as any as ExtractionFailedResponse).businessStatus
        if (status) {
            error = {
                status,
                docIds,
                folderId,
            }
        } else {
            error = {
                status: ExtractionFailedStatus.Unknown,
                docIds,
                folderId,
            }
        }
    }
    const getTaskStatus = async (cb: (result: AIRecognizeResult) => void) => {
        while (task.extractionTaskStatus !== 'ALL_FINISHED' && !cancelled) {
            await sleep(3000)
            task = await new GetExtractionTask(task.taskId).start()
            if (task.extractionTaskStatus === 'TERMINATE') {
                cb({
                    status: task.extractionTaskStatus,
                })
                break
            } else if (task.extractionTaskStatus === 'ALL_FINISHED') {
                cb({
                    status: task.extractionTaskStatus,
                    docId: task.taskResultRelateDocId,
                    layerCount: parseInt(task.layerCount),
                })
                break
            } else if (task.extractionTaskStatus === 'AI_NOT_FOUND_SCREEN') {
                cb({
                    status: task.extractionTaskStatus,
                })
            } else {
                cb({
                    status: task.extractionTaskStatus,
                })
            }
        }
    }
    return {
        error,
        cancel: () => {
            cancelled = true
            new CancelExtractionTask(task.taskId).start()
        },
        getTaskStatus,
    }
}

export async function createAIRecognizeTaskForLibraryMaintain(
    docIds: string[],
    folderId: string,
    currentLibraryDocId: string,
    preferences: AiLibraryMaintainPreferences
) {
    let error: ExtractionFailedError | undefined
    const request = new StartExtractionTaskForLibraryMaintain(docIds, folderId, currentLibraryDocId, preferences)
    let task: AiMaintainTask | undefined
    let cancelled = false
    try {
        task = await request.start()
    } catch (e) {
        const status = (request.responseObject as any as ExtractionFailedResponse).businessStatus
        if (status) {
            error = {
                status,
                docIds,
                folderId,
            }
        } else {
            error = {
                status: ExtractionFailedStatus.Unknown,
                docIds,
                folderId,
            }
        }
    }
    const getTaskStatus = async (cb: (result: AIRecognizeResult) => void) => {
        while (task!.maintainTaskStatus !== 'ALL_FINISHED' && !cancelled) {
            await sleep(3000)
            task = await new GetExtractionTaskForLibraryMaintain(task!.taskId).start()
            if (task.maintainTaskStatus === 'TERMINATE') {
                cb({
                    status: task.maintainTaskStatus,
                })
                break
            } else if (task.maintainTaskStatus === 'ALL_FINISHED') {
                cb({
                    status: task.maintainTaskStatus,
                    layerCount: parseInt(task.layerCount),
                })
                break
            } else if (task.maintainTaskStatus === 'AI_NOT_FOUND_SCREEN') {
                cb({
                    status: task.maintainTaskStatus,
                })
                break
            } else {
                cb({
                    status: task.maintainTaskStatus,
                })
            }
        }
    }
    return {
        taskId: task?.taskId,
        error,
        cancel: () => {
            cancelled = true
            new CancelLibraryMaintainTask(task!.taskId).start()
        },
        getTaskStatus,
    }
}
