import { Wukong } from '@wukong/bridge-proto'
import { parseLinearGradient, parseRadialGradient } from '../utils/gradient'
import { parseRGBA } from '../utils/utils'

export function getInlineNodeFills(window: Window, node: ChildNode) {
    let currentNode = node
    if (currentNode.nodeType === Node.TEXT_NODE) {
        currentNode = node.parentElement!
    }
    const computedStyle = window.getComputedStyle(currentNode as Element)
    const color = computedStyle.color
    const backgroundImage = computedStyle.backgroundImage
    let fills: Wukong.DocumentProto.IPaint[] = [
        {
            type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
            visible: true,
            opacity: parseRGBA(color).a,
            blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
            color: {
                r: parseRGBA(color).r,
                g: parseRGBA(color).g,
                b: parseRGBA(color).b,
            },
        },
    ]
    if (backgroundImage.includes('linear-gradient') && computedStyle.backgroundClip === 'text') {
        const gradient = parseLinearGradient(backgroundImage)

        fills = [
            {
                type: Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_LINEAR,
                visible: true,
                opacity: 1,
                blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
                gradientStops: gradient.gradientStops,
                gradientTransform: gradient.gradientTransform,
            },
        ]
    } else if (backgroundImage.includes('radial-gradient') && computedStyle.backgroundClip === 'text') {
        const gradient = parseRadialGradient(backgroundImage, +computedStyle.width.replace('px', ''))
        if (gradient) {
            fills = [
                {
                    type: Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_RADIAL,
                    visible: true,
                    opacity: 1,
                    blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
                    gradientStops: gradient.colors.map((item, index) => ({
                        position: item.posPercent ?? index / (gradient.colors.length - 1),
                        color: item.rgba,
                    })),
                },
            ]
        }
    }
    return fills
}
