import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Edited: '已编辑',
    Reply: '回复',
} as const

export const enTranslation = {
    Edited: 'Edited',
    Reply: 'Reply',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
