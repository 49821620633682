/* eslint-disable no-restricted-imports */
import { useViewState } from '../../../../view-state-bridge'
import { CandidateComponentPanel } from './components/candidate-component-panel'
import { ComponentPropDefsPanel } from './components/component-prop-defs-panel'
import { ComponentTitlePanel } from './components/component-title-panel'
import { InstancePanel } from './components/instance-panel'
import { VariantPanel } from './components/variant-panel'

export { JumpToFigmaModal } from '../../component/jump-to-figma-modal'

/**
 * 组件：组件描述、发布组件按钮
 * 变体：变体属性、冲突
 * 实例：实例操作面板、合并变体按钮、实例属性、冲突
 * 组件集：组件描述、组件集属性、冲突
 *
 * 独立组件：
 * - 冲突面板
 * - 组件描述
 * - 发布组件按钮
 * - 合并变体按钮
 * - 组件集属性
 * - 变体属性和实例属性（两者的区别在于后者不能编辑）
 */
export function ComponentInstancePanel() {
    const state = useViewState('componentPanelState')
    const replacePanelState = useViewState('replaceInstanceState')

    if (!state) {
        return null
    }

    return (
        <>
            <CandidateComponentPanel {...state.candidateComponentState} />
            <InstancePanel
                {...state.instanceState}
                noBottom={state.variantState.isVariantInstance || state.variantState.isRemovedVariantInstance}
                disableJumpToOrigin={
                    !state.instanceState.isRemote &&
                    (state.variantState.isRemovedVariantInstance || state.instanceState.showRecoverComponent)
                }
                replaceState={replacePanelState}
            />
            <VariantPanel
                {...state.variantState}
                showVariantPropOnInstancePanel={state.instanceState.showVariantProp}
            />
            <ComponentTitlePanel state={state.componentState} />
            <ComponentPropDefsPanel />
        </>
    )
}
