import classnames from 'classnames'
import { BadgeBase, BadgeBaseProps } from './badge-base'
import classes from './badge-dot.module.less'

export interface BadgeDotProps extends BadgeBaseProps {
    size?: 'small' | 'large' // 6px|8px
    color?: 'blue' | 'green' | 'red'
    stroke?: boolean
}

export function BadgeDot(props: BadgeDotProps) {
    const { size = 'small', color = 'blue', stroke, className, ...otherProps } = props
    return (
        <BadgeBase
            className={classnames(classes.badgeDot, className, classes[size], classes[color], {
                [classes.stroke]: stroke,
            })}
            {...otherProps}
        ></BadgeBase>
    )
}
