import { SVGAttributes, useMemo, forwardRef } from 'react'
export const BicolorIconToolbarSubtract24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 4 4)"
                d="M1 0C0.447715 0 0 0.447715 0 1L0 9C0 9.55228 0.447715 10 1 10L9 10C9.55229 10 10 9.55228 10 9L10 1C10 0.447715 9.55228 0 9 0L1 0Z"
                fillRule="nonzero"
                fill="currentColor"
                opacity="0.45"
            />
            <path
                transform="matrix(1 0 0 1 9 9)"
                d="M0 6.5L0 10C0 10.5522 0.447693 11 1 11L10 11C10.5523 11 11 10.5522 11 10L11 1C11 0.447754 10.5523 0 10 0L6.5 0L6.5 5C6.5 5.82837 5.82843 6.5 5 6.5L0 6.5Z"
                fillRule="nonzero"
                fill="currentColor"
            />
        </svg>
    )
})
export const BicolorIconToolbarExclude24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 9.5 9.5)"
                d="M0 0L5 0L5 5L0 5L0 0Z"
                fillRule="nonzero"
                fill="currentColor"
                opacity="0.45"
            />
            <path
                transform="matrix(1 0 0 1 4 3.99951)"
                d="M1 0C0.447693 0 0 0.447754 0 1L0 9C0 9.55225 0.447693 10 1 10L4 10L4 5.00073C4 4.44849 4.44769 4.00073 5 4.00073L10 4.00073L10 1C10 0.447754 9.55231 0 9 0L1 0ZM12 11.0007L12 6L15 6C15.5523 6 16 6.44775 16 7L16 15C16 15.5522 15.5523 16 15 16L7 16C6.44769 16 6 15.5522 6 15L6 12.0007L11 12.0007C11.5523 12.0007 12 11.553 12 11.0007Z"
                fillRule="evenodd"
                fill="currentColor"
            />
        </svg>
    )
})
export const BicolorIconToolbarIntersect24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 4 3.99927)"
                d="M1 0C0.447693 0 0 0.447754 0 1L0 9C0 9.55225 0.447693 10 1 10L3.5 10L3.5 4.50073C3.5 3.94849 3.94769 3.50073 4.5 3.50073L10 3.50073L10 1C10 0.447754 9.55231 0 9 0L1 0ZM12.5 11.5007L12.5 6L15 6C15.5523 6 16 6.44775 16 7L16 15C16 15.5522 15.5523 16 15 16L7 16C6.44769 16 6 15.5522 6 15L6 12.5007L11.5 12.5007C12.0523 12.5007 12.5 12.053 12.5 11.5007Z"
                fillRule="evenodd"
                fill="currentColor"
                opacity="0.45"
            />
            <path transform="matrix(1 0 0 1 9 9)" d="M0 0L6 0L6 6L0 6L0 0Z" fillRule="nonzero" fill="currentColor" />
        </svg>
    )
})
