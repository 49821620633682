/* eslint-disable no-restricted-imports */
import { IN_JEST_TEST } from '../../../environment'
import { featureSwitchManager } from '../../../kernel/switch/core'

let frameId = 0
const originalRequestAnimationFrame = window.requestAnimationFrame
window.requestAnimationFrame = (callback: FrameRequestCallback) => {
    const id = originalRequestAnimationFrame(callback)
    frameId = id
    return id
}

function generateElementDebugInfo(element?: EventTarget | null) {
    if (!element) {
        return
    }
    const el = element as HTMLElement
    return {
        id: el.id,
        tagName: el.tagName?.toLowerCase(),
        testId: el.getAttribute?.('data-testid'),
        className: el.className,
    }
}

export function focusViewTraceLog(...args: Parameters<typeof console.info>) {
    if (featureSwitchManager.isEnabled('focus-view-debug-log')) {
        const title = args.length > 0 ? args[0] : ''
        const restArgs = args.length > 1 ? args.slice(1) : []
        console.group(`[FocusView:${frameId}] ${title}`)
        if (restArgs.length) {
            // 测试中打印 DOM 可能导致崩溃或刷屏，因此只打印部分信息
            if (IN_JEST_TEST) {
                console.info(
                    ...restArgs.map((arg) => (arg instanceof HTMLElement ? generateElementDebugInfo(arg) : arg))
                )
            } else {
                console.info(...restArgs)
            }
        }
        console.groupEnd()
    }
}

export function focusViewWarning(...args: Parameters<typeof console.warn>) {
    if (featureSwitchManager.isEnabled('focus-view-debug-log')) {
        console.warn(`[FocusView:${frameId}]`, ...args)
    }
}
