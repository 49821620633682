import { POST } from '@tutor/network-core'
import { BaseCommonRequest } from './base-request'

@POST
export class CreatePrototypePresentFile extends BaseCommonRequest {
    constructor(private readonly docId: string, private readonly pageId: string, private readonly pageName: string) {
        super()
    }

    public override requestUrl() {
        return `documents/${this.docId}/prototype`
    }

    public override requestBody() {
        return {
            pageId: this.pageId,
            pageName: this.pageName,
        }
    }
}

@POST
export class RecentPrototypePresentFileOpenRecord extends BaseCommonRequest {
    constructor(private readonly docId: string, private readonly pageId: string) {
        super()
    }

    public override requestUrl() {
        return `recents/prototype`
    }

    public override requestArgument() {
        return {
            docId: this.docId,
            pageId: this.pageId,
        }
    }
}
