import { translation } from './scrubbable-input-line-height.translation'
/* eslint-disable no-restricted-imports */
import { IconLineheight, toMixed } from '../../../../../../ui-lib/src'
import { NumberUnit } from '../../../../document/node/node'
import { ScrubbableInputTextProps, ScrubbableTextInput } from './components/scrubbable-input-text'
import { useLineHeightFormatterTool } from './utils/use-line-height-formatter-tool'
import { valueFilterUserConfig } from './utils/value-filter'

/**
 * @param props
 * @returns
 * @description text-line-height 专用
 */
export function ScrubbableInputLineHeight(
    props: Omit<ScrubbableInputTextProps, 'formatter'> & {
        autoValue?:
            | {
                  readonly mixed: false
                  readonly value: number
              }
            | {
                  readonly mixed: true
              }
    }
) {
    const formatter = useLineHeightFormatterTool({ ...props })

    /**
     * LineHeight.unit为Auto时, 有以下两种情况
     * 1. Auto实际值一样, 则在实际值的基础上进行拖拽和上下键修改
     * 2. Auto实际值不一样, 则禁止拖拽、上下键修改
     */
    const isAuto = props.value?.unit === NumberUnit.Auto
    const isAutoMixed = isAuto && props.autoValue && props.autoValue.mixed

    const isMixed = props.isMixed || isAutoMixed

    const computedLineHeightWithAutoValue =
        isAuto && props.autoValue && !props.autoValue.mixed
            ? { unit: NumberUnit.Auto, value: props.autoValue.value }
            : props.value

    return (
        <ScrubbableTextInput
            {...props}
            value={computedLineHeightWithAutoValue}
            isMixed={isMixed}
            customMixedValue={isAutoMixed ? translation('Auto') : undefined}
            icon={<IconLineheight />}
            formatter={formatter}
            shouldClearOnFocus={isAuto}
            scrubbingDisabled={isMixed}
            min={0}
            placeholder={
                isAuto && props.autoValue ? (props.autoValue.mixed ? toMixed() : String(props.autoValue.value)) : ''
            }
            valueFilter={props.value?.unit !== NumberUnit.Percent ? valueFilterUserConfig : undefined}
        />
    )
}
