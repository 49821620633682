import { createSelectors, createStore } from '../../../../util/src'
import { ColorMode } from '../../document/node/node'
import { environment } from '../../environment'
import { ServiceClass } from '../../kernel/util/service-class'
import { getSessionColorMode, setSessionColorMode } from '../../ui/component/design/blend/color-picker/use-color-picker'

export class ColorModeService extends ServiceClass {
    public states = createSelectors(
        createStore<{
            colorModeState: ColorMode
        }>(
            () => ({
                colorModeState: getSessionColorMode() || ColorMode.HEX,
            }),
            environment.isDev
        )
    )

    public setColorMode = (colorMode: ColorMode) => {
        setSessionColorMode(colorMode)
        this.states.setState({ colorModeState: colorMode })
    }
}
