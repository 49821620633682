import { translation } from './comment-jump-link.translation'
/* eslint-disable no-restricted-imports */
import { WKTextButton } from '../../../../../../../../ui-lib/src'
import React from 'react'
import classes from './comment-jump-link.module.less'

export interface CommentJumpLinkProps {
    onClick?: (e: React.MouseEvent<HTMLElement>) => void
}
export function CommentJumpLink(props: CommentJumpLinkProps) {
    return (
        <div className={classes.link}>
            <WKTextButton type="primary" size={12} onClick={props.onClick}>
                {translation('GoToStyle')}
            </WKTextButton>
        </div>
    )
}
