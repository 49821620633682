import autoSize from 'autosize'
import classnames from 'classnames'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useRef } from 'react'
import classes from './html-textarea.module.less'

export interface HtmlTextareaProps extends React.AllHTMLAttributes<HTMLTextAreaElement> {
    disabled?: boolean
    value?: string
    // 拓展自动高度这一基础需求
    autoHeight?: boolean
    autoHeightBaseHeight?: number
}
export interface HtmlTextareaRef {
    getTextareaElement: () => HTMLTextAreaElement
    updateAutoHeight: () => void
    setSelectionRange: HTMLTextAreaElement['setSelectionRange']
}
function _HtmlTextarea(props: HtmlTextareaProps, ref?: React.Ref<HtmlTextareaRef>) {
    const { autoHeight, autoHeightBaseHeight, className, onKeyDown, ...otherProps } = props
    const textareaRef = useRef<HTMLTextAreaElement>(null)

    const _onKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (e.nativeEvent.isComposing) {
                e.stopPropagation()
                return
            }
            onKeyDown?.(e)
        },
        [onKeyDown]
    )

    const updateAutoHeight = useCallback(() => {
        const textarea = textareaRef.current
        if (textarea && autoHeight) {
            autoSize.update(textarea)
        }
    }, [autoHeight])

    useLayoutEffect(() => {
        const textarea = textareaRef.current
        if (textarea && autoHeight) {
            // 设置这个变量的目的: 文本域能应用传入高度时，初始能正确展示且后续高度能被autoSize正确计算。
            // 这么设置这个变量的原因: 浏览器默认计算高度按两行 且 autoSize会重写style里的height。
            textarea.style.setProperty('--autoHeightBaseHeight', `${autoHeightBaseHeight}px`)
        }
    }, [autoHeight, autoHeightBaseHeight])

    useEffect(() => {
        const textarea = textareaRef.current
        if (textarea && autoHeight) {
            autoSize(textarea)
        }
        return () => {
            if (textarea) {
                autoSize.destroy(textarea)
            }
        }
    }, [autoHeight, autoHeightBaseHeight])

    useImperativeHandle(
        ref,
        () => ({
            getTextareaElement: () => textareaRef.current!,
            updateAutoHeight,
            setSelectionRange: (...args) => {
                textareaRef.current!.setSelectionRange(...args)
            },
        }),
        [updateAutoHeight]
    )

    return (
        <textarea
            ref={textareaRef}
            className={classnames(classes.htmlTextarea, [className])}
            onKeyDown={_onKeyDown}
            {...otherProps}
        ></textarea>
    )
}

export const HtmlTextarea = forwardRef(_HtmlTextarea)
