import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Update: '更新',
    NoUpdatesAvailable: '暂无内容',
    UpdateAll: '全部更新',
} as const

export const enTranslation = {
    Update: 'Updates',
    NoUpdatesAvailable: 'No updates',
    UpdateAll: 'Update all',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
