import { useCallback, useEffect, useRef, useState } from 'react'
import { Select, SelectMinimalSingleLevelRef } from '../../../../ui-lib/src'
import { OrganizationVO, RoleStatus } from '../../kernel/interface/type'
import { GetOrganizations } from '../../kernel/request/organizations'
import { translation } from './link-role-dropdown.translation'

enum LinkRoleDropEnum {
    os = 'os', // 仅允许被邀请的协作者访问
    ov = 'ov', // 组织成员可查看
    ovp = 'ovp', // 组织成员可查看原型
    oe = 'oe', // 组织成员可编辑
    av = 'av', // 任何人可查看
    avp = 'avp', // 任何人可查看原型
    ae = 'ae', // 任何人可编辑
}
const enum2label: Record<LinkRoleDropEnum, string> = {
    [LinkRoleDropEnum.os]: translation('OnlyShare'),
    [LinkRoleDropEnum.ov]: translation('MembersOfOrganization'),
    [LinkRoleDropEnum.ovp]: translation('MembersOfOrganization_prototype'),
    [LinkRoleDropEnum.oe]: translation('MembersOfOrganization_synonyms1'),
    [LinkRoleDropEnum.av]: translation('AnyoneWithThe'),
    [LinkRoleDropEnum.avp]: translation('AnyoneWithThe_prototype'),
    [LinkRoleDropEnum.ae]: translation('AnyoneWithThe_synonyms1'),
}
const params2enum = (orgMemberOnly: boolean, publicAccessRole: RoleStatus, onlyShare: boolean) => {
    if (onlyShare) {
        return LinkRoleDropEnum.os
    }
    if (orgMemberOnly && publicAccessRole == RoleStatus.Viewer) {
        return LinkRoleDropEnum.ov
    }
    if (orgMemberOnly && publicAccessRole == RoleStatus.ViewPrototype) {
        return LinkRoleDropEnum.ovp
    }
    if (orgMemberOnly && publicAccessRole == RoleStatus.Editor) {
        return LinkRoleDropEnum.oe
    }
    if (!orgMemberOnly && publicAccessRole == RoleStatus.Viewer) {
        return LinkRoleDropEnum.av
    }
    if (!orgMemberOnly && publicAccessRole == RoleStatus.ViewPrototype) {
        return LinkRoleDropEnum.avp
    }
    if (!orgMemberOnly && publicAccessRole == RoleStatus.Editor) {
        return LinkRoleDropEnum.ae
    }
}
const enum2params: Record<
    Exclude<LinkRoleDropEnum, LinkRoleDropEnum.os>,
    [orgMemberOnly: boolean, publicAccessRole: RoleStatus]
> = {
    [LinkRoleDropEnum.ae]: [false, RoleStatus.Editor],
    [LinkRoleDropEnum.av]: [false, RoleStatus.Viewer],
    [LinkRoleDropEnum.avp]: [false, RoleStatus.ViewPrototype],
    [LinkRoleDropEnum.oe]: [true, RoleStatus.Editor],
    [LinkRoleDropEnum.ov]: [true, RoleStatus.Viewer],
    [LinkRoleDropEnum.ovp]: [true, RoleStatus.ViewPrototype],
}

interface SharingListItem {
    key: LinkRoleDropEnum | undefined
    label: string
    disabled: boolean
}
// 这个组件默认是只有分享文件的时候才会展示
export const LinkRoleDropDown = ({
    orgMemberOnly,
    publicAccessRole,
    isEditable,
    orgId,
    isDraft,
    dimission,
    publicLinkSharing,
    onlyShare,
    onChangeOnlyShare,
    onChange,
    hidePrototype,
}: {
    orgMemberOnly: boolean
    orgId: string
    dimission: boolean
    isEditable: boolean
    publicAccessRole: RoleStatus
    isDraft: boolean
    publicLinkSharing: boolean
    onlyShare: boolean
    onChangeOnlyShare: () => void
    onChange: (orgMemberOnly: boolean, publicAccessRole: RoleStatus) => void
    hidePrototype: boolean
}) => {
    const isPersonal = orgId == '-1'
    const [displayPublicLinkSharing, setDisplayPublicLinkSharing] = useState(publicLinkSharing)

    const [list, setList] = useState<SharingListItem[]>([])
    const [curOrgMemberOnly, setCurOrgMemberOnly] = useState(!publicLinkSharing || orgMemberOnly)

    useEffect(() => {
        if (!publicLinkSharing && !displayPublicLinkSharing) {
            setCurOrgMemberOnly(true)
        } else {
            setCurOrgMemberOnly(orgMemberOnly)
        }
    }, [publicLinkSharing, displayPublicLinkSharing, orgMemberOnly])

    useEffect(() => {
        // 个人组织不允许分享给组织成员 只可以分享给任何人
        setList(
            (!hidePrototype
                ? [
                      LinkRoleDropEnum.os,
                      !isPersonal ? LinkRoleDropEnum.oe : undefined,
                      !isPersonal ? LinkRoleDropEnum.ov : undefined,
                      !isPersonal && !isDraft && !dimission ? LinkRoleDropEnum.ovp : undefined,
                      LinkRoleDropEnum.ae,
                      LinkRoleDropEnum.av,
                      !isDraft && !dimission ? LinkRoleDropEnum.avp : undefined,
                  ]
                : [
                      LinkRoleDropEnum.os,
                      !isPersonal ? LinkRoleDropEnum.ov : undefined,
                      !isPersonal ? LinkRoleDropEnum.oe : undefined,
                      LinkRoleDropEnum.av,
                      LinkRoleDropEnum.ae,
                  ]
            )
                .filter(Boolean)
                .map((key) => ({
                    key: key!,
                    label: enum2label[key!],
                    disabled:
                        !displayPublicLinkSharing &&
                        (key === LinkRoleDropEnum.ae || key === LinkRoleDropEnum.av || key === LinkRoleDropEnum.avp),
                }))
        )
    }, [dimission, displayPublicLinkSharing, hidePrototype, isDraft, isPersonal])

    const [showDropDown, setShowDropDown] = useState(false)
    const [waitAccessSignal, setWaitAccessSignal] = useState(true)

    const updatePublicLinkSharing = useCallback(() => {
        // 仅打开时走网络请求
        if (showDropDown) {
            setWaitAccessSignal(true)
            return
        }
        new GetOrganizations()
            .start()
            .then((orgs: OrganizationVO[]) => {
                const target = orgs.find((org) => org.id == orgId)
                setDisplayPublicLinkSharing(!!target?.publicLinkSharing)
            })
            .finally(() => {
                setWaitAccessSignal(false)
                if (!showDropDown) {
                    setTimeout(() => {
                        ref.current?.open()
                    }, 0)
                }
            })
    }, [orgId, showDropDown])

    const ref = useRef<SelectMinimalSingleLevelRef>(null)

    if (isEditable) {
        return (
            <Select.MinimalSingleLevel
                dataTestIds={{
                    triggerFocus: 'link-role-dropdown-trigger',
                    container: 'link-role-dropdown-popup',
                }}
                placement="bottom left"
                value={params2enum(curOrgMemberOnly, publicAccessRole, onlyShare)}
                onChange={(value) => {
                    if (value === LinkRoleDropEnum.os) {
                        onChangeOnlyShare()
                    } else {
                        onChange(...enum2params[value as Exclude<LinkRoleDropEnum, LinkRoleDropEnum.os>])
                    }
                }}
                label={enum2label[params2enum(curOrgMemberOnly, publicAccessRole, onlyShare)!]}
                ref={ref}
                openStateToBeTrue={() => waitAccessSignal}
                onClick={updatePublicLinkSharing}
                onOpen={() => setShowDropDown(true)}
                onClose={() => setShowDropDown(false)}
            >
                {list.map((o) => {
                    return (
                        <Select.MinimalSingleLevel.Option key={o.key} value={o.key} disabled={o.disabled}>
                            {o.label + (o.disabled ? translation('DisabledByAdmin') : '')}
                        </Select.MinimalSingleLevel.Option>
                    )
                })}
            </Select.MinimalSingleLevel>
        )
    } else {
        return <>{enum2label[params2enum(curOrgMemberOnly, publicAccessRole, onlyShare)!]}</>
    }
}
