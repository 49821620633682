import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CommitType } from '../../../../../../document/command/commit-type'
import { PrototypeTestId } from '../../../../../../window/wk-data-test'
import { EventEmitter } from '../../../../../utils/event-emmitter'
import { InputOptionsForUndoSquash } from '../../../../atom/inputs/components/formatted-input'
import { ScrubbableInputNumber } from '../../../../atom/inputs/scrubbable-input-number'
import { Value } from '../../../../atom/inputs/utils/type'
import { CubicBezierNumber } from '../action-animation-preview'
import { AnimationTransitionTypeCommandProps, useAnimationEasingSpringCommand } from '../use-action-animation-commands'
import { translation } from './animation-easing-spring-function.translation'
import { AnimationEasingSpringPreview } from './animation-easing-spring-preview'
import {
    getSpringDurationHandleOffset,
    getSpringFuncByHandleOffset,
    getSpringHandleOffset,
    getSpringStableDurationByFunc,
    ITransform,
    slowFunction,
    slowFunctionInverse,
    SpringSolver,
    Transformation,
    TransformationLimiter,
    TransformCompose,
} from './spring-util'

interface AnimationEasingSpringFunctionProps {
    animationState: Wukong.DocumentProto.VPrototypeInteractionActionAnimation
    commandProps: AnimationTransitionTypeCommandProps
}

export const AnimationEasingSpringFunction = (props: AnimationEasingSpringFunctionProps) => {
    const { animationState, commandProps } = props
    const { onChangeSpring } = useAnimationEasingSpringCommand(commandProps)
    const func = animationState.easingFunction.value as CubicBezierNumber
    const [mass, stiffness, damping, other] = func

    const startSpringPreview = useCallback(() => {
        commandProps.springEventEmitter.emit('startSpringPreview')
    }, [commandProps.springEventEmitter])

    const onChange = useCallback(
        (easingFunction: CubicBezierNumber, option?: InputOptionsForUndoSquash) => {
            onChangeSpring(
                {
                    duration: Math.round(getSpringStableDurationByFunc(easingFunction) * 1000),
                    easingFunction,
                },
                option
            )
            if (option?.commitType !== CommitType.Noop) {
                startSpringPreview()
            }
        },
        [onChangeSpring, startSpringPreview]
    )

    const onStiffnessChange = (value: Value, option?: InputOptionsForUndoSquash) => {
        if (typeof value != 'number') {
            return
        }

        onChange([mass, value, damping, other], option)
    }

    const onDampingChange = (value: Value, option?: InputOptionsForUndoSquash) => {
        if (typeof value != 'number') {
            return
        }

        onChange([mass, stiffness, value, other], option)
    }

    const onMassChange = (value: Value, option?: InputOptionsForUndoSquash) => {
        if (typeof value != 'number') {
            return
        }

        onChange([value, stiffness, damping, other], option)
    }

    return (
        <div className="flex flex-col pl-10 pr-4">
            {animationState.easingType.value === Wukong.DocumentProto.EasingType.EASING_TYPE_CUSTOM_SPRING && (
                <>
                    <div data-testid={PrototypeTestId.InteractionPopup.AnimationEasingSpringCurve} className="py-6px">
                        <AnimationEasingSpringCurve
                            springEventEmitter={commandProps.springEventEmitter}
                            func={func}
                            onInput={(value) => onChange(value, { commitType: CommitType.Noop })}
                            onChange={(value) => {
                                onChange(value)
                            }}
                        ></AnimationEasingSpringCurve>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex flex-row items-center justify-between h-36px">
                            <div className="wk-font-regular text-black wk-text-12">{translation('Stiffness')}</div>
                            <div className="w-104px h-28px">
                                <ScrubbableInputNumber
                                    onChange={onStiffnessChange}
                                    onScrubEnd={startSpringPreview}
                                    value={stiffness}
                                    resolution={1}
                                    min={0.01}
                                    max={10000}
                                    notUseUserConfig
                                    testId={PrototypeTestId.InteractionPopup.SpringStiffnessInput}
                                />
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-between h-36px">
                            <div className="wk-font-regular text-black wk-text-12">{translation('Damping')}</div>
                            <div className="w-104px h-28px">
                                <ScrubbableInputNumber
                                    onChange={onDampingChange}
                                    onScrubEnd={startSpringPreview}
                                    value={damping}
                                    resolution={1}
                                    min={0.01}
                                    max={1000}
                                    notUseUserConfig
                                    testId={PrototypeTestId.InteractionPopup.SpringDampingInput}
                                />
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-between h-36px">
                            <div className="wk-font-regular text-black wk-text-12">{translation('Mass')}</div>
                            <div className="w-104px h-28px">
                                <ScrubbableInputNumber
                                    onChange={onMassChange}
                                    onScrubEnd={startSpringPreview}
                                    value={mass}
                                    resolution={1}
                                    min={0.01}
                                    max={100}
                                    notUseUserConfig
                                    testId={PrototypeTestId.InteractionPopup.SpringMassInput}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div className="py-6px">
                <AnimationEasingSpringPreview func={func} springEventEmitter={commandProps.springEventEmitter} />
            </div>
        </div>
    )
}

interface AnimationEasingSpringCurveProps {
    springEventEmitter: EventEmitter
    func: CubicBezierNumber
    onInput: (func: CubicBezierNumber) => void
    onChange: (func: CubicBezierNumber) => void
}

const AnimationEasingSpringCurve = ({
    func,
    onInput,
    onChange,
    springEventEmitter,
}: AnimationEasingSpringCurveProps) => {
    const [width, height] = [184, 110]
    const widthTransformation = useMemo(() => Transformation.fromRangeMapping(0, 1, 10, width - 10), [width])
    const heightTransformation = useMemo(() => Transformation.fromRangeMapping(2, 0, 10, height - 10), [height])
    const widthInvert = useMemo(() => Transformation.invert(widthTransformation), [widthTransformation])
    const heightInvert = useMemo(() => Transformation.invert(heightTransformation), [heightTransformation])

    const springPathLine = useMemo(() => {
        const step = widthInvert.transformDirection(1)
        const solver = new SpringSolver(func[0], func[1], func[2], func[3])
        let line = `M ${widthTransformation.transformValue(0)}, ${heightTransformation.transformValue(0)}`
        for (let x = 0; x <= 1; x += step) {
            const y = solver.solve(x)
            if (isNaN(y)) {
                return ''
            }
            line += ` L ${widthTransformation.transformValue(x)}, ${heightTransformation.transformValue(y)}`
        }

        return line
    }, [func, heightTransformation, widthTransformation, widthInvert])

    const springDuration = useMemo(() => {
        return getSpringDurationHandleOffset(func)
    }, [func])

    const springHandle = useMemo(() => {
        return getSpringHandleOffset(func)
    }, [func])

    const container = useRef<HTMLDivElement>(null)

    const [dragInfo, setDragInfo] = useState<{
        handle?: 'DURATION_HANDLE' | 'SPRING_HANDLE' | 'BACKGROUND'
        offset?: { x: ITransform; y: ITransform }
    }>({})

    const [hoverInfo, setHoverInfo] = useState<'DURATION_HANDLE' | 'SPRING_HANDLE'>()

    useEffect(() => {
        if (!dragInfo.handle) {
            return
        }
        const onPointerMove = (event: MouseEvent) => {
            if (!dragInfo.handle) {
                return
            }
            event.stopPropagation()

            const rect = container.current!.getBoundingClientRect()
            const eventOffset = {
                x: widthInvert.transformValue(event.clientX - rect.left),
                y: heightInvert.transformValue(event.clientY - rect.top),
            }

            if (dragInfo.offset) {
                onInput(
                    getSpringFuncByHandleOffset(
                        {
                            x: dragInfo.offset.x.transformValue(eventOffset.x),
                            y: dragInfo.offset.y.transformValue(eventOffset.y),
                        },
                        func
                    )
                )
                return
            }

            if (dragInfo.handle === 'DURATION_HANDLE') {
                setDragInfo({
                    ...dragInfo,
                    offset: {
                        x: new TransformCompose(
                            new Transformation()
                                .translate(springDuration - eventOffset.x)
                                .scale(springHandle.x / springDuration),
                            new TransformationLimiter({
                                hardLimit: 0,
                                threshold: 0.08,
                                initialValue: springHandle.x,
                                slowFunction: slowFunction,
                                slowFunctionInverse: slowFunctionInverse,
                            })
                        ),
                        y: Transformation.fromConstant(springHandle.y),
                    },
                })
            } else if (dragInfo.handle === 'SPRING_HANDLE') {
                setDragInfo({
                    ...dragInfo,
                    offset: {
                        x: new TransformCompose(
                            new Transformation().translate(springHandle.x - eventOffset.x),
                            new TransformationLimiter({
                                hardLimit: 0,
                                threshold: 0.15,
                                initialValue: springHandle.x,
                                slowFunction: slowFunction,
                                slowFunctionInverse: slowFunctionInverse,
                            })
                        ),
                        y: new TransformCompose(
                            new Transformation().translate(springHandle.y - eventOffset.y),
                            new TransformationLimiter({
                                hardLimit: 2,
                                threshold: 1.8,
                                initialValue: springHandle.y,
                                slowFunction: slowFunction,
                                slowFunctionInverse: slowFunctionInverse,
                            })
                        ),
                    },
                })
            } else if (dragInfo.handle === 'BACKGROUND') {
                const offset =
                    eventOffset.x > springHandle.x
                        ? {
                              x: new Transformation().scale(springHandle.x / eventOffset.x),
                              y: Transformation.fromConstant(springHandle.y),
                          }
                        : {
                              x: new Transformation().translate(springHandle.x - eventOffset.x),
                              y: Transformation.fromConstant(springHandle.y),
                          }
                setDragInfo({
                    ...dragInfo,
                    offset: {
                        x: new TransformCompose(
                            offset.x,
                            new TransformationLimiter({
                                hardLimit: 0,
                                threshold: 0.08,
                                initialValue: springHandle.x,
                                slowFunction: slowFunction,
                                slowFunctionInverse: slowFunctionInverse,
                            })
                        ),
                        y: offset.y,
                    },
                })
            }
        }

        const onPointerUp = (event: MouseEvent) => {
            event.stopPropagation()
            setDragInfo({})
            onChange(func)
            springEventEmitter.emit('startSpringPreview')
        }

        const onMouseMove = (event: MouseEvent) => {
            event.stopPropagation()
        }

        const onMouseUp = (event: MouseEvent) => {
            event.stopPropagation()
        }

        document.addEventListener('pointermove', onPointerMove)
        document.addEventListener('pointerup', onPointerUp)
        document.addEventListener('mousemove', onMouseMove, true)
        document.addEventListener('mouseup', onMouseUp, true)
        return () => {
            document.removeEventListener('pointermove', onPointerMove)
            document.removeEventListener('pointerup', onPointerUp)
            document.removeEventListener('mousemove', onMouseMove, true)
            document.removeEventListener('mouseup', onMouseUp, true)
        }
    }, [
        dragInfo,
        func,
        heightInvert,
        onChange,
        onInput,
        springDuration,
        springHandle.x,
        springHandle.y,
        widthInvert,
        springEventEmitter,
    ])

    const handleSVGDbClick: MouseEventHandler<SVGSVGElement> = useCallback(
        (event) => {
            const rect = container.current!.getBoundingClientRect()
            const eventOffset = {
                x: widthInvert.transformValue(event.clientX - rect.left),
                y: heightInvert.transformValue(event.clientY - rect.top),
            }

            onChange(getSpringFuncByHandleOffset(eventOffset, func))
        },
        [func, heightInvert, onChange, widthInvert]
    )

    // drag / hover / none
    let durationHandleState: 'drag' | 'hover' | 'none' = 'none'
    let springHandleState: 'drag' | 'hover' | 'none' = 'none'
    if (dragInfo.handle) {
        switch (dragInfo.handle) {
            case 'DURATION_HANDLE':
            case 'BACKGROUND':
                durationHandleState = 'drag'
                break
            case 'SPRING_HANDLE':
                springHandleState = 'drag'
                break
        }
    } else {
        switch (hoverInfo) {
            case 'DURATION_HANDLE':
                durationHandleState = 'hover'
                break
            case 'SPRING_HANDLE':
                springHandleState = 'hover'
                break
            default:
                break
        }
    }

    return (
        <div
            className="rounded-$wk-radius-default overflow-hidden"
            style={{ width: `${width}px`, height: `${height}px`, boxShadow: '0 0 0 1px var(--wk-gray-3) inset' }}
            ref={container}
            data-disabled-drag-move="true"
        >
            <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} onDoubleClick={handleSVGDbClick}>
                <line
                    x1={widthTransformation.transformValue(0)}
                    y1={heightTransformation.transformValue(1)}
                    x2={widthTransformation.transformValue(1)}
                    y2={heightTransformation.transformValue(1)}
                    className="stroke-$wk-canvas-2"
                ></line>

                <path d={springPathLine} fill="transparent" className="stroke-$wk-black"></path>

                <rect
                    className={classNames(
                        'stroke-$wk-white stroke-width-1px',
                        {
                            drag: 'fill-$wk-brand-8',
                            hover: 'fill-$wk-brand-6',
                            none: 'fill-$wk-gray-13',
                        }[durationHandleState]
                    )}
                    x={widthTransformation.transformValue(springDuration) - 2.5}
                    y={heightTransformation.transformValue(1) - 7}
                    width={5}
                    height={14}
                    rx={2.5}
                    ry={2.5}
                ></rect>

                <circle
                    className={classNames(
                        'stroke-$wk-white stroke-width-1px',
                        {
                            drag: 'fill-$wk-brand-8',
                            hover: 'fill-$wk-brand-6',
                            none: 'fill-$wk-gray-13',
                        }[springHandleState]
                    )}
                    cx={widthTransformation.transformValue(springHandle.x)}
                    cy={heightTransformation.transformValue(springHandle.y)}
                    r={5}
                ></circle>

                <rect
                    x={0}
                    y={0}
                    width={width}
                    height={height}
                    fill="transparent"
                    className="cursor-ew-resize"
                    onPointerDown={() => {
                        setDragInfo({ handle: 'BACKGROUND' })
                        springEventEmitter.emit('resetSpringPreview')
                    }}
                    onMouseEnter={() => setHoverInfo('DURATION_HANDLE')}
                    onMouseLeave={() => setHoverInfo(undefined)}
                ></rect>
                <circle
                    className="cursor-ew-resize"
                    fill="transparent"
                    cx={widthTransformation.transformValue(springDuration)}
                    cy={heightTransformation.transformValue(1)}
                    r={16}
                    onPointerDown={() => {
                        setDragInfo({ handle: 'DURATION_HANDLE' })
                        springEventEmitter.emit('resetSpringPreview')
                    }}
                    onMouseEnter={() => setHoverInfo('DURATION_HANDLE')}
                    onMouseLeave={() => setHoverInfo(undefined)}
                ></circle>

                <circle
                    className="cursor-default"
                    fill="transparent"
                    cx={widthTransformation.transformValue(springHandle.x)}
                    cy={heightTransformation.transformValue(springHandle.y)}
                    r={16}
                    onPointerDown={() => {
                        setDragInfo({ handle: 'SPRING_HANDLE' })
                        springEventEmitter.emit('resetSpringPreview')
                    }}
                    onMouseEnter={() => setHoverInfo('SPRING_HANDLE')}
                    onMouseLeave={() => setHoverInfo(undefined)}
                ></circle>
            </svg>
        </div>
    )
}
