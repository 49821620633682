/* eslint-disable @typescript-eslint/no-shadow */
import {
    PLUGIN_CLIENT_QUOTA_LIMIT,
    PLUGIN_CLIENT_QUOTA_STORE,
    PLUGIN_CLIENT_STORAGE_STORE,
    openPluginDB,
} from './plugin-db'

function getValueLength({ value }: { value: any }) {
    let len = 0
    return (
        JSON.stringify(value, (r, n) =>
            typeof n == 'string'
                ? ((len += n.length), '')
                : ArrayBuffer.isView(n)
                ? ((len += n.buffer.byteLength), '')
                : n === void 0
                ? ''
                : n
        ).length + len
    )
}

async function getStorageHandler({ userId, pluginId, key }: { userId: string; pluginId: string; key: string }) {
    const db = await openPluginDB()
    const store = db.transaction([PLUGIN_CLIENT_STORAGE_STORE], 'readonly')

    const storageIndex = [userId, pluginId, key]
    const storageStore = store.objectStore(PLUGIN_CLIENT_STORAGE_STORE)
    const storageReq = await storageStore.get(storageIndex)

    return storageReq
}

async function updateStorageHandler({
    userId,
    pluginId,
    key,
    value,
    remove,
}: {
    userId: string
    pluginId: string
    key: string
    value?: any
    remove?: boolean
}) {
    const db = await openPluginDB()
    const newStorage = remove ? void 0 : { value }
    const store = db.transaction([PLUGIN_CLIENT_QUOTA_STORE, PLUGIN_CLIENT_STORAGE_STORE], 'readwrite')
    const storageIndex = [userId, pluginId, key]
    const quotaIndex = [userId, pluginId]
    const storageStore = store.objectStore(PLUGIN_CLIENT_STORAGE_STORE)
    const quotaStore = store.objectStore(PLUGIN_CLIENT_QUOTA_STORE)

    const storage = await storageStore.get(storageIndex)
    const quota = await quotaStore.get(quotaIndex)

    const quotaValue = quota ? quota.quota : 0
    const storageLength = storage ? key.length + getValueLength(storage) : 0
    const newStorageLength = newStorage ? key.length + getValueLength(newStorage) : 0

    const newQuota = {
        quota: quotaValue + newStorageLength - storageLength,
    }

    if (newQuota.quota > PLUGIN_CLIENT_QUOTA_LIMIT) {
        store.abort()
        throw new Error('Client storage quota exceeded for this plugin ID. Please use less client storage.')
    }

    newStorage ? await storageStore.put(newStorage, storageIndex) : await storageStore.delete(storageIndex)
    await quotaStore.put(newQuota, quotaIndex)
}

async function keysStorageHandler({ userId, pluginId }: { userId: string; pluginId: string }) {
    const db = await openPluginDB()
    const store = db.transaction([PLUGIN_CLIENT_STORAGE_STORE], 'readonly')

    const storageIndex = IDBKeyRange.bound([userId, pluginId], [userId, pluginId, []])
    const storageStore = store.objectStore(PLUGIN_CLIENT_STORAGE_STORE)
    const storageReq = await storageStore.getAllKeys(storageIndex)

    return storageReq.map((v: any) => v?.[2])
}

export function getAsync(
    key: string,
    userId: string,
    pluginId: string
): Promise<
    | {
          value: any
      }
    | undefined
> {
    return new Promise((resolve, reject) => {
        getStorageHandler({
            userId,
            pluginId,
            key,
        })
            .then((v) => {
                resolve(v)
            })
            .catch((err) => {
                reject(`Failed to get client storage key ${key}: ${err}`)
            })
    })
}

export function setAsync(key: string, value: string, userId: string, pluginId: string) {
    return new Promise<void>((resolve, reject) => {
        updateStorageHandler({
            userId,
            pluginId,
            key,
            value,
        })
            .then(() => {
                resolve()
            })
            .catch((err) => {
                reject(`Failed to set client storage key ${key}: ${err}`)
            })
    })
}

export function deleteAsync(key: string, userId: string, pluginId: string) {
    return new Promise<void>((resolve, reject) => {
        updateStorageHandler({
            userId,
            pluginId,
            key,
            remove: true,
        })
            .then(() => {
                resolve()
            })
            .catch((err) => {
                reject(`Failed to delete client storage key ${key}: ${err}`)
            })
    })
}

export function keysAsync(userId: string, pluginId: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
        keysStorageHandler({
            userId,
            pluginId,
        })
            .then((v) => {
                resolve(v)
            })
            .catch((err) => {
                reject(`Failed to get client storage keys: ${err}`)
            })
    })
}
