/* eslint-disable no-restricted-imports */
import { IconCheckboxChecked, ScrollView, Tooltip, useEllipsisTooltip } from '../../../../../../../../ui-lib/src'
import { useCallback, useMemo, useRef } from 'react'
import { FontWeight, FontWidth } from '../../../../../../document/node/node'
import type { FontInfoExt } from '../../../../../../main/font/interface'
import classes from './font-select-option.module.less'

interface Props {
    readonly fontInfo: FontInfoExt
    readonly isSelect: boolean
    readonly preSelectedKey: number
    readonly onPreSelected: (preSelectedKey: Props['preSelectedKey']) => void
    readonly onClick: (option: Props['fontInfo']) => void
}

const findBestFamilySampleResourceUrl = (fontInfo: FontInfoExt) => {
    const regular = fontInfo.styles.find(
        (style) => style.weight === FontWeight.NormalWeight && !style.italic && style.width == FontWidth.NormalWidth
    )
    return regular ? regular : fontInfo.styles[0]
}

export function FontSelectOption({ fontInfo, isSelect, preSelectedKey, onClick, onPreSelected }: Props) {
    const divRef = useRef<HTMLDivElement>(null)
    const { inactivation, ellipsisRef, onmouseenter, onmouseleave } = useEllipsisTooltip<HTMLDivElement>()

    const { familySampleResourceId, familySampleResourceUrl } = findBestFamilySampleResourceUrl(fontInfo)
    const validResourceUrl = familySampleResourceId && familySampleResourceUrl
    const style = useMemo(() => {
        return validResourceUrl
            ? undefined
            : {
                  fontFamily: `${fontInfo.family}, Inter, apple-system, BlinkMacSystemFont, segoe ui, Helvetica Neue, Arial, PingFang SC, Microsoft Yahei, Hiragino Sans GB, Noto Sans, Heiti SC, sans-serif`,
              }
    }, [fontInfo.family, validResourceUrl])

    const onMouseMove = useCallback(() => {
        onPreSelected(preSelectedKey)
    }, [onPreSelected, preSelectedKey])

    return (
        <Tooltip title={fontInfo.localizedFamily} inactivation={inactivation} placement="left">
            <ScrollView.Item
                uniqueKey={preSelectedKey}
                ref={divRef}
                selectClassName={classes.selecting}
                className={classes.option}
                style={style}
                onMouseEnter={onmouseenter}
                onMouseMove={onMouseMove}
                onMouseLeave={onmouseleave}
                onClick={(): void => onClick(fontInfo)}
            >
                <span className={classes.selectIcon}>{isSelect ? <IconCheckboxChecked /> : null}</span>
                {validResourceUrl ? (
                    <img
                        className={classes.sample}
                        src={familySampleResourceUrl}
                        data-testid={`family-${fontInfo.family}-${fontInfo.styles.length}`}
                    ></img>
                ) : (
                    <div
                        className={classes.fontName}
                        ref={ellipsisRef}
                        data-testid={`family-${fontInfo.family}-${fontInfo.styles.length}`}
                    >
                        {fontInfo.localizedFamily}
                    </div>
                )}
            </ScrollView.Item>
        </Tooltip>
    )
}
