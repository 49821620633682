import { Wukong } from '@wukong/bridge-proto'
import type { GradientPaint, ImagePaint, Paint, SolidPaint } from '../../document/node/node'
import PaintType = Wukong.DocumentProto.PaintType

export function switchPaintType(originPaint: Paint, targetPaintType: PaintType): Paint {
    if (originPaint.type === targetPaintType) {
        return originPaint
    }

    const isToImage = targetPaintType === PaintType.PAINT_TYPE_IMAGE_PAINT
    if (isToImage) {
        return getDefaultImagePaint()
    }

    const isToGradient = isGradientType(targetPaintType)
    if (isToGradient) {
        const defaultGradientPaint = getDefaultGradientPaint()
        if (isGradientType(originPaint.type)) {
            return Object.assign(defaultGradientPaint, originPaint, { type: targetPaintType })
        } else if (originPaint.type === PaintType.PAINT_TYPE_IMAGE_PAINT) {
            return Object.assign(defaultGradientPaint, { type: targetPaintType })
        } else if (originPaint.type === PaintType.PAINT_TYPE_SOLID_PAINT) {
            const { color } = originPaint
            const startColor = { r: color.r, g: color.g, b: color.b, a: 255 }
            const endColor = { r: color.r, g: color.g, b: color.b, a: 0 }
            const gradientStops = [
                { position: 0, color: startColor },
                { position: 1, color: endColor },
            ]
            return Object.assign(defaultGradientPaint, { gradientStops }, { type: targetPaintType })
        }
    }

    const isToSolid = targetPaintType === PaintType.PAINT_TYPE_SOLID_PAINT
    if (isToSolid) {
        const defaultSolidPaint = getDefaultSolidPaint()
        if (isGradient(originPaint)) {
            const gradientStops = [...originPaint.gradientStops].sort((a, b) => a.position - b.position)
            const gradientColor = gradientStops[0].color
            const opacity = gradientColor.a / 255
            const color = { r: gradientColor.r, g: gradientColor.g, b: gradientColor.b }
            return Object.assign(defaultSolidPaint, { color, opacity })
        } else if (originPaint.type === PaintType.PAINT_TYPE_IMAGE_PAINT) {
            return defaultSolidPaint
        }
    }

    return getDefaultSolidPaint()
}

export function isGradientType(
    paintType: PaintType
): paintType is
    | PaintType.PAINT_TYPE_GRADIENT_ANGULAR
    | PaintType.PAINT_TYPE_GRADIENT_DIAMOND
    | PaintType.PAINT_TYPE_GRADIENT_LINEAR
    | PaintType.PAINT_TYPE_GRADIENT_RADIAL {
    return (
        paintType === PaintType.PAINT_TYPE_GRADIENT_ANGULAR ||
        paintType === PaintType.PAINT_TYPE_GRADIENT_DIAMOND ||
        paintType === PaintType.PAINT_TYPE_GRADIENT_LINEAR ||
        paintType === PaintType.PAINT_TYPE_GRADIENT_RADIAL
    )
}
export function isGradient(paint: Paint): paint is GradientPaint {
    return (
        paint.type === PaintType.PAINT_TYPE_GRADIENT_ANGULAR ||
        paint.type === PaintType.PAINT_TYPE_GRADIENT_DIAMOND ||
        paint.type === PaintType.PAINT_TYPE_GRADIENT_LINEAR ||
        paint.type === PaintType.PAINT_TYPE_GRADIENT_RADIAL
    )
}

export function getDefaultTransition() {
    return {
        scaleX: 1,
        skewX: 0,
        translateX: 0,
        skewY: 0,
        scaleY: 1,
        translateY: 0,
    }
}

export function getDefaultSolidPaint(): SolidPaint {
    return {
        visible: true,
        opacity: 0.2,
        blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
        type: PaintType.PAINT_TYPE_SOLID_PAINT,
        color: { r: 0, g: 0, b: 0 },
    }
}

export function getDefaultImagePaint(): ImagePaint {
    return {
        type: PaintType.PAINT_TYPE_IMAGE_PAINT,
        scaleMode: Wukong.DocumentProto.ScaleMode.SCALE_MODE_FILL,
        imageHash: null,
        imageName: null,
        imageTransform: getDefaultTransition(),
        scalingFactor: 0.5,
        rotation: 0,
        visible: true,
        opacity: 1,
        blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
        filters: {
            exposure: 0,
            contrast: 0,
            saturation: 0,
            temperature: 0,
            tint: 0,
            highlights: 0,
            shadows: 0,
        },
    }
}
export function getDefaultGradientPaint(): GradientPaint {
    return {
        type: PaintType.PAINT_TYPE_GRADIENT_LINEAR,
        gradientTransform: {
            scaleX: 0,
            skewX: -1,
            translateX: 1,
            skewY: 1,
            scaleY: 0,
            translateY: 0,
        },
        gradientStops: [
            { position: 0, color: { r: 255, g: 255, b: 255, a: 255 } },
            { position: 1, color: { r: 255, g: 255, b: 255, a: 0 } },
        ],
        visible: true,
        opacity: 1,
        blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
    }
}
