import {
    FeatureSwitchConfig,
    SwitchEnvironmentScopeType,
    SwitchStrategyType,
    UserGroupType,
} from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'prototype-overlay-position',
    owner: '',
    description: '',
    strategies: [
        { env: SwitchEnvironmentScopeType.TESTING, config: { type: SwitchStrategyType.ALL_USER } },
        {
            env: SwitchEnvironmentScopeType.PRODUCTION,
            config: { type: SwitchStrategyType.SPECIFIC_USER_LIST, group: UserGroupType.MOTIFF_GROUP },
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
