import { LayoutGridStyleListInternal, LayoutGridStyleListInternalProps } from './layout-grid-style-list-internal'

export const LayoutGridStyleList = (props: LayoutGridStyleListInternalProps) => {
    const { modelState } = props.model

    if (modelState?.layoutGridStyleItems?.length) {
        return <LayoutGridStyleListInternal {...props} />
    }
    return null
}
