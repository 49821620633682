import type { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useMemo, type CSSProperties, type PropsWithChildren } from 'react'
import type { DeepRequired } from '../../../../../../view-state-bridge'
import { DevModeBoxModelTestId } from '../../../../../../window'
import { CopyableRow } from '../../common/copyable-row'
import classes from '../box-model.module.less'
import { DevModeInspectCopyableValue } from '../common/number-value'
import { formatNumberValue } from '../utils/format-value'

enum RadiusPosition {
    TopLeft,
    TopRight,
    BottomRight,
    BottomLeft,
}

function formatRadius2CssStyleValue(value: number) {
    return Math.min(value, 12)
}

export function BorderRadiusBox(
    props: PropsWithChildren<{ value: DeepRequired<Wukong.DocumentProto.IDevModeInspectBoxModelCommonLayout> }>
) {
    return (
        <div
            className={classes.borderRadiusBoxContainer}
            data-label="Border"
            data-testid={DevModeBoxModelTestId.BorderRadius}
            style={{
                borderTopLeftRadius: formatRadius2CssStyleValue(props.value.topLeftRadius),
                borderTopRightRadius: formatRadius2CssStyleValue(props.value.topRightRadius),
                borderBottomRightRadius: formatRadius2CssStyleValue(props.value.bottomRightRadius),
                borderBottomLeftRadius: formatRadius2CssStyleValue(props.value.bottomLeftRadius),
            }}
        >
            <RadiusMeasure position={RadiusPosition.TopLeft} value={props.value.topLeftRadius} />
            <BorderMeasure value={props.value.topBorder} />
            <RadiusMeasure position={RadiusPosition.TopRight} value={props.value.topRightRadius} />
            <BorderMeasure value={props.value.leftBorder} />
            {props.children}
            <BorderMeasure value={props.value.rightBorder} />
            <RadiusMeasure position={RadiusPosition.BottomLeft} value={props.value.bottomLeftRadius} />
            <BorderMeasure value={props.value.bottomBorder} />
            <RadiusMeasure position={RadiusPosition.BottomRight} value={props.value.bottomRightRadius} />
        </div>
    )
}

function RadiusMeasure(props: { position: RadiusPosition; value: number }) {
    const positionStyleInfo: { className: string; style: CSSProperties } = useMemo(() => {
        switch (props.position) {
            case RadiusPosition.TopLeft:
                return {
                    className: classes.leftTop,
                    style: { borderTopLeftRadius: formatRadius2CssStyleValue(props.value) },
                }
            case RadiusPosition.BottomRight:
                return {
                    className: classes.rightBottom,
                    style: { borderBottomRightRadius: formatRadius2CssStyleValue(props.value) },
                }
            case RadiusPosition.TopRight:
                return {
                    className: classes.rightTop,
                    style: { borderTopRightRadius: formatRadius2CssStyleValue(props.value) },
                }
            case RadiusPosition.BottomLeft:
                return {
                    className: classes.leftBottom,
                    style: { borderBottomLeftRadius: formatRadius2CssStyleValue(props.value) },
                }
        }
    }, [props.value, props.position])

    const formatValue = useMemo(() => formatNumberValue(props.value), [props.value])

    return (
        <div
            className={classNames(classes.radiusMeasureValue, positionStyleInfo.className, {
                [classes.emptyValue]: !formatValue,
            })}
        >
            {!!formatValue && <span className={classes.measureLine} style={positionStyleInfo.style} />}
            <CopyableRow copyValue={`${formatValue}`} className={classNames(classes.measureValue)} paddingMode="normal">
                {formatValue ? formatValue : '-'}
            </CopyableRow>
        </div>
    )
}

function BorderMeasure(props: { value: number }) {
    return (
        <div className={classes.borderMeasureValue}>
            <DevModeInspectCopyableValue
                className={classes.measureValue}
                emptyClassName={classes.emptyValue}
                value={props.value}
                paddingMode="normal"
            />
        </div>
    )
}
