import { command } from 'ccstate'
import { TraceableAbortSignal } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { transaction } from '../../../../../../util/src/abort-controller/traceable-transaction'
import { traceable } from '../../../../../../util/src/ccstate-helper/traceable'
import { WkCLog } from '../../../../kernel/clog/wukong/instance'
import { MetricCollector, MetricName } from '../../../../kernel/metric-collector'
import { AddDocOpenRecordRequest } from '../../../../kernel/request/document'
import { Sentry } from '../../../../kernel/sentry'
import { refreshWhenExpiredLogin } from '../../../../kernel/util/expire-login'
import { createFileManager, isCreatingFile$ } from '../../../../main/create-file-manager'
import { resetWasm$ } from '../../../../main/lifecycle/setup-editor/editor'
import { redirectWhenWebGLNoSupport, toastWhenCloseWebgl } from '../../../../share/webgl-acceleration'
import { setHtmlTitleWithBrandSuffix } from '../../../../utils/html'
import { payment$ } from '../../../atoms/payment'
import { ticket$ } from '../../../atoms/ticket'
import { featureGuideService } from '../../../../main/service/feature-guide/feature-guide-service'

export const afterDocAuth$ = traceable(
    'hulh01@kanyun.com',
    command(async ({ get, set }, signal: TraceableAbortSignal) => {
        const { act } = transaction(signal)

        const ticket = (await get(ticket$))!

        await get(payment$)

        signal.throwIfAborted()

        act('metric start load doc', () => {
            MetricCollector.pushMetric(MetricName.START_LOAD_DOC, performance.now())
        })

        // wasm 的预加载在用户登录后进行，但是重置需要在退出编辑器时进行。因为暂时没有进入编辑器时重置的能力
        // 所用 signal 依然和 session 挂钩
        act('setup wasm when exit editor', () => {
            return () => {
                set(resetWasm$)
            }
        })

        act('redirect when webgl not supported', () => {
            redirectWhenWebGLNoSupport()
        })

        act('inject docId', () => {
            Sentry.setTag('documentId', ticket.docId)
            WkCLog.setDocumentId(ticket.docId)

            return () => {
                Sentry.setTag('documentId', undefined)
                WkCLog.setDocumentId(null)
            }
        })

        act('init featureGuide', () => {
            featureGuideService.setInfos({
                orgId: ticket.orgId,
            })
        })

        act('set doc name', () => {
            setHtmlTitleWithBrandSuffix(ticket.documentName)
        })

        act('record user visit', () => {
            // 新建文档需要延后，因为 docId 不一定生成成功
            if (get(isCreatingFile$)) {
                createFileManager.injectCreateFileCallBack(() => {
                    new AddDocOpenRecordRequest(ticket.docId).startWithSignal(signal).catch(() => {
                        WkCLog.log(`new file add recents failed, docId: ${ticket.docId}`)
                    })
                })
            } else {
                new AddDocOpenRecordRequest(ticket.docId).startWithSignal(signal).catch(() => {
                    WkCLog.log(`open file add recents failed, docId: ${ticket.docId}`)
                })
            }
        })

        act('refresh when expired login', () => {
            refreshWhenExpiredLogin(true)
        })

        act('toast when close webgl', () => {
            toastWhenCloseWebgl()
        })
    })
)
