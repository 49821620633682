import { useCallback, useRef, useState } from 'react'

function isEllipsisStyle(element: HTMLElement) {
    const style = window.getComputedStyle(element)
    const notFlexGrid = !style.display.includes('flex') && !style.display.includes('grid')
    const isEllipsis = style.overflow === 'hidden' && style.textOverflow === 'ellipsis' && style.whiteSpace === 'nowrap'
    return notFlexGrid && isEllipsis
}

function isEllipsisWithIn(element: HTMLElement) {
    return element.scrollWidth > element.clientWidth
}

function isEllipsisWithOut(element: HTMLElement, width: number) {
    return width > element.getBoundingClientRect().width
}

function findStyleEllipsis(container: HTMLElement, child: HTMLElement) {
    if (!container.contains(child)) {
        return false
    }
    if (isEllipsisStyle(child)) {
        return isEllipsisWithIn(child)
    }
    const childWidth = child.getBoundingClientRect().width
    let parent = child.parentElement
    do {
        if (!parent) {
            return false
        }
        if (isEllipsisStyle(parent)) {
            return isEllipsisWithOut(parent, childWidth)
        }
        parent = parent.parentElement
    } while (!container.contains(parent))
    return false
}

export function useEllipsisTooltip<T extends HTMLElement>() {
    const [inactivation, setInactivation] = useState(true)
    const ellipsisRef = useRef<T>(null)
    const onmouseenter = (e: React.MouseEvent) => {
        if (!ellipsisRef.current) {
            return
        }
        const child = ellipsisRef.current
        const container = e.currentTarget as HTMLElement
        const isEllipsis = findStyleEllipsis(container, child)
        setInactivation(isEllipsis ? false : true)
    }
    // 如果文本内容会动态更新，需要挂这个。以防下次移入时因文本内容变更导致inactivation的状态不对
    const onmouseleave = () => {
        setInactivation(true)
    }
    return { inactivation, ellipsisRef, onmouseenter, onmouseleave }
}

export function useMultiEllipsisTooltip<T extends HTMLElement>() {
    const [inactivation, setInactivation] = useState(true)
    const ellipsisHtmlRef = useRef<T>()
    const onmouseenter = (e: MouseEvent) => {
        const element = e.currentTarget as HTMLElement
        const style = window.getComputedStyle(element)
        const isEllipsis =
            element.scrollHeight > element.clientHeight &&
            style.overflow === 'hidden' &&
            (style.display === '-webkit-box' || style.display === 'flow-root') &&
            style.webkitBoxOrient === 'vertical' &&
            !isNaN(Number(style.webkitLineClamp))
        setInactivation(isEllipsis ? false : true)
    }
    const onmouseleave = () => {
        setInactivation(true)
    }

    const ellipsisRef = useCallback((element: T | null) => {
        ellipsisHtmlRef.current = element ? element : undefined
        element?.addEventListener('mouseenter', onmouseenter)
        element?.addEventListener('mouseleave', onmouseleave)
    }, [])

    return { inactivation, ellipsisRef, ellipsisHtmlRef }
}
