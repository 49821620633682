import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    DialogDescriptionPrefix: '在团队中新建文件',

    DialogOkTextOfAdminOrAutoGrade: '确定',
    DialogCancelTextOfAdminOrAutoGrade: '取消',
    DialogTitleOfAdminOrAutoGrade: '确定要在团队中新建文件？',
} as const

export const enTranslation = {
    DialogDescriptionPrefix: 'To create files in a team',

    DialogOkTextOfAdminOrAutoGrade: 'Confirm',
    DialogCancelTextOfAdminOrAutoGrade: 'Cancel',
    DialogTitleOfAdminOrAutoGrade: 'Are you sure you want to create files in a team?',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
