import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Untitled: '未命名',
    FailedTo: '导出失败，超过最大导出尺寸（16000 × 16000 像素）',
} as const

export const enTranslation = {
    Untitled: 'Untitled',
    FailedTo: 'Exceeds maximum export size limit (16000 x 16000 px)',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
