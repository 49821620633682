import { FC, PropsWithChildren, useLayoutEffect } from 'react'
import { useGet, useResolved } from 'ccstate-react'
import { docAuthStatus$ } from '../../../../atoms/doc-auth-status'
import { synergyAuthed$ } from '../../../../atoms/synergy-auth'
import { computed, state } from 'ccstate'
import { EditorDataTestId } from '../../../../../window/wk-data-test'
import { WKLogo } from '../../../../../../../ui-lib/src/components/wk-logo/logo'
import { Progress } from '../../../../../main/loading-container/progress'
import { TopAreaSkeleton } from './top'
import { LeftAreaSkeleton } from './left'
import { RightAreaSkeleton } from './right'
import { traceable } from '../../../../../../../util/src/ccstate-helper/traceable'

export const editorLoading$ = traceable('hulh01@kanyuncom', state(true))

export const EditorSkeletonWrapper: FC<PropsWithChildren> = ({ children }) => {
    return (
        <>
            {children}
            <EditorSkeleton />
        </>
    )
}

const testId$ = computed((get) => {
    const editorLoading = get(editorLoading$)
    const synergyAuthed = get(synergyAuthed$)

    return editorLoading || !synergyAuthed
        ? EditorDataTestId.DocumentContentLoading
        : EditorDataTestId.DocumentContentReady
})

const EditorSkeleton: FC = () => {
    const testId = useGet(testId$)
    const editorLoading = useGet(editorLoading$)
    const docAuthStatus = useResolved(docAuthStatus$)
    const authSuccessed = docAuthStatus?.status === 'auth-successed'

    // 去掉避免闪动增加的 body background
    useLayoutEffect(() => {
        document.getElementById('editor-root-skeleton')?.remove()
    }, [])

    return (
        <div
            data-testid={testId}
            className="bg-[#EDEEF0] flex items-center justify-center pointer-events-none absolute top-0 left-0 right-0 bottom-0"
            style={{
                opacity: editorLoading && docAuthStatus?.status !== 'open-in-desktop' ? 1 : 0,
                transition: '0.5s',
            }}
        >
            <div className="flex flex-col items-center justify-center">
                <WKLogo.WithText height={24} type="normal" className="mb-28px" />
                {authSuccessed ? <Progress /> : <div className="h-3px w-180px" />}
            </div>

            {authSuccessed && (
                <>
                    <TopAreaSkeleton />
                    <LeftAreaSkeleton />
                    <RightAreaSkeleton />
                </>
            )}
        </div>
    )
}
