import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    PublishTo: '发布给',
    PublishToV2: '发布给',
    PrivateTeamPublishTooltip:
        '选择发布给企业中的所有人后，企业成员将在引用组件库中看到当前团队的名称。如果你不希望这样，可以将当前文件移动到其他允许被知晓的团队中再发布。',
    Team: '团队',
    Org: '企业',
    OrgV2: '企业',
    Workspace: '工作区',
    PublishEveryoneInSpecificOrganizationTooltip: '{{specificOrg}}「{{specificOrgName}}」中的所有人',
} as const

export const enTranslation = {
    PublishTo: 'Publish to',
    PublishToV2: 'Publish to\u00A0',
    PrivateTeamPublishTooltip:
        'If you publish to everyone at the organization, the name of your secret team could be visible to all org members. To prevent this, you can move the file to an open or closed team and publish it from there instead.',
    Team: 'team',
    Org: 'enterprise',
    OrgV2: 'organization',
    Workspace: 'workspace',
    PublishEveryoneInSpecificOrganizationTooltip: 'everyone in {{specificOrg}} "{{specificOrgName}}"',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
