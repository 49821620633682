import { DocGetVO, UserBriefVO } from './type'

export enum AiSearchResourceType {
    ORGANIZATION = 'organization',
    FOLDER = 'folder',
    TEAM = 'team',
}

export enum AISearchFileOwnerCondition {
    ANY_USER = 'ANY_USER',
    MYSELF = 'MYSELF',
}

export enum AISearchSortingRule {
    SIMILARITY = 'SIMILARITY',
    EDIT_TIME = 'EDIT_TIME',
}

export interface AISearchResponse {
    result: AISearchDocumentResult[]
    documentOwnerInfo: UserBriefVO[]
}

export interface AISearchDocumentResult {
    documentVO: DocGetVO & { ownerUserId: number }
    candidates: AISearchCandidate[]
}

export interface AISearchCandidate {
    nodeId: string
    nodeThumbnailUrl: string
    nodeDataUrl: string
    nodeName: string
    nodeType: number
}

export interface AISearchRequest {
    imageFormat: string
    condition: AISearchCondition
    sortingRule: AISearchSortingRule
}

export interface AISearchCondition {
    fileOwnerCondition: AISearchFileOwnerCondition
    resourceType: AiSearchResourceType
    resourceId: string
}
