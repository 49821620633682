import { Select } from '../../../../../../../ui-lib/src'
import { isEnglishLanguage } from '../../../../../../../util/src'
import { useMemo } from 'react'
import {
    InteractionActionType2Label,
    MIXED_TYPE,
    MIXED_TYPE_LABEL,
    PrototypeInteractionSelectedTargetNode,
    PrototypeInteractionTargetNode,
} from '../../prototype-interaction/constants'

export const InteractionActionTargetSelect = ({
    targetNodes,
    selectedTargetNode,
    onChange,
    onMouseEnterTarget,
    onMouseLeaveTarget,
}: {
    targetNodes: PrototypeInteractionTargetNode[]
    selectedTargetNode: PrototypeInteractionSelectedTargetNode
    onChange: (v: string) => void
    onMouseEnterTarget?: (v: string) => void
    onMouseLeaveTarget?: () => void
}) => {
    const sortedOuterNodes = useMemo(() => {
        const ret: PrototypeInteractionTargetNode[][] = []
        if (targetNodes.length) {
            const enabledNodes = targetNodes
                .filter((v) => !v.disabled)
                .sort((a, b) => a.name.localeCompare(b.name, isEnglishLanguage() ? 'en' : 'zh'))
            const disabledNodes = targetNodes.filter((v) => v.disabled)
            ret.push(enabledNodes, disabledNodes)
        }
        if (!selectedTargetNode.isMixed && !selectedTargetNode.isEmpty) {
            const node = selectedTargetNode.selectedNodes[0]
            if (!ret.length) {
                ret.push([node])
            } else if (ret[0].every((v) => v.id !== node.id)) {
                ret[0].push(node)
                if (ret[1].some((v) => v.id === node.id)) {
                    ret[1] = ret[1].filter((v) => v.id !== node.id)
                }
            }
        }
        return ret
    }, [targetNodes, selectedTargetNode])
    const menus = useMemo(() => {
        const ret: any[] = []
        if (selectedTargetNode.isMixed) {
            ret.push({
                value: MIXED_TYPE,
                label: MIXED_TYPE_LABEL,
                disabled: true,
                splitLineBottom: sortedOuterNodes.length ? true : false,
            })
        }
        sortedOuterNodes.forEach((nodes, index) => {
            nodes.forEach((node, idx) => {
                ret.push({
                    value: node.id,
                    label: node.name,
                    splitLineTop: index === 1 && idx === 0 && sortedOuterNodes[0].length > 0,
                    disabled: node.disabled,
                })
            })
        })
        return ret
    }, [sortedOuterNodes, selectedTargetNode])
    const selectedValue = selectedTargetNode.isMixed ? MIXED_TYPE : selectedTargetNode.selectedNodes[0]?.id
    const selectedLabel = useMemo(() => {
        return selectedValue ? menus.find((v) => v.value === selectedValue)?.label : InteractionActionType2Label.None
    }, [selectedValue, menus])

    return (
        <Select.MinimalSingleLevel
            className="pl-10 pr-4 box-border w-full"
            value={selectedValue}
            label={<div className="py-2 truncate">{selectedLabel}</div>}
            onChange={onChange}
            dataTestIds={{
                triggerFocus: 'interaction-target-select-trigger',
                container: 'interaction-target-select-container',
            }}
            onClose={onMouseLeaveTarget}
            maxWidth={280}
        >
            {menus.map((item, index) => {
                return (
                    <Select.MinimalSingleLevel.Option
                        key={index}
                        value={item.value}
                        disabled={item.disabled}
                        splitLineTop={item.splitLineTop}
                        splitLineBottom={item.splitLineBottom}
                        dataTestId="interaction-target-select-option"
                        onMouseEnter={() => onMouseEnterTarget?.(item.value)}
                        onMouseLeave={onMouseLeaveTarget}
                    >
                        {item.label}
                    </Select.MinimalSingleLevel.Option>
                )
            })}
        </Select.MinimalSingleLevel>
    )
}
