/* eslint-disable no-restricted-imports */
import { POST, ResponseType } from '@tutor/network-core'
import { DocID } from '../../kernel/interface/type'
import { BaseCommonRequest } from '../../kernel/request/base-request'

/**
 * 上报协同长连接建立时的上下文信息
 */
@POST
export class ReportConnectionContext extends BaseCommonRequest<void> {
    constructor(
        private readonly docId: DocID,
        private readonly sid: number,
        private readonly clientId: number,
        private readonly release: string,
        private readonly orgId: string,
        private readonly featureSwitches: string
    ) {
        super()
    }

    override requestUrl(): string {
        return `contexts/connection`
    }

    override responseType(): ResponseType {
        return ResponseType.JSON
    }

    public override requestBody() {
        return {
            docId: this.docId,
            sid: this.sid,
            clientId: this.clientId,
            release: this.release,
            orgId: this.orgId,
            featureSwitches: this.featureSwitches,
        }
    }
}
