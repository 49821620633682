/* eslint-disable no-restricted-imports */
import { useCallback, useState } from 'react'
import { NodeId } from '../../../../../../document/node/node'
import { StyleWithoutDocIdGetVO } from '../../../../../../kernel/interface/component-style'
import { useLibraryComponentService } from '../../../../../../main/app-context'
import {
    buildThumbnailDataFromVO,
    LibraryThumbnailImage,
} from '../../../../../../share/component-style-library/library-thumbnail-image'
import { LibraryResourceOssClientType } from '../../../../../../share/component-style-library/service/library-resource-downloader'
import { getStyleShortName } from '../../../style/style-util'
import { CommonStyleInfo } from '../../get-style-nodes-info-map'
import { StyleListItem, StyleListItemKeyboardProps } from '../../style-items-layout/style-list-item'
import style from '../style-color-list-item.module.less'

export interface RemoteStyleProps {
    item: StyleWithoutDocIdGetVO
    docId: string
    selectStyleId: string | undefined
    selectStyleKey: string | undefined
    openStyleId?: string
    className?: string
    imageClassName?: string
    disabledOnClick?: boolean
    children?: React.ReactNode
    dataTestId?: string
    onSelectItem: (item: CommonStyleInfo) => void
    onClickHoverIcon?: (
        item: CommonStyleInfo,
        containerDomRect: DOMRect,
        remoteStyleId?: string,
        docId?: string
    ) => void
    onContextMenu?: (item: CommonStyleInfo, e: any, remoteStyleId?: string, docId?: string) => void
    isRightClickStyle?: boolean
}

export function RemoteStyleColorListItem(props: RemoteStyleProps & StyleListItemKeyboardProps) {
    const {
        item,
        selectStyleId,
        selectStyleKey = '',
        openStyleId,
        docId,
        onSelectItem,
        onClickHoverIcon,
        onContextMenu,
        dataTestId,
        isRightClickStyle,
    } = props
    const { name, description } = props.item
    const libraryComponentService = useLibraryComponentService()

    const [styleNodeId, setStyleNodeId] = useState<NodeId | null>(null)
    const getStyleNodeId = useCallback(async () => {
        if (styleNodeId) {
            return styleNodeId
        }

        const newStyleNodeId = await libraryComponentService.libraryNodeDataService.createRemoteStyleNode({
            isLocal: false,
            localNodeId: null,
            ossClientType: LibraryResourceOssClientType.Style,
            remoteDocId: docId,
            remoteNodeId: item.nodeId,
            nodeDataPath: item.nodeDataPath ?? '',
            key: item.id,
        })

        if (newStyleNodeId) {
            setStyleNodeId(newStyleNodeId)
        }
        return newStyleNodeId
    }, [styleNodeId, libraryComponentService, docId, item])

    return (
        <StyleListItem
            item={{
                id: selectStyleId && item.id === selectStyleKey ? selectStyleId : styleNodeId || '',
                name: name || '',
                description: description || '',
                groupName: '',
                styleName: '',
            }}
            isReadonly
            dataTestId={dataTestId}
            openStyleId={openStyleId}
            onSelectItem={async (value) => {
                const styleId = await getStyleNodeId()
                if (styleId) {
                    value.id = styleId
                    onSelectItem(value)
                }
            }}
            onClickHoverIcon={
                onClickHoverIcon &&
                (async (itemValue, containerDomRect) => {
                    const styleId = await getStyleNodeId()
                    if (styleId) {
                        itemValue.id = styleId
                        setTimeout(() => onClickHoverIcon(itemValue, containerDomRect, item.nodeId, docId))
                    }
                })
            }
            onContextMenu={(itemValue, e) => {
                onContextMenu?.(itemValue, e, item.nodeId, docId)
            }}
            isRightClickStyle={isRightClickStyle}
            index={props.index}
            selectStyleId={props.selectStyleId}
            setPreselectIndex={props.setPreselectIndex}
            trySetPreselectIndex={props.trySetPreselectIndex}
            recordEnterCallback={props.recordEnterCallback}
        >
            <LibraryThumbnailImage className={style.thumbnail} thumbnailData={buildThumbnailDataFromVO(item)} />
            <div className={style.name}>{getStyleShortName(name || '')}</div>
        </StyleListItem>
    )
}
