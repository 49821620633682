import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    MissingLibraries: '缺失组件库',
    Missing: '缺失',
} as const

export const enTranslation = {
    MissingLibraries: 'Missing libraries',
    Missing: 'Missing',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
