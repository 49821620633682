import {
    ApplyComponentPropRefForSelectedCommand,
    CreateAndApplyComponentPropForSelectedCommand,
    CreateComponentPropDefineForSelectedCommand,
    CreateVariantPropWasmCall,
    DeleteComponentPropDefinitionForSelectedCommand,
    DetachComponentPropRefCommand,
    EditComponentPropWasmCall,
    HighlightNodesByIdsWasmCall,
    HoverNodesBySpecificVariantValueWasmCall,
    NavigateToComponentPropOnComponentWasmcall,
    NavigateToComponentPropOnInstanceWasmcall,
    ReorderComponentPropDefs,
    SelectNodesBySpecificVariantValueWasmCall,
    ToggleComponentPickerWasmCall,
    ToggleComponentPropPopupWasmCall,
    UpdateComponentPropSelectionStateWasmCall,
    UpdateNodeValAboutCompPropInInstanceWasmCall,
    UpdateVariantPropForPublicInstance,
    ValidateInstanceSwapValue,
    Wukong,
} from '@wukong/bridge-proto'
import { CommitType } from '../../../../../../document/command/commit-type'
import { PopupStateType } from '../../../../../../document/node/node'
import { useComponentPropService } from '../../../../../../main/app-context'
import { useViewState } from '../../../../../../view-state-bridge'
import { useCommand } from '../../../../../context/document-context'
import {
    Arg_cmdEditComponentPropData,
    Arg_cmdReorderComponentPropDefs,
    Arg_validateInstanceSwapValue,
    ComponentPropNodeField,
    ComponentPropPreferredValues,
    ComponentPropType,
    convertVirtualComponentPropTypeToField,
    MultiHoverBorderColorType,
    VariableAnyValue,
    VariantProp,
    VComponentPropDef,
    VInstanceCompProp,
    type ComponentPropEditPopupVal,
} from '../../types'

export interface ICreateComponentProp {
    name: string
    type: ComponentPropType
    value: VariableAnyValue
    preferredValues?: ComponentPropPreferredValues
}

export const useComponentProp = () => {
    const command = useCommand()
    const { type: popupType } = useViewState('popupState')!
    const componentPropViewState = useViewState('componentPropViewState')!

    const publicPropViewState = useViewState('publicPropInstanceState')

    const componentPropService = useComponentPropService()
    const instancePanelLocateDefId = componentPropService.states.use.instancePanelLocateDefId()

    // 组件属性区域弹窗显示状态
    const defsPanelPopupVisible = [
        PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_VARIANT_PROP_OF_PROP_AREA,
        PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_VARIANT_PROP,
        PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_BOOL_PROP_OF_PROP_AREA,
        PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_BOOL_PROP,
        PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_TEXT_PROP_OF_PROP_AREA,
        PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_TEXT_PROP,
        PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_INSTANCE_SWAP_PROP_OF_PROP_AREA,
        PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_INSTANCE_SWAP_PROP,
        PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_PUBLIC_PROP_OF_PROP_AREA,
    ].includes(popupType)

    // 切换组件属性弹窗状态
    const toggleComponentPropPopup = (popupStateType: PopupStateType, editPopupVal?: ComponentPropEditPopupVal) => {
        command.invokeBridge(CommitType.Noop, ToggleComponentPropPopupWasmCall, {
            popupStateType,
            editPopupVal,
        })
    }
    // 关闭组件属性弹窗
    const closeComponentPropPopup = () => {
        command.invokeBridge(CommitType.Noop, ToggleComponentPropPopupWasmCall, {
            popupStateType: PopupStateType.POPUP_STATE_TYPE_NONE,
        })
    }
    // 创建组件属性
    const createProp = (propVal: ICreateComponentProp) => {
        command.invokeBridge(CommitType.CommitUndo, CreateComponentPropDefineForSelectedCommand, propVal)
    }
    // 创建并应用组件属性
    const createAndApplyProp = (propVal: ICreateComponentProp) => {
        command.invokeBridge(CommitType.CommitUndo, CreateAndApplyComponentPropForSelectedCommand, propVal)
    }
    // 删除组件属性
    const removeProp = (def: VComponentPropDef) => {
        command.invokeBridge(CommitType.CommitUndo, DeleteComponentPropDefinitionForSelectedCommand, {
            propId: def.id,
            type: convertVirtualComponentPropTypeToField(def.type),
        })
    }
    // 删除多个组件属性
    const batchRemoveProps = (defs: VComponentPropDef[]) => {
        defs.forEach((def) => {
            command.invokeBridge(CommitType.Noop, DeleteComponentPropDefinitionForSelectedCommand, {
                propId: def.id,
                type: convertVirtualComponentPropTypeToField(def.type),
            })
        })
        command.commitUndo()
    }
    // 修改组件属性
    const updateProp = (propVal: Arg_cmdEditComponentPropData) => {
        command.invokeBridge(CommitType.CommitUndo, EditComponentPropWasmCall, propVal)
    }
    // 分离组件属性
    const detachProp = (detachDatas: Wukong.DocumentProto.IDetachComponentPropDefData[]) => {
        command.invokeBridge(CommitType.CommitUndo, DetachComponentPropRefCommand, {
            detachDatas,
        })
    }
    // 应用组件属性
    const applyProp = (type: ComponentPropNodeField, propId: string) => {
        command.invokeBridge(CommitType.CommitUndo, ApplyComponentPropRefForSelectedCommand, {
            type,
            propId,
        })
    }
    // 定位到组件/组件集上的组件属性
    const locateComponentProp = (defId: string) => {
        return command.invokeBridge(CommitType.Noop, NavigateToComponentPropOnComponentWasmcall, {
            defId,
        })
    }
    // 定位到组件属性所属的实例上
    const locateInstanceOfRef = (defId: string) => {
        componentPropService.setInstancePanelLocateDefId(defId)
        return command.invokeBridge(CommitType.Noop, NavigateToComponentPropOnInstanceWasmcall, {
            defId,
        })
    }
    // 切换实例与组件属性相关的 nodeProps 值
    const updateAssignment = (
        value: Wukong.DocumentProto.IArg_updateNodeValAboutCompPropInInstance,
        callback?: (success: boolean) => void
    ) => {
        const ret = command.invokeBridge(CommitType.CommitUndo, UpdateNodeValAboutCompPropInInstanceWasmCall, value)
        callback?.(!!ret.value)
    }

    const createVariantPropWasmCall = () => {
        command.invokeBridge(CommitType.CommitUndo, CreateVariantPropWasmCall)
    }

    // 选中指定变体属性数值的所有节点
    const selectNodesBySpecificVariantValue = (propName: string, propValue: string) => {
        command.invokeBridge(CommitType.Noop, SelectNodesBySpecificVariantValueWasmCall, {
            propName,
            propValue,
        })
    }

    // 悬浮指定变体属性数值的所有节点
    const hoverNodesBySpecificVariantValue = (propName: string, propValue: string) => {
        command.invokeBridge(CommitType.Noop, HoverNodesBySpecificVariantValueWasmCall, {
            propName,
            propValue,
        })
    }

    // 切换实例替换面板状态
    const toggleComponentPicker = (popupStateType: PopupStateType, instanceSwapAssignment?: VInstanceCompProp) => {
        if (
            [
                PopupStateType.POPUP_STATE_TYPE_NONE,
                PopupStateType.POPUP_STATE_TYPE_INSTANCE_SWAP_ASSIGNMENT_COMPONENT_PICKER,
            ].includes(popupType)
        ) {
            command.invokeBridge(CommitType.Noop, ToggleComponentPickerWasmCall, {
                popupStateType,
                instanceSwapAssignment,
            })
        }
    }

    // 检测实例替换属性值是否合法
    const validateInstanceSwapValue = (value: Arg_validateInstanceSwapValue) => {
        return command.invokeBridge(CommitType.Noop, ValidateInstanceSwapValue, value).value
    }

    // 重排组件属性
    const reorderDefs = (value: Arg_cmdReorderComponentPropDefs) => {
        return command.invokeBridge(CommitType.CommitUndo, ReorderComponentPropDefs, value)
    }

    // 多个图层同时悬浮高亮
    const highlightNodesByIds = (
        nodeIds: string[],
        color = MultiHoverBorderColorType.MULTI_HOVER_BORDER_COLOR_TYPE_BLUE
    ) => {
        command.invokeBridge(CommitType.Noop, HighlightNodesByIdsWasmCall, {
            nodeIds,
            color,
        })
    }

    // 选中组件属性
    const selectComponentProp = (value: boolean) => {
        command.invokeBridge(CommitType.Noop, UpdateComponentPropSelectionStateWasmCall, {
            value,
        })
    }

    // 更新公开属性的变体属性
    const updatePublicVariantProps = (instanceId: string, props: VariantProp[]) => {
        command.invokeBridge(CommitType.CommitUndo, UpdateVariantPropForPublicInstance, {
            instanceId,
            props,
        })
    }

    const resetInstancePanelLocateDefId = () => {
        componentPropService.setInstancePanelLocateDefId(null)
    }

    return {
        ...componentPropViewState,
        publicPropChains: publicPropViewState?.chains ?? [],
        popupType,
        defsPanelPopupVisible,
        instancePanelLocateDefId,
        toggleComponentPropPopup,
        closeComponentPropPopup,
        toggleComponentPicker,
        createProp,
        createAndApplyProp,
        removeProp,
        batchRemoveProps,
        updateProp,
        detachProp,
        applyProp,
        locateComponentProp,
        locateInstanceOfRef,
        updateAssignment,
        createVariantPropWasmCall,
        selectNodesBySpecificVariantValue,
        hoverNodesBySpecificVariantValue,
        validateInstanceSwapValue,
        reorderDefs,
        highlightNodesByIds,
        selectComponentProp,
        updatePublicVariantProps,
        resetInstancePanelLocateDefId,
    }
}
