import { IconClose16, WKIconButton, _WKDialog } from '../../../../../../ui-lib/src'
import classes from './pay-apply-dialog-container.module.less'

type _WKDialogProps = Parameters<typeof _WKDialog>[0]

export interface PayApplyDialogContainerProps
    extends Omit<_WKDialogProps, 'width' | 'showTitle' | 'hideFooterLine' | 'bodyStyle' | 'children'> {}

export function PayApplyDialogContainer1(
    props: React.PropsWithChildren<PayApplyDialogContainerProps & { dataTestIds?: { description: string } }>
) {
    const { title, dataTestIds, ...otherProps } = props
    return (
        <_WKDialog
            width={400}
            showTitle={false}
            hideFooterLine
            bodyStyle={{ padding: 0 }}
            testId="pay-apply-dialog-container"
            escClosable
            {...otherProps}
        >
            <div className={classes.content}>
                <div className={classes.title}>{title}</div>
                <WKIconButton
                    data-testid="close-icon"
                    className={classes.close}
                    onClick={props.onCancel}
                    icon={<IconClose16 />}
                ></WKIconButton>
                <div data-testid={dataTestIds?.description} className={classes.description}>
                    {props.children}
                </div>
            </div>
        </_WKDialog>
    )
}

export function PayApplyDialogContainer2(props: React.PropsWithChildren<PayApplyDialogContainerProps>) {
    return (
        <_WKDialog
            {...props}
            width={536}
            bodyStyle={{ padding: '16px 24px 24px' }}
            testId="pay-apply-dialog-container"
            escClosable
        >
            {props.children}
        </_WKDialog>
    )
}
