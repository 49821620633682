import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    NameChanged: '姓名已修改',
    ChangeName: '修改姓名',
    Name: '姓名',
    lowerCaseName: '姓名',
} as const

export const enTranslation = {
    NameChanged: 'Name changed',
    ChangeName: 'Change name',
    Name: 'Name',
    lowerCaseName: 'name',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
