import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    ManiFestError: '无法读取 manifest 文件',
    ReadPluginFailed: '无法读取插件，请检查后再试',
    ShowConsole: '打开控制台',
    PublishNewVersion: '发布新版本',
    Publish: '发布',
    Icon: '图标',
    Name: '名称',
    ManifestIdInvalidPleaseCopy: 'Manifest 文件中的 ID 无效，请复制此 ID 到 manifest 文件中',
    CopyId: '复制 ID',
    PublishNewVersionFailed: '插件发布失败',
    CopyDone: '已复制到剪贴板',
    ViewDetails: '查看详情',
} as const

export const enTranslation = {
    ManiFestError: 'Manifest error',
    ReadPluginFailed: 'An error occurred while loading code. Check plugin files and try again. ',
    ShowConsole: 'Show console',
    PublishNewVersion: 'Publish new version',
    Publish: 'Publish',
    Icon: 'Icon',
    Name: 'Name',
    ManifestIdInvalidPleaseCopy: 'Invalid ID in manifest.json. Copy this ID to your manifest.json file.',
    CopyId: 'Copy',
    PublishNewVersionFailed: 'An error occurred while publishing plugin.',
    CopyDone: 'Copied to clipboard',
    ViewDetails: 'View details',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
