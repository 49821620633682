/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { isObjectLike } from 'lodash-es'
import { useMemo, useRef } from 'react'
import { DraggablePopupV2, Position } from '../../../../../ui-lib/src'
import { useAppContext, usePluginService } from '../../../main/app-context'
import { useTopMargin } from '../../../main/layout/layout-context'
import { useUserConfigStateV2 } from '../../../main/user-config/user-config-hook'
import style from './plugin-modal.module.less'
import { PluginToast } from './plugin-toast'
import { PluginExported, PluginsPosition } from './type'

const isPluginsPosition = (x: unknown): x is PluginsPosition => {
    return isObjectLike(x)
}

function Title(plugin: PluginExported) {
    if (plugin.manifest.icon != undefined) {
        return (
            <div className={style['popup-title-bar']}>
                <span className={style['popup-title-bar-icon']}>{plugin.manifest.icon}</span>
                <span className={style['popup-title-bar-text']}>{plugin.manifest.name}</span>
            </div>
        )
    }
    return <>{plugin.manifest.name}</>
}

export function PluginPopupModal() {
    const pluginService = usePluginService()
    const [pluginPositionV2, setPluginPositionV2] = useUserConfigStateV2('pluginsPosition')
    const toolbarHeight = useTopMargin()
    const currentModalState = useAppContext().pluginService.states.use.currentModalState()
    const plugin = useMemo(() => {
        return (
            pluginService.officialPlugins.find(
                (officalPlugin) => officalPlugin.manifest.key === currentModalState?.key
            ) ?? null
        )
    }, [currentModalState?.key, pluginService.officialPlugins])
    const toolbarHeightRef = useRef(toolbarHeight)
    toolbarHeightRef.current = toolbarHeight
    const pluginPositionV2Ref = useRef(pluginPositionV2)
    pluginPositionV2Ref.current = pluginPositionV2

    const initPosition: Position = useMemo(() => {
        const currentToolbarHeight = toolbarHeightRef.current

        if (!plugin) {
            return { left: 0, top: 0 }
        }
        // 默认水平居中，垂直距离工具栏 15%
        const defaultPosition = () => {
            return {
                left: Math.round((document.body.clientWidth - plugin.manifest.width) * 0.5),
                top: Math.round(currentToolbarHeight + (document.body.clientHeight - currentToolbarHeight) * 0.15),
            }
        }

        try {
            const pluginPostions = pluginPositionV2Ref.current
            if (isPluginsPosition(pluginPostions)) {
                const position = pluginPostions[plugin.manifest.key]
                if (position) {
                    return position
                }
            }
        } catch (_e) {}
        return defaultPosition()
    }, [plugin])

    const handleMove = (pos: Position) => {
        if (!plugin) {
            return
        }
        try {
            const saved = pluginPositionV2Ref.current
            if (isPluginsPosition(saved)) {
                setPluginPositionV2({
                    ...saved,
                    [plugin.manifest.key]: pos,
                })
            }
        } catch (e) {
            console.error(e)
        }
    }

    if (!plugin) {
        return null
    }

    const Comp = plugin.Component

    return (
        <DraggablePopupV2
            visible
            position={initPosition}
            width={plugin.manifest.width}
            header={<Title {...plugin} />}
            bodyClassName="p-0"
            footer={null}
            enableScrollBar={
                ![
                    Wukong.DocumentProto.PluginType.PLUGIN_TYPE_LOOPER,
                    Wukong.DocumentProto.PluginType.PLUGIN_TYPE_ICONPARK,
                ].includes(plugin.manifest.key)
            }
            styleType="editor"
            onCancel={pluginService.closeModal}
            onMove={handleMove}
            closeTestId="plugin-modal-close"
        >
            <Comp closeModal={pluginService.closeModal} />
            <PluginToast />
        </DraggablePopupV2>
    )
}
