import { translation } from './candidate-component-panel.translation'
/* eslint-disable no-restricted-imports */
import { useCallback, useState } from 'react'
import { MonoIconPanelTransform16, Tooltip, WKButton } from '../../../../../../../ui-lib/src'
import { WKFrog } from '../../../../../kernel/frog'
import { CandidateComponentTestIds } from '../../../../../window'
import { CandidateComponentReplaceDialog } from '../../../ai/candidate-component/candidate-component-replace-dialog'
import { IconButton } from '../../../atom/button/icon-button'
import { SingleGrid } from '../../../atom/grid/single-grid'
import { Title } from '../../../atom/title/title'
import { useComponentInstanceCommand } from '../commands'
import { CandidateComponentState } from '../types'
import style from './candidate-component-panel.module.less'

export { CandidateComponentTestIds }

export function CandidateComponentPanel(props: CandidateComponentState) {
    const commands = useComponentInstanceCommand()

    const [isOpen, setIsOpen] = useState(false)
    const handleReplaceBtnClick = useCallback(() => {
        WKFrog.addFrogRecord({
            url: '/click/AIReplacesComponent/ClickReplace',
            eventId: 26844,
            eventAction: 'click',
            eventName: 'ClickReplace',
        })
        setIsOpen(true)
    }, [setIsOpen])

    const onClose = useCallback(() => {
        setIsOpen(false)
    }, [setIsOpen])

    if (!props.shouldDisplay) {
        return <></>
    }

    return (
        <div className={style.componentPanel}>
            <Tooltip
                title={props.isComponent ? translation('ReplaceComponent') : translation('ReplaceStyle')}
                placement="bottom"
            >
                <IconButton
                    selected={!!isOpen}
                    icon={<MonoIconPanelTransform16 className="color-$wk-v2-label-color-gray-8" />}
                    className={style.replaceBtn}
                    onClick={handleReplaceBtnClick}
                    data-testid={CandidateComponentTestIds.replaceBtn}
                />
            </Tooltip>
            <Title>
                <Title.Left data-testid={CandidateComponentTestIds.nameText}>
                    {props.isComponent ? translation('CandidateComponent') : translation('CandidateStyle')}
                </Title.Left>
            </Title>
            <SingleGrid className={style.publishBtn}>
                <SingleGrid.Item start={5} span={55}>
                    <WKButton
                        type="secondary"
                        style={{ width: '100%' }}
                        onClick={commands.aiCandidateComponentConvert}
                        data-testid={CandidateComponentTestIds.publishBtn}
                    >
                        {translation('Create', {
                            name:
                                props.number === 1
                                    ? props.isComponent
                                        ? translation('Component')
                                        : translation('Style')
                                    : props.isComponent
                                    ? translation('MultipleComponents')
                                    : translation('MultipleStyles'),
                        })}
                    </WKButton>
                </SingleGrid.Item>
            </SingleGrid>
            {isOpen && (
                <CandidateComponentReplaceDialog
                    isComponent={props.isComponent}
                    candidateType={props.candidateType}
                    onClose={onClose}
                ></CandidateComponentReplaceDialog>
            )}
        </div>
    )
}
