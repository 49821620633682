import { getWasmDownloadUrl } from '../../../../util/src/cdn'
import { IN_JEST_TEST } from '../../environment'

import { computed } from 'ccstate'
import { traceable } from '../../../../util/src/ccstate-helper/traceable'

export const wasmBlobUrl$ = traceable(
    'hulh01@kanyun.com',
    computed(async () => {
        if (IN_JEST_TEST) {
            return Promise.resolve(getWasmDownloadUrl('wk-wasm-app.wasm'))
        } else {
            return fetch(getWasmDownloadUrl('wk-wasm-app.wasm'))
                .then((res) => res.blob())
                .then((blob) => URL.createObjectURL(blob))
        }
    })
)
