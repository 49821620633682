import { cloneDeep, isEqual, uniqWith } from 'lodash-es'
import { useMemo } from 'react'
import { useRightPanelWidth } from '../../../../../main/layout/layout-context'
import { useViewState } from '../../../../../view-state-bridge'
import { useRenderColorSpace } from '../../../color-profile'
import { StyleColorsV2 } from '../../../inspect/comp/style-colors-v2'
import { UnStyleColorsV2 } from '../../../inspect/comp/un-style-colors-v2'
import style from '../inspect.module.less'
import { ColorModePicker } from './color-mode-picker'
import { translation } from './index.translation'

export function DevModeInspectFill() {
    const viewState = useViewState('devModeInspectFillViewState') ?? {
        show: false,
        inspectSelectionFillItems: [],
    }
    const colorSpace = useRenderColorSpace()
    const inspectSelectionFillItems = useMemo(() => {
        return uniqWith(cloneDeep(viewState.inspectSelectionFillItems), isEqual)
    }, [viewState.inspectSelectionFillItems])
    // 没有使用样式
    const isUnStyle = !inspectSelectionFillItems.some((o) => o.styleName)
    const rightPanelWidth = useRightPanelWidth()

    if (!viewState.show) {
        return null
    }
    return (
        <div className={style.panel} data-testid="dev-mode-inspect-fill">
            <div className={style.panelTitle}>
                <div className={style.title}>{translation('BackgroundColor')}</div>
                <div className={style.panelCopyActionControls}>
                    <ColorModePicker></ColorModePicker>
                </div>
            </div>
            {isUnStyle && (
                <div className="px-4">
                    <UnStyleColorsV2
                        dataTestId="dev-mode-inspect-fill-unstyle-colors"
                        paints={inspectSelectionFillItems.map((o) => o.paints).flat()}
                        colorSpace={colorSpace}
                        variables={inspectSelectionFillItems.map((o) => o.variables).flat()}
                        maxWidth={rightPanelWidth - 32}
                    />
                </div>
            )}
            {!isUnStyle && (
                <div className="px-4">
                    <StyleColorsV2
                        dataTestId="dev-mode-inspect-fill-style-colors"
                        items={inspectSelectionFillItems}
                        colorSpace={colorSpace}
                        maxWidth={rightPanelWidth - 32}
                    />
                </div>
            )}
        </div>
    )
}
