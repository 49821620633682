import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Basics: '基础',
    Variable: '可变字体轴',
} as const

export const enTranslation = {
    Basics: 'Basics',
    Variable: 'Variable',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
