import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    HelpCenter: '帮助文档',
    VideoTutorials: '视频教程',
    KeyboardShortcuts: '快捷键指南',
    SubmitFeedback: '用户反馈',
    SendFeedback: '用户反馈',
    ResetOnboarding: '重置新手引导',
    ReleaseNotes: '更新日志',
} as const

export const enTranslation = {
    HelpCenter: 'Help center',
    VideoTutorials: 'Video tutorials',
    KeyboardShortcuts: 'Keyboard shortcuts',
    SubmitFeedback: 'Submit feedback',
    SendFeedback: 'Send feedback',
    ResetOnboarding: 'Reset onboarding',
    ReleaseNotes: 'Release notes',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
