import { FolderID, ResourceType, SeatType, TriggerSeatApplicationSceneType } from '../../kernel/interface/type'
import { featureSwitchManager } from '../../kernel/switch'
import { UserRole, UserSeatStatus } from '../../utils/payment'
import { PayApplyDialog } from './pay-apply-dialog/pay-apply-dialog'
import {
    PayApplyDialogSeatType,
    PayApplyDialogType,
    PayApplyDialogUserType,
} from './pay-apply-dialog/pay-apply-dialog-struct/type'
import { translation } from './pay-seat-apply-create-doc.translation'
import { getAdminUpgradeSelfDescription } from './util'

export function PayApplySeatOfCreateDocPromise(props: {
    userRole: UserRole
    userSeatStatus: UserSeatStatus
    resourceType: ResourceType
    resourceId: string
    unresolvedSeatTypes: SeatType[]
    folderId: FolderID
    userId: number
    isEnterprise: boolean
    orgId: string
    isSomeWorkspaceAdmin: boolean
}) {
    return new Promise<void>((resolve, reject) => {
        PayApplySeatOfCreateDoc({ ...props, resolve, reject })
    })
}

function PayApplySeatOfCreateDoc(props: {
    userRole: UserRole
    userSeatStatus: UserSeatStatus
    resourceType: ResourceType
    resourceId: string
    unresolvedSeatTypes: SeatType[]
    folderId: FolderID
    userId: number
    isEnterprise: boolean
    orgId: string
    isSomeWorkspaceAdmin: boolean
    resolve: (value: void | PromiseLike<void>) => void
    reject: (reason?: any) => void
}) {
    const {
        userRole,
        userSeatStatus,
        resourceId,
        resourceType,
        unresolvedSeatTypes,
        folderId,
        userId,
        isEnterprise,
        orgId,
        isSomeWorkspaceAdmin,
        resolve,
        reject,
    } = props

    const httpProps = {
        resourceType: resourceType,
        resourceId: resourceId,
        triggerScene: {
            scene: TriggerSeatApplicationSceneType.CreateDoc,
            folderId: folderId,
            userId: userId,
        },
        orgId: orgId,
    }

    if (userRole == UserRole.Admin || (featureSwitchManager.isEnabled('organization-plus') && isSomeWorkspaceAdmin)) {
        PayApplyDialog.show({
            type: PayApplyDialogType.Admin,
            seatType: PayApplyDialogSeatType.Design,
            dialogProps: {
                title: translation('DialogTitleOfAdminOrAutoGrade'),
                okText: translation('DialogOkTextOfAdminOrAutoGrade'),
                cancelText: translation('DialogCancelTextOfAdminOrAutoGrade'),
                onOk: async () => {
                    resolve()
                },
                onCancel: async () => {
                    reject('pay-seat-apply-create-doc')
                },
            },
            httpProps: httpProps,
            description: getAdminUpgradeSelfDescription('design'),
        })
        return
    }

    const userInfo = {
        type: userRole == UserRole.Guest ? PayApplyDialogUserType.External : PayApplyDialogUserType.Normal,
    }

    switch (userSeatStatus) {
        case UserSeatStatus.CanApplyAutoUpgrade: {
            PayApplyDialog.show({
                type: PayApplyDialogType.AutoUpgrade,
                seatType: PayApplyDialogSeatType.Design,
                dialogProps: {
                    title: translation('DialogTitleOfAdminOrAutoGrade'),
                    okText: translation('DialogOkTextOfAdminOrAutoGrade'),
                    cancelText: translation('DialogCancelTextOfAdminOrAutoGrade'),
                    onOk: async () => {
                        resolve()
                    },
                    onCancel: async () => {
                        reject('pay-seat-apply-create-doc')
                    },
                },
                httpProps: httpProps,
                description: undefined,
            })
            break
        }
        case UserSeatStatus.CanApplyGracePeriod:
        case UserSeatStatus.NeedWaitToBeApproved:
        case UserSeatStatus.NeedReApply: {
            PayApplyDialog.show({
                type: PayApplyDialogType.Normal,
                isAllowGracePeriod: userSeatStatus == UserSeatStatus.CanApplyGracePeriod,
                isEnterprise: isEnterprise,
                userInfo: userInfo,
                seatType: PayApplyDialogSeatType.Design,
                dialogProps: {
                    onOk: async () => {
                        userSeatStatus == UserSeatStatus.CanApplyGracePeriod
                            ? resolve()
                            : reject('pay-seat-apply-create-doc')
                    },
                    onCancel: async () => {
                        reject('pay-seat-apply-create-doc')
                    },
                },
                httpProps: httpProps,
                descriptionPrefix: translation('DialogDescriptionPrefix'),
                unresolvedSeatTypes: unresolvedSeatTypes,
                repeatDialogProps: {
                    dialogTitle: '',
                },
            })
            break
        }
        case UserSeatStatus.InGracePeriod:
        case UserSeatStatus.InAutoUpgrade:
        case UserSeatStatus.InPermanentSeat:
            // 如果走到这里，那 PayApplySeatOfCreateDocPromise 这个 promise 就一直处于未决状态？
            return
    }
}
