import { ONE_DAY } from '../../../../util/src'
import { startOfDay } from 'date-fns'
import { translation } from './data.translation'
// export const YEARS = [2022, 2023]
// 获取从 2022 到 当前年份
export const GET_YEARS = () => {
    const currentYear = new Date().getFullYear()
    return Array.from({ length: currentYear - 2022 + 1 }, (_, i) => 2022 + i)
}
export const MONTH = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
export const MONTH2LABEL_ZH = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
export const MONTH2LABEL_EN = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

export interface WKDatepickerValue {
    start: number
    end: number
}
export interface WKDatepickerProps {
    /**
     * 在这个日期之前的全部都禁用掉
     */
    disableTime?: number
    value: WKDatepickerValue
    onChange: (param: WKDatepickerValue, type: DatepickerSelectType) => void
}

export enum DatepickerSelectType {
    Today = 1,
    Yesterday,
    Last7Days,
    Last30Days,
    CustomRange,
}

export const DatepickerSelectType2Label: Record<DatepickerSelectType, string> = {
    [DatepickerSelectType.Today]: translation('Today'),
    [DatepickerSelectType.Yesterday]: translation('Yesterday'),
    [DatepickerSelectType.Last7Days]: `${translation('Last')} 7 ${translation('Days')}`,
    [DatepickerSelectType.Last30Days]: `${translation('Last')} 30 ${translation('Days')}`,
    [DatepickerSelectType.CustomRange]: translation('Custom'),
}
export const DatepickerSelectTypeOptions = Object.entries(DatepickerSelectType2Label).map(([value, name]) => ({
    value: +value,
    name,
}))

export const getWKDatepickerDefaultRange = (
    type:
        | DatepickerSelectType.Last30Days
        | DatepickerSelectType.Last7Days
        | DatepickerSelectType.Today
        | DatepickerSelectType.Yesterday
): WKDatepickerValue => {
    switch (type) {
        case DatepickerSelectType.Today:
            return { start: startOfDay(new Date()).getTime(), end: Date.now() }
        case DatepickerSelectType.Yesterday:
            return { start: startOfDay(new Date()).getTime() - ONE_DAY, end: Date.now() }
        case DatepickerSelectType.Last7Days:
            return { start: startOfDay(new Date()).getTime() - ONE_DAY * 6, end: Date.now() }
        case DatepickerSelectType.Last30Days:
            return { start: startOfDay(new Date()).getTime() - ONE_DAY * 29, end: Date.now() }
        default:
            throw new Error('getWKDatepickerDefaultRange error')
    }
}
