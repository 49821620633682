/* eslint-disable no-restricted-imports */
import { POST } from '@tutor/network-core'
import { BaseCommonRequest } from '../../kernel/request/base-request'

export interface UpgradedOperations {
    synergyOperations: Array<string>
    blobMap?: { [k: string]: string }
    refNodes?: { [k: string]: string }
}

// 升级离线数据时调用这个接口
@POST
export class UpgradeOperations extends BaseCommonRequest<UpgradedOperations> {
    constructor(
        private readonly docId: string,
        private readonly originSynergyOperations: Array<number>[],
        private readonly currentSchemaVersion: number,
        private readonly targetSchemaVersion: number,
        private readonly originBlobMap?: { [k: string]: Array<number> },
        private readonly originRefNodes?: { [k: string]: Array<number> }
    ) {
        super()
    }

    override requestUrl(): string {
        return `upgrade-operations`
    }

    public override requestBody() {
        return {
            docId: this.docId,
            synergyOperations: this.originSynergyOperations,
            blobMap: this.originBlobMap ? this.originBlobMap : {},
            refNodes: this.originRefNodes ? this.originRefNodes : {},
            currentSchemaVersion: this.currentSchemaVersion,
            targetSchemaVersion: this.targetSchemaVersion,
        }
    }
}
