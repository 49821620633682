import { omit } from 'lodash-es'
import React, { PropsWithChildren, useCallback, useEffect, useImperativeHandle, useRef } from 'react'
import { KeyboardCode, isKey } from '../../../kernel/keyboard/keyboard-event-handler'
import { WindowSelectionType, getWindowSelection } from '../../global/window'
import { focusViewTraceLog } from './focus-view-trace-log'

export interface FocusContainerProps {
    /**
     * 获得焦点的 div 测试 id。无默认值
     */
    dataTestId?: string
    /**
     * 当获得焦点后响应的 keydown 事件，默认为空
     */
    onKeyDown?: (e: React.KeyboardEvent) => void
    /**
     * 是否可以使用 tab 聚焦到，能则 tabIndex 为 0 否则为 -1。默认为 false，即 tabIndex 为 -1
     */
    tabbable?: boolean
    /**
     * 是否自动获得焦点，默认为 false
     */
    autoFocus?: boolean
    /**
     * 收到 keydown 事件且是 esc 时，是否优先检查有没有选区需要清空，然后再执行默认行为。默认为 false
     */
    shouldClearSelectionFirst?: boolean
}

const FocusContainerInternal = (
    props: PropsWithChildren<
        FocusContainerProps & Omit<React.HTMLAttributes<HTMLDivElement>, 'onKeyDown' | 'tabIndex'>
    >,
    ref: React.Ref<HTMLDivElement>
) => {
    const autoFocus = useRef(props.autoFocus ?? false)
    const divRef = useRef<HTMLDivElement | null>(null)
    const onKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            const divEl = divRef.current
            if (props.shouldClearSelectionFirst && isKey(e.nativeEvent, KeyboardCode.ESCAPE)) {
                const selection = getWindowSelection()
                if (
                    divEl &&
                    document.activeElement === divEl &&
                    selection &&
                    selection.type === WindowSelectionType.Range
                ) {
                    focusViewTraceLog('FocusContainer: clear selection', divEl)
                    divEl.blur()
                    selection.removeAllRanges()
                    e.stopPropagation()
                    return
                }
            }
            focusViewTraceLog(`FocusContainer: on keydown: ${e.type},${e.code}`, divEl)
            props.onKeyDown?.(e)
        },
        [props]
    )
    useEffect(() => {
        const divEl = divRef.current
        if (autoFocus.current) {
            focusViewTraceLog(`FocusContainer: focus on mounted`, divEl)
            divEl?.focus()
        }
        return () => {
            focusViewTraceLog(`FocusContainer: blur on destroyed`, divEl)
            divEl?.blur()
        }
    }, [])
    useImperativeHandle(ref, () => divRef.current!)
    const otherProps = omit(
        props,
        'dataTestId',
        'onKeyDown',
        'tabIndex',
        'tabbable',
        'autoFocus',
        'shouldClearSelectionFirst'
    )
    return (
        <div
            data-focus-container
            data-testid={props.dataTestId}
            tabIndex={props.tabbable ? 0 : -1}
            onKeyDown={onKeyDown}
            ref={divRef}
            {...otherProps}
        >
            {props.children}
        </div>
    )
}

/**
 * @description 自定义焦点容器。包装了一层 div，会在渲染后自动获得焦点，在卸载时自动失去焦点，在聚焦时接管一切键盘事件。你应该确保在容器出现时取代原先焦点的场景才使用它。
 */
export const FocusContainer = React.forwardRef(FocusContainerInternal)
