import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    UploadFailed: '上传失败，请重试',
    Uploading: '上传中...',
    Oversized: '请上传 5MB 以内的图片',
    FileFormatRestricted: '当前仅支持 PNG、JPG、WebP 格式',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    UploadFailed: 'Failed to upload. Try again.',
    Uploading: 'Uploading...',
    Oversized: 'Upload an image under 5MB.',
    FileFormatRestricted: 'Only PNG, JPG, and WebP are supported.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
