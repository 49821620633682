/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import React, { forwardRef, useCallback } from 'react'
import { DraggablePopupV2, IconDelete16, IconEyes, IconEyesClose } from '../../../../../../ui-lib/src'
import { usePosition } from '../../../utils/use-position'
import { IconButton } from '../../atom/button/icon-button'
import { SingleGrid } from '../../atom/grid/single-grid'
import { LayoutContent, LayoutContentProps } from './layout-content'
import style from './layout-grid-componet.module.less'
import { LayoutGridRecord } from './layout-grid-record'
import { LayoutTitle } from './layout-title'

export interface GridComponetProps
    extends React.HTMLAttributes<HTMLDivElement>,
        Omit<LayoutContentProps, 'onChangeColor' | 'onChangeOpacityValue' | 'onChangeOpacityNumber'> {
    layoutGrid: Wukong.DocumentProto.ILayoutGrid
    variables: Wukong.DocumentProto.ISingleFloatVariable[]
    isOpen?: boolean
    selected?: boolean
    isMini?: boolean
    disabled?: boolean
    onChangeVisible: (visible: boolean) => void
    onClickSub: () => void
    onChangeLayoutPattern: (pattern: Wukong.DocumentProto.LayoutGridPattern) => void
    onChangeModalVisible: (visible: boolean) => void
}

function _GridComponet(props: GridComponetProps, outRef: React.Ref<HTMLDivElement>) {
    const {
        className,
        layoutGrid,
        isOpen,
        selected,
        isMini,
        disabled,
        onChangeVisible,
        onClickSub,
        onChangeLayoutPattern,
        onChangeModalVisible,
        ...restprops
    } = props
    const { position, reactRef } = usePosition({ isOpen, outRef })

    const _onChangeVisible = useCallback(() => {
        onChangeModalVisible(false)
        onChangeVisible(!layoutGrid?.visible)
    }, [layoutGrid?.visible, onChangeModalVisible, onChangeVisible])

    const onClickOpenModal = useCallback(() => {
        onChangeModalVisible(!isOpen)
    }, [isOpen, onChangeModalVisible])

    const onCancel = () => {
        onChangeModalVisible(false)
    }

    return (
        <>
            <SingleGrid
                ref={reactRef}
                className={`${style.eventPenetrate} ${selected ? style.selected : ''} ${className ?? ''}`}
                style={isMini ? { gridTemplateColumns: 'repeat(60, 4px)' } : undefined}
            >
                <SingleGrid.Item start={5} span={isMini ? 44 : 46} data-simple-drag-ignore>
                    <SingleGrid
                        className={`${style.backgroundGrid}`}
                        style={isMini ? { gridTemplateColumns: `repeat(44, 4px)` } : undefined}
                    >
                        <SingleGrid.Item start={1} span={isMini ? 37 : 39}>
                            <LayoutGridRecord
                                layoutGrid={layoutGrid}
                                onMouseDownIcon={onClickOpenModal}
                                hidden={!layoutGrid!.visible}
                                disabled={disabled}
                            ></LayoutGridRecord>
                        </SingleGrid.Item>
                        <SingleGrid.Item start={isMini ? 38 : 40} span={7}>
                            <IconButton
                                icon={layoutGrid?.visible ? <IconEyes /> : <IconEyesClose />}
                                selected={false}
                                onChange={_onChangeVisible}
                                className={style.eyeIcon}
                                disabled={disabled}
                                dataTestId="layoutgrid-visible-icon"
                            />
                        </SingleGrid.Item>
                    </SingleGrid>
                </SingleGrid.Item>
                <SingleGrid.Item start={isMini ? 49 : 51} span={12} horizontalCenter>
                    <IconButton
                        icon={<IconDelete16 />}
                        selected={false}
                        onClick={onClickSub}
                        disabled={disabled}
                        dataTestId="layoutgrid-delete"
                        data-simple-drag-ignore
                    />
                </SingleGrid.Item>
            </SingleGrid>

            <DraggablePopupV2
                visible={isOpen}
                position={position}
                onCancel={onCancel}
                positionRightBase
                header={
                    <div className={style.layoutTitle}>
                        <LayoutTitle data={layoutGrid} onChangeLayoutPattern={onChangeLayoutPattern} />
                    </div>
                }
                footer={null}
                bodyClassName={style.layoutBody}
                styleType="editor"
                headerTestId="layoutgrid-modal-header"
                bodyTestId="layoutgrid-modal-body"
            >
                <LayoutContent layoutGrid={layoutGrid} {...restprops} />
            </DraggablePopupV2>
        </>
    )
}

export const GridComponet = forwardRef(_GridComponet)
