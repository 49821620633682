import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    OldPage: '画布背景',
    Page: '页面',
} as const

export const enTranslation = {
    OldPage: 'Page',
    Page: 'Page',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
