import { forwardRef, useImperativeHandle, useRef } from 'react'
import { ExplicitUndefined } from '../../../utils/explicit-undefined'
import { useCommentSelect } from '../../selects/select/use-comment-select'
import { Empty, EmptyProps, EmptyRef } from '../picks/empty/empty'
import { TriggerIcon, TriggerIconRef } from '../triggers/trigger-icon'
import { DropdownEmptyProps, DropdownTriggerIconProps } from './type'

export interface DropdownIconEmptyRef {
    close: () => void
    open: () => void
    getTriggerElement: () => HTMLElement | null | undefined
}

export type DropdownIconEmptyProps = DropdownTriggerIconProps & DropdownEmptyProps

function _DropdownIconEmpty(props: DropdownIconEmptyProps, ref?: React.Ref<DropdownIconEmptyRef>) {
    const pickRef = useRef<EmptyRef>(null)
    const triggerRef = useRef<TriggerIconRef>(null)
    const {
        pickRest,
        worldRect,
        triggerRect,
        placement,
        overMajorClassName,
        overMinorClassName,
        isMinWidthFromTrigger = true,
        minWidth,
        width,
        maxWidth,
        removeMask,
        autoAdjustLeft,
        onKeyboard,
        onClose,
        onOpen,
        ...otherProps
    } = props

    const commentSelectState = useCommentSelect({
        triggerRect,
        triggerRef,
        pickRef,
        onOpen,
        onClose,
    })
    useImperativeHandle(
        ref,
        () => ({
            close: () => pickRef.current?.close(),
            open: () => pickRef.current?.open(),
            getTriggerElement: () => triggerRef.current?.getTriggerElement(),
        }),
        []
    )

    return (
        <TriggerIcon
            ref={triggerRef}
            {...otherProps}
            isOpenPick={commentSelectState.isOpenPick}
            openAction={commentSelectState.openAction}
            closeAction={commentSelectState.closeAction}
        >
            <Empty<ExplicitUndefined<EmptyProps>>
                pickRest={pickRest}
                dataTestIds={props.dataTestIds}
                removeMask={removeMask}
                triggerRect={commentSelectState.triggerRect}
                closeByESC={commentSelectState.closeByESC}
                onClose={commentSelectState.onSelectClose}
                onOpen={commentSelectState.onSelectOpen}
                ref={pickRef}
                worldRect={worldRect}
                placement={placement}
                overMajorClassName={overMajorClassName}
                overMinorClassName={overMinorClassName}
                isMinWidthFromTrigger={isMinWidthFromTrigger}
                minWidth={minWidth}
                width={width}
                maxWidth={maxWidth}
                onKeyboard={onKeyboard}
                isOpenState={undefined}
                openStateToBeFalse={undefined}
                openStateToBeTrue={undefined}
                getPortalElement={commentSelectState.getPortalElement}
                autoAdjustLeft={autoAdjustLeft}
            >
                {props.children}
            </Empty>
        </TriggerIcon>
    )
}

export const DropdownIconEmpty = forwardRef(_DropdownIconEmpty)
