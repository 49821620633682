/*
 * 这个模块用来避免在代码中直接依赖 window 对象，从而避免在测试中对 window 的依赖
 *
 * 为什么要这么做?
 * 如果在代码中直接使用 window 下的对象，那么在测试中我们就不可避免的需要对 window 方法进行修改
 * 在 jest 运行这些测试时，两个异步测试的执行顺序可能会互相产生影响，例如:
 * ```
 * // test1.ts
 * const originalLocation = window.location
 * beforeEach(() => {
 *     window.location = mockedLocationA
 * })
 * afterEach(() => {
 *     window.location = originalLocation
 * })
 * it('testA', () => {
 *     await fooA();
 *     barA();
 * })
 *
 * // test2.ts
 * const originalLocation = window.location
 * beforeEach(() => {
 *     window.location = mockedLocationB
 * })
 * afterEach(() => {
 *     window.location = originalLocation
 * })
 * it('testB', () => {
 *     await fooB();
 *     barB();
 * })
 * ```
 * 以上代码在执行时，会有概率在 testA 中访问到 mockedLocationB，或者反过来也有可能
 * 为了避免这种情况，我们应该避免在测试中对 window（以及任何全局变量）进行 mock，更好
 * 的方式是使用 jest 的 module mock 能力。dom-env.ts 提供了这样的一个中间层，使我
 * 们可以通过 jest 的 module mock 能力来修改 window 下的一些全局对象，且不会影响到
 * 其他测试
 */
export function domDocument(): Document {
    return window.document
}

export function domLocation(): Location {
    return window.location
}
