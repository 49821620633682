/* eslint-disable no-process-env */
import { MODE } from '../meta'
import { formalAbroadDevEnvironment } from './formal-abroad.ts/development'
import { formalAbroadProductionEnvironment } from './formal-abroad.ts/production'
import { formalAbroadTestingEnvironment } from './formal-abroad.ts/testing'
import { devEnvironment } from './inland/development'
import { productionEnvironment } from './inland/production'
import { testingEnvironment } from './inland/testing'
import { Environment } from './types'
export * from './types'

// FOR TEST
export const IN_JEST_TEST = MODE === 'test'

export const START_FAKE_SERVER = MODE === 'development-with-fake-server'

// 由于 isAbroad 将变更为动态的，估 environment 无法再以静态存在，否则 worker 无法跑通
export const getEnvironment = (): Environment => {
    const origin = self.location.origin

    const isAbroad = origin?.includes('.zhenguanyu.com') || origin?.includes('motiff.com')
    const isTesting = origin?.includes('.zhenguanyu.com') || origin?.includes('.yuanfudao.biz')

    let env: Environment

    if (MODE === 'development' || START_FAKE_SERVER) {
        env = isAbroad ? formalAbroadDevEnvironment : devEnvironment
    } else if (isTesting || IN_JEST_TEST) {
        env = isAbroad ? formalAbroadTestingEnvironment : testingEnvironment
    } else {
        env = isAbroad ? formalAbroadProductionEnvironment : productionEnvironment
    }

    if (IN_JEST_TEST) {
        env.wasmDownloadPrefix = `file://${process.cwd()}/packages/app/src/editor`
    } else if (process.env.BUILD_WITHOUT_CDN) {
        env.wasmDownloadPrefix = process.env.WASM_DOWNLOAD_PREFIX
    } else {
        env.wasmDownloadPrefix = `${env.ossCdnPath}app${process.env.WASM_DOWNLOAD_PREFIX}`
    }

    return env
}

export const environment: Environment = getEnvironment()
