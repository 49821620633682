import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    cornerSizeToolTip: `在 shadcn/UI 中，圆角值通常会乘以基础圆角值来得到最终的圆角值：
0: 没有圆角。
0.3: 30% 的基础圆角值，即小圆角。
0.5: 50% 的基础圆角值，即中等圆角。
0.75: 75% 的基础圆角值，即较大圆角。
1.0: 100% 的基础圆角值，即完整的圆角效果`,
    cornerSize: '圆角',
    size: '尺寸',
    None: '无',
    Small: '小',
    Medium: '中',
    Large: '大',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    cornerSizeToolTip: `In shadcn/UI, the corner radius values are typically multiplied by the base corner radius to get the final value:
0: No rounded corners.
0.3: 30% of the base radius, representing a small corner radius.
0.5: 50% of the base radius, representing a medium corner radius.
0.75: 75% of the base radius, representing a large corner radius.
1.0: 100% of the base radius, achieving a fully rounded effect.`,
    cornerSize: 'Corner Radius',
    size: 'Size',
    None: 'None',
    Small: 'Small',
    Medium: 'Medium',
    Large: 'Large',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
