/* eslint-disable no-restricted-imports */
import { NodePreviewSubscribeCommand, NodePreviewUnsubscribeCommand } from '@wukong/bridge-proto'
import { useEffect } from 'react'
import { useThrottleFn } from 'react-use'
import { useCommand } from '../../../../../main/app-context'
import { useViewState } from '../../../../../view-state-bridge'
import { PreviewImageSize } from '../util'
import { useNodePreviewBase64 } from './use-get-node-preview'

export function useNodePreview(ids: string[], size: PreviewImageSize): string | null {
    const { base64, reFetch } = useNodePreviewBase64()
    const command = useCommand()
    const changed = useViewState('nodePreviewTracksChanged')
    const popupStateV2 = useViewState('batchExportPopupState')?.show
    const isOpenBatchExportModal = popupStateV2

    useThrottleFn<void, [number | undefined, string[], PreviewImageSize]>(
        (_arg_changed, arg_ids, arg_size) => {
            reFetch(arg_ids, arg_size)
        },
        166,
        [changed, ids, size]
    )

    useEffect(() => {
        if (base64 == null) {
            reFetch(ids, size)
        }
    }, [ids, size, reFetch, base64])

    useEffect(() => {
        command.DEPRECATED_invokeBridge(NodePreviewSubscribeCommand)
        return () => {
            // 在批量导出模态框打开时将不会在预览关闭后不再追踪更新
            if (isOpenBatchExportModal) {
                return
            } else {
                command.DEPRECATED_invokeBridge(NodePreviewUnsubscribeCommand)
            }
        }
    }, [command, isOpenBatchExportModal])

    return base64
}
