import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    Move: '移动',
    Scale: '等比缩放',
    Frame: '容器',
    Slice: '切片',
    Rectangle: '矩形',
    Line: '直线',
    Arrow: '箭头',
    Ellipse: '椭圆',
    Polygon: '多边形',
    Star: '星形',
    PlaceImage: '图片...',
    Pen: '钢笔',
    Text: '文本',
    HandTool: '手形工具',
    AddComment: '评论',
    BendTool: '曲率工具',
    PaintBucket: '油漆桶',
    BwkPDs: '魔法框',
    Copy: '复制',
    AlignLeft: '左对齐',
    AlignHorizontalCenters: '水平居中对齐',
    AlignRight: '右对齐',
    AlignTop: '顶对齐',
    AlignVerticalCenters: '垂直居中对齐',
    AlignBottom: '底对齐',
    Inspect: '标注工具',
} as const

export const enTranslation = {
    Move: 'Move',
    Scale: 'Scale',
    Frame: 'Frame',
    Slice: 'Slice',
    Rectangle: 'Rectangle',
    Line: 'Line',
    Arrow: 'Arrow',
    Ellipse: 'Ellipse',
    Polygon: 'Polygon',
    Star: 'Star',
    PlaceImage: 'Place image...',
    Pen: 'Pen',
    Text: 'Text',
    HandTool: 'Hand tool',
    AddComment: 'Add comment',
    BendTool: 'Bend tool',
    PaintBucket: 'Paint bucket',
    BwkPDs: 'Magic Box',
    Copy: 'Copy',
    AlignLeft: 'Align left',
    AlignHorizontalCenters: 'Align horizontal centers',
    AlignRight: 'Align right',
    AlignTop: 'Align top',
    AlignVerticalCenters: 'Align vertical centers',
    AlignBottom: 'Align bottom',
    Inspect: 'Inspect',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
