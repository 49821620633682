import { translation } from './library-name.translation'
import { Wukong } from '@wukong/bridge-proto'
import { HTMLAttributes, useMemo } from 'react'

// 组件库名称
export function LibraryName({
    libraryType,
    name,
    children,
    dataTestIdPrefix,
    ...props
}: {
    libraryType: Wukong.DocumentProto.VLibraryType | undefined | null
    name: string | undefined | null
    dataTestIdPrefix?: string
} & HTMLAttributes<HTMLSpanElement>) {
    const libraryName = useMemo(() => {
        switch (libraryType) {
            case Wukong.DocumentProto.VLibraryType.V_LIBRARY_TYPE_LOCAL:
                return translation('LocalComponents')
            case Wukong.DocumentProto.VLibraryType.V_LIBRARY_TYPE_LOCAL_HIDDEN:
                return translation('Hidden')
            case Wukong.DocumentProto.VLibraryType.V_LIBRARY_TYPE_INUSE:
                return translation('ComponentsUsedIn')
            case Wukong.DocumentProto.VLibraryType.V_LIBRARY_TYPE_OTHER:
                return name ?? translation('UnknownLibrary')
            default:
                return ''
        }
    }, [libraryType, name])

    return (
        <span {...props} data-testid={dataTestIdPrefix === undefined ? undefined : `${dataTestIdPrefix}${libraryName}`}>
            {libraryName}
            {children}
        </span>
    )
}
