import LS from '../local-storage'
import { CLOGHOST, CLOGENV } from './environment'

/**
 * 测试环境地址
 */
export function getWsHost(HOST: CLOGHOST, site: string) {
    return [`https://${HOST}.${site}.biz`, `https://${HOST}2.${site}.biz`]
}

/**
 * 线上环境地址
 */
export function getComHost(HOST: CLOGHOST, site: string) {
    return [
        `https://${HOST}.${site}.com`,
        `https://${HOST}2.${site}.com`,
        `https://${HOST}3.${site}.com`,
        `https://${HOST}4.${site}.com`,
        `https://${HOST}5.${site}.com`,
        `https://${HOST}6.${site}.com`,
    ]
}

export function getLocalKey(host: CLOGHOST, env: CLOGENV, site = 'yuanfudao') {
    return `tutor_clog_${env}_${host}_${site}_host`
}

/**
 * 获取localStorage缓存的hosts
 * @param key
 */
export function getLocalHost(key: string) {
    const data = LS.get(key)
    if (data?.hosts) {
        try {
            // 如果有缓存旧的ws域名直接清空
            const host = data.hosts[0]
            if (/ws$/.test(host)) {
                return []
            }
        } catch (error) {}
        return data.hosts
    }
}
/**
 * 获取测试环境随机host
 */
export function getHost(clogHost: CLOGHOST, clogEnv: CLOGENV, site: string) {
    const LocalKey = getLocalKey(clogHost, clogEnv, site)

    const localHost = getLocalHost(LocalKey)
    const localLen = localHost?.length

    const isTest = clogEnv === CLOGENV.WS || clogEnv === CLOGENV.BIZ
    const cacheHost = isTest ? getWsHost(clogHost, site) : getComHost(clogHost, site)

    const hosts = localLen > 0 ? localHost : cacheHost
    const len = hosts.length

    const index = Math.round(Math.random() * 100) % len

    return hosts[index]
}

/**
 * 判定当下环境的本地缓存是否有效，还在有效时间1天内
 * @param env
 */
export function isValidHost(host: CLOGHOST, env: CLOGENV, site: string) {
    const LocalKey = getLocalKey(host, env, site)

    const data = LS.get(LocalKey)
    if (data?.timestamp) {
        return Date.now() - data.timestamp > 86400000
    }
    return true
}

/**
 * 更新hosts列表到本地缓存
 * @param host 当前host地址
 * @param env  当前环境变量
 */
export function syncHost(host: CLOGHOST, env: CLOGENV, url: string, site: string) {
    if (!isValidHost(host, env, site)) {
        return false
    }

    const xhr = new XMLHttpRequest()
    xhr.open('Get', url, true)
    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
            try {
                const response = JSON.parse(xhr.response)
                // 存host
                const key = getLocalKey(host, env, site)
                LS.set(key, {
                    timestamp: Date.now(),
                    hosts: response.urls,
                })
            } catch (e) {
                console.error(e)
            }
        }
    }
    xhr.send()
}
