import classNames from 'classnames'
import { FC, useMemo } from 'react'
import { createRoot } from 'react-dom/client'
import { MonoIconCommonArrowRight16, WKDialog } from '../../../../../ui-lib/src'
import { AppState, AppStateNeedsAsyncResource, LifecycleTraceInfo, appStateNeedsAsyncResource } from '../types'
import styles from './index.module.less'

export const showLifecycleTracePanel = (traceInfo: LifecycleTraceInfo) => {
    const div = document.createElement('div')
    div.id = 'wukong-lifecycle-trace-panel'
    document.body.appendChild(div)

    const root = createRoot(div)

    root.render(
        <TracePanel
            traceInfo={traceInfo}
            close={() => {
                root.unmount()
                document.body.removeChild(div)
            }}
        />
    )
}

interface TracePanelProps {
    traceInfo: LifecycleTraceInfo
    close: () => void
}

const TracePanel: FC<TracePanelProps> = ({ traceInfo, close }) => {
    const appStates = Object.values(AppState)

    const asyncResourceStatus = useMemo(() => {
        return Object.values(AppState).reduce((res, appState) => {
            if (!appStateNeedsAsyncResource.includes(appState as AppStateNeedsAsyncResource)) {
                res[appState] = []
                return res
            }

            const resourceComplete = traceInfo.appState2AsyncResourceComplete[appState as AppStateNeedsAsyncResource]

            res[appState] = Object.entries(resourceComplete).map(([resource, complete]) => ({
                resource,
                complete,
            }))

            return res
        }, {} as Record<AppState, { resource: string; complete: boolean }[]>)
    }, [traceInfo])

    return (
        <WKDialog width={2000} title="生命周期链路跟踪" onCancel={close} visible footer={null}>
            <div className={styles['state-container']}>
                {
                    // appState
                    appStates.map((state, index) => (
                        <div key={state} style={{ display: 'flex' }}>
                            <div>
                                <div
                                    className={classNames(
                                        styles['state-outline'],
                                        traceInfo.currentState === state && styles['active-state']
                                    )}
                                >
                                    {state}
                                </div>

                                {asyncResourceStatus[state].length !== 0 && (
                                    <div>
                                        <span className={styles['async-resource']}>异步资源</span>

                                        {asyncResourceStatus[state].map(({ resource, complete }) => (
                                            <div key={resource} style={{ position: 'relative' }}>
                                                {!complete && state === traceInfo.currentState && (
                                                    <MonoIconCommonArrowRight16 className={styles['active-icon']} />
                                                )}
                                                <span>{resource}</span>
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {traceInfo.appState2CallbackDescriptionsList[state]?.length !== 0 && (
                                    <div>
                                        <span className={styles.callback}>回调队列</span>

                                        {traceInfo.appState2CallbackDescriptionsList[state]!.map(
                                            (callback, callbackIndex) => (
                                                <div key={callback} style={{ position: 'relative' }}>
                                                    {traceInfo.currentUnFinishedCustomCallbackIndex === callbackIndex &&
                                                        state === traceInfo.currentState && (
                                                            <MonoIconCommonArrowRight16
                                                                className={styles['active-icon']}
                                                            />
                                                        )}
                                                    <span>{callback}</span>
                                                </div>
                                            )
                                        )}
                                    </div>
                                )}
                            </div>
                            {index !== appStates.length - 1 && <span className={styles['state-next']}>{'—>'}</span>}
                        </div>
                    ))
                }
            </div>
        </WKDialog>
    )
}
