import { usePointerCapture } from '../../../../../../../../ui-lib/src'
import React, { useCallback, useRef, useState } from 'react'
import { useMount, useUpdateEffect } from 'react-use'
import classes from './cubic-bezier-canvas.module.less'

export interface CubicBezierCanvasProps {
    x1: number // 贝塞尔曲线 P1 点的 x 坐标
    y1: number // 贝塞尔曲线 P1 点的 y 坐标
    x2: number // 贝塞尔曲线 P2 点的 x 坐标
    y2: number // 贝塞尔曲线 P2 点的 y 坐标
    onChange: (cubicBezier: [number, number, number, number]) => void
    dragStart?: () => void
    dragEnd?: () => void
}

function getCoordinate(dpr: number) {
    const leftTopX = 40 * dpr
    const leftTopY = 40 * dpr
    const rightBottomX = 144 * dpr
    const rightBottomY = 144 * dpr
    return {
        // canvas 大小
        canvasWidth: 184 * dpr,
        canvasHeight: 184 * dpr,
        // 坐标区域
        leftTopX,
        leftTopY,
        rightBottomX,
        rightBottomY,
        xLength: rightBottomX - leftTopX,
        yLength: rightBottomY - leftTopY,
        // 线宽
        lineWidth: 1 * dpr,
        lineWidthForDocStroke: 2 * dpr,
        LineDash: [4 * dpr, 4 * dpr] as [number, number],
        fixedPointRadius: 2.5 * dpr,
        movedPointRadius: 3.5 * dpr,
        hotAreaLineWidth: 7 * dpr,
        hotAreaPointRadius: 7 * dpr,
    }
}

function pointOnLineSegment(
    checkX: number,
    checkY: number,
    LineX1: number,
    LineY1: number,
    LineX2: number,
    LineY2: number,
    lineWidth: number
) {
    const lineLengthSquared = (LineX2 - LineX1) * (LineX2 - LineX1) + (LineY2 - LineY1) * (LineY2 - LineY1)
    const t = ((checkX - LineX1) * (LineX2 - LineX1) + (checkY - LineY1) * (LineY2 - LineY1)) / lineLengthSquared
    if (t < 0 || t > 1) {
        // 点在线段的延长线上，而不是线段上
        return false
    }
    const nearestX = LineX1 + t * (LineX2 - LineX1)
    const nearestY = LineY1 + t * (LineY2 - LineY1)
    const dx = checkX - nearestX
    const dy = checkY - nearestY
    const distanceSquared = dx * dx + dy * dy
    return distanceSquared <= (lineWidth / 2) * (lineWidth / 2)
}
function pointOnLineSegmentEndPoint(
    checkX: number,
    checkY: number,
    LineEndX: number,
    LineEndY: number,
    radius: number
) {
    return Math.sqrt(Math.pow(checkX - LineEndX, 2) + Math.pow(checkY - LineEndY, 2)) - radius <= 0
}

export function CubicBezierCanvas(props: CubicBezierCanvasProps) {
    const { x1, y1, x2, y2, onChange, dragStart, dragEnd } = props
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const [handleState, setHandleState] = useState<{
        active: 'left' | 'right' | 'none'
        ratio2FixPoint: number
    }>(() => ({
        active: 'none',
        ratio2FixPoint: 0,
    }))
    const [coordinate, setCoordinate] = useState(() => getCoordinate(window.devicePixelRatio))

    const getHandlePointInCanvas = useCallback(
        (cubicBezierX: number, cubicBezierY: number) => {
            const { leftTopX, rightBottomY, xLength, yLength } = coordinate
            const canvasX = leftTopX + Math.max(Math.min(cubicBezierX, 1), 0) * xLength
            const canvasY = rightBottomY - cubicBezierY * yLength
            return { x: canvasX, y: canvasY }
        },
        [coordinate]
    )

    const transformDOMPoint2CanvasPoint = (domX: number, domY: number, rect: DOMRect) => {
        const { canvasWidth, canvasHeight } = coordinate
        const canvasX = (domX / rect.width) * canvasWidth
        const canvasY = (domY / rect.height) * canvasHeight
        return { x: canvasX, y: canvasY }
    }

    const pointOnLeftHandleLine = (canvasX: number, canvasY: number) => {
        const handlePoint = getHandlePointInCanvas(x1, y1)
        const isHitFixedPoint = pointOnLineSegmentEndPoint(
            canvasX,
            canvasY,
            coordinate.leftTopX,
            coordinate.rightBottomY,
            coordinate.hotAreaPointRadius
        )
        const isHitMovePoint = pointOnLineSegmentEndPoint(
            canvasX,
            canvasY,
            handlePoint.x,
            handlePoint.y,
            coordinate.hotAreaPointRadius
        )
        return {
            isHit:
                isHitFixedPoint ||
                isHitMovePoint ||
                pointOnLineSegment(
                    canvasX,
                    canvasY,
                    coordinate.leftTopX,
                    coordinate.rightBottomY,
                    handlePoint.x,
                    handlePoint.y,
                    coordinate.hotAreaLineWidth
                ),
            isHitFixedPoint,
            isHitMovePoint,
        }
    }

    const pointOnRightHandleLine = (canvasX: number, canvasY: number) => {
        const handlePoint = getHandlePointInCanvas(x2, y2)
        const isHitFixedPoint = pointOnLineSegmentEndPoint(
            canvasX,
            canvasY,
            coordinate.rightBottomX,
            coordinate.leftTopY,
            coordinate.hotAreaPointRadius
        )
        const isHitMovePoint = pointOnLineSegmentEndPoint(
            canvasX,
            canvasY,
            handlePoint.x,
            handlePoint.y,
            coordinate.hotAreaPointRadius
        )
        return {
            isHit:
                isHitFixedPoint ||
                isHitMovePoint ||
                pointOnLineSegment(
                    canvasX,
                    canvasY,
                    coordinate.rightBottomX,
                    coordinate.leftTopY,
                    handlePoint.x,
                    handlePoint.y,
                    coordinate.hotAreaLineWidth
                ),
            isHitFixedPoint,
            isHitMovePoint,
        }
    }

    const pointOnDiagonalLB2RT = (canvasX: number, canvasY: number) => {
        return pointOnLineSegment(
            canvasX,
            canvasY,
            coordinate.rightBottomX,
            coordinate.leftTopY,
            coordinate.leftTopX,
            coordinate.rightBottomY,
            coordinate.hotAreaLineWidth
        )
    }

    const ratio2LeftFixPoint = (canvasX: number, canvasY: number) => {
        const { leftTopX, rightBottomY, xLength, yLength } = coordinate
        const len = Math.sqrt(Math.pow(canvasX - leftTopX, 2) + Math.pow(canvasY - rightBottomY, 2))
        const sumLen = Math.sqrt(Math.pow(x1 * xLength, 2) + Math.pow(y1 * yLength, 2))
        const ratio2FixPoint = Math.max(Math.min(len / sumLen, 1), 0.01)
        return ratio2FixPoint
    }

    const ratio2RightFixPoint = (canvasX: number, canvasY: number) => {
        const { rightBottomX, leftTopY, xLength, yLength } = coordinate
        const len = Math.sqrt(Math.pow(canvasX - rightBottomX, 2) + Math.pow(canvasY - leftTopY, 2))
        const sumLen = Math.sqrt(Math.pow((1 - x2) * xLength, 2) + Math.pow((1 - y2) * yLength, 2))
        const ratio2FixPoint = Math.max(Math.min(len / sumLen, 1), 0.001)
        return ratio2FixPoint
    }

    const getNextX1Y1 = (canvasX: number, canvasY: number) => {
        const { leftTopX, rightBottomY, xLength, yLength } = coordinate
        const limitX = Math.max(Math.min((canvasX - leftTopX) / handleState.ratio2FixPoint, xLength), 0)
        if (limitX === 0) {
            const cubicY = (rightBottomY - canvasY) / handleState.ratio2FixPoint / yLength
            return { cubicX: 0, cubicY }
        } else {
            const k = (canvasY - rightBottomY) / (canvasX - leftTopX)
            const cubicX = limitX / xLength
            const cubicY = -((limitX * k) / yLength)
            return { cubicX, cubicY }
        }
    }

    const getNextX2Y2 = (canvasX: number, canvasY: number) => {
        const { leftTopY, rightBottomX, xLength, yLength } = coordinate
        const limitX = Math.max(Math.min((canvasX - rightBottomX) / handleState.ratio2FixPoint, 0), -yLength)
        if (limitX === 0) {
            const cubicY = 1 - (canvasY - leftTopY) / handleState.ratio2FixPoint / yLength
            return { cubicX: 1, cubicY }
        } else {
            const k = (canvasY - leftTopY) / (canvasX - rightBottomX)
            const cubicX = 1 + limitX / xLength
            const cubicY = 1 - (limitX * k) / yLength
            return { cubicX, cubicY }
        }
    }

    const closeCapture = (e: React.MouseEvent) => {
        const { offsetX, offsetY } = e.nativeEvent
        const rect = (e.target as HTMLCanvasElement).getBoundingClientRect()
        const point = transformDOMPoint2CanvasPoint(offsetX, offsetY, rect)
        const hitRightRes = pointOnRightHandleLine(point.x, point.y)
        const hitLeftRes = pointOnLeftHandleLine(point.x, point.y)
        if (hitRightRes.isHitMovePoint) {
            setHandleState({ active: 'right', ratio2FixPoint: ratio2RightFixPoint(point.x, point.y) })
            return false
        }
        if (hitLeftRes.isHitMovePoint) {
            setHandleState({ active: 'left', ratio2FixPoint: ratio2LeftFixPoint(point.x, point.y) })
            return false
        }
        if (hitRightRes.isHitFixedPoint) {
            onChange?.([x1, y1, 1, 1])
            return true
        }
        if (hitLeftRes.isHitFixedPoint) {
            onChange?.([0, 0, x2, y2])
            return true
        }
        if (hitRightRes.isHit) {
            setHandleState({ active: 'right', ratio2FixPoint: ratio2RightFixPoint(point.x, point.y) })
            return false
        }
        if (hitLeftRes.isHit) {
            setHandleState({ active: 'left', ratio2FixPoint: ratio2LeftFixPoint(point.x, point.y) })
            return false
        }
        if (pointOnDiagonalLB2RT(point.x, point.y)) {
            onChange?.([0, 0, 1, 1])
            return true
        }
        return true
    }

    const captureStart = () => {
        dragStart?.()
    }

    const capturing = (e: React.PointerEvent) => {
        if (handleState.active !== 'left' && handleState.active !== 'right') {
            return
        }
        const { offsetX, offsetY } = e.nativeEvent
        const rect = (e.target as HTMLCanvasElement).getBoundingClientRect()
        const { x, y } = transformDOMPoint2CanvasPoint(offsetX, offsetY, rect)
        if (handleState.active === 'left') {
            const { cubicX, cubicY } = getNextX1Y1(x, y)
            onChange?.([cubicX, cubicY, x2, y2])
        } else if (handleState.active === 'right') {
            const { cubicX, cubicY } = getNextX2Y2(x, y)
            onChange?.([x1, y1, cubicX, cubicY])
        }
    }

    const captureEnd = () => {
        setHandleState({ active: 'none', ratio2FixPoint: 0 })
        dragEnd?.()
    }

    const { pointerdown, pointerup, pointermove } = usePointerCapture({
        closeCapture,
        captureStart,
        capturing,
        captureEnd,
    })

    const getCtxHandleCycleStyle = useCallback((isActive: boolean, isOver: boolean) => {
        return {
            fillStyle: isActive ? '#3071FF' : isOver ? '#FFFFFF' : '#1A1D25',
            strokeStyle: isActive ? '#FFFFFF' : isOver ? '#1A1D25' : '#FFFFFF',
        }
    }, [])

    const draw = useCallback(() => {
        const ctx = canvasRef.current?.getContext('2d')
        if (!ctx || !canvasRef.current) return
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
        const {
            leftTopX,
            leftTopY,
            rightBottomX,
            rightBottomY,
            lineWidth,
            lineWidthForDocStroke,
            LineDash,
            fixedPointRadius,
            movedPointRadius,
        } = coordinate
        const { x: leftBottomHandlePointX, y: leftBottomHandlePointY } = getHandlePointInCanvas(x1, y1)
        const { x: rightTopHandlePointX, y: rightTopHandlePointY } = getHandlePointInCanvas(x2, y2)

        ctx.lineWidth = lineWidth
        // 绘制坐标轴虚线
        ctx.setLineDash(LineDash)
        ctx.beginPath()
        ctx.moveTo(leftTopX, leftTopY)
        ctx.lineTo(rightBottomX, leftTopY)
        ctx.moveTo(rightBottomX, rightBottomY)
        ctx.lineTo(rightBottomX, leftTopY)
        ctx.strokeStyle = '#AAAAAA'
        ctx.stroke()
        // 绘制坐标轴实线和对角线
        ctx.setLineDash([])
        ctx.beginPath()
        ctx.moveTo(leftTopX, leftTopY)
        ctx.lineTo(leftTopX, rightBottomY)
        ctx.lineTo(rightBottomX, rightBottomY)
        ctx.moveTo(leftTopX, rightBottomY)
        ctx.lineTo(rightBottomX, leftTopY)
        ctx.strokeStyle = '#AAAAAA'
        ctx.stroke()
        // 绘制 贝塞尔曲线
        ctx.beginPath()
        ctx.moveTo(leftTopX, rightBottomY)
        ctx.bezierCurveTo(
            leftBottomHandlePointX,
            leftBottomHandlePointY,
            rightTopHandlePointX,
            rightTopHandlePointY,
            rightBottomX,
            leftTopY
        )
        ctx.strokeStyle = '#1A1D25'
        ctx.stroke()
        // 绘制控制手柄 - 线
        ctx.beginPath()
        ctx.moveTo(leftTopX, rightBottomY)
        ctx.lineTo(leftBottomHandlePointX, leftBottomHandlePointY)
        ctx.moveTo(rightBottomX, leftTopY)
        ctx.lineTo(rightTopHandlePointX, rightTopHandlePointY)
        ctx.strokeStyle = '#1A1D25'
        ctx.stroke()
        //  绘制控制手柄 - 固定点
        ctx.beginPath()
        ctx.arc(leftTopX, rightBottomY, fixedPointRadius, 0, 2 * Math.PI)
        ctx.arc(rightBottomX, leftTopY, fixedPointRadius, 0, 2 * Math.PI)
        ctx.fillStyle = '#1A1D25'
        ctx.fill()
        //  绘制控制手柄 - 两个动态点
        ctx.beginPath()
        ctx.lineWidth = lineWidthForDocStroke
        const overLeftPoint = leftBottomHandlePointX === leftTopX && leftBottomHandlePointY === rightBottomY
        const leftHandleStyle = getCtxHandleCycleStyle(handleState.active === 'left', overLeftPoint)
        ctx.arc(leftBottomHandlePointX, leftBottomHandlePointY, movedPointRadius, 0, 2 * Math.PI)
        ctx.fillStyle = leftHandleStyle.fillStyle
        ctx.strokeStyle = leftHandleStyle.strokeStyle
        ctx.stroke()
        ctx.fill()
        ctx.beginPath()
        const overRightPoint = rightTopHandlePointX === rightBottomX && rightTopHandlePointY === leftTopY
        const rightHandleStyle = getCtxHandleCycleStyle(handleState.active === 'right', overRightPoint)
        ctx.arc(rightTopHandlePointX, rightTopHandlePointY, movedPointRadius, 0, 2 * Math.PI)
        ctx.fillStyle = rightHandleStyle.fillStyle
        ctx.strokeStyle = rightHandleStyle.strokeStyle
        ctx.stroke()
        ctx.fill()
        // _debugLineWidth(ctx, leftBottomHandlePointX, leftBottomHandlePointY, rightTopHandlePointX, rightTopHandlePointY)
    }, [coordinate, getCtxHandleCycleStyle, getHandlePointInCanvas, handleState.active, x1, x2, y1, y2])

    useMount(draw)

    useUpdateEffect(draw, [draw])

    useUpdateEffect(() => setCoordinate(getCoordinate(window.devicePixelRatio)), [window.devicePixelRatio])

    return (
        <canvas
            ref={canvasRef}
            width={coordinate.canvasWidth}
            height={coordinate.canvasHeight}
            style={{ width: 184, height: 184 }}
            className={classes.canvas}
            onPointerDown={pointerdown}
            onPointerMove={pointermove}
            onPointerUp={pointerup}
        ></canvas>
    )
}

function _debugLineWidth(
    ctx: CanvasRenderingContext2D,
    leftBottomHandlePointX: number,
    leftBottomHandlePointY: number,
    rightTopHandlePointX: number,
    rightTopHandlePointY: number
) {
    // 绘制debug辅助线
    const coordinate = getCoordinate(window.devicePixelRatio)
    ctx.lineWidth = coordinate.hotAreaLineWidth
    ctx.lineCap = 'square'
    ctx.beginPath()
    ctx.moveTo(coordinate.leftTopX, coordinate.rightBottomY)
    ctx.lineTo(leftBottomHandlePointX, leftBottomHandlePointY)
    ctx.moveTo(coordinate.rightBottomX, coordinate.leftTopY)
    ctx.lineTo(rightTopHandlePointX, rightTopHandlePointY)
    ctx.strokeStyle = 'red'
    ctx.stroke()
}
