import { FC } from 'react'
import { useAsync, useWindowSize } from 'react-use'
import {
    asyncModal,
    MonoIconCommonClose16,
    MonoIconCommonWarning24,
    Scrollbar,
    WKAvatar,
    WKIconButton,
    WKTextButton,
} from '../../../../../../../ui-lib/src'
import { WKTypography } from '../../../../../../../ui-lib/src/components/wk-typography/index'
import { isEnglishLanguage } from '../../../../../../../util/src'
import { useUserInfoContext } from '../../../../../auth'
import { FulfillmentBriefVO } from '../../../../../kernel/interface/account'
import { PayPlanType } from '../../../../../kernel/interface/type'
import { GetCancelAccountPreValidate } from '../../../../../kernel/request/account'
import { translation } from './cancel-account.translation'
import { emailValidateModal } from './email-validate-modal'
import { passwordValidateModal } from './password-validate-modal'
const planType2label: Record<PayPlanType, string> = {
    [PayPlanType.organization]: translation('Organization'),
    [PayPlanType.enterprise]: translation('Enterprise'), // TODO(zhengyihui): 临时使用
    [PayPlanType.professional]: translation('Professional'),
    [PayPlanType.starter]: '',
}

const ModalBody = ({
    invalidFulfillmentVOList,
    resolve,
}: {
    invalidFulfillmentVOList: FulfillmentBriefVO[] | undefined
    resolve: (value: 'ok' | 'cancel') => void
}) => {
    const { height: windowHeight } = useWindowSize()
    const otherHeight = isEnglishLanguage() ? 229 : 209
    const maxHeight = Math.min(800 - otherHeight, windowHeight * 0.85 - otherHeight - 8)
    const length = invalidFulfillmentVOList?.length ?? 0
    return (
        <div className="flex flex-col gap-4">
            <div className="flex gap-4">
                <div className="flex gap-2 flex-1 items-center">
                    <MonoIconCommonWarning24 className="color-$wk-yellow-7" />
                    <WKTypography.Text size={14} weight="medium">
                        {translation('UnableToDeleteAccount')}
                    </WKTypography.Text>
                </div>
                <WKIconButton
                    className="-my-0.5 -mr-2"
                    icon={<MonoIconCommonClose16 />}
                    onClick={() => resolve('cancel')}
                />
            </div>
            <div className="flex-col flex gap-3">
                <WKTypography.Text>{translation('UnableToDeleteAccountDesc')}</WKTypography.Text>
                <Scrollbar
                    autoHeight
                    autoHeightMax={maxHeight}
                    autoHeightMin={length > 2 ? 160 : length * 72}
                    className="rounded-3px"
                >
                    <div className="p-4 flex flex-col gap-4 bg-$wk-v2-fill-color-gray-1">
                        {invalidFulfillmentVOList?.map((data) => {
                            return (
                                <div key={data.resourceId} className="flex gap-2 items-center">
                                    <WKAvatar
                                        type="square"
                                        backgroundColor={data.iconBackgroundColor}
                                        name={data.name}
                                        imageId={data.icon}
                                        size={32}
                                    />
                                    <div className="flex flex-col truncate">
                                        <WKTypography.Text
                                            size={12}
                                            weight="medium"
                                            className="block! truncate max-w-full"
                                        >
                                            {data.name}
                                        </WKTypography.Text>
                                        <WKTypography.Text size={12} color="placeholder">
                                            {planType2label[data.planType]} · {data.memberCount}{' '}
                                            {translation('member') +
                                                (isEnglishLanguage() && data.memberCount > 1 ? 's' : '')}
                                        </WKTypography.Text>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Scrollbar>
            </div>
        </div>
    )
}
export const CancelAccount: FC<{
    setUserInfoDialogVisible: (visible: boolean) => void
    openResetPasswordModal: () => void
}> = ({ setUserInfoDialogVisible, openResetPasswordModal }) => {
    const { userInfo } = useUserInfoContext()
    const { value } = useAsync(() => new GetCancelAccountPreValidate().start(), [])
    const invalidFulfillmentVOList = value?.invalidFulfillmentVOList

    const canDeleteAccount = async () => {
        const passwordValidateResult = await passwordValidateModal()
        // 如果返回了verifyToken 继续打开第三个弹窗了
        if (typeof passwordValidateResult.verifyToken === 'string') {
            await emailValidateModal(passwordValidateResult.verifyToken, userInfo.userBrief.email)
            setUserInfoDialogVisible(true)
        } else if (passwordValidateResult.verifyToken === 1) {
            openResetPasswordModal()
        } else {
            setUserInfoDialogVisible(true)
        }
    }
    const onDeleteAccount = async () => {
        setUserInfoDialogVisible(false)
        if (!invalidFulfillmentVOList?.length) {
            await canDeleteAccount()
        } else {
            await asyncModal<'ok' | 'cancel'>({
                titleRender: <></>,
                hideTitleLine: true,
                childrenFn: (resolve) => (
                    <ModalBody invalidFulfillmentVOList={invalidFulfillmentVOList} resolve={resolve} />
                ),
                onCancel: (resolve) => {
                    resolve('cancel')
                },
                cancelButtonProps: {
                    hidden: true,
                },
                okText: translation('Ok'),
                onOk: (resolve) => {
                    resolve('ok')
                },
            })
            setUserInfoDialogVisible(true)
        }
    }
    return (
        <div className="flex gap-4 w-full">
            <div className="flex items-center gap-4 flex-1">
                <div className="flex-1 flex flex-col gap-1">
                    <WKTypography.Text weight="medium">{translation('CancelAccount')}</WKTypography.Text>
                    <WKTypography.Text color="placeholder">{translation('CancelAccountDesc')}</WKTypography.Text>
                </div>
            </div>
            <WKTextButton type="danger" size={12} onClick={onDeleteAccount}>
                {translation('Cancel')}
            </WKTextButton>
        </div>
    )
}
