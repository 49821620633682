import { MotiffApi } from '@motiffcom/plugin-api-types'
import { PluginApiListAvailableFontsAsync, PluginApiListAvailableFontsAsyncCallback } from '@wukong/bridge-proto'
import { IBasicBridge } from '../../kernel/bridge/basic-bridge'
import { BaseVM } from '../vm-interface'

export function createListAvailableFontsAsyncImpl(bridge: IBasicBridge, vm: BaseVM) {
    const listAvailableFontsAsyncCallbacks: Array<(ret: MotiffApi.Font[]) => void> = []

    bridge.bind(PluginApiListAvailableFontsAsyncCallback, (ret) => {
        const fonts = ret.fonts
            .map((font) => ({
                fontName: {
                    family: font.family!,
                    style: font.style!,
                },
            }))
            .sort((a, b) => {
                if (a.fontName.family !== b.fontName.family) {
                    return a.fontName.family > b.fontName.family ? 1 : -1
                }

                if (a.fontName.style !== b.fontName.style) {
                    return a.fontName.style > b.fontName.style ? 1 : -1
                }
                return 0
            })

        listAvailableFontsAsyncCallbacks.slice().forEach((callback) => callback(fonts))
        listAvailableFontsAsyncCallbacks.length = 0
    })

    return {
        listAvailableFontsAsync: () => {
            const { promise, resolve } = vm.newPromise()
            listAvailableFontsAsyncCallbacks.push((fonts) => {
                resolve(vm.deepWrapHandle(fonts))
            })
            bridge.call(PluginApiListAvailableFontsAsync)
            return promise
        },
    }
}
