export enum RootComponentId {
    Canvas = 'WKC',
    CanvasOverlay = 'WKC-Overlay',
}

export function getWkCanvasOverlay() {
    return document.getElementById(RootComponentId.CanvasOverlay) as HTMLDivElement
}

export const CANVAS_ID = RootComponentId.Canvas

export const POPUP_CONTAINER_ID = 'pop-up-container'
