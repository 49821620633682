import { MotiffApi } from '@motiffcom/plugin-api-types'

interface Enum {
    enum: string[]
}

interface Parameters {
    properties: Record<string, Enum | Record<string, string>>
    required: string[]
}

interface Template {
    name: string
    type: string
    variant_types: string[]
    parameters: Parameters
}

type Results = Array<{ type: string; message: string; node: MotiffApi.SceneNode; id: string }>

export async function lintByTemplate(accessToken: string, page: MotiffApi.PageNode) {
    const path = encodeURIComponent('dsl/data/templates/templates_v4.json')
    const res = await fetch(`https://jihulab.com/api/v4/projects/165579/repository/files/${path}/raw`, {
        headers: { 'PRIVATE-TOKEN': accessToken },
    })
    const json = await res.json()
    const templates: Template[] = json.templates
    const templatesNameMap: Map<string, Template> = new Map(templates.map((t) => [t.name, t]))
    const components = page.children as MotiffApi.FrameNode[]
    // Check if all component are in templates, expected "homeindicator", "statusbar"
    for (const component of components) {
        const name = component.name
        if (name !== 'homeindicator' && name !== 'statusbar') {
            const template = templatesNameMap.has(name)
            if (!template) {
                throw new Error('Component not in templates: ' + name)
            }
        }
    }
    // Check if all component in templates are in components
    for (const [name] of templatesNameMap) {
        const component = components.find((c) => c.name === name)
        if (!component) {
            throw new Error('Template not in components: ' + name)
        }
    }
    // Check if the component children name can be found in the related Templates in BFS, expected properties in variant_types
    for (const component of components) {
        const template = templatesNameMap.get(component.name) as Template
        // Check if all the names are in the properties
        const shouldContainedFieldNames = new Set(Object.keys(template?.parameters?.properties ?? []))
        // The variant_types will not occurred in node fields
        for (const variant_type of template?.variant_types ?? []) {
            shouldContainedFieldNames.delete(variant_type)
        }
        // BFS iter all children collect all the names
        const queue: MotiffApi.SceneNode[] = [component]
        const fieldNames = new Set<string>()
        while (queue.length) {
            const node = queue.shift() as MotiffApi.SceneNode
            if (node.type === 'FRAME' || node.type === 'GROUP') {
                node.children.forEach((child) => queue.push(child))
            }
            // component children name should not repeated
            if (fieldNames.has(node.name) && shouldContainedFieldNames.has(node.name)) {
                throw new Error('Duplicate field name found: ' + node.name)
            }
            fieldNames.add(node.name)
        }
        // every name in shouldContainedFieldNames should be in fieldNames
        for (const name of shouldContainedFieldNames) {
            if (!fieldNames.has(name)) {
                throw new Error('Field name not in component: ' + name)
            }
        }
    }
}

export function noInvisibleNode(
    root: MotiffApi.SceneNode | MotiffApi.PageNode,
    collectedResults: Results = []
): Results {
    if (root.type !== 'PAGE' && !root.visible && root.name !== 'placeholder' && root.type != 'SLICE') {
        collectedResults.push({
            type: 'no invisible',
            message: 'invisible node found: ' + root.name,
            node: root,
            id: root.id,
        })
    }
    if (root.type === 'FRAME' || root.type === 'GROUP' || root.type === 'PAGE') {
        root.children.forEach((child) => noInvisibleNode(child, collectedResults))
    }
    return collectedResults
}

export function noComponent(root: MotiffApi.SceneNode | MotiffApi.PageNode, collectedResults: Results = []): Results {
    if (root.type === 'COMPONENT' || root.type === 'COMPONENT_SET') {
        collectedResults.push({
            type: 'no component',
            message: 'component found: ' + root.name,
            node: root,
            id: root.id,
        })
    }
    if (root.type === 'FRAME' || root.type === 'GROUP' || root.type === 'PAGE') {
        root.children.forEach((child) => noComponent(child, collectedResults))
    }
    return collectedResults
}
