import { translation } from './use-switch-target-library.translation'

import { UpdateLibraryReplaceModalTargetLibraryWasmCall, Wukong } from '@wukong/bridge-proto'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { formatMenuList, WKItemType, WKMenuConfig, WKToast } from '../../../../../../ui-lib/src'
import { compareString } from '../../../../../../util/src'
import { LibraryId } from '../../../../kernel/interface/component-style-library-id'
import { DocID, FolderID } from '../../../../kernel/interface/type'
import type { LibraryQueryResponse, LibraryVO } from '../../../../kernel/interface/library'
import { GetLibraryQuery } from '../../../../kernel/request/library'
import { useCommand } from '../../../../main/app-context'
import { useLibraryModalConfigContext } from '../../../context/library-component-style/library-modal-config'
import { useDocInfoContext } from '../../../context/top-area-context'

export interface UseSwitchTargetLibraryProps extends Wukong.DocumentProto.ILibraryIdInfo {
    setSelectedTargetName: (value: string | undefined) => void
}
export function useSwitchTargetLibrary({ setSelectedTargetName, ...props }: UseSwitchTargetLibraryProps) {
    const command = useCommand()
    const { docData: currentDoc } = useDocInfoContext()
    const {
        mainModalConfig: { routePath },
    } = useLibraryModalConfigContext()
    const [libraryQueryResponse, _setLibraryQueryResponse] = useState<LibraryQueryResponse>()
    const setLibraryQueryResponse = useCallback(async () => {
        _setLibraryQueryResponse(
            currentDoc?.id
                ? await new GetLibraryQuery(currentDoc.id, '').start().catch(() => {
                      WKToast.error(translation('FailedToObtain'))
                      return undefined
                  })
                : undefined
        )
    }, [currentDoc?.id])

    useEffect(() => {
        if (
            routePath ===
            Wukong.DocumentProto.LibraryMainModalRoutePathType.LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REPLACE_LIBRARY_DETAIL
        ) {
            setLibraryQueryResponse()
        }
    }, [routePath, setLibraryQueryResponse])

    const onSwitchTargetLibrary = useCallback(
        (documentId: DocID, docName: string, libraryId: LibraryId) => {
            setSelectedTargetName(docName)
            command.DEPRECATED_invokeBridge(UpdateLibraryReplaceModalTargetLibraryWasmCall, { documentId, libraryId })
        },
        [command, setSelectedTargetName]
    )

    const menuConfig = useMemo<WKMenuConfig>(() => {
        const result: WKMenuConfig = []
        if (libraryQueryResponse) {
            // 原始组件库所在项目 id
            let originFolderId: FolderID | undefined

            // 按 team -> folder -> library 的顺序组织 menu 展示
            Object.entries(libraryQueryResponse.teamId2TeamName)
                .sort((i1, i2) => compareString(i1[1], i2[1]))
                .forEach(([teamId, teamName]) => {
                    const folderId2FolderName: Record<FolderID, string> = {}
                    const folderId2LibraryList: Record<FolderID, LibraryVO[]> = {}

                    libraryQueryResponse.teamId2LibraryList[teamId]
                        .sort((l1, l2) => compareString(l1.document?.name ?? '', l2.document?.name ?? ''))
                        .forEach((library) => {
                            // 过滤原始组件库
                            if (library.document && library.document.id !== props.documentId) {
                                folderId2FolderName[library.document.folderId] = library.document.folderName!
                                folderId2LibraryList[library.document.folderId] = [
                                    ...(folderId2LibraryList[library.document.folderId] ?? []),
                                    library,
                                ]
                            }

                            if (library.document && library.document.id === props.documentId) {
                                originFolderId = library.document.folderId
                            }
                        })

                    // 置顶原始组件库所在的项目下的其他组件库
                    if (
                        originFolderId &&
                        folderId2FolderName[originFolderId] &&
                        folderId2LibraryList[originFolderId].length
                    ) {
                        result.splice(
                            0,
                            0,
                            {
                                type: WKItemType.GroupHeader,
                                name: folderId2FolderName[originFolderId],
                                key: originFolderId,
                            },
                            ...folderId2LibraryList[originFolderId].map(({ document, id: libraryId }) => ({
                                name: document!.name,
                                handler: () => onSwitchTargetLibrary(document!.id, document!.name, libraryId),
                                key: libraryId,
                            })),
                            {
                                type: WKItemType.Divider,
                            }
                        )
                    }

                    const subMenuList: WKMenuConfig = []
                    Object.entries(folderId2FolderName)
                        .sort(([, folderName1], [, folderName2]) => compareString(folderName1, folderName2))
                        .forEach(([folderId, folderName]) => {
                            if (folderId !== originFolderId) {
                                subMenuList.push({
                                    type: WKItemType.GroupHeader,
                                    name: folderName,
                                    key: folderId,
                                })
                                folderId2LibraryList[folderId].forEach(({ document, id: libraryId }) => {
                                    if (document) {
                                        subMenuList.push({
                                            name: document.name,
                                            handler: () => onSwitchTargetLibrary(document.id, document.name, libraryId),
                                            key: libraryId,
                                        })
                                    }
                                })
                                subMenuList.push({
                                    type: WKItemType.Divider,
                                })
                            }
                        })

                    if (subMenuList.length) {
                        result.push({
                            name: teamName,
                            position: 'right top',
                            children: subMenuList,
                            key: teamId,
                        })
                    }
                })
        }
        return formatMenuList(result)
    }, [libraryQueryResponse, onSwitchTargetLibrary, props.documentId])

    return {
        menuConfig,
        loading: !libraryQueryResponse,
        disabled: !menuConfig.length,
    }
}
