import { translation } from './local-select-group.translation'
/* eslint-disable no-restricted-imports */
import { useMemo, useState } from 'react'
import { DropdownDefaultCustomNode, DropdownV2, RectContainer } from '../../../../../../../../ui-lib/src'
import { LibraryStyleTestId } from '../../../../../../window'
import { useCommand } from '../../../../../context/document-context'
import { EffectStyleDetail } from '../../../../../document-view-state/view-states/effect-style'
import { CommonStyleInfo } from '../../../styles/get-style-nodes-info-map'
import style from '../../../styles/style-color/style-color.module.less'
import { StyleListItem, StyleListItemKeyboardProps } from '../../../styles/style-items-layout/style-list-item'
import { EffectStyleEditor } from '../../../styles/style-panel/effect-style-editor/effect-style-editor'
import { useEffectContext } from '../../context'
import { EffectStyleIcon } from '../effect-style-icon'
import { useEffectStyleEditInsideSelect, useEffectStyleSelectHandler } from './use-effect-style-select'
interface EffectStyleItemProps {
    item: EffectStyleDetail
}

export function EffectStyleListItem(props: EffectStyleItemProps & StyleListItemKeyboardProps) {
    const { closeEffectStyleSelectModal } = useEffectContext()
    const { onSelect, onDelete } = useEffectStyleSelectHandler()
    const { onEdit, onEndEdit, opened, openedId, position } = useEffectStyleEditInsideSelect()

    const [contextMenuInfo, setContextMenuInfo] = useState<RectContainer | null>(null)

    const onSelectStyle = (item: CommonStyleInfo) => {
        onSelect(item.id)
        closeEffectStyleSelectModal()
    }

    const onOperateStyle = (item: CommonStyleInfo, e: any) => {
        const { clientX, clientY } = e
        setContextMenuInfo({
            top: clientY,
            bottom: clientY,
            right: clientX,
            left: clientX,
        })
    }

    const onEditStyle = (item: CommonStyleInfo, containerDomRect: DOMRect) => {
        onEdit(item.id, containerDomRect)
    }

    const onClickEditStyle = (e?: any) => {
        e?.stopPropagation()
        if (!contextMenuInfo) {
            return
        }
        setContextMenuInfo(null)
        onEdit(props.item.effectStyle.id, contextMenuInfo)
    }

    const onDeleteStyle = () => {
        onDelete(props.item.effectStyle.id)
    }

    const onClosehandler = () => {
        setContextMenuInfo(null)
    }

    const listItem = useMemo(() => {
        const {
            styleName,
            groupName,
            effectStyle: { id, name, description },
        } = props.item
        return {
            styleName,
            groupName,
            id,
            name,
            description,
        }
    }, [props.item])

    return (
        <>
            <StyleListItem
                item={listItem}
                openStyleId={opened && openedId === props.item.effectStyle.id && position ? openedId : undefined}
                onSelectItem={onSelectStyle}
                onClickHoverIcon={onEditStyle}
                onContextMenu={onOperateStyle}
                dataTestId={`${LibraryStyleTestId.LocalEffectStyleItem}-${props.item.effectStyle.name}`}
                index={props.index}
                selectStyleId={props.selectStyleId}
                setPreselectIndex={props.setPreselectIndex}
                trySetPreselectIndex={props.trySetPreselectIndex}
                recordEnterCallback={props.recordEnterCallback}
            >
                <EffectStyleIcon className={style.thumbnail} iconType={props.item.iconType} />
                <div className={style.name}>{props.item.styleName}</div>
            </StyleListItem>
            {contextMenuInfo ? (
                <DropdownV2.NoTriggerMultiLevel<DropdownDefaultCustomNode & { handler?: () => void }>
                    isOpenState={true}
                    triggerRect={contextMenuInfo}
                    onClose={onClosehandler}
                    onChange={(v) => v.customNode?.handler?.()}
                    pickRest={{ onMouseDown: (e) => e.stopPropagation() }}
                    onKeyboard={(e) => e.code === 'Escape' && e.stopPropagation()}
                    items={[
                        {
                            name: translation('EditStyle'),
                            customNode: {
                                handler: onClickEditStyle,
                            },
                        },
                        {
                            name: translation('DeleteStyle'),
                            customNode: {
                                handler: onDeleteStyle,
                            },
                        },
                    ]}
                />
            ) : null}
            {position ? (
                <EffectStyleEditor
                    visible={opened && openedId === props.item.effectStyle.id}
                    onCancel={onEndEdit}
                    position={position}
                    styleId={props.item.effectStyle.id}
                />
            ) : null}
        </>
    )
}
