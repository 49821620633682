import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Random: '任意主题',
    Minimal: '极简',
    Animal: '动物',
    Nature: '自然',
    Architecture: '建筑',
    Plant: '植物',
    Art: '艺术',
    Portrait: '肖像',
    Business: '商业',
    Space: '太空',
    Colorful: '颜色',
    Technology: '科技',
    Food: '食物',
    Texture: '质感',
    Interior: '室内',
    Abstract: '抽象',
} as const

export const enTranslation = {
    Random: 'random',
    Minimal: 'minimal',
    Animal: 'animal',
    Nature: 'nature',
    Architecture: 'architecture',
    Plant: 'plant',
    Art: 'art',
    Portrait: 'portrait',
    Business: 'business',
    Space: 'space',
    Colorful: 'colorful',
    Technology: 'technology',
    Food: 'food',
    Texture: 'texture',
    Interior: 'interior',
    Abstract: 'abstract',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
