import { forwardRef } from 'react'
import { WKTextButton } from '../../../../../../../ui-lib/src'
import { PayPlanType } from '../../../../../kernel/interface/type'
import { PayUpgradeDialog } from '../../../../../share/payment/pay-upgrade-dialog/pay-upgrade-dialog'
import {
    PayUpgradeDialogFrom,
    PayUpgradeDialogType,
} from '../../../../../share/payment/pay-upgrade-dialog/pay-upgrade-dialog-struct/type'
import { UpgradePopupType, WKFrogTask } from '../../../../../share/wk-frog-task'
import { useDocInfoContext } from '../../../../context/top-area-context'
import { RectangleSchedule } from '../../../top-area/tool-title/rectangle-schedule'
import styles from './index.module.less'
import { translation } from './index.translation'

interface PageLimitTipProps {
    pageNum: number
    isFreeTeam: boolean
}

export const PAGE_LIMIT_NUM = 3

function _PageLimitTip({ pageNum, isFreeTeam }: PageLimitTipProps, ref?: React.Ref<HTMLDivElement>) {
    const { docData } = useDocInfoContext()

    if (!isFreeTeam) {
        return null
    }

    const getfreeTipText = () => {
        if (pageNum > PAGE_LIMIT_NUM) {
            return translation('ReachedPageLimitNotNumTip', { limitNum: `${PAGE_LIMIT_NUM}` })
        } else if (pageNum === PAGE_LIMIT_NUM) {
            return translation('ReachedPageLimit', { pageNum: `${pageNum}`, limitNum: `${PAGE_LIMIT_NUM}` })
        } else {
            return translation('CreatedPageLimit', {
                pageNum: `${pageNum}`,
                limitNum: `${PAGE_LIMIT_NUM}`,
                leftNum: `${PAGE_LIMIT_NUM - pageNum}`,
            })
        }
    }

    return (
        <div className={styles.pageLimitTipWrapper} ref={ref}>
            <div className={styles.pageLimitTip} data-testid="page-limit-tip">
                <RectangleSchedule sumStep={PAGE_LIMIT_NUM} currentStep={pageNum} keyStep={PAGE_LIMIT_NUM} />
                <div className={styles.description}>{getfreeTipText()}</div>
                <WKTextButton
                    type="primary"
                    size={12}
                    onClick={() => {
                        WKFrogTask.payment.UpgradePopupType(UpgradePopupType.GetMorePage)
                        PayUpgradeDialog.show({
                            type: PayUpgradeDialogType.All,
                            currentPlanType: PayPlanType.starter,
                            organizationProps: {
                                submitProps: {
                                    from: PayUpgradeDialogFrom.Editor,
                                    teamId: docData?.teamId,
                                },
                            },
                            professionalProps: {
                                submitProps: {
                                    from: PayUpgradeDialogFrom.Editor,
                                    teamId: docData?.teamId,
                                },
                            },
                        })
                    }}
                >
                    {translation('UpgradeForUnlimitedPages')}
                </WKTextButton>
            </div>
        </div>
    )
}

export const PageLimitTip = forwardRef(_PageLimitTip)
