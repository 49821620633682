import { FC, useState } from 'react'
import styles from './index.module.less'
import { useEffectOnce } from 'react-use'

export const SpaceProgress: FC = () => {
    const [progress, setProgress] = useState<number>(25)

    const [hidden, setHidden] = useState(false)

    useEffectOnce(() => {
        let hiddenTimer: NodeJS.Timeout

        // 0-60 阶段为线性增长
        const timer = setTimeout(() => {
            setProgress(75)

            hiddenTimer = setTimeout(() => {
                setHidden(true)
            }, 1000)
        }, 100)

        return () => {
            clearTimeout(timer)
            hiddenTimer && clearTimeout(hiddenTimer)
        }
    })

    return (
        <div
            style={{
                display: hidden ? 'none' : 'block',
            }}
            className={styles.outside}
        >
            <div className={styles.inside} style={{ width: `${progress}vw` }}></div>
        </div>
    )
}
