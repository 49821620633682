import React, {
    forwardRef,
    isValidElement,
    KeyboardEvent,
    MouseEvent,
    useCallback,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react'
import ReactDOM from 'react-dom'
import { useMount, useUpdateEffect } from 'react-use'
import { ExplicitUndefined } from '../../../../utils/explicit-undefined'
import { CaptureKeyboard, CaptureKeyboardProps, CaptureKeyboardRef } from '../../capture-keyboard/capture-keyboard'
import { PopUpScroll, PopUpScrollProps, PopUpScrollRef } from '../../pop-up-scroll/pop-up-scroll'
import { PickBaseProps, PickBaseRef, pickComponentNames } from '../type'
import { useComment } from '../use-comment'
import { mixedValue, SelectMixedOption } from './select-option-mixed'
import {
    optionElementClassName,
    preselectClassName,
    selectElementClassName,
    SingleLevelContext,
    SingleLevelContextProps,
} from './type'

function isValidValue(value: any): boolean {
    return value !== undefined
}

type PickPopUpScrollProps = Omit<
    PopUpScrollProps,
    | 'overMajorClassName'
    | 'overMinorClassName'
    | 'visibleClassName'
    | 'visibleTrigger'
    | 'onClickMask'
    | 'dataTestIds'
    | 'isNotCoverTriggerRect'
    | 'className'
>

export interface SingleLevelProps extends PickBaseProps, PickPopUpScrollProps {
    optionTemplate: (props: { disabled: boolean; value: any }) => JSX.Element
    dataTestIds?: PopUpScrollProps['dataTestIds'] & CaptureKeyboardProps['dataTestIds']
    children?: React.ReactNode
    disableDefaultPreselect?: boolean
    value?: SingleLevelContextProps['selectOptionsValue']
    mainValue?: any // 如果value 传入的是一个数组，那么这个值用于确认默认预选中的
    isMixed?: boolean // 对应文本 【多个值】
    isAllowEmptyOption?: boolean
    onChange?: SingleLevelContextProps['onChange']
    onChangePreselect?: (value: any, options: { action: 'mouse' | 'keyboard' | 'open' | 'close' }) => void
}

export interface SingleLevelRef extends PickBaseRef {}

function _SingleLevel<T extends SingleLevelProps>(props: T, ref?: React.Ref<SingleLevelRef>) {
    const {
        value: _value,
        mainValue,
        disableDefaultPreselect,
        onChange,
        closeByESC,
        openStateToBeTrue,
        openStateToBeFalse,
        onOpen,
        onClose,
        getPortalElement,
        onKeyboard,
        onChangePreselect,
    } = props
    const getDefaultPreselectValue = useRef<() => any>(() => undefined)
    const captureKeyboardRef = useRef<CaptureKeyboardRef>(null)
    const popUpScrollRef = useRef<PopUpScrollRef>(null)
    const value = useMemo(() => {
        return props.isMixed ? mixedValue : _value
    }, [_value, props.isMixed])
    getDefaultPreselectValue.current = () => {
        if (disableDefaultPreselect) {
            return undefined
        }
        return isValidValue(mainValue) ? mainValue : Array.isArray(value) ? value[0] : value
    }
    const [isOpenState, setIsOpenState] = useState(false)
    const [preselectValue, setPreselectValue] = useState(getDefaultPreselectValue.current)

    const { addOverflowHiddenToBody, resetOverflowStatusToBody } = useComment()

    const optionValues = useMemo(() => {
        const allOptionValues: unknown[] = []
        const enabledOptionValues: unknown[] = []
        React.Children.forEach(props.children, (child) => {
            React.Children.forEach(child, (optionsChild) => {
                if (isValidElement(optionsChild) && optionsChild.type === props.optionTemplate) {
                    const optionValue = optionsChild.props.value
                    if (isValidValue(optionValue)) {
                        allOptionValues.push(optionValue)
                        if (!optionsChild.props.disabled) {
                            enabledOptionValues.push(optionValue)
                        }
                    }
                }
            })
        })
        return { allOptionValues, enabledOptionValues }
    }, [props.children, props.optionTemplate])

    const open = useCallback(() => {
        if (isOpenState || openStateToBeTrue?.()) {
            return
        }
        const forbidOpen = optionValues.allOptionValues.length === 0 && !props.isAllowEmptyOption
        if (forbidOpen) {
            return
        }
        addOverflowHiddenToBody()
        setIsOpenState(true)
        const initPreselectValue = getDefaultPreselectValue.current()
        setPreselectValue(initPreselectValue)
        onChangePreselect?.(initPreselectValue, { action: 'open' })
        onOpen?.()
    }, [
        addOverflowHiddenToBody,
        isOpenState,
        onOpen,
        openStateToBeTrue,
        optionValues.allOptionValues.length,
        props.isAllowEmptyOption,
        onChangePreselect,
    ])

    const close = useCallback(() => {
        if (!isOpenState || openStateToBeFalse?.()) {
            return
        }
        resetOverflowStatusToBody()
        setIsOpenState(false)
        setPreselectValue(undefined)
        onChangePreselect?.(undefined, { action: 'close' })
        onClose?.()
    }, [isOpenState, onChangePreselect, onClose, openStateToBeFalse, resetOverflowStatusToBody])

    const updateOpenStateFromOutside = useCallback(() => {
        if (props.isOpenState === false) {
            close()
        } else if (props.isOpenState === true) {
            open()
        }
    }, [close, open, props.isOpenState])

    useMount(updateOpenStateFromOutside)

    useUpdateEffect(updateOpenStateFromOutside, [updateOpenStateFromOutside])

    const onFocusLeave = useCallback(() => {
        close()
    }, [close])

    const tryRestoreFocusElement = useCallback(() => {
        if (!captureKeyboardRef.current || captureKeyboardRef.current.getContainer().contains(document.activeElement)) {
            return
        }
        captureKeyboardRef.current.focus()
    }, [])

    const _onChange = useCallback(
        (nextValue: unknown, e?: MouseEvent | KeyboardEvent) => {
            close()
            isValidValue(nextValue) && onChange?.(nextValue, e)
        },
        [close, onChange]
    )

    const setPreselectValueByMouse = useCallback(
        (nextPreselectValue: unknown) => {
            setPreselectValue(nextPreselectValue)
            onChangePreselect?.(nextPreselectValue, { action: 'mouse' })
        },
        [onChangePreselect]
    )

    const setPreselectValueByKey = useCallback(
        (dir: 'up' | 'down') => {
            const deltaIndex = dir === 'up' ? -1 : 1
            const { enabledOptionValues } = optionValues
            const currIndex = enabledOptionValues.findIndex((v) => v === preselectValue)
            let nextIndex = currIndex + deltaIndex
            if (nextIndex >= enabledOptionValues.length) {
                nextIndex = 0
            }
            if (nextIndex < 0) {
                nextIndex = enabledOptionValues.length - 1
            }
            if (nextIndex === currIndex) {
                return
            }
            const nextPreselectValue = enabledOptionValues[nextIndex]
            setPreselectValue(nextPreselectValue)
            onChangePreselect?.(nextPreselectValue, { action: 'keyboard' })
        },
        [optionValues, preselectValue, onChangePreselect]
    )
    const onKeyboardEvent = useCallback(
        (e: React.KeyboardEvent) => {
            if (e.keyCode === 13 || e.code === 'Enter') {
                e.stopPropagation()
                _onChange(preselectValue, e)
            } else if (e.keyCode === 38 || e.code === 'ArrowUp') {
                e.preventDefault()
                e.stopPropagation()
                setPreselectValueByKey('up')
            } else if (e.keyCode === 40 || e.code === 'ArrowDown') {
                e.preventDefault()
                e.stopPropagation()
                setPreselectValueByKey('down')
            } else if (e.keyCode === 27 || e.code === 'Escape') {
                e.stopPropagation()
                close()
                closeByESC?.()
            }
            onKeyboard?.(e)
        },
        [_onChange, close, closeByESC, onKeyboard, preselectValue, setPreselectValueByKey]
    )

    useImperativeHandle(ref, () => ({ open, close }), [close, open])

    return isOpenState
        ? ReactDOM.createPortal(
              <CaptureKeyboard<ExplicitUndefined<CaptureKeyboardProps>>
                  dataTestIds={props.dataTestIds}
                  ref={captureKeyboardRef}
                  onKeyboardEvent={onKeyboardEvent}
                  onFocusLeave={onFocusLeave}
                  onMouseMove={tryRestoreFocusElement}
                  getFocusElement={() => popUpScrollRef.current?.getKeyboardElement()}
                  data-component-name={pickComponentNames.singleLevel}
                  className={props.pickRest?.classNameRoot}
                  onMouseDown={props.pickRest?.onMouseDown}
              >
                  <PopUpScroll<ExplicitUndefined<PopUpScrollProps>>
                      className={props.pickRest?.firstPopUpScrollProps?.className}
                      ref={popUpScrollRef}
                      worldRect={props.worldRect}
                      triggerRect={props.triggerRect}
                      placement={props.placement ?? 'over'}
                      isMinWidthFromTrigger={props.isMinWidthFromTrigger}
                      minWidth={props.minWidth}
                      width={props.width}
                      maxWidth={props.maxWidth}
                      removeMask={props.removeMask}
                      removeTopPadding={props.removeTopPadding}
                      removeBottomPadding={props.removeBottomPadding}
                      dataTestIds={props.dataTestIds}
                      overMajorClassName={selectElementClassName}
                      overMinorClassName={optionElementClassName}
                      visibleClassName={preselectClassName}
                      visibleTrigger={preselectValue}
                      onClickMask={close}
                      autoAdjustTop={props.autoAdjustTop}
                      isNotCoverTriggerRect={undefined}
                  >
                      <SingleLevelContext.Provider
                          value={{
                              preselectValue,
                              selectOptionsValue: value,
                              selectElementClassName,
                              optionElementClassName,
                              preselectClassName,
                              onChange: _onChange,
                              setPreselectValueByMouse,
                          }}
                      >
                          <SelectMixedOption isMixed={props.isMixed} dataTestId="mixed-option" />
                          {props.children}
                      </SingleLevelContext.Provider>
                  </PopUpScroll>
              </CaptureKeyboard>,
              getPortalElement?.() ?? document.body
          )
        : null
}

export const SingleLevel = forwardRef(_SingleLevel) as <T extends SingleLevelProps = SingleLevelProps>(
    props: T & { ref?: React.Ref<SingleLevelRef> }
) => React.ReactElement
