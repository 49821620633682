import classNames from 'classnames'
import { InputV2 } from '../../../../../../../ui-lib/src'
import { isEnglishLanguage } from '../../../../../../../util/src'
import { isKeyAsEnter } from '../../../../../kernel/keyboard/keyboard-event-handler'
import { BlurType } from '../../../../../main/service/active-element-blur-service'
import classes from './index.module.less'
import { translation } from './index.translation'

export function VariableNameDescription({
    name,
    description,
    onChangeName,
    onChangeDescription,
}: {
    name?: string
    description?: string
    onChangeName: (value: string) => void
    onChangeDescription: (value: string) => void
}) {
    return (
        <div
            className={classNames(classes.container, isEnglishLanguage() && classes.en)}
            data-testid="variable-name-description"
        >
            <div className={classes.line}>
                <div className={classes.label}>{translation('Name')}</div>
                <div className={classes.name}>
                    <InputV2
                        className={classes.nameInput}
                        value={name ?? ''}
                        onBlur={(e) => onChangeName(e.target.value)}
                        autoFocus
                        placeholder={translation('ProvideName')}
                        data-blur-after-capture-mouse-down={BlurType.Immediately}
                        dataTestIds={{
                            input: 'variable-name-input',
                        }}
                    />
                </div>
            </div>
            <div className={classes.line}>
                <div className={classes.label}>{translation('Description')}</div>
                <div className={classes.description}>
                    <InputV2.Textarea
                        key={description}
                        placeholder={translation('ProvideDescription')}
                        onBlur={(e) => onChangeDescription((e.target as HTMLTextAreaElement).value)}
                        onKeyDown={(e) => {
                            if (isKeyAsEnter(e.nativeEvent)) {
                                e.preventDefault()
                                setTimeout(() => (e.target as HTMLTextAreaElement).blur(), 0)
                            }
                        }}
                        defaultValue={description ?? ''}
                        minHeight={32}
                        autoHeight
                        data-blur-after-capture-mouse-down={BlurType.Immediately}
                        dataTestIds={{
                            textarea: 'variable-description-input',
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
