/* eslint-disable no-restricted-imports */
import { useCallback, useEffect, useRef } from 'react'
import { EditorMode } from '../../../../document/node/node'
import { useCommentService } from '../../../../main/app-context'
import { RootComponentId } from '../../../../main/canvas/canvas'
import { useViewState } from '../../../../view-state-bridge'

export function useEventCanvas() {
    const commentService = useCommentService()
    const editorMode = useViewState('editorModeState')?.editorMode || EditorMode.EM_Move
    const hasDownBeforeUp = useRef<boolean>(false)

    const pointerdown = useCallback(
        (e: PointerEvent) => {
            hasDownBeforeUp.current = true
            if (
                editorMode !== EditorMode.EM_Comment &&
                commentService.hasOpenComment() &&
                commentService.checkMouseEventExist()
            ) {
                e.preventDefault()
                e.stopPropagation()
            }
        },
        [commentService, editorMode]
    )

    const pointerup = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (e: PointerEvent) => {
            if (hasDownBeforeUp.current && editorMode === EditorMode.EM_Comment && commentService.hasOpenComment()) {
                commentService.checkMouseEventExist()
            }
            hasDownBeforeUp.current = false
        },
        [commentService, editorMode]
    )

    useEffect(() => {
        const eventTarget = document.getElementById(RootComponentId.CanvasOverlay)
        if (!eventTarget) {
            return
        }
        eventTarget.addEventListener('pointerdown', pointerdown, false)
        window.addEventListener('pointerup', pointerup, false)
        return () => {
            eventTarget.removeEventListener('pointerdown', pointerdown, false)
            window.removeEventListener('pointerup', pointerup, false)
        }
    }, [pointerdown, pointerup])
}
