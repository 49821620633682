import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    Solid: '纯色',
    LineGradient: '线性渐变',
    RadialGradient: '径向渐变',
    AngularGradient: '角度渐变',
    DiamondGradient: '菱形渐变',
    Image: '图片',
    Center: '居中',
    Inside: '内部',
    Outside: '外部',
    InnerShadow: '内阴影',
    DropShadow: '外阴影',
    LayerBlur: '图层模糊',
    BackgroundBlur: '背景模糊',
} as const

export const enTranslation = {
    Solid: 'Solid',
    LineGradient: 'Line gradient',
    RadialGradient: 'Radial gradient',
    AngularGradient: 'Angular gradient',
    DiamondGradient: 'Diamond gradient',
    Image: 'Image',
    Center: 'Center',
    Inside: 'Inside',
    Outside: 'Outside',
    InnerShadow: 'Inner shadow',
    DropShadow: 'Drop shadow',
    LayerBlur: 'Layer blur',
    BackgroundBlur: 'Background blur',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
