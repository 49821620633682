import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    ImageSizePlease: '图片尺寸不能小于300x300',
    ImageShouldBe: '图片大小请不要超过10M',
    Reupload: '重新上传',
    Cancel: '取消',
    Confirm: '确定',
} as const

export const enTranslation = {
    ImageSizePlease: 'Image is too small (Minimum is 300×300)',
    ImageShouldBe: 'Image is too large for profile picture (Maximum is 10M)',
    Reupload: 'Reupload',
    Cancel: 'Cancel',
    Confirm: 'Confirm',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
