/* eslint-disable no-restricted-imports */
import {
    ClearIntervalForWasm,
    ClearTimeoutForWasm,
    CmdSetURLSearchParams,
    DetachSelectedInstanceReport,
    DownLoadBase64,
    EnqueueMicrotaskForWasm,
    GenerateDocumentNodeUrl,
    GenerateUrlWithSearchParams,
    GetIsCreatingFile,
    GetLocalEditorTypeCommand,
    GetMicrosecondTimestampForPerformanceMeasurement,
    GetMotiffScopeConfigCommand,
    GetPrototypePresentUrlParams,
    GetTimestampForDurationCalculation,
    GetTimestampForPerformanceMeasurement,
    GetURLSearchParams,
    GetWallClock,
    GetWindowClientInnerSize,
    IsTabVisible,
    LoadBenchResult,
    NaturalSortStringArray,
    OnAnimationFrame,
    OnMicroTask,
    OpenLink,
    PickImages,
    PrototypeAnimationFrameCallback,
    PushMetric,
    ReloadImageTimerCallback,
    RequestAnimationFrameCommand,
    SaveBenchResult,
    SetIntervalForWasm,
    SetLocalEditorTypeCommand,
    SetPrototypePresentUrlParams,
    SetTimeoutForCommand,
    SetTimeoutToReloadImage,
    Void,
    Wukong,
} from '@wukong/bridge-proto'
import { downloadBlob, isDocumentVisible, sortArrayByKey } from '../../../../util/src'
import {
    clearSignalTimer,
    signalAnimationFrame,
    signalInterval,
    signalMicrotask,
    signalTimeout,
} from '../../../../util/src/abort-controller/timers'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { Bridge } from '../../kernel/bridge/bridge'
import { MetricCollector } from '../../kernel/metric-collector'
import { ReportDetachSelectedInstance } from '../../kernel/request/library-usage'
import { featureSwitchManager } from '../../kernel/switch/core'
import { openLink } from '../../kernel/util/url'
import { createFileManager } from '../../main/create-file-manager'
import { getPresentUrlParams, setPresentUrlParams } from '../../prototype/context/present-url'
import { LocalStorageKey } from '../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../web-storage/local-storage/storage'
import {
    generateDocumentNodeUrl,
    generateURLWithSearchParams,
    getURLSearchParams,
    setURLSearchParams,
} from '../util/url'

/**
 * 给 wasm 提供一些基础能力
 */
export class WasmUtilBridge {
    private rafCount = 0
    private timeoutIds = new Set<number>()
    private intervalIds = new Set<number>()

    constructor(
        private readonly bridge: Bridge,
        private readonly isPrototype: boolean,
        private signal?: TraceableAbortSignal
    ) {
        this.bridge.bind(SetLocalEditorTypeCommand, (proto) => {
            enhancedLocalStorage.setSerializedItem(LocalStorageKey.EditorType, proto.value)
        })

        this.bridge.bind(GetLocalEditorTypeCommand, () => {
            return {
                value: enhancedLocalStorage.getSerializedItem(
                    LocalStorageKey.EditorType,
                    Wukong.DocumentProto.EditorType.EDITOR_TYPE_DESIGN
                ),
            }
        })

        this.bridge.bind(OpenLink, (args) => {
            openLink(args.url, args.openUrlInNewTab)
        })

        this.bridge.bind(GetTimestampForPerformanceMeasurement, () => {
            return { value: performance.now() }
        })

        this.bridge.bind(GetTimestampForDurationCalculation, () => {
            return { value: performance.now() }
        })

        this.bridge.bind(GetMicrosecondTimestampForPerformanceMeasurement, () => {
            return { value: performance.now() * 1000 }
        })

        this.bridge.bind(GetWallClock, () => {
            return { value: Date.now() }
        })

        this.bridge.bind(RequestAnimationFrameCommand, (wasmCall) => {
            if (this.rafCount != 0) {
                throw new Error('More than one RAF in a tick')
            }

            this.rafCount += 1

            signalAnimationFrame(
                () => {
                    // 在历史版本下有可能会有延迟的 Raf，此时不再渲染
                    if (this.rafCount !== 0) {
                        this.rafCount -= 1
                        if (this.isPrototype) {
                            this.bridge.call(PrototypeAnimationFrameCallback)
                        }
                        this.bridge.call(OnAnimationFrame, wasmCall)
                        MetricCollector.onAnimationFrame()
                    }
                },
                { signal: this.signal }
            )
        })

        this.bridge.bind(EnqueueMicrotaskForWasm, (wasmCall) => {
            signalMicrotask(
                () => {
                    this.bridge.call(OnMicroTask, wasmCall)
                    MetricCollector.onAnimationFrame()
                },
                { signal: this.signal }
            )
        })

        this.bridge.bind(SetTimeoutForCommand, (arg) => {
            const callbackId = signalTimeout(
                () => {
                    this.bridge.call({
                        code: arg.callback!,
                        argumentType: Void as any,
                        returnType: Void as any,
                    })
                    this.timeoutIds.delete(callbackId)
                },
                arg.timeout!,
                { signal: this.signal }
            )

            this.timeoutIds.add(callbackId)

            return { value: callbackId }
        })

        this.bridge.bind(SetTimeoutToReloadImage, (arg) => {
            const callbackId = signalTimeout(
                () => {
                    this.bridge.call(ReloadImageTimerCallback, arg)
                    this.timeoutIds.delete(callbackId)
                },
                arg.timeout!,
                { signal: this.signal }
            )

            this.timeoutIds.add(callbackId)

            return { value: callbackId }
        })

        this.bridge.bind(SetIntervalForWasm, (arg) => {
            const callbackId = signalInterval(
                () => {
                    this.bridge.call({
                        code: arg.callback!,
                        argumentType: Void as any,
                        returnType: Void as any,
                    })
                    this.intervalIds.delete(callbackId)
                },
                arg.interval!,
                { signal: this.signal }
            )

            this.intervalIds.add(callbackId)
            return { value: callbackId }
        })

        this.bridge.bind(ClearTimeoutForWasm, (arg) => {
            clearSignalTimer(arg.value as any)
        })

        this.bridge.bind(ClearIntervalForWasm, (arg) => {
            clearSignalTimer(arg.value as any)
        })

        // URL Service
        this.bridge.bind(GenerateDocumentNodeUrl, generateDocumentNodeUrl)
        this.bridge.bind(GetURLSearchParams, getURLSearchParams)
        this.bridge.bind(CmdSetURLSearchParams, setURLSearchParams)
        this.bridge.bind(GenerateUrlWithSearchParams, generateURLWithSearchParams)
        this.bridge.bind(GetPrototypePresentUrlParams, getPresentUrlParams)
        this.bridge.bind(SetPrototypePresentUrlParams, setPresentUrlParams)

        this.bridge.bind(IsTabVisible, () => {
            return Wukong.DocumentProto.BridgeProtoBoolean.create({
                value: isDocumentVisible(),
            })
        })

        this.bridge.bind(PushMetric, (arg) => {
            if (arg.pushMetricsToServer) {
                MetricCollector.pushMetricToServer(arg.metricName!, arg.value!, arg.labels!)
            } else {
                MetricCollector.pushMetric(arg.metricName!, arg.value!)
            }
        })

        this.bridge.bind(NaturalSortStringArray, ({ targetList }) => {
            return Wukong.DocumentProto.Arg_naturalSortStringArray.create({
                targetList: targetList ? sortArrayByKey(targetList, 'value') : undefined,
            })
        })
        this.bridge.bind(GetIsCreatingFile, () => {
            return { value: createFileManager.isCreatingFile() }
        })

        this.bridge.bind(DownLoadBase64, (wasmCall) => {
            const buffer = Buffer.from(wasmCall.dataBase64, 'base64')
            const blob = new Blob([buffer], {
                type: 'application/octet-stream',
            })

            downloadBlob(blob, wasmCall.name)
        })

        this.bridge.bind(GetWindowClientInnerSize, () => {
            return {
                innerWidth: window.innerWidth,
                innerHeight: window.innerHeight,
                devicePixelRatio: window.devicePixelRatio,
            } as Wukong.DocumentProto.WindowClientInnerSize
        })

        this.bridge.bind(LoadBenchResult, () => {
            return enhancedLocalStorage.getSerializedItem(
                LocalStorageKey.BenchResult,
                Wukong.DocumentProto.BenchResult.create({
                    cost: 0,
                    objectSize: 0,
                    readTime: 0,
                    renderTime: 0,
                    version: 0,
                })
            )
        })

        this.bridge.bind(SaveBenchResult, (result) => {
            enhancedLocalStorage.setSerializedItem(
                LocalStorageKey.BenchResult,
                Wukong.DocumentProto.BenchResult.create(result)
            )
        })

        // 分离实例行为上报服务端
        this.bridge.bind(DetachSelectedInstanceReport, (arg) => {
            new ReportDetachSelectedInstance(arg.docId, arg.nodeId2ComponentNodeId).start().catch(() => {})
        })

        if (featureSwitchManager.isEnabled('motiff-debugger')) {
            this.bridge.bind(GetMotiffScopeConfigCommand, () => {
                const config = enhancedLocalStorage.getSerializedItem(LocalStorageKey.MotiffScopeConfig)
                return {
                    showMainPanel: config?.showMainPanel ?? false,
                    followSelection: config?.followSelect ?? false,
                    autoShowCommon: config?.autoShowCommon ?? false,
                }
            })
        }
    }

    public destroy() {
        this.bridge.unbind(SetLocalEditorTypeCommand)
        this.bridge.unbind(GetLocalEditorTypeCommand)
        this.bridge.unbind(OpenLink)
        this.bridge.unbind(GetTimestampForPerformanceMeasurement)
        this.bridge.unbind(GetTimestampForDurationCalculation)
        this.bridge.unbind(GetMicrosecondTimestampForPerformanceMeasurement)
        this.bridge.unbind(GetWallClock)
        this.bridge.unbind(RequestAnimationFrameCommand)
        this.bridge.unbind(EnqueueMicrotaskForWasm)
        this.bridge.unbind(SetTimeoutForCommand)
        this.bridge.unbind(SetTimeoutToReloadImage)
        this.bridge.unbind(SetIntervalForWasm)
        this.bridge.unbind(ClearTimeoutForWasm)
        this.bridge.unbind(ClearIntervalForWasm)
        this.bridge.unbind(GenerateDocumentNodeUrl)
        this.bridge.unbind(GetURLSearchParams)
        this.bridge.unbind(CmdSetURLSearchParams)
        this.bridge.unbind(GenerateUrlWithSearchParams)
        this.bridge.unbind(IsTabVisible)
        this.bridge.unbind(PushMetric)
        this.bridge.unbind(PickImages)
        this.bridge.unbind(NaturalSortStringArray)
        this.bridge.unbind(GetIsCreatingFile)
        this.bridge.unbind(DownLoadBase64)
        this.bridge.unbind(GetWindowClientInnerSize)
        this.bridge.unbind(GetPrototypePresentUrlParams)
        this.bridge.unbind(SetPrototypePresentUrlParams)
        this.bridge.unbind(LoadBenchResult)
        this.bridge.unbind(SaveBenchResult)
        this.bridge.unbind(DetachSelectedInstanceReport)
        if (featureSwitchManager.isEnabled('motiff-debugger')) {
            this.bridge.unbind(GetMotiffScopeConfigCommand)
        }
        this.timeoutIds.forEach((id) => clearTimeout(id))
        this.intervalIds.forEach((id) => clearInterval(id))
    }

    public resetRafCount(): void {
        this.rafCount = 0
    }
}
