import { translation } from './mask-panel.translation'
import { Wukong } from '@wukong/bridge-proto'
import { Checkbox } from '../../../../../../../../ui-lib/src'
import { SingleGrid } from '../../../../atom/grid/single-grid'
import { Title } from '../../../../atom/title/title'
import { useEffect } from 'react'
import { featureGuideService } from '../../../../../../main/service/feature-guide/feature-guide-service'

export interface MaskPanelProps {
    checkedState: Wukong.DocumentProto.MixedCheckState
    onChange?: () => void
}
export function MaskPanel(props: MaskPanelProps) {
    const { checkedState, onChange } = props
    useEffect(() => {
        return () => {
            featureGuideService.tryRemove('mask')
        }
    }, [])

    return (
        <div className={'pt-2 pb-3'}>
            <Title>
                <Title.Left>{translation('Mask')}</Title.Left>
            </Title>
            <SingleGrid>
                <SingleGrid.Item start={1} span={59}>
                    <Checkbox
                        className="ml-4"
                        checked={checkedState === Wukong.DocumentProto.MixedCheckState.MIXED_CHECK_STATE_TRUE}
                        indeterminate={checkedState === Wukong.DocumentProto.MixedCheckState.MIXED_CHECK_STATE_MIXED}
                        label={translation('UseTransparency')}
                        onChange={onChange}
                        testid="alpha-mask-checkbox"
                    />
                </SingleGrid.Item>
            </SingleGrid>
        </div>
    )
}
