import { Wukong } from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { FC, RefObject, useCallback } from 'react'
import { DraggablePopupV2, MonoIconPanelMore16, Select, WKIconButton } from '../../../../../../../ui-lib/src'
import { cmdChangePopupState } from '../../../../../document/command/document-command'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'
import {
    DevModeCodeTypeLabels,
    DevModeCodeTypeLabelsV2,
    DevModeCodeTypeOptionsV2,
    useChangeDevModeInspectCodeType,
} from '../common/code-data'
import { CodeConfigComp } from '../dev-mode-inspect-code-config'
import CodeType = Wukong.DocumentProto.DevModeInspectCodeType

interface SettingDialogRightPanelPopupProps {
    position?: DOMRect
    visible: boolean
    codeType: CodeType
    onCancel: () => void
}

const SettingDialogRightPanelPopup: FC<SettingDialogRightPanelPopupProps> = ({
    position,
    visible,
    codeType,
    onCancel,
}) => {
    return (
        <DraggablePopupV2
            positionRightBase
            position={position}
            visible={visible}
            bodyClassName="px-0 py-3"
            footer={null}
            styleType={'editor'}
            onCancel={onCancel}
            header={DevModeCodeTypeLabels[codeType]}
            overflowVisible
        >
            <CodeConfigComp />
        </DraggablePopupV2>
    )
}

export const SettingDialogMainMenuPopup: FC = () => {
    const command = useCommand()

    const popupState = useViewState('popupState')
    const show = popupState?.type == Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_DEV_MODE_CODE_CONFIG_MAIN_MENU

    const closeCodeConfigDialog = useCallback(() => {
        command.invoke(cmdChangePopupState, {
            type: Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_NONE,
            reciprocalIndex: -1,
            multiPopup: [],
        })
    }, [command])

    const devModeInspectCodeTypeViewState = useViewState('devModeInspectCodeTypeViewState')
    const codeType = devModeInspectCodeTypeViewState?.codeType || CodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS

    return (
        <DraggablePopupV2
            positionRightBase
            width={320}
            visible={show}
            bodyClassName="px-0 py-3"
            footer={null}
            styleType={'editor'}
            onCancel={closeCodeConfigDialog}
            header={DevModeCodeTypeLabels[codeType]}
            overflowVisible
            style={{ zIndex: 11 }}
            testId="code-config-setting-dialog-popup"
        >
            <CodeConfigComp />
        </DraggablePopupV2>
    )
}

export function DevModeCodeTypePicker({ headRef }: { headRef: RefObject<HTMLDivElement> }) {
    const devModeInspectCodeTypeViewState = useViewState('devModeInspectCodeTypeViewState')
    const codeType = devModeInspectCodeTypeViewState?.codeType || CodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS
    const { changeType } = useChangeDevModeInspectCodeType()
    const popupState = useViewState('popupState')
    const show = popupState?.type == Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_DEV_MODE_CODE_CONFIG
    const command = useCommand()
    const toggleCodeConfigDialog = useCallback(() => {
        if (show) {
            command.invoke(cmdChangePopupState, {
                type: Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_NONE,
                reciprocalIndex: -1,
                multiPopup: [],
            })
            return
        }
        command.invoke(cmdChangePopupState, {
            type: Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_DEV_MODE_CODE_CONFIG,
            reciprocalIndex: -1,
            multiPopup: [],
        })
    }, [command, show])
    const closeCodeConfigDialog = useCallback(() => {
        command.invoke(cmdChangePopupState, {
            type: Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_NONE,
            reciprocalIndex: -1,
            multiPopup: [],
        })
    }, [command])
    const position = headRef.current?.getBoundingClientRect()
    return (
        <div className="flex items-center">
            <Select.MinimalMultiLevel
                label={DevModeCodeTypeLabelsV2[codeType]}
                dataTestIds={{
                    triggerFocus: 'code-type-select',
                }}
                onChange={(v) => {
                    const type = v.customNode?.value
                    if (!isNil(type)) {
                        changeType(type)
                    }
                }}
                weight="medium"
                items={DevModeCodeTypeOptionsV2}
            />
            <WKIconButton
                icon={<MonoIconPanelMore16 />}
                className="ml-2"
                onClick={toggleCodeConfigDialog}
                data-testid="dev-mode-inspect-code-config-btn"
                type={show ? 'deepBlue' : 'secondary'}
            />
            <SettingDialogRightPanelPopup
                position={position}
                visible={show}
                codeType={codeType}
                onCancel={closeCodeConfigDialog}
            />
        </div>
    )
}
