import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    Today: '今天',
    Yesterday: '昨天',
    Last: '最近',
    Days: '天',
    Custom: '自定义区间',
} as const

export const enTranslation = {
    Today: 'Today',
    Yesterday: 'Yesterday',
    Last: 'Last',
    Days: 'days',
    Custom: 'Custom',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
