import { PluginApiLoadFont, PluginApiLoadFontCallback, Wukong } from '@wukong/bridge-proto'
import { IPluginAPIContext } from '../plugin-api-context-interface'
import { Handle } from '../vm-interface'
import { ZodTypes } from '../zod-type'
import { IBasicBridge } from '../../kernel/bridge/basic-bridge'

type FontUid = string
const getFontUid = (fontName: Wukong.DocumentProto.IFontName): FontUid => {
    return fontName.family + '-' + fontName.style
}

export function createLoadFontImpl(bridge: IBasicBridge, context: IPluginAPIContext) {
    const loadFontCallbacks = new Map<string, (ret: Wukong.DocumentProto.IRet_APILoadFont) => void>()

    bridge.bind(PluginApiLoadFontCallback, (ret) => {
        const uid = getFontUid(ret.fontName)
        if (!loadFontCallbacks.has(uid)) {
            return
        }

        loadFontCallbacks.get(uid)?.(ret)
        loadFontCallbacks.delete(uid)
    })

    return {
        loadFontAsync: (fontName_: Handle) => {
            const { promise, resolve, reject } = context.vm.newPromise()
            const fontName = context.unwrapAndValidate({
                handle: fontName_,
                type: ZodTypes.FontName,
                key: 'loadFontAsync',
            })
            const handleLoadFontCallback = (value: Wukong.DocumentProto.IRet_APILoadFont) => {
                switch (value.status) {
                    case Wukong.DocumentProto.LoadFontStatus.LOAD_FONT_STATUS_LOADED:
                        resolve(context.vm.undefined)
                        break
                    case Wukong.DocumentProto.LoadFontStatus.LOAD_FONT_STATUS_FAILED:
                        reject(
                            context.vm.newString(`The font ${fontName.family} ${fontName.style} could not be loaded`)
                        )
                        break
                    case Wukong.DocumentProto.LoadFontStatus.LOAD_FONT_STATUS_LOADING:
                        throw new Error('should never happen')
                }
            }

            try {
                const ret = context.unwrapCallBridge(PluginApiLoadFont, {
                    family: fontName.family,
                    style: fontName.style,
                })

                switch (ret.status) {
                    case Wukong.DocumentProto.LoadFontStatus.LOAD_FONT_STATUS_LOADED:
                        resolve(context.vm.undefined)
                        break
                    case Wukong.DocumentProto.LoadFontStatus.LOAD_FONT_STATUS_LOADING:
                        loadFontCallbacks.set(getFontUid(fontName), handleLoadFontCallback)
                        break
                    case Wukong.DocumentProto.LoadFontStatus.LOAD_FONT_STATUS_FAILED:
                        throw new Error('should never happen')
                }
            } catch (e) {
                reject(context.vm.newString(`The font ${fontName.family} ${fontName.style} could not be loaded`))
            }

            return promise
        },
    }
}
