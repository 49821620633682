/* eslint-disable no-restricted-imports */
import { MouseEventHandler, PropsWithChildren, useMemo, useRef } from 'react'
import { DragInsertArea, useDragItem } from './context'
import styles from './index.module.less'
import useEvent, { ListenerType1 } from 'react-use/lib/useEvent'
import { isEqual } from 'lodash-es'
import { useViewState } from '../../../../../view-state-bridge'

interface DragItemProps<T> {
    item: T
}

/**
 * 用于包裹子组件的拖拽容器
 * @constructor
 */
export function DragItem<T>({ item, children }: PropsWithChildren<DragItemProps<T>>) {
    const { activeDragItem, setActiveDragItem, currentEnterItem, setCurrentEnterItem, onDragEnd } = useDragItem()

    const docReadonly = useViewState('docReadonly')

    const dragItemRef = useRef<HTMLDivElement>(null)

    const moveInDragItem = useMemo<boolean>(() => {
        return isEqual(activeDragItem, item)
    }, [activeDragItem, item])

    const moveInCurrentItem = useMemo<boolean>(() => {
        return isEqual(currentEnterItem?.item, item)
    }, [currentEnterItem, item])

    const handleMouseDown = () => {
        if (docReadonly) {
            return
        }
        setActiveDragItem(item)
    }

    const handleMouseMove: MouseEventHandler<HTMLDivElement> = (event) => {
        if (dragItemRef.current && activeDragItem) {
            // 如果在拖拽 item 自身区域移动，则忽略
            if (moveInDragItem) {
                return
            }
            const { pageY } = event
            const dragItemTop = dragItemRef.current.getBoundingClientRect().top
            const dragItemHeight = dragItemRef.current.offsetHeight
            // 如果在上半区
            if (pageY < dragItemTop + dragItemHeight / 2) {
                setCurrentEnterItem({
                    item,
                    insertArea: DragInsertArea.Before,
                })
            } else {
                // 在下半区
                setCurrentEnterItem({
                    item,
                    insertArea: DragInsertArea.After,
                })
            }
        }
    }

    const handleMouseUp = () => {
        // dragEnd
        onDragEnd()
        setActiveDragItem(undefined)
        setCurrentEnterItem({
            item: undefined,
            insertArea: DragInsertArea.None,
        })
    }

    // 响应区域外的 up 事件
    useEvent<ListenerType1>('mouseup', handleMouseUp)

    return (
        <div
            className={styles.dragItem}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            ref={dragItemRef}
        >
            {currentEnterItem?.insertArea === DragInsertArea.Before && moveInCurrentItem && (
                <div className={styles.insertBeforeLine}></div>
            )}
            {children}
            {currentEnterItem?.insertArea === DragInsertArea.After && moveInCurrentItem && (
                <div className={styles.insertAfterLine}></div>
            )}
        </div>
    )
}
