import classnames from 'classnames'
import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { IconArrowDown12 } from '../../../svg-icon/12/controls'
import { ExplicitUndefined } from '../../../utils/explicit-undefined'
import { TriggerContainer, TriggerContainerProps } from '../../selects/triggers/base-components/trigger-container'
import { TriggerFocus, TriggerFocusProps, TriggerFocusRef } from '../../selects/triggers/base-components/trigger-focus'
import { TriggerBaseProps, TriggerBaseRef } from '../../selects/triggers/type'
import { useCommonTrigger } from '../../selects/triggers/use-common-trigger'
import { TabElement } from '../../tab-element'
import { Tooltip, TooltipProps } from '../../tooltip'
import classes from './trigger-menu.module.less'

export interface TriggerMenuProps extends TriggerBaseProps {
    // TriggerContainerProps
    classNameWhenOpen?: TriggerContainerProps['classNameWhenOpen']
    isOpenPick: TriggerContainerProps['isOpenPick']
    // 私有配置
    label?: React.ReactNode
    tooltipProps?: TooltipProps
    labelMoveOut?: {
        isSelect?: boolean
        tooltipProps?: TooltipProps
        dataTestId?: string
        onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
    }
}
export interface TriggerMenuRef extends TriggerBaseRef {}

function _TriggerMenu(props: TriggerMenuProps, ref?: React.Ref<TriggerMenuRef>) {
    const {
        className,
        disabled,
        dataTestIds,
        openAction,
        closeAction,
        classNameWhenOpen,
        isOpenPick,
        label,
        tooltipProps,
        labelMoveOut,
        ...otherProps
    } = props
    const triggerRef = useRef<TriggerFocusRef>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const commonTrigger = useCommonTrigger({ isOpenPick, openAction, closeAction })

    useImperativeHandle(
        ref,
        () => ({
            triggerRect: () => triggerRef.current?.getContainer().getBoundingClientRect()!,
            focus: () => triggerRef.current?.getFocusElement().focus(),
            getContainer: () => containerRef.current!,
            getTriggerElement: () => triggerRef.current?.getContainer(),
        }),
        []
    )
    return (
        <TriggerContainer<ExplicitUndefined<TriggerContainerProps>>
            ref={containerRef}
            className={classnames(classes.template, [className], {
                [classes.disabled]: disabled,
                [classes.labelMoveOut]: labelMoveOut,
            })}
            isOpenPick={isOpenPick}
            classNameWhenOpen={classnames(classNameWhenOpen, classes.isOpenPick)}
            disabled={disabled}
            dataTestIds={dataTestIds}
            {...otherProps}
        >
            {labelMoveOut ? (
                <Tooltip
                    inactivation={labelMoveOut.tooltipProps?.inactivation ?? !labelMoveOut.tooltipProps?.title}
                    {...labelMoveOut.tooltipProps}
                    triggerRect={
                        labelMoveOut.tooltipProps?.triggerRect
                            ? labelMoveOut.tooltipProps.triggerRect
                            : () =>
                                  containerRef.current?.getBoundingClientRect() ?? {
                                      top: 0,
                                      left: 0,
                                      bottom: 0,
                                      right: 0,
                                  }
                    }
                >
                    <div
                        className={classnames(classes.label, classes.focus, {
                            [classes.isSelect]: labelMoveOut.isSelect,
                        })}
                        data-testid={labelMoveOut.dataTestId}
                        onClick={labelMoveOut.onClick}
                    >
                        {label}
                        <TabElement as="button" disabled={!labelMoveOut.onClick} />
                    </div>
                </Tooltip>
            ) : null}
            <Tooltip
                inactivation={tooltipProps?.inactivation ?? !tooltipProps?.title}
                {...tooltipProps}
                triggerRefKey="getContainer"
            >
                <TriggerFocus<ExplicitUndefined<TriggerFocusProps>>
                    ref={triggerRef}
                    className={classes.trigger}
                    dataTestIds={dataTestIds}
                    disabled={disabled}
                    onClick={commonTrigger.click}
                    onMouseDown={commonTrigger.mousedown}
                    onEnter={openAction}
                    onBlankSpace={openAction}
                    outlineMode={undefined}
                >
                    {labelMoveOut ? null : <div className={classes.label}>{label}</div>}
                    <span className={classes.arrow}>
                        <IconArrowDown12 />
                    </span>
                </TriggerFocus>
            </Tooltip>
            {props.children}
        </TriggerContainer>
    )
}

export const TriggerMenu = forwardRef(_TriggerMenu)
