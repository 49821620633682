import { IconArrowRight, MonoIconPanelTarget16, Select, Tooltip } from '../../../../../../ui-lib/src'
import { FontInfo, FontName } from '../../../../document/node/node'
import { getFontNameUid } from '../../../../document/util/font'
import { useFontManagerService } from '../../../context/document-context'
import { useRecentFonts } from '../../../context/recent-fonts-context'
import { useFontLocalizedFamily } from '../../design/common/use-font-localized-family'
import { FontMissIcon } from '../../design/text/font/font-miss-icon/font-miss-icon'
import { FontSelectInput } from '../../design/text/font/font-select-input'
import { CustomAxesStyleInfo, useFontStyleOptionList } from '../../design/text/use-font-style-option-list'
import styles from './font-miss-row.module.less'
import { translation } from './font-miss-row.translation'

interface FontMissRowProps {
    missFontNameUid: string
    missFontName: FontName
    usingCount: number
    targetFontName: FontName | undefined
    onSelectedFamily: (missFamily: string, family: string) => void
    onSelectedFontName: (missFamily: string, fontName: FontName) => void
    onInspectMissFont: (missFontName: FontName) => void
    onChangeExpand: ((expend: boolean) => void) | undefined
}

export function FontMissRow({
    missFontNameUid,
    missFontName,
    usingCount,
    targetFontName,
    onSelectedFamily,
    onSelectedFontName,
    onInspectMissFont,
    onChangeExpand,
}: FontMissRowProps) {
    const fontManagerService = useFontManagerService()
    const fonts = fontManagerService.states.use.allFontInfosState()
    const { recentFonts, updateRecentFont } = useRecentFonts()

    const onFontInfoChange = (info: FontInfo) => {
        onSelectedFamily(getFontNameUid(missFontName), info.family)
        updateRecentFont(info)
    }

    const missFontInfo = fonts.find((font) => font.family === missFontName.family)
    const isFamilyMissed = !missFontInfo

    // 未选择替换字体时，缺失字重所属字体的其他字重
    const candidateFontInfo = targetFontName
        ? fonts.find((font) => font.family === targetFontName?.family)
        : missFontInfo

    const onChangeFontStyle = (info: CustomAxesStyleInfo) => {
        const fontName = candidateFontInfo?.styles.find((item) => item.style === info.style)
        if (fontName) {
            onSelectedFontName(getFontNameUid(missFontName), fontName)
        }
    }

    const fontStyleOptionList = useFontStyleOptionList({
        fontInfo: candidateFontInfo ?? null,
        selectedFontName: targetFontName ?? null,
        customAxesStyle: '',
        showVariantsSetting: false,
        stylesInfo: [],
    })
    const selectedFamily = targetFontName ? targetFontName.family : isFamilyMissed ? '' : missFontName.family

    const selectedLocalizedFamily = targetFontName
        ? targetFontName.localizedFamily
        : isFamilyMissed
        ? ''
        : missFontName.localizedFamily

    const missFontLocalizedFamily = useFontLocalizedFamily(missFontName.family, missFontName.localizedFamily)

    return (
        <div className={styles.row} data-testid="font-miss-row">
            <div className={styles.left}>
                <Tooltip title={`${translation('Select')} ${usingCount} ${translation('UsingThisFont')}`}>
                    <MonoIconPanelTarget16
                        data-testid="icon-target-svg"
                        onMouseDown={(e) => e.stopPropagation()}
                        onClick={() => onInspectMissFont(missFontName)}
                    />
                </Tooltip>
                <div className={styles.missFont}>
                    {missFontLocalizedFamily}, {missFontName.localizedStyle}
                </div>
                <IconArrowRight />
            </div>
            <div className={styles.right}>
                <FontSelectInput
                    searchStringBelongTo={missFontNameUid}
                    className={styles.fontSelectInput}
                    selectedFamily={selectedFamily}
                    selectedLocalizedFamily={selectedLocalizedFamily}
                    missIcon={!targetFontName && isFamilyMissed}
                    recentFonts={recentFonts}
                    onChange={onFontInfoChange}
                    onChangeExpand={onChangeExpand}
                />
                <div className={styles.fontStyle}>
                    <Select.NormalSingleLevel
                        value={{
                            style: targetFontName?.style ?? '',
                            customAxesStyle: { name: '' },
                            isMissFontCustomStyle: !targetFontName && !isFamilyMissed,
                        }}
                        icon={!targetFontName && !isFamilyMissed ? <FontMissIcon /> : null}
                        disabled={!targetFontName && isFamilyMissed}
                        label={
                            targetFontName
                                ? targetFontName.localizedStyle
                                : isFamilyMissed
                                ? translation('Regular')
                                : missFontName.localizedStyle
                        }
                        dataTestIds={{
                            triggerFocus: 'font-style-select-trigger-focus',
                            container: 'font-style-select-container',
                        }}
                        onChange={onChangeFontStyle}
                        maxWidth={216}
                    >
                        {fontStyleOptionList}
                    </Select.NormalSingleLevel>
                </div>
            </div>
        </div>
    )
}
