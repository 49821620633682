import xxhash, { XXHash } from 'xxhash-wasm'

type Hash32Creator = (seed?: number | undefined) => XXHash<number>

export class XXHash32Writer {
    private xxHash32: XXHash<number>
    private buffer = new ArrayBuffer(4)
    private view = new DataView(this.buffer)
    private bytes1 = new Uint8Array(this.buffer, 0, 1)
    private bytes4 = new Uint8Array(this.buffer)

    private static hash32Creator: Hash32Creator | undefined
    private static hash32CreatorPromise: Promise<any> | undefined

    static ensureHash32Creator() {
        if (!XXHash32Writer.hash32CreatorPromise) {
            XXHash32Writer.hash32CreatorPromise = xxhash().then(({ create32 }) => {
                XXHash32Writer.hash32Creator = create32
            })
        }
        return XXHash32Writer.hash32CreatorPromise
    }

    constructor() {
        if (!XXHash32Writer.hash32Creator) {
            throw new Error("Can't init xxhash wasm")
        }
        this.xxHash32 = XXHash32Writer.hash32Creator(55817)
    }

    addBytes(value: Uint8Array) {
        this.xxHash32.update(value)
    }

    addUint8(value: number) {
        this.view.setUint8(0, value)
        this.xxHash32.update(this.bytes1)
    }

    addSint32(value: number) {
        this.view.setInt32(0, value, true)
        this.xxHash32.update(this.bytes4)
    }

    addUint32(value: number) {
        this.view.setUint32(0, value, true)
        this.xxHash32.update(this.bytes4)
    }

    addFloat(value: number) {
        this.view.setFloat32(0, value, true)
        this.xxHash32.update(this.bytes4)
    }

    addString(value: string) {
        this.xxHash32.update(value)
    }

    addNull() {
        // n
        this.addUint8(110)
    }

    beginObject() {
        // {
        this.addUint8(123)
    }

    endObject() {
        // }
        this.addUint8(125)
    }

    beginArray() {
        // [
        this.addUint8(91)
    }

    endArray() {
        // ]
        this.addUint8(93)
    }

    hash() {
        return this.xxHash32.digest()
    }
}
