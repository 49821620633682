import { translation } from './shadcn-sample-html.translation'

export const SampleHTML = (cssURL: string) => `
<link rel="stylesheet" href="${cssURL}">
<div class="root-c" style="display: none;">
  <div class="root-theme container py-6 mx-auto">
<section class="overflow-hidden rounded-[0.5rem] border bg-background shadow">
  <div class="md:hidden"><img alt="Dashboard" loading="lazy" width="1280" height="866" decoding="async"
      data-nimg="1" class="block dark:hidden"
      src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=32&h=32" style="color: transparent;"><img
      alt="Dashboard" loading="lazy" width="1280" height="866" decoding="async" data-nimg="1"
      class="hidden dark:block object-cover"
      src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=32&h=32" style="color: transparent;"></div>
  <div class="hidden flex-col md:flex">
    <div class="border-b">
      <div class="flex h-16 items-center px-8"><button
          class="inline-flex items-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&amp;_svg]:pointer-events-none [&amp;_svg]:size-4 [&amp;_svg]:shrink-0 border border-input bg-background shadow-sm text-primary hover:bg-accent hover:text-accent-foreground h-9 px-4 py-2 w-[200px] justify-between"
          role="combobox" aria-expanded="false" aria-label="Select a team" type="button" aria-haspopup="dialog"
          aria-controls="radix-:r95:" data-state="closed"><span
            class="relative flex shrink-0 overflow-hidden rounded-full mr-2 h-5 w-5"><img
              class="aspect-square h-full w-full object-cover" alt="Alicia Koch"
              src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=32&h=32"></span>${translation(
                  'Tech Analyst'
              )}<svg xmlns="http://www.w3.org/2000/svg"
            width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round"
            class="lucide lucide-chevrons-up-down ml-auto opacity-50">
            <path d="m7 15 5 5 5-5"></path>
            <path d="m7 9 5-5 5 5"></path>
          </svg></button>
        <nav class="flex items-center space-x-4 lg:space-x-6 mx-6"><a
            class="text-sm font-medium text-muted-foreground transition-colors hover:text-primary"
            href="">${translation('Overview')}</a><a
            class="text-sm font-medium text-muted-foreground transition-colors hover:text-primary"
            href="">${translation('Customers')}</a><a
            class="text-sm font-medium text-muted-foreground transition-colors hover:text-primary"
            href="">${translation('Products')}</a><a
            class="text-sm font-medium text-muted-foreground transition-colors hover:text-primary"
            href="">${translation('Settings')}</a></nav>
        <div class="ml-auto flex items-center space-x-4">
          <div><input
              class="flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm md:w-[100px] lg:w-[300px]"
              placeholder="${translation('Search')}" type="search"></div></div>
        </div>
      </div>
    </div>
    <div class="flex-1 space-y-4 p-8 pt-6">
      <div class="flex items-center justify-between space-y-2">
        <h2 class="text-3xl text-primary font-bold tracking-tight">${translation('Dashboard')}</h2>
        <div class="flex items-center space-x-2">
          <div class="grid gap-2"><button
              class="inline-flex items-center gap-2 whitespace-nowrap rounded-md text-sm transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&amp;_svg]:pointer-events-none [&amp;_svg]:size-4 [&amp;_svg]:shrink-0 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-9 px-4 py-2 w-[260px] justify-start text-left font-normal"
              id="date" type="button" aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-:r98:"
              data-state="closed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="lucide lucide-calendar mr-2 h-4 w-4">
                <path d="M8 2v4"></path>
                <path d="M16 2v4"></path>
                <rect width="18" height="18" x="3" y="4" rx="2"></rect>
                <path d="M3 10h18"></path>
              </svg>${translation('Jan 20, 2023 - Feb 09, 2023')}</button></div><button
            class="inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&amp;_svg]:pointer-events-none [&amp;_svg]:size-4 [&amp;_svg]:shrink-0 bg-primary text-primary-foreground shadow hover:bg-primary/90 h-9 px-4 py-2">${translation(
                'Download'
            )}</button>
        </div>
      </div>
      <div dir="ltr" data-orientation="horizontal" class="space-y-4">
        <div role="tablist" aria-orientation="horizontal"
          class="inline-flex h-9 items-center justify-center rounded-lg bg-muted p-1 text-muted-foreground"
          tabindex="0" data-orientation="horizontal" style="outline: none;"><button type="button" role="tab"
            aria-selected="true" aria-controls="radix-:r99:-content-overview" data-state="active"
            id="radix-:r99:-trigger-overview"
            class="inline-flex items-center justify-center whitespace-nowrap rounded-md px-3 py-1 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow"
            tabindex="-1" data-orientation="horizontal" data-radix-collection-item="">${translation(
                'Overview'
            )}</button><button
            type="button" role="tab" aria-selected="false" aria-controls="radix-:r99:-content-analytics"
            data-state="inactive" data-disabled="" disabled="" id="radix-:r99:-trigger-analytics"
            class="inline-flex items-center justify-center whitespace-nowrap rounded-md px-3 py-1 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow"
            tabindex="-1" data-orientation="horizontal" data-radix-collection-item="">${translation(
                'Analytics'
            )}</button><button
            type="button" role="tab" aria-selected="false" aria-controls="radix-:r99:-content-reports"
            data-state="inactive" data-disabled="" disabled="" id="radix-:r99:-trigger-reports"
            class="inline-flex items-center justify-center whitespace-nowrap rounded-md px-3 py-1 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow"
            tabindex="-1" data-orientation="horizontal" data-radix-collection-item="">${translation(
                'Reports'
            )}</button><button
            type="button" role="tab" aria-selected="false" aria-controls="radix-:r99:-content-notifications"
            data-state="inactive" data-disabled="" disabled="" id="radix-:r99:-trigger-notifications"
            class="inline-flex items-center justify-center whitespace-nowrap rounded-md px-3 py-1 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow"
            tabindex="-1" data-orientation="horizontal" data-radix-collection-item="">${translation(
                'Notifications'
            )}</button></div>
        <div data-state="active" data-orientation="horizontal" role="tabpanel"
          aria-labelledby="radix-:r99:-trigger-overview" id="radix-:r99:-content-overview" tabindex="0"
          class="mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 space-y-4"
          style="animation-duration: 0s;">
          <div class="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
            <div class="rounded-xl border bg-card text-card-foreground shadow">
              <div class="p-6 flex flex-row items-center justify-between space-y-0 pb-2">
                <div class="tracking-tight text-sm font-medium">${translation('Total Revenue')}</div><svg
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                  stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  class="h-4 w-4 text-muted-foreground">
                  <path d="M12 2v20M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                </svg>
              </div>
              <div class="p-6 pt-0">
                <div class="text-2xl font-bold">$45,231.89</div>
                <p class="text-xs text-muted-foreground">${translation('+20.1% from last month')}</p>
              </div>
            </div>
            <div class="rounded-xl border bg-card text-card-foreground shadow">
              <div class="p-6 flex flex-row items-center justify-between space-y-0 pb-2">
                <div class="tracking-tight text-sm font-medium">${translation('Subscriptions')}</div><svg
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                  stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  class="h-4 w-4 text-muted-foreground">
                  <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path>
                  <circle cx="9" cy="7" r="4"></circle>
                  <path d="M22 21v-2a4 4 0 0 0-3-3.87M16 3.13a4 4 0 0 1 0 7.75"></path>
                </svg>
              </div>
              <div class="p-6 pt-0">
                <div class="text-2xl font-bold">+2350</div>
                <p class="text-xs text-muted-foreground">${translation('+180.1% from last month')}</p>
              </div>
            </div>
            <div class="rounded-xl border bg-card text-card-foreground shadow">
              <div class="p-6 flex flex-row items-center justify-between space-y-0 pb-2">
                <div class="tracking-tight text-sm font-medium">${translation(
                    'Sales'
                )}</div><svg xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round"
                  stroke-linejoin="round" stroke-width="2" class="h-4 w-4 text-muted-foreground">
                  <rect width="20" height="14" x="2" y="5" rx="2"></rect>
                  <path d="M2 10h20"></path>
                </svg>
              </div>
              <div class="p-6 pt-0">
                <div class="text-2xl font-bold">+12,234</div>
                <p class="text-xs text-muted-foreground">${translation('+19% from last month')}</p>
              </div>
            </div>
            <div class="rounded-xl border bg-card text-card-foreground shadow">
              <div class="p-6 flex flex-row items-center justify-between space-y-0 pb-2">
                <div class="tracking-tight text-sm font-medium">${translation('Active Now')}</div><svg
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                  stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  class="h-4 w-4 text-muted-foreground">
                  <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                </svg>
              </div>
              <div class="p-6 pt-0">
                <div class="text-2xl font-bold">+573</div>
                <p class="text-xs text-muted-foreground">${translation('+201 since last hour')}</p>
              </div>
            </div>
          </div>
          <div class="grid gap-4 md:grid-cols-2 lg:grid-cols-7">
            <div class="rounded-xl border bg-card text-card-foreground shadow col-span-4">
              <div class="flex flex-col space-y-1.5 p-6">
                <div class="font-semibold leading-none tracking-tight">${translation('Overview')}</div>
              </div>
              <div class="p-6 pt-0 pl-2">
                <div class="recharts-responsive-container" style="width: 100%; height: 350px; min-width: 0px;">
                  <div class="recharts-wrapper"
                    style="position: relative; cursor: default; width: 100%; height: 100%; max-height: 350px; max-width: 771px;">
                    <svg class="recharts-surface" width="771" height="350" viewBox="0 0 771 350"
                      style="width: 100%; height: 100%;">
                      <title></title>
                      <desc></desc>
                      <defs>
                        <clipPath id="recharts55-clip">
                          <rect x="65" y="5" height="310" width="701"></rect>
                        </clipPath>
                      </defs>
                      <g class="recharts-layer recharts-cartesian-axis recharts-xAxis xAxis">
                        <g class="recharts-cartesian-axis-ticks">
                          <g class="recharts-layer recharts-cartesian-axis-tick"><text stroke="none" font-size="12"
                              orientation="bottom" width="701" height="30" x="94.20833333333333" y="323"
                              class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="middle"
                              fill="#888888">
                              <tspan x="94.20833333333333" dy="0.71em">Jan</tspan>
                            </text></g>
                          <g class="recharts-layer recharts-cartesian-axis-tick"><text stroke="none" font-size="12"
                              orientation="bottom" width="701" height="30" x="152.625" y="323"
                              class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="middle"
                              fill="#888888">
                              <tspan x="152.625" dy="0.71em">Feb</tspan>
                            </text></g>
                          <g class="recharts-layer recharts-cartesian-axis-tick"><text stroke="none" font-size="12"
                              orientation="bottom" width="701" height="30" x="211.04166666666666" y="323"
                              class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="middle"
                              fill="#888888">
                              <tspan x="211.04166666666666" dy="0.71em">Mar</tspan>
                            </text></g>
                          <g class="recharts-layer recharts-cartesian-axis-tick"><text stroke="none" font-size="12"
                              orientation="bottom" width="701" height="30" x="269.4583333333333" y="323"
                              class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="middle"
                              fill="#888888">
                              <tspan x="269.4583333333333" dy="0.71em">Apr</tspan>
                            </text></g>
                          <g class="recharts-layer recharts-cartesian-axis-tick"><text stroke="none" font-size="12"
                              orientation="bottom" width="701" height="30" x="327.87499999999994" y="323"
                              class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="middle"
                              fill="#888888">
                              <tspan x="327.87499999999994" dy="0.71em">May</tspan>
                            </text></g>
                          <g class="recharts-layer recharts-cartesian-axis-tick"><text stroke="none" font-size="12"
                              orientation="bottom" width="701" height="30" x="386.29166666666663" y="323"
                              class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="middle"
                              fill="#888888">
                              <tspan x="386.29166666666663" dy="0.71em">Jun</tspan>
                            </text></g>
                          <g class="recharts-layer recharts-cartesian-axis-tick"><text stroke="none" font-size="12"
                              orientation="bottom" width="701" height="30" x="444.7083333333333" y="323"
                              class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="middle"
                              fill="#888888">
                              <tspan x="444.7083333333333" dy="0.71em">Jul</tspan>
                            </text></g>
                          <g class="recharts-layer recharts-cartesian-axis-tick"><text stroke="none" font-size="12"
                              orientation="bottom" width="701" height="30" x="503.12499999999994" y="323"
                              class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="middle"
                              fill="#888888">
                              <tspan x="503.12499999999994" dy="0.71em">Aug</tspan>
                            </text></g>
                          <g class="recharts-layer recharts-cartesian-axis-tick"><text stroke="none" font-size="12"
                              orientation="bottom" width="701" height="30" x="561.5416666666666" y="323"
                              class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="middle"
                              fill="#888888">
                              <tspan x="561.5416666666666" dy="0.71em">Sep</tspan>
                            </text></g>
                          <g class="recharts-layer recharts-cartesian-axis-tick"><text stroke="none" font-size="12"
                              orientation="bottom" width="701" height="30" x="619.9583333333334" y="323"
                              class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="middle"
                              fill="#888888">
                              <tspan x="619.9583333333334" dy="0.71em">Oct</tspan>
                            </text></g>
                          <g class="recharts-layer recharts-cartesian-axis-tick"><text stroke="none" font-size="12"
                              orientation="bottom" width="701" height="30" x="678.375" y="323"
                              class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="middle"
                              fill="#888888">
                              <tspan x="678.375" dy="0.71em">Nov</tspan>
                            </text></g>
                          <g class="recharts-layer recharts-cartesian-axis-tick"><text stroke="none" font-size="12"
                              orientation="bottom" width="701" height="30" x="736.7916666666666" y="323"
                              class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="middle"
                              fill="#888888">
                              <tspan x="736.7916666666666" dy="0.71em">Dec</tspan>
                            </text></g>
                        </g>
                      </g>
                      <g class="recharts-layer recharts-cartesian-axis recharts-yAxis yAxis">
                        <g class="recharts-cartesian-axis-ticks">
                          <g class="recharts-layer recharts-cartesian-axis-tick"><text stroke="none" font-size="12"
                              orientation="left" width="60" height="310" x="57" y="315"
                              class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="end"
                              fill="#888888">
                              <tspan x="57" dy="0.355em">$0</tspan>
                            </text></g>
                          <g class="recharts-layer recharts-cartesian-axis-tick"><text stroke="none" font-size="12"
                              orientation="left" width="60" height="310" x="57" y="237.5"
                              class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="end"
                              fill="#888888">
                              <tspan x="57" dy="0.355em">$1500</tspan>
                            </text></g>
                          <g class="recharts-layer recharts-cartesian-axis-tick"><text stroke="none" font-size="12"
                              orientation="left" width="60" height="310" x="57" y="160"
                              class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="end"
                              fill="#888888">
                              <tspan x="57" dy="0.355em">$3000</tspan>
                            </text></g>
                          <g class="recharts-layer recharts-cartesian-axis-tick"><text stroke="none" font-size="12"
                              orientation="left" width="60" height="310" x="57" y="82.5"
                              class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="end"
                              fill="#888888">
                              <tspan x="57" dy="0.355em">$4500</tspan>
                            </text></g>
                          <g class="recharts-layer recharts-cartesian-axis-tick"><text stroke="none" font-size="12"
                              orientation="left" width="60" height="310" x="57" y="9"
                              class="recharts-text recharts-cartesian-axis-tick-value" text-anchor="end"
                              fill="#888888">
                              <tspan x="57" dy="0.355em">$6000</tspan>
                            </text></g>
                        </g>
                      </g>
                      <g class="recharts-layer recharts-bar fill-primary">
                        <g class="recharts-layer recharts-bar-rectangles">
                          <g class="recharts-layer">
                            <g class="recharts-layer recharts-bar-rectangle">
                              <path x="70.84166666666667" y="242.45999999999998" width="46"
                                height="72.54000000000002" radius="4,4,0,0" fill="currentColor"
                                class="recharts-rectangle fill-primary" name="Jan" d="M70.84166666666667,246.45999999999998A 4,4,0,0,1,74.84166666666667,242.45999999999998L 112.84166666666667,242.45999999999998A 4,4,0,0,1,
    116.84166666666667,246.45999999999998L 116.84166666666667,315L 70.84166666666667,315Z"></path>
                            </g>
                            <g class="recharts-layer recharts-bar-rectangle">
                              <path x="129.25833333333333" y="121.45666666666668" width="46"
                                height="193.54333333333332" radius="4,4,0,0" fill="currentColor"
                                class="recharts-rectangle fill-primary" name="Feb" d="M129.25833333333333,125.45666666666668A 4,4,0,0,1,133.25833333333333,121.45666666666668L 171.25833333333333,121.45666666666668A 4,4,0,0,1,
    175.25833333333333,125.45666666666668L 175.25833333333333,315L 129.25833333333333,315Z"></path>
                            </g>
                            <g class="recharts-layer recharts-bar-rectangle">
                              <path x="187.67499999999998" y="84.30833333333334" width="46"
                                height="230.69166666666666" radius="4,4,0,0" fill="currentColor"
                                class="recharts-rectangle fill-primary" name="Mar" d="M187.67499999999998,88.30833333333334A 4,4,0,0,1,191.67499999999998,84.30833333333334L 229.67499999999998,84.30833333333334A 4,4,0,0,1,
    233.67499999999998,88.30833333333334L 233.67499999999998,315L 187.67499999999998,315Z"></path>
                            </g>
                            <g class="recharts-layer recharts-bar-rectangle">
                              <path x="246.09166666666667" y="144.5" width="46" height="170.5" radius="4,4,0,0"
                                fill="currentColor" class="recharts-rectangle fill-primary" name="Apr" d="M246.09166666666667,148.5A 4,4,0,0,1,250.09166666666667,144.5L 288.0916666666667,144.5A 4,4,0,0,1,
    292.0916666666667,148.5L 292.0916666666667,315L 246.09166666666667,315Z"></path>
                            </g>
                            <g class="recharts-layer recharts-bar-rectangle">
                              <path x="304.50833333333327" y="182.32" width="46" height="132.68" radius="4,4,0,0"
                                fill="currentColor" class="recharts-rectangle fill-primary" name="May" d="M304.50833333333327,186.32A 4,4,0,0,1,308.50833333333327,182.32L 346.50833333333327,182.32A 4,4,0,0,1,
    350.50833333333327,186.32L 350.50833333333327,315L 304.50833333333327,315Z"></path>
                            </g>
                            <g class="recharts-layer recharts-bar-rectangle">
                              <path x="362.92499999999995" y="200.765" width="46" height="114.23500000000001"
                                radius="4,4,0,0" fill="currentColor" class="recharts-rectangle fill-primary"
                                name="Jun" d="M362.92499999999995,204.765A 4,4,0,0,1,366.92499999999995,200.765L 404.92499999999995,200.765A 4,4,0,0,1,
    408.92499999999995,204.765L 408.92499999999995,315L 362.92499999999995,315Z"></path>
                            </g>
                            <g class="recharts-layer recharts-bar-rectangle">
                              <path x="421.34166666666664" y="166.0966666666667" width="46"
                                height="148.9033333333333" radius="4,4,0,0" fill="currentColor"
                                class="recharts-rectangle fill-primary" name="Jul" d="M421.34166666666664,170.0966666666667A 4,4,0,0,1,425.34166666666664,166.0966666666667L 463.34166666666664,166.0966666666667A 4,4,0,0,1,
    467.34166666666664,170.0966666666667L 467.34166666666664,315L 421.34166666666664,315Z"></path>
                            </g>
                            <g class="recharts-layer recharts-bar-rectangle">
                              <path x="479.75833333333327" y="209.91" width="46" height="105.09" radius="4,4,0,0"
                                fill="currentColor" class="recharts-rectangle fill-primary" name="Aug" d="M479.75833333333327,213.91A 4,4,0,0,1,483.75833333333327,209.91L 521.7583333333332,209.91A 4,4,0,0,1,
    525.7583333333332,213.91L 525.7583333333332,315L 479.75833333333327,315Z"></path>
                            </g>
                            <g class="recharts-layer recharts-bar-rectangle">
                              <path x="538.175" y="105.64666666666665" width="46" height="209.35333333333335"
                                radius="4,4,0,0" fill="currentColor" class="recharts-rectangle fill-primary"
                                name="Sep" d="M538.175,109.64666666666665A 4,4,0,0,1,542.175,105.64666666666665L 580.175,105.64666666666665A 4,4,0,0,1,
    584.175,109.64666666666665L 584.175,315L 538.175,315Z"></path>
                            </g>
                            <g class="recharts-layer recharts-bar-rectangle">
                              <path x="596.5916666666667" y="179.73666666666668" width="46"
                                height="135.26333333333332" radius="4,4,0,0" fill="currentColor"
                                class="recharts-rectangle fill-primary" name="Oct" d="M596.5916666666667,183.73666666666668A 4,4,0,0,1,600.5916666666667,179.73666666666668L 638.5916666666667,179.73666666666668A 4,4,0,0,1,
    642.5916666666667,183.73666666666668L 642.5916666666667,315L 596.5916666666667,315Z"></path>
                            </g>
                            <g class="recharts-layer recharts-bar-rectangle">
                              <path x="655.0083333333333" y="132.15166666666667" width="46"
                                height="182.84833333333333" radius="4,4,0,0" fill="currentColor"
                                class="recharts-rectangle fill-primary" name="Nov" d="M655.0083333333333,136.15166666666667A 4,4,0,0,1,659.0083333333333,132.15166666666667L 697.0083333333333,132.15166666666667A 4,4,0,0,1,
    701.0083333333333,136.15166666666667L 701.0083333333333,315L 655.0083333333333,315Z"></path>
                            </g>
                            <g class="recharts-layer recharts-bar-rectangle">
                              <path x="713.425" y="92.05833333333334" width="46" height="222.94166666666666"
                                radius="4,4,0,0" fill="currentColor" class="recharts-rectangle fill-primary"
                                name="Dec" d="M713.425,96.05833333333334A 4,4,0,0,1,717.425,92.05833333333334L 755.425,92.05833333333334A 4,4,0,0,1,
    759.425,96.05833333333334L 759.425,315L 713.425,315Z"></path>
                            </g>
                          </g>
                        </g>
                        <g class="recharts-layer"></g>
                      </g>
                    </svg></div>
                </div>
              </div>
            </div>
            <div class="rounded-xl border bg-card text-card-foreground shadow col-span-3">
              <div class="flex flex-col space-y-1.5 p-6">
                <div class="font-semibold leading-none tracking-tight">${translation('Recent Sales')}</div>
                <div class="text-sm text-muted-foreground">${translation('You made 265 sales this month.')}</div>
              </div>
              <div class="p-6 pt-0">
                <div class="space-y-8">
                  <div class="flex items-center"><span
                      class="relative flex shrink-0 overflow-hidden rounded-full h-9 w-9"><img
                        class="aspect-square h-full w-full object-cover" alt="Avatar" src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?w=60&h=60"></span>
                    <div class="ml-4 space-y-1">
                      <p class="text-sm font-medium leading-none">${translation('Marcus Chen')}</p>
                      <p class="text-sm text-muted-foreground">${translation('marcus.chen@email.com')}</p>
                    </div>
                    <div class="ml-auto font-medium">${translation('+$1,999.00')}</div>
                  </div>
                  <div class="flex items-center"><span
                      class="relative shrink-0 overflow-hidden rounded-full flex h-9 w-9 items-center justify-center space-y-0 border"><img
                        class="aspect-square h-full w-full object-cover" alt="Avatar" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=60&h=60"></span>
                    <div class="ml-4 space-y-1">
                      <p class="text-sm font-medium leading-none">${translation('Emma Wilson')}</p>
                      <p class="text-sm text-muted-foreground">${translation('emma.wilson@email.com')}</p>
                    </div>
                    <div class="ml-auto font-medium">${translation('+$39.00')}</div>
                  </div>
                  <div class="flex items-center"><span
                      class="relative flex shrink-0 overflow-hidden rounded-full h-9 w-9"><img
                        class="aspect-square h-full w-full object-cover" alt="Avatar" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=60&h=60"></span>
                    <div class="ml-4 space-y-1">
                      <p class="text-sm font-medium leading-none">${translation('Ryan Park')}</p>
                      <p class="text-sm text-muted-foreground">${translation('ryan.park@email.com')}</p>
                    </div>
                    <div class="ml-auto font-medium">${translation('+$299.00')}</div>
                  </div>
                  <div class="flex items-center"><span
                      class="relative flex shrink-0 overflow-hidden rounded-full h-9 w-9"><img
                        class="aspect-square h-full w-full object-cover" alt="Avatar" src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=60&h=60"></span>
                    <div class="ml-4 space-y-1">
                      <p class="text-sm font-medium leading-none">${translation('Sarah Miller')}</p>
                      <p class="text-sm text-muted-foreground">${translation('sarah.miller@email.com')}</p>
                    </div>
                    <div class="ml-auto font-medium">${translation('+$99.00')}</div>
                  </div>
                  <div class="flex items-center"><span
                      class="relative flex shrink-0 overflow-hidden rounded-full h-9 w-9"><img
                        class="aspect-square h-full w-full object-cover" alt="Avatar" src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=60&h=60"></span>
                    <div class="ml-4 space-y-1">
                      <p class="text-sm font-medium leading-none">${translation('David Thompson')}</p>
                      <p class="text-sm text-muted-foreground">${translation('david.thompson@email.com')}</p>
                    </div>
                    <div class="ml-auto font-medium">${translation('+$39.00')}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  </div>
</div>`
