/* eslint-disable no-restricted-imports */
import { IconAdd16, Tooltip } from '../../../../../../ui-lib/src'
import React from 'react'
import { IconButton } from '../../atom/button/icon-button'
import classes from './style-title.module.less'

export interface StyleTitleProps {
    title: string
    tooltipTitle: string
    isReadonly: boolean
    onChickAddIcon: (e: React.MouseEvent<HTMLElement>) => void
}
export function StyleTitle(props: StyleTitleProps) {
    return (
        <div className={classes.title}>
            <span>{props.title}</span>
            {!props.isReadonly ? (
                <Tooltip title={props.tooltipTitle}>
                    <IconButton
                        className={classes.addIcon}
                        icon={<IconAdd16 />}
                        selected={false}
                        onClick={props.onChickAddIcon}
                    />
                </Tooltip>
            ) : null}
        </div>
    )
}
