import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    DetachStyle: '分离样式',
} as const

export const enTranslation = {
    DetachStyle: 'Detach style',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
