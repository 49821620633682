import { translation } from './batch-export-modal-trigger.translation'
/* eslint-disable no-restricted-imports */
import { UpdateBatchExportPopupStateCommandWasmCall } from '@wukong/bridge-proto'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { WKButton } from '../../../../../../ui-lib/src'
import { CommitType } from '../../../../document/command/commit-type'
import { useCommand, useCopyCheckService } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import style from './batch-export-modal-trigger.module.less'
import { ProhibitExportPanel } from './batch-export-prohibit-panel'

export interface BatchExportModalTriggerProps {}

const usePageName = () => {
    const { pages } = useViewState('documentPageList', { pages: [] })
    const pageId = useViewState('currentPageId')

    const pageName = useMemo(() => {
        if (Array.isArray(pages)) {
            return pages.find((page) => page.id === pageId)?.name
        }
    }, [pageId, pages])

    return pageName
}

const BatchExportModalTrigger = (_props: BatchExportModalTriggerProps) => {
    const command = useCommand()
    const pageName = usePageName()

    const handleOpenBatchExportModal = useCallback(() => {
        command.invokeBridge(CommitType.Noop, UpdateBatchExportPopupStateCommandWasmCall, {
            value: true,
        })
    }, [command])

    const [showProhibitExportPanel, setShowProhibitExportPanel] = useState<boolean | undefined>()

    const copyCheckService = useCopyCheckService()
    const copyHasBeenProhibited = copyCheckService.useZustandStore.use.copyHasBeenProhibited()
    useEffect(() => {
        setShowProhibitExportPanel(copyHasBeenProhibited)
    }, [copyHasBeenProhibited])
    if (showProhibitExportPanel === undefined) {
        return <></>
    }
    return (
        <div className={style['read-only-batch-export-container']}>
            {showProhibitExportPanel ? (
                <>
                    <ProhibitExportPanel />
                </>
            ) : (
                <>
                    <div className={`${style['read-only-batch-export-page-name']} wk-font-semibold text-black`}>
                        {pageName}
                    </div>
                    <WKButton
                        type="text"
                        onClick={handleOpenBatchExportModal}
                        className={`${style['read-only-batch-export-page-button']} wk-font-semibold wk-text-12`}
                    >
                        {translation('Export')}...
                    </WKButton>
                </>
            )}
        </div>
    )
}

export default BatchExportModalTrigger
