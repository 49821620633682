import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Apply: '应用',
    SelectALayer: '请选择一个图层',
    Linear: '线性变化',
    Sinusoidal: '正弦变化',
    Count: '重复次数',
    Position: '位移',
    Rotation: '旋转',
    Scale: '尺寸变化',
    Opacity: '不透明度变化',
    Fill: '填充变化',
    Stroke: '描边变化',
    Looper: '循环变化',
} as const

export const enTranslation = {
    Apply: 'Apply',
    SelectALayer: 'Select a layer',
    Linear: 'Linear',
    Sinusoidal: 'Sinusoidal',
    Count: 'Count',
    Position: 'Position',
    Rotation: 'Rotation',
    Scale: 'Scale',
    Opacity: 'Opacity',
    Fill: 'Fill',
    Stroke: 'Stroke',
    Looper: 'Looper',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
