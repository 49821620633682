/* eslint-disable no-restricted-imports */
import { ScrollView } from '../../../../../../ui-lib/src'
import { HistoryList } from '../history-list/history-list'
import { HistoryListProvider } from '../history-list/history-list-provider'
import { HistoryTitle } from '../history-title/history-title'
import { LoadMore } from '../load-more/load-more'
import { LoadingMask } from '../loading-mask/loading-mask'
import { UpgradeTip } from '../upgrade-tip/upgrade-tip'
import classes from './history-panel.module.less'
import { useHistoryPanel } from './use-history-panel'

export function HistoryPanel() {
    const {
        items,
        currentItem,
        currentHistoryVersionId,
        isDifferentSelectSort,
        selectSortFilter,
        loading,
        isFinish,
        existsBeyondThresholdVersion,
        isLoadMoreLoading,
        isReadonly,
        addNewVersion,
        closeHistoryPanel,
        onChangeSort,
        clickVersion,
        editVersionInfo,
        clearVersionInfo,
        applyVersion,
        copyVersion,
        copyVersionLink,
        onClickLoadMore,
    } = useHistoryPanel()

    return (
        <div className={classes.historyPanel}>
            <HistoryTitle
                isReadonly={isReadonly}
                isDifferentSelectSort={isDifferentSelectSort}
                selectSort={selectSortFilter}
                onClickAdd={addNewVersion}
                onClickClose={closeHistoryPanel}
                onChangeSort={onChangeSort}
            />
            <ScrollView className={classes.listContainer} selectKey={currentHistoryVersionId} block="nearest">
                <HistoryListProvider
                    selectVersionId={currentHistoryVersionId}
                    isReadonly={isReadonly}
                    clickVersion={clickVersion}
                    editVersionInfo={editVersionInfo}
                    clearVersionInfo={clearVersionInfo}
                    applyVersion={applyVersion}
                    copyVersion={copyVersion}
                    copyVersionLink={copyVersionLink}
                >
                    <HistoryList items={items} currentItem={currentItem} dataTestIds={{ container: 'history-list' }} />
                </HistoryListProvider>
                <LoadMore isVisible={!isFinish} isLoading={isLoadMoreLoading} onClick={onClickLoadMore} />
                <UpgradeTip existsBeyondThresholdVersion={existsBeyondThresholdVersion} />
            </ScrollView>
            <LoadingMask isLoading={loading} />
        </div>
    )
}
