import { EditorMode } from '../../../../document/node/node'
import { useViewState } from '../../../../view-state-bridge'
import { DesignModeTool, DevModeTool } from './normal-tool'
import { PlaceImageTool } from './place-image-tool'
import { ReadonlyTool } from './readonly-tool'
import { useToolbar } from './use-toolbar'
import { VectorTool } from './vector-tool'
import { DesignModeViewHistoryTool, DevModeViewHistoryTool } from './view-history-tool'

export function DesignModeToolbar() {
    const { editorMode, isEditVector } = useToolbar()
    const docReadonly = useViewState('docReadonly')
    const isHistoryMode = useViewState('historyMode')

    if (isHistoryMode) {
        return <DesignModeViewHistoryTool />
    }

    if (docReadonly) {
        return <ReadonlyTool />
    }

    if (isEditVector) {
        return <VectorTool />
    }

    if (editorMode === EditorMode.EM_PlaceImage) {
        return <PlaceImageTool />
    }

    return <DesignModeTool />
}

export function DevModeToolbar() {
    const isHistoryMode = useViewState('historyMode')
    if (isHistoryMode) {
        return <DevModeViewHistoryTool />
    }
    return <DevModeTool />
}
