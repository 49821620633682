import { SVGAttributes, useMemo, forwardRef } from 'react'
export const MulticolorIconCommonFigmaFile32 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 6 4)"
                d="M0 1C0 0.447715 0.447715 0 1 0L13.5858 0C13.851 0 14.1054 0.105357 14.2929 0.292893L19.7071 5.70711C19.8946 5.89464 20 6.149 20 6.41421L20 23C20 23.5523 19.5523 24 19 24L1 24C0.447715 24 0 23.5523 0 23L0 1Z"
                fillRule="nonzero"
                fill="rgb(230, 232, 234)"
            />
            <defs>
                <mask id={ids[0]} style={{ maskType: 'alpha' }}>
                    <path
                        transform="matrix(1 0 0 1 6 4)"
                        d="M0 1C0 0.447715 0.447715 0 1 0L13.5858 0C13.851 0 14.1054 0.105357 14.2929 0.292893L19.7071 5.70711C19.8946 5.89464 20 6.149 20 6.41421L20 23C20 23.5523 19.5523 24 19 24L1 24C0.447715 24 0 23.5523 0 23L0 1Z"
                        fillRule="nonzero"
                        fill="rgb(0, 0, 0)"
                    />
                </mask>
            </defs>
            <g mask={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 18 4)"
                    d="M2 1C2 0.447715 1.55228 0 1 0L0 0L8 0L8 8L8 7C8 6.44772 7.55228 6 7 6L3 6C2.44772 6 2 5.55228 2 5L2 1Z"
                    fillRule="nonzero"
                    fill="rgb(218, 220, 224)"
                />
            </g>
            <path
                transform="matrix(1 0 0 1 16 14.9999)"
                d="M0 2.50001C0 1.1215 1.1215 0 2.5 0C3.8785 0 5 1.1215 5 2.50001C5 3.87852 3.8785 5.00002 2.5 5.00002C1.1215 5.00002 0 3.87852 0 2.50001Z"
                fillRule="nonzero"
                fill="rgb(26, 188, 254)"
            />
            <path
                transform="matrix(1 0 0 1 11 20.0001)"
                d="M0 2.50001C0 1.1215 1.12676 0 2.51174 0L5 0L5 2.50001C5 3.87852 3.87324 5.00002 2.48826 5.00002C1.10329 5.00002 0 3.87852 0 2.50001L0 2.50001Z"
                fillRule="nonzero"
                fill="rgb(10, 207, 131)"
            />
            <path
                transform="matrix(1 0 0 1 16 10)"
                d="M0 0L0 5.00002L2.5 5.00002C3.8785 5.00002 5 3.87326 5 2.48827C5 1.10329 3.8785 0 2.5 0L0 0Z"
                fillRule="nonzero"
                fill="rgb(255, 114, 98)"
            />
            <path
                transform="matrix(1 0 0 1 11 10)"
                d="M0 2.50001C0 3.87852 1.12676 5.00002 2.51174 5.00002L5 5.00002L5 0L2.48826 0C1.10329 0 0 1.1215 0 2.50001L0 2.50001Z"
                fillRule="nonzero"
                fill="rgb(242, 78, 30)"
            />
            <path
                transform="matrix(1 0 0 1 11 14.9999)"
                d="M0 2.50001C0 3.87852 1.12676 5.00002 2.51174 5.00002L5 5.00002L5 0L2.48826 0C1.10329 0 0 1.1215 0 2.50001L0 2.50001Z"
                fillRule="nonzero"
                fill="rgb(162, 89, 255)"
            />
        </svg>
    )
})
export const MulticolorIconCommonComponentsFile32 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 6 4)"
                d="M0 1C0 0.447715 0.447715 0 1 0L13.5858 0C13.851 0 14.1054 0.105357 14.2929 0.292893L19.7071 5.70711C19.8946 5.89464 20 6.149 20 6.41421L20 23C20 23.5523 19.5523 24 19 24L1 24C0.447715 24 0 23.5523 0 23L0 1Z"
                fillRule="nonzero"
                fill="rgb(205, 95, 255)"
            />
            <defs>
                <mask id={ids[0]} style={{ maskType: 'alpha' }}>
                    <path
                        transform="matrix(1 0 0 1 6 4)"
                        d="M0 1C0 0.447715 0.447715 0 1 0L13.5858 0C13.851 0 14.1054 0.105357 14.2929 0.292893L19.7071 5.70711C19.8946 5.89464 20 6.149 20 6.41421L20 23C20 23.5523 19.5523 24 19 24L1 24C0.447715 24 0 23.5523 0 23L0 1Z"
                        fillRule="nonzero"
                        fill="rgb(0, 0, 0)"
                    />
                </mask>
            </defs>
            <g mask={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 17 4)"
                    d="M0 0L1.90214 0L0 0ZM8.96924 0L1.90214 0C2.55228 0 3 0.447715 3 1L3 5C3 5.55228 3.44772 6 4 6L8.00008 6C8.51171 6 8.9336 6.38422 8.99294 6.87987L8.96924 0ZM9.00024 9.00024L9.00008 6.99992C9.00008 6.95929 8.99765 6.91923 8.99294 6.87987L9.00024 9.00024Z"
                    fillRule="nonzero"
                    fill="rgb(255, 255, 255)"
                    opacity="0.3"
                />
            </g>
            <path
                transform="matrix(1 0 0 1 9 12.0138)"
                d="M12.1902 0.348181C11.5528 0.0625582 10.8562 -0.0473362 10.1005 0.0184977C9.41295 0.078385 8.75307 0.274738 8.12079 0.607556C7.68816 0.835285 7.31277 1.10326 6.9946 1.41148C6.67695 1.10332 6.30265 0.835447 5.87169 0.607866C5.2411 0.274863 4.58305 0.078411 3.89754 0.0185095C3.14388 -0.0473474 2.44862 0.0625701 1.81178 0.348262C1.09869 0.668153 0.520096 1.18344 0.0759838 1.89412L0 2.15909L0 11.5614L0.859905 11.9085C1.05211 11.7092 1.30557 11.5309 1.62027 11.3735C2.01026 11.1784 2.43428 11.0508 2.89232 10.9907C4.11333 10.8304 5.39331 11.1626 6.73224 11.9872L7.25663 11.9872C8.59569 11.1625 9.87757 10.8304 11.1023 10.9907C11.5619 11.0509 11.9872 11.1786 12.3785 11.3737C12.6943 11.5312 12.9482 11.7095 13.1401 11.9085L14 11.5614L14 2.15909L13.9247 1.89518C13.4827 1.18398 12.9045 0.668316 12.1902 0.348181ZM11.2321 9.99919C10.0145 9.83975 8.76859 10.0771 7.49444 10.7111L7.49444 2.3372C7.77915 2.00742 8.1432 1.72584 8.58659 1.49245C9.1 1.2222 9.63355 1.06296 10.1872 1.01473C10.7705 0.963916 11.3018 1.04592 11.7812 1.26074C12.2707 1.48011 12.677 1.82879 13 2.30677L13 10.5707C12.9431 10.5393 12.8847 10.5087 12.8248 10.4789C12.3335 10.2338 11.8026 10.0739 11.2321 9.99919ZM6.49444 2.33715C6.20968 2.00708 5.84645 1.7254 5.40473 1.49214C4.89324 1.22203 4.36183 1.06289 3.81049 1.01471C3.22955 0.963949 2.69974 1.04593 2.22108 1.26066C1.73193 1.48009 1.32491 1.82902 1 2.30743L1 10.57C1.05619 10.539 1.11384 10.5087 1.17296 10.4791C1.66323 10.2339 2.19297 10.0739 2.76217 9.99921C3.97675 9.83978 5.22084 10.0771 6.49444 10.7111L6.49444 2.33715Z"
                fillRule="evenodd"
                fill="rgb(255, 255, 255)"
            />
        </svg>
    )
})
export const MulticolorIconCommonSketchFile32 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 2 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 6 4)"
                d="M0 1C0 0.447715 0.447715 0 1 0L13.5858 0C13.851 0 14.1054 0.105357 14.2929 0.292893L19.7071 5.70711C19.8946 5.89464 20 6.149 20 6.41421L20 23C20 23.5523 19.5523 24 19 24L1 24C0.447715 24 0 23.5523 0 23L0 1Z"
                fillRule="nonzero"
                fill="rgb(230, 232, 234)"
            />
            <defs>
                <mask id={ids[0]} style={{ maskType: 'alpha' }}>
                    <path
                        transform="matrix(1 0 0 1 6 4)"
                        d="M0 1C0 0.447715 0.447715 0 1 0L13.5858 0C13.851 0 14.1054 0.105357 14.2929 0.292893L19.7071 5.70711C19.8946 5.89464 20 6.149 20 6.41421L20 23C20 23.5523 19.5523 24 19 24L1 24C0.447715 24 0 23.5523 0 23L0 1Z"
                        fillRule="nonzero"
                        fill="rgb(0, 0, 0)"
                    />
                </mask>
            </defs>
            <g mask={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 18 4)"
                    d="M2 1C2 0.447715 1.55228 0 1 0L0 0L8 0L8 8L8 7C8 6.44772 7.55228 6 7 6L3 6C2.44772 6 2 5.55228 2 5L2 1Z"
                    fillRule="nonzero"
                    fill="rgb(218, 220, 224)"
                />
            </g>
            <defs>
                <clipPath id={ids[1]}>
                    <path
                        transform="matrix(1 0 0 1 5.99976 6.99951)"
                        d="M2.50006 0C1.11932 0 0 1.11932 0 2.50006L0 17.5004C0 18.8812 1.11932 20.0005 2.50006 20.0005L17.5004 20.0005C18.8812 20.0005 20.0005 18.8812 20.0005 17.5004L20.0005 2.50006C20.0005 1.11932 18.8812 0 17.5004 0L2.50006 0Z"
                        fillRule="nonzero"
                    />
                </clipPath>
            </defs>
            <g clipPath={`url(#${ids[1]})`}>
                <path
                    transform="matrix(1 0 0 1 8.12475 10.124)"
                    d="M7.87528 14.3188L0 5.13536L3.40372 0.485099L7.87528 0L12.3468 0.485099L15.7506 5.13536L7.87528 14.3188Z"
                    fillRule="nonzero"
                    fill="rgb(255, 174, 0)"
                />
                <path
                    transform="matrix(1 0 0 1 8.12475 15.2572)"
                    d="M7.87528 9.18342L0 0L15.7506 0L7.87528 9.18342Z"
                    fillRule="nonzero"
                    fill="rgb(236, 108, 0)"
                />
                <path
                    transform="matrix(1 0 0 1 11.3099 15.2572)"
                    d="M4.68846 9.18342L0 0L9.37693 0L4.68846 9.18342Z"
                    fillRule="nonzero"
                    fill="rgb(255, 174, 0)"
                />
                <path
                    transform="matrix(1 0 0 1 11.3099 10.124)"
                    d="M4.68846 0L0 5.13536L9.37693 5.13536L4.68846 0Z"
                    fillRule="nonzero"
                    fill="rgb(255, 239, 180)"
                />
                <path
                    transform="matrix(1 0 0 1 8.12475 10.6098)"
                    d="M3.40372 0L1.65181 2.34186L0 4.65026L3.22019 4.65026L3.40372 0ZM12.3468 0L14.0988 2.34186L15.7506 4.65026L12.5304 4.65026L12.3468 0Z"
                    fillRule="nonzero"
                    fill="rgb(255, 174, 0)"
                />
                <path
                    transform="matrix(1 0 0 1 11.3099 10.124)"
                    d="M0.216904 0.485099L0 5.13536L4.68846 0L0.216904 0.485099ZM9.16002 0.485099L9.37693 5.13536L4.68846 0L9.16002 0.485099Z"
                    fillRule="nonzero"
                    fill="rgb(254, 211, 5)"
                />
            </g>
        </svg>
    )
})
export const MulticolorIconCommonFont32 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 6 4)"
                d="M0 1C0 0.447715 0.447715 0 1 0L13.5858 0C13.851 0 14.1054 0.105357 14.2929 0.292893L19.7071 5.70711C19.8946 5.89464 20 6.149 20 6.41421L20 23C20 23.5523 19.5523 24 19 24L1 24C0.447715 24 0 23.5523 0 23L0 1Z"
                fillRule="nonzero"
                fill="rgb(230, 232, 234)"
            />
            <defs>
                <mask id={ids[0]} style={{ maskType: 'alpha' }}>
                    <path
                        transform="matrix(1 0 0 1 6 4)"
                        d="M0 1C0 0.447715 0.447715 0 1 0L13.5858 0C13.851 0 14.1054 0.105357 14.2929 0.292893L19.7071 5.70711C19.8946 5.89464 20 6.149 20 6.41421L20 23C20 23.5523 19.5523 24 19 24L1 24C0.447715 24 0 23.5523 0 23L0 1Z"
                        fillRule="nonzero"
                        fill="rgb(0, 0, 0)"
                    />
                </mask>
            </defs>
            <g mask={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 18 4)"
                    d="M2 1C2 0.447715 1.55228 0 1 0L0 0L8 0L8 8L8 7C8 6.44772 7.55228 6 7 6L3 6C2.44772 6 2 5.55228 2 5L2 1Z"
                    fillRule="nonzero"
                    fill="rgb(218, 220, 224)"
                />
            </g>
            <path
                transform="matrix(1 0 0 1 9 5)"
                d="M6.06831 6.00244L2.24702 16L3.99702 16L4.9062 13.4946L9.09321 13.4946L10.0024 16L11.7524 16L7.9311 6.00244L6.06831 6.00244ZM5.39838 12.1514L6.97749 7.76953L7.03559 7.76953L8.60444 12.1514L5.39838 12.1514Z"
                fillRule="nonzero"
                fill="rgb(130, 138, 153)"
            />
        </svg>
    )
})
export const MulticolorIconCommonUnknown32 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 6 4)"
                d="M0 1C0 0.447715 0.447715 0 1 0L13.5858 0C13.851 0 14.1054 0.105357 14.2929 0.292893L19.7071 5.70711C19.8946 5.89464 20 6.149 20 6.41421L20 23C20 23.5523 19.5523 24 19 24L1 24C0.447715 24 0 23.5523 0 23L0 1Z"
                fillRule="nonzero"
                fill="rgb(230, 232, 234)"
            />
            <defs>
                <mask id={ids[0]} style={{ maskType: 'alpha' }}>
                    <path
                        transform="matrix(1 0 0 1 6 4)"
                        d="M0 1C0 0.447715 0.447715 0 1 0L13.5858 0C13.851 0 14.1054 0.105357 14.2929 0.292893L19.7071 5.70711C19.8946 5.89464 20 6.149 20 6.41421L20 23C20 23.5523 19.5523 24 19 24L1 24C0.447715 24 0 23.5523 0 23L0 1Z"
                        fillRule="nonzero"
                        fill="rgb(0, 0, 0)"
                    />
                </mask>
            </defs>
            <g mask={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 18 4)"
                    d="M2 1C2 0.447715 1.55228 0 1 0L0 0L8 0L8 8L8 7C8 6.44772 7.55228 6 7 6L3 6C2.44772 6 2 5.55228 2 5L2 1Z"
                    fillRule="nonzero"
                    fill="rgb(218, 220, 224)"
                />
            </g>
            <path
                transform="matrix(1 0 0 1 9 5)"
                d="M7.15456 5.80762C6.11892 5.80762 5.30544 6.10156 4.70388 6.68945C4.08865 7.27734 3.7947 8.09082 3.7947 9.12646L5.39089 9.12646C5.39089 8.5249 5.50368 8.06348 5.73952 7.73877C6.0198 7.36279 6.45388 7.18164 7.05544 7.18164C7.53054 7.18164 7.90993 7.30469 8.17654 7.57129C8.42947 7.83789 8.5696 8.20361 8.5696 8.66504C8.5696 9.01367 8.44314 9.34863 8.19021 9.65967L8.02273 9.85449C7.11355 10.6646 6.56667 11.2524 6.38552 11.6318C6.18728 12.0112 6.10525 12.4727 6.10525 13.0024L6.10525 13.2007L7.71511 13.2007L7.71511 13.0024C7.71511 12.6675 7.78347 12.3599 7.92361 12.0796C8.05007 11.8267 8.23122 11.5908 8.48415 11.3926C9.15749 10.8047 9.56081 10.4287 9.68728 10.2886C10.0257 9.84082 10.2068 9.2666 10.2068 8.56592C10.2068 7.71143 9.92654 7.0415 9.36599 6.54932C8.80544 6.04688 8.06374 5.80762 7.15456 5.80762ZM6.90163 13.8877C6.59402 13.8877 6.34109 13.9834 6.14626 14.1816C5.92068 14.3765 5.82497 14.6294 5.82497 14.937C5.82497 15.2446 5.92068 15.4976 6.14626 15.6924C6.34109 15.8872 6.59402 16 6.90163 16C7.20925 16 7.46218 15.9009 7.68435 15.7061C7.88259 15.5112 7.99538 15.2446 7.99538 14.937C7.99538 14.6294 7.88259 14.3765 7.68435 14.1816C7.47585 13.9834 7.20925 13.8877 6.90163 13.8877Z"
                fillRule="nonzero"
                fill="rgb(130, 138, 153)"
            />
        </svg>
    )
})
export const MulticolorIconCommonFileNew32 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 6 3.99951)"
                d="M0 1C0 0.447715 0.447715 0 1 0L12.8731 0C13.1678 0 13.4504 0.117063 13.6588 0.325437L19.6746 6.34123C19.8829 6.5496 20 6.83222 20 7.1269L20 23C20 23.5523 19.5523 24 19 24L1 24C0.447715 24 0 23.5523 0 23L0 1Z"
                fillRule="nonzero"
                fill="rgb(48, 113, 255)"
            />
            <path
                transform="matrix(1 0 0 1 18.5607 3.99951)"
                d="M0.773736 2.23724L0.7736 0.77349C0.7736 0.346303 0.427297 0 0.000110507 0L0.274846 0C0.594276 0 0.900622 0.126893 1.12649 0.352764L7.08762 6.31389C7.31349 6.53977 7.44039 6.84612 7.44038 7.16556L7.4401 7.43999C7.4401 7.01281 7.0938 6.6665 6.66661 6.6665L2.86216 6.66658C1.70876 6.66658 0.773736 5.73156 0.773736 4.57816L0.773736 2.23724Z"
                fillRule="evenodd"
                fill="rgb(118, 176, 255)"
                fillOpacity="0.6"
            />
            <path
                transform="matrix(1 0 0 1 10.5 11)"
                d="M6.8675 5.61667C6.42806 5.09296 5.74369 4.90324 5.1518 5.0849L0.885154 0.000109467C2.9844 0.55905 7.90081 2.00585 9.25305 3.61739C10.4151 5.00229 10.7114 6.81425 10.1865 8.35719L11.3592 9.75479C11.5219 9.94861 11.4966 10.2376 11.3028 10.4002L8.43209 12.809C8.23826 12.9716 7.9493 12.9464 7.78666 12.7525L6.6141 11.3551C5.00332 11.6043 3.27011 10.9979 2.10792 9.61287C0.758803 8.00505 0.187231 2.93191 -3.65814e-05 0.75902L4.25914 5.83491C3.97849 6.38601 4.0468 7.092 4.48579 7.61516C5.06379 8.304 6.06551 8.41503 6.7232 7.86316C7.3809 7.31129 7.4455 6.3055 6.8675 5.61667Z"
                fillRule="evenodd"
                fill="rgb(255, 255, 255)"
            />
        </svg>
    )
})
export const MulticolorIconCommonFileCreateNew32 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 2 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" ref={ref} {...props}>
            <defs>
                <linearGradient
                    id={ids[0]}
                    gradientTransform="matrix(0.000331547 22.7998 -21.1169 -0.922504 23.2247 -1.9603)"
                    gradientUnits="userSpaceOnUse"
                    x1="0"
                    y1="0.5"
                    x2="1"
                    y2="0.5"
                >
                    <stop offset="0.10219713" stopColor="rgb(85, 141, 252)" />
                    <stop offset="1" stopColor="rgb(35, 88, 251)" />
                </linearGradient>
            </defs>
            <path
                transform="matrix(1 0 0 1 4 3.33333)"
                d="M0 2C0 0.895431 0.895431 0 2 0L16.1716 0C16.702 0 17.2107 0.210714 17.5858 0.585787L23.4142 6.41421C23.7893 6.78928 24 7.298 24 7.82843L24 23.3333C24 24.4379 23.1046 25.3333 22 25.3333L2 25.3333C0.895431 25.3333 0 24.4379 0 23.3333L0 2Z"
                fillRule="nonzero"
                fill={`url(#${ids[0]})`}
            />
            <defs>
                <mask id={ids[1]} style={{ maskType: 'alpha' }}>
                    <path
                        transform="matrix(0.766044 -0.642788 0.642788 0.766044 5.61743 12.2839)"
                        d="M10.5568 10.1349C10.5568 12.1812 9.49535 13.9677 7.91767 14.9236L7.91767 16.989C7.91767 17.2753 7.68552 17.5075 7.39915 17.5075L3.1578 17.5075C2.87143 17.5075 2.63928 17.2753 2.63928 16.989L2.63928 14.9237C1.06152 13.9679 0 12.1812 0 10.1349C0 7.75947 3.19508 2.9453 4.61358 0.925035L4.61358 8.4254C3.96941 8.69909 3.51513 9.36084 3.51513 10.1337C3.51513 11.1515 4.30287 11.9765 5.27459 11.9765C6.24632 11.9765 7.03406 11.1515 7.03406 10.1337C7.03406 9.35986 6.57863 8.69742 5.93318 8.42437L5.93318 0.910742C7.34657 2.9225 10.5568 7.75385 10.5568 10.1349ZM5.27866 -1.47939e-15Z"
                        fillRule="evenodd"
                        fill="rgb(83, 203, 255)"
                    />
                </mask>
            </defs>
            <g mask={`url(#${ids[1]})`}>
                <path
                    transform="matrix(0.766044 -0.642788 0.642788 0.766044 5.78098 12.6268)"
                    d="M0 0L11.4902 0L11.4902 17.6371L0 17.6371L0 0Z"
                    fillRule="nonzero"
                    fill="rgb(255, 255, 255)"
                    fillOpacity="0.7"
                />
                <path
                    transform="matrix(0.766044 -0.642788 0.642788 0.766044 9.98815 9.09666)"
                    d="M0 0L5.99725 0L5.99725 17.6371L0 17.6371L0 0Z"
                    fillRule="nonzero"
                    fill="rgb(255, 255, 255)"
                />
                <path
                    transform="matrix(0.766044 -0.642788 0.642788 0.766044 14.6667 24.3174)"
                    d="M2.05361 0L10.7312 0.0291447L10.866 3.21296L0 3.21296L2.05361 0Z"
                    fillRule="nonzero"
                    fill="rgb(160, 186, 252)"
                    fillOpacity="0.47"
                />
            </g>
            <path
                transform="matrix(1 0 0 1 19.9261 3.33333)"
                d="M0.740741 0.740741C0.740741 0.331641 0.4091 0 0 0L0.245502 0C0.775935 0 1.28464 0.210714 1.65972 0.585786L7.48814 6.41421C7.86321 6.78928 8.07393 7.298 8.07393 7.82843L8.07393 8.07287C8.07328 7.66432 7.74189 7.33333 7.33319 7.33333L2.7406 7.33333C1.63603 7.33333 0.740596 6.4379 0.740596 5.33333L0.740741 0.740741Z"
                fillRule="evenodd"
                fill="rgb(118, 176, 255)"
                fillOpacity="0.6"
            />
        </svg>
    )
})
export const MulticolorIconCommonFileImporting32 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 2 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 8 4)"
                d="M0 1.48148C0 0.663282 0.663282 0 1.48148 0L18 0C19.1046 0 20 0.89543 20 2L20 7.40741C20 8.22561 19.3367 8.88889 18.5185 8.88889L1.48148 8.88889C0.663282 8.88889 0 8.22561 0 7.40741L0 1.48148Z"
                fillRule="nonzero"
                fill="rgb(250, 69, 25)"
            />
            <defs>
                <linearGradient
                    id={ids[0]}
                    gradientTransform="matrix(-1.26472e-08 -37.2176 35.2588 1.83325e-06 -10.3547 35.1314)"
                    gradientUnits="userSpaceOnUse"
                    x1="0"
                    y1="0.5"
                    x2="1"
                    y2="0.5"
                >
                    <stop offset="0" stopColor="rgb(255, 72, 32)" />
                    <stop offset="1" stopColor="rgb(255, 131, 91)" />
                </linearGradient>
            </defs>
            <path
                transform="matrix(1 0 0 1 4 3.33333)"
                d="M0 2C0 0.895431 0.895431 0 2 0L6.87768 0C7.47525 0 8.04152 0.267209 8.42144 0.728465L12.0143 5.09051C12.1409 5.24426 12.3297 5.33333 12.5289 5.33333L22 5.33333C23.1046 5.33333 24 6.22877 24 7.33333L24 23.3333C24 24.4379 23.1046 25.3333 22 25.3333L2 25.3333C0.895431 25.3333 0 24.4379 0 23.3333L0 2Z"
                fillRule="nonzero"
                fill={`url(#${ids[0]})`}
            />
            <path
                transform="matrix(1 0 0 1 13.0376 6.74154)"
                d="M5.54541e-05 10L5.54541e-05 6.88889L5.54425e-05 6.78896C5.45653e-05 2.98434 3.08435 0 6.88897 0L8.88889 0C8.3646 0.414023 7.66056 1.16112 7.03462 1.87033C6.00872 3.03272 5.54933 4.37682 5.53638 5.92713L5.50233 10L5.54541e-05 10Z"
                fillRule="nonzero"
                fill="rgb(255, 224, 227)"
            />
            <path
                transform="matrix(1 0 0 1 13.0376 6.74154)"
                d="M8.88889 -0.37037L7.88893 -0.37037L6.22292 0.402276Q5.25187 0.496405 4.34847 0.868244Q3.17683 1.35049 2.277 2.23228L1.75855 1.70322Q0.751798 2.6898 0.200907 3.97743Q-0.370315 5.31259 -0.370315 6.78896L-0.370315 10Q-0.370315 10.0365 -0.363198 10.0723Q-0.356082 10.108 -0.342122 10.1417Q-0.328162 10.1754 -0.307896 10.2058Q-0.28763 10.2361 -0.261836 10.2619Q-0.236042 10.2877 -0.205711 10.308Q-0.175381 10.3282 -0.141679 10.3422Q-0.107978 10.3561 -0.0722002 10.3633Q-0.0364228 10.3704 5.54541e-05 10.3704L5.50233 10.3704Q5.53861 10.3704 5.57421 10.3633Q5.6098 10.3563 5.64335 10.3425Q5.6769 10.3287 5.70713 10.3086Q5.73736 10.2885 5.76312 10.263Q5.78889 10.2374 5.8092 10.2074Q5.82952 10.1773 5.84361 10.1439Q5.85771 10.1104 5.86504 10.0749Q5.87238 10.0394 5.87269 10.0031L5.90673 5.93022Q5.91609 4.81132 6.25385 3.87947Q6.60122 2.9211 7.31231 2.11541Q7.83644 1.52155 8.26474 1.08335Q8.77258 0.563778 9.11842 0.290668Q9.18513 0.237989 9.22219 0.161495Q9.25926 0.0850005 9.25926 0Q9.25926 -0.0364783 9.25214 -0.0722557Q9.24503 -0.108033 9.23107 -0.141735Q9.21711 -0.175436 9.19684 -0.205767Q9.17657 -0.236097 9.15078 -0.261891Q9.12498 -0.287685 9.09466 -0.307952Q9.06432 -0.328218 9.03062 -0.342178Q8.99692 -0.356137 8.96114 -0.363254Q8.92537 -0.37037 8.88889 -0.37037ZM6.22292 0.402276L2.277 2.23228Q1.37509 3.11612 0.881938 4.2688Q0.370426 5.46439 0.370426 6.78896L0.370426 9.62963L5.13504 9.62963L5.16602 5.92403Q5.17643 4.67822 5.55744 3.62705Q5.95276 2.53642 6.75693 1.62525Q7.29354 1.01725 7.73501 0.565587Q7.83456 0.463736 7.92877 0.37037L6.88897 0.37037Q6.55206 0.37037 6.22292 0.402276Z"
                fillRule="evenodd"
                fill="rgb(255, 224, 227)"
            />
            <defs>
                <mask id={ids[1]} style={{ maskType: 'alpha' }}>
                    <path
                        transform="matrix(1 0 0 1 8.59143 16)"
                        d="M1.26452 1.26452C0.797883 0.797883 1.12838 0 1.78831 0L13.0265 0C13.6864 0 14.0169 0.797883 13.5503 1.26452L7.93119 6.88363C7.64191 7.1729 7.1729 7.1729 6.88363 6.88363L1.26452 1.26452Z"
                        fillRule="nonzero"
                        fill="rgb(254, 241, 120)"
                    />
                </mask>
            </defs>
            <g mask={`url(#${ids[1]})`}>
                <path
                    transform="matrix(1 0 0 1 8.59143 16)"
                    d="M0 0L14.8148 0L7.40741 7.40741L0 0Z"
                    fillRule="nonzero"
                    fill="rgb(255, 241, 241)"
                />
                <path
                    transform="matrix(1 0 0 1 8.59143 16)"
                    d="M0 0L7.40741 0L7.40741 7.40741L0 0Z"
                    fillRule="nonzero"
                    fill="rgb(255, 255, 255)"
                />
            </g>
        </svg>
    )
})
