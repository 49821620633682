import { Wukong } from '@wukong/bridge-proto'
import type { HTMLAttributes } from 'react'
import { useCallback, useMemo, useState } from 'react'
import { MonoIconPanelLink16, WKIconButton } from '../../../../../ui-lib/src'
import Tooltip from '../../../../../ui-lib/src/components/tooltip/tooltip'
import { SingleGrid } from '../atom/grid/single-grid'
import type { ColorSpace } from '../design/blend/color-picker/utils/color-translate'
import { ColorVarIcon } from './color-var-icon'
import { ColorVarName } from './color-var-name'
import styles from './index.module.less'
import { translation } from './index.translation'

export interface ColorVarItemProps extends HTMLAttributes<HTMLDivElement> {
    paint: Wukong.DocumentProto.IPaint
    colorSpace: ColorSpace
    variable: Wukong.DocumentProto.ISingleVariable
    width?: 184 | 176 | 208 | 144
    disabled?: boolean
    selected?: boolean
    isStroke?: boolean
    onMouseDownItem?: () => void
    onClickItem?: () => void
    onClickDetach?: () => void
}
export function ColorVarItem(props: ColorVarItemProps) {
    const {
        paint,
        colorSpace,
        variable,
        width = 184,
        disabled,
        selected,
        isStroke,
        onMouseDownItem,
        onClickItem,
        onClickDetach,
    } = props
    const [isShowCutLinkIcon, setIsShowCutLinkIcon] = useState(false)
    const [isHoverName, setIsHoverName] = useState(false)
    const color = useMemo(() => {
        if (paint.color) {
            return paint.color
        }
        return { r: 255, g: 255, b: 255 }
    }, [paint])
    const fullSpan = width / 4
    const leftSpan = useMemo(() => {
        return isShowCutLinkIcon ? fullSpan - 7 : fullSpan
    }, [isShowCutLinkIcon, fullSpan])

    const _onMouseDownItem = useCallback(() => {
        if (disabled) {
            return
        }

        onMouseDownItem?.()
    }, [disabled, onMouseDownItem])

    const _onClickItem = useCallback(() => {
        if (disabled) {
            return
        }
        onClickItem?.()
    }, [disabled, onClickItem])

    const _onClickDetach = useCallback(() => {
        if (disabled) {
            return
        }
        onClickDetach?.()
    }, [disabled, onClickDetach])

    return (
        <SingleGrid
            className={styles.colorVarItem}
            style={{ gridTemplateColumns: `repeat(${fullSpan}, 4px)` }}
            onMouseEnter={() => setIsShowCutLinkIcon(true)}
            onMouseLeave={() => setIsShowCutLinkIcon(false)}
            testId="color-var-item"
        >
            <SingleGrid.Item
                start={1}
                span={leftSpan}
                className={styles.leftItem}
                onMouseEnter={() => setIsHoverName(true)}
                onMouseLeave={() => setIsHoverName(false)}
            >
                <SingleGrid className={styles.leftContent} style={{ gridTemplateColumns: `repeat(${leftSpan}, 4px)` }}>
                    <SingleGrid.Item start={1} span={7}>
                        <ColorVarIcon
                            rgb={color}
                            colorSpace={colorSpace}
                            opacity={paint.opacity ?? undefined}
                            onMouseDown={_onMouseDownItem}
                            onClick={_onClickItem}
                            isStroke={isStroke}
                        />
                    </SingleGrid.Item>
                    <SingleGrid.Item start={8} span={leftSpan - 7}>
                        <ColorVarName
                            name={variable.name}
                            deleted={variable.isSoftDeleted}
                            hovered={isHoverName}
                            disabled={disabled}
                            selected={selected}
                            onMouseDown={_onMouseDownItem}
                            onClick={_onClickItem}
                        />
                    </SingleGrid.Item>
                </SingleGrid>
            </SingleGrid.Item>

            {isShowCutLinkIcon && (
                <SingleGrid.Item start={fullSpan - 6} span={7}>
                    <Tooltip title={translation('Detach')}>
                        <WKIconButton
                            data-testid="color-var-item-cut-link"
                            icon={<MonoIconPanelLink16 />}
                            onClick={_onClickDetach}
                        />
                    </Tooltip>
                </SingleGrid.Item>
            )}
        </SingleGrid>
    )
}
