import {
    UpdateLibraryAssetPanelFilterTextCommand,
    UpdateLibraryAssetPanelScrollableSizeCommand,
    UpdateLibraryAssetPanelScrollTopCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { useCallback } from 'react'
import { InputV2, Scrollbar } from '../../../../../../ui-lib/src'
import { sleep } from '../../../../../../util/src'
import { LibraryComponentListLayout } from '../../../../share/component-style-library/library-component-viewer/library-component-viewer'
import { useViewState } from '../../../../view-state-bridge'
import { LibraryTestId } from '../../../../window'
import { useCommand } from '../../../context/document-context'
import { useVirtualListContainer } from '../../../hooks/virtual-list'
import { LibraryShowPointContainer } from '../library-show-point-container/library-show-point-container'
import { ComponentStyleListLayoutSwitch } from './component-style-list-layout-switch/component-style-list-layout-switch'
import style from './library-assets-panel.module.less'
import { translation } from './library-assets-panel.translation'
import { LibraryDialogEntryBtn } from './library-dialog-entry-btn/library-dialog-entry-btn'
import { PreviewLibraryList } from './preview-library-list/preview-library-list'

export function LibraryAssetPanelTitle() {
    const showBadge = useViewState('libraryGlobalState')?.showBadge ?? false
    return <LibraryShowPointContainer showPoint={showBadge}>{translation('Assets')}</LibraryShowPointContainer>
}

// 资产面板
export function LibraryAssetPanel({
    handleKeydown,
}: {
    handleKeydown: (event: React.KeyboardEvent<HTMLInputElement>) => void
}) {
    const command = useCommand()
    const libraryAssetPanel = useViewState('libraryAssetPanel')
    const onSearch = useCallback(
        (keyword?: string) => {
            command.DEPRECATED_invokeBridge(
                UpdateLibraryAssetPanelFilterTextCommand,
                Wukong.DocumentProto.BridgeProtoString.create({ value: keyword })
            )
            return Promise.resolve()
        },
        [command]
    )

    return (
        <div className={style.assets} data-testid={LibraryTestId.AssetPanel.Body}>
            <div className={style.head_container}>
                <div className={style.search_with_view}>
                    <InputV2.Search
                        className={style.search_input}
                        placeholder={translation('SearchAssets...')}
                        defaultValue={libraryAssetPanel?.filterText || undefined}
                        onSearch={(v) => onSearch?.(v.trim())}
                        dataTestIds={{ input: LibraryTestId.AssetPanel.SearchInput, clearIcon: 'icon-close-svg' }}
                        autoFocus
                        debounce
                        onKeyDown={handleKeydown}
                    />
                </div>
                <div className={style.toolbar}>
                    <ComponentStyleListLayoutSwitch />
                    <LibraryDialogEntryBtn />
                </div>
            </div>
            <LibraryAssetPanelContent />
        </div>
    )
}

// 资产面板-组件预览区域
function LibraryAssetPanelContent() {
    const libraryAssetPanel = useViewState('libraryAssetPanel')
    const displayMode =
        libraryAssetPanel?.displayMode ??
        Wukong.DocumentProto.VLibraryAssetPanelDisplayMode.V_LIBRARY_ASSET_PANEL_DISPLAY_MODE_GRID
    const command = useCommand()

    const handleResize = useCallback(
        async (width: number, height: number) => {
            const rightPadding =
                displayMode ===
                Wukong.DocumentProto.VLibraryAssetPanelDisplayMode.V_LIBRARY_ASSET_PANEL_DISPLAY_MODE_GRID
                    ? 16
                    : 0
            await sleep(0)
            command.DEPRECATED_invokeBridge(
                UpdateLibraryAssetPanelScrollableSizeCommand,
                Wukong.DocumentProto.Arg_UpdateLibraryAssetPanelScrollableSize.create({
                    width: Math.max(width - rightPadding, 0),
                    height,
                })
            )
        },
        [command, displayMode]
    )

    const handleScroll = useCallback(
        (scrollTop: number) => {
            command.DEPRECATED_invokeBridge(
                UpdateLibraryAssetPanelScrollTopCommand,
                Wukong.DocumentProto.Arg_UpdateLibraryAssetPanelScrollTop.create({
                    value: scrollTop,
                })
            )
        },
        [command]
    )

    const { ref: containerRef } = useVirtualListContainer({
        onResize: handleResize,
        onScroll: handleScroll,
        deps: [libraryAssetPanel?.hasContent, libraryAssetPanel?.displayMode],
    })

    return (
        <div className={style.previewContainer}>
            <LibraryComponentListLayout maxChildrenLength={undefined} layout={displayMode} className="h-full">
                <Scrollbar ref={containerRef}>
                    <PreviewLibraryList />
                </Scrollbar>
            </LibraryComponentListLayout>
        </div>
    )
}
