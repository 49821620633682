/* eslint-disable no-restricted-imports */
import {
    RotateCopyEndPreviewCommandForJs,
    RotateCopyPreviewCommandForJs,
    RotateCopyStartPreviewCommandForJs,
    UpdateCenterPointPositionCommandForJs,
    Wukong,
} from '@wukong/bridge-proto'
import { useCommand } from '../../../../main/app-context'
import { PluginRotateCopyData } from './type'

export const usePreviewCommand = () => {
    const command = useCommand()

    return (data: PluginRotateCopyData) => {
        command.DEPRECATED_invokeBridge(RotateCopyPreviewCommandForJs, data)
        motiff.commitUndo()
    }
}

export const useStartPreview = () => {
    const command = useCommand()
    return () => {
        command.DEPRECATED_invokeBridge(RotateCopyStartPreviewCommandForJs)
        motiff.commitUndo()
    }
}

export const useFinishPreview = () => {
    const command = useCommand()
    return () => {
        command.DEPRECATED_invokeBridge(RotateCopyEndPreviewCommandForJs, {
            state: Wukong.DocumentProto.RotateCopyEndState.ROTATE_COPY_END_STATE_FINISH,
        })
        motiff.commitUndo()
    }
}

export const useCancelPreview = () => {
    const command = useCommand()
    return () => {
        command.DEPRECATED_invokeBridge(RotateCopyEndPreviewCommandForJs, {
            state: Wukong.DocumentProto.RotateCopyEndState.ROTATE_COPY_END_STATE_CANCEL,
        })
        motiff.commitUndo()
    }
}
export const useUpdateCenterPoint = () => {
    const command = useCommand()
    return (value: number) => {
        command.DEPRECATED_invokeBridge(
            UpdateCenterPointPositionCommandForJs,
            Wukong.DocumentProto.BridgeProtoFloat.create({ value })
        )
        motiff.commitUndo()
    }
}
