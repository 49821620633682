import { Wukong } from '@wukong/bridge-proto'
import { FC, useMemo } from 'react'
import { formateToFixed2 } from '../../../../atom/inputs/utils/format'
import styles from './index.module.less'
import AssetSizeInfo = Wukong.DocumentProto.IVAssetSizeInfo

interface DevModeLayerPanelRenderItemSizeInfoProps {
    assetSizeInfo: AssetSizeInfo | null | undefined
}

export const DevModeLayerPanelRenderItemSizeInfo: FC<DevModeLayerPanelRenderItemSizeInfoProps> = ({
    assetSizeInfo,
}) => {
    return useMemo(
        () =>
            assetSizeInfo ? (
                <div className={styles.devModeLayerPanelRenderItemSizeInfo}>
                    <span>
                        &nbsp;
                        {`· ${formateToFixed2(assetSizeInfo.width!)} x  ${formateToFixed2(assetSizeInfo.height!)} px`}
                    </span>
                </div>
            ) : null,
        [assetSizeInfo]
    )
}
