import classnames from 'classnames'
import isNil from 'lodash-es/isNil'
import React, {
    CSSProperties,
    forwardRef,
    ReactNode,
    useCallback,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useMount } from 'react-use'
import { MonoIconCommonError16, MonoIconCommonSearch16 } from '../../../icons-v2'
import { HtmlInput, HtmlInputProps } from '../html-input/html-input'
import { PopUpSelectList, PopUpSelectListProps, PopUpSelectListRef } from '../pop-up-select-list/pop-up-select-list'
import classes from './search-input.module.less'

export interface SearchInputProps<T> extends HtmlInputProps {
    rootClassName?: string
    minWidth?: number
    maxWidth?: number
    width?: number
    debounce?: boolean // true 表示onSearch的调用机制 会以debounceTime指定的时间进行 防抖处理
    debounceTime?: number // 毫秒 默认 200
    dataTestIds?: { root?: string; input?: string; clearIcon?: string }
    listProps?: PopUpSelectListProps<T> // 查询列表相关配置
    prefixIcon?: ReactNode // 前置的搜索图标
    skipComposing?: boolean // 输入法合成中时，不触发onSearch
    onSearch?: (value: string) => void
    onClickClearBtn?: () => void
}

export interface SearchInputRef {
    getInputElement: () => HTMLInputElement
}

function _SearchInput<T>(props: SearchInputProps<T>, ref?: React.Ref<SearchInputRef>) {
    const {
        rootClassName,
        minWidth,
        maxWidth,
        width,
        debounce,
        debounceTime = 300,
        dataTestIds,
        listProps,
        prefixIcon,
        skipComposing,
        onSearch,
        onClickClearBtn,
        value,
        defaultValue,
        disabled,
        style,
        className,
        onFocus,
        onBlur,
        onKeyDown,
        onChange,
        onCompositionEnd,
        ...otherProps
    } = props
    const [inputFocus, setInputFocus] = useState<boolean>(false)
    const [editingValue, setEditingValue] = useState<string | null>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const popUpSelectListRef = useRef<PopUpSelectListRef<T>>(null)
    const debounceSetTimeoutRef = useRef<NodeJS.Timeout>()
    const onSearchRef = useRef<SearchInputProps<T>['onSearch']>()
    onSearchRef.current = onSearch

    const rootStyle = useMemo((): CSSProperties => {
        return { minWidth, maxWidth, width, ...style }
    }, [maxWidth, minWidth, style, width])

    const renderValue = useMemo(() => {
        if (!isNil(value)) {
            return value
        } else if (!isNil(editingValue)) {
            return editingValue
        }
        return String(defaultValue ?? '')
    }, [defaultValue, editingValue, value])

    const onSearchDebounce = useCallback(() => {
        clearTimeout(debounceSetTimeoutRef.current)
        debounceSetTimeoutRef.current = setTimeout(() => {
            onSearchRef.current?.(inputRef.current?.value ?? '')
        }, debounceTime)
    }, [debounceTime])

    const _onSearch = useCallback(
        (v: string) => {
            debounce ? onSearchDebounce() : onSearchRef.current?.(v)
        },
        [debounce, onSearchDebounce]
    )

    const clickClearBtn = useCallback(() => {
        if (document.activeElement !== inputRef.current) {
            inputRef.current?.focus()
        }
        setEditingValue('')
        _onSearch?.('')
        onClickClearBtn?.()
    }, [_onSearch, onClickClearBtn])

    const _onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const v = e.target.value
            setEditingValue(v)
            if (!skipComposing || !(e.nativeEvent as InputEvent).isComposing) {
                _onSearch(v)
            }
            onChange?.(e)
        },
        [_onSearch, onChange, skipComposing]
    )

    const _onCompositionEnd = useCallback(
        (e: React.CompositionEvent<HTMLInputElement>) => {
            if (skipComposing) {
                _onSearch((e.target as HTMLInputElement).value)
            }
            onCompositionEnd?.(e)
        },
        [_onSearch, onCompositionEnd, skipComposing]
    )

    const _onFocus = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            setInputFocus(true)
            onFocus?.(e)
        },
        [onFocus]
    )

    const _onBlur = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            setInputFocus(false)
            onBlur?.(e)
        },
        [onBlur]
    )

    const _onKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.code === 'Escape') {
                inputRef.current?.blur()
            } else if (e.code === 'ArrowUp') {
                popUpSelectListRef.current?.move2top()
            } else if (e.code === 'ArrowDown') {
                popUpSelectListRef.current?.move2bottom()
            } else if (e.code === 'Enter') {
                const item = popUpSelectListRef.current?.getSelectItem()
                if (item !== undefined) {
                    listProps?.onSelectItem?.(item, { metaKey: e.metaKey, shiftKey: e.shiftKey })
                }
            }
            onKeyDown?.(e)
        },
        [listProps, onKeyDown]
    )

    useMount(() => {
        if (props.autoFocus) {
            inputRef.current?.focus()
            inputRef.current?.select()
        }
    })

    useImperativeHandle(ref, () => ({ getInputElement: () => inputRef.current! }), [])

    return (
        <div
            className={classnames(classes.root, [rootClassName], {
                [classes.disabled]: disabled,
                [classes.inputFocus]: inputFocus,
            })}
            style={rootStyle}
            data-testid={dataTestIds?.root}
        >
            <div className={classes.inputArea}>
                <div className={classes.hoverLayer}>
                    <div className={classes.forwardIcon}>{prefixIcon ? prefixIcon : <MonoIconCommonSearch16 />}</div>
                    <HtmlInput
                        ref={inputRef}
                        className={classnames(classes.input, [className])}
                        disabled={disabled}
                        value={renderValue}
                        onChange={_onChange}
                        onFocus={_onFocus}
                        onBlur={_onBlur}
                        onKeyDown={_onKeyDown}
                        data-testid={dataTestIds?.input}
                        onCompositionEnd={_onCompositionEnd}
                        {...otherProps}
                    />
                    {renderValue ? (
                        <div className={classes.backwardIcon} onMouseDown={(e) => e.preventDefault()}>
                            <MonoIconCommonError16
                                onClick={clickClearBtn}
                                className={classes.clearIcon}
                                data-testid={dataTestIds?.clearIcon}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
            <div className={classes.searchList}>
                {inputFocus && listProps?.items?.length ? (
                    <PopUpSelectList {...listProps} ref={popUpSelectListRef} />
                ) : null}
            </div>
        </div>
    )
}

export const SearchInput = forwardRef(_SearchInput) as <T>(
    props: SearchInputProps<T> & { ref?: React.Ref<SearchInputRef> }
) => React.ReactElement
