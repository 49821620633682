import { translation } from './vector-tool.translation'
/* eslint-disable no-restricted-imports */
import {
    DropdownV2,
    MonoIconToolbarBending24,
    MonoIconToolbarMove24,
    MonoIconToolbarPaintBucket24,
    MonoIconToolbarPen24,
    MonoIconToolbarZoom24,
    WKButton,
} from '../../../../../../ui-lib/src'
import { EditorMode } from '../../../../document/node/node'
import { Switch } from '../../atom/switch/switch'
import { getEditorModeName, getEditorModeShortCut } from '../utils'
import styles from './background.module.less'
import style from './toolbar.module.less'
import { useToolbar } from './use-toolbar'
import { frogExposeToolsMenusAndActionsMenuDisplay } from '../../../utils/tools-actions-frog'
import { useViewState } from '../../../../view-state-bridge'
import { Wukong } from '@wukong/bridge-proto'

export function VectorTool() {
    const { adjustTool, editorMode, isSelect, onSwitchChange, onClickDone } = useToolbar()
    const docReadonly = useViewState('docReadonly')
    const isHistoryMode = useViewState('historyMode')
    const state = useViewState('topArea')
    const isEditVector = state?.isEditVector
    const editorType = useViewState('editorType')

    return (
        <Switch active={editorMode} onSwitchChange={onSwitchChange}>
            <DropdownV2.MenuSingleLevel
                style={{ borderRadius: 'var(--wk-radius-none)' }}
                placement="bottom center"
                onChange={onSwitchChange}
                label={adjustTool.icon}
                onOpen={() => {
                    frogExposeToolsMenusAndActionsMenuDisplay({
                        doc_mode: {
                            is_readonly: !!docReadonly,
                            is_dev_mode: editorType === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV,
                            is_history_version: !!isHistoryMode,
                            is_vector: !!isEditVector,
                        },
                        displayed_menu: '移动工具下拉菜单',
                    })
                }}
                tooltipProps={{ title: translation('MoveTools') }}
                labelMoveOut={{
                    isSelect: adjustTool.editorMode === editorMode,
                    tooltipProps: {
                        title: getEditorModeName(adjustTool.editorMode),
                        shortcut: getEditorModeShortCut(adjustTool.editorMode),
                    },
                    onClick: () => onSwitchChange(adjustTool.editorMode),
                }}
                className={
                    editorMode != null && [EditorMode.EM_Move, EditorMode.EM_Scale].includes(editorMode)
                        ? styles['active-background']
                        : undefined
                }
            >
                <DropdownV2.MenuSingleLevel.Option
                    value={EditorMode.EM_Move}
                    isSelect={isSelect(EditorMode.EM_Move)}
                    forwardIcon={<MonoIconToolbarMove24 />}
                    backwardIcon={getEditorModeShortCut(EditorMode.EM_Move)}
                >
                    {getEditorModeName(EditorMode.EM_Move)}
                </DropdownV2.MenuSingleLevel.Option>
                <DropdownV2.MenuSingleLevel.Option
                    value={EditorMode.EM_Scale}
                    isSelect={isSelect(EditorMode.EM_Scale)}
                    forwardIcon={<MonoIconToolbarZoom24 />}
                    backwardIcon={getEditorModeShortCut(EditorMode.EM_Scale)}
                    disabled
                >
                    {getEditorModeName(EditorMode.EM_Scale)}
                </DropdownV2.MenuSingleLevel.Option>
            </DropdownV2.MenuSingleLevel>
            <Switch.Item
                className={style.switchItem44}
                key={EditorMode.EM_PEN}
                tooltipTitle={getEditorModeName(EditorMode.EM_PEN)}
                tooltipShortcut={getEditorModeShortCut(EditorMode.EM_PEN)}
            >
                <MonoIconToolbarPen24 />
            </Switch.Item>
            <Switch.Item
                className={style.switchItem44}
                key={EditorMode.EM_BendTool}
                tooltipTitle={getEditorModeName(EditorMode.EM_BendTool)}
                tooltipShortcut={getEditorModeShortCut(EditorMode.EM_BendTool)}
            >
                <MonoIconToolbarBending24 />
            </Switch.Item>
            <Switch.Item
                className={style.switchItem44}
                key={EditorMode.EM_PaintBucket}
                tooltipTitle={getEditorModeName(EditorMode.EM_PaintBucket)}
                tooltipShortcut={getEditorModeShortCut(EditorMode.EM_PaintBucket)}
            >
                <MonoIconToolbarPaintBucket24 />
            </Switch.Item>
            <div className={style.doneButton}>
                <WKButton onClick={onClickDone} type="primary">
                    {translation('Done')}
                </WKButton>
            </div>
        </Switch>
    )
}
