import { WKButton } from '../../../../../../ui-lib/src'
import { isEnglishLanguage } from '../../../../../../util/src'
import { PayApplyDialogContainer1 } from './pay-apply-dialog-container'
import { translation } from './repeat-application.translation'
import { NormalPayApplyProps } from './type'
import { getUINameFromPayApplyDialogSeatType } from './utils'

export function RepeatApplication(props: NormalPayApplyProps) {
    const { dialogProps, descriptionPrefix, seatType, repeatDialogProps } = props

    return (
        <PayApplyDialogContainer1
            onCancel={dialogProps?.onCancel}
            onOk={dialogProps?.onOk}
            title={repeatDialogProps.dialogTitle}
            footer={
                <WKButton
                    onClick={dialogProps?.onOk}
                    type="primary"
                    {...dialogProps?.okButtonProps}
                    dataTestId="ok-button"
                >
                    {translation('ok')}
                </WKButton>
            }
        >
            <div data-testid="repeat-pay-apply-dialog">
                {isEnglishLanguage() ? (
                    <>
                        {descriptionPrefix}, you need to request a &quot;{getUINameFromPayApplyDialogSeatType(seatType)}
                        &quot;. You have sent a request. Contact the admin for prompt approval.
                    </>
                ) : (
                    <>
                        {descriptionPrefix}需要向管理员申请「{getUINameFromPayApplyDialogSeatType(seatType)}
                        」。你已发送申请，可以联系管理员尽快审批。
                    </>
                )}
            </div>
        </PayApplyDialogContainer1>
    )
}
