import classNames from 'classnames'
import { useMemo, useState } from 'react'
import {
    MulticolorIconCommonFolderBg24,
    MulticolorIconCommonTeamMemberBg24,
    Tag,
    WKAvatar,
    WKTextButton,
    WKUserAvatar,
} from '../../../../../ui-lib/src'
import {
    PlanAndUserStateVO,
    RoleStatus,
    SeatType,
    ResourceMemberVO,
    UserMemberVO,
} from '../../../kernel/interface/type'
import { SpaceLogs } from '../../../kernel/service/space-frog-service/logs'
import { getLERoles } from '../../../space/util/access/util'
import { isActiveOrgPlan, isActiveProPlan } from '../../../utils/plan-status-checker'
import { AccessDropdown } from '../access-dropdown'
import { AccessDropdownType, AccessVO, ClickAccessMenuParams, CurrentUser, ShareDialogType } from '../types'
import classes from './index.module.less'
import { translation } from './index.translation'

interface UserListProps {
    type: ShareDialogType
    access: AccessVO
    members: ResourceMemberVO
    currentUser: CurrentUser
    dimission: boolean
    onClickAccessMenu: (p: ClickAccessMenuParams) => void
    isDraft?: boolean
    hidePrototype: boolean
    teamPlanOfSharingDoc: PlanAndUserStateVO | undefined
}

const UserListItem = (props: {
    user: UserMemberVO
    type: AccessDropdownType
    currentUser: CurrentUser
    tooltipContent?: string
    isDraft?: boolean
    shareDialogType: ShareDialogType
    dimission: boolean
    onClickAccessMenu: (p: ClickAccessMenuParams) => void
    hidePrototype: boolean
}) => {
    const { user, type, currentUser, tooltipContent, isDraft, shareDialogType, dimission, onClickAccessMenu } = props
    const dropdownType = useMemo(() => {
        if (type === AccessDropdownType.Member) {
            if (user.userId === currentUser.userId) {
                return AccessDropdownType.Self
            }
        }
        return type
    }, [type, currentUser, user.userId])
    const isGuest = user.guest
    return (
        <div className={`${classes.line}`} data-testid="user-list-item">
            <WKUserAvatar userInfo={user.userBrief} key={user.id} size={24} />
            <div className={classes.userName}>
                {user.userBrief?.waitRegister ? (
                    <span>{user.userBrief?.nickname + translation('PendingJoin')}</span>
                ) : (
                    <>{user.userBrief?.nickname}</>
                )}
                {user.userId === currentUser.userId && <span style={{ whiteSpace: 'pre' }}>{translation('You')}</span>}
                {isGuest && <Tag name={translation('Viewer')} className="ml-1" size="small" color="yellow" />}
            </div>
            <AccessDropdown
                isDraft={!!isDraft}
                shareDialogType={shareDialogType}
                dimission={dimission}
                type={dropdownType}
                member={user}
                role={currentUser.role}
                tooltipContent={tooltipContent}
                onClickAccessMenu={onClickAccessMenu}
                hidePrototype={props.hidePrototype}
            />
        </div>
    )
}

const ShareDialogType2Label = {
    [ShareDialogType.Doc]: translation('File'),
    [ShareDialogType.Folder]: translation('Project'),
}

export const UserList = ({
    access,
    members,
    currentUser,
    dimission,
    onClickAccessMenu,
    isDraft,
    type,
    hidePrototype,
    teamPlanOfSharingDoc,
}: UserListProps) => {
    const { members: currMembers, parentMembers, parentName, parentIcon, parentIconBackgroundColor } = members
    const [showParents, setShowParents] = useState(false)
    // @ts-expect-error
    const parentMember: UserMemberVO = access
    const parentInfo = {
        name: parentName,
        icon: parentIcon,
        iconBackgroundColor: parentIconBackgroundColor,
    }

    const getTooltipContent = (member: UserMemberVO, isParent?: boolean) => {
        // 优先处理席位冲突
        const inActiveResource =
            teamPlanOfSharingDoc && (isActiveProPlan(teamPlanOfSharingDoc) || isActiveOrgPlan(teamPlanOfSharingDoc))

        // 只在企业版/专业版处理席位冲突
        if (inActiveResource) {
            const hasEditAuth = !getLERoles(RoleStatus.Viewer).includes(member.actualRole)
            const hasGracePeriod = member.inNeedApproveGracePeriod
            const currentSeat = member.seatType
            const isDraftOwner = member.actualRole === RoleStatus.Owner && isDraft

            if (hasEditAuth && !isDraftOwner) {
                if (hasGracePeriod && currentSeat === SeatType.designer) {
                    return translation('SeatRequestPending')
                } else if (!hasGracePeriod && (currentSeat === SeatType.viewer || currentSeat === SeatType.developer)) {
                    // 原地是可查看原型，出 CannotEditWithLink
                    return member.role === RoleStatus.ViewPrototype
                        ? translation('CannotEditWithLink')
                        : // 无席位时降级为可查看，因此原地可查看不展示冲突
                        member.role === RoleStatus.Viewer
                        ? ''
                        : translation('CannotEdit')
                }
            }
        }

        // 处理权限冲突
        // 所有者权限，低于可编辑权限才出冲突提示
        if (
            member.actualRole !== member.role &&
            ((member.actualRole === RoleStatus.Owner && getLERoles(RoleStatus.Viewer).includes(member.role)) ||
                member.actualRole !== RoleStatus.Owner)
        ) {
            switch (type) {
                case ShareDialogType.Folder: {
                    // 项目权限出现role和actualRole不一致，是因为邀请权限和继承权限不一致
                    if (member.actualRole === RoleStatus.Owner) {
                        return translation('ActualRoleOwner', { type: ShareDialogType2Label[type] })
                    } else if (member.actualRole === RoleStatus.Editor) {
                        return translation('ActualRoleEditor', {
                            type: ShareDialogType2Label[type],
                        })
                    } else if (member.actualRole === RoleStatus.Viewer) {
                        return translation('ActualRoleViewer', {
                            type: ShareDialogType2Label[type],
                        })
                    }
                    break
                }
                case ShareDialogType.Doc: {
                    // 文件权限出现role和actualRole不一致，可能是因为邀请权限和继承权限不一致，也可能是因为公开访问权限高于用户继承/邀请权限
                    const inviteMember = isParent ? currMembers.find((m) => m.userId === member.userId) : member

                    if (inviteMember && inviteMember.role !== member.role && inviteMember.role === member.actualRole) {
                        if (member.actualRole === RoleStatus.Owner) {
                            return translation('ActualRoleOwner', { type: ShareDialogType2Label[type] })
                        } else if (inviteMember.role === RoleStatus.Editor) {
                            return translation('ActualRoleEditor', {
                                type: ShareDialogType2Label[type],
                            })
                        } else if (inviteMember.role === RoleStatus.Viewer) {
                            return translation('ActualRoleViewer', {
                                type: ShareDialogType2Label[type],
                            })
                        } else if (inviteMember.role === RoleStatus.ViewPrototype) {
                            return translation('ActualRoleViewPrototype')
                        }
                    } else {
                        const role =
                            access.publicAccess?.publicAccessRole === RoleStatus.Editor
                                ? translation('Edit')
                                : translation('View')
                        const scope = access.publicAccess?.orgMemberOnly
                            ? translation('OrgMember')
                            : translation('Anyone')
                        return translation('LinkShare', { role, scope })
                    }
                    break
                }
            }
        }

        return ''
    }

    return (
        <div className={`${classes.userList} ${type === ShareDialogType.Doc ? classes.doc : ''}`}>
            {!isDraft && parentName && (
                <div className={classes.line} data-testid="user-list-parent-title">
                    {type === ShareDialogType.Doc ? (
                        <MulticolorIconCommonFolderBg24 />
                    ) : (
                        <WKAvatar
                            type={'square'}
                            imageId={parentInfo.icon}
                            backgroundColor={parentInfo.iconBackgroundColor}
                            name={parentInfo.name}
                            size={24}
                        />
                    )}
                    <div className={classes.name}>{parentName}</div>
                    <div className={classNames(classes.buttonWrap, 'mr-16px')}>
                        {(!!parentMembers.length || (type === ShareDialogType.Doc && members.hideTeamMember)) && (
                            <WKTextButton
                                type="primary"
                                size={12}
                                onClick={() => {
                                    setShowParents((prev) => {
                                        if (!prev) {
                                            type === ShareDialogType.Doc
                                                ? SpaceLogs.FileShareAndPermission.expandProjectMemberList()
                                                : SpaceLogs.ProjectShareAndPermission.expandTeamMemberList()
                                        }
                                        return !prev
                                    })
                                }}
                                className="whitespace-nowrap"
                            >
                                {showParents ? translation('ShowLess') : translation('ShowMore')}
                            </WKTextButton>
                        )}
                    </div>
                    <AccessDropdown
                        type={AccessDropdownType.Parent}
                        member={parentMember}
                        shareDialogType={type}
                        dimission={dimission}
                        isDraft={false}
                        role={currentUser.role}
                        onClickAccessMenu={onClickAccessMenu}
                        hidePrototype={hidePrototype}
                    />
                </div>
            )}
            {!isDraft && !parentName && (
                <div className={classes.teamMemberHide}>
                    <div className={classes.teamIcon}>
                        <MulticolorIconCommonFolderBg24 style={{ opacity: 0.4 }} />
                    </div>
                    {type === ShareDialogType.Folder ? translation('TeamMemberHide') : translation('ProjectMemberHide')}
                </div>
            )}
            {showParents && (!!parentMembers.length || (type === ShareDialogType.Doc && members.hideTeamMember)) && (
                <div className={classes.parentMembersWrap}>
                    <div className={classes.decoration}></div>
                    <div className={classes.parentMembers} data-testid="user-list-parent-members">
                        {type === ShareDialogType.Doc && members.hideTeamMember ? (
                            <div className={classes.teamMemberHide}>
                                <div className={classes.teamIcon}>
                                    <MulticolorIconCommonTeamMemberBg24 style={{ opacity: 0.4 }} />
                                </div>
                                {translation('TeamMemberHide')}
                            </div>
                        ) : null}
                        {parentMembers.map((user, index) => (
                            <UserListItem
                                user={user}
                                key={index}
                                isDraft={isDraft}
                                shareDialogType={type}
                                dimission={dimission}
                                type={AccessDropdownType.View}
                                currentUser={currentUser}
                                tooltipContent={getTooltipContent(user, true)}
                                onClickAccessMenu={onClickAccessMenu}
                                hidePrototype={hidePrototype}
                            />
                        ))}
                    </div>
                </div>
            )}
            {!!currMembers?.length &&
                currMembers.map((user, index) => (
                    <UserListItem
                        isDraft={isDraft}
                        user={user}
                        key={index}
                        shareDialogType={type}
                        dimission={dimission}
                        type={user.userBrief.waitRegister ? AccessDropdownType.Invited : AccessDropdownType.Member}
                        currentUser={currentUser}
                        tooltipContent={getTooltipContent(user)}
                        onClickAccessMenu={onClickAccessMenu}
                        hidePrototype={hidePrototype}
                    />
                ))}
        </div>
    )
}
