import { translation } from './view-all.translation'
import classnames from 'classnames'
import style from '../inspect.module.less'

interface PropType {
    limit: number
    count: number
    onChange: (limit: number) => void
    className?: string
}

export function ViewAll(props: PropType) {
    return (
        <>
            {props.count > props.limit && (
                <div className={classnames(style.viewAll, props.className)} onClick={() => props.onChange(props.count)}>
                    {translation('SeeAll', { count: props.count })}
                </div>
            )}
        </>
    )
}
