/* eslint-disable no-restricted-imports */
import classNames from 'classnames'
import { useCallback } from 'react'
import { isRightClick } from '../../../../../../../ui-lib/src'
import { Paint } from '../../../../../document/node/node'
import { ToKeyCode } from '../../../../../document/util/keycode'
import { CommonStyleInfo } from '../get-style-nodes-info-map'
import { StyleToolTip } from '../style-tooltip/style-tooltip'
import { LocalStyleColorListItemThumnail } from './local-style-color-list-item-thumbnail'
import style from './style-color-grid-item-v2.module.less'

type Item = CommonStyleInfo & { paints: readonly Paint[]; contentHash: string }
export interface StyleColorGridItemV2Props {
    item: Item
    className?: string
    onSelectItem?: (item: Item) => void
    onClickHoverIcon?: (item: Item, containerDomRect: DOMRect) => void
    onContextMenu?: (item: Item, e: any) => void
    isRightClickStyle?: boolean
    isSelected?: boolean
}

export function StyleColorGridItemV2(props: StyleColorGridItemV2Props) {
    const { item, onSelectItem, onContextMenu, isRightClickStyle, isSelected } = props

    const _onMouseDown: React.MouseEventHandler<HTMLDivElement> = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (isRightClick(e)) {
                return
            }
            onSelectItem?.(item)
        },
        [item, onSelectItem]
    )

    const _onContextMenu: React.MouseEventHandler<HTMLDivElement> = useCallback(
        (e) => {
            e.stopPropagation()
            e.preventDefault()
            onContextMenu?.(item, e)
        },
        [item, onContextMenu]
    )
    const _onKeydown = useCallback(
        (e: any) => {
            if (e.keyCode === ToKeyCode.Enter) {
                e.stopPropagation()
                _onMouseDown(e)
            }
        },
        [_onMouseDown]
    )

    return (
        <StyleToolTip titleText={item.name} contentText={item.description}>
            <div
                onContextMenu={_onContextMenu}
                onMouseDown={_onMouseDown}
                className={classNames(
                    style.gridItem,
                    isRightClickStyle && style['right-click-menu-visible'],
                    props.className,
                    { [style.selectItem]: isSelected }
                )}
                onKeyDown={_onKeydown}
                data-testid={item.id}
            >
                <LocalStyleColorListItemThumnail
                    id={props.item.id}
                    contentHash={props.item.contentHash}
                    paints={props.item.paints}
                    className={style.color}
                />
            </div>
        </StyleToolTip>
    )
}
