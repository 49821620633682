import { MaterialDynamicColors } from '@material/material-color-utilities'
import { translation } from './constants.translation'

export const PlatformMap = {
    app: 'app',
    web: 'web',
} as const

export type Platform = typeof PlatformMap[keyof typeof PlatformMap]

export const DesignSystemMap = {
    custom: 'custom',
    apple: 'Apple HIG',
    md3: 'Material Design 3',
    antd: 'Ant Design',
    shadcn: 'shadcn UI',
} as const

export type DesignSystem = typeof DesignSystemMap[keyof typeof DesignSystemMap]

export const CornerSizeMap = {
    none: 'None',
    small: 'Small',
    medium: 'Medium',
    large: 'Large',
} as const

export const ShadcnUIRadiusMap = {
    '0': 0,
    '0.3': 0.3,
    '0.5': 0.5,
    '0.75': 0.75,
    '1.0': 1.0,
}

export const ModeMap = {
    light: 'light',
    dark: 'dark',
} as const

export type Mode = typeof ModeMap[keyof typeof ModeMap]

export type CornerSize = typeof CornerSizeMap[keyof typeof CornerSizeMap]

export const ContrastMap = {
    standard: 'Standard',
    medium: 'Medium',
    high: 'High',
} as const

export type Contrast = typeof ContrastMap[keyof typeof ContrastMap]

export const AutoStylingBorderRadiusMap = {
    None: '0px',
    Small: '4px',
    Medium: '8px',
    Large: '12px',
    '0px': 'None',
    '4px': 'Small',
    '8px': 'Medium',
    '12px': 'Large',
} as const

export type AutoStylingBorderRadius = typeof AutoStylingBorderRadiusMap[keyof typeof AutoStylingBorderRadiusMap]

export const COLORS = new Map([
    [
        DesignSystemMap.custom as DesignSystem,
        ['#1677FF', '#1668DC', '#7B61FF', '#F759AB', '#722ED1', '#F5222D', '#FA8C16', '#FADB14', '#52C41A'],
    ],
    // TODO(chaibowen@kanyun.com): light 和 dark 的颜色不一样
    [DesignSystemMap.shadcn, ['#18181B', '#DC2828', '#E21D48', '#F97415', '#16A249', '#2463EB', '#FACC14', '#7C3BED']],
    [
        DesignSystemMap.md3,
        ['#B33B15', '#63A002', '#769CDF', '#FFDE3F', '#CA3E6C', '#00A17A', '#00B7C3', '#E78B1B', '#7C72D3', '#A15BD2'],
    ],
    [
        DesignSystemMap.apple,
        [
            '#007AFF',
            '#FF3B30',
            '#FF9500',
            '#FFCC00',
            '#34C759',
            '#00C7BE',
            '#30B0C7',
            '#32ADE6',
            '#5856D6',
            '#AF52DE',
            '#FF2D55',
            '#A2845E',
        ],
    ],
    [
        DesignSystemMap.antd,
        [
            '#1677FF',
            '#722ED1',
            '#13C2C2',
            '#52C41A',
            '#EB2F96',
            '#F5222D',
            '#FA8C16',
            '#FADB14',
            '#FF7A45',
            '#2F54EB',
            '#FAAD14',
            '#A0D911',
            '#000000',
        ],
    ],
] as const)

export const MAXIMUM_USER_REMIXES = 5

export const materialDesignNameWithDynamicColor = [
    { name: translation('secondaryColor'), color: MaterialDynamicColors.secondary },
    { name: translation('tertiaryColor'), color: MaterialDynamicColors.tertiary },
    { name: translation('errorColor'), color: MaterialDynamicColors.error },
    { name: translation('neutralColor'), color: MaterialDynamicColors.neutralPaletteKeyColor },
    { name: translation('neutralVariantColor'), color: MaterialDynamicColors.neutralVariantPaletteKeyColor },
] as const

export const antdColorNameWithIndex = [
    { name: translation('successColor'), index: 'colorSuccess' },
    { name: translation('warningColor'), index: 'colorWarning' },
    { name: translation('errorColor'), index: 'colorError' },
    { name: translation('linkColor'), index: 'colorLink' },
    { name: translation('textBaseColor'), index: 'colorTextBase' },
    { name: translation('bgBaseColor'), index: 'colorBackgroundBase' },
] as const

// Type definitions for interfaces and types that depend on the constants
export interface BuiltinRemix {
    platform: Platform
    designSystem: DesignSystem
    name: string
    id: number
    builtin: true
    config: Map<string, string>
}

export type ConfigKey =
    | 'mode'
    | 'contrast'
    | 'font'
    | 'cornerSize' // 'cornerSize' 是 'cornerRadius' 的别名
    | 'primaryColor'
    | 'fontSizeBase'
    | 'margin'

export interface UserRemix {
    platform: Platform
    designSystem: DesignSystem
    name: string
    id: number
    builtin: false
    // Material其他的参数全都使用生成的 theme,
    // Antd的额外参数采用Map出来的, 本身都使用ConfigKey中的内容
    config: Map<string, string>
}

export type Remix = UserRemix | BuiltinRemix

export const MaterialDesignThemes = {}
