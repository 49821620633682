import { useEffect, useRef } from 'react'
import { ScrollView } from '../../scrollbars'
import { Tag as TagComponent, TagProps, TagRef } from '../../tag'

export interface TagItemProps {
    name: TagProps['name']
    color: TagProps['color']
    index: number
    maxWidth?: number
    interactiveKey: number
    disabled: boolean | undefined
    className: string
    onDelete: (index: number, type: 'mouse' | 'keyboard') => void
    onSelect: (index: number) => void
}

export function TagItem(props: TagItemProps) {
    const { name, color, index, maxWidth, interactiveKey, disabled, className, onDelete, onSelect } = props
    const tagRef = useRef<TagRef>(null)

    useEffect(() => {
        if (interactiveKey === index && !tagRef.current?.getRootElement().contains(document.activeElement)) {
            tagRef.current?.focus()
        }
    }, [index, interactiveKey])

    return (
        <ScrollView.Item
            uniqueKey={index}
            style={{ margin: '2px 0 2px 4px', maxWidth: maxWidth !== undefined ? maxWidth - 4 : undefined }}
            className={className}
        >
            <TagComponent
                name={name}
                color={color}
                style={{ maxWidth: 'inherit' }}
                colorChecked={color === 'black' ? 'blue' : undefined}
                ref={tagRef}
                size="medium"
                structure="line-surface"
                closable
                disabled={disabled}
                onDelete={(v) => onDelete(index, v)}
                onClick={() => onSelect(index)}
                onFocus={() => onSelect(index)}
                onMouseDown={(e) => e.preventDefault()}
            />
        </ScrollView.Item>
    )
}
