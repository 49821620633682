import {
    CanOpenPresentCommand,
    GeneratePrototypePresentUrlOptionsForCopyFLowLink,
    GeneratePrototypePresentUrlOptionsForOpenInEditor,
    GeneratePrototypePresentUrlOptionsForOpenPresent,
    Wukong,
} from '@wukong/bridge-proto'
import { WKToast } from '../../../../ui-lib/src'
import { domLocation, execCommandCopy, generateRouterPath, safeDecodeUriComponent } from '../../../../util/src'
import type { CommandInvoker } from '../../document/command/command-invoker'
import { getURLWithoutParams } from '../../document/util/url'
import { Bridge } from '../../kernel/bridge/bridge'
import { CreatePrototypePresentFile } from '../../kernel/request/prototype-present'
import { translation } from './present-url.translation'

const PresentPathPrefix = 'proto'
const EditorPathPrefix = 'file'

export const isPresentPath = (pathname?: string): boolean => {
    const presentPathPrefix = '/' + generateRouterPath(PresentPathPrefix)
    const innerPathname = pathname ?? domLocation().pathname
    return innerPathname === presentPathPrefix || innerPathname.startsWith(presentPathPrefix + '/')
}

const PresentUrlViewportOptionToParam: {
    [key in Wukong.DocumentProto.PrototypePresentScaling]: string
} = {
    [Wukong.DocumentProto.PrototypePresentScaling.MIN_ZOOM]: 'min-zoom',
    [Wukong.DocumentProto.PrototypePresentScaling.SCALE_DOWN]: 'scale-down',
    [Wukong.DocumentProto.PrototypePresentScaling.SCALE_DOWN_WIDTH]: 'scale-down-width',
    [Wukong.DocumentProto.PrototypePresentScaling.FULL_CONTAIN]: 'full-contain',
}

const PresentUrlViewportParamToOption: {
    [key: string]: Wukong.DocumentProto.PrototypePresentScaling
} = {
    'min-zoom': Wukong.DocumentProto.PrototypePresentScaling.MIN_ZOOM,
    'scale-down': Wukong.DocumentProto.PrototypePresentScaling.SCALE_DOWN,
    'scale-down-width': Wukong.DocumentProto.PrototypePresentScaling.SCALE_DOWN_WIDTH,
    'full-contain': Wukong.DocumentProto.PrototypePresentScaling.FULL_CONTAIN,
}

/**
 * 指定 fileId 和查询参数，生成原型预览页面的 URL（ 不带 origin，可用于 navigate 或 open ）
 * @param options
 * @returns
 */
export function generatePresentUrl(options: Wukong.DocumentProto.IPrototypePresentUrlParam & { fileId: string }) {
    const { fileId, ...others } = options
    const params = generatePresentUrlParams(others)
    const paramStr = new URLSearchParams(params).toString()
    return `/${PresentPathPrefix}/${fileId}?${paramStr}`
}

/**
 * 指定 fileId 和查询参数，生成原型预览页面的 URL（ 完整 url，可用于复制链接 ）
 * @param options
 * @returns
 */
export function generatePresentFullUrl(options: Wukong.DocumentProto.IPrototypePresentUrlParam & { fileId: string }) {
    // 这里使用 generateRouterPath + substring 是为了处理 commit review 链接的情况
    // 如不考虑直接使用 `${domLocation().origin}${generatePresentUrl(options)}` 即可
    return `${domLocation().origin}/${generateRouterPath(generatePresentUrl(options).substring(1))}`
}

/**
 * 生成原型预览页面的 URL 并跳转
 * @param bridge
 */
export function openPresentUrl(bridge: Bridge) {
    const canOpenPrent = bridge.call(CanOpenPresentCommand)
    if (!canOpenPrent.value) {
        WKToast.show(translation('NoFrameToPresent'))
        return
    }

    const options = bridge.call(GeneratePrototypePresentUrlOptionsForOpenPresent)
    const url = generatePresentFullUrl(options)
    new CreatePrototypePresentFile(options.fileId, options.pageId, options.pageName).start().catch(() => {
        console.error(translation('FailedToCreatePrototype'))
    })
    window.open(url, `proto-${options.fileId}-${options.pageId}`, 'noopener')
}

export function copyFlowLinkForEditor(commandInvoker: CommandInvoker, flowId: string) {
    const options = commandInvoker.DEPRECATED_invokeBridge(GeneratePrototypePresentUrlOptionsForOpenPresent)
    const url = generatePresentFullUrl({
        fileId: options.fileId,
        nodeId: flowId,
        startingPointNodeId: flowId,
        showProtoSidebar: options.showProtoSidebar,
        scaling: options.scaling,
    })
    execCommandCopy(url)
}

export function copyFlowLinkInPresent(commandInvoker: CommandInvoker, flowId: string) {
    const options = commandInvoker.DEPRECATED_invokeBridge(GeneratePrototypePresentUrlOptionsForCopyFLowLink)
    const url = generatePresentFullUrl({
        fileId: options.fileId,
        nodeId: flowId,
        startingPointNodeId: flowId,
        showProtoSidebar: options.showProtoSidebar,
        scaling: options.scaling,
        disableHotspotHints: options.disableHotspotHints,
        hideDeviceFrame: options.hideDeviceFrame,
        disableDefaultKeyboardNav: options.disableDefaultKeyboardNav,
        hideUI: options.hideUI,
    })
    execCommandCopy(url)
}

/**
 * 根据 fileId 和 nodeId，跳转返回至编辑器
 * @param commandInvoker
 * @returns
 */
export function openInEditor(commandInvoker: CommandInvoker) {
    const options = commandInvoker.DEPRECATED_invokeBridge(GeneratePrototypePresentUrlOptionsForOpenInEditor)
    const { fileId, nodeId } = options

    const paramStr = new URLSearchParams(nodeId ? { nodeId } : {}).toString()
    const url = `${domLocation().origin}/${EditorPathPrefix}/${fileId}?${paramStr}`

    window.open(url, '_self')
}

function fetchSearchParam() {
    const searchParams = new URLSearchParams(domLocation().search)
    return {
        hasKey: (key: keyof Wukong.DocumentProto.IPrototypePresentUrlParam) => {
            return searchParams.has(key)
        },
        parseValue: (key: keyof Wukong.DocumentProto.IPrototypePresentUrlParam) => {
            const rawValue = searchParams.get(key)
            return rawValue ? safeDecodeUriComponent(rawValue, '') : ''
        },
        parseBoolean: (key: keyof Wukong.DocumentProto.IPrototypePresentUrlParam) => {
            const rawValue = searchParams.get(key)
            return rawValue == '1' || rawValue == 'true'
        },
    }
}

/**
 * 获取原型预览页面下的 URL 查询参数
 * @returns
 */
export const getPresentUrlParams = (): Wukong.DocumentProto.IPrototypePresentUrlParam => {
    const searchParams = fetchSearchParam()
    const params: Wukong.DocumentProto.IPrototypePresentUrlParam = {}

    if (searchParams.hasKey('nodeId')) {
        params.nodeId = searchParams.parseValue('nodeId')
    }
    if (searchParams.hasKey('pageId')) {
        params.pageId = searchParams.parseValue('pageId')
    }
    if (searchParams.hasKey('startingPointNodeId')) {
        params.startingPointNodeId = searchParams.parseValue('startingPointNodeId')
    }
    if (searchParams.hasKey('scaling')) {
        params.scaling = PresentUrlViewportParamToOption[searchParams.parseValue('scaling')]
    }
    if (searchParams.hasKey('showProtoSidebar')) {
        params.showProtoSidebar = searchParams.parseBoolean('showProtoSidebar')
    }
    if (searchParams.hasKey('hideDeviceFrame')) {
        params.hideDeviceFrame = searchParams.parseBoolean('hideDeviceFrame')
    }
    if (searchParams.hasKey('disableHotspotHints')) {
        params.disableHotspotHints = searchParams.parseBoolean('disableHotspotHints')
    }
    if (searchParams.hasKey('disableDefaultKeyboardNav')) {
        params.disableDefaultKeyboardNav = searchParams.parseBoolean('disableDefaultKeyboardNav')
    }
    if (searchParams.hasKey('hideUI')) {
        params.hideUI = searchParams.parseBoolean('hideUI')
    }
    return params
}

function generatePresentUrlParams(params: Wukong.DocumentProto.IPrototypePresentUrlParam) {
    const newParams: { [key in keyof Wukong.DocumentProto.IPrototypePresentUrlParam]?: string } = {}
    if (params.nodeId) {
        newParams.nodeId = params.nodeId
    }
    if (params.pageId) {
        newParams.pageId = params.pageId
    }
    if (params.startingPointNodeId) {
        newParams.startingPointNodeId = params.startingPointNodeId
    }
    if (params.scaling != undefined) {
        newParams.scaling = PresentUrlViewportOptionToParam[params.scaling]
    }
    if (params.showProtoSidebar) {
        newParams.showProtoSidebar = '1'
    }
    if (params.disableHotspotHints) {
        newParams.disableHotspotHints = '1'
    }
    if (params.hideDeviceFrame) {
        newParams.hideDeviceFrame = '1'
    }
    if (params.disableDefaultKeyboardNav) {
        newParams.disableDefaultKeyboardNav = '1'
    }
    if (params.hideUI) {
        newParams.hideUI = '1'
    }
    return new URLSearchParams(newParams)
}

/**
 * 更新原型预览页面下的 URL 查询参数
 * @param params
 */
export const setPresentUrlParams = (params: Wukong.DocumentProto.IPrototypePresentUrlParam) => {
    const newUrl = new URL(getURLWithoutParams())
    Array.from(generatePresentUrlParams(params).entries()).forEach(([key, value]) => {
        newUrl.searchParams.set(key, value)
    })
    try {
        history.replaceState(null, '', newUrl)
    } catch (e) {
        // do nothing，safari 会 crash 高频的 replaceState 和 pushState 不更新 url，但是从 case 上不影响
    }
}
