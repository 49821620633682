import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    NoFoundManifestError: 'Manifest 文件错误：Manifest 文件丢失，请检查后再试',
    PermissionDeniedError: 'Manifest 文件错误：无访问权限',
    ReadFileError: 'Manifest 文件错误：无法读取 manifest 文件',
    NoNameError: 'Manifest 文件错误: “manifest.name” 缺失',
    NoMainError: 'Manifest 文件错误: “manifest.main” 缺失',
    NoIdError: 'Manifest 文件错误: “manifest.id” 缺失',
    NoUiError: 'Manifest 文件错误: “manifest.ui” 缺失',
    DuplicatePluginError: '所选插件已导入，无法重复导入',
    ImportSuccess: '插件已导入',
    RunPluginError: '插件运行失败，请检查后再试',
    OpenConsole: '打开控制台',
    OnlyManifestJsonError: '仅支持导入 manifest.json 文件',
} as const

export const enTranslation = {
    NoFoundManifestError: 'Manifest error: Missing manifest. Check plugin files and try again.',
    PermissionDeniedError: 'Manifest error: No permission to access the plugin files.',
    ReadFileError: 'Manifest error: Failed to read manifest.json',
    NoNameError: 'Manifest error: "manifest.name" undefined',
    NoMainError: 'Manifest error: "manifest.main" undefined',
    NoIdError: 'Manifest error: "manifest.id" undefined',
    NoUiError: 'Manifest error: "manifest.ui" undefined',
    DuplicatePluginError: 'Selected plugin has already been imported, and cannot be imported repeatedly',
    ImportSuccess: 'Plugin imported',
    RunPluginError: 'An error occurred while running the plugin. Check plugin files and try again. ',
    OpenConsole: 'Show console',
    OnlyManifestJsonError: 'Invalid file. Only manifest.json files are supported.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
