import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    MoveTools: '移动工具',
    RegionTools: '区域工具',
    ShapeTools: '形状工具',
    CreationTools: '绘图工具',
    Pencil: '铅笔',
    Plugins: '插件',
    RunLastPlugin: '运行最近一次启用',
    AiTools: 'Motiff AI 工具',
    AiToolBox: 'AI 工具箱',
    AiRedupliation: 'AI 复制',
    AILayout: 'AI 布局',
    AIDesignSystem: 'AI 设计系统',
    AIDesignSystemCreator: 'AI 设计系统创建',
    AIDesignSystemMaintainer: 'AI 设计系统维护',
    AIConsistencyChecker: 'AI 一致性检查',
    AIMagicBox: 'AI 魔法框',
    MotiffLab: 'Motiff 妙多实验室',
    AreYouSure: '确定取消识别组件吗？',
    Cancel: '取消识别',
    Continue: '继续识别',
    StructuredTemporarily: '临时结构化调整',
    SmartlyAddAutoLayout: '智能添加自动布局',
    AiMagicHint1: '当前文件不支持使用 AI 魔法框',
    AiMagicHint2: '你可以打开 AI 魔法框 Playground 文件体验此功能。',
    CancelAiMagic: '取消',
    OpenPlayground: '打开 Playground',
    AiGenUI: 'AI 生成 UI',
    PluginManagement: '管理企业私有插件...',
    OrgIcon: '企业',
    AISearch: 'AI 检索...',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    MoveTools: 'Move tools',
    RegionTools: 'Region tools',
    ShapeTools: 'Shape tools',
    CreationTools: 'Creation tools',
    Pencil: 'Pencil',
    Plugins: 'Plugins',
    RunLastPlugin: 'Run last plugin',
    AiTools: 'Motiff AI tools',
    AiToolBox: 'AI Toolbox',
    AiRedupliation: 'AI Reduplication',
    AILayout: 'AI Layout',
    AIDesignSystem: 'AI Design Systems',
    AIDesignSystemCreator: 'AI Design System Creator...',
    AIDesignSystemMaintainer: 'AI Design System Maintainer...',
    AIConsistencyChecker: 'AI Consistency Checker...',
    AIMagicBox: 'AI Magic Box...',
    MotiffLab: 'Motiff Lab',
    AreYouSure: 'Are you sure you want to stop recognizing components?',
    Cancel: 'Stop recognizing',
    Continue: 'Continue',
    StructuredTemporarily: 'AI builds temporary structure',
    SmartlyAddAutoLayout: 'AI adds auto layout',
    AiMagicHint1: 'This file does not support AI Magic Box',
    AiMagicHint2: 'You should open Playground file to experience it.',
    CancelAiMagic: 'Cancel',
    OpenPlayground: 'Open Playground',
    AiGenUI: 'AI Generates UI',
    PluginManagement: 'Manage private organization plugins...',
    OrgIcon: 'Org',
    AISearch: 'AI Search...',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
