export function deferedPromise<T>(): {
    promise: Promise<T>
    resolve: (t: T) => void
    reject: (e?: Error) => void
} {
    const out = {} as {
        promise: Promise<T>
        resolve: (t: T) => void
        reject: (e?: Error) => void
    }

    out.promise = new Promise<T>((resolve, reject) => {
        out.resolve = resolve
        out.reject = reject
    })
    return out
}
