import { translation } from './style-layout-grid.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { IconDelete16, IconEyes, IconEyesClose, IconLink, Tooltip } from '../../../../../../ui-lib/src'
import { useViewState } from '../../../../view-state-bridge'
import classnames from 'classnames'
import React, { forwardRef, useCallback, useRef, useState } from 'react'
import { IconButton } from '../../atom/button/icon-button'
import { SingleGrid } from '../../atom/grid/single-grid'
import { LayoutGridStyleThumbnail } from '../../design/styles/style-layout-grid/style-thumbnail'
import { StyleToolTip } from '../../design/styles/style-tooltip/style-tooltip'
import style from './style-layout-grid.module.less'

export interface StyleGridProps extends React.HTMLAttributes<HTMLDivElement> {
    name: string
    description?: string | null
    isOpen?: boolean
    onClickCutLink?: () => void
    onClickStyle?: (e: any) => void
    onClickSub?: () => void
    onChangeVisible?: () => void
    layoutgrids: Wukong.DocumentProto.ILayoutGrid[]
}

function _StyleGrid(props: StyleGridProps, ref: React.Ref<HTMLDivElement>) {
    const { name, description, isOpen, layoutgrids, onClickCutLink, onClickSub, onChangeVisible, onClickStyle } = props
    const [isShowCutLinkIcon, setIsShowCutLinkIcon] = useState<boolean>(false)
    const gridRef = useRef<HTMLDivElement>(null)
    const visible = !!useViewState('globalState')?.enableLayoutGrid

    const _onClick = useCallback((e: React.MouseEvent) => {
        e.stopPropagation()
    }, [])

    const _onMouseDown = () => {}
    const _onMouseEnter = useCallback(() => {
        setIsShowCutLinkIcon(true)
    }, [])
    const _onMouseLeave = useCallback(() => {
        setIsShowCutLinkIcon(false)
    }, [])

    return (
        <SingleGrid
            className={style.layoutGrid}
            ref={ref}
            onClick={_onClick}
            onMouseEnter={_onMouseEnter}
            onMouseLeave={_onMouseLeave}
            onMouseDown={_onMouseDown}
        >
            <SingleGrid.Item start={5} span={46}>
                <SingleGrid className={`${style.backgroundGrid} ${isOpen ? style.opened : ''}`}>
                    <SingleGrid.Item start={1} span={isShowCutLinkIcon ? 32 : 46}>
                        <SingleGrid
                            onClick={onClickStyle}
                            className={style.colorNameContainer}
                            style={{ gridTemplateColumns: `repeat(${isShowCutLinkIcon ? 32 : 46}, 4px)` }}
                            ref={gridRef}
                        >
                            <SingleGrid.Item start={1} span={7}>
                                <LayoutGridStyleThumbnail layoutGrids={layoutgrids} shallowColor={true} />
                            </SingleGrid.Item>
                            <StyleToolTip
                                titleText={name}
                                contentText={description}
                                triggerRect={() => gridRef.current!.getBoundingClientRect()}
                            >
                                <SingleGrid.Item start={9} span={isShowCutLinkIcon ? 24 : 37}>
                                    <div className={classnames(style.name, { [style.visible]: visible })}>{name}</div>
                                </SingleGrid.Item>
                            </StyleToolTip>
                        </SingleGrid>
                    </SingleGrid.Item>

                    {isShowCutLinkIcon && (
                        <SingleGrid.Item start={33} span={7}>
                            <IconButton
                                icon={visible ? <IconEyes /> : <IconEyesClose />}
                                selected={false}
                                onChange={onChangeVisible}
                                className={style.eyeIcon}
                                dataTestId="grid-style-visible-icon"
                            />
                        </SingleGrid.Item>
                    )}

                    {isShowCutLinkIcon && (
                        <SingleGrid.Item start={40} span={7}>
                            <Tooltip title={translation('DetachStyle')}>
                                <IconButton
                                    dataTestId="cut-style-link"
                                    icon={<IconLink />}
                                    selected={false}
                                    className={style.link}
                                    onClick={onClickCutLink}
                                />
                            </Tooltip>
                        </SingleGrid.Item>
                    )}
                </SingleGrid>
            </SingleGrid.Item>
            <SingleGrid.Item start={51} span={12} horizontalCenter>
                <IconButton icon={<IconDelete16 />} selected={false} onClick={onClickSub} dataTestId="sub-icon" />
            </SingleGrid.Item>
        </SingleGrid>
    )
}

export const StyleGrid = forwardRef(_StyleGrid)
