import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    GJsCWH: '此文件已达到同时编辑的人数上限，暂时进入查看模式',
    UFQTsQ: '关于同时编辑人数上限',
    OCDDWA: '此文件已达到同时在线编辑的人数上限，你已暂时进入查看模式。当部分编辑权限的用户离开文件后，将自动为你切换至编辑模式。',
    YJRYty: '我知道了',
    LearnMore: '了解更多',
} as const

export const enTranslation = {
    GJsCWH: 'The file has reached the maximum number of editors. You have been temporarily switched to viewer mode.',
    UFQTsQ: 'About editor limit',
    OCDDWA: 'The editing access will be restored to you once some editors leave the file.',
    YJRYty: 'OK',
    LearnMore: 'Learn more',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
