import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import { WKToast } from '../../../../ui-lib/src'
import { environment } from '../../environment'
import { getChromeVersion, isMac } from '../../kernel/util/ua'
import { GlobalCanvas } from '../../main/gpu/global-canvas'

function isBadGraphicsBackend(): boolean {
    if (!isMac()) {
        // 不是 mac 就没问题
        return false
    }

    const chromeVersion = getChromeVersion()
    if (!chromeVersion || chromeVersion < 130) {
        // 不是 chrome 或 chrome < 130 就没问题
        return false
    }

    const webgl = GlobalCanvas.webgl()
    if (!webgl) {
        return false
    }

    const debugInfo = webgl.context.getExtension('WEBGL_debug_renderer_info')
    if (!debugInfo) {
        return false
    }

    const renderer = webgl.context.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)
    if (typeof renderer != 'string') {
        return false
    }

    if (!renderer.includes('ANGLE')) {
        // 不是 ANGLE 先认为没问题
        return false
    }

    if (renderer.includes('SwiftShader')) {
        // 没开启硬件加速时跳过
        return false
    }

    // 没有 Metal 就认为有问题
    return !renderer.includes('Metal')
}

function showGraphicsBackendToast() {
    const url = environment.isAbroad
        ? `${environment.host}/help/docs/articles/314522868193026`
        : `${environment.host}/help/docs/sections/313024247487232`

    const toastId = WKToast.show(translation('ChangeTheANGLE'), {
        duration: -1,
        firstButton: { type: 'x' },
        secondButton: {
            type: 'button',
            text: translation('LearnMore'),
            onClick: () => window.open(url, '_blank'),
        },
        onClose: () => {
            WKToast.close(toastId)
        },
    })
}

export function toastWhenCheckingGraphicsBackend() {
    if (isBadGraphicsBackend()) {
        showGraphicsBackendToast()
    }
}
