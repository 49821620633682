/* eslint-disable no-restricted-imports */
import constate from 'constate'
import { useLibraryComponentService } from '../../../../main/app-context'

// 组件库通用弹窗路由管理
function useLibraryModalConfig() {
    const mainModalConfig = useLibraryComponentService().libraryModalRouterService.useZustandStore.use.mainModal()
    const publishModalConfig = useLibraryComponentService().libraryModalRouterService.useZustandStore.use.publishModal()

    return {
        mainModalConfig: mainModalConfig as Readonly<typeof mainModalConfig>,
        publishModalConfig: publishModalConfig as Readonly<typeof publishModalConfig>,
    }
}

export const [LibraryModalControlContextProvider, useLibraryModalConfigContext] = constate(useLibraryModalConfig)
