/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { IconHyperlink } from '../../../../../../../ui-lib/src'
import { DeepRequired } from '../../../../../view-state-bridge'
import { IconFrame, IconGroup } from '../../../layer-panel/render-item-icon/svg'
import { IconMotiff, IconPage } from './svg'
import { isMotiffDocumentUrl } from '../../../../../document/util/url'

export function HyperLinkIcon({
    hyperlinkHoverPopupState,
}: {
    hyperlinkHoverPopupState: DeepRequired<Wukong.DocumentProto.IHyperlinkHoverPopupState>
}) {
    const isLinkNode = hyperlinkHoverPopupState.hyperlink.nodeId

    if (!isLinkNode) {
        if (isMotiffDocumentUrl(hyperlinkHoverPopupState.hyperlink.url)) {
            return <IconMotiff />
        }
        return <IconHyperlink width={16} height={16} />
    }

    switch (hyperlinkHoverPopupState.hyperlinkNodeType) {
        case Wukong.DocumentProto.NodeType.NODE_TYPE_PAGE:
            return <IconPage />

        case Wukong.DocumentProto.NodeType.NODE_TYPE_GROUP:
            return <IconGroup />

        default:
            return <IconFrame />
    }
}
