import {
    OnMembershipChange,
    OnSyncUserProperties,
    OnUserEnter,
    OnUserQuit,
    OnUserSynergyRoleChange,
    WsUpdateCurrentActiveCommand,
    WsUpdateCurrentCursorModeCommand,
    WsUpdateCurrentInactiveCommand,
    WsUpdateCurrentMousePositionCommand,
    WsUpdateCurrentObservingCommand,
    WsUpdateCurrentPageIdCommand,
    WsUpdateCurrentSelectionsCommand,
    WsUpdateCurrentViewportCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { signalThrottle } from '../../../../util/src/abort-controller/signal-task'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { EmBridge } from '../../kernel/bridge/em-bridge'
import { WebSocketBridge } from '../synergy/web-socket-bridge'
import {
    WsMembership,
    WsSyncUserProperties,
    WsUpdateUserProperties,
    WsUserActive,
    WsUserEnter,
    WsUserInactive,
    WsUserQuit,
    WsUserSynergyRoleChange,
} from '../synergy/web-socket-message'

export class CooperationSynergy {
    constructor(
        private readonly signal: TraceableAbortSignal,
        private readonly webSocketBridge: WebSocketBridge,
        protected readonly bridge: EmBridge,
        private readonly isMirror: boolean
    ) {
        if (isMirror) {
            return
        }
        this.handleSynergyMessage()

        this.initCooperationEventHandler(this.signal)
    }

    // 处理下行 ws message
    private handleSynergyMessage = () => {
        this.webSocketBridge.onSynergyMessageProto(WsMembership, (proto) => {
            this.bridge.call(OnMembershipChange, { value: proto.payload })
        })
        this.webSocketBridge.onSynergyMessageProto(WsSyncUserProperties, (proto) => {
            this.bridge.call(OnSyncUserProperties, { value: proto.payload })
        })
        this.webSocketBridge.onSynergyMessageProto(WsUserEnter, (proto) => {
            this.bridge.call(OnUserEnter, { value: proto.payload })
        })
        this.webSocketBridge.onSynergyMessageProto(WsUserQuit, (proto) => {
            this.bridge.call(OnUserQuit, { value: proto.payload })
        })
        this.webSocketBridge.onSynergyMessageProto(WsUserSynergyRoleChange, (proto) => {
            this.bridge.call(OnUserSynergyRoleChange, { value: proto.payload })
        })
    }

    private initCooperationEventHandler = (signal: TraceableAbortSignal) => {
        this.initMouseEventHandler(signal)
        this.initCursorModeEventHandler(signal)
        this.initSelectionEventHandler(signal)
        this.initCurrentPageIdEventHandler(signal)
        this.initViewportEventHandler(signal)
        this.initObservingEventHandler(signal)

        this.initUserActiveEventHandler(signal)
        this.initUserInactiveEventHandler(signal)
    }

    private initMouseEventHandler = (signal: TraceableAbortSignal) => {
        const handleEvent = this.createBufferedCooperationEventHandler(signal, (event) => {
            this.webSocketBridge.sendPayload(WsUpdateUserProperties, event)
        })

        const throttleHandleEvent = signalThrottle(signal, handleEvent, 30, { leading: true, trailing: false })
        this.bridge.bind(WsUpdateCurrentMousePositionCommand, throttleHandleEvent, { signal })
    }

    private initCursorModeEventHandler = (signal: TraceableAbortSignal) => {
        const handleEvent = this.createBufferedCooperationEventHandler(signal, (event) => {
            this.webSocketBridge.sendPayload(WsUpdateUserProperties, event)
        })

        this.bridge.bind(WsUpdateCurrentCursorModeCommand, handleEvent, { signal })
    }

    private initSelectionEventHandler = (signal: TraceableAbortSignal) => {
        const handleEvent = this.createBufferedCooperationEventHandler(signal, (event) => {
            this.webSocketBridge.sendPayload(WsUpdateUserProperties, event)
        })

        this.bridge.bind(WsUpdateCurrentSelectionsCommand, handleEvent, { signal })
    }

    private initCurrentPageIdEventHandler = (signal: TraceableAbortSignal) => {
        const handleEvent = this.createBufferedCooperationEventHandler(signal, (event) => {
            this.webSocketBridge.sendPayload(WsUpdateUserProperties, event)
        })

        this.bridge.bind(WsUpdateCurrentPageIdCommand, handleEvent, { signal })
    }

    private initViewportEventHandler = (signal: TraceableAbortSignal) => {
        const handleEvent = this.createBufferedCooperationEventHandler(signal, (event) => {
            this.webSocketBridge.sendPayload(WsUpdateUserProperties, event)
        })

        const throttleHandleEvent = signalThrottle(signal, handleEvent, 30, { leading: true, trailing: false })
        this.bridge.bind(WsUpdateCurrentViewportCommand, throttleHandleEvent, { signal })
    }

    private initObservingEventHandler = (signal: TraceableAbortSignal) => {
        const handleEvent = this.createBufferedCooperationEventHandler(signal, (event) => {
            this.webSocketBridge.sendPayload(WsUpdateUserProperties, event)
        })

        this.bridge.bind(WsUpdateCurrentObservingCommand, handleEvent, { signal })
    }

    private initUserActiveEventHandler = (signal: TraceableAbortSignal) => {
        const handleEvent = this.createBufferedCooperationEventHandler(signal, (event) => {
            this.webSocketBridge.sendPayload(WsUserActive, event)
        })

        this.bridge.bind(WsUpdateCurrentActiveCommand, handleEvent, { signal })
    }

    private initUserInactiveEventHandler = (signal: TraceableAbortSignal) => {
        const handleEvent = this.createBufferedCooperationEventHandler(signal, (event) => {
            this.webSocketBridge.sendPayload(WsUserInactive, event)
        })
        this.bridge.bind(WsUpdateCurrentInactiveCommand, handleEvent, { signal })
    }

    private createBufferedCooperationEventHandler = (
        signal: TraceableAbortSignal,
        sender: (event: Uint8Array) => void
    ) => {
        let eventCache: Uint8Array | null = null
        let currentSynergyState = false

        const handleEvent = (param: Wukong.DocumentProto.ISendCooperationProto) => {
            // 在历史版本状态下，不发送任何 cooperation 相关数据
            if (!param.payload || this.bridge.inHistoryMode) {
                return
            }

            const event = param.payload
            if (currentSynergyState) {
                sender(event)
            } else {
                eventCache = event
            }
        }

        const onSynergyStateChange = (state: Wukong.DocumentProto.SynergyState) => {
            const nextSynergyState = state === Wukong.DocumentProto.SynergyState.SYNERGY_STATE_ONLINE
            if (currentSynergyState === nextSynergyState) {
                return
            }

            currentSynergyState = nextSynergyState
            // 发送之前离线时缓存的事件
            if (currentSynergyState && eventCache) {
                sender(eventCache)
                eventCache = null
            }
        }

        this.webSocketBridge.onSynergyStateChangeWithSignal(signal, onSynergyStateChange)

        return handleEvent
    }
}
