import type { Wukong } from '@wukong/bridge-proto'
import { imageDataToPremulAlphaBytes } from '../../image-lib'
import type { IControlParagraphSupports } from './support-interface'

export class NativeControlParagraphSupports implements IControlParagraphSupports {
    private readonly measureContext: CanvasRenderingContext2D
    private readonly controlTextBitmapContext: CanvasRenderingContext2D

    constructor(private readonly logger: (...args: any[]) => void) {
        this.measureContext = this.createMeasureContext()
        this.controlTextBitmapContext = this.createControlTextBitmapContext()
    }

    createControlFont(font: Wukong.DocumentProto.IControlParagraphFont) {
        return document.fonts.load(this.getFontStyle(font))
    }

    createControlTextBitmap(arg: Wukong.DocumentProto.IArg_GetCanvasGlyphBitmap): Wukong.DocumentProto.ISerBitmap {
        const ctx = this.controlTextBitmapContext

        const canvas = ctx.canvas
        if (canvas.width !== arg.bitmapWidth) {
            canvas.width = arg.bitmapWidth
        }

        if (canvas.height !== arg.bitmapHeight) {
            canvas.height = arg.bitmapHeight
        }

        ctx.save()
        ctx.setTransform(1, 0, 0, 1, 0, 0)
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.restore()

        ctx.save()
        ctx.fillStyle = 'rgba(0, 0, 0, 1)'
        ctx.font = this.getFontStyle(arg.meta.font)
        ctx.direction = 'ltr'
        // x y wasm已经scale过了, 这里只对文字进行scale
        ctx.translate(arg.x, arg.y)
        ctx.scale(arg.scaleX, arg.scaleY)
        ctx.fillText(arg.grapheme, 0, 0)
        ctx.restore()

        let bytes: Uint8Array
        try {
            const _imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
            bytes = imageDataToPremulAlphaBytes(_imageData)
        } catch (e) {
            this.logger('[NativeControlParagraphSupports] error', {
                x: arg.x,
                y: arg.y,
                scaleX: arg.scaleX,
                scaleY: arg.scaleY,
                grapheme: arg.grapheme,
                width: arg.bitmapWidth,
                height: arg.bitmapHeight,
            })
            throw e
        }

        return {
            width: canvas.width,
            height: canvas.height,
            bytes: {
                value: bytes,
            },
        }
    }
    measureControlTextWidth(
        arg: Wukong.DocumentProto.IArg_MeasureControlTextWidth
    ): Wukong.DocumentProto.IBridgeProtoFloat {
        this.measureContext.font = this.getFontStyle(arg.meta.font)
        return { value: this.measureContext.measureText(arg.grapheme ?? '').width }
    }

    private createMeasureContext() {
        return this.createCanvasContext2D(20, 20, false)
    }

    private createControlTextBitmapContext() {
        return this.createCanvasContext2D(20, 20, true)
    }

    private createCanvasContext2D = (
        width: number,
        height: number,
        willReadFrequently: boolean
    ): CanvasRenderingContext2D => {
        const canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height
        return canvas.getContext('2d', {
            alpha: true,
            willReadFrequently,
        })!
    }

    private getFontStyle = ({ style }: Wukong.DocumentProto.IControlParagraphFont) => {
        return `normal ${style.fontWeight} ${style.fontSize}px/${style.lineHeight}px "Inter",sans-serif`
    }
}
