import { UserBriefVO } from './type'

export interface CommentMessage {
    message: string // JSON.stringify
    messageText: string // 正文
    mentionedUsers: CommentUser[] // 提及到的账号列表
    reactions: CommentReaction[] // 表情回复
}

export type CommentId = number
export type CommentUUID = string

export interface CommentThread extends CommentMessage {
    id: CommentId
    docId: string // 属于哪个 doc
    page: string // 属于哪个 page
    seq: number // 对于每个 doc 有唯一的序号，由服务端控制，会在列表展示
    uuid: CommentUUID // 每个评论有一个唯一 uuid，在创建评论时由客户端生成
    owner: CommentUser // 返回了 User 详情，用于展示
    replies: CommentReply[] // 普通回复
    position: CommentPosition // 在画布上的位置信息
    unread: boolean // 未读状态
    createdTime: number // 创建时间
    updatedTime: number // 更新时间
    lastEditTime: number // 旧的数据是 15609600000（'1970-07-01 00:00:00'）。
    resolved: boolean // 是否被 resolve
    // resolvedTime: number // resolve 时间。应该是一次性的
    // deleted: boolean // 是否被删除
    // deletedTime: number // 删除时间。看起来是标记删除？
}

export interface CommentReply extends CommentMessage {
    id: number
    parentId: number // 属于哪个 comment thread
    docId: string
    uuid: CommentUUID // 每个文字回复有一个唯一 uuid，在创建回复时由客户端生成
    owner: CommentUser
    createdTime: number
    updatedTime: number
    lastEditTime: number // 旧的数据是 15609600000（'1970-07-01 00:00:00'）。
    unread: boolean // 未读状态

    // deleted: boolean
    // deletedTime: number
}

export interface CommentPosition {
    // id: number
    commentId: number
    nodeId: string // 是否绑定到 node，没绑定为空字符串
    hasAnchor: boolean // 是否有锚点
    // 气泡左下角在画布上的绝对坐标
    // 无论是否绑定 node 都会变化
    x: number
    y: number
    // 如果绑定了 node，相对于 node 外接矩形左上角的 relative offset
    // 没绑定时和上面的 x y 相同
    offsetX: number
    offsetY: number
    // 如果是框选范围，是左上角的绝对坐标
    // 不是框选范围的话都为 null
    // 业务上锚点不能绑定 node
    anchorX: number
    anchorY: number
}

export type CommentUser = UserBriefVO

export interface CommentReaction {
    id: number
    message: string // 表情字符，如 :eye:
    owner: CommentUser // 返回了 User 详情，用于展示
}

export interface CreateCommentReplyArgs {
    message: string
    messageText: string
    mentionedUserIds: number[] // 没有提及时也要传空数组
    parentCommentId: number // 回复的哪一条 comment
    uuid: CommentUUID
}

export type CreateCommentPosition = CommentPosition

export interface CreateCommentThreadArgs {
    docId: string // 属于哪个 doc
    page: string // 属于哪个 page
    message: string
    messageText: string
    mentionedUserIds: number[] // 没有提及时也要传空数组
    positionVO: CreateCommentPosition // position 必须全量更新
    uuid: CommentUUID
}

export interface EditCommentOrReplyArgs {
    message: string
    messageText: string
    addedUserIds: number[]
    removedUserIds: number[]
}

export enum Strategy {
    All = 0, // 接收全部
    None = 1, // 全不接收
    Relative = 2, // 接收与我相关
}
