import { useEffect, useRef } from 'react'
import { useLocation } from 'react-use'

export function useUnmountHref(callback?: () => void) {
    const location = useLocation()
    const callbackRef = useRef(callback)
    callbackRef.current = callback

    useEffect(() => {
        return () => {
            // 避免 Warning: Attempted to synchronously unmount a root while React was already rendering.
            setTimeout(() => callbackRef.current?.(), 0)
        }
    }, [location.href])
}
