import { translation } from './load-more.translation'
/* eslint-disable no-restricted-imports */
import { WKTextButton } from '../../../../../../ui-lib/src'
import classnames from 'classnames'
import { LoadingLoad } from '../loading-mask/loading-mask'
import classes from './load-more.module.less'

export interface LoadMoreProps {
    isVisible: boolean
    isLoading: boolean
    onClick: () => void
    dataTestIds?: {
        container?: string
        loading?: string
    }
}
export function LoadMore(props: LoadMoreProps) {
    const { isVisible, isLoading, onClick, dataTestIds } = props
    return isVisible ? (
        <div
            className={classnames(classes.loadMore, { [classes.isLoading]: isLoading })}
            data-testid={dataTestIds?.container}
        >
            {isLoading ? (
                <LoadingLoad dataTestId={dataTestIds?.loading} />
            ) : (
                <WKTextButton type="primary" size={12} onClick={onClick}>
                    {translation('ShowMore')}
                </WKTextButton>
            )}
        </div>
    ) : null
}
