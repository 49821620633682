import {
    DevModeLayerPanelCancelHoverCommand,
    DevModeLayerPanelEnterHoverCommand,
    Wukong,
    ZoomToFitNodesAtMaxScaleByIdsCommand,
} from '@wukong/bridge-proto'
import classNames from 'classnames'
import { MouseEvent, useCallback, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { Checkbox, IconTarget, WKIconButton } from '../../../../../../ui-lib/src'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { useNodePreviewBase64 } from '../../design/export/hooks/use-get-node-preview'
import { getNodesPreviewBase64ImageWithFixedSize } from '../../design/export/util'
import style from './batch-export.module.less'

export interface BatchExportItemContent {
    nodeIds: string[]
    name: string
    width: number
    height: number
}

export interface BatchExportItemProps {
    config: BatchExportItemContent
    selected: Set<BatchExportItemContent>
    select: (config: BatchExportItemContent) => void
    unSelect: (config: BatchExportItemContent) => void
    onClick: (nodeId: string) => void
}

function ItemContent(props: BatchExportItemContent) {
    const content: string[] = []

    const size = `${props.width | 0}x${props.height | 0} px`
    content.push(size)

    return <article className="text-gray wk-text-12">{content.join(' , ')}</article>
}

export function BatchExportItem(props: BatchExportItemProps) {
    const { config, selected, select, unSelect } = props
    const changed = useViewState('nodePreviewTracksChanged')
    const { base64, setBase64 } = useNodePreviewBase64()
    const command = useCommand()
    const { ref, inView } = useInView()

    useEffect(() => {
        if (inView && base64 === null) {
            setBase64(getNodesPreviewBase64ImageWithFixedSize(command, config.nodeIds, 64))
            return
        }
        if (changed !== undefined && inView) {
            setBase64(getNodesPreviewBase64ImageWithFixedSize(command, config.nodeIds, 64))
        }
    }, [config.nodeIds, changed, setBase64, command, inView, base64])

    const handleClickBtn = useCallback(
        (e: MouseEvent) => {
            // 阻止响应 checkbox
            e.stopPropagation()
            command.DEPRECATED_invokeBridge(ZoomToFitNodesAtMaxScaleByIdsCommand, { nodeIds: config.nodeIds })
        },
        [command, config.nodeIds]
    )

    const handleMouseEnter = useCallback(() => {
        command.DEPRECATED_invokeBridge(
            DevModeLayerPanelEnterHoverCommand,
            Wukong.DocumentProto.BridgeProtoString.create({
                value: config.nodeIds[0],
            })
        )
    }, [command, config.nodeIds])

    const handleMouseLeave = useCallback(() => {
        command.DEPRECATED_invokeBridge(DevModeLayerPanelCancelHoverCommand)
    }, [command])

    return (
        <div
            className={classNames(style.batchExportModalConfigsItem)}
            onClick={() => {
                selected.has(config) ? unSelect(config) : select(config)
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            data-testid="dev-mode-batch-export-node-item"
            // 避免触发可拖拽弹窗的拖拽
            data-disabled-drag-move={'true'}
        >
            <div className={classNames(style.batchExportModalConfigsCheckBox)}>
                <Checkbox
                    stopPropagation
                    checked={selected.has(config)}
                    onChange={(flag) => (flag ? select(config) : unSelect(config))}
                />
            </div>
            <div
                className={classNames(style.batchExportModalConfigsItemImg, 'mosaic-3')}
                key={`${config.nodeIds[0]}${config.name}${selected}-img`}
            >
                <img ref={ref} draggable={false} src={base64 ?? ''} />
            </div>
            <div
                className={classNames(style.batchExportModalDescription)}
                key={`${config.nodeIds[0]}${config.name}${selected}-text`}
            >
                <div className={classNames(style.batchExportModalConfigsItemFrameContainer)}>
                    <article
                        className={classNames([
                            style.batchExportModalConfigsItemFrameName,
                            'text-black',
                            'wk-font-medium',
                            'wk-text-12',
                        ])}
                    >
                        {config.name}
                    </article>
                </div>
                <ItemContent {...config} />
            </div>
            <div className={classNames(style.batchExportModalConfigsBtn)}>
                <WKIconButton
                    onClick={handleClickBtn}
                    icon={<IconTarget />}
                    data-testid="dev-mode-batch-export-item-btn"
                />
            </div>
        </div>
    )
}
