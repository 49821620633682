import { SVGAttributes } from 'react'

// 此按钮不属于按钮库
export function ResizeIcon(props: SVGAttributes<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" {...props}>
            <path
                transform="matrix(1 0 0 1 0.823223 0.823223)"
                d="M8 0L8.35355 0.353554L0.353554 8.35355L0 8L8 0ZM4 8L8 4L8.35355 4.35355L4.35355 8.35355L4 8Z"
                fillRule="evenodd"
                fill="currentColor"
            />
        </svg>
    )
}
