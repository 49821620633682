import { isEnglishLanguage } from '../../../../../util/src'
import classNames from 'classnames'
import { format, isToday } from 'date-fns'
import { FC, useMemo } from 'react'
import { IconArrowDown12 } from '../../../svg-icon/12/controls'
import { IconArrowLeft16, IconArrowRight16 } from '../../../svg-icon/16/common'
import { WKIconButton } from '../../wk-button/icon-button'
import { MONTH, MONTH2LABEL_EN, MONTH2LABEL_ZH, GET_YEARS } from '../data'
import styles from '../index.module.less'
import { DatepickerMenuItem } from '../menu'
import { CalendarItemType, CalendarProps, CalendarrProvider, useCalendarrContext } from './context'
import { translation } from './index.translation'

const YearPicker = () => {
    const { year, setYear, showYear, setShowYear, yearRef } = useCalendarrContext()
    return (
        <div ref={yearRef} className="relative">
            <button
                onClick={() => {
                    setShowYear(true)
                }}
                data-testid="year-picker"
                className="bg-transparent border-none outline-none flex items-center wk-font-semibold"
            >
                {year}
                {translation('BvfQIK')} <IconArrowDown12 className="color-$wk-v2-label-color-gray-8 ml-1" />
            </button>
            {showYear && (
                <div className={classNames(styles.popup_container, 'w-120px py-2 wk-text-12 wk-font-regular')}>
                    {GET_YEARS().map((y) => (
                        <DatepickerMenuItem
                            onClick={() => {
                                setYear(y)
                                setShowYear(false)
                            }}
                            data-testid={`year-${y}`}
                            key={y}
                            name={`${y} ${translation('BvfQIK')}`}
                            checked={y == year}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

const MonthPicker = () => {
    const { setShowMonth, monthRef, month, setMonth, showMonth } = useCalendarrContext()
    return (
        <div ref={monthRef} className="ml-4 relative">
            <button
                data-testid="month-picker"
                onClick={() => setShowMonth(true)}
                className="flex items-center bg-transparent border-none outline-none wk-font-semibold"
            >
                {isEnglishLanguage() ? MONTH2LABEL_EN[month] : MONTH2LABEL_ZH[month]}
                <IconArrowDown12 className="color-$wk-v2-label-color-gray-8 ml-1" />
            </button>
            {showMonth && (
                <div className={classNames(styles.popup_container, 'w-120px py-2 wk-text-12 wk-font-regular')}>
                    {MONTH.map((m) => (
                        <DatepickerMenuItem
                            onClick={() => {
                                setMonth(m)
                                setShowMonth(false)
                            }}
                            data-testid={`month-${m + 1}`}
                            key={m}
                            name={isEnglishLanguage() ? MONTH2LABEL_EN[m] : MONTH2LABEL_ZH[m]}
                            checked={m == month}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

const CalendarTitle = () => {
    const { addMonth, subMonth, addMothDisabled, subMonthDisabled } = useCalendarrContext()
    return (
        <div className="h-7 flex items-center relative justify-between z-1">
            <WKIconButton
                data-testid={'sub-month'}
                disabled={subMonthDisabled}
                onClick={subMonth}
                size="small"
                icon={<IconArrowLeft16 />}
            />
            <div className="flex wk-text-14 wk-font-medium">
                {isEnglishLanguage() ? (
                    <>
                        <MonthPicker />
                        <YearPicker />
                    </>
                ) : (
                    <>
                        <YearPicker />
                        <MonthPicker />
                    </>
                )}
            </div>
            <WKIconButton
                data-testid={'add-month'}
                disabled={addMothDisabled}
                size="small"
                onClick={addMonth}
                icon={<IconArrowRight16 />}
            />
        </div>
    )
}

const DAYS = [
    translation('Mon'),
    translation('Tue'),
    translation('Wed'),
    translation('Thu'),
    translation('Fri'),
    translation('Sat'),
    translation('Sun'),
] as const

const DAYS_ABROAD = [
    translation('Sun'),
    translation('Mon'),
    translation('Tue'),
    translation('Wed'),
    translation('Thu'),
    translation('Fri'),
    translation('Sat'),
] as const

const TodyBadge: FC<CalendarItemType> = (props) => {
    if (isToday(props.time)) {
        return (
            <span
                className={classNames(
                    'absolute w-1 h-1 rounded bottom-3px bg-$wk-brand-7',
                    (props.rangeStart || props.rangeEnd) && 'bg-white'
                )}
            />
        )
    } else {
        return <></>
    }
}

const CalendarItemDisabled: FC<CalendarItemType> = (props) => {
    return (
        <button
            disabled={true}
            data-testid={format(props.time, 'MM/dd')}
            className={classNames(
                styles.calendar_item,
                '!bg-$wk-gray-1 cursor-not-allowed color-$wk-gray-6 !rounded-0'
            )}
        >
            <span>{props.label}</span>
        </button>
    )
}

const CalendarItemNotThisMonth: FC<CalendarItemType> = (props) => {
    const { clickDate } = useCalendarrContext()
    return (
        <button
            onClick={() => {
                clickDate(props.time)
            }}
            className={classNames(
                styles.calendar_item,
                'rounded-3px color-$wk-v2-label-color-gray-8 !rounded-0 hover:bg-$wk-gray-13-4'
            )}
        >
            <span>{props.label}</span>
        </button>
    )
}

const CalendarItemInRange: FC<CalendarItemType> = (props) => {
    const { clickDate } = useCalendarrContext()
    return (
        <button
            data-testid={format(props.time, 'MM/dd')}
            onClick={() => {
                clickDate(props.time)
            }}
            className={classNames(styles.calendar_item, '!bg-$wk-brand-1 hover:!bg-$wk-brand-3')}
        >
            <span>{props.label}</span>
        </button>
    )
}
const CalendarItemPoint: FC<CalendarItemType> = (props) => {
    const { clickDate, start, end } = useCalendarrContext()
    const roundedStyle = useMemo(() => {
        if (start && end) {
            return classNames(props.rangeStart && 'rounded-l-3px', props.rangeEnd && 'rounded-r-3px')
        }
        return 'rounded-3px'
    }, [end, props.rangeEnd, props.rangeStart, start])
    return (
        <button
            data-testid={format(props.time, 'MM/dd')}
            onClick={() => {
                clickDate(props.time)
            }}
            className={classNames(
                styles.calendar_item,
                '!bg-$wk-brand-7 color-$wk-white hover:!bg-$wk-brand-6',
                roundedStyle
            )}
        >
            <span>{props.label}</span>
            <TodyBadge {...props} />
        </button>
    )
}

const CalendarItem: FC<CalendarItemType> = (props) => {
    const { clickDate, updateHoverDate } = useCalendarrContext()
    if (props.disable) {
        return <CalendarItemDisabled {...props} />
    }
    if (!props.isThisMonth) {
        return <CalendarItemNotThisMonth {...props} />
    }
    if (props.inRange && !props.rangeStart && !props.rangeEnd) {
        return <CalendarItemInRange {...props} />
    }
    if (props.rangeStart || props.rangeEnd) {
        return <CalendarItemPoint {...props} />
    }
    return (
        <button
            data-testid={format(props.time, 'MM/dd')}
            onMouseEnter={() => {
                updateHoverDate(props.time)
            }}
            onMouseLeave={() => {
                updateHoverDate(null)
            }}
            onClick={() => {
                clickDate(props.time)
            }}
            className={classNames(
                styles.calendar_item,
                'color-$wk-v2-label-color-gray-13 rounded-3px hover:bg-$wk-gray-13-4',
                props.overing && '!bg-$wk-brand-1 !rounded-0'
            )}
            key={props.time}
        >
            <span>{props.label}</span>
            <TodyBadge {...props} />
        </button>
    )
}

const CalendarBody = () => {
    const { dates } = useCalendarrContext()
    return (
        <div className="mt-3 wk-text-12">
            <div data-testid="weeks" className="flex h-7 mb-2">
                {(isEnglishLanguage() ? DAYS_ABROAD : DAYS).map((day) => (
                    <div className="flex-1 flex items-center justify-around color-$wk-gray-6" key={day}>
                        <span>{day}</span>
                    </div>
                ))}
            </div>
            {dates.map((items, idx) => {
                return (
                    <div data-testid="calendar-body-item" className="flex h-7 mt-1" key={idx}>
                        {items.map((item) => {
                            return <CalendarItem {...item} key={item.time} />
                        })}
                    </div>
                )
            })}
        </div>
    )
}

export const Calendar: FC<CalendarProps> = (props) => {
    return (
        <CalendarrProvider {...props}>
            <CalendarTitle />
            <CalendarBody />
        </CalendarrProvider>
    )
}
