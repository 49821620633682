import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useCallback, useMemo, useState } from 'react'
import { MulticolorIconCommonCollection24, WKButton, WKTypography } from '../../../../../../ui-lib/src'
import { capitalizeFirstLetter, isEnglishLanguage, timeDesc } from '../../../../../../util/src'
import { featureSwitchManager } from '../../../../kernel/switch'
import { useLibraryComponentService } from '../../../../main/app-context'
import { LibraryThumbnailImage } from '../../../../share/component-style-library/library-thumbnail-image'
import { LibraryTestId } from '../../../../window'
import { VariableIconBlock } from './library-remote-change-list-item-icon'
import classes from './library-remote-change-list-item.module.less'
import { translation } from './library-remote-change-list-item.translation'

function LibraryRemoteChangeSingleUpdateBtnV2({
    item,
}: {
    item: Wukong.DocumentProto.IVLibraryComponentUpdateModalPublishMixinItem
}) {
    const { upgradeComponentsExecutor } = useLibraryComponentService()
    const [loading, setLoading] = useState(false)

    const updateVersion = useCallback(async () => {
        setLoading(true)
        await upgradeComponentsExecutor.singleRun(item)
        setLoading(false)
    }, [item, upgradeComponentsExecutor])

    return (
        <WKButton
            size="small"
            type="secondary"
            onClick={updateVersion}
            data-testid={LibraryTestId.RemoteUpdateModal.SingleUpdateBtn}
            loading={loading}
            className="flex-none ml-2"
        >
            {translation('Update')}
        </WKButton>
    )
}

function LibraryRemoteChangeVariableUpdateBtnV2({
    items,
}: {
    items: Wukong.DocumentProto.IVLibraryComponentUpdateModalPublishMixinItem[]
}) {
    const { upgradeComponentsExecutor } = useLibraryComponentService()
    const [loading, setLoading] = useState(false)

    const updateVersion = useCallback(async () => {
        setLoading(true)
        await upgradeComponentsExecutor.runVariables(items)
        setLoading(false)
    }, [items, upgradeComponentsExecutor])

    return (
        <WKButton
            size="small"
            type="secondary"
            onClick={updateVersion}
            data-testid={LibraryTestId.RemoteUpdateModal.SingleUpdateBtn}
            loading={loading}
            className="flex-none ml-2"
        >
            {translation('Update')}
        </WKButton>
    )
}

// 引用组件库-更新列表-组件
function RemoteLibraryItemChangeViewer({
    item,
}: {
    item: Wukong.DocumentProto.IVLibraryComponentUpdateModalPublishMixinItem | null
}) {
    const fallbackName = useMemo(() => {
        switch (item?.upgradeType) {
            case Wukong.DocumentProto.UpgradePublishMixinVersionType.UPGRADE_PUBLISH_MIXIN_VERSION_TYPE_COMPONENT:
                return translation('UnknownComponent')
            case Wukong.DocumentProto.UpgradePublishMixinVersionType.UPGRADE_PUBLISH_MIXIN_VERSION_TYPE_COMPONENT_SET:
                return translation('UnknownComponentSet')
            case Wukong.DocumentProto.UpgradePublishMixinVersionType.UPGRADE_PUBLISH_MIXIN_VERSION_TYPE_STYLE:
                return translation('UnknownStyle')
            default:
                return ''
        }
    }, [item?.upgradeType])

    if (!item) {
        return null
    }
    return (
        <div className={classes.itemContainer}>
            <div className={classes.leftContainer}>
                <div className={classes.thumbnailImageWrapper}>
                    <LibraryThumbnailImage className={classes.thumbnailImage} thumbnailData={item.thumbnailData} />
                </div>
                <div className="flex-1 overflow-hidden">
                    <WKTypography.Paragraph className="w-full truncate wk-text-12">
                        {item.name || fallbackName}
                    </WKTypography.Paragraph>
                    {!!item.movedOldDocName && (
                        <WKTypography.Paragraph className="w-full truncate text-gray">
                            {isEnglishLanguage() ? (
                                <>Moved from &quot;{item.movedOldDocName}&quot;</>
                            ) : (
                                <>从 {item.movedOldDocName} 被移走</>
                            )}
                        </WKTypography.Paragraph>
                    )}
                </div>
            </div>
            <LibraryRemoteChangeSingleUpdateBtnV2 item={item} />
        </div>
    )
}

// 引用组件库-更新列表-变量展示图标
function RemoteLibraryVariableItemChangeViewerIcon({
    items,
}: {
    items: Wukong.DocumentProto.IVLibraryComponentUpdateModalPublishMixinItem[]
}) {
    const types = useMemo(() => {
        const set = new Set(
            items
                .map(
                    (item) =>
                        item.thumbnailData.variableIconType ??
                        Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_INVALID
                )
                .filter(
                    (type) => type !== Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_INVALID
                )
        )
        // 顺序是固定的，按颜色、数值、文本、布尔来展示
        const array: Wukong.DocumentProto.VariableResolvedDataType[] = []
        if (set.has(Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR)) {
            array.push(Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR)
        }
        if (set.has(Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT)) {
            array.push(Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT)
        }
        if (set.has(Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_STRING)) {
            array.push(Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_STRING)
        }
        if (set.has(Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_BOOLEAN)) {
            array.push(Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_BOOLEAN)
        }
        return array
    }, [items])
    return (
        <div className={classNames(classes.thumbnailImageWrapperV2, classes[`block-size-${types.length}`])}>
            {Array.from(types).map((type, index) => (
                <VariableIconBlock
                    key={type}
                    type={type}
                    className={classNames(classes.thumbnailImage, classes[`block-${index + 1}`])}
                />
            ))}
        </div>
    )
}

// 引用组件库-更新列表-变量
function RemoteLibraryVariableItemChangeViewer({
    items,
}: {
    items: Wukong.DocumentProto.IVLibraryComponentUpdateModalPublishMixinItem[] | null
}) {
    if (!items || items.length === 0) {
        return null
    }
    return (
        <div className={classes.itemContainer}>
            <div className={classes.leftContainer}>
                {featureSwitchManager.isEnabled('float-variable') ? (
                    <RemoteLibraryVariableItemChangeViewerIcon items={items} />
                ) : (
                    <div className={classes.thumbnailImageWrapper}>
                        <MulticolorIconCommonCollection24 className={classes.thumbnailImage} />
                    </div>
                )}
                <div className="flex-1 overflow-hidden">
                    <WKTypography.Paragraph className="w-full truncate wk-text-12">
                        {items[0].name || translation('UnknownVariableSet')}
                    </WKTypography.Paragraph>
                </div>
            </div>
            <LibraryRemoteChangeVariableUpdateBtnV2 items={items} />
        </div>
    )
}

// 引用组件库-更新记录
export function LibraryRemoteChangeListItem({
    remoteChange,
}: {
    remoteChange: Wukong.DocumentProto.IVLibraryComponentUpdateModalItem
}) {
    const variables = useMemo(() => {
        return Object.values(
            remoteChange.items.reduce((acc, item) => {
                if (
                    item.upgradeType !==
                        Wukong.DocumentProto.UpgradePublishMixinVersionType
                            .UPGRADE_PUBLISH_MIXIN_VERSION_TYPE_VARIABLE ||
                    !item.collectionId
                ) {
                    return acc
                }
                if (!acc[item.collectionId]) {
                    acc[item.collectionId] = []
                }
                acc[item.collectionId].push(item)
                return acc
            }, {} as Record<string, Wukong.DocumentProto.IVLibraryComponentUpdateModalPublishMixinItem[]>)
        )
    }, [remoteChange.items])
    const others = useMemo(() => {
        return remoteChange.items.filter(
            (item) =>
                item.upgradeType !==
                Wukong.DocumentProto.UpgradePublishMixinVersionType.UPGRADE_PUBLISH_MIXIN_VERSION_TYPE_VARIABLE
        )
    }, [remoteChange.items])
    return (
        <div
            className={classes.container}
            data-testid={LibraryTestId.RemoteUpdateModal.ChangeItem(remoteChange.name ?? '')}
        >
            <WKTypography.Paragraph className={classes.name} weight="semibold">
                {remoteChange.name || translation('UnknownLibrary')}
            </WKTypography.Paragraph>
            {!!remoteChange.operationTime && (
                <WKTypography.Paragraph color="placeholder" className={classes.operationTime}>
                    {capitalizeFirstLetter(timeDesc(Number(remoteChange.operationTime)))}
                </WKTypography.Paragraph>
            )}
            {!!remoteChange.description && (
                <WKTypography.Paragraph className={classes.description}>
                    {remoteChange.description}
                </WKTypography.Paragraph>
            )}
            <>
                {variables.map((items) => (
                    <RemoteLibraryVariableItemChangeViewer key={items[0].nodeKey} items={items} />
                ))}
                {others.map((item) => (
                    <RemoteLibraryItemChangeViewer key={item.nodeKey} item={item} />
                ))}
            </>
        </div>
    )
}
