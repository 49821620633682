/**
 * @owner: loushuangzhanbj@kanyun.com
 */
import { HtmlInput } from './html-input/html-input'
import { NormalInput } from './normal-input/normal-input'
import { PasswordInput } from './password-input/password-input'
import { SearchInput } from './search-input/search-input'
import { TagInput } from './tag-input/tag-input'
import { Textarea } from './textarea/textarea'

const Input: typeof NormalInput & {
    Password: typeof PasswordInput
    Search: typeof SearchInput
    Textarea: typeof Textarea
    Tag: typeof TagInput
    Html: typeof HtmlInput
} = Object.assign(NormalInput, {
    Password: PasswordInput,
    Search: SearchInput,
    Textarea: Textarea,
    Tag: TagInput,
    Html: HtmlInput,
})

export type { NormalInputProps as InputV2Props, NormalInputRef as InputV2Ref } from './normal-input/normal-input'
export type { SearchInputProps, SearchInputRef } from './search-input/search-input'
export type { TagInputProps } from './tag-input/tag-input'
export type { TextareaProps } from './textarea/textarea'
export { trimInputValue } from './utils/trim'
export { Input as InputV2 }
