import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    contrast: '对比度',
    sourceColor: '源色',
    standard: '标准',
    medium: '中等',
    high: '高',
    secondaryColor: '次级色',
    tertiaryColor: '三级色',
    errorColor: '错误色',
    neutralColor: '中性色',
    neutralVariantColor: '中性变体',
    successColor: '成功色',
    warningColor: '警告色',
    linkColor: '链接色',
    textBaseColor: '基础文本色',
    bgBaseColor: '基础背景色',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    contrast: 'Contrast',
    sourceColor: 'Source Color',
    standard: 'Standard',
    medium: 'Medium',
    high: 'High',
    secondaryColor: 'Secondary Color',
    tertiaryColor: 'Tertiary Color',
    errorColor: 'Error Color',
    neutralColor: 'Neutral Color',
    neutralVariantColor: 'Neutral Variant Color',
    successColor: 'Success Color',
    warningColor: 'Warning Color',
    linkColor: 'Link Color',
    textBaseColor: 'Text Base Color',
    bgBaseColor: 'Bg Base Color',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
