import { uniqBy } from 'lodash-es'
import { toMixed } from '../../../../../../ui-lib/src'
import { FontInfo, FontName, FontWidth } from '../../../../document/node/node'

export function getStyleGroup(fontInfo: FontInfo) {
    const weight: FontName[] = []
    const weightWidth: FontName[] = []
    const weightSlant: FontName[] = []
    const weightWidthSlant: FontName[] = []
    for (const style of fontInfo.styles) {
        const emptySlant = !style.italic
        const emptyWidth = style.width === FontWidth.InvisibleWidth || style.width === FontWidth.NormalWidth
        if (emptySlant && emptyWidth) {
            weight.push(style)
        } else if (emptySlant) {
            weightWidth.push(style)
        } else if (emptyWidth) {
            weightSlant.push(style)
        } else {
            weightWidthSlant.push(style)
        }
    }
    weight.sort((styleA, styleB) => styleA.weight - styleB.weight)
    weightWidth.sort((styleA, styleB) => styleA.weight - styleB.weight)
    weightSlant.sort((styleA, styleB) => styleA.weight - styleB.weight)
    weightWidthSlant.sort((styleA, styleB) => styleA.weight - styleB.weight)
    return {
        weight,
        weightWidth,
        weightSlant,
        weightWidthSlant,
    }
}

const isMixedFamily = (fontNames: FontName[] = []) => {
    return uniqBy(fontNames, (v) => v.family).length > 1
}

export const toSelectedFamily = (fontNames: FontName[] = []) => {
    const mixed = isMixedFamily(fontNames)

    return {
        mixed,
        family: mixed ? toMixed() : fontNames[0]?.family || '',
        localizedFamily: mixed ? toMixed() : fontNames[0]?.localizedFamily || '',
    } as const
}

export const skFourByteTagToString = (num: number) => {
    const char1 = String.fromCharCode((num >> 24) & 0xff) // 提取最高 8 位
    const char2 = String.fromCharCode((num >> 16) & 0xff) // 提取次高 8 位
    const char3 = String.fromCharCode((num >> 8) & 0xff) // 提取次低 8 位
    const char4 = String.fromCharCode(num & 0xff) // 提取最低 8 位

    return char1 + char2 + char3 + char4
}

export const fourByteStringToNumber = (str: string) => {
    return (str.charCodeAt(0) << 24) | (str.charCodeAt(1) << 16) | (str.charCodeAt(2) << 8) | str.charCodeAt(3)
}
