import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    EffectStyles: '效果样式',
    MultipleEffects: '多层效果样式',
    BackgroundBlur: '背景模糊',
    LayerBlur: '图层模糊',
    DropShadow: '投影',
    InnerShadow: '内阴影',
} as const

export const enTranslation = {
    EffectStyles: 'Effect styles',
    MultipleEffects: 'Multiple effects',
    BackgroundBlur: 'Background blur',
    LayerBlur: 'Layer blur',
    DropShadow: 'Drop shadow',
    InnerShadow: 'Inner shadow',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
