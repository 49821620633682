import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    FontSize: '字号',
    LineHeight: '行高',
    Mixed: '多个文本属性',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    FontSize: 'Size',
    LineHeight: 'Line height',
    Mixed: 'Mixed styles',
}

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
