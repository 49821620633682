import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    GjQlXC: '即将达到浏览器可用内存上限，继续编辑将有数据丢失的风险',
    TJmWXw: '为保证稳定流畅的操作该文件，请删减部分内容或拆分文件使内存使用量小于{{memory}}',
} as const

export const enTranslation = {
    GjQlXC: 'Insufficient browser memory, unable to continue editing.',
    TJmWXw: 'Try removing unneeded content to free up memory and keep things running smoothly.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
