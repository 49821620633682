import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    LocalComponents: '本地组件',
    Hidden: '隐藏的组件',
    ComponentsUsedIn: '此文件使用组件',
    UnknownLibrary: '未知组件库',
} as const

export const enTranslation = {
    LocalComponents: 'Local components',
    Hidden: 'Hidden',
    ComponentsUsedIn: 'Components used in this file',
    UnknownLibrary: 'Unknown library',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
