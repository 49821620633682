import classNames from 'classnames'

export function EmptyPrompt(props: { texts: string[] }) {
    return (
        <div
            className={classNames('flex h-full items-center justify-center text-$wk-gray-8 flex-col')}
            style={{
                fontSize: 'var(--wk-font-size-h4)',
                lineHeight: 'var(--wk-line-height-h4)',
                fontWeight: 'var(--wk-font-weight-regular)',
            }}
        >
            {props.texts.map((text, _) => (
                <span key={text}>{text}</span>
            ))}
        </div>
    )
}
