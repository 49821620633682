import { computed } from 'ccstate'
import { traceable } from '../../../../util/src/ccstate-helper/traceable'
import { PlanAndUserStatesInfo, ResourceType } from '../../kernel/interface/type'
import { getDocRequest } from '../../kernel/request/document'
import { QueryOrgAndUserState } from '../../kernel/request/organizations'
import { QueryTeamAndUserState } from '../../kernel/request/team'
import { isFileCreated$ } from '../../main/create-file-manager'
import { editorDocId$, isPresentPath$ } from './editor-context'
import { ticket$ } from './ticket'

export const payment$ = traceable(
    'hulh01@kanyun.com',
    computed(async (get) => {
        if (get(isFileCreated$)) {
            return undefined
        }

        // 服务端的限制：payment 的获取必须在 ticket 之后，所以这里需要先获取 ticket 再获取 payment
        await get(ticket$)

        return await get(asyncPayment$)
    })
)

const asyncPayment$ = traceable(
    'hulh01@kanyun.com',
    computed(async (get) => {
        const docId = get(editorDocId$)

        if (!docId) {
            return undefined
        }

        const docInfo = await getDocRequest(docId, get(isPresentPath$))

        if (docInfo.orgId !== '-1') {
            const getOrgPaymentInfo = await new QueryOrgAndUserState(docInfo.orgId).start()
            return {
                org: getOrgPaymentInfo,
                teams: [],
                doc: getOrgPaymentInfo,
                docIsDraft: docInfo.draft,
            } as PlanAndUserStatesInfo
        } else {
            const getTeamPaymentInfo = await new QueryTeamAndUserState().start()
            const planInDoc = getTeamPaymentInfo.find(
                (plan) => plan.resourceType === ResourceType.Team && plan.resourceId === docInfo.teamId
            )

            return {
                org: undefined,
                teams: getTeamPaymentInfo,
                doc: planInDoc,
                docIsDraft: docInfo.draft,
            } as PlanAndUserStatesInfo
        }
    })
)
