import { translation } from './reply-emoji-pick.translation'
/* eslint-disable no-restricted-imports */
import classNames from 'classnames'
import { useCallback, useRef, useState } from 'react'
import { MinDistanceNearEdge, Popup, Tooltip } from '../../../../../../ui-lib/src'
import { useCanvas } from '../../../../external-store/atoms/create-canvas'
import { ReactComponent as SvgEmojiAdd } from '../../../assets/comment/emoji-add.svg'
import { IconButton } from '../../atom/button/icon-button'
import { UsualEmoji, usualEmojis } from './common'
import classes from './reply-emoji-pick.module.less'

export interface ReplyEmojiPickProps {
    className?: string
    onEmojiPick?: EmojiItemProps['onEmojiPick']
    onEmojiPickStart?: () => void
    onEmojiPickEnd?: () => void
}
export function ReplyEmojiPick(props: ReplyEmojiPickProps) {
    const { onEmojiPickStart, onEmojiPickEnd } = props
    const emojiPickRef = useRef<HTMLSpanElement>(null)
    const [isOpenEmojiPick, setIsOpenEmojiPick] = useState<boolean>(false)
    const minDistanceNearEdge = useRef<MinDistanceNearEdge>([0, 0, 0, 0])
    const canvas = useCanvas()

    const updateMinDistanceNearEdge = useCallback(() => {
        const { clientWidth, clientHeight } = document.documentElement
        const rect = canvas.getBoundingClientRect()
        minDistanceNearEdge.current = [
            rect.top + 8,
            clientWidth - rect.right + 8,
            clientHeight - rect.bottom + 8,
            rect.left + 8,
        ]
    }, [canvas])

    const switchEmojiPickOpen = useCallback(() => {
        const nextOpenState = !isOpenEmojiPick
        if (nextOpenState) {
            updateMinDistanceNearEdge()
            onEmojiPickStart?.()
        } else {
            onEmojiPickEnd?.()
        }
        setIsOpenEmojiPick(nextOpenState)
    }, [isOpenEmojiPick, onEmojiPickEnd, onEmojiPickStart, updateMinDistanceNearEdge])

    const onEmojiPick = useCallback(
        (emojiInfo: UsualEmoji) => {
            props.onEmojiPick?.(emojiInfo)
            switchEmojiPickOpen()
        },
        [props, switchEmojiPickOpen]
    )

    return (
        <>
            <Tooltip title={translation('AddReaction')}>
                <IconButton
                    ref={emojiPickRef}
                    className={classNames(props.className, classes.normalEmoji, {
                        [classes.activeEmoji]: isOpenEmojiPick,
                    })}
                    selected={false}
                    icon={<SvgEmojiAdd />}
                    onClick={switchEmojiPickOpen}
                />
            </Tooltip>
            {isOpenEmojiPick ? (
                <Popup
                    handle={emojiPickRef.current}
                    isOpen={true}
                    needTail={true}
                    needModal={true}
                    onClose={switchEmojiPickOpen}
                    position="bottom left"
                    minDistanceNearEdge={minDistanceNearEdge.current}
                >
                    <div className={classes.replyEmojiPick}>
                        {usualEmojis.map((emojiInfo) => (
                            <EmojiItem key={emojiInfo.id} emojiInfo={emojiInfo} onEmojiPick={onEmojiPick} />
                        ))}
                    </div>
                </Popup>
            ) : null}
        </>
    )
}

interface EmojiItemProps {
    emojiInfo: UsualEmoji
    onEmojiPick?: (emojiInfo: UsualEmoji) => void
}
export function EmojiItem(props: EmojiItemProps) {
    const { emojiInfo, onEmojiPick } = props
    const onClick = useCallback(() => {
        onEmojiPick?.(emojiInfo)
    }, [emojiInfo, onEmojiPick])
    return (
        <span key={emojiInfo.id} className={classes.emojiItem} onClick={onClick}>
            {emojiInfo.emoji}
        </span>
    )
}
