/**
 * @owner: chenxiangbj@kanyun.com
 */
import classNames from 'classnames'
import { FC, useMemo, useState } from 'react'
import { useInterval } from 'react-use'
import { MonoIconCommonLoading16, MonoIconCommonLoading24, MonoIconCommonLoading48 } from '../../icons-v2'
import { WKTypography } from '../wk-typography'
import { translation } from './wk-loading.translation'
interface WKLoadingProps {
    size?: 'small' | 'default' | 'large'
    direction?: 'horizontal' | 'vertical'
    noText?: boolean
    noIcon?: boolean
    text?: string
    textClassName?: string
    color?: 'default' | 'blue' | 'white'
    className?: string
}

export const WKLoading: FC<WKLoadingProps> = (props) => {
    const { color, size = 'default', direction, noIcon, noText, className, text, textClassName } = props
    const colorStyles = useMemo(() => {
        switch (color) {
            case 'blue':
                return '!color-$wk-brand-6'
            case 'white':
                return '!color-white'
            case undefined:
            case 'default':
                return '!color-$wk-v2-label-color-gray-8'
        }
    }, [color])
    const iconStyles = classNames('animate-spin', colorStyles)
    const icon = useMemo(() => {
        switch (size) {
            case 'small':
                return <MonoIconCommonLoading16 data-testid="icon-loading-svg" className={iconStyles} />
            case 'default':
                return <MonoIconCommonLoading24 data-testid="icon-loading-svg" className={iconStyles} />
            case 'large':
                return <MonoIconCommonLoading48 data-testid="icon-loading-svg" className={iconStyles} />
        }
    }, [iconStyles, size])

    const directionStyles = direction == 'horizontal' ? 'flex-row' : 'flex-col'

    const textSize = useMemo(() => {
        switch (size) {
            case 'small':
            case 'default':
                return 12
            case 'large':
                return 14
        }
    }, [size])

    const [dots, setDots] = useState('...')
    // 没有图标的时候，后面的 dot 要动起来
    useInterval(
        () => {
            setDots(dots === '...' ? '.' : dots === '.' ? '..' : '...')
        },
        noIcon ? 500 : null
    )

    return (
        <div className={classNames('inline-flex gap-1 items-center justify-center', directionStyles, className)}>
            {!noIcon && icon}
            {!noText && (
                <WKTypography.Text size={textSize} className={classNames(colorStyles, textClassName)}>
                    {text ?? translation('loading')}
                    <span className="select-none relative">
                        <span className="opacity-0">...</span>
                        <span className="absolute left-0">{dots}</span>
                    </span>
                </WKTypography.Text>
            )}
        </div>
    )
}
