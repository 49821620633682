import { useState, useEffect } from 'react'

export function useDerivedState<T>(originState: T): [T, (_: T) => void] {
    const [derivedState, setDerivedState] = useState(originState)

    useEffect(() => {
        setDerivedState(originState)
    }, [originState])

    return [derivedState, setDerivedState]
}

/**
 * 对 Map 执行 map 操作
 **/
export function mapForMap<K, V, T, U>(map: Map<K, V>, func: (k: K, v: V, i: number) => [T, U]): Map<T, U> {
    return new Map(Array.from(map.entries()).map(([k, v], i) => func(k, v, i)))
}
