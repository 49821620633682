/* eslint-disable no-restricted-imports */
import { isDocumentVisible } from '../../../../util/src'
import { UserVOV2 } from '../../kernel/interface/account'
import { DocWithAuthorityVO } from '../../kernel/interface/type'

class _WKFrogTaskStateService {
    private docGetVO: DocWithAuthorityVO | undefined = undefined
    private userVOV2: UserVOV2 | undefined = undefined

    private alreadyRecordBrowserPageVisibility = false
    private hiddenVisibleHistoryTimestampMap: Map<number, number> = new Map()
    private hiddenTimestamp: number | undefined = undefined

    // 在编辑器内被初始化
    public setDocGetVO = (docGetVO: Readonly<DocWithAuthorityVO | undefined>) => {
        this.docGetVO = docGetVO
    }
    // 在编辑器内被初始化
    public getDocGetVO = (): Readonly<DocWithAuthorityVO | undefined> => {
        return this.docGetVO
    }
    // 在编辑器内被初始化
    public setUserVOV2 = (docGetVO: Readonly<UserVOV2 | undefined>) => {
        this.userVOV2 = docGetVO
    }
    // 在编辑器内被初始化
    public getUserVOV2 = (): Readonly<UserVOV2 | undefined> => {
        return this.userVOV2
    }

    // 解决 log 参数 duration 时间问题
    private visibilitychangeCallback = () => {
        if (isDocumentVisible()) {
            if (typeof this.hiddenTimestamp === 'number') {
                this.hiddenVisibleHistoryTimestampMap.set(this.hiddenTimestamp, Date.now())
            }
            this.hiddenTimestamp = undefined
        } else {
            this.hiddenTimestamp = Date.now()
        }
    }

    public recordBrowserPageVisibility = () => {
        if (this.alreadyRecordBrowserPageVisibility) {
            return
        }
        this.alreadyRecordBrowserPageVisibility = true
        document.addEventListener('visibilitychange', this.visibilitychangeCallback)
        // safari 切换浏览器 tab 不触发 visibilitychange， 用 focus + blur
        document.addEventListener('focus', this.visibilitychangeCallback)
        document.addEventListener('blur', this.visibilitychangeCallback)
    }

    public getDurationMilliseconds = (startTimestamp: number) => {
        let durationTimestamp = Date.now() - startTimestamp
        for (const [hiddenStartTime, visibleStartTime] of this.hiddenVisibleHistoryTimestampMap) {
            if (startTimestamp >= visibleStartTime) {
                continue
            }
            if (startTimestamp <= hiddenStartTime) {
                durationTimestamp -= visibleStartTime - hiddenStartTime
                continue
            }
            durationTimestamp -= visibleStartTime - startTimestamp
        }
        return durationTimestamp
    }
}

export const WKFrogTaskStateService = new _WKFrogTaskStateService()
