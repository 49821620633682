import { translation } from './paint-var.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import * as React from 'react'
import { useCallback } from 'react'
import { MonoIconPanelTarget16, Tooltip, WKIconButton } from '../../../../../../ui-lib/src'
import type { Paint as TypePaint } from '../../../../document/node/node'
import { usePosition } from '../../../utils/use-position'
import { SingleGrid } from '../../atom/grid/single-grid'
import { ColorVarItem } from '../../color-var-item'
import { ColorSpace } from '../blend/color-picker/utils/color-translate'
import { ColorInteraction } from '../color-interaction/color-interaction'
import { ColorInteractionFrom, TypeOnChangeStyle } from '../color-interaction/type'
import style from './paint-var.module.less'

interface PaintProps {
    paint: TypePaint
    colorVar: Wukong.DocumentProto.ISingleVariable
    colorSpace: ColorSpace
    isOpen?: boolean
    selected?: boolean
    usingNodeCount: number
    onClickAimIcon?: (data: any) => void
    onChangeModalVisible?: (visible: boolean) => void
    onDetachColorVar?: () => void
    onChangeStyle: TypeOnChangeStyle
    onChangeColorVar: (colorVar: Wukong.DocumentProto.IVariableAliasData) => void
    onChangePaints: (paints: Wukong.DocumentProto.IPaint[]) => void
}
function _PaintVar(props: PaintProps, outRef: React.Ref<HTMLDivElement>) {
    const {
        paint,
        colorVar,
        isOpen,
        selected,
        usingNodeCount,
        onChangeModalVisible,
        onClickAimIcon,
        onDetachColorVar,
    } = props
    const { position, reactRef } = usePosition({ isOpen, outRef })

    const onClose = useCallback(() => {
        onChangeModalVisible?.(false)
    }, [onChangeModalVisible])

    const _onMouseDown = useCallback(() => {
        onChangeModalVisible?.(!isOpen)
    }, [isOpen, onChangeModalVisible])

    const _onClickDetach = useCallback(() => {
        onDetachColorVar?.()
    }, [onDetachColorVar])

    return (
        <div>
            <SingleGrid ref={reactRef} className={classNames(style.selectColor, style.v2, selected && style.selected)}>
                <SingleGrid.Item start={5} span={46}>
                    <ColorVarItem
                        paint={paint}
                        colorSpace={props.colorSpace}
                        variable={colorVar}
                        selected={selected}
                        onMouseDownItem={_onMouseDown}
                        onClickDetach={_onClickDetach}
                    />
                </SingleGrid.Item>

                <SingleGrid.Item start={51} span={12} className={style.selectColorIcon} horizontalCenter>
                    <Tooltip
                        title={translation('SelectItemsUsing', {
                            usingNodeCount: String(usingNodeCount),
                            item: usingNodeCount > 1 ? 'items' : 'item',
                        })}
                        maxWidth={232}
                    >
                        <WKIconButton
                            icon={<MonoIconPanelTarget16 />}
                            onClick={onClickAimIcon}
                            data-simple-drag-ignore
                            data-testid="select-color-inspect-paint-var"
                        />
                    </Tooltip>
                </SingleGrid.Item>
            </SingleGrid>
            {isOpen && position && (
                <ColorInteraction
                    from={ColorInteractionFrom.SELECTION_COLOR}
                    position={position}
                    onCancel={onClose}
                    paints={[paint]}
                    onChangeStyle={props.onChangeStyle}
                    onChangeColorVar={props.onChangeColorVar}
                    onChangePaints={props.onChangePaints}
                    styleId={undefined}
                    onChangePaintType={() => {}}
                    onChangeBlendMode={() => {}}
                    onChangeColor={() => {}}
                    onChangeOpacity={() => {}}
                    onChangeColorStops={() => {}}
                    onChangeTransform={() => {}}
                    enterClose={() => {}}
                    onChangeImagePaint={() => {}}
                    onClickCreateStyleButton={undefined}
                />
            )}
        </div>
    )
}

export const PaintVar = React.forwardRef(_PaintVar)
