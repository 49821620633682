import { RefObject, useEffect } from 'react'
import { isNil } from 'lodash-es'

/**
 * 懒加载 Hook
 * @param targetRef 监听的 Dom 元素
 * @param loadFn 在 Dom 元素第一次出现在可视区域内触发
 * @param debounceTime 防抖时长（单位为 ms）
 * @param deps 变化时会重置监听器，使得 loadFn 可再次触发
 */
export function useLazyLoad(
    {
        targetRef,
        loadFn,
        debounceTime,
    }: {
        targetRef: RefObject<HTMLElement>
        loadFn: () => void | Promise<void>
        debounceTime?: number
    },
    deps: any[]
) {
    useEffect(() => {
        const fakeIntersectionDom = targetRef.current
        let io: IntersectionObserver | undefined
        let setDebounceTimer: NodeJS.Timeout | undefined

        function clearDebounceTimer() {
            if (setDebounceTimer) {
                clearTimeout(setDebounceTimer)
                setDebounceTimer = undefined
            }
        }
        if (fakeIntersectionDom) {
            // 懒加载
            io = new IntersectionObserver((entries) => {
                const handler = () => {
                    loadFn()
                    io?.disconnect()
                    clearDebounceTimer()
                }

                entries.forEach((item) => {
                    if (item.intersectionRatio > 0) {
                        if (!isNil(debounceTime)) {
                            if (!setDebounceTimer) {
                                setDebounceTimer = setTimeout(handler, debounceTime)
                            }
                        } else {
                            handler()
                        }
                    } else {
                        clearDebounceTimer()
                    }
                })
            })
            io.observe(fakeIntersectionDom)
        }
        return () => {
            if (fakeIntersectionDom) {
                io?.disconnect()
            }
            clearDebounceTimer()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetRef, loadFn, debounceTime, ...deps])
}
