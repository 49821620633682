/* eslint-disable no-restricted-imports */
import { EmBridge } from '../../kernel/bridge/em-bridge'
import { WkCLog } from '../../kernel/clog/wukong/instance'
import { getReplayDownloadUrl } from '../../kernel/interface/replay'
import { Sentry } from '../../kernel/sentry'

type TraceEvent = {
    traceEventName: string
    traceEventKey: number
    msg: string
} & Record<string, any>

const formatters = new Map<number, (this: Record<string, any>) => string>()

const INCLUDE_STACK = false
// const INCLUDE_STACK = true // 本地测试的时候可以打开这行注释

if (INCLUDE_STACK) {
    Error.stackTraceLimit = 30
}

export function handleTraceEvent(bridge: EmBridge, traceEvent: TraceEvent, includesStack: boolean) {
    if (traceEvent.msg) {
        const msg = getFormatter(traceEvent).call(traceEvent)
        if (includesStack || INCLUDE_STACK) {
            const err = new Error(msg)
            const recordingName = bridge.currentRecordingName
            Sentry.captureException(
                err,
                // 记录下载回放的地址
                recordingName
                    ? {
                          extra: {
                              replayDownloadUrl: getReplayDownloadUrl(recordingName),
                          },
                      }
                    : undefined
            )
            traceEvent = { ...traceEvent, stack: err.stack }
        }
        WkCLog.throttleLog(msg, traceEvent)
    }
}

export function formatTraceEvent(traceEvent: TraceEvent) {
    return getFormatter(traceEvent).call(traceEvent)
}

function getFormatter(traceEvent: TraceEvent) {
    let formatter = formatters.get(traceEvent.traceEventKey)
    if (formatter) {
        return formatter
    }
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    formatter = new Function('with(this) { return `' + traceEvent.msg + '` }') as any
    formatters.set(traceEvent.traceEventKey, formatter!)
    return formatter!
}
