import { translation } from './select-color.translation'
/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { useMemo, useRef } from 'react'
import { IconMote, WKButton } from '../../../../../../ui-lib/src'
import type { Paint as TypePaint } from '../../../../document/node/node'
import { Paint as PaintType } from '../../../../document/node/node'
import { SingleGrid } from '../../atom/grid/single-grid'
import { Title } from '../../atom/title/title'
import { useRenderColorSpace } from '../../color-profile'
import { ColorInteraction } from '../color-interaction/color-interaction'
import { ColorInteractionFrom } from '../color-interaction/type'
import { getDefaultSolidPaint } from '../color-interaction/utils'
import { StylePaintColor } from '../styles/style-color/style-paint-color'
import { SelectionPaintType } from './hooks/use-selection-paint-model'
import { Paint } from './paint'
import { PaintVar } from './paint-var'
import style from './select-color.module.less'
import { useSelectColor } from './use-select-color'
import { ModifyStyleIdsFrom, useStyleSelector } from './use-style-selector'

export function shouldShowSelectColor(modelState: SelectionPaintType) {
    return (
        modelState.show &&
        (modelState.emptyDueToLimitExceeded ||
            modelState.paintInfos.length ||
            modelState.styleInfos.length ||
            modelState.paintVarInfos.length)
    )
}

export function SelectColor() {
    const {
        isShowAll,
        isShowAllStyle,
        onClickShowAll,
        onClickShowAllStyle,
        onDetachColorVar,
        isOpenV2,
        onChangeModalVisibleV2,
        onChangeColorV2,
        onChangeOpacityV2,
        onChangeBlendModeV2,
        onChangeColorStopsV2,
        onChangeTransformV2,
        onChangePaintTypeV2,
        onChangeColorVar,
        onChangePaints,

        modelState,
        modelCommand,
    } = useSelectColor()
    const {
        openStyle,
        stylePosition,
        currentOpenStyleId,
        onClickStyle,
        onChangeStyle,
        onClickCutLink,
        onChangeStyleWithColor,
        close,
    } = useStyleSelector()
    const colorSpace = useRenderColorSpace()

    const modifyStyleIdsFrom = useRef<ModifyStyleIdsFrom | undefined>()
    const stylePickPaints = useRef<PaintType[]>([])

    const selectedStyleNode = useMemo(() => {
        if (!currentOpenStyleId) {
            return
        }
        return modelState.styleInfos.find((styleInfo) => currentOpenStyleId == styleInfo.originStyleId)?.style
    }, [modelState.styleInfos, currentOpenStyleId])

    const renderSelectColorV2 = () => {
        return modelState.paintInfos.map(({ paint, originPaint, usingNodeCount }, index) => {
            if (!isShowAll && modelState.paintInfos.length > 3) {
                if (index === 3) {
                    return (
                        <SingleGrid onClick={onClickShowAll} key={'show_all_select_color'}>
                            <SingleGrid.Item start={5} span={46} className={style.showAllSelectColor}>
                                <span className={style.showAllSelectColor_icon}>
                                    <IconMote />
                                </span>
                                {translation('SeeAll')} {modelState.paintInfos.length} {translation('Colors')}
                            </SingleGrid.Item>
                        </SingleGrid>
                    )
                } else if (index > 3) {
                    return null
                }
            }

            const newIndex = index + modelState.paintVarInfos.length

            return (
                <div key={index} onMouseDown={(e) => e.stopPropagation()} data-testid={`select-color-paint-${index}`}>
                    <Paint
                        key={index}
                        paint={paint}
                        colorSpace={colorSpace}
                        isOpen={isOpenV2(newIndex)}
                        selected={isOpenV2(newIndex) || stylePickPaints.current.includes(paint)}
                        usingNodeCount={usingNodeCount}
                        onClickAimIcon={() => modelCommand.cmdSelectionColorsInspectColor(originPaint)}
                        onChangeOpacity={onChangeOpacityV2(newIndex)}
                        onChangeColor={onChangeColorV2(newIndex)}
                        onChangeModalVisible={onChangeModalVisibleV2(newIndex)}
                        onChangePaintType={onChangePaintTypeV2(newIndex)}
                        onChangeBlendMode={onChangeBlendModeV2(newIndex)}
                        onChangeColorStops={onChangeColorStopsV2(newIndex)}
                        onChangeTransform={onChangeTransformV2(newIndex)}
                        onChangeStyle={(styleId, needClosePopup, isCreate) =>
                            onChangeStyle(styleId, needClosePopup, { type: 'paint', paint, originPaint }, isCreate)
                        }
                        onChangeColorVar={onChangeColorVar(newIndex)}
                        onChangePaints={onChangePaints(newIndex)}
                    />
                </div>
            )
        })
    }

    const renderSelectLibrary = () => {
        const { styleInfos, paintVarInfos } = modelState

        return (
            <>
                {paintVarInfos.map(({ paint, originPaint, variable, usingNodeCount }, index) => {
                    if (!isShowAllStyle && paintVarInfos.length > 3) {
                        if (index === 3) {
                            return (
                                <SingleGrid onClick={onClickShowAllStyle} key={'show_all_select_style'}>
                                    <SingleGrid.Item start={5} span={46} className={style.showAllSelectColor}>
                                        <span className={style.showAllSelectColor_icon}>
                                            <IconMote />
                                        </span>
                                        {translation('SeeAll')} {paintVarInfos.length + styleInfos.length}{' '}
                                        {translation('LibraryColorsV2')}
                                    </SingleGrid.Item>
                                </SingleGrid>
                            )
                        } else if (index > 3) {
                            return null
                        }
                    }
                    return (
                        <div
                            key={index}
                            onMouseDown={(e) => e.stopPropagation()}
                            data-testid={`select-color-paint-var-${index}`}
                        >
                            <PaintVar
                                key={index}
                                paint={paint}
                                colorVar={variable}
                                colorSpace={colorSpace}
                                isOpen={isOpenV2(index)}
                                selected={isOpenV2(index)}
                                usingNodeCount={usingNodeCount}
                                onClickAimIcon={() => modelCommand.cmdSelectionColorsInspectColor(originPaint)}
                                onChangeModalVisible={onChangeModalVisibleV2(index)}
                                onDetachColorVar={onDetachColorVar(index)}
                                onChangeColorVar={onChangeColorVar(index)}
                                onChangePaints={onChangePaints(index)}
                                onChangeStyle={(styleId, needClosePopup, isCreate) =>
                                    onChangeStyle(
                                        styleId,
                                        needClosePopup,
                                        { type: 'paint', paint, originPaint },
                                        isCreate
                                    )
                                }
                            />
                        </div>
                    )
                })}
                {styleInfos.map(({ style: styleNode, originStyleId, usingNodeCount }, index) => {
                    const paints = styleNode.paints
                    if (!isShowAllStyle && styleInfos.length + paintVarInfos.length > 3) {
                        if (paintVarInfos.length + index === 3) {
                            return (
                                <SingleGrid onClick={onClickShowAllStyle} key={'show_all_select_style'}>
                                    <SingleGrid.Item start={5} span={46} className={style.showAllSelectColor}>
                                        <span className={style.showAllSelectColor_icon}>
                                            <IconMote />
                                        </span>
                                        {translation('SeeAll')} {styleInfos.length + paintVarInfos.length}{' '}
                                        {translation('LibraryColorsV2')}
                                    </SingleGrid.Item>
                                </SingleGrid>
                            )
                        } else if (paintVarInfos.length + index > 3) {
                            return null
                        }
                    }
                    return (
                        <StylePaintColor
                            key={index}
                            isUseColor
                            paints={paints as TypePaint[]}
                            name={styleNode.name}
                            description={styleNode.description}
                            isOpen={currentOpenStyleId === originStyleId}
                            usingNodeCount={usingNodeCount}
                            onClickStyle={(e) => {
                                modifyStyleIdsFrom.current = {
                                    type: 'style',
                                    styleId: styleNode.node,
                                    originStyleId,
                                    styleNodeKey: styleNode.key,
                                }
                                onClickStyle(e, originStyleId)
                                stylePickPaints.current = styleNode.paints as PaintType[]
                            }}
                            onClickCutLink={() => onClickCutLink(styleNode.node)}
                            onClickAimIcon={() => modelCommand.cmdSelectionColorsInspectStyle(styleNode.node)}
                        />
                    )
                })}
            </>
        )
    }

    const show = shouldShowSelectColor(modelState)

    return show ? (
        <div className={classnames('pt-8px pb-12px')} data-testid="design-panel-select-color">
            <Title>
                <Title.Left>{translation('SelectionColors')}</Title.Left>
            </Title>
            {modelState.emptyDueToLimitExceeded ? (
                <div className={style.forceUpdateContainer}>
                    <WKButton
                        type="secondary"
                        className={style.forceShow}
                        onClick={modelCommand.cmdUpdateInfiniteSelectionPaint}
                    >
                        {translation('ShowSelectionColors')}
                    </WKButton>
                </div>
            ) : (
                <>
                    {renderSelectLibrary()}
                    {renderSelectColorV2()}
                    {openStyle && stylePosition && (
                        <ColorInteraction
                            from={ColorInteractionFrom.SELECTION_COLOR}
                            position={stylePosition}
                            paints={stylePickPaints.current}
                            styleId={selectedStyleNode?.node}
                            styleKey={selectedStyleNode?.key ?? ''}
                            onChangeStyle={(styleId: string, needClosePopup: boolean, isCreate) =>
                                modifyStyleIdsFrom.current &&
                                onChangeStyle(styleId, needClosePopup, modifyStyleIdsFrom.current, isCreate)
                            }
                            onChangeColorVar={(v) => {
                                onChangeStyleWithColor(selectedStyleNode?.node ?? '', {
                                    ...getDefaultSolidPaint(),
                                    colorVar: v,
                                })
                            }}
                            onChangePaints={(paints) =>
                                onChangeStyleWithColor(selectedStyleNode?.node ?? '', paints[0])
                            }
                            onCancel={close}
                            onChangePaintType={() => {}}
                            onChangeBlendMode={() => {}}
                            onChangeColor={() => {}}
                            onChangeOpacity={() => {}}
                            onChangeImagePaint={() => {}}
                            onChangeColorStops={() => {}}
                            onChangeTransform={() => {}}
                            enterClose={() => {}}
                            onClickCreateStyleButton={undefined}
                        />
                    )}
                </>
            )}
        </div>
    ) : null
}
