export const collapse = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" stroke="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 11L10.5 8L6 5L6 11Z" fill="#c2c3c4" />
    </svg>
)

export const expand = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" stroke="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 6L8 10.5L11 6L5 6Z" fill="#c2c3c4" />
    </svg>
)
