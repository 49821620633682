/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { Effect, ITextStyle, Paint, TextStyleNode } from '../../../../../document/node/node'
import { getDefaultSolidPaint } from '../../../../utils/switch-paint-type'
import { getDefaultEffects } from '../../effects/context'
import { createITextStyle } from '../style-text/style-text-utils'
import { createTextStyleData } from './text-style-editor/utils'

export function getFirstFillPaintDefault() {
    return Object.assign(getDefaultSolidPaint(), {
        color: { r: 196, g: 196, b: 196 },
        opacity: 1,
    })
}

function getDefaultPaint(): Wukong.DocumentProto.IPaint {
    return Wukong.DocumentProto.Paint.create({
        type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
        visible: true,
        opacity: 0.1,
        blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
        color: { r: 255, b: 0, g: 0 },
    })
}

export function getDefaultLayoutGrid(): Wukong.DocumentProto.ILayoutGrid {
    return {
        pattern: Wukong.DocumentProto.LayoutGridPattern.LAYOUT_GRID_PATTERN_GRID,
        sectionSize: 10,
        visible: true,
        paints: getDefaultPaint(),
        align: Wukong.DocumentProto.RowsColsLayoutGridAlign.ROWS_COLS_LAYOUT_GRID_ALIGN_STRETCH,
        count: 5,
        gutterSize: 20,
        offset: 0,
    }
}

export function createCutPaintStyle(params?: { paints?: Paint[] }) {
    return Object.assign(
        {
            name: '',
            description: '',
            paints: [getFirstFillPaintDefault()],
            visible: false,
        },
        params
    )
}

export function createCutEffectStyle(params?: { effects?: Effect[] }) {
    return Object.assign(
        {
            name: '',
            description: '',
            effects: [getDefaultEffects()],
            visible: false,
        },
        params
    )
}

export function createCutTextStyle(
    params: ITextStyle & Pick<TextStyleNode, 'paragraphIndent' | 'paragraphSpacing'> = Object.assign(
        createTextStyleData(),
        { paragraphIndent: 0 }
    )
) {
    return Object.assign(
        createITextStyle(params),
        {
            paragraphIndent: params.paragraphIndent ?? 0,
            paragraphSpacing: params.paragraphSpacing ?? 0,
        },
        { name: '', visible: false }
    )
}

export function createCutLayoutGridStyle(params?: { layoutGrids?: Wukong.DocumentProto.ILayoutGrid[] }) {
    return Object.assign(
        {
            name: '',
            description: '',
            layoutGrids: [getDefaultLayoutGrid()],
            visible: false,
        },
        params
    )
}
