import { UpdateTextSettingTabKeyCommand, Wukong } from '@wukong/bridge-proto'
import { useCallback, useEffect } from 'react'
import { WKTabsV2 } from '../../../../../../ui-lib/src'
import { useCommand } from '../../../context/document-context'
import styles from './test-setting.module.less'
import { TextDetail, TextDetailProps } from './text-detail'
import { TextVariable } from './text-setting-variable'
import { translation } from './text-setting.translation'

const TextSettingTabKey = Wukong.DocumentProto.TextSettingTabKey

export function TextSetting(props: TextDetailProps) {
    const activeKey = props.selectionTextState.tabKey
    const command = useCommand()
    const handleActiveTabChange = useCallback(
        (tabKey: any) => {
            command.DEPRECATED_invokeBridge(UpdateTextSettingTabKeyCommand, { tabKey })
        },
        [command]
    )
    const showTextVariable = props.selectionTextState.variationInfo.hasValue

    useEffect(() => {
        if (!showTextVariable && activeKey != TextSettingTabKey.TEXT_SETTING_TAB_KEY_BASICS) {
            handleActiveTabChange(TextSettingTabKey.TEXT_SETTING_TAB_KEY_BASICS)
        }
    }, [activeKey, handleActiveTabChange, showTextVariable])

    return (
        <div data-testid="design-panel-text-setting">
            {showTextVariable && (
                <div className={'flex flex-row items-center gap-4'}>
                    <WKTabsV2
                        defaultActiveKey={TextSettingTabKey.TEXT_SETTING_TAB_KEY_BASICS}
                        activeKey={activeKey}
                        onActiveTabChange={handleActiveTabChange}
                        navListClassName={styles.navListClassName}
                    >
                        <WKTabsV2.Item
                            tabKey={TextSettingTabKey.TEXT_SETTING_TAB_KEY_BASICS}
                            label={translation('Basics')}
                        ></WKTabsV2.Item>
                        <WKTabsV2.Item
                            tabKey={TextSettingTabKey.TEXT_SETTING_TAB_KEY_VARIABLE}
                            label={translation('Variable')}
                        ></WKTabsV2.Item>
                    </WKTabsV2>
                </div>
            )}
            {activeKey == TextSettingTabKey.TEXT_SETTING_TAB_KEY_BASICS && <TextDetail {...props} />}
            {activeKey == TextSettingTabKey.TEXT_SETTING_TAB_KEY_VARIABLE && showTextVariable && (
                <TextVariable
                    variationInfo={props.selectionTextState.variationInfo}
                    styleNodeId={undefined}
                    readonly={false}
                />
            )}
        </div>
    )
}
