import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    "Can'tFindObject": '找不到目标团队，移动失败',
} as const

export const enTranslation = {
    "Can'tFindObject": "Can't find object team.failed to move",
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
