import { translation } from './image-filter.translation'
/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { useMemo } from 'react'
import type { ImageFilters } from '../../../../../document/node/node'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { ScrubbableInputInteger } from '../../../atom/inputs/scrubbable-input-integer'
import { Value } from '../../../atom/inputs/utils/type'
import { Range } from '../../../atom/range/range'
import style from './image-filter.module.less'

type OptionalNumber = number | undefined

export interface ImageFilterProps {
    filters?: ImageFilters | null
    onChange?: (data: ImageFilters, options?: InputOptionsForUndoSquash) => void
    onDragChangeStart?: () => void
    onDragChangeEnd?: () => void
}

function transform2out(value: OptionalNumber) {
    if (typeof value === 'number') {
        return value * 2 - 1
    }
    return value
}

function transform2in(value: OptionalNumber) {
    if (typeof value === 'number') {
        return (value + 1) / 2
    }
    return value
}

const defaultFilters: ImageFilters = {
    exposure: 0,
    contrast: 0,
    saturation: 0,
    temperature: 0,
    tint: 0,
    highlights: 0,
    shadows: 0,
}

export function ImageFilter(props: ImageFilterProps) {
    const { filters: _filters, onChange } = props

    const filters = useMemo(() => {
        if (!_filters) {
            return defaultFilters
        }
        return _filters
    }, [_filters])

    const onChangeFilter =
        (key: string, originalValue?: boolean) => (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value !== 'number') {
                return
            }
            const rate = Number(value)
            const updateProperty = { [key]: originalValue ? rate / 100 : transform2out(rate) }
            onChange?.(
                Object.assign(
                    {
                        exposure: filters.exposure,
                        contrast: filters.contrast,
                        saturation: filters.saturation,
                        temperature: filters.temperature,
                        tint: filters.tint,
                        highlights: filters.highlights,
                        shadows: filters.shadows,
                    },
                    updateProperty
                ),
                options
            )
        }

    return (
        <div className={style.container2} data-testid="image-filter">
            <div className={classnames(style.item, style.nameItem)} data-testid="image-filter-name">
                {translation('Exposure')}
            </div>
            <div className={classnames(style.item, style.rangeItem)}>
                <Range
                    rate={transform2in(filters.exposure)}
                    delta={0.05}
                    attachPoint={0.035}
                    backwardsIgnoreAttach
                    onMouseDown={props.onDragChangeStart}
                    onMouseUp={props.onDragChangeEnd}
                    onChange={(value, options) => onChangeFilter('exposure')(value, options)}
                />
            </div>
            <div className={classnames(style.item, style.inputItem)}>
                <ScrubbableInputInteger
                    value={filters.exposure * 100}
                    onChange={(value, options) => onChangeFilter('exposure', true)(value, options)}
                    onScrubBegin={props.onDragChangeStart}
                    onScrubEnd={props.onDragChangeEnd}
                    min={-100}
                    max={100}
                />
            </div>
            <div className={classnames(style.item, style.nameItem)} data-testid="image-filter-name">
                {translation('Contrast')}
            </div>
            <div className={classnames(style.item, style.rangeItem)}>
                <Range
                    delta={0.05}
                    attachPoint={0.035}
                    backwardsIgnoreAttach
                    rate={transform2in(filters.contrast)}
                    onMouseDown={props.onDragChangeStart}
                    onMouseUp={props.onDragChangeEnd}
                    onChange={(value, options) => onChangeFilter('contrast')(value, options)}
                />
            </div>
            <div className={classnames(style.item, style.inputItem)}>
                <ScrubbableInputInteger
                    value={filters.contrast * 100}
                    onChange={(value, options) => onChangeFilter('contrast', true)(value, options)}
                    onScrubBegin={props.onDragChangeStart}
                    onScrubEnd={props.onDragChangeEnd}
                    min={-100}
                    max={100}
                />
            </div>
            <div className={classnames(style.item, style.nameItem)} data-testid="image-filter-name">
                {translation('Saturation')}
            </div>
            <div className={classnames(style.item, style.rangeItem)}>
                <Range
                    delta={0.05}
                    attachPoint={0.035}
                    backwardsIgnoreAttach
                    rate={transform2in(filters.saturation)}
                    onMouseDown={props.onDragChangeStart}
                    onMouseUp={props.onDragChangeEnd}
                    onChange={(value, options) => onChangeFilter('saturation')(value, options)}
                />
            </div>
            <div className={classnames(style.item, style.inputItem)}>
                <ScrubbableInputInteger
                    value={filters.saturation * 100}
                    onChange={(value, options) => onChangeFilter('saturation', true)(value, options)}
                    onScrubBegin={props.onDragChangeStart}
                    onScrubEnd={props.onDragChangeEnd}
                    min={-100}
                    max={100}
                />
            </div>
            <div className={classnames(style.item, style.nameItem)} data-testid="image-filter-name">
                {translation('Temperature')}
            </div>
            <div className={classnames(style.item, style.rangeItem)}>
                <Range
                    delta={0.05}
                    attachPoint={0.035}
                    backwardsIgnoreAttach
                    rate={transform2in(filters.temperature)}
                    onMouseDown={props.onDragChangeStart}
                    onMouseUp={props.onDragChangeEnd}
                    onChange={(value, options) => onChangeFilter('temperature')(value, options)}
                />
            </div>
            <div className={classnames(style.item, style.inputItem)}>
                <ScrubbableInputInteger
                    value={filters.temperature * 100}
                    onChange={(value, options) => onChangeFilter('temperature', true)(value, options)}
                    onScrubBegin={props.onDragChangeStart}
                    onScrubEnd={props.onDragChangeEnd}
                    min={-100}
                    max={100}
                />
            </div>
            <div className={classnames(style.item, style.nameItem)} data-testid="image-filter-name">
                {translation('Tint')}
            </div>
            <div className={classnames(style.item, style.rangeItem)}>
                <Range
                    delta={0.05}
                    attachPoint={0.035}
                    backwardsIgnoreAttach
                    rate={transform2in(filters.tint)}
                    onMouseDown={props.onDragChangeStart}
                    onMouseUp={props.onDragChangeEnd}
                    onChange={(value, options) => onChangeFilter('tint')(value, options)}
                />
            </div>
            <div className={classnames(style.item, style.inputItem)}>
                <ScrubbableInputInteger
                    value={filters.tint * 100}
                    onChange={(value, options) => onChangeFilter('tint', true)(value, options)}
                    onScrubBegin={props.onDragChangeStart}
                    onScrubEnd={props.onDragChangeEnd}
                    min={-100}
                    max={100}
                />
            </div>
            <div className={classnames(style.item, style.nameItem)} data-testid="image-filter-name">
                {translation('Highlights')}
            </div>
            <div className={classnames(style.item, style.rangeItem)}>
                <Range
                    delta={0.05}
                    attachPoint={0.035}
                    backwardsIgnoreAttach
                    rate={transform2in(filters.highlights)}
                    onMouseDown={props.onDragChangeStart}
                    onMouseUp={props.onDragChangeEnd}
                    onChange={(value, options) => onChangeFilter('highlights')(value, options)}
                />
            </div>
            <div className={classnames(style.item, style.inputItem)}>
                <ScrubbableInputInteger
                    value={filters.highlights * 100}
                    onChange={(value, options) => onChangeFilter('highlights', true)(value, options)}
                    onScrubBegin={props.onDragChangeStart}
                    onScrubEnd={props.onDragChangeEnd}
                    min={-100}
                    max={100}
                />
            </div>
            <div className={classnames(style.item, style.nameItem)} data-testid="image-filter-name">
                {translation('Shadows')}
            </div>
            <div className={classnames(style.item, style.rangeItem)}>
                <Range
                    delta={0.05}
                    attachPoint={0.035}
                    backwardsIgnoreAttach
                    rate={transform2in(filters.shadows)}
                    onMouseDown={props.onDragChangeStart}
                    onMouseUp={props.onDragChangeEnd}
                    onChange={(value, options) => onChangeFilter('shadows')(value, options)}
                />
            </div>
            <div className={classnames(style.item, style.inputItem)}>
                <ScrubbableInputInteger
                    value={filters.shadows * 100}
                    onChange={(value, options) => onChangeFilter('shadows', true)(value, options)}
                    onScrubBegin={props.onDragChangeStart}
                    onScrubEnd={props.onDragChangeEnd}
                    min={-100}
                    max={100}
                />
            </div>
        </div>
    )
}
