import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    None: '正常',
    LineArrow: '直线箭头',
    TriangleArrow: '三角箭头',
    ReversedTriangle: '反向三角',
    CircleArrow: '圆形箭头',
    DiamondArrow: '菱形箭头',
    Round: '圆形',
    Square: '方形',
} as const

export const enTranslation = {
    None: 'None',
    LineArrow: 'Line arrow',
    TriangleArrow: 'Triangle arrow',
    ReversedTriangle: 'Reversed triangle',
    CircleArrow: 'Circle arrow',
    DiamondArrow: 'Diamond arrow',
    Round: 'Round',
    Square: 'Square',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
