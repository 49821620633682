import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    NotFoundError: 'Manifest 文件丢失',
    PermissionDeniedError: '无法读取 manifest 文件：无访问权限',
    ReadFileError: '无法读取 manifest 文件：文件读取失败',
    NoNameError: '无法读取 manifest 文件：manifest 文件中 name 缺失',
    NoMainError: '无法读取 manifest 文件：manifest 文件中 main 缺失',
    NoIdError: '无法读取 manifest 文件：manifest 文件中 id 缺失',
    NoUiError: '无法读取 manifest 文件：manifest 文件中 ui 缺失',
    Published: '已发布',
    UploadLocalVersion: '上传本地版本...',
    MoreOptions: '更多选项',
    Run: '运行',
    Publish: '发布...',
    EditorPublishDetails: '编辑插件信息...',
    Republish: '发布新版本...',
    Remove: '删除',
    OpenFolder: '在文件夹中显示',
    RunPublishVersion: '运行发布版本',
    RunLocalVersion: '运行本地版本',
    ManagePermissions: '管理权限...',
    RemoveLocalVersion: '移除本地版本',
    Unpublish: '取消发布',
    UploadFile: '上传文件',
    UploadLocalVersionBtn: '上传本地版本',
} as const

export const enTranslation = {
    NotFoundError: 'Missing manifest',
    PermissionDeniedError: 'No permission to access the manifest.json file',
    ReadFileError: 'Manifest error: file read failed',
    NoNameError: 'Manifest error: name undefined',
    NoMainError: 'Manifest error: main undefined',
    NoIdError: 'Manifest error: id undefined',
    NoUiError: 'Manifest error: ui undefined',
    Published: 'Published',
    UploadLocalVersion: 'Locate local version...',
    MoreOptions: 'More options',
    Run: 'Run',
    Publish: 'Publish...',
    EditorPublishDetails: 'Edit plugin details...',
    Republish: 'Publish new version...',
    Remove: 'Remove',
    OpenFolder: 'Show in Finder',
    RunPublishVersion: 'Run published version',
    RunLocalVersion: 'Run local version',
    ManagePermissions: 'Manage permissions...',
    RemoveLocalVersion: 'Remove local version',
    Unpublish: 'Unpublish',
    UploadFile: 'Locate',
    UploadLocalVersionBtn: 'Locate local version',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
