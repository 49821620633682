import { FeatureSwitchConfig, SwitchEnvironmentScopeType, SwitchStrategyType } from '../feature-switch-config'

const Config: FeatureSwitchConfig = {
    name: 'handle-wheel-debugger',
    owner: '',
    description: '',
    strategies: [
        {
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                userList: ['ba359f8f7cabb59bea6a1c75fceeffdef1f40827', 'b02d0d9ae7de3736f97b6f1c5b162ac7dde63a1a'],
            },
            env: SwitchEnvironmentScopeType.ALL,
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
