/* eslint-disable no-restricted-imports */
import { ShowTitleInput, Wukong } from '@wukong/bridge-proto'
import { Bridge } from '../../kernel/bridge/bridge'

export class TitleInputService {
    private showTitleInputCallback: (arg: Wukong.DocumentProto.IArg_showTitleInput) => void = () => {}

    constructor(private bridge: Bridge) {
        // 这里 bind 到 bridge 的是一个闭包，set 会更新闭包中调用的函数的引用，但整个闭包引用不变，不需要重新 bind
        this.bridge.bind(ShowTitleInput, (arg: Wukong.DocumentProto.IArg_showTitleInput) =>
            this.showTitleInputCallback(arg)
        )
    }

    setShowTitleInputCallback(cb: (arg: Wukong.DocumentProto.IArg_showTitleInput) => void) {
        this.showTitleInputCallback = cb
    }

    destroy() {
        this.bridge.unbind(ShowTitleInput)
        this.showTitleInputCallback = () => {}
    }
}
