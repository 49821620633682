import { ReactNode } from 'react'
import { WKButtonProps } from '../../wk-button/button'

export type WKTourPlacement =
    | 'bottom-center'
    | 'bottom-left'
    | 'bottom-right'
    | 'top-center'
    | 'top-left'
    | 'top-right'
    | 'left-center'
    | 'left-top'
    | 'left-bottom'
    | 'right-center'
    | 'right-top'
    | 'right-bottom'

export type WKTourTheme = 'white' | 'brand'

export type WKTourTargetsType = (HTMLElement | null)[] | (() => (HTMLElement | null)[])

export interface WKTourButtonProps extends Partial<WKButtonProps> {
    text?: string // 按钮文案
    show?: boolean // 是否显示
}

export interface WKTourStep {
    /**
     * 目标元素
     */
    targets?: WKTourTargetsType
    /**
     * 引导相对于目标元素的位置，默认bottom-center
     */
    placement?: WKTourPlacement
    /**
     * 是否显示箭头，丢失 target 时箭头会自动隐藏
     * 会覆盖外层 showArrow 的值
     */
    showArrow?: boolean
    /**
     * 箭头颜色，默认 theme 颜色
     */
    arrowColor?: string
    /**
     * 引导图片
     */
    image?: string
    /**
     * 引导视频
     */
    video?: string
    /**
     * 引导标题
     */
    title?: string | ReactNode
    /**
     * 引导描述
     */
    description: string | ReactNode
    /**
     * 上一步按钮
     * 按钮文案，默认「上一步」
     * 默认不展示
     */
    prevButton?: WKTourButtonProps
    /**
     * 下一步按钮
     * 按钮文案，默认「下一步/完成」
     * 默认展示
     */
    nextButton?: WKTourButtonProps // 下一步按钮
    /**
     * 了解更多按钮
     * 按钮文案，默认「了解更多」
     * 默认不展示
     */
    moreButton?: WKTourButtonProps
    /**
     * 是否高亮目标元素
     */
    highlight?: boolean
    /**
     * 高亮的目标元素，可选，不传则高亮在 targets 元素上
     */
    highlightTargets?: WKTourTargetsType
    /**
     * 高亮元素与目标元素的相对位置
     */
    highlightAlign?: WKTourPosition
    /**
     * 高亮元素的范围
     */
    highlightRange?: Rect | (() => Rect | undefined)
    /**
     * 滚动元素
     * 滚动触发时，丢失 target，重新计算高亮位置
     */
    scrollElement?: HTMLElement | null | (() => HTMLElement | null)
    /**
     * 引导内容丢失 target 时的位置，默认居中，为 null 表示不重新计算位置
     */
    noTargetPosition?: WKTourPosition | null | (() => WKTourPosition)
    /**
     * 自定义触发器的位置，默认为 targets 合并计算后的范围
     */
    triggerRect?: Rect | (() => Rect | undefined)
    /**
     * 自定义视窗范围，默认为整个视窗边界-8px
     */
    worldRect?: Rect | (() => Rect)
}

export interface WKTourProps {
    /**
     * 引导的步骤
     */
    steps: WKTourStep[]
    /**
     * 当前步骤
     */
    current: number
    /**
     * 打开引导
     */
    open?: boolean
    /**
     * 引导的层级
     */
    zIndex?: number
    /**
     * 是否可拖拽
     */
    draggable?: boolean
    /**
     * 引导的主题色
     */
    theme?: WKTourTheme
    /**
     * 是否显示箭头
     */
    showArrow?: boolean
    /**
     * 是否隐藏进度
     */
    hideProgress?: boolean
    /**
     * 关闭引导回调
     */
    onClose?: (index: number) => void
    /**
     * 切换步骤回调
     */
    onChange?: (index: number) => void
    /**
     * 完成引导回调
     */
    onFinish?: () => void
}

export interface WKTourStepProps extends WKTourStep {
    zIndex?: number // 引导的层级
    current: number // 当前步骤
    length: number // 引导的步骤总数
    draggable?: boolean // 是否可拖拽
    theme?: WKTourTheme // 引导的主题色，默认 white
    hideProgress?: boolean // 是否隐藏进度
    onClose: () => void // 关闭引导回调
    onClickNext: () => void // 点击下一步回调
    onClickPrev: () => void // 点击上一步回调
}

export interface WKTourPosition {
    left?: number
    top?: number
    right?: number
    bottom?: number
}

export interface WKTourHighlightPosition extends WKTourPosition {
    hide?: boolean
}

export interface Rect {
    top: number
    left: number
    right: number
    bottom: number
    width: number
    height: number
}

export const TOUR_CONTAINER_CLASSNAME = 'wk-tour-container'
// 箭头宽度12px，高度12/2=6px
export const TOUR_ARROW_WIDTH = 12
// 箭头偏移量10px
export const TOUR_ARROW_OFFSET = 10
// 高亮元素的宽度28px，高度28px
export const TOUR_HIGHLIGHT_WIDTH = 28
// 有箭头 gap 为 4px，无箭头 gap 为 8px(2px+6px箭头高度)
export const TOUR_ARROW_GAP = 4
export const TOUR_NO_ARROW_GAP = 2
// 距离视窗边缘的距离
export const TOUR_EDGE_DISTANCE = 8
