import { Range } from '../dot-range/range'
import { Position } from '../type'

interface PositionCreatingProps {
    startPosition: Position
    endPosition: Position
}
export function PositionCreating(props: PositionCreatingProps) {
    return <Range anchorPosition={props.endPosition} minorAnchorPosition={props.startPosition} />
}
