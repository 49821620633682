/* eslint-disable no-restricted-imports */
import {
    DispatchKeyboardEventCommand,
    GetFocusViewCommand,
    IsFocusViewEnabled,
    TextInputCommand,
    TriggerAllKeyUpBindingsCommand,
    UpdateFocusViewCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { signalAnimationFrame } from '../../../../../util/src/abort-controller/timers'
import { TraceableAbortSignal } from '../../../../../util/src/abort-controller/traceable-abort-controller'
import { CommandInvoker } from '../../../document/command/command-invoker'
import { CommitType } from '../../../document/command/commit-type'
import { Bridge } from '../../../kernel/bridge/bridge'
import { getWindowSelection } from '../../global/window'
import { getDocumentActiveElement, isWindowFocusEvent } from './focus-event'
import { isFullscreenInterceptTarget } from './focus-view-element-interceptable'
import { focusViewTraceLog } from './focus-view-trace-log'
import { isFocusableElement } from './focusable-element'
import { shouldPreventThisEventByDefault } from './keyboard-event-prevent-or-pass'
import { generateWasmKeyboardEvent } from './keyboard-event-to-wasm'
import { registerEditorWindowEventListener } from './register-event-listener'

class FocusViewManager {
    // ==========
    // 生命周期控制
    // ==========
    private _invoker: CommandInvoker | null = null

    setupEditorContext = (
        signal: TraceableAbortSignal,
        { bridge, commandInvoker }: { bridge: Bridge; commandInvoker: CommandInvoker }
    ) => {
        bridge.bind(
            UpdateFocusViewCommand,
            ({ focusView }) => {
                // NOTE: 在 wasm bootstrap 阶段回调时，立即设置 documentReady 为 true，否则 isFocusViewEnabled 无法正常执行
                this._invoker = commandInvoker

                if (this.isFocusViewEnabled) {
                    focusViewTraceLog('update focus view from wasm', Wukong.DocumentProto.FocusView[focusView!])
                    this._focusToFocusView(focusView!)
                } else {
                    // 不启用时，主动让焦点系统失焦
                    this.releaseFocusViewTarget()
                }
            },
            { signal }
        )
        signal.addEventListener('abort', () => {
            this._invoker = null
            this._canvasFocusedInput = null
            this._textEditingInput = null
        })
    }

    setupEventHandlers = (signal: TraceableAbortSignal) => {
        this._registerBlurCaptureEventHander(signal)
        this._registerFocusCaptureEventHander(signal)
        this._registerKeyboardEventHander(signal)
        this._registerClipboardEventHander(signal)
    }

    // =============================
    // CanvasFocusedInput 绑定 & 事件
    // =============================

    private _canvasFocusedInput: HTMLInputElement | null = null

    isCanvasFocusedInputFocused = () => {
        return !!this._canvasFocusedInput && this._canvasFocusedInput === getDocumentActiveElement()
    }

    isEventOnCanvasFocusedInput = (e: Event) => {
        return !!this._canvasFocusedInput && e.target === this._canvasFocusedInput
    }

    bindCanvasFocusedInputElement = (input: HTMLInputElement | null) => {
        // 同步绑定元素
        if (input && !this._canvasFocusedInput) {
            this._canvasFocusedInput = input
            this._addEventListenersToCanvasFocusedInput()
            if (this.isFocusViewEnabled) {
                focusViewTraceLog('canvas ready')
                this._focusToFocusView()
            }
        } else if (!input && this._canvasFocusedInput) {
            this._removeEventListenersFromCanvasFocusedInput()
            this._canvasFocusedInput = null
        }
    }
    private _addEventListenersToCanvasFocusedInput = () => {
        if (this._canvasFocusedInput) {
            this._canvasFocusedInput.addEventListener('focus', this._onCanvasFocusedInputFocus)
            this._canvasFocusedInput.addEventListener('blur', this._onCanvasFocusedInputBlur)
        }
    }
    private _removeEventListenersFromCanvasFocusedInput = () => {
        if (this._canvasFocusedInput) {
            this._canvasFocusedInput.removeEventListener('focus', this._onCanvasFocusedInputFocus)
            this._canvasFocusedInput.removeEventListener('blur', this._onCanvasFocusedInputBlur)
        }
    }

    private _onCanvasFocusedInputFocus = (_: FocusEvent) => {
        if (this.isFocusViewEnabled) {
            focusViewTraceLog('canvas on focused')
        }
    }
    private _onCanvasFocusedInputBlur = (_: FocusEvent) => {
        if (this.isFocusViewEnabled) {
            focusViewTraceLog('canvas on blured')
            this._invoker?.invokeBridge(CommitType.Noop, TriggerAllKeyUpBindingsCommand) // 画布区失焦时，触发所有的 keyup 事件来重置临时状态
        }
    }

    // =========================
    // TextEditingInput 绑定/事件
    // =========================

    private _textEditingInput: HTMLInputElement | null = null

    isTextEditingInputFocused = () => {
        return !!this._textEditingInput && this._textEditingInput === getDocumentActiveElement()
    }

    isEventOnTextEditingInput = (e: Event) => {
        return !!this._textEditingInput && e.target === this._textEditingInput
    }

    bindTextEditingInputElement = (input: HTMLInputElement | null) => {
        // 同步绑定元素
        if (input && !this._textEditingInput) {
            this._textEditingInput = input
            this._addEventListenerToTextEditingInput()
        } else if (!input && this._textEditingInput) {
            this._removeEventListenerFromTextEditingInput()
            this._textEditingInput = null
        }
    }

    private _addEventListenerToTextEditingInput = () => {
        if (this._textEditingInput) {
            this._textEditingInput.addEventListener('focus', this._onTextEditingInputFocus)
            this._textEditingInput.addEventListener('blur', this._onTextEditingInputBlur)
        }
    }
    private _removeEventListenerFromTextEditingInput = () => {
        if (this._textEditingInput) {
            this._textEditingInput.removeEventListener('focus', this._onTextEditingInputFocus)
            this._textEditingInput.removeEventListener('blur', this._onTextEditingInputBlur)
        }
    }

    private _onTextEditingInputFocus = (_: FocusEvent) => {
        if (this.isFocusViewEnabled) {
            focusViewTraceLog('text on focused')
            this._invoker?.invokeBridge(CommitType.Noop, TextInputCommand, {
                mode: Wukong.DocumentProto.InputMode.INPUT_MODE_FOCUS,
            })
        }
    }
    private _onTextEditingInputBlur = (_: FocusEvent) => {
        if (this.isFocusViewEnabled) {
            focusViewTraceLog('text on blured')
            this._invoker?.invokeBridge(CommitType.Noop, TextInputCommand, {
                mode: Wukong.DocumentProto.InputMode.INPUT_MODE_BLUR,
            })
        }
    }

    // ==========
    // 自动聚焦处理
    // ==========

    private _getFocusView = () => {
        return (
            this._invoker?.invokeBridge(CommitType.Noop, GetFocusViewCommand).focusView ??
            Wukong.DocumentProto.FocusView.FOCUS_VIEW_CANVAS
        )
    }

    private _getFocusViewElement = (focusView: Wukong.DocumentProto.FocusView) => {
        switch (focusView) {
            case Wukong.DocumentProto.FocusView.FOCUS_VIEW_CANVAS: {
                return this._canvasFocusedInput
            }
            case Wukong.DocumentProto.FocusView.FOCUS_VIEW_TEXT_EDITING: {
                return this._textEditingInput
            }
        }
    }

    private _focusToFocusView = (expected?: Wukong.DocumentProto.FocusView) => {
        const focusView = expected ?? this._getFocusView()
        focusViewTraceLog('focus to view', Wukong.DocumentProto.FocusView[focusView])
        const focusViewElement = this._getFocusViewElement(focusView)
        this._focusToFocusViewElement(focusViewElement)
    }

    private _focusToFocusViewElement = (focusViewElement: HTMLElement | null) => {
        focusViewTraceLog('focus to element', focusViewElement)
        if (!focusViewElement) {
            focusViewTraceLog('element not existed')
            return
        }
        if (focusViewElement === getDocumentActiveElement()) {
            focusViewTraceLog('element focused already', focusViewElement)
            return
        }
        focusViewElement.focus()
    }

    private _focusIfUnfocused = () => {
        if (!this.isFocusViewEnabled) {
            // 不启用时，被动让焦点系统失焦
            this.releaseFocusViewTarget()
            return
        }
        // 在浏览器窗口聚焦时，保证有 focused 元素
        if (!document.hasFocus()) {
            focusViewTraceLog('document not focused')
            return
        }
        const t = getDocumentActiveElement()
        // 焦点不在预期元素上时，尝试修正
        if (t && t !== document.body && isFocusableElement(t)) {
            focusViewTraceLog('active element already fulfilled', t)
            return
        }
        this._focusToFocusView()
    }

    // ==========
    // 全局事件监听
    // ==========
    private _registerBlurCaptureEventHander = (signal: TraceableAbortSignal) => {
        const blurCaptureEventHandler = (e: FocusEvent) => {
            if (this.isFocusViewEnabled) {
                focusViewTraceLog('blur captured', e.target)

                if (isWindowFocusEvent(e)) {
                    return
                }

                // 因为失焦事件发生时，新的焦点还未确定，无法立即检查并修正焦点，只能在下一帧做
                // 场景为：输入框聚焦状态下，用户点击在其外部使其失焦，但又想立即使其聚焦。重聚焦是在捕获阶段的做的，无法阻止目标阶段事件的发生。因此不延迟处理的结果是：先收到聚焦事件，再收到失焦事件，但最终处于聚焦状态。这不符合预期
                signalAnimationFrame(
                    () => {
                        focusViewTraceLog('focus if unfocused (in next animation frame)')
                        this._focusIfUnfocused()
                    },
                    { signal }
                )
            }
        }

        registerEditorWindowEventListener<FocusEvent>('blur', blurCaptureEventHandler, true, signal)
    }

    private _registerFocusCaptureEventHander = (signal: TraceableAbortSignal) => {
        const focusCaptureEventHandler = (e: FocusEvent) => {
            if (this.isFocusViewEnabled) {
                focusViewTraceLog('focus captured', e.target)

                if (isWindowFocusEvent(e)) {
                    // Window 重新聚焦时要检查一次
                    this._focusIfUnfocused()
                }
            }
        }

        registerEditorWindowEventListener<FocusEvent>('focus', focusCaptureEventHandler, true, signal)
    }

    private _registerKeyboardEventHander = (signal: TraceableAbortSignal) => {
        const keyboardEventHandler = (e: KeyboardEvent) => {
            if (this.isFocusViewEnabled) {
                focusViewTraceLog(`${e.type} captured`, e.target)
                // 检查是否需要 prevent default
                shouldPreventThisEventByDefault(e)
                // 立即检查焦点并尝试修正
                focusViewTraceLog('focus if unfocused (immediately)')
                this._focusIfUnfocused()
                // 焦点不符合预期，则不响应快捷键事件
                if (!isFullscreenInterceptTarget(e) && !this.isCanvasFocusedInputFocused()) {
                    return
                }
                const eventType =
                    e.type === 'keydown'
                        ? Wukong.DocumentProto.KeyEventType.KEY_EVENT_TYPE_KEY_DOWN
                        : e.type === 'keyup'
                        ? Wukong.DocumentProto.KeyEventType.KEY_EVENT_TYPE_KEY_UP
                        : Wukong.DocumentProto.KeyEventType.KEY_EVENT_TYPE_UNKNOWN
                const event = generateWasmKeyboardEvent(
                    eventType,
                    e,
                    Wukong.DocumentProto.KeyboardEventTraceSource.KEYBOARD_EVENT_TRACE_SOURCE_FOCUS_VIEW_MANAGER
                )
                focusViewTraceLog(`dispatch keyboard command: ${e.type},${e.code}`)
                const continuation = this._invoker?.invokeBridge(CommitType.Noop, DispatchKeyboardEventCommand, event)
                if (continuation?.preventDefault) {
                    e.preventDefault()
                }
            }
        }

        registerEditorWindowEventListener<KeyboardEvent>('keydown', keyboardEventHandler, true, signal)
        registerEditorWindowEventListener<KeyboardEvent>('keyup', keyboardEventHandler, true, signal)
    }

    private _registerClipboardEventHander = (signal: TraceableAbortSignal) => {
        const clipboardEventHandler = (e: ClipboardEvent) => {
            if (this.isFocusViewEnabled) {
                focusViewTraceLog(`${e.type} captured`, e.target)
                // 立即检查焦点并尝试修正
                focusViewTraceLog('focus if unfocused (immediately)')
                this._focusIfUnfocused()
                // 焦点符合预期，则响应剪贴板事件
                // TODO(chenyn): 还在 ClipboardEventHandler 中响应事件，这里只在捕获阶段做 refocus。之后可能迁移到这里
                if (!this.isDocumentActiveElementFocusView()) {
                    return
                }
                focusViewTraceLog(`dispatch clipboard command: ${e.type} (TODO)`)
            }
        }

        registerEditorWindowEventListener<ClipboardEvent>('copy', clipboardEventHandler, true, signal)
        registerEditorWindowEventListener<ClipboardEvent>('cut', clipboardEventHandler, true, signal)
        registerEditorWindowEventListener<ClipboardEvent>('paste', clipboardEventHandler, true, signal)
    }

    // ==========
    // 外部焦点判定
    // ==========

    isEventOnFocusViewElement = (e: Event) => {
        return this.isEventOnCanvasFocusedInput(e) || this.isEventOnTextEditingInput(e)
    }

    isDocumentActiveElementFocusView = () => {
        return this.isCanvasFocusedInputFocused() || this.isTextEditingInputFocused()
    }

    // ==========
    // 提供开关判定
    // ==========

    get isFocusViewEnabled() {
        return !!this._invoker?.invokeBridge(CommitType.Noop, IsFocusViewEnabled).value
    }

    // ================
    // [临时] 焦点系统切换
    // ================

    private releaseFocusViewTarget = () => {
        focusViewTraceLog('release focus view target')
        // this._canvasFocusedInput?.blur()
        // this._textEditingInput?.blur()
        getDocumentActiveElement()?.blur()
        getWindowSelection()?.empty()
    }
}

export const focusViewManager = new FocusViewManager()
