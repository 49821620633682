/* eslint-disable no-restricted-imports */
import { useViewState } from '../../../../view-state-bridge'
import React, { ReactNode, useCallback, useState } from 'react'
import { collapse, expand } from '../../inspect/assets/inspect-svg'
import styles from './expand.module.less'
import { enhancedSessionStorage } from '../../../../web-storage/session-storage/storage'
import { SessionStorageKey } from '../../../../web-storage/session-storage/config'

interface ExpandProps {
    children: ReactNode
    title: ReactNode
}

export function Expand(props: ExpandProps) {
    const docReadonly = useViewState('docReadonly')

    const sessionVal = enhancedSessionStorage.getItem(SessionStorageKey.PreviewExpanded)

    // 编辑权限默认收起，只读权限默认展开
    const defaultPreviewExpanded = typeof sessionVal === 'string' ? sessionVal === '1' : !!docReadonly

    const [previewExpanded, setPreviewExpanded] = useState(defaultPreviewExpanded)

    const handlePreviewExpand = useCallback(() => {
        const newVal = !previewExpanded
        setPreviewExpanded(!previewExpanded)
        enhancedSessionStorage.setItem(SessionStorageKey.PreviewExpanded, newVal ? '1' : '0')
    }, [previewExpanded])

    return (
        <div className={styles.preview}>
            <div className={styles.preview_row} onClick={handlePreviewExpand}>
                <span className={styles.expand}>{previewExpanded ? expand : collapse}</span>
                {props.title}
            </div>
            {previewExpanded && props.children}
        </div>
    )
}
