import { translation } from './font-select.translation'
/* eslint-disable no-restricted-imports */
import { useEffect, useMemo, useRef } from 'react'
import { useUpdateEffect } from 'react-use'
import { IconCheckboxChecked, InputV2, ScrollView, ScrollViewRef } from '../../../../../../../../ui-lib/src'
import type { FontInfo } from '../../../../../../document/node/node'
import { useFeatureSwitch } from '../../../../../../kernel/switch/hooks'
import { useFontSelectContext } from '../font-select-context'
import { FontSelectOption } from './font-select-option'
import classes from './font-select.module.less'
import { useFontSelect } from './use-font-select'

export interface Props {
    /**
     * 当前可用字体列表
     */
    readonly fontInfos: FontInfo[]
    /**
     * 是否显示{translation('RecentlyUsed')}字体, 默认为false
     */
    readonly showRecent?: boolean
    readonly placeholder?: string

    /**
     * 默认列表填充内容 没有可用字体列表时的展示内容
     */
    readonly children?: React.ReactNode
    selectMaxHeight: number
}

function useFontOptionItemsSection({
    fontInfos,
    onPreSelected,
    startIndex,
    topIndex,
    title,
    isRecentFont,
}: {
    fontInfos: FontInfo[]
    onPreSelected: (index: number) => void
    startIndex: number
    topIndex: number
    title?: string
    isRecentFont?: boolean
}) {
    const { currentSelectedFamily, onFontSelectChange } = useFontSelectContext()

    const fontOptionItems = useMemo(
        () =>
            fontInfos.map((option, index) => (
                <FontSelectOption
                    key={option.family}
                    fontInfo={option}
                    isSelect={currentSelectedFamily === option.family}
                    preSelectedKey={index + startIndex + topIndex}
                    onPreSelected={onPreSelected}
                    onClick={(v) => {
                        onFontSelectChange(v)
                    }}
                ></FontSelectOption>
            )),
        [fontInfos, currentSelectedFamily, startIndex, onPreSelected, onFontSelectChange, topIndex]
    )

    if (fontOptionItems.length > 0) {
        return (
            <div className={classes.section}>
                {title ? <div className={classes.title}>{title}</div> : null}
                <div style={{ height: `${topIndex * 32}px` }} />
                {fontOptionItems}
            </div>
        )
    }

    return null
}

export function FontSelect({ fontInfos, placeholder, showRecent = false, selectMaxHeight, children }: Props) {
    const scrollRef = useRef<ScrollViewRef>(null)
    const { currentSelectedFamily, recentFontInfos, expend, searchStringBelongTo, onPreselectFont } =
        useFontSelectContext()
    const {
        textSearchString,
        filterRecentFontInfos,
        preSelectedFontIndex,
        isMixed,
        totalHeight,
        topIndex,
        renderFontInfos,
        setPreSelected,
        setSearchContent,
        onKeyDown,
        onScroll,
        onPreSelectedChange,
    } = useFontSelect(
        fontInfos,
        recentFontInfos,
        showRecent,
        currentSelectedFamily,
        selectMaxHeight,
        searchStringBelongTo
    )

    const recentSection = useFontOptionItemsSection({
        fontInfos: filterRecentFontInfos,
        onPreSelected: setPreSelected,
        startIndex: 0,
        topIndex: 0,
        title: translation('RecentlyUsed'),
        isRecentFont: true,
    })
    const fontSection = useFontOptionItemsSection({
        fontInfos: renderFontInfos,
        onPreSelected: setPreSelected,
        startIndex: filterRecentFontInfos.length,
        topIndex,
    })

    useEffect(() => {
        onPreSelectedChange(preSelectedFontIndex, scrollRef)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preSelectedFontIndex])

    const preSelectEnabled = useFeatureSwitch('font-select')

    useUpdateEffect(() => {
        if (!preSelectEnabled) {
            return
        }

        if (preSelectedFontIndex < 0) {
            onPreselectFont?.(null)
            return
        }

        if (preSelectedFontIndex < filterRecentFontInfos.length) {
            onPreselectFont?.(filterRecentFontInfos[preSelectedFontIndex])
        }

        const renderIndex = preSelectedFontIndex - filterRecentFontInfos.length - topIndex
        if (renderIndex < 0 || renderIndex >= renderFontInfos.length) {
            onPreselectFont?.(null)
            return
        }

        onPreselectFont?.(renderFontInfos[renderIndex])
    }, [preSelectedFontIndex])

    return (
        <div>
            <InputV2.Search
                style={{ margin: 8 }}
                value={textSearchString}
                autoFocus
                placeholder={placeholder ?? ''}
                onKeyDown={onKeyDown}
                onSearch={setSearchContent}
                key={expend.toString()}
                dataTestIds={{ input: 'search-font' }}
            />
            <ScrollView
                block="nearest"
                selectKey={preSelectedFontIndex}
                className={classes.options}
                style={{ maxHeight: selectMaxHeight }}
                onMouseLeave={() => setPreSelected(-1)}
                notUseDefaultClassName
                ref={scrollRef}
                scrollbar={{
                    onScroll,
                }}
            >
                {children ?? (
                    <div style={{ height: totalHeight }} data-testid="font-select-list">
                        {isMixed ? (
                            <div className={classes.mixed}>
                                <span className={classes.isSelect}>
                                    <IconCheckboxChecked />
                                </span>
                                {translation('Mixed')}
                            </div>
                        ) : null}
                        {recentSection}
                        {fontSection}
                        {textSearchString && !recentSection && !fontSection ? <EmptyResult /> : null}
                    </div>
                )}
            </ScrollView>
        </div>
    )
}

function EmptyResult() {
    return <div className={classes.empty}>{translation('NoMatchingFonts')}</div>
}
