import { useEffect, useRef, useState } from 'react'
import {
    DropdownIconSingleLevelRef,
    DropdownV2,
    MonoIconCommonAdd16,
    MonoIconCommonEyes16,
    MonoIconLayerInstance16,
    MonoIconLayerType16,
    MonoIconLayerVariants16,
    MonoIconPanelParentLevel16,
    Tooltip,
} from '../../../../../../../../ui-lib/src'
import { PopupStateType } from '../../../../../../document/node/node'
import { documentLoaded$ } from '../../../../../../external-store/atoms/editor-context'
import { appStore$ } from '../../../../../../external-store/store'
import { useViewState } from '../../../../../../view-state-bridge'
import { frogEventToolsMenusAndActionsBasicAction } from '../../../../../utils/tools-actions-frog'
import { Title } from '../../../../atom/title/title'
import { VComponentPropDef, VComponentPropType } from '../../types'
import { ComponentPropList } from '../component-prop-defs-list'
import { ComponentPropDragPopup } from '../component-prop-popup'
import { ComponentPropVariantConflict } from '../component-prop-variant-conflict'
import { useComponentProp } from '../component-prop/hook'
import styles from './index.module.less'
import { translation } from './index.translation'

const ComponentPropTypeToPopupType = {
    [VComponentPropType.V_COMPONENT_PROP_TYPE_BOOL]: {
        create: PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_BOOL_PROP_OF_PROP_AREA,
        edit: PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_BOOL_PROP,
    },
    [VComponentPropType.V_COMPONENT_PROP_TYPE_TEXT]: {
        create: PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_TEXT_PROP_OF_PROP_AREA,
        edit: PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_TEXT_PROP,
    },
    [VComponentPropType.V_COMPONENT_PROP_TYPE_INSTANCE_SWAP]: {
        create: PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_INSTANCE_SWAP_PROP_OF_PROP_AREA,
        edit: PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_INSTANCE_SWAP_PROP,
    },
    [VComponentPropType.V_COMPONENT_PROP_TYPE_VARIANT]: {
        create: PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_VARIANT_PROP_OF_PROP_AREA,
        edit: PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_VARIANT_PROP,
    },
}

function _ComponentPropDefsPanel() {
    const containerRef = useRef<HTMLDivElement>(null)
    const [popupPosition, setPopupPosition] = useState<{ left: number; top: number } | undefined>(undefined)

    const {
        publicPropChains,
        defsPanelPopupVisible,
        componentPropDefs,
        toggleComponentPropPopup,
        closeComponentPropPopup,
        removeProp,
        batchRemoveProps,
        updateProp,
        reorderDefs,
        popupType,
        popupVal,
        createVariantPropWasmCall,
        highlightNodesByIds,
        selectComponentProp,
    } = useComponentProp()

    const state = useViewState('componentPanelState')
    const titleGrayTheme =
        (state?.variantState?.variantProps?.length ?? 0) <= 0 &&
        componentPropDefs.length <= 0 &&
        publicPropChains.length <= 0

    const [dropdownVisible, setDropdownVisible] = useState(false)

    useEffect(() => {
        return () => {
            if (appStore$.get(documentLoaded$)) {
                selectComponentProp(false)
            }
        }
    }, [selectComponentProp])

    const dropdownRef = useRef<DropdownIconSingleLevelRef>(null)

    return (
        <div className={styles.componentPropDefsPanel} ref={containerRef} data-testid="component-prop-defs-panel">
            <Title grayTheme={titleGrayTheme} onClick={() => dropdownRef.current?.open()}>
                <Title.Left>{translation('ComponentProp')}</Title.Left>
                <Title.Right>
                    <Tooltip title={translation('CreateCompProp')} triggerRefKey="getTriggerElement">
                        <DropdownV2.IconSingleLevel
                            ref={dropdownRef}
                            label={<MonoIconCommonAdd16 className={!dropdownVisible ? styles.grayIcon : undefined} />}
                            onChange={(value: PopupStateType) => {
                                setPopupPosition(containerRef.current?.getBoundingClientRect())
                                if (
                                    value === PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_VARIANT_PROP_OF_PROP_AREA
                                ) {
                                    createVariantPropWasmCall()
                                } else {
                                    toggleComponentPropPopup(value)
                                }
                            }}
                            dataTestIds={{ triggerFocus: 'component-props-add-btn' }}
                            onOpen={() => setDropdownVisible(true)}
                            onClose={() => setDropdownVisible(false)}
                        >
                            <DropdownV2.IconSingleLevel.Option
                                disabled
                                value={translation('CreateCompProp')}
                                backwardIcon=" "
                                centerContainerClassName={styles.optionHeader}
                            >
                                {translation('CreateCompProp')}
                            </DropdownV2.IconSingleLevel.Option>
                            <DropdownV2.IconSingleLevel.Option
                                value={PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_VARIANT_PROP_OF_PROP_AREA}
                                dataTestId={'create-variant-prop'}
                                onClick={() => {
                                    frogEventToolsMenusAndActionsBasicAction({
                                        action_name: '创建变体组件',
                                        operation_way: '右边栏-组件属性菜单',
                                    })
                                }}
                                forwardChildren={<MonoIconLayerVariants16 />}
                            >
                                {translation('VariantProp')}
                            </DropdownV2.IconSingleLevel.Option>
                            <DropdownV2.IconSingleLevel.Option
                                value={PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_BOOL_PROP_OF_PROP_AREA}
                                dataTestId={'create-boolean-prop'}
                                forwardChildren={<MonoIconCommonEyes16 />}
                            >
                                {translation('BoolProp')}
                            </DropdownV2.IconSingleLevel.Option>
                            <DropdownV2.IconSingleLevel.Option
                                value={PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_INSTANCE_SWAP_PROP_OF_PROP_AREA}
                                dataTestId={'create-instance-swap-prop'}
                                forwardChildren={<MonoIconLayerInstance16 />}
                            >
                                {translation('InstanceSwapProp')}
                            </DropdownV2.IconSingleLevel.Option>
                            <DropdownV2.IconSingleLevel.Option
                                value={PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_TEXT_PROP_OF_PROP_AREA}
                                dataTestId={'create-text-prop'}
                                forwardChildren={<MonoIconLayerType16 />}
                            >
                                {translation('TextProp')}
                            </DropdownV2.IconSingleLevel.Option>
                            {!!publicPropChains?.length && (
                                <>
                                    <DropdownV2.IconSingleLevel.Option
                                        splitLineTop
                                        disabled
                                        value={translation('PublicProp')}
                                        backwardIcon=" "
                                        centerContainerClassName={styles.optionHeader}
                                    >
                                        {translation('PublicProp')}
                                    </DropdownV2.IconSingleLevel.Option>
                                    <DropdownV2.IconSingleLevel.Option
                                        value={
                                            PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_PUBLIC_PROP_OF_PROP_AREA
                                        }
                                        dataTestId={'create-public-prop'}
                                        forwardChildren={<MonoIconPanelParentLevel16 />}
                                    >
                                        {translation('NestedInstance')}
                                    </DropdownV2.IconSingleLevel.Option>
                                </>
                            )}
                        </DropdownV2.IconSingleLevel>
                    </Tooltip>
                </Title.Right>
            </Title>

            <ComponentPropList
                defs={componentPropDefs}
                popupType={popupType}
                popupVal={popupVal}
                publicPropChains={publicPropChains}
                openEditPopup={(def: VComponentPropDef) => {
                    toggleComponentPropPopup(ComponentPropTypeToPopupType[def.type].edit, {
                        ...def,
                        preferredValues: { instanceSwapValues: def.instanceSwapPreferredValues, stringValues: [] },
                    })
                }}
                closePopup={closeComponentPropPopup}
                removeProp={removeProp}
                batchRemoveProps={batchRemoveProps}
                updateProp={updateProp}
                reorderDefs={reorderDefs}
                setPopupPosition={setPopupPosition}
                highlightNodesByIds={highlightNodesByIds}
                selectComponentProp={selectComponentProp}
            />
            <ComponentPropVariantConflict />
            {defsPanelPopupVisible && popupPosition && <ComponentPropDragPopup position={popupPosition} />}
        </div>
    )
}

function ComponentPropDefsPanelWrapper() {
    const { displayPropPanelOfComponent, defsPanelKey } = useComponentProp()
    return <>{displayPropPanelOfComponent && <_ComponentPropDefsPanel key={defsPanelKey} />}</>
}

export const ComponentPropDefsPanel = () => {
    return <ComponentPropDefsPanelWrapper />
}
