import { translation } from './image-show.translation'
/* eslint-disable no-restricted-imports */
import { useCallback } from 'react'
import type { ImagePaint } from '../../../../../document/node/node'

import type { DivType } from '../../../../../../../util/src'
import { useImageDownloadContext } from '../../../../context/document-context'

import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { showPasteImageFailureModal } from '../../../../../document/command/image-command'
import style from './image-show.module.less'

export type ImageIdenti = Pick<ImagePaint, 'imageHash' | 'imageName' | 'previewHash'>

interface UploadImageDataErrorRusult {
    sucess: false
}

interface UploadImageDataSucessRusult {
    sucess: true
    imageInfo: Wukong.DocumentProto.ImageInfo
    hasCompressed: boolean
}

type UploadImageDataRusult = UploadImageDataErrorRusult | UploadImageDataSucessRusult
interface ImageShowProps extends DivType {
    src?: string
    paint: ImagePaint
    onChangeImage: (image: ImageIdenti) => void // 类型待定
}

export function ImageShow(props: ImageShowProps) {
    const imageDownloadContext = useImageDownloadContext()

    const onImageLoad = useCallback(
        async (e: any) => {
            const imageList = Array.from(e.target.files) as File[]
            if (!imageList.length) return
            const file = imageList[0]
            let result: UploadImageDataRusult = {
                sucess: false,
            }
            try {
                result = await imageDownloadContext.addImageSource(file, file.name)
            } catch {
                showPasteImageFailureModal()
            }

            if (!result.sucess) {
                return
            }

            const imageInfo = result.imageInfo

            if (!imageInfo.imageId) {
                return
            }

            props.onChangeImage({
                imageHash: imageInfo.imageId,
                imageName: imageInfo.imageName,
                previewHash: imageInfo.previewId,
            })
        },
        [imageDownloadContext, props]
    )

    return (
        <div className={classnames(style.imageContainer, 'mosaic-8', style.line)}>
            <img src={props.src} draggable={false} />
            <div className={style.imageMask}></div>
            <div className={style.imageInput}>
                {translation('ChooseImage')}
                <input type="file" accept="image/*" className={style.input} onChange={onImageLoad} />
            </div>
        </div>
    )
}
