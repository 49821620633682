// 这里存放一些工作台需要用到的自定义类型
import { RouteTokenValue } from '../../../../util/src'
import type {
    DocPrototypeVO,
    DocWithAuthorityVO,
    FolderIdWithDocVO,
    FolderWithAuthorityVO,
} from '../../kernel/interface/type'

// 前端自定义类型，使用要注意, 将收藏时间和访问时间加入到了DocWithAuthorityVO中， 这一步在 doc-list-page-state.ts 中完成
export interface FlatDoc extends DocWithAuthorityVO {
    visitedTime?: number
    favoriteTime?: number
    prototype?: DocPrototypeVO // 这个字段存在的话，就表明这个FlatDoc是原型文件生成的
}

// 团队页 每一项是由 fodler + docs组成
export type FolderWithDocs = FolderIdWithDocVO & {
    folderWithAuthorityVO: FolderWithAuthorityVO
    route?: RouteTokenValue
}

// 这里是列表视图相关的依赖，网格视图和列表视图
export enum ShowType {
    Grid = 1,
    List,
}

// 这里存放的是工作台里面与筛选相关的，包括文件列，项目列表，共享项目等
export enum SortOrder {
    Ascending = 'ascending',
    Descending = 'descending',
}

export enum TeamMembersPageSortKey {
    Name = 'name',
    Role = 'role',
    Active = 'lastActive',
    SeatType = 'seatType',
}

export enum TeamFoldersPageSortKey {
    UpdateTime = 'updateTime',
    DocCount = 'docCount',
    FolderName = 'folderName',
}

export enum DocListPageSortKey {
    Visited = 'visitedTime',
    Created = 'createdTime',
    Favorite = 'favoriteTime',
    Edited = 'lastEditedTime',
    Deleted = 'deletedTime',
    DeleteUserName = 'deleteUserName',
    Name = 'name',
}

export enum OrganizationManageTeamPageSortKey {
    TeamName = 'teamName',
    Owner = 'owner',
    PublicStatus = 'publicStatus',
    MemberCount = 'memberCount',
    FolderCount = 'folderCount',
    Workspace = 'workspace',
}

export enum OrganizationManageMemberPageSortKey {
    Name = 'name',
    Active = 'active',
    Edit = 'edit',
    TeamCount = 'teamCount',
    Seat = 'seat',
    Workspace = 'workspace',
}

export enum SettlementInvoicePageSortKey {
    Date = 'date',
}

export enum OrgResourceLibrarySortKey {
    Library = 'Library',
    Team = 'Team',
    Enable = 'Enable',
    ComponentCount = 'ComponentCount',
    StyleCount = 'StyleCount',
}
