import React, { forwardRef } from 'react'
import {
    Empty as SelectEmpty,
    EmptyProps as SelectEmptyProps,
    EmptyRef as SelectEmptyRef,
} from '../../../selects/picks/empty/empty'

export interface EmptyProps extends SelectEmptyProps {}

export interface EmptyRef extends SelectEmptyRef {}

function _Empty<T extends EmptyProps = EmptyProps>(props: T, ref?: React.Ref<EmptyRef>) {
    const { placement = 'bottom center', ...otherProps } = props
    return (
        <SelectEmpty placement={placement} {...otherProps} ref={ref}>
            {props.children}
        </SelectEmpty>
    )
}

export const Empty = forwardRef(_Empty) as <T extends EmptyProps = EmptyProps>(
    props: T & { ref?: React.Ref<EmptyRef> }
) => React.ReactElement
