import { useFontManagerService } from '../../../../../context/document-context'
import { FontSelect } from '../font-select/font-select'
import { LocalFontPluginInstaller, LocalFontPluginUpdater } from './font-local-plugin-installer'
import { translation } from './font-local-tab-item.translation'

export function FontLocalTabItem({ selectMaxHeight }: { selectMaxHeight: number }) {
    const fontManagerService = useFontManagerService()
    const fonts = fontManagerService.states.use.localFontInfosState()
    const { versionUrl, pluginStatus } = fontManagerService.states.use.pluginDetails()

    return (
        <FontSelect fontInfos={fonts} placeholder={translation('SearchLocalFonts')} selectMaxHeight={selectMaxHeight}>
            {pluginStatus === 'uninstalled' ? (
                <LocalFontPluginInstaller url={versionUrl} />
            ) : pluginStatus === 'forceUpdated' ? (
                <LocalFontPluginUpdater url={versionUrl} />
            ) : null}
        </FontSelect>
    )
}
