import { OnObservingModeChange, Wukong } from '@wukong/bridge-proto'
import { createSelectors, createStore } from '../../../../../../util/src'
import { createPendingTaskExecutor } from '../../../../../../util/src/abort-controller/signal-task'
import { TraceableAbortSignal } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import type { WebSocketBridge } from '../../../../document/synergy/web-socket-bridge'
import { Bridge } from '../../../../kernel/bridge/bridge'
import { DeepRequired, ViewStateBridge } from '../../../../view-state-bridge'
import { CoactorCursorRenderServiceV2 } from './coactor-cursor-render-service-v2'
import { CoactorSynergyRoleToastService } from './coactor-synergy-role-toast-service'
import { CooperationUserActiveService } from './cooperation-user-active-service'

type SessionId = number

export class CooperationService {
    private zustandStore = createStore<{
        coactorSessionIds: SessionId[]
    }>(() => ({
        coactorSessionIds: [],
    }))
    useZustandStore = createSelectors(this.zustandStore)

    private coactorMembershipMap: Record<SessionId, DeepRequired<Wukong.DocumentProto.IVActiveUser>> | undefined
    private currentPageCoactorMouseList: DeepRequired<Wukong.DocumentProto.IVCoactorMouse>[] | undefined

    private screenLock: WakeLockSentinel | undefined

    private sessionId2CoactorCursorRenderMap = new Map<SessionId, CoactorCursorRenderServiceV2>()
    public readonly cooperationUserActiveService: CooperationUserActiveService
    private readonly coactorSynergyRoleToastService: CoactorSynergyRoleToastService

    constructor(
        private readonly commandInvoker: CommandInvoker,
        protected readonly viewStateBridge: ViewStateBridge,
        private readonly webSocketBridge: WebSocketBridge,
        protected readonly bridge: Bridge,
        private readonly signal: TraceableAbortSignal
    ) {
        this.bindWasmCall(this.signal)
        this.cooperationUserActiveService = new CooperationUserActiveService(
            this.commandInvoker,
            this.webSocketBridge,
            this.signal
        )
        this.coactorSynergyRoleToastService = new CoactorSynergyRoleToastService(this.bridge)
    }

    public init = () => {
        this.cooperationUserActiveService.init()
        this.bindViewStates()
    }

    public destroy() {
        this.coactorSynergyRoleToastService.destroy()
        this.releaseScreenLock()
    }

    public bindViewStates = () => {
        this.viewStateBridge.registerWithSignal(this.signal, 'coactorMembershipMap', this.onCoactorMembershipMapChange)
        this.viewStateBridge.registerWithSignal(
            this.signal,
            'currentPageCoactorMouseList',
            this.onCurrentPageCoactorMouseListChange
        )
    }

    private onCoactorMembershipMapChange = (membershipMap: DeepRequired<Wukong.DocumentProto.IVMembershipMap>) => {
        this.coactorMembershipMap = membershipMap.value
        this.updateCoactorCursorRenderList()
    }

    private onCurrentPageCoactorMouseListChange = (
        currentPageCoactorMouseList: DeepRequired<Wukong.DocumentProto.IVCoactorMouseList>
    ) => {
        this.currentPageCoactorMouseList = currentPageCoactorMouseList.value
        this.updateCoactorCursorRenderList()
    }

    private updateCoactorCursorRenderList = () => {
        const prevCoactorSessionIds = new Set(this.zustandStore.getState().coactorSessionIds)
        const coactorSessionIds: SessionId[] = []
        let hasNewCoactor = false

        const needDemote = (this.currentPageCoactorMouseList?.length ?? 0) >= 30
        this.currentPageCoactorMouseList?.forEach((coactorMouse) => {
            const activeUser = this.coactorMembershipMap?.[coactorMouse.sessionId]
            if (!activeUser) {
                return
            }

            if (!this.sessionId2CoactorCursorRenderMap.has(coactorMouse.sessionId)) {
                this.sessionId2CoactorCursorRenderMap.set(coactorMouse.sessionId, new CoactorCursorRenderServiceV2())
            }

            this.sessionId2CoactorCursorRenderMap.get(coactorMouse.sessionId)?.rerenderCoactorCursor(
                coactorMouse.cursor,
                activeUser.color,
                activeUser.colorIndex,
                activeUser.nickname,
                coactorMouse.mouseCameraPosition,
                // 被观察者的光标不需要降级渲染
                activeUser.role !== Wukong.DocumentProto.ActiveUserRole.ACTIVE_USER_ROLE_OBSERVED && needDemote
            )

            coactorSessionIds.push(coactorMouse.sessionId)

            if (!hasNewCoactor && !prevCoactorSessionIds.has(coactorMouse.sessionId)) {
                hasNewCoactor = true
            }
        })

        // diff 一下，减少 ReactNode 渲染次数
        if (prevCoactorSessionIds.size !== coactorSessionIds.length || hasNewCoactor) {
            this.zustandStore.setState({
                coactorSessionIds: coactorSessionIds,
            })
        }
    }

    // 处理被添加/被移除的协作者光标
    public handleAppendOrRemoveCoactorCursor = (sessionId: SessionId, element: HTMLElement | null) => {
        if (element) {
            this.sessionId2CoactorCursorRenderMap.get(sessionId)?.handleAppendCoactorCursor(element)
        } else {
            this.sessionId2CoactorCursorRenderMap.get(sessionId)?.handleRemoveCoactorCursor()
        }
    }

    private bindWasmCall = async (signal: TraceableAbortSignal) => {
        const { addTask } = createPendingTaskExecutor(signal)

        this.bridge.bind(
            OnObservingModeChange,
            ({ value }) => {
                addTask(async (signal) => {
                    await (value ? this.requestScreenLock() : this.releaseScreenLock())
                    signal.throwIfAborted()
                })
            },
            { signal }
        )
    }

    // 进入观察模式时，请求屏幕常亮 lock
    private requestScreenLock = async () => {
        if (!this.screenLock && 'wakeLock' in navigator) {
            try {
                this.screenLock = await navigator.wakeLock.request('screen')
            } catch (error) {
                // ignore error
            }
        }
    }

    // 退出观察模式时，释放屏幕常亮 lock
    private releaseScreenLock = async () => {
        await this.screenLock?.release()
        this.screenLock = undefined
    }
}
