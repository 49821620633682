import { CheckCanvasRightMenuOpenable, Wukong } from '@wukong/bridge-proto'
import { useCallback } from 'react'
import { isRightClick } from '../../../../ui-lib/src'
import { useCanvas } from '../../external-store/atoms/create-canvas'
import { isWindows } from '../../kernel/util/ua'
import { AiComponentDetailModal } from '../../ui/component/ai/component-recognize/component-detail-modal'
import { Comment } from '../../ui/component/comment'
import { CoactorObservingBorder } from '../../ui/component/cooperation/coactor-observing-mode/coactor-observing-border'
import { CooperationCursorList } from '../../ui/component/cooperation/cooperation-cursor/cooperation-cursor'
import { HyperlinkHoverPopup } from '../../ui/component/design/hyperlink/hover-popup'
import { NavigateToDesktopPopup } from '../../ui/component/desktop/navigate-to-desktop-popup'
import { DevModeBatchExportModal } from '../../ui/component/dev-mode/dev-mode-batch-export'
import { DevModeEditorHelpCenter } from '../../ui/component/dev-mode/editor-help-center'
import { SettingDialogMainMenuPopup as CodeConfigSettingDialogMainMenuProp } from '../../ui/component/dev-mode/inspect/comp/dev-mode-code-type-picker'
import { HoverNodeLabelLayerWrapper } from '../../ui/component/dev-mode/layer/dev-mode-hover-node-label'
import { DevModeLeftSidePanel } from '../../ui/component/dev-mode/left-side-panel/dev-mode-left-side-panel'
import { MemoryUsageBar } from '../../ui/component/memory-usage/memory-usage-bar'
import { MemoryAlertDialog } from '../../ui/component/memory-usage/memory-usage-crash-dialog'
import { PubMirrorFrame } from '../../ui/component/mirror'
import { ShortcutGuide } from '../../ui/component/shortcut-guide/shortcut-guide'
import { AccountManager } from '../../ui/component/top-area/menu/components/account-manager'
import { FeedBack } from '../../ui/component/top-area/menu/components/feedback'
import { ReportObstacle } from '../../ui/component/top-area/menu/components/report-obstacle'
import { SaveHistoryVersion } from '../../ui/component/top-area/menu/components/save-history-version'
import { RightClickMenu } from '../../ui/component/top-area/menu/right-click-menu/right-click-menu'
import { useDocThumbnail } from '../../ui/hooks/use-doc-thumbnail'
import { buildMouseEvent, dispatchMouseEventSpecially } from '../../ui/utils/event'
import { isRightClickAtCanvas } from '../../ui/utils/is-right-click'
import {
    useCanvasCursor,
    useCommand,
    useDevModeAvailabilityVerificationService,
    useMouseOnCanvas,
} from '../app-context'
import { RootComponentId } from '../canvas/canvas'
import { useHandleRightClickEvent } from '../canvas/use-right-click'
import { AppLayoutWrapper, useLeftPanelShown } from '../layout/layout-context'
import { useUserConfigStateV2 } from '../user-config/user-config-hook'
import { DeliveryUnitNavigator } from './delivery-unit-navigator/navigator'
import { DevModeOnboarding } from './dev-mode-onboarding'
import { DevModeEditorRightPanel } from './dev-mode-right-panel'
import classes from './dev-mode-root.module.less'

import RightClickSource = Wukong.DocumentProto.RightClickSource

function DevModeEditorBodyArea() {
    return (
        <div className={classes.BodyArea}>
            <DevModeEditorLeftPanel />
            <DevModeEditorCenterStage />
            <DevModeEditorRightPanel />
        </div>
    )
}

function DevModeEditorLeftPanel() {
    const showLeftPanel = useLeftPanelShown()
    return showLeftPanel ? (
        <div className={classes.LayerPanel} data-testid="dev-mode-left-panel">
            {/* 研发模式的左边栏 */}
            <DevModeLeftSidePanel />
        </div>
    ) : null
}

function DevModeEditorCenterStage() {
    const handleCanvas = useHandleRightClickEvent(RightClickSource.RIGHT_CLICK_SOURCE_CANVAS)
    const handleCanvasWithCtrOrCmd = useHandleRightClickEvent(
        RightClickSource.RIGHT_CLICK_SOURCE_CANVAS_WITH_CTR_OR_CMD
    )
    const canvas = useCanvas()
    const command = useCommand()
    const [ctrlClickTriggerContextMenu] = useUserConfigStateV2('ctrlClickTriggerContextMenu')

    const onContextMenu = useCallback(
        (e: React.MouseEvent) => {
            const targetId = (e.target as HTMLElement).id
            if (targetId === RootComponentId.CanvasOverlay) {
                // fix: [WK-15326][WK-15327][WK-16172] windows上右键菜单时，handleCanvasMouseEvent取到的target有问题，导致无法正常dispatchEvent，在这里单独dispatch
                isWindows() &&
                    isRightClick(e) &&
                    dispatchMouseEventSpecially(buildMouseEvent('contextmenu', e), command, canvas)

                const openable = command.DEPRECATED_invokeBridge(CheckCanvasRightMenuOpenable)?.value!
                if (openable) {
                    if ((e.ctrlKey || e.metaKey) && e.button == 2) {
                        handleCanvasWithCtrOrCmd(e)
                    } else {
                        if (isRightClickAtCanvas(e, ctrlClickTriggerContextMenu)) {
                            handleCanvas(e)
                        }
                    }
                }
            }
        },
        [command, ctrlClickTriggerContextMenu, handleCanvas, handleCanvasWithCtrOrCmd, canvas]
    )

    const devModeAvailablieyVerificationService = useDevModeAvailabilityVerificationService()
    const isDevModeAccessible = devModeAvailablieyVerificationService.useZustandStore.use.isDevModeAvailable()

    return (
        <AppLayoutWrapper>
            <div className="w-full h-full" onMouseDown={onContextMenu}>
                <DevModeCanvasArea />
                <Comment />
                <CooperationCursorList />
                <CoactorObservingBorder />
                <MemoryUsageBar />
                <MemoryAlertDialog />
                {isDevModeAccessible && <HoverNodeLabelLayerWrapper />}
                {isDevModeAccessible && <DeliveryUnitNavigator />}
                <HyperlinkHoverPopup />
                <CodeConfigSettingDialogMainMenuProp />
            </div>
        </AppLayoutWrapper>
    )
}

function DevModeCanvasArea() {
    const cursorManager = useCanvasCursor()
    const mouseOnCanvas = useMouseOnCanvas()

    const overlayRefCallback = useCallback(
        (e: HTMLDivElement | null) => {
            e ? cursorManager.attachCanvasTarget(e) : cursorManager.detachCanvasTarget()
            mouseOnCanvas.provideCanvasViewElement(e)
        },
        [cursorManager, mouseOnCanvas]
    )

    return (
        <div
            ref={overlayRefCallback}
            className={classes.CanvasArea}
            id={RootComponentId.CanvasOverlay}
            data-testid="dev-mode-canvas-overlay"
            data-should-accept-mouse-event-for-canvas
        ></div>
    )
}

export function DevModeEditorUI() {
    useDocThumbnail()

    return (
        <>
            <RightClickMenu />
            <DevModeBatchExportModal />
            <DevModeEditorBodyArea />
            <ShortcutGuide />
            <DevModeEditorHelpCenter />
            <AiComponentDetailModal />
            <ReportObstacle />
            <FeedBack />
            <AccountManager />
            <SaveHistoryVersion />
            <PubMirrorFrame />
            <DevModeOnboarding />
            <NavigateToDesktopPopup />
        </>
    )
}
