import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    NoSelect: '未选中图层',
    GuideTitle: '标注、代码、导出预设',
    GuideDescription: '在画布点击、悬停，就可能查看布局、颜色与代码。支持导出预设，一键批量导出。',
} as const

export const enTranslation = {
    NoSelect: 'No Selection',
    GuideTitle: 'Advanced inspection',
    GuideDescription: 'Click or hover on the canvas to view the layout, color, and code.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
