import { FC } from 'react'
import { createRoot } from 'react-dom/client'
import { LibConfigProvider } from '../../../../../ui-lib/src/components/lib-config-provider'
import { NormalModal } from './normal'
import { OfflineModal } from './offline'
import { QuotaExccededModalType } from './types'

let hasExisted = false

export const QUOTA_EXCCEDED_MODAL_ID = 'motiff_quota_exccedded_modal'

export const showQuotaExccededModal = (type: QuotaExccededModalType) => {
    // 不能重复调用
    if (document.getElementById(QUOTA_EXCCEDED_MODAL_ID)) {
        return
    }

    // 普通的内存溢出只在一个刷新周期提醒一次
    if (type === QuotaExccededModalType.Normal && hasExisted) {
        return
    }

    const dom = document.createElement('div')
    dom.id = QUOTA_EXCCEDED_MODAL_ID
    document.body.appendChild(dom)

    const root = createRoot(dom)

    const oldCb = window.onpopstate
    window.onpopstate = () => {
        root.unmount()
        window.onpopstate = oldCb
    }

    root.render(
        <QuotaExccededModal
            close={() => {
                root.unmount()
                document.body.removeChild(dom)
            }}
            type={type}
        />
    )

    hasExisted = true
}

export const QuotaExccededModal: FC<{ close: () => void; type: QuotaExccededModalType }> = ({ close, type }) => {
    return (
        <LibConfigProvider>
            {type === QuotaExccededModalType.Normal ? <NormalModal close={close} /> : <OfflineModal close={close} />}
        </LibConfigProvider>
    )
}
