import { useCallback } from 'react'
import { Select } from '../../../../../../../ui-lib/src'
import { useColorModeServiceV2 } from '../../../../../main/app-context'
import { SingleGrid } from '../../../atom/grid/single-grid'
import { tranformColorModeV2 } from '../common/utils'
import { Wukong } from '@wukong/bridge-proto'

const ColorModePickerV2 = () => {
    const colorModeService = useColorModeServiceV2()
    const colorMode = colorModeService.states.use.colorModeState()
    const setColorMode = colorModeService.setColorMode

    const onChangeColorMode = useCallback(
        (_colorMode: Wukong.DocumentProto.DevAndReadonlyColorMode) => {
            setColorMode(_colorMode)
        },
        [setColorMode]
    )
    const options = [
        {
            value: Wukong.DocumentProto.DevAndReadonlyColorMode.DEV_AND_READONLY_COLOR_MODE_H_E_X,
        },
        {
            value: Wukong.DocumentProto.DevAndReadonlyColorMode.DEV_AND_READONLY_COLOR_MODE_R_G_B,
        },
        {
            value: Wukong.DocumentProto.DevAndReadonlyColorMode.DEV_AND_READONLY_COLOR_MODE_H_S_L,
        },
        {
            value: Wukong.DocumentProto.DevAndReadonlyColorMode.DEV_AND_READONLY_COLOR_MODE_H_S_B,
        },
        {
            splitLineTop: true,
            value: Wukong.DocumentProto.DevAndReadonlyColorMode.DEV_AND_READONLY_COLOR_MODE_U_I_COLOR,
        },
        {
            value: Wukong.DocumentProto.DevAndReadonlyColorMode.DEV_AND_READONLY_COLOR_MODE_SWIFT_U_I,
        },
        {
            splitLineTop: true,
            value: Wukong.DocumentProto.DevAndReadonlyColorMode.DEV_AND_READONLY_COLOR_MODE_A_R_G_B,
        },
        {
            value: Wukong.DocumentProto.DevAndReadonlyColorMode.DEV_AND_READONLY_COLOR_MODE_COMPOSE,
        },
    ]
    return (
        <SingleGrid.Item start={5} span={14}>
            <Select.MinimalSingleLevel
                value={colorMode}
                dataTestIds={{
                    triggerFocus: 'color-mode-picker-v2-trigger-focus',
                    container: 'color-mode-picker-v2-container',
                }}
                onChange={onChangeColorMode}
                label={tranformColorModeV2(colorMode)}
                weight="medium"
                minWidth={88}
            >
                {options.map((option) => (
                    <Select.MinimalSingleLevel.Option
                        splitLineTop={option.splitLineTop}
                        key={option.value}
                        value={option.value}
                        backwardIcon={''}
                    >
                        {tranformColorModeV2(option.value)}
                    </Select.MinimalSingleLevel.Option>
                ))}
            </Select.MinimalSingleLevel>
        </SingleGrid.Item>
    )
}

export const ColorModePicker = () => {
    return <ColorModePickerV2 />
}
