import { useMemo } from 'react'
import {
    DraggablePopupV2,
    MonoIconCommonArrowLeft16,
    MulticolorIconCommonPic16,
    Position,
} from '../../../../../../ui-lib/src'
import { AISearchNodeIcon } from './ai-search-node-icon'
import { AiSearchResultBody } from './ai-search-result-body'
import { AiSearchResultFileDetail } from './ai-search-result-file-detail'
import { translation } from './ai-search-result-modal.translation'
import { AiSearchResultModel } from './use-ai-search'

function ModalTitleSearchInfo({
    prefix,
    suffix,
    icon,
    name,
}: {
    prefix: string
    suffix: string
    icon: React.ReactNode
    name: string
}) {
    return (
        <div className="mr-64px flex flex-row items-center gap-8px overflow-hidden">
            <div className="flex-none wk-font-semibold wk-text-14 text-black">{translation('ResultsFor')}</div>
            <div className="flex flex-row items-center overflow-hidden wk-font-regular wk-text-12">
                <div className="flex-none">{prefix}</div>
                <div className="h-20px overflow-hidden flex flex-row items-center">
                    {icon}
                    <span className="overflow-hidden text-ellipsis whitespace-nowrap">{name}</span>
                </div>
                <div className="flex-none">{suffix}</div>
            </div>
        </div>
    )
}
function ModalTitle({ model }: { model: AiSearchResultModel }) {
    const { searchInfo, currentFile } = model
    if (currentFile) {
        return (
            <div className="mr-64px wk-font-semibold wk-text-14 text-black overflow-hidden text-ellipsis whitespace-nowrap">
                {currentFile.documentVO.name}
            </div>
        )
    }

    switch (searchInfo.type) {
        case 'IMAGE':
            return (
                <ModalTitleSearchInfo
                    prefix={translation('ImagePrefix')}
                    suffix={translation('ImageSuffix')}
                    icon={<MulticolorIconCommonPic16 className="flex-nonem mr-4px" />}
                    name={searchInfo.file.name}
                />
            )
        case 'NODE':
            return (
                <ModalTitleSearchInfo
                    prefix={translation('LayerPrefix')}
                    suffix={translation('LayerSuffix')}
                    icon={<AISearchNodeIcon node={searchInfo.node} className="flex-none mr-2px" />}
                    name={searchInfo.node.name}
                />
            )
    }
}

export function AiSearchResultModal({ model }: { model: AiSearchResultModel }) {
    const position = useMemo(() => {
        return model.positionTopCenter
            ? { ...model.positionTopCenter, left: model.positionTopCenter.left - 808 / 2 }
            : undefined
    }, [model.positionTopCenter])

    const onMove = (pos: Position) => model.setPositionTopCenter({ ...pos, left: pos.left + 808 / 2 })

    return (
        <DraggablePopupV2
            testId="ai-search-result-modal"
            width={808}
            height={800}
            position={position}
            onMove={onMove}
            visible={true}
            onCancel={model.onExit}
            bodyClassName="!p-0"
            header={
                <div className="flex flex-row items-center gap-8px h-54px pl-24px">
                    <MonoIconCommonArrowLeft16 onClick={model.onBack} className="flex-none" />
                    <ModalTitle model={model} />
                </div>
            }
            footer={null}
            closeTestId="ai-search-result-close"
        >
            <div style={{ display: model.currentFile ? 'none' : 'block' }}>
                <AiSearchResultBody model={model} resultHeight={690} />
            </div>

            {model.currentFile ? <AiSearchResultFileDetail file={model.currentFile} model={model} /> : null}
        </DraggablePopupV2>
    )
}
