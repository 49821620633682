/* eslint-disable no-restricted-imports */
import { WKFrog } from '../../kernel/frog'
import { WKFrogTaskStateService } from './wk-frog-task-state-service'

export enum File_type {
    isNotDraftFile = '0',
    isDraftFile = '1',
}

type Milliseconds = number

function getLdap() {
    return WKFrogTaskStateService.getUserVOV2()?.userBrief.email ?? ''
}
function getFileType() {
    return WKFrogTaskStateService.getDocGetVO()?.draft ? File_type.isDraftFile : File_type.isNotDraftFile
}
function durationMilliseconds(startTime: Milliseconds | undefined) {
    return startTime === undefined ? -1 : WKFrogTaskStateService.getDurationMilliseconds(startTime)
}

let startTimeExposeTextStylePanel: Milliseconds | undefined = undefined
let existSearchContentDuringTheOpening = false

function resetState() {
    startTimeExposeTextStylePanel = undefined
    existSearchContentDuringTheOpening = false
}

function hiddenTabsAutoEffect() {
    if (existSearchContentDuringTheOpening) {
        textStyle.searchTextStyle()
    }
}

export const textStyle = {
    _recordSearchContent(searchContent: string) {
        if (!existSearchContentDuringTheOpening) {
            existSearchContentDuringTheOpening = !!searchContent
        }
    },
    textStyle() {
        WKFrog.addFrogRecord({
            url: '/click/TextStyle/textStyle',
            eventId: 21520,
            eventName: 'textStyle',
            eventAction: 'click',
            customExtend: { ldap: getLdap(), file_type: getFileType() },
        })
    },
    exposeTextStylePanel() {
        WKFrogTaskStateService.recordBrowserPageVisibility()
        resetState()
        startTimeExposeTextStylePanel = Date.now()
        WKFrog.addFrogRecord({
            url: '/expose/TextStyle/textStylePanel',
            eventId: 21521,
            eventName: 'textStylePanel',
            eventAction: 'expose',
            customExtend: { ldap: getLdap(), file_type: getFileType() },
        })
    },
    textStylePanel(isFlash?: boolean) {
        const duration = durationMilliseconds(startTimeExposeTextStylePanel)
        WKFrog.addFrogRecord({
            url: '/time/TextStyle/textStylePanel',
            eventId: 21521,
            eventName: 'textStylePanel',
            eventAction: 'time',
            customExtend: { ldap: getLdap(), duration },
        })
        hiddenTabsAutoEffect()
        if (isFlash) {
            WKFrog.flushRecord()
        }
    },
    searchTextStyle() {
        WKFrog.addFrogRecord({
            url: '/event/TextStyle/searchTextStyle',
            eventId: 21523,
            eventName: 'searchTextStyle',
            eventAction: 'event',
            customExtend: { ldap: getLdap(), file_type: getFileType() },
        })
    },
    searchResults() {
        WKFrog.addFrogRecord({
            url: '/click/TextStyle/searchResults',
            eventId: 21527,
            eventName: 'searchResults',
            eventAction: 'click',
            customExtend: { ldap: getLdap(), file_type: getFileType() },
        })
    },
    textStyleList() {
        WKFrog.addFrogRecord({
            url: '/click/TextStyle/textStyleList',
            eventId: 21528,
            eventName: 'textStyleList',
            eventAction: 'click',
            customExtend: { ldap: getLdap(), file_type: getFileType() },
        })
    },
    addTextStyle() {
        WKFrog.addFrogRecord({
            url: '/click/TextStyle/addTextStyle',
            eventId: 21608,
            eventName: 'addTextStyle',
            eventAction: 'click',
            customExtend: { ldap: getLdap(), file_type: getFileType() },
        })
    },
}
