/* eslint-disable no-restricted-imports */
import { TraceableAbortSignal } from '../../../util/src/abort-controller/traceable-abort-controller'
import { EffectController } from '../../../util/src/effect-controller'
import { CommandInvoker } from '../document/command/command-invoker'
import { sandboxRootSignal$ } from '../external-store/atoms/sandbox/root-signal'
import { appStore$ } from '../external-store/store'
import { MouseOnCanvas } from '../ui/component/mouse-on-canvas/mouse-on-canvas'
import { ViewStateBridge } from '../view-state-bridge'
import { CanvasEventDelegator } from './canvas/canvas-event-delegator'
import { CanvasEventHandler } from './canvas/canvas-event-handler'
import type { HandlerProvider } from './handler-provider/handler-provider'
import { keyboardReceiverStore } from './keyboard-receiver/store/index'
import { TextEditingInputHandler } from './text-editing-input/text-editing-input-handler'

export function registerCanvasEventDelegator(
    viewStateBridge: ViewStateBridge,
    handlerProvider: HandlerProvider,
    canvas: HTMLCanvasElement,
    signal: TraceableAbortSignal
): CanvasEventDelegator {
    const handler = new CanvasEventDelegator(viewStateBridge, handlerProvider, canvas, signal)
    return handler
}

export function registerCanvasEventHandler(
    handlerProvider: HandlerProvider,
    commandInvoker: CommandInvoker,
    canvas: HTMLCanvasElement,
    mouseOnCanvas: MouseOnCanvas
): CanvasEventHandler {
    const handler = new CanvasEventHandler(handlerProvider, commandInvoker, canvas, mouseOnCanvas)
    return handler
}

export function registerTextEditingInputHandler(
    handlerProvider: HandlerProvider,
    signal?: TraceableAbortSignal
): TextEditingInputHandler {
    const handler = new TextEditingInputHandler(handlerProvider, signal)
    return handler
}

export function registerSandboxHandler(
    canvas: HTMLCanvasElement,
    commandInvoker: CommandInvoker,
    handlerProvider: HandlerProvider,
    museOnCanvas: MouseOnCanvas,
    viewStateBridge: ViewStateBridge
) {
    const controller = new EffectController('RegisterSandboxHandler')
    const rootSignal = appStore$.get(sandboxRootSignal$)

    const canvasEventDelegator = registerCanvasEventDelegator(viewStateBridge, handlerProvider, canvas, rootSignal!)
    const canvasEventHandler = registerCanvasEventHandler(handlerProvider, commandInvoker, canvas, museOnCanvas)
    const textEditingInputHandler = registerTextEditingInputHandler(handlerProvider)

    keyboardReceiverStore.initKeyboardMonitor(commandInvoker)

    canvasEventDelegator.enable()
    canvasEventHandler.enable()
    textEditingInputHandler.enable()

    return {
        destroy: () => {
            controller.destroy()
            canvasEventDelegator.disable()
            canvasEventHandler.disable()
            canvasEventHandler.destroy()
            textEditingInputHandler.disable()
            keyboardReceiverStore.destroy()
        },
    }
}

export function registerHandler(
    canvas: HTMLCanvasElement,
    commandInvoker: CommandInvoker,
    handlerProvider: HandlerProvider,
    museOnCanvas: MouseOnCanvas,
    viewStateBridge: ViewStateBridge,
    signal: TraceableAbortSignal
) {
    const canvasEventDelegator = registerCanvasEventDelegator(viewStateBridge, handlerProvider, canvas, signal)
    const canvasEventHandler = registerCanvasEventHandler(handlerProvider, commandInvoker, canvas, museOnCanvas)
    const textEditingInputHandler = registerTextEditingInputHandler(handlerProvider, signal)

    keyboardReceiverStore.initKeyboardMonitor(commandInvoker)

    canvasEventDelegator.enable()
    canvasEventHandler.enable()
    textEditingInputHandler.enable()

    return {
        destroy: () => {
            canvasEventDelegator.disable()
            canvasEventHandler.disable()
            canvasEventHandler.destroy()
            textEditingInputHandler.disable()
            keyboardReceiverStore.destroy()
        },
    }
}
