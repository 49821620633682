import { translation } from './page-panel-service.translation'
/* eslint-disable no-restricted-imports */
import {
    DeletePageCommandWasmCall,
    DuplicatePageCommand,
    PageEnterEditing,
    SetSelectionMaybeJumpPageCommand,
    Wukong,
    ZoomToSelectionWasmCall,
} from '@wukong/bridge-proto'
import { WKToast } from '../../../../ui-lib/src'
import { domLocation, sleep } from '../../../../util/src'
import { CommandInvoker } from '../../document/command/command-invoker'
import { copyText } from '../../ui/handler/copy-handler'
import { ViewStateBridge } from '../../view-state-bridge'
import type { HandlerProvider } from '../handler-provider/handler-provider'

export class PagePanelService {
    private lastSelectedPageForRightClick = ''
    private lastSelectedComponentForAIRightClick = ''
    private pagePanelCollapsed = false
    private expandPagePanelFn: (() => void) | null = null

    constructor(
        private command: CommandInvoker,
        private handler: HandlerProvider,
        private viewSateBridge: ViewStateBridge,
        private docReadonly: boolean
    ) {}

    renameSelectedPage = () => {
        if (this.docReadonly) {
            return
        }
        if (this.pagePanelCollapsed && this.expandPagePanelFn) {
            this.expandPagePanelFn()
        }
        // 通过 cmd 设置 page 进入编辑态
        this.command.DEPRECATED_invokeBridge(
            PageEnterEditing,
            Wukong.DocumentProto.PageEnterEditingCommandParam.create({
                nodeId: this.lastSelectedPageForRightClick,
            })
        )
    }

    getLastSelectedPageForRightClick = () => {
        return this.lastSelectedPageForRightClick
    }

    setLastSelectedPageForRightClick = (id: string) => {
        this.lastSelectedPageForRightClick = id
    }

    copyUrlOfSelectedPage = () => {
        const editorType =
            this.viewSateBridge.getDefaultValue('editorType') === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV
                ? 'dev'
                : 'design'

        // 复制链接
        const preUrl = domLocation().href.split('?')[0]
        const url = `${preUrl}?nodeId=${encodeURIComponent(this.lastSelectedPageForRightClick)}&type=${editorType}`
        this.handler.handle(copyText, url)
        WKToast.show(translation('PageLinkCopied'))
    }

    deleteSelectedPage = () => {
        // 删除页面
        this.command.DEPRECATED_invokeBridge(
            DeletePageCommandWasmCall,
            Wukong.DocumentProto.DeletePageCommandParam.create({
                node: this.lastSelectedPageForRightClick,
            })
        )
    }

    // 注意：使用该方法时需要在外部对它进行限制，免费团队只能创建3个页面
    duplicateSelectedPage = async () => {
        const tId = WKToast.loading(translation('Duplicating'))
        await sleep(0)

        // 创建页面副本
        this.command.DEPRECATED_invokeBridge(
            DuplicatePageCommand,
            Wukong.DocumentProto.DuplicatePageCommandParam.create({
                nodeId: this.lastSelectedPageForRightClick,
            })
        )
        this.command.commitUndo()
        WKToast.close(tId)
        WKToast.show(translation('PageDuplicated'))
    }

    setPagePanelCollapsed = (collapsed: boolean) => {
        this.pagePanelCollapsed = collapsed
    }

    setExpandPagePanelFn = (fn: () => void) => {
        this.expandPagePanelFn = fn
    }

    setLastSelecteAIComponentId = (id: string) => {
        this.lastSelectedComponentForAIRightClick = id
    }

    locateToComponent = () => {
        this.command.DEPRECATED_invokeBridge(SetSelectionMaybeJumpPageCommand, {
            selection: [this.lastSelectedComponentForAIRightClick],
            leftShift: false,
        })
        this.command.DEPRECATED_invokeBridge(ZoomToSelectionWasmCall)
    }

    public destroy() {
        this.lastSelectedPageForRightClick = ''
        this.pagePanelCollapsed = false
        this.expandPagePanelFn = null
    }
}
