import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    Linear: '线性',
    EaseIn: '缓入',
    EaseOut: '缓出',
    EaseInAndOut: '缓入缓出',
    EaseInBack: '缓入回弹',
    EaseOutBack: '缓出回弹',
    EaseInAndOutBack: '缓入缓出回弹',
    CustomBezier: '自定义贝塞尔',
    Mixed: '多个值',
    Gentle: '柔和',
    Quick: '快速',
    Bouncy: '弹跳',
    Slow: '慢速',
    CustomSpring: '自定义弹性动画',
} as const

export const enTranslation = {
    Linear: 'Linear',
    EaseIn: 'Ease in',
    EaseOut: 'Ease out',
    EaseInAndOut: 'Ease in and out',
    EaseInBack: 'Ease in back',
    EaseOutBack: 'Ease out back',
    EaseInAndOutBack: 'Ease in and out back',
    CustomBezier: 'Custom bezier',
    Mixed: 'Mixed',
    Gentle: 'Gentle',
    Quick: 'Quick',
    Bouncy: 'Bouncy',
    Slow: 'Slow',
    CustomSpring: 'Custom spring',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
