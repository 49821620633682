import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    TheSelectedVariant: '当前选中的变体被删除，你可切换至其它其他变体',
    TheSelectedVariant_synonyms1: '当前选中的「变体」在变体组件中被删除。',
    ResetToDefault: '重置为默认变体',
    Default: '默认',
    yUACfo: '更多操作',
    AddVariant: '添加新变体',
    CreateComponentProperty: '添加新属性',
    CombineAsVariants: '合并变体',
    PropertyName: '输入属性名称',
    Mixed: '多个值',
} as const

export const enTranslation = {
    TheSelectedVariant: 'The selected variant is missing in the component',
    TheSelectedVariant_synonyms1: 'The selected variant is missing in the component',
    ResetToDefault: 'Reset to default',
    Default: 'Default',
    yUACfo: '更多操作',
    AddVariant: 'Add variant',
    CreateComponentProperty: 'Add property',
    CombineAsVariants: 'Combine as variants',
    PropertyName: 'Property name',
    Mixed: 'Mixed',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
