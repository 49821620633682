import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    remix: '改编',
    save: '保存',
    editStyling: '编辑风格',
    mobileAuto: '移动端 - 自由风格',
    webAuto: '网页端 - 自由风格',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    remix: 'Remix',
    save: 'Save',
    editStyling: 'Edit Styling',
    mobileAuto: 'Mobile Auto',
    webAuto: 'Web Auto',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
