import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    Stiffness: '刚度',
    Damping: '阻尼',
    Mass: '质量',
} as const

export const enTranslation = {
    Stiffness: 'Stiffness',
    Damping: 'Damping',
    Mass: 'Mass',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
