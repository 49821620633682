import { focusViewTraceLog } from './focus-view/focus-view-trace-log'

export const enum BlurType {
    Immediately = 'immediately',
}

export class ActiveElementBlurService {
    constructor() {
        document.addEventListener('mousedown', this.handleCaptureMouseDown, { capture: true })
    }

    handleCaptureMouseDown = (event: MouseEvent) => {
        focusViewTraceLog('[ActiveElementBlurService] mousedown captured')

        const activeElement = document.activeElement as HTMLElement | null
        if (!activeElement) {
            return
        }

        if (event.target === activeElement) {
            return
        }

        if (activeElement.dataset.blurAfterCaptureMouseDown === BlurType.Immediately) {
            focusViewTraceLog('[ActiveElementBlurService] mousedown to blur', activeElement)
            activeElement.blur()
        }
    }

    destory() {
        document.removeEventListener('mousedown', this.handleCaptureMouseDown, { capture: true })
    }
}
