import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    AllLibraries: '全部组件库',
    LocalLibrary: '本地组件库',
    NoColorVariables: '没有颜色变量',
    NoColorStylesVariables: '没有颜色样式或变量',
    BrowseLibraries: '浏览团队组件库',
    NoMatchesFound1: '没有找到该颜色变量',
    NoMatchesFound2: '没有找到该颜色样式或变量',
    GoToStyle: '打开样式所在文件',
    EditStyle: '编辑样式',
    DeleteStyle: '删除样式',
    Search: '搜索',
    Styles: '样式',
} as const

export const enTranslation = {
    AllLibraries: 'All libraries',
    LocalLibrary: 'Local library',
    NoColorVariables: 'No color variables',
    NoColorStylesVariables: 'No color styles or variables',
    BrowseLibraries: 'Browse libraries',
    NoMatchesFound1: 'No results found',
    NoMatchesFound2: 'No results found',
    GoToStyle: 'Go to style definition to edit',
    EditStyle: 'Edit style',
    DeleteStyle: 'Delete style',
    Search: 'Search',
    Styles: 'Styles',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
