import { AddFrog, Wukong } from '@wukong/bridge-proto'
import { command } from 'ccstate'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { traceable } from '../../../../util/src/ccstate-helper/traceable'
import { EffectController } from '../../../../util/src/effect-controller'
import { CanvasRenderBridge } from '../../document/bridge/canvas-render-bridge'
import { initDocumentContext } from '../../document/document-bridge/init-document-context'
import { PluginHostService } from '../../document/plugin-ui/plugin-host-service'
import { NetworkService } from '../../document/synergy/network-service'
import { IN_JEST_TEST } from '../../environment'
import { benchmarkService } from '../../kernel/benchmark'
import { EmBridge } from '../../kernel/bridge/em-bridge'
import { AddFrogRecord } from '../../kernel/frog/add-frog'
import { schemaVersionManager } from '../../kernel/schema'
import { Sentry } from '../../kernel/sentry'
import { setupKernel } from '../../kernel/setup-kernel'
import { featureSwitchManager } from '../../kernel/switch/core'
import { AiService } from '../../main/ai-service'
import { setupClipboard } from '../../main/clipboard/setup-clipboard'
import { ControlParagraphService } from '../../main/control-paragraph/service'
import { CursorManager } from '../../main/cursor/cursor-manager'
import { registerExportDocumentCommand } from '../../main/dev-tool/export-document'
import { registerLocateNodeCommand } from '../../main/dev-tool/locate-node'
import { FontManagerService } from '../../main/font/font-manager-service'
import { GPUService } from '../../main/gpu/gpu-service'
import { HandlerProvider } from '../../main/handler-provider/handler-provider'
import { wasm$ } from '../../main/lifecycle/setup-editor/editor'
import { EditorContext } from '../../main/lifecycle/types/editor'
import { ActiveElementBlurService } from '../../main/service/active-element-blur-service'
import { CanvasSearchService } from '../../main/service/canvas-search-service'
import { ClientService } from '../../main/service/client-service'
import { ColorModeService } from '../../main/service/color-mode-service'
import { ColorModeServiceV2 } from '../../main/service/color-mode-service-v2'
import { ColorProfileService } from '../../main/service/color-profile-service'
import { ComponentPropService } from '../../main/service/component-prop-service'
import { CopyCheckService } from '../../main/service/copy-check-service'
import { setupDesignPanelInputKeyboardEventService } from '../../main/service/design-panel-input-keyboard-event-service'
import { DesktopPreviewService } from '../../main/service/desktop-preview-service'
import { DevModeAvailabilityVerificationService } from '../../main/service/dev-mode-availability-verification-service'
import { ExporterService } from '../../main/service/exporter-service'
import { focusViewManager } from '../../main/service/focus-view/focus-view-manager'
import { setupEditorTraceWindowEvent } from '../../main/service/focus-view/register-event-listener'
import { HyperlinkService } from '../../main/service/hyperlink-service'
import { ImageService } from '../../main/service/image-service'
import { LayerPanelService } from '../../main/service/layer-panel-service'
import { OrganizationNotifyService } from '../../main/service/organization-notify-service'
import { PagePanelService } from '../../main/service/page-panel-service'
import { PlanAndUserStateService } from '../../main/service/plan-and-user-service/plan-and-user-state-service'
import { PreExportService } from '../../main/service/pre-export-service'
import { PrototypePreviewService } from '../../main/service/prototype-preview-service'
import { PrototypeService } from '../../main/service/prototype-service'
import { RawMemAccessService } from '../../main/service/raw-mem-access-service'
import { TitleInputService } from '../../main/service/title-input-service'
import { VariableService } from '../../main/service/variable-service'
import { ViewMenuService } from '../../main/service/view-menu-service'
import { ViewportAnimationService } from '../../main/service/viewport-animation-service'
import { UserConfigService } from '../../main/user-config/user-config-service'
import { AIDesignLintService } from '../../ui/component/ai/ai-design-lint/ai-design-lint-service'
import { ChatBotService } from '../../ui/component/chatbot/service/chatbot-service'
import { CommentService } from '../../ui/component/comment/comment-service/comment-service'
import { LibraryComponentService } from '../../ui/component/component-style-library-v2/library-service/library-component-service'
import { ComponentService } from '../../ui/component/component/component-service'
import { CooperationService } from '../../ui/component/cooperation/service/cooperation-service'
import { ReplayService } from '../../ui/component/crash/replay-service'
import { DevModeHoverNodeLabelService } from '../../ui/component/dev-mode/layer/service/hover-node-label-service'
import { HistoryService } from '../../ui/component/history-file/history-service/history-service'
import { MouseOnCanvas } from '../../ui/component/mouse-on-canvas/mouse-on-canvas'
import { PluginService } from '../../ui/component/plugin/plugin-service'
import { ExportSyncStatusService } from '../../ui/component/top-area/tool-sync-status/service/export-sync-status-service'
import { SynergySyncStatusService } from '../../ui/component/top-area/tool-sync-status/service/synergy-sync-status-service'
import { SelectionService } from '../../ui/document/selection/selection-service'
import { ViewStateBridge } from '../../view-state-bridge/view-state-bridge'
import { isDocReadonly$ } from './doc-readonly'
import { notify$ } from './notify'
import { payment$ } from './payment'
import { cloudFonts$, localFonts$ } from './preload-fonts'
import { connectSynergySessionChange } from './synergy-session-change'
import { ticket$ } from './ticket'

export const setupEditor$ = traceable(
    'hulh01@kanyun.com',
    command(
        async (
            { get },
            signal: TraceableAbortSignal,
            canvas: HTMLCanvasElement,
            controller: EffectController
        ): Promise<EditorContext> => {
            const editor = await get(wasm$)

            signal.throwIfAborted()

            const notifyService = get(notify$)!

            const ticket = (await get(ticket$))!

            signal.throwIfAborted()

            const { docId, userId, orgId, schemaVersion } = ticket
            // js 提供 ticketSchemaVersion
            schemaVersionManager.injectTicketSchemaVersion(schemaVersion)

            const docReadonly = (await get(isDocReadonly$))!

            const payment = await get(payment$)

            signal.throwIfAborted()

            // setup context memebers
            const { bridge, editorService, destroy: destroyKernel } = setupKernel(editor, controller)

            if (!IN_JEST_TEST) {
                await bridge.initRecording(docId)
                signal.throwIfAborted()
            }

            const networkService = new NetworkService(signal)
            const {
                documentRoot,
                commandInvoker,
                imageDownloadContext,
                connectSynergy,
                destroySynergy,
                destroy: destroyDocument,
                webSocketBridge,
                synergyManager,
                resetRafCount,
            } = initDocumentContext(
                bridge,
                docId,
                userId,
                {
                    docMode: Wukong.DocumentProto.DocMode.DOC_MODE_DEFAULT,
                    renderType: Wukong.DocumentProto.RenderType.RENDER_TYPE_NORMAL,
                },
                controller,
                signal
            )
            // 在编辑器中，向 synergy 同步编辑模式状态。非核心逻辑，try catch 一下不阻塞流程
            try {
                connectSynergySessionChange(signal, bridge, webSocketBridge, synergyManager, commandInvoker)
            } catch (e) {
                Sentry.captureException(e)
            }

            const gpuService = new GPUService(bridge, commandInvoker, controller)
            const activeElementBlurService = new ActiveElementBlurService()
            const colorProfileService = new ColorProfileService(bridge, controller)
            const canvasRenderBridge = new CanvasRenderBridge(bridge as EmBridge, controller)
            const preExportService = new PreExportService(bridge)
            const rawMemAccessService = new RawMemAccessService(bridge)
            const clipboardService = setupClipboard(
                signal,
                bridge,
                canvasRenderBridge,
                commandInvoker,
                imageDownloadContext,
                preExportService,
                docId,
                notifyService
            )
            const viewStateBridge = new ViewStateBridge(bridge, controller)
            const cooperationService = new CooperationService(
                commandInvoker,
                viewStateBridge,
                webSocketBridge,
                bridge,
                signal
            )
            const synergySyncStatusService = new SynergySyncStatusService(
                signal,
                viewStateBridge,
                webSocketBridge,
                synergyManager,
                commandInvoker,
                imageDownloadContext
            )
            const devModeAvailabilityVerificationService = new DevModeAvailabilityVerificationService(
                commandInvoker,
                webSocketBridge,
                signal
            )

            const exportSyncStatusService = new ExportSyncStatusService(signal, imageDownloadContext)
            const historyService = new HistoryService(viewStateBridge, clipboardService, docId, userId)
            const userConfigService = new UserConfigService(controller)

            await userConfigService.init(docId, docReadonly)
            signal.throwIfAborted()

            userConfigService.provideBridge(bridge)
            const libraryComponentService = new LibraryComponentService(
                docId,
                docReadonly,
                viewStateBridge,
                commandInvoker,
                bridge,
                historyService,
                userConfigService,
                colorProfileService,
                networkService,
                notifyService,
                signal,
                canvas
            )
            const componentService = new ComponentService(docId, orgId, commandInvoker, bridge)
            const variableService = new VariableService(signal, bridge, commandInvoker, viewStateBridge)
            const handlerProvider = new HandlerProvider(
                documentRoot,
                commandInvoker,
                clipboardService,
                bridge,
                libraryComponentService,
                userConfigService,
                imageDownloadContext
            )
            const cursorManager = new CursorManager(bridge, commandInvoker)
            const organizationNotifyService = new OrganizationNotifyService(signal, orgId, notifyService)
            const desktopPreviewService = new DesktopPreviewService(signal, docId, notifyService, commandInvoker)
            const viewportAnimationService = new ViewportAnimationService(
                bridge,
                commandInvoker,
                viewStateBridge,
                signal
            )
            const commentService = new CommentService(
                signal,
                viewStateBridge,
                commandInvoker,
                bridge,
                notifyService,
                viewportAnimationService,
                docId,
                orgId,
                controller
            )
            const selectionService = new SelectionService(bridge)
            const replayService = new ReplayService(bridge, controller)
            const viewMenuService = new ViewMenuService(viewStateBridge, commandInvoker)
            const aiService = new AiService(
                signal,
                bridge,
                commandInvoker,
                networkService,
                colorProfileService,
                docId,
                docReadonly,
                orgId,
                notifyService
            )
            const pluginHostService = new PluginHostService(
                bridge,
                commandInvoker,
                userId,
                historyService,
                imageDownloadContext,
                canvasRenderBridge,
                rawMemAccessService,
                signal,
                libraryComponentService,
                editor,
                docReadonly,
                viewStateBridge
            )
            const hyperlinkService = new HyperlinkService(bridge, aiService, orgId)

            const mouseOnCanvas = new MouseOnCanvas(commandInvoker)

            const fontManagerService = new FontManagerService(signal, bridge, docId, get(cloudFonts$), get(localFonts$))

            const clientService = new ClientService(viewStateBridge, docReadonly, controller)
            const pluginService = new PluginService(
                controller,
                commandInvoker,
                userConfigService,
                pluginHostService,
                bridge,
                clientService,
                userId,
                orgId,
                docId
            )

            const chatbotService = new ChatBotService(controller, bridge, commandInvoker, viewStateBridge, docId)

            const titleInputService = new TitleInputService(bridge)
            const imageService = new ImageService(bridge, commandInvoker, imageDownloadContext)
            const exporterService = new ExporterService(commandInvoker, bridge, imageService)

            const layerPanelService = new LayerPanelService(bridge)
            const pagePanelService = new PagePanelService(commandInvoker, handlerProvider, viewStateBridge, docReadonly)
            const canvasSearchService = new CanvasSearchService(bridge)
            const prototypeService = new PrototypeService(bridge)
            const prototypePreviewService = new PrototypePreviewService(bridge, imageDownloadContext, controller)

            const devModeHoverNodeLabelService = new DevModeHoverNodeLabelService(viewStateBridge, controller)

            const aiDesignLintService = new AIDesignLintService(docId)

            const planAndUserStateService = new PlanAndUserStateService(
                signal,
                commandInvoker,
                bridge,
                userId,
                false,
                false,
                controller,
                ticket,
                notifyService,
                payment
            )

            const copyCheckService = new CopyCheckService(signal, bridge, notifyService, docId)
            const colorModeService = new ColorModeService()
            const colorModeServiceV2 = new ColorModeServiceV2()

            const controlParagraphService = new ControlParagraphService(bridge)

            const componentPropService = new ComponentPropService()

            const destroyDownloadDocument = registerExportDocumentCommand(docId)
            const destroyLocateNodeCommand = registerLocateNodeCommand(commandInvoker)

            featureSwitchManager.connect(bridge)
            cursorManager.connectCursor()
            benchmarkService.setGraphicCardInfo(gpuService.getGraphicCardInfo())
            const ejectBenchmarkService = benchmarkService.injectBridge(bridge)

            focusViewManager.setupEditorContext(signal, { bridge, commandInvoker })

            const destroyDesignPanelInputKeyboardEventService =
                setupDesignPanelInputKeyboardEventService(commandInvoker)

            bridge.bind(AddFrog, (arg) => AddFrogRecord(arg, ticket))

            bridge.autofillUnbindedJsCallsWithErrorCallback()

            await editorService.initCanvas(canvas)

            signal.throwIfAborted()

            gpuService.afterInitCanvas(signal)
            editorService.startEditor2(schemaVersion)

            setupEditorTraceWindowEvent(bridge, signal)

            // expose necessary members
            return {
                docId,
                userId,
                ticket,
                canvas,
                bridge,
                editor,
                editorService,
                documentRoot,
                commandInvoker,
                handlerProvider,
                imageDownloadContext,
                cooperationService,
                cursorManager,
                exporterService,
                rawMemAccessService,
                colorProfileService,
                organizationNotifyService,
                preExportService,
                fontManagerService,
                commentService,
                viewportAnimationService,
                historyService,
                synergySyncStatusService,
                exportSyncStatusService,
                aiService,
                selectionService,
                replayService,
                viewMenuService,
                clientService,
                libraryComponentService,
                componentService,
                variableService,
                mouseOnCanvas,
                userConfigService,
                canvasRenderBridge,
                clipboardService,
                viewStateBridge,
                chatbotService,
                pluginService,
                networkService,
                titleInputService,
                webSocketBridge,
                hyperlinkService,
                imageService,
                layerPanelService,
                pagePanelService,
                canvasSearchService,
                devModeHoverNodeLabelService,
                aiDesignLintService,
                gpuService,
                planAndUserStateService,
                copyCheckService,
                prototypeService,
                devModeAvailabilityVerificationService,
                colorModeService,
                colorModeServiceV2,
                pluginHostService,
                componentPropService,
                notifyService,
                resetRafCount,
                connectSynergy: () => {
                    connectSynergy()
                },
                destroySynergy,
                destroyEditorWasm: () => {
                    destroyKernel()
                },
                destroyEditorWeb: () => {
                    destroyDocument()
                    destroyDownloadDocument()
                    destroyLocateNodeCommand()
                    activeElementBlurService.destory()
                    clipboardService.destroy()
                    fontManagerService.destroy()
                    commentService.destroy()
                    synergySyncStatusService.destroy()
                    exportSyncStatusService.destroy()
                    historyService.destroy()
                    selectionService.destroy()
                    libraryComponentService.destroy()
                    userConfigService.destroy()
                    aiService.destroy()
                    chatbotService.destroy()
                    pluginService.destroy()
                    clientService.destroy()
                    viewStateBridge.destroy()
                    cursorManager.destroy()
                    exporterService.destroy()
                    desktopPreviewService.destroy()
                    preExportService.destroy()
                    rawMemAccessService.destroy()
                    canvasRenderBridge.destroy()
                    componentService.destroy()
                    replayService.destroy()
                    titleInputService?.destroy()
                    cooperationService.destroy()
                    schemaVersionManager.destroy()
                    hyperlinkService.destroy()
                    imageService.destroy()
                    layerPanelService.destroy()
                    pagePanelService.destroy()
                    canvasSearchService.destroy()
                    ejectBenchmarkService()
                    colorProfileService.destroy()
                    devModeHoverNodeLabelService.destroy()
                    aiDesignLintService.destroy()
                    gpuService.destroy()
                    bridge.unbind(AddFrog)
                    destroyDesignPanelInputKeyboardEventService()
                    prototypeService.destroy()
                    prototypePreviewService.destroy()
                    planAndUserStateService.destroy()
                    viewMenuService.destroy()
                    colorModeService.destroy()
                    colorModeServiceV2.destroy()
                    componentPropService.destroy()
                    controlParagraphService.destroy()
                },
            } as const
        }
    )
)
