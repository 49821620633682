/**
 * @owner: jiangzg@kanyun.com
 */
import { environment } from '../../../../app/src/environment'
import { WKToast } from '../wk-toast'
import { translation } from './index.translation'

export function showImageResizeTip() {
    WKToast.show(`${translation('jZimJh')}`, {
        duration: 5000,
        secondButton: {
            type: 'button',
            text: translation('LearnMore'),
            dataTestId: 'show-more-btn',
            onClick: () => window.open(environment.helpDocsUrl.image, '_blank'),
        },
    })
}
