import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    ReplaceComponent: '替换组件',
    ReplaceStyle: '替换样式',
    CurrentPage: '当前页面',
    CurrentFile: '全部页面',
    NoReplacePageStyle: '当前「页面」中没有要替换为样式的属性',
    NoReplacePageComponent: '当前「页面」中没有要替换为组件的元素',
    NoReplaceFileStyle: '当前「文件」中没有要替换为样式的属性',
    NoReplaceFileComponent: '当前「文件」中没有要替换为组件的元素',
    ReplaceStyleComponent: '替换样式组件',
    Replace: '替换',
    View: '查看',
    SelectLayers1: '选中使用该属性的 ',
    SelectLayers2: ' 个图层',
} as const

export const enTranslation = {
    ReplaceComponent: 'Replace components',
    ReplaceStyle: 'Replace styles',
    CurrentPage: 'This page',
    CurrentFile: 'All page',
    NoReplacePageStyle: 'There are no properties to be replaced with styles in "This page".',
    NoReplacePageComponent: 'There are no elements to be replaced with components in "This page".',
    NoReplaceFileStyle: 'There are no properties to be replaced with styles iin "All page".',
    NoReplaceFileComponent: 'There are no elements to be replaced with components in "All page".',
    ReplaceStyleComponent: 'Replace styles and components',
    Replace: 'Replace',
    View: 'View',
    SelectLayers1: 'Select ',
    SelectLayers2: ' layers that use this property',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
