import { forwardRef, useImperativeHandle, useRef } from 'react'
import { ExplicitUndefined } from '../../../utils/explicit-undefined'
import { Empty, EmptyProps, EmptyRef } from '../picks/empty/empty'
import { TriggerMinimal, TriggerMinimalRef } from '../triggers/trigger-minimal'
import { SelectEmptyProps, SelectTriggerMinimalProps } from './type'
import { useCommentSelect } from './use-comment-select'

export interface SelectMinimalEmptyRef {
    close: () => void
}

export type SelectMinimalEmptyProps = SelectTriggerMinimalProps & SelectEmptyProps

function _SelectMinimalEmpty(props: SelectMinimalEmptyProps, ref?: React.Ref<SelectMinimalEmptyRef>) {
    const pickRef = useRef<EmptyRef>(null)
    const triggerRef = useRef<TriggerMinimalRef>(null)
    const {
        pickRest,
        worldRect,
        triggerRect,
        placement,
        overMajorClassName,
        overMinorClassName,
        isMinWidthFromTrigger = true,
        minWidth,
        width,
        maxWidth,
        autoAdjustLeft,
        onKeyboard,
        onClose,
        onOpen,
        ...otherProps
    } = props
    const commentSelectState = useCommentSelect({
        triggerRect,
        triggerRef,
        pickRef,
        onOpen,
        onClose,
    })
    useImperativeHandle(ref, () => ({ close: () => pickRef.current?.close() }), [])

    return (
        <TriggerMinimal
            ref={triggerRef}
            {...otherProps}
            isOpenPick={commentSelectState.isOpenPick}
            openAction={commentSelectState.openAction}
            closeAction={commentSelectState.closeAction}
        >
            <Empty<ExplicitUndefined<EmptyProps>>
                pickRest={pickRest}
                dataTestIds={props.dataTestIds}
                triggerRect={commentSelectState.triggerRect}
                closeByESC={commentSelectState.closeByESC}
                onClose={commentSelectState.onSelectClose}
                onOpen={commentSelectState.onSelectOpen}
                ref={pickRef}
                worldRect={worldRect}
                placement={placement}
                overMajorClassName={overMajorClassName}
                overMinorClassName={overMinorClassName}
                isMinWidthFromTrigger={isMinWidthFromTrigger}
                minWidth={minWidth}
                width={width}
                maxWidth={maxWidth}
                onKeyboard={onKeyboard}
                isOpenState={undefined}
                openStateToBeFalse={undefined}
                openStateToBeTrue={undefined}
                getPortalElement={commentSelectState.getPortalElement}
                removeMask={undefined}
                autoAdjustLeft={autoAdjustLeft}
            >
                {props.children}
            </Empty>
        </TriggerMinimal>
    )
}
export const SelectMinimalEmpty = forwardRef(_SelectMinimalEmpty)
