/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useCallback, useRef } from 'react'
import { useViewCallback, useViewState } from '../../../view-state-bridge'
import { getSVGIconByNodeIconType } from '../layer-panel/render-item-icon'
import styles from './canvas-search-high-light-title.module.less'

const Gap = 6
const TitleHeight = 22

function CanvasSearchHighLightTitleInternal() {
    const canvasSearchHighLightTitle = useViewState('canvasSearchHighLightTitle')!

    const lastCanvasSearchHighLightTitle = useRef<Wukong.DocumentProto.ICanvasSearchHighLightTitle>()
    lastCanvasSearchHighLightTitle.current = canvasSearchHighLightTitle

    const ref = useRef<HTMLDivElement | null>()
    const updatePositionCallback = useCallback((state: Wukong.DocumentProto.ICanvasSearchHighLightTitle) => {
        lastCanvasSearchHighLightTitle.current = state

        if (ref.current && state?.show) {
            const left = state.left ?? 0
            const top = state.top ?? 0
            ref.current.style.setProperty('left', left + 'px')
            ref.current.style.setProperty('top', top - TitleHeight - Gap + 'px')
        }
    }, [])

    // 绕过React的batchUpdate, 直接监听viewState变化操作原生DOM, 避免拖动画布时抖动
    useViewCallback('canvasSearchHighLightTitle', updatePositionCallback)

    const refCallback = useCallback(
        (el: HTMLDivElement | null) => {
            ref.current = el
            if (lastCanvasSearchHighLightTitle.current) {
                updatePositionCallback(lastCanvasSearchHighLightTitle.current)
            }
        },
        [updatePositionCallback]
    )

    return (
        <div
            ref={refCallback}
            className={classNames(styles.root, { [styles.purple]: canvasSearchHighLightTitle.isPurple })}
            data-testid="canvas-search-high-light-title"
        >
            {getSVGIconByNodeIconType(canvasSearchHighLightTitle.nodeIcon as Wukong.DocumentProto.NodeIconData)}
            <div className={styles.name}>{canvasSearchHighLightTitle.name}</div>
        </div>
    )
}

export function CanvasSearchHighLightTitle() {
    const canvasSearchHighLightTitle = useViewState('canvasSearchHighLightTitle')

    if (!canvasSearchHighLightTitle?.show) {
        return null
    }

    return <CanvasSearchHighLightTitleInternal />
}
