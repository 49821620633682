import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    Recent2Far: '近-远',
    Far2Recent: '远-近',
    MostToLeast: '多-少',
    LeastToMost: '少-多',
    HighLow: '高-低',
    LowHigh: '低-高',
} as const

export const enTranslation = {
    Recent2Far: 'Newest first',
    Far2Recent: 'Oldest first',
    MostToLeast: 'Largest first',
    LeastToMost: 'Smallest first',
    HighLow: 'Highest first',
    LowHigh: 'Lowest first',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
