import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    des: '设计席位',
    dev: '研发席位',
} as const

export const enTranslation = {
    des: 'Designer seat',
    dev: 'Developer seat',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
