import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    AfterMoving: '移动后',
    TheOriginalMembers: '原本的访问权限可能会丢失',
    FileMoved: '移动成功',

    DialogDescriptionPrefixInTeam: '移动文件到专业版团队',
    DialogDescriptionPrefixInOrg: '移动文件到团队中',
} as const

export const enTranslation = {
    AfterMoving: 'After moving',
    TheOriginalMembers: 'the original members may lose access to the file',
    FileMoved: 'File moved',

    DialogDescriptionPrefixInTeam: 'To move files to a Professional team',
    DialogDescriptionPrefixInOrg: 'To move files to a team',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
