import { createSelectors, createStore } from '../../../../util/src'

// 组件属性

export class ComponentPropService {
    public states = createSelectors(
        createStore<{ instancePanelLocateDefId: string | null }>(() => ({
            instancePanelLocateDefId: null,
        }))
    )

    public setInstancePanelLocateDefId(value: string | null) {
        this.states.setState({ instancePanelLocateDefId: value })
    }

    destroy() {
        this.setInstancePanelLocateDefId(null)
    }
}
