import { Wukong } from '@wukong/bridge-proto'
import { IN_JEST_TEST } from '../../environment/index'
import { MemoryReport } from './types'

const WasmCallCode = Wukong.DocumentProto.WasmCallCode
const JsCallCallCode = Wukong.DocumentProto.JsCallCode

export const MAX_WINDOW_SIZE = 10000

/**
 * 用于生成用户的内存报告
 * 内存用量每间隔 1s 记录一次，记录期间的 wasmCalls / usedMemory / scenNodeCounts
 * 在内存溢出时上报到 sentry，用于观测用户内存的过程变化趋势
 */
class MemoryReportService {
    private memoryReport: MemoryReport = []

    private lastPeriodWasmCalls: Array<string> = []

    // 过滤掉内存无关的 wasmCall
    private shouldIgnoreWasmCallSet = new Set([
        'WCC_getUsedMemory',
        'WCC_getSingleViewStateValue',
        'WCC_onAnimationFrame',
        'WCC_enableSingleViewState',
        'WCC_disableSingleViewState',
        'WCC_setDevicePixelRatio',
        'WCC_cmdUpdateMouseCameraPosition',
        'WCC_cmdShouldMoveViewportWhenOutsideCanvas',
        'WCC_cmdUpdateCursorWithinCanvas',
        'WCC_clearHoverPopupHyperlink',
    ])

    /**
     * 记录当前的内存使用情况，记录时会合并过去一段时间间隔内的 wasmCalls
     * @param usedMemory
     */
    public recordCurrentMemoryInfo(usedMemory: Wukong.DocumentProto.IRet_GetUsedMemoryCommand): void {
        const { value, sceneNodesCount } = usedMemory

        // 当内存用量没有发生变化时不进行记录
        if (value === this.memoryReport.at(-1)?.usedMemory) {
            this.lastPeriodWasmCalls = []
            return
        }

        this.memoryReport.push({
            usedMemory: value,
            wasmCalls: this.lastPeriodWasmCalls.join(','),
            timestamp: IN_JEST_TEST ? 0 : Date.now(),
            sceneNodesCount,
        })

        // 按照最大窗口数量滚动存储
        if (this.memoryReport.length > MAX_WINDOW_SIZE) {
            this.memoryReport.shift()
        }

        this.lastPeriodWasmCalls = []
    }

    /**
     * 记录内存变化间隔的 wasmCalls
     * @param bridgeCallCode
     */
    public recordLastPeriodWasmCall(bridgeCallCode: number): void {
        if (!JsCallCallCode[bridgeCallCode] && WasmCallCode[bridgeCallCode]) {
            const methodName = WasmCallCode[bridgeCallCode]
            // 记录非连续的 wasmCall
            if (!this.shouldIgnoreWasmCallSet.has(methodName) && this.lastPeriodWasmCalls.at(-1) !== methodName) {
                this.lastPeriodWasmCalls.push(methodName)
            }
        }
    }

    /**
     * 清除内存数据
     */
    public clear(): void {
        this.memoryReport = []
        this.lastPeriodWasmCalls = []
    }

    /**
     * 获取内存报告
     * @returns
     */
    public getReport(): MemoryReport {
        return this.memoryReport
    }
}

export const memoryReportService = new MemoryReportService()
