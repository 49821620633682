import { TraceableAbortSignal } from '../../../../../util/src/abort-controller/traceable-abort-controller'
import { pageSignal$ } from '../../../external-store/atoms/page-signal'
import { appStore$ } from '../../../external-store/store'
import { Bridge } from '../../../kernel/bridge/bridge'
import { traceWindowEvent } from './keyboard-event-to-wasm'

export function registerEditorWindowEventListener<E>(
    eventName: string,
    eventHandler: (e: E) => void,
    captured = false,
    signal = appStore$.get(pageSignal$)
) {
    window.addEventListener(eventName, eventHandler as unknown as (e: Event) => void, {
        capture: captured,
        signal,
    })
    return () => window.removeEventListener(eventName, eventHandler as unknown as (e: Event) => void, captured)
}

export function setupEditorTraceWindowEvent(brigde: Bridge, signal: TraceableAbortSignal) {
    registerEditorWindowEventListener('keydown', (e: KeyboardEvent) => traceWindowEvent(brigde, e), true, signal)
    registerEditorWindowEventListener('keyup', (e: KeyboardEvent) => traceWindowEvent(brigde, e), true, signal)
    registerEditorWindowEventListener('focus', (e: FocusEvent) => traceWindowEvent(brigde, e), true, signal)
    registerEditorWindowEventListener('blur', (e: FocusEvent) => traceWindowEvent(brigde, e), true, signal)
    registerEditorWindowEventListener('paste', (e: ClipboardEvent) => traceWindowEvent(brigde, e), true, signal)
    registerEditorWindowEventListener('copy', (e: ClipboardEvent) => traceWindowEvent(brigde, e), true, signal)
    registerEditorWindowEventListener('cut', (e: ClipboardEvent) => traceWindowEvent(brigde, e), true, signal)
    registerEditorWindowEventListener('mousedown', (e: ClipboardEvent) => traceWindowEvent(brigde, e), true, signal)
    registerEditorWindowEventListener('mouseup', (e: ClipboardEvent) => traceWindowEvent(brigde, e), true, signal)
    registerEditorWindowEventListener('click', (e: ClipboardEvent) => traceWindowEvent(brigde, e), true, signal)
    registerEditorWindowEventListener('contextmenu', (e: ClipboardEvent) => traceWindowEvent(brigde, e), true, signal)
}
