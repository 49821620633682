import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    Project: '项目',
    File: '文件',
    InheritPermissions: '继承权限',
    CanEdit: '可编辑',
    CanView: '可查看',
    CanViewPrototype: '可查看原型',
    Remove: '无权限',
    Owner: '所有者',
    Admin: '管理员',
    Remove_synonyms1: '移除',
    ResendInvite: '重新发送邀请',
    Leave: '离开',
} as const

export const enTranslation = {
    Project: 'project',
    File: 'file',
    InheritPermissions: 'Inherited access',
    CanEdit: 'Can edit',
    CanView: 'Can view',
    CanViewPrototype: 'Can view prototypes',
    Remove: 'No access',
    Owner: 'Owner',
    Admin: 'Admin',
    Remove_synonyms1: 'Remove',
    ResendInvite: 'Resend invite',
    Leave: 'Leave',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
