import { MonoIconCommonInfoLine16 } from '../../../../../ui-lib/src'
import { translation } from './index.translation'

import styles from './index.module.less'

export function MoveDraftTipBanner() {
    return (
        <div className={`py-3 px-6 ${styles.moveDraftTipBanner}`}>
            <MonoIconCommonInfoLine16 />
            <span className="ml-2">{translation('MoveDraftTip')}</span>
        </div>
    )
}
