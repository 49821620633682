import {
    ChangeScaleToolPanelCommand,
    CreatePageCommandWasmCall,
    CurrentPageSetSelectionCommandWasmCall,
    DeletePageCommandWasmCall,
    DispatchMouseEventCommand,
    GetTangentCameraPositionCommand,
    GetVertexCameraPositionCommand,
    MoveViewportCommand,
    OnBatchOperation,
    RemoveSelectedNodesCommand,
    SelectionAllNodesCommand,
    SetCurrentPageWasmCall,
    SetVariableModeBySetForPageCommand,
    ToggleEditingVectorStateCommandForJs,
    ToggleSystemMetricCommand,
    UndoRedoCommand,
    UpdateColorVariableValueCommand,
    UpdateSelectionFillPaintForSameOrAddCommand,
    Wukong,
    ZoomAtViewportCenterWasmCall,
    ZoomToSelectionWasmCall,
} from '@wukong/bridge-proto'
import { command } from 'ccstate'
import { TraceableAbortSignal } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { transaction } from '../../../../../../util/src/abort-controller/traceable-transaction'
import { traceable } from '../../../../../../util/src/ccstate-helper/traceable'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import { CommitType } from '../../../../document/command/commit-type'
import { benchmarkService } from '../../../../kernel/benchmark'
import { MetricCollector, MetricName } from '../../../../kernel/metric-collector'
import { WK } from '../../../../window/wk-object'
import { editorContext$ } from '../../../atoms/editor-context'

const MouseType = Wukong.DocumentProto.MouseEventType

const metricOpSystemCost = async (commandInvoker: CommandInvoker, op: () => void) => {
    return new Promise((resolve) => {
        commandInvoker.invokeBridge(CommitType.Noop, ToggleSystemMetricCommand, {
            value: true,
        })
        op()
        requestAnimationFrame(() => {
            stopMetricsAndCollect(commandInvoker)
            resolve(1)
        })
    })
}

const stopMetricsAndCollect = (commandInvoker: CommandInvoker) => {
    const systemMetrics = commandInvoker.invokeBridge(CommitType.Noop, ToggleSystemMetricCommand, {
        value: false,
    })
    MetricCollector.pushMetric(MetricName.SYSTEM_NAME_TO_COST, systemMetrics.system2Cost!)
    MetricCollector.pushMetric(MetricName.COMMAND_COST, systemMetrics.commandCost!)
    MetricCollector.pushMetric(MetricName.SYSTEM_FLUSH_COST, systemMetrics.systemFlushCost!)
    MetricCollector.pushMetric(MetricName.RENDER_COST, systemMetrics.renderCost!)
}

const benchmarkContinuousOperation = (
    op: () => void,
    opCount: number
): Promise<{ totalDuration: number; maxDuration: number; avgDuration: number }> => {
    return new Promise((resolve) => {
        let count = 0
        let totalDuration = 0
        let totalSingleOperationDuration = 0
        let maxDuration = 0
        const start = performance.now()

        const doOpration = () => {
            if (count === opCount) {
                totalDuration = performance.now() - start
                resolve({
                    totalDuration,
                    maxDuration,
                    avgDuration: totalSingleOperationDuration / opCount,
                })
                return
            }
            count++
            const singleStart = performance.now()
            op()
            requestAnimationFrame(() => {
                const singleDuration = performance.now() - singleStart
                totalSingleOperationDuration += singleDuration
                maxDuration = Math.max(maxDuration, singleDuration)
                doOpration()
            })
        }

        doOpration()
    })
}

export const prepareBenchmarkEnv$ = traceable(
    'hulh01@kanyun.com',
    command(async ({ get }, signal: TraceableAbortSignal) => {
        const editorContext = get(editorContext$)!

        signal.throwIfAborted()

        const { act } = transaction(signal)

        act('inject option copy to wk', () => {
            WK.optionCopy = async () => {
                const commandInvoker = editorContext.commandInvoker
                const start = performance.now()
                commandInvoker.invokeBridge(CommitType.Noop, DispatchMouseEventCommand, {
                    type: MouseType.MOUSE_EVENT_TYPE_DOWN,
                    triggerByKeyboard: false,
                    clientX: 250,
                    clientY: 282,
                    altKey: true,
                })
                await metricOpSystemCost(commandInvoker, () => {
                    commandInvoker.invokeBridge(CommitType.Noop, DispatchMouseEventCommand, {
                        type: MouseType.MOUSE_EVENT_TYPE_MOVE,
                        triggerByKeyboard: false,
                        clientX: 300,
                        clientY: 282,
                        altKey: true,
                    })
                })
                commandInvoker.invokeBridge(CommitType.Noop, DispatchMouseEventCommand, {
                    type: MouseType.MOUSE_EVENT_TYPE_UP,
                    triggerByKeyboard: false,
                    clientX: 300,
                    clientY: 282,
                    altKey: true,
                })
                requestAnimationFrame(() => {
                    MetricCollector.pushMetric(MetricName.OPTION_COPY_DURATION, performance.now() - start)
                })
            }

            return () => {
                delete WK.optionCopy
            }
        })

        act('inject on batch operation to wk', () => {
            WK.onBatchOperation = async (proto: Wukong.ServerProto.SynergyMessageProto) => {
                return new Promise((resolve) => {
                    const commandInvoker = editorContext.commandInvoker

                    const start = performance.now()
                    const payload = proto.payload
                    commandInvoker.invokeBridge(CommitType.Noop, OnBatchOperation, {
                        payload,
                        serverPayload: proto.serverPayload,
                        checksum: proto.checksum,
                        prevChecksum: proto.prevChecksum,
                        docHash: proto.docHash,
                        compressType: proto.compressType ?? Wukong.DocumentProto.CompressType.COMPRESS_TYPE_NO_COMPRESS,
                    })

                    requestAnimationFrame(() => {
                        MetricCollector.pushMetric(MetricName.ON_BATCH_OPEEATION_DURATION, performance.now() - start)
                        resolve(1)
                    })
                })
            }

            WK.onBatchOperationWithBase64 = async (proto: string) => {
                const decodedData = atob(proto)
                const decodedUint8Array = new Uint8Array(decodedData.split('').map((char) => char.charCodeAt(0)))
                const message = Wukong.ServerProto.SynergyMessageProto.decode(decodedUint8Array)
                return WK.onBatchOperation(message)
            }

            return () => {
                delete WK.onBatchOperation
                delete WK.onBatchOperationWithBase64
            }
        })

        act('inject zoom center to wk', () => {
            WK.zoomCenter = async () => {
                const commandInvoker = editorContext.commandInvoker
                let currentZoom = WK.viewState().currentViewport.zoom
                const step = (1 - currentZoom) / 10
                const { totalDuration, avgDuration, maxDuration } = await benchmarkContinuousOperation(() => {
                    currentZoom += step
                    commandInvoker.invokeBridge(CommitType.Noop, ZoomAtViewportCenterWasmCall, {
                        scale: currentZoom,
                    })
                }, 10)
                MetricCollector.pushMetric(MetricName.ZOOM_CENTER_DURATION, totalDuration)
                MetricCollector.pushMetric(MetricName.ZOOM_CENTER_OP_AVG, avgDuration)
                MetricCollector.pushMetric(MetricName.ZOOM_CENTER_OP_MAX, maxDuration)
            }
            return () => {
                delete WK.zoomCenter
            }
        })

        act('inject move viewport to wk', () => {
            WK.moveViewport = async () => {
                const commandInvoker = editorContext.commandInvoker

                commandInvoker.invokeBridge(CommitType.Noop, ToggleSystemMetricCommand, {
                    value: true,
                })

                const { totalDuration, avgDuration, maxDuration } = await benchmarkContinuousOperation(() => {
                    commandInvoker.invokeBridge(CommitType.Noop, MoveViewportCommand, {
                        deltaX: 5,
                        deltaY: 0,
                    })
                }, 10)

                stopMetricsAndCollect(commandInvoker)

                MetricCollector.pushMetric(MetricName.MOVE_VIEWPORT_DURATION, totalDuration)
                MetricCollector.pushMetric(MetricName.MOVE_VIEWPORT_OP_AVG, avgDuration)
                MetricCollector.pushMetric(MetricName.MOVE_VIEWPORT_OP_MAX, maxDuration)
            }
            return () => {
                delete WK.moveViewport
            }
        })

        act('inject drag node to wk', () => {
            WK.dragNode = async (opCount = 10) => {
                const commandInvoker = editorContext.commandInvoker
                commandInvoker.invokeBridge(CommitType.Noop, DispatchMouseEventCommand, {
                    type: MouseType.MOUSE_EVENT_TYPE_DOWN,
                    clientX: 250,
                    clientY: 282,
                })
                let startClientX = 250
                commandInvoker.invokeBridge(CommitType.Noop, ToggleSystemMetricCommand, {
                    value: true,
                })
                const { totalDuration, avgDuration, maxDuration } = await benchmarkContinuousOperation(() => {
                    startClientX += 5
                    commandInvoker.invokeBridge(CommitType.Noop, DispatchMouseEventCommand, {
                        type: MouseType.MOUSE_EVENT_TYPE_MOVE,
                        clientX: startClientX,
                        clientY: 282,
                    })
                }, opCount)
                stopMetricsAndCollect(commandInvoker)
                commandInvoker.invokeBridge(CommitType.Noop, DispatchMouseEventCommand, {
                    type: MouseType.MOUSE_EVENT_TYPE_UP,
                    clientX: startClientX,
                    clientY: 282,
                })
                MetricCollector.pushMetric(MetricName.DRAG_NODE_DURATION, totalDuration)
                MetricCollector.pushMetric(MetricName.DRAG_NODE_OP_AVG, avgDuration)
                MetricCollector.pushMetric(MetricName.DRAG_NODE_OP_MAX, maxDuration)
            }
            return () => {
                delete WK.dragNode
            }
        })

        act('inject benchmark switch page to wk', () => {
            WK.switchPage = async (targetPageIndex: number) => {
                return new Promise((resolve) => {
                    const page = WK.viewState().documentPageList.pages[targetPageIndex]
                    const start = performance.now()
                    editorContext.commandInvoker.invokeBridge(
                        CommitType.Noop,
                        SetCurrentPageWasmCall,
                        Wukong.DocumentProto.SetCurrentPageCommandParam.create({
                            nodeId: page.id,
                        })
                    )
                    requestAnimationFrame(() => {
                        MetricCollector.pushMetric(MetricName.SWITCH_PAGE_DURATION, performance.now() - start)
                        resolve(1)
                    })
                })
            }

            return () => {
                delete WK.switchPage
            }
        })

        act('inject benchmark select all to wk', () => {
            WK.selectAll = async () => {
                return new Promise((resolve) => {
                    const start = performance.now()
                    editorContext.commandInvoker.invokeBridge(CommitType.Noop, SelectionAllNodesCommand)
                    requestAnimationFrame(() => {
                        MetricCollector.pushMetric(MetricName.SELECT_ALL_DURATION, performance.now() - start)
                        resolve(1)
                    })
                })
            }

            return () => {
                delete WK.selectAll
            }
        })

        act('inject benchmark drag tangent to wk', () => {
            WK.dragTangent = async () => {
                const commandInvoker = editorContext.commandInvoker
                commandInvoker.invokeBridge(CommitType.Noop, CurrentPageSetSelectionCommandWasmCall, {
                    selection: ['3633:5765'],
                })
                commandInvoker.invokeBridge(CommitType.Noop, ZoomToSelectionWasmCall)
                commandInvoker.invokeBridge(CommitType.Noop, ToggleEditingVectorStateCommandForJs)
                const { x, y } = commandInvoker.invokeBridge(CommitType.Noop, GetVertexCameraPositionCommand, {
                    value: 2,
                })
                commandInvoker.invokeBridge(CommitType.Noop, DispatchMouseEventCommand, {
                    type: MouseType.MOUSE_EVENT_TYPE_MOVE,
                    clientX: x,
                    clientY: y,
                })
                commandInvoker.invokeBridge(CommitType.Noop, DispatchMouseEventCommand, {
                    type: MouseType.MOUSE_EVENT_TYPE_DOWN,
                    clientX: x,
                    clientY: y,
                })
                commandInvoker.invokeBridge(CommitType.Noop, DispatchMouseEventCommand, {
                    type: MouseType.MOUSE_EVENT_TYPE_UP,
                    clientX: x,
                    clientY: y,
                })
                const { x: tangentX, y: tangentY } = commandInvoker.invokeBridge(
                    CommitType.Noop,
                    GetTangentCameraPositionCommand,
                    {
                        value: 2,
                    }
                )
                commandInvoker.invokeBridge(CommitType.Noop, DispatchMouseEventCommand, {
                    type: MouseType.MOUSE_EVENT_TYPE_MOVE,
                    clientX: tangentX,
                    clientY: tangentY,
                })
                commandInvoker.invokeBridge(CommitType.Noop, DispatchMouseEventCommand, {
                    type: MouseType.MOUSE_EVENT_TYPE_DOWN,
                    clientX: tangentX,
                    clientY: tangentY,
                })
                let startClientY = tangentY!
                commandInvoker.invokeBridge(CommitType.Noop, ToggleSystemMetricCommand, {
                    value: true,
                })
                const { totalDuration, avgDuration, maxDuration } = await benchmarkContinuousOperation(() => {
                    startClientY -= 2
                    commandInvoker.invokeBridge(CommitType.Noop, DispatchMouseEventCommand, {
                        type: MouseType.MOUSE_EVENT_TYPE_MOVE,
                        clientX: 469,
                        clientY: startClientY,
                    })
                }, 5)
                stopMetricsAndCollect(commandInvoker)
                commandInvoker.invokeBridge(CommitType.Noop, DispatchMouseEventCommand, {
                    type: MouseType.MOUSE_EVENT_TYPE_UP,
                    clientX: tangentX,
                    clientY: startClientY,
                })
                MetricCollector.pushMetric(MetricName.DRAG_TANGENT_DURATION, totalDuration)
                MetricCollector.pushMetric(MetricName.DRAG_TANGENT_OP_AVG, avgDuration)
                MetricCollector.pushMetric(MetricName.DRAG_TANGENT_OP_MAX, maxDuration)
            }

            return () => {
                delete WK.dragTangent
            }
        })

        act('inject benchmark drag vertex to wk', () => {
            WK.dragVertex = async () => {
                const commandInvoker = editorContext.commandInvoker
                commandInvoker.invokeBridge(CommitType.Noop, CurrentPageSetSelectionCommandWasmCall, {
                    selection: ['3633:5765'],
                })
                commandInvoker.invokeBridge(CommitType.Noop, ZoomToSelectionWasmCall)
                commandInvoker.invokeBridge(CommitType.Noop, ToggleEditingVectorStateCommandForJs)
                const { x: clientX, y: clientY } = commandInvoker.invokeBridge(
                    CommitType.Noop,
                    GetVertexCameraPositionCommand,
                    {
                        value: 2,
                    }
                )
                commandInvoker.invokeBridge(CommitType.Noop, DispatchMouseEventCommand, {
                    type: MouseType.MOUSE_EVENT_TYPE_MOVE,
                    clientX,
                    clientY,
                })
                commandInvoker.invokeBridge(CommitType.Noop, DispatchMouseEventCommand, {
                    type: MouseType.MOUSE_EVENT_TYPE_DOWN,
                    clientX,
                    clientY,
                })
                let startClientY = clientY!
                commandInvoker.invokeBridge(CommitType.Noop, ToggleSystemMetricCommand, {
                    value: true,
                })
                const { totalDuration, avgDuration, maxDuration } = await benchmarkContinuousOperation(() => {
                    startClientY -= 2
                    commandInvoker.invokeBridge(CommitType.Noop, DispatchMouseEventCommand, {
                        type: MouseType.MOUSE_EVENT_TYPE_MOVE,
                        clientX,
                        clientY: startClientY,
                    })
                }, 5)
                stopMetricsAndCollect(commandInvoker)
                commandInvoker.invokeBridge(CommitType.Noop, DispatchMouseEventCommand, {
                    type: MouseType.MOUSE_EVENT_TYPE_UP,
                    clientX,
                    clientY: startClientY,
                })
                MetricCollector.pushMetric(MetricName.DRAG_VERTEX_DURATION, totalDuration)
                MetricCollector.pushMetric(MetricName.DRAG_VERTEX_OP_AVG, avgDuration)
                MetricCollector.pushMetric(MetricName.DRAG_VERTEX_OP_MAX, maxDuration)
            }

            return () => {
                delete WK.dragVertex
            }
        })

        act('inject benchmark deletePage to wk', () => {
            WK.deletePage = async (pageIndex: number) => {
                return new Promise((resolve) => {
                    editorContext.commandInvoker.invokeBridge(CommitType.Noop, CreatePageCommandWasmCall)
                    const start = performance.now()
                    editorContext.commandInvoker.invokeBridge(CommitType.Noop, DeletePageCommandWasmCall, {
                        node: WK.viewState().documentPageList.pages[pageIndex].id,
                    })
                    requestAnimationFrame(() => {
                        MetricCollector.pushMetric(MetricName.DELETE_PAGE, performance.now() - start)
                        resolve(1)
                    })
                })
            }

            return () => {
                delete WK.deletePage
            }
        })

        act('inject benchmark scale to wk', () => {
            WK.scale = async () => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async (resolve) => {
                    const commandInvoker = editorContext.commandInvoker
                    commandInvoker.invokeBridge(CommitType.Noop, SelectionAllNodesCommand)
                    const start = performance.now()
                    await metricOpSystemCost(commandInvoker, () => {
                        commandInvoker.invokeBridge(CommitType.Noop, ChangeScaleToolPanelCommand, {
                            scaleFactorPercent: 2 * 100,
                        })
                    })
                    requestAnimationFrame(() => {
                        MetricCollector.pushMetric(MetricName.SCALE_DURATION, performance.now() - start)
                        resolve(1)
                    })
                })
            }

            return () => {
                delete WK.scale
            }
        })

        act('inject benchmark deleteAll to wk', () => {
            WK.deleteAll = async () => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async (resolve) => {
                    const commandInvoker = editorContext.commandInvoker
                    commandInvoker.invokeBridge(CommitType.Noop, SelectionAllNodesCommand)
                    const start = performance.now()
                    await metricOpSystemCost(commandInvoker, () => {
                        commandInvoker.invokeBridge(CommitType.Noop, RemoveSelectedNodesCommand)
                    })
                    requestAnimationFrame(() => {
                        MetricCollector.pushMetric(MetricName.DELETE_ALL, performance.now() - start)
                        resolve(1)
                    })
                })
            }

            return () => {
                delete WK.deleteAll
            }
        })

        act('inject benchmark deleteAllAndUndo to wk', () => {
            WK.deleteAllAndUndo = async () => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise((resolve) => {
                    const commandInvoker = editorContext.commandInvoker
                    commandInvoker.invokeBridge(CommitType.Noop, SelectionAllNodesCommand)
                    commandInvoker.invokeBridge(CommitType.Noop, RemoveSelectedNodesCommand)
                    requestAnimationFrame(async () => {
                        const start = performance.now()
                        await metricOpSystemCost(commandInvoker, () => {
                            commandInvoker.invokeBridge(
                                CommitType.Noop,
                                UndoRedoCommand,
                                Wukong.DocumentProto.UndoRedoCommandParam.create({
                                    metaKey: true,
                                    ctrlKey: false,
                                    shiftKey: false,
                                    altKey: false,
                                })
                            )
                        })

                        requestAnimationFrame(() => {
                            MetricCollector.pushMetric(MetricName.DELETE_ALL_AND_UNDO, performance.now() - start)
                            resolve(1)
                        })
                    })
                })
            }

            return () => {
                delete WK.deleteAllAndUndo
            }
        })

        act('inject benchmark allFillsToAll to wk', () => {
            WK.addFillsToAll = async () => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async (resolve) => {
                    const commandInvoker = editorContext.commandInvoker
                    commandInvoker.invokeBridge(CommitType.Noop, SelectionAllNodesCommand)
                    const start = performance.now()
                    await metricOpSystemCost(commandInvoker, () => {
                        commandInvoker.invokeBridge(CommitType.Noop, UpdateSelectionFillPaintForSameOrAddCommand)
                    })
                    requestAnimationFrame(() => {
                        MetricCollector.pushMetric(MetricName.ADD_FILLS_TO_ALL, performance.now() - start)
                        resolve(1)
                    })
                })
            }

            return () => {
                delete WK.addFillsToAll
            }
        })

        act('inject MetricImageDownloadSuccessRation timer', () => {
            const timer = benchmarkService.startMetricImageDownloadSuccessRation()

            return () => {
                clearInterval(timer)
            }
        })

        act('inject benchmark variableChangePageMode to wk', () => {
            WK.variableChangeMode = async () => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async (resolve) => {
                    const commandInvoker = editorContext.commandInvoker
                    const start = performance.now()
                    commandInvoker.invokeBridge(CommitType.Noop, SetVariableModeBySetForPageCommand, {
                        variableSetIds: ['54778:406'],
                        variableSetModeId: '54778:4',
                    })
                    requestAnimationFrame(() => {
                        MetricCollector.pushMetric(MetricName.VARIABLE_CHANGE_MODE, performance.now() - start)
                        resolve(1)
                    })
                })
            }

            return () => {
                delete WK.variableChangeMode
            }
        })

        act('inject benchmark variableChangeColor to wk', () => {
            WK.variableChangeColor = async () => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async (resolve) => {
                    const commandInvoker = editorContext.commandInvoker
                    const start = performance.now()
                    commandInvoker.invokeBridge(CommitType.Noop, UpdateColorVariableValueCommand, {
                        variableId: '54778:407',
                        variableSetModeId: '54778:1',
                        colorRGBAValue: {
                            r: 0,
                            g: 255,
                            b: 0,
                            a: 255,
                        },
                    })
                    requestAnimationFrame(() => {
                        MetricCollector.pushMetric(MetricName.VARIABLE_CHANGE_COLOR, performance.now() - start)
                        resolve(1)
                    })
                })
            }

            return () => {
                delete WK.variableChangeColor
            }
        })
    })
)
