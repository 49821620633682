export function parseRGBA(color: string) {
    const rgbaRegex = /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)/
    const match = color.match(rgbaRegex)

    if (match) {
        const [_, r, g, b, a] = match // 解构匹配结果
        return {
            r: parseInt(r, 10),
            g: parseInt(g, 10),
            b: parseInt(b, 10),
            a: a !== undefined ? parseFloat(a) : 1, // 如果没有 a，默认为 1
        }
    } else {
        throw new Error('Invalid RGBA color string')
    }
}

export function getSVGString(svgElement: SVGElement) {
    return svgElement.outerHTML.replaceAll('&quot;', "'")
}

export function getOwnTextContent(element: HTMLElement) {
    const clone = element.cloneNode(true)
    let result = ''
    while (clone.firstChild) {
        if (clone.firstChild.nodeType === Node.TEXT_NODE) {
            result += clone.firstChild.textContent ? clone.firstChild.textContent.trim() : ''
        }
        clone.firstChild.remove()
    }

    return result
}

export const getPadding = (computedStyle: CSSStyleDeclaration) => {
    const paddingLeft = +computedStyle.paddingLeft.replace('px', '')
    const paddingRight = +computedStyle.paddingRight.replace('px', '')
    const paddingTop = +computedStyle.paddingTop.replace('px', '')
    const paddingBottom = +computedStyle.paddingBottom.replace('px', '')
    return {
        left: paddingLeft,
        right: paddingRight,
        top: paddingTop,
        bottom: paddingBottom,
        hasPadding: paddingLeft || paddingRight || paddingTop || paddingBottom,
    }
}

export const getMargin = (computedStyle: CSSStyleDeclaration) => {
    const marginLeft = +computedStyle.marginLeft.replace('px', '')
    const marginRight = +computedStyle.marginRight.replace('px', '')
    const marginTop = +computedStyle.marginTop.replace('px', '')
    const marginBottom = +computedStyle.marginBottom.replace('px', '')
    return {
        left: marginLeft,
        right: marginRight,
        top: marginTop,
        bottom: marginBottom,
        hasMargin: marginLeft || marginRight || marginTop || marginBottom,
    }
}

export class OrderIndexUtil {
    static default(): Uint8Array {
        return Uint8Array.from([0b10000000])
    }

    // 递增
    static greater(index: Uint8Array): Uint8Array {
        // 从右向左 找到不为0xFF的第一个byte，将其值加1，后续byte清空
        for (let i = index.length - 1; i >= 0; --i) {
            if (index[i] != 0xff) {
                const result = OrderIndexUtil.copy(index, Math.min(index.length, i + 1))
                result[i] = index[i] + 1
                return result
            }
        }
        // 如果找不到不为0xFF的byte，就在末尾添加一个新的byte，值为1
        const result = OrderIndexUtil.copy(index, index.length + 1)
        result[index.length] = 1
        return result
    }
    // 递减
    static less(index: Uint8Array): Uint8Array {
        // 从右向左找到不为0x00的第一个byte
        for (let i = index.length - 1; i >= 0; --i) {
            if (index[i] != 0x00) {
                // 拷贝数组并只保留有效部分
                const result = OrderIndexUtil.copy(index, Math.min(index.length, i + 1))
                result[i] = index[i] - 1
                // 将后续的字节全设置为0xFF
                for (let j = i + 1; j < result.length; j++) {
                    result[j] = 0xff
                }
                return result
            }
        }
        // 如果所有字节都为0x00，抛出错误
        throw new Error('Index underflow: cannot decrement further.')
    }
    /**
     * 从头开始复制数据
     * @param byte 源数据
     * @param length 目标长度，有可能超出原数据的长度
     * @returns
     */
    private static copy(byte: Uint8Array, length: number): Uint8Array {
        const buffer = new Uint8Array(length)
        const temp = byte.subarray(0, length)
        buffer.set(temp)
        return buffer
    }
}

let gloablNodeId = '3:0'

export function getNextNodeId() {
    gloablNodeId = `${gloablNodeId.split(':')[0]}:${Number(gloablNodeId.split(':')[1]) + 1}`
    return gloablNodeId
}

export class ParentInfo {
    orderIndexMap: Map<string, Uint8Array>
    constructor() {
        this.orderIndexMap = new Map()
    }
    getParentInfo(parentId: string, isLess = false) {
        let orderIndex = this.orderIndexMap.get(parentId)
        let updateOrderIndex = false
        if (!orderIndex) {
            orderIndex = OrderIndexUtil.default()
            updateOrderIndex = true
        } else {
            orderIndex = isLess ? OrderIndexUtil.less(orderIndex) : OrderIndexUtil.greater(orderIndex)
            // 这里先简单处理, 如果isLess为true, 不用改orderIndex, 但是复杂情况应该记一个最大值和最小值
            updateOrderIndex = !isLess
        }
        if (updateOrderIndex) {
            this.orderIndexMap.set(parentId, orderIndex)
        }
        return {
            parentId,
            orderIndex,
        }
    }
    reset() {
        this.orderIndexMap.clear()
    }
}

export const parentInfo = new ParentInfo()

export function getShadow(boxShadow: string) {
    const regex = /(rgb\(.*?\)|rgba\(.*?\))[^,]*/g
    const shadowParts = [...boxShadow.matchAll(regex)].map((match) => match[0])

    const shadowRegex =
        /(rgba?\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*,?\s*[\d.]*\s*\)|\d+)\s*(-?\d*\.?\d+px)\s*(-?\d*\.?\d+px)\s*(-?\d*\.?\d+px)\s*(-?\d*\.?\d+px)?/

    // 存储所有阴影的数组
    const shadows: {
        color: string
        offsetX: string
        offsetY: string
        blurRadius: string
        spreadRadius: string
        isInsert: boolean
    }[] = []

    shadowParts.forEach((shadow) => {
        const match = shadowRegex.exec(shadow)
        if (match) {
            shadows.push({
                color: match[1].trim(),
                offsetX: match[2].trim(),
                offsetY: match[3].trim(),
                blurRadius: match[4].trim(),
                spreadRadius: match[5] ? match[5].trim() : '0px',
                isInsert: shadow.includes('inset'),
            })
        }
    })

    return shadows
}

export function calculateVectorFromMatrix(x1: number, y1: number, rotationMatrix: number[][]) {
    // 提取旋转矩阵的元素
    const a = rotationMatrix[0][0]
    const b = rotationMatrix[0][1]
    const c = rotationMatrix[1][0]
    const d = rotationMatrix[1][1]

    // 计算旋转后的坐标 (x2, y2)
    const x2 = a * x1 + b * y1
    const y2 = c * x1 + d * y1

    // 从旋转后中心点到旋转前中心点的向量
    const vx = x1 - x2 // 向量的 x 分量
    const vy = y1 - y2 // 向量的 y 分量

    // 返回向量
    return { vx, vy }
}

// 矩阵乘法函数
export function multiplyMatrices(a: number[][], b: number[][]) {
    const result = Array.from({ length: 3 }, () => Array(3).fill(0))
    for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 3; j++) {
            result[i][j] = a[i][0] * b[0][j] + a[i][1] * b[1][j] + a[i][2] * b[2][j]
        }
    }
    return result
}

export function hexToRgb(hexColor: string) {
    if (!/^#([0-9A-Fa-f]{6})$/.test(hexColor)) {
        throw new Error('颜色HEX格式错误')
    }

    const r = parseInt(hexColor.slice(1, 3), 16)
    const g = parseInt(hexColor.slice(3, 5), 16)
    const b = parseInt(hexColor.slice(5, 7), 16)

    return { r, g, b }
}

export function getTransformMatrix(transform: string) {
    const regex = /matrix\(([^)]+)\)/
    const match = transform.match(regex)
    if (match) {
        return {
            scaleX: +match[1].split(',')[0],
            skewX: +match[1].split(',')[1],
            skewY: +match[1].split(',')[2],
            scaleY: +match[1].split(',')[3],
            translateX: +match[1].split(',')[4],
            translateY: +match[1].split(',')[5],
        }
    }
    return null
}

export function isRotate(matrix: { scaleX: number; skewX: number; skewY: number; scaleY: number }) {
    return matrix.scaleX !== 1 || matrix.skewX !== 0 || matrix.skewY !== 0 || matrix.scaleY !== 1
}

export function convertBorderRadius(borderRadius: string, diameter: number) {
    if (borderRadius.includes('px')) {
        return +borderRadius.replace('px', '')
    }
    if (borderRadius.includes('%')) {
        return (+borderRadius.replace('%', '') / 100) * diameter
    }
    return 0
}

export function isElement(node: Node) {
    return Object.prototype.toString.call(node).includes('Element')
}

export function getActiveBorderColor(computedStyle: CSSStyleDeclaration) {
    const borderTop = computedStyle.borderTop
    const borderRight = computedStyle.borderRight
    const borderBottom = computedStyle.borderBottom
    const borderLeft = computedStyle.borderLeft
    if (!borderTop.includes('none') && !borderTop.startsWith('0px')) {
        return computedStyle.borderTopColor
    }
    if (!borderRight.includes('none') && !borderRight.startsWith('0px')) {
        return computedStyle.borderRightColor
    }
    if (!borderBottom.includes('none') && !borderBottom.startsWith('0px')) {
        return computedStyle.borderBottomColor
    }
    if (!borderLeft.includes('none') && !borderLeft.startsWith('0px')) {
        return computedStyle.borderLeftColor
    }
    return computedStyle.borderTopColor
}
