import { DELETE, GET, POST } from '@tutor/network-core'
import {
    OrgID,
    ResourceType,
    TeamID,
    type AbroadPayInfoVO,
    type ContractCreateAbroadRequestVO,
    type ContractCreateNationalRequestVO,
    type ContractCreateResponseVO,
    type ContractId,
    type ContractSettlementDetailVO,
    type ContractSettlementsVO,
    type ContractStatusResponseVO,
    type ContractTerminationAppointmentResultVO,
    type ContractVO,
    type NationalPayInfoVO,
    type ProductVO,
    type SettlementId,
    type SettlementStatusResponseVO,
    type SettlementVO,
} from '../interface/type'
import { BaseCommonRequest } from './base-request'

// 查询有效合约
@GET
export class QueryEffectiveContract extends BaseCommonRequest<ContractVO> {
    constructor(
        private readonly resourceType: ResourceType.Organization | ResourceType.Team,
        private readonly resourceId: OrgID | TeamID
    ) {
        super()
    }

    public override requestArgument() {
        return { resourceType: this.resourceType, resourceId: this.resourceId }
    }

    public override requestUrl() {
        return 'contract'
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 查询合约支付状态
@GET
export class QueryContractStatus extends BaseCommonRequest<ContractStatusResponseVO> {
    constructor(private readonly contractId: ContractId) {
        super()
    }

    public override requestArgument() {
        return { contractId: this.contractId }
    }

    public override requestUrl() {
        return 'contract/status'
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 查询合约核算单支付状态
@GET
export class QuerySettlement extends BaseCommonRequest<SettlementStatusResponseVO> {
    constructor(private readonly settlementId: SettlementId) {
        super()
    }

    public override requestArgument() {
        return { settlementId: this.settlementId }
    }

    public override requestUrl() {
        return 'contract/settlement-status'
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 创建合约（海外）
@POST
export class CreateAbroadContract extends BaseCommonRequest<ContractCreateResponseVO<AbroadPayInfoVO>> {
    constructor(private readonly body: ContractCreateAbroadRequestVO) {
        super()
    }

    public override requestBody() {
        return this.body
    }

    public override requestUrl() {
        return 'contract/create'
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 更新合约
@POST
export class UpdatePaymentMethod extends BaseCommonRequest {
    constructor(private readonly paymentMethodId: string, private readonly contractId: ContractId) {
        super()
    }

    public override requestArgument() {
        return { paymentMethodId: this.paymentMethodId, contractId: this.contractId }
    }

    public override requestUrl() {
        return 'contract/updatePaymentMethod'
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 创建合约（国内）
@POST
export class CreateNationalContract extends BaseCommonRequest<ContractCreateResponseVO<NationalPayInfoVO>> {
    constructor(private readonly body: ContractCreateNationalRequestVO) {
        super()
    }

    public override requestBody() {
        return this.body
    }

    public override requestUrl() {
        return 'contract/create'
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 取消合约
@DELETE
export class CancelContract extends BaseCommonRequest<void> {
    constructor(private readonly contractId: ContractId) {
        super()
    }

    public override requestArgument() {
        return { contractId: this.contractId }
    }

    public override requestUrl() {
        return 'contract/cancel'
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 查询合约计费信息
@GET
export class GetContractSettlement extends BaseCommonRequest<ContractSettlementsVO> {
    constructor(private readonly resourceId: string, private readonly resourceType: ResourceType) {
        super()
    }

    public override requestArgument() {
        return { resourceId: this.resourceId, resourceType: this.resourceType }
    }

    public override requestUrl() {
        return 'contract/allSettlements'
    }

    public override requestDidFailed(): boolean {
        return false
    }
}
@GET
export class GetContractSettlementV2 extends BaseCommonRequest<ContractSettlementsVO> {
    constructor(private readonly resourceId: string, private readonly resourceType: ResourceType) {
        super()
    }

    public override requestArgument() {
        return { resourceId: this.resourceId, resourceType: this.resourceType }
    }

    public override requestUrl() {
        return 'contract/allSettlementsV2'
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 查询所有产品价格
@GET
export class GetContractAllProducts extends BaseCommonRequest<ProductVO[]> {
    public override requestUrl() {
        return 'contract/products'
    }
}

// 查询一个未支付的合约，如有则返回合约信息，否则返回错误
@GET
export class QueryExistsUnpaidContract extends BaseCommonRequest<ContractVO | undefined> {
    public override requestUrl() {
        return 'contract/existsUnpaidContract'
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 二次支付合约（国内）
@GET
export class RepayNationalContract extends BaseCommonRequest<ContractCreateResponseVO<NationalPayInfoVO>> {
    constructor(private readonly contractId: ContractId) {
        super()
    }

    public override requestArgument() {
        return { contractId: this.contractId }
    }

    public override requestUrl() {
        return 'contract/repay'
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 支付已结算合约（国内）
@GET
export class PaySettledNationalContract extends BaseCommonRequest<ContractCreateResponseVO<NationalPayInfoVO>> {
    constructor(private readonly settlementId: SettlementId) {
        super()
    }

    public override requestArgument() {
        return { settlementId: this.settlementId }
    }

    public override requestUrl() {
        return 'contract/paySettlement'
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 查询合约结算单收费明细
export class GetContractSettlementInvoice extends BaseCommonRequest<ContractSettlementDetailVO> {
    constructor(private readonly settlementId: SettlementId) {
        super()
    }

    public override requestArgument() {
        return { settlementId: this.settlementId }
    }

    public override requestUrl() {
        return 'contract/settlement/invoice'
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 查询合约未结算单收费明细
@GET
export class GetUpcomingInvoice extends BaseCommonRequest<ContractSettlementDetailVO> {
    constructor(private readonly contractId: ContractId) {
        super()
    }

    public override requestArgument() {
        return { contractId: this.contractId }
    }

    public override requestUrl() {
        return 'contract/settlement/upcoming-invoice'
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 查询所有过期未支付的结算单
@POST
export class GetAllPayExpiredSettlements extends BaseCommonRequest<{ [resourceId: string]: SettlementVO[] }> {
    constructor(
        private readonly resourceType: ResourceType.Organization | ResourceType.Team,
        private readonly resourceIds: string[]
    ) {
        super()
    }

    public override requestArgument() {
        return { resourceType: this.resourceType }
    }

    public override requestBody() {
        return this.resourceIds
    }

    public override requestUrl() {
        return 'contract/queryAllPayExpiredSettlements'
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 查询合约是否已经预约终止
@GET
export class GetContractHasTerminationAppointment extends BaseCommonRequest<ContractTerminationAppointmentResultVO> {
    constructor(
        private readonly resourceType: ResourceType.Organization | ResourceType.Team,
        private readonly resourceId: OrgID | TeamID
    ) {
        super()
    }

    public override requestArgument() {
        return { resourceType: this.resourceType, resourceId: this.resourceId }
    }

    public override requestUrl() {
        return 'contract/terminationAppoint'
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 预约终止合约
@DELETE
export class TerminationAppoint extends BaseCommonRequest<void> {
    constructor(
        private readonly resourceType: ResourceType.Organization | ResourceType.Team,
        private readonly resourceId: OrgID | TeamID,
        private readonly cancelReason: { label: string; desc?: string }[]
    ) {
        super()
    }

    public override requestArgument() {
        return { resourceType: this.resourceType, resourceId: this.resourceId }
    }

    public override requestBody() {
        return { cancelReason: this.cancelReason }
    }

    public override requestUrl() {
        return 'contract/terminationAppoint'
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 取消终止合约预约
@DELETE
export class CancelTerminationAppoint extends BaseCommonRequest<void> {
    constructor(
        private readonly resourceType: ResourceType.Organization | ResourceType.Team,
        private readonly resourceId: OrgID | TeamID
    ) {
        super()
    }

    public override requestArgument() {
        return { resourceType: this.resourceType, resourceId: this.resourceId }
    }

    public override requestUrl() {
        return 'contract/cancelTerminationAppoint'
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

@GET
export class QueryAllContracts extends BaseCommonRequest<ContractVO[]> {
    constructor(private readonly resourceType: ResourceType, private readonly resourceId: OrgID | TeamID) {
        super()
    }

    public override requestArgument() {
        return { resourceId: this.resourceId, resourceType: this.resourceType }
    }

    public override requestUrl() {
        return 'contract/list'
    }

    public override requestDidFailed(): boolean {
        return false
    }
}
