import classnames from 'classnames'
import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { MonoIconControlDelete12 } from '../../icons-v2'
import { TabElement } from '../tab-element'
import classes from './tag.module.less'

export interface TagProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
    name: string | number // 标签名
    size?: 'mini' | 'small' | 'medium' | 'large' // 18px|20px|24px|28px
    structure?: 'line' | 'surface' | 'line-surface' // line 边框+透明背景；surface 背景；'line-surface 边框+背景
    color?: 'black' | 'blue' | 'green' | 'yellow' | 'red' | 'gray' // tag的主题风格是什么颜色
    colorChecked?: TagProps['color'] // 在选中情况下使用的tag风格。默认与color字段统一
    checked?: boolean // 选中明显且醒目的样式风格
    closable?: boolean // true 存在关闭图标； false 不存在按钮的同时也表示着这是个纯标签。不响应键盘事件
    disabled?: boolean // 规范并没有定义tag的禁用状态，这里仅仅在closable=true时禁用 键盘tab
    hoverable?: boolean // 是否展示悬浮状态(注意: 没有定义组件规范, 目前只临时支持color = blue|black的情况)
    dataTestIds?: { root?: string; closeIcon?: string; closeTabElement?: string }
    deleteLine?: boolean // 是否展示删除线(注意: 没有定义组件规范）
    prevChild?: React.ReactNode // 前置节点
    onDelete?: (deleteType: 'mouse' | 'keyboard') => void
}
export interface TagRef {
    focus: () => void // 作为纯标签的tag调用focus并不能聚焦
    getRootElement: () => HTMLDivElement
}

function _Tag(props: TagProps, ref?: React.Ref<TagRef>) {
    const {
        name,
        size = 'medium',
        structure = 'surface',
        color = 'black',
        colorChecked = color,
        checked,
        hoverable = false,
        closable,
        disabled,
        dataTestIds,
        className,
        deleteLine,
        prevChild,
        onDelete,
        onFocus,
        onBlur,
        onKeyDown,
        ...otherProps
    } = props
    const [rootFocus, setRootFocus] = useState<boolean>(false)
    const [tabFocus, setTabFocus] = useState<boolean>(false)
    const rootRef = useRef<HTMLDivElement>(null)

    const isCheckedStyle = useMemo(() => {
        return tabFocus ? false : checked ?? rootFocus
    }, [checked, rootFocus, tabFocus])

    const classList = useMemo(() => {
        const classKeys = isCheckedStyle ? [size, `${colorChecked}-checked`] : [size, ...structure.split('-'), color]
        if (hoverable) {
            classKeys.push('hoverable')
        }
        if (deleteLine) {
            classKeys.push('deleteLine')
        }
        if (disabled) {
            classKeys.push('disabled') // 目前只指定过 black 的 disabled 样式
        }
        return classKeys.map((v) => classes[v as keyof typeof classes])
    }, [isCheckedStyle, size, colorChecked, structure, color, hoverable, deleteLine, disabled])

    const onKeyDownRoot = useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.code === 'Delete' || e.code === 'Backspace') {
                e.target === e.currentTarget && onDelete?.('keyboard')
            }
            onKeyDown?.(e)
        },
        [onDelete, onKeyDown]
    )

    const onFocusRoot = useCallback(
        (e: React.FocusEvent<HTMLDivElement>) => {
            setRootFocus(true)
            onFocus?.(e)
        },
        [onFocus]
    )

    const onBlurRoot = useCallback(
        (e: React.FocusEvent<HTMLDivElement>) => {
            setRootFocus(false)
            onBlur?.(e)
        },
        [onBlur]
    )

    const onKeyDownClose = useCallback(
        (e: React.KeyboardEvent) => {
            if (e.code === 'Enter' || e.code === 'Space') {
                onDelete?.('keyboard')
            }
        },
        [onDelete]
    )

    const onClickClose = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation()
            onDelete?.('mouse')
        },
        [onDelete]
    )

    useImperativeHandle(
        ref,
        () => ({ focus: () => rootRef.current?.focus(), getRootElement: () => rootRef.current! }),
        []
    )

    return (
        <div
            ref={rootRef}
            className={classnames(classes.tag, [className], classList)}
            data-testid={dataTestIds?.root}
            tabIndex={closable ? -1 : undefined}
            onFocus={onFocusRoot}
            onBlur={onBlurRoot}
            onKeyDown={onKeyDownRoot}
            {...otherProps}
        >
            {prevChild}
            <span className={classes.name}>{name}</span>
            {closable ? (
                <div className={classes.close} data-testid={dataTestIds?.closeIcon} onClick={onClickClose}>
                    <TabElement
                        onKeyDown={onKeyDownClose}
                        onFocus={() => setTabFocus(true)}
                        onBlur={() => setTabFocus(false)}
                        data-testid={dataTestIds?.closeTabElement}
                        disabled={disabled}
                    />
                    <MonoIconControlDelete12 />
                </div>
            ) : null}
        </div>
    )
}

export const Tag = forwardRef(_Tag)
