import { translation } from './font-miss-icon.translation'
/* eslint-disable no-restricted-imports */
import React from 'react'
import { IconFontMiss, Tooltip, TooltipProps } from '../../../../../../../../ui-lib/src'
import styles from './font-miss-icon.module.less'
function _FontMissIcon(
    props: { className?: string; triggerRect?: TooltipProps['triggerRect']; testId?: string },
    ref?: React.Ref<HTMLDivElement>
) {
    return (
        <Tooltip title={translation('MissingFonts')} triggerRect={props.triggerRect}>
            <div
                ref={ref}
                style={{ display: 'flex', height: '100%', alignItems: 'center' }}
                className={`${props.className}  ${styles['font-miss']}`}
                data-testid={props.testId ?? 'font-miss-icon'}
            >
                <IconFontMiss />
            </div>
        </Tooltip>
    )
}
export const FontMissIcon = React.forwardRef(_FontMissIcon)
