import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    RestoreDefaultThumbnail: '恢复默认封面',
    DefaultThumbnailRestored: '已恢复默认封面',
    SetAsThumbnail: '设为封面',
    ThumbnailSet: '已设置为封面',
} as const

export const enTranslation = {
    RestoreDefaultThumbnail: 'Restore default thumbnail',
    DefaultThumbnailRestored: 'Default thumbnail restored',
    SetAsThumbnail: 'Set as thumbnail',
    ThumbnailSet: 'Thumbnail set',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
