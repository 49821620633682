import { Wukong } from '@wukong/bridge-proto'
import { Checkbox } from '../../../../../../../../ui-lib/src'
import { PrototypeTestId } from '../../../../../../window'
import classes from '../interaction-action-animation.module.less'
import {
    useAnimationShouldSmartAnimateCommand,
    type AnimationTransitionTypeCommandProps,
} from '../use-action-animation-commands'
import { translation } from './transition-should-smart-animate.translation'

export function TransitionShouldSmartAnimateCheckbox(props: {
    state: Wukong.DocumentProto.ITransitionShouldSmartAnimate
    commandProps: AnimationTransitionTypeCommandProps
}) {
    const command = useAnimationShouldSmartAnimateCommand(props.commandProps)

    return props.state.show ? (
        <div className={classes.animationContainer}>
            <Checkbox
                checked={props.state.value}
                indeterminate={
                    props.state.valueType ===
                    Wukong.DocumentProto.InteractionActionPropValueType.INTERACTION_ACTION_PROP_VALUE_TYPE_MIXED
                }
                label={translation('AnimateMatchingLayers')}
                onChange={command.onChange}
                testid={PrototypeTestId.InteractionPopup.ShouldSmartAnimateCheckbox}
            />
        </div>
    ) : (
        <></>
    )
}
