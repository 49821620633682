import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    ZoomIn: '放大',
    ZoomOut: '缩小',
    ZoomTo: '缩放至',
    ZoomToFit: '缩放至显示全部',
    SnapToPixels: '对齐像素网格',
    PixelGrid: '像素网格',
    Rulers: '标尺',
    LayoutGrids: '布局网格',
    Comments: '显示评论',
    MultiplayerCursors: '显示多人协作光标',
    ShowUI: '显示操作界面',
    ShowLeftSidebar: '显示左边栏',
} as const

export const enTranslation = {
    ZoomIn: 'Zoom in',
    ZoomOut: 'Zoom out',
    ZoomTo: 'Zoom to',
    ZoomToFit: 'Zoom to fit',
    SnapToPixels: 'Snap to pixels grid',
    PixelGrid: ' Pixel grid',
    Rulers: 'Rulers',
    LayoutGrids: 'Layout grids',
    Comments: 'Comments',
    MultiplayerCursors: 'Multiplayer cursors',
    ShowUI: 'Show UI',
    ShowLeftSidebar: 'Show left sidebar',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
