import { POST } from '@tutor/network-core'
import { DocID, OnlineUserVO } from '../interface/type'
import { BaseCommonRequest } from './base-request'

// 根据文档id查询在线协作者信息
@POST
export class GetDocOnlineUsers extends BaseCommonRequest<Record<DocID, OnlineUserVO[]>> {
    constructor(private readonly docIds: DocID[]) {
        super()
    }

    public override requestUrl() {
        return 'doc-users/online'
    }

    public override requestDidFailed(): boolean {
        return false
    }

    public override requestBody() {
        return {
            docIds: this.docIds,
        }
    }
}
