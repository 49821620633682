/* eslint-disable no-restricted-imports */
import { Writer, Wukong } from '@wukong/bridge-proto'
import { sleep } from '../../../../../util/src'
import { CommandInvoker } from '../../../document/command/command-invoker'
import { WkCLog } from '../../../kernel/clog/wukong/instance'
import { featureSwitchManager } from '../../../kernel/switch/core'
import { ClipboardDataFile, ClipboardDataItem, ClipboardDataProxyFile } from './clipboard-data-types'
import { parseFigmaClipboardSceneDataToExportedDocument } from './clipboard-figma'

export const PLAIN_TEXT_SLUG = 'text/plain'
export const RICH_TEXT_SLUG = 'text/html'
export const URL_LINK_SLUG = 'text/uri-list'
interface Proto<T> {
    encodeDelimited: (message: T, writer?: Writer) => Writer | undefined
    encode: (message: T, writer?: Writer) => Writer | undefined
}

function _arrayBufferToBase64(bytes: Uint8Array) {
    let binary = ''
    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
}

function protoToBase64<T>(proto: Proto<T>, message: T) {
    const writer = proto.encode(message)
    if (!writer) {
        throw new Error('encodeDelimited fail')
    }
    const data = writer.finish()
    return _arrayBufferToBase64(data)
}

export function isImage(type: string) {
    return (type.startsWith('image') && !type.includes('svg')) || type === 'web image/png'
}

export function encodeCanvasClipboardData(
    data: Wukong.DocumentProto.ISerializedExportedDocument,
    meta: Wukong.DocumentProto.IClipboardMeta
) {
    const encodedData = protoToBase64(Wukong.DocumentProto.SerializedExportedDocument, data)
    const encodedMeta = protoToBase64(Wukong.DocumentProto.ClipboardMeta, meta)

    const WK_CLIPBOARD_META_SPAN_STRING_PREFIX = `<span data-meta="<!--__Wukong-Clipboard__`
    const WK_CLIPBOARD_META_SPAN_STRING_SUFFIX = `__Meta-End__-->"></span>`
    const WK_CLIPBOARD_DATA_SPAN_STRING_PREFIX = `<span data-content="<!--`
    const WK_CLIPBOARD_DATA_SPAN_STRING_SUFFIX = `__Clipboard-End__-->"></span>`

    let res = ''

    res += "<meta charset='utf-8'>"
    res += WK_CLIPBOARD_META_SPAN_STRING_PREFIX
    res += encodedMeta
    res += WK_CLIPBOARD_META_SPAN_STRING_SUFFIX
    res += WK_CLIPBOARD_DATA_SPAN_STRING_PREFIX
    res += encodedData
    res += WK_CLIPBOARD_DATA_SPAN_STRING_SUFFIX

    return res
}

export class WkDataTransfer {
    public files: ClipboardDataProxyFile[] = []
    public imageInfos: Array<{ blobSize: number; blobId: number; imageInfo: Wukong.DocumentProto.ImageInfo }> = []
    public itemRecord: Record<string, string | undefined> = {}
    private blobMap: Map<number, { blob: Blob; name: string }> = new Map()
    private generatedBlobId = 0

    constructor(private commandInvoker: CommandInvoker) {}

    public getData(format: string) {
        return this.itemRecord[format] ?? ''
    }

    public setData(format: string, data: string) {
        this.itemRecord[format] = data
    }

    public clearData() {
        this.itemRecord = {}
    }

    public getItems(): ClipboardDataItem[] {
        const items: ClipboardDataItem[] = []
        for (const key in this.itemRecord) {
            const data = this.itemRecord[key]
            if (data) {
                items.push({
                    type: key,
                    content: data,
                })
            }
        }
        return items
    }

    public rebuildItems(items: ClipboardDataItem[]) {
        for (const item of items) {
            this.setData(item.type, item.content)
        }
    }

    public async rebuildProxyFiles(files: ClipboardDataProxyFile[]) {
        this.files = files
    }
    public async rebuildFiles(files: ClipboardDataFile[]) {
        const proxyFiles = await Promise.all(
            files.map(async (file) => this.buildWkDataTransformFile(new Blob([file.buffer]), file.name))
        )
        this.files = proxyFiles
    }

    public testIsFigmaSceneCopy(content: string) {
        // 只给开关内的人使用
        if (!featureSwitchManager.isEnabled('paste-from-figma')) {
            return false
        }

        const result = /\(figmeta\)(.*)\(\/figmeta\)/gm.exec(content)
        if (!result?.[1]) {
            return false
        }
        try {
            const figmaMeta = JSON.parse(Buffer.from(result[1], 'base64').toString('utf-8'))
            return figmaMeta.dataType === 'scene'
        } catch {
            return false
        }
    }

    public async parseFigmaClipboardSceneData(content: string): Promise<string> {
        const exportedDocument = await parseFigmaClipboardSceneDataToExportedDocument(
            this.commandInvoker,
            content,
            true
        )
        if (!exportedDocument) {
            return ''
        }

        return encodeCanvasClipboardData(exportedDocument, {
            type: Wukong.DocumentProto.ClipboardNodeTreeContentType.CLIPBOARD_NODE_TREE_CONTENT_TYPE_NODES,
        })
    }

    public async rebuildItemsFromDataTransfer(dataTransfer: DataTransfer) {
        this.files = []
        this.imageInfos = []
        this.clearData()

        const toAwaitPromises: Promise<any>[] = []
        for (const type of dataTransfer.types) {
            const content = dataTransfer.getData(type)
            if (this.testIsFigmaSceneCopy(content)) {
                // 读 DataTransfer 不能 await
                const promise = this.parseFigmaClipboardSceneData(content).then((parsed) => {
                    this.setData(type, parsed)
                })
                toAwaitPromises.push(promise)
            } else {
                toAwaitPromises.push(
                    // 等一会儿是为了让toast先弹出来
                    sleep(5).then(() => {
                        this.setData(type, content)
                    })
                )
            }
        }

        await Promise.all(toAwaitPromises)
    }

    public async rebuildItemsAndFiles(dataTransfer: DataTransfer) {
        this.files = []
        const files = [...dataTransfer.files]
        await this.rebuildItemsFromDataTransfer(dataTransfer)
        await Promise.all(
            files.map(async (file) => {
                const res = this.buildWkDataTransformFile(file, file.name)
                this.files.push(res)
            })
        )
    }

    public async rebuildItemsAndFilesFromClipboardItems(clipboardItems: ClipboardItems) {
        this.files = []
        this.imageInfos = []
        this.clearData()

        const toLog: Array<{
            index: number
            type: string
            size: number
        }> = []

        await Promise.all(
            clipboardItems.map((item, itemIndex) =>
                Promise.all(
                    item.types.map(async (type) => {
                        const blob = await item.getType(type)

                        toLog.push({
                            index: itemIndex,
                            type,
                            size: blob.size,
                        })

                        if (isImage(type)) {
                            this.files.push(this.buildWkDataTransformFile(blob, 'image'))
                        } else if (type.startsWith('text/')) {
                            const content = await blob.text()
                            if (this.testIsFigmaSceneCopy(content)) {
                                await this.parseFigmaClipboardSceneData(content).then((parsed) => {
                                    this.setData(type, parsed)
                                })
                            } else {
                                this.setData(type, content)
                            }
                        } else {
                            this.files.push(this.buildWkDataTransformFile(blob, 'unknown'))
                        }
                    })
                )
            )
        )

        WkCLog.throttleLog('WK_CLIPBOARD', {
            scope: 'digest-clipboard-items',
            items: JSON.stringify(toLog),
        })
    }

    public async getFileByBlobId(blobId: number): Promise<ClipboardDataFile | null> {
        const item = this.blobMap.get(blobId)
        if (!item) {
            return null
        }
        const { blob, name } = item
        const buffer = await blob.arrayBuffer()

        return {
            type: blob.type,
            name: name,
            size: blob.size,
            buffer: new Uint8Array(buffer),
        }
    }

    public getBlobByBlobId(blobId: number): Blob | null {
        const item = this.blobMap.get(blobId)
        if (!item) {
            return null
        }
        const { blob } = item
        return blob
    }

    public trace(caller: string) {
        const itemRecordKeys = Object.keys(this.itemRecord)
        const files = this.files.map((file) => {
            return {
                type: file.type,
                size: file.size,
            }
        })
        const imageInfos = this.imageInfos.map(({ blobSize, blobId, imageInfo }) => {
            return {
                blobSize,
                blobId,
                width: imageInfo.imageWidth,
                height: imageInfo.imageHeight,
            }
        })

        WkCLog.throttleLog('WK_CLIPBOARD', {
            scope: 'trace-data-transfer',
            caller,
            itemRecordKeys: JSON.stringify(itemRecordKeys),
            files: JSON.stringify(files),
            imageInfos: JSON.stringify(imageInfos),
        })
    }

    private buildWkDataTransformFile(blob: Blob, name: string): ClipboardDataProxyFile {
        const res: ClipboardDataProxyFile = {
            type: blob.type,
            name,
            size: blob.size,
            blobId: this.getBlobId(blob, name),
        }
        return res
    }

    private getBlobId(blob: Blob, name: string): number {
        const id = this.generatedBlobId + 1
        this.generatedBlobId += 1
        this.blobMap.set(id, { blob, name })
        return id
    }
}
