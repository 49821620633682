import classnames from 'classnames'
import { useCallback, useRef, type ReactNode } from 'react'
import { useMount, useUnmount } from 'react-use'
import { IconClose16 } from '../../svg-icon/16/panel'
import classes from './toast-struct.module.less'

export interface CloseButton {
    type: 'x'
    dataTestId?: string
    onClick?: () => void
}
export interface InteractivityButton {
    type: 'button'
    text: string // button 按钮内的文字
    dataTestId?: string
    onClick: () => void
}

export interface ToastStructProps {
    type: 'normal' | 'error' // 常规或者红色
    icon?: ReactNode // message 的前置修饰图标
    message: string | ReactNode
    duration?: -1 | 5000 // toast存活时间， -1表示不会自动销毁
    firstButton?: CloseButton | InteractivityButton
    secondButton?: InteractivityButton
    showWithAnimation?: boolean
    dataTestIds?: { toast?: string }
    onClose?: () => void // toast关闭的回调
    onClick?: () => void // 功能按钮有自己的点击事件。这个点击会在message及其前置装饰按钮icon上触发
    closeToast: () => void // 关闭toast的方法
}

export function ToastStruct(props: ToastStructProps) {
    const toastRef = useRef<HTMLDivElement>(null)
    useMount(() => {
        if (props.duration === -1) {
            return
        }
        const duration = props.duration ?? 5000
        const exitDuration = 280 // 设计定义的值
        setTimeout(() => {
            toastRef.current?.classList.add(classes.fadeOut)
            setTimeout(() => props.closeToast(), exitDuration)
        }, duration - exitDuration)
    })

    useMount(() => {
        const toast = toastRef.current
        const minWidth = 360 // 设计指定值
        // 因为这个最小宽度的值不太好估算，所以只在最开始确定一次。之后的缩放浏览器就不再计算了。
        // 也就是说如果一开始就把浏览器缩放的比较小，那么即使内容在足够大时超出360，也不会给其设置最小宽度
        if (toast && toast.getBoundingClientRect().width > minWidth) {
            toast.style.minWidth = `${minWidth}px`
        }
    })

    useUnmount(() => props.onClose?.())

    return (
        <div
            ref={toastRef}
            className={classnames(classes.toast, {
                [classes.normal]: props.type === 'normal',
                [classes.error]: props.type === 'error',
                [classes.fadeIn]: props.showWithAnimation,
                [classes.existIcon]: props.icon,
            })}
            data-testid={props.dataTestIds?.toast}
            data-role="toast"
        >
            {props.icon ? (
                <span className={classes.icon} onClick={props.onClick}>
                    {props.icon}
                </span>
            ) : null}
            <div className={classes.message} onClick={props.onClick}>
                {props.message}
            </div>
            {RenderButton(props.secondButton, props.closeToast)}
            {RenderButton(props.firstButton, props.closeToast)}
        </div>
    )
}

function RenderButton(buttonProps: CloseButton | InteractivityButton | undefined, closeToast: () => void) {
    const onClick = useCallback(() => {
        buttonProps?.onClick?.()
        closeToast?.()
    }, [buttonProps, closeToast])

    if (!buttonProps) {
        return null
    }
    const isCloseButton = buttonProps.type === 'x'
    return (
        <div
            className={classnames(classes.button, { [classes.isCloseButton]: isCloseButton })}
            onClick={onClick}
            data-testid={buttonProps.dataTestId}
        >
            {isCloseButton ? <IconClose16 /> : buttonProps.text}
        </div>
    )
}
