/* eslint-disable no-restricted-imports */
import { useCallback, useState } from 'react'
import { ConstraintDirection, RectAreaDom } from './rect-area-dom'
import { Matrix } from '../../../../../document/util/matrix'

export interface ChangeCallBackParams {
    value: ConstraintDirection
    shiftKey: boolean
    metaKey: boolean
}
export interface RectAreaProps {
    disabled?: boolean
    selectDirection?: ConstraintDirection[]
    onChange?: (data: ChangeCallBackParams) => void
}

export function RectArea(props: RectAreaProps) {
    const { disabled, selectDirection = [], onChange } = props
    const [hoverConstraintDirection, setHoverConstraintDirection] = useState<ConstraintDirection>()

    const isHover = useCallback(
        (direction: ConstraintDirection) => {
            return hoverConstraintDirection === direction
        },
        [hoverConstraintDirection]
    )

    const isSelect = useCallback(
        (direction: ConstraintDirection) => {
            return selectDirection.includes(direction)
        },
        [selectDirection]
    )

    const onMouseEnter = useCallback((direction: ConstraintDirection) => {
        setHoverConstraintDirection(direction)
    }, [])

    const onMouseLeave = useCallback(() => {
        setHoverConstraintDirection(undefined)
    }, [])

    const onMouseMoveCenter = useCallback(
        (e: any) => {
            const rect: DOMRect = e.target.getBoundingClientRect()
            const { clientX, clientY, shiftKey, metaKey } = e
            if (!shiftKey && !metaKey) {
                const onlySelectHCenter =
                    selectDirection.includes(ConstraintDirection.HCenter) &&
                    !selectDirection.includes(ConstraintDirection.VCenter)
                if (onlySelectHCenter) {
                    setHoverConstraintDirection(ConstraintDirection.VCenter)
                    return
                }
                const onlySelectVCenter =
                    selectDirection.includes(ConstraintDirection.VCenter) &&
                    !selectDirection.includes(ConstraintDirection.HCenter)
                if (onlySelectVCenter) {
                    setHoverConstraintDirection(ConstraintDirection.HCenter)
                    return
                }
            }
            const site = assetsPointSite(rect, clientX, clientY)
            if (site === 1 || site === 3) {
                setHoverConstraintDirection(ConstraintDirection.VCenter)
            } else {
                setHoverConstraintDirection(ConstraintDirection.HCenter)
            }
        },
        [selectDirection]
    )

    const onClick = useCallback(
        (e: any) => {
            if (hoverConstraintDirection === undefined) {
                return
            }
            onChange?.({
                value: hoverConstraintDirection,
                shiftKey: e.shiftKey,
                metaKey: e.metaKey,
            })
        },
        [hoverConstraintDirection, onChange]
    )

    return (
        <RectAreaDom
            disabled={disabled}
            isHover={isHover}
            isSelect={isSelect}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseMoveCenter={onMouseMoveCenter}
            onClick={onClick}
        />
    )
}

/*
    -1-
    2-4
    -3-
*/
export function assetsPointSite(rect: DOMRect, x: number, y: number): 1 | 2 | 3 | 4 {
    const { top, left, bottom, right } = rect
    const centerX = (left + right) / 2
    const centerY = (top + bottom) / 2
    const relativeX = x - centerX
    const relativeY = y - centerY

    if (relativeX <= 3 && relativeX >= -3 && relativeY <= 3 && relativeY >= -3) {
        return relativeY > 0 ? 4 : 2
    }

    const sin45 = Math.sin((45 / 180) * Math.PI)
    const cos45 = Math.cos((45 / 180) * Math.PI)
    const rotate45 = new Matrix({
        scaleX: cos45,
        scaleY: cos45,
        translateX: 0,
        translateY: 0,
        skewX: -sin45,
        skewY: sin45,
    })
    const point5 = rotate45.mapVector({ x: relativeX, y: relativeY })

    // 视情况 把坐标轴的点归到象限内
    if (point5.y <= 0) {
        if (point5.x <= 0) {
            return 2
        } else {
            return 1
        }
    } else {
        if (point5.x <= 0) {
            return 3
        } else {
            return 4
        }
    }
}
