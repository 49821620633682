import { environment } from '../../environment'
import { CloudAuthClientParams } from '../interface/cloud-auth-client-params'
import type { AWSClient } from './aws'
import type { OssClient } from './oss'

/**
 * 从云存储获取鉴权后的文件地址
 * 支持从 OSS | AWS 获取，由 isAbroad 判断进行分流
 */
export class CloudAuthClient {
    private client: OssClient | AWSClient | undefined

    constructor(private params: CloudAuthClientParams) {}

    private async init() {
        if (this.client) {
            return this.client
        }
        if (environment.isAbroad) {
            const { AWSClient } = await import('./aws')
            this.client = new AWSClient(this.params)
        } else {
            const { OssClient } = await import('./oss')
            this.client = new OssClient(this.params)
        }
        return this.client
    }

    public fetchFileByPath = async (path: string): Promise<Response> => {
        const client = await this.init()
        return client.fetchFileByPath(path)
    }

    public fetchUrlByPath = async (path: string): Promise<string> => {
        const client = await this.init()
        return client.fetchUrlByPath(path)
    }
}
