import { ClogDomain, Environment, HttpPrefixKey } from '../types'

export const formalAbroadTestingEnvironment: Environment = {
    isAbroad: true,
    isDev: false,
    isProduction: false,
    googleClientId: '266725513237-8bunbtkvmkqrark30rrqgb3ucsdb2pbk.apps.googleusercontent.com',
    host: 'https://wukong-abroad-test.zhenguanyu.com',
    wssPrefix: 'wss://wukong-abroad-synergy-test.zhenguanyu.com/synergy',
    ossCdnPath: 'https://static-dev.motiffcontent.com/',
    publicResourcePrefix: 'app/public/resource',
    sentryDsn: 'https://daa074fcf66040c8bbe5c3908036a9ee@o1401900.ingest.sentry.io/6733363',
    clogConfig: {
        site: 'yuanfudao',
        domain: ClogDomain.Biz,
        queryUrl: 'https://octopus.zhenguanyu.com/#/logs/search?env=test',
    },
    featureSwitchEnv: 'testing',
    httpPrefixes: {
        [HttpPrefixKey.COMMON_API]: 'https://wukong-abroad-test.zhenguanyu.com/wukong-api/api',
        [HttpPrefixKey.NOTIFY_API]: 'https://wukong-abroad-test.zhenguanyu.com/wukong-notify/api',
        [HttpPrefixKey.SYNERGY_CONNECTOR_API]: 'https://wukong-abroad-test.zhenguanyu.com/motiff-synergy-connector',
        [HttpPrefixKey.SYNERGY_ADMIN_API]: 'https://wukong-abroad-test.zhenguanyu.com/motiff-coordinator/admin',
        [HttpPrefixKey.ADMIN_API]: 'https://wukong-abroad-test.zhenguanyu.com/wukong-api-admin/api/',
        [HttpPrefixKey.INNER_API]: 'https://wukong-abroad-test.zhenguanyu.com/wukong-api/api',
        [HttpPrefixKey.BOLT_API]: 'https://bolt.yuanfudao.biz/bolt-pay-gateway/api',
        [HttpPrefixKey.AI_GEN_API]: 'https://motiff-ai-gen.yuanfudao.biz/api',
    },
    availableHostsForEmailLoginApi: [],
    uiPrefixCls: 'wukong-ui',
    figmaClientId: 'eHkRdGNNtr6k6b8m1s644M',
    figmaCallbackUrl: 'https://wukong-abroad-test.zhenguanyu.com/figma-oauth',
    mirrorBasePath: 'mirror',
    notifyWssPrefix: 'wss://wukong-abroad-notify-test.zhenguanyu.com/notify-connector',
    mirrorWssPrefix: 'wss://wukong-abroad-test.zhenguanyu.com/motiff-preview-connector/preview',
    fontPluginDaemonHost: 'https://daemon.motiff.com',
    fontPluginLocalHost: 'http://127.0.0.1',
    onboardingHelpDocUrl: {
        frame: 'https://motiff.com/help/docs/articles/38036382480896',
        vector: 'https://motiff.com/help/docs/articles/37711961443072',
        comment: 'https://motiff.com/help/docs/sections/37712087272192',
        share: 'https://motiff.com/help/docs/sections/37711902722816',
        coator: 'https://motiff.com/help/docs/sections/49569364184328',
        library: 'https://motiff.com/help/docs/articles/37712074689280',
        prototype: 'https://motiff.com/help/docs/sections/232648479013384',
        device: 'https://motiff.com/help/docs/sections/232662316033280',
        devModeStart: 'https://motiff.com/help/docs/sections/232648479013382#4-code%20settings',
        devmodeInspect: 'https://motiff.com/help/docs/sections/232648479013382#3-advanced%20inspect',
        devmodeExport: 'https://motiff.com/help/docs/sections/232648479013382#7-export',
        variable: 'https://wukong-abroad-test.zhenguanyu.com/help/docs/articles/305390387245587',
    },
    helpDocsUrl: {
        home: 'https://wukong-abroad-test.zhenguanyu.com/help/docs',
        color: 'https://motiff.com/help/docs/sections/64851546582028',
        image: 'https://motiff.com/help/docs/articles/37711974025984',
        component: 'https://motiff.com/help/docs/articles/37712032746240',
        webgl: 'https://motiff.com/help/others/49991877417216',
        aiCredit: 'https://motiff.com/help/docs/sections/232928398489604',
        quota: 'https://motiff.com/help/others/266701503819272',
        ssoPath: '/help/docs/sections/267353940428032',
        stateManagement: '',
        motiffAPI: '',
        variable: 'https://wukong-abroad-test.zhenguanyu.com/help/docs/articles/305390387245589',
        organizationGuest: 'https://motiff.com/help/docs/sections/101470896999680',
    },
}
