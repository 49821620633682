import { FC, useMemo } from 'react'
import {
    IconLogo1024,
    IconLogo16,
    IconLogo24,
    IconLogo32,
    IconLogo512,
    IconLogo60,
    IconLogo80,
    IconLogoWhite1024,
    IconLogoWhite16,
    IconLogoWhite24,
    IconLogoWhite32,
    IconLogoWhite512,
    IconLogoWhite60,
    IconLogoWhite80,
} from './svg'

// 方形 不带文字
export interface WKIconLogoProps {
    height: 1024 | 512 | 80 | 60 | 32 | 24 | 16
    type: 'normal' | 'white'
    className?: string
    rounded: boolean // 是否圆角
}

export const WKIconLogo: FC<WKIconLogoProps> = ({ height, rounded, type, className }) => {
    const borderRadius = useMemo(() => {
        if (!rounded) {
            return 0
        }
        switch (height) {
            case 1024:
                return 102
            case 512:
                return 51
            case 80:
                return 8
            case 60:
                return 6
            case 32:
                return 3
            case 24:
            case 16:
                return 2
        }
    }, [height, rounded])
    switch (height) {
        case 1024:
            if (type == 'white') {
                return <IconLogoWhite1024 className={className} style={{ borderRadius }} />
            } else {
                return <IconLogo1024 className={className} style={{ borderRadius }} />
            }
        case 512:
            if (type == 'white') {
                return <IconLogoWhite512 className={className} style={{ borderRadius }} />
            } else {
                return <IconLogo512 className={className} style={{ borderRadius }} />
            }
        case 80:
            if (type == 'white') {
                return <IconLogoWhite80 className={className} style={{ borderRadius }} />
            } else {
                return <IconLogo80 className={className} style={{ borderRadius }} />
            }
        case 60:
            if (type == 'white') {
                return <IconLogoWhite60 className={className} style={{ borderRadius }} />
            } else {
                return <IconLogo60 className={className} style={{ borderRadius }} />
            }
        case 32:
            if (type == 'white') {
                return <IconLogoWhite32 className={className} style={{ borderRadius }} />
            } else {
                return <IconLogo32 className={className} style={{ borderRadius }} />
            }
        case 24:
            if (type == 'white') {
                return <IconLogoWhite24 className={className} style={{ borderRadius }} />
            } else {
                return <IconLogo24 className={className} style={{ borderRadius }} />
            }
        case 16:
            if (type == 'white') {
                return <IconLogoWhite16 className={className} style={{ borderRadius }} />
            } else {
                return <IconLogo16 className={className} style={{ borderRadius }} />
            }
    }
}
