/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { Fragment, HTMLAttributes, useCallback, useMemo } from 'react'
import { MonoIconCommonClose16, WKIconButton } from '../../../../../../ui-lib/src'
import classes from './tabs.module.less'

export interface Tab {
    key: string
    name: string
    component: JSX.Element
}
export interface TabsProps extends HTMLAttributes<HTMLDivElement> {
    tabs: Tab[]
    activeKey: Tab['key']
    onClickClose: () => void
    onSwitchTab: (params: Tab) => void
    dataTestId?: string
}

export function Tabs(props: TabsProps) {
    const { tabs, activeKey, className, onClickClose, onSwitchTab, dataTestId, ...otherProps } = props

    const activeTabComponent = useMemo(() => {
        return tabs.find((tab) => tab.key === activeKey)?.component
    }, [activeKey, tabs])

    const onClickTab = useCallback(
        (v: Tab) => {
            if (v.key !== activeKey) {
                onSwitchTab?.(v)
            }
        },
        [activeKey, onSwitchTab]
    )

    return (
        <div className={classnames(classes.tabs, [className])} {...otherProps} data-testid={dataTestId}>
            <div className={classes.tabsTitle}>
                <div className={classes.tabsContainer}>
                    <div className={classes.tabSideMargin}></div>
                    {tabs.map((tab, index) => (
                        <Fragment key={tab.key}>
                            {index !== 0 ? <div className={classes.tabCenterMargin}></div> : null}
                            <Tab tab={tab} activeKey={activeKey} onClick={onClickTab} />
                        </Fragment>
                    ))}
                    <div className={classes.tabSideMargin}></div>
                </div>
                <div className={classes.tabsClose}>
                    <WKIconButton icon={<MonoIconCommonClose16 />} onClick={onClickClose} />
                </div>
            </div>
            <div className={classes.tabsContent}>{activeTabComponent}</div>
        </div>
    )
}

export interface TabProps {
    tab: Tab
    activeKey: Tab['key']
    onClick?: (params: Tab) => void
}

export function Tab(props: TabProps) {
    const { tab, activeKey, onClick } = props

    const _onClick = useCallback(() => {
        onClick?.(tab)
    }, [onClick, tab])

    return (
        <div className={classnames(classes.tab, { [classes.active]: tab.key === activeKey })} onClick={_onClick}>
            {tab.name}
        </div>
    )
}
