import { FeatureSwitchConfig, SwitchEnvironmentScopeType, SwitchStrategyType } from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'webgpu-blacklist',
    owner: '',
    description: '',
    strategies: [
        {
            env: SwitchEnvironmentScopeType.PRODUCTION,
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                userList: ['70efbc58726722d3b91efeadc1d031822284b360'],
            },
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
