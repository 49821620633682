import { Rect, Vector } from '../node/node'

// 生成一个点坐标
export function makePoint(x: number, y: number): Vector {
    return { x, y }
}

// 生成一个 rect
export function makeRect(x: number, y: number, width: number, height: number): Rect {
    return { x, y, width, height }
}

// rect 值是否合法
export function isRectValid(rect: Rect) {
    return !isNaN(rect.x) && !isNaN(rect.y) && !isNaN(rect.width) && !isNaN(rect.height)
}

// 合并两个 rects, 即求其并集
export function mergeRects(a: Rect, b: Rect): Rect {
    return {
        x: Math.min(a.x, b.x),
        y: Math.min(a.y, b.y),
        width: Math.max(a.x + a.width, b.x + b.width) - Math.min(a.x, b.x),
        height: Math.max(a.y + a.height, b.y + b.height) - Math.min(a.y, b.y),
    }
}

// 得到一个 rect 四个顶点的坐标
export function getRectVertices(rect: Rect): Vector[] {
    return [
        makePoint(rect.x, rect.y),
        makePoint(rect.x + rect.width, rect.y),
        makePoint(rect.x + rect.width, rect.y + rect.height),
        makePoint(rect.x, rect.y + rect.height),
    ]
}
