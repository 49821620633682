/* eslint-disable no-restricted-imports */
import { Tooltip, WKUserAvatar, WKUserAvatarProps, useEllipsisTooltip } from '../../../../../../../ui-lib/src'
import classnames from 'classnames'
import classes from './user-avatar.module.less'
export interface UserAvatarProps extends Pick<WKUserAvatarProps, 'userInfo'> {
    className?: string
}
export function UserAvatar(props: UserAvatarProps) {
    const { className, userInfo } = props
    const { inactivation, ellipsisRef, onmouseenter, onmouseleave } = useEllipsisTooltip<HTMLDivElement>()

    return (
        <div className={classnames(classes.userAvatar, [className])}>
            <WKUserAvatar size={16} userInfo={userInfo} />{' '}
            <Tooltip title={userInfo.nickname} inactivation={inactivation}>
                <div className={classes.name} ref={ellipsisRef} onMouseEnter={onmouseenter} onMouseLeave={onmouseleave}>
                    {userInfo.nickname}
                </div>
            </Tooltip>
        </div>
    )
}
