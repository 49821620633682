/* eslint-disable no-restricted-imports */
import {
    InsertPageAfterCommand,
    InsertPageBeforeCommand,
    PageCancelEditing,
    PageEnterEditing,
    SetCurrentPageInHistoryWasmCommand,
    SetCurrentPageWasmCall,
    UpdatePageName,
    Wukong,
} from '@wukong/bridge-proto'
import classNames from 'classnames'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { IconCheckboxChecked, ScrollView } from '../../../../../../../ui-lib/src'
import { usePagePanelService } from '../../../../../main/app-context'
import {
    useInHistoryWasm,
    useLastArchiveFromSinglePage,
} from '../../../../../main/history-mode/context/history-mode-context'
import { useViewState } from '../../../../../view-state-bridge'
import { EditorDataTestId } from '../../../../../window'
import { useCommand } from '../../../../context/document-context'
import { RightClickMenuWrapper } from '../../../right-click-menu-wrapper'
import { DoubleClickInput } from '../double-click-input'
import { DragItem } from '../drag-container'
import { DragContextProvider, DragEndProps, DragInsertArea, useDragItem } from '../drag-container/context'
import styles from './index.module.less'
import PageCancelEditingCommandParam = Wukong.DocumentProto.PageCancelEditingCommandParam
import PageEnterEditingCommandParam = Wukong.DocumentProto.PageEnterEditingCommandParam
import SetCurrentPageCommandParam = Wukong.DocumentProto.SetCurrentPageCommandParam
import UpdatePageNameCommandParam = Wukong.DocumentProto.UpdatePageNameCommandParam
import VPageItem = Wukong.DocumentProto.VPageItem

export const PagePanelItemList: FC<{ items: VPageItem[] }> = ({ items }) => {
    const command = useCommand()

    const handleDragEnd = (dragEndProps: DragEndProps) => {
        const { dragItem, insertItem, insertArea } = dragEndProps

        if (insertArea === DragInsertArea.Before) {
            command.DEPRECATED_invokeBridge(InsertPageBeforeCommand, {
                node: dragItem.id,
                nextSibling: insertItem.id,
            })
        } else {
            command.DEPRECATED_invokeBridge(InsertPageAfterCommand, {
                node: dragItem.id,
                prevSibling: insertItem.id,
            })
        }
        command.commitUndo()
    }

    return (
        <div className={styles['page-panel-item-list']} data-testid="page-container">
            <DragContextProvider onDragEnd={handleDragEnd}>
                {items.map((item, index) => (
                    <PagePanelItem key={item.id} item={item} pageIndex={index} disableDelete={items.length <= 1} />
                ))}
            </DragContextProvider>
        </div>
    )
}

export const PagePanelItem: FC<{
    item: VPageItem
    disableDelete: boolean
    pageIndex: number
}> = (props) => {
    const { item } = props

    const command = useCommand()

    const { activeDragItem } = useDragItem()

    const [hovered, setHovered] = useState<boolean>(false)

    const docReadonly = useViewState('docReadonly')

    const enableHoverdColor = useMemo<boolean>(() => {
        if ((activeDragItem as VPageItem)?.id === item.id) {
            return true
        }
        return !activeDragItem && hovered
    }, [activeDragItem, item.id, hovered])

    const handleChange = (name: string) => {
        if (docReadonly) {
            return
        }
        // 通过 cmd 设置 pageName
        if (name && name != item.name) {
            command.DEPRECATED_invokeBridge(
                UpdatePageName,
                UpdatePageNameCommandParam.create({
                    nodeId: item.id,
                    name,
                })
            )
            command.commitUndo()
        } else {
            command.DEPRECATED_invokeBridge(
                PageCancelEditing,
                PageCancelEditingCommandParam.create({
                    nodeId: item.id,
                })
            )
        }
    }

    const rightClickMenuState = useViewState('rightClickMenuState')
    const [itemRightClickState, setItemRightClickState] = useState<boolean>(false)
    const pagePanelService = usePagePanelService()

    const onChangeRightClickState = () => {
        pagePanelService.setLastSelectedPageForRightClick(item.id)
        setItemRightClickState(true)
    }
    useEffect(() => {
        if (!rightClickMenuState?.opened) {
            setItemRightClickState(false)
        }
    }, [rightClickMenuState])
    const enableRightPopOpenColor = useMemo<boolean>(() => {
        return !!rightClickMenuState?.opened && itemRightClickState
    }, [rightClickMenuState, itemRightClickState])

    const handleDbClick = useCallback(() => {
        if (docReadonly) {
            return
        }
        // 通过 cmd 设置 page 进入编辑态
        command.DEPRECATED_invokeBridge(PageEnterEditing, PageEnterEditingCommandParam.create({ nodeId: item.id }))
    }, [command, item.id, docReadonly])

    const { inHistoryWasm } = useInHistoryWasm()
    const { lastArchiveFromSinglePage } = useLastArchiveFromSinglePage()

    const handleSelect = () => {
        // 在历史版本的 wasm 中且上一次返回的是分页数据，需要调用特殊的 wasmCall 来获取新的 page archive
        if (inHistoryWasm && lastArchiveFromSinglePage.current) {
            command.DEPRECATED_invokeBridge(
                SetCurrentPageInHistoryWasmCommand,
                SetCurrentPageCommandParam.create({
                    nodeId: item.id,
                })
            )
        } else {
            // 通过 cmd 设置 currentPage
            command.DEPRECATED_invokeBridge(
                SetCurrentPageWasmCall,
                SetCurrentPageCommandParam.create({
                    nodeId: item.id,
                })
            )
        }
        command.commitUndo()
    }

    return (
        <RightClickMenuWrapper
            source={Wukong.DocumentProto.RightClickSource.RIGHT_CLICK_SOURCE_DESIGN_MODE_PAGE_PANEL}
            onMouseDown={onChangeRightClickState}
            stopPropagation
            dataTestId={EditorDataTestId.PagePanelContextMenu}
        >
            <DragItem<VPageItem> item={item}>
                <ScrollView.Item
                    uniqueKey={undefined}
                    isSelectFn={() => item.isSelected}
                    data-testid={`page-item:${props.pageIndex}`}
                    className={classNames(
                        styles['page-panel-item'],
                        enableHoverdColor && styles['page-panel-item-hovered'],
                        enableRightPopOpenColor && styles['page-panel-item-right-click']
                    )}
                    onMouseEnter={() => {
                        setHovered(true)
                    }}
                    onMouseLeave={() => {
                        setHovered(false)
                    }}
                    onDoubleClick={handleDbClick}
                    onClick={handleSelect}
                >
                    <div className={styles['check-icon-container']}>
                        {item.isSelected ? (
                            <IconCheckboxChecked data-testid={`page-item-selected:${props.pageIndex}`} />
                        ) : null}
                    </div>

                    <DoubleClickInput
                        className={item.isSelected ? styles.strong : undefined}
                        value={item.name}
                        onChange={handleChange}
                        isEditing={item.isEditing}
                    />
                </ScrollView.Item>
            </DragItem>
        </RightClickMenuWrapper>
    )
}
