import { translation } from './font-miss-modal.translation'
/* eslint-disable no-restricted-imports */
import { PropsWithChildren } from 'react'
import { DraggablePopupV2 } from '../../../../../../ui-lib/src'

export interface FontMissModalProps extends PropsWithChildren {
    onOkDisabled: boolean
    onOk: () => void
    onCancel: () => void
    emptyFooter?: boolean
}

export const FontMissModal = ({ children, onOkDisabled, onOk, onCancel, emptyFooter }: FontMissModalProps) => {
    const width = 536
    return (
        <DraggablePopupV2
            visible
            header={translation('MissingFonts')}
            width={width}
            bodyClassName="p-0"
            okText={translation('ReplaceFonts')}
            testId="font-miss-modal"
            closeTestId="font-miss-modal-close"
            okButtonProps={{
                disabled: onOkDisabled,
                onMouseDown: (e) => e.stopPropagation(),
            }}
            position={{ top: 57, left: window.innerWidth - 248 - 8 - width }}
            cancelButtonProps={{ onMouseDown: (e) => e.stopPropagation() }}
            onOk={onOk}
            onCancel={onCancel}
            footer={emptyFooter ? null : undefined}
        >
            {children}
        </DraggablePopupV2>
    )
}
