import { state } from 'ccstate'
import { useGet } from 'ccstate-react'
import classNames from 'classnames'
import { FC, PropsWithChildren, useLayoutEffect } from 'react'
import { traceable } from '../../../../../../../util/src/ccstate-helper/traceable'
import { Admin } from '../../../../../space/app/loading-container/layout/admin'
import { DocList } from '../../../../../space/app/loading-container/layout/doc-list'
import { SpaceProgress } from '../../../../../space/app/loading-container/layout/progress'
import { Shared } from '../../../../../space/app/loading-container/layout/shared'
import { Teams } from '../../../../../space/app/loading-container/layout/teams'
import { usePageType } from '../../../../../space/app/loading-container/use-page-type'
import styles from './index.module.less'
import { SideBar } from './sidebar'
import { TeamPageSkeleton } from './team'
import { TopBar } from './top'
import { Workspace, Workspaces } from './workspaces'

export const SpaceSkeletonWrapper: FC<PropsWithChildren> = ({ children }) => {
    return (
        <>
            {children}
            <SpaceSkeleton />
        </>
    )
}

export const spaceLoading$ = traceable('hulh01@kanyun.com', state(true))

const SpaceSkeleton: FC = () => {
    const loading = useGet(spaceLoading$)
    const pageType = usePageType()

    useLayoutEffect(() => {
        document.getElementById('space-root-skeleton')?.remove()
    }, [])

    const showDocListSkeleton =
        pageType.isTrash || pageType.isFav || pageType.isDrafts || pageType.isRecent || pageType.isFolder

    const showTeamPageSkeleton = pageType.isTeam || pageType.isTeamMembers || pageType.isTeamFonts

    return (
        <div data-testid={loading ? '' : 'space-loaded'} className="top-0 z-99 absolute pointer-events-none">
            <SpaceProgress />

            <div
                className={'flex w-screen h-screen bg-$wk-v2-bg-color-white'}
                style={{
                    opacity: loading ? 1 : 0,
                    transition: 'opacity 0.5s',
                }}
            >
                <SideBar />

                <div className="flex-1 relative flex flex-col">
                    {/* mask */}
                    <div
                        className="absolute inset-0 top-50 z-1"
                        style={{
                            background: 'linear-gradient(180.00002deg, rgba(255, 255, 255, 0.00) 0%, #FFFFFF 100%)',
                        }}
                    />

                    <div className={classNames(styles['fade-in'], 'flex flex-col flex-1')}>
                        <TopBar />

                        {showDocListSkeleton && <DocList />}

                        {showTeamPageSkeleton && <TeamPageSkeleton />}

                        {pageType.isTeams && <Teams />}

                        {pageType.isAdmin && <Admin />}

                        {pageType.isShared && <Shared />}

                        {pageType.isWorkspaces && <Workspaces />}
                        {pageType.isWorkspace && <Workspace />}
                    </div>
                </div>
            </div>
        </div>
    )
}
