import { useCallback } from 'react'
import { useUserConfigStateV2 } from '../../../../main/user-config/user-config-hook'

export function usePluginDevUserConfig() {
    const [pluginDevUseHotReloadV2, setPluginDevUseHotReloadV2] = useUserConfigStateV2('pluginDevUseHotReload')
    const [pluginDevUseSandboxV2, setPluginDevUseSandboxV2] = useUserConfigStateV2('pluginDevUseSandbox')
    const pluginDevUseHotReload = (() => {
        return pluginDevUseHotReloadV2
    })()
    const pluginDevUseSandbox = (() => {
        return pluginDevUseSandboxV2
    })()

    const setPluginDevUseHotReload = useCallback(
        (value: boolean) => {
            setPluginDevUseHotReloadV2(value)
        },
        [setPluginDevUseHotReloadV2]
    )

    const setPluginDevUseSandbox = useCallback(
        (value: boolean) => {
            setPluginDevUseSandboxV2(value)
        },
        [setPluginDevUseSandboxV2]
    )

    return { pluginDevUseHotReload, pluginDevUseSandbox, setPluginDevUseHotReload, setPluginDevUseSandbox }
}
