import { translation } from './coactor-member-list.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { HtmlHTMLAttributes, useCallback, useMemo, useRef } from 'react'
import { WKPopover, WKPopoverRef, WKUserAvatar } from '../../../../../../ui-lib/src'
import { DeepRequired } from '../../../../view-state-bridge'
import { CooperationTestId } from '../../../../window'
import { rgb2Style } from '../util/color'
import classes from './coactor-member-list.module.less'
import { useCoactorMemberList } from './use-coactor-member-list'

// 协作者列表
export function CoactorMemberList() {
    const { activeUserList, moreActiveUserList, onToggleObservingMode } = useCoactorMemberList()

    return (
        <>
            <ActiveCoactorMemberList userList={activeUserList} onClick={onToggleObservingMode} />
            <MoreActiveCoactorMemberList userList={moreActiveUserList} onClick={onToggleObservingMode} />
        </>
    )
}

// 最外层平铺的协作者
function ActiveCoactorMemberList(props: {
    userList: DeepRequired<Wukong.DocumentProto.IVActiveUser>[]
    onClick: (user: DeepRequired<Wukong.DocumentProto.IVActiveUser>) => void
}) {
    return (
        <div
            className={classes.activeContainer}
            id="wk-active-coactor"
            data-testid={CooperationTestId.ActiveUserList}
            data-user-count={props.userList.length}
        >
            {props.userList.map((user) => (
                <ActiveCoactorMemberItem key={user.sessionId} user={user} onClick={props.onClick} />
            ))}
        </div>
    )
}

function ActiveCoactorMemberItem(props: {
    user: DeepRequired<Wukong.DocumentProto.IVActiveUser>
    onClick: (user: DeepRequired<Wukong.DocumentProto.IVActiveUser>) => void
}) {
    const triggerRef = useRef<HTMLDivElement>(null)
    const triggerRect = useCallback(() => {
        return triggerRef.current?.getBoundingClientRect()
    }, [])
    return (
        <div key={props.user.sessionId} ref={triggerRef} className={classes.avatarContainer}>
            <WKPopover
                contents={<NicknameLabel className={classes.tooltipNameLabel} user={props.user} />}
                contentClassName={classes.popoverContentContainer}
                placement="bottom-center"
                data-testid={CooperationTestId.AvatarItem(props.user.role)}
                className={classnames(classes.coactorItem, {
                    [classes.observed]:
                        props.user.role === Wukong.DocumentProto.ActiveUserRole.ACTIVE_USER_ROLE_OBSERVED,
                })}
                style={{
                    color: rgb2Style(props.user.color),
                }}
                onClick={() => props.onClick(props.user)}
                triggerRect={triggerRect}
            >
                <WKUserAvatar
                    userInfo={{
                        avatarBackgroundColor: props.user.avatarBackgroundColor,
                        avatarId: props.user.avatarId,
                        nickname: props.user.nickname,
                    }}
                    size={24}
                />
            </WKPopover>
        </div>
    )
}

// 更多收起的协作者
function MoreActiveCoactorMemberList(props: {
    userList: DeepRequired<Wukong.DocumentProto.IVActiveUser>[]
    onClick: (user: DeepRequired<Wukong.DocumentProto.IVActiveUser>) => void
}) {
    const popoverRef = useRef<WKPopoverRef>(null)

    const onClick = (user: DeepRequired<Wukong.DocumentProto.IVActiveUser>) => {
        popoverRef.current?.hide()
        props.onClick(user)
    }

    return (
        <>
            {!!props.userList.length && (
                <div
                    className={classnames(classes.moreCoactorCountContainer)}
                    data-testid={CooperationTestId.MoreActiveUserList}
                    data-user-count={props.userList.length}
                >
                    <WKPopover
                        ref={popoverRef}
                        placement="bottom-center"
                        contentClassName={classes.popoverMoreContentContainer}
                        className={classes.popoverTriggerContainer}
                        triggerTestId={CooperationTestId.MoreActiveUserTrigger}
                        contents={props.userList.map((user) => (
                            <div
                                key={user.sessionId}
                                className={classes.moreCoactorItemContainer}
                                onClick={() => onClick(user)}
                                data-testid={CooperationTestId.AvatarItem(user.role)}
                            >
                                <div className={classes.moreCoactorItem}>
                                    <div
                                        className={classnames(classes.menuCoactorAvatar)}
                                        style={{
                                            color: rgb2Style(user.color),
                                        }}
                                    >
                                        <WKUserAvatar
                                            userInfo={{
                                                avatarId: user.avatarId,
                                                nickname: user.nickname,
                                                avatarBackgroundColor: user.avatarBackgroundColor,
                                            }}
                                            size={24}
                                        />
                                    </div>
                                    <NicknameLabel className={classes.menuNameLabel} user={user} />
                                </div>
                            </div>
                        ))}
                    >
                        <span className={classnames(classes.moreCoactorCount)}>{props.userList.length}</span>
                    </WKPopover>
                </div>
            )}
        </>
    )
}

function NicknameLabel({
    user,
    className,
}: {
    user: DeepRequired<Wukong.DocumentProto.IVActiveUser>
} & HtmlHTMLAttributes<HTMLSpanElement>) {
    const isMySelf = useMemo(
        () =>
            user.role === Wukong.DocumentProto.ActiveUserRole.ACTIVE_USER_ROLE_CURRENT_MYSELF ||
            user.role === Wukong.DocumentProto.ActiveUserRole.ACTIVE_USER_ROLE_OTHER_MYSELF,
        [user.role]
    )
    return (
        <div className={classnames(classes.nicknameWrapper)}>
            <span
                className={classnames(className, {
                    [classes.myNickname]: isMySelf,
                })}
            >
                {user.nickname}
            </span>
            {isMySelf && <span className={classes.roleLabel}>({translation('You')})</span>}
        </div>
    )
}
