import {
    CanvasClipboardCutCommand,
    DeleteLocalStyleSelectionCommand,
    GetClipboardStyleNodeCountCommand,
    GroupLocalStyleSelectionCommand,
    UngroupLocalStyleSelectionCommand,
    UpdateLocalStyleEditingNameCommand,
    UpdateLocalStyleEditingStyleIdCommand,
    UpdateLocalStyleFolderNameCommand,
    UpdateLocalStyleNameCommand,
    UpdateLocalStyleSelectionCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { cloneDeep, findLastIndex } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { cmdChangePopupState } from '../../../../../document/command/document-command'
import { cmdChangeAttrPanelStyleEditorState } from '../../../../../document/command/node-props-command'
import { PopupStateType } from '../../../../../document/node/node'
import { isWindows } from '../../../../../kernel/util/ua'
import { DeepRequired, useViewState } from '../../../../../view-state-bridge'
import { useClipboardService, useCommand } from '../../../../context/document-context'
import { isItemInFolder, isItemSelected } from '../utils'

export type EditStyle = {
    type: Wukong.DocumentProto.LocalStyleSelectionType
    editingStyleId: string
    isCreate?: boolean
} | null

export type ILocalStyleItem =
    | DeepRequired<Wukong.DocumentProto.ILocalTextStyleItem>
    | DeepRequired<Wukong.DocumentProto.ILocalPaintStyleItem>
    | DeepRequired<Wukong.DocumentProto.ILocalEffectStyleItem>
    | DeepRequired<Wukong.DocumentProto.ILocalLayoutGridStyleItem>

export const isLocalStyleItemEqual = (a: ILocalStyleItem, b: ILocalStyleItem) => {
    return a.isFolder === b.isFolder && a.folderName === b.folderName && a.styleId === b.styleId
}

export const getLocalStyleItemKey = (type: 'text' | 'paint' | 'effect' | 'layoutgrid', item: ILocalStyleItem) => {
    return type + '-' + (item.isFolder ? item.folderName : item.styleId)
}

export const getItemKeyByStyleId = (type: 'text' | 'paint' | 'effect' | 'layoutgrid', styleId: string) => {
    return type + '-' + styleId
}

export const removeDuplicatSelectionItems = (
    items: ILocalStyleItem[],
    selection: DeepRequired<Wukong.DocumentProto.ILocalStyleSelection>
) => {
    const relatedSelectionItems = getRelatedSelectionItems(items, selection)
    const relatedFolderNames = new Set<string>(
        relatedSelectionItems.filter((item) => item.isFolder).map((item) => item.folderName)
    )
    const relatedStyleIds = new Set<string>(
        relatedSelectionItems.filter((item) => !item.isFolder).map((item) => item.styleId)
    )

    return {
        ...selection,
        folderNames: selection.folderNames.filter((v) => !relatedFolderNames.has(v)),
        styleIds: selection.styleIds.filter((v) => !relatedStyleIds.has(v)),
    }
}

export const isAncestorSelect = (
    items: ILocalStyleItem[],
    selection: DeepRequired<Wukong.DocumentProto.ILocalStyleSelection>,
    targetItem: ILocalStyleItem
) => {
    if (targetItem.isFolder) {
        return !!selection.folderNames.find((folderName) => targetItem.folderName.startsWith(folderName))
    } else {
        const targetStyle = items.find((value) => {
            return !value.isFolder && targetItem.styleId == value.styleId
        })

        if (targetStyle) {
            return !!selection.folderNames.find((folderName) => targetStyle?.styleNode?.name.startsWith(folderName))
        }
    }
    return false
}

export const continuousSelect = (
    items: ILocalStyleItem[],
    selection: DeepRequired<Wukong.DocumentProto.ILocalStyleSelection>,
    targetItem: ILocalStyleItem
): DeepRequired<Wukong.DocumentProto.ILocalStyleSelection> => {
    if (selection.folderNames.length <= 0 && selection.styleIds.length <= 0) {
        return selection
    }

    const nextSelection: DeepRequired<Wukong.DocumentProto.ILocalStyleSelection> = {
        type: selection.type,
        folderNames: [],
        styleIds: [],
    }

    let minIndex = items.findIndex((item) => isItemSelected(item, selection))
    let maxIndex = findLastIndex(items, (item) => isItemSelected(item, selection))

    const targetIndex = items.findIndex((item) => {
        return (
            targetItem.isFolder == item.isFolder &&
            targetItem.folderName == item.folderName &&
            targetItem.styleId == item.styleId
        )
    })

    minIndex = Math.min(minIndex, targetIndex)
    maxIndex = Math.max(maxIndex, targetIndex)

    items.forEach((item, index) => {
        if (index >= minIndex && index <= maxIndex) {
            item.isFolder ? nextSelection.folderNames.push(item.folderName) : nextSelection.styleIds.push(item.styleId)
        }
    })

    return nextSelection
}

const getRelatedSelectionItems = <T extends ILocalStyleItem>(
    items: T[] = [],
    selection: DeepRequired<Wukong.DocumentProto.ILocalStyleSelection>
) => {
    return items.filter((item) => {
        return selection.folderNames.find((folderName) => isItemInFolder(item, folderName))
    })
}

export const getNextSelectionByMouseDown = (
    e: React.MouseEvent<HTMLElement>,
    selection: DeepRequired<Wukong.DocumentProto.ILocalStyleSelection>,
    type: Wukong.DocumentProto.LocalStyleSelectionType,
    items: ILocalStyleItem[],
    item: ILocalStyleItem
): DeepRequired<Wukong.DocumentProto.ILocalStyleSelection> => {
    if (selection.type !== type) {
        return {
            type,
            folderNames: item.isFolder ? [item.folderName] : [],
            styleIds: !item.isFolder ? [item.styleId] : [],
        }
    }

    let nextSelection = selection
    // windows 不支持 ctrl+左键 唤起右键菜单
    if (e.button === 0 && (!e.ctrlKey || isWindows())) {
        if (e.metaKey) {
            if (item.isFolder) {
                if (selection.folderNames.includes(item.folderName)) {
                    nextSelection = {
                        ...selection,
                        folderNames: selection.folderNames.filter((name) => name !== item.folderName),
                    }
                } else {
                    nextSelection = {
                        ...selection,
                        folderNames: [...selection.folderNames, item.folderName],
                    }
                }
            } else {
                if (selection.styleIds.includes(item.styleId)) {
                    nextSelection = {
                        ...selection,
                        styleIds: selection.styleIds.filter((styleId) => styleId !== item.styleId),
                    }
                } else {
                    nextSelection = {
                        ...selection,
                        styleIds: [...selection.styleIds, item.styleId],
                    }
                }
            }
        } else if (e.shiftKey) {
            nextSelection = continuousSelect(items, selection, item)
        } else {
            nextSelection = {
                type,
                folderNames: item.isFolder ? [item.folderName] : [],
                styleIds: !item.isFolder ? [item.styleId] : [],
            }
        }
    } else if (e.button === 2 || (e.button == 0 && e.ctrlKey && !isWindows())) {
        if (
            !selection.styleIds.includes(item.styleId) &&
            !selection.folderNames.includes(item.folderName) &&
            !isAncestorSelect(items, selection, item)
        ) {
            nextSelection = {
                type,
                folderNames: item.isFolder ? [item.folderName] : [],
                styleIds: !item.isFolder ? [item.styleId] : [],
            }
        }
    }

    return removeDuplicatSelectionItems(items, nextSelection)
}

export type LocalStyleModel = ReturnType<typeof useLocalStyleModel>

export function useLocalStyleModel(props: {
    isReadonly?: boolean // 只读模式
    designPanelRightMenuOpen?: boolean // 设计面板右键菜单是否打开
}) {
    const command = useCommand()
    const modelState_ = useViewState('localStyleNodeState')
    const modelState = useMemo(() => {
        const model = cloneDeep(modelState_)
        return model
    }, [modelState_])
    const docReadonly = useViewState('docReadonly')
    const editingStateV2 = useViewState('localStyleEditingStateV2')
    const clipboardService = useClipboardService()
    const styleSelectionCount = editingStateV2?.selectionCount ?? 0
    const editingState = editingStateV2?.state
    const editStyle = editingState?.editingStyleId
        ? {
              type: editingState?.selection.type,
              editingStyleId: editingState?.editingStyleId,
          }
        : null

    const { selection, editingName } = useMemo(() => {
        return {
            selection: editingState?.selection ?? {
                type: Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_NONE,
                folderNames: [],
                styleIds: [],
            },

            editingName: editingState?.editingName ?? {
                type: Wukong.DocumentProto.LocalStyleEditingNameType.LOCAL_STYLE_EDITING_NAME_TYPE_NONE,
                editingFolderName: '',
                editingStyleId: '',
            },
        }
    }, [editingState])

    const isEditingName =
        editingName.type !== Wukong.DocumentProto.LocalStyleEditingNameType.LOCAL_STYLE_EDITING_NAME_TYPE_NONE

    const relatedSelectionItems = useMemo(
        () =>
            ({
                text:
                    selection.type === Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_TEXT
                        ? getRelatedSelectionItems(modelState?.textStyleItems ?? [], selection)
                        : [],
                paint:
                    selection.type === Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_PAINT
                        ? getRelatedSelectionItems(modelState?.paintStyleItems ?? [], selection)
                        : [],
                effect:
                    selection.type === Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_EFFECT
                        ? getRelatedSelectionItems(modelState?.effectStyleItems ?? [], selection)
                        : [],
                layoutGrid:
                    selection.type ===
                    Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_LAYOUT_GRID
                        ? getRelatedSelectionItems(modelState?.layoutGridStyleItems ?? [], selection)
                        : [],
            } as const),
        [modelState, selection]
    )

    const [clipboardStyleNodeCount, _setClipboardStyleNodeCount] = useState(0)

    // 在右键菜单 designPanelRightMenuOpen 打开时，会重新获取一次剪贴板样式数量
    const setClipboardStyleNodeCount = useCallback(async () => {
        if (docReadonly) {
            return
        }

        if (props.designPanelRightMenuOpen) {
            await clipboardService.updateDataTransferFromClipboardApi()
            _setClipboardStyleNodeCount(command.DEPRECATED_invokeBridge(GetClipboardStyleNodeCountCommand).value ?? 0)
        } else {
            _setClipboardStyleNodeCount(0)
        }
    }, [clipboardService, command, props.designPanelRightMenuOpen, docReadonly])

    useEffect(() => {
        setClipboardStyleNodeCount()
    }, [setClipboardStyleNodeCount])

    const updateSelection = useCallback(
        (nextSelection: Wukong.DocumentProto.ILocalStyleSelection) => {
            command.DEPRECATED_invokeBridge(
                UpdateLocalStyleSelectionCommand,
                Wukong.DocumentProto.LocalStyleSelection.create(nextSelection)
            )
        },
        [command]
    )

    return {
        modelState,
        editStyle,
        isEditingName,
        selection,
        relatedSelectionItems,
        clipboardStyleNodeCount,
        styleSelectionCount,
        modelCommand: {
            clearSelection: () => {
                updateSelection({
                    type: Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_NONE,
                    folderNames: [],
                    styleIds: [],
                })
            },

            updateEditStyle: (nextEditStyle: EditStyle) => {
                command.DEPRECATED_invokeBridge(
                    UpdateLocalStyleEditingStyleIdCommand,
                    Wukong.DocumentProto.BridgeProtoString.create({
                        value: nextEditStyle?.editingStyleId ?? '',
                    })
                )

                if (nextEditStyle) {
                    updateSelection({
                        type: nextEditStyle.type,
                        folderNames: [],
                        styleIds: [nextEditStyle.editingStyleId],
                    })
                    command.invoke(cmdChangeAttrPanelStyleEditorState, {
                        openFromModule:
                            nextEditStyle.type ===
                            Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_TEXT
                                ? Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_TEXT
                                : nextEditStyle.type ===
                                  Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_EFFECT
                                ? Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_EFFECT
                                : nextEditStyle.type ===
                                  Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_LAYOUT_GRID
                                ? Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_LAYOUT_GRID
                                : Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_FILL,
                        editingStyleId: nextEditStyle.editingStyleId,
                        isCreate: nextEditStyle.isCreate,
                    })
                    command.invoke(cmdChangePopupState, {
                        type: props.isReadonly
                            ? PopupStateType.POPUP_STATE_TYPE_STYLE_VIEWER
                            : PopupStateType.POPUP_STATE_TYPE_STYLE_MANAGE,
                        reciprocalIndex: -1,
                        multiPopup: [],
                    })
                } else {
                    updateSelection({
                        type: Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_NONE,
                        folderNames: [],
                        styleIds: [],
                    })
                    command.invoke(cmdChangeAttrPanelStyleEditorState, null)
                    command.invoke(cmdChangePopupState, {
                        type: PopupStateType.POPUP_STATE_TYPE_NONE,
                        reciprocalIndex: -1,
                        multiPopup: [],
                    })
                }
            },

            clearEditName: () => {
                command.DEPRECATED_invokeBridge(
                    UpdateLocalStyleEditingNameCommand,
                    Wukong.DocumentProto.LocalStyleEditingName.create({
                        type: Wukong.DocumentProto.LocalStyleEditingNameType.LOCAL_STYLE_EDITING_NAME_TYPE_NONE,
                        editingFolderName: '',
                        editingStyleId: '',
                    })
                )
            },

            updateEditName: (item: ILocalStyleItem) => {
                command.DEPRECATED_invokeBridge(
                    UpdateLocalStyleEditingNameCommand,
                    Wukong.DocumentProto.LocalStyleEditingName.create({
                        type: item.isFolder
                            ? Wukong.DocumentProto.LocalStyleEditingNameType.LOCAL_STYLE_EDITING_NAME_TYPE_FOLDER
                            : Wukong.DocumentProto.LocalStyleEditingNameType.LOCAL_STYLE_EDITING_NAME_TYPE_STYLE,
                        editingFolderName: item.isFolder ? item.folderName : '',
                        editingStyleId: !item.isFolder ? item.styleId : '',
                    })
                )
            },

            isEditingItemName: (type: Wukong.DocumentProto.LocalStyleSelectionType, item: ILocalStyleItem) => {
                return !!(
                    selection &&
                    selection.type == type &&
                    editingName &&
                    editingName.type !=
                        Wukong.DocumentProto.LocalStyleEditingNameType.LOCAL_STYLE_EDITING_NAME_TYPE_NONE &&
                    (item.isFolder
                        ? editingName.editingFolderName === item.folderName
                        : editingName.editingStyleId === item.styleId)
                )
            },

            renameLocalStyleItem: (item: ILocalStyleItem, newName: string) => {
                if (item.isFolder) {
                    command.DEPRECATED_invokeBridge(
                        UpdateLocalStyleFolderNameCommand,
                        Wukong.DocumentProto.UpdateLocalStyleFolderNameCommandParam.create({
                            srcFolderName: item.folderName,
                            dstFolderName: newName,
                        })
                    )
                } else {
                    command.DEPRECATED_invokeBridge(
                        UpdateLocalStyleNameCommand,
                        Wukong.DocumentProto.UpdateLocalStyleNameCommandParam.create({
                            styleId: item.styleId,
                            name: newName,
                        })
                    )
                }
            },
            updateSelection,
            groupSelection: () => {
                command.DEPRECATED_invokeBridge(GroupLocalStyleSelectionCommand)
            },
            ungroupSelection: () => {
                command.DEPRECATED_invokeBridge(UngroupLocalStyleSelectionCommand)
            },
            deleteSelection: () => {
                command.DEPRECATED_invokeBridge(DeleteLocalStyleSelectionCommand)
            },
            cutSelection: () => {
                command.DEPRECATED_invokeBridge(CanvasClipboardCutCommand)
            },
            paste: () => {
                clipboardService.paste()
            },
        },
    } as const
}
