import { Wukong } from '@wukong/bridge-proto'
import { translation } from './constants.translation'

import IPrototypeInteractionWithNodeId = Wukong.DocumentProto.IPrototypeInteractionWithNodeId
import IPrototypeInteractionTargetNode = Wukong.DocumentProto.IPrototypeInteractionTargetNode
import IPrototypeInteractionSelectedTargetNode = Wukong.DocumentProto.IPrototypeInteractionSelectedTargetNode
import InteractionType = Wukong.DocumentProto.InteractionType

export type PrototypeInteractionWithNodeId = IPrototypeInteractionWithNodeId
export type PrototypeInteractionTargetNode = IPrototypeInteractionTargetNode
export type PrototypeInteractionSelectedTargetNode = IPrototypeInteractionSelectedTargetNode

export interface InteractionsWithEventType {
    event: { interactionType: InteractionType }
    interactions: PrototypeInteractionWithNodeId[]
}

export const MIXED_TYPE = 'Mixed' as const
export const MIXED_TYPE_LABEL = translation('Mixed')

export function getInteractionTypeList(): InteractionType[][] {
    return [
        [InteractionType.INTERACTION_TYPE_NONE],
        [
            InteractionType.INTERACTION_TYPE_ON_CLICK,
            InteractionType.INTERACTION_TYPE_DRAG,
            InteractionType.INTERACTION_TYPE_ON_HOVER,
            InteractionType.INTERACTION_TYPE_ON_PRESS,
        ],
        [
            InteractionType.INTERACTION_TYPE_MOUSE_ENTER,
            InteractionType.INTERACTION_TYPE_MOUSE_LEAVE,
            InteractionType.INTERACTION_TYPE_MOUSE_DOWN,
            InteractionType.INTERACTION_TYPE_MOUSE_UP,
        ],
        [InteractionType.INTERACTION_TYPE_AFTER_TIMEOUT],
    ]
}

export const InteractionType2DestopFullLabel: Partial<Record<InteractionType, string>> = {
    [InteractionType.INTERACTION_TYPE_ON_CLICK]: translation('OnClick'),
    [InteractionType.INTERACTION_TYPE_AFTER_TIMEOUT]: translation('AfterDelay'),
    [InteractionType.INTERACTION_TYPE_MOUSE_ENTER]: translation('MouseEnter'),
    [InteractionType.INTERACTION_TYPE_MOUSE_LEAVE]: translation('MouseLeave'),
    [InteractionType.INTERACTION_TYPE_ON_HOVER]: translation('WhileHovering'),
    [InteractionType.INTERACTION_TYPE_MOUSE_DOWN]: translation('MouseDown'),
    [InteractionType.INTERACTION_TYPE_MOUSE_UP]: translation('MouseUp'),
    [InteractionType.INTERACTION_TYPE_ON_PRESS]: translation('WhilePressing'),
    [InteractionType.INTERACTION_TYPE_NONE]: translation('None'),
    [InteractionType.INTERACTION_TYPE_DRAG]: translation('OnDrag'),
}

export const InteractionType2DestopShortLabel: Partial<Record<InteractionType, string>> = {
    [InteractionType.INTERACTION_TYPE_ON_CLICK]: translation('Click'),
    [InteractionType.INTERACTION_TYPE_AFTER_TIMEOUT]: translation('AfterDelay'),
    [InteractionType.INTERACTION_TYPE_MOUSE_ENTER]: translation('MouseEnter'),
    [InteractionType.INTERACTION_TYPE_MOUSE_LEAVE]: translation('MouseLeave'),
    [InteractionType.INTERACTION_TYPE_ON_HOVER]: translation('Hover'),
    [InteractionType.INTERACTION_TYPE_MOUSE_DOWN]: translation('MouseDown'),
    [InteractionType.INTERACTION_TYPE_MOUSE_UP]: translation('MouseUp'),
    [InteractionType.INTERACTION_TYPE_ON_PRESS]: translation('Press'),
    [InteractionType.INTERACTION_TYPE_NONE]: translation('None'),
    [InteractionType.INTERACTION_TYPE_DRAG]: translation('Drag'),
}

export const InteractionType2MobileFullLabel: Partial<Record<InteractionType, string>> = {
    [InteractionType.INTERACTION_TYPE_ON_CLICK]: translation('OnTap'),
    [InteractionType.INTERACTION_TYPE_AFTER_TIMEOUT]: translation('AfterDelay'),
    [InteractionType.INTERACTION_TYPE_MOUSE_ENTER]: translation('MouseEnter'),
    [InteractionType.INTERACTION_TYPE_MOUSE_LEAVE]: translation('MouseLeave'),
    [InteractionType.INTERACTION_TYPE_ON_HOVER]: translation('WhileHovering'),
    [InteractionType.INTERACTION_TYPE_MOUSE_DOWN]: translation('TouchDown'),
    [InteractionType.INTERACTION_TYPE_MOUSE_UP]: translation('TouchUp'),
    [InteractionType.INTERACTION_TYPE_ON_PRESS]: translation('WhilePressing'),
    [InteractionType.INTERACTION_TYPE_NONE]: translation('None'),
    [InteractionType.INTERACTION_TYPE_DRAG]: translation('OnDrag'),
}

export const InteractionType2MobileShortLabel: Partial<Record<InteractionType, string>> = {
    [InteractionType.INTERACTION_TYPE_ON_CLICK]: translation('Tap'),
    [InteractionType.INTERACTION_TYPE_AFTER_TIMEOUT]: translation('AfterDelay'),
    [InteractionType.INTERACTION_TYPE_MOUSE_ENTER]: translation('MouseEnter'),
    [InteractionType.INTERACTION_TYPE_MOUSE_LEAVE]: translation('MouseLeave'),
    [InteractionType.INTERACTION_TYPE_ON_HOVER]: translation('Hover'),
    [InteractionType.INTERACTION_TYPE_MOUSE_DOWN]: translation('TouchDown'),
    [InteractionType.INTERACTION_TYPE_MOUSE_UP]: translation('TouchUp'),
    [InteractionType.INTERACTION_TYPE_ON_PRESS]: translation('Press'),
    [InteractionType.INTERACTION_TYPE_NONE]: translation('None'),
    [InteractionType.INTERACTION_TYPE_DRAG]: translation('Drag'),
}

export function getAddInteractionTypeList(): InteractionType[] {
    return [
        InteractionType.INTERACTION_TYPE_ON_CLICK,
        InteractionType.INTERACTION_TYPE_DRAG,
        InteractionType.INTERACTION_TYPE_ON_HOVER,
        InteractionType.INTERACTION_TYPE_ON_PRESS,
        InteractionType.INTERACTION_TYPE_MOUSE_ENTER,
        InteractionType.INTERACTION_TYPE_MOUSE_LEAVE,
        InteractionType.INTERACTION_TYPE_MOUSE_DOWN,
        InteractionType.INTERACTION_TYPE_MOUSE_UP,
    ]
}

export enum InteractionActionType {
    None = 'None',
    NavigateTo = 'NavigateTo',
    Back = 'Back',
    ScrollTo = 'ScrollTo',
    OpenLink = 'OpenLink',
    OpenOverlay = 'OpenOverlay',
    SwapOverlay = 'SwapOverlay',
    CloseOverlay = 'CloseOverlay',
}

export type InteractionActionTypeWithMixed = InteractionActionType | typeof MIXED_TYPE
export type InteractionTypeWithMixed = InteractionType | typeof MIXED_TYPE

export const InteractionActionType2Label: Record<InteractionActionType, string> = {
    [InteractionActionType.None]: translation('None'),
    [InteractionActionType.NavigateTo]: translation('NavigateTo'),
    [InteractionActionType.Back]: translation('Back'),
    [InteractionActionType.ScrollTo]: translation('ScrollTo'),
    [InteractionActionType.OpenLink]: translation('OpenLink'),
    [InteractionActionType.OpenOverlay]: translation('OpenOverlay'),
    [InteractionActionType.SwapOverlay]: translation('SwapOverlay'),
    [InteractionActionType.CloseOverlay]: translation('CloseOverlay'),
}
