import { ClogDomain, Environment, HttpPrefixKey } from '../types'

export const testingEnvironment: Environment = {
    isAbroad: false,
    isDev: false,
    googleClientId: 'none',
    isProduction: false,
    host: 'https://wukong.yuanfudao.biz',
    wssPrefix: 'wss://motiff-synergy.yuanfudao.biz/synergy',
    ossCdnPath: 'https://motiff-dev.fbcontent.cn/',
    publicResourcePrefix: 'app/public/resource',
    sentryDsn: 'https://daa074fcf66040c8bbe5c3908036a9ee@sentry-saas.motiff.cn/6733363',
    clogConfig: {
        site: 'yuanfudao',
        domain: ClogDomain.Biz,
        queryUrl: 'https://octopus.zhenguanyu.com/#/logs/search?env=test',
    },
    featureSwitchEnv: 'testing',
    httpPrefixes: {
        [HttpPrefixKey.COMMON_API]: 'https://wukong.yuanfudao.biz/wukong-api/api',
        [HttpPrefixKey.NOTIFY_API]: 'https://wukong.yuanfudao.biz/wukong-notify/api',
        [HttpPrefixKey.SYNERGY_CONNECTOR_API]: 'https://wukong.yuanfudao.biz/motiff-synergy-connector',
        [HttpPrefixKey.SYNERGY_ADMIN_API]: 'https://wukong.yuanfudao.biz/motiff-coordinator/admin',
        [HttpPrefixKey.ADMIN_API]: 'https://wukong.yuanfudao.biz/wukong-api-admin/api/',
        [HttpPrefixKey.INNER_API]: 'https://wukong.yuanfudao.biz/wukong-api/api',
        [HttpPrefixKey.BOLT_API]: 'https://bolt.yuanfudao.biz/bolt-pay-gateway/api',
        [HttpPrefixKey.AI_GEN_API]: 'https://motiff-ai-gen.yuanfudao.biz/api',
    },
    availableHostsForEmailLoginApi: [],
    uiPrefixCls: 'wukong-ui',
    figmaClientId: 'eHkRdGNNtr6k6b8m1s644M',
    figmaCallbackUrl: 'https://wukong.yuanfudao.biz/figma-oauth',
    mirrorBasePath: 'mirror',
    notifyWssPrefix: 'wss://wukong-notify.yuanfudao.biz/notify-connector',
    mirrorWssPrefix: 'wss://wukong.yuanfudao.biz/motiff-preview-connector/preview',
    fontPluginDaemonHost: 'https://daemon.motiff.com',
    fontPluginLocalHost: 'http://127.0.0.1',
    onboardingHelpDocUrl: {
        frame: 'https://motiff.cn/help/docs/articles/88036382480896',
        vector: 'https://motiff.cn/help/docs/articles/87711961443072',
        comment: 'https://motiff.cn/help/docs/articles/87712087272192',
        share: 'https://motiff.cn/help/docs/sections/87711902722816',
        coator: 'https://motiff.cn/help/docs/articles/99569364184328',
        library: 'https://motiff.cn/help/docs/articles/100783506244352',
        prototype: 'https://motiff.cn/help/docs/sections/232640631466294',
        device: 'https://motiff.cn/help/docs/sections/232662316033281',
        devModeStart: 'https://motiff.cn/help/docs/sections/223860030368261#0-设置代码',
        devmodeInspect: 'https://motiff.cn/help/docs/sections/223860030368260#4-查看标注',
        devmodeExport: 'https://motiff.cn/help/docs/sections/223860030368260#6-导出',
        variable: 'https://wukong.yuanfudao.biz/help/docs/articles/305390366273793',
    },
    helpDocsUrl: {
        home: 'https://wukong.yuanfudao.biz/help/docs',
        color: 'https://motiff.cn/help/docs/sections/114851546582028',
        image: 'https://motiff.cn/help/docs/articles/87711974025984',
        component: 'https://motiff.cn/help/docs/articles/87712032746240',
        webgl: 'https://motiff.cn/help/others/99991877417216',
        aiCredit: 'https://motiff.cn/help/docs/sections/232982865729549',
        quota: 'https://motiff.cn/help/others/266640468297220',
        ssoPath: '/help/docs/sections/267344884918528',
        stateManagement: '',
        motiffAPI: 'https://motiff.cn/help/others/269261270998789',
        variable: 'https://wukong.yuanfudao.biz/help/docs/articles/305390366273796',
        organizationGuest: 'https://motiff.cn/help/docs/sections/151470896999680',
    },
}
