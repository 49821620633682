import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import { LocalStorageKey, LocalStorageKey2Schema } from '../config'
import { enhancedLocalStorage } from '../storage'
import { LocalStorageSupportKey } from '../types'

/**
 * 用于从 localStorage 获取 value 的 state，在 state 变更时更新 store
 * @param key
 * @param defalutValue
 */
export const useLocalStorageState = <T extends LocalStorageKey>(
    key: LocalStorageSupportKey<T>,
    defalutValue: LocalStorageKey2Schema[T]
): [LocalStorageKey2Schema[T], Dispatch<SetStateAction<LocalStorageKey2Schema[T]>>, () => void] => {
    const [state, setState] = useState<LocalStorageKey2Schema[T]>(() =>
        enhancedLocalStorage.getSerializedItem<T>(key, defalutValue)
    )

    // 更新的显示状态并同步 LocalStorage
    const updateStateAndSyncLocalStorage = (action: SetStateAction<LocalStorageKey2Schema[T]>) => {
        const newValue =
            typeof action === 'function'
                ? (action as (prevState: LocalStorageKey2Schema[T]) => LocalStorageKey2Schema[T])(state)
                : action

        setState(newValue)
        enhancedLocalStorage.setSerializedItem(key, newValue)
    }

    // 传入的 key 可能在业务方发生变更，此时需要重新获取一次值
    useUpdateEffect(
        () => {
            setState(enhancedLocalStorage.getSerializedItem<T>(key, defalutValue))
        },
        typeof key === 'string' ? [key] : [key.key, key.suffix]
    )

    // 提供给业务手动保存到 storage 的能力
    const saveToLocalStore = useCallback(() => {
        enhancedLocalStorage.setSerializedItem(key, state)
    }, [key, state])

    return [state, updateStateAndSyncLocalStorage, saveToLocalStore]
}

/**
 * 用于从 localStorage 获取 value 的 state，需要手动触发 Store 的数据更新
 * @param key
 * @param defalutValue
 */
export const useManualLocalStorageState = <T extends LocalStorageKey>(
    key: LocalStorageSupportKey<T>,
    defalutValue: LocalStorageKey2Schema[T]
): [LocalStorageKey2Schema[T], Dispatch<SetStateAction<LocalStorageKey2Schema[T]>>, () => void] => {
    const [state, setState] = useState<LocalStorageKey2Schema[T]>(() =>
        enhancedLocalStorage.getSerializedItem<T>(key, defalutValue)
    )

    // 传入的 key 可能在业务方发生变更，此时需要重新获取一次值
    useUpdateEffect(
        () => {
            setState(enhancedLocalStorage.getSerializedItem<T>(key, defalutValue))
        },
        typeof key === 'string' ? [key] : [key.key, key.suffix]
    )

    // 提供给业务手动保存到 storage 的能力
    const saveToLocalStore = () => {
        enhancedLocalStorage.setSerializedItem(key, state)
    }

    return [state, setState, saveToLocalStore]
}
