import classnames from 'classnames'
import type { HTMLAttributes } from 'react'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { MonoIconControlMove12 } from '../../icons-v2'
import { findDatasetPropertyInPath } from '../../utils/utils'
import classes from './simple-drag.module.less'

interface BlackLineProps {
    state: 'hidden' | 'readying' | 'visible'
    y: number
    direction: 'bottom' | 'top'
    itemIndex: number
}

interface SimpleDragContextProps {
    blackLineState: BlackLineProps['state']
    selectedIndexList: number[]
    mousedown2readyDrag: (clientX: number, clientY: number) => void
    mousedown2changeSelect: (itemIndex: number, e: React.MouseEvent) => void
    mousemove2changeBlankLine: (itemIndex: number, itemRect: DOMRect, clientX: number, clientY: number) => void
}

const SimpleDragContext = createContext<SimpleDragContextProps>({
    blackLineState: 'hidden',
    selectedIndexList: [],
    mousedown2readyDrag: () => {},
    mousedown2changeSelect: () => {},
    mousemove2changeBlankLine: () => {},
})

export interface SimpleDragProps<T> extends HTMLAttributes<HTMLDivElement> {
    selectedIndexList: number[]
    items: T[]
    disabled?: boolean
    onDragDone?: (items: T[], selectedIndexList: number[]) => void // selectedIndexList: 拖拽后选中项在该item中的新位置
    onSelectChange?: (indexList: number[], e: React.MouseEvent) => void
    dataTestIds?: {
        simpleDrag?: string
    }
}

/**
 * @description 处理没有key(id)的列表项的拖拽;
 * @description data-simple-drag-ignore(dom 上添加) 来忽略一些区域的鼠标按下选择效果
 */
function SimpleDrag<T = any>(props: SimpleDragProps<T>) {
    const { selectedIndexList, items, disabled, onDragDone, onSelectChange, dataTestIds, className, ...otherProps } =
        props
    const [blackLine, setBlackLine] = useState<BlackLineProps>({
        state: 'hidden',
        y: 0,
        direction: 'bottom',
        itemIndex: 0,
    })
    const divRef = useRef<HTMLDivElement>(null)
    const prevInteractiveRef = useRef<number>() // 用于shift选中时的范围确定
    const dragStartPointRef = useRef<{ clientX: number; clientY: number }>({ clientX: 0, clientY: 0 })
    const hasMovedRef = useRef<boolean>(false)

    if (selectedIndexList.length === 1) {
        prevInteractiveRef.current = selectedIndexList[0]
    }

    const blackLineStyle = useMemo(() => {
        const lineHeight = 2
        const top = blackLine.y - lineHeight / 2
        const containerTop = divRef.current?.getBoundingClientRect().top ?? top
        return { top: top - containerTop, height: lineHeight }
    }, [blackLine.y])

    const mousedown2readyDrag = useCallback((clientX: number, clientY: number) => {
        dragStartPointRef.current = { clientX, clientY }
        hasMovedRef.current = false
        setBlackLine({ state: 'readying', y: 0, direction: 'bottom', itemIndex: 0 })
    }, [])

    const mousedown2changeSelect = useCallback(
        (itemIndex: number, e: React.MouseEvent) => {
            const { shiftKey, metaKey } = e
            if (disabled) return
            let selectList: number[] = []
            if (metaKey && !shiftKey) {
                if (selectedIndexList.includes(itemIndex)) {
                    selectList = selectedIndexList.filter((v) => v !== itemIndex)
                } else {
                    selectList = [...selectedIndexList, itemIndex]
                    prevInteractiveRef.current = itemIndex
                }
            } else if (shiftKey && !metaKey) {
                const preItemIndex = selectedIndexList.includes(prevInteractiveRef.current ?? NaN)
                    ? prevInteractiveRef.current ?? itemIndex
                    : itemIndex
                const min = Math.min(preItemIndex, itemIndex)
                const max = Math.max(preItemIndex, itemIndex)
                for (let i = min; i <= max; i++) {
                    selectList.push(i)
                }
            } else {
                selectList = [itemIndex]
                prevInteractiveRef.current = itemIndex
            }
            onSelectChange?.(selectList, e)
        },
        [disabled, onSelectChange, selectedIndexList]
    )

    const mousemove2changeBlankLine = useCallback(
        (itemIndex: number, itemRect: DOMRect, clientX: number, clientY: number) => {
            if (!hasMovedRef.current) {
                hasMovedRef.current =
                    Math.max(
                        Math.abs(clientX - dragStartPointRef.current.clientX),
                        Math.abs(clientY - dragStartPointRef.current.clientY)
                    ) >= 4
            }
            if (!hasMovedRef.current) {
                return
            }
            const { top, height, bottom } = itemRect
            const isMove2ItemBottom = clientY - top > height / 2
            if (isMove2ItemBottom) {
                setBlackLine({ state: 'visible', y: bottom, direction: 'bottom', itemIndex })
            } else {
                setBlackLine({ state: 'visible', y: top, direction: 'top', itemIndex })
            }
        },
        []
    )

    const _onDragDone = useCallback(() => {
        setBlackLine({ state: 'hidden', y: 0, direction: 'bottom', itemIndex: 0 })
        if (disabled || blackLine.state !== 'visible') {
            return
        }
        const move2index = blackLine.direction === 'top' ? blackLine.itemIndex : blackLine.itemIndex + 1
        const objectItems = items.map((v) => ({ item: v }))
        const willMoveObjectItemList: { item: T }[] = []
        const prevInteractiveObjectItem = objectItems[prevInteractiveRef.current ?? -1]
        let finalObjectItems = objectItems.map((item, index) => {
            if (selectedIndexList.includes(index)) {
                willMoveObjectItemList.push(item)
                return null
            } else {
                return item
            }
        })
        finalObjectItems.splice(move2index, 0, ...willMoveObjectItemList)
        finalObjectItems = finalObjectItems.filter((item) => item !== null)
        // 找到拖拽后先前交互的那个itemIndex
        prevInteractiveRef.current = finalObjectItems.findIndex((v) => v === prevInteractiveObjectItem)
        const finalItems = finalObjectItems.map((v) => v!.item)
        const selectIndexInFinalItems = willMoveObjectItemList.map((v1) =>
            finalObjectItems.findIndex((v2) => v1 === v2)
        )
        onDragDone?.(finalItems, selectIndexInFinalItems)
    }, [blackLine.direction, blackLine.itemIndex, blackLine.state, disabled, items, onDragDone, selectedIndexList])

    useEffect(() => {
        window.addEventListener('mouseup', _onDragDone, false)
        return () => {
            window.removeEventListener('mouseup', _onDragDone)
        }
    }, [_onDragDone])

    return (
        <SimpleDragContext.Provider
            value={{
                blackLineState: blackLine.state,
                selectedIndexList,
                mousedown2readyDrag,
                mousedown2changeSelect,
                mousemove2changeBlankLine,
            }}
        >
            <div
                className={classnames(
                    classes.simpleDrag,
                    {
                        [classes.disabled]: disabled,
                        [classes.isDragging]: blackLine.state === 'visible',
                    },
                    className
                )}
                ref={divRef}
                data-testid={dataTestIds?.simpleDrag}
                {...otherProps}
            >
                {blackLine.state === 'visible' ? (
                    <div className={classnames(classes.blackLine)} style={blackLineStyle}></div>
                ) : null}
                {props.children}
            </div>
        </SimpleDragContext.Provider>
    )
}

interface SimpleDragItemProps {
    itemIndex: number // 把item 在 数组中的 index当做id去处理
    disabledDragging?: boolean // 隐藏icon，禁用掉拖拽逻辑
    className?: string
    selectClassName?: string
    dragIconClassName?: string
    fullAreaInteractive?: boolean // 是否可以全区域相应拖拽
    dataTestIds?: {
        item?: string
        dragIcon?: string
    }
    tabIndex?: number
    onMouseEnter?: () => void
    onMouseLeave?: () => void
    onContextMenu?: () => void
    onMouseDown?: (e: React.MouseEvent) => void
    onKeyDown?: (e: React.KeyboardEvent) => void
    onDragIconMouseDown?: (e: React.MouseEvent) => void
}

function SimpleDragItem(props: React.PropsWithChildren<SimpleDragItemProps>) {
    const {
        className,
        selectClassName,
        itemIndex,
        fullAreaInteractive,
        dragIconClassName,
        disabledDragging,
        tabIndex,
        onMouseEnter,
        onMouseLeave,
        onContextMenu,
        onMouseDown,
        onKeyDown,
        onDragIconMouseDown,
    } = props
    const {
        blackLineState,
        selectedIndexList,
        mousedown2changeSelect,
        mousedown2readyDrag,
        mousemove2changeBlankLine,
    } = useContext(SimpleDragContext)
    const downRedirect2clickRef = useRef<boolean>(false)

    const onClickDownDragIcon = useCallback(
        (e: React.MouseEvent) => {
            if (downRedirect2clickRef.current) {
                mousedown2changeSelect?.(itemIndex, e)
            }
        },
        [itemIndex, mousedown2changeSelect]
    )

    const handleMouseDownDragIcon = useCallback(
        (e: React.MouseEvent) => {
            onDragIconMouseDown?.(e)
            // 在选中的节点上拖拽按钮上按下，可能是要去拖拽，因此不应该去取消别的选中的节点
            downRedirect2clickRef.current = false
            if (selectedIndexList.includes(itemIndex)) {
                downRedirect2clickRef.current = true
                e.stopPropagation()
            }
            mousedown2readyDrag(e.clientX, e.clientY)
        },
        [itemIndex, mousedown2readyDrag, onDragIconMouseDown, selectedIndexList]
    )

    const handleMouseDown = useCallback(
        (e: React.MouseEvent) => {
            onMouseDown?.(e)
            const simpleDragIgnoreFlags = findDatasetPropertyInPath(
                e.target as HTMLElement,
                e.currentTarget as HTMLElement,
                'simpleDragIgnore'
            )
            if (simpleDragIgnoreFlags.includes('true')) {
                return
            }
            mousedown2changeSelect?.(itemIndex, e)
        },
        [itemIndex, mousedown2changeSelect, onMouseDown]
    )

    const onMouseMove = useCallback(
        (e: React.MouseEvent) => {
            if (blackLineState === 'hidden') {
                return
            }
            mousemove2changeBlankLine(itemIndex, e.currentTarget.getBoundingClientRect(), e.clientX, e.clientY)
        },
        [blackLineState, mousemove2changeBlankLine, itemIndex]
    )

    return (
        <div
            tabIndex={tabIndex}
            onMouseDown={handleMouseDown}
            onMouseMove={onMouseMove}
            className={classnames(classes.simpleDragItem, className, {
                [selectClassName ?? '']: selectedIndexList.includes(itemIndex),
            })}
            data-testid={props.dataTestIds?.item}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onContextMenu={onContextMenu}
            onKeyDown={onKeyDown}
        >
            {fullAreaInteractive ? (
                <>
                    <div onMouseDown={handleMouseDownDragIcon} onClick={onClickDownDragIcon}>
                        <div className={classes.dragHandleContainer}>
                            <span
                                className={classnames(classes.dragHandleIcon, dragIconClassName)}
                                data-testid={props.dataTestIds?.dragIcon}
                            >
                                <MonoIconControlMove12 />
                            </span>
                        </div>
                        {props.children}
                    </div>
                </>
            ) : (
                <>
                    {!disabledDragging && (
                        <div className={classes.dragHandleContainer}>
                            <span
                                onMouseDown={handleMouseDownDragIcon}
                                onClick={onClickDownDragIcon}
                                className={classnames(classes.dragHandleIcon, dragIconClassName)}
                                data-testid={props.dataTestIds?.dragIcon}
                            >
                                <MonoIconControlMove12 />
                            </span>
                        </div>
                    )}
                    {props.children}
                </>
            )}
        </div>
    )
}

SimpleDrag.Item = SimpleDragItem

export { SimpleDrag }
