import { SpaceNotifyFlow } from './notify-flow'
import { FavoriteDocsStore, getSpaceStore, setSpaceStore, SpaceStoreType } from './types'
import { featureSwitchManager } from '../../../kernel/switch'
import {
    GetFavoritePreferenceRequest,
    GetUserFavoriteDocRequest,
    GetUserFavoritePrototypeRequest,
    UpdateFavoritePreferenceRequest,
} from '../../../kernel/request/favorite'
import { FlatDoc } from '../../util/types'
import { DocPrototypeVO } from '../../../kernel/interface/type'
import { UpdateDocRequest } from '../../../kernel/request/document'
import { throttle } from 'lodash-es'

function transformDocPrototypeVO2FlatDoc(
    docPrototypeVO: Readonly<DocPrototypeVO>,
    properties?: Partial<FlatDoc>
): FlatDoc {
    return {
        // docPrototypeVO 属性
        ...docPrototypeVO.document,
        id: docPrototypeVO.id, // 把docId换成原型文件的id保持id的唯一性
        favorites: docPrototypeVO.isFavorite,
        thumbnailUrl: docPrototypeVO.thumbnailUrl,
        createdTime: docPrototypeVO.createdTime,
        canvasBackgroundColor: docPrototypeVO.canvasBackgroundColor,
        name: docPrototypeVO.pageName
            ? `${docPrototypeVO.document.name}-${docPrototypeVO.pageName}`
            : docPrototypeVO.document.name,
        // 其他
        prototype: docPrototypeVO,
        ...properties,
    }
}

export const generateFavoriteDocsState = (set: setSpaceStore, get: getSpaceStore): FavoriteDocsStore => ({
    docs: [],
    loading: true,
    changeName: (docId: string, name: string) => {
        const oldName = get().favoriteDocsStore.docs.find((o) => o.id === docId)?.name
        if (!oldName) {
            return
        }
        if (oldName === name) {
            return
        }
        set((state) => {
            const doc = state.favoriteDocsStore.docs.find((o) => o.id === docId)
            if (doc) {
                doc.name = name
            }
        })
        new UpdateDocRequest(docId, { name }).start().catch(() => {
            set((state) => {
                const doc = state.favoriteDocsStore.docs.find((o) => o.id === docId)
                if (doc) {
                    doc.name = oldName
                }
            })
        })
    },
    changeOrder(targetId, currentId, direction) {
        // currentId 表示当前文档的id
        // targetId 表示目标文档的id
        // direction 表示目标文档在当前文档的上方还是下方
        const docs = get().favoriteDocsStore.docs
        const currentDoc = docs.find((o) => o.id === currentId)
        if (!currentDoc) {
            return
        }
        const docsWithoutCurrent = docs.filter((o) => o.id !== currentId)
        const targetIndex = docsWithoutCurrent.findIndex((o) => o.id === targetId)
        if (targetIndex === -1) {
            return
        }
        const newDocs = [...docsWithoutCurrent]
        if (direction === 'up') {
            newDocs.splice(targetIndex, 0, currentDoc)
        } else {
            newDocs.splice(targetIndex + 1, 0, currentDoc)
        }
        set((state) => {
            state.favoriteDocsStore.docs = newDocs
        })
        new UpdateFavoritePreferenceRequest({
            orgId: get().organizationStore.organization.id,
            list: newDocs.map((o) => ({
                id: o.id,
                type: o.prototype ? 'DOC_PROTOTYPE' : 'DOC',
            })),
        }).start()
    },
    fetch: async () => {
        if (featureSwitchManager.isEnabled('organization-plus')) {
            const { id: orgId } = get().organizationStore.organization
            set((state) => {
                state.favoriteDocsStore.loading = true
            })
            const preference = await new GetFavoritePreferenceRequest(orgId).start()
            const designFileRequest = new GetUserFavoriteDocRequest(orgId).start().then((list) =>
                list.map((o) => ({
                    ...o.document,
                    favoriteTime: o.favoriteTime,
                }))
            )
            const prototypeFileRequest = new GetUserFavoritePrototypeRequest(orgId)
                .start()
                .then((prototypeFileList) =>
                    prototypeFileList.map(({ favoriteTime, prototype }) =>
                        transformDocPrototypeVO2FlatDoc(prototype, { favoriteTime })
                    )
                )
            const docs = await Promise.all([designFileRequest, prototypeFileRequest.catch(() => [])]).then((files) =>
                files.flat()
            )
            const sortedDocs = docs.sort((a, b) => {
                const aIndex = preference.findIndex((o) => o.id === a.id)
                const bIndex = preference.findIndex((o) => o.id === b.id)
                return aIndex - bIndex
            })
            set((state) => {
                state.favoriteDocsStore.docs = sortedDocs
                state.favoriteDocsStore.loading = false
            })
        }
    },
})

export const syncFavoriteDocsState = (store: SpaceStoreType, notifyFlow: SpaceNotifyFlow) => {
    // 批量收藏的时候，协同会发 N 次消息，所以需要单独处理一下
    const debounceFetch = throttle(() => {
        store.getState().favoriteDocsStore.fetch()
    }, 500)
    notifyFlow.addFavoriteDocChangeCallback(() => {
        debounceFetch()
    })
    notifyFlow.addPrototypeFavoriteChangeCallback(() => {
        debounceFetch()
    })
    notifyFlow.addDocNameChangeCallback((msg) => {
        store.getState().favoriteDocsStore.changeName(msg.id, msg.name)
    })
    notifyFlow.addDocRelationChangeCallback(() => {
        store.getState().favoriteDocsStore.fetch()
    })
    notifyFlow.addPrototypeRelationChangeCallback(() => {
        debounceFetch()
    })
}
