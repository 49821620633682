import classNames from 'classnames'
import { RadioGroupProvider, WKRadioGroupContextProps } from './use-radio-group-context'

export interface WKRadioGroupProps extends WKRadioGroupContextProps {
    /**
     * @defaultValue 'horizontal'
     */
    direction?: 'horizontal' | 'vertical'
    verticalGap?: 8 | 16
    children: React.ReactNode
}

const styles = {
    vertical: 'flex flex-col',
    verticalGap: {
        8: 'gap-8px',
        16: 'gap-16px',
    },
    horizontal: 'flex flex-row gap-x-4 flex-wrap',
}
export const WKRadioGroup = (props: WKRadioGroupProps) => {
    const { direction = 'horizontal', verticalGap, children, ...otherProps } = props
    const rootClassNames = classNames(
        styles[direction],
        direction === 'vertical' && styles.verticalGap[verticalGap ?? 8]
    )

    return (
        <RadioGroupProvider {...otherProps}>
            <div className={rootClassNames}>{children}</div>
        </RadioGroupProvider>
    )
}
