/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { FC, useMemo } from 'react'
import { DevModeLayerPanelExpand } from '../render-item-expand'
import styles from './index.module.less'
import VLayerPanelNodeExpandStauts = Wukong.DocumentProto.VLayerPanelNodeExpandStauts

import { NodeId } from '../../../../../../document/node/node'

interface DevModeLayerPanelIndentProps {
    indent: number
    id: NodeId
    expandStatus: VLayerPanelNodeExpandStauts
    isAsset: boolean
}

export const DevModeLayerPanelIndent: FC<DevModeLayerPanelIndentProps> = ({ indent, id, expandStatus, isAsset }) => {
    const indentItemLength = Math.max(0, indent + (isAsset ? 1 : 0))

    return useMemo(
        () => (
            <>
                {/* 渲染占位 */}
                {Array.from({ length: indentItemLength })
                    .fill('')
                    .map((_, index) => (
                        <div
                            key={index}
                            className={classNames(styles.renderItemIndent, {
                                [styles.renderItemIndentLarge]: index !== 0,
                            })}
                            data-testid="dev-mode-layer-panel-indent"
                        />
                    ))}
                {isAsset ? null : (
                    // 渲染 expand
                    <div
                        className={classNames(styles.renderItemExpand, {
                            [styles.renderItemExpandLarge]: indent !== 0,
                        })}
                        data-testid="dev-mode-layer-panel-expand-wrapper"
                    >
                        <DevModeLayerPanelExpand id={id} expandStatus={expandStatus} />
                    </div>
                )}
            </>
        ),
        [indent, id, expandStatus, isAsset, indentItemLength]
    )
}
