/* eslint-disable no-restricted-imports */
import { environment } from '../../environment'
import { isSafari, isWindows } from '../../kernel/util/ua'

export const DEFAULT_FONT_FILE_BASE_PATH = `${environment.ossCdnPath}${environment.publicResourcePrefix}/font/default/`
export const FALLBACK_FONT_FILE_BASE_PATH = `${environment.ossCdnPath}${environment.publicResourcePrefix}/font/fallback/`

/**
 * 默认字体（Inter 为可变字体）
 */
const DEFAULT_FONT_FILES_VF = ['Inter-VF.v3.019.ttf'].map((name) => DEFAULT_FONT_FILE_BASE_PATH + name)

export const DEFAULT_FONT_FILES = () => {
    return DEFAULT_FONT_FILES_VF
}

export const FALLBACK_FONT_FILE_MAP = Object.fromEntries(
    Object.entries({
        'Motiff Emoji': 'MotiffEmoji-Regular.2.01.ttf',
        'Noto Sans': 'NotoSans-VF.ttf',
        'Noto Sans Arabic': 'NotoSansArabic-VF.ttf',
        'Noto Sans Bengali': 'NotoSansBengali-VF.ttf',
        'Noto Sans Devanagari': 'NotoSansDevanagari-VF.ttf',
        'Noto Sans Georgian': 'NotoSansGeorgian-VF.ttf',
        'Noto Sans Gujarati': 'NotoSansGujarati-VF.ttf',
        'Noto Sans Hebrew': 'NotoSansHebrew-VF.ttf',
        'Noto Sans HK': 'NotoSansHK-VF.ttf',
        'Noto Sans Javanese': 'NotoSansJavanese-VF.ttf',
        'Noto Sans JP': 'NotoSansJP-VF.ttf',
        'Noto Sans Kannada': 'NotoSansKannada-VF.ttf',
        'Noto Sans Khmer': 'NotoSansKhmer-VF.ttf',
        'Noto Sans KR': 'NotoSansKR-VF.ttf',
        'Noto Sans Lao': 'NotoSansLao-VF.ttf',
        'Noto Sans Malayalam': 'NotoSansMalayalam-VF.ttf',
        'Noto Sans Math': 'NotoSansMath-Regular.ttf',
        'Noto Sans New Tai Lue': 'NotoSansNewTaiLue-VF.ttf',
        'Noto Sans SC': 'NotoSansSC-VF.ttf',
        'Noto Sans Symbols': 'NotoSansSymbols-VF.ttf',
        'Noto Sans Symbols 2': 'NotoSansSymbols2-Regular.ttf',
        'Noto Sans Tamil': 'NotoSansTamil-VF.ttf',
        'Noto Sans Tai Le': 'NotoSansTaiLe-Regular.ttf',
        'Noto Sans Tai Tham': 'NotoSansTaiTham-VF.ttf',
        'Noto Sans Tai Viet': 'NotoSansTaiViet-Regular.ttf',
        'Noto Sans Telugu': 'NotoSansTelugu-VF.ttf',
        'Noto Sans Thai': 'NotoSansThai-VF.ttf',
    }).map(([key, value]) => [key, FALLBACK_FONT_FILE_BASE_PATH + value])
)

/**
 * Safari中无法访问http
 * 默认字体助手端口号 38474
 * electron字体助手端口号 38475
 */
export const LOCAL_FONT_LIST_SERVER_BASE_URL = isSafari()
    ? `${environment.fontPluginDaemonHost}:38574/`
    : `${environment.fontPluginLocalHost}:${window.localBridge ? 38475 : 38474}/`

/**
 * TODO (jiangzg): 端口动态检测
 * 本地字体文件列表接口
 */
export const LOCAL_FONT_LIST_SERVER_URL_V1 = `${LOCAL_FONT_LIST_SERVER_BASE_URL}list`
export const LOCAL_FONT_LIST_SERVER_URL_V2 = `${LOCAL_FONT_LIST_SERVER_BASE_URL}font-list`
export const LOCAL_FONT_LIST_SERVER_URL = () => {
    return LOCAL_FONT_LIST_SERVER_URL_V2
}

/**
 * 本地字体文件下载接口
 */
export const LOCAL_FONT_FILE_SERVER_URL = `${LOCAL_FONT_LIST_SERVER_BASE_URL}file`

/**
 * mac本地插件安装包下载地址
 */
export const LOCAL_FONT_PLUGIN_MAC_URL = `${environment.ossCdnPath}${environment.publicResourcePrefix}/font/plugin/MotiffFontAgent-macos-installer-x64-1.2.0.pkg`

/**
 * windows本地插件安装包下载地址
 */
export const LOCAL_FONT_PLUGIN_WINDOWS_URL = `${environment.ossCdnPath}${environment.publicResourcePrefix}/font/plugin/MotiffFontAgent-installer-0.7.0.exe`

/**
 * 本地插件安装包下载地址
 */
export const LOCAL_FONT_PLUGIN_URL = isWindows() ? LOCAL_FONT_PLUGIN_WINDOWS_URL : LOCAL_FONT_PLUGIN_MAC_URL
