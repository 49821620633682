import { TraceableAbortController, TraceableAbortSignal } from './traceable-abort-controller'

/**
 * Takes an array of AbortSignals and returns a single signal.
 * If any signals are aborted, the returned signal will be aborted.
 */
export function anySignal(signals: Array<TraceableAbortSignal>): TraceableAbortSignal {
    const name = signals.map((signal) => signal.name).join('-')

    const controller = new TraceableAbortController(name)

    function onAbort(reason?: string): void {
        controller.abort(reason ?? '')
    }

    for (const signal of signals) {
        if (signal.aborted) {
            onAbort(signal.reason)
            break
        }

        if (signal?.addEventListener != null) {
            signal.addEventListener(
                'abort',
                (event) => {
                    const target = event.target as TraceableAbortSignal
                    onAbort(target.reason)
                },
                { signal: controller.signal }
            )
        }
    }

    return controller.signal
}
