import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    HowToUseThisComponent: '如何使用这个组件',
    Desc: '描述',
    ComponentSet: '变体组件',
    SelectedVariant: '选中的变体',
    SimplifyAllInstances: '简化实例',
    ComponentConfig: '组件设置',
} as const

export const enTranslation = {
    HowToUseThisComponent: 'How to use this component',
    Desc: 'Description',
    ComponentSet: 'Component set',
    SelectedVariant: 'Selected variant',
    SimplifyAllInstances: 'Simplify all instances',
    ComponentConfig: 'Component configuration',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
