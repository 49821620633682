import type { WebGLRenderingContextCompat } from '../../../../util/src'

export const enum CanvasStateType {
    Null,
    WebGL,
    WebGPU,
}

export interface WebGLCanvasState {
    type: CanvasStateType.WebGL
    canvas: HTMLCanvasElement
    handle: number
    version: number
    context: WebGLRenderingContextCompat
    findTexture: (textureHandle: number) => WebGLTexture | null
    findBuffer: (bufferHandle: number) => WebGLBuffer | null
}

export interface WebGPUCanvasState {
    type: CanvasStateType.WebGPU
    canvas: HTMLCanvasElement
    adapterId: number
    deviceId: number
    queueId: number
    device: GPUDevice | null
    findTexture: (textureHandle: number) => GPUTexture | null
    findBuffer: (bufferHandle: number) => GPUBuffer | null
}

export interface NullCanvasState {
    type: CanvasStateType.Null
    canvas: HTMLCanvasElement
}

export type CanvasState = NullCanvasState | WebGLCanvasState | WebGPUCanvasState
