import classnames from 'classnames'
import * as React from 'react'
import { useCallback, useRef } from 'react'
import { isComposing } from '../utils/is-composing'
import classes from './html-input.module.less'

export interface HtmlInputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

/**
 * @description 仅支持下鼠标的点击全选功能
 */
function _HtmlInput(props: HtmlInputProps, ref: React.Ref<HTMLInputElement>) {
    const { className, onFocus, onMouseDown, onMouseUp, onKeyDown, onCompositionEnd, ...otherProps } = props
    const clickSelectWhenFocus = useRef<'down' | 'focus'>()
    const compositionEndTimeRef = useRef<number>(0)

    const _onFocus = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            if (clickSelectWhenFocus.current === 'down') {
                clickSelectWhenFocus.current = 'focus'
            }
            onFocus?.(e)
        },
        [onFocus]
    )

    const _onMouseDown = useCallback(
        (e: React.MouseEvent<HTMLInputElement>) => {
            clickSelectWhenFocus.current = 'down'
            onMouseDown?.(e)
        },
        [onMouseDown]
    )

    const _onMouseUp = useCallback(
        (e: React.MouseEvent<HTMLInputElement>) => {
            const inputElement = e.target as HTMLInputElement
            if (inputElement.selectionStart === inputElement.selectionEnd && clickSelectWhenFocus.current === 'focus') {
                inputElement.focus()
                inputElement.select()
            }
            clickSelectWhenFocus.current = undefined
            onMouseUp?.(e)
        },
        [onMouseUp]
    )

    const _onKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (isComposing(e.nativeEvent, compositionEndTimeRef.current)) {
                e.stopPropagation()
                return
            }
            onKeyDown?.(e)
        },
        [onKeyDown]
    )

    const _onCompositionEnd = useCallback(
        (e: React.CompositionEvent<HTMLInputElement>) => {
            onCompositionEnd?.(e)
            compositionEndTimeRef.current = Date.now()
        },
        [onCompositionEnd]
    )

    return (
        <input
            ref={ref}
            spellCheck={false}
            className={classnames(classes.htmlInput, [className])}
            onFocus={_onFocus}
            onMouseDown={_onMouseDown}
            onMouseUp={_onMouseUp}
            onKeyDown={_onKeyDown}
            onCompositionEnd={_onCompositionEnd}
            {...otherProps}
        />
    )
}

export const HtmlInput = React.forwardRef(_HtmlInput)
