export enum CrashType {
    OUT_OF_MEMORY,
    OPERATION_CRASH,
    WEBGL_CONTEXT_LOST,
}

export const getCrashType = (error: Error, isContextLost: boolean): CrashType => {
    if (isContextLost) {
        return CrashType.WEBGL_CONTEXT_LOST
    }
    return error.message.startsWith('Aborted') ? CrashType.OUT_OF_MEMORY : CrashType.OPERATION_CRASH
}

export const CrashType2Label = {
    [CrashType.OUT_OF_MEMORY]: '内存溢出',
    [CrashType.OPERATION_CRASH]: '操作崩溃',
    [CrashType.WEBGL_CONTEXT_LOST]: 'webglcontextlost',
}
