import { Wukong } from '@wukong/bridge-proto'
import { useCallback, useMemo } from 'react'
import {
    BicolorIconPanelGradientFill16,
    MonoIconPanelPictureFill16,
    MonoIconPanelSolidFill16,
    Tooltip,
    WKRadioIconButton,
    WKRadioIconButtonGroup,
} from '../../../../../../../ui-lib/src'
import { BlendModeSelect } from '../../blend-mode/blend-mode-select'
import { ColorCustomToolsProps, PaintMode } from '../type'
import classes from './color-custom-tools.module.less'
import { translation } from './color-custom-tools.translation'

export function ColorCustomTools(props: ColorCustomToolsProps) {
    const { paint, hideImage, onChangePaintMode } = props

    const type = useMemo(() => {
        switch (paint.type) {
            case Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT:
                return PaintMode.COLOR
            case Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_LINEAR:
            case Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_RADIAL:
            case Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_ANGULAR:
            case Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_DIAMOND:
                return PaintMode.GRADIENT
            case Wukong.DocumentProto.PaintType.PAINT_TYPE_IMAGE_PAINT:
                return PaintMode.IMAGE
            default:
                return undefined
        }
    }, [paint])

    const onValueChange = useCallback(
        (v: string) => {
            if (v === type) {
                return
            }
            onChangePaintMode(v as PaintMode)
        },
        [onChangePaintMode, type]
    )

    return (
        <div className={classes.colorCustomTools} data-testid="color-custom-tools">
            <WKRadioIconButtonGroup
                value={type}
                size={28}
                onValueChange={onValueChange}
                activeClassName={classes.active}
            >
                <Tooltip title={translation('Color')} addContainer={{ style: { display: 'flex' } }}>
                    <WKRadioIconButton
                        value={PaintMode.COLOR}
                        icon={<MonoIconPanelSolidFill16 className={classes.icon} />}
                        dataTestId="color-custom-tools-color"
                        preventDefaultFocus
                    />
                </Tooltip>
                <Tooltip title={translation('Gradient')} addContainer={{ style: { display: 'flex' } }}>
                    <WKRadioIconButton
                        value={PaintMode.GRADIENT}
                        icon={<BicolorIconPanelGradientFill16 className={classes.icon} />}
                        dataTestId="color-custom-tools-gradient"
                        preventDefaultFocus
                    />
                </Tooltip>
                {hideImage ? null : (
                    <Tooltip title={translation('Image')} addContainer={{ style: { display: 'flex' } }}>
                        <WKRadioIconButton
                            value={PaintMode.IMAGE}
                            icon={<MonoIconPanelPictureFill16 className={classes.icon} />}
                            dataTestId="color-custom-tools-image"
                            preventDefaultFocus
                        />
                    </Tooltip>
                )}
            </WKRadioIconButtonGroup>
            <Tooltip title={translation('BlendMode')} addContainer={{}}>
                <BlendModeSelect
                    value={paint.blendMode ?? undefined}
                    onChange={props.onChangeBlendMode}
                    onlyIcon
                ></BlendModeSelect>
            </Tooltip>
        </div>
    )
}
