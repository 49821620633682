import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Unsplash: '图片资源库',
    SearchUnsplash: '输入关键词',
    Search: '搜索',
    InsertRandom: '插入随机图片',
    Random: '任意主题',
    Nature: '自然',
    SourcedFrom: '图片来源于',
    qwYbVv: '，使用本插件需访问  Unsplash 服务器',
    NoResults: '没有匹配的内容',
    ReachedUsageFrequencyLimit: '您已经达到使用频率限制，请稍后再试',
} as const

export const enTranslation = {
    Unsplash: 'Unsplash',
    SearchUnsplash: 'Search Unsplash',
    Search: 'Search',
    InsertRandom: 'Insert random',
    Random: 'random',
    Nature: 'nature',
    SourcedFrom: 'Sourced from',
    qwYbVv: '',
    NoResults: 'No results',
    ReachedUsageFrequencyLimit: 'You have reached the usage frequency limit, please try again later.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
