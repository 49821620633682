import { createRoot, Root } from 'react-dom/client'
import { createHTMLDivElement, generateKey } from '../../../../../ui-lib/src'

export type RootKey = string
export interface State {
    close: () => void
}
type Render<T> = (props: T, state: State) => JSX.Element
export class ReactRootRender<T> {
    private rootMap: Map<RootKey, { reactRoot: Root; htmlRoot: HTMLElement }> = new Map()
    private render: Render<T>

    constructor(render: Render<T>) {
        this.render = render
    }

    private generateRoot = () => {
        const htmlRoot = createHTMLDivElement({})
        const rootKey = generateKey()
        const reactRoot = createRoot(htmlRoot)
        return { reactRoot, htmlRoot, rootKey }
    }

    private rootRender = (props: T) => {
        const { reactRoot, htmlRoot, rootKey } = this.generateRoot()
        document.body.appendChild(htmlRoot)
        const state: State = {
            close: () => this.close(rootKey),
        }
        reactRoot.render(this.render(props, state))
        this.rootMap.set(rootKey, { reactRoot, htmlRoot })
        return rootKey
    }

    private closeAll() {
        for (const [rootKey] of this.rootMap) {
            this.close(rootKey)
        }
    }

    private close(rootKey: RootKey) {
        const root = this.rootMap.get(rootKey)
        if (root) {
            try {
                root.reactRoot.unmount()
                root.htmlRoot.remove()
            } catch (e) {}
            this.rootMap.delete(rootKey)
        }
    }

    public show = (props: T) => {
        this.closeAll()
        return this.rootRender(props)
    }
}
