import { timeout, interval } from 'signal-timers'
import { TraceableAbortController, TraceableAbortSignal } from './traceable-abort-controller'
import { anySignal } from './abort-any'

export { animationFrame as signalAnimationFrame, microtask as signalMicrotask } from 'signal-timers'

let timerId = 0

const timerId2ClearTimer = new Map<number, () => void>()

interface TimerOptions {
    signal?: TraceableAbortSignal
}

function getNewTimerId() {
    return ++timerId
}

function signalTimers(
    type: 'timeout' | 'interval',
    callback: (args: void) => void,
    ms: number,
    signal?: TraceableAbortSignal
): number {
    const id = getNewTimerId()

    const controller = new TraceableAbortController(`timeout-${timerId}`)

    if (type === 'timeout') {
        timeout(
            () => {
                callback()
                timerId2ClearTimer.delete(id)
            },
            ms,
            {
                signal: anySignal(signal ? [controller.signal, signal] : [controller.signal]),
            }
        )
    } else {
        interval(callback, ms, {
            signal: anySignal(signal ? [controller.signal, signal] : [controller.signal]),
        })
    }

    timerId2ClearTimer.set(id, () => {
        controller.abort(`clear timeout ${id}`)
        timerId2ClearTimer.delete(id)
    })

    return id
}

export function signalTimeout(callback: (args: void) => void, ms: number, options?: TimerOptions): number {
    return signalTimers('timeout', callback, ms, options?.signal)
}

export function signalInterval(callback: (args: void) => void, ms: number, options?: TimerOptions): number {
    return signalTimers('interval', callback, ms, options?.signal)
}

export function clearSignalTimer(id: number) {
    const clearTimer = timerId2ClearTimer.get(id)

    if (clearTimer) {
        clearTimer()
    }
}
