import classnames from 'classnames'
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import {
    DialogRef,
    Resize,
    Scrollbar,
    ScrollbarRef,
    Select,
    WKDialog,
    wkDialogConfirm,
    WKDialogProps,
    WKUserAvatar,
} from '../../../../ui-lib/src'
import { TagItem } from '../../../../ui-lib/src/components/inputs/tag-input/tag-input'
import { useAsyncRetry } from '../../../../util/src'

import { PluginUserMemberVO, PluginVO } from '../../kernel/interface/plugin'
import { UserBriefVO } from '../../kernel/interface/type'
import { PluginUserRoleEnum, pluginUserRoleLabel } from '../../kernel/interface/wukong-message-center'
import {
    GetPluginMembers,
    InvitePluginMembers,
    QueryPluginInviteMembers,
    RemovePluginMemberRole,
    UpdatePluginMemberRole,
} from '../../kernel/request/plugin-dev'
import { InviteMemberInputV2 } from '../invite-member-input'
import { UserBriefVOAndUnregisterVO } from '../invite-member-input/use-member-tag-input'
import classes from './plugin-permission.module.less'
import { translation } from './plugin-permission.translation'

export interface PluginPermissionDialogProps {
    plugin: PluginVO
    currentUserId: UserBriefVO['id']
    isAdmin: boolean
    onCancel: WKDialogProps['onCancel']
    onPluginLeave?: () => void
    onPluginOwnerChange?: () => void
}

enum PluginUserRoleOption {
    Owner = PluginUserRoleEnum.Owner,
    Editor = PluginUserRoleEnum.Editor,
    Remove = 'Remove',
    Leave = 'Leave',
}

export function PluginPermissionDialog(props: PluginPermissionDialogProps) {
    const { plugin, currentUserId, isAdmin, onCancel, onPluginLeave, onPluginOwnerChange } = props
    const [key, setKey] = useState(0) // 通过 key 来重置输入框
    const [autoHeightMax, setAutoHeightMax] = useState<number | undefined>()
    const [visible, setVisible] = useState(false)
    const [hideDialog, setHideDialog] = useState(false)
    const dialogRef = useRef<DialogRef>(null)
    const scrollbarRef = useRef<ScrollbarRef>(null)
    const pluginMembers = useAsyncRetry(async () => {
        const result = await new GetPluginMembers(plugin.id).start()
        return result
    }, [plugin.id])

    const isPluginOnwer = useMemo(() => {
        const onwer = pluginMembers.value?.userRoles.find((v) => v.pluginUserRole === PluginUserRoleEnum.Owner)
        return onwer?.userBrief.id === currentUserId
    }, [pluginMembers.value?.userRoles, currentUserId])

    const queryInviteMembers = useCallback(
        (query: string) => {
            return new QueryPluginInviteMembers(plugin.id, query).start().then((v) => v.userList)
        },
        [plugin.id]
    )

    const onInvite = useCallback(
        (user: UserBriefVOAndUnregisterVO[]) => {
            setKey((v) => v + 1)
            new InvitePluginMembers(plugin.id, {
                userEmails: user.map((o) => o.email),
                role: PluginUserRoleEnum.Editor,
            })
                .start()
                .then(() => {
                    pluginMembers.retry()
                })
        },
        [plugin.id, pluginMembers]
    )

    const onChangeRole = useCallback(
        (roleOption: PluginUserRoleOption, item: PluginUserMemberVO) => {
            switch (roleOption) {
                case PluginUserRoleOption.Owner:
                    setHideDialog(true)
                    wkDialogConfirm.show({
                        title: translation('OwnerConfirmTitle', {
                            pluginName: plugin.name,
                            userName: item.userBrief.nickname,
                        }),
                        content: translation('OwnerConfirmContent'),
                        okText: translation('TransferOwnership'),
                        okButtonProps: { danger: true },
                        wrapperTestId: 'plugin-role-change-confirm-dialog',
                        onClose: () => {
                            setHideDialog(false)
                        },
                        onOk: () => {
                            setHideDialog(false)
                            new UpdatePluginMemberRole(plugin.id, {
                                userId: String(item.userBrief.id),
                                newRole: PluginUserRoleEnum.Owner,
                            })
                                .start()
                                .then(() => {
                                    pluginMembers.retry()
                                    onPluginOwnerChange?.()
                                })
                        },
                    })
                    return
                case PluginUserRoleOption.Editor:
                    // 暂时无这个操作
                    return
                case PluginUserRoleOption.Remove:
                    setHideDialog(true)
                    wkDialogConfirm.show({
                        title: translation('RemoveConfirmTitle', {
                            pluginName: plugin.name,
                            userName: item.userBrief.nickname,
                        }),
                        content: translation('RemoveConfirmContent'),
                        okText: translation('Remove'),
                        okButtonProps: { danger: true },
                        wrapperTestId: 'plugin-role-change-confirm-dialog',
                        onClose: () => {
                            setHideDialog(false)
                        },
                        onOk: () => {
                            setHideDialog(false)
                            new RemovePluginMemberRole(plugin.id, {
                                userId: String(item.userBrief.id),
                                originRole: item.pluginUserRole,
                            })
                                .start()
                                .then(() => {
                                    pluginMembers.retry()
                                })
                        },
                    })
                    return
                case PluginUserRoleOption.Leave:
                    setHideDialog(true)
                    wkDialogConfirm.show({
                        title: translation('LeaveConfirmTitle', {
                            pluginName: plugin.name,
                        }),
                        content: translation('LeaveConfirmContent'),
                        okText: translation('Leave'),
                        okButtonProps: { danger: true },
                        wrapperTestId: 'plugin-role-change-confirm-dialog',
                        onClose: () => {
                            setHideDialog(false)
                        },
                        onOk: () => {
                            setHideDialog(false)
                            new RemovePluginMemberRole(plugin.id, {
                                userId: String(item.userBrief.id),
                                originRole: item.pluginUserRole,
                            })
                                .start()
                                .then(() => {
                                    pluginMembers.retry()
                                    onCancel?.()
                                    onPluginLeave?.()
                                })
                        },
                    })
                    return
            }
        },
        [onCancel, onPluginLeave, onPluginOwnerChange, plugin.id, plugin.name, pluginMembers]
    )

    const customGenerateTag = useCallback((v: TagItem<UserBriefVOAndUnregisterVO | string>) => {
        if (typeof v.customProps == 'string' || v.customProps?.unregistered) {
            v.color = 'red'
        }
        return v
    }, [])

    const calcAutoHeightMax = useCallback(() => {
        const containerElement = dialogRef.current?.getContainerElement()
        const scrollContainerElement = scrollbarRef.current?.getContainerElement()?.parentElement
        if (!containerElement || !scrollContainerElement) {
            return
        }
        const containerRect = containerElement.getBoundingClientRect()
        const scrollContainerReact = scrollContainerElement.getBoundingClientRect()
        const defaultDialogMaxHeight = 800
        const dialogCssMaxHeight = window.getComputedStyle(containerElement).maxHeight
        const dynamicDialogMaxHeight = window.innerHeight - containerRect.top * 2
        let finalDialogMaxHeight = dialogCssMaxHeight ? parseInt(dialogCssMaxHeight) : defaultDialogMaxHeight
        finalDialogMaxHeight = Math.min(finalDialogMaxHeight, dynamicDialogMaxHeight)

        const scrollMaxHeight =
            finalDialogMaxHeight -
            Math.max(scrollContainerReact.top - containerRect.top, 0) -
            Math.max(containerRect.bottom - scrollContainerReact.bottom, 0)
        const defaultScrollbarMaxHeight = 150
        setAutoHeightMax(Math.max(scrollMaxHeight, defaultScrollbarMaxHeight))
    }, [])

    useLayoutEffect(() => {
        if (visible) {
            calcAutoHeightMax()
        }
    }, [calcAutoHeightMax, visible])

    useEffect(() => {
        if (!pluginMembers.loading) {
            setVisible(true)
        }
    }, [pluginMembers.loading])

    return (
        <WKDialog
            ref={dialogRef}
            visible={visible}
            title={translation('ManagePermissions')}
            footer={null}
            testId="plugin-permission-dialog"
            onCancel={props.onCancel}
            width={464}
            bodyStyle={{ padding: '16px 0 0', position: 'relative' }}
            style={hideDialog ? { opacity: 0, pointerEvents: 'none' } : undefined}
        >
            <Resize onChangeResize={calcAutoHeightMax} connectWindowResize />
            <div className={classes.description} data-testid="plugin-permission-description">
                {translation('Yci')}
            </div>
            <InviteMemberInputV2
                key={key}
                className={classes.inviteMemberInput}
                requestFn={queryInviteMembers}
                onOk={onInvite}
                placeholder={translation('Ecs')}
                customGenerateTag={customGenerateTag}
                tool={
                    <span data-testid="plugin-permission-tool">{pluginUserRoleLabel[PluginUserRoleEnum.Editor]}</span>
                }
            />
            <Scrollbar autoHeight autoHeightMax={autoHeightMax} ref={scrollbarRef}>
                <div className={classes.memberList}>
                    {pluginMembers.value?.userRoles.map((v) => {
                        const isCurrentUser = v.userBrief.id === props.currentUserId
                        return (
                            <div key={v.userBrief.id} className={classes.memberItem} data-testid="plugin-member-item">
                                <div className={classes.memberItemLeft}>
                                    <WKUserAvatar
                                        className={classes.avatar}
                                        userInfo={v.userBrief}
                                        key={v.userBrief.id}
                                        size={24}
                                    />
                                    <div className={classes.nicknameContainer}>
                                        <span className={classes.nickname}>{v.userBrief.nickname}</span>
                                        <span className={classes.you}>{isCurrentUser ? translation('You') : ''}</span>
                                    </div>
                                </div>
                                {v.pluginUserRole === PluginUserRoleEnum.Owner ? (
                                    <div className={classnames(classes.memberItemRight, classes.ownerRole)}>
                                        {pluginUserRoleLabel[PluginUserRoleEnum.Owner]}
                                    </div>
                                ) : (
                                    <Select.MinimalSingleLevel
                                        placement="bottom right"
                                        className={classes.memberItemRight}
                                        label={pluginUserRoleLabel[v.pluginUserRole]}
                                        value={v.pluginUserRole}
                                        onChange={(opt) => onChangeRole(opt, v)}
                                        dataTestIds={{
                                            container: 'plugin-user-dropdown-container',
                                            triggerFocus: 'plugin-user-dropdown-trigger-focus',
                                        }}
                                    >
                                        {isPluginOnwer || isAdmin ? (
                                            <Select.MinimalSingleLevel.Option
                                                data-testid="plugin-owner-option"
                                                value={PluginUserRoleOption.Owner}
                                            >
                                                {pluginUserRoleLabel[PluginUserRoleEnum.Owner]}
                                            </Select.MinimalSingleLevel.Option>
                                        ) : null}
                                        <Select.MinimalSingleLevel.Option
                                            data-testid="plugin-owner-option"
                                            value={PluginUserRoleOption.Editor}
                                        >
                                            {pluginUserRoleLabel[PluginUserRoleEnum.Editor]}
                                        </Select.MinimalSingleLevel.Option>
                                        <Select.MinimalSingleLevel.Option
                                            data-testid="plugin-owner-option"
                                            value={
                                                isCurrentUser ? PluginUserRoleOption.Leave : PluginUserRoleOption.Remove
                                            }
                                            splitLineTop
                                        >
                                            {isCurrentUser ? translation('Leave') : translation('Remove')}
                                        </Select.MinimalSingleLevel.Option>
                                    </Select.MinimalSingleLevel>
                                )}
                            </div>
                        )
                    })}
                </div>
            </Scrollbar>
        </WKDialog>
    )
}
