import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    MissingFonts: '缺失字体',
    ReplaceFonts: '替换',
} as const

export const enTranslation = {
    MissingFonts: 'Missing fonts',
    ReplaceFonts: 'Replace fonts',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
