import { keepDecimal } from '../../../../../../../util/src'
import { CurrencyType, FeeUnitVO } from '../../../../../kernel/interface/type'
import { getMonthsByContractPeriod } from '../../../utils'

export function getPriceLabelFromExpense(feeUnitVO: FeeUnitVO | undefined): string {
    if (!feeUnitVO) {
        return '-'
    }
    switch (feeUnitVO.price.currency) {
        case CurrencyType.Usd:
            return `$${keepDecimal(
                feeUnitVO.price.valueInCent / 100.0 / getMonthsByContractPeriod(feeUnitVO.period),
                2
            )}`
        case CurrencyType.Cny:
            return `￥${keepDecimal(
                feeUnitVO.price.valueInCent / 100.0 / getMonthsByContractPeriod(feeUnitVO.period),
                2
            )}`
        default:
            return '-'
    }
}
