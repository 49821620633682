import { translation } from './view.translation'
/* eslint-disable no-restricted-imports */
import {
    DevModeToggleIconAutoDetection,
    DevModeZoomToFitSelectionWasmCall,
    PageDownCommandWasmCall,
    PageUpCommandWasmCall,
    ShowSliceCommand,
    SwitchEditorTypeCommand,
    ToggleEnabledLayoutGridWasmCall,
    ToggleEnablePixelGridWasmCall,
    ToggleShowCommentCommand,
    ToggleShowMultiplayerCursorWasmCall,
    ToggleShowRulerWasmCall,
    ToggleShowSidebarCommandForJs,
    ToggleShowUsedMemory,
    Wukong,
} from '@wukong/bridge-proto'
import { WKItemType, WKMenuConfig } from '../../../../../../../ui-lib/src'
import { isEnglishLanguage } from '../../../../../../../util/src'
import { CommandInvoker } from '../../../../../document/command/command-invoker'
import {
    cmdViewNextFrame,
    cmdViewPrevFrame,
    cmdZoomToNextFrame,
    cmdZoomToPrevFrame,
} from '../../../../../document/command/frame-command'
import { cmdToggleFullscreen } from '../../../../../document/command/fullscreen-command'
import {
    cmdZoomAtCameraCenter,
    cmdZoomInByLevelAtCameraCenter,
    cmdZoomOutByLevelAtCameraCenter,
    cmdZoomToAll,
    cmdZoomToSelection,
} from '../../../../../document/command/viewport-command'
import { useFeatureSwitch } from '../../../../../kernel/switch/hooks'
import { useViewState } from '../../../../../view-state-bridge'
import { LocalStorageKey } from '../../../../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../../../../web-storage/local-storage/storage'
import { logs, SwitchModeWay } from '../../../dev-mode/logs'
import {
    generateSubMenuConfigUnderSelectionSort,
    generateSubMenuConfigUnderSelectionView,
    generateSubMenuConfigWithActivity,
} from './util'
import { ShortcutKey, shortcutLabelMap } from '../../../../../kernel/interface/shortcut-key'

export const useDesignModeMenuView = (command: CommandInvoker): WKMenuConfig => {
    const globalState = useViewState('globalState')

    const fullscreen = !!globalState?.fullscreen
    const showSidebar = !!globalState?.showSidebar
    const enablePixelGrid = !!globalState?.enablePixelGrid
    const showRuler = globalState?.showRuler

    const showComment = useViewState('showComments', true)
    const showMultiplayerCursor = !!useViewState('showMultiplayerCursor')
    const showSlice = useViewState('showSlice', true)
    const showLayoutgrid = !!useViewState('globalState')?.enableLayoutGrid

    const memoryUsage = useViewState('memoryUsage')
    const switchLanguage = useFeatureSwitch('switch-language')

    return [
        {
            name: translation('View'),
            position: 'right top',
            testId: 'main-menu-view',
            children: [
                {
                    hidden: !switchLanguage,
                    name: isEnglishLanguage() ? 'Switch to Chinese' : '切换至英文',
                    handler: () => {
                        enhancedLocalStorage.setSerializedItem(
                            LocalStorageKey.Language,
                            isEnglishLanguage() ? 'zh' : 'en'
                        )
                        window.location.reload()
                    },
                },
                {
                    name: translation('PixelGrid'),
                    shortCut: shortcutLabelMap[ShortcutKey.PixelGrid],
                    activity: enablePixelGrid,
                    disable: !useViewState('actionEnabledPixelGridStatus'),
                    handler: () => command.DEPRECATED_invokeBridge(ToggleEnablePixelGridWasmCall),
                },
                {
                    name: translation('LayoutGrids'),
                    shortCut: shortcutLabelMap[ShortcutKey.LayoutGrid],
                    activity: showLayoutgrid,
                    disable: false,
                    handler: () => command.DEPRECATED_invokeBridge(ToggleEnabledLayoutGridWasmCall),
                },
                {
                    name: translation('Rulers'),
                    shortCut: shortcutLabelMap[ShortcutKey.Rulers],
                    activity: showRuler,
                    disable: !useViewState('actionEnabledRulerStatus'),
                    handler: () => command.DEPRECATED_invokeBridge(ToggleShowRulerWasmCall),
                },
                generateSubMenuConfigWithActivity(
                    !useViewState('actionEnabledTogglenShowSliceStatus'),
                    command,
                    translation('ShowSlices'),
                    '',
                    showSlice,
                    true,
                    ShowSliceCommand,
                    Wukong.DocumentProto.BridgeProtoBoolean.create({
                        value: !showSlice,
                    })
                ),
                {
                    ...generateSubMenuConfigWithActivity(
                        !useViewState('actionEnabledTogglenMemoryUsageStatus'),
                        command,
                        translation('MemoryUsage'),
                        '',
                        memoryUsage?.show || false,
                        true,
                        ToggleShowUsedMemory
                    ),
                    testId: 'main-menu-view-memory-usage',
                },
                { type: WKItemType.Divider },
                generateSubMenuConfigWithActivity(
                    !useViewState('actionEnabledToggleShowCommentStatus'),
                    command,
                    translation('Comments'),
                    shortcutLabelMap[ShortcutKey.ShowComment],
                    showComment,
                    true,
                    ToggleShowCommentCommand
                ),
                {
                    name: translation('MultiplayerCursors'),
                    shortCut: shortcutLabelMap[ShortcutKey.ShowMultiplayerCursor],
                    activity: showMultiplayerCursor,
                    disable: false,
                    handler: () => command.DEPRECATED_invokeBridge(ToggleShowMultiplayerCursorWasmCall),
                },
                generateSubMenuConfigWithActivity(
                    !useViewState('actionEnabledToggleFullScreenStatus'),
                    command,
                    translation('ShowUI'),
                    shortcutLabelMap[ShortcutKey.ToggleFullScreen],
                    !fullscreen,
                    false,
                    cmdToggleFullscreen
                ),
                {
                    name: translation('ShowLeftSidebar'),
                    shortCut: shortcutLabelMap[ShortcutKey.ShowLeftSidebar],
                    activity: showSidebar,
                    disable: !useViewState('actionEnabledShowSidebarStatus'),
                    handler: () => command.DEPRECATED_invokeBridge(ToggleShowSidebarCommandForJs),
                },
                {
                    name: translation('OpenDevMode'),
                    shortCut: shortcutLabelMap[ShortcutKey.DevMode],
                    activity: false,
                    disable: false,
                    handler: () => {
                        logs.DevMode.enterDevMode({ enter_devmode_way: SwitchModeWay.Menu })
                        command.DEPRECATED_invokeBridge(SwitchEditorTypeCommand)
                    },
                },
                { type: WKItemType.Divider },
                generateSubMenuConfigUnderSelectionView(
                    !useViewState('actionEnabledZoomInAndOutStatus'),
                    command,
                    translation('ZoomIn'),
                    shortcutLabelMap[ShortcutKey.ZoomIn],
                    false,
                    cmdZoomInByLevelAtCameraCenter
                ),
                generateSubMenuConfigUnderSelectionView(
                    !useViewState('actionEnabledZoomInAndOutStatus'),
                    command,
                    translation('ZoomOut'),
                    shortcutLabelMap[ShortcutKey.ZoomOut],
                    false,
                    cmdZoomOutByLevelAtCameraCenter
                ),
                generateSubMenuConfigUnderSelectionView(
                    !useViewState('actionEnabledZoomInAndOutStatus'),
                    command,
                    `${translation('ZoomTo')} 100%`,
                    shortcutLabelMap[ShortcutKey.ZoomTo100Percent],
                    false,
                    cmdZoomAtCameraCenter,
                    1
                ),
                generateSubMenuConfigUnderSelectionView(
                    !useViewState('actionEnabledZoomInAndOutStatus'),
                    command,
                    translation('ZoomToFit'),
                    shortcutLabelMap[ShortcutKey.ZoomToFit],
                    false,
                    cmdZoomToAll
                ),
                generateSubMenuConfigUnderSelectionView(
                    !useViewState('actionEnabledZoomInAndOutStatus'),
                    command,
                    translation('ZoomToSelection'),
                    shortcutLabelMap[ShortcutKey.ZoomToSelection],
                    false,
                    cmdZoomToSelection
                ),
                { type: WKItemType.Divider },
                generateSubMenuConfigUnderSelectionSort(
                    !useViewState('actionEnabledPageUpStatus'),
                    command,
                    translation('PreviousPage'),
                    shortcutLabelMap[ShortcutKey.PreviousPage],
                    true,
                    PageUpCommandWasmCall
                ),
                generateSubMenuConfigUnderSelectionSort(
                    !useViewState('actionEnabledPageDownStatus'),
                    command,
                    translation('NextPage'),
                    shortcutLabelMap[ShortcutKey.NextPage],
                    true,
                    PageDownCommandWasmCall
                ),
                generateSubMenuConfigUnderSelectionView(
                    !useViewState('actionEnabledZoomToPrevFrameStatus'),
                    command,
                    translation('ZoomToPrevious'),
                    shortcutLabelMap[ShortcutKey.ZoomToPreviousFrame],
                    false,
                    cmdZoomToPrevFrame
                ),
                generateSubMenuConfigUnderSelectionView(
                    !useViewState('actionEnabledZoomToNextFrameStatus'),
                    command,
                    translation('ZoomToNext'),
                    shortcutLabelMap[ShortcutKey.ZoomToNextFrame],
                    false,
                    cmdZoomToNextFrame
                ),
                generateSubMenuConfigUnderSelectionView(
                    !useViewState('actionEnabledViewPrevFrameStatus'),
                    command,
                    translation('FindPreviousFrame'),
                    shortcutLabelMap[ShortcutKey.FindPreviousFrame],
                    false,
                    cmdViewPrevFrame
                ),
                generateSubMenuConfigUnderSelectionView(
                    !useViewState('actionEnabledViewNextFrameStatus'),
                    command,
                    translation('FindNextFrame'),
                    shortcutLabelMap[ShortcutKey.FindNextFrame],
                    false,
                    cmdViewNextFrame
                ),
            ],
        },
    ]
}

export const useDevModeMenuView = (command: CommandInvoker): WKMenuConfig => {
    const globalState = useViewState('globalState')

    const fullscreen = !!globalState?.fullscreen
    const showSidebar = !!globalState?.showSidebar
    const enablePixelGrid = !!globalState?.enablePixelGrid
    const showRuler = globalState?.showRuler

    const showComment = useViewState('showComments', true)
    const showMultiplayerCursor = !!useViewState('showMultiplayerCursor')
    const showSlice = useViewState('showSlice', true)
    const showLayoutgrid = !!useViewState('globalState')?.enableLayoutGrid
    const allowIconAutoDetection = useViewState('devModeAllowIconAutoDetectionViewState', true)

    const memoryUsage = useViewState('memoryUsage')

    return [
        {
            name: translation('View'),
            position: 'right top',
            testId: 'main-menu-view',
            children: [
                {
                    name: translation('PixelGrid'),
                    shortCut: shortcutLabelMap[ShortcutKey.PixelGrid],
                    activity: enablePixelGrid,
                    disable: !useViewState('actionEnabledPixelGridStatus'),
                    handler: () => command.DEPRECATED_invokeBridge(ToggleEnablePixelGridWasmCall),
                },

                {
                    name: translation('LayoutGrids'),
                    shortCut: shortcutLabelMap[ShortcutKey.LayoutGrid],
                    activity: showLayoutgrid,
                    disable: false,
                    handler: () => command.DEPRECATED_invokeBridge(ToggleEnabledLayoutGridWasmCall),
                },
                {
                    name: translation('Rulers'),
                    shortCut: shortcutLabelMap[ShortcutKey.Rulers],
                    activity: showRuler,
                    disable: !useViewState('actionEnabledRulerStatus'),
                    handler: () => command.DEPRECATED_invokeBridge(ToggleShowRulerWasmCall),
                },
                generateSubMenuConfigWithActivity(
                    !useViewState('actionEnabledTogglenShowSliceStatus'),
                    command,
                    translation('ShowSlices'),
                    '',
                    showSlice,
                    true,
                    ShowSliceCommand,
                    Wukong.DocumentProto.BridgeProtoBoolean.create({
                        value: !showSlice,
                    })
                ),

                {
                    ...generateSubMenuConfigWithActivity(
                        !useViewState('actionEnabledTogglenMemoryUsageStatus'),
                        command,
                        translation('MemoryUsage'),
                        '',
                        memoryUsage?.show || false,
                        true,
                        ToggleShowUsedMemory
                    ),
                    testId: 'main-menu-view-memory-usage',
                },
                {
                    name: translation('AutomaticallyDetectIcons'),
                    shortCut: '',
                    activity: allowIconAutoDetection,
                    disable: false,
                    handler: () => command.DEPRECATED_invokeBridge(DevModeToggleIconAutoDetection),
                },
                { type: WKItemType.Divider },
                generateSubMenuConfigWithActivity(
                    !useViewState('actionEnabledToggleShowCommentStatus'),
                    command,
                    translation('Comments'),
                    shortcutLabelMap[ShortcutKey.ShowComment],
                    showComment,
                    true,
                    ToggleShowCommentCommand
                ),
                {
                    name: translation('MultiplayerCursors'),
                    shortCut: shortcutLabelMap[ShortcutKey.ShowMultiplayerCursor],
                    activity: showMultiplayerCursor,
                    disable: false,
                    handler: () => command.DEPRECATED_invokeBridge(ToggleShowMultiplayerCursorWasmCall),
                },
                generateSubMenuConfigWithActivity(
                    !useViewState('actionEnabledToggleFullScreenStatus'),
                    command,
                    translation('ShowUI'),
                    shortcutLabelMap[ShortcutKey.ToggleFullScreen],
                    !fullscreen,
                    false,
                    cmdToggleFullscreen
                ),
                {
                    name: translation('ShowLeftSidebar'),
                    shortCut: shortcutLabelMap[ShortcutKey.ShowLeftSidebar],
                    activity: showSidebar,
                    disable: !useViewState('actionEnabledShowSidebarStatus'),
                    handler: () => command.DEPRECATED_invokeBridge(ToggleShowSidebarCommandForJs),
                },
                {
                    name: translation('OpenDesignMode'),
                    shortCut: shortcutLabelMap[ShortcutKey.DevMode],
                    activity: false,
                    disable: false,
                    handler: () => {
                        logs.DevMode.enterDesignMode({ enter_designmode_way: SwitchModeWay.Menu })
                        command.DEPRECATED_invokeBridge(SwitchEditorTypeCommand)
                    },
                },
                { type: WKItemType.Divider },
                generateSubMenuConfigUnderSelectionView(
                    !useViewState('actionEnabledZoomInAndOutStatus'),
                    command,
                    translation('ZoomIn'),
                    shortcutLabelMap[ShortcutKey.ZoomIn],
                    false,
                    cmdZoomInByLevelAtCameraCenter
                ),
                generateSubMenuConfigUnderSelectionView(
                    !useViewState('actionEnabledZoomInAndOutStatus'),
                    command,
                    translation('ZoomOut'),
                    shortcutLabelMap[ShortcutKey.ZoomOut],
                    false,
                    cmdZoomOutByLevelAtCameraCenter
                ),
                generateSubMenuConfigUnderSelectionView(
                    !useViewState('actionEnabledZoomInAndOutStatus'),
                    command,
                    `${translation('ZoomTo')} 100%`,
                    shortcutLabelMap[ShortcutKey.ZoomTo100Percent],
                    false,
                    cmdZoomAtCameraCenter,
                    1
                ),
                generateSubMenuConfigUnderSelectionView(
                    !useViewState('actionEnabledZoomInAndOutStatus'),
                    command,
                    translation('ZoomToFit'),
                    shortcutLabelMap[ShortcutKey.ZoomToFit],
                    false,
                    cmdZoomToAll
                ),
                generateSubMenuConfigUnderSelectionView(
                    !useViewState('actionEnabledZoomInAndOutStatus'),
                    command,
                    translation('ZoomToSelection'),
                    shortcutLabelMap[ShortcutKey.ZoomToSelection],
                    true,
                    DevModeZoomToFitSelectionWasmCall
                ),
                { type: WKItemType.Divider },
                generateSubMenuConfigUnderSelectionSort(
                    !useViewState('actionEnabledPageUpStatus'),
                    command,
                    translation('PreviousPage'),
                    shortcutLabelMap[ShortcutKey.PreviousPage],
                    true,
                    PageUpCommandWasmCall
                ),
                generateSubMenuConfigUnderSelectionSort(
                    !useViewState('actionEnabledPageDownStatus'),
                    command,
                    translation('NextPage'),
                    shortcutLabelMap[ShortcutKey.NextPage],
                    true,
                    PageDownCommandWasmCall
                ),
                generateSubMenuConfigUnderSelectionView(
                    !useViewState('actionEnabledZoomToPrevFrameStatus'),
                    command,
                    translation('ZoomToPrevious'),
                    shortcutLabelMap[ShortcutKey.ZoomToPreviousFrame],
                    false,
                    cmdZoomToPrevFrame
                ),
                generateSubMenuConfigUnderSelectionView(
                    !useViewState('actionEnabledZoomToNextFrameStatus'),
                    command,
                    translation('ZoomToNext'),
                    shortcutLabelMap[ShortcutKey.ZoomToNextFrame],
                    false,
                    cmdZoomToNextFrame
                ),
                generateSubMenuConfigUnderSelectionView(
                    !useViewState('actionEnabledViewPrevFrameStatus'),
                    command,
                    translation('FindPreviousFrame'),
                    shortcutLabelMap[ShortcutKey.FindPreviousFrame],
                    false,
                    cmdViewPrevFrame
                ),
                generateSubMenuConfigUnderSelectionView(
                    !useViewState('actionEnabledViewNextFrameStatus'),
                    command,
                    translation('FindNextFrame'),
                    shortcutLabelMap[ShortcutKey.FindNextFrame],
                    false,
                    cmdViewNextFrame
                ),
            ],
        },
    ]
}
