import { Wukong } from '@wukong/bridge-proto'
import { useState } from 'react'
import { ColorSpace } from '../../../design/blend/color-picker/utils/color-translate'
import { NormalPaintRender as NormalPaintRenderV2 } from '../paint-render-v2'
import { ViewAllV2 } from '../view-all-v2'

import Paint = Wukong.DocumentProto.IPaint

export const UnStyleColorsV2 = ({
    paints,
    colorSpace,
    variables,
    isStroke,
    dataTestId,
    maxWidth,
}: {
    paints: Paint[]
    colorSpace: ColorSpace
    variables: Wukong.DocumentProto.ISingleVariable[]
    isStroke?: boolean
    dataTestId?: string
    maxWidth?: number
}) => {
    const [limit, setLimit] = useState(5)
    // 只有基础颜色，没有变量
    const isSimplePaints = !variables.length
    const _paints = paints.slice().reverse().slice(0, limit)

    return (
        <>
            <div className="flex flex-col" style={{ gap: isSimplePaints ? '8px' : '16px' }} data-testid={dataTestId}>
                {_paints.map((paint, idx) => {
                    const variable = variables.find((o) => o.id === paint.colorVar?.value.alias)
                    return (
                        <NormalPaintRenderV2
                            paint={paint}
                            key={idx}
                            colorSpace={colorSpace}
                            variable={variable}
                            maxWidth={maxWidth}
                            isStroke={isStroke}
                        />
                    )
                })}
            </div>
            <ViewAllV2 limit={limit} count={paints.length} onChange={setLimit} dataTestId="unstyle-color-view-all" />
        </>
    )
}
