/* eslint-disable no-restricted-imports */
import { DraftHandleValue, Editor, EditorState, getDefaultKeyBinding } from 'draft-js'
import 'draft-js/dist/Draft.css'
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { CommentUser } from '../../../../kernel/interface/comment'
import { Message } from '../type'
import { decorator } from './decorator'
import {
    createEditorStateWithMessages,
    getEditorStateWithSelectAll,
    getSearchInfoFromEditorState,
    insertEmojiToEditorState,
    insertMentionUserToEditorState,
    isPressedDecorativeKey,
    moveCursorToLeft,
    moveCursorToRight,
} from './draft-editor-utils'
import classes from './draft-editor.module.less'

export interface DraftEditorProps {
    disabled?: boolean
    placeholder?: string
    usersMap: Map<CommentUser['id'], CommentUser> //用于解析Message里的人信息
    initialMessages?: Message[]
    onSendEditorState: (editorState: EditorState) => void
    maxCharSize?: number
    replaceMessages?: Message[] | null // replaceMessagesDone 不存在 replaceMessages不会生效
    replaceMessagesDone?: () => void // 这个函数里把传入的 replaceMessages 设置成 undefined | null
    insertMentionUser?: CommentUser | null
    insertMentionUserDone?: () => void
    insertEmoji?: string
    insertEmojiDone?: () => void
    selectAll?: boolean // selectAllDone 不存在 selectAll 不会生效
    selectAllDone?: () => void // 这个函数里把传入的 selectAll 设置成 false
    isMentionHandling?: boolean
    mentionKeyEvent?: (e: React.KeyboardEvent) => void
    isFastInsertMentionUser?: boolean
    onSearchMentionUsers?: (findStr: string) => void
    onSearchMentionUsersDone?: () => void
    onFocus?: () => void
    onBlur?: () => void
    onSubmit?: () => void
    editorRef?: React.RefObject<Editor>
    dataTestId?: string
    dataTestIds?: {
        CommentEditor?: string
        CommentEditorInput?: string
    }
}

export function _DraftEditor(props: DraftEditorProps, outRef: React.Ref<HTMLDivElement>) {
    // 因为提及的这个行为优化比较复杂，这次的优化先不做。
    const enabledOptimize = false
    const {
        disabled,
        onSubmit,
        usersMap,
        insertEmoji,
        insertEmojiDone,
        isMentionHandling,
        mentionKeyEvent,
        isFastInsertMentionUser,
        insertMentionUser,
        insertMentionUserDone,
        replaceMessages,
        replaceMessagesDone,
        selectAll,
        selectAllDone,
        onSendEditorState,
        maxCharSize = Number.MAX_SAFE_INTEGER,
        onSearchMentionUsers,
        onSearchMentionUsersDone,
    } = props
    const [editorState, setEditorState] = useState(() =>
        EditorState.moveFocusToEnd(
            props.initialMessages
                ? createEditorStateWithMessages(props.initialMessages, decorator, usersMap)
                : EditorState.createEmpty(decorator)
        )
    )
    const editorStateRef = useRef<EditorState>(editorState)

    const onChange = useCallback(
        (_editorState: EditorState) => {
            setEditorState(_editorState)
            if (_editorState.getCurrentContent() !== editorStateRef.current.getCurrentContent()) {
                onSendEditorState(_editorState)
                const text = _editorState.getCurrentContent().getPlainText()
                const search = text.length > maxCharSize ? undefined : getSearchInfoFromEditorState(_editorState)
                if (search !== undefined) {
                    onSearchMentionUsers?.(search.searchString)
                } else {
                    onSearchMentionUsersDone?.()
                }
            }

            editorStateRef.current = _editorState
        },
        [maxCharSize, onSearchMentionUsers, onSearchMentionUsersDone, onSendEditorState]
    )

    const handleBeforeInput = (_chars: string, _editorState: EditorState): DraftHandleValue => {
        // https://github.com/facebook/draft-js/issues/631
        return 'not-handled'
    }

    const keyBindingFn = useCallback(
        (e: React.KeyboardEvent) => {
            e.stopPropagation()
            if (
                isMentionHandling &&
                (e.key === 'ArrowUp' ||
                    e.key === 'ArrowDown' ||
                    e.key === 'ArrowLeft' ||
                    e.key === 'ArrowRight' ||
                    e.key === 'Tab')
            ) {
                e.preventDefault()
                mentionKeyEvent?.(e)
                return null
            } else if (e.key === 'Escape') {
                mentionKeyEvent?.(e)
                return null
            }
            if (enabledOptimize && e.key === 'ArrowLeft' && !isPressedDecorativeKey(e)) {
                return 'custom-move-cursor-to-left'
            }
            if (enabledOptimize && e.key === 'ArrowRight' && !isPressedDecorativeKey(e)) {
                return 'custom-move-cursor-to-right'
            }
            return getDefaultKeyBinding(e)
        },
        [enabledOptimize, isMentionHandling, mentionKeyEvent]
    )

    const handleReturn = useCallback(
        (e: React.KeyboardEvent, _editorState: EditorState): DraftHandleValue => {
            e.stopPropagation()

            if (isMentionHandling && e.key === 'Enter') {
                e.preventDefault()
                mentionKeyEvent?.(e)
                return 'handled'
            }

            if (e.key === 'Enter' && !e.shiftKey) {
                const text = editorStateRef.current.getCurrentContent().getPlainText()
                const hasContent = /\S/.test(text)
                if (hasContent) {
                    e.preventDefault()
                    !disabled && onSubmit?.()
                    return 'handled'
                }
            }

            return 'not-handled'
        },
        [disabled, isMentionHandling, mentionKeyEvent, onSubmit]
    )

    const handleKeyCommand = useCallback((command: string, _editorState: EditorState) => {
        switch (command) {
            case 'custom-move-cursor-to-left': {
                setEditorState(moveCursorToLeft(_editorState))
                return 'handled'
            }
            case 'custom-move-cursor-to-right':
                setEditorState(moveCursorToRight(_editorState))
                return 'handled'
            default:
                return 'not-handled'
        }
    }, [])

    useEffect(() => {
        if (!insertEmoji || !insertEmojiDone) {
            return
        }
        const nextEditorState = insertEmojiToEditorState(editorStateRef.current, insertEmoji)
        onChange(nextEditorState)
        insertEmojiDone()
    }, [insertEmoji, insertEmojiDone, onChange])

    useEffect(() => {
        if (!insertMentionUser || !insertMentionUserDone) {
            return
        }
        const nextEditorState = insertMentionUserToEditorState(
            editorStateRef.current,
            insertMentionUser,
            !!isFastInsertMentionUser
        )
        onChange(nextEditorState)
        insertMentionUserDone()
    }, [insertMentionUser, insertMentionUserDone, isFastInsertMentionUser, onChange])

    useEffect(() => {
        if (!replaceMessages || !replaceMessagesDone) {
            return
        }
        const nextEditorState = createEditorStateWithMessages(replaceMessages, decorator, usersMap)
        onChange(EditorState.moveFocusToEnd(nextEditorState))
        replaceMessagesDone()
    }, [onChange, replaceMessagesDone, replaceMessages, usersMap])

    useEffect(() => {
        if (!selectAll || !selectAllDone) {
            return
        }
        const nextEditorState = getEditorStateWithSelectAll(editorStateRef.current)
        onChange(nextEditorState)
        selectAllDone()
    }, [onChange, selectAll, selectAllDone])

    useEffect(() => {
        props.onFocus?.()
        onSendEditorState(editorState)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div
            ref={outRef}
            data-testid={props.dataTestIds?.CommentEditor ?? 'draft-editor'}
            onPaste={(e) => e.stopPropagation()}
            onPointerDown={(e) => e.stopPropagation()}
            className={classes.draftEditor}
            onCompositionStart={onSearchMentionUsersDone}
        >
            <Editor
                editorState={editorState}
                onChange={onChange}
                placeholder={props.placeholder}
                handleBeforeInput={handleBeforeInput}
                stripPastedStyles={true}
                ref={props.editorRef}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                keyBindingFn={keyBindingFn}
                handleReturn={handleReturn}
                handleKeyCommand={enabledOptimize ? handleKeyCommand : undefined}
                webDriverTestID={props.dataTestIds?.CommentEditorInput ?? ''}
            />
        </div>
    )
}

export const DraftEditor = forwardRef(_DraftEditor)
