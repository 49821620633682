import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Presentation: '演示模式',
    None: '无',
    CustomSize: '自定义尺寸',
    Fit: '适应',
    Fill: '填充',
} as const

export const enTranslation = {
    Presentation: 'Presentation',
    None: 'None',
    CustomSize: 'Custom Size',
    Fit: 'Fit',
    Fill: 'Fill',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
