import { createStore as $createStore, createDebugStore } from 'ccstate'
import { environment } from '../../environment'

export let appStore$: ReturnType<typeof $createStore>

export function $initStore() {
    if (environment.isDev) {
        const store = createDebugStore()
        appStore$ = store
    } else {
        appStore$ = $createStore()
    }

    return appStore$
}
