/* eslint-disable no-restricted-imports */
import {
    BatchUpdateBlendModeCommand,
    BatchUpdateOpacityCommand,
    BatchUpdateVisibleCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { isNil, isNumber } from 'lodash-es'
import { useCallback } from 'react'
import { useAppContext } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { Value } from '../../atom/inputs/utils/type'

export interface BlendModeView {
    blendMode: Wukong.DocumentProto.BlendMode | string
    opacity: number | string
    visible: boolean | string
}

export function useBlendMode() {
    const command = useCommand()
    const blendModeViewState = useViewState('blendModeView')

    const docReadonly = useViewState('docReadonly')

    const service = useAppContext().variableService

    const onChangeVariableMode = useCallback(
        (value: { variableSetIds?: string[]; modeId?: string; type?: 'value' | 'mixed' | 'auto' }) => {
            switch (value.type) {
                case undefined:
                case 'mixed':
                    break
                case 'auto':
                    if (!isNil(value.variableSetIds)) {
                        service.resetDefaultVariableModeBySetForSelection(value.variableSetIds)
                    }
                    break
                case 'value':
                    if (!isNil(value.variableSetIds) && !isNil(value.modeId)) {
                        service.setVariableModeBySetForSelection(value.variableSetIds, value.modeId)
                    }
                    break
            }
        },
        [service]
    )

    const onChangeBlendMode = useCallback(
        (value: Wukong.DocumentProto.BlendMode) => {
            if (blendModeViewState?.passIds) {
                command.DEPRECATED_invokeBridge(BatchUpdateBlendModeCommand, {
                    type: value,
                    nodeIds: blendModeViewState.passIds,
                })
                command.commitUndo()
            }
        },
        [command, blendModeViewState?.passIds]
    )

    const onChangeOpacity = useCallback(
        (value: number, options?: InputOptionsForUndoSquash) => {
            if (!blendModeViewState?.passIds) {
                return
            }
            isNumber(value) &&
                command.DEPRECATED_invokeBridge(BatchUpdateOpacityCommand, {
                    opacity: value / 100,
                    nodeIds: blendModeViewState.passIds,
                })
            command.commitUndo(options?.commitType)
        },
        [command, blendModeViewState?.passIds]
    )

    const onChangeVisible = useCallback(
        (value: boolean) => {
            if (!blendModeViewState?.passIds) {
                return
            }
            command.DEPRECATED_invokeBridge(BatchUpdateVisibleCommand, {
                visible: value,
                nodeIds: blendModeViewState.passIds,
            })
            command.commitUndo()
        },
        [command, blendModeViewState?.passIds]
    )

    const onChangeOpacity2 = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value === 'number') {
                onChangeOpacity(value, options)
            }
        },
        [onChangeOpacity]
    )

    return {
        docReadonly,
        blendModeViewState,
        onChangeBlendMode,
        onChangeOpacity2,
        onChangeVisible,
        onChangeVariableMode,
    }
}
