/* eslint-disable no-restricted-imports */
import constate from 'constate'
import { useRef } from 'react'
import type { CanvasRenderBridge } from '../../document/bridge/canvas-render-bridge'
import type { CommandInvoker } from '../../document/command/command-invoker'
import { ImageDownloadContext } from '../../document/command/image-download-context'
import type { ReadonlyDocumentRoot } from '../../document/document-bridge/document-root'
import { ExecutableClipboardService } from '../../main/clipboard/clipboard-service/clipboard-service-interface'
import type { FontManagerService } from '../../main/font/font-manager-service'
import type { HandlerProvider } from '../../main/handler-provider/handler-provider'
import type { DocumentContextDeps } from './document-context-deps'

function useDocumentContext(deps: DocumentContextDeps) {
    const HandlerProviderRef = useRef<HandlerProvider>(deps.handlerProvider)
    const fontManagerServiceRef = useRef<FontManagerService>(deps.fontManagerService)
    const clipboardServiceRef = useRef<ExecutableClipboardService>(deps.clipboardService)
    /* @deprecated */
    const CommandInvokerRef = useRef<CommandInvoker>(deps.commandInvoker)
    /* @deprecated */
    const DocumentRootRef = useRef<ReadonlyDocumentRoot>(deps.documentRoot)
    const canvasRenderBridgeRef = useRef<CanvasRenderBridge>(deps.canvasRenderBridge)
    const imageDownloadContextRef = useRef<ImageDownloadContext>(deps.imageDownloadContext)

    return {
        document: DocumentRootRef.current,
        command: CommandInvokerRef.current,
        handler: HandlerProviderRef.current,
        fontManagerService: fontManagerServiceRef.current,
        canvasRenderBridge: canvasRenderBridgeRef.current,
        clipboardService: clipboardServiceRef.current,
        imageDownloadContext: imageDownloadContextRef.current,
    }
}

export const [
    DocumentContextProvider,
    useHandler,
    useCommand,
    useDocument,
    useFontManagerService,
    useCanvasRenderBridge,
    useClipboardService,
    useImageDownloadContext,
] = constate(
    useDocumentContext,
    (ctx) => ctx.handler,
    (ctx) => ctx.command,
    (ctx) => ctx.document,
    (ctx) => ctx.fontManagerService,
    (ctx) => ctx.canvasRenderBridge,
    (ctx) => ctx.clipboardService,
    (ctx) => ctx.imageDownloadContext
)
