import { FeatureSwitchConfig, SwitchEnvironmentScopeType, SwitchStrategyType } from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'allow-debug-log',
    owner: '',
    description: '',
    strategies: [
        {
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                userList: ['fa41bf23ad54135e8310a19dcc012fbfb70d29f2', 'cff50cb5a26acb11aea4dac603e98ca5f6d6a213'],
            },
            env: SwitchEnvironmentScopeType.TESTING,
        },
    ],
    alwaysDisableInTests: true,
}
export default Config
