import { createContext } from 'react'

export const scrollTargetClassName = 'into-view-target'

export interface ScrollContextProps {
    selectKey: unknown
    selectClassName: string
}
export const defaultScrollContext: ScrollContextProps = {
    selectKey: undefined,
    selectClassName: scrollTargetClassName,
}
export const SelectContext = createContext<ScrollContextProps>(defaultScrollContext)
