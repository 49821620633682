import { translation } from './dev-mode-right-panel-detail.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { useMemo } from 'react'
import { useRightPanelWidth } from '../../../../main/layout/layout-context'
import { useViewState } from '../../../../view-state-bridge'
import { CommentPanel } from '../../comment/comment-panel/comment-panel'
import { HistoryPanel } from '../../history-file/history-panel/history-panel'
import { DevModeInspect } from '../inspect/inspect'
import style from './detail.module.less'

import DisplayPanelType = Wukong.DocumentProto.DisplayPanelType

const displayPanelType2TitleAndComponent: Partial<
    Record<DisplayPanelType, { title: string; comp: JSX.Element; testId?: string }>
> = {
    [DisplayPanelType.DISPLAY_PANEL_TYPE_DEV_MODE_INSPECT]: {
        title: translation('Inspect'),
        comp: <DevModeInspect />,
        testId: 'dev-mode-inspect-panel',
    },
    [DisplayPanelType.DISPLAY_PANEL_TYPE_COMMENT]: {
        title: translation('Comments'),
        comp: <CommentPanel />,
        testId: 'dev-mode-comment-panel',
    },
}

export function DevModeRightPanelDetail() {
    const isHistoryMode = useViewState('historyMode')
    const displayPanels = useViewState('devModeDisplayPanels', { displayPanels: [] }).displayPanels
    const selectedPanel = useMemo(() => {
        const selectedType = displayPanels.find(({ isSelected }) => isSelected)?.type
        if (isNil(selectedType)) {
            return null
        }
        return displayPanelType2TitleAndComponent[selectedType] ?? null
    }, [displayPanels])
    const rightPanelWidth = useRightPanelWidth()

    if (isHistoryMode) {
        return (
            <div
                className={style.container}
                style={{ width: rightPanelWidth }}
                data-testid="dev-mode-history-panel-container"
            >
                <HistoryPanel />
            </div>
        )
    }
    return !selectedPanel ? null : (
        <div
            className={style.container}
            style={{ width: rightPanelWidth }}
            data-testid={selectedPanel.testId}
            onDragStart={(e) => {
                e.preventDefault() // 禁用可拖拽选中内容
            }}
        >
            {displayPanels.length === 1 ? selectedPanel.comp : null}
        </div>
    )
}
