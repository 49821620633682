import { createSignalSwitch as createSignalSwitchInternal } from 'signal-transaction'
import { TraceableAbortSignal } from './traceable-abort-controller'

type WrappedFn<Args extends unknown[], ReturnType> = (signal: TraceableAbortSignal, ...args: Args) => ReturnType

export function createSignalSwitch(signal: TraceableAbortSignal): {
    (): () => AbortSignal
    <Args extends unknown[], ReturnType>(fn: WrappedFn<Args, ReturnType>): (...args: Args) => ReturnType
} {
    return createSignalSwitchInternal(signal)
}
