import { createContext } from 'react'
import { EditorTopBarRef } from '../../../share/editor-top-bar/wrapper'

export interface TopAreaContextProps {
    getCenterWidth: () => number | undefined
    editorTopBarRef: React.RefObject<EditorTopBarRef> | undefined
}

const defaultContext: TopAreaContextProps = {
    getCenterWidth: () => undefined,
    editorTopBarRef: undefined,
}
export const TopAreaContext = createContext<TopAreaContextProps>(defaultContext)
