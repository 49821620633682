import {
    DocWithAuthorityVO,
    ResourceType,
    SeatType,
    TriggerSeatApplicationSceneType,
} from '../../kernel/interface/type'
import { featureSwitchManager } from '../../kernel/switch'
import { UserRole, UserSeatStatus } from '../../utils/payment'
import { PayApplyDialog } from './pay-apply-dialog/pay-apply-dialog'
import {
    PayApplyDialogSeatType,
    PayApplyDialogType,
    PayApplyDialogUserType,
} from './pay-apply-dialog/pay-apply-dialog-struct/type'
import { translation } from './pay-seat-apply-edit-doc.translation'
import { getAdminUpgradeSelfDescription } from './util'

export function PayApplySeatOfEditDocs(props: {
    userRole: UserRole
    userSeatStatus: UserSeatStatus
    docData: DocWithAuthorityVO
    unresolvedSeatTypes: SeatType[]
    userId: number
    isEnterprise: boolean
    isSomeWorkspaceAdmin: boolean
}) {
    const { userRole, userSeatStatus, docData, unresolvedSeatTypes, userId, isEnterprise, isSomeWorkspaceAdmin } = props

    const httpProps = {
        resourceType: docData.orgId == '-1' ? ResourceType.Team : ResourceType.Organization,
        resourceId: docData.orgId == '-1' ? docData.teamId! : docData.orgId,
        triggerScene: {
            scene: TriggerSeatApplicationSceneType.EditDoc,
            docId: docData.id,
            userId: userId,
        },
        orgId: docData.orgId,
    }

    if (userRole == UserRole.Admin || (featureSwitchManager.isEnabled('organization-plus') && isSomeWorkspaceAdmin)) {
        PayApplyDialog.show({
            type: PayApplyDialogType.Admin,
            seatType: PayApplyDialogSeatType.Design,
            dialogProps: {
                title: translation('DialogTitleOfAdminOrAutoGrade'),
                okText: translation('DialogOkTextOfAdminOrAutoGrade'),
                cancelText: translation('DialogCancelTextOfAdminOrAutoGrade'),
            },
            httpProps: httpProps,
            description: getAdminUpgradeSelfDescription('design'),
        })
        return
    }

    const userInfo = {
        type: userRole == UserRole.Guest ? PayApplyDialogUserType.External : PayApplyDialogUserType.Normal,
    }

    switch (userSeatStatus) {
        case UserSeatStatus.CanApplyAutoUpgrade: {
            PayApplyDialog.show({
                type: PayApplyDialogType.AutoUpgrade,
                seatType: PayApplyDialogSeatType.Design,
                dialogProps: {
                    title: translation('DialogTitleOfAdminOrAutoGrade'),
                    okText: translation('DialogOkTextOfAdminOrAutoGrade'),
                    cancelText: translation('DialogCancelTextOfAdminOrAutoGrade'),
                },
                httpProps: httpProps,
                description: undefined,
            })
            break
        }
        case UserSeatStatus.CanApplyGracePeriod:
        case UserSeatStatus.NeedWaitToBeApproved:
        case UserSeatStatus.NeedReApply: {
            PayApplyDialog.show({
                type: PayApplyDialogType.Normal,
                isAllowGracePeriod: userSeatStatus == UserSeatStatus.CanApplyGracePeriod,
                isEnterprise: isEnterprise,
                userInfo: userInfo,
                seatType: PayApplyDialogSeatType.Design,
                httpProps: httpProps,
                descriptionPrefix: translation('DialogDescriptionPrefix'),
                unresolvedSeatTypes: unresolvedSeatTypes,
                repeatDialogProps: {
                    dialogTitle: '',
                },
            })
            break
        }
        case UserSeatStatus.InGracePeriod:
        case UserSeatStatus.InAutoUpgrade:
        case UserSeatStatus.InPermanentSeat:
            return
    }
}
