import { useRef } from 'react'
import { ExplicitUndefined } from '../../../utils/explicit-undefined'
import { SelectOption } from '../picks/single-level/select-option'
import { SingleLevel, SingleLevelProps, SingleLevelRef } from '../picks/single-level/single-level'
import { TriggerNormal, TriggerNormalRef } from '../triggers/trigger-normal'
import { SelectSingleLevelProps, SelectTriggerNormalProps } from './type'
import { useCommentSelect } from './use-comment-select'

export type SelectNormalSingleLevelProps = SelectTriggerNormalProps & SelectSingleLevelProps

export function SelectNormalSingleLevel(props: SelectNormalSingleLevelProps) {
    const pickRef = useRef<SingleLevelRef>(null)
    const triggerRef = useRef<TriggerNormalRef>(null)
    const {
        pickRest,
        value,
        mainValue,
        placement,
        triggerRect,
        worldRect,
        isMinWidthFromTrigger = true,
        minWidth,
        width,
        maxWidth,
        disableDefaultPreselect,
        removeTopPadding,
        removeBottomPadding,
        openStateToBeFalse,
        openStateToBeTrue,
        onKeyboard,
        onChange,
        onClose,
        onOpen,
        onChangePreselect,
        ...otherProps
    } = props

    const commentSelectState = useCommentSelect({
        triggerRect,
        triggerRef,
        pickRef,
        onOpen,
        onClose,
    })

    return (
        <TriggerNormal
            ref={triggerRef}
            {...otherProps}
            isOpenPick={commentSelectState.isOpenPick}
            openAction={commentSelectState.openAction}
            closeAction={commentSelectState.closeAction}
        >
            <SingleLevel<ExplicitUndefined<SingleLevelProps>>
                pickRest={pickRest}
                optionTemplate={SelectOption}
                isMixed={props.isMixed}
                dataTestIds={props.dataTestIds}
                triggerRect={commentSelectState.triggerRect}
                closeByESC={commentSelectState.closeByESC}
                onClose={commentSelectState.onSelectClose}
                onOpen={commentSelectState.onSelectOpen}
                ref={pickRef}
                value={value}
                mainValue={mainValue}
                placement={placement}
                worldRect={worldRect}
                isMinWidthFromTrigger={isMinWidthFromTrigger}
                minWidth={minWidth}
                width={width}
                maxWidth={maxWidth}
                onKeyboard={onKeyboard}
                onChange={onChange}
                onChangePreselect={onChangePreselect}
                removeTopPadding={removeTopPadding}
                removeBottomPadding={removeBottomPadding}
                isOpenState={undefined}
                openStateToBeFalse={openStateToBeFalse}
                openStateToBeTrue={openStateToBeTrue}
                getPortalElement={commentSelectState.getPortalElement}
                disableDefaultPreselect={disableDefaultPreselect}
                removeMask={undefined}
                isAllowEmptyOption={undefined}
                autoAdjustTop={undefined}
            >
                {props.children}
            </SingleLevel>
        </TriggerNormal>
    )
}

SelectNormalSingleLevel.Option = SelectOption
