import type { ReactElement, ReactNode } from 'react'
import { isValidElement, cloneElement as rCloneElement } from 'react'

type AnyObject = Record<any, any>

type RenderProps = undefined | AnyObject | ((originProps: AnyObject) => AnyObject | undefined)

export function replaceElement(element: ReactNode, replacement: ReactNode, props: RenderProps): ReactNode {
    if (!isValidElement(element)) return replacement

    return rCloneElement(element, typeof props === 'function' ? props(element.props || {}) : props)
}

export function cloneElement(element: ReactNode, props?: RenderProps): ReactElement {
    return replaceElement(element, element, props) as ReactElement
}

export function getInnerText(element: ReactNode): string {
    if (typeof element === 'string') return element

    if (!isValidElement(element)) return ''

    return getInnerText(element.props.children?.[0]?.props ? element.props.children[0] : element.props.children)
}
