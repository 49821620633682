import { IconAdjust, Tooltip } from '../../../../../../../ui-lib/src'
import classNames from 'classnames'
import { Wukong } from '@wukong/bridge-proto'
import { DeepRequired } from '../../../../../../../util/src'
import { LocalStyleTestId } from '../../../../../window'
import { IconButton } from '../../../atom/button/icon-button'
import { collapse, expand } from '../../../inspect/assets/inspect-svg'
import { RightClickMenuWrapper } from '../../../right-click-menu-wrapper'
import { LayoutGridStyleThumbnail } from '../../styles/style-layout-grid/style-thumbnail'
import { DragItem } from '../drag/drag-item'
import { LocalStyleModel } from '../hooks/use-local-style-model-v2'
import { useStyleListItem } from '../hooks/use-style-list-item-v2'
import { StyleName } from '../style-name'
import style from '../style.module.less'
import { translation } from './layout-grid-style-list-item.translation'

const LOCAL_STYLE_SELECTION_TYPE_LAYOUT_GRID =
    Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_LAYOUT_GRID

export interface LayoutGridStyleListItemProps {
    item: DeepRequired<Wukong.DocumentProto.ILocalLayoutGridStyleItem>
    itemIndex: number
    model: LocalStyleModel
    hover: string | undefined
    setHover: (value: string) => void
    isReadonly?: boolean
    isFolderCollapse: boolean
    toggleFolderCollapse: (folderName: string) => void
}

export const LayoutGridStyleListItem = (props: LayoutGridStyleListItemProps) => {
    const {
        item,
        itemIndex,
        model: { modelCommand, relatedSelectionItems, modelState, isEditingName },
        hover,
        setHover,
        isReadonly,
        isFolderCollapse,
        toggleFolderCollapse,
    } = props

    const {
        itemKey,
        handleMouseDown,
        handleMouseUp,
        itemClassNames,
        isHover,
        isEditingItemName,
        rowRightStyle,
        isOpenPopup,
    } = useStyleListItem({
        type: LOCAL_STYLE_SELECTION_TYPE_LAYOUT_GRID,
        itemKeyType: 'layoutgrid',
        item,
        itemList: modelState?.layoutGridStyleItems ?? [],
        relatedSelectionItems: relatedSelectionItems.layoutGrid,
        model: props.model,
        hover,
        style,
        isReadonly,
    })

    return (
        <RightClickMenuWrapper
            source={Wukong.DocumentProto.RightClickSource.RIGHT_CLICK_SOURCE_DESIGN_PANEL}
            stopPropagation
        >
            <DragItem itemIndex={itemIndex} isFolderCollapse={isFolderCollapse}>
                <div
                    data-style-id={itemKey}
                    data-testid={LocalStyleTestId.LayoutGridStyleListItem + itemIndex}
                    className={classNames(...itemClassNames)}
                    onMouseEnter={(): void => setHover(itemKey)}
                    onMouseLeave={(): void => setHover('')}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                >
                    {<div style={{ flex: 1 }}></div>}
                    <div className={style.row_right} style={rowRightStyle}>
                        {item.isFolder ? (
                            <div
                                className={style.svg_container}
                                onMouseDown={(): void => toggleFolderCollapse(item.folderName)}
                            >
                                {isFolderCollapse ? collapse : expand}
                            </div>
                        ) : (
                            <LayoutGridStyleThumbnail
                                layoutGrids={item.styleNode.layoutGrids}
                                className={style.paint_preview}
                            />
                        )}
                        <StyleName
                            isEdit={isEditingItemName}
                            fullName={item.isFolder ? item.folderName : item.styleNode.name}
                            startEditName={() => {
                                modelCommand.updateEditName(item)
                            }}
                            endEditName={() => modelCommand.clearEditName()}
                            rename={(newName) => modelCommand.renameLocalStyleItem(item, newName)}
                            isReadonly={isReadonly}
                        />
                        {!item.isFolder && !isEditingName && (isHover || isOpenPopup) && (
                            <span className={style.hover_icon}>
                                <Tooltip title={translation('EditStyle')}>
                                    <IconButton
                                        icon={<IconAdjust />}
                                        selected={isOpenPopup}
                                        deepColor
                                        onClick={() =>
                                            modelCommand.updateEditStyle({
                                                type: LOCAL_STYLE_SELECTION_TYPE_LAYOUT_GRID,
                                                editingStyleId: item.styleId,
                                            })
                                        }
                                        dataTestId={LocalStyleTestId.LayoutGridStyleListItem + itemIndex + '-edit-icon'}
                                    />
                                </Tooltip>
                            </span>
                        )}
                    </div>
                </div>
            </DragItem>
        </RightClickMenuWrapper>
    )
}
