import { Wukong } from '@wukong/bridge-proto'
import { cloneDeep } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import {
    MonoIconPanelXOffset16,
    MonoIconPanelYOffset16,
    safeCall,
    Select,
    Tooltip,
} from '../../../../../../../ui-lib/src'
import { isEnglishLanguage } from '../../../../../../../util/src'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { ScrubbableInputNumber } from '../../../atom/inputs/scrubbable-input-number'
import { Value } from '../../../atom/inputs/utils/type'
import {
    MIXED_TYPE,
    MIXED_TYPE_LABEL,
    PrototypeInteractionSelectedTargetNode,
    PrototypeInteractionWithNodeId,
} from '../../prototype-interaction/constants'
import { getScrollOffset } from '../../prototype-interaction/utils'
import { translation } from './scroll-to.translation'

import ScrollToTargetNodeList = Wukong.DocumentProto.IScrollToTargetNodeList

const useScrollToOffset = (props: {
    data: PrototypeInteractionWithNodeId[]
    onChange: (data: PrototypeInteractionWithNodeId[], options?: InputOptionsForUndoSquash) => void
}) => {
    const { data, onChange } = props
    const scrollOffset = useMemo(() => {
        return getScrollOffset(data)
    }, [data])
    const xOffset = useMemo(() => {
        if (scrollOffset.xOffset === MIXED_TYPE) {
            return 0
        }
        return scrollOffset.xOffset
    }, [scrollOffset.xOffset])
    const yOffset = useMemo(() => {
        if (scrollOffset.yOffset === MIXED_TYPE) {
            return 0
        }
        return scrollOffset.yOffset
    }, [scrollOffset.yOffset])
    const onChangeOffset = useCallback(
        (v: Value, prop: 'x' | 'y', options?: InputOptionsForUndoSquash) => {
            if (typeof v !== 'number') {
                return
            }
            const newInteractions = data.map((interaction) => {
                const newAction = interaction.actions[0]
                    ? { ...interaction.actions[0] }
                    : ({} as Wukong.DocumentProto.IPrototypeAction)
                const newExtraScrollOffset = {
                    x: newAction.extraScrollOffset?.x ?? 0,
                    y: newAction.extraScrollOffset?.y ?? 0,
                }
                if (prop === 'x') {
                    newAction.extraScrollOffset = { x: v, y: newExtraScrollOffset.y }
                } else {
                    newAction.extraScrollOffset = { y: v, x: newExtraScrollOffset.x }
                }
                return { ...interaction, actions: [newAction] }
            })
            onChange(newInteractions, options)
        },
        [data, onChange]
    )

    const getMixedMathHandler = useCallback(
        (prop: 'x' | 'y') => {
            return {
                getScrubStartValueMixed: () =>
                    new Map<string, Value>(
                        data.map((interaction) => [
                            interaction.id!,
                            interaction.actions[0].extraScrollOffset?.[prop] ?? 0,
                        ])
                    ),
                onScrubChangeAllMixed: (values: Map<string, Value>) => {
                    const result = Object.fromEntries(values) as Record<string, number>
                    onChange(
                        cloneDeep(data).map((interaction) => {
                            const action = interaction.actions[0]
                            action.extraScrollOffset![prop] = result[interaction.id!]
                            return interaction
                        })
                    )
                },
                onChangeMixed: (parse: (value: any) => void, options?: InputOptionsForUndoSquash) => {
                    const res: Record<string, number> = {}
                    new Map<string, number>(
                        data.map((interaction) => [
                            interaction.id!,
                            interaction.actions[0].extraScrollOffset![prop] ?? 0,
                        ])
                    ).forEach((value, key) => {
                        res[key] = safeCall(parse, value) ?? value
                    })
                    onChange(
                        cloneDeep(data).map((interaction) => {
                            const action = interaction.actions[0]
                            action.extraScrollOffset![prop] =
                                safeCall(parse, action.extraScrollOffset![prop]) ?? action.extraScrollOffset![prop] ?? 0
                            return interaction
                        }),
                        options
                            ? {
                                  commitType: options.commitType,
                              }
                            : undefined
                    )
                },
            }
        },
        [data, onChange]
    )

    return {
        xOffset,
        yOffset,
        xIsMixed: scrollOffset.xOffset === MIXED_TYPE,
        yIsMixed: scrollOffset.yOffset === MIXED_TYPE,
        getMixedMathHandler,
        onChangeOffset,
    }
}

export const InteractionActionScrollTo = ({
    data,
    scrollToTargets,
    selectedTargetNode,
    onChange,
    onChangeTarget,
    onMouseEnterTarget,
    onMouseLeaveTarget,
}: {
    data: PrototypeInteractionWithNodeId[]
    scrollToTargets?: ScrollToTargetNodeList
    selectedTargetNode: PrototypeInteractionSelectedTargetNode
    onChange: (data: PrototypeInteractionWithNodeId[], options?: InputOptionsForUndoSquash) => void
    onChangeTarget: (v: string) => void
    onMouseEnterTarget?: (v: string) => void
    onMouseLeaveTarget?: () => void
}) => {
    const menus = useMemo(() => {
        const ret: any[] = []
        if (!scrollToTargets) {
            return ret
        }
        if (selectedTargetNode.isMixed) {
            ret.push({
                value: MIXED_TYPE,
                label: MIXED_TYPE_LABEL,
                disabled: true,
            })
        }
        const topTargets = [...scrollToTargets.topTargets]
        const middleTargetsList = [...scrollToTargets.middleTargetsList]
        let disabledTargets = [...scrollToTargets.disabledTargets]
        const enabledTargets = [
            ...scrollToTargets.topTargets,
            ...scrollToTargets.middleTargetsList.map((v) => v.list).flat(),
        ].filter((v) => !v.disabled)
        if (!selectedTargetNode.isMixed && selectedTargetNode.selectedNodes.length) {
            const selectedNodeId = selectedTargetNode.selectedNodes[0].id
            if (disabledTargets.some((v) => v.id === selectedNodeId)) {
                disabledTargets = disabledTargets.filter((v) => v.id !== selectedNodeId)
                topTargets.push(selectedTargetNode.selectedNodes[0])
            } else if (!enabledTargets.some((v) => v.id === selectedNodeId)) {
                topTargets.push(selectedTargetNode.selectedNodes[0])
            }
        }

        if (topTargets.length && topTargets.some((v) => !v.disabled)) {
            topTargets.forEach((node, idx) => {
                ret.push({
                    value: `${node.id}${node.disabled ? '-disabled' : ''}`,
                    label: node.name,
                    disabled: node.disabled,
                    splitLineTop: idx === 0 && ret.length > 0,
                })
            })
        }
        if (middleTargetsList.length) {
            const middleList = [...middleTargetsList.map((v) => v.list)].sort((a, b) =>
                a[0].name.localeCompare(b[0].name, isEnglishLanguage() ? 'en' : 'zh')
            )
            middleList.forEach((list) => {
                list.forEach((node, idx) => {
                    ret.push({
                        value: `${node.id}${node.disabled ? '-disabled' : ''}`,
                        label: node.name,
                        disabled: node.disabled,
                        splitLineTop: idx === 0 && ret.length > 0,
                    })
                })
            })
        }
        if (disabledTargets.length) {
            disabledTargets.forEach((node, idx) => {
                ret.push({
                    value: `${node.id}-disabled`,
                    label: node.name,
                    disabled: true,
                    splitLineTop: idx === 0 && ret.length > 0,
                })
            })
        }
        return ret
    }, [scrollToTargets, selectedTargetNode])
    const selectedValue = selectedTargetNode.isMixed ? MIXED_TYPE : selectedTargetNode.selectedNodes[0]?.id
    const selectedLabel = useMemo(() => {
        return selectedValue ? menus.find((v) => v.value === selectedValue)?.label : translation('None')
    }, [selectedValue, menus])
    const { xOffset, yOffset, xIsMixed, yIsMixed, onChangeOffset, getMixedMathHandler } = useScrollToOffset({
        data,
        onChange,
    })

    return (
        <>
            <Select.MinimalSingleLevel
                className="pl-10 pr-4 box-border w-full"
                value={selectedValue}
                label={<div className="py-2 truncate">{selectedLabel}</div>}
                onChange={onChangeTarget}
                dataTestIds={{
                    triggerFocus: 'interaction-scroll-to-target-select-trigger',
                }}
                onClose={onMouseLeaveTarget}
                maxWidth={280}
            >
                {menus.map((item, index) => {
                    return (
                        <Select.MinimalSingleLevel.Option
                            key={index}
                            value={item.value}
                            disabled={item.disabled}
                            splitLineTop={item.splitLineTop}
                            dataTestId="interaction-scroll-to-target-select-option"
                            onMouseEnter={() => onMouseEnterTarget?.(item.value)}
                            onMouseLeave={onMouseLeaveTarget}
                        >
                            {item.label}
                        </Select.MinimalSingleLevel.Option>
                    )
                })}
            </Select.MinimalSingleLevel>
            <div className="flex ml-10 mr-4 py-1 justify-between">
                <Tooltip title={translation('YOffset')}>
                    <div className="w-22 h-7">
                        <ScrubbableInputNumber
                            testId="scroll-to-y-offset-input"
                            icon={<MonoIconPanelYOffset16 />}
                            value={yOffset}
                            isMixed={yIsMixed}
                            onChange={(v, options) => onChangeOffset(v, 'y', options)}
                            mixedMathHandler={getMixedMathHandler('y')}
                        />
                    </div>
                </Tooltip>
                <Tooltip title={translation('XOffset')}>
                    <div className="w-22 h-7">
                        <ScrubbableInputNumber
                            testId="scroll-to-x-offset-input"
                            icon={<MonoIconPanelXOffset16 />}
                            value={xOffset}
                            isMixed={xIsMixed}
                            onChange={(v, options) => onChangeOffset(v, 'x', options)}
                            mixedMathHandler={getMixedMathHandler('x')}
                        />
                    </div>
                </Tooltip>
            </div>
        </>
    )
}
