import { GET, POST } from '@tutor/network-core'
import { DocID } from '../../../../kernel/interface/type'
import { BaseCommonRequest } from '../../../../kernel/request/base-request'
import { ExtractionFailedResponse } from '../../../../main/ai-recognize/requests'

export interface AiDesignInspectionConfigVO {
    docId: string

    // 是否设置过配置
    configured: boolean

    // 待检查件库
    libraryIds: string[]

    // 开启的检查规则
    rules: AiDesignInspectionRuleEnum[]
}

export enum AiDesignInspectionRuleEnum {
    RULE_STRICT_TEXT_HEIGHT = 'RULE_STRICT_TEXT_HEIGHT', // 严格检查文本行高
}

export interface AiDesignInspectionTaskResultVO {
    // 该任务是否已结束
    finished: boolean

    // 样式检查的范围
    styleInspectionNodeIds: string[]

    // 待检查文本样式的图层 Id
    textStyleInspectionNodeIds: string[]

    // 待检查颜色样式的图层 Id
    colorStyleInspectionNodeIds: string[]

    // 组件检查结果
    componentItems: AIComponentItem[]
}

export interface AIComponentItem {
    // 组件类型
    componentType: string

    // 组件信息-用来展示缩略图
    component: ComponentItemBasicInfo

    // 重复的组件
    repeats: ComponentItemBasicInfo[] | null
}

export interface ComponentItemBasicInfo {
    // 该组件包含的nodeIds
    nodeIds: string[]
}

type TaskID = string
export interface SubmissionTaskResponse {
    taskId: TaskID
}

export interface ResourceUploadResponse {
    resourceId: string
    resourceUrl: string
    url: string
    ossUrl: string
    contentType: string
    method: string
}

/**
 * 查询检查配置
 */
@GET
class _QueryAIDesignLintConfig extends BaseCommonRequest<AiDesignInspectionConfigVO> {
    constructor(private readonly docId: string) {
        super()
    }

    public override requestUrl() {
        return `ai/design-inspection/${this.docId}/config`
    }
}

export class QueryAIDesignLintConfig {
    constructor(private readonly thisDocId: DocID) {}

    public async start() {
        const libraryQueryResponse = await new _QueryAIDesignLintConfig(this.thisDocId).start()
        return libraryQueryResponse
    }
}

/**
 * 修改组件库配置
 */
@POST
class _UpdateAIDesignLintLibraryConfig extends BaseCommonRequest<null> {
    constructor(private readonly docId: string, private readonly libraryIds: string[]) {
        super()
    }

    public override requestBody() {
        return this.libraryIds
    }

    public override requestUrl() {
        return `ai/design-inspection/${this.docId}/config/library`
    }
}

export class UpdateAIDesignLintLibraryConfig {
    constructor(private readonly thisDocId: DocID, private readonly libraryIds: string[]) {}

    public async start() {
        await new _UpdateAIDesignLintLibraryConfig(this.thisDocId, this.libraryIds).start()
    }
}

/**
 * 修改规则配置
 */
@POST
class _UpdateAIDesignLintRuleConfig extends BaseCommonRequest<null> {
    constructor(private readonly docId: string, private readonly rules: AiDesignInspectionRuleEnum[]) {
        super()
    }

    public override requestBody() {
        return this.rules
    }

    public override requestUrl() {
        return `ai/design-inspection/${this.docId}/config/rule`
    }
}

export class UpdateAIDesignLintRuleConfig {
    constructor(private readonly thisDocId: DocID, private readonly rules: AiDesignInspectionRuleEnum[]) {}

    public async start() {
        await new _UpdateAIDesignLintRuleConfig(this.thisDocId, this.rules).start()
    }
}

/**
 * 提交检查任务
 */
@POST
class _SubmitAIDesignLintTask extends BaseCommonRequest<SubmissionTaskResponse> {
    constructor(
        private readonly docId: string,
        private readonly pageId: string,
        private readonly libraryIds: string[]
    ) {
        super()
    }

    public override requestBody() {
        return this.libraryIds
    }

    public override requestArgument() {
        return {
            pageNodeId: this.pageId,
        }
    }

    public override requestUrl() {
        return `ai/design-inspection/${this.docId}/inspection`
    }
}

export class SubmitAIDesignLintTask {
    constructor(
        private readonly thisDocId: DocID,
        private readonly pageId: string,
        private readonly libraryIds: string[]
    ) {}

    public async start() {
        const request = new _SubmitAIDesignLintTask(this.thisDocId, this.pageId, this.libraryIds)
        try {
            return (await request.start()).taskId
        } catch (_) {
            throw (request.responseObject as unknown as ExtractionFailedResponse).businessStatus
        }
    }
}

/**
 * 提交检查任务 V2
 * 附带相关资源 OSS URL 供 AI 业务下载
 */
@POST
class _SubmitAIDesignLintTaskV2 extends BaseCommonRequest<SubmissionTaskResponse> {
    constructor(
        private readonly docId: string,
        private readonly pageId: string,
        private readonly libraryIds: string[],
        private readonly docJsonUrl: string,
        private readonly nodeId2ImageUrl: { [k: string]: string }
    ) {
        super()
    }

    public override requestBody() {
        return { libraryIds: this.libraryIds, docJsonUrl: this.docJsonUrl, nodeId2ImageUrl: this.nodeId2ImageUrl }
    }

    public override requestArgument() {
        return {
            pageNodeId: this.pageId,
        }
    }

    public override requestUrl() {
        return `ai/design-inspection/${this.docId}/inspection/v2`
    }
}

export class SubmitAIDesignLintTaskV2 {
    constructor(
        private readonly thisDocId: DocID,
        private readonly pageId: string,
        private readonly libraryIds: string[],
        private readonly docJsonUrl: string,
        private readonly nodeId2ImageUrl: { [k: string]: string }
    ) {}

    public async start() {
        const request = new _SubmitAIDesignLintTaskV2(
            this.thisDocId,
            this.pageId,
            this.libraryIds,
            this.docJsonUrl,
            this.nodeId2ImageUrl
        )
        try {
            return (await request.start()).taskId
        } catch (_) {
            throw (request.responseObject as unknown as ExtractionFailedResponse).businessStatus
        }
    }
}

/**
 * 查询检查 任务状态 / 任务结果
 */
@GET
class _QueryAIDsignLintTask extends BaseCommonRequest<AiDesignInspectionTaskResultVO> {
    constructor(private readonly taskId: string) {
        super()
    }

    public override requestUrl() {
        return `ai/design-inspection/inspection/${this.taskId}`
    }
}

export class QueryAIDsignLintTask {
    constructor(private readonly taskId: string) {}

    public async start() {
        const taskStatus = await new _QueryAIDsignLintTask(this.taskId).start()

        return taskStatus
    }
}

/**
 * 取消进行中的检查任务
 */
@POST
class _CancelAIDesignLintTask extends BaseCommonRequest<null> {
    constructor(private readonly taskId: string) {
        super()
    }

    public override requestUrl() {
        return `ai/design-inspection/${this.taskId}/cancel`
    }
}

export class CancelAIDesignLintTask {
    constructor(private readonly taskId: TaskID) {}

    public async start() {
        await new _CancelAIDesignLintTask(this.taskId).start()
    }
}

/**
 * OSS url
 */
@GET
class _GetOSSUploadURLForImg extends BaseCommonRequest<ResourceUploadResponse> {
    constructor(private readonly resourceId: string) {
        super()
    }

    public override requestUrl() {
        return `ai/design-inspection/upload/url?resourceId=${this.resourceId}&format=png`
    }
}

export class GetOSSUploadURLForImg {
    constructor(private readonly resourceId: string) {}

    public async start() {
        return await new _GetOSSUploadURLForImg(this.resourceId).start()
    }
}

@GET
class _GetOSSUploadURLForDocJson extends BaseCommonRequest<ResourceUploadResponse> {
    constructor(private readonly resourceId: string) {
        super()
    }

    public override requestUrl() {
        return `ai/design-inspection/upload/url?resourceId=${this.resourceId}&format=json`
    }
}

export class GetOSSUploadURLForDocJson {
    constructor(private readonly resourceId: string) {}

    public async start() {
        return await new _GetOSSUploadURLForDocJson(this.resourceId).start()
    }
}
