import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    Start: '开始使用研发模式',
    StartContent1: '选择你的开发语言、单位与倍率。',
    Inspect: '高级标注',
    InspectContent1: '选中图层，在画布、右侧面板查看图层的布局属性、样式属性与代码。',
    Export: '导出',
    ExportContent1: '在 {{name}}研发模式，自由且高效的导出。',
    ExportContent2: '你可以按照客户端类型选择导出预设，按照需选择倍率和文件格式。',
} as const

export const enTranslation = {
    Start: 'Welcom to Develop Mode',
    StartContent1: 'Choose your platform, units, and scales.',
    Inspect: 'Advanced inspect',
    InspectContent1:
        'Select the layers and then view their layout properties, style properties, and code in the canva and rightside panel.',
    Export: 'Export',
    ExportContent1:
        'In Develop Mode, you can export preset based on the platform types, selecting units, scales, and file formats. ',
    ExportContent2: '',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
