import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    EnableFailed: '订阅失败',
    DisableFailed: '取消订阅失败',
} as const

export const enTranslation = {
    EnableFailed: 'An error occurred while enabling',
    DisableFailed: 'An error occurred while disabling',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
