import { translation } from './stroke-dash-cap.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import {
    IconStrokeEndPointNone,
    IconStrokeEndPointRound,
    IconStrokeEndPointSquare,
    Tooltip,
} from '../../../../../../../ui-lib/src'
import { useCallback, useMemo } from 'react'
import { SelectIconGroup } from '../../../atom/button/select-button-group'
import { SingleGrid } from '../../../atom/grid/single-grid'
import style from '../advance-stroke/advance-stroke.module.less'
import { useStrokeDashCap } from './use-stroke-dash-cap'

export function StrokeDashCap() {
    const { dashCapState, onChangeDashCap } = useStrokeDashCap()

    const onClickIcon = useCallback(
        (value: Wukong.DocumentProto.StrokeCap) => {
            onChangeDashCap(value)
        },
        [onChangeDashCap]
    )

    const optionValue = useMemo(() => {
        return [
            Wukong.DocumentProto.StrokeCap.STROKE_CAP_NONE,
            Wukong.DocumentProto.StrokeCap.STROKE_CAP_SQUARE,
            Wukong.DocumentProto.StrokeCap.STROKE_CAP_ROUND,
        ]
    }, [])

    return dashCapState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_HIDDEN ? (
        <></>
    ) : (
        <SingleGrid className={style.grid}>
            <SingleGrid.Item start={5} span={28}>
                {translation('DashCap')}
            </SingleGrid.Item>
            <SingleGrid.Item start={35} span={22}>
                <SelectIconGroup optionValue={optionValue} onClickIcon={onClickIcon}>
                    <Tooltip title={translation('None')}>
                        <SelectIconGroup.Item
                            value={Wukong.DocumentProto.StrokeCap.STROKE_CAP_NONE}
                            icon={<IconStrokeEndPointNone />}
                            selected={dashCapState?.value === Wukong.DocumentProto.StrokeCap.STROKE_CAP_NONE}
                        />
                    </Tooltip>
                    <Tooltip title={translation('Square')}>
                        <SelectIconGroup.Item
                            value={Wukong.DocumentProto.StrokeCap.STROKE_CAP_SQUARE}
                            icon={<IconStrokeEndPointSquare />}
                            selected={dashCapState?.value === Wukong.DocumentProto.StrokeCap.STROKE_CAP_SQUARE}
                        />
                    </Tooltip>
                    <Tooltip title={translation('Round')}>
                        <SelectIconGroup.Item
                            value={Wukong.DocumentProto.StrokeCap.STROKE_CAP_ROUND}
                            icon={<IconStrokeEndPointRound />}
                            selected={dashCapState?.value === Wukong.DocumentProto.StrokeCap.STROKE_CAP_ROUND}
                        />
                    </Tooltip>
                </SelectIconGroup>
            </SingleGrid.Item>
        </SingleGrid>
    )
}
