import { translation } from './scrub-cursor.translation'
/* eslint-disable no-restricted-imports */
import { useCallback, useEffect, useMemo } from 'react'
import style from './scrub-cursor.module.less'
// eslint-disable-next-line import/default
import { WKToast } from '../../../../../../../ui-lib/src'
import ReactDOM from 'react-dom'
import { useUpdateEffect } from 'react-use'
import svg_resize_scroll_1_1 from '../../../../assets/resize-cursor/svg-resize-scroll-1-1.svg'
import svg_resize_scroll_1_2 from '../../../../assets/resize-cursor/svg-resize-scroll-1-2.svg'
import svg_resize_scroll_1_4_8 from '../../../../assets/resize-cursor/svg-resize-scroll-1-4-8.svg'
import svg_resize_scroll_2_1 from '../../../../assets/resize-cursor/svg-resize-scroll-2-1.svg'
import svg_resize_scroll_4_1 from '../../../../assets/resize-cursor/svg-resize-scroll-4-1.svg'
import svg_resize_scroll_8_1 from '../../../../assets/resize-cursor/svg-resize-scroll-8-1.svg'

export enum Multiple {
    OneEight = 1 / 8,
    OneFour = 1 / 4,
    OneTwo = 1 / 2,
    OneOne = 1,
    TwoOne = 2,
    FourOne = 4,
    EightOne = 8,
}

const multipleCursor: Map<Multiple, any> = new Map([
    [Multiple.OneEight, svg_resize_scroll_1_4_8],
    [Multiple.OneFour, svg_resize_scroll_1_4_8],
    [Multiple.OneTwo, svg_resize_scroll_1_2],
    [Multiple.OneOne, svg_resize_scroll_1_1],
    [Multiple.TwoOne, svg_resize_scroll_2_1],
    [Multiple.FourOne, svg_resize_scroll_4_1],
    [Multiple.EightOne, svg_resize_scroll_8_1],
])

export interface ScrubCursorProps {
    x: number
    y: number
    multiple: Multiple
    useSystemCursor?: boolean
}

export function ScrubCursor(props: ScrubCursorProps) {
    const { x, y, multiple, useSystemCursor } = props

    const innerStyle = useCallback(() => {
        return { transform: `translate3d(${x}px,${y + 1}px, 0px)` }
    }, [x, y])

    const resizeScrollCursor = useMemo(() => {
        return multipleCursor.get(multiple) ?? multipleCursor.get(Multiple.OneOne)
    }, [multiple])

    useUpdateEffect(() => {
        WKToast.show(getMultipleToast(props.multiple))
    }, [props.multiple])

    useEffect(() => {
        if (useSystemCursor) {
            document.documentElement.classList.add(style.html)
        }
        return () => {
            document.documentElement.classList.remove(style.html)
        }
    }, [useSystemCursor])

    return useSystemCursor
        ? null
        : ReactDOM.createPortal(
              <div className={`${style.container}`} style={innerStyle()} data-textid="ScrubCursor">
                  <img src={resizeScrollCursor} />
              </div>,
              document.body
          )
}

export function getMultipleByDeltaY(deltaY: number) {
    switch (true) {
        case deltaY <= -760:
            return Multiple.EightOne
        case deltaY <= -400:
            return Multiple.FourOne
        case deltaY <= -200:
            return Multiple.TwoOne
        case deltaY > 760:
            return Multiple.OneEight
        case deltaY > 400:
            return Multiple.OneFour
        case deltaY > 200:
            return Multiple.OneTwo
        default:
            return Multiple.OneOne
    }
}

export function getMultipleToast(multiple: Multiple) {
    switch (multiple) {
        case Multiple.EightOne:
            return `${translation('ScrubbingAt')} 8x`
        case Multiple.FourOne:
            return `${translation('ScrubbingAt')} 4x`
        case Multiple.TwoOne:
            return `${translation('ScrubbingAt')} 2x`
        case Multiple.OneEight:
            return `${translation('ScrubbingAt')} 1/8`
        case Multiple.OneFour:
            return `${translation('ScrubbingAt')} 1/4`
        case Multiple.OneTwo:
            return `${translation('ScrubbingAt')} 1/2`
        default:
            return `${translation('ScrubbingAt')} 1x`
    }
}
