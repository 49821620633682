import { translation } from './place-image-tool.translation'
/* eslint-disable no-restricted-imports */
import { MonoIconToolbarMove24, WKButton } from '../../../../../../ui-lib/src'
import { EditorMode } from '../../../../document/node/node'
import { Switch } from '../../atom/switch/switch'
import style from './place-image-tool.module.less'
import { useToolbar } from './use-toolbar'

export function PlaceImageTool() {
    const { editorMode, onPlaceAll, onCancelPlacing } = useToolbar()
    return (
        <Switch active={editorMode} onSwitchChange={() => onCancelPlacing()}>
            <Switch.Item className={style.switchItem44} key={EditorMode.EM_PlaceImage}>
                <MonoIconToolbarMove24 />
            </Switch.Item>
            <div className={style.placeImage}>
                <div className={style.tipMessage}>{translation('ClickOrDrag')}</div>
                <WKButton type="primary" onClick={() => onPlaceAll()} className={style.button}>
                    {translation('PlaceAll')}
                </WKButton>
                <WKButton type="secondary" onClick={() => onCancelPlacing()} className={style.button}>
                    {translation('DiscardAll')}
                </WKButton>
            </div>
        </Switch>
    )
}
