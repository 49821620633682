import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    FGmEgR: '单位',
    fWUuqn: '倍率',
    NtLxAi: '根元素字号',
    nquVve: '仅在文本属性上应用 rem',
    QjKJps: '代码设置',
    pcBcgJ: '代码类型',
} as const

export const enTranslation = {
    FGmEgR: 'Unit',
    fWUuqn: 'Scale',
    NtLxAi: 'Root font size',
    nquVve: 'Only apply rem on text properties',
    QjKJps: 'Code settings',
    pcBcgJ: 'Language',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
