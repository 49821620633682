/* eslint-disable no-restricted-imports */
import { ResizeCanvasCommand, ResizeViewportCommand } from '@wukong/bridge-proto'
import { BridgeCommand } from './command-invoker'
import { CommitType } from './commit-type'

export const DO_NOT_USE_THIS_cmdSetRenderViewport: BridgeCommand<[HTMLCanvasElement]> = (ctx, canvas) => {
    const { width, height } = canvas.getBoundingClientRect()
    ctx.commandInvoker.invokeBridge(CommitType.Noop, ResizeCanvasCommand, {
        width: width * devicePixelRatio,
        height: height * devicePixelRatio,
        devicePixelRatio: window.devicePixelRatio,
    })
}

/**
 * 场景 1：正常 resize window，则 canvas 元素的 size 会变，此时 zoom 不变，且 x,y 不变，只会改变 width, height
 * 场景 2：拖动左边图层面板大小，此时 canvas 元素的 size 会变，zoom 不变，但 x 要变 y 不变，拖拽过程中要记录原始 canvas 大小，根据差值重置 x
 * 场景 3：隐藏/展示 UI，此时左边图层面板收起，同场景 2 一样改变 x，导航栏收起，类似地改变 y，zoom 不变
 */
export const cmdResizeCanvas: BridgeCommand<[canvas: HTMLCanvasElement, dLeft: number, dTop: number]> = (
    ctx,
    canvas,
    dLeft,
    dTop
) => {
    const { width, height } = canvas.getBoundingClientRect()
    // update current viewport, with dLeft dTop
    ctx.commandInvoker.invokeBridge(CommitType.Noop, ResizeViewportCommand, { width, height, dLeft, dTop })
    // update wasm render canvas size
    ctx.commandInvoker.invokeBridge(CommitType.Noop, ResizeCanvasCommand, {
        width: width * devicePixelRatio,
        height: height * devicePixelRatio,
        devicePixelRatio: window.devicePixelRatio,
    })
    // update HTMLCavnasElement pixel
    canvas.width = width * window.devicePixelRatio
    canvas.height = height * window.devicePixelRatio
}
