/**
 * SessionStorage key
 */
export enum SessionStorageKey {
    // ------------- For Test ------------- //
    Test = 'test',
    Test2 = 'test2',

    // ------------- Web -------------- //
    UserTriggerLogin = 'user-trigger-login',
    UserTriggerLogout = 'user-trigger-logout',
    SessionTokenEmail = 'session-token-email',
    SessionTokenOtpToken = 'session-token-otp-token',
    SessionTokenOtp = 'session-token-otp',
    SessionTokenVerifiedOtpKeyOtp = 'session-token-verified-otp-key-otp',
    ReloadTimeKey = 'reload-gpu-lost',
    DisableWebGPU = 'disable-webgpu',
    ColorMode = 'colorMode',
    PreviewExpanded = 'preview-expanded',
    DevModePreviewExpanded = 'dev-mode-preview-expanded',
    FirstPath = 'first-path',
    DuplicateResource = 'duplicate-resource',

    // ------------- Variable ------------- //
    VariableLocalEditorPopupWidth = 'variable-local-editor-popup-width',
    VariableLocalEditorPopupHeight = 'variable-local-editor-popup-height',
    VariableLocalEditorPopupLeft = 'variable-local-editor-popup-left',
    VariableLocalEditorPopupTop = 'variable-local-editor-popup-top',
    VariableLocalEditorSidebarCollapsedGroups = 'variable-local-editor-sidebar-collapsed-groups',
}
