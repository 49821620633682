/* eslint-disable no-restricted-imports */
import { CanvasSearchInputFocusAndSelectAll, CanvasSearchResultListScrollTopTo } from '@wukong/bridge-proto'
import { Bridge } from '../../kernel/bridge/bridge'

export class CanvasSearchService {
    private focusInputAndSelectAll: () => void = () => {}
    private resultListScrollTo: (value: number) => void = () => {}

    constructor(private bridge: Bridge) {
        this.bridge.bind(CanvasSearchInputFocusAndSelectAll, () => this.focusInputAndSelectAll())
        this.bridge.bind(CanvasSearchResultListScrollTopTo, ({ value }) => this.resultListScrollTo(Number(value)))
    }

    setFocusInputCallback = (cb: () => void) => {
        this.focusInputAndSelectAll = cb
    }

    setResultListScrollTopToCallback = (cb: (value: number) => void) => {
        this.resultListScrollTo = cb
    }

    invokeFocusInputAndSelectAll = () => {
        this.focusInputAndSelectAll()
    }

    destroy() {
        this.bridge.unbind(CanvasSearchInputFocusAndSelectAll)
        this.bridge.unbind(CanvasSearchResultListScrollTopTo)
        this.focusInputAndSelectAll = () => {}
        this.setResultListScrollTopToCallback = () => {}
    }
}
