/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { useViewState } from '../../../../view-state-bridge'
import { EditorDataTestId } from '../../../../window'
import { StrokeOtherSetting } from './stroke-other-setting/stroke-other-setting'
import { StrokePaint } from './stroke-paint/stroke-paint'

export function StrokeV2() {
    const selectionStroke = useViewState('selectionStrokeV2')
    const showPanel = selectionStroke?.showPanel ?? false

    return showPanel ? (
        <div
            data-testid={EditorDataTestId.StrokeAttr.Container}
            className={classnames('pt-8px', selectionStroke?.paintState?.type ? 'pb-12px' : 'pb-8px')}
        >
            <StrokePaint />
            <StrokeOtherSetting />
        </div>
    ) : (
        <></>
    )
}
