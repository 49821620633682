export const IconPage = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path
                transform="matrix(1 0 0 1 2.5 1)"
                d="M6.58594 0L1 0C0.447754 0 0 0.447266 0 1L0 13C0 13.5527 0.447754 14 1 14L10 14C10.5522 14 11 13.5527 11 13L11 4.41406C11 4.14941 10.8945 3.89453 10.707 3.70703L7.29297 0.292969C7.10547 0.105469 6.85107 0 6.58594 0ZM1 1L1 13L10 13L10 5L6.5 5L6.5 1L1 1ZM7.5 4L7.5 1.91406L9.58594 4L7.5 4Z"
                fillRule="evenodd"
                fill="rgb(26, 29, 37)"
            />
        </svg>
    )
}

export const IconMotiff = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path
                transform="matrix(1 0 0 1 2.5 1)"
                d="M6.58594 0L1 0C0.447754 0 0 0.447266 0 1L0 13C0 13.5527 0.447754 14 1 14L10 14C10.5522 14 11 13.5527 11 13L11 4.41406C11 4.14941 10.8945 3.89453 10.707 3.70703L7.29297 0.292969C7.10547 0.105469 6.85107 0 6.58594 0ZM1 1L1 13L10 13L10 5L6.5 5L6.5 1L1 1ZM7.5 4L7.5 1.91406L9.58594 4L7.5 4Z"
                fillRule="evenodd"
                fill="rgb(26, 29, 37)"
            />
            <path
                transform="matrix(1 0 0 1 5.5 8)"
                d="M-0.5 0L-0.5 4C-0.5 4.27614 -0.27614 4.5 0 4.5C0.27614 4.5 0.5 4.27614 0.5 4L0.5 1.38102L2.11589 3.32009Q2.14499 3.35501 2.17991 3.38411Q2.21774 3.41564 2.26099 3.43918Q2.30425 3.46272 2.35127 3.47737Q2.39828 3.49201 2.44725 3.49721Q2.49622 3.5024 2.54527 3.49795Q2.59431 3.49349 2.64154 3.47955Q2.68877 3.46561 2.73238 3.44272Q2.77598 3.41983 2.81428 3.38888Q2.85258 3.35792 2.88411 3.32009L4.5 1.38102L4.5 4C4.5 4.27614 4.72386 4.5 5 4.5C5.27614 4.5 5.5 4.27614 5.5 4L5.5 0Q5.5 -0.111292 5.4528 -0.212078Q5.40559 -0.312863 5.32009 -0.384111Q5.24369 -0.447781 5.14873 -0.477366Q5.05378 -0.506951 4.95473 -0.497947Q4.85568 -0.488942 4.76762 -0.442719Q4.67956 -0.396497 4.61589 -0.320092L2.5 2.21898L0.384111 -0.320092Q0.312863 -0.405589 0.212078 -0.452795Q0.111292 -0.5 0 -0.5Q-0.0492457 -0.5 -0.0975452 -0.490393Q-0.145845 -0.480785 -0.191342 -0.46194Q-0.236839 -0.443094 -0.277785 -0.415735Q-0.318731 -0.388375 -0.353553 -0.353553Q-0.388375 -0.318731 -0.415735 -0.277785Q-0.443094 -0.236839 -0.46194 -0.191342Q-0.480785 -0.145845 -0.490393 -0.0975452Q-0.5 -0.0492457 -0.5 0Z"
                fillRule="evenodd"
                fill="rgb(0, 0, 0)"
            />
        </svg>
    )
}
