import { translation } from './index.translation'
import { StartHoveredHyperlinkEditing, Wukong } from '@wukong/bridge-proto'
import { useCommand, useHyperlinkService } from '../../../../../main/app-context'
import { DeepRequired, useViewState } from '../../../../../view-state-bridge'
import { HyperLinkIcon } from './hyperlink-icon'
import styles from './index.module.less'
import { usePopupPosition } from './use-popup-position'
import { frogEventToolsMenusAndActionsBasicAction } from '../../../../utils/tools-actions-frog'

function InternalHyperlinkHoverPopup({
    hyperlinkHoverPopupState,
}: {
    hyperlinkHoverPopupState: DeepRequired<Wukong.DocumentProto.IHyperlinkHoverPopupState>
}) {
    const command = useCommand()
    const { containerRef, top, left, positionType, gapHeight } = usePopupPosition(hyperlinkHoverPopupState)

    const docReadonly = useViewState('docReadonly')
    const isDevMode = useViewState('editorType') === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV
    const popupLinkEditable = !docReadonly && !isDevMode && hyperlinkHoverPopupState.editable

    const hyperlinkSerivce = useHyperlinkService()

    const handleHyperlinkClick = () => {
        hyperlinkSerivce.openHyperlink(hyperlinkHoverPopupState.hyperlink)
    }

    const isLinkNode = hyperlinkHoverPopupState.hyperlink.nodeId
    const label = isLinkNode ? translation('GoTo') : translation('Open')
    const content = isLinkNode
        ? hyperlinkHoverPopupState.hyperlinkNodeName
        : encodeURI(hyperlinkHoverPopupState.hyperlink.url)

    return (
        <div
            data-testid="hyperlink-hover-popup"
            className={styles.root}
            style={{ left: `${left}px`, top: `${top}px` }}
            onMouseEnter={() => hyperlinkSerivce.mouseEnterHyperlink()}
            onMouseLeave={() => hyperlinkSerivce.mouseLeaveHyperlink()}
        >
            {positionType == 'bottom' ? (
                <div className={styles.topGap} style={{ top: `${-gapHeight}px`, height: `${gapHeight}px` }}></div>
            ) : null}
            <div className={styles.container} ref={containerRef}>
                <div className={styles.left} onClick={handleHyperlinkClick}>
                    <HyperLinkIcon hyperlinkHoverPopupState={hyperlinkHoverPopupState} />
                    <div className={styles.label}>{label}</div>
                    <div className={styles.content}>{content}</div>
                </div>
                {popupLinkEditable && (
                    <div
                        className={styles.right}
                        onClick={() => {
                            command.DEPRECATED_invokeBridge(StartHoveredHyperlinkEditing)
                            frogEventToolsMenusAndActionsBasicAction({
                                action_name: '创建/编辑文字链',
                                operation_way: '链接气泡-编辑按钮',
                            })
                        }}
                    >
                        {translation('Edit')}
                    </div>
                )}
            </div>
            {positionType == 'top' ? (
                <div className={styles.buttomGap} style={{ height: `${gapHeight}px` }}></div>
            ) : null}
        </div>
    )
}

export function HyperlinkHoverPopup() {
    const hyperlinkHoverPopupState = useViewState('hyperlinkHoverPopupState')

    if (hyperlinkHoverPopupState?.show) {
        return <InternalHyperlinkHoverPopup hyperlinkHoverPopupState={hyperlinkHoverPopupState} />
    }

    return null
}
