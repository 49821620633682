import classnames from 'classnames'
import React from 'react'
import classes from './pay-switch.module.less'

export interface PaySwitchItem {
    active?: boolean
    content: React.ReactElement
    dataTestId?: string
    onClick?: () => void
}

export interface PaySwitchProps {
    className?: string
    left: PaySwitchItem
    right: PaySwitchItem
}

export function PaySwitch(props: PaySwitchProps) {
    const { className, left, right } = props
    return (
        <div className={classnames(classes.switch, className)}>
            <div
                className={classnames(classes.item, { [classes.active]: left.active })}
                onClick={left.onClick}
                data-testid={left.dataTestId}
            >
                {left.content}
            </div>
            <div
                className={classnames(classes.item, { [classes.active]: right.active })}
                onClick={right.onClick}
                data-testid={right.dataTestId}
            >
                {right.content}
            </div>
        </div>
    )
}
