import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Exporting: '正在导出',
} as const

export const enTranslation = {
    Exporting: 'Exporting',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
