import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    Instant: '即时',
    Dissolve: '溶解',
    MoveIn: '移入',
    MoveOut: '移出',
    Push: '推入',
    SlideIn: '滑入',
    SlideOut: '滑出',
    ScrollAnimate: '缓动动效',
    Mixed: '多个值',
    SmartAnimate: '智能动画',
} as const

export const enTranslation = {
    Instant: 'Instant',
    Dissolve: 'Dissolve',
    MoveIn: 'Move in',
    MoveOut: 'Move out',
    Push: 'Push',
    SlideIn: 'Slide in',
    SlideOut: 'Slide out',
    ScrollAnimate: 'Animate',
    Mixed: 'Mixed',
    SmartAnimate: 'Smart Animate',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
