import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Error: '加载失败，请重新加载',
    Retry: '重新加载',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    Error: 'An error occurred',
    Retry: 'Retry',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
