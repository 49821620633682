import {
    FeatureSwitchConfig,
    SwitchEnvironmentScopeType,
    SwitchStrategyType,
    UserGroupType,
} from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'motiff-scope-pretty-props',
    owner: '',
    description: '',
    strategies: [
        {
            env: SwitchEnvironmentScopeType.ALL,
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                userList: [
                    '905875fc0919c2dae30154a89c9722e9de6cb2fe',
                    '8924f0f3c2562122c6dce85e95a7e585a82ba76c',
                    'ba359f8f7cabb59bea6a1c75fceeffdef1f40827',
                    'c2520912ef296753265cdcdcf7564f496c39c41a',
                    '225eb66e04c4fbf5474dc54c786bcd347796f116',
                    '05cc727e3c9837ac3b846dccbb3be8066073adc9',
                    'fa41bf23ad54135e8310a19dcc012fbfb70d29f2',
                    '42dfaf3c6e424285078d88146c7e8083e4f41f67',
                    '4ec1663a76757c645d04b63c612a84e2a6c4e38b',
                    '4d0c1e32c00069c6a39541d2dcf2a0d7f7322487',
                    'c25a7c619bd3d207ef342fb9328434439de70bd6',
                    'a2c0199b5d34e5b9fd73c148b260dbe0f2a671be',
                    '67001c05d873b3128d9f09cb7faa91c12c9d4aef',
                    '96a0470e759201f48ceb2d2f9f134df24a508b59',
                ],
            },
        },
    ],
    alwaysDisableInTests: true,
}
export default Config
