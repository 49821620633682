import { translation } from './library-dialog-entry-btn.translation'

import classNames from 'classnames'
import { FC, HTMLAttributes } from 'react'
import { IconBookBigOpen16, Tooltip } from '../../../../../../../ui-lib/src'
import { isLoginExpired } from '../../../../../kernel/util/expire-login'
import { useLibraryComponentService } from '../../../../../main/app-context'
import { useViewState } from '../../../../../view-state-bridge'
import { useLibraryModalConfigContext } from '../../../../context/library-component-style/library-modal-config'
import { IconButton } from '../../../atom/button/icon-button'
import { LibraryShowPointContainer } from '../../library-show-point-container/library-show-point-container'
import styles from './library-dialog-entry-btn.module.less'

const AssetIconSvgContainer: FC<HTMLAttributes<HTMLSpanElement>> = (props) => {
    return (
        <span {...props} className={classNames(styles.svgContainer, props.className)} role="button">
            {props.children}
        </span>
    )
}

export function LibraryDialogEntryBtn() {
    const {
        libraryModalRouterService: { toggleLibraryMainModal },
    } = useLibraryComponentService()
    const {
        mainModalConfig: { show },
    } = useLibraryModalConfigContext()

    const libraryGlobalState = useViewState('libraryGlobalState')
    const docReadonly = useViewState('docReadonly')

    const onclickBtn = async () => {
        if (!(await isLoginExpired(false)) && document.getElementsByClassName('expireTimeModalPop').length <= 0) {
            toggleLibraryMainModal()
        }
    }

    return (
        <Tooltip title={translation('Libraries')}>
            <IconButton
                selected={false}
                icon={
                    <AssetIconSvgContainer
                        onClick={onclickBtn}
                        className={classNames({ [styles.active]: show })}
                        id={!docReadonly ? 'asset-container' : undefined}
                    >
                        <LibraryShowPointContainer showPoint={libraryGlobalState?.showBadge ?? false}>
                            <IconBookBigOpen16 />
                        </LibraryShowPointContainer>
                    </AssetIconSvgContainer>
                }
                className={styles.fill}
            />
        </Tooltip>
    )
}
