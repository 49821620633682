import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    PleaseSetPluginIcon: '请设置插件图标',
    PleaseSetPluginName: '请设置插件名称',
    PluginNameTooLong: '插件名称请不要超过 50 个字符',
} as const

export const enTranslation = {
    PleaseSetPluginIcon: 'A plugin must have a icon to publish',
    PleaseSetPluginName: 'A plugin must have a name to publish',
    PluginNameTooLong: 'Name is too long (Maximum is 50 characters)',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
