import { isNil } from 'lodash-es'
import { DocWithAuthorityVO, PayPlanType, PlanAndUserStateVO } from '../kernel/interface/type'
import { featureSwitchManager } from '../kernel/switch'

/**
 * 判断是否为一个正常使用功能的专业版
 * @param planInfo 履约信息
 * @returns 是否为一个正常使用功能的专业版
 */
export function isActiveProPlan(planInfo: PlanAndUserStateVO) {
    return !planInfo.planFreeze && planInfo.planType === PayPlanType.professional
}

/**
 * 判断是否为一个正常使用功能的企业版
 * @param planInfo 履约信息
 * @returns 是否为一个正常使用功能的企业版
 * @update 2025-02-27 新增高级企业版，该函数功能该为判断是否为一个正常使用功能的企业级计划(包含企业版和高级企业版)
 * TODO(zhengyihui): 后续改名为 isActiveOrgGradePlan
 */
export function isActiveOrgPlan(planInfo: PlanAndUserStateVO) {
    if (featureSwitchManager.isEnabled('organization-plus')) {
        return !planInfo.planFreeze && [PayPlanType.organization, PayPlanType.enterprise].includes(planInfo.planType)
    } else {
        return !planInfo.planFreeze && planInfo.planType === PayPlanType.organization
    }
}

/**
 * 判断是否为一个正常使用功能的高级企业版
 * @param planInfo 履约信息
 * @returns 是否为一个正常使用功能的高级企业版
 */
export function isActiveEnterprisePlan(planInfo: PlanAndUserStateVO) {
    return !planInfo.planFreeze && planInfo.planType === PayPlanType.enterprise
}

/**
 * 判断是否为基础版
 * @param planInfo 履约信息
 * @returns 是否为基础版
 */
export function isStarterPlan(planInfo: PlanAndUserStateVO) {
    return planInfo.planType === PayPlanType.starter
}

/**
 * 判断是否为试用专业版
 * @param planInfo 履约信息
 * @returns 是否为试用专业版
 */
export function isTrialProPlan(planInfo: PlanAndUserStateVO) {
    return planInfo.planType === PayPlanType.professional && planInfo.trial
}

const OTHER_TEAM_ORG_ID = '-1'

/**
 * 判断是否为其他团队的草稿
 * @param planInfo 履约信息
 * @param docInfo 文档信息
 * @returns 是否为其他团队的草稿
 */
export function inOtherTeamDraftBox(planInfo: PlanAndUserStateVO | undefined, docInfo: DocWithAuthorityVO) {
    return isNil(planInfo) && docInfo.draft && docInfo.orgId === OTHER_TEAM_ORG_ID
}

/**
 * 判断是否为免费团队且超出使用限制
 * @param planInfo 履约信息
 * @returns 是否为免费团队且超出使用限制
 */
export function isLimitedStarterPlan(planInfo: PlanAndUserStateVO) {
    return isStarterPlan(planInfo) && planInfo.exceedUsageLimit
}

/**
 * 判断是否为锁定专业版
 * @param planInfo 履约信息
 * @returns 是否为锁定专业版
 * 锁定原因：
 * 1. 试用期到期
 * 2. 欠费
 * 3. 服务期到期（停止付费）
 */
export function isLockedProPlan(planInfo: PlanAndUserStateVO) {
    return planInfo.planType === PayPlanType.professional && planInfo.planFreeze
}

/**
 * 判断是否为锁定企业版
 * @param planInfo 履约信息
 * @returns 是否为锁定企业版
 * @update 2025-02-27 新增高级企业版，该函数功能该为判断是否为锁定企业级计划(包含企业版和高级企业版)
 * TODO(zhengyihui): 后续改名为 isLockedOrgGradePlan
 */
export function isLockedOrgPlan(planInfo: PlanAndUserStateVO) {
    if (featureSwitchManager.isEnabled('organization-plus')) {
        return planInfo.planFreeze && [PayPlanType.organization, PayPlanType.enterprise].includes(planInfo.planType)
    } else {
        return planInfo.planType === PayPlanType.organization && planInfo.planFreeze
    }
}

/**
 * 判断是否为试用期过期导致锁定的企业版
 * @param planInfo 履约信息
 * @returns 是否为试用期过期导致锁定的企业版
 */
export function isLockedExpiredTrialOrgPlan(planInfo: PlanAndUserStateVO) {
    return isLockedOrgPlan(planInfo) && planInfo.trial
}
