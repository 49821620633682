/* eslint-disable no-restricted-imports */
import { WKFrog } from '../../../../kernel/frog'

// 下面是使用示例：
// logs.AiMagicBound.componentDrawSuccess()

// 下面是生成的代码：
export const logs = {
    AiMagicBound: {
        componentToolFocus(customExtend: { interaction_type: 'click' | 'hotkey' }) {
            WKFrog.addFrogRecord({
                url: '/event/AiMagicBound/componentToolFocus',
                eventId: 20858,
                eventName: 'componentToolFocus',
                eventAction: 'event',
                customExtend,
            })
        },
        textToolFocus(customExtend: { interaction_type: 'click' | 'hotkey' }) {
            WKFrog.addFrogRecord({
                url: '/event/AiMagicBound/textToolFocus',
                eventId: 20859,
                eventName: 'textToolFocus',
                eventAction: 'event',
                customExtend,
            })
        },
        componentDrawSuccess() {
            WKFrog.addFrogRecord({
                url: '/event/AiMagicBound/componentDrawSuccess',
                eventId: 20860,
                eventName: 'componentDrawSuccess',
                eventAction: 'event',
            })
        },
        switchToComponent() {
            WKFrog.addFrogRecord({
                url: '/event/AiMagicBound/switchToComponent',
                eventId: 20861,
                eventName: 'switchToComponent',
                eventAction: 'event',
            })
        },
        componentNoCandidate() {
            WKFrog.addFrogRecord({
                url: '/event/AiMagicBound/componentNoCandidate',
                eventId: 20872,
                eventName: 'componentNoCandidate',
                eventAction: 'event',
            })
        },
        componentInputName() {
            WKFrog.addFrogRecord({
                url: '/event/AiMagicBound/componentInputName',
                eventId: 20873,
                eventName: 'componentInputName',
                eventAction: 'event',
            })
        },
        componentInputText() {
            WKFrog.addFrogRecord({
                url: '/event/AiMagicBound/componentInputText',
                eventId: 20874,
                eventName: 'componentInputText',
                eventAction: 'event',
            })
        },
        componentSelectSuccess(customExtend: { rank: number }) {
            WKFrog.addFrogRecord({
                url: '/click/AiMagicBound/componentSelectSuccess',
                eventId: 20875,
                eventName: 'componentSelectSuccess',
                eventAction: 'click',
                customExtend,
            })
        },
        componentQuit() {
            WKFrog.addFrogRecord({
                url: '/event/AiMagicBound/componentQuit',
                eventId: 20876,
                eventName: 'componentQuit',
                eventAction: 'event',
            })
        },
        textDrawSuccess() {
            WKFrog.addFrogRecord({
                url: '/event/AiMagicBound/textDrawSuccess',
                eventId: 20877,
                eventName: 'textDrawSuccess',
                eventAction: 'event',
            })
        },
        switchToText() {
            WKFrog.addFrogRecord({
                url: '/event/AiMagicBound/switchToText',
                eventId: 20878,
                eventName: 'switchToText',
                eventAction: 'event',
            })
        },
        textNoCandidate() {
            WKFrog.addFrogRecord({
                url: '/event/AiMagicBound/textNoCandidate',
                eventId: 20879,
                eventName: 'textNoCandidate',
                eventAction: 'event',
            })
        },
        textSelectSuccess(customExtend: { rank: number }) {
            WKFrog.addFrogRecord({
                url: '/click/AiMagicBound/textSelectSuccess',
                eventId: 20880,
                eventName: 'textSelectSuccess',
                eventAction: 'click',
                customExtend,
            })
        },
        textQuit() {
            WKFrog.addFrogRecord({
                url: '/event/AiMagicBound/textQuit',
                eventId: 20881,
                eventName: 'textQuit',
                eventAction: 'event',
            })
        },
    },
}
