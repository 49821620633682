import { translation } from './blend-mode-select.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { useMemo } from 'react'
import { MonoIconPanelBlend16, MonoIconPanelBlendEmpty16, Select } from '../../../../../../ui-lib/src'
import classes from './blend-mode-select.module.less'

interface BlendModeOption {
    name: string
    needSplitLineTop?: boolean
}
export const blendModeToShow = new Map<Wukong.DocumentProto.BlendMode, BlendModeOption>([
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_PASS_THROUGH, { name: translation('PassThrough') }],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL, { name: translation('Normal') }],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_DARKEN, { name: translation('Darken'), needSplitLineTop: true }],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_MULTIPLY, { name: translation('Multiply') }],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_LINEAR_BURN, { name: translation('LinearBurn') }],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_COLOR_BURN, { name: translation('ColorBurn') }],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_LIGHTEN, { name: translation('Lighten'), needSplitLineTop: true }],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_SCREEN, { name: translation('Screen') }],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_LINEAR_DODGE, { name: translation('LinearDodge') }],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_COLOR_DODGE, { name: translation('PlusLighter') }],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_OVERLAY, { name: translation('Overlay'), needSplitLineTop: true }],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_SOFT_LIGHT, { name: translation('SoftLight') }],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_HARD_LIGHT, { name: translation('HardLight') }],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_DIFFERENCE, { name: translation('Difference'), needSplitLineTop: true }],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_EXCLUSION, { name: translation('Exclusion') }],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_HUE, { name: translation('Hue'), needSplitLineTop: true }],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_SATURATION, { name: translation('Saturation') }],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_COLOR, { name: translation('Color') }],
    [Wukong.DocumentProto.BlendMode.BLEND_MODE_LUMINOSITY, { name: translation('Luminosity') }],
])

interface BlendModeSelectProps {
    value?: Wukong.DocumentProto.BlendMode
    isMixed?: boolean
    onlyIcon?: boolean
    removeIcon?: boolean
    arrowClassName?: string
    isDisabledStyle?: boolean
    onChange?: ((value: any) => void) | undefined
    dataTestIds?: {
        select?: string
        trigger?: string
    }
}
export function BlendModeSelect(props: BlendModeSelectProps) {
    const {
        value = Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
        isMixed,
        onlyIcon,
        removeIcon,
        arrowClassName,
        isDisabledStyle,
        onChange,
    } = props

    const selectIcon = useMemo(() => {
        const isEmptyWaterIcon =
            value === Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL ||
            value === Wukong.DocumentProto.BlendMode.BLEND_MODE_PASS_THROUGH
        return isEmptyWaterIcon ? <MonoIconPanelBlendEmpty16 /> : <MonoIconPanelBlend16 />
    }, [value])

    return onlyIcon ? (
        <Select.IconSingleLevel
            isMixed={isMixed}
            value={value}
            isLightColor
            label={selectIcon}
            onChange={onChange}
            dataTestIds={{
                container: 'blend-mode-container',
                triggerContainer: props.dataTestIds?.select ?? 'blend-mode-select',
                triggerFocus: 'blend-mode-trigger',
            }}
            classNameTriggerFocus={classes.icon}
        >
            {[...blendModeToShow.entries()].map(([key, v]) =>
                key === Wukong.DocumentProto.BlendMode.BLEND_MODE_PASS_THROUGH ? null : (
                    <Select.IconSingleLevel.Option key={key} value={key} splitLineTop={v.needSplitLineTop}>
                        {v.name}
                    </Select.IconSingleLevel.Option>
                )
            )}
        </Select.IconSingleLevel>
    ) : (
        <Select.NormalSingleLevel
            isMixed={isMixed}
            value={value}
            isDisabledStyle={isDisabledStyle}
            icon={removeIcon ? undefined : selectIcon}
            label={blendModeToShow.get(value)?.name}
            onChange={onChange}
            hoverMoveToRight
            dataTestIds={{ triggerContainer: 'blend-mode-select', tabElement: 'blend-mode-trigger-focus' }}
            arrowClassName={arrowClassName}
        >
            {[...blendModeToShow.entries()].map(([key, v]) => (
                <Select.NormalSingleLevel.Option
                    key={key}
                    value={key}
                    backwardIcon={''}
                    splitLineTop={v.needSplitLineTop}
                >
                    {v.name}
                </Select.NormalSingleLevel.Option>
            ))}
        </Select.NormalSingleLevel>
    )
}
