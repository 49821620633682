import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    UnableToView: '因评论所在页面被删除，无法查看此评论',
    CanNotFind: '无页面',
} as const

export const enTranslation = {
    UnableToView: 'This comment cannot be viewed because the page has been deleted',
    CanNotFind: 'Can not find this page',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
