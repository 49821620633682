import { domLocation } from '../../../../util/src'
import { IndexedDBName } from '../../web-storage/indexed-db/config'

// 获取在 DB 存储的回放前缀
export const ReplayDBPrefix = IndexedDBName.RecordReplay + '_'

// 获取 replayId, 为 dbname 去掉前缀
export const getReplayId = (dbName: string): number => {
    return Number(dbName.substring(ReplayDBPrefix.length))
}

// 获取回放的下载 id
export const getReplayDownloadUrl = (dbName: string) => {
    const replayId = getReplayId(dbName)

    const href = domLocation().href

    // 以 /app/ 标志结尾
    if (href.includes('review') || href.includes('commit') || href.includes('-release')) {
        return href.replace(/(.*app).*/, '$1') + `/workbench/recording-downloader/${replayId}`
    }

    return `${domLocation().origin}/workbench/recording-downloader/${replayId}`
}
