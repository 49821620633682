import { translation } from './frame-template-dom.translation'
/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { useCallback } from 'react'
import { MonoIconCommonEnterDown16, MonoIconCommonEnterRight16 } from '../../../../../../ui-lib/src'
import { Title } from '../../atom/title/title'
import classes from './frame-template-dom.module.less'
import { FrameTemplateData, FrameTemplateGroupName, FrameTemplateItem } from './template-data'

export interface FrameTemplateDOMProps
    extends Pick<GroupNameProps, 'toggleOpen'>,
        Pick<GroupItemProps, 'applyTemplate'> {
    openKey: string
    data: FrameTemplateData
}

export function FrameTemplateDOM(props: FrameTemplateDOMProps) {
    const { openKey, data, toggleOpen, applyTemplate } = props
    return (
        <div className={classes.container}>
            <Title>
                <Title.Left>{translation('Frame')}</Title.Left>
            </Title>
            {[...data.entries()].map(([name, items]) => (
                <GroupName data={name} isOpen={name.code === openKey} key={name.code} toggleOpen={toggleOpen}>
                    {items.map((item) => (
                        <GroupItem data={item} key={item.name} applyTemplate={applyTemplate} />
                    ))}
                </GroupName>
            ))}
        </div>
    )
}

interface GroupNameProps {
    data: FrameTemplateGroupName
    isOpen: boolean
    children: React.ReactNode
    toggleOpen?: (data: FrameTemplateGroupName) => void
}
function GroupName(props: GroupNameProps) {
    const { data, isOpen, children, toggleOpen } = props
    const _toggleOpen = useCallback(() => {
        toggleOpen?.(data)
    }, [data, toggleOpen])
    return (
        <div className={classnames(classes.group, { [classes.open]: isOpen })}>
            <div className={classes.groupName} onClick={_toggleOpen} data-testid={data.name}>
                <span className={classes.arrowSvg}>
                    {isOpen ? (
                        <MonoIconCommonEnterDown16 className="color-$wk-gray-6" />
                    ) : (
                        <MonoIconCommonEnterRight16 className="color-$wk-gray-6" />
                    )}
                </span>
                {data.name}
            </div>
            {isOpen ? children : null}
        </div>
    )
}

interface GroupItemProps {
    data: FrameTemplateItem
    applyTemplate?: (data: FrameTemplateItem) => void
}
function GroupItem(props: GroupItemProps) {
    const { data, applyTemplate } = props
    const _applyTemplate = useCallback(() => {
        applyTemplate?.(data)
    }, [data, applyTemplate])
    return (
        <div className={classes.item} onClick={_applyTemplate} data-testid={data.name}>
            <span className={classes.itemName}>{data.name}</span>
            <span className={classes.itemSize}>
                <span>{data.width}</span>
                <span className={classes.multiply}>×</span>
                <span>{data.height}</span>
            </span>
        </div>
    )
}
