import { environment } from '../../../../environment'
import { translation } from './template-data.translation'
export enum FrameTemplateGroupNameCode {
    Phone = 'phone',
    IPad = 'iPad',
    PC = 'pc',
    SmartWatch = 'smartWatch',
    SocialMedia = 'socialMedia',
    Paper = 'paper',
    Other = 'other',
}

export interface FrameTemplateGroupName {
    name: string // 展示
    code: FrameTemplateGroupNameCode // 存储到用户账号的数据
}

export interface FrameTemplateItem {
    name: string
    width: number
    height: number
}

export type FrameTemplateData = Map<FrameTemplateGroupName, FrameTemplateItem[]>

export const groupNames: Record<FrameTemplateGroupNameCode, FrameTemplateGroupName> = {
    [FrameTemplateGroupNameCode.Phone]: { code: FrameTemplateGroupNameCode.Phone, name: translation('Phone') },
    [FrameTemplateGroupNameCode.IPad]: { code: FrameTemplateGroupNameCode.IPad, name: translation('Tablet') },
    [FrameTemplateGroupNameCode.PC]: { code: FrameTemplateGroupNameCode.PC, name: translation('Desktop') },
    [FrameTemplateGroupNameCode.SmartWatch]: {
        code: FrameTemplateGroupNameCode.SmartWatch,
        name: translation('Watch'),
    },
    [FrameTemplateGroupNameCode.SocialMedia]: {
        code: FrameTemplateGroupNameCode.SocialMedia,
        name: translation('SocialMedia'),
    },
    [FrameTemplateGroupNameCode.Paper]: {
        code: FrameTemplateGroupNameCode.Paper,
        name: translation('Paper'),
    },
    [FrameTemplateGroupNameCode.Other]: { code: FrameTemplateGroupNameCode.Other, name: translation('Others') },
}

export const groupItems = () => {
    const res = {
        [FrameTemplateGroupNameCode.Phone]: [
            { name: 'iPhone 16 Pro Max', width: 440, height: 956 },
            { name: 'iPhone 16 Plus', width: 430, height: 932 },
            { name: 'iPhone 16 Pro', width: 402, height: 874 },
            { name: 'iPhone 16', width: 393, height: 852 },
            { name: 'iPhone 14 & 15 Pro Max', width: 430, height: 932 },
            { name: 'iPhone 14 & 15 Pro', width: 393, height: 852 },
            { name: 'iPhone 13 & 14', width: 390, height: 844 },
            { name: 'iPhone 14 Plus', width: 428, height: 926 },
            { name: 'iPhone 13 Pro Max', width: 428, height: 926 },
            { name: 'iPhone 13 mini', width: 375, height: 812 },
            { name: 'iPhone 11 Pro Max', width: 414, height: 896 },
            { name: 'iPhone 11 Pro / X', width: 375, height: 812 },
            { name: 'iPhone SE', width: 320, height: 568 },
            { name: 'iPhone 8 Plus', width: 414, height: 736 },
            { name: 'iPhone 8', width: 375, height: 667 },
            { name: 'Android Small', width: 360, height: 640 },
            { name: 'Android Large', width: 360, height: 800 },
        ],
        [FrameTemplateGroupNameCode.IPad]: [
            { name: 'iPad mini 9.7"', width: 768, height: 1024 },
            { name: 'iPad mini 8.3"', width: 744, height: 1133 },
            { name: 'iPad Pro 11"', width: 834, height: 1194 },
            { name: 'iPad Pro 12.9"', width: 1024, height: 1366 },
        ],
        [FrameTemplateGroupNameCode.PC]: [
            { name: 'Web 1920', width: 1920, height: 1080 },
            { name: 'Web 1440', width: 1440, height: 900 },
            { name: 'Web 1280', width: 1280, height: 800 },
            { name: 'MacBook Air', width: 1280, height: 832 },
            { name: 'MacBook Pro 14"', width: 1512, height: 982 },
            { name: 'MacBook Pro 16"', width: 1728, height: 1117 },
            { name: 'iMac', width: 1280, height: 720 },
            { name: 'TV', width: 1280, height: 720 },
        ],
        [FrameTemplateGroupNameCode.SmartWatch]: [
            { name: 'Apple Watch 49mm', width: 205, height: 251 },
            { name: 'Apple Watch 45mm', width: 198, height: 242 },
            { name: 'Apple Watch 44mm', width: 184, height: 224 },
            { name: 'Apple Watch 42mm', width: 156, height: 195 },
            { name: 'Apple Watch 41mm', width: 176, height: 215 },
            { name: 'Apple Watch 40mm', width: 162, height: 197 },
            { name: 'Apple Watch 38mm', width: 136, height: 170 },
        ],
        [FrameTemplateGroupNameCode.SocialMedia]: [] as FrameTemplateItem[],
        [FrameTemplateGroupNameCode.Paper]: [
            { name: 'A0', width: 2384, height: 3370 },
            { name: 'A1', width: 1684, height: 2384 },
            { name: 'A2', width: 1191, height: 1684 },
            { name: 'A3', width: 842, height: 1191 },
            { name: 'A4', width: 595, height: 842 },
            { name: 'A5', width: 420, height: 595 },
            { name: 'A6', width: 297, height: 420 },
        ],
        [FrameTemplateGroupNameCode.Other]: [{ name: `Motiff ${translation('FileCover')}`, width: 1600, height: 960 }],
    }
    if (environment.isAbroad) {
        res[FrameTemplateGroupNameCode.SocialMedia].push(
            { name: 'Twitter post', width: 1200, height: 675 },
            { name: 'Twitter header', width: 1500, height: 500 },
            { name: 'Facebook post', width: 1200, height: 630 },
            { name: 'Facebook cover', width: 820, height: 312 },
            { name: 'Instagram post', width: 1080, height: 1080 },
            { name: 'Instagram story', width: 1080, height: 1920 },
            { name: 'Dribbble Shot', width: 400, height: 300 },
            { name: 'Dribble shot HD', width: 800, height: 600 },
            { name: 'Linkedln cover', width: 1584, height: 900 }
        )
    } else {
        res[FrameTemplateGroupNameCode.SocialMedia].push(
            { name: translation('WeChatArticle'), width: 400, height: 400 },
            { name: translation('WeChatOfficial'), width: 900, height: 383 },
            { name: translation('WeChatArticle_synonyms1'), width: 900, height: 500 },
            { name: 'Dribbble Shot', width: 400, height: 300 }
        )
    }
    return res
}

// Map 保证列表顺序
export const getFrameTemplateData = (): FrameTemplateData =>
    new Map([
        [groupNames[FrameTemplateGroupNameCode.Phone], groupItems()[FrameTemplateGroupNameCode.Phone]],
        [groupNames[FrameTemplateGroupNameCode.IPad], groupItems()[FrameTemplateGroupNameCode.IPad]],
        [groupNames[FrameTemplateGroupNameCode.PC], groupItems()[FrameTemplateGroupNameCode.PC]],
        [groupNames[FrameTemplateGroupNameCode.SmartWatch], groupItems()[FrameTemplateGroupNameCode.SmartWatch]],
        [groupNames[FrameTemplateGroupNameCode.Paper], groupItems()[FrameTemplateGroupNameCode.Paper]],
        [groupNames[FrameTemplateGroupNameCode.SocialMedia], groupItems()[FrameTemplateGroupNameCode.SocialMedia]],
        [groupNames[FrameTemplateGroupNameCode.Other], groupItems()[FrameTemplateGroupNameCode.Other]],
    ])
