import { isNil } from 'lodash-es'

export interface RGB {
    r: number
    g: number
    b: number
}

export interface RGBA {
    r: number
    g: number
    b: number
    a: number
}

export interface HSL {
    h: number
    s: number
    l: number
}
export interface HSV {
    h: number
    s: number
    v: number
}

export type ColorSpace = 'srgb' | 'display-p3'

/*
    r、g、b、s、l -> [0,1]
    h -> [0,360)
*/
export function rgb2hsl(r: number, g: number, b: number): HSL {
    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    let h: number
    let s: number

    if (max === min) {
        h = 0
    } else if (max === r) {
        if (g >= b) {
            h = (60 * (g - b)) / (max - min)
        } else {
            h = (60 * (g - b)) / (max - min) + 360
        }
    } else if (max === g) {
        h = (60 * (b - r)) / (max - min) + 120
    } else {
        h = (60 * (r - g)) / (max - min) + 240
    }

    const l = (max + min) / 2

    if (max === min || l === 0) {
        s = 0
    } else if (l <= 0.5) {
        s = (max - min) / (max + min)
    } else {
        s = (max - min) / (2 - max - min)
    }
    return { h, s, l }
}

/*
    r、g、b、s、l -> [0,1]
    h -> [0,360)
*/
export function hsl2rgb(h: number, s: number, l: number) {
    if (h === 360) {
        h = 0
    }
    const C = (1 - Math.abs(2 * l - 1)) * s
    const X = C * (1 - Math.abs(((h / 60) % 2) - 1))
    const M = l - C / 2
    let R = 0,
        G = 0,
        B = 0
    if (h >= 0 && h < 60) {
        R = C
        G = X
    } else if (h < 120) {
        R = X
        G = C
    } else if (h < 180) {
        G = C
        B = X
    } else if (h < 240) {
        G = X
        B = C
    } else if (h < 300) {
        R = X
        B = C
    } else if (h < 360) {
        R = C
        B = X
    }
    return {
        r: R + M,
        g: G + M,
        b: B + M,
    }
}

/*
    r、g、b、s、v -> [0,1]
    h -> [0,360)
*/
export function rgb2hsv(r: number, g: number, b: number): HSV {
    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    let h: number
    let s: number

    if (max === min) {
        h = 0
    } else if (max === r) {
        if (g >= b) {
            h = (60 * (g - b)) / (max - min)
        } else {
            h = (60 * (g - b)) / (max - min) + 360
        }
    } else if (max === g) {
        h = (60 * (b - r)) / (max - min) + 120
    } else {
        h = (60 * (r - g)) / (max - min) + 240
    }

    if (max === min) {
        s = 0
    } else {
        s = 1 - min / max
    }

    return { h, s, v: max }
}

/*
    r、g、b、s、v -> [0,1]
    h -> [0,360)
*/
export function hsv2rgb(h: number, s: number, v: number) {
    if (h === 360) {
        h = 0
    }
    const i = Math.floor(h / 60) % 6
    const f = h / 60 - i
    const p = v * (1 - s)
    const q = v * (1 - f * s)
    const t = v * (1 - (1 - f) * s)
    switch (i) {
        case 0:
            return { r: v, g: t, b: p }
        case 1:
            return { r: q, g: v, b: p }
        case 2:
            return { r: p, g: v, b: t }
        case 3:
            return { r: p, g: q, b: v }
        case 4:
            return { r: t, g: p, b: v }
        case 5:
            return { r: v, g: p, b: q }
        default:
            return { r: 1, g: 1, b: 1 }
    }
}

/*
    r,g,b -> [0,255]
    hex: string
*/
export function rgb2hex(r: number, g: number, b: number) {
    // NOTE: 如需改动 请同步修改NodeProperty.h 中RGB的operator==实现
    const red = Math.round(r).toString(16).padStart(2, '0').slice(0, 2)
    const green = Math.round(g).toString(16).padStart(2, '0').slice(0, 2)
    const blue = Math.round(b).toString(16).padStart(2, '0').slice(0, 2)
    return `${red}${green}${blue}`
}

/*
    f -> [0,255]
    hex: string
*/
export function float2hex(f: number) {
    return Math.round(f).toString(16).padStart(2, '0').slice(0, 2)
}

/*
    hex -> #?FFF
    hex -> #?FFFFFF
*/
export function hex2rgb(hex: string) {
    const hexChars = hex.replace(/[^0-9,^a-f,^A-F]/g, '').split('')
    let r = 255,
        g = 255,
        b = 255
    if (hexChars.length === 3) {
        r = parseInt(hexChars[0].repeat(2), 16)
        g = parseInt(hexChars[1].repeat(2), 16)
        b = parseInt(hexChars[2].repeat(2), 16)
    } else if (hexChars.length === 6) {
        r = parseInt(hexChars[0] + hexChars[1], 16)
        g = parseInt(hexChars[2] + hexChars[3], 16)
        b = parseInt(hexChars[4] + hexChars[5], 16)
    }
    return { r, g, b }
}

export function rgb2css(r: number, g: number, b: number, a?: number): string {
    r = Math.round(r)
    g = Math.round(g)
    b = Math.round(b)
    return !isNil(a) ? `rgba(${r},${g},${b},${a})` : `rgb(${r},${g},${b})`
}

/**
 * 将 proto 中定义的 color 编码转换为 CSS 可用的十六进制字符串
 * TODO(chenyn): 单测
 */
export function ARGBColorIntToRGBString(argbInt?: number | null): string {
    const rgbString = argbInt?.toString(16).padStart(8, '0').slice(2)
    return rgbString ? `#${rgbString}` : '#000000'
}

export function toCSSBackgroundColor(rgb: RGB, colorSpace: ColorSpace) {
    if (colorSpace === 'srgb') {
        const hex = rgb2hex(rgb.r, rgb.g, rgb.b)
        return `#${hex}`.replace(/^#+/, '#')
    }
    return `color(display-p3 ${rgb.r / 255} ${rgb.g / 255} ${rgb.b / 255}`
}

export function toCSSBackgroundColorWithAlpha(rgba: RGBA, colorSpace: ColorSpace) {
    if (colorSpace === 'srgb') {
        return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`
    }
    return `color(display-p3 ${rgba.r / 255} ${rgba.g / 255} ${rgba.b / 255} / ${rgba.a / 255}`
}
