import { DevModeLayerPanelCollapseAllNodesWasmCall } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { FC, useCallback, useMemo, useRef } from 'react'
import {
    DropdownV2,
    MonoIconCommonEnterDown16,
    MonoIconCommonEnterRight16,
    MonoIconCommonMore16,
    Tooltip,
} from '../../../../../../../../ui-lib/src'
import { useCommand } from '../../../../../../main/app-context'
import styles from './index.module.less'
import { translation } from './index.translation'
import { ShortcutKey, shortcutLabelMap } from '../../../../../../kernel/interface/shortcut-key'

interface DevModeLayerPanelHeaderProps {
    devModeLayerPanelCollapsed: boolean
    isCollapseAllNodesEnabled: boolean
    changeDevModeLayerPanelCollapsed: () => void
}

enum DevModeLayerPanelMoreOperation {
    CollapseAllNode = 'dev-mode-layer-panel-collapse-all-node',
}

export const DevModeLayerPanelHeader: FC<DevModeLayerPanelHeaderProps> = ({
    devModeLayerPanelCollapsed,
    isCollapseAllNodesEnabled,
    changeDevModeLayerPanelCollapsed,
}) => {
    const command = useCommand()
    const headerRef = useRef<HTMLDivElement>(null)

    const onSelectChange = useCallback(
        (value: DevModeLayerPanelMoreOperation) => {
            switch (value) {
                case DevModeLayerPanelMoreOperation.CollapseAllNode:
                    command.DEPRECATED_invokeBridge(DevModeLayerPanelCollapseAllNodesWasmCall)
                    break
                default:
                    break
            }
        },
        [command]
    )

    const headerYPosition = headerRef.current ? headerRef.current.getBoundingClientRect().y : 0
    const isDropdownOpenUpward = window.innerHeight - headerYPosition < 80

    return useMemo(
        () => (
            <div
                ref={headerRef}
                className={classNames(styles.devModeLayerPanelHeader, { [styles.shadow]: devModeLayerPanelCollapsed })}
                onMouseDown={(e) => {
                    e.stopPropagation()
                    changeDevModeLayerPanelCollapsed()
                }}
                data-testid="dev-mode-layer-panel-header"
            >
                <div className={styles.expandStatus}>
                    {devModeLayerPanelCollapsed ? (
                        <MonoIconCommonEnterRight16 className="color-$wk-gray-6" />
                    ) : (
                        <MonoIconCommonEnterDown16 className="color-$wk-gray-6" />
                    )}
                </div>
                <div className={styles.title}>{translation('Layers')}</div>
                <Tooltip title={translation('Options')} triggerRefKey="getTriggerElement">
                    <DropdownV2.IconSingleLevel
                        label={<MonoIconCommonMore16 />}
                        className={styles.devModeLayerPanelSelectIcon}
                        placement={isDropdownOpenUpward ? 'top right' : 'bottom right'}
                        onMouseDown={(e) => e.stopPropagation()}
                        onChange={onSelectChange}
                        dataTestIds={{ triggerFocus: 'dev-mode-layer-panel-more-operation' }}
                        classNameWhenOpen={styles.open}
                    >
                        <DropdownV2.IconSingleLevel.Option
                            value={DevModeLayerPanelMoreOperation.CollapseAllNode}
                            dataTestId={DevModeLayerPanelMoreOperation.CollapseAllNode}
                            selectContainerClassName={styles.devModeLayerPanelSelectContainer}
                            backwardIcon={shortcutLabelMap[ShortcutKey.FoldSelection]}
                            disabled={!isCollapseAllNodesEnabled}
                        >
                            {translation('CollapseAllLayers')}
                        </DropdownV2.IconSingleLevel.Option>
                    </DropdownV2.IconSingleLevel>
                </Tooltip>
            </div>
        ),
        [
            devModeLayerPanelCollapsed,
            changeDevModeLayerPanelCollapsed,
            onSelectChange,
            isCollapseAllNodesEnabled,
            isDropdownOpenUpward,
        ]
    )
}
