import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { getSVGIconByNodeIconType } from '../../layer-panel/render-item-icon'

export const AISearchNodeIcon = ({
    node,
    className,
}: {
    node: Wukong.DocumentProto.IAISearchNode
    className?: string
}) => {
    return (
        <span
            className={classnames('h-16px w-16px', className)}
            style={
                node.isPurple
                    ? {
                          color: 'var(--wk-purple-7)',
                          fill: 'var(--wk-purple-7)',
                          stroke: 'var(--wk-purple-7)',
                      }
                    : {
                          color: 'var(--wk-v2-label-color-gray-13)',
                          fill: 'var(--wk-gray-13)',
                          stroke: 'var(--wk-gray-13)',
                      }
            }
        >
            {getSVGIconByNodeIconType(node.iconData as Wukong.DocumentProto.NodeIconData)}
        </span>
    )
}
