import { IconFontMiss, Tooltip } from '../../../../../../ui-lib/src'
import { useDocumentMissingFont } from '../../../context/document-missing-font-context'
import styles from './tool-font-miss.module.less'
import { translation } from './tool-font-miss.translation'

export function ToolFontMiss() {
    const { openDialog, missFont, allFontInfosLoaded, isOpenFontMissDialog } = useDocumentMissingFont()

    if (!allFontInfosLoaded) {
        return null
    }

    if (Object.keys(missFont).length <= 0 && !isOpenFontMissDialog) {
        return null
    }

    return (
        <Tooltip title={translation('MissingFonts')}>
            <div className={styles.root} onClick={openDialog} data-testid="font-miss-icon">
                <div className={styles.icon}>
                    <IconFontMiss />
                </div>
            </div>
        </Tooltip>
    )
}
