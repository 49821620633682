import { translation } from './stroke-dash-gap.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { EditorDataTestId } from '../../../../../window'
import { SingleGrid } from '../../../atom/grid/single-grid'
import { ScrubbableInputNumber } from '../../../atom/inputs/scrubbable-input-number'
import style from '../advance-stroke/advance-stroke.module.less'
import { useStrokeDashGap } from './use-stroke-dash-gap'

// 虚线-{translation('Gap')}
export function StrokeDashGap() {
    const { dashGapState, onChangeDashGap } = useStrokeDashGap()
    return dashGapState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_HIDDEN ? (
        <></>
    ) : (
        <SingleGrid className={style.grid}>
            <SingleGrid.Item
                start={5}
                span={28}
                className={
                    dashGapState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_DISABLED
                        ? style.disabled
                        : undefined
                }
            >
                {translation('Gap')}
            </SingleGrid.Item>
            <SingleGrid.Item start={35} span={22}>
                <ScrubbableInputNumber
                    disabled={
                        dashGapState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_DISABLED
                    }
                    isMixed={
                        dashGapState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                    }
                    value={dashGapState?.value}
                    onChange={onChangeDashGap}
                    scrubbingDisabled={
                        dashGapState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                    }
                    testId={EditorDataTestId.StrokeAttr.DashGapInput}
                    labelTestId={EditorDataTestId.StrokeAttr.DashGapInputLabel}
                    className={dashGapState?.connected ? style.disabledInputColor : undefined}
                />
            </SingleGrid.Item>
        </SingleGrid>
    )
}
