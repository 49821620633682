import { isEqual } from 'lodash-es'
import { RouteToken, compareString, domLocation } from '../../../../../util/src'
import { PayPlanType, RoleStatus } from '../../../kernel/interface/type'
import {
    QueryOrganizationSeats,
    QueryOrganizationSeatsV2,
    QueryOrganizationWorkspaceSeats,
} from '../../../kernel/request/organizations'
import { BatchInviteOrgUser, BatchInviteOrgUserInEnterprise } from '../../../kernel/request/user-roles'
import { featureSwitchManager } from '../../../kernel/switch'
import { SpaceNotifyFlow } from './notify-flow'
import { SpaceStoreType, getSpaceStore, setSpaceStore, type OrganizationManageMembersStore } from './types'

const defaultData = {
    seats: [],
    workspaces: [],
    accountType2Count: {
        ORG_ADMIN: 0,
        WORKSPACE_ADMIN: 0,
        ORG_MEMBER: 0,
        ORG_GUEST: 0,
    },
    seatType2Count: {
        designer: 0,
        developer: 0,
        viewer: 0,
    },
}

export const generateOrganizationManageMembersState = (
    set: setSpaceStore,
    get: getSpaceStore
): OrganizationManageMembersStore => {
    return {
        data: [],
        loading: true,
        text: undefined,
        previous: '',
        seats: [],
        workspaces: [],
        params: {},
        prevParams: {},
        setText: (text) => {
            set((state) => {
                state.organizationManageMembersStore.previous = state.organizationManageMembersStore.text ?? ''
                state.organizationManageMembersStore.text = text
            })
            const previous = get().organizationManageMembersStore.previous
            if (!(text === previous)) {
                get().organizationManageMembersStore.fetchData()
            }
        },
        updateParams: (params) => {
            set((state) => {
                state.organizationManageMembersStore.prevParams = state.organizationManageMembersStore.params
                state.organizationManageMembersStore.params = {
                    ...state.organizationManageMembersStore.params,
                    ...params,
                }
            })
            const prev = get().organizationManageMembersStore.prevParams
            if (!isEqual(prev, params)) {
                get().organizationManageMembersStore.fetchData()
            }
        },
        checkAndclearParams: () => {
            if (!isInOrganizationMemberPage()) {
                set((state) => {
                    state.organizationManageMembersStore.prevParams = {}
                    state.organizationManageMembersStore.params = {}
                })
            }
        },
        invite: async (users, seatType) => {
            const emails = users.map((user) => user.email)
            const orgId = get().organizationStore.organization.id
            await new BatchInviteOrgUser(orgId, emails, seatType).start()
            get().organizationManageMembersStore._fetchData()
        },
        inviteV2: async (users, seatType, workspaceId) => {
            const emails = users.map((user) => user.email)
            const organization = get().organizationStore.organization
            const orgId = organization.id
            const isEnterprise = organization.planType === PayPlanType.enterprise
            if (isEnterprise) {
                await new BatchInviteOrgUserInEnterprise(orgId, emails, seatType, workspaceId).start()
            } else {
                await new BatchInviteOrgUser(orgId, emails, seatType).start()
            }
            get().organizationManageMembersStore._fetchData()
        },
        _fetchData: async () => {
            const flag = featureSwitchManager.isEnabled('organization-plus')

            if (flag) {
                const organization = get().organizationStore.organization
                const orgId = organization.id
                const isOrgAdmin = organization.role === RoleStatus.Admin
                const params = get().organizationManageMembersStore.params
                const workspaceId = get().managedWorkspaceStore.currentSelectedWorkspaceIdInAdmin
                const data = isOrgAdmin
                    ? await new QueryOrganizationSeatsV2(orgId, params).start().catch(() => defaultData)
                    : await new QueryOrganizationWorkspaceSeats(orgId, workspaceId!, params)
                          .start()
                          .catch(() => defaultData)

                set((state) => {
                    state.organizationManageMembersStore.seats = data.seats
                    state.organizationManageMembersStore.accountType2Count = data.accountType2Count
                    state.organizationManageMembersStore.seatType2Count = data.seatType2Count
                    state.organizationManageMembersStore.workspaces = data.workspaces.sort((a, b) => {
                        // 未分配团队排在最前面
                        if (a.id === '-1') {
                            return -1
                        } else if (b.id === '-1') {
                            return 1
                        }
                        return compareString(a.name, b.name)
                    })
                })
            } else {
                const orgId = get().organizationStore.organization.id
                const text = get().organizationManageMembersStore.text
                const data = await new QueryOrganizationSeats(orgId, text).start().catch((err) => {
                    console.error(err)
                    return []
                })
                set((state) => {
                    state.organizationManageMembersStore.data = data
                })
            }
        },
        fetchData: async () => {
            set((state) => {
                state.organizationManageMembersStore.loading = true
            })
            await get().organizationManageMembersStore._fetchData()
            set((state) => {
                state.organizationManageMembersStore.loading = false
            })
        },
    }
}

const isInOrganizationMemberPage = () => domLocation().pathname.includes(`${RouteToken.Admin}/members`)

export const syncOrganizationManageMembersState = (store: SpaceStoreType, notifyFlow: SpaceNotifyFlow) => {
    notifyFlow.addSeatTypeOrSeatApplicationRelationChangeCallback(() => {
        if (isInOrganizationMemberPage()) {
            store.getState().organizationManageMembersStore._fetchData()
        }
    })
    notifyFlow.addTeamRelationChangeCallback(() => {
        if (isInOrganizationMemberPage()) {
            store.getState().organizationManageMembersStore._fetchData()
        }
    })
    notifyFlow.addUserOrganizationRoleChangeCallback(() => {
        if (isInOrganizationMemberPage()) {
            store.getState().organizationManageMembersStore._fetchData()
        }
    })
    notifyFlow.addWorkspaceRelationChangeCallback(() => {
        if (isInOrganizationMemberPage()) {
            store.getState().organizationManageMembersStore._fetchData()
        }
    })
}
