import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    SelectItemsUsing: '选中使用该组件库颜色的 {{usingNodeCount}} 个图层',
} as const

export const enTranslation = {
    SelectItemsUsing: 'Select {{usingNodeCount}} {{item}} using this library color',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
