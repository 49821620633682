import { ScrubbableInput, ScrubbableInputProps } from './components/scrubbable-input'
import { formateToFixed2Degree } from './utils/format'
import { valueFilter15 } from './utils/value-filter'

/**
 * @param props
 * @returns
 * @description 角度
 */
export function ScrubbableInputDegree(props: ScrubbableInputProps) {
    return (
        <ScrubbableInput
            formatter={{ format: formateToFixed2Degree }}
            valueFilter={valueFilter15}
            {...props}
            value={(Number(props.value ?? 0) * 180) / Math.PI}
            allowedUnits={'°'}
            notUseUserConfig
        />
    )
}
