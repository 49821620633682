import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import { command } from 'ccstate'
import classNames from 'classnames'
import { FC, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { WKDialog } from '../../../../../ui-lib/src'
import { getMotiffName } from '../../../../../util/src'
import { traceable } from '../../../../../util/src/ccstate-helper/traceable'
import { isEditorSupport } from '../../../kernel/util/ua'
import chromeImg from '../../assets/chrome.png'
import edgeImg from '../../assets/edge.png'
import safariImg from '../../assets/safari.png'
import styles from './index.module.less'

export const showBrowserSupportPopup$ = traceable(
    'hulh01@kanyun.com',
    command(() => {
        if (isEditorSupport()) {
            return
        }

        const root = createRoot(document.createElement('div'))

        root.render(<BrowserSupportPopup />)

        return () => {
            root.unmount()
        }
    })
)

const BrowserSupportPopup: FC = () => {
    const [opacity1, setOpacity1] = useState(1)
    const [opacity2, setOpacity2] = useState(1)
    const [opacity3, setOpacity3] = useState(1)

    return (
        <WKDialog
            width={400}
            className={styles.browserSupportPopup}
            footer={null}
            visible={true}
            titleRender={
                <div className={'pt-4 pb-4 justify-between pr-6 pl-6 flex items-center'}>
                    <div className={`wk-text-14 wk-font-medium ${styles.titleText}`}>
                        {translation('UpdateOrSwitch')}
                    </div>
                </div>
            }
            bodyStyle={{ padding: '24px' }}
            data-testid={'welcome-popup'}
        >
            <div className={styles.browserTipText}>{translation('DownloadOrUpgrade', { name: getMotiffName() })}</div>
            <div className={`flex mt-2 ${styles.browserWrapper}`}>
                <a
                    className={classNames(['flex', 'flex-1', 'p-4', 'rounded-3px', styles.browserSingle])}
                    href="https://www.google.com/chrome/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        className={'mb-2'}
                        src={chromeImg}
                        style={{ opacity: opacity1 }}
                        onError={() => setOpacity1(0)}
                    />
                    <div className={'wk-font-medium text-center'}>Chrome 87+</div>
                </a>
                <a
                    className={classNames(['flex', 'flex-1', 'p-4', 'rounded-3px', styles.browserSingle])}
                    href="https://www.microsoft.com/zh-cn/edge/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        className={'mb-2'}
                        src={edgeImg}
                        style={{ opacity: opacity2 }}
                        onError={() => setOpacity2(0)}
                    />
                    <div className={'wk-font-medium text-center'}>Edge 88+</div>
                </a>
                <a
                    className={classNames(['flex', 'flex-1', 'p-4', 'rounded-3px', styles.browserSingle])}
                    href="https://support.apple.com/zh-cn/HT204416/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        className={'mb-2'}
                        src={safariImg}
                        style={{ opacity: opacity3 }}
                        onError={() => setOpacity3(0)}
                    />
                    <div className={'wk-font-medium text-center'}>Safari 14.1+ (macOS 11+)</div>
                </a>
            </div>
        </WKDialog>
    )
}
