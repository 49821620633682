import { useGet } from 'ccstate-react'
import { PropsWithChildren, useEffect } from 'react'
import { editorContext$ } from '../external-store/atoms/editor-context'
import { AppContextProvider, useAppContext } from './app-context'
import { useSetLeftPanelWidth } from './layout/layout-context'

export function AppContextContainer(props: PropsWithChildren) {
    const editorContext = useGet(editorContext$)

    if (!editorContext) {
        return null
    }

    return (
        <AppContextProvider ctx={editorContext}>
            <UpdateLeftPanelWidth>{props.children}</UpdateLeftPanelWidth>
        </AppContextProvider>
    )
}

function UpdateLeftPanelWidth(props: PropsWithChildren) {
    const ctx = useAppContext()
    const setLeftPanelWidth = useSetLeftPanelWidth()

    // TODO(hulh01) 需要把 setLeftPanelWidth 迁移到 Atom, 并将下列 Effect 移出 React
    useEffect(() => {
        return ctx.userConfigService.useZustandStore2.subscribe(
            (store) => store.leftPanelWidth,
            (value) => setLeftPanelWidth(value)
        )
    }, [ctx, setLeftPanelWidth])

    return <>{props.children}</>
}
