import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    View: '视图',
    PixelGrid: '像素网格',
    Rulers: '标尺',
    LayoutGrids: '布局网格',
    ShowSlices: '显示切片',
    AutomaticallyDetectIcons: '自动识别图标',
    Comments: '显示评论',
    MultiplayerCursors: '显示多人协作光标',
    ShowUI: '显示操作界面',
    ShowLeftSidebar: '显示左边栏',
    OpenDesignMode: '进入设计模式',
    OpenDevMode: '进入研发模式',
    MemoryUsage: '显示内存使用量',
    ZoomIn: '放大',
    ZoomOut: '缩小',
    ZoomTo: '缩放至',
    ZoomToFit: '缩放至显示全部',
    ZoomToSelection: '缩放至显示选中',
    PreviousPage: '上一页',
    NextPage: '下一页',
    ZoomToPrevious: '缩放至上一容器',
    ZoomToNext: '缩放至下一容器',
    FindPreviousFrame: '查找上一容器',
    FindNextFrame: '查找下一容器',
} as const

export const enTranslation = {
    View: 'View',
    PixelGrid: 'Pixel grid',
    Rulers: 'Rulers',
    LayoutGrids: 'Layout grids',
    ShowSlices: 'Show slices',
    AutomaticallyDetectIcons: 'Automatically detect icons',
    Comments: 'Comments',
    MultiplayerCursors: 'Multiplayer cursors',
    ShowUI: 'Show UI',
    ShowLeftSidebar: 'Show left sidebar',
    OpenDesignMode: 'Open design mode',
    OpenDevMode: 'Open Develop Mode',
    MemoryUsage: 'Memory usage',
    ZoomIn: 'Zoom in',
    ZoomOut: 'Zoom out',
    ZoomTo: 'Zoom to',
    ZoomToFit: 'Zoom to fit',
    ZoomToSelection: 'Zoom to selection',
    PreviousPage: 'Previous page',
    NextPage: 'Next page',
    ZoomToPrevious: 'Zoom to previous frame',
    ZoomToNext: 'Zoom to next frame',
    FindPreviousFrame: 'Find previous frame',
    FindNextFrame: 'Find next frame',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
