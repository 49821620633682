import { CLOGCATEGORY, CLOGENV, CLOGHOST } from './environment'

export interface CLogParam {
    env: CLOGENV // 测试 还是 线上
    site?: string // 站点名，例如yuanfudao, cretaclass
    category: CLOGCATEGORY // tutor 还是 conan
    syncUpdate?: boolean
}

export const CLOGPATH = {
    UPLOAD: '/tutor-clog/api/log/v2',
    UPDATE: '/tutor-clog/api/urls',
}

export function getApiPath(_clogHost: CLOGHOST) {
    return CLOGPATH
}
