import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    DialogDescriptionPrefix: '编辑文件',

    DialogOkTextOfAdminOrAutoGrade: '确定',
    DialogCancelTextOfAdminOrAutoGrade: '取消',
    DialogTitleOfAdminOrAutoGrade: '确定开始编辑文件？',
} as const

export const enTranslation = {
    DialogDescriptionPrefix: 'To edit file',

    DialogOkTextOfAdminOrAutoGrade: 'Confirm',
    DialogCancelTextOfAdminOrAutoGrade: 'Cancel',
    DialogTitleOfAdminOrAutoGrade: 'Are you sure you want to edit this file?',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
