import { useViewState } from '../../../../../view-state-bridge'
import { translation } from './index.translation'
import style from './style.module.less'

export function DevModeInspectMultiSelectionsInfo() {
    const selectionLength = useViewState('singleSelection')?.length ?? 0

    if (selectionLength <= 1) {
        return null
    }

    return (
        <div className={style.container} data-testid="dev-mode-inspect-multi-selections-info">
            <span className={style.title}>{`${translation('gUpQFe')} ${selectionLength} ${translation(
                'Selected'
            )}`}</span>
        </div>
    )
}
