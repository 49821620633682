/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { useMemo } from 'react'
import { CommandInvoker } from '../../document/command/command-invoker'
import { GlobalEditorActionConfigMap } from '../../main/global/global-editor-action-config/global-editor-action-config'
import { useViewState } from '../../view-state-bridge'
import ActionEnabledType = Wukong.DocumentProto.ActionEnabledType
export interface EditorActionStatusConfig {
    name: string
    shortCut: string | undefined
    hidden: boolean
    disable: boolean
    handler: () => void
    autoCommit: boolean
}

export interface EditorActionStatusConfigProps {
    command: CommandInvoker
    editorActionType: ActionEnabledType
    hiddenWhenDisabled: boolean // 禁用时隐藏
    handlerCallback?: () => void // 执行完 handler 后的回调函数
}

// 根据 menuActionType 获取适用于 ui 状态展示的 actionConfig
export function useEditorActionStatusConfig({
    command,
    editorActionType,
    hiddenWhenDisabled,
    handlerCallback,
}: EditorActionStatusConfigProps): EditorActionStatusConfig {
    const config = GlobalEditorActionConfigMap[editorActionType]

    let enabled: boolean | undefined
    switch (editorActionType) {
        case ActionEnabledType.ACTION_ENABLED_TYPE_ENTER_AI_LAYOUT: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledEnterAiLayoutStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_EXIT_AI_LAYOUT: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledExitAiLayoutStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_CANCEL_MASK: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledCancelMaskStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_SHOW_COMMENT: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledToggleShowCommentStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_SELECT_ALL: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledSelectAllStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_SELECT_INVERSE: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledSelectInverseStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_UNDOABLE: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledUndoableStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_REDOABLE: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledRedoableStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_CLEAR_SELECT_NODES: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledClearSelectNodesStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_GROUP_SELECTION: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledGroupSelectionStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_FRAME_SELECTION: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledFrameSelectionStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_BRING_TO_FRONT: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledBringToFrontStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_BRING_FORWARD: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledBringForwardStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_SEND_BACKWARD: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledSendBackwardStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_SEND_TO_BACK: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledSendToBackStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_RENAME: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledRenameStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_ADD_AUTO_LAYOUT: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledAddAutoLayoutStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_AI_CREATE_AUTO_LAYOUT: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledAiAddAutoLayout')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_REMOVE_AUTO_LAYOUT: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledRemoveAutoLayoutStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_COMPONENT_SELECTION: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledComponentSelectionStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_RESET_PROPERTIES: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledResetPropertiesStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_DETACH_INSTANCE: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledDetachInstanceStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_UNION: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledUnionStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_SUBTRACT: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledSubtractStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_INTERSECT: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledIntersectStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_EXCLUDE: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledExcludeStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_OUTLINE_STROKE: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledOutlineStrokeStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_FLATTEN: {
            /**
             * NOTE: ACTION_ENABLED_TYPE_FLATTEN 与 ACTION_ENABLED_TYPE_OUTLINE_STROKE 计算完全逻辑一致，使用同一个viewState以免重复计算
             * ps. 批量选中时 actionEnabledOutlineStrokeStatus的计算耗时较长
             */
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledOutlineStrokeStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_USE_AS_MASK: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledUseAsMaskStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_GO_TO_SELECTED_INSTANCE_MAIN_COMPONENT: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledGoToSelectedInstanceMainComponentStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_SHOWN: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledToggleShownStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_LOCKED: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledToggleLockedStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_FLIP_HORIZONTAL: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledFlipHorizontalStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_FLIP_VERTICAL: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledFlipVerticalStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_FULL_SCREEN: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledToggleFullScreenStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_PUBLISH_SELECTED_NODES: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledPublishSelectedNodesStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_REMOVE_FILL: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledRemoveFillStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_REMOVE_STROKE: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledRemoveStrokeStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_SWAP_FILL_AND_STROKE: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledSwapFillAndStrokeStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_UNGROUP_SELECTION: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledUngroupSelectionStatus')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_HIDE_OTHER_LAYERS: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('hasSelection')
            break
        }
        case ActionEnabledType.ACTION_ENABLED_TYPE_REMOVE_ALL_AUTO_LAYOUT: {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            enabled = useViewState('actionEnabledMoreLayoutOptionStatus')
            break
        }
        default: {
            enabled = false
            console.error('useEditorActionStatusConfig', '未知的 editorActionType 调用', editorActionType)
            break
        }
    }

    return useMemo(
        () => ({
            name: config?.displayName ?? '',
            shortCut: config?.keyboardShortcut?.shortcutLabel,
            disable: !enabled,
            hidden: !config || (hiddenWhenDisabled && !enabled),
            handler: () => {
                config?.viewCommand(command)
                handlerCallback?.()
            },
            autoCommit: config?.autoCommit ?? false,
        }),
        [command, config, enabled, handlerCallback, hiddenWhenDisabled]
    )
}
