import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    ViewStyle: '查看样式',
    EditStyle: '编辑样式',
} as const

export const enTranslation = {
    ViewStyle: 'View style',
    EditStyle: 'Edit style',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
