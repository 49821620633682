import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    MaskAsUnresolved: '标为未解决',
    MaskAsResolved: '标为已解决',
    Replies: '有 {{count}} 条回复',
} as const

export const enTranslation = {
    MaskAsUnresolved: 'Mask as unresolved',
    MaskAsResolved: 'Mask as resolved',
    Replies: '{{count}} {{reply}}',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string | number>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
