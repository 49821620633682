import constate from 'constate'
import { fileDialog } from 'file-select-dialog'
import { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import { MonoIconCommonaddPicture16, WKIconButton } from '../../../../../../ui-lib/src'
import { ConversationType } from '../../../../kernel/interface/chatbot'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { useNodePreviewBase64 } from '../../design/export/hooks/use-get-node-preview'

export interface ChatbotInputMessage {
    text: string
    imageBase64?: string
    selectedNodeIds?: string[]
    seletedNodeThumbnailBase64?: string
}

export interface ChatbotInputProps {
    onSend: (messages: ChatbotInputMessage) => void
    currentConversationType?: ConversationType
    answering: boolean // todo 是否正在回答
    scrollBy: (y: number) => void
}

const useHook = (params: ChatbotInputProps) => {
    const { currentConversationType, onSend } = params
    const [useInlineStyle, setUseInlineStyle] = useState(true)
    const [rows, setRows] = useState(1)
    const [text, setText] = useState('')
    const [image, setImage] = useState<File | undefined>(undefined)

    const imageDataUrl = useMemo(() => (image ? URL.createObjectURL(image) : ''), [image])
    const state = useViewState('pageTopArea')
    const selectionChangeSessionId = state?.selectionChangeSessionId

    const command = useCommand()
    const getSelectionNodeIds = useCallback(() => motiff.currentPage.selection.map((node) => node.id) ?? [], [])
    const [nodeIds, setNodeIds] = useState<string[]>(
        currentConversationType === ConversationType.EXECUTE ? getSelectionNodeIds() : []
    )

    useUpdateEffect(() => {
        // 任何type，切换选中时都要展示选中
        setNodeIds(getSelectionNodeIds())
    }, [command, selectionChangeSessionId])

    const { reFetch } = useNodePreviewBase64()
    const selectionsPreviewInfo = useMemo(() => {
        if (nodeIds.length > 0) {
            return {
                base64: reFetch(nodeIds, 200),
            }
        }
        return null
    }, [nodeIds, reFetch])

    const sendMessageActive: boolean = (() => {
        if (currentConversationType === ConversationType.SEARCH) {
            return !!image || nodeIds.length > 0 || text.length > 0
        } else {
            return text.length > 0
        }
    })()

    const clear = useCallback(() => {
        setText('')
        setImage(undefined)
        setNodeIds([])
    }, [])
    const sendMessage = useCallback(async () => {
        if (!sendMessageActive) {
            return
        }
        const imageBase64 = image ? await transImageFileToBase64(image) : undefined
        const sendText = (() => {
            if (currentConversationType !== ConversationType.SEARCH) {
                return text
            }
            // 资产检索仅有选区时自动补充一个文本
            if (text.length === 0 && !image) {
                return '帮我检索和当前选中图层相似的资产'
            }
            return text
        })()
        onSend({
            text: sendText,
            imageBase64,
            selectedNodeIds: nodeIds,
            seletedNodeThumbnailBase64: selectionsPreviewInfo?.base64 ?? undefined,
        })
        clear()
    }, [sendMessageActive, image, onSend, text, nodeIds, selectionsPreviewInfo?.base64, clear, currentConversationType])

    return {
        ...params,
        useInlineStyle,
        setUseInlineStyle,
        text,
        setText,
        rows,
        sendMessageActive,
        setRows,
        nodeIds,
        image,
        setImage,
        imageDataUrl,
        selectionsPreviewInfo,
        sendMessage,
    }
}

export const [ChatbotInputHelpersProvider, useChatbotInputHelpers] = constate(useHook)

export const ChatbotImageSelectButton = () => {
    const { setImage } = useChatbotInputHelpers()
    return (
        <WKIconButton
            onClick={async () => {
                const files = await fileDialog({
                    accept: ['image/*'],
                })
                const file = files[0]
                file && setImage(file)
            }}
            type="primary"
            size="small"
            icon={<MonoIconCommonaddPicture16 />}
        />
    )
}

export const transImageFileToBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => {
            resolve(reader.result as string)
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
    })
}
