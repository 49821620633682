import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    Search: '搜索',
    EnableLibraries: '启用默认组件库',
    ViewLibraries: '查看默认组件库',
    EnabledForTeam: '为团队启用',
    EnabledForDrafts: '为草稿启用',
} as const

export const enTranslation = {
    Search: 'Search',
    EnableLibraries: 'Enable default libraries',
    ViewLibraries: 'View default libraries',
    EnabledForTeam: 'Enabled for team',
    EnabledForDrafts: 'Enabled for drafts',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
