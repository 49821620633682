import {
    ConvertCompSetIdToPrimaryCompId,
    ImportNodeFromLibraryCommand,
    ReplaceSelectedInstanceMainCompWasmCall,
    Wukong,
} from '@wukong/bridge-proto'
import { WKToast } from '../../../../../../../../ui-lib/src'
import { CommitType } from '../../../../../../document/command/commit-type'
import { useLibraryComponentService } from '../../../../../../main/app-context'
import { LibraryResourceOssClientType } from '../../../../../../share/component-style-library/service/library-resource-downloader'
import { useCommand } from '../../../../../context/document-context'
import { LibraryNodeDataService } from '../../../../component-style-library-v2/library-service/library-node-data-service'
import { ReplaceInstanceNodeData } from '../../types'
import { translation } from './command.translation'

export function useComponentPickerCommand() {
    const command = useCommand()
    const libraryComponentService = useLibraryComponentService()

    /**
     * @returns 组件集 id 或普通组件的 id
     */
    async function fetchRemoteNodeData(nodeData: ReplaceInstanceNodeData & { docId: string }) {
        const fetchData = await (
            libraryComponentService.libraryNodeDataService as LibraryNodeDataService
        ).fetchRemoteExportedDocument({
            remoteDocId: nodeData.docId,
            remoteNodeId: nodeData.nodeId,
            nodeDataPath: nodeData.nodeDataPath,
            ossClientType: LibraryResourceOssClientType.Component,
            isLocal: false,
            localNodeId: null,
        })
        return command.DEPRECATED_invokeBridge(
            ImportNodeFromLibraryCommand,
            Wukong.DocumentProto.ImportNodeFromLibraryParam.create({
                ...fetchData,
                key: nodeData.key,
            })
        ).id
    }

    // 目前只给 ComponentPickerTrigger.InstanceSwapValue 使用该函数
    async function selectComponent(nodeData: ReplaceInstanceNodeData & { docId: string }) {
        let nodeId: string | null | undefined = nodeData.nodeId

        if (nodeData.isRemote) {
            nodeId = await fetchRemoteNodeData(nodeData)
        }

        if (!nodeId) {
            throw new Error('[ComponentPicker] selectComponent importNodeFromLibrary fail')
        }
        return command.invokeBridge(CommitType.Noop, ConvertCompSetIdToPrimaryCompId, {
            value: nodeId,
        })
    }

    async function replaceMainCompId(nodeData: ReplaceInstanceNodeData & { docId: string }) {
        const asyncFn = async () => {
            let nodeId: string | null | undefined = nodeData.nodeId

            if (nodeData.isRemote) {
                nodeId = await fetchRemoteNodeData(nodeData)
            }

            if (!nodeId) {
                throw new Error('[replaceMainCompId] importNodeFromLibrary fail')
            }

            const { success, errorMsg } = command.invokeBridge(
                CommitType.CommitUndo,
                ReplaceSelectedInstanceMainCompWasmCall,
                {
                    value: nodeId,
                }
            )

            if (!success) {
                throw new Error(errorMsg || translation('SwapInstanceFailed'))
            }
        }
        const toastKey = WKToast.show(translation('SwappingInstance'), { delay: 2000 })
        return await asyncFn()
            .then((res) => {
                WKToast.close(toastKey)
                return res
            })
            .catch((e) => {
                WKToast.close(toastKey)
                WKToast.show(e.message)
                return undefined
            })
    }

    return {
        selectComponent,
        replaceMainCompId,
    }
}
