import { Wukong } from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { ConcurrentScheduler } from '../../../../../util/src'
import { isStyleNode } from '../../../document/node/node-assertion'
import { featureSwitchManager } from '../../../kernel/switch/core'
import { ServiceClass } from '../../../kernel/util/service-class'
import { LibraryResourceDownloader, LibraryResourceOssClientType } from './library-resource-downloader'
import type { LibraryThumbnailKeyItem } from './library-thumbnail-service-type'

interface RemoteItem {
    thumbnailDataUrl: string
}

// 远端组件/样式缩略图
export class RemoteLibraryNodeThumbnailService extends ServiceClass {
    private remoteStore = new Map<string, RemoteItem>()
    // fetch 远端缩略图并发控制器
    private remoteLibraryNodeThumbnailConcurrentScheduler = ConcurrentScheduler({ limitCount: 10, delayTime: 0 })

    constructor(private readonly libraryResourceDownloader: LibraryResourceDownloader) {
        super()
    }

    public override destroy() {
        super.destroy()
        this.remoteLibraryNodeThumbnailConcurrentScheduler.destroy()
    }

    public loadThumbnailData = async (
        arg: Wukong.DocumentProto.IThumbnailData
    ): Promise<LibraryThumbnailKeyItem | null> => {
        const { isLocal, url, type, width, height, effectStyleIconType, layoutGridStyleIconType, variableIconType } =
            arg
        if (type === Wukong.DocumentProto.NodeType.NODE_TYPE_EFFECT_STYLE) {
            return {
                isLocal: Boolean(isLocal),
                type,
                width,
                height,
                data: undefined,
                effectStyleIconType: effectStyleIconType ?? undefined,
                layoutGridStyleIconType: undefined,
            }
        }
        if (type === Wukong.DocumentProto.NodeType.NODE_TYPE_LAYOUT_GRID_STYLE) {
            return {
                isLocal: Boolean(isLocal),
                type,
                width,
                height,
                data: undefined,
                effectStyleIconType: undefined,
                layoutGridStyleIconType: layoutGridStyleIconType ?? undefined,
            }
        }
        if (featureSwitchManager.isEnabled('float-variable')) {
            if (type === Wukong.DocumentProto.NodeType.NODE_TYPE_VARIABLE) {
                return {
                    isLocal: Boolean(isLocal),
                    type,
                    variableIconType: variableIconType ?? undefined,
                }
            }
        }
        if (!isLocal && !isNil(url) && !isNil(type)) {
            const val = await this.concurrentGenerateRemoteLibraryThumbnail(
                url,
                isStyleNode(type) ? LibraryResourceOssClientType.Style : LibraryResourceOssClientType.Component
            )
            return {
                isLocal: false,
                type,
                width,
                height,
                data: val,
                effectStyleIconType: undefined,
                layoutGridStyleIconType: undefined,
            }
        }
        return null
    }

    public loadThumbnailDataFromCache = (
        arg: Wukong.DocumentProto.IThumbnailData | null
    ): LibraryThumbnailKeyItem | null => {
        if (arg === null) {
            return null
        }
        const { isLocal, url, type, width, height } = arg
        if (!isLocal && !isNil(url) && !isNil(type)) {
            const val = this.tryGetFromRemoteStore(url)
            if (val === null) {
                return null
            }
            return {
                isLocal: false,
                type,
                width,
                height,
                data: val,
                effectStyleIconType: undefined,
                layoutGridStyleIconType: undefined,
            }
        }
        return null
    }

    private concurrentGenerateRemoteLibraryThumbnail = async (
        thumbnailDataPath: string,
        ossClientType: LibraryResourceOssClientType
    ): Promise<string> => {
        return (
            this.tryGetFromRemoteStore(thumbnailDataPath) ??
            this.remoteLibraryNodeThumbnailConcurrentScheduler.add(() =>
                this.generateRemoteLibraryThumbnail(thumbnailDataPath, ossClientType)
            )
        )
    }

    public eraseThumbnailDataCache = (thumbnailDataPath: string) => {
        this.remoteStore.delete(thumbnailDataPath)
    }

    private generateRemoteLibraryThumbnail = async (
        thumbnailDataPath: string,
        ossClientType: LibraryResourceOssClientType
    ): Promise<string> => {
        const cached = this.tryGetFromRemoteStore(thumbnailDataPath)
        if (cached !== null) {
            return cached
        }

        const thumbnailDataUrl = await this.libraryResourceDownloader.fetchUrl(ossClientType, thumbnailDataPath)
        if (thumbnailDataUrl) {
            this.remoteStore.set(thumbnailDataPath, { thumbnailDataUrl })
        }
        return thumbnailDataUrl
    }

    private tryGetFromRemoteStore = (thumbnailDataPath: string): string | null => {
        if (!thumbnailDataPath) {
            return ''
        }
        return this.remoteStore.get(thumbnailDataPath)?.thumbnailDataUrl ?? null
    }
}
