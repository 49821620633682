import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    SearchError: '搜索失败',
    NoResultsFor: '无「{{librarySearchKeyword}}」搜索结果',
    NoResults: '暂无组件库',
} as const

export const enTranslation = {
    SearchError: 'Search failed',
    NoResultsFor: 'No results for "{{librarySearchKeyword}}"',
    NoResults: 'No libraries',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
