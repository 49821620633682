import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useUnmount } from 'react-use'
import {
    MonoIconCommonEyes16,
    MonoIconCommonRemove16,
    MonoIconCommonWarningLine16,
    MonoIconLayerInstance16,
    MonoIconLayerType16,
    MonoIconLayerVariants16,
    MonoIconPanelAdjust16,
    SimpleDrag,
    Tooltip,
    WKIconButton,
} from '../../../../../../../../ui-lib/src'
import { PopupStateType } from '../../../../../../document/node/node'
import {
    Arg_cmdEditComponentPropData,
    MultiHoverBorderColorType,
    VComponentPropDef,
    VComponentPropType,
} from '../../types'
import { DoubleClickInput, DoubleClickVariantPropInput } from './components/double-click-input'
import styles from './index.module.less'
import { translation } from './index.translation'

export const ComponentPropDefsIconMap = {
    [VComponentPropType.V_COMPONENT_PROP_TYPE_BOOL]: <MonoIconCommonEyes16 />,
    [VComponentPropType.V_COMPONENT_PROP_TYPE_TEXT]: <MonoIconLayerType16 />,
    [VComponentPropType.V_COMPONENT_PROP_TYPE_INSTANCE_SWAP]: <MonoIconLayerInstance16 />,
    [VComponentPropType.V_COMPONENT_PROP_TYPE_VARIANT]: <MonoIconLayerVariants16 />,
}

export function VariantPropItem(props: {
    index: number
    popupType: PopupStateType
    popupVal: VComponentPropDef
    propName: string
    propValues: string[]
    selected: boolean
    conflicted: boolean
    renameProp: (newPropName: string) => void
    deleteProp: () => void
    editProp: () => void
    setPopupPosition: (value: { left: number; top: number } | undefined) => void
    dataTestIds?: {
        item?: string
        propName?: string
        propValue?: string
        edit?: string
        remove?: string
    }
    closePopup: () => void
    selectSelf: () => void
    deleteSelectedCompSetProps: () => void
}) {
    const {
        index,
        popupType,
        popupVal,
        propName,
        propValues,
        selected,
        conflicted,
        setPopupPosition,
        renameProp,
        deleteProp,
        editProp,
        dataTestIds,
        closePopup,
        selectSelf,
        deleteSelectedCompSetProps,
    } = props
    const [hovered, setHovered] = useState<boolean>(false)
    const [inputEditing, setInputEditing] = useState<boolean>(false)

    const defItemRef = useRef<HTMLDivElement>(null)
    const isOpenEditPopup =
        PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_VARIANT_PROP === popupType && propName === popupVal.name

    useEffect(() => {
        if (isOpenEditPopup) {
            setPopupPosition(defItemRef.current?.getBoundingClientRect())
        }
    }, [isOpenEditPopup, setPopupPosition])

    const toggleEditStatus = () => {
        if (!isOpenEditPopup) {
            setPopupPosition(defItemRef.current?.getBoundingClientRect())
            editProp()
        } else {
            closePopup()
        }
        selectSelf()
    }

    return (
        <SimpleDrag.Item
            itemIndex={index}
            dataTestIds={{ dragIcon: 'component-prop-variant-prop-drag-' + propName }}
            onContextMenu={selectSelf}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            tabIndex={0}
            onKeyDown={(e: any) => {
                if (['Delete', 'Backspace'].includes(e.code)) {
                    deleteSelectedCompSetProps()
                }
                e.stopPropagation()
            }}
            onMouseDown={() => isOpenEditPopup && closePopup()}
            onDragIconMouseDown={() => isOpenEditPopup && closePopup()}
        >
            <div
                ref={defItemRef}
                className={classNames(styles.componentPropDefsItem, selected ? styles.selected : '')}
                data-testid={dataTestIds?.item}
                onDoubleClick={() => setInputEditing(true)}
            >
                {!inputEditing && <div className={styles.componentPropDefsItemIcon}>{<MonoIconLayerVariants16 />}</div>}
                <DoubleClickVariantPropInput
                    forwardIcon={<MonoIconLayerVariants16 />}
                    propName={propName}
                    propValue={propValues.join(', ')}
                    inputEditing={inputEditing}
                    conflicted={conflicted}
                    updateEditing={setInputEditing}
                    onBlur={renameProp}
                    dataTestId={{
                        name: dataTestIds?.propName,
                        value: dataTestIds?.propValue,
                    }}
                />
                {(hovered || isOpenEditPopup) && !inputEditing && (
                    <>
                        <Tooltip title={translation('editProp')}>
                            <WKIconButton
                                onDoubleClick={(e: any) => e.stopPropagation()}
                                type={isOpenEditPopup ? 'deepBlue' : 'secondary'}
                                data-testid={dataTestIds?.edit}
                                icon={<MonoIconPanelAdjust16 />}
                                onClick={toggleEditStatus}
                                onMouseDown={(e: any) => e.stopPropagation()} // 防止冒泡到外层div导致无法关闭编辑弹窗
                            />
                        </Tooltip>
                        <Tooltip title={translation('deleteProp')}>
                            <WKIconButton
                                onDoubleClick={(e: any) => e.stopPropagation()}
                                data-testid={dataTestIds?.remove}
                                icon={<MonoIconCommonRemove16 />}
                                onClick={(e) => {
                                    deleteProp()
                                    e.stopPropagation()
                                }}
                            />
                        </Tooltip>
                    </>
                )}
                {!inputEditing && conflicted && (
                    <Tooltip title={translation('nameConflict')}>
                        <MonoIconCommonWarningLine16
                            className={styles.componentPropDefsItemWarn}
                            data-testid={'variant-prop-conflict-' + propName}
                        />
                    </Tooltip>
                )}
            </div>
        </SimpleDrag.Item>
    )
}

export function ComponentPropDefsItem(props: {
    def: VComponentPropDef
    popupType: PopupStateType
    popupVal: VComponentPropDef
    index: number
    selected: boolean
    openEditPopup: (def: VComponentPropDef) => void
    removeProp: (def: VComponentPropDef) => void
    updateProp: (propVal: Arg_cmdEditComponentPropData) => void
    setPopupPosition: (value: { left: number; top: number } | undefined) => void
    closePopup: () => void
    highlightNodesByIds: (nodeIds: string[], color?: MultiHoverBorderColorType) => void
    selectSelf: () => void
    deleteSelectedDefs: () => void
}) {
    const {
        def,
        popupType,
        popupVal,
        index,
        selected,
        openEditPopup,
        removeProp,
        updateProp,
        setPopupPosition,
        closePopup,
        highlightNodesByIds,
        selectSelf,
        deleteSelectedDefs,
    } = props
    const [isHovered, setIsHovered] = useState<boolean>(false)
    const [inputEditing, setInputEditing] = useState<boolean>(false)

    const defItemRef = useRef<HTMLDivElement>(null)
    const isOpenEditPopup =
        [
            PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_TEXT_PROP,
            PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_BOOL_PROP,
            PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_INSTANCE_SWAP_PROP,
        ].includes(popupType) && popupVal.id === def.id

    useEffect(() => {
        // 该 effect 用于其他组件触发编辑时，跳转到组件属性列表面板
        if (isOpenEditPopup) {
            setPopupPosition(defItemRef.current?.getBoundingClientRect())
        }
    }, [isOpenEditPopup, setPopupPosition])

    const handleInputBlur = (val: string) => {
        if (val !== '' && val !== def.name) {
            updateProp({
                propId: def.id,
                name: val,
            })
        }
    }

    const warningTooltipTitle = useMemo(() => {
        if (def.isConflict) {
            return translation('nameConflict')
        }
        if (def.unused) {
            return translation('propNotUsed')
        }
        return ''
    }, [def.isConflict, def.unused])

    const toggleEditStatus = () => {
        if (!isOpenEditPopup) {
            setPopupPosition(defItemRef.current?.getBoundingClientRect())
            openEditPopup(def)
        } else {
            closePopup()
        }
        selectSelf()
    }

    const onPropHovered = (hovered: boolean) => {
        setIsHovered(hovered)
        const nodeIds = hovered ? def.affectedNodeIds : []
        const color = hovered
            ? MultiHoverBorderColorType.MULTI_HOVER_BORDER_COLOR_TYPE_PURPLE
            : MultiHoverBorderColorType.MULTI_HOVER_BORDER_COLOR_TYPE_BLUE
        highlightNodesByIds(nodeIds, color)
    }
    useUnmount(() => isHovered && onPropHovered(false)) // 组件卸载时，取消组件属性 wasm 侧的高亮颜色

    return (
        <SimpleDrag.Item
            itemIndex={index}
            dataTestIds={{ dragIcon: `component-prop-def-drag-${index}` }}
            onContextMenu={selectSelf}
            onMouseEnter={() => onPropHovered(true)}
            onMouseLeave={() => onPropHovered(false)}
            tabIndex={0}
            onKeyDown={(e: any) => {
                if (['Delete', 'Backspace'].includes(e.code)) {
                    deleteSelectedDefs()
                }
                e.stopPropagation() // 防止冒泡到 wasm 画布区
            }}
            onMouseDown={() => isOpenEditPopup && closePopup()}
            onDragIconMouseDown={() => isOpenEditPopup && closePopup()}
        >
            <div
                ref={defItemRef}
                className={classNames(styles.componentPropDefsItem, { [styles.selected]: selected })}
                data-testid={`component-prop-defs-item-${index}`}
                onDoubleClick={() => setInputEditing(true)}
            >
                {!inputEditing && (
                    <div className={styles.componentPropDefsItemIcon}>{ComponentPropDefsIconMap[def.type]}</div>
                )}
                <DoubleClickInput
                    forwardIcon={ComponentPropDefsIconMap[def.type]}
                    value={def.name}
                    editing={inputEditing}
                    updateEditing={setInputEditing}
                    onBlur={handleInputBlur}
                    dataTestId={{
                        normal: 'component-prop-defs-item-normal-name',
                    }}
                />
                {(isHovered || isOpenEditPopup) && !inputEditing && (
                    <>
                        <Tooltip title={translation('editProp')}>
                            <WKIconButton
                                onDoubleClick={(e: any) => e.stopPropagation()}
                                data-testid="component-prop-defs-item-edit"
                                type={isOpenEditPopup ? 'deepBlue' : 'secondary'}
                                icon={<MonoIconPanelAdjust16 />}
                                onClick={toggleEditStatus}
                                onMouseDown={(e: any) => e.stopPropagation()} // 防止冒泡到外层div导致无法关闭编辑弹窗
                            />
                        </Tooltip>
                        <Tooltip title={translation('deleteProp')}>
                            <WKIconButton
                                onDoubleClick={(e: any) => e.stopPropagation()}
                                data-testid="component-prop-defs-item-remove"
                                icon={<MonoIconCommonRemove16 />}
                                onClick={(e) => {
                                    removeProp(def)
                                    e.stopPropagation() // 防止冒泡到外层触发选中逻辑
                                }}
                            />
                        </Tooltip>
                    </>
                )}
                {!inputEditing && !!warningTooltipTitle.length && (
                    <Tooltip title={warningTooltipTitle} firstDelay={100}>
                        <div
                            className={styles.componentPropDefsItemWarn}
                            data-testid={`component-prop-warning-${def.name}`}
                        >
                            <MonoIconCommonWarningLine16 />
                        </div>
                    </Tooltip>
                )}
            </div>
        </SimpleDrag.Item>
    )
}
