import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useViewState } from '../../../../../view-state-bridge'
import guideInDevMode from '../../../../assets/layer-panel/dev-mode-down-grade-guide-3x.png'
import { DevModeInspectMultiSelectionsInfo } from '../dev-mode-inspect-multi-selections-info'
import { DevModeInspectNodeBasicInfoIcon } from '../dev-mode-inspect-selection-info'
import style from './index.module.less'
import { translation } from './index.translation'
import DevModeInspectSelectionInfoType = Wukong.DocumentProto.DevModeInspectSelectionInfoType
import NodeIconData = Wukong.DocumentProto.NodeIconData

export function DevModeInspectGuide() {
    return (
        <div className={style.guidePanel} data-testid="dev-mode-inspect-guide">
            <div className={style.guideTitle}>{translation('GuideTitle')}</div>
            <div className={style.guideDesciption}>{translation('GuideDescription')}</div>
            <img src={guideInDevMode} width="268px" height="214px"></img>
        </div>
    )
}

export function DevModeBasicInfoForNoSeat({
    selectionType,
}: {
    selectionType: DevModeInspectSelectionInfoType | undefined
}) {
    if (selectionType == DevModeInspectSelectionInfoType.DEV_MODE_INSPECT_SELECTION_INFO_TYPE_SINGLE) {
        return <DevModeBasicInfoForNoSeatSingleSelection />
    } else if (selectionType == DevModeInspectSelectionInfoType.DEV_MODE_INSPECT_SELECTION_INFO_TYPE_NONE) {
        return <DevModeBasicInfoForNoSeatNoneSelection />
    } else {
        return <DevModeInspectMultiSelectionsInfo />
    }
}

function DevModeBasicInfoForNoSeatSingleSelection() {
    const { show, selectionInfo } = useViewState('devModeInspectNodeBasicInfoViewState') ?? {}

    if (!show) {
        return null
    }

    return (
        <div className={style.panel} data-testid="dev-mode-inspect-node-basic-info-for-noseat">
            <div className={style.title}>{selectionInfo?.nodeName}</div>
            <div className={style.nameIconContainer}>
                {selectionInfo?.nodeIcon && selectionInfo?.nodeId && (
                    <div style={{ marginRight: '8px' }}>
                        <DevModeInspectNodeBasicInfoIcon
                            nodeId={selectionInfo.nodeId}
                            isPurple={selectionInfo.isPurple}
                            nodeIcon={selectionInfo.nodeIcon as NodeIconData}
                        />
                    </div>
                )}
                <div className={classNames(style.name, { [style.purpleName]: selectionInfo?.isPurple ?? false })}>
                    {selectionInfo?.typeName}
                </div>
            </div>
        </div>
    )
}

function DevModeBasicInfoForNoSeatNoneSelection() {
    return (
        <div className={style.panel}>
            <div className={style.title}>{translation('NoSelect')}</div>
            <div className={style.nameNoneSelection}>{'—'}</div>
        </div>
    )
}
