import {
    BatchUpdateArcStartingAngleCommand,
    BatchUpdateArcSweepCommand,
    BatchUpdateBaseAttributeCommand,
    BatchUpdateInnerRadiusCommand,
    BatchUpdateMinSizeAndMaxSizeWasmCall,
    BatchUpdatePointCountCommand,
    ConvertFrameToGroupCommand,
    ConvertGroupToFrameWasmCall,
    ExpandSelectionIndependentCornerRadiusCommand,
    FrameResizeToFitWasmCall,
    UpdateSelectionArcStartingAngleCommand,
    UpdateSelectionArcSweepCommand,
    UpdateSelectionBaseAttributeCommand,
    UpdateSelectionClipsContentCommand,
    UpdateSelectionConstrainProportionsCommand,
    UpdateSelectionCornerRadiusCommand,
    UpdateSelectionFrameDirectionCommand,
    UpdateSelectionIndependentCornerRadiusCommand,
    UpdateSelectionInnerRadiusCommand,
    UpdateSelectionPointCountCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { isNil, isNumber } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import { IconPolygonCount, IconStarCount, safeCall } from '../../../../../../ui-lib/src'
import { CommitType } from '../../../../document/command/commit-type'
import { cmdChangePopupState } from '../../../../document/command/document-command'
import { PopupStateType } from '../../../../document/node/node'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { Value } from '../../atom/inputs/utils/type'
import { useArcHiddenState } from './use-arc-hidden-state'
import { translation } from './use-attribute.translation'

export function useAttributeV2() {
    const command = useCommand()
    const constrainProportions = useViewState('constrainProportions')
    const panelStateUnderAILayout = useViewState('panelStateUnderAILayout')

    const cornerRadius = useViewState('cornerRadius')
    const cornerSmoothing = useViewState('cornerSmoothing')
    const independentCornerRadius = useViewState('independentCornerRadius')
    const defaultCornerRadius = { mixed: false, value: 0 }
    const topLeftRadius = independentCornerRadius?.site1 || defaultCornerRadius
    const topRightRadius = independentCornerRadius?.site2 || defaultCornerRadius
    const bottomRightRadius = independentCornerRadius?.site3 || defaultCornerRadius
    const bottomLeftRadius = independentCornerRadius?.site4 || defaultCornerRadius

    const clipsContent = useViewState('clipsContent')
    const arcDataState = useViewState('arcData')

    const polygonStarState = useViewState('polygonStar')

    const polygonStarIcon = useMemo(() => {
        if (polygonStarState?.showType == Wukong.DocumentProto.NodeType.NODE_TYPE_POLYGON) {
            return <IconPolygonCount />
        } else {
            return <IconStarCount />
        }
    }, [polygonStarState?.showType])

    const tooltipTitle = useMemo(() => {
        if (polygonStarState?.showType == Wukong.DocumentProto.NodeType.NODE_TYPE_POLYGON) {
            return translation('Count')
        } else {
            return translation('Count_synonyms1')
        }
    }, [polygonStarState?.showType])

    const showStarInnerRadius = polygonStarState?.showInnerRadius

    const starInnerRadiusMixed = polygonStarState?.innerRadius.mixed

    const starInnerRadiusValue = polygonStarState?.innerRadius.value

    const pointCountValue = polygonStarState?.pointCount.value

    const pointCountMixed = polygonStarState?.pointCount.mixed

    const showPointCount = polygonStarState?.show

    const arcDataStartingAngleMixed = arcDataState?.startingAngle.mixed

    const arcDataStartingAngleValue = arcDataState?.startingAngle.value

    const innerRadiusValue = arcDataState?.innerRadius.value

    const innerRadiusMixed = arcDataState?.innerRadius.mixed

    const sweepValue = arcDataState?.sweep.value

    const sweepMixed = arcDataState?.sweep.mixed

    const state = useViewState('baseAttributeV2')

    const rotation = state?.r
    const selectionX = state?.x
    const selectionY = state?.y
    const selectionW = state?.w
    const selectionH = state?.h

    const batchUpdateBaseAttribute = (key: string, values: Record<string, number>) => {
        command.DEPRECATED_invokeBridge(BatchUpdateBaseAttributeCommand, {
            type: key,
            values,
        })
    }

    const batchUpdateMinMaxSizeAttribute = (
        type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum,
        v: number | string | boolean | null,
        values?: Record<string, number>,
        options?: InputOptionsForUndoSquash,
        transform?: (prevValue: any) => void
    ) => {
        if (!values) {
            return
        }
        if (transform) {
            command.DEPRECATED_invokeBridge(BatchUpdateMinSizeAndMaxSizeWasmCall, {
                params: Object.entries(values).map(([nodeId, value]) => ({
                    nodeId,
                    value: safeCall(transform, value) ?? value,
                    type,
                })),
            })
            command.commitUndo(options?.commitType)
        } else if (!isNil(v)) {
            command.DEPRECATED_invokeBridge(BatchUpdateMinSizeAndMaxSizeWasmCall, {
                params: Object.keys(values).map((nodeId) => ({
                    nodeId,
                    value: Number(v),
                    type,
                })),
            })
            command.commitUndo(options?.commitType)
        }
    }

    const setNodeProperty = (
        key: string,
        v: number | string | boolean | null,
        options?: InputOptionsForUndoSquash,
        transform?: (prevValue: any) => void
    ) => {
        switch (key) {
            case 'x': {
                if (transform && state?.x.values) {
                    const res: Record<string, number> = {}
                    Object.entries(state.x.values).forEach(([name, value]) => {
                        res[name] = safeCall(transform, value) ?? value
                    })
                    batchUpdateBaseAttribute('x', res)
                    command.commitUndo(options?.commitType)
                } else if (!isNil(v)) {
                    command.DEPRECATED_invokeBridge(UpdateSelectionBaseAttributeCommand, { x: Number(v) })
                    command.commitUndo(options?.commitType)
                }
                break
            }
            case 'y': {
                if (transform && state?.y.values) {
                    const res: Record<string, number> = {}
                    Object.entries(state.y.values).forEach(([name, value]) => {
                        res[name] = safeCall(transform, value) ?? value
                    })
                    batchUpdateBaseAttribute('y', res)
                    command.commitUndo(options?.commitType)
                } else if (!isNil(v)) {
                    command.DEPRECATED_invokeBridge(UpdateSelectionBaseAttributeCommand, { y: Number(v) })
                    command.commitUndo(options?.commitType)
                }
                break
            }
            case 'width': {
                if (transform && state?.w.values) {
                    const res: Record<string, number> = {}
                    Object.entries(state.w.values).forEach(([name, value]) => {
                        res[name] = safeCall(transform, value) ?? value
                    })
                    batchUpdateBaseAttribute('w', res)
                    command.commitUndo(options?.commitType)
                } else if (!isNil(v)) {
                    command.DEPRECATED_invokeBridge(UpdateSelectionBaseAttributeCommand, { w: Number(v) })
                    command.commitUndo(options?.commitType)
                }
                break
            }
            case 'height': {
                if (transform && state?.h.values) {
                    const res: Record<string, number> = {}
                    Object.entries(state.h.values).forEach(([name, value]) => {
                        res[name] = safeCall(transform, value) ?? value
                    })
                    batchUpdateBaseAttribute('h', res)
                    command.commitUndo(options?.commitType)
                } else if (!isNil(v)) {
                    command.DEPRECATED_invokeBridge(UpdateSelectionBaseAttributeCommand, { h: Number(v) })
                    command.commitUndo(options?.commitType)
                }
                break
            }
            case 'rotation': {
                if (transform && state?.r.values) {
                    const res: Record<string, number> = {}
                    Object.entries(state.r.values).forEach(([name, value]) => {
                        const temp = (value * 180) / Math.PI
                        res[name] = ((safeCall(transform, temp) ?? temp) * Math.PI) / 180
                    })
                    batchUpdateBaseAttribute('r', res)
                    command.commitUndo(options?.commitType)
                } else if (!isNil(v)) {
                    command.DEPRECATED_invokeBridge(UpdateSelectionBaseAttributeCommand, {
                        r: (Number(v) * Math.PI) / 180,
                    })
                    command.commitUndo(options?.commitType)
                }
                break
            }
            case 'constrainProportions': {
                command.DEPRECATED_invokeBridge(UpdateSelectionConstrainProportionsCommand, { value: !!v })
                command.commitUndo()
                break
            }
            case 'cornerRadius': {
                if (!isNil(v)) {
                    command.DEPRECATED_invokeBridge(UpdateSelectionCornerRadiusCommand, { value: Number(v) })
                    command.commitUndo(options?.commitType)
                }
                break
            }
            case 'independentCorners': {
                command.DEPRECATED_invokeBridge(ExpandSelectionIndependentCornerRadiusCommand, { value: !!v })
                command.commitUndo()
                break
            }
            case 'topLeftRadius': {
                if (!isNil(v)) {
                    command.DEPRECATED_invokeBridge(UpdateSelectionIndependentCornerRadiusCommand, {
                        type: 'site1',
                        value: Number(v),
                    })
                    command.commitUndo(options?.commitType)
                }
                break
            }
            case 'topRightRadius': {
                if (!isNil(v)) {
                    command.DEPRECATED_invokeBridge(UpdateSelectionIndependentCornerRadiusCommand, {
                        type: 'site2',
                        value: Number(v),
                    })
                    command.commitUndo(options?.commitType)
                }
                break
            }
            case 'bottomLeftRadius': {
                if (!isNil(v)) {
                    command.DEPRECATED_invokeBridge(UpdateSelectionIndependentCornerRadiusCommand, {
                        type: 'site4',
                        value: Number(v),
                    })
                    command.commitUndo(options?.commitType)
                }
                break
            }
            case 'bottomRightRadius': {
                if (!isNil(v)) {
                    command.DEPRECATED_invokeBridge(UpdateSelectionIndependentCornerRadiusCommand, {
                        type: 'site3',
                        value: Number(v),
                    })
                    command.commitUndo(options?.commitType)
                }
                break
            }
            case 'cornerSmoothing': {
                const type = v ? PopupStateType.POPUP_STATE_TYPE_CORNER_SMOOTHING : PopupStateType.POPUP_STATE_TYPE_NONE
                command.invoke(cmdChangePopupState, {
                    type,
                    reciprocalIndex: -1,
                    multiPopup: [],
                })
                break
            }
            case 'pointCount': {
                if (!polygonStarState?.pointCount.values) {
                    return
                }
                if (transform) {
                    const values: Record<string, number> = {}
                    Object.entries(polygonStarState.pointCount.values).forEach(([nodeId, value]) => {
                        values[nodeId] = safeCall(transform, value) ?? value
                    })
                    command.DEPRECATED_invokeBridge(BatchUpdatePointCountCommand, { values })
                    command.commitUndo(options?.commitType)
                } else if (!isNil(v)) {
                    command.DEPRECATED_invokeBridge(UpdateSelectionPointCountCommand, { value: Number(v) })
                    command.commitUndo(options?.commitType)
                }
                break
            }
            case 'innerRadius': {
                if (!polygonStarState?.innerRadius.values) {
                    return
                }
                if (transform) {
                    const values: Record<string, number> = {}
                    Object.entries(polygonStarState.innerRadius.values).forEach(([nodeId, value]) => {
                        values[nodeId] = (safeCall(transform, value * 100) ?? value * 100) / 100
                    })
                    command.DEPRECATED_invokeBridge(BatchUpdateInnerRadiusCommand, { values })
                    command.commitUndo(options?.commitType)
                } else if (!isNil(v)) {
                    command.DEPRECATED_invokeBridge(UpdateSelectionInnerRadiusCommand, {
                        value: Number(v) / 100,
                        nodeType: Wukong.DocumentProto.NodeType.NODE_TYPE_STAR,
                    })
                    command.commitUndo(options?.commitType)
                }
                break
            }
            case 'arcStartingAngle': {
                if (!arcDataState?.startingAngle.values) {
                    return
                }
                if (transform) {
                    const values: Record<string, number> = {}
                    Object.entries(arcDataState.startingAngle.values).forEach(([nodeId, value]) => {
                        const percent = safeCall(transform, (value * 180) / Math.PI)
                        values[nodeId] = (Number(percent) / 180) * Math.PI
                    })
                    command.DEPRECATED_invokeBridge(BatchUpdateArcStartingAngleCommand, { values })
                    command.commitUndo(options?.commitType)
                } else if (!isNil(v)) {
                    command.DEPRECATED_invokeBridge(UpdateSelectionArcStartingAngleCommand, {
                        value: (Number(v) / 180) * Math.PI,
                    })
                    command.commitUndo(options?.commitType)
                }
                break
            }
            case 'arcInnerRadius': {
                if (!arcDataState?.innerRadius.values) {
                    return
                }
                if (transform) {
                    const values: Record<string, number> = {}
                    Object.entries(arcDataState.innerRadius.values).forEach(([nodeId, value]) => {
                        values[nodeId] = (safeCall(transform, value * 100) ?? value * 100) / 100
                    })
                    command.DEPRECATED_invokeBridge(BatchUpdateInnerRadiusCommand, { values })
                    command.commitUndo(options?.commitType)
                } else if (!isNil(v)) {
                    command.DEPRECATED_invokeBridge(UpdateSelectionInnerRadiusCommand, {
                        value: Number(v) / 100,
                        nodeType: Wukong.DocumentProto.NodeType.NODE_TYPE_ELLIPSE,
                    })
                    command.commitUndo(options?.commitType)
                }
                break
            }
            case 'sweep': {
                if (!arcDataState?.sweep.values) {
                    return
                }
                if (transform) {
                    const values: Record<string, number> = {}
                    Object.entries(arcDataState.sweep.values).forEach(([nodeId, value]) => {
                        v = safeCall(transform, value * 100) ?? value * 100
                        values[nodeId] = Number(v) / 100
                    })
                    command.DEPRECATED_invokeBridge(BatchUpdateArcSweepCommand, { values })
                    command.commitUndo(options?.commitType)
                } else if (isNumber(v)) {
                    command.DEPRECATED_invokeBridge(UpdateSelectionArcSweepCommand, {
                        value: Number(v) / 100,
                    })
                    command.commitUndo(options?.commitType)
                }
                break
            }
            case 'stackMode': {
                command.DEPRECATED_invokeBridge(UpdateSelectionFrameDirectionCommand, {
                    direction: Number(v) as Wukong.DocumentProto.UpdateSelectionFrameDirectionCommandEnum,
                })
                command.commitUndo(options?.commitType)
                break
            }
            case 'clipsContent': {
                command.DEPRECATED_invokeBridge(UpdateSelectionClipsContentCommand, { value: !!v })
                command.commitUndo(options?.commitType)
                break
            }
            case 'minW': {
                batchUpdateMinMaxSizeAttribute(
                    Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_WIDTH,
                    v,
                    state?.minW.values,
                    options,
                    transform
                )
                break
            }
            case 'maxW': {
                batchUpdateMinMaxSizeAttribute(
                    Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_WIDTH,
                    v || NaN,
                    state?.maxW.values,
                    options,
                    transform
                )
                break
            }
            case 'minH': {
                batchUpdateMinMaxSizeAttribute(
                    Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_HEIGHT,
                    v,
                    state?.minH.values,
                    options,
                    transform
                )
                break
            }
            case 'maxH': {
                batchUpdateMinMaxSizeAttribute(
                    Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_HEIGHT,
                    v || NaN,
                    state?.maxH.values,
                    options,
                    transform
                )
                break
            }
        }
    }

    const onChange = (key: string) => (v: number | string | boolean, options?: InputOptionsForUndoSquash) => {
        setNodeProperty(key, v, options)
    }

    const transformErrorCallback = (key: string) => (error: any, transform: (prevValue: any) => void) => {
        setNodeProperty(key, null, undefined, transform)
    }

    const hiddenArc = useArcHiddenState(!!arcDataState?.hidden, arcDataState?.passIds)

    const resizeToFit = useCallback(() => {
        command.invokeBridge(CommitType.CommitUndo, FrameResizeToFitWasmCall)
    }, [command])

    const onClickGroupToFrame = useCallback(() => {
        command.invokeBridge(CommitType.CommitUndo, ConvertGroupToFrameWasmCall)
    }, [command])

    const onClickFrameToGroup = useCallback(() => {
        command.invokeBridge(CommitType.CommitUndo, ConvertFrameToGroupCommand)
    }, [command])

    const onChangeNew = (key: string) => {
        return (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value === 'number') {
                setNodeProperty(key, value, options)
            }
        }
    }
    const onChangeMixed = (key: string) => {
        return (parse: (value: Value) => Value, options?: InputOptionsForUndoSquash) => {
            setNodeProperty(key, null, options, parse)
        }
    }
    const getScrubStartValueMixed = (key: string) => {
        return () => {
            const valueMap: Map<string, number> = new Map()
            switch (key) {
                case 'pointCount': {
                    Object.entries(polygonStarState?.pointCount.values ?? {}).forEach(([id, value]) => {
                        valueMap.set(id, value)
                    })
                    break
                }
                case 'innerRadius': {
                    Object.entries(polygonStarState?.innerRadius.values ?? {}).forEach(([id, value]) => {
                        valueMap.set(id, value * 100)
                    })
                    break
                }
                case 'arcStartingAngle': {
                    Object.entries(arcDataState?.startingAngle.values ?? {}).forEach(([id, value]) => {
                        valueMap.set(id, (value * 180) / Math.PI)
                    })
                    break
                }
                case 'arcInnerRadius': {
                    Object.entries(arcDataState?.innerRadius.values ?? {}).forEach(([id, value]) => {
                        valueMap.set(id, value * 100)
                    })
                    break
                }
                case 'sweep': {
                    Object.entries(arcDataState?.sweep.values ?? {}).forEach(([id, value]) => {
                        valueMap.set(id, value * 100)
                    })
                    break
                }
            }
            return valueMap
        }
    }
    const onScrubChangeAllMixed = (key: string) => {
        return (values: Map<string, Value>) => {
            const result = Object.fromEntries(values) as Record<string, number>
            switch (key) {
                case 'pointCount':
                    return command.DEPRECATED_invokeBridge(BatchUpdatePointCountCommand, { values: result })
                case 'innerRadius':
                case 'arcInnerRadius':
                    return command.DEPRECATED_invokeBridge(BatchUpdateInnerRadiusCommand, {
                        values: Object.entries(result).reduce((prev, current) => {
                            // 对象中每个value 百分比转小数
                            return { ...prev, [current[0]]: current[1] / 100 }
                        }, {}),
                    })
                case 'arcStartingAngle':
                    return command.DEPRECATED_invokeBridge(BatchUpdateArcStartingAngleCommand, {
                        values: Object.entries(result).reduce((prev, [nodeId, value]) => {
                            // 对象中每个value 角度转弧度
                            return { ...prev, [nodeId]: (value / 180) * Math.PI }
                        }, {}),
                    })
                case 'sweep': {
                    command.DEPRECATED_invokeBridge(BatchUpdateArcSweepCommand, {
                        values: Object.entries(result).reduce((prev, current) => {
                            // 对象中每个value 百分比转小数
                            return { ...prev, [current[0]]: current[1] / 100 }
                        }, {}),
                    })
                    return
                }
                case 'minW': {
                    return command.DEPRECATED_invokeBridge(BatchUpdateMinSizeAndMaxSizeWasmCall, {
                        params: Object.entries(result).map(([id, value]) => ({
                            id,
                            value,
                            type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                                .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_WIDTH,
                        })),
                    })
                }
                case 'maxW': {
                    return command.DEPRECATED_invokeBridge(BatchUpdateMinSizeAndMaxSizeWasmCall, {
                        params: Object.entries(result).map(([id, value]) => ({
                            id,
                            value: value || NaN,
                            type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                                .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_WIDTH,
                        })),
                    })
                }
                case 'minH': {
                    return command.DEPRECATED_invokeBridge(BatchUpdateMinSizeAndMaxSizeWasmCall, {
                        params: Object.entries(result).map(([id, value]) => ({
                            id,
                            value,
                            type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                                .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_HEIGHT,
                        })),
                    })
                }
                case 'maxH': {
                    return command.DEPRECATED_invokeBridge(BatchUpdateMinSizeAndMaxSizeWasmCall, {
                        params: Object.entries(result).map(([id, value]) => ({
                            id,
                            value: value || NaN,
                            type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                                .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_HEIGHT,
                        })),
                    })
                }
                default:
                    return
            }
        }
    }

    return {
        polygonStarIcon,
        tooltipTitle,
        pointCountMixed,
        pointCountValue,
        showPointCount,
        starInnerRadiusValue,
        starInnerRadiusMixed,
        showStarInnerRadius,
        innerRadiusMixed,
        innerRadiusValue,
        sweepMixed,
        sweepValue,
        arcDataStartingAngleMixed,
        arcDataStartingAngleValue,
        batchUpdateBaseAttribute,
        onChangeNew,
        getScrubStartValueMixed,
        onChangeMixed,
        selectionW,
        selectionH,
        rotation,
        selectionX,
        selectionY,
        constrainProportions,
        cornerRadius,
        cornerSmoothing,
        independentCornerRadius,
        topLeftRadius,
        topRightRadius,
        onScrubChangeAllMixed,
        bottomLeftRadius,
        bottomRightRadius,
        clipsContent,
        onChange,
        transformErrorCallback,
        hiddenArc,
        resizeToFit,
        onClickGroupToFrame,
        onClickFrameToGroup,
        disabledByAILayout: !!panelStateUnderAILayout?.disableAutoLayout,
    }
}
