import { compareString, createSelectors, createStore } from '../../../../../util/src'
import { isAbortError } from '../../../../../util/src/abort-controller/abort-error'
import { createSwitchTask } from '../../../../../util/src/abort-controller/signal-task'
import { TraceableAbortSignal } from '../../../../../util/src/abort-controller/traceable-abort-controller'
import { environment } from '../../../environment'
import type { LibraryQueryResponse, LibraryVO } from '../../../kernel/interface/library'
import { ResourceType, TeamID } from '../../../kernel/interface/type'
import { GetDefaultLibraryQuery, GetLibraryQuery } from '../../../kernel/request/library'
import { ServiceClass } from '../../../kernel/util/service-class'
export interface CustomLibraryTeamInfo {
    id: TeamID
    name: string
    libraryList: ReadonlyArray<LibraryVO>
}

export class LibraryRemoteSearchService extends ServiceClass {
    public states = createSelectors(
        createStore<{
            librarySearchKeywordState: string
            librarySearchLoadingState: boolean
            librarySearchErrorState: boolean
            libraryQueryResponseState: LibraryQueryResponse | undefined
            libraryTeamInfoListState: CustomLibraryTeamInfo[] | undefined
        }>(
            () => ({
                librarySearchKeywordState: '',
                librarySearchLoadingState: false,
                librarySearchErrorState: false,
                libraryQueryResponseState: undefined,
                libraryTeamInfoListState: undefined,
            }),
            environment.isDev
        )
    )

    private librarySearchKeyword: string | undefined = undefined
    private getLibraryQuery = (keyword: string) => {}

    private getFilteredLibraryList = (teamLibraries: LibraryVO[]) => {
        return this.resourceType === ResourceType.Document
            ? teamLibraries.filter(({ document }) => document?.id !== this.resourceId)
            : teamLibraries
    }

    private createTeamInfoList = (teamInfo: LibraryQueryResponse, needMatchedCount: boolean) => {
        return Object.entries(teamInfo.teamId2TeamName)
            .map(([id, name]) => {
                const libraryList = this.getFilteredLibraryList(teamInfo.teamId2LibraryList[id])
                const baseInfo = { id, name, libraryList }

                if (!needMatchedCount) {
                    return baseInfo
                }

                return {
                    ...baseInfo,
                    matchedCount: libraryList.reduce(
                        (count, { id: libraryId }) =>
                            count +
                            (teamInfo.libraryId2MatchedComponentList[libraryId]?.length ?? 0) +
                            (teamInfo.libraryId2MatchedStyleList[libraryId]?.length ?? 0),
                        0
                    ),
                }
            })
            .filter(({ libraryList }) => libraryList.length > 0)
            .sort((t1, t2) => {
                if (needMatchedCount && 'matchedCount' in t1 && 'matchedCount' in t2) {
                    const diff = t2.matchedCount - t1.matchedCount
                    return diff === 0 ? compareString(t1.name, t2.name) : diff
                } else {
                    return compareString(t1.name, t2.name)
                }
            })
    }

    private init = (signal: TraceableAbortSignal) => {
        this.getLibraryQuery = createSwitchTask(signal, async (signal: TraceableAbortSignal, keyword: string) => {
            try {
                const libraryQueryRes = await (this.resourceType === ResourceType.Document
                    ? new GetLibraryQuery(this.resourceId, keyword).startWithSignal(signal)
                    : new GetDefaultLibraryQuery(this.resourceId, this.resourceType, keyword).startWithSignal(signal))
                this.states.setState({
                    libraryQueryResponseState: libraryQueryRes,
                    libraryTeamInfoListState: this.createTeamInfoList(libraryQueryRes, Boolean(keyword)),
                })
            } catch (e) {
                // 如果非 abort 取消请求，则设置错误
                if (!isAbortError(e)) {
                    this.setLibrarySearchError(true)
                }
            } finally {
                this.setLibrarySearchLoading(false)
            }
        })

        this.updateSearchKeyword('')
    }

    private updateSearchKeyword = (keyword: string) => {
        if (this.librarySearchKeyword === keyword) {
            return
        }

        this.setLibrarySearchLoading(true)
        this.setLibrarySearchError(false)

        this.librarySearchKeyword = keyword
        this.states.setState({ librarySearchKeywordState: keyword })

        this.getLibraryQuery(keyword)
    }

    constructor(
        private readonly signal: TraceableAbortSignal,
        private readonly resourceId: string,
        private readonly resourceType: ResourceType
    ) {
        super()
        this.init(this.signal)
    }

    public search = (key: string) => {
        this.updateSearchKeyword(key)
    }

    private setLibrarySearchLoading = (value: boolean) => {
        this.states.setState({ librarySearchLoadingState: value })
    }

    private setLibrarySearchError = (value: boolean) => {
        this.states.setState({ librarySearchErrorState: value })
    }
}
