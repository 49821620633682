/* eslint-disable no-restricted-imports */
import { LayerPanelExpandClickCommand, Wukong } from '@wukong/bridge-proto'
import { FC, MouseEventHandler, PropsWithChildren, useEffect, useMemo, useRef } from 'react'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { MouseStatus, useDragContext } from './context'
import styles from './index.module.less'
import { caculatePlaceNodeIndent, getItemIndent } from './util'
import VLayerPanelNodeExpandStauts = Wukong.DocumentProto.VLayerPanelNodeExpandStauts

interface LayerPanelDragNodeItemProps {
    item: Wukong.DocumentProto.VLayerPanelNode
    preItem: Wukong.DocumentProto.VLayerPanelItemInfo | undefined
    nextItem: Wukong.DocumentProto.VLayerPanelItemInfo | undefined
    index: number
}

enum DragArea {
    None,
    Top,
    Middle,
    Bottom,
}

const SingleIndentMovement = 16

// 用于为 renderItem 添加响应 Drag 的能力
export const LayerPanelDragNodeItem: FC<PropsWithChildren<LayerPanelDragNodeItemProps>> = ({
    children,
    item,
    preItem,
    nextItem,
    index,
}) => {
    const {
        mouseStatusRef,
        mouseDownPositionRef,
        handleMouseUp: onMouseUp,
        dragTargetInfo,
        selectionMinIndentRef,
        setDropInfo,
        dragExpandNodeIdsRef,
    } = useDragContext()

    const docReadonly = useViewState('docReadonly')

    const command = useCommand()

    const expandTimer = useRef<NodeJS.Timeout>()

    const dragArea = useMemo<DragArea>(() => {
        if (!dragTargetInfo || dragTargetInfo?.nodeId !== item.id) {
            return DragArea.None
        }
        const { mouseArea } = dragTargetInfo
        // 如果是容器节点且该容器没有在选择节点的祖先链路中，则有上中下三个部位
        if (item.isContainer && !item.isAncestorSelected && !item.isSelected) {
            if (mouseArea < 0.3) {
                return DragArea.Top
            } else if (mouseArea > 0.7) {
                return DragArea.Bottom
            } else {
                return DragArea.Middle
            }
        } // 非容器节点有上和下两个部位
        else {
            if (mouseArea < 0.5) {
                return DragArea.Top
            }
            return DragArea.Bottom
        }
    }, [dragTargetInfo, item.id, item.isAncestorSelected, item.isContainer, item.isSelected])

    // 在改变放置的层级时，不能放置在下层容器层级之上的位置

    const minIndent = useMemo<number>(() => {
        return getItemIndent(nextItem)
    }, [nextItem])

    // 由位移改变的层级
    const movementIndent = useMemo<number>(() => {
        if (!dragTargetInfo) {
            return 0
        }
        const indent = dragTargetInfo.movementX / SingleIndentMovement
        return indent < 0 ? Math.ceil(indent) : Math.floor(indent)
    }, [dragTargetInfo])

    // 计算当前引导线的层级
    const currentIndent = useMemo<number>(() => {
        const currentItemIndent = item.indent
        const prevItemIndent = getItemIndent(preItem)

        switch (dragArea) {
            // 如果拖拽不在当前节点，则忽略
            case DragArea.None: {
                return 0
            }
            // 放置到当前元素的下面
            case DragArea.Bottom: {
                return caculatePlaceNodeIndent(
                    // 容器节点可以放置在容器内部，故默认的层级可以 + 1
                    currentItemIndent + (item.isContainer ? 1 : 0),
                    minIndent,
                    selectionMinIndentRef.current!,
                    movementIndent
                )
            }
            // 放置到当前元素的中间【一定是容器节点，放置到容器节点内部】
            case DragArea.Middle: {
                return currentItemIndent + 1
            }
            // 放置到当前元素的上方
            case DragArea.Top: {
                // 如果上一个元素不存在，则 indent 一定为 0
                if (!preItem) {
                    return 0
                }
                return caculatePlaceNodeIndent(
                    prevItemIndent +
                        (preItem.type == Wukong.DocumentProto.LayerPanelItemType.LAYER_PANEL_ITEM_TYPE_NODE &&
                        preItem.layerPanelNodeInfo.isContainer
                            ? 1
                            : 0),
                    currentItemIndent,
                    selectionMinIndentRef.current!,
                    movementIndent
                )
            }
        }
    }, [dragArea, item.indent, item.isContainer, preItem, minIndent, selectionMinIndentRef, movementIndent])

    // 设置放置节点的具体信息
    useEffect(() => {
        switch (dragArea) {
            // 不在 drag 区域，跳过
            case DragArea.None: {
                return
            }
            // 在 drag 上半区，放置到 preItem 上
            case DragArea.Top: {
                setDropInfo({
                    indent: currentIndent,
                    itemInfo:
                        preItem ??
                        Wukong.DocumentProto.VLayerPanelItemInfo.create({
                            type: Wukong.DocumentProto.LayerPanelItemType.LAYER_PANEL_ITEM_TYPE_NODE,
                            layerPanelNodeInfo: Wukong.DocumentProto.VLayerPanelNode.create({ id: '' }),
                        }),
                })
                break
            }
            // 其余情况放置到 item 上
            default: {
                setDropInfo({
                    indent: currentIndent,
                    itemInfo: Wukong.DocumentProto.VLayerPanelItemInfo.create({
                        type: Wukong.DocumentProto.LayerPanelItemType.LAYER_PANEL_ITEM_TYPE_NODE,
                        layerPanelNodeInfo: item,
                    }),
                })
            }
        }
    }, [currentIndent, dragArea, item, item.id, preItem, setDropInfo])

    // 如果在容器中心停留 1.5s，且容器为折叠时，展开容器
    useEffect(() => {
        if (
            dragArea === DragArea.Middle &&
            item.expandStatus === VLayerPanelNodeExpandStauts.V_LAYER_PANEL_NODE_EXPAND_STAUTS_UN_EXPANDED
        ) {
            expandTimer.current = setTimeout(() => {
                command.DEPRECATED_invokeBridge(
                    LayerPanelExpandClickCommand,
                    Wukong.DocumentProto.BridgeProtoString.create({
                        value: item.id,
                    })
                )
                dragExpandNodeIdsRef.current = dragExpandNodeIdsRef.current.concat([item.id!])
            }, 1500)
        } else {
            if (expandTimer.current) {
                clearTimeout(expandTimer.current)
            }
        }
    }, [command, dragArea, dragExpandNodeIdsRef, item.expandStatus, item.id])

    const handleMouseDown: MouseEventHandler<HTMLDivElement> = (event) => {
        const { button, metaKey, shiftKey, ctrlKey, clientX, clientY } = event
        if (docReadonly) {
            return
        }
        // 只响应单纯的左键点下事件
        if (metaKey || shiftKey || button !== 0 || (button === 0 && ctrlKey)) {
            return
        }
        // 设置 mouseDown
        mouseStatusRef.current = MouseStatus.Down
        // 记录点击点的 X/Y 信息
        mouseDownPositionRef.current = { x: clientX, y: clientY }
    }

    const handleMouseUp: MouseEventHandler<HTMLDivElement> = (event) => {
        onMouseUp(event)
    }

    const nextItemIsFixedSection = useMemo(() => {
        if (nextItem?.type == Wukong.DocumentProto.LayerPanelItemType.LAYER_PANEL_ITEM_TYPE_SECTION) {
            return nextItem.layerPanelSectionInfo.type == Wukong.DocumentProto.SectionType.SECTION_TYPE_FIXED
        }
        return false
    }, [nextItem?.layerPanelSectionInfo.type, nextItem?.type])

    return (
        <div
            className={styles['layer-panel-drag-item']}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            data-testid={`drag-node-item-${index}`}
        >
            {dragArea === DragArea.Top && (
                <div
                    className={styles['drag-line']}
                    data-testid={`drag-line-top-${index}`}
                    style={{ marginLeft: SingleIndentMovement + currentIndent * SingleIndentMovement + 'px' }}
                />
            )}
            {children}
            {dragArea === DragArea.Bottom && !nextItemIsFixedSection && (
                <div
                    className={styles['drag-line']}
                    data-testid={`drag-line-bottom-${index}`}
                    style={{ marginLeft: SingleIndentMovement + currentIndent * SingleIndentMovement + 'px' }}
                />
            )}
        </div>
    )
}
