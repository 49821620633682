import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Fixed: '固定组',
    Scrolls: '滚动组',
} as const

export const enTranslation = {
    Fixed: 'FIXED',
    Scrolls: 'SCROLLS',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
