import { TextEditCustomInputId } from './text-editing-input/text-editing-input-element'

/**
 * 如果在可输入状态下发出的 keyboard event，可能要静默处理
 * @param event
 * @returns
 */
export const isKeyboardEventTargetEditable = (event: KeyboardEvent) => {
    return event.isComposing || isEventTargetEditable(event)
}

/**
 * 是否是 input textarea 的原生输入行为
 * @param event
 */
export const isEventTargetEditable = (event: Event) => {
    return (
        event.target instanceof HTMLInputElement ||
        event.target instanceof HTMLTextAreaElement ||
        isTargetContentEditable((event as any).target)
    )
}

/**
 * 是否是文本节点隐藏 input 的 event
 * @param event
 */
export const isFromTextNodeHiddenInput = (event: Event) => {
    return event.target instanceof HTMLInputElement && event.target.id === TextEditCustomInputId
}

function isTargetContentEditable(target: HTMLElement) {
    return target.tagName === 'DIV' && target.contentEditable === 'true'
}

/**
 * 当选择器/菜单项打开 / 有聚焦态 / 弹窗 时，禁用快捷键
 */

export const needForbidShortcuts = () => {
    const activeElement = document.activeElement as HTMLElement | null
    return activeElement?.dataset.forbidShortcuts === 'true' || document.querySelectorAll('[role="dialog"]').length > 0
}
