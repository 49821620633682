import { Wukong } from '@wukong/bridge-proto'
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
import { Position } from '../../../../../../ui-lib/src'
import { cmdChangePopupState } from '../../../../document/command/document-command'
import { cmdChangeAttrPanelStyleEditorState } from '../../../../document/command/node-props-command'
import { useComponentService } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { modifiedMultiPopup } from '../styles/modified-multi-popup'
import { ColorPanel } from './color-panel/color-panel'
import { ColorPanelRef } from './color-panel/use-color-panel'
import { ColorSet } from './color-set/color-set'
import { ColorInteractionFrom, ColorInteractionProps, ColorLibrariesProps, ColorSetProps } from './type'

export interface ColorInteractionRef {
    getContainerElement: () => HTMLDivElement | null
}

function _ColorInteraction(props: ColorInteractionProps, ref: React.Ref<ColorInteractionRef>) {
    const { onChangeColorVar, onClickCreateStyleButton } = props
    const popupState = useViewState('popupState')
    const command = useCommand()
    const componentService = useComponentService()
    const [colorSetProps, setColorSetProps] = useState<
        Pick<ColorSetProps, 'position' | 'isCreateStyle' | 'isRemoteStyle' | 'hideVariableTab'> & {
            remoteStyleInfo: Parameters<ColorLibrariesProps['onClickEditStyle']>[1]
        }
    >()
    const colorPaintRef = useRef<ColorPanelRef>(null)

    const changeColorStylePopupState = useCallback(
        (open: boolean) => {
            command.invoke(
                cmdChangePopupState,
                modifiedMultiPopup(
                    popupState,
                    Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_PAINT_STYLE_EDITOR,
                    open
                        ? {
                              type: Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_PAINT_STYLE_EDITOR,
                              reciprocalIndex: -1,
                              multiPopup: [],
                          }
                        : undefined
                )
            )
        },
        [command, popupState]
    )

    const createColorStyle = useCallback(
        (position: Position) => {
            setColorSetProps({
                position,
                isCreateStyle: true,
                isRemoteStyle: false,
                hideVariableTab: false,
                remoteStyleInfo: undefined,
            })
            changeColorStylePopupState(true)
        },
        [changeColorStylePopupState]
    )

    const editColorStyle = useCallback(
        (position: Position, remoteStyleInfo: Parameters<ColorLibrariesProps['onClickEditStyle']>[1]) => {
            setColorSetProps({
                position,
                isCreateStyle: false,
                isRemoteStyle: !!remoteStyleInfo?.styleId,
                hideVariableTab: true,
                remoteStyleInfo,
            })
            changeColorStylePopupState(true)
        },
        [changeColorStylePopupState]
    )

    const onCancel = useCallback(() => {
        setColorSetProps(undefined)
        changeColorStylePopupState(false)
    }, [changeColorStylePopupState])

    const onClickCreateButton = useCallback(() => {
        setColorSetProps(undefined)
        changeColorStylePopupState(false)
        command.invoke(cmdChangeAttrPanelStyleEditorState, {
            editingStyleId: '',
        })
        onClickCreateStyleButton?.()
        colorPaintRef.current?.clearPaintCache()
    }, [changeColorStylePopupState, command, onClickCreateStyleButton])

    const onCreateColorVariable = useCallback(
        (v: Wukong.DocumentProto.IVariableAliasData) => {
            setColorSetProps(undefined)
            changeColorStylePopupState(false)
            onChangeColorVar(v)
            colorPaintRef.current?.clearPaintCache()
        },
        [changeColorStylePopupState, onChangeColorVar]
    )

    const renderCreateModule = () => {
        if (!colorSetProps) {
            return null
        }
        switch (props.from) {
            case ColorInteractionFrom.FILL_PAINT:
            case ColorInteractionFrom.STROKE_PAINT:
            case ColorInteractionFrom.FILL:
            case ColorInteractionFrom.STROKE:
            case ColorInteractionFrom.SELECTION_COLOR:
            case ColorInteractionFrom.SELECTION_COLOR_FAKE_STYLE:
                return (
                    <ColorSet
                        {...colorSetProps}
                        zIndex={props.zIndex}
                        from={props.from}
                        hideStyleTab={false}
                        allwaysUseStyleTab={colorSetProps.isCreateStyle}
                        paints={props.paints}
                        onClickRemoteStyleLink={() =>
                            componentService.jumpToOriginDocument({
                                docId: colorSetProps.remoteStyleInfo?.docId,
                                nodeId: colorSetProps.remoteStyleInfo?.styleId,
                                name: colorSetProps.remoteStyleInfo?.name,
                                fromFig: colorSetProps.remoteStyleInfo?.fromFig,
                            })
                        }
                        onCreateStyle={(v) => props.onChangeStyle(v, false, true)}
                        onClickCreateButton={onClickCreateButton}
                        onCreateColorVariable={onCreateColorVariable}
                        onCancel={onCancel}
                        hideCommonFooter={false}
                        key={String(colorSetProps.isCreateStyle)}
                    />
                )
            case ColorInteractionFrom.EFFECT:
                return (
                    <ColorSet
                        {...colorSetProps}
                        zIndex={props.zIndex}
                        hideStyleTab
                        allwaysUseStyleTab={colorSetProps.isCreateStyle}
                        paints={[props.paint]}
                        onClickRemoteStyleLink={undefined}
                        onCreateStyle={undefined}
                        onClickCreateButton={onClickCreateButton}
                        onCreateColorVariable={onCreateColorVariable}
                        onCancel={onCancel}
                        hideCommonFooter={false}
                        key={String(colorSetProps.isCreateStyle)}
                    />
                )
            case ColorInteractionFrom.STYLE_PAINT:
            case ColorInteractionFrom.COLOR_STOP:
            case ColorInteractionFrom.LOCAL_VARIABLE_EDIT:
            case ColorInteractionFrom.LOCAL_VARIABLE_CREATE_ALIAS:
            case ColorInteractionFrom.BACKGROUND:
            case ColorInteractionFrom.GRID:
                return null
        }
    }

    useImperativeHandle(ref, () => ({
        getContainerElement: () => colorPaintRef.current?.getContainerElement() ?? null,
    }))

    return (
        <>
            <ColorPanel
                {...props}
                onClickAddIcon={createColorStyle}
                onClickEditStyle={editColorStyle}
                ref={colorPaintRef}
            />
            {renderCreateModule()}
        </>
    )
}

export const ColorInteraction = forwardRef<ColorInteractionRef, ColorInteractionProps>(_ColorInteraction)
