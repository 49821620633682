/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { IconCheckboxChecked } from '../../../../../ui-lib/src'
import classnames from 'classnames'
import { DeepRequired, useViewState } from '../../../view-state-bridge'
import { IconPage } from '../design/hyperlink/hover-popup/svg'
import { LayerPanelItemIcon } from '../layer-panel/render-item-icon'
import { CanvasSearchMatchText } from './canvas-search-match-text'
import styles from './canvas-search-result-layer-item.module.less'
import { CanvasSearchParamModel, CanvasSearchResultModel } from './use-canvas-search-model'

interface Props {
    layerItem: DeepRequired<Wukong.DocumentProto.ICanvasSearchLayerItem>
    nodeIcon: Wukong.DocumentProto.INodeIconData | null
    selected: boolean
    indirectSelected: boolean
    hovered: boolean
    canvasSearchResultModel: CanvasSearchResultModel
    canvasSearchParamModel: CanvasSearchParamModel
}

function CanvasSearchResultLayerItemContent({ layerItem, nodeIcon, selected, canvasSearchParamModel }: Props) {
    const currentPageId = useViewState('currentPageId')

    if (layerItem.nodeType === Wukong.DocumentProto.NodeType.NODE_TYPE_PAGE) {
        return (
            <>
                <div className={styles.icon}>
                    {currentPageId === layerItem.nodeId ? <IconCheckboxChecked /> : <IconPage />}
                </div>
                <div className={styles.detail}>
                    <CanvasSearchMatchText
                        className={classnames(styles.name, styles.multiline)}
                        content={layerItem.name}
                        textMatch={layerItem.nameMatch}
                        isPurple={false}
                        multiline
                    />
                </div>
            </>
        )
    }

    const layerPanelItemIcon = (
        <LayerPanelItemIcon
            id={layerItem.nodeId}
            nodeIcon={nodeIcon as Wukong.DocumentProto.NodeIconData}
            isSelected={selected}
            isPurple={layerItem.isPurple}
            isFontBold={false}
            visibleStatus={Wukong.DocumentProto.VLayerPanelVisibleStatus.V_LAYER_PANEL_VISIBLE_STATUS_VISIBLE}
            disabled
        />
    )

    if (layerItem.matchType == Wukong.DocumentProto.TextMatchType.TEXT_MATCH_TYPE_TEXT) {
        const { modelState, replaceValue } = canvasSearchParamModel
        const replacement =
            modelState.mode === Wukong.DocumentProto.CanvasSearchMode.CANVAS_SEARCH_MODE_REPLACE ? replaceValue : ''

        return (
            <>
                {layerPanelItemIcon}
                <div className={styles.detail}>
                    <CanvasSearchMatchText
                        className={classnames(styles.name, styles.multiline)}
                        content={layerItem.textContent}
                        textMatch={layerItem.textContentMatch}
                        multiline
                        isPurple={layerItem.isPurple}
                        replacement={replacement}
                    />
                </div>
            </>
        )
    }

    if (layerItem.nodeType === Wukong.DocumentProto.NodeType.NODE_TYPE_TEXT) {
        // jest测试中start/end都被编码为Long类型, 但是实际运行中是number类型, 这里强制转换为Number避免jest测试失败
        const hasTextContentMatch = Number(layerItem.textContentMatch.start) != Number(layerItem.textContentMatch.end)
        return (
            <>
                {layerPanelItemIcon}
                <div className={styles.detail}>
                    <CanvasSearchMatchText
                        className={classnames(styles.name, { [styles.multiline]: hasTextContentMatch })}
                        content={layerItem.textContent}
                        textMatch={layerItem.textContentMatch}
                        isPurple={layerItem.isPurple}
                        multiline={hasTextContentMatch}
                    />
                    <CanvasSearchMatchText
                        className={styles.container}
                        content={layerItem.name}
                        textMatch={layerItem.nameMatch}
                        isPurple={layerItem.isPurple}
                        multiline={false}
                    />
                </div>
            </>
        )
    }

    return (
        <>
            {layerPanelItemIcon}
            <div className={styles.detail}>
                <CanvasSearchMatchText
                    className={classnames(styles.name, styles.multiline)}
                    content={layerItem.name}
                    textMatch={layerItem.nameMatch}
                    isPurple={layerItem.isPurple}
                    multiline
                />
                {layerItem.containerName ? <div className={styles.container}>{layerItem.containerName}</div> : null}
            </div>
        </>
    )
}

export function CanvasSearchResultLayerItem({
    layerItem,
    nodeIcon,
    selected,
    indirectSelected,
    hovered,
    canvasSearchResultModel,
    canvasSearchParamModel,
}: Props) {
    const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
        if (event.metaKey) {
            canvasSearchResultModel.modelCommand.comandClickLayerItem(layerItem)
        } else if (event.shiftKey) {
            canvasSearchResultModel.modelCommand.shiftClickLayerItem(layerItem)
        } else {
            canvasSearchResultModel.modelCommand.clickLayerItem(layerItem)
        }
    }

    const handleEnterHover = () => {
        canvasSearchResultModel.modelCommand.enterHover(layerItem)
    }

    const handleLeaveHover = () => {
        canvasSearchResultModel.modelCommand.leaveHover(layerItem)
    }

    return (
        <div
            className={classnames(styles.row, {
                [styles.purple]: layerItem.isPurple,
                [styles.selected]: selected,
                [styles.indirectSelected]: indirectSelected,
                [styles.hovered]: hovered,
            })}
            onDoubleClick={() => canvasSearchParamModel.modelCommand.exitSearch()}
            onClick={handleClick}
            onMouseEnter={handleEnterHover}
            onMouseLeave={handleLeaveHover}
            data-testid="canvas-search-result-layer-item"
        >
            <CanvasSearchResultLayerItemContent
                layerItem={layerItem}
                selected={selected}
                indirectSelected={indirectSelected}
                hovered={hovered}
                nodeIcon={nodeIcon}
                canvasSearchResultModel={canvasSearchResultModel}
                canvasSearchParamModel={canvasSearchParamModel}
            />
        </div>
    )
}
