import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    Image: '图片',
    LinearGradient: '线性渐变',
    RadialGradient: '径向渐变',
    AngularGradient: '角度渐变',
    DiamondGradient: '菱形渐变',
} as const

export const enTranslation = {
    Image: 'Image',
    LinearGradient: 'Linear',
    RadialGradient: 'Radial',
    AngularGradient: 'Angular',
    DiamondGradient: 'Diamond',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
