import { useCallback, useRef } from 'react'
import {
    DropdownMinimalSingleLevelRef,
    DropdownV2,
    MonoIconCanvasLightning16,
    MonoIconCommonaddPicture16,
    MonoIconCommonCheckboxChecked16,
    MonoIconCommonDelete16,
    MonoIconCommonLoading16,
    MonoIconCommonrandom16,
    MonoIconCommonRemix16,
    MonoIconCommonStyling16,
    MonoIconPanelAdjust16,
    MonoIconPanelReset16,
    Tooltip,
    useEllipsisTooltip,
    WKButton,
    WKDialog,
    wkDialogConfirm,
    WKIconButton,
} from '../../../../../../ui-lib/src'
import { useFeatureSwitch } from '../../../../kernel/switch'
import { dynamicSwitchService } from '../../../../main/service/dynamic-switch-service'
import { CircleProgressDot } from './ai-gen-progress-dot'
import style from './ai-gen-ui-footer.module.less'
import { translation } from './ai-gen-ui-footer.translation'
import { Remix } from './constants'
import { DesignSystemEditor } from './design-system-custom'
import { isBuiltinRemix } from './use-ai-gen-platform-and-design-system'
import { useAIGenUIInContext } from './use-ai-gen-ui'

/**
 * Renders a single remix option item within the design system dropdown
 */
function RemixOptionItem({
    remix,
    isSelected,
    onSelect,
    disableCreatingRemix,
}: {
    remix: Remix
    isSelected: boolean
    onSelect: () => void
    disableCreatingRemix: boolean
}) {
    const { createTempRemix, selectDesignSystem } = useAIGenUIInContext()
    const { name, id, designSystem, platform } = remix
    const isBuiltin = isBuiltinRemix(remix)

    const handleRemixClick = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        onSelect()
        createTempRemix(id)
    }

    const iconButtonClassName = `invisible group-hover:visible ${
        isBuiltin && disableCreatingRemix ? '!cursor-not-allowed text-[var(--wk-v2-label-color-gray-8)] ' : ''
    }`

    return (
        <div className="isolate" data-testid={id}>
            <DropdownV2.MinimalSingleLevel.Option
                dataTestId="design-system-config"
                value={id}
                className={`group w-full flex h-8 ${style['ai-gen-ui-dropdown-menu-option']}`}
                centerContainerClassName="w-full"
                addRightPadding={false}
                isHover={false}
                forwardIconClassName={`!text-black isolate`}
                isInteractiveClassName="bg-transparent isolate text-black!"
                onClick={() => selectDesignSystem(id)}
            >
                <div className="flex flex-row gap-2 items-center w-54">
                    <div className="w-4 h-4">
                        {isSelected ? <MonoIconCommonCheckboxChecked16 id="wk-force-black" /> : <></>}
                    </div>
                    <span
                        style={{ color: 'var(--wk-gray-13) !important' }}
                        data-testid="design-system-config-content"
                        className="w-39 text-black text-ellipsis overflow-hidden whitespace-nowrap wk-break-word"
                    >
                        {designSystem === 'custom' && isBuiltin
                            ? platform === 'app'
                                ? translation('mobileAuto')
                                : translation('webAuto')
                            : name}
                        {isBuiltin && designSystem !== 'custom' && (
                            <span style={{ color: 'var(--wk-gray-6) !important' }} className="text-gray-light">
                                {translation('default')}
                            </span>
                        )}
                    </span>
                    {isBuiltin && disableCreatingRemix ? (
                        <Tooltip title={translation('maxiummTip')}>
                            <div>
                                <WKIconButton
                                    className={iconButtonClassName}
                                    data-testid={`remix-${id}`}
                                    icon={<MonoIconCommonRemix16 />}
                                    disabled={true}
                                    onClick={handleRemixClick}
                                />
                            </div>
                        </Tooltip>
                    ) : (
                        <Tooltip title={isBuiltin ? translation('remixStyling') : translation('editStyling')}>
                            <WKIconButton
                                className={iconButtonClassName}
                                data-testid={isBuiltin ? `remix-${id}` : `edit-${id}`}
                                icon={isBuiltin ? <MonoIconCommonRemix16 /> : <MonoIconPanelAdjust16 />}
                                disabled={isBuiltin && disableCreatingRemix}
                                onClick={handleRemixClick}
                            />
                        </Tooltip>
                    )}
                </div>
            </DropdownV2.MinimalSingleLevel.Option>
        </div>
    )
}

/**
 * Renders a group of remix options for a specific platform
 */
function ConfigDesignSystemPlatformGroup({
    remixes,
    platform,
    onSelect,
}: {
    remixes: Remix[]
    platform: string
    onSelect: (id: number) => void
}) {
    const { selectedRemixID, disableCreatingRemix } = useAIGenUIInContext()

    return (
        <div className="py-2 wk-gray-bottom-border">
            <h3 className="pl-4 h-7 m-0 py-0 flex wk-text-12">
                <span className="w-full h-full text-gray-light">{platform}</span>
            </h3>
            {remixes.map((remix) => (
                <RemixOptionItem
                    key={remix.id}
                    remix={remix}
                    isSelected={selectedRemixID === remix.id}
                    onSelect={() => onSelect(remix.id)}
                    disableCreatingRemix={disableCreatingRemix}
                />
            ))}
        </div>
    )
}

/**
 * Renders the dropdown menu for configuring design systems
 * Shows platform groups with their respective remix options
 */
function ConfigDesignSystemDropDownMenu({ onSelect }: { onSelect: (id: number) => void }) {
    const { appRemixes, webRemixes } = useAIGenUIInContext()
    const isLoading = appRemixes.length === 0 && webRemixes.length === 0

    return (
        <>
            <h3 className="w-full h-10 pl-4 m-0 py-0 flex justify-start wk-text-12 items-center wk-gray-bottom-border">
                <span>{translation('stylingPresets')}</span>
            </h3>
            {isLoading ? (
                <div className="w-60 h-26 m-0 flex justify-center wk-text-12 items-center wk-gray-bottom-border">
                    <MonoIconCommonLoading16 className="animate-spin" />
                </div>
            ) : (
                <>
                    {appRemixes.length > 0 && (
                        <ConfigDesignSystemPlatformGroup
                            remixes={appRemixes}
                            platform={translation('mobile')}
                            onSelect={onSelect}
                        />
                    )}
                    {webRemixes.length > 0 && (
                        <ConfigDesignSystemPlatformGroup
                            remixes={webRemixes}
                            platform={translation('web')}
                            onSelect={onSelect}
                        />
                    )}
                </>
            )}
        </>
    )
}

/**
 * Renders the dialog title for the design system editor
 */
function DesignSystemEditorTitle({
    title,
    isEditing,
    onDelete,
}: {
    title: React.ReactNode
    isEditing: boolean
    onDelete: () => void
}) {
    return (
        <div className={`w-full flex flex-row justify-between items-center`}>
            <span className="wk-text-14 text-black wk-font-semibold">{title}</span>
            {isEditing ? (
                <WKIconButton
                    className="mr-1.5"
                    data-testid="delete-remix"
                    icon={<MonoIconCommonDelete16 />}
                    onClick={onDelete}
                />
            ) : (
                <></>
            )}
        </div>
    )
}

/**
 * Component for configuring design system and platform
 * Includes a dropdown menu and a dialog for editing design systems
 */
function ConfigDesignSystemAndPlatform() {
    const {
        dropdownMenuDisplayValue,
        dropdownSelectOption,
        showDesignSystemEditor,
        editorTitle,
        discardTempRemix,
        isTempRemixChanged,
        creatingOrEditingRemix,
        finishCreatingOrEditRemix,
        deleteRemix,
        cachedRemixName,
        waitForModification,
    } = useAIGenUIInContext()
    const supportSelectPlatformAndDesignSystem =
        useFeatureSwitch('ai-gen-config-platform-and-design-system') || dynamicSwitchService.isFeatureEnabled('ai-gen')
    const ref = useRef<DropdownMinimalSingleLevelRef | null>(null)
    const { ellipsisRef, inactivation, onmouseenter, onmouseleave } = useEllipsisTooltip<HTMLSpanElement>()

    const onClickDeleteRemix = useCallback(() => {
        wkDialogConfirm.show({
            title: translation('deleteConfirmation'),
            content: <span>{translation('deleteConfirmationDescription')}</span>,
            okButtonProps: { danger: true },
            onOk: deleteRemix,
            okText: translation('delete'),
        })
    }, [deleteRemix])

    if (!supportSelectPlatformAndDesignSystem) {
        return null
    }

    const isEditing = creatingOrEditingRemix?.value === 'Save'

    const content = dropdownMenuDisplayValue ?? cachedRemixName ?? translation('mobileAuto')

    const tempContentNotChanged = !isTempRemixChanged

    return (
        <>
            <DropdownV2.MinimalSingleLevel
                dataTestIds={{ container: 'ai-gen-ui-design-system-dropdown' }}
                ref={ref}
                removeTopPadding
                removeBottomPadding
                lineClamp={1}
                label={
                    <div className="flex flex-row items-center gap-1 pl-1 py-1 flex-1 truncate ml-1">
                        <Tooltip title={translation('styling')}>
                            <MonoIconCommonStyling16 className="shrink-0" />
                        </Tooltip>
                        <Tooltip title={content} inactivation={inactivation}>
                            <span
                                ref={ellipsisRef}
                                onMouseEnter={onmouseenter}
                                onMouseLeave={onmouseleave}
                                className="wk-text-12 flex-1 truncate text-ellipsis overflow-hidden whitespace-nowrap text-black wk-break-word"
                            >
                                {content}
                            </span>
                        </Tooltip>
                    </div>
                }
                onChange={(value: number) => {
                    dropdownSelectOption(value)
                }}
                classNameWhenOpen="bg-gray-light"
                className="hover:bg-gray-light text-[var(--wk-v2-label-color-gray-8)] truncate pr-1"
            >
                <ConfigDesignSystemDropDownMenu
                    onSelect={() => {
                        ref.current?.close()
                    }}
                />
            </DropdownV2.MinimalSingleLevel>
            <WKDialog
                width={944}
                visible={showDesignSystemEditor}
                okButtonProps={{ disabled: tempContentNotChanged || waitForModification, loading: waitForModification }}
                bodyStyle={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }}
                onOk={finishCreatingOrEditRemix}
                onCloseIconCancel={discardTempRemix}
                onCancel={discardTempRemix}
                okText={creatingOrEditingRemix?.label}
                title={
                    <DesignSystemEditorTitle title={editorTitle} isEditing={isEditing} onDelete={onClickDeleteRemix} />
                }
            >
                <DesignSystemEditor />
            </WKDialog>
        </>
    )
}

/**
 * Button for applying a random example prompt
 */
function RandomExampleButton({ onClick }: { onClick: () => void }) {
    return (
        <Tooltip title={translation('randomPrompt')}>
            <WKIconButton
                data-testid="apply-example"
                type="secondary"
                className="w-6 h-6 p-1 shrink-0"
                icon={<MonoIconCommonrandom16 className="text-[var(--wk-gray-11)]" />}
                onClick={onClick}
            />
        </Tooltip>
    )
}

/**
 * Button for uploading an image
 */
function UploadImageButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    return (
        <Tooltip title={translation('UploadImage')}>
            <WKIconButton
                data-testid="upload image"
                type="secondary"
                className="w-6 h-6 p-1 shrink-0 mr-1"
                icon={
                    <MonoIconCommonaddPicture16
                        className={disabled ? '!color-[var(--wk-gray-6)]' : 'color-[var(--wk-gray-11)]'}
                    />
                }
                onClick={onClick}
            />
        </Tooltip>
    )
}

function LoadingButtonContent({
    finishedTaskCount,
    taskQuantity,
}: {
    finishedTaskCount: number
    taskQuantity: number
}) {
    return (
        <>
            <div className="w-2.5 h-2.5 rounded-4 border border-white bg-transparent p-0.25 flex justify-center items-center">
                <CircleProgressDot size={10} progress={finishedTaskCount === taskQuantity ? 100 : undefined} />
            </div>
            <span>
                {translation('InGen')}
                {`(${Math.min(finishedTaskCount + 1, taskQuantity)}/${taskQuantity})`}
            </span>
        </>
    )
}

function RetryButtonContent() {
    return (
        <>
            <MonoIconPanelReset16 />
            <span>{translation('Retry')}</span>
        </>
    )
}

function DefaultButtonContent() {
    return (
        <>
            <MonoIconCanvasLightning16 className="color-white" />
            <span>{translation('Gen')}</span>
        </>
    )
}

function GenBtnContent() {
    const { shouldRetry, waitForResult, taskQuantity, finishedTaskCount } = useAIGenUIInContext()

    if (waitForResult) {
        return <LoadingButtonContent finishedTaskCount={finishedTaskCount} taskQuantity={taskQuantity} />
    }

    if (shouldRetry) {
        return <RetryButtonContent />
    }

    return <DefaultButtonContent />
}

export function AIGenUIByCodeFooter() {
    const { disableGen, callAIGen, waitForResult, applyExample, triggerUploadImage, disableUploadImage } =
        useAIGenUIInContext()

    const buttonClassName = waitForResult ? style['ai-gen-ui-btn-gen-loading'] : style['ai-gen-ui-btn-gen-v2']

    return (
        <div className={style['ai-gen-ui-btn-container']}>
            <RandomExampleButton onClick={applyExample} />
            <UploadImageButton onClick={triggerUploadImage} disabled={disableUploadImage} />
            <div className="w-full pr-2 flex-1 overflow-hidden">
                <ConfigDesignSystemAndPlatform />
            </div>
            <WKButton
                className={buttonClassName}
                dataTestId="ai-gen-ui-btn"
                type="primary"
                onClick={callAIGen}
                disabled={disableGen}
            >
                <GenBtnContent />
            </WKButton>
        </div>
    )
}
