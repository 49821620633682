import type { FC } from 'react'
import styles from './index.module.less'

export const Progress: FC = () => {
    return (
        <div className={styles.outside}>
            <div className={styles.inside}>{<div className={styles.flight}></div>}</div>
        </div>
    )
}
