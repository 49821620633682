import constate from 'constate'
import { useRef, useState } from 'react'
import { SessionStorageKey } from '../../../web-storage/session-storage/config'
import { enhancedSessionStorage } from '../../../web-storage/session-storage/storage'

const MOVE_MIN_PIXEL_THRESHOLD = 4

// 默认宽高
const POPUP_DEFAULT_WIDTH = 672
const POPUP_DEFAULT_HEIGHT = 462
// 最小宽高
const POPUP_MIN_WIDTH = 672
const POPUP_MIN_HEIGHT = 462

const POPUP_MIN_TOP = 48 // 上边缘不能超出导航栏
const POPUP_FULLSCREEN_MARGIN = 8 // 最大化时上下左右留出的空隙

export const POPUP_HEADER_HEIGHT = 40

export const POPUP_RESIZE_HANDLER_WEIGHT = 16

export type ResizeHandler = 'n' | 's' | 'w' | 'e' | 'nw' | 'ne' | 'se' | 'sw'

function parseIntOrDefault(value: string, defaultValue: number) {
    try {
        return parseInt(value) || defaultValue
    } catch (e) {
        return defaultValue
    }
}
function getValidPosition(x: number, y: number, width: number) {
    return {
        // 最左和最右分别要留出宽度的一半，参考 figma
        x: Math.min(Math.max(-width / 2, x), window.innerWidth - width / 2),
        // 最大高度要将 header 留出来，参考 figma 表现
        y: Math.min(Math.max(POPUP_MIN_TOP, y), window.innerHeight - POPUP_HEADER_HEIGHT),
    }
}
function getValidSize(width: number, height: number) {
    return {
        width: Math.max(POPUP_MIN_WIDTH, width),
        height: Math.max(POPUP_MIN_HEIGHT, height),
    }
}
function getDefaultPosition(defaultSize: { width: number; height: number }) {
    const x = parseIntOrDefault(
        enhancedSessionStorage.getItem(SessionStorageKey.VariableLocalEditorPopupLeft) ?? '',
        (window.innerWidth - defaultSize.width) / 2
    )
    const y = parseIntOrDefault(
        enhancedSessionStorage.getItem(SessionStorageKey.VariableLocalEditorPopupTop) ?? '',
        (window.innerHeight - defaultSize.height) / 2
    )
    return getValidPosition(x, y, defaultSize.width)
}
function getDefaultSize() {
    const width = parseIntOrDefault(
        enhancedSessionStorage.getItem(SessionStorageKey.VariableLocalEditorPopupWidth) ?? '',
        POPUP_DEFAULT_WIDTH
    )
    const height = parseIntOrDefault(
        enhancedSessionStorage.getItem(SessionStorageKey.VariableLocalEditorPopupHeight) ?? '',
        POPUP_DEFAULT_HEIGHT
    )
    return getValidSize(width, height)
}

function _useLocalVariableEditorContext() {
    const [popupSize, setPopupSize] = useState(getDefaultSize())
    const [popupPosition, setPopupPosition] = useState(getDefaultPosition(popupSize))
    const [popupFullscreenState, setPopupFullscreenState] = useState<{
        width: number
        height: number
        x: number
        y: number
    } | null>(null)

    const _updatePopupSize = (size: { width: number; height: number }, skipCache = false) => {
        const validSize = getValidSize(size.width, size.height)
        setPopupSize(validSize)
        setPopupFullscreenState(null)
        if (!skipCache) {
            enhancedSessionStorage.setItem(SessionStorageKey.VariableLocalEditorPopupWidth, validSize.width.toString())
            enhancedSessionStorage.setItem(
                SessionStorageKey.VariableLocalEditorPopupHeight,
                validSize.height.toString()
            )
        }
    }
    const _updatePopupPosition = (position: { x: number; y: number }, skipCache = false) => {
        const validPosition = getValidPosition(position.x, position.y, popupSize.width)
        setPopupPosition(validPosition)
        setPopupFullscreenState(null)
        if (!skipCache) {
            enhancedSessionStorage.setItem(SessionStorageKey.VariableLocalEditorPopupLeft, validPosition.x.toString())
            enhancedSessionStorage.setItem(SessionStorageKey.VariableLocalEditorPopupTop, validPosition.y.toString())
        }
    }

    // 是否正在拖拽移动弹框
    const _popupIsDragging = useRef<boolean>(false)
    // 拖拽移动弹框的中间状态
    const _popupContentPointerDownState = useRef<{
        pointerDownPosition: { x: number; y: number }
        pointerDownPopupPosition: { x: number; y: number }
    } | null>(null)

    const popupPointerDown = (clientX: number, clientY: number) => {
        _popupContentPointerDownState.current = {
            pointerDownPosition: { x: clientX, y: clientY },
            pointerDownPopupPosition: popupPosition,
        }
    }
    const popupPointerMove = (clientX: number, clientY: number) => {
        if (!_popupContentPointerDownState.current) {
            return
        }
        const delta = Math.max(
            Math.abs(clientX - _popupContentPointerDownState.current.pointerDownPosition.x),
            Math.abs(clientY - _popupContentPointerDownState.current.pointerDownPosition.y)
        )
        if (delta >= MOVE_MIN_PIXEL_THRESHOLD || _popupIsDragging.current) {
            _popupIsDragging.current = true
            _updatePopupPosition({
                x:
                    clientX -
                    _popupContentPointerDownState.current.pointerDownPosition.x +
                    _popupContentPointerDownState.current.pointerDownPopupPosition.x,
                y:
                    clientY -
                    _popupContentPointerDownState.current.pointerDownPosition.y +
                    _popupContentPointerDownState.current.pointerDownPopupPosition.y,
            })
        }
    }
    const popupPointerUp = () => {
        _popupContentPointerDownState.current = null
        _popupIsDragging.current = false
    }

    // 拖拽缩放弹框的中间状态
    const _popupResizeHandlerPointerDownState = useRef<{
        pointerDownPosition: { x: number; y: number }
        pointerDownPopupSize: { width: number; height: number }
        pointerDownPopupPosition: { x: number; y: number }
        pointerDownResizeHandler: ResizeHandler
    } | null>(null)
    const popupHandlerPointerDown = (clientX: number, clientY: number, resizeHandler: ResizeHandler) => {
        _popupResizeHandlerPointerDownState.current = {
            pointerDownPosition: { x: clientX, y: clientY },
            pointerDownPopupSize: { width: popupSize.width, height: popupSize.height },
            pointerDownPopupPosition: { x: popupPosition.x, y: popupPosition.y },
            pointerDownResizeHandler: resizeHandler,
        }
    }
    const popupHandlerPointerMove = (clientX: number, clientY: number) => {
        clientX = Math.min(Math.max(0, clientX), window.innerWidth)
        clientY = Math.min(Math.max(POPUP_MIN_TOP, clientY), window.innerHeight)

        if (!_popupResizeHandlerPointerDownState.current) {
            return
        }
        const startX = _popupResizeHandlerPointerDownState.current.pointerDownPosition.x
        const startY = _popupResizeHandlerPointerDownState.current.pointerDownPosition.y
        const initLeft = _popupResizeHandlerPointerDownState.current.pointerDownPopupPosition.x
        const initTop = _popupResizeHandlerPointerDownState.current.pointerDownPopupPosition.y
        const initWidth = _popupResizeHandlerPointerDownState.current.pointerDownPopupSize.width
        const initHeight = _popupResizeHandlerPointerDownState.current.pointerDownPopupSize.height

        const handlerId = _popupResizeHandlerPointerDownState.current.pointerDownResizeHandler

        let x = initLeft
        let y = initTop
        let width = initWidth
        let height = initHeight

        if (handlerId.includes('n')) {
            const newHeight = initHeight + (startY - clientY)
            if (newHeight >= POPUP_MIN_HEIGHT) {
                height = newHeight
                y = initTop + (clientY - startY)
            } else {
                height = POPUP_MIN_HEIGHT
                y = initTop + (initHeight - POPUP_MIN_HEIGHT)
            }
        } else if (handlerId.includes('s')) {
            const newHeight = initHeight + (clientY - startY)
            if (newHeight >= POPUP_MIN_HEIGHT) {
                height = newHeight
            } else {
                height = POPUP_MIN_HEIGHT
            }
        }
        if (handlerId.includes('w')) {
            const newWidth = initWidth + (startX - clientX)
            if (newWidth >= POPUP_MIN_WIDTH) {
                width = newWidth
                x = initLeft + (clientX - startX)
            } else {
                width = POPUP_MIN_WIDTH
                x = initLeft + (initWidth - POPUP_MIN_WIDTH)
            }
        } else if (handlerId.includes('e')) {
            const newWidth = initWidth + (clientX - startX)
            if (newWidth >= POPUP_MIN_WIDTH) {
                width = newWidth
            } else {
                width = POPUP_MIN_WIDTH
            }
        }
        _updatePopupSize({ width, height })
        _updatePopupPosition({ x, y })
    }
    const popupHandlerPointerUp = () => {
        _popupResizeHandlerPointerDownState.current = null
    }

    const popupToggleFullscreen = () => {
        if (popupFullscreenState) {
            _updatePopupSize(popupFullscreenState, true)
            _updatePopupPosition(popupFullscreenState, true)
        } else {
            const originalSize = popupSize
            const originalPosition = popupPosition
            _updatePopupSize(
                getValidSize(
                    window.innerWidth - POPUP_FULLSCREEN_MARGIN * 2,
                    window.innerHeight - POPUP_MIN_TOP - POPUP_FULLSCREEN_MARGIN * 2
                ),
                true
            )
            _updatePopupPosition(
                {
                    x: POPUP_FULLSCREEN_MARGIN,
                    y: POPUP_MIN_TOP + POPUP_FULLSCREEN_MARGIN,
                },
                true
            )
            setPopupFullscreenState({
                width: originalSize.width,
                height: originalSize.height,
                x: originalPosition.x,
                y: originalPosition.y,
            })
        }
    }
    return {
        popupSize,
        popupPosition,
        popupFullscreenState,
        popupPointerDown,
        popupPointerMove,
        popupPointerUp,
        popupHandlerPointerDown,
        popupHandlerPointerMove,
        popupHandlerPointerUp,
        popupToggleFullscreen,
    }
}

export const [LocalVariableEditorContext, useLocalVariableEditorContext] = constate(_useLocalVariableEditorContext)
