import { GenerateLibraryThumbnail, GeneratePublishMixinThumbnailCommand, Wukong } from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { ConcurrentScheduler } from '../../../../../../../util/src'
import { CommandInvoker } from '../../../../../document/command/command-invoker'
import { NodeId } from '../../../../../document/node/node'
import { Bridge } from '../../../../../kernel/bridge/bridge'
import { featureSwitchManager } from '../../../../../kernel/switch/core'
import { ServiceClass } from '../../../../../kernel/util/service-class'
import type { ColorProfileService } from '../../../../../main/service/color-profile-service'
import { LibraryResourceDownloader } from '../../../../../share/component-style-library/service/library-resource-downloader'
import type { LibraryThumbnailKeyItem } from '../../../../../share/component-style-library/service/library-thumbnail-service-type'

interface LocalItem {
    contentHash: string
    thumbnailData: string
    effectStyleIconType?: Wukong.DocumentProto.EffectStyleIconType
    layoutGridStyleIconType?: Wukong.DocumentProto.LayoutGridStyleIconType
    colorProfile: Wukong.DocumentProto.DocumentColorProfile
}

// 本地组件/样式缩略图
export class LocalLibraryNodeThumbnailService extends ServiceClass {
    private localStore = new Map<NodeId, LocalItem>()
    // 生成本地缩略图并发控制器
    private localLibraryNodeThumbnailConcurrentScheduler = ConcurrentScheduler({ limitCount: 1, delayTime: 11 })

    constructor(
        private readonly commandInvoker: CommandInvoker,
        protected override readonly bridge: Bridge,
        private readonly colorProfileService: ColorProfileService,
        private readonly libraryResourceDownloader: LibraryResourceDownloader
    ) {
        super(bridge)
        this.initBindJsCall()
    }

    public override destroy() {
        super.destroy()
        this.localLibraryNodeThumbnailConcurrentScheduler.destroy()
    }

    public loadThumbnailData = async (
        arg: Wukong.DocumentProto.IThumbnailData
    ): Promise<LibraryThumbnailKeyItem | null> => {
        const {
            isLocal,
            id,
            contentHash,
            type,
            width,
            height,
            effectStyleIconType,
            layoutGridStyleIconType,
            variableIconType,
        } = arg
        if (type === Wukong.DocumentProto.NodeType.NODE_TYPE_EFFECT_STYLE) {
            return {
                isLocal: Boolean(isLocal),
                type,
                width,
                height,
                data: undefined,
                effectStyleIconType: effectStyleIconType ?? undefined,
                layoutGridStyleIconType: undefined,
            }
        }
        if (type === Wukong.DocumentProto.NodeType.NODE_TYPE_LAYOUT_GRID_STYLE) {
            return {
                isLocal: Boolean(isLocal),
                type,
                width,
                height,
                data: undefined,
                effectStyleIconType: undefined,
                layoutGridStyleIconType: layoutGridStyleIconType ?? undefined,
            }
        }
        if (featureSwitchManager.isEnabled('float-variable')) {
            if (type === Wukong.DocumentProto.NodeType.NODE_TYPE_VARIABLE) {
                return {
                    isLocal: Boolean(isLocal),
                    type,
                    variableIconType: variableIconType ?? undefined,
                }
            }
        }
        if (isLocal && !isNil(id) && !isNil(contentHash) && !isNil(type)) {
            const val = await this.concurrentGenerateLocalLibraryThumbnail(
                id,
                contentHash,
                this.colorProfileService.states.getState().colorProfileState
            )
            return {
                isLocal,
                type,
                width,
                height,
                data: val.buffer ?? undefined,
                effectStyleIconType: undefined,
                layoutGridStyleIconType: undefined,
            }
        }
        return null
    }

    public loadThumbnailDataFromCache = (
        arg: Wukong.DocumentProto.IThumbnailData | null
    ): LibraryThumbnailKeyItem | null => {
        if (arg === null) {
            return null
        }
        const { isLocal, id, contentHash, type, width, height } = arg
        if (isLocal && !isNil(id) && !isNil(contentHash) && !isNil(type)) {
            const val = this.tryGetFromLocalStore(
                id,
                contentHash,
                this.colorProfileService.states.getState().colorProfileState
            )
            if (val === null) {
                return null
            }
            return {
                isLocal,
                type,
                width,
                height,
                data: val.buffer ?? undefined,
                effectStyleIconType: val.effectStyleIconType ?? undefined,
                layoutGridStyleIconType: val.layoutGridIconType ?? undefined,
            }
        }
        return null
    }

    private concurrentGenerateLocalLibraryThumbnail = (
        id: string,
        contentHash: string,
        colorProfile: Wukong.DocumentProto.DocumentColorProfile
    ) => {
        return (
            this.tryGetFromLocalStore(id, contentHash, colorProfile) ??
            this.localLibraryNodeThumbnailConcurrentScheduler.add(() =>
                this.generateLocalLibraryThumbnail(id, contentHash, colorProfile)
            )
        )
    }

    private initBindJsCall = () => {
        this.bindJsCall(GenerateLibraryThumbnail, (arg) => {
            const { id, contentHash } = arg
            if (isNil(id) || isNil(contentHash)) {
                return {
                    buffer: '',
                }
            }
            return this.generateLocalLibraryThumbnail(
                id,
                contentHash,
                this.colorProfileService.states.getState().colorProfileState
            )
        })
    }

    private generateLocalLibraryThumbnail = (
        id: string,
        contentHash: string,
        colorProfile: Wukong.DocumentProto.DocumentColorProfile
    ): Wukong.DocumentProto.IRet_generatePublishMixinThumbnail => {
        const cached = this.tryGetFromLocalStore(id, contentHash, colorProfile)
        if (cached !== null) {
            return cached
        }
        if (!contentHash) {
            return {}
        }
        const res = this.commandInvoker.DEPRECATED_invokeBridge(GeneratePublishMixinThumbnailCommand, {
            id: id,
        })
        const item = {
            contentHash,
            thumbnailData: res.buffer ?? '',
            effectStyleIconType: res.effectStyleIconType ?? undefined,
            layoutGridIconType: res.layoutGridIconType ?? undefined,
            colorProfile,
        }
        this.localStore.set(id, item)
        return res
    }

    private tryGetFromLocalStore = (
        id: string,
        contentHash: string,
        colorProfile: Wukong.DocumentProto.DocumentColorProfile
    ): Wukong.DocumentProto.IRet_generatePublishMixinThumbnail | null => {
        if (!contentHash) {
            return {}
        }
        const cached = this.localStore.get(id)
        if (cached && cached.contentHash === contentHash && cached.colorProfile === colorProfile) {
            return {
                buffer: cached.thumbnailData,
                effectStyleIconType: cached.effectStyleIconType,
                layoutGridIconType: cached.layoutGridStyleIconType,
            }
        }
        return null
    }
}
