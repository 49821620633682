/**
 * @owner: loushuangzhanbj@kanyun.com
 */
import { useEffect, useRef } from 'react'
import style from './arrow.module.less'

const scrollArrowTop = (
    <svg className="svg" width="8" height="8" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M.646 4.647l.708.707L4 2.707l2.646 2.647.708-.707L4 1.293.646 4.647z"
            fillRule="nonzero"
            fillOpacity="1"
            fill="#1B1F26"
            stroke="none"
        ></path>
    </svg>
)

const scrollArrowBottom = (
    <svg className="svg" width="8" height="8" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M.646 3.354l.708-.708L4 5.293l2.646-2.647.708.708L4 6.707.646 3.354z"
            fillRule="nonzero"
            fillOpacity="1"
            fill="#1B1F26"
            stroke="none"
        ></path>
    </svg>
)

export interface ScrollArrowProps {
    hidden?: boolean
    onMouseIn: () => void
    onMouseOut: () => void
}
const useMouseEnterLeave = (params: ScrollArrowProps) => {
    const ref = useRef<HTMLDivElement>(null)
    useEffect(() => {
        const node = ref.current
        if (node) {
            node.addEventListener('mouseenter', params.onMouseIn)
            node.addEventListener('mouseleave', params.onMouseOut)
        }
        return () => {
            if (node) {
                node.removeEventListener('mouseenter', params.onMouseIn)
                node.removeEventListener('mouseleave', params.onMouseOut)
            }
        }
    }, [params.onMouseIn, params.onMouseOut])
    return { ref }
}
export function TopScrollArrow(props: ScrollArrowProps) {
    const { hidden } = props
    const innerStyle: { [key: string]: any } = {}
    if (hidden) {
        innerStyle.display = 'none'
    }
    const { ref } = useMouseEnterLeave(props)
    return (
        <div ref={ref} style={innerStyle} className={`${style.scrollArrow} ${style.scrollArrow_top}`}>
            {scrollArrowTop}
        </div>
    )
}
export function BottomScrollArrow(props: ScrollArrowProps) {
    const { hidden } = props
    const innerStyle: { [key: string]: any } = {}
    if (hidden) {
        innerStyle.display = 'none'
    }
    const { ref } = useMouseEnterLeave(props)

    return (
        <div ref={ref} style={innerStyle} className={`${style.scrollArrow} ${style.scrollArrow_bottom}`}>
            {scrollArrowBottom}
        </div>
    )
}
