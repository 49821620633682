import type { FeatureSwitch } from '@wukong/feature-switch'
import { ColorSpace, ImageFormat, TextureCompressionFormat } from '../types'

export interface ImageLibContext {
    env: {
        isDev: boolean
        wasmDownloadPrefix: string | undefined
    }
    logger: {
        log: (desc: string, logData?: Record<string, string | number | boolean>) => void
        throttleLog: (desc: string, logData: Record<string, string | number | boolean>) => void
    }
    isFeatureEnabled: (featureSwitch: FeatureSwitch) => boolean
}

export interface DecoderArgument {
    buffer: ArrayBuffer
    wasmDownloadPrefix: string | undefined
}

export interface DecoderResult {
    image?: ImageData
}

export interface RemoveICCChunksAndGetMetaArgument {
    source: Uint8Array
}

export interface RemoveICCChunksAndGetMetaResult {
    image: Uint8Array
    isDisplayP3: boolean
    colorProfileRawData: Uint8Array
    format: ImageFormat | null
    hasGamma: boolean
}

export interface ReplaceImageICCToDisplayP3Argument {
    data: Uint8Array
    type: ImageFormat
    wasmDownloadPrefix: string | undefined
}

export interface ReplaceImageICCToDisplayP3Result {
    ret: Uint8Array
}

export const enum MethodType {
    Decode,
    RemoveICCChunksAndGetMeta,
    ReplaceImageICCToDisplayP3,
    EncodeCompressPng,
    EncodeAvif,
    CompressBitmap,
    DecompressBitmap,
    ImageBitmapToBytesUsingOffscreenCanvas,
    TranscodeKtx2,
}

export type ImageLibWorkerArgument =
    | {
          type: MethodType.Decode
          id: number
          arg: DecoderArgument
      }
    | {
          type: MethodType.RemoveICCChunksAndGetMeta
          id: number
          arg: RemoveICCChunksAndGetMetaArgument
      }
    | {
          type: MethodType.ReplaceImageICCToDisplayP3
          id: number
          arg: ReplaceImageICCToDisplayP3Argument
      }
    | {
          type: MethodType.EncodeCompressPng
          id: number
          arg: {
              data: Uint8Array
              width: number
              height: number
              colorProfile: ColorSpace
          }
      }
    | {
          type: MethodType.EncodeAvif
          id: number
          arg: {
              data: Uint8Array
              width: number
              height: number
              colorProfile: ColorSpace
              wasmDownloadPrefix: string | undefined
          }
      }
    | {
          type: MethodType.CompressBitmap
          id: number
          arg: {
              data: Uint8Array
              width: number
              height: number
          }
      }
    | {
          type: MethodType.DecompressBitmap
          id: number
          arg: {
              data: Uint8Array
          }
      }
    | {
          type: MethodType.ImageBitmapToBytesUsingOffscreenCanvas
          id: number
          arg: {
              data: ImageBitmap
          }
      }
    | {
          type: MethodType.TranscodeKtx2
          id: number
          arg: {
              data: Uint8Array
              format: TextureCompressionFormat
              wasmDownloadPrefix: string | undefined
          }
      }

export type ImageLibWorkerResult =
    | {
          type: MethodType.Decode
          id: number
          ret: DecoderResult
      }
    | {
          type: MethodType.RemoveICCChunksAndGetMeta
          id: number
          ret: RemoveICCChunksAndGetMetaResult
      }
    | {
          type: MethodType.ReplaceImageICCToDisplayP3
          id: number
          ret: ReplaceImageICCToDisplayP3Result
      }
    | {
          type: MethodType.EncodeCompressPng
          id: number
          ret: {
              data: Uint8Array
          }
      }
    | {
          type: MethodType.EncodeAvif
          id: number
          ret: {
              data: Uint8Array | null
          }
      }
    | {
          type: MethodType.CompressBitmap
          id: number
          ret: {
              data: Uint8Array | null
          }
      }
    | {
          type: MethodType.DecompressBitmap
          id: number
          ret: {
              data: Uint8Array | null
          }
      }
    | {
          type: MethodType.ImageBitmapToBytesUsingOffscreenCanvas
          id: number
          ret: {
              data: Uint8Array | null
          }
      }
    | {
          type: MethodType.TranscodeKtx2
          id: number
          ret: {
              data: Uint8Array | null
          }
      }
export { ImageFormat }
