import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { Badge } from '../../../../../../ui-lib/src'
import { LibraryTestId } from '../../../../window'
import styles from './library-show-point-container.module.less'

// 有变动则右上角有小蓝点
export function LibraryShowPointContainer(
    props: PropsWithChildren<{
        showPoint: boolean
    }>
) {
    return (
        <span className={classNames(styles.changeBtnContainer)}>
            {props.children}
            <Badge.Dot
                stroke
                style={{ top: -1, right: -3 }}
                isHide={!props.showPoint}
                dataTestIds={{ root: props.showPoint ? LibraryTestId.AssetPanel.UpdateHint : undefined }}
            />
        </span>
    )
}
