import { GET, POST, RequestBody } from '@tutor/network-core'
import { BaseCommonRequest } from '../../../../kernel/request/base-request'

export enum UserDuplicateTeamScope {
    All = 'ALL',
    Part = 'PART',
}

export interface UserDuplicateTeamConfigVO {
    teamId: string
    specificFolderIds: string[]
    scope: UserDuplicateTeamScope
}

export interface UserDuplicateConfigVO {
    orgId: string
    teamConfigList?: UserDuplicateTeamConfigVO[]
}

@GET
export class GetUserDuplicateConfigRequest extends BaseCommonRequest<UserDuplicateConfigVO> {
    constructor(private readonly orgId: string) {
        super()
    }

    override requestUrl(): string {
        return 'ai/duplicate-config'
    }

    override requestArgument() {
        return {
            orgId: this.orgId,
        }
    }
}

interface AiDuplicateConfigChangeResult {
    effectedFileCount: number
}

@POST
export class ModifyUserDuplicateConfigRequest extends BaseCommonRequest<AiDuplicateConfigChangeResult> {
    constructor(private readonly orgId: string, private readonly selectedInfo: UserDuplicateConfigVO) {
        super()
    }

    override requestUrl(): string {
        return 'ai/duplicate-config'
    }

    override requestArgument() {
        return {
            orgId: this.orgId,
        }
    }

    override requestBody(): RequestBody {
        return this.selectedInfo
    }
}

export enum AiDuplicateConfigStatusType {
    Latest = 'LATEST',
    EmptyIndex = 'EMPTY_INDEX',
    DocContentChange = 'DOC_CONTENT_CHANGED',
}

export interface AiDuplicateConfigStatus {
    status: AiDuplicateConfigStatusType
    count: number
}

@GET
export class GetAiDuplicateConfigStatusRequest extends BaseCommonRequest<AiDuplicateConfigStatus> {
    constructor(private readonly orgId: string) {
        super()
    }

    override requestUrl(): string {
        return 'ai/duplicate-config/status'
    }

    override requestArgument(): { [key: string]: string | number } | undefined {
        return {
            orgId: this.orgId,
        }
    }
}
