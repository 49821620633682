import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    LastModified: '最后修改：',
    Owner: '所有者：',
    TotalResult: '共 {{count}} 个搜索结果',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    LastModified: 'Last modified: ',
    Owner: 'Owner: ',
    TotalResult: '{{count}} results',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
