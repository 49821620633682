import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    UnableToLeave: '无法退出文件',
    ThisFileHas: '当前文件中存在没有保存的修改。{{name}}正在保存这些内容，请稍后重试。',
    OK: '我知道了',
    BackToFiles: '返回',
} as const

export const enTranslation = {
    UnableToLeave: 'Unable to leave document',
    ThisFileHas:
        'There are unsaved changes in the current file. Your offline changes will be synced with {{name}} once you are back online.',
    OK: 'OK',
    BackToFiles: 'Back to files',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
