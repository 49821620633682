import { GET } from '@tutor/network-core'
import { environment } from '../../environment'
import { AppVersionResponse } from '../interface/app-version'
import { BaseRequest } from './base-request'

@GET
export class GetAppVersion extends BaseRequest<AppVersionResponse> {
    constructor(private productId: number, private version: string, private platform: string) {
        super()
    }
    public override requestUrl() {
        const isTestingOrDev = !environment.isProduction
        const hostName = environment.isAbroad
            ? isTestingOrDev
                ? 'ke.cretaclass.biz'
                : 'ke.cretaclass.com'
            : isTestingOrDev
            ? 'ke.yuanfudao.biz'
            : 'www.yuanfudao.com'
        return `https://${hostName}/tutor-app-version/win/app-versions/current?_productId=${this.productId}&_hostProductId=${this.productId}&version=${this.version}&platform=${this.platform}`
    }
    public override requestDidFailed(): boolean {
        return false
    }
}
