import { translation } from './text-style-list-v2.translation'
/* eslint-disable no-restricted-imports */
import { IconAdjust, Tooltip } from '../../../../../../ui-lib/src'
import classNames from 'classnames'
import { useMemo, useRef } from 'react'
import { Wukong } from '@wukong/bridge-proto'
import { DeepRequired } from '../../../../view-state-bridge'
import { LocalStyleTestId } from '../../../../window'
import { IconButton } from '../../atom/button/icon-button'
import { collapse, expand } from '../../inspect/assets/inspect-svg'
import { RightClickMenuWrapper } from '../../right-click-menu-wrapper'
import { LocalTextStyleLibraryThumbnailImage } from '../styles/style-text/style-thumbnail'
import { DragContextProvider } from './drag/drag-context'
import { DragItem } from './drag/drag-item'
import { useStyleFolderCollapseModel } from './hooks/use-doc-style-folder-collapse'
import { LocalStyleModel } from './hooks/use-local-style-model-v2'
import { useStyleListItem } from './hooks/use-style-list-item-v2'
import { StyleName } from './style-name'
import { StyleTitle, StyleTitleProps } from './style-title'
import style from './style.module.less'

const LOCAL_STYLE_SELECTION_TYPE_TEXT = Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_TEXT
interface Props {
    model: LocalStyleModel
    hover: string | undefined
    setHover: (value: string) => void
    isReadonly?: boolean
    onChickAddIcon: StyleTitleProps['onChickAddIcon']
}

interface TextStyleListItemProps {
    item: DeepRequired<Wukong.DocumentProto.ILocalTextStyleItem>
    itemIndex: number
    model: LocalStyleModel
    hover: string | undefined
    setHover: (value: string) => void
    isReadonly?: boolean
    isFolderCollapse: boolean
    toggleFolderCollapse: (folderName: string) => void
}

const TextStyleListItem = (props: TextStyleListItemProps) => {
    const {
        item,
        itemIndex,
        model: { modelCommand, modelState, relatedSelectionItems, isEditingName },
        hover,
        setHover,
        isReadonly,
        isFolderCollapse,
        toggleFolderCollapse,
    } = props

    const {
        itemKey,
        handleMouseDown,
        handleMouseUp,
        itemClassNames,
        isHover,
        isEditingItemName,
        rowRightStyle,
        isOpenPopup,
    } = useStyleListItem({
        type: LOCAL_STYLE_SELECTION_TYPE_TEXT,
        itemKeyType: 'text',
        item,
        itemList: modelState?.textStyleItems ?? [],
        relatedSelectionItems: relatedSelectionItems.text,
        model: props.model,
        hover,
        style,
        isReadonly,
    })

    return (
        <RightClickMenuWrapper
            source={Wukong.DocumentProto.RightClickSource.RIGHT_CLICK_SOURCE_DESIGN_PANEL}
            stopPropagation
        >
            <DragItem itemIndex={itemIndex} isFolderCollapse={isFolderCollapse}>
                <div
                    data-style-id={itemKey}
                    data-testid={LocalStyleTestId.TextStyleListItem + itemIndex}
                    className={classNames(...itemClassNames)}
                    onMouseEnter={(): void => setHover(itemKey)}
                    onMouseLeave={(): void => setHover('')}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                >
                    {<div style={{ flex: 1 }}></div>}
                    <div className={style.row_right} style={rowRightStyle}>
                        {item.isFolder ? (
                            <div
                                className={style.svg_container}
                                onMouseDown={(): void => toggleFolderCollapse(item.folderName)}
                            >
                                {isFolderCollapse ? collapse : expand}
                            </div>
                        ) : (
                            <LocalTextStyleLibraryThumbnailImage
                                className={style.paint_preview_text}
                                id={item.styleNode.id}
                                contentHash={item.styleNode.contentHash}
                                fontName={item.styleNode.fontName}
                                missFontInfo={item.styleNode.missFontInfo}
                            />
                        )}

                        <StyleName
                            textStyleNode={item.isFolder ? undefined : item.styleNode}
                            isEdit={isEditingItemName}
                            fullName={item.isFolder ? item.folderName : item.styleNode.name}
                            startEditName={() => {
                                modelCommand.updateEditName(item)
                            }}
                            endEditName={() => modelCommand.clearEditName()}
                            rename={(newName) => modelCommand.renameLocalStyleItem(item, newName)}
                            isReadonly={isReadonly}
                        />
                        {!item.isFolder && !isEditingName && (isHover || isOpenPopup) && (
                            <span className={style.hover_icon}>
                                <Tooltip title={translation('EditStyle')}>
                                    <IconButton
                                        icon={<IconAdjust />}
                                        selected={isOpenPopup}
                                        deepColor
                                        onClick={() =>
                                            modelCommand.updateEditStyle({
                                                type: Wukong.DocumentProto.LocalStyleSelectionType
                                                    .LOCAL_STYLE_SELECTION_TYPE_TEXT,
                                                editingStyleId: item.styleId,
                                            })
                                        }
                                        dataTestId={LocalStyleTestId.TextStyleListItem + itemIndex + '-edit-icon'}
                                    />
                                </Tooltip>
                            </span>
                        )}
                    </div>
                </div>
            </DragItem>
        </RightClickMenuWrapper>
    )
}

const TextStyleListInternal = ({ model, hover, setHover, isReadonly, onChickAddIcon }: Props) => {
    const { modelState, selection } = model
    const containerRef = useRef<HTMLDivElement>(null)

    const textStyleItems = useMemo(() => modelState?.textStyleItems ?? [], [modelState?.textStyleItems])
    const folderCollapseModel = useStyleFolderCollapseModel('text', textStyleItems)
    const renderItemList = useMemo(() => {
        return textStyleItems.filter((item) => {
            return folderCollapseModel.isItemVisible(item)
        })
    }, [folderCollapseModel, textStyleItems])

    const list = renderItemList.map((item, index) => {
        return (
            <TextStyleListItem
                key={index}
                item={item}
                itemIndex={index}
                model={model}
                hover={hover}
                setHover={setHover}
                isReadonly={isReadonly}
                isFolderCollapse={item.isFolder && folderCollapseModel.isFolderCollapse(item.folderName)}
                toggleFolderCollapse={folderCollapseModel.toggleFolderCollapse}
            ></TextStyleListItem>
        )
    })

    if (!modelState) {
        return null
    }

    return (
        <>
            {modelState.textStyleItems.length > 0 ? (
                <StyleTitle
                    title={translation('TextStyles')}
                    tooltipTitle={translation('CreateNewText')}
                    onChickAddIcon={onChickAddIcon}
                    isReadonly={!!isReadonly}
                ></StyleTitle>
            ) : null}
            <div ref={containerRef} data-testid="text-style-container">
                <DragContextProvider
                    containerRef={containerRef}
                    renderList={renderItemList}
                    selection={selection}
                    handleToggleFolderCollapse={folderCollapseModel.toggleFolderCollapse}
                >
                    {list}
                </DragContextProvider>
            </div>
        </>
    )
}

export const TextStyleList = (props: Props) => {
    const { modelState } = props.model

    if (modelState?.textStyleItems?.length) {
        return <TextStyleListInternal {...props} />
    }
    return null
}
