import { ResourceType, SeatType, TriggerSeatApplicationSceneType, UserID } from '../../kernel/interface/type'
import { featureSwitchManager } from '../../kernel/switch'
import { FlatDoc } from '../../space/util/types'
import { getFolderIdOfMoveDocs, UserRole, UserSeatStatus } from '../../utils/payment'
import { PayApplyDialog } from './pay-apply-dialog/pay-apply-dialog'
import {
    PayApplyDialogSeatType,
    PayApplyDialogType,
    PayApplyDialogUserType,
} from './pay-apply-dialog/pay-apply-dialog-struct/type'
import { translation } from './pay-seat-apply-move-doc.translation'
import { getAdminUpgradeSelfDescription } from './util'

export function PayApplySeatOfMoveDocsPromise(props: {
    userRole: UserRole
    userSeatStatus: UserSeatStatus
    docs: FlatDoc[]
    targetTeamId: string
    targetFolderId: string
    userId: UserID
    unresolvedSeatTypes: SeatType[]
    isEnterprise: boolean
    isSomeWorkspaceAdmin: boolean
    onCancel: () => void
    onOk: () => void
}) {
    return new Promise<void>((resolve, reject) => {
        PayApplySeatOfMoveDocs({ ...props, resolve, reject })
    })
}

async function PayApplySeatOfMoveDocs(props: {
    userRole: UserRole
    userSeatStatus: UserSeatStatus
    docs: FlatDoc[]
    targetTeamId: string
    targetFolderId: string
    userId: UserID
    unresolvedSeatTypes: SeatType[]
    isEnterprise: boolean
    isSomeWorkspaceAdmin: boolean
    onCancel: () => void
    onOk: () => void
    resolve: (value: void | PromiseLike<void>) => void
    reject: (reason?: any) => void
}) {
    const {
        userRole,
        userSeatStatus,
        docs,
        targetTeamId,
        targetFolderId,
        userId,
        unresolvedSeatTypes,
        isEnterprise,
        isSomeWorkspaceAdmin,
        onCancel,
        onOk,
        resolve,
        reject,
    } = props

    const httpProps = {
        resourceType: docs[0].orgId == '-1' ? ResourceType.Team : ResourceType.Organization,
        resourceId: docs[0].orgId == '-1' ? targetTeamId : docs[0].orgId,
        triggerScene: {
            scene:
                docs.length == 1
                    ? TriggerSeatApplicationSceneType.MoveDoc
                    : TriggerSeatApplicationSceneType.BatchMoveDoc,
            fromFolderId: getFolderIdOfMoveDocs(docs),
            toFolderId: targetFolderId,
            docId: docs.length == 1 ? docs[0].id : '',
            userId: userId,
        },
        orgId: docs[0].orgId,
    }

    if (userRole == UserRole.Admin || (featureSwitchManager.isEnabled('organization-plus') && isSomeWorkspaceAdmin)) {
        PayApplyDialog.show({
            type: PayApplyDialogType.Admin,
            seatType: PayApplyDialogSeatType.Design,
            dialogProps: {
                title:
                    httpProps.resourceType == ResourceType.Team
                        ? translation('DialogTitleOfAdminOrAutoGradeInTeam')
                        : translation('DialogTitleOfAdminOrAutoGradeInOrg'),
                okText: translation('DialogOkTextOfAdminOrAutoGrade'),
                cancelText: translation('DialogCancelTextOfAdminOrAutoGrade'),
                onOk: async () => {
                    onOk()
                    resolve()
                },
                onCancel: async () => {
                    onCancel()
                    reject('pay-seat-apply-move-doc')
                },
            },
            httpProps: httpProps,
            description: getAdminUpgradeSelfDescription('design'),
        })
        return
    }

    const userInfo = {
        type: userRole == UserRole.Guest ? PayApplyDialogUserType.External : PayApplyDialogUserType.Normal,
    }

    switch (userSeatStatus) {
        case UserSeatStatus.CanApplyAutoUpgrade: {
            PayApplyDialog.show({
                type: PayApplyDialogType.AutoUpgrade,
                seatType: PayApplyDialogSeatType.Design,
                dialogProps: {
                    title:
                        httpProps.resourceType == ResourceType.Team
                            ? translation('DialogTitleOfAdminOrAutoGradeInTeam')
                            : translation('DialogTitleOfAdminOrAutoGradeInOrg'),
                    okText: translation('DialogOkTextOfAdminOrAutoGrade'),
                    cancelText: translation('DialogCancelTextOfAdminOrAutoGrade'),
                    onOk: async () => {
                        onOk()
                        resolve()
                    },
                    onCancel: async () => {
                        onCancel()
                        reject('pay-seat-apply-move-doc')
                    },
                },
                httpProps: httpProps,
                description: undefined,
            })
            break
        }
        case UserSeatStatus.CanApplyGracePeriod:
        case UserSeatStatus.NeedWaitToBeApproved:
        case UserSeatStatus.NeedReApply: {
            PayApplyDialog.show({
                type: PayApplyDialogType.Normal,
                isAllowGracePeriod: userSeatStatus == UserSeatStatus.CanApplyGracePeriod,
                isEnterprise: isEnterprise,
                userInfo: userInfo,
                seatType: PayApplyDialogSeatType.Design,
                dialogProps: {
                    onOk: async () => {
                        onOk()
                        userSeatStatus == UserSeatStatus.CanApplyGracePeriod
                            ? resolve()
                            : reject('pay-seat-apply-move-doc')
                    },
                    onCancel: async () => {
                        onCancel()
                        reject('pay-seat-apply-move-doc')
                    },
                },
                httpProps: httpProps,
                descriptionPrefix:
                    httpProps.resourceType == ResourceType.Team
                        ? translation('DialogDescriptionPrefixInTeam')
                        : translation('DialogDescriptionPrefixInOrg'),
                unresolvedSeatTypes: unresolvedSeatTypes,
                repeatDialogProps: {
                    dialogTitle: translation('DialogTitleOfNeedWaitToApproved'),
                },
            })
            break
        }
        case UserSeatStatus.InGracePeriod:
        case UserSeatStatus.InAutoUpgrade:
        case UserSeatStatus.InPermanentSeat:
            return
    }
}
