import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    TPostYour: '添加评论失败',
    Retry: '重试',
} as const

export const enTranslation = {
    TPostYour: "Couldn't post your comment",
    Retry: 'Retry',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
