import { useCallback } from 'react'
import { FormattedInput, FormattedInputProps } from './components/formatted-input'

interface HexInputProps extends Omit<FormattedInputProps<string>, 'formatter' | 'divertKeyboardEvent'> {
    upperCase?: boolean
    lowerCase?: boolean
}
function HexInput(props: HexInputProps) {
    const { upperCase, lowerCase, ...otherProps } = props

    const format = useCallback(
        (value: string) => {
            if (upperCase) {
                return String(value).toUpperCase()
            } else if (lowerCase) {
                return String(value).toLowerCase()
            } else {
                return String(value).toUpperCase()
            }
        },
        [lowerCase, upperCase]
    )

    const parse = useCallback((value: string) => {
        value = String(value).replace(/^#/, '')
        const isInvalid = /[^0-9,a-f,A-F]/g.test(value)
        if (isInvalid) {
            throw new Error('无效输入')
        }
        if (value.length === 1) {
            value = value.repeat(6)
        } else if (value.length == 2) {
            value = value.repeat(3)
        } else if (value.length === 3) {
            value = value.replace(/./g, '$&$&')
        } else if (value.length !== 6) {
            throw new Error('无效输入')
        }
        return value
    }, [])

    const emptyIncrement = (v: string) => v

    return <FormattedInput formatter={{ parse, format, increment: emptyIncrement }} {...otherProps} />
}

export { HexInput }
