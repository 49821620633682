import { WKFrog } from '../../../kernel/frog'

/* eslint-disable no-restricted-imports */
let changeAvatarStartTime: number = Date.now()

export const AccountManage = {
    accountManage() {
        WKFrog.addFrogRecord({
            url: '/click/AccountManage/accountManage',
            eventId: 22658,
            eventName: 'accountManage',
            eventAction: 'click',
        })
    },
    changeAvatar() {
        WKFrog.addFrogRecord({
            url: '/click/AccountManage/changeAvatar',
            eventId: 22659,
            eventName: 'changeAvatar',
            eventAction: 'click',
        })
    },
    selectAvatarPicture() {
        WKFrog.addFrogRecord({
            url: '/event/AccountManage/selectAvatarPicture',
            eventId: 22660,
            eventName: 'selectAvatarPicture',
            eventAction: 'event',
        })
    },
    reuploadAvatar() {
        WKFrog.addFrogRecord({
            url: '/click/AccountManage/reuploadAvatar',
            eventId: 22661,
            eventName: 'reuploadAvatar',
            eventAction: 'click',
        })
    },
    confirmAvatar() {
        changeAvatarStartTime = Date.now()
        WKFrog.addFrogRecord({
            url: '/click/AccountManage/confirmAvatar',
            eventId: 22662,
            eventName: 'confirmAvatar',
            eventAction: 'click',
        })
    },
    uploadAvatarSuccess() {
        const duration = Date.now() - changeAvatarStartTime
        WKFrog.addFrogRecord({
            url: '/event/AccountManage/uploadAvatarSuccess',
            eventId: 22663,
            eventName: 'uploadAvatarSuccess',
            eventAction: 'event',
            duration,
            customExtend: { duration },
        })
    },
    changeNameSuccess() {
        WKFrog.addFrogRecord({
            url: '/event/AccountManage/changeNameSuccess',
            eventId: 22664,
            eventName: 'changeNameSuccess',
            eventAction: 'event',
        })
    },
    changePassword() {
        WKFrog.addFrogRecord({
            url: '/click/AccountManage/changePassword',
            eventId: 22665,
            eventName: 'changePassword',
            eventAction: 'click',
        })
    },
    changePasswordSuccess() {
        WKFrog.addFrogRecord({
            url: '/event/AccountManage/changePasswordSuccess',
            eventId: 22802,
            eventName: 'changePasswordSuccess',
            eventAction: 'event',
        })
    },
    changePasswordInAccountManage() {
        WKFrog.addFrogRecord({
            url: '/click/AccountManage/changePasswordInAccountManage',
            eventId: 22666,
            eventName: 'changePasswordInAccountManage',
            eventAction: 'click',
        })
    },
    resetPasswordSuccess() {
        WKFrog.addFrogRecord({
            url: '/event/AccountManage/resetPasswordSuccess',
            eventId: 22667,
            eventName: 'resetPasswordSuccess',
            eventAction: 'event',
        })
    },
}
