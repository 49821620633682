import { WKTourStep } from '../../../../../ui-lib/src/components/wk-tour'
import { getMotiffName, isEnglishLanguage } from '../../../../../util/src'
import { environment } from '../../../environment'
import { OnboardingScene } from '../../../kernel/interface/onboarding'
import { onboardingService } from '../../service/onboarding-service'
import exportEnImage from './assets/export-en.webp'
import exportImage from './assets/export.webp'
import inspectEnImage from './assets/inspect-en.webp'
import inspectImage from './assets/inspect.webp'
import startEnImage from './assets/start-en.webp'
import startImage from './assets/start.webp'
import { translation } from './config.translation'

export const devModeTourSteps: WKTourStep[] = [
    {
        targets: () => [document.getElementById('onboarding-position')],
        image: isEnglishLanguage() ? startEnImage : startImage,
        video: isEnglishLanguage()
            ? `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/onboarding/en/devmode-start.mp4`
            : `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/onboarding/devmode-start.mp4`,
        title: translation('Start'),
        description: (
            <>
                <p>{translation('StartContent1')}</p>
            </>
        ),
        moreButton: {
            show: true,
            onClick: () => {
                onboardingService.packingFrog(OnboardingScene.DevMode, 'more')
                window.open(environment.onboardingHelpDocUrl.devModeStart)
            },
        },
    },
    {
        targets: () => [document.getElementById('onboarding-position')],
        image: isEnglishLanguage() ? inspectEnImage : inspectImage,
        video: isEnglishLanguage()
            ? `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/onboarding/en/devmode-inspect-2.mp4`
            : `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/onboarding/devmode-inspect-2.mp4`,
        title: translation('Inspect'),
        description: (
            <>
                <p>{translation('InspectContent1')}</p>
            </>
        ),
        moreButton: {
            show: true,
            onClick: () => {
                onboardingService.packingFrog(OnboardingScene.DevMode, 'more')
                window.open(environment.onboardingHelpDocUrl.devmodeInspect)
            },
        },
    },
    {
        targets: () => [document.getElementById('onboarding-position')],
        image: isEnglishLanguage() ? exportEnImage : exportImage,
        video: isEnglishLanguage()
            ? `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/onboarding/en/devmode-export.mp4`
            : `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/onboarding/devmode-export.mp4`,
        title: translation('Export'),
        description: (
            <>
                <p>{translation('ExportContent1', { name: getMotiffName() })}</p>
                {translation('ExportContent2') ? <p>{translation('ExportContent2')}</p> : null}
            </>
        ),
        moreButton: {
            show: true,
            onClick: () => {
                onboardingService.packingFrog(OnboardingScene.DevMode, 'more')
                window.open(environment.onboardingHelpDocUrl.devmodeExport)
            },
        },
    },
]
