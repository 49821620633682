/**
 * @owner: loushuangzhanbj@kanyun.com
 */
import { DropdownArrowEmpty } from './dropdown/dropdown-arrow-empty'
import { DropdownArrowMultiLevel } from './dropdown/dropdown-arrow-multi-level'
import { DropdownArrowSingleLevel } from './dropdown/dropdown-arrow-single-level'
import { DropdownEmptySingleLevel } from './dropdown/dropdown-empty-single-level'
import { DropdownIconEmpty } from './dropdown/dropdown-icon-empty'
import { DropdownIconMultiLevel } from './dropdown/dropdown-icon-multi-level'
import { DropdownIconSingleLevel } from './dropdown/dropdown-icon-single-level'
import { DropdownMenuEmpty } from './dropdown/dropdown-menu-empty'
import { DropdownMenuMultiLevel } from './dropdown/dropdown-menu-multi-level'
import { DropdownMenuSingleLevel } from './dropdown/dropdown-menu-single-level'
import { DropdownMinimalEmpty } from './dropdown/dropdown-minimal-empty'
import { DropdownMinimalMultiLevel } from './dropdown/dropdown-minimal-multi-level'
import { DropdownMinimalSingleLevel } from './dropdown/dropdown-minimal-single-level'
import { DropdownNoTriggerEmpty } from './dropdown/dropdown-no-trigger-empty'

import { DropdownNoTriggerMultiLevel } from './dropdown/dropdown-no-trigger-multi-level'
import { DropdownNoTriggerSingleLevel } from './dropdown/dropdown-no-trigger-single-level'

export { DropdownDefaultRenderItem } from './dropdown/dropdown-default-custom-render'
export type { DropdownDefaultCustomNode } from './dropdown/dropdown-default-custom-render'
export type { DropdownIconEmptyProps, DropdownIconEmptyRef } from './dropdown/dropdown-icon-empty'
export type { DropdownIconMultiLevelProps, DropdownIconMultiLevelRef } from './dropdown/dropdown-icon-multi-level'
export type { DropdownIconSingleLevelProps, DropdownIconSingleLevelRef } from './dropdown/dropdown-icon-single-level'
export type { DropdownMenuEmptyProps, DropdownMenuEmptyRef } from './dropdown/dropdown-menu-empty'
export type { DropdownMenuMultiLevelProps, DropdownMenuMultiLevelRef } from './dropdown/dropdown-menu-multi-level'
export type { DropdownMenuSingleLevelProps, DropdownMenuSingleLevelRef } from './dropdown/dropdown-menu-single-level'
export type { DropdownMinimalEmptyProps, DropdownMinimalEmptyRef } from './dropdown/dropdown-minimal-empty'
export type {
    DropdownMinimalMultiLevelProps,
    DropdownMinimalMultiLevelRef,
} from './dropdown/dropdown-minimal-multi-level'
export type {
    DropdownMinimalSingleLevelProps,
    DropdownMinimalSingleLevelRef,
} from './dropdown/dropdown-minimal-single-level'
export type { DropdownNoTriggerEmptyProps, DropdownNoTriggerEmptyRef } from './dropdown/dropdown-no-trigger-empty'
export type {
    DropdownNoTriggerMultiLevelProps,
    DropdownNoTriggerMultiLevelRef,
} from './dropdown/dropdown-no-trigger-multi-level'
export type {
    DropdownNoTriggerSingleLevelProps,
    DropdownNoTriggerSingleLevelRef,
} from './dropdown/dropdown-no-trigger-single-level'
export * from './dropdown/type'
export { getOptionMinWidth } from './picks/option-width'

export const DropdownV2 = {
    IconEmpty: DropdownIconEmpty,
    IconMultiLevel: DropdownIconMultiLevel,
    IconSingleLevel: DropdownIconSingleLevel,
    MenuEmpty: DropdownMenuEmpty,
    MenuMultiLevel: DropdownMenuMultiLevel,
    MenuSingleLevel: DropdownMenuSingleLevel,
    MinimalEmpty: DropdownMinimalEmpty,
    MinimalMultiLevel: DropdownMinimalMultiLevel,
    MinimalSingleLevel: DropdownMinimalSingleLevel,
    ArrowEmpty: DropdownArrowEmpty,
    ArrowMultiLevel: DropdownArrowMultiLevel,
    ArrowSingleLevel: DropdownArrowSingleLevel,
    NoTriggerMultiLevel: DropdownNoTriggerMultiLevel,
    NoTriggerSingleLevel: DropdownNoTriggerSingleLevel,
    NoTriggerEmpty: DropdownNoTriggerEmpty,
    EmptySingleLevel: DropdownEmptySingleLevel,
}
