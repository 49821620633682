/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { useMemo } from 'react'
import { FontName } from '../../../document/node/node'
import { useViewState } from '../../../view-state-bridge'

export function useFontLoaded(props?: FontName | Wukong.DocumentProto.IFontName) {
    const availableFonts = useViewState('availableFonts')?.availableFonts

    const isFontLoaded = useMemo(() => {
        if (!availableFonts || !props) {
            return false
        }
        return availableFonts.some((font) => font.family === props.family && font.style === props.style)
    }, [availableFonts, props])

    return { isFontLoaded }
}
