import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { MonoIconCommonEnterRight16 } from '../../../../../../../../ui-lib/src'
import { useLibraryComponentService } from '../../../../../../main/app-context'
import { LibraryTestId } from '../../../../../../window'
import { LibraryName } from '../../../library-name/library-name'
import classes from './preview-library-header-item.module.less'

export function PreviewLibraryHeaderItem(props: {
    header: Wukong.DocumentProto.IVLibraryAssetPanelHeaderItem | null | undefined
}) {
    const { libraryAssetPanelExpandService } = useLibraryComponentService()

    const onToggleCollapse = () => {
        libraryAssetPanelExpandService.changeExpandStatus(props.header?.expandKey, !props.header?.expand)
    }

    return props.header ? (
        <div
            className={classnames(classes.container, { [classes.expanded]: props.header?.expand })}
            onClick={onToggleCollapse}
            style={{ height: props.header?.height ?? 0, top: props.header?.top ?? 0 }}
        >
            <MonoIconCommonEnterRight16 className={classes.arrow} />
            <LibraryName
                libraryType={props.header?.libraryType}
                name={props.header?.name}
                className={classes.title}
                dataTestIdPrefix={LibraryTestId.AssetPanel.HeaderItemPrefix}
            />
        </div>
    ) : (
        <></>
    )
}
