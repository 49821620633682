export interface HeaderValue {
    [key: string]: string
}

export class Header {
    // 缓存对象
    private _header: HeaderValue = {}
    /**
     * 增加一个属性
     * @param key
     * @param value
     */
    public add(key: string, value: string) {
        this._header[key] = value
    }
    /**
     * 移除指定属性
     * @param key
     */
    public remove(key: string) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete this._header[key]
    }
    /**
     * 整体赋值header
     * @param header
     */
    public set(header: HeaderValue) {
        this._header = header
    }
    /**
     * 获取header属性，不指定key时返回全部
     * @param key
     */
    public get(key?: string) {
        if (key) {
            return this._header[key]
        }
        return this._header
    }
}
