export interface RGB {
    r: number //[0-255]
    g: number //[0-255]
    b: number //[0-255]
}
export enum DrawMode {
    HSL = 'HSL',
    HSB = 'HSB',
}

export enum ChangeColorType {
    R,
    G,
    B,
    Hex,
    CSS,
    LH,
    LS,
    LL,
    BH,
    BS,
    BB,
    RangeHue,
    RangeHV,
}
