import { SVGAttributes } from 'react'

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconEyes = () => (
    <svg
        stroke="none"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="icon-eyes"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99999 11C5.78054 11 3.84084 9.79496 2.80242 7.99994C3.84086 6.20498 5.78053 5 7.99992 5C10.2194 5 12.1591 6.20504 13.1975 8.00006C12.159 9.79502 10.2194 11 7.99999 11ZM7.99992 4C10.7923 4 13.2029 5.63509 14.3263 8.00007C13.2028 10.365 10.7924 12 7.99999 12C5.20756 12 2.79703 10.3649 1.67358 7.99993C2.79706 5.63502 5.20755 4 7.99992 4ZM8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
            fill="currentColor"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconEyesClose = () => (
    <svg
        stroke="none"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="icon-eyes-close"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.20102 7.23206C2.48961 6.60974 1.91852 5.85063 1.5365 5H2.65479C3.63754 6.75897 5.64195 8 8.00002 8C10.3581 8 12.3625 6.75896 13.3452 5H14.4635C14.1086 5.79033 13.5904 6.50165 12.9482 7.09766L14.15 8.70012L13.35 9.30012L12.1673 7.72316C11.5399 8.15545 10.832 8.4926 10.0682 8.71162L10.4851 10.3789L9.51499 10.6214L9.08997 8.92167C8.73479 8.97324 8.37077 9 8.00002 9C7.62924 9 7.2652 8.97323 6.91002 8.92167L6.48507 10.6216L5.51492 10.3791L5.93176 8.71161C5.23482 8.51175 4.58438 8.21354 3.99924 7.83443L2.9 9.30009L2.1 8.70009L3.20102 7.23206Z"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconBatchExport = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
            transform="matrix(1 0 0 1 1 1)"
            d="M1 1L12 1L12 0L1 0Q0.585815 0 0.292847 0.292969C0.0976562 0.488037 0 0.723877 0 1L0 12L1 12L1 1ZM3 2C2.44775 2 2 2.44775 2 3L2 13C2 13.5522 2.44775 14 3 14L13 14C13.5522 14 14 13.5522 14 13L14 3C14 2.44775 13.5522 2 13 2L3 2ZM3 3L3 13L13 13L13 3L3 3ZM8.5 9.29297L8.5 5L7.5 5L7.5 9.29297L6.35364 8.14648L5.64648 8.85352L8 11.207L10.3536 8.85352L9.64648 8.14648L8.5 9.29297Z"
            fillRule="evenodd"
            fill="rgb(26, 29, 37)"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconLock = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" stroke="none" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.5 5.5V7H6.5V5.5C6.5 4.67157 7.17157 4 8 4C8.82843 4 9.5 4.67157 9.5 5.5ZM5.5 7V5.5C5.5 4.11929 6.61929 3 8 3C9.38071 3 10.5 4.11929 10.5 5.5V7H11C11.2761 7 11.5 7.22386 11.5 7.5V12.5C11.5 12.7761 11.2761 13 11 13H5C4.72386 13 4.5 12.7761 4.5 12.5V7.5C4.5 7.22386 4.72386 7 5 7H5.5Z"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconUnLock = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" stroke="none" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 6V7H10.5C10.7761 7 11 7.22386 11 7.5V12.5C11 12.7761 10.7761 13 10.5 13H4.5C4.22386 13 4 12.7761 4 12.5V7.5C4 7.22386 4.22386 7 4.5 7H8V5.5C8 4.11929 9.11929 3 10.5 3C11.8807 3 13 4.11929 13 5.5V6H12V5.5C12 4.67157 11.3284 4 10.5 4C9.67157 4 9 4.67157 9 5.5V6Z"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconArrowDown = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.64648 5.85359L3.35359 5.14648L8.00005 9.79293L12.6465 5.14649L13.3536 5.85359L8.00005 11.2071L2.64648 5.85359Z"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconArrowUp = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.64648 10.1464L3.35359 10.8535L8.00005 6.20707L12.6465 10.8535L13.3536 10.1464L8.00005 4.79286L2.64648 10.1464Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconAdd16 = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5 8.5V14H8.5V8.5H14V7.5H8.5V2H7.5V7.5H2V8.5H7.5Z"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconDelete16 = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 7.5H14V8.5H2V7.5Z" fill="currentColor" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconChecked = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            d="M12.7072 5.70706L7.00008 11.4142L3.29297 7.70706L4.70718 6.29285L7.00008 8.58574L11.293 4.29285L12.7072 5.70706Z"
            fillRule="evenodd"
            fill="rgb(26, 29, 37)"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconCheckboxChecked = (props: SVGAttributes<SVGSVGElement>) => (
    <svg
        className="svg-checkbox-checked"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.7072 5.70706L7.00008 11.4142L3.29297 7.70706L4.70718 6.29285L7.00008 8.58574L11.293 4.29285L12.7072 5.70706Z"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconCheckboxMixed = (props: SVGAttributes<SVGSVGElement>) => (
    <svg
        className="svg-checkbox-mixed"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <line x1="4" y1="8" x2="12" y2="8" stroke="#1B1F26" strokeWidth="2" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconSearch = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 7C11 9.20914 9.20914 11 7 11C4.79086 11 3 9.20914 3 7C3 4.79086 4.79086 3 7 3C9.20914 3 11 4.79086 11 7ZM10.1644 10.8714C9.30247 11.5768 8.20065 12 7 12C4.23858 12 2 9.76142 2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7C12 8.20062 11.5768 9.3024 10.8715 10.1643L13.8223 13.1152L13.1152 13.8223L10.1644 10.8714Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconSketchIcon = (props: SVGAttributes<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        {...props}
    >
        <path
            d="M3 0C1.34315 0 0 1.34315 0 3L0 13C0 14.6569 1.34315 16 3 16L13 16C14.6569 16 16 14.6569 16 13L16 3C16 1.34315 14.6569 0 13 0L3 0Z"
            fillRule="evenodd"
            fill="rgb(255, 255, 255)"
            fillOpacity="1"
        />
        <path
            d="M8.00002 13.9547L1.69995 6.60819L4.42286 2.88807L8.00002 2.5L11.5772 2.88807L14.3001 6.60819L8.00002 13.9547Z"
            fillRule="evenodd"
            fill="rgb(255, 174, 0)"
            fillOpacity="1"
        />
        <path
            d="M8.00002 13.9533L1.69995 6.60674L14.3001 6.60674L8.00002 13.9533Z"
            fillRule="evenodd"
            fill="rgb(236, 108, 0)"
            fillOpacity="1"
        />
        <path
            d="M7.99876 13.9533L4.24808 6.60674L11.7494 6.60674L7.99876 13.9533Z"
            fillRule="evenodd"
            fill="rgb(255, 174, 0)"
            fillOpacity="1"
        />
        <path
            d="M7.99876 2.5L4.24808 6.60819L11.7494 6.60819L7.99876 2.5Z"
            fillRule="evenodd"
            fill="rgb(255, 239, 180)"
            fillOpacity="1"
        />
        <path
            d="M4.42286 2.88872L3.02136 4.76216L1.69995 6.60884L4.27604 6.60884L4.42286 2.88872ZM11.5772 2.88872L12.9787 4.76216L14.3001 6.60884L11.724 6.60884L11.5772 2.88872Z"
            fillRule="evenodd"
            fill="rgb(255, 174, 0)"
            fillOpacity="1"
        />
        <path
            d="M4.4216 2.88807L4.24808 6.60819L7.99876 2.5L4.4216 2.88807ZM11.5759 2.88807L11.7494 6.60819L7.99876 2.5L11.5759 2.88807Z"
            fillRule="evenodd"
            fill="rgb(254, 211, 5)"
            fillOpacity="1"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconEnter = (props: SVGAttributes<SVGSVGElement>) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path d="M6 11L10.5 8L6 5L6 11Z" fillRule="evenodd" fill="currentColor" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconFavorite = (props: SVGAttributes<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
        <path
            d="M10.3496 5.2644L8.44686 1.40894C8.26345 1.03711 7.73348 1.03711 7.55013 1.40894L5.6473 5.2644L1.3926 5.88257C0.982505 5.94214 0.818748 6.44604 1.1155 6.73535L4.19424 9.73633L3.46743 13.9739C3.39742 14.3823 3.82613 14.6938 4.19295 14.501L7.9985 12.5002L11.804 14.501C12.1708 14.6938 12.5996 14.3823 12.5295 13.9739L11.8027 9.73633L14.8814 6.73535C15.1782 6.44604 15.0144 5.94214 14.6043 5.88257L10.3496 5.2644ZM2.53896 6.72656L5.2687 9.38721L4.62429 13.1445L7.9985 11.3706L11.3726 13.1445L10.7282 9.38721L13.458 6.72656L9.68557 6.17822L7.9985 2.76001L6.31142 6.17822L2.53896 6.72656Z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconSubtract16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 11L10.5 8L6 5L6 11Z" fill="#1B1F26" />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconInfo16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            d="M8 1C11.866 1 15 4.13403 15 8C15 11.866 11.866 15 8 15C4.134 15 1 11.866 1 8C1 4.13403 4.134 1 8 1ZM8.75 4.74951C8.75 5.16382 8.41422 5.49951 8 5.49951C7.58578 5.49951 7.25 5.16382 7.25 4.74951C7.25 4.33521 7.58578 3.99951 8 3.99951C8.41422 3.99951 8.75 4.33521 8.75 4.74951ZM8.5 6.49976L7.5 6.49976L7.5 11.9995L8.5 11.9995L8.5 6.49976Z"
            fillRule="evenodd"
            fill="var(--wk-brand-7)"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconSuccess16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            d="M15 8C15 4.13403 11.866 1 8 1C4.134 1 1 4.13403 1 8C1 11.866 4.134 15 8 15C11.866 15 15 11.866 15 8ZM5.35358 7.14648L4.64648 7.85352L7.50003 10.707L11.8536 6.35352L11.1465 5.64648L7.50003 9.29297L5.35358 7.14648Z"
            fillRule="evenodd"
            fill="var(--wk-green-7)"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconWarning16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            d="M8 1C11.866 1 15 4.13403 15 8C15 11.866 11.866 15 8 15C4.13403 15 1 11.866 1 8C1 4.13403 4.13403 1 8 1ZM8.5 3.99951L7.5 3.99951L7.5 9.49976L8.5 9.49976L8.5 3.99951ZM8.75 11.2498C8.75 11.6641 8.41418 11.9998 8 11.9998C7.58582 11.9998 7.25 11.6641 7.25 11.2498C7.25 10.8354 7.58582 10.4998 8 10.4998C8.41418 10.4998 8.75 10.8354 8.75 11.2498Z"
            fillRule="evenodd"
            fill="var(--wk-yellow-7)"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconError16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            d="M8 1C11.866 1 15 4.13403 15 8C15 11.866 11.866 15 8 15C4.13403 15 1 11.866 1 8C1 4.13403 4.13403 1 8 1ZM5.52545 4.81763L8.00049 7.29248L10.4752 4.81787L11.1823 5.5249L8.70758 7.99976L11.1827 10.4749L10.4756 11.1819L8.00049 8.70679L5.52509 11.1821L4.81799 10.4751L7.2934 7.99976L4.81836 5.52466L5.52545 4.81763Z"
            fillRule="evenodd"
            fill="var(--wk-red-7)"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconLoading16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
        <path
            transform="matrix(1 0 0 1 2 2)"
            d="M6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6L11 6Q11 6.06522 10.9986 6.12981Q10.9773 7.09853 10.6368 7.92062Q10.2736 8.79751 9.53553 9.53553Q8.79751 10.2736 7.92062 10.6368Q7.04373 11 6 11Q4.95627 11 4.07938 10.6368Q3.20249 10.2736 2.46447 9.53553Q1.72644 8.79751 1.36322 7.92062Q1 7.04373 1 6Q1 5.93477 1.00142 5.87019Q1.0227 4.90147 1.36322 4.07938Q1.72644 3.20249 2.46447 2.46447Q3.20249 1.72644 4.07938 1.36322Q4.95627 1 6 1L6 0Z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconBackTo16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
        <path
            transform="matrix(1 0 0 1 2.5 1.5)"
            d="M3.35355 0.353553L1.20711 2.5L7.5 2.5Q8.61659 2.5 9.54321 2.84647Q10.4719 3.19369 11.1391 3.86091Q11.8063 4.52813 12.1535 5.45679Q12.5 6.38341 12.5 7.5Q12.5 8.61659 12.1535 9.54321Q11.8063 10.4719 11.1391 11.1391Q10.4719 11.8063 9.54321 12.1535Q8.61659 12.5 7.5 12.5L2.5 12.5L2.5 11.5L7.5 11.5Q9.36396 11.5 10.432 10.432Q11.5 9.36396 11.5 7.5Q11.5 5.63604 10.432 4.56802Q9.36396 3.5 7.5 3.5L1.20711 3.5L3.35355 5.64645L2.64645 6.35355L-0.707107 3L2.64645 -0.353553L3.35355 0.353553Z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconEnter16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            transform="matrix(1 0 0 1 6.00002 5.00024)"
            d="M0 6L4.5 3L0 0L0 6Z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconArrowLeft16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            transform="matrix(1 0 0 1 4.43936 2.64673)"
            d="M6.06066 0.707107L1.41421 5.35355L6.06066 10L5.35355 10.7071L0 5.35355L5.35355 0L6.06066 0.707107Z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconArrowRight16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            transform="matrix(1 0 0 1 5.5 2.64648)"
            d="M4.64645 5.35355L0 0.707107L0.707107 0L6.06066 5.35355L0.707107 10.7071L0 10L4.64645 5.35355Z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconReset16 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            transform="matrix(1 0 0 1 2.5 2)"
            d="M8.5 2L5.5 0L5.5 1.5C2.46246 1.5 0 3.9624 0 7C0 10.0376 2.46246 12.5 5.5 12.5C8.53754 12.5 11 10.0376 11 7L10 7C10 9.48535 7.98529 11.5 5.5 11.5C3.01471 11.5 1 9.48535 1 7C1 4.51465 3.01471 2.5 5.5 2.5L5.5 4L8.5 2Z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconTrash16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
        <path transform="matrix(1 0 0 1 1 4)" d="M0 0L14 0L14 1L0 1L0 0Z" fillRule="nonzero" fill="currentColor" />
        <path transform="matrix(1 0 0 1 5 1)" d="M0 0L6 0L6 1L0 1L0 0Z" fillRule="nonzero" fill="currentColor" />
        <path
            transform="matrix(1 0 0 1 3 4)"
            d="M0 0L0 9.33333C0 9.70152 0.298477 10 0.666667 10L9.33333 10C9.70152 10 10 9.70152 10 9.33333L10 0L0 0ZM1 1L1 9L9 9L9 1L1 1Z"
            fillRule="evenodd"
            fill="currentColor"
        />
        <path transform="matrix(1 0 0 1 6 7)" d="M0 0L1 0L1 4L0 4L0 0Z" fillRule="nonzero" fill="currentColor" />
        <path transform="matrix(1 0 0 1 9 7)" d="M0 0L1 0L1 4L0 4L0 0Z" fillRule="nonzero" fill="currentColor" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconBookBigOpen16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" {...props} data-testid="IconBookBigOpen16">
        <path
            transform="matrix(1 0 0 1 1 2.0138)"
            d="M12.1902 0.348181C11.5528 0.0625582 10.8562 -0.0473362 10.1005 0.0184977C9.41295 0.078385 8.75307 0.274738 8.12079 0.607556C7.68816 0.835285 7.31277 1.10326 6.9946 1.41148C6.67695 1.10332 6.30265 0.835447 5.87169 0.607866C5.2411 0.274863 4.58305 0.078411 3.89754 0.0185095C3.14388 -0.0473474 2.44862 0.0625701 1.81178 0.348262C1.09869 0.668153 0.520096 1.18344 0.0759838 1.89412L0 2.15909L0 11.5614L0.859905 11.9085C1.05211 11.7092 1.30557 11.5309 1.62027 11.3735C2.01026 11.1784 2.43428 11.0508 2.89232 10.9907C4.11333 10.8304 5.39331 11.1626 6.73224 11.9872L7.25663 11.9872C8.59569 11.1625 9.87757 10.8304 11.1023 10.9907C11.5619 11.0509 11.9872 11.1786 12.3785 11.3737C12.6943 11.5312 12.9482 11.7095 13.1401 11.9085L14 11.5614L14 2.15909L13.9247 1.89518C13.4827 1.18398 12.9045 0.668316 12.1902 0.348181ZM11.2321 9.99919C10.0145 9.83975 8.76859 10.0771 7.49444 10.7111L7.49444 2.3372C7.77915 2.00742 8.1432 1.72584 8.58659 1.49245C9.1 1.2222 9.63355 1.06296 10.1872 1.01473C10.7705 0.963916 11.3018 1.04592 11.7812 1.26074C12.2707 1.48011 12.677 1.82879 13 2.30677L13 10.5707C12.9431 10.5393 12.8847 10.5087 12.8248 10.4789C12.3335 10.2338 11.8026 10.0739 11.2321 9.99919ZM6.49444 2.33715C6.20968 2.00708 5.84645 1.7254 5.40473 1.49214C4.89324 1.22203 4.36183 1.06289 3.81049 1.01471C3.22955 0.963949 2.69974 1.04593 2.22108 1.26066C1.73193 1.48009 1.32491 1.82902 1 2.30743L1 10.57C1.05619 10.539 1.11384 10.5087 1.17296 10.4791C1.66323 10.2339 2.19297 10.0739 2.76217 9.99921C3.97675 9.83978 5.22084 10.0771 6.49444 10.7111L6.49444 2.33715Z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconSecret16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
        <path
            transform="matrix(1 0 0 1 2.00002 1.00024)"
            d="M6 0C7.65686 0 9 1.34326 9 3C9 4.65674 7.65686 6 6 6C4.34314 6 3 4.65674 3 3C3 1.34326 4.34314 0 6 0ZM6 1C5.44772 1 4.97632 1.19531 4.58578 1.58569C4.19525 1.97632 4 2.44775 4 3C4 3.55225 4.19525 4.02368 4.58578 4.41431C4.97632 4.80469 5.44772 5 6 5C6.55228 5 7.02368 4.80469 7.41422 4.41431C7.80475 4.02368 8 3.55225 8 3C8 2.44775 7.80475 1.97632 7.41422 1.58569C7.02368 1.19531 6.55228 1 6 1ZM3 7L6.67133 7C6.319 7.28516 6.01056 7.62231 5.75778 8L3 8C2.44772 8 1.97632 8.19531 1.58578 8.58569C1.19525 8.97632 1 9.44775 1 10L1 12L5.25607 12C5.3819 12.356 5.55127 12.6914 5.75778 13L1 13C0.447723 13 0 12.5522 0 12L0 10C0 8.34326 1.34314 7 3 7ZM13 10.5C13 8.56689 11.433 7 9.5 7C7.56702 7 6 8.56689 6 10.5C6 12.4331 7.56702 14 9.5 14C11.433 14 13 12.4331 13 10.5ZM10.4728 8.19604C10.6161 8.25659 10.7515 8.32935 10.8792 8.41382L7.41382 11.8792C7.32922 11.7515 7.25665 11.616 7.19604 11.4729C7.06534 11.1638 7 10.8396 7 10.5C7 10.1604 7.06534 9.83618 7.19604 9.5271C7.32251 9.22827 7.50122 8.96313 7.73224 8.73218Q8.07872 8.38574 8.52719 8.19604C8.83621 8.06543 9.16046 8 9.5 8C9.83954 8 10.1638 8.06543 10.4728 8.19604ZM8.52719 12.804C8.83621 12.9346 9.16046 13 9.5 13C9.83954 13 10.1638 12.9346 10.4728 12.804Q10.9213 12.6143 11.2678 12.2678C11.4988 12.0369 11.6775 11.7717 11.804 11.4729C11.9347 11.1638 12 10.8396 12 10.5C12 10.1604 11.9347 9.83618 11.804 9.5271C11.7434 9.38403 11.6708 9.24854 11.5862 9.12085L8.12094 12.5862C8.24857 12.6709 8.38397 12.7434 8.52719 12.804Z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconFilter16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
        <path
            transform="matrix(1 0 0 1 2.00002 3.00024)"
            d="M12 0L0 0L0 1L12 1L12 0ZM10 4.5L2 4.5L2 5.5L10 5.5L10 4.5ZM4 9L8 9L8 10L4 10L4 9Z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconApplyProp16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
        <path
            transform="matrix(1 0 0 1 0.5 1.70703)"
            d="M7.79305 0.292965L4.64751 3.43893C4.4522 3.63424 4.4522 3.95113 4.64751 4.14644C4.84282 4.34126 5.15923 4.34126 5.35455 4.14595L8.50009 0.999989L13.7926 6.29241L8.50009 11.5853L5.35406 8.43887C5.15875 8.24405 4.84185 8.24405 4.64702 8.43887C4.45171 8.63418 4.45171 8.95107 4.64702 9.14638L7.79305 12.2923C7.98837 12.4877 8.22372 12.5853 8.50009 12.5853C8.77597 12.5853 9.01181 12.4877 9.20713 12.2923L14.4997 6.99943C14.695 6.80461 14.7926 6.56877 14.7926 6.29241C14.7926 6.01653 14.695 5.7807 14.4997 5.58539L9.20713 0.292965C9.01181 0.0976552 8.77597 0 8.50009 0C8.22372 0 7.98837 0.0976552 7.79305 0.292965ZM8.50009 4.79291C9.32871 4.79291 10.0001 5.46429 10.0001 6.2929C10.0001 7.12101 9.32871 7.79288 8.50009 7.79288C7.84676 7.79288 7.29158 7.37541 7.08552 6.79289L0.500005 6.79289C0.223635 6.79289 0 6.56877 0 6.2929C0 6.01653 0.223635 5.7929 0.500005 5.7929L7.08552 5.7929C7.29158 5.2099 7.84676 4.79291 8.50009 4.79291Z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconWarningLine16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
        <path
            transform="matrix(1 0 0 1 1 1)"
            d="M7 0C10.866 0 14 3.13403 14 7C14 10.866 10.866 14 7 14C3.13403 14 0 10.866 0 7C0 3.13403 3.13403 0 7 0ZM2.75732 2.75732C3.31128 2.20337 3.94702 1.77466 4.66479 1.47107C5.40747 1.15698 6.18579 1 7 1C7.81421 1 8.59253 1.15698 9.33521 1.47107C10.053 1.77466 10.6887 2.20337 11.2427 2.75732C11.7966 3.31128 12.2253 3.94714 12.5288 4.66479C12.843 5.40735 13 6.18579 13 7C13 7.81421 12.843 8.59265 12.5288 9.33521C12.2253 10.0529 11.7966 10.6887 11.2427 11.2427C10.6887 11.7966 10.053 12.2253 9.33521 12.5289C8.59253 12.843 7.81421 13 7 13C6.18579 13 5.40747 12.843 4.66479 12.5289C3.94702 12.2253 3.31128 11.7966 2.75732 11.2427C2.20337 10.6887 1.77466 10.0529 1.47119 9.33521C1.15698 8.59265 1 7.81421 1 7C1 6.18579 1.15698 5.40735 1.47119 4.66479C1.77466 3.94714 2.20337 3.31128 2.75732 2.75732ZM7.5 3.5L7.5 8.5L6.5 8.5L6.5 3.5L7.5 3.5ZM7 9.5C7.41431 9.5 7.75 9.83582 7.75 10.25C7.75 10.6642 7.41431 11 7 11C6.58569 11 6.25 10.6642 6.25 10.25C6.25 9.83582 6.58569 9.5 7 9.5Z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconErrorLine16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
        <path
            transform="matrix(1 0 0 1 1 1)"
            d="M14 7C14 3.13403 10.866 0 7 0C3.134 0 0 3.13403 0 7C0 10.866 3.134 14 7 14C10.866 14 14 10.866 14 7ZM11.2426 2.75732C10.0711 1.58569 8.65686 1 7 1C5.34314 1 3.92892 1.58569 2.75735 2.75732C1.58578 3.92896 1 5.34326 1 7C1 8.65674 1.58578 10.071 2.75735 11.2427C3.92892 12.4143 5.34314 13 7 13C8.65686 13 10.0711 12.4143 11.2426 11.2427C12.4142 10.071 13 8.65674 13 7C13 5.34326 12.4142 3.92896 11.2426 2.75732ZM3.81836 4.52393L4.52545 3.81689L7.00043 6.29175L9.47485 3.81738L10.1819 4.52441L7.70755 6.99902L10.1823 9.47363L9.47522 10.1807L7.00043 7.70605L4.52512 10.1814L3.81799 9.47412L6.29333 6.99902L3.81836 4.52393Z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconInfoLine16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
        <path
            transform="matrix(1 0 0 1 1 1)"
            d="M14 7C14 3.13403 10.866 0 7 0C3.13403 0 0 3.13403 0 7C0 10.866 3.13403 14 7 14C10.866 14 14 10.866 14 7ZM1.47119 4.66479C1.77466 3.94714 2.20337 3.31128 2.75732 2.75732C3.31128 2.20337 3.94702 1.77466 4.66479 1.47107C5.40747 1.15698 6.18579 1 7 1C7.81421 1 8.59253 1.15698 9.33521 1.47107C10.053 1.77466 10.6887 2.20337 11.2427 2.75732C11.7966 3.31128 12.2253 3.94714 12.5288 4.66479C12.843 5.40735 13 6.18579 13 7C13 7.81421 12.843 8.59265 12.5288 9.33521C12.2253 10.0529 11.7966 10.6887 11.2427 11.2427C10.6887 11.7966 10.053 12.2253 9.33521 12.5289C8.59253 12.843 7.81421 13 7 13C6.18579 13 5.40747 12.843 4.66479 12.5289C3.94702 12.2253 3.31128 11.7966 2.75732 11.2427C2.20337 10.6887 1.77466 10.0529 1.47119 9.33521C1.15698 8.59265 1 7.81421 1 7C1 6.18579 1.15698 5.40735 1.47119 4.66479ZM7 3C7.41431 3 7.75 3.33582 7.75 3.75C7.75 4.16418 7.41431 4.5 7 4.5C6.58569 4.5 6.25 4.16418 6.25 3.75C6.25 3.33582 6.58569 3 7 3ZM7.5 5.5L7.5 10.5L6.5 10.5L6.5 5.5L7.5 5.5Z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconSuccessLine16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
        <path
            transform="matrix(1 0 0 1 1 1)"
            d="M14 7C14 3.13403 10.866 0 7 0C3.13403 0 0 3.13403 0 7C0 10.866 3.13403 14 7 14C10.866 14 14 10.866 14 7ZM4.66479 1.47107C5.40747 1.15698 6.18579 1 7 1C7.81421 1 8.59253 1.15698 9.33521 1.47107C10.053 1.77466 10.6887 2.20337 11.2427 2.75732C11.7966 3.31128 12.2253 3.94714 12.5288 4.66479C12.843 5.40735 13 6.18579 13 7C13 7.81421 12.843 8.59265 12.5288 9.33521C12.2253 10.0529 11.7966 10.6887 11.2427 11.2427C10.6887 11.7966 10.053 12.2253 9.33521 12.5289C8.59253 12.843 7.81421 13 7 13C6.18579 13 5.40747 12.843 4.66479 12.5289C3.94702 12.2253 3.31128 11.7966 2.75732 11.2427C2.20337 10.6887 1.77466 10.0529 1.47119 9.33521C1.15698 8.59265 1 7.81421 1 7C1 6.18579 1.15698 5.40735 1.47119 4.66479C1.77466 3.94714 2.20337 3.31128 2.75732 2.75732C3.31128 2.20337 3.94702 1.77466 4.66479 1.47107ZM4.35352 6.14648L6.5 8.29297L10.1465 4.64648L10.8535 5.35364L6.5 9.70715L3.64648 6.85364L4.35352 6.14648Z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconQuestion16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
        <path
            transform="matrix(1 0 0 1 1 1)"
            d="M13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7ZM7.23096 8.60449L6.29102 8.60449L6.29102 8.11035C6.29102 7.87402 6.32861 7.66097 6.40381 7.47119C6.479 7.27783 6.59717 7.10059 6.7583 6.93945C6.91943 6.77832 7.12174 6.62435 7.36523 6.47754C7.57292 6.34863 7.73942 6.22331 7.86475 6.10156C7.99365 5.97624 8.08675 5.84017 8.14404 5.69336C8.20133 5.54655 8.22998 5.37826 8.22998 5.18848L8.22998 5.17773C8.22998 4.95573 8.17627 4.75879 8.06885 4.58691C7.96143 4.41504 7.80924 4.27897 7.6123 4.17871C7.41536 4.07845 7.18262 4.02832 6.91406 4.02832C6.64551 4.02832 6.41097 4.07845 6.21045 4.17871C6.00993 4.27897 5.85059 4.4222 5.73242 4.6084C5.61784 4.7946 5.5516 5.01481 5.53369 5.26904L5.52295 5.27441L4.60986 5.27979L4.60986 5.26904C4.63135 4.8859 4.73161 4.53678 4.91064 4.22168C5.08968 3.90658 5.34928 3.65592 5.68945 3.46973C6.02962 3.28353 6.45036 3.19043 6.95166 3.19043C7.40283 3.19043 7.7985 3.27637 8.13867 3.44824C8.48242 3.61654 8.74919 3.84749 8.93896 4.14111C9.13232 4.43115 9.229 4.76058 9.229 5.12939L9.229 5.14014C9.229 5.55908 9.12695 5.92074 8.92285 6.2251C8.72233 6.52588 8.4502 6.78011 8.10645 6.98779C7.88086 7.12028 7.70361 7.2474 7.57471 7.36914C7.44938 7.49089 7.35986 7.62158 7.30615 7.76123C7.25602 7.90088 7.23096 8.06559 7.23096 8.25537L7.23096 8.60449ZM7.36523 10.8872C7.22917 11.0269 7.05192 11.0967 6.8335 11.0967C6.61507 11.0967 6.43604 11.0269 6.29639 10.8872C6.15674 10.7476 6.08691 10.5685 6.08691 10.3501C6.08691 10.1281 6.15674 9.94727 6.29639 9.80762C6.43604 9.66797 6.61507 9.59814 6.8335 9.59814C7.05192 9.59814 7.22917 9.66797 7.36523 9.80762C7.50488 9.94727 7.57471 10.1281 7.57471 10.3501C7.57471 10.5685 7.50488 10.7476 7.36523 10.8872Z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
)
