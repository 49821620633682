import { GetCurrentSchemaVersionWasmCall, Wukong } from '@wukong/bridge-proto'
import { isNotNullOrUndefined } from '../../../../../util/src'
import { CommandInvoker } from '../../../document/command/command-invoker'

export async function parseFigmaClipboardSceneDataToExportedDocument(
    commandInvoker: CommandInvoker,
    content: string,
    insertImagePlaceholder: boolean
): Promise<Wukong.DocumentProto.ISerializedExportedDocument | null> {
    const result = /<!--\(figma\)(.*)\(\/figma\)/.exec(content)
    if (!result?.[1]) {
        return null
    }
    const schemaVersion = commandInvoker.DEPRECATED_invokeBridge(GetCurrentSchemaVersionWasmCall).value!
    const { Importer } = await import('../../../importer')
    const tree = await Importer.generateWkNodeTreeFromFigFile(
        Buffer.from(result[1], 'base64'),
        schemaVersion,
        insertImagePlaceholder
    )
    // 由于与服务端对齐，SynergyNode 没有 id，需要手动补上
    // 复制逻辑依赖 instance relativeTransform 存在
    tree.map.forEach((node, id) => {
        node.id = id
        if (
            node.type === Wukong.DocumentProto.NodeType.NODE_TYPE_INSTANCE &&
            !isNotNullOrUndefined(node.relativeTransform)
        ) {
            node.relativeTransform = node.symbolData!.symbolOverrides![id]!.partialNode!.relativeTransform!
        }
    })

    const document = tree.roots.find((node) => node.type == Wukong.DocumentProto.NodeType.NODE_TYPE_DOCUMENT)
    if (!document) {
        throw new Error('document not found')
    }
    delete document.parentInfo
    const shouldWalkWkNodes = [document]

    const refNodes = tree.roots.filter(
        (node) =>
            node.type != Wukong.DocumentProto.NodeType.NODE_TYPE_DOCUMENT &&
            node.type != Wukong.DocumentProto.NodeType.NODE_TYPE_PAGE &&
            node.type != Wukong.DocumentProto.NodeType.NODE_TYPE_STYLE_CONTAINER
    )
    const styleContainer = tree.roots.find(
        (node) => node.type == Wukong.DocumentProto.NodeType.NODE_TYPE_STYLE_CONTAINER
    )
    refNodes.push(...(styleContainer?.children ?? []))

    if (refNodes.length > 0) {
        refNodes.forEach((child) => delete child.parentInfo)
        shouldWalkWkNodes.push(...refNodes)
    }

    const allNodes: Wukong.DocumentProto.ISerializedExportedNode[] = []
    for (const rootNode of shouldWalkWkNodes) {
        Importer.walkWkNode(rootNode, (node) => {
            allNodes.push({
                nodeProps: node,
            })
        })
    }

    const pasteOffsetX = tree.pasteOffset?.x ?? 0
    const pasteOffsetY = tree.pasteOffset?.y ?? 0

    const exportedDocument: Wukong.DocumentProto.ISerializedExportedDocument = {
        allNodes,
        docId: `figmaDoc-2:${tree.pasteFileKey}`,
        pasteContainerBounds: {
            left: pasteOffsetX,
            top: pasteOffsetY,
            right: pasteOffsetX + 100,
            bottom: pasteOffsetY + 100,
        },
        pasteIsInsideContainer: !tree.pasteIsPartiallyOutsideEnclosingFrame,
        pasteExtraRefNodes: [],
        blobs: tree.blobs,
        schemaVersion,
    }

    return exportedDocument
}
