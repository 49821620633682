import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    propNotUsed: '该属性未使用',
    nameConflict: '属性名冲突',
    editProp: '编辑属性',
    deleteProp: '删除属性',
} as const

export const enTranslation = {
    propNotUsed: 'Not used within component ',
    nameConflict: 'Conflicting property name',
    editProp: 'Edit property',
    deleteProp: 'Delete property',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
