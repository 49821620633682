/* eslint-disable no-restricted-imports */
import { CommentId, CommentThread, CommentUser } from '../../../kernel/interface/comment'

export interface CommentWorldPosition {
    x: number
    y: number
    hasAnchor: boolean
    anchorWorldX: number
    anchorWorldY: number
}

export interface CommentClusterWorldPosition {
    x: number
    y: number
}

export interface WorldPosition {
    worldX: number
    worldY: number
}

export interface CameraPosition {
    cameraX: number
    cameraY: number
}

export interface Comment {
    commentMetaData: CommentThread // 元数据
    overlayPosition: CommentWorldPosition // 在 overlay 上的位置
}

export type ClusterId = string
export interface CommentCluster {
    clusterId: ClusterId
    commentIds: CommentId[]
    overlayPosition: CommentClusterWorldPosition
}

export interface Position {
    left: number
    top: number
}

export interface DragAnchorEnd {
    anchorOffsetX: number
    anchorOffsetY: number
    minorAnchorOffsetX?: number
    minorAnchorOffsetY?: number
}

export interface DragMinorAnchorEnd {
    minorAnchorOffsetX: number
    minorAnchorOffsetY: number
}

export enum MessageType {
    Text,
    Emoji,
    Mention,
}

export interface MessageText {
    t: MessageType.Text
    c: string
}

export interface MessageEmoji {
    t: MessageType.Emoji
    c: string
}

export interface MessageMention {
    t: MessageType.Mention
    c: CommentUser['id']
}

export type Message = MessageText | MessageEmoji | MessageMention

export enum EditorType {
    Null,
    CreateComment,
    CreateReply,
    EditReply,
}
