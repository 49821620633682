import { translation } from './report-obstacle.translation'
/* eslint-disable no-restricted-imports */
import { useCallback } from 'react'
import { ToKeyCode } from '../../../../../document/util/keycode'
import { EmBridge } from '../../../../../kernel/bridge/em-bridge'
import { WkCLog } from '../../../../../kernel/clog/wukong/instance'
import { isCombo, ModifierKeysCombo } from '../../../../../kernel/keyboard/keyboard-event-handler'
import { openRecordingDb } from '../../../../../kernel/recording/record'
import {
    GetUploadAuthorizationError,
    reportRecording,
    uploadBridgeRecording,
} from '../../../../../kernel/recording/upload'
import { useBridge } from '../../../../../main/app-context'
import { KeyboardReceiver } from '../../../../../main/keyboard-receiver/component'
import { IssusModal } from '../../../crash/ui/issue-modal'
import { useMenuContextState } from '../context/menu-context'

export function ReportObstacle() {
    const { isOpenReportObstacle, setIsOpenReportObstacle } = useMenuContextState()
    const bridge = useBridge() as EmBridge

    const onCancel = useCallback(() => {
        setIsOpenReportObstacle(false)
    }, [setIsOpenReportObstacle])

    const onOK = useCallback(
        async (description: string) => {
            try {
                const uploadTo = await uploadBridgeRecording(
                    bridge.currentRecordingName,
                    await openRecordingDb(bridge.currentRecordingName)
                )
                await reportRecording(description, uploadTo)
            } catch (e) {
                if (e instanceof GetUploadAuthorizationError) {
                    alert(translation('FailedToAcquire'))
                }
                WkCLog.log('failed to reportJiraIssue')
            }
        },
        [bridge.currentRecordingName]
    )

    const keydownHandler = useCallback(
        (e: KeyboardEvent) => {
            if (isCombo(e, ModifierKeysCombo.META_SHIFT_ALT)) {
                e.preventDefault()
                setIsOpenReportObstacle(true)
                return false
            }
            return true
        },
        [setIsOpenReportObstacle]
    )

    return (
        <>
            <KeyboardReceiver keyCode={ToKeyCode.I} onKeydown={keydownHandler}>
                <IssusModal
                    key={String(isOpenReportObstacle)}
                    type="bug"
                    visible={isOpenReportObstacle}
                    onCancel={onCancel}
                    onOK={onOK}
                    onClose={onCancel}
                />
            </KeyboardReceiver>
        </>
    )
}
