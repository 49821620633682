/* eslint-disable no-restricted-imports */
import { GetContainerIdByIndentCommand, LayerPanelDragSelectionToNodeIndentCommand, Wukong } from '@wukong/bridge-proto'
import constate from 'constate'
import { MouseEventHandler, useMemo, useRef, useState } from 'react'
import useEvent from 'react-use/lib/useEvent'
import useUpdateEffect from 'react-use/lib/useUpdateEffect'
import { NodeId } from '../../../../document/node/node'
import { useCommand } from '../../../context/document-context'
import { getItemId, getItemInfoByTopHeight, LayerPanelItemType2Height } from './util'

interface dragProps {
    renderList: Wukong.DocumentProto.VLayerPanelItemInfo[]
    layerPanelContainerRef: () => HTMLDivElement
    totalHeight: number
    translateHeight: number
}

export enum MouseStatus {
    Up,
    Down,
}

// 维护 drag 过程中的状态
const useDrag = (props: dragProps) => {
    const { renderList, layerPanelContainerRef, totalHeight, translateHeight } = props

    const command = useCommand()

    const mouseStatusRef = useRef<MouseStatus>(MouseStatus.Up)
    const mouseDownPositionRef = useRef<{ x: number; y: number }>()
    const dragStartRef = useRef<boolean>(false)
    const dragExpandNodeIdsRef = useRef<NodeId[]>([])

    // 提供给 dragItem 拖拽时的目标节点信息，包含目标节点的 id 和在目标节点所处的位置【0 ~ 1，从小到大为从上至下的位置】, movementX 为水平移动距离和起始点的相对距离
    const [dragTargetInfo, setDragTargetInfo] = useState<{ nodeId: NodeId; mouseArea: number; movementX: number }>()
    // 从 dragItem 中获取到的 drop 节点的数据，包括 drop 节点的 id 和 drop 到节点的具体层级
    const [dropInfo, setDropInfo] = useState<{
        itemInfo: Wukong.DocumentProto.VLayerPanelItemInfo
        indent: number
    }>()
    // drop 的容器 id
    const [dropContainerId, setDropContainerId] = useState<NodeId>()

    // 进入目标区域默认的缩进值，如果通过 x 移动后，会变更成新的默认值
    const selectionMinIndentRef = useRef<number>()

    const itemTopHeightMap = useMemo(() => {
        const itemHeightMap_ = new Map<NodeId, number>()
        let height = translateHeight
        renderList.forEach((item) => {
            itemHeightMap_.set(getItemId(item), height)
            height += LayerPanelItemType2Height[item.type]
        })
        return itemHeightMap_
    }, [renderList, translateHeight])

    // 计算出拖拽开始时选中元素的最小层级，由此来判断初次进入目标图层展示的层级
    const caculateSelectionMinIndent = () => {
        if (selectionMinIndentRef.current === undefined) {
            const renderNodeList = renderList
                .filter((renderItem) => {
                    return renderItem.type == Wukong.DocumentProto.LayerPanelItemType.LAYER_PANEL_ITEM_TYPE_NODE
                })
                .map(({ layerPanelNodeInfo }) => layerPanelNodeInfo as Wukong.DocumentProto.VLayerPanelNode)
            const selectionIndents = renderNodeList.filter(({ isSelected }) => !!isSelected).map(({ indent }) => indent)
            let minIndent = selectionIndents[0]
            for (let i = 1; i < selectionIndents.length; i++) {
                if (minIndent === 0) {
                    break
                }
                minIndent = Math.min(minIndent, selectionIndents[i])
            }
            selectionMinIndentRef.current = minIndent
        }
    }

    const fillDragTargetInfo = (targetPositionY: number, movementX: number) => {
        const currentItemInfo = getItemInfoByTopHeight(targetPositionY, renderList, itemTopHeightMap)
        const currentItemHeight = LayerPanelItemType2Height[currentItemInfo.type]
        const currentItemId = getItemId(currentItemInfo)

        setDragTargetInfo({
            nodeId: currentItemId,
            mouseArea: Math.min((targetPositionY - itemTopHeightMap.get(currentItemId)!) / currentItemHeight, 1),
            movementX,
        })
    }

    const handleMouseUp: MouseEventHandler<HTMLDivElement> = (event) => {
        if (dragStartRef.current && dropInfo) {
            // 拖拽至目标节点的对应层级位置
            command.DEPRECATED_invokeBridge(
                LayerPanelDragSelectionToNodeIndentCommand,
                Wukong.DocumentProto.Arg_cmdDragSelectionToNodeIndent.create({
                    item: dropInfo.itemInfo,
                    indent: dropInfo.indent,
                    dragExpandNodeIds: dragExpandNodeIdsRef.current,
                    altKey: event.altKey,
                })
            )
            // 清空状态
            setDragTargetInfo(undefined)
            setDropInfo(undefined)
            setDropContainerId(undefined)
            selectionMinIndentRef.current = undefined
            mouseDownPositionRef.current = undefined
            dragExpandNodeIdsRef.current = []
            dragStartRef.current = false
            command.commitUndo()
        }
        mouseStatusRef.current = MouseStatus.Up
    }

    // 处理 mouseMove 事件
    useEvent('mousemove', (event) => {
        if (mouseStatusRef.current === MouseStatus.Down) {
            const element = layerPanelContainerRef()
            const { clientX, clientY, movementY } = event
            if (
                Math.abs(clientX - mouseDownPositionRef.current!.x) < 4 &&
                Math.abs(clientY - mouseDownPositionRef.current!.y) < 4
            ) {
                return
            }
            // 当拖拽距离水平或竖直超过 4 px 的时候，开始响应拖拽行为
            dragStartRef.current = true
            // 计算出选中节点中最小的层级
            caculateSelectionMinIndent()
            // 获取 container 元素的滚动高度和顶点所处的 clientY 的位置
            const scrollTop = element.scrollTop
            const boundingY = element.getBoundingClientRect().y
            const boundingHeight = element.clientHeight
            const scrollMovement = Math.abs(movementY) * 10
            // 当前鼠标位置在虚拟列表中的指向位置
            let targetPositionY
            // 当拖拽的点超过 bounding 时，触发向上的滚动
            if (clientY < boundingY) {
                // 触发滚动
                element.scrollTop = Math.max(0, scrollTop - scrollMovement)
                targetPositionY = scrollTop
            } // 当拖拽的点超过下方的 bounding 时，触发向下的滚动
            else if (clientY > boundingY + boundingHeight) {
                // 触发滚动
                element.scrollTop = Math.max(0, scrollTop + scrollMovement)
                targetPositionY = scrollTop + Math.min(boundingHeight, totalHeight)
            } // 在 container 区域内移动
            else {
                targetPositionY = scrollTop + Math.min(clientY - boundingY, totalHeight)
            }
            // 填充拖入目标节点的信息
            fillDragTargetInfo(targetPositionY, clientX - mouseDownPositionRef.current!.x)
        }
    })

    // 处理在图层面板区域外 mouseUp 的情况
    useEvent('mouseup', (event) => {
        handleMouseUp(event)
    })

    // 监听 dropInfo 的变化，重置高亮显示的容器节点
    useUpdateEffect(() => {
        if (dropInfo) {
            setDropContainerId(
                command.DEPRECATED_invokeBridge(GetContainerIdByIndentCommand, {
                    item: dropInfo.itemInfo!,
                    indent: dropInfo.indent,
                }).value!
            )
        }
    }, [dropInfo])

    return {
        mouseStatusRef,
        dragStartRef,
        mouseDownPositionRef,
        handleMouseUp,
        dragTargetInfo,
        selectionMinIndentRef,
        setDropInfo,
        dropContainerId,
        dragExpandNodeIdsRef,
    }
}

export const [LayerPanelItemDragProvider, useDragContext] = constate(useDrag, (ctx) => ctx)
