/* eslint-disable no-restricted-imports */
import { GetDocReadonlyCommand } from '@wukong/bridge-proto'
import { TraceableAbortSignal } from '../../../../../util/src/abort-controller/traceable-abort-controller'
import { transaction } from '../../../../../util/src/abort-controller/traceable-transaction'
import { Bridge } from '../../../kernel/bridge/bridge'
import { WkCLog } from '../../../kernel/clog/wukong/instance'
import { WindowSelectionType, getWindowSelection } from '../../global/window'
import { focusViewManager } from '../../service/focus-view/focus-view-manager'
import { focusViewTraceLog } from '../../service/focus-view/focus-view-trace-log'

export function shouldHandleClipboardEvent(event: ClipboardEvent, filter: ClipboardEventFilter): boolean {
    if (focusViewManager.isFocusViewEnabled) {
        return focusViewManager.isDocumentActiveElementFocusView()
    }
    return (
        (event.type === 'paste' || event.type === 'copy' || event.type === 'cut') &&
        // 当 window selection 为 range 时，说明选中了页面上的一些 text 或 input，此时执行浏览器默认复制粘贴操作
        // 用户先选中input中的一段内容再blur selection.type 可能还是range 但是此时需要wasm响应复制粘贴操作
        (getWindowSelection()?.type !== WindowSelectionType.Range || event.target == document.body) &&
        filter(event)
    )
}

export type ClipboardEventFilter = (event: ClipboardEvent) => boolean

export type ClipboardEventWithModifierKeys = Readonly<{ event: ClipboardEvent; shiftKey: boolean }>

export interface IClipboardEventHandler {
    registerHandlers: () => void
    onCopyEventWithSignal: (signal: TraceableAbortSignal, callback: (event: ClipboardEvent) => void) => void
    onPasteEventWithSignal: (
        signal: TraceableAbortSignal,
        callback: (event: ClipboardEventWithModifierKeys) => void
    ) => void
    onCutEventWithSignal: (signal: TraceableAbortSignal, callback: (event: ClipboardEvent) => void) => void
    destroy: () => void
}

function uploadCXVTarget(event: 'copy' | 'paste' | 'cut', _target: EventTarget | null) {
    const desc = `WK_CLIPBOARD`
    if (_target && typeof _target === 'object') {
        const toLog = {
            scope: 'cxv-event',
            event,
            tagName: '<null>',
            testId: '<null>',
            className: '<null>',
        }

        const target = _target as Partial<HTMLElement>
        try {
            const tagName = target.tagName
            if (tagName) {
                toLog.tagName = tagName
            }
        } catch {}

        try {
            const testId = target.dataset?.testid
            if (testId) {
                toLog.testId = testId
            }
        } catch {}

        try {
            const className = target.className
            if (className) {
                toLog.className = className
            }
        } catch {}

        WkCLog.throttleLog(desc, toLog)
    } else {
        WkCLog.throttleLog(desc, {
            scope: 'cxv-event',
            exist: false,
        })
    }
}

class Dispatcher<T> {
    private _listeners: Set<(_event: T) => void> = new Set()
    private _destroyed = false

    subscribe(listener: (event: T) => void) {
        if (this._destroyed) {
            console.warn('dispatcher has been destroyed and cannot subscribe')
            return
        }

        this._listeners.add(listener)
        return () => {
            this._listeners.delete(listener)
        }
    }

    emit(event: T) {
        if (this._destroyed) {
            console.warn('dispatcher has been destroyed and cannot emit')
            return
        }

        for (const listener of [...this._listeners]) {
            listener(event)
        }
    }

    destroy() {
        this._destroyed = true
        this._listeners.clear()
    }
}

export class ClipboardEventHandler implements IClipboardEventHandler {
    private _pasteEventDispatcher: Dispatcher<ClipboardEventWithModifierKeys> = new Dispatcher()
    private _postCopyEventDispatcher: Dispatcher<ClipboardEvent> = new Dispatcher()
    private _postCutEventDispatcher: Dispatcher<ClipboardEvent> = new Dispatcher()
    private _hostUnsubscribers: Array<() => void> = []

    onPasteEventWithSignal(signal: TraceableAbortSignal, callback: (event: ClipboardEventWithModifierKeys) => void) {
        const { act } = transaction(signal)
        act('onPasteEventWithSignal', () => {
            return this._pasteEventDispatcher.subscribe(callback)
        })
    }
    onCopyEventWithSignal(signal: TraceableAbortSignal, callback: (event: ClipboardEvent) => void) {
        const { act } = transaction(signal)
        act('onCopyEventWithSignal', () => {
            return this._postCopyEventDispatcher.subscribe(callback)
        })
    }

    onCutEventWithSignal(signal: TraceableAbortSignal, callback: (event: ClipboardEvent) => void) {
        const { act } = transaction(signal)
        act('onCutEventWithSignal', () => {
            return this._postCutEventDispatcher.subscribe(callback)
        })
    }

    private modifierKeysDownStatus = {
        metaKey: false,
        shiftKey: false,
        altKey: false,
        ctrlKey: false,
    }
    private updateModifierKeysStatus = (event: KeyboardEvent) => {
        this.modifierKeysDownStatus.metaKey = event.metaKey
        this.modifierKeysDownStatus.shiftKey = event.shiftKey
        this.modifierKeysDownStatus.altKey = event.altKey
        this.modifierKeysDownStatus.ctrlKey = event.ctrlKey
    }

    constructor(private clipboardEventFilter: ClipboardEventFilter = () => true, private bridge: Bridge) {}

    registerHandlers() {
        const clipboardEventFilter = this.clipboardEventFilter

        const onPaste = (event: ClipboardEvent) => {
            uploadCXVTarget('paste', event.target)
            focusViewTraceLog('[ClipboardEventHandler] on paste', event.target)
            if (this.bridge.call(GetDocReadonlyCommand).value) {
                return
            }
            if (!shouldHandleClipboardEvent(event, clipboardEventFilter)) {
                return
            }
            const { clipboardData } = event
            if (!clipboardData) {
                return
            }
            if (event.clipboardData) {
                this._pasteEventDispatcher.emit({ event, ...this.modifierKeysDownStatus })
            } else {
                console.warn('[ClipboardEventHandler] not supported on paste event')
            }
            event.preventDefault()
        }

        const onCopy = (event: ClipboardEvent) => {
            uploadCXVTarget('copy', event.target)
            focusViewTraceLog('[ClipboardEventHandler] on copy', event.target)
            if (!shouldHandleClipboardEvent(event, clipboardEventFilter)) {
                return
            }
            if (event.clipboardData) {
                this._postCopyEventDispatcher.emit(event)
            } else {
                console.warn('[ClipboardEventHandler] not supported on copy event')
            }
            event.preventDefault()
        }

        const onCut = (event: ClipboardEvent) => {
            uploadCXVTarget('cut', event.target)
            focusViewTraceLog('[ClipboardEventHandler] on cut', event.target)
            if (this.bridge.call(GetDocReadonlyCommand).value) {
                return
            }
            if (!shouldHandleClipboardEvent(event, clipboardEventFilter)) {
                return
            }
            if (event.clipboardData) {
                this._postCutEventDispatcher.emit(event)
            } else {
                console.warn('[ClipboardEventHandler] not supported on cut event')
            }
            event.preventDefault()
        }

        this.addHostEventListener('paste', onPaste)
        this.addHostEventListener('copy', onCopy)
        this.addHostEventListener('cut', onCut)
        this.addHostEventListener('keydown', (e) => this.updateModifierKeysStatus(e))
        this.addHostEventListener('keyup', (e) => this.updateModifierKeysStatus(e))
    }

    destroy() {
        const hostUnsubscribers = this._hostUnsubscribers.splice(0, this._hostUnsubscribers.length)
        for (const unsubscribe of hostUnsubscribers) {
            unsubscribe()
        }

        this._pasteEventDispatcher.destroy()
        this._postCopyEventDispatcher.destroy()
        this._postCutEventDispatcher.destroy()
    }

    private addHostEventListener<E extends 'paste' | 'copy' | 'cut' | 'keydown' | 'keyup'>(
        name: E,
        handler: (ev: WindowEventMap[E]) => void
    ) {
        window.addEventListener(name, handler)

        this._hostUnsubscribers.push(() => {
            window.removeEventListener(name, handler)
        })
    }
}
