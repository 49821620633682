/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { TraceableAbortSignal } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { CommentId } from '../../../../kernel/interface/comment'
import { featureSwitchManager } from '../../../../kernel/switch'
import { DeepRequired, ViewStateBridge } from '../../../../view-state-bridge'
import { CameraPosition, ClusterId, CommentCluster, WorldPosition } from '../type'

type Viewport = DeepRequired<Wukong.DocumentProto.IViewport>
type AnimationNodePositionCallback = (viewport: Viewport) => void

export class AnimationService {
    private nextRegisterKey = 0

    // 当前所有的 cluster
    private curClusterMap: Map<string, CommentCluster> = new Map()
    // 当前被聚合的 comment 所在的 cluster
    private curCommentClusterMap: Map<CommentId, CommentCluster> = new Map()
    // 刚离开聚合的 comment 之前所在的 cluster
    private exitFromClusterMap: Map<CommentId, CommentCluster> = new Map()
    // 无缝进入新的聚合的 comment 之前所在的 cluster
    private lastEnterClusterMap: Map<CommentId, CommentCluster> = new Map()
    // 最新的 comment 和 cluster 的视窗位置
    private lastBubbleCameraPositionMap: Map<string, CameraPosition> = new Map()
    // 最新的 comment 和 cluster 的世界位置
    private lastBubbleWorldPositionMap: Map<string, WorldPosition> = new Map()
    // 当前在 viewport 范围内的 comment 和 cluster 位置
    private bubblesInsideViewportMap: Map<string, CameraPosition> = new Map()
    // 上一次在 viewport 范围内的 comment 和 cluster 位置
    private bubblesBeforeInsideViewportMap: Map<string, CameraPosition> = new Map()

    // 更新动画 dom 位置的回调函数
    private animationNodePositionCallbacks: Map<string, AnimationNodePositionCallback> = new Map()

    constructor(private readonly signal: TraceableAbortSignal, private readonly viewStateBridge: ViewStateBridge) {}

    public init = () => {
        this.viewStateBridge.register('commentsPosition', this.viewStateCommentsPositionCallback)
        this.viewStateBridge.register('currentViewport', this.viewStateCurrentViewportCallback)
        this.viewStateBridge.register('currentPageId', this.viewStateCurrentPageIdChangeCallback)
        this.viewStateBridge.register('showComments', this.viewStateShowCommentsChangeCallback)
        this.viewStateBridge.register('editorType', this.viewStateEditorTypeChangeCallback)
    }

    private getUniqueRegisterKey = () => {
        return `${this.nextRegisterKey++}`
    }

    private registerAnimationNodePosition(callback: AnimationNodePositionCallback) {
        const key = this.getUniqueRegisterKey()
        this.animationNodePositionCallbacks.set(key, callback)
        const viewport = this.viewStateBridge.getDefaultValue('currentViewport')
        if (viewport) {
            callback(viewport)
        }
        return key
    }

    private unregisterAnimationNodePosition(key: string) {
        this.animationNodePositionCallbacks.delete(key)
    }

    private transCameraPositionToWorldPosition = (cameraPos: CameraPosition): WorldPosition => {
        const viewport = this.viewStateBridge.getDefaultValue('currentViewport')
        if (!viewport) {
            return { worldX: cameraPos.cameraX, worldY: cameraPos.cameraY }
        }
        return { worldX: cameraPos.cameraX / viewport.zoom, worldY: cameraPos.cameraY / viewport.zoom }
    }

    private transWolrdPositionToCameraPosition = (worldPos: WorldPosition): CameraPosition => {
        const viewport = this.viewStateBridge.getDefaultValue('currentViewport')
        if (!viewport) {
            return { cameraX: worldPos.worldX, cameraY: worldPos.worldY }
        }
        return { cameraX: worldPos.worldX * viewport.zoom, cameraY: worldPos.worldY * viewport.zoom }
    }

    public isVisableInViewport = (commentId: CommentId | ClusterId): boolean => {
        return !!this.bubblesInsideViewportMap.get(`${commentId}`)
    }

    private computeContainedInViewport = (cameraPosition: CameraPosition): boolean => {
        const { cameraX: x, cameraY: y } = cameraPosition
        const viewport = this.viewStateBridge.getDefaultValue('visibleViewport')
        const left = viewport.x * viewport.zoom
        const top = viewport.y * viewport.zoom
        const right = left + viewport.width
        const bottom = top + viewport.height

        const gap = 128
        return x >= left - gap && y >= top - gap && x <= right + gap && y <= bottom + gap
    }

    public removeCommentByEnterCluster = async (ref: HTMLElement, commentId: CommentId) => {
        if (!isAnimationEnabled()) {
            return null
        }

        const commentWorldosition = this.lastBubbleWorldPositionMap.get(`${commentId}`)
        if (!commentWorldosition) {
            return null
        }

        const targetCluster = this.curCommentClusterMap.get(commentId)
        if (!targetCluster) {
            // 不存在当前所在的 cluster 则说明被销毁的原因不是由于进入 cluster
            return null
        }

        // 拷贝一份 dom 进行动画
        const animationNode = cloneAnimationDOM(ref)
        ref.style.opacity = '0'

        // 注册当 viewport 的 zoom 变更时，更新动画节点
        const clusterWorldPosition = this.lastBubbleWorldPositionMap.get(`${targetCluster.clusterId}`)!
        const key = this.registerAnimationNodePosition((viewport) => {
            if (!animationNode.parentNode) {
                key && this.unregisterAnimationNodePosition(key)
                return
            }
            animationNode.setAttribute(
                'style',
                `left:${clusterWorldPosition.worldX * viewport.zoom}px;top:${
                    clusterWorldPosition.worldY * viewport.zoom
                }px;`
            )
        })

        // 该 comment 由于被聚合进 cluster，从而当前帧的 commentsPosition 不会更新该 comment 的最新位置
        // 固根据其世界位置，计算 comment 在当前 viewport 下应在的位置
        const commentCameraPosition = this.transWolrdPositionToCameraPosition(commentWorldosition)
        const animation = await animateDirectRemove(animationNode, commentCameraPosition, {
            cameraX: targetCluster.overlayPosition.x,
            cameraY: targetCluster.overlayPosition.y,
        })

        animationNode.remove()
        this.unregisterAnimationNodePosition(key)

        return animation
    }

    public createCommentByExitCluster = async (ref: HTMLElement, commentId: CommentId) => {
        if (!isAnimationEnabled()) {
            return null
        }

        if (
            featureSwitchManager.isEnabled('comment-perf-viewport-filter') &&
            !this.bubblesBeforeInsideViewportMap.get(`${commentId}`)
        ) {
            // 由「只渲染 viewport 可视范围内的气泡」策略创建的评论无动画
            return null
        }

        const commentCameraPosition = this.lastBubbleCameraPositionMap.get(`${commentId}`)
        if (!commentCameraPosition) {
            return null
        }

        const lastCluster = this.exitFromClusterMap.get(commentId)
        if (!lastCluster) {
            // 没有上次进入的 cluster，说明是刚刚创建的 comment
            return null
        }

        // 由于 lastCluster 已经被删除，在当前帧不会被 commentsPosition 更新
        // 固根据其世界位置，计算 lastCluster 在当前 viewport 下应在的位置
        const lastClusterWorldPosition = this.lastBubbleWorldPositionMap.get(`${lastCluster.clusterId}`)!
        const lastClusterCameraPosition = this.transWolrdPositionToCameraPosition(lastClusterWorldPosition)

        const animation = await animateDirectCreate(ref, lastClusterCameraPosition, commentCameraPosition)
        return animation
    }

    public createCommentByExitClusterV2 = async (ref: HTMLElement, commentId: CommentId) => {
        if (!isAnimationEnabled()) {
            return
        }

        if (
            featureSwitchManager.isEnabled('comment-perf-viewport-filter') &&
            !this.bubblesBeforeInsideViewportMap.get(`${commentId}`)
        ) {
            // 由「只渲染 viewport 可视范围内的气泡」策略创建的评论无动画
            return
        }

        const lastCluster = this.exitFromClusterMap.get(commentId)
        if (!lastCluster) {
            // 没有上次进入的 cluster，说明是刚刚创建的 comment
            return
        }

        // 由于 lastCluster 已经被删除，在当前帧不会被 commentsPosition 更新
        // lastCluster 的世界坐标即为起点
        const lastClusterWorldPosition = this.lastBubbleWorldPositionMap.get(`${lastCluster.clusterId}`)!

        runDynamicStartEndAnimation(
            this.signal,
            ref,
            () => this.transWolrdPositionToCameraPosition(lastClusterWorldPosition),
            () => this.lastBubbleCameraPositionMap.get(`${commentId}`),
            0.2,
            1
        )
    }

    public createClusterByExitCluster = async (ref: HTMLElement, clusterId: string) => {
        if (!isAnimationEnabled()) {
            return null
        }

        const curClusterCameraPosition = this.lastBubbleCameraPositionMap.get(`${clusterId}`)
        if (!curClusterCameraPosition) {
            return null
        }

        const cluster = this.curClusterMap.get(clusterId)
        if (!cluster) {
            // 不存在该 cluster，无动画
            return null
        }

        const fromCluster = this.getOriginCluster(cluster)
        if (!fromCluster) {
            // 没有 cluster 的来源位置信息，则直接创建
            return null
        }

        // 由于 fromCluster 已经被删除，在当前帧不会被 commentsPosition 更新
        // 固根据其不变的世界位置，计算 fromCluster 在当前 viewport 下应在的位置
        const fromClusterWorldPosition = this.lastBubbleWorldPositionMap.get(`${fromCluster.clusterId}`)!
        const fromClusterCameraPosition = this.transWolrdPositionToCameraPosition(fromClusterWorldPosition)
        const animation = await animateDirectCreate(
            ref,
            { cameraX: fromClusterCameraPosition.cameraX - 32, cameraY: fromClusterCameraPosition.cameraY - 32 },
            curClusterCameraPosition
        )
        return animation
    }

    public createClusterByExitClusterV2 = async (ref: HTMLElement, clusterId: string) => {
        if (!isAnimationEnabled()) {
            return
        }

        const cluster = this.curClusterMap.get(clusterId)
        if (!cluster) {
            // 不存在该 cluster，无动画
            return
        }

        const fromCluster = this.getOriginCluster(cluster)
        if (!fromCluster) {
            // 没有 cluster 的来源位置信息，则直接创建
            return
        }

        // 由于 fromCluster 已经被删除，在当前帧不会被 commentsPosition 更新
        // fromCluster 的世界坐标为起始位置
        const fromClusterWorldPosition = this.lastBubbleWorldPositionMap.get(`${fromCluster.clusterId}`)!

        runDynamicStartEndAnimation(
            this.signal,
            ref,
            () => {
                const fromClusterCameraPosition = this.transWolrdPositionToCameraPosition(fromClusterWorldPosition)
                return {
                    cameraX: fromClusterCameraPosition.cameraX - 32,
                    cameraY: fromClusterCameraPosition.cameraY - 32,
                }
            },
            () => {
                const curClusterCameraPosition = this.lastBubbleCameraPositionMap.get(`${cluster.clusterId}`)
                if (!curClusterCameraPosition) {
                    return null
                }
                return {
                    cameraX: curClusterCameraPosition.cameraX - 32,
                    cameraY: curClusterCameraPosition.cameraY - 32,
                }
            },
            0.2,
            1
        )
    }

    public destroy = () => {
        this.viewStateBridge.unregister('commentsPosition', this.viewStateCommentsPositionCallback)
        this.viewStateBridge.unregister('currentViewport', this.viewStateCurrentViewportCallback)
        this.viewStateBridge.unregister('currentPageId', this.viewStateCurrentPageIdChangeCallback)
        this.viewStateBridge.unregister('showComments', this.viewStateShowCommentsChangeCallback)
        this.viewStateBridge.unregister('editorType', this.viewStateEditorTypeChangeCallback)
    }

    // 计算一个 cluster 的来源 cluster，用于 cluster 动画
    private getOriginCluster(cluster: CommentCluster): CommentCluster | undefined {
        const firstCluster = this.lastEnterClusterMap.get(Number(cluster.commentIds[0]))
        if (cluster.commentIds.length == firstCluster?.commentIds.length) {
            return undefined
        }
        let isAllPositionSame = true
        // 仅当包含的所有评论都来源于同一个位置，才认定该 cluster 来源于该位置
        for (const commentId of cluster.commentIds) {
            const prevCluster = this.lastEnterClusterMap.get(Number(commentId))

            if (!firstCluster && prevCluster) {
                isAllPositionSame = false
                break
            } else if (!firstCluster && !prevCluster) {
                // 均为 undefined
                continue
            }

            if (firstCluster!.clusterId !== prevCluster?.clusterId) {
                isAllPositionSame = false
                break
            }
        }

        return isAllPositionSame ? firstCluster : undefined
    }

    private viewStateCommentsPositionCallback = (
        overlayPosition: Wukong.DocumentProto.IVCommentsPositionViewState | null
    ) => {
        const pins = overlayPosition?.pins
        const clusters = overlayPosition?.clusters

        const newBubblesInsideViewportMap = new Map<string, CameraPosition>()
        for (const [commentId, position] of Object.entries(pins ?? {})) {
            const cameraPosition = {
                cameraX: position.translateX ?? 0,
                cameraY: position.translateY ?? 0,
            }
            if (this.computeContainedInViewport(cameraPosition)) {
                newBubblesInsideViewportMap.set(commentId, cameraPosition)
            }
            // 记录游离评论的视窗位置
            this.lastBubbleCameraPositionMap.set(commentId, cameraPosition)
            // 记录游离评论的世界位置
            this.lastBubbleWorldPositionMap.set(commentId, this.transCameraPositionToWorldPosition(cameraPosition))
            const exitFromCluster = this.curCommentClusterMap.get(Number(commentId))
            if (exitFromCluster) {
                // 记录刚离开 cluster 的评论之前所在的 cluster
                this.exitFromClusterMap.set(Number(commentId), exitFromCluster)
            }
        }

        const newCurCommentClusterMap = new Map<CommentId, CommentCluster>()
        for (const [clusterId, cluster] of Object.entries(clusters ?? {})) {
            this.curClusterMap.set(clusterId, formatCommentCluster(cluster))
            const cameraPosition = {
                cameraX: cluster.cameraX ?? 0,
                cameraY: cluster.cameraY ?? 0,
            }
            if (this.computeContainedInViewport(cameraPosition)) {
                newBubblesInsideViewportMap.set(clusterId, cameraPosition)
                cluster.commentIds?.forEach((commentId: string) => {
                    newBubblesInsideViewportMap.set(commentId, cameraPosition)
                })
            }
            // 记录 cluster 的视窗位置
            this.lastBubbleCameraPositionMap.set(clusterId, cameraPosition)
            // 记录 cluster 的世界位置
            this.lastBubbleWorldPositionMap.set(clusterId, this.transCameraPositionToWorldPosition(cameraPosition))

            cluster.commentIds?.forEach((commentId: string) => {
                const lastEnterCluster = this.curCommentClusterMap.get(Number(commentId))
                // 记录上一帧所在的 cluster
                if (!lastEnterCluster) {
                    this.lastEnterClusterMap.delete(Number(commentId))
                } else {
                    this.lastEnterClusterMap.set(Number(commentId), lastEnterCluster)
                }

                newCurCommentClusterMap.set(Number(commentId), formatCommentCluster(cluster))
            })
        }
        this.curCommentClusterMap = newCurCommentClusterMap
        this.bubblesBeforeInsideViewportMap = this.bubblesInsideViewportMap
        this.bubblesInsideViewportMap = newBubblesInsideViewportMap
    }

    private viewStateCurrentViewportCallback = (viewport: Viewport) => {
        for (const callback of this.animationNodePositionCallbacks.values()) {
            callback(viewport)
        }
    }

    // 清理动画缓存状态，使得下次渲染评论时不会触发动画
    private clearAnimationState = () => {
        this.exitFromClusterMap.clear()
        this.lastEnterClusterMap.clear()
        this.bubblesBeforeInsideViewportMap.clear()
    }

    private viewStateCurrentPageIdChangeCallback = () => {
        this.clearAnimationState()
    }

    private viewStateShowCommentsChangeCallback = () => {
        this.clearAnimationState()
    }

    private viewStateEditorTypeChangeCallback = () => {
        this.clearAnimationState()
    }
}

const formatCommentCluster = (cluster: Wukong.DocumentProto.ICommentCluster): CommentCluster => {
    return {
        clusterId: cluster.clusterId ?? '',
        commentIds: cluster.commentIds ? cluster.commentIds.map((v) => Number(v)) : [],
        overlayPosition: {
            x: cluster.cameraX ?? 0,
            y: cluster.cameraY ?? 0,
        },
    }
}

export const cloneAnimationDOM = (ref: HTMLElement) => {
    const cloneNode = ref.cloneNode(true) as HTMLElement
    ref.parentNode?.appendChild(cloneNode)
    cloneNode.style.pointerEvents = `none` // 禁止鼠标事件
    return cloneNode
}

const ANIMATION_DURATION = 200

// start -> end: scale(1) -> scale(0.2)
const animateDirectRemove = (
    element: HTMLElement,
    startPosition: CameraPosition,
    endPosition: CameraPosition
): Promise<Animation | null> => {
    const dertaPosition = {
        x: startPosition.cameraX - endPosition.cameraX,
        y: startPosition.cameraY - endPosition.cameraY,
    }
    const keyframes = [
        { transform: `translate(${dertaPosition.x}px, ${dertaPosition.y}px) scale(1)`, offset: 0 },
        { transform: `scale(0.2)`, offset: 1 },
    ]
    return startAnimation(element, keyframes)
}

// start -> end: scale(0.2) -> scale(1)
const animateDirectCreate = (
    element: HTMLElement,
    startPosition: CameraPosition,
    endPosition: CameraPosition
): Promise<Animation | null> => {
    const dertaPosition = {
        x: startPosition.cameraX - endPosition.cameraX,
        y: startPosition.cameraY - endPosition.cameraY,
    }

    const keyframes = [{ transform: `translate(${dertaPosition.x}px, ${dertaPosition.y}px) scale(0.2)`, offset: 0 }]
    return startAnimation(element, keyframes)
}

// 开启一个起点和终点会变化的动画（如打散过程中，评论的起点和终点位置可能会随着缩放或拖拽绑定图层而变动）
const runDynamicStartEndAnimation = (
    signal: TraceableAbortSignal,
    element: HTMLElement,
    getStartPosition: () => CameraPosition,
    getEndPosition: () => CameraPosition | null | undefined,
    startScale: number,
    endScale: number
) => {
    const initStartPosition = getStartPosition()
    element.style.transform = `translate(${initStartPosition.cameraX}px, ${initStartPosition.cameraY}px) scale(${startScale})`

    let startTime: number | null = null
    let animationTimer: number | null = null

    const runAnimation = () => {
        const timestamp = performance.now()
        if (startTime === null) {
            startTime = timestamp
        }

        const endPosition = getEndPosition()
        if (!endPosition || !element.parentNode || signal.aborted) {
            // 不存在则说明已经被删除了，直接结束动画
            animationTimer && cancelAnimationFrame(animationTimer)
            return
        }

        const elapsedTime = timestamp - startTime
        const process = Math.min(1, elapsedTime / ANIMATION_DURATION)

        const startPosition = getStartPosition()
        const translateX = startPosition.cameraX * (1 - process) + process * endPosition.cameraX
        const translateY = startPosition.cameraY * (1 - process) + process * endPosition.cameraY
        const scale = startScale + (endScale - startScale) * process
        // 根据 process 从起点偏移至终点
        const transformValue = `translate(${translateX}px, ${translateY}px) scale(${scale.toFixed(2)})`
        element.style.transform = transformValue

        if (elapsedTime >= ANIMATION_DURATION) {
            return
        }
        animationTimer = requestAnimationFrame(runAnimation)
    }

    runAnimation()
}

const startAnimation = (
    element: HTMLElement,
    keyframes: Keyframe[] | PropertyIndexedKeyframes | null
): Promise<Animation | null> => {
    if (!element || !isAnimationEnabled()) {
        return Promise.resolve(null)
    }

    return new Promise<Animation>((resolve, reject) => {
        const animation = element.animate(keyframes, {
            duration: ANIMATION_DURATION,
        })
        animation.onfinish = () => {
            resolve(animation)
        }
        animation.oncancel = () => {
            reject(animation)
        }
    })
}

const isAnimationEnabled = () => {
    return typeof Element.prototype.animate == 'function' && typeof Element.prototype.getAnimations == 'function'
}
