import React from 'react'

export interface BadgeBaseProps extends React.HTMLAttributes<HTMLDivElement> {
    center?: { top: number | string; left: number | string } // 指定中心点的位置。以top、left表示
    isHide?: boolean
    dataTestIds?: { root?: string }
}

export function BadgeBase(props: BadgeBaseProps) {
    const { center, isHide, dataTestIds, style, ...otherProps } = props
    const centerStyle = center
        ? { ...center, right: undefined, bottom: undefined, transform: 'translate(-50%,-50%)' }
        : undefined
    return (
        <span
            data-testid={dataTestIds?.root}
            style={{
                ...style,
                ...centerStyle,
                position: 'absolute',
                pointerEvents: 'none',
                display: isHide ? 'none' : undefined,
            }}
            {...otherProps}
        >
            {props.children}
        </span>
    )
}
