import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Cancel: '取消',
    CancelAccount: '注销账号',
    PasswordError: '密码错误',
    PleaseInputPassword: '请输入密码进行安全验证。',
    InputPassword: '输入密码',
    ForgetPassword: '忘记密码？',
    Reset: '点此重置',
    Confirm: '继续',
    NetworkError: '当前网络不可用，请检查网络设置',
} as const

export const enTranslation = {
    Cancel: 'Cancel',
    CancelAccount: 'Delete account',
    PasswordError: 'Incorrect password',
    PleaseInputPassword: 'Enter your password for security verification.',
    InputPassword: 'Current password',
    ForgetPassword: '',
    Reset: 'Forgot password?',
    Confirm: 'Continue',
    NetworkError: 'Network unavailable, please check your network settings',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
