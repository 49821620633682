import { useEffect, useMemo } from 'react'
import { IconCloud, Tooltip } from '../../../../../../ui-lib/src'
import { useSynergySyncStatusService } from '../../../../main/app-context'
import { useInHistoryWasm } from '../../../../main/history-mode/context/history-mode-context'
import { useViewState } from '../../../../view-state-bridge'
import { EditorDataTestId } from '../../../../window/wk-data-test'
import { Switch } from '../../atom/switch/switch'
import classes from './tool-sync-status.module.less'
import { translation } from './tool-sync-status.translation'

export function ToolSyncStatus() {
    const synergySyncStatusService = useSynergySyncStatusService()
    const showOfflineIcon = synergySyncStatusService.states.use.showOfflineIconState()
    const docReadonly = useViewState('docReadonly')
    const tooltipTitle = useMemo(
        () => (docReadonly ? translation('NetworkUnavailable') : translation('OfflineChangesWill')),
        [docReadonly]
    )
    const { inHistoryWasm } = useInHistoryWasm()

    useEffect(() => {
        synergySyncStatusService.updateInHistoryWasm(inHistoryWasm)
    }, [inHistoryWasm, synergySyncStatusService])

    return showOfflineIcon ? (
        <Tooltip title={tooltipTitle}>
            <Switch
                style={{
                    marginRight: 10,
                }}
                className={classes.offlineIcon}
                data-testid={EditorDataTestId.Sync.OfflineIcon}
            >
                <IconCloud />
            </Switch>
        </Tooltip>
    ) : (
        <></>
    )
}
