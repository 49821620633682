import { DropdownNoTriggerMultiLevelProps, DropdownV2, WKMenuConfig } from '../../../../../../../ui-lib/src'
import { MenuItemRender } from './menu-item-render'
import { MainMenuItemCustomNode } from './type'
import { useMenu } from './use-menu'
import { transformMenuConfig } from './utils'

export interface MenuRightClickV2Props {
    config: WKMenuConfig
    position: { top: number; left: number }
    forwardIconClassName?: string
    dataTestIds?: DropdownNoTriggerMultiLevelProps<MainMenuItemCustomNode>['dataTestIds']
    onClose: () => void
}
export function MenuRightClickV2(props: MenuRightClickV2Props) {
    const { onChange } = useMenu()

    return (
        <DropdownV2.NoTriggerMultiLevel
            triggerRect={{
                left: props.position.left,
                right: props.position.left,
                top: props.position.top,
                bottom: props.position.top,
            }}
            onClose={props.onClose}
            isOpenState={true}
            items={transformMenuConfig(props.config, props)}
            renderItem={MenuItemRender}
            onChange={onChange}
            dataTestIds={{
                ...props?.dataTestIds,
                captureKeyboard: props?.dataTestIds?.captureKeyboard ?? 'menu-right-click-item-container',
            }}
        />
    )
}
