/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { escapeRegExp, isNil, omit } from 'lodash-es'
import type { HTMLAttributes } from 'react'
import { Children, cloneElement, forwardRef, isValidElement, useCallback } from 'react'
import { TabElement, Tooltip } from '../../../../../../ui-lib/src'
import style from './switch.module.less'

type Div = HTMLAttributes<HTMLDivElement>
type Key = string | number

interface SwitchProps extends Div {
    active?: Key
    onSwitchChange?: (key: Key) => void
}
const forwardSuffix = '_switch_key_'
function _Switch(props: SwitchProps, ref?: React.Ref<HTMLDivElement>) {
    const { active, className, children, onSwitchChange } = props
    const otherProps = omit(props, 'onChange', 'className', 'active', 'onSwitchChange')

    return (
        <div ref={ref} {...otherProps} className={`${style.switch} ${className ?? ''}`}>
            {Children.map(children, (child) => {
                if (isValidElement(child) && child.type === SwitchItem) {
                    const reactKey = child?.key ?? `${forwardSuffix}${Math.round(Math.random() * 1e8)}`
                    // @ts-expect-error
                    return cloneElement(child, { active, reactKey, onSwitchChange })
                }
                return child
            })}
        </div>
    )
}

interface SwitchItemProps extends Pick<SwitchProps, 'active' | 'onSwitchChange'>, Div {
    reactKey?: Key
    testid?: string
    activeClassName?: string
    tooltipTitle?: string
    tooltipShortcut?: string
    useTabFocus?: boolean
    onKeyboardClick?: (e: React.MouseEvent<HTMLElement>) => void
}
export function SwitchItem(props: SwitchItemProps) {
    const {
        className,
        active,
        activeClassName,
        onMouseDown,
        onSwitchChange,
        reactKey,
        tooltipTitle,
        tooltipShortcut,
        useTabFocus,
        onKeyboardClick,
        ...otherProps
    } = props
    const isValidKey = useCallback((key?: Key) => {
        if (isNil(key)) {
            return false
        }
        const regexp = new RegExp(`^${escapeRegExp(forwardSuffix)}`)
        return !regexp.test(String(key))
    }, [])
    const _onMouseDown = useCallback(
        (e: any) => {
            onMouseDown?.(e)
            if (isValidKey(reactKey)) {
                onSwitchChange?.(reactKey!)
            }
        },
        [onMouseDown, onSwitchChange, isValidKey, reactKey]
    )
    const isActive = !isNil(reactKey) && (active === reactKey || String(active) === String(reactKey))
    const render = () => {
        return (
            <div
                data-testid={props.testid}
                onMouseDown={_onMouseDown}
                className={classnames(
                    style.switch_item,
                    style.focus,
                    {
                        [activeClassName ?? style.switch_item_active]: isActive,
                    },
                    className
                )}
                {...otherProps}
            >
                {useTabFocus ?? isValidKey(reactKey) ? (
                    <TabElement
                        as="button"
                        onClick={(e) => {
                            onSwitchChange?.(reactKey!)
                            onKeyboardClick?.(e)
                        }}
                    />
                ) : null}
                {props.children}
            </div>
        )
    }
    return tooltipTitle ? (
        <Tooltip title={tooltipTitle} shortcut={tooltipShortcut}>
            {render()}
        </Tooltip>
    ) : (
        render()
    )
}

const SwitchRef = forwardRef(_Switch)
const Switch: typeof SwitchRef & { Item: typeof SwitchItem } = Object.assign(SwitchRef, { Item: SwitchItem })

export { Switch }
