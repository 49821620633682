export enum CLOGENV {
    WS = 'ws',
    COM = 'com',
    BIZ = 'biz',
}
export enum CLOGCATEGORY {
    WUKONG = 10,
}

export enum CLOGHOST {
    CLOG = 'clog',
}
