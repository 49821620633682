import { useSet } from 'ccstate-react'
import { useRef } from 'react'
import { useEffectOnce } from 'react-use'
import { appendCanvasToReact$ } from '../../external-store/atoms/create-canvas'
import { AppLayoutWrapper } from '../layout/layout-context'
import { focusViewManager } from '../service/focus-view/focus-view-manager'

export function CanvasContainer() {
    const appendCanvasToReact = useSet(appendCanvasToReact$)

    const canvasContainerRef = useRef<HTMLDivElement>(null)

    useEffectOnce(() => {
        const containerRef = canvasContainerRef.current
        if (containerRef) {
            const cleanup = appendCanvasToReact(containerRef)

            return cleanup
        }
    })

    return (
        <AppLayoutWrapper>
            <div ref={canvasContainerRef} style={{ position: 'relative', display: 'inline' }}>
                <input
                    tabIndex={-1}
                    role="application"
                    readOnly
                    style={{ opacity: 0, top: -200, position: 'fixed' }}
                    ref={focusViewManager.bindCanvasFocusedInputElement}
                    data-testid="canvas-focused-input"
                ></input>
            </div>
        </AppLayoutWrapper>
    )
}
