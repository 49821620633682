import { FontInfo, FontName, TextNode, TextRange } from '../node/node'

// 仅在 design-panel 中用到, 等 design-panel 逻辑迁移 wasm 后可删掉
export function isInsertText(selectedTextRange: TextRange | null | undefined) {
    return selectedTextRange && selectedTextRange.start === selectedTextRange.end
}

// 仅在 design-panel 中用到, 等 design-panel 逻辑迁移 wasm 后可删掉
export function getTextNodeSelectRange(textNode: TextNode, selectedTextRange: TextRange | null | undefined) {
    const { characters } = textNode
    let start = 0,
        end = characters.length

    if (selectedTextRange && selectedTextRange.start !== selectedTextRange.end) {
        start = selectedTextRange.start
        end = selectedTextRange.end
    }
    return { start, end, isFullText: start === 0 && end === characters.length }
}

export function switchFamily(originFontName: FontName | undefined, targetFontInfo: FontInfo): FontName | undefined {
    const canUseFontNames = targetFontInfo.styles
    if (!originFontName) {
        return canUseFontNames[0]
    }

    let maxScore = 0
    let maxIndex = 0

    for (let i = 0; i < canUseFontNames.length; ++i) {
        let curScore = 0
        const curFontName = canUseFontNames[i]

        curScore += 10 - Math.abs(curFontName.width - originFontName.width)
        curScore <<= 2

        if (originFontName.italic == curFontName.italic) {
            curScore += 1
        }
        curScore <<= 12

        curScore += 1000 - Math.abs(curFontName.weight - originFontName.weight)
        curScore <<= 2

        if (originFontName.style == curFontName.style) {
            curScore += 1
        }

        if (maxScore < curScore) {
            maxScore = curScore
            maxIndex = i
        }
    }

    return canUseFontNames[maxIndex]
}
