import {
    ApplyCreateStyle,
    CancelCreateStyle,
    CreatePaintStyleNodeCommand,
    GetLatestPopupStateCommand,
    GetSelectionNodeIdsCommandForWasm,
    StartEditingGradientCommand,
    StartEditingImageCommand,
    UpdatePaintStyleNodeNameCommand,
    Wukong,
} from '@wukong/bridge-proto'
import classnames from 'classnames'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { DraggablePopupV2, WKTabsV2 } from '../../../../../../../ui-lib/src'
import { CommitType } from '../../../../../document/command/commit-type'
import { cmdChangeAttrPanelStyleEditorState } from '../../../../../document/command/node-props-command'
import { Paint } from '../../../../../document/node/node'
import { ToKeyCode } from '../../../../../document/util/keycode'
import { KeyboardReceiver } from '../../../../../main/keyboard-receiver/component'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'
import { rgb2hex } from '../../blend/color-picker/utils/color-translate'
import { useEditingPaintStyle } from '../../styles/hooks/use-editing-style'
import { CommentCreateButton } from '../../styles/style-panel/common-create-button/comment-create-button'
import { CommentJumpLink } from '../../styles/style-panel/common-jump-link/comment-jump-link'
import { createCutPaintStyle } from '../../styles/style-panel/utils'
import { ColorStyleEditor } from '../color-style/color-style-editor'
import { ColorVariableCreate } from '../color-variable/color-variable-create'
import { ColorInteractionFrom, ColorSetProps } from '../type'
import classes from './color-set.module.less'
import { translation } from './color-set.translation'

enum ColorSetTabKey {
    NONE = 'none',
    STYLE = 'style',
    VARIABLE = 'variable',
}

function getDefaultActiveTitle(props: ColorSetProps) {
    if (props.hideStyleTab && props.hideVariableTab) {
        return ColorSetTabKey.NONE
    }
    if (props.hideVariableTab) {
        return ColorSetTabKey.STYLE
    } else if (props.hideStyleTab) {
        return ColorSetTabKey.VARIABLE
    }
    if (props.paints.length === 1 && props.paints[0].type === Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT) {
        return ColorSetTabKey.VARIABLE
    }
    return ColorSetTabKey.STYLE
}

export function ColorSet(props: ColorSetProps) {
    const { onCreateStyle, onCancel } = props
    const attrPanelStyleEditorState = useViewState('attrPanelStyleEditorState')
    const selectedGradientColorStopIndex = useViewState('selectedGradientColorStopIndex')
    const popupState = useViewState('popupState')
    const paintStyleNode = useEditingPaintStyle() as Wukong.DocumentProto.IVPaintStyleNode | null
    const command = useCommand()
    const [activeKey, setActiveKey] = useState<ColorSetTabKey>(getDefaultActiveTitle(props))

    const openStyleEditor = useMemo(() => {
        return !!attrPanelStyleEditorState?.editingStyleId && !!paintStyleNode?.node
    }, [attrPanelStyleEditorState?.editingStyleId, paintStyleNode?.node])

    const tryResetFromCreateStyleRef = useRef<(_editingStyleId: string) => void>()
    tryResetFromCreateStyleRef.current = (editingStyleId: string) => {
        // 创建样式会绑定样式id,导致可能得渐变态消失。这里尝试恢复
        const nodeIds = command.invokeBridge(CommitType.Noop, GetSelectionNodeIdsCommandForWasm).value ?? []
        const index =
            popupState?.type === Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_FILLS ||
            popupState?.type === Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_STROKES
                ? popupState?.reciprocalIndex ?? NaN
                : props.paints.length === 1
                ? 0
                : NaN
        const paint = props.paints[index]
        if (paint) {
            if (paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_IMAGE_PAINT) {
                const type =
                    props.from === ColorInteractionFrom.FILL_PAINT || props.from === ColorInteractionFrom.FILL
                        ? Wukong.DocumentProto.EditingPaintType.EDITING_PAINT_TYPE_FILL_STYLE
                        : props.from === ColorInteractionFrom.STROKE_PAINT || props.from === ColorInteractionFrom.STROKE
                        ? Wukong.DocumentProto.EditingPaintType.EDITING_PAINT_TYPE_STROKE_STYLE
                        : props.from === ColorInteractionFrom.SELECTION_COLOR_FAKE_STYLE ||
                          props.from === ColorInteractionFrom.SELECTION_COLOR
                        ? Wukong.DocumentProto.EditingPaintType.EDITING_PAINT_TYPE_SELECT_COLOR_STYLE
                        : Wukong.DocumentProto.EditingPaintType.EDITING_PAINT_TYPE_LOCAL_STYLE
                command.invokeBridge(CommitType.Noop, StartEditingImageCommand, {
                    editingStyleId,
                    index,
                    nodeIds,
                    type,
                    notModifyPopupState: true,
                })
            } else if (
                paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_ANGULAR ||
                paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_LINEAR ||
                paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_RADIAL ||
                paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_DIAMOND
            ) {
                command.invokeBridge(CommitType.Noop, StartEditingGradientCommand, {
                    gradientNodeId: editingStyleId,
                    positions: [
                        {
                            type: Wukong.DocumentProto.PaintPositionType.PAINT_POSITION_TYPE_STYLE,
                            index,
                        },
                    ],
                    selectedGradientColorStopIndex: selectedGradientColorStopIndex,
                })
            }
        }
    }

    const tryResetFromCancelRef = useRef<() => void>()
    tryResetFromCancelRef.current = () => {
        const latestPopupState = command.invokeBridge(CommitType.Noop, GetLatestPopupStateCommand).value
        if (latestPopupState.type === Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_NONE) {
            return
        }
        // 取消创建样式解绑 样式id,导致可能得渐变态消失。这里尝试恢复
        const nodeIds = command.invokeBridge(CommitType.Noop, GetSelectionNodeIdsCommandForWasm).value ?? []
        const index =
            popupState?.type === Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_FILLS ||
            popupState?.type === Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_STROKES
                ? popupState?.reciprocalIndex ?? NaN
                : props.paints.length === 1
                ? 0
                : NaN
        const paint = props.paints[index]
        if (paint) {
            if (paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_IMAGE_PAINT) {
                const type =
                    props.from === ColorInteractionFrom.FILL_PAINT || props.from === ColorInteractionFrom.FILL
                        ? Wukong.DocumentProto.EditingPaintType.EDITING_PAINT_TYPE_FILL
                        : props.from === ColorInteractionFrom.STROKE_PAINT || props.from === ColorInteractionFrom.STROKE
                        ? Wukong.DocumentProto.EditingPaintType.EDITING_PAINT_TYPE_STROKE
                        : undefined
                type !== undefined &&
                    command.invokeBridge(CommitType.Noop, StartEditingImageCommand, {
                        index,
                        nodeIds,
                        type,
                        notModifyPopupState: true,
                    })
            } else if (
                paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_ANGULAR ||
                paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_LINEAR ||
                paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_RADIAL ||
                paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_DIAMOND
            ) {
                const type =
                    props.from === ColorInteractionFrom.FILL_PAINT || props.from === ColorInteractionFrom.FILL
                        ? Wukong.DocumentProto.PaintPositionType.PAINT_POSITION_TYPE_FILL
                        : props.from === ColorInteractionFrom.STROKE_PAINT || props.from === ColorInteractionFrom.STROKE
                        ? Wukong.DocumentProto.PaintPositionType.PAINT_POSITION_TYPE_STROKE
                        : undefined
                type !== undefined &&
                    command.invokeBridge(CommitType.Noop, StartEditingGradientCommand, {
                        gradientNodeId: nodeIds[0],
                        positions: [{ type, index: index }],
                        selectedGradientColorStopIndex: selectedGradientColorStopIndex,
                    })
            }
        }
    }

    const _onCancel = useCallback(() => {
        onCancel?.()
        if (!props.hideStyleTab) {
            command.invoke(cmdChangeAttrPanelStyleEditorState, {
                editingStyleId: '',
                openFromModule: attrPanelStyleEditorState?.openFromModule,
            })
        }
    }, [attrPanelStyleEditorState?.openFromModule, command, onCancel, props.hideStyleTab])

    const effectCreateStyle = useCallback(
        (styleId: string) => {
            onCreateStyle?.(styleId)
            const passIds = command.invokeBridge(CommitType.Noop, GetSelectionNodeIdsCommandForWasm).value ?? []
            command.invoke(cmdChangeAttrPanelStyleEditorState, {
                editingStyleId: styleId,
                openFromModule:
                    attrPanelStyleEditorState?.openFromModule ===
                        Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_FILL ||
                    attrPanelStyleEditorState?.openFromModule ===
                        Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_STROKE ||
                    attrPanelStyleEditorState?.openFromModule ===
                        Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_SELECT_COLOR
                        ? attrPanelStyleEditorState?.openFromModule
                        : Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_FILL,
                openFromNodeId: passIds,
                isCreate: true,
            })
            tryResetFromCreateStyleRef.current?.(styleId)
        },
        [attrPanelStyleEditorState?.openFromModule, command, onCreateStyle]
    )

    const alreadyApplyCreateRef = useRef<boolean>(false)
    const effectCreateStyleRef = useRef<{
        create: typeof effectCreateStyle
        paints: Wukong.DocumentProto.IPaint[]
    }>()
    effectCreateStyleRef.current = { create: effectCreateStyle, paints: props.paints }

    const onClickCreateStyleButton = () => {
        alreadyApplyCreateRef.current = true
        if (!paintStyleNode) {
            return
        }
        if (!paintStyleNode.name) {
            const defaultName = analyzePaints(paintStyleNode.paints as Paint[]).placeholder
            command.invokeBridge(
                CommitType.Noop,
                UpdatePaintStyleNodeNameCommand,
                Wukong.DocumentProto.UpdatePaintStyleNodeNameParam.create({
                    styleId: paintStyleNode.node,
                    name: defaultName,
                })
            )
        }
        command.invokeBridge(CommitType.Noop, ApplyCreateStyle, { nodeId: paintStyleNode.node })
        props.onClickCreateButton?.()
        command.commitUndo()
    }
    const createStyleIdRef = useRef<string | null | undefined>()
    const tryCreateStyleRef = useRef<() => string | null | undefined>()
    const tryRemoveEffectFromCreateStyleRef = useRef<() => void>()
    tryCreateStyleRef.current = () => {
        if (props.isCreateStyle) {
            const paintStyle = command.invokeBridge(
                CommitType.Noop,
                CreatePaintStyleNodeCommand,
                createCutPaintStyle(
                    effectCreateStyleRef.current?.paints
                        ? {
                              paints: (effectCreateStyleRef.current.paints.slice() ?? []) as Paint[],
                          }
                        : {}
                )
            )
            createStyleIdRef.current = paintStyle.id
            effectCreateStyleRef.current?.create?.(paintStyle.id!)
            return paintStyle.id
        }
    }
    tryRemoveEffectFromCreateStyleRef.current = () => {
        if (!alreadyApplyCreateRef.current && createStyleIdRef.current) {
            command.invokeBridge(CommitType.Noop, CancelCreateStyle, { nodeId: createStyleIdRef.current })
            tryResetFromCancelRef.current?.()
            createStyleIdRef.current = null
        }
    }

    const onActiveTabChange = useCallback(
        (v: React.Key) => {
            setActiveKey(v as ColorSetTabKey)
            if (activeKey !== ColorSetTabKey.STYLE && v === ColorSetTabKey.STYLE) {
                tryCreateStyleRef.current?.()
            } else if (activeKey === ColorSetTabKey.STYLE && v !== ColorSetTabKey.STYLE) {
                command.invoke(cmdChangeAttrPanelStyleEditorState, {
                    editingStyleId: '',
                    openFromModule: attrPanelStyleEditorState?.openFromModule,
                })
                tryRemoveEffectFromCreateStyleRef.current?.()
            }
        },
        [activeKey, attrPanelStyleEditorState?.openFromModule, command]
    )

    useEffectOnce(() => {
        if (activeKey === ColorSetTabKey.STYLE) {
            tryCreateStyleRef.current?.()
        }
        return () => tryRemoveEffectFromCreateStyleRef.current?.()
    })

    useEffectOnce(() => {
        if (activeKey === ColorSetTabKey.VARIABLE && !props.hideStyleTab) {
            command.invoke(cmdChangeAttrPanelStyleEditorState, {
                editingStyleId: '',
                openFromModule: attrPanelStyleEditorState?.openFromModule,
            })
        }
    })

    return (
        <KeyboardReceiver keyCode={ToKeyCode.Esc} onKeydown={() => (_onCancel(), false)}>
            <DraggablePopupV2
                style={{ zIndex: props.zIndex }}
                visible={activeKey === ColorSetTabKey.STYLE ? openStyleEditor : true}
                position={props.position}
                closeTestId="color-set-title-close-icon"
                header={
                    <>
                        <WKTabsV2
                            activeKey={activeKey}
                            size="small"
                            className={classes.block}
                            onActiveTabChange={onActiveTabChange}
                            dataTestId="color-set-title"
                        >
                            {props.hideStyleTab ? null : (
                                <WKTabsV2.Item
                                    tabKey={ColorSetTabKey.STYLE}
                                    label={translation(
                                        props.allwaysUseStyleTab
                                            ? 'Style'
                                            : props.isRemoteStyle
                                            ? 'ViewColorStyle'
                                            : props.isCreateStyle
                                            ? 'CreateColorStyle'
                                            : 'EditColorStyle'
                                    )}
                                ></WKTabsV2.Item>
                            )}
                            {props.hideVariableTab ? null : (
                                <WKTabsV2.Item
                                    tabKey={ColorSetTabKey.VARIABLE}
                                    label={translation('Variable')}
                                ></WKTabsV2.Item>
                            )}
                        </WKTabsV2>
                    </>
                }
                onCancel={_onCancel}
                bodyClassName={classnames(classes.body, {
                    [classes.existCreate]: activeKey === ColorSetTabKey.STYLE && props.isCreateStyle,
                    [classes.existRemote]: activeKey === ColorSetTabKey.STYLE && props.isRemoteStyle,
                    [classes.variable]: activeKey === ColorSetTabKey.VARIABLE,
                })}
                footer={
                    props.hideCommonFooter ? null : activeKey === ColorSetTabKey.STYLE ? (
                        props.isRemoteStyle ? (
                            <CommentJumpLink onClick={props.onClickRemoteStyleLink} />
                        ) : props.isCreateStyle ? (
                            <CommentCreateButton onClick={onClickCreateStyleButton} />
                        ) : null
                    ) : null
                }
                positionRightBase
                notUseDefaultFooterClassName
                enableScrollBar
                styleType="editor"
                testId="color-set-container"
            >
                {activeKey === ColorSetTabKey.STYLE ? (
                    <ColorStyleEditor styleId={paintStyleNode?.node ?? ''} isRemoteStyle={props.isRemoteStyle} />
                ) : activeKey === ColorSetTabKey.VARIABLE ? (
                    <ColorVariableCreate
                        paints={props.paints}
                        onClickCreateColorVariable={props.onCreateColorVariable}
                    />
                ) : null}
            </DraggablePopupV2>
        </KeyboardReceiver>
    )
}

interface AnalyzePaints {
    placeholder: string
}

export function analyzePaints(paints?: ReadonlyArray<Paint>): AnalyzePaints {
    const result: AnalyzePaints = { placeholder: translation('ColorStyles') }
    if (!paints?.length) {
        return result
    }
    if (paints.length > 1) {
        result.placeholder = translation('ColorStyles_synonyms1')
    } else {
        const paint = paints[0]
        result.placeholder = getPlaceholder(paint)
    }
    return result
}

function getPlaceholder(paint: Paint): string {
    switch (paint.type) {
        case Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT: {
            const color = paint.color
            const hex = rgb2hex(color.r, color.g, color.b)
            return `#${hex}`.toUpperCase()
        }
        case Wukong.DocumentProto.PaintType.PAINT_TYPE_IMAGE_PAINT:
            return paint.imageName ? paint.imageName : translation('Image')
        case Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_ANGULAR:
            return translation('AngularGradient')
        case Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_DIAMOND:
            return translation('DiamondGradient')
        case Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_LINEAR:
            return translation('LinearGradient')
        case Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_RADIAL:
            return translation('RadialGradient')
        default:
            return translation('StyleName')
    }
}
