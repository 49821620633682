/* eslint-disable no-restricted-imports */
import classNames from 'classnames'
import React, { useCallback } from 'react'
import { TabElement, isRightClick } from '../../../../../../../../ui-lib/src'
import { PaintStyleNode } from '../../../../../../document/node/node'
import { ToKeyCode } from '../../../../../../document/util/keycode'
import { CommonStyleInfo } from '../../get-style-nodes-info-map'
import { StyleToolTip } from '../../style-tooltip/style-tooltip'
import style from '../style-color-grid-item.module.less'
import { PaintStyleThumbnail } from '../style-thumbnail'

export interface UnknowStyleColorGridItemV2Props {
    item: Omit<CommonStyleInfo, 'groupName' | 'styleName'> &
        Pick<PaintStyleNode, 'publishFile' | 'fromFig' | 'publishId' | 'paints'>
    docId: string
    className?: string
    imageSize?: number
    isSelected?: boolean
    onSelectItem: (item: CommonStyleInfo) => void
    onContextMenu?: (
        item: CommonStyleInfo,
        e: any,
        remoteStyleId?: string,
        docId?: string,
        name?: string,
        fromFig?: boolean
    ) => void
    isRightClickStyle?: boolean
}

export function UnknowStyleColorGridItemV2(props: UnknowStyleColorGridItemV2Props) {
    const { item, docId, onSelectItem, onContextMenu, isRightClickStyle, isSelected } = props
    const { id, name, description } = props.item

    const _onContextMenu: React.MouseEventHandler<HTMLDivElement> = useCallback(
        (e) => {
            e.stopPropagation()
            e.preventDefault()
            onContextMenu?.(
                {
                    id: id,
                    name: name || '',
                    description: description || '',
                    groupName: '',
                    styleName: '',
                },
                e,
                item.publishFile,
                docId
            )
        },
        [description, docId, id, item.publishFile, name, onContextMenu]
    )

    const _onMouseDown = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (isRightClick(e)) {
                return
            }
            const value = {
                id: id,
                name: name || '',
                description: description || '',
                groupName: '',
                styleName: '',
            }
            onSelectItem(value)
        },
        [description, id, name, onSelectItem]
    )

    const _onKeydown = useCallback(
        (e: any) => {
            if (e.keyCode === ToKeyCode.Enter) {
                e.stopPropagation()
                _onMouseDown(e)
            }
        },
        [_onMouseDown]
    )
    return (
        <StyleToolTip titleText={item.name || ''} contentText={item.description}>
            <div
                onContextMenu={_onContextMenu}
                onMouseDown={_onMouseDown}
                className={classNames(
                    style.gridItem,
                    isRightClickStyle && style['right-click-menu-visible'],
                    props.className,
                    { [style.selectItem]: isSelected }
                )}
                onKeyDown={_onKeydown}
            >
                <TabElement></TabElement>
                <PaintStyleThumbnail
                    className={style.thumbnail}
                    paints={item.paints}
                    width={props.imageSize || 24}
                    height={props.imageSize || 24}
                    multiple={2}
                />
            </div>
        </StyleToolTip>
    )
}
