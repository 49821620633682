import { useMemo } from 'react'
import { isSafari } from '../../../../kernel/util/ua'
import { CooperationTestId } from '../../../../window'
import classes from './cooperation-cursor.module.less'
import { useCooperationCursor, useCooperationCursorItem } from './use-cooperation-cursor'

// 协作者光标
export function CooperationCursorList() {
    const { coactorSessionIds } = useCooperationCursor()
    return (
        <>
            {coactorSessionIds?.map((coactorSessionId) => (
                <CooperationCursorItem key={coactorSessionId} coactorSessionId={coactorSessionId} />
            ))}
        </>
    )
}

function CooperationCursorItem(props: { coactorSessionId: number }) {
    const { mountOrUnmountCoactorCursorDom } = useCooperationCursorItem(props)
    const isInSafari = useMemo(isSafari, [])

    return (
        <div
            className={classes.coactor}
            ref={mountOrUnmountCoactorCursorDom}
            data-testid={CooperationTestId.CursorItem}
        >
            {isInSafari ? <object className={classes.cursorIcon} /> : <img className={classes.cursorIcon} />}
            <span className={classes.nameLabel} />
        </div>
    )
}
