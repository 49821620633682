import {
    AIDesignLintUpdateAndZoomToSelectionCommand,
    GetTextStyleNodeInfoByNodeIdCommand,
    Wukong,
} from '@wukong/bridge-proto'
import {
    IconArrowDown12,
    IconMore16,
    MonoIconCommonQuestion16,
    MonoIconPanelStyleFont16,
    Tooltip,
    WKButton,
    WKPopover,
    WKToast,
} from '../../../../../../../ui-lib/src'

import classnames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { ITextStyle, NodeId } from '../../../../../document/node/node'
import { useAIDesignLintService } from '../../../../../main/app-context'
import { useCommand } from '../../../../context/document-context'
import { TextStyleThumbnail } from '../../../design/styles/style-text/style-thumbnail'
import {
    Card,
    CardInnerNavigation,
    CardProp,
    ImportedTextStyleNode,
    roundTo2DecimalPlaces,
    useCardInnerNavigation,
    useSameResAndSimilarResProcess,
} from './card'
import classes from './card.module.less'
import { EmptyPrompt } from './empty-prompt'
import { ExclusiveList } from './exclusive-list'
import { useStyleSelect } from './style-select'
import { translation } from './text-card-list.translation'

export interface TextSingleCardInfo {
    styleNode: ImportedTextStyleNode
    segmentList: Wukong.DocumentProto.ISegmentInfo[]
}

interface TextCardProp extends CardProp {
    segmentList: Wukong.DocumentProto.ISegmentInfo[]
}

const generateStyleNodeDescription = (textStyle: Wukong.DocumentProto.VTextStyleNode | null) => {
    return textStyle?.id !== '' ? textStyle?.name : '-'
}

const generateSegmentDescription = (style: Wukong.DocumentProto.IBasicTextStyle) => {
    const lineHeightDes = (lineHeight: Wukong.DocumentProto.ILineHeight) => {
        const NumberUnit = Wukong.DocumentProto.NumberUnit

        switch (lineHeight.unit) {
            case NumberUnit.NUMBER_UNIT_AUTO:
                return 'auto'
            case NumberUnit.NUMBER_UNIT_PERCENT:
                return roundTo2DecimalPlaces(lineHeight.value || 0) + '%'
            case NumberUnit.NUMBER_UNIT_PIXELS:
                return roundTo2DecimalPlaces(lineHeight.value || 0) + 'px'
            default:
                return ''
        }
    }

    const lineHeightDesc: string = lineHeightDes(style.lineHeight)

    return (
        <>
            <div>{style.fontWeight}</div>
            <div>{roundTo2DecimalPlaces(style.fontSize) + 'px' + ' / ' + lineHeightDesc}</div>
        </>
    )
}

const generateSegmentDetailDescription = (style: Wukong.DocumentProto.IBasicTextStyle) => {
    const textDecorationMap: Record<Wukong.DocumentProto.TextDecoration, string> = {
        [Wukong.DocumentProto.TextDecoration.TEXT_DECORATION_NONE]: translation('None'),
        [Wukong.DocumentProto.TextDecoration.TEXT_DECORATION_UNDERLINE]: translation('Underline'),
        [Wukong.DocumentProto.TextDecoration.TEXT_DECORATION_STRIKETHROUGH]: translation('Strokethrough'),
    }

    const letterSpacingDes = (letterSpacing: Wukong.DocumentProto.ILetterSpacing) => {
        const NumberUnit = Wukong.DocumentProto.NumberUnit

        switch (letterSpacing.unit) {
            case NumberUnit.NUMBER_UNIT_AUTO:
                return 'auto'
            case NumberUnit.NUMBER_UNIT_PERCENT:
                return roundTo2DecimalPlaces(letterSpacing.value || 0) + '%'
            case NumberUnit.NUMBER_UNIT_PIXELS:
                return roundTo2DecimalPlaces(letterSpacing.value || 0) + 'px'
            default:
                return ''
        }
    }

    return (
        <div className={classes.text_segment_style_info_container} data-testid={'text-segment-style-info-container'}>
            <div className={classes.item}>
                <div className={classes.properity_name}>{translation('LetterSpacing')}</div>
                <div className={classes.value}>{letterSpacingDes(style.letterSpacing)}</div>
            </div>
            <div className={classes.item}>
                <div className={classes.properity_name}>{translation('ParagraphSpacing')}</div>
                <div className={classes.value}>{style.paragraphSpacing}</div>
            </div>
            <div className={classes.item}>
                <div className={classes.properity_name}>{translation('Decoration')}</div>
                <div className={classes.value}>{textDecorationMap[style.textDecoration]}</div>
            </div>
        </div>
    )
}

function TextCard(prop: TextCardProp) {
    const { expanded, segmentList, deleteCard, targetStyleNodeId, setTargetStyleNodeId } = prop
    const total = segmentList.length

    const command = useCommand()
    const aiDesignLintService = useAIDesignLintService()

    const { curIdx, handleArrowDownClick, handleArrowUpClick, showCurrentIdx } = useCardInnerNavigation(
        total,
        expanded,
        (curIdx_) => {
            command.DEPRECATED_invokeBridge(AIDesignLintUpdateAndZoomToSelectionCommand, {
                nodeIds: [segmentList[curIdx_].ownerId] as NodeId[],
            })
        }
    )

    const { StyleSelect, refToAttachStyleSelect, closeStyleSelectionPanel } = useStyleSelect<HTMLDivElement>(
        'TEXT',
        setTargetStyleNodeId,
        targetStyleNodeId
    )

    // 展开时, 全选相关图层
    useEffect(() => {
        if (expanded) {
            const nodeIds = segmentList.map((segment) => {
                return segment.ownerId
            })

            command.DEPRECATED_invokeBridge(AIDesignLintUpdateAndZoomToSelectionCommand, {
                nodeIds: nodeIds as string[],
            })

            command.commitUndo()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded])

    // 非展开状态下, 关闭样式选择面板
    useEffect(() => {
        if (!expanded) {
            closeStyleSelectionPanel()
        }
    }, [closeStyleSelectionPanel, expanded])

    // 全部替换
    const replaceAll = useCallback(
        (e: React.MouseEvent) => {
            const ranges = segmentList.map((segment) => ({
                start: segment.start,
                end: segment.end,
                nodeId: segment.ownerId,
            }))

            aiDesignLintService.updateTextStyle(command, ranges, targetStyleNodeId)

            deleteCard?.()

            WKToast.show(
                translation('ToastMessageBegin') +
                    ranges.length +
                    (ranges.length > 1 ? translation('ToastMessagePluralEnd') : translation('ToastMessageSingleEnd'))
            )
            e.stopPropagation()
        },
        [aiDesignLintService, command, deleteCard, segmentList, targetStyleNodeId]
    )

    const [currentSelectedStyldNode, setCurrentSelectedStyldNode] =
        useState<Wukong.DocumentProto.VTextStyleNode | null>(null)

    useEffect(() => {
        setCurrentSelectedStyldNode(
            command.DEPRECATED_invokeBridge(GetTextStyleNodeInfoByNodeIdCommand, {
                value: targetStyleNodeId,
            }) as Wukong.DocumentProto.VTextStyleNode
        )
    }, [command, targetStyleNodeId])

    return (
        <div>
            <Card
                testId={'text-style-card-' + prop.segmentList[curIdx].ownerId + '-' + prop.segmentList[curIdx].start}
                expanded={expanded}
            >
                <>
                    <div className={classes.card_container_item} style={{ height: '20px' }}>
                        <div className={classes.flex_center} style={{ gap: '0 8px' }}>
                            <div className={classes.icon_container}>
                                <MonoIconPanelStyleFont16 />
                            </div>
                            {/* 当前 segment 的属性 */}
                            {generateSegmentDescription(prop.segmentList[curIdx].style)}
                            <WKPopover
                                contents={generateSegmentDetailDescription(prop.segmentList[curIdx].style)}
                                placement={'bottom-center'}
                                contentClassName="p-0"
                            >
                                <div
                                    className={`${classes.icon_container} ${classes.icon_detail}`}
                                    style={{ width: '24px', height: '24px' }}
                                    data-testid={
                                        'text-style-card-item-' +
                                        prop.segmentList[curIdx].ownerId +
                                        '-' +
                                        prop.segmentList[curIdx].start +
                                        '-detail-icon'
                                    }
                                    onClick={(e) => {
                                        e.stopPropagation()
                                    }}
                                >
                                    <IconMore16 color={'var(--wk-v2-label-color-gray-8)'} />
                                </div>
                            </WKPopover>
                        </div>
                        <CardInnerNavigation
                            curIdx={curIdx}
                            showCurrentIdx={showCurrentIdx}
                            expanded={expanded}
                            total={total}
                            handleArrowDownClick={handleArrowDownClick}
                            handleArrowUpClick={handleArrowUpClick}
                        ></CardInnerNavigation>
                    </div>

                    {expanded && (
                        <div className={classnames(classes.card_container_item, 'space-x-2', 'truncate')}>
                            <div className={classes.text}>{translation('ReplaceWith')}</div>
                            <div
                                ref={refToAttachStyleSelect}
                                onClick={(e) => {
                                    e.stopPropagation()
                                }}
                                className={classnames(classes.target_desc, 'truncate')}
                            >
                                <div className={classnames(classes.target_desc_left_part, 'w-full', 'truncate')}>
                                    {currentSelectedStyldNode?.id != '' && (
                                        <TextStyleThumbnail
                                            className={classes.thumbnail_image}
                                            styleData={currentSelectedStyldNode as unknown as ITextStyle}
                                            width={16}
                                            height={16}
                                            textStyleId={targetStyleNodeId}
                                            textThumbnailTestId={
                                                'text-style-thumbnail-image-' +
                                                prop.segmentList[curIdx].ownerId +
                                                '-' +
                                                prop.segmentList[curIdx].start
                                            }
                                        ></TextStyleThumbnail>
                                    )}
                                    <div className="truncate w-full">
                                        {generateStyleNodeDescription(currentSelectedStyldNode)}
                                    </div>
                                </div>
                                <div className={classes.icon_container} style={{ pointerEvents: 'none' }}>
                                    <IconArrowDown12 color={'var(--wk-v2-label-color-gray-8)'} />
                                </div>
                            </div>
                            <WKButton
                                className={classes.replace_all_btn}
                                onClick={replaceAll}
                                type={'secondary'}
                                disabled={currentSelectedStyldNode?.id === ''}
                            >
                                {translation('ReplaceAll')}
                            </WKButton>
                        </div>
                    )}
                </>
            </Card>
            <StyleSelect />
        </div>
    )
}

export function TextStyleCardList(prop: {
    cardInfoList: { sameStyleCardInfos: TextSingleCardInfo[]; similarStyleCardInfos: TextSingleCardInfo[] }
}) {
    const aiDesignLintService = useAIDesignLintService()
    const command = useCommand()

    const { cardInfoList } = prop

    const cardComp = (lhsCard: TextSingleCardInfo, rhsCard: TextSingleCardInfo) => {
        const lhs = lhsCard.segmentList[0].style
        const rhs = rhsCard.segmentList[0].style

        if (lhs.fontSize != rhs.fontSize) {
            return rhs.fontSize - lhs.fontSize
        } else if (lhs.fontWeight != rhs.fontWeight) {
            return lhs.fontWeight.localeCompare(rhs.fontWeight)
        } else if (lhs.numericLineHeight != rhs.numericLineHeight) {
            return rhs.numericLineHeight - lhs.numericLineHeight
        } else if (lhs.numericLetterSpacing != rhs.numericLetterSpacing) {
            return rhs.numericLetterSpacing - lhs.numericLetterSpacing
        } else if (lhs.paragraphSpacing != rhs.paragraphSpacing) {
            return rhs.paragraphSpacing - lhs.paragraphSpacing
        } else {
            return lhs.textDecoration - rhs.textDecoration
        }
    }

    // 卡片排序
    const sameStyleCardInfos = cardInfoList.sameStyleCardInfos
        .slice()
        .sort(cardComp)
        .map((info) => ({
            info: info,
            targetStyleNodeId: info.styleNode.id!,
        }))

    const similarStyleCardInfos = cardInfoList.similarStyleCardInfos
        .slice()
        .sort(cardComp)
        .map((info) => ({
            info: info,
            targetStyleNodeId: info.styleNode.id!,
        }))

    const { ignoreItemByIndex, setTargetStyleNodeIdByIndex, allInfos, avaliableSimilarIndexes, avaliableSameIndexes } =
        useSameResAndSimilarResProcess(sameStyleCardInfos, similarStyleCardInfos)

    const similarStyleCountToReplace = avaliableSimilarIndexes.reduce((num, index) => {
        if (allInfos[index].targetStyleNodeId === '') {
            return num
        } else {
            return num + allInfos[index].info.segmentList.length
        }
    }, 0)
    const sameStyleCountToReplace = avaliableSameIndexes.reduce((num, index) => {
        if (allInfos[index].targetStyleNodeId === '') {
            return num
        } else {
            return num + allInfos[index].info.segmentList.length
        }
    }, 0)

    const groupByFontName = (indexes: number[]) => {
        return Array.from(
            indexes.reduce((acc, infoIndex) => {
                const key = allInfos[infoIndex].info.segmentList[0].style.fontFamily
                if (!acc.has(key)) {
                    acc.set(key, [])
                }
                acc.get(key)?.push(infoIndex)
                return acc
            }, new Map())
        ).sort(([lhs, _lIndexes], [rhs, _rIndexes]) => {
            return lhs.localeCompare(rhs)
        }) as [string, number[]][]
    }

    // group by first segment's style's fontName
    const similarItemsGroupByFontName = groupByFontName(avaliableSimilarIndexes)
    const sameItemsGroupByFontName = groupByFontName(avaliableSameIndexes)

    const [expandedId, setExpandedId] = useState<number | null>(null)

    const renderItem = useCallback(
        (index: number, expanded: boolean) => {
            const cardInfo = allInfos[index].info
            return (
                cardInfo && (
                    <TextCard
                        deleteCard={() => {
                            ignoreItemByIndex(index)
                        }}
                        segmentList={cardInfo.segmentList}
                        expanded={expanded}
                        targetStyleNodeId={allInfos[index].targetStyleNodeId}
                        setTargetStyleNodeId={(styleNodeId) => {
                            setTargetStyleNodeIdByIndex(index, styleNodeId)
                        }}
                    ></TextCard>
                )
            )
        },
        [allInfos, ignoreItemByIndex, setTargetStyleNodeIdByIndex]
    )

    const replaceAll = useCallback(
        (items: [string, number[]][]) => {
            let num = 0

            const indexes = items
                .map(([_, indexes_]) => {
                    return indexes_
                })
                .flat()

            const batch = indexes
                .filter((index) => allInfos[index].targetStyleNodeId !== '')
                .map((index) => {
                    const cardInfo = allInfos[index].info
                    const ranges = cardInfo.segmentList.map((segment) => ({
                        start: segment.start,
                        end: segment.end,
                        nodeId: segment.ownerId,
                    }))

                    num += ranges.length
                    ignoreItemByIndex(index)

                    return { ranges: ranges, textStyleId: cardInfo.styleNode.id! }
                })

            aiDesignLintService.batchUpdateTextStyle(command, batch)
            WKToast.show(
                translation('ToastMessageBegin') +
                    num +
                    (num ? translation('ToastMessagePluralEnd') : translation('ToastMessageSingleEnd'))
            )
        },
        [aiDesignLintService, allInfos, command, ignoreItemByIndex]
    )

    return (
        <>
            {avaliableSameIndexes.length === 0 && avaliableSimilarIndexes.length === 0 && (
                <EmptyPrompt texts={[translation('EmptyPrompt')]} />
            )}

            {avaliableSameIndexes.length > 0 && (
                <>
                    <div className={classes.result_tips}>
                        <div>
                            <div className="color-$wk-v2-label-color-gray-13 wk-text-12 wk-font-medium">
                                {translation('Tip1')}
                            </div>
                            <Tooltip title={translation('Tip1Extra')} firstDelay={100}>
                                <MonoIconCommonQuestion16 data-testid="ai-design-lint-same-style-tooltip" />
                            </Tooltip>
                        </div>
                        <a
                            data-testid="ai-design-lint-replace-all-same-items"
                            onClick={(e) => {
                                replaceAll(sameItemsGroupByFontName)
                                e.stopPropagation()
                            }}
                            className={classnames(
                                'cursor-default',
                                sameStyleCountToReplace === 0 ? 'pointer-events-none' : 'pointer-events-auto'
                            )}
                        >
                            {translation('ReplaceAll') + ' ' + sameStyleCountToReplace}
                        </a>
                    </div>
                    <div className={classes.card_list_container_container}>
                        {sameItemsGroupByFontName.map(([fontName, value]) => {
                            return (
                                <ExclusiveList
                                    key={fontName}
                                    renderItem={renderItem}
                                    title={fontName}
                                    expandedId={expandedId}
                                    setExpandedId={setExpandedId}
                                    indexes={value}
                                ></ExclusiveList>
                            )
                        })}
                    </div>
                </>
            )}
            {avaliableSimilarIndexes.length > 0 && avaliableSameIndexes.length > 0 && (
                <div className={classes.horizontal_line}></div>
            )}
            {avaliableSimilarIndexes.length > 0 && (
                <>
                    <div className={classes.result_tips}>
                        <div>
                            <div className="color-$wk-v2-label-color-gray-13 wk-text-12 wk-font-medium">
                                {translation('Tip2')}
                            </div>
                            <Tooltip title={translation('Tip2Extra')} firstDelay={100}>
                                <MonoIconCommonQuestion16 data-testid="ai-design-lint-similar-style-tooltip" />
                            </Tooltip>
                        </div>
                        <a
                            data-testid="ai-design-lint-replace-all-similar-items"
                            onClick={(e) => {
                                replaceAll(similarItemsGroupByFontName)
                                e.stopPropagation()
                            }}
                            className={classnames(
                                'cursor-default',
                                similarStyleCountToReplace === 0 ? 'pointer-events-none' : 'pointer-events-auto'
                            )}
                        >
                            {translation('ReplaceAll') + ' ' + similarStyleCountToReplace}
                        </a>
                    </div>
                    <div className={classes.card_list_container_container}>
                        {similarItemsGroupByFontName.map(([fontName, value]) => {
                            return (
                                <ExclusiveList
                                    key={fontName}
                                    renderItem={renderItem}
                                    title={fontName}
                                    expandedId={expandedId}
                                    setExpandedId={setExpandedId}
                                    indexes={value}
                                ></ExclusiveList>
                            )
                        })}
                    </div>
                </>
            )}
        </>
    )
}
