/* eslint-disable no-restricted-imports */
import { WK } from '../window'
import { SequentialBucket, incInRangeBucket, initSequentialBucketOfTwoPow } from './common'

export interface BridgeMetricReport {
    readonly lastRequest: Map<number, number>
    readonly requestCounter: Map<number, number>
    readonly errorCounter: Map<number, number>
    readonly duration: Map<number, SequentialBucket>
    readonly durationSum: Map<number, number>
    readonly maxDuration: Map<number, number>
}

/**
 * BridgeMetricCollector is a class that collects metrics for the bridge.
 * It follows the RED practice: https://www.weave.works/blog/the-red-method-key-metrics-for-microservices-architecture
 * We treated the wasm kernel as a microservice.
 * There will be 3 metrics:
 *  - request counter: count the number of requests
 *  - error counter: count the number of errors
 *  - request duration: record the duration of each request
 */
export class BridgeMetricCollector {
    private _report: BridgeMetricReport = {
        requestCounter: new Map(),
        errorCounter: new Map(),
        duration: new Map(),
        lastRequest: new Map(),
        durationSum: new Map(),
        maxDuration: new Map(),
    }

    constructor() {
        WK.getBridgeMetric = this.report.bind(this)
    }

    incRequestCounter(code: number): void {
        const counter = this._report.requestCounter.get(code)
        if (counter === undefined) {
            this._report.requestCounter.set(code, 1)
        } else {
            this._report.requestCounter.set(code, counter + 1)
        }

        this.markLastRequest(code)
    }

    private markLastRequest(code: number): void {
        this._report.lastRequest.set(code, performance.now())
    }

    incErrorCounter(code: number): void {
        const counter = this._report.errorCounter.get(code)
        if (counter === undefined) {
            this._report.errorCounter.set(code, 1)
        } else {
            this._report.errorCounter.set(code, counter + 1)
        }
    }

    insertRequestDuration(code: number, duration: number): void {
        let buckets = this._report.duration.get(code)
        if (buckets === undefined) {
            buckets = this.initBlankBuckets()
            this._report.duration.set(code, buckets)
        }

        incInRangeBucket(buckets, duration)

        const durationSum = this._report.durationSum.get(code)
        if (durationSum === undefined) {
            this._report.durationSum.set(code, duration)
        } else {
            this._report.durationSum.set(code, durationSum + duration)
        }

        const maxDuration = this._report.maxDuration.get(code)
        if (maxDuration === undefined || maxDuration < duration) {
            this._report.maxDuration.set(code, duration)
        }
    }

    private initBlankBuckets(): SequentialBucket {
        // 给请求时间分配 10 个桶，从 0 开始，到 10000ms，按照对数来分桶，最后一个桶是超过 10000ms 的请求
        return initSequentialBucketOfTwoPow(9)
    }

    report(): BridgeMetricReport {
        const ret = this._report
        this._report = {
            requestCounter: new Map(),
            errorCounter: new Map(),
            duration: new Map(),
            lastRequest: new Map(),
            durationSum: new Map(),
            maxDuration: new Map(),
        }
        return ret
    }

    destroy(): void {
        delete WK.getBridgeMetric
    }
}
