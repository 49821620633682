import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Select: '选中包含该字体的',
    UsingThisFont: '个图层',
    Regular: '常规',
} as const

export const enTranslation = {
    Select: 'Select',
    UsingThisFont: 'using this font',
    Regular: 'Regular',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
