import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Hint: '勾选「全部可用属性」将支持在全部颜色属性内展示该变量，也包括未来发布的颜色属性。',
} as const

export const enTranslation = {
    Hint: 'Checking "All supported properties" will enable this variable to be displayed in all color properties, including those released in the future.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
