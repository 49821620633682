/* eslint-disable no-restricted-imports */
import { MouseEvent, useCallback, useMemo, useRef, useState } from 'react'
import { useCommentService } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { CommentAvatarProps } from '../comment-avatar/comment-avatar'
import { useEventAnchor } from '../comment-service/use-event-anchor'

import { featureSwitchManager } from '../../../../kernel/switch/core'
import { ShowMessageProps } from '../show-message/show-message'
import { Comment, Position } from '../type'
import { calculateRange, getShowUserInfo } from '../utils'
import { BubbleDOMProps } from './bubble-dom'

export interface BubbleProps {
    comment: Comment
    usersMap: ShowMessageProps['usersMap']
    handleRightClickBubble: (e: MouseEvent) => void
    pending?: CommentAvatarProps['pending']
    isActive?: boolean
    dataTestIds?: BubbleDOMProps['dataTestIds']
}

export function useBubble(props: BubbleProps): BubbleDOMProps {
    const { comment, usersMap, handleRightClickBubble, isActive, pending, dataTestIds } = props
    const { onAnchorMouseMove, onAnchorPointerUp, onAnchorPointerEnter, onAnchorPointerLeave } = useEventAnchor()
    const commentService = useCommentService()
    const docReadonly = useViewState('docReadonly')
    const hoveredComment = useViewState('hoveredComment')
    const isHovered = hoveredComment?.commentId
        ? Number(hoveredComment.commentId) === comment.commentMetaData.id
        : false

    const hasMove = useRef<boolean>(false)
    const [basePoint, setBasePoint] = useState<Position>({ left: 0, top: 0 })
    const rangeKeys = useRef<string[]>([])
    const bubbleKeys = useRef<string[]>([])
    const scaleKeys = useRef<string[]>([])

    const bubbleRef = useRef<HTMLDivElement | null>(null)

    const showUsers = useMemo(() => {
        return getShowUserInfo(comment.commentMetaData.owner, comment.commentMetaData.replies)
    }, [comment.commentMetaData.owner, comment.commentMetaData.replies])

    const onMoveStart = useCallback(() => {
        hasMove.current = false
        if (isActive) {
            // true：评论在点击状态时，移动评论时停止range和dot的位置更新
            commentService.updateIsStopSyncActiveCommentPosition(true)
        }
    }, [commentService, isActive])

    const onMoving = useCallback(
        (position: Position) => {
            // 首次移动须调用 startMoving
            if (!hasMove.current) {
                commentService.updateCommentAnchorStart(comment.commentMetaData.id)
            }
            hasMove.current = true
            commentService.updateCommentAnchor(comment.commentMetaData.id, position)
        },
        [comment.commentMetaData.id, commentService]
    )

    const onMoveEnd = useCallback(() => {
        if (isActive) {
            commentService.updateIsStopSyncActiveCommentPosition(false)
        }
        if (hasMove.current) {
            commentService.updateCommentAnchorEnd(comment.commentMetaData.id)
            hasMove.current = false
        }
    }, [comment.commentMetaData.id, commentService, isActive])

    const onMouseMove = useCallback(
        (e: React.MouseEvent) => {
            onAnchorMouseMove(e.nativeEvent)
        },
        [onAnchorMouseMove]
    )

    const onPointerUp = useCallback(
        (e: React.MouseEvent) => {
            onAnchorPointerUp(e.nativeEvent, comment.commentMetaData.id)
        },
        [comment.commentMetaData.id, onAnchorPointerUp]
    )

    const onPointerEnter = useCallback(
        (e: React.MouseEvent) => {
            onAnchorPointerEnter(e.nativeEvent, comment.commentMetaData.id)
        },
        [comment.commentMetaData.id, onAnchorPointerEnter]
    )

    const onPointerLeave = useCallback(
        (e: React.MouseEvent) => {
            onAnchorPointerLeave(e.nativeEvent, comment.commentMetaData.id)
        },
        [comment.commentMetaData.id, onAnchorPointerLeave]
    )

    const onClick = useCallback(() => {
        commentService.clickOnComment(comment.commentMetaData.id)
    }, [comment.commentMetaData.id, commentService])

    const onContextMenu = useCallback(
        (e: MouseEvent) => {
            commentService.rightClickComment(comment.commentMetaData.id)
            handleRightClickBubble(e)
        },
        [comment.commentMetaData.id, commentService, handleRightClickBubble]
    )

    const anchorRefCallback = useCallback(
        (element: HTMLDivElement | null) => {
            if (element) {
                const key = commentService.positionService.registerBubbleAnchor(
                    comment.commentMetaData.id,
                    (position) => {
                        if (featureSwitchManager.isEnabled('comment-position-transform')) {
                            if (element) {
                                element.style.transform = `translate(${position.left}px, ${position.top}px)`
                            }
                        } else {
                            element?.setAttribute('style', `left:${position.left}px;top:${position.top}px;`)
                        }
                        setBasePoint(position)
                    }
                )
                bubbleKeys.current.push(key)

                const scaleKey = commentService.positionService.registerUpdateBubblePositionWhenScale(
                    element,
                    comment.commentMetaData.id
                )
                scaleKeys.current.push(scaleKey)

                bubbleRef.current = element
                if (featureSwitchManager.isEnabled('comment-position-transform')) {
                    commentService.animationService.createCommentByExitClusterV2(
                        bubbleRef.current,
                        comment.commentMetaData.id
                    )
                } else {
                    commentService.animationService.createCommentByExitCluster(
                        bubbleRef.current,
                        comment.commentMetaData.id
                    )
                }
            } else {
                commentService.tryHoverOffTargetComment(comment.commentMetaData.id)

                bubbleKeys.current.forEach((key) => commentService.positionService.unregisterBubble(key))
                scaleKeys.current.forEach((key) =>
                    commentService.positionService.unRegisterUpdateBubblePositionWhenScale(key)
                )

                bubbleRef.current &&
                    commentService.animationService.removeCommentByEnterCluster(
                        bubbleRef.current,
                        comment.commentMetaData.id
                    )
            }
        },
        [comment.commentMetaData.id, commentService]
    )
    const rangeRefCallback = useCallback(
        (element: HTMLDivElement | null) => {
            if (element) {
                const key = commentService.positionService.registerBubbleAnchorMinorAnchor(
                    comment.commentMetaData.id,
                    (anchorPosition, minorAnchorPosition) => {
                        const { width, height, left, top } = calculateRange(anchorPosition, minorAnchorPosition)
                        if (featureSwitchManager.isEnabled('comment-position-transform')) {
                            if (element) {
                                element.style.transform = `translate(${left - anchorPosition.left}px, ${
                                    top - anchorPosition.top
                                }px)`
                                element.style.width = `${width}px`
                                element.style.height = `${height}px`
                            }
                        } else {
                            element?.setAttribute(
                                'style',
                                `width:${width}px;height:${height}px;left:${left}px;top:${top}px;`
                            )
                        }
                    }
                )
                rangeKeys.current.push(key)
            } else {
                rangeKeys.current.forEach((key) => commentService.positionService.unregisterBubble(key))
            }
        },
        [comment.commentMetaData.id, commentService.positionService]
    )

    return {
        message: comment.commentMetaData.message,
        usersMap: usersMap,
        repliesSum: comment.commentMetaData.replies.length,
        nickname: comment.commentMetaData.owner.nickname,
        createdTime: comment.commentMetaData.createdTime,
        isHovered,
        isActive: !!isActive,
        isResolved: comment.commentMetaData.resolved,
        isUnread: comment.commentMetaData.unread || comment.commentMetaData.replies?.some((reply) => reply.unread),
        isPending: !!pending,
        hasAnchor: comment.overlayPosition.hasAnchor,
        showUsers: showUsers.userList,
        showUsersOverlaySize: showUsers.overflowNum,
        closeMove: !!docReadonly && !commentService.isCurrentUser(comment.commentMetaData.owner.id),
        dragMoveBasePoint: basePoint,
        onClick: onClick,
        onContextMenu: onContextMenu,
        onMoveStart: onMoveStart,
        onMoving: onMoving,
        onMoveEnd: onMoveEnd,
        onMouseMove: onMouseMove,
        onPointerUp: onPointerUp,
        onPointerEnter: onPointerEnter,
        onPointerLeave: onPointerLeave,
        anchorRefCallback: anchorRefCallback,
        rangeRefCallback: rangeRefCallback,
        dataTestIds: dataTestIds,
    }
}
