import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    FailedToObtain: '获取目标组件库失败',
} as const

export const enTranslation = {
    FailedToObtain: 'An error occurred while accessing the library',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
