/* eslint-disable no-restricted-imports */
import { InspectStateCommand, Wukong } from '@wukong/bridge-proto'
import { useEffect, useRef } from 'react'
import { Scrollbar, ScrollbarRef } from '../../../../../ui-lib/src'
import { OnboardingStatus } from '../../../kernel/interface/onboarding'
import { onboardingService } from '../../../main/service/onboarding-service'
import { useViewState } from '../../../view-state-bridge'
import { useCommand } from '../../context/document-context'
import { ScrollContainerProvider } from '../design/design-scroll-container-context'
import { SelectionInfoPanel } from '../design/export/export-panel'
import { InteractionDevProvider } from '../prototype/prototype-interaction-dev/hook'
import { PrototypeInteractionDev } from '../prototype/prototype-interaction-dev/prototype-interaction-dev'
import { InspectBorderV2 } from './inspect-border-v2'
import { InspectColor } from './inspect-color'
import { InspectComponentInfo } from './inspect-component-info'
import { InspectComponentPropInfo } from './inspect-component-prop-info'
import { InspectContent } from './inspect-content'
import { InspectDevModeGuide } from './inspect-dev-mode-guide'
import { InspectEffect } from './inspect-effect'
import { InspectLocalStyles } from './inspect-local-styles'
import { InspectMode } from './inspect-mode'
import { InspectProperty } from './inspect-property'
import { InspectPrototypeFlows } from './inspect-prototype-flows'
import { InspectTypography } from './inspect-typography'
import classes from './inspect.module.less'

export function Inspect() {
    const command = useCommand()
    const singleSelection = useViewState('singleSelection')
    const scrollbarRef = useRef<ScrollbarRef>(null)

    useEffect(() => {
        command.DEPRECATED_invokeBridge(
            InspectStateCommand,
            Wukong.DocumentProto.BridgeProtoBoolean.create({
                value: true,
            })
        )

        return () => {
            command.DEPRECATED_invokeBridge(
                InspectStateCommand,
                Wukong.DocumentProto.BridgeProtoBoolean.create({
                    value: false,
                })
            )
        }
    }, [command])
    const selectionLength = singleSelection?.length ?? 0
    const demotionDevModeStatus = onboardingService.useZustandStore.use.demotionDevModeStatus()
    const showDevModeOnBoarding = demotionDevModeStatus === OnboardingStatus.Init
    return (
        <Scrollbar ref={scrollbarRef} className={classes.variable}>
            <ScrollContainerProvider getScrollContainer={() => scrollbarRef.current?.getContainerElement()}>
                <InteractionDevProvider isDevMode={false}>
                    {showDevModeOnBoarding && <InspectDevModeGuide />}
                    {selectionLength > 0 && <SelectionInfoPanel showAddButton={false} noShadow={true} />}
                    <InspectComponentInfo />
                    <InspectComponentPropInfo />
                    {selectionLength === 1 && <InspectMode />}
                    {selectionLength === 1 && <InspectProperty></InspectProperty>}
                    <InspectContent></InspectContent>
                    <InspectTypography></InspectTypography>
                    {selectionLength === 1 && <InspectColor />}
                    {selectionLength === 1 && <InspectBorderV2 />}
                    {selectionLength === 1 && <InspectEffect />}
                    {selectionLength === 1 && <PrototypeInteractionDev borderTop />}
                    {selectionLength == 0 && <InspectLocalStyles />}
                    {selectionLength == 0 && <InspectPrototypeFlows topBorder bottomBorder={false} inDevMode={false} />}
                </InteractionDevProvider>
            </ScrollContainerProvider>
        </Scrollbar>
    )
}
