import constate from 'constate'
import { Ticket } from '../../document/synergy/synergy-ticket'
import { getDocReadonly } from '../utils/doc-readonly'

const useDocInfoInternal = ({ ticket }: { ticket: Ticket }) => {
    const docReadonly = getDocReadonly(ticket.role)

    return {
        ticket,
        docReadonly,
    }
}

export const [DocInfoProvider, useDocInfo] = constate(useDocInfoInternal)

export const useDocReadonly = () => useDocInfo().docReadonly
