import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    samplePage: '页面示例',
    stylingOverview: '风格概览',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    samplePage: 'Sample Page',
    stylingOverview: 'Styling Overview',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
