import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    FilesRemovedFrom_synonyms1: '个文件已取消收藏',
    FileRemovedFrom_synonyms1: '文件已取消收藏',
    FilesAddedTo: '个文件已收藏',
    FileAddedTo: '文件已收藏',
} as const

export const enTranslation = {
    FilesRemovedFrom_synonyms1: 'files removed from favorites',
    FileRemovedFrom_synonyms1: 'File removed from favorites',
    FilesAddedTo: 'files added to favorites',
    FileAddedTo: 'File added to favorites',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string | number>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
