/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { useCallback, useLayoutEffect, useRef } from 'react'
import { ScrollView, ScrollViewRef } from '../../../../../../ui-lib/src'
import { useCommentService } from '../../../../main/app-context'
import { CommentAvatar } from '../comment-avatar/comment-avatar'
import {
    useMentionUserList,
    useMentionUsersRect,
    usePreSelectedMentionUserIndex,
} from '../comment-service/comment-service-hook'
import classes from './mention-users.module.less'
import { useCanvas } from '../../../../external-store/atoms/create-canvas'

export function MentionUsers() {
    const mentionRef = useRef<ScrollViewRef>(null)
    const canvas = useCanvas()
    const commentService = useCommentService()
    const mentionUsersRect = useMentionUsersRect()
    const mentionUserList = useMentionUserList()
    const preSelectedMentionUserIndex = usePreSelectedMentionUserIndex()

    const onPointerDown = useCallback((e: React.PointerEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()
    }, [])

    const calculateMentionListPosition = useCallback(() => {
        const mention = mentionRef.current?.getScrollViewElement()
        const canvasRect = canvas.getBoundingClientRect()
        if (!mention || !canvasRect || !mentionUsersRect) {
            return
        }
        const edge = 8
        const { height: mentionHeight } = mention.getBoundingClientRect()
        const { splitLineRect, containerRect } = mentionUsersRect
        const baseline = Math.min(containerRect.bottom, splitLineRect.bottom)
        const minTop = canvasRect.top + edge
        const maxBottom = canvasRect.bottom - edge
        let top = baseline - containerRect.top
        // 上下溢出 | 上溢出
        if (mentionHeight + 2 * edge > canvasRect.height || minTop > baseline) {
            top = minTop - containerRect.top
        }
        // 下溢出
        if (baseline + mentionHeight > maxBottom) {
            top = maxBottom - mentionHeight - containerRect.top
        }
        mention.setAttribute(
            'style',
            `top: ${top}px;left:${splitLineRect.left - containerRect.left}px;width: ${splitLineRect.width}px;`
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvas, mentionUsersRect, mentionUserList.length])

    useLayoutEffect(() => {
        calculateMentionListPosition()
    }, [calculateMentionListPosition])

    return (
        <ScrollView
            block="nearest"
            selectKey={preSelectedMentionUserIndex}
            className={classnames(classes.mentionUsersContainer)}
            onPointerDown={onPointerDown}
            onPointerUp={(e) => e.stopPropagation()}
            ref={mentionRef}
            notUseDefaultClassName
            scrollbar={{ autoHeight: true, autoHeightMin: 0, autoHeightMax: 56 * 5 }}
            data-testid={'comment-mention-user-list'}
        >
            {mentionUserList.map((item, index) => {
                return (
                    <ScrollView.Item
                        uniqueKey={index}
                        key={item.id}
                        className={classnames(classes.box, {
                            [classes.active]: preSelectedMentionUserIndex === index,
                        })}
                        onClick={commentService.applySelectedMentionUser}
                        onMouseEnter={() => commentService.updatePreSelectedMentionUserIndex(index)}
                    >
                        <div className={classes.avatar}>
                            <CommentAvatar userInfo={item} />
                        </div>
                        <div className={classes.info}>
                            <div className={classes.userName}>{item.nickname}</div>
                            <div className={classes.email}>{item.email}</div>
                        </div>
                    </ScrollView.Item>
                )
            })}
        </ScrollView>
    )
}
