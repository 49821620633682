/* eslint-disable no-restricted-imports */
import { UserBriefAndErrorVO } from '../../../../ui-lib/src'
import { ResourceType, RoleStatus } from '../../kernel/interface/type'
import { generatePresentFullUrl, getPresentUrlParams } from '../../prototype/context/present-url'
import { batchInviteUserRequest } from '../batch-invite-user-request/batch-invite-user-request'

export function usePrototypePresentShareUtil(fileId: string) {
    const getPrototypePresentShareLink = () => {
        const params = getPresentUrlParams()

        const url = generatePresentFullUrl({
            fileId: fileId,
            ...params,
        })

        return url
    }

    const prototypePresentInviteUsers = async (
        users: UserBriefAndErrorVO[],
        role: RoleStatus,
        folderId?: string | false,
        docId?: string | false
    ) => {
        const inviteRedirectUrl = getPrototypePresentShareLink()
        const emails = users.map((user) => user.email)

        if (folderId) {
            await batchInviteUserRequest(ResourceType.Folder, folderId, emails, role, undefined, inviteRedirectUrl)
        } else if (docId) {
            await batchInviteUserRequest(ResourceType.Document, docId, emails, role, undefined, inviteRedirectUrl)
        }
    }

    return {
        prototypePresentInviteUsers,
        getPrototypePresentShareLink,
    }
}
