import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    Duplicating: '正在复制',
    AddingImage: '正在添加图片',
    Pasting: '正在粘贴',
    CopyingAs: '正在复制为',
    CopiedAs: '已复制为',
    LinkCopied: '链接已复制',
    CopyFailed: '复制失败',
    CopyPNGFailed: '复制失败',
    CopyPNGFailedByLimit: '复制失败，超过复制为 PNG 的最大尺寸限制，请使用导出功能',
    PasteFailed: '粘贴失败',
    ProhibitCopy: '当前文件被设置为禁止查看者复制',
} as const

export const enTranslation = {
    Duplicating: 'Duplicating',
    AddingImage: 'Adding image',
    Pasting: 'Pasting',
    CopyingAs: 'Copying as',
    CopiedAs: 'Copied as',
    LinkCopied: 'Link copied',
    CopyFailed: 'An error occurred while copying layers',
    CopyPNGFailed: 'An error occurred while copying',
    CopyPNGFailedByLimit: 'The selection exceeds the maximum size limit for copying as PNG',
    PasteFailed: 'An error occurred while pasting layers',
    ProhibitCopy: 'This file settings disable viewers from copying.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
