import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react'
import { useMount } from 'react-use'
import { IN_JEST_TEST } from '../../../../../app/src/environment'

export interface CaptureKeyboardProps {
    children?: React.ReactNode
    dataTestIds?: { captureKeyboard?: string }
    className?: string
    getFocusElement?: () => HTMLElement | undefined
    onFocusLeave?: (e: React.FocusEvent) => void // 焦点从元素内离开
    onKeyboardEvent?: (e: React.KeyboardEvent) => void
    onMouseMove?: (e: React.MouseEvent) => void
    onMouseDown?: (e: React.MouseEvent) => void
}
export interface CaptureKeyboardRef {
    getContainer: () => HTMLDivElement
    focus: () => void
}

function _CaptureKeyboard<T extends CaptureKeyboardProps = CaptureKeyboardProps>(
    props: T,
    ref?: React.Ref<CaptureKeyboardRef>
) {
    const { getFocusElement, onFocusLeave, onKeyboardEvent, dataTestIds, ...otherProps } = props
    const containerRef = useRef<HTMLDivElement>(null)
    // 用于区分在没有relatedTarget情况下的失焦事件是改聚焦回组件还是触发失焦逻辑。对应于直接调用element.blur()
    const instantSemaphoreRef = useRef<number>(0)

    const focus = useCallback(() => {
        const container = containerRef.current
        if (!container) {
            return
        }
        if (container.contains(document.activeElement)) {
            return
        }
        const focusElement = getFocusElement?.() ?? container
        if (container.contains(focusElement)) {
            focusElement.focus({ preventScroll: true })
        }
    }, [getFocusElement])

    const onBlurCapture = useCallback(
        (e: React.FocusEvent) => {
            const isUserClose = --instantSemaphoreRef.current === 0
            //jest 测试里js 失焦会把e.relatedTarget 设置成document
            const documentIsNull = IN_JEST_TEST ? (e.relatedTarget as unknown as Document) !== document : true
            if (e.relatedTarget && documentIsNull) {
                if (containerRef.current?.contains(e.relatedTarget)) {
                    return
                }
                onFocusLeave?.(e)
                return
            }
            if (!document.hasFocus()) {
                onFocusLeave?.(e)
                return
            }
            if (isUserClose) {
                onFocusLeave?.(e)
            } else {
                focus()
            }
        },
        [focus, onFocusLeave]
    )

    useMount(focus)

    useEffect(() => {
        const pointerdown = (e: PointerEvent) => {
            if (containerRef.current?.contains(e.target as HTMLElement)) {
                instantSemaphoreRef.current = 0
            } else {
                instantSemaphoreRef.current = 1
            }
            // 执行顺序 pointerdown -> blur -> setTimeout
            setTimeout(() => {
                instantSemaphoreRef.current = 0
            }, 0)
        }
        window.addEventListener('pointerdown', pointerdown, true)
        return () => {
            window.removeEventListener('pointerdown', pointerdown, true)
        }
    }, [])

    useImperativeHandle(ref, () => ({ getContainer: () => containerRef.current!, focus }), [focus])

    return (
        <div
            ref={containerRef}
            tabIndex={-1}
            onBlurCapture={onBlurCapture}
            onKeyDown={onKeyboardEvent}
            data-testid={dataTestIds?.captureKeyboard}
            {...otherProps}
        >
            {props.children}
        </div>
    )
}

export const CaptureKeyboard = forwardRef(_CaptureKeyboard) as <T extends CaptureKeyboardProps = CaptureKeyboardProps>(
    props: T & { ref?: React.Ref<CaptureKeyboardRef> }
) => React.ReactElement
