import { DraggablePopupV2 } from '../../../../../../ui-lib/src'
import { BlendContent } from '../../design/blend/blend-content'
import { PaintIconColor } from '../../paint-icon-color/paint-icon-color'
import { usePrototypeBackgroundColor } from './hook'
import { translation } from './prototype-background-color.translation'

const SolidPaintSelect = () => {
    const { onClickOpenModal, onChangeOpacity, _onChangeColor, onClose, paint, htmlRef, colorSpace, position, isOpen } =
        usePrototypeBackgroundColor()

    if (!paint) {
        return <></>
    }
    return (
        <div className="px-4 py-1" ref={htmlRef}>
            <div className="bg-$wk-v2-fill-color-gray-1 rounded-3px w-fit">
                <PaintIconColor
                    paint={paint}
                    colorSpace={colorSpace}
                    onChangeColor={_onChangeColor}
                    onMouseDownIcon={onClickOpenModal}
                    onClickInput={onClose}
                />
            </div>
            <DraggablePopupV2
                visible={isOpen}
                position={position}
                onCancel={onClose}
                header={translation('背景颜色')}
                styleType="editor"
                footer={null}
                positionRightBase
                bodyClassName="p-0"
                testId="prototype-background-paint-popup"
                overflowVisible
            >
                <BlendContent
                    data={paint}
                    colorSpace={colorSpace}
                    onChangeColor={_onChangeColor}
                    onChangeOpacity={onChangeOpacity}
                    enterClose={onClose}
                />
            </DraggablePopupV2>
        </div>
    )
}

export const PrototypeBackgroundColor = () => {
    return (
        <div className="pt-2 pb-3 b-b border-color-$wk-gray-3">
            <div className="wk-text-12 px-4 py-1.5 wk-font-semibold">{translation('Background')}</div>
            <SolidPaintSelect />
        </div>
    )
}
