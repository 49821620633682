import { translation } from './use-hyperlink-model.translation'
/* eslint-disable no-restricted-imports */
import {
    CancelHyperlinkEditing,
    DetachSelectionTextNodeHyperlinkCommand,
    EndHyperlinkEditing,
    StartHyperlinkEditing,
} from '@wukong/bridge-proto'
import { WKToast } from '../../../../../../../ui-lib/src'
import { isValidUrl } from '../../../../../document/util/url'
import { useCommand } from '../../../../../main/app-context'
import { useViewState } from '../../../../../view-state-bridge'
import { formatUrl } from './util'

export const useHyperlinkModel = () => {
    const editingState = useViewState('hyperlinkEditingState')
    const command = useCommand()

    return {
        modelState: editingState,
        modelCommand: {
            startHyperlinkEditing: () => {
                command.DEPRECATED_invokeBridge(StartHyperlinkEditing)
            },

            endHyperlinkEditing: (url: string) => {
                const formatedUrl = formatUrl(url)
                if (!isValidUrl(formatedUrl)) {
                    command.DEPRECATED_invokeBridge(CancelHyperlinkEditing)
                    WKToast.show(translation('HyperlinkFormatError'), { duration: 5000 })
                } else {
                    command.DEPRECATED_invokeBridge(EndHyperlinkEditing, { value: formatedUrl })
                }
            },

            cancelHyperlinkEditing: () => {
                command.DEPRECATED_invokeBridge(CancelHyperlinkEditing)
            },

            detachHyperlink: () => {
                command.DEPRECATED_invokeBridge(DetachSelectionTextNodeHyperlinkCommand)
                command.DEPRECATED_invokeBridge(CancelHyperlinkEditing)
            },
        },
    } as const
}

export type HyperlinkModel = ReturnType<typeof useHyperlinkModel>
