import { useMemo } from 'react'
import { Select } from '../../../../../../ui-lib/src'
import { FontInfo, FontName } from '../../../../document/node/node'
import { isEqualFontName } from '../../../../document/util/font'
import { translation } from './use-font-style-option-list.translation'
import { getStyleGroup } from './utils'

const renderFontStyleOptionSubList = (
    styleList: FontName[],
    selectedFontName: FontName | null,
    customAxesStyle: string,
    splitLine: (style: FontName, index: number) => boolean
) => {
    return styleList.map((fontStyle, index) => (
        <Select.NormalSingleLevel.Option
            key={fontStyle.localizedStyle + index}
            value={{ style: fontStyle.style, customAxesStyle: { name: '' }, isMissFontCustomStyle: false }}
            isSelect={!!selectedFontName && isEqualFontName(fontStyle, selectedFontName) && customAxesStyle === ''}
            splitLineTop={splitLine(fontStyle, index)}
        >
            {fontStyle.localizedStyle}
        </Select.NormalSingleLevel.Option>
    ))
}

export interface CustomAxesStyleInfo {
    style: string
    customAxesStyle: { name: string }
    isMissFontCustomStyle: boolean
}

export const useFontStyleOptionList = ({
    fontInfo,
    selectedFontName,
    customAxesStyle,
    showVariantsSetting,
    stylesInfo,
}: {
    fontInfo: FontInfo | null
    selectedFontName: FontName | null
    customAxesStyle: string
    showVariantsSetting: boolean
    stylesInfo: CustomAxesStyleInfo[]
}) => {
    const styleList = useMemo(() => {
        if (!fontInfo) {
            return []
        }

        const { weightWidth, weight, weightWidthSlant, weightSlant } = getStyleGroup(fontInfo)
        return [weightWidth, weight, weightWidthSlant, weightSlant].filter((list) => list.length > 0)
    }, [fontInfo])

    const elementArray = styleList.map((list, listIndex) => {
        return renderFontStyleOptionSubList(
            list,
            selectedFontName,
            customAxesStyle,
            (_, optionIndex) => listIndex != 0 && optionIndex == 0
        )
    })

    if (stylesInfo.length) {
        elementArray.push([
            ...stylesInfo.map((info, index) => (
                <Select.NormalSingleLevel.Option
                    key={info.customAxesStyle.name + index}
                    value={info}
                    isSelect={customAxesStyle == info.customAxesStyle.name}
                    splitLineTop={index == 0}
                >
                    {info.customAxesStyle.name}
                </Select.NormalSingleLevel.Option>
            )),
        ])
    }

    if (showVariantsSetting) {
        elementArray.push([
            <Select.NormalSingleLevel.Option
                key={'variable-setting'}
                value={'variable-setting'}
                isSelect={false}
                splitLineTop={true}
            >
                {translation('VariableSetting')}
            </Select.NormalSingleLevel.Option>,
        ])
    }
    return elementArray
}
