import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    FlowStartingPoint: '流程起点',
    AddStartingPoint: '添加起点',
    CopyLink: '复制链接',
    RemoveStartingPoint: '移除起点',
    EditDescription: '编辑描述',
    Description: '描述',
    EnterADescriptionForTheFlow: '请输入流程描述',
    RemoveAll: '点击「-」移除多个内容',
    LinkCopied: '链接已复制',
} as const

export const enTranslation = {
    FlowStartingPoint: 'Flow starting point',
    AddStartingPoint: 'Add starting point',
    CopyLink: 'Copy link',
    RemoveStartingPoint: 'Remove starting point',
    EditDescription: 'Edit description',
    Description: 'Description',
    EnterADescriptionForTheFlow: 'Enter a description for the flow',
    RemoveAll: 'Click - to remove the mixed content.',
    LinkCopied: 'Link copied',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
