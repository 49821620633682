type NeedStopThisAction = boolean | void
export interface PickBaseProps {
    onOpen?: () => void // 弹窗打开的callback
    onClose?: () => void // 弹窗关闭的callback
    getPortalElement?: () => HTMLElement | undefined | null
    // 下面三项的组合以实现一个受控弹出展示状态。当然也可以单独使用
    isOpenState?: boolean // 会尝试使用这个值及其变化去尝试更新组件内的打开状态
    openStateToBeFalse?: () => NeedStopThisAction // 返回值基本用于用外部的isOpenState管理打开状态， true将会阻止打开
    openStateToBeTrue?: () => NeedStopThisAction // 返回值基本用于用外部的isOpenState管理打开状态， true将会阻止关闭
    // esc 关闭pick时 往往会对 trigger有特殊处理
    closeByESC?: () => void
    // 弹出层的keydown回调
    onKeyboard?: (e: React.KeyboardEvent) => void
    pickRest?: {
        classNameRoot?: string
        onMouseDown?: (e: React.MouseEvent) => void
        firstPopUpScrollProps?: { className?: string }
    }
}

export interface PickBaseRef {
    open: () => void
    close: () => void
}

export const pickComponentNames = {
    singleLevel: 'single-level',
    multiLevel: 'multi-level',
    empty: 'empty',
}

export function isPickComponent(componentName?: string) {
    switch (componentName) {
        case 'single-level':
        case 'multi-level':
        case 'empty':
            return true
        default:
            return false
    }
}
