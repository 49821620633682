import { Wukong } from '@wukong/bridge-proto'
import * as $protobufjs from 'protobufjs'
import { DeepRequired } from '../../../../util/src'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { MessageContentType } from '../interface/notify'
import { NotifyService } from './notify-service'

export interface WsNewMsg<T> extends Omit<DeepRequired<Wukong.NotifyProto.IMessageCenterNewMessageProto>, 'payload'> {
    messageData: T
    read: boolean
}

export function toObject<
    T,
    K extends {
        decode: (buff: ArrayBuffer) => T
        toObject: (data: T, o?: $protobufjs.IConversionOptions) => DeepRequired<T>
    } = any
>(f: K, buff: ArrayBuffer) {
    const decode = f.decode(buff)
    return f.toObject(decode, {
        longs: Number, // Long 类型 decode 时返回 number
        defaults: true, // 无值 decode 时返回默认值
    })
}

export interface ConnectMsgCallbacks {
    onConnect: () => void // 连接成功时的回调，包括首次连接和每次重连成功
    onModify: (message: DeepRequired<Wukong.NotifyProto.IMessageCenterModifyMessageProto>) => void
    onComment: (message: WsNewMsg<DeepRequired<Wukong.NotifyProto.IMessageCenterCommentProto>>) => void
    onJoinRequest: (message: WsNewMsg<DeepRequired<Wukong.NotifyProto.IMessageCenterJoinRequestProto>>) => void
    onJoinApply: (message: WsNewMsg<DeepRequired<Wukong.NotifyProto.IMessageCenterJoinApplyProto>>) => void
    onInvitation: (message: WsNewMsg<DeepRequired<Wukong.NotifyProto.IMessageCenterInvitationProto>>) => void
    onAssetHandover: (message: WsNewMsg<DeepRequired<Wukong.NotifyProto.IMessageCenterAssetHandover>>) => void
    onSeatApplication: (message: WsNewMsg<DeepRequired<Wukong.NotifyProto.IMessageCenterSeatApplicationProto>>) => void
    onSeatApplyResolved: (
        message: WsNewMsg<DeepRequired<Wukong.NotifyProto.IMessageCenterSeatApplyResolvedProto>>
    ) => void
    onSeatUpdate: (message: WsNewMsg<DeepRequired<Wukong.NotifyProto.IMessageCenterSeatUpdateProto>>) => void
    onPluginEditInvitation: (message: WsNewMsg<DeepRequired<Wukong.NotifyProto.IPluginEditInvitationProto>>) => void
    onMarkOpened: (message: DeepRequired<Wukong.NotifyProto.IMessageCenterMarkOpened>) => void
}

export class MessageCenterNotifyService {
    constructor(
        private readonly notifyService: NotifyService,
        private readonly orgId: string,
        private userId: number
    ) {}

    getSubscribeParams = () => {
        return {
            [MessageContentType.MessageCenter]: {
                filters: [
                    {
                        filterParameters: [
                            {
                                name: 'orgId',
                                value: this.orgId,
                            },
                            {
                                name: 'receiverUserId',
                                value: String(this.userId),
                            },
                        ],
                    },
                ],
            },
        }
    }

    connectWithSignal = (signal: TraceableAbortSignal, msgCallbacks: ConnectMsgCallbacks) => {
        this.notifyService.onConnectChangeWithSignal(signal, ({ sessionId }) => {
            if (sessionId) {
                this.notifyService.sendSubscribeProto(this.orgId, this.getSubscribeParams())
                msgCallbacks.onConnect()
            }
        })

        signal.addEventListener('abort', () => {
            this.notifyService.sendUnSubscribeProto(this.orgId, this.getSubscribeParams())
        })

        this.notifyService.onBusinessMessageChangeWithSignal(signal, (proto) => {
            if (proto.businessCode !== Wukong.NotifyProto.BusinessCode.MESSAGE_CENTER_MESSAGE) {
                return
            }
            const decodeRes = Wukong.NotifyProto.MessageCenterProto.decode(proto.payload)
            switch (decodeRes.type) {
                case Wukong.NotifyProto.MessageCenterMessageTypeProto.MODIFY_MESSAGE_CENTER_MESSAGE_TYPE: {
                    this.handlerModifyMessage(decodeRes.payload, msgCallbacks)
                    break
                }
                case Wukong.NotifyProto.MessageCenterMessageTypeProto.NEW_MESSAGE_CENTER_MESSAGE_TYPE: {
                    this.handlerNewMessage(decodeRes.payload, msgCallbacks)
                    break
                }
                default:
                    break
            }
        })
    }

    private handlerModifyMessage = (payload: ArrayBuffer, msgCallbacks: ConnectMsgCallbacks) => {
        const res = toObject<Wukong.NotifyProto.IMessageCenterModifyMessageProto>(
            Wukong.NotifyProto.MessageCenterModifyMessageProto,
            payload
        )
        msgCallbacks.onModify(res)
    }

    private handlerNewMessage = (payload: Uint8Array, msgCallbacks: ConnectMsgCallbacks) => {
        const {
            payload: newMessagePayload,
            messageId,
            messageCreateTimeMillion,
            ...otherNotLongProperties
        } = Wukong.NotifyProto.MessageCenterNewMessageProto.decode(payload)
        const otherProperties = {
            ...otherNotLongProperties,
            messageId: Number(messageId),
            messageCreateTimeMillion: Number(messageCreateTimeMillion),
        }
        switch (otherProperties.type) {
            case Wukong.NotifyProto.MessageCenterNewMessageTypeProto.NEW_COMMENT_MESSAGE_CENTER_MESSAGE_TYPE:
            case Wukong.NotifyProto.MessageCenterNewMessageTypeProto.REPLY_COMMENT_MESSAGE_CENTER_MESSAGE_TYPE:
            case Wukong.NotifyProto.MessageCenterNewMessageTypeProto.MENTION_COMMENT_MESSAGE_CENTER_MESSAGE_TYPE: {
                const res = toObject<Wukong.NotifyProto.IMessageCenterCommentProto>(
                    Wukong.NotifyProto.MessageCenterCommentProto,
                    newMessagePayload
                )
                msgCallbacks.onComment({ ...otherProperties, messageData: res, read: false })
                break
            }
            case Wukong.NotifyProto.MessageCenterNewMessageTypeProto.JOIN_REQUEST_MESSAGE_CENTER_MESSAGE_TYPE: {
                const res = toObject<Wukong.NotifyProto.IMessageCenterJoinRequestProto>(
                    Wukong.NotifyProto.MessageCenterJoinRequestProto,
                    newMessagePayload
                )
                msgCallbacks.onJoinRequest(Object.assign({ ...otherProperties, messageData: res, read: false }))
                break
            }
            case Wukong.NotifyProto.MessageCenterNewMessageTypeProto.JOIN_APPLY_MESSAGE_CENTER_MESSAGE_TYPE: {
                const res = toObject<Wukong.NotifyProto.IMessageCenterJoinApplyProto>(
                    Wukong.NotifyProto.MessageCenterJoinApplyProto,
                    newMessagePayload
                )
                msgCallbacks.onJoinApply(Object.assign({ ...otherProperties, messageData: res, read: false }))
                break
            }
            case Wukong.NotifyProto.MessageCenterNewMessageTypeProto.INVITATION_MESSAGE_CENTER_MESSAGE_TYPE: {
                const res = toObject<Wukong.NotifyProto.IMessageCenterInvitationProto>(
                    Wukong.NotifyProto.MessageCenterInvitationProto,
                    newMessagePayload
                )
                msgCallbacks.onInvitation(Object.assign({ ...otherProperties, messageData: res, read: false }))
                break
            }
            case Wukong.NotifyProto.MessageCenterNewMessageTypeProto.ASSET_HANDOVER_MESSAGE_CENTER_MESSAGE_TYPE: {
                const res = toObject<Wukong.NotifyProto.IMessageCenterAssetHandover>(
                    Wukong.NotifyProto.MessageCenterAssetHandover,
                    newMessagePayload
                )
                msgCallbacks.onAssetHandover(Object.assign({ ...otherProperties, messageData: res, read: false }))
                break
            }
            case Wukong.NotifyProto.MessageCenterNewMessageTypeProto.SEAT_APPLICATION_MESSAGE_CENTER_MESSAGE_TYPE: {
                const res = toObject<Wukong.NotifyProto.IMessageCenterSeatApplicationProto>(
                    Wukong.NotifyProto.MessageCenterSeatApplicationProto,
                    newMessagePayload
                )
                msgCallbacks.onSeatApplication(Object.assign({ ...otherProperties, messageData: res, read: false }))
                break
            }
            case Wukong.NotifyProto.MessageCenterNewMessageTypeProto.SEAT_APPLY_RESOLVED_MESSAGE_CENTER_MESSAGE_TYPE: {
                const res = toObject<Wukong.NotifyProto.IMessageCenterSeatApplyResolvedProto>(
                    Wukong.NotifyProto.MessageCenterSeatApplyResolvedProto,
                    newMessagePayload
                )
                msgCallbacks.onSeatApplyResolved(Object.assign({ ...otherProperties, messageData: res, read: false }))
                break
            }
            case Wukong.NotifyProto.MessageCenterNewMessageTypeProto.SEAT_UPDATE_MESSAGE_CENTER_MESSAGE_TYPE: {
                const res = toObject<Wukong.NotifyProto.IMessageCenterSeatUpdateProto>(
                    Wukong.NotifyProto.MessageCenterSeatUpdateProto,
                    newMessagePayload
                )
                msgCallbacks.onSeatUpdate(Object.assign({ ...otherProperties, messageData: res, read: false }))
                break
            }
            case Wukong.NotifyProto.MessageCenterNewMessageTypeProto.MARK_OPENED_MESSAGE_CENTER_MESSAGE_TYPE: {
                const res = toObject<Wukong.NotifyProto.IMessageCenterMarkOpened>(
                    Wukong.NotifyProto.MessageCenterMarkOpened,
                    newMessagePayload
                )
                msgCallbacks.onMarkOpened(res)
                break
            }
            case Wukong.NotifyProto.MessageCenterNewMessageTypeProto
                .PLUGIN_EDIT_INVITATION_MESSAGE_CENTER_MESSAGE_TYPE: {
                const res = toObject<Wukong.NotifyProto.IPluginEditInvitationProto>(
                    Wukong.NotifyProto.PluginEditInvitationProto,
                    newMessagePayload
                )
                msgCallbacks.onPluginEditInvitation(
                    Object.assign({ ...otherProperties, messageData: res, read: false })
                )
                break
            }
            case Wukong.NotifyProto.MessageCenterNewMessageTypeProto.UNKNOWN_MESSAGE_CENTER_NEW_MESSAGE_TYPE:
            default:
                return
        }
    }
}
