import { Wukong } from '@wukong/bridge-proto'
import {
    MonoIconPanelConstraintScale16,
    MonoIconPanelCoordinateH16,
    MonoIconPanelCoordinateW16,
    MonoIconPanelFreeScale16,
    MonoIconPanelHeightExtremes16,
    MonoIconPanelMaxHeight16,
    MonoIconPanelMaxWidth16,
    MonoIconPanelMinHeight16,
    MonoIconPanelMinWidth16,
    MonoIconPanelWidthExtremes16,
    Tooltip,
} from '../../../../../../ui-lib/src'
import { EditorDataTestId } from '../../../../window'
import { IconButton } from '../../atom/button/icon-button'
import { SingleGrid } from '../../atom/grid/single-grid'
import { ScrubbableInputNumber } from '../../atom/inputs/scrubbable-input-number'
import { useTextState } from '../text/hooks/use-text-state'
import { translation } from './attr-about-width-height.translation'
import { MinAndMaxSizeOption, MinMaxSizeDropdown, MinMaxSizeInput, MinMaxSizeInputLayout } from './min-max-size'
import { useAttrAboutWidthHeight } from './use-attr-about-width-height'

export function AttrAboutWidthHeight() {
    const {
        constrainProportions,
        onChangeConstrainProportions,
        state,
        minMaxSizeSelectInputProps,
        onChangeWidth,
        onChangeHeight,
        onChangeMinWidth,
        onChangeMaxWidth,
        onChangeMinHeight,
        onChangeMaxHeight,
        onChangeMixedWidth,
        onChangeMixedHeight,
        onChangeMixedMinWidth,
        onChangeMixedMinHeight,
        onChangeMixedMaxWidth,
        onChangeMixedMaxHeight,
        onScrubChangeAllMixedWidth,
        onScrubChangeAllMixedHeight,
        onScrubChangeAllMixedMinWidth,
        onScrubChangeAllMixedMaxWidth,
        onScrubChangeAllMixedMinHeight,
        onScrubChangeAllMixedMaxHeight,
    } = useAttrAboutWidthHeight()
    const { missFontInfo } = useTextState()
    const missFontStyle = missFontInfo.isMissStyle

    if (!state) {
        return <></>
    }

    return (
        <>
            <SingleGrid>
                <SingleGrid.Item start={5} span={22}>
                    <MinMaxSizeDropdown
                        hoverId={Wukong.DocumentProto.SizeHoverMenuItem.SIZE_HOVER_MENU_ITEM_WIDTH}
                        disabled={
                            missFontStyle ||
                            state.w.editType ===
                                Wukong.DocumentProto.BaseAttributePropEditType.BASE_ATTRIBUTE_PROP_EDIT_TYPE_DISABLE
                        }
                        placement="bottom left"
                        inputLabel={
                            <ScrubbableInputNumber
                                disabled={
                                    missFontStyle ||
                                    state.w.editType ===
                                        Wukong.DocumentProto.BaseAttributePropEditType
                                            .BASE_ATTRIBUTE_PROP_EDIT_TYPE_DISABLE
                                }
                                icon={
                                    minMaxSizeSelectInputProps.hasMinMaxWidth ? (
                                        <MonoIconPanelWidthExtremes16 />
                                    ) : (
                                        <MonoIconPanelCoordinateW16 />
                                    )
                                }
                                labelTestId="coordinate-width-label"
                                defaultShowEmpty={
                                    state.w.editType ===
                                    Wukong.DocumentProto.BaseAttributePropEditType
                                        .BASE_ATTRIBUTE_PROP_EDIT_TYPE_SECONDARY
                                }
                                isMixed={
                                    state.w.valueType ===
                                    Wukong.DocumentProto.BaseAttributePropValueType.BASE_ATTRIBUTE_PROP_VALUE_TYPE_MIXED
                                }
                                placeholder={
                                    state.w.editType ===
                                    Wukong.DocumentProto.BaseAttributePropEditType
                                        .BASE_ATTRIBUTE_PROP_EDIT_TYPE_SECONDARY
                                        ? `${state.w.value}`
                                        : undefined
                                }
                                value={
                                    state.w.editType ===
                                    Wukong.DocumentProto.BaseAttributePropEditType
                                        .BASE_ATTRIBUTE_PROP_EDIT_TYPE_SECONDARY
                                        ? undefined
                                        : state.w.value
                                }
                                onChange={onChangeWidth}
                                mixedMathHandler={{
                                    getScrubStartValueMixed: () => new Map(Object.entries(state.w.values)),
                                    onScrubChangeAllMixed: onScrubChangeAllMixedWidth,
                                    onChangeMixed: onChangeMixedWidth,
                                }}
                                min={0.01}
                                testId="width-input"
                            />
                        }
                        dropdownTriggerTestId={EditorDataTestId.BaseAttribute.WidthMinMaxSizeDropdownTrigger}
                        min={{
                            value: state.minW,
                            add: {
                                icon: <MonoIconPanelMinWidth16 />,
                                value: MinAndMaxSizeOption.addMinWidth,
                                text: translation('AddMinWidth'),
                            },
                            remove: {
                                value: MinAndMaxSizeOption.removeMinWidth,
                                text: translation('RemoveMinWidth'),
                            },
                        }}
                        max={{
                            value: state.maxW,
                            add: {
                                icon: <MonoIconPanelMaxWidth16 />,
                                value: MinAndMaxSizeOption.addMaxWidth,
                                text: translation('AddMaxWidth'),
                            },
                            remove: {
                                value: MinAndMaxSizeOption.removeMaxWidth,
                                text: translation('RemoveMaxWidth'),
                            },
                        }}
                        onChange={minMaxSizeSelectInputProps.onMinMaxSizeOptionChange}
                    />
                </SingleGrid.Item>
                <SingleGrid.Item start={29} span={22}>
                    <MinMaxSizeDropdown
                        hoverId={Wukong.DocumentProto.SizeHoverMenuItem.SIZE_HOVER_MENU_ITEM_HEIGHT}
                        disabled={
                            missFontStyle ||
                            state.h.editType ===
                                Wukong.DocumentProto.BaseAttributePropEditType.BASE_ATTRIBUTE_PROP_EDIT_TYPE_DISABLE
                        }
                        placement="bottom right"
                        inputLabel={
                            <ScrubbableInputNumber
                                disabled={
                                    missFontStyle ||
                                    state.h.editType ===
                                        Wukong.DocumentProto.BaseAttributePropEditType
                                            .BASE_ATTRIBUTE_PROP_EDIT_TYPE_DISABLE
                                }
                                icon={
                                    minMaxSizeSelectInputProps.hasMinMaxHeight ? (
                                        <MonoIconPanelHeightExtremes16 />
                                    ) : (
                                        <MonoIconPanelCoordinateH16 />
                                    )
                                }
                                labelTestId="coordinate-height-label"
                                defaultShowEmpty={
                                    state.h.editType ===
                                    Wukong.DocumentProto.BaseAttributePropEditType
                                        .BASE_ATTRIBUTE_PROP_EDIT_TYPE_SECONDARY
                                }
                                isMixed={
                                    state.h.valueType ===
                                    Wukong.DocumentProto.BaseAttributePropValueType.BASE_ATTRIBUTE_PROP_VALUE_TYPE_MIXED
                                }
                                placeholder={
                                    state.h.editType ===
                                    Wukong.DocumentProto.BaseAttributePropEditType
                                        .BASE_ATTRIBUTE_PROP_EDIT_TYPE_SECONDARY
                                        ? `${state.h.value}`
                                        : undefined
                                }
                                value={
                                    state.h.editType ===
                                    Wukong.DocumentProto.BaseAttributePropEditType
                                        .BASE_ATTRIBUTE_PROP_EDIT_TYPE_SECONDARY
                                        ? undefined
                                        : state.h.value
                                }
                                onChange={onChangeHeight}
                                mixedMathHandler={{
                                    getScrubStartValueMixed: () => new Map(Object.entries(state.h.values)),
                                    onScrubChangeAllMixed: onScrubChangeAllMixedHeight,
                                    onChangeMixed: onChangeMixedHeight,
                                }}
                                min={0.01}
                                testId="height-input"
                            />
                        }
                        dropdownTriggerTestId={EditorDataTestId.BaseAttribute.HeightMinMaxSizeDropdownTrigger}
                        min={{
                            value: state.minH,
                            add: {
                                icon: <MonoIconPanelMinHeight16 />,
                                value: MinAndMaxSizeOption.addMinHeight,
                                text: translation('AddMinHeight'),
                            },
                            remove: {
                                value: MinAndMaxSizeOption.removeMinHeight,
                                text: translation('RemoveMinHeight'),
                            },
                        }}
                        max={{
                            value: state.maxH,
                            add: {
                                icon: <MonoIconPanelMaxHeight16 />,
                                value: MinAndMaxSizeOption.addMaxHeight,
                                text: translation('AddMaxHeight'),
                            },
                            remove: {
                                value: MinAndMaxSizeOption.removeMaxHeight,
                                text: translation('RemoveMaxHeight'),
                            },
                        }}
                        onChange={minMaxSizeSelectInputProps.onMinMaxSizeOptionChange}
                    />
                </SingleGrid.Item>
                {constrainProportions?.show && (
                    <SingleGrid.Item start={51} span={12} horizontalCenter>
                        <Tooltip title={translation('ConstrainProportions')} canMouseDownClose>
                            <IconButton
                                onChange={onChangeConstrainProportions}
                                selected={constrainProportions.mixed ? true : !!constrainProportions.value}
                                selectedIcon={<MonoIconPanelConstraintScale16 data-testid="lock-ratio-true" />}
                                icon={<MonoIconPanelFreeScale16 data-testid="lock-ratio-false" />}
                                disabled={constrainProportions.disabled}
                            />
                        </Tooltip>
                    </SingleGrid.Item>
                )}
            </SingleGrid>
            <MinMaxSizeInputLayout
                inputMinW={
                    <MinMaxSizeInput
                        hoverId={Wukong.DocumentProto.SizeHoverMenuItem.SIZE_HOVER_MENU_ITEM_WIDTH}
                        tooltipTitle={translation('MinWidthToolTip')}
                        placeholder={translation('MinWidthPlaceHolder')}
                        propType={
                            Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                                .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_WIDTH
                        }
                        propValue={state.minW}
                        mixedMathHandler={{
                            getScrubStartValueMixed: () => new Map(Object.entries(state.minW.values)),
                            onScrubChangeAllMixed: onScrubChangeAllMixedMinWidth,
                            onChangeMixed: onChangeMixedMinWidth,
                        }}
                        onChange={onChangeMinWidth}
                        labelTestId={EditorDataTestId.BaseAttribute.MinWInputLabel}
                        icon={<MonoIconPanelMinWidth16 />}
                    />
                }
                inputMaxW={
                    <MinMaxSizeInput
                        hoverId={Wukong.DocumentProto.SizeHoverMenuItem.SIZE_HOVER_MENU_ITEM_WIDTH}
                        tooltipTitle={translation('MaxWidthToolTip')}
                        placeholder={translation('MaxWidthPlaceHolder')}
                        propType={
                            Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                                .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_WIDTH
                        }
                        propValue={state.maxW}
                        mixedMathHandler={{
                            getScrubStartValueMixed: () => new Map(Object.entries(state.maxW.values)),
                            onScrubChangeAllMixed: onScrubChangeAllMixedMaxWidth,
                            onChangeMixed: onChangeMixedMaxWidth,
                        }}
                        onChange={onChangeMaxWidth}
                        labelTestId={EditorDataTestId.BaseAttribute.MaxWInputLabel}
                        icon={<MonoIconPanelMaxWidth16 />}
                    />
                }
                inputMinH={
                    <MinMaxSizeInput
                        hoverId={Wukong.DocumentProto.SizeHoverMenuItem.SIZE_HOVER_MENU_ITEM_HEIGHT}
                        tooltipTitle={translation('MinHeightToolTip')}
                        placeholder={translation('MinHeightPlaceHolder')}
                        propType={
                            Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                                .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_HEIGHT
                        }
                        propValue={state.minH}
                        mixedMathHandler={{
                            getScrubStartValueMixed: () => new Map(Object.entries(state.minH.values)),
                            onScrubChangeAllMixed: onScrubChangeAllMixedMinHeight,
                            onChangeMixed: onChangeMixedMinHeight,
                        }}
                        onChange={onChangeMinHeight}
                        labelTestId={EditorDataTestId.BaseAttribute.MinHInputLabel}
                        icon={<MonoIconPanelMinHeight16 />}
                    />
                }
                inputMaxH={
                    <MinMaxSizeInput
                        hoverId={Wukong.DocumentProto.SizeHoverMenuItem.SIZE_HOVER_MENU_ITEM_HEIGHT}
                        tooltipTitle={translation('MaxHeightToolTip')}
                        placeholder={translation('MaxHeightPlaceHolder')}
                        propType={
                            Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                                .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_HEIGHT
                        }
                        propValue={state.maxH}
                        mixedMathHandler={{
                            getScrubStartValueMixed: () => new Map(Object.entries(state.maxH.values)),
                            onScrubChangeAllMixed: onScrubChangeAllMixedMaxHeight,
                            onChangeMixed: onChangeMixedMaxHeight,
                        }}
                        onChange={onChangeMaxHeight}
                        labelTestId={EditorDataTestId.BaseAttribute.MaxHInputLabel}
                        icon={<MonoIconPanelMaxHeight16 />}
                    />
                }
                showMinWidth={minMaxSizeSelectInputProps.showMinWidth}
                showMaxWidth={minMaxSizeSelectInputProps.showMaxWidth}
                showMinHeight={minMaxSizeSelectInputProps.showMinHeight}
                showMaxHeight={minMaxSizeSelectInputProps.showMaxHeight}
            />
        </>
    )
}
