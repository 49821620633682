import { translation } from './stroke-dash-len.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { EditorDataTestId } from '../../../../../window'
import { SingleGrid } from '../../../atom/grid/single-grid'
import { ScrubbableInputNumber } from '../../../atom/inputs/scrubbable-input-number'
import style from '../advance-stroke/advance-stroke.module.less'
import { useStrokeDashLen } from './use-stroke-dash-len'

// {translation('Dash')}-长度
export function StrokeDashLen() {
    const { dashLenState, onChangeDashLen } = useStrokeDashLen()
    return dashLenState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_HIDDEN ? (
        <></>
    ) : (
        <SingleGrid className={style.grid}>
            <SingleGrid.Item
                start={5}
                span={28}
                className={
                    dashLenState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_DISABLED
                        ? style.disabled
                        : undefined
                }
            >
                {translation('Dash')}
            </SingleGrid.Item>
            <SingleGrid.Item start={35} span={22}>
                <ScrubbableInputNumber
                    disabled={
                        dashLenState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_DISABLED
                    }
                    isMixed={
                        dashLenState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                    }
                    value={dashLenState?.value}
                    onChange={onChangeDashLen}
                    testId={EditorDataTestId.StrokeAttr.DashLenInput}
                    labelTestId={EditorDataTestId.StrokeAttr.DashLenInputLabel}
                    scrubbingDisabled={
                        dashLenState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                    }
                />
            </SingleGrid.Item>
        </SingleGrid>
    )
}
