import {
    HighlightNodesByIdsWasmCall,
    SearchLocateNodeWithinMotiffScopeWasmCall,
    ToggleMotiffScopeNodeExpandedWasmCall,
    Wukong,
} from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useCallback } from 'react'
import {
    MonoIconCommonEnterDown16,
    MonoIconCommonEnterRight16,
    ScrollView,
    Tooltip,
    WKCollapse,
    WKToast,
} from '../../../../../../ui-lib/src'
import { CommitType } from '../../../../document/command/commit-type'
import { getDocumentActiveElement } from '../../../../main/service/focus-view/focus-event'
import { useCommand } from '../../../context/document-context'
import { RightClickMenuWrapper } from '../../right-click-menu-wrapper'
import styles from './dummy-node-item.module.less'

export const DummyNodeItem = (props: {
    node: Wukong.DocumentProto.IDummyNodeData
    selectedId: string
    depth: number
    followSelection: boolean
    onClick: () => void
    highlightNode: (id: string) => void
}) => {
    const highlightNode = props.highlightNode
    const hasChildren = props.node.hasChildren
    const nodeId = props.node.id ?? ''
    const onClick = props.onClick
    const title = nodeId ? `(${nodeId}) ${props.node.name}` : props.node.name

    const onMouseEnter = () => {
        highlightNode(nodeId)
    }
    const onMouseLeave = () => {
        highlightNode('')
    }

    if (hasChildren) {
        return (
            <WKCollapse selectionMode="multiple" defaultSelection={[]}>
                <NodeCollapseItem
                    node={props.node}
                    itemId={props.node.id ?? ''}
                    title={title}
                    customHeaderStyle={{
                        paddingLeft: `${props.depth * 8}px`,
                        flexShrink: 0,
                    }}
                    selectedId={props.selectedId}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={onClick}
                    depth={props.depth}
                    followSelection={props.followSelection}
                    highlightNode={highlightNode}
                />
            </WKCollapse>
        )
    }
    return (
        <ScrollView.Item uniqueKey={nodeId}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: `${(props.depth + 2) * 8}px`,
                    height: '32px',
                }}
                className={classNames({
                    [styles.leftPanelItemSelected]: props.selectedId === nodeId,
                })}
                onClick={onClick}
            >
                <NodeTypePill type={props.node.type} />
                <Tooltip title={props.node.name}>
                    <div
                        className="wk-text-12 wk-font-semibold pl-1 whitespace-nowrap truncate"
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                    >
                        {title}
                    </div>
                </Tooltip>
            </div>
        </ScrollView.Item>
    )
}

export const DummyNodeItemWrapper = (props: {
    node: Wukong.DocumentProto.IDummyNodeData
    selectedId: string
    depth: number
    followSelection: boolean
    highlightNode: (id: string) => void
}) => {
    const nodeId = props.node.id ?? ''
    const command = useCommand()
    const onClick = useCallback(() => {
        if (nodeId === '-1') {
            return
        }
        const ret = command.invokeBridge(CommitType.CommitUndo, SearchLocateNodeWithinMotiffScopeWasmCall, {
            value: nodeId,
        })
        if (!ret.value) {
            WKToast.error('搜索节点不存在')
        }
        getDocumentActiveElement()?.blur()
    }, [nodeId, command])
    return (
        <RightClickMenuWrapper
            source={Wukong.DocumentProto.RightClickSource.RIGHT_CLICK_SOURCE_MOTIFF_SCOPE}
            stopPropagation
            onMouseDown={onClick}
            hidden={nodeId === '-1'}
        >
            <DummyNodeItem
                node={props.node}
                selectedId={props.selectedId}
                depth={props.depth}
                followSelection={props.followSelection}
                onClick={onClick}
                highlightNode={props.highlightNode}
            />
        </RightClickMenuWrapper>
    )
}

const NodeTypePill = ({ type }: { type: string }) => {
    const getBorderColor = () => {
        switch (type) {
            // 蓝色
            case '矢量':
                return '#4096FF'
            case '矩形':
                return '#4096FF'
            case '星形':
                return '#4096FF'
            case '多边形':
                return '#4096FF'
            case '线条':
                return '#4096FF'
            case '布尔运算':
                return '#4096FF'
            case '椭圆':
                return '#4096FF'
            case '切片':
                return '#4096FF'
            // 绿色
            case '容器':
                return '#00C49F'
            case '组':
                return '#00C49F'
            case '文本':
                return '#00C49F'
            // 紫色
            case '组件集':
                return '#9466FF'
            case '组件':
                return '#9466FF'
            case '实例':
                return '#9466FF'
            case '变量':
                return '#9466FF'
            case '变量集':
                return '#9466FF'
            case '样式容器':
                return '#9466FF'
            case '文本样式':
                return '#9466FF'
            case '效果样式':
                return '#9466FF'
            case '布局网格样式':
                return '#9466FF'
            case '填充样式':
                return '#9466FF'
            case '评论':
                return '#9466FF'
            // 灰色
            default:
                return '#808080'
        }
    }
    // 生成一个圆角tag元素
    return (
        <div style={{ border: `1px solid ${getBorderColor()}` }} className={styles.pill}>
            {type}
        </div>
    )
}

export interface NodeCollapseItemProps {
    node: Wukong.DocumentProto.IDummyNodeData
    title: string
    onHoverTitleEnter?: () => void
    onHoverTitleLeave?: () => void
    itemRadius?: 'none' | 'default'
    customHeaderStyle?: React.CSSProperties
    selectedId: string
    onMouseEnter?: () => void
    onMouseLeave?: () => void
    onClick?: () => void
    itemId: string
    selectionBlockWidth?: number
    depth: number
    followSelection: boolean
    highlightNode: (id: string) => void
}

const NodeCollapseItem = ({
    node,
    title,
    itemRadius = 'none',
    customHeaderStyle,
    selectedId,
    itemId,
    onMouseEnter,
    onMouseLeave,
    onClick,
    selectionBlockWidth,
    depth,
    followSelection,
    highlightNode,
}: NodeCollapseItemProps) => {
    const command = useCommand()
    const isOpened = node.expanded
    const getHeaderClassNames = () => {
        return classNames(
            'group flex flex-row items-center box-border h-32px focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset--2 focus-visible:outline-$wk-brand-7 truncate',
            {
                'rounded-none': itemRadius === 'none',
                'rounded-$wk-radius-default': itemRadius === 'default',
                [styles.leftPanelItemSelected]: selectedId === itemId,
            }
        )
    }

    const getIconClassNames = () => {
        return classNames(
            'flex-none flex group-hover:color-$wk-v2-label-color-gray-13',
            {
                'color-$wk-v2-label-color-gray-13': isOpened,
                'color-$wk-gray-6': !isOpened,
            },
            styles.expand
        )
    }

    const handleIconClick = useCallback(
        (e: React.MouseEvent) => {
            onClick?.()
            command.invokeBridge(CommitType.Noop, ToggleMotiffScopeNodeExpandedWasmCall, {
                nodeId: itemId,
                expanded: !isOpened,
            })
            e.preventDefault()
            e.stopPropagation()
        },
        [command, itemId, isOpened, onClick]
    )

    return (
        <ScrollView.Item uniqueKey={itemId} style={{ width: `${selectionBlockWidth}px` }}>
            <div className={classNames('flex flex-col bg-$wk-white')}>
                <div
                    className={getHeaderClassNames()}
                    tabIndex={0}
                    style={customHeaderStyle}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={onClick}
                >
                    <div className={getIconClassNames()} onClick={handleIconClick}>
                        {isOpened ? <MonoIconCommonEnterDown16 /> : <MonoIconCommonEnterRight16 />}
                    </div>
                    <div className={getHeaderClassNames()}>
                        <NodeTypePill type={node.type} />
                        <Tooltip title={node.name}>
                            <div className="wk-text-12 wk-font-semibold pl-1 truncate">{title}</div>
                        </Tooltip>
                    </div>
                </div>
                <div>
                    {isOpened ? (
                        <>
                            {' '}
                            {node.collectedChildren.map((child) => {
                                return (
                                    <DummyNodeItemWrapper
                                        node={child}
                                        key={child.id}
                                        selectedId={selectedId}
                                        depth={depth + 1}
                                        followSelection={followSelection}
                                        highlightNode={highlightNode}
                                    />
                                )
                            })}
                        </>
                    ) : null}
                </div>
            </div>
        </ScrollView.Item>
    )
}
