import { translation } from './shortcut-guide.translation'
/* eslint-disable no-restricted-imports */
import { ToggleShowShortcutPanelWasmCall, Wukong } from '@wukong/bridge-proto'
import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { useCommand } from '../../../main/app-context'
import { useSetBottomPanelShown } from '../../../main/layout/layout-context'
import { DEFAULT_BOTTOM_BANNER_HEIGHT } from '../../../main/layout/layout-default-value'
import { useViewState } from '../../../view-state-bridge'
import {
    componentTabCenter,
    componentTabLeft,
    componentTabRight,
    editTabCenter,
    editTabLeft,
    editTabRight,
    layerTabCenter,
    layerTabLeft,
    layerTabRight,
    selectionTabCenter,
    selectionTabLeft,
    selectionTabRight,
    sortTabCenter,
    sortTabLeft,
    sortTabRight,
    textTabCenter,
    textTabLeft,
    textTabRight,
    toolTabCenter,
    toolTabLeft,
    toolTabRight,
    transformTabCenter,
    transformTabLeft,
    transformTabRight,
    vectorTabCenter,
    vectorTabLeft,
    vectorTabRight,
    viewTabCenter,
    viewTabLeft,
    viewTabRight,
} from './data'
import { DevModeShortcutGuidePanel } from './dev-mode-shortcut-guide-panel'
import classes from './shortcut-guide.module.less'
import { TabContainer } from './tab-container'
import { Tab, Tabs, TabsProps } from './tabs/tabs'

export interface ShortcutGuidePanelTab {
    key: string
    name: string
    component: JSX.Element
}

export function DesignModeShortcutGuidePanel() {
    const command = useCommand()
    const tabs = useMemo((): TabsProps['tabs'] => {
        return [
            {
                key: '1',
                name: translation('Tools'),
                component: <TabContainer left={toolTabLeft} center={toolTabCenter} right={toolTabRight} />,
            },
            {
                key: '2',
                name: translation('View'),
                component: <TabContainer left={viewTabLeft(true)} center={viewTabCenter} right={viewTabRight} />,
            },
            {
                key: '3',
                name: translation('Text'),
                component: <TabContainer left={textTabLeft} center={textTabCenter} right={textTabRight} />,
            },
            {
                key: '4',
                name: translation('Shape'),
                component: <TabContainer left={vectorTabLeft} center={vectorTabCenter} right={vectorTabRight} />,
            },
            {
                key: '5',
                name: translation('Components'),
                component: (
                    <TabContainer left={componentTabLeft} center={componentTabCenter} right={componentTabRight} />
                ),
            },
            {
                key: '6',
                name: translation('Selection'),
                component: (
                    <TabContainer left={selectionTabLeft} center={selectionTabCenter} right={selectionTabRight} />
                ),
            },
            {
                key: '7',
                name: translation('Transform'),
                component: (
                    <TabContainer left={transformTabLeft} center={transformTabCenter} right={transformTabRight} />
                ),
            },
            {
                key: '8',
                name: translation('Object'),
                component: <TabContainer left={layerTabLeft} center={layerTabCenter} right={layerTabRight} />,
            },
            {
                key: '9',
                name: translation('Edit'),
                component: <TabContainer left={editTabLeft} center={editTabCenter} right={editTabRight} />,
            },
            {
                key: '10',
                name: translation('Arrange'),
                component: <TabContainer left={sortTabLeft} center={sortTabCenter} right={sortTabRight} />,
            },
        ]
    }, [])

    const [activeKey, setActiveKey] = useState<TabsProps['activeKey']>(tabs[0].key)

    const onClickClose = useCallback(() => {
        command.DEPRECATED_invokeBridge(ToggleShowShortcutPanelWasmCall)
    }, [command])

    const onSwitchTab = useCallback((tab: Tab) => {
        setActiveKey(tab.key)
    }, [])

    return (
        <Tabs
            className={classes.shortcutGuide}
            style={{ height: DEFAULT_BOTTOM_BANNER_HEIGHT }}
            tabs={tabs}
            activeKey={activeKey}
            onClickClose={onClickClose}
            onSwitchTab={onSwitchTab}
            dataTestId="design-mode-shortcut-guide-panel"
        />
    )
}

export function ShortcutGuide() {
    const showShortcutPanel = useViewState('showShortcutPanel')
    const setRightPanelShown = useSetBottomPanelShown()

    useLayoutEffect(() => {
        setRightPanelShown(!!showShortcutPanel)
    }, [setRightPanelShown, showShortcutPanel])

    const editorType = useViewState('editorType')
    const isDevMode = editorType === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV

    if (!showShortcutPanel) {
        return null
    }

    if (isDevMode) {
        return <DevModeShortcutGuidePanel />
    }

    return <DesignModeShortcutGuidePanel />
}
