import { translation } from './text-candidate.translation'
/* eslint-disable no-restricted-imports */
import { debounce } from 'lodash-es'
import { useCallback, useEffect, useRef, useState } from 'react'
import { ScrollView } from '../../../../../../ui-lib/src'
import { TextIcon } from './icons'
import styles from './index.module.less'

import { useFontManagerService } from '../../../context/document-context'
import { CopilotCommandType, useAiMagicCommands } from './commands'
import { logs } from './logs'

import type { AIMagicState } from '.'
import { WKLoading } from '../../../../../../ui-lib/src/components/wk-loading/wk-loading'
import { AIMagicIds } from './ai-magic-ids'
import { Rect, RenderCandidateNode, RenderTextCandidateNode } from './type'
import { useHoveringSwitch } from './use-hovering-switch'

/**
 * 文本输入模式
 */
function useTextCandidateState(props: AIMagicTextProps) {
    // 加载状态
    const [loading, setLoading] = useState(true)

    const visible = props.magicState.magicMode === 'text'

    // 文本模式下的输入框
    const textInputRef = useRef<HTMLInputElement>(null)

    // 文本输入内容
    const [textInput, _setTextInput] = useState(props.magicState.textInput)
    function setTextInput(input: string) {
        _setTextInput(input)
        props.magicState.setTextInput(input)
    }

    // 绘制魔法框后自动聚焦到输入框
    useEffect(() => {
        if (props.magicState.showCandidate) {
            textInputRef.current?.focus()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.magicState.showCandidate])

    function onTextInput(input: string) {
        setTextInput(input)
    }

    function onTextKeydown(event: any) {
        if (loading) {
            return
        }

        const e = event as KeyboardEvent

        // 用户没有选中任何候选文本时，按下回车选中第一个候选文本
        if (e.code === 'Enter') {
            if (selectedCandidateIndex < 0) {
                setSelectedCandidateIndex(0)
            } else if (selectedCandidateIndex < candidates.length) {
                applyItem(candidates[selectedCandidateIndex])
            }
        } else {
            if (e.code === 'ArrowUp') {
                const newIndex = Math.max(0, selectedCandidateIndex - 1)
                setSelectedCandidateIndex(newIndex)
                previewItem(candidates[newIndex])
                e.preventDefault()
            } else if (e.code === 'ArrowDown') {
                const newIndex = Math.min(candidates.length - 1, selectedCandidateIndex + 1)
                setSelectedCandidateIndex(newIndex)
                previewItem(candidates[newIndex])
                e.preventDefault()
            }
        }
    }

    // 无手机屏数据状态
    const [notMobileScreen, setNotMobileScreen] = useState(false)

    const [candidates, setCandidates] = useState<RenderTextCandidateNode[]>([])
    const noCandidates = candidates.length === 0
    const showCandidateList = textInput.trim().length > 0 || !noCandidates
    const [selectedCandidateIndex, setSelectedCandidateIndex] = useState(-1)

    // 用于预览候选组件
    const commands = useAiMagicCommands()
    const insertInfo = useRef({
        parentNodeId: '',
        mobileScreenNodeId: '',
    })
    const lastPreviewNodeId = useRef('')
    const encodedNodeBlobInfo = useRef('')

    async function previewTextCandidates(
        res: Awaited<ReturnType<CopilotCommandType['requestAiCopilot']>>,
        defaultPreviewFirstItem = false,
        worldRect = props.magicState.worldRect
    ) {
        setCandidates([])
        if (!res.candidates) return
        const imageResults = await commands.getImageOfFigNodes(res.candidates, res.nodeBlobInfo!, worldRect, true)
        if (!imageResults) {
            return
        }

        // 获取文本节点的准确偏移位置
        const textNodePositions = await commands.getTextPosition({
            traceId: res.traceId,
            positionQueryList: res.candidates.map((c, i) => ({
                componentData: c.componentData,
                category: c.category,
                boundingBox: imageResults.images![i].textBounds as Rect,
                extendInfos: c.extendInfos,
            })),
        })

        res.candidates.forEach((c) => {
            const result = textNodePositions.get(c.componentData)
            if (!result) return
            c.position = result.position
        })

        const renderCandidates = res.candidates.map((node, i) => {
            // 获取文本描述
            const image = imageResults.images![i]
            let fontDescs: string[] = []
            const fontSize = (Math.round(image.fontDesc!.fontSize! * 100) / 100).toString() + 'px'
            if (!image.fontDesc!.hasStyleName) {
                const localizedFontInfo = getLocalizedFontInfo(image.fontDesc!.fontFamily!, image.fontDesc!.fontStyle!)
                fontDescs = [fontSize, localizedFontInfo.fontStyle, localizedFontInfo.fontFamily]
            } else {
                fontDescs = [fontSize, image.fontDesc!.fontStyleName!]
            }
            const fontDesc = fontDescs.join(' / ')

            return {
                width: image.width!,
                height: image.height!,
                fontDesc,
                background: imageResults.images![i].background! as any,
                image: commands.getImageUrlFromBase64(imageResults.images![i].image!),
                ...node,
            }
        })
        setCandidates(renderCandidates)

        // 默认预览第一个元素
        if (defaultPreviewFirstItem && renderCandidates.length > 0) {
            setSelectedCandidateIndex(0)
            previewItem(renderCandidates[0], worldRect)
        }
    }

    const latestRequestId = useRef(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const requestAiCopilot = useCallback(
        debounce(
            (rect: Rect, _textInput: string) => {
                let canceled = false
                setNotMobileScreen(false)

                // 用户没有输入文本时，不进行推荐
                if (_textInput.trim().length === 0) {
                    setLoading(false)
                    setCandidates([])
                    return
                }

                const requestId = (latestRequestId.current += 1)
                const isValidRequest = () => requestId === latestRequestId.current

                commands
                    .requestAiCopilot(rect, _textInput, 'text')
                    .then(async (res) => {
                        if (canceled || !isValidRequest()) {
                            return
                        }

                        if (res.noMobileScreenData) {
                            setNotMobileScreen(true)
                            return
                        }

                        if (!res?.candidates?.length) {
                            setCandidates([])
                            // 文本魔法框无候选
                            logs.AiMagicBound.textNoCandidate()
                            return
                        }

                        // 暂存信息
                        insertInfo.current.mobileScreenNodeId = res.info.mobileScreenNodeId ?? ''
                        insertInfo.current.parentNodeId = res.info.parentId ?? ''
                        encodedNodeBlobInfo.current = JSON.stringify(res.nodeBlobInfo)

                        // 文本节点不需要轮询
                        await previewTextCandidates(res, res.candidates.length > 0, rect)
                    })
                    .catch(() => {
                        if (isValidRequest()) {
                            setCandidates([])
                        }
                    })
                    .finally(() => {
                        if (isValidRequest()) {
                            setLoading(false)
                        }
                    })

                return {
                    cancel() {
                        canceled = true
                        if (isValidRequest()) {
                            setLoading(false)
                        }
                    },
                }
            },
            300,
            {
                trailing: true,
            }
        ),
        []
    )

    // 请求 ai copilot 服务
    useEffect(() => {
        if (!props.magicState.showCandidate) {
            return
        }
        setSelectedCandidateIndex(-1)
        setLoading(true)
        const handler = requestAiCopilot(props.magicState.worldRect, textInput)

        return () => {
            handler?.cancel()
            unpreview()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textInput, ...Object.values(props.magicState.worldRect), props.magicState.showCandidate])

    // 做一次限流，防止出现闪现
    const lastPreviewTimoutId = useRef<ReturnType<typeof setTimeout>>()
    function previewItem(candidate: RenderCandidateNode, worldRect = props.magicState.worldRect) {
        if (lastPreviewTimoutId.current) {
            clearTimeout(lastPreviewTimoutId.current)
        }
        lastPreviewTimoutId.current = setTimeout(() => {
            unpreview()
            const previewNodeId =
                commands.applyAiCopilotResultTo(
                    insertInfo.current.parentNodeId,
                    insertInfo.current.mobileScreenNodeId,
                    candidate,
                    encodedNodeBlobInfo.current,
                    true,
                    worldRect
                ).nodeId ?? ''
            lastPreviewNodeId.current = previewNodeId
        }, 50)
    }

    function unpreview() {
        if (lastPreviewNodeId.current) {
            commands.removeNode(lastPreviewNodeId.current)
        }
    }

    function applyItem(candidate: RenderCandidateNode) {
        unpreview()
        commands.exitCopilot()
        setTimeout(() => {
            commands.applyAiCopilotResultTo(
                insertInfo.current.parentNodeId,
                insertInfo.current.mobileScreenNodeId,
                candidate,
                encodedNodeBlobInfo.current,
                false,
                props.magicState.worldRect
            )
            commands.decreaseLabCredits()
        }, 1)
    }

    // 字体名称本地化
    const fontManagerService = useFontManagerService()
    const family2FontInfoMap = fontManagerService.states.use.family2FontInfoMapState()

    function getLocalizedFontInfo(fontFamily: string, fontStyle: string) {
        const localizedFontInfo = {
            fontFamily,
            fontStyle,
        }

        const fontInfo = family2FontInfoMap[fontFamily]
        if (fontInfo) {
            localizedFontInfo.fontFamily = fontInfo.localizedFamily
            const localizedStyle = fontInfo.styles.find((s) => s.style === fontStyle)
            if (localizedStyle) {
                localizedFontInfo.fontStyle = localizedStyle.localizedStyle
            }
        }

        return localizedFontInfo
    }

    // 用于判断当前是否在滚动列表
    const isScrolling = useRef(false)
    const lastScrollTimeoutId = useRef<ReturnType<typeof setTimeout>>()
    function onCandidateScroll() {
        isScrolling.current = true
        if (lastScrollTimeoutId.current) {
            clearTimeout(lastScrollTimeoutId.current)
        }
        // 停止滚动 100ms 后才响应 hover 事件
        lastScrollTimeoutId.current = setTimeout(() => {
            isScrolling.current = true
        }, 100)
    }

    return {
        visible,

        // 文本输入框
        textInputRef,
        textInput,
        onTextInput,
        onTextKeydown,

        // 候选列表
        loading,
        notMobileScreen,

        isScrolling,
        onCandidateScroll,
        showCandidateList,
        candidates,
        noCandidates,
        selectedCandidateIndex,
        setSelectedCandidateIndex,

        previewItem,
        applyItem,
        unpreview,

        getLocalizedFontInfo,
    }
}

export interface AIMagicTextProps {
    magicState: AIMagicState
}

export function AIMagicTextCandidate(props: AIMagicTextProps) {
    const candidateState = useTextCandidateState(props)
    const hoveringSwitch = useHoveringSwitch({ setShowSwitch: props.magicState.setShowSwitch })

    return (
        <>
            <div className={styles['prompt-input']} style={{ width: 400 }} data-testid={AIMagicIds.prompt.text.main}>
                <div className={styles['prompt-input-icon']}>
                    <TextIcon />
                    <div
                        className={styles['icon-hotarea']}
                        onMouseOver={hoveringSwitch.onMouseInSwitchArea}
                        onMouseOut={hoveringSwitch.onMouseOutSwitchArea}
                        data-testid={AIMagicIds.prompt.text.icon}
                    ></div>
                </div>
                <div className={`${styles['prompt-input-text']}`}>
                    <input
                        ref={candidateState.textInputRef}
                        onInput={(e) => candidateState.onTextInput(e.currentTarget.value)}
                        onKeyDown={candidateState.onTextKeydown}
                        placeholder={translation('ezHbgp')}
                        value={candidateState.textInput}
                        autoFocus
                    />
                </div>
            </div>
            {candidateState.showCandidateList && (
                <div className={styles['prompt-candidate']}>
                    <ScrollView
                        scrollbar={{
                            autoHeight: true,
                            autoHeightMax: 420,
                        }}
                        selectKey={candidateState.selectedCandidateIndex}
                        block="nearest"
                    >
                        {(candidateState.noCandidates || candidateState.notMobileScreen || candidateState.loading) && (
                            <div className={styles['prompt-candidate-empty']}>
                                {candidateState.loading ? (
                                    <WKLoading noText />
                                ) : candidateState.notMobileScreen ? (
                                    <div>{translation('QPADHG')}</div>
                                ) : candidateState.noCandidates ? (
                                    <div>
                                        <div>{translation('UUJhjY')}</div>
                                        <div>{translation('DapWZQ')}</div>
                                    </div>
                                ) : null}
                            </div>
                        )}
                        {!(candidateState.noCandidates || candidateState.loading) && (
                            <div className={styles['text-candidate-list']}>
                                {candidateState.candidates.map((c, i) => (
                                    <ScrollView.Item key={i} uniqueKey={i} onScroll={candidateState.onCandidateScroll}>
                                        <div
                                            className={`${styles['text-candidate-list-item']} ${
                                                candidateState.selectedCandidateIndex === i &&
                                                styles['text-candidate-list-item-selected']
                                            }`}
                                            onClick={() => {
                                                candidateState.applyItem(c)
                                                // 文本魔法框选中候选
                                                logs.AiMagicBound.textSelectSuccess({ rank: i })
                                            }}
                                            onMouseEnter={() => {
                                                // 如果列表正在滚动，不响应预览事件
                                                if (candidateState.isScrolling.current) return
                                                candidateState.setSelectedCandidateIndex(i)
                                                candidateState.previewItem(c)
                                            }}
                                            onMouseLeave={() => candidateState.unpreview()}
                                        >
                                            <div
                                                className={`${styles['text-candidate-preview']} ${
                                                    styles['text-candidate-preview-' + c.background]
                                                }`}
                                            >
                                                <img
                                                    src={c.image}
                                                    style={{
                                                        maxWidth: c.width,
                                                        maxHeight: c.height,
                                                    }}
                                                ></img>
                                            </div>
                                            <div className={`${styles['text-candidate-font']}`}>{c.fontDesc}</div>
                                        </div>
                                    </ScrollView.Item>
                                ))}
                            </div>
                        )}
                    </ScrollView>
                </div>
            )}
        </>
    )
}
