import type { Reader, Writer } from '@wukong/bridge-proto'
import { Wukong } from '@wukong/bridge-proto'

export interface NotifyWebSocketMessage<T> {
    code: Wukong.NotifyProto.MessageTypeCode
    bodyType: {
        decode: (reader: Reader | Uint8Array) => T
        encode: (arg: Partial<T>) => Writer
    }
}

export const WsPing: NotifyWebSocketMessage<Wukong.NotifyProto.PingProto> = {
    code: Wukong.NotifyProto.MessageTypeCode.TC_Ping,
    bodyType: Wukong.NotifyProto.PingProto,
}

export const WsPong: NotifyWebSocketMessage<Wukong.NotifyProto.PongProto> = {
    code: Wukong.NotifyProto.MessageTypeCode.TC_Pong,
    bodyType: Wukong.NotifyProto.PongProto,
}

export const WsAuth: NotifyWebSocketMessage<Wukong.NotifyProto.AuthProto> = {
    code: Wukong.NotifyProto.MessageTypeCode.TC_Auth,
    bodyType: Wukong.NotifyProto.AuthProto,
}

export const WsSubscription: NotifyWebSocketMessage<Wukong.NotifyProto.SubscriptionProto> = {
    code: Wukong.NotifyProto.MessageTypeCode.TC_SubscriptionEnable,
    bodyType: Wukong.NotifyProto.SubscriptionProto,
}

export const WsUnSubscription: NotifyWebSocketMessage<Wukong.NotifyProto.SubscriptionProto> = {
    code: Wukong.NotifyProto.MessageTypeCode.TC_SubscriptionDisable,
    bodyType: Wukong.NotifyProto.SubscriptionProto,
}

export const WsAuthResult: NotifyWebSocketMessage<Wukong.NotifyProto.AuthResultProto> = {
    code: Wukong.NotifyProto.MessageTypeCode.TC_AuthResult,
    bodyType: Wukong.NotifyProto.AuthResultProto,
}

export const WsError: NotifyWebSocketMessage<Wukong.NotifyProto.ErrorProto> = {
    code: Wukong.NotifyProto.MessageTypeCode.TC_Error,
    bodyType: Wukong.NotifyProto.ErrorProto,
}

export const WsBusinessMessage: NotifyWebSocketMessage<Wukong.NotifyProto.BusinessMessageProto> = {
    code: Wukong.NotifyProto.MessageTypeCode.TC_BusinessMessage,
    bodyType: Wukong.NotifyProto.BusinessMessageProto,
}

export interface NotifyWebSocketBusinessMessage<T> {
    code: Wukong.NotifyProto.BusinessCode
    bodyType: {
        decode: (reader: Reader | Uint8Array) => T
        encode: (arg: Partial<T>) => Writer
    }
}

export const WsMirrorMessage: NotifyWebSocketBusinessMessage<Wukong.NotifyProto.Mirror> = {
    code: Wukong.NotifyProto.BusinessCode.MIRROR,
    bodyType: Wukong.NotifyProto.Mirror,
}

export const WsCommentMessage: NotifyWebSocketBusinessMessage<Wukong.NotifyProto.Comment> = {
    code: Wukong.NotifyProto.BusinessCode.COMMENT,
    bodyType: Wukong.NotifyProto.Comment,
}

export const WsPropertyChangeMessage: NotifyWebSocketBusinessMessage<Wukong.NotifyProto.BusinessEntityPropertiesChange> =
    {
        code: Wukong.NotifyProto.BusinessCode.BUSINESS_ENTITY_PROPERTY_CHANGE,
        bodyType: Wukong.NotifyProto.BusinessEntityPropertiesChange,
    }

export const WsRelationChangeMessage: NotifyWebSocketBusinessMessage<Wukong.NotifyProto.BusinessEntityRelationChange> =
    {
        code: Wukong.NotifyProto.BusinessCode.BUSINESS_ENTITY_RELATION_CHANGE,
        bodyType: Wukong.NotifyProto.BusinessEntityRelationChange,
    }
