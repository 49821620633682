import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Scale: '等比缩放',
} as const

export const enTranslation = {
    Scale: 'Scale',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
