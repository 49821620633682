import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    ComponentConfig: '组件设置',
    PublishSelectedComponents: '发布选中组件',
} as const

export const enTranslation = {
    ComponentConfig: 'Component configuration',
    PublishSelectedComponents: 'Publish changes...',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
