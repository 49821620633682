import 'reflect-metadata'

import '../../../../../palette/src/index.css'
import '../../main.less'

import 'core-js'
import '../../../../../../../ui-lib/src'
// unocss 的位置不要随便更改
// 看了一下，这个没有办法优化了
// unocss 的引入顺序应该在 ui-lib 之后，app 业务代码之前
// 现在的写法已经是最好的了
// eslint-disable-next-line import/no-unresolved
import 'uno.css'

// eslint-disable-next-line import/no-nodejs-modules
import { Buffer } from 'buffer'

import { command } from 'ccstate'
import { areaUnion } from '../../../../../../../ui-lib/src/components/component-union/position-union'
import { wkNotification } from '../../../../../../../ui-lib/src/components/wk-notification'
import { TraceableAbortSignal } from '../../../../../../../util/src/abort-controller/traceable-abort-controller'
import { transaction } from '../../../../../../../util/src/abort-controller/traceable-transaction'
import { getCommitId, getReleaseTag } from '../../../../../../../util/src/build-info'
import { traceable } from '../../../../../../../util/src/ccstate-helper/traceable'
import { abroadOldIdRedirect } from '../../../../../../../util/src/redirect'
import { IN_JEST_TEST } from '../../../../../environment'
import { WkCLog } from '../../../../../kernel/clog/wukong/instance'
import { WK } from '../../../../../window'

export const bootstrapCommon$ = traceable(
    'hulh01@kanyun.com',
    command((_, signal: TraceableAbortSignal) => {
        const { act } = transaction(signal)

        if (!IN_JEST_TEST) {
            act('inject lazysizes', () => {
                import('lazysizes')
                // @ts-expect-error
                import('lazysizes/plugins/parent-fit/ls.parent-fit')
            })

            act('hooks global fetch to report network error', () => {
                const oldFetch = window.fetch

                window.fetch = function (input: RequestInfo | URL, init?: RequestInit) {
                    return oldFetch(input, init).catch((error) => {
                        WkCLog.preLog('motiff_network_error', {
                            value: `failed to fetch, url: ${input}, error: ${error}`,
                        })
                        throw new Error('motiff_network_error', error)
                    })
                }

                return () => {
                    window.fetch = oldFetch
                }
            })

            // 对于 motiff.com 如果访问曾经创建的文件，则跳转到 .cn 域名
            abroadOldIdRedirect(signal)

            act('inject commitID And ReleaseTag', () => {
                // eslint-disable-next-line no-process-env
                console.info(`Running frontend build ${process.env.BUILD_ID}`)

                const commitId = (WK.commitId = getCommitId() ?? 'unknown')
                console.info(`Running frontend commit ${commitId}`)

                const releaseTag = (WK.releaseTag = getReleaseTag())
                console.info(`Running frontend release tag ${releaseTag}`)

                return () => {
                    WK.commitId = undefined
                    WK.releaseTag = undefined
                }
            })
        }

        act('init notification', () => {
            const cleanup = wkNotification.init()

            if (!IN_JEST_TEST) {
                return cleanup
            }
        })

        act('init area union', () => {
            const cleanup = areaUnion.init()

            if (!IN_JEST_TEST) {
                return cleanup
            }
        })

        act('init Buffer', () => {
            const oldBuffer = window.Buffer

            window.Buffer = Buffer

            return () => {
                window.Buffer = oldBuffer
            }
        })

        return null
    })
)
