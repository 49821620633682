import { useUserConfigStateV2 } from '../../../../../../main/user-config/user-config-hook'
import { ComponentPickerViewMode } from './type'

export function useComponentPickerViewMode() {
    const [viewMode2, setViewMode2] = useUserConfigStateV2('componentPickerViewMode')

    return {
        viewMode: viewMode2,
        setViewMode: (newVal: ComponentPickerViewMode) => {
            setViewMode2(newVal)
        },
    }
}
