import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    本地变量弹框标题: '变量',
    下拉菜单重命名合集: '重命名合集',
    下拉菜单删除合集: '删除合集',
    下拉菜单添加合集: '添加合集',
    侧边栏全部变量: '全部变量',
    创建变量: '创建变量',
    创建颜色变量: '颜色变量',
    创建颜色变量简写: '颜色变量',
    创建数值变量: '数值变量',
    创建数值变量简写: '数值变量',
    空数据描述1: '创建可复用的颜色变量，',
    空数据描述2: '实现模式快速切换。',
    空数据了解更多: '了解更多',
    更多操作: '右键点击变量可进行更多操作',
    重命名分组: '重命名分组',
    复制分组: '创建分组副本',
    复制分组复数: '创建分组副本',
    取消分组: '取消分组',
    删除分组: '删除分组',
    删除分组复数: '删除分组',
} as const

export const enTranslation = {
    本地变量弹框标题: 'Variables',
    下拉菜单重命名合集: 'Rename collection',
    下拉菜单删除合集: 'Delete collection',
    下拉菜单添加合集: 'Create collection',
    侧边栏全部变量: 'All variables',
    创建变量: 'Create variable',
    创建颜色变量: 'Color variable',
    创建颜色变量简写: 'Color',
    创建数值变量: 'Number variable',
    创建数值变量简写: 'Number',
    空数据描述1: 'Create reusable color variables',
    空数据描述2: 'for quick mode switching. ',
    空数据了解更多: 'Learn more',
    更多操作: 'Right-click variables for more options',
    重命名分组: 'Rename group',
    复制分组: 'Duplicate group',
    复制分组复数: 'Duplicate groups',
    取消分组: 'Ungroup',
    删除分组: 'Delete group',
    删除分组复数: 'Delete groups',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
