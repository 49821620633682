/* eslint-disable no-restricted-imports */
import {
    GetLastOperationIndex,
    OnMicroTask,
    RefreshPage,
    RefreshPageWithDialog,
    SetReleaseVersion,
    Wukong,
} from '@wukong/bridge-proto'
import { BehaviorSubject, ReplaySubject, Subject, firstValueFrom } from 'rxjs'
import {
    ValidSearchParams,
    domLocation,
    getCommitId,
    getReleaseTag,
    getUrlDocId,
    isDocumentVisible,
    queryStringify,
} from '../../../../util/src'
import { signalDebounce } from '../../../../util/src/abort-controller/signal-task'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { transaction } from '../../../../util/src/abort-controller/traceable-transaction'
import { createCombineLatestEventHandler } from '../../../../util/src/combine-latest-event-handler'
import { ClassWithEffect, EffectController } from '../../../../util/src/effect-controller'
import { SimpleEventEmitter } from '../../../../util/src/event-emitter/simple-event-emitter'
import { environment } from '../../environment'
import { synergyAuthed$ } from '../../external-store/atoms/synergy-auth'
import { appStore$ } from '../../external-store/store'
import { Bridge } from '../../kernel/bridge/bridge'
import { WkCLog } from '../../kernel/clog/wukong/instance'
import { debugLog } from '../../kernel/debug'
import { DocID, RoleStatus } from '../../kernel/interface/type'
import { RoleStatusWeight, compareRole } from '../../kernel/interface/user-roles'
import { MetricCollector, MetricName } from '../../kernel/metric-collector'
import { setCurrentSessionId } from '../../kernel/recording/upload'
import { featureSwitchManager } from '../../kernel/switch/core'
import { reLogin } from '../../kernel/util/expire-login'
import { PreconnectData, PreconnectEvent } from '../../preconnect'
import { previewSenceEnum2value } from '../../prototype/context/preview-scene'
import { shouldApplyBetaForceUpgrade } from '../../utils/release'
import { WK } from '../../window'
import { ReportConnectionContext } from './report-context'
import { WebSocketMessage, WsAuthChanged, WsAuthResult, WsHeartbeatPong } from './web-socket-message'

declare global {
    interface Window {
        preconnectPromise?: Promise<PreconnectData>
        isIntegrationTest: boolean
    }
}

export enum WebSocketStatus {
    Connecting,
    Connected = 1,
    // Disconnecting = 2, @Deprecated
    Disconnected = 3,
}

const SECOND = 1000
// 暂定 10 秒发一次心跳请求
export const HEARTBEAT_INTERVAL = 10 * SECOND
// 断线重连周期
const RECONNECT_INTERVALS = [SECOND, 5 * SECOND, 10 * SECOND, 30 * SECOND, 60 * SECOND]

const CONNECT_VERSION = 2 // 与服务端的连接协议(或交互流程)有改动时需要新增版本

const NETWORK_FLUCTUATION_TOLERATE_TIME = 500 // 网络波动容忍时间, 单位 ms. 不确定设为多少最合适, 先拍了一个值

const INVALID_VALUE = 0

/**
 * 管理 WebSocket 连接，负责连接的建立、关闭、重联以及连接状态的维护
 */
export class WebSocketBridge extends ClassWithEffect {
    private socket?: WebSocket | null
    private status$$ = new BehaviorSubject<WebSocketStatus>(WebSocketStatus.Disconnected)
    private sessionId$$ = new ReplaySubject<number>(1)
    private documentId$$ = new ReplaySubject<DocID>(1)
    private userId$$ = new ReplaySubject<number>(1)
    private role$$ = new ReplaySubject<number>(1)
    private authResults$$ = new BehaviorSubject<Wukong.ServerProto.IAuthResultItem[]>([])
    private bindMethods: Record<number, (proto: Wukong.ServerProto.SynergyMessageProto) => void> = {}
    private triggerLoadLocalOperations?: (userId: number) => Promise<void>
    private clientId = 0
    private currentRole: RoleStatus | null = null
    private metricTracker = new WebSocketMetricTracker()
    private networkOfflineTimes = 0
    public hasLoadedDocumentData = false
    private hasReceivedAuthResult = false

    private afterSynergyAuthCallback: (() => void)[] = []

    private commitId = getCommitId() ?? 'unknown'
    private release = getReleaseTag()

    isTesting = !environment.isProduction
    private debugConnectorUrl: string | null = null
    private debugUser: string | null = null

    private destroyed$ = new Subject<void>()

    private readonly messageReplayEventEmitter = new SimpleEventEmitter<MessageEvent>()

    public onStatusChangeWithSignal = (signal: TraceableAbortSignal, handler: (status: WebSocketStatus) => void) => {
        const { act } = transaction(signal)
        act('onStatusChange', () => {
            const subscription = this.status$$.subscribe(handler)
            return () => subscription.unsubscribe()
        })
    }

    public onSessionIdChangeWithSignal = (signal: TraceableAbortSignal, handler: (sessionId: number) => void) => {
        const { act } = transaction(signal)
        act('onSessionIdChange', () => {
            const subscription = this.sessionId$$.subscribe(handler)
            return () => subscription.unsubscribe()
        })
    }

    public async getSessionId() {
        return await firstValueFrom(this.sessionId$$)
    }

    public onDocumentIdChangeWithSignal = (signal: TraceableAbortSignal, handler: (docId: DocID) => void) => {
        const { act } = transaction(signal)
        act('onDcumentIdChange', () => {
            const subscription = this.documentId$$.subscribe(handler)
            return () => subscription.unsubscribe()
        })
    }

    public onUserIdChangeWithSignal = (signal: TraceableAbortSignal, handler: (userId: number) => void) => {
        const { act } = transaction(signal)
        act('onUserIdChange', () => {
            const subscription = this.userId$$.subscribe(handler)
            return () => subscription.unsubscribe()
        })
    }

    public onRoleChangeWithSignal = (signal: TraceableAbortSignal, handler: (role: number) => void) => {
        const { act } = transaction(signal)
        act('onRoleChange', () => {
            const subscription = this.role$$.subscribe(handler)
            return () => subscription.unsubscribe()
        })
    }

    public async getRole() {
        return await firstValueFrom(this.role$$)
    }

    private readonly synergyState$$ = new ReplaySubject<Wukong.DocumentProto.SynergyState>(1)
    public onSynergyStateChangeWithSignal(
        signal: TraceableAbortSignal,
        handler: (state: Wukong.DocumentProto.SynergyState) => void
    ) {
        const { act } = transaction(signal)
        act('onSynergyStateChangeWithSignal', () => {
            const subscription = this.synergyState$$.subscribe(handler)
            return () => subscription.unsubscribe()
        })
    }

    private readonly triggerKickCodeReleaseNotValid$$ = new ReplaySubject<void>(1)
    public onTriggerKickCodeReleaseNotValidWithSignal = (signal: TraceableAbortSignal, handler: () => void) => {
        const { act } = transaction(signal)
        act('onTriggerKickCodeReleaseNotValid', () => {
            const subscription = this.triggerKickCodeReleaseNotValid$$.subscribe(handler)
            return () => subscription.unsubscribe()
        })
    }

    private readonly triggerRefreshPage$$ = new ReplaySubject<void>(1)
    public onTriggerRefreshPageWithSignal = (signal: TraceableAbortSignal, handler: () => void) => {
        const { act } = transaction(signal)
        act('onTriggerRefreshPage', () => {
            const subscription = this.triggerRefreshPage$$.subscribe(handler)
            return () => subscription.unsubscribe()
        })
    }

    private readonly triggerRefreshPageWithDialog$$ = new ReplaySubject<void>(1)
    public onTriggerRefreshPageWithDialogWithSignal = (signal: TraceableAbortSignal, handler: () => void) => {
        const { act } = transaction(signal)
        act('onTriggerRefreshPageWithDialog', () => {
            const subscription = this.triggerRefreshPageWithDialog$$.subscribe(handler)
            return () => subscription.unsubscribe()
        })
    }

    constructor(
        private readonly signal: TraceableAbortSignal,
        controller: EffectController,
        private readonly docId: string,
        private readonly userId: number,
        private autoReconnect = false,
        private readonly bridge?: Bridge,
        private readonly isMirror?: boolean,
        private readonly sceneParam?: Wukong.DocumentProto.PreviewScene
    ) {
        super(controller)
        const docIdInUrl = getUrlDocId()
        if (docIdInUrl && docIdInUrl != docId) {
            WkCLog.log(`current docId=${docId}, is not match to docIdInUrl=${docIdInUrl}`)
            this.bridge?.crash(new Error(`docId not match`))
        }

        this.onSynergyMessageProto(WsAuthResult, this.onAuthResult.bind(this))
        this.onSynergyMessageProto(WsHeartbeatPong, this.onHeartbeatPong.bind(this))
        this.onSynergyMessageProto(WsAuthChanged, this.onAuthChanged.bind(this))

        MetricCollector.setClientType(isMirror ? 'mirror' : 'web')
        if (bridge?.clientId) {
            this.clientId = bridge.clientId
            MetricCollector.setClientId(this.clientId)
            WkCLog.setClientId(this.clientId)
        }
        WkCLog.log(`web-socket-bridge has been constructed, docId=${docId}`)
        WkCLog.setRelease(this.release!)

        this.initNetworkProbe()

        WK.getWSStatus = () => structuredClone(this.status$$.value)

        WK.disableWSAutoReconnect = () => this.disableAutoReconnect()
        WK.disconnectWS = () => this.disconnect()
        WK.reconnectWS = () => this.reconnect()

        WK.receiveCloseWebSocketFrame = (code: number) => {
            const closeEvent = new CloseEvent('close', {
                code: code,
            })
            this.onClose(closeEvent)
        }

        WK.receiveSynergyMessage = (base64Data?: any) =>
            this.onMessage(
                new MessageEvent('message', {
                    data: Buffer.from(base64Data, 'base64'),
                })
            )

        this.bridge?.bind(RefreshPage, () => {
            this.refreshPage()
        })
        this.bridge?.bind(RefreshPageWithDialog, () => {
            this.refreshPageWithDialog()
        })

        // 关闭网页时断开 websocket
        window.addEventListener('unload', this.onUnload)
        window.addEventListener('online', this.onOnline)
        window.addEventListener('offline', this.onOffline)
        window.document.addEventListener('visibilitychange', this.onVisibilityChange)

        this.documentId$$.next(docId)
        this.userId$$.next(userId)

        if (domLocation().href) {
            const locationUrl = new URL(domLocation().href)
            this.debugConnectorUrl = locationUrl.searchParams.get('debugConnectorUrl')
            this.debugUser = locationUrl.searchParams.get('_debug_user_')
        }
    }

    getCurrentStatus() {
        return this.status$$.value
    }

    destroy() {
        this.metricTracker.beforeDestroy()
        this.destroyed$.next()
        this.destroyed$.complete()
        this.disconnect()
        this.disableAutoReconnect()
        if (this.scheduleCheckSocketJob) {
            clearInterval(this.scheduleCheckSocketJob)
            this.scheduleCheckSocketJob = undefined
        }

        delete WK.getWSStatus
        delete WK.disableWSAutoReconnect
        delete WK.disconnectWS
        delete WK.reconnectWS
        delete WK.receiveCloseWebSocketFrame
        delete WK.receiveSynergyMessage

        window.removeEventListener('online', this.onOnline)
        window.removeEventListener('unload', this.onUnload)
        window.removeEventListener('offline', this.onOffline)
        window.document.removeEventListener('visibilitychange', this.onVisibilityChange)

        this.bridge?.unbind(RefreshPage)
        this.bridge?.unbind(RefreshPageWithDialog)

        this.afterSynergyAuthCallback = []
    }

    private onOnline = () => {
        this.metricTracker.onNetworkChange()
    }

    private onOffline = () => {
        this.metricTracker.onNetworkChange()
        this.networkOfflineTimes++
        WkCLog.log(`onOffline, will check connectivity after ${NETWORK_FLUCTUATION_TOLERATE_TIME} ms`)
        setTimeout(() => {
            const networkOnline = this.browserOnline()
            WkCLog.log(`check connectivity now, networkOnline=${networkOnline}`)
            if (!networkOnline) {
                this.disconnect()
            }
        }, NETWORK_FLUCTUATION_TOLERATE_TIME)
    }

    private onVisibilityChange = () => {
        this.metricTracker.onVisibilityChange()
    }

    setClientReleaseVersion() {
        this.bridge?.call(SetReleaseVersion, {
            value: this.commitId,
        })
    }

    /**
     * 检查回放的预链接数据是否合法
     * 合法意味着预链接加载的数据第一位必须为 TC_AuthResult
     */
    private checkPreconnectDataLegal(eventQueue: PreconnectEvent[]): boolean {
        const decodeDatas = eventQueue
            .map(({ data }) => data && Wukong.ServerProto.SynergyMessageProto.decode(new Uint8Array(data.data)))
            .filter((item) => !!item)

        const legal = decodeDatas[0]?.type === Wukong.ServerProto.SynergyMessageTypeCode.TC_AuthResult

        if (!legal && decodeDatas.length) {
            WkCLog.log(
                `preconnect data is illegal, preconnect data types is ${decodeDatas.map(({ type }) => type).join(',')}`
            )
        }

        return legal
    }

    // 尝试用挂在在 window 上的 socket 连接做加载
    private async tryPreconnect(): Promise<boolean> {
        // 没有挂在 preconnectPromise 或为预览模式则不处理提前建联
        if (!window.preconnectPromise || this.isMirror || this.isDebugConnector()) {
            // 当不处理提前建联但拥有 preconnectPromise 时，需要 close 掉
            if (window.preconnectPromise) {
                const { socket } = await window.preconnectPromise
                socket?.close()
            }
            return false
        }

        if (!window.preconnectPromise) {
            return false
        }

        const { socket, eventQueue } = await window.preconnectPromise

        if (this.controller.aborted) {
            return false
        }

        if (getUrlDocId() !== this.docId) {
            WkCLog.log(`docId not match when preconnect`)
            return false
        }

        // 需要确认 eventQueue 里没有 Failed 消息
        if (eventQueue.some(({ type }) => type === 'failed')) {
            return false
        }

        this.status$$.next(WebSocketStatus.Connected)

        if (socket) {
            // 该 socket 是可用的，直接替代原 socket
            this.setCurrentWebSocket(socket)
        }

        if (!this.checkPreconnectDataLegal(eventQueue)) {
            return false
        }

        // 回放所有 event
        eventQueue.forEach(({ data }) => {
            if (data) {
                this.onMessage(data)
                this.bridge?.call(OnMicroTask)
            }
        })

        // 释放 preconnect
        window.preconnectPromise = undefined

        return true
    }

    async connect() {
        this.metricTracker.beforeConnect()
        try {
            await this.triggerLoadLocalOperations!(this.userId)
        } catch (e) {
            // TODO(wuhuajia): 之前产品说如果打开文档时离线数据读取失败就先直接跳过. 之后再次找产品明确一下最终方案
            // view_prototype 权限打开原型预览时也会走到这里，因为 GetSchemaVersionByDocId 请求没有权限返回 403
            WkCLog.log(`Failed to triggerLoadLocalOperations, ${e}`)
        }
        if (this.controller.aborted) {
            return
        }
        const preconnectSuccessed = await this.tryPreconnect()
        if (this.controller.aborted) {
            return
        }
        if (preconnectSuccessed) {
            return
        }
        this.connectV2()
    }

    private initNetworkProbe() {
        // 网络空闲HEARTBEAT_INTERVAL时，发送心跳
        const { handleEvent } = createCombineLatestEventHandler(
            {
                message: {
                    init: false,
                },
                status: {
                    init: false,
                },
            },
            signalDebounce(
                this.signal,
                ({ status }: { message: MessageEvent; status: WebSocketStatus }) => {
                    if (this.docId !== '0' && status === WebSocketStatus.Connected) {
                        this.sendPing()
                    }
                },
                HEARTBEAT_INTERVAL
            )
        )

        this.messageReplayEventEmitter.onWithSignal(this.signal, (message) => handleEvent('message', message))
        this.onStatusChangeWithSignal(this.signal, (status) => handleEvent('status', status))

        // 超时未收到服务端消息主动断连
        // 集成测试下不处理超时，因为集成测试始终使用 fakeTimer, runPendingTimer 后一定会触发超时逻辑
        if (!window.isIntegrationTest) {
            this.messageReplayEventEmitter.onWithSignal(
                this.signal,
                signalDebounce(
                    this.signal,
                    () => {
                        this.timeoutDisconnect()
                    },
                    HEARTBEAT_INTERVAL * 20
                )
            )
        }
    }

    private timeoutDisconnect() {
        if (this.status$$.value !== WebSocketStatus.Connected) {
            return
        }

        WkCLog.log('Disconnect by timeout')
        this.metricTracker.onTimeoutDisconnect()
        this.disconnect()
    }

    private sendPing() {
        if (!this.webSocketConnected()) {
            WkCLog.log('send Ping is ignored because connection is unestablished')
            return
        }

        this.socket!.send(
            Wukong.ServerProto.SynergyMessageProto.encode({
                type: Wukong.ServerProto.SynergyMessageTypeCode.TC_Ping,
                payload: Wukong.ServerProto.PingProto.encode({
                    timestamp: Date.now(),
                }).finish(),
            }).finish()
        )
    }

    private onHeartbeatPong() {
        if (this.status$$.value !== WebSocketStatus.Connected && this.status$$.value !== WebSocketStatus.Connecting) {
            WkCLog.log(`onHeartbeatPong but current WebSocket is ${this.status$$.value}`)
        }
    }

    private getLastOperationIndex(): Wukong.DocumentProto.SynergyOperationIndex | undefined {
        const operationIndex = this.bridge?.call(GetLastOperationIndex)
        return operationIndex
            ? ({
                  minTxId: operationIndex.minTxId,
                  maxTxId: operationIndex.maxTxId,
                  epoch: operationIndex.epoch,
              } as Wukong.DocumentProto.SynergyOperationIndex)
            : undefined
    }

    sendPayload<T>(
        message: WebSocketMessage<T>,
        payload: Uint8Array,
        compressType: Wukong.DocumentProto.CompressType = Wukong.DocumentProto.CompressType.COMPRESS_TYPE_NO_COMPRESS,
        payloadBeforeCompressSize = 0
    ) {
        if (!this.webSocketConnected()) {
            WkCLog.log('send payload is ignored because connection is unestablished')
            if (!this.isMirror) {
                this.disconnect()
            }
            return
        }
        this.checkMessageForMirror(message)

        this.socket?.send(
            Wukong.ServerProto.SynergyMessageProto.encode({
                type: message.code,
                payload,
                compressType,
                payloadBeforeCompressSize,
            }).finish()
        )
    }

    onSynergyMessageProto<T>(
        message: WebSocketMessage<T>,
        callback: (arg: Wukong.ServerProto.SynergyMessageProto) => void
    ) {
        this.bindMethods[message.code] = (proto) => {
            callback(proto)
        }
    }

    async registerTriggerLoadLocalOperations(callback: (userId: number) => Promise<void>) {
        this.triggerLoadLocalOperations = async (userId) => {
            await callback(userId)
        }
    }

    private onMessage(messageEvent: MessageEvent) {
        const message = Wukong.ServerProto.SynergyMessageProto.decode(new Uint8Array(messageEvent.data))

        // 在收到auth之前都不应该收到别的消息
        if (message.type === Wukong.ServerProto.SynergyMessageTypeCode.TC_AuthResult) {
            this.hasReceivedAuthResult = true
        }

        if (!this.hasReceivedAuthResult) {
            WkCLog.log('receive message before auth result, messageType=' + message.type)
            this.refreshPage()
            return
        }

        this.messageReplayEventEmitter.next(messageEvent)

        if (message.type == Wukong.ServerProto.SynergyMessageTypeCode.TC_Document && message.finalFragment == true) {
            this.metricTracker.clearReceiveFinalOnDocumentTimer()
        }

        const callback = this.bindMethods[message.type]
        if (!callback) {
            return
        }

        callback(message)
    }

    private async onConnected() {
        WkCLog.log('WebSocket connected, reconnectTime=' + this.metricTracker.getReconnectTimesBeforeWsConnected())
        this.metricTracker.onConnected()

        // 理论是这里应该是 open 状态了，但是遇到过是其他状态的情况, 所以额外判断一下
        if (this.socket?.readyState === WebSocket.OPEN) {
            this.status$$.next(WebSocketStatus.Connected)
        } else {
            this.disconnect()
            WkCLog.log('onConnected but socket is not open, state=' + this.socket?.readyState)
        }
    }

    private onError(event: Event) {
        WkCLog.log(`WebSocket has error. ${JSON.stringify(event, Object.getOwnPropertyNames(event))}`)
        this.metricTracker.onError()
        this.socket?.close()
        this.status$$.next(WebSocketStatus.Disconnected)
    }

    private onClose(event: CloseEvent) {
        this.handleCloseWebSocketFrame(event.code)
        this.metricTracker.onClose(event)

        WkCLog.log(
            `WebSocket is closed, onDestroy=${this.metricTracker.isOnDestroy()}, joinedSynergyTimes=${this.metricTracker.getJoinedSynergyTimes()}, networkOfflineTimes=${
                this.networkOfflineTimes
            }. ${JSON.stringify(event, ['wasClean', 'code', 'reason'])}`
        )

        this.socket?.close()
        this.status$$.next(WebSocketStatus.Disconnected)
    }

    private handleCloseWebSocketFrame(code: number) {
        // status code 标准见: https://datatracker.ietf.org/doc/html/rfc6455#section-7.4
        // ErrorCode 的范围: 4000~4499   KickCode 的范围: 4500~4999
        if (code < 4000) {
            WkCLog.log(`WebSocket is closed, code=${code}`)
        } else if (code < 4500) {
            WkCLog.log(`WebSocket is closed, serverErrorCode=${code}`)
            this.metricTracker.onServerErrorCloseCode(code)
        } else {
            WkCLog.log(`WebSocket is closed, serverKickCode=${code}`)
            this.metricTracker.onKickCloseCode(code)
            this.autoReconnect = false
            switch (code) {
                case Wukong.DocumentProto.WebSocketKickCode.RELEASE_NOT_VALID: {
                    // 这里的 RELEASE_NOT_VALID 是指强升的场景, 而非 schemaVersion 不匹配
                    this.triggerKickCodeReleaseNotValid$$.next()
                    this.triggerKickCodeReleaseNotValid$$.complete()
                    break
                }
                case Wukong.DocumentProto.WebSocketKickCode.PERMISSION_LOST:
                case Wukong.DocumentProto.WebSocketKickCode.DOC_DELETED:
                case Wukong.DocumentProto.WebSocketKickCode.SCHEMA_MISMATCH:
                case Wukong.DocumentProto.WebSocketKickCode.SYNC_LOST:
                case Wukong.DocumentProto.WebSocketKickCode.DOCUMENT_REVERSION: {
                    this.refreshPage()
                    break
                }
                case Wukong.DocumentProto.WebSocketKickCode.ILLEGAL_REQUEST:
                case Wukong.DocumentProto.WebSocketKickCode.DECODE_ERROR:
                case Wukong.DocumentProto.WebSocketKickCode.UNKNOWN_MESSAGE:
                case Wukong.DocumentProto.WebSocketKickCode.ILLEGAL_URI:
                case Wukong.DocumentProto.WebSocketKickCode.RECEIVE_MESSAGE_UNDER_ERROR_STATE:
                case Wukong.DocumentProto.WebSocketKickCode.DOC_CRASHED:
                case Wukong.DocumentProto.WebSocketKickCode.NO_EDIT_PERMISSION: {
                    this.bridge?.crash(new Error(`Receive ServerKick: ${code}`))
                    break
                }
                case Wukong.DocumentProto.WebSocketKickCode.DOC_BLOCKING: {
                    // TODO(wuhuajia): 暂时简单的策略就是升级文档期间允许自动重连. 后续等产品给出其他方案时再修改
                    this.autoReconnect = true
                    break
                }
                case Wukong.DocumentProto.WebSocketKickCode.CONNECTION_UNAUTHORIZED: {
                    if (this.isMirror) {
                        // 预览端直接刷新页面
                        this.refreshPage()
                    } else {
                        reLogin()
                    }
                    break
                }
                default: {
                    WkCLog.log(`unknown kick code: ${code}`)
                    this.refreshPage()
                    break
                }
            }
        }
    }

    private onAuthResult(proto: Wukong.ServerProto.SynergyMessageProto) {
        const authResult = Wukong.ServerProto.AuthResultProto.decode(proto.payload)
        if (authResult.status !== 200) {
            WkCLog.log('auth failed.', {
                status: authResult.status,
                sessionId: authResult.sessionId,
            })
            this.disconnect()
            this.refreshPage()
            return
        }

        const role = this.isMirror ? RoleStatus.Viewer : authResult.role
        this.checkIfShouldRefreshPage(role as RoleStatus)
        WkCLog.setSessionId(authResult.sessionId)
        setCurrentSessionId(authResult.sessionId)
        MetricCollector.setSessionId(authResult.sessionId)
        this.sessionId$$.next(authResult.sessionId)
        this.role$$.next(RoleStatusWeight[role as RoleStatus])
        this.authResults$$.next(authResult.results)
        this.reportConnectionContext(authResult.sessionId)
        this.replaySynergyAuthCallBack()
        appStore$.set(synergyAuthed$, true)
    }

    private onAuthChanged(proto: Wukong.ServerProto.SynergyMessageProto) {
        const authChangedResult = Wukong.ServerProto.AuthChangedProto.decode(proto.payload)
        this.authResults$$.next(authChangedResult.changeResults)
    }

    private reportConnectionContext(sid: number) {
        const reportConnectionContext = new ReportConnectionContext(
            this.docId,
            sid,
            this.clientId,
            this.release!,
            '', // orgId 只是为了服务端删除 cypress 时更方便, 先都写成空字符串
            JSON.stringify(featureSwitchManager.getSnapshot())
        )

        reportConnectionContext.start().catch((e) => {
            WkCLog.log(
                `Failed to reportConnectionContext, docId=${
                    this.docId
                }, sid=${sid}, errorTime=${new Date()}, error=${e}`
            )
        })
    }

    joinedSynergy() {
        this.metricTracker.onJoinedSynergy()
    }

    getStartConnectWsTs() {
        return this.metricTracker.getStartConnectWsTs()
    }

    getJoinedSynergyTimes() {
        return this.metricTracker.getJoinedSynergyTimes()
    }

    reconnect() {
        this.metricTracker.beforeReconnect()

        this.connect()
    }

    disconnect() {
        WkCLog.log('disconnect websocket')
        this.socket?.close()
    }

    disconnectUnderHistoryMode(): Promise<void> {
        return new Promise((resolve) => {
            WkCLog.log('disconnectUnderHistoryMode websocket')
            if (this.socket) {
                if (this.socket.readyState === WebSocket.CLOSED) {
                    resolve()
                    return
                }
                const oldOnClose = this.socket.onclose
                this.socket.onclose = (event) => {
                    oldOnClose?.call(this.socket!, event)
                    resolve()
                }
                this.socket.close()
            } else {
                resolve()
            }
        })
    }

    enableAutoReconnect() {
        // 防止重复调用
        if (this.autoReconnect) {
            return
        }
        WkCLog.log('enableAutoReconnect')
        this.autoReconnect = true
    }

    /**
     * 禁止自动重连，如果当前有重连任务会清理掉
     */
    disableAutoReconnect() {
        // 防止重复调用
        if (!this.autoReconnect) {
            return
        }
        WkCLog.log('disableAutoReconnect')
        this.autoReconnect = false
        this.clearReconnectJob()
    }

    /**
     * 判断当前浏览器是否是通网状态.
     * 返回 false 时一定可信: 即当前一定连不上网
     * 返回 true 时不一定可信: 例如假设只能连上局域网则也可能返回 true
     *
     * @link https://developer.mozilla.org/en-US/docs/Web/API/Navigator/onLine
     */
    private browserOnline(): boolean {
        return navigator.onLine
    }

    private onUnload() {
        this.socket?.close()
    }

    private webSocketConnected(): boolean {
        if (this.status$$.value === WebSocketStatus.Connected && this.socket?.readyState === WebSocket.OPEN) {
            return true
        } else {
            WkCLog.log(
                `WebSocket is not connected, status=${this.status$$.value}, readyState=${this.socket?.readyState}`
            )
            return false
        }
    }

    public refreshPage() {
        WkCLog.log(`refreshPage`)
        this.triggerRefreshPage$$.next()
        this.triggerRefreshPage$$.complete()
    }

    public refreshPageWithDialog() {
        WkCLog.log(`refreshPageWithDialog`)
        this.triggerRefreshPageWithDialog$$.next()
        this.triggerRefreshPageWithDialog$$.complete()
    }

    private checkIfShouldRefreshPage(newRole: RoleStatus) {
        if (this.currentRole == null) {
            this.currentRole = newRole
            return
        }
        if (this.currentRole == newRole) {
            return
        }

        // 可编辑权限变为只读权限时, 刷新页面
        if (compareRole(this.currentRole, RoleStatus.Viewer) > 0 && compareRole(newRole, RoleStatus.Viewer) <= 0) {
            WkCLog.log(`user role change, from:${this.currentRole}, to:${newRole}`)
            this.refreshPage()
            return
        }

        // 只读权限变为可编辑权限时, 刷新页面
        if (compareRole(this.currentRole, RoleStatus.Viewer) <= 0 && compareRole(newRole, RoleStatus.Viewer) > 0) {
            WkCLog.log(`user role change, from:${this.currentRole}, to:${newRole}`)
            this.refreshPage()
            return
        }
        this.currentRole = newRole
    }

    private checkMessageForMirror<T>(message: WebSocketMessage<T>) {
        if (
            this.isMirror &&
            ![
                Wukong.ServerProto.SynergyMessageTypeCode.TC_Auth,
                Wukong.ServerProto.SynergyMessageTypeCode.TC_Ping,
                Wukong.ServerProto.SynergyMessageTypeCode.TC_MirrorPreview,
                Wukong.ServerProto.SynergyMessageTypeCode.TC_SceneGraphQuery,
            ].includes(message.code)
        ) {
            // NOTE: 预览模式下不应该发送其他类型的消息，否则服务端会kick掉
            console.error('should not send the proto in mirror mode: ', message)
        }
    }

    public injectAfterAuthCallback(fnList: (() => void)[]): void {
        if (Array.isArray(fnList)) {
            this.afterSynergyAuthCallback = fnList
        } else {
            this.afterSynergyAuthCallback = []
        }
    }

    /**
     *  回放  synergy auth 回调
     */
    private replaySynergyAuthCallBack(): void {
        for (const fn of this.afterSynergyAuthCallback) {
            fn()
        }
    }

    private isDebugConnector() {
        return this.isTesting && this.debugConnectorUrl
    }

    // ------------------------------ v2 ---------------------------------------
    private reconnectWithTimeoutJob: NodeJS.Timeout | undefined = undefined
    private scheduleCheckSocketJob: NodeJS.Timeout | undefined = undefined
    private waitOnlineAndReconnect: (() => void) | null = null

    connectV2() {
        WkCLog.log('[reconnect v2] start to connect websocket')

        this.clearCurrentSocket()
        const connectUrl = this.buildConnectUrl()
        this.status$$.next(WebSocketStatus.Connecting)
        const socket = new WebSocket(connectUrl)
        socket.binaryType = 'arraybuffer'
        this.setCurrentWebSocket(socket)
    }

    private buildConnectUrl() {
        let searchParams: ValidSearchParams = {
            release: this.release,
            version: CONNECT_VERSION,
            beta: shouldApplyBetaForceUpgrade(),
        }

        if (this.hasLoadedDocumentData && !this.isMirror) {
            const lastOperationindex = this.getLastOperationIndex()
            searchParams = {
                ...searchParams,
                txId: lastOperationindex?.maxTxId,
                epoch: lastOperationindex?.epoch,
            }
        }

        if (this.isDebugConnector() && this.debugUser) {
            searchParams = {
                ...searchParams,
                _debug_user_: this.debugUser,
            }
        }

        if (this.clientId) {
            searchParams = {
                ...searchParams,
                clientId: this.clientId,
            }
        }

        if (this.isMirror && this.sceneParam != undefined) {
            searchParams = {
                ...searchParams,
                scene: previewSenceEnum2value[this.sceneParam],
            }
        }

        const connectUrlPrefix = this.isDebugConnector()
            ? this.debugConnectorUrl
            : this.isMirror
            ? environment.mirrorWssPrefix
            : environment.wssPrefix
        const connectUrl = `${connectUrlPrefix}/${this.docId}?${queryStringify(searchParams)}`

        WkCLog.log(`[reconnect v2] build WebSocket connect url: ${connectUrl}`)
        return connectUrl
    }

    private setCurrentWebSocket(webSocket: WebSocket) {
        this.socket = webSocket
        this.socket.onmessage = (event) => {
            this.onMessage(event)
        }
        this.socket.onopen = () => {
            this.onConnected()
        }
        this.socket.onerror = (event) => {
            this.onError(event)
        }
        this.socket.onclose = (event) => {
            this.onClose(event)
            this.tryReconnectWithTimeOut()
        }

        // 定时检查一下 socket 的状态, 作为断线重连的保底
        if (!this.scheduleCheckSocketJob) {
            WkCLog.log(`[reconnect v2] register scheduleCheckSocketJob`)
            this.scheduleCheckSocketJob = setInterval(() => {
                this.checkSocketStatus()
            }, 10 * SECOND)
        }
    }

    private checkSocketStatus() {
        debugLog(`[reconnect v2] onCheckSocketStatus, readyState=${this.socket?.readyState}.`)
        if (
            !(this.socket?.readyState === WebSocket.OPEN || this.socket?.readyState === WebSocket.CONNECTING) &&
            this.reconnectWithTimeoutJob == null &&
            this.autoReconnect
        ) {
            WkCLog.log('[reconnect v2] checkSocketStatus: socket is not open and no reconnection job.')
            this.tryReconnectWithTimeOut()
        }
    }

    private clearCurrentSocket = () => {
        debugLog(`[reconnect v2] clear current socket.`)
        this.status$$.next(WebSocketStatus.Disconnected)
        if (this.socket) {
            this.socket.onclose = () => {}
            this.socket.onerror = () => {}
            this.socket.onmessage = () => {}
            this.socket.onopen = () => {}
            this.socket.close()
        }
        this.socket = null
    }

    tryReconnectWithTimeOut() {
        this.clearCurrentSocket()
        if (this.reconnectWithTimeoutJob != null) {
            WkCLog.log(`[reconnect v2] already has reconnectWithTimeout job.`)
            return
        }
        if (!this.autoReconnect) {
            return
        }

        const delayTime =
            this.metricTracker.getReconnectTimesIgnoreConnectivity() == 0
                ? Math.random() * 500
                : RECONNECT_INTERVALS[
                      Math.min(
                          this.metricTracker.getReconnectTimesIgnoreConnectivity() - 1,
                          RECONNECT_INTERVALS.length - 1
                      )
                  ]

        WkCLog.log(`[reconnect v2] Attempting reconnect in ${delayTime} ms.`)
        this.reconnectWithTimeoutJob = setTimeout(() => {
            WkCLog.log(`[reconnect v2] Attempting reconnect now after waiting ${delayTime} ms.`)
            this.clearReconnectJobAndDoReconnect()
        }, delayTime)

        this.waitOnlineAndReconnect = () => {
            if (this.browserOnline()) {
                WkCLog.log(`[reconnect v2] Attempting reconnect now due to connectivity change to online.`)
                this.clearReconnectJobAndDoReconnect()
            }
        }
        window.addEventListener('online', this.waitOnlineAndReconnect)
    }

    clearReconnectJob() {
        debugLog(`[reconnect v2] do clearReconnectJobAndDoReconnect.`)
        if (this.reconnectWithTimeoutJob) {
            clearTimeout(this.reconnectWithTimeoutJob)
            this.reconnectWithTimeoutJob = undefined
            debugLog(`[reconnect v2] clear reconnectWithTimeout job.`)
        }
        if (this.waitOnlineAndReconnect) {
            window.removeEventListener('online', this.waitOnlineAndReconnect)
            this.waitOnlineAndReconnect = null
            debugLog(`[reconnect v2] clear waitOnlineAndReconnect listener.`)
        }
    }

    clearReconnectJobAndDoReconnect() {
        this.clearReconnectJob()
        this.reconnect()
    }

    public updateSynergyState = (value: Wukong.DocumentProto.SynergyState) => {
        this.synergyState$$.next(value)
    }

    public onAuthResultsChange(
        signal: TraceableAbortSignal,
        handler: (results: Wukong.ServerProto.IAuthResultItem[]) => void
    ) {
        const { act } = transaction(signal)
        act('onAuthResultsChange', () => {
            const subscription = this.authResults$$.subscribe(handler)
            return () => subscription.unsubscribe()
        })
    }

    public async getCurrentSynergyState(defaultValue: Wukong.DocumentProto.SynergyState) {
        return await firstValueFrom(this.synergyState$$, { defaultValue })
    }

    sendEditorTypeToSynergy(editorType: Wukong.DocumentProto.EditorType) {
        if (!this.webSocketConnected()) {
            WkCLog.log('update EditorType session status is ignored because connection is unestablished')
            return
        }

        this.socket!.send(
            Wukong.ServerProto.SynergyMessageProto.encode({
                type: Wukong.ServerProto.SynergyMessageTypeCode.TC_UserSessionChanged,
                payload: Wukong.ServerProto.UserSessionChanged.encode({
                    editorType: {
                        [Wukong.DocumentProto.EditorType.EDITOR_TYPE_DO_NOT_USE_ME_ZERO]:
                            Wukong.ServerProto.EditorType.UNKNOWN,
                        [Wukong.DocumentProto.EditorType.EDITOR_TYPE_DESIGN]: Wukong.ServerProto.EditorType.Design,
                        [Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV]: Wukong.ServerProto.EditorType.Dev,
                    }[editorType],
                }).finish(),
            }).finish()
        )
    }
}

class WebSocketMetricTracker {
    private reconnectTimesBeforeWsConnected = 0 // 本次断连 -> 长连接建立成功 的重试次数
    private reconnectTimesBeforeJoinedSynergy = 0 // 本次断连 -> 加入协同服务成功 的重试次数
    private reconnectTimesTotal = 0 // 总的重连次数, 重连成功后不重置
    private reconnectTimesIgnoreConnectivity = 0 // 本次断连 -> 加入协同服务成功 的重试次数, 忽略网络状态 (用于网络不通时控制重连间隔)
    private offlineStartTime = 0 // 协同离线的开始时间
    private isNetworkOnline = true
    private networkOfflineStartTime = 0 // 网络离线的开始时间
    private networkOfflineDuration = 0 // 网络离线的持续时间
    private onDestroy = false
    private startConnectWsTs = 0
    private joinedSynergyTimes = 0 // 成功加入协同的次数, 等于 1 时表示首次打开, 大于 1 表示断线重连
    private isWindowsHidden = false
    private startBrowseTimestamp = performance.now() // 开始浏览当前页面的时间
    private checkReceiveFinalOnDocumentTimer: NodeJS.Timeout | undefined = undefined // 太长时间没有收到最后一片onDocument数据会触发这个定时器

    public beforeConnect() {
        this.startConnectWsTs = performance.now()
        if (!this.checkReceiveFinalOnDocumentTimer) {
            this.checkReceiveFinalOnDocumentTimer = setTimeout(() => {
                WkCLog.log(
                    `checkReceiveFinalOnDocumentTimer is triggered due to didn't receive final document data after connect one minute`
                )
            }, 60000)
        }
    }

    public clearReceiveFinalOnDocumentTimer() {
        if (this.checkReceiveFinalOnDocumentTimer) {
            clearTimeout(this.checkReceiveFinalOnDocumentTimer)
            this.checkReceiveFinalOnDocumentTimer = undefined
            debugLog(`checkReceiveFinalOnDocumentTimer is cleared`)
        }
    }

    public onConnected() {
        this.reconnectTimesBeforeWsConnected = 0
    }
    public onClose(event: CloseEvent) {
        if (this.offlineStartTime == 0) {
            this.offlineStartTime = performance.now()
        }

        const fromSynergyState = this.isSynergyOnline()

        MetricCollector.pushMetricToServer(MetricName.SYNERGY_OFFLINE_TIMES, 1, {
            intentional: this.onDestroy,
            networkOffline: !window.navigator.onLine,
            fromSynergyState: fromSynergyState,
            closeCode: event.code,
        })
    }
    public onError() {
        MetricCollector.pushMetricToServer(MetricName.SYNERGY_SOCKET_ERROR, 1, {
            fromSynergyState: this.isSynergyOnline(),
        })
    }
    public onKickCloseCode(kickCode: number) {
        MetricCollector.pushMetricToServer(MetricName.SYNERGY_RECEIVE_SERVER_KICK, 1, {
            code: kickCode,
            fromSynergyState: this.isSynergyOnline(),
        })
    }

    public onServerErrorCloseCode(errorCode: number) {
        MetricCollector.pushMetricToServer(MetricName.SYNERGY_RECEIVE_SERVER_ERROR, 1, {
            code: errorCode,
            fromSynergyState: this.isSynergyOnline(),
        })
    }

    public onTimeoutDisconnect() {
        MetricCollector.pushMetricToServer(MetricName.SYNERGY_HEARTBEAT_TIMEOUT, 1, {
            fromSynergyState: this.isSynergyOnline(),
        })
    }

    public onJoinedSynergy() {
        if (this.offlineStartTime != 0) {
            MetricCollector.pushMetricToServer(
                MetricName.SYNERGY_OFFLINE_DURATION,
                (performance.now() - this.offlineStartTime) / 1000
            )
        }
        if (this.reconnectTimesTotal == 0) {
            MetricCollector.pushMetricToServer(MetricName.SYNERGY_FIRST_LOAD_SUCCESS, 1, {
                hasRetry: this.reconnectTimesBeforeJoinedSynergy > 0,
            })
        } else {
            MetricCollector.pushMetricToServer(MetricName.SYNERGY_OFFLINE_SYNC_SUCCESS_TIMES, 1)
            MetricCollector.pushMetricToServer(
                MetricName.SYNERGY_NETWORK_OFFLINE_DURATION,
                this.networkOfflineDuration / 1000
            )
        }
        this.offlineStartTime = 0
        this.joinedSynergyTimes++
        this.networkOfflineDuration = 0
        this.reconnectTimesIgnoreConnectivity = 0
        this.reconnectTimesBeforeJoinedSynergy = 0
    }

    public onNetworkChange() {
        const wasOnline = this.isNetworkOnline
        this.isNetworkOnline = window.navigator.onLine

        if (this.isNetworkOnline) {
            if (!wasOnline && this.networkOfflineStartTime != 0) {
                this.networkOfflineDuration += performance.now() - this.networkOfflineStartTime
                this.networkOfflineStartTime = 0
            }
        } else {
            if (wasOnline) {
                this.networkOfflineStartTime = performance.now()
            }
        }
    }

    public onVisibilityChange() {
        WkCLog.log(`window's visibilityState changed to ${isDocumentVisible() ? 'visible' : 'hidden'}`)
        const isCurrentWindowsHidden = !isDocumentVisible()
        if (this.isWindowsHidden === isCurrentWindowsHidden) {
            return
        }
        this.isWindowsHidden = isCurrentWindowsHidden
        if (this.isWindowsHidden) {
            if (this.startBrowseTimestamp != INVALID_VALUE) {
                MetricCollector.pushMetricToServer(
                    MetricName.SYNERGY_BROWSE_CURRENT_WINDOW_DURATION,
                    (performance.now() - this.startBrowseTimestamp) / 1000
                )
            }
            this.startBrowseTimestamp = INVALID_VALUE
        } else {
            this.startBrowseTimestamp = performance.now()
        }
    }

    public beforeDestroy() {
        if (this.startBrowseTimestamp != INVALID_VALUE) {
            MetricCollector.pushMetricToServer(
                MetricName.SYNERGY_BROWSE_CURRENT_WINDOW_DURATION,
                (performance.now() - this.startBrowseTimestamp) / 1000
            )
        }
        this.onDestroy = true
        this.startBrowseTimestamp = INVALID_VALUE
        this.clearReceiveFinalOnDocumentTimer()
    }

    public beforeReconnect() {
        if (navigator.onLine) {
            this.reconnectTimesBeforeJoinedSynergy++
            this.reconnectTimesBeforeWsConnected++
            this.reconnectTimesTotal++
        }
        this.reconnectTimesIgnoreConnectivity++

        // 我们的日志也能构造出 metric, 之后可以考虑统一改为直接发 metric
        WkCLog.log(
            `try reconnecting to WebSocket server... reconnectTimesBeforeWsConnected=${this.reconnectTimesBeforeWsConnected}, reconnectTimesBeforeJoinedSynergy=${this.reconnectTimesBeforeJoinedSynergy}, reconnectTimesTotal=${this.reconnectTimesTotal}, browserOnline=${navigator.onLine}`
        )
        if (this.reconnectTimesBeforeWsConnected >= 3 && this.reconnectTimesBeforeWsConnected % 3 == 0) {
            // 长连接服务连不上
            WkCLog.log(`reconnect times too much before websocket connected`)
        }
        if (this.reconnectTimesBeforeJoinedSynergy >= 5 && this.reconnectTimesBeforeJoinedSynergy % 5 == 0) {
            // 协同服务加入失败
            WkCLog.log(`reconnect times too much before joined synergy`)
        }
    }

    public getStartConnectWsTs() {
        return this.startConnectWsTs
    }

    public getReconnectTimesBeforeWsConnected() {
        return this.reconnectTimesBeforeWsConnected
    }

    public getReconnectTimesIgnoreConnectivity() {
        return this.reconnectTimesIgnoreConnectivity
    }

    public getJoinedSynergyTimes() {
        return this.joinedSynergyTimes
    }

    public isOnDestroy() {
        return this.onDestroy
    }

    private isSynergyOnline(): boolean {
        return this.offlineStartTime == 0
    }
}
