import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Action: '动作',
    None: '无',
    Animate: '动画',
    Curve: '动效曲线',
    Duration: '持续时间',
    Delay: '延时',
    Show: '显示被折叠的 {{count}} 个交互',
    Interactions: '交互',
    CopiedToClipboard: '已复制到剪贴板',
    Spring: '弹性动画',
    Mass: '质量',
    Stiffness: '刚度',
    Damping: '阻尼',
} as const

export const enTranslation = {
    Action: 'Action',
    None: 'None',
    Animate: 'Animate',
    Curve: 'Curve',
    Duration: 'Duration',
    Delay: 'Delay',
    Interactions: 'Interactions',
    Show: '{{count}} more',
    CopiedToClipboard: 'Copied to clipboard',
    Spring: 'Spring',
    Mass: 'Mass',
    Stiffness: 'Stiffness',
    Damping: 'Damping',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string | number>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
