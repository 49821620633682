import { HighlightNodesByIdsWasmCall, Wukong } from '@wukong/bridge-proto'
import { cloneDeep } from 'lodash-es'
import { useEffect, useState } from 'react'
import { DropdownV2, SimpleDrag } from '../../../../../../../../ui-lib/src'
import { CommitType } from '../../../../../../document/command/commit-type'
import { PopupStateType } from '../../../../../../document/node/node'
import { useViewState } from '../../../../../../view-state-bridge'
import { useCommand } from '../../../../../context/document-context'
import { useComponentInstanceCommand } from '../../commands'
import {
    Arg_cmdEditComponentPropData,
    Arg_cmdReorderComponentPropDefs,
    MultiHoverBorderColorType,
    VComponentPropDef,
    VComponentPropType,
} from '../../types'
import { ComponentPropDefsItem, VariantPropItem } from '../component-prop-defs-item'
import { SinglePublicPropInstanceChain } from '../component-prop-defs-item/components/public-prop'
import { useComponentPropDefItemRightClick } from './hook'
import styles from './index.module.less'
import { translation } from './index.translation'

export function PublicPropArea(props: { chains: Wukong.DocumentProto.IPublicPropInstanceChain[] }) {
    const { chains } = props

    const command = useCommand()
    const publicChains = chains.filter((it) => it.isPublic)
    const [hoverIndex, setHoverIndex] = useState<number>(-1)
    if (!publicChains.length) {
        return null
    }

    const onInstanceHovered = (nodeIds: string[], index: number) => {
        setHoverIndex(index)
        command.invokeBridge(CommitType.Noop, HighlightNodesByIdsWasmCall, {
            nodeIds: nodeIds,
        })
    }

    return (
        <div className={styles.publicPropArea}>
            <div className={styles.nestedInstanceWrapper}>
                <div className={styles.nestedInstance}>{translation('nestedInstance')}</div>
            </div>
            <div className={styles.publicPropBody}>
                {publicChains.map((chain, index) => (
                    <SinglePublicPropInstanceChain
                        key={index}
                        index={index}
                        chain={chain}
                        onInstanceHovered={onInstanceHovered}
                        hovered={index === hoverIndex}
                        allowDelete
                    />
                ))}
            </div>
        </div>
    )
}

export function ComponentPropVariantList(props: {
    setPopupPosition: (
        value:
            | {
                  left: number
                  top: number
              }
            | undefined
    ) => void
    openEditPopup: (def: VComponentPropDef) => void
    closePopup: () => void
    popupType: PopupStateType
    popupVal: VComponentPropDef
    compSetProps: Wukong.DocumentProto.ComponentSetProp[]
    otherCompDefName: string[]
    selectedDragItems: number[]
    setSelectedDragItems: (value: number[]) => void
}) {
    const {
        setPopupPosition,
        openEditPopup,
        popupType,
        popupVal,
        otherCompDefName,
        closePopup,
        selectedDragItems,
        setSelectedDragItems,
        compSetProps,
    } = props

    const commands = useComponentInstanceCommand()
    const updatePropsWith = (modifier: (newProps: typeof compSetProps) => void) => {
        const newProps = cloneDeep(compSetProps)
        modifier(newProps)
        commands.updateCompSetProps(newProps)
    }

    const propsAction = (i: number) => ({
        rename(newPropName: string) {
            const oldPropName = compSetProps[i].name
            if (newPropName.trim().length > 0 && newPropName !== oldPropName) {
                commands.renameCompSetProp(oldPropName, newPropName, i, compSetProps)
            }
        },
        delete() {
            updatePropsWith((newProps) => newProps.splice(i, 1))
            setSelectedDragItems([])
            ;(document.activeElement as HTMLElement)?.blur()
        },
        updateOption: (optionIndex: number) => (newOptionValue: string) => {
            updatePropsWith((newProps) => (newProps[i].options[optionIndex] = newOptionValue))
        },
    })

    const deleteSelectedCompSetProps = () => {
        if (selectedDragItems.length === compSetProps.length) return
        const deletedProps = compSetProps.filter((_, index) => !selectedDragItems.includes(index))
        commands.updateCompSetProps(deletedProps)
        setSelectedDragItems([])
    }

    const { visible, rect, handleContextMenu, closeDropdown } = useComponentPropDefItemRightClick()

    return (
        <>
            <SimpleDrag
                selectedIndexList={selectedDragItems}
                items={compSetProps}
                onSelectChange={setSelectedDragItems}
                onDragDone={(newSetProps) => {
                    commands.updateCompSetProps(newSetProps)
                    setSelectedDragItems([])
                }}
                data-testid={'component-prop-variant-prop-list'}
                onContextMenu={(e) => handleContextMenu(e, () => compSetProps.length > 1)}
            >
                {compSetProps.map((prop, propIndex) => {
                    const propAction = propsAction(propIndex)
                    return (
                        <VariantPropItem
                            key={propIndex}
                            index={propIndex}
                            popupType={popupType}
                            popupVal={popupVal}
                            propName={prop.name}
                            propValues={prop.options}
                            selected={selectedDragItems.includes(propIndex)}
                            conflicted={otherCompDefName.includes(prop.name)}
                            editProp={() => {
                                openEditPopup({
                                    name: prop.name,
                                    type: VComponentPropType.V_COMPONENT_PROP_TYPE_VARIANT,
                                } as VComponentPropDef)
                            }}
                            renameProp={propAction.rename}
                            deleteProp={propAction.delete}
                            setPopupPosition={setPopupPosition}
                            dataTestIds={{
                                item: 'component-prop-variant-prop-index-' + propIndex,
                                propName: 'component-prop-variant-prop-name-' + prop.name,
                                propValue: 'component-prop-variant-prop-value-' + prop.name,
                                edit: 'component-prop-variant-prop-edit-' + prop.name,
                                remove: 'component-prop-variant-prop-remove-' + prop.name,
                            }}
                            closePopup={closePopup}
                            selectSelf={() => setSelectedDragItems([propIndex])}
                            deleteSelectedCompSetProps={deleteSelectedCompSetProps}
                        />
                    )
                })}
            </SimpleDrag>
            <DropdownV2.NoTriggerSingleLevel
                triggerRect={rect}
                isOpenState={visible}
                openStateToBeFalse={closeDropdown}
                dataTestIds={{ container: 'variant-prop-right-click-dropdown' }}
            >
                <DropdownV2.NoTriggerSingleLevel.Option
                    value="delete-prop"
                    onClick={(e) => {
                        if (selectedDragItems.length === 1) {
                            propsAction(selectedDragItems[0]).delete()
                            e.stopPropagation() // 防止冒泡到外层触发选中逻辑
                        }
                    }}
                >
                    {translation('deleteProp')}
                </DropdownV2.NoTriggerSingleLevel.Option>
            </DropdownV2.NoTriggerSingleLevel>
        </>
    )
}

interface ComponentPropDefsListProps {
    defs: VComponentPropDef[]
    popupVal: VComponentPropDef
    popupType: PopupStateType
    selectedDragItems: number[]
    setSelectedDragItems: (value: number[]) => void
    reorderDefs: (value: Arg_cmdReorderComponentPropDefs) => void
    openEditPopup: (def: VComponentPropDef) => void
    removeProp: (def: VComponentPropDef) => void
    updateProp: (propVal: Arg_cmdEditComponentPropData) => void
    setPopupPosition: (value: { left: number; top: number } | undefined) => void
    closePopup: () => void
    highlightNodesByIds: (nodeIds: string[], color?: MultiHoverBorderColorType) => void
    batchRemoveProps: (defs: VComponentPropDef[]) => void
}

function ComponentPropDefsList({
    defs,
    popupVal,
    popupType,
    selectedDragItems,
    setSelectedDragItems,
    reorderDefs,
    openEditPopup,
    removeProp,
    updateProp,
    setPopupPosition,
    closePopup,
    highlightNodesByIds,
    batchRemoveProps,
}: ComponentPropDefsListProps) {
    const deleteSelectedDefs = () => batchRemoveProps(defs.filter((_, index) => selectedDragItems.includes(index)))

    const { visible, rect, handleContextMenu, closeDropdown } = useComponentPropDefItemRightClick()

    return (
        <>
            <SimpleDrag
                selectedIndexList={selectedDragItems}
                items={defs}
                onSelectChange={(items) => setSelectedDragItems(items)}
                onDragDone={(newDefs) => {
                    reorderDefs({ defIds: newDefs.map((def) => def.id) })
                    setSelectedDragItems([])
                }}
                data-testid="component-prop-defs-list"
                onContextMenu={handleContextMenu}
            >
                {defs.map((def, index) => (
                    <ComponentPropDefsItem
                        key={def.id}
                        def={def}
                        popupVal={popupVal}
                        popupType={popupType}
                        index={index}
                        selected={selectedDragItems.includes(index)}
                        openEditPopup={openEditPopup}
                        removeProp={removeProp}
                        updateProp={updateProp}
                        setPopupPosition={setPopupPosition}
                        closePopup={closePopup}
                        highlightNodesByIds={highlightNodesByIds}
                        selectSelf={() => setSelectedDragItems([index])}
                        deleteSelectedDefs={deleteSelectedDefs}
                    />
                ))}
            </SimpleDrag>
            <DropdownV2.NoTriggerSingleLevel
                triggerRect={rect}
                isOpenState={visible}
                openStateToBeFalse={closeDropdown}
                removeMask
            >
                <DropdownV2.NoTriggerSingleLevel.Option
                    value="delete-prop"
                    onClick={(e) => {
                        if (selectedDragItems.length === 1) {
                            removeProp(defs[selectedDragItems[0]])
                            e.stopPropagation() // 防止冒泡到外层触发选中逻辑
                        }
                    }}
                >
                    {translation('deleteProp')}
                </DropdownV2.NoTriggerSingleLevel.Option>
            </DropdownV2.NoTriggerSingleLevel>
        </>
    )
}

export function ComponentPropList(props: {
    defs: VComponentPropDef[]
    popupType: PopupStateType
    popupVal: VComponentPropDef
    publicPropChains: Wukong.DocumentProto.IPublicPropInstanceChain[]
    openEditPopup: (def: VComponentPropDef) => void
    removeProp: (def: VComponentPropDef) => void
    batchRemoveProps: (defs: VComponentPropDef[]) => void
    updateProp: (propVal: Arg_cmdEditComponentPropData) => void
    reorderDefs: (value: Arg_cmdReorderComponentPropDefs) => void
    highlightNodesByIds: (nodeIds: string[], color?: MultiHoverBorderColorType) => void
    setPopupPosition: (value: { left: number; top: number } | undefined) => void
    closePopup: () => void
    selectComponentProp: (value: boolean) => void
}) {
    const {
        defs,
        popupType,
        popupVal,
        publicPropChains,
        openEditPopup,
        removeProp,
        batchRemoveProps,
        updateProp,
        reorderDefs,
        highlightNodesByIds,
        setPopupPosition,
        closePopup,
        selectComponentProp,
    } = props

    const [selectedVariantDragItems, setSelectedVariantDragItems] = useState<number[]>([])
    const [selectedDefsDragItems, setSelectedDefsDragItems] = useState<number[]>([])

    const variantState = useViewState('componentPanelState')?.variantState
    const compSetProps = variantState?.compSetProps ?? []

    useEffect(() => {
        setSelectedDefsDragItems([])
        setSelectedVariantDragItems([])
    }, [defs.length, compSetProps.length])

    useEffect(() => {
        selectComponentProp(!!selectedVariantDragItems.length || !!selectedDefsDragItems.length)
    }, [selectedVariantDragItems, selectedDefsDragItems, selectComponentProp])

    return (
        <div data-testid="component-prop-list">
            <ComponentPropVariantList
                compSetProps={compSetProps as Wukong.DocumentProto.ComponentSetProp[]}
                setPopupPosition={setPopupPosition}
                openEditPopup={openEditPopup}
                popupType={popupType}
                popupVal={popupVal}
                closePopup={closePopup}
                otherCompDefName={defs.map((def) => def.name)}
                selectedDragItems={selectedVariantDragItems}
                setSelectedDragItems={(items) => {
                    setSelectedVariantDragItems(items)
                    setSelectedDefsDragItems([])
                }}
            />
            <ComponentPropDefsList
                defs={defs}
                popupVal={popupVal}
                popupType={popupType}
                selectedDragItems={selectedDefsDragItems}
                setSelectedDragItems={(items) => {
                    setSelectedDefsDragItems(items)
                    setSelectedVariantDragItems([])
                }}
                reorderDefs={reorderDefs}
                openEditPopup={openEditPopup}
                removeProp={(v) => {
                    removeProp(v)
                    setSelectedDefsDragItems([])
                }}
                updateProp={updateProp}
                setPopupPosition={setPopupPosition}
                closePopup={closePopup}
                highlightNodesByIds={highlightNodesByIds}
                batchRemoveProps={(v) => {
                    batchRemoveProps(v)
                    setSelectedDefsDragItems([])
                }}
            />
            <PublicPropArea chains={publicPropChains} />
        </div>
    )
}
