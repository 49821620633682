import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    selectAll: '全选',
    Guest: '访客',
} as const

export const enTranslation = {
    selectAll: 'Select all',
    Guest: 'Guest',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
