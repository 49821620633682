import { IconClose16, WKIconButton, _WKDialog } from '../../../../../../../ui-lib/src'
import classnames from 'classnames'
import React from 'react'
import classes from './pay-upgrade-dialog-container.module.less'

type _WKDialogProps = Parameters<typeof _WKDialog>[0]

export interface PayUpgradeDialogContainerProps
    extends Omit<_WKDialogProps, 'title' | 'showTitle' | 'footer' | 'bodyStyle' | 'children'> {
    hide: boolean
}

export function PayUpgradeDialogContainer(props: React.PropsWithChildren<PayUpgradeDialogContainerProps>) {
    const { hide, className, ...otherProps } = props

    return (
        <_WKDialog
            showTitle={false}
            footer={null}
            bodyStyle={{ padding: 0 }}
            testId="pay-upgrade-dialog-container"
            className={classnames(className, { [classes.hide]: hide })}
            {...otherProps}
            enabledMarginTop
            escClosable
        >
            <div className={classes.content}>
                <WKIconButton
                    data-testid="close-icon"
                    className={classes.close}
                    onClick={props.onCancel}
                    icon={<IconClose16 />}
                ></WKIconButton>
                {props.children}
            </div>
        </_WKDialog>
    )
}
