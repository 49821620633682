/* eslint-disable no-restricted-imports */
import {
    ViewNextFrameCommand,
    ViewPrevFrameCommand,
    ZoomToNextFrameCommand,
    ZoomToPrevFrameCommand,
} from '@wukong/bridge-proto'
import { BridgeCommand } from './command-invoker'

export const cmdZoomToNextFrame: BridgeCommand = (ctx) => {
    ctx.commandInvoker.DEPRECATED_invokeBridge(ZoomToNextFrameCommand)
}

export const cmdZoomToPrevFrame: BridgeCommand = (ctx) => {
    ctx.commandInvoker.DEPRECATED_invokeBridge(ZoomToPrevFrameCommand)
}

export const cmdViewNextFrame: BridgeCommand = (ctx) => {
    ctx.commandInvoker.DEPRECATED_invokeBridge(ViewNextFrameCommand)
}

export const cmdViewPrevFrame: BridgeCommand = (ctx) => {
    ctx.commandInvoker.DEPRECATED_invokeBridge(ViewPrevFrameCommand)
}
