import { StrokeDashCap } from '../stroke-dash-cap/stroke-dash-cap'
import { StrokeDashGap } from '../stroke-dash-gap/stroke-dash-gap'
import { StrokeDashLen } from '../stroke-dash-len/stroke-dash-len'
import { StrokeSingleEndPoint } from '../stroke-end-point/stroke-end-point'
import { StrokeJoin } from '../stroke-join/stroke-join'
import { StrokeMiterAngle } from '../stroke-miter-angle/stroke-miter-angle'
import { StrokeMode } from '../stroke-mode/stroke-mode'
import style from './advance-stroke.module.less'

export function AdvanceStroke() {
    return (
        <>
            <div className={style.area}>
                <StrokeMode />
                <StrokeDashLen />
                <StrokeDashGap />
                <StrokeDashCap />
            </div>
            <StrokeSingleEndPoint />
            <div className={style.area}>
                <StrokeJoin />
                <StrokeMiterAngle />
            </div>
        </>
    )
}
