import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    ViewFontStyle: '查看文本样式',
    CreateFontStyle: '创建文本样式',
    EditFontStyle: '编辑文本样式',
} as const

export const enTranslation = {
    ViewFontStyle: 'View text style',
    CreateFontStyle: 'Create new text style',
    EditFontStyle: 'Edit text style',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
