import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    AutoSettingOptical: '自动设置 optical size',
    AdditionalAxes: '附加轴',
} as const

export const enTranslation = {
    AutoSettingOptical: 'Set optical size automatically',
    AdditionalAxes: 'Additional axes',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
