import { FeatureSwitchConfig, SwitchEnvironmentScopeType, SwitchStrategyType } from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'library-reduce-calc-further-optimize',
    owner: '',
    description: '',
    strategies: [
        {
            env: SwitchEnvironmentScopeType.ALL,
            config: { type: SwitchStrategyType.SPECIFIC_SUFFIX, suffix: ['@kanyun.com'] },
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
