export const Dot = () => (
    <svg style={{ flexShrink: 0 }} xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
        <path
            transform="matrix(1 0 0 1 1 1)"
            d="M2 0C3.10457 0 4 0.89543 4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0Z"
            fillRule="nonzero"
            fill="rgb(69, 73, 89)"
        />
    </svg>
)
