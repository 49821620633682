import { Placement, Point, Rect } from './type'

export function getFrontEndOfLineInLimited(
    worldStart: number,
    triggerStart: number,
    lineLength: number,
    triggerEnd: number,
    worldEnd: number,
    offsetCenter: number
) {
    const center = (triggerStart + triggerEnd) / 2
    const haftLen = lineLength / 2
    const leftPriorityCalculation = center <= (worldEnd + worldStart) / 2
    const isLeftOverflow = center - haftLen < worldStart
    const isRightOverflow = center + haftLen > worldEnd
    const calculateLeftOverflow = () => {
        const lower =
            worldStart <= center - offsetCenter ? worldStart : Math.max(center - haftLen, center - offsetCenter)
        return lower
    }
    const calculateRightOverflow = () => {
        const upper = worldEnd >= center + offsetCenter ? worldEnd : Math.min(center + haftLen, center + offsetCenter)
        return upper - lineLength
    }
    if (leftPriorityCalculation) {
        if (isLeftOverflow) {
            return calculateLeftOverflow()
        } else if (isRightOverflow) {
            return calculateRightOverflow()
        }
    } else {
        if (isRightOverflow) {
            return calculateRightOverflow()
        } else if (isLeftOverflow) {
            return calculateLeftOverflow()
        }
    }
    return center - haftLen
}

export function isPointInRect(position: Readonly<Point>, rect: Readonly<Rect>) {
    // rect 和 e.clientX、e.clientY 存在误差。2是发现的最大误差（https://www.notion.so/kanyun/f2febe5ded644c6ab2305fd4700b9270?pvs=4#0b0b0bbef7a044d692b46a71576a842c）
    const precision = 2
    const isInRect =
        position.x >= Math.floor(rect.left) - precision &&
        position.x <= Math.ceil(rect.right) + precision &&
        position.y >= Math.floor(rect.top) - precision &&
        position.y <= Math.ceil(rect.bottom) + precision
    return isInRect
}

/**
 * @description 返回一个一个指向给定 triggerRect 矩形的箭头样式
 */
export function createArrowStyle(triggerRect: Rect, arrowHeight: number, placement: Placement) {
    let arrowTop: number, arrowLeft: number
    const triggerHorizontal = (triggerRect.top + triggerRect.bottom) / 2
    const triggerVertical = (triggerRect.left + triggerRect.right) / 2
    const rectSideLength = arrowHeight * Math.sqrt(2)
    const rotateOffset = arrowHeight * (1 - Math.sqrt(2) / 2)
    let transform = ';'
    switch (placement) {
        case 'left': {
            arrowTop = triggerHorizontal
            arrowLeft = triggerRect.left - rectSideLength
            transform = `translate(-${rotateOffset}px,-50%) rotate(45deg)`
            break
        }
        case 'right': {
            arrowTop = triggerHorizontal
            arrowLeft = triggerRect.right
            transform = `translate(${rotateOffset}px,-50%) rotate(45deg)`
            break
        }
        case 'top': {
            arrowTop = triggerRect.top - rectSideLength
            arrowLeft = triggerVertical
            transform = `translate(-50%, -${rotateOffset}px) rotate(45deg)`
            break
        }
        case 'bottom':
        default: {
            arrowTop = triggerRect.bottom
            arrowLeft = triggerVertical
            transform = `translate(-50%, ${rotateOffset}px) rotate(45deg)`
        }
    }
    return `top:${arrowTop}px;left:${arrowLeft}px; width:${rectSideLength}px;height:${rectSideLength}px; transform: ${transform};`
}
