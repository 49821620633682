/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { useImperativeHandle, useMemo, useRef, useState } from 'react'
import { DraggablePopupRef, OmitToJSON } from '../../../../../../../ui-lib/src'
import { ImagePaint, RGB } from '../../../../../document/node/node'
import { DeepRequired } from '../../../../../view-state-bridge'
import { switchPaintType } from '../../../../utils/switch-paint-type'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { useRenderColorSpace } from '../../../color-profile'
import { ColorInteractionFrom, ColorPanelProps, ColorPanelTitleActiveKey, PaintMode } from '../type'
import { getDefaultSolidPaint } from '../utils'

function getPanelPaintState(props: ColorPanelProps) {
    switch (props.from) {
        case ColorInteractionFrom.FILL_PAINT:
        case ColorInteractionFrom.STROKE_PAINT:
        case ColorInteractionFrom.STYLE_PAINT:
        case ColorInteractionFrom.COLOR_STOP:
        case ColorInteractionFrom.BACKGROUND:
        case ColorInteractionFrom.GRID:
        case ColorInteractionFrom.EFFECT:
        case ColorInteractionFrom.LOCAL_VARIABLE_EDIT:
        case ColorInteractionFrom.LOCAL_VARIABLE_CREATE_ALIAS:
            return { paint: props.paint, isFallback: false }
        case ColorInteractionFrom.FILL:
        case ColorInteractionFrom.STROKE:
        case ColorInteractionFrom.SELECTION_COLOR:
        case ColorInteractionFrom.SELECTION_COLOR_FAKE_STYLE:
            return props.paints.length === 1
                ? { paint: props.paints[0], isFallback: !!(props.styleId || props.paints[0].colorVar) }
                : { paint: getDefaultSolidPaint(), isFallback: true }
    }
}

function getDefaultActiveTitle(props: ColorPanelProps) {
    switch (props.from) {
        case ColorInteractionFrom.FILL_PAINT:
        case ColorInteractionFrom.STROKE_PAINT:
        case ColorInteractionFrom.STYLE_PAINT:
        case ColorInteractionFrom.COLOR_STOP:
        case ColorInteractionFrom.EFFECT:
        case ColorInteractionFrom.LOCAL_VARIABLE_EDIT:
            return props.paint.colorVar ? ColorPanelTitleActiveKey.LIBRARIES : ColorPanelTitleActiveKey.CUSTOM
        case ColorInteractionFrom.LOCAL_VARIABLE_CREATE_ALIAS:
            return ColorPanelTitleActiveKey.LIBRARIES
        case ColorInteractionFrom.SELECTION_COLOR:
            return !props.styleId && props.paints.length === 1 && !props.paints[0].colorVar
                ? ColorPanelTitleActiveKey.CUSTOM
                : ColorPanelTitleActiveKey.LIBRARIES
        case ColorInteractionFrom.SELECTION_COLOR_FAKE_STYLE:
        case ColorInteractionFrom.FILL:
        case ColorInteractionFrom.STROKE:
            return ColorPanelTitleActiveKey.LIBRARIES
        case ColorInteractionFrom.BACKGROUND:
        case ColorInteractionFrom.GRID:
            return ColorPanelTitleActiveKey.CUSTOM
    }
}

export interface ColorPanelRef {
    getContainerElement: () => HTMLDivElement | null
    clearPaintCache: () => void
}

export function useColorPanel(props: ColorPanelProps, ref: React.Ref<ColorPanelRef>) {
    const [activeTitle, setActiveTitle] = useState<ColorPanelTitleActiveKey>(getDefaultActiveTitle(props))
    const colorSpace = useRenderColorSpace()
    const hasMoved = useRef<boolean>(false)
    const colorPanelContainerRef = useRef<HTMLDivElement>(null)
    const colorPanelContainerV2Ref = useRef<DraggablePopupRef>(null)
    const lastModifiedPaintRef = useRef<Wukong.DocumentProto.IPaint>()

    const panelPaintState = getPanelPaintState(props)

    const derivedProps = useMemo(() => {
        const _derivedProps = {
            hideTools: false,
            hideImage: false,
        }
        switch (props.from) {
            case ColorInteractionFrom.LOCAL_VARIABLE_EDIT:
            case ColorInteractionFrom.LOCAL_VARIABLE_CREATE_ALIAS:
            case ColorInteractionFrom.BACKGROUND:
            case ColorInteractionFrom.GRID:
            case ColorInteractionFrom.EFFECT:
            case ColorInteractionFrom.COLOR_STOP: {
                _derivedProps.hideTools = true
                break
            }
            case ColorInteractionFrom.SELECTION_COLOR_FAKE_STYLE:
            case ColorInteractionFrom.SELECTION_COLOR: {
                if (!props.styleId) {
                    _derivedProps.hideImage = true
                }
                break
            }
            case ColorInteractionFrom.FILL_PAINT:
            case ColorInteractionFrom.STROKE_PAINT:
            case ColorInteractionFrom.FILL:
            case ColorInteractionFrom.STROKE:
            case ColorInteractionFrom.STYLE_PAINT:
        }
        return _derivedProps
    }, [props.from, props.styleId])

    const onFirstMove = () => {
        hasMoved.current = true
    }

    const onChangeBlendMode = (blendMode: Wukong.DocumentProto.BlendMode) => {
        lastModifiedPaintRef.current = undefined
        if (panelPaintState.isFallback) {
            props.onChangePaints?.([{ ...panelPaintState.paint, colorVar: null, blendMode }])
            return
        }
        props.onChangeBlendMode(blendMode)
    }

    const onChangeColor = (rgb: RGB, options?: InputOptionsForUndoSquash) => {
        lastModifiedPaintRef.current = undefined
        if (panelPaintState.isFallback) {
            props.onChangePaints?.([{ ...panelPaintState.paint, colorVar: null, color: rgb }])
            return
        }
        props.onChangeColor?.(rgb, options)
    }

    const onChangeOpacity = (opacity: number, options?: InputOptionsForUndoSquash) => {
        lastModifiedPaintRef.current = undefined
        if (panelPaintState.isFallback) {
            props.onChangePaints?.([{ ...panelPaintState.paint, colorVar: null, opacity }])
            return
        }
        props.onChangeOpacity?.(opacity, options)
    }

    const onChangeImagePaint = (imagePaint: ImagePaint, options?: InputOptionsForUndoSquash) => {
        lastModifiedPaintRef.current = undefined
        if (panelPaintState.isFallback) {
            props.onChangePaints?.([imagePaint])
            return
        }
        props.onChangeImagePaint?.(imagePaint, options)
    }
    const onChangeColorStops = (
        colorStops: OmitToJSON<Wukong.DocumentProto.ColorStop>[],
        options?: InputOptionsForUndoSquash
    ) => {
        lastModifiedPaintRef.current = undefined
        if (panelPaintState.isFallback) {
            props.onChangePaints?.([{ ...panelPaintState.paint, colorVar: null, gradientStops: colorStops }])
            return
        }
        props.onChangeColorStops?.(colorStops, options)
    }
    const onChangeTransform = (transform: OmitToJSON<Wukong.DocumentProto.Transform>) => {
        lastModifiedPaintRef.current = undefined
        if (panelPaintState.isFallback) {
            props.onChangePaints?.([{ ...panelPaintState.paint, colorVar: null, gradientTransform: transform }])
            return
        }
        props.onChangeTransform?.(transform)
    }
    const onChangePaintType = (paintType: Wukong.DocumentProto.PaintType) => {
        if (!lastModifiedPaintRef.current) {
            lastModifiedPaintRef.current = panelPaintState.paint
        }
        if (panelPaintState.isFallback) {
            const paint = {
                ...panelPaintState.paint,
                colorVar: null,
            } as unknown as DeepRequired<Wukong.DocumentProto.IPaint>
            props.onChangePaints?.([switchPaintType(paint, paintType)])
            return
        }
        const nextDefaultPaint =
            lastModifiedPaintRef.current?.type === paintType ? lastModifiedPaintRef.current : undefined
        props.onChangePaintType?.(paintType, nextDefaultPaint)
    }

    const onChangePaintMode = (paintMode: PaintMode) => {
        switch (paintMode) {
            case PaintMode.COLOR:
                onChangePaintType(Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT)
                break
            case PaintMode.GRADIENT:
                onChangePaintType(Wukong.DocumentProto.PaintType.PAINT_TYPE_GRADIENT_LINEAR)
                break
            case PaintMode.IMAGE:
                onChangePaintType(Wukong.DocumentProto.PaintType.PAINT_TYPE_IMAGE_PAINT)
                break
        }
    }

    const onChangeColorVar = (colorVar: Wukong.DocumentProto.IVariableAliasData) => {
        props.onCancel()
        props.onChangeColorVar?.(colorVar)
    }

    const onChangeStyle = (styleId: string) => {
        props.onChangeStyle?.(styleId, true)
    }

    useImperativeHandle(ref, () => ({
        getContainerElement: () => colorPanelContainerV2Ref.current?.getPopupElement() || null,
        clearPaintCache: () => {
            lastModifiedPaintRef.current = undefined
        },
    }))

    return {
        originalProps: props,
        derivedProps,
        paint: panelPaintState.paint,
        activeTitle,
        colorSpace,
        colorPanelContainerRef,
        colorPanelContainerV2Ref,
        onFirstMove,
        setActiveTitle,
        onChangePaintMode,
        onChangeBlendMode,
        onChangeColor,
        onChangeOpacity,
        onChangeImagePaint,
        onChangeColorStops,
        onChangeTransform,
        onChangePaintType,
        onChangeColorVar,
        onChangeStyle,
    }
}
