import { deleteAsync, getAsync, keysAsync, setAsync } from '../../document/plugin-ui/db/client-storage'
import { IPluginAPIContext } from '../plugin-api-context-interface'

export function createClientStorageApi(ctx: IPluginAPIContext) {
    const vm = ctx.vm
    const apiObject = vm.newObject()

    ctx.defineVmFunction({
        objhandle: apiObject,
        key: 'getAsync',
        func: (keyHandle) => {
            const key = vm.getString(keyHandle)
            const { promise, resolve, reject } = vm.newPromise()

            vm.registerPromise(getAsync(key, ctx.hostService.getUserId(), ctx.hostService.getPluginId())).then(
                (result) => {
                    resolve(result ? vm.deepWrapHandle(result.value) : vm.undefined)
                },
                (_) => {
                    reject(vm.newString(`Failed to get async with key: ${key}`))
                }
            )

            return promise
        },
    })

    ctx.defineVmFunction({
        objhandle: apiObject,
        key: 'setAsync',
        func: (keyHandle, valueHandle) => {
            const key = vm.getString(keyHandle)
            const value = vm.deepUnWrapHandle(valueHandle)
            const { promise, resolve, reject } = vm.newPromise()

            vm.registerPromise(setAsync(key, value, ctx.hostService.getUserId(), ctx.hostService.getPluginId())).then(
                () => {
                    resolve(vm.undefined)
                },
                (_) => {
                    reject(vm.newString(`Failed to set async with key: ${key}`))
                }
            )

            return promise
        },
    })

    ctx.defineVmFunction({
        objhandle: apiObject,
        key: 'deleteAsync',
        func: (keyHandle) => {
            const key = vm.getString(keyHandle)
            const { promise, resolve, reject } = vm.newPromise()
            vm.registerPromise(deleteAsync(key, ctx.hostService.getUserId(), ctx.hostService.getPluginId())).then(
                () => {
                    resolve(vm.undefined)
                },
                (_) => {
                    reject(vm.newString(`Failed to delete async with key: ${key}`))
                }
            )
            return promise
        },
    })

    ctx.defineVmFunction({
        objhandle: apiObject,
        key: 'keysAsync',
        func: () => {
            const { promise, resolve, reject } = vm.newPromise()
            vm.registerPromise(keysAsync(ctx.hostService.getUserId(), ctx.hostService.getPluginId())).then(
                (result) => {
                    resolve(vm.deepWrapHandle(result))
                },
                (_) => {
                    reject(vm.newString(`Failed to get keys async`))
                }
            )
            return promise
        },
    })

    return apiObject
}
