/* eslint-disable no-restricted-imports */
import { SetIndependentStrokeTypeForSelectionCommand, Wukong } from '@wukong/bridge-proto'
import { useCallback, useEffect, useState } from 'react'
import { useDeepEqual } from '../../../../../../../util/src/hooks/use-deep-equal'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'
import { useSelectionState } from '../../../../document/selection/use-selection-state'

export function useStrokeIndependentType() {
    const command = useCommand()
    const selectionStroke = useViewState('selectionStrokeV2')
    const allBorderWeightValue = selectionStroke?.weightState.allBorderState.value
    const _typeState = useDeepEqual(selectionStroke?.weightState.typeState)
    const { changeSelectionInfo } = useSelectionState()

    const [userSelectTypeState, setUserSelectTypeState] =
        useState<Wukong.DocumentProto.ISelectionStrokeIndependentTypeState>()

    useEffect(() => {
        // NOTE: 线重值为 0 时保留当前单边描边类型值
        setUserSelectTypeState((prev) => {
            if (
                allBorderWeightValue === 0 &&
                _typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_ALL &&
                prev?.value &&
                [
                    Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_TOP,
                    Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_RIGHT,
                    Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_BOTTOM,
                    Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_LEFT,
                ].includes(prev.value)
            ) {
                return prev
            }
            return _typeState
        })
    }, [_typeState, allBorderWeightValue])

    // 选区改变时，无条件赋值当前类型值
    useEffect(() => {
        setUserSelectTypeState(_typeState)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeSelectionInfo])

    const onChangeType = useCallback(
        (value: Wukong.DocumentProto.IndependentStrokeType) => {
            command.DEPRECATED_invokeBridge(SetIndependentStrokeTypeForSelectionCommand, { type: value })
            setUserSelectTypeState({
                type: Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_NORMAL,
                value,
            })
            command.commitUndo()
        },
        [command]
    )

    return {
        iconTypeState: _typeState,
        userSelectTypeState,
        onChangeType,
    }
}
