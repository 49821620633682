import { useCallback } from 'react'
import { DropdownV2, MonoIconPanelMore16, Tooltip, WKTextButton } from '../../../../../../../ui-lib/src'
import { ReactComponent as CustomPluginIcon } from '../../../../assets/plugins/custom-plugin.svg'
import { LocalPlugin, PluginLocalManifestFileError } from '../template/type'
import { usePluginDevelopment } from '../use-plugin-development'
import { translation } from './plugin-dev-main-item.translation'

const errorTextMap = {
    [PluginLocalManifestFileError.NotFoundError]: translation('NotFoundError'),
    [PluginLocalManifestFileError.ReadFileError]: translation('ReadFileError'),
    [PluginLocalManifestFileError.NoNameError]: translation('NoNameError'),
    [PluginLocalManifestFileError.NoMainError]: translation('NoMainError'),
    [PluginLocalManifestFileError.NoIdError]: translation('NoIdError'),
    [PluginLocalManifestFileError.NoUiError]: translation('NoUiError'),
    [PluginLocalManifestFileError.PermissionDeniedError]: translation('PermissionDeniedError'),
}

enum PluginDevMainItemActionType {
    Run = 0, // 运行
    Publish, // 发布
    EditorPublishInfo, // 编辑插件信息
    Republish, // 发布新版本
    Remove, // 移除
    OpenFolder, // 在文件夹中显示
    RunPublishVersion, // 运行发布版本
    RunLocalVersion, // 运行本地版本
    OperationRole, // 管理权限
    RemoveLocalVersion, // 移除本地版本
    UploadLocalVersion, // 上传本地版本
    Unpublish, // 取消发布

    SPLIT_LINE, // 分割线
}

export function PluginDevelopmentItem({ plugin }: { plugin: LocalPlugin }) {
    const {
        currentUserId,
        runCustomPlugin,
        runPluginPublishVersion,
        runPluginLocalVersion,
        openPublishDialog,
        importCustomPlugin,
        unpublishPlugin,
        removeLocalPlugin,
        openLocalFileFolder,
        openEditorPublishInfoDialog,
        openRepublishDialog,
        openEditorPermissionDialog,
    } = usePluginDevelopment()

    const needUploadLocalManifest =
        plugin.publishInfo &&
        (!plugin.path || plugin.localManifestFileError === PluginLocalManifestFileError.NotFoundError)

    const moreActions = computeMoreActions(plugin, currentUserId)

    const locateLocalVersion = useCallback(async () => {
        const importedPlugin = await importCustomPlugin()
        if (importedPlugin) {
            // 导入成功后，如果导入的不是同 path 的则删除旧的插件
            if (importedPlugin.path !== plugin.path) {
                removeLocalPlugin(plugin)
            }
        }
    }, [importCustomPlugin, plugin, removeLocalPlugin])

    return (
        <div className="flex justify-start items-center h-42px" data-testid="dev-plugin-item">
            {plugin.publishInfo ? (
                <img src={plugin.publishInfo.iconUrl} className="mr-12px w-32px h-32px" />
            ) : (
                <CustomPluginIcon className="mr-12px" />
            )}
            <div className="flex flex-col flex-1 mr-2 overflow-hidden">
                <div className="flex flex-row justify-start items-center">
                    <div
                        className="h-22px wk-text-14 wk-font-medium color-$wk-v2-label-color-gray-13 text-ellipsis overflow-hidden ws-nowrap"
                        data-testid="dev-plugin-name"
                    >
                        {plugin.publishInfo ? plugin.publishInfo.name : plugin.name}
                    </div>
                    {plugin.publishInfo && (
                        <div
                            className="flex justify-center items-center box-border ml-2 px-3px h-18px whitespace-nowrap text-11px lh-18px
                            wk-font-regular color-$wk-green-7 border-1 border-solid border-$wk-green-3 rounded-2px"
                        >
                            {translation('Published')}
                        </div>
                    )}
                </div>
                {needUploadLocalManifest ? (
                    <WKTextButton type={'secondary'} size={12} onClick={importCustomPlugin} className="w-fit">
                        {translation('UploadLocalVersionBtn')}
                    </WKTextButton>
                ) : (
                    <div
                        className={`h-20px wk-text-12 wk-font-regular text-ellipsis overflow-hidden ws-nowrap ${
                            plugin.localManifestFileError ? 'color-$wk-red-8' : 'color-$wk-v2-label-color-gray-8'
                        }`}
                    >
                        {plugin.localManifestFileError ? errorTextMap[plugin.localManifestFileError] : plugin.path}
                        {plugin.localManifestFileError === PluginLocalManifestFileError.NotFoundError && (
                            <WKTextButton
                                type={'secondary'}
                                size={12}
                                onClick={locateLocalVersion}
                                className="ml-2! w-fit"
                            >
                                {translation('UploadFile')}
                            </WKTextButton>
                        )}
                    </div>
                )}
            </div>

            <Tooltip title={translation('MoreOptions')} triggerRefKey="getTriggerElement">
                <DropdownV2.IconSingleLevel
                    label={<MonoIconPanelMore16 />}
                    onChange={(value: PluginDevMainItemActionType) => {
                        switch (value) {
                            case PluginDevMainItemActionType.Run:
                                runCustomPlugin(plugin)
                                break
                            case PluginDevMainItemActionType.RunPublishVersion:
                                runPluginPublishVersion(plugin)
                                break
                            case PluginDevMainItemActionType.RunLocalVersion:
                                runPluginLocalVersion(plugin)
                                break
                            case PluginDevMainItemActionType.Publish:
                                openPublishDialog(plugin)
                                break
                            case PluginDevMainItemActionType.Republish:
                                openRepublishDialog(plugin)
                                break
                            case PluginDevMainItemActionType.Unpublish:
                                unpublishPlugin(plugin)
                                break
                            case PluginDevMainItemActionType.EditorPublishInfo:
                                openEditorPublishInfoDialog(plugin)
                                break
                            case PluginDevMainItemActionType.OpenFolder:
                                openLocalFileFolder(plugin)
                                break
                            case PluginDevMainItemActionType.OperationRole:
                                openEditorPermissionDialog(plugin.publishInfo!)
                                break
                            case PluginDevMainItemActionType.RemoveLocalVersion:
                                removeLocalPlugin(plugin)
                                break
                            case PluginDevMainItemActionType.UploadLocalVersion:
                                importCustomPlugin()
                                break
                            case PluginDevMainItemActionType.Remove:
                                removeLocalPlugin(plugin)
                                break
                            case PluginDevMainItemActionType.SPLIT_LINE:
                                break
                        }
                    }}
                    dataTestIds={{
                        triggerFocus: 'plugin-item-more-actions',
                        scrollContainer: 'plugin-item-more-actions-container',
                    }}
                >
                    {moreActions.map(({ actionType, splitLineBottom }) => (
                        <PluginDevMainItemAction
                            actionType={actionType}
                            splitLineBottom={splitLineBottom}
                            key={actionType}
                        />
                    ))}
                </DropdownV2.IconSingleLevel>
            </Tooltip>
        </div>
    )
}

const computeMoreActionsType = (plugin: LocalPlugin, currentUserId: number): PluginDevMainItemActionType[] => {
    const hasLocalVersion = plugin.path && plugin.localManifestFileError !== PluginLocalManifestFileError.NotFoundError
    const manifestEnabled = plugin.path && !plugin.localManifestFileError
    // 纯本地插件的 isOwner 判定属于未定义行为，因为不能确认是新建的还是拷贝的，不过暂时没有用到这个逻辑的需求，先设为 false
    const isOwner = plugin.publishInfo ? plugin.publishInfo.ownerUser?.userBrief.id === currentUserId : false

    if (!plugin.publishInfo) {
        if (manifestEnabled) {
            return [
                PluginDevMainItemActionType.Run,
                PluginDevMainItemActionType.SPLIT_LINE,
                PluginDevMainItemActionType.Publish,
                PluginDevMainItemActionType.OpenFolder,
                PluginDevMainItemActionType.Remove,
            ]
        } else {
            return [
                ...(hasLocalVersion ? [PluginDevMainItemActionType.OpenFolder] : []),
                PluginDevMainItemActionType.SPLIT_LINE,
                PluginDevMainItemActionType.Remove,
            ]
        }
    }

    return [
        ...(manifestEnabled
            ? [PluginDevMainItemActionType.RunPublishVersion, PluginDevMainItemActionType.RunLocalVersion]
            : [PluginDevMainItemActionType.Run]),
        PluginDevMainItemActionType.SPLIT_LINE,
        ...(manifestEnabled ? [PluginDevMainItemActionType.Republish] : []),
        PluginDevMainItemActionType.EditorPublishInfo,
        PluginDevMainItemActionType.OperationRole,
        ...(hasLocalVersion ? [PluginDevMainItemActionType.OpenFolder] : []),
        ...(hasLocalVersion
            ? [PluginDevMainItemActionType.RemoveLocalVersion]
            : [PluginDevMainItemActionType.UploadLocalVersion]),
        ...(isOwner ? [PluginDevMainItemActionType.SPLIT_LINE, PluginDevMainItemActionType.Unpublish] : []),
    ]
}

const computeMoreActions = (
    plugin: LocalPlugin,
    currentUserId: number
): { actionType: PluginDevMainItemActionType; splitLineBottom?: boolean }[] => {
    const moreActionsType = computeMoreActionsType(plugin, currentUserId)
    const res: { actionType: PluginDevMainItemActionType; splitLineBottom?: boolean }[] = []

    moreActionsType.forEach((actionType, index) => {
        if (actionType === PluginDevMainItemActionType.SPLIT_LINE && index !== 0) {
            res[res.length - 1].splitLineBottom = true
        } else {
            res.push({ actionType })
        }
    })

    return res
}

const PluginDevMainItemAction = ({
    actionType,
    splitLineBottom,
}: {
    actionType: PluginDevMainItemActionType
    splitLineBottom?: boolean
}) => {
    switch (actionType) {
        case PluginDevMainItemActionType.Run:
            return (
                <DropdownV2.IconSingleLevel.Option
                    value={PluginDevMainItemActionType.Run}
                    splitLineBottom={splitLineBottom}
                >
                    {translation('Run')}
                </DropdownV2.IconSingleLevel.Option>
            )
        case PluginDevMainItemActionType.Publish: {
            return (
                <DropdownV2.IconSingleLevel.Option
                    value={PluginDevMainItemActionType.Publish}
                    dataTestId="plugin-item-publish"
                    splitLineBottom={splitLineBottom}
                >
                    {translation('Publish')}
                </DropdownV2.IconSingleLevel.Option>
            )
        }
        case PluginDevMainItemActionType.EditorPublishInfo: {
            return (
                <DropdownV2.IconSingleLevel.Option
                    value={PluginDevMainItemActionType.EditorPublishInfo}
                    dataTestId="plugin-item-editor-publish-info"
                    splitLineBottom={splitLineBottom}
                >
                    {translation('EditorPublishDetails')}
                </DropdownV2.IconSingleLevel.Option>
            )
        }
        case PluginDevMainItemActionType.Republish: {
            return (
                <DropdownV2.IconSingleLevel.Option
                    value={PluginDevMainItemActionType.Republish}
                    dataTestId="plugin-item-republish"
                    splitLineBottom={splitLineBottom}
                >
                    {translation('Republish')}
                </DropdownV2.IconSingleLevel.Option>
            )
        }
        case PluginDevMainItemActionType.Remove: {
            return (
                <DropdownV2.IconSingleLevel.Option
                    value={PluginDevMainItemActionType.Remove}
                    splitLineBottom={splitLineBottom}
                >
                    {translation('Remove')}
                </DropdownV2.IconSingleLevel.Option>
            )
        }
        case PluginDevMainItemActionType.OpenFolder: {
            return (
                <DropdownV2.IconSingleLevel.Option
                    value={PluginDevMainItemActionType.OpenFolder}
                    splitLineBottom={splitLineBottom}
                >
                    {translation('OpenFolder')}
                </DropdownV2.IconSingleLevel.Option>
            )
        }
        case PluginDevMainItemActionType.RunPublishVersion: {
            return (
                <DropdownV2.IconSingleLevel.Option
                    value={PluginDevMainItemActionType.RunPublishVersion}
                    splitLineBottom={splitLineBottom}
                >
                    {translation('RunPublishVersion')}
                </DropdownV2.IconSingleLevel.Option>
            )
        }
        case PluginDevMainItemActionType.RunLocalVersion: {
            return (
                <DropdownV2.IconSingleLevel.Option
                    value={PluginDevMainItemActionType.RunLocalVersion}
                    splitLineBottom={splitLineBottom}
                >
                    {translation('RunLocalVersion')}
                </DropdownV2.IconSingleLevel.Option>
            )
        }
        case PluginDevMainItemActionType.OperationRole: {
            return (
                <DropdownV2.IconSingleLevel.Option
                    value={PluginDevMainItemActionType.OperationRole}
                    splitLineBottom={splitLineBottom}
                >
                    {translation('ManagePermissions')}
                </DropdownV2.IconSingleLevel.Option>
            )
        }
        case PluginDevMainItemActionType.RemoveLocalVersion: {
            return (
                <DropdownV2.IconSingleLevel.Option
                    value={PluginDevMainItemActionType.RemoveLocalVersion}
                    splitLineBottom={splitLineBottom}
                >
                    {translation('RemoveLocalVersion')}
                </DropdownV2.IconSingleLevel.Option>
            )
        }
        case PluginDevMainItemActionType.UploadLocalVersion: {
            return (
                <DropdownV2.IconSingleLevel.Option
                    value={PluginDevMainItemActionType.UploadLocalVersion}
                    splitLineBottom={splitLineBottom}
                >
                    {translation('UploadLocalVersion')}
                </DropdownV2.IconSingleLevel.Option>
            )
        }
        case PluginDevMainItemActionType.Unpublish: {
            return (
                <DropdownV2.IconSingleLevel.Option
                    value={PluginDevMainItemActionType.Unpublish}
                    splitLineBottom={splitLineBottom}
                >
                    {translation('Unpublish')}
                </DropdownV2.IconSingleLevel.Option>
            )
        }
        case PluginDevMainItemActionType.SPLIT_LINE: {
            return null
        }
    }
}
