export function getStyleShortName(name: string) {
    return getNameParts(name).pop() || ''
}

export const getNameParts = (name: string) => {
    const nameParts = (name || '').split('/').filter((part: string) => !!part)
    return nameParts.map((part: string) => part.trim())
}

export const getNamePrefix = (name: string) => {
    const pathParts = getNameParts(name)
    pathParts.pop()
    return pathParts.join('/')
}

export const path2Name = (path: string[]) => {
    return path.join('/')
}

export const getEventComposedPath = (event: Event) => {
    // NOTE: chrome最新版本删除了path属性，需要使用composedPath
    return event.composedPath()
}
