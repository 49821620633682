import {
    BuildLibraryComponentUpdateVOWasmCall,
    BuildLibraryStyleUpdateVOWasmCall,
    BuildLibraryVariableSetUpdateVOWasmCall,
    BuildLibraryVariableUpdateVOWasmCall,
    EndLibraryPublishModalPublishCheckedWasmCall,
    StartLibraryPublishModalPublishChecked,
    Wukong,
} from '@wukong/bridge-proto'
import { delay } from 'signal-timers'
import { WKToast } from '../../../../../../ui-lib/src'
import { ConcurrentScheduler, generateUniqString } from '../../../../../../util/src'
import { TraceableAbortSignal } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import { CommitType } from '../../../../document/command/commit-type'
import { benchmarkService } from '../../../../kernel/benchmark'
import { Bridge } from '../../../../kernel/bridge/bridge'
import { WkCLog } from '../../../../kernel/clog/wukong/instance'
import {
    LibraryContentVO,
    UpdateLibraryContentRequestV4,
    UpdateLibraryContentType,
    UpdateLibraryMetaRequestV4,
} from '../../../../kernel/interface/library'
import { CrashFrogType } from '../../../../kernel/interface/performance'
import { BusinessStatusCode } from '../../../../kernel/interface/request-error-code'
import { MetricCollector, MetricName } from '../../../../kernel/metric-collector'
import { RequestResponseErrorHandler } from '../../../../kernel/request/error-handler'
import {
    GetLibraryIdAndNameByDocIdRequest,
    PostLibrary,
    PutLibrary,
    PutLibraryContentV4,
} from '../../../../kernel/request/library'
import { ServiceClass } from '../../../../kernel/util/service-class'
import { ViewStateBridge } from '../../../../view-state-bridge'
import { HistoryService } from '../../history-file/history-service/history-service'
import { ToastProgressMessage } from '../../top-area/tool-sync-status/sync-toast-progress-message/sync-toast-progress-message'
import { isLibraryHasPublished } from '../util/status'
import { LibraryNotifySyncService } from './library-notify-sync-service'
import { translation } from './publish-components-executor.translation'
import {
    ComponentId,
    ComponentSetId,
    LibraryId,
    StyleVOId,
} from '../../../../kernel/interface/component-style-library-id'

const enum PublishType {
    // 新增
    Published,
    // 更新
    Updated,
    // 删除
    Unpublished,
}

interface ConvertFnRes {
    style?: Wukong.DocumentProto.IStyleUpdateVO
    component?: Wukong.DocumentProto.IComponentUpdateVO
    variants?: Wukong.DocumentProto.IComponentUpdateVO[]
    unpublishNodeKey?: string
    unpublishVariantKeys?: string[]
    variableSet?: Wukong.DocumentProto.IVariableSetUpdateVO
    variable?: Wukong.DocumentProto.IVariableUpdateVO
}

interface RemoteLibraryPiece {
    publishType: PublishType
    convertFn: () => ConvertFnRes
    voType: 'style' | 'component' | 'componentSet' | 'variableSet' | 'variable'
}

type RemoteLibraryPieceResult =
    | {
          publishType: PublishType.Unpublished
          voType: 'style' | 'component' | 'componentSet' | 'variableSet' | 'variable'
          libraryNodeKey: string
          variantKeys: string[]
      }
    | {
          publishType: PublishType.Published | PublishType.Updated
          voType: 'style'
          vo: Wukong.DocumentProto.IStyleUpdateVO
      }
    | {
          publishType: PublishType.Published | PublishType.Updated
          voType: 'component'
          vo: Wukong.DocumentProto.IComponentUpdateVO
      }
    | {
          publishType: PublishType.Published | PublishType.Updated
          voType: 'componentSet'
          vo: Wukong.DocumentProto.IComponentUpdateVO
          variants: Wukong.DocumentProto.IComponentUpdateVO[]
          unpublishVariantKeys?: string[]
      }
    | {
          publishType: PublishType.Published | PublishType.Updated
          voType: 'variableSet'
          vo: Wukong.DocumentProto.IVariableSetUpdateVO
      }
    | {
          publishType: PublishType.Published | PublishType.Updated
          voType: 'variable'
          vo: Wukong.DocumentProto.IVariableUpdateVO
      }

const MaxUploadByteSize = 31457280 // 上传请求内存占比限制

export class PublishComponentsExecutor extends ServiceClass {
    private readonly buildUpdateVOConcurrentScheduler = ConcurrentScheduler({ limitCount: 1, delayTime: 0 }) // 上传请求个数限制
    private progressToastId: string | null = null
    private isPublished = false

    constructor(
        private readonly docId: string,
        private readonly commandInvoker: CommandInvoker,
        protected override readonly bridge: Bridge,
        protected override readonly viewStateBridge: ViewStateBridge,
        private readonly libraryNotifySyncService: LibraryNotifySyncService,
        private readonly historyService: HistoryService,
        private readonly signal: TraceableAbortSignal
    ) {
        super(bridge, viewStateBridge)

        this.bridge.bind(
            StartLibraryPublishModalPublishChecked,
            async (arg) => {
                await this.run(arg)
                this.commandInvoker.DEPRECATED_invokeBridge(EndLibraryPublishModalPublishCheckedWasmCall)
            },
            { signal: this.signal }
        )
    }

    public override destroy(): void {
        super.destroy()
        this.closeProgressToast()
        this.buildUpdateVOConcurrentScheduler.destroy()
    }

    private run = async (
        arg: Omit<Wukong.DocumentProto.IArg_publishLibraryComponents, 'libraryShared' | 'libraryId'>
    ) => {
        if (this.viewStateBridge.getDefaultValue('publishModal')?.publishing) {
            return WKToast.show(`${translation('PublishingLibrary')}，${translation('PleaseTryAgain')}`)
        }
        const oldLibraryContent = await this.libraryNotifySyncService.getCurrentRemoteLibraryContentPromise()

        this.isPublished = !!oldLibraryContent?.library.shared && isLibraryHasPublished(oldLibraryContent.library)

        try {
            this.showProgressToast()

            const start = performance.now()
            const libraryId = await this.createOrUpdateLibrary(arg.shareScope, oldLibraryContent)
            await this.updateContent(libraryId, arg)
            MetricCollector.pushMetric(MetricName.LIBRARY_PUBLISH, performance.now() - start)

            await this.closeProgressToast()
            WKToast.show(
                this.isPublished
                    ? translation('SuccessfullyPublishedLibrary')
                    : translation('SuccessfullyPublishedLibrary_synonyms1')
            )

            this.historyService.requestCreateUpdateLibraryVersion(arg.description ?? '')

            benchmarkService.benchmarkCrash(CrashFrogType.PublishOrUpdateLibrary, false, {
                tag: this.isPublished ? 'publish' : 'update',
            })
        } catch (e) {
            try {
                await this.closeProgressToast()

                const { businessStatus } = RequestResponseErrorHandler(e)
                if (businessStatus === BusinessStatusCode.LibraryDocumentInDraftFolderCanNotPublish) {
                    return WKToast.show(translation('HDcKoP'))
                } else if (businessStatus === BusinessStatusCode.LibrarySharedScopeOverLimit) {
                    return WKToast.show(translation('TeamsAreNot'))
                } else {
                    WkCLog.log('[library] publish library uncaught businessStatus', {
                        businessStatus,
                    })
                }
            } catch {
                WkCLog.log('[library] publish library unknown error', {
                    errorMsg: `${e}`,
                })
            }

            benchmarkService.benchmarkCrash(CrashFrogType.PublishOrUpdateLibrary, true, {
                tag: this.isPublished ? 'publish' : 'update',
            })

            return WKToast.error(
                this.isPublished ? translation('FailedToPublish') : translation('FailedToPublish_synonyms1')
            )
        }
    }

    private showProgressToast = () => {
        this.progressToastId = WKToast.show(
            <ToastProgressMessage
                progress={0}
                progressMsg={
                    this.isPublished ? translation('PublishingLibrary_synonyms1') : translation('PublishingLibrary')
                }
                onProgressEnd={this.closeProgressToast}
            />,
            { duration: -1 }
        )
    }

    private updateProgressToast = (progress: number) => {
        if (this.progressToastId) {
            WKToast.updateMessage(
                this.progressToastId,
                <ToastProgressMessage
                    progress={progress}
                    progressMsg={
                        this.isPublished ? translation('PublishingLibrary_synonyms1') : translation('PublishingLibrary')
                    }
                    onProgressEnd={this.closeProgressToast}
                />
            )
        }
    }

    private closeProgressToast = () => {
        return new Promise<void>((resolve) => {
            const toastId = this.progressToastId
            if (toastId) {
                this.progressToastId = null
                // NOTE: 加 setTimeout 避免出现 Warning: Attempted to synchronously unmount a root while React was already rendering
                setTimeout(() => {
                    WKToast.close(toastId)
                    resolve()
                }, 0)
            } else {
                resolve()
            }
        })
    }

    private createOrUpdateLibrary = async (
        shareScope: string | null | undefined,
        oldLibraryContent: LibraryContentVO | null
    ): Promise<LibraryId> => {
        if (oldLibraryContent) {
            if (shareScope && oldLibraryContent?.library.shared && oldLibraryContent.library.shareScope == shareScope) {
                return oldLibraryContent.library.id
            }

            await new PutLibrary(oldLibraryContent.library.id, {
                id: oldLibraryContent.library.id,
                shared: true,
                shareScope,
            }).start()

            return oldLibraryContent.library.id
        }

        const existLibraryInfoVO = await new GetLibraryIdAndNameByDocIdRequest(this.docId, this.docId).start()
        if (existLibraryInfoVO) {
            await new PutLibrary(existLibraryInfoVO.id, {
                id: existLibraryInfoVO.id,
                shared: true,
                shareScope,
            }).start()

            return existLibraryInfoVO.id
        }

        return (
            await new PostLibrary({
                docId: this.docId,
                shareScope: shareScope,
            }).start()
        ).id
    }

    private getPublishPieceListByChangeList({
        changeList,
        convertFn,
        voType,
    }: {
        changeList: Wukong.DocumentProto.ILibraryPublishComponentItem[]
        convertFn: (change: Wukong.DocumentProto.ILibraryPublishComponentItem) => ConvertFnRes
        voType: 'style' | 'componentSet' | 'component' | 'variableSet' | 'variable'
    }) {
        const pieceList: RemoteLibraryPiece[] = []

        for (const change of changeList) {
            switch (change.changeType) {
                case Wukong.DocumentProto.LibraryChangeType.LIBRARY_CHANGE_TYPE_ADD:
                case Wukong.DocumentProto.LibraryChangeType.LIBRARY_CHANGE_TYPE_MOVEMENT: {
                    pieceList.push({
                        publishType: PublishType.Published,
                        voType,
                        convertFn: () => convertFn(change),
                    })
                    break
                }
                case Wukong.DocumentProto.LibraryChangeType.LIBRARY_CHANGE_TYPE_UPDATE: {
                    pieceList.push({
                        publishType: PublishType.Updated,
                        voType,
                        convertFn: () => convertFn(change),
                    })
                    break
                }
                case Wukong.DocumentProto.LibraryChangeType.LIBRARY_CHANGE_TYPE_REORDER: {
                    pieceList.push({
                        publishType: PublishType.Updated,
                        voType,
                        convertFn: () => convertFn(change),
                    })
                    break
                }
                case Wukong.DocumentProto.LibraryChangeType.LIBRARY_CHANGE_TYPE_REMOVE: {
                    const nodeKey = change.libraryNodeKey
                    if (nodeKey) {
                        pieceList.push({
                            publishType: PublishType.Unpublished,
                            voType,
                            convertFn: () => ({
                                unpublishNodeKey: nodeKey,
                                unpublishVariantKeys: change.removedLibraryVariantKeys ?? [],
                            }),
                        })
                    }
                    break
                }
                default:
                    break
            }
        }

        return pieceList
    }

    private updateContent = async (
        libraryId: LibraryId,
        arg: Omit<Wukong.DocumentProto.IArg_publishLibraryComponents, 'libraryShared' | 'libraryId'>
    ) => {
        const arr = [
            ...this.getPublishPieceListByChangeList({
                voType: 'style',
                changeList: arg.styles ?? [],
                convertFn: (change) => {
                    const ret = this.commandInvoker.DEPRECATED_invokeBridge(BuildLibraryStyleUpdateVOWasmCall, {
                        id: change.nodeId,
                        key: change.libraryNodeKey,
                        libraryId,
                    })
                    return {
                        style: ret.value ?? undefined,
                    }
                },
            }),
            ...this.getPublishPieceListByChangeList({
                voType: 'component',
                changeList: arg.components ?? [],
                convertFn: (change) => {
                    const ret = this.commandInvoker.DEPRECATED_invokeBridge(BuildLibraryComponentUpdateVOWasmCall, {
                        id: change.nodeId,
                        key: change.libraryNodeKey,
                        libraryId,
                    })
                    return {
                        component: ret.vo ?? undefined,
                    }
                },
            }),
            ...this.getPublishPieceListByChangeList({
                voType: 'componentSet',
                changeList: arg.componentSets ?? [],
                convertFn: (change) => {
                    const ret = this.commandInvoker.DEPRECATED_invokeBridge(BuildLibraryComponentUpdateVOWasmCall, {
                        id: change.nodeId,
                        key: change.libraryNodeKey,
                        libraryId,
                    })
                    return {
                        component: ret.vo ?? undefined,
                        variants: ret.variants ?? undefined,
                        unpublishVariantKeys: change.removedLibraryVariantKeys ?? [],
                    }
                },
            }),
        ]
        arr.push(
            ...this.getPublishPieceListByChangeList({
                voType: 'variableSet',
                changeList: arg.variableSets ?? [],
                convertFn: (change) => {
                    const ret = this.commandInvoker.invokeBridge(
                        CommitType.Noop,
                        BuildLibraryVariableSetUpdateVOWasmCall,
                        {
                            id: change.nodeId,
                            key: change.libraryNodeKey,
                            libraryId,
                        }
                    )
                    return {
                        variableSet: ret.value ?? undefined,
                    }
                },
            }),
            ...this.getPublishPieceListByChangeList({
                voType: 'variable',
                changeList: arg.variables ?? [],
                convertFn: (change) => {
                    const ret = this.commandInvoker.invokeBridge(
                        CommitType.Noop,
                        BuildLibraryVariableUpdateVOWasmCall,
                        {
                            id: change.nodeId,
                            key: change.libraryNodeKey,
                            libraryId,
                        }
                    )
                    return {
                        variable: ret.value ?? undefined,
                    }
                },
            })
        )
        await this.splitContentListUpdate(libraryId, arg, arr)
    }

    private async putLibraryContentWithRetry(requestContentData: UpdateLibraryContentRequestV4) {
        const maxRetryAttempts = 2
        const scalingDuration = 6000
        for (let i = 0; i < maxRetryAttempts; i++) {
            try {
                return await new PutLibraryContentV4(requestContentData).startWithSignal(this.signal)
            } catch (e) {
                if (this.signal.aborted || i === maxRetryAttempts - 1) {
                    throw e
                } else {
                    await delay(scalingDuration * i, { signal: this.signal })
                }
            }
        }
    }

    private splitContentListUpdate = async (
        libraryId: LibraryId,
        arg: Omit<Wukong.DocumentProto.IArg_publishLibraryComponents, 'libraryShared' | 'libraryId'>,
        pieceList: RemoteLibraryPiece[]
    ) => {
        let hasPublishedCount = 1
        this.updateProgressToast(hasPublishedCount)
        const uniqKey = generateUniqString()

        const result = {
            byteSize: 0,
            list: [] as RemoteLibraryPieceResult[],
        }

        // 部分发布失败不影响后续分片发布
        let uploadError: unknown = undefined
        const uploadResult = async () => {
            try {
                if (result.list.length) {
                    const resultLength = result.list.length
                    const requestContentData = this.getDefaultRequestContentData(
                        libraryId,
                        arg.description ?? '',
                        uniqKey
                    )
                    const fillVariableSetRequestContentData = ({
                        vo,
                        attrName,
                    }: {
                        vo: Wukong.DocumentProto.IVariableSetUpdateVO
                        attrName: 'publishedVariableCollections' | 'updatedVariableCollections'
                    }) => {
                        const { nodeDataV2, ...otherAttrs } = vo
                        requestContentData.manifest[attrName]!.push(otherAttrs)
                        requestContentData.requestContent.allData.push({
                            nodeId: otherAttrs.nodeId!,
                            type: UpdateLibraryContentType.ProtoData,
                            data: nodeDataV2!,
                        })
                    }
                    const fillVariableRequestContentData = ({
                        vo,
                        attrName,
                    }: {
                        vo: Wukong.DocumentProto.IVariableUpdateVO
                        attrName: 'publishedVariables' | 'updatedVariables'
                    }) => {
                        const { nodeDataV2, ...otherAttrs } = vo
                        requestContentData.manifest[attrName]!.push(otherAttrs)
                        requestContentData.requestContent.allData.push({
                            nodeId: otherAttrs.nodeId!,
                            type: UpdateLibraryContentType.ProtoData,
                            data: nodeDataV2!,
                        })
                    }
                    const fillRequestContentData = ({
                        vo,
                        attrName,
                    }:
                        | {
                              vo: Wukong.DocumentProto.IStyleUpdateVO
                              attrName: keyof Pick<UpdateLibraryMetaRequestV4, 'publishedStyles' | 'updatedStyles'>
                          }
                        | {
                              vo: Wukong.DocumentProto.IStyleUpdateVO
                              attrName: keyof Pick<
                                  UpdateLibraryMetaRequestV4,
                                  | 'publishedComponents'
                                  | 'updatedComponents'
                                  | 'publishedComponentSets'
                                  | 'updatedComponentSets'
                              >
                          }) => {
                        const { thumbnailData, nodeDataV2, ...otherAttrs } = vo
                        requestContentData.manifest[attrName]!.push(otherAttrs)
                        requestContentData.requestContent.allData.push({
                            nodeId: otherAttrs.nodeId!,
                            type: UpdateLibraryContentType.ProtoData,
                            data: nodeDataV2!,
                        })
                        if (
                            otherAttrs.type !== Wukong.DocumentProto.NodeType.NODE_TYPE_EFFECT_STYLE &&
                            otherAttrs.type !== Wukong.DocumentProto.NodeType.NODE_TYPE_LAYOUT_GRID_STYLE
                        ) {
                            requestContentData.requestContent.allData.push({
                                nodeId: otherAttrs.nodeId!,
                                type: UpdateLibraryContentType.Thumbnail,
                                data: thumbnailData!,
                            })
                        }
                    }

                    result.list.forEach((item) => {
                        const { publishType, voType } = item
                        if (publishType === PublishType.Unpublished) {
                            const attr = (() => {
                                switch (voType) {
                                    case 'style':
                                        return 'unpublishedStyles'
                                    case 'componentSet':
                                        return 'unpublishedComponentSetIds'
                                    case 'variableSet':
                                        return 'unpublishedVariableCollectionIds'
                                    case 'variable':
                                        return 'unpublishedVariableIds'
                                    default:
                                        return 'unpublishedComponentIds'
                                }
                            })()
                            requestContentData.manifest[attr]!.push(item.libraryNodeKey)
                            if (item.variantKeys.length) {
                                requestContentData.manifest.unpublishedComponentIds!.push(...item.variantKeys)
                            }
                        } else {
                            const getStyleAttr = () =>
                                publishType === PublishType.Published ? 'publishedStyles' : 'updatedStyles'
                            const getComponentAttr = () =>
                                publishType === PublishType.Published ? 'publishedComponents' : 'updatedComponents'
                            const getComponentSetAttr = () =>
                                publishType === PublishType.Published
                                    ? 'publishedComponentSets'
                                    : 'updatedComponentSets'
                            const getVariableSetAttr = () =>
                                publishType === PublishType.Published
                                    ? 'publishedVariableCollections'
                                    : 'updatedVariableCollections'
                            const getVariableAttr = () =>
                                publishType === PublishType.Published ? 'publishedVariables' : 'updatedVariables'
                            if (voType === 'style') {
                                fillRequestContentData({
                                    vo: item.vo,
                                    attrName: getStyleAttr(),
                                })
                                this.fillUpdateContentMoveRemappings(
                                    requestContentData.manifest,
                                    item.vo,
                                    arg.moveRemappings ?? {}
                                )
                            } else if (voType === 'component') {
                                fillRequestContentData({
                                    vo: item.vo,
                                    attrName: getComponentAttr(),
                                })
                                this.fillUpdateContentMoveRemappings(
                                    requestContentData.manifest,
                                    item.vo,
                                    arg.moveRemappings ?? {}
                                )
                            } else if (voType === 'componentSet') {
                                requestContentData.manifest.unpublishedComponentIds!.push(
                                    ...(item.unpublishVariantKeys ?? [])
                                )

                                item.variants.forEach((vo) => {
                                    fillRequestContentData({
                                        vo: vo,
                                        attrName: getComponentAttr(),
                                    })
                                    this.fillUpdateContentMoveRemappings(
                                        requestContentData.manifest,
                                        vo,
                                        arg.moveRemappings ?? {}
                                    )
                                })
                                fillRequestContentData({
                                    vo: item.vo,
                                    attrName: getComponentSetAttr(),
                                })
                                this.fillUpdateContentMoveRemappings(
                                    requestContentData.manifest,
                                    item.vo,
                                    arg.moveRemappings ?? {}
                                )
                            } else {
                                if (voType === 'variableSet') {
                                    fillVariableSetRequestContentData({
                                        vo: item.vo,
                                        attrName: getVariableSetAttr(),
                                    })
                                    this.fillUpdateContentMoveRemappings(
                                        requestContentData.manifest,
                                        item.vo,
                                        arg.moveRemappings ?? {}
                                    )
                                } else if (voType === 'variable') {
                                    fillVariableRequestContentData({
                                        vo: item.vo,
                                        attrName: getVariableAttr(),
                                    })
                                    this.fillUpdateContentMoveRemappings(
                                        requestContentData.manifest,
                                        item.vo,
                                        arg.moveRemappings ?? {}
                                    )
                                }
                            }
                        }
                    })
                    result.list = []
                    result.byteSize = 0
                    await this.putLibraryContentWithRetry(requestContentData)
                    hasPublishedCount += resultLength
                    this.updateProgressToast(Math.min((hasPublishedCount * 100.0) / pieceList.length, 100))
                }
            } catch (err) {
                uploadError = err
            }
        }

        const updateByteSize = (value: Wukong.DocumentProto.IStyleUpdateVO | Wukong.DocumentProto.IComponentUpdateVO) =>
            (result.byteSize += ((value.nodeDataV2?.length ?? 0) + (value.thumbnailData?.length ?? 0)) * 1.5) // decimal byte -> binary byte

        await Promise.all(
            pieceList.map((piece) =>
                this.buildUpdateVOConcurrentScheduler.add(async () => {
                    const convertRes = piece.convertFn()
                    const { voType, publishType } = piece
                    if (publishType === PublishType.Unpublished) {
                        if (convertRes.unpublishNodeKey) {
                            const value = convertRes.unpublishNodeKey
                            const variantKeys = convertRes.unpublishVariantKeys ?? []
                            result.list.push({
                                publishType: PublishType.Unpublished,
                                voType,
                                libraryNodeKey: value,
                                variantKeys,
                            })
                        }
                    } else {
                        if (voType === 'style' && convertRes.style) {
                            result.list.push({ publishType, voType, vo: convertRes.style })
                            updateByteSize(convertRes.style)
                        } else if (voType === 'component' && convertRes.component) {
                            result.list.push({ publishType, voType, vo: convertRes.component })
                            updateByteSize(convertRes.component)
                        } else if (voType === 'componentSet' && convertRes.component && convertRes.variants?.length) {
                            result.list.push({
                                publishType,
                                voType,
                                vo: convertRes.component,
                                variants: convertRes.variants,
                                unpublishVariantKeys: convertRes.unpublishVariantKeys,
                            })
                            updateByteSize(convertRes.component)
                            convertRes.variants.forEach(updateByteSize)
                        } else {
                            if (voType === 'variableSet' && convertRes.variableSet) {
                                result.list.push({ publishType, voType, vo: convertRes.variableSet })
                                updateByteSize(convertRes.variableSet)
                            } else if (voType === 'variable' && convertRes.variable) {
                                result.list.push({ publishType, voType, vo: convertRes.variable })
                                updateByteSize(convertRes.variable)
                            }
                        }
                    }

                    // 大于 30MB（in binary bytes）则分片
                    if (result.byteSize > MaxUploadByteSize) {
                        await uploadResult()
                    }
                })
            )
        )

        await uploadResult()

        if (uploadError) {
            this.updateProgressToast(-1)
            throw uploadError
        }
    }

    private getDefaultRequestContentData = (
        libraryId: LibraryId,
        description: string,
        uniqKey: string
    ): UpdateLibraryContentRequestV4 => ({
        requestContent: { allData: [] },
        manifest: {
            libraryId,
            description,
            uniqKey,
            moveRemappings: {},

            unpublishedComponentIds: [],
            publishedComponents: [],
            updatedComponents: [],

            publishedComponentSets: [],
            unpublishedComponentSetIds: [],
            updatedComponentSets: [],

            publishedStyles: [],
            unpublishedStyles: [],
            updatedStyles: [],

            publishedVariableCollections: [],
            unpublishedVariableCollectionIds: [],
            updatedVariableCollections: [],

            publishedVariables: [],
            unpublishedVariableIds: [],
            updatedVariables: [],
        },
    })

    private fillUpdateContentMoveRemappings = (
        manifest: UpdateLibraryMetaRequestV4,
        vo: Wukong.DocumentProto.IComponentUpdateVO,
        moveRemappings: Record<ComponentId | ComponentSetId | StyleVOId, ComponentId | ComponentSetId | StyleVOId>
    ) => {
        if (vo.nodeId && moveRemappings[vo.nodeId]) {
            manifest.moveRemappings[vo.nodeId] = moveRemappings[vo.nodeId]
        }
    }
}
