import { FeeUnitVO, PayPlanType } from '../../../../../kernel/interface/type'
import { PrivilegeCard, PrivilegeCardProps } from './privilege-card'
import { translation } from './privilege-card-professional.translation'
import { getPriceLabelFromExpense } from './utils'

export type PrivilegeCardProfessionalProps = Pick<
    PrivilegeCardProps,
    'className' | 'privilegesTitle' | 'privileges' | 'moreProps'
> & {
    payment: 'monthly' | 'annual'
    mainPriceAnnual: FeeUnitVO | undefined
    mainPriceMonthly: FeeUnitVO | undefined
    subPriceAnnual: FeeUnitVO | undefined
    subPriceMonthly: FeeUnitVO | undefined
    onSubmit: () => void
} & (
        | {
              currentPlanType: PayPlanType.professional
              isCurrentPlanTypeInTrial: boolean
          }
        | {
              currentPlanType: PayPlanType.starter
              isCurrentPlanTypeInTrial?: never
          }
    )

export function PrivilegeCardProfessional(props: PrivilegeCardProfessionalProps) {
    const {
        payment,
        mainPriceAnnual,
        mainPriceMonthly,
        subPriceAnnual,
        subPriceMonthly,
        onSubmit,
        currentPlanType,
        isCurrentPlanTypeInTrial,
        ...otherProps
    } = props

    return (
        <PrivilegeCard
            {...otherProps}
            name={translation('p')}
            tagProps={{
                name: translation('mp'),
                style: { color: 'var(--wk-lake-7)' },
                dataTestIds: { root: 'professional-tag' },
            }}
            price={{
                designer: {
                    money: getPriceLabelFromExpense(payment === 'annual' ? mainPriceAnnual : mainPriceMonthly),
                    per: translation('m'),
                },
                developer: {
                    money: getPriceLabelFromExpense(payment === 'annual' ? subPriceAnnual : subPriceMonthly),
                    per: translation('m'),
                },
            }}
            buttonProps={{
                type: 'lake7',
                children:
                    currentPlanType === PayPlanType.professional
                        ? isCurrentPlanTypeInTrial
                            ? translation('chooseTrial')
                            : translation('currentPlan')
                        : translation('up'),
                onClick: onSubmit,
                disabled: currentPlanType === PayPlanType.professional && !isCurrentPlanTypeInTrial,
            }}
            dataTestIds={{
                title: 'professional-title',
                price: 'professional-price',
                button: 'professional-button',
                privileges: 'professional-privileges',
                prefixPrivilege: 'professional-privilege',
            }}
        />
    )
}
