import {
    CallCloseLibraryModalAfterPublish,
    ChangeLibraryMainModalRoutePathCommand,
    CloseLibraryMainModal,
    CloseLibraryPublishModalCommand,
    OpenLibraryDetailPageCommand,
    OpenLibraryPublishModalWasmCall,
    OpenLibraryReplaceModalWasmCall,
    PublishMovedComponentNodesCommand,
    PublishSelectedNodesCommand,
    ToggleLibraryMainModal,
    Wukong,
} from '@wukong/bridge-proto'
import type { Position } from '../../../../../../ui-lib/src'
import { createSelectors, createStore } from '../../../../../../util/src'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import { Bridge } from '../../../../kernel/bridge/bridge'
import { LibraryId } from '../../../../kernel/interface/component-style-library-id'
import { DocID } from '../../../../kernel/interface/type'
import { ServiceClass } from '../../../../kernel/util/service-class'
import { ViewStateBridge } from '../../../../view-state-bridge'
import type { InnerDraftLibraryMoveToTeamRef } from '../draft-library-move-to-team/draft-library-move-to-team'

export enum LibraryListModalTab {
    Remote = 'remote', // 引用组件库 tab
    Local = 'local', // 本地组件库 tab
}

export enum OpenLibraryPublishModalSource {
    CurrentDocPublishButton, // 本地组件弹窗上的「去发布」按钮
    MoveNotification, // 右下角移动 Notification 上的「查看」按钮
    SelectionPublishButton, // 右侧属性面板上的去发布按钮
}

export interface LibraryModalRouterZustandStore {
    mainModal: {
        show: boolean
        activeTab?: LibraryListModalTab
        routePath?: Wukong.DocumentProto.LibraryMainModalRoutePathType
        extra?: {
            libraryId?: LibraryId
            documentId?: DocID
            docName?: string
        }
        position?: Position
    }
    publishModal: { show: false } | { show: true; openSource: OpenLibraryPublishModalSource }
}

// 管理组件库弹窗路由
export class LibraryModalRouterService extends ServiceClass {
    private zustandStore = createStore<LibraryModalRouterZustandStore>(() => ({
        mainModal: { show: false },
        publishModal: { show: false },
    }))
    useZustandStore = createSelectors(this.zustandStore)

    // 用于发布弹窗关闭后恢复主弹窗状态
    private mainModalStateBeforeOpenPublishModal?: LibraryModalRouterZustandStore['mainModal']
    // 用于恢复主弹窗位置
    private mainModalPosition?: Position

    private draftLibraryMoveToTeamRef: InnerDraftLibraryMoveToTeamRef | null = null

    constructor(
        protected override readonly bridge: Bridge,
        private readonly commandInvoker: CommandInvoker,
        protected override readonly viewStateBridge: ViewStateBridge
    ) {
        super(bridge, viewStateBridge)
        this.initBindJsCall()
    }

    public init = () => {
        this.initBindViewState()
        this.bindToWasmCall()
    }

    public override destroy(): void {
        super.destroy()
    }

    public onDraftLibraryMoveToTeamRefChange = (ref: InnerDraftLibraryMoveToTeamRef | null) => {
        this.draftLibraryMoveToTeamRef = ref
    }

    /** 关闭主弹窗、发布弹窗 */
    public closeLibraryAllModal = () => {
        this.zustandStore.setState({
            mainModal: { show: false },
            publishModal: { show: false },
        })
        this.mainModalStateBeforeOpenPublishModal = undefined
    }

    /** 关闭发布弹窗 */
    public closeLibraryPublishModal = () => {
        // 恢复打开发布弹窗前，主弹窗的显示状态
        this.restoreMainModalState()
        this.zustandStore.setState({
            publishModal: { show: false },
        })
    }

    /** 打开发布弹窗 */
    public openLibraryPublishModal = (openSource: OpenLibraryPublishModalSource, isDraft: boolean) => {
        if (isDraft) {
            if (!this.draftLibraryMoveToTeamRef) {
                return
            }
            // 存储打开发布弹窗前，主弹窗的显示状态
            this.saveMainModalStateIfShow()
            this.draftLibraryMoveToTeamRef.moveDoc(openSource)
        } else {
            // 存储打开发布弹窗前，主弹窗的显示状态
            this.saveMainModalStateIfShow()
            this.zustandStore.setState({
                publishModal: { show: true, openSource },
            })
        }
    }

    public afterDraftMoveModalClose = (moved: boolean, openSource: OpenLibraryPublishModalSource) => {
        if (moved) {
            // 文件移动到团队，则打开发布弹窗
            this.zustandStore.setState({
                publishModal: { show: true, openSource },
            })
        } else {
            // 恢复打开移动弹窗前，主弹窗的显示状态
            this.restoreMainModalState()
        }
    }

    private saveMainModalStateIfShow = () => {
        const mainModal = this.zustandStore.getState().mainModal
        if (mainModal.show) {
            this.mainModalStateBeforeOpenPublishModal = { ...mainModal, position: this.mainModalPosition }
            this.closeLibraryMainModal()
        }
    }

    private restoreMainModalState = () => {
        if (this.mainModalStateBeforeOpenPublishModal) {
            this.zustandStore.setState({
                mainModal: this.mainModalStateBeforeOpenPublishModal,
            })
            this.mainModalStateBeforeOpenPublishModal = undefined
        }
    }

    /** 关闭主弹窗 */
    public closeLibraryMainModal = () => {
        this.zustandStore.setState({
            mainModal: { show: false },
        })
    }

    /** 跳转至本地组件库 tab-首页 */
    public goToLocalLibraryHome = () => {
        this.zustandStore.setState({
            mainModal: { show: true, activeTab: LibraryListModalTab.Local, position: this.mainModalPosition },
        })
    }

    /** 跳转至引用组件库 tab-组件库详情 */
    public goToRemoteLibraryDetail = (libraryId: LibraryId, documentId: DocID) => {
        this.zustandStore.setState({
            mainModal: {
                show: true,
                activeTab: LibraryListModalTab.Remote,
                routePath:
                    Wukong.DocumentProto.LibraryMainModalRoutePathType
                        .LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REMOTE_LIBRARY_DETAIL,
                extra: { libraryId, documentId },
                position: this.mainModalPosition,
            },
        })
    }

    /** 跳转至引用组件库 tab-首页 */
    public goToRemoteLibraryHome = () => {
        this.zustandStore.setState({
            mainModal: {
                show: true,
                activeTab: LibraryListModalTab.Remote,
                routePath:
                    Wukong.DocumentProto.LibraryMainModalRoutePathType
                        .LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REMOTE_LIBRARY_TOTAL_LIST,
                position: this.mainModalPosition,
            },
        })
    }

    /** 跳转至引用组件库 tab-更新列表 */
    public goToRemoteLibraryChangeList = async () => {
        this.zustandStore.setState({
            mainModal: {
                show: true,
                activeTab: LibraryListModalTab.Remote,
                routePath:
                    Wukong.DocumentProto.LibraryMainModalRoutePathType
                        .LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REMOTE_LIBRARY_CHANGE_LIST,
                position: this.mainModalPosition,
            },
        })
    }

    /** 跳转至引用组件库 tab-缺失组件库列表 */
    public goToRemoteLibraryMissingList = async () => {
        this.zustandStore.setState({
            mainModal: {
                show: true,
                activeTab: LibraryListModalTab.Remote,
                routePath:
                    Wukong.DocumentProto.LibraryMainModalRoutePathType
                        .LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REMOTE_MISS_LIBRARY_DETAIL,
                position: this.mainModalPosition,
            },
        })
    }

    /** 跳转至引用组件库 tab-组件库替换面板 */
    public goToLibraryReplaceDetail = (
        documentId: DocID,
        docName: string | undefined,
        libraryId: LibraryId | undefined
    ) => {
        this.zustandStore.setState({
            mainModal: {
                show: true,
                activeTab: LibraryListModalTab.Remote,
                routePath:
                    Wukong.DocumentProto.LibraryMainModalRoutePathType
                        .LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REPLACE_LIBRARY_DETAIL,
                extra: { libraryId, documentId, docName },
                position: this.mainModalPosition,
            },
        })
    }

    /** 切换组件库主弹窗显示状态 */
    public toggleLibraryMainModal = () => {
        // 发布弹窗展示时，不响应任何操作
        if (this.zustandStore.getState().publishModal.show) {
            return
        }

        if (this.zustandStore.getState().mainModal.show) {
            return this.closeLibraryMainModal()
        }

        const libraryGlobalState = this.viewStateBridge.getDefaultValue('libraryGlobalState')
        if (libraryGlobalState?.showLocalLibraryTabBadge && !libraryGlobalState.showRemoteLibraryTabBadge) {
            return this.goToLocalLibraryHome()
        }

        return this.goToRemoteLibraryHome()
    }

    // 记录组件库主弹窗当前位置
    public updateMainModalPosition = (position: Position) => {
        this.mainModalPosition = position
    }

    private onEditorTypeChance = (state: Wukong.DocumentProto.EditorType) => {
        if (state === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV) {
            this.closeLibraryAllModal()
        }
    }

    private initBindViewState = () => {
        this.registerViewState('editorType', this.onEditorTypeChance)
    }

    private bindToWasmCall = () => {
        this.subscribeZustand(
            this.zustandStore,
            (store) => store.publishModal,
            (publishModal) => {
                if (!publishModal.show) {
                    return this.commandInvoker.DEPRECATED_invokeBridge(CloseLibraryPublishModalCommand)
                }
                if (publishModal.openSource === OpenLibraryPublishModalSource.SelectionPublishButton) {
                    return this.commandInvoker.DEPRECATED_invokeBridge(PublishSelectedNodesCommand)
                }
                if (publishModal.openSource === OpenLibraryPublishModalSource.CurrentDocPublishButton) {
                    return this.commandInvoker.DEPRECATED_invokeBridge(OpenLibraryPublishModalWasmCall)
                }
                if (publishModal.openSource === OpenLibraryPublishModalSource.MoveNotification) {
                    return this.commandInvoker.DEPRECATED_invokeBridge(PublishMovedComponentNodesCommand)
                }
            }
        )
        this.subscribeZustand(
            this.zustandStore,
            (store) => store.mainModal,
            (mainModal) => {
                let routePath: Wukong.DocumentProto.LibraryMainModalRoutePathType =
                    Wukong.DocumentProto.LibraryMainModalRoutePathType.LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_NONE

                if (mainModal.show) {
                    if (mainModal.activeTab === LibraryListModalTab.Local) {
                        routePath =
                            Wukong.DocumentProto.LibraryMainModalRoutePathType
                                .LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_CURRENT_LOCAL
                    } else if (mainModal.activeTab === LibraryListModalTab.Remote && mainModal.routePath) {
                        routePath = mainModal.routePath
                    }
                }

                this.commandInvoker.DEPRECATED_invokeBridge(ChangeLibraryMainModalRoutePathCommand, {
                    value: routePath,
                })

                if (mainModal.show) {
                    if (
                        mainModal.routePath ==
                        Wukong.DocumentProto.LibraryMainModalRoutePathType
                            .LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REMOTE_LIBRARY_DETAIL
                    ) {
                        this.commandInvoker.DEPRECATED_invokeBridge(OpenLibraryDetailPageCommand, {
                            libraryId: mainModal.extra?.libraryId,
                            documentId: mainModal.extra?.documentId,
                        })
                    } else if (
                        mainModal.routePath ==
                        Wukong.DocumentProto.LibraryMainModalRoutePathType
                            .LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REPLACE_LIBRARY_DETAIL
                    ) {
                        this.commandInvoker.DEPRECATED_invokeBridge(OpenLibraryReplaceModalWasmCall, {
                            libraryId: mainModal.extra?.libraryId,
                            documentId: mainModal.extra?.documentId,
                        })
                    }
                }
            }
        )
    }

    private initBindJsCall = () => {
        this.bindJsCall(ToggleLibraryMainModal, this.toggleLibraryMainModal)
        this.bindJsCall(CloseLibraryMainModal, this.closeLibraryMainModal)
        this.bindJsCall(CallCloseLibraryModalAfterPublish, () => {
            // 仅当发布弹窗展示时，才执行关闭所有弹窗操作
            if (this.zustandStore.getState().publishModal.show) {
                this.closeLibraryAllModal()
            }
        })
    }
}
