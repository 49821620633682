import { translation } from './config.translation'
/* eslint-disable no-restricted-imports */
import { WKTourStep } from '../../../../../ui-lib/src/components/wk-tour'
import { isEnglishLanguage } from '../../../../../util/src'
import { environment } from '../../../environment'
import { OnboardingScene } from '../../../kernel/interface/onboarding'
import { onboardingService } from '../../../main/service/onboarding-service'
import aiImage from '../../assets/onboarding/ai.webp'
import commentImage from '../../assets/onboarding/comment.webp'
import deviceEnImage from '../../assets/onboarding/device-en.webp'
import deviceImage from '../../assets/onboarding/device.webp'
import img1 from '../../assets/onboarding/img-1.jpg'
import img2 from '../../assets/onboarding/img-2.jpg'
import img4 from '../../assets/onboarding/img-4.jpg'
import img5 from '../../assets/onboarding/img-5.jpg'
import libraryEnImage from '../../assets/onboarding/library-en.webp'
import libraryImage from '../../assets/onboarding/library.webp'
import mpEnImage from '../../assets/onboarding/mp-en.webp'
import prototypeEnImage from '../../assets/onboarding/prototype-en.webp'
import prototypeImage from '../../assets/onboarding/prototype.webp'
import shareEnImage from '../../assets/onboarding/share-en.webp'

export const onboardingSteps: WKTourStep[] = [
    {
        targets: () => {
            const node = document.getElementById('wk-frame-dropdown')
            return [node] as HTMLElement[]
        },
        image: img1,
        video: `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/onboarding/01-frame.mp4`,
        title: translation('Frame'),
        description: (
            <>
                <p>{translation('FrameContent1')}</p>
                <p>{translation('FrameContent2')}</p>
            </>
        ),
        highlight: true,
        moreButton: {
            show: true,
            onClick: () => {
                onboardingService.packingFrog(OnboardingScene.Editor, 'more')
                window.open(environment.onboardingHelpDocUrl.frame)
            },
        },
    },
    {
        targets: () => {
            const node = document.getElementById('wk-pen-tool')
            return [node] as HTMLElement[]
        },
        image: img2,
        video: `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/onboarding/02-vector.mp4`,
        title: translation('VN'),
        description: (
            <>
                <p>{translation('VNContent1')}</p>
                <p>{translation('VNContent2')}</p>
            </>
        ),
        highlight: true,
        moreButton: {
            show: true,
            onClick: () => {
                onboardingService.packingFrog(OnboardingScene.Editor, 'more')
                window.open(environment.onboardingHelpDocUrl.vector)
            },
        },
    },
    {
        targets: () => {
            const nodes = document.getElementsByClassName('wk-comment-tool')
            return [...nodes] as HTMLElement[]
        },
        image: commentImage,
        video: isEnglishLanguage()
            ? `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/onboarding/en/comment.mp4`
            : `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/onboarding/03-comment.mp4`,
        title: translation('Comment'),
        description: (
            <>
                <p>{translation('CommentContent1')}</p>
                <p>{translation('CommentContent2')}</p>
            </>
        ),
        highlight: true,
        moreButton: {
            show: true,
            onClick: () => {
                onboardingService.packingFrog(OnboardingScene.Editor, 'more')
                window.open(environment.onboardingHelpDocUrl.comment)
            },
        },
    },
    {
        targets: () => {
            const node = document.getElementById('wk-ai-menu')
            return [node] as HTMLElement[]
        },
        image: aiImage,
        video: isEnglishLanguage()
            ? `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/onboarding/en/ai.mp4`
            : `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/onboarding/ai.mp4`,
        title: 'Motiff AI',
        description: (
            <>
                <p>{translation('AIContent1')}</p>
                <p>{translation('AIContent2')}</p>
            </>
        ),
        highlight: true,
    },
    {
        targets: () => [document.getElementById('share-btn')],
        image: isEnglishLanguage() ? shareEnImage : img4,
        video: isEnglishLanguage()
            ? `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/onboarding/en/share.mp4`
            : `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/onboarding/04-share.mp4`,
        title: translation('Share'),
        description: (
            <>
                <p>{translation('ShareContent1')}</p>
                <p>{translation('ShareContent2')}</p>
            </>
        ),
        highlight: true,
        moreButton: {
            show: true,
            onClick: () => {
                onboardingService.packingFrog(OnboardingScene.Editor, 'more')
                window.open(environment.onboardingHelpDocUrl.share)
            },
        },
    },
    {
        targets: () => [document.getElementById('wk-active-coactor')],
        image: isEnglishLanguage() ? mpEnImage : img5,
        video: isEnglishLanguage()
            ? `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/onboarding/en/coactor.mp4`
            : `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/onboarding/05-coactor.mp4`,
        title: translation('Multiplayer'),
        description: (
            <>
                <p>{translation('MultiplayerContent1')}</p>
                <p>{translation('MultiplayerContent2')}</p>
            </>
        ),
        highlight: true,
        moreButton: {
            show: true,
            onClick: () => {
                onboardingService.packingFrog(OnboardingScene.Editor, 'more')
                window.open(environment.onboardingHelpDocUrl.coator)
            },
        },
    },
    {
        targets: () => [document.getElementById('asset-container')],
        image: isEnglishLanguage() ? libraryEnImage : libraryImage,
        video: isEnglishLanguage()
            ? `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/onboarding/en/library.mp4`
            : `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/onboarding/06-component.mp4`,
        title: translation('TeamLibrary'),
        description: (
            <>
                <p>{translation('TeamLibraryContent1')}</p>
                <p>{translation('TeamLibraryContent2')}</p>
            </>
        ),
        highlight: true,
        moreButton: {
            show: true,
            onClick: () => {
                onboardingService.packingFrog(OnboardingScene.Editor, 'more')
                window.open(environment.onboardingHelpDocUrl.library)
            },
        },
    },
    {
        targets: () => [document.getElementById('wk-prototype-tab')],
        image: isEnglishLanguage() ? prototypeEnImage : prototypeImage,
        video: isEnglishLanguage()
            ? `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/onboarding/en/prototype.mp4`
            : `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/onboarding/prototype.mp4`,
        title: translation('Prototype'),
        description: (
            <>
                <p>{translation('PrototypeContent1')}</p>
            </>
        ),
        highlight: true,
        moreButton: {
            show: true,
            onClick: () => {
                onboardingService.packingFrog(OnboardingScene.Editor, 'more')
                window.open(environment.onboardingHelpDocUrl.prototype)
            },
        },
    },
    {
        targets: [],
        image: isEnglishLanguage() ? deviceEnImage : deviceImage,
        video: isEnglishLanguage()
            ? `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/onboarding/en/device.mp4`
            : `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/onboarding/08-preview.mp4`,
        title: translation('Device'),
        description: (
            <>
                <p>{translation('DeviceContent1')}</p>
                <p>{translation('DeviceContent2')}</p>
            </>
        ),
        moreButton: {
            show: true,
            onClick: () => {
                onboardingService.packingFrog(OnboardingScene.Editor, 'more')
                window.open(environment.onboardingHelpDocUrl.device)
            },
        },
    },
]
