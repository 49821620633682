import { FeatureSwitchConfig, SwitchEnvironmentScopeType, SwitchStrategyType } from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'wk-43991',
    owner: '',
    description: '',
    strategies: [
        { env: SwitchEnvironmentScopeType.TESTING, config: { type: SwitchStrategyType.ALL_USER } },
        {
            env: SwitchEnvironmentScopeType.PRODUCTION,
            config: { type: SwitchStrategyType.SPECIFIC_SUFFIX, suffix: ['@kanyun.com'] },
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
