import { GET, POST, PUT } from '@tutor/network-core'
import type {
    BatchUpdateSeatTypeRequest,
    CreateSeatApplicationRequest,
    ResolveSeatApplicationRequest,
    ResourceType,
    SeatApplicationListVO,
    SeatApplicationWithResourceBriefVO,
    UpdateSeatTypeRequest,
    UserBriefVO,
} from '../interface/type'
import { BaseCommonRequest } from './base-request'

// 申请席位时查询能审批的管理员列表
/**
 * @description
 * 1. 如果是企业，
 * 1.1 当前用户是访客，返回403  -> 只改这里，返回空数组
 * 1.2 当前用户不是访客，正常返回
 * 2 如果是团队
 * 2.1 当前用户在团队下没有席位，返回403
 * 2.2 当前用户在团队下有席位，正常返回
 */
@GET
export class QueryApplicationAuditors extends BaseCommonRequest<UserBriefVO[]> {
    constructor(private readonly resourceType: ResourceType, private readonly resourceId: string) {
        super()
    }

    public override requestArgument() {
        return {
            resourceType: this.resourceType,
            resourceId: this.resourceId,
        }
    }

    public override requestUrl() {
        return 'seats/application/auditor'
    }
}

// 查询待处理的席位申请列表
@GET
export class QuerySeatApplications extends BaseCommonRequest<SeatApplicationListVO> {
    constructor(private readonly resourceType: ResourceType, private readonly resourceId: string) {
        super()
    }

    public override requestArgument() {
        return {
            resourceType: this.resourceType,
            resourceId: this.resourceId,
        }
    }

    public override requestUrl() {
        return 'seats/application'
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 查询工作区席位申请列表信息
@GET
export class GetWorkspaceSeatApplications extends BaseCommonRequest<SeatApplicationListVO> {
    constructor(private readonly orgId: string, private readonly workspaceId: string) {
        super()
    }

    public override requestArgument() {
        return {
            orgId: this.orgId,
            workspaceId: this.workspaceId,
        }
    }

    public override requestUrl() {
        return 'seats/workspace/application'
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 申请席位或者直接获取席位，取决于当前用户在履约单位下的权限、用户在履约单位下是否有宽限期
@POST
export class CreateApplication extends BaseCommonRequest<void> {
    constructor(private readonly body: CreateSeatApplicationRequest) {
        super()
    }

    public override requestBody() {
        return this.body
    }

    public override requestUrl() {
        return 'seats/application'
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 查询单个用户申请席位信息的详情
@GET
export class QuerySeatApplicationDetail extends BaseCommonRequest<SeatApplicationWithResourceBriefVO> {
    constructor(private readonly applicationId: number) {
        super()
    }

    public override requestArgument() {
        return {
            applicationId: this.applicationId,
        }
    }

    public override requestUrl() {
        return 'seats/application/detail'
    }
}

// 通过或者拒绝席位申请
@POST
export class ResolveSeatApplication extends BaseCommonRequest<void> {
    constructor(private readonly body: ResolveSeatApplicationRequest) {
        super()
    }

    public override requestBody() {
        return this.body
    }

    public override requestUrl() {
        return 'seats/application/resolve'
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 修改企业或者团队用户的席位类型
@PUT
export class UpdateSeat extends BaseCommonRequest<void> {
    constructor(private readonly body: UpdateSeatTypeRequest) {
        super()
    }

    public override requestBody() {
        return this.body
    }

    public override requestUrl() {
        return 'seats'
    }
}

// 批量修改企业或者团队用户的席位类型
@PUT
export class BatchUpdateSeat extends BaseCommonRequest<void> {
    constructor(private readonly body: BatchUpdateSeatTypeRequest) {
        super()
    }

    public override requestBody() {
        return this.body
    }

    public override requestUrl() {
        return 'seats/v2'
    }
}
