import classnames from 'classnames'
import React, { forwardRef, useCallback, useMemo } from 'react'
import { Checkbox, Scrollbar, Tag, WKTypography, WKUserAvatar } from '../../../../../../ui-lib/src'
import { WKLoading } from '../../../../../../ui-lib/src/components/wk-loading/wk-loading'
import { UserBriefVO } from '../../../../kernel/interface/type'
import classes from './pay-apply-checkbox-group.module.less'
import { translation } from './pay-apply-checkbox-group.translation'

export interface PayApplyCheckboxGroupProps {
    usersInfoMap: Map<UserBriefVO['id'], { user: Readonly<UserBriefVO>; checked: boolean; guest?: boolean }>
    onChange: (v: PayApplyCheckboxGroupProps['usersInfoMap']) => void
    textWhenNotExistChecked: string | undefined
    loading?: boolean
    maxAutoHeight?: number
    emptyDescription?: string
}
export const PayApplyCheckboxGroup = forwardRef(function Component(
    props: PayApplyCheckboxGroupProps,
    ref: React.Ref<HTMLDivElement>
) {
    const { usersInfoMap, textWhenNotExistChecked, onChange, loading, maxAutoHeight = 206, emptyDescription } = props

    const checkboxAllState = useMemo(() => {
        const list = [...usersInfoMap.values()]
        const isAllChecked = !!list.length && list.every((v) => v.checked)
        const isIndeterminateChecked = !!list.length && !isAllChecked && list.some((v) => v.checked)
        const notExistChecked = !!list.length && list.every((v) => !v.checked)
        return { isAllChecked, isIndeterminateChecked, notExistChecked }
    }, [usersInfoMap])

    const onChangeCheckboxAll = useCallback(
        (checked: boolean) => {
            onChange(
                new Map([...usersInfoMap.entries()].map(([key, v]) => [key, { user: v.user, checked, guest: v.guest }]))
            )
        },
        [onChange, usersInfoMap]
    )

    const onChangeCheckboxOne = useCallback(
        (id: UserBriefVO['id'], checked: boolean) => {
            onChange(
                new Map(
                    [...usersInfoMap.entries()].map(([key, v]) => [
                        key,
                        { user: v.user, checked: id === key ? checked : v.checked, guest: v.guest },
                    ])
                )
            )
        },
        [onChange, usersInfoMap]
    )

    const isEmpty = useMemo(() => {
        return usersInfoMap.size === 0
    }, [usersInfoMap])

    return (
        <div className={classes.checkboxes}>
            <div className={classes.checkboxGroup}>
                <div className={classes.checkboxAll}>
                    <Checkbox
                        checked={checkboxAllState.isAllChecked}
                        indeterminate={checkboxAllState.isIndeterminateChecked}
                        onChange={onChangeCheckboxAll}
                        label={translation('selectAll')}
                        disabled={isEmpty}
                    />
                </div>
                <Scrollbar autoHeight autoHeightMin={60} autoHeightMax={maxAutoHeight}>
                    <div ref={ref}>
                        {loading ? (
                            <div className="py-10 flex justify-center items-center">
                                <WKLoading noText />
                            </div>
                        ) : isEmpty && emptyDescription ? (
                            <div className="px-10px py-40px flex justify-center" data-testid="member-empty-description">
                                <WKTypography.Text className="py-2px px-10px" color="placeholder">
                                    {emptyDescription}
                                </WKTypography.Text>
                            </div>
                        ) : (
                            <>
                                <div className={classes.height8}></div>
                                {[...usersInfoMap.entries()].map(([id, userInfo]) => (
                                    <div
                                        key={id}
                                        className={classes.checkboxOne}
                                        onClick={() => onChangeCheckboxOne(id, !userInfo.checked)}
                                        data-testid="checkbox-user-item"
                                    >
                                        <Checkbox
                                            checked={userInfo.checked}
                                            onChange={(v) => onChangeCheckboxOne(id, v)}
                                            onClick={(e) => e.stopPropagation()}
                                            className={classes.checkbox}
                                        />
                                        <WKUserAvatar className={classes.avatar} size={24} userInfo={userInfo.user} />
                                        <div className={classes.userInfo}>
                                            <span className={classes.name}>
                                                <span className="truncate">{userInfo.user.nickname}</span>
                                                {userInfo.guest && (
                                                    <Tag name={translation('Guest')} className="ml-1" size="mini" />
                                                )}
                                            </span>
                                            <span className={classnames(classes.email, 'truncate')}>
                                                {userInfo.user.email}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                                <div className={classes.height8}></div>
                            </>
                        )}
                    </div>
                </Scrollbar>
            </div>
            {textWhenNotExistChecked && checkboxAllState.notExistChecked ? (
                <div className={classes.notExistChecked}>{textWhenNotExistChecked}</div>
            ) : null}
        </div>
    )
})
