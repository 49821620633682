import { translation } from './teams-folders-state.translation'
/* eslint-disable no-restricted-imports */
import { isNumber, throttle } from 'lodash-es'
import { asyncConfirm, WKToast } from '../../../../../ui-lib/src'
import { domLocation, RouteToken } from '../../../../../util/src'
import { BusinessStatusCode } from '../../../kernel/interface/request-error-code'
import {
    FolderIdWithDocVO,
    FolderSetVO,
    FolderStatus,
    FolderWithAuthorityVO,
    OrganizationVO,
    ResourceType,
    TeamWithAuthorityVO,
    TeamWithFolderAndAuthorityVO,
} from '../../../kernel/interface/type'
import { WorkspaceWithTeamAndFolderVO } from '../../../kernel/interface/workspace'
import { RequestResponseErrorHandler } from '../../../kernel/request/error-handler'
import { AddFavoriteFolderRequest, RemoveFavoriteFolderRequest } from '../../../kernel/request/favorite'
import { ChangeFavoritesFolderOrder } from '../../../kernel/request/favorites-folder-preference'
import {
    CreateFolderRequest,
    DeleteFolderRequest,
    DeleteShareFolderRequest,
    GetShareFolderListV2,
    MoveFolderRequest,
    UpdateFolderRequest,
} from '../../../kernel/request/folder'
import * as TeamRequest from '../../../kernel/request/team'
import {
    CreateTeamRequest,
    GetTeamResourceState,
    GetTeamsFoldersEnterpriseRequest,
    GetTeamsFoldersRequest,
    UpdateTeamRequest,
} from '../../../kernel/request/team'
import { PutTeamPreferences } from '../../../kernel/request/team-preferences'
import { DeleteMemberByTeamId, TeamMemberBecameTeamOwner } from '../../../kernel/request/user-roles'
import { UpdateWorkspacePreferencesRequest } from '../../../kernel/request/workspace-preferences'
import { isStarterPlan } from '../../../utils/plan-status-checker'
import { createDocInFolder } from '../../util/create-doc'
import { SpaceNotifyFlow } from './notify-flow'
import { useSpaceStore } from './space-state'
import { getSpaceStore, setSpaceStore, SpaceStore, SpaceStoreType, TeamsFoldersStore } from './types'

/**
 *
 * 这个是工作台最重要的一个store 里面存储了团队列表 项目列表 共享项目
 * 格式为
 * {
 *   shareFolders: [{
 *      folderWithAuthorityVO: folder1,
 *      documentWithAuthorityVOList: [doc1, doc2, doc3],
 * }],
 *   teamsFolders: [
 *       {
 *           teamWithAuthorityVO: team1,
 *           folderWithAuthorityVOList: [folder1, foler2, folder3],
 *       },
 *       {
 *           teamWithAuthorityVO: team2,
 *           folderWithAuthorityVOList: [folder21, foler22, folder23],
 *       },
 *   ],
 * }
 * 因为要支持所有页面的数据联动，并且支持协同来的消息修改内容
 * 所以，工作台任何页面需要用到团队、项目信息都应该从这里面取
 * 修改团队、项目信息的操作也应该更新这里的数据
 *
 * 获取数据可以使用最下方定义的各种选择器
 * 比如想要通过团队id到团队信息的映射关系获取团队信息
 * const team = useTeamsFoldersState(teamId2TeamSelector).get(:teamId)
 *
 */

const findTeamIdx = (list: ReadonlyArray<TeamWithFolderAndAuthorityVO>, teamId: string) =>
    list.findIndex((o) => o.teamWithAuthorityVO.id == teamId)
const findFolderIdx = (list: FolderWithAuthorityVO[], folderId: string) => list.findIndex((f) => f.id == folderId)
const findTeamFolderIdx = (list: ReadonlyArray<TeamWithFolderAndAuthorityVO>, teamId: string, folderId: string) => {
    const teamIdx = findTeamIdx(list, teamId)
    if (teamIdx !== -1) {
        const folderIdx = findFolderIdx(list[teamIdx].folderWithAuthorityVOList, folderId)
        if (folderIdx !== -1) {
            return { teamIdx, folderIdx }
        }
    }
    return {}
}

const getTeamFolderWorkspaceData = async (
    organization: OrganizationVO,
    additionalRequestHeader?: Record<string, string>
) => {
    const { id: orgId, planType } = organization
    const isEnterprise = planType == 'enterprise'
    let teamsFolders: TeamWithFolderAndAuthorityVO[] = []
    let workspaces: Array<WorkspaceWithTeamAndFolderVO> = []
    if (isEnterprise) {
        const result = (await new GetTeamsFoldersEnterpriseRequest(orgId, additionalRequestHeader).start()) ?? []
        teamsFolders = result.map((item) => item.teamsWithFolderAndAuthorityVO).flat()
        workspaces = result
    } else {
        const result = (await new GetTeamsFoldersRequest(orgId, additionalRequestHeader).start()) ?? []
        teamsFolders = result.filter((o) => o.teamWithAuthorityVO.status == 'NORMAL')
    }
    // 过滤掉状态不正确的团队和项目
    teamsFolders = teamsFolders
        .filter((o) => o.teamWithAuthorityVO.status == 'NORMAL')
        .map((o) => ({
            teamWithAuthorityVO: o.teamWithAuthorityVO,
            folderWithAuthorityVOList: o.folderWithAuthorityVOList.filter((f) => f.status == FolderStatus.normal),
        }))
    return {
        teamsFolders,
        workspaces,
    }
}

export const generateTeamsFoldersState = (set: setSpaceStore, get: getSpaceStore): TeamsFoldersStore => {
    return {
        teamsFolders: [],
        teamResourceStateMap: new Map(),
        shareFolders: [],
        workspaces: [],
        loading: true,
        _refreshData: throttle(async (additionalRequestHeader?: Record<string, string>) => {
            const org = useSpaceStore.getState().organizationStore.organization
            const { teamsFolders, workspaces } = await getTeamFolderWorkspaceData(org, additionalRequestHeader)
            set((state) => {
                state.teamsFoldersStore.teamsFolders = teamsFolders
                state.teamsFoldersStore.workspaces = workspaces
            })
        }, 500),
        _changeTeamOrder: (orders) => {
            set((state) => {
                state.teamsFoldersStore.teamsFolders = state.teamsFoldersStore.teamsFolders.sort((a, b) => {
                    const idxA = orders.indexOf(a.teamWithAuthorityVO.id)
                    const idxB = orders.indexOf(b.teamWithAuthorityVO.id)
                    return idxA - idxB
                })
            })
            get().teamsFoldersStore._refreshData()
        },
        _changeFolderFavorite: ({ id, type }) => {
            const isEnterprise = get().organizationStore.organization.planType == 'enterprise'
            if (isEnterprise) {
                // 如果是高级企业版，直接从服务端拉新数据
                get().teamsFoldersStore._refreshData()
                return
            }
            const teamFolders = get()
                .teamsFoldersStore.teamsFolders.map((o) => o.folderWithAuthorityVOList)
                .flat()
            const teamFolder = teamFolders.find((f) => f.id == id)
            if (teamFolder) {
                const teamId = teamFolder.teamId
                const { teamIdx, folderIdx } = findTeamFolderIdx(get().teamsFoldersStore.teamsFolders, teamId, id)
                if (isNumber(teamIdx) && isNumber(folderIdx)) {
                    set((state) => {
                        state.teamsFoldersStore.teamsFolders[teamIdx].folderWithAuthorityVOList[folderIdx].favorites =
                            type == 'add'
                    })
                }
            }
            const shardFolders = get().teamsFoldersStore.shareFolders
            const shardFolderIdx = shardFolders.findIndex((f) => f.folderWithAuthorityVO.id == id)
            if (shardFolderIdx !== -1) {
                set((state) => {
                    state.teamsFoldersStore.shareFolders[shardFolderIdx].folderWithAuthorityVO.favorites = type == 'add'
                })
            }
        },
        fetch: async () => {
            set((state) => {
                state.teamsFoldersStore.loading = true
            })
            const org = useSpaceStore.getState().organizationStore.organization
            const { teamsFolders, workspaces } = await getTeamFolderWorkspaceData(org)
            await get().teamsFoldersStore.fetchShareFolders()
            set((state) => {
                state.teamsFoldersStore.teamsFolders = teamsFolders
                state.teamsFoldersStore.workspaces = workspaces
            })
            set((state) => {
                state.teamsFoldersStore.loading = false
            })
        },
        becomeTeamOwner: async (teamId, userId) => {
            await new TeamMemberBecameTeamOwner(teamId, {
                toUserId: userId ?? useSpaceStore.getState().organizationStore.user.id,
                resourceType: ResourceType.Team,
                resourceId: teamId,
            }).start()
            get().teamsFoldersStore._refreshData()
            !userId && WKToast.show(translation('YouAreNow'))
        },
        fetchShareFolders: (additionalRequestHeader) =>
            new GetShareFolderListV2(
                useSpaceStore.getState().organizationStore.organization.id,
                additionalRequestHeader
            )
                .start()
                .then((shareFolders) =>
                    set((state) => {
                        state.teamsFoldersStore.shareFolders = shareFolders
                    })
                ),
        moveAndSort: async (folderId, sourceTeamId, targetTeamId, targetIndex) => {
            const { teamIdx: sourceTeamIdx, folderIdx } = findTeamFolderIdx(
                get().teamsFoldersStore.teamsFolders,
                sourceTeamId,
                folderId
            )
            const targetTeamIdx = findTeamIdx(get().teamsFoldersStore.teamsFolders, targetTeamId)
            if (targetTeamIdx !== -1 && isNumber(folderIdx) && isNumber(sourceTeamIdx)) {
                const targetFolderList = get().teamsFoldersStore.teamsFolders[targetTeamIdx].folderWithAuthorityVOList
                const sourceFolderList = get().teamsFoldersStore.teamsFolders[sourceTeamIdx].folderWithAuthorityVOList
                const folder = get().teamsFoldersStore.teamsFolders[sourceTeamIdx].folderWithAuthorityVOList[folderIdx]
                const orders = targetFolderList.filter((f) => f.favorites).map((o) => o.id)
                orders.splice(targetIndex, 0, folderId)
                await new MoveFolderRequest(folderId, targetTeamId).start()
                await new ChangeFavoritesFolderOrder(targetTeamId, orders).start()
                set((state) => {
                    // 把项目从源团队项目列表移除
                    state.teamsFoldersStore.teamsFolders[sourceTeamIdx].folderWithAuthorityVOList =
                        sourceFolderList.filter((f) => f.id !== folderId)
                    // 把项目添加到目标团队中
                    state.teamsFoldersStore.teamsFolders[targetTeamIdx].folderWithAuthorityVOList = [
                        ...targetFolderList,
                        { ...folder, teamId: targetTeamId },
                    ].sort((a, b) => {
                        const idxA = orders.indexOf(a.id)
                        const idxB = orders.indexOf(b.id)
                        return idxA - idxB
                    })
                })
            }
        },
        changeFolderListOrder: async (teamId, orders) => {
            const teamIdx = findTeamIdx(get().teamsFoldersStore.teamsFolders, teamId)
            if (teamIdx !== -1) {
                await new ChangeFavoritesFolderOrder(teamId, orders).start()
                set((state) => {
                    state.teamsFoldersStore.teamsFolders[teamIdx].folderWithAuthorityVOList =
                        state.teamsFoldersStore.teamsFolders[teamIdx].folderWithAuthorityVOList.sort((a, b) => {
                            const idxA = orders.indexOf(a.id)
                            const idxB = orders.indexOf(b.id)
                            return idxA - idxB
                        })
                })
            }
        },
        _removeTeam: async (teamId) => {
            set((state) => {
                state.teamsFoldersStore.teamsFolders = state.teamsFoldersStore.teamsFolders.filter(
                    (o) => o.teamWithAuthorityVO.id !== teamId
                )
            })
        },
        removeSharedFolder: (folderId) =>
            set((state) => {
                state.teamsFoldersStore.shareFolders = state.teamsFoldersStore.shareFolders.filter(
                    (f) => f.folderWithAuthorityVO.id !== folderId
                )
            }),
        updateFolder: async (folderId: string, params: FolderSetVO) => {
            if (get().organizationStore.organization.planType === 'enterprise') {
                // 更新项目名称时，需要先获取旧名称，如果更新失败，则恢复旧名称
                const oldName = get()
                    .teamsFoldersStore.teamsFolders.find((o) =>
                        o.folderWithAuthorityVOList.find((f) => f.id === folderId)
                    )
                    ?.folderWithAuthorityVOList.find((f) => f.id === folderId)?.name
                // 根据 folderId反向找到 folderIndex teamIndex 和 workspaceIndex
                const workspaceIndex = get().teamsFoldersStore.workspaces.findIndex((o) =>
                    o.teamsWithFolderAndAuthorityVO.find((t) =>
                        t.folderWithAuthorityVOList.find((f) => f.id === folderId)
                    )
                )
                if (workspaceIndex === -1) {
                    // 如果找不到 workspace，则有可能是在共享项目中
                    const folderIdx = get().teamsFoldersStore.shareFolders.findIndex(
                        (o) => o.folderWithAuthorityVO.id === folderId
                    )
                    if (folderIdx !== -1) {
                        set((state) => {
                            state.teamsFoldersStore.shareFolders[folderIdx].folderWithAuthorityVO.name = params.name
                        })
                    }
                    try {
                        await new UpdateFolderRequest(folderId, params).start()
                    } catch (e) {
                        set((state) => {
                            state.teamsFoldersStore.shareFolders[folderIdx].folderWithAuthorityVO.name = oldName ?? ''
                        })
                    }
                    return
                }
                const teamIndex = get().teamsFoldersStore.workspaces[
                    workspaceIndex
                ].teamsWithFolderAndAuthorityVO.findIndex((t) =>
                    t.folderWithAuthorityVOList.find((f) => f.id === folderId)
                )
                if (teamIndex === -1) {
                    return
                }
                const folderIndex = get().teamsFoldersStore.workspaces[workspaceIndex].teamsWithFolderAndAuthorityVO[
                    teamIndex
                ].folderWithAuthorityVOList.findIndex((f) => f.id === folderId)
                if (folderIndex === -1) {
                    return
                }
                set((state) => {
                    state.teamsFoldersStore.workspaces[workspaceIndex].teamsWithFolderAndAuthorityVO[
                        teamIndex
                    ].folderWithAuthorityVOList[folderIndex].name = params.name
                })
                try {
                    await new UpdateFolderRequest(folderId, params).start()
                } catch (e) {
                    set((state) => {
                        state.teamsFoldersStore.workspaces[workspaceIndex].teamsWithFolderAndAuthorityVO[
                            teamIndex
                        ].folderWithAuthorityVOList[folderIndex].name = oldName ?? ''
                    })
                }
            } else {
                // 更新项目名称时，需要先获取旧名称，如果更新失败，则恢复旧名称
                // 先尝试在 共享项目中找 找不到就去teamsfolders中找
                const sharedFolderIdx = get().teamsFoldersStore.shareFolders.findIndex(
                    (o) => o.folderWithAuthorityVO.id === folderId
                )
                if (sharedFolderIdx !== -1) {
                    const oldName = get().teamsFoldersStore.shareFolders[sharedFolderIdx].folderWithAuthorityVO.name
                    set((state) => {
                        state.teamsFoldersStore.shareFolders[sharedFolderIdx].folderWithAuthorityVO.name = params.name
                    })
                    try {
                        await new UpdateFolderRequest(folderId, params).start()
                    } catch (e) {
                        set((state) => {
                            state.teamsFoldersStore.shareFolders[sharedFolderIdx].folderWithAuthorityVO.name =
                                oldName ?? ''
                        })
                    }
                    return
                }
                const oldName = get()
                    .teamsFoldersStore.teamsFolders.find((o) =>
                        o.folderWithAuthorityVOList.find((f) => f.id === folderId)
                    )
                    ?.folderWithAuthorityVOList.find((f) => f.id === folderId)?.name
                set((state) => {
                    const folder = state.teamsFoldersStore.teamsFolders
                        .find((o) => o.folderWithAuthorityVOList.find((f) => f.id === folderId))
                        ?.folderWithAuthorityVOList.find((f) => f.id === folderId)
                    if (folder) {
                        folder.name = params.name
                    }
                })
                try {
                    await new UpdateFolderRequest(folderId, params).start()
                } catch (e) {
                    set((state) => {
                        const folder = state.teamsFoldersStore.teamsFolders
                            .find((o) => o.folderWithAuthorityVOList.find((f) => f.id === folderId))
                            ?.folderWithAuthorityVOList.find((f) => f.id === folderId)
                        if (folder) {
                            folder.name = oldName ?? ''
                        }
                    })
                }
            }
        },
        createFolder: async (teamId, name) => {
            const newFolder = await new CreateFolderRequest({
                name,
                description: '',
                teamId,
            }).start()
            await get().teamsFoldersStore._refreshData()
            return newFolder
        },
        deleteFolder: async (folderId, teamId) => {
            // 共享项目也支持删除了
            const sharedFolders = get().teamsFoldersStore.shareFolders
            const sharedFolderIdx = sharedFolders.findIndex((o) => o.folderWithAuthorityVO.id === folderId)
            if (sharedFolderIdx !== -1) {
                const folderName = get().teamsFoldersStore.shareFolders[sharedFolderIdx].folderWithAuthorityVO.name
                try {
                    await asyncConfirm({
                        title: `${translation('AreYouSure', { folderName })}`,
                        content: `${translation('ThisProjectAnd')}`,
                        okButtonProps: { danger: true },
                        okText: translation('DeleteProject'),
                    })
                } catch (e) {
                    return false
                }
                await new DeleteShareFolderRequest(folderId).start()
                set((state) => {
                    state.teamsFoldersStore.shareFolders = state.teamsFoldersStore.shareFolders.filter(
                        (f) => f.folderWithAuthorityVO.id !== folderId
                    )
                })
            } else {
                const { teamIdx, folderIdx } = findTeamFolderIdx(get().teamsFoldersStore.teamsFolders, teamId, folderId)
                if (isNumber(teamIdx) && isNumber(folderIdx)) {
                    const folderName =
                        get().teamsFoldersStore.teamsFolders[teamIdx].folderWithAuthorityVOList[folderIdx].name
                    try {
                        await asyncConfirm({
                            title: `${translation('AreYouSure', { folderName })}`,
                            content: `${translation('ThisProjectAnd')}`,
                            okButtonProps: { danger: true },
                            okText: translation('DeleteProject'),
                        })
                    } catch (e) {
                        return false
                    }
                    new DeleteFolderRequest(folderId).start()
                }
            }
            return true
        },
        favoriteFolder: async (folderId, isFavorite) => {
            if (isFavorite) {
                await new RemoveFavoriteFolderRequest(folderId).start()
                WKToast.show(translation('RemoveFromSidebar'))
            } else {
                await new AddFavoriteFolderRequest(folderId).start()
                WKToast.show(translation('AddToSidebar'))
            }
        },
        changeTeamOrder: async (orders) => {
            await new PutTeamPreferences({
                orgId: useSpaceStore.getState().organizationStore.organization.id,
                teamIds: orders,
            }).start()
            get().teamsFoldersStore._changeTeamOrder(orders)
        },
        leaveTeam: async (teamId) => {
            await new DeleteMemberByTeamId(teamId).start()
            set((state) => {
                state.teamsFoldersStore.teamsFolders = state.teamsFoldersStore.teamsFolders.filter(
                    (o) => o.teamWithAuthorityVO.id !== teamId
                )
            })
        },
        createTeam: async (name, publicStatus, workspaceId) => {
            const orgId = useSpaceStore.getState().organizationStore.organization.id
            // 在企业中新建团队 需要设置团队公开访问配置，在个人组织中设置为 none
            const teamWithAuthorityVO = await new CreateTeamRequest({
                name,
                orgId,
                description: '',
                publicStatus,
                icon: '',
                workspaceId,
            }).start()
            await get().teamsFoldersStore._refreshData()
            return teamWithAuthorityVO
        },
        deleteTeam: async (teamId) => {
            try {
                await new TeamRequest.DeleteTeamV2(teamId).start()
            } catch (e) {
                const error = RequestResponseErrorHandler(e)

                if (error.status == 400) {
                    if (
                        [
                            BusinessStatusCode.TeamContractHasNotBeenCanceled,
                            BusinessStatusCode.TeamContractHasNotBeenPaid,
                        ].includes(error.businessStatus)
                    ) {
                        WKToast.show(translation('DeleteTeamFailed'))
                    } else if (error.businessStatus == 44009) {
                        WKToast.show(translation('NetworkError'))
                    }
                }

                return
            }
            set((state) => {
                state.teamsFoldersStore.teamsFolders = state.teamsFoldersStore.teamsFolders.filter(
                    (o) => o.teamWithAuthorityVO.id !== teamId
                )
            })
        },
        updateTeamName: async (teamId, name) => {
            if (get().organizationStore.organization.planType == 'enterprise') {
                // 通过 teamId 找到 workspace 的 index
                const workspaceIndex = get().teamsFoldersStore.workspaces.findIndex((o) =>
                    o.teamsWithFolderAndAuthorityVO.find((t) => t.teamWithAuthorityVO.id == teamId)
                )
                if (workspaceIndex === -1) {
                    // 如果找不到 workspace，则有可能是工作区管理员在企业管理中修改了名字，这时候直接刷新即可
                    await new UpdateTeamRequest(teamId, { name }).start()
                    return
                }
                // 然后在这个 workspace 中找到 team 的 index
                const teamIndex = get().teamsFoldersStore.workspaces[
                    workspaceIndex
                ].teamsWithFolderAndAuthorityVO.findIndex((t) => t.teamWithAuthorityVO.id == teamId)
                if (teamIndex === -1) {
                    return
                }
                set((state) => {
                    state.teamsFoldersStore.workspaces[workspaceIndex].teamsWithFolderAndAuthorityVO[
                        teamIndex
                    ].teamWithAuthorityVO.name = name
                })
                await new UpdateTeamRequest(teamId, { name }).start()
            } else {
                const idx = get().teamsFoldersStore.teamsFolders.findIndex((o) => o.teamWithAuthorityVO.id == teamId)
                set((state) => {
                    state.teamsFoldersStore.teamsFolders[idx].teamWithAuthorityVO.name = name
                })
                await new UpdateTeamRequest(teamId, { name }).start()
            }

            WKToast.show(translation('TeamHasBeen'))
        },
        updateTeamIcon: async (teamId, icon) => {
            const idx = get().teamsFoldersStore.teamsFolders.findIndex((o) => o.teamWithAuthorityVO.id == teamId)
            await new UpdateTeamRequest(teamId, { icon }).start()
            set((state) => {
                state.teamsFoldersStore.teamsFolders[idx].teamWithAuthorityVO.icon = icon
            })
            WKToast.show(translation('TeamIconUpdated'))
        },
        createProjectDoc: async (folderId) => {
            const orgId = get().organizationStore.organization.id
            return createDocInFolder(folderId, orgId)
        },
        changeWorkspaceOrder: async (targetId, currentId, direction) => {
            // currentId 表示当前文档的id
            // targetId 表示目标文档的id
            // direction 表示目标文档在当前文档的上方还是下方
            const workspaces = get().teamsFoldersStore.workspaces
            const currentWorkspace = workspaces.find((o) => o.workspaceWithAuthorityVO?.workspaceId === currentId)
            if (!currentWorkspace) {
                return
            }
            const workspaceWithoutCurrent = workspaces.filter(
                (o) => o.workspaceWithAuthorityVO?.workspaceId !== currentId
            )
            const targetWorkspaceIdx = workspaceWithoutCurrent.findIndex(
                (o) => o.workspaceWithAuthorityVO?.workspaceId === targetId
            )
            if (targetWorkspaceIdx === -1) {
                return
            }
            const newWorkspaces = [...workspaceWithoutCurrent]
            if (direction === 'up') {
                newWorkspaces.splice(targetWorkspaceIdx, 0, currentWorkspace)
            } else {
                newWorkspaces.splice(targetWorkspaceIdx + 1, 0, currentWorkspace)
            }
            set((state) => {
                state.teamsFoldersStore.workspaces = newWorkspaces
            })
            new UpdateWorkspacePreferencesRequest(
                get().organizationStore.organization.id,
                newWorkspaces.map((o) => o.workspaceWithAuthorityVO?.workspaceId ?? '')
            ).start()
        },
        initTeamResourceState: async (teamId) => {
            // 只有其他团队（非企业）才能查团队资源使用情况：GetTeamResourceState
            if (get().organizationStore.organization.id !== '-1') {
                return
            }
            // 只有免费团队才有查询的必要
            const planAndUserState = get().planAndUserStateStore.getTeamPlan(teamId, '-1')
            const isFreeTeam = planAndUserState && isStarterPlan(planAndUserState)
            if (!isFreeTeam) {
                return
            }
            const teamResourceState = await new GetTeamResourceState(teamId).start()
            const teamResourceStateMap = get().teamsFoldersStore.teamResourceStateMap
            set((state) => {
                const nextMap = new Map(teamResourceStateMap)
                nextMap.set(teamId, teamResourceState)
                state.teamsFoldersStore.teamResourceStateMap = nextMap
            })
        },
        updateTeamResourceState: async (teamId) => {
            if (get().teamsFoldersStore.teamResourceStateMap.has(teamId)) {
                get().teamsFoldersStore.initTeamResourceState(teamId)
            }
        },
        getTeamResourceState: (teamId) => {
            const teamResourceStateMap = get().teamsFoldersStore.teamResourceStateMap
            return teamResourceStateMap.get(teamId)
        },
    }
}

// 选择器
export const teamId2FavoriteFolderSelector = (state: SpaceStore) => {
    const teamsFolders = state.teamsFoldersStore.teamsFolders
    const map: Record<string, FolderWithAuthorityVO[]> = {}
    if (teamsFolders) {
        teamsFolders.forEach((o) => {
            map[o.teamWithAuthorityVO.id] = o.folderWithAuthorityVOList.filter((f) => f.favorites)
        })
    }
    return map
}

export const teamId2FolderSelector = (state: SpaceStore) => {
    const teamsFolders = state.teamsFoldersStore.teamsFolders
    const map: Record<string, FolderWithAuthorityVO[]> = {}
    if (teamsFolders) {
        teamsFolders.forEach((o) => {
            map[o.teamWithAuthorityVO.id] = o.folderWithAuthorityVOList
        })
    }
    return map
}
export const teamId2TeamSelector = (state: SpaceStore) => {
    const teamsFolders = state.teamsFoldersStore.teamsFolders
    const map = new Map<string, TeamWithAuthorityVO>()
    if (teamsFolders) {
        teamsFolders.forEach((o) => map.set(o.teamWithAuthorityVO.id, o.teamWithAuthorityVO))
    }
    return map
}
export const teamFoldersSelector = (state: SpaceStore) => {
    return state.teamsFoldersStore.teamsFolders.map((o) => o.folderWithAuthorityVOList).flat()
}
export const sharedFolderSelector = (state: SpaceStore) => {
    return state.teamsFoldersStore.shareFolders.map((o) => o.folderWithAuthorityVO)
}
export const sharedFolderId2DocListSelector = (state: SpaceStore) => {
    const list: FolderIdWithDocVO[] = []
    state.teamsFoldersStore.shareFolders.forEach((o) => {
        list.push({
            folderId: o.folderWithAuthorityVO.id,
            folderUpdateTime: o.folderUpdateTime,
            documentWithAuthorityVOList: o.documentWithAuthorityVOList,
        })
    })
    return list
}
export const allFoldersSelector = (state: SpaceStore) => {
    return [
        ...state.teamsFoldersStore.teamsFolders.map((o) => o.folderWithAuthorityVOList).flat(),
        ...state.teamsFoldersStore.shareFolders.map((f) => f.folderWithAuthorityVO),
    ]
}

export const syncTeamsFoldersState = (store: SpaceStoreType, notifyFlow: SpaceNotifyFlow) => {
    notifyFlow.addPropertyChangeCallbackWithoutDocAndUser(() =>
        store.getState().teamsFoldersStore._refreshData({
            'X-QoS-Level': 'notify',
        })
    )
    notifyFlow.addTeamOrderChangeCallback(store.getState().teamsFoldersStore._changeTeamOrder)
    notifyFlow.addTeamRelationChangeCallback(() =>
        store.getState().teamsFoldersStore._refreshData({
            'X-QoS-Level': 'notify',
        })
    )
    notifyFlow.addFolderRelationChangeCallback(() =>
        store.getState().teamsFoldersStore._refreshData({
            'X-QoS-Level': 'notify',
        })
    )
    notifyFlow.addWorkspaceRelationChangeCallback(() =>
        store.getState().teamsFoldersStore._refreshData({
            'X-QoS-Level': 'notify',
        })
    )
    notifyFlow.addFolderOrderChangeCallback(() =>
        store.getState().teamsFoldersStore._refreshData({
            'X-QoS-Level': 'notify',
        })
    )
    notifyFlow.addFavoriteFolderChangeCallback(({ id, type }) =>
        store.getState().teamsFoldersStore._changeFolderFavorite({ id, type })
    )
    notifyFlow.addUserOrganizationRoleChangeCallback(() =>
        store.getState().teamsFoldersStore._refreshData({
            'X-QoS-Level': 'notify',
        })
    )

    notifyFlow.addDocRelationChangeCallback((msg) => {
        if (domLocation().pathname.includes(RouteToken.Shared)) {
            if (
                store
                    .getState()
                    .teamsFoldersStore.shareFolders.map((o) => o.folderWithAuthorityVO.id)
                    .includes(msg.folderId)
            ) {
                store.getState().teamsFoldersStore.fetchShareFolders({ 'X-QoS-Level': 'notify' })
            }
        }
    })
    notifyFlow.addDocNameChangeCallback(() => {
        if (domLocation().pathname.includes(RouteToken.Shared)) {
            store.getState().teamsFoldersStore.fetchShareFolders({ 'X-QoS-Level': 'notify' })
        }
    })
    notifyFlow.addDocCountChangeCallback((data) => {
        store.getState().teamsFoldersStore.updateTeamResourceState(data.teamId)
    })
    // 轮训
    notifyFlow.addSpaceIntervalCallback(() =>
        store.getState().teamsFoldersStore._refreshData({
            'X-QoS-Level': 'scheduled',
        })
    )
}
