import { useCallback, useEffect, useState } from 'react'

export function useSelected<T>(configs: T[]) {
    const [selected, setSelected] = useState(new Set<T>([]))

    const select = (config: T) =>
        setSelected((s) => {
            s.add(config)
            return new Set(s)
        })

    const replace = (paramConfigs: T[]) => setSelected((_) => new Set(paramConfigs))

    const unSelect = (config: T) =>
        setSelected((s) => {
            s.delete(config)
            return new Set(s)
        })

    const selectAllOrNone = useCallback(
        (allOrNone: 'all' | 'none') => {
            if (allOrNone === 'all') {
                setSelected(new Set(configs))
            } else {
                setSelected(new Set([]))
            }
        },
        [configs]
    )

    const cleanChecked = useCallback(() => {
        setSelected(new Set([]))
    }, [])

    return {
        quanlity: configs.length,
        count: selected.size,
        allChecked: selected.size === configs.length && configs.length !== 0,
        replace,
        select,
        cleanChecked,
        selected,
        unSelect,
        indeterminate: selected.size !== 0 && selected.size !== configs.length,
        selectAllOrNone,
    } as const
}

// 持久化 useSelected
// 如果更新后 key 对应的项还存在，则该项保留上一次的选中结果，默认全选
export function useStableSelected<T>(items: T[], getKey: (item: T) => string) {
    const [selected, setSelected] = useState(new Map<string, T>())
    const [unselected, setUnselected] = useState(new Map<string, T>())

    useEffect(() => {
        const newSelected = new Map<string, T>()
        const newUnselected = new Map<string, T>()
        items.forEach((item) => {
            const key = getKey(item)

            if (selected.has(key)) {
                newSelected.set(key, item)
            } else if (unselected.has(key)) {
                newUnselected.set(key, item)
            } else {
                // 新增的默认选中
                newSelected.set(key, item)
            }
        })

        setSelected(newSelected)
        setUnselected(newUnselected)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items])

    const replace = (paramConfigs: T[]) => {
        setSelected((_) => new Map(paramConfigs.map((item) => [getKey(item), item])))
        setUnselected((_) => new Map())
    }

    const cleanChecked = () => {
        setSelected(new Map())
        setUnselected(new Map())
    }

    const select = (item: T) => {
        setSelected((s) => {
            s.set(getKey(item), item)
            return new Map(s)
        })
        setUnselected((s) => {
            s.delete(getKey(item))
            return new Map(s)
        })
    }

    const unSelect = (item: T) => {
        setUnselected((s) => {
            s.set(getKey(item), item)
            return new Map(s)
        })
        setSelected((s) => {
            s.delete(getKey(item))
            return new Map(s)
        })
    }

    const isSelected = (item: T) => selected.has(getKey(item))

    const selectAllOrNone = useCallback(
        (allOrNone: 'all' | 'none') => {
            if (allOrNone === 'all') {
                setSelected(new Map(items.map((item) => [getKey(item), item])))
                setUnselected(new Map())
            } else {
                setUnselected(new Map(items.map((item) => [getKey(item), item])))
                setSelected(new Map())
            }
        },
        [getKey, items]
    )

    return {
        quanlity: items.length,
        count: selected.size,
        allChecked: selected.size === items.length && items.length !== 0,
        select,
        selected: new Set(selected.values()),
        unSelect,
        indeterminate: selected.size !== 0 && selected.size !== items.length,
        selectAllOrNone,
        replace,
        cleanChecked,
        isSelected,
    } as const
}
