import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    DialogDescriptionPrefixInTeam: '移动文件到专业版团队',
    DialogDescriptionPrefixInOrg: '移动文件到团队中',

    DialogOkTextOfAdminOrAutoGrade: '移动',
    DialogCancelTextOfAdminOrAutoGrade: '取消',
    DialogTitleOfAdminOrAutoGradeInOrg: '确定要移动文件到团队中？',
    DialogTitleOfAdminOrAutoGradeInTeam: '确定要移动文件到专业版团队中？',

    DialogTitleOfNeedWaitToApproved: '无法移动文件',
} as const

export const enTranslation = {
    DialogDescriptionPrefixInTeam: 'To move files to a Professional team',
    DialogDescriptionPrefixInOrg: 'To move files to a team',

    DialogOkTextOfAdminOrAutoGrade: 'Move',
    DialogCancelTextOfAdminOrAutoGrade: 'Cancel',
    DialogTitleOfAdminOrAutoGradeInOrg: 'Are you sure you want to move files to a team?',
    DialogTitleOfAdminOrAutoGradeInTeam: 'Are you sure you want to move files to a Professional team?',
    DialogTitleOfNeedWaitToApproved: 'Unable to move files',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
