import { ApplyCreateStyle, CancelCreateStyle, CreateTextStyleNodeCommand, Wukong } from '@wukong/bridge-proto'
import { useEffect, useRef } from 'react'
import { ExplicitUndefined } from '../../../../../../../../ui-lib/src'
import { ITextStyle } from '../../../../../../document/node/node'
import { useCommand } from '../../../../../../main/app-context'
import { TextStylePanel, TextStylePanelProps } from '../text-style-panel/text-style-panel'
import { createCutTextStyle } from '../utils'
import { translation } from './text-style-editor.translation'
import { useTextStyleEditor } from './use-text-style-editor'

export interface TextStyleEditorProps
    extends Pick<
        TextStylePanelProps,
        'visible' | 'position' | 'onCancel' | 'onClickRemoteStyleLink' | 'onClickCreate' | 'hideCommonFooter'
    > {
    isRemoteStyle?: boolean
    createStyle?: { isCreate: boolean; createBase?: Parameters<typeof createCutTextStyle>[0] }
    effectCreateStyle?: (styleId: string) => void
    styleId: string
}
export function TextStyleEditor(props: TextStyleEditorProps) {
    const { createStyle, effectCreateStyle, hideCommonFooter } = props
    const {
        textStyleNode,
        variantState,
        onChangeFontFamily,
        onChangeFontStyle,
        onChangeFontSize,
        onChangeName,
        onChangeDescription,
        onChangeTextDecoration,
        onChangeLetterSpacing,
        onChangeLineHeight,
        onChangeParagraphSpacing,
    } = useTextStyleEditor(props)

    const alreadyApplyCreateRef = useRef<boolean>(false)
    const command = useCommand()
    const effectCreateStyleRef = useRef<TextStyleEditorProps['effectCreateStyle']>()
    effectCreateStyleRef.current = effectCreateStyle

    const onCreateStyle = () => {
        alreadyApplyCreateRef.current = true
        if (!textStyleNode?.name) {
            onChangeName(analyzeStyleData(textStyleNode ?? undefined).placeholder)
        }
        command.DEPRECATED_invokeBridge(ApplyCreateStyle, { nodeId: props.styleId })
        props.onClickCreate?.()
        command.commitUndo()
    }
    useEffect(() => {
        let textStyleId: string | null | undefined
        if (createStyle?.isCreate) {
            const textStyle = command.DEPRECATED_invokeBridge(
                CreateTextStyleNodeCommand,
                createCutTextStyle(createStyle.createBase) as unknown as Wukong.DocumentProto.ICreateTextStyleNodeParam
            )
            textStyleId = textStyle.id
            effectCreateStyleRef.current?.(textStyleId!)
        }
        return () => {
            if (createStyle?.isCreate && !alreadyApplyCreateRef.current && !!textStyleId) {
                command.DEPRECATED_invokeBridge(CancelCreateStyle, { nodeId: textStyleId })
            }
        }
    }, [command, createStyle?.createBase, createStyle?.isCreate])

    if (!textStyleNode) {
        return null
    }
    return (
        <TextStylePanel<ExplicitUndefined<TextStylePanelProps>>
            visible={props.visible}
            position={props.position}
            openFrom={props.isRemoteStyle ? 'remote' : createStyle?.isCreate ? 'create' : 'normal'}
            onCancel={props.onCancel}
            onClickRemoteStyleLink={props.onClickRemoteStyleLink}
            header={
                props.isRemoteStyle
                    ? translation('ViewFontStyle')
                    : createStyle?.isCreate
                    ? translation('CreateFontStyle')
                    : translation('EditFontStyle')
            }
            textStyleData={textStyleNode}
            hideCommonFooter={hideCommonFooter}
            variantData={variantState}
            onChangeName={onChangeName}
            onChangeDescription={onChangeDescription}
            onChangeFontFamily={onChangeFontFamily}
            onChangeFontStyle={onChangeFontStyle}
            onChangeFontSize={onChangeFontSize}
            onChangeLineHeight={onChangeLineHeight}
            onChangeLetterSpacing={onChangeLetterSpacing}
            onChangeParagraphSpacing={onChangeParagraphSpacing}
            onChangeTextDecoration={onChangeTextDecoration}
            onClickCreate={onCreateStyle}
            dataTestIds={{
                fontSizeInput: 'fontSize-input',
                lineHeightInput: 'lineHeight-input',
                letterSpacingInput: 'letterSpacing-input',
                paragraphSpacingInput: 'paragraphSpacing-input',
                decorationNone: 'decoration-none',
                decorationUnderline: 'decoration-underline',
                decorationStrikethrough: 'decoration-strikethrough',
            }}
        ></TextStylePanel>
    )
}

interface AnalyzePaints {
    placeholder: string
}
function analyzeStyleData(iTextStyle?: ITextStyle): AnalyzePaints {
    const result: AnalyzePaints = { placeholder: '' }

    if (iTextStyle) {
        const fontVariations = iTextStyle.fontVariations
        const { localizedFamily, family, localizedStyle, style } = iTextStyle.fontName
        if (fontVariations?.length) {
            result.placeholder = `${(localizedFamily || family) ?? ''}`
        } else {
            result.placeholder = `${(localizedFamily || family) ?? ''} ${(localizedStyle || style) ?? ''}`
        }
    }

    return result
}
