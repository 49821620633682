import classnames from 'classnames'
import { environment } from '../../../../environment'
import { PayPlanType } from '../../../../kernel/interface/type'
import { navigateToPricing } from '../../../../utils/payment'
import { PayUpgradeDialogContainer } from './pay-upgrade-dialog-container/pay-upgrade-dialog-container'
import classes from './price-comparison-professional.module.less'
import { PrivilegeCardProfessional } from './privilege-card/privilege-card-professional'
import { PrivilegeCardStarter } from './privilege-card/privilege-card-starter'
import { PriceComparisonProfessionalProps } from './type'
import { usePayUpgradePrice } from './use-pay-upgrade-price'
import { usePayUpgradeSubmit } from './use-pay-upgrade-submit'

export function PriceComparisonProfessional(props: PriceComparisonProfessionalProps) {
    const { title, subtitle, dialogProps, starterProps, professionalProps } = props
    const { hidePayUpgradeDialogContainer, submitProfessional } = usePayUpgradeSubmit()

    const { price } = usePayUpgradePrice()

    return (
        <PayUpgradeDialogContainer
            width={672}
            hide={hidePayUpgradeDialogContainer}
            {...dialogProps}
            containerClassName={classes['dialog-bg']}
            maskClosable
        >
            <div className={classes.title} data-testid="price-comparison-title">
                {title}
            </div>
            <div className={classes.subtitle} data-testid="price-comparison-subtitle">
                {subtitle}
                {environment.isAbroad ? null : <span className={classes.subtitleTip}>*所有版本需按年付费</span>}
            </div>
            <div className={classes.body}>
                <PrivilegeCardStarter
                    className={classes.privilegeCard}
                    currentPlanType={PayPlanType.starter}
                    {...starterProps}
                />
                <PrivilegeCardProfessional
                    className={classnames(classes.privilegeCard)}
                    currentPlanType={PayPlanType.starter}
                    payment={'annual'}
                    mainPriceAnnual={price.professionDesignYear}
                    mainPriceMonthly={price.professionDesignMonthly}
                    subPriceAnnual={price.professionDevYear}
                    subPriceMonthly={price.professionDevMonthly}
                    moreProps={{ onClick: navigateToPricing }}
                    {...professionalProps}
                    onSubmit={() => submitProfessional(professionalProps.submitProps, dialogProps?.onOk)}
                />
            </div>
        </PayUpgradeDialogContainer>
    )
}
