import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    ConstrainProportions: '锁定比例',
    HorizontalResizing: '宽度响应类型',
    VerticalResizing: '高度响应类型',
    Rotation: '旋转角度',
    CornerRadius: '圆角',
    IndependentCorners: '独立圆角',
    TopLeft: '左上圆角',
    TopRight: '右上圆角',
    BottomRight: '右下圆角',
    BottomLeft: '左下圆角',
    Radio: '内径比',
    Start: '起始角度',
    Sweep: '圆心角比例',
    ClipContent: '裁剪内容',
    MinWidthPlaceHolder: '最小值',
    MaxWidthPlaceHolder: '最大值',
    MinHeightPlaceHolder: '最小值',
    MaxHeightPlaceHolder: '最大值',
    MinWidthToolTip: '宽度最小值',
    MaxWidthToolTip: '宽度最大值',
    MinHeightToolTip: '高度最小值',
    MaxHeightToolTip: '高度最大值',
    AddMinWidth: '添加最小值...',
    AddMaxWidth: '添加最大值...',
    RemoveMinWidth: '移除最小值',
    RemoveMaxWidth: '移除最大值',
    AddMinHeight: '添加最小值...',
    AddMaxHeight: '添加最大值...',
    RemoveMinHeight: '移除最小值',
    RemoveMaxHeight: '移除最大值',
    ResizeTo: '适应内容大小',
} as const

export const enTranslation = {
    ConstrainProportions: 'Constrain proportions',
    HorizontalResizing: 'Horizontal resizing',
    VerticalResizing: 'Vertical resizing',
    Rotation: 'Rotation',
    CornerRadius: 'Corner radius',
    IndependentCorners: 'Independent corners',
    TopLeft: 'Top left corner radius',
    TopRight: 'Top right corner radius',
    BottomRight: 'Bottom right corner radius',
    BottomLeft: 'Bottom left corner radius',
    Radio: 'Ratio',
    Start: 'Start',
    Sweep: 'Sweep',
    ClipContent: 'Clip content',
    MinWidthPlaceHolder: 'Min W',
    MaxWidthPlaceHolder: 'Max W',
    MinHeightPlaceHolder: 'Min H',
    MaxHeightPlaceHolder: 'Max H',
    MinWidthToolTip: 'Min width',
    MaxWidthToolTip: 'Max width',
    MinHeightToolTip: 'Min height ',
    MaxHeightToolTip: 'Max height',
    AddMinWidth: 'Add min width...',
    AddMaxWidth: 'Add max width...',
    RemoveMinWidth: 'Remove min width',
    RemoveMaxWidth: 'Remove max width',
    AddMinHeight: 'Add min height...',
    AddMaxHeight: 'Add max height...',
    RemoveMinHeight: 'Remove min height',
    RemoveMaxHeight: 'Remove max height',
    ResizeTo: 'Resize to fit',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
