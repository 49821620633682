import { translation } from './color-style-select-panel.translation'
/* eslint-disable no-restricted-imports */
import { HTMLAttributes, useCallback, useMemo, useState } from 'react'
import { cmdChangePopupState } from '../../../../../document/command/document-command'
import { PopupStateType } from '../../../../../document/node/node'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'
import {
    IconColorStyle,
    IconColorType,
    IconStyleTheme,
    IconStyleTheme_CN_EN,
    IconStyleTheme_EN_CN,
    defaultIconColorStyle,
} from '../config'
import { ColorSelector } from './color-selector'
import { InputAndSelector } from './input-and-select'

export interface colorStyleSelectPanelProps extends HTMLAttributes<HTMLDivElement> {
    setColorStyle: (colorStyle: IconColorStyle) => void
    dataTestIds?: {
        colorStyleSelector?: string
        colorInput?: string
        colorSelectorPanel?: string
    }
}

export const ColorStyleSelectPanel = (props: colorStyleSelectPanelProps) => {
    const { setColorStyle, dataTestIds } = props

    const [curColorStyle, setCurColorStyle] = useState<IconColorStyle>(defaultIconColorStyle)
    const colorStyleThemes = useMemo<IconStyleTheme[]>(() => {
        return [IconStyleTheme.OUTLINE, IconStyleTheme.FILL, IconStyleTheme.TWO_TONE, IconStyleTheme.MULTI_COLOR]
    }, [])

    const command = useCommand()
    const popupState = useViewState('popupState')
    const isColorStyleOpen = popupState && popupState.type === PopupStateType.POPUP_STATE_TYPE_PLUGIN_ICONPARK_STYLE

    const [curPopupKey, setCurPopupKey] = useState<number | null>(null)

    const isOpen = useCallback(
        (popupKey: number) => {
            if (!isColorStyleOpen) {
                return false
            }
            return curPopupKey === popupKey
        },
        [curPopupKey, isColorStyleOpen]
    )

    const onChangeModalVisible = (popupKey: number | null, visible: boolean) => {
        // 新打开
        if (!isColorStyleOpen && visible) {
            command.invoke(cmdChangePopupState, {
                type: PopupStateType.POPUP_STATE_TYPE_PLUGIN_ICONPARK_STYLE,
                reciprocalIndex: -1,
                multiPopup: [],
            })
            setCurPopupKey(popupKey)

            // 打开状态直接打开另一个
        } else if (isColorStyleOpen && visible) {
            setCurPopupKey(popupKey)

            // 打开状态下关闭
        } else if (isColorStyleOpen && !visible) {
            command.invoke(cmdChangePopupState, {
                type: PopupStateType.POPUP_STATE_TYPE_NONE,
                reciprocalIndex: -1,
                multiPopup: [],
            })
            setCurPopupKey(null)
        } else {
            // 关闭状态下又关闭
            setCurPopupKey(null)
        }
    }

    const _setColorStyle = useCallback(
        (style: IconColorStyle) => {
            setColorStyle(style)
            setCurColorStyle(style)
        },
        [setColorStyle]
    )
    return (
        <>
            <InputAndSelector
                values={colorStyleThemes.map((item) => IconStyleTheme_EN_CN.get(item)!)}
                defaultIndex={0}
                inputTestId={dataTestIds?.colorStyleSelector}
                onChange={(v: string | number) => {
                    const theme = IconStyleTheme_CN_EN.get(v as string)! as IconStyleTheme
                    _setColorStyle({ ...curColorStyle, theme: theme })
                    onChangeModalVisible(null, false)
                }}
            />

            {(() => {
                switch (curColorStyle.theme) {
                    case IconStyleTheme.OUTLINE:
                        return (
                            <ColorSelector
                                popupKey={0}
                                title={translation('Stroke')}
                                colorHexValue={curColorStyle.colors[IconColorType.OUTLINE].fill}
                                isOpen={isOpen}
                                onChangeModalVisible={onChangeModalVisible}
                                dataTestIds={{
                                    colorInput: dataTestIds?.colorInput,
                                    colorSelectorPanel: dataTestIds?.colorSelectorPanel,
                                }}
                                onChange={(hexColor) => {
                                    const colorType = IconColorType.OUTLINE
                                    _setColorStyle({
                                        theme: IconStyleTheme.OUTLINE,
                                        colors: {
                                            ...curColorStyle.colors,
                                            [colorType]: {
                                                ...curColorStyle.colors[colorType],
                                                fill: hexColor,
                                            },
                                        },
                                    })
                                }}
                            />
                        )
                    case IconStyleTheme.FILL:
                        return (
                            <ColorSelector
                                popupKey={1}
                                title={translation('Background')}
                                colorHexValue={curColorStyle.colors[IconColorType.FILL].fill}
                                isOpen={isOpen}
                                onChangeModalVisible={onChangeModalVisible}
                                dataTestIds={{
                                    colorInput: dataTestIds?.colorInput,
                                    colorSelectorPanel: dataTestIds?.colorSelectorPanel,
                                }}
                                onChange={(hexColor) => {
                                    const colorType = IconColorType.FILL
                                    _setColorStyle({
                                        theme: IconStyleTheme.FILL,
                                        colors: {
                                            ...curColorStyle.colors,
                                            [colorType]: {
                                                ...curColorStyle.colors[colorType],
                                                fill: hexColor,
                                            },
                                        },
                                    })
                                }}
                            />
                        )
                    case IconStyleTheme.TWO_TONE:
                        return (
                            <>
                                <ColorSelector
                                    popupKey={2}
                                    title={translation('Stroke')}
                                    colorHexValue={curColorStyle.colors[IconColorType.TWO_TONE].fill}
                                    isOpen={isOpen}
                                    onChangeModalVisible={onChangeModalVisible}
                                    dataTestIds={{
                                        colorInput: dataTestIds?.colorInput,
                                        colorSelectorPanel: dataTestIds?.colorSelectorPanel,
                                    }}
                                    onChange={(hexColor) => {
                                        const colorType = IconColorType.TWO_TONE
                                        _setColorStyle({
                                            theme: IconStyleTheme.TWO_TONE,
                                            colors: {
                                                ...curColorStyle.colors,
                                                [colorType]: {
                                                    ...curColorStyle.colors[colorType],
                                                    fill: hexColor,
                                                },
                                            },
                                        })
                                    }}
                                />
                                <ColorSelector
                                    popupKey={3}
                                    title={translation('Background')}
                                    colorHexValue={curColorStyle.colors[IconColorType.TWO_TONE].twoTone}
                                    isOpen={isOpen}
                                    onChangeModalVisible={onChangeModalVisible}
                                    dataTestIds={{
                                        colorInput: dataTestIds?.colorInput,
                                        colorSelectorPanel: dataTestIds?.colorSelectorPanel,
                                    }}
                                    onChange={(hexColor) => {
                                        const colorType = IconColorType.TWO_TONE
                                        _setColorStyle({
                                            theme: IconStyleTheme.TWO_TONE,
                                            colors: {
                                                ...curColorStyle.colors,
                                                [colorType]: {
                                                    ...curColorStyle.colors[colorType],
                                                    twoTone: hexColor,
                                                },
                                            },
                                        })
                                    }}
                                />
                            </>
                        )
                    case IconStyleTheme.MULTI_COLOR:
                        return (
                            <>
                                <ColorSelector
                                    popupKey={4}
                                    title={translation('Outline')}
                                    colorHexValue={curColorStyle.colors[IconColorType.MULTI_COLOR].outStrokeColor}
                                    isOpen={isOpen}
                                    onChangeModalVisible={onChangeModalVisible}
                                    dataTestIds={{
                                        colorInput: dataTestIds?.colorInput,
                                        colorSelectorPanel: dataTestIds?.colorSelectorPanel,
                                    }}
                                    onChange={(hexColor) => {
                                        const colorType = IconColorType.MULTI_COLOR
                                        _setColorStyle({
                                            theme: IconStyleTheme.MULTI_COLOR,
                                            colors: {
                                                ...curColorStyle.colors,
                                                [colorType]: {
                                                    ...curColorStyle.colors[colorType],
                                                    outStrokeColor: hexColor,
                                                },
                                            },
                                        })
                                    }}
                                />
                                <ColorSelector
                                    popupKey={5}
                                    title={translation('Background_synonyms1')}
                                    colorHexValue={curColorStyle.colors[IconColorType.MULTI_COLOR].outFillColor}
                                    isOpen={isOpen}
                                    onChangeModalVisible={onChangeModalVisible}
                                    dataTestIds={{
                                        colorInput: dataTestIds?.colorInput,
                                        colorSelectorPanel: dataTestIds?.colorSelectorPanel,
                                    }}
                                    onChange={(hexColor) => {
                                        const colorType = IconColorType.MULTI_COLOR
                                        _setColorStyle({
                                            theme: IconStyleTheme.MULTI_COLOR,
                                            colors: {
                                                ...curColorStyle.colors,
                                                [colorType]: {
                                                    ...curColorStyle.colors[colorType],
                                                    outFillColor: hexColor,
                                                },
                                            },
                                        })
                                    }}
                                />
                                <ColorSelector
                                    popupKey={6}
                                    title={translation('InnerStroke')}
                                    colorHexValue={curColorStyle.colors[IconColorType.MULTI_COLOR].innerStrokeColor}
                                    isOpen={isOpen}
                                    onChangeModalVisible={onChangeModalVisible}
                                    dataTestIds={{
                                        colorInput: dataTestIds?.colorInput,
                                        colorSelectorPanel: dataTestIds?.colorSelectorPanel,
                                    }}
                                    onChange={(hexColor) => {
                                        const colorType = IconColorType.MULTI_COLOR
                                        _setColorStyle({
                                            theme: IconStyleTheme.MULTI_COLOR,
                                            colors: {
                                                ...curColorStyle.colors,
                                                [colorType]: {
                                                    ...curColorStyle.colors[colorType],
                                                    innerStrokeColor: hexColor,
                                                },
                                            },
                                        })
                                    }}
                                />
                                <ColorSelector
                                    popupKey={7}
                                    title={translation('InnerFill')}
                                    colorHexValue={curColorStyle.colors[IconColorType.MULTI_COLOR].innerFillColor}
                                    isOpen={isOpen}
                                    onChangeModalVisible={onChangeModalVisible}
                                    dataTestIds={{
                                        colorInput: dataTestIds?.colorInput,
                                        colorSelectorPanel: dataTestIds?.colorSelectorPanel,
                                    }}
                                    onChange={(hexColor) => {
                                        const colorType = IconColorType.MULTI_COLOR
                                        _setColorStyle({
                                            theme: IconStyleTheme.MULTI_COLOR,
                                            colors: {
                                                ...curColorStyle.colors,
                                                [colorType]: {
                                                    ...curColorStyle.colors[colorType],
                                                    innerFillColor: hexColor,
                                                },
                                            },
                                        })
                                    }}
                                />
                            </>
                        )
                }
            })()}
        </>
    )
}
