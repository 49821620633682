import { useEffect, useRef, useState } from 'react'
import { InputV2, InputV2Ref, Tooltip, useEllipsisTooltip } from '../../../../../../../../../../ui-lib/src'
import styles from './index.module.less'

export function DoubleClickInput(props: {
    value: string
    editing: boolean
    updateEditing: (val: boolean) => void
    forwardIcon?: React.ReactNode
    onBlur?: (val: string) => void
    dataTestId?: {
        input?: string
        normal?: string
    }
}) {
    const { value, editing, updateEditing, forwardIcon, onBlur, dataTestId } = props
    const [inputVal, setInputVal] = useState<string>(value)
    const { inactivation, ellipsisRef, onmouseenter } = useEllipsisTooltip<HTMLDivElement>()
    const inputRef = useRef<InputV2Ref>(null)

    const handleInputChange = (e: any) => {
        setInputVal(e.target.value)
        onBlur?.(e.target.value)
        updateEditing(false)
    }

    return editing ? (
        <InputV2
            size="small"
            forwardIcon={forwardIcon}
            ref={inputRef}
            value={inputVal}
            autoFocus
            onBlur={handleInputChange}
            onKeyDown={(e) => e.stopPropagation()}
            rootClassName={styles.inputBlock}
            dataTestIds={{ root: dataTestId?.input }}
            onMouseDown={(e) => e.stopPropagation()} // 编辑时阻止拖拽
        />
    ) : (
        <Tooltip title={value} inactivation={inactivation}>
            <div
                ref={ellipsisRef}
                onMouseEnter={onmouseenter}
                className={styles.normalBlock}
                data-testid={dataTestId?.normal}
            >
                {value}
            </div>
        </Tooltip>
    )
}

export function DoubleClickVariantPropInput(props: {
    propName: string
    propValue?: string
    inputEditing: boolean
    conflicted: boolean
    updateEditing: (val: boolean) => void
    forwardIcon?: React.ReactNode
    onBlur?: (val: string) => void
    dataTestId?: {
        input?: string
        name?: string
        value?: string
    }
}) {
    const { propName, propValue, inputEditing, conflicted, updateEditing, forwardIcon, onBlur, dataTestId } = props
    const [inputVal, setInputVal] = useState<string>(propName)
    const { inactivation, ellipsisRef, onmouseenter } = useEllipsisTooltip<HTMLDivElement>()
    const inputRef = useRef<InputV2Ref>(null)

    const handleBlur = (e: any) => {
        updateEditing(false)
        if (e.target.value) {
            setInputVal(e.target.value)
            onBlur?.(e.target.value)
        } else {
            setInputVal(propName)
            inputRef.current?.getInputElement().focus()
        }
    }

    useEffect(() => {
        setInputVal(propName)
    }, [propName])

    return inputEditing ? (
        <InputV2
            size="small"
            forwardIcon={forwardIcon}
            ref={inputRef}
            value={inputVal}
            autoFocus
            onBlur={handleBlur}
            onKeyDown={(e) => e.stopPropagation()}
            rootClassName={styles.inputBlock}
            dataTestIds={{ root: dataTestId?.input }}
            onMouseDown={(e) => e.stopPropagation()} // 编辑时阻止拖拽
        />
    ) : (
        <>
            <Tooltip title={propName} inactivation={inactivation}>
                <div
                    ref={ellipsisRef}
                    className={conflicted ? styles.variantNameConflict : styles.variantName}
                    data-testid={dataTestId?.name}
                    onMouseEnter={onmouseenter}
                >
                    {propName}
                </div>
            </Tooltip>
            {!conflicted && propValue && (
                <div className={styles.variantValues} data-testid={dataTestId?.value}>
                    {propValue}
                </div>
            )}
        </>
    )
}
