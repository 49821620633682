/* eslint-disable no-restricted-imports */
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { DragMoveBox, Resize } from '../../../../../../ui-lib/src'
import { useCanvas } from '../../../../external-store/atoms/create-canvas'
import { CommentId } from '../../../../kernel/interface/comment'
import { useCommentService } from '../../../../main/app-context'
import { Position } from '../type'
import classes from './detail-position.module.less'

interface DetailPositionProps {
    commentId: CommentId
    topOffsetAnchor: number
    leftOffsetAnchor: number
    rightOffsetAnchor: number
    children?: React.ReactNode
    dockToSide?: boolean
    isStopSyncActiveCommentPosition?: boolean
    dockToSideDone?: () => void
    onMoveEnd?: () => void
    changeMaxHeight?: (maxHeight: number) => void
    dataTestId?: string
}

const minEdge = 8

export function DetailPosition(props: DetailPositionProps) {
    const { dockToSideDone, changeMaxHeight } = props
    const canvas = useCanvas()
    const divRef = useRef<HTMLDivElement>(null)
    const isMoved = useRef<boolean>(false)
    const [maxHeight, setMaxHeight] = useState<number>()
    const addTopOffsetBeforeMoved = useRef<number>(0)
    const keys = useRef<string[]>([])
    const commentService = useCommentService()
    const anchorPositionRef = useRef<Position>({ left: 0, top: 0 })
    const pointXYRef = useRef<Position>({ left: 0, top: 0 })
    const coordinateRef = useRef<{ canvasDeltaX: number; canvasDeltaY: number }>({ canvasDeltaX: 0, canvasDeltaY: 0 })
    const isLoadedCalculatePosition = useRef<boolean>(true)
    const isStopSyncRef = useRef<boolean>(false)
    isStopSyncRef.current = !!props.isStopSyncActiveCommentPosition

    const transformCoordinate = useCallback(
        (position: Position) => {
            const canvasRect = canvas.getBoundingClientRect()
            return {
                left: canvasRect.left + coordinateRef.current.canvasDeltaX + position.left,
                top: canvasRect.top + coordinateRef.current.canvasDeltaY + position.top,
            }
        },
        [canvas]
    )

    const getOffsetPosition = useCallback(
        (position: Position) => {
            return { left: position.left + props.leftOffsetAnchor, top: position.top - props.topOffsetAnchor }
        },
        [props.leftOffsetAnchor, props.topOffsetAnchor]
    )

    const setStyle = useCallback(() => {
        if (!divRef.current) {
            return
        }
        const { left, top } = pointXYRef.current
        let style = `left:${left}px;top:${top}px;`
        maxHeight && (style += `max-height:${maxHeight}px;`)
        props.dockToSide && (style += `transition:left 0.2s linear 0s, top 0.2s linear 0s;`)
        divRef.current.setAttribute('style', style)
    }, [maxHeight, props.dockToSide])

    const getTop = useCallback((childRect: DOMRect, parentRect: DOMRect, minEdgeVertical = 0) => {
        let top = childRect.top
        const isOverBottom = childRect.bottom + minEdgeVertical > parentRect.bottom
        if (isOverBottom) {
            top = Math.max(parentRect.bottom - minEdgeVertical - childRect.height, parentRect.top + minEdgeVertical)
        }
        return top
    }, [])

    const getLeft = useCallback(
        (
            childRect: DOMRect,
            parentRect: DOMRect,
            minEdgeHorizontal = 0,
            baseLeft: number,
            offsetLeft: number,
            offsetRight: number
        ) => {
            let left = baseLeft + offsetRight
            const isOverRight = left + childRect.width + minEdgeHorizontal > parentRect.right
            if (isOverRight) {
                const isOverLeft = baseLeft - offsetLeft - childRect.width - minEdgeHorizontal < parentRect.left
                left = isOverLeft ? parentRect.left + minEdgeHorizontal : baseLeft - offsetLeft - childRect.width
            }
            return left
        },
        []
    )

    const updateMaxHeight = useCallback(
        (canvasHeight: number) => {
            const currentMaxHeight = canvasHeight - 2 * minEdge
            setMaxHeight(currentMaxHeight)
            changeMaxHeight?.(currentMaxHeight)
        },
        [changeMaxHeight]
    )

    const getRectWhenAllowAutoUpdatePosition = useCallback(() => {
        const canvasRect = canvas.getBoundingClientRect()
        const divRect = divRef.current?.getBoundingClientRect()
        if (!divRect || !canvasRect) {
            return
        }
        updateMaxHeight(canvasRect.height)
        if (isMoved.current) {
            return
        }
        return { canvasRect, divRect }
    }, [canvas, updateMaxHeight])

    const onChangeResize = useCallback(() => {
        const rect = getRectWhenAllowAutoUpdatePosition()
        if (!rect) {
            return
        }
        const top = getTop(rect.divRect, rect.canvasRect, minEdge)
        const left = getLeft(
            rect.divRect,
            rect.canvasRect,
            minEdge,
            anchorPositionRef.current.left,
            props.leftOffsetAnchor,
            props.rightOffsetAnchor
        )
        addTopOffsetBeforeMoved.current = top - getOffsetPosition(anchorPositionRef.current).top
        pointXYRef.current = { left, top }
        setStyle()
    }, [
        props.leftOffsetAnchor,
        props.rightOffsetAnchor,
        getRectWhenAllowAutoUpdatePosition,
        getTop,
        getLeft,
        getOffsetPosition,
        setStyle,
    ])

    const updatePointXYByAnchorPosition = useCallback(
        (nextAnchorPosition: Position, firstCall?: boolean) => {
            const rect = getRectWhenAllowAutoUpdatePosition()
            if (!rect) {
                return
            }
            const nextPosition = getOffsetPosition(nextAnchorPosition)
            const nextDivRect = {
                x: nextPosition.left,
                y: nextPosition.top,
                width: rect.divRect.width,
                height: rect.divRect.height,
                top: nextPosition.top,
                left: nextPosition.left,
                bottom: nextPosition.top + rect.divRect.height,
                right: nextPosition.left + rect.divRect.width,
                toJSON: () => '',
            }
            const top = nextPosition.top
            if (firstCall) {
                const _top = getTop(nextDivRect, rect.canvasRect, minEdge)
                addTopOffsetBeforeMoved.current = _top - top
            }
            const left = getLeft(
                nextDivRect,
                rect.canvasRect,
                minEdge,
                nextAnchorPosition.left,
                props.leftOffsetAnchor,
                props.rightOffsetAnchor
            )
            pointXYRef.current = { left, top: top + addTopOffsetBeforeMoved.current }
            setStyle()
        },
        [
            props.leftOffsetAnchor,
            props.rightOffsetAnchor,
            getRectWhenAllowAutoUpdatePosition,
            getOffsetPosition,
            getTop,
            getLeft,
            setStyle,
        ]
    )

    const onMoving = useCallback(
        (nextPosition: Position) => {
            isMoved.current = true
            pointXYRef.current = nextPosition
            setStyle()
        },
        [setStyle]
    )

    const dockToLeftTop = useCallback(() => {
        const canvasRect = canvas.getBoundingClientRect()
        pointXYRef.current = { left: 8 + canvasRect.left, top: 8 + canvasRect.top }
        isMoved.current = true
        setStyle()
    }, [canvas, setStyle])

    const onTransitionEnd = useCallback(
        (e: React.TransitionEvent) => {
            if (e.target === divRef.current) {
                dockToSideDone?.()
            }
        },
        [dockToSideDone]
    )

    useEffect(() => {
        if (!props.dockToSide) {
            return
        }
        dockToLeftTop()
    }, [props.dockToSide, dockToLeftTop])

    const updateCoordinate = useCallback((canvasDeltaX: number, canvasDeltaY: number) => {
        coordinateRef.current = { canvasDeltaX, canvasDeltaY }
    }, [])

    const updateAnchor = useCallback(
        (anchorPosition: Position) => {
            if (isStopSyncRef.current) {
                return
            }
            anchorPositionRef.current = transformCoordinate(anchorPosition)
            updatePointXYByAnchorPosition(anchorPositionRef.current, isLoadedCalculatePosition.current)
            isLoadedCalculatePosition.current = false
        },
        [transformCoordinate, updatePointXYByAnchorPosition]
    )

    const unsubscribe = useCallback(() => {
        keys.current.forEach((key) => commentService.positionService.unregisterBubble(key))
        keys.current = []
    }, [commentService.positionService])

    const subscribe = useCallback(() => {
        unsubscribe()
        if (!isMoved.current) {
            // 切换打开其他评论详情时，如果未移动过，则应视为新打开
            isLoadedCalculatePosition.current = true
        }

        const key1 = commentService.positionService.registerCoordinate(updateCoordinate)
        const key2 = commentService.positionService.registerBubbleAnchor(props.commentId, updateAnchor)
        keys.current.push(key1, key2)
    }, [unsubscribe, updateAnchor, updateCoordinate, commentService.positionService, props.commentId])

    useLayoutEffect(() => {
        subscribe()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscribe, props.isStopSyncActiveCommentPosition])

    useEffect(() => {
        return unsubscribe
    }, [unsubscribe])

    return (
        <DragMoveBox
            ref={divRef}
            onMoving={onMoving}
            onMoveEnd={props.onMoveEnd}
            className={classes.detailPosition}
            onTransitionEnd={onTransitionEnd}
            dataTestId={props.dataTestId}
        >
            <Resize onChangeResize={onChangeResize} connectWindowResize />
            {props.children}
        </DragMoveBox>
    )
}
