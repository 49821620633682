import { isMotiffApp, openLinkOnWeb, validUrl } from '../../../../util/src'
import { jsbridge } from '../../mirror/jsbridge'

export function openLink(url: string, openUrlInNewTab: boolean) {
    const formatUrl = validUrl(url)
    if (isMotiffApp()) {
        jsbridge.openLinkByNativeBrowser(formatUrl)
    } else {
        openLinkOnWeb(formatUrl, openUrlInNewTab)
    }
}
