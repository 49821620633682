/**
 * @owner: wangshuleibj@kanyun.com
 */
import classNames from 'classnames'
import { isEqual } from 'lodash-es'
import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import { MonoIconCommonClose16 } from '../../../icons-v2'
import { Scrollbar } from '../../scrollbars'
import { TabLoop, isDefaultTabbableElement } from '../../tab-loop/tab-loop'
import { WKButton } from '../../wk-button/button'
import { WKIconButton } from '../../wk-button/icon-button'
import {
    DraggablePopupPropsV2,
    DraggablePopupRef,
    MIN_SCROLL_HEIGHT,
    Position,
    PositionRange,
    calcInitPosition,
    calcInnerPosition,
    calcPositionRange,
    calcPositionWithRange,
} from '../util'
import classes from './index.module.less'
import { translation } from './index.translation'

const minMovePixel = 4 // 点击时可能会伴随轻微的移动，低于这个值的偏移并不会触发 onMoving，产品定义值为4px

/**
 * 可拖拽弹窗： https://motiff.cn/file/n9WBIheOSqPxaKHJmiN2i5i?nodeId=189%3A31
 * V2: 优化拖拽相关逻辑，会捕获 pointer event，弹窗内部拖拽模块可添加 data-disabled-drag-move="true" 属性禁用外层拖拽逻辑
 */
function _DraggablePopupV2(props: DraggablePopupPropsV2, ref: React.Ref<DraggablePopupRef>) {
    const {
        children,
        visible = false,
        header = '',
        footer,
        closable = true,
        position,
        positionRightBase = false,
        useRawPosition = false,
        range,
        width,
        height,
        style,
        okText = translation('Confirm'),
        cancelText = translation('Cancel'),
        okButtonProps = {},
        cancelButtonProps = {},
        enableScrollBar = false,
        minScrollHeight = MIN_SCROLL_HEIGHT,
        maxScrollHeight,
        styleType = 'modal',
        className,
        headerClassName,
        closeClassName,
        bodyClassName,
        footerClassName,
        notUseDefaultFooterClassName,
        testId,
        closeTestId,
        headerTestId,
        bodyTestId,
        footerTestId,
        overflowVisible,
        mask,
        keepStableTop,
        onOk,
        onCancel,
        onFirstMove,
        onMove,
        onPointerDown,
        onPointerMove,
        onPointerUp,
        ...restProps
    } = props
    const [localPosition, setLocalPosition] = useState<Position>()
    const isNeverMove = useRef<boolean>(true)
    const offset = useRef<Position>({ left: 0, top: 0 })
    // pointer down 时置为 true，用于判断是否开始移动；pointer up/move 时置为 false
    const startFlag = useRef<boolean>(false)
    const pointerDownLeftTop = useRef<Position>({ left: 0, top: 0 })
    const popupRef = useRef<HTMLDivElement>(null)
    const [container, setContainer] = useState<HTMLDivElement>()
    const contentRef = useRef<HTMLDivElement>(null)
    const headerRef = useRef<HTMLDivElement>(null)
    const footerRef = useRef<HTMLDivElement>(null)
    const [maxHeight, setMaxHeight] = useState<number>()
    const [positionRange, setPositionRange] = useState<PositionRange>()
    const [winWidth, setWinWidth] = useState<number>(window.innerWidth)
    const [winHeight, setWinHeight] = useState<number>(window.innerHeight)
    const innerRange = useMemo(() => {
        return {
            top: range?.top,
            left: range?.left,
            bottom: range?.bottom,
            right: range?.right,
        }
    }, [range?.top, range?.left, range?.bottom, range?.right])
    const [contentResizeFlag, setContentResizeFlag] = useState<number>(0)

    const containerRefCallback = useCallback(
        (node: HTMLDivElement) => {
            if (node && contentRef.current) {
                setContainer(node)
                if (!localPosition) {
                    const containerRect = node.getBoundingClientRect()
                    const rect = contentRef.current.getBoundingClientRect()
                    const headerHeight = headerRef.current?.getBoundingClientRect?.()?.height || 0
                    const footerHeight = footerRef.current?.getBoundingClientRect?.()?.height || 0
                    const innerPosition = calcInnerPosition(containerRect.width, position, positionRightBase)
                    const { newPosition, newMaxHeight } = calcInitPosition({
                        contentRect: rect,
                        headerHeight,
                        footerHeight,
                        position: innerPosition,
                        enableScrollBar,
                        minScrollHeight,
                        maxScrollHeight,
                        useRawPosition,
                    })
                    const newPostionRange = calcPositionRange({
                        range: innerRange,
                        width: containerRect.width,
                        height: newMaxHeight + headerHeight + footerHeight,
                    })

                    setLocalPosition(newPosition)
                    setMaxHeight(newMaxHeight)
                    setPositionRange(newPostionRange)
                }
            }
        },
        [
            position,
            localPosition,
            positionRightBase,
            enableScrollBar,
            innerRange,
            minScrollHeight,
            maxScrollHeight,
            useRawPosition,
        ]
    )

    const calcPositionAndMaxHeight = useCallback(
        (pos?: Position) => {
            if (!container || !contentRef.current) {
                return
            }
            const containerRect = container.getBoundingClientRect()
            const rect = contentRef.current.getBoundingClientRect()
            const { newPosition, newMaxHeight } = calcInitPosition({
                headerHeight: headerRef.current?.getBoundingClientRect()?.height || 0,
                footerHeight: footerRef.current?.getBoundingClientRect()?.height || 0,
                contentRect: rect,
                position: pos
                    ? calcInnerPosition(rect.width, pos, positionRightBase)
                    : keepStableTop || !isNeverMove.current
                    ? localPosition || containerRect
                    : calcInnerPosition(rect.width, position, positionRightBase),
                enableScrollBar,
                minScrollHeight,
                maxScrollHeight,
                useRawPosition,
            })
            if (!isEqual(localPosition, newPosition)) {
                setLocalPosition(newPosition)
            }
            setMaxHeight(newMaxHeight)
        },
        [
            container,
            positionRightBase,
            keepStableTop,
            position,
            localPosition,
            enableScrollBar,
            minScrollHeight,
            maxScrollHeight,
            useRawPosition,
        ]
    )

    const shouldCancelDrag = useCallback((e: React.PointerEvent) => {
        let startElement: HTMLElement | null = e.target as HTMLElement
        if (!popupRef.current?.contains?.(startElement)) {
            return true
        }
        while (true) {
            const endElement = popupRef.current
            if (startElement === null || startElement === endElement) {
                return false
            }
            if (startElement.dataset.disabledDragMove === 'true') {
                return true
            }
            if (isDefaultTabbableElement(startElement.nodeName.toLowerCase())) {
                return true
            }
            startElement = startElement.parentElement
        }
    }, [])

    const _onPointerDown = useCallback(
        (e: React.PointerEvent<HTMLDivElement>) => {
            startFlag.current = true
            pointerDownLeftTop.current = { left: e.clientX, top: e.clientY }
            e.stopPropagation()
            onPointerDown?.(e)
        },
        [onPointerDown]
    )

    const _onPointerMove = useCallback(
        (e: React.PointerEvent<HTMLDivElement>) => {
            onPointerMove?.(e)
            const element = e.currentTarget as HTMLDivElement
            // 判断过拖拽目标后，如果没有 capture，不再处理
            if (!startFlag.current && !element.hasPointerCapture(e.pointerId)) {
                return
            }
            e.stopPropagation()

            if (
                startFlag.current &&
                Math.max(
                    Math.abs(e.clientX - pointerDownLeftTop.current.left),
                    Math.abs(e.clientY - pointerDownLeftTop.current.top)
                ) >= minMovePixel
            ) {
                // move start
                startFlag.current = false
                if (shouldCancelDrag(e)) {
                    return
                }
                element.setPointerCapture(e.pointerId)

                const rect = element.getBoundingClientRect()
                offset.current = { left: e.clientX - rect.left, top: e.clientY - rect.top }
            }

            if (!startFlag.current) {
                // moving
                const nextPosition = calcPositionWithRange(
                    e.clientX - offset.current.left,
                    e.clientY - offset.current.top,
                    positionRange
                )
                setLocalPosition(nextPosition)
                if (isNeverMove.current) {
                    onFirstMove?.()
                }
                onMove?.(nextPosition)
                isNeverMove.current = false
            }
        },
        [onPointerMove, onFirstMove, onMove, shouldCancelDrag, positionRange]
    )

    const _onPointerUp = useCallback(
        (e: React.PointerEvent<HTMLDivElement>) => {
            onPointerUp?.(e)
            startFlag.current = false
            const element = e.currentTarget as HTMLDivElement
            if (!element.hasPointerCapture(e.pointerId)) {
                return
            }
            e.stopPropagation()
            element.releasePointerCapture(e.pointerId)
            offset.current = { left: 0, top: 0 }
        },
        [onPointerUp]
    )

    useLayoutEffect(() => {
        calcPositionAndMaxHeight()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [container, position?.top, position?.left, contentResizeFlag])

    const onResize = useCallback(() => {
        if (!container || !contentRef.current) {
            return
        }
        const containerRect = container.getBoundingClientRect()
        const rect = contentRef.current.getBoundingClientRect()
        const headerHeight = headerRef.current?.getBoundingClientRect?.()?.height || 0
        const footerHeight = footerRef.current?.getBoundingClientRect?.()?.height || 0
        const innerPosition = calcInnerPosition(containerRect.width, position, positionRightBase)
        const { newPosition, newMaxHeight } = calcInitPosition({
            contentRect: rect,
            headerHeight,
            footerHeight,
            position: keepStableTop || !isNeverMove.current ? containerRect : innerPosition,
            enableScrollBar,
            minScrollHeight,
            maxScrollHeight,
        })
        setLocalPosition(newPosition)
        setMaxHeight(newMaxHeight)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [container, isNeverMove.current, position?.top, position?.left, positionRightBase, enableScrollBar])

    const _onResize = useCallback(() => {
        setWinWidth(window.innerWidth)
        setWinHeight(window.innerHeight)
    }, [])

    useEffect(() => {
        if (!container) {
            return
        }
        const containerRect = container.getBoundingClientRect()
        const headerHeight = headerRef.current?.getBoundingClientRect?.()?.height || 0
        const footerHeight = footerRef.current?.getBoundingClientRect?.()?.height || 0
        const newPositionRange = calcPositionRange({
            range: innerRange,
            width: containerRect.width,
            height: maxHeight ? maxHeight + headerHeight + footerHeight : containerRect.height,
        })
        setPositionRange(newPositionRange)
    }, [container, innerRange, winWidth, winHeight, maxHeight])

    useEffect(() => {
        if (!visible) {
            setContainer(undefined)
            setLocalPosition(undefined)
            setMaxHeight(undefined)
            isNeverMove.current = true
        }
    }, [visible])

    useEffect(() => {
        if (container && contentRef.current) {
            const observer = new ResizeObserver((entries) => {
                setContentResizeFlag((prev) => prev + 1)
            })

            observer.observe(contentRef.current)

            return () => {
                observer.disconnect()
            }
        }
    }, [container])

    useEffect(() => {
        window.addEventListener('resize', onResize, false)
        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [onResize])

    useEffect(() => {
        window.addEventListener('resize', _onResize, false)
        return () => {
            window.removeEventListener('resize', _onResize)
        }
    }, [_onResize])

    useImperativeHandle(ref, () => ({
        getPopupElement: () => popupRef.current,
        setPosition: (pos: Position) => {
            calcPositionAndMaxHeight(pos)
        },
    }))

    return visible ? (
        <TabLoop>
            <div
                ref={popupRef}
                onMouseDown={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
                data-testid="draggable-popup-v2"
            >
                <div
                    ref={containerRefCallback}
                    className={classNames(classes['draggable-popup-container'], className)}
                    style={{
                        ...style,
                        top: `${localPosition?.top}px`,
                        left: `${localPosition?.left}px`,
                        overflow: overflowVisible ? 'visible' : 'hidden',
                    }}
                    data-testid={testId}
                    onPointerDown={_onPointerDown}
                    onPointerMove={_onPointerMove}
                    onPointerUp={_onPointerUp}
                    {...restProps}
                >
                    <div
                        className={classNames(classes['draggable-popup'], styleType === 'editor' ? classes.editor : '')}
                        style={{
                            width: typeof width === 'number' ? `${width}px` : width,
                            height: typeof height === 'number' ? `${height}px` : height,
                        }}
                    >
                        {header !== null ? (
                            <div
                                ref={headerRef}
                                className={classNames(classes.header, headerClassName)}
                                data-testid={headerTestId}
                            >
                                {closable ? (
                                    <DraggablePopupCloseButton
                                        dataTestId={closeTestId}
                                        onClick={onCancel}
                                        isCenterVertically
                                        className={closeClassName}
                                    />
                                ) : null}
                                {typeof header === 'string' ? <div className={classes.title}>{header}</div> : header}
                            </div>
                        ) : null}
                        {enableScrollBar ? (
                            <Scrollbar autoHeight autoHeightMax={maxHeight}>
                                <div className={classes.content} ref={contentRef}>
                                    <div className={classNames(classes.body, bodyClassName)} data-testid={bodyTestId}>
                                        {children}
                                    </div>
                                </div>
                            </Scrollbar>
                        ) : (
                            <div className={classes.content} ref={contentRef}>
                                <div className={classNames(classes.body, bodyClassName)} data-testid={bodyTestId}>
                                    {children}
                                </div>
                            </div>
                        )}

                        {footer === undefined ? (
                            <div
                                ref={footerRef}
                                className={classNames(
                                    { [classes.footer]: !notUseDefaultFooterClassName },
                                    footerClassName
                                )}
                                data-testid={footerTestId}
                            >
                                <div className={classes['footer-buttons']}>
                                    <WKButton
                                        type="secondary"
                                        onClick={onCancel}
                                        {...cancelButtonProps}
                                        data-testid="draggable-popup-cancel"
                                    >
                                        {cancelText}
                                    </WKButton>
                                    <WKButton
                                        type="primary"
                                        className={classes['ok-btn']}
                                        onClick={onOk}
                                        {...okButtonProps}
                                        data-testid="draggable-popup-ok"
                                    >
                                        {okText}
                                    </WKButton>
                                </div>
                            </div>
                        ) : footer ? (
                            <div
                                ref={footerRef}
                                className={classNames(
                                    { [classes.footer]: !notUseDefaultFooterClassName },
                                    footerClassName
                                )}
                                data-testid={footerTestId}
                            >
                                {footer}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            {mask ? <div className={classes.mask} onMouseDown={onCancel} /> : null}
        </TabLoop>
    ) : null
}

export interface DraggablePopupCloseButtonProps {
    className?: string
    style?: React.CSSProperties
    isCenterVertically?: boolean // 在定位元素中垂直居中
    onClick?: () => void
    dataTestId?: string
}
export function DraggablePopupCloseButton(props: DraggablePopupCloseButtonProps) {
    return (
        <WKIconButton
            icon={<MonoIconCommonClose16 data-testid="icon-close-svg" />}
            onClick={props.onClick}
            className={classNames({ [classes.close]: props.isCenterVertically }, props.className)}
            style={props.style}
            data-testid={props.dataTestId}
        />
    )
}

export const DraggablePopupV2 = forwardRef(_DraggablePopupV2)
