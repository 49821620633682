import { translation } from './batch-export-prohibit-panel.translation'
import style from './batch-export-prohibit-panel.module.less'
import { IconError } from '../../../../../../ui-lib/src'

// 导出禁止面板
export function ProhibitExportPanel() {
    return (
        <div className={style.prohibit} data-testid="prohibit-export-panel">
            <div className={style.content}>
                <div className={style.tipTitleBar}>
                    <IconError />
                    <div className={style.tipTitle}>{translation('ProhibitExportTitle')}</div>
                </div>
                <div className={`${style.tipContent}`}>{translation('ProhibitExportContent')}</div>
            </div>
        </div>
    )
}
