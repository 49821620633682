import { ticket$ } from '../../../external-store/atoms/ticket'
import { appStore$ } from '../../../external-store/store'
import { WKFrog } from '../../../kernel/frog'

async function getRole() {
    const ticket = await appStore$.get(ticket$)
    return ticket?.role
}

export enum SwitchModeWay {
    ShortcutKey = '快捷键',
    Toolbar = '工具栏',
    Menu = '菜单',
}

export const logs = {
    DevMode: {
        async enterDevMode(customExtend: { enter_devmode_way: SwitchModeWay }) {
            WKFrog.addFrogRecord({
                url: '/event/DevMode/enterDevMode',
                eventId: 24145,
                eventName: 'enterDevMode',
                eventAction: 'event',
                customExtend: {
                    role: await getRole(),
                    ...customExtend,
                },
            })
        },
        async enterDesignMode(customExtend: { enter_designmode_way: SwitchModeWay }) {
            WKFrog.addFrogRecord({
                url: '/event/DevMode/enterDesignMode',
                eventId: 24146,
                eventName: 'enterDesignMode',
                eventAction: 'event',
                customExtend: {
                    role: await getRole(),
                    ...customExtend,
                },
            })
        },
        async exportDialogDisplay(customExtend: { interaction_type: 'click' | 'hotkey' }) {
            WKFrog.addFrogRecord({
                url: '/expose/DevMode/exportDialogDisplay',
                eventId: 24152,
                eventName: 'exportDialogDisplay',
                eventAction: 'expose',
                customExtend: {
                    role: await getRole(),
                    ...customExtend,
                },
            })
        },
        async bulkExportExecute(customExtend: {
            export_preset_type: 'iOS' | 'Web' | 'Android'
            export_file_type: 'PNG' | 'JPG' | 'PDF' | 'WebP' | 'SVG' | 'AVIF'
            export_scale: string
            export_compression: 'compressed' | 'uncompressed'
        }) {
            WKFrog.addFrogRecord({
                url: '/event/DevMode/bulkExportExecute',
                eventId: 24153,
                eventName: 'bulkExportExecute',
                eventAction: 'event',
                customExtend: {
                    role: await getRole(),
                    ...customExtend,
                },
            })
        },
        async exportExecute(customExtend: {
            export_preset_type: 'iOS' | 'Web' | 'Android'
            export_file_type: 'PNG' | 'JPG' | 'PDF' | 'WebP' | 'SVG' | 'AVIF'
            export_scale: string
            export_compression: 'compressed' | 'uncompressed'
        }) {
            WKFrog.addFrogRecord({
                url: '/event/DevMode/exportExecute',
                eventId: 24154,
                eventName: 'exportExecute',
                eventAction: 'event',
                customExtend: {
                    role: await getRole(),
                    ...customExtend,
                },
            })
        },
    },
}
