import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Swapping: '正在替换',
} as const

export const enTranslation = {
    Swapping: 'Swapping',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
