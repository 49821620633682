import { DELETE, GET, POST, PUT } from '@tutor/network-core'
import {
    CommentPosition,
    CommentReply,
    CommentThread,
    CommentUser,
    CreateCommentReplyArgs,
    CreateCommentThreadArgs,
    EditCommentOrReplyArgs,
    Strategy,
} from '../interface/comment'
import { BaseCommonRequest } from './base-request'

// 获取doc下所有评论，及评论相关的回复、互动和位置信息
@GET
export class GetAllComments extends BaseCommonRequest<CommentThread[]> {
    constructor(private docId: string, private startFromCommentId?: number) {
        super()
    }

    public override requestArgument() {
        return {
            minCommentId: this.startFromCommentId ?? -1,
        }
    }

    public override requestUrl() {
        return `comments/query/${this.docId}`
    }
}

// 查询最近的@列表
/**
 * @deprecated
 */
@GET
export class GetContactsList extends BaseCommonRequest<CommentUser[]> {
    constructor(private docId: string) {
        super()
    }

    public override requestArgument() {
        return { docId: this.docId }
    }

    public override requestUrl() {
        return `comments/contacts/list`
    }
}

// 查询最近的@列表
@GET
export class GetContactsRecommend extends BaseCommonRequest<CommentUser[]> {
    constructor(private docId: string) {
        super()
    }

    public override requestArgument() {
        return { docId: this.docId }
    }

    public override requestUrl() {
        return `comments/contacts/recommend`
    }
}

// 表情回复
@POST
export class ReplyReactions extends BaseCommonRequest<number> {
    constructor(private commentId: number, private message: string) {
        super()
    }

    public override requestArgument() {
        return { message: this.message }
    }

    public override requestUrl() {
        return `comments/${this.commentId}/reactions`
    }
}

// 删除表情回复
@DELETE
export class DeleteReactions extends BaseCommonRequest<number> {
    constructor(private id: number) {
        super()
    }

    public override requestArgument() {
        return { id: this.id }
    }

    public override requestUrl() {
        return `comments/reactions`
    }
}

// 标为未读
@POST
export class SetCommentsAsUnread extends BaseCommonRequest {
    constructor(private ids: number[]) {
        super()
    }

    public override requestBody() {
        return this.ids
    }

    public override requestUrl() {
        return `comments/unread-comments`
    }
}

// 标记已读
@DELETE
export class SetCommentsAsRead extends BaseCommonRequest {
    constructor(private ids: number[]) {
        super()
    }

    public override requestBody() {
        return this.ids
    }

    public override requestUrl() {
        return `comments/unread-comments`
    }
}

// 标记已解决
@POST
export class SetCommentsAsResolve extends BaseCommonRequest {
    constructor(private commentId: number) {
        super()
    }

    public override requestUrl() {
        return `comments/${this.commentId}/resolve`
    }
}

// 标记未解决
@POST
export class SetCommentsAsUnResolve extends BaseCommonRequest {
    constructor(private commentId: number) {
        super()
    }

    public override requestUrl() {
        return `comments/${this.commentId}/unResolve`
    }
}

// 发布回复 返回该回复的id
@POST
export class ReleaseReply extends BaseCommonRequest<CommentReply> {
    constructor(private replyInfo: CreateCommentReplyArgs) {
        super()
    }

    public override requestBody() {
        return this.replyInfo
    }

    public override requestUrl() {
        return `comments/reply`
    }
}

// 发布评论 返回该评论的id
@POST
export class ReleaseComment extends BaseCommonRequest<CommentThread> {
    constructor(private commentInfo: CreateCommentThreadArgs) {
        super()
    }

    public override requestBody() {
        return this.commentInfo
    }

    public override requestUrl() {
        return `comments`
    }
}

// 获取评论详情，及评论相关的回复、互动和位置信息。如果该评论是一条回复，则无法通过该接口获取
@GET
export class GetCommentDetail extends BaseCommonRequest<CommentThread> {
    constructor(private id: number) {
        super()
    }

    public override requestUrl() {
        return `comments/${this.id}`
    }
}

// 删除评论或回复
@DELETE
export class DeleteCommentOrReply extends BaseCommonRequest {
    constructor(private id: number) {
        super()
    }

    public override requestUrl() {
        return `comments/${this.id}`
    }
}

// 编辑评论或回复
@PUT
export class EditCommentOrReply extends BaseCommonRequest {
    constructor(private id: number, private editInfo: EditCommentOrReplyArgs) {
        super()
    }

    public override requestBody() {
        return this.editInfo
    }

    public override requestUrl() {
        return `comments/${this.id}/edit`
    }
}

// 编辑评论位置
@PUT
export class EditCommentPosition extends BaseCommonRequest {
    constructor(private id: number, private position: CommentPosition, private pageId: string) {
        super()
    }

    public override requestBody() {
        return this.position
    }

    public override requestUrl() {
        return `comments/${this.id}/move?page=${this.pageId}`
    }
}

// 搜索可@的用户列表，无符合条件的返回空
@GET
export class QueryCommentUser extends BaseCommonRequest {
    constructor(private docId: string, private queryString: string) {
        super()
    }

    public override requestArgument() {
        return { keywords: this.queryString }
    }

    public override requestUrl() {
        return `comments/${this.docId}/contacts/search`
    }
}

// 按文档维度设置邮件接收规则
@POST
export class SetMailStrategy extends BaseCommonRequest<void> {
    constructor(private docId: string, private strategy: Strategy) {
        super()
    }

    public override requestArgument() {
        return { docId: this.docId, strategy: this.strategy }
    }

    public override requestUrl() {
        return `comments/config-mail-strategy`
    }
}

// 搜索可@的用户列表，无符合条件的返回空
@GET
export class GetMailStrategy extends BaseCommonRequest<Strategy> {
    constructor(private docId: string) {
        super()
    }

    public override requestArgument() {
        return { docId: this.docId }
    }

    public override requestUrl() {
        return `comments/query-mail-strategy`
    }
}
