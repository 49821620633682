import {
    HistoryModeGetPageArchive,
    IsFeatureEnabled,
    LoadFontInJsCommand,
    UpdateFromCreatingFileCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { command } from 'ccstate'
import { TraceableAbortSignal } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { transaction } from '../../../../../../util/src/abort-controller/traceable-transaction'
import { traceable } from '../../../../../../util/src/ccstate-helper/traceable'
import { DO_NOT_USE_THIS_cmdSetRenderViewport } from '../../../../document/command/canvas-resize-command'
import { CommitType } from '../../../../document/command/commit-type'
import { cleanReplayStaticVariables } from '../../../../kernel/recording/record'
import { schemaVersionManager } from '../../../../kernel/schema'
import { featureSwitchManager } from '../../../../kernel/switch/core'
import { createFileManager, isCreatingFile$ } from '../../../../main/create-file-manager'
import { ForceUpgradeReloadService } from '../../../../main/force-upgrade-reload/force-upgrade-reload-service'
import { EditorContext } from '../../../../main/lifecycle/types/editor'
import { toastWhenCheckingGraphicsBackend } from '../../../../share/graphics-backend'
import { editorCanvas$ } from '../../../atoms/create-canvas'
import { defaultFonts$ } from '../../../atoms/preload-fonts'
import { synergyAuthed$ } from '../../../atoms/synergy-auth'
import { userInfo$ } from '../../../atoms/user-info'
import { prepareBenchmarkEnv$ } from './prepare-benchmark-env'

export const afterSetupEditor$ = traceable(
    'hulh01@kanyun.com',
    command(async ({ get, set }, signal: TraceableAbortSignal, editorContext: EditorContext) => {
        const userInfo = await get(userInfo$)

        signal.throwIfAborted()

        const defaultFonts = await get(defaultFonts$)

        signal.throwIfAborted()

        const { act } = transaction(signal)

        act('cleanup editor after leave editor', () => {
            return () => {
                window.clientId = undefined
                editorContext.destroyEditorWeb()
                editorContext.destroyEditorWasm()
            }
        })

        act('init force upgrade service', () => {
            new ForceUpgradeReloadService(signal, false, editorContext.webSocketBridge)
        })

        act('update commentService userInfo', () => {
            const { commentService } = editorContext

            commentService.updateCurrentUser(userInfo.userBrief)
        })

        act('commentService update docId', () => {
            if (get(isCreatingFile$)) {
                createFileManager.injectCreateFileCallBack(editorContext.commentService?.updateCurrentDocId)
            } else {
                editorContext.commentService.updateCurrentDocId()
            }
        })

        act('set default fonts', () => {
            const { fontManagerService, commandInvoker } = editorContext

            for (const font of defaultFonts) {
                fontManagerService.insertFont(font)
                commandInvoker.invokeBridge(CommitType.Noop, LoadFontInJsCommand, {
                    url: font.url,
                    size: font.data.length,
                })
            }
        })

        act('mark creating file in wasm', () => {
            editorContext.commandInvoker.invokeBridge(
                CommitType.Noop,
                UpdateFromCreatingFileCommand,
                Wukong.DocumentProto.BridgeProtoBoolean.create({
                    value: createFileManager.isCreatingFile(),
                })
            )
        })

        act('reset synergy authed', () => {
            return () => {
                set(synergyAuthed$, false)
            }
        })

        act('set viewport and dpr for renderTree', () => {
            if (!featureSwitchManager.isEnabled('do-not-set-canvas-size-on-after-editor-setup')) {
                editorContext.commandInvoker.invoke(DO_NOT_USE_THIS_cmdSetRenderViewport, get(editorCanvas$))
            }
        })

        act('create file callback and connect synergy', () => {
            if (get(isCreatingFile$)) {
                createFileManager.createFileOnDocument(editorContext.userId, editorContext.bridge)
                createFileManager.afterCreateFile(editorContext.connectSynergy)
            } else {
                editorContext.connectSynergy()
            }

            return () => {
                editorContext.destroySynergy()
            }
        })

        act('provide bridge for schemaVersionManager', () => {
            schemaVersionManager.provideBridge(editorContext.bridge)

            return () => {
                schemaVersionManager.destroy()
            }
        })

        act('inject some bind cleanup', () => {
            return () => {
                const bridge = editorContext.bridge
                bridge.unbind(IsFeatureEnabled)
                bridge.unbind(HistoryModeGetPageArchive)
            }
        })

        act('replay static variables cleanup', () => {
            return () => cleanReplayStaticVariables()
        })

        act('toast when checking graphics backend', () => {
            toastWhenCheckingGraphicsBackend()
        })

        await set(prepareBenchmarkEnv$, signal)
    })
)
