import { Wukong } from '@wukong/bridge-proto'
import { useMemo, useRef, useState } from 'react'
import { useDebounce } from 'react-use'
import { ReactComponent as IconparkIcon } from '../../../assets/plugins/iconpark.svg'
import { ReactComponent as AttentionFooterIcon } from '../../../assets/plugins/iconpark/icon-attention-footer.svg'
import { PluginExported, PluginModalProps } from '../type'
import { AttributePanel } from './components/attribute-panel'
import IconItemsContainer from './components/icon-items-container.lazy'
import { categoryOrder, defaultIconConfig, IconColorStyle, IconData } from './config'
import styles from './index.module.less'
import { translation } from './index.translation'
import { IconItemId, IconSearchItem, SearchHelper } from './utils/search-helper'

import { IconProvider, IIconConfig, StrokeLinecap, StrokeLinejoin } from '@icon-park/react/es/runtime'
import icons from '@icon-park/react/icons.json'
import { InputV2 } from '../../../../../../ui-lib/src'

const Prefix = (content: any) => `iconpark-${content}`
export const IconparkTestIds = {
    Container: Prefix('container'),
    SearchInput: Prefix('search-input'),
    IconsContainer: Prefix('icons-container'),
    IconItem: Prefix('icon-item'),
    SizeSelector: Prefix('size-selector'),
    StrokeWidthSelector: Prefix('stroke-width-selector'),
    StrokeCapRoundBtn: Prefix('stroke-cap-round'),
    StrokeCapButtBtn: Prefix('stroke-cap-butt'),
    StrokeCapSquareBtn: Prefix('stroke-cap-square'),
    StrokeJoinRoundBtn: Prefix('stroke-join-round'),
    StrokeJoinMiterBtn: Prefix('stroke-join-miter'),
    StrokeJoinBevelBtn: Prefix('stroke-join-bevel'),
    ColorStyleSelector: Prefix('color-style-selector'),
    ColorInput: Prefix('color-input'),
    ColorSelectorPanel: Prefix('color-selector-panel'),
    CancelBtn: Prefix('delete-x'),
}

export const IconparkPlugin = (_: PluginModalProps) => {
    const [config, setConfig] = useState<IIconConfig>(defaultIconConfig)

    const [iconsMap, tagsMap] = useMemo(() => {
        const iconMap = new Map<number, IconData>()
        const tagMap = new Map<IconItemId, IconSearchItem>()

        const _icons = icons.sort((a, b) => {
            const orderA = categoryOrder.get(a.category) || -1
            const orderB = categoryOrder.get(b.category) || -1
            if (orderA !== orderB) {
                return orderA - orderB
            }
            return a.name.localeCompare(b.name)
        })

        for (const icon of _icons) {
            iconMap.set(icon.id, icon)
            tagMap.set(icon.id, { id: icon.id, tags: getIconItemTag(icon) })
        }

        return [iconMap, tagMap]
    }, [])

    const [showIconIds, setShowIconIds] = useState<number[]>([...iconsMap.keys()])
    const [debouncedShowIconIds, setDebouncedShowIconIds] = useState<number[]>([...iconsMap.keys()])

    useDebounce(
        () => {
            setDebouncedShowIconIds(showIconIds)
        },
        200,
        [showIconIds]
    )

    const showIconsMap = useMemo(() => {
        const map = new Map<number, IconData>()
        for (const id of debouncedShowIconIds) {
            map.set(id, iconsMap.get(id)!)
        }
        return map
    }, [debouncedShowIconIds, iconsMap])

    const searchHelper = useRef<SearchHelper>(new SearchHelper(tagsMap))

    const onSearchChange = (value: string) => {
        const result = searchHelper.current.search(value)
        setShowIconIds(result)
    }

    const setSize = (size: number) => {
        setConfig({ ...config, size })
    }

    const setStrokeWidth = (strokeWidth: number) => {
        setConfig({ ...config, strokeWidth })
    }

    const setColorStyle = (colorStyle: IconColorStyle) => {
        setConfig({ ...config, theme: colorStyle.theme, colors: colorStyle.colors })
    }

    const setStrokeLinecap = (strokeLinecap: StrokeLinecap) => {
        setConfig({ ...config, strokeLinecap })
    }

    const setStrokeLinejoin = (strokeLinejoin: StrokeLinejoin) => {
        setConfig({ ...config, strokeLinejoin })
    }

    return (
        <div className={styles.container} data-testid={IconparkTestIds.Container}>
            <div className={styles.leftPanel}>
                <div className={styles.iconPanel}>
                    <InputV2.Search
                        autoFocus
                        onSearch={(v) => onSearchChange(v.toLocaleLowerCase())}
                        style={{ margin: '0 14px 13px 0' }}
                        placeholder={translation('Search')}
                        dataTestIds={{
                            input: IconparkTestIds.SearchInput,
                            clearIcon: IconparkTestIds.CancelBtn,
                        }}
                    />

                    <IconProvider value={config}>
                        <IconItemsContainer
                            // 显示内容有变化则刷新滚动框，否则不变
                            key={[...showIconsMap.keys()].toString()}
                            iconItemsMap={showIconsMap}
                            config={config}
                            dataTestIds={{
                                IconsContainer: IconparkTestIds.IconsContainer,
                                IconItem: IconparkTestIds.IconItem,
                            }}
                        />
                    </IconProvider>
                </div>

                <Footer />
            </div>

            <div className={styles.rightPanel}>
                <AttributePanel
                    setSize={setSize}
                    setStrokeWidth={setStrokeWidth}
                    setColorStyle={setColorStyle}
                    setStrokeLinecap={setStrokeLinecap}
                    setStrokeLinejoin={setStrokeLinejoin}
                    dataTestIds={{
                        SizeSelector: IconparkTestIds.SizeSelector,
                        StrokeWidthSelector: IconparkTestIds.StrokeWidthSelector,
                        StrokeCapRoundBtn: IconparkTestIds.StrokeCapRoundBtn,
                        StrokeCapButtBtn: IconparkTestIds.StrokeCapButtBtn,
                        StrokeCapSquareBtn: IconparkTestIds.StrokeCapSquareBtn,
                        StrokeJoinRoundBtn: IconparkTestIds.StrokeJoinRoundBtn,
                        StrokeJoinMiterBtn: IconparkTestIds.StrokeJoinMiterBtn,
                        StrokeJoinBevelBtn: IconparkTestIds.StrokeJoinBevelBtn,
                        colorStyleSelector: IconparkTestIds.ColorStyleSelector,
                        colorInput: IconparkTestIds.ColorInput,
                        colorSelectorPanel: IconparkTestIds.ColorSelectorPanel,
                    }}
                />
            </div>
        </div>
    )
}

const Footer = () => {
    return (
        <div className={styles.footer}>
            <AttentionFooterIcon />
            <span>
                {translation('SourcedFromIconPark')}&nbsp;
                <a target="_blank" href={'https://iconpark.oceanengine.com/official'} rel="noreferrer">
                    IconPark
                </a>
            </span>
        </div>
    )
}

function getIconItemTag(item: any): string[] {
    const tags = item.tag.map((tag: string) => tag.toLowerCase())
    tags.push(item.title.toLowerCase())
    tags.push(item.name.toLowerCase())
    tags.push(item.category.toLowerCase())
    tags.push(item.categoryCN.toLowerCase())

    return tags
}

export default {
    manifest: {
        key: Wukong.DocumentProto.PluginType.PLUGIN_TYPE_ICONPARK,
        name: translation('IconparkIcon'),
        width: 532,
        height: 673,
        icon: <IconparkIcon />,
    },
    Component: IconparkPlugin,
} as PluginExported
