import { translation } from './stroke-independent-type.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { Select, Tooltip } from '../../../../../../../ui-lib/src'
import { useEffect } from 'react'
import { EditorDataTestId } from '../../../../../window'
import { StrokeIndependentOptionMap, StrokeIndependentTypeIcon } from './stroke-independent-type-icon'
import classes from './stroke-independent-type.module.less'
import { useStrokeIndependentType } from './use-stroke-independent-type'

// 独立描边类型
export function StrokeIndependentType(props: {
    className?: string
    onChangeType: (type: Wukong.DocumentProto.IndependentStrokeType) => void
    onUserTypeStateChange:
        | undefined
        | ((typeState: Wukong.DocumentProto.ISelectionStrokeIndependentTypeState | undefined) => void)
}) {
    const { iconTypeState, userSelectTypeState, onChangeType } = useStrokeIndependentType()

    useEffect(() => {
        props.onUserTypeStateChange?.(userSelectTypeState)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userSelectTypeState])

    const propsOnChangeType = (value: Wukong.DocumentProto.IndependentStrokeType) => {
        onChangeType(value)
        props.onChangeType?.(value)
    }

    return userSelectTypeState &&
        userSelectTypeState.type !== Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_EMPTY ? (
        <Tooltip title={translation('StrokesPerSide')}>
            <div className={props.className}>
                <Select.IconSingleLevel
                    classNameWhenOpen={classes.svgWhite}
                    value={userSelectTypeState.value}
                    onChange={propsOnChangeType}
                    isMixed={
                        userSelectTypeState.type ===
                        Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                    }
                    label={
                        <StrokeIndependentTypeIcon
                            mixed={
                                iconTypeState?.type ===
                                Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                            }
                            type={iconTypeState?.value}
                            existStatus={iconTypeState?.existStatus}
                        />
                    }
                    placement="bottom center"
                    disableDefaultPreselect
                    dataTestIds={{
                        triggerContainer: EditorDataTestId.StrokeAttr.IndependentTypeSelect,
                        triggerFocus: EditorDataTestId.StrokeAttr.IndependentTypeSelectTrigger,
                    }}
                    minWidth={120}
                >
                    {[
                        Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_ALL,
                        Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_TOP,
                        Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_BOTTOM,
                        Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_LEFT,
                        Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_RIGHT,
                        Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_CUSTOM,
                    ].map((key) => (
                        <Select.IconSingleLevel.Option
                            value={key}
                            key={key}
                            forwardIcon={StrokeIndependentOptionMap.get(key)?.icon}
                            splitLineTop={
                                key == Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_CUSTOM
                            }
                            data-testid={EditorDataTestId.StrokeAttr.IndependentTypeSelectPrefix(key)}
                        >
                            {StrokeIndependentOptionMap.get(key)?.label}
                        </Select.IconSingleLevel.Option>
                    ))}
                </Select.IconSingleLevel>
            </div>
        </Tooltip>
    ) : (
        <></>
    )
}
