import { CheckCanvasRightMenuOpenable, Wukong } from '@wukong/bridge-proto'
import { useSet } from 'ccstate-react'
import classnames from 'classnames'
import { isNil } from 'lodash-es'
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { useEffectOnce } from 'react-use'
import { isRightClick, TabLoop } from '../../../ui-lib/src'
import { usePrevious } from '../../../util/src'
import { cmdResizeCanvas } from '../document/command/canvas-resize-command'
import { PluginUIContainer } from '../document/plugin-ui/plugin-ui-container'
import { useCanvas } from '../external-store/atoms/create-canvas'
import { useDocReadonly } from '../external-store/context/doc-info'
import { editorLoading$ } from '../external-store/lifecycle/1-create-app/skeleton/editor'
import { featureSwitchManager } from '../kernel/switch/core'
import { isWindows } from '../kernel/util/ua'
import { useCommand, useHistoryService } from '../main/app-context'
import { RootComponentId } from '../main/canvas/canvas'
import { useHandleRightClickEvent } from '../main/canvas/use-right-click'
import { DevModeEditorUI } from '../main/dev-mode/dev-mode-root'
import {
    AppLayoutWrapper,
    useBottomMargin,
    useLeftMargin,
    useLeftPanelShown,
    useRightMargin,
    useRightPanelShown,
    useSetLeftPanelShown,
    useSetRightPanelShown,
    useSetRightPanelWidth,
    useSetTopbarShown,
    useTopbarShown,
    useTopMargin,
} from '../main/layout/layout-context'
import { DEFAULT_RIGHT_PANEL_WIDTH } from '../main/layout/layout-default-value'
import { getDefaultGlobalConfigsWithStorage2 } from '../main/user-config/user-config'
import { useUserConfigStateV2 } from '../main/user-config/user-config-hook'
import { SchemaVersionUpdater } from '../schema-version'
import { useViewState } from '../view-state-bridge'
import { EditorDataTestId } from '../window'
import { AIDesignLintPopupModal } from './component/ai/ai-design-lint/ai-design-lint-modal'
import { AiDuplicateReferenceSelectModal } from './component/ai/ai-duplicate/ai-duplicate-reference-select-modal'
import { AIGenPrototype, AIGenUI } from './component/ai/ai-gen-ui/ai-gen-ui-modal'
import { AiLayoutEntryMenu } from './component/ai/ai-layout/ai-layout-entry-menu'
import { AIMagic } from './component/ai/ai-magic'
import { AiSearchModal } from './component/ai/ai-search'
import { isAISearchEnabled } from './component/ai/ai-search/ai-search-feature'
import { CandidateComponentPublishDialog } from './component/ai/candidate-component/candidate-component-publish-dialog'
import { CandidateComponentTooltip } from './component/ai/candidate-component/candidate-component-tooltip'
import { AiComponentDetailModal } from './component/ai/component-recognize/component-detail-modal'
import { CanvasSearchHighLightTitle } from './component/canvas-search-panel/canvas-search-high-light-title'
import { ChatbotEntry } from './component/chatbot/chatbot-entry'
import { ChatbotModal } from './component/chatbot/chatbot-modal'
import { MenuChangeColorProfieDialog } from './component/color-profile'
import { MenuPreferenceColorProfieDialog } from './component/color-profile/preference-dialog'
import { Comment } from './component/comment'
import { DraftLibraryMoveToTeam } from './component/component-style-library-v2/draft-library-move-to-team/draft-library-move-to-team'
import { CurrentDocLibraryPublishModal as CurrentDocLibraryPublishModalV2 } from './component/component-style-library-v2/library-local-publish'
import { JumpToFigmaStyleModal } from './component/component/jump-to-figma-style-modal'
import { CoactorObservingBorder, CooperationCursorList } from './component/cooperation'
import { AutoLayoutFloatInput } from './component/design-panel-v2/attributes/components/auto-layout'
import { JumpToFigmaModal } from './component/design-panel-v2/component-instance-panel'
import BatchExportModal from './component/design/export/batch-export-modal'
import { HyperlinkEditorPopup } from './component/design/hyperlink/editor-popup'
import { HyperlinkHoverPopup } from './component/design/hyperlink/hover-popup'
import { NavigateToDesktopPopup } from './component/desktop/navigate-to-desktop-popup'
import { Detail } from './component/detail/detail'
import { DropToCanvasArea } from './component/drop-canvas-area/drop-canvas-area'
import { EditorHelpCenter } from './component/editor-help-center'
import { EditorOnboarding } from './component/editor-onboarding'
import { usePickColor } from './component/eye-dropper/use-eye-dropper'
import { LeftSidePanel } from './component/left-side-panel/left-side-panel'
import { UsedMemoryProvider } from './component/memory-usage/context/used-memory-context'
import { MemoryUsageAlertBanner } from './component/memory-usage/memory-usage-alert-banner'
import { MemoryUsageBar } from './component/memory-usage/memory-usage-bar'
import { MemoryAlertDialog } from './component/memory-usage/memory-usage-crash-dialog'
import { PubMirrorFrame } from './component/mirror'
import { MotiffScope } from './component/motiff-debugger/motiff-scope'
import { WasmCallMonitor } from './component/motiff-debugger/wasm-call-monitor'
import { MultipleRenameModal } from './component/multiple-rename-modal'
import { DevModeBlockedModal } from './component/payment/dev-mode-blocked-modal'
import { PlanUpgradingModal } from './component/payment/plan-updating-modal'
import { PluginPopupModal } from './component/plugin'
import { PluginDevelopment } from './component/plugin/plugin-development/plugin-development'
import { PluginManagement } from './component/plugin/plugin-management/plugin-management'
import { PrototypeInteractionPopup } from './component/prototype/prototype-interaction-popup'
import { PrototypeStartingPointNameInput } from './component/prototype/starting-point/starting-point-name-input'
import { ShortcutGuide } from './component/shortcut-guide/shortcut-guide'
import { CustomFocus } from './component/text-edit/custom-focus'
import { TitleInput } from './component/title-input/title-input'
import { AccountManager } from './component/top-area/menu/components/account-manager'
import { FeedBack } from './component/top-area/menu/components/feedback'
import { FineTuning } from './component/top-area/menu/components/fine-tuning'
import { ReportObstacle } from './component/top-area/menu/components/report-obstacle'
import { SaveHistoryVersion } from './component/top-area/menu/components/save-history-version'
import { MenuContextProvider } from './component/top-area/menu/context/menu-context'
import { RightClickContextProvider, RightClickMenu } from './component/top-area/menu/right-click-menu/right-click-menu'
import { DocumentMissingFontModal } from './component/top-area/tool-font-miss/document-missing-font-modal'
import { TopArea } from './component/top-area/top-area'
import { LocalVariableEditorPopup } from './component/variable/local-variable-editor-popup'
import { DocumentContextProvider } from './context/document-context'
import type { DocumentContextDeps } from './context/document-context-deps'
import { DocumentMissingFontContextProvider } from './context/document-missing-font-context'
import { EditorContextProvider } from './context/editor-context'
import { LibraryComponentStyleRootProvider as LibraryComponentStyleRootProviderV2 } from './context/library-component-style/library-component-style-root-v2'
import { PrototypeInteractionProvider } from './context/prototype-interaction-context'
import { RecentFontsContextProvider } from './context/recent-fonts-context'
import { DocInfoContextProvider } from './context/top-area-context'
import { useDocThumbnail } from './hooks/use-doc-thumbnail'
import { useUserActiveRecord } from './hooks/use-user-active-record'
import classes from './root.module.less'
import { buildMouseEvent, dispatchMouseEventSpecially } from './utils/event'
import { isRightClickAtCanvas } from './utils/is-right-click'
import RightClickSource = Wukong.DocumentProto.RightClickSource

function EditorTopbar() {
    const showTopbar = useTopbarShown()

    const handle = useHandleRightClickEvent(RightClickSource.RIGHT_CLICK_SOURCE_TOP_BAR)

    return showTopbar ? (
        <div className={classnames(classes.TopbarArea)} onMouseDown={handle} data-testid="topbar">
            <TopArea />
        </div>
    ) : null
}

function DesignModeEditorLeftPanel() {
    const showLeftPanel = useLeftPanelShown()
    const leftSideContainerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (!showLeftPanel && leftSideContainerRef.current?.contains(document.activeElement)) {
            ;(document.activeElement as HTMLElement)?.blur()
        }
    }, [showLeftPanel])

    return (
        <div
            className={classnames(classes.LayerPanel, { [classes.HiddenLeftSidePanel]: !showLeftPanel })}
            data-testid={EditorDataTestId.LeftPanel}
            ref={leftSideContainerRef}
        >
            <LeftSidePanel />
        </div>
    )
}
function DesignModeEditorRightPanel() {
    const showRightPanel = useRightPanelShown()

    return showRightPanel ? (
        <div className={classnames(classes.AttributePanel)} data-testid={EditorDataTestId.RightPanel}>
            <TabLoop>
                <Detail />
            </TabLoop>
        </div>
    ) : null
}

function DesignModeEditorCenterStage() {
    const handleCanvas = useHandleRightClickEvent(RightClickSource.RIGHT_CLICK_SOURCE_CANVAS)
    const handleCanvasWithCtrOrCmd = useHandleRightClickEvent(
        RightClickSource.RIGHT_CLICK_SOURCE_CANVAS_WITH_CTR_OR_CMD
    )
    const canvas = useCanvas()
    const command = useCommand()
    const [ctrlClickTriggerContextMenu] = useUserConfigStateV2('ctrlClickTriggerContextMenu')

    const onContextMenu = useCallback(
        (e: React.MouseEvent) => {
            const targetId = (e.target as HTMLElement).id
            if (targetId === RootComponentId.CanvasOverlay) {
                // fix: [WK-15326][WK-15327][WK-16172] windows上右键菜单时，handleCanvasMouseEvent取到的target有问题，导致无法正常dispatchEvent，在这里单独dispatch
                isWindows() &&
                    isRightClick(e) &&
                    dispatchMouseEventSpecially(buildMouseEvent('contextmenu', e), command, canvas)

                const openable = command.DEPRECATED_invokeBridge(CheckCanvasRightMenuOpenable)?.value!
                if (openable) {
                    if ((e.ctrlKey || e.metaKey) && e.button == 2) {
                        handleCanvasWithCtrOrCmd(e)
                    } else {
                        if (isRightClickAtCanvas(e, ctrlClickTriggerContextMenu)) {
                            handleCanvas(e)
                        }
                    }
                }
            }
        },
        [command, ctrlClickTriggerContextMenu, handleCanvas, handleCanvasWithCtrOrCmd, canvas]
    )

    return (
        <AppLayoutWrapper>
            <div className="w-full h-full" onMouseDown={onContextMenu}>
                <CandidateComponentTooltip />
                <DropToCanvasArea />
                <CustomFocus />
                <TitleInput />
                <PrototypeStartingPointNameInput />
                <AIMagic />
                <Comment />
                <CooperationCursorList />
                <CoactorObservingBorder />
                <AutoLayoutFloatInput />
                <MemoryUsageBar />
                <MemoryAlertDialog />
                <HyperlinkEditorPopup />
                <HyperlinkHoverPopup />
                <CanvasSearchHighLightTitle />
            </div>
        </AppLayoutWrapper>
    )
}

function DesignModeEditorBodyArea() {
    const isReadonly = useDocReadonly()
    const historyService = useHistoryService()
    const isHistoryMode = historyService.useZustandStore.use.isHistoryMode()

    return (
        <div className={classes.BodyArea}>
            <DesignModeEditorLeftPanel />
            {!isReadonly && !isHistoryMode && <CandidateComponentPublishDialog />}
            <DesignModeEditorCenterStage />
            <DesignModeEditorRightPanel />
        </div>
    )
}

function DesignModeEditorUI() {
    useDocThumbnail()
    usePickColor()

    const showMultipleRenameModal = useViewState('showMultipleRenameModal')

    return (
        <>
            <DesignModeEditorBodyArea />
            <BatchExportModal />
            <AIGenUI />
            {isAISearchEnabled() ? <AiSearchModal /> : null}
            <AIGenPrototype />
            <ShortcutGuide />
            <RightClickMenu />
            <PrototypeInteractionPopup />
            <DraftLibraryMoveToTeam />
            <CurrentDocLibraryPublishModalV2 />
            <JumpToFigmaModal />
            <JumpToFigmaStyleModal />
            {showMultipleRenameModal && <MultipleRenameModal />}
            <PubMirrorFrame />
            <LocalVariableEditorPopup />
            <AIDesignLintPopupModal />
            <FineTuning />
            <AiComponentDetailModal />
            <SaveHistoryVersion />
            <ReportObstacle />
            <FeedBack />
            <PluginDevelopment />
            <PluginManagement />
            <PluginPopupModal />
            <PluginUIContainer />
            <AccountManager />
            <EditorOnboarding />
            <EditorHelpCenter />
            {featureSwitchManager.isEnabled('chatbot') && <ChatbotModal />}
            {featureSwitchManager.isEnabled('chatbot') && <ChatbotEntry />}
            <MenuPreferenceColorProfieDialog />
            <MenuChangeColorProfieDialog />
            <AiLayoutEntryMenu />
            <AiDuplicateReferenceSelectModal />
            <DocumentMissingFontModal />
            <DevModeBlockedModal />
            <NavigateToDesktopPopup />
        </>
    )
}

function EditorUIRoot() {
    return (
        <DocInfoContextProvider>
            <LibraryComponentStyleRootProviderV2>
                <RightClickContextProvider>
                    <RightClickContextProvider>
                        <MenuContextProvider>
                            <RecentFontsContextProvider>
                                <DocumentMissingFontContextProvider>
                                    <PrototypeInteractionProvider>
                                        <EditorContextProvider>
                                            <div className={classes.UiContainer}>
                                                <MemoryUsageAlertBanner />
                                                <EditorTopbar />
                                                <PlanUpgradingModal />
                                                {/* 需要复用的组件可以添加在这里，下面是根据 EditorType 分化的组件 */}
                                                <EditorUIForEditorType />
                                                {featureSwitchManager.isEnabled('motiff-debugger') && (
                                                    <>
                                                        <MotiffScope />
                                                        <WasmCallMonitor />
                                                    </>
                                                )}
                                            </div>
                                        </EditorContextProvider>
                                    </PrototypeInteractionProvider>
                                </DocumentMissingFontContextProvider>
                            </RecentFontsContextProvider>
                        </MenuContextProvider>
                    </RightClickContextProvider>
                </RightClickContextProvider>
            </LibraryComponentStyleRootProviderV2>
        </DocInfoContextProvider>
    )
}

function EditorUIForEditorType() {
    const editorType = useViewState('editorType')

    useSwitchDevModeTheme()
    useSwitchDevModeLayout()
    useToggleFullscreen()
    useCanvasLayoutChange()
    useUserActiveRecord()

    if (editorType === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV) {
        return <DevModeEditorUI />
    }
    return <DesignModeEditorUI />
}

export const UIRoot = (props: { ctx: DocumentContextDeps }) => {
    const setEditorLoading = useSet(editorLoading$)

    useEffectOnce(() => {
        setEditorLoading(false)

        return () => {
            setEditorLoading(true)
        }
    })

    return (
        <DocumentContextProvider {...props.ctx}>
            <UsedMemoryProvider>
                <EditorUIRoot />
                {featureSwitchManager.isEnabled('async-request-schema-version') && <SchemaVersionUpdater />}
            </UsedMemoryProvider>
        </DocumentContextProvider>
    )
}

function useSwitchDevModeTheme() {
    const htmlThemeRef = useRef<string | null | undefined>()
    const editorType = useViewState('editorType')

    const isDevMode = useMemo(() => editorType === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV, [editorType])

    const enterDevMode = useCallback(() => {
        htmlThemeRef.current = document.documentElement.getAttribute('theme')
        document.documentElement.setAttribute('theme', 'devMode')
        document.documentElement.classList.add('wk-sub-theme-dev')
    }, [])

    const leaveDevMode = useCallback(() => {
        if (!isNil(htmlThemeRef.current)) {
            document.documentElement.setAttribute('theme', htmlThemeRef.current)
        } else if (htmlThemeRef.current == null) {
            document.documentElement.removeAttribute('theme')
            document.documentElement.classList.remove('wk-sub-theme-dev')
        }
        htmlThemeRef.current = undefined
    }, [])

    useEffect(() => {
        if (isDevMode) {
            enterDevMode()
        } else {
            leaveDevMode()
        }
    }, [enterDevMode, isDevMode, leaveDevMode])

    useEffect(() => {
        return leaveDevMode
    }, [leaveDevMode])
}

// [how_to_adjust_layout][3/n] viewState 控制 layout 状态的代码，需要写如下的 hook，并在 useLayoutEffect 中更新之前声明的状态

// 切换模式，修改布局
function useSwitchDevModeLayout() {
    const editorType = useViewState('editorType')
    const rightPanelWidth = useMemo(() => {
        if (editorType === Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV) {
            return getDefaultGlobalConfigsWithStorage2().devModeRightPanelWidth
        } else {
            return DEFAULT_RIGHT_PANEL_WIDTH
        }
    }, [editorType])
    const updateRightPanelWidth = useSetRightPanelWidth()
    useLayoutEffect(() => {
        updateRightPanelWidth(rightPanelWidth)
    }, [rightPanelWidth, updateRightPanelWidth])
}

// 切换全屏和侧边栏，修改布局
function useToggleFullscreen() {
    const setTopbarShown = useSetTopbarShown()
    const setLeftPanelShown = useSetLeftPanelShown()
    const setRightPanelShwon = useSetRightPanelShown()

    const fullscreen = useViewState('fullscreen')
    const showSidebar = useViewState('showSidebar')

    useLayoutEffect(() => {
        setTopbarShown(!fullscreen)
        setRightPanelShwon(!fullscreen)
        setLeftPanelShown(!fullscreen && !!showSidebar)
    }, [setLeftPanelShown, setRightPanelShwon, setTopbarShown, fullscreen, showSidebar])
}

// [how_to_adjust_layout][4/n] 根据四周的 margin 值，更新 canvas 大小（包括 wasm 和 dom）。这里需要了解但不需要改动，且无需在这个改变 layout 状态的地方都更新 canvas

// 根据布局的修改，更新画布区
function useCanvasLayoutChange() {
    const command = useCommand()
    const canvas = useCanvas()

    const marginTop = useTopMargin()
    const marginLeft = useLeftMargin()
    const marginRight = useRightMargin()
    const marginBottom = useBottomMargin()

    const previewMarginTop = usePrevious(marginTop)
    const previewMarginLeft = usePrevious(marginLeft)

    useLayoutEffect(() => {
        const dTop = marginTop - (previewMarginTop ?? 0)
        const dLeft = marginLeft - (previewMarginLeft ?? 0)
        command.invoke(cmdResizeCanvas, canvas, dLeft, dTop)
        // NOTE: marginRight, marginBottom 变化也需要更新 canvas size，但因为现在 width 是通过 getBoundingRect 拿的，无法订阅，所以需要定义依赖来触发 Effect
    }, [canvas, command, marginTop, previewMarginTop, marginLeft, previewMarginLeft, marginRight, marginBottom])
}
