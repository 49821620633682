import classnames from 'classnames'
import ReactDOM from 'react-dom'
import classes from './loading-mask.module.less'

export interface HistoryLoadingProps {
    className?: string
    size: number
    dataTestId?: string
}
export function HistoryLoading(props: HistoryLoadingProps) {
    return (
        <div className={classnames(classes.mask, [props.className])} data-testid={props.dataTestId}>
            <div className={classes.loading} style={{ width: props.size, height: props.size }}></div>
        </div>
    )
}

export interface LoadingMaskProps extends Pick<HistoryLoadingProps, 'dataTestId'> {
    isLoading: boolean
}
export function LoadingMask({ isLoading, ...otherProps }: LoadingMaskProps) {
    return isLoading
        ? ReactDOM.createPortal(
              <HistoryLoading className={classes.loadingMask} size={32} {...otherProps} />,
              document.body
          )
        : null
}

export function LoadingLoad(props: Pick<HistoryLoadingProps, 'dataTestId'>) {
    return <HistoryLoading size={16} {...props} />
}
