import { GetComputedColorVariableCommand, Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import React, { useMemo, useRef, useState } from 'react'
import { useMount } from 'react-use'
import {
    MonoIconCommonRemove16,
    MonoIconPanelLink16,
    OmitToJSON,
    Tooltip,
    WKIconButton,
} from '../../../../../../../ui-lib/src'
import { CommitType } from '../../../../../document/command/commit-type'
import { SolidPaint } from '../../../../../document/node/node'
import { useCommand } from '../../../../context/document-context'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { ScrubbableInputPercent } from '../../../atom/inputs/scrubbable-input-percent'
import { ColorVarIcon } from '../../../color-var-item/color-var-icon'
import { ColorVarName } from '../../../color-var-item/color-var-name'
import { PaintIconColor } from '../../../paint-icon-color/paint-icon-color'
import { ColorSpace } from '../../blend/color-picker/utils/color-translate'
import { ColorPanelSubset } from '../color-panel/color-panel-subset'
import { ColorInteractionFrom } from '../type'
import classes from './paint-color-stop.module.less'
import { translation } from './paint-color-stop.translation'

interface PaintColorStopProps {
    colorStop: OmitToJSON<Wukong.DocumentProto.ColorStop>
    colorSpace: ColorSpace
    hideDeleteIcon: boolean
    isSelect: boolean
    isOpen: boolean
    onClickEmptySpace: () => void
    onClickColorBlock: () => void
    onClickDelete: () => void
    onChangeColorStop: (value: OmitToJSON<Wukong.DocumentProto.ColorStop>, options?: InputOptionsForUndoSquash) => void
}
export function PaintColorStop(props: PaintColorStopProps) {
    const {
        colorStop,
        colorSpace,
        hideDeleteIcon,
        isSelect,
        isOpen,
        onClickEmptySpace,
        onClickColorBlock,
        onClickDelete,
        onChangeColorStop,
        ...otherProps
    } = props
    const containerRef = useRef<HTMLDivElement>(null)
    const [mounted, setMounted] = useState(false)
    const command = useCommand()

    const colorVariable = useMemo(() => {
        const nodeId = colorStop.colorVar?.value.alias
        if (!nodeId) {
            return
        }
        const res = command.invokeBridge(CommitType.Noop, GetComputedColorVariableCommand, {
            nodeId,
        })
        return res
    }, [colorStop.colorVar?.value.alias, command])

    const paint = useMemo(() => {
        return {
            type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
            color: colorVariable?.color ?? colorStop.color,
            opacity: colorStop.color.a / 255,
            visible: true,
            colorVar: colorStop.colorVar,
        }
    }, [colorStop.color, colorStop.colorVar, colorVariable?.color])

    const onClickContainer = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget) {
            onClickEmptySpace()
        }
    }

    useMount(() => {
        setMounted(true)
    })

    return (
        <>
            <div
                ref={containerRef}
                className={classnames(classes.container, isSelect && classes.select)}
                onClick={onClickContainer}
                data-testid="paint-color-stop"
                {...otherProps}
            >
                <ScrubbableInputPercent
                    scrubbingDisabled
                    className={classes.positonInput}
                    value={colorStop.position}
                    onChange={(v, options) => {
                        onChangeColorStop({ ...colorStop, position: (v as number) / 100 }, options)
                    }}
                    min={0}
                    max={100}
                    resolution={1}
                    isInteger
                />

                {paint.colorVar ? (
                    <div className={classes.colorVar}>
                        <div className={classes.varIconName}>
                            <div className={classes.colorVarBlockContainer}>
                                <ColorVarIcon
                                    rgb={paint.color}
                                    colorSpace={colorSpace}
                                    opacity={paint.opacity ?? undefined}
                                    onMouseDown={onClickColorBlock}
                                />
                            </div>
                            <ColorVarName
                                name={colorVariable?.name ?? ''}
                                onClick={onClickColorBlock}
                                className={classes.colorVarTag}
                                nameClassName={isSelect ? undefined : classes.tagName}
                                selected={isSelect}
                            />
                        </div>
                        <Tooltip title={translation('DetachVariable')}>
                            <WKIconButton
                                preventFocus
                                icon={<MonoIconPanelLink16 />}
                                onClick={() => {
                                    onChangeColorStop(
                                        { ...colorStop, colorVar: undefined },
                                        { commitType: CommitType.CommitUndo }
                                    )
                                }}
                                className={classes.button}
                                data-testid="color-var-cut-link-button"
                            />
                        </Tooltip>
                    </div>
                ) : (
                    <div className={classes.color}>
                        <PaintIconColor
                            paint={paint as SolidPaint}
                            colorSpace={colorSpace}
                            onChangeColor={(v) => {
                                onChangeColorStop({ ...colorStop, color: { ...v, a: colorStop.color.a } })
                            }}
                            onMouseDownIcon={onClickColorBlock}
                            isWidth={88}
                            onMouseDown={(e) => e.stopPropagation()}
                        />
                        <ScrubbableInputPercent
                            className={classes.opcityInput}
                            value={paint.opacity}
                            onChange={(v, options) => {
                                onChangeColorStop(
                                    { ...colorStop, color: { ...colorStop.color, a: ((v as number) / 100) * 255 } },
                                    options
                                )
                            }}
                            min={0}
                            max={100}
                        />
                    </div>
                )}
                <div className={classes.delete}>
                    {hideDeleteIcon ? null : (
                        <WKIconButton
                            icon={<MonoIconCommonRemove16 />}
                            onClick={onClickDelete}
                            data-testid="paint-color-stop-delete-button"
                        />
                    )}
                </div>
            </div>

            {isOpen && mounted && (
                <ColorPanelSubset
                    from={ColorInteractionFrom.COLOR_STOP}
                    position={containerRef.current?.getBoundingClientRect()!}
                    onCancel={onClickColorBlock}
                    paint={paint}
                    onChangeColor={(v, options) => {
                        onChangeColorStop(
                            { ...colorStop, color: { ...v, a: colorStop.color.a }, colorVar: undefined },
                            options
                        )
                    }}
                    onChangeOpacity={(v, options) => {
                        onChangeColorStop(
                            {
                                ...colorStop,
                                color: { ...colorStop.color, a: (v as number) * 255 },
                                colorVar: undefined,
                            },
                            options
                        )
                    }}
                    onChangeColorVar={(v) => {
                        onChangeColorStop({ ...colorStop, colorVar: v })
                    }}
                    styleId={undefined}
                    onChangePaintType={() => {}}
                    onChangeBlendMode={() => {}}
                    onChangeImagePaint={() => {}}
                    onChangeColorStops={() => {}}
                    onChangeTransform={() => {}}
                    enterClose={() => {}}
                    onClickAddIcon={() => {}}
                    onClickEditStyle={() => {}}
                    onChangePaints={undefined}
                    onClickCreateStyleButton={undefined}
                />
            )}
        </>
    )
}
