import { UpdateDevModeInspectTypeCommand, Wukong } from '@wukong/bridge-proto'
import { useCallback, useRef } from 'react'
import { Select } from '../../../../../../../ui-lib/src'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'
import { DevModeCodeTypePicker } from '../comp/dev-mode-code-type-picker'
import { DevModeInspectCode } from '../dev-mode-inspect-code'
import { DevModeInspectLayout } from '../dev-mode-inspect-layout'
import style from '../inspect.module.less'
import { translation } from './index.translation'

import DevModeInspectType = Wukong.DocumentProto.DevModeInspectType

export function DevModeInspectTypeSelectPanel() {
    const devModeInspectType = useViewState('devModeInspectTypeViewState')

    const command = useCommand()

    const onClickCode = useCallback(() => {
        command.DEPRECATED_invokeBridge(UpdateDevModeInspectTypeCommand, {
            inspectType: DevModeInspectType.DEV_MODE_INSPECT_TYPE_CODE,
        })
    }, [command])

    const onClickProperty = useCallback(() => {
        command.DEPRECATED_invokeBridge(UpdateDevModeInspectTypeCommand, {
            inspectType: DevModeInspectType.DEV_MODE_INSPECT_TYPE_PROPERTY,
        })
    }, [command])
    const headRef = useRef<HTMLDivElement>(null)

    return (
        <>
            <div ref={headRef} className={style.panel} data-testid="dev-mode-inspect-type-select-panel">
                <div className={style.panelTitle}>
                    <div className={style.title}>
                        <DevModeInspectTypeSelect
                            InspectType={devModeInspectType?.inspectType}
                            onClickCode={onClickCode}
                            onClickProperty={onClickProperty}
                            dataTestId="dev-mode-inspect-type-select-input"
                        />
                    </div>
                    {devModeInspectType?.inspectType === DevModeInspectType.DEV_MODE_INSPECT_TYPE_CODE ? (
                        <div className={style.panelCopyActionControls}>
                            <DevModeCodeTypePicker headRef={headRef} />
                        </div>
                    ) : null}
                </div>
                {/* 下面内容需要和「属性」「代码」的标题展示在一块内 */}
                {devModeInspectType?.inspectType === DevModeInspectType.DEV_MODE_INSPECT_TYPE_CODE && (
                    <DevModeInspectCode />
                )}
                {devModeInspectType?.inspectType === DevModeInspectType.DEV_MODE_INSPECT_TYPE_PROPERTY && (
                    <DevModeInspectLayout />
                )}
            </div>
        </>
    )
}

export interface DevModeInspectTypeSelectProps {
    InspectType?: DevModeInspectType
    onClickCode?: () => void
    onClickProperty?: () => void
    dataTestId?: string
}
export function DevModeInspectTypeSelect(props: DevModeInspectTypeSelectProps) {
    const { InspectType, onClickCode, onClickProperty } = props

    const onChangeSelect = useCallback(
        (value: DevModeInspectType) => {
            switch (value) {
                case DevModeInspectType.DEV_MODE_INSPECT_TYPE_CODE:
                    return onClickCode?.()
                case DevModeInspectType.DEV_MODE_INSPECT_TYPE_PROPERTY:
                    return onClickProperty?.()
                default:
                    return
            }
        },
        [onClickCode, onClickProperty]
    )

    return (
        <Select.MinimalSingleLevel
            value={InspectType}
            label={
                InspectType === DevModeInspectType.DEV_MODE_INSPECT_TYPE_CODE
                    ? translation('EKRUlL')
                    : translation('MboXZt')
            }
            onChange={onChangeSelect}
            weight="semibold"
            dataTestIds={{ triggerFocus: props.dataTestId }}
            minWidth={80}
        >
            <Select.MinimalSingleLevel.Option value={DevModeInspectType.DEV_MODE_INSPECT_TYPE_CODE} backwardIcon={''}>
                {translation('EKRUlL')}
            </Select.MinimalSingleLevel.Option>
            <Select.MinimalSingleLevel.Option
                value={DevModeInspectType.DEV_MODE_INSPECT_TYPE_PROPERTY}
                backwardIcon={''}
            >
                {translation('MboXZt')}
            </Select.MinimalSingleLevel.Option>
        </Select.MinimalSingleLevel>
    )
}
