import {
    ContractPeriod,
    CurrencyType,
    type ContractSpecification,
    type FeeUnitVO,
    type ProductVO,
    SeatType,
} from '../../../kernel/interface/type'

// 获取单价
export function findProductFeeUnit(options: {
    allProducts?: readonly ProductVO[]
    seatType: SeatType
    currencyType: CurrencyType
    contractPeriod: ContractPeriod
    specification: ContractSpecification
}): FeeUnitVO | undefined {
    for (const product of options.allProducts || []) {
        if (
            product.specification === options.specification &&
            ((product.seatType === 'DEV' && options.seatType === SeatType.developer) ||
                (product.seatType === 'DESIGN' && options.seatType === SeatType.designer))
        ) {
            for (const feeUnit of product.feeUnits) {
                if (feeUnit.period === options.contractPeriod && feeUnit.price.currency === options.currencyType) {
                    return feeUnit
                }
            }
        }
    }
}

// 根据合约周期获取月数
export function getMonthsByContractPeriod(contractPeriod: ContractPeriod) {
    switch (contractPeriod) {
        case ContractPeriod.Month:
            return 1
        case ContractPeriod.Year:
            return 12
        default:
            return 0
    }
}
