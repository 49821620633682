import {
    BackendTypeCommand,
    GetCanvasInfoCommand,
    GetGPUWorkaroundCommand,
    LoseGpu,
    NotifyCanvasSizeChanged,
    ReadMagnifierBufferCallbackCommand,
    ReadMagnifierBufferCommand,
    ReadSyncWGPUCommand,
    RestoreGpu,
    TraceGpuLostAndCrashCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { toNumber } from 'lodash-es'
import { isDocumentVisible } from '../../../../util/src'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { EffectController } from '../../../../util/src/effect-controller'
import { CommandInvoker } from '../../document/command/command-invoker'
import { CommitType } from '../../document/command/commit-type'
import { EmBridge } from '../../kernel/bridge/em-bridge'
import { WkCLog } from '../../kernel/clog/wukong/instance'
import { Sentry } from '../../kernel/sentry'
import { CanvasStateType } from '../../kernel/service/canvas-types'
import { EditorService } from '../../kernel/service/editor-service'
import { ServiceClass } from '../../kernel/util/service-class'
import { SessionStorageKey } from '../../web-storage/session-storage/config'
import { enhancedSessionStorage } from '../../web-storage/session-storage/storage'
import { WK } from '../../window'
import { CanvasInspector } from './canvas-inspector'
import { GPUBroadcast } from './gpu-broadcast'
import { detectCompressedTextureSupported } from './gpu-texture'
import { detectWebgpuSyncRead, findWebGLWorkaround, findWebGPUWorkaround } from './gpu-workaround'
import { getVideoCardInfo, GraphicCardInfo } from './utils'

export const enum ShouldReloadResult {
    ReloadByTime,
    ReloadByWebGPU,
    DoNotReload,
}

interface WebGLListener {
    canvas: HTMLCanvasElement
    onWebGLLost: (e: Event) => void
    onWebGLRestore: () => void
}

class DevicePixelRatioObserver {
    private callback: (() => void) | null = null
    private remover: (() => void) | null = null

    start(callback: () => void) {
        if (this.callback) {
            throw new Error('DevicePixelRatioObserver already started')
        }

        // 第一次不触发 callback
        this.updateDevicePixelRatio()
        this.callback = callback
    }

    stop() {
        this.callback = null
        if (this.remover) {
            this.remover()
            this.remover = null
        }
    }

    private updateDevicePixelRatio() {
        if (this.remover != null) {
            this.remover()
        }
        const mqString = `(resolution: ${window.devicePixelRatio}dppx)`
        const media = matchMedia(mqString)
        const listener = this.updateDevicePixelRatio.bind(this)
        media.addEventListener('change', listener)
        this.remover = () => {
            media.removeEventListener('change', listener)
        }

        this.callback?.()
    }
}

export class GPUService extends ServiceClass {
    private gpuWorkaround_: Wukong.DocumentProto.IGPUWorkaround = {
        glViewportBug: false,
        glDrawClearReorderingBug: false,
        glUnexpectedCompositingBug: false,
        mobileOptimizations: false,
    }

    private canvasInspector_: CanvasInspector
    private broadcast_: GPUBroadcast
    private graphicCardInfo_: GraphicCardInfo | null = null
    private webGLListener: WebGLListener | null = null
    private canvasResizeObserver: ResizeObserver | null = null
    private dprObserver: DevicePixelRatioObserver | null = null

    constructor(protected override bridge: EmBridge, private invoker: CommandInvoker, controller: EffectController) {
        super(bridge)
        controller.onCleanup(() => this.destroy())

        this.canvasInspector_ = new CanvasInspector({
            getCanvasState: () => bridge.currentEditorService.getCanvasState(),
            startSetTimeout: (f, ms) => this.startSetTimeout(f, ms),
            getHeap: () => bridge.currentEditorService.HEAPU8,
            log: (desc, arg) => WkCLog.throttleLog(desc, arg),
        })
        this.broadcast_ = new GPUBroadcast(bridge, invoker, controller)
        this.graphicCardInfo_ = getVideoCardInfo()
        if (this.graphicCardInfo_) {
            Sentry.setTag('gpu.vendor', this.graphicCardInfo_.vendor)
            Sentry.setTag('gpu.renderer', this.graphicCardInfo_.renderer)
            WkCLog.setVendor(this.graphicCardInfo_.vendor)
            WkCLog.setRenderer(this.graphicCardInfo_.renderer)
        }
        detectWebgpuSyncRead()

        this.bindJsCall(GetGPUWorkaroundCommand, () => {
            return this.gpuWorkaround_
        })

        this.bindJsCall(BackendTypeCommand, () => {
            const state = this.bridge.currentEditorService.getCanvasState()
            if (state?.type == CanvasStateType.WebGL) {
                return Wukong.DocumentProto.Ret_BackendTypeCommand.create({
                    type: Wukong.DocumentProto.BackendType.BACKEND_TYPE_G_L,
                })
            } else if (state?.type == CanvasStateType.WebGPU) {
                return Wukong.DocumentProto.Ret_BackendTypeCommand.create({
                    type: Wukong.DocumentProto.BackendType.BACKEND_TYPE_W_G_P_U,
                    wgpuAdapterId: state.adapterId,
                    wgpuDeviceId: state.deviceId,
                    wgpuQueueId: state.queueId,
                    // 对于 web 而言，instanceId 无意义，但不能为 0
                    wgpuInstanceId: 1,
                })
            } else {
                return Wukong.DocumentProto.Ret_BackendTypeCommand.create({
                    type: Wukong.DocumentProto.BackendType.BACKEND_TYPE_NULL,
                })
            }
        })

        this.bindJsCall(GetCanvasInfoCommand, () => {
            const state = this.bridge.currentEditorService.getCanvasState()
            return Wukong.DocumentProto.CanvasInfo.create({
                width: state?.canvas?.width ?? 0,
                height: state?.canvas?.height ?? 0,
                dpr: window.devicePixelRatio,
                visible: isDocumentVisible(),
            })
        })

        this.bindJsCall(ReadMagnifierBufferCommand, (arg) => this.readMagnifierBuffer(arg))
        this.bindJsCall(ReadSyncWGPUCommand, (arg) => this.readSyncWGPU(arg))

        this.bindJsCall(TraceGpuLostAndCrashCommand, (arg) => {
            WkCLog.log('WK_RENDER_GPU_LOST_AND_CRASH', {
                traceEventName: 'WARN_WEBGL_CONTEXT_LOST_AND_CRASH',
                backend: this.bridge.currentEditorService.getBackendType(),
                vram: arg.vram,
                tabs: this.broadcast_.tabs,
                totalVRAM: this.broadcast_.totalVRAM,
                reload:
                    GPUService.shouldReload(this.bridge.currentEditorService, new Date().getTime()) !=
                    ShouldReloadResult.DoNotReload,
            })
        })

        WK.getCanvas = () => {
            return this.bridge.currentEditorService.getCanvasState()?.canvas ?? null
        }
    }

    public override destroy(): void {
        this.canvasInspector_.destroy()
        this.broadcast_.destroy()
        if (this.webGLListener) {
            this.webGLListener.canvas.removeEventListener('webglcontextlost', this.webGLListener.onWebGLLost)
            this.webGLListener.canvas.removeEventListener('webglcontextrestored', this.webGLListener.onWebGLRestore)
        }
        this.webGLListener = null

        if (this.canvasResizeObserver) {
            this.canvasResizeObserver.disconnect()
        }
        this.canvasResizeObserver = null

        if (this.dprObserver) {
            this.dprObserver.stop()
        }
        this.dprObserver = null

        delete WK.getCanvas

        super.destroy()
    }

    public getGraphicCardInfo() {
        return this.graphicCardInfo_
    }

    public static shouldReload(editorService: EditorService, time: number): ShouldReloadResult {
        let lastReloadTime = toNumber(enhancedSessionStorage.getItem(SessionStorageKey.ReloadTimeKey))
        if (Number.isNaN(lastReloadTime)) {
            lastReloadTime = 0
        }
        // 距离上次刷新在三分钟以上，则可以刷新
        if (time - lastReloadTime > 3 * 60 * 1000) {
            return ShouldReloadResult.ReloadByTime
        }
        // 如果是 WebGPU 则也可以刷新
        if (editorService.getBackendType() == 'WebGPU') {
            return ShouldReloadResult.ReloadByWebGPU
        }
        return ShouldReloadResult.DoNotReload
    }

    public afterInitCanvas(signal: TraceableAbortSignal) {
        const state = this.bridge.currentEditorService.getCanvasState()

        Sentry.setTag('gpu.backend', this.bridge.currentEditorService.getBackendType())

        if (state?.canvas) {
            const canvas = state.canvas
            this.canvasResizeObserver = new ResizeObserver(() => {
                signal.throwIfAborted()
                this.invoker.invokeBridge(CommitType.Noop, NotifyCanvasSizeChanged)
            })
            this.canvasResizeObserver.observe(canvas)
        }

        this.dprObserver = new DevicePixelRatioObserver()
        this.dprObserver.start(() => {
            this.invoker.invokeBridge(CommitType.Noop, NotifyCanvasSizeChanged)
        })

        if (state?.type == CanvasStateType.WebGL) {
            // context lost
            const canvas = state.canvas
            this.webGLListener = {
                canvas: state.canvas,
                onWebGLLost: (e: Event) => {
                    e.preventDefault()
                    this.invoker.invokeBridge(CommitType.Noop, LoseGpu)
                    canvas.hidden = true
                    WkCLog.log('WK_RENDER_WEBGL_CONTEXT_LOST', {
                        tabVisible: isDocumentVisible(),
                    })
                },
                onWebGLRestore: () => {
                    this.invoker.invokeBridge(CommitType.Noop, RestoreGpu)
                    canvas.hidden = false
                    WkCLog.log('WK_RENDER_WEBGL_CONTEXT_RESTORED', {
                        tabVisible: isDocumentVisible(),
                    })
                },
            }
            state.canvas.addEventListener('webglcontextlost', this.webGLListener.onWebGLLost)
            state.canvas.addEventListener('webglcontextrestored', this.webGLListener.onWebGLRestore)

            this.gpuWorkaround_ = findWebGLWorkaround(state.version)
            detectCompressedTextureSupported()
        } else if (state?.type == CanvasStateType.WebGPU) {
            // 这里不要 await
            this.waitForWebGPULost()
            this.gpuWorkaround_ = findWebGPUWorkaround()
        }
    }

    private async waitForWebGPULost() {
        const state = this.bridge.currentEditorService.getCanvasState()
        if (state?.type != CanvasStateType.WebGPU) {
            return
        }
        if (!state.device) {
            return
        }

        const value = await state.device.lost
        if (this.isDestroy) {
            return
        }

        if (value.reason == 'destroyed') {
            // 主动 destroy 不用管
            return
        }

        this.invoker.invokeBridge(CommitType.Noop, LoseGpu)
        state.canvas.hidden = true
        WkCLog.log('WK_RENDER_WEBGPU_DEVICE_LOST', {
            tabVisible: isDocumentVisible(),
        })

        await this.bridge.currentEditorService.onWebGPUDeviceLost()
        if (this.isDestroy) {
            return
        }

        // 不需要 await
        this.restoreWebGPU()
    }

    private async restoreWebGPU() {
        const state = this.bridge.currentEditorService.getCanvasState()
        if (state?.type != CanvasStateType.WebGPU) {
            return
        }
        if (!state.device) {
            // 没有 restore 成功
            return
        }

        this.invoker.invokeBridge(CommitType.Noop, RestoreGpu)
        state.canvas.hidden = false
        WkCLog.log('WK_RENDER_WEBGPU_DEVICE_RESTORED', {
            tabVisible: isDocumentVisible(),
        })

        // 不需要 await
        this.waitForWebGPULost()
    }

    private readMagnifierBuffer(arg: Wukong.DocumentProto.IArg_ReadMagnifierBuffer) {
        this.canvasInspector_.readMagnifierBuffer(
            {
                width: arg.width,
                height: arg.height,
                dataOffset: arg.data,
                bufferHandle: arg.bufferHandle,
            },
            () => {
                this.invoker.invokeBridge(CommitType.Noop, ReadMagnifierBufferCallbackCommand, {
                    data: arg.data,
                    size: arg.width * arg.height * 4,
                    callback: arg.callback,
                })
            }
        )
    }

    private readSyncWGPU(arg: Wukong.DocumentProto.IArg_ReadSyncWGPU) {
        this.canvasInspector_.readSyncWGPU({
            x: arg.x,
            y: arg.y,
            width: arg.width,
            height: arg.height,
            dataOffset: arg.data,
            textureHandle: arg.textureHandle,
        })
    }
}
