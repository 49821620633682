import { useCallback, useEffect, useRef } from 'react'
import { isRightClick } from '../is-right-click'

export interface PointerCaptureProps {
    captureStart?: (e: React.PointerEvent) => void
    capturing: (e: React.PointerEvent) => void
    captureEnd?: (e: React.PointerEvent | PointerEvent) => void
    closeCapture?: boolean | ((e: React.PointerEvent) => boolean)
    onPointerDown?: (e: React.PointerEvent) => void
    onPointerMove?: (e: React.PointerEvent) => void
    onPointerUp?: (e: React.PointerEvent) => void
}
export function usePointerCapture(props: PointerCaptureProps) {
    const { captureStart, capturing, captureEnd, closeCapture, onPointerDown, onPointerMove, onPointerUp } = props
    const capturePointerId = useRef<number>(NaN)

    const pointerdown = useCallback(
        (e: React.PointerEvent) => {
            onPointerDown?.(e)
            const skipCapture = (typeof closeCapture === 'function' ? closeCapture(e) : closeCapture) || isRightClick(e)
            if (!skipCapture) {
                const element = e.currentTarget as HTMLDivElement
                element.setPointerCapture(e.pointerId)
                capturePointerId.current = e.pointerId
                captureStart?.(e)
            }
        },
        [captureStart, closeCapture, onPointerDown]
    )

    const pointermove = useCallback(
        (e: React.PointerEvent) => {
            onPointerMove?.(e)
            const element = e.currentTarget as HTMLDivElement
            if (element.hasPointerCapture(e.pointerId)) {
                capturing?.(e)
            }
        },
        [capturing, onPointerMove]
    )

    const pointerup = useCallback(
        (e: React.PointerEvent) => {
            onPointerUp?.(e)
            const element = e.currentTarget as HTMLDivElement
            if (element.hasPointerCapture(e.pointerId)) {
                element.releasePointerCapture(e.pointerId)
                captureEnd?.(e)
                capturePointerId.current = NaN
            }
        },
        [captureEnd, onPointerUp]
    )

    const onLostPointerCapture = useCallback(
        (e: PointerEvent) => {
            if (e.pointerId === capturePointerId.current) {
                captureEnd?.(e)
            }
            capturePointerId.current = NaN
        },
        [captureEnd]
    )

    useEffect(() => {
        document.addEventListener('lostpointercapture', onLostPointerCapture, false)
        return () => {
            document.removeEventListener('lostpointercapture', onLostPointerCapture)
        }
    }, [onLostPointerCapture])

    return { pointerdown, pointerup, pointermove }
}
