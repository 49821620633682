import { WkCLog } from '../../../kernel/clog/wukong/instance'
import { SessionStorageKey } from '../config'

type Key = SessionStorageKey | { key: SessionStorageKey; suffix: string }

/**
 * SessionStorage 的简易封装
 * 严格管理 key 的写入
 * 限定 api 使用
 */
class EnhancedSessionStorage {
    private storage: Storage = window.sessionStorage

    /**
     * 拼接 key 和 suffix
     * @param key
     * @returns
     */
    private combineKey(key: Key): string {
        if (typeof key === 'string') {
            return key
        }
        return key.key + key.suffix
    }

    /**
     * 配置一个 kv 的存储
     * @param key
     * @param value
     * @returns
     */
    public setItem(key: Key, value: string): void {
        try {
            this.storage.setItem(this.combineKey(key), value)
        } catch (e) {
            WkCLog.log(`WebStorage set item failed, type is: SessionStorage, error: ${e}`)
        }
    }

    /**
     * 获取一个 kv 的存储，没找到的结果为 null（如果填入 defaultValue 则为 defaultValue）
     * @param key
     * @param defaultValue
     * @returns
     */
    public getItem(key: Key): string | null
    public getItem(key: Key, defaultValue: string): string
    public getItem(key: Key, defaultValue?: string): string | null {
        const value = this.storage.getItem(this.combineKey(key))

        if (value === null && defaultValue !== undefined) {
            return defaultValue
        }

        return value
    }

    /**
     * 清除某个 key
     * @param key
     */
    public removeItem(key: Key): void {
        this.storage.removeItem(this.combineKey(key))
    }

    /**
     * 获取当前存储下的 keys
     */
    public getKeys(): string[] {
        const keys = []
        for (let i = 0; i < this.storage.length; i++) {
            keys.push(this.storage.key(i) as string)
        }
        return keys
    }

    /**
     * Danger
     * 清除所有 key
     */
    public clear(): void {
        this.storage.clear()
    }
}

export const enhancedSessionStorage = new EnhancedSessionStorage()
