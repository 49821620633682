import { translation } from './gradient-picker.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { IconRotate, IconStrokePointChnage, Tooltip } from '../../../../../../../ui-lib/src'
import { cmdSelectedGradientColorStopIndex } from '../../../../../document/command/document-command'
import { ColorStop, RGB, RGBA, Transform } from '../../../../../document/node/node'
import { Matrix } from '../../../../../document/util/matrix'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'
import { IconButton } from '../../../atom/button/icon-button'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { useRenderColorSpace } from '../../../color-profile'
import { BlendColor } from '../blend-color/blend-color'
import { GradientArea } from './gradient-area'
import style from './gradient-picker.module.less'
import { getMinPositionIndex } from './utils'
import PaintType = Wukong.DocumentProto.PaintType

export interface GradientPickerProps {
    type: PaintType

    colorStops: ColorStop[]
    transform: Transform
    onChangeColorStops: (colorStops: ColorStop[], options?: InputOptionsForUndoSquash) => void
    onChangeTransform?: (transform: Transform) => void
}
export function GradientPicker(props: GradientPickerProps) {
    const command = useCommand()
    const { type, colorStops, transform, onChangeColorStops, onChangeTransform } = props
    const selectedGradientColorStopIndex = useViewState('selectedGradientColorStopIndex') || 0
    const colorSpace = useRenderColorSpace()

    const firstOpen = useRef<boolean>(true)

    const activeColorStop = useMemo(() => {
        return colorStops[selectedGradientColorStopIndex] ?? colorStops[getMinPositionIndex(colorStops)]
    }, [colorStops, selectedGradientColorStopIndex])

    const changeActiveColorStopColor = useCallback(
        (value: { [key: string]: any }, options?: InputOptionsForUndoSquash) => {
            const newColorStops = colorStops.map((colorStop, index) => {
                if (index === selectedGradientColorStopIndex) {
                    const color: RGBA = Object.assign(colorStop.color, value)
                    const newColorStop: ColorStop = Object.assign(colorStop, { color })
                    return newColorStop
                }
                return colorStop
            })
            onChangeColorStops?.(newColorStops, options)
        },
        [colorStops, onChangeColorStops, selectedGradientColorStopIndex]
    )

    const onChangeColor = useCallback(
        (value: RGB, options?: InputOptionsForUndoSquash) => {
            changeActiveColorStopColor(value, options)
        },
        [changeActiveColorStopColor]
    )

    const onChangeOpacity = useCallback(
        (value: number, options?: InputOptionsForUndoSquash) => {
            changeActiveColorStopColor({ a: value * 255 }, options)
        },
        [changeActiveColorStopColor]
    )

    const onClickSwitch = useCallback(() => {
        const newColorStops: ColorStop[] = colorStops.map((colorStop) =>
            Object.assign({}, colorStop, { position: 1 - colorStop.position })
        )
        onChangeColorStops?.(newColorStops)
        command.commitUndo()
    }, [colorStops, command, onChangeColorStops])

    const onClickRotate = useCallback(() => {
        let center = { x: 0, y: 0 }
        if (type == PaintType.PAINT_TYPE_GRADIENT_LINEAR) {
            center.x = 0.5
            center.y = 0.5
        } else {
            center.x = 0.75
            center.y = 0.5
        }

        const matrix = new Matrix(transform)
        center = matrix.mapVector(center)

        const newTransform = new Matrix(transform)
            .translateX(-center.x)
            .translateY(-center.y)
            .rotate(Math.PI / 2)
            .translateX(center.x)
            .translateY(center.y)
            .valueOf()
        onChangeTransform?.(newTransform)
        command.commitUndo()
    }, [type, transform, onChangeTransform, command])

    useEffect(() => {
        if (firstOpen.current) {
            firstOpen.current = false
            const index = getRightColorStopIndex(colorStops)
            const time_out = setTimeout(() => command.invoke(cmdSelectedGradientColorStopIndex, index), 0)
            return () => clearTimeout(time_out)
        }
    }, [colorStops, command])

    return (
        <div>
            <div className={style.container}>
                <GradientArea
                    colorSpace={colorSpace}
                    isCycle={type == PaintType.PAINT_TYPE_GRADIENT_ANGULAR}
                    colorStops={colorStops}
                    selectedIndex={selectedGradientColorStopIndex}
                    onChangeColorStops={onChangeColorStops}
                />
                <Tooltip title={translation('Flip')}>
                    <IconButton
                        selected={false}
                        icon={<IconStrokePointChnage />}
                        onClick={onClickSwitch}
                        dataTestId="icon-stroke-point-change"
                    />
                </Tooltip>
                <Tooltip title={translation('Rotate') + ' 90°'}>
                    <IconButton
                        className={style.resetIcon}
                        selected={false}
                        icon={<IconRotate />}
                        onClick={onClickRotate}
                        dataTestId="icon-rotate"
                    />
                </Tooltip>
            </div>
            <BlendColor
                color={activeColorStop.color}
                colorSpace={colorSpace}
                opacity={activeColorStop.color.a / 255}
                onChangeColor={onChangeColor}
                onChangeOpacity={onChangeOpacity}
            />
        </div>
    )
}

export function getRightColorStopIndex(colorStops: ColorStop[]) {
    let index = 0,
        position = 1
    colorStops.forEach((colorStop, _index) => {
        if (colorStop.position < position) {
            position = colorStop.position
            index = _index
        }
    })
    return index
}
