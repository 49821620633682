import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    NoColorsAvailable: '没有颜色样式',
    BrowseLibraries: '浏览团队组件库',
    NoMatchesFound: '没有找到该颜色样式',
    GoToStyle: '打开样式所在文件',
    EditStyle: '编辑样式',
    DeleteStyle: '删除样式',
    Search: '搜索',
} as const

export const enTranslation = {
    NoColorsAvailable: 'No color styles',
    BrowseLibraries: 'Browse libraries',
    NoMatchesFound: 'No results found',
    GoToStyle: 'Go to style definition to edit',
    EditStyle: 'Edit style',
    DeleteStyle: 'Delete style',
    Search: 'Search',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
