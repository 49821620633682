import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    CopyFileModalTitle: '升级至专业版以复制此文件',
    CopyFileModalSubTitle: '基础版团队最多仅能创建 3 个文件，升级专业版后可以无限创建文件。',

    RecoverFileModalTitle: '升级至专业版以恢复此文件',
    RecoverFileModalSubTitle: '基础版团队仅能创建 3 个文件，升级专业版后可以无限创建文件。',

    MoveFileModalTitle: '升级至专业版以移动此文件',
    MoveFileModalSubTitle: '基础版团队仅能创建 3 个文件，升级专业版后可以无限创建文件。',

    MoveFileModalPageLimitTitle: '升级至专业版以移动此文件',
    MoveFileModalPageLimitSubTitle: '当文件中的「页面」数量大于 3，不能将其移动至基础版团队中。',

    OneProject: '1 个项目',
    ThreeFiles: '3 个文件',
    ThreePagesPerFile: '每个文件 3 个页面',

    SharePrototypeModalTitle: '升级至专业版以分享你的原型',
    SharePrototypeModalSubTitle: '专业版可以设置仅可查看原型权限。',
    PrototypeModalStartSubDesc1: '不支持分享仅可查看原型',
    PrototypeModalProfessionalSubDesc1: '分享仅可查看原型',

    PrivacyNotice: '升级至专业版以创建私密项目',
    PrivacyDescription: '专业版支持在团队中创建仅受邀成员可访问的项目和文件。',
    NoProjectControl: '不支持编辑项目权限',
    DesignFilesAndProjects: '3 个文件和 1 个项目',
    PagesPerFile: '每个文件 3 个页面',
    FullProjectControl: '自由设置项目权限',
    UnlimitedFilesAndProjects: '无限文件和项目',
    UnlimitedPages: '无限页面',

    NeedPublishLibrary: '升级至专业版以发布组件库',
    NeedPublishLibraryDesc: '基础版不支持发布组件库，升级专业版后可以共享你的样式和组件。',
    PublishTeamLibrary: '发布团队组件库',

    NeedMoreFile: '升级至专业版以创建更多文件',
    NeedMoreFileDesc: '基础版团队仅能创建 3 个文件，升级专业版后可以无限创建文件。',
    UnlimitedFiles: '无限文件',
    UnlimitedProject: '无限项目',

    NeedMorePage: '升级至专业版以创建更多页面',
    NeddMorePageDesc: '基础版团队仅能创建 3 个页面，升级专业版后可以无限创建页面。',

    NewProject: '新建项目',
    ProjectName: '项目名称',
    UnlimitedProjects: '无限项目',
    UnlimitedPagesInFiles: '无限页面',
    NeedMoreProjects: '升级至专业版以创建更多项目',
    StarterPlanLimit: '基础版团队仅能创建 1 个项目，升级专业版后可以无限创建项目。',
} as const

export const enTranslation = {
    CopyFileModalTitle: 'Want to copy this file?',
    CopyFileModalSubTitle:
        'The Starter plan only comes with 3 files. Upgrade to Professional plan to create unlimited number of files.',

    RecoverFileModalTitle: 'Want to restore this file?',
    RecoverFileModalSubTitle:
        'The Starter plan only comes with 3 files. Upgrade to Professional plan to create unlimited number of files.',

    MoveFileModalTitle: 'Want to add this file to your team?',
    MoveFileModalSubTitle:
        'The Starter plan only comes with 3 files. Upgrade to Professional plan to create unlimited number of files.',

    MoveFileModalPageLimitTitle: 'Want to add this file to your team?',
    MoveFileModalPageLimitSubTitle:
        'The number of pages in this file is more than 3. You cannot move it to teams on the Starter plan. ',

    SharePrototypeModalTitle: 'Want to share just your prototypes?',
    SharePrototypeModalSubTitle:
        'Upgrade to Professional plan to set the prototype permission to "Can view prototypes". ',
    PrototypeModalStartSubDesc1: 'No sharing prototypes separately',
    PrototypeModalProfessionalSubDesc1: 'Share prototypes separately',

    OneProject: '1 project',
    ThreeFiles: '3 design files',
    ThreePagesPerFile: '3 pages per design file',

    PrivacyNotice: 'Need more privacy for this project?',
    PrivacyDescription: 'Upgrade to Professional plan to create private projects',
    NoProjectControl: 'No control over project missions',
    DesignFilesAndProjects: '3 design files and 1 project',
    PagesPerFile: '3 pages per design file',
    FullProjectControl: 'Full control over project missions',
    UnlimitedFilesAndProjects: 'Unlimited files and projects',
    UnlimitedPages: 'Unlimited pages in design files',

    NeedPublishLibrary: 'Ready to build a team library?',
    NeedPublishLibraryDesc:
        'The starter plan does not support team libraries. Upgrade to Professional plan to share your styles and components.',
    PublishTeamLibrary: 'Shared team libraries',

    NeedMoreFile: 'Need more files?',
    NeedMoreFileDesc:
        'The Starter plan only comes with 3 files. Upgrade to Professional plan to create unlimited number of files. ',
    UnlimitedFiles: 'Unlimited files',
    UnlimitedProject: 'Unlimited projects',

    NeedMorePage: 'Need more pages?',
    NeddMorePageDesc:
        'The Starter plan only comes with 3 pages. Upgrade to Professional plan to create unlimited number of pages. ',
    NewProject: 'New project',
    ProjectName: 'Project name',
    UnlimitedProjects: 'Unlimited projects',
    UnlimitedPagesInFiles: 'Unlimited pages in design files',
    NeedMoreProjects: 'Need more projects?',
    StarterPlanLimit:
        'The Starter plan only comes with 1 project. Upgrade to Professional plan to create unlimited number of projects.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string | number>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
