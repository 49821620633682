/* eslint-disable no-restricted-imports */
import { useCommentService } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { useIsStopSyncActiveCommentPosition } from '../comment-service/comment-service-hook'
import { Comment } from '../type'
import { MinorAnchor } from './minor-anchor'

interface CommentDetailProps {
    comment: Comment
}

export function CommentDetailOverlayContent(props: CommentDetailProps) {
    const docReadonly = useViewState('docReadonly')
    const { comment } = props
    const commentService = useCommentService()
    const isStopSyncActiveCommentPosition = useIsStopSyncActiveCommentPosition()

    return comment.commentMetaData.position.hasAnchor ? (
        <MinorAnchor
            commentId={comment.commentMetaData.id}
            isStopSyncActiveCommentPosition={isStopSyncActiveCommentPosition}
            closeMove={docReadonly && !commentService.isCurrentUser(comment.commentMetaData.owner.id)}
        />
    ) : null
}
