import { DocID } from './type'

export enum OnboardingStatus {
    Init = 'init',
    Started = 'started',
    Closed = 'closed',
    Finished = 'finished',
}

/**
 * @deprecated 需求指引不是 onboarding!
 */
export enum OnboardingScene {
    Editor = 'editor',
    Space = 'workBench',
    Ai = 'aiLibraryExtraction',
    DemotionDevMode = 'demotionDevMode', // 降级版 devMode
    DevMode = 'devMode', // 普通研发模式
    AiLibraryMaintain = 'aiLibraryMaintain',
    OrgBenchMember = 'orgBenchMember', // 企业版工作台的团队添加状态
}

export enum OnboardingPopupStatus {
    EditorTour = 'editorTour', // 展示Editor导航
    SpaceTour = 'spaceTour', // 展示Space导航
    AiTour = 'aiTour', // 展示Ai引导
    Notify = 'notify', // 展示提示
    Hide = 'hide', // 都隐藏
    DevModeTour = 'devModeTour', // 展示研发模式导航
}

export enum OnboardingAction {
    None = 'none', // 无操作
    NavigateToDraft = 'navigateToDraft', // 跳转到草稿
    OpenHelpCenter = 'openHelpCenter', // 打开帮助中心
    FetchDraftDocs = 'fetchDraftDocs', // 获取草稿预置文件
    SwitchToAssets = 'switchToAssets', // 切换到资产面板
    FetchEditorDocs = 'fetchEditorDocs', // 获取编辑器预置文件
}

export interface OnboardingScene2Status {
    workBench: OnboardingStatus
    aiLibraryExtraction: OnboardingStatus
    editor: OnboardingStatus
    demotionDevMode: OnboardingStatus
    devMode: OnboardingStatus
    aiLibraryMaintain: OnboardingStatus
    orgBenchMember: OnboardingStatus
    variable: OnboardingStatus
}

export interface OnboardingNoneDraftDocVO {
    uniqKey: string
    docId: DocID
    folderId: string
    teamId: string
}

export interface OnboardingDraftDocVO {
    uniqKey: string
    docId: DocID
    folderId: string
}

export interface OnboardingResourceVO {
    nonDraftDocs: OnboardingNoneDraftDocVO[]
    draftDocs: OnboardingDraftDocVO[]
    initial: boolean
}

export const getFilesTutorialStep = (step: number, isOrg: boolean): string => {
    const normalStepList = ['1draft', '2team', '3create', '4playground', '5help']
    const orgStepList = ['1draft', '2allteam', '3create', '4playground', '5help']

    return isOrg ? orgStepList[step] : normalStepList[step]
}

export const getBasicTutorialStep = (step: number): string => {
    return ['1frame', '2pen', '3comment', '4ai', '5share', '6collaboration', '7library', '8prototype', '9preview'][step]
}

export const getDevTutorialStep = (step: number): number => {
    return [1, 2, 3][step]
}

export const FilesTutorialStep = ['1draft', '2team', '3create', '4playground', '5aiSystem', '6help']

export const BasicTutorialStep = [
    '1frame',
    '2pen',
    '3comment',
    '4share',
    '5collaboration',
    '6library',
    '7export',
    '8preview',
]

export enum TutorialTriggerFile {
    Playground = 'playground',
    NewFile = 'newFile',
}

export interface OnboardingFrogParams {
    url: string
    eventId: number
    eventAction: 'expose' | 'click' | 'event' | 'time'
    eventName: string
    page: 'FilesTutorial' | 'EditorTutorial'
    duration?: number
    customExtend?: { [key: string]: string }
}

// 工作台引导中断去向，埋点用
export const FilesTutorialAboutsMap = {
    recent: '最近',
    favorite: '收藏',
    trash: '回收站',
    team: '团队目录',
    newFile: '新建文件',
    importFile: '导入文件',
    componentMigration: '组件迁移',
    ai: `AI 设计系统`,
    openFile: '打开文件',
    closeTab: '关闭标签页',
    other: '其他',
}

// 编辑器引导中断去向，埋点用
export const BasicTutorialAboutsMap = {
    share: '分享',
    closeTab: '关闭标签页',
    returnSpace: '返回工作台',
    other: '其他',
}

export const FirstOnboardingFrogMap: any = {
    [OnboardingScene.DevMode]: {
        expose: {
            url: '/expose/DevTutorial/devFirstExpose',
            eventId: 26712,
            currentPage: 'DevTutorial',
            eventAction: 'expose',
            eventName: 'devFirstExpose',
        },
        closed: {
            url: '/click/DevTutorial/devFirstClose',
            eventId: 26714,
            currentPage: 'DevTutorial',
            eventAction: 'click',
            eventName: 'devFirstClose',
        },
        finished: {
            url: '/click/DevTutorial/devFirstDone',
            eventId: 26715,
            currentPage: 'DevTutorial',
            eventAction: 'click',
            eventName: 'devFirstDone',
        },
        interrupt: {
            url: '/event/DevTutorial/devFirstInterrupt',
            eventId: 26716,
            currentPage: 'DevTutorial',
            eventAction: 'event',
            eventName: 'devFirstInterrupt',
        },
        more: {
            url: '/click/DevTutorial/devFirstMore',
            eventId: 26713,
            currentPage: 'DevTutorial',
            eventAction: 'click',
            eventName: 'devFirstMore',
        },
        closedDuration: {
            url: '/time/DevTutorial/devFirstCloseDuration',
            eventId: 26717,
            currentPage: 'DevTutorial',
            eventAction: 'time',
            eventName: 'devFirstCloseDuration',
        },
        finishedDuration: {
            url: '/time/DevTutorial/devFirstDoneDuration',
            eventId: 26718,
            currentPage: 'DevTutorial',
            eventAction: 'time',
            eventName: 'devFirstDoneDuration',
        },
        interruptDuration: {
            url: '/time/DevTutorial/devFirstInterruptDuration',
            eventId: 26719,
            currentPage: 'DevTutorial',
            eventAction: 'time',
            eventName: 'devFirstInterruptDuration',
        },
    },
    [OnboardingScene.Editor]: {
        expose: {
            url: '/expose/EditorTutorial/basicFirstExpose',
            eventId: 20702,
            currentPage: 'EditorTutorial',
            eventAction: 'expose',
            eventName: 'basicFirstExpose',
        },
        closed: {
            url: '/click/EditorTutorial/basicFirstClose',
            eventId: 20704,
            currentPage: 'EditorTutorial',
            eventAction: 'click',
            eventName: 'basicFirstClose',
        },
        finished: {
            url: '/click/EditorTutorial/basicFirstDone',
            eventId: 20705,
            currentPage: 'EditorTutorial',
            eventAction: 'click',
            eventName: 'basicFirstDone',
        },
        interrupt: {
            url: '/event/EditorTutorial/basicFirstInterrupt',
            eventId: 20706,
            currentPage: 'EditorTutorial',
            eventAction: 'event',
            eventName: 'basicFirstInterrupt',
        },
        more: {
            url: '/click/EditorTutorial/basicFirstMore',
            eventId: 20703,
            currentPage: 'EditorTutorial',
            eventAction: 'click',
            eventName: 'basicFirstMore',
        },
        closedDuration: {
            url: '/time/EditorTutorial/basicFirstCloseDuration',
            eventId: 20707,
            currentPage: 'EditorTutorial',
            eventAction: 'time',
            eventName: 'basicFirstCloseDuration',
        },
        finishedDuration: {
            url: '/time/EditorTutorial/basicFirstDoneDuration',
            eventId: 20708,
            currentPage: 'EditorTutorial',
            eventAction: 'time',
            eventName: 'basicFirstDoneDuration',
        },
        interruptDuration: {
            url: '/time/EditorTutorial/basicFirstInterruptDuration',
            eventId: 20709,
            currentPage: 'EditorTutorial',
            eventAction: 'time',
            eventName: 'basicFirstInterruptDuration',
        },
    },
    [OnboardingScene.Space]: {
        expose: {
            url: '/expose/FilesTutorial/filesFirstExpose',
            eventId: 20678,
            currentPage: 'FilesTutorial',
            eventAction: 'expose',
            eventName: 'filesFirstExpose',
        },
        closed: {
            url: '/click/FilesTutorial/filesFirstClose',
            eventId: 20679,
            currentPage: 'FilesTutorial',
            eventAction: 'click',
            eventName: 'filesFirstClose',
        },
        finished: {
            url: '/click/FilesTutorial/filesFirstDone',
            eventId: 20680,
            currentPage: 'FilesTutorial',
            eventAction: 'click',
            eventName: 'filesFirstDone',
        },
        interrupt: {
            url: '/event/FilesTutorial/filesFirstInterrupt',
            eventId: 20681,
            currentPage: 'FilesTutorial',
            eventAction: 'event',
            eventName: 'filesFirstInterrupt',
        },
        closedDuration: {
            url: '/time/FilesTutorial/filesFirstCloseDuration',
            eventId: 20685,
            currentPage: 'FilesTutorial',
            eventAction: 'time',
            eventName: 'filesFirstCloseDuration',
        },
        finishedDuration: {
            url: '/time/FilesTutorial/filesFirstDoneDuration',
            eventId: 20686,
            currentPage: 'FilesTutorial',
            eventAction: 'time',
            eventName: 'filesFirstDoneDuration',
        },
        interruptDuration: {
            url: '/time/FilesTutorial/filesFirstInterruptDuration',
            eventId: 20687,
            currentPage: 'FilesTutorial',
            eventAction: 'time',
            eventName: 'filesFirstInterruptDuration',
        },
    },
}

export const RecoverOnboardingFrogMap: any = {
    [OnboardingScene.DevMode]: {
        expose: {
            url: '/expose/DevTutorial/devRecoverExpose',
            eventId: 26724,
            currentPage: 'DevTutorial',
            eventAction: 'expose',
            eventName: 'devRecoverExpose',
        },
        closed: {
            url: '/click/DevTutorial/devRecoverClose',
            eventId: 26726,
            currentPage: 'DevTutorial',
            eventAction: 'click',
            eventName: 'devRecoverClose',
        },
        finished: {
            url: '/click/DevTutorial/devRecoverDone',
            eventId: 26727,
            currentPage: 'DevTutorial',
            eventAction: 'click',
            eventName: 'devRecoverDone',
        },
        interrupt: {
            url: '/event/DevTutorial/devRecoverInterrupt',
            eventId: 26728,
            currentPage: 'DevTutorial',
            eventAction: 'event',
            eventName: 'devRecoverInterrupt',
        },
        more: {
            url: '/click/DevTutorial/devRecoverMore',
            eventId: 26725,
            currentPage: 'DevTutorial',
            eventAction: 'click',
            eventName: 'devRecoverMore',
        },
    },
    [OnboardingScene.Editor]: {
        expose: {
            url: '/expose/EditorTutorial/basicRecoverExpose',
            eventId: 20714,
            currentPage: 'EditorTutorial',
            eventAction: 'expose',
            eventName: 'basicRecoverExpose',
        },
        closed: {
            url: '/click/EditorTutorial/basicRecoverClose',
            eventId: 20716,
            currentPage: 'EditorTutorial',
            eventAction: 'click',
            eventName: 'basicRecoverClose',
        },
        finished: {
            url: '/click/EditorTutorial/basicRecoverDone',
            eventId: 20717,
            currentPage: 'EditorTutorial',
            eventAction: 'click',
            eventName: 'basicRecoverDone',
        },
        interrupt: {
            url: '/event/EditorTutorial/basicRecoverInterrupt',
            eventId: 20718,
            currentPage: 'EditorTutorial',
            eventAction: 'event',
            eventName: 'basicRecoverInterrupt',
        },
        more: {
            url: '/click/EditorTutorial/basicRecoverMore',
            eventId: 20715,
            currentPage: 'EditorTutorial',
            eventAction: 'click',
            eventName: 'basicRecoverMore',
        },
    },
    [OnboardingScene.Space]: {
        expose: {
            url: '/expose/FilesTutorial/filesRecoverExpose',
            eventId: 20693,
            currentPage: 'FilesTutorial',
            eventAction: 'expose',
            eventName: 'filesRecoverExpose',
        },
        closed: {
            url: '/click/FilesTutorial/filesRecoverClose',
            eventId: 20694,
            currentPage: 'FilesTutorial',
            eventAction: 'click',
            eventName: 'filesRecoverClose',
        },
        finished: {
            url: '/click/FilesTutorial/filesRecoverDone',
            eventId: 20695,
            currentPage: 'FilesTutorial',
            eventAction: 'click',
            eventName: 'filesRecoverDone',
        },
        interrupt: {
            url: '/event/FilesTutorial/filesRecoverInterrupt',
            eventId: 20696,
            currentPage: 'FilesTutorial',
            eventAction: 'event',
            eventName: 'filesRecoverInterrupt',
        },
    },
}

export const ResetOnboardingFrogMap: any = {
    [OnboardingScene.DevMode]: {
        expose: {
            url: '/expose/DevTutorial/devResetExpose',
            eventId: 26730,
            currentPage: 'DevTutorial',
            eventAction: 'expose',
            eventName: 'devResetExpose',
        },
        closed: {
            url: '/click/DevTutorial/devResetClose',
            eventId: 26732,
            currentPage: 'DevTutorial',
            eventAction: 'click',
            eventName: 'devResetClose',
        },
        finished: {
            url: '/click/DevTutorial/devResetDone',
            eventId: 26733,
            currentPage: 'DevTutorial',
            eventAction: 'click',
            eventName: 'devResetDone',
        },
        interrupt: {
            url: '/event/DevTutorial/devResetInterrupt',
            eventId: 26734,
            currentPage: 'DevTutorial',
            eventAction: 'event',
            eventName: 'devResetInterrupt',
        },
        more: {
            url: '/click/DevTutorial/devResetMore',
            eventId: 26731,
            currentPage: 'DevTutorial',
            eventAction: 'click',
            eventName: 'devResetMore',
        },
    },
    [OnboardingScene.Editor]: {
        expose: {
            url: '/expose/EditorTutorial/basicResetExpose',
            eventId: 20720,
            currentPage: 'EditorTutorial',
            eventAction: 'expose',
            eventName: 'basicResetExpose',
        },
        closed: {
            url: '/click/EditorTutorial/basicResetClose',
            eventId: 20722,
            currentPage: 'EditorTutorial',
            eventAction: 'click',
            eventName: 'basicResetClose',
        },
        finished: {
            url: '/click/EditorTutorial/basicResetDone',
            eventId: 20723,
            currentPage: 'EditorTutorial',
            eventAction: 'click',
            eventName: 'basicResetDone',
        },
        interrupt: {
            url: '/event/EditorTutorial/basicResetInterrupt',
            eventId: 20724,
            currentPage: 'EditorTutorial',
            eventAction: 'event',
            eventName: 'basicResetInterrupt',
        },
        more: {
            url: '/click/EditorTutorial/basicResetMore',
            eventId: 20721,
            currentPage: 'EditorTutorial',
            eventAction: 'click',
            eventName: 'basicResetMore',
        },
    },
    [OnboardingScene.Space]: {
        expose: {
            url: '/expose/FilesTutorial/filesResetExpose',
            eventId: 20698,
            currentPage: 'FilesTutorial',
            eventAction: 'expose',
            eventName: 'filesResetExpose',
        },
        closed: {
            url: '/click/FilesTutorial/filesResetClose',
            eventId: 20699,
            currentPage: 'FilesTutorial',
            eventAction: 'click',
            eventName: 'filesResetClose',
        },
        finished: {
            url: '/click/FilesTutorial/filesResetDone',
            eventId: 20700,
            currentPage: 'FilesTutorial',
            eventAction: 'click',
            eventName: 'filesResetDone',
        },
        interrupt: {
            url: '/event/FilesTutorial/filesResetInterrupt',
            eventId: 20701,
            currentPage: 'FilesTutorial',
            eventAction: 'event',
            eventName: 'filesResetInterrupt',
        },
    },
}
