// 对 x 保留 decimal 位小数
export function keepDecimal(x: number, decimal: number, isRound?: boolean): number {
    const base = Math.pow(10, decimal)
    return isRound ? Math.round(x * base) / base : Math.floor(x * base) / base
}

export function getHigh32Bits(num: number) {
    const other = parseInt(num.toString()).toString(2)
    if (other.length <= 32) {
        return 0
    }
    const highBits = other.length - 32
    return parseInt(other.slice(0, highBits), 2)
}
