import { translation } from './type.translation'
import { Wukong } from '@wukong/bridge-proto'

export const LibraryChangeTypeLabel: Record<Wukong.DocumentProto.LibraryChangeType, string> = {
    [0]: '',
    [Wukong.DocumentProto.LibraryChangeType.LIBRARY_CHANGE_TYPE_ADD]: translation('Added'),
    [Wukong.DocumentProto.LibraryChangeType.LIBRARY_CHANGE_TYPE_UPDATE]: translation('Modified'),
    [Wukong.DocumentProto.LibraryChangeType.LIBRARY_CHANGE_TYPE_REMOVE]: translation('Removed'),
    [Wukong.DocumentProto.LibraryChangeType.LIBRARY_CHANGE_TYPE_REORDER]: translation('Ordered'),
    [Wukong.DocumentProto.LibraryChangeType.LIBRARY_CHANGE_TYPE_UNCHANGE]: translation('Unchanged'),
    [Wukong.DocumentProto.LibraryChangeType.LIBRARY_CHANGE_TYPE_MOVEMENT]: translation('MoveToThis'),
}
