export type ConversationId = string
export type DialogueId = string

export interface DialogueResponse {
    dialogueId: DialogueId
    conversationMeta: ConversationMetaVO
    questionAnswer: DialogueAnswer
}

export interface ConversationVO {
    conversationMeta: ConversationMetaVO
    conversationDataList: DialogueData[]
}

// export interface DialogueInputStatus {
// }

export interface DialogueAnswerStatus {
    pending: boolean
    success: boolean
    error: string | null
    changedNodeIds: string[]
}

type ConversationAndDialogueJointKey = string

// dialogueId -> 对话状态
export type ConversationStatus = Record<
    ConversationAndDialogueJointKey, // joint key of conversationId and dialogueId
    // { inputStatus: DialogueInputStatus; answerStatus: DialogueAnswerStatus }
    { answerStatus: DialogueAnswerStatus }
>

export const createJointKey = (...args: (string | undefined)[]) => JSON.stringify(args)

export interface ConversationMetaVO {
    conversationId: ConversationId
    conversationTitle: string // 标题
    conversationType: ConversationType // 对话类型
    docId: string // 对话所在 docId
    startTime: number // 开始对话时间
    lastTime: number // 最后对话时间
}

export interface DialogueData {
    dialogueId: string
    questionInput: DialogueInput // 输入问题。注意模型需要整个Conversation作为输入
    questionAnswer: DialogueAnswer // 模型回答
    answerReactionType: AnswerReactionType // 点赞点踩
}

export enum ConversationType {
    ASK = 'ASK', // 询问
    EXECUTE = 'EXECUTE', // 编辑
    SEARCH = 'SEARCH', // 资产搜索
    IDEA_DISCOVERY = 'IDEA_DISCOVERY', // 灵感搜索
    USE_HELP = 'USE_HELP', // 使用帮助
}

export enum DialogueAnswerType {
    ASK = ConversationType.ASK,
    EXECUTE = ConversationType.EXECUTE,
    SEARCH = ConversationType.SEARCH,
    IDEA_DISCOVERY = ConversationType.IDEA_DISCOVERY,
    USE_HELP = ConversationType.USE_HELP,
    DENY = 'DENY', // bot 返回拒接消息
}

export enum AnswerReactionType {
    NONE = 'NONE',
    LIKE = 'LIKE',
    DISLIKE = 'DISLIKE',
}

/**
 * ai 接口
 */
export interface DialogueInput {
    docId: string
    pageId: string
    entryName: ConversationType
    nodeIds: string[] // 用户当前选中结点的 id array
    messages: MessageItemProps[]
    selectionThumbnail?: string // 用户当前选中结点的缩略图
}

export interface DialogueAnswer {
    type?: DialogueAnswerType // TODO(lizhaohui):先定义为可选，等 ai 全量修改后变成必有字段
    messages?: MessageItemProps[] // 空代表报错模型报错
    ops: JavaScriptOp[] | AssertSearchOp[] | InspireSearchOp[] // 按顺序遍历，如果是 JavaScriptOp，执行 eval(op.code)
    suggest?: string[] // 拒接后建议的输入 TODO(lizhaohui):先定义为可选，等 ai 全量修改后变成必有字段
}

export interface MessageItemContentProps {
    // 三者只有一个有值
    text?: string
    image?: string // base64 的图像
    nodeInfo?: { id: string; type: string; name: string; image: string } // 用户选中的各个图层信息
}

interface MessageItemFunctionCallProps {
    name: string
    arguments: string
}

export interface MessageItemProps {
    role: 'user' | 'assistant' | 'function'
    content: MessageItemContentProps[]
    name?: string
    functionCall?: MessageItemFunctionCallProps
}

// 返回数据
export interface JavaScriptOp {
    code: string
}

// 资产搜索或灵感搜索
// searchType = selectedNode，使用用户选中的结点图片做搜索
// searchType = imageUrl，使用 imageUrl 的图片做搜索
// searchType = text，使用 text 做搜索
interface AssertSearchOp {
    searchType: 'selectedNode' | 'imageUrl' | 'text'
    imageUrl?: string
    text?: string
}

export interface InspireSearchOp {
    imageUrls: string[]
}
