import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import { useViewState } from '../../../../view-state-bridge'
import { Manifest, PluginExported, PluginModalProps } from '../type'
import style from './index.module.less'
import { PluginArcTextState, ShortCut } from './type'

import { Checkbox, WKButton } from '../../../../../../ui-lib/src'
import { Range } from '../../atom/range/range'

import { Wukong } from '@wukong/bridge-proto'
import { useEffect, useRef } from 'react'
import { ReactComponent as ArcTextIcon } from '../../../assets/plugins/arc-text.svg'
import { toFixed } from '../../../utils/to-fixed'
import { ScrubbableInputDegree } from '../../atom/inputs/scrubbable-input-degree'
import { ScrubbableInputNumber } from '../../atom/inputs/scrubbable-input-number'
import { useDerivedState } from '../../design-panel-v2/attributes/components/auto-layout/utils'
import { getLdap, logs } from '../log'
import { useArcTextCommands } from './commands'
import { EmptyBg, ShortCutIcons } from './icons'

export const manifest: Manifest = {
    key: Wukong.DocumentProto.PluginType.PLUGIN_TYPE_ARC_TEXT,
    name: translation('ArcText'),
    width: 280,
    height: 408,
    icon: <ArcTextIcon />,
}

const Prefix = (content: any) => `arc-text-${content}`

export const TestIds = {
    ShortCut(key: any) {
        return Prefix(`shortcut-${key}`)
    },

    Empty: Prefix('empty'),
    Angle: Prefix('angle'),
    Radius: Prefix('radius'),
    FollowPath: Prefix('follow-path'),
    ReversePath: Prefix('reverse-path'),
    Generate: Prefix('generate'),
}
/*
 *
* - 路径向上、圆心角 120°
- 路径向下、圆心角 120°
- 路径向上、圆心角 180°
- 路径向下、圆心角 180°
- 整圆
 */

export const ArcTextShortCuts: Array<ShortCut> = [
    {
        angle: 120,
        reversePath: false,
    },
    {
        angle: 120,
        reversePath: true,
    },
    {
        angle: 180,
        reversePath: false,
    },
    {
        angle: 180,
        reversePath: true,
    },
    {
        angle: 360,
        reversePath: false,
    },
]

function ArcTextPluginEmpty() {
    return (
        <div className={style.emptyContainer} data-testid={TestIds.Empty}>
            <div className={style.emptyInner}>
                <EmptyBg></EmptyBg>
                <span className={style.emptyText}>{translation('PleaseSelectA')}</span>
            </div>
        </div>
    )
}

function useAngleRange() {
    const min = 0
    const max = 360

    return {
        min,
        max,
        angleToRad: (angle: number) => (angle / 180) * Math.PI,
        radToAngle: (rad: number) => (rad / Math.PI) * 180,
        angleToRate: (angle: number) => (angle - min) / (max - min),
        rateToAngle: (rate: number) => min + rate * (max - min),
    }
}

export function ArcTextPluginPanel(props: PluginArcTextState) {
    const commands = useArcTextCommands()

    const [state, setState] = useDerivedState(props)
    const angleRange = useAngleRange()

    const timer = useRef<ReturnType<typeof setTimeout>>()
    const syncState = (newState: PluginArcTextState) => {
        clearTimeout(timer.current)
        timer.current = setTimeout(() => {
            // 实时同步到 wasm
            if (newState.shouldSync) {
                commands.applyArcTextPlugin(newState)
            }
        }, 100)
    }

    const updateState = (updater: (state: PluginArcTextState) => void) => {
        const newState = Object.assign({}, state)
        updater(newState)
        setState(newState)
        syncState(newState)
    }
    const applyShortCut = (shortcut: ShortCut) => {
        updateState((newState) => Object.assign(newState, shortcut))
    }

    if (state.isEmpty) {
        return <ArcTextPluginEmpty />
    }

    return (
        <div className={style.arcTextPanel}>
            <div className={`${style.shortcutRow} ${style.row}`}>
                {ArcTextShortCuts.map((s, i) => (
                    <div className={style.shortcut} key={i} onClick={() => applyShortCut(s)}>
                        {ShortCutIcons[i]()}
                    </div>
                ))}
            </div>

            <div className={style.row}>
                <div className={style.head}>{translation('Sweep')}</div>
                <div className={style.content}>
                    <Range
                        rate={angleRange.angleToRate(state.angle)}
                        onChange={(value) =>
                            updateState((newState) => (newState.angle = Math.round(angleRange.rateToAngle(value))))
                        }
                    ></Range>
                </div>
                <div className={style.tail}>
                    <ScrubbableInputDegree
                        className={style.input}
                        onChange={(value) =>
                            updateState((newState) => {
                                if (typeof value === 'number') {
                                    newState.angle = Math.round(value)
                                }
                            })
                        }
                        leftScrubbable={false}
                        rightScrubbable={false}
                        value={angleRange.angleToRad(state.angle)}
                        min={angleRange.min}
                        max={angleRange.max}
                        testId={TestIds.Angle}
                    ></ScrubbableInputDegree>
                </div>
            </div>

            <div className={style.row}>
                <div className={style.head}>{translation('Radius')}</div>
                <div className={style.content}>
                    <ScrubbableInputNumber
                        testId={TestIds.Radius}
                        className={style.input}
                        onChange={(value) =>
                            updateState((newState) => {
                                if (typeof value === 'number') {
                                    newState.radius = toFixed(value, 1)
                                }
                            })
                        }
                        leftScrubbable={false}
                        rightScrubbable={false}
                        value={toFixed(state.radius, 1)}
                        min={0}
                    />
                </div>
            </div>

            <div className={`${style.row} ${style.rotateRow}`}>
                <div className={style.list}>
                    <div className={style.title}>{translation('Rotation')}</div>
                    <div className={style.item}>
                        <Checkbox
                            checked={state.followPath}
                            onChange={() => updateState((newState) => (newState.followPath = !newState.followPath))}
                            label={translation('FollowTheCurve')}
                        />
                    </div>
                    <div className={style.item}>
                        <Checkbox
                            checked={state.reversePath}
                            onChange={() => updateState((newState) => (newState.reversePath = !newState.reversePath))}
                            label={translation('ReverseTheDirection')}
                        />
                    </div>
                </div>
            </div>

            <WKButton
                type="primary"
                disabled={!state.enableGenerateBtn || state.radius <= 0}
                onClick={() => {
                    // trace
                    {
                        logs.Plugins.arctextCreate({ ldap: getLdap() })
                    }
                    commands.applyArcTextPlugin(state)
                }}
                className={style.row}
                data-testid={TestIds.Generate}
            >
                {translation('Generate')}
            </WKButton>
        </div>
    )
}

export function ArcTextPlugin(_: PluginModalProps) {
    // 统计曝光次数
    useEffect(() => {
        // trace
        {
            logs.Plugins.arctext({ ldap: getLdap() })
        }
    }, [])

    const state = useViewState('pluginArcText')

    if (!state) {
        return null
    }

    return <ArcTextPluginPanel {...state}></ArcTextPluginPanel>
}

export default {
    manifest,
    Component: ArcTextPlugin,
} as PluginExported
