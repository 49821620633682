import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    TsFwaoiDM: '通过研发模式的链接打开文件，将自动进入研发模式。',
    GotIt: '我知道了',
} as const

export const enTranslation = {
    TsFwaoiDM: 'This shared file will automatically open in Developer Mode.',
    GotIt: 'OK',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
