import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    IconSize: '图标大小',
    StrokeWidth: '线段粗细',
    IconTheme: '图标风格',
    Round: '圆形端点',
    Normal: '正常端点',
    Square: '方型端点',
    Round_synonyms1: '圆角节点',
    Miter: '尖角节点',
    Bevel: '斜面节点',
} as const

export const enTranslation = {
    IconSize: 'Icon size',
    StrokeWidth: 'Stroke width',
    IconTheme: 'Icon theme',
    Round: 'Round',
    Normal: 'Normal',
    Square: 'Square',
    Round_synonyms1: 'Round',
    Miter: 'Miter',
    Bevel: 'Bevel',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
