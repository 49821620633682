import { FocusEvent, PropsWithChildren, useRef } from 'react'

export const TabLoop: React.FC<PropsWithChildren> = (props: PropsWithChildren) => {
    const containerRef = useRef<HTMLSpanElement | null>(null)

    const firstTabbableElement = useRef<HTMLElement | null>(null)
    const lastTabbableElement = useRef<HTMLElement | null>(null)

    const traverseChildren = () => {
        firstTabbableElement.current = null
        lastTabbableElement.current = null

        const collectTabbableElementRange = (targetElement: HTMLElement | null) => {
            if (!targetElement) {
                return
            }
            if (isTabbable(targetElement)) {
                if (!firstTabbableElement.current || targetElement.tabIndex < firstTabbableElement.current.tabIndex) {
                    firstTabbableElement.current = targetElement
                }
                if (!lastTabbableElement.current || targetElement.tabIndex >= lastTabbableElement.current.tabIndex) {
                    lastTabbableElement.current = targetElement
                }
            }
            Array.from(targetElement.children).forEach((element) => collectTabbableElementRange(element as HTMLElement))
        }
        collectTabbableElementRange(containerRef.current)
    }

    const onFrontGuardFocus = (event: FocusEvent<HTMLSpanElement>) => {
        traverseChildren()
        const target = containerRef.current?.contains(event.relatedTarget as Node)
            ? lastTabbableElement
            : firstTabbableElement
        focusOnElement(target.current)
    }

    const onBackGuardFocus = () => {
        traverseChildren()
        focusOnElement(firstTabbableElement.current)
    }

    return (
        <span>
            <span tabIndex={0} onFocus={onFrontGuardFocus}></span>
            <span ref={containerRef}>{props.children}</span>
            <span tabIndex={0} onFocus={onBackGuardFocus}></span>
        </span>
    )
}

function focusOnElement(element: HTMLElement | null) {
    if (element) {
        element.focus()
        if ((element as HTMLInputElement | HTMLTextAreaElement).select) {
            ;(element as HTMLInputElement | HTMLTextAreaElement).select()
        }
    }
}

function isTabbable(element: HTMLElement) {
    const elementTabIndex = element.getAttribute('tabIndex')
    if (elementTabIndex === '-1') {
        return false
    }
    const elementName = element.nodeName.toLowerCase()
    if (isDefaultTabbableElement(elementName)) {
        return true
    }
    if (elementTabIndex !== '0') {
        return false
    }
    const elementDisabled = element.getAttribute('disabled')
    if (elementDisabled) {
        return false
    }
    if (elementName === 'a' && !(element as HTMLLinkElement).href) {
        return false
    }
    if (!(element.offsetWidth || element.offsetHeight || element.getClientRects().length)) {
        return false
    }
    return isElementVisible(element)
}

export function isDefaultTabbableElement(elementName: string) {
    return /^(input|select|textarea|button)$/.test(elementName)
}

function isElementVisible(element: HTMLElement | null): boolean {
    if (!element) {
        return false
    }
    const visibleStyle = element.style.visibility
    if (visibleStyle === 'inherit') {
        return isElementVisible(element.parentElement)
    }
    return visibleStyle !== 'hidden'
}
