import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    yBLOTd: '该内容无法使用，请修改后重试',
    wJlDKM: '该图片无法使用，请重新上传',
    WBSAlt: '内容保存失败，请稍后重试',
    vzheZs: '图片保存失败，请稍后重试',
} as const

export const enTranslation = {
    yBLOTd: '该内容无法使用，请修改后重试',
    wJlDKM: '该图片无法使用，请重新上传',
    WBSAlt: '内容保存失败，请稍后重试',
    vzheZs: '图片保存失败，请稍后重试',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
