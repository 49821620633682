import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    OnlyShare: '仅允许被邀请的协作者访问',
    MembersOfOrganization: '获得链接的企业成员可查看',
    MembersOfOrganization_prototype: '获得链接的企业成员可查看原型',
    MembersOfOrganization_synonyms1: '获得链接的企业成员可编辑',
    AnyoneWithThe: '获得链接的任何人可查看',
    AnyoneWithThe_prototype: '获得链接的任何人可查看原型',
    AnyoneWithThe_synonyms1: '获得链接的任何人可编辑',
    DisabledByAdmin: ' (已被管理员禁用)',
} as const

export const enTranslation = {
    OnlyShare: 'Only people invited to this file can access',
    MembersOfOrganization: 'Members in the organization with the link can view',
    MembersOfOrganization_prototype: 'Members in the organization with the link can view prototypes',
    MembersOfOrganization_synonyms1: ' Members in the organization with the link can edit',
    AnyoneWithThe: 'Anyone with the link can view',
    AnyoneWithThe_prototype: 'Anyone with the link can view prototypes',
    AnyoneWithThe_synonyms1: 'Anyone with the link can edit',
    DisabledByAdmin: ' (Disabled by admin)',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
