import classnames from 'classnames'
import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { isWindows } from '../../../../../app/src/kernel/util/ua'
import { IconArrowDown12 } from '../../../svg-icon/12/controls'
import { ExplicitUndefined } from '../../../utils/explicit-undefined'
import { TriggerContainer, TriggerContainerProps } from './base-components/trigger-container'
import { TriggerFocus, TriggerFocusProps, TriggerFocusRef } from './base-components/trigger-focus'
import classes from './trigger-arrow.module.less'
import { TriggerBaseProps, TriggerBaseRef } from './type'
import { useCommonTrigger } from './use-common-trigger'

export interface TriggerArrowProps extends TriggerBaseProps {
    // TriggerContainerProps
    classNameWhenOpen?: TriggerContainerProps['classNameWhenOpen']
    isOpenPick: TriggerContainerProps['isOpenPick']
    // 私有配置
    label?: React.ReactNode
    isSmallArrow?: boolean
    onClickLabel?: () => void
}
export interface TriggerArrowRef extends TriggerBaseRef {}

function _TriggerArrow(props: TriggerArrowProps, ref?: React.Ref<TriggerArrowRef>) {
    const {
        className,
        disabled,
        dataTestIds,
        openAction,
        closeAction,
        classNameWhenOpen,
        isOpenPick,
        label,
        isSmallArrow,
        onClickLabel,
        ...otherProps
    } = props

    const triggerRef = useRef<TriggerFocusRef>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const commonTrigger = useCommonTrigger({ isOpenPick, openAction, closeAction })

    const onKeyDownCapture = (e: React.KeyboardEvent) => {
        if ((e.target as HTMLElement).tagName.toLocaleLowerCase() !== 'input') {
            return
        }
        if ((isWindows() ? e.ctrlKey : e.metaKey) && e.key === 'ArrowDown') {
            // Capture 是为了能调用stopPropagation阻止输入框的自增行为
            e.stopPropagation()
            openAction()
        }
    }

    useImperativeHandle(
        ref,
        () => ({
            getTriggerElement: () => triggerRef.current?.getContainer(),
            triggerRect: () => containerRef.current?.getBoundingClientRect()!,
            focus: () => triggerRef.current?.getFocusElement().focus(),
            getContainer: () => containerRef.current!,
        }),
        []
    )

    return (
        <TriggerContainer<ExplicitUndefined<TriggerContainerProps>>
            ref={containerRef}
            className={classnames(classes.template, [className], {
                [classes.disabled]: disabled,
                [classes.smallArrow]: isSmallArrow,
            })}
            disabled={disabled}
            dataTestIds={dataTestIds}
            classNameWhenOpen={classNameWhenOpen}
            isOpenPick={isOpenPick}
            {...otherProps}
        >
            <div className={classes.label} onClick={onClickLabel} tabIndex={-1} onKeyDownCapture={onKeyDownCapture}>
                {label}
            </div>
            <TriggerFocus<ExplicitUndefined<TriggerFocusProps>>
                className={classes.trigger}
                ref={triggerRef}
                dataTestIds={dataTestIds}
                disabled={disabled}
                onClick={commonTrigger.click}
                onMouseDown={commonTrigger.mousedown}
                onEnter={openAction}
                onBlankSpace={openAction}
                outlineMode={undefined}
            >
                <span className={classes.arrow}>
                    <IconArrowDown12 />
                </span>
            </TriggerFocus>
            {props.children}
        </TriggerContainer>
    )
}

export const TriggerArrow = forwardRef(_TriggerArrow)
