import { useEffect, useState } from 'react'
import { Wukong } from '@wukong/bridge-proto'
import { useUserInfoContext } from '../../../../../auth'
import { useOrganizationNotifyService } from '../../../../../main/app-context'

export const useBackNotify = (reSync: () => void) => {
    const { userInfo } = useUserInfoContext()
    const orgNotify = useOrganizationNotifyService()
    const relationMessage = orgNotify.states.use.relationMessageState()
    const [signal, setSignal] = useState<number>()

    useEffect(() => {
        if (!relationMessage) {
            return
        }

        if (relationMessage.changeType !== Wukong.NotifyProto.RelationChangeType.UPDATE) {
            return
        }
        if (
            relationMessage.relation?.one?.entityType !== Wukong.NotifyProto.EntityType.ORG &&
            relationMessage.relation?.one?.entityType !== Wukong.NotifyProto.EntityType.TEAM
        ) {
            return
        }

        if (
            !userInfo.userId ||
            relationMessage.relation?.another?.entityType !== Wukong.NotifyProto.EntityType.USER ||
            Number(relationMessage.relation?.another?.entityId) !== userInfo.userId
        ) {
            return
        }

        setSignal(Date.now())
    }, [relationMessage, userInfo.userId])

    useEffect(() => {
        signal && reSync()
    }, [reSync, signal])
}
