import React, { useCallback, useState } from 'react'
import { WKToast } from '../../../../../../../ui-lib/src'
import './styles.less'

interface ReactJsonProps {
    src: any
    name?: string
    style?: React.CSSProperties
    theme?: 'light' | 'dark'
    displayDataTypes?: boolean
    enableClipboard?: boolean
    collapsed?: boolean | number
    groupArraysAfterLength?: number
    jumpToNode?: (id: string) => void
    highlightNode?: (id: string) => void
}

interface JsonNodeProps extends Omit<ReactJsonProps, 'src'> {
    value: any
    keyName?: string
    keyPath: (string | number)[]
    level: number
}

const getValueType = (value: any): string => {
    if (value === null) return 'null'
    if (Array.isArray(value)) return 'array'
    if (value instanceof Date) return 'date'
    return typeof value
}

const getNodeType = (value: any) => {
    if (value === null) return 'null'
    if (Array.isArray(value)) return '[]'
    if (typeof value === 'object') return '{}'
    if (typeof value === 'string') return '""'
    return ''
}

const isNodeIdFormatSchema = (str: string): boolean => {
    return str.startsWith('$_')
}

const JsonNode: React.FC<JsonNodeProps> = ({
    value,
    keyName,
    keyPath,
    level,
    displayDataTypes = true,
    enableClipboard = true,
    collapsed = false,
    groupArraysAfterLength = 100,
    jumpToNode,
    highlightNode,
    style = {},
}) => {
    const shouldBeCollapsed = () => {
        if (typeof collapsed === 'number') {
            return level >= collapsed
        }
        if (collapsed) {
            return true
        }
        const parentIsArray = typeof keyName === 'string' && !isNaN(Number(keyName))
        return parentIsArray
    }

    const [isCollapsed, setIsCollapsed] = useState(shouldBeCollapsed())
    const [copied, setCopied] = useState(false)
    const [currentGroup, setCurrentGroup] = useState(0)

    const handleCopy = useCallback(async () => {
        try {
            await navigator.clipboard.writeText(JSON.stringify(value, null, 2))
            WKToast.show('已复制到剪贴板')
        } catch (err) {
            console.error('Failed to copy:', err)
        }
    }, [value])

    const handleCopyValue = useCallback(async (val: any) => {
        try {
            await navigator.clipboard.writeText(String(val))
            WKToast.show('已复制到剪贴板')
        } catch (err) {
            console.error('Failed to copy value:', err)
        }
    }, [])

    const handleCopyKey = useCallback(async () => {
        if (keyName) {
            try {
                await navigator.clipboard.writeText(keyName)
                setCopied(true)
                WKToast.show('已复制到剪贴板')
                setTimeout(() => setCopied(false), 1500)
            } catch (err) {
                console.error('Failed to copy key:', err)
            }
        }
    }, [keyName])

    const valueType = getValueType(value)
    const nodeType = getNodeType(value)

    const renderCollapsedArrow = () => {
        if (valueType !== 'array' && valueType !== 'object') return null
        if (
            (valueType === 'array' && value.length === 0) ||
            (valueType === 'object' && Object.keys(value).length === 0)
        )
            return null
        return (
            <span
                className={`arrow-icon ${isCollapsed ? 'collapsed' : ''}`}
                onClick={() => setIsCollapsed(!isCollapsed)}
            >
                ▼
            </span>
        )
    }

    const renderValue = () => {
        switch (valueType) {
            case 'null':
                return (
                    <span className="json-null hoverable" onClick={() => handleCopyValue(null)}>
                        null
                    </span>
                )
            case 'undefined':
                return (
                    <span className="json-undefined hoverable" onClick={() => handleCopyValue(undefined)}>
                        undefined
                    </span>
                )
            case 'string':
                // eslint-disable-next-line no-case-declarations
                const isNodeId = isNodeIdFormatSchema(value)
                return (
                    <span
                        className={`json-string ${isNodeId ? 'node-id' : ''}`}
                        onClick={() => (isNodeId ? jumpToNode?.(value.slice(2)) : handleCopyValue(value))}
                        onMouseEnter={() => {
                            if (isNodeId) {
                                highlightNode?.(value.slice(2))
                            }
                        }}
                        onMouseLeave={() => {
                            if (isNodeId) {
                                highlightNode?.('')
                            }
                        }}
                    >
                        &quot;{isNodeId ? value.slice(2) : value}&quot;
                    </span>
                )
            case 'number':
                return (
                    <span className="json-number hoverable" onClick={() => handleCopyValue(value)}>
                        {value}
                    </span>
                )
            case 'boolean':
                return (
                    <span className="json-boolean hoverable" onClick={() => handleCopyValue(value)}>
                        {value.toString()}
                    </span>
                )
            case 'date':
                return (
                    <span className="json-date hoverable" onClick={() => handleCopyValue(value.toISOString())}>
                        &quot;{value.toISOString()}&quot;
                    </span>
                )
            case 'array':
                if (value.length === 0) {
                    return <span className="json-empty">空数组</span>
                }
                return null
            case 'object':
                if (Object.keys(value).length === 0) {
                    return <span className="json-empty">空对象</span>
                }
                return isCollapsed ? (
                    <span className="json-mark" onClick={handleCopy}>
                        {'{...}'}
                    </span>
                ) : null
            default:
                return null
        }
    }

    const renderArrayGroup = (array: any[], start: number, end: number, totalGroups: number) => {
        return (
            <div key={`group-${start}`} className="json-children" style={{ marginLeft: 12 }}>
                {array.slice(start, end).map((val: any, index: number) => (
                    <JsonNode
                        key={`${start + index}`}
                        value={val}
                        keyName={`${start + index}`}
                        keyPath={[...keyPath, start + index]}
                        level={level + 1}
                        displayDataTypes={displayDataTypes}
                        enableClipboard={enableClipboard}
                        collapsed={collapsed}
                        groupArraysAfterLength={groupArraysAfterLength}
                        jumpToNode={jumpToNode}
                        highlightNode={highlightNode}
                    />
                ))}
                <div className="json-group-navigation">
                    {currentGroup > 0 && (
                        <span className="json-group-nav-btn" onClick={() => setCurrentGroup(currentGroup - 1)}>
                            ← 上一组
                        </span>
                    )}
                    <span className="json-group-info">
                        第 {currentGroup + 1}/{totalGroups} 组 (共 {array.length} 项)
                    </span>
                    {currentGroup < totalGroups - 1 && (
                        <span className="json-group-nav-btn" onClick={() => setCurrentGroup(currentGroup + 1)}>
                            下一组 →
                        </span>
                    )}
                </div>
            </div>
        )
    }

    const renderChildren = () => {
        if (isCollapsed || (valueType !== 'array' && valueType !== 'object')) return null
        if (
            (valueType === 'array' && value.length === 0) ||
            (valueType === 'object' && Object.keys(value).length === 0)
        )
            return null

        if (valueType === 'array') {
            if (groupArraysAfterLength && value.length > groupArraysAfterLength) {
                const totalGroups = Math.ceil(value.length / groupArraysAfterLength)
                const start = currentGroup * groupArraysAfterLength
                const end = Math.min(start + groupArraysAfterLength, value.length)
                return renderArrayGroup(value, start, end, totalGroups)
            }

            return (
                <div className="json-children" style={{ marginLeft: 12 }}>
                    {value.map((val: any, index: number) => (
                        <JsonNode
                            key={`${index}`}
                            value={val}
                            keyName={`${index}`}
                            keyPath={[...keyPath, index]}
                            level={level + 1}
                            displayDataTypes={displayDataTypes}
                            enableClipboard={enableClipboard}
                            collapsed={collapsed}
                            groupArraysAfterLength={groupArraysAfterLength}
                            jumpToNode={jumpToNode}
                            highlightNode={highlightNode}
                        />
                    ))}
                </div>
            )
        }

        const sortedKeys = Object.keys(value).sort()
        return (
            <div className="json-children" style={{ marginLeft: 12 }}>
                {sortedKeys.map((key) => (
                    <JsonNode
                        key={key}
                        value={value[key]}
                        keyName={key}
                        keyPath={[...keyPath, key]}
                        level={level + 1}
                        displayDataTypes={displayDataTypes}
                        enableClipboard={enableClipboard}
                        collapsed={collapsed}
                        groupArraysAfterLength={groupArraysAfterLength}
                        jumpToNode={jumpToNode}
                        highlightNode={highlightNode}
                    />
                ))}
            </div>
        )
    }

    const keyIsNodeId = keyName && isNodeIdFormatSchema(keyName)
    return (
        <div className="json-node" style={{ marginLeft: level > 0 ? 0 : undefined }}>
            {keyName !== undefined && (
                <>
                    {renderCollapsedArrow()}
                    <span
                        className={`json-key ${copied ? 'copied' : ''} ${keyIsNodeId ? 'node-id' : ''}`}
                        onClick={() => (keyIsNodeId ? jumpToNode?.(keyName.slice(2)) : handleCopyKey())}
                        onMouseEnter={() => highlightNode?.(keyName.slice(2))}
                        onMouseLeave={() => highlightNode?.('')}
                    >
                        {keyIsNodeId ? keyName.slice(2) : keyName}
                    </span>
                    <span className="json-colon">:</span>
                </>
            )}
            {renderValue()}
            {displayDataTypes && <span className="json-type">{nodeType}</span>}
            {renderChildren()}
        </div>
    )
}

const MotiffScopeJsonViewer: React.FC<ReactJsonProps> = (props) => {
    const { src, name = 'root', ...rest } = props
    return (
        <div className={`react-json-view ${props.theme || 'light'}`}>
            <JsonNode {...rest} value={src} keyName={name} keyPath={[]} level={0} />
        </div>
    )
}

export default MotiffScopeJsonViewer
