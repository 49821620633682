import classNames from 'classnames'
import { Wukong } from '@wukong/bridge-proto'
import { WKTypography } from '../../../../../../../ui-lib/src'
import { useViewState } from '../../../../../view-state-bridge'
import { LibraryTestId } from '../../../../../window'
import { LibraryName } from '../../library-name/library-name'
import { generateLibraryDisplayItemKey } from '../../util/key'
import { EmptyLibraryList } from './empty-library-list'
import { PreviewLibraryComponentItem } from './preview-library-component-item/preview-library-component-item'
import { PreviewLibraryFolderItem } from './preview-library-folder-item/preview-library-folder-item'
import { PreviewLibraryHeaderItem } from './preview-library-header-item/preview-library-header-item'
import classes from './preview-library-list.module.less'
import { translation } from './preview-library-list.translation'

// 组件库资产面板-组件库列表
export function PreviewLibraryList() {
    const libraryAssetPanel = useViewState('libraryAssetPanel')
    const inSearch = !!libraryAssetPanel?.filterText

    if (!inSearch && !libraryAssetPanel?.hasContent) {
        return <EmptyLibraryList />
    }

    if (inSearch && !libraryAssetPanel.hasContent) {
        return (
            <WKTypography.Paragraph
                color="placeholder"
                className={classes.emptyContainer}
                data-testid={LibraryTestId.AssetPanel.SearchEmptyPlaceholder}
            >
                {translation('NoResultsFor', { filterText: libraryAssetPanel?.filterText })}
            </WKTypography.Paragraph>
        )
    }

    return (
        <>
            <div className={classNames(classes.wrapper)} style={{ height: libraryAssetPanel?.totalHeight ?? 0 }}>
                {libraryAssetPanel?.displayItems.map((item) => (
                    <PreviewLibraryListItem item={item} key={generateLibraryDisplayItemKey(item)} />
                ))}
            </div>
            <PreviewLibraryListStickyHeader />
        </>
    )
}

function PreviewLibraryListStickyHeader() {
    const stickyHeader = useViewState('libraryAssetPanel')?.stickyHeader

    return stickyHeader?.visible ? (
        <div className={classes.stickyHeader}>
            <LibraryName
                className={classes.titleLibraryName}
                libraryType={stickyHeader?.libraryType}
                name={stickyHeader?.libraryName}
            >
                {stickyHeader.folderNames.length > 0 && <span className={classes.titleLibrarySpacing}>/</span>}
            </LibraryName>
            <span className={classes.titleLibraryTitle}>{stickyHeader.folderNames.join(' / ')}</span>
        </div>
    ) : (
        <></>
    )
}

function PreviewLibraryListItem({ item }: { item: Wukong.DocumentProto.IVLibraryAssetPanelItem }) {
    const isLocalHidden =
        item.type === Wukong.DocumentProto.VLibraryAssetPanelItemType.V_LIBRARY_ASSET_PANEL_ITEM_TYPE_HEADER &&
        item.header?.libraryType == Wukong.DocumentProto.VLibraryType.V_LIBRARY_TYPE_LOCAL_HIDDEN

    switch (item.type) {
        case Wukong.DocumentProto.VLibraryAssetPanelItemType.V_LIBRARY_ASSET_PANEL_ITEM_TYPE_HEADER:
            if (isLocalHidden) {
                // 隐藏目录实际上是 header，但展示为 folder
                return (
                    <PreviewLibraryFolderItem
                        folder={
                            {
                                ...(item.header ?? {}),
                                prefixes: [],
                                suffixes: [translation('HideWhenPublishing')],
                            } as Wukong.DocumentProto.IVLibraryAssetPanelFolderItem
                        }
                    />
                )
            } else {
                return <PreviewLibraryHeaderItem header={item.header} />
            }
        case Wukong.DocumentProto.VLibraryAssetPanelItemType.V_LIBRARY_ASSET_PANEL_ITEM_TYPE_FOLDER:
            return <PreviewLibraryFolderItem folder={item.folder} />
        case Wukong.DocumentProto.VLibraryAssetPanelItemType.V_LIBRARY_ASSET_PANEL_ITEM_TYPE_COMPONENT:
            return <PreviewLibraryComponentItem component={item.component} />
        default:
            return <></>
    }
}
