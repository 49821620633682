/* eslint-disable no-restricted-imports */
import { ApplyEffectStyleForSelectionCommand, GetSelectionNodeIdsCommandForWasm, Wukong } from '@wukong/bridge-proto'
import constate from 'constate'
import { useCallback, useMemo, useState } from 'react'
import { sortArrayByKey } from '../../../../../../../../util/src'
import { cmdChangePopupState } from '../../../../../../document/command/document-command'
import {
    cmdChangeAttrPanelStyleEditorState,
    cmdDeleteStyleNode,
} from '../../../../../../document/command/node-props-command'
import { EffectStyleNode, NodeId, PopupStateType } from '../../../../../../document/node/node'
import {
    EffectStyleIconType,
    StyleGetVO,
    StyleWithoutDocIdGetVO,
} from '../../../../../../kernel/interface/component-style'
import { useLibraryComponentService } from '../../../../../../main/app-context'
import { LibraryResourceOssClientType } from '../../../../../../share/component-style-library/service/library-resource-downloader'
import { DeepRequired, useViewState } from '../../../../../../view-state-bridge'
import { useCommand } from '../../../../../context/document-context'
import {
    EffectStyleDetail,
    EffectStylesGroup,
    EffectStylesGroupRemote,
} from '../../../../../document-view-state/view-states/effect-style'
import { isSearchMatched, searchMatchedFilter } from '../../../../../utils/search-sort'
import { parseStyleName } from '../../../styles/get-style-nodes-info-map'
import { modifiedMultiPopup } from '../../../styles/modified-multi-popup'
import { ClientPosittion, useEffectContext } from '../../context'
import { getEffectStyleIcon } from '../effect-style-icon'

function useEffectStyleSelectInternal() {
    const commandInvoker = useCommand()

    // TODO(jiangzg): 统一ts类型
    const { selectionEffectStyle: effectStyle, effectsToCreateStyle } = useEffectContext()

    const selectEffectStyleId = effectStyle?.styleId ?? ''

    const selectEffectStyleNodeKey = effectStyle?.key ?? ''

    const popupState = useViewState('popupState')

    const setDocumentPopupState = useCallback(
        (openPopup: boolean) => {
            const _popupState = modifiedMultiPopup(
                popupState,
                PopupStateType.POPUP_STATE_TYPE_EFFECT_STYLE_EDIT_INSIDE_SELECT,
                openPopup
                    ? {
                          type: PopupStateType.POPUP_STATE_TYPE_EFFECT_STYLE_EDIT_INSIDE_SELECT,
                          reciprocalIndex: -1,
                          multiPopup: [],
                      }
                    : undefined
            )
            commandInvoker.invoke(cmdChangePopupState, _popupState)
        },
        [commandInvoker, popupState]
    )

    const openEditModalInsideSelectModal = (styleId: NodeId, rect: ClientPosittion, isCreate?: boolean) => {
        setDocumentPopupState(true)

        const { left, top } = rect
        setEditModeStyleId(styleId)
        setEditModePosition({ left, top })
        const passIds = commandInvoker.DEPRECATED_invokeBridge(GetSelectionNodeIdsCommandForWasm).value ?? []
        commandInvoker.invoke(cmdChangeAttrPanelStyleEditorState, {
            editingStyleId: styleId,
            openFromModule: Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_EFFECT,
            openFromNodeId: passIds,
            isCreate,
        })
    }

    const closeEditModalInsideSelectModal = () => {
        setDocumentPopupState(false)
    }

    const editModalOpened = useMemo(() => {
        if (!popupState) {
            return false
        }
        if (
            (popupState.type !== PopupStateType.POPUP_STATE_TYPE_EFFECT_STYLE_SELECT &&
                popupState.type !== PopupStateType.POPUP_STATE_TYPE_CANDIDATE_COMPONENT_REPLACE) ||
            !popupState.multiPopup?.length
        ) {
            return false
        }
        return popupState.multiPopup[0].type === PopupStateType.POPUP_STATE_TYPE_EFFECT_STYLE_EDIT_INSIDE_SELECT
    }, [popupState])

    const [editModeStyleId, setEditModeStyleId] = useState<NodeId | null>(null)
    const [editModePosition, setEditModePosition] = useState<ClientPosittion | null>(null)

    const selectEffectStyle = (effectStyleId: NodeId) => {
        commandInvoker.DEPRECATED_invokeBridge(ApplyEffectStyleForSelectionCommand, { value: effectStyleId })
        commandInvoker.commitUndo()
    }

    const deleteEffectStyle = (effectStyleId: NodeId) => {
        commandInvoker.invoke(cmdDeleteStyleNode, effectStyleId)
        commandInvoker.commitUndo()
    }

    const libraryComponentService = useLibraryComponentService()

    const getRemoteEffectStyleId = async (item: StyleWithoutDocIdGetVO, docId: string) =>
        libraryComponentService.libraryNodeDataService.createRemoteStyleNode({
            isLocal: false,
            localNodeId: null,
            ossClientType: LibraryResourceOssClientType.Style,
            remoteDocId: docId,
            remoteNodeId: item.nodeId,
            nodeDataPath: item.nodeDataPath ?? '',
            key: item.id,
        })

    const selectRemoteEffectStyle = async (remoteStyle: StyleWithoutDocIdGetVO, docId: string) => {
        const effectStyleId = await getRemoteEffectStyleId(remoteStyle, docId)
        if (!effectStyleId) return

        commandInvoker.DEPRECATED_invokeBridge(ApplyEffectStyleForSelectionCommand, { value: effectStyleId })
        commandInvoker.commitUndo()
    }

    const [searchStr, setSearchStr] = useState('')

    const { localStyleGroups, inUseUnknownStyleNodeItems, subscribedRemoteItems, inUseOtherRemoteItems } = useViewState(
        'libraryEffectStyleState',
        {
            localStyleGroups: [],
            inUseUnknownStyleNodeItems: [],
            subscribedRemoteItems: [],
            inUseOtherRemoteItems: [],
            fakeFieldToForceUpdate: false,
        }
    )

    const localEffectStyleGroupList = useMemo(() => createEffectStyleGroup(localStyleGroups), [localStyleGroups])

    const filterLocalEffectStyleGroupList = useMemo(
        () => filterEffectStyleGroup(localEffectStyleGroupList, searchStr),
        [localEffectStyleGroupList, searchStr]
    )

    const { unknownEffectStyleGroupList, unknownEffectStylePublishFile } = useMemo(() => {
        const selectItem = inUseUnknownStyleNodeItems.find((item) => item.id === selectEffectStyleId)
        if (!selectItem) {
            return { unknownEffectStyleGroupList: [], unknownEffectStylePublishFile: '' }
        }

        const { groupName } = parseStyleName(selectItem.name)
        return {
            unknownEffectStyleGroupList: [{ groupName, effectStyles: createEffectStyleDetail([selectItem]) }],
            unknownEffectStylePublishFile: selectItem.publishFile,
        }
    }, [inUseUnknownStyleNodeItems, selectEffectStyleId])

    const filteredUnknownEffectStyleGroupList = useMemo(
        () => filterEffectStyleGroup(unknownEffectStyleGroupList, searchStr),
        [unknownEffectStyleGroupList, searchStr]
    )

    const subscribedDocEffectStyleGroupList = useMemo(
        () => [
            ...createEffectRemoteStyleLibraryList(subscribedRemoteItems, ''),
            ...createEffectRemoteStyleLibraryList(inUseOtherRemoteItems, selectEffectStyleNodeKey),
        ],
        [subscribedRemoteItems, inUseOtherRemoteItems, selectEffectStyleNodeKey]
    )

    const filteredSubscribedDocEffectStyleGroupList = useMemo(() => {
        return filterEffectRemoteStyleLibraryList(subscribedDocEffectStyleGroupList, searchStr)
    }, [subscribedDocEffectStyleGroupList, searchStr])

    const withoutAnyEffectStyleNode =
        !localEffectStyleGroupList.length &&
        !unknownEffectStyleGroupList.length &&
        !subscribedDocEffectStyleGroupList.length

    return {
        effectsToCreateStyle,
        filterLocalEffectStyleGroupList,
        filteredSubscribedDocEffectStyleGroupList,
        withoutAnyEffectStyleNode,
        filteredUnknownEffectStyleGroupList,
        unknownEffectStylePublishFile,
        hasLocalStyle: localEffectStyleGroupList.length > 0,
        hasRemoteStyle: subscribedDocEffectStyleGroupList.length > 0,
        selectEffectStyleNodeKey,
        editModalOpened,
        searchInputChange: setSearchStr,
        editModeStyleId,
        editModePosition,
        setEditModePosition,
        selectEffectStyle,
        deleteEffectStyle,
        openEditModalInsideSelectModal,
        closeEditModalInsideSelectModal,
        selectRemoteEffectStyle,
        getRemoteEffectStyleId,
    }
}

export const [
    SelectEffectStyleContextProvider,
    useEffectStyleSelectHandler,
    useEffectStyleSelectList,
    useEffectsToCreate,
    useEffectStyleEditInsideSelect,
    useRemoteEffectHandler,
] = constate(
    useEffectStyleSelectInternal,
    (ctx) => ({
        onSelect: ctx.selectEffectStyle,
        onSearch: ctx.searchInputChange,
        onDelete: ctx.deleteEffectStyle,
    }),
    (ctx) => ({
        list: ctx.filterLocalEffectStyleGroupList,
        subscribedList: ctx.filteredSubscribedDocEffectStyleGroupList,
        unknownList: ctx.filteredUnknownEffectStyleGroupList,
        unknownPublishFile: ctx.unknownEffectStylePublishFile,
        empty: ctx.withoutAnyEffectStyleNode,
        hasLocalStyle: ctx.hasLocalStyle,
        hasRemoteStyle: ctx.hasRemoteStyle,
        selectEffectStyleNodeKey: ctx.selectEffectStyleNodeKey,
    }),
    (ctx) => ({
        effects: ctx.effectsToCreateStyle,
    }),
    (ctx) => ({
        onEdit: ctx.openEditModalInsideSelectModal,
        onEndEdit: ctx.closeEditModalInsideSelectModal,
        opened: ctx.editModalOpened,
        openedId: ctx.editModeStyleId ?? null,
        position: ctx.editModePosition ?? null,
        setPosition: ctx.setEditModePosition,
    }),
    (ctx) => ({
        onSelect: ctx.selectRemoteEffectStyle,
        getNodeId: ctx.getRemoteEffectStyleId,
    })
)

/* ---------------------------------- utils --------------------------------- */

function createEffectStyleDetail(
    items: DeepRequired<Wukong.DocumentProto.ILibraryEffectStyleNodeItem>[]
): EffectStyleDetail[] {
    return items.map((item): EffectStyleDetail => {
        const { styleName, groupName } = parseStyleName(item.name)
        return {
            // TODO(jiangzg) 统一ts类型
            effectStyle: {
                id: item.id,
                name: item.name,
                description: item.description,
                effects: item.effects,
                publishFile: item.publishFile,
                publishId: item.publishId,
                fromFig: item.fromFig,
            } as unknown as EffectStyleNode,
            styleName,
            groupName,
            iconType: getEffectStyleIcon(item.effects),
        }
    })
}

function createEffectStyleGroup(
    groups: DeepRequired<Wukong.DocumentProto.ILibraryEffectStyleGroup>[]
): EffectStylesGroup[] {
    return groups
        .filter((group) => group.items.length > 0)
        .map((group) => {
            return {
                groupName: group.name,
                effectStyles: createEffectStyleDetail(group.items),
            }
        })
}

function filterEffectStyleGroup(groups: EffectStylesGroup[], searchStr: string) {
    if (!searchStr) {
        return groups
    }

    return groups
        .map((group) => {
            return {
                ...group,
                items: searchMatchedFilter(group.effectStyles, searchStr, (item) => item.effectStyle.name),
            }
        })
        .filter((group) => group.items.length > 0)
}

function createEffectRemoteStyleLibraryList(
    items: DeepRequired<Wukong.DocumentProto.ILibraryEffectStyleRemoteItem>[],
    filterId: string
) {
    return items
        .map((libraryContent) => {
            return {
                libraryId: libraryContent.libraryId,
                libraryName: libraryContent.libraryName,
                docId: libraryContent.documentId,
                effectStyles: libraryContent.groups
                    .map((group): EffectStylesGroupRemote => {
                        return {
                            groupName: group.name,
                            effectStyles: group.items
                                .filter((item) => !filterId || item.id === filterId)
                                .map((item) => {
                                    const { styleName, groupName } = parseStyleName(item.name)
                                    return {
                                        groupName,
                                        styleName,
                                        effectStyle: item as StyleGetVO,
                                        iconType: item.effectStyleIconType || EffectStyleIconType.Default,
                                    }
                                }),
                        }
                    })
                    .filter((group) => group.effectStyles.length),
            }
        })
        .filter((libraryContent) => libraryContent.effectStyles.length)
}

function filterEffectRemoteStyleLibraryList(
    libs: ReturnType<typeof createEffectRemoteStyleLibraryList>,
    searchStr: string
) {
    return sortArrayByKey(
        libs
            .map((lib) => ({
                ...lib,
                effectStyles: (isSearchMatched(lib.libraryName, searchStr)
                    ? lib.effectStyles
                    : lib.effectStyles.map((group) => ({
                          ...group,
                          effectStyles: group.effectStyles.filter((item) =>
                              isSearchMatched(item.effectStyle.name ?? '', searchStr)
                          ),
                      }))
                ).filter((group) => group.effectStyles.length),
            }))
            .filter((lib) => lib.effectStyles.length),
        'libraryName'
    )
}
