import { command } from 'ccstate'
import { LoaderFunctionArgs } from 'react-router-dom'
import { TraceableAbortSignal } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { traceable } from '../../../../../../util/src/ccstate-helper/traceable'
import { redirectWhenAnonymous$, setupUserSession$ } from '../../../atoms/authenticate'
import { optionalUserInfo$ } from '../../../atoms/user-info'
import { afterAuthentication$ } from './after-auth'

// 开启鉴权成功的流程
export const startAuthenticationFlow$ = traceable(
    'hulh01@kanyun.com',
    command(async ({ get, set }, signal: TraceableAbortSignal, args: LoaderFunctionArgs) => {
        const sessionSignal = set(setupUserSession$, signal)

        const userInfo = await get(optionalUserInfo$)

        sessionSignal.throwIfAborted()

        if (!userInfo) {
            await set(redirectWhenAnonymous$, sessionSignal)
            return
        }

        set(afterAuthentication$, sessionSignal, args, userInfo)
    })
)
