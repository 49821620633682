import { Wukong } from '@wukong/bridge-proto'
import { useMemo } from 'react'
import { useViewState } from '../../../../../view-state-bridge'
import { toFixed } from '../../../../utils/to-fixed'
import { useAutoLayoutState, WHValueText } from '../../../design-panel-v2/attributes/components/auto-layout'
import { AutoLayoutWHType } from '../../../design-panel-v2/attributes/components/auto-layout/types'
import { BLENDMODE_TO_NAME_MAP } from '../../../inspect/inspect-property-blend-mode'
import { DevModeInspectBoxModel } from '../dev-mode-inspect-box-model/box-model'
import { DevModeInspectMode } from '../dev-mode-inspect-mode'
import { DevModeProperty, DevModePropertyValue } from '../dev-mode-property-pair'
import style from '../inspect.module.less'
import { translation } from './index.translation'

export function DevModeInspectLayout() {
    const layoutViewState = useViewState('devModeInspectLayoutViewState')
    const cornerSmoothing = useViewState('cornerSmoothing')
    const autolayout = useAutoLayoutState()
    const blendModeViewState = useViewState('blendModeView')

    const textMapper = (type: 'widthType' | 'heightType') => (origin: string) => {
        const value = autolayout.wh[type]

        if (WHValueText[value]) {
            return `${WHValueText[value]}${translation('LeftBracket')}${origin}${translation('RightBracket')}`
        }
        return origin
    }

    const xValue = layoutViewState?.x!
    const yValue = layoutViewState?.y!
    const widthValue = textMapper('widthType')(layoutViewState?.width!)
    const heightValue = textMapper('heightType')(layoutViewState?.height!)
    const cornerRadius = layoutViewState?.cornerRadius
    const cornerSmoothingValue = toFixed(cornerSmoothing?.value! * 100, 0) + '%'
    const rotationValue = layoutViewState?.rotation
    const padding = layoutViewState?.padding!
    const formatSpacing = useMemo(() => {
        const space =
            !autolayout.flex.stackPrimaryAlignItems.isMixed &&
            autolayout.flex.stackPrimaryAlignItems.value ===
                Wukong.DocumentProto.StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_SPACE_EVENTLY
                ? translation('Auto')
                : layoutViewState?.space!

        let counterSpacing = ''
        if (autolayout.flex.stackMode === Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_WRAP) {
            counterSpacing =
                !autolayout.flex.stackCounterAlignContent.isMixed &&
                autolayout.flex.stackCounterAlignContent.value ===
                    Wukong.DocumentProto.StackCounterAlignContent.STACK_COUNTER_ALIGN_CONTENT_SPACE_BETWEEN
                    ? translation('Auto')
                    : layoutViewState?.counterSpacing!
        }
        return !counterSpacing || space === counterSpacing ? space : `${counterSpacing}, ${space}`
    }, [
        autolayout.flex.stackCounterAlignContent.isMixed,
        autolayout.flex.stackCounterAlignContent.value,
        autolayout.flex.stackMode,
        autolayout.flex.stackPrimaryAlignItems.isMixed,
        autolayout.flex.stackPrimaryAlignItems.value,
        layoutViewState?.counterSpacing,
        layoutViewState?.space,
    ])
    const isAutoLayout = autolayout.wh.whType === AutoLayoutWHType.WH_Parent

    const includeRadius = useMemo(() => {
        return cornerRadius !== ''
    }, [cornerRadius])

    const includeCornerSmoothing = useMemo(() => {
        return includeRadius && cornerSmoothing && cornerSmoothing?.value > 0
    }, [includeRadius, cornerSmoothing])

    const propertyPair = useMemo(() => {
        const property = ['X', 'Y', 'W', 'H']
        const value = [xValue, yValue, widthValue, heightValue]

        if (isAutoLayout) {
            if (formatSpacing) {
                property.push(translation('Gap'))
                value.push(formatSpacing)
            }
            if (padding) {
                property.push(translation('Padding'))
                value.push(padding)
            }
        }

        if (includeRadius) {
            property.push(translation('Radius'))
            value.push(cornerRadius!)
        }

        if (includeCornerSmoothing) {
            property.push(translation('CornerSmoothing'))
            value.push(cornerSmoothingValue)
        }

        if (rotationValue !== '0deg') {
            property.push(translation('Rotation'))
            value.push(rotationValue!)
        }

        if (blendModeViewState) {
            const shouldShowBlendMode =
                !blendModeViewState.multiBlendMode &&
                blendModeViewState.blendMode &&
                ![
                    Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
                    Wukong.DocumentProto.BlendMode.BLEND_MODE_PASS_THROUGH,
                ].includes(blendModeViewState.blendMode)

            const shouldShowOpacity = !blendModeViewState.multiOpacity && blendModeViewState.opacity !== 1

            if (shouldShowBlendMode) {
                property.push(translation('Blend'))
                value.push(BLENDMODE_TO_NAME_MAP.get(blendModeViewState.blendMode) ?? '')
            }

            if (shouldShowOpacity) {
                property.push(translation('Opacity'))
                value.push(toFixed(blendModeViewState!.opacity * 100, 0) + '%')
            }
        }
        return [property, value]
    }, [
        blendModeViewState,
        cornerRadius,
        cornerSmoothingValue,
        formatSpacing,
        heightValue,
        includeCornerSmoothing,
        includeRadius,
        isAutoLayout,
        padding,
        rotationValue,
        widthValue,
        xValue,
        yValue,
    ])
    if (!layoutViewState?.show) {
        return null
    }
    return (
        <div data-testid="dev-mode-inspect-layout">
            <DevModeInspectBoxModel />
            <DevModeInspectMode className="-mt-10px mb-8px" />
            <div className={style.panelTitle}>
                <div className={style.title}>{translation('Layout')}</div>
            </div>
            <div className={style.propertyPair}>
                <div>
                    {propertyPair[0].map((property, index) => {
                        return <DevModeProperty key={index} property={property} />
                    })}
                </div>

                <div style={{ overflow: 'hidden' }}>
                    {propertyPair[1].map((value, index) => {
                        return <DevModePropertyValue key={index} value={value} />
                    })}
                </div>
            </div>
        </div>
    )
}
