import {
    DropComponentV2Command,
    GetSelectionNodeIdsCommandForWasm,
    ImportNodeFromLibraryCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { difference } from 'lodash-es'
import { WKToast } from '../../../../../../ui-lib/src'
import { ConcurrentScheduler, throwErrorWithLog } from '../../../../../../util/src'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import { NodeId } from '../../../../document/node/node'
import { WkCLog } from '../../../../kernel/clog/wukong/instance'
import { DocID } from '../../../../kernel/interface/type'
import {
    LibraryResourceDownloader,
    LibraryResourceOssClientType,
} from '../../../../share/component-style-library/service/library-resource-downloader'
import { generateLibraryComponentItemKey } from '../util/key'
import { translation } from './library-node-data-service.translation'

type LibraryNodeDataStoreItem = Pick<
    Required<Wukong.DocumentProto.IVLibraryAssetPanelComponentItem>,
    'nodeDataPath'
> & {
    nodeData: Wukong.DocumentProto.ISerializedExportedDocument
}

export type LibraryNodeDataStoreItemUpdateVO = Pick<
    Wukong.DocumentProto.IVLibraryAssetPanelComponentItem,
    'isLocal' | 'remoteDocId' | 'remoteNodeId' | 'nodeDataPath' | 'localNodeId'
> & {
    ossClientType: LibraryResourceOssClientType
}

export type LibraryNodeDataStoreItemUpdateVOForCreateComponentNode = LibraryNodeDataStoreItemUpdateVO &
    Pick<Wukong.DocumentProto.IArg_cmdDropComponentV2, 'mousePoint' | 'anchorPoint' | 'altKey'>

export interface FetchRemoteRawExportedDocumentReturnType {
    nodeDataPath: string
    toCreateNodeId: NodeId
    remoteDocId: DocID
    rawExportedDocument: Uint8Array
}

export interface FetchRemoteExportedDocumentReturnType
    extends Omit<FetchRemoteRawExportedDocumentReturnType, 'rawExportedDocument'> {
    exportedDocument: Wukong.DocumentProto.ISerializedExportedDocument
}

// 组件库 nodeData 管理
export class LibraryNodeDataService {
    // 组件库 nodeData 并发控制器
    private fetchLibraryNodeDataConcurrentScheduler = ConcurrentScheduler({ delayTime: 1, limitCount: 10 })

    // 组件库 nodeData 缓存
    private remoteLibraryNodeDataStore = new Map<string, LibraryNodeDataStoreItem>()

    private isDestroy = false

    constructor(
        private readonly command: CommandInvoker,
        private readonly libraryResourceDownloader: LibraryResourceDownloader
    ) {}

    public destroy = () => {
        this.fetchLibraryNodeDataConcurrentScheduler.destroy()
        this.remoteLibraryNodeDataStore.clear()
        this.isDestroy = true
    }

    public createComponentNode = async (
        updateVO: LibraryNodeDataStoreItemUpdateVOForCreateComponentNode
    ): Promise<void> => {
        if (this.isDestroy) {
            return
        }
        const asyncFn = async () => {
            // 创建本地组件实例
            if ((updateVO.isLocal || !updateVO.nodeDataPath) && updateVO.localNodeId) {
                return this.command.DEPRECATED_invokeBridge(
                    DropComponentV2Command,
                    Wukong.DocumentProto.Arg_cmdDropComponentV2.create({
                        hasLocal: true,
                        mousePoint: updateVO.mousePoint,
                        anchorPoint: updateVO.anchorPoint,
                        toCreateNodeId: updateVO.localNodeId,
                        altKey: updateVO.altKey,
                    })
                )
            }
            // 创建引用组件实例
            const fetchData = await this.fetchRemoteExportedDocument(updateVO)

            if (!fetchData || this.isDestroy) {
                return
            }

            this.command.DEPRECATED_invokeBridge(
                DropComponentV2Command,
                Wukong.DocumentProto.Arg_cmdDropComponentV2.create({
                    ...fetchData,
                    hasLocal: !!updateVO.localNodeId,
                    mousePoint: updateVO.mousePoint,
                    anchorPoint: updateVO.anchorPoint,
                    altKey: updateVO.altKey,
                })
            )
        }
        const toastKey = WKToast.show(translation('GettingComponentData'), { delay: 2000 })
        await asyncFn()
            .then((res) => {
                WKToast.close(toastKey)
                return res
            })
            .catch(() => {
                WKToast.close(toastKey)
                WKToast.show(translation('FailedToGet'))
                return undefined
            })
    }

    public createRemoteStyleNode = async (
        updateVO: LibraryNodeDataStoreItemUpdateVO & { key?: string }
    ): Promise<NodeId | null> => {
        if (this.isDestroy) {
            return null
        }
        const asyncFn = async () => {
            if ((updateVO.isLocal || !updateVO.nodeDataPath) && updateVO.localNodeId) {
                return updateVO.localNodeId
            }

            const startTime = performance.now()
            const timestamps = []
            const beforeSelectionIds =
                this.command.DEPRECATED_invokeBridge(GetSelectionNodeIdsCommandForWasm).value ?? []
            timestamps.push({ name: '1-beforeSelectionIds', time: performance.now() })

            const fetchData = await this.fetchRemoteExportedDocument(updateVO)

            if (!fetchData) {
                return null
            }

            timestamps.push({ name: '2-fetchRemoteExportedDocument', time: performance.now() })

            // FIX(jiangzhiguang): https://wkong.atlassian.net/browse/WK-9932
            const afterSelectionIds =
                this.command.DEPRECATED_invokeBridge(GetSelectionNodeIdsCommandForWasm).value ?? []
            timestamps.push({ name: '3-afterSelectionIds', time: performance.now() })

            // 选区有变动则不进行样式应用
            if (
                difference(beforeSelectionIds, afterSelectionIds).length ||
                difference(afterSelectionIds, beforeSelectionIds).length
            ) {
                return null
            }

            const { id: newStyleNodeId } = this.command.DEPRECATED_invokeBridge(ImportNodeFromLibraryCommand, {
                exportedDocument: fetchData.exportedDocument,
                toCreateNodeId: fetchData.toCreateNodeId,
                key: updateVO.key,
            })

            timestamps.push({ name: '4-importNodeFromLibrary', time: performance.now() })
            if (timestamps[timestamps.length - 1].time - startTime > 2000) {
                WkCLog.log(
                    '[createRemoteStyleNode] 超时',
                    timestamps.reduce((acc, cur) => ({ ...acc, [cur.name]: Math.round(cur.time) }), {})
                )
            }

            return newStyleNodeId ?? null
        }
        const isCreateVariableNode = updateVO.ossClientType === LibraryResourceOssClientType.Variable
        const isCreateVariableSetNode = updateVO.ossClientType === LibraryResourceOssClientType.VariableSet
        const toastKey = WKToast.show(
            translation(
                isCreateVariableNode
                    ? 'GettingVariableData'
                    : isCreateVariableSetNode
                    ? 'GettingVariableSetData'
                    : 'GettingStyleData'
            ),
            {
                delay: 2000,
            }
        )
        return (
            (await asyncFn()
                .then((res) => {
                    WKToast.close(toastKey)
                    return res
                })
                .catch(() => {
                    WKToast.close(toastKey)
                    WKToast.show(
                        translation(
                            isCreateVariableNode
                                ? 'FailedToGet_synonyms2'
                                : isCreateVariableSetNode
                                ? 'FailedToGet_synonyms3'
                                : 'FailedToGet_synonyms1'
                        )
                    )
                    return undefined
                })) ?? null
        )
    }

    public createRemoteVariableNode = async (
        updateVO: LibraryNodeDataStoreItemUpdateVO & { key?: string }
    ): Promise<NodeId | null> => {
        return this.createRemoteStyleNode(updateVO)
    }

    public createRemoteVariableSetNode = async (
        updateVO: LibraryNodeDataStoreItemUpdateVO & { key?: string }
    ): Promise<NodeId | null> => {
        return this.createRemoteStyleNode(updateVO)
    }

    // 检查缓存中是否有对应记录
    private getRemoteLibraryNodeDataStoreItem = (
        updateVO: LibraryNodeDataStoreItemUpdateVO
    ): LibraryNodeDataStoreItem | null => {
        const storeItem = this.remoteLibraryNodeDataStore.get(generateLibraryComponentItemKey(updateVO))
        if (storeItem && storeItem?.nodeDataPath === updateVO.nodeDataPath) {
            return storeItem
        }
        return null
    }

    // dragStart 时预加载数据
    public preFetchRemoteNodeData = (updateVO: LibraryNodeDataStoreItemUpdateVO): void => {
        if (this.isDestroy) {
            return
        }

        if (!updateVO.nodeDataPath) {
            return
        }

        // 预加载中无须报错
        this.fetchRemoteExportedDocument(updateVO).catch(() => {})
    }

    public fetchRemoteExportedDocument = async (
        updateVO: LibraryNodeDataStoreItemUpdateVO
    ): Promise<Pick<FetchRemoteExportedDocumentReturnType, 'toCreateNodeId' | 'exportedDocument' | 'remoteDocId'>> =>
        this.fetchLibraryNodeDataConcurrentScheduler.add(async () => {
            const { remoteNodeId, remoteDocId } = updateVO
            const storeItem = this.getRemoteLibraryNodeDataStoreItem(updateVO)?.nodeData
            if (storeItem) {
                if (!remoteNodeId || !remoteDocId) {
                    throwErrorWithLog('[fetchRemoteExportedDocument] 部分参数缺失：' + JSON.stringify(updateVO))
                }

                return {
                    exportedDocument: storeItem,
                    toCreateNodeId: remoteNodeId,
                    remoteDocId,
                }
            }

            const fetchData = await this.fetchRemoteExportedDocumentImpl(updateVO)

            this.remoteLibraryNodeDataStore.set(generateLibraryComponentItemKey(updateVO), {
                nodeDataPath: fetchData.nodeDataPath,
                nodeData: fetchData.exportedDocument,
            })

            return fetchData
        })

    public fetchRemoteRawExportedDocument = async (
        updateVO: LibraryNodeDataStoreItemUpdateVO
    ): Promise<
        Pick<FetchRemoteRawExportedDocumentReturnType, 'toCreateNodeId' | 'rawExportedDocument' | 'remoteDocId'>
    > => this.fetchLibraryNodeDataConcurrentScheduler.add(() => this.fetchRemoteRawExportedDocumentImpl(updateVO))

    // 获取 nodePath 指向的 proto 数据
    private fetchRemoteRawExportedDocumentImpl = async (
        updateVO: LibraryNodeDataStoreItemUpdateVO
    ): Promise<FetchRemoteRawExportedDocumentReturnType> => {
        const { remoteNodeId, nodeDataPath, remoteDocId, ossClientType } = updateVO
        if (!nodeDataPath || !remoteDocId || !remoteNodeId) {
            throwErrorWithLog('[fetchRemoteRawExportedDocumentImpl] 部分参数缺失：' + JSON.stringify(updateVO))
        }

        // 防止 docId 误传
        if (remoteDocId === '0:0') {
            throwErrorWithLog('fetch remote document but remoteDocId is document node id')
        }

        return {
            nodeDataPath,
            toCreateNodeId: remoteNodeId,
            remoteDocId,
            rawExportedDocument: new Uint8Array(
                await (await this.libraryResourceDownloader.fetchFile(ossClientType, nodeDataPath)).arrayBuffer()
            ),
        }
    }

    // 获取 nodePath 指向的 proto 数据
    private fetchRemoteExportedDocumentImpl = async (
        updateVO: LibraryNodeDataStoreItemUpdateVO
    ): Promise<FetchRemoteExportedDocumentReturnType> => {
        const { rawExportedDocument, remoteDocId, ...otherData } = await this.fetchRemoteRawExportedDocumentImpl(
            updateVO
        )

        const exportedDocument = Wukong.DocumentProto.SerializedExportedDocument.decode(rawExportedDocument)
        exportedDocument.docId = remoteDocId

        return {
            ...otherData,
            remoteDocId,
            exportedDocument,
        }
    }
}
