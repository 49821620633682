import { IconLoading24 } from '../../../../../ui-lib/src'
import styles from './canvas-search-result-loading.module.less'

export const CanvasSearchResultLoading = () => {
    return (
        <div className={styles.root} data-testid="canvas-search-result-loading">
            <IconLoading24 className={`${styles.loading} animate-spin`}></IconLoading24>
        </div>
    )
}
