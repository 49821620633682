import type { Reader, Writer } from '@wukong/bridge-proto'
import { Wukong } from '@wukong/bridge-proto'

export interface WebSocketMessage<T> {
    code: Wukong.ServerProto.SynergyMessageTypeCode
    bodyType: {
        decode: (reader: Reader | Uint8Array) => T
        encode: (arg: Partial<T>) => Writer
    }
}

export const WsAuthResult: WebSocketMessage<Wukong.ServerProto.AuthResultProto> = {
    code: Wukong.ServerProto.SynergyMessageTypeCode.TC_AuthResult,
    bodyType: Wukong.ServerProto.AuthResultProto,
}

export const WsAuthChanged: WebSocketMessage<Wukong.ServerProto.AuthChangedProto> = {
    code: Wukong.ServerProto.SynergyMessageTypeCode.TC_AuthChanged,
    bodyType: Wukong.ServerProto.AuthChangedProto,
}

export const WsDocument: WebSocketMessage<Wukong.DocumentProto.SynergyDocument> = {
    code: Wukong.ServerProto.SynergyMessageTypeCode.TC_Document,
    bodyType: Wukong.DocumentProto.SynergyDocument,
}

export const WsBatchOperation: WebSocketMessage<Wukong.DocumentProto.SynergyBatchOperation> = {
    code: Wukong.ServerProto.SynergyMessageTypeCode.TC_BatchOperation,
    bodyType: Wukong.DocumentProto.SynergyBatchOperation,
}

export const WsBatchOperationAck: WebSocketMessage<Wukong.DocumentProto.SynergyBatchOperationAck> = {
    code: Wukong.ServerProto.SynergyMessageTypeCode.TC_BatchOperationAck,
    bodyType: Wukong.DocumentProto.SynergyBatchOperationAck,
}

export const WsHeartbeatPong: WebSocketMessage<Wukong.ServerProto.PongProto> = {
    code: Wukong.ServerProto.SynergyMessageTypeCode.TC_Pong,
    bodyType: Wukong.ServerProto.PongProto,
}

export const WsMembership: WebSocketMessage<Wukong.DocumentProto.MembershipProto> = {
    code: Wukong.ServerProto.SynergyMessageTypeCode.TC_Membership,
    bodyType: Wukong.DocumentProto.MembershipProto,
}

export const WsSyncUserProperties: WebSocketMessage<Wukong.DocumentProto.SyncUserPropertiesProto> = {
    code: Wukong.ServerProto.SynergyMessageTypeCode.TC_SyncUserProperties,
    bodyType: Wukong.DocumentProto.SyncUserPropertiesProto,
}

export const WsUserEnter: WebSocketMessage<Wukong.DocumentProto.UserEnterProto> = {
    code: Wukong.ServerProto.SynergyMessageTypeCode.TC_UserEnter,
    bodyType: Wukong.DocumentProto.UserEnterProto,
}

export const WsUserQuit: WebSocketMessage<Wukong.DocumentProto.UserQuitProto> = {
    code: Wukong.ServerProto.SynergyMessageTypeCode.TC_UserQuit,
    bodyType: Wukong.DocumentProto.UserQuitProto,
}

export const WsUpdateUserProperties: WebSocketMessage<Wukong.DocumentProto.UpdateUserPropertiesProto> = {
    code: Wukong.ServerProto.SynergyMessageTypeCode.TC_UpdateUserProperties,
    bodyType: Wukong.DocumentProto.UpdateUserPropertiesProto,
}

export const WsUserSynergyRoleChange: WebSocketMessage<Wukong.DocumentProto.UserSynergyRoleChangeProto> = {
    code: Wukong.ServerProto.SynergyMessageTypeCode.TC_UserSynergyRoleChange,
    bodyType: Wukong.DocumentProto.UserSynergyRoleChangeProto,
}

export const WsUserInactive: WebSocketMessage<Wukong.ServerProto.UserInactiveProto> = {
    code: Wukong.ServerProto.SynergyMessageTypeCode.TC_UserInactive,
    bodyType: Wukong.ServerProto.UserInactiveProto,
}

export const WsUserActive: WebSocketMessage<Wukong.ServerProto.UserActiveProto> = {
    code: Wukong.ServerProto.SynergyMessageTypeCode.TC_UserActive,
    bodyType: Wukong.ServerProto.UserActiveProto,
}

export const WsMirrorPreview: WebSocketMessage<Wukong.ServerProto.MirrorPreviewProto> = {
    code: Wukong.ServerProto.SynergyMessageTypeCode.TC_MirrorPreview,
    bodyType: Wukong.ServerProto.MirrorPreviewProto,
}

export const WsSceneGraphQuery: WebSocketMessage<Wukong.ServerProto.SceneGraphQueriesProto> = {
    code: Wukong.ServerProto.SynergyMessageTypeCode.TC_SceneGraphQuery,
    bodyType: Wukong.ServerProto.SceneGraphQueriesProto,
}

export const WsSceneGraphReply: WebSocketMessage<Wukong.ServerProto.SceneGraphQueriesProto> = {
    code: Wukong.ServerProto.SynergyMessageTypeCode.TC_SceneGraphReply,
    bodyType: Wukong.ServerProto.SceneGraphQueriesProto,
}
