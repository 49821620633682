import { DELETE, GET, POST, PUT } from '@tutor/network-core'
import { ValidSearchParams, queryStringify } from '../../../../util/src'
import {
    AssetMigrationMode,
    GenerateOrgAccessTokenVO,
    LoginPolicy,
    OrgAccessTokenVO,
    OrgEventVO,
    OrgID,
    OrganizationOperationQueryParams,
    OrganizationOperationQueryParamsV2,
    OrganizationSamlSSOVO,
    OrganizationTeamVO,
    OrganizationVO,
    PlanAndUserStateVO,
    PublicStatus,
    QueryOrganizationSeatsParams,
    QueryOrganizationSeatsResponse,
    SeatUpgradeType,
    TeamBriefVO,
    UserBriefVO,
    UserID,
    UserOperationVO,
    WorkspaceAndTeamListBriefVO,
    type OrganizationSeatVO,
    type OrganizationSummaryVO,
    type UpgradeOrganizationVO,
} from '../interface/type'
import type { UserEditDocVO } from '../interface/user-edit-doc'
import { omitByValue } from '../util/omit-by-value'
import { BaseCommonRequest } from './base-request'

@POST
export class UpdateAssetMigrationMode extends BaseCommonRequest<void> {
    constructor(private readonly orgId: OrgID, private readonly importMode: AssetMigrationMode) {
        super()
    }
    public override requestArgument() {
        return {
            orgId: this.orgId,
            importMode: this.importMode,
        }
    }
    public override requestUrl() {
        return `organizations/import-mode`
    }
}

// 查询用户可以切换的组织、用户在组织下的角色、用户在组织下的草稿箱id
@GET
export class GetOrganizations extends BaseCommonRequest<OrganizationVO[]> {
    constructor(private readonly isNotify?: boolean) {
        super()
    }
    public override additionalRequestHeaders(): { [key: string]: string } | undefined {
        if (this.isNotify) {
            return {
                'X-QoS-Level': 'notify',
            }
        }
        return undefined
    }
    public override requestUrl() {
        return `organizations`
    }
}

// 新增接口查询组织成员的团队列表
@GET
export class GetOrganizationMemberTeams extends BaseCommonRequest<TeamBriefVO[]> {
    constructor(private readonly orgId: OrgID, private readonly userId: number) {
        super()
    }
    public override requestUrl() {
        return `organizations/teams/${this.orgId}/${this.userId}`
    }
}

// 新增接口查询组织团队列表
@GET
export class GetOrganizationTeams extends BaseCommonRequest<OrganizationTeamVO[]> {
    constructor(private readonly orgId: OrgID, private readonly queryString?: string) {
        super()
    }
    public override requestUrl() {
        return `organizations/teams/${this.orgId}`
    }
    public override requestArgument() {
        return omitByValue({
            queryString: this.queryString,
        })
    }
    public override requestDidFailed(): boolean {
        return false
    }
}
// 新增接口查询组织团队列表
@GET
export class GetOrganizationTeamsV2 extends BaseCommonRequest<OrganizationTeamVO[]> {
    constructor(
        private readonly orgId: OrgID,
        private readonly params: { queryString?: string; workspaceIds?: string[] }
    ) {
        super()
    }
    public override requestUrl() {
        return `organizations/teams/${this.orgId}/v2`
    }
    public override requestArgument() {
        return omitByValue({
            queryString: this.params.queryString,
            workspaceIds: this.params.workspaceIds?.join(','),
        })
    }
    public override requestDidFailed(): boolean {
        return false
    }
}

// 只根据邮箱进行精准匹配，同时匹配组织域和邮箱是否注册，符合条件则返回用户信息，不符合返回空的UserBriefVO
@GET
export class GetOrganizationMemberByEmail extends BaseCommonRequest<UserBriefVO> {
    constructor(private readonly orgId: OrgID, private readonly queryEmail?: string) {
        super()
    }
    public override requestUrl() {
        return `organizations/member/email-match`
    }
    public override requestArgument() {
        return omitByValue({
            queryEmail: this.queryEmail,
            orgId: this.orgId,
        })
    }
    public override requestDidFailed(): boolean {
        return false
    }
}

// 只根据邮箱进行精准匹配，同时匹配组织域和邮箱是否注册，符合条件则返回用户信息，不符合返回空的UserBriefVO
@GET
export class GetOrganizationMemberByEmailForWorkspaceAdmin extends BaseCommonRequest<UserBriefVO> {
    constructor(
        private readonly orgId: OrgID,
        private readonly workspaceId: string,
        private readonly queryEmail?: string
    ) {
        super()
    }
    public override requestUrl() {
        return `organizations/member/email-match/for-workspace-admin`
    }
    public override requestArgument() {
        return omitByValue({
            queryEmail: this.queryEmail,
            orgId: this.orgId,
            workspaceId: this.workspaceId,
        })
    }
}

// 移除组织成员
@DELETE
export class DeleteOrgMember extends BaseCommonRequest {
    constructor(private orgId: OrgID, private userId: number) {
        super()
    }
    public override requestArgument() {
        return {
            userId: this.userId,
            orgId: this.orgId,
        }
    }
    public override requestUrl() {
        return `organizations/member`
    }
}

// 批量移除组织成员
@DELETE
export class BatchDeleteOrgMember extends BaseCommonRequest {
    constructor(private orgId: OrgID, private userIds: number[]) {
        super()
    }
    public override requestBody() {
        return {
            userIds: this.userIds,
            orgId: this.orgId,
        }
    }
    public override requestUrl() {
        return `organizations/members`
    }
}

// 查询组织下的活动日志
@POST
export class GetOrganizationOperations extends BaseCommonRequest<UserOperationVO[]> {
    constructor(private readonly orgId: OrgID, private readonly params?: OrganizationOperationQueryParams) {
        super()
    }
    public override requestUrl() {
        if (this.params) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { userIds, ...rest } = this.params
            return `organizations/operations?orgId=${this.orgId}&${queryStringify(
                rest as unknown as ValidSearchParams
            )}`
        }
        return `organizations/operations?orgId=${this.orgId}`
    }
    public override requestBody() {
        return this.params?.userIds || []
    }
    public override requestDidFailed(): boolean {
        return false
    }
}

// 查询组织下的活动日志
@POST
export class GetOrganizationOperationsV2 extends BaseCommonRequest<UserOperationVO[]> {
    constructor(private readonly orgId: OrgID, private readonly params: OrganizationOperationQueryParamsV2) {
        super()
    }
    public override requestUrl() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { userEmails, ...rest } = this.params
        return `organizations/operations-v2?orgId=${this.orgId}&${queryStringify(rest as unknown as ValidSearchParams)}`
    }
    public override requestBody() {
        return this.params?.userEmails ?? []
    }
    public override requestDidFailed(): boolean {
        return false
    }
}

// 查询组织下的活动日志
@POST
export class GetOrganizationOperationsV3 extends BaseCommonRequest<UserOperationVO[]> {
    constructor(private readonly orgId: OrgID, private readonly params: OrganizationOperationQueryParamsV2) {
        super()
    }
    public override requestUrl() {
        return 'organizations/operations-v2'
    }
    public override requestArgument() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { userEmails, ...rest } = this.params

        // 处理数组，如果为空则设为 undefined
        const events = rest.events?.length ? rest.events.join(',') : undefined
        const teamIds = rest.teamIds?.length ? rest.teamIds.join(',') : undefined
        const workspaceIds = rest.workspaceIds?.length ? rest.workspaceIds.join(',') : undefined

        return omitByValue({
            orgId: this.orgId,
            events,
            teamIds,
            workspaceIds,
            startTime: rest.startTime,
            endTime: rest.endTime,
            pageSize: rest.pageSize,
            page: rest.page,
        })
    }
    public override requestBody() {
        return this.params?.userEmails ?? []
    }
    public override requestDidFailed(): boolean {
        return false
    }
}

// 导出组织下的活动日志
@POST
export class ExportOrganizationOperations extends BaseCommonRequest<UserOperationVO[]> {
    constructor(private readonly orgId: OrgID, private readonly params?: OrganizationOperationQueryParams) {
        super()
    }
    public override requestUrl() {
        if (this.params) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { userIds, ...rest } = this.params
            return `organizations/operations/export?orgId=${this.orgId}&${queryStringify(
                rest as unknown as ValidSearchParams
            )}`
        }
        return `organizations/operations/export?orgId=${this.orgId}`
    }
    public override requestBody() {
        return this.params?.userIds || []
    }
    public override additionalRequestHeaders(): { [key: string]: string } | undefined {
        return {
            'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'Time-Zone-Offset': new Date().getTimezoneOffset().toString(),
        }
    }
    public override requestDidFailed(): boolean {
        return false
    }
}

// 导出组织下的活动日志
@POST
export class ExportOrganizationOperationsV2 extends BaseCommonRequest<UserOperationVO[]> {
    constructor(private readonly orgId: OrgID, private readonly params: OrganizationOperationQueryParamsV2) {
        super()
    }
    public override requestUrl() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { userEmails, ...rest } = this.params
        return `organizations/operations/export-v2?orgId=${this.orgId}&${queryStringify(
            rest as unknown as ValidSearchParams
        )}`
    }
    public override requestBody() {
        return this.params?.userEmails ?? []
    }
    public override additionalRequestHeaders(): { [key: string]: string } | undefined {
        return {
            'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'Time-Zone-Offset': new Date().getTimezoneOffset().toString(),
        }
    }
    public override requestDidFailed(): boolean {
        return false
    }
}

// 查询组织下的所有事件
@GET
export class GetOrganizationEvents extends BaseCommonRequest<OrgEventVO[]> {
    constructor(private readonly orgId: OrgID) {
        super()
    }
    public override requestUrl() {
        return 'organizations/events'
    }
    public override requestArgument() {
        return {
            orgId: this.orgId,
        }
    }
    public override requestDidFailed(): boolean {
        return false
    }
}

// 查询组织管理员
@GET
export class GetOrganizationAdmins extends BaseCommonRequest<UserBriefVO[]> {
    constructor(private readonly orgId: OrgID) {
        super()
    }
    public override requestUrl() {
        return `organizations/admins/${this.orgId}`
    }
}

// 查询企业的履约状态、用户当前是否有宽限期等
@GET
export class QueryOrgAndUserState extends BaseCommonRequest<PlanAndUserStateVO> {
    constructor(private readonly orgId: OrgID) {
        super()
    }

    public override requestArgument() {
        return {
            orgId: this.orgId,
        }
    }

    public override requestUrl() {
        return `organizations/state`
    }
}

// 修改企业席位升级方式
@PUT
export class UpdateOrgSeatUpgradeType extends BaseCommonRequest<void> {
    constructor(
        private readonly orgId: OrgID,
        private readonly seatUpgradeType: Exclude<SeatUpgradeType, SeatUpgradeType.Unknown>
    ) {
        super()
    }

    public override requestBody() {
        return {
            orgId: this.orgId,
            seatUpgradeType: this.seatUpgradeType,
        }
    }

    public override requestUrl() {
        return `organizations/seat-upgrade-type`
    }
}

// 查询企业概览
@GET
export class QueryOrganizationSummary extends BaseCommonRequest<OrganizationSummaryVO> {
    constructor(private readonly orgId: OrgID) {
        super()
    }

    public override requestArgument() {
        return {
            orgId: this.orgId,
        }
    }

    public override requestUrl() {
        return `organizations/summary`
    }
}

// 查询企业席位列表
@GET
export class QueryOrganizationSeats extends BaseCommonRequest<OrganizationSeatVO[]> {
    constructor(private readonly orgId: OrgID, private readonly queryString?: string) {
        super()
    }

    public override requestArgument() {
        return omitByValue({
            orgId: this.orgId,
            queryString: this.queryString,
        })
    }

    public override requestUrl() {
        return `organizations/seats`
    }

    public override additionalRequestHeaders(): { [key: string]: string } | undefined {
        return {
            'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'Time-Zone-Offset': new Date().getTimezoneOffset().toString(),
        }
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 「企业管理-成员列表」
@GET
export class QueryOrganizationSeatsV2 extends BaseCommonRequest<QueryOrganizationSeatsResponse> {
    constructor(private readonly orgId: OrgID, private readonly params: QueryOrganizationSeatsParams) {
        super()
    }

    public override requestArgument() {
        return omitByValue({
            queryString: this.params.queryString,
            accountTypes: this.params.accountTypes?.join(','),
            workspaceIds: this.params.workspaceIds?.join(','),
            seatTypes: this.params.seatTypes?.join(','),
            hasNewPaidSeat: this.params.hasNewPaidSeat?.toString(),
            gracePeriodPaidSeat: this.params.gracePeriodPaidSeat?.toString(),
        })
    }

    public override requestUrl() {
        return `organizations/seats/${this.orgId}/v2`
    }

    public override additionalRequestHeaders(): { [key: string]: string } | undefined {
        return {
            'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'Time-Zone-Offset': new Date().getTimezoneOffset().toString(),
        }
    }

    public override requestDidFailed(): boolean {
        return false
    }
}

// 「企业管理-工作区成员列表」
@GET
export class QueryOrganizationWorkspaceSeats extends BaseCommonRequest<QueryOrganizationSeatsResponse> {
    constructor(
        private readonly orgId: OrgID,
        private readonly workspaceId: string,
        private readonly params: Omit<QueryOrganizationSeatsParams, 'workspaceIds'>
    ) {
        super()
    }
    public override requestArgument() {
        return omitByValue({
            queryString: this.params.queryString,
            accountTypes: this.params.accountTypes?.join(','),
            seatTypes: this.params.seatTypes?.join(','),
            hasNewPaidSeat: this.params.hasNewPaidSeat?.toString(),
            gracePeriodPaidSeat: this.params.gracePeriodPaidSeat?.toString(),
        })
    }
    public override requestUrl() {
        return `organizations/seats/${this.orgId}/${this.workspaceId}/v2`
    }
    public override additionalRequestHeaders(): { [key: string]: string } | undefined {
        return {
            'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'Time-Zone-Offset': new Date().getTimezoneOffset().toString(),
        }
    }
    public override requestDidFailed(): boolean {
        return false
    }
}

// 查询一个席位在企业下的最近5条活动日志
@GET
export class QueryOrganizationSeatOperations extends BaseCommonRequest<UserOperationVO[]> {
    constructor(private readonly orgId: OrgID, private readonly userId: UserID) {
        super()
    }

    public override requestArgument() {
        return {
            orgId: this.orgId,
            userId: this.userId,
        }
    }

    public override requestUrl() {
        return `organizations/seats/operations`
    }
}

@PUT
export class PublicLinkSharingOperations extends BaseCommonRequest<void> {
    constructor(private readonly orgId: OrgID, private readonly publicLinkSharing: boolean) {
        super()
    }

    public override requestBody() {
        return {
            orgId: this.orgId,
            publicLinkSharing: this.publicLinkSharing,
        }
    }

    public override requestUrl() {
        return `organizations/public-link-sharing`
    }
}

// 查询一个席位在企业下的最近5条标记编辑日志
export class QueryOrganizationSeatEditDocs extends BaseCommonRequest<UserEditDocVO[]> {
    constructor(private readonly orgId: OrgID, private readonly userId: UserID) {
        super()
    }

    public override requestArgument() {
        return {
            orgId: this.orgId,
            userId: this.userId,
        }
    }

    public override requestUrl() {
        return `organizations/seats/edits`
    }
}

@GET
export class GetOrganizationsSSO extends BaseCommonRequest<OrganizationSamlSSOVO> {
    constructor(private readonly orgId: OrgID) {
        super()
    }

    public override requestUrl() {
        return `organizations/${this.orgId}/saml-sso`
    }
}

@POST
export class EnableSSO extends BaseCommonRequest {
    constructor(
        private file: File,
        private enableSamlRequest: {
            idpIssuer: string
            idpSSOUrl: string
            orgId: OrgID
        }
    ) {
        super()
    }

    public override requestUrl() {
        return `organizations/enable-saml-sso`
    }
    public override requestBody() {
        const ret = new FormData()
        ret.append('file', this.file)
        ret.append('idpIssuer', this.enableSamlRequest.idpIssuer)
        ret.append('idpSSOUrl', this.enableSamlRequest.idpSSOUrl)
        ret.append('orgId', this.enableSamlRequest.orgId)
        return ret
    }
    public override requestDidFailed() {
        return false
    }
}

@POST
export class CheckSSO extends BaseCommonRequest {
    constructor(
        private file: File,
        private enableSamlRequest: {
            idpIssuer: string
            idpSSOUrl: string
            orgId: OrgID
        }
    ) {
        super()
    }

    public override requestDidFailed() {
        return false
    }

    public override requestUrl() {
        return `organizations/check-saml-sso`
    }
    public override requestBody() {
        const ret = new FormData()
        ret.append('file', this.file)
        ret.append('idpIssuer', this.enableSamlRequest.idpIssuer)
        ret.append('idpSSOUrl', this.enableSamlRequest.idpSSOUrl)
        ret.append('orgId', this.enableSamlRequest.orgId)
        return ret
    }
}

// 查询企业下的所有访问令牌
@GET
export class GetOrgAccessTokens extends BaseCommonRequest<OrgAccessTokenVO[]> {
    constructor(private readonly orgId: OrgID) {
        super()
    }
    public override requestUrl() {
        return `organizations/${this.orgId}/access-tokens`
    }
}

// 创建企业访问令牌
@POST
export class GenerateOrgAccessToken extends BaseCommonRequest<GenerateOrgAccessTokenVO> {
    constructor(private readonly orgId: OrgID, private readonly name: string, private readonly expiration?: number) {
        super()
    }
    public override requestUrl() {
        return `organizations/${this.orgId}/access-tokens`
    }
    public override requestBody() {
        return {
            name: this.name,
            expiration: this.expiration,
        }
    }
}

// 修改登录方式校验
@POST
export class CheckLoginPolicy extends BaseCommonRequest {
    constructor(private readonly orgId: OrgID, private readonly orgLoginPolicy: LoginPolicy) {
        super()
    }
    public override requestUrl() {
        return `organizations/check-login-policy`
    }
    public override requestBody() {
        return {
            orgId: this.orgId,
            orgLoginPolicy: this.orgLoginPolicy,
        }
    }
    public override requestDidFailed() {
        return false
    }
}

// 修改登录方式
@PUT
export class UpdateLoginPolicy extends BaseCommonRequest {
    constructor(private readonly orgId: OrgID, private readonly orgLoginPolicy: LoginPolicy) {
        super()
    }
    public override requestUrl() {
        return `organizations/login-policy`
    }
    public override requestBody() {
        return {
            orgId: this.orgId,
            orgLoginPolicy: this.orgLoginPolicy,
        }
    }
    public override requestDidFailed() {
        return false
    }
}

// 删除企业访问令牌
@DELETE
export class DeleteOrgAccessToken extends BaseCommonRequest {
    constructor(private readonly orgId: OrgID, private readonly tokenId: number) {
        super()
    }
    public override requestUrl() {
        return `organizations/${this.orgId}/access-tokens/${this.tokenId}`
    }
}

// 用于升级企业时查询升级相关的信息
@GET
export class QueryUpgradeOrganizationInfo extends BaseCommonRequest<UpgradeOrganizationVO> {
    constructor(private readonly orgId: OrgID) {
        super()
    }

    public override requestArgument() {
        return {
            orgId: this.orgId,
        }
    }

    public override requestUrl() {
        return `organizations/upgrade-info`
    }
}

@GET
export class ExportOrganizationTeams extends BaseCommonRequest {
    constructor(
        private readonly orgId: OrgID,
        private readonly params: {
            queryString?: string
            teamPublicStatusEnums?: PublicStatus[]
            workspaceIds?: string[]
            isTeamMember?: boolean
            hasOwner?: boolean
        }
    ) {
        super()
    }
    public override additionalRequestHeaders(): { [key: string]: string } | undefined {
        return {
            'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'Time-Zone-Offset': new Date().getTimezoneOffset().toString(),
        }
    }

    public override requestArgument() {
        return omitByValue({
            queryString: this.params.queryString,
            teamPublicStatusEnums: this.params.teamPublicStatusEnums?.join(','),
            workspaceIds: this.params.workspaceIds?.join(','),
            isTeamMember: this.params.isTeamMember?.toString(),
            hasOwner: this.params.hasOwner?.toString(),
        })
    }

    public override requestUrl() {
        return `organizations/export-teams/${this.orgId}`
    }
}

// 「企业管理-成员列表」导出成员列表
@GET
export class ExportOrganizationMembers extends BaseCommonRequest<void> {
    constructor(private readonly orgId: OrgID, private readonly params: QueryOrganizationSeatsParams) {
        super()
    }
    public override requestUrl() {
        return `organizations/export-seats/${this.orgId}`
    }
    public override requestArgument() {
        return omitByValue({
            queryString: this.params.queryString,
            accountTypes: this.params.accountTypes?.join(','),
            workspaceIds: this.params.workspaceIds?.join(','),
            seatTypes: this.params.seatTypes?.join(','),
            hasNewPaidSeat: this.params.hasNewPaidSeat?.toString(),
            gracePeriodPaidSeat: this.params.gracePeriodPaidSeat?.toString(),
        })
    }
    public override additionalRequestHeaders(): { [key: string]: string } | undefined {
        return {
            'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'Time-Zone-Offset': new Date().getTimezoneOffset().toString(),
        }
    }
}

// 「企业管理-工作区管理-成员列表」导出成员列表
@GET
export class ExportOrganizationWorkspaceMembers extends BaseCommonRequest {
    constructor(
        private readonly orgId: OrgID,
        private readonly workspaceId: string,
        private readonly params: Omit<QueryOrganizationSeatsParams, 'workspaceId'>
    ) {
        super()
    }
    public override requestUrl() {
        return `organizations/export-seats/${this.orgId}/${this.workspaceId}`
    }
    public override requestArgument() {
        return omitByValue({
            queryString: this.params.queryString,
            accountTypes: this.params.accountTypes?.join(','),
            seatTypes: this.params.seatTypes?.join(','),
            hasNewPaidSeat: this.params.hasNewPaidSeat?.toString(),
            gracePeriodPaidSeat: this.params.gracePeriodPaidSeat?.toString(),
        })
    }
    public override additionalRequestHeaders(): { [key: string]: string } | undefined {
        return {
            'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'Time-Zone-Offset': new Date().getTimezoneOffset().toString(),
        }
    }
}

// 「活动日志页」查询工作区团队列表
@GET
export class GetOrganizationWorkspaceTeamList extends BaseCommonRequest<WorkspaceAndTeamListBriefVO[]> {
    constructor(private readonly orgId: OrgID) {
        super()
    }
    public override requestUrl() {
        return 'organizations/operations/workspace-team-list'
    }
    public override requestArgument() {
        return omitByValue({
            orgId: this.orgId,
        })
    }
}
