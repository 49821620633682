import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    RestoreComponent: '恢复组件',
    Cancel: '取消',
    Restore: '恢复',
    ComponentRemoved: '组件母版已删除',
    ComponentRemoved_synonyms1: '组件已被删除，恢复后可继续编辑使用。',
    UnknownLibrary: '未知组件库',
    MovedTo: '该组件已被移动到“{{moveTo}}”',
    MovedToLong:
        '该组件已被移动到“{{moveTo}}”，你可以更新实例来保持与组件的连接关系，你也可以恢复组件的副本，但组件副本不会与实例保持连接关系，除非将移走的组件移回此文件并发布',
} as const

export const enTranslation = {
    RestoreComponent: 'Restore Component',
    Cancel: 'Cancel',
    Restore: 'Restore',
    ComponentRemoved: 'Main component removed',
    ComponentRemoved_synonyms1: 'The main component has been deleted. Restore it to continue making edits.',
    UnknownLibrary: 'Unknown library',
    MovedTo: "This component was moved to '{{moveTo}}'",
    MovedToLong: `This component has been moved to "{{moveTo}}". To reconnect your instances to the newly moved component, you can accept its updates. Or you can publish a copy, and the copy will not retain connections with the instances unless you cut and paste the original component back into this file to publish it.`,
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
