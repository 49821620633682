import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Title: '创建数值变量',
    Collection: '合集',
    Name: '名称',
    Description: '描述',
    DescriptionPlaceholder: '请输入描述',
    Value: '值',
    Create: '创建变量',
    InvalidVariableName: '变量名称无效',
} as const

export const enTranslation = {
    Title: 'Create number variable',
    Collection: 'Collection',
    Name: 'Name',
    Description: 'Description',
    DescriptionPlaceholder: 'What is it for?',
    Value: 'Value',
    Create: 'Create variable',
    InvalidVariableName: 'Invalid variable name',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
