import { translation } from './onboarding-service.translation'
/* eslint-disable no-restricted-imports */
import { Location } from 'history'
import { createSelectors, createStore, domLocation } from '../../../../util/src'
import { FrogCustomExtend, WKFrog } from '../../kernel/frog'
import { UserVOV2, UserVOV2Status } from '../../kernel/interface/account'
import {
    BasicTutorialAboutsMap,
    FilesTutorialAboutsMap,
    FirstOnboardingFrogMap,
    getBasicTutorialStep,
    getDevTutorialStep,
    getFilesTutorialStep,
    OnboardingAction,
    OnboardingPopupStatus,
    OnboardingScene,
    OnboardingStatus,
    RecoverOnboardingFrogMap,
    ResetOnboardingFrogMap,
    TutorialTriggerFile,
} from '../../kernel/interface/onboarding'
import { BatchUpdateOnboardingStatus, GetUserRequest, UpdateOnboardingStatus } from '../../kernel/request/user'
import { ServiceClass } from '../../kernel/util/service-class'
import { shouldJumpToAIPlayGround } from '../../ui/component/ai/ai-gen-ui/util'

// 拥有多步骤的类型
type SEVERAL_STEPS_SCENE = OnboardingScene.Space | OnboardingScene.Editor | OnboardingScene.DevMode

interface RouteInfo {
    scene: SEVERAL_STEPS_SCENE
    isDraft: boolean
    pathname: string
}

export enum SpaceOnboardingType {
    StandradFinished, // 标准工作台完成引导
    StandradUnfinished, // 标准版工作台未完成引导
    StandradFinishedOrgUnfinished, // 标准版工作台完成引导但组织版未完成引导
    OrgUnfinished, // 组织版工作台未完成引导
    OrgFinished, // 组织版工作台完成引导
}

function getRouteInterruptAbout(scene: SEVERAL_STEPS_SCENE, pathname: string, isCreateFile?: boolean) {
    let about = FilesTutorialAboutsMap.other
    const location = domLocation()
    if (scene === OnboardingScene.Editor || scene === OnboardingScene.DevMode) {
        about = /\/file\//.test(pathname) ? BasicTutorialAboutsMap.other : BasicTutorialAboutsMap.returnSpace
    } else if (/\/file\//.test(location.pathname)) {
        about = isCreateFile ? FilesTutorialAboutsMap.newFile : FilesTutorialAboutsMap.openFile
    } else if (/\/recent$/.test(location.pathname)) {
        about = FilesTutorialAboutsMap.recent
    } else if (/\/favorites$/.test(location.pathname)) {
        about = FilesTutorialAboutsMap.favorite
    } else if (/\/trash$/.test(location.pathname)) {
        about = FilesTutorialAboutsMap.trash
    } else if (/\/(team|project)\//.test(location.pathname)) {
        about = FilesTutorialAboutsMap.team
    }

    switch (scene) {
        case OnboardingScene.Editor: {
            return {
                basic_tutorial_where_abouts: about,
            }
        }
        case OnboardingScene.DevMode: {
            return {
                dev_tutorial_where_abouts: about,
            }
        }
        case OnboardingScene.Space: {
            return {
                files_tutorial_where_abouts: about,
            }
        }
    }
}

function getModalInterruptAbout(scene: SEVERAL_STEPS_SCENE, title?: string) {
    let about = FilesTutorialAboutsMap.other
    if (title === translation('Import')) {
        about = FilesTutorialAboutsMap.importFile
    } else if (title === translation('MigrateLibrary')) {
        about = FilesTutorialAboutsMap.componentMigration
    } else if (title === translation('SelectFiles')) {
        about = FilesTutorialAboutsMap.ai
    } else if (title === translation('ShareFile')) {
        about = BasicTutorialAboutsMap.share
    }

    switch (scene) {
        case OnboardingScene.Editor: {
            return {
                basic_tutorial_where_abouts: about,
            }
        }
        case OnboardingScene.DevMode: {
            return {
                dev_tutorial_where_abouts: about,
            }
        }
        case OnboardingScene.Space: {
            return {
                files_tutorial_where_abouts: about,
            }
        }
    }
}

export interface OnboardingZustandStore {
    step: number
    popupStatus: OnboardingPopupStatus
    action: OnboardingAction
    demotionDevModeStatus: OnboardingStatus | undefined
}

/**
 * 用于维护引导的 context
 */
export class OnboardingService extends ServiceClass {
    private zustandStore = createStore<OnboardingZustandStore>(() => ({
        step: -1,
        popupStatus: OnboardingPopupStatus.Hide,
        action: OnboardingAction.None,
        demotionDevModeStatus: undefined,
    }))
    public useZustandStore = createSelectors(this.zustandStore)

    // 是否是初始化用户，当前用户需要填入信息，不展示 onboarding
    private isInitUser = false
    // 是否是组织版
    private isOrg = false

    private editorStatus?: OnboardingStatus
    private spaceStatus?: OnboardingStatus
    private aiStatus?: OnboardingStatus
    private aiLibraryMaintainStatus?: OnboardingStatus
    private orgBenchMemberStatus?: OnboardingStatus
    private devModeStatus?: OnboardingStatus
    private variableFeatureStatus?: OnboardingStatus

    private isLoading = true
    private routeInfo?: RouteInfo
    private stepLength = 0
    // 重置引导
    private isRestart = false
    private isResetTour = false
    private isCreateFile = false
    private tutorialTriggerFile?: TutorialTriggerFile
    private interruptByModal?: string

    // frog
    private isFirstOnboarding = false
    private isResetOnboarding = false
    private startTimestamp = 0

    // ---------------------------private method-------------------------------------

    private get currentStep(): number {
        return this.zustandStore.getState().step
    }

    private get currentPopupStatus(): OnboardingPopupStatus {
        return this.zustandStore.getState().popupStatus
    }

    /**
     * 处于某些状态下不开启引导流程
     */
    private get isIllegalStatus() {
        return this.isLoading || !this.routeInfo || this.isInitUser
    }

    private get spaceOnboardingType(): SpaceOnboardingType {
        // 用户处于组织版
        if (this.isOrg) {
            if (this.isStatusSilenced(this.spaceStatus)) {
                return this.isStatusSilenced(this.orgBenchMemberStatus)
                    ? SpaceOnboardingType.OrgFinished
                    : SpaceOnboardingType.StandradFinishedOrgUnfinished
            } else {
                return SpaceOnboardingType.OrgUnfinished
            }
        }
        // 用户处于普通版
        else {
            return this.isStatusSilenced(this.spaceStatus)
                ? SpaceOnboardingType.StandradFinished
                : SpaceOnboardingType.StandradUnfinished
        }
    }

    /**
     * 获取工作台的步骤数
     */
    private getSpaceStepLength() {
        if (this.isRestart || this.isResetTour) {
            return 5
        } else {
            switch (this.spaceOnboardingType) {
                // 已完成的工作台引导没有步数
                case SpaceOnboardingType.OrgFinished:
                case SpaceOnboardingType.StandradFinished: {
                    return 0
                }
                // 标准工作台未完成时及组织版未完成有 5 步
                case SpaceOnboardingType.StandradUnfinished:
                case SpaceOnboardingType.OrgUnfinished: {
                    return 5
                }
                // 标准版工作台完成但组织版未完成时有 1 步
                case SpaceOnboardingType.StandradFinishedOrgUnfinished: {
                    return 1
                }
            }
        }
    }

    /**
     * 判断引导状态是否是静默的 finished | closed
     */
    private isStatusSilenced(status?: OnboardingStatus): boolean {
        return status === OnboardingStatus.Finished || status === OnboardingStatus.Closed
    }

    /**
     * 检查进入编辑器的引导状态
     * @param isCheckedFile
     * @returns
     */
    private checkEditorStatusLegal(isCheckedFile?: boolean): boolean {
        if (this.editorStatus === OnboardingStatus.Init) {
            if (this.isCreateFile || isCheckedFile) {
                this.tutorialTriggerFile = isCheckedFile ? TutorialTriggerFile.Playground : TutorialTriggerFile.NewFile
                this.startFlow(OnboardingScene.Editor)
                this.isCreateFile = false
            } else {
                this.zustandStore.setState({
                    action: OnboardingAction.FetchEditorDocs,
                })
            }
            return true
        }
        if (this.editorStatus === OnboardingStatus.Started) {
            if (this.isCreateFile || isCheckedFile) {
                this.isCreateFile = false
                this.zustandStore.setState({
                    popupStatus: OnboardingPopupStatus.Notify,
                })
            } else {
                this.zustandStore.setState({
                    action: OnboardingAction.FetchEditorDocs,
                })
            }
            return true
        }

        return false
    }

    /**
     * 检查工作台的引导状态
     * @returns
     */
    private checkSpaceStatusLegal(): boolean {
        // 重置引导
        if (this.isResetTour) {
            this.startFlow(OnboardingScene.Space)
            this.isResetTour = false
            return true
        }
        // 首次进入触发引导
        if (this.spaceStatus === OnboardingStatus.Init) {
            // 当前路由为草稿时，直接开启引导
            if (this.routeInfo!.isDraft) {
                this.startFlow(OnboardingScene.Space)
            }
            // 否则先跳转到草稿，再由路由跳转的 callback 来开启引导
            else {
                this.zustandStore.setState({
                    action: OnboardingAction.NavigateToDraft,
                })
            }
            return true
        }
        // 已进入过引导，未完成刷新
        if (this.spaceStatus === OnboardingStatus.Started) {
            if (this.isRestart) {
                this.startFlow(OnboardingScene.Space)
                this.isRestart = false
            } else {
                this.zustandStore.setState({
                    popupStatus: OnboardingPopupStatus.Notify,
                })
            }
            return true
        }
        // 当标准版工作台完成但组织版未完成时，也需要开启引导，此时引导只有一步
        if (this.isOrg && this.orgBenchMemberStatus === OnboardingStatus.Init) {
            this.startFlow(OnboardingScene.Space)
            return true
        }

        return false
    }

    /**
     * 检查研发模式的引导流程
     */
    private checkDevModeStatusLegal(): boolean {
        if (this.devModeStatus === OnboardingStatus.Init) {
            this.startFlow(OnboardingScene.DevMode)
            return true
        }
        if (this.devModeStatus === OnboardingStatus.Started) {
            this.zustandStore.setState({
                popupStatus: OnboardingPopupStatus.Notify,
            })
            return true
        }

        return false
    }

    /**
     * 在开启引导流程后，先初始化一次状态
     * @param type
     * @param length
     */
    private initStatusAfterStartFlow(type: OnboardingPopupStatus, length: number) {
        this.stepLength = length
        this.zustandStore.setState({
            step: 0,
            popupStatus: type,
            action: OnboardingAction.None,
        })
    }

    private async syncOnboardingStatusToServer(
        scene: OnboardingScene[] | OnboardingScene,
        status: OnboardingStatus
    ): Promise<void> {
        if (Array.isArray(scene)) {
            return new BatchUpdateOnboardingStatus(scene, status).start().catch(console.error)
        } else {
            return new UpdateOnboardingStatus(scene, status).start().catch(console.error)
        }
    }

    /**
     * 开启编辑器的引导流程
     */
    private async startEditorFlow(): Promise<void> {
        const editorStepLength = 9
        if (this.editorStatus === OnboardingStatus.Init) {
            await this.syncOnboardingStatusToServer(OnboardingScene.Editor, OnboardingStatus.Started)
            this.isFirstOnboarding = true
            this.editorStatus = OnboardingStatus.Started
            this.initStatusAfterStartFlow(OnboardingPopupStatus.EditorTour, editorStepLength)
            this.startTimestamp = Date.now()
            this.packingFrog(OnboardingScene.Editor, 'expose')
        } else {
            this.initStatusAfterStartFlow(OnboardingPopupStatus.EditorTour, editorStepLength)
            this.packingFrog(OnboardingScene.Editor, 'expose')
        }
    }

    /**
     * 开启工作台的引导流程
     */
    private async startSpaceFlow(): Promise<void> {
        const spaceStepLength = this.getSpaceStepLength()

        switch (this.spaceOnboardingType) {
            // 通过重试开启的工作台引导流程，此时不需要更新远端状态
            case SpaceOnboardingType.OrgFinished:
            case SpaceOnboardingType.StandradFinished: {
                this.initStatusAfterStartFlow(OnboardingPopupStatus.SpaceTour, spaceStepLength)
                this.packingFrog(OnboardingScene.Space, 'expose')
                break
            }
            // 标准工作台的启动流程，需要更新状态，需要标注是全新启动，需要记录引导开始时间
            case SpaceOnboardingType.StandradUnfinished: {
                await this.syncOnboardingStatusToServer(OnboardingScene.Space, OnboardingStatus.Started)
                this.isFirstOnboarding = true
                this.spaceStatus = OnboardingStatus.Started
                this.initStatusAfterStartFlow(OnboardingPopupStatus.SpaceTour, spaceStepLength)
                this.startTimestamp = Date.now()
                this.packingFrog(OnboardingScene.Space, 'expose')
                break
            }
            // 组织版工作台的启动流程，需要更新状态（含组织版 member 状态），需要标注是全新启动，需要记录引导开始时间
            case SpaceOnboardingType.OrgUnfinished: {
                await this.syncOnboardingStatusToServer(
                    [OnboardingScene.Space, OnboardingScene.OrgBenchMember],
                    OnboardingStatus.Started
                )
                this.isFirstOnboarding = true
                this.spaceStatus = OnboardingStatus.Started
                this.orgBenchMemberStatus = OnboardingStatus.Started
                this.initStatusAfterStartFlow(OnboardingPopupStatus.SpaceTour, spaceStepLength)
                this.startTimestamp = Date.now()
                this.packingFrog(OnboardingScene.Space, 'expose')
                break
            }
            // 仅启动组织版的团队添加状态，需要更新对应状态即可
            case SpaceOnboardingType.StandradFinishedOrgUnfinished: {
                await this.syncOnboardingStatusToServer(OnboardingScene.OrgBenchMember, OnboardingStatus.Started)
                this.orgBenchMemberStatus = OnboardingStatus.Started
                this.initStatusAfterStartFlow(OnboardingPopupStatus.SpaceTour, spaceStepLength)
                this.packingFrog(OnboardingScene.Space, 'expose')
                break
            }
        }
    }

    /**
     * 开启 ai 引导流程
     */
    private startAiFlow(): void {
        if (this.aiStatus === OnboardingStatus.Init) {
            this.zustandStore.setState({
                step: 0,
                popupStatus: OnboardingPopupStatus.AiTour,
            })
            this.aiStatus = OnboardingStatus.Started
            this.syncOnboardingStatusToServer(OnboardingScene.Ai, OnboardingStatus.Started)
        }
    }

    /**
     * 开启 devMode 引导流程
     */
    private async startDevModeFlow(): Promise<void> {
        if (this.devModeStatus === OnboardingStatus.Init) {
            await this.syncOnboardingStatusToServer(OnboardingScene.DevMode, OnboardingStatus.Started)
            this.isFirstOnboarding = true
            this.devModeStatus = OnboardingStatus.Started
            this.initStatusAfterStartFlow(OnboardingPopupStatus.DevModeTour, 3)
            this.startTimestamp = Date.now()
            this.packingFrog(OnboardingScene.DevMode, 'expose')
        } else {
            this.initStatusAfterStartFlow(OnboardingPopupStatus.DevModeTour, 3)
            this.packingFrog(OnboardingScene.DevMode, 'expose')
        }
    }

    /**
     * 刷新用户数据
     */
    private async refreshOnboardingInfo(): Promise<void> {
        const info = await new GetUserRequest().start()
        this.injectOnboardingInfo(info)
    }

    // ---------------------------public method-------------------------------------

    /**
     * 填充初始的引导信息
     * @param info
     */
    public injectOnboardingInfo(info?: UserVOV2): void {
        if (info) {
            this.isInitUser = info.status === UserVOV2Status.INIT
            this.editorStatus = info?.onBoardingScene2Status?.editor
            this.spaceStatus = info?.onBoardingScene2Status?.workBench
            this.orgBenchMemberStatus = info?.onBoardingScene2Status?.orgBenchMember
            this.aiStatus = info?.onBoardingScene2Status?.aiLibraryExtraction
            this.aiLibraryMaintainStatus = info?.onBoardingScene2Status?.aiLibraryMaintain
            this.zustandStore.setState({
                demotionDevModeStatus: info?.onBoardingScene2Status?.demotionDevMode,
            })
            this.devModeStatus = info?.onBoardingScene2Status?.devMode
            this.variableFeatureStatus = info?.onBoardingScene2Status?.variable
        }
    }

    /**
     * 开始正式引导前的状态检测，如果合法则开启引导流程
     * @param scene
     * @param isCheckedFile
     * @returns
     */
    public checkOnboardingStatusLegal(scene: OnboardingScene, isCheckedFile?: boolean): boolean {
        if (this.isIllegalStatus) {
            return false
        }

        // NOTE: 如果不在新手文档里删掉
        const skipInit = shouldJumpToAIPlayGround()

        if (skipInit) {
            return false
        }

        // 根据不同的场景进入不同的引导状态合法检测
        switch (scene) {
            case OnboardingScene.Editor: {
                return this.checkEditorStatusLegal(isCheckedFile)
            }
            case OnboardingScene.Space: {
                return this.checkSpaceStatusLegal()
            }
            case OnboardingScene.Ai:
                return this.aiStatus === OnboardingStatus.Init
            case OnboardingScene.AiLibraryMaintain:
                return this.aiLibraryMaintainStatus === OnboardingStatus.Init
            case OnboardingScene.DevMode:
                return this.checkDevModeStatusLegal()
            default:
                return false
        }
    }

    /**
     * 注入是否是组织版的信息
     * @param isOrg
     */
    public injectOrgInfo(isOrg: boolean): void {
        this.isOrg = isOrg
    }

    /**
     * 用于视图加载完成后的回调
     * @param scene
     */
    public async loaded(scene: SEVERAL_STEPS_SCENE): Promise<void> {
        // 每次开启流程前均重新获取一次 onBoarding 的状态，避免跨 tab 的信息丢失
        await this.refreshOnboardingInfo()

        // 视图启动流程时需要重置一次 routeInfo，因为切换模式并不会引发路由变化
        this.routeInfo = this.getRouteInfo(domLocation())

        this.isLoading = false

        this.checkOnboardingStatusLegal(scene)
    }

    /**
     * 获取引导步数
     * @returns
     */
    public getStepLength(): number {
        return this.stepLength
    }

    /**
     * 开启引导流程
     * @param scene
     */
    public async startFlow(scene: OnboardingScene): Promise<void> {
        switch (scene) {
            case OnboardingScene.Editor: {
                return this.startEditorFlow()
            }
            case OnboardingScene.Space: {
                return this.startSpaceFlow()
            }
            case OnboardingScene.Ai:
                return this.startAiFlow()
            case OnboardingScene.DevMode:
                return this.startDevModeFlow()
            default:
                return
        }
    }

    /**
     * 点击引导下一步的回调
     * @param scene
     */
    public handleNextStep(scene: SEVERAL_STEPS_SCENE): void {
        if (this.currentStep === -1 || this.currentStep > this.stepLength - 1) {
            throw new Error('current step is invalid')
        }

        if (this.currentStep === this.stepLength - 1) {
            this.packingFrog(scene, 'finished')
            this.end(scene, OnboardingStatus.Finished)
        } else if (scene === OnboardingScene.Space && this.currentStep === 2) {
            this.zustandStore.setState({
                action: OnboardingAction.FetchDraftDocs,
            })
        } else if (scene === OnboardingScene.Space && this.currentStep === 3) {
            this.zustandStore.setState({
                action: OnboardingAction.OpenHelpCenter,
            })
        } else if (scene === OnboardingScene.Editor && this.currentStep === 5) {
            this.zustandStore.setState({
                action: OnboardingAction.SwitchToAssets,
            })
        } else {
            this.forceEnterNextStep(scene)
        }
    }

    /**
     * 引导流程结束，可能是 close | finish
     * @param scene
     * @param status
     */
    public end(scene: OnboardingScene, status: OnboardingStatus.Finished | OnboardingStatus.Closed): void {
        this.zustandStore.setState({
            popupStatus: OnboardingPopupStatus.Hide,
            step: -1,
        })
        switch (scene) {
            case OnboardingScene.Editor:
                if (this.editorStatus === OnboardingStatus.Started) {
                    this.editorStatus = status
                    this.syncOnboardingStatusToServer(scene, status)
                }
                break
            case OnboardingScene.Space: {
                switch (this.spaceOnboardingType) {
                    // 标准工作台未完成状态需要更新为完成
                    case SpaceOnboardingType.StandradUnfinished: {
                        this.spaceStatus = status
                        this.syncOnboardingStatusToServer(scene, status)
                        break
                    }
                    // 组织版工作台未完成状态需要更新标准工作台和组织版团队状态为完成
                    case SpaceOnboardingType.OrgUnfinished: {
                        this.spaceStatus = status
                        this.orgBenchMemberStatus = status
                        this.syncOnboardingStatusToServer(
                            [OnboardingScene.Space, OnboardingScene.OrgBenchMember],
                            status
                        )
                        break
                    }
                    // 仅有组织版团队状态未完成则单独标记
                    case SpaceOnboardingType.StandradFinishedOrgUnfinished: {
                        this.orgBenchMemberStatus = status
                        this.syncOnboardingStatusToServer(OnboardingScene.OrgBenchMember, status)
                        break
                    }
                    default: {
                        break
                    }
                }
                break
            }
            case OnboardingScene.Ai:
                if (this.aiStatus === OnboardingStatus.Started) {
                    this.aiStatus = status
                    this.syncOnboardingStatusToServer(scene, status)
                }
                break
            case OnboardingScene.AiLibraryMaintain:
                this.aiLibraryMaintainStatus = status
                this.syncOnboardingStatusToServer(scene, status)
                break
            case OnboardingScene.DemotionDevMode:
                this.syncOnboardingStatusToServer(scene, status)
                this.zustandStore.setState({
                    demotionDevModeStatus: status,
                })
                break
            case OnboardingScene.DevMode:
                if (this.devModeStatus === OnboardingStatus.Started) {
                    this.devModeStatus = status
                    this.syncOnboardingStatusToServer(scene, status)
                }
                break
            default:
                break
        }
    }

    /**
     * 将引导步骤推动至下一步
     */
    public forceEnterNextStep(scene: SEVERAL_STEPS_SCENE): void {
        this.zustandStore.setState({
            action: OnboardingAction.None,
            step: this.currentStep + 1,
        })
        this.packingFrog(scene, 'expose')
        if (this.currentPopupStatus === OnboardingPopupStatus.SpaceTour && this.currentStep === this.stepLength - 1) {
            if (this.isOrg) {
                this.spaceStatus = OnboardingStatus.Finished
                this.orgBenchMemberStatus = OnboardingStatus.Finished
                this.syncOnboardingStatusToServer(
                    [OnboardingScene.Space, OnboardingScene.OrgBenchMember],
                    OnboardingStatus.Finished
                )
            } else {
                this.spaceStatus = OnboardingStatus.Finished
                this.syncOnboardingStatusToServer(OnboardingScene.Space, OnboardingStatus.Finished)
            }
        }
    }

    /**
     * 用户填充完成信息后开启引导流程
     */
    public handleInitUserComplete() {
        this.isInitUser = false
        this.checkOnboardingStatusLegal(this.routeInfo!.scene)
    }

    // notify - 立即开始
    restart(scene: OnboardingScene) {
        this.isFirstOnboarding = false
        this.isResetOnboarding = false
        if (scene === OnboardingScene.Editor || scene === OnboardingScene.DevMode) {
            this.startFlow(scene)
        } else {
            this.isRestart = true
        }
    }

    // 重置新手引导
    resetTour(scene: SEVERAL_STEPS_SCENE) {
        this.isResetOnboarding = true
        this.isFirstOnboarding = false
        if (this.currentStep !== -1) {
            this.packingFrog(scene, 'interrupt')
        }
        if (scene === OnboardingScene.Editor) {
            WKFrog.addFrogRecord({
                url: '/click/EditorTutorial/basicReset',
                eventId: 20719,
                currentPage: 'EditorTutorial',
                eventAction: 'click',
                eventName: 'basicReset',
            })
            this.startFlow(scene)
        } else if (scene === OnboardingScene.DevMode) {
            WKFrog.addFrogRecord({
                url: '/click/DevTutorial/devReset',
                eventId: 26729,
                currentPage: 'DevTutorial',
                eventAction: 'click',
                eventName: 'devReset',
            })
            this.startFlow(scene)
        } else {
            WKFrog.addFrogRecord({
                url: '/click/FilesTutorial/filesReset',
                eventId: 20697,
                currentPage: 'FilesTutorial',
                eventAction: 'click',
                eventName: 'filesReset',
            })
            this.isResetTour = true
            this.stepLength = this.getSpaceStepLength()
        }
    }

    setCreateFile(v: boolean) {
        this.isCreateFile = v
    }

    /**
     * 富化并发送 frog 信息
     */
    public packingFrog(
        scene: SEVERAL_STEPS_SCENE,
        type: 'expose' | 'closed' | 'finished' | 'more' | 'interrupt',
        customExtend?: FrogCustomExtend,
        needFlush?: boolean
    ): void {
        const extend: FrogCustomExtend = {}

        switch (scene) {
            case OnboardingScene.Editor: {
                if (type !== 'finished') {
                    extend.basicTutorialStep = getBasicTutorialStep(this.currentStep)
                }
                if (type === 'interrupt') {
                    extend.basic_tutorial_where_abouts = BasicTutorialAboutsMap.other
                }
                if (this.isFirstOnboarding && type === 'expose') {
                    extend.tutorialTriggerFile = this.tutorialTriggerFile
                }
                break
            }
            case OnboardingScene.DevMode: {
                if (type !== 'finished') {
                    extend.dev_tutorial_step = getDevTutorialStep(this.currentStep)
                }
                if (type === 'interrupt') {
                    extend.dev_tutorial_where_abouts = BasicTutorialAboutsMap.other
                }
                break
            }
            case OnboardingScene.Space: {
                extend.files_tutorial_type = this.isOrg ? 'org' : 'personal'
                if (type !== 'finished') {
                    extend.filesTutorialStep = getFilesTutorialStep(this.currentStep, this.isOrg)
                }
                if (type === 'interrupt') {
                    extend.files_tutorial_where_abouts = BasicTutorialAboutsMap.other
                }
                break
            }
        }

        // 生成 frog 参数
        let args

        if (this.isFirstOnboarding) {
            args = FirstOnboardingFrogMap?.[scene]?.[type]
        } else if (this.isResetOnboarding) {
            args = ResetOnboardingFrogMap?.[scene]?.[type]
        } else {
            args = RecoverOnboardingFrogMap?.[scene]?.[type]
        }

        // 发送第一种 frog
        if (args) {
            WKFrog.addFrogRecord({
                ...args,
                customExtend: {
                    ...extend,
                    ...customExtend,
                },
            })
        }

        // 在第一次 onboarding 的时候统计完成时间
        if (this.isFirstOnboarding && (type === 'closed' || type === 'finished' || type === 'interrupt')) {
            const timeArgs = FirstOnboardingFrogMap?.[scene]?.[`${type}Duration`]
            const duration = Date.now() - this.startTimestamp
            timeArgs &&
                WKFrog.addFrogRecord({
                    ...timeArgs,
                    duration,
                    customExtend: {
                        duration,
                        ...extend,
                        ...customExtend,
                    },
                })
        }

        if (needFlush) {
            WKFrog.flushRecord()
        }
    }

    /**
     * 根据一些路由信息归结一些信息
     * @param location
     * @returns
     */
    private getRouteInfo(location: { pathname: string; search: string }): RouteInfo {
        const pathname = location.pathname
        const isEditor = /\/file\//.test(pathname)
        const isDraft = /\/drafts$/.test(pathname)
        const isDevMode = location.search?.includes('type=dev')

        return {
            scene: isEditor ? (isDevMode ? OnboardingScene.DevMode : OnboardingScene.Editor) : OnboardingScene.Space,
            isDraft,
            pathname,
        }
    }

    /**
     * 当路由切换的时候触发的回调
     * @param v
     */
    public routerChange(v: Location): void {
        const routeInfo = this.getRouteInfo(v)

        // 当场景发生转换时，需要重置 loading 的状态，等待下一次场景替换完成时重新激活流程
        if (routeInfo.scene !== this.routeInfo?.scene) {
            this.isLoading = true
        }

        if (!this.isRestart && !this.isResetTour) {
            if (this.currentStep !== -1) {
                this.packingFrog(
                    this.routeInfo!.scene,
                    'interrupt',
                    getRouteInterruptAbout(this.routeInfo?.scene!, routeInfo!.pathname, this.isCreateFile)
                )
            }
            this.zustandStore.setState({
                action: OnboardingAction.None,
                popupStatus: OnboardingPopupStatus.Hide,
                step: -1,
            })
            this.isFirstOnboarding = false
            this.isResetOnboarding = false
        }
        this.routeInfo = routeInfo
        this.checkOnboardingStatusLegal(this.routeInfo.scene)
    }

    modalVisibleChange(v: boolean | undefined, { id, title }: { id: string; title?: string }) {
        if (v) {
            if (this.currentStep !== -1) {
                this.packingFrog(
                    this.routeInfo!.scene,
                    'interrupt',
                    getModalInterruptAbout(this.routeInfo?.scene!, title)
                )
            }
            if (
                this.currentPopupStatus !== OnboardingPopupStatus.Hide &&
                this.currentPopupStatus !== OnboardingPopupStatus.AiTour
            ) {
                this.interruptByModal = id
            }
            this.zustandStore.setState({
                action: OnboardingAction.None,
                popupStatus: OnboardingPopupStatus.Hide,
                step: -1,
            })
            this.isResetTour = false
            this.isFirstOnboarding = false
            this.isResetOnboarding = false
        } else if (this.interruptByModal === id) {
            this.interruptByModal = undefined
            this.checkOnboardingStatusLegal(this.routeInfo!.scene, true)
        }
    }

    pageUnload(scene: SEVERAL_STEPS_SCENE) {
        if (this.currentStep !== -1) {
            const extend =
                scene === OnboardingScene.Editor
                    ? { basic_tutorial_where_abouts: FilesTutorialAboutsMap.closeTab }
                    : { files_tutorial_where_abouts: BasicTutorialAboutsMap.closeTab }
            this.packingFrog(scene, 'interrupt', extend, true)
        }
    }

    reset() {
        this.zustandStore.setState({
            action: OnboardingAction.None,
            popupStatus: OnboardingPopupStatus.Hide,
            step: -1,
        })
        this.editorStatus = undefined
        this.spaceStatus = undefined
        this.orgBenchMemberStatus = undefined
        this.aiStatus = undefined
        this.isLoading = true
        this.routeInfo = undefined
        this.stepLength = 0
        this.isRestart = false
        this.isResetTour = false
        this.isCreateFile = false
        this.isOrg = false
    }
}

export const onboardingService = new OnboardingService()
