import { translation } from './coactor-observing-border.translation'
import classnames from 'classnames'
import { CooperationTestId } from '../../../../window'
import classes from './coactor-observing-border.module.less'
import { useCoactorObservingBorder } from './use-coactor-observing-border'

export function CoactorObservingBorder() {
    const { currentObservedUser, colorStyleString, titleShow } = useCoactorObservingBorder()

    return currentObservedUser ? (
        <div
            className={classnames(classes.borderContainer, { [classes.hidden]: !currentObservedUser })}
            style={{ borderColor: colorStyleString }}
            data-testid={CooperationTestId.ObservingBorder}
        >
            {titleShow && (
                <span className={classes.borderTitle} style={{ background: colorStyleString }}>
                    {translation('Following')} {currentObservedUser.nickname} {translation('aIjDaP')}
                </span>
            )}
        </div>
    ) : (
        <></>
    )
}
