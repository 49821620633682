import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import { GetSelectionNodeIdsCommandForWasm, LooperApplyCommand, Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useEvent } from 'react-use'
import {
    Checkbox,
    IconAngle,
    IconCoordinateH,
    IconCoordinateW,
    IconCoordinateX,
    IconCoordinateY,
    IconCopy,
    IconRight,
    IconSine,
    IconStrokeWeight,
    Scrollbar,
    Select,
    WKButton,
} from '../../../../../../ui-lib/src'
import { isEnglishLanguage } from '../../../../../../util/src'
import { useCommand } from '../../../../main/app-context'
import { useUserConfigStateV2 } from '../../../../main/user-config/user-config-hook'
import { DeepRequired, useViewState } from '../../../../view-state-bridge'
import { ReactComponent as LooperIcon } from '../../../assets/plugins/looper.svg'
import { ScrubbableInputProps } from '../../atom/inputs/components/scrubbable-input'
import { ScrubbableInputDegree } from '../../atom/inputs/scrubbable-input-degree'
import { ScrubbableInputInteger } from '../../atom/inputs/scrubbable-input-integer'
import { ScrubbableInputNumber } from '../../atom/inputs/scrubbable-input-number'
import { ScrubbableInputPercent } from '../../atom/inputs/scrubbable-input-percent'
import { getLdap, logs } from '../log'
import { PluginExported, PluginModalProps } from '../type'
import { ColorInput } from './components/color-input'
import styles from './index.module.less'
import LooperRotateMode = Wukong.DocumentProto.LooperRotateMode

const baseInputProps: Partial<ScrubbableInputProps> = {
    leftScrubbable: false,
    rightScrubbable: false,
}

export function PopupModal(_props: PluginModalProps) {
    // 统计曝光次数
    useEffect(() => {
        // trace
        {
            logs.Plugins.looper({ ldap: getLdap() })
        }
    }, [])

    const enabled = useViewState('pluginLooperEnabled', false)

    const buttonText = useMemo(() => {
        return enabled ? translation('Apply') : translation('SelectALayer')
    }, [enabled])

    const [looperPluginDataV2, setLooperPluginDataV2] = useUserConfigStateV2('looperPluginData')

    const defalutData: DeepRequired<Wukong.DocumentProto.PluginLooperData> = useRef(
        looperPluginDataV2 as unknown as DeepRequired<Wukong.DocumentProto.PluginLooperData>
    ).current

    const getModalBodyHeight = () => {
        const windowInnerHeight = window.innerHeight
        // 当视窗大于 topBar + 2 * padding + 窗口高度时
        if (windowInnerHeight >= 48 + 8 * 2 + 668) {
            return 564
        } else {
            return Math.max(400 - 40 - 64, windowInnerHeight - 48 - 8 * 2 - 40 - 64)
        }
    }

    const [modalBodyHeight, setModalBodyHeight] = useState(getModalBodyHeight())

    // form
    const [repeatTime, setRepeatTime] = useState(defalutData.repeatTime)
    const [translateX, setTranslateX] = useState(defalutData.translateX)
    const [translateY, setTranslateY] = useState(defalutData.translateY)
    const [width, setWidth] = useState(defalutData.width)
    const [height, setHeight] = useState(defalutData.height)
    const [rotate, setRotate] = useState(defalutData.rotate)
    const [opacity, setOpacity] = useState(defalutData.opacity)
    const [fills, setFills] = useState(defalutData.fills)
    const [strokes, setStrokes] = useState(defalutData.strokes)

    // 视窗变化时，动态设置 height
    useEvent('resize', () => {
        setModalBodyHeight(getModalBodyHeight())
    })

    const command = useCommand()
    // {translation('Apply')}循环变化
    const apply = () => {
        // trace
        {
            const selectionIds = command.DEPRECATED_invokeBridge(GetSelectionNodeIdsCommandForWasm).value ?? []
            logs.Plugins.looperApply({ ldap: getLdap(), layer_id: selectionIds.join(',') })
        }

        const data = {
            repeatTime,
            translateX,
            translateY,
            width,
            height,
            rotate,
            opacity,
            fills,
            strokes,
        }
        // invoke looper apply
        command.DEPRECATED_invokeBridge(LooperApplyCommand, Wukong.DocumentProto.PluginLooperData.create(data))
        // set user config
        setLooperPluginDataV2(data)
        motiff.commitUndo()
    }

    const rotateMode2Label = {
        [LooperRotateMode.LOOPER_ROTATE_MODE_LINER]: translation('Linear'),
        [LooperRotateMode.LOOPER_ROTATE_MODE_SINE]: translation('Sinusoidal'),
    }

    return (
        <div className="relative">
            <Scrollbar autoHeight autoHeightMin={0} autoHeightMax={670}>
                <div className={styles['modal-body']} style={{ height: modalBodyHeight }}>
                    {/* {translation('Count')} */}
                    <div className={styles.control}>
                        <p className={styles.title}>{translation('Count')}</p>
                        <ScrubbableInputInteger
                            {...baseInputProps}
                            className={styles.input}
                            icon={<IconCopy />}
                            value={repeatTime}
                            min={2}
                            max={1000}
                            onChange={(value) => {
                                setRepeatTime(value as number)
                            }}
                        />
                    </div>

                    {/* {translation('Position')} */}
                    <div className={styles.control}>
                        <p className={styles.title}>{translation('Position')}</p>
                        <div className={styles['splite-control']}>
                            <ScrubbableInputNumber
                                {...baseInputProps}
                                className={styles.input}
                                icon={<IconCoordinateX />}
                                value={translateX}
                                min={-1000}
                                max={1000}
                                onChange={(value) => {
                                    setTranslateX(value as number)
                                }}
                            />
                            <ScrubbableInputNumber
                                {...baseInputProps}
                                className={styles.input}
                                icon={<IconCoordinateY />}
                                value={translateY}
                                min={-1000}
                                max={1000}
                                onChange={(value) => {
                                    setTranslateY(value as number)
                                }}
                            />
                        </div>
                    </div>

                    {/* {translation('Rotation')} */}
                    <div className={styles.control}>
                        <p className={styles.title}>{translation('Rotation')}</p>
                        <div className={styles['splite-control']}>
                            <Select.NormalSingleLevel
                                value={String(rotate.mode)}
                                label={rotateMode2Label[rotate.mode]}
                                minWidth={140}
                                onChange={(value) => {
                                    setRotate((cur) => ({
                                        ...cur,
                                        mode: value,
                                    }))
                                }}
                            >
                                {Object.entries(rotateMode2Label).map(([key, v]) => (
                                    <Select.NormalSingleLevel.Option key={key} value={key} backwardIcon={''}>
                                        {v}
                                    </Select.NormalSingleLevel.Option>
                                ))}
                            </Select.NormalSingleLevel>

                            {Number(rotate.mode) === LooperRotateMode.LOOPER_ROTATE_MODE_LINER ? (
                                <ScrubbableInputDegree
                                    {...baseInputProps}
                                    className={styles.input}
                                    icon={<IconAngle />}
                                    value={rotate.degree}
                                    min={-360}
                                    max={360}
                                    onChange={(value) => {
                                        setRotate((cur) => ({
                                            ...cur,
                                            degree: ((value as number) * Math.PI) / 180,
                                        }))
                                    }}
                                />
                            ) : (
                                <ScrubbableInputNumber
                                    {...baseInputProps}
                                    className={styles.input}
                                    icon={<IconSine />}
                                    value={rotate.sine}
                                    min={-360}
                                    max={360}
                                    onChange={(value) => {
                                        setRotate((cur) => ({
                                            ...cur,
                                            sine: value as number,
                                        }))
                                    }}
                                />
                            )}
                        </div>
                    </div>

                    {/* {translation('Scale')} */}
                    <div className={styles.control}>
                        <p className={styles.title}>{translation('Scale')}</p>
                        <div className={styles['splite-control']}>
                            <ScrubbableInputNumber
                                {...baseInputProps}
                                className={styles.input}
                                icon={<IconCoordinateW />}
                                value={width}
                                min={-10000}
                                max={10000}
                                onChange={(value) => {
                                    setWidth(value as number)
                                }}
                            />
                            <ScrubbableInputNumber
                                {...baseInputProps}
                                className={styles.input}
                                icon={<IconCoordinateH />}
                                value={height}
                                min={-10000}
                                max={10000}
                                onChange={(value) => {
                                    setHeight(value as number)
                                }}
                            />
                        </div>
                    </div>

                    {/* {translation('Opacity')} */}
                    <div className={styles.control}>
                        <p className={styles.title}>{translation('Opacity')}</p>

                        <div
                            className={isEnglishLanguage() ? styles['splite-control-2-en'] : styles['splite-control-2']}
                        >
                            <div className={styles['checkbox-container']}>
                                <Checkbox
                                    checked={opacity.apply}
                                    onChange={(subApply) => {
                                        setOpacity((cur) => ({
                                            ...cur,
                                            apply: subApply,
                                        }))
                                    }}
                                    label={translation('Apply')}
                                />
                            </div>

                            <div>
                                <ScrubbableInputPercent
                                    {...baseInputProps}
                                    className={styles.input}
                                    value={opacity.start}
                                    min={0}
                                    max={100}
                                    onChange={(value) => {
                                        setOpacity((cur) => ({
                                            ...cur,
                                            start: (value as number) / 100,
                                        }))
                                    }}
                                />
                            </div>

                            <div className={styles['flex-box']}>
                                <IconRight />
                            </div>

                            <div>
                                <ScrubbableInputPercent
                                    {...baseInputProps}
                                    className={styles.input}
                                    value={opacity.end}
                                    min={0}
                                    max={100}
                                    onChange={(value) => {
                                        setOpacity((cur) => ({
                                            ...cur,
                                            end: (value as number) / 100,
                                        }))
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {/* {translation('Fill')} */}
                    <div className={styles.control}>
                        <p className={styles.title}>{translation('Fill')}</p>

                        <div
                            className={isEnglishLanguage() ? styles['splite-control-2-en'] : styles['splite-control-2']}
                        >
                            <div className={styles['checkbox-container']}>
                                <Checkbox
                                    checked={fills.apply}
                                    onChange={(subApply) => {
                                        setFills((cur) => ({
                                            ...cur,
                                            apply: subApply,
                                        }))
                                    }}
                                    label={translation('Apply')}
                                />
                            </div>

                            <div>
                                <ColorInput
                                    value={fills.start}
                                    onChange={(value) => {
                                        setFills((cur) => ({
                                            ...cur,
                                            start: value,
                                        }))
                                    }}
                                />
                            </div>

                            <div className={styles['flex-box']}>
                                <IconRight />
                            </div>

                            <div>
                                <ColorInput
                                    value={fills.end}
                                    onChange={(value) => {
                                        setFills((cur) => ({
                                            ...cur,
                                            end: value,
                                        }))
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {/* {translation('Stroke')} */}
                    <div className={styles.control}>
                        <p className={styles.title}>{translation('Stroke')}</p>

                        <div
                            className={isEnglishLanguage() ? styles['splite-control-2-en'] : styles['splite-control-2']}
                        >
                            <div className={styles['checkbox-container']}>
                                <Checkbox
                                    checked={strokes.applyColor}
                                    onChange={(applyColor) => {
                                        setStrokes((cur) => ({
                                            ...cur,
                                            applyColor,
                                        }))
                                    }}
                                    label={translation('Apply')}
                                />
                            </div>

                            <div>
                                <ColorInput
                                    value={strokes.startColor}
                                    onChange={(value) => {
                                        setStrokes((cur) => ({
                                            ...cur,
                                            startColor: value,
                                        }))
                                    }}
                                />
                            </div>

                            <div className={styles['flex-box']}>
                                <IconRight />
                            </div>

                            <div>
                                <ColorInput
                                    value={strokes.endColor}
                                    onChange={(value) => {
                                        setStrokes((cur) => ({
                                            ...cur,
                                            endColor: value,
                                        }))
                                    }}
                                />
                            </div>
                        </div>

                        <div
                            className={classNames(
                                isEnglishLanguage() ? styles['splite-control-2-en'] : styles['splite-control-2'],
                                styles['mt-8']
                            )}
                        >
                            <div className={styles['checkbox-container']}>
                                <Checkbox
                                    checked={strokes.applyBorderWeight}
                                    onChange={(applyBorderWeight) => {
                                        setStrokes((cur) => ({
                                            ...cur,
                                            applyBorderWeight,
                                        }))
                                    }}
                                    label={translation('Apply')}
                                />
                            </div>

                            <div>
                                <ScrubbableInputNumber
                                    {...baseInputProps}
                                    className={styles.input}
                                    icon={<IconStrokeWeight />}
                                    value={strokes.startBorderWeight}
                                    onChange={(value) => {
                                        setStrokes((cur) => ({
                                            ...cur,
                                            startBorderWeight: value as number,
                                        }))
                                    }}
                                    min={0}
                                />
                            </div>

                            <div className={styles['flex-box']}>
                                <IconRight />
                            </div>

                            <div>
                                <ScrubbableInputNumber
                                    {...baseInputProps}
                                    className={styles.input}
                                    icon={<IconStrokeWeight />}
                                    value={strokes.endBorderWeight}
                                    onChange={(value) => {
                                        setStrokes((cur) => ({
                                            ...cur,
                                            endBorderWeight: value as number,
                                        }))
                                    }}
                                    min={0}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 底部 padding */}
                    <div className={`w-full ${modalBodyHeight < 564 ? 'h-4' : ''}`} />
                </div>
            </Scrollbar>
            {/* footer */}
            <div className="w-84 h-16">
                <div className={classNames(styles['modal-footer'], modalBodyHeight < 564 && styles['footer-border'])}>
                    <WKButton
                        className={styles['apply-btn']}
                        type="primary"
                        disabled={!enabled}
                        onClick={apply}
                        dataTestId="looper-apply"
                    >
                        {buttonText}
                    </WKButton>
                </div>
            </div>
        </div>
    )
}

export default {
    manifest: {
        key: Wukong.DocumentProto.PluginType.PLUGIN_TYPE_LOOPER,
        name: translation('Looper'),
        width: 320,
        height: 668,
        icon: <LooperIcon />,
    },
    Component: PopupModal,
} as PluginExported
