import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Grid: '网格',
    Rows: '行',
    Columns: '列',
} as const

export const enTranslation = {
    Grid: 'Grid',
    Rows: 'Rows',
    Columns: 'Columns',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
