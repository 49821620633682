import { translation } from './candidate-effect-style-list-item.translation'
/* eslint-disable no-restricted-imports */
import { useMemo, useState } from 'react'
import { DropdownDefaultCustomNode, DropdownV2, RectContainer } from '../../../../../../ui-lib/src'
import { LibraryStyleTestId } from '../../../../window'
import { EffectStyleDetail } from '../../../document-view-state/view-states/effect-style'
import { EffectStyleIcon } from '../../design/effects/effect-style/effect-style-icon'
import {
    useEffectStyleEditInsideSelect,
    useEffectStyleSelectHandler,
} from '../../design/effects/effect-style/effect-style-select/use-effect-style-select'
import { CommonStyleInfo } from '../../design/styles/get-style-nodes-info-map'
import { StyleListItem, StyleListItemKeyboardProps } from '../../design/styles/style-items-layout/style-list-item'
import { EffectStyleEditor } from '../../design/styles/style-panel/effect-style-editor/effect-style-editor'
import style from './candidate-style-color.module.less'

interface EffectStyleItemProps {
    item: EffectStyleDetail
}

export function CandidateEffectStyleListItem(
    props: EffectStyleItemProps &
        StyleListItemKeyboardProps & {
            onClick: (id: string, needClosePopup: boolean, name: string) => void
            width?: number
        }
) {
    const { onClick } = props
    const { onDelete } = useEffectStyleSelectHandler()
    const { onEdit, onEndEdit, opened, openedId, position } = useEffectStyleEditInsideSelect()

    const [contextMenuInfo, setContextMenuInfo] = useState<RectContainer | null>(null)

    const onSelectStyle = (item: CommonStyleInfo) => {
        onClick(item.id, true, item.name)
    }

    const onOperateStyle = (item: CommonStyleInfo, e: any) => {
        const { clientX, clientY } = e
        setContextMenuInfo({
            top: clientY,
            bottom: clientY,
            right: clientX,
            left: clientX,
        })
    }

    const onEditStyle = (item: CommonStyleInfo, containerDomRect: DOMRect) => {
        onEdit(item.id, containerDomRect)
    }

    const onClickEditStyle = (e?: any) => {
        e?.stopPropagation()
        if (!contextMenuInfo) {
            return
        }
        setContextMenuInfo(null)
        onEdit(props.item.effectStyle.id, contextMenuInfo)
    }

    const onDeleteStyle = () => {
        onDelete(props.item.effectStyle.id)
    }

    const onClosehandler = () => {
        setContextMenuInfo(null)
    }

    const listItem = useMemo(() => {
        const {
            styleName,
            groupName,
            effectStyle: { id, name, description },
        } = props.item
        return {
            styleName,
            groupName,
            id,
            name,
            description,
        }
    }, [props.item])

    return (
        <>
            <StyleListItem
                item={listItem}
                openStyleId={opened && openedId === props.item.effectStyle.id && position ? openedId : undefined}
                onSelectItem={onSelectStyle}
                onClickHoverIcon={onEditStyle}
                onContextMenu={onOperateStyle}
                dataTestId={`${LibraryStyleTestId.LocalEffectStyleItem}-${props.item.effectStyle.name}`}
                index={props.index}
                selectStyleId={props.selectStyleId}
                setPreselectIndex={props.setPreselectIndex}
                trySetPreselectIndex={props.trySetPreselectIndex}
                recordEnterCallback={props.recordEnterCallback}
            >
                <EffectStyleIcon className={style.thumbnail} iconType={props.item.iconType} />
                <div className={style.name}>{props.item.styleName}</div>
            </StyleListItem>
            {contextMenuInfo ? (
                <DropdownV2.NoTriggerMultiLevel<DropdownDefaultCustomNode & { handler?: () => void }>
                    isOpenState={true}
                    triggerRect={contextMenuInfo}
                    onClose={onClosehandler}
                    onChange={(v) => v.customNode?.handler?.()}
                    pickRest={{ onMouseDown: (e) => e.stopPropagation() }}
                    onKeyboard={(e) => e.code === 'Escape' && e.stopPropagation()}
                    items={[
                        {
                            name: translation('EditStyle'),
                            customNode: {
                                handler: onClickEditStyle,
                            },
                        },
                        {
                            name: translation('DeleteStyle'),
                            customNode: {
                                handler: onDeleteStyle,
                            },
                        },
                    ]}
                />
            ) : null}
            {position ? (
                <EffectStyleEditor
                    visible={opened && openedId === props.item.effectStyle.id}
                    onCancel={onEndEdit}
                    position={{
                        top: position?.top ?? 0,
                        left: (position?.left ?? 0) + 240 + (props.width ? props.width : 216),
                    }}
                    styleId={props.item.effectStyle.id}
                />
            ) : null}
        </>
    )
}
