import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    mode: '模式',
    light: '亮色',
    dark: '暗色',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    mode: 'Mode',
    light: 'Light',
    dark: 'Dark',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
