/* eslint-disable no-restricted-imports */
import {
    ChangeOrderedTextListCommand,
    ChangePlainTextCommand,
    ChangeUnorderedTextListCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'

export enum TextListType {
    None,
    Ordered,
    Unordered,
    Plain,
}

const SelectionTextLineType = Wukong.DocumentProto.SelectionTextLineType
const LineType = Wukong.DocumentProto.LineType

export const TextListTypeValues = [TextListType.None, TextListType.Ordered, TextListType.Unordered, TextListType.Plain]

/**
 * 选区文本行类型状态
 */
export function useTextListModel() {
    const command = useCommand()

    const selectionTextLine = useViewState('selectionTextLine', {
        type: SelectionTextLineType.SELECTION_TEXT_LINE_TYPE_NONE,
        lineType: LineType.LINE_TYPE_PLAIN,
    })

    let listType = TextListType.None
    if (selectionTextLine.type == SelectionTextLineType.SELECTION_TEXT_LINE_TYPE_SAME) {
        switch (selectionTextLine.lineType) {
            case LineType.LINE_TYPE_ORDERED_LIST:
                listType = TextListType.Ordered
                break
            case LineType.LINE_TYPE_UNORDERED_LIST:
                listType = TextListType.Unordered
                break
            case LineType.LINE_TYPE_BLOCKQUOTE:
            case LineType.LINE_TYPE_HEADER:
            case LineType.LINE_TYPE_PLAIN:
                listType = TextListType.Plain
                break
        }
    }

    const handleListTypeChange = (value: TextListType) => {
        if (listType === value) {
            return
        }

        switch (value) {
            case TextListType.None:
                break
            case TextListType.Plain:
                command.DEPRECATED_invokeBridge(ChangePlainTextCommand)
                command.commitUndo()
                break
            case TextListType.Ordered:
                command.DEPRECATED_invokeBridge(ChangeOrderedTextListCommand)
                command.commitUndo()
                break
            case TextListType.Unordered:
                command.DEPRECATED_invokeBridge(ChangeUnorderedTextListCommand)
                command.commitUndo()
                break
        }
    }

    return {
        listType,
        handleListTypeChange,
    }
}
