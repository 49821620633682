import { translation } from './style-color.translation'
/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { useMemo } from 'react'
import {
    DraggablePopupV2,
    IconComponentLibrary16,
    InputV2,
    ScrollView,
    WKButton,
} from '../../../../../../../ui-lib/src'
import { ToKeyCode } from '../../../../../document/util/keycode'
import { useLibraryComponentService } from '../../../../../main/app-context'
import { KeyboardReceiver } from '../../../../../main/keyboard-receiver/component'
import { LibraryStyleTestId } from '../../../../../window'
import { useKeyboardStyleStyleList } from '../hooks/use-keyboard-style-list'
import { StyleTitle, StyleType } from '../style-title/style-title'
import { RemoteStyleColorGridItem } from './remote-style/remote-style-color-grid-item'
import { RemoteStyleColorListItem } from './remote-style/remote-style-color-list-item'
import { UnknowStyleColorGridItem } from './remote-style/unknow-style-color-grid-item'
import { UnknowStyleColorListItem } from './remote-style/unknow-style-color-list-item'
import { StyleColorGridItem } from './style-color-grid-item'
import styleGrid from './style-color-grid-item.module.less'
import { StyleColorListItem } from './style-color-list-item'
import style from './style-color.module.less'
import { StyleColorProps, useStyleColor } from './use-style-color'

export function StyleColorViewOnly(props: StyleColorProps & { width?: number }): JSX.Element {
    const {
        open,
        position,
        remoteStyleList,
        isNoStyle,
        isList,
        onClickChangeLayout,
        onChangeSearchInput,
        showStyleMap,
        onContextMenu,
        onClickApplyStyle,
        selectStyleId,
        onClickHoverIcon,
        styleIdEditor,
        openStyleEditor,
        contextMenuInfo,
        onFirstMove,
        unknownStyleGroup,
        rightClickStyleId,
        setRightClickStyleId,
        titleContainerRef,
    } = useStyleColor(props)
    const {
        libraryModalRouterService: { goToRemoteLibraryHome },
    } = useLibraryComponentService()

    const noSearchResult = useMemo(() => {
        return (
            showStyleMap.length === 0 &&
            !remoteStyleList?.filter((v) => v.groups.length > 0).length &&
            !unknownStyleGroup?.groups.size
        )
    }, [remoteStyleList, showStyleMap.length, unknownStyleGroup])

    const needSplitLineWithRemoteStyleListItem = (index: number) => {
        const hasItemBeforeRemoteStyle = showStyleMap.length > 0 || !!unknownStyleGroup?.groups.size
        if (hasItemBeforeRemoteStyle) {
            return true
        }
        const firstItemIndex = remoteStyleList?.findIndex((library) => library.groups.length > 0) ?? -1
        if (firstItemIndex !== -1 && index > firstItemIndex) {
            return true
        }
        return false
    }

    const maxPreSelectIndex = useMemo(() => {
        let optionSum = 0
        for (const { items } of showStyleMap) {
            optionSum += items.length ?? 0
        }
        if (unknownStyleGroup) {
            for (const [_, styles] of unknownStyleGroup.groups) {
                optionSum += styles.length ?? 0
            }
        }
        if (remoteStyleList) {
            for (const { groups } of remoteStyleList) {
                for (const { items } of groups) {
                    optionSum += items.length ?? 0
                }
            }
        }
        return Math.max(optionSum - 1, 0)
    }, [remoteStyleList, showStyleMap, unknownStyleGroup])

    const keyboardStyleStyleList = useKeyboardStyleStyleList({ maxPreSelectIndex, disabled: !isList })

    const renderListItems = () => {
        let optionIndex = 0
        const _selectStyleId = selectStyleId ? selectStyleId : undefined
        return (
            <>
                {showStyleMap.map(({ name, items }, index) => (
                    <div key={index}>
                        {name ? <div className={style.h2}>{name}</div> : null}
                        <div>
                            {items.map((item, index2) => (
                                <StyleColorListItem
                                    key={item.id}
                                    item={item}
                                    openStyleId={open && openStyleEditor ? styleIdEditor : undefined}
                                    onContextMenu={(...args) => {
                                        setRightClickStyleId(`${item.id}-show-${index}-${index2}`)
                                        onContextMenu(...args)
                                    }}
                                    dataTestId={`${LibraryStyleTestId.LocalPaintStyleItem}-${item.id}`}
                                    onSelectItem={onClickApplyStyle}
                                    isRightClickStyle={rightClickStyleId === `${item.id}-show-${index}-${index2}`}
                                    selectStyleId={_selectStyleId}
                                    index={optionIndex++}
                                    setPreselectIndex={keyboardStyleStyleList.setPreselectIndex}
                                    trySetPreselectIndex={keyboardStyleStyleList.trySetPreselectIndex}
                                    recordEnterCallback={keyboardStyleStyleList.recordEnterCallback}
                                />
                            ))}
                        </div>
                        <div className={style.paddingBottom8}></div>
                    </div>
                ))}
                {!!unknownStyleGroup?.groups.size && (
                    <div key={unknownStyleGroup.docId}>
                        <div
                            className={classnames(style.h1, {
                                [style.splitLine]: !!showStyleMap.length,
                            })}
                        >
                            {unknownStyleGroup?.libraryName}
                        </div>
                        {[...unknownStyleGroup.groups.entries()].map(([groupName, styles], index) => (
                            <div key={index}>
                                {groupName ? <div className={style.h2}>{groupName}</div> : null}
                                {styles.map((item, index2) => (
                                    <UnknowStyleColorListItem
                                        key={item.id}
                                        docId={unknownStyleGroup.docId || ''}
                                        item={item}
                                        openStyleId={open && openStyleEditor ? styleIdEditor : undefined}
                                        onContextMenu={(...args) => {
                                            setRightClickStyleId(`${item.id}-unknown-${index}-${index2}`)
                                            onContextMenu(...args)
                                        }}
                                        onSelectItem={onClickApplyStyle}
                                        isRightClickStyle={
                                            rightClickStyleId === `${item.id}-unknown-${index}-${index2}`
                                        }
                                        selectStyleId={_selectStyleId}
                                        index={optionIndex++}
                                        setPreselectIndex={keyboardStyleStyleList.setPreselectIndex}
                                        trySetPreselectIndex={keyboardStyleStyleList.trySetPreselectIndex}
                                        recordEnterCallback={keyboardStyleStyleList.recordEnterCallback}
                                    />
                                ))}
                                <div className={style.paddingBottom8}></div>
                            </div>
                        ))}
                    </div>
                )}
                {remoteStyleList?.map(
                    (library, index) =>
                        library.groups.length > 0 && (
                            <div key={library.libraryId}>
                                <div
                                    className={classnames(style.h1, {
                                        [style.splitLine]: needSplitLineWithRemoteStyleListItem(index),
                                    })}
                                >
                                    {library?.libraryName}
                                </div>
                                {library.groups.map(({ name, items }, index2) => (
                                    <div key={index2}>
                                        {name ? <div className={style.h2}>{name}</div> : null}
                                        {items.map((item, index3) => (
                                            <RemoteStyleColorListItem
                                                key={item.id}
                                                docId={library.docId || ''}
                                                item={item}
                                                dataTestId={`${LibraryStyleTestId.RemotePaintStyleItem}-${library.libraryName}-${index3}`}
                                                selectStyleId={_selectStyleId}
                                                selectStyleKey={props.selectStyleNodeKey}
                                                openStyleId={open && openStyleEditor ? styleIdEditor : undefined}
                                                onContextMenu={(...args) => {
                                                    setRightClickStyleId(
                                                        `${item.id}-remote-${index}-${index2}-${index3}`
                                                    )
                                                    onContextMenu(...args)
                                                }}
                                                onSelectItem={onClickApplyStyle}
                                                isRightClickStyle={
                                                    rightClickStyleId ===
                                                    `${item.id}-remote-${index}-${index2}-${index3}`
                                                }
                                                index={optionIndex++}
                                                setPreselectIndex={keyboardStyleStyleList.setPreselectIndex}
                                                trySetPreselectIndex={keyboardStyleStyleList.trySetPreselectIndex}
                                                recordEnterCallback={keyboardStyleStyleList.recordEnterCallback}
                                            ></RemoteStyleColorListItem>
                                        ))}
                                        <div className={style.paddingBottom8}></div>
                                    </div>
                                ))}
                            </div>
                        )
                )}
            </>
        )
    }
    const renderGridItems = () => {
        return (
            <>
                {showStyleMap.map(({ name, items }, index) => (
                    <div key={index}>
                        {name ? <div className={style.h2}>{name}</div> : null}
                        <div className={styleGrid.gridItems}>
                            {items.map((item, index2) => (
                                <StyleColorGridItem
                                    key={item.id}
                                    item={item}
                                    onContextMenu={onClickApplyStyle}
                                    onSelectItem={onClickApplyStyle}
                                    isRightClickStyle={rightClickStyleId === `${item.id}-show-${index}-${index2}`}
                                />
                            ))}
                        </div>
                        <div className={style.paddingBottom8}></div>
                    </div>
                ))}
                {!!unknownStyleGroup?.groups.size && (
                    <div key={unknownStyleGroup.docId}>
                        <div
                            className={classnames(style.h1, {
                                [style.splitLine]: showStyleMap.length > 0,
                            })}
                        >
                            {unknownStyleGroup.libraryName}
                        </div>
                        {[...unknownStyleGroup.groups.entries()].map(([groupName, styles], index) => (
                            <div key={index}>
                                {groupName ? <div className={style.h2}>{groupName}</div> : null}
                                <div className={styleGrid.gridItems}>
                                    {styles.map((item, index2) => (
                                        <UnknowStyleColorGridItem
                                            key={item.id}
                                            item={item}
                                            docId={unknownStyleGroup.docId || ''}
                                            openStyleId={open && openStyleEditor ? styleIdEditor : undefined}
                                            onContextMenu={onClickApplyStyle}
                                            onSelectItem={onClickApplyStyle}
                                            isRightClickStyle={
                                                rightClickStyleId === `${item.id}-unknown-${index}-${index2}`
                                            }
                                        ></UnknowStyleColorGridItem>
                                    ))}
                                </div>
                                <div className={style.paddingBottom8}></div>
                            </div>
                        ))}
                    </div>
                )}
                {remoteStyleList?.map((library, index) => {
                    return (
                        library.groups.length > 0 && (
                            <div key={library.libraryId}>
                                <div
                                    className={classnames(style.h1, {
                                        [style.splitLine]: needSplitLineWithRemoteStyleListItem(index),
                                    })}
                                >
                                    {library?.libraryName}
                                </div>
                                {library.groups.map(({ name, items }, index2) => (
                                    <div key={index2}>
                                        {name ? <div className={style.h2}>{name}</div> : null}
                                        <div className={styleGrid.gridItems}>
                                            {items.map((item, index3) => (
                                                <RemoteStyleColorGridItem
                                                    key={item.id}
                                                    item={item}
                                                    docId={library.docId || ''}
                                                    selectStyleId={selectStyleId}
                                                    selectStyleKey={props.selectStyleNodeKey}
                                                    openStyleId={open && openStyleEditor ? styleIdEditor : undefined}
                                                    onContextMenu={onClickApplyStyle}
                                                    onSelectItem={onClickApplyStyle}
                                                    onClickHoverIcon={onClickHoverIcon}
                                                    isRightClickStyle={
                                                        rightClickStyleId ===
                                                        `${item.id}-remote-${index}-${index2}-${index3}`
                                                    }
                                                ></RemoteStyleColorGridItem>
                                            ))}
                                        </div>
                                        <div className={style.paddingBottom8}></div>
                                    </div>
                                ))}
                            </div>
                        )
                    )
                })}
            </>
        )
    }

    return (
        <div>
            <DraggablePopupV2
                visible={open}
                position={position}
                closable={false}
                width={props.width ? props.width : 216}
                positionRightBase
                onFirstMove={onFirstMove}
                header={
                    <div className={style.titleContainer} ref={titleContainerRef}>
                        <StyleTitle
                            styleType={StyleType.PaintStyle}
                            hiddenLibraryIcon={true}
                            disabledAddIcon={true}
                            onClickLayoutIcon={onClickChangeLayout}
                            isList={!isList}
                        />
                    </div>
                }
                bodyClassName="p-0"
                footer={null}
            >
                <div className={style.contentContainer}>
                    <InputV2.Search
                        style={{ margin: 8 }}
                        onSearch={onChangeSearchInput}
                        autoFocus
                        onInput={keyboardStyleStyleList.onInput}
                        onKeyDown={keyboardStyleStyleList.onKeyDown}
                        placeholder={translation('Search')}
                    />
                    {isNoStyle ? (
                        <div className={style.emptyStyle}>
                            <div className={style.emptyStyle_text}>{translation('NoColorsAvailable')}</div>
                            <div className={style.emptyStyle_button}>
                                <WKButton
                                    type="secondary"
                                    className={style.button}
                                    onClick={goToRemoteLibraryHome}
                                    icon={<IconComponentLibrary16 />}
                                >
                                    {translation('BrowseLibraries')}
                                </WKButton>
                            </div>
                        </div>
                    ) : noSearchResult ? (
                        <div className={style.emptyStyle}>
                            <div className={style.emptyStyle_text}>{translation('NoMatchesFound')}</div>
                        </div>
                    ) : isList ? (
                        <ScrollView
                            className={classnames(style.content)}
                            key="list"
                            selectKey={keyboardStyleStyleList.preselectIndex}
                            ref={keyboardStyleStyleList.scrollViewRef}
                            block="nearest"
                            scrollbar={{ autoHeight: true, autoHeightMin: 0, autoHeightMax: 352 }}
                        >
                            <KeyboardReceiver keyCode={ToKeyCode.All} onKeydown={keyboardStyleStyleList.onKeyDown}>
                                {renderListItems()}
                            </KeyboardReceiver>
                        </ScrollView>
                    ) : (
                        <ScrollView
                            className={classnames(style.content)}
                            key="grid"
                            selectKey={selectStyleId ? selectStyleId : undefined}
                            scrollbar={{ autoHeight: true, autoHeightMin: 0, autoHeightMax: 352 }}
                        >
                            {renderGridItems()}
                        </ScrollView>
                    )}
                </div>
            </DraggablePopupV2>
        </div>
    )
}
