import { PUT } from '@tutor/network-core'
import { BaseCommonRequest } from './base-request'

// 更新工作区的排序
@PUT
export class UpdateWorkspacePreferencesRequest extends BaseCommonRequest<boolean> {
    constructor(private readonly orgId: string, private readonly workspaceIds: string[]) {
        super()
    }

    public override requestUrl() {
        return `workspace-preferences`
    }

    public override requestBody() {
        return {
            orgId: this.orgId,
            workspaceIds: this.workspaceIds,
        }
    }
}
