import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    ScaleTopLeft: '从左上角扩展',
    ScaleTop: '从顶部扩展',
    ScaleTopRight: '从右上角扩展',
    ScaleLeft: '从左侧扩展',
    ScaleCenter: '从中心扩展',
    ScaleRight: '从右侧扩展',
    ScaleBottomLeft: '从左下角扩展',
    ScaleBottom: '从底部扩展',
    ScaleBottomRight: '从右下角扩展',
} as const

export const enTranslation = {
    ScaleTopLeft: 'Scale from top left',
    ScaleTop: 'Scale from top',
    ScaleTopRight: 'Scale from top right',
    ScaleLeft: 'Scale from left',
    ScaleCenter: 'Scale from center',
    ScaleRight: 'Scale from right',
    ScaleBottomLeft: 'Scale from bottom left',
    ScaleBottom: 'Scale from bottom',
    ScaleBottomRight: 'Scale from bottom right',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
