import { translation } from './text-style-panel.translation'
/* eslint-disable no-restricted-imports */
import { GetLineHeightCommand, Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import {
    DraggablePopupPropsV2,
    DraggablePopupV2,
    IconLineheight2,
    MonoIconCommonMore16,
    Select,
    Tooltip,
    WKIconButton,
} from '../../../../../../../../ui-lib/src'
import { cmdChangePopupState } from '../../../../../../document/command/document-command'
import { switchFamily } from '../../../../../../document/command/text-command'
import {
    FontInfo,
    FontName,
    ITextStyle,
    NumberUnit,
    PopupStateType,
    TextDecoration,
    TextStyleNode,
} from '../../../../../../document/node/node'
import { useCommand } from '../../../../../../main/app-context'
import { DeepRequired, useViewState } from '../../../../../../view-state-bridge'
import { useFontManagerService } from '../../../../../context/document-context'
import { useRecentFonts } from '../../../../../context/recent-fonts-context'
import { usePosition } from '../../../../../utils/use-position'
import { SingleGrid } from '../../../../atom/grid/single-grid'
import { InputOptionsForUndoSquash } from '../../../../atom/inputs/components/formatted-input'
import { ScrubbableInputLetterSpacing } from '../../../../atom/inputs/scrubbable-input-letter-spacing'
import { ScrubbableInputLineHeight } from '../../../../atom/inputs/scrubbable-input-line-height'
import { ScrubbableInputNumber } from '../../../../atom/inputs/scrubbable-input-number'
import { TextValueUnit, Value } from '../../../../atom/inputs/utils/type'
import { useUserFontNameFirst } from '../../../common/use-user-font-name-first'
import { FontMissIcon } from '../../../text/font/font-miss-icon/font-miss-icon'
import { FontSelectInput } from '../../../text/font/font-select-input'
import { CustomAxesStyleInfo, useFontStyleOptionList } from '../../../text/use-font-style-option-list'
import { modifiedMultiPopup } from '../../modified-multi-popup'
import { createITextStyle } from '../../style-text/style-text-utils'
import { CommentAttributeTitle } from '../common-attribute-title/comment-attribute-title'
import { CommonFooter } from '../common-footer/common-footer'
import { CommonNameDescription } from '../common-name-description/common-name-description'
import { CommonThumbnail } from '../common-thumbnail/common-thumbnail'
import { FontSizeSelect } from './font-size-select'
import classes from './text-style-panel.module.less'
import { TextStyleSetting } from './text-style-setting'

const textDecorationOptions = [TextDecoration.None, TextDecoration.Underline, TextDecoration.Strikethrough]

export type PickDraggablePopupProps = Pick<DraggablePopupPropsV2, 'visible' | 'position' | 'header' | 'onCancel'>

export interface TextStylePanelProps extends PickDraggablePopupProps {
    textStyleData: ITextStyle & Pick<TextStyleNode, 'id' | 'name' | 'description' | 'paragraphSpacing'>
    openFrom: 'create' | 'remote' | 'normal'
    variantData?: Omit<DeepRequired<Wukong.DocumentProto.IVTextStyleNodeState>, 'basic'>
    onChangeName: (v: string) => void
    onChangeDescription: (v: string) => void
    onChangeFontFamily: (v: FontName) => void
    onChangeFontStyle: (v: FontName, customAxesStyle: string, isMissFontCustomStyle: boolean) => void
    onChangeFontSize: (v: number | string, options?: InputOptionsForUndoSquash) => void
    onChangeLineHeight: (v: TextValueUnit, options?: InputOptionsForUndoSquash) => void
    onChangeLetterSpacing: (v: TextValueUnit, options?: InputOptionsForUndoSquash) => void
    onChangeParagraphSpacing: (v: number, options?: InputOptionsForUndoSquash) => void
    onChangeTextDecoration: (v: TextDecoration) => void
    onClickRemoteStyleLink?: (e: React.MouseEvent) => void
    onClickCreate?: () => void
    hideCommonFooter?: boolean
    dataTestIds?: {
        fontSizeInput?: string
        lineHeightInput?: string
        letterSpacingInput?: string
        paragraphSpacingInput?: string
        decorationNone?: string
        decorationUnderline?: string
        decorationStrikethrough?: string
    }
}

export function TextStylePanel<T extends TextStylePanelProps = TextStylePanelProps>(props: T) {
    const { textStyleData, openFrom, variantData, hideCommonFooter } = props
    const command = useCommand()

    const isRemoteStyle = useMemo(() => openFrom === 'remote', [openFrom])
    const isCreateStyle = useMemo(() => openFrom === 'create', [openFrom])

    const fontManagerService = useFontManagerService()
    const availableFonts = fontManagerService.states.use.allFontInfosState()

    const iTextStyle = useMemo(() => (textStyleData ? createITextStyle(textStyleData) : undefined), [textStyleData])

    const isMissStyle = textStyleData.missFontInfo?.isMissStyle ?? false

    const isMissFamily = textStyleData.missFontInfo?.isMissFamily ?? false

    const availableFontInfo = useMemo(() => {
        const emptyFontInfo = { family: '', localizedFamily: '', styles: [] }
        const fontInfo = availableFonts.find((_fontInfo) => _fontInfo.family === textStyleData?.fontName.family)
        return fontInfo ?? emptyFontInfo
    }, [availableFonts, textStyleData?.fontName.family])

    const selectedFontName = useUserFontNameFirst(textStyleData?.fontName ?? null)

    const lineHeightAutoValue = useMemo(() => {
        if (textStyleData && textStyleData.lineHeight.unit == NumberUnit.Auto) {
            const value =
                command.DEPRECATED_invokeBridge(
                    GetLineHeightCommand,
                    Wukong.DocumentProto.Arg_getLineHeight.create({
                        fontName: textStyleData.fontName,
                        fontSize: textStyleData.fontSize,
                    })
                ).value ?? 0

            return {
                mixed: false,
                value,
            }
        }
    }, [command, textStyleData])

    const { recentFonts, updateRecentFont } = useRecentFonts()
    const _onChangeFontFamily = (fontInfo: FontInfo) => {
        const fontName = switchFamily(textStyleData?.fontName, fontInfo)
        if (fontName) {
            props.onChangeFontFamily(fontName)
            updateRecentFont(fontInfo)
        }
    }

    const _onChangeFontStyle = (info: string | CustomAxesStyleInfo) => {
        if (info == 'variable-setting') {
            onClickMore()
            return
        }

        const style = typeof info === 'string' ? info : info.style
        const customAxesStyle = typeof info === 'string' ? '' : info.customAxesStyle.name
        const isMissFontCustomStyle = typeof info === 'string' ? false : info.isMissFontCustomStyle
        const fontName = availableFontInfo.styles.find((item) => item.style === style)
        if (fontName) {
            props.onChangeFontStyle(fontName, customAxesStyle, isMissFontCustomStyle)
        } else if (isMissFontCustomStyle) {
            props.onChangeFontStyle(textStyleData?.fontName, customAxesStyle, isMissFontCustomStyle)
        }
    }

    const _onChangeParagraphSpacing = (value: Value, options?: InputOptionsForUndoSquash) => {
        if (typeof value === 'number') {
            props.onChangeParagraphSpacing(value, options)
        }
    }

    const fontStyleOptionList = useFontStyleOptionList({
        fontInfo: availableFontInfo,
        selectedFontName,
        customAxesStyle: variantData?.customAxesStyle ?? '',
        showVariantsSetting: !!variantData?.variationInfo.hasValue,
        stylesInfo: variantData?.customStyleState.styleToCustomAxes ?? [],
    })

    const [activeKey, setActiveKey] = useState<Wukong.DocumentProto.TextSettingTabKey>(
        Wukong.DocumentProto.TextSettingTabKey.TEXT_SETTING_TAB_KEY_BASICS
    )
    const popupState = useViewState('popupState')
    const [moreSettingOpen, setMoreSettingOpen] = useState<boolean>(false)
    const { position, reactRef } = usePosition({ isOpen: moreSettingOpen })
    const styleValue: CustomAxesStyleInfo = useMemo(() => {
        return {
            style: selectedFontName?.style ?? '',
            customAxesStyle: { name: variantData?.customAxesStyle ?? '' },
            isMissFontCustomStyle: isMissStyle,
        }
    }, [isMissStyle, selectedFontName?.style, variantData?.customAxesStyle])
    const styleLabel = useMemo(() => {
        return variantData?.customAxesStyleDisplay.length
            ? variantData.customAxesStyleDisplay
            : selectedFontName?.localizedStyle
    }, [selectedFontName?.localizedStyle, variantData?.customAxesStyleDisplay])

    const onClickMore = useCallback(() => {
        const _popupState = modifiedMultiPopup(
            popupState,
            PopupStateType.POPUP_STATE_TYPE_TEXT,
            !moreSettingOpen
                ? {
                      type: PopupStateType.POPUP_STATE_TYPE_TEXT,
                      reciprocalIndex: -1,
                      multiPopup: [],
                  }
                : undefined
        )
        command.invoke(cmdChangePopupState, _popupState)
        setMoreSettingOpen(!moreSettingOpen)
    }, [command, moreSettingOpen, popupState])

    return (
        <DraggablePopupV2
            visible={props.visible}
            position={props.position}
            positionRightBase
            header={props.header}
            bodyClassName={classnames(classes.body, {
                [classes.existCreate]: isCreateStyle,
                [classes.existRemote]: isRemoteStyle,
            })}
            footer={
                hideCommonFooter ? null : (
                    <CommonFooter
                        isCreateStyle={isCreateStyle}
                        isRemoteStyle={isRemoteStyle}
                        onClickJumpLink={props.onClickRemoteStyleLink}
                        onClickCreateButton={props.onClickCreate}
                    />
                )
            }
            notUseDefaultFooterClassName
            onCancel={props.onCancel}
            enableScrollBar={true}
            styleType="editor"
            testId="text-style-panel"
            closeTestId="close-text-style-panel"
        >
            <CommonThumbnail type="text" data={iTextStyle} />
            <CommonNameDescription
                isReadOnly={isRemoteStyle}
                name={textStyleData?.name}
                description={textStyleData?.description}
                onChangeName={props.onChangeName}
                onChangeDescription={props.onChangeDescription}
                key={textStyleData?.id}
                testId="text-style-name-description"
            />
            <CommentAttributeTitle />
            <FontSelectInput
                className={classes.family}
                selectedFamily={textStyleData?.fontName.family ?? ''}
                selectedLocalizedFamily={textStyleData?.fontName.localizedFamily ?? ''}
                recentFonts={recentFonts}
                onChange={_onChangeFontFamily}
                missIcon={isMissFamily}
                disabled={isRemoteStyle}
                searchStringBelongTo={textStyleData.id}
            ></FontSelectInput>
            <SingleGrid>
                <SingleGrid.Item start={5} span={25}>
                    <Select.NormalSingleLevel
                        value={styleValue}
                        onChange={_onChangeFontStyle}
                        label={styleLabel}
                        icon={isMissStyle ? <FontMissIcon testId="style-node-miss-style" /> : null}
                        disabled={isMissFamily || isRemoteStyle}
                        maxWidth={216}
                        dataTestIds={{
                            triggerFocus: 'font-style-select-trigger-focus',
                            container: 'font-style-select-container',
                        }}
                    >
                        {fontStyleOptionList}
                    </Select.NormalSingleLevel>
                </SingleGrid.Item>
                <SingleGrid.Item start={32} span={25}>
                    <FontSizeSelect
                        value={textStyleData?.fontSize}
                        onChange={props.onChangeFontSize}
                        disabled={isMissStyle || isRemoteStyle}
                        optionWidth={100}
                        isMixed={false}
                        dataTestIds={props.dataTestIds}
                    />
                </SingleGrid.Item>
            </SingleGrid>
            <SingleGrid className={classes.grid}>
                <Tooltip title={translation('LineHeight')}>
                    <SingleGrid.Item start={5} span={25}>
                        <ScrubbableInputLineHeight
                            disabled={isMissStyle || isRemoteStyle}
                            value={textStyleData?.lineHeight}
                            onChange={props.onChangeLineHeight}
                            autoValue={lineHeightAutoValue}
                            testId={props.dataTestIds?.lineHeightInput}
                        />
                    </SingleGrid.Item>
                </Tooltip>
                <Tooltip title={translation('LetterSpacing')}>
                    <SingleGrid.Item start={32} span={25}>
                        <ScrubbableInputLetterSpacing
                            disabled={isMissStyle || isRemoteStyle}
                            value={textStyleData?.letterSpacing}
                            onChange={props.onChangeLetterSpacing}
                            testId={props.dataTestIds?.letterSpacingInput}
                        />
                    </SingleGrid.Item>
                </Tooltip>
            </SingleGrid>
            <SingleGrid className={classes.grid} ref={reactRef}>
                <Tooltip title={translation('ParagraphSpacing')}>
                    <SingleGrid.Item start={5} span={25}>
                        <ScrubbableInputNumber
                            disabled={isMissStyle || isRemoteStyle}
                            icon={<IconLineheight2 />}
                            value={textStyleData?.paragraphSpacing}
                            onChange={_onChangeParagraphSpacing}
                            min={0}
                            testId={props.dataTestIds?.paragraphSpacingInput}
                        />
                    </SingleGrid.Item>
                </Tooltip>

                <SingleGrid.Item start={49} span={12} horizontalCenter>
                    <Tooltip title={translation('MoreSettings')}>
                        <WKIconButton
                            icon={<MonoIconCommonMore16 />}
                            type={moreSettingOpen ? 'deepBlue' : 'primary'}
                            onClick={onClickMore}
                            data-testid="more-setting"
                        />
                    </Tooltip>
                </SingleGrid.Item>
            </SingleGrid>

            <DraggablePopupV2
                visible={moreSettingOpen}
                position={position}
                onCancel={onClickMore}
                styleType="editor"
                footer={null}
                bodyClassName="p-0"
                positionRightBase
                header={translation('TypeSettings')}
                closeTestId={'close-style-setting'}
            >
                <TextStyleSetting
                    activeKey={activeKey}
                    onChangeActiveKey={setActiveKey}
                    variationInfo={variantData?.variationInfo}
                    textStyleData={textStyleData}
                    onChangeTextDecoration={props.onChangeTextDecoration}
                    dataTestIds={{
                        decorationNone: props.dataTestIds?.decorationNone,
                        decorationUnderline: props.dataTestIds?.decorationUnderline,
                        decorationStrikethrough: props.dataTestIds?.decorationStrikethrough,
                    }}
                    readonly={isRemoteStyle || isMissStyle}
                />
            </DraggablePopupV2>
        </DraggablePopupV2>
    )
}
