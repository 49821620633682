import { PluginPublishRequestV0, PluginVO } from '../../../../kernel/interface/plugin'
import {
    CreatePlugin,
    GetEditablePlugins,
    GetPublishedPlugins,
    GetPublishedPluginsByIds,
    PublishPlugin,
    UnpublishPlugin,
} from '../../../../kernel/request/plugin-dev'
import { PluginId } from './template/type'

export const fetchCreatePlugin = (orgId?: string): Promise<PluginVO> => {
    return new CreatePlugin(orgId ?? '').start()
}

export const fetchPublishPlugin = (pluginId: PluginId, publishInfo: PluginPublishRequestV0): Promise<PluginVO> => {
    return new PublishPlugin(pluginId, publishInfo).start()
}

export const fetchGetPublishedPlugins = (orgId: string): Promise<PluginVO[]> => {
    return new GetPublishedPlugins(orgId).start()
}

export const fetchGetEditablePlugins = (orgId: string): Promise<PluginVO[]> => {
    return new GetEditablePlugins(orgId).start()
}

export const fetchUnpublishPlugin = (pluginId: PluginId): Promise<void> => {
    return new UnpublishPlugin(pluginId).start()
}

export const fetchGetPublishedPluginsByIds = (orgId: string, ids: string[]): Promise<PluginVO[]> => {
    return new GetPublishedPluginsByIds(orgId, ids).start()
}
