import { ConstraintUnit } from '../../../../../document/node/node'
import { clampMinMax } from './clamp'
import { parseString } from './parse-string'
import { ConstraintPropsValueUnit, Formatter } from './type'

export const unitFromChar = (char: string): ConstraintUnit => {
    switch (char) {
        case 'w':
            return ConstraintUnit.Width
        case 'h':
            return ConstraintUnit.Height
        default:
            return ConstraintUnit.Scale
    }
}

export const charFromUnit = (unit: ConstraintUnit): string => {
    switch (unit) {
        case ConstraintUnit.Scale:
            return 'x'
        case ConstraintUnit.Height:
            return 'h'
        case ConstraintUnit.Width:
            return 'w'
    }
}

const unitInferFromInput = (input: string): ConstraintUnit | null => {
    if (/\d+(\.\d+)?[whx]/.test(input)) {
        if (/[whx]\d/.test(input)) {
            return null
        } else {
            const first: number = input.search(/[whx]/)
            if (first === -1) {
                return ConstraintUnit.Scale
            } else {
                const unit: ConstraintUnit = unitFromChar(input.charAt(first))
                return unit
            }
        }
    } else {
        return null
    }
}

const removeUnit = (input: string, unit: ConstraintUnit) => {
    return input.replaceAll(charFromUnit(unit), '')
}

type Format = Formatter<ConstraintPropsValueUnit>

export const parse: Format['parse'] = (input, oldValue?): ConstraintPropsValueUnit => {
    const previousUnit = oldValue?.unit ?? ConstraintUnit.Scale
    const unit = unitInferFromInput(input) ?? previousUnit
    try {
        const unitRemoved = removeUnit(input, unit)
        const value = parseString(unitRemoved)
        return {
            value,
            unit,
        }
    } catch {
        return oldValue ?? { value: 1, unit: ConstraintUnit.Scale }
    }
}

const only2Dot = (num: number): string => {
    if (Number.isInteger(num)) {
        return num.toString()
    } else {
        const fixed = num.toFixed(2)
        const trimmed = fixed.replace(/0+$/, '')
        return trimmed
    }
}

export const format = (value: ConstraintPropsValueUnit) => {
    return `${only2Dot(value.value)}${charFromUnit(value.unit)}`
}

const scaleClamp = (num: number): number => {
    const value = clampMinMax(num, { min: 0, max: 1024 })
    return value === 0 ? 1 : Number(only2Dot(value))
}

const HWClamp = (num: number): number => {
    const value = clampMinMax(num, { min: 0, max: 32768 })
    return value === 0 ? 1 : Number(only2Dot(value))
}

export const clamp: Format['clamp'] = (value, _isIncrement?) => {
    switch (value.unit) {
        case ConstraintUnit.Scale:
            return {
                value: scaleClamp(value.value),
                unit: value.unit,
            }
        default:
            return {
                value: HWClamp(value.value),
                unit: value.unit,
            }
    }
}

export const increment: Format['increment'] = (v, isIncrement, _shiftKey) => {
    const delta = isIncrement ? 1 : -1
    return {
        value: v.value + delta,
        unit: v.unit,
    }
}
