import { useCommentService } from '../../../../main/app-context'

export function useCommentUnread() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.hasUnreadComment()
}

export function useMentionUsersRect() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.mentionUsersRect()
}

export function useMentionUserList() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.mentionUserList()
}

export function usePreSelectedMentionUserIndex() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.preSelectedMentionUserIndex()
}

export function useCommentUsersMap() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.usersMap()
}

export function useActiveCommentId() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.activeCommentId()
}

export function useHoverCommentId() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.hoverCommentId()
}

export function useCanvasCommentsMap() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.canvasCommentsMap()
}

export function useCanvasPendingCommentsMap() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.canvasPendingCommentsMap()
}

export function useCanvasCommentClustersMap() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.canvasCommentClustersMap()
}

export function useIsStopSyncActiveCommentPosition() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.isStopSyncActiveCommentPosition()
}

export function useInsertEmoji() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.insertEmoji()
}

export function useInsertEmojiTarget() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.insertEmojiTarget()
}

export function useInsertEmojiRect() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.insertEmojiRect()
}

export function useCommentsList() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.commentsList()
}

export function useCommentSortType() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.commentSortType()
}

export function useCommentShowFilters() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.commentShowFilters()
}

export function useCommentSearchString() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.commentSearchString()
}

export function useCommentNoticeType() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.commentNoticeType()
}

export function useCreateComment() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.createComment()
}

export function useDrawingPosition() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.drawingPosition()
}

export function useCommentNeedShake() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.needShake()
}

export function useSelectAllInCreateComment() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.selectAllInCreateComment()
}

export function useIsShowMentionUserList() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.isShowMentionUserList()
}

export function useInsertMentionUserInCreateComment() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.insertMentionUserInCreateComment()
}

export function useEditingReply() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.editingReply()
}

export function useIsFastInsertMentionUser() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.isFastInsertMentionUser()
}

export function usePrevCommentId() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.prevCommentId()
}

export function useNextCommentId() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.nextCommentId()
}

export function useInsertMentionUserInCreateReply() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.insertMentionUserInCreateReply()
}

export function useInsertMentionUserInEditReply() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.insertMentionUserInEditReply()
}

export function useReplaceCreateReplyMessages() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.replaceCreateReplyMessages()
}

export function useSelectAllInCreateReply() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.selectAllInCreateReply()
}

export function useSelectAllInEditReply() {
    const commentService = useCommentService()
    return commentService.store.useZustandStore.use.selectAllInEditReply()
}
