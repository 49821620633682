import { getTranslationValue } from '../../../../../../../../../util/src/i18n'

export const zhTranslation = {
    detachProp: '分离属性',
    seeProp: '查看属性',
    addProp: '添加属性',
    createProp: '创建属性...',
    editProp: '编辑属性',
    zombiePropOnComponent: '组件上找不到该属性，请为组件添加属性',
    zombiePropOnNonComponent: '这个图层来自一个组件，并带着它的属性',
} as const

export const enTranslation = {
    detachProp: 'Detach property',
    seeProp: 'Go to property',
    addProp: 'Add property to component',
    createProp: 'Create property...',
    editProp: 'Edit property',
    zombiePropOnComponent: 'Property not found on component. Add property to provide controls for instances.',
    zombiePropOnNonComponent: 'This layer came from a component, and brought this property with it.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
