/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { HTMLAttributes, useCallback, useMemo } from 'react'
import { DragMoveBox } from '../../../../../../ui-lib/src'
import { CommentUser } from '../../../../kernel/interface/comment'
import { CommentAvatar } from '../comment-avatar/comment-avatar'
import classes from './bubble.module.less'

export interface ClusterBubbleDOMProps extends HTMLAttributes<HTMLElement> {
    showUsers: CommentUser[] // 倒序展示
    commentCounts: number
    showUsersOverlaySize: number
    isUnread?: boolean
    anchorRefCallback: (e: HTMLDivElement | null) => void
    dataTestIds?: {
        bubble?: string
        cover?: string
        content?: string
        backgroud?: string
    }
}

export function ClusterBubbleDOM(props: ClusterBubbleDOMProps) {
    const {
        commentCounts,
        showUsers,
        showUsersOverlaySize,
        isUnread,
        onClick, // 这里的点击事件指的是没有移动过位置触发的点击
        anchorRefCallback,
        onMouseMove,
        dataTestIds,
    } = props

    const _onClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            onClick?.(e)
        },
        [onClick]
    )

    return useMemo(
        () => (
            <DragMoveBox
                ref={anchorRefCallback}
                onClick={_onClick}
                className={classnames(classes.clusterBubble, { [classes.unread]: isUnread })}
                onMouseMove={onMouseMove}
                data-testid={dataTestIds?.bubble}
            >
                <div data-testid={dataTestIds?.cover} className={classnames(classes.clusterCover, classes.fadeOut)}>
                    <span style={{ pointerEvents: 'none' }}>{commentCounts}</span>
                </div>
                <div data-testid={dataTestIds?.backgroud} className={classes.clusterContentBackgroud}></div>
                <div
                    className={classnames(
                        classes.clusterContent,
                        { [classes.one]: showUsers.length == 2 && showUsers[0].id == showUsers[1].id },
                        { [classes.two]: showUsers.length == 2 && showUsers[0].id != showUsers[1].id },
                        { [classes.three]: showUsers.length == 3 },
                        { [classes.four]: showUsers.length == 4 && showUsersOverlaySize == 0 },
                        { [classes.five]: (showUsers.length == 4 && showUsersOverlaySize > 1) || showUsers.length == 5 }
                    )}
                    data-testid={dataTestIds?.content}
                >
                    {showUsers.map((user, index) => (
                        <CommentAvatar
                            key={index}
                            userInfo={user}
                            className={classnames(classes.commentAvatar, classes.clusterCommentAvatar)}
                        />
                    ))}
                    {showUsersOverlaySize > 0 ? (
                        <span
                            className={classnames(
                                classes.moreCommentAvatar,
                                classes.commentAvatar,
                                classes.clusterCommentAvatar,
                                { [classes.unreadInCluster]: isUnread }
                            )}
                            style={{ width: showUsersOverlaySize.toString().length * 8 + 16 }}
                        >
                            +{showUsersOverlaySize}
                        </span>
                    ) : null}
                </div>
            </DragMoveBox>
        ),
        [
            _onClick,
            anchorRefCallback,
            commentCounts,
            dataTestIds?.backgroud,
            dataTestIds?.bubble,
            dataTestIds?.content,
            dataTestIds?.cover,
            isUnread,
            onMouseMove,
            showUsers,
            showUsersOverlaySize,
        ]
    )
}
