/* eslint-disable no-restricted-imports */
import { MonoIconToolbarComment24, MonoIconToolbarGripper24, MonoIconToolbarMove24 } from '../../../../../../ui-lib/src'
import classNames from 'classnames'
import { FC } from 'react'
import { EditorMode } from '../../../../document/node/node'
import { Switch } from '../../atom/switch/switch'
import { getEditorModeName, getEditorModeShortCut } from '../utils'
import style from './toolbar.module.less'
import { useToolbar } from './use-toolbar'

export const ReadonlyTool: FC = () => {
    const { editorMode, hasUnreadComment, onSwitchChange } = useToolbar()

    return (
        <Switch active={editorMode} onSwitchChange={onSwitchChange} data-testid="readonly-tool">
            <Switch.Item
                className={style.switchItem44}
                key={EditorMode.EM_Move}
                tooltipTitle={getEditorModeName(EditorMode.EM_Move)}
                tooltipShortcut={getEditorModeShortCut(EditorMode.EM_Move)}
            >
                <MonoIconToolbarMove24 />
            </Switch.Item>
            <Switch.Item
                className={style.switchItem44}
                key={EditorMode.EM_HandTool}
                tooltipTitle={getEditorModeName(EditorMode.EM_HandTool)}
                tooltipShortcut={getEditorModeShortCut(EditorMode.EM_HandTool)}
            >
                <MonoIconToolbarGripper24 />
            </Switch.Item>
            <Switch.Item
                className={`${style.switchItem44} wk-comment-tool`}
                key={EditorMode.EM_Comment}
                tooltipTitle={getEditorModeName(EditorMode.EM_Comment)}
                tooltipShortcut={getEditorModeShortCut(EditorMode.EM_Comment)}
            >
                <span className={classNames(style.comment, { [style.unread]: hasUnreadComment })}>
                    <MonoIconToolbarComment24 />
                </span>
            </Switch.Item>
        </Switch>
    )
}
