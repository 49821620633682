import {
    CurrentPageSetSelectionCommandWasmCall,
    DeletePrototypeStartingPointByIdCommand,
    UpdatePrototypeFlowOrderIndexCommand,
    UpdatePrototypeStartingPointNameByIdCommand,
    UpdatePrototypingVisibleCommand,
    Wukong,
    ZoomToSelectionWasmCall,
} from '@wukong/bridge-proto'
import classNames from 'classnames'
import { FC, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import {
    InputV2,
    MonoIconCanvasLink16,
    MonoIconCommonEyes16,
    MonoIconCommonEyesClose16,
    MonoIconPanelTarget16,
    SimpleDrag,
    Tooltip,
    WKIconButton,
    WKToast,
} from '../../../../../../ui-lib/src'
import { useIsEllipsisActive } from '../../../../../../util/src'
import { ShortcutKey, shortcutLabelMap } from '../../../../kernel/interface/shortcut-key'
import { useAppContext } from '../../../../main/app-context'
import { copyFlowLinkForEditor } from '../../../../prototype/context/present-url'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { translation } from './prototype-flows.translation'

const FlowItem: FC<{
    focusParent: () => void
    flow: Wukong.DocumentProto.IFlowForPanel
    selectedDragItems: number[]
    onRenamingChange: (renaming: boolean) => void
    disableRename: boolean
    index: number
}> = ({ flow, index, onRenamingChange, disableRename, focusParent, selectedDragItems }) => {
    const command = useCommand()
    const copyLink = useCallback(() => {
        copyFlowLinkForEditor(command, flow.nodeId!)
        WKToast.show(translation('LinkCopied'))
    }, [command, flow.nodeId])

    const move = useCallback(() => {
        command.DEPRECATED_invokeBridge(CurrentPageSetSelectionCommandWasmCall, { selection: [flow.nodeId!] })
        command.DEPRECATED_invokeBridge(ZoomToSelectionWasmCall)
    }, [command, flow.nodeId])

    const moveAndClearSelection = useCallback(() => {
        move()
        command.DEPRECATED_invokeBridge(CurrentPageSetSelectionCommandWasmCall, { selection: [] })
    }, [command, move])

    const [renaming, setRenaming] = useState(false)

    const changeFlowName = useCallback(
        (name: string) => {
            onRenamingChange(false)
            setRenaming(false)
            if (name.length) {
                command.DEPRECATED_invokeBridge(UpdatePrototypeStartingPointNameByIdCommand, {
                    name: name,
                    nodeId: flow.nodeId!,
                })
                command.commitUndo()
            }
            focusParent()
        },
        [command, flow.nodeId, focusParent, onRenamingChange]
    )

    const ref = useRef<HTMLSpanElement>(null)
    const isEllipsis = useIsEllipsisActive(ref.current)

    return (
        <div
            data-testid="flow-panel-item"
            className={classNames(
                'h-9 px-4 flex items-center group',
                selectedDragItems.includes(index) && 'bg-$wk-v2-fill-color-brand-2'
            )}
        >
            {renaming ? (
                <div tabIndex={-1} className="-mx-2" style={{ width: `calc(100% + 16px)` }}>
                    <InputV2
                        rootClassName="w-full"
                        size="small"
                        dataTestIds={{
                            input: 'flow-name-input',
                        }}
                        onMouseDown={(e) => e.stopPropagation()}
                        value={flow.name}
                        onKeyDown={(e) => e.stopPropagation()}
                        autoFocus
                        onBlur={(e) => {
                            changeFlowName(e.target.value)
                        }}
                    />
                </div>
            ) : (
                <>
                    <Tooltip title={flow.name} inactivation={!isEllipsis}>
                        <span
                            ref={ref}
                            onDoubleClick={() => {
                                if (disableRename) return
                                onRenamingChange(true)
                                setRenaming(true)
                                moveAndClearSelection()
                            }}
                            data-testid="flow-name"
                            className="truncate flex-1"
                        >
                            {flow.name}
                        </span>
                    </Tooltip>
                    <Tooltip title={translation('Position')}>
                        <WKIconButton
                            onClick={move}
                            icon={<MonoIconPanelTarget16 />}
                            className="hidden group-hover:block"
                        />
                    </Tooltip>
                    <Tooltip title={translation('CopyLink')}>
                        <WKIconButton
                            onClick={copyLink}
                            className="!ml-1 hidden group-hover:block"
                            icon={<MonoIconCanvasLink16 />}
                            data-testid={'prototype-copy-flow-link'}
                        />
                    </Tooltip>
                </>
            )}
        </div>
    )
}

export const PrototypeFlows: FC<{ showVisibleButton?: boolean; disableDelete: boolean; disableRename: boolean }> = ({
    disableRename,
    showVisibleButton,
    disableDelete,
}) => {
    const flows = useViewState('prototypeFlowList', { list: [] })?.list
    const [selectedIds, setSelectedIds] = useState<string[]>([])
    const selectedIdxs = useMemo(() => {
        return selectedIds.map((id) => flows.findIndex((flow) => flow.nodeId === id)).filter((idx) => idx !== -1)
    }, [flows, selectedIds])
    const ref = useRef<HTMLDivElement>(null)
    useClickAway(ref, () => {
        setSelectedIds([])
    })
    const command = useCommand()

    const onDragEnd = useCallback(
        (sortedList: Wukong.DocumentProto.IFlowForPanel[], selectedIdx: number[]) => {
            if (selectedIdx.length) {
                command.DEPRECATED_invokeBridge(UpdatePrototypeFlowOrderIndexCommand, {
                    nodeIds: sortedList.filter((_, idx) => selectedIdx.includes(idx)).map((flow) => flow.nodeId!),
                    prevNodeId: sortedList[selectedIdx[0] - 1]?.nodeId,
                    nextNodeId: sortedList[selectedIdx[selectedIdx.length - 1] + 1]?.nodeId,
                })
                command.commitUndo()
            }
        },
        [command]
    )

    const onKeyDown = useCallback(
        (e: KeyboardEvent<HTMLDivElement>) => {
            switch (e.key) {
                case 'Backspace':
                    if (!disableDelete) {
                        selectedIds.forEach((id) => {
                            command.DEPRECATED_invokeBridge(DeletePrototypeStartingPointByIdCommand, { value: id })
                        })
                        command.commitUndo()
                    }
                    break
            }
        },
        [command, disableDelete, selectedIds]
    )

    useEffect(() => {
        if (selectedIdxs.length > 0) {
            ref.current?.focus({ preventScroll: true })
        } else {
            ref.current?.blur()
        }
    }, [selectedIdxs])

    const focusParent = useCallback(() => {
        ref.current?.focus({ preventScroll: true })
    }, [])

    const [someItemRenameing, setSomeItemRenameing] = useState(false)

    if (flows.length == 0) {
        return <></>
    }
    return (
        <div
            ref={ref}
            onKeyDown={onKeyDown}
            tabIndex={someItemRenameing ? undefined : -1}
            className="color-$wk-v2-label-color-gray-13 pt-2 pb-3 b-b border-color-$wk-gray-3 wk-text-12"
        >
            <div className="py-1.5 wk-font-semibold pl-4 pr-10px flex items-center justify-between">
                <span>{translation('Flow')}</span>
                {showVisibleButton && <PrototypeVisibleButton />}
            </div>
            <SimpleDrag
                selectedIndexList={selectedIdxs}
                onSelectChange={(idxs) => {
                    setSelectedIds(flows.filter((_, idx) => idxs.includes(idx)).map((flow) => flow.nodeId))
                }}
                items={flows}
                onDragDone={onDragEnd}
                onMouseDown={(e) => e.stopPropagation()}
            >
                {flows.map((flow, index) => (
                    <SimpleDrag.Item key={index} itemIndex={index} fullAreaInteractive>
                        <FlowItem
                            focusParent={focusParent}
                            disableRename={disableRename}
                            onRenamingChange={setSomeItemRenameing}
                            index={index}
                            flow={flow}
                            selectedDragItems={selectedIdxs}
                            key={flow.name + flow.nodeId}
                        />
                    </SimpleDrag.Item>
                ))}
            </SimpleDrag>
        </div>
    )
}

export const PrototypeVisibleButton = () => {
    const visible = useViewState('prototypingVisible')
    const command = useCommand()
    const userConfigService = useAppContext().userConfigService

    const toggleVisible = useCallback(() => {
        command.DEPRECATED_invokeBridge(UpdatePrototypingVisibleCommand)
        command.commitUndo()
        userConfigService.updateConfig2('prototypingVisibility', !visible)
    }, [command, userConfigService, visible])

    return (
        <Tooltip
            title={translation('ShowPrototypingSettings')}
            shortcut={shortcutLabelMap[ShortcutKey.PrototypingVisble]}
        >
            <WKIconButton
                onClick={toggleVisible}
                icon={
                    visible ? (
                        <MonoIconCommonEyes16 data-testid="prototyping-visible-true" />
                    ) : (
                        <MonoIconCommonEyesClose16 data-testid="prototyping-visible-false" />
                    )
                }
            />
        </Tooltip>
    )
}
