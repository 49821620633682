import { BusinessStatusCode } from '../interface/request-error-code'
import { translation } from './error-handler.translation'

// businessStatus 8888 表示断网
export const RequestResponseErrorHandler = (e: any) => {
    if (e?.req?._response?.data) {
        return e.req._response.data as { message: string; status: number; timestamp: number; businessStatus: number }
    } else {
        return { businessStatus: 8888 } as {
            message: string
            status: number
            timestamp: number
            businessStatus: number
        }
    }
}

export const contentAuditError = (err: any, type: 'text' | 'image' = 'text') => {
    const res = RequestResponseErrorHandler(err)
    let msg = ''
    if (res.businessStatus === BusinessStatusCode.AuditNotPass) {
        msg = type === 'text' ? `${translation('yBLOTd')}` : `${translation('wJlDKM')}`
    } else if (res.businessStatus === BusinessStatusCode.AuditFailed) {
        msg = type === 'text' ? `${translation('WBSAlt')}` : `${translation('vzheZs')}`
    }

    return msg
}

export const requestDidFailedHandler = (err: any, statusCode: number) => {
    const msg = contentAuditError(err)
    if (!msg) {
        window.dispatchEvent(new CustomEvent('request-error', { detail: { statusCode } }))
    }
    return false
}
