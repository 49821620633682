/* eslint-disable no-restricted-imports */
import { UpdateLayoutGridsCommand, UpdateLayoutGridStyleLayoutGridsCommand, Wukong } from '@wukong/bridge-proto'
import { useCallback, useMemo } from 'react'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'

const useLayoutGridViewState = () => {
    const selectLayoutGridState = useViewState('selectionLayoutGridState')

    return useMemo(() => {
        if (!selectLayoutGridState) {
            return { type: 'none' } as const
        }

        switch (selectLayoutGridState.type) {
            case Wukong.DocumentProto.VSelectionLayoutGridType.V_SELECTION_LAYOUT_GRID_TYPE_EMPTY:
            case Wukong.DocumentProto.VSelectionLayoutGridType.V_SELECTION_LAYOUT_GRID_TYPE_NORMAL:
                return {
                    type: 'normal',
                    layoutGridStyleNode: null,
                    layoutGrids: selectLayoutGridState.layoutGrids,
                }
            case Wukong.DocumentProto.VSelectionLayoutGridType.V_SELECTION_LAYOUT_GRID_TYPE_STYLE:
                return {
                    type: 'style',
                    layoutGridStyleNode: selectLayoutGridState.layoutGridStyleId,
                    layoutGrids: null,
                }
            case Wukong.DocumentProto.VSelectionLayoutGridType.V_SELECTION_LAYOUT_GRID_TYPE_MIXED:
                return {
                    type: 'mixed',
                    layoutGridStyleNode: null,
                    layoutGrids: null,
                }
        }
    }, [selectLayoutGridState])
}

export const useLayoutGridModel = () => {
    const modelState = useLayoutGridViewState()
    const command = useCommand()

    const changelayoutGrids = useCallback(
        (
            layoutGrids: Wukong.DocumentProto.ILayoutGrid[],
            clearSelected: boolean,
            options?: InputOptionsForUndoSquash
        ) => {
            command.DEPRECATED_invokeBridge(
                UpdateLayoutGridsCommand,
                Wukong.DocumentProto.UpdateLayoutGridsCommandParam.create({
                    layoutGrids,
                    clearSelected,
                })
            )
            command.commitUndo(options?.commitType)
        },
        [command]
    )

    const changeLayoutGridStyleNode = useCallback(
        (styleNode: string, layoutGrids: Wukong.DocumentProto.ILayoutGrid[]) => {
            command.DEPRECATED_invokeBridge(
                UpdateLayoutGridStyleLayoutGridsCommand,
                Wukong.DocumentProto.UpdateLayoutGridStyleLayoutGridsCommandParam.create({
                    styleNode,
                    layoutGrids,
                })
            )
        },
        [command]
    )

    return {
        modelState,
        modelCommand: { changelayoutGrids, changeLayoutGridStyleNode },
    }
}
