import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    loading: '加载中',
} as const

export const enTranslation = {
    loading: 'Loading',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
