/* eslint-disable no-restricted-imports */
import { ApplyCursorCommand, RenderCursorCommand, RenderCursorRecord, Wukong } from '@wukong/bridge-proto'
import { CommandInvoker } from '../../document/command/command-invoker'
import { CommitType } from '../../document/command/commit-type'
import { Bridge } from '../../kernel/bridge/bridge'
import { Sentry } from '../../kernel/sentry'
import { WK } from '../../window'
import type { Cursor } from './cursor'
import { isValidCursor, renderCursor } from './cursor-renderer'

const DefaultCustomTargetStandalone = true

/**
 * 光标管理器，用于调度光标的渲染、上屏对象等
 *
 * ```ts
 * // wasm 更新光标，上屏到 canvas
 * connectCursor()
 * attachCanvasTarget(canvas)
 * ```
 *
 * ```ts
 * // wasm 更新光标，只上屏到 el
 * attachCustomTarget(el)
 * // 退出上屏 el
 * detachCustomTarget()
 * ```
 *
 * ```ts
 * // wasm 更新光标，同时上屏到 canvas 和 el
 * attachCustomTarget(el, false)
 * // 重新上屏到 canvas
 * detachCustomTarget()
 * ```
 *
 * ```ts
 * // 手动更新光标，只上屏到 el
 * disconnectCursor()
 * attachCustomTarget(el)
 * updateCursor(cursor)
 * // 退出手动更新模式，重新上屏到 canvas
 * detachCustomTarget()
 * connectCursor()
 * ```
 *
 * ```ts
 * // 销毁管理器
 * destroy()
 * ```
 */
export class CursorManager {
    private canvasTarget: HTMLElement | null = null
    private customTarget: HTMLElement | null = null
    private customTargetStandalone = DefaultCustomTargetStandalone
    private cursorData: Wukong.DocumentProto.IArg_ApplyCursor | null = null

    constructor(private readonly bridge: Bridge, private readonly commandInvoker: CommandInvoker) {
        WK.cursorData = () => ({ ...this.cursorData })
    }

    private renderCursorInternal = () => {
        const cursor = this.cursorData
        if (!cursor || !isValidCursor(cursor)) {
            return
        }
        const records: Wukong.DocumentProto.IRenderCursorResult[] = []
        if (this.customTarget) {
            const ret = renderCursor(cursor, this.customTarget, document)
            records.push({
                ...ret,
                renderTarget: Wukong.DocumentProto.RenderCursorTarget.RENDER_CURSOR_TARGET_CUSTOM,
            })
        }
        if (this.canvasTarget) {
            if (!this.customTarget || !this.customTargetStandalone) {
                const ret = renderCursor(cursor, this.canvasTarget, document)
                records.push({
                    ...ret,
                    renderTarget: Wukong.DocumentProto.RenderCursorTarget.RENDER_CURSOR_TARGET_CANVAS,
                })
            }
        }
        if (records.length) {
            try {
                this.commandInvoker.invokeBridge(CommitType.Noop, RenderCursorRecord, {
                    results: records,
                })
            } catch (e) {
                Sentry.captureException(e)
            }
        }
    }

    /**
     * 手动更新光标
     * NOTE: 调用此方法只是一次性更新光标，不会影响 wasm 更新光标的逻辑。如只想通过手动更新，请同时调用 disconnectCursor
     * @param cursor
     */
    updateCursor = (cursor: Cursor) => {
        this.cursorData = this.bridge.call(RenderCursorCommand, cursor)
        this.renderCursorInternal()
    }

    /**
     * 挂载光标更新事件到 wasm
     */
    connectCursor = () => {
        this.bridge.bind(ApplyCursorCommand, (cursor) => {
            if (cursor.hash === this.cursorData?.hash) {
                return
            }
            this.cursorData = cursor
            this.renderCursorInternal()
        })
    }

    /**
     * 卸载光标更新事件，不再响应 wasm
     * 但避免 wasm 报错，不能 unbind 而是 bind 到空函数
     */
    disconnectCursor = () => {
        this.bridge.bind(ApplyCursorCommand, () => {})
    }

    /**
     * 销毁管理器
     */
    destroy = () => {
        this.disconnectCursor()
        this.bridge.unbind(ApplyCursorCommand)
        this.customTargetStandalone = DefaultCustomTargetStandalone
        this.customTarget = null
        this.canvasTarget = null
        this.cursorData = null
        delete WK.cursorData
    }

    /**
     * 挂载 canvas 元素
     * 挂载时若已经存在光标则会直接上屏
     * 挂载相同的元素不会再次上屏
     * TODO(chenyn): 当前挂载的对象是个 div，应该改为 canvas
     * @param el
     * @returns
     */
    attachCanvasTarget = (el: HTMLElement) => {
        if (this.canvasTarget === el) {
            return
        }
        this.canvasTarget = el
        this.renderCursorInternal()
    }

    /**
     * 卸载 canvas 元素
     */
    detachCanvasTarget = () => {
        if (!this.canvasTarget) {
            return
        }
        this.canvasTarget = null
        this.renderCursorInternal()
    }

    /**
     * 挂载支持渲染光标的自定义元素
     * 挂载时若已经存在光标则会直接上屏
     * 以相同的模式挂载相同的元素不会再次上屏
     * @param el
     * @param standalone 是否独立渲染，true 时只渲染自定义元素，false 时会同时渲染 canvas 元素。默认为 true
     * @returns
     */
    attachCustomTarget = (el: HTMLElement, standalone = DefaultCustomTargetStandalone) => {
        if (this.customTarget === el && this.customTargetStandalone === standalone) {
            return
        }
        this.customTarget = el
        this.customTargetStandalone = standalone
        this.renderCursorInternal()
    }

    /**
     * 卸载自定义元素
     */
    detachCustomTarget = () => {
        if (!this.customTarget) {
            return
        }
        this.customTarget = null
        this.customTargetStandalone = DefaultCustomTargetStandalone
        this.renderCursorInternal()
    }
}
