import { useState } from 'react'
import constate from 'constate'

const useLayerPanelScrollTopInternal = () => {
    const [scrollTop, setScrollTop] = useState(0)

    return {
        scrollTop,
        setScrollTop,
    }
}

export const [LayerPanelScrollTopProvider, useLayerPanelScrollTop] = constate(
    useLayerPanelScrollTopInternal,
    (ctx) => ctx
)
