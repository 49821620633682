import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Auto: '自动',
    Resizing: '响应类型',
    AutoWidth: '自动宽度',
    AutoHeight: '自动高度',
    FixedSize: '固定尺寸',
    AlignTop: '顶对齐',
    AlignMiddle: '垂直居中对齐',
    AlignBottom: '底对齐',
    HorizontalAlignment: '水平对齐',
    AlignLeft: '左对齐',
    AlignCenter: '水平居中对齐',
    AlignRight: '右对齐',
    TextAlignJustified: '两端对齐',
    Decoration: '文字装饰',
    None: '无',
    Underline: '下划线',
    Strikethrough: '删除线',
    ListStyle: '列表样式',
    BulletedList: '无序列表',
    NumberedList: '有序列表',
    Case: '大小写',
    AsTyped: '默认',
    Uppercase: '全大写',
    Lowercase: '全小写',
    TitleCase: '首字母大写',
    TruncateText: '文本省略',
    MaxLines: '最大行数',
} as const

export const enTranslation = {
    Auto: 'Auto',
    Resizing: 'Resizing',
    AutoWidth: 'Auto width',
    AutoHeight: 'Auto height',
    FixedSize: 'Fixed size',
    AlignTop: 'Align top',
    AlignMiddle: 'Align middle',
    AlignBottom: 'Align bottom',
    HorizontalAlignment: 'Alignment',
    AlignLeft: 'Text align left',
    AlignCenter: 'Text align center',
    AlignRight: 'Text align right',
    TextAlignJustified: 'Text align justified',
    Decoration: 'Decoration',
    None: 'None',
    Underline: 'Underline',
    Strikethrough: 'Strikethrough',
    ListStyle: 'List style',
    BulletedList: 'Bulleted list',
    NumberedList: 'Numbered list',
    Case: 'Case',
    AsTyped: 'As typed',
    Uppercase: 'Uppercase',
    Lowercase: 'Lowercase',
    TitleCase: 'Title case',
    TruncateText: 'Truncate text',
    MaxLines: 'Max lines',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
