/* eslint-disable no-restricted-imports */
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'

import { UpdateSelectionConstraintsCommand, Wukong } from '@wukong/bridge-proto'
import ConstraintType = Wukong.DocumentProto.ConstraintType

export function useConstraints() {
    const viewState = useViewState('constraintsState')
    const panelStateUnderAILayout = useViewState('panelStateUnderAILayout')

    const command = useCommand()

    const onChangeHorizontal = (horizontal: ConstraintType) => {
        command.DEPRECATED_invokeBridge(UpdateSelectionConstraintsCommand, {
            horizontal,
        })
        command.commitUndo()
    }

    const onChangeVertical = (vertical: ConstraintType) => {
        command.DEPRECATED_invokeBridge(UpdateSelectionConstraintsCommand, {
            vertical,
        })
        command.commitUndo()
    }

    const show = viewState?.show

    const horizontalValue = viewState?.horizontal.value

    const horizontalMixed = viewState?.horizontal.mixed

    const verticalValue = viewState?.vertical.value

    const verticalMixed = viewState?.vertical.mixed

    const disabled = viewState?.disable || panelStateUnderAILayout?.disableConstraints

    return {
        show,
        horizontalValue,
        horizontalMixed,
        verticalValue,
        verticalMixed,
        disabled,
        onChangeHorizontal,
        onChangeVertical,
    }
}
