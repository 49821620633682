import classnames from 'classnames'
import { forwardRef } from 'react'
import {
    TriggerIcon as SelectTriggerIcon,
    TriggerIconProps as SelectTriggerIconProps,
    TriggerIconRef as SelectTriggerIconRef,
} from '../../selects/triggers/trigger-icon'
import classes from './trigger-icon.module.less'

export interface TriggerIconProps extends SelectTriggerIconProps {
    size?: 'small' | 'medium' | 'large'
}
export interface TriggerIconRef extends SelectTriggerIconRef {}

function _TriggerIcon(props: TriggerIconProps, ref?: React.Ref<TriggerIconRef>) {
    const { className, size = 'medium', ...otherProps } = props
    return (
        <SelectTriggerIcon ref={ref} className={classnames(className, classes[size])} {...otherProps}>
            {props.children}
        </SelectTriggerIcon>
    )
}

export const TriggerIcon = forwardRef(_TriggerIcon)
