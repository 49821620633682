import { useCallback, useEffect, useRef, useState } from 'react'
import { observe } from 'react-intersection-observer'
import { DraggablePopupRef, DraggablePopupV2 } from '../../../../../../ui-lib/src'
import customColorBG from '../../../assets/ai/custom-color-bg.png'
import customColor from '../../../assets/ai/custom-color.png'
import { PureBlock } from '../../atom/color-block/pure'
import { useRenderColorSpace } from '../../color-profile'
import { ColorPicker } from '../../design/blend/color-picker/color-picker'
import { hex2rgb, rgb2hex } from '../../design/blend/color-picker/utils/color-translate'
import { COLORS } from './constants'
import { translation } from './remix-design-system-color-pallette.translation'
import { SelectingButton } from './selecting-button'
import { useAIGenUIInContext } from './use-ai-gen-ui'

export function ColorPallette() {
    const {
        tempRemixConfig,
        updateRemixConfig,
        tempRemixDesignSystem,
        isSelectedCustomColor,
        setIsSelectedCustomColor,
        closePrimaryColorPicker,
    } = useAIGenUIInContext()
    const selectedPrimaryColor = tempRemixConfig?.get('primaryColor') ?? null
    const colorSpace = useRenderColorSpace()

    if (!tempRemixDesignSystem) return null

    const colors = COLORS.get(tempRemixDesignSystem)

    if (!colors) return null

    const renderColorBlock = (color: string) => {
        const onClick = () => {
            updateRemixConfig('primaryColor', color)
            setIsSelectedCustomColor(false)
            closePrimaryColorPicker()
        }
        return (
            <SelectingButton
                key={color}
                onClick={onClick}
                extraClass="p-2"
                isSelected={color === selectedPrimaryColor?.toUpperCase() && !isSelectedCustomColor}
            >
                <PureBlock
                    onClick={onClick}
                    dataTestId={`use-${color}`}
                    rgb={hex2rgb(color)}
                    size={16}
                    style={{ width: 16, height: 16 }}
                    className="w-4 h-4 p-0 border-0 rounded-0.5"
                    colorSpace={colorSpace}
                />
            </SelectingButton>
        )
    }

    return (
        <>
            <div className="flex flex-wrap w-full gap-2">
                {colors.map(renderColorBlock)}
                {tempRemixDesignSystem !== 'shadcn UI' && tempRemixDesignSystem !== 'Material Design 3' && (
                    <CustomColorPallette />
                )}
            </div>
        </>
    )
}

export function RemixDesignSystemPrimaryColor() {
    const { tempRemixConfig, tempRemixDesignSystem } = useAIGenUIInContext()

    if (tempRemixConfig === null || tempRemixDesignSystem === null) {
        return null
    }

    return (
        <div className={`flex flex-col ${tempRemixDesignSystem === 'Ant Design' ? 'gap-1' : 'gap-2 mb-0.5'}`}>
            {tempRemixDesignSystem === 'Ant Design' ? (
                <div className="flex flex-col gap-1">
                    <span className="wk-text-12 wk-font-semibold">{translation('color')}</span>
                    <span className="wk-text-12 wk-font-regular text-[var(--wk-v2-label-color-gray-8)]">
                        {translation('primaryColor')}
                    </span>
                </div>
            ) : (
                <span className="wk-text-12 wk-font-semibold">{translation('primaryColor')}</span>
            )}
            <ColorPallette />
        </div>
    )
}

export function CustomColorPallette() {
    const {
        updateRemixConfig,
        tempRemixConfig,
        isSelectedCustomColor,
        setIsSelectedCustomColor,
        customColorInfo,
        setCustomColorInfo,
        openedColorPicker,
        openPrimaryColorPicker,
        closePrimaryColorPicker,
        setCustomizedDerivedColor,
        tempRemixDesignSystem,
    } = useAIGenUIInContext()
    const ref = useRef<HTMLDivElement>(null)
    const [opacity, setOpacity] = useState(1)
    const colorPickerRef = useRef<DraggablePopupRef>(null)
    const colorSpace = useRenderColorSpace()

    const [colorPanelPos, setColorPanelPos] = useState({ top: 0, left: 0 })

    useEffect(() => {
        if (!ref.current) return

        return observe(ref.current, (entry) => {
            if (entry && ref.current) {
                const client = ref.current.getBoundingClientRect()
                if (client) {
                    setColorPanelPos({ top: client.bottom + 8, left: client.left })
                }
            }
        })
    }, [])

    useEffect(() => {
        const handleEscape = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                closePrimaryColorPicker()
            }
        }

        document.addEventListener('keydown', handleEscape)
        return () => document.removeEventListener('keydown', handleEscape)
    }, [closePrimaryColorPicker])

    const handleColorChange = useCallback(
        (rgb: { r: number; g: number; b: number }) => {
            const hex = rgb2hex(rgb.r, rgb.g, rgb.b)
            setCustomColorInfo(`#${hex}`)
            updateRemixConfig('primaryColor', `#${hex}`)

            if (opacity !== 1 && tempRemixDesignSystem === 'Ant Design') {
                const alphaHex = Math.round(opacity * 255)
                    .toString(16)
                    .padStart(2, '0')
                setCustomizedDerivedColor((prev) => {
                    const newMap = new Map(prev)
                    newMap.set('colorLink', `#${hex}${alphaHex}`)
                    return newMap
                })
            }
        },
        [opacity, setCustomizedDerivedColor, setCustomColorInfo, tempRemixDesignSystem, updateRemixConfig]
    )

    const primaryColor = tempRemixConfig?.get('primaryColor') ?? null

    const handleOpacityChange = useCallback(
        (newOpacity: number) => {
            setOpacity(newOpacity)
            if (!primaryColor) return

            const rgb = hex2rgb(primaryColor)
            const hex = rgb2hex(rgb.r, rgb.g, rgb.b)
            setCustomColorInfo(`#${hex}`)
            updateRemixConfig('primaryColor', `#${hex}`)

            if (tempRemixDesignSystem === 'Ant Design') {
                setCustomizedDerivedColor((prev) => {
                    const newMap = new Map(prev)

                    if (newOpacity === 1) {
                        newMap.delete('colorLink')
                    } else {
                        const alphaHex = Math.round(newOpacity * 255)
                            .toString(16)
                            .padStart(2, '0')
                        newMap.set('colorLink', `#${hex}${alphaHex}`)
                    }
                    return newMap
                })
            }
        },
        [primaryColor, setCustomColorInfo, updateRemixConfig, tempRemixDesignSystem, setCustomizedDerivedColor]
    )

    const onClickColorBlock = useCallback(() => {
        setIsSelectedCustomColor(true)
        if (customColorInfo) {
            updateRemixConfig('primaryColor', customColorInfo.toUpperCase())
        }
        openPrimaryColorPicker()
    }, [customColorInfo, openPrimaryColorPicker, setIsSelectedCustomColor, updateRemixConfig])

    return (
        <div ref={ref}>
            <div
                data-testid="custom-color"
                style={{
                    backgroundImage: isSelectedCustomColor ? `url(${customColorBG})` : '',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
                onClick={onClickColorBlock}
                className={`w-8 bg-[var(--wk-gray-1)] h-8 ${
                    isSelectedCustomColor ? 'w-7 h-7' : 'w-8 h-8 hover:bg-[var(--wk-gray-2)]'
                } wk-bg-white flex justify-center items-center rounded-0.75`}
            >
                {isSelectedCustomColor ? (
                    <PureBlock
                        data-testid={`custom-color-swatch-${primaryColor}`}
                        rgb={hex2rgb(customColorInfo ?? primaryColor ?? '#000')}
                        colorSpace={colorSpace}
                        size={16}
                        opacity={opacity}
                        className={`w-full h-full p-0 border-0 rounded-0.5 flex justify-center items-center cursor-default ${
                            isSelectedCustomColor ? 'bg-[var(--wk-brand-1)] wk-font-semibold' : ''
                        }`}
                        onClick={onClickColorBlock}
                    />
                ) : (
                    <img src={customColor} alt="custom color" className="w-4 h-4 rounded-0.25" />
                )}
            </div>

            <DraggablePopupV2
                ref={colorPickerRef}
                title="Color"
                bodyClassName="p-0"
                data-testid="remix-custom-color-picker"
                width={240}
                visible={openedColorPicker === 'primary'}
                position={colorPanelPos}
                onCancel={() => closePrimaryColorPicker()}
                header={
                    <div className="p-0 m-0 h-10 flex flex-row justify-start items-center">
                        <span className="wk-text-12 wk-font-medium px-4">{translation('color')}</span>
                    </div>
                }
                closeClassName="right-2.5"
                className="z-2000"
                closable={true}
                draggable={false}
                footer={<></>}
                mask
            >
                <ColorPicker
                    color={hex2rgb(customColorInfo ?? primaryColor ?? '#000')}
                    colorSpace={colorSpace}
                    opacity={opacity}
                    onChangeColor={handleColorChange}
                    onChangeOpacity={handleOpacityChange}
                />
            </DraggablePopupV2>
        </div>
    )
}
