/* eslint-disable no-restricted-imports */
import {
    ChangeCurrentPageBackgroundCommandWasmCall,
    ClearSelectedReversedIndexesCommandForJs,
    SetSelectedReveredIndexesWasmCall,
    TextKeyboardOperationCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { BridgeCommand } from './command-invoker'
import IKeyboardEvent = Wukong.DocumentProto.IKeyboardEvent

export const cmdTextKeyboardOperation: BridgeCommand<[IKeyboardEvent]> = ({ bridge }, event) => {
    bridge.call(TextKeyboardOperationCommand, event)
}

export const cmdUpdateSelectedReverseIndex: BridgeCommand<
    [type: 'fills' | 'strokes' | 'effects' | 'layoutgrids' | 'clear', reverseIndex: number[]]
> = ({ bridge }, type, reverseIndex) => {
    switch (type) {
        case 'clear': {
            bridge.call(ClearSelectedReversedIndexesCommandForJs)
            break
        }
        case 'effects': {
            bridge.call(SetSelectedReveredIndexesWasmCall, {
                type: Wukong.DocumentProto.SelectedReversedIndexesType.SELECTED_REVERSED_INDEXES_TYPE_EFFECT,
                indexes: reverseIndex,
            })
            break
        }
        case 'fills': {
            bridge.call(SetSelectedReveredIndexesWasmCall, {
                type: Wukong.DocumentProto.SelectedReversedIndexesType.SELECTED_REVERSED_INDEXES_TYPE_FILL,
                indexes: reverseIndex,
            })
            break
        }
        case 'strokes': {
            bridge.call(SetSelectedReveredIndexesWasmCall, {
                type: Wukong.DocumentProto.SelectedReversedIndexesType.SELECTED_REVERSED_INDEXES_TYPE_STROKE,
                indexes: reverseIndex,
            })
            break
        }
        case 'layoutgrids': {
            bridge.call(SetSelectedReveredIndexesWasmCall, {
                type: Wukong.DocumentProto.SelectedReversedIndexesType.SELECTED_REVERSED_INDEXES_TYPE_LAYOUT_GRID,
                indexes: reverseIndex,
            })
            break
        }
        default: {
            return
        }
    }
}

export const cmdBackground: BridgeCommand<[Wukong.DocumentProto.IPaint]> = ({ bridge }, paint) => {
    bridge.call(ChangeCurrentPageBackgroundCommandWasmCall, { paint: paint })
}
