import { MaskPanel } from './mask-panel'
import { useMaskCommand } from './use-mask-command'
import { useMaskState } from './use-mask-state'

export function Mask() {
    const state = useMaskState()
    const command = useMaskCommand()

    return state.show ? <MaskPanel checkedState={state.checked} onChange={command.toggleSelectionMaskOutline} /> : null
}
