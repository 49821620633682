import {
    UpdateTextStyleNodeDescriptionCommand,
    UpdateTextStyleNodeFontFamilyCommand,
    UpdateTextStyleNodeFontNameCommand,
    UpdateTextStyleNodeFontSizeCommand,
    UpdateTextStyleNodeLetterSpacingCommand,
    UpdateTextStyleNodeLineHeightCommand,
    UpdateTextStyleNodeNameCommand,
    UpdateTextStyleNodeParagraphSpacingCommand,
    UpdateTextStyleNodeTextDecorationCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { useCallback } from 'react'
import { FontName, TextDecoration } from '../../../../../../document/node/node'
import { useCommand } from '../../../../../../main/app-context'
import { InputOptionsForUndoSquash } from '../../../../atom/inputs/components/formatted-input'
import { TextValueUnit, Value } from '../../../../atom/inputs/utils/type'
import { useEditingTextStyle } from '../../hooks/use-editing-style'

export function useTextStyleEditor(props: { styleId: string }) {
    const { styleId } = props
    const command = useCommand()
    const editingTextStyle = useEditingTextStyle()
    const textStyleNode = editingTextStyle?.textStyleNode
    const variantState = editingTextStyle?.variantState

    const onChangeTextDecoration = (value: TextDecoration) => {
        command.DEPRECATED_invokeBridge(
            UpdateTextStyleNodeTextDecorationCommand,
            Wukong.DocumentProto.UpdateTextStyleNodeTextDecorationParam.create({
                styleId,
                textDecoration: value as unknown as Wukong.DocumentProto.TextDecoration,
            })
        )
        command.commitUndo()
    }

    const onChangeFontSize = (fontSize: number | string, options?: InputOptionsForUndoSquash) => {
        command.DEPRECATED_invokeBridge(
            UpdateTextStyleNodeFontSizeCommand,
            Wukong.DocumentProto.UpdateTextStyleNodeFontSizeParam.create({
                styleId,
                fontSize: Number(fontSize),
            })
        )
        command.commitUndo(options?.commitType)
    }

    const onChangeFontName = (fontName: FontName, customAxesStyle: string, isMissFontCustomStyle: boolean) => {
        command.DEPRECATED_invokeBridge(
            UpdateTextStyleNodeFontNameCommand,
            Wukong.DocumentProto.UpdateTextStyleNodeFontNameParam.create({
                styleId,
                fontName,
                customAxesStyle: { name: customAxesStyle },
                isMissFontCustomStyle: isMissFontCustomStyle,
            })
        )
        command.commitUndo()
    }

    const onChangeFontStyle = (v: FontName, customAxesStyle: string, isMissFontCustomStyle: boolean) => {
        onChangeFontName(v, customAxesStyle, isMissFontCustomStyle)
    }

    const onChangeFontFamily = (v: FontName) => {
        command.DEPRECATED_invokeBridge(
            UpdateTextStyleNodeFontFamilyCommand,
            Wukong.DocumentProto.UpdateTextStyleNodeFontNameParam.create({
                styleId,
                fontName: v,
            })
        )
        command.commitUndo()
    }

    const onChangeName = useCallback(
        (value: string) => {
            command.DEPRECATED_invokeBridge(
                UpdateTextStyleNodeNameCommand,
                Wukong.DocumentProto.UpdateTextStyleNodeNameParam.create({
                    styleId,
                    name: value,
                })
            )
            command.commitUndo()
        },
        [command, styleId]
    )

    const onChangeDescription = useCallback(
        (value: string) => {
            command.DEPRECATED_invokeBridge(
                UpdateTextStyleNodeDescriptionCommand,
                Wukong.DocumentProto.UpdateTextStyleNodeDescriptionParam.create({
                    styleId,
                    description: value,
                })
            )
            command.commitUndo()
        },
        [command, styleId]
    )

    const onChangeLetterSpacing = useCallback(
        (value: TextValueUnit, options?: InputOptionsForUndoSquash) => {
            command.DEPRECATED_invokeBridge(
                UpdateTextStyleNodeLetterSpacingCommand,
                Wukong.DocumentProto.UpdateTextStyleNodeLetterSpacingParam.create({
                    styleId,
                    letterSpacing: value as unknown as Wukong.DocumentProto.ILetterSpacing,
                })
            )
            command.commitUndo(options?.commitType)
        },
        [command, styleId]
    )

    const onChangeLineHeight = useCallback(
        (value: TextValueUnit, options?: InputOptionsForUndoSquash) => {
            command.DEPRECATED_invokeBridge(
                UpdateTextStyleNodeLineHeightCommand,
                Wukong.DocumentProto.UpdateTextStyleNodeLineHeightParam.create({
                    styleId,
                    lineHeight: value as unknown as Wukong.DocumentProto.ILineHeight,
                })
            )
            command.commitUndo(options?.commitType)
        },
        [command, styleId]
    )

    const onChangeParagraphSpacing = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value === 'number') {
                command.DEPRECATED_invokeBridge(
                    UpdateTextStyleNodeParagraphSpacingCommand,
                    Wukong.DocumentProto.UpdateTextStyleNodeParagraphSpacingParam.create({
                        styleId,
                        paragraphSpacing: value,
                    })
                )
                command.commitUndo(options?.commitType)
            }
        },
        [command, styleId]
    )

    return {
        textStyleNode,
        variantState,
        onChangeName,
        onChangeDescription,
        onChangeFontFamily,
        onChangeFontStyle,
        onChangeFontSize,
        onChangeTextDecoration,
        onChangeLetterSpacing,
        onChangeLineHeight,
        onChangeParagraphSpacing,
    }
}
