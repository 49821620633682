import { UserGroupType } from './feature-switch-config'
import { BasicRdAll } from './user-group/basic-rd-all'
import { Pm } from './user-group/pm'
import { Rd } from './user-group/rd'

import sha1 from 'js-sha1'
import { AiLab } from './user-group/ai-lab'
import { Cypress } from './user-group/cypress'
import { Fenbi } from './user-group/fenbi'
import { InlandRestOrgUser } from './user-group/inland-rest-org-user'
import { Mo } from './user-group/mo'
import { MonitorAll } from './user-group/monitor-all'
import { MonitorPm } from './user-group/monitor-pm'
import { MonitorRd } from './user-group/monitor-rd'
import { MonitorUx } from './user-group/monitor-ux'
import { MotiffGroup } from './user-group/motiff-group'
import { Oda } from './user-group/oda'
import { Qa } from './user-group/qa'
import { Ux } from './user-group/ux'

export function checkUserInGroup(userId: string, userGroup: UserGroupType): boolean {
    const userGroupMapper = new Map([
        [UserGroupType.RD, Rd],
        [UserGroupType.PM, Pm],
        [UserGroupType.QA, Qa],
        [UserGroupType.UX, Ux],
        [UserGroupType.MONITOR_ALL, MonitorAll],
        [UserGroupType.MONITOR_RD, MonitorRd],
        [UserGroupType.MONITOR_PM, MonitorPm],
        [UserGroupType.MONITOR_UX, MonitorUx],
        [UserGroupType.MOTIFF_GROUP, MotiffGroup],
        [UserGroupType.BASIC_RD_ALL, BasicRdAll],
        [UserGroupType.CYPRESS, Cypress],
        [UserGroupType.MO, Mo],
        [UserGroupType.ODA, Oda],
        [UserGroupType.AI_LAB, AiLab],
        [UserGroupType.FENBI, Fenbi],
        [UserGroupType.INLAND_REST_ORG_USER, InlandRestOrgUser],
    ])
    return userGroupMapper.get(userGroup)?.includes(sha1(userId)) ?? false
}
