/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { usePluginService } from '../../../main/app-context'
import { useUserConfigStateV2 } from '../../../main/user-config/user-config-hook'
import { useViewState } from '../../../view-state-bridge'

export function useLastUsedPlugin() {
    const pluginState = useViewState('pluginState')
    const pluginService = usePluginService()
    const [lastUsedPluginId, setLastUsedPluginId] = useUserConfigStateV2('lastUsedPluginId')

    return {
        disabledLastUsedPlugin: !lastUsedPluginId || !pluginState?.enableLastPlugin,
        disabledContextMenu: !pluginState?.enableContextMenuPlugin,
        onClick: () => {
            pluginService.showLastUsedPlugin()
        },
        onUserClickPlugin: (id: Wukong.DocumentProto.PluginType) => {
            const value = Wukong.DocumentProto.PluginType[id]
            setLastUsedPluginId(value)
        },
    }
}

export function showLastUsedPlugin() {}
