import type { FC } from 'react'
import styles from './index.module.less'
import classNames from 'classnames'

interface SkeletonProps {
    type: 'round' | 'rectangle'
    width?: string
    height?: string
    className?: string
}

export const Skeleton: FC<SkeletonProps> = ({ type, width, height, className }) => {
    const computedWidth = width ? width : type === 'round' ? 16 : 104
    const computedHeight = height ? height : 16

    return (
        <div
            className={classNames(styles.skeleton, styles[`skeleton-${type}`], className)}
            style={{ width: `${computedWidth}px`, height: `${computedHeight}px` }}
        />
    )
}
