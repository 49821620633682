/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { isEqual } from 'lodash-es'
import React, { useCallback, useContext, useMemo } from 'react'
import { CustomItem, CustomItemProps } from '../../../custom-item/custom-item'
import { SingleLevelContext } from '../../../selects/picks/single-level/type'
import { getOptionMinWidth } from '../option-width'
import classes from './select-option.module.less'

export interface SelectOptionProps extends Omit<CustomItemProps, 'isInteractive'> {
    value: unknown
    size?: 'medium' | 'large'
    dataTestId?: string
}

export function SelectOption(props: SelectOptionProps) {
    const {
        className,
        value,
        size = 'medium',
        forwardIconClassName,
        onClick,
        onMouseMove,
        onMouseLeave,
        addRightPadding = true,
        ...otherProps
    } = props
    const { preselectValue, preselectClassName, onChange, setPreselectValueByMouse } = useContext(SingleLevelContext)

    const isPreselect = useMemo(() => {
        if (props.disabled || preselectValue === undefined) {
            return false
        }
        return isEqual(preselectValue, value)
    }, [props.disabled, preselectValue, value])

    const _onClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            onChange(value, e)
            onClick?.(e)
        },
        [onChange, onClick, value]
    )

    const _onMouseMove = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (!isEqual(preselectValue, value)) {
                setPreselectValueByMouse(value)
            }
            onMouseMove?.(e)
        },
        [onMouseMove, preselectValue, setPreselectValueByMouse, value]
    )

    const _onMouseLeave = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            setPreselectValueByMouse(undefined)
            onMouseLeave?.(e)
        },
        [onMouseLeave, setPreselectValueByMouse]
    )

    return (
        <CustomItem
            {...otherProps}
            style={{
                minWidth: getOptionMinWidth(!!otherProps.backwardIcon),
            }}
            forwardIconClassName={classnames(forwardIconClassName)}
            backwardIconStyle={{ paddingLeft: 16 }}
            className={classnames([className], {
                [preselectClassName]: isPreselect,
                [classes[size]]: size,
            })}
            isInteractive={isPreselect}
            onClick={_onClick}
            onMouseMove={_onMouseMove}
            onMouseLeave={_onMouseLeave}
            addRightPadding={addRightPadding}
        >
            {props.children}
        </CustomItem>
    )
}
