import { WKItemType, WKMenuConfig, WKMenuItem, WKSubMenuItem } from '../../../../../../../ui-lib/src'
import { isUndefined } from 'lodash-es'
import { MainMenuItem, MainMenuItemCustomNode } from './type'

export function transformMenuConfig(
    config: WKMenuConfig,
    otherConfig?: {
        forwardIconClassName?: string
        forwardIconToForwardChildren?: boolean
    }
): MainMenuItem[] {
    const mainMenuItem: MainMenuItem[] = []
    for (const configItem of config) {
        if ('children' in configItem) {
            const children = transformMenuConfig(configItem.children, otherConfig)
            mainMenuItem.push({
                name: configItem.name,
                hidden: configItem.hidden,
                children,
                dataTestId: configItem.testId,
                customNode: {
                    customItemProps: {
                        ...getForwardProps(configItem, otherConfig),
                        showCheckBoxAndForwardIcon: configItem?.showCheckBoxAndForwardIcon,
                        isLocked: configItem.isLocked,
                        isSelect: configItem.activity,
                        dataTestIds: {
                            isSelectOrCheckbox: configItem.name,
                        },
                    },
                    autoCommit: configItem.autoCommit,
                    linkInfo: configItem.linkInfo,
                },
            })
            continue
        }
        if (!('type' in configItem)) {
            mainMenuItem.push({
                name: configItem.name,
                hidden: configItem.hidden,
                dataTestId: configItem.testId,
                disabled: configItem.disable,
                customNode: {
                    customItemProps: {
                        ...getForwardProps(configItem, otherConfig),
                        showCheckBoxAndForwardIcon: configItem?.showCheckBoxAndForwardIcon,
                        isLocked: configItem.isLocked,
                        isSelect: configItem.activity,
                        dataTestIds: {
                            isSelectOrCheckbox: configItem.name,
                        },
                        onMouseEnter: configItem.mouseEnterHandler,
                        onMouseLeave: configItem.mouseLevelHandler,
                    },
                    shortcut: configItem.shortCut,
                    autoCommit: configItem.autoCommit,
                    linkInfo: configItem.linkInfo,
                    handler: configItem.handler as MainMenuItemCustomNode['handler'],
                },
            })
            continue
        }

        if (configItem.type === WKItemType.Divider) {
            mainMenuItem.push({
                name: '',
                hidden: configItem.hidden,
                dataTestId: configItem.testId,
                disabled: true,
                customNode: {
                    autoSplitLine: true,
                },
            })
            continue
        }

        if (configItem.type === WKItemType.GroupHeader) {
            mainMenuItem.push({
                name: configItem.name,
                hidden: configItem.hidden,
                dataTestId: configItem.testId,
                disabled: true,
            })
            continue
        }
    }
    return mainMenuItem
}

function getForwardIcon(item: WKMenuItem | WKSubMenuItem) {
    const forwardIcon =
        isUndefined(item.activity) || item.showCheckBoxAndForwardIcon
            ? typeof item.forwardIcon === 'number' && isNaN(item.forwardIcon)
                ? undefined
                : item.forwardIcon || ''
            : undefined

    return typeof forwardIcon === 'boolean' || !forwardIcon ? undefined : forwardIcon
}

function getForwardProps(item: WKMenuItem | WKSubMenuItem, otherConfig: Parameters<typeof transformMenuConfig>[1]) {
    return otherConfig?.forwardIconToForwardChildren
        ? {
              forwardChildren: item.forwardIcon,
              forwardChildrenClassName: otherConfig?.forwardIconClassName,
          }
        : {
              forwardIcon: getForwardIcon(item),
              forwardIconClassName: otherConfig?.forwardIconClassName,
          }
}
