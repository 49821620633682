import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useCallback } from 'react'
import { usePrevious } from '../../../../../../../util/src'
import { LibraryId } from '../../../../../kernel/interface/component-style-library-id'
import { DocID, ResourceType } from '../../../../../kernel/interface/type'
import { useComponentService, useLibraryComponentService } from '../../../../../main/app-context'
import { SearchRemoteLibraryProvider } from '../../../../../share/component-style-library/hook/use-library-service-remote-search'
import { LibraryComponentViewEditorConfigProvider } from '../../../../../share/component-style-library/library-component-viewer/context'
import { LibraryControlListItemDetailContainer } from '../../../../../share/component-style-library/library-control-list-item-detail/library-control-list-item-detail'
import { useLibraryModalConfigContext } from '../../../../context/library-component-style/library-modal-config'
import { LibraryRemoteChangeList } from '../../library-remote-change-list/library-remote-change-list'
import { LibraryRemoteMissingList } from '../../library-remote-missing-list/library-remote-missing-list'
import { LibraryRemoteTotalList } from '../../library-remote-total-list/library-remote-total-list'
import { LibraryRemoteUsedAndMissingListProvider } from '../../library-remote-used-list/use-library-remote-used-hook'
import { LibraryReplaceDetail } from '../../library-replace-detail/library-replace-detail'
import styles from './library-publish-modal-remote.module.less'

// 引用组件库 tab 内容
export function LibraryListModalRemoteContainer() {
    const { docId } = useLibraryComponentService()
    const libraryComponentService = useLibraryComponentService()
    const componentService = useComponentService()
    const subscribeLibraryIds = libraryComponentService.states.use.subscribeLibraryIds()

    const openDetail = useCallback(
        (params: { libraryId: LibraryId; docId: DocID }) => {
            libraryComponentService.libraryModalRouterService.goToRemoteLibraryDetail(params.libraryId, params.docId)
        },
        [libraryComponentService]
    )

    const jump2DetailDoc = useCallback(
        (detailDocId: DocID) => {
            componentService.jumpToOriginDocument({
                docId: detailDocId,
                nodeId: undefined,
            })
        },
        [componentService]
    )

    return (
        <SearchRemoteLibraryProvider
            resourceId={docId}
            resourceType={ResourceType.Document}
            readOnly={false}
            goBackHome={libraryComponentService.libraryModalRouterService.goToRemoteLibraryHome}
            openDetail={openDetail}
            jump2DetailDoc={jump2DetailDoc}
            subscribeLibrary={libraryComponentService.subscribeLibrary}
            unSubscribeLibrary={libraryComponentService.unSubscribeLibrary}
            subscribeLibraryIds={subscribeLibraryIds}
        >
            <LibraryListModalRemote />
        </SearchRemoteLibraryProvider>
    )
}

const RoutePathType2OrderIndex: Record<Wukong.DocumentProto.LibraryMainModalRoutePathType, number> = {
    [Wukong.DocumentProto.LibraryMainModalRoutePathType.LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_NONE]: -1,
    [Wukong.DocumentProto.LibraryMainModalRoutePathType.LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_CURRENT_LOCAL]: 0,
    [Wukong.DocumentProto.LibraryMainModalRoutePathType
        .LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REMOTE_LIBRARY_TOTAL_LIST]: 0,
    [Wukong.DocumentProto.LibraryMainModalRoutePathType
        .LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REMOTE_MISS_LIBRARY_DETAIL]: 1,
    [Wukong.DocumentProto.LibraryMainModalRoutePathType.LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REMOTE_LIBRARY_DETAIL]: 1,
    [Wukong.DocumentProto.LibraryMainModalRoutePathType
        .LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REMOTE_LIBRARY_CHANGE_LIST]: 1,
    [Wukong.DocumentProto.LibraryMainModalRoutePathType.LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REPLACE_LIBRARY_DETAIL]: 2,
}

function LibraryListModalRemote() {
    const {
        mainModalConfig,
        mainModalConfig: {
            routePath = Wukong.DocumentProto.LibraryMainModalRoutePathType
                .LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REMOTE_LIBRARY_TOTAL_LIST,
            activeTab,
            extra,
        },
    } = useLibraryModalConfigContext()
    const prevModalConfig = usePrevious(mainModalConfig)

    const getClassNameByRoutePath = useCallback(
        (currentRoutePath: Wukong.DocumentProto.LibraryMainModalRoutePathType) =>
            routePath === currentRoutePath
                ? prevModalConfig?.routePath &&
                  prevModalConfig.routePath !== routePath &&
                  prevModalConfig?.activeTab === activeTab
                    ? classNames(
                          styles.show,
                          RoutePathType2OrderIndex[prevModalConfig.routePath] < RoutePathType2OrderIndex[routePath]
                              ? styles.go
                              : styles.back
                      )
                    : ''
                : styles.hidden,
        [routePath, activeTab, prevModalConfig]
    )

    return (
        <>
            <LibraryRemoteUsedAndMissingListProvider>
                <LibraryRemoteTotalList
                    className={classNames(
                        getClassNameByRoutePath(
                            Wukong.DocumentProto.LibraryMainModalRoutePathType
                                .LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REMOTE_LIBRARY_TOTAL_LIST
                        )
                    )}
                />
                <LibraryComponentViewEditorConfigProvider draggable>
                    <LibraryControlListItemDetailContainer
                        className={classNames(
                            getClassNameByRoutePath(
                                Wukong.DocumentProto.LibraryMainModalRoutePathType
                                    .LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REMOTE_LIBRARY_DETAIL
                            )
                        )}
                        libraryId={extra?.libraryId}
                    />
                </LibraryComponentViewEditorConfigProvider>
                <LibraryRemoteMissingList
                    className={classNames(
                        getClassNameByRoutePath(
                            Wukong.DocumentProto.LibraryMainModalRoutePathType
                                .LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REMOTE_MISS_LIBRARY_DETAIL
                        )
                    )}
                />
                <LibraryComponentViewEditorConfigProvider draggable={false}>
                    <LibraryReplaceDetail
                        className={classNames(
                            getClassNameByRoutePath(
                                Wukong.DocumentProto.LibraryMainModalRoutePathType
                                    .LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REPLACE_LIBRARY_DETAIL
                            )
                        )}
                        libraryId={extra?.libraryId}
                        documentId={extra?.documentId}
                        docName={extra?.docName}
                    />
                </LibraryComponentViewEditorConfigProvider>
            </LibraryRemoteUsedAndMissingListProvider>
            <LibraryRemoteChangeList
                className={classNames(
                    getClassNameByRoutePath(
                        Wukong.DocumentProto.LibraryMainModalRoutePathType
                            .LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REMOTE_LIBRARY_CHANGE_LIST
                    )
                )}
            />
        </>
    )
}
