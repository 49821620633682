import { MotiffApi } from '@motiffcom/plugin-api-types'
import { Wukong } from '@wukong/bridge-proto'

export function convertVComponentPropType2ComponentPropertyType(
    type: Wukong.DocumentProto.VComponentPropType
): MotiffApi.ComponentPropertyType {
    switch (type) {
        case Wukong.DocumentProto.VComponentPropType.V_COMPONENT_PROP_TYPE_BOOL:
            return 'BOOLEAN'
        case Wukong.DocumentProto.VComponentPropType.V_COMPONENT_PROP_TYPE_TEXT:
            return 'TEXT'
        case Wukong.DocumentProto.VComponentPropType.V_COMPONENT_PROP_TYPE_INSTANCE_SWAP:
            return 'INSTANCE_SWAP'
        case Wukong.DocumentProto.VComponentPropType.V_COMPONENT_PROP_TYPE_VARIANT:
            return 'VARIANT'
    }
}
