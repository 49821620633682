import {
    AddAutoLayoutCommand,
    BatchAttachAutoLayoutVarCommand,
    BatchDetachAutoLayoutVarCommand,
    ClearAutoLayoutFloatInputForJs,
    NextAutoLayoutFloatInputCommand,
    RemoveAutoLayoutWasmCall,
    SetAutoLayoutAdvancePanelCommand,
    UpdateAutoLayoutHoverItemWasmCall,
    UpdateBatchAutoLayoutChildCommand,
    UpdateBatchAutoLayoutCommand,
    UpdateSelectedAutoLayoutWHValueWasmCall,
    Wukong,
} from '@wukong/bridge-proto'
import { CommitType } from '../../../../../../document/command/commit-type'
import { useCommand } from '../../../../../context/document-context'
import { InputOptionsForUndoSquash } from '../../../../atom/inputs/components/formatted-input'
import {
    AlignParam,
    AutoLayoutHoverItem,
    AutoLayoutState,
    AutoLayoutUpdatedParam,
    AutoLayoutVisualDirection,
    AutoLayoutWHValue,
    BatchAutoLayoutChildUpdatedParam,
    BatchAutoLayoutUpdatedParam,
    MapPaddingKey,
    PaddingPair,
    StackAlign,
    StackJustify,
    StackMode,
    StackPositioning,
    UpdateAutoLayoutHoverItemParam,
    ValidAdvanceItem,
} from './types'

export function useAutoLayoutCommand(state: AutoLayoutState) {
    const command = useCommand()

    return {
        addAutoLayout: () => {
            command.DEPRECATED_invokeBridge(AddAutoLayoutCommand, {
                origin: Wukong.DocumentProto.AddAutoLayoutOrigin.ADD_AUTO_LAYOUT_ORIGIN_PANEL,
            })
            command.commitUndo()
        },
        removeAutoLayout: () => {
            command.DEPRECATED_invokeBridge(RemoveAutoLayoutWasmCall)
            command.commitUndo()
        },
        updateAutoLayoutBatch: (
            param: BatchAutoLayoutUpdatedParam,
            options?: InputOptionsForUndoSquash,
            skipCommitUndo?: boolean
        ) => {
            command.DEPRECATED_invokeBridge(UpdateBatchAutoLayoutCommand, param)
            !skipCommitUndo && command.commitUndo(options?.commitType)
        },
        updateChildAutoLayoutBatch: (param: BatchAutoLayoutChildUpdatedParam) => {
            command.DEPRECATED_invokeBridge(UpdateBatchAutoLayoutChildCommand, param)
            command.commitUndo()
        },

        updateSelectedAutoLayout(
            param: Omit<AutoLayoutUpdatedParam, 'nodeId'>,
            options?: InputOptionsForUndoSquash,
            skipCommitUndo?: boolean
        ) {
            this.updateAutoLayoutBatch(
                {
                    params: state.autoLayoutParentIds.map((id) => ({
                        ...param,
                        nodeId: id,
                    })),
                },
                options,
                skipCommitUndo
            )
        },

        setStackMode(stackMode: Wukong.DocumentProto.ComputedStackModeType) {
            switch (stackMode) {
                case Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_NONE:
                    return this.updateSelectedAutoLayout({
                        stackMode: Wukong.DocumentProto.StackMode.STACK_MODE_NONE,
                        stackWrap: Wukong.DocumentProto.StackWrap.STACK_WRAP_NO_WRAP,
                    })
                case Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_HORIZONTAL:
                    return this.updateSelectedAutoLayout({
                        stackMode: Wukong.DocumentProto.StackMode.STACK_MODE_HORIZONTAL,
                        stackWrap: Wukong.DocumentProto.StackWrap.STACK_WRAP_NO_WRAP,
                    })
                case Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_VERTICAL:
                    return this.updateSelectedAutoLayout({
                        stackMode: Wukong.DocumentProto.StackMode.STACK_MODE_VERTICAL,
                        stackWrap: Wukong.DocumentProto.StackWrap.STACK_WRAP_NO_WRAP,
                    })
                case Wukong.DocumentProto.ComputedStackModeType.COMPUTED_STACK_MODE_TYPE_WRAP:
                    return this.updateSelectedAutoLayout({
                        stackMode: Wukong.DocumentProto.StackMode.STACK_MODE_HORIZONTAL,
                        stackWrap: Wukong.DocumentProto.StackWrap.STACK_WRAP_WRAP,
                    })
            }
        },

        setAlignMode(align: AlignParam, skipCommitUndo?: boolean) {
            this.updateSelectedAutoLayout({ ...align }, undefined, skipCommitUndo)
        },

        setStackSpace(
            value: number | number[] | 'auto',
            clearFloatInput = false,
            options?: InputOptionsForUndoSquash,
            skipCommitUndo?: boolean
        ) {
            if (value === 'auto') {
                this.updateSelectedAutoLayout(
                    {
                        stackPrimaryAlignItems: StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_SPACE_EVENTLY,
                    },
                    undefined,
                    skipCommitUndo
                )
            } else {
                const isSingleValue = !Array.isArray(value)
                this.updateAutoLayoutBatch(
                    {
                        params: state.autoLayoutParentIds.map((id, i) => ({
                            nodeId: id,
                            stackSpacing: isSingleValue ? value : value[i],
                        })),
                        clearFloatInput,
                    },
                    options,
                    skipCommitUndo
                )
            }
        },

        setStackSpacingVar(varId: string) {
            command.invokeBridge(
                CommitType.CommitUndo,
                BatchAttachAutoLayoutVarCommand,
                Wukong.DocumentProto.BatchAttachAutoLayoutVarParam.create({
                    params: state.autoLayoutParentIds.map((nodeId) => ({
                        nodeId,
                        field: Wukong.DocumentProto.VariableField.STACK_SPACING,
                        var: {
                            dataType: Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_ALIAS,
                            resolvedDataType:
                                Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT,
                            value: {
                                alias: varId,
                            },
                        },
                    })),
                })
            )
        },

        detechStackSpacingVar() {
            command.invokeBridge(
                CommitType.CommitUndo,
                BatchDetachAutoLayoutVarCommand,
                Wukong.DocumentProto.BatchDetachAutoLayoutVarParam.create({
                    params: state.autoLayoutParentIds.map((nodeId) => ({
                        nodeId,
                        field: Wukong.DocumentProto.VariableField.STACK_SPACING,
                    })),
                })
            )
        },

        setStackCounterSpacing(
            value: number | number[] | 'auto',
            clearFloatInput = false,
            options?: InputOptionsForUndoSquash,
            skipCommitUndo?: boolean
        ) {
            if (value === 'auto') {
                this.updateSelectedAutoLayout(
                    {
                        stackCounterAlignContent:
                            Wukong.DocumentProto.StackCounterAlignContent.STACK_COUNTER_ALIGN_CONTENT_SPACE_BETWEEN,
                    },
                    undefined,
                    skipCommitUndo
                )
            } else {
                const isSingleValue = !Array.isArray(value)
                this.updateAutoLayoutBatch(
                    {
                        params: state.autoLayoutParentIds.map((id, i) => ({
                            nodeId: id,
                            stackCounterSpacing: isSingleValue ? value : value[i],
                            stackCounterAlignContent:
                                Wukong.DocumentProto.StackCounterAlignContent.STACK_COUNTER_ALIGN_CONTENT_AUTO,
                        })),
                        clearFloatInput,
                    },
                    options,
                    skipCommitUndo
                )
            }
        },

        setStackCounterSpacingVar(varId: string) {
            command.invokeBridge(
                CommitType.CommitUndo,
                BatchAttachAutoLayoutVarCommand,
                Wukong.DocumentProto.BatchAttachAutoLayoutVarParam.create({
                    params: state.autoLayoutParentIds.map((nodeId) => ({
                        nodeId,
                        field: Wukong.DocumentProto.VariableField.STACK_COUNTER_SPACING,
                        var: {
                            dataType: Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_ALIAS,
                            resolvedDataType:
                                Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT,
                            value: {
                                alias: varId,
                            },
                        },
                    })),
                })
            )
        },

        detechStackCounterSpacingVar() {
            command.invokeBridge(
                CommitType.CommitUndo,
                BatchDetachAutoLayoutVarCommand,
                Wukong.DocumentProto.BatchDetachAutoLayoutVarParam.create({
                    params: state.autoLayoutParentIds.map((nodeId) => ({
                        nodeId,
                        field: Wukong.DocumentProto.VariableField.STACK_COUNTER_SPACING,
                    })),
                })
            )
        },

        setMultiPadding(payload: PaddingPair[], clearFloatInput = false, options?: InputOptionsForUndoSquash) {
            this.updateAutoLayoutBatch(
                {
                    params: state.autoLayoutParentIds.map((id, i) => {
                        const param: AutoLayoutUpdatedParam = {
                            nodeId: id,
                        }

                        for (const [key, value] of payload) {
                            const isSingleValue = !Array.isArray(value)
                            param[MapPaddingKey[key]] = isSingleValue ? value : value[i]
                        }

                        return param
                    }),
                    clearFloatInput,
                },
                options
            )
        },

        setAdvance(key: ValidAdvanceItem, value: boolean) {
            this.updateAutoLayoutBatch({
                params: state.autoLayoutParentIds.map((id) => {
                    const param: AutoLayoutUpdatedParam = {
                        nodeId: id,
                    }

                    if (key === 'isAlignToTextBaseline') {
                        param.stackCounterAlignItems = value
                            ? StackAlign.STACK_ALIGN_STACK_ALIGN_BASE_LINE
                            : StackAlign.STACK_ALIGN_STACK_ALIGN_CENTER
                    } else if (key === 'isReversedZIndex') {
                        param.stackReverseZIndex = value
                    }

                    return param
                }),
            })
        },

        setWHValue(key: AutoLayoutVisualDirection, value: AutoLayoutWHValue) {
            let direction = StackMode.STACK_MODE_NONE
            if (key === 'w') {
                direction = StackMode.STACK_MODE_HORIZONTAL
            } else if (key === 'h') {
                direction = StackMode.STACK_MODE_VERTICAL
            }

            command.DEPRECATED_invokeBridge(UpdateSelectedAutoLayoutWHValueWasmCall, {
                direction,
                value,
            })
            command.commitUndo()
        },

        hoverWHValue(key: AutoLayoutVisualDirection, value: AutoLayoutWHValue) {
            const param: UpdateAutoLayoutHoverItemParam = {
                value: AutoLayoutHoverItem.AUTO_LAYOUT_HOVER_MENU_ITEM_NONE,
            }

            if (key === 'w') {
                if (value === AutoLayoutWHValue.WH_Fill) {
                    param.value = AutoLayoutHoverItem.AUTO_LAYOUT_HOVER_MENU_ITEM_WIDTH_FILL_CONTAINER
                } else if (value === AutoLayoutWHValue.WH_Hug) {
                    param.value = AutoLayoutHoverItem.AUTO_LAYOUT_HOVER_MENU_ITEM_WIDTH_HUG
                }
            } else if (key === 'h') {
                if (value === AutoLayoutWHValue.WH_Fill) {
                    param.value = AutoLayoutHoverItem.AUTO_LAYOUT_HOVER_MENU_ITEM_HEIGHT_FILL_CONTAINER
                } else if (value === AutoLayoutWHValue.WH_Hug) {
                    param.value = AutoLayoutHoverItem.AUTO_LAYOUT_HOVER_MENU_ITEM_HEIGHT_HUG
                }
            }

            command.DEPRECATED_invokeBridge(UpdateAutoLayoutHoverItemWasmCall, param)
        },

        hoverAutoLayoutMenu(value: AutoLayoutHoverItem) {
            command.DEPRECATED_invokeBridge(UpdateAutoLayoutHoverItemWasmCall, { value })
        },

        unhoverAutoLayoutMenu() {
            command.DEPRECATED_invokeBridge(UpdateAutoLayoutHoverItemWasmCall, {
                value: AutoLayoutHoverItem.AUTO_LAYOUT_HOVER_MENU_ITEM_NONE,
            })
        },

        setAbsolute(enable: boolean) {
            this.updateChildAutoLayoutBatch({
                params: state.autoLayoutChildIds.map((id) => ({
                    nodeId: id,
                    stackPositioning: enable
                        ? StackPositioning.STACK_POSITIONING_ABSOLUTE
                        : StackPositioning.STACK_POSITIONING_AUTO,
                })),
            })
        },

        disableAlignToBaseline(skipCommitUndo?: boolean) {
            this.updateAutoLayoutBatch(
                {
                    params: state.autoLayoutParentIds.map((id) => ({
                        nodeId: id,
                        stackCounterAlignItems: StackAlign.STACK_ALIGN_STACK_ALIGN_CENTER,
                    })),
                },
                undefined,
                skipCommitUndo
            )
        },

        setAdvancePanel(show: boolean) {
            command.DEPRECATED_invokeBridge(SetAutoLayoutAdvancePanelCommand, {
                value: show,
            })
        },

        nextSpaceInput(reverse: boolean) {
            command.DEPRECATED_invokeBridge(NextAutoLayoutFloatInputCommand, { value: reverse })
        },

        clearSpaceInput() {
            try {
                command.DEPRECATED_invokeBridge(ClearAutoLayoutFloatInputForJs)
            } catch (e) {}
        },
    }
}

export type CommandType = ReturnType<typeof useAutoLayoutCommand>
