import { translation } from './auto-item-group-component.translation'
/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { useCallback, useMemo, useState } from 'react'
import { IconArrowDown12 } from '../../../../../../../ui-lib/src'
import { LineIconWrapper } from '../line-icon-wrapper/line-icon-wrapper'
import { AutoItemGroup } from '../type'
import { AutoItemComponent } from './auto-item-component'
import classes from './auto-item-group-component.module.less'

export interface AutoItemGroupComponentProps {
    item: AutoItemGroup
    isFirstItem: boolean
    isLastItem: boolean
    isAutoExpend: boolean
}
export function AutoItemGroupComponent(props: AutoItemGroupComponentProps) {
    const { item, isFirstItem, isLastItem, isAutoExpend } = props
    const [isCollapse, setIsCollapse] = useState<boolean>(!isAutoExpend)

    const onClick = useCallback(() => {
        setIsCollapse(!isCollapse)
    }, [isCollapse])

    const renderChildren = useMemo(() => {
        return (
            <div>
                {item.children.map((child, index) => (
                    <AutoItemComponent
                        key={child.versionId}
                        item={child}
                        isFirstItem={false}
                        isLastItem={index === item.children.length - 1 && isLastItem && !isCollapse}
                    ></AutoItemComponent>
                ))}
            </div>
        )
    }, [isCollapse, isLastItem, item.children])

    return (
        <>
            <LineIconWrapper
                item={item}
                hideUpperHalf={isFirstItem}
                hideLowerHalf={isLastItem && isCollapse}
                onClick={onClick}
                className={classnames(classes.container, { [classes.isCollapse]: isCollapse })}
                dataTestIds={{
                    container: 'history-list-item',
                    moreButton: 'history-list-item-more-button',
                }}
            >
                <div className={classes.autoItemGroup}>
                    <div className={classes.name}>{`${item.children.length} ${translation('AutosaveVersions', {
                        version: item.children.length > 1 ? 'versions' : 'version',
                    })}`}</div>
                    <div className={classes.arrow}>
                        <IconArrowDown12 />
                    </div>
                </div>
            </LineIconWrapper>
            {isCollapse ? null : renderChildren}
        </>
    )
}
