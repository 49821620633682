import { featureSwitchManager } from '../../../kernel/switch/core'
import { useAppContext } from '../../../main/app-context'
import { useViewState } from '../../../view-state-bridge'
import { ChatbotAIInspireSearch } from './chatbot-component/chatbot-ai-inspire-searh'
import { ChatbotAISearch } from './chatbot-component/chatbot-ai-searh'
import { ChatbotHomePage } from './chatbot-component/home-page'
import { ChatbotMainConversationPage } from './chatbot-component/main-conversation-page'
import { PageType } from './service/chatbot-service'
import { ChatbotDialogContainer } from './ui-component/chatbot-container'

export const ChatbotModal = () => {
    const chatbotService = useAppContext().chatbotService
    const isOpen = chatbotService.stateStore.use.popupOpened()
    const pageStack = chatbotService.stateStore.use.pageStack()
    const isHistoryMode = useViewState('historyMode')
    const isReadonly = useViewState('docReadonly')

    const currentPageState = (() => {
        if (pageStack.length === 0) {
            return { pageType: PageType.Home }
        }
        return pageStack[pageStack.length - 1]
    })()

    if (!featureSwitchManager.isEnabled('chatbot')) {
        return null
    }

    if (!isOpen || isHistoryMode || isReadonly) {
        return null
    }

    if (currentPageState.pageType === PageType.NodesPopup) {
        return <ChatbotAISearch />
    }

    if (currentPageState.pageType === PageType.InspireSearchPopup) {
        return <ChatbotAIInspireSearch />
    }

    return (
        <ChatbotDialogContainer
            size={currentPageState.size ?? 'small'}
            title={currentPageState.title}
            showReturnButton={pageStack.length > 0}
            onClose={() => chatbotService.closeModal()}
            onReturn={() => chatbotService.returnPage()}
            data-testid="chatbot-modal"
        >
            {(() => {
                switch (currentPageState.pageType) {
                    case PageType.Home:
                        return <ChatbotHomePage />
                    case PageType.Conversation:
                        return <ChatbotMainConversationPage />
                    default: // 临时弹窗
                        return null
                }
            })()}
        </ChatbotDialogContainer>
    )
}
