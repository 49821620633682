import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAsync } from 'react-use'
import { TagInputRef, TagItem } from '../../../../ui-lib/src/components/inputs/tag-input/tag-input'
import { emailRegExp } from '../../../../util/src'
import { UserBriefVO } from '../../kernel/interface/type'
export type UserBriefVOAndUnregisterVO = UserBriefVO & { unregistered?: boolean }
const splitMark = /,|，|;|；|\n/

const getEmails = (string: string) => {
    const emails = string.split(splitMark)
    return emails
}

interface UseMemberInputProps {
    requestFn: (query: string) => Promise<UserBriefVO[]>
    emailSuffixes?: string[] // 支持的邮箱后缀
    customGenerateTag?: (tag: TagItem<UserBriefVO | string>) => TagItem<UserBriefVO | string> // 对组件提供的生成标签的方法返回值进行二次处理
    defaultUser?: UserBriefVO | UserBriefVO[]
}
export const useMemberTagInput = ({
    requestFn,
    defaultUser,
    emailSuffixes,
    customGenerateTag,
}: UseMemberInputProps) => {
    const [query, setQuery] = useState('')
    const _generateTag = useCallback(
        (v: string | UserBriefVO): TagItem<UserBriefVO | string> => {
            if (typeof v === 'string') {
                const _v = v.trim()
                if (emailRegExp.test(_v)) {
                    // 如果邮箱符合格式，可以生成标签
                    if (emailSuffixes?.length) {
                        if (emailSuffixes.some((e) => _v.endsWith(e))) {
                            return {
                                name: _v,
                                color: 'black' as const,
                                customProps: { email: _v, unregistered: true } as UserBriefVOAndUnregisterVO,
                            } as TagItem<UserBriefVO>
                        } else {
                            return { name: _v, color: 'red' as const, customProps: _v }
                        }
                    } else {
                        return {
                            name: _v,
                            color: 'black' as const,
                            customProps: { email: _v, unregistered: true } as UserBriefVOAndUnregisterVO,
                        } as TagItem<UserBriefVO>
                    }
                }
                return { name: _v, color: 'red' as const, customProps: _v }
            } else {
                return { name: v.nickname, color: 'black' as const, customProps: v }
            }
        },
        [emailSuffixes]
    )
    const generateTag = useCallback(
        (v: string | UserBriefVO): TagItem<UserBriefVOAndUnregisterVO | string> =>
            customGenerateTag ? customGenerateTag(_generateTag(v)) : _generateTag(v),
        [_generateTag, customGenerateTag]
    )
    const defaultTags = useMemo(() => {
        if (Array.isArray(defaultUser)) {
            return defaultUser.map(generateTag)
        } else {
            return defaultUser ? [generateTag(defaultUser)] : undefined
        }
    }, [defaultUser, generateTag])
    const [tags, setTags] = useState<TagItem<UserBriefVOAndUnregisterVO | string>[]>(defaultTags ?? [])

    const { value: _items = [], loading } = useAsync(() => {
        if (query) {
            if (getEmails(query).length > 1) {
                return Promise.resolve([])
            }
            return requestFn(query)
        } else {
            return Promise.resolve([])
        }
    }, [query])
    // 过滤掉已经选择的用户
    const items = useMemo(() => {
        return _items.filter((o) => {
            const { email } = o
            const selectedEmails = tags.map((tag) => {
                if (typeof tag.customProps != 'string') {
                    return tag.customProps?.email
                }
                return tag.name
            })
            return !selectedEmails.includes(email)
        })
    }, [_items, tags])

    const tagInputRef = useRef<TagInputRef<string | UserBriefVOAndUnregisterVO>>(null)

    // 支持粘贴一大批邮箱进来的情况
    useEffect(() => {
        const emails = getEmails(query)
        let flag = true
        if (emails.length > 1) {
            Promise.all(
                emails.filter(Boolean).map((email) => requestFn(email).then((r) => [r[0], email] as const))
            ).then((list) => {
                if (!flag) {
                    return
                }
                const _tags: Array<ReturnType<typeof generateTag>> = []
                for (const res of list) {
                    const [user, email] = res
                    const tag = generateTag(user ?? email)
                    _tags.push(tag)
                }
                tagInputRef.current?.appendTag(..._tags)
            })
        }
        return () => {
            flag = false
        }
    }, [generateTag, query, requestFn])

    return {
        query,
        setQuery,
        tagInputRef,
        tags,
        setTags,
        loading,
        items,
        generateTag,
        defaultTags,
    }
}
