import { WKDivider } from '../../../../../../ui-lib/src'

function SkeletonHeader() {
    return (
        <div className="w-full flex flex-col gap-4px">
            <div className="my-1px w-80px h-20px rounded-$wk-radius-default bg-$wk-gray-2"></div>
            <div className="w-full flex flex-row items-center justify-between">
                <div className="flex flex-row gap-16px items-center">
                    <div className="w-140px h-16px rounded-$wk-radius-default bg-$wk-gray-2"></div>
                    <div className="w-20px h-20px rounded-full bg-$wk-gray-2"></div>
                </div>
                <div className="w-100px h-16px rounded-$wk-radius-default bg-$wk-gray-2"></div>
            </div>
        </div>
    )
}

function SkeletonCard() {
    return (
        <div className="w-full flex flex-col gap-8px">
            <div className="w-242px h-180px rounded-$wk-radius-default bg-$wk-gray-2"></div>
            <div className="my-2px w-100px h-16px rounded-$wk-radius-default bg-$wk-gray-2"></div>
        </div>
    )
}

export function AISearchResultLoading() {
    return (
        <div className="p-24px">
            <div className="w-full flex flex-col gap-16px">
                <SkeletonHeader />
                <div className="w-full flex flex-row gap-16px">
                    <SkeletonCard />
                    <SkeletonCard />
                    <SkeletonCard />
                </div>
            </div>
            <WKDivider className="my-20px" />
            <div className="w-full flex flex-col gap-16px">
                <SkeletonHeader />
                <div className="w-full flex flex-row gap-16px">
                    <SkeletonCard />
                    <SkeletonCard />
                    <SkeletonCard />
                </div>
            </div>
        </div>
    )
}
