/* eslint-disable no-restricted-imports */
import { ToggleFullScreenCommandWasmCall, ToggleShowSidebarCommandForJs } from '@wukong/bridge-proto'
import { BridgeCommand } from './command-invoker'

export const cmdToggleFullscreen: BridgeCommand = (ctx) => {
    ctx.commandInvoker.DEPRECATED_invokeBridge(ToggleFullScreenCommandWasmCall)
}

export const cmdToggleSidebar: BridgeCommand = (ctx) => {
    ctx.commandInvoker.DEPRECATED_invokeBridge(ToggleShowSidebarCommandForJs)
}
