import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Frame: '容器',
    Group: '组',
} as const

export const enTranslation = {
    Frame: 'Frame',
    Group: 'Group',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
