import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    PageLinkCopied: '页面链接已复制',
    Duplicating: '正在创建页面副本',
    PageDuplicated: '已创建页面副本',
} as const

export const enTranslation = {
    PageLinkCopied: 'Page link copied to clipboard',
    Duplicating: 'Duplicating',
    PageDuplicated: 'Page duplicated',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
