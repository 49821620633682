import classnames from 'classnames'
import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useWindowResize } from '../../resize/use-window-resize'
import {
    getContainerLeft,
    minEdgeInWorldRect,
    offsetTriggerHorizontal,
    PopUpScrollProps,
    Rect,
} from '../pop-up-scroll/pop-up-scroll'
import classes from './pop-up-static.module.less'

type PopUpScrollPropsPick = Pick<
    PopUpScrollProps,
    | 'placement'
    | 'overMajorClassName'
    | 'overMinorClassName'
    | 'worldRect'
    | 'triggerRect'
    | 'isMinWidthFromTrigger'
    | 'minWidth'
    | 'width'
    | 'maxWidth'
    | 'children'
    | 'removeMask'
    | 'onClickMask'
>

export interface PopUpStaticProps extends PopUpScrollPropsPick {
    style?: React.CSSProperties
    autoAdjustLeft?: boolean
    dataTestIds?: {
        container?: string
        content?: string
        mask?: string
    }
}

export interface PopUpStaticRef {
    getContainer: () => HTMLDivElement
    getKeyboardElement: () => HTMLDivElement
}
function _PopUpStatic<T extends PopUpStaticProps = PopUpStaticProps>(props: T, ref?: React.Ref<PopUpStaticRef>) {
    const {
        triggerRect,
        dataTestIds,
        isMinWidthFromTrigger,
        minWidth,
        width,
        maxWidth,
        placement = 'bottom center',
        overMajorClassName,
        overMinorClassName,
        worldRect,
        removeMask,
        style,
        autoAdjustLeft,
        onClickMask,
    } = props
    const containerRef = useRef<HTMLDivElement>(null)
    const contentRef = useRef<HTMLDivElement>(null)
    const isMountedRef = useRef<boolean>(false)
    const [_containerStyle, _setContainerStyle] = useState<React.CSSProperties>({})

    const containerStyle = useMemo(() => {
        return Object.assign({}, _containerStyle, style)
    }, [_containerStyle, style])

    const getWorldRect = useCallback(() => {
        return worldRect
            ? typeof worldRect === 'function'
                ? worldRect()
                : worldRect
            : {
                  left: 0,
                  top: 0,
                  right: document.documentElement.clientWidth,
                  bottom: document.documentElement.clientHeight,
              }
    }, [worldRect])

    const getTriggerRect = useCallback(() => {
        return typeof triggerRect === 'function' ? triggerRect() : triggerRect
    }, [triggerRect])

    const getOverRect = useCallback(
        (el: HTMLElement) => {
            let rect: Rect | undefined
            if (overMajorClassName) {
                rect = el.getElementsByClassName(overMajorClassName)[0]?.getBoundingClientRect()
            }
            if (rect) {
                return rect
            }
            if (overMinorClassName) {
                rect = el.getElementsByClassName(overMinorClassName)[0]?.getBoundingClientRect()
            }
            if (rect) {
                return rect
            }
            const rect1 = el.getBoundingClientRect()
            const rect2 = getTriggerRect()
            const height = 0
            const top = rect1.top + height
            return { left: rect1.left, top, right: rect1.right, bottom: top + rect2.bottom - rect2.top }
        },
        [getTriggerRect, overMinorClassName, overMajorClassName]
    )

    const contentStyle = useMemo(() => {
        let _minWidth = minWidth
        if (isMinWidthFromTrigger) {
            const rect1 = getTriggerRect()
            const rect2 = getWorldRect()
            const limitMinWidth = Math.min(rect1.right - rect1.left, rect2.right - rect2.left - 2 * minEdgeInWorldRect)
            _minWidth = Math.max(_minWidth ?? 0, limitMinWidth)
        }
        const isOverflowHidden =
            (typeof maxWidth === 'number' && !isNaN(maxWidth)) || (typeof width === 'number' && !isNaN(width))
        return {
            minWidth: _minWidth,
            width,
            maxWidth,
            overflow: isOverflowHidden ? 'hidden' : undefined,
        }
    }, [getTriggerRect, getWorldRect, isMinWidthFromTrigger, maxWidth, minWidth, width])

    const setContainerStyle = useCallback((top: number, left: number) => {
        if (!containerRef.current) {
            return
        }
        _setContainerStyle(Object.assign({ left, top }))
        containerRef.current.setAttribute('style', `top:${top}px; left: ${left}px;`)
    }, [])

    // 用于初次显示时计算位置
    const setOpenPosition = useCallback(() => {
        if (!containerRef.current || !contentRef.current) {
            return
        }
        const contentElement = contentRef.current
        const _worldRect = getWorldRect()
        const _triggerRect = getTriggerRect()
        const contentRect = contentElement.getBoundingClientRect()

        const containerLeft = getContainerLeft(
            _worldRect,
            _triggerRect,
            contentRect.width,
            minEdgeInWorldRect,
            placement,
            false
        )

        if (placement === 'over') {
            const overRect = getOverRect(contentElement)
            const containerOffsetTop = overRect.top - contentRect.top + (overRect.bottom - overRect.top) / 2
            const triggerCenterLine = (_triggerRect.top + _triggerRect.bottom) / 2
            const containerTop = Math.max(_worldRect.top + minEdgeInWorldRect, triggerCenterLine - containerOffsetTop)
            setContainerStyle(containerTop, containerLeft)
        } else if (placement.includes('bottom ')) {
            let containerTop = _triggerRect.bottom + offsetTriggerHorizontal
            const isBottomOverflow = containerTop + contentRect.height + minEdgeInWorldRect > _worldRect.bottom
            if (isBottomOverflow) {
                const isTopOverflow = contentRect.height + 2 * minEdgeInWorldRect > _worldRect.bottom - _worldRect.top
                if (isTopOverflow) {
                    containerTop = _worldRect.top + minEdgeInWorldRect
                } else {
                    containerTop = _worldRect.bottom - minEdgeInWorldRect - contentRect.height
                }
            }
            setContainerStyle(containerTop, containerLeft)
        } else if (placement.includes('top ')) {
            const containerBottom = _triggerRect.top - offsetTriggerHorizontal
            const containerTop = Math.max(_worldRect.top + minEdgeInWorldRect, containerBottom - contentRect.height)
            setContainerStyle(containerTop, containerLeft)
        } else if (placement === 'left top' || placement === 'right top') {
            const containerTop = _triggerRect.top
            setContainerStyle(containerTop, containerLeft)
        }
    }, [getWorldRect, getTriggerRect, placement, getOverRect, setContainerStyle])

    const onResize = useCallback(() => {
        if (!autoAdjustLeft) {
            return
        }
        if (!contentRef.current || !containerRef.current) {
            return
        }
        const container = containerRef.current as HTMLDivElement
        const _worldRect = getWorldRect()
        const _triggerRect = getTriggerRect()
        const contentRect = contentRef.current.getBoundingClientRect()
        const containerRect = container.getBoundingClientRect()

        const containerLeft = getContainerLeft(
            _worldRect,
            _triggerRect,
            contentRect.width,
            minEdgeInWorldRect,
            placement,
            false
        )
        if (containerLeft !== containerRect.left) {
            setContainerStyle(containerRect.top, containerLeft)
        }
    }, [autoAdjustLeft, getTriggerRect, getWorldRect, placement, setContainerStyle])

    useLayoutEffect(() => {
        if (isMountedRef.current) {
            return
        }
        setOpenPosition()
    }, [setOpenPosition])

    useEffect(() => {
        isMountedRef.current = true
    }, [])

    useWindowResize(onResize)

    useImperativeHandle(
        ref,
        () => ({ getContainer: () => containerRef.current!, getKeyboardElement: () => containerRef.current! }),
        []
    )

    return (
        <div
            className={classnames(classes.positionContainer)}
            ref={containerRef}
            data-testid={dataTestIds?.container}
            style={containerStyle}
            tabIndex={-1}
            data-forbid-shortcuts
        >
            <div ref={contentRef} className={classes.content} data-testid={dataTestIds?.content} style={contentStyle}>
                {props.children}
            </div>
            {removeMask ? null : (
                <span
                    className={classes.mask}
                    onClick={onClickMask}
                    onContextMenu={onClickMask}
                    data-testid={dataTestIds?.mask}
                ></span>
            )}
        </div>
    )
}

export const PopUpStatic = forwardRef(_PopUpStatic) as <T extends PopUpStaticProps = PopUpStaticProps>(
    props: T & { ref?: React.Ref<PopUpStaticRef> }
) => React.ReactElement
