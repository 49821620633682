import { Item, KeyType } from '../data'
import { translation } from './data.translation'

export const toolTabCenter: Item[] = [
    {
        name: translation('Inspect'),
        macKeys: [{ type: KeyType.Key, char: 'V' }],
        winKeys: [{ type: KeyType.Key, char: 'V' }],
    },
    {
        name: translation('AddComment'),
        macKeys: [{ type: KeyType.Key, char: 'C' }],
        winKeys: [{ type: KeyType.Key, char: 'C' }],
    },
]

export const viewTabLeft: Item[] = [
    {
        name: translation('ShowHideUI'),
        macKeys: [
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: '\\' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: '\\' },
        ],
    },
    {
        name: translation('OpenLayersPanel'),
        macKeys: [
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: '1' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: '1' },
        ],
    },
    {
        name: translation('MultiplayerCursors'),
        macKeys: [
            { type: KeyType.Key, char: '⌥' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: '\\' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Alt' },
            { type: KeyType.Key, char: '\\' },
        ],
    },
    {
        name: translation('OpenDesignMode'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: 'D' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: 'D' },
        ],
    },
]

export const viewTabRight: Item[] = [
    {
        name: translation('ZoomInOut'),
        macKeys: [
            { type: KeyType.Key, char: '+' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: '-' },
        ],
        winKeys: [
            { type: KeyType.Key, char: '+' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: '-' },
        ],
    },
    {
        name: `${translation('ZoomTo')} 100%`,
        macKeys: [
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: '0' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: '0' },
        ],
    },
    {
        name: translation('ZoomToFit'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: '1' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: '1' },
        ],
    },
    {
        name: translation('ZoomToSelection'),
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: '2' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: '2' },
        ],
    },
]

export const copyTabCenter: Item[] = [
    {
        name: translation('Copy'),
        macKeys: [
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'C' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'C' },
        ],
    },
    {
        name: `${translation('CopyAs')} PNG`,
        macKeys: [
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: '⌘' },
            { type: KeyType.Key, char: 'C' },
        ],
        winKeys: [
            { type: KeyType.Key, char: 'Ctrl' },
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: 'C' },
        ],
    },
]

export const selectionTabCenter: Item[] = [
    {
        name: translation('SelectNone'),
        macKeys: [{ type: KeyType.Key, char: '⎋ Esc' }],
        winKeys: [{ type: KeyType.Key, char: '⎋ Esc' }],
    },
    {
        name: translation('SelectChildren'),
        macKeys: [{ type: KeyType.Key, char: '↩︎ Enter' }],
        winKeys: [{ type: KeyType.Key, char: '↩︎ Enter' }],
    },
    {
        name: translation('SelectParent'),
        macKeys: [
            { type: KeyType.Key, char: '\\' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: '⇧' },
            { type: KeyType.Key, char: '↩︎ Enter' },
        ],
        winKeys: [
            { type: KeyType.Key, char: '\\' },
            { type: KeyType.Split, char: '/' },
            { type: KeyType.Key, char: 'Shift' },
            { type: KeyType.Key, char: '↩︎ Enter' },
        ],
    },
]
