import { SessionStorageKey } from '../../web-storage/session-storage/config/index'
import { enhancedSessionStorage } from '../../web-storage/session-storage/storage'
import { featureSwitchManager } from '../switch'
import { Serializable, baseType, deserialize, serialize } from './serialize'

export class SyncByStorage<T extends Serializable | baseType> {
    constructor(private name: SessionStorageKey) {}
    private eventListener: ((e: StorageEvent) => void) | null = null
    private signalAbort: (() => void) | null = null

    private onMessage(storageEvent: StorageEvent): MessageEvent<T> {
        if (storageEvent.newValue != null) {
            const value = storageEvent.newValue
            const data: T = deserialize(value)
            return new MessageEvent(`${this.name}'s message by localStorage`, {
                data,
            })
        }
        return new MessageEvent(`${this.name}'s message by localStorage`)
    }

    public close() {
        if (this.eventListener) {
            window.removeEventListener('storage', this.eventListener)
            this.eventListener = null
        }
    }

    public isClose() {
        return this.eventListener === null
    }

    public postMessage(message: T): boolean {
        enhancedSessionStorage.setItem(this.name, serialize(message))
        return true
    }

    // currently only support message
    addEventListener(
        type: 'message',
        callback: (ev: MessageEvent<T>) => void,
        options?: boolean | AddEventListenerOptions | undefined
    ): void {
        if (type === 'message' && this.eventListener === null) {
            this.eventListener = (e: StorageEvent) => callback(this.onMessage(e))
            if (options && typeof options === 'object' && options.signal) {
                this.signalAbort = () => {
                    this.eventListener = null
                }
                options.signal.addEventListener('abort', this.signalAbort)
            }
            window.addEventListener('storage', this.eventListener, options)
        }
    }

    dispatchEvent(_event: Event): boolean {
        return false
    }

    removeEventListener(
        type: 'message',
        _callback: (ev: MessageEvent<T>) => void,
        options?: boolean | EventListenerOptions | undefined
    ): void {
        if (type === 'message' && this.eventListener) {
            window.removeEventListener('storage', this.eventListener, options)
        }
    }
}
export class SyncChannel<T extends Serializable | baseType> {
    static construced: Set<SessionStorageKey> = new Set()
    private channel: BroadcastChannel | null = null
    private storage: SyncByStorage<T> | null = null

    constructor(private channelName: SessionStorageKey, enableStorageChannel = false) {
        if (
            window.BroadcastChannel !== undefined &&
            typeof window.BroadcastChannel === 'function' &&
            !SyncChannel.construced.has(channelName)
        ) {
            this.channel = new window.BroadcastChannel(channelName)
            SyncChannel.construced.add(channelName)
        } else if (enableStorageChannel && featureSwitchManager.isEnabled('storage-event-as-channel')) {
            this.storage = new SyncByStorage(channelName)
        }
    }

    public postMessage(message: T): boolean {
        if (this.channel !== null) {
            this.channel.postMessage(message)
            return true
        }
        if (this.storage !== null) {
            return this.storage.postMessage(message)
        }
        return false
    }

    public close() {
        if (this.channel !== null) {
            this.channel.close()
            SyncChannel.construced.delete(this.channelName)
            return
        }
        this.storage = null
    }

    addEventListener(
        type: 'message',
        callback: (ev: MessageEvent<T>) => void,
        options?: boolean | AddEventListenerOptions | undefined
    ): void {
        if (this.channel !== null) {
            this.channel.addEventListener(type, callback, options)
            return
        }
        if (this.storage !== null) {
            this.storage.addEventListener('message', callback, options)
        }
    }

    dispatchEvent(event: MessageEvent<T>): boolean {
        if (this.channel !== null) {
            return this.channel.dispatchEvent(event)
        }
        if (this.storage !== null) {
            return this.storage.dispatchEvent(event)
        }
        return false
    }

    removeEventListener(
        type: 'message',
        callback: (ev: MessageEvent<T>) => void,
        options?: boolean | EventListenerOptions | undefined
    ): void {
        if (this.channel !== null) {
            this.channel.removeEventListener(type, callback, options)
            return
        }
        if (this.storage !== null) {
            this.storage.removeEventListener('message', callback, options)
            return
        }
    }
}
