import { TraceableAbortSignal } from 'packages/util/src/abort-controller/traceable-abort-controller'
import { isCypress } from '../../../../../util/src'
import { NotifyService } from '../../../kernel/notify/notify-service'
import { SpaceNotifyService } from './space-noify-service'
import { SpaceNotifyStore, getSpaceStore, setSpaceStore } from './types'

export const generateSpaceNotifyState = (
    set: setSpaceStore,
    get: getSpaceStore,
    notifyService: NotifyService,
    signal: TraceableAbortSignal
): SpaceNotifyStore => {
    return {
        instance: new SpaceNotifyService(notifyService, signal),
        connect(orgId: string, userId: number) {
            if (!isCypress()) {
                get().spaceNotifyStore.instance.connect(orgId, userId)
            }
        },
    }
}
