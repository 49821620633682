import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    ManiFestError: '无法读取 manifest 文件',
    ReadPluginFailed: '无法读取插件，请检查后再试',
    ShowConsole: '打开控制台',
    PublishToOrg: '发布至企业',
    Publish: '发布',
    Icon: '图标',
    Name: '名称',
    ManifestIdInvalid: 'Manifest 文件中的 ID 无效',
    ManifestIdInvalidPleaseCopy: 'Manifest 文件中的 ID 无效，请复制此 ID 到 manifest 文件中',
    PleaseCopy: '复制此 ID 到 manifest 文件中',
    GenerateNewPluginId: '生成 ID',
    CopyId: '复制 ID',
    CopyDone: '已复制到剪贴板',
    ViewDetails: '查看详情',
    CreatePluginFailed: '插件创建失败',
    PublishFailed: '插件发布失败',
    GenerateIdFailed: '插件 ID 生成失败',
} as const

export const enTranslation = {
    ManiFestError: 'Manifest error',
    ReadPluginFailed: 'An error occurred while loading code. Check plugin files and try again. ',
    ShowConsole: 'Show console',
    PublishToOrg: 'Publish to organization',
    Publish: 'Publish',
    Icon: 'Icon',
    Name: 'Name',
    ManifestIdInvalid: 'Invalid ID in manifest.json',
    ManifestIdInvalidPleaseCopy: 'Invalid ID in manifest.json. Copy this ID to your manifest.json file.',
    PleaseCopy: 'Copy this ID to your manifest.json file.',
    GenerateNewPluginId: 'Generate ID',
    CopyId: 'Copy',
    CopyDone: 'Copied to clipboard',
    ViewDetails: 'View details',
    CreatePluginFailed: 'An error occurred while creating plugin.',
    PublishFailed: 'An error occurred while publishing plugin.',
    GenerateIdFailed: 'An error occurred while generating plugin ID.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
