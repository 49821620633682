import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    UnknownLibrary: '未知库',
    NoEffect: '本文件没有效果样式',
    NoStyles: '没有找到该效果样式',
    EffectStyle: '效果样式',
    Search: '搜索',
} as const

export const enTranslation = {
    UnknownLibrary: 'Unknown library',
    NoEffect: 'This file has no effect styles',
    NoStyles: 'No styles match this search',
    EffectStyle: 'Effect Styles',
    Search: 'Search',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
