import { PropsWithChildren } from 'react'
import { LibraryThumbnailImageEditorConfigProvider } from '../../../share/component-style-library/library-thumbnail-image/context'
import { LibraryListModal } from '../../component/component-style-library-v2/library-list-modal/library-list-modal'
import { LibraryModalControlContextProvider } from './library-modal-config'

// 组件库相关 ContextProvider 汇总
export function LibraryComponentStyleRootProvider({ children }: PropsWithChildren) {
    return (
        <LibraryModalControlContextProvider>
            <LibraryThumbnailImageEditorConfigProvider>
                {children}
                <LibraryListModal />
            </LibraryThumbnailImageEditorConfigProvider>
        </LibraryModalControlContextProvider>
    )
}
