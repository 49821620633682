import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Top: '上',
    Left: '左',
    Bottom: '下',
    Right: '右',
    TopAnd: '上下',
    LeftAnd: '左右',
    Center: '居中',
    Scale: '缩放',
} as const

export const enTranslation = {
    Top: 'Top',
    Left: 'Left',
    Bottom: 'Bottom',
    Right: 'Right',
    TopAnd: 'Top and bottom',
    LeftAnd: 'Left and right',
    Center: 'Center',
    Scale: 'Scale',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
