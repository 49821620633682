import { command, computed, state, type Command, type Computed, type Write } from 'ccstate'
import { TraceableAbortSignal } from '../abort-controller/traceable-abort-controller'

export const RESET$ = Symbol('RESET')

/**
 * 传入一个函数，获取一个可同时触发和使用返回值的 [Value, Effect]
 * 当 set(effect) 时，标明调用传入的函数
 * 当 get(value) 时，标明获取函数调用的结果。
 * 注：
 * 1. get(value) 必须发生在 set(effect) 之后，否则会报错
 * 2. set(effect, RESET) 支持 RESET api，当 RESET 后，atom 恢复到 init 状态，再次 get 会报错
 * @param action
 * @returns
 */
export function action<
    Args extends Result extends Promise<unknown> ? [TraceableAbortSignal, ...unknown[]] : unknown[],
    Result
>(act: Write<Result, Args>): [Computed<Result | undefined>, Command<Result, Args | [typeof RESET$]>] {
    const initSymbol = Symbol('init')

    const inner$ = state<typeof initSymbol | Result>(initSymbol)

    return [
        computed((get) => {
            const value = get(inner$)

            if (value === initSymbol) {
                return undefined
            }

            return value
        }),
        command(({ get, set }, ...args) => {
            if (args[0] === RESET$) {
                set(inner$, initSymbol)
                return undefined as unknown as Result
            }

            const value = act({ get, set }, ...(args as Args))

            set(inner$, typeof value === 'function' ? () => value : value)

            return value
        }),
    ]
}
