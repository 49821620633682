import { translation } from './commands.translation'
/* eslint-disable no-restricted-imports */
import {
    AddVariantForSelectedComponentWasmCall,
    AICandidateComponentConvertCommand,
    CheckShowRestoreComponentWarningCommand,
    ComponentSetSelectionCommand,
    CurrentPageSetSelectionCommandWasmCall,
    DetachSelectedInstanceWasmCall,
    ForceRecoverFigmaComponentForSelectedInstanceCommand,
    GoToSelectedInstanceMainComponentCommand,
    ImportNodeFromLibraryCommand,
    PublishSelectedNodesCommand,
    PushSelectedInstanceChangesToMainComponentCommand,
    RecoverComponentForSelectedInstanceCommand,
    RecoverToDefaultVarientCommand,
    RenameSelectedComponentSetPropWasmCall,
    ReorderSelectedComponentSetPropOptionsCommand,
    ReplaceSelectedInstanceMainCompWasmCall,
    ResetComponentPropOnInstanceCommand,
    ResetSelectedInstancesByTypeCommand,
    SearchReplaceInstanceWasmCall,
    SelectConflictVariantsCommand,
    ToggleReplaceInstancePanelWasmCall,
    UpdateSelectedComponentSetPropsWasmCall,
    UpdateSelectedNodeDescriptionCommand,
    UpdateSelectedVariantsPropsCommandWasmCall,
    Wukong,
} from '@wukong/bridge-proto'
import { wkDialogConfirm, WKToast } from '../../../../../../ui-lib/src'
import { throwErrorWithLog } from '../../../../../../util/src'
import { CommitType } from '../../../../document/command/commit-type'
import { useLibraryComponentService } from '../../../../main/app-context'
import { LibraryResourceOssClientType } from '../../../../share/component-style-library/service/library-resource-downloader'
import { useCommand } from '../../../context/document-context'
import { useDocInfoContext } from '../../../context/top-area-context'
import { OpenLibraryPublishModalSource } from '../../component-style-library-v2/library-service/library-modal-router-service'
import { LibraryNodeDataService } from '../../component-style-library-v2/library-service/library-node-data-service'
import { useLibraryModalRouter } from '../../component-style-library-v2/library-service/use-library-modal-router'
import {
    ComponentSetProp,
    CompSetPropConflictType,
    InstanceResetType,
    ReplaceInstanceNodeData,
    ReplaceInstanceSearchResults,
    VariantProp,
} from './types'

export function useComponentInstanceCommand() {
    const command = useCommand()
    const libraryComponentService = useLibraryComponentService()
    const { openLibraryPublishModalIfPaid } = useLibraryModalRouter()
    const { docData } = useDocInfoContext()

    return {
        aiCandidateComponentConvert() {
            command.DEPRECATED_invokeBridge(AICandidateComponentConvertCommand)
            command.commitUndo()
        },
        changeComponentDescription(desc: string) {
            command.DEPRECATED_invokeBridge(UpdateSelectedNodeDescriptionCommand, { value: desc })
            command.commitUndo()
        },
        publishSelectedComponents() {
            command.DEPRECATED_invokeBridge(PublishSelectedNodesCommand)
            openLibraryPublishModalIfPaid(OpenLibraryPublishModalSource.SelectionPublishButton, !!docData?.draft)
        },
        goToMainComp() {
            command.DEPRECATED_invokeBridge(GoToSelectedInstanceMainComponentCommand)
            command.commitUndo()
        },
        JumpToOuterMostInstances(outerMostInstanceIds: string[]) {
            command.invokeBridge(CommitType.CommitUndo, CurrentPageSetSelectionCommandWasmCall, {
                selection: outerMostInstanceIds,
            })
        },
        detachSelectedInstance() {
            command.DEPRECATED_invokeBridge(DetachSelectedInstanceWasmCall)
            command.commitUndo()
        },
        pushSelectedInstanceChangesToMainComp() {
            command.DEPRECATED_invokeBridge(PushSelectedInstanceChangesToMainComponentCommand)
            command.commitUndo()
        },
        resetInstanceProps(type: InstanceResetType) {
            command.DEPRECATED_invokeBridge(ResetSelectedInstancesByTypeCommand, { type })
            command.commitUndo()
        },
        resetInstanceAssignments(propId: string, defId2EffectdNodeIds: { [propId: string]: { value: string[] } }) {
            command.invokeBridge(CommitType.CommitUndo, ResetComponentPropOnInstanceCommand, {
                defId2EffectdNodeIds,
            })
        },
        combineAsVariants() {
            command.DEPRECATED_invokeBridge(ComponentSetSelectionCommand)
            command.commitUndo()
        },
        updateCompSetProps(props: ComponentSetProp[]) {
            command.DEPRECATED_invokeBridge(UpdateSelectedComponentSetPropsWasmCall, { props })
            command.commitUndo()
        },
        renameCompSetProp(oldName: string, newName: string, index: number, props: ComponentSetProp[]) {
            command.invokeBridge(CommitType.CommitUndo, RenameSelectedComponentSetPropWasmCall, {
                oldName,
                newName,
                props,
            })
        },
        updateVariantProps(props: VariantProp[]) {
            command.DEPRECATED_invokeBridge(UpdateSelectedVariantsPropsCommandWasmCall, { props })
            command.commitUndo()
        },
        reorderSelectedComponentSetPropOptions(
            props: Wukong.DocumentProto.IArg_reorderSelectedComponentSetPropOptions
        ) {
            command.DEPRECATED_invokeBridge(ReorderSelectedComponentSetPropOptionsCommand, props)
            command.commitUndo()
        },
        selectNodes(type: CompSetPropConflictType) {
            command.DEPRECATED_invokeBridge(SelectConflictVariantsCommand, { type })
            command.commitUndo()
        },
        createVariantFromSelection() {
            command.DEPRECATED_invokeBridge(AddVariantForSelectedComponentWasmCall)
            command.commitUndo()
        },
        searchByKeyword(input: string, trigger: Wukong.DocumentProto.ComponentPickerTrigger, localOnly = false) {
            return (command.DEPRECATED_invokeBridge(SearchReplaceInstanceWasmCall, {
                value: input,
                localOnly,
                componentPickerTrigger: trigger,
            })?.results ?? []) as unknown as ReplaceInstanceSearchResults
        },
        toggleReplacePanel(status: boolean) {
            command.DEPRECATED_invokeBridge(ToggleReplaceInstancePanelWasmCall, {
                value: status,
            })
        },
        recoverToDefaultVairent() {
            command.DEPRECATED_invokeBridge(RecoverToDefaultVarientCommand)
        },
        recoverComponentForInstance() {
            const showWarningModal = command.invokeBridge(CommitType.Noop, CheckShowRestoreComponentWarningCommand)
            if (showWarningModal.value) {
                wkDialogConfirm.show({
                    title: translation('RestoreMainComponent'),
                    content: translation('RestoreComponentTip'),
                    okText: translation('Restore'),
                    cancelText: translation('Cancel'),
                    okButtonProps: { type: 'primary' },
                    onOk: async () => {
                        command.invokeBridge(CommitType.CommitUndo, RecoverComponentForSelectedInstanceCommand)
                    },
                })
            } else {
                command.invokeBridge(CommitType.CommitUndo, RecoverComponentForSelectedInstanceCommand)
            }
        },
        forceRecoverFigmaComponentForInstance() {
            command.invokeBridge(CommitType.CommitUndo, ForceRecoverFigmaComponentForSelectedInstanceCommand)
        },

        async replaceMainCompId(listNodeData: ReplaceInstanceNodeData & { docId: string }) {
            const asyncFn = async () => {
                let nodeId: string | null | undefined = listNodeData.nodeId

                if (listNodeData.isRemote) {
                    const fetchData = await (
                        libraryComponentService.libraryNodeDataService as LibraryNodeDataService
                    ).fetchRemoteExportedDocument({
                        remoteDocId: listNodeData.docId,
                        remoteNodeId: listNodeData.nodeId,
                        nodeDataPath: listNodeData.nodeDataPath,
                        ossClientType: LibraryResourceOssClientType.Component,
                        isLocal: false,
                        localNodeId: null,
                    })
                    nodeId = command.DEPRECATED_invokeBridge(
                        ImportNodeFromLibraryCommand,
                        Wukong.DocumentProto.ImportNodeFromLibraryParam.create({
                            ...fetchData,
                            key: listNodeData.key,
                        })
                    ).id
                }

                if (!nodeId) {
                    return throwErrorWithLog('[replaceMainCompId] ImportNodeFromLibraryCommand fail')
                }

                const { success, errorMsg } = command.DEPRECATED_invokeBridge(ReplaceSelectedInstanceMainCompWasmCall, {
                    value: nodeId,
                })

                command.commitUndo()

                if (!success) {
                    WKToast.show(errorMsg || translation('zwScDh'))
                }
            }
            const toastKey = WKToast.show(translation('BDDuSC'), { delay: 2000 })
            return await asyncFn()
                .then((res) => {
                    WKToast.close(toastKey)
                    return res
                })
                .catch(() => {
                    WKToast.close(toastKey)
                    WKToast.show(`${translation('zwScDh')}`)
                    return undefined
                })
        },
    }
}

export type CommandType = ReturnType<typeof useComponentInstanceCommand>
