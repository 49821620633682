import { Wukong } from '@wukong/bridge-proto'
import { useCallback, useRef, useState } from 'react'
import { Position } from '../../../../../../ui-lib/src/components/draggable-popup/util'
import {
    AISearchDocumentResult,
    AISearchFileOwnerCondition,
    AiSearchResourceType,
    AISearchResponse,
    AISearchSortingRule,
} from '../../../../kernel/interface/ai-search'
import { UserBriefVO } from '../../../../kernel/interface/type'
import { AISearch } from '../../../../kernel/request/ai-search'
import { useAppContext } from '../../../../main/app-context'
import { DeepRequired } from '../../../../view-state-bridge'
import { useDocInfoContext } from '../../../context/top-area-context'

interface SearchFilter {
    fileOwnerCondition: AISearchFileOwnerCondition
    resourceType: AiSearchResourceType
    sortingRule: AISearchSortingRule
}

export type SearchInfo =
    | { type: 'IMAGE'; file: File; searchFilter: SearchFilter }
    | {
          type: 'NODE'
          file: File
          node: DeepRequired<Wukong.DocumentProto.IAISearchNode>
          searchFilter: SearchFilter
      }

export type SearchResult =
    | {
          type: 'Loading'
      }
    | {
          type: 'Success'
          result: AISearchResponse['result']
          documentOwnerInfo: AISearchResponse['documentOwnerInfo']
      }
    | {
          type: 'Error'
      }

interface AiSearchCommonModel {
    onExit: () => void
    setSearchInfo: (searchInfo: SearchInfo | null) => void
    positionTopCenter: Position | undefined
    setPositionTopCenter: (position: Position) => void
}

export interface AiSearchInitModel extends AiSearchCommonModel {
    init: true
}

export interface AiSearchResultModel extends AiSearchCommonModel {
    init: false
    isDraft: boolean
    searchInfo: SearchInfo
    searchResult: SearchResult
    onBack: () => void
    onRetry: () => void
    currentFile: AISearchDocumentResult | null
    setCurrentFile: (file: AISearchDocumentResult) => void
    changeResourceType: (resourceType: AiSearchResourceType) => void
    changeFileOwnerCondition: (fileOwnerCondition: AISearchFileOwnerCondition) => void
    changeSortingRule: (sortingRule: AISearchSortingRule) => void
    getFileOwnerInfo: (file: AISearchDocumentResult) => UserBriefVO | null
}

export type AiSearchModel = AiSearchInitModel | AiSearchResultModel

export function useAiSearch(): AiSearchModel {
    const [positionTopCenter, setPositionTopCenter] = useState<Position>()
    const aiService = useAppContext().aiService
    const [info, setInfo] = useState<SearchInfo | null>(null)
    const [searchResult, setSearchResult] = useState<SearchResult | null>(null)
    const currentRequestId = useRef(0)
    const [currentFile, setCurrentFile] = useState<AISearchDocumentResult | null>(null)
    const { docData, folderData } = useDocInfoContext()

    const setSearchInfo = useCallback(
        (searchInfo: SearchInfo | null) => {
            if (!docData || !folderData) {
                return
            }

            const requestId = ++currentRequestId.current
            setInfo(searchInfo)
            setCurrentFile(null)
            if (!searchInfo) {
                setSearchResult(null)
            } else {
                setSearchResult({ type: 'Loading' })

                const getResourceId = () => {
                    switch (searchInfo.searchFilter.resourceType) {
                        case AiSearchResourceType.ORGANIZATION:
                            return docData.orgId

                        case AiSearchResourceType.TEAM:
                            return docData.teamId ?? ''

                        case AiSearchResourceType.FOLDER:
                            return folderData?.id ?? ''
                    }
                }

                new AISearch(
                    {
                        imageFormat: searchInfo.file.type.split('/')[1],
                        condition: {
                            fileOwnerCondition: searchInfo.searchFilter.fileOwnerCondition,
                            resourceType: searchInfo.searchFilter.resourceType,
                            resourceId: getResourceId(),
                        },
                        sortingRule: searchInfo.searchFilter.sortingRule,
                    },
                    searchInfo.file
                )
                    .start()
                    .then(({ result, documentOwnerInfo }) => {
                        if (requestId === currentRequestId.current) {
                            setSearchResult({ type: 'Success', result, documentOwnerInfo })
                            aiService.decreaseAiToolBoxCredits()
                        }
                    })
                    .catch(() => {
                        if (requestId === currentRequestId.current) {
                            setSearchResult({ type: 'Error' })
                        }
                    })
            }
        },
        [aiService, docData, folderData]
    )

    const onBack = () => {
        if (currentFile) {
            setCurrentFile(null)
        } else {
            setSearchInfo(null)
        }
    }

    const onExit = () => {
        aiService.exitAiSearch()
    }

    if (info && searchResult && docData && folderData) {
        const changeResourceType = (resourceType: AiSearchResourceType) => {
            setSearchInfo({
                ...info,
                searchFilter: { ...info.searchFilter, resourceType },
            })
        }

        const changeFileOwnerCondition = (fileOwnerCondition: AISearchFileOwnerCondition) => {
            setSearchInfo({ ...info, searchFilter: { ...info.searchFilter, fileOwnerCondition } })
        }

        const changeSortingRule = (sortingRule: AISearchSortingRule) => {
            setSearchInfo({ ...info, searchFilter: { ...info.searchFilter, sortingRule } })
        }

        const getFileOwnerInfo = (file: AISearchDocumentResult) => {
            if (searchResult.type === 'Success') {
                return searchResult.documentOwnerInfo.find((item) => item.id === file.documentVO.ownerUserId) ?? null
            }
            return null
        }

        const onRetry = () => {
            setSearchInfo(info)
        }

        return {
            init: false,
            isDraft: docData.draft ?? false,
            onExit,
            searchInfo: info,
            setSearchInfo,
            positionTopCenter,
            setPositionTopCenter,
            onBack,
            onRetry,
            searchResult,
            currentFile,
            setCurrentFile,
            changeResourceType,
            changeFileOwnerCondition,
            changeSortingRule,
            getFileOwnerInfo,
        }
    } else {
        return {
            init: true,
            onExit,
            setSearchInfo,
            positionTopCenter,
            setPositionTopCenter,
        }
    }
}
