import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Layer: '图层',
    Click: '存在隐藏图层，点击',
    ToReplace: '替换为可见',
} as const

export const enTranslation = {
    Layer: 'Layer',
    Click: 'Click',
    ToReplace: 'to replace mixed visibility',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
