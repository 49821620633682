import { Wukong } from '@wukong/bridge-proto'
import { FontInfo, FontName } from '../node/node'

export const isEqualFontName = (a: Wukong.DocumentProto.IFontName, b: Wukong.DocumentProto.IFontName) => {
    return a === b || (a.family === b.family && a.style == b.style)
}

/**
 * 比较两个字重的参数是否一致, 用于切换family时自动匹配到相同的字重
 */
export const isEqualFontStyle = (fa: FontName, fb: FontName) => {
    return (
        fa.style == fb.style ||
        fa.style.toLocaleLowerCase() === fb.style.toLocaleLowerCase() ||
        (fa.weight === fb.weight && fa.width === fb.width && fa.italic === fb.italic)
    )
}

export const getFontNameUid = (a: Wukong.DocumentProto.IFontName) => {
    return getFontNameUidCore(a.family ?? '', a.style ?? '')
}

export const getFontNameUidCore = (family: string, style: string) => {
    return `${family}-${style}`
}

export const getFontNameUidSet = (fonts: FontInfo[]) => {
    return new Set(fonts.flatMap((font) => font.styles.map(getFontNameUid)))
}
