import type { HTMLAttributes } from 'react'
import {
    Children,
    cloneElement,
    createContext,
    isValidElement,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import style from './simple-drag-inline.module.less'

// 以下代码完全拷贝 simple-drag.tsx，仅对 UI 展示做了修改（Item.props 应用了 HTMLDivElement 的原生事件）
interface SimpleDragPropsInline<T> extends HTMLAttributes<HTMLDivElement> {
    selects: readonly T[]
    items: T[]
    onDragDone?: (items: T[]) => void
    onSelectChange?: (selectItems: T[]) => void
}
const Context = createContext<any>({})
function SimpleDragInline<T>(props: SimpleDragPropsInline<T>) {
    const { onDragDone, selects, items, onSelectChange } = props
    const [sharedMouseDown, setSharedMouseDown] = useState<boolean>(false)
    const sharedMoveTarget = useRef<{ item: T; isTop: boolean }>()
    const [blackLine, setBlackLine] = useState<{ top: number; left: number }>()
    const divRef = useRef<HTMLDivElement>(null)
    const prevItem = useRef<T>()
    const sharedBlackLine = useCallback((value: { top: number; left: number }) => {
        if (!divRef.current) return
        const { top, left } = divRef.current.getBoundingClientRect()
        setBlackLine({ top: value.top - top, left: value.left - left })
    }, [])

    const sharedSelectChange = useCallback(
        (item: T, shiftKey: any, metaKey: any) => {
            let selectItems: T[]
            const selectsFromItems = items.filter((_item) => selects.includes(_item))
            if (metaKey && !shiftKey) {
                if (selectsFromItems.includes(item)) {
                    selectItems = selectsFromItems.filter((v) => v !== item)
                } else {
                    selectItems = [...selectsFromItems, item]
                }
                prevItem.current = item
            } else if (shiftKey && !metaKey) {
                let min = items.findIndex((v) => v === prevItem.current)
                let max = items.findIndex((v) => v === item)
                if (min === -1) {
                    prevItem.current = item
                    min = max
                }
                const temp = min
                min = Math.min(min, max)
                max = Math.max(temp, max)
                selectItems = items.filter((_, index) => index >= min && index <= max)
            } else {
                selectItems = [item]
                prevItem.current = item
            }
            onSelectChange?.(selectItems)
        },
        [items, onSelectChange, selects]
    )

    const _onMouseUp = useCallback(() => {
        setSharedMouseDown(false)
        setBlackLine(undefined)
    }, [])

    const _onClick = useCallback((e: any) => {
        e.stopPropagation()
    }, [])

    const _onDragDone = useCallback(
        (targetItem: T, isTop: boolean) => {
            let index = items.findIndex((item) => targetItem === item)
            if (index === -1) return
            index = isTop ? index : index + 1
            const moveList: T[] = []
            const targetList: (T | null)[] = items.map((item) => {
                if (selects.includes(item)) {
                    moveList.push(item)
                    return null
                } else {
                    return item
                }
            })
            targetList.splice(index, 0, ...moveList)
            onDragDone?.(targetList.filter((v) => v !== null) as T[])
        },
        [items, onDragDone, selects]
    )

    const _onMouseDown = useCallback((e: any) => {
        e.stopPropagation()
    }, [])

    useEffect(() => {
        const mouseup = () => {
            _onMouseUp()
            if (sharedMoveTarget.current) {
                const { item, isTop } = sharedMoveTarget.current
                _onDragDone?.(item, isTop)
                sharedMoveTarget.current = undefined
            }
        }
        window.addEventListener('mouseup', mouseup, false)
        // window.addEventListener('click', click, false)
        return () => {
            window.removeEventListener('mouseup', mouseup)
            // window.removeEventListener('click', click)
        }
    }, [_onDragDone, _onMouseUp, onDragDone, onSelectChange])

    return (
        <Context.Provider
            value={{
                sharedMouseDown,
                setSharedMouseDown,
                sharedMoveTarget,
                sharedBlackLine,
                selects,
                sharedSelectChange,
            }}
        >
            <div
                onMouseDown={_onMouseDown}
                onMouseUp={_onMouseUp}
                onClick={_onClick}
                className={`${style.container}`}
                ref={divRef}
            >
                {!!blackLine && (
                    <div
                        className={`${style.blackLine} ${sharedMouseDown ? style.preventChildrenEvent : ''}`}
                        style={blackLine}
                    />
                )}
                {props.children}
            </div>
        </Context.Provider>
    )
}

interface ItemProps extends HTMLAttributes<HTMLDivElement> {
    item: any
    dragContainerClassName?: string
    dataTest?: string
    testId?: string
}
function Item(props: ItemProps) {
    const {
        className,
        item,
        dragContainerClassName,
        dataTest,
        testId,
        onMouseDown,
        onClick,
        onMouseMove,
        children,
        ...otherProps
    } = props
    const { sharedMouseDown, selects, sharedSelectChange, setSharedMouseDown, sharedMoveTarget, sharedBlackLine } =
        useContext(Context)
    const ref = useRef<any>(null)

    const _onMouseDownHandle = useCallback(
        (e: any) => {
            setSharedMouseDown(true)
            if (!selects.includes(item) || e.shiftKey || e.metaKey) {
                sharedSelectChange?.(item, e.shiftKey, e.metaKey)
            }
        },
        [item, sharedSelectChange, selects, setSharedMouseDown]
    )

    const _onMouseDown = useCallback(
        (e: any) => {
            if (e.target === ref.current) {
                setSharedMouseDown(true)
                if (!selects.includes(item) || e.shiftKey || e.metaKey) {
                    sharedSelectChange?.(item, e.shiftKey, e.metaKey)
                }
            }
            onMouseDown?.(e)
        },
        [onMouseDown, setSharedMouseDown, selects, item, sharedSelectChange]
    )

    const _onClick = useCallback(
        (e: any) => {
            if (
                (selects.length === 1 && selects.includes(item)) ||
                e.shiftKey ||
                e.metaKey ||
                e.target !== e.currentTarget
            ) {
                return
            }
            sharedSelectChange?.(item, e.shiftKey, e.metaKey)
            onClick?.(e)
        },
        [selects, item, sharedSelectChange, onClick]
    )

    const _onMouseMove = useCallback(
        (e: any) => {
            if (!sharedMouseDown) return
            const { clientX } = e
            const { top, left, width } = e.currentTarget.getBoundingClientRect()

            if (clientX - left > width / 2) {
                sharedBlackLine({ top: top + 10, left: left + width - 5 })
                sharedMoveTarget.current = { item, isTop: false }
            } else {
                sharedBlackLine({ top: top + 10, left: left - 5 })
                sharedMoveTarget.current = { item, isTop: true }
            }
            onMouseMove?.(e)
        },
        [sharedMouseDown, onMouseMove, sharedBlackLine, sharedMoveTarget, item]
    )

    return (
        <div
            {...otherProps}
            onMouseDown={_onMouseDown}
            onMouseMove={_onMouseMove}
            onClick={_onClick}
            className={`${style.item} ${className ?? ''} ${sharedMouseDown ? style.preventChildrenEvent : ''}`}
            data-testid={dataTest}
        >
            <div
                className={`${style.dragHandle} ${dragContainerClassName ?? ''}`}
                onMouseDown={_onMouseDownHandle}
                data-testid={testId}
            >
                {Children.map(children, (child) => {
                    if (isValidElement(child)) {
                        // @ts-expect-error
                        return cloneElement(child, { ref })
                    }
                    return child
                })}
            </div>
        </div>
    )
}

SimpleDragInline.Item = Item

export { SimpleDragInline }
