import {
    PluginApiGetName,
    PluginApiGetProps,
    PluginApiGetPublishStatusCommand,
    PluginApiGetTypeCommand,
    PluginApiIsRemoteLibraryNode,
    PluginApiRemoveNode,
    PluginApiSetName,
    PluginApiSetProps,
    Wukong,
} from '@wukong/bridge-proto'
import { z } from 'zod'
import { DeepRequired, PrototypePropMapper } from '../mapper'
import { NodePropDescriptor } from '../plugin-api-context-interface'
import { Handle } from '../vm-interface'
import { ZodTypes } from '../zod-type'
import { BaseNodePrototype } from './base-node-prototype'

export class StyleNodePrototype extends BaseNodePrototype {
    paintStylePrototype!: Handle
    effectStylePrototype!: Handle
    gridStylePrototype!: Handle
    textStylePrototype!: Handle

    override createNodePrototypes(): void {
        const _ = this.createSceneNodePropDescriptor()
        for (const key in _) {
            // @ts-expect-error
            _[key].key = key
        }

        const publishableMixin = [_.description, _.key, _.getPublishStatusAsync, _.remote]
        const baseStyleMixin = [...publishableMixin, _.name, _.remove]

        const paintStyleMixin = [...baseStyleMixin, _.paints]

        const effectStyleMixin = [...baseStyleMixin, _.effects]

        const gridStyleMixin = [...baseStyleMixin, _.layoutGrids]

        const textStyleMixin = [
            ...baseStyleMixin,
            _.fontSize,
            _.fontName,
            _.textDecoration,
            _.letterSpacing,
            _.lineHeight,
            _.paragraphSpacing,
        ]

        this.paintStylePrototype = this.createNodePrototype('PaintStyle', 'PAINT', paintStyleMixin)
        this.effectStylePrototype = this.createNodePrototype('EffectStyle', 'EFFECT', effectStyleMixin)
        this.gridStylePrototype = this.createNodePrototype('GridStyle', 'GRID', gridStyleMixin)
        this.textStylePrototype = this.createNodePrototype('TextStyle', 'TEXT', textStyleMixin)
    }

    override createNodeHandle(id: string): Handle {
        if (this.nodeCache.has(id)) {
            return this.nodeCache.get(id)!
        }

        const nodeType = this.ctx.callBridge(PluginApiGetTypeCommand, { nodeId: id }).type!
        const type = PrototypePropMapper.StyleNodeType.fromMotiff(nodeType)
        let ret: Handle
        switch (type) {
            case 'PAINT':
                ret = this.ctx.vm.newObject(this.paintStylePrototype)
                break
            case 'EFFECT':
                ret = this.ctx.vm.newObject(this.effectStylePrototype)
                break
            case 'GRID':
                ret = this.ctx.vm.newObject(this.gridStylePrototype)
                break
            case 'TEXT':
                ret = this.ctx.vm.newObject(this.textStylePrototype)
                break
            default:
                throw new Error('暂不支持的 styleNodeType')
        }

        this.ctx.vm.defineProp(ret, 'id', {
            value: this.ctx.vm.newString(id),
            writable: false,
            enumerable: true,
        })

        this.nodeCache.set(id, ret)
        this.ctx.vm.retainHandle(ret)
        return ret
    }

    createSceneNodePropDescriptor() {
        const self = this
        const ctx = this.ctx
        const vm = ctx.vm

        // just for type check
        const r = (descriptors: NodePropDescriptor) => {
            return descriptors
        }

        const getNodeId = (node: Handle) => {
            if (!vm.isObject(node)) {
                throw Error(`Expected node, got ${vm.isNull(node) ? 'null' : vm.typeof(node)}`)
            }
            const id = vm.getProp(node, 'id')
            if (!vm.isString(id)) {
                throw Error(`Expected node id to be a string, got ${vm.typeof(id)}`)
            }
            return vm.getString(id)
        }

        const createSimpleProp = <K extends keyof Wukong.DocumentProto.IApiProps>(name: K, zodType: z.ZodType) => {
            return r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: [name],
                    }).value?.[name]
                    return vm.deepWrapHandle(result)
                },
                set: function (valueHandle: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const value = ctx.unwrapAndValidate({
                        handle: valueHandle,
                        type: zodType,
                        key: name,
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            [name]: value,
                        }),
                        targetPropName: name,
                    })
                },
            })
        }

        return {
            name: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const name = ctx.callBridge(PluginApiGetName, { nodeId }).value!
                    return vm.newString(name)
                },
                set: function (valueHandle: Handle) {
                    const value = ctx.unwrapAndValidate({
                        handle: valueHandle,
                        type: ZodTypes.String,
                        key: 'name',
                    })
                    const nodeId = getNodeId(this)
                    ctx.unwrapCallBridge(PluginApiSetName, { nodeId, name: value })
                },
            }),
            remove: r({
                func: function () {
                    const nodeId = getNodeId(this)
                    ctx.callBridge(PluginApiRemoveNode, { nodeId })
                },
            }),
            getPublishStatusAsync: r({
                func: function () {
                    const nodeId = getNodeId(this)
                    const { promise, resolve } = ctx.vm.newPromise()
                    const statusType = ctx.callBridge(PluginApiGetPublishStatusCommand, { nodeId }).status
                    switch (statusType) {
                        case Wukong.DocumentProto.PublishStatus.PUBLISH_STATUS_UNPUBLISHED:
                            resolve(vm.newString('UNPUBLISHED'))
                            break
                        case Wukong.DocumentProto.PublishStatus.PUBLISH_STATUS_CURRENT:
                            resolve(vm.newString('CURRENT'))
                            break
                        case Wukong.DocumentProto.PublishStatus.PUBLISH_STATUS_CHANGED:
                            resolve(vm.newString('CHANGED'))
                            break
                        default:
                            throw new Error('return type error')
                    }
                    return promise
                },
            }),
            layoutGrids: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const mapper = PrototypePropMapper.LayoutGrids
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['layoutGrids'],
                    }).value?.layoutGrids
                    return vm.deepWrapHandle(mapper.fromMotiff(ret as any))
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const mapper = PrototypePropMapper.LayoutGrids
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.LayoutGrids,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            layoutGrids: mapper.fromFigma(value),
                        }),
                        targetPropName: 'layoutGrids',
                    })
                },
            }),
            paints: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const mapper = PrototypePropMapper.Paints
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['paints'],
                    }).value?.paints
                    return vm.deepWrapHandle(mapper.fromMotiff(ret as any))
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const mapper = PrototypePropMapper.Paints
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.Paints,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            paints: mapper.fromFigma(value as any),
                        }),
                        targetPropName: 'paints',
                    })
                },
            }),
            effects: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const mapper = PrototypePropMapper.Effects
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['effects'],
                    }).value?.effects
                    return vm.deepWrapHandle(mapper.fromMotiff(ret as any))
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const mapper = PrototypePropMapper.Effects
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.Effects,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            effects: mapper.fromFigma(value as any),
                        }),
                        targetPropName: 'effects',
                    })
                },
            }),
            fontSize: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['fontSize'],
                    }).value?.fontSize

                    if (result.mixed) {
                        return ctx.mixedSymbol
                    }

                    return vm.deepWrapHandle(result.value)
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: z.number().min(1).finite(),
                        key: 'fontSize',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            fontSize: { mixed: false, value },
                        }),
                        targetPropName: 'fontSize',
                    })
                },
            }),
            fontName: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['fontName'],
                    }).value?.fontName

                    if (result.mixed) {
                        return ctx.mixedSymbol
                    }

                    return vm.deepWrapHandle(result.value)
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.FontName,
                        key: 'fontName',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            fontName: { mixed: false, value },
                        }),
                        targetPropName: 'fontName',
                    })
                },
            }),
            textDecoration: r({
                get: function () {
                    const mapper = PrototypePropMapper.TextDecoration
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['textDecoration'],
                    }).value?.textDecoration
                    if (result.mixed) {
                        return ctx.mixedSymbol
                    }
                    return vm.deepWrapHandle(mapper.fromMotiff(result.value))
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const mapper = PrototypePropMapper.TextDecoration
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.TextDecoration,
                        key: 'textDecoration',
                    })
                    const motiffValue = mapper.fromFigma(value)
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            textDecoration: { mixed: false, value: motiffValue },
                        }),
                        targetPropName: 'textDecoration',
                    })
                },
            }),
            letterSpacing: r({
                get: function () {
                    const mapper = PrototypePropMapper.LetterSpacing
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['letterSpacing'],
                    }).value?.letterSpacing
                    if (result.mixed) {
                        return ctx.mixedSymbol
                    }

                    type ResultType = DeepRequired<typeof result.value>
                    return vm.deepWrapHandle(mapper.fromMotiff(result.value as ResultType))
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const mapper = PrototypePropMapper.LetterSpacing
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.LetterSpacing,
                        key: 'letterSpacing',
                    })
                    const motiffValue = mapper.fromFigma(value)
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            letterSpacing: { mixed: false, value: motiffValue },
                        }),
                        targetPropName: 'letterSpacing',
                    })
                },
            }),
            lineHeight: r({
                get: function () {
                    const mapper = PrototypePropMapper.LineHeight
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['lineHeight'],
                    }).value?.lineHeight
                    if (result.mixed) {
                        return ctx.mixedSymbol
                    }
                    type ResultType = DeepRequired<typeof result.value>
                    return vm.deepWrapHandle(mapper.fromMotiff(result.value as ResultType))
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const mapper = PrototypePropMapper.LineHeight
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.LineHeight,
                        key: 'lineHeight',
                    })
                    const motiffValue = mapper.fromFigma(value)
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            lineHeight: { mixed: false, value: motiffValue },
                        }),
                        targetPropName: 'lineHeight',
                    })
                },
            }),
            paragraphSpacing: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['paragraphSpacing'],
                    }).value?.paragraphSpacing
                    return vm.deepWrapHandle(result)
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.ParagraphSpacing,
                        key: 'paragraphSpacing',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            paragraphSpacing: value === null ? undefined : value,
                        }),
                        targetPropName: 'paragraphSpacing',
                    })
                },
            }),
            remote: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiIsRemoteLibraryNode, { value: nodeId })
                    return vm.newBoolean(ret.value)
                },
            }),
            key: createSimpleProp('key', z.string()),
            description: createSimpleProp('description', z.string()),
        }
    }
}
