/* eslint-disable no-restricted-imports */
import { isNil } from 'lodash-es'
import { useCallback, useState } from 'react'
import type { NodeId } from '../../../../../document/node/node'
import { StyleWithoutDocIdGetVO } from '../../../../../kernel/interface/component-style'
import { useLibraryComponentService } from '../../../../../main/app-context'
import {
    buildThumbnailDataFromVO,
    LibraryThumbnailImage,
} from '../../../../../share/component-style-library/library-thumbnail-image'
import { LibraryResourceOssClientType } from '../../../../../share/component-style-library/service/library-resource-downloader'
import { LibraryStyleTestId } from '../../../../../window'
import { getStyleShortName } from '../../style/style-util'
import { CommonStyleInfo } from '../get-style-nodes-info-map'
import { StyleListItem, StyleListItemKeyboardProps } from '../style-items-layout/style-list-item'
import style from './style-text-list-item.module.less'
import { transformFontSizeForUi, transformLineHeightForUi } from './style-text-utils'

interface Props {
    item: StyleWithoutDocIdGetVO
    docId: string
    selectStyleId: string | undefined
    selectStyleKey: string
    openStyleId?: string
    className?: string
    disabledOnClick?: boolean
    children?: React.ReactNode
    onSelectItem: (item: CommonStyleInfo) => void
    onClickHoverIcon?: (
        item: CommonStyleInfo,
        containerDomRect: DOMRect,
        remoteStyleId?: string,
        docId?: string
    ) => void
    onContextMenu?: (item: CommonStyleInfo, e: any, remoteStyleId?: string, docId?: string) => void
}

export function RemoteStyleTextListItem(props: Props & StyleListItemKeyboardProps) {
    const { item, selectStyleId, selectStyleKey, openStyleId, docId, onSelectItem, onClickHoverIcon, onContextMenu } =
        props
    const { name, description } = props.item
    const [styleNodeId, setStyleNodeId] = useState<NodeId | null>(null)

    const libraryComponentService = useLibraryComponentService()

    const getStyleNodeId = useCallback(async () => {
        if (styleNodeId) {
            return styleNodeId
        }

        const newStyleNodeId = await libraryComponentService.libraryNodeDataService.createRemoteStyleNode({
            isLocal: false,
            localNodeId: null,
            ossClientType: LibraryResourceOssClientType.Style,
            remoteDocId: docId,
            remoteNodeId: item.nodeId,
            nodeDataPath: item.nodeDataPath ?? '',
            key: item.id,
        })

        if (newStyleNodeId) {
            setStyleNodeId(newStyleNodeId)
        }

        return newStyleNodeId
    }, [styleNodeId, libraryComponentService, docId, item])

    const [isMiss] = useState<boolean>(false)

    return (
        <StyleListItem
            item={{
                id: selectStyleId && item.id === selectStyleKey ? selectStyleId : styleNodeId || '',
                name: name || '',
                description: description || '',
                groupName: '',
                styleName: '',
            }}
            isReadonly
            openStyleId={openStyleId}
            disabledOnClick={isMiss}
            onSelectItem={async (value) => {
                const styleId = await getStyleNodeId()
                if (styleId) {
                    value.id = styleId
                    onSelectItem(value)
                }
            }}
            onClickHoverIcon={
                onClickHoverIcon &&
                (async (itemValue, containerDomRect) => {
                    const styleId = await getStyleNodeId()
                    if (styleId) {
                        itemValue.id = styleId
                        setTimeout(() => onClickHoverIcon(itemValue, containerDomRect, item.nodeId, docId))
                    }
                })
            }
            className={isMiss ? style.isMiss : undefined}
            onContextMenu={(itemValue, e) => {
                onContextMenu?.(itemValue, e, item.nodeId, docId)
            }}
            dataTestId={LibraryStyleTestId.RemoteTextStyleItem}
            index={props.index}
            selectStyleId={props.selectStyleId}
            setPreselectIndex={props.setPreselectIndex}
            trySetPreselectIndex={props.trySetPreselectIndex}
            recordEnterCallback={props.recordEnterCallback}
        >
            <LibraryThumbnailImage thumbnailData={buildThumbnailDataFromVO(item)} className={style.thumbnail} />
            <div className={style.name}>{getStyleShortName(item.name || '')}</div>
            {!isNil(item?.fontSize) && !isNil(item?.lineHeightValue) && !isNil(item?.lineHeightUnit) && (
                <div className={style.textInfo}>
                    {transformFontSizeForUi(item.fontSize)}/
                    {transformLineHeightForUi({
                        value: item.lineHeightValue,
                        unit: item.lineHeightUnit,
                    })}
                </div>
            )}
        </StyleListItem>
    )
}
