/* eslint-disable no-restricted-imports */
import {
    ClipboardReadFilesCommand,
    ClipboardReadImageInfosCommand,
    ClipboardReadItemsCommand,
    ClipboardReadProxyFiles,
    ClipboardWriteCanvasImageBlob,
    ClipboardWriteFilesCommand,
    ClipboardWriteItemsCommand,
    OnClipboardReadProxyFilesCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import type { CanvasRenderBridge } from '../../../document/bridge/canvas-render-bridge'
import type { CommandInvoker } from '../../../document/command/command-invoker'
import { EmBridge } from '../../../kernel/bridge/em-bridge'
import { WkCLog } from '../../../kernel/clog/wukong/instance'
import { ClipboardDataManager } from './clipboard-data-manager'
import {
    ClipboardDataFile,
    ClipboardDataItem,
    ClipboardDataPromiseFile,
    WKClipboardError,
    WKClipboardErrorType,
} from './clipboard-data-types'

class AsyncTask {
    private promiseResolve!: (value?: unknown) => void
    private promiseReject!: (value?: unknown) => void
    private promise: Promise<any>
    private taken = false

    constructor(public readonly id: number, private asyncTaskManager: AsyncTaskManager) {
        this.promise = new Promise((res, rej) => {
            this.promiseResolve = res
            this.promiseReject = rej
        })
    }

    public takeHandlers = () => {
        this.taken = true
        return [this.promiseResolve, this.promiseReject] as const
    }

    public isTaken = () => {
        return this.taken
    }

    public waitAndDestroy = async (): Promise<void> => {
        const afterFinally = () => {
            this.asyncTaskManager.removeTask(this.id)
        }
        if (this.taken) {
            return this.promise.finally(() => {
                afterFinally()
            })
        }
        // 没有被调用 takeHandlers，可以直接 resolve
        this.promiseResolve()
        afterFinally()
        return Promise.resolve()
    }
}

class AsyncTaskManager {
    private alloc_ = 0
    private map_ = new Map<number, AsyncTask>()

    public newTask(): AsyncTask {
        const alloc = ++this.alloc_
        const task = new AsyncTask(alloc, this)
        this.map_.set(alloc, task)
        return task
    }

    public getTask(id: number): AsyncTask | null {
        return this.map_.get(id) ?? null
    }

    public removeTask(id: number) {
        this.map_.delete(id)
    }

    public size(): number {
        return this.map_.size
    }
}

export class ClipboardWasmConnector {
    public readonly asyncTaskManager = new AsyncTaskManager()
    constructor(
        private readonly bridge: EmBridge,
        private commandInvoker: CommandInvoker,
        private canvasRenderBridge: CanvasRenderBridge,
        private clipboardDataManager: ClipboardDataManager
    ) {
        bridge.bind(ClipboardReadItemsCommand, this.readWkClipboardItems.bind(this))
        bridge.bind(ClipboardReadFilesCommand, this.readWkClipboardFiles.bind(this))
        bridge.bind(ClipboardWriteFilesCommand, this.writeWkClipboardFiles.bind(this))
        bridge.bind(ClipboardReadImageInfosCommand, this.readWkClipboardImageInfos.bind(this))
        bridge.bind(ClipboardWriteCanvasImageBlob, this.writeCanvasImageBlobs.bind(this))
        bridge.bind(ClipboardReadProxyFiles, this.readClipboardReadProxyFiles.bind(this))
        bridge.bind(ClipboardWriteItemsCommand, this.writeWkClipboardItems.bind(this))
    }

    public destroy() {
        this.bridge.unbind(ClipboardReadItemsCommand)
        this.bridge.unbind(ClipboardReadFilesCommand)
        this.bridge.unbind(ClipboardWriteFilesCommand)
        this.bridge.unbind(ClipboardReadImageInfosCommand)
        this.bridge.unbind(ClipboardWriteCanvasImageBlob)
        this.bridge.unbind(ClipboardReadProxyFiles)
        this.bridge.unbind(ClipboardWriteItemsCommand)
    }

    public readWkClipboardFiles(): Wukong.DocumentProto.IRet_clipboardReadFiles {
        return {
            files: this.clipboardDataManager.dataTransfer.files,
        }
    }

    public writeWkClipboardFiles(
        args: Wukong.DocumentProto.IArg_clipboardWriteFiles
    ): Wukong.DocumentProto.IBridgeProtoBoolean {
        const toWriteClipboardDataFiles: ClipboardDataFile[] = []
        if (args.files) {
            for (const item of args.files) {
                if (item.type && item.buffer && !isNil(item.name) && item.size) {
                    toWriteClipboardDataFiles.push({
                        type: item.type,
                        name: item.name,
                        buffer: item.buffer,
                        size: item.size,
                    })
                }
            }
        }
        this.clipboardDataManager.writeClipboardFiles(toWriteClipboardDataFiles)
        return {
            value: Boolean(true),
        }
    }

    public writeWkClipboardItems(
        args: Wukong.DocumentProto.IArg_clipboardWriteItems
    ): Wukong.DocumentProto.IBridgeProtoBoolean {
        const toWriteClipboardDataItems: ClipboardDataItem[] = []
        if (args.items) {
            for (const item of args.items) {
                if (item.type && item.ptr && item.size) {
                    const buf = new Uint8Array(this.bridge.currentEditorService.HEAPU8.buffer, item.ptr, item.size)
                    // 用 Buffer.from(...).toString('utf-8') 在数据量大的时候会导致浏览器自身 crash，这里用 TextDecoder
                    const content = new TextDecoder().decode(buf)
                    toWriteClipboardDataItems.push({
                        type: item.type,
                        content: content,
                    })
                }
            }
        }
        this.clipboardDataManager.writeClipboardItems(toWriteClipboardDataItems)
        return {
            value: true,
        }
    }

    public readWkClipboardItems(): Wukong.DocumentProto.IRet_clipboardReadItems {
        const dataTransfer = this.clipboardDataManager.dataTransfer
        if (dataTransfer === null) {
            throw new Error('dataTransfer is null')
        }

        const retItems: Wukong.DocumentProto.IWkClipboardItem[] = dataTransfer.getItems()

        return {
            items: retItems,
        }
    }

    public readWkClipboardImageInfos(): Wukong.DocumentProto.IRet_clipboardReadImageInfos {
        const data = this.clipboardDataManager.dataTransfer.imageInfos
        const imageInfos = data.map((v) => v.imageInfo)
        this.clipboardDataManager.dataTransfer.trace('read-wk-cilpboard-image-infos')
        return {
            imageInfos,
        }
    }

    public writeCanvasImageBlobs(
        args: Wukong.DocumentProto.IArg_clipboardWriteCanvasImageBlobs
    ): Wukong.DocumentProto.IBridgeProtoBoolean {
        const asyncTask = this.asyncTaskManager.getTask(args.asyncId!)
        if (!asyncTask) {
            WkCLog.log('WK_COPY_ERROR', { msg: `asyncTask ${args.asyncId} not found` })
            return { value: false }
        }
        if (asyncTask.isTaken()) {
            WkCLog.log('WK_COPY_ERROR', { msg: `asyncTask ${args.asyncId} is already taken` })
            return { value: false }
        }

        const [waitForDownloadRes, waitForDownloadRej] = asyncTask.takeHandlers()
        const items = args.items ?? []
        if (items.length === 0) {
            waitForDownloadRes()
            return {
                value: false,
            }
        }

        const firstItem = items[0]
        if (firstItem.resourceId) {
            const meta = this.canvasRenderBridge.fetchImageMeta(firstItem.resourceId)
            if (meta) {
                const { width, height } = meta
                if (width > 4096 * 2 || height > 4096 * 2) {
                    this.canvasRenderBridge.fetchImageBlob(firstItem.resourceId)?.finally(() => {
                        waitForDownloadRej(new WKClipboardError(WKClipboardErrorType.CopyImageLimitSize, null))
                    })
                    return {
                        value: false,
                    }
                }
            }
        }

        const writeCanvasImageBlobsImpl = async () => {
            const generateToWriteClipboardDataFiles = (() => {
                const item = firstItem
                const resourceId = item.resourceId
                const type = item.type!
                const name = item.name ?? ''
                let buffer: Promise<Uint8Array> | null = null
                if (!isNil(resourceId) && type === 'image/png') {
                    const imageInfo = this.canvasRenderBridge.fetchImageInfo(resourceId)
                    if (imageInfo) {
                        buffer = imageInfo.then((result) => {
                            return result.file.arrayBuffer().then((buf) => new Uint8Array(buf))
                        })
                    }
                }

                return (withImageForColorProfile: boolean): ClipboardDataPromiseFile[] => {
                    if (!buffer) {
                        return [
                            {
                                type,
                                name,
                                buffer: Promise.reject<Uint8Array>('writeCanvasImageBlobs'),
                            },
                        ]
                    }

                    const generateItem = (mimeType: string): ClipboardDataPromiseFile => {
                        return {
                            type: mimeType,
                            name,
                            buffer,
                        }
                    }

                    if (withImageForColorProfile) {
                        return [generateItem('image/png'), generateItem('web image/png')]
                    } else {
                        return [generateItem('image/png')]
                    }
                }
            })()

            // 第一次复制，复制两张图
            try {
                await this.clipboardDataManager.writeClipboardPromiseFiles(generateToWriteClipboardDataFiles(true))
                waitForDownloadRes()
                return
            } catch (_err) {}

            // 如果第一次复制不成功，那么进行第二次复制，只复制标准 PNG
            try {
                await this.clipboardDataManager.writeClipboardPromiseFiles(generateToWriteClipboardDataFiles(false))
                waitForDownloadRes()
            } catch (err) {
                console.error(err)
                waitForDownloadRej(err)
            }
        }

        writeCanvasImageBlobsImpl()
        return {
            value: true,
        }
    }

    public async readClipboardReadProxyFiles(arg: Wukong.DocumentProto.IArg_clipboardReadProxyFiles) {
        const blobIds = arg.blobIds ?? []
        const callbackId = arg.callbackId
        if (isNil(callbackId)) {
            return
        }

        const files: Wukong.DocumentProto.IWkClipboardFile[] = []
        for (const blobId of blobIds) {
            const file = await this.clipboardDataManager.dataTransfer.getFileByBlobId(blobId)
            if (file) {
                files.push(file)
            }
        }

        const ret: Wukong.DocumentProto.IArg_onClipboardReadProxyFiles = {
            files,
            callbackId,
        }
        this.commandInvoker.DEPRECATED_invokeBridge(OnClipboardReadProxyFilesCommand, ret)
    }
}
