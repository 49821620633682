/* eslint-disable no-restricted-imports */
import { forwardRef } from 'react'
import { DragMoveBox, DragMoveBoxProps } from '../../../../../../ui-lib/src'
import classes from './dot-range.module.less'

interface DotProps extends Omit<DragMoveBoxProps, 'className'> {
    dataTestId?: string
}

export function _Dot(props: DotProps, outRef: React.Ref<HTMLDivElement>) {
    return <DragMoveBox ref={outRef} className={classes.dot} {...props} />
}
export const Dot = forwardRef(_Dot)
