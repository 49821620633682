/**
 * @owner: loushuangzhanbj@kanyun.com
 */
import classnames from 'classnames'
import React, { forwardRef, useMemo } from 'react'
import type { DivType } from '../../../../util/src'
import { IconCheckboxChecked, IconLock } from '../../svg-icon/16/common'
import { Tooltip, TooltipProps, useEllipsisTooltip } from '../tooltip'
import classes from './custom-item.module.less'

export interface CustomItemProps extends DivType {
    isSelect?: boolean
    disabled?: boolean
    forwardIcon?: any
    forwardChildren?: React.ReactNode
    backwardChildren?: React.ReactNode
    backwardIcon?: any
    rightContainerWithoutSvgFill?: boolean
    forwardIconClassName?: string
    forwardChildrenClassName?: string
    backwardChildrenClassName?: string
    backwardIconClassName?: string // 可以删除
    centerContainerClassName?: string
    backwardIconStyle?: React.CSSProperties
    selectContainerClassName?: string
    selectContainerStyle?: React.CSSProperties
    showCheckBoxAndForwardIcon?: boolean
    isLocked?: boolean
    isHover?: boolean
    tooltipInactivation?: TooltipProps['inactivation']
    tooltipTitle?: TooltipProps['title']
    placement?: TooltipProps['placement']
    addRightPadding?: boolean
    splitLineTop?: boolean
    splitLineBottom?: boolean
    isInteractive?: boolean
    isInteractiveClassName?: string
    dataTestId?: string
    dataTestIds?: {
        isSelectOrCheckbox?: string
    }
}

export const CustomItem = forwardRef((props: CustomItemProps, ref?: any) => {
    const {
        className,
        isInteractiveClassName,
        centerContainerClassName,
        disabled,
        backwardIcon,
        forwardIcon,
        isSelect,
        forwardIconClassName,
        forwardChildrenClassName,
        backwardChildrenClassName,
        backwardIconClassName,
        backwardIconStyle,
        selectContainerClassName,
        selectContainerStyle,
        dataTestId,
        dataTestIds,
        showCheckBoxAndForwardIcon,
        isLocked,
        isHover,
        tooltipInactivation,
        tooltipTitle,
        placement,
        addRightPadding,
        rightContainerWithoutSvgFill,
        splitLineTop,
        splitLineBottom,
        isInteractive,
        forwardChildren,
        backwardChildren,
        ...otherProps
    } = props

    const getCheckBoxOrLockIcon = useMemo(() => {
        if (!isLocked && isSelect === undefined) return null
        const icon = isSelect ? (
            <IconCheckboxChecked data-testid="custom-item-select-icon" />
        ) : isLocked ? (
            <IconLock />
        ) : null
        return (
            <span
                className={classnames(classes.selectContainer, [selectContainerClassName])}
                style={selectContainerStyle}
                data-testid={dataTestIds?.isSelectOrCheckbox}
            >
                {icon}
            </span>
        )
    }, [dataTestIds?.isSelectOrCheckbox, isLocked, isSelect, selectContainerClassName, selectContainerStyle])

    const { inactivation, ellipsisRef, onmouseenter, onmouseleave } = useEllipsisTooltip<HTMLDivElement>()

    return (
        <Tooltip
            title={tooltipTitle}
            inactivation={!tooltipTitle || (tooltipInactivation ?? inactivation)}
            placement={placement}
            addContainer={disabled ? { onMouseEnter: onmouseenter, onMouseLeave: onmouseleave } : undefined}
        >
            <div
                ref={ref}
                className={classnames(classes.container, [className], {
                    [classes.disabled]: disabled,
                    [classes.layerSelection]: showCheckBoxAndForwardIcon,
                    [classes.isHover]: isHover,
                    [classes.splitLineTop]: splitLineTop,
                    [classes.splitLineBottom]: splitLineBottom,
                    [classes.isInteractive]: isInteractive,
                    ...(isInteractiveClassName ? { [isInteractiveClassName]: isInteractive } : {}),
                })}
                data-testid={dataTestId}
                {...otherProps}
            >
                {getCheckBoxOrLockIcon}
                {forwardIcon !== undefined ? (
                    <span
                        className={classnames(classes.leftContainer, [forwardIconClassName], {
                            [classes.checkboxAndForwardIcon]: showCheckBoxAndForwardIcon,
                        })}
                    >
                        {forwardIcon}
                    </span>
                ) : null}
                {forwardChildren ? (
                    <div className={classnames(classes.forwardChildren, [forwardChildrenClassName])}>
                        {forwardChildren}
                    </div>
                ) : null}
                <div
                    className={classnames(classes.centerContainer, centerContainerClassName)}
                    ref={ellipsisRef}
                    onMouseEnter={onmouseenter}
                    onMouseLeave={onmouseleave}
                >
                    {props.children}
                </div>
                {backwardChildren ? (
                    <div className={classnames(classes.backwardChildren, [backwardChildrenClassName])}>
                        {backwardChildren}
                    </div>
                ) : null}
                {backwardIcon !== undefined ? (
                    <span
                        className={classnames(
                            rightContainerWithoutSvgFill
                                ? classes.rightContainerWithoutSvgFill
                                : classes.rightContainer,
                            [backwardIconClassName]
                        )}
                        style={backwardIconStyle}
                    >
                        {backwardIcon}
                    </span>
                ) : addRightPadding ? (
                    <span className={classes.addRightPadding}></span>
                ) : null}
            </div>
        </Tooltip>
    )
})
CustomItem.displayName = 'CustomItem'
