/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { useCallback, useEffect, useState } from 'react'
import { CommitType } from '../../../../document/command/commit-type'
import { cmdChangePopupState } from '../../../../document/command/document-command'
import type { ColorStop, RGB, Transform } from '../../../../document/node/node'
import { PopupStateType } from '../../../../document/node/node'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { getDefaultSolidPaint, isGradientType } from '../../../utils/switch-paint-type'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { useSelectionPaintModel } from './hooks/use-selection-paint-model'
import PaintType = Wukong.DocumentProto.PaintType

export function useSelectColor() {
    const command = useCommand()

    const [isShowAll, setIsShowAll] = useState<boolean>(false)
    const [isShowAllStyle, setIsShowAllStyle] = useState<boolean>(false)
    const onClickShowAll = useCallback(() => {
        setIsShowAll(true)
    }, [])
    const onClickShowAllStyle = useCallback(() => {
        setIsShowAllStyle(true)
    }, [])

    const { modelState, modelCommand } = useSelectionPaintModel()

    const popupState = useViewState('popupState')

    const isOpenV2 = (index: number) => {
        if (
            popupState &&
            popupState.type === PopupStateType.POPUP_STATE_TYPE_SELECT_COLOR &&
            modelState.paintInfos &&
            modelState.paintVarInfos
        ) {
            return (
                popupState.reciprocalIndex ===
                modelState.paintVarInfos.length + modelState.paintInfos.length - 1 - index
            )
        }
        return false
    }

    // NOTE: 打开颜色编辑弹窗时暂停更新选中颜色
    const { cmdUpdateFreezeSelectionPaint } = modelCommand
    useEffect(() => {
        if (popupState?.type === PopupStateType.POPUP_STATE_TYPE_SELECT_COLOR) {
            cmdUpdateFreezeSelectionPaint(true)
        } else {
            cmdUpdateFreezeSelectionPaint(false)
        }
    }, [cmdUpdateFreezeSelectionPaint, popupState?.type])

    const changePartialPaintV2 =
        (index: number) =>
        (partialPaint: Partial<Wukong.DocumentProto.IPaint>, commitType: CommitType = CommitType.CommitUndo) => {
            const paintInfo =
                index < modelState.paintVarInfos.length
                    ? modelState.paintVarInfos[index]
                    : modelState.paintInfos[index - modelState.paintVarInfos.length]
            if (paintInfo) {
                const newPaint = Object.assign({}, paintInfo.paint, partialPaint)
                modelCommand.cmdSelectionColorsReplaceColorV2(paintInfo.originPaint, newPaint, commitType)
            }
        }

    const onChangeColorStopsV2 = (index: number) => (v: ColorStop[], options?: InputOptionsForUndoSquash) => {
        changePartialPaintV2(index)({ gradientStops: v }, options?.commitType)
    }

    const onChangeTransformV2 = (index: number) => (v: Transform) => {
        changePartialPaintV2(index)({ gradientTransform: v })
    }

    const onChangeColorV2 = (index: number) => (color: RGB, options?: InputOptionsForUndoSquash) => {
        changePartialPaintV2(index)({ color, colorVar: null }, options?.commitType)
    }

    const onChangeOpacityV2 = (index: number) => (opacity: number, options?: InputOptionsForUndoSquash) => {
        changePartialPaintV2(index)({ opacity, colorVar: null }, options?.commitType)
    }

    const onChangeBlendModeV2 = (index: number) => (blendMode: Wukong.DocumentProto.BlendMode) => {
        changePartialPaintV2(index)({ blendMode, colorVar: null })
    }

    const onDetachColorVar = (index: number) => () => {
        const paintInfo = modelState.paintVarInfos[index]
        if (paintInfo) {
            const newPaint = Object.assign({}, paintInfo.paint, { colorVar: null })
            modelCommand.cmdSelectionColorsDetachVariable(paintInfo.originPaint, newPaint)
        }
        // 分离变量后，关闭颜色弹窗
        if (popupState?.type === PopupStateType.POPUP_STATE_TYPE_SELECT_COLOR) {
            command.invoke(cmdChangePopupState, {
                type: PopupStateType.POPUP_STATE_TYPE_NONE,
                reciprocalIndex: -1,
                multiPopup: [],
            })
        }
    }

    const onChangeModalVisibleV2 = (index: number) => (visible: boolean) => {
        const isColorVar = index < modelState.paintVarInfos.length
        const paintInfo = !isColorVar ? modelState.paintInfos[index - modelState.paintVarInfos.length] : undefined
        if (visible) {
            const reciprocalIndex = modelState.paintVarInfos.length + modelState.paintInfos.length - index - 1
            command.invoke(cmdChangePopupState, {
                type: PopupStateType.POPUP_STATE_TYPE_SELECT_COLOR,
                reciprocalIndex,
                multiPopup: [],
            })
            paintInfo && modelCommand.startEditingGradient(paintInfo.usingInfos)
        } else if (isOpenV2(index)) {
            paintInfo && isGradientType(paintInfo.paint.type) && modelCommand.endEditingGradient()
            command.invoke(cmdChangePopupState, {
                type: PopupStateType.POPUP_STATE_TYPE_NONE,
                reciprocalIndex: -1,
                multiPopup: [],
            })
        }
    }

    const onChangePaintTypeV2 =
        (index: number) => (nextPaintType: PaintType, nextPaint: Wukong.DocumentProto.IPaint | undefined | null) => {
            const paintInfo =
                index < modelState.paintVarInfos.length
                    ? modelState.paintVarInfos[index]
                    : modelState.paintInfos[index - modelState.paintVarInfos.length]
            if (!paintInfo) {
                return
            }

            modelCommand.changeSelectColorPaintType(paintInfo, nextPaintType, nextPaint)

            const { paint, usingInfos } = paintInfo
            if (isGradientType(paint.type) && nextPaintType === PaintType.PAINT_TYPE_SOLID_PAINT) {
                modelCommand.endEditingGradient()
            } else if (paint.type === PaintType.PAINT_TYPE_SOLID_PAINT && isGradientType(nextPaintType)) {
                modelCommand.startEditingGradient(usingInfos)
            }
            command.commitUndo()
        }

    const onChangeColorVar = (index: number) => (colorVar: Wukong.DocumentProto.IVariableAliasData) => {
        changePartialPaintV2(index)({ ...getDefaultSolidPaint(), colorVar })
    }

    const onChangePaints = (index: number) => (paints: Wukong.DocumentProto.IPaint[]) => {
        changePartialPaintV2(index)({ ...paints[0], colorVar: null })
    }

    return {
        isShowAll,
        isShowAllStyle,
        onClickShowAll,
        onClickShowAllStyle,
        isOpenV2,
        onDetachColorVar,
        onChangeColorV2,
        onChangeOpacityV2,
        onChangeBlendModeV2,
        onChangeColorStopsV2,
        onChangeTransformV2,
        onChangePaintTypeV2,
        onChangeModalVisibleV2,
        onChangeColorVar,
        onChangePaints,

        modelState,
        modelCommand,
    }
}
