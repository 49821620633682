import { IDBPObjectStore, IDBPTransaction, StoreNames } from 'idb'
import { IndexedDBName } from '../../web-storage/indexed-db/config'
import { SavedResourcesSchema } from '../../web-storage/indexed-db/config/schema'
import { openEnhancedIndexedDB } from '../../web-storage/indexed-db/storage'

const STORE_SAVED_IMAGES = 'saved-images'
const DB_VERSION = 1

async function prepareResourceDB() {
    return openEnhancedIndexedDB({
        name: IndexedDBName.SavedResources,
        version: DB_VERSION,
        callback: {
            upgrade: (database) => {
                if (!database.objectStoreNames.contains(STORE_SAVED_IMAGES)) {
                    database.createObjectStore(STORE_SAVED_IMAGES, {
                        keyPath: ['documentId', 'hash'],
                    })
                }
            },
        },
    })
}

type ImagesObjectStore = IDBPObjectStore<
    SavedResourcesSchema,
    ['saved-images'],
    StoreNames<SavedResourcesSchema>,
    'readwrite'
>

export async function mutateImagesObjectStoreV2<R>(f: (objectStore: ImagesObjectStore) => Promise<R>): Promise<R> {
    const db = await prepareResourceDB()

    let tx: IDBPTransaction<SavedResourcesSchema, ['saved-images'], 'readwrite'> | undefined = undefined

    try {
        tx = db.transaction(STORE_SAVED_IMAGES, 'readwrite')
        const objectStore = tx.objectStore(STORE_SAVED_IMAGES)
        const ret = await f(objectStore)
        await tx.done
        db.close()
        return ret
    } catch (e) {
        if (tx) {
            tx.abort()
        }
        db.close()
        throw e
    }
}
