/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { InputV2, WKButton } from '../../../../../../ui-lib/src'
import { useState } from 'react'
import { useEffectOnce } from 'react-use'
import useEvent from 'react-use/lib/useEvent'
import { LocalStorageKey } from '../../../../web-storage/local-storage/config'
import { useManualLocalStorageState } from '../../../../web-storage/local-storage/hooks'
import { WK } from '../../../../window'
import { ReactComponent as AEExport } from '../../../assets/plugins/export-ae.svg'
import { useClipboardService } from '../../../context/document-context'
import { PluginExported } from '../type'

export function PopupModal() {
    const defalutValue =
        'const absDeltaY = Math.abs(y)\nif (!Number.isInteger(y)) {\nreturn Math.min(1.2, absDeltaY * (dp == 2 ? 0.00625 : 0.0125) + 1);\n} else {\nif (absDeltaY < 10) {\nreturn absDeltaY / 400 + 1;\n}\nreturn Math.min(1.2, absDeltaY * (dp == 2 ? 0.0025 : 0.005) + 1);\n}'
    const [evalString, setEvalString, saveToLocalStore] = useManualLocalStorageState(
        LocalStorageKey.ScaleSpeedEvalFunction,
        defalutValue
    )
    const [deltaYDisplay, setDeltaYDisplay] = useState<string>('')

    useEvent('wheel', (e: WheelEvent) => {
        if (e.metaKey || e.ctrlKey) {
            setDeltaYDisplay((item) => {
                item = `${e.deltaY},${item}`
                const itemArray = item.split(',')
                if (itemArray.length > 100) {
                    itemArray.pop()
                    return itemArray.join(',')
                }
                return item
            })
        }
    })

    useEffectOnce(() => {
        return () => {
            WK.scaleSpeedEvalFunction = undefined
        }
    })

    const clipboardService = useClipboardService()

    const apply = () => {
        const warpWithFunc = '(function job(){' + evalString + '})()'
        WK.scaleSpeedEvalFunction = warpWithFunc
        saveToLocalStore()
    }

    return (
        <>
            {/* body */}
            <div style={{ padding: '10px' }}>
                <p>输入表达式，做数据变换时, y 和 dp 会转换为系统值</p>
                <InputV2.Textarea
                    height={200}
                    value={evalString}
                    onChange={(e: any) => {
                        setEvalString(e.target.value)
                    }}
                />
                <br />
                <WKButton type="primary" onClick={apply} style={{ marginRight: '10px' }}>
                    应用
                </WKButton>

                <WKButton
                    type="primary"
                    onClick={() => {
                        setEvalString(defalutValue)
                        apply()
                    }}
                >
                    恢复到默认
                </WKButton>
            </div>
            <div style={{ padding: '10px' }}>
                <p>近期输入的 deltaY(100 条)</p>
                <div>{deltaYDisplay}</div>
                <WKButton
                    type="primary"
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                        clipboardService.copyText(deltaYDisplay)
                    }}
                >
                    复制到剪切板
                </WKButton>

                <WKButton
                    type="primary"
                    onClick={() => {
                        setDeltaYDisplay('')
                    }}
                >
                    清除 deltaY
                </WKButton>
            </div>
        </>
    )
}

export default {
    manifest: {
        key: Wukong.DocumentProto.PluginType.PLUGIN_TYPE_ADJUST_MOUSE_SCALE_SPEED,
        name: '调整鼠标速率',
        width: 500,
        height: 500,
        icon: <AEExport />,
    },
    Component: PopupModal,
} as PluginExported
