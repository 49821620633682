import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Title: '变量',
    AllLibraries: '全部组件库',
    LocalLibrary: '本地组件库',
    NotFound: '没有找到该变量',
} as const

export const enTranslation = {
    Title: 'Variables',
    AllLibraries: 'All libraries',
    LocalLibrary: 'Local library',
    NotFound: 'No results found',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
