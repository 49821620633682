import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Custom: '自定义',
    Libraries: '组件库',
    CanvasBackground: '画布背景',
    LayoutGrid: '布局网格',
    CreateVariable: '创建变量',
    CreateStyleOrVariable: '创建样式或变量',
} as const

export const enTranslation = {
    Custom: 'Custom',
    Libraries: 'Libraries',
    CanvasBackground: 'Color',
    LayoutGrid: 'Layout grid',
    CreateVariable: 'Create variable',
    CreateStyleOrVariable: 'Create style or variable',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
