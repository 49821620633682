import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Preview: '预览',
} as const

export const enTranslation = {
    Preview: 'Preview',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
