import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import styles from './canvas-search-match-text.module.less'
import { formatForStartEllipsis } from './util'

interface Props {
    content: string
    textMatch: Wukong.DocumentProto.ITextMatch | null
    className: string
    multiline: boolean
    isPurple: boolean
    replacement?: string
}

export function CanvasSearchMatchText({ content, textMatch, multiline, className, replacement = '', isPurple }: Props) {
    const containerRef = useRef<HTMLDivElement>(null)
    const matchedHeadLastCharRef = useRef<HTMLSpanElement>(null)
    const [needHeadEllipsis, setNeedHeadEllipsis] = useState(false)

    const { start, end } = textMatch ?? { start: 0, end: 0 }
    useEffect(() => {
        // 每次内容变化时重置状态以触发重新计算省略
        setNeedHeadEllipsis(false)
    }, [content, start, end, multiline, replacement])

    useLayoutEffect(() => {
        if (needHeadEllipsis || start == end) {
            return
        }

        if (!containerRef.current || !matchedHeadLastCharRef.current) {
            return
        }

        const isContainerEndEllipsised =
            containerRef.current.offsetHeight < containerRef.current.scrollHeight ||
            containerRef.current.offsetWidth < containerRef.current.scrollWidth
        if (!isContainerEndEllipsised) {
            // 所有文本能够完整展示, 不需要前置省略
            return
        }

        const middleHeadLastCharRect = matchedHeadLastCharRef.current.getBoundingClientRect()
        const containerRect = containerRef.current.getBoundingClientRect()

        // 计算LastChar是否完全展示在可见区域内, 来设置前置省略
        // 因为最后一个字符后面还有..., 额外+20像素的buffer
        const isLastCharNearRightBound = middleHeadLastCharRect.left + 20 >= containerRect.right
        if (multiline) {
            const isLastCharThirdLine = middleHeadLastCharRect.bottom > containerRect.bottom
            const isLastCharSecondLine = middleHeadLastCharRect.top > containerRect.top + 15
            setNeedHeadEllipsis(isLastCharThirdLine || (isLastCharSecondLine && isLastCharNearRightBound))
        } else {
            setNeedHeadEllipsis(isLastCharNearRightBound)
        }
    }, [needHeadEllipsis, start, end, multiline])

    const { isStartEllipsis, left, middleHead, middleHeadLastChar, middleTail, right } = useMemo(() => {
        return formatForStartEllipsis(content, start!, end!, needHeadEllipsis)
    }, [content, start, end, needHeadEllipsis])

    return (
        <div
            ref={containerRef}
            className={classNames(className, { [styles.purple]: isPurple })}
            data-testid="canvas-search-match-text"
        >
            {isStartEllipsis ? '\u2026' : ''}
            {left}
            {replacement ? (
                <span className={styles.replaced}>
                    <span>{middleHead}</span>
                    <span ref={matchedHeadLastCharRef}>{middleHeadLastChar}</span>
                    {middleTail}
                </span>
            ) : (
                <span className={styles.bold}>
                    <span>{middleHead}</span>
                    <span ref={matchedHeadLastCharRef}>{middleHeadLastChar}</span>
                    {middleTail}
                </span>
            )}
            {replacement ? <span className={styles.bold}>{replacement}</span> : null}
            {right}
        </div>
    )
}
