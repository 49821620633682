import { FC, useCallback, useRef, useState } from 'react'
import {
    IconEditor,
    IconEditorRef,
    WKDialog,
    WKDivider,
    WKTextButton,
    WKToast,
    WKTypography,
    WKUserAvatar,
} from '../../../../../../ui-lib/src'
import { useUserInfoContext } from '../../../../auth'
import { LoginPolicy, ResourceType, RoleStatus } from '../../../../kernel/interface/type'
import { contentAuditError } from '../../../../kernel/request/error-handler'
import { UpdateUserProfile } from '../../../../kernel/request/user'
import { DefaultLibraryModal } from '../../default-library/default-library-modal'
import { AccountManage } from '../../frog-task/account-manage'
import { useSpaceStore } from '../../zustand/space-state'
import { CancelAccount } from './cancel-account/cancel-account'
import { GlobalEmailConfig } from './email-config/email-config'
import { translation } from './index.translation'
import { NicknameDialog } from './nickname-dialog'
import { PasswordDialog } from './password-dialog'
import { ResetPassword } from './reset-password'
interface UserInfoDialogProps {
    openSource: 'space' | 'editor'
    setDialogShow: (show: boolean) => void
}

const BlockItem = ({ children }: { children: React.ReactNode }) => {
    return <div className="py-3 flex gap-4 items-center">{children}</div>
}

const Block = ({ children, hideDivider = false }: { children: React.ReactNode; hideDivider?: boolean }) => {
    return (
        <>
            <div className="py-2">{children}</div>
            {!hideDivider && <WKDivider />}
        </>
    )
}

export const UserInfoDialog: FC<UserInfoDialogProps> = (props: UserInfoDialogProps) => {
    const { setDialogShow, openSource } = props
    const { updateUserInfo, userInfo } = useUserInfoContext()
    const { organization } = useSpaceStore.use.organizationStore()
    const [visible, setVisible] = useState<boolean>(true)

    const nickname = userInfo?.userBrief.nickname
    const email = userInfo?.userBrief.email

    const [passwordDialogShow, setPasswordDialogShow] = useState<boolean>(false)
    const [nameDialogShow, setNameDialogShow] = useState<boolean>(false)
    const [resetPasswordDialogShow, setResetPasswordDialogShow] = useState<boolean>(false)
    const [defaultLibraryModalVisible, setDefaultLibraryModalVisible] = useState<boolean>(false)

    const closePasswordDialog = () => {
        setVisible(true)
        setPasswordDialogShow(false)
    }

    const closeNameDialog = () => {
        setVisible(true)
        setNameDialogShow(false)
    }

    const openRestPassword = () => {
        setPasswordDialogShow(false)
        setResetPasswordDialogShow(true)
    }

    const closeResetPassword = () => {
        setVisible(true)
        setResetPasswordDialogShow(false)
    }

    const changeIcon = useCallback(
        async (avatarId: string) => {
            await new UpdateUserProfile({ avatarId }).start()
            updateUserInfo()
        },
        [updateUserInfo]
    )
    const iconEditorRef = useRef<IconEditorRef>(null)

    const hidePassword =
        userInfo?.googleManaged ||
        !userInfo?.hasPassword ||
        userInfo?.orgLoginPolicyEnum == LoginPolicy.OnlyEmailGoogle ||
        userInfo?.orgLoginPolicyEnum == LoginPolicy.OnlyEmailSso

    if (userInfo) {
        return (
            <>
                <WKDialog
                    title={translation('Settings')}
                    onCancel={() => {
                        setDialogShow(false)
                    }}
                    width={672}
                    footer={null}
                    cancelIconProps={{ 'data-testid': 'close-icon' } as any}
                    visible={visible}
                    bodyStyle={{ padding: '4px 32px 8px 24px' }}
                    enabledMarginTop
                    testId="user-info-dialog"
                >
                    <div className="flex gap-6">
                        <div className="pt-5">
                            <WKUserAvatar
                                editable
                                backgroundColor={
                                    userInfo?.userBrief.avatarId
                                        ? userInfo.userBrief.avatarBackgroundColor
                                        : 'var(--wk-gray-3)'
                                }
                                useDefault={!userInfo?.userBrief.avatarId}
                                onClickEdit={() => {
                                    AccountManage.changeAvatar()
                                    iconEditorRef.current?.open()
                                }}
                                size={60}
                                userInfo={userInfo?.userBrief}
                            />
                        </div>
                        <div className="w-0 flex-1">
                            <Block>
                                <BlockItem>
                                    <div className="flex flex-col gap-1 flex-1">
                                        <WKTypography.Text weight="medium">{translation('Name')}</WKTypography.Text>
                                        <WKTypography.Text color="placeholder">{nickname}</WKTypography.Text>
                                    </div>
                                    <WKTextButton
                                        type="primary"
                                        size={12}
                                        id={'change-name'}
                                        data-testid="user-info-change-name"
                                        onClick={() => {
                                            setVisible(false)
                                            setNameDialogShow(true)
                                        }}
                                    >
                                        {translation('Change')}
                                    </WKTextButton>
                                </BlockItem>
                                <BlockItem>
                                    <div className="flex flex-col gap-1 flex-1">
                                        <WKTypography.Text weight="medium">{translation('Email')}</WKTypography.Text>
                                        <div className="flex justify-between">
                                            <WKTypography.Text color="placeholder">{email}</WKTypography.Text>
                                            {userInfo?.googleManaged && (
                                                <WKTypography.Text color="placeholder">
                                                    Managed by Google
                                                </WKTypography.Text>
                                            )}
                                        </div>
                                    </div>
                                </BlockItem>
                                {!hidePassword && (
                                    <BlockItem>
                                        <div className="flex flex-col gap-1 flex-1">
                                            <WKTypography.Text weight="medium">
                                                {translation('Password')}
                                            </WKTypography.Text>
                                            <WKTypography.Text color="placeholder">
                                                {translation('SetAPassword')}
                                            </WKTypography.Text>
                                        </div>
                                        <WKTextButton
                                            type="primary"
                                            id={'change-password'}
                                            data-testid="user-info-change-password"
                                            size={12}
                                            onClick={() => {
                                                setVisible(false)
                                                setPasswordDialogShow(true)
                                                AccountManage.changePassword()
                                            }}
                                        >
                                            {translation('Change')}
                                        </WKTextButton>
                                    </BlockItem>
                                )}
                                {userInfo?.orgLoginPolicyEnum == LoginPolicy.OnlyEmailSso && (
                                    <BlockItem>
                                        <WKTypography.Text color="placeholder">
                                            {translation('SsoLogin')}
                                        </WKTypography.Text>
                                    </BlockItem>
                                )}
                                {userInfo?.orgLoginPolicyEnum == LoginPolicy.OnlyEmailGoogle && (
                                    <BlockItem>
                                        <WKTypography.Text color="placeholder">
                                            {translation('GoogleLogin')}
                                        </WKTypography.Text>
                                    </BlockItem>
                                )}
                            </Block>
                            {openSource === 'space' && (
                                <Block>
                                    <BlockItem>
                                        <div className="flex flex-col gap-1 flex-1">
                                            <WKTypography.Text weight="medium">
                                                {translation('Library')}
                                            </WKTypography.Text>
                                            <WKTypography.Text color="placeholder">
                                                {translation('LibraryDesc')}
                                            </WKTypography.Text>
                                        </div>
                                        <WKTextButton
                                            data-testid="user-info-set-draft-library"
                                            type="primary"
                                            size={12}
                                            onClick={() => {
                                                setDefaultLibraryModalVisible(true)
                                                setTimeout(() => {
                                                    setVisible(false)
                                                }, 0)
                                            }}
                                        >
                                            {translation('Setting')}
                                        </WKTextButton>
                                    </BlockItem>
                                </Block>
                            )}
                            <Block>
                                <BlockItem>
                                    <GlobalEmailConfig />
                                </BlockItem>
                            </Block>
                            <Block hideDivider>
                                <BlockItem>
                                    <CancelAccount
                                        setUserInfoDialogVisible={setVisible}
                                        openResetPasswordModal={openRestPassword}
                                    />
                                </BlockItem>
                            </Block>
                        </div>
                    </div>
                </WKDialog>

                {passwordDialogShow && (
                    <PasswordDialog closePasswordDialog={closePasswordDialog} openRestPassword={openRestPassword} />
                )}
                {nameDialogShow && <NicknameDialog user={userInfo} closeNickName={closeNameDialog} />}
                {resetPasswordDialogShow && <ResetPassword closeResetPassword={closeResetPassword} />}
                {defaultLibraryModalVisible && (
                    <DefaultLibraryModal
                        resourceType={ResourceType.Folder}
                        resourceId={organization.draftFolderId}
                        role={RoleStatus.Owner}
                        onCancel={() => {
                            setVisible(true)
                            setDefaultLibraryModalVisible(false)
                        }}
                    />
                )}
                <IconEditor
                    ref={iconEditorRef}
                    title={translation('ChangePhoto')}
                    onClose={() => {
                        setVisible(true)
                    }}
                    onOpen={() => {
                        setVisible(false)
                        AccountManage.selectAvatarPicture()
                    }}
                    onOk={async (id) => {
                        try {
                            AccountManage.confirmAvatar()
                            await changeIcon(id)
                            AccountManage.uploadAvatarSuccess()
                        } catch (e: any) {
                            const msg = e?.req ? contentAuditError(e, 'image') : e?.message
                            msg && WKToast.error(msg)
                        }
                        setVisible(true)
                    }}
                    onClickReupload={AccountManage.reuploadAvatar}
                />
            </>
        )
    }
    return <></>
}
