import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    NameThisVersion: '编辑版本信息',
    AddToVersion: '添加历史版本',
    AddToVersion_synonyms1: '保存为历史版本',
    ExceededTheCharacter: '超出字数限制',
    Cancel: '取消',
    Save: '添加',
    Save_synonyms1: '确定',
    Save_synonyms1_synonyms2: '保存',
    Title: '请输入版本名称',
    DescribeWhatChanged: '请输入版本描述',
} as const

export const enTranslation = {
    NameThisVersion: 'Edit version information',
    AddToVersion: 'Add to version history',
    AddToVersion_synonyms1: 'Save to version history',
    ExceededTheCharacter: 'Exceeds character limit',
    Cancel: 'Cancel',
    Save: 'Add',
    Save_synonyms1: 'Done',
    Save_synonyms1_synonyms2: 'Save',
    Title: 'Title',
    DescribeWhatChanged: 'Describe what changed',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
