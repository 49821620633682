import {
    SetComponentConfigPopupWasmCall,
    UpdateComponentDescWasmCall,
    UpdateSelectedComponentNameWasmCall,
    UpdateSimplifyInstancePanels,
} from '@wukong/bridge-proto'
import { PopupStateType } from '../../../../../../document/node/node'
import { useViewState } from '../../../../../../view-state-bridge'
import { useCommand } from '../../../../../context/document-context'
import { VComponentDescUnit } from '../../types'

export const useComponentTitlePanel = () => {
    const { type } = useViewState('popupState')!
    const command = useCommand()
    const popupVisible = type === PopupStateType.POPUP_STATE_TYPE_COMPONENT_CONFIG

    // 更新选中组件/组件集的名字
    const updateComponentName = (newName: string) => {
        command.DEPRECATED_invokeBridge(UpdateSelectedComponentNameWasmCall, {
            newName,
        })
        command.commitUndo()
    }
    // 打开组件/变体/组件集配置弹窗
    const openComponentConfigPopup = () => {
        command.DEPRECATED_invokeBridge(SetComponentConfigPopupWasmCall, {
            value: true,
        })
    }
    // 关闭组件/变体/组件集配置弹窗
    const closeComponentConfigPopup = () => {
        command.DEPRECATED_invokeBridge(SetComponentConfigPopupWasmCall, {
            value: false,
        })
    }
    // 更新组件/变体/组件集描述
    const updateComponentDesc = (descUnit: VComponentDescUnit) => {
        command.DEPRECATED_invokeBridge(UpdateComponentDescWasmCall, descUnit)
        command.commitUndo()
    }
    // 更新普通组件/组件集简化实例配置
    const updateSimplifyInstancePanels = (value: boolean) => {
        command.DEPRECATED_invokeBridge(UpdateSimplifyInstancePanels, { value })
    }

    return {
        popupVisible,
        updateComponentName,
        openComponentConfigPopup,
        closeComponentConfigPopup,
        updateComponentDesc,
        updateSimplifyInstancePanels,
    }
}
