import { ContractSpecification } from '../../kernel/interface/type'
import { UpgradePopupType, WKFrogTask } from '../wk-frog-task'
import { PayUpgradeDialog } from './pay-upgrade-dialog/pay-upgrade-dialog'
import { PayUpgradeDialogContainerProps } from './pay-upgrade-dialog/pay-upgrade-dialog-struct/pay-upgrade-dialog-container/pay-upgrade-dialog-container'
import { PayUpgradeDialogFrom, PayUpgradeDialogType } from './pay-upgrade-dialog/pay-upgrade-dialog-struct/type'
import { translation } from './pay-upgrade-scene-modal.translation'

export function PayUpgradeCopyFileFileLimitSceneDialog(
    from: PayUpgradeDialogFrom,
    teamId: string | undefined,
    initUpgradePlan?: (plan: ContractSpecification, selectedTeamId?: string) => Promise<void>
) {
    WKFrogTask.payment.UpgradePopupType(UpgradePopupType.CopyFile)
    PayUpgradeDialog.show({
        type: PayUpgradeDialogType.Professional,
        title: translation('CopyFileModalTitle'),
        subtitle: translation('CopyFileModalSubTitle'),

        starterProps: {
            privileges: [
                { name: translation('ThreeFiles'), enable: true },
                { name: translation('OneProject'), enable: true },
                { name: translation('ThreePagesPerFile'), enable: true },
            ],
        },
        professionalProps: {
            privileges: [
                { name: translation('UnlimitedFiles'), enable: true },
                { name: translation('UnlimitedProject'), enable: true },
                { name: translation('UnlimitedPages'), enable: true },
            ],
            submitProps:
                from === PayUpgradeDialogFrom.Space
                    ? {
                          from: PayUpgradeDialogFrom.Space,
                          teamId,
                          submit: () => {
                              initUpgradePlan?.(ContractSpecification.Profession, teamId)
                          },
                      }
                    : {
                          from: PayUpgradeDialogFrom.Editor,
                          teamId,
                      },
        },
    })
}

export function PayUpgradeCopyFilePageLimitSceneDialog(
    from: PayUpgradeDialogFrom,
    teamId: string | undefined,
    initUpgradePlan?: (plan: ContractSpecification, selectedTeamId?: string) => Promise<void>
) {
    WKFrogTask.payment.UpgradePopupType(UpgradePopupType.CopyFile)
    PayUpgradeDialog.show({
        type: PayUpgradeDialogType.Professional,
        title: translation('CopyFileModalTitle'),
        subtitle: translation('NeddMorePageDesc'),

        starterProps: {
            privileges: [
                { name: translation('ThreePagesPerFile'), enable: true },
                { name: translation('ThreeFiles'), enable: true },
                { name: translation('OneProject'), enable: true },
            ],
        },
        professionalProps: {
            privileges: [
                { name: translation('UnlimitedPages'), enable: true },
                { name: translation('UnlimitedFiles'), enable: true },
                { name: translation('UnlimitedProject'), enable: true },
            ],
            submitProps:
                from === PayUpgradeDialogFrom.Space
                    ? {
                          from: PayUpgradeDialogFrom.Space,
                          teamId,
                          submit: () => {
                              initUpgradePlan?.(ContractSpecification.Profession, teamId)
                          },
                      }
                    : {
                          from: PayUpgradeDialogFrom.Editor,
                          teamId,
                      },
        },
    })
}

export function PayUpgradeRecoverFileFileLimitSceneDiadlog(
    from: PayUpgradeDialogFrom,
    teamId: string | undefined,
    initUpgradePlan: (plan: ContractSpecification, selectedTeamId?: string) => Promise<void>
) {
    WKFrogTask.payment.UpgradePopupType(UpgradePopupType.RecoverFile)
    PayUpgradeDialog.show({
        type: PayUpgradeDialogType.Professional,
        title: translation('RecoverFileModalTitle'),
        subtitle: translation('RecoverFileModalSubTitle'),

        starterProps: {
            privileges: [
                { name: translation('ThreeFiles'), enable: true },
                { name: translation('OneProject'), enable: true },
                { name: translation('ThreePagesPerFile'), enable: true },
            ],
        },
        professionalProps: {
            privileges: [
                { name: translation('UnlimitedFiles'), enable: true },
                { name: translation('UnlimitedProject'), enable: true },
                { name: translation('UnlimitedPages'), enable: true },
            ],
            submitProps:
                from === PayUpgradeDialogFrom.Space
                    ? {
                          from: PayUpgradeDialogFrom.Space,
                          teamId,
                          submit: () => {
                              initUpgradePlan?.(ContractSpecification.Profession, teamId)
                          },
                      }
                    : {
                          from: PayUpgradeDialogFrom.Editor,
                          teamId,
                      },
        },
    })
}

export function PayUpgradeRecoverFilePageLimitSceneDiadlog(
    from: PayUpgradeDialogFrom,
    teamId: string | undefined,
    initUpgradePlan: (plan: ContractSpecification, selectedTeamId?: string) => Promise<void>
) {
    WKFrogTask.payment.UpgradePopupType(UpgradePopupType.RecoverFile)
    PayUpgradeDialog.show({
        type: PayUpgradeDialogType.Professional,
        title: translation('RecoverFileModalTitle'),
        subtitle: translation('NeddMorePageDesc'),

        starterProps: {
            privileges: [
                { name: translation('ThreePagesPerFile'), enable: true },
                { name: translation('ThreeFiles'), enable: true },
                { name: translation('OneProject'), enable: true },
            ],
        },
        professionalProps: {
            privileges: [
                { name: translation('UnlimitedPages'), enable: true },
                { name: translation('UnlimitedFiles'), enable: true },
                { name: translation('UnlimitedProject'), enable: true },
            ],
            submitProps:
                from === PayUpgradeDialogFrom.Space
                    ? {
                          from: PayUpgradeDialogFrom.Space,
                          teamId,
                          submit: () => {
                              initUpgradePlan?.(ContractSpecification.Profession, teamId)
                          },
                      }
                    : {
                          from: PayUpgradeDialogFrom.Editor,
                          teamId,
                      },
        },
    })
}

export function PayUpgradeMoveFileFileLimitSceneDiadlog(
    from: PayUpgradeDialogFrom,
    teamId: string | undefined,
    initUpgradePlan?: (plan: ContractSpecification, selectedTeamId?: string) => Promise<void>,
    upgradePlanSubmitCallback?: () => void,
    dialogProps?: Omit<PayUpgradeDialogContainerProps, 'hide'>
) {
    WKFrogTask.payment.UpgradePopupType(UpgradePopupType.MoveFile)
    PayUpgradeDialog.show({
        type: PayUpgradeDialogType.Professional,
        title: translation('MoveFileModalTitle'),
        subtitle: translation('MoveFileModalSubTitle'),

        starterProps: {
            privileges: [
                { name: translation('ThreeFiles'), enable: true },
                { name: translation('OneProject'), enable: true },
                { name: translation('ThreePagesPerFile'), enable: true },
            ],
        },
        professionalProps: {
            privileges: [
                { name: translation('UnlimitedFiles'), enable: true },
                { name: translation('UnlimitedProject'), enable: true },
                { name: translation('UnlimitedPages'), enable: true },
            ],
            submitProps:
                from === PayUpgradeDialogFrom.Space
                    ? {
                          from: PayUpgradeDialogFrom.Space,
                          teamId,
                          submit: () => {
                              initUpgradePlan?.(ContractSpecification.Profession, teamId)
                              upgradePlanSubmitCallback?.()
                          },
                      }
                    : {
                          from: PayUpgradeDialogFrom.Editor,
                          teamId,
                      },
        },
        dialogProps: dialogProps,
    })
}

export function PayUpgradeMoveFilePageLimitSceneDiadlog(
    from: PayUpgradeDialogFrom,
    teamId: string | undefined,
    initUpgradePlan?: (plan: ContractSpecification, selectedTeamId?: string) => Promise<void>,
    upgradePlanSubmitCallback?: () => void,
    dialogProps?: Omit<PayUpgradeDialogContainerProps, 'hide'>
) {
    WKFrogTask.payment.UpgradePopupType(UpgradePopupType.MoveFile)
    PayUpgradeDialog.show({
        type: PayUpgradeDialogType.Professional,
        title: translation('MoveFileModalPageLimitTitle'),
        subtitle: translation('MoveFileModalPageLimitSubTitle'),

        starterProps: {
            privileges: [
                { name: translation('ThreePagesPerFile'), enable: true },
                { name: translation('OneProject'), enable: true },
                { name: translation('ThreeFiles'), enable: true },
            ],
        },
        professionalProps: {
            privileges: [
                { name: translation('UnlimitedPages'), enable: true },
                { name: translation('UnlimitedProject'), enable: true },
                { name: translation('UnlimitedFiles'), enable: true },
            ],
            submitProps:
                from === PayUpgradeDialogFrom.Space
                    ? {
                          from: PayUpgradeDialogFrom.Space,
                          teamId,
                          submit: () => {
                              initUpgradePlan?.(ContractSpecification.Profession, teamId)
                              upgradePlanSubmitCallback?.()
                          },
                      }
                    : {
                          from: PayUpgradeDialogFrom.Editor,
                          teamId,
                      },
        },
        dialogProps: dialogProps,
    })
}

export function PayUpgradeSharePrototypeSceneDiadlog(
    from: PayUpgradeDialogFrom,
    teamId: string | undefined,
    submit: () => void | (() => Promise<void>),
    dialogProps?: Omit<PayUpgradeDialogContainerProps, 'hide'>
) {
    WKFrogTask.payment.UpgradePopupType(UpgradePopupType.SharePrototype)
    PayUpgradeDialog.show({
        type: PayUpgradeDialogType.Professional,
        title: translation('SharePrototypeModalTitle'),
        subtitle: translation('SharePrototypeModalSubTitle'),
        starterProps: {
            privileges: [
                { name: translation('PrototypeModalStartSubDesc1'), enable: false },
                { name: translation('DesignFilesAndProjects'), enable: true },
                { name: translation('ThreePagesPerFile'), enable: true },
            ],
        },
        professionalProps: {
            privileges: [
                { name: translation('PrototypeModalProfessionalSubDesc1'), enable: true },
                { name: translation('UnlimitedFilesAndProjects'), enable: true },
                { name: translation('UnlimitedPages'), enable: true },
            ],
            submitProps:
                from === PayUpgradeDialogFrom.Space
                    ? {
                          from: PayUpgradeDialogFrom.Space,
                          teamId,
                          submit: () => {
                              submit()
                          },
                      }
                    : {
                          from: PayUpgradeDialogFrom.Editor,
                          teamId,
                      },
        },
        dialogProps: dialogProps,
    })
}

export function PayUpgradePrivateProject({
    teamId,
    initUpgradePlan,
    actions,
}: {
    teamId: string | undefined
    initUpgradePlan?: (plan: ContractSpecification, selectedTeamId?: string) => Promise<void>
    actions?: {
        setMoveModal: (v: boolean) => void
        setShareModal: (v: boolean) => void
    }
}) {
    actions?.setShareModal(false)

    WKFrogTask.payment.UpgradePopupType(UpgradePopupType.PrivacyFolder)
    PayUpgradeDialog.show({
        type: PayUpgradeDialogType.Professional,
        title: translation('PrivacyNotice'),
        subtitle: translation('PrivacyDescription'),
        starterProps: {
            privileges: [
                { name: translation('NoProjectControl'), enable: false },
                { name: translation('DesignFilesAndProjects'), enable: true },
                { name: translation('PagesPerFile'), enable: true },
            ],
        },
        professionalProps: {
            privileges: [
                { name: translation('FullProjectControl'), enable: true },
                { name: translation('UnlimitedFilesAndProjects'), enable: true },
                { name: translation('UnlimitedPages'), enable: true },
            ],
            submitProps: {
                from: PayUpgradeDialogFrom.Space,
                teamId,
                submit: () => {
                    initUpgradePlan?.(ContractSpecification.Profession, teamId)
                },
            },
        },
    })
}

export function PayUpgradePublishLibrary({ teamId }: { teamId: string | undefined }) {
    WKFrogTask.payment.UpgradePopupType(UpgradePopupType.PublishLibrary)
    PayUpgradeDialog.show({
        type: PayUpgradeDialogType.Professional,
        title: translation('NeedPublishLibrary'),
        subtitle: translation('NeedPublishLibraryDesc'),
        starterProps: {
            privileges: [
                { name: translation('DesignFilesAndProjects'), enable: true },
                { name: translation('PagesPerFile'), enable: true },
            ],
        },
        professionalProps: {
            privileges: [
                { name: translation('PublishTeamLibrary'), enable: true },
                { name: translation('UnlimitedFilesAndProjects'), enable: true },
                { name: translation('UnlimitedPages'), enable: true },
            ],
            submitProps: {
                from: PayUpgradeDialogFrom.Editor,
                teamId,
            },
        },
    })
}

export function PayUpgradeCreateMoreFile({
    teamId,
    initUpgradePlan,
}: {
    teamId: string | undefined
    initUpgradePlan?: (plan: ContractSpecification, selectedTeamId?: string) => Promise<void>
}) {
    WKFrogTask.payment.UpgradePopupType(UpgradePopupType.CreateFile)
    PayUpgradeDialog.show({
        type: PayUpgradeDialogType.Professional,
        title: translation('NeedMoreFile'),
        subtitle: translation('NeedMoreFileDesc'),
        starterProps: {
            privileges: [
                { name: translation('ThreePagesPerFile'), enable: true },
                { name: translation('ThreeFiles'), enable: true },
                { name: translation('OneProject'), enable: true },
            ],
        },
        professionalProps: {
            privileges: [
                { name: translation('UnlimitedPages'), enable: true },
                { name: translation('UnlimitedFiles'), enable: true },
                { name: translation('UnlimitedProject'), enable: true },
            ],
            submitProps: {
                from: PayUpgradeDialogFrom.Space,
                teamId,
                submit: () => {
                    initUpgradePlan?.(ContractSpecification.Profession, teamId)
                },
            },
        },
    })
}

export function PayUpgradeUpperPageNumLimit({
    from,
    teamId,
    initUpgradePlan,
    upgradePlanSubmitCallback,
    dialogProps,
}: {
    from: PayUpgradeDialogFrom
    teamId: string | undefined
    initUpgradePlan?: (plan: ContractSpecification, selectedTeamId?: string) => Promise<void>
    upgradePlanSubmitCallback?: () => void
    dialogProps?: Omit<PayUpgradeDialogContainerProps, 'hide'>
}) {
    WKFrogTask.payment.UpgradePopupType(UpgradePopupType.CreatePage)
    PayUpgradeDialog.show({
        type: PayUpgradeDialogType.Professional,
        title: translation('NeedMorePage'),
        subtitle: translation('NeddMorePageDesc'),
        starterProps: {
            privileges: [
                { name: translation('PagesPerFile'), enable: true },
                { name: translation('ThreeFiles'), enable: true },
                { name: translation('OneProject'), enable: true },
            ],
        },
        professionalProps: {
            privileges: [
                { name: translation('UnlimitedPages'), enable: true },
                { name: translation('UnlimitedFiles'), enable: true },
                { name: translation('UnlimitedProject'), enable: true },
            ],

            submitProps:
                from === PayUpgradeDialogFrom.Space
                    ? {
                          from: PayUpgradeDialogFrom.Space,
                          teamId,
                          submit: () => {
                              initUpgradePlan?.(ContractSpecification.Profession, teamId)
                              upgradePlanSubmitCallback?.()
                          },
                      }
                    : {
                          from: PayUpgradeDialogFrom.Editor,
                          teamId,
                      },
        },
        dialogProps: dialogProps,
    })
}

export function PayUpgradeCreateFolderLimit({
    from,
    teamId,
    initUpgradePlan,
    dialogProps,
}: {
    from: PayUpgradeDialogFrom
    teamId: string | undefined
    initUpgradePlan?: (plan: ContractSpecification, selectedTeamId?: string) => Promise<void>
    dialogProps?: Omit<PayUpgradeDialogContainerProps, 'hide'>
}) {
    WKFrogTask.payment.UpgradePopupType(UpgradePopupType.CreateFolder)
    PayUpgradeDialog.show({
        type: PayUpgradeDialogType.Professional,
        title: translation('NeedMoreProjects'),
        subtitle: translation('StarterPlanLimit'),
        starterProps: {
            privileges: [
                { name: translation('OneProject'), enable: true },
                { name: translation('ThreeFiles'), enable: true },
                { name: translation('ThreePagesPerFile'), enable: true },
            ],
        },
        professionalProps: {
            privileges: [
                { name: translation('UnlimitedProject'), enable: true },
                { name: translation('UnlimitedFiles'), enable: true },
                { name: translation('UnlimitedPages'), enable: true },
            ],

            submitProps:
                from === PayUpgradeDialogFrom.Space
                    ? {
                          from: PayUpgradeDialogFrom.Space,
                          teamId,
                          submit: () => {
                              initUpgradePlan?.(ContractSpecification.Profession, teamId)
                          },
                      }
                    : {
                          from: PayUpgradeDialogFrom.Editor,
                          teamId,
                      },
        },
        dialogProps: dialogProps,
    })
}
