import { useLocation } from 'react-router-dom'
import { domLocation } from '../dom-env'

export function useWindow() {
    const { pathname } = useLocation()

    const openByPathName = (_pathname: string, target?: '_self' | '_blank' | '_parent' | '_top') =>
        window.open(
            `${domLocation().origin}${domLocation().pathname.replace(pathname, '')}/${_pathname.replace(/^\//, '')}`,
            target
        )

    return { openByPathName }
}
