import constate from 'constate'
import { isNil } from 'lodash-es'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAsync, useDeepCompareEffect } from 'react-use'
import { asyncConfirm, WKToast } from '../../../../ui-lib/src'
import { getMoveDocsTitle } from '../../../../util/src'
import {
    DocWithAuthorityVO,
    ContractSpecification,
    FolderWithAuthorityVO,
    PayPlanType,
    ResourceType,
    TeamID,
    TeamWithAuthorityVO,
    TeamWithFolderAndAuthorityVO,
    OrganizationVO,
    RoleStatus,
    SeatType,
} from '../../kernel/interface/type'
import { MoveDocRequest } from '../../kernel/request/document'
import { RequestResponseErrorHandler } from '../../kernel/request/error-handler'
import { CreateFolderRequest } from '../../kernel/request/folder'
import {
    GetTeamResourceState,
    GetTeamsFoldersForMoveDoc,
    GetTeamsFoldersForMoveDocInEnterprise,
    QueryTeamAndUserState,
} from '../../kernel/request/team'
import { getFileFlowDirection, SpaceLogs } from '../../kernel/service/space-frog-service/logs'
import { checkPaymentIsUpdatingByDocs, getPayUpgradeDialogFrom, getPlanAndUserState } from '../../utils/payment'
import { handleBusinessStatusInMoveDocs, PayUpgradeCreateFolderLimit } from '../payment'
import { PayPreCheck } from '../payment/pay-pre-check'
import { translation } from './context.translation'
import { isActiveProPlan, isStarterPlan } from '../../utils/plan-status-checker'

export interface BatchMoveDocModalProps {
    docs: DocWithAuthorityVO[]
    onClose: (moved: boolean) => void
    organization: OrganizationVO
    forceHideTipBanner: boolean
    submitPayment?: (plan: ContractSpecification, selectedTeamId?: TeamID) => Promise<void>
}

export interface FolderWithAuthorityWithCheckLimit {
    folderWithAuthorityVO: FolderWithAuthorityVO
    reachLimit: boolean
    isDraft?: boolean
}

export interface TeamWithAuthorityWithCheckLimit {
    teamWithAuthorityVO: TeamWithAuthorityVO
    reachLimit: boolean
}

export interface TeamWithFolderAndAuthorityWithCheckLimit {
    teamWithAuthorityVO: TeamWithAuthorityWithCheckLimit
    folderWithAuthorityVOList: FolderWithAuthorityWithCheckLimit[]
}

interface Result {
    sharedFolders: FolderWithAuthorityWithCheckLimit[]
    teamsAndFolders: TeamWithFolderAndAuthorityWithCheckLimit[]
    /**
     * 产品要求展示是排序，所以这里要记录服务端返回的排序
     */
    worspacesAndTeamIds: { workspaceId: string; workspaceName: string; teamIds: string[] }[]
    openStatusMap: Record<string, boolean>
    workspaceOpenStatusMap: Record<string, boolean>
    showDraft: boolean
}

async function getSingleTeamLimit(orgId: string, teamId: string) {
    if (orgId == '-1') {
        // 1. 查询是否为免费团队
        const planAndUserState = await new QueryTeamAndUserState().start().then((res) => {
            return res.find((plan) => plan.resourceType === ResourceType.Team && plan.resourceId === teamId)
        })
        if (isNil(planAndUserState) || planAndUserState.planType == PayPlanType.starter) {
            // 2. 查询免费团队的限制
            const teamResourceState = await new GetTeamResourceState(teamId).start()
            return {
                folderReachLimit: teamResourceState.docCount >= 3,
                docReachLimit: teamResourceState.docCount >= 3,
            }
        } else {
            return { folderReachLimit: false, docReachLimit: false }
        }
    } else {
        return { folderReachLimit: false, docReachLimit: false }
    }
}

async function checkTeamLimit(teamsAndFolders: TeamWithFolderAndAuthorityVO[], sharedFolders: FolderWithAuthorityVO[]) {
    const teamAndFolderWithCheckLimitPromise = Promise.all(
        teamsAndFolders.map(async (teamAndFolder) => {
            const { teamWithAuthorityVO, folderWithAuthorityVOList } = teamAndFolder
            const { folderReachLimit, docReachLimit } = await getSingleTeamLimit(
                teamWithAuthorityVO.orgId,
                teamWithAuthorityVO.id
            )
            const folderWithCheckLimit = folderWithAuthorityVOList.map((folder) => {
                return {
                    folderWithAuthorityVO: folder,
                    reachLimit: docReachLimit,
                }
            })
            return {
                teamWithAuthorityVO: { teamWithAuthorityVO, reachLimit: folderReachLimit },
                folderWithAuthorityVOList: folderWithCheckLimit,
            }
        })
    )

    const sharedFolderWithCheckLimitPromise = Promise.all(
        sharedFolders.map(async (folder) => {
            const { docReachLimit } = await getSingleTeamLimit(folder.orgId, folder.teamId)
            return {
                folderWithAuthorityVO: folder,
                reachLimit: docReachLimit,
            }
        })
    )
    return Promise.all([teamAndFolderWithCheckLimitPromise, sharedFolderWithCheckLimitPromise]).then((res) => {
        return { teamAndFolderWithCheckLimit: res[0], sharedFolderWithCheckLimit: res[1] }
    })
}

const useBatchMoveDocModal = (props: BatchMoveDocModalProps) => {
    const { docs, onClose, organization, forceHideTipBanner, ...rest } = props
    const selectedDocFolderIds = Array.from(new Set(docs.map((doc) => doc.folderId)))
    const docIds = docs.map((doc) => doc.id)
    const docNames = docs.map((doc) => doc.name)
    const isEnterprise = organization.planType == 'enterprise'

    const [visible, setVisible] = useState(true)
    const [createFolderModalVisible, setCreateFolderModalVisible] = useState<number>(0)
    const [qs, setQs] = useState('')
    const [selectKey, setSelectKey] = useState<string>()
    const defaultSelectedFolderId = useMemo(() => {
        if (selectedDocFolderIds?.length == 1) {
            return selectedDocFolderIds[0]
        } else {
            return undefined
        }
    }, [selectedDocFolderIds])
    const hasSomeDraft = docs.some((doc) => doc.draft)
    const [userSelectedFolder, setUserSelectedFolder] = useState<
        | { id: string; name: string; teamId: string; orgId: string | undefined; reachLimit: boolean; isDraft: boolean }
        | undefined
    >(
        defaultSelectedFolderId
            ? { id: defaultSelectedFolderId, name: '', teamId: '', orgId: '', reachLimit: false, isDraft: false }
            : undefined
    )
    const createFolderTeamId = useRef<string>()
    const createFolderWorkspaceId = useRef<string>()
    const [result, setResult] = useState<Result>()
    const [singnal, setSignal] = useState(Date.now())
    const request = useMemo(() => {
        if (isEnterprise) {
            return new GetTeamsFoldersForMoveDocInEnterprise(docIds, qs).start()
        } else {
            return new GetTeamsFoldersForMoveDoc(docIds, qs).start()
        }
    }, [docIds, isEnterprise, qs])
    useDeepCompareEffect(() => {
        request
            .then(async (res) => {
                let openStatusMap: Record<string, boolean> = {}
                let workspaceOpenStatusMap: Record<string, boolean> = {}
                let showDraft = true
                const { sharedFolders } = res
                let teamsAndFolders: TeamWithFolderAndAuthorityVO[] = []
                let worspacesAndTeamIds: { workspaceId: string; workspaceName: string; teamIds: string[] }[] = []

                if ('workspaceWithTeamAndFolderList' in res) {
                    teamsAndFolders = res.workspaceWithTeamAndFolderList
                        .map((item) => item.teamsWithFolderAndAuthorityVO)
                        .flat()
                    worspacesAndTeamIds = res.workspaceWithTeamAndFolderList.map((item) => ({
                        workspaceId: item.workspaceWithAuthorityVO.workspaceId,
                        workspaceName: item.workspaceWithAuthorityVO.name,
                        teamIds: item.teamsWithFolderAndAuthorityVO.map((team) => team.teamWithAuthorityVO.id),
                    }))
                } else {
                    teamsAndFolders = res.teamsAndFolders
                }
                if (qs) {
                    // 如果有搜索词
                    showDraft = false
                    const obj: Record<string, boolean> = {}
                    const workspaceObj: Record<string, boolean> = {}
                    if (sharedFolders.length) {
                        obj.shareFolders = true
                    }
                    teamsAndFolders.forEach((item) => {
                        obj[item.teamWithAuthorityVO.id] = true
                        workspaceObj[item.teamWithAuthorityVO.workspaceId] = true
                    })
                    openStatusMap = obj
                    workspaceOpenStatusMap = workspaceObj
                } else {
                    // 没有搜索词
                    if (createFolderTeamId.current) {
                        // 如果是新建项目返回后，要打开新建的项目团队
                        openStatusMap = { ...openStatusMap, [createFolderTeamId.current!]: true }
                        const workspaceId = teamsAndFolders.find(
                            (item) => item.teamWithAuthorityVO.id == createFolderTeamId.current
                        )?.teamWithAuthorityVO.workspaceId
                        workspaceOpenStatusMap = { ...workspaceOpenStatusMap, [workspaceId!]: true }
                    } else {
                        // 首次打开要 并且 所属项目只有一个的时候 展开所属的团队
                        const openFolderTeamIds: Record<string, true> = {}
                        const workspaceObj: Record<string, boolean> = {}
                        if (selectedDocFolderIds?.length == 1) {
                            sharedFolders.forEach((item) => {
                                if (selectedDocFolderIds.includes(item.id)) {
                                    openFolderTeamIds.shareFolders = true
                                }
                            })
                            teamsAndFolders.forEach((item) => {
                                const { folderWithAuthorityVOList } = item
                                folderWithAuthorityVOList.map((folder) => {
                                    if (selectedDocFolderIds?.includes(folder.id)) {
                                        openFolderTeamIds[item.teamWithAuthorityVO.id] = true
                                        workspaceObj[item.teamWithAuthorityVO.workspaceId] = true
                                    }
                                })
                            })
                        }
                        openStatusMap = openFolderTeamIds
                        workspaceOpenStatusMap = workspaceObj
                    }
                }
                const teamsAndFolder = await checkTeamLimit(teamsAndFolders, res.sharedFolders)
                return {
                    showDraft,
                    teamsAndFolders: teamsAndFolder.teamAndFolderWithCheckLimit,
                    sharedFolders: teamsAndFolder.sharedFolderWithCheckLimit,
                    openStatusMap,
                    workspaceOpenStatusMap,
                    worspacesAndTeamIds,
                }
            })
            .then(setResult)
    }, [docIds, qs, selectedDocFolderIds, singnal])
    useEffect(() => {
        if (result) {
            setSelectKey(defaultSelectedFolderId)
            createFolderTeamId.current = undefined
            createFolderWorkspaceId.current = undefined
        }
    }, [defaultSelectedFolderId, result])
    const moveDocs = useCallback(async () => {
        if (userSelectedFolder?.id && docs.length) {
            setVisible(false)
            const canMoveDocs = await PayPreCheck.checkMoveDocs({
                moveInDraftOrTrash: userSelectedFolder.isDraft,
                orgId: docs[0].orgId,
                moveInTeamId: userSelectedFolder.teamId,
                moveInFolderId: userSelectedFolder.id,
                docs,
                initUpgradePlan: props.submitPayment,
                upgradePlanSubmitCallback: () => onClose(true),
                dialogPropsInUpgrade: {
                    onCancel: () => setVisible(true),
                    onOk: () => setVisible(true),
                },
                dialogPropsInSeat: {
                    onCancel: () => setVisible(true),
                    onOk: () => onClose(true),
                },
            })

            if (!canMoveDocs) {
                return
            }

            asyncConfirm({
                title: getMoveDocsTitle(docNames, userSelectedFolder.name),
                content: (
                    <>
                        {translation('AfterMoving')}，{translation('TheOriginalMembers')}
                    </>
                ),
                onClose() {
                    setVisible(true)
                },
            })
                .then(async () => {
                    await new MoveDocRequest(docIds, userSelectedFolder.id).start()
                    SpaceLogs.FileOperations.moveFileDialogSuccess({
                        file_count: docIds.length,
                        file_flow_direction: getFileFlowDirection(docs, organization.draftFolderId, {
                            folderId: userSelectedFolder.id,
                            teamId: userSelectedFolder.teamId,
                        }),
                    })
                    onClose(true)
                    WKToast.show(translation('FileMoved'))
                })
                .catch((e) => {
                    onClose(false)
                    if (!e || typeof e != 'object') {
                        return
                    }
                    handleBusinessStatusInMoveDocs(
                        RequestResponseErrorHandler(e).businessStatus,
                        userSelectedFolder.teamId,
                        props.submitPayment
                    )
                })
        }
    }, [userSelectedFolder, docs, props, onClose, docNames, docIds, organization.draftFolderId])
    const createFolder = useCallback(async (name: string, teamId: string) => {
        const folder = await new CreateFolderRequest({
            name,
            description: '',
            teamId,
        }).start()
        setUserSelectedFolder({
            id: folder.id,
            name: folder.name,
            teamId,
            orgId: folder.orgId,
            reachLimit: false,
            isDraft: false,
        })
        setQs('')
        setSignal((r) => r + 1)
        SpaceLogs.FileOperations.moveFileDialogNewProject()
        setVisible(true)
        setCreateFolderModalVisible(0)
    }, [])

    const checkMoveDocAvailable = useCallback(async () => {
        if (!docs.length || docs[0].orgId != '-1') {
            return true
        }
        const teamIds = Array.from(new Set(docs.map((doc) => doc.teamId))).filter((teamId) => !isNil(teamId))
        const paymentIsUpdating = await checkPaymentIsUpdatingByDocs(teamIds as string[])
        return !paymentIsUpdating
    }, [docs])

    const handleClickAddFolder = async (teamId: TeamID, orgId: string) => {
        const plan = await getPlanAndUserState(orgId, teamId)

        if (plan && isStarterPlan(plan)) {
            const resourceUsage = await new GetTeamResourceState(teamId).start()

            if (resourceUsage.folderCount >= 1) {
                setVisible(false)
                PayUpgradeCreateFolderLimit({
                    from: getPayUpgradeDialogFrom(),
                    teamId: teamId,
                    initUpgradePlan: props.submitPayment,
                    dialogProps: {
                        onCancel: () => setVisible(true),
                        onOk: () => onClose(true),
                    },
                })
                return
            }
        }

        createFolderTeamId.current = teamId
        setVisible(false)
        setCreateFolderModalVisible(Math.random() * Math.random())
    }

    const shouldSendRequest =
        organization.id == '-1' &&
        !forceHideTipBanner &&
        docs.length == 1 &&
        docs[0].draft &&
        docs[0].role === RoleStatus.Owner

    const { value: teamsPlan } = useAsync(
        shouldSendRequest ? () => new QueryTeamAndUserState().start() : () => Promise.resolve(undefined),
        []
    )

    const showMoveDraftTipBanner = useMemo(() => {
        if (!teamsPlan) {
            return false
        } else {
            return !teamsPlan?.some((plan) => isActiveProPlan(plan) && plan.userSeatType === SeatType.designer)
        }
    }, [teamsPlan])

    return {
        ...rest,
        qs,
        organization,
        defaultSelectedFolderId,
        setQs,
        onClose,
        result,
        visible,
        setVisible,
        createFolderTeamId,
        setResult,
        userSelectedFolder,
        setUserSelectedFolder,
        moveDocs,
        selectKey,
        isEnterprise,
        createFolder,
        createFolderModalVisible,
        setCreateFolderModalVisible,
        hasSomeDraft,
        showMoveDraftTipBanner,
        checkMoveDocAvailable,
        initUpgradePlan: props.submitPayment,
        handleClickAddFolder,
    }
}
export const [BatchMoveDocModalProvider, useBatchMoveDocModalContext] = constate(useBatchMoveDocModal)
