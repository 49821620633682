import { MotiffApi } from '@motiffcom/plugin-api-types'

export const FrameNotReadonlyProp = [
    'name',
    'visible',
    'locked',
    'componentPropertyReferences',
    'layoutGrids',
    'gridStyleId',
    'clipsContent',
    'guides',
    // 'inferredAutoLayout',
    'layoutMode',
    'layoutWrap',
    'paddingLeft',
    'paddingRight',
    'paddingTop',
    'paddingBottom',
    'horizontalPadding',
    'verticalPadding',
    'primaryAxisSizingMode',
    'counterAxisSizingMode',
    'primaryAxisAlignItems',
    'counterAxisAlignItems',
    'counterAxisAlignContent',
    'itemSpacing',
    'counterAxisSpacing',
    'itemReverseZIndex',
    // 'strokesIncludedInLayout',
    // 'devStatus',
    // 'annotations',
    'expanded',
    'backgrounds',
    'backgroundStyleId',
    'fills',
    'fillStyleId',
    'strokes',
    'strokeStyleId',
    'strokeWeight',
    'strokeJoin',
    'strokeAlign',
    'dashPattern',
    'strokeCap',
    // 'strokeMiterLimit',
    'cornerRadius',
    'cornerSmoothing',
    'topLeftRadius',
    'topRightRadius',
    'bottomLeftRadius',
    'bottomRightRadius',
    'strokeTopWeight',
    'strokeBottomWeight',
    'strokeLeftWeight',
    'strokeRightWeight',
    'opacity',
    'blendMode',
    'isMask',
    'maskType',
    'effects',
    'effectStyleId',
    'x',
    'y',
    'minWidth',
    'maxWidth',
    'minHeight',
    'maxHeight',
    'relativeTransform',
    'layoutAlign',
    'layoutGrow',
    'layoutPositioning',
    'constrainProportions',
    'rotation',
    // 'layoutSizingHorizontal',
    // 'layoutSizingVertical',
    'constraints',
    'reactions',
    'overflowDirection',
    // 'numberOfFixedChildren',
] as const

export const VectorNotReadonlyProp = [
    'vectorNetwork',
    'vectorPaths',
    'handleMirroring',
    'name',
    'visible',
    'locked',
    'componentPropertyReferences',
    // "resolvedVariableModes",
    // "explicitVariableModes",
    'opacity',
    'blendMode',
    'isMask',
    'maskType',
    'effects',
    'effectStyleId',
    'cornerRadius',
    'cornerSmoothing',
    'fills',
    'fillStyleId',
    'strokes',
    'strokeStyleId',
    'strokeWeight',
    'strokeJoin',
    'strokeAlign',
    'dashPattern',
    'strokeCap',
    // 'strokeMiterLimit',
    'x',
    'y',
    'minWidth',
    'maxWidth',
    'minHeight',
    'maxHeight',
    'relativeTransform',
    'layoutAlign',
    'layoutGrow',
    'layoutPositioning',
    'constrainProportions',
    'rotation',
    // 'layoutSizingHorizontal',
    // 'layoutSizingVertical',
    'constraints',
    'exportSettings',
    'reactions',
    // 'annotations',
] as const

export const TextNodePluginDataKeys = [
    'textAlignHorizontal',
    'textAlignVertical',
    'textAutoResize',
    'textTruncation',
    'maxLines',
    // 'paragraphIndent',
    'paragraphSpacing',
    // 'listSpacing',
    // 'hangingPunctuation',
    // 'hangingList',
    'autoRename',
    'characters',
    'fontSize',
    'fontName',
    'textCase',
    'textDecoration',
    'letterSpacing',
    'lineHeight',
    // 'leadingTrim',
    'textStyleId',
    'hyperlink',
    'name',
    'visible',
    'locked',
    'componentPropertyReferences',
    // 'resolvedVariableModes',
    // 'explicitVariableModes',
    'opacity',
    'blendMode',
    'isMask',
    'maskType',
    'effects',
    'effectStyleId',
    'fills',
    'fillStyleId',
    'strokes',
    'strokeStyleId',
    'strokeWeight',
    'strokeJoin',
    'strokeAlign',
    'dashPattern',
    'strokeCap',
    // 'strokeMiterLimit',
    'x',
    'y',
    'minWidth',
    'maxWidth',
    'minHeight',
    'maxHeight',
    'relativeTransform',
    'layoutAlign',
    'layoutGrow',
    'layoutPositioning',
    'constrainProportions',
    'rotation',
    // 'layoutSizingHorizontal',
    // 'layoutSizingVertical',
    'constraints',
    'exportSettings',
    'reactions',
    // 'annotations',
] as const

export const FrameNodePluginDataKeys = [
    'type',
    'id',
    // parent 不进行设置
    // 'parent',
    'name',
    'removed',
    // 'isAsset',
    'visible',
    'locked',
    // 'stuckNodes',
    // 'attachedConnectors',
    'componentPropertyReferences',
    // 目前还不支持 Variables
    // "boundVariables?",
    // "inferredVariables?",
    // "resolvedVariableModes",
    // "explicitVariableModes",
    // children 的类型就是SceneNodePluginData
    // 'children',
    // 'detachedInfo',
    'layoutGrids',
    'gridStyleId',
    'clipsContent',
    'guides',
    // 'inferredAutoLayout',
    'layoutMode',
    'layoutWrap',
    'paddingLeft',
    'paddingRight',
    'paddingTop',
    'paddingBottom',
    'horizontalPadding',
    'verticalPadding',
    'primaryAxisSizingMode',
    'counterAxisSizingMode',
    'primaryAxisAlignItems',
    'counterAxisAlignItems',
    'counterAxisAlignContent',
    'itemSpacing',
    'counterAxisSpacing',
    'itemReverseZIndex',
    // 'strokesIncludedInLayout',
    // 'devStatus',
    // 'annotations',
    'expanded',
    'backgrounds',
    'backgroundStyleId',
    'fills',
    'fillStyleId',
    'strokes',
    'strokeStyleId',
    'strokeWeight',
    'strokeJoin',
    'strokeAlign',
    'dashPattern',
    // 'strokeGeometry',
    'strokeCap',
    // 'strokeMiterLimit',
    // 'fillGeometry',
    'cornerRadius',
    'cornerSmoothing',
    'topLeftRadius',
    'topRightRadius',
    'bottomLeftRadius',
    'bottomRightRadius',
    'strokeTopWeight',
    'strokeBottomWeight',
    'strokeLeftWeight',
    'strokeRightWeight',
    'opacity',
    'blendMode',
    'isMask',
    'maskType',
    'effects',
    'effectStyleId',
    'x',
    'y',
    'width',
    'height',
    'minWidth',
    'maxWidth',
    'minHeight',
    'maxHeight',
    'relativeTransform',
    'absoluteTransform',
    'absoluteBoundingBox',
    'layoutAlign',
    'layoutGrow',
    'layoutPositioning',
    'absoluteRenderBounds',
    'constrainProportions',
    'rotation',
    // 'layoutSizingHorizontal',
    // 'layoutSizingVertical',
    'constraints',
    'exportSettings',
    'reactions',
    'overflowDirection',
    // 'numberOfFixedChildren',
    // 'overlayPositionType',
    // 'overlayBackground',
    // 'overlayBackgroundInteraction',
] as const

export const VectorNodePluginDataKeys = [
    'type',
    'vectorNetwork',
    'vectorPaths',
    'handleMirroring',
    'id',
    // parent 不进行设置
    // 'parent',
    'name',
    'removed',
    // 'isAsset',
    'visible',
    'locked',
    // Only Supported in FigJam
    // "stuckNodes",
    // 'attachedConnectors',
    'componentPropertyReferences',
    // "boundVariables?",
    // "inferredVariables?",
    // "resolvedVariableModes",
    // "explicitVariableModes",
    'opacity',
    'blendMode',
    'isMask',
    'maskType',
    'effects',
    'effectStyleId',
    'cornerRadius',
    'cornerSmoothing',
    'fills',
    'fillStyleId',
    'strokes',
    'strokeStyleId',
    'strokeWeight',
    'strokeJoin',
    'strokeAlign',
    'dashPattern',
    // 'strokeGeometry',
    'strokeCap',
    // 'strokeMiterLimit',
    // 'fillGeometry',
    'x',
    'y',
    'width',
    'height',
    'minWidth',
    'maxWidth',
    'minHeight',
    'maxHeight',
    'relativeTransform',
    'absoluteTransform',
    'absoluteBoundingBox',
    'layoutAlign',
    'layoutGrow',
    'layoutPositioning',
    'absoluteRenderBounds',
    'constrainProportions',
    'rotation',
    // 'layoutSizingHorizontal',
    // 'layoutSizingVertical',
    'constraints',
    'exportSettings',
    'reactions',
    // 'annotations',
] as const

export type NoReadonly<T extends Record<string | number | symbol, unknown>> = {
    -readonly [K in keyof T]: T[K]
}

export type NoFieldTypeOnlyUndefined<T> = {
    [K in keyof T]: T[K] extends undefined ? (undefined extends T[K] ? never : K) : K
}[keyof T]

export type TextNodePluginData = Partial<NoReadonly<Pick<MotiffApi.TextNode, typeof TextNodePluginDataKeys[number]>>>

export type FrameNodePluginData = Partial<
    NoReadonly<
        Pick<MotiffApi.FrameNode, typeof FrameNodePluginDataKeys[number]> & {
            children: SceneNodePluginData[]
        }
    >
>

export type VectorNodePluginData = Partial<
    NoReadonly<Pick<MotiffApi.VectorNode, typeof VectorNodePluginDataKeys[number]>>
>

export type CheckNotReadonlyPropAreAllInNodeProp<
    NodeProp extends unknown | unknown,
    NotReadonlyProp extends NodeProp
> = NotReadonlyProp extends NodeProp ? never : NotReadonlyProp

type _CheckFrame = CheckNotReadonlyPropAreAllInNodeProp<
    keyof typeof FrameNodePluginDataKeys,
    keyof typeof FrameNotReadonlyProp
>

type _CheckVector = CheckNotReadonlyPropAreAllInNodeProp<
    keyof typeof VectorNodePluginDataKeys,
    keyof typeof VectorNotReadonlyProp
>

export type PickUndefinedField<T extends Record<string, unknown>> = {
    [K in keyof T]: T[K] extends undefined ? K : never
}[keyof T]

export type StylePluginData = Record<string, unknown>

export type SceneNodePluginData = FrameNodePluginData | VectorNodePluginData // | TextNodePluginData

export interface NodePluginData {
    style: StylePluginData
    node: SceneNodePluginData | null
}
