import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    MoveTools: '移动工具',
    CreationTools: '绘图工具',
    Pencil: '铅笔',
    Done: '完成',
} as const

export const enTranslation = {
    MoveTools: 'Move tools',
    CreationTools: 'Creation tools',
    Pencil: 'Pencil',
    Done: 'Done',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
