import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    StartUseDevMode: '开始使用研发模式',
    DevModeDescription: '获取更专注的研发视角、更详细的标注展示。使用自动识别图标、导出预设、倍率切换等功能。',
    GetDevMode: '进入研发模式',
} as const

export const enTranslation = {
    StartUseDevMode: 'Start using Develop Mode',
    DevModeDescription:
        'Get a more developer-focused view and detailed inspection display. Use automatic icon detection, export preset, and unit & scale settings.',
    GetDevMode: 'Try Develop Mode',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
