import { useGet } from 'ccstate-react'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { documentLoaded$ } from '../external-store/atoms/editor-context'
import { UIRoot } from '../ui'
import { useAppContext } from './app-context'
import { useEnterHistoryMode, useSwitchHistoryVersion } from './history-mode'

export function AppDocumentContainer() {
    const ctx = useAppContext()

    const [docReady, setDocReady] = useState<boolean>(false)

    const [searchParams] = useSearchParams()

    const enterHistoryMode = useEnterHistoryMode()

    const { switchHistoryVersion } = useSwitchHistoryVersion()

    const documentLoaded = useGet(documentLoaded$)

    const locateHistory = async (historyVersionId: number) => {
        if (!historyVersionId) {
            return
        }
        enterHistoryMode()
        await switchHistoryVersion(historyVersionId, -1)
    }

    // firstTick 后，document 执行完第一次 flush，标明 wasm 侧已经 ready，此时开始进行 web 侧 AfterLoaded 的生命周期
    const afterDocumentLoadedCallback = async () => {
        if (searchParams.get('historyVersionId')) {
            await locateHistory(Number(searchParams.get('historyVersionId')))
        }

        window.localBridge?.bindLocateHistory?.(locateHistory)

        // 下层 UI 开始渲染
        setDocReady(true)
    }

    useEffect(() => {
        if (documentLoaded) {
            afterDocumentLoadedCallback()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentLoaded])

    return docReady ? <UIRoot ctx={{ ...ctx }}></UIRoot> : null
}
