import { isEnglishLanguage } from '../../../../util/src'
import styles from './util.module.less'
import { translation } from './util.translation'

/**
 *
 * @param seatType 席位类型
 * @param isAdmin 是否为管理员
 * @returns 「管理员」 / 「自动升级」 的升级席位弹窗中的内容描述.
 *          形如：这是一个计费动作，作为管理员，你将升级自己为「研发席位」。
 */

export function getDescription(seatType: 'dev' | 'design', isAdmin: boolean) {
    const describePrefix = isAdmin ? translation('Admin') : translation('Autograde')
    const describeSeat = seatType == 'design' ? translation('DesignSeat') : translation('DevSeat')
    const inEnglist = isEnglishLanguage()

    return (
        <div className={styles.text}>
            <span>{describePrefix}</span>
            <span className={inEnglist ? styles.bold : ''}>{describeSeat}</span>
        </div>
    )
}

export function getAdminUpgradeSelfDescription(seatType: 'dev' | 'design') {
    return seatType == 'dev' ? translation('ApplyDevSeat') : translation('ApplyDesignSeat')
}
