import { domLocation } from '../../../../util/src'
import { IDBPDatabase, IDBPObjectStore, StoreNames } from 'idb'
import { openEnhancedIndexedDB } from '../../web-storage/indexed-db/storage'
import { IndexedDBName } from '../../web-storage/indexed-db/config'
import { MotiffUserConfigSchema } from '../../web-storage/indexed-db/config/schema'
import { WkCLog } from '../../kernel/clog/wukong/instance'

const DB_NAME = 'motiff-user-config'
const USER_CONFIG_STORE = 'user-config'
const DB_VERSION = 1

export async function openUserConfigDB(version = DB_VERSION): Promise<IDBPDatabase<MotiffUserConfigSchema>> {
    const db = await openEnhancedIndexedDB({
        version,
        name: IndexedDBName.MotiffUserConifg,
        callback: {
            upgrade: (database, oldVersion, newVersion) => {
                WkCLog.log(`openDB: ${DB_NAME}, upgrade version from ${oldVersion} to ${newVersion}`)
                if (!database.objectStoreNames.contains(USER_CONFIG_STORE)) {
                    database.createObjectStore(USER_CONFIG_STORE, {
                        keyPath: ['key'],
                    })
                }
            },
        },
    })

    db.onversionchange = (e) => {
        if (e.newVersion && e.newVersion > e.oldVersion) {
            db.close()
            domLocation().reload()
        }
    }

    return db
}

export async function openUserConfigDbWithRetry() {
    let retryTimes = 0,
        error = null
    for (; retryTimes < 5; ) {
        try {
            return await openUserConfigDB()
        } catch (e) {
            WkCLog.log(`Failed to open ${DB_NAME} , retryTimes=${retryTimes}, error=${e}`)
            error = e
        }
        retryTimes++
    }
    throw error
}

export async function getUserConfigStore(): Promise<
    IDBPObjectStore<
        MotiffUserConfigSchema,
        ArrayLike<StoreNames<MotiffUserConfigSchema>>,
        StoreNames<MotiffUserConfigSchema>,
        'readwrite'
    >
> {
    const tx = (await openUserConfigDbWithRetry()).transaction(USER_CONFIG_STORE, 'readwrite')
    tx.oncomplete = () => {
        tx.db.close()
    }
    return tx.objectStore(USER_CONFIG_STORE)
}
