import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
    Checkbox,
    DraggablePopupV2,
    InputV2,
    MonoIconPanelComponentLibrary16,
    MonoIconPanelGrid16,
    MonoIconPanelList16,
    ScrollContainer,
    ScrollView,
    Select,
    Tooltip,
    useEllipsisTooltip,
    WKButton,
    WKIconButton,
} from '../../../../../../../../ui-lib/src'
import { sortArrayByKey } from '../../../../../../../../util/src'
import { useLibraryComponentService } from '../../../../../../main/app-context'
import { LibraryThumbnailImage } from '../../../../../../share/component-style-library/library-thumbnail-image'
import { ComponentPropTestId, ReplaceInstancePanelTestId as ReplaceTestIds } from '../../../../../../window'
import { useComponentInstanceCommand } from '../../commands'
import {
    ReplaceInstanceLibraryList,
    ReplaceInstanceNodeData,
    ReplaceInstanceSearchResults,
    ReplaceInstanceState,
    ReplaceInstanceTree,
} from '../../types'
import { IconBack, IconFolder, IconForward } from '../icons'
import { useComponentPickerCommand } from './command'
import { useComponentPickerViewMode } from './hooks'
import style from './index.module.less'
import { ComponentPickerViewMode } from './type'

export enum ComponentPickerTrigger {
    None,
    InstanceSwapValue,
    PreferredValues,
    ReplaceInstance,
}

const FrontTriggerToWasmTrigger = {
    [ComponentPickerTrigger.InstanceSwapValue]:
        Wukong.DocumentProto.ComponentPickerTrigger.COMPONENT_PICKER_TRIGGER_INSTANCE_SWAP_VALUE,
    [ComponentPickerTrigger.PreferredValues]:
        Wukong.DocumentProto.ComponentPickerTrigger.COMPONENT_PICKER_TRIGGER_PREFERRED_VALUES,
    [ComponentPickerTrigger.ReplaceInstance]:
        Wukong.DocumentProto.ComponentPickerTrigger.COMPONENT_PICKER_TRIGGER_REPLACE_INSTANCE,
    [ComponentPickerTrigger.None]: Wukong.DocumentProto.ComponentPickerTrigger.COMPONENT_PICKER_TRIGGER_NONE,
}

function ThumbnailListItem(
    props: ReplaceInstanceNodeData & {
        docId: string
        showCheckbox: boolean
        triggerScene: ComponentPickerTrigger
        handleThumbnailClick: (node: ReplaceInstanceNodeData & { docId: string }) => void
    }
) {
    const commands = useComponentPickerCommand()
    const [hovered, setHovered] = useState(false)

    const { inactivation, ellipsisRef, onmouseenter, onmouseleave } = useEllipsisTooltip<HTMLDivElement>()
    const formatDescription = props.description !== props.name ? props.description : undefined

    return (
        <Tooltip
            placement="left"
            overlay={
                <div className={style.thumbnailTooltip}>
                    <p className="my-0" data-testid={ReplaceTestIds.thumbnailTooltipName}>
                        {props.name}
                    </p>
                    {!!formatDescription && (
                        <p
                            className={classNames({ ['mt-1']: !inactivation }, 'mb-0')}
                            data-testid={ReplaceTestIds.thumbnailTooltipDesc}
                        >
                            {formatDescription}
                        </p>
                    )}
                </div>
            }
            inactivation={inactivation && !formatDescription}
        >
            <ScrollView.Item
                isSelectFn={() => props.isSelected}
                uniqueKey={props.nodeId}
                className={classNames(style.listModeItem, {
                    [style.listModeItemSelected]: props.isSelected && !props.showCheckbox,
                    [style.listModeItemHovered]: hovered && (!props.isSelected || props.showCheckbox),
                })}
                onMouseEnter={(e) => {
                    setHovered(true)
                    onmouseenter(e)
                }}
                onMouseLeave={() => {
                    setHovered(false)
                    onmouseleave()
                }}
                onClick={() => {
                    const value = { ...props, docId: props.docId || props.documentId }
                    if (props.triggerScene === ComponentPickerTrigger.InstanceSwapValue) {
                        commands.selectComponent(value).then((nodeId) => {
                            props.handleThumbnailClick({
                                ...value,
                                nodeId: nodeId.value ?? '',
                            })
                        })
                    } else if (props.triggerScene === ComponentPickerTrigger.PreferredValues) {
                        props.handleThumbnailClick(value)
                    } else if (props.triggerScene === ComponentPickerTrigger.ReplaceInstance) {
                        commands.replaceMainCompId(value)
                        props.handleThumbnailClick(value) // 此处用于关闭弹窗，实际不执行
                    }
                }}
                data-testid={
                    props.isSelected
                        ? ComponentPropTestId.ComponentPicker.currentPreviewImage(props.name)
                        : ComponentPropTestId.ComponentPicker.previewImage(props.name)
                }
            >
                <div className={style.image}>
                    {JSON.stringify(props.thumbnailData) !== '{}' && (
                        <LibraryThumbnailImage thumbnailData={props.thumbnailData} />
                    )}
                </div>
                <div className={style.name} ref={ellipsisRef}>
                    {props.name}
                </div>
                {props.showCheckbox && <Checkbox checked={props.isSelected} className="ml-2" />}
            </ScrollView.Item>
        </Tooltip>
    )
}

function ThumbnailGridItem(
    props: ReplaceInstanceNodeData & {
        docId: string
        showCheckbox: boolean
        triggerScene: ComponentPickerTrigger
        handleThumbnailClick: (node: ReplaceInstanceNodeData & { docId: string }) => void
    }
) {
    const { inactivation, ellipsisRef, onmouseenter, onmouseleave } = useEllipsisTooltip<HTMLDivElement>()
    const formatDescription = props.description !== props.name ? props.description : undefined
    const isSelectFn = useCallback(() => props.isSelected, [props.isSelected])
    const nameInactivation =
        inactivation &&
        props.layoutSizeType !== Wukong.DocumentProto.LibraryLayoutSizeType.LIBRARY_LAYOUT_SIZE_TYPE_SMALL
    const commands = useComponentPickerCommand()

    return (
        <Tooltip
            placement="left"
            overlay={
                <div className={style.thumbnailTooltip}>
                    <p className="my-0" data-testid={ReplaceTestIds.thumbnailTooltipName}>
                        {props.name}
                    </p>
                    {!!formatDescription && (
                        <p
                            className={classNames({ ['mt-1']: !inactivation }, 'mb-0')}
                            data-testid={ReplaceTestIds.thumbnailTooltipDesc}
                        >
                            {formatDescription}
                        </p>
                    )}
                </div>
            }
            inactivation={nameInactivation && !formatDescription}
        >
            <ScrollView.Item
                isSelectFn={isSelectFn}
                uniqueKey={props.nodeId}
                className={classNames(style.thumbnail, style[`size${props.layoutSizeType}`])}
                onClick={() => {
                    const value = { ...props, docId: props.docId || props.documentId }
                    if (props.triggerScene === ComponentPickerTrigger.InstanceSwapValue) {
                        commands.selectComponent(value).then((nodeId) => {
                            props.handleThumbnailClick({
                                ...value,
                                nodeId: nodeId.value ?? '',
                            })
                        })
                    } else if (props.triggerScene === ComponentPickerTrigger.PreferredValues) {
                        props.handleThumbnailClick(value)
                    } else if (props.triggerScene === ComponentPickerTrigger.ReplaceInstance) {
                        commands.replaceMainCompId(value)
                        props.handleThumbnailClick(value) // 此处用于关闭弹窗，实际不执行
                    }
                }}
                style={{
                    width: props.width,
                }}
                onMouseEnter={onmouseenter}
                onMouseLeave={onmouseleave}
            >
                <div
                    data-testid={
                        props.isSelected
                            ? ComponentPropTestId.ComponentPicker.currentPreviewImage(props.name)
                            : ComponentPropTestId.ComponentPicker.previewImage(props.name)
                    }
                    className={`${style.thumbnailImage} ${props.isSelected && style.thumbnailImageSelected}`}
                    style={{
                        height: props.height,
                    }}
                >
                    {JSON.stringify(props.thumbnailData) !== '{}' && (
                        <LibraryThumbnailImage thumbnailData={props.thumbnailData} />
                    )}
                </div>
                {props.layoutSizeType !== Wukong.DocumentProto.LibraryLayoutSizeType.LIBRARY_LAYOUT_SIZE_TYPE_SMALL && (
                    <div className={style.thumbnailText} ref={ellipsisRef}>
                        {props.name}
                    </div>
                )}
                {props.showCheckbox && <Checkbox className={style.gridModeItemCheckbox} checked={props.isSelected} />}
            </ScrollView.Item>
        </Tooltip>
    )
}

function ThumbnailItem(
    props: ReplaceInstanceNodeData & {
        docId: string
        viewMode: ComponentPickerViewMode
        showCheckbox: boolean
        triggerScene: ComponentPickerTrigger
        handleThumbnailClick: (node: ReplaceInstanceNodeData & { docId: string }) => void
    }
) {
    return props.viewMode === 'grid' ? <ThumbnailGridItem {...props} /> : <ThumbnailListItem {...props} />
}

export function ListItem(props: {
    head?: React.ReactNode
    body?: React.ReactNode
    tail?: React.ReactNode
    onClick?: () => void
    testId?: string
    showCheckbox: boolean
    checkboxVal?: 'checked' | 'unchecked' | 'indeterminate'
    handleCheckboxClick?: (v: boolean) => void
}) {
    return (
        <div className={style.listItem} onClick={props.onClick} data-testid={props.testId}>
            <div className={style.head}>{props.head}</div>
            <div className={style.body}>{props.body}</div>
            <div className={style.tail}>{props.tail}</div>
            {props.showCheckbox && props.checkboxVal && (
                <Checkbox
                    indeterminate={props.checkboxVal === 'indeterminate'}
                    checked={props.checkboxVal === 'checked'}
                    stopPropagation
                    onChange={props.handleCheckboxClick}
                    containerTestId={ComponentPropTestId.ComponentPicker.currentFolderCheckBox}
                />
            )}
        </div>
    )
}

export interface FolderTree {
    parent: number
    folderChildren: number[]
    name: string
    nodes: ReplaceInstanceTree['nodes']
    checkboxVal: 'checked' | 'unchecked' | 'indeterminate'
}

export function buildFolderTree(root?: ReplaceInstanceTree) {
    const flattenFolders: FolderTree[] = []
    let selectedFolderIndex = -1
    const selectedFolderPaths: Record<number, number[][]> = {}

    const buildFromInstanceTree = (tree: ReplaceInstanceTree, parentPath = [-1]) => {
        const index = flattenFolders.length
        const currentPath = [...parentPath, index]

        // 处理选中路径
        if (tree.nodes.some((node) => node.isSelected)) {
            const pathLength = currentPath.length
            if (!selectedFolderPaths[pathLength]) {
                selectedFolderPaths[pathLength] = [currentPath]
            } else {
                selectedFolderPaths[pathLength].push(currentPath)
            }
        }

        // 处理 checkbox 状态
        const selectedNodesCount = tree.nodes.reduce((count, node) => count + (node.isSelected ? 1 : 0), 0)
        const checkboxVal: FolderTree['checkboxVal'] =
            selectedNodesCount === tree.nodes.length
                ? 'checked'
                : selectedNodesCount > 0
                ? 'indeterminate'
                : 'unchecked'

        flattenFolders.push({
            ...tree,
            parent: parentPath[parentPath.length - 1],
            folderChildren: [],
            checkboxVal,
        })

        flattenFolders.at(-1)!.folderChildren = tree.folderChildren.map((child) =>
            buildFromInstanceTree(child, currentPath)
        )

        return index
    }

    if (root) {
        buildFromInstanceTree(root)
    }

    // 找到所有选中节点路径的共同祖先
    const findCommonAncestor = (paths: Record<number, number[][]>): number => {
        const pathKeys = Object.keys(paths).map(Number)
        if (pathKeys.length === 0) return -1

        const minKey = Math.min(...pathKeys)
        const minPaths = paths[minKey]

        for (let col = 0; col < minPaths[0].length; col++) {
            const firstParent = minPaths[0][col]
            if (minPaths.some((path) => path[col] !== firstParent)) {
                return minPaths[0][col - 1]
            }
        }

        return minPaths[0][minPaths[0].length - 1]
    }

    selectedFolderIndex = findCommonAncestor(selectedFolderPaths)
    selectedFolderIndex = Math.max(selectedFolderIndex, 0)

    return { flattenFolders, selectedFolderIndex }
}

function ReplacePanelFoldes({
    library,
    onSetStickyTitle,
    handleThumbnailClick,
    triggerScene,
    viewMode,
    showCheckbox,
    handleFolderCheckboxClick,
}: {
    library: ReplaceInstanceLibraryList[0]
    onSetStickyTitle: (content: ReactNode) => void
    handleThumbnailClick: (node: ReplaceInstanceNodeData & { docId: string }) => void
    triggerScene: ComponentPickerTrigger
    viewMode: ComponentPickerViewMode
    showCheckbox: boolean
    handleFolderCheckboxClick?: (checked: boolean, nodes: (ReplaceInstanceNodeData & { docId: string })[]) => void
}) {
    const [defaultTree] = useState(() => buildFolderTree(library.tree))
    const [allFolders, setFolders] = useState<FolderTree[]>(defaultTree.flattenFolders)
    const [folderIndex, setFolderIndex] = useState<number>(defaultTree.selectedFolderIndex)

    const currentFolder = allFolders.at(folderIndex)
    const onUpperFolder = useCallback(() => {
        setFolderIndex(currentFolder?.parent ?? 0)
    }, [currentFolder?.parent])

    const sortedNodes = useMemo(() => {
        return library.sortingNodes
            ? sortArrayByKey(currentFolder?.nodes.slice() ?? [], 'name')
            : currentFolder?.nodes ?? []
    }, [currentFolder, library.sortingNodes])

    const handleFolderCheckboxClick_ = useCallback(
        (v: boolean) => {
            handleFolderCheckboxClick?.(
                v,
                sortedNodes.map((node) => ({ ...node, docId: library.docId }))
            )
        },
        [sortedNodes, handleFolderCheckboxClick, library.docId]
    )

    useEffect(() => {
        setFolders(buildFolderTree(library.tree).flattenFolders)
    }, [library])

    useEffect(() => {
        if (currentFolder?.name !== undefined && currentFolder?.parent >= 0) {
            onSetStickyTitle(
                <ListItem
                    head={<IconBack />}
                    body={currentFolder?.name}
                    testId={ReplaceTestIds.upper}
                    onClick={onUpperFolder}
                    showCheckbox={showCheckbox && currentFolder.nodes.length > 0}
                    checkboxVal={currentFolder?.checkboxVal}
                    handleCheckboxClick={handleFolderCheckboxClick_}
                />
            )
        } else {
            onSetStickyTitle(null)
        }
    }, [currentFolder, showCheckbox, onSetStickyTitle, onUpperFolder, handleFolderCheckboxClick_])

    useEffect(() => {
        return () => {
            onSetStickyTitle(null)
        }
    }, [onSetStickyTitle])

    return (
        <div data-testid={ReplaceTestIds.currentFolder(currentFolder?.name ?? 'none')}>
            {currentFolder?.folderChildren.map((childFolderIndex) => {
                const folder = allFolders.at(childFolderIndex)
                return (
                    folder && (
                        <ListItem
                            key={folder.name}
                            head={<IconFolder />}
                            body={folder.name}
                            tail={<IconForward />}
                            onClick={() => setFolderIndex(childFolderIndex)}
                            testId={ReplaceTestIds.folder(folder.name)}
                            showCheckbox={false}
                        />
                    )
                )
            })}
            {currentFolder && sortedNodes.length > 0 && (
                <div className={viewMode === 'grid' ? style.thumbnailList : undefined}>
                    {sortedNodes.map(({ key, ...node }) => (
                        <ThumbnailItem
                            {...node}
                            docId={library.docId ?? ''}
                            key={node.nodeId || key}
                            handleThumbnailClick={handleThumbnailClick}
                            triggerScene={triggerScene}
                            viewMode={viewMode}
                            showCheckbox={showCheckbox}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

const SINGLE_SELECTION_TRIGGERS = [ComponentPickerTrigger.InstanceSwapValue, ComponentPickerTrigger.ReplaceInstance]

export function ComponentPicker(props: {
    state: ReplaceInstanceState
    position?: {
        left: number
        top: number
    }
    triggerScene: ComponentPickerTrigger
    onCancel: () => void
    handleThumbnailClick: (node: ReplaceInstanceNodeData & { docId: string }) => void
    handleFolderCheckboxClick?: (checked: boolean, nodes: (ReplaceInstanceNodeData & { docId: string })[]) => void
}) {
    const { onCancel, triggerScene, handleThumbnailClick, handleFolderCheckboxClick } = props
    const showCloseBtn = triggerScene === ComponentPickerTrigger.PreferredValues
    const showCheckbox = triggerScene === ComponentPickerTrigger.PreferredValues

    const commands = useComponentInstanceCommand()
    const defaultIndex = useMemo(
        () => props.state.allLibraryList.findIndex((lib) => lib.isSelected),
        [props.state.allLibraryList]
    )
    const [selectedIndex, setSelectedIndex] = useState<number>(Math.max(defaultIndex, 0))

    useEffect(() => {
        if (defaultIndex > -1 && SINGLE_SELECTION_TRIGGERS.includes(triggerScene)) {
            setSelectedIndex(defaultIndex)
        }
    }, [defaultIndex, triggerScene])

    const selectedLibrary = props.state.allLibraryList.at(selectedIndex)

    const [searchResults, setSearchResults] = useState<ReplaceInstanceSearchResults>([])
    const [searchText, setSearchText] = useState('')
    const { viewMode, setViewMode } = useComponentPickerViewMode()

    const onSearchList = useCallback(
        (searchInput: string) => {
            setSearchText(searchInput)
            searchInput &&
                setSearchResults(commands.searchByKeyword(searchInput, FrontTriggerToWasmTrigger[triggerScene]))
        },
        [commands, triggerScene]
    )

    const onChangeLibraryIndex = (index: number) => {
        setSelectedIndex(Math.max(0, index))
    }

    const _onCancel = useCallback(() => {
        onCancel()
        onSearchList('')
    }, [onCancel, onSearchList])

    const isPopupMovedRef = useRef(false)

    const markPopupMoved = useCallback(() => {
        isPopupMovedRef.current = true
    }, [])

    const _handleThumbnailClick = useCallback(
        (node: ReplaceInstanceNodeData & { docId: string }) => {
            handleThumbnailClick(node)
            if (SINGLE_SELECTION_TRIGGERS.includes(triggerScene) && !isPopupMovedRef.current) {
                _onCancel()
            }
            // 在搜索场景下需要重新搜索展示最新的选择状态
            searchText &&
                setSearchResults(commands.searchByKeyword(searchText, FrontTriggerToWasmTrigger[triggerScene]))
        },
        [handleThumbnailClick, _onCancel, commands, searchText, triggerScene]
    )

    const [stickyTitle, setStickyTitle] = useState<ReactNode>(null)
    useEffect(() => {
        if (!selectedLibrary) {
            setStickyTitle(null)
        }
    }, [selectedLibrary])

    const {
        libraryModalRouterService: { goToRemoteLibraryHome },
    } = useLibraryComponentService()

    return (
        <DraggablePopupV2
            visible
            position={props.position}
            width={216}
            positionRightBase={triggerScene === ComponentPickerTrigger.ReplaceInstance}
            onCancel={_onCancel}
            onKeyDown={(e) => {
                if (e.code === 'Escape') {
                    _onCancel()
                    e.stopPropagation() // 防止关闭外部弹窗
                }
            }}
            headerClassName={classNames(style.componentPickerHeader, { [style.closeBtnExistence]: showCloseBtn })}
            header={
                <div data-testid={ReplaceTestIds.title} className={style.modalTitle}>
                    <div className={style.selectWrapper}>
                        <Select.MinimalSingleLevel
                            className={style.librarySelect}
                            weight="semibold"
                            value={selectedIndex}
                            label={selectedLibrary?.name}
                            onChange={onChangeLibraryIndex}
                            dataTestIds={{
                                triggerFocus: ComponentPropTestId.ComponentPicker.librarySelectTrigger,
                                scrollContent: ComponentPropTestId.ComponentPicker.librarySelectList,
                            }}
                        >
                            {props.state.allLibraryList?.map((v, i) => (
                                <Select.MinimalSingleLevel.Option
                                    value={i}
                                    key={i}
                                    style={{ maxWidth: 200 }}
                                    splitLineBottom={v.hasDividerBelow}
                                >
                                    {v.name}
                                </Select.MinimalSingleLevel.Option>
                            ))}
                        </Select.MinimalSingleLevel>
                    </div>
                    <Tooltip title={viewMode === 'grid' ? translation('ShowAsList') : translation('ShowAsGrid')}>
                        <WKIconButton
                            data-testid="toggle-component-picker-view-mode"
                            onClick={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}
                            icon={
                                viewMode === 'grid' ? (
                                    <MonoIconPanelList16
                                        data-testid={ComponentPropTestId.ComponentPicker.listViewIcon}
                                    />
                                ) : (
                                    <MonoIconPanelGrid16
                                        data-testid={ComponentPropTestId.ComponentPicker.gridViewIcon}
                                    />
                                )
                            }
                        />
                    </Tooltip>
                </div>
            }
            bodyClassName="p-0"
            footer={null}
            maxScrollHeight={352}
            minScrollHeight={352}
            onFirstMove={markPopupMoved}
            testId={ReplaceTestIds.popup}
            closeTestId={ReplaceTestIds.closeBtn}
            styleType="editor"
            closable={showCloseBtn}
        >
            <ScrollContainer
                className={style.scrollContainer}
                scrollViewProps={{ scrollbar: { autoHeight: true, autoHeightMin: 352, autoHeightMax: 352 } }}
                stickyHeader={
                    <>
                        <div className={style.searchInput}>
                            <InputV2.Search
                                onSearch={onSearchList}
                                onBlur={(e) => onSearchList(e.target.value.trim())}
                                placeholder={translation('Search')}
                                dataTestIds={{
                                    input: ComponentPropTestId.ComponentPicker.searchInput,
                                    clearIcon: ComponentPropTestId.ComponentPicker.searchInputClear,
                                }}
                                autoFocus
                            />
                        </div>
                        {stickyTitle}
                    </>
                }
            >
                {searchText ? (
                    <div data-testid={ComponentPropTestId.ComponentPicker.searchResult}>
                        {searchResults.length > 0 ? (
                            <>
                                <div className={style.searchTitle}>{translation('SearchResult')}</div>
                                <div className={viewMode === 'grid' ? style.thumbnailList : undefined}>
                                    {searchResults.map((node) => (
                                        <ThumbnailItem
                                            {...node}
                                            docId={node.documentId}
                                            key={`${node.documentId}-${node.nodeId}`}
                                            handleThumbnailClick={_handleThumbnailClick}
                                            triggerScene={triggerScene}
                                            viewMode={viewMode}
                                            showCheckbox={showCheckbox}
                                        />
                                    ))}
                                </div>
                            </>
                        ) : (
                            <div className={style.noResult} data-testid={ReplaceTestIds.noResult}>
                                {translation('ComponentNotFound')}
                            </div>
                        )}
                    </div>
                ) : selectedLibrary ? (
                    selectedLibrary.showLocalNoComponents ? (
                        <div
                            className={style.localEmpty}
                            data-testid={ComponentPropTestId.ComponentPicker.noLocalComponents}
                        >
                            <div className={style.localEmptyText}>{translation('NoLocalComponents')}</div>
                            <div className={style.localEmptyButton}>
                                <WKButton
                                    type="secondary"
                                    onClick={goToRemoteLibraryHome}
                                    icon={<MonoIconPanelComponentLibrary16 />}
                                >
                                    {translation('BrowseLibraries')}
                                </WKButton>
                            </div>
                        </div>
                    ) : (
                        <ReplacePanelFoldes
                            library={selectedLibrary}
                            key={selectedLibrary?.docId}
                            onSetStickyTitle={setStickyTitle}
                            handleThumbnailClick={_handleThumbnailClick}
                            triggerScene={triggerScene}
                            viewMode={viewMode}
                            showCheckbox={showCheckbox}
                            handleFolderCheckboxClick={handleFolderCheckboxClick}
                        />
                    )
                ) : null}
            </ScrollContainer>
        </DraggablePopupV2>
    )
}
