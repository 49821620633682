export function generateKey() {
    return `${Date.now().toString(36)}_${Math.random().toString(36)}`
}

export function createHTMLDivElement(properties: { id?: string; class?: string; dataTestId?: string }) {
    const element = document.createElement('div')
    if (properties.id !== undefined) {
        element.id = properties.id
    }
    if (properties.class !== undefined) {
        element.classList.add(properties.class)
    }
    if (properties.dataTestId !== undefined) {
        element.dataset.testid = properties.dataTestId
    }
    return element
}

/**
 * @description 查找并返回指定两个节点路径间的指定的dataset属性
 */
export function findDatasetPropertyInPath(
    startPointElement: HTMLElement,
    endPointElement: HTMLElement,
    propertyName: string
) {
    const propertyValues: string[] = []
    if (!endPointElement.contains(startPointElement)) {
        return propertyValues
    }
    let checkElement: HTMLElement | null | undefined = startPointElement
    while (endPointElement.contains(checkElement)) {
        const propertyValue = checkElement?.dataset[propertyName]
        if (propertyValue !== undefined) {
            propertyValues.push(propertyValue)
        }
        checkElement = checkElement?.parentElement
    }
    return propertyValues
}
