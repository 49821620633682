export const getAppVersion = () => {
    const parts = navigator.userAgent.match(/MotiffApp(?:-Android|-AndroidPad)?\/((\d+\.)+(\d+))/)
    return parts ? parts[1] : '1.0.0'
}

export function isVersionGreaterThanOrEqualTo(targetVersion: string, baseVersion: string): boolean {
    const targetParts = targetVersion.split('.').map(Number)
    const baseParts = baseVersion.split('.').map(Number)

    for (let i = 0; i < Math.max(targetParts.length, baseParts.length); i++) {
        const targetPart = targetParts[i] || 0
        const basePart = baseParts[i] || 0

        if (targetPart > basePart) return true
        if (targetPart < basePart) return false
    }

    return true // 如果所有的部分都相等，则返回 true
}
