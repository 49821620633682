import { useSelectVersionId } from './history-list-provider'
import classes from './history-list.module.less'
import { AutoItemGroupComponent } from './items/auto-item-group-component'
import { CurrentItemComponent } from './items/current-item-component'
import { UserItemComponent } from './items/user-item-component'
import { AutoItemGroup, CurrentItem, ItemType, UserItem } from './type'

export interface HistoryListProps {
    items: (UserItem | AutoItemGroup)[]
    currentItem: CurrentItem
    dataTestIds?: {
        container?: string
    }
}

export function HistoryList(props: HistoryListProps) {
    const { items, currentItem, dataTestIds } = props
    const selectVersionId = useSelectVersionId()

    return (
        <div className={classes.historyList} data-testid={dataTestIds?.container}>
            <CurrentItemComponent item={currentItem} isFirstItem={true} isLastItem={items.length === 0} />
            {items.map((item, index) => {
                switch (item.type) {
                    case ItemType.UserItem:
                        return (
                            <UserItemComponent
                                key={item.versionId}
                                item={item}
                                isFirstItem={false}
                                isLastItem={index === items.length - 1}
                            />
                        )
                    case ItemType.AutoItemGroup: {
                        const isAutoExpend =
                            items.length === 1 ||
                            item.children.length === 1 ||
                            item.children.some((v) => v.origin.id === selectVersionId)
                        return (
                            <AutoItemGroupComponent
                                key={item.children[0].versionId}
                                item={item}
                                isFirstItem={false}
                                isLastItem={index === items.length - 1}
                                isAutoExpend={isAutoExpend}
                            />
                        )
                    }
                    default:
                        return null
                }
            })}
        </div>
    )
}
