/* eslint-disable no-restricted-imports */

import { RouteToken, domLocation } from '../../../../../util/src'
import { GetOrganizationAdmins } from '../../../kernel/request/organizations'
import { GetTeamsInEnterprise } from '../../../kernel/request/team'
import { GetWorkspacesSummary } from '../../../kernel/request/workspace'
import { SpaceNotifyFlow } from './notify-flow'
import { AllWorkspacesStore, SpaceStoreType, getSpaceStore, setSpaceStore } from './types'

export const generateAllWorkspacesState = (set: setSpaceStore, get: getSpaceStore): AllWorkspacesStore => {
    return {
        summary: {
            assignedWorkspaces: [],
            otherWorkspaces: [],
            unassignedTeams: [],
        },
        yourTeamsInEnterprise: [],
        admins: [],
        loading: true,
        initData: async () => {
            set((state) => {
                state.allWorkspacesStore.loading = true
            })
            await get().allWorkspacesStore.refresh()
            set((state) => {
                state.allWorkspacesStore.loading = false
            })
        },
        refresh: async () => {
            const orgId = get().organizationStore.organization.id
            const isGuest = get().organizationStore.organization.guest
            if (isGuest) {
                const [summary, teams] = await Promise.all([
                    new GetWorkspacesSummary(orgId).start(),
                    new GetTeamsInEnterprise(orgId).start(),
                ])
                set((state) => {
                    state.allWorkspacesStore.summary = summary
                    state.allWorkspacesStore.yourTeamsInEnterprise = teams
                })
            } else {
                const [summary, teams, admins] = await Promise.all([
                    new GetWorkspacesSummary(orgId).start(),
                    new GetTeamsInEnterprise(orgId).start(),
                    new GetOrganizationAdmins(orgId).start(),
                ])
                set((state) => {
                    state.allWorkspacesStore.summary = summary
                    state.allWorkspacesStore.yourTeamsInEnterprise = teams
                    state.allWorkspacesStore.admins = admins
                })
            }
        },
    }
}

const allWorkspacesPageRegex = new RegExp(`/${RouteToken.OrganizationWithoutOrgId}/([^/]+)/${RouteToken.Workspaces}$`)
const inWorkspacesPage = () => domLocation().pathname.match(allWorkspacesPageRegex)
export const syncAllWorkspacesState = (store: SpaceStoreType, notifyFlow: SpaceNotifyFlow) => {
    // 协同
    notifyFlow.addRelationChangeCallbackWithoutAccess(() => {
        if (inWorkspacesPage()) {
            store.getState().allWorkspacesStore.refresh()
        }
    })
    notifyFlow.addPropertyChangeCallbackWithoutDocAndUser(() => {
        if (inWorkspacesPage()) {
            store.getState().allWorkspacesStore.refresh()
        }
    })
    notifyFlow.addCreateWorkspaceCallback(() => {
        if (inWorkspacesPage()) {
            store.getState().allWorkspacesStore.refresh()
        }
    })
    notifyFlow.addRemoveWorkspaceCallback(() => {
        if (inWorkspacesPage()) {
            store.getState().allWorkspacesStore.refresh()
        }
    })
    notifyFlow.addUpdateWorkspaceCallback(() => {
        if (inWorkspacesPage()) {
            store.getState().allWorkspacesStore.refresh()
        }
    })
}
