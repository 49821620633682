import { Wukong } from '@wukong/bridge-proto'

import comment0 from '../icon/comment-0.svg'
import comment1 from '../icon/comment-1.svg'
import comment2 from '../icon/comment-2.svg'
import comment3 from '../icon/comment-3.svg'
import comment4 from '../icon/comment-4.svg'
import comment5 from '../icon/comment-5.svg'
import comment6 from '../icon/comment-6.svg'
import comment7 from '../icon/comment-7.svg'
import comment8 from '../icon/comment-8.svg'
import comment9 from '../icon/comment-9.svg'
import draw0 from '../icon/draw-0.svg'
import draw1 from '../icon/draw-1.svg'
import draw2 from '../icon/draw-2.svg'
import draw3 from '../icon/draw-3.svg'
import draw4 from '../icon/draw-4.svg'
import draw5 from '../icon/draw-5.svg'
import draw6 from '../icon/draw-6.svg'
import draw7 from '../icon/draw-7.svg'
import draw8 from '../icon/draw-8.svg'
import draw9 from '../icon/draw-9.svg'
import grab0 from '../icon/grab-0.svg'
import grab1 from '../icon/grab-1.svg'
import grab2 from '../icon/grab-2.svg'
import grab3 from '../icon/grab-3.svg'
import grab4 from '../icon/grab-4.svg'
import grab5 from '../icon/grab-5.svg'
import grab6 from '../icon/grab-6.svg'
import grab7 from '../icon/grab-7.svg'
import grab8 from '../icon/grab-8.svg'
import grab9 from '../icon/grab-9.svg'
import move0 from '../icon/move-0.svg'
import move1 from '../icon/move-1.svg'
import move2 from '../icon/move-2.svg'
import move3 from '../icon/move-3.svg'
import move4 from '../icon/move-4.svg'
import move5 from '../icon/move-5.svg'
import move6 from '../icon/move-6.svg'
import move7 from '../icon/move-7.svg'
import move8 from '../icon/move-8.svg'
import move9 from '../icon/move-9.svg'
import paint0 from '../icon/paint-0.svg'
import paint1 from '../icon/paint-1.svg'
import paint2 from '../icon/paint-2.svg'
import paint3 from '../icon/paint-3.svg'
import paint4 from '../icon/paint-4.svg'
import paint5 from '../icon/paint-5.svg'
import paint6 from '../icon/paint-6.svg'
import paint7 from '../icon/paint-7.svg'
import paint8 from '../icon/paint-8.svg'
import paint9 from '../icon/paint-9.svg'
import pen0 from '../icon/pen-0.svg'
import pen1 from '../icon/pen-1.svg'
import pen2 from '../icon/pen-2.svg'
import pen3 from '../icon/pen-3.svg'
import pen4 from '../icon/pen-4.svg'
import pen5 from '../icon/pen-5.svg'
import pen6 from '../icon/pen-6.svg'
import pen7 from '../icon/pen-7.svg'
import pen8 from '../icon/pen-8.svg'
import pen9 from '../icon/pen-9.svg'
import picker0 from '../icon/picker-0.svg'
import picker1 from '../icon/picker-1.svg'
import picker2 from '../icon/picker-2.svg'
import picker3 from '../icon/picker-3.svg'
import picker4 from '../icon/picker-4.svg'
import picker5 from '../icon/picker-5.svg'
import picker6 from '../icon/picker-6.svg'
import picker7 from '../icon/picker-7.svg'
import picker8 from '../icon/picker-8.svg'
import picker9 from '../icon/picker-9.svg'

const IconUrl: { [mode: number]: { [index: number]: string } } = {
    [Wukong.DocumentProto.CursorMode.CURSOR_MODE_DRAW]: {
        0: draw0,
        1: draw1,
        2: draw2,
        3: draw3,
        4: draw4,
        5: draw5,
        6: draw6,
        7: draw7,
        8: draw8,
        9: draw9,
    },
    [Wukong.DocumentProto.CursorMode.CURSOR_MODE_COMMENT]: {
        0: comment0,
        1: comment1,
        2: comment2,
        3: comment3,
        4: comment4,
        5: comment5,
        6: comment6,
        7: comment7,
        8: comment8,
        9: comment9,
    },
    [Wukong.DocumentProto.CursorMode.CURSOR_MODE_COLOR_PICK]: {
        0: picker0,
        1: picker1,
        2: picker2,
        3: picker3,
        4: picker4,
        5: picker5,
        6: picker6,
        7: picker7,
        8: picker8,
        9: picker9,
    },
    [Wukong.DocumentProto.CursorMode.CURSOR_MODE_PAINT]: {
        0: paint0,
        1: paint1,
        2: paint2,
        3: paint3,
        4: paint4,
        5: paint5,
        6: paint6,
        7: paint7,
        8: paint8,
        9: paint9,
    },
    [Wukong.DocumentProto.CursorMode.CURSOR_MODE_HAND_LOOSE]: {
        0: grab0,
        1: grab1,
        2: grab2,
        3: grab3,
        4: grab4,
        5: grab5,
        6: grab6,
        7: grab7,
        8: grab8,
        9: grab9,
    },
    [Wukong.DocumentProto.CursorMode.CURSOR_MODE_ARROW]: {
        0: move0,
        1: move1,
        2: move2,
        3: move3,
        4: move4,
        5: move5,
        6: move6,
        7: move7,
        8: move8,
        9: move9,
    },
    [Wukong.DocumentProto.CursorMode.CURSOR_MODE_PEN]: {
        0: pen0,
        1: pen1,
        2: pen2,
        3: pen3,
        4: pen4,
        5: pen5,
        6: pen6,
        7: pen7,
        8: pen8,
        9: pen9,
    },
} as const

export function getIconSrc(mode: Wukong.DocumentProto.CursorMode, colorIndex: number) {
    return IconUrl[mode][colorIndex - 1]
}
