import { translation } from './layout-grid-record.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { IconColLayout, IconGridLayout, IconRowLayout } from '../../../../../../ui-lib/src'
import { default as classnames } from 'classnames'
import { HTMLAttributes, useCallback, useMemo } from 'react'
import { IconButton } from '../../atom/button/icon-button'
import { SingleGrid } from '../../atom/grid/single-grid'
import style from './layout-grid-record.module.less'

export interface LayoutGridRecordProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
    layoutGrid: Wukong.DocumentProto.ILayoutGrid
    onMouseDownIcon?: () => void
    isMini?: boolean
    isLarge?: boolean
    disabled?: boolean
    hidden?: boolean
}

export function LayoutGridRecord(props: LayoutGridRecordProps) {
    const { layoutGrid, isMini, isLarge, disabled = false, onMouseDownIcon, hidden } = props
    const type = useMemo(() => {
        return layoutGrid.pattern
    }, [layoutGrid.pattern])

    const _onMouseDownIcon = useCallback(() => {
        if (!disabled) {
            onMouseDownIcon?.()
        }
    }, [disabled, onMouseDownIcon])

    return (
        <>
            <SingleGrid
                className={classnames(style.container, {
                    [style.disabled]: disabled,
                    [style.mini]: isMini,
                    [style.large]: isLarge,
                })}
            >
                <SingleGrid.Item className={style.item1}>
                    <LayoutGridIcon
                        type={type!}
                        onMouseDownIcon={_onMouseDownIcon}
                        hidden={hidden}
                        disabled={disabled}
                    ></LayoutGridIcon>
                </SingleGrid.Item>
                <SingleGrid.Item className={style.item2}>
                    <RecordText layoutGrid={layoutGrid} hidden={hidden} disabled={disabled}></RecordText>
                </SingleGrid.Item>
            </SingleGrid>
        </>
    )
}

interface LayoutGridIconProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
    type: Wukong.DocumentProto.LayoutGridPattern
    onMouseDownIcon: () => void
    hidden?: boolean
    disabled?: boolean
}
function LayoutGridIcon(props: LayoutGridIconProps) {
    const { type, onMouseDownIcon, hidden, disabled } = props
    const onMouseDown = useCallback((e: React.MouseEvent) => {
        e.preventDefault()
    }, [])

    switch (type) {
        case Wukong.DocumentProto.LayoutGridPattern.LAYOUT_GRID_PATTERN_GRID: {
            return (
                <IconButton
                    icon={<IconGridLayout />}
                    selected={false}
                    onMouseDown={onMouseDown}
                    onChange={onMouseDownIcon}
                    dataTestId="grid-icon"
                    className={hidden ? style.hidden : ''}
                    disabled={disabled}
                />
            )
        }
        case Wukong.DocumentProto.LayoutGridPattern.LAYOUT_GRID_PATTERN_ROWS: {
            return (
                <IconButton
                    icon={<IconRowLayout />}
                    selected={false}
                    onMouseDown={onMouseDown}
                    onChange={onMouseDownIcon}
                    className={hidden ? style.hidden : ''}
                    disabled={disabled}
                />
            )
        }
        case Wukong.DocumentProto.LayoutGridPattern.LAYOUT_GRID_PATTERN_COLUMNS: {
            return (
                <IconButton
                    icon={<IconColLayout />}
                    selected={false}
                    onMouseDown={onMouseDown}
                    onChange={onMouseDownIcon}
                    className={hidden ? style.hidden : ''}
                    disabled={disabled}
                />
            )
        }
    }
}

export interface RecordTextProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
    layoutGrid: Wukong.DocumentProto.ILayoutGrid
    hidden?: boolean
    disabled?: boolean
}

function RecordText(props: RecordTextProps) {
    const { layoutGrid, hidden, disabled } = props

    const description = useMemo(() => {
        const type = layoutGrid.pattern
        if (type === Wukong.DocumentProto.LayoutGridPattern.LAYOUT_GRID_PATTERN_GRID) {
            const sectionSize = layoutGrid.sectionSize
            return `${translation('Grid')}\u00a0` + sectionSize + 'px'
        } else {
            const count = layoutGrid.count === -1 ? '' : layoutGrid.count + '\u00a0'
            const sectionSize =
                layoutGrid.align === Wukong.DocumentProto.RowsColsLayoutGridAlign.ROWS_COLS_LAYOUT_GRID_ALIGN_STRETCH
                    ? translation('Auto')
                    : layoutGrid.sectionSize + 'px'
            const typeScript =
                type === Wukong.DocumentProto.LayoutGridPattern.LAYOUT_GRID_PATTERN_ROWS
                    ? `${translation('Rows')}\u00a0`
                    : `${translation('Columns')}\u00a0`
            return count + typeScript + sectionSize
        }
    }, [layoutGrid.align, layoutGrid.count, layoutGrid.pattern, layoutGrid.sectionSize])

    return (
        <span className={classnames(style.description, { [style.hidden]: hidden, [style.disabled]: disabled })}>
            {description}
        </span>
    )
}
