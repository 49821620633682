import {
    MulticolorIconCommonComponentsFile32,
    Tag,
    WKAlert,
    WKButton,
    WKDialog,
    WKTextButton,
} from '../../../../../ui-lib/src'
import { domLocation, getMotiffName } from '../../../../../util/src'
import { featureSwitchManager } from '../../../kernel/switch'
import { useComponentService } from '../../../main/app-context'
import { useComponentInstanceCommand } from '../design-panel-v2/component-instance-panel/commands'
import { useMenuBack } from '../top-area/menu/menu-config/back'
import type { JumpToFigmaModalParams } from './component-service'
import { translation } from './jump-to-figma-modal.translation'

export function JumpToFigmaModal() {
    return featureSwitchManager.isEnabled('asset-migration-mode') ? JumpToFigmaModalV2() : JumpToFigmaModalV1()
}

function JumpToFigmaModalV2() {
    const componentService = useComponentService()
    const componentCommands = useComponentInstanceCommand()
    const jumpToFigmaModal = componentService.states.use.jumpToFigmaModalState()
    const visible = jumpToFigmaModal.visible

    const { link } = useMenuBack()

    const openComponentMigration = () => {
        window.open(`${domLocation().origin}${link}?open-lib-migration=true`, '_blank')
    }

    return (
        <WKDialog
            visible={visible}
            okText={translation('RestoreMainComponent')}
            bodyStyle={{ padding: 0 }}
            title={translation('UnableGoToMain')}
            onOk={() => {
                componentCommands.forceRecoverFigmaComponentForInstance()
                componentService.closeJumpToFigmaModal()
            }}
            onCancel={componentService.closeJumpToFigmaModal}
            width={400}
            testId="figma-library-not-be-imported-dialog"
        >
            <div className="px-6 pt-5 pb-6 flex flex-col gap-4">
                <div className="wk-text-12 color-$wk-v2-label-color-gray-13 font-400">
                    {translation('UnableGoToMainDesc')}
                </div>
                <WKAlert.WithoutTitle iconWrapClassName={'self-start'}>
                    {translation('ComponentMigrationTip1')}
                    <WKTextButton type={'primary'} size={12} onClick={openComponentMigration}>
                        {translation('ComponentMigration')}
                    </WKTextButton>
                    {translation('ComponentMigrationTip2')}
                </WKAlert.WithoutTitle>
            </div>
        </WKDialog>
    )
}

function JumpToFigmaModalV1() {
    const componentService = useComponentService()
    const jumpToFigmaModal = componentService.states.use.jumpToFigmaModalState()
    const visible = jumpToFigmaModal.visible
    const data: Partial<JumpToFigmaModalParams> = jumpToFigmaModal.visible ? jumpToFigmaModal.data : {}

    return (
        <WKDialog
            visible={visible}
            okText={translation('OK')}
            bodyStyle={{ paddingTop: 16, paddingBottom: 12 }}
            title={translation('GoToMain')}
            cancelButtonProps={{ style: { display: 'none' } }}
            onOk={componentService.closeJumpToFigmaModal}
            onCancel={componentService.closeJumpToFigmaModal}
        >
            <div className="wk-text-12 py-1">
                <p className="mb-2">{translation('pbpmng', { name: getMotiffName() })}</p>
                <div className="pb-2">
                    <p className="m-0 wk-font-semibold" style={{ padding: '6px 0 8px' }}>
                        {translation('FigmaLibrary')}
                    </p>
                    <div className="py-3 px-4 wk-bg-white flex items-center">
                        <MulticolorIconCommonComponentsFile32 className="flex-none mr-2" />
                        <div className="flex-1 overflow-hidden mr-4">
                            <p className="mb-0 wk-text-12 truncate">{data.componentName}</p>
                            <Tag size="small" structure="line" color="gray" name={translation('NotImported')} />
                        </div>
                        <WKButton
                            size="small"
                            className="flex-none"
                            type="secondary"
                            onClick={() => {
                                window.open(`https://www.figma.com/file/${data.publishFile}/`, '_blank')
                            }}
                        >
                            {translation('ViewFile')}
                        </WKButton>
                    </div>
                </div>
            </div>
        </WKDialog>
    )
}
