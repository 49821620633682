/* eslint-disable no-restricted-imports */
import { IconLetterSpacing } from '../../../../../../ui-lib/src'
import { NumberUnit } from '../../../../document/node/node'
import { ScrubbableInputTextProps, ScrubbableTextInput } from './components/scrubbable-input-text'
import { useLetterSpacingFormatterTool } from './utils/use-letter-spacing-formatter-tool'
import { valueFilterUserConfig } from './utils/value-filter'

/**
 * @param props
 * @returns
 * @description text-letter-spacing 专用
 */
export function ScrubbableInputLetterSpacing(props: Omit<ScrubbableInputTextProps, 'formatter'>) {
    const formatter = useLetterSpacingFormatterTool(props)
    return (
        <ScrubbableTextInput
            {...props}
            formatter={formatter}
            valueFilter={props.value?.unit !== NumberUnit.Percent ? valueFilterUserConfig : undefined}
            icon={<IconLetterSpacing />}
            scrubbingDisabled={props.isMixed}
        />
    )
}
