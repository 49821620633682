import { translation } from './comment-toast.translation'
/* eslint-disable no-restricted-imports */
import { useCallback, useEffect, useRef } from 'react'
import { WKToast } from '../../../../../../ui-lib/src'
import { useCommentService } from '../../../../main/app-context'
import { CommentService } from '../comment-service/comment-service'
import { Comment } from '../type'

function useFailedComments(commentService: CommentService) {
    return commentService.createCommentService.useZustandStore.use.failedComments()
}

export function CommentToast() {
    const commentService = useCommentService()
    const failedComments = useFailedComments(commentService)
    const aliveToast = useRef<Set<number>>(new Set())

    const createFailedToast = useCallback(
        (comment: Comment) => {
            const { id: commentId, uuid: commentUUID } = comment.commentMetaData

            if (aliveToast.current.has(commentId)) {
                return
            }
            aliveToast.current.add(commentId)
            let id = ''
            const onClose = () => {
                WKToast.close(id)
                aliveToast.current.delete(commentId)
                commentService.createCommentService.tryRemoveFailedComment(commentUUID)
            }
            const onRetry = () => {
                WKToast.close(id)
                aliveToast.current.delete(commentId)
                commentService.retrySubmitCreateComment(commentUUID)
            }
            id = WKToast.error(translation('TPostYour'), {
                firstButton: { type: 'x', onClick: onClose },
                secondButton: { type: 'button', text: translation('Retry'), onClick: onRetry },
                duration: -1,
            })
        },
        [commentService]
    )

    useEffect(() => {
        for (const comment of failedComments.values()) {
            createFailedToast(comment)
        }
    }, [createFailedToast, failedComments])

    return null
}
