import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Color: '纯色',
    Gradient: '渐变',
    Image: '图片',
    BlendMode: '混合模式',
} as const

export const enTranslation = {
    Color: 'Solid',
    Gradient: 'Gradient',
    Image: 'Image',
    BlendMode: 'Blend mode',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
