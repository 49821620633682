import { ReplaceLibraryItemCommand, StartLibraryReplace, Wukong } from '@wukong/bridge-proto'
import { WKToast } from '../../../../../../ui-lib/src'
import { generateUniqString } from '../../../../../../util/src'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import { Bridge } from '../../../../kernel/bridge/bridge'
import { ServiceClass } from '../../../../kernel/util/service-class'
import { DocID } from '../../../../kernel/interface/type'
import { LibraryResourceOssClientType } from '../../../../share/component-style-library/service/library-resource-downloader'
import { ToastProgressMessage } from '../../top-area/tool-sync-status/sync-toast-progress-message/sync-toast-progress-message'
import { LibraryNodeDataService } from './library-node-data-service'
import { translation } from './library-replace-service.translation'

export class LibraryReplaceService extends ServiceClass {
    private replaceExecutorMap = new Map<string, LibraryReplaceExecutor>()

    constructor(
        private readonly commandInvoker: CommandInvoker,
        protected override readonly bridge: Bridge,
        private readonly libraryNodeDataService: LibraryNodeDataService
    ) {
        super(bridge)
        this.initBindJsCall()
    }

    public override destroy(): void {
        super.destroy()
        this.replaceExecutorMap.forEach((executor) => {
            executor.destroy()
        })
    }

    private initBindJsCall = () => {
        this.bindJsCall(StartLibraryReplace, this.startLibraryReplace)
    }

    // 开始替换组件库
    private startLibraryReplace = (arg: Wukong.DocumentProto.IArg_startLibraryReplace) => {
        const id = generateUniqString()
        const executor = new LibraryReplaceExecutor(
            this.libraryNodeDataService,
            this.commandInvoker,
            () => {
                executor.destroy()
                this.replaceExecutorMap.delete(id)
            },
            arg
        )
        this.replaceExecutorMap.set(id, executor)
    }
}

// 单次替换组件库执行器
class LibraryReplaceExecutor extends ServiceClass {
    // 替换进度
    private readonly replaceProgressInfo = {
        current: 0,
        total: 0,
    }

    // 替换进度 toastId
    private replaceProgressToastId: string | undefined

    constructor(
        private readonly libraryNodeDataService: LibraryNodeDataService,
        private readonly commandInvoker: CommandInvoker,
        private readonly afterInit: () => void,
        private readonly arg: Wukong.DocumentProto.IArg_startLibraryReplace
    ) {
        super()
        this.init()
    }

    public override destroy(): void {
        super.destroy()
        this.closeProgressToast()
    }

    private init = async () => {
        const { targetDocumentId, count, componentSetList, componentList, styleList } = this.arg
        if (targetDocumentId && count) {
            this.replaceProgressInfo.total = count
            this.openProgressToast()
            const args: (Wukong.DocumentProto.IArg_ReplaceLibraryItem | undefined)[] = []
            args.push(
                ...(await Promise.all([
                    ...(componentSetList ?? []).map(async (item) =>
                        this.buildReplaceSingleLibraryItemArg(
                            targetDocumentId,
                            item,
                            Wukong.DocumentProto.ReplaceLibraryItemType.REPLACE_LIBRARY_ITEM_TYPE_COMPONENT_SET
                        )
                    ),
                    ...(componentList ?? []).map(async (item) =>
                        this.buildReplaceSingleLibraryItemArg(
                            targetDocumentId,
                            item,
                            Wukong.DocumentProto.ReplaceLibraryItemType.REPLACE_LIBRARY_ITEM_TYPE_COMPONENT
                        )
                    ),
                ]))
            )
            args.push(
                ...(await Promise.all(
                    (styleList ?? []).map(async (item) =>
                        this.buildReplaceSingleLibraryItemArg(
                            targetDocumentId,
                            item,
                            Wukong.DocumentProto.ReplaceLibraryItemType.REPLACE_LIBRARY_ITEM_TYPE_STYLE
                        )
                    )
                ))
            )
            if (!this.isDestroy) {
                this.commandInvoker.DEPRECATED_invokeBridge(ReplaceLibraryItemCommand, {
                    value: args.filter((arg) => !!arg) as Wukong.DocumentProto.IArg_ReplaceLibraryItem[],
                })
            }
        } else {
            this.closeProgressToast()
        }

        this.afterInit()
    }

    // 开启替换进度 toast
    private openProgressToast = () => {
        this.closeProgressToast()
        this.replaceProgressToastId = WKToast.show(
            <ToastProgressMessage
                progress={undefined}
                progressMsg={translation('Swapping')}
                onProgressEnd={this.closeProgressToast.bind(this)}
            />,
            { duration: -1 }
        )
    }

    // 更新 toast 的进度
    private updateProgressToast = (progress: number) => {
        if (this.replaceProgressToastId) {
            WKToast.updateMessage(
                this.replaceProgressToastId,
                <ToastProgressMessage
                    progress={progress}
                    progressMsg={translation('Swapping')}
                    onProgressEnd={this.closeProgressToast}
                />
            )
        }
    }

    // 关闭替换进度 toast
    private closeProgressToast = () => {
        if (this.replaceProgressToastId) {
            const toastIdValue = this.replaceProgressToastId
            this.replaceProgressToastId = undefined
            // NOTE: 加 setTimeout 避免出现 Warning: Attempted to synchronously unmount a root while React was already rendering
            setTimeout(() => {
                WKToast.close(toastIdValue)
            }, 0)
        }
    }

    private buildReplaceSingleLibraryItemArg = async (
        targetDocumentId: DocID,
        item: Wukong.DocumentProto.IArg_startLibraryReplaceItem,
        originType: Wukong.DocumentProto.ReplaceLibraryItemType
    ) => {
        if (this.isDestroy) {
            return undefined
        }
        const fetchData = await this.libraryNodeDataService
            .fetchRemoteExportedDocument({
                remoteDocId: targetDocumentId,
                remoteNodeId: item.target?.publishId,
                isLocal: false,
                nodeDataPath: item.target?.nodeDataPath ?? '',
                localNodeId: undefined,
                ossClientType:
                    originType === Wukong.DocumentProto.ReplaceLibraryItemType.REPLACE_LIBRARY_ITEM_TYPE_STYLE
                        ? LibraryResourceOssClientType.Style
                        : LibraryResourceOssClientType.Component,
            })
            .catch(
                () =>
                    // NOTE: 替换暂时无须报错处理
                    null
            )

        this.replaceProgressInfo.current++
        this.updateProgressToast(
            Math.min((this.replaceProgressInfo.current * 100.0) / this.replaceProgressInfo.total, 100)
        )

        if (fetchData) {
            return {
                ...fetchData,
                originType,
                content: item,
            }
        }

        return undefined
    }
}
