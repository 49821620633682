import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    YouAreNow: '你已成为团队所有者',
    AreYouSure: '确定删除项目「{{folderName}}」？',
    ThisProjectAnd: '删除后，项目无法恢复，但项目下的文件可以在回收站中找到并恢复。',
    DeleteProject: '删除项目',
    RemoveFromSidebar: '项目已取消固定到侧边栏',
    AddToSidebar: '项目已固定到侧边栏',
    TeamHasBeen: '团队名已修改',
    TeamIconUpdated: '团队图标已修改',
    DeleteTeamFailed: '无法删除团队，请稍后重试',
    NetworkError: '网络异常，操作失败',
} as const

export const enTranslation = {
    YouAreNow: 'You are now the owner of the team',
    AreYouSure: 'Are you sure you want to delete the project "{{folderName}}"?',
    ThisProjectAnd:
        'The project will be permanently deleted, but the files in the project can be restored from the trash.',
    DeleteProject: 'Delete project',
    RemoveFromSidebar: 'Project removed from sidebar',
    AddToSidebar: 'Project added to sidebar',
    TeamHasBeen: 'Team has been renamed',
    TeamIconUpdated: 'Team icon updated',
    DeleteTeamFailed: 'An error occurred while deleting team. Try again later.',
    NetworkError: 'An error occurred while deleting team.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
