import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    MoveToTitle: '移动至团队以发布组件库',
    MoveToContext: '组件库只能在团队中发布，请将此文件移动至团队后发布。',
    Move: '移动',
} as const

export const enTranslation = {
    MoveToTitle: 'Move to team to publish library assets',
    MoveToContext: 'To publish a team library, move this file to a team.',
    Move: 'Move',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
