import { isEmoji, toCodePoints } from './emoji'
import { splitGrapheme } from './grapheme-split'
import { translation } from './string.translation'

export enum Priority {
    Symbol = 1,
    Emoji,
    Number,
    Letter,
    Chinese,
}

interface SortSegment {
    segment: string
    index: number
    priority?: Priority
}

// 验证字符串是否为纯英文+空格，如果是的话取首尾两字符串的首字母大写
export const checkLetterWithSpace = (str: string) => {
    const isLetterWithSpace = /^[a-zA-Z\s]*$/.test(str)
    const nicknameArr = str.trim().split(' ')
    if (isLetterWithSpace && nicknameArr.length > 1) {
        const first = nicknameArr[0].charAt(0).toUpperCase()
        const last = nicknameArr[nicknameArr.length - 1].charAt(0).toUpperCase()
        return `${first}${last}`
    }
}

const LetterRanges = [
    {
        start: 0x41,
        end: 0x5a,
    },
    {
        start: 0x61,
        end: 0x7a,
    },
]
const ChineseRanges = [
    {
        start: 0x4e00,
        end: 0x9fa5,
    },
]
const NumberRanges = [
    {
        start: 0x30,
        end: 0x39,
    },
]
const sortSegmentsMap = new Map<string, SortSegment[]>()

function isLetter(str: string) {
    const codePoints = toCodePoints(str)
    return LetterRanges.some((range) => {
        return codePoints.some((cp) => range.start <= cp && cp <= range.end)
    })
}

function isChinese(str: string) {
    const codePoints = toCodePoints(str)
    return ChineseRanges.some((range) => {
        return codePoints.some((cp) => range.start <= cp && cp <= range.end)
    })
}

function isNumber(str: string) {
    const codePoints = toCodePoints(str)
    return NumberRanges.some((range) => {
        return codePoints.some((cp) => range.start <= cp && cp <= range.end)
    })
}

function judgePriority(str: string) {
    if (isLetter(str)) {
        return Priority.Letter
    } else if (isChinese(str)) {
        return Priority.Chinese
    } else if (isNumber(str)) {
        return Priority.Number
    } else if (isEmoji(str)) {
        return Priority.Emoji
    }
    return Priority.Symbol
}

function getSortSegment(str: string, map?: Map<string, SortSegment[]>): SortSegment[] {
    if (!map) {
        return splitGrapheme(str)
    }
    if (!map.has(str)) {
        map.set(str, splitGrapheme(str))
    }
    return map.get(str)!
}

function getSegmentPriority(segment: SortSegment): Priority {
    if (segment.priority === undefined) {
        segment.priority = judgePriority(segment.segment)
    }
    return segment.priority
}

/**
 * 比较两个字符串 a, b，表示字符串a在排序顺序中是在b之前、之后还是相同。如果 a 在 b 之前返回负数，如果 a 在 b 之后返回正数，如果 a 和 b 相同返回 0
 * 字符串排序规则：符号 -> Emoji -> 数字（自然排序） -> 字母 -> 汉字
 * 数字自然排序指的是按数字大小进行排序，例如 12 > 5，所以 12 会排在 5 的后面
 */
export const compareString = (a: string, b: string, map?: Map<string, SortSegment[]>): number => {
    if (a === b) {
        return 0
    }

    if (!a) {
        return -1
    }

    if (!b) {
        return 1
    }

    const aSegments = getSortSegment(a, map)
    const bSegments = getSortSegment(b, map)
    const aPriority = getSegmentPriority(aSegments[0])
    const bPriority = getSegmentPriority(bSegments[0])

    if (aPriority !== bPriority) {
        return aPriority - bPriority
    }

    const length = Math.min(aSegments.length, bSegments.length)

    if (aPriority === Priority.Number) {
        const aNumber = parseInt(a)
        const bNumber = parseInt(b)
        const numberLength = aNumber.toString().length

        if (aNumber !== bNumber) {
            return aNumber - bNumber
        }

        return compareString(a.substring(numberLength), b.substring(numberLength), map)
    }

    for (let i = 0; i < length; i++) {
        const aPro = getSegmentPriority(aSegments[i])
        const bPro = getSegmentPriority(bSegments[i])
        if (aPro !== bPro) {
            return aPro - bPro
        } else if (aPro === Priority.Number) {
            return compareString(a.substring(aSegments[i].index), b.substring(bSegments[i].index), map)
        } else if (aSegments[i].segment !== bSegments[i].segment) {
            return aSegments[i].segment.localeCompare(bSegments[i].segment, 'zh')
        }
    }

    return aSegments.length - bSegments.length
}

/**
 * 比较一个数组，按照数组中key对应字符串进行排序，字符串排序规则同compareString
 * 返回排序好的数组
 */
export function sortArrayByKey<T>(list: T[], key: string | ((v: T) => string), cache?: boolean): T[] {
    if (!list.length) {
        return list
    }

    const getStr = (item: any) => {
        return typeof key === 'string' ? item?.[key] || '' : key(item)
    }

    const map = cache ? sortSegmentsMap : new Map<string, SortSegment[]>()

    list.forEach((item) => {
        const str = getStr(item)
        if (!map.has(str)) {
            map.set(str, splitGrapheme(str))
        }
    })

    return list.sort((a, b) => compareString(getStr(a), getStr(b), map))
}

export function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export function onlyCapitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export function joinStringListWithComma(list?: string[]) {
    return list?.join(translation('Comma')) ?? ''
}

export function splitStringWithComma(str?: string) {
    return str?.split(translation('Comma')).map((s) => s.trim()) ?? []
}
