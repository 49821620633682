import { Wukong } from '@wukong/bridge-proto'
import { useCallback, useEffect, useState } from 'react'
import { EffectStyleIconType } from '../../../../kernel/interface/component-style'
import { AIComponentReplaceSnapshot } from '../../../../main/ai-service/typings'
import { useAppContext } from '../../../../main/app-context'
import { EffectStyleIcon } from '../../design/effects/effect-style/effect-style-icon'
import styles from './tool-ai-replace-component-img.module.less'

export function ToolAiReplaceComponentImg({
    imgId,
    item,
    isStyle,
    isRadius,
    isGrid,
}: {
    imgId: string | null
    item: AIComponentReplaceSnapshot | null
    isStyle?: boolean
    isRadius?: boolean
    isGrid?: boolean
}) {
    const aiService = useAppContext().aiService

    const [imgSrc, setImgSrc] = useState<string>()

    const [effectStyleIconType, setEffectStyleIconType] = useState<Wukong.DocumentProto.EffectStyleIconType>()

    const getImg = useCallback(
        async (id: string) => {
            const url = await aiService.getLibraryResourceDownloader().fetchUrl(id)
            if (url) {
                setImgSrc(url)
            }
        },
        [aiService]
    )
    const updateEffectStyleIconType = useCallback(
        async (snapItem: AIComponentReplaceSnapshot) => {
            if (!snapItem) {
                return
            }
            const res = await aiService?.getStyleThumbnail(snapItem)
            if (!res) {
                return
            }
            setEffectStyleIconType(res.thumbnail.effectStyleIconType ?? undefined)
        },
        [aiService]
    )

    useEffect(() => {
        if (imgId) {
            getImg(imgId)
        } else if (item) {
            updateEffectStyleIconType(item)
        }
    }, [imgId, item, getImg, updateEffectStyleIconType])

    return (
        <>
            {effectStyleIconType !== undefined ? (
                <EffectStyleIcon iconType={effectStyleIconType as unknown as EffectStyleIconType} />
            ) : imgSrc ? (
                <div className={`${styles.imgWrapper} ${isStyle ? styles.styleImg : ''} `}>
                    <img
                        className={`${styles.img} ${isRadius ? styles.radius : ''} ${isGrid ? styles.gridBg : ''}`}
                        src={imgSrc}
                        draggable={false}
                    />
                </div>
            ) : null}
        </>
    )
}
