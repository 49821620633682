/**
 * @owner: chenxiangbj@kanyun.com
 */
import { Paragraph } from './paragraph'
import { Text } from './text'
import { Title } from './title'

export const WKTypography = {
    Text: Text,
    Paragraph: Paragraph,
    Title: Title,
}
