import { Wukong } from '@wukong/bridge-proto'

export interface VariableModeSelectProps {
    source: 'page' | 'layer'
    data: Wukong.DocumentProto.IVariableModeEntriesState
    onChange: (value: { variableSetIds: string[]; modeId?: string; type?: 'value' | 'mixed' | 'auto' }) => void
}

export type VariableSetModeSelectionState = Wukong.DocumentProto.IVVariableSetModeSelectionState
export type VariableSetModeState = Wukong.DocumentProto.IVVariableSetModeState
export const VariableSetModeSelectionType = Wukong.DocumentProto.VariableSetModeSelectionType
export const VariableSetModeConflictType = Wukong.DocumentProto.VariableSetModeConflictType
export type LocalVariableSetItem = Wukong.DocumentProto.ILocalVariableSetItem
export type RemoteVariableSetItem = Wukong.DocumentProto.IRemoteVariableSetItem
