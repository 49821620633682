import { translation } from './instance-panel.translation'
/* eslint-disable no-restricted-imports */
import { useEffect, useMemo, useRef, useState } from 'react'

import classNames from 'classnames'
import {
    DropdownV2,
    MonoIconLayerComponent16,
    MonoIconLayerInstance16,
    MonoIconPanelMainComponentOut16,
    MonoIconPanelMore16,
    MonoIconPanelParentLevel16,
    Select,
    Tooltip,
    WKButton,
    WKIconButton,
} from '../../../../../../../ui-lib/src'
import { PopupStateType } from '../../../../../document/node/node'
import { ShortcutKey, shortcutLabelMap } from '../../../../../kernel/interface/shortcut-key'
import { InstancePanelTestId as InstanceTestIds } from '../../../../../window'
import { frogEventToolsMenusAndActionsBasicAction } from '../../../../utils/tools-actions-frog'
import { SingleGrid } from '../../../atom/grid/single-grid'
import { useComponentInstanceCommand } from '../commands'
import { InstanceState, ReplaceInstanceState } from '../types'
import { ComponentConfigPopup } from './component-config-popup'
import { ComponentPicker, ComponentPickerTrigger } from './component-picker'
import { InstanceSwapComponentPropPill } from './component-prop-pill'
import { ComponentPropDragPopup } from './component-prop-popup'
import { useComponentProp } from './component-prop/hook'
import { useComponentTitlePanel } from './component-title-panel/hook'
import { InstanceAssignmentList } from './instance-assignment-list'
import style from './instance-panel.module.less'
import { InstanceSwapPropApplyBtn } from './instance-swap-prop-apply-btn'

enum InstanceMenuType {
    Detach = 'detach',
    PushChanges = 'pushChanges',
    noResetItem = 'noResetItem',
}

function InstancePanelV2(
    props: InstanceState & {
        noBottom?: boolean
        replaceState?: ReplaceInstanceState
        disableJumpToOrigin: boolean
    }
) {
    const commands = useComponentInstanceCommand()
    const [replacePosition, setReplacePosition] = useState<{ left: number; top: number }>()
    const replaceRef = useRef<HTMLDivElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const {
        popupType,
        showApplyIconForApplyInstanceSwapProp,
        showApplyIconForCreateInstanceSwapProp,
        showNormalPillOfInstanceSwap,
    } = useComponentProp()
    const [hoverDesc, setHoverDesc] = useState<boolean>(false)
    const [isTextOverflowing, setIsTextOverflowing] = useState<boolean>(false)
    const descRef = useRef<HTMLDivElement>(null)
    const { popupVisible, openComponentConfigPopup, closeComponentConfigPopup } = useComponentTitlePanel()

    const [isCompDescConfigPopupOpen, setIsCompDescConfigPopupOpen] = useState<boolean>(false)

    useEffect(() => {
        if (descRef.current) {
            setIsTextOverflowing(descRef.current.scrollWidth > descRef.current.clientWidth)
        }
        if (isCompDescConfigPopupOpen) {
            if (!props.compDesc && !props.compSetDesc) {
                setIsCompDescConfigPopupOpen(false)
                closeComponentConfigPopup()
            }
        }
    }, [props.compDesc, props.compSetDesc, closeComponentConfigPopup, isCompDescConfigPopupOpen])

    const popupPosition = useMemo(() => {
        if (popupVisible && containerRef.current) {
            const { left, top } = containerRef.current.getBoundingClientRect()
            return { left, top }
        }
    }, [popupVisible])

    if (!props.shouldDisplay) {
        return null
    }

    const shouldDisplayReplacePanel =
        props.replaceState?.shouldDisplay && popupType === PopupStateType.POPUP_STATE_TYPE_REPLACE_INSTANCE

    const shouldDisplayApplyPropIcon = showApplyIconForApplyInstanceSwapProp || showApplyIconForCreateInstanceSwapProp

    const showReplacePanel = () => {
        if (props.disableReplaceComponent) return
        if (!replaceRef.current) return

        const refPosition = replaceRef.current!.getBoundingClientRect()
        setReplacePosition({ left: refPosition.left + 216, top: refPosition.bottom + 8 })
        if (shouldDisplayReplacePanel) {
            commands.toggleReplacePanel(false)
        } else {
            commands.toggleReplacePanel(true)
        }
    }

    const onEnterChange = (value: unknown) => {
        switch (value) {
            case InstanceMenuType.Detach:
                commands.detachSelectedInstance()
                return
            case InstanceMenuType.PushChanges:
                commands.pushSelectedInstanceChangesToMainComp()
                return
        }

        for (const i in props.resetTypes) {
            if (value === 'reset' + props.resetTypes[i].name) {
                commands.resetInstanceProps(props.resetTypes[i].type)
                return
            }
        }

        for (const v of props.resetAssignments) {
            if (value === 'reset-assignment' + v.defId) {
                commands.resetInstanceAssignments(v.defId, v.defId2EffectdNodeIds)
                return
            }
        }
    }

    const showInstanceDesc = !!props.compDesc || !!props.compSetDesc
    const multiDescExisted = !!props.compDesc && !!props.compSetDesc
    const showMoreDesc = hoverDesc && (isTextOverflowing || multiDescExisted)

    return (
        <div className={`${style.instancePanel} ${props.noBottom && style.noPaddingBottom}`} ref={containerRef}>
            <SingleGrid>
                <SingleGrid.Item start={5} span={46}>
                    <div
                        className={classNames(
                            {
                                [style.backgroundGrid]: !showNormalPillOfInstanceSwap,
                            },
                            style.replaceInstanceSelectWrapper
                        )}
                        ref={replaceRef}
                    >
                        {showNormalPillOfInstanceSwap ? (
                            <InstanceSwapComponentPropPill />
                        ) : (
                            <>
                                <div className={style.replaceInstanceSelect} id="instance-no-fold">
                                    {props.selectOuterInstanceState ? (
                                        <Select.NormalSingleLevel
                                            className={style.componentNameSelect}
                                            label={props.selectOuterInstanceState.componentOrSetName}
                                            icon={<MonoIconLayerInstance16 />}
                                            isHover={false}
                                            arrow={<></>}
                                            dataTestIds={{ triggerFocus: InstanceTestIds.mainCompName }}
                                        />
                                    ) : (
                                        <Select.NormalSingleLevel
                                            isMixed={props.isMixed}
                                            hoverMoveToRight
                                            isHover={shouldDisplayReplacePanel}
                                            icon={<MonoIconLayerInstance16 />}
                                            label={props.name}
                                            onClick={showReplacePanel}
                                            dataTestIds={{ triggerFocus: InstanceTestIds.mainCompName }}
                                            disabled={props.disableReplaceComponent}
                                        ></Select.NormalSingleLevel>
                                    )}
                                    {shouldDisplayReplacePanel && props.replaceState && (
                                        <ComponentPicker
                                            position={replacePosition}
                                            state={props.replaceState}
                                            triggerScene={ComponentPickerTrigger.ReplaceInstance}
                                            onCancel={() => commands.toggleReplacePanel(false)}
                                            handleThumbnailClick={() => {}}
                                        />
                                    )}
                                </div>
                                {shouldDisplayApplyPropIcon && <InstanceSwapPropApplyBtn />}
                            </>
                        )}
                        {popupType ===
                            PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_INSTANCE_SWAP_PROP_OF_INSTANCE_AREA && (
                            <ComponentPropDragPopup position={containerRef.current?.getBoundingClientRect()} />
                        )}
                        {!props.disableGoToMainComponent && (
                            <Tooltip title={translation('GoToMain')}>
                                <WKIconButton
                                    icon={
                                        props.isRemote ? (
                                            <MonoIconPanelMainComponentOut16 />
                                        ) : (
                                            <MonoIconLayerComponent16 />
                                        )
                                    }
                                    data-testid={
                                        props.isRemote ? InstanceTestIds.goToRemoteBtn : InstanceTestIds.goToMainBtn
                                    }
                                    className={
                                        showNormalPillOfInstanceSwap ? style.pillGoToMainBtnIcon : style.goToMainBtnIcon
                                    }
                                    onClick={commands.goToMainComp}
                                    disabled={props.disableJumpToOrigin}
                                    type="primary"
                                />
                            </Tooltip>
                        )}
                        {props.selectOuterInstanceState && (
                            <Tooltip title={translation('SelectInstance')}>
                                <WKIconButton
                                    icon={<MonoIconPanelParentLevel16 />}
                                    data-testid={InstanceTestIds.selectInstance}
                                    className={style.selectInstanceBtn}
                                    onClick={() =>
                                        commands.JumpToOuterMostInstances(
                                            props.selectOuterInstanceState.outerMostInstanceIds
                                        )
                                    }
                                    type="primary"
                                />
                            </Tooltip>
                        )}
                    </div>
                </SingleGrid.Item>
                <SingleGrid.Item start={51} span={12} horizontalCenter>
                    {!props.disableMoreActions && (
                        <Tooltip title={translation('InstanceOptions')} triggerRefKey="getTriggerElement">
                            <DropdownV2.IconSingleLevel
                                label={<MonoIconPanelMore16 />}
                                placement="bottom right"
                                onChange={onEnterChange}
                                classNameTriggerFocus={style.moreIcon}
                                dataTestIds={{ triggerFocus: InstanceTestIds.moreActionBtn }}
                            >
                                {!props.disableDetachInstance && (
                                    <DropdownV2.IconSingleLevel.Option
                                        value={InstanceMenuType.Detach}
                                        backwardIcon={shortcutLabelMap[ShortcutKey.DetachInstance]}
                                        disabled={props.disableDetachInstance}
                                        data-testid={InstanceTestIds.detachBtn}
                                        splitLineBottom
                                    >
                                        {translation('DetachInstance')}
                                    </DropdownV2.IconSingleLevel.Option>
                                )}
                                {!props.disablePushChangesToMainComp && (
                                    <DropdownV2.IconSingleLevel.Option
                                        value={InstanceMenuType.PushChanges}
                                        disabled={props.disablePushChangesToMainComp}
                                        data-testid={InstanceTestIds.pushChangesBtn}
                                        splitLineBottom
                                    >
                                        {translation('PushChangesTo')}
                                    </DropdownV2.IconSingleLevel.Option>
                                )}

                                {props.resetTypes.length === 0 && props.resetAssignments.length === 0 ? (
                                    <DropdownV2.IconSingleLevel.Option
                                        value={InstanceMenuType.noResetItem}
                                        disabled
                                        data-testid={InstanceTestIds.noResetItem}
                                    >
                                        {translation('NoChangeTo')}
                                    </DropdownV2.IconSingleLevel.Option>
                                ) : (
                                    <>
                                        {props.resetTypes.map((v) => (
                                            <DropdownV2.IconSingleLevel.Option
                                                value={'reset' + v.name}
                                                key={v.type}
                                                onClick={() => {
                                                    frogEventToolsMenusAndActionsBasicAction({
                                                        action_name: '重置所有修改',
                                                        operation_way: '右边栏-组件菜单',
                                                    })
                                                }}
                                                data-testid={InstanceTestIds.resetBtn(v.type.toString())}
                                            >
                                                {translation('Reset')}
                                                {v.name}
                                            </DropdownV2.IconSingleLevel.Option>
                                        ))}
                                        {props.resetAssignments.map((v, i) => (
                                            <DropdownV2.IconSingleLevel.Option
                                                value={'reset-assignment' + v.defId}
                                                key={`${v.name}-${i}`}
                                                data-testid={InstanceTestIds.resetBtn(`${v.name}-${i}`)}
                                            >
                                                {translation('ResetAssignment', { propName: v.name })}
                                            </DropdownV2.IconSingleLevel.Option>
                                        ))}
                                    </>
                                )}
                            </DropdownV2.IconSingleLevel>
                        </Tooltip>
                    )}
                </SingleGrid.Item>
            </SingleGrid>
            {showInstanceDesc && (
                <SingleGrid>
                    <SingleGrid.Item
                        start={5}
                        span={54}
                        onMouseEnter={() => {
                            if (popupVisible) {
                                return
                            }
                            setHoverDesc(true)
                        }}
                        onMouseLeave={() => {
                            setHoverDesc(false)
                        }}
                        data-testid={InstanceTestIds.descPreview}
                        className={style.instanceDescPreview}
                    >
                        <div ref={descRef} className={style.instanceDescPreviewContent}>
                            {props.compDesc || props.compSetDesc}
                        </div>
                        {showMoreDesc && (
                            <div
                                className={style.moreDesc}
                                onClick={() => {
                                    openComponentConfigPopup()
                                    setIsCompDescConfigPopupOpen(true)
                                    setHoverDesc(false)
                                }}
                                data-testid={InstanceTestIds.showMoreDesc}
                            >
                                {translation('showMoreDesc')}
                            </div>
                        )}
                    </SingleGrid.Item>
                </SingleGrid>
            )}

            {props.showRecoverComponent && (
                <div className={style.tipRecover}>
                    <WKButton
                        type="secondary"
                        className={style.tipRecoverBtn}
                        onClick={commands.recoverComponentForInstance}
                    >
                        {translation('RestoreMainComponent')}
                    </WKButton>
                </div>
            )}

            <InstanceAssignmentList />

            {popupVisible && (
                <ComponentConfigPopup
                    type={'instance'}
                    compDescUnit={{
                        desc: props.compDesc,
                        isDescMixed: false,
                        nodeIds: [],
                    }}
                    compSetDescUnit={{
                        desc: props.compSetDesc,
                        isDescMixed: false,
                        nodeIds: [],
                    }}
                    position={popupPosition}
                    onClose={closeComponentConfigPopup}
                />
            )}
        </div>
    )
}

export function InstancePanel(
    props: InstanceState & {
        noBottom?: boolean
        replaceState?: ReplaceInstanceState
        disableJumpToOrigin: boolean
    }
) {
    return <InstancePanelV2 {...props} />
}
