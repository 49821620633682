import { Wukong } from '@wukong/bridge-proto'
import { uniq } from 'lodash-es'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactJson from 'react-json-view'
import { useEffectOnce } from 'react-use'
import { InputV2, Scrollbar, Tag, WKButton, wkDialogConfirm, WKToast } from '../../../../../../ui-lib/src'
import { TagInputRef, TagItem } from '../../../../../../ui-lib/src/components/inputs/tag-input/tag-input'
import { WasmCallMonitorItem } from '../../../../../../util/src/wasm-call-monitor'
import { LocalStorageKey } from '../../../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../../../web-storage/local-storage/storage'
import { WK } from '../../../../window'
import style from './wasm-call-monitor.module.less'
import { featureSwitchManager } from '../../../../kernel/switch'

function CopyableRow({ copyValue, children }: { copyValue: string; children: React.ReactNode }) {
    const onCopy = useCallback(() => {
        WKToast.show('复制成功')
        navigator.clipboard.writeText(copyValue)
    }, [copyValue])

    return (
        <span className={style.copyableRow} onClick={onCopy}>
            {children}
        </span>
    )
}

export const BlackListTagItem = ({ name }: { name: string }) => {
    return <div style={{ padding: '4px 8px' }}>{name}</div>
}

const DEFAULT_BLACK_LIST = [
    'WCC_onAnimationFrame',
    'WCC_getUsedMemory',
    'WCC_dispatchMouseEvent',
    'WCC_emDocuemntGetDocumentNodeId',
    'WCC_emDocumentGetNode',
    'WCC_emDocumentGetNodeAttr',
    'WCC_setDevicePixelRatio',
    'WCC_cmdUpdateCursorWithinCanvas',
    'WCC_cmdShouldMoveViewportWhenOutsideCanvas',
    'WCC_cmdUpdateMouseCameraPosition',
    'WCC_clearHoverPopupHyperlink',
    'WCC_renderCursorRecord',
    'WCC_gcTextures',
    'WCC_dispatchWheelEvent',
    'WCC_cmdMoveViewport',
    'WCC_windowEventTrace',
    'WCC_triggerAllKeyUpBindings',
    'WCC_dispatchKeyboardEvent',
    'WCC_isFocusViewEnabled',
    'WCC_getSingleViewStateValue',
    'WCC_loadFontInJs',
    'WCC_cmdLayerPanelEnterArea',
    'WCC_onBatchOperationAck',
    'WCC_enableSingleViewState',
    'WCC_disableSingleViewState',
    'WCC_commitSynergyStore',
    'WCC_getVRAM',
    'WCC_updateIsViewportZooming',
    'WCC_cmdScaleViewportByWheel',
]

export const BlackListTagBar = ({
    activeCustomBlackList,
    setActiveCustomBlackList,
}: {
    activeCustomBlackList: string[]
    setActiveCustomBlackList: (blackList: string[]) => void
}) => {
    const [query, setQuery] = useState<string>('')
    const optionProps = useMemo(() => {
        if (query === '') {
            return []
        }
        const wasmCallCodes = Object.keys(Wukong.DocumentProto.WasmCallCode)
        return wasmCallCodes.filter((name) => name.toLocaleLowerCase().includes(query.toLocaleLowerCase()))
    }, [query])

    const generateTag = (name: string): TagItem<BlackListTagItem> => {
        return {
            name: `${name.replace('WCC_', '')}`,
            color: 'black',
            customProps: {
                value: name,
            } as BlackListTagItem,
        }
    }
    const tagInputRef = useRef<TagInputRef<BlackListTagItem>>(null)

    const updateLocalStorage = useCallback((blackList: string[]) => {
        enhancedLocalStorage.setSerializedItem(LocalStorageKey.MotiffScopeWasmCallMonitorBlackList, blackList)
    }, [])

    const onTagChanged = useCallback(
        (v: TagItem<BlackListTagItem>[]) => {
            if (v.length !== 0) {
                const newBlackList = uniq([...activeCustomBlackList, ...v.map((item) => item.customProps?.value ?? '')])
                setActiveCustomBlackList(newBlackList)
                updateLocalStorage(newBlackList)
                tagInputRef.current?.deleteAllTags()
            }
        },
        [activeCustomBlackList, setActiveCustomBlackList, updateLocalStorage]
    )

    const onTagDelete = useCallback(
        (v: string) => {
            const newBlackList = activeCustomBlackList.filter((value) => value !== v)
            setActiveCustomBlackList(newBlackList)
            updateLocalStorage(newBlackList)
        },
        [activeCustomBlackList, setActiveCustomBlackList, updateLocalStorage]
    )

    const resetDefaultBlackList = () => {
        wkDialogConfirm.warning({
            closable: true,
            okText: '确定',
            onOk: () => {
                setActiveCustomBlackList(DEFAULT_BLACK_LIST)
                updateLocalStorage(DEFAULT_BLACK_LIST)
            },
            title: '恢复默认WasmCall监听黑名单配置',
            content: '此操作不可撤销，确认重置？',
        })
    }

    return (
        <div className="flex flex-col flex-1">
            <div className="flex items-center gap-1 mx-1">
                <InputV2.Tag
                    autoHeight
                    placeholder="增加监听黑名单"
                    className="flex-1"
                    listProps={{
                        items: optionProps,
                        renderItem: (v) => <BlackListTagItem name={v.item} />,
                    }}
                    onChangeValue={setQuery}
                    onChangeTag={onTagChanged}
                    generateTag={generateTag}
                    ref={tagInputRef}
                />
                <WKButton type="secondary" onClick={resetDefaultBlackList}>
                    一键重置
                </WKButton>
            </div>
            <Scrollbar renderViewClassName="px-1" style={{ flex: 1 }}>
                <div className="flex flex-wrap gap-2 pt-1">
                    {activeCustomBlackList.map((value) => (
                        <Tag key={value} name={value} closable onDelete={() => onTagDelete(value)} className="!ml-0" />
                    ))}
                </div>
            </Scrollbar>
        </div>
    )
}

export const WasmCallMonitorPanel = ({ height, showSetting }: { height: number; showSetting: boolean }) => {
    const [isMonitoring, setMonitoringStatus] = useState(
        enhancedLocalStorage.getSerializedItem(LocalStorageKey.MotiffScopeWasmCallMonitorRunningState) ?? false
    )
    const [monitorLogs, setMonitorLogs] = useState<WasmCallMonitorItem[]>()

    let timer: NodeJS.Timeout | null = null
    const POLLING_INTERVAL = 200

    const startListening = () => {
        WK.wasmCallLogs = []
        setMonitorLogs([])
        stopListening()
        WK.wasmCallLogsMonitorStatus = true
        enhancedLocalStorage.setSerializedItem(LocalStorageKey.MotiffScopeWasmCallMonitorRunningState, true)
        timer = setInterval(() => {
            setMonitorLogs(WK.wasmCallLogs)
        }, POLLING_INTERVAL)
    }

    const stopListening = () => {
        WK.wasmCallLogsMonitorStatus = false
        clearInterval(timer ?? undefined)
        timer = null
        enhancedLocalStorage.setSerializedItem(LocalStorageKey.MotiffScopeWasmCallMonitorRunningState, false)
    }

    const allCustomBlackList = enhancedLocalStorage.getSerializedItem(
        LocalStorageKey.MotiffScopeWasmCallMonitorBlackList
    )
    const [activeCustomBlackList, setActiveCustomBlackList] = useState(
        allCustomBlackList ? uniq(allCustomBlackList) : DEFAULT_BLACK_LIST
    )

    useEffect(() => {
        WK.wasmCallLogsMonitorBlackList = activeCustomBlackList
        return () => {
            WK.wasmCallLogsMonitorStatus = false
            WK.wasmCallLogs = ''
        }
    }, [activeCustomBlackList])

    useEffectOnce(() => {
        if (featureSwitchManager.isEnabled('motiff-debugger') && isMonitoring) {
            startListening()
        }
    })
    if (showSetting) {
        return (
            <div className="flex flex-col" style={{ height }}>
                <BlackListTagBar
                    activeCustomBlackList={activeCustomBlackList}
                    setActiveCustomBlackList={setActiveCustomBlackList}
                />
            </div>
        )
    }

    const onViewLog = (log: WasmCallMonitorItem) => {
        wkDialogConfirm.info({
            closable: true,
            hideCancelButton: true,
            okText: '确定',
            title: '查看WasmCall详细日志',
            content: (
                <div className="flex flex-col gap-2 overflow-scroll max-h-250px">
                    <div className="flex flex-col gap-1">
                        <div className="flex gap-1">
                            <div className="font-semibold">Name:</div>
                            <CopyableRow copyValue={log.method}>{log.method}</CopyableRow>
                        </div>
                        <div className="flex gap-1">
                            <div className="font-semibold">Timestamp:</div>
                            <CopyableRow copyValue={log.timestamp}>{log.timestamp}</CopyableRow>
                        </div>
                    </div>
                    {log.args && (
                        <div className="flex flex-col gap-1">
                            <h2>Args:</h2>
                            <ReactJson
                                src={JSON.parse(log.args)}
                                displayDataTypes={false}
                                enableClipboard={true}
                                quotesOnKeys={false}
                                groupArraysAfterLength={10}
                            />
                        </div>
                    )}
                    {log.ret && (
                        <div className="flex flex-col gap-1">
                            <h2>Ret:</h2>
                            <ReactJson
                                src={JSON.parse(log.ret)}
                                displayDataTypes={false}
                                enableClipboard={true}
                                quotesOnKeys={false}
                                groupArraysAfterLength={10}
                            />
                        </div>
                    )}
                </div>
            ),
        })
    }
    return (
        <div className="flex flex-col" style={{ height }}>
            <div className="flex items-center gap-1">
                <h3 className="px-2 py-2 my-0">{'监听 WasmCall'}</h3>
                <WKButton
                    type="secondary"
                    onClick={() => {
                        const nextStatus = !isMonitoring
                        setMonitoringStatus(nextStatus)
                        if (nextStatus) {
                            startListening()
                        } else {
                            stopListening()
                        }
                    }}
                >
                    {isMonitoring ? '暂停监听' : '开始监听'}
                </WKButton>
            </div>
            {monitorLogs && (
                <div style={{ maxHeight: height - 32, padding: 10, overflow: 'scroll' }}>
                    {monitorLogs.map((log) => (
                        <div key={log.id} className="flex gap-1 items-center">
                            <CopyableRow copyValue={log.timestamp}>{log.timestamp}</CopyableRow>
                            <span> | </span>
                            <CopyableRow copyValue={log.method}>{log.method}</CopyableRow>
                            <WKButton
                                type="secondary"
                                onClick={() => {
                                    onViewLog(log)
                                }}
                                size="small"
                            >
                                详情
                            </WKButton>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export interface BlackListTagItem {
    value: string
}
