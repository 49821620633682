import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Interactions: '交互',
    ResetInteractions: '重置交互',
} as const

export const enTranslation = {
    Interactions: 'Interactions',
    ResetInteractions: 'Reset interactions',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
