import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useUnmount } from 'react-use'
import {
    InputV2,
    MonoIconLayerInstance16,
    Select,
    Switch,
    Tooltip,
    useEllipsisTooltip,
} from '../../../../../../../../ui-lib/src'
import { TextAreaOperations } from '../../../../../../../../ui-lib/src/components/inputs/textarea/textarea'
import { ComponentPropTestId } from '../../../../../../window'
import { SingleGrid } from '../../../../atom/grid/single-grid'
import { ComponentPropType, MultiHoverBorderColorType, VComponentPropType, VInstanceCompProp } from '../../types'
import styles from './index.module.less'

export function InstanceAssignmentItem(props: {
    appliedProp: VInstanceCompProp
    index: number
    tmpLocateDefId?: string | null
    updateAssignment: (value: Wukong.DocumentProto.IArg_updateNodeValAboutCompPropInInstance) => void
    highlightNodesByIds: (nodeIds: string[], color?: MultiHoverBorderColorType) => void
    toggleComponentPickerVisible: (triggerRef: React.RefObject<HTMLDivElement>, instanceSwap: VInstanceCompProp) => void
    resetInstancePanelLocateDefId?: () => void
    locateInstanceOfRef?: (defId: string) => void
}) {
    const { appliedProp, index, tmpLocateDefId, highlightNodesByIds, resetInstancePanelLocateDefId } = props
    const { inactivation, ellipsisRef, onmouseenter } = useEllipsisTooltip<HTMLDivElement>()

    const [isHovered, setIsHovered] = useState<boolean>(false)

    const onPropHovered = (hovered: boolean) => {
        setIsHovered(hovered)
        const nodeIds = hovered ? appliedProp.nodeIds : []
        const color = hovered
            ? MultiHoverBorderColorType.MULTI_HOVER_BORDER_COLOR_TYPE_PURPLE
            : MultiHoverBorderColorType.MULTI_HOVER_BORDER_COLOR_TYPE_BLUE
        highlightNodesByIds(nodeIds, color)
    }

    useUnmount(() => isHovered && onPropHovered(false))

    useEffect(() => {
        let timer: NodeJS.Timeout | undefined

        if (tmpLocateDefId === appliedProp.defId && resetInstancePanelLocateDefId) {
            timer = setTimeout(resetInstancePanelLocateDefId, 1000)
        }

        return () => timer && clearTimeout(timer)
    }, [resetInstancePanelLocateDefId, tmpLocateDefId, appliedProp.defId])

    return (
        <SingleGrid
            className={classNames(styles.instanceAssignmentItem, {
                [styles.textPropRow]: appliedProp.type === VComponentPropType.V_COMPONENT_PROP_TYPE_TEXT,
                [styles.flashHighlight]: tmpLocateDefId === appliedProp.defId,
            })}
            testId={`instance-assignment-item-${index}`}
            onMouseEnter={() => onPropHovered(true)}
            onMouseLeave={() => onPropHovered(false)}
        >
            <SingleGrid.Item
                start={5}
                span={18}
                className={
                    appliedProp.type === VComponentPropType.V_COMPONENT_PROP_TYPE_TEXT
                        ? styles.textPropLabel
                        : undefined
                }
            >
                <Tooltip title={appliedProp.name} inactivation={inactivation}>
                    <div className={styles.instanceAssignmentItemName} ref={ellipsisRef} onMouseEnter={onmouseenter}>
                        {appliedProp.name}
                    </div>
                </Tooltip>
            </SingleGrid.Item>
            {appliedProp.type === VComponentPropType.V_COMPONENT_PROP_TYPE_BOOL && (
                <BoolPropAssignmentBlock {...props} />
            )}
            {appliedProp.type === VComponentPropType.V_COMPONENT_PROP_TYPE_TEXT && (
                <TextPropAssignmentEditBlock {...props} />
            )}
            {appliedProp.type === VComponentPropType.V_COMPONENT_PROP_TYPE_INSTANCE_SWAP && (
                <InstanceSwapPropAssignmentBlock {...props} />
            )}
        </SingleGrid>
    )
}

function BoolPropAssignmentBlock({
    appliedProp,
    updateAssignment,
}: {
    appliedProp: VInstanceCompProp
    updateAssignment: (value: Wukong.DocumentProto.IArg_updateNodeValAboutCompPropInInstance) => void
}) {
    const updateBoolPropVal = (val: boolean) => {
        updateAssignment(
            Wukong.DocumentProto.Arg_updateNodeValAboutCompPropInInstance.create({
                type: ComponentPropType.COMPONENT_PROP_TYPE_BOOL,
                ids: appliedProp.nodeIds,
                visible: val,
            })
        )
    }

    return (
        <SingleGrid.Item start={25} span={26}>
            <Switch
                checked={appliedProp.boolVal}
                isMixed={appliedProp.isMixed}
                onChange={updateBoolPropVal}
                dataTestIds={{ switch: 'instance-assignment-bool-switch' }}
            />
        </SingleGrid.Item>
    )
}

function TextPropAssignmentEditBlock({
    appliedProp,
    updateAssignment,
}: {
    appliedProp: VInstanceCompProp
    updateAssignment: (
        value: Wukong.DocumentProto.IArg_updateNodeValAboutCompPropInInstance,
        callback?: (success: boolean) => void
    ) => void
}) {
    const ref = useRef<TextAreaOperations>(null)
    const [textVal, setTextVal] = useState<string>(appliedProp.textVal)

    useEffect(() => {
        setTextVal(appliedProp.textVal)
    }, [appliedProp.textVal])

    return (
        <SingleGrid.Item start={25} span={26}>
            <InputV2.Textarea
                dataTestIds={{ textarea: 'text-prop-assignment-textarea' }}
                ref={ref}
                maxHeight={108}
                autoHeight
                value={textVal}
                onFocus={() => {
                    ref.current?.selectAll()
                    appliedProp.isMixed && setTextVal('')
                }}
                onInput={(e) => setTextVal(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault() // 阻止 textArea 回车换行的默认行为
                        e.stopPropagation() // 阻止冒泡到 wasm
                        const inputElement = e.target as HTMLTextAreaElement
                        inputElement.blur() // 触发失焦事件
                    }
                }}
                onBlur={(e) => {
                    const value = e.target.value
                    if (value !== appliedProp.textVal) {
                        updateAssignment(
                            Wukong.DocumentProto.Arg_updateNodeValAboutCompPropInInstance.create({
                                type: ComponentPropType.COMPONENT_PROP_TYPE_TEXT,
                                ids: appliedProp.nodeIds,
                                textVal: value,
                            }),
                            (success: boolean) => {
                                if (success) {
                                    setTextVal(value)
                                } else {
                                    // 缺失字体时回退到原来的值
                                    setTextVal(appliedProp.textVal)
                                }
                            }
                        )
                    }
                }}
            />
        </SingleGrid.Item>
    )
}

function InstanceSwapPropAssignmentBlock({
    appliedProp,
    toggleComponentPickerVisible,
}: {
    appliedProp: VInstanceCompProp
    toggleComponentPickerVisible: (triggerRef: React.RefObject<HTMLDivElement>, instanceSwap: VInstanceCompProp) => void
}) {
    const selectWrapperRef = useRef<HTMLDivElement>(null)

    return (
        <SingleGrid.Item ref={selectWrapperRef} start={25} span={26}>
            <Select.NormalSingleLevel
                dataTestIds={{
                    triggerContainer: ComponentPropTestId.InstanceAssignmentPanel.InstanceSwapSelectTrigger(
                        appliedProp.name
                    ),
                }}
                isMixed={appliedProp.isMixed}
                label={appliedProp.mainComponentName}
                icon={<MonoIconLayerInstance16 />}
                onClick={() => toggleComponentPickerVisible(selectWrapperRef, appliedProp)}
                onMouseDown={(e) => e.stopPropagation()}
            ></Select.NormalSingleLevel>
        </SingleGrid.Item>
    )
}
