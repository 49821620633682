export const MonitorRd = [
    'b8f3469a07f51f655211c08ad148f09565e5b288',
    'c1a0328270df7f4956da99ae9a9577186d5fd3ed',
    '3c88da4287e52ba91876b7795c3b02cbfc76ddaa',
    'd4e6d65ccee16576632fc308e13961f9497dcb3b',
    '284bfd4ffe2964c808afb1c38e602a392bf6adaf',
    '338f364947b4a1750795d6323eccb3093c98073d',
    '5d439eadfb0ccb6f3f42213782d0f27025fb75be',
    'db9032c736bb9e54586a883471cfd0d3c203d8c1',
    'a230f676c3f96eda738ab4fab67399cc3d7fba4b',
    '1fab519bd1ecbb8e6c15d53a7759ee4e9394d879',
    'a6b263bdd5df87096c7db4a192e2490c50203f2e',
    'eaad43d6d44d8c0cce306502d5e95e403d044e88',
    'c30feaa18ee340c2e1ee53c1be7782a102880385',
    '020a7fe802ad65fa38f416b1c4775e0ab2f1ec51',
    '23a648c20ad6b90165e5d407a1d982c636b6126a',
    '296f6b16cb8026ef8a005f943c791536317bc20e',
    'b7ac244a9ce47ff2a082eb37ac7fbdf8a7c92092',
    'e843ec065b7a75338e9b6ecd8c6be1a78392b46e',
    'ba4f73e4eb02aa2969a0e20a2990e9ec47949d58',
    '431e38ea8171d09f044f723ac6d4e626b7317929',
    '98ed395c2d9ec53c2c394574ad92cf4461ba65f7',
    '8c78e3b6352e19e364f68405e775103be4afda10',
    '9f2f8fa5b3f97fdb2715a2d63c5d9d854346098c',
    '212781a967372bef25fcaeab4675fec5b196c087',
    '0c56bdf75d4c7fa0d6b7812ec1705408edd34f6c',
    'e8e21ac5205ad1e3bcea29ae5a75c9b2dba4b785',
    'd8e03439cca68b90004a1be5bd696abef01955d6',
    'a6f87cff99c969a178773b856260acdd7d7230c9',
    'b4a5415bac7a4297468c369b3934661dd14beee1',
    '0d8245131d181bff305b212b07265a08dbd3bff5',
]
