import { Wukong } from '@wukong/bridge-proto'
import { RefObject } from 'react'
import { useAppContext } from '../../../../../main/app-context'
import { ScrubbableInputNumber } from '../../../atom/inputs/scrubbable-input-number'
import { FloatValueEditingPopupPure } from '../../float-variable/float-value-editing-popup-pure'
import { FloatVariablePreviewPure } from '../../float-variable/float-variable-alias-preview'
import { useSelectedVariablesEditorContext } from '../selected-variables-editor-context'
import styles from './float-item.module.less'

export function FloatVariableValueItem({
    mode,
    variable,
    popupRef,
}: {
    mode: Wukong.DocumentProto.IVariableSetMode
    variable: Wukong.DocumentProto.ILocalVariable
    popupRef?: RefObject<HTMLDivElement>
}) {
    const { modeId } = mode
    const value = modeId ? variable.dataValues?.[modeId] : undefined
    const isAlias = value?.dataType === Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_ALIAS
    const service = useAppContext().variableService.localVariableEditorService
    const onDetach = () => service.detachAliasForVariable(variable, modeId!)
    const { selectedModeId, onChangeSelectedModeId } = useSelectedVariablesEditorContext()
    const selected = selectedModeId === modeId
    const onSelect = () => onChangeSelectedModeId(modeId!)
    const onClose = () => onChangeSelectedModeId('')
    const onVariableSelected = (id: string) => {
        service.setAliasForVariable(variable.id, modeId!, id)
        onClose()
    }
    if (!value) {
        return null
    }
    return (
        <>
            {isAlias ? (
                <div className={styles['float-variable-preview-container']}>
                    <FloatVariablePreviewPure
                        variable={variable}
                        modeId={modeId!}
                        onClickTag={onSelect}
                        onDetach={onDetach}
                    />
                </div>
            ) : (
                <ScrubbableInputNumber
                    className={styles['float-input-container']}
                    toolClassName={styles['float-input-icon-tool']}
                    value={value.resolvedValue.floatValue}
                    scrubbingDisabled
                    useVariable={{
                        notAvailable: false, // 只在没绑定时展示，所以始终为 false
                        variable: null, // 只在没绑定时展示，所以始终为 null
                        open: selected, // 会在打开选择器后始终展示绑定 icon
                        onUnbind: () => {}, // 只在没绑定时展示，这里无需实现
                        iconBindUnbind: {
                            iconClassName: styles['float-input-icon'],
                            onBind: () => onSelect(),
                        },
                    }}
                    onChange={(_value) => {
                        if (typeof _value === 'number') {
                            service.updateFloatVariableValue(variable.id, modeId!, _value)
                        }
                    }}
                    testId="local-variable-editor-table-item-number-input"
                />
            )}
            {selected && (
                <FloatValueEditingPopupPure
                    position={{
                        // 在编辑区域的右上角打开
                        left: popupRef?.current?.getBoundingClientRect()?.right ?? 0,
                        top: popupRef?.current?.getBoundingClientRect()?.top ?? 0,
                    }}
                    onCancel={onClose}
                    onVariableSelected={onVariableSelected}
                    variableData={value}
                />
            )}
        </>
    )
    return null
}
