import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    EditStyle: '编辑样式',
    EffectStyles: '效果样式',
    CreateNewEffect: '创建效果样式',
} as const

export const enTranslation = {
    EditStyle: 'Edit style',
    EffectStyles: 'Effect styles',
    CreateNewEffect: 'Create effect style',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
