/* eslint-disable no-restricted-imports */
import { UpdateSelectionStrokeJoinCommand, Wukong } from '@wukong/bridge-proto'
import { useCallback } from 'react'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'

export function useStrokeJoin() {
    const command = useCommand()
    const selectionStroke = useViewState('selectionStrokeV2')
    const joinState = selectionStroke?.joinState
    const onChangeStrokeJoin = useCallback(
        (value: Wukong.DocumentProto.StrokeJoin) => {
            command.DEPRECATED_invokeBridge(UpdateSelectionStrokeJoinCommand, { strokeJoin: value })
            command.commitUndo()
        },
        [command]
    )

    return {
        joinState,
        onChangeStrokeJoin,
    }
}
