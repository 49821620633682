import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { WKTypography } from '../../../../../../../ui-lib/src'
import { GradientPaint, ImagePaint, SolidPaint, Paint as WPaint } from '../../../../../document/node/node'
import { toFixed } from '../../../../utils/to-fixed'
import { ColorVarIcon } from '../../../color-var-item/color-var-icon'
import { ColorVarName } from '../../../color-var-item/color-var-name'
import { ColorSpace } from '../../../design/blend/color-picker/utils/color-translate'
import { PaintStyleThumbnail } from '../../../design/styles/style-color/style-thumbnail'
import { ColorRenderV2 } from '../../../dev-mode/inspect/dev-mode-inspect-fill/paint-render'
import { PaintIcon } from '../../../paint-icon-color/paint-icon/paint-icon'
import { paintTypeToShow } from '../../inspect-type'
import style from './index.module.less'
import { translation } from './index.translation'
import InspectSelectionFillItem = Wukong.DocumentProto.IInspectSelectionFillItem
import PaintType = Wukong.DocumentProto.PaintType
import Paint = Wukong.DocumentProto.IPaint

export const ImagePaintRender = ({
    paint,
    colorSpace,
    className,
}: {
    paint: ImagePaint
    colorSpace: ColorSpace
    className?: string
}) => {
    const opacity = toFixed((paint.opacity ?? 0) * 100, 2)
    return (
        <div className={classNames('flex items-center h-6', className)}>
            <PaintIcon className={style.paintIconIndependent} paint={paint} colorSpace={colorSpace}></PaintIcon>
            <span>
                <span className="inline-block w-16">{translation('Image')}</span>
                {opacity !== 100 && <span className="ml-2">{opacity}%</span>}
            </span>
        </div>
    )
}

export const SolidPaintRender = ({
    paint,
    colorSpace,
    className,
    variable,
    maxWidth,
    isStroke,
}: {
    paint: SolidPaint
    colorSpace: ColorSpace
    className?: string
    variable?: Wukong.DocumentProto.ISingleVariable
    maxWidth?: number
    isStroke?: boolean
}) => {
    return (
        <>
            {variable ? (
                <ColorVarRender
                    className={className}
                    paint={paint}
                    colorSpace={colorSpace}
                    variable={variable}
                    maxWidth={maxWidth}
                    isStroke={isStroke}
                />
            ) : (
                <div className={classNames('flex items-center h-6', className)}>
                    <PaintIcon className={style.paintIconIndependent} paint={paint} colorSpace={colorSpace}></PaintIcon>
                    <ColorRenderV2 rgb={paint.color} opacity={paint.opacity}></ColorRenderV2>
                </div>
            )}
        </>
    )
}

const ColorVarRender = ({
    paint,
    colorSpace,
    variable,
    maxWidth,
    className,
    isStroke,
}: {
    paint: SolidPaint
    colorSpace: ColorSpace
    variable: Wukong.DocumentProto.ISingleVariable
    maxWidth?: number
    className?: string
    isStroke?: boolean
}) => {
    return (
        <div className={classNames('flex flex-col gap-4px', className)}>
            <div className="flex items-center h-6">
                <ColorVarIcon
                    className={style.paintIconIndependent}
                    rgb={paint.color}
                    colorSpace={colorSpace}
                    opacity={paint.opacity}
                    isStroke={isStroke}
                />
                <WKTypography.Text copyText={variable.name} copyable className={style.colorVarName}>
                    <ColorVarName
                        className="!p-0"
                        name={variable.name}
                        deleted={variable.isSoftDeleted}
                        maxWidth={maxWidth ? maxWidth - 32 : undefined}
                    />
                </WKTypography.Text>
            </div>
            {variable.description && (
                <div className="ml-8 color-$wk-v2-label-color-gray-8 wk-text-12 cursor-text select-text word-break whitespace-normal">
                    <span>{variable.description}</span>
                </div>
            )}
            <div className="ml-7 h-6 flex items-center">
                <ColorRenderV2 rgb={paint.color} opacity={paint.opacity} className="!color-$wk-gray-11" />
            </div>
        </div>
    )
}

export const GradientPaintRender = ({
    paint,
    colorSpace,
    className,
}: {
    paint: GradientPaint
    colorSpace: ColorSpace
    className?: string
}) => {
    const colors = paint.gradientStops
    return (
        <div className={classNames('flex flex-col gap-4px', className)}>
            <div className={'flex items-center h-6'}>
                <PaintIcon className={style.paintIconIndependent} paint={paint} colorSpace={colorSpace}></PaintIcon>
                <WKTypography.Text
                    copyable
                    copyText={paintTypeToShow[paint.type]}
                    className={style.inspectTypographyText}
                >
                    {paintTypeToShow[paint.type]}
                </WKTypography.Text>
            </div>
            {colors
                .sort((a, b) => a.position - b.position)
                .map((color, idx) => {
                    return (
                        <div key={idx} className="pl-7 h-6 flex items-center">
                            <ColorRenderV2
                                rgb={color.color}
                                className="!color-$wk-gray-11"
                                opacity={(color.color.a / 255) * (paint.opacity ?? 1)}
                            />
                        </div>
                    )
                })}
        </div>
    )
}
export const NormalPaintRender = ({
    paint,
    colorSpace,
    variable,
    className,
    maxWidth,
    isStroke,
}: {
    paint: Paint
    colorSpace: ColorSpace
    variable?: Wukong.DocumentProto.ISingleVariable
    className?: string
    maxWidth?: number
    isStroke?: boolean
}) => {
    if (paint.type == PaintType.PAINT_TYPE_SOLID_PAINT) {
        const color = paint.color
        return (
            <>
                {!!color && (
                    <SolidPaintRender
                        paint={paint as SolidPaint}
                        colorSpace={colorSpace}
                        className={className}
                        variable={variable}
                        maxWidth={maxWidth}
                        isStroke={isStroke}
                    />
                )}
            </>
        )
    }
    if (
        [
            PaintType.PAINT_TYPE_GRADIENT_ANGULAR,
            PaintType.PAINT_TYPE_GRADIENT_DIAMOND,
            PaintType.PAINT_TYPE_GRADIENT_LINEAR,
            PaintType.PAINT_TYPE_GRADIENT_RADIAL,
        ].includes(paint.type!)
    ) {
        return <GradientPaintRender paint={paint as GradientPaint} colorSpace={colorSpace} className={className} />
    }
    if (paint.type == PaintType.PAINT_TYPE_IMAGE_PAINT) {
        return <ImagePaintRender paint={paint as ImagePaint} colorSpace={colorSpace} className={className} />
    }
    return <></>
}

const StylePaintRenderItem = ({
    styleInfo,
    paints,
    colorSpace,
    variables,
    isStroke,
    maxWidth,
}: {
    styleInfo: { styleName: string; styleDescription: string }
    paints?: Paint[] | null
    colorSpace: ColorSpace
    variables?: Wukong.DocumentProto.ISingleVariable[] | null
    isStroke?: boolean
    maxWidth?: number
}) => {
    return (
        <div>
            <div className="flex items-center">
                <PaintStyleThumbnail
                    className="w-4 h-4 inline-block mr-4 shrink-0"
                    paints={paints as readonly WPaint[]}
                    width={16}
                    height={16}
                />
                <div className={style.paintStyleName}>
                    <WKTypography.Text copyable copyText={styleInfo.styleName} className={style.inspectTypographyText}>
                        {styleInfo.styleName}
                    </WKTypography.Text>
                </div>
            </div>
            <div className={styleInfo.styleName ? style.styleItemBodyWithDivider : style.styleItemBody}>
                <div className="ml-2 flex flex-col gap-4px">
                    {styleInfo.styleDescription && (
                        <div className={style.paintStyleDescription} tabIndex={-1}>
                            <span className="wk-text-12  wk-font-regular">{styleInfo.styleDescription}</span>
                        </div>
                    )}
                    {paints?.map((paint, idx) => {
                        const variable = variables?.find((o) => o.id === paint.colorVar?.value.alias)
                        return (
                            <NormalPaintRender
                                paint={paint}
                                colorSpace={colorSpace}
                                key={idx}
                                variable={variable}
                                maxWidth={maxWidth}
                                isStroke={isStroke}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export const StylePaintRender = ({
    data,
    colorSpace,
    renderCount,
    isStroke,
    maxWidth,
}: {
    data: InspectSelectionFillItem
    colorSpace: ColorSpace
    renderCount: number
    isStroke?: boolean
    maxWidth?: number
}) => {
    // styleName 说明是真的样式
    if (data.styleName) {
        return (
            <StylePaintRenderItem
                paints={data.paints}
                styleInfo={{ styleName: data.styleName, styleDescription: data.styleDescription ?? '' }}
                colorSpace={colorSpace}
                variables={data.variables}
                isStroke={isStroke}
                maxWidth={maxWidth ? maxWidth - 32 : undefined}
            />
        )
    } else {
        return (
            <>
                {data.paints?.slice(0, renderCount).map((paint, idx) => {
                    const variable = data.variables?.find((o) => o.id === paint.colorVar?.value.alias)
                    return (
                        <NormalPaintRender
                            key={idx}
                            paint={paint}
                            variable={variable}
                            maxWidth={maxWidth}
                            colorSpace={colorSpace}
                            isStroke={isStroke}
                        />
                    )
                })}
            </>
        )
    }
}
