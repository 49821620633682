import { RouteToken, domLocation } from '../../../../../util/src'
import { GetOrganizationTeams, GetOrganizationTeamsV2 } from '../../../kernel/request/organizations'
import { featureSwitchManager } from '../../../kernel/switch'
import { SpaceNotifyFlow } from './notify-flow'
import { OrganizationManageTeamStore, SpaceStoreType, getSpaceStore, setSpaceStore } from './types'

export const generateOrganizationManageTeamState = (
    set: setSpaceStore,
    get: getSpaceStore
): OrganizationManageTeamStore => {
    return {
        data: [],
        filterFromWorkspace: {},
        loading: true,
        previous: '',
        text: undefined,
        setText: (text) => {
            set((state) => {
                state.organizationManageTeamStore.previous = state.organizationManageTeamStore.text ?? ''
                state.organizationManageTeamStore.text = text
            })
            const previous = get().organizationManageTeamStore.previous
            if (!(text === previous)) {
                get().organizationManageTeamStore.fetchData()
            }
        },
        _fetchData: async () => {
            const flag = featureSwitchManager.isEnabled('organization-plus')
            const currentSelectedWorkspaceIdInAdmin = get().managedWorkspaceStore.currentSelectedWorkspaceIdInAdmin
            const orgId = get().organizationStore.organization.id
            const list = await (flag
                ? new GetOrganizationTeamsV2(orgId, {
                      queryString: get().organizationManageTeamStore.text,
                      workspaceIds: currentSelectedWorkspaceIdInAdmin ? [currentSelectedWorkspaceIdInAdmin] : undefined,
                  }).start()
                : new GetOrganizationTeams(orgId, get().organizationManageTeamStore.text).start())
            set((state) => {
                state.organizationManageTeamStore.data = list
            })
        },
        fetchData: async () => {
            set((state) => {
                state.organizationManageTeamStore.loading = true
            })
            await get().organizationManageTeamStore._fetchData()
            set((state) => {
                state.organizationManageTeamStore.loading = false
            })
        },
        clearData: () => {
            set((state) => {
                state.organizationManageTeamStore.data = []
                state.organizationManageTeamStore.loading = true
                state.organizationManageTeamStore.previous = ''
                state.organizationManageTeamStore.text = undefined
            })
        },
        setFilterFromWorkspace: (filter) => {
            set((state) => {
                state.organizationManageTeamStore.filterFromWorkspace = filter
            })
        },
    }
}

export const syncOrganizationManageTeamState = (store: SpaceStoreType, notifyFlow: SpaceNotifyFlow) => {
    const location = domLocation()
    notifyFlow.addPropertyChangeCallbackWithoutDocAndUser(() => {
        if (location.pathname.includes(`${RouteToken.Admin}/teams`)) {
            store.getState().organizationManageTeamStore._fetchData()
        }
    })
    notifyFlow.addTeamRelationChangeCallback(() => {
        if (location.pathname.includes(`${RouteToken.Admin}/teams`)) {
            store.getState().organizationManageTeamStore._fetchData()
        }
    })
    notifyFlow.addFolderRelationChangeCallback(() => {
        if (location.pathname.includes(`${RouteToken.Admin}/teams`)) {
            store.getState().organizationManageTeamStore._fetchData()
        }
    })
    notifyFlow.addWorkspaceRelationChangeCallback(() => {
        if (location.pathname.includes(`${RouteToken.Admin}/teams`)) {
            store.getState().organizationManageTeamStore._fetchData()
        }
    })
    notifyFlow.addUserOrganizationRoleChangeCallback(() => {
        if (location.pathname.includes(`${RouteToken.Admin}/teams`)) {
            store.getState().organizationManageTeamStore._fetchData()
        }
    })
}
