import { HTMLAttributes } from 'react'
import { GradientPaint } from '../../../../document/node/node'
import { featureSwitchManager } from '../../../../kernel/switch/core'
import { useViewState } from '../../../../view-state-bridge'
import { usePaintGradientThumbnail } from '../../../utils/thumbnail-cache/paints-thumbnail'
import { ImageBlock } from '../../atom/color-block/image'

interface PaintIconGradientImageProps extends HTMLAttributes<HTMLDivElement> {
    paint: GradientPaint
}
export function PaintIconGradientImage(props: PaintIconGradientImageProps) {
    const { paint, ...otherProps } = props
    const enabledEditVectorGradient = featureSwitchManager.isEnabled('vector-edit-gradient')
    const isEditVector = useViewState('topArea')?.isEditVector

    const src = usePaintGradientThumbnail({
        width: 16,
        height: 16,
        paint,
        multiple: 3,
        isStopUpdate: enabledEditVectorGradient ? false : isEditVector,
    })

    return <ImageBlock {...otherProps} src={src} />
}
