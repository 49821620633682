import {
    HighlightNodesByIdsWasmCall,
    SearchLocateNodeWithinMotiffScopeWasmCall,
    UpdateMotiffScopeSettingsWasmCall,
    UpdateTriggerFilterWithinMotiffScopeCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { union, uniq } from 'lodash-es'
import { SVGAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactJson from 'react-json-view'
import {
    DraggablePopupV2,
    InputV2,
    MonoIconCommonSettings16,
    MonoIconPanelTidyUpGrid16,
    ScrollView,
    ScrollViewRef,
    Select,
    Switch,
    Tooltip,
    WKButton,
    WKIconButton,
    WKToast,
} from '../../../../../../ui-lib/src'
import { TagInputRef, TagItem } from '../../../../../../ui-lib/src/components/inputs/tag-input/tag-input'
import { CommitType } from '../../../../document/command/commit-type'
import { featureSwitchManager } from '../../../../kernel/switch'
import { getDocumentActiveElement } from '../../../../main/service/focus-view/focus-event'
import { useViewState } from '../../../../view-state-bridge'
import { LocalStorageKey } from '../../../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../../../web-storage/local-storage/storage'
import { WK } from '../../../../window/wk-object'
import { useCommand } from '../../../context/document-context'
import { useMenuContextState } from '../../top-area/menu/context/menu-context'
import { DummyNodeItemWrapper } from './dummy-node-item'
import { useResizeHandle } from './hook/use-resize-handle'
import styles from './index.module.less'
import { getCommonPropsByNodeType, nodeTypeShortcutKeyMap } from './node-common-props'
import { prettifyPropValue } from './prettify'
import MotiffScopeJsonViewer from './react-json-view'
import { OptionPropItem, PropItem, SettingPanel } from './setting'

const MotiffScopeIcon = (props: SVGAttributes<SVGSVGElement>) => (
    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" {...props}>
        <path
            d="M640 213.333333m-42.666667 0a42.666667 42.666667 0 1 0 85.333334 0 42.666667 42.666667 0 1 0-85.333334 0Z"
            fill="#586889"
        ></path>
        <path
            d="M469.333333 21.333333h128v85.333334h-128zM469.333333 576h128v85.333333h-128zM789.333333 725.333333v64H106.666667v-64h682.666666z"
            fill="#586889"
        ></path>
        <path
            d="M597.333333 106.666667a42.794667 42.794667 0 0 1 42.666667 42.666666v21.333334a42.666667 42.666667 0 0 0 0 85.333333v320H426.666667V149.333333a42.794667 42.794667 0 0 1 42.666666-42.666666z"
            fill="#6B95FF"
        ></path>
        <path
            d="M560.213333 874.666667h-11.093333a58.24 58.24 0 0 0-57.386667 48.426666 39.061333 39.061333 0 0 0-1.066666 10.026667V1002.666667h128v-69.546667A58.538667 58.538667 0 0 0 560.213333 874.666667zM512 448V106.666667h-42.666667a42.794667 42.794667 0 0 0-42.666666 42.666666v426.666667h213.333333v-85.333333h-85.333333a42.666667 42.666667 0 0 1-42.666667-42.666667z"
            fill="#567BFF"
        ></path>
        <path
            d="M512 21.333333a42.666667 42.666667 0 0 0 42.666667 42.666667h42.666666v42.666667h-128V21.333333zM512 661.333333v-21.333333a42.666667 42.666667 0 0 1 42.666667-42.666667h42.666666v-21.333333h-128v85.333333z"
            fill="#2B3E5B"
        ></path>
        <path
            d="M640 213.333333m-21.333333 0a21.333333 21.333333 0 1 0 42.666666 0 21.333333 21.333333 0 1 0-42.666666 0Z"
            fill="#2B3E5B"
        ></path>
        <path d="M106.666667 725.333333h682.666666v21.333334H106.666667z" fill="#7989AF"></path>
        <path
            d="M560.213333 874.666667h-11.093333a58.069333 58.069333 0 0 0-44.992 21.333333H512a42.666667 42.666667 0 0 1 42.666667 42.666667 42.666667 42.666667 0 0 0 42.666666 42.666666h21.333334v-48.213333A58.538667 58.538667 0 0 0 560.213333 874.666667z"
            fill="#6B95FF"
        ></path>
        <path
            d="M346.837333 757.333333a10.666667 10.666667 0 0 0 10.666667 10.666667h320a10.666667 10.666667 0 1 0 0-21.333333h-320a10.666667 10.666667 0 0 0-10.666667 10.666666zM709.504 757.333333a10.666667 10.666667 0 0 0 10.666667 10.666667h21.333333a10.666667 10.666667 0 1 0 0-21.333333h-21.333333a10.666667 10.666667 0 0 0-10.666667 10.666666z"
            fill="#65769C"
        ></path>
        <path
            d="M569.072102 947.126746a24.490667 17.6 44.98 1 0 24.881469-24.898846 24.490667 17.6 44.98 1 0-24.881469 24.898846Z"
            fill="#F6FAFD"
        ></path>
        <path
            d="M547.729932 179.148089a24.490667 17.6 44.98 1 0 24.881469-24.898845 24.490667 17.6 44.98 1 0-24.881469 24.898845Z"
            fill="#F6FAFD"
        ></path>
        <path
            d="M548.517839 204.915833a12.245333 8.810667 44.98 1 0 12.455814-12.464513 12.245333 8.810667 44.98 1 0-12.455814 12.464513Z"
            fill="#F6FAFD"
        ></path>
        <path d="M618.666667 1024h-128a21.333333 21.333333 0 0 1-21.333334-21.333333v-69.589334A79.850667 79.850667 0 0 1 549.098667 853.333333h11.136A79.850667 79.850667 0 0 1 640 933.077333V1002.666667a21.333333 21.333333 0 0 1-21.333333 21.333333z m-106.666667-42.666667h85.333333v-48.256A37.141333 37.141333 0 0 0 560.234667 896h-11.136A37.141333 37.141333 0 0 0 512 933.077333zM448 192h-42.666667V149.333333a64 64 0 0 1 64-64h128a64 64 0 0 1 64 64v21.333334h-42.666666V149.333333a21.333333 21.333333 0 0 0-21.333334-21.333333h-128a21.333333 21.333333 0 0 0-21.333333 21.333333zM640 597.333333H426.666667a21.333333 21.333333 0 0 1-21.333334-21.333333V234.666667h42.666667v320h170.666667v-42.666667h42.666666v64a21.333333 21.333333 0 0 1-21.333333 21.333333z"></path>
        <path d="M618.666667 256h42.666666v213.333333h-42.666666zM597.333333 128h-128a21.333333 21.333333 0 0 1-21.333333-21.333333V21.333333a21.333333 21.333333 0 0 1 21.333333-21.333333h128a21.333333 21.333333 0 0 1 21.333334 21.333333v85.333334a21.333333 21.333333 0 0 1-21.333334 21.333333z m-106.666666-42.666667h85.333333V42.666667h-85.333333zM789.333333 810.666667H106.666667a21.333333 21.333333 0 0 1-21.333334-21.333334v-64a21.333333 21.333333 0 0 1 21.333334-21.333333h64v42.666667H128v21.333333h640v-21.333333H298.666667v-42.666667h490.666666a21.333333 21.333333 0 0 1 21.333334 21.333333v64a21.333333 21.333333 0 0 1-21.333334 21.333334z"></path>
        <path d="M213.333333 704h42.666667v42.666667h-42.666667zM640 277.333333a64 64 0 1 1 64-64 64 64 0 0 1-64 64z m0-85.333333a21.333333 21.333333 0 1 0 21.333333 21.333333 21.333333 21.333333 0 0 0-21.333333-21.333333zM746.666667 981.333333h42.666666v42.666667h-42.666666zM64 981.333333h640v42.666667H64z"></path>
        <path d="M763.733333 253.866667A187.157333 187.157333 0 0 0 682.666667 234.666667V192a229.141333 229.141333 0 0 1 99.52 23.466667zM576 960a295.104 295.104 0 0 1-151.808-45.952l22.954667-35.968A250.581333 250.581333 0 0 0 576 917.333333c244.032 0 362.666667-125.610667 362.666667-384 0-92.010667-60.821333-203.712-141.461334-259.84l24.384-35.008C914.154667 302.933333 981.333333 426.88 981.333333 533.333333c0 283.114667-136.384 426.666667-405.333333 426.666667zM393.066667 890.56A137.152 137.152 0 0 1 341.333333 789.333333h42.666667a98.88 98.88 0 0 0 37.504 69.44z"></path>
        <path d="M597.333333 682.666667h-128a21.333333 21.333333 0 0 1-21.333333-21.333334v-85.333333a21.333333 21.333333 0 0 1 21.333333-21.333333h128a21.333333 21.333333 0 0 1 21.333334 21.333333v85.333333a21.333333 21.333333 0 0 1-21.333334 21.333334z m-106.666666-42.666667h85.333333v-42.666667h-85.333333z"></path>
    </svg>
)

const MotiffScopeHeader = (props: {
    followSelection: boolean
    setFollowSelection: (follow: boolean) => void
    showCommonProps: boolean
    onShowCommonPropsChange: (show: boolean) => void
    updateShowPropPreferenceSetting: (value: boolean) => void
    showPropPreferenceSetting: boolean
}) => {
    return (
        <div className={styles.inspectorHeader}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <MotiffScopeIcon />
            </div>
            <div className={styles.title}> Motiff Scope </div>

            {props.showPropPreferenceSetting ? (
                <WKButton type="primary" onClick={() => props.updateShowPropPreferenceSetting(false)}>
                    回主页
                </WKButton>
            ) : (
                <>
                    <div className="flex items-center gap-1">
                        <div>跟随选中 </div>
                        <Switch checked={props.followSelection} onChange={props.setFollowSelection} />
                    </div>
                    <div className="flex items-center gap-1">
                        <div>显示常用 </div>
                        <Switch checked={props.showCommonProps} onChange={props.onShowCommonPropsChange} />
                        <Tooltip title={'常用属性配置'}>
                            <WKIconButton
                                className="group"
                                data-testid="setting-icon"
                                onClick={() => props.updateShowPropPreferenceSetting(true)}
                                icon={
                                    <MonoIconCommonSettings16 className="transition-transform duration-300 group-hover:rotate-90" />
                                }
                            ></WKIconButton>
                        </Tooltip>
                    </div>
                </>
            )}
        </div>
    )
}

const getViewStateJsonValue = (tags: TagItem<PropItem>[]) => {
    const ret: Record<string, any> = {}
    for (const tag of tags) {
        if (tag.color === 'red') {
            continue
        }
        ret[tag.name] = WK.viewState()[tag.name]
    }
    if (Object.keys(ret).length === 0) {
        return ''
    }
    return JSON.stringify(ret)
}

const checkValue = (value: any) => {
    if (value === null) {
        return false
    }
    if (Array.isArray(value) && value.length === 0) {
        return false
    }
    if (typeof value === 'string' && value.length === 0) {
        return false
    }

    return true
}

const getPropJsonValue = (tagsByUser: string[], tagsCommonSet: string[], selectedId?: string) => {
    if (!selectedId || selectedId === '-1') {
        return ''
    }

    const ret: Record<string, any> = {}
    // tag by user input
    for (const field of tagsByUser) {
        if (featureSwitchManager.isEnabled('motiff-scope-pretty-props')) {
            ret[`NP_${field}`] = prettifyPropValue(field, WK.getNode(selectedId)[field])
        } else {
            ret[field] = WK.getNode(selectedId)[field]
        }
    }

    // auto show common props
    for (const field of tagsCommonSet) {
        const value = WK.getNode(selectedId)[field]
        if (checkValue(value)) {
            if (featureSwitchManager.isEnabled('motiff-scope-pretty-props')) {
                ret[`NP_${field}`] = prettifyPropValue(field, value)
            } else {
                ret[field] = value
            }
        }
    }

    if (Object.keys(ret).length === 0) {
        return ''
    }
    return JSON.stringify(ret)
}

type QueryCategory = 'NodeProps' | 'ViewStates'

const QueryCategoryDropMenu = ({
    queryCategory,
    onChangeCategory,
}: {
    queryCategory: QueryCategory
    onChangeCategory: (v: QueryCategory) => void
}) => {
    return (
        <Select.MinimalSingleLevel
            minWidth={112}
            value={queryCategory}
            label={queryCategory}
            onChange={onChangeCategory}
            dataTestIds={{
                triggerFocus: 'query-category-select',
                scrollContainer: 'query-category-scrollContainer',
            }}
        >
            <Select.MinimalSingleLevel.Option key={'NodeProps'} value={'NodeProps'} dataTestId="select-option">
                {'NodeProps'}
            </Select.MinimalSingleLevel.Option>
            <Select.MinimalSingleLevel.Option key={'ViewStates'} value={'ViewStates'} dataTestId="select-option">
                {'ViewStates'}
            </Select.MinimalSingleLevel.Option>
        </Select.MinimalSingleLevel>
    )
}

const ParentChainBreadcrumbItem = ({
    id,
    name,
    locate,
}: {
    id: string
    name: string
    locate: (id: string) => void
}) => {
    const display = `(${id}) ${name}`

    const onClick = () => {
        if (id === '-1') {
            return
        }
        locate(id)
    }
    return (
        <Tooltip key={display} title={display}>
            <li onClick={onClick}>
                <a>{id}</a>
            </li>
        </Tooltip>
    )
}
const RightDetailPanel = ({
    selectedId,
    allPropNames,
    selectedParentChain,
    locate,
    highlightNode,
    showCommonProps,
}: {
    selectedId?: string
    allPropNames: string[]
    selectedParentChain: { id: string; name: string }[]
    locate: (id: string) => void
    highlightNode: (id: string) => void
    showCommonProps: boolean
}) => {
    const [query, setQuery] = useState('')
    const [tags, setTags] = useState<TagItem<PropItem>[]>([])
    const [nodePropTags, setNodePropTags] = useState<TagItem<PropItem>[]>([])
    const [viewStateTags, setViewStateTags] = useState<TagItem<PropItem>[]>([])

    const motiffScopeRefreshTrigger = useViewState('motiffScopeRefreshTrigger')

    const command = useCommand()
    const [queryCategory, setQueryCategory] = useState<QueryCategory>('NodeProps')
    const tagInputRef = useRef<TagInputRef<PropItem>>(null)

    const hints = useMemo(() => {
        if (queryCategory === 'NodeProps') {
            return allPropNames
        }
        return Object.keys(WK.viewState())
    }, [queryCategory, allPropNames])

    const generateTag = useCallback(
        (name: string): TagItem<PropItem> => {
            let color: 'black' | 'red' = 'black'
            if (!hints.includes(name)) {
                color = 'red'
            }
            if (tags.some((tag) => tag.name === name)) {
                color = 'red'
            }
            return {
                name,
                color,
            }
        },
        [hints, tags]
    )

    const tagsByUser: string[] = useMemo(() => {
        const queryFieldTag: string[] = []
        for (const tag of tags) {
            if (tag.color === 'red') {
                continue
            }
            const name = tag.name.toString()
            if (nodeTypeShortcutKeyMap[name]) {
                nodeTypeShortcutKeyMap[name].forEach((key: string) => {
                    queryFieldTag.push(key)
                })
            } else {
                queryFieldTag.push(name)
            }
        }
        return uniq(queryFieldTag)
    }, [tags])

    const tagsCommonSet: string[] = useMemo(() => {
        if (showCommonProps && selectedId) {
            const type: Wukong.DocumentProto.NodeType = WK.getNode(selectedId).type
            const commonProps =
                enhancedLocalStorage.getSerializedItem(LocalStorageKey.MotiffScopePropsSetting)?.[type] ??
                getCommonPropsByNodeType(type)
            return uniq(commonProps)
        } else {
            return []
        }
    }, [selectedId, showCommonProps])

    const propJsonValue = useMemo(() => {
        if (queryCategory === 'NodeProps') {
            if (!selectedId) {
                return ''
            }
            return getPropJsonValue(tagsByUser, tagsCommonSet, selectedId)
        }
        return getViewStateJsonValue(tags)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tags, selectedId, queryCategory, motiffScopeRefreshTrigger?.trigger, showCommonProps])

    const optionProps = useMemo(() => {
        if (query === '') {
            return []
        }
        return hints.filter((name) => name.toLocaleLowerCase().startsWith(query.toLocaleLowerCase()))
    }, [hints, query])

    const onTagChanged = useCallback(
        (v: TagItem<PropItem>[]) => {
            if (queryCategory === 'NodeProps') {
                setNodePropTags(v)
            } else {
                setViewStateTags(v)
            }
            setTags(v)
        },
        [queryCategory, setNodePropTags, setViewStateTags, setTags]
    )

    useEffect(() => {
        command.invokeBridge(CommitType.Noop, UpdateTriggerFilterWithinMotiffScopeCommand, {
            names: uniq(union(tagsByUser, tagsCommonSet)),
            selectedId: selectedId,
            type:
                queryCategory === 'NodeProps'
                    ? Wukong.DocumentProto.QueryCategory.QUERY_CATEGORY_NODE_PROP
                    : Wukong.DocumentProto.QueryCategory.QUERY_CATEGORY_VIEW_STATE,
        })
    }, [selectedId, tags, command, queryCategory, tagsByUser, tagsCommonSet])

    useEffect(() => {
        tagInputRef.current?.deleteAllTags()
        setTimeout(() => {
            if (queryCategory === 'NodeProps') {
                tagInputRef.current?.appendTag(...nodePropTags)
            } else {
                tagInputRef.current?.appendTag(...viewStateTags)
            }
        }, 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryCategory])

    return (
        <div className={styles.rightPanel}>
            <ol className={styles.breadcrumbList}>
                {selectedParentChain.map((item) => {
                    return <ParentChainBreadcrumbItem key={item.id} id={item.id} name={item.name} locate={locate} />
                })}
            </ol>
            <InputV2.Tag
                autoHeight
                placeholder={'输入属性名称'}
                className="flex-1"
                listProps={{
                    items: optionProps,
                    renderItem: (v) => <OptionPropItem name={v.item} />,
                }}
                onChangeValue={setQuery}
                onChangeTag={onTagChanged}
                dataTestIds={{
                    input: 'prop-input',
                }}
                generateTag={generateTag}
                tool={<QueryCategoryDropMenu queryCategory={queryCategory} onChangeCategory={setQueryCategory} />}
                ref={tagInputRef}
            ></InputV2.Tag>

            {propJsonValue &&
                (featureSwitchManager.isEnabled('motiff-scope-pretty-props') ? (
                    <MotiffScopeJsonViewer
                        src={JSON.parse(propJsonValue)}
                        style={{ paddingTop: '4px' }}
                        displayDataTypes={false}
                        enableClipboard={true}
                        name={queryCategory}
                        groupArraysAfterLength={10}
                        jumpToNode={locate}
                        highlightNode={highlightNode}
                    />
                ) : (
                    <ReactJson
                        src={JSON.parse(propJsonValue)}
                        style={{ paddingTop: '4px' }}
                        displayDataTypes={false}
                        enableClipboard={true}
                        quotesOnKeys={false}
                        name={queryCategory}
                        groupArraysAfterLength={10}
                    />
                ))}
        </div>
    )
}

const TITLE_HEIGHT = 44
const LEFT_PANEL_MIN_WIDTH = 200

const MotiffScopeDataPanel = ({
    followSelection,
    height,
    showCommonProps,
}: {
    followSelection: boolean
    height: number
    showCommonProps: boolean
}) => {
    const motiffScope = useViewState('motiffScope')

    const command = useCommand()
    const document = motiffScope?.doc
    const locate = useCallback(
        (id: string) => {
            const ret = command.invokeBridge(CommitType.CommitUndo, SearchLocateNodeWithinMotiffScopeWasmCall, {
                value: id,
            })
            if (!ret.value) {
                WKToast.error('搜索节点不存在')
            }
            getDocumentActiveElement()?.blur()
        },
        [command]
    )
    const highlightNode = useCallback(
        (id: string) => {
            command.invokeBridge(CommitType.Noop, HighlightNodesByIdsWasmCall, {
                nodeIds: [id],
            })
        },
        [command]
    )

    const leftPanelRef = useRef<ScrollViewRef>(null)
    const [isDragging, setIsDragging] = useState(false)
    const [leftPanelWidth, setLeftPanelWidth] = useState(LEFT_PANEL_MIN_WIDTH)

    const handleDragStart = (e: React.PointerEvent<HTMLDivElement>) => {
        setIsDragging(true)

        e.preventDefault()
        e.stopPropagation()
    }
    const handleDragEnd = (e: React.PointerEvent<HTMLDivElement>) => {
        setIsDragging(false)

        e.preventDefault()
        e.stopPropagation()
    }

    const handleDragMove = (e: React.PointerEvent<HTMLDivElement>) => {
        if (!isDragging) {
            return
        }
        if (leftPanelRef.current) {
            const element = leftPanelRef.current.getScrollViewElement()
            const newWidth = element.clientWidth + e.movementX
            if (newWidth >= LEFT_PANEL_MIN_WIDTH) {
                setLeftPanelWidth(newWidth)
            }
        }
        e.preventDefault()
        e.stopPropagation()
    }

    const selectedId = motiffScope?.selectedId ?? ''

    const selectedParentChain = motiffScope?.selectedParentChain ?? []

    const allPropOptions = useMemo(() => {
        const allPropNames = motiffScope?.allPropNames ?? []
        return Array.from(new Set<string>([...allPropNames, ...Object.keys(nodeTypeShortcutKeyMap)]))
    }, [motiffScope?.allPropNames])

    return (
        <div
            className={styles.container}
            onPointerMove={handleDragMove}
            onPointerLeave={handleDragEnd}
            onPointerUp={handleDragEnd}
            style={{ cursor: isDragging ? 'ew-resize' : 'default', height: `${height}px` }}
        >
            {motiffScope?.showMainPanel && (
                <div className="flex flex-col truncate" style={{ position: 'relative', width: `${leftPanelWidth}px` }}>
                    {motiffScope?.showSearchPanel && (
                        <InputV2.Search
                            placeholder="请输入节点id..."
                            skipComposing={true}
                            autoFocus
                            rootClassName={styles.searchInput}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    const id = e.currentTarget.value
                                        .trim()
                                        .replace(/^["'](.*)["']$/, '$1')
                                        .trim()
                                    if (id === '') {
                                        return
                                    }
                                    locate(id)
                                }
                                e.stopPropagation()
                            }}
                        />
                    )}

                    <ScrollView
                        ref={leftPanelRef}
                        data-testid="left-node-tree-panel"
                        className={styles.leftNodeTreePanel}
                        block="start"
                        selectKey={selectedId}
                    >
                        <DummyNodeItemWrapper
                            node={document as Wukong.DocumentProto.IDummyNodeData}
                            depth={0}
                            selectedId={selectedId}
                            followSelection={followSelection}
                            highlightNode={highlightNode}
                        />
                    </ScrollView>
                </div>
            )}
            <div onPointerDown={handleDragStart} className={styles.divider} style={{ left: `${leftPanelWidth}px` }} />
            <RightDetailPanel
                selectedId={selectedId}
                allPropNames={allPropOptions}
                selectedParentChain={selectedParentChain}
                locate={locate}
                highlightNode={highlightNode}
                showCommonProps={showCommonProps}
            />
        </div>
    )
}

export const MotiffScopePanel = ({
    showPropPreferenceSetting,
    followSelection,
    showCommonProps,
    height,
}: {
    showPropPreferenceSetting: boolean
    followSelection: boolean
    showCommonProps: boolean
    height: number
}) => {
    if (showPropPreferenceSetting) {
        return <SettingPanel height={height} />
    }
    return <MotiffScopeDataPanel followSelection={followSelection} height={height} showCommonProps={showCommonProps} />
}

export const MotiffScope = () => {
    const motiffScopeConfig = enhancedLocalStorage.getSerializedItem(LocalStorageKey.MotiffScopeConfig)
    const [followSelection, setFollowSelection] = useState(motiffScopeConfig?.followSelect ?? false)
    const [showCommonProps, setShowCommonProps] = useState(motiffScopeConfig?.autoShowCommon ?? false)

    const { size, handleResizeStart } = useResizeHandle({
        initialSize: { width: 800, height: 400 },
        minSize: { width: 480, height: 200 },
    })
    const command = useCommand()
    const { showMainPanel } = useViewState('motiffScope') ?? { showMainPanel: false }

    const { setShowMotiffScope } = useMenuContextState()

    useEffect(() => {
        enhancedLocalStorage.setSerializedItem(LocalStorageKey.MotiffScopeConfig, {
            followSelect: followSelection,
            autoShowCommon: showCommonProps,
            showMainPanel: showMainPanel,
        })
    }, [showCommonProps, followSelection, showMainPanel])
    useEffect(() => {
        setShowMotiffScope(showMainPanel)
    }, [showMainPanel, setShowMotiffScope])

    const [showPropPreferenceSetting, setShowPropPreferenceSetting] = useState(false)

    const onShowCommonPropsChange = (show: boolean) => {
        setShowCommonProps(show)
    }

    const onFollowSelectionChange = (follow: boolean) => {
        setFollowSelection(follow)
        command.invokeBridge(CommitType.Noop, UpdateMotiffScopeSettingsWasmCall, {
            updatedFollowSelection: follow,
        })
    }

    return (
        <DraggablePopupV2
            style={{
                display: showMainPanel ? 'block' : 'none',
            }}
            onCancel={() => {
                command.invokeBridge(CommitType.Noop, UpdateMotiffScopeSettingsWasmCall, {
                    updatedShowMainPanel: false,
                })
            }}
            visible={true}
            header={
                <MotiffScopeHeader
                    followSelection={followSelection}
                    setFollowSelection={onFollowSelectionChange}
                    showCommonProps={showCommonProps}
                    onShowCommonPropsChange={onShowCommonPropsChange}
                    showPropPreferenceSetting={showPropPreferenceSetting}
                    updateShowPropPreferenceSetting={setShowPropPreferenceSetting}
                />
            }
            footer={null}
            range={undefined}
            bodyClassName="p-0"
            width={size.width}
            height={size.height}
            maxScrollHeight={size.height - TITLE_HEIGHT}
            enableScrollBar={true}
            testId="motiff-scope"
            styleType="editor"
        >
            <MotiffScopePanel
                showPropPreferenceSetting={showPropPreferenceSetting}
                followSelection={followSelection}
                showCommonProps={showCommonProps}
                height={size.height - TITLE_HEIGHT}
            />
            <MonoIconPanelTidyUpGrid16 className={styles.resizeHandle} onPointerDown={handleResizeStart} />
        </DraggablePopupV2>
    )
}
