import { FC, MouseEventHandler, PropsWithChildren, useEffect, useMemo, useRef } from 'react'
import { caculatePlaceNodeIndent } from '../../../layer-panel/render-item-drag/util'
import { MouseStatus, useDragContext } from './drag-context'
import styles from './drag-item.module.less'

enum DragArea {
    None,
    Top,
    Middle,
    Bottom,
}

interface DragItemProps {
    itemIndex: number
    isFolderCollapse: boolean
}

const SingleIndentMovement = 24

export const DragItem: FC<PropsWithChildren<DragItemProps>> = ({ children, itemIndex, isFolderCollapse }) => {
    const {
        mouseStatusRef,
        mouseDownPositionRef,
        selectionMinIndentRef,
        dragExpandFolderRef,
        dragTargetInfo,
        setDropInfo,
        handleMouseUp,
        selection,
        handleToggleFolderCollapse,
    } = useDragContext()

    const dragAreaBeforeCheck = useMemo(() => {
        if (!dragTargetInfo || dragTargetInfo.itemIndex != itemIndex) {
            return DragArea.None
        }

        const { item, mouseArea } = dragTargetInfo
        // 如果是容器节点，则有上中下三个部位
        if (item.isFolder) {
            if (mouseArea < 0.3) {
                return DragArea.Top
            } else if (mouseArea > 0.7) {
                return DragArea.Bottom
            } else {
                return DragArea.Middle
            }
        } // 非容器节点有上和下两个部位
        else {
            if (mouseArea < 0.5) {
                return DragArea.Top
            }
            return DragArea.Bottom
        }
    }, [dragTargetInfo, itemIndex])

    // 根据选中的内容检查是否可以插入当前位置
    const dragArea = useMemo(() => {
        if (!dragTargetInfo || dragAreaBeforeCheck == DragArea.None) {
            return DragArea.None
        }

        const containStyles = selection.styleIds.length
        const containFolders = selection.folderNames.length
        if (containStyles && containFolders) {
            return dragAreaBeforeCheck
        }

        const { item, preItem, nextItem } = dragTargetInfo
        if (dragAreaBeforeCheck == DragArea.Top) {
            if (containStyles) {
                // 样式不能插入folder之间(preItem为item的父级目录除外)
                return item?.isFolder && preItem?.isFolder && preItem.level >= item.level
                    ? DragArea.None
                    : dragAreaBeforeCheck
            } else {
                // folder不能插入style之前
                return item && !item.isFolder ? DragArea.None : dragAreaBeforeCheck
            }
        } else if (dragAreaBeforeCheck == DragArea.Bottom) {
            if (containStyles) {
                // 样式不能插入folder之间(item为nextItem的父级目录除外)
                return item?.isFolder && nextItem?.isFolder && item.level >= nextItem.level
                    ? DragArea.None
                    : dragAreaBeforeCheck
            } else {
                // folder不能插入style之前
                return nextItem && !nextItem.isFolder ? DragArea.None : dragAreaBeforeCheck
            }
        } else if (dragAreaBeforeCheck == DragArea.Middle) {
            return dragAreaBeforeCheck
        } else {
            return DragArea.None
        }
    }, [dragAreaBeforeCheck, dragTargetInfo, selection])

    // 由位移改变的层级
    const movementIndent = useMemo<number>(() => {
        if (!dragTargetInfo) {
            return 0
        }
        const indent = dragTargetInfo.movementX / SingleIndentMovement
        return indent < 0 ? Math.ceil(indent) : Math.floor(indent)
    }, [dragTargetInfo])

    // 计算当前引导线的层级
    const currentIndent = useMemo<number>(() => {
        if (!dragTargetInfo) {
            return 0
        }

        const { item, preItem, nextItem } = dragTargetInfo
        const currentItemIndent = item?.level ?? 0
        const prevItemIndent = preItem?.level ?? 0
        // 在改变放置的层级时，不能放置在下层容器层级之上的位置
        const minIndent = nextItem?.level ?? 0

        switch (dragArea) {
            // 如果拖拽不在当前节点，则忽略
            case DragArea.None: {
                return 0
            }

            // 放置到当前元素的下面
            case DragArea.Bottom: {
                // 只包含样式则不能提高缩进层级
                if (selection.folderNames.length === 0) {
                    return currentItemIndent + (item.isFolder ? 1 : 0)
                }
                return caculatePlaceNodeIndent(
                    // 容器节点可以放置在容器内部，故默认的层级可以 + 1
                    currentItemIndent + (item.isFolder ? 1 : 0),
                    minIndent,
                    selectionMinIndentRef.current!,
                    movementIndent
                )
            }
            // 放置到当前元素的中间【一定是容器节点，放置到容器节点内部】
            case DragArea.Middle: {
                return currentItemIndent + 1
            }

            // 放置到当前元素的上方
            case DragArea.Top: {
                // 如果上一个元素不存在，则 indent 一定为 0
                if (!preItem) {
                    return 0
                }
                // 只包含样式则不能提高缩进层级
                if (selection.folderNames.length === 0) {
                    return prevItemIndent + (preItem.isFolder ? 1 : 0)
                }
                return caculatePlaceNodeIndent(
                    prevItemIndent + (preItem.isFolder ? 1 : 0),
                    currentItemIndent,
                    selectionMinIndentRef.current!,
                    movementIndent
                )
            }
        }
    }, [dragArea, dragTargetInfo, selectionMinIndentRef, movementIndent, selection])

    // 设置放置节点的具体信息
    useEffect(() => {
        if (!dragTargetInfo) {
            return
        }

        switch (dragArea) {
            // 不在 drag 区域，跳过
            case DragArea.None: {
                // 当前位置不可插入，则清理上次的DropInfo
                if (dragAreaBeforeCheck !== DragArea.None) {
                    setDropInfo(undefined)
                }
                return
            }
            // 在 drag 上半区，放置到 preItem 上
            case DragArea.Top: {
                setDropInfo({
                    item: dragTargetInfo.preItem,
                    indent: currentIndent,
                })
                break
            }
            // 其余情况放置到 item 上
            default: {
                setDropInfo({
                    item: dragTargetInfo.item,
                    indent: currentIndent,
                })
            }
        }
    }, [currentIndent, dragArea, dragAreaBeforeCheck, dragTargetInfo, setDropInfo])

    const expandTimer = useRef<NodeJS.Timeout>()

    // 如果在容器中心停留 1.5s，且容器为折叠时，展开容器
    useEffect(() => {
        if (dragArea === DragArea.Middle && isFolderCollapse) {
            expandTimer.current = setTimeout(() => {
                if (dragTargetInfo?.item?.folderName) {
                    handleToggleFolderCollapse(dragTargetInfo.item.folderName)
                    dragExpandFolderRef.current = dragExpandFolderRef.current.concat([dragTargetInfo.item.folderName])
                }
            }, 1500)
        } else {
            if (expandTimer.current) {
                clearTimeout(expandTimer.current)
            }
        }
    }, [dragArea, isFolderCollapse, dragExpandFolderRef, handleToggleFolderCollapse, dragTargetInfo?.item?.folderName])

    const handleMouseDown: MouseEventHandler<HTMLDivElement> = (event) => {
        const { button, metaKey, shiftKey, ctrlKey, clientX, clientY } = event
        // 只响应单纯的左键点下事件
        if (metaKey || shiftKey || button !== 0 || (button === 0 && ctrlKey)) {
            return
        }

        //  触发开始拖拽
        mouseStatusRef.current = MouseStatus.Down
        // 记录点击点的 X/Y 信息
        mouseDownPositionRef.current = { x: clientX, y: clientY }
    }

    const marginLeft = Math.min(248 - 64, 16 + currentIndent * SingleIndentMovement) + 'px'
    return (
        <div className={styles.root} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
            {dragArea === DragArea.Top && <div className={styles['drag-line']} style={{ left: marginLeft }} />}
            {children}
            {dragArea === DragArea.Bottom && <div className={styles['drag-line']} style={{ left: marginLeft }} />}
        </div>
    )
}
