// NOTE: 此文件由 auto-export.js 自动生成，请勿自定义编辑
import type { HTMLAttributes } from 'react'
import { ReactComponent as IconLogoFigmaLarge } from './logo-figma-large.svg'
import { ReactComponent as IconMissingLibrary } from './missing-library.svg'

/**
 * @deprecated 请使用新的图标
 * @description 这是个60*60的图标，iconv2里没有这种。目前只有导入一个需要figma权限验证的组件文档那里用到了
 */
export const IconLogoFigmaLargeSvg = (props: HTMLAttributes<SVGElement>) => {
    return <IconLogoFigmaLarge {...props} />
}
/**
 * @deprecated 请使用新的图标
 * @description 目前没有这个图标
 */
export const IconMissingLibrarySvg = (props: HTMLAttributes<SVGElement>) => {
    return <IconMissingLibrary {...props} />
}
