import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    AccountLoginStatus: '账号登录状态异常，请刷新页面重新登录',
    Refresh: '刷新页面',
} as const

export const enTranslation = {
    AccountLoginStatus:
        'An abnormality has been detected in your login status, try to reload the page and log in again.',
    Refresh: 'Reload',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
