import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    AfterUploadingTeam: '上传团队字体后，',
    TeamMembersCan: '团队成员无需安装可直接使用',
    UploadFonts: '上传团队字体',
    AfterUploadingTeam_synonyms2: '上传共享字体，团队成员无需安装可直接使用。',
    AfterUploadingTeamNew: '上传共享字体',
    TeamMembersCanNew: '团队成员无需安装可直接使用',
    UploadFontsNew: '上传共享字体',
    LearnMore: '了解更多',
} as const

export const enTranslation = {
    AfterUploadingTeam: 'Once team fonts are uploaded, ',
    TeamMembersCan: 'they are available to everyone on your team.',
    UploadFonts: 'Upload fonts',
    AfterUploadingTeam_synonyms2: 'Upload a shared font for use by everyone in the team.',
    AfterUploadingTeamNew: 'Upload a shared font',
    TeamMembersCanNew: 'for anyone at team to use.',
    UploadFontsNew: 'Upload shared fonts',
    LearnMore: 'Learn more',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
