import classNames from 'classnames'
import { Scrollbar, WKTabsV2 } from '../../../../../../ui-lib/src'
import { useAIDesignLintService } from '../../../../main/app-context'
import classes from './ai-design-lint-modal.module.less'
import { ColorStyleCardList } from './common/color-card-list'
import { ComponentCardList } from './common/component-card-list'
import { EmptyPrompt } from './common/empty-prompt'
import { TextStyleCardList } from './common/text-card-list'
// eslint-disable-next-line wukong/restrict-translation-import
import { translation } from './translation/result-page.translation'

export enum AIDesignLintResultTab {
    Text = 'Text',
    Color = 'Color',
    Component = 'Component',
}

function ColorStyleResult() {
    const aiDesignLintService = useAIDesignLintService()
    const colorCardInfoList = aiDesignLintService.states.use.colorCardInfoListState()

    return (
        <Scrollbar height="100%">
            {colorCardInfoList.sameStyleCardInfos.length + colorCardInfoList.similarStyleCardInfos.length > 0 ? (
                <ColorStyleCardList cardInfoList={colorCardInfoList}></ColorStyleCardList>
            ) : (
                <EmptyPrompt texts={[translation('ColorTagEmptyPrompt')]} />
            )}
        </Scrollbar>
    )
}

function TextStyleResult() {
    const aiDesignLintService = useAIDesignLintService()
    const textCardInfoList = aiDesignLintService.states.use.textCardInfoListState()

    return (
        <Scrollbar height="100%">
            {textCardInfoList.sameStyleCardInfos.length + textCardInfoList.similarStyleCardInfos.length > 0 ? (
                <TextStyleCardList cardInfoList={textCardInfoList}></TextStyleCardList>
            ) : (
                <EmptyPrompt texts={[translation('TextTagEmptyPrompt')]} />
            )}
        </Scrollbar>
    )
}

function ComponentResult() {
    const aiDesignLintService = useAIDesignLintService()
    const componentCardInfoList = aiDesignLintService.states.use.componentCardInfoListState()

    return (
        <Scrollbar height="100%">
            {componentCardInfoList?.length ? (
                <ComponentCardList cardInfoList={componentCardInfoList}></ComponentCardList>
            ) : (
                <EmptyPrompt texts={[translation('ComponentTagEmptyPrompt')]} />
            )}
        </Scrollbar>
    )
}

export function ResultPage() {
    return (
        <WKTabsV2
            className={classNames(classes.tag_page_container)}
            defaultActiveKey={AIDesignLintResultTab.Text}
            navListClassName={classNames(classes.tag_nav_list_container, 'py-2px')}
            keepInactiveTabPane
        >
            <WKTabsV2.Item
                tabKey={AIDesignLintResultTab.Text}
                label={
                    <div data-testid={'text-tag'} className={classes.tag_title}>
                        {translation('Text')}
                    </div>
                }
            >
                <TextStyleResult />
            </WKTabsV2.Item>
            <WKTabsV2.Item
                tabKey={AIDesignLintResultTab.Color}
                label={
                    <div data-testid={'color-tag'} className={classes.tag_title}>
                        {translation('Color')}
                    </div>
                }
            >
                <ColorStyleResult />
            </WKTabsV2.Item>
            <WKTabsV2.Item
                tabKey={AIDesignLintResultTab.Component}
                label={
                    <div data-testid={'component-tag'} className={classes.tag_title}>
                        {translation('Component')}
                    </div>
                }
            >
                <ComponentResult />
            </WKTabsV2.Item>
        </WKTabsV2>
    )
}
