import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    SearchFonts: '搜索团队字体',
    SearchSharedFonts: '搜索共享字体',
    LearnMoreSharedFonts: '上传共享字体，企业成员无需安装可直接使用。',
} as const

export const enTranslation = {
    SearchFonts: 'Search team fonts',
    SearchSharedFonts: 'Search shared fonts',
    LearnMoreSharedFonts: 'Upload a shared font for use by everyone in the org.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
