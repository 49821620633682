import React, { forwardRef } from 'react'

import {
    SingleLevel as SelectSingleLevel,
    SingleLevelProps as SelectSingleLevelProps,
    SingleLevelRef as SelectSingleLevelRef,
} from '../../../selects/picks/single-level/single-level'
import { getOptionMaxWidth } from '../option-width'

export interface SingleLevelProps extends SelectSingleLevelProps {}

export interface SingleLevelRef extends SelectSingleLevelRef {}

function _SingleLevel<T extends SingleLevelProps>(props: T, ref?: React.Ref<SingleLevelRef>) {
    const { placement = 'bottom left', maxWidth, ...otherProps } = props

    return (
        <SelectSingleLevel placement={placement} maxWidth={getOptionMaxWidth(maxWidth)} {...otherProps} ref={ref}>
            {props.children}
        </SelectSingleLevel>
    )
}

export const SingleLevel = forwardRef(_SingleLevel) as <T extends SingleLevelProps = SingleLevelProps>(
    props: T & { ref?: React.Ref<SingleLevelRef> }
) => React.ReactElement
