import { CommentCreateButton, CommentCreateButtonProps } from '../common-create-button/comment-create-button'
import { CommentJumpLink, CommentJumpLinkProps } from '../common-jump-link/comment-jump-link'

export interface CommonFooterProps {
    isRemoteStyle: boolean
    isCreateStyle: boolean
    onClickJumpLink?: CommentJumpLinkProps['onClick']
    onClickCreateButton?: CommentCreateButtonProps['onClick']
}
export function CommonFooter(props: CommonFooterProps) {
    return (
        <>
            {props.isRemoteStyle ? <CommentJumpLink onClick={props.onClickJumpLink} /> : null}
            {props.isCreateStyle ? <CommentCreateButton onClick={props.onClickCreateButton} /> : null}
        </>
    )
}
