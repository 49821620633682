import classes from './ai-tool-title.module.less'

export function AiToolTitle() {
    return (
        <div className={classes.OuterContainer}>
            <div className={classes.Container}>
                <div className={classes.Title}>Motiff AI</div>
                <div className={classes.TagBackground}>
                    <div className={classes.Tag}>Beta</div>
                </div>
            </div>
        </div>
    )
}
