import { useEffect, useState } from 'react'
import { IN_JEST_TEST } from '../../../../../environment'

export const useLazyItem = (ref: React.RefObject<HTMLDivElement>) => {
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        if (!ref.current) return

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    setVisible(entry.isIntersecting)
                })
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0,
            }
        )

        observer.observe(ref.current)

        return () => {
            observer.disconnect()
        }
    }, [ref])

    if (IN_JEST_TEST) {
        // jest测试中无法正确计算是否可见
        return { visible: true } as const
    }

    return { visible } as const
}
