/* eslint-disable no-restricted-imports */
import {
    LayerPanelClickBlankCommandWasmCall,
    LayerPanelEnterAreaCommandWasmCall,
    UpdateLayerPanelClientHeightCommand,
    UpdateLayerPanelScrollTopWasmCall,
    Wukong,
} from '@wukong/bridge-proto'
import { debounce } from 'lodash-es'
import { FC, MouseEventHandler, UIEventHandler, useCallback, useEffect, useMemo, useRef } from 'react'
import { useUnmount } from 'react-use'
import { Scrollbar, ScrollbarRef } from '../../../../../../ui-lib/src'
import { CommitType } from '../../../../document/command/commit-type'
import { documentLoaded$ } from '../../../../external-store/atoms/editor-context'
import { appStore$ } from '../../../../external-store/store'
import { useLayerPanelService } from '../../../../main/app-context'
import { useLayerPanelScrollTop } from '../../../../main/history-mode/context/layer-panel-scroll'
import { useViewState } from '../../../../view-state-bridge'
import { EditorDataTestId } from '../../../../window'
import { useCommand } from '../../../context/document-context'
import { blurActiveElement } from '../../../utils/active-element'
import { LayerPanelAILayoutColorfulLine } from '../ai-layout-line'
import { LayerPanelRenderList } from '../render-list'
import styles from './index.module.less'

export const LayerPanelLayout: FC<{ layerPanelShow: boolean }> = () => {
    const command = useCommand()
    const layerPanelService = useLayerPanelService()
    const ref = useRef<ScrollbarRef>(null)

    const containerRef = useRef<HTMLDivElement>(null)
    const { scrollTop: containerScrollTop, setScrollTop: setContainerScrollTop } = useLayerPanelScrollTop()
    const { renderList, translateHeight, totalHeight, topLevelFixedFrame } = useViewState('layerPanel')!
    const { nodeUsingModes } = useViewState('variableLayerUsingMode')!

    const autoPosition = layerPanelService.states.use.autoPosition()
    const hasAutoPositioning = layerPanelService.states.use.hasAutoPositioning()

    useEffect(() => {
        const element = ref.current?.getContainerElement()

        if (!element) return

        const handleResize = debounce(() => {
            if (element) {
                command.invokeBridge(
                    CommitType.Noop,
                    UpdateLayerPanelClientHeightCommand,
                    Wukong.DocumentProto.BridgeProtoInt.create({
                        value: element.clientHeight,
                    })
                )
            }
        }, 200)

        const observer = new ResizeObserver(handleResize)
        observer.observe(element)

        return () => observer.disconnect()
    }, [command])

    useEffect(() => {
        const element = ref.current?.getContainerElement()
        if (!hasAutoPositioning && element) {
            layerPanelService.setHasAutoPositioning(true)
            if (autoPosition !== ref.current?.getScrollTop()) {
                requestAnimationFrame(() => {
                    ref.current?.scrollTop(autoPosition)
                })
            }
        }
    }, [autoPosition, hasAutoPositioning, layerPanelService])

    const hasScrolled = useMemo(() => {
        return containerScrollTop !== 0
    }, [containerScrollTop])

    // 触发滚动时的回调
    const handleScroll: UIEventHandler<HTMLDivElement> = useCallback(
        (event) => {
            const { scrollTop } = event.target as HTMLDivElement
            setContainerScrollTop(scrollTop)
            command.invokeBridge(
                CommitType.Noop,
                UpdateLayerPanelScrollTopWasmCall,
                Wukong.DocumentProto.BridgeProtoInt.create({
                    value: scrollTop,
                })
            )
        },
        [command, setContainerScrollTop]
    )

    // 图层面板组件注销时，更新 wasm 侧 scrollTop 为 0，避免下次打开时 wasm 侧计算的虚拟列表不正确
    useUnmount(() => {
        if (appStore$.get(documentLoaded$)) {
            command.invokeBridge(CommitType.Noop, UpdateLayerPanelScrollTopWasmCall, {
                value: 0,
            })
        }
    })

    // 在空白区域点击回调
    const handleMouseDown: MouseEventHandler<HTMLDivElement> = useCallback(
        (event) => {
            blurActiveElement()
            event.stopPropagation()
            command.invokeBridge(CommitType.CommitUndo, LayerPanelClickBlankCommandWasmCall)
        },
        [command]
    )

    const handleMouseEnter = useCallback(() => {
        command.invokeBridge(
            CommitType.Noop,
            LayerPanelEnterAreaCommandWasmCall,
            Wukong.DocumentProto.BridgeProtoBoolean.create({
                value: true,
            })
        )
    }, [command])

    const handleMouseLeave = useCallback(() => {
        command.invokeBridge(
            CommitType.Noop,
            LayerPanelEnterAreaCommandWasmCall,
            Wukong.DocumentProto.BridgeProtoBoolean.create({
                value: false,
            })
        )
    }, [command])

    return useMemo(
        () => (
            <div className={styles['layer-panel-container']} style={{ flexGrow: 1, flexShrink: 0, height: 32 }}>
                <Scrollbar onScroll={handleScroll} ref={ref} renderViewClassName={styles.scrollBarRenderView}>
                    <div
                        ref={containerRef}
                        className={styles.hiddenHorizontal}
                        data-testid={EditorDataTestId.LayerPanel}
                        onMouseDown={handleMouseDown}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <LayerPanelRenderList
                            renderList={renderList as Wukong.DocumentProto.VLayerPanelItemInfo[]}
                            translateHeight={translateHeight}
                            totalHeight={totalHeight}
                            layerPanelContainerRef={() => ref.current?.getContainerElement() as HTMLDivElement}
                            topLevelFixedFrame={topLevelFixedFrame as Wukong.DocumentProto.TopLevelFixedFrame}
                            hasScrolled={hasScrolled}
                            layerUsingModesMap={nodeUsingModes}
                        />
                    </div>
                    <LayerPanelAILayoutColorfulLine />
                </Scrollbar>
            </div>
        ),
        [
            handleScroll,
            handleMouseDown,
            handleMouseEnter,
            handleMouseLeave,
            renderList,
            translateHeight,
            totalHeight,
            topLevelFixedFrame,
            hasScrolled,
            nodeUsingModes,
        ]
    )
}
