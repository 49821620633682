import { translation } from './variant-panel.translation'
/* eslint-disable no-restricted-imports */
import { cloneDeep } from 'lodash-es'
import { useMemo, useRef, useState } from 'react'

import {
    IconAdjust,
    IconDelete16,
    IconError,
    InputV2,
    MonoIconCommonWarningLine16,
    Select,
    SimpleDrag,
    Tooltip,
    useEllipsisTooltip,
    WKButton,
    WKTextButton,
} from '../../../../../../../ui-lib/src'
import { IconButton } from '../../../atom/button/icon-button'
import { SingleGrid } from '../../../atom/grid/single-grid'
import { Title } from '../../../atom/title/title'

import { Wukong } from '@wukong/bridge-proto'
import { DeepRequired } from '../../../../../view-state-bridge'
import { frogEventToolsMenusAndActionsBasicAction } from '../../../../utils/tools-actions-frog'
import { SimpleDragInline } from '../../../atom/simple-drag-inline/simple-drag-inline'
import { useComponentInstanceCommand } from '../commands'
import { CompSetPropConflict, CompSetPropConflictType, getConflictText, VariantState } from '../types'
import { ComponentConfigPopup } from './component-config-popup'
import { useComponentTitlePanel } from './component-title-panel/hook'
import style from './variant-panel.module.less'

export const VariantTestIds = {
    title: 'variant-title',

    combineBtn: 'variant-combine-btn', // 合并为变体按钮
    addBtn: 'variant-add-btn', // 增加变体按钮

    // 组件集操作
    moreActionBtn: 'variant-more-action-btn',
    addVariantBtn: 'component-set-add-variant-btn', // 增加新变体
    addNewPropBtn: 'component-set-add-new-prop-btn', // 增加新属性
    addNewPropInput: 'component-set-new-prop-btn', // {translation('CreateComponentProperty')}输入框

    dragVarProp: 'variant-prop-drag',
    variantProp: (key: string) => ({
        name: `variant-prop-name-${key}`,

        drag: `variant-prop-name-${key}-drag`,
        // 可编辑属性值
        value: `variant-prop-value-${key}`,
        valueInput: `variant-prop-value-${key}-input`,
        valueSelect: `variant-prop-value-${key}-select`,
        select: `variant-prop-${key}-select`, // 实例属性下拉框
        switch: `variant-prop-${key}-switch`,
        option: (value: string) => `variant-prop-value-${key}-${value}`,
    }), // 每个变体属性的 id
    dragCompSetProp: 'component-set-prop-drag',
    compSetProp: (key: string) => ({
        name: `component-set-prop-name-${key}`,
        delBtn: `component-set-prop-del-btn-${key}`,
        drag: `component-set-prop-del-btn-${key}-drag`,
        option: (value: string) => {
            const text = `component-set-prop-value-${key}-${value}`
            return { text: text, input: text + '-input' }
        },
    }), // 组件集的每个属性的 id
    conflictPanelActionBtn: `component-set-conflict-panel-action`, // 冲突面板
    adjustBtn: 'variant-config-adjust-btn', // 变体配置调整按钮
    descPreview: 'variant-desc-preview', // 变体描述预览
}

// 冲突面板
export function ConflictPanel(props: CompSetPropConflict) {
    const text = getConflictText(props)
    const commands = useComponentInstanceCommand()

    return (
        <div className={style.conflictV2}>
            <div className={style.tip}>
                <MonoIconCommonWarningLine16 className={style.tipIcon} />
                <div className={`${style.tipContent} ${text.actionText && style.hasLink}`}>{text.tipText}</div>
            </div>
            {text.actionText && (
                <WKTextButton
                    type="primary"
                    size={12}
                    onClick={() => commands.selectNodes(props.type)}
                    testId={VariantTestIds.conflictPanelActionBtn}
                    className={style.actionBtn}
                >
                    {text.actionText}
                </WKTextButton>
            )}
        </div>
    )
}

// 变体被删除面板
export function VariantRemovedPanel({ recoverToDefaultVairent }: { recoverToDefaultVairent: () => void }) {
    return (
        <div className={style.conflict}>
            <div className={style.content}>
                <div className={style.tipIcon}>
                    <IconError />
                </div>
                <div className={style.tipContent}>{translation('TheSelectedVariant_synonyms1')}</div>
                <WKButton type="secondary" className={style.tipRecoverBtn} onClick={recoverToDefaultVairent}>
                    {translation('ResetToDefault')}
                </WKButton>
            </div>
        </div>
    )
}

// 组件集属性
export function ComponentSetProperty(props: VariantState) {
    const compSetProps = props.compSetProps
    type CompsetProps = typeof compSetProps
    const commands = useComponentInstanceCommand()
    const [selectedDragItems, setSelectedDragItems] = useState<number[]>([])
    const [selectedDragOptions, setSelectedDragOptions] = useState<string[]>([])

    const shouldHideDeleteBtn = compSetProps.length < 2

    // 更新属性，并同步到 wasm
    const updatePropsWith = (modifier: (newProps: CompsetProps) => void) => {
        const newProps = cloneDeep(compSetProps)
        modifier(newProps)
        commands.updateCompSetProps(newProps)
    }

    // 与组件集属性更新相关的所有操作
    const propsAction = (i: number) => ({
        renameTo(newPropName: string) {
            // 如果新值为空或者没有变化，则不更新
            if (newPropName.trim().length > 0 && newPropName !== compSetProps[i].name) {
                updatePropsWith((newProps) => (newProps[i].name = newPropName))
            }
        },
        delete() {
            updatePropsWith((newProps) => newProps.splice(i, 1))
        },
        updateOption: (optionIndex: number) => (newOptionValue: string) => {
            updatePropsWith((newProps) => (newProps[i].options[optionIndex] = newOptionValue))
        },
    })

    return (
        <SimpleDrag
            selectedIndexList={selectedDragItems}
            items={compSetProps}
            onSelectChange={setSelectedDragItems}
            onDragDone={(setProps) => {
                commands.updateCompSetProps(setProps)
            }}
            data-testid={VariantTestIds.dragCompSetProp}
        >
            {compSetProps.map((prop, propIndex) => {
                const testIds = VariantTestIds.compSetProp(prop.name)
                const propAction = propsAction(propIndex)

                return (
                    <SimpleDrag.Item
                        key={propIndex}
                        itemIndex={propIndex}
                        dragIconClassName={style.dragIconClassName}
                        dataTestIds={{ dragIcon: testIds.drag }}
                    >
                        <SingleGrid>
                            <SingleGrid.Item start={5} span={46} data-simple-drag-ignore>
                                <InputV2
                                    value={prop.name}
                                    className={style.keyContainer}
                                    onBlur={(e) => propAction.renameTo(e.target.value)}
                                    data-testid={testIds.name}
                                    size="small"
                                    removeLeftPadding
                                    removeRightPadding
                                    removeDefaultBackgroundColor
                                    style={{ width: '100%' }}
                                />
                            </SingleGrid.Item>
                            <SingleGrid.Item start={54} span={6}>
                                {!shouldHideDeleteBtn && (
                                    <IconButton
                                        icon={<IconDelete16 />}
                                        selected={false}
                                        onClick={propAction.delete}
                                        dataTestId={testIds.delBtn}
                                        data-simple-drag-ignore
                                    />
                                )}
                            </SingleGrid.Item>
                        </SingleGrid>
                        <SingleGrid style={{ gridTemplateRows: 'unset' }}>
                            <SingleGrid.Item start={5} span={52} className={style.gridItem}>
                                <SimpleDragInline
                                    selects={selectedDragOptions}
                                    items={prop.options}
                                    onSelectChange={setSelectedDragOptions}
                                    onDragDone={(newOptions) => {
                                        commands.reorderSelectedComponentSetPropOptions({
                                            selectedVariantPropKey: prop.name,
                                            newOptions: newOptions,
                                        })
                                    }}
                                >
                                    {prop.options.map((option, optionIndex) => (
                                        <ComponentSetOption
                                            key={option}
                                            optionValue={option}
                                            onOptionChange={propAction.updateOption(optionIndex)}
                                            optionTestId={testIds.option(option).text}
                                            optionInputTestId={testIds.option(option).input}
                                        />
                                    ))}
                                </SimpleDragInline>
                            </SingleGrid.Item>
                        </SingleGrid>
                    </SimpleDrag.Item>
                )
            })}
        </SimpleDrag>
    )
}

function ComponentSetOption(props: {
    optionValue: string
    onOptionChange: (_: string) => void
    optionTestId?: string
    optionInputTestId?: string
}) {
    const [isEditing, setIsEditing] = useState(false)
    const onOptionChange = (value: string) => {
        if (value.trim().length > 0 && value !== props.optionValue) {
            // 当且仅当输入非空且字符串变化时
            props.onOptionChange(value)
        }
        setIsEditing(false)
    }

    return (
        <SimpleDragInline.Item
            item={props.optionValue}
            dragContainerClassName={style.valueContainer}
            onDoubleClick={() => setIsEditing(true)}
        >
            <div>
                <div className={style.divValue} data-testid={props.optionTestId}>
                    {props.optionValue}
                </div>
                {isEditing ? (
                    <InputV2
                        rootClassName="absolute inset-0"
                        value={props.optionValue}
                        onBlur={(e) => onOptionChange(e.target.value)}
                        autoFocus
                        data-testid={props.optionInputTestId}
                        size="small"
                    />
                ) : null}
            </div>
        </SimpleDragInline.Item>
    )
}

// 变体和变体实例属性
export function VariantProperty(props: VariantState) {
    const { variantProps } = props
    const commands = useComponentInstanceCommand()

    return variantProps.map((prop, propIndex) => {
        const onChangePropValue = (newPropValue: string | number) => {
            const varProps = cloneDeep(variantProps)
            if (
                newPropValue &&
                newPropValue.toString().trim().length > 0 &&
                newPropValue !== variantProps[propIndex].value
            ) {
                varProps[propIndex].value = newPropValue.toString()
                commands.updateVariantProps(varProps)
            }
        }

        return <PropItem prop={prop} onChangePropValue={onChangePropValue} key={propIndex} />
    })
}

export function VariantPanel(props: VariantState & { showVariantPropOnInstancePanel: boolean }) {
    const commands = useComponentInstanceCommand()
    const { popupVisible, updateComponentDesc, openComponentConfigPopup, closeComponentConfigPopup } =
        useComponentTitlePanel()
    const containerRef = useRef<HTMLDivElement>(null)
    const popupPosition = useMemo(() => {
        if (popupVisible && containerRef.current) {
            const { left, top } = containerRef.current.getBoundingClientRect()
            return { left, top }
        }
    }, [popupVisible])
    const isVariantInstanceOrRemoved = props.isVariantInstance || props.isRemovedVariantInstance
    if (props.isEmpty) {
        return <></>
    }

    const handleAdjustIconClick = () => {
        popupVisible ? closeComponentConfigPopup() : openComponentConfigPopup()
    }

    const showCombineAsVariant = props.showCombineAsVariant
    const showVariantProperty = props.showVariantProp
    const showVariantConflict =
        !props.isRemovedVariantInstance &&
        (props.showVariantProp || props.showVariantPropOnInstancePanel) &&
        props.conflict.type !== CompSetPropConflictType.COMP_SET_PROP_CONFLICT_TYPE_EMPTY
    const showVariantRemovePanel = props.isRemovedVariantInstance
    const showAdjustConfig = props.showAdjustConfig
    const showVariantDesc = !props.variantDescUnit.isDescMixed && props.variantDescUnit.desc.length > 0

    if (
        !showCombineAsVariant &&
        !showVariantProperty &&
        !showVariantConflict &&
        !showVariantRemovePanel &&
        !isVariantInstanceOrRemoved
    ) {
        return <></>
    }
    return (
        <div
            ref={containerRef}
            className={`${style.variantPanel} ${isVariantInstanceOrRemoved && style.noPaddingTop}`}
            data-testid={'variant-panel'}
        >
            {!isVariantInstanceOrRemoved && (
                <>
                    <Title>
                        <Title.Left data-testid={VariantTestIds.title}>
                            {showCombineAsVariant ? translation('Variant') : translation('CurrentVariant')}
                        </Title.Left>
                        <Title.Right>
                            <>
                                {showAdjustConfig && (
                                    <Tooltip title={translation('ComponentConfig')}>
                                        <IconButton
                                            dataTestId={VariantTestIds.adjustBtn}
                                            deepColor
                                            icon={<IconAdjust />}
                                            selected={popupVisible}
                                            onClick={handleAdjustIconClick}
                                        />
                                    </Tooltip>
                                )}
                            </>
                        </Title.Right>
                    </Title>
                    {showVariantDesc && (
                        <SingleGrid>
                            <SingleGrid.Item start={5} span={50}>
                                <div data-testid={VariantTestIds.descPreview} className={style.variantDescPreview}>
                                    {props.variantDescUnit.desc}
                                </div>
                            </SingleGrid.Item>
                        </SingleGrid>
                    )}

                    {showAdjustConfig && popupVisible && (
                        <ComponentConfigPopup
                            type="variant"
                            compSetDescUnit={props.compSetDescUnit}
                            variantDescUnit={props.variantDescUnit}
                            position={popupPosition}
                            onClose={closeComponentConfigPopup}
                            onDescTextAreaChange={updateComponentDesc}
                        />
                    )}
                </>
            )}
            {showCombineAsVariant && (
                <SingleGrid>
                    <SingleGrid.Item start={5} span={54}>
                        <WKButton
                            type="secondary"
                            className={style.button}
                            onClick={() => {
                                commands.combineAsVariants()
                                frogEventToolsMenusAndActionsBasicAction({
                                    action_name: '创建变体组件',
                                    operation_way: '右边栏-合并变体',
                                })
                            }}
                            data-testid={VariantTestIds.combineBtn}
                        >
                            {translation('CombineAsVariants')}
                        </WKButton>
                    </SingleGrid.Item>
                </SingleGrid>
            )}
            {showVariantProperty && <VariantProperty {...props} />}
            {showVariantConflict && <ConflictPanel {...props.conflict} />}
            {showVariantRemovePanel && (
                <VariantRemovedPanel recoverToDefaultVairent={commands.recoverToDefaultVairent} />
            )}
        </div>
    )
}

// 这里改动的话，需要考虑 InstanceVariantPropItem 组件也需不需要改动
function PropItem(props: {
    prop: DeepRequired<Wukong.DocumentProto.IVariantProp>
    onChangePropValue: (newPropValue: string | number) => void
}) {
    const { prop, onChangePropValue: onChangePropValueOut } = props
    const { inactivation, ellipsisRef, onmouseenter } = useEllipsisTooltip<HTMLDivElement>()
    const testIds = VariantTestIds.variantProp(prop.compSetProp.name)
    const switchRef = useRef<HTMLButtonElement>(null)
    const onChangePropValue = (newPropValue: string | number) => {
        onChangePropValueOut(newPropValue)
        switchRef.current?.blur()
    }

    return (
        <SingleGrid className={style.grid}>
            <SingleGrid.Item start={5} span={18}>
                <Tooltip title={prop.compSetProp.name} inactivation={inactivation}>
                    <div
                        className={style.propertyKeyV2}
                        data-testid={testIds.name}
                        ref={ellipsisRef}
                        onMouseEnter={onmouseenter}
                        data-simple-drag-ignore
                    >
                        {prop.compSetProp.name}
                    </div>
                </Tooltip>
            </SingleGrid.Item>
            <SingleGrid.Item start={25} span={26} data-simple-drag-ignore>
                <Select.ArrowSingleLevel
                    isMixed={prop.isInvalid ? false : prop.isMixed}
                    value={prop.isInvalid ? '-' : prop.value}
                    onChange={onChangePropValue}
                    dataTestIds={{ triggerContainer: testIds.value, triggerFocus: testIds.valueSelect }}
                    label={
                        <InputV2
                            size="small"
                            value={prop.isInvalid ? '-' : prop.isMixed ? translation('Mixed') : prop.value}
                            onBlur={(e) => onChangePropValue(e.target.value)}
                            data-testid={testIds.valueInput}
                        />
                    }
                    maxWidth={232}
                >
                    {prop.compSetProp.options.map((option, optionIndex) => (
                        <Select.ArrowSingleLevel.Option
                            key={option + optionIndex.toString()}
                            value={option}
                            data-testid={testIds.option(option)}
                            placement="left"
                            tooltipTitle={option}
                        >
                            {option}
                        </Select.ArrowSingleLevel.Option>
                    ))}
                </Select.ArrowSingleLevel>
            </SingleGrid.Item>
            {prop.isInvalid && (
                <SingleGrid.Item start={55} span={4} className={style.errIcon}>
                    <MonoIconCommonWarningLine16 />
                </SingleGrid.Item>
            )}
        </SingleGrid>
    )
}
