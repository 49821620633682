import { PaintStyleNode } from '../../../../../../document/node/node'
import { LibraryStyleTestId } from '../../../../../../window'
import { getStyleShortName } from '../../../style/style-util'
import { CommonStyleInfo } from '../../get-style-nodes-info-map'
import { StyleListItemV2 } from '../../style-items-layout/style-list-item-v2'
import style from '../style-color-list-item.module.less'
import { PaintStyleThumbnail } from '../style-thumbnail'
export interface UnknowStyleColorListItemV2Props {
    item: Omit<CommonStyleInfo, 'groupName' | 'styleName'> &
        Pick<PaintStyleNode, 'publishFile' | 'fromFig' | 'publishId' | 'paints'>
    docId: string
    openStyleId?: string
    className?: string
    imageSize?: number
    disabledOnClick?: boolean
    children?: React.ReactNode
    onSelectItem: (item: CommonStyleInfo) => void
    onClickHoverIcon?: (
        item: CommonStyleInfo,
        containerDomRect: DOMRect,
        remoteStyleId?: string,
        docId?: string,
        name?: string,
        fromFig?: boolean
    ) => void
    onContextMenu?: (
        item: CommonStyleInfo,
        e: any,
        remoteStyleId?: string,
        docId?: string,
        name?: string,
        fromFig?: boolean
    ) => void
    isRightClickStyle?: boolean
    isSelected?: boolean
    isPreselect?: boolean
    onMouseEnter?: () => void
    onMouseLeave?: () => void
    index: number
    recordEnterCallback: (index: number, callback: () => void) => void
}

export function UnknowStyleColorListItemV2(props: UnknowStyleColorListItemV2Props) {
    const {
        item,
        openStyleId,
        onSelectItem,
        onClickHoverIcon,
        onContextMenu,
        isRightClickStyle,
        isSelected,
        isPreselect,
        onMouseEnter,
        onMouseLeave,
        index,
        recordEnterCallback,
    } = props
    const { id, name, fromFig } = props.item
    const docId = props.docId || props.item.publishFile

    return (
        <StyleListItemV2
            item={{
                ...item,
                groupName: '',
                styleName: '',
            }}
            isReadonly
            dataTestId={`${LibraryStyleTestId.UnknownPaintStyleItem}-${id}`}
            openStyleId={openStyleId}
            onSelectItem={onSelectItem}
            onClickHoverIcon={
                onClickHoverIcon &&
                (async (itemValue, containerDomRect) => {
                    onClickHoverIcon(itemValue, containerDomRect, item.publishId, docId, name, fromFig)
                })
            }
            onContextMenu={(itemValue, e) => {
                onContextMenu?.(itemValue, e, item.publishId, docId, name, fromFig)
            }}
            isRightClickStyle={isRightClickStyle}
            isSelected={isSelected}
            isPreselect={isPreselect}
            onMouseMove={onMouseEnter}
            onMouseLeave={onMouseLeave}
            index={index}
            recordEnterCallback={recordEnterCallback}
        >
            <PaintStyleThumbnail className={style.thumbnail} paints={item.paints} width={24} height={24} multiple={2} />
            <div className={style.name}>{getStyleShortName(name || '')}</div>
        </StyleListItemV2>
    )
}
