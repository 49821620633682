import { isNil } from 'lodash-es'
import { MotiffApi } from '@motiffcom/plugin-api-types'
import {
    FrameNodePluginData,
    FrameNotReadonlyProp,
    SceneNodePluginData,
    VectorNodePluginData,
    VectorNotReadonlyProp,
} from './constant'

interface Options {
    root: MotiffApi.FrameNode | null
    isRootFrame: boolean
}

export const DefaultOptions: Options = {
    root: null,
    isRootFrame: false,
}

function resizeNodeByTreeBoundingBox(
    node: MotiffApi.FrameNode | MotiffApi.VectorNode | MotiffApi.RectangleNode | MotiffApi.TextNode,
    tree: SceneNodePluginData
) {
    node.resize(tree.absoluteBoundingBox?.width ?? tree.width ?? 0, tree.absoluteBoundingBox?.height ?? 0)
}

function createFrameByJSONTree(frameNodeData: FrameNodePluginData, options = DefaultOptions): MotiffApi.FrameNode {
    const node = motiff.createFrame()
    resizeNodeByTreeBoundingBox(node, frameNodeData)
    if (options.root) {
        options.root.appendChild(node)
    }
    for (const key of FrameNotReadonlyProp) {
        if (frameNodeData[key] === undefined) {
            continue
        } else {
            ;(node as any)[key] = frameNodeData[key]
        }
    }
    for (const child of frameNodeData.children ?? []) {
        const subTree = createNodeByJSONTree(child)
        if (subTree) {
            node.appendChild(subTree)
        }
    }
    return node
}

function createVectorByJSONTree(vectorNodeData: VectorNodePluginData, options = DefaultOptions): MotiffApi.VectorNode {
    const node = motiff.createVector()
    // Cause create vector will auto add strokes, clean it
    node.strokes = [{} as unknown as any]
    console.info('init', node.strokes)
    resizeNodeByTreeBoundingBox(node, vectorNodeData)
    if (options.root) {
        options.root.appendChild(node)
    }
    for (const key of VectorNotReadonlyProp) {
        if (isNil(vectorNodeData[key])) {
            continue
        } else {
            ;(node as any)[key] = vectorNodeData[key]
        }
    }
    return node
}

export function createNodeByJSONTree(
    data: SceneNodePluginData,
    options: Options = DefaultOptions
): MotiffApi.SceneNode | null {
    switch (data.type) {
        case 'FRAME':
            return createFrameByJSONTree(data, options)
        case 'VECTOR':
            return createVectorByJSONTree(data, options)
        default:
            return null
    }
}
