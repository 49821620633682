import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Phone: '手机',
    Tablet: '平板',
    Desktop: '电脑',
    Watch: '智能手表',
    SocialMedia: '社交媒体',
    Others: '其他',
    WeChatArticle: '微信文章插图',
    WeChatOfficial: '微信公众号封面',
    WeChatArticle_synonyms1: '微信文章预览',
    FileCover: '文件封面',
    Paper: '纸张',
} as const

export const enTranslation = {
    Phone: 'Phone',
    Tablet: 'Tablet',
    Desktop: 'Desktop',
    Watch: 'Watch',
    SocialMedia: 'Social media',
    Others: 'Others',
    WeChatArticle: 'WeChat article illustration',
    WeChatOfficial: 'WeChat Official Account cover',
    WeChatArticle_synonyms1: 'WeChat article preview',
    FileCover: 'File cover',
    Paper: 'Paper',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
