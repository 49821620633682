import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    EditStyle: '编辑样式',
    TextStyles: '文本样式',
    CreateNewText: '创建文本样式',
} as const

export const enTranslation = {
    EditStyle: 'Edit style',
    TextStyles: 'Text styles',
    CreateNewText: 'Create text style',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
