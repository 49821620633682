/* eslint-disable no-restricted-imports */
import { MouseEvent } from 'react'
import { CommentDataTestId } from '../../../../window'
import {
    useActiveCommentId,
    useCanvasCommentClustersMap,
    useCanvasCommentsMap,
    useCanvasPendingCommentsMap,
    useCommentUsersMap,
} from '../comment-service/comment-service-hook'
import { Bubble } from './bubble'
import { ClusterBubble } from './cluster-bubble'

interface BubblesProps {
    handleRightClickBubble: (e: MouseEvent) => void
}

export function Bubbles(props: BubblesProps) {
    const { handleRightClickBubble } = props
    const usersMap = useCommentUsersMap()
    const activeCommentId = useActiveCommentId()
    const canvasCommentsMap = useCanvasCommentsMap()
    const pendingComments = useCanvasPendingCommentsMap()
    const canvasCommentClustersMap = useCanvasCommentClustersMap()

    // 排序为了展示的层级
    const sortedCanvasComments = [...canvasCommentsMap.values()].sort(
        (a, b) => a.commentMetaData.createdTime - b.commentMetaData.createdTime
    )
    const sortedPendingComments = [...pendingComments.values()].sort(
        (a, b) => a.commentMetaData.createdTime - b.commentMetaData.createdTime
    )

    const commentClusters = [...canvasCommentClustersMap.values()]

    return (
        <>
            {sortedCanvasComments.map((comment, index) => (
                <Bubble
                    key={comment.commentMetaData.id}
                    handleRightClickBubble={handleRightClickBubble}
                    comment={comment}
                    usersMap={usersMap}
                    isActive={comment.commentMetaData.id === activeCommentId}
                    dataTestIds={{
                        range: CommentDataTestId.CommentRangeHover,
                        content: CommentDataTestId.CommentBubble(index),
                    }}
                />
            ))}
            {sortedPendingComments.map((comment, index) => (
                <Bubble
                    key={comment.commentMetaData.id}
                    handleRightClickBubble={handleRightClickBubble}
                    comment={comment}
                    usersMap={usersMap}
                    pending
                    dataTestIds={{
                        content: CommentDataTestId.CommentPendingBubble(index),
                    }}
                />
            ))}
            {commentClusters.map((cluster, index) => (
                <ClusterBubble
                    key={cluster.clusterId}
                    cluster={cluster}
                    dataTestIds={{
                        bubble: CommentDataTestId.CommentClusterBubble(index),
                        cover: CommentDataTestId.CommentClusterBubbleCover(index),
                        content: CommentDataTestId.CommentClusterBubbleContent(index),
                        backgroud: CommentDataTestId.CommentClusterBubbleBackgroud(index),
                    }}
                />
            ))}
        </>
    )
}
