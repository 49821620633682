import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    Import: '导入文件',
    MigrateLibrary: '组件迁移',
    SelectFiles: '选择识别文件',
    ShareFile: '分享文件',
} as const

export const enTranslation = {
    Import: 'Import',
    MigrateLibrary: 'Import figma library',
    SelectFiles: 'Select files',
    ShareFile: 'Share file',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
