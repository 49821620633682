import { translation } from './comment-create-button.translation'
/* eslint-disable no-restricted-imports */
import { WKButton } from '../../../../../../../../ui-lib/src'
import React from 'react'
import classes from './comment-create-button.module.less'

export interface CommentCreateButtonProps {
    onClick?: (e: React.MouseEvent<HTMLElement>) => void
}
export function CommentCreateButton(props: CommentCreateButtonProps) {
    return (
        <div className={classes.buttonArea}>
            <WKButton type="primary" onClick={props.onClick} data-testid="style-create-btn">
                {translation('CreateStyle')}
            </WKButton>
        </div>
    )
}
