import { Wukong } from '@wukong/bridge-proto'

export function generateLibraryDisplayItemKey(displayItem: Wukong.DocumentProto.IVLibraryAssetPanelItem) {
    return [
        displayItem.type,
        displayItem.header?.expandKey || displayItem.folder?.expandKey || displayItem.component?.renderKey,
    ].join('-')
}

export function generateLibraryComponentItemKey(
    componentItem: Pick<
        Wukong.DocumentProto.IVLibraryAssetPanelComponentItem,
        'remoteDocId' | 'remoteNodeId' | 'isLocal' | 'localNodeId'
    >
) {
    return componentItem.isLocal
        ? `local-${componentItem.localNodeId}`
        : `${componentItem.remoteDocId}-${componentItem.remoteNodeId}`
}
