import {
    FeatureSwitchConfig,
    SwitchEnvironmentScopeType,
    SwitchStrategyType,
    UserGroupType,
} from '../feature-switch-config'

const Config: FeatureSwitchConfig = {
    name: 'import-html',
    owner: '',
    description: '',
    strategies: [
        {
            config: { type: SwitchStrategyType.ALL_USER },
            env: SwitchEnvironmentScopeType.TESTING,
        },
        {
            config: { type: SwitchStrategyType.SPECIFIC_USER_LIST, group: UserGroupType.RD },
            env: SwitchEnvironmentScopeType.PRODUCTION,
        },
        {
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                userList: ['eff45a3f83f3209c6223ed22924154d913aa48ce'],
            },
            env: SwitchEnvironmentScopeType.ALL,
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
