import { LineIconWrapper } from '../line-icon-wrapper/line-icon-wrapper'
import { CurrentItem } from '../type'
import classes from './current-item-component.module.less'

export interface CurrentItemComponentProps {
    item: CurrentItem
    isFirstItem: boolean
    isLastItem: boolean
}
export function CurrentItemComponent(props: CurrentItemComponentProps) {
    const { item, isFirstItem, isLastItem } = props

    return (
        <LineIconWrapper
            item={item}
            hideUpperHalf={isFirstItem}
            hideLowerHalf={isLastItem}
            dataTestIds={{
                container: 'history-list-item',
                moreButton: 'history-list-item-more-button',
            }}
        >
            <div className={classes.currentItem}>
                <div className={classes.name}>{item.name}</div>
            </div>
        </LineIconWrapper>
    )
}
