import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Added: '新增',
    Modified: '修改',
    Removed: '删除',
    Ordered: '排序',
    Unchanged: '未修改',
    MoveToThis: '移动到此文件',
} as const

export const enTranslation = {
    Added: 'Added',
    Modified: 'Modified',
    Removed: 'Removed',
    Ordered: 'Reordered',
    Unchanged: 'Unchanged',
    MoveToThis: 'Moved to this file',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
