import { CurrentPageSetSelectionCommandWasmCall } from '@wukong/bridge-proto'
import { useCallback } from 'react'
import { WKButton } from '../../../../../ui-lib/src'
import { useCommand } from '../../context/document-context'
import { translation } from './back-prototype-setting-button.translation'

export const BackPrototypeSettingButton = () => {
    const command = useCommand()
    const cleartSelection = useCallback(() => {
        command.DEPRECATED_invokeBridge(CurrentPageSetSelectionCommandWasmCall, { selection: [] })
    }, [command])

    return (
        <div className="p-4 b-b border-color-$wk-gray-3">
            <WKButton className="block w-full" onClick={cleartSelection} type="secondary">
                {translation('ShowPrototypingSettings')}
            </WKButton>
        </div>
    )
}
