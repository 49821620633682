import { translation } from './ai-recognize-modal.translation'

import { RequestError } from '@tutor/network-core'
import { ApplyAIComponentRecognizeResultCommand, GetAllLocalStyleAndComponentsCountCommand } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { cloneDeep } from 'lodash-es'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAsync } from 'react-use'
import {
    Checkbox,
    DropdownV2,
    MonoIconPanelAdjust16,
    MulticolorIconCommonFolder24,
    Scrollbar,
    ScrollView,
    Tooltip,
    WKAlert,
    WKButton,
    WKCollapse,
    WKCollapseItem,
    WKDialog,
    WKLogo,
    WKToast,
} from '../../../../../../ui-lib/src'
import {
    domLocation,
    generateRouterPath,
    isEnglishLanguage,
    openLinkOnWeb,
    RouteToken,
    timeDesc,
    useIsEllipsisActive,
} from '../../../../../../util/src'
import { IN_JEST_TEST } from '../../../../environment'
import { WkCLog } from '../../../../kernel/clog/wukong/instance'
import { WKFrog } from '../../../../kernel/frog/frog-service'
import { OnboardingScene, OnboardingStatus } from '../../../../kernel/interface/onboarding'
import {
    DocWithAuthorityVO,
    PayPlanType,
    FolderWithAuthorityVO,
    TeamWithAuthorityVO,
    TeamWithFolderAndAuthorityVO,
} from '../../../../kernel/interface/type'
import { QueryDocFromFolderRequest } from '../../../../kernel/request/document'
import { GetOrganizations } from '../../../../kernel/request/organizations'
import { GetTeamsFoldersEnterpriseRequest, GetTeamsFoldersRequest } from '../../../../kernel/request/team'
import { featureSwitchManager } from '../../../../kernel/switch/core'
import {
    AiLibraryMaintainPreferences,
    AIRecognizeStatus,
    createAIRecognizeTaskForLibraryMaintain,
    ExtractionFailedStatus,
    GetAiLibraryMaintainPreferencesRequest,
    GetAiLibraryMaintainTemplate,
    GetAiMaintainReusltDownloadUrl,
    GetAIRecognizeResult,
    SetAiComponentDataValidate,
    UpdateAiLibraryMaintainPreferencesRequest,
} from '../../../../main/ai-recognize/requests'
import { useAppContext, useBridge, useDocId, usePlanAndUserStateService } from '../../../../main/app-context'
import { onboardingService } from '../../../../main/service/onboarding-service'
import { DocThumbnail } from '../../../../space/app/document/doc-thumbnail'
import { getSortedDocs } from '../../../../space/app/document/sort-menu/menu-utils'
import failedIcon from '../../../../space/assets/failed.png'
import succeedIcon from '../../../../space/assets/succeed.png'
import { DocListPageSortKey, FlatDoc, SortOrder } from '../../../../space/util/types'
import { useDocInfoContext } from '../../../context/top-area-context'
import classes from './ai-recognize-modal.module.less'
import { WorkspaceWithTeamAndFolderVO } from '../../../../kernel/interface/workspace'

enum CurrentModalContent {
    ChooseFile,
    Recognizing,
    RecognizeSucceed,
    RecognizeFailed,
}

const FolderItem = ({
    selectedFolder,
    folder,
    itemIndent,
    selectedFolderDocsMap,
    onSelectedFolderChanged,
}: {
    selectedFolder: string | undefined
    folder: FolderWithAuthorityVO
    selectedFolderDocsMap: Map<string, Set<string>>
    onSelectedFolderChanged: (id: string) => void
    itemIndent: number
}) => {
    const countSize = selectedFolderDocsMap.get(folder.id)?.size ?? 0

    const headerRef = useRef<HTMLDivElement>(null)
    const isEllipsis = useIsEllipsisActive(headerRef.current)

    return (
        <Tooltip title={folder.name} inactivation={!isEllipsis}>
            <div
                className={classNames([
                    'flex items-center pl-3 h-8',
                    classes.FolderItem,
                    selectedFolder === folder.id ? classes.Selected : undefined,
                ])}
                key={folder.id}
                onClick={() => onSelectedFolderChanged(folder.id)}
            >
                <MulticolorIconCommonFolder24 style={{ marginLeft: itemIndent }} />
                <div className={classNames(['ml-1 truncate', classes.FolderName])} ref={headerRef}>
                    {folder.name}
                </div>
                {countSize > 0 ? (
                    <div className={classNames(['text-gray', classes.Count])}>{countSize > 99 ? '99+' : countSize}</div>
                ) : undefined}
            </div>
        </Tooltip>
    )
}

const DocItem = ({
    selectedAIDocs,
    doc,
    onHover,
    onLeave,
    onClick,
    hoverredDocId,
}: {
    selectedAIDocs: Set<string>
    doc: FlatDoc
    onHover: (id: string) => void
    onLeave: () => void
    onClick: (doc: FlatDoc) => void
    hoverredDocId: string | undefined
}) => {
    const headerRef = useRef<HTMLDivElement>(null)
    const isEllipsis = useIsEllipsisActive(headerRef.current)

    return (
        <div
            className={classNames([classes.Item, selectedAIDocs.has(doc.id) ? classes.Selected : undefined])}
            key={doc.id}
            id={`ai-item-${doc.id}`}
            onClick={() => onClick(doc)}
            onMouseOver={() => {
                onHover(doc.id)
            }}
            onMouseLeave={() => {
                onLeave()
            }}
        >
            <DocThumbnail
                url={doc.thumbnailUrl}
                thumbnailNodeId={doc.thumbnailNodeId}
                canvasBackgroundColor={doc.canvasBackgroundColor}
            ></DocThumbnail>
            <Tooltip title={doc.name} inactivation={!isEllipsis}>
                <div className={classNames(['wk-font-medium truncate mt-2', classes.Title])} ref={headerRef}>
                    {doc.name}
                </div>
            </Tooltip>
            <div className="flex items-center">
                <div className="text-gray">
                    {`${translation('EditedTo')} ${timeDesc(Math.max(doc.lastEditedTime, doc.createdTime))}`}
                </div>
                {hoverredDocId === doc.id || selectedAIDocs.has(doc.id) ? (
                    <Checkbox className="ml-auto" checked={selectedAIDocs.has(doc.id)} />
                ) : undefined}
            </div>
        </div>
    )
}

export const RecognizeSuccessContent = ({
    layerCount,
    folder,
    onClickOK,
}: {
    layerCount: number
    folder: FolderWithAuthorityVO
    onClickOK: (e: React.MouseEvent<HTMLElement>) => void
}) => {
    return (
        <div className={classNames([classes.InRecognzingContainer, classes.SuccessContainer, 'py-12'])}>
            <img className={classes.SucceedImg} src={succeedIcon}></img>
            <div className={classNames([classes.RecognizingText, 'text-black wk-font-medium wk-text-16'])}>
                AI {translation('RecognitionComplete')}
            </div>
            <div className={classNames([classes.LayerCountContainer, 'wk-font-regular', 'wk-text-12', 'mt-1'])}>
                <div>{translation('Detected')}&nbsp;</div>
                <div className={classNames(['wk-font-semibold', 'wk-text-12'])}>
                    {isEnglishLanguage() ? `${(layerCount! / 1000).toFixed(1)}k` : (layerCount! / 10000).toFixed(1)}
                </div>
                <div>&nbsp;{translation('10kLayers')}</div>
            </div>
            <WKButton type={'primary'} className={classes.BottomButton} onClick={onClickOK}>
                {translation('View')}
            </WKButton>
        </div>
    )
}

interface ErrorDescription {
    title: string
    subTitle: string
}

export const RecognizeFailedContent = ({
    onClickOK,
    failedStatus,
}: {
    onClickOK: (e: React.MouseEvent<HTMLElement>) => void
    failedStatus: ExtractionFailedStatus | undefined
}) => {
    const errorDescription: ErrorDescription = {
        title: translation('RecognitionFailureDue'),
        subTitle: translation('PleaseTryAgain'),
    }
    if (failedStatus === ExtractionFailedStatus.DocTooLarge) {
        errorDescription.title = translation('TheFileIs')
        errorDescription.subTitle = translation('PleaseSplitIt')
    } else if (failedStatus === ExtractionFailedStatus.ServerBusy) {
        errorDescription.title = translation('CurrentServerBusy')
        errorDescription.subTitle = translation('PleaseTryAgain')
    } else if (failedStatus === ExtractionFailedStatus.TooManyDocsInRequest) {
        errorDescription.title = translation('ExcessiveNumberOf')
        errorDescription.subTitle = translation('PleaseReduceTo')
    } else if (failedStatus === ExtractionFailedStatus.TooManyRunningQuestOneUser) {
        errorDescription.title = translation('YouHaveAn')
        errorDescription.subTitle = translation('PleaseTryAgain_synonyms1')
    } else if (failedStatus === ExtractionFailedStatus.HasNoScreen) {
        errorDescription.title = translation('NoMobileInterface')
        errorDescription.subTitle = translation('PleaseSelectThe')
    } else if (failedStatus === ExtractionFailedStatus.FreeUserForbidden) {
        errorDescription.title = translation('FreePageNotEnough')
        errorDescription.subTitle = translation('PleaseDeletePage')
    }
    return (
        <div className={classNames([classes.InRecognzingContainer, classes.FailedContainer])}>
            <img className={classes.FailedImg} src={failedIcon}></img>
            <div className={classNames([classes.FailedRecognizingText, 'text-black wk-font-medium wk-text-16'])}>
                <div>{errorDescription.title}</div>
                <div>{errorDescription.subTitle}</div>
            </div>
            <WKButton type={'primary'} className={classes.BottomButton} onClick={onClickOK}>
                {translation('GotIt')}
            </WKButton>
        </div>
    )
}

export const RecognizingContent = ({
    onClickOK,
}: {
    onClickOK: (e: React.MouseEvent<HTMLElement>) => void
    status: AIRecognizeStatus
}) => {
    return (
        <div className={classNames([classes.InRecognzingContainer, classes.LoadingContainer])}>
            <WKLogo.WithText height={24} type={'normal'} />
            <div className={classes.PrograssBar}>
                <div className={classes.Inside}>
                    <div className={classes.Flight}></div>
                </div>
            </div>
            <div className={classNames([classes.RecognizingText, 'text-black wk-font-medium wk-text-16'])}>
                AI {translation('Recognizing')}...
            </div>
            <WKButton type={'secondary'} className={classes.BottomButton} onClick={onClickOK}>
                {translation('Cancel')}
            </WKButton>
        </div>
    )
}

export function AiRecognizeModalDataImpl(props: {
    onCancel: () => void
    onClickCancelRecognize: () => void
    visible: boolean
    teamOrderedList: TeamWithAuthorityVO[]
    teamId2FavoritesFolderList: Record<string, FolderWithAuthorityVO[]>
    isInDraft: boolean
    isEnterprise: boolean
    workspaces: readonly WorkspaceWithTeamAndFolderVO[]
    currDraftId: string
    currentDocFolder?: FolderWithAuthorityVO
}) {
    const {
        teamOrderedList,
        workspaces,
        teamId2FavoritesFolderList,
        currentDocFolder,
        isInDraft,
        currDraftId,
        isEnterprise,
    } = props
    // 控制当前显示的内容
    const [currentModalContent, setCurrentModalContent] = useState(CurrentModalContent.ChooseFile)

    const [currentDocList, setCurrentDocList] = useState<readonly DocWithAuthorityVO[]>()
    const [selectedFolder, setSelectedFolder] = useState<string>(currentDocFolder?.id || '')
    const [selectedAIDocs, setSelectedAIDocs] = useState<Set<string>>(new Set<string>())
    const [resultPageId, setResultPageId] = useState<string>()
    const [selectedFolderDocsMap, setSelectedFolderDocsMap] = useState<Map<string, Set<string>>>(
        new Map<string, Set<string>>()
    )
    const [hoverredDocId, setHoverredDocId] = useState<string>()
    const [layerCount, setLayoutCount] = useState<number>()
    const [aiRecognizeStatus, setAiRecognizeStatus] = useState<AIRecognizeStatus>()
    const [failedStatus, setFailedStatus] = useState<ExtractionFailedStatus>()
    const [showAlertBanner, setShowAlertBannder] = useState<boolean>(true)
    const bridge = useBridge()
    const allLocalStyleAndComponentsCount = useMemo(() => {
        return bridge.call(GetAllLocalStyleAndComponentsCountCommand)
    }, [bridge])
    const hasNoLocalStyleAndComponents = useMemo(() => {
        return allLocalStyleAndComponentsCount.componentCount === 0 && allLocalStyleAndComponentsCount.styleCount === 0
    }, [allLocalStyleAndComponentsCount])

    const planAndUserStateService = usePlanAndUserStateService()
    const planAndUserState = planAndUserStateService.useZustandStore.use.planAndUserState() // 拿到用户 plan
    const isFreeUser = !planAndUserState || planAndUserState.planType == PayPlanType.starter
    const isOverFreeUserUsage = useMemo(() => {
        return !isInDraft && isFreeUser && motiff.currentPage.parent!.children.length >= 3
    }, [isFreeUser, isInDraft])
    const cannotStartTask = useMemo(() => {
        return selectedAIDocs.size === 0 || hasNoLocalStyleAndComponents || isOverFreeUserUsage
    }, [hasNoLocalStyleAndComponents, isOverFreeUserUsage, selectedAIDocs])

    const aiService = useAppContext().aiService

    const draftSelectedFilesCount = useMemo(() => {
        if (!currDraftId) {
            return 0
        }
        return selectedFolderDocsMap.get(currDraftId)?.size ?? 0
    }, [currDraftId, selectedFolderDocsMap])

    const [aiLibraryMaintainPreferences, setAiLibraryMaintainPreferences] = useState<AiLibraryMaintainPreferences>({
        hideLowUsage: true,
        skipCasesWithDifferentLineHeightOnly: true,
    })
    const [preferenceDropDownVisible, setPreferenceDropDownVisible] = useState(false)

    useEffect(() => {
        if (currentDocFolder?.id) {
            setSelectedFolder(currentDocFolder.id)
        } else if (isInDraft && currDraftId) {
            setSelectedFolder(currDraftId)
        }
    }, [currDraftId, currentDocFolder, isInDraft])

    const titleText = useMemo(() => {
        if (currentModalContent === CurrentModalContent.ChooseFile) {
            return translation('AiLibraryMaintain')
        }
        return ''
    }, [currentModalContent])

    const modalWidth = useMemo(() => {
        if (currentModalContent === CurrentModalContent.ChooseFile) {
            return 1080
        } else {
            return 400
        }
    }, [currentModalContent])

    const isSelectAll = useMemo(() => {
        let ret = true
        if (currentDocList) {
            for (const doc of currentDocList) {
                if (!selectedAIDocs.has(doc.id)) {
                    ret = false
                    break
                }
            }
        } else {
            return false
        }

        return ret
    }, [currentDocList, selectedAIDocs])

    const isIndeterminate = useMemo(() => {
        if (isSelectAll) {
            return false
        }
        let ret = false
        if (currentDocList) {
            for (const doc of currentDocList) {
                if (selectedAIDocs.has(doc.id)) {
                    ret = true
                    break
                }
            }
        }

        return ret
    }, [currentDocList, isSelectAll, selectedAIDocs])

    const sortedDocList = useMemo((): readonly FlatDoc[] => {
        if (currentDocList) {
            return getSortedDocs(currentDocList, {
                sortKey: DocListPageSortKey.Edited,
                sortOrder: SortOrder.Ascending,
            })
        } else {
            return []
        }
    }, [currentDocList])

    const maskClosable = useMemo(() => {
        if (
            currentModalContent === CurrentModalContent.Recognizing ||
            currentModalContent === CurrentModalContent.ChooseFile
        ) {
            return false
        }
        return true
    }, [currentModalContent])

    const hideFooterLine = useMemo(() => {
        if (currentModalContent === CurrentModalContent.ChooseFile) {
            return false
        }
        return true
    }, [currentModalContent])

    const showCloseIcon = useMemo(() => {
        return currentModalContent !== CurrentModalContent.Recognizing
    }, [currentModalContent])

    const cancelRecognizeTask = useRef<() => void>()

    useEffect(() => {
        return () => {
            cancelRecognizeTask.current?.()
        }
    }, [])

    const currentDocId = useDocId()

    useEffect(() => {
        new GetAiLibraryMaintainPreferencesRequest(currentDocId).start().then((result) => {
            if (result) {
                setAiLibraryMaintainPreferences(result)
            }
        })
    }, [currentDocId])

    const onClickOk = useCallback(async () => {
        if (currentModalContent === CurrentModalContent.ChooseFile && selectedFolder) {
            WKFrog.addFrogRecord({
                url: '/click/AIDesignSystemMaintainer/begin',
                eventId: 26800,
                eventName: 'begin',
                eventAction: 'click',
            })
            setCurrentModalContent(CurrentModalContent.Recognizing)
            try {
                const task = await createAIRecognizeTaskForLibraryMaintain(
                    Array.from(selectedAIDocs),
                    selectedFolder,
                    currentDocId,
                    aiLibraryMaintainPreferences
                )
                if (task.error !== undefined) {
                    if (task.error.status === ExtractionFailedStatus.DocSchemaVersionNotSame) {
                        WKToast.error(translation('DocSchemaVersionNotSame'))
                        WkCLog.log('AiLibraryMaintain', {
                            traceEventName: 'INFO_SCHEMA_VERSION_NOT_MATCH',
                            traceEventKey: 1386768,
                            scenario: 'AiLibraryMaintain',
                            docIds: task.error.docIds.join(','),
                            folderId: task.error.folderId,
                        })
                    }
                    setFailedStatus(task.error.status)
                    setCurrentModalContent(CurrentModalContent.RecognizeFailed)
                    return
                }
                cancelRecognizeTask.current = task.cancel
                task.getTaskStatus((status) => {
                    if (status.status === 'ALL_FINISHED') {
                        // 获取结果，调用 wasmCommand 布局
                        new GetAiMaintainReusltDownloadUrl(task.taskId!)
                            .start()
                            .then((downloadUrl) => {
                                return Promise.all([
                                    new GetAIRecognizeResult(downloadUrl.aiExtractResultUrl).start(),
                                    new GetAiLibraryMaintainTemplate(downloadUrl.maintainTemplateDocUrl).start(),
                                ])
                            })
                            .then((aiResult) => {
                                cancelRecognizeTask.current = undefined

                                if (!isInDraft && isFreeUser && motiff.currentPage.parent!.children.length >= 3) {
                                    setCurrentModalContent(CurrentModalContent.RecognizeFailed)
                                    setFailedStatus(ExtractionFailedStatus.FreeUserForbidden)
                                } else {
                                    const layoutResult = bridge.call(ApplyAIComponentRecognizeResultCommand, {
                                        encodedData: JSON.stringify(aiResult[0]),
                                        templateFileData: aiResult[1],
                                        isBuildIndex: featureSwitchManager.isEnabled('ai-recognize-debug'),
                                        hideLowUsage: aiLibraryMaintainPreferences.hideLowUsage,
                                        layoutInOnePage: true,
                                    })

                                    setCurrentModalContent(CurrentModalContent.RecognizeSucceed)
                                    setResultPageId(layoutResult.resultPageId)
                                    setLayoutCount(status.layerCount)
                                    aiService.decreaseAiDesignSystemCredits()
                                }
                            })
                        new SetAiComponentDataValidate(currentDocId).start()
                    } else if (status.status === 'TERMINATE') {
                        cancelRecognizeTask.current = undefined
                        setCurrentModalContent(CurrentModalContent.RecognizeFailed)
                    } else if (status.status === 'AI_NOT_FOUND_SCREEN') {
                        cancelRecognizeTask.current = undefined
                        setFailedStatus(ExtractionFailedStatus.HasNoScreen)
                        setCurrentModalContent(CurrentModalContent.RecognizeFailed)
                    } else {
                        setAiRecognizeStatus(status.status)
                    }
                })
            } catch (err) {
                cancelRecognizeTask.current = undefined
                setCurrentModalContent(CurrentModalContent.RecognizeFailed)
            }
        } else if (currentModalContent === CurrentModalContent.RecognizeSucceed) {
            WKFrog.addFrogRecord({
                url: '/event/AIDesignSystemMaintainer/getResult',
                eventId: 26801,
                eventName: 'getResult',
                eventAction: 'event',
            })
            // 跳转到布局成功的页面
            openLinkOnWeb(
                (window.location.href = `${domLocation().origin}/${generateRouterPath(
                    RouteToken.File
                )}/${currentDocId}?nodeId=${resultPageId!}`),
                IN_JEST_TEST
            )
        } else if (currentModalContent === CurrentModalContent.Recognizing) {
            props.onClickCancelRecognize()
        } else {
            props.onCancel()
        }
    }, [
        currentModalContent,
        selectedFolder,
        selectedAIDocs,
        currentDocId,
        aiLibraryMaintainPreferences,
        isInDraft,
        isFreeUser,
        bridge,
        aiService,
        resultPageId,
        props,
    ])

    const onClickCancel = useCallback(() => {
        props.onCancel()
    }, [props])

    const deleteDocInFolderDocsMap = (doc: FlatDoc) => {
        selectedFolderDocsMap.get(doc.folderId)?.delete(doc.id)
    }

    const addDocInFolderDocsMap = (doc: FlatDoc) => {
        if (!selectedFolderDocsMap.get(doc.folderId)) {
            selectedFolderDocsMap.set(doc.folderId, new Set<string>())
        }
        selectedFolderDocsMap.get(doc.folderId)!.add(doc.id)
    }

    const toggleSelectAll = () => {
        if (!isSelectAll) {
            currentDocList?.forEach((doc) => {
                selectedAIDocs.add(doc.id)
                addDocInFolderDocsMap(doc)
            })
        } else {
            currentDocList?.forEach((doc) => {
                selectedAIDocs.delete(doc.id)
                deleteDocInFolderDocsMap(doc)
            })
        }

        setSelectedFolderDocsMap(cloneDeep(selectedFolderDocsMap))
        setSelectedAIDocs(cloneDeep(selectedAIDocs))
    }

    const toggleSelectDoc = (doc: FlatDoc) => {
        if (selectedAIDocs.has(doc.id)) {
            selectedAIDocs.delete(doc.id)
            setSelectedAIDocs(cloneDeep(selectedAIDocs))

            deleteDocInFolderDocsMap(doc)
            setSelectedFolderDocsMap(cloneDeep(selectedFolderDocsMap))
        } else {
            selectedAIDocs.add(doc.id)
            setSelectedAIDocs(cloneDeep(selectedAIDocs))

            addDocInFolderDocsMap(doc)
            setSelectedFolderDocsMap(cloneDeep(selectedFolderDocsMap))
        }
    }

    const footer = useMemo(() => {
        if (currentModalContent === CurrentModalContent.ChooseFile) {
            return (
                <div className={classes.AiChooseDocModalFooter}>
                    <div className="flex items-center">
                        <div className="text-black">{translation('PTQXkb')}&nbsp;</div>
                        <div className="wk-font-medium text-black">{selectedAIDocs.size}</div>
                        <div className="text-black">
                            &nbsp;{translation('FilesSelected', { file: selectedAIDocs.size !== 1 ? 'files' : 'file' })}
                        </div>
                    </div>
                    <div className={classes.ButtonArea}>
                        <WKButton type="secondary" onClick={onClickCancel}>
                            {translation('Cancel_synonyms1')}
                        </WKButton>
                        <WKButton type="primary" disabled={cannotStartTask} onClick={onClickOk}>
                            {translation('Start')}
                        </WKButton>
                    </div>
                </div>
            )
        }

        return null
    }, [cannotStartTask, currentModalContent, onClickCancel, onClickOk, selectedAIDocs.size])

    const togglePreferencesHideLowUsage = () => {
        const newPreferences = {
            ...aiLibraryMaintainPreferences,
            hideLowUsage: !aiLibraryMaintainPreferences.hideLowUsage,
        }

        setAiLibraryMaintainPreferences({
            ...aiLibraryMaintainPreferences,
            hideLowUsage: !aiLibraryMaintainPreferences.hideLowUsage,
        })

        new UpdateAiLibraryMaintainPreferencesRequest(currentDocId, newPreferences).start()
    }

    const togglePreferencesSkipCasesWithDifferentLineHeightOnly = () => {
        const newPreferences = {
            ...aiLibraryMaintainPreferences,
            skipCasesWithDifferentLineHeightOnly: !aiLibraryMaintainPreferences.skipCasesWithDifferentLineHeightOnly,
        }
        setAiLibraryMaintainPreferences(newPreferences)

        new UpdateAiLibraryMaintainPreferencesRequest(currentDocId, newPreferences).start()
    }

    const title = useMemo(() => {
        if (currentModalContent === CurrentModalContent.ChooseFile) {
            return (
                <div className={classes.TitleContainer}>
                    <div>{titleText}</div>
                    <DropdownV2.IconSingleLevel
                        style={{ width: 'unset' }}
                        placement="bottom right"
                        className={classes.ButtonContainer}
                        onOpen={() => setPreferenceDropDownVisible(true)}
                        onClose={() => setPreferenceDropDownVisible(false)}
                        maxWidth={1000}
                        label={
                            <span
                                className={classNames(
                                    classes.Button,
                                    preferenceDropDownVisible ? undefined : classes.ButtonNormal
                                )}
                            >
                                <MonoIconPanelAdjust16 /> {translation('Preferences')}
                            </span>
                        }
                    >
                        <DropdownV2.IconSingleLevel.Option
                            key="1"
                            value={1}
                            isSelect={aiLibraryMaintainPreferences.hideLowUsage}
                            onClick={togglePreferencesHideLowUsage}
                        >
                            {translation('HideLowUsage')}
                        </DropdownV2.IconSingleLevel.Option>
                        <DropdownV2.IconSingleLevel.Option
                            key="2"
                            value={2}
                            isSelect={aiLibraryMaintainPreferences.skipCasesWithDifferentLineHeightOnly}
                            onClick={togglePreferencesSkipCasesWithDifferentLineHeightOnly}
                        >
                            {translation('SkipCasesWithDifferentLineHeightOnly')}
                        </DropdownV2.IconSingleLevel.Option>
                    </DropdownV2.IconSingleLevel>
                </div>
            )
        } else {
            return undefined
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentModalContent, titleText, preferenceDropDownVisible])

    useEffect(() => {
        if (selectedFolder) {
            setCurrentDocList(undefined)
            new QueryDocFromFolderRequest(selectedFolder).start().then((docs) => {
                setCurrentDocList(docs.filter((d) => d.copyable))
            })
        }
    }, [selectedFolder])

    const closeOnbard = () => {
        setShouldShowOnBoard(false)
        onboardingService.end(OnboardingScene.AiLibraryMaintain, OnboardingStatus.Finished)
    }

    const [shouldShowOnBoard, setShouldShowOnBoard] = useState<boolean>(true)

    return (
        <WKDialog
            visible={props.visible}
            closable={showCloseIcon}
            bodyStyle={{
                padding: 0,
            }}
            title={title}
            hideFooterLine={hideFooterLine}
            okText={translation('SelectFiles')}
            onOk={onClickOk}
            onCancel={onClickCancel}
            width={modalWidth}
            showTitle={!!title}
            maskClosable={maskClosable}
            footer={footer}
            centered={true}
        >
            {currentModalContent === CurrentModalContent.RecognizeSucceed && (
                <RecognizeSuccessContent
                    folder={currentDocFolder!}
                    layerCount={layerCount!}
                    onClickOK={onClickOk}
                ></RecognizeSuccessContent>
            )}
            {currentModalContent === CurrentModalContent.RecognizeFailed && (
                <RecognizeFailedContent onClickOK={onClickOk} failedStatus={failedStatus}></RecognizeFailedContent>
            )}
            {currentModalContent === CurrentModalContent.Recognizing && (
                <RecognizingContent onClickOK={onClickOk} status={aiRecognizeStatus ?? 'INIT'}></RecognizingContent>
            )}
            {currentModalContent === CurrentModalContent.ChooseFile ? (
                <div className={classes.AiChooseDocContainer}>
                    {showAlertBanner && (
                        <div className={classes.AlertContainer}>
                            {isOverFreeUserUsage ? (
                                <WKAlert.WithoutTitle color="warning">
                                    {translation('OverFreePageHint')}
                                </WKAlert.WithoutTitle>
                            ) : hasNoLocalStyleAndComponents ? (
                                <WKAlert.WithoutTitle color="warning">
                                    {translation('HasNoComponents')}
                                </WKAlert.WithoutTitle>
                            ) : (
                                <WKAlert.WithoutTitle
                                    closeable
                                    onClose={() => {
                                        setShowAlertBannder(false)
                                    }}
                                >
                                    {translation('ComponentsCount', {
                                        componentCount: allLocalStyleAndComponentsCount.componentCount.toString(),
                                        styleCount: allLocalStyleAndComponentsCount.styleCount.toString(),
                                    })}
                                </WKAlert.WithoutTitle>
                            )}
                        </div>
                    )}

                    <div className={classes.AiChooseDocTeamFolderContainer}>
                        <div className={classes.FolderListContainer}>
                            {selectedFolder && (
                                <Scrollbar>
                                    <div id={classes.FolderList}>
                                        <div
                                            className={classNames([
                                                classes.DraftContainer,
                                                selectedFolder === currDraftId ? classes.Selected : undefined,
                                                classes.FolderItem,
                                            ])}
                                            onClick={() => setSelectedFolder(currDraftId)}
                                        >
                                            <div className={classNames(['wk-font-semibold text-black'])}>
                                                {translation('Draft')}
                                            </div>
                                            {draftSelectedFilesCount > 0 ? (
                                                <div className={classNames(['text-gray', classes.Count])}>
                                                    {draftSelectedFilesCount > 99 ? '99+' : draftSelectedFilesCount}
                                                </div>
                                            ) : undefined}
                                        </div>
                                        {isEnterprise ? (
                                            <>
                                                <WKCollapse
                                                    selectionMode="multiple"
                                                    style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
                                                    key={currentDocFolder?.workspaceId}
                                                    defaultSelection={
                                                        currentDocFolder?.workspaceId
                                                            ? [currentDocFolder?.workspaceId]
                                                            : []
                                                    }
                                                >
                                                    {workspaces.map(
                                                        ({
                                                            workspaceWithAuthorityVO: { workspaceId, name },
                                                            teamsWithFolderAndAuthorityVO,
                                                        }) => {
                                                            return (
                                                                <WKCollapseItem
                                                                    key={workspaceId}
                                                                    itemRadius="default"
                                                                    value={workspaceId}
                                                                    title={name || translation('Others')}
                                                                >
                                                                    <WKCollapse
                                                                        selectionMode="multiple"
                                                                        defaultSelection={
                                                                            currentDocFolder?.teamId
                                                                                ? [currentDocFolder?.teamId]
                                                                                : []
                                                                        }
                                                                    >
                                                                        {teamsWithFolderAndAuthorityVO.map((item) => {
                                                                            const team = item.teamWithAuthorityVO
                                                                            const folderList =
                                                                                item.folderWithAuthorityVOList
                                                                            return (
                                                                                <WKCollapseItem
                                                                                    key={team.id}
                                                                                    value={team.id}
                                                                                    itemRadius="default"
                                                                                    itemIndent={12}
                                                                                    className="pt-1"
                                                                                    title={team.name}
                                                                                >
                                                                                    {folderList.length > 0 ? (
                                                                                        folderList.map((v) => {
                                                                                            return (
                                                                                                <FolderItem
                                                                                                    key={v.id}
                                                                                                    folder={v}
                                                                                                    itemIndent={12}
                                                                                                    onSelectedFolderChanged={
                                                                                                        setSelectedFolder
                                                                                                    }
                                                                                                    selectedFolder={
                                                                                                        selectedFolder
                                                                                                    }
                                                                                                    selectedFolderDocsMap={
                                                                                                        selectedFolderDocsMap
                                                                                                    }
                                                                                                />
                                                                                            )
                                                                                        })
                                                                                    ) : (
                                                                                        <div
                                                                                            className={classNames([
                                                                                                'h-7 pl-10 pt-1 text-gray-light',
                                                                                            ])}
                                                                                        >
                                                                                            {translation('NoProjects')}
                                                                                        </div>
                                                                                    )}
                                                                                </WKCollapseItem>
                                                                            )
                                                                        })}
                                                                    </WKCollapse>
                                                                </WKCollapseItem>
                                                            )
                                                        }
                                                    )}
                                                </WKCollapse>
                                            </>
                                        ) : (
                                            <WKCollapse
                                                selectionMode="multiple"
                                                defaultSelection={
                                                    currentDocFolder?.teamId ? [currentDocFolder?.teamId] : []
                                                }
                                            >
                                                {teamOrderedList?.map((team) => {
                                                    const folderList = teamId2FavoritesFolderList[team.id]
                                                    return (
                                                        <WKCollapseItem
                                                            key={team.id}
                                                            value={team.id}
                                                            title={team.name}
                                                            tooltipProps={{
                                                                title: team.name,
                                                            }}
                                                            className="my-2"
                                                        >
                                                            {folderList.length > 0 ? (
                                                                folderList.map((v) => {
                                                                    return (
                                                                        <FolderItem
                                                                            key={v.id}
                                                                            itemIndent={12}
                                                                            folder={v}
                                                                            onSelectedFolderChanged={setSelectedFolder}
                                                                            selectedFolder={selectedFolder}
                                                                            selectedFolderDocsMap={
                                                                                selectedFolderDocsMap
                                                                            }
                                                                        />
                                                                    )
                                                                })
                                                            ) : (
                                                                <div
                                                                    className={classNames([
                                                                        'h-7 pl-10 pt-1 text-gray-light',
                                                                    ])}
                                                                >
                                                                    {translation('NoProjects')}
                                                                </div>
                                                            )}
                                                        </WKCollapseItem>
                                                    )
                                                })}
                                            </WKCollapse>
                                        )}
                                    </div>
                                </Scrollbar>
                            )}
                        </div>
                        {currentDocList && currentDocList.length > 0 ? (
                            <div className={classes.DocListContainer}>
                                <Checkbox
                                    className="mt-3 mb-1 ml-5 w-fit"
                                    checked={isSelectAll}
                                    onChange={toggleSelectAll}
                                    indeterminate={isIndeterminate}
                                    label={translation('CheckAll')}
                                    size="medium"
                                />

                                <div onClick={toggleSelectAll}></div>
                                <ScrollView block="start">
                                    <div className={classNames([classes.ListContainer])}>
                                        <div className={classNames([classes.List])}>
                                            {sortedDocList.map((doc) => {
                                                return (
                                                    <ScrollView.Item uniqueKey={doc.id} key={doc.id}>
                                                        <DocItem
                                                            doc={doc}
                                                            hoverredDocId={hoverredDocId}
                                                            onClick={(d) => toggleSelectDoc(d)}
                                                            onHover={(id) => setHoverredDocId(id)}
                                                            onLeave={() => setHoverredDocId(undefined)}
                                                            selectedAIDocs={selectedAIDocs}
                                                        />
                                                    </ScrollView.Item>
                                                )
                                            })}
                                        </div>
                                        <div className={classes.Spacer}></div>
                                    </div>
                                </ScrollView>
                            </div>
                        ) : currentDocList && currentDocList.length === 0 ? (
                            <div className={classNames(['wk-text-16 wk-font-semibold', classes.EmptyText])}>
                                {translation('NoFiles')}
                            </div>
                        ) : undefined}
                    </div>

                    {shouldShowOnBoard &&
                        onboardingService.checkOnboardingStatusLegal(OnboardingScene.AiLibraryMaintain) && (
                            <div className={classes.onBoard}>
                                <div className="bg-$wk-brand-7 rounded-1">
                                    <div className={classes.desc}>
                                        <p>{translation('Onboard1')}</p>
                                        <p>{translation('Onboard2')}</p>
                                    </div>
                                    <div className="justify-end flex px-4 pt-3 pb-4 border-0">
                                        <WKButton
                                            type="primary"
                                            white
                                            size="small"
                                            onClick={closeOnbard}
                                            dataTestId="close-ai-library-maintain-on-board"
                                        >
                                            {translation('GotIt')}
                                        </WKButton>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            ) : undefined}
        </WKDialog>
    )
}

export function useAiRecognizeModalState(isEnterprise: boolean) {
    const docContext = useDocInfoContext()
    const [isInDraft, setIsInDraft] = useState<boolean>(false)
    useEffect(() => {
        if (docContext.docData?.draft) {
            setIsInDraft(true)
        }
    }, [docContext.docData])

    const teamFolderInfo = useAsync(async () => {
        if (docContext.docData) {
            let teamFolders: TeamWithFolderAndAuthorityVO[] = []
            let workspaces: WorkspaceWithTeamAndFolderVO[] = []
            try {
                if (isEnterprise) {
                    workspaces = await new GetTeamsFoldersEnterpriseRequest(docContext.docData.orgId ?? '-1').start()
                } else {
                    teamFolders = await new GetTeamsFoldersRequest(docContext.docData.orgId ?? '-1').start()
                }
            } catch (err: any) {
                if (err instanceof RequestError) {
                    if (err.req.responseStatusCode === 403) {
                        setIsInDraft(true)
                        teamFolders = await new GetTeamsFoldersRequest('-1').start()
                    }
                }
            }
            const teamId2FavoritesFolderList: Record<string, FolderWithAuthorityVO[]> = {}
            teamFolders.forEach((t) => {
                teamId2FavoritesFolderList[t.teamWithAuthorityVO.id] = t.folderWithAuthorityVOList
            })

            const teamId = docContext.docData.teamId!
            const currentTeamInfo = teamFolders.filter((t) => t.teamWithAuthorityVO.id === teamId)[0]
            const currentFolder = currentTeamInfo?.folderWithAuthorityVOList.filter(
                (t) => t.id === docContext.docData!.folderId
            )[0]
            if (!currentFolder) {
                setIsInDraft(true)
            }

            return {
                teamOrderedList: teamFolders.map((t) => t.teamWithAuthorityVO),
                currentFolder,
                teamId2FavoritesFolderList,
                workspaces,
            }
        }
    }, [docContext.docData])

    const draftIdResponse = useAsync(async () => {
        if (docContext.docData) {
            const res = await new GetOrganizations().start()
            const currentOrg = res.find((org) => org.id === docContext.docData?.orgId)
            let draftId = currentOrg?.draftFolderId
            if (!draftId) {
                draftId = res.find((org) => org.id === '-1')?.draftFolderId
            }
            return { draftId, planType: currentOrg?.planType }
        } else {
            return undefined
        }
    }, [docContext.docData])

    const currDraftId = useMemo(() => {
        return draftIdResponse.value?.draftId ?? ''
    }, [draftIdResponse.value])

    return {
        isInDraft,
        currDraftId,
        teamFolderInfo,
    }
}

export const AiRecognizeModal = (props: {
    onCancel: () => void
    onClickCancelRecognize: () => void
    visible: boolean
    isEnterprise: boolean
}) => {
    const state = useAiRecognizeModalState(props.isEnterprise)

    useEffect(() => {
        WKFrog.addFrogRecord({
            url: '/expose/AIDesignSystemMaintainer/enter',
            eventId: 26799,
            eventName: 'enter',
            eventAction: 'expose',
        })
    }, [])

    return AiRecognizeModalDataImpl({
        ...props,
        teamOrderedList: state.teamFolderInfo.value?.teamOrderedList ?? [],
        workspaces: state.teamFolderInfo.value?.workspaces ?? [],
        teamId2FavoritesFolderList: state.teamFolderInfo.value?.teamId2FavoritesFolderList ?? {},
        currentDocFolder: state.teamFolderInfo.value?.currentFolder,
        currDraftId: state.currDraftId,
        isInDraft: state.isInDraft,
    })
}
