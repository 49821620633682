import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    AISearch: 'AI 检索',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    AISearch: 'AI Search',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
