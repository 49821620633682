import type { PopupState } from '../../../../document/node/node'
import { PopupStateType } from '../../../../document/node/node'

const defaultPopupState: PopupState = {
    type: PopupStateType.POPUP_STATE_TYPE_NONE,
    reciprocalIndex: -1,
    multiPopup: [],
}
export function modifiedMultiPopup(
    popupState: PopupState = defaultPopupState,
    type: PopupStateType,
    ...multiPopup: (PopupState | undefined)[]
) {
    const _popupState: PopupState = { ...popupState }
    let needKeep = true
    const _multiPopup = (_popupState?.multiPopup ?? []).filter((v) => {
        if (needKeep) {
            needKeep = v.type !== type
        }
        return needKeep
    })
    for (const item of multiPopup) {
        if (item) {
            _multiPopup.push(item)
        }
    }
    _popupState.multiPopup = _multiPopup
    return _popupState
}
