import { useEffect, useRef } from 'react'

export function useWindowResize(onChangeResize?: (e: UIEvent) => void) {
    const ref = useRef(onChangeResize)
    ref.current = onChangeResize

    useEffect(() => {
        const onResize = (e: UIEvent) => ref.current?.(e)
        window.addEventListener('resize', onResize)
        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [])
}
