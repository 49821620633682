/**
 * @owner: chenxiangbj@kanyun.com
 */
import classnames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode, Ref, useMemo } from 'react'
import { IconLoading16 } from '../../svg-icon/16/common'
import { IconLoading24 } from '../../svg-icon/24/common'
import { TabElement } from '../tab-element'
import styles from './button.module.less'
export interface WKButtonProps extends HTMLAttributes<HTMLButtonElement> {
    type: 'primary' | 'secondary' | 'text' | 'lake7' // lake7 设计定义了部分的样式
    danger?: boolean
    white?: boolean
    icon?: ReactNode
    children?: ReactNode
    disabled?: boolean
    loading?: boolean
    formId?: string
    size?: 'small' | 'medium' | 'large'
    htmlType?: 'submit' | 'reset' | 'button'
    dataTestId?: string
    ellipsis?: boolean
}
export const _WKButton = (props: WKButtonProps, ref: Ref<HTMLButtonElement>) => {
    const {
        loading,
        icon,
        disabled,
        size = 'medium',
        type,
        htmlType,
        className,
        danger,
        white,
        formId,
        dataTestId,
        ellipsis,
        ...rest
    } = props
    // https://css-tricks.com/keyboard-only-focus-styles/ focus样式只响应 keyboard 操作
    const wrapType = useMemo(() => {
        if (danger) {
            return 'danger-type'
        } else if (white) {
            return 'white-type'
        } else {
            return 'default-type'
        }
    }, [danger, white])

    const typeClassname = useMemo(() => {
        if (loading) {
            return styles[`${wrapType}-${type}-loading`]
        } else {
            return styles[`${wrapType}-${type}`]
        }
    }, [loading, type, wrapType])
    const gapClass = useMemo(() => {
        if (size == 'large') {
            return styles['button-gap-large']
        }
        return styles['button-gap']
    }, [size])

    return (
        <button
            ref={ref}
            form={formId}
            type={htmlType || 'button'}
            tabIndex={-1}
            disabled={disabled || loading}
            className={classnames(styles.button, gapClass, styles[size], wrapType, typeClassname, className)}
            data-testid={dataTestId}
            {...rest}
        >
            <TabElement disabled={disabled || loading}></TabElement>
            {loading ? (
                <span className={classnames(styles.icon, 'animate-spin', props.children && 'mr-1')}>
                    {size === 'large' ? <IconLoading24 /> : <IconLoading16 />}
                </span>
            ) : (
                <>{icon && <span className={classnames(styles.icon, props.children && 'mr-1')}>{icon}</span>}</>
            )}
            <span className={ellipsis ? 'truncate' : undefined}>{props.children}</span>
        </button>
    )
}

export const WKButton = forwardRef(_WKButton)
