import {
    FontName,
    ITextStyle,
    LetterSpacing,
    LineHeight,
    NumberUnit,
    TextDecoration,
} from '../../../../../../document/node/node'
import { environment } from '../../../../../../environment'
import { TextStylePanelProps } from '../text-style-panel/text-style-panel'
import { translation } from './utils.translation'

export function createTextStyleData(params?: Partial<TextStylePanelProps['textStyleData']>) {
    const defaultTextStyleData = createDefaultTextStyleData()
    return Object.assign(defaultTextStyleData, params)
}

export function createFontName(params?: Partial<FontName>) {
    const defaultFontName = createDefaultFontName()
    return Object.assign(defaultFontName, params)
}

function createDefaultTextStyleData(): TextStylePanelProps['textStyleData'] {
    return {
        id: '',
        name: '',
        description: '',
        paragraphSpacing: 0,
        ...createDefaultITextStyle(),
    }
}
function createDefaultITextStyle(): ITextStyle {
    return {
        fontSize: 12,
        fontName: createDefaultFontName(),
        textDecoration: TextDecoration.None,
        lineHeight: createDefaultHeightLine(),
        letterSpacing: createDefaultLetterSpacing(),
        fills: [],
        textStyleId: '',
        fillStyleId: '',
        textCase: undefined,
        fontVariations: [],
        detachOpticalSizeFromFontSize: false,
    }
}

function createDefaultFontName(): FontName {
    const family = environment.isAbroad ? 'Inter' : 'Source Han Sans SC'
    const localizedFamily = environment.isAbroad ? family : translation('SourceHanSans')

    return {
        family: family,
        style: 'Regular',
        localizedFamily: localizedFamily,
        localizedStyle: 'Regular',
        width: 5,
        weight: 400,
        italic: false,
    }
}

function createDefaultHeightLine(): LineHeight {
    return {
        value: 20,
        unit: NumberUnit.Auto,
    }
}

function createDefaultLetterSpacing(): LetterSpacing {
    return {
        value: 0,
        unit: NumberUnit.Pixels,
    }
}
