import { Wukong } from '@wukong/bridge-proto'
import { useCallback, useEffect, useRef, useState, WheelEventHandler } from 'react'
import { CommitType } from '../../../../../document/command/commit-type'
import { cmdUpdateWasmColorMode } from '../../../../../document/command/document-command'
import { ColorMode } from '../../../../../document/node/node'
import { useUserConfigStateV2 } from '../../../../../main/user-config/user-config-hook'
import { SessionStorageKey } from '../../../../../web-storage/session-storage/config/index'
import { enhancedSessionStorage } from '../../../../../web-storage/session-storage/storage'
import { useCommand } from '../../../../context/document-context'
import { toFixed } from '../../../../utils/to-fixed'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { Value } from '../../../atom/inputs/utils/type'
import type { RGB } from './color-type'
import { ChangeColorType, DrawMode } from './color-type'
import { ColorSpace, hex2rgb, hsl2rgb, hsv2rgb, rgb2css, rgb2hex, rgb2hsl, rgb2hsv } from './utils/color-translate'

export interface ColorPickerProps {
    color: RGB
    colorSpace: ColorSpace
    opacity: number // [0-1]
    selectEyedropper?: boolean
    onChangeColor?: (value: RGB, options?: InputOptionsForUndoSquash) => void
    onChangeOpacity?: (value: number, options?: InputOptionsForUndoSquash) => void
    onChangeEyeDropper?: (value: boolean) => void
}

export function useColorPicker(props: ColorPickerProps) {
    const { color, colorSpace, opacity, selectEyedropper, onChangeColor, onChangeOpacity, onChangeEyeDropper } = props
    const colorRef = useRef<RGB>({ ...color })
    const dragStateRef = useRef<'dragging' | 'end'>('end')
    const modeChangeInputRef = useRef<(type: ColorMode, rgb: RGB) => void>(() => {})
    const command = useCommand()

    const [colorMode, setColorMode] = useUserConfigStateV2('colorMode')

    const [drawMode, setDrawMode] = useState<DrawMode>(colorMode === ColorMode.HSL ? DrawMode.HSL : DrawMode.HSB)
    const [hue, setHue] = useState<number>(() => calculateHueHL(drawMode, color).hue)
    const [horizontal, setHorizontal] = useState<number>(() => calculateHueHL(drawMode, color).horizontal)
    const [vertical, setVertical] = useState<number>(() => calculateHueHL(drawMode, color).vertical)
    const [inputR, setInputR] = useState<number>(colorRef.current.r)
    const [inputG, setInputG] = useState<number>(colorRef.current.g)
    const [inputB, setInputB] = useState<number>(colorRef.current.b)
    const [inputHex, setInputHex] = useState<string>(rgb2hex(color.r, color.g, color.b))
    const [inputCSS, setInputCSS] = useState<string>(rgb2css(color.r, color.g, color.b, toFixed(opacity, 4)))

    useEffect(() => {
        command.invoke(cmdUpdateWasmColorMode, colorMode)
    }, [colorMode, command])

    modeChangeInputRef.current = (type: ColorMode, rgb: RGB) => {
        switch (type) {
            case ColorMode.RGB: {
                setInputR(rgb.r)
                setInputG(rgb.g)
                setInputB(rgb.b)
                break
            }
            case ColorMode.HEX: {
                setInputHex(rgb2hex(rgb.r, rgb.g, rgb.b))
                break
            }
            case ColorMode.CSS: {
                setInputCSS(rgb2css(rgb.r, rgb.g, rgb.b, toFixed(opacity, 4)))
                break
            }
            case ColorMode.HSL: {
                // 模式切换不会改变色相
                const { s, l } = rgb2hsl(rgb.r / 255, rgb.g / 255, rgb.b / 255)
                setHorizontal(s)
                setVertical(l)
                break
            }
            case ColorMode.HSB: {
                // 模式切换不会改变色相
                const { s, v } = rgb2hsv(rgb.r / 255, rgb.g / 255, rgb.b / 255)
                setHorizontal(s)
                setVertical(v)
                break
            }
        }
    }

    const reCalculateHHorVer = useCallback(
        (_drawMode: DrawMode, rgb: RGB) => {
            const { hue: _hue, horizontal: _horizontal, vertical: _vertical } = calculateHueHL(_drawMode, rgb)
            if ((!(hue === 0 || hue === 360) || !(_hue === 0 || _hue === 360)) && dragStateRef.current !== 'dragging') {
                setHue(_hue)
            }
            setHorizontal(_horizontal)
            setVertical(_vertical)
        },
        [hue]
    )

    const onChangeColorMode = useCallback(
        (mode: ColorMode) => {
            if (mode === ColorMode.HSL) {
                setDrawMode(DrawMode.HSL)
            } else {
                setDrawMode(DrawMode.HSB)
            }
            setColorMode(mode)
            setSessionColorMode(mode)
            modeChangeInputRef.current(mode, colorRef.current)
        },
        [setColorMode]
    )

    const onChangeOpacityRange = useCallback(
        (_opacity: number, options?: InputOptionsForUndoSquash) => {
            onChangeOpacity?.(Number(_opacity.toFixed(2)), options)
        },
        [onChangeOpacity]
    )
    const onChangeOpacityInput = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value === 'number') {
                onChangeOpacity?.(value / 100, options)
            }
        },
        [onChangeOpacity]
    )
    const onChangeColorInput = (changeColorType: ChangeColorType) => {
        return (value: number | string, v?: number, options?: InputOptionsForUndoSquash) => {
            let nextColor = {
                r: color.r,
                g: color.g,
                b: color.b,
            }
            let nextOpacity: number | undefined
            switch (changeColorType) {
                case ChangeColorType.R: {
                    nextColor.r = Number(value)
                    reCalculateHHorVer(drawMode, nextColor)
                    setInputR(Number(value))
                    break
                }
                case ChangeColorType.G: {
                    nextColor.g = Number(value)
                    reCalculateHHorVer(drawMode, nextColor)
                    setInputG(Number(value))
                    break
                }
                case ChangeColorType.B: {
                    nextColor.b = Number(value)
                    reCalculateHHorVer(drawMode, nextColor)
                    setInputB(Number(value))
                    break
                }
                case ChangeColorType.Hex: {
                    nextColor = hex2rgb(String(value))
                    reCalculateHHorVer(drawMode, nextColor)
                    setInputHex(String(value))
                    break
                }
                case ChangeColorType.CSS: {
                    const rgba = String(value)
                        .toLocaleLowerCase()
                        .match(/^ *rgba *\( *(\d+) *, *(\d+) *, *(\d+) *, *([\d,.]+) *\) *$/)!
                    nextColor.r = Number(rgba[1])
                    nextColor.g = Number(rgba[2])
                    nextColor.b = Number(rgba[3])
                    nextOpacity = Number(rgba[4])
                    reCalculateHHorVer(drawMode, nextColor)
                    setInputCSS(String(value))
                    break
                }
                case ChangeColorType.LH: {
                    nextColor = hsl2rgb(Number(value), horizontal, vertical)
                    rgb2rgb255(nextColor)
                    setHue(Number(value))
                    break
                }
                case ChangeColorType.LS: {
                    nextColor = hsl2rgb(hue, Number(value) / 100, vertical)
                    rgb2rgb255(nextColor)
                    setHorizontal(Number(value) / 100)
                    break
                }
                case ChangeColorType.LL: {
                    nextColor = hsl2rgb(hue, horizontal, Number(value) / 100)
                    rgb2rgb255(nextColor)
                    setVertical(Number(value) / 100)
                    break
                }
                case ChangeColorType.BH: {
                    nextColor = hsv2rgb(Number(value), horizontal, vertical)
                    rgb2rgb255(nextColor)
                    setHue(Number(value))
                    break
                }
                case ChangeColorType.BS: {
                    nextColor = hsv2rgb(hue, Number(value) / 100, vertical)
                    rgb2rgb255(nextColor)
                    setHorizontal(Number(value) / 100)
                    break
                }
                case ChangeColorType.BB: {
                    nextColor = hsv2rgb(hue, horizontal, Number(value) / 100)
                    rgb2rgb255(nextColor)
                    setVertical(Number(value) / 100)
                    break
                }
                case ChangeColorType.RangeHue: {
                    nextColor =
                        drawMode === DrawMode.HSL
                            ? hsl2rgb(Number(value), horizontal, vertical)
                            : hsv2rgb(Number(value), horizontal, vertical)
                    rgb2rgb255(nextColor)
                    modeChangeInputRef.current(colorMode, nextColor)
                    setHue(Number(value))
                    break
                }
                case ChangeColorType.RangeHV: {
                    nextColor =
                        drawMode === DrawMode.HSL ? hsl2rgb(hue, Number(value), v!) : hsv2rgb(hue, Number(value), v!)
                    rgb2rgb255(nextColor)
                    modeChangeInputRef.current(colorMode, nextColor)
                    setHorizontal(Number(value))
                    setVertical(v!)
                    // 保持色相不变
                    // setHue(hue)
                    break
                }
            }
            if (nextOpacity !== undefined) {
                onChangeOpacity?.(nextOpacity, options)
            }
            colorRef.current = { ...nextColor }
            onChangeColor?.(nextColor, options)
        }
    }

    const onDragState = useCallback((dragState: 'dragging' | 'end') => {
        dragStateRef.current = dragState
    }, [])

    const onWheel: WheelEventHandler<HTMLDivElement> = (e) => {
        const dx = e.deltaX | 0
        const dy = e.deltaY | 0
        const change = onChangeColorInput(ChangeColorType.RangeHue)
        const cal = (x: number, y: number): number => {
            const x2 = Math.pow(x | 0, 2) | 0
            const y2 = Math.pow(y | 0, 2) | 0
            const len = Math.sqrt(x2 + y2) | 0
            //鼠标滚轮的滑动距离偏大，所以这里滤掉
            return Math.min(len, 18)
        }
        const len = cal(dx, dy)
        const deg = Math.atan2(dy | 0, dx | 0) | 0
        if (deg === -1 || deg == 0) {
            const delta = hue - len
            change(delta <= 0 ? 360 + delta : delta, undefined, { commitType: CommitType.CommitSquash })
        } else {
            const delta = hue + len
            change(delta >= 360 ? delta - 360 : delta, undefined, { commitType: CommitType.CommitSquash })
        }
    }

    useEffect(() => {
        if (colorRef.current.r !== color.r || colorRef.current.g !== color.g || colorRef.current.b !== color.b) {
            colorRef.current = { ...color }
            reCalculateHHorVer(drawMode, color)
            modeChangeInputRef.current(colorMode, color)
        }
    }, [color, colorMode, drawMode, reCalculateHHorVer])

    useEffect(() => {
        if (colorMode === ColorMode.CSS) {
            modeChangeInputRef.current(colorMode, colorRef.current)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opacity])

    return {
        onChangeColorMode,
        hue,
        horizontal,
        vertical,
        drawMode,
        colorMode,
        colorSpace,
        selectEyedropper,
        onChangeEyeDropper,
        colorRef,
        opacity,
        onChangeOpacityRange,
        onChangeOpacityInput,
        inputR,
        inputG,
        inputB,
        inputHex,
        inputCSS,
        onChangeColorInput,
        onDragState,
        onWheel,
    } as const
}

export function calculateHueHL(mode: DrawMode, rgb: RGB) {
    let hue: number, horizontal: number, vertical: number
    if (mode === DrawMode.HSL) {
        ;({ h: hue, s: horizontal, l: vertical } = rgb2hsl(rgb.r / 255, rgb.g / 255, rgb.b / 255))
    } else {
        ;({ h: hue, s: horizontal, v: vertical } = rgb2hsv(rgb.r / 255, rgb.g / 255, rgb.b / 255))
    }
    return { hue, horizontal, vertical }
}

export function parseCSS(value: string | number) {
    const formatOrigin = String(value).toLocaleLowerCase().replace(/ /g, '')
    const span = document.createElement('span')
    span.style.backgroundColor = formatOrigin
    if (!span.style.backgroundColor) {
        throw new Error('无效CSS')
    }
    const isOpacityFull = /(,1\))$/.test(formatOrigin)
    let formatTarget = span.style.backgroundColor.toLocaleLowerCase().replace(/ /g, '')
    if (isOpacityFull) {
        formatTarget = formatTarget.replace(/^rgb.*\(/, 'rgba(')
        formatTarget = formatTarget.replace(/\)$/, ',1)')
    }
    if (formatOrigin !== formatTarget || !formatTarget.includes('rgba')) {
        throw new Error('只允许输入css-rgba格式')
    }
    return formatTarget
}

export function rgb2rgb255(rgb: RGB) {
    rgb.r *= 255
    rgb.g *= 255
    rgb.b *= 255
    return rgb
}

export function getSessionColorMode(): ColorMode {
    const colorMode = enhancedSessionStorage.getItem(SessionStorageKey.ColorMode)
    if (colorMode !== null) {
        return Number(colorMode) as ColorMode
    }
    return colorMode ?? ColorMode.HEX
}

export function getSessionColorModeV2(): Wukong.DocumentProto.DevAndReadonlyColorMode {
    const colorMode = enhancedSessionStorage.getItem(SessionStorageKey.ColorMode)
    if (colorMode !== null) {
        return Number(colorMode) as Wukong.DocumentProto.DevAndReadonlyColorMode
    }
    return colorMode ?? Wukong.DocumentProto.DevAndReadonlyColorMode.DEV_AND_READONLY_COLOR_MODE_H_E_X
}

export function setSessionColorMode(colorMode: ColorMode) {
    enhancedSessionStorage.setItem(SessionStorageKey.ColorMode, String(colorMode))
}

export function setSessionColorModeV2(colorMode: Wukong.DocumentProto.DevAndReadonlyColorMode) {
    enhancedSessionStorage.setItem(SessionStorageKey.ColorMode, String(colorMode))
}
