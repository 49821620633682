import { getTranslationValue } from '../../../../../../../util/src'

export const zhTranslation = {
    RuleDescription: '跳过与设计系统内样式仅行高不同的文本图层',
    Preference: '检查规则',
} as const

export const enTranslation = {
    RuleDescription: 'Skip text layers that differ only in line height from styles within the design system',
    Preference: 'Preference',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
