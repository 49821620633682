import { ReactNode } from 'react'
import { WKButton } from '../../../../../../../ui-lib/src'

export const PluginTextButton = ({
    onClick,
    icon,
    label,
    className,
}: {
    onClick: () => void
    icon: ReactNode
    label: string
    className?: string
}) => (
    <WKButton
        className={`box-border h-80px w-236px py-20px px-71px bg-$wk-v2-fill-color-gray-1 ${className}`}
        type={'text'}
        size={'large'}
        onClick={onClick}
    >
        <div className="flex items-center justify-center">
            {icon}
            <span className="wk-font-medium wk-text-14 color-$wk-v2-label-color-gray-13">{label}</span>
        </div>
    </WKButton>
)
