/* eslint-disable no-restricted-imports */
import { useViewState } from '../../../view-state-bridge'
import classNames from 'classnames'
import { FC, PropsWithChildren } from 'react'
import styles from './index.module.less'

/**
 * 放置在该 wrapper 下，会跟随 canvas 的大小变化大小：canvas 的大小随标尺的出现变小
 * 超出 wrapper 的元素会隐藏
 */
export const CanvasWrapper: FC<PropsWithChildren> = ({ children }) => {
    const viewport = useViewState('visibleViewport')!
    return (
        <div
            className={classNames(styles['canvas-wrapper'])}
            style={{
                width: viewport.width,
                height: viewport.height,
                left: viewport.paddingLeft,
                top: viewport.paddingTop,
            }}
            data-testid="comment-wrapper"
        >
            {children}
        </div>
    )
}
