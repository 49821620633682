/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { HTMLAttributes, useCallback, useMemo, useRef } from 'react'
import { DragMoveBox, DragMoveBoxProps } from '../../../../../../ui-lib/src'
import { capitalizeFirstLetter, isEnglishLanguage, timeDesc2 } from '../../../../../../util/src'
import { CommentUser } from '../../../../kernel/interface/comment'
import { CommentAvatar } from '../comment-avatar/comment-avatar'
import { Range } from '../dot-range/range'
import { ShowMessage } from '../show-message/show-message'
import { Position } from '../type'
import classes from './bubble.module.less'

export interface BubbleDOMProps extends HTMLAttributes<HTMLDivElement> {
    message: string // comment message
    usersMap: Map<CommentUser['id'], CommentUser> // 用于解析 message
    repliesSum: number
    nickname: string
    createdTime: number
    isHovered: boolean
    isActive: boolean
    isResolved: boolean
    isUnread: boolean
    isPending: boolean
    hasAnchor: boolean
    showUsers: CommentUser[] // 倒序展示
    showUsersOverlaySize: number
    closeMove: boolean
    dragMoveBasePoint: DragMoveBoxProps['dragMoveBasePoint']
    onContextMenu: DragMoveBoxProps['onContextMenu']
    onMoveStart: DragMoveBoxProps['onMoveStart']
    onMoving: DragMoveBoxProps['onMoving']
    onMoveEnd: DragMoveBoxProps['onMoveEnd']
    anchorRefCallback: (e: HTMLDivElement | null) => void
    rangeRefCallback: (e: HTMLDivElement | null) => void
    dataTestIds?: {
        range?: string
        bubble?: string
        content?: string
    }
}

export function BubbleDOM(props: BubbleDOMProps) {
    const {
        message,
        usersMap,
        nickname,
        createdTime,
        repliesSum,
        isHovered,
        isActive,
        isResolved,
        isUnread,
        isPending,
        hasAnchor,
        showUsers,
        showUsersOverlaySize,
        closeMove,
        dragMoveBasePoint,
        onClick, // 这里的点击事件指的是没{translation('FdMSYR')}移动过位置触发的点击
        onContextMenu,
        onMoving,
        onPointerDown,
        anchorRefCallback,
        rangeRefCallback,
        dataTestIds,
        onMoveStart,
        onMoveEnd,
        onMouseMove,
        onPointerUp,
        onPointerEnter,
        onPointerLeave,
    } = props
    const isClick = useRef<boolean>(false)

    const perfDragMoveBasePoint = useMemo(() => {
        return dragMoveBasePoint
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dragMoveBasePoint?.left, dragMoveBasePoint?.top])

    const _onPointerDown = useCallback(
        (e: React.PointerEvent<HTMLDivElement>) => {
            isClick.current = true
            onPointerDown?.(e)
        },
        [onPointerDown]
    )

    const _onMoving = useCallback(
        (position: Position, e: React.PointerEvent<HTMLDivElement>) => {
            isClick.current = false
            onMoving?.(position, e)
        },
        [onMoving]
    )

    const _onClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (isClick.current) {
                onClick?.(e)
            }
        },
        [onClick]
    )

    const _onContextMenu = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            isClick.current = false
            onContextMenu?.(e)
        },
        [onContextMenu]
    )

    return useMemo(
        () => (
            <DragMoveBox
                ref={anchorRefCallback}
                className={classnames(
                    classes.bubble,
                    { [classes.highlightBorder]: isActive },
                    { [classes.hovered]: !isActive && isHovered },
                    { [classes.resolved]: isResolved },
                    { [classes.unread]: isUnread },
                    { [classes.column]: showUsers.length > 1 },
                    { [classes.pending]: isPending }
                )}
                dragMoveBasePoint={perfDragMoveBasePoint}
                closeMove={closeMove}
                onClick={_onClick}
                onContextMenu={_onContextMenu}
                onMoving={_onMoving}
                onPointerDown={_onPointerDown}
                dataTestId={dataTestIds?.bubble}
                onMoveStart={onMoveStart}
                onMoveEnd={onMoveEnd}
                onMouseMove={onMouseMove}
                onPointerUp={onPointerUp}
                onPointerEnter={onPointerEnter}
                onPointerLeave={onPointerLeave}
            >
                {hasAnchor ? (
                    <Range ref={rangeRefCallback} className={classes.range} dataTestId={dataTestIds?.range} />
                ) : null}
                <div className={classes.content} data-testid={dataTestIds?.content}>
                    <div className={classes.avatarContent}>
                        {showUsersOverlaySize > 0 ? (
                            <span
                                className={classnames(classes.moreCommentAvatar, classes.commentAvatar)}
                                style={{ width: showUsersOverlaySize.toString().length * 8 + 16 }}
                            >
                                +{showUsersOverlaySize}
                            </span>
                        ) : null}
                        {showUsers.map((user) => (
                            <CommentAvatar
                                key={user.id}
                                userInfo={user}
                                pending={isPending}
                                className={classes.commentAvatar}
                            />
                        ))}
                    </div>
                    <div className={classes.expendContent}>
                        <div className={classes.title}>
                            <div className={classes.name}>{nickname}</div>
                            <div className={classes.time}>{capitalizeFirstLetter(timeDesc2(createdTime))}</div>
                        </div>
                        <div className={classes.message}>
                            <ShowMessage message={message} usersMap={usersMap} className={classes.forbidSelect} />
                        </div>
                        {repliesSum > 0 ? (
                            <div className={classes.repliesSum}>
                                {isEnglishLanguage()
                                    ? `${repliesSum} ${repliesSum === 1 ? 'reply' : 'replies'}`
                                    : `有${repliesSum}条回复`}
                            </div>
                        ) : null}
                    </div>
                </div>
            </DragMoveBox>
        ),
        [
            _onClick,
            _onContextMenu,
            _onMoving,
            _onPointerDown,
            anchorRefCallback,
            closeMove,
            createdTime,
            dataTestIds?.bubble,
            dataTestIds?.content,
            dataTestIds?.range,
            perfDragMoveBasePoint,
            hasAnchor,
            isActive,
            isHovered,
            isPending,
            isResolved,
            isUnread,
            message,
            nickname,
            rangeRefCallback,
            repliesSum,
            showUsers,
            showUsersOverlaySize,
            usersMap,
            onMoveStart,
            onMoveEnd,
            onMouseMove,
            onPointerUp,
            onPointerEnter,
            onPointerLeave,
        ]
    )
}
