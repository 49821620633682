/* eslint-disable no-restricted-imports */
import { domLocation } from '../../../../util/src'
import { isLoginExpired } from '../../kernel/util/expire-login'
import { DetectExpireLoginPop } from './expire-login-pop'

export async function popWhenDetectExpireLogin() {
    if (
        !domLocation().pathname.includes('/login') &&
        document.getElementsByClassName('expireTimeModalPop').length <= 0 &&
        (await isLoginExpired(false))
    ) {
        setTimeout(() => {
            DetectExpireLoginPop()
        }, 10)
    }
}
