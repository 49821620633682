import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    MainMenu: '主菜单',
} as const

export const enTranslation = {
    MainMenu: 'Main menu',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
