import { Select, SelectArrowSingleLevelProps } from '../../../../../../../../ui-lib/src'
import { useMemo } from 'react'
import { InputOptionsForUndoSquash } from '../../../../atom/inputs/components/formatted-input'
import { ScrubbableInputNumber } from '../../../../atom/inputs/scrubbable-input-number'
import { formateToFixed2 } from '../../../../atom/inputs/utils/format'

const fontSizeList = [10, 11, 12, 13, 14, 15, 16, 20, 24, 32, 36, 48, 64, 96, 128]

export interface FontSizeSelectProps {
    isMixed: SelectArrowSingleLevelProps['isMixed']
    disabled: SelectArrowSingleLevelProps['disabled']
    value: SelectArrowSingleLevelProps['value']
    optionWidth: 88 | 100
    onChange: (v: number | string, options?: InputOptionsForUndoSquash) => void
    dataTestIds?: {
        fontSizeSelect?: string
        fontSizeSelectAddOption?: string
        fontSizeInput?: string
    }
}
export function FontSizeSelect(props: FontSizeSelectProps) {
    const needAddFontSizeOption = useMemo(() => {
        return !props.isMixed && !fontSizeList.includes(props.value) && typeof props.value === 'number'
    }, [props.isMixed, props.value])

    return (
        <Select.ArrowSingleLevel
            isMixed={props.isMixed}
            disabled={props.disabled}
            value={props.value}
            onChange={(v) => props.onChange(v)}
            label={
                <ScrubbableInputNumber
                    isMixed={props.isMixed}
                    disabled={props.disabled}
                    value={props.value}
                    onChange={(v, options) => props.onChange?.(v as number, options)}
                    min={1}
                    scrubbingDisabled={props.isMixed}
                    testId={props.dataTestIds?.fontSizeInput}
                />
            }
            minWidth={props.optionWidth}
            dataTestIds={{ triggerFocus: props.dataTestIds?.fontSizeSelect }}
        >
            {needAddFontSizeOption ? (
                <Select.ArrowSingleLevel.Option
                    key={props.value}
                    value={props.value}
                    backwardIcon={''}
                    splitLineBottom
                    data-testid={props.dataTestIds?.fontSizeSelectAddOption}
                >
                    {formateToFixed2(props.value)}
                </Select.ArrowSingleLevel.Option>
            ) : null}
            {fontSizeList.map((size) => (
                <Select.ArrowSingleLevel.Option key={size} value={size} backwardIcon={''}>
                    {size}
                </Select.ArrowSingleLevel.Option>
            ))}
        </Select.ArrowSingleLevel>
    )
}
