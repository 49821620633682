import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import { ToggleShowShortcutPanelWasmCall } from '@wukong/bridge-proto'
import { useMemo, useState } from 'react'
import { environment } from '../../../../environment'
import { OnboardingScene } from '../../../../kernel/interface/onboarding'
import { ShortcutKey, shortcutLabelMap } from '../../../../kernel/interface/shortcut-key'
import { useCommand } from '../../../../main/app-context'
import { useLeftPanelShown, useRightPanelShown, useTopbarShown } from '../../../../main/layout/layout-context'
import { onboardingService } from '../../../../main/service/onboarding-service'
import { HelpCenterV2 } from '../../../../share/help-center'
import { useMenuContextState } from '../../top-area/menu/context/menu-context'
import { useShowDevModeReminder } from '../../top-area/payment-alert-banner/hooks'

export const DevModeEditorHelpCenter = () => {
    const showReminder = useShowDevModeReminder()

    const [open, setOpen] = useState(false)
    const command = useCommand()
    const { setIsOpenFeedback } = useMenuContextState()
    const showTopbar = useTopbarShown()
    const showLeftPanel = useLeftPanelShown()
    const showRightPanel = useRightPanelShown()

    const isNotFullScreen = useMemo(() => {
        return showTopbar || showLeftPanel || showRightPanel
    }, [showLeftPanel, showRightPanel, showTopbar])

    return isNotFullScreen ? (
        <HelpCenterV2
            openState={open}
            onChangeOpenState={setOpen}
            items={[
                {
                    name: translation('HelpCenter'),
                    dataTestId: 'dev-help-center-item',
                    customNode: {
                        handler: () => window.open(`${environment.host}/help/docs`),
                    },
                },
                ...(!environment.isAbroad
                    ? [
                          {
                              name: translation('VideoTutorials'),
                              dataTestId: 'dev-help-center-item',
                              customNode: {
                                  handler: () => window.open(`${environment.host}/help/videos`),
                              },
                          },
                      ]
                    : []),
                {
                    name: translation('ReleaseNotes'),
                    dataTestId: 'dev-help-center-item-release-notes',
                    customNode: {
                        handler: () => window.open(`${environment.host}/release-notes`),
                    },
                },
                {
                    name: translation('KeyboardShortcuts'),
                    dataTestId: 'dev-help-center-item',
                    customNode: {
                        shortcut: shortcutLabelMap[ShortcutKey.ShortcutGuide],
                        handler: () => command.DEPRECATED_invokeBridge(ToggleShowShortcutPanelWasmCall),
                    },
                },
                {
                    name: translation('ResetOnboarding'),
                    dataTestId: 'dev-help-center-item',
                    hidden: showReminder,
                    customNode: {
                        handler: () => {
                            onboardingService.resetTour(OnboardingScene.DevMode)
                        },
                    },
                },
                {
                    name: '',
                    dataTestId: 'dev-help-center-item',
                    disabled: true,
                    customNode: { autoSplitLine: true },
                },
                {
                    name: translation('SendFeedback'),
                    dataTestId: 'dev-help-center-item',
                    customNode: {
                        handler: () => setIsOpenFeedback(true),
                    },
                },
            ]}
        />
    ) : null
}
