import { translation } from './current-dialog.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import React, { useEffect, useState } from 'react'
import { WKAlert, WKButton, WKDialog, WKRadio, WKRadioGroup, WKTextButton } from '../../../../../ui-lib/src'
import { getMotiffName } from '../../../../../util/src'
import { useColorProfileService } from '../../../main/app-context'
import { useMenuContextState } from '../top-area/menu/context/menu-context'
import styles from './current-dialog.module.less'
import { ColorProfieDialogFooter, isShowColorProfieDialogFooter } from './dialog-footer'
import { jumpToCenter, useColorProfileDialog } from './hook'

export interface ChangeColorProfieDialogProps {
    colorProfile: Wukong.DocumentProto.DocumentColorProfile
    visible: boolean
    onClose: () => void
}

const SRGB = Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B
const P3 = Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILE_DISPLAY_P3

// 把字符串常量单独提出来，便于写测试
export const ColorProfileDialogStringResources = {
    TitleChangeTo: {
        [SRGB]: translation('ChangeToSRGB'),
        [P3]: translation('ChangeToP3'),
    },
    AssignColor: translation('WEPLUC'),
    AssignColorDescription: translation('CAsQQu'),
    ConvertColor: translation('jZjtrb'),
    ConvertColorDescription: {
        [SRGB]: translation('ChangeValuesToSRGB'),
        [P3]: translation('ChangeValuesToP3'),
    },
    ConvertColorToDisplayP3Hint: translation('GMZmEi'),
    ButtonCancelChangeTo: {
        [SRGB]: `${translation('YuKwrA')} Display P3`,
        [P3]: `${translation('YuKwrA')} sRGB`,
    },
    ButtonConvertTo: {
        [SRGB]: translation('ConvertToSRGB'),
        [P3]: translation('ConvertToP3'),
    },
    ButtonAssign: {
        [SRGB]: translation('AssignSRGB'),
        [P3]: translation('AssignP3'),
    },
    WarningDeviceNotSupport: {
        [SRGB]: translation('NotSupportedSRGB'),
        [P3]: translation('NotSupportedP3'),
    },
    WarningOtherDeviceSupport: {
        [SRGB]: translation('SupportSRGB', { name: '{name}', motiffName: getMotiffName() }),
        [P3]: translation('SupportP3', { name: '{name}', motiffName: getMotiffName() }),
    },
    WarningHostNotSupport: {
        [SRGB]: translation('AppliedSRGB'),
        [P3]: translation('AppliedP3'),
    },
}
const R = ColorProfileDialogStringResources

enum ChangeMode {
    Assign,
    Convert,
}

const alertCls = {
    icon: styles.alertHiddenOriginIcon,
}

const dialogContainerStyle: React.CSSProperties = {
    paddingBottom: 0,
}

function ChangeColorProfieDialog({ visible, colorProfile, onClose }: ChangeColorProfieDialogProps) {
    const service = useColorProfileService()
    const [changeMode, setChangeMode] = useState<ChangeMode>(ChangeMode.Assign)
    const { deviceSupportLevel, isHostSupport } = useColorProfileDialog(colorProfile, visible)
    const showP3Hint =
        colorProfile === Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B &&
        changeMode === ChangeMode.Convert

    const handleChangeTo = () => {
        service.updateDocumentColorProfile(colorProfile, changeMode === ChangeMode.Convert)
        onClose()
    }

    useEffect(() => {
        if (visible) {
            service.refreshMonitorDisplayP3SupportedLevel()
        }
    }, [visible, service])

    return (
        <WKDialog
            title={R.TitleChangeTo[colorProfile]}
            visible={visible}
            onCancel={onClose}
            bodyStyle={dialogContainerStyle}
            footer={
                !isShowColorProfieDialogFooter(deviceSupportLevel, isHostSupport, false) ? null : (
                    <ColorProfieDialogFooter
                        deviceSupportLevel={deviceSupportLevel}
                        isHostSupport={isHostSupport}
                        colorProfile={colorProfile}
                        warningDeviceNotSupportText={R.WarningDeviceNotSupport}
                        warningOtherDeviceSupportText={R.WarningOtherDeviceSupport}
                        warningHostNotSupportText={R.WarningHostNotSupport}
                        warningShowMoreLink={null}
                    />
                )
            }
        >
            <div className={styles.colorProfileDialogContent}>
                <WKRadioGroup
                    direction="vertical"
                    verticalGap={16}
                    value={ChangeMode[changeMode]}
                    onValueChange={(value) => {
                        setChangeMode(ChangeMode[value as keyof typeof ChangeMode])
                    }}
                >
                    <WKRadio
                        value={ChangeMode[ChangeMode.Assign]}
                        label={R.AssignColor}
                        description={R.AssignColorDescription}
                    ></WKRadio>
                    <WKRadio
                        value={ChangeMode[ChangeMode.Convert]}
                        label={R.ConvertColor}
                        description={R.ConvertColorDescription[colorProfile]}
                    ></WKRadio>
                </WKRadioGroup>

                {showP3Hint && (
                    <div className={styles.alertContainer}>
                        <WKAlert.WithoutTitle>
                            {R.ConvertColorToDisplayP3Hint}
                            <WKTextButton type="primary" size={12} onClick={jumpToCenter}>
                                {translation('LearnMore')}
                            </WKTextButton>
                        </WKAlert.WithoutTitle>
                    </div>
                )}
                <div className={styles.panel}>
                    <WKButton type="secondary" onClick={onClose}>
                        {R.ButtonCancelChangeTo[colorProfile]}
                    </WKButton>
                    {changeMode === ChangeMode.Assign && (
                        <WKButton type="primary" onClick={handleChangeTo}>
                            {R.ButtonAssign[colorProfile]}
                        </WKButton>
                    )}
                    {changeMode === ChangeMode.Convert && (
                        <WKButton type="primary" onClick={handleChangeTo}>
                            {R.ButtonConvertTo[colorProfile]}
                        </WKButton>
                    )}
                </div>
            </div>
        </WKDialog>
    )
}

export function MenuChangeColorProfieDialog() {
    const { changeColorProfileDialogState } = useMenuContextState()

    return (
        <ChangeColorProfieDialog
            visible={changeColorProfileDialogState.visible}
            colorProfile={changeColorProfileDialogState.nextColorProfile}
            onClose={changeColorProfileDialogState.close}
        />
    )
}
