/* eslint-disable no-restricted-imports */
import { MouseEventHandler, useMemo, useRef, useState } from 'react'
import useEvent, { ListenerType1 } from 'react-use/lib/useEvent'
import { TabLoop } from '../../../../ui-lib/src/components/tab-loop/tab-loop'
import { DevModeRightPanelDetail } from '../../ui/component/dev-mode/right-panel-detail/dev-mode-right-panel-detail'
import { useAppContext } from '../app-context'
import { useRightPanelShown, useRightPanelWidth, useSetRightPanelWidth } from '../layout/layout-context'
import styles from './dev-mode-right-panel.module.less'

const MaxRightPanelWidth = 640
const MinRightPanelWidth = 300

export function getWidthInRange(width: number) {
    return width < MinRightPanelWidth ? MinRightPanelWidth : width > MaxRightPanelWidth ? MaxRightPanelWidth : width
}

const DevModeRightPanelResizeLine = () => {
    const startRef = useRef<{
        startWidth: number
        startMouseClientX: number
    } | null>(null)

    const rightPanelWidth = useRightPanelWidth()

    const setRightPanelWith = useSetRightPanelWidth()

    const resizeLineRight = useMemo(() => rightPanelWidth - 3 + 'px', [rightPanelWidth])

    const [resizeLineWidth, setResizeLineWidth] = useState<string>('6px')

    const userConfigService = useAppContext().userConfigService

    const handleMouseDown = (e: MouseEvent) => {
        setResizeLineWidth('100%')
        startRef.current = {
            startWidth: rightPanelWidth,
            startMouseClientX: e.clientX,
        }
    }

    const handleMouseUp = () => {
        setResizeLineWidth('6px')
        startRef.current = null
    }

    const handleMouseMove: MouseEventHandler<HTMLDivElement> = (e) => {
        setRightPanelWith((width: number) => {
            if (startRef.current) {
                const newWidth = getWidthInRange(
                    startRef.current.startWidth + (startRef.current.startMouseClientX - e.clientX)
                )
                userConfigService.updateConfig2('devModeRightPanelWidth', newWidth)
                return newWidth
            }
            return width
        })
    }

    useEvent<ListenerType1>('mouseup', handleMouseUp, window, { capture: true })

    useEvent<ListenerType1>('mousemove', handleMouseMove)

    return (
        <div
            style={{ right: resizeLineRight, width: resizeLineWidth }}
            className={styles['right-side-resize-line']}
            onMouseDown={(e) => handleMouseDown(e.nativeEvent)}
        ></div>
    )
}

export function DevModeEditorRightPanel() {
    const showRightPanel = useRightPanelShown()
    return showRightPanel ? (
        <div className={styles['attribute-panel']} data-testid="dev-mode-right-panel">
            <TabLoop>
                <DevModeRightPanelDetail />
            </TabLoop>
            <DevModeRightPanelResizeLine />
            {/* 仅为 devMode 的引导做定位作用 */}
            <div className={styles['onboarding-position']} id="onboarding-position" />
        </div>
    ) : null
}
