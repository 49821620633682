import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Constraints: '约束',
} as const

export const enTranslation = {
    Constraints: 'Constraints',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
