import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    GreetingTitle: '你好，{{name}}',
    GreetingDescription: '我可以帮助你解答设计问题、提供信息，或者协助你完成特定的任务。 你今天需要什么帮助？',
    Ask: '询问',
    AskDescription: '解答专业知识',
    Editor: '辅助编辑',
    EditorDescription: '完成特定操作任务',
    SearchIdeas: '搜索灵感',
    SearchIdeasDescription: '搜集参考图以发散思维',
    SearchAssets: '检索我的设计',
    SearchAssetsDescription: '快速找到相似设计稿',
    Help: '使用帮助',
    HelpDescription: '回答 Motiff 相关知识',
} as const

// TODO: 翻译
export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    GreetingTitle: '你好，{{name}}',
    GreetingDescription: '我可以帮助你解答设计问题、提供信息，或者协助你完成特定的任务。 你今天需要什么帮助？',
    Ask: '询问',
    AskDescription: '解答专业知识',
    Editor: '辅助编辑',
    EditorDescription: '完成特定操作任务',
    SearchIdeas: '搜索灵感',
    SearchIdeasDescription: '搜集参考图以发散思维',
    SearchAssets: '检索我的设计',
    SearchAssetsDescription: '快速找到相似设计稿',
    Help: '使用帮助',
    HelpDescription: '回答 Motiff 相关知识',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
