import { translation } from './emoji-list.translation'
import classes from './emoji-list.module.less'
import { useCallback, useState } from 'react'
import { getRecentEmoji, EmojiShortName, addRecentEmojiToLocal } from './emoji'
import { EmojiMap, EmojiShortNameMap } from './emoji-map'

export interface EmojiSelectProps {
    onSelect: (shortName: EmojiShortName) => void
}

export function EmojiSelect(props: EmojiSelectProps) {
    const { onSelect } = props
    const [recentEmojiList, setRecentEmojiList] = useState<EmojiShortName[]>(getRecentEmoji())
    const onClick = useCallback(
        (emojiShortName: EmojiShortName) => {
            onSelect(emojiShortName)
            addRecentEmojiToLocal(emojiShortName)
            setRecentEmojiList(getRecentEmoji())
        },
        [onSelect]
    )
    return (
        <div className={classes.emojiContainer}>
            {recentEmojiList.length > 0 ? <div className={classes.title}>{translation('RecentlyUsed')}</div> : null}

            {recentEmojiList.map((emojiShortName) => (
                <div
                    onClick={() => {
                        onClick(emojiShortName)
                    }}
                    className={classes.emoji}
                    key={emojiShortName}
                >
                    {EmojiShortNameMap.get(emojiShortName)}
                </div>
            ))}

            <div className={classes.title}>{translation('AllEmojis')}</div>

            {[...EmojiMap.keys()].map((emoji) => (
                <div
                    onClick={() => {
                        onClick(EmojiMap.get(emoji) ?? '')
                    }}
                    className={classes.emoji}
                    key={EmojiMap.get(emoji)}
                >
                    {emoji}
                </div>
            ))}
        </div>
    )
}
