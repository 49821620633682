export const AvatarDefaultSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full" width="24" height="24" viewBox="0 0 24 24">
        <path
            transform="matrix(1 0 0 1 0 0)"
            d="M12 0C5.37258 0 0 5.37258 0 12L0 12.018C0 18.6454 5.35458 24 11.982 24L12 24C18.6274 24 24 18.6454 24 12.018L24 12C24 5.37258 18.6454 0 12.018 0L12 0Z"
            fillRule="nonzero"
            fill="transparent"
        />
        <path
            transform="matrix(1 0 0 1 4.35938 5)"
            d="M11.6406 4C11.6406 6.20914 10.1406 8.5 7.64062 8.5C5.14062 8.5 3.64062 6.20914 3.64062 4C3.64062 1.79086 5.43149 0 7.64062 0C9.84976 0 11.6406 1.79086 11.6406 4ZM0.409963 12.2706C1.37002 11.569 3.92831 10 7.63769 10C11.3471 10 13.9054 11.569 14.8654 12.2706C15.0951 12.4385 15.2424 12.6961 15.2705 12.9792C15.2986 13.2622 15.2048 13.5438 15.0127 13.7535C13.1859 15.7475 10.5573 17 7.63769 17C4.71805 17 2.0895 15.7475 0.262671 13.7535C0.0705314 13.5438 -0.023212 13.2622 0.00490093 12.9792C0.0330138 12.6961 0.180323 12.4385 0.409963 12.2706Z"
            fillRule="evenodd"
            fill="rgb(255, 255, 255)"
        />
    </svg>
)
