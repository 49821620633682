import { Wukong } from '@wukong/bridge-proto'
import { LibraryId } from '../../../../kernel/interface/component-style-library-id'
import type { LibraryContentVO } from '../../../../kernel/interface/library'
import { compareComponentVO, compareVariableSetVO } from '../../../../share/component-style-library/util/sort'

// 将 js 对象转换为 proto 对象
export function serializedLibraryContentMap(
    libraryContentMap: Map<LibraryId, LibraryContentVO>
): Record<string, Wukong.DocumentProto.ISerializedLibraryContentVO> {
    const root: Record<string, Wukong.DocumentProto.ISerializedLibraryContentVO> = {}

    for (const [libraryId, vo] of libraryContentMap) {
        const res: Wukong.DocumentProto.ISerializedLibraryContentVO = {}
        res.library = vo.library
        if (vo.library.shared) {
            res.styles = vo.styles.filter((v) => !v.removed)
            res.components = vo.components.filter((v) => !v.removed).sort(compareComponentVO)
            const variants = Object.values(vo.componentSetId2ChildrenComponents).flat(1)
            res.variants = variants.filter((v) => !v.removed && v.containerNodeId)
            res.componentSets = vo.componentSets.filter((v) => !v.removed).sort(compareComponentVO)
            try {
                res.variableSets = vo.variableSets
                    .filter((v) => !v.removed)
                    .sort(compareVariableSetVO)
                    .map((v) => ({
                        ...v,
                        modes: v.modes ? JSON.parse(v.modes) : [],
                        name: v.name ?? '',
                        nodeDataPath: v.nodeDataPath ?? '',
                        contentHash: v.contentHash ?? '',
                    }))
                res.variables = vo.variables
                    .filter((v) => !v.removed)
                    .map((v) => ({
                        ...v,
                        value: v.value ? JSON.parse(v.value) : {},
                        libraryId,
                        scope: v.scope ? JSON.parse(v.scope) : [],
                        name: v.name ?? '',
                        nodeDataPath: v.nodeDataPath ?? '',
                        contentHash: v.contentHash ?? '',
                    }))
            } catch (e) {
                console.error('变量数据解析失败:' + e)
            }

            res.movedComponentOldKey2NewKey = {}
            res.movedComponentSetOldKey2NewKey = {}
            res.movedStyleOldKey2NewKey = {}

            for (const oldKey of Object.keys(vo.libraryMovedInfos.styleMoveRemappings)) {
                if (res.styles.find((v) => v.id === oldKey)) {
                    res.movedStyleOldKey2NewKey[oldKey] = vo.libraryMovedInfos.styleMoveRemappings[oldKey]
                }
            }

            for (const oldKey of Object.keys(vo.libraryMovedInfos.componentMoveRemappings)) {
                if ([...res.components, ...res.variants].find((v) => v.id === oldKey)) {
                    res.movedComponentOldKey2NewKey[oldKey] = vo.libraryMovedInfos.componentMoveRemappings[oldKey]
                    continue
                }
                if (res.componentSets.find((v) => v.id === oldKey)) {
                    res.movedComponentSetOldKey2NewKey[oldKey] = vo.libraryMovedInfos.componentMoveRemappings[oldKey]
                }
            }

            root[libraryId] = res
        }
    }

    return root
}
