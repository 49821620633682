import { translation } from './comment-service.translation'
/* eslint-disable no-restricted-imports */
import {
    CallJsToAbortDrawingCommand,
    CallJsToAskForEditingContentProtected,
    CallJsToAskForEscHandled,
    CallJsToAsyncFocusAndActiveCommentWithAnimationCommand,
    CallJsToCreateCommentCommand,
    CallJsToGenerateTempCommentIdForCreating,
    CallJsToRemoveCommentCommand,
    CallJsToRemoveDraftCommand,
    CallJsToUpdateCommentMetaPositionCommand,
    ClickCommentClusterCommand,
    ClickOnCommentCommand,
    CopyCommentLinkCommand,
    DeactiveCommentCommand,
    FinishMovingCommentCommand,
    OnMovingCommentCommand,
    RemoveDraftCommentCommand,
    SetActivedCommentIdCommand,
    StartMovingCommentCommand,
    SubmitDraftCommentCommand,
    SyncCommentsMetaCommand,
    TryHoverOffTargetCommentCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { EditorState } from 'draft-js'
import { cloneDeep, isEqual } from 'lodash-es'
import { WKToast } from '../../../../../../ui-lib/src'
import { uuidv4 } from '../../../../../../util/src'
import { ClassWithEffect, EffectController } from '../../../../../../util/src/effect-controller'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import { Bridge } from '../../../../kernel/bridge/bridge'
import {
    CommentId,
    CommentPosition,
    CommentReaction,
    CommentReply,
    CommentThread,
    CommentUser,
    CommentUUID,
    CreateCommentPosition,
    Strategy,
} from '../../../../kernel/interface/comment'

import { TraceableAbortSignal } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { CommitType } from '../../../../document/command/commit-type'
import { CommentNotifyService, CommentProtoMessage } from '../../../../kernel/notify/comment-notify-service'
import { NotifyService } from '../../../../kernel/notify/notify-service'
import { Sentry } from '../../../../kernel/sentry'
import { featureSwitchManager } from '../../../../kernel/switch'
import { ViewportAnimationService } from '../../../../main/service/viewport-animation-service'
import { ViewStateBridge } from '../../../../view-state-bridge'
import { getMessagesFromEditorState } from '../comment-editor/draft-editor-utils'
import { ShowFilterType, SortType } from '../comment-panel/type'
import {
    fetchDeleteCommentOrReply,
    fetchDeleteReactions,
    fetchEditCommentOrReply,
    fetchEditCommentPosition,
    fetchGetAllComments,
    fetchGetContactsRecommend,
    fetchGetMailStrategy,
    fetchQueryCommentUser,
    fetchReplyReactions,
    fetchSetCommentsAsRead,
    fetchSetCommentsAsResolve,
    fetchSetCommentsAsUnread,
    fetchSetCommentsAsUnResolve,
    fetchSetMailStrategy,
} from '../comment-request'
import { ClusterId, Comment, CommentCluster, CommentWorldPosition, EditorType, Position } from '../type'
import {
    createCommentThread,
    createReply,
    generateTempCommentIdForCreating,
    getCommentHeadCount,
    getMentionUsersByMessages,
    getMentionUsersChangeState,
    isPreventExist,
    transformLocalMessageToOrigin,
    transformOriginMessageToLocal,
} from '../utils'
import { AnimationService } from './animation-service'
import { CommentOverlayPositionService } from './comment-overlay-position-service'
import {
    createCommentProtoToReply,
    createCommentProtoToThread,
    updateCommentProtoToReply,
    updateCommentProtoToThread,
} from './comment-proto-message'
import { CommentServiceStore } from './comment-service-store'
import { CreateCommentService } from './create-comment-service'
import { PositionService } from './position-service'
import { ReplyCommentService } from './reply-comment-service'

export class CommentService extends ClassWithEffect {
    // services
    createCommentService!: CreateCommentService
    replyCommentService!: ReplyCommentService
    overlayPositionService!: CommentOverlayPositionService
    protected commentNotifyService?: CommentNotifyService
    positionService: PositionService
    animationService: AnimationService
    store = new CommentServiceStore()

    // private states
    protected commentsMap: Map<CommentId, Comment> = new Map()
    protected commentClustersMap: Map<ClusterId, CommentCluster> = new Map()
    protected commentsInCluster: Set<CommentId> = new Set() // 用于过滤不用渲染的评论组件（被聚合的评论不用上屏）
    protected currentPageCommentIds: Set<CommentId> = new Set() // wasm 返回的当前页面实际参与显示的评论
    protected createReplyEditorState: EditorState | null = null
    protected editCommentOrReplyEditorState: EditorState | null = null
    protected createCommentEditorState: EditorState | null = null
    protected isPreventExistState = false
    protected escContinuedTimes = 0
    protected isMovingCreateComment = false
    protected currentUser: CommentUser | null = null
    protected lastFocusEditor: EditorType = EditorType.Null
    protected strWhenGetUsersResultEmpty = ''
    protected lastUpdateMentionUserRequest: PromiseWithCancel<any> | null = null
    protected rightClickCommentId: CommentId | undefined = undefined
    protected showComment = true
    protected pages: Wukong.DocumentProto.IVPageItem[] = []

    constructor(
        private readonly signal: TraceableAbortSignal,
        protected readonly viewStateBridge: ViewStateBridge,
        protected readonly commandInvoker: CommandInvoker,
        protected readonly bridge: Bridge,
        protected readonly notifyService: NotifyService,
        protected readonly viewportAnimationService: ViewportAnimationService,
        protected readonly docId: string,
        protected readonly orgId: string,
        controller: EffectController
    ) {
        super(controller)
        this.createCommentService = new CreateCommentService(commandInvoker)
        this.replyCommentService = new ReplyCommentService()
        this.overlayPositionService = new CommentOverlayPositionService(viewStateBridge)
        this.positionService = new PositionService(viewStateBridge)
        this.animationService = new AnimationService(this.signal, viewStateBridge)
        this.commentNotifyService = new CommentNotifyService(notifyService, docId, orgId)
        this.bindWasmCallJs()
    }

    init = () => {
        this.bindViewStates()
        this.positionService.init()
        this.overlayPositionService.init()
        this.animationService.init()
    }

    destroy = () => {
        this.positionService.destroy()
        this.overlayPositionService.destroy()
        this.animationService.destroy()

        this.viewStateBridge.unregister('currentPageId', this.updateViewCurrentPageId)
        this.viewStateBridge.unregister('drawingComment', this.updateViewDrawingComment)
        this.viewStateBridge.unregister('draftComment', this.updateViewDraftComment)
        this.viewStateBridge.unregister('activedComment', this.updateViewActiveComment)
        this.viewStateBridge.unregister('hoveredComment', this.updateViewHoveredComment)
        this.viewStateBridge.unregister('commentsPosition', this.updateViewCommentPosition)
        this.viewStateBridge.unregister('documentPageList', this.updateViewDocumentPageList)

        this.unbindWasmCallJs()
    }

    private bindViewStates = () => {
        this.viewStateBridge.register('currentPageId', this.updateViewCurrentPageId)
        this.viewStateBridge.register('drawingComment', this.updateViewDrawingComment)
        this.viewStateBridge.register('draftComment', this.updateViewDraftComment)
        this.viewStateBridge.register('activedComment', this.updateViewActiveComment)
        this.viewStateBridge.register('hoveredComment', this.updateViewHoveredComment)
        this.viewStateBridge.register('commentsPosition', this.updateViewCommentPosition)
        this.viewStateBridge.register('documentPageList', this.updateViewDocumentPageList)
    }

    private bindWasmCallJs = () => {
        this.bridge.bind(CallJsToUpdateCommentMetaPositionCommand, this.wasmUpdateCommentMetaPosition)
        this.bridge.bind(CallJsToCreateCommentCommand, this.wasmCreateComment)
        this.bridge.bind(CallJsToRemoveCommentCommand, this.wasmRemoveComment)
        this.bridge.bind(CallJsToRemoveDraftCommand, this.wasmRemoveDraftComment)
        this.bridge.bind(CallJsToAbortDrawingCommand, this.wasmAbortDrawingComment)
        this.bridge.bind(CallJsToAskForEscHandled, this.commentEsc)
        this.bridge.bind(
            CallJsToAsyncFocusAndActiveCommentWithAnimationCommand,
            this.asyncFocusAndActiveCommentWithAnimation,
            { signal: this.signal }
        )
        this.bridge.bind(
            CallJsToGenerateTempCommentIdForCreating,
            () => ({ value: generateTempCommentIdForCreating() } as Wukong.DocumentProto.IBridgeProtoString)
        )
        this.bridge.bind(
            CallJsToAskForEditingContentProtected,
            () => ({ value: this.isPreventExistState } as Wukong.DocumentProto.IBridgeProtoBoolean)
        )
    }

    private unbindWasmCallJs = () => {
        this.bridge.unbind(CallJsToUpdateCommentMetaPositionCommand)
        this.bridge.unbind(CallJsToCreateCommentCommand)
        this.bridge.unbind(CallJsToRemoveCommentCommand)
        this.bridge.unbind(CallJsToRemoveDraftCommand)
        this.bridge.unbind(CallJsToAbortDrawingCommand)
        this.bridge.unbind(CallJsToAskForEscHandled)
        this.bridge.unbind(CallJsToGenerateTempCommentIdForCreating)
        this.bridge.unbind(CallJsToAskForEditingContentProtected)
        this.bridge.unbind(CallJsToAsyncFocusAndActiveCommentWithAnimationCommand)
    }

    // view state start
    protected updateViewCurrentPageId = (key?: string) => {
        this.updateCommentReactState(key)
    }

    protected updateViewDrawingComment = (state: Wukong.DocumentProto.IVDrawingCommentViewState | null) => {
        if (!state?.display) {
            return this.store.set('drawingPosition', null)
        }
        const startPosition = {
            left: state.drawingStartPosition?.translateX ?? 0,
            top: state.drawingStartPosition?.translateY ?? 0,
        }
        this.store.set('drawingPosition', {
            startPosition,
            endPosition: {
                left: state.drawingEndPosition?.translateX ?? startPosition.left,
                top: state.drawingEndPosition?.translateY ?? startPosition.top,
            },
        })
    }

    protected updateViewDraftComment = (state: Wukong.DocumentProto.IVDraftCommentViewState | null) => {
        if (!state?.display) {
            this.updateCreateComment(null)
            return
        }
        const id = this.store.get('createComment')?.id ?? `${new Date().getTime()}`
        this.updateCreateComment({
            id,
            startPosition: state.hasAnchor
                ? {
                      left: state.commentAnchorPosition?.translateX ?? 0,
                      top: state.commentAnchorPosition?.translateY ?? 0,
                  }
                : null,
            endPosition: {
                left: state.commentPosition?.translateX ?? 0,
                top: state.commentPosition?.translateY ?? 0,
            },
        })
    }

    protected updateViewActiveComment = (state: Wukong.DocumentProto.IVActivedCommentViewState | null) => {
        const activeCommentId = typeof state?.commentId === 'string' ? Number(state.commentId) : undefined
        if (activeCommentId === this.store.get('activeCommentId')) {
            return
        }
        const isCloseCommentDetail = activeCommentId === undefined
        if (isCloseCommentDetail) {
            this.setCommentEditingContentProtected(false)
        }
        this.updateViewHoveredComment(state)
        this.updateViewPrevNextComment(state)
        this.createReplyEditorState = null
        this.closeEditCommentReply()
        this.updateActiveCommentId(activeCommentId)
    }

    protected updateViewPrevNextComment = (state: Wukong.DocumentProto.IVActivedCommentViewState | null) => {
        this.store.set(
            'prevCommentId',
            typeof state?.prevCommentId === 'string' ? Number(state.prevCommentId) : undefined
        )
        this.store.set(
            'nextCommentId',
            typeof state?.nextCommentId === 'string' ? Number(state.nextCommentId) : undefined
        )
    }

    protected updateViewHoveredComment = (state: Wukong.DocumentProto.IVActivedCommentViewState | null) => {
        this.store.set('hoverCommentId', state?.commentId === null ? undefined : Number(state?.commentId))
        const comment = this.commentsMap.get(state?.commentId === null ? NaN : Number(state?.commentId))
        if (!comment) {
            return
        }
        comment.overlayPosition.x = state?.commentPosition?.translateX ?? 0
        comment.overlayPosition.y = state?.commentPosition?.translateY ?? 0

        const hasAnchor = !!state?.hasAnchor
        if (hasAnchor) {
            comment.overlayPosition.hasAnchor = hasAnchor
            comment.overlayPosition.anchorWorldX = state?.commentAnchorPosition?.translateX ?? 0
            comment.overlayPosition.anchorWorldY = state?.commentAnchorPosition?.translateY ?? 0
            this.updateCommentReactState('overlayPosition')
        }
    }

    protected updateViewCommentPosition = (state: Wukong.DocumentProto.IVCommentsPositionViewState | null) => {
        const pins = state?.pins
        const clusters = state?.clusters
        if (!pins && !clusters) {
            return
        }

        this.currentPageCommentIds.clear()

        for (const [id, position] of Object.entries(pins ?? {})) {
            const targetComment = this.commentsMap.get(Number(id))
            if (!targetComment) {
                continue
            }
            targetComment.overlayPosition.x = position.translateX ?? 0
            targetComment.overlayPosition.y = position.translateY ?? 0
            targetComment.overlayPosition.hasAnchor = !!position.hasAnchor
            targetComment.overlayPosition.anchorWorldX = position.anchorTranslateX ?? 0
            targetComment.overlayPosition.anchorWorldY = position.anchorTranslateY ?? 0

            this.currentPageCommentIds.add(Number(id))
        }

        this.commentClustersMap.clear()
        this.commentsInCluster.clear()
        for (const [clusterId, cluster] of Object.entries(clusters ?? {})) {
            const commentIds: number[] = cluster.commentIds!.map((id) => Number(id))

            commentIds.forEach((commentId) => {
                this.commentsInCluster.add(commentId)
                this.currentPageCommentIds.add(commentId)
            })

            this.commentClustersMap.set(clusterId, {
                clusterId: clusterId,
                commentIds: commentIds,
                overlayPosition: {
                    x: cluster.cameraX ?? 0,
                    y: cluster.cameraY ?? 0,
                },
            })
        }
        this.updateCommentReactState('overlayPosition')
    }

    protected updateViewDocumentPageList = (state: Wukong.DocumentProto.IVDocumentPageList | null) => {
        this.pages = state?.pages ?? []
    }
    // view state end

    // wasm call start
    protected wasmUpdateCommentMetaPosition = (param: Wukong.DocumentProto.ICommentMetaPositionUpdateParam) => {
        const targetComment = this.commentsMap.get(Number(param.commentId))
        const updatePosition = param.commentMetaPosition
        if (!targetComment || !updatePosition) {
            return
        }
        const pageId = param.pageId ? param.pageId : targetComment?.commentMetaData.page
        const commentId = targetComment.commentMetaData.id
        const position = targetComment.commentMetaData.position
        const nextMetaPosition: CommentPosition = {
            commentId: commentId,
            nodeId: updatePosition.nodeId ?? '',
            hasAnchor: !!updatePosition.hasAnchor,
            x: updatePosition.x ?? position.x,
            y: updatePosition.y ?? position.y,
            offsetX: updatePosition.offsetX ?? position.offsetX,
            offsetY: updatePosition.offsetY ?? position.offsetY,
            anchorX: updatePosition.anchorX ?? position.anchorX,
            anchorY: updatePosition.anchorY ?? position.anchorY,
        }

        fetchEditCommentPosition(targetComment.commentMetaData.id, nextMetaPosition, pageId).catch((e) => {
            this.syncSingleCommentMeta(commentId)
            Sentry.captureException(e)
        })
    }

    tryHoverOffTargetComment = (commentId: CommentId) => {
        this.commandInvoker.invokeBridge(CommitType.Noop, TryHoverOffTargetCommentCommand, { value: String(commentId) })
    }

    protected wasmRemoveComment = (param: Wukong.DocumentProto.IBridgeProtoString) => {
        this.commentsMap.delete(Number(param.value))
        this.updateCommentReactState()
    }

    protected wasmRemoveDraftComment = () => {
        this.updateCreateComment(null)
        this.createCommentEditorState = null
        this.setCommentEditingContentProtected(false)
    }

    protected wasmAbortDrawingComment = () => {
        this.store.set('drawingPosition', null)
    }
    protected commentEsc = () => {
        if (this.hasOpenComment()) {
            this.checkKeyEventExist()
            return Wukong.DocumentProto.BridgeProtoBoolean.create({
                value: true,
            })
        }
        return Wukong.DocumentProto.BridgeProtoBoolean.create({
            value: false,
        })
    }

    private asyncFocusAndActiveCommentWithAnimation = async (
        param: Wukong.DocumentProto.IFocusAndActiveCommentWithAnimationParam
    ) => {
        await this.viewportAnimationService.startViewportAnimation(param.animationParam)
        if (this.signal.aborted) {
            return
        }
        this.commandInvoker.invokeBridge(CommitType.Noop, SetActivedCommentIdCommand, {
            value: param.commentId,
        })
    }

    // wasm call end

    startRequestComment = async () => {
        if (this.commentNotifyService) {
            const onConnectCallback = (disconnectedDuration?: number) => {
                if (disconnectedDuration !== undefined && disconnectedDuration < 10000) {
                    // 断线重连小于 10s，只重新拉取增量数据
                    this.fetchIncrementComments()
                    return
                } else {
                    this.fetchAllComments()
                }
            }
            const onMessageCallback = (comment: CommentProtoMessage) => {
                // thread 变更
                if (!comment.parentId) {
                    this.createCommentService.tryRemoveFailedComment(comment.uuid)
                    this.createCommentService.tryRemovePendingComment(comment.uuid)

                    // 删除 thread
                    if (comment.deleted) {
                        this.commentsMap.delete(comment.id)
                        this.syncSingleCommentMeta(comment.id)
                        this.updateCommentReactState()
                        return
                    }
                    const thisComment = this.commentsMap.get(comment.id)
                    // 更新 thread
                    if (thisComment) {
                        const merged = updateCommentProtoToThread(comment, thisComment.commentMetaData)
                        thisComment.commentMetaData = merged
                        this.commentsMap.set(merged.id, thisComment)
                        this.collectAllUser([merged])
                        this.syncSingleCommentMeta(merged.id)
                        this.updateCommentReactState()
                        return
                    }
                    // 新增 thread
                    if (!this.currentUser) {
                        return
                    }
                    const created = createCommentProtoToThread(comment, this.currentUser.id)
                    this.commentsMap.set(created.id, {
                        commentMetaData: created,
                        overlayPosition: createDefaultOverlayPosition(created.position),
                    })
                    this.collectAllUser([created])
                    this.syncSingleCommentMeta(created.id)
                    this.updateCommentReactState()
                    return
                }
                // reply 变更
                const thisThread = this.commentsMap.get(comment.parentId)
                if (!thisThread) {
                    return
                }
                this.replyCommentService.tryRemovePendingCreateReply(comment.parentId, comment.uuid)
                this.replyCommentService.tryRemovePendingEditReply(comment.uuid)
                const replies = thisThread.commentMetaData.replies
                if (comment.deleted) {
                    thisThread.commentMetaData.replies = replies.filter((reply) => reply.id !== comment.id)
                } else {
                    const targetReplyIndex = replies.findIndex((reply) => reply.id === comment.id)
                    if (targetReplyIndex !== -1) {
                        replies[targetReplyIndex] = updateCommentProtoToReply(comment, replies[targetReplyIndex])
                        thisThread.commentMetaData.replies = [...replies]
                    } else if (this.currentUser) {
                        thisThread.commentMetaData.replies = [
                            ...replies,
                            createCommentProtoToReply(comment, this.currentUser.id),
                        ]
                    }
                }
                this.commentsMap.set(thisThread.commentMetaData.id, thisThread)
                this.collectAllUser([thisThread.commentMetaData])
                this.syncSingleCommentMeta(thisThread.commentMetaData.id)
                this.updateCommentReactState()
            }

            this.commentNotifyService.connectWithSignal(this.signal, onConnectCallback, onMessageCallback)
        }
    }

    // 初始化数据 start
    // TODO: 移除这个方法
    updateCurrentDocId = () => {
        this.getCommentNoticeType(this.docId)
    }

    updateCurrentUser = (user: CommentUser) => {
        this.currentUser = user
    }

    // 初始化数据 end

    // 右击菜单 start
    rightClickComment = (commentId: CommentId) => {
        this.rightClickCommentId = commentId
    }
    rightClickResolve = () => {
        if (this.rightClickCommentId === undefined) {
            return
        }
        this.setCommentsAsResolve(this.rightClickCommentId)
    }
    rightClickUnResolve = () => {
        if (this.rightClickCommentId === undefined) {
            return
        }
        this.setCommentsAsUnResolve(this.rightClickCommentId)
    }
    rightClickUnread = () => {
        if (this.rightClickCommentId === undefined) {
            return
        }
        this.setCommentsAsUnread(this.rightClickCommentId)
    }
    rightClickCopyCommentLink = () => {
        if (this.rightClickCommentId === undefined) {
            return
        }
        this.copyCommentLink(this.rightClickCommentId)
    }
    isHideRightClickCopyCommentLink = () => {
        return this.rightClickCommentId === undefined
    }
    isHideRightClickCommentUnread = () => {
        if (this.rightClickCommentId === undefined) {
            return true
        }
        return !!this.commentsMap.get(this.rightClickCommentId)?.commentMetaData.unread
    }
    isHideRightClickCommentResolved = () => {
        if (this.rightClickCommentId === undefined) {
            return true
        }
        return !!this.commentsMap.get(this.rightClickCommentId)?.commentMetaData.resolved
    }
    isHideRightClickCommentUnResolved = () => {
        return !this.isHideRightClickCommentResolved()
    }
    // 右击菜单 end

    private fetchIncrementComments = async () => {
        try {
            const currentCommentThreads: CommentThread[] = []
            let currentMaxCommentId = -1
            this.commentsMap.forEach((comment, commentId) => {
                currentCommentThreads.push(comment.commentMetaData)
                currentMaxCommentId = Math.max(currentMaxCommentId, commentId)
            })

            const newCommentThreads = await fetchGetAllComments(this.docId, currentMaxCommentId)
            this.updateCommentsMap([...currentCommentThreads, ...newCommentThreads])
        } catch (e) {
            Sentry.captureException(e)
        }
    }

    protected fetchAllComments = async () => {
        try {
            const commentThreads = await fetchGetAllComments(this.docId)
            this.updateCommentsMap(commentThreads)
        } catch (e) {
            Sentry.captureException(e)
        }
    }

    private updateCommentsMap = (allCommentThreads: CommentThread[]) => {
        const newCommentsMap = new Map<CommentId, Comment>()
        for (const commentThread of allCommentThreads) {
            const { id, position, uuid } = commentThread

            this.createCommentService.tryRemoveFailedComment(uuid)
            this.createCommentService.tryRemovePendingComment(uuid)

            const overlayPosition = this.commentsMap.get(id)?.overlayPosition ?? createDefaultOverlayPosition(position)
            newCommentsMap.set(id, {
                commentMetaData: commentThread,
                overlayPosition,
            })
        }

        const oldCommentsMap = this.commentsMap
        this.commentsMap = newCommentsMap
        this.syncCommentsMeta(this.commentsMap, oldCommentsMap)

        this.collectAllUser(allCommentThreads)
        this.updateCommentReactState()
    }

    protected updateCommentReactState = (key?: string) => {
        switch (key) {
            case 'overlayPosition':
                this.updateCommentsListByOverlayPosition()
                this.updateCanvasCommentsReactState()
                this.updateCanvasCommentClustersReactState()
                return
            case 'sortType':
                return this.updateCommentsListBySortType()
            case 'showFilterType':
            case 'commentSearchString': {
                this.updateCommentsListReactState()
                // 向 wasm 同步所有评论的 visible，可能因已解决、与我相关、搜索而变化
                // 会在同一个 tick 触发 wasm 更新，然后回调回 overlayPosition
                this.syncCommentsVisible()
                return
            }
            default: {
                this.updateCommentsListReactState()
                this.updateCanvasCommentsReactState()
                this.updateCanvasCommentClustersReactState()
                this.updateHasUnreadCommentReactState()
                this.updateIsNoCommentsYetReact()
            }
        }
    }

    protected collectAllUser = (commentThreads: CommentThread[]) => {
        const newUserMap = new Map(this.store.get('usersMap'))
        for (const { owner, mentionedUsers, replies } of commentThreads) {
            newUserMap.set(owner.id, owner)
            mentionedUsers.forEach((user) => newUserMap.set(user.id, user))
            replies.forEach((reply) => {
                newUserMap.set(reply.owner.id, reply.owner)
                reply.mentionedUsers.forEach((user) => newUserMap.set(user.id, user))
            })
        }
        this.store.set('usersMap', newUserMap)
    }

    private filterCommentsByShowFilterType = (comments: Comment[]) => {
        const filters = this.store.get('commentShowFilters')
        if (!filters.includes(ShowFilterType.Resolved)) {
            comments = comments.filter((v) => !v.commentMetaData.resolved)
        }
        if (filters.includes(ShowFilterType.Relative)) {
            comments = comments.filter((v) => this.isCurrentUserInComment(v.commentMetaData))
        }
        return comments
    }

    private filterCommentsByNotClustered = (comments: Comment[]) => {
        comments = comments.filter((comment) => !this.commentsInCluster.has(comment.commentMetaData.id))
        return comments
    }

    private filterCommentsBySearchString = (comments: Comment[]) => {
        const searchString = this.store.get('commentSearchString')
        if (searchString) {
            comments = comments.filter((comment) => isMatchSearchComment(comment, searchString))
        }
        return comments
    }

    private sortCommentsBySortType = (comments: Comment[]) => {
        const sortType = this.store.get('commentSortType')
        const sort = (list: Comment[]) => {
            list.sort((a, b) => b.commentMetaData.createdTime - a.commentMetaData.createdTime)
        }
        if (sortType === SortType.Time) {
            sort(comments)
        } else if (sortType === SortType.Unread) {
            const unReads = [],
                reads = []
            for (const comment of comments) {
                const { unread, replies } = comment.commentMetaData
                isUnreadComment(unread, replies) ? unReads.push(comment) : reads.push(comment)
            }
            sort(unReads)
            sort(reads)
            comments = [...unReads, ...reads]
        }
        return comments
    }

    protected updateIsNoCommentsYetReact = () => {
        this.store.set('isNoCommentsYet', this.commentsMap.size === 0)
    }

    protected updateCommentsListReactState = () => {
        const filters = this.store.get('commentShowFilters')
        if (filters.includes(ShowFilterType.CurrentPage)) {
            return // 如果筛选了仅显示当前页，则属性面板交由 overlayPosition 更新
        }
        let filterComments = this.filterCommentsByShowFilterType([...this.commentsMap.values()])
        filterComments = this.filterCommentsBySearchString(filterComments)
        const finalCommentList = this.sortCommentsBySortType(filterComments)
        this.store.set('commentsList', finalCommentList)
    }

    protected updateCommentsListBySortType = () => {
        // 排序类型变更，重新排序属性面板的评论顺序
        let commentsList = this.store.get('commentsList')
        commentsList = this.sortCommentsBySortType(commentsList)
        this.store.set('commentsList', commentsList)
    }

    protected updateCommentsListByOverlayPosition = () => {
        const filters = this.store.get('commentShowFilters')
        if (!filters.includes(ShowFilterType.CurrentPage)) {
            return
        }

        // 如果筛选了仅显示当前页，则设置属性面板的评论为 commentsPosition 返回的当前页评论
        let commentsList: Comment[] = []
        this.currentPageCommentIds.forEach((commentId: CommentId) => {
            const comment = this.commentsMap.get(commentId)
            if (comment) {
                commentsList.push(comment)
            }
        })

        // 这里只需要排序，不需要再过滤，因为在 wasm 刷新之前，已经由 js 通过 commentVisible 同步了筛选结果
        commentsList = this.sortCommentsBySortType(commentsList)
        this.store.set('commentsList', commentsList)
    }

    protected updateCanvasCommentsReactState = () => {
        const commentsMap: Map<CommentId, Comment> = new Map()
        this.currentPageCommentIds.forEach((commentId: CommentId) => {
            const comment = this.commentsMap.get(commentId)
            if (comment && !this.commentsInCluster.has(commentId)) {
                if (featureSwitchManager.isEnabled('comment-perf-viewport-filter')) {
                    if (this.animationService.isVisableInViewport(commentId)) {
                        commentsMap.set(commentId, comment)
                    }
                } else {
                    commentsMap.set(commentId, comment)
                }
            }
        })
        this.store.set('canvasCommentsMap', commentsMap)
        this.updateCanvasPendingCommentsReactState()
    }

    private updateCanvasPendingCommentsReactState = () => {
        const pendingCommentsMap = this.createCommentService.getPendingComments()
        const canvasPendingCommentsMap = new Map<CommentId, Comment>()
        for (const [, comment] of pendingCommentsMap) {
            if (!this.commentsInCluster.has(comment.commentMetaData.id)) {
                canvasPendingCommentsMap.set(comment.commentMetaData.id, comment)
            }
        }
        this.store.set('canvasPendingCommentsMap', canvasPendingCommentsMap)
    }

    protected updateCanvasCommentClustersReactState = () => {
        this.store.set('canvasCommentClustersMap', new Map(this.commentClustersMap))
    }

    protected updateHasUnreadCommentReactState = () => {
        const currentPageIdSet = new Set(this.pages.map((page) => page.id))
        const hasUnread = [...this.commentsMap.values()].some(
            ({ commentMetaData: { page, resolved, unread, replies } }) =>
                !resolved && currentPageIdSet.has(page) && isUnreadComment(unread, replies)
        )
        this.store.set('hasUnreadComment', hasUnread)
    }

    private getUpdatedComments = (newCommentsMap: Map<CommentId, Comment>, oldCommentsMap: Map<CommentId, Comment>) => {
        const diffMetaMap: Map<CommentId, Wukong.DocumentProto.ICommentAspect> = new Map()

        newCommentsMap.forEach((newComment) => {
            const oldComment = oldCommentsMap.get(newComment.commentMetaData.id)
            const commentAspect = oldComment
                ? this.getCommentAspect(newComment, oldComment)
                : this.getCommentAspect(newComment)
            if (commentAspect) {
                diffMetaMap.set(newComment.commentMetaData.id, commentAspect)
            }
        })

        return Object.fromEntries(diffMetaMap)
    }

    private getRemovedComments = (newCommentsMap: Map<CommentId, Comment>, oldCommentsMap: Map<CommentId, Comment>) => {
        const removedComments: string[] = []
        const idsSet = new Set(newCommentsMap.keys())
        oldCommentsMap.forEach((_, key) => {
            if (!idsSet.has(key)) {
                removedComments.push(key.toString())
            }
        })
        return removedComments
    }

    private isCommentVisible(comment: Comment): boolean {
        let filterComments = this.filterCommentsByShowFilterType([comment])
        filterComments = this.filterCommentsBySearchString(filterComments)

        // 如果评论没有被筛选条件过滤掉，则说明可见
        return filterComments.length === 1
    }

    private updateCreateComment(
        createComment: {
            id: string
            startPosition: Position | null
            endPosition: Position
        } | null
    ) {
        const oldId = this.store.get('createComment')?.id
        const newId = createComment?.id
        if (oldId !== newId) {
            this.lastUpdateMentionUserRequest?.cancel()
        }
        this.store.set('createComment', createComment)
    }

    private updateActiveCommentId = (activeCommentId: CommentId | undefined) => {
        this.lastUpdateMentionUserRequest?.cancel()
        this.store.set('activeCommentId', activeCommentId)
    }

    protected syncCommentsVisible = () => {
        const allComments = [...this.commentsMap.values()]
        const updatedComments: Map<CommentId, Wukong.DocumentProto.ICommentAspect> = new Map()

        allComments.forEach((comment) => {
            const commentAspect = this.getCommentAspect(comment)
            updatedComments.set(comment.commentMetaData.id, commentAspect)
        })

        this.commandInvoker.DEPRECATED_invokeBridge(SyncCommentsMetaCommand, {
            updatedComments: Object.fromEntries(updatedComments),
        })
    }

    protected syncCommentsMeta = (newCommentsMap: Map<CommentId, Comment>, oldCommentsMap: Map<CommentId, Comment>) => {
        const updatedComments = this.getUpdatedComments(newCommentsMap, oldCommentsMap)
        const removedComments = this.getRemovedComments(newCommentsMap, oldCommentsMap)
        this.commandInvoker.DEPRECATED_invokeBridge(SyncCommentsMetaCommand, {
            updatedComments,
            removedComments,
        })
    }

    protected syncSingleCommentMeta = (commentId: CommentId) => {
        const comment = this.commentsMap.get(commentId)
        const id = commentId.toString()
        if (comment) {
            const commentAspect = this.getCommentAspect(comment)
            this.commandInvoker.DEPRECATED_invokeBridge(SyncCommentsMetaCommand, {
                updatedComments: { [id]: commentAspect },
            })
        } else {
            this.commandInvoker.DEPRECATED_invokeBridge(SyncCommentsMetaCommand, {
                removedComments: [id],
            })
        }
    }

    clickOnComment = (commentId: CommentId) => {
        this.checkMouseEventExist()
        this.commandInvoker.DEPRECATED_invokeBridge(ClickOnCommentCommand, {
            value: String(commentId),
        })
    }

    isCurrentUser = (userId: CommentUser['id']) => {
        return this.currentUser?.id === userId
    }

    isCurrentUserInComment = (commentMetaData: CommentThread) => {
        const { owner, mentionedUsers, replies } = commentMetaData
        return (
            this.isCurrentUser(owner.id) ||
            mentionedUsers.some((v) => this.isCurrentUser(v.id)) ||
            replies.some(
                (reply) =>
                    this.isCurrentUser(reply.owner.id) || reply.mentionedUsers.some((v) => this.isCurrentUser(v.id))
            )
        )
    }

    updateShowComment = (showComment: boolean) => {
        this.showComment = showComment
        this.closeEmojiPick()
        this.closeMentionUser()
    }

    getCreateCommentInitialMessages = () => {
        return this.createCommentEditorState ? getMessagesFromEditorState(this.createCommentEditorState) : undefined
    }
    getCreateReplyInitialMessages = () => {
        return this.createReplyEditorState ? getMessagesFromEditorState(this.createReplyEditorState) : undefined
    }
    getEditCommentOrReplyEditorState = () => {
        return this.editCommentOrReplyEditorState
            ? getMessagesFromEditorState(this.editCommentOrReplyEditorState)
            : undefined
    }

    editorFocus = (type: EditorType) => {
        this.lastFocusEditor = type
    }

    editorBlur = () => {
        this.lastFocusEditor = EditorType.Null
        this.closeMentionUser()
        this.closeEmojiPick()
    }

    openEmojiPick = (editorType: EditorType) => {
        this.store.set('insertEmojiTarget', editorType)
    }

    updateInsertEmojiRect = (editRect: DOMRect, containerRect: DOMRect) => {
        this.store.set('insertEmojiRect', { editRect, containerRect })
    }

    updateInsertEmoji = (shortName: string) => {
        this.store.set('insertEmoji', shortName)
    }

    insertEmojiDone = () => {
        this.store.set('insertEmoji', undefined)
        this.closeEmojiPick()
    }

    clickCluster = (clusterId: ClusterId) => {
        this.commandInvoker.DEPRECATED_invokeBridge(ClickCommentClusterCommand, {
            clusterId,
        })
    }

    // CommentAnchor => MovingTargetType::Comment
    updateCommentAnchorStart = (commentId: CommentId) => {
        const comment = this.commentsMap.get(commentId)
        if (!comment) {
            return
        }
        // WCC_startMovingComment
        const params = {
            targetType: Wukong.DocumentProto.MovingTargetType.MOVING_TARGET_TYPE_COMMENT,
            targetCommentId: String(commentId),
        }
        this.commandInvoker.DEPRECATED_invokeBridge(StartMovingCommentCommand, params)
    }

    updateCommentAnchor = (commentId: CommentId, position: Position) => {
        const comment = this.commentsMap.get(commentId)
        if (!comment) {
            return
        }
        // WCC_movingComment
        const params = {
            position: {
                translateX: position.left,
                translateY: position.top,
            },
        }
        this.commandInvoker.DEPRECATED_invokeBridge(OnMovingCommentCommand, params)
    }

    updateCommentAnchorEnd = (commentId: CommentId) => {
        const overlayPosition = this.commentsMap.get(commentId)?.overlayPosition
        if (!overlayPosition) {
            return
        }
        this.commandInvoker.DEPRECATED_invokeBridge(FinishMovingCommentCommand)
    }

    // CommentMinorAnchor => MovingTargetType::CommentAnchor
    updateCommentMinorAnchorStart = (commentId: CommentId) => {
        const comment = this.commentsMap.get(commentId)
        if (!comment?.overlayPosition.hasAnchor) {
            return
        }
        // WCC_startMovingComment
        const params = {
            targetType: Wukong.DocumentProto.MovingTargetType.MOVING_TARGET_TYPE_COMMENT_ANCHOR,
            targetCommentId: String(commentId),
        }
        this.commandInvoker.DEPRECATED_invokeBridge(StartMovingCommentCommand, params)
    }

    updateCommentMinorAnchor = (commentId: CommentId, position: Position) => {
        const comment = this.commentsMap.get(commentId)
        if (!comment?.overlayPosition.hasAnchor) {
            return
        }
        // WCC_movingComment
        const params = {
            position: {
                translateX: position.left,
                translateY: position.top,
            },
        }
        this.commandInvoker.DEPRECATED_invokeBridge(OnMovingCommentCommand, params)
    }

    updateCommentMinorAnchorEnd = (commentId: CommentId) => {
        const overlayPosition = this.commentsMap.get(commentId)?.overlayPosition
        if (!overlayPosition?.hasAnchor) {
            return
        }
        this.commandInvoker.DEPRECATED_invokeBridge(FinishMovingCommentCommand)
    }

    // CreateCommentAnchor => MovingTargetType::DraftComment
    updateCreateCommentAnchorStart = () => {
        const createComment = this.store.get('createComment')
        if (!createComment) return
        // WCC_startMovingComment
        const params = {
            targetType: Wukong.DocumentProto.MovingTargetType.MOVING_TARGET_TYPE_DRAFT_COMMENT,
            targetCommentId: undefined,
        }
        this.commandInvoker.DEPRECATED_invokeBridge(StartMovingCommentCommand, params)
    }

    updateCreateCommentAnchor = (position: Position) => {
        const createComment = this.store.get('createComment')
        if (createComment) {
            // this.positionService.updateBubble(undefined, position, createComment.startPosition)
            this.updateCreateComment({
                ...createComment,
                endPosition: position,
            })
            this.commandInvoker.DEPRECATED_invokeBridge(OnMovingCommentCommand, {
                position: {
                    translateX: position.left,
                    translateY: position.top,
                },
            })
        }
    }
    updateCreateCommentAnchorEnd = () => {
        const endPosition = this.store.get('createComment')?.endPosition
        if (!endPosition) {
            return
        }
        this.commandInvoker.DEPRECATED_invokeBridge(FinishMovingCommentCommand)
    }

    // CreateCommentMinorAnchor => MovingTargetType::DraftCommentAnchor
    updateCreateCommentMinorAnchorStart = () => {
        const createComment = this.store.get('createComment')
        if (!createComment) return
        // WCC_startMovingComment
        const params = {
            targetType: Wukong.DocumentProto.MovingTargetType.MOVING_TARGET_TYPE_DRAFT_COMMENT_ANCHOR,
            targetCommentId: undefined,
        }
        this.commandInvoker.DEPRECATED_invokeBridge(StartMovingCommentCommand, params)
    }

    updateCreateCommentMinorAnchor = (position: Position) => {
        const createComment = this.store.get('createComment')
        if (createComment) {
            // this.positionService.updateBubble(undefined, createComment.endPosition, position)
            this.updateCreateComment({
                ...createComment,
                startPosition: position,
            })
            this.commandInvoker.DEPRECATED_invokeBridge(OnMovingCommentCommand, {
                position: {
                    translateX: position.left,
                    translateY: position.top,
                },
            })
        }
    }
    updateCreateCommentMinorAnchorEnd = () => {
        const startPosition = this.store.get('createComment')?.startPosition
        if (!startPosition) {
            return
        }
        this.commandInvoker.DEPRECATED_invokeBridge(FinishMovingCommentCommand)
    }

    updateCommentCreateMessage = (editorState: EditorState | null) => {
        this.escContinuedTimes = 0
        this.createCommentEditorState = editorState
        this.closeEmojiPick()
    }

    updateCreateReplyMessage = (editorState: EditorState | null) => {
        this.escContinuedTimes = 0
        this.createReplyEditorState = editorState
        this.closeEmojiPick()
    }

    updateEditCommentOrReplyMessage = (editorState: EditorState | null) => {
        this.escContinuedTimes = 0
        this.editCommentOrReplyEditorState = editorState
        this.closeEmojiPick()
    }

    submitCreateComment = () => {
        this.bridge.call(SubmitDraftCommentCommand)
    }

    private setCommentEditingContentProtected = (isPreventExistState: boolean) => {
        this.isPreventExistState = isPreventExistState
    }

    protected wasmCreateComment = (param: Wukong.DocumentProto.ICommentCreateParam) => {
        const createComment = this.store.get('createComment')
        if (!this.currentUser || !this.createCommentEditorState || !createComment) {
            return
        }
        const position: CreateCommentPosition = {
            commentId: param.commentId ? parseInt(param.commentId) : 0,
            nodeId: param.commentMetaPosition?.nodeId ?? '',
            hasAnchor: !!param.commentMetaPosition?.hasAnchor,
            x: param.commentMetaPosition?.x ?? 0,
            y: param.commentMetaPosition?.y ?? 0,
            offsetX: param.commentMetaPosition?.offsetX ?? 0,
            offsetY: param.commentMetaPosition?.offsetY ?? 0,
            anchorX: param.commentMetaPosition?.anchorX ?? 0,
            anchorY: param.commentMetaPosition?.anchorY ?? 0,
        }
        const messages = getMessagesFromEditorState(this.createCommentEditorState)
        const pendingCommentThread = createCommentThread({
            id: position.commentId,
            docId: this.docId,
            uuid: uuidv4(),
            page: this.viewStateBridge.getDefaultValue('currentPageId'),
            owner: this.currentUser,
            message: transformLocalMessageToOrigin(messages),
            messageText: this.createCommentEditorState.getCurrentContent().getPlainText(),
            position,
            mentionedUsers: getMentionUsersByMessages(messages, this.store.get('usersMap')),
        })
        this.createCommentService
            .requestCreateComment({
                commentMetaData: pendingCommentThread,
                overlayPosition: {
                    x: createComment.endPosition.left,
                    y: createComment.endPosition.top,
                    hasAnchor: !!createComment.startPosition,
                    anchorWorldX: createComment.startPosition?.left ?? 0,
                    anchorWorldY: createComment.startPosition?.top ?? 0,
                },
            })
            .then((comment?: Comment) => {
                if (comment) {
                    const id = comment.commentMetaData.id
                    this.commentsMap.set(id, comment)
                    this.syncSingleCommentMeta(id)
                    this.updateCommentReactState()
                }
            })
    }

    retrySubmitCreateComment = (commentUUID: CommentUUID) => {
        for (const comment of this.commentsMap.values()) {
            if (comment.commentMetaData.uuid === commentUUID) {
                // 重试时发现该评论已经被创建好了，则直接删除该失败评论的记录
                this.createCommentService.tryRemoveFailedComment(commentUUID)
                return
            }
        }

        const failedComment = this.createCommentService.getFailedComments().get(commentUUID)
        if (!failedComment) {
            return
        }

        // 将失败评论存储为 pending 评论
        this.createCommentService.storePendingComment(failedComment)
        this.createCommentService.tryRemoveFailedComment(commentUUID)

        // 向 wasm 创建 pending 评论的节点
        this.commandInvoker.DEPRECATED_invokeBridge(SyncCommentsMetaCommand, {
            updatedComments: { [failedComment.commentMetaData.id]: this.getCommentAspect(failedComment) },
        })

        // 重发创建请求，成功或失败后均会删除 wasm 中对应的 pending 节点
        this.createCommentService.requestCreateComment(failedComment).then((comment?: Comment) => {
            if (comment) {
                const id = comment.commentMetaData.id
                this.commentsMap.set(id, comment)
                this.syncSingleCommentMeta(id)
                this.updateCommentReactState()
            }
        })
    }

    updateIsStopSyncActiveCommentPosition = (isStop: boolean) => {
        this.store.set('isStopSyncActiveCommentPosition', isStop)
    }

    fastReply = (commentUser: CommentUser) => {
        let isFastInsert = true
        if (this.lastFocusEditor === EditorType.CreateReply) {
            this.store.set('insertMentionUserInCreateReply', commentUser)
        } else if (this.lastFocusEditor === EditorType.EditReply) {
            this.store.set('insertMentionUserInEditReply', commentUser)
        } else if (this.store.get('activeCommentId')) {
            if (this.store.get('editingReply')) {
                this.store.set('insertMentionUserInEditReply', commentUser)
            } else {
                this.store.set('insertMentionUserInCreateReply', commentUser)
            }
        } else {
            isFastInsert = false
        }
        this.store.set('isFastInsertMentionUser', isFastInsert)
    }
    searchMentionUsers = async (queryString: string) => {
        this.lastUpdateMentionUserRequest?.cancel()
        this.updatePreSelectedMentionUserIndex(0)
        if (this.strWhenGetUsersResultEmpty && queryString.startsWith(this.strWhenGetUsersResultEmpty)) {
            this.updateMentionUserList([])
            return
        }
        if (queryString === '') {
            this.lastUpdateMentionUserRequest = waitPromiseWithCancel(this.getContactsList(this.docId))
            const contactsUsers = await this.lastUpdateMentionUserRequest.finish
            if (contactsUsers.length > 0) {
                this.updateMentionUserList(contactsUsers)
                return
            }
        }

        this.lastUpdateMentionUserRequest = waitPromiseWithCancel(fetchQueryCommentUser(this.docId, queryString))
        this.lastUpdateMentionUserRequest.finish
            .then((commentUsers: CommentUser[]) => {
                if (!commentUsers.length) {
                    this.strWhenGetUsersResultEmpty = queryString
                }
                const newUserMap = new Map(this.store.get('usersMap'))
                commentUsers.forEach((user) => newUserMap.set(user.id, user))
                this.store.set('usersMap', newUserMap)
                this.updateMentionUserList(commentUsers.length > 15 ? commentUsers.slice(0, 15) : commentUsers)
            })
            .catch((e) => {
                this.updateMentionUserList([])
                Sentry.captureException(e)
            })
    }

    updateMentionUsersRect = (splitLineRect: DOMRect, containerRect: DOMRect) => {
        this.store.set('mentionUsersRect', {
            splitLineRect,
            containerRect,
        })
    }

    getCommentById(commentId: CommentId) {
        if (this.commentsMap.has(commentId)) {
            return this.commentsMap.get(commentId)
        }
        const pendingComments = this.createCommentService.getPendingComments()
        for (const [, comment] of pendingComments) {
            if (comment.commentMetaData.id === commentId) {
                return comment
            }
        }
        return null
    }

    protected updateMentionUserList = (userList: CommentUser[]) => {
        this.store.set('mentionUserList', userList)
        this.store.set('isShowMentionUserList', userList.length > 0)
    }

    updatePreSelectedMentionUserIndex = (index: number) => {
        this.store.set('preSelectedMentionUserIndex', index)
    }

    protected updatePreSelectedMentionUserIndexByKey = (key: 'ArrowDown' | 'ArrowUp') => {
        const maxIndex = this.store.get('mentionUserList').length - 1
        const delta = key === 'ArrowDown' ? 1 : -1
        let index = this.store.get('preSelectedMentionUserIndex') + delta
        index = index < 0 ? maxIndex : index > maxIndex ? 0 : index
        this.updatePreSelectedMentionUserIndex(index)
    }

    mentionKeyEvent = (e: React.KeyboardEvent): void => {
        switch (e.key) {
            case 'ArrowUp':
            case 'ArrowDown':
                return this.updatePreSelectedMentionUserIndexByKey(e.key)
            case 'ArrowLeft':
            case 'ArrowRight':
                return this.closeMentionUser()
            case 'Enter':
            case 'Tab':
                return this.applySelectedMentionUser()
            case 'Escape': {
                this.store.get('isShowMentionUserList') ? this.closeMentionUser() : this.checkKeyEventExist()
                return
            }
        }
    }

    applySelectedMentionUser = () => {
        const commentUser = this.store.get('mentionUserList')[this.store.get('preSelectedMentionUserIndex')]
        if (commentUser) {
            if (this.lastFocusEditor === EditorType.CreateComment) {
                this.store.set('insertMentionUserInCreateComment', commentUser)
            } else if (this.lastFocusEditor === EditorType.CreateReply) {
                this.store.set('insertMentionUserInCreateReply', commentUser)
            } else if (this.lastFocusEditor === EditorType.EditReply) {
                this.store.set('insertMentionUserInEditReply', commentUser)
            } else if (this.store.get('activeCommentId')) {
                if (this.store.get('editingReply')) {
                    this.store.set('insertMentionUserInEditReply', commentUser)
                } else {
                    this.store.set('insertMentionUserInCreateReply', commentUser)
                }
            } else if (this.store.get('createComment')) {
                this.store.set('insertMentionUserInCreateComment', commentUser)
            }
        }
        this.updateMentionUserList([])
    }

    insertMentionUserDone = () => {
        this.store.set('isFastInsertMentionUser', false)
        this.store.set('insertMentionUserInCreateComment', null)
        this.store.set('insertMentionUserInCreateReply', null)
        this.store.set('insertMentionUserInEditReply', null)
    }

    replaceCreateReplyMessagesDone = () => {
        this.store.set('replaceCreateReplyMessages', null)
    }

    editReply = (commentId: CommentId, commentReply: CommentReply) => {
        this.closeEditCommentReply()
        this.store.set('editingReply', { commentId, commentReply })
    }

    submitEditCommentOrReply = async () => {
        const activeCommentId = this.store.get('activeCommentId')
        const editingReply = this.store.get('editingReply')
        const editComment = editingReply ? this.commentsMap.get(editingReply.commentId) : undefined

        if (activeCommentId === undefined || !editComment || !editingReply || !this.editCommentOrReplyEditorState) {
            return
        }
        const isEditComment = editingReply.commentId === editingReply.commentReply.id
        const messages = getMessagesFromEditorState(this.editCommentOrReplyEditorState)
        const message = transformLocalMessageToOrigin(messages)
        const messageText = this.editCommentOrReplyEditorState.getCurrentContent().getPlainText()
        const pendingReply = cloneDeep(editingReply.commentReply)
        pendingReply.message = message
        pendingReply.mentionedUsers = getMentionUsersByMessages(messages, this.store.get('usersMap'))
        const { addedUserIds, removedUserIds } = getMentionUsersChangeState(editingReply.commentReply, pendingReply)
        this.replyCommentService.addPendingEditReply(pendingReply)
        this.closeEditCommentReply()

        await fetchEditCommentOrReply(editingReply.commentReply.id, {
            message,
            messageText,
            addedUserIds,
            removedUserIds,
        })
            .then(() => {
                const updatedTime = new Date().getTime()
                if (isEditComment) {
                    editComment.commentMetaData.message = message
                    editComment.commentMetaData.messageText = messageText
                    editComment.commentMetaData.updatedTime = updatedTime
                } else {
                    for (const reply of editComment.commentMetaData.replies) {
                        if (reply.id === editingReply.commentReply.id) {
                            reply.message = message
                            reply.messageText = messageText
                            reply.updatedTime = updatedTime
                            break
                        }
                    }
                }
                this.syncSingleCommentMeta(editComment.commentMetaData.id)
                this.updateCommentReactState()
            })
            .catch((e) => {
                if (
                    this.store.get('activeCommentId') === activeCommentId &&
                    this.replyCommentService.getPendingEditReplies().size === 1
                ) {
                    this.editReply(editingReply.commentId, pendingReply)
                }
                WKToast.error(translation('InGOlQ'))
                Sentry.captureException(e)
            })
            .finally(() => {
                this.replyCommentService.tryRemovePendingEditReply(editingReply.commentReply.uuid)
            })
    }

    submitCreateReply = () => {
        const activeCommentId = this.store.get('activeCommentId')
        if (activeCommentId === undefined || !this.currentUser || !this.createReplyEditorState) {
            return
        }
        const messages = getMessagesFromEditorState(this.createReplyEditorState)
        const pendingReply = createReply({
            uuid: uuidv4(),
            message: transformLocalMessageToOrigin(messages),
            messageText: this.createReplyEditorState.getCurrentContent().getPlainText(),
            owner: this.currentUser,
            mentionedUsers: getMentionUsersByMessages(messages, this.store.get('usersMap')),
        })
        this.store.set('replaceCreateReplyMessages', [])
        this.replyCommentService
            .requestCreateReply(activeCommentId, pendingReply)
            .then((commentReply?: CommentReply) => {
                if (commentReply) {
                    const targetComment = this.commentsMap.get(activeCommentId)
                    const replies = targetComment?.commentMetaData.replies
                    if (targetComment && replies && !replies.some((reply) => reply.id === commentReply.id)) {
                        targetComment.commentMetaData.replies = [...replies, commentReply]
                        this.updateCommentReactState()
                    }
                } else {
                    WKToast.error(translation('rJkbhc'))
                    if (
                        this.store.get('activeCommentId') === activeCommentId &&
                        this.replyCommentService.isAllowRestorePending(activeCommentId, pendingReply.uuid)
                    ) {
                        this.store.set(
                            'replaceCreateReplyMessages',
                            transformOriginMessageToLocal(pendingReply.message)
                        )
                    }
                    this.replyCommentService.tryRemovePendingCreateReply(activeCommentId, pendingReply.uuid)
                }
            })
    }

    closeEditCommentReply = () => {
        this.store.set('editingReply', null)
        this.editCommentOrReplyEditorState = null
    }

    closeMentionUser = () => {
        this.updateMentionUserList([])
    }

    closeEmojiPick = () => {
        this.store.set('insertEmojiTarget', EditorType.Null)
    }

    closeCreateComment = () => {
        this.commandInvoker.DEPRECATED_invokeBridge(RemoveDraftCommentCommand)
    }
    closeCommentDetail = () => {
        this.commandInvoker.DEPRECATED_invokeBridge(DeactiveCommentCommand)
    }

    selectAllDone = () => {
        this.store.set('selectAllInCreateComment', false)
        this.store.set('selectAllInCreateReply', false)
        this.store.set('selectAllInEditReply', false)
    }

    shakeAnimationEnd = () => {
        this.store.set('needShake', false)
    }

    focusPrevComment = () => {
        const prevCommentId = this.store.get('prevCommentId')
        if (typeof prevCommentId != 'number') {
            return
        }
        this.clickOnComment(prevCommentId)
    }

    focusNextComment = () => {
        const nextCommentId = this.store.get('nextCommentId')
        if (typeof nextCommentId != 'number') {
            return
        }
        this.clickOnComment(nextCommentId)
    }

    /**
     * @param e
     * @returns true: 关闭被阻止;false: 关闭被允许;
     */
    checkMouseEventExist = () => {
        const isDetailModalOpen = this.store.get('activeCommentId') !== undefined
        const isCreateModalOpen = this.store.get('createComment') !== null
        if (isDetailModalOpen) {
            this.isPreventExistState =
                isPreventExist(this.createReplyEditorState) || isPreventExist(this.editCommentOrReplyEditorState)
            if (this.isPreventExistState) {
                if (this.store.get('editingReply')) {
                    this.store.set('selectAllInEditReply', true)
                } else {
                    this.store.set('selectAllInCreateReply', true)
                }
            }
        } else if (isCreateModalOpen) {
            this.isPreventExistState = isPreventExist(this.createCommentEditorState)
            if (this.isPreventExistState) {
                this.store.set('selectAllInCreateComment', true)
            }
        } else {
            this.isPreventExistState = false
        }
        if (this.isPreventExistState) {
            this.store.set('needShake', true)
        }
        this.setCommentEditingContentProtected(this.isPreventExistState)
        return this.isPreventExistState
    }

    checkKeyEventExist = () => {
        this.escContinuedTimes += 1
        if (this.escContinuedTimes <= 1) {
            if (this.checkMouseEventExist()) {
                return true
            }
        } else {
            this.isPreventExistState = false
            this.setCommentEditingContentProtected(this.isPreventExistState)
        }
        if (this.store.get('activeCommentId')) {
            if (this.store.get('editingReply')) {
                this.closeEditCommentReply()
            } else {
                setTimeout(this.closeCommentDetail)
            }
        } else if (this.store.get('createComment')) {
            setTimeout(this.closeCreateComment)
        }
        this.escContinuedTimes = 0
        return false
    }

    hasOpenComment = () => {
        const isOpenCreatePopup = this.store.get('createComment') !== null
        const isOpenDetailPopup = this.store.get('activeCommentId') !== undefined
        return this.showComment && (isOpenCreatePopup || isOpenDetailPopup)
    }

    closeOpenComment = () => {
        if (!this.showComment) {
            return
        }
        const isOpenCreatePopup = this.store.get('createComment') !== null
        if (isOpenCreatePopup) {
            this.closeCreateComment()
        }

        const isOpenDetailPopup = this.store.get('activeCommentId') !== undefined
        if (isOpenDetailPopup) {
            this.closeCommentDetail()
        }
    }

    updateCommentAsRead = (commentId: CommentId) => {
        const commentMetaData = this.commentsMap.get(commentId)?.commentMetaData
        if (!commentMetaData) {
            return
        }
        const unread = this.commentsMap.get(commentId)?.commentMetaData.unread
        const replies = commentMetaData.replies.filter((reply) => reply.unread)
        if (unread || replies.length > 0) {
            this.setCommentsAsRead(
                commentId,
                replies.map((reply) => reply.id)
            )
        }
    }

    setCommentsAsRead = (commentId: CommentId, repliesId: CommentId[]) => {
        fetchSetCommentsAsRead([commentId, ...repliesId])
            .then(() => {
                const targetComment = this.commentsMap.get(commentId)
                if (targetComment) {
                    targetComment.commentMetaData.unread = false
                    targetComment.commentMetaData.replies = targetComment.commentMetaData.replies.map(
                        (reply) => ((reply.unread = false), reply)
                    )
                    this.syncSingleCommentMeta(commentId)
                    this.updateCommentReactState()
                }
            })
            .catch((e) => {
                WKToast.error(translation('cjYUjt'))
                Sentry.captureException(e)
            })
    }

    setCommentsAsUnread = (commentId: CommentId) => {
        fetchSetCommentsAsUnread([commentId])
            .then(() => {
                const targetComment = this.commentsMap.get(commentId)
                if (targetComment) {
                    targetComment.commentMetaData.unread = true
                    this.syncSingleCommentMeta(commentId)
                    this.updateCommentReactState()
                }
            })
            .catch((e) => {
                WKToast.error(translation('bOpBff'))
                Sentry.captureException(e)
            })
    }

    setCommentsAsResolve = (commentId: CommentId, isImmediate = false) => {
        this.closeCommentDetail()
        const targetComment = this.commentsMap.get(commentId)
        if (!targetComment || targetComment.commentMetaData.resolved) {
            return
        }
        if (isImmediate) {
            targetComment.commentMetaData.resolved = true
            this.syncSingleCommentMeta(commentId)
            this.updateCommentReactState()
        }
        fetchSetCommentsAsResolve(commentId).catch((e) => {
            WKToast.error(translation('ENxogC'))
            Sentry.captureException(e)
            const initialComment = this.commentsMap.get(commentId)
            if (!initialComment?.commentMetaData.resolved) {
                return
            }
            initialComment.commentMetaData.resolved = false
            this.syncSingleCommentMeta(commentId)
            this.updateCommentReactState()
        })
    }

    setCommentsAsUnResolve = (commentId: CommentId) => {
        this.closeCommentDetail()
        fetchSetCommentsAsUnResolve(commentId).catch((e) => {
            WKToast.error(translation('LyfVCa'))
            Sentry.captureException(e)
        })
    }

    deleteComment = (commentId: CommentId) => {
        fetchDeleteCommentOrReply(commentId).catch((e) => {
            WKToast.error(translation('QlOqkM'))
            Sentry.captureException(e)
        })
    }
    deleteReply = (commentId: number, replyId: number) => {
        fetchDeleteCommentOrReply(replyId).catch((e) => {
            WKToast.error(translation('hGzgLY'))
            Sentry.captureException(e)
        })
    }

    getContactsList = async (documentId: string) => {
        try {
            const contactsList = await fetchGetContactsRecommend(documentId)
            const newUserMap = new Map(this.store.get('usersMap'))
            contactsList.forEach((user) => newUserMap.set(user.id, user))
            this.store.set('usersMap', newUserMap)
            return contactsList.length > 5 ? contactsList.slice(0, 5) : contactsList
        } catch (e) {
            Sentry.captureException(e)
            return [] as CommentUser[]
        }
    }

    updateCommentReplyEmoji = (commentId: CommentId, commentReplyId: CommentReply['id'], emojiId: string) => {
        const comment = this.commentsMap.get(commentId)
        const currentUser = this.currentUser
        if (!comment || !currentUser) {
            return
        }
        const isComment = commentId === commentReplyId
        let targetReaction: CommentReaction | undefined
        if (isComment) {
            const reactions = comment.commentMetaData.reactions
            targetReaction = reactions.find(
                (reaction) => reaction.message === emojiId && reaction.owner.id === currentUser.id
            )
            if (targetReaction) {
                fetchDeleteReactions(targetReaction.id).catch(Sentry.captureException)
            } else {
                fetchReplyReactions(commentReplyId, emojiId).catch((e) => {
                    WKToast.error(translation('uSXmHF'))
                    Sentry.captureException(e)
                })
            }
        } else {
            const commentReply = comment.commentMetaData.replies.find((reply) => reply.id === commentReplyId)
            if (!commentReply) {
                return
            }
            const reactions = commentReply.reactions
            targetReaction = reactions.find(
                (reaction) => reaction.message === emojiId && reaction.owner.id === currentUser.id
            )
            if (targetReaction) {
                fetchDeleteReactions(targetReaction.id).catch(Sentry.captureException)
            } else {
                fetchReplyReactions(commentReplyId, emojiId).catch((e) => {
                    WKToast.error(translation('uSXmHF'))
                    Sentry.captureException(e)
                })
            }
        }
    }

    protected getCommentNoticeType = (documentId: string) => {
        fetchGetMailStrategy(documentId)
            .then((data) => this.store.set('commentNoticeType', data))
            .catch((e) => Sentry.captureException(e))
    }

    setCommentNoticeType = (type: Strategy) => {
        const docId = this.docId
        const currentType = this.store.get('commentNoticeType')
        if (!docId || currentType === type) {
            return
        }
        this.store.set('commentNoticeType', type)
        fetchSetMailStrategy(docId, type).catch((e) => {
            this.store.set('commentNoticeType', currentType)
            Sentry.captureException(e)
        })
    }

    copyCommentLink = (commentId: CommentId) => {
        this.commandInvoker.DEPRECATED_invokeBridge(CopyCommentLinkCommand, {
            value: `${commentId}`,
        })
        WKToast.show(translation('LinkCopiedTo'))
    }

    setCommentSortType = (value: SortType) => {
        this.store.set('commentSortType', value)
        this.updateCommentReactState('sortType')
    }

    setCommentShowFilters = (value: ShowFilterType[]) => {
        this.store.set('commentShowFilters', value)
        this.updateCommentReactState('showFilterType')
    }

    setCommentSearchString = (value: string) => {
        this.store.set('commentSearchString', value)
        this.updateCommentReactState('commentSearchString')
    }

    private getCommentAspect(newComment: Comment): Wukong.DocumentProto.ICommentAspect
    private getCommentAspect(newComment: Comment, oldComment: Comment): Wukong.DocumentProto.ICommentAspect | null
    private getCommentAspect(newComment: Comment, oldComment?: Comment) {
        const newMeta = newComment.commentMetaData
        const oldMeta = oldComment?.commentMetaData

        const { page, resolved, position } = newMeta
        if (oldMeta && oldMeta.page === page && oldMeta.resolved === resolved && isEqual(oldMeta.position, position)) {
            return null
        }
        const commentAspect: Wukong.DocumentProto.ICommentAspect = {
            commentPageId: page,
            commentMetaPosition: position,
            commentCreatedTime: newMeta.createdTime,
            commentHeadCount: getCommentHeadCount(newMeta),
            commentVisible: this.isCommentVisible(newComment), // 向 wasm 同步之前计算 comment 的 visible 状态
        }
        return commentAspect
    }
}

function createDefaultOverlayPosition(position: CommentPosition): CommentWorldPosition {
    const { x, y, hasAnchor, anchorX, anchorY } = position
    return {
        x,
        y,
        hasAnchor,
        anchorWorldX: anchorX,
        anchorWorldY: anchorY,
    }
}

function isMatchSearchComment(comment: Comment, searchString: string): boolean {
    const { commentMetaData } = comment
    if (commentMetaData.messageText.includes(searchString)) {
        return true
    }
    if (commentMetaData.owner.nickname.includes(searchString)) {
        return true
    }
    if (
        commentMetaData.replies.some(
            (reply) => reply.messageText.includes(searchString) || reply.owner.nickname.includes(searchString)
        )
    ) {
        return true
    }
    return false
}

export function isUnreadComment(unread: boolean, replies: CommentReply[]) {
    return unread || replies.some((reply) => reply.unread)
}

interface PromiseWithCancel<T> {
    finish: Promise<T>
    cancel: () => void
}
function waitPromiseWithCancel<T>(callFn: Promise<T>): PromiseWithCancel<T> {
    let canceled = false

    const finish = new Promise<T>((resolve, reject) => {
        callFn.then((v) => {
            if (canceled) return
            resolve(v)
        })

        callFn.catch((e) => {
            if (canceled) return
            reject(e)
        })
    })

    const cancel = () => {
        canceled = true
    }

    return {
        finish,
        cancel,
    }
}
