import ReactMarkdown from 'react-markdown'
import remarkBreaks from 'remark-breaks'
import remarkGfm from 'remark-gfm'

export const ChatbotTextArea = ({ text }: { text: string }) => {
    return (
        <div className="color-$wk-v2-label-color-gray-13 text-13px lh-22px font-400 select-text [&_>*:not(:last-child)]:mb-8px [&_>*:last-child]:m-0!">
            <ReactMarkdown remarkPlugins={[remarkGfm, remarkBreaks]}>{text}</ReactMarkdown>
        </div>
    )
}
