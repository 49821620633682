import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import { RotateCopyEndPreviewCommandForJs, Wukong } from '@wukong/bridge-proto'
import { useCallback, useEffect, useRef, useState } from 'react'
import { MonoIconCommonQuestion16, Tooltip, WKButton } from '../../../../../../ui-lib/src'
import { useCommand, usePluginService } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { ReactComponent as RotateCopyIcon } from '../../../assets/plugins/rotate-copy.svg'
import { ScrubbableInputProps } from '../../atom/inputs/components/scrubbable-input'
import { ScrubbableInputDegree } from '../../atom/inputs/scrubbable-input-degree'
import { ScrubbableInputInteger } from '../../atom/inputs/scrubbable-input-integer'
import { ScrubbableInputNumber } from '../../atom/inputs/scrubbable-input-number'
import { getLdap, logs } from '../log'
import { Manifest, PluginExported, PluginModalProps } from '../type'
import {
    useCancelPreview,
    useFinishPreview,
    usePreviewCommand,
    useStartPreview,
    useUpdateCenterPoint,
} from './commands'
import styles from './index.module.less'
import { ReactComponent as PlaceholderImg } from './select-layer.svg'

const Prefix = (content: any) => `rotate-copy-${content}`
export const RotateCopyTestIds = {
    StartPreview: Prefix('start-preview'),
    CancelPreview: Prefix('cancel-preview'),
    FinishPreview: Prefix('finish-preview'),
}

export function RotateCopyPlugin(_: PluginModalProps) {
    // 统计曝光次数
    useEffect(() => {
        // trace
        {
            logs.Plugins.rotatecopy({ ldap: getLdap() })
        }
    }, [])

    const baseInputProps: Partial<ScrubbableInputProps> = {
        leftScrubbable: true,
        rightScrubbable: true,
    }
    const command = useCommand()

    const previewCommand = usePreviewCommand()
    const apply = (newCopyCount: number, newRadius: number, newRotateAngle: number) => {
        const data = {
            radius: newRadius,
            totalRotateAngle: newRotateAngle,
            copyCount: newCopyCount,
        }
        previewCommand(Wukong.DocumentProto.PluginRotateCopyData.create(data))
    }
    const startPreview = useStartPreview()
    const cancelCommand = useCancelPreview()
    const finishCommand = useFinishPreview()
    const finish = () => {
        finishCommand()
        _.closeModal()
    }

    const updateCenterPointCmd = useUpdateCenterPoint()
    const updateCenterPoint = (value: number) => {
        updateCenterPointCmd(value)
    }

    const viewState = useViewState('pluginRotateCopyViewState')
    const [radius, setRadius] = useState(0)
    const [selectedLayerName, setSelectedLayerName] = useState('')
    const [inPreview, setInPreview] = useState(false)
    const [placeholderText, setPlaceholderText] = useState(translation('SelectALayer'))
    useEffect(() => {
        setRadius(viewState?.radius ? viewState.radius : 0)
        setSelectedLayerName(viewState?.baseNodeName ? viewState.baseNodeName : '')
        setInPreview(viewState?.inPreviewStage ? viewState.inPreviewStage : false)
        setPlaceholderText(viewState?.validNodeSelected ? translation('Selected') : translation('SelectALayer'))
    }, [viewState])

    const initRotateAngle = 360
    const initCopyCount = 6

    const [rotateAngle, setRotateAngle] = useState(initRotateAngle)
    const [copyCount, setCopyCount] = useState(initCopyCount)

    const triggerRef1 = useRef<HTMLSpanElement>(null)
    const triggerRect1 = useCallback(() => {
        return triggerRef1.current!.getBoundingClientRect()
    }, [])
    const triggerRef2 = useRef<HTMLSpanElement>(null)
    const triggerRect2 = useCallback(() => {
        return triggerRef2.current!.getBoundingClientRect()
    }, [])
    const pluginService = usePluginService()

    useEffect(() => {
        pluginService.injectActivatedPluginCloseFn(() => {
            if (viewState?.inPreviewStage) {
                command.DEPRECATED_invokeBridge(RotateCopyEndPreviewCommandForJs, {
                    state: Wukong.DocumentProto.RotateCopyEndState.ROTATE_COPY_END_STATE_FINISH,
                })
            }
            return pluginService.clearActivatedPluginCloseFn
        })
    })

    const resetState = useCallback(() => {
        setRotateAngle(initRotateAngle)
        setCopyCount(initCopyCount)
    }, [])

    if (!inPreview) {
        return (
            <div className={styles.selectLayerPanel}>
                <PlaceholderImg className={styles.image} />
                <div className={styles.textContainer}>
                    <span className={styles.emptyText}>{placeholderText}</span>
                    <span className={styles.layerName}>&nbsp;{selectedLayerName}</span>
                </div>
                <WKButton
                    type="primary"
                    disabled={!viewState?.validNodeSelected}
                    className={styles.startButton}
                    onClick={() => {
                        // trace
                        {
                            logs.Plugins.rotatecopyStartCopy({ ldap: getLdap() })
                        }

                        startPreview()
                    }}
                    data-testid={RotateCopyTestIds.StartPreview}
                >
                    {translation('Start')}
                </WKButton>
            </div>
        )
    }

    return (
        <div className={styles.configurePanel}>
            <div className={styles.contents}>
                {/* {translation('Layer')} */}
                <div className={styles.layerTextContainer}>
                    <span className={styles.attrNameText}>{translation('Layer')}</span>
                    <span className={styles.layerText}>{selectedLayerName}</span>
                </div>
                {/* {translation('Count')} */}
                <div className={styles.repeatCountContainer}>
                    <span className={styles.attrNameText}>{translation('Count')}</span>
                    <ScrubbableInputInteger
                        className={styles.input}
                        {...baseInputProps}
                        value={copyCount}
                        min={1}
                        max={1000}
                        onChange={(value) => {
                            setCopyCount(value as number)
                            apply(value as number, radius, rotateAngle)
                        }}
                    />
                </div>
                {/* {translation('Radius')} */}
                <div className={styles.repeatCountContainer}>
                    <span className={styles.attrNameText}>{translation('Radius')}</span>
                    <Tooltip title={translation('DistanceFromGeometric')} triggerRect={triggerRect1}>
                        <div className="w-24px h-24px flex justify-center items-center -ml-11px">
                            <span style={{ lineHeight: 0 }} ref={triggerRef1}>
                                <MonoIconCommonQuestion16 className="color-$wk-v2-label-color-gray-8" />
                            </span>
                        </div>
                    </Tooltip>
                    <ScrubbableInputNumber
                        {...baseInputProps}
                        className={styles.input}
                        value={radius}
                        min={0}
                        max={10000}
                        onChange={(value) => {
                            updateCenterPoint(value as number)
                            setRadius(value as number)
                            apply(copyCount, value as number, rotateAngle)
                        }}
                    />
                </div>
                {/* {translation('Sweep')} */}
                <div className={styles.repeatCountContainer}>
                    <span className={styles.attrNameText}>{translation('Sweep')}</span>
                    <Tooltip title={translation('AngleRangeFor')} triggerRect={triggerRect2}>
                        <div className="w-24px h-24px flex justify-center items-center -ml-11px">
                            <span style={{ lineHeight: 0 }} ref={triggerRef2}>
                                <MonoIconCommonQuestion16 className="color-$wk-v2-label-color-gray-8" />
                            </span>
                        </div>
                    </Tooltip>
                    <ScrubbableInputDegree
                        {...baseInputProps}
                        className={styles.input}
                        value={(rotateAngle / 180) * Math.PI}
                        min={0}
                        max={360}
                        onChange={(value) => {
                            setRotateAngle(value as number)
                            apply(copyCount, radius, value as number)
                        }}
                    />
                </div>
                {/* buttons */}
                <div className={styles.btnContainer}>
                    <WKButton
                        type="secondary"
                        className={styles.cancelBtn}
                        onClick={() => {
                            // trace
                            {
                                logs.Plugins.rotatecopyCancel({ ldap: getLdap() })
                            }

                            resetState()
                            cancelCommand()
                        }}
                        data-testid={RotateCopyTestIds.CancelPreview}
                    >
                        {translation('Cancel')}
                    </WKButton>
                    <WKButton
                        type="primary"
                        className={styles.finishBtn}
                        onClick={() => {
                            // trace
                            {
                                logs.Plugins.rotatecopyDone({ ldap: getLdap() })
                            }

                            finish()
                        }}
                        data-testid={RotateCopyTestIds.FinishPreview}
                    >
                        {translation('Done')}
                    </WKButton>
                </div>
            </div>
        </div>
    )
}

export const manifest: Manifest = {
    key: Wukong.DocumentProto.PluginType.PLUGIN_TYPE_ROTATE_COPY,
    name: translation('RotateCopies'),
    width: 280,
    height: 312,
    icon: <RotateCopyIcon />,
}

export default {
    manifest,
    Component: RotateCopyPlugin,
} as unknown as PluginExported
