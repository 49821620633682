import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import { CreatePageCommandWasmCall, Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { IconAdd16, IconArrowDown, IconArrowUp, IconSearch, ScrollView, Tooltip } from '../../../../../ui-lib/src'
import { ShortcutKey, shortcutLabelMap } from '../../../kernel/interface/shortcut-key'
import { usePagePanelService, usePlanAndUserStateService } from '../../../main/app-context'
import { useUserConfigStateV2 } from '../../../main/user-config/user-config-hook'
import { PayUpgradeDialogFrom } from '../../../share/payment/pay-upgrade-dialog/pay-upgrade-dialog-struct/type'
import { PayUpgradeUpperPageNumLimit } from '../../../share/payment/pay-upgrade-scene-modal'
import { isStarterPlan } from '../../../utils/plan-status-checker'
import { useViewState } from '../../../view-state-bridge'
import { useCommand } from '../../context/document-context'
import { IconButton } from '../atom/button/icon-button'
import { useCanvasSearchParamModel } from '../canvas-search-panel/use-canvas-search-model'
import { RightClickMenuWrapper } from '../right-click-menu-wrapper'
import { PAGE_LIMIT_NUM, PageLimitTip } from './components/page-limit-tip'
import { PagePanelItemList } from './components/page-panel-item'
import { PageResizeLine } from './components/page-resize-line'
import styles from './index.module.less'
import VPageItem = Wukong.DocumentProto.VPageItem

// 2023 07 05: Update: 根据WK-17692, 这里的最小高度调整为可展示一页半左右的行高, 也就是48px.
const EXPANDED_MIN_HEIGHT = 48

// 用户信息无法判断不存在，临时 hack 一下
const usePagePanelCollapsed = (defaultValue: boolean) => {
    const [pageCollapsedV2, setPageCollapsedV2] = useUserConfigStateV2('pagePanelCollapsed')

    const shouldCollapsed = pageCollapsedV2 === null ? defaultValue : pageCollapsedV2

    return [shouldCollapsed, setPageCollapsedV2] as const
}

// 能否考虑将组件拆分, 减少useMemo中的内容.
export const PagePanel: FC<{ pages: VPageItem[] }> = ({ pages }) => {
    const command = useCommand()

    const currentPage: VPageItem = pages.find(({ isSelected }) => !!isSelected)!

    const docReadonly = useViewState('docReadonly')

    const [pagePanelCollapsed, setPageCollapsedV2] = usePagePanelCollapsed(pages.length <= 1)

    const [customHeightFlag, setCustomHeightFlag] = useUserConfigStateV2('pagePanelCustomHeightFlag')

    const [customHeight, setCustomHeight] = useUserConfigStateV2('pagePanelHeight')

    const pagePanelService = usePagePanelService()

    const [isScroll, setIsScroll] = useState<boolean>(false)

    const pagePanelRef = useRef<HTMLDivElement>(null)

    // const pageLimitTipRef = useRef<HTMLDivElement>(null)
    const [pageLimitTipHeight, setPageLimitTipHeight] = useState<number>(0)

    const pageLimitTipRef = useCallback((el: HTMLDivElement | null) => {
        setPageLimitTipHeight(el?.clientHeight ?? 0)
    }, [])

    const computedHeight = useMemo<number>(() => {
        if (!customHeightFlag) {
            // 自适应高度，最小高度为 EXPANDED_MIN_HEIGHT, 最大高度为 innterHeight / 4 - EXPANDED_MIN_HEIGHT
            return Math.min(
                pages.length * 32 + 16 + pageLimitTipHeight,
                0.25 * window.innerHeight - EXPANDED_MIN_HEIGHT
            )
        }
        // 用户手动调节的高度
        if (customHeight < EXPANDED_MIN_HEIGHT) {
            return EXPANDED_MIN_HEIGHT
        }
        // 依次为 topArea / tab / layerPanel
        // TODO: 加了只读模式需要把 tab 高度变成选填
        const maxHeight = window.innerHeight - 48 - (docReadonly ? 0 : 44) - 30
        if (customHeight > maxHeight) {
            return maxHeight
        }
        return customHeight
    }, [customHeightFlag, customHeight, docReadonly, pages.length, pageLimitTipHeight])

    const planAndUserStateService = usePlanAndUserStateService()
    const planInfo = planAndUserStateService.useZustandStore.use.planAndUserState()

    const isFreeTeam = !!(planInfo && isStarterPlan(planInfo))

    const createPage = useCallback(() => {
        if (isFreeTeam && pages.length >= PAGE_LIMIT_NUM) {
            return PayUpgradeUpperPageNumLimit({
                from: PayUpgradeDialogFrom.Editor,
                teamId: planInfo?.resourceId,
            })
        }
        // 通过 cmd 创建 page
        command.DEPRECATED_invokeBridge(CreatePageCommandWasmCall)
        setPageCollapsedV2(false)
        command.commitUndo()
    }, [isFreeTeam, pages.length, command, planInfo?.resourceId, setPageCollapsedV2])

    const onScroll = useCallback((e: React.UIEvent) => {
        setIsScroll(e.currentTarget.scrollTop !== 0)
    }, [])

    const handlePagePanelHeaderRightClick = useCallback(() => {
        pagePanelService.setLastSelectedPageForRightClick(currentPage.id)
    }, [currentPage, pagePanelService])

    useEffect(() => {
        pagePanelService.setPagePanelCollapsed(pagePanelCollapsed)
        pagePanelService.setExpandPagePanelFn(() => {
            setPageCollapsedV2(false)
        })
    }, [pagePanelService, pagePanelCollapsed, setPageCollapsedV2])

    const canvasSearchParamModel = useCanvasSearchParamModel()

    const shouldScroll = pagePanelCollapsed ? false : isScroll

    return useMemo(
        () => (
            <>
                <RightClickMenuWrapper
                    source={Wukong.DocumentProto.RightClickSource.RIGHT_CLICK_SOURCE_DESIGN_MODE_PAGE_PANEL}
                    onMouseDown={handlePagePanelHeaderRightClick}
                    stopPropagation
                >
                    <div
                        className={classNames(
                            styles.pageTabContainer,
                            styles.coverLayerPanel,
                            pagePanelCollapsed && styles.shadow
                        )}
                        style={{
                            flex: 'none',
                        }}
                        data-testid="page-panel-header"
                    >
                        <div className={styles.pageTab}>
                            <div className={classNames(styles.text, styles.strong)}>
                                {pagePanelCollapsed ? currentPage.name : translation('Pages')}
                            </div>

                            <div className={styles.iconContainer}>
                                {docReadonly ? (
                                    <Tooltip
                                        title={translation('Find…')}
                                        shortcut={shortcutLabelMap[ShortcutKey.CanvasSearch]}
                                    >
                                        <IconButton
                                            dataTestId="canvas-search-button"
                                            icon={<IconSearch />}
                                            className={styles.searchIcon}
                                            selected={false}
                                            onClick={canvasSearchParamModel.modelCommand.startSearch}
                                        />
                                    </Tooltip>
                                ) : null}
                                {!docReadonly && (
                                    <Tooltip title={translation('AddNewPage')}>
                                        <IconButton
                                            data-testid="add-page"
                                            className={styles.addIcon}
                                            icon={<IconAdd16 />}
                                            selected={false}
                                            onClick={createPage}
                                        />
                                    </Tooltip>
                                )}
                                <Tooltip
                                    title={
                                        pagePanelCollapsed ? translation('OpenPageList') : translation('ClosePageList')
                                    }
                                >
                                    <IconButton
                                        data-testid="toggle-page-list-collapsed"
                                        icon={pagePanelCollapsed ? <IconArrowDown /> : <IconArrowUp />}
                                        selected={false}
                                        onClick={() => {
                                            setPageCollapsedV2(!pagePanelCollapsed)
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </RightClickMenuWrapper>

                {pagePanelCollapsed ? null : (
                    <>
                        {shouldScroll ? <div className={styles.splitLine}></div> : null}
                        <div
                            className={classNames(styles.pagePanel, styles.coverLayerPanel)}
                            style={{ height: computedHeight + 'px' }}
                            data-testid="editor-pange-panel-collapsed-list"
                            ref={pagePanelRef}
                        >
                            <ScrollView
                                scrollbar={{ onScroll }}
                                selectKey={pages.find((p) => p.isSelected)?.id}
                                block="nearest"
                            >
                                <PagePanelItemList items={pages as VPageItem[]} />
                                <PageLimitTip ref={pageLimitTipRef} pageNum={pages.length} isFreeTeam={isFreeTeam} />
                            </ScrollView>
                        </div>
                        <PageResizeLine
                            setCustomHeight={setCustomHeight}
                            setCustomHeightFlag={setCustomHeightFlag}
                            height={computedHeight}
                            pagePanelRef={pagePanelRef}
                        />
                    </>
                )}
            </>
        ),
        [
            handlePagePanelHeaderRightClick,
            pagePanelCollapsed,
            currentPage?.name,
            docReadonly,
            canvasSearchParamModel.modelCommand.startSearch,
            createPage,
            shouldScroll,
            computedHeight,
            onScroll,
            pages,
            pageLimitTipRef,
            isFreeTeam,
            setCustomHeight,
            setCustomHeightFlag,
            setPageCollapsedV2,
        ]
    )
}
