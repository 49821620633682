import { WKButton } from '../../../../../../ui-lib/src'
import { translation } from './ai-search-result-error.translation'
import { AiSearchResultModel } from './use-ai-search'
export function AISearchResultError({ model }: { model: AiSearchResultModel }) {
    return (
        <div className="mt-162px py-40px flex flex-col items-center gap-20px">
            <div className="wk-font-regular wk-text-14 text-gray text-center">{translation('Error')}</div>
            <WKButton
                className="w-72px"
                type="primary"
                onClick={() => model.onRetry()}
                dataTestId="ai-search-restul-retry"
            >
                {translation('Retry')}
            </WKButton>
        </div>
    )
}
