import { FC, SVGAttributes, useMemo } from 'react'
import { environment } from '../../../../app/src/environment'
import {
    StandardLogo48,
    StandardLogo48Inland,
    StandardLogoWhite48,
    StandardLogoWhite48Inland,
    StarndardLogo20,
    StarndardLogo20Inland,
    StarndardLogo24,
    StarndardLogo24Inland,
    StarndardLogo26,
    StarndardLogo26Inland,
    StarndardLogo32,
    StarndardLogo32Inland,
    StarndardLogoWhite20,
    StarndardLogoWhite20Inland,
    StarndardLogoWhite24,
    StarndardLogoWhite24Inland,
    StarndardLogoWhite26,
    StarndardLogoWhite26Inland,
    StarndardLogoWhite32,
    StarndardLogoWhite32Inland,
} from './svg'

// 带 Motiff 文字
export interface WKStandardLogoProps extends SVGAttributes<SVGSVGElement> {
    height: 20 | 24 | 26 | 32 | 48
    type: 'normal' | 'white'
    env?: 'Inland' | 'Abroad' | 'Auto'
    fontColor?: string
}
export const WKStandardLogo: FC<WKStandardLogoProps> = ({ fontColor, style, height, type, env = 'Auto', ...rest }) => {
    const isAbroad = useMemo(() => {
        switch (env) {
            case 'Inland':
                return false
            case 'Abroad': {
                return true
            }
            case 'Auto': {
                return environment.isAbroad
            }
        }
    }, [env])

    const attrs = useMemo(() => {
        return {
            style: { ...style, color: fontColor || 'rgb(0, 0, 0)' },
            ...rest,
        }
    }, [fontColor, rest, style])

    switch (height) {
        case 20:
            if (type == 'white') {
                if (!isAbroad) {
                    return <StarndardLogoWhite20Inland {...attrs} />
                }
                return <StarndardLogoWhite20 {...attrs} />
            } else {
                if (!isAbroad) {
                    return <StarndardLogo20Inland {...attrs} />
                }
                return <StarndardLogo20 {...attrs} />
            }
        case 24:
            if (type == 'white') {
                if (!isAbroad) {
                    return <StarndardLogoWhite24Inland {...attrs} />
                }
                return <StarndardLogoWhite24 {...attrs} />
            } else {
                if (!isAbroad) {
                    return <StarndardLogo24Inland {...attrs} />
                }
                return <StarndardLogo24 {...attrs} />
            }
        case 26:
            if (type == 'white') {
                if (!isAbroad) {
                    return <StarndardLogoWhite26Inland {...attrs} />
                }
                return <StarndardLogoWhite26 {...attrs} />
            } else {
                if (!isAbroad) {
                    return <StarndardLogo26Inland {...attrs} />
                }
                return <StarndardLogo26 {...attrs} />
            }
        case 32:
            if (type == 'white') {
                if (!isAbroad) {
                    return <StarndardLogoWhite32Inland {...attrs} />
                }
                return <StarndardLogoWhite32 {...attrs} />
            } else {
                if (!isAbroad) {
                    return <StarndardLogo32Inland {...attrs} />
                }
                return <StarndardLogo32 {...attrs} />
            }
        case 48:
            if (type == 'white') {
                if (!isAbroad) {
                    return <StandardLogoWhite48Inland {...attrs} />
                }
                return <StandardLogoWhite48 {...attrs} />
            } else {
                if (!isAbroad) {
                    return <StandardLogo48Inland {...attrs} />
                }
                return <StandardLogo48 {...attrs} />
            }
    }
}
