import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { ForwardedRef, forwardRef, useImperativeHandle, useRef, useState } from 'react'
import {
    InputV2,
    MonoIconCommonAdd16,
    MonoIconCommonRemove16,
    MonoIconLayerInstance16,
    Scrollbar,
    Select,
    SimpleDrag,
    Tooltip,
    WKButton,
    WKIconButton,
    WKToast,
} from '../../../../../../../../../ui-lib/src'
import { PopupStateType } from '../../../../../../../document/node/node'
import { LibraryThumbnailImage } from '../../../../../../../share/component-style-library/library-thumbnail-image'
import { useViewState } from '../../../../../../../view-state-bridge'
import { ComponentPropTestId } from '../../../../../../../window'
import { SingleGrid } from '../../../../../atom/grid/single-grid'
import { Title } from '../../../../../atom/title/title'
import {
    Arg_cmdEditComponentPropData,
    Arg_validateInstanceSwapValue,
    ComponentPropType,
    InstanceSwapPreferredValue,
    InstanceSwapPreferredValueType,
    ReplaceInstanceNodeData,
    VComponentPropDef,
    ValidateInstanceSwapValueScene,
    VariableAnyValue,
    type ComponentPropEditPopupVal,
} from '../../../types'
import { ComponentPicker, ComponentPickerTrigger } from '../../component-picker'
import { convertRealComponentPropType2Virtual } from '../../component-prop-pill/base-component-prop-pill'
import { ICreateComponentProp } from '../../component-prop/hook'
import styles from './index.module.less'
import { translation } from './index.translation'

function PreferredValuesList(props: {
    popupData: VComponentPropDef
    preferedValueListClassName: string
    preferredValuesPanelVisible: boolean
    closeComponentPicker: () => void
    updatePreferredValues: (instanceSwapValues: InstanceSwapPreferredValue[]) => void
    toggleComponentPickerVisible: (trigger: ComponentPickerTrigger, triggerRef: React.RefObject<HTMLDivElement>) => void
}) {
    const {
        popupData,
        preferedValueListClassName,
        preferredValuesPanelVisible,
        closeComponentPicker,
        updatePreferredValues,
        toggleComponentPickerVisible,
    } = props

    const preferredValuesWrapperRef = useRef<HTMLDivElement>(null)
    const [selectedDragItems, setSelectedDragItems] = useState<number[]>([])

    return (
        <div className={styles.preferredValuesList} ref={preferredValuesWrapperRef}>
            <Title
                className={styles.preferredValuesTitle}
                grayTheme={popupData.instanceSwapPreferredValues.length === 0 && !preferredValuesPanelVisible}
                onClick={(e) => {
                    e.stopPropagation() // 防止冒泡到 wasm 侧重置弹窗
                    toggleComponentPickerVisible(ComponentPickerTrigger.PreferredValues, preferredValuesWrapperRef)
                }}
            >
                <Title.Left>{translation('PreferredValues')}</Title.Left>
                <Title.Right>
                    <Tooltip title={translation('SelectPreferredValues')}>
                        <WKIconButton
                            data-testid={ComponentPropTestId.ComponentPicker.preferredValuesAddBtn}
                            icon={<MonoIconCommonAdd16 />}
                            type={preferredValuesPanelVisible ? 'deepBlue' : 'secondary'}
                        />
                    </Tooltip>
                </Title.Right>
            </Title>
            {!!popupData.instanceSwapPreferredValues.length && (
                <Scrollbar autoHeight autoHeightMax={296}>
                    <SimpleDrag
                        selectedIndexList={selectedDragItems}
                        items={popupData.instanceSwapPreferredValues}
                        onSelectChange={(items) => setSelectedDragItems(items)}
                        onDragDone={(newPreferredValues, newSelectedIndexItems) => {
                            updatePreferredValues(newPreferredValues)
                            setSelectedDragItems(newSelectedIndexItems)
                        }}
                        onPointerDown={(e) => e.stopPropagation()} // 防止最外层弹窗被拖拽
                        dataTestIds={{ simpleDrag: ComponentPropTestId.ComponentPicker.preferredValuesList }}
                        className={classNames(styles.simpleDragList, preferedValueListClassName)}
                    >
                        {popupData.instanceSwapPreferredValues.map((v, index) => (
                            <SimpleDrag.Item
                                key={`${v.publishFile}-${v.publishId}`}
                                itemIndex={index}
                                fullAreaInteractive
                                tabIndex={0}
                                onKeyDown={(e) => {
                                    if (['Delete', 'Backspace'].includes(e.code)) {
                                        e.stopPropagation() // 防止快捷键冒泡到 wasm 侧
                                        updatePreferredValues(
                                            popupData.instanceSwapPreferredValues.filter(
                                                (_, i) => !selectedDragItems.includes(i)
                                            )
                                        )
                                        setSelectedDragItems([])
                                    }
                                }}
                                onMouseDown={closeComponentPicker}
                            >
                                <div
                                    className={classNames(styles.preferredValuesItem, {
                                        [styles.preferredValuesItemSelected]: selectedDragItems.includes(index),
                                    })}
                                    data-testid={ComponentPropTestId.ComponentPicker.preferredValuesItem(index)}
                                >
                                    <div className={styles.thumbnailWrapper}>
                                        <LibraryThumbnailImage thumbnailData={v.thumbnailData} />
                                    </div>
                                    <span className={styles.preferredValueName}>{v.name}</span>
                                    <WKIconButton
                                        onMouseDown={(e) => e.stopPropagation()} // 防止首选值Item被选中
                                        onClick={(e) => {
                                            e.stopPropagation() // 防止冒泡到拖拽组件
                                            updatePreferredValues(
                                                popupData.instanceSwapPreferredValues.filter((_, i) => i !== index)
                                            )
                                            setSelectedDragItems([])
                                        }}
                                        className={styles.preferredValueRemove}
                                        icon={<MonoIconCommonRemove16 />}
                                    />
                                </div>
                            </SimpleDrag.Item>
                        ))}
                    </SimpleDrag>
                </Scrollbar>
            )}
        </div>
    )
}

export interface InstanceSwapPropPopupContentRef {
    closeComponentPicker: () => void
}

const INSTANCE_SWAP_VALUE_LEFT_OFFSET = -68
const INSTANCE_SWAP_VALUE_TOP_OFFSET = 36
const PREFERRED_VALUES_LEFT_OFFSET = 241
const PREFERRED_VALUES_TOP_OFFSET = 3

interface CreateInstanceSwapPropPopupContentProps {
    popupData: VComponentPropDef
    popupType: PopupStateType
    closePopup: () => void
    createProp: (propVal: ICreateComponentProp) => void
    createAndApplyProp: (propVal: ICreateComponentProp) => void
    toggleComponentPropPopup: (popupStateType: PopupStateType, editPopupVal?: ComponentPropEditPopupVal) => void
    validateInstanceSwapValue: (value: Arg_validateInstanceSwapValue) => boolean | null | undefined
}

function _CreateInstanceSwapPropPopupContent(
    props: CreateInstanceSwapPropPopupContentProps,
    ref: ForwardedRef<InstanceSwapPropPopupContentRef>
) {
    const {
        closePopup,
        popupType,
        createProp,
        popupData,
        createAndApplyProp,
        toggleComponentPropPopup,
        validateInstanceSwapValue,
    } = props

    const selectWrapperRef = useRef<HTMLDivElement>(null)
    const [componentPickerPosition, setComponentPickerPosition] = useState<{ left: number; top: number } | undefined>()
    const [componentPickerTrigger, setComponentPickerTrigger] = useState<ComponentPickerTrigger>(
        ComponentPickerTrigger.None
    )
    const [propName, setPropName] = useState<string>(popupData.name)
    const instanceSwapValueState = useViewState('componentInstanceSwapPropState')
    const perferredValuesPanelState = useViewState('componentInstanceSwapPropPreferValueState')

    const confirm = () => {
        const propData: ICreateComponentProp = {
            name: propName,
            type: ComponentPropType.COMPONENT_PROP_TYPE_INSTANCE_SWAP,
            value: popupData.varValue.value,
        }

        if (popupData.instanceSwapPreferredValues.length > 0) {
            propData.preferredValues = {
                stringValues: [],
                instanceSwapValues: popupData.instanceSwapPreferredValues,
            }
        }

        if (popupType === PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_INSTANCE_SWAP_PROP_OF_PROP_AREA) {
            if (
                validateInstanceSwapValue({
                    newInstanceSwapValue: propData.value.mainComponentId,
                    scene: ValidateInstanceSwapValueScene.VALIDATE_INSTANCE_SWAP_VALUE_SCENE_CREATE_INSTANCE_SWAP,
                    affectedNodeIds: [],
                })
            ) {
                createProp(propData)
            } else {
                WKToast.show(translation('ComponentCanNotContainItself'))
            }
        } else if (popupType === PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_INSTANCE_SWAP_PROP_OF_INSTANCE_AREA) {
            if (
                validateInstanceSwapValue({
                    newInstanceSwapValue: propData.value.mainComponentId,
                    scene: ValidateInstanceSwapValueScene.VALIDATE_INSTANCE_SWAP_VALUE_SCENE_CREATE_AND_APPLY_INSTANCE_SWAP,
                    affectedNodeIds: [],
                })
            ) {
                createAndApplyProp(propData)
            } else {
                WKToast.show(translation('ComponentCanNotContainItself'))
            }
        }
        closePopup()
    }

    const closeComponentPicker = () => {
        setComponentPickerTrigger(ComponentPickerTrigger.None)
        setComponentPickerPosition(undefined)
    }

    useImperativeHandle(ref, () => ({
        closeComponentPicker,
    }))

    const toggleComponentPickerVisible = (
        trigger: ComponentPickerTrigger,
        triggerRef: React.RefObject<HTMLDivElement>
    ) => {
        if (!triggerRef.current || trigger === ComponentPickerTrigger.None) return
        if (trigger === componentPickerTrigger) {
            closeComponentPicker()
        } else {
            const position = triggerRef.current.getBoundingClientRect()
            const left =
                position.left +
                (trigger === ComponentPickerTrigger.InstanceSwapValue
                    ? INSTANCE_SWAP_VALUE_LEFT_OFFSET
                    : PREFERRED_VALUES_LEFT_OFFSET)
            const top =
                position.top +
                (trigger === ComponentPickerTrigger.InstanceSwapValue
                    ? INSTANCE_SWAP_VALUE_TOP_OFFSET
                    : PREFERRED_VALUES_TOP_OFFSET)
            setComponentPickerPosition({ top, left })
            setComponentPickerTrigger(trigger)
        }
    }

    const updatePreferredValues = (instanceSwapValues: Wukong.DocumentProto.IInstanceSwapPreferredValue[]) => {
        // 这里借助 setPopupStateType 触发计算属性重算
        toggleComponentPropPopup(popupType, {
            type: convertRealComponentPropType2Virtual(ComponentPropType.COMPONENT_PROP_TYPE_INSTANCE_SWAP),
            preferredValues: { instanceSwapValues },
            varValue: popupData.varValue,
        } as ComponentPropEditPopupVal)
    }

    const handlePreferredValues = (value: ReplaceInstanceNodeData & { docId: string }) => {
        const type = value.isCompSet
            ? InstanceSwapPreferredValueType.INSTANCE_SWAP_PREFERRED_VALUE_TYPE_STATE_GROUP
            : InstanceSwapPreferredValueType.INSTANCE_SWAP_PREFERRED_VALUE_TYPE_COMPONENT

        const isExist = popupData.instanceSwapPreferredValues.some(
            (p) => p.publishFile === value.docId && p.publishId === value.componentOrSetNodeId && p.type === type
        )

        updatePreferredValues(
            isExist
                ? popupData.instanceSwapPreferredValues.filter(
                      (p) =>
                          p.publishFile !== value.docId || p.publishId !== value.componentOrSetNodeId || p.type !== type
                  )
                : [
                      ...popupData.instanceSwapPreferredValues,
                      { publishFile: value.docId, publishId: value.componentOrSetNodeId, type },
                  ]
        )
    }

    const updateSelectedNode = (nodeId: string) => {
        // 这里借助 setPopupStateType 触发计算属性重算
        toggleComponentPropPopup(popupType, {
            type: convertRealComponentPropType2Virtual(ComponentPropType.COMPONENT_PROP_TYPE_INSTANCE_SWAP),
            preferredValues: {
                instanceSwapValues: popupData.instanceSwapPreferredValues.map((p) => ({
                    type: p.type,
                    publishFile: p.publishFile,
                    publishId: p.publishId,
                })),
            },
            varValue: {
                value: {
                    mainComponentId: nodeId,
                },
            },
        } as ComponentPropEditPopupVal)
    }

    const handleThumbnailClick = (value: ReplaceInstanceNodeData & { docId: string }) => {
        if (componentPickerTrigger === ComponentPickerTrigger.InstanceSwapValue) {
            if (
                validateInstanceSwapValue({
                    newInstanceSwapValue: value.nodeId,
                    affectedNodeIds: [],
                    scene:
                        popupType === PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_INSTANCE_SWAP_PROP_OF_PROP_AREA
                            ? ValidateInstanceSwapValueScene.VALIDATE_INSTANCE_SWAP_VALUE_SCENE_CREATE_INSTANCE_SWAP
                            : ValidateInstanceSwapValueScene.VALIDATE_INSTANCE_SWAP_VALUE_SCENE_CREATE_AND_APPLY_INSTANCE_SWAP,
                })
            ) {
                updateSelectedNode(value.nodeId)
            } else {
                WKToast.show(translation('ComponentCanNotContainItself'))
            }
        } else if (componentPickerTrigger === ComponentPickerTrigger.PreferredValues) {
            handlePreferredValues(value)
        }
    }

    const handleFolderCheckboxClick = (checked: boolean, nodes: (ReplaceInstanceNodeData & { docId: string })[]) => {
        if (checked) {
            const preferredValuosMap = popupData.instanceSwapPreferredValues.reduce((acc, node) => {
                acc.set(`${node.publishFile}-${node.publishId}`, node)
                return acc
            }, new Map())

            const newPreferredValues: any = [...popupData.instanceSwapPreferredValues]
            nodes.forEach((n) => {
                if (!preferredValuosMap.has(`${n.docId}-${n.componentOrSetNodeId}`)) {
                    newPreferredValues.push({
                        publishFile: n.docId,
                        publishId: n.componentOrSetNodeId,
                        type: n.isCompSet
                            ? InstanceSwapPreferredValueType.INSTANCE_SWAP_PREFERRED_VALUE_TYPE_STATE_GROUP
                            : InstanceSwapPreferredValueType.INSTANCE_SWAP_PREFERRED_VALUE_TYPE_COMPONENT,
                    })
                }
            })

            updatePreferredValues(newPreferredValues)
        } else {
            const nodesMap = nodes.reduce((acc, node) => {
                acc.set(`${node.docId}-${node.componentOrSetNodeId}`, node)
                return acc
            }, new Map())
            updatePreferredValues(
                popupData.instanceSwapPreferredValues.filter((p) => !nodesMap.has(`${p.publishFile}-${p.publishId}`))
            )
        }
    }

    return (
        <>
            <div className="pt-2 pb-2" onClick={closeComponentPicker}>
                <SingleGrid>
                    <SingleGrid.Item start={5} span={16}>
                        {translation('PropertyName')}
                    </SingleGrid.Item>
                    <SingleGrid.Item start={21} span={36}>
                        <InputV2
                            dataTestIds={{ input: 'create-instance-swap-prop-name-input' }}
                            autoFocus
                            size="small"
                            width={144}
                            value={propName}
                            onChange={(e: any) => setPropName(e.target.value)}
                        />
                    </SingleGrid.Item>
                </SingleGrid>
                <SingleGrid>
                    <SingleGrid.Item start={5} span={16}>
                        {translation('PropertyValue')}
                    </SingleGrid.Item>
                    <SingleGrid.Item ref={selectWrapperRef} start={21} span={36}>
                        <Select.NormalSingleLevel
                            isHover={componentPickerTrigger === ComponentPickerTrigger.InstanceSwapValue}
                            dataTestIds={{ triggerFocus: 'create-instance-swap-prop-trigger' }}
                            icon={<MonoIconLayerInstance16 />}
                            label={popupData.instanceSwapPropComponentName}
                            placeholder={translation('PickInstance')}
                            onClick={(e) => {
                                e.stopPropagation()
                                toggleComponentPickerVisible(ComponentPickerTrigger.InstanceSwapValue, selectWrapperRef)
                            }}
                        />
                    </SingleGrid.Item>
                </SingleGrid>
            </div>
            <PreferredValuesList
                popupData={popupData}
                toggleComponentPickerVisible={toggleComponentPickerVisible}
                updatePreferredValues={updatePreferredValues}
                preferredValuesPanelVisible={componentPickerTrigger === ComponentPickerTrigger.PreferredValues}
                preferedValueListClassName="pb-2"
                closeComponentPicker={closeComponentPicker}
            />
            <div className={styles.compPropPopupConfirm} onClick={closeComponentPicker}>
                <WKButton
                    type="primary"
                    dataTestId="create-prop-confirm"
                    onClick={confirm}
                    disabled={
                        !propName ||
                        !popupData.varValue.value.mainComponentId ||
                        !popupData.instanceSwapPropComponentName
                    }
                >
                    {translation('CreateProperty')}
                </WKButton>
            </div>
            {componentPickerTrigger !== ComponentPickerTrigger.None &&
                instanceSwapValueState &&
                perferredValuesPanelState && (
                    <ComponentPicker
                        key={componentPickerTrigger}
                        state={
                            componentPickerTrigger === ComponentPickerTrigger.InstanceSwapValue
                                ? instanceSwapValueState
                                : perferredValuesPanelState
                        }
                        position={componentPickerPosition}
                        onCancel={closeComponentPicker}
                        triggerScene={componentPickerTrigger}
                        handleThumbnailClick={handleThumbnailClick}
                        handleFolderCheckboxClick={handleFolderCheckboxClick}
                    />
                )}
        </>
    )
}

export const CreateInstanceSwapPropPopupContent = forwardRef<
    InstanceSwapPropPopupContentRef,
    CreateInstanceSwapPropPopupContentProps
>(_CreateInstanceSwapPropPopupContent)

interface UpdateInstanceSwapPropPopupContentProps {
    popupData: VComponentPropDef
    updateProp: (propVal: Arg_cmdEditComponentPropData) => void
    validateInstanceSwapValue: (value: Arg_validateInstanceSwapValue) => boolean | null | undefined
}

function _UpdateInstanceSwapPropPopupContent(
    props: UpdateInstanceSwapPropPopupContentProps,
    ref: ForwardedRef<InstanceSwapPropPopupContentRef>
) {
    const { popupData, updateProp, validateInstanceSwapValue } = props
    const instanceSwapValueState = useViewState('componentInstanceSwapPropState')
    const perferredValuesPanelState = useViewState('componentInstanceSwapPropPreferValueState')
    const [componentPickerPosition, setComponentPickerPosition] = useState<{ left: number; top: number } | undefined>()
    const [componentPickerTrigger, setComponentPickerTrigger] = useState<ComponentPickerTrigger>(
        ComponentPickerTrigger.None
    )
    const selectWrapperRef = useRef<HTMLDivElement>(null)

    const toggleComponentPickerVisible = (
        trigger: ComponentPickerTrigger,
        triggerRef: React.RefObject<HTMLDivElement>
    ) => {
        if (!triggerRef.current || trigger === ComponentPickerTrigger.None) return
        if (trigger === componentPickerTrigger) {
            closeComponentPicker()
        } else {
            const position = triggerRef.current.getBoundingClientRect()
            const left =
                position.left +
                (trigger === ComponentPickerTrigger.InstanceSwapValue
                    ? INSTANCE_SWAP_VALUE_LEFT_OFFSET
                    : PREFERRED_VALUES_LEFT_OFFSET)
            const top =
                position.top +
                (trigger === ComponentPickerTrigger.InstanceSwapValue
                    ? INSTANCE_SWAP_VALUE_TOP_OFFSET
                    : PREFERRED_VALUES_TOP_OFFSET)
            setComponentPickerPosition({ top, left })
            setComponentPickerTrigger(trigger)
        }
    }

    const closeComponentPicker = () => {
        setComponentPickerTrigger(ComponentPickerTrigger.None)
        setComponentPickerPosition(undefined)
    }

    useImperativeHandle(ref, () => ({
        closeComponentPicker,
    }))

    const onNameInputBlur = (e: any) => {
        const value = e.target.value
        if (!value || value === popupData.name) {
            return
        }
        updateProp({
            propId: popupData.id,
            name: value,
        })
    }

    const selectNewComponent = (newComponentId: string) => {
        if (newComponentId === popupData.varValue.value.mainComponentId) return
        updateProp({
            propId: popupData.id,
            value: {
                mainComponentId: newComponentId,
            } as VariableAnyValue,
        })
    }

    const updatePreferredValues = (instanceSwapValues: InstanceSwapPreferredValue[]) => {
        // 这里直接更新 componentPropDefs，wasm 根据 componentPropDefs 计算出新的 preferredValues
        updateProp({
            propId: popupData.id,
            preferredValues: { instanceSwapValues, stringValues: [] },
        })
    }

    const handlePreferredValues = (value: ReplaceInstanceNodeData & { docId: string }) => {
        const type = value.isCompSet
            ? InstanceSwapPreferredValueType.INSTANCE_SWAP_PREFERRED_VALUE_TYPE_STATE_GROUP
            : InstanceSwapPreferredValueType.INSTANCE_SWAP_PREFERRED_VALUE_TYPE_COMPONENT

        const isExist = popupData.instanceSwapPreferredValues.some(
            (p) => p.publishFile === value.docId && p.publishId === value.componentOrSetNodeId && p.type === type
        )

        updatePreferredValues(
            isExist
                ? popupData.instanceSwapPreferredValues.filter(
                      (p) =>
                          p.publishFile !== value.docId || p.publishId !== value.componentOrSetNodeId || p.type !== type
                  )
                : [
                      ...popupData.instanceSwapPreferredValues,
                      { publishFile: value.docId, publishId: value.componentOrSetNodeId, type },
                  ]
        )
    }

    const handleThumbnailClick = (value: ReplaceInstanceNodeData & { docId: string }) => {
        if (componentPickerTrigger === ComponentPickerTrigger.InstanceSwapValue) {
            if (
                validateInstanceSwapValue({
                    updateDefId: popupData.id,
                    newInstanceSwapValue: value.nodeId,
                    scene: ValidateInstanceSwapValueScene.VALIDATE_INSTANCE_SWAP_VALUE_SCENE_UPDATE_INSTANCE_SWAP,
                    affectedNodeIds: [],
                })
            ) {
                selectNewComponent(value.nodeId)
            } else {
                WKToast.show(translation('ComponentCanNotContainItself'))
            }
        } else if (componentPickerTrigger === ComponentPickerTrigger.PreferredValues) {
            handlePreferredValues(value)
        }
    }

    const handleFolderCheckboxClick = (checked: boolean, nodes: (ReplaceInstanceNodeData & { docId: string })[]) => {
        if (checked) {
            const preferredValuosMap = popupData.instanceSwapPreferredValues.reduce((acc, node) => {
                acc.set(`${node.publishFile}-${node.publishId}`, node)
                return acc
            }, new Map())

            const newPreferredValues: any = [...popupData.instanceSwapPreferredValues]
            nodes.forEach((n) => {
                if (!preferredValuosMap.has(`${n.docId}-${n.componentOrSetNodeId}`)) {
                    newPreferredValues.push({
                        publishFile: n.docId,
                        publishId: n.componentOrSetNodeId,
                        type: n.isCompSet
                            ? InstanceSwapPreferredValueType.INSTANCE_SWAP_PREFERRED_VALUE_TYPE_STATE_GROUP
                            : InstanceSwapPreferredValueType.INSTANCE_SWAP_PREFERRED_VALUE_TYPE_COMPONENT,
                    })
                }
            })

            updatePreferredValues(newPreferredValues)
        } else {
            const nodesMap = nodes.reduce((acc, node) => {
                acc.set(`${node.docId}-${node.componentOrSetNodeId}`, node)
                return acc
            }, new Map())
            updatePreferredValues(
                popupData.instanceSwapPreferredValues.filter((p) => !nodesMap.has(`${p.publishFile}-${p.publishId}`))
            )
        }
    }

    return (
        <>
            <div className="pt-2 pb-2" onClick={closeComponentPicker}>
                <SingleGrid>
                    <SingleGrid.Item start={5} span={16}>
                        {translation('PropertyName')}
                    </SingleGrid.Item>
                    <SingleGrid.Item start={21} span={36}>
                        <InputV2
                            dataTestIds={{ input: 'update-instance-swap-prop-name-input' }}
                            autoFocus
                            size="small"
                            width={144}
                            value={popupData.name}
                            onBlur={onNameInputBlur}
                        />
                    </SingleGrid.Item>
                </SingleGrid>
                <SingleGrid>
                    <SingleGrid.Item start={5} span={16}>
                        {translation('PropertyValue')}
                    </SingleGrid.Item>
                    <SingleGrid.Item ref={selectWrapperRef} start={21} span={36}>
                        <Select.NormalSingleLevel
                            isHover={componentPickerTrigger === ComponentPickerTrigger.InstanceSwapValue}
                            dataTestIds={{ triggerFocus: 'update-instance-swap-prop-trigger' }}
                            icon={<MonoIconLayerInstance16 />}
                            label={popupData.instanceSwapPropComponentName}
                            onClick={(e) => {
                                e.stopPropagation()
                                toggleComponentPickerVisible(ComponentPickerTrigger.InstanceSwapValue, selectWrapperRef)
                            }}
                        />
                    </SingleGrid.Item>
                </SingleGrid>
            </div>

            <PreferredValuesList
                popupData={popupData}
                toggleComponentPickerVisible={toggleComponentPickerVisible}
                updatePreferredValues={updatePreferredValues}
                preferredValuesPanelVisible={componentPickerTrigger === ComponentPickerTrigger.PreferredValues}
                preferedValueListClassName="pb-3"
                closeComponentPicker={closeComponentPicker}
            />
            {componentPickerTrigger !== ComponentPickerTrigger.None &&
                instanceSwapValueState &&
                perferredValuesPanelState && (
                    <ComponentPicker
                        key={componentPickerTrigger}
                        state={
                            componentPickerTrigger === ComponentPickerTrigger.InstanceSwapValue
                                ? instanceSwapValueState
                                : perferredValuesPanelState
                        }
                        position={componentPickerPosition}
                        onCancel={closeComponentPicker}
                        triggerScene={componentPickerTrigger}
                        handleThumbnailClick={handleThumbnailClick}
                        handleFolderCheckboxClick={handleFolderCheckboxClick}
                    />
                )}
        </>
    )
}

export const UpdateInstanceSwapPropPopupContent = forwardRef<
    InstanceSwapPropPopupContentRef,
    UpdateInstanceSwapPropPopupContentProps
>(_UpdateInstanceSwapPropPopupContent)
