import classnames from 'classnames'
import { FC } from 'react'
import { MonoIconCommonClose16 } from '../../icons-v2'
import { WKDivider } from '../divider'
import { WKButton, WKButtonProps } from '../wk-button/button'
import { WKIconButton } from '../wk-button/icon-button'
import { WKTypography } from '../wk-typography'
import { WKNotificationProps } from './type'

export const NotificationRenderComponent: FC<WKNotificationProps> = ({
    wrapClassName,
    icon,
    titleClassName,
    title,
    onClose,
    closeIconProps,
    content,
    contentClassName,
    onCancel,
    onOk,
    cancelButtonProps,
    cancelText,
    okText,
    okButtonProps,
}) => {
    return (
        <div
            data-testid="WKNotification"
            className={classnames('px-4 pt-4 w-344px box-border rounded-4px mt-4 bg-white', wrapClassName)}
            style={{ boxShadow: 'var(--wk-shadow-200)' }}
        >
            <div className="flex">
                {icon && <div className="mr-2">{icon}</div>}
                <div className="flex-1 w-full">
                    <div className="py-1px wk-text-14 wk-font-semibold flex justify-between">
                        <span className={classnames('color-$wk-v2-label-color-gray-13', titleClassName)}>{title}</span>
                        <div className="w-6 h-6 relative -top-2px -right-4px shrink-0">
                            <WKIconButton
                                onClick={onClose}
                                icon={<MonoIconCommonClose16 />}
                                type="secondary"
                                {...closeIconProps}
                            ></WKIconButton>
                        </div>
                    </div>
                    {content && (
                        <div className={classnames('mt-2 color-$wk-gray-11 wk-text-12', contentClassName)}>
                            {content}
                        </div>
                    )}
                </div>
            </div>
            <div className="text-right pt-3 pb-4">
                <WKButton onClick={onCancel} type="secondary" {...cancelButtonProps}>
                    {cancelText}
                </WKButton>
                <WKButton onClick={onOk} type="primary" {...okButtonProps}>
                    {okText}
                </WKButton>
            </div>
        </div>
    )
}

const BlackButton: FC<WKButtonProps> = (props) => {
    const { className, children } = props
    const blackButtonClass = '!px-4 !m-0 !color-white !rounded-0 !bg-transparent relative group'

    return (
        <WKButton {...props} className={classnames(blackButtonClass, className)}>
            <span className="absolute inset-0 z-0 bg-transparent group-hover:bg-$wk-gray-13-50"></span>
            <span className="relative z-1">{children}</span>
        </WKButton>
    )
}

export const NotificationRenderComponentBlack: FC<WKNotificationProps> = ({
    okButtonProps,
    cancelButtonProps,
    icon,
    onCancel,
    onOk,
    okText,
    cancelText,
    title,
}) => {
    return (
        <div className="bg-$wk-gray-13 w-fit ml-auto mt-4 flex rounded-$wk-radius-default min-w-320px max-w-400px">
            <div className="flex-1 color-white flex items-center px-3 gap-2">
                {icon}
                <WKTypography.Paragraph color="white" ellipsis={{ rows: 2 }}>
                    {title}
                </WKTypography.Paragraph>
            </div>
            <WKDivider className="shrink-0 !border-$wk-gray-11 !w-1px b-r" />
            <div className="flex flex-col shrink-0">
                <BlackButton type="text" onClick={onOk} {...okButtonProps}>
                    {okText}
                </BlackButton>
                <WKDivider className="!border-$wk-gray-11 -mt-1px relative" />
                <BlackButton onClick={onCancel} type="text" {...cancelButtonProps}>
                    {cancelText}
                </BlackButton>
            </div>
        </div>
    )
}
