import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Center: '居中',
    Inside: '内部',
    Outside: '外部',
} as const

export const enTranslation = {
    Center: 'Center',
    Inside: 'Inside',
    Outside: 'Outside',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
