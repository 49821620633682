import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    UnableGoToStyleFile: '无法打开样式所在文件',
    UnableGoToMainDesc1: '该实例来自 Figma，暂未查找到其样式母版。请前往 Figma 下载该实例引用的组件库并通过',
    ComponentMigration: '「组件迁移」',
    UnableGoToMainDesc2: '功能迁移至 {{name}}。',
    Ok: '我知道了',
} as const

export const enTranslation = {
    UnableGoToStyleFile: 'Unable to go to style definition',
    UnableGoToMainDesc1:
        'This style is from Figma, and the style definition has not been found. Go to Figma to download the referenced library, and',
    ComponentMigration: ' import library ',
    UnableGoToMainDesc2: 'to {{name}}.',
    Ok: 'OK',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
