import { UpdateAutoLayoutHoverItemWasmCall } from '@wukong/bridge-proto'
import { useCallback, useEffect, useRef } from 'react'
import { useCommand } from '../../../../../context/document-context'
import { AutoLayoutHoverItem } from './types'

// 控制自动布局相关输入框 hover/unhover 状态向 wasm 同步
export function useHoverAutolayoutMenuItem<T = never>(props: { hideMenu: boolean; hoverMenu: (option: T) => void }) {
    const command = useCommand()
    const isHoverMenu = useRef<boolean>(false)

    const unHoverMenu = useCallback(() => {
        isHoverMenu.current = false
        command.DEPRECATED_invokeBridge(UpdateAutoLayoutHoverItemWasmCall, {
            value: AutoLayoutHoverItem.AUTO_LAYOUT_HOVER_MENU_ITEM_NONE,
        })
    }, [command])

    const hoverMenu = (option: T) => {
        isHoverMenu.current = true
        props.hoverMenu(option)
    }

    useEffect(() => {
        // 如果组件隐藏，取消 hover 状态
        props.hideMenu && isHoverMenu.current && unHoverMenu()
    }, [props.hideMenu, unHoverMenu])

    useEffect(() => {
        // 组件卸载时，取消 hover 状态
        return () => {
            isHoverMenu.current && unHoverMenu()
        }
    })

    return {
        hoverMenu,
        unHoverMenu,
    }
}
