import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { ExplicitUndefined } from '../../../utils/explicit-undefined'
import { Empty, EmptyProps, EmptyRef } from '../picks/empty/empty'

export interface DropdownNoTriggerEmptyRef {
    open: () => void
    close: () => void
}
export type DropdownNoTriggerEmptyProps = EmptyProps

function _DropdownNoTriggerEmpty(props: DropdownNoTriggerEmptyProps, ref?: React.Ref<DropdownNoTriggerEmptyRef>) {
    const pickRef = useRef<EmptyRef>(null)

    useImperativeHandle(
        ref,
        () => ({
            close: () => pickRef.current?.close(),
            open: () => pickRef.current?.open(),
        }),
        []
    )

    return (
        <Empty<ExplicitUndefined<EmptyProps>>
            pickRest={props.pickRest}
            dataTestIds={props.dataTestIds}
            removeMask={props.removeMask}
            triggerRect={props.triggerRect}
            closeByESC={props.closeByESC}
            onClose={props.onClose}
            onOpen={props.onOpen}
            ref={pickRef}
            worldRect={props.worldRect}
            placement={props.placement}
            overMajorClassName={props.overMajorClassName}
            overMinorClassName={props.overMinorClassName}
            isMinWidthFromTrigger={props.isMinWidthFromTrigger}
            minWidth={props.minWidth}
            width={props.width}
            maxWidth={props.maxWidth}
            onKeyboard={props.onKeyboard}
            isOpenState={props.isOpenState}
            openStateToBeFalse={props.openStateToBeFalse}
            openStateToBeTrue={props.openStateToBeTrue}
            getPortalElement={props.getPortalElement}
            autoAdjustLeft={props.autoAdjustLeft}
        >
            {props.children}
        </Empty>
    )
}

/**
 * @description 这个 dropdown 不属于设计的定义。
 * */
export const DropdownNoTriggerEmpty = forwardRef(_DropdownNoTriggerEmpty)
