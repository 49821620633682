import { LocalStorageKey } from '../../../../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../../../../web-storage/local-storage/storage'

export type EmojiShortName = string
export type EmojiFont = string

export function getRecentEmoji() {
    return enhancedLocalStorage.getSerializedItem(LocalStorageKey.RecentEmoji, [])
}
export function addRecentEmojiToLocal(emojiShortName: EmojiShortName) {
    const recentEmojiList: EmojiShortName[] = enhancedLocalStorage.getSerializedItem(LocalStorageKey.RecentEmoji, [])
    let newRecentEmojiList: EmojiShortName[] = []
    if (isRecentEmoji(emojiShortName, recentEmojiList)) {
        newRecentEmojiList.push(emojiShortName)
        for (const recentEmoji of recentEmojiList) {
            if (recentEmoji != emojiShortName) {
                newRecentEmojiList.push(recentEmoji)
            }
        }
    } else {
        newRecentEmojiList = [emojiShortName, ...recentEmojiList]
    }
    enhancedLocalStorage.setSerializedItem(LocalStorageKey.RecentEmoji, newRecentEmojiList.slice(0, 7))
}

export function isRecentEmoji(emojiShortName: EmojiShortName, recentEmojiList: EmojiShortName[]) {
    for (const recentEmojiShortName of recentEmojiList) {
        if (emojiShortName === recentEmojiShortName) {
            return true
        }
    }
    return false
}
