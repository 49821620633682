import { Checkbox, InputV2 } from '../../../../../../../ui-lib/src'
import { useMemo } from 'react'
import { MIXED_TYPE, MIXED_TYPE_LABEL } from '../../prototype-interaction/constants'
import { translation } from './open-link.translation'

export const InteractionActionOpenLink = ({
    openInNewTab,
    connectionUrls,
    onChangeOpenInNewTab,
    onChange,
}: {
    openInNewTab?: boolean | typeof MIXED_TYPE
    connectionUrls: string[]
    onChange: (v: string) => void
    onChangeOpenInNewTab: (v: boolean) => void
}) => {
    const inputValue = useMemo(() => {
        return connectionUrls.length > 1 ? MIXED_TYPE_LABEL : connectionUrls[0] ?? ''
    }, [connectionUrls])

    return (
        <div className="pl-10 pr-4">
            <InputV2
                value={inputValue}
                onChange={(e) => onChange(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
                placeholder="example.com"
                rootClassName="py-1"
                size="small"
                dataTestIds={{
                    input: 'open-link-input',
                }}
            />
            <Checkbox
                checked={openInNewTab === true}
                indeterminate={openInNewTab === MIXED_TYPE}
                onChange={onChangeOpenInNewTab}
                className="py-2 w-fit"
                label={translation('OpenInNewTab')}
                testid="open-link-checkbox"
            />
        </div>
    )
}
