/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconScrollArrowTop = () => (
    <svg className="svg" width="8" height="8" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M.646 4.647l.708.707L4 2.707l2.646 2.647.708-.707L4 1.293.646 4.647z"
            fillRule="nonzero"
            fillOpacity="1"
            fill="#1B1F26"
            stroke="none"
        ></path>
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconScrollArrowBottom = () => (
    <svg className="svg" width="8" height="8" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M.646 3.354l.708-.708L4 5.293l2.646-2.647.708.708L4 6.707.646 3.354z"
            fillRule="nonzero"
            fillOpacity="1"
            fill="#1B1F26"
            stroke="none"
        ></path>
    </svg>
)
