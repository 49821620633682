import React from 'react'
import classes from './tool-card.module.less'

export interface ToolCardProps {
    name: string
    description: string
    tools: {
        icon: React.ReactNode
        name: string
    }[]
    dataTestIds?: {
        name?: string
        description?: string
        prefixTool?: string
    }
}
export function ToolCard(props: ToolCardProps) {
    const { name, description, tools, dataTestIds } = props
    return (
        <div className={classes.card}>
            <div className={classes.title}>
                <span className={classes.name} data-testid={dataTestIds?.name}>
                    {name}
                </span>
                <span className={classes.description} data-testid={dataTestIds?.description}>
                    {description}
                </span>
            </div>
            <div className={classes.tools}>
                {tools.map((v, index) => (
                    <div key={index} className={classes.tool} data-testid={`${dataTestIds?.prefixTool}-${index}`}>
                        <span className={classes.icon}>{v.icon}</span>
                        <span className={classes.toolName}>{v.name}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}
