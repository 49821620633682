import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Skew: '倾斜工具',
    SelectALayer: '无选中图层，选中目标后可调整倾斜效果。',
    HorizontalSkew: '水平倾斜',
    VerticalSkew: '垂直倾斜',
    Reset: '重置',
    Apply: '应用',
} as const

export const enTranslation = {
    Skew: 'Skew',
    SelectALayer: 'Select a layer before running Skew.',
    HorizontalSkew: 'Horizontal Skew',
    VerticalSkew: 'Vertical Skew',
    Reset: 'Reset',
    Apply: 'Apply',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
