import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Fill: '填充',
    Fit: '适应',
    Crop: '裁剪',
    Tile: '平铺',
    Rotate: '旋转',
} as const

export const enTranslation = {
    Fill: 'Fill',
    Fit: 'Fit',
    Crop: 'Crop',
    Tile: 'Tile',
    Rotate: 'Rotate',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
