/**
 * @experimental 用于替换rxjs的combineLatest
 */
export const createCombineLatestEventHandler = <T extends Record<string, unknown>>(
    config: { [K in keyof T]: { init: false } | { init: true; value: T[K] } },
    handler: (events: T) => void
) => {
    const latestEvents: Partial<T> = {}
    const initStates = {} as { [K in keyof T]: boolean }

    // 根据config 初始化 initStates 和 latestEvents
    Object.entries(config).forEach(([key, value]) => {
        initStates[key as keyof T] = value.init

        if (value.init) {
            latestEvents[key as keyof T] = value.value
        }
    })

    const notify = () => {
        if (Object.values(initStates).every((v) => v)) {
            handler(latestEvents as T)
        }
    }

    // 如果config中所有事件都设置为已初始化，则立即调用handler
    notify()

    return {
        handleEvent: <K extends keyof T>(event: K, value: T[K]) => {
            latestEvents[event] = value
            initStates[event] = true

            notify()
        },
    }
}
