/* eslint-disable no-restricted-imports */
import {
    ScaleViewportByWheelWasmCall,
    MoveViewportCommand as wasmCmdMoveViewport,
    MoveViewportWhenOutsideCanvasCommandForJs as wasmCmdMoveViewportWhenOutsideCanvas,
    ScrollSelectionIntoView as wasmCmdScrollSelectionIntoView,
    SetViewportCommandForJs as wasmCmdSetViewport,
    ZoomAtViewportCenterWasmCall as wasmCmdZoomAtViewportCenter,
    ZoomInByLevelAtViewportCenterWasmCall as wasmCmdZoomInByLevelAtViewportCenter,
    ZoomOutByLevelAtViewportCenterWasmCall as wasmCmdZoomOutByLevelAtViewportCenter,
    ZoomToAllCommandForJs as wasmCmdZoomToAll,
    ZoomToFitNodesAtMaxScaleByIdsCommand as wasmCmdZoomToFitNodesAndScreen,
    ZoomToSelectionWasmCall,
} from '@wukong/bridge-proto'
import { getChromeVersion, isChrome, isSafari } from '../../kernel/util/ua'
import { WK } from '../../window'
import { NodeId, Rect, Viewport } from '../node/node'
import { BridgeCommand } from './command-invoker'

export const cmdSetViewport: BridgeCommand<[viewport: Viewport]> = (ctx, viewport) => {
    ctx.commandInvoker.DEPRECATED_invokeBridge(wasmCmdSetViewport, viewport)
}

export const cmdMoveViewport: BridgeCommand<[deltaX: number, deltaY: number]> = (ctx, deltaX, deltaY) => {
    ctx.commandInvoker.DEPRECATED_invokeBridge(wasmCmdMoveViewport, {
        deltaX,
        deltaY,
    })
}

export const cmdZoomAtCameraCenter: BridgeCommand<[scale: number]> = (ctx, scale) => {
    ctx.commandInvoker.DEPRECATED_invokeBridge(wasmCmdZoomAtViewportCenter, {
        scale: scale,
    })
}

export const cmdZoomInByLevelAtCameraCenter: BridgeCommand = (ctx) => {
    ctx.commandInvoker.DEPRECATED_invokeBridge(wasmCmdZoomInByLevelAtViewportCenter)
}

export const cmdZoomOutByLevelAtCameraCenter: BridgeCommand = (ctx) => {
    ctx.commandInvoker.DEPRECATED_invokeBridge(wasmCmdZoomOutByLevelAtViewportCenter)
}

export const cmdZoomToFitNodesAndScreen: BridgeCommand<[nodeIds: ReadonlyArray<NodeId>]> = (ctx, nodeIds) => {
    ctx.commandInvoker.DEPRECATED_invokeBridge(wasmCmdZoomToFitNodesAndScreen, {
        nodeIds: nodeIds as string[],
    })
}

export const cmdZoomToSelection: BridgeCommand = (ctx) => {
    ctx.commandInvoker.DEPRECATED_invokeBridge(ZoomToSelectionWasmCall)
}

export const cmdZoomToAll: BridgeCommand = (ctx) => {
    ctx.commandInvoker.DEPRECATED_invokeBridge(wasmCmdZoomToAll)
}

export const cmdMoveViewportWhenOutsideCanvas: BridgeCommand<
    [clientX: number, clientY: number, canvasBounding: Rect]
> = (ctx, clientX, clientY, rect) => {
    ctx.commandInvoker.DEPRECATED_invokeBridge(wasmCmdMoveViewportWhenOutsideCanvas, {
        mousePoint: {
            x: clientX,
            y: clientY,
        },
        canvasBounding: {
            x: rect.x,
            y: rect.y,
            width: rect.width,
            height: rect.height,
        },
    })
}

export const cmdScaleViewportByWheel: BridgeCommand<[WheelEvent]> = (ctx, e) => {
    const { offsetX, offsetY, deltaY } = e
    const { devicePixelRatio = 1 } = window
    let expandRatio = 1
    if (isSafari() && devicePixelRatio === 2) {
        expandRatio = 2
    }
    const formattedDeltaY = +deltaY.toFixed(2) * expandRatio
    let caculatedDeltaY: number | undefined = undefined

    if (WK.scaleSpeedEvalFunction) {
        caculatedDeltaY = eval(
            WK.scaleSpeedEvalFunction.replace(/y/g, formattedDeltaY).replace(/dp/g, devicePixelRatio)
        )
    }

    ctx.commandInvoker.DEPRECATED_invokeBridge(ScaleViewportByWheelWasmCall, {
        deltaY: formattedDeltaY,
        devicePixelRatio,
        mousePoint: {
            x: offsetX,
            y: offsetY,
        },
        caculatedDeltaY,
        isOverChrome118: isChrome() && getChromeVersion()! > 118,
        ctrlKey: e.ctrlKey,
        metaKey: e.metaKey,
    })
}

export const cmdScrollSelectionIntoView: BridgeCommand = (ctx) => {
    ctx.commandInvoker.DEPRECATED_invokeBridge(wasmCmdScrollSelectionIntoView)
}
