import { RoleStatus } from '../../../../../kernel/interface/type'
import { usePluginService } from '../../../../../main/app-context'
import { PluginPermissionDialog } from '../../../../../share/plugin-permission/plugin-permission'
import { PluginDevStatus, usePluginDevelopment } from '../use-plugin-development'

export function PluginDevelopmentPermission() {
    const { setPluginDevStatus, currentEditorPermissionPlugin, currentUserId, currentOrganization } =
        usePluginDevelopment()
    const pluginService = usePluginService()

    if (!currentEditorPermissionPlugin) {
        return null
    }
    return (
        <PluginPermissionDialog
            plugin={currentEditorPermissionPlugin}
            currentUserId={currentUserId}
            onCancel={() => setPluginDevStatus(PluginDevStatus.Main)}
            onPluginLeave={() => {
                pluginService.fetchEditablePublishedPlugins()
            }}
            onPluginOwnerChange={() => {
                pluginService.fetchEditablePublishedPlugins()
            }}
            isAdmin={currentOrganization?.role === RoleStatus.Admin}
        />
    )
}
