import { translation } from './comment.translation'
/* eslint-disable no-restricted-imports */
import { useCallback, useMemo } from 'react'
import { WKToast } from '../../../../../../ui-lib/src'
import { CommentThread } from '../../../../kernel/interface/comment'
import { useCommentService } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { CommentDataTestId } from '../../../../window'
import { useActiveCommentId, useCommentUsersMap, useHoverCommentId } from '../comment-service/comment-service-hook'
import { getShowUserInfo } from '../utils'
import { CommentDOM } from './comment-dom'

export function Comment(props: { meta: CommentThread }) {
    const { meta } = props
    const docReadonly = useViewState('docReadonly')
    const { pages } = useViewState('documentPageList', { pages: [] })
    const commentService = useCommentService()
    const usersMap = useCommentUsersMap()
    const activeCommentId = useActiveCommentId()
    const hoverCommentId = useHoverCommentId()

    const isUnread = useMemo(() => {
        return meta.unread || meta.replies?.some((reply) => reply.unread)
    }, [meta.unread, meta.replies])

    const showUsers = useMemo(() => {
        return getShowUserInfo(meta.owner, meta.replies)
    }, [meta.owner, meta.replies])

    const pageName = useMemo(() => {
        if (Array.isArray(pages)) {
            return pages.find((page) => page.id === meta.page)?.name
        }
    }, [meta.page, pages])

    const onCopyLink = useCallback(() => {
        commentService.copyCommentLink(meta.id)
    }, [meta.id, commentService])
    const onSetRead = useCallback(() => {
        commentService.updateCommentAsRead(meta.id)
    }, [meta.id, commentService])
    const onSetUnread = useCallback(() => {
        commentService.setCommentsAsUnread(meta.id)
    }, [meta.id, commentService])
    const onSetResolve = useCallback(() => {
        commentService.setCommentsAsResolve(meta.id)
    }, [meta.id, commentService])
    const onSetUnResolve = useCallback(() => {
        commentService.setCommentsAsUnResolve(meta.id)
    }, [meta.id, commentService])
    const onDeleteComment = useCallback(() => {
        commentService.deleteComment(meta.id)
    }, [meta.id, commentService])
    const onClickComment = useCallback(() => {
        onSetRead()
        if (pageName === undefined) {
            WKToast.error(translation('UnableToView'))
        }
        commentService.clickOnComment(meta.id)
    }, [onSetRead, pageName, commentService, meta.id])

    return (
        <CommentDOM
            key={meta.id}
            usersMap={usersMap}
            isActive={activeCommentId === meta.id}
            isHover={hoverCommentId === meta.id}
            isUnread={isUnread}
            isResolved={meta.resolved}
            isOwner={commentService.isCurrentUser(meta.owner.id)}
            isHideResolvedIcon={docReadonly && !commentService.isCurrentUserInComment(meta)}
            showUsers={showUsers.userList}
            showUsersOverlaySize={showUsers.overflowNum}
            nickname={meta.owner.nickname}
            createdTime={meta.createdTime}
            message={meta.message}
            repliesSum={meta.replies.length}
            seq={meta.seq}
            pageName={pageName ?? translation('CanNotFind')}
            onCopyLink={onCopyLink}
            onSetRead={onSetRead}
            onSetUnread={onSetUnread}
            onSetResolve={onSetResolve}
            onSetUnResolve={onSetUnResolve}
            onDeleteComment={onDeleteComment}
            onClickComment={onClickComment}
            dataTestIds={CommentDataTestId.CommentPanelDom}
        />
    )
}
