import { useCallback } from 'react'
import { wkDialogConfirm } from '../../../../../ui-lib/src'
import { RealNameVerification } from './real-name-verificatio'

export function useRealNameVerificationTip(params: {
    onRealNameVerificationStart: () => void
    onRealNameVerificationEnd: () => void
}) {
    const { onRealNameVerificationStart, onRealNameVerificationEnd } = params

    const shouldGoToRealNameVerification = useCallback(() => {
        onRealNameVerificationStart()
        wkDialogConfirm.warning({
            wrapperTestId: 'real-name-verification-tip',
            title: '无法开启互联网公开分享',
            content: '应国家法律法规要求，完成身份认证后才可以开启互联网公开分享。请使用实名手机号进行认证。',
            okText: '去认证',
            onOk: () => {
                RealNameVerification.show({
                    onCancel: () => {
                        onRealNameVerificationEnd()
                    },
                    onOk: () => {
                        onRealNameVerificationEnd()
                    },
                })
            },
            onClose: () => {
                onRealNameVerificationEnd()
            },
        })
    }, [onRealNameVerificationEnd, onRealNameVerificationStart])

    return { shouldGoToRealNameVerification }
}
