import { useMemo } from 'react'
import { DraggablePopupV2, Position } from '../../../../../../ui-lib/src'
import { AiSearchInitBody } from './ai-search-init-body'
import { translation } from './ai-search-init-modal.translation'
import { AiSearchInitModel } from './use-ai-search'

export function AiSearchInitModal({ model }: { model: AiSearchInitModel }) {
    const position = useMemo(() => {
        return model.positionTopCenter
            ? { ...model.positionTopCenter, left: model.positionTopCenter.left - 400 / 2 }
            : undefined
    }, [model.positionTopCenter])

    const onMove = (pos: Position) => model.setPositionTopCenter({ ...pos, left: pos.left + 400 / 2 })

    return (
        <DraggablePopupV2
            testId="ai-search-init-modal"
            width={400}
            height={401}
            position={position}
            onMove={onMove}
            visible={true}
            bodyClassName="!px-24px !py-0"
            onCancel={model.onExit}
            header={translation('AISearch')}
            footer={null}
            closeTestId="ai-search-init-close"
        >
            <AiSearchInitBody model={model} />
        </DraggablePopupV2>
    )
}
