import {
    MarkEvalJsBeginCommand,
    MarkEvalJsEndCommand,
    PluginApiCancelNotify,
    PluginApiNotify,
    PluginApiNotifyCallbackJS,
} from '@wukong/bridge-proto'
import { IBasicBridge } from '../../kernel/bridge/basic-bridge'
import { IPluginAPIContext } from '../plugin-api-context-interface'
import { getFunctionHandle, Handle } from '../vm-interface'
import { ZodTypes } from '../zod-type'

let buttonActionId = 1
let onDequeuId = 1
let cancelId = 1

export function createNotifyApi(apiObject: Handle, bridge: IBasicBridge, ctx: IPluginAPIContext) {
    const deQueueCallbackMap = new Map<number, Handle>()
    const buttonActionMap = new Map<number, Handle>()

    bridge.bind(PluginApiNotifyCallbackJS, (arg) => {
        bridge.call(MarkEvalJsBeginCommand)
        if (arg.buttonActionId !== -1) {
            const action = buttonActionMap.get(arg.buttonActionId)
            if (action) {
                ctx.vm.callFunction(action, ctx.vm.null)
                buttonActionMap.delete(arg.buttonActionId)
                ctx.vm.releaseHandle(action)
            }
        }
        if (arg.onDequeueCallbackId !== -1) {
            const action = deQueueCallbackMap.get(arg.onDequeueCallbackId)
            if (action) {
                ctx.vm.callFunction(action, ctx.vm.null, ctx.vm.deepWrapHandle(arg.dequeueReason))
                deQueueCallbackMap.delete(arg.onDequeueCallbackId)
                ctx.vm.releaseHandle(action)
            }
        }
        bridge.call(MarkEvalJsEndCommand)
    })

    ctx.defineVmFunction({
        objhandle: apiObject,
        key: 'notify',
        func: (message_, options_) => {
            const message = ctx.unwrapAndValidate({
                handle: message_,
                type: ZodTypes.String,
                key: 'notify',
            })
            const options = ctx.unwrapAndValidate({
                handle: options_,
                type: ZodTypes.NotifyOptions.optional(),
                key: 'notify',
            })
            let curOnDequeueCallbackId = -1
            if (options?.onDequeue) {
                curOnDequeueCallbackId = onDequeuId++
                const functionHandle = getFunctionHandle(options?.onDequeue)
                ctx.vm.retainHandle(functionHandle)
                deQueueCallbackMap.set(curOnDequeueCallbackId, functionHandle)
            }

            let curButtonActionId = -1
            if (options?.button) {
                curButtonActionId = buttonActionId++
                const functionHandle = getFunctionHandle(options?.button.action)
                ctx.vm.retainHandle(functionHandle)
                buttonActionMap.set(curButtonActionId, functionHandle)
            }

            const curCancelId = cancelId++
            ctx.unwrapCallBridge(PluginApiNotify, {
                message: message,
                timeout: options?.timeout !== undefined ? options.timeout : 3000,
                error: options?.error !== undefined ? options.error : false,
                onDequeueCallbackId: curOnDequeueCallbackId,
                buttonText: options?.button?.text ?? '',
                buttonActionId: curButtonActionId,
                cancelId: curCancelId,
            })

            const ret = ctx.vm.newObject()
            ctx.vm.setProp(
                ret,
                'cancel',
                ctx.vm.newFunction('cancel', () => {
                    ctx.unwrapCallBridge(PluginApiCancelNotify, {
                        value: curCancelId,
                    })
                })
            )
            return ret
        },
        canUseInReadonly: true,
    })
}
