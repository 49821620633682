import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Text: '文本',
    Color: '颜色',
    Component: '组件',
    ComponentTagEmptyPrompt: '未发现组件一致性问题',
    TextTagEmptyPrompt: '未发现文本一致性问题',
    ColorTagEmptyPrompt: '未发现颜色一致性问题',
} as const

export const enTranslation = {
    Text: 'Text',
    Color: 'Color',
    Component: 'Component',
    ComponentTagEmptyPrompt: 'No component consistency issues found',
    TextTagEmptyPrompt: 'No text consistency issues found',
    ColorTagEmptyPrompt: 'No color consistency issues found',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
