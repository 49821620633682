/* eslint-disable no-restricted-imports */
import { groupBy, isNil } from 'lodash-es'
import { FontInfoVO } from '../../kernel/interface/font'
import { GetDocRequest, GetPrototypeDocRequest } from '../../kernel/request/document'
import { GetOrgFontList, GetSystemFontList, GetTeamFontList } from '../../kernel/request/font'
import type { FontInfoExt, FontNameExt, FontVariant } from './interface'
import { parseVariableFontStyle } from './util'

export function getStyle(fontInfoVOs: FontInfoVO[]): FontNameExt[] {
    const styles: FontNameExt[] = []
    fontInfoVOs.forEach((fontInfoVO) => {
        if (isNil(fontInfoVO.variationInfo) || fontInfoVO.variationInfo === '') {
            styles.push(fontInfoVO)
            return
        }

        const variationInfo: FontVariant | null = JSON.parse(fontInfoVO.variationInfo)
        if (isNil(variationInfo)) {
            return
        }
        if (variationInfo.axes.length && variationInfo.instances.length) {
            styles.push(parseVariableFontStyle(variationInfo.instances, variationInfo.axes, fontInfoVO))
        } else {
            styles.push(fontInfoVO)
        }
    })

    return styles
}

export function formatCloudFontInfoVO(vos: FontInfoVO[]) {
    const familyGroup = groupBy(vos, (f) => f.family)

    const fontInfos: FontInfoExt[] = Object.keys(familyGroup)
        .filter((family) => family !== 'Apple Color Emoji')
        .map((family: string) => {
            const infos = familyGroup[family]
            return {
                family: family,
                localizedFamily: infos[0].localizedFamily,
                styles: getStyle(infos),
            }
        })

    return fontInfos
}

export const fetchCloudFontInfo = (): Promise<FontInfoExt[]> => {
    return new GetSystemFontList().start().then(formatCloudFontInfoVO)
}

export const fetchDocumentInfo = (documentId: string) => {
    return new GetDocRequest(documentId).start()
}

export const fetchPrototypeDocumentInfo = (documentId: string, pageId: string) => {
    return new GetPrototypeDocRequest(documentId, pageId).start()
}

export const fetchTeamFontInfo = (teamId: string, docId?: string): Promise<FontInfoExt[]> => {
    return new GetTeamFontList(teamId, docId).start().then(formatCloudFontInfoVO)
}

export const fetchOrgFontInfo = (orgId: string, docId?: string): Promise<FontInfoExt[]> => {
    return new GetOrgFontList(orgId, docId).start().then(formatCloudFontInfoVO)
}
