import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    MoreOptions: '更多操作',
    TidyUp: '整理',
    DistributeHorizontal: '水平等间距分布',
    DistributeVertical: '垂直等间距分布',
    Align: '对齐',
} as const

export const enTranslation = {
    MoreOptions: 'More options',
    TidyUp: 'Tidy up',
    DistributeHorizontal: 'Distribute horizontal spacing',
    DistributeVertical: 'Distribute vertical spacing',
    Align: 'align',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
