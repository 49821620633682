import { computed } from 'ccstate'
import { traceable } from '../../../../util/src/ccstate-helper/traceable'
import { fetchCloudFontInfo } from '../../main/font/cloud-font'
import { DEFAULT_FONT_FILES } from '../../main/font/font-config'
import { fetchLocalFontInfo } from '../../main/font/local-font'

export const defaultFonts$ = traceable(
    'hulh01@kanyun.com',
    computed(async () => {
        return Promise.all(
            DEFAULT_FONT_FILES().map((url) => {
                return new Promise<{ data: Uint8Array; url: string }>((resolve, reject) => {
                    fetch(url)
                        .then(async (res) => {
                            if (!res.ok) {
                                reject(res)
                            } else {
                                resolve({ data: new Uint8Array(await res.arrayBuffer()), url })
                            }
                        })
                        .catch((e) => {
                            reject(e)
                        })
                })
            })
        ).catch((e) => {
            throw new Error(`SentryIgnoreError: 获取默认字体失败。错误信息: ${e}`)
        })
    })
)

export const localFonts$ = traceable(
    'hulh01@kanyun.com',
    computed(async () => {
        return fetchLocalFontInfo()
    })
)

export const cloudFonts$ = traceable(
    'hulh01@kanyun.com',
    computed(async () => {
        return fetchCloudFontInfo()
    })
)
