import type { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useMemo } from 'react'
import type { DeepRequired } from '../../../../../../../../util/src'
import { DevModeBoxModelTestId } from '../../../../../../window'
import { CopyableRow } from '../../common/copyable-row'
import classes from '../box-model.module.less'
import { DevModeInspectCopyableValue } from '../common/number-value'
import { formatNumberValue } from '../utils/format-value'
import { translation } from './text.translation'

export function TextBox(props: { value: DeepRequired<Wukong.DocumentProto.IDevModeInspectBoxModelText> }) {
    return (
        <div className={classNames(classes.textBoxContainer)} data-testid={DevModeBoxModelTestId.TextContent}>
            <div className={classNames(classes.fontName)}>
                <DevModeInspectCopyableValue className={classes.measureValue} value={props.value.fontName} />
            </div>
            <div className={classNames(classes.textMeasure)}>
                <FontSizeMeasure value={props.value} />
                <Thumbnail value={props.value} />
                <LineHeightMeasure value={props.value} />
            </div>
            {!!props.value.isMixed && <div className={classNames(classes.mixedInfo)}>{translation('Mixed')}</div>}
            {!!props.value.textStyleNodeName && !props.value.isMixed && (
                <div className={classNames(classes.styleNodeName)}>
                    <DevModeInspectCopyableValue
                        className={classes.measureValue}
                        value={props.value.textStyleNodeName}
                    />
                </div>
            )}
        </div>
    )
}

function Thumbnail(props: { value: DeepRequired<Wukong.DocumentProto.IDevModeInspectBoxModelText> }) {
    return (
        <div
            className={classNames(classes.textThumbnailContainer)}
            style={{ height: props.value.thumbnailData.height }}
        >
            <img
                style={{ width: props.value.thumbnailData.width, height: props.value.thumbnailData.height }}
                src={props.value.thumbnailData.data}
            />
            <span className={classNames(classes.measureLine)} style={{ top: props.value.secondMeasurementLine + 1 }} />
        </div>
    )
}

const FontThumbnailDataPadding = 9
function FontSizeMeasure(props: { value: DeepRequired<Wukong.DocumentProto.IDevModeInspectBoxModelText> }) {
    const formatFontSize = useMemo(() => formatNumberValue(props.value.fontSize.value), [props.value.fontSize.value])
    return (
        <div
            className={classNames(classes.fontSizeMeasure)}
            style={{
                height: props.value.thirdMeasurementLine - props.value.firstMeasurementLine + 1,
            }}
        >
            <div className={classNames(classes.measureContent, classes.fontLineHeightEnhancement)}>
                <CopyableRow
                    className={classes.measureValue}
                    copyValue={`${formatFontSize}${props.value.fontSize.unitSuffix}`}
                    feedback="deep"
                >
                    {translation('FontSize')}
                    <br />
                    <span>
                        {formatFontSize}
                        {props.value.fontSize.unitSuffix}
                    </span>
                </CopyableRow>
            </div>
            <span
                className={classNames(classes.measureLine)}
                style={{ width: props.value.thumbnailData.width + FontThumbnailDataPadding * 2 }}
            />
        </div>
    )
}

function LineHeightMeasure(props: { value: DeepRequired<Wukong.DocumentProto.IDevModeInspectBoxModelText> }) {
    const formatLineHeight = useMemo(
        () => formatNumberValue(props.value.lineHeight.value),
        [props.value.lineHeight.value]
    )
    return (
        <div
            className={classNames(classes.lineHeightMeasure)}
            style={{
                height: props.value.thumbnailData.height,
            }}
        >
            <div className={classNames(classes.measureContent, classes.fontLineHeightEnhancement)}>
                <CopyableRow
                    className={classes.measureValue}
                    copyValue={`${formatLineHeight}${props.value.lineHeight.unitSuffix}`}
                    feedback="deep"
                >
                    {translation('LineHeight')}
                    <br />
                    <span>
                        {formatLineHeight}
                        {props.value.lineHeight.unitSuffix}
                    </span>
                </CopyableRow>
            </div>
        </div>
    )
}
