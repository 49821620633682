import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    GridStyles: '布局网格样式',
    CreateNewGrid: '创建布局网格样式',
} as const

export const enTranslation = {
    GridStyles: 'Grid styles',
    CreateNewGrid: 'Create grid style',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
