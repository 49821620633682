import { translation } from './comment-attribute-title.translation'
/* eslint-disable no-restricted-imports */
import React from 'react'
import { IconAdd16 } from '../../../../../../../../ui-lib/src'
import { IconButton } from '../../../../atom/button/icon-button'
import { Title, TitleProps } from '../../../../atom/title/title'
import classes from './comment-attribute-title.module.less'

export interface CommentAttributeTitleProps {
    grayTheme?: TitleProps['grayTheme']
    disabledIcon?: boolean
    onClickTitle?: TitleProps['onClick']
    onClickAddIcon?: (e: React.MouseEvent<HTMLElement>) => void
}
export function CommentAttributeTitle(props: CommentAttributeTitleProps) {
    const { onClickAddIcon } = props
    return (
        <Title
            grayTheme={props.grayTheme}
            onClick={props.onClickTitle}
            className={classes.title}
            data-testid={'style-attribute-title'}
        >
            <Title.Left>{translation('Properties')}</Title.Left>
            {onClickAddIcon ? (
                <Title.Right>
                    <IconButton
                        icon={<IconAdd16 />}
                        selected={false}
                        onClick={onClickAddIcon}
                        disabled={props.disabledIcon}
                        dataTestId="add-comment-attribute"
                    />
                </Title.Right>
            ) : null}
        </Title>
    )
}
