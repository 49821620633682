import { isNumber } from 'lodash-es'
import { WKToast } from '../../../../../ui-lib/src'
import { RouteToken, domLocation } from '../../../../../util/src'
import {
    DocWithAuthorityForSearchVO,
    FolderForSearchVO,
    TeamWithAuthorityForSearchVO,
} from '../../../kernel/interface/type'
import { DocumentSearchRequest, GetDocRequest } from '../../../kernel/request/document'
import { batchAddFavoriteDocs, batchRemoveFavoriteDocs } from '../../../kernel/request/favorite'
import { FolderSearchRequest, GetFolderRequest } from '../../../kernel/request/folder'
import { GetUserRecentRecordRequest } from '../../../kernel/request/recent'
import { GetTeamsInOrg, TeamSearchRequest } from '../../../kernel/request/team'
import { SpaceLogs } from '../../../kernel/service/space-frog-service/logs'
import { SearchType } from '../../context/search-context'
import { FlatDoc } from '../../util/types'
import { useDocListSelectedState } from './doc-list-selected-state'
import { SpaceNotifyFlow } from './notify-flow'
import { translation } from './search-page-state.translation'
import { SearchPageStore, SpaceStoreType, getSpaceStore, setSpaceStore } from './types'

function getSortedRecents(docs: ReadonlyArray<FlatDoc>) {
    const list = [...docs]
    return list
        .filter((doc) => !doc.prototype)
        .sort((a, b) => {
            return (b.visitedTime ?? 0) - (a.visitedTime ?? 0)
        })
        .slice(0, 10)
}

interface WithRank {
    rank: number
}

function getSortedListByRank<T extends WithRank>(list: ReadonlyArray<T>) {
    const sortedList = [...list]
    return sortedList.sort((a, b) => {
        return a.rank - b.rank
    })
}

const findContentAndIdx = (
    list: ReadonlyArray<DocWithAuthorityForSearchVO | TeamWithAuthorityForSearchVO>,
    id: string
) => {
    const idx = list.findIndex((o) => o.id == id)
    if (idx !== -1) {
        const content = list[idx]
        return { idx, content }
    }
    return {}
}

const findFolderAndIdx = (folderList: ReadonlyArray<FolderForSearchVO>, id: string) => {
    const idx = folderList.findIndex((o) => o.folderWithAuthorityVO.id == id)
    if (idx !== -1) {
        const folder = folderList[idx]
        return { idx, folder }
    }
    return {}
}

export const generateSearchPageState = (set: setSpaceStore, get: getSpaceStore): SearchPageStore => {
    let timer: NodeJS.Timeout
    return {
        loading: false,
        beforeUrl: '',
        searchType: SearchType.doc,
        searchValue: '',
        recents: [],
        docs: [],
        docCount: 0,
        folders: [],
        folderCount: 0,
        teams: [],
        teamCount: 0,
        // 请求计数，用于判断是否是最新的请求，只展示最新的请求结果
        requestCount: 0,
        setSearchType: (type) => {
            set((state) => {
                state.searchPageStore.searchType = type
            })
        },
        setBeforeUrl: (url) => {
            set((state) => {
                state.searchPageStore.beforeUrl = url
            })
        },
        fetchRecents: async () => {
            const orgId = get().organizationStore.organization.id
            const routeInfo = get().docListPageStore.routeInfo
            if (routeInfo?.routeToken !== RouteToken.Recent) {
                new GetUserRecentRecordRequest(orgId).start().then((res) => {
                    set((state) => {
                        state.searchPageStore.recents = getSortedRecents(
                            res.map((v) => ({ ...v.document, visitedTime: v.visitedTime }))
                        )
                    })
                })
            }
        },
        _fetchData: async (qs, fromInput, reqCount) => {
            const orgId = get().organizationStore.organization.id
            const type = get().searchPageStore.searchType
            const isSearch = domLocation().pathname.includes(`/${RouteToken.Search}`)
            const docSize = !isSearch || type !== SearchType.doc ? 4 : undefined
            const folderSize = !isSearch || type !== SearchType.folder ? 4 : undefined
            const teamSize = !isSearch || type !== SearchType.team ? 4 : undefined
            try {
                if (fromInput) {
                    SpaceLogs.SearchPanel.Search({ search_query: qs })
                }
                const [docRes, folderRes, teamRes] = await Promise.all([
                    new DocumentSearchRequest(qs, orgId, docSize).start(),
                    new FolderSearchRequest(qs, orgId, folderSize).start(),
                    new TeamSearchRequest(qs, orgId, teamSize).start(),
                ])
                if (reqCount === get().searchPageStore.requestCount) {
                    set((state) => {
                        state.searchPageStore.docs = getSortedListByRank(docRes.documentWithAuthorityForSearchVOList)
                        state.searchPageStore.docCount = docRes.documentCount
                        state.searchPageStore.folders = getSortedListByRank(folderRes.folderForSearchVOList)
                        state.searchPageStore.folderCount = folderRes.folderCount
                        state.searchPageStore.teams = getSortedListByRank(teamRes.teamForSearchVOList)
                        state.searchPageStore.teamCount = teamRes.teamCount
                        state.searchPageStore.loading = false
                    })
                }
            } catch (e) {
                set((state) => {
                    state.searchPageStore.loading = false
                })
            }
        },
        fetchData: async (qs, fromInput) => {
            set((state) => {
                state.searchPageStore.searchValue = qs
            })
            clearTimeout(timer)
            if (qs.trim()) {
                const newCount = get().searchPageStore.requestCount + 1
                set((state) => {
                    state.searchPageStore.loading = true
                    state.searchPageStore.requestCount = newCount
                })
                timer = setTimeout(() => {
                    get().searchPageStore._fetchData(qs, fromInput, newCount)
                }, 200)
            } else {
                set((state) => {
                    state.searchPageStore.docs = []
                    state.searchPageStore.docCount = 0
                    state.searchPageStore.folders = []
                    state.searchPageStore.folderCount = 0
                    state.searchPageStore.teams = []
                    state.searchPageStore.teamCount = 0
                    state.searchPageStore.loading = false
                })
                get().searchPageStore.fetchRecents()
            }
        },
        clear: () => {
            set((state) => {
                state.searchPageStore.searchValue = ''
                state.searchPageStore.docs = []
                state.searchPageStore.docCount = 0
                state.searchPageStore.folders = []
                state.searchPageStore.folderCount = 0
                state.searchPageStore.teams = []
                state.searchPageStore.teamCount = 0
                state.searchPageStore.loading = false
            })
        },
        setSelectedDocs: (docIds = []) => {
            const docs = get().searchPageStore.docs.filter((doc) => docIds.includes(doc.id))
            useDocListSelectedState.getState().set(docs)
        },
        favoriteDoc: async (docIds, isFavorite) => {
            const orgId = get().organizationStore.organization.id
            if (isFavorite) {
                await batchRemoveFavoriteDocs(
                    docIds.map((id) => ({ id, isPrototype: false })),
                    orgId
                )
            } else {
                await batchAddFavoriteDocs(
                    docIds.map((id) => ({ id, isPrototype: false })),
                    orgId
                )
            }
            if (isFavorite) {
                WKToast.show(
                    docIds.length > 1
                        ? `${docIds.length} ${translation('FilesRemovedFrom_synonyms1')}`
                        : translation('FileRemovedFrom_synonyms1')
                )
            } else {
                WKToast.show(
                    docIds.length > 1 ? `${docIds.length} ${translation('FilesAddedTo')}` : translation('FileAddedTo')
                )
            }
            get().searchPageStore.setSelectedDocs([])
            set((state) => {
                docIds.forEach((docId) => {
                    const { content, idx } = findContentAndIdx(state.searchPageStore.docs, docId)
                    if (content) {
                        state.searchPageStore.docs[idx].favorites = !isFavorite
                    }
                })
            })
        },
        _changeDocName: ({ id, name }) => {
            const { idx } = findContentAndIdx(get().searchPageStore.docs, id)
            if (isNumber(idx)) {
                set((state) => {
                    state.searchPageStore.docs[idx].name = name
                })
            }
        },
        _changeDocFavorite: ({ id, type }) => {
            const { idx } = findContentAndIdx(get().searchPageStore.docs, id)
            if (isNumber(idx)) {
                set((state) => {
                    state.searchPageStore.docs[idx].favorites = type === 'add'
                })
            }
        },
        _updateDoc: async (id) => {
            const hasDoc = get().searchPageStore.docs.some((doc) => doc.id === id)
            if (hasDoc) {
                const doc = await new GetDocRequest(id).start()
                const { idx } = findContentAndIdx(get().searchPageStore.docs, id)
                if (isNumber(idx)) {
                    set((state) => {
                        state.searchPageStore.docs[idx].draft = doc.draft
                        state.searchPageStore.docs[idx].folderId = doc.folderId
                        state.searchPageStore.docs[idx].folderName = doc.folderName
                        state.searchPageStore.docs[idx].folderRole = doc.folderRole
                        state.searchPageStore.docs[idx].lastEditedTime = doc.lastEditedTime
                        state.searchPageStore.docs[idx].libraryDocument = doc.libraryDocument
                        state.searchPageStore.docs[idx].role = doc.role
                        state.searchPageStore.docs[idx].temporaryRole = doc.temporaryRole
                    })
                }
            }
        },
        _changeFolderFavorite: ({ id, type }) => {
            const { idx } = findFolderAndIdx(get().searchPageStore.folders, id)
            if (isNumber(idx)) {
                set((state) => {
                    state.searchPageStore.folders[idx].folderWithAuthorityVO.favorites = type === 'add'
                })
            }
        },
        _updateFolder: async (id) => {
            const hasFolder = get().searchPageStore.folders.some((folder) => folder.folderWithAuthorityVO.id === id)
            if (hasFolder) {
                const folder = await new GetFolderRequest(id).start()
                const { idx } = findFolderAndIdx(get().searchPageStore.folders, id)
                if (isNumber(idx)) {
                    set((state) => {
                        state.searchPageStore.folders[idx].folderWithAuthorityVO.name = folder.name
                        state.searchPageStore.folders[idx].folderWithAuthorityVO.basicInfoUpdatedTime =
                            folder.basicInfoUpdatedTime
                        state.searchPageStore.folders[idx].folderWithAuthorityVO.updatedTime = folder.updatedTime
                        state.searchPageStore.folders[idx].folderWithAuthorityVO.roleEnum = folder.roleEnum
                        state.searchPageStore.folders[idx].folderWithAuthorityVO.teamRoleEnum = folder.teamRoleEnum
                        state.searchPageStore.folders[idx].folderWithAuthorityVO.teamPublicStatus =
                            folder.teamPublicStatus
                        state.searchPageStore.folders[idx].folderWithAuthorityVO.teamMemberPermission =
                            folder.teamMemberPermission
                    })
                }
            }
        },
        _updateTeam: async (id) => {
            const hasTeam = get().searchPageStore.teams.some((team) => team.id === id)
            if (hasTeam) {
                const orgId = get().organizationStore.organization.id
                const allTeams = await new GetTeamsInOrg(orgId).start()
                const team = allTeams.find((t) => t.id === id)
                const { idx } = findContentAndIdx(get().searchPageStore.teams, id)
                if (isNumber(idx) && team) {
                    set((state) => {
                        state.searchPageStore.teams[idx].name = team.name
                        state.searchPageStore.teams[idx].icon = team.icon
                        state.searchPageStore.teams[idx].updatedTime = team.updatedTime
                        state.searchPageStore.teams[idx].role = team.role
                        state.searchPageStore.teams[idx].publicStatus = team.publicStatus
                        state.searchPageStore.teams[idx].memberCount = team.memberCount
                    })
                }
            }
        },
    }
}

const isInSearchPage = () => {
    const pathname = domLocation().pathname
    return pathname.includes(RouteToken.Search)
}

export const syncSearchPageState = (store: SpaceStoreType, notifyFlow: SpaceNotifyFlow): void => {
    store.subscribe(
        (state) => state.docListPageStore.docList,
        (docList) => {
            const routeInfo = store.getState().docListPageStore.routeInfo
            if (routeInfo?.routeToken === RouteToken.Recent) {
                store.setState((state) => {
                    state.searchPageStore.recents = getSortedRecents(docList)
                })
            }
        }
    )
    notifyFlow.addDocNameChangeCallback((msg) => {
        if (isInSearchPage()) {
            store.getState().searchPageStore._changeDocName(msg)
        }
    })
    notifyFlow.addFavoriteDocChangeCallback(({ id, type }) => {
        if (isInSearchPage()) {
            store.getState().searchPageStore._changeDocFavorite({ id, type })
        }
    })
    notifyFlow.addDocOnlineUserChangeCallback((id) => {
        if (isInSearchPage()) {
            store.getState().searchPageStore._updateDoc(id)
        }
    })
    notifyFlow.addDocRelationChangeCallback((msg) => {
        if (isInSearchPage()) {
            store.getState().searchPageStore._updateDoc(msg.docId)
            store.getState().searchPageStore._updateFolder(msg.folderId)
        }
    })

    notifyFlow.addFavoriteFolderChangeCallback(({ id, type }) => {
        if (isInSearchPage()) {
            store.getState().searchPageStore._changeFolderFavorite({ id, type })
        }
    })
    notifyFlow.addFolderPropertyChangeCallback((id) => {
        if (isInSearchPage()) {
            store.getState().searchPageStore._updateFolder(id)
        }
    })
    notifyFlow.addFolderRelationChangeCallback((msg) => {
        if (isInSearchPage()) {
            store.getState().searchPageStore._updateFolder(msg.folderId)
        }
    })

    notifyFlow.addTeamPropertyChangeCallback((id) => {
        if (isInSearchPage()) {
            store.getState().searchPageStore._updateTeam(id)
        }
    })
    notifyFlow.addTeamRelationChangeCallback((msg) => {
        if (isInSearchPage()) {
            store.getState().searchPageStore._updateTeam(msg.teamId)
        }
    })
}
