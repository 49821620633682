import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    TotalLayers: '图层数量',
    MemoryUsage: '内存使用',
} as const

export const enTranslation = {
    TotalLayers: 'Total layers',
    MemoryUsage: 'Memory usage',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
