import classNames from 'classnames'
import { Wukong } from '@wukong/bridge-proto'
import { Checkbox, MonoIconPanelArrowRight16, WKDivider } from '../../../../../../ui-lib/src'
import { LibraryComponentViewer } from '../../../../share/component-style-library/library-component-viewer/library-component-viewer'
import { LibraryStyleViewer } from '../../../../share/component-style-library/library-style-viewer/library-style-viewer'
import { DeepRequired } from '../../../../view-state-bridge'
import { LibraryTestId } from '../../../../window'
import classes from './library-replace-display-item.module.less'
import { translation } from './library-replace-display-item.translation'
import {
    generateDisplayItemCss,
    useLibraryReplaceComponentItem,
    useLibraryReplaceStyleItem,
} from './use-library-replace-display-item'

export function LibraryReplaceDisplayItem(props: {
    displayItem: DeepRequired<Wukong.DocumentProto.IVLibraryReplaceModalDisplayItem>
    selectTargetLibrary: boolean
}) {
    switch (props.displayItem.displayType) {
        case Wukong.DocumentProto.LibraryReplaceModalItemDisplayType.LIBRARY_REPLACE_MODAL_ITEM_DISPLAY_TYPE_DIVIDER:
            return <WKDivider className="absolute left-0 my-2" style={generateDisplayItemCss(props.displayItem)} />
        case Wukong.DocumentProto.LibraryReplaceModalItemDisplayType
            .LIBRARY_REPLACE_MODAL_ITEM_DISPLAY_TYPE_STYLE_TITLE:
            return <StyleTitleDisplayItem displayItem={props.displayItem} />
        case Wukong.DocumentProto.LibraryReplaceModalItemDisplayType
            .LIBRARY_REPLACE_MODAL_ITEM_DISPLAY_TYPE_STYLE_REPLACE_ITEM:
            return <StyleDisplayItem displayItem={props.displayItem} selectTargetLibrary={props.selectTargetLibrary} />
        case Wukong.DocumentProto.LibraryReplaceModalItemDisplayType
            .LIBRARY_REPLACE_MODAL_ITEM_DISPLAY_TYPE_COMPONENT_TITLE:
            return <ComponentTitleDisplayItem displayItem={props.displayItem} />
        case Wukong.DocumentProto.LibraryReplaceModalItemDisplayType
            .LIBRARY_REPLACE_MODAL_ITEM_DISPLAY_TYPE_COMPONENT_REPLACE_ITEM:
        case Wukong.DocumentProto.LibraryReplaceModalItemDisplayType
            .LIBRARY_REPLACE_MODAL_ITEM_DISPLAY_TYPE_COMPONENT_SET_REPLACE_ITEM:
            return (
                <ComponentDisplayItem displayItem={props.displayItem} selectTargetLibrary={props.selectTargetLibrary} />
            )
        default:
            return <></>
    }
}

export function StyleTitleDisplayItem(props: {
    displayItem: DeepRequired<Wukong.DocumentProto.IVLibraryReplaceModalDisplayItem>
}) {
    return (
        <div className={classNames(classes.titleContainer)} style={generateDisplayItemCss(props.displayItem)}>
            <span className="color-$wk-v2-label-color-gray-8 wk-text-12">{translation('UsedStyle')}</span>
            <span className="color-$wk-v2-label-color-gray-8 wk-text-12">{translation('NewStyle')}</span>
        </div>
    )
}

export function StyleDisplayItem(
    props: Parameters<typeof useLibraryReplaceStyleItem>[0] & { selectTargetLibrary: boolean }
) {
    const { originViewerItem, targetViewerItem, onToggleChecked } = useLibraryReplaceStyleItem(props)

    return (
        <div
            className={classNames(classes.displayItemContainer)}
            style={generateDisplayItemCss(props.displayItem)}
            onClick={onToggleChecked}
            data-testid={LibraryTestId.ReplaceModal.StyleReplaceItem}
        >
            <div className="flex items-center">
                <Checkbox
                    className="ml-2.5 mr-3"
                    checked={Boolean(props.displayItem.checked)}
                    disabled={!props.displayItem.target}
                />

                <div className="flex-none">
                    <LibraryStyleViewer style={originViewerItem} disableRightClick hiddenName />
                </div>
                <span
                    className={classNames(
                        'flex-auto wk-text-12 color-$wk-v2-label-color-gray-13 block truncate',
                        classes.styleName
                    )}
                >
                    {props.displayItem.origin.name}
                </span>
            </div>
            <MonoIconPanelArrowRight16 className="color-$wk-v2-label-color-gray-13 ml-3 mr-1" />
            {targetViewerItem ? (
                <div className="flex items-center">
                    <div className="flex-none">
                        <LibraryStyleViewer style={targetViewerItem} disableRightClick hiddenName />
                    </div>
                    <span
                        className={classNames(
                            'flex-auto wk-text-12 color-$wk-v2-label-color-gray-13 block truncate',
                            classes.styleName
                        )}
                    >
                        {props.displayItem.target?.name}
                    </span>
                </div>
            ) : (
                props.selectTargetLibrary && (
                    <span className="wk-text-12 color-$wk-gray-6 ml-1">{translation('NoneFound')}</span>
                )
            )}
        </div>
    )
}

export function ComponentTitleDisplayItem(props: {
    displayItem: DeepRequired<Wukong.DocumentProto.IVLibraryReplaceModalDisplayItem>
}) {
    return (
        <div className={classNames(classes.titleContainer)} style={generateDisplayItemCss(props.displayItem)}>
            <span className="color-$wk-v2-label-color-gray-8 wk-text-12">{translation('UsedComponent')}</span>
            <span className="color-$wk-v2-label-color-gray-8 wk-text-12">{translation('NewComponent')}</span>
        </div>
    )
}

export function ComponentDisplayItem(
    props: Parameters<typeof useLibraryReplaceComponentItem>[0] & { selectTargetLibrary: boolean }
) {
    const { originViewerItem, targetViewerItem, onToggleChecked } = useLibraryReplaceComponentItem(props)

    return (
        <div
            className={classNames(classes.displayItemContainer)}
            style={generateDisplayItemCss(props.displayItem)}
            onClick={onToggleChecked}
            data-testid={
                props.displayItem.displayType ===
                Wukong.DocumentProto.LibraryReplaceModalItemDisplayType
                    .LIBRARY_REPLACE_MODAL_ITEM_DISPLAY_TYPE_COMPONENT_SET_REPLACE_ITEM
                    ? LibraryTestId.ReplaceModal.ComponentSetReplaceItem
                    : LibraryTestId.ReplaceModal.ComponentReplaceItem
            }
        >
            <div className="flex items-center">
                <Checkbox
                    className="ml-2.5 mr-3"
                    checked={props.displayItem.checked}
                    disabled={!props.displayItem.target}
                />
                <div className="flex-none">
                    <LibraryComponentViewer
                        component={originViewerItem}
                        selected={false}
                        disableRightClick
                        hiddenName
                        innerStyle={{ width: 80, height: 80 }}
                    />
                </div>
                <div className="flex-auto ml-2">
                    <span
                        className={classNames(
                            'wk-text-12 color-$wk-v2-label-color-gray-13 block truncate',
                            classes.componentName
                        )}
                    >
                        {props.displayItem.origin.name}
                    </span>
                </div>
            </div>
            <MonoIconPanelArrowRight16 className="color-$wk-v2-label-color-gray-13 ml-3 mr-1" />
            {targetViewerItem ? (
                <div className="flex items-center">
                    <div className="flex-none ml-1">
                        <LibraryComponentViewer
                            component={targetViewerItem}
                            selected={false}
                            disableRightClick
                            hiddenName
                            innerStyle={{ width: 80, height: 80 }}
                        />
                    </div>
                    <div className="flex-auto ml-2">
                        <span
                            className={classNames(
                                'wk-text-12 color-$wk-v2-label-color-gray-13 block truncate',
                                classes.componentName
                            )}
                        >
                            {props.displayItem.target?.name}
                        </span>
                    </div>
                </div>
            ) : (
                props.selectTargetLibrary && (
                    <span className="wk-text-12 color-$wk-gray-6 ml-1">{translation('NoneFound_synonyms1')}</span>
                )
            )}
        </div>
    )
}
