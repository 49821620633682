import { isNil } from 'lodash-es'
import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { MonoIconCommonInfoLine16, MonoIconCommonWarningLine16, WKAlert, WKButton } from '../../../../../../ui-lib/src'
import { isEnglishLanguage } from '../../../../../../util/src'
import { useUserInfoContext } from '../../../../auth/auth-guard/context/user'
import { Ticket } from '../../../../document/synergy/synergy-ticket'
import { useTicket } from '../../../../external-store/atoms/ticket'
import { PayPlanType, PlanAndUserStateVO, SeatType, UserID } from '../../../../kernel/interface/type'
import { usePlanAndUserStateService } from '../../../../main/app-context'
import {
    useDocFolderTeamData,
    useSetDevModeAccessibleBannerShown,
    useSetExceedUsageLimitBannerShown,
    useSetFreezeBannerShown,
} from '../../../../main/layout/layout-context'
import { PayApplySeatOfDevMode } from '../../../../share/payment/pay-seat-apply-dev-mode'
import { PayUpgradeDialog } from '../../../../share/payment/pay-upgrade-dialog/pay-upgrade-dialog'
import {
    PayUpgradeDialogFrom,
    PayUpgradeDialogType,
} from '../../../../share/payment/pay-upgrade-dialog/pay-upgrade-dialog-struct/type'
import { UpgradePopupType, WKFrogTask } from '../../../../share/wk-frog-task'
import { checkUserIsAdminInPayment, getUserSeatStatus, UserSeatStatus } from '../../../../utils/payment'
import { isActiveOrgPlan, isActiveProPlan, isStarterPlan } from '../../../../utils/plan-status-checker'
import { useDocInfoContext } from '../../../context/top-area-context'
import styles from './index.module.less'
import { translation } from './index.translation'
import { formattedText, userCloseReminderInGracePeriod, userHasCloseReminderInGracePeriod } from './util'
export function getAlertOfDevModeUnavailableInfo(
    planAndUserState: PlanAndUserStateVO | undefined,
    isDevMode: boolean,
    userId: UserID
) {
    const returnNull = { show: false, title: null, button: null, closeable: false }
    if (!isDevMode) {
        return returnNull
    }
    const hasActiveProPlan = planAndUserState && isActiveProPlan(planAndUserState)
    const hasActiveOrgPlan = planAndUserState && isActiveOrgPlan(planAndUserState)

    const handleSuffixInPayingResource = () => {
        if (!hasActiveOrgPlan && !hasActiveProPlan) {
            return returnNull
        }

        const userSeatStatus = getUserSeatStatus(planAndUserState!, SeatType.developer)
        switch (userSeatStatus) {
            case UserSeatStatus.InPermanentSeat:
            case UserSeatStatus.InAutoUpgrade: {
                return returnNull
            }
            case UserSeatStatus.InGracePeriod: {
                if (userHasCloseReminderInGracePeriod(userId, planAndUserState!.resourceId)) {
                    return returnNull
                } else {
                    return {
                        show: true,
                        title: formattedText(translation('SeatIsApplying'), translation('ReminderInGracePeriod')),
                        button: null,
                        closeable: true,
                    }
                }
            }
            case UserSeatStatus.CanApplyGracePeriod: {
                const isAdmin = checkUserIsAdminInPayment(planAndUserState!)
                return {
                    show: true,
                    title: translation('DevModeBannerDesc'),
                    button: isAdmin ? translation('StartUse') : translation('ApplyDevSeat'),
                    closeable: false,
                }
            }
            case UserSeatStatus.CanApplyAutoUpgrade: {
                return {
                    show: true,
                    title: translation('DevModeBannerDesc'),
                    button: translation('StartUse'),
                    closeable: false,
                }
            }
            case UserSeatStatus.NeedWaitToBeApproved: {
                return {
                    show: true,
                    title: formattedText(translation('SeatIsApplying'), translation('ReminderInWaittingToApproved')),
                    button: null,
                    closeable: false,
                }
            }
            case UserSeatStatus.NeedReApply: {
                const isAdmin = checkUserIsAdminInPayment(planAndUserState!)
                return {
                    show: true,
                    title: isAdmin ? (
                        translation('DevModeBannerDesc')
                    ) : (
                        <>
                            <span>{translation('ReminderInNeedingReApplySub1')}</span>
                            <span className={isEnglishLanguage() ? styles.seg : ''}>
                                {translation('ReminderInNeedingReApplySub2')}
                            </span>
                        </>
                    ),
                    button: isAdmin ? translation('StartUse') : translation('ApplyDevSeat'),
                    closeable: false,
                }
            }
        }
    }

    const hasStarterPlan = planAndUserState && isStarterPlan(planAndUserState)
    if (hasStarterPlan) {
        return {
            show: true,
            title: <span>{translation('DevModeBannerDesc')}</span>,
            button: translation('OpenDevMode'),
            closeable: false,
        }
    } else if (hasActiveProPlan || hasActiveOrgPlan) {
        return handleSuffixInPayingResource()
    } else {
        return returnNull
    }
}

export function DevModeUnavailableAlertBanner({ isDevMode }: { isDevMode: boolean }) {
    const { userInfo } = useUserInfoContext()
    const { docData } = useDocFolderTeamData()
    const [storageChange, setStorageChange] = useState(false)

    const planAndUserStateService = usePlanAndUserStateService()
    const planAndUserState = planAndUserStateService.useZustandStore.use.planAndUserState()
    const userRole = planAndUserStateService.useZustandStore.use.userRole()

    const alertInfo = useMemo(
        () => getAlertOfDevModeUnavailableInfo(planAndUserState, isDevMode, userInfo.userId),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isDevMode, planAndUserState, userInfo.userId, storageChange]
    )

    const setDevModeAccessibleShown = useSetDevModeAccessibleBannerShown()
    useLayoutEffect(() => {
        setDevModeAccessibleShown(alertInfo.show)
    }, [alertInfo.show, setDevModeAccessibleShown])

    const handleClose = () => {
        userCloseReminderInGracePeriod(userInfo.userId, planAndUserState?.resourceId!)
        setStorageChange(true)
    }
    const handleClickButton = useCallback(() => {
        const hasStarterPlan = planAndUserState && isStarterPlan(planAndUserState)
        if (hasStarterPlan) {
            WKFrogTask.payment.UpgradePopupType(UpgradePopupType.OpenDevMode)
            PayUpgradeDialog.show({
                type: PayUpgradeDialogType.All,
                currentPlanType: PayPlanType.starter,
                organizationProps: {
                    submitProps: {
                        from: PayUpgradeDialogFrom.Editor,
                        teamId: docData?.teamId,
                    },
                },
                professionalProps: {
                    submitProps: {
                        from: PayUpgradeDialogFrom.Editor,
                        teamId: docData?.teamId,
                    },
                },
            })
        } else {
            const userSeatStatus = getUserSeatStatus(planAndUserState!, SeatType.developer)
            if (
                userSeatStatus == UserSeatStatus.CanApplyGracePeriod ||
                userSeatStatus == UserSeatStatus.CanApplyAutoUpgrade ||
                userSeatStatus == UserSeatStatus.NeedReApply
            ) {
                PayApplySeatOfDevMode({
                    userRole: userRole!,
                    userSeatStatus: userSeatStatus!,
                    resourceId: planAndUserState?.resourceId!,
                    resourceType: planAndUserState?.resourceType!,
                    unresolvedSeatTypes: planAndUserState?.unresolvedApplicationSeatTypes ?? [],
                    docId: docData?.id!,
                    userId: userInfo.userId,
                    isEnterprise: planAndUserState?.planType === PayPlanType.enterprise,
                    orgId: docData?.orgId!,
                    isSomeWorkspaceAdmin: !!planAndUserState?.adminWorkspaceIds?.length,
                })
            }
        }
    }, [docData?.id, docData?.orgId, docData?.teamId, planAndUserState, userInfo.userId, userRole])

    if (!alertInfo.show || !docData) {
        return null
    }

    return (
        <WKAlert.WithoutTitle
            data-testid="alert-banner-dev-mode-unavailable"
            color="success"
            icon={<MonoIconCommonInfoLine16 className="color-$wk-green-7" />}
            closeable={alertInfo.closeable}
            onClose={handleClose}
            suffix={
                !isNil(alertInfo.button) ? (
                    <WKButton
                        size="small"
                        type="secondary"
                        onClick={handleClickButton}
                        className={styles.alertOfDevModeUnavailableButton}
                    >
                        {alertInfo.button}
                    </WKButton>
                ) : null
            }
            closeButtonTestId="alert-close-dev-mode-unavailable"
        >
            {alertInfo.title}
        </WKAlert.WithoutTitle>
    )
}

export function getFreezeAlertInEditorShown(isDevMode: boolean, ticket: Ticket | undefined) {
    // 冻结: 研发模式-出alert; 设计模式-对有编辑权限的人出alert
    return !!(ticket?.planFreeze && (isDevMode ? true : ticket.canEditDocByUpgradePlan))
}

export function FreezeAlertBanner({ isDevMode }: { isDevMode: boolean }) {
    const ticket = useTicket()
    const setFreezeBannerShow = useSetFreezeBannerShown()
    const showFreezeAlertInDevMode = getFreezeAlertInEditorShown(isDevMode, ticket)

    const planAndUserStateService = usePlanAndUserStateService()
    const planAndUserState = planAndUserStateService.useZustandStore.use.planAndUserState()

    const isAdmin = useMemo(
        () => (planAndUserState ? checkUserIsAdminInPayment(planAndUserState) : false),
        [planAndUserState]
    )

    useLayoutEffect(() => {
        setFreezeBannerShow(showFreezeAlertInDevMode)
    }, [setFreezeBannerShow, showFreezeAlertInDevMode])

    const titleText = useMemo(() => {
        if (ticket) {
            return ticket.orgId == '-1'
                ? isAdmin
                    ? translation('FreezeOfTeamAdmin')
                    : translation('FreezeOfTeamMember')
                : ticket.trial
                ? isAdmin
                    ? translation('FreezeOfTrialOrgAdmin')
                    : translation('FreezeOfTrialOrgMember')
                : isAdmin
                ? translation('FreezeOfOrgAdmin')
                : translation('FreezeOfOrgMember')
        }
    }, [isAdmin, ticket])

    if (!showFreezeAlertInDevMode) {
        return null
    }
    const title = (
        <>
            <span
                style={{
                    fontWeight: 'var(--wk-font-weight-medium)',
                }}
            >
                {isDevMode ? translation('UnableUseDevMode') : translation('UnableUseDesignMode')}
            </span>
            <span>{titleText}</span>
        </>
    )

    return (
        <WKAlert.WithoutTitle
            data-testid="FreezeDevModeAlert"
            color="deepRed"
            icon={<MonoIconCommonWarningLine16 className="color-white" />}
        >
            {title}
        </WKAlert.WithoutTitle>
    )
}

export function getExceedUsageLimitForFreeTeamShown(isDevMode: boolean, ticket: Ticket | undefined) {
    return !!(ticket?.exceedUsageLimit && ticket?.canEditDocByUpgradePlan && !isDevMode)
}

export function ExceedUsageLimitForFreeTeamBanner() {
    const { docData } = useDocInfoContext()
    const ticket = useTicket()
    const showExceedUsageLimitForFreeTeam = getExceedUsageLimitForFreeTeamShown(false, ticket)
    const setExceedLimitBannerShow = useSetExceedUsageLimitBannerShown()
    useLayoutEffect(() => {
        setExceedLimitBannerShow(showExceedUsageLimitForFreeTeam)
    }, [setExceedLimitBannerShow, showExceedUsageLimitForFreeTeam])

    if (!showExceedUsageLimitForFreeTeam) {
        return null
    }

    const title = (
        <>
            <span className={styles.exceedUsageLimitPrefixTitle}>{translation('UnableToEditFile')}</span>
            <span>{translation('UnableToEditFileReason')}</span>
        </>
    )

    const handleViewPlans = () => {
        PayUpgradeDialog.show({
            type: PayUpgradeDialogType.All,
            currentPlanType: PayPlanType.starter,
            organizationProps: {
                submitProps: {
                    from: PayUpgradeDialogFrom.Editor,
                    teamId: docData?.teamId,
                },
            },
            professionalProps: {
                submitProps: {
                    from: PayUpgradeDialogFrom.Editor,
                    teamId: docData?.teamId,
                },
            },
        })
    }

    return (
        <WKAlert.WithoutTitle
            color="deepYellow"
            className="!pr-4"
            data-testid="exceed-usage-limit-banner-in-doc"
            suffix={
                <WKButton
                    className={styles.exceedUsageLimitSuffix}
                    size="small"
                    type="secondary"
                    onClick={handleViewPlans}
                >
                    {translation('ViewPlans')}
                </WKButton>
            }
        >
            {title}
        </WKAlert.WithoutTitle>
    )
}
