/* eslint-disable no-restricted-imports */
import classNames from 'classnames'
import { useMemo } from 'react'
import { Paint } from '../../../../../document/node/node'
import { base64BlackWhite_240_120 } from '../../../../assets/common/common-base64'
import { ReactComponent as StrokeDot } from '../../../../assets/strokes/stroke-dot.svg'
import {
    usePaintStylePreviewThumbnail,
    usePaintStyleThumbnail,
} from '../../../../utils/thumbnail-cache/paints-thumbnail'
import styles from './style-thumbnail.module.less'

export interface PaintStyleThumbnailProps {
    className?: string
    testId?: string
    stroke?: boolean
    paints: readonly Paint[]
    width: number
    height: number
    mode?: 'preview' | 'thumbnail'
    multiple?: number
}

export function PaintStyleThumbnail(props: PaintStyleThumbnailProps): JSX.Element {
    return props.mode === 'preview' ? <ModePreview {...props} /> : <ModeNotPreview {...props} />
}

function ModeNotPreview(props: PaintStyleThumbnailProps) {
    const { stroke, className, paints, ...otherProps } = props
    const url = usePaintStyleThumbnail({
        ...otherProps,
        paints,
    })

    return (
        <PaintStyleThumbnailDOM
            isPreview={false}
            url={url}
            stroke={stroke}
            className={className}
            dataTestId={props.testId}
        />
    )
}

function ModePreview(props: PaintStyleThumbnailProps) {
    const { stroke, className, paints, ...otherProps } = props
    const url = usePaintStylePreviewThumbnail({
        ...otherProps,
        paints,
    })
    return (
        <PaintStyleThumbnailDOM
            isPreview={true}
            url={url}
            stroke={stroke}
            className={className}
            dataTestId={props.testId}
        />
    )
}

function PaintStyleThumbnailDOM(props: {
    isPreview?: boolean
    url?: string
    stroke?: boolean
    className?: string
    dataTestId?: string
}) {
    const thumbnailSrc = useMemo(() => {
        if (props.url) {
            return props.url
        }
        return base64BlackWhite_240_120
    }, [props.url])

    return (
        <div className={classNames([styles.nailBorder, props.isPreview && styles.preview, props.className])}>
            {props.stroke && <StrokeDot className={styles.imgEmptyCircle} />}
            <img alt={'paint style thumbnail'} src={thumbnailSrc} draggable={false} data-testid={props.dataTestId} />
        </div>
    )
}
