import { Wukong } from '@wukong/bridge-proto'

interface Point {
    x: number
    y: number
}

export function createTransforms(
    width: number,
    height: number,
    canvasWidth: number,
    canvasHeight: number,
    inset: number
) {
    const cx = canvasWidth / 2
    const cy = canvasHeight / 2
    const scale = Math.max(width / (canvasWidth - inset * 2) || 1, height / (canvasHeight - inset * 2) || 1)
    function tx(x: number) {
        return cx + (x - width / 2) / scale
    }
    function ty(y: number) {
        return cy + (y - height / 2) / scale
    }
    return { tx, ty }
}

export function drawVertex(x: number, y: number, color: string, ctx: CanvasRenderingContext2D) {
    ctx.fillStyle = color
    ctx.beginPath()
    ctx.arc(x, y, 2.5, 0, Math.PI * 2, false)
    ctx.fill()
}

export function drawSegment(
    sx: number,
    sy: number,
    csx: number,
    csy: number,
    cex: number,
    cey: number,
    ex: number,
    ey: number,
    color: string,
    ctx: CanvasRenderingContext2D
) {
    ctx.strokeStyle = color
    ctx.beginPath()
    ctx.moveTo(sx, sy)
    ctx.bezierCurveTo(csx, csy, cex, cey, ex, ey)
    ctx.stroke()
}

function hasVertex(seg: Wukong.DocumentProto.IVectorSegment, index: number) {
    return seg.start === index || seg.end === index
}

function startingVertex(vn: Wukong.DocumentProto.IVectorNetwork, loop: number[]) {
    const { segments } = vn
    if (loop.length === 1) {
        return segments![loop[0]].start
    }

    const first = segments![loop[0]]
    const second = segments![loop[1]]
    let takeStart = hasVertex(second, first.end!)
    const takeEnd = hasVertex(second, first.start!)

    if (takeStart && takeEnd && loop.length > 2) {
        const third = segments![loop[2]]
        takeStart = hasVertex(third, first.start!)
    }

    if (takeStart) {
        return first.start
    }
    return first.end
}

function tangentForVertex(seg: Wukong.DocumentProto.IVectorSegment, index: number) {
    if (index === seg.start) {
        return seg.tangentStart ?? { x: 0, y: 0 }
    } else {
        return seg.tangentEnd ?? { x: 0, y: 0 }
    }
}

function otherVertex(seg: Wukong.DocumentProto.IVectorSegment, index: number) {
    if (index === seg.start) {
        return seg.end
    } else {
        return seg.start
    }
}

export function* segmentIterator(vn: Wukong.DocumentProto.IVectorNetwork, loop: number[]) {
    const { vertices, segments } = vn
    let start = startingVertex(vn, loop)

    for (const index of loop) {
        const segment = segments![index]
        const end = otherVertex(segment, start!)
        const ts = tangentForVertex(segment, start!)
        const te = tangentForVertex(segment, end!)
        const s = vertices![start!]
        const e = vertices![end!]
        const cs = { x: s.x! + ts!.x!, y: s.y! + ts!.y! }
        const ce = { x: e.x! + te!.x!, y: e.y! + te!.y! }
        start = end
        yield { s, cs, ce, e }
    }
}

export function outlineLoop(
    tx: (x: number) => number,
    ty: (y: number) => number,
    vn: Wukong.DocumentProto.IVectorNetwork,
    loop: number[],
    ctx: CanvasRenderingContext2D
) {
    let isFirst = true
    for (const { s, cs, ce, e } of segmentIterator(vn, loop)) {
        if (isFirst) {
            isFirst = false
            ctx.moveTo(tx(s.x!), ty(s.y!))
        }
        ctx.bezierCurveTo(tx(cs.x), ty(cs.y), tx(ce.x), ty(ce.y), tx(e.x!), ty(e.y!))
    }
}

export function cubic1D(a: number, b: number, c: number, d: number, t: number) {
    const s = 1 - t
    return a * s * s * s + b * 3 * s * s * t + c * 3 * s * t * t + d * t * t * t
}

export function cubicDerivative1D(a: number, b: number, c: number, d: number, t: number) {
    const s = 1 - t
    return (b - a) * 3 * s * s + (c - b) * 6 * s * t + (d - c) * 3 * t * t
}

function createBoundingBox() {
    return {
        xmin: Infinity,
        ymin: Infinity,
        xmax: -Infinity,
        ymax: -Infinity,

        includePoint(x: number, y: number) {
            this.xmin = Math.min(this.xmin, x)
            this.ymin = Math.min(this.ymin, y)
            this.xmax = Math.max(this.xmax, x)
            this.ymax = Math.max(this.ymax, y)
        },

        containsPoint(point: Point, threshold: number) {
            return (
                point.x >= this.xmin - threshold &&
                point.y >= this.ymin - threshold &&
                point.x <= this.xmax + threshold &&
                point.y <= this.ymax + threshold
            )
        },
    }
}

function midpoint(a: Point, b: Point) {
    return { x: (a.x + b.x) / 2, y: (a.y + b.y) / 2 }
}

function subdivideCubic(a: Point, b: Point, c: Point, d: Point) {
    const ab = midpoint(a, b)
    const bc = midpoint(b, c)
    const cd = midpoint(c, d)
    const abc = midpoint(ab, bc)
    const bcd = midpoint(bc, cd)
    const abcd = midpoint(abc, bcd)
    return [
        [a, ab, abc, abcd],
        [abcd, bcd, cd, d],
    ]
}

function isPointNearCubic(s: Point, cs: Point, ce: Point, e: Point, point: Point, threshold: number) {
    const bb = createBoundingBox()
    bb.includePoint(s.x, s.y)
    bb.includePoint(cs.x, cs.y)
    bb.includePoint(ce.x, ce.y)
    bb.includePoint(e.x, e.y)

    if (!bb.containsPoint(point, threshold)) {
        return false
    }

    function visit(ts: Point, tcs: Point, tce: Point, te: Point, depth: number): boolean {
        // Subdivision
        if (depth < 4) {
            const [[s1, cs1, ce1, e1], [s2, cs2, ce2, e2]] = subdivideCubic(ts, tcs, tce, te)
            return visit(s1, cs1, ce1, e1, depth + 1) || visit(s2, cs2, ce2, e2, depth + 1)
        }

        // Closest point to line
        const xse = te.x - ts.x
        const yse = te.y - ts.y
        const xsp = point.x - ts.x
        const ysp = point.y - ts.y
        let t = (xse * xsp + yse * ysp) / (xse * xse + yse * yse)
        t = Math.max(0, Math.min(1, t))

        // Distance check
        const dx = ts.x + xse * t - point.x
        const dy = ts.y + yse * t - point.y
        return dx * dx + dy * dy < threshold * threshold
    }

    return visit(s, cs, ce, e, 0)
}

export function isPointInsideRegion(
    tx: (x: number) => number,
    ty: (y: number) => number,
    vn: Wukong.DocumentProto.IVectorNetwork,
    region: Wukong.DocumentProto.IVectorRegion,
    point: Point
) {
    const bb = createBoundingBox()
    for (const loop of region.loops!) {
        for (const { s, cs, ce, e } of segmentIterator(vn, loop.segmentIndices!)) {
            bb.includePoint(tx(s.x!), ty(s.y!))
            bb.includePoint(tx(cs.x), ty(cs.y))
            bb.includePoint(tx(ce.x), ty(ce.y))
            bb.includePoint(tx(e.x!), ty(e.y!))
        }
    }

    if (!bb.containsPoint(point, 0)) {
        return false
    }

    function visit(s: Point, cs: Point, ce: Point, e: Point, depth: number) {
        const xmax = Math.max(s.x, cs.x, ce.x, e.x)
        const ymin = Math.min(s.y, cs.y, ce.y, e.y)
        const ymax = Math.max(s.y, cs.y, ce.y, e.y)

        // Culling
        if (point.x > xmax || point.y < ymin || point.y > ymax) {
            return
        }

        // Subdivision
        if (depth < 4) {
            const [[s1, cs1, ce1, e1], [s2, cs2, ce2, e2]] = subdivideCubic(s, cs, ce, e)
            visit(s1, cs1, ce1, e1, depth + 1)
            visit(s2, cs2, ce2, e2, depth + 1)
            return
        }

        // Upward crossing
        if (s.y <= point.y && point.y < e.y) {
            const cross = (point.y - s.y) * (e.x - s.x) - (point.x - s.x) * (e.y - s.y)
            if (cross > 0) {
                crossingCount++
            }
        }

        // Downward crossing
        else if (e.y <= point.y && point.y < s.y) {
            const cross = (point.y - s.y) * (e.x - s.x) - (point.x - s.x) * (e.y - s.y)
            if (cross < 0) {
                crossingCount--
            }
        }
    }

    let crossingCount = 0

    for (const loop of region.loops!) {
        for (const { s, cs, ce, e } of segmentIterator(vn, loop.segmentIndices!)) {
            const ts = { x: tx(s.x!), y: ty(s.y!) }
            const tcs = { x: tx(cs.x), y: ty(cs.y) }
            const tce = { x: tx(ce.x), y: ty(ce.y) }
            const te = { x: tx(e.x!), y: ty(e.y!) }
            visit(ts, tcs, tce, te, 0)
        }
    }

    if (region.windingRule === Wukong.DocumentProto.WindingRule.WINDING_RULE_EVENODD) {
        crossingCount &= 1
    }

    return crossingCount !== 0
}

export function hitTestLoops(
    tx: (x: number) => number,
    ty: (y: number) => number,
    point: Point,
    threshold: number,
    vn: Wukong.DocumentProto.IVectorNetwork
) {
    for (let regionIndex = vn.regions!.length - 1; regionIndex >= 0; --regionIndex) {
        const loops = vn.regions![regionIndex].loops!
        for (let loopIndex = loops.length - 1; loopIndex >= 0; --loopIndex) {
            for (const { s, cs, ce, e } of segmentIterator(vn, loops[loopIndex].segmentIndices!)) {
                const ts = { x: tx(s.x!), y: ty(s.y!) }
                const tcs = { x: tx(cs.x), y: ty(cs.y) }
                const tce = { x: tx(ce.x), y: ty(ce.y) }
                const te = { x: tx(e.x!), y: ty(e.y!) }
                if (isPointNearCubic(ts, tcs, tce, te, point, threshold)) {
                    return { type: 'loop', regionIndex, loopIndex }
                }
            }
        }
    }
    return null
}
