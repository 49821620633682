import { SVGAttributes } from 'react'
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconLayoutAlignLeft = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 1.5H1V14.5H2V1.5Z" fill="#1B1F26" />
        <path d="M4 4.5H14V6.5H4V4.5Z" fill="#1B1F26" />
        <path d="M4 9.5H10V11.5H4V9.5Z" fill="#1B1F26" />
        <path d="M4 4.5H14V6.5H4V4.5Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconLayoutAlignHorizontalCenters = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 4.5H13.5V6.5H2.5V4.5Z" fill="#1B1F26" />
        <path d="M4.5 9.5H11.5V11.5H4.5V9.5Z" fill="#1B1F26" />
        <path d="M7.5 1.5H8.5V14.5H7.5V1.5Z" fill="#1B1F26" />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconLayoutAlignRight = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 4.5H12V6.5H2V4.5Z" fill="#1B1F26" />
        <path d="M6 9.5H12V11.5H6V9.5Z" fill="#1B1F26" />
        <path d="M14 1.5H15V14.5H14V1.5Z" fill="#1B1F26" />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconLayoutTop = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.5 4H6.5V14H4.5V4Z" fill="#1B1F26" />
        <path d="M9.5 4H11.5V10H9.5V4Z" fill="#1B1F26" />
        <path d="M1.5 1H14.5V2H1.5V1Z" fill="#1B1F26" />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconLayoutAlignVerticalCenters = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.5 13.5H4.5V2.5H6.5V13.5Z" fill="#1B1F26" />
        <path fillRule="evenodd" clipRule="evenodd" d="M14.5 8.5H1.5L1.5 7.5H14.5V8.5Z" fill="#1B1F26" />
        <path fillRule="evenodd" clipRule="evenodd" d="M11.5 11.5H9.5V4.5H11.5V11.5Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconLayoutDown = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.5 2H6.5V12H4.5V2Z" fill="#1B1F26" />
        <path d="M9.5 6H11.5V12H9.5V6Z" fill="#1B1F26" />
        <path d="M1.5 14H14.5V15H1.5V14Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconTidyUpHorizontal = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect x="2" y="2" width="2" height="12" rx="0.5" fill="#1B1F26" />
        <rect x="7" y="2" width="2" height="12" rx="0.5" fill="#1B1F26" />
        <rect x="12" y="2" width="2" height="12" rx="0.5" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconTidyUpVertical = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect x="2" y="14" width="2" height="12" rx="0.5" transform="rotate(-90 2 14)" fill="#1B1F26" />
        <rect x="2" y="9" width="2" height="12" rx="0.5" transform="rotate(-90 2 9)" fill="#1B1F26" />
        <rect x="2" y="4" width="2" height="12" rx="0.5" transform="rotate(-90 2 4)" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconTidyUpMultiDim = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect x="2.5" y="14" width="2" height="2" rx="0.5" transform="rotate(-90 2.5 14)" fill="#1B1F26" />
        <rect x="2.5" y="9" width="2" height="2" rx="0.5" transform="rotate(-90 2.5 9)" fill="#1B1F26" />
        <rect x="2.5" y="4" width="2" height="2" rx="0.5" transform="rotate(-90 2.5 4)" fill="#1B1F26" />
        <rect x="7.5" y="14" width="2" height="2" rx="0.5" transform="rotate(-90 7.5 14)" fill="#1B1F26" />
        <rect x="7.5" y="9" width="2" height="2" rx="0.5" transform="rotate(-90 7.5 9)" fill="#1B1F26" />
        <rect x="7.5" y="4" width="2" height="2" rx="0.5" transform="rotate(-90 7.5 4)" fill="#1B1F26" />
        <rect x="12.5" y="14" width="2" height="2" rx="0.5" transform="rotate(-90 12.5 14)" fill="#1B1F26" />
        <rect x="12.5" y="9" width="2" height="2" rx="0.5" transform="rotate(-90 12.5 9)" fill="#1B1F26" />
        <rect x="12.5" y="4" width="2" height="2" rx="0.5" transform="rotate(-90 12.5 4)" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconTidyUpDisabled = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect x="2" y="14" width="2" height="12" rx="0.5" transform="rotate(-90 2 14)" fill="#C2C3C4" />
        <rect x="2" y="9" width="2" height="12" rx="0.5" transform="rotate(-90 2 9)" fill="#C2C3C4" />
        <rect x="2" y="4" width="2" height="12" rx="0.5" transform="rotate(-90 2 4)" fill="#C2C3C4" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconDistributeHorizontalSpacing = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M7 11.5L7 4.5H9L9 11.5H7Z" fill="#1B1F26" />
        <path d="M2 1.5V14.5H3L3 1.5H2Z" fill="#1B1F26" />
        <path d="M13 1.5V14.5H14L14 1.5H13Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconDistributeVerticalSpacing = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.5 7L11.5 7V9L4.5 9L4.5 7Z" fill="#1B1F26" />
        <path d="M14.5 2L1.5 2L1.5 3L14.5 3V2Z" fill="#1B1F26" />
        <path d="M14.5 13L1.5 13L1.5 14L14.5 14L14.5 13Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconTidyUpListVertical = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.5 2L14.5 2V4L1.5 4L1.5 2Z" fill="#1B1F26" />
        <path d="M1.5 7L14.5 7V9L1.5 9L1.5 7Z" fill="#1B1F26" />
        <path d="M14.5 12L1.5 12L1.5 14L14.5 14V12Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconTidyUpListHorizontal = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2 14.5L2 1.5L4 1.5L4 14.5L2 14.5Z" fill="#1B1F26" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7 14.5L7 1.5L9 1.5L9 14.5H7Z" fill="#1B1F26" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12 14.5L12 1.5L14 1.5L14 14.5H12Z" fill="#1B1F26" />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconTidyUpGrid = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2 4L2 2L4 2L4 4L2 4Z" fill="#1B1F26" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2 9L2 7H4L4 9H2Z" fill="#1B1F26" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2 14L2 12H4L4 14H2Z" fill="#1B1F26" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7 4V2L9 2L9 4L7 4Z" fill="#1B1F26" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7 9V7H9L9 9H7Z" fill="#1B1F26" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7 14V12H9L9 14H7Z" fill="#1B1F26" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12 4V2L14 2V4L12 4Z" fill="#1B1F26" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12 9V7H14V9H12Z" fill="#1B1F26" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12 14V12H14V14H12Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconStrokeWeight = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 3L14 3L14 4L2 4L2 3ZM2 6L14 6L14 8L2 8L2 6ZM14 10L2 10L2 13L14 13L14 10Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconOrientationPortrait = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 3H5V13H11V3ZM5 2C4.44772 2 4 2.44772 4 3V13C4 13.5523 4.44772 14 5 14H11C11.5523 14 12 13.5523 12 13V3C12 2.44772 11.5523 2 11 2H5Z"
            fill="#1B1F26"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconOrientationlandscape = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 11L13 5L3 5L3 11L13 11ZM14 5C14 4.44772 13.5523 4 13 4L3 4C2.44772 4 2 4.44771 2 5L2 11C2 11.5523 2.44771 12 3 12L13 12C13.5523 12 14 11.5523 14 11L14 5Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconResizeToFit = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.7248 2L5.43744 4.71264V2.36236H6.46246V5.43744V6.46246H5.43744H2.36236V5.43744L4.71264 5.43744L2 2.7248L2.7248 2ZM2.7248 14L5.43744 11.2874V13.6376H6.46246V10.5626V9.53754H5.43744H2.36236V10.5626H4.71264L2 13.2752L2.7248 14ZM10.5626 4.71264L13.2752 2L14 2.7248L11.2874 5.43744L13.6376 5.43744V6.46246H10.5626H9.53754V5.43744V2.36236H10.5626V4.71264ZM13.2752 14L10.5626 11.2874V13.6376H9.53754V10.5626V9.53754H10.5626H13.6376V10.5626H11.2874L14 13.2752L13.2752 14Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconFreeScale = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="lock-ratio-false"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5 10V11.5C10.5 12.8807 9.38071 14 8 14C6.61929 14 5.5 12.8807 5.5 11.5V10H4.5V11.5C4.5 13.433 6.067 15 8 15C9.933 15 11.5 13.433 11.5 11.5V10H10.5ZM10.5 6H11.5V4.5C11.5 2.567 9.933 1 8 1C6.067 1 4.5 2.567 4.5 4.5V6H5.5V4.5C5.5 3.11929 6.61929 2 8 2C9.38071 2 10.5 3.11929 10.5 4.5V6Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconConstraintScale = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="lock-ratio-true"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5 11.5V10H11.5V11.5C11.5 13.433 9.933 15 8 15C6.067 15 4.5 13.433 4.5 11.5V10H5.5V11.5C5.5 12.8807 6.61929 14 8 14C9.38071 14 10.5 12.8807 10.5 11.5ZM11.5 6H10.5V4.5C10.5 3.11929 9.38071 2 8 2C6.61929 2 5.5 3.11929 5.5 4.5V6H4.5V4.5C4.5 2.567 6.067 1 8 1C9.933 1 11.5 2.567 11.5 4.5V6ZM8.5 5H7.5V11H8.5V5Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconCorners = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 3H9.5V2H12C13.1046 2 14 2.89543 14 4V6.5H13V4C13 3.44772 12.5523 3 12 3ZM3 6.5V4C3 3.44772 3.44772 3 4 3H6.5V2H4C2.89543 2 2 2.89543 2 4V6.5H3ZM2 9.5V12C2 13.1046 2.89543 14 4 14H6.5V13H4C3.44772 13 3 12.5523 3 12V9.5H2ZM13 9.5H14V12C14 13.1046 13.1046 14 12 14H9.5V13H12C12.5523 13 13 12.5523 13 12V9.5Z"
            fill="#1B1F26"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconAdjust = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 2H5V8.06301C5.86261 8.28503 6.5 9.06808 6.5 10C6.5 10.9319 5.86261 11.715 5 11.937V14H4V11.937C3.13739 11.715 2.5 10.9319 2.5 10C2.5 9.06808 3.13739 8.28503 4 8.06301V2ZM4.5 11C5.05228 11 5.5 10.5523 5.5 10C5.5 9.44772 5.05228 9 4.5 9C3.94772 9 3.5 9.44772 3.5 10C3.5 10.5523 3.94772 11 4.5 11ZM12 2H11V4.06301C10.1374 4.28503 9.5 5.06808 9.5 6C9.5 6.93192 10.1374 7.71497 11 7.93699V14H12V7.93699C12.8626 7.71497 13.5 6.93192 13.5 6C13.5 5.06808 12.8626 4.28503 12 4.06301V2ZM11.5 7C12.0523 7 12.5 6.55228 12.5 6C12.5 5.44772 12.0523 5 11.5 5C10.9477 5 10.5 5.44772 10.5 6C10.5 6.55228 10.9477 7 11.5 7Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconStyle = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.5 3C3.67157 3 3 3.67157 3 4.5C3 5.32843 3.67157 6 4.5 6C5.32843 6 6 5.32843 6 4.5C6 3.67157 5.32843 3 4.5 3ZM4.5 10C3.67157 10 3 10.6716 3 11.5C3 12.3284 3.67157 13 4.5 13C5.32843 13 6 12.3284 6 11.5C6 10.6716 5.32843 10 4.5 10ZM10 4.5C10 3.67157 10.6716 3 11.5 3C12.3284 3 13 3.67157 13 4.5C13 5.32843 12.3284 6 11.5 6C10.6716 6 10 5.32843 10 4.5ZM11.5 10C10.6716 10 10 10.6716 10 11.5C10 12.3284 10.6716 13 11.5 13C12.3284 13 13 12.3284 13 11.5C13 10.6716 12.3284 10 11.5 10Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconMote = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 8C4 8.82843 3.32843 9.5 2.5 9.5C1.67157 9.5 1 8.82843 1 8C1 7.17157 1.67157 6.5 2.5 6.5C3.32843 6.5 4 7.17157 4 8ZM9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8ZM13.5 9.5C14.3284 9.5 15 8.82843 15 8C15 7.17157 14.3284 6.5 13.5 6.5C12.6716 6.5 12 7.17157 12 8C12 8.82843 12.6716 9.5 13.5 9.5Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconCoordinateX = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.40002 12H5.59534L8.12659 8.25586L7.42932 8.625H7.90979L10.1598 12H11.4254L8.31409 7.43555L8.56604 8.25V7.78125L11.4899 3.54492H10.2887L7.75159 7.32422L8.44885 6.94922H7.96838L5.70667 3.54492H4.43518L7.28284 7.72852V8.19727L7.53479 7.38281L4.40002 12Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconCoordinateY = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.47266 12H8.52734V8.36133L11.5273 3.54492H10.3262L8.04688 7.30078H7.95312L5.67383 3.54492H4.47266L7.47266 8.36133V12Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconCoordinateW = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.02344 12H6.01953L7.96484 5.32617H8.03516L9.98047 12H10.9766L13.2793 3.54492H12.1777L10.4961 10.418H10.4258L8.5332 3.54492H7.4668L5.57422 10.418H5.50391L3.82227 3.54492H2.7207L5.02344 12Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconCoordinateH = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.5 12H5.55469V8.15039H10.1953V12H11.25V3.54492H10.1953V7.20117H5.55469V3.54492H4.5V12Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconAngle = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.5 3.5H4.5V6.58296C7.0125 7.00448 8.99552 8.9875 9.41704 11.5H12.5V12.5H9.5H8.5H4.5H3.5V11.5V7.5V6.5V3.5ZM8.39998 11.5C8.0023 9.54087 6.45913 7.9977 4.5 7.60002V11.5L8.39998 11.5Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconCorners2 = (props: SVGAttributes<SVGPathElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5 4.5H11.5H12.5V3.5H11.5H7.5C5.29086 3.5 3.5 5.29086 3.5 7.5V11.5V12.5H4.5V11.5V7.5C4.5 5.84315 5.84315 4.5 7.5 4.5Z"
            fill="#1B1F26"
            {...props}
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconStarCount = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.5 13.5V9.20752L4.4645 12.243L3.75739 11.5359L6.79331 8.5H2.5V7.5H6.79346L3.75747 4.46401L4.46458 3.75691L7.5 6.79233V2.5H8.5V6.79331L11.5356 3.75774L12.2427 4.46484L9.20752 7.5H13.5V8.5H9.20767L12.2428 11.5351L11.5356 12.2422L8.5 9.20654V13.5H7.5Z"
                fill="#1B1F26"
            />
        </svg>
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconRatio = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.91807 5.8754L10.68 7.99995L7.91807 10.1245C7.03718 8.84977 7.03718 7.15013 7.91807 5.8754ZM7.12451 5.26497L4.69519 3.39626L5.3049 2.60364L11.8049 7.60364L12.3201 7.99995L11.8049 8.39626L5.3049 13.3963L4.69519 12.6036L7.12451 10.7349C5.96837 9.10079 5.96837 6.89911 7.12451 5.26497Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconOvalEdit = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.51226 3.52746C8.34775 3.50932 8.18058 3.5 8.01123 3.5C5.52088 3.5 3.50204 5.51472 3.50204 8C3.50204 10.4853 5.52088 12.5 8.01123 12.5C10.3322 12.5 12.2437 10.75 12.4929 8.5H13.5C13.2469 11.3033 10.8861 13.5 8.01123 13.5C4.96746 13.5 2.5 11.0376 2.5 8C2.5 4.96243 4.96746 2.5 8.01123 2.5C8.18012 2.5 8.34724 2.50758 8.51226 2.52242V3.52746ZM10.4662 8.5C10.2341 9.64112 9.22318 10.5 8.01123 10.5C6.6277 10.5 5.50613 9.38071 5.50613 8C5.50613 6.61929 6.6277 5.5 8.01123 5.5C8.18282 5.5 8.35037 5.51722 8.51226 5.55001V6.58535C8.35555 6.53008 8.18691 6.5 8.01123 6.5C7.18111 6.5 6.50817 7.17157 6.50817 8C6.50817 8.82843 7.18111 9.5 8.01123 9.5C8.66568 9.5 9.22243 9.0826 9.42877 8.5H10.4662Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconComponentLibrary16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        data-testid="IconComponentLibrary16"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5077 2.1718L8 2.5L8.4923 2.1718C9.14937 1.73375 9.9214 1.5 10.7111 1.5H13.5H14.5V2.5V12.5V13.5H13.5H10.7111C9.9214 13.5 9.14937 13.7338 8.4923 14.1718L8 14.5L7.5077 14.1718C6.85063 13.7338 6.0786 13.5 5.2889 13.5H2.5H1.5V12.5V2.5V1.5H2.5H5.2889C6.0786 1.5 6.85063 1.73375 7.5077 2.1718ZM7.5 3.36852L7.4453 3.33205L6.953 3.00385C6.4602 2.67531 5.88117 2.5 5.2889 2.5H2.5V12.5H5.2889C6.05858 12.5 6.81483 12.6776 7.5 13.0155V3.36852ZM8.5 13.0155V3.36852L8.5547 3.33205L9.047 3.00385C9.5398 2.67531 10.1188 2.5 10.7111 2.5H13.5V12.5H10.7111C9.94142 12.5 9.18517 12.6776 8.5 13.0155Z"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconList = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="icon-layout-list-svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 2.5H14V3.5H6V2.5ZM4 2.5H2V3.5H4V2.5ZM4 7.5H2V8.5H4V7.5ZM4 12.5H2V13.5H4V12.5ZM6 13.5H14V12.5H6V13.5ZM6 8.5V7.5H14V8.5H6Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconGrid = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="icon-layout-grid-svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 6V3H6V6H3ZM2 2.5C2 2.22386 2.22386 2 2.5 2H6.5C6.77614 2 7 2.22386 7 2.5V6.5C7 6.77614 6.77614 7 6.5 7H2.5C2.22386 7 2 6.77614 2 6.5V2.5ZM3 13V10H6V13H3ZM2 9.5C2 9.22386 2.22386 9 2.5 9H6.5C6.77614 9 7 9.22386 7 9.5V13.5C7 13.7761 6.77614 14 6.5 14H2.5C2.22386 14 2 13.7761 2 13.5V9.5ZM10 3V6H13V3H10ZM9.5 2C9.22386 2 9 2.22386 9 2.5V6.5C9 6.77614 9.22386 7 9.5 7H13.5C13.7761 7 14 6.77614 14 6.5V2.5C14 2.22386 13.7761 2 13.5 2H9.5ZM10 13V10H13V13H10ZM9 9.5C9 9.22386 9.22386 9 9.5 9H13.5C13.7761 9 14 9.22386 14 9.5V13.5C14 13.7761 13.7761 14 13.5 14H9.5C9.22386 14 9 13.7761 9 13.5V9.5Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconEffect = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.5 2.5H7.5V4.5H8.5V2.5ZM8.5 13.5V11.5H7.5V13.5H8.5ZM11.5 8.5H13.5V7.5H11.5V8.5ZM4.5 8.5V7.5H2.5V8.5H4.5ZM12.2427 11.5352L11.5356 12.2423L10.1214 10.828L10.8285 10.1209L12.2427 11.5352ZM5.87866 5.17188L5.17156 5.87898L3.75734 4.46477L4.46445 3.75766L5.87866 5.17188ZM3.75749 11.5361L4.4646 12.2432L5.87881 10.829L5.17171 10.1218L3.75749 11.5361ZM10.1214 5.1718L10.8285 5.87891L12.2427 4.46469L11.5356 3.75759L10.1214 5.1718ZM9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8ZM10.5 8C10.5 9.38071 9.38071 10.5 8 10.5C6.61929 10.5 5.5 9.38071 5.5 8C5.5 6.61929 6.61929 5.5 8 5.5C9.38071 5.5 10.5 6.61929 10.5 8Z"
            fill="#1B1F26"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconEffectStyle = (props: SVGAttributes<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        {...props}
    >
        <path
            transform="matrix(1 0 0 1 1 0.999532)"
            d="M7.5 0L6.5 0L6.5 3L7.5 3L7.5 0ZM4.52466 3.81836L3.81755 4.52547L1.6967 2.40413L2.40381 1.69702L4.52466 3.81836ZM9.47508 3.81847L10.1822 4.52557L12.3036 2.40421L11.5964 1.6971L9.47508 3.81847ZM10 7.00073C10 5.34399 8.65686 4.00073 7 4.00073C5.34314 4.00073 4 5.34399 4 7.00073C4 8.65747 5.34314 10.0007 7 10.0007C8.65686 10.0007 10 8.65747 10 7.00073ZM8.41421 5.58643C8.02368 5.19604 7.55227 5.00073 7 5.00073C6.44772 5.00073 5.97631 5.19604 5.58578 5.58643C5.19525 5.97705 5 6.44849 5 7.00073C5 7.55298 5.19525 8.02441 5.58578 8.41504C5.97631 8.80542 6.44772 9.00073 7 9.00073C7.55227 9.00073 8.02368 8.80542 8.41421 8.41504C8.80475 8.02441 9 7.55298 9 7.00073C9 6.44849 8.80475 5.97705 8.41421 5.58643ZM2.99994 7.5L2.99994 6.5L0 6.5L0 7.5L2.99994 7.5ZM10.9999 7.5L13.9999 7.5L13.9999 6.5L10.9999 6.5L10.9999 7.5ZM12.3031 11.5963L11.5962 12.3031L9.47466 10.1821L10.1818 9.47498L12.3031 11.5963ZM1.69707 11.5965L2.40417 12.3036L4.52542 10.1823L3.81831 9.47524L1.69707 11.5965ZM7.5 14L7.5 11L6.5 11L6.5 14L7.5 14Z"
            fillRule="evenodd"
            fill="rgb(26, 29, 37)"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconBlendEmpty = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 13C10.2091 13 12 11.2091 12 9C12 8.58083 11.7978 7.98741 11.36 7.25363C10.936 6.54292 10.3559 5.80684 9.75293 5.12921C9.1534 4.45543 8.55077 3.86111 8.09643 3.43414C8.06346 3.40315 8.03129 3.37307 8 3.34394C7.96871 3.37307 7.93654 3.40315 7.90357 3.43414C7.44923 3.86111 6.8466 4.45543 6.24707 5.12921C5.64412 5.80684 5.06404 6.54292 4.64003 7.25363C4.20224 7.98741 4 8.58083 4 9C4 11.2091 5.79086 13 8 13ZM8 14C10.7614 14 13 11.7614 13 9C13 6.23858 8 2 8 2C8 2 3 6.23858 3 9C3 11.7614 5.23858 14 8 14Z"
            fill="#c2c3c4"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconBlend = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 9L12 9C12 8.58083 11.7978 7.98741 11.36 7.25363C10.936 6.54292 10.3559 5.80684 9.75293 5.12921C9.1534 4.45543 8.55077 3.86111 8.09643 3.43414C8.06346 3.40315 8.03129 3.37307 8 3.34394C7.96871 3.37307 7.93654 3.40315 7.90357 3.43414C7.44923 3.86111 6.8466 4.45543 6.24707 5.12921C5.64412 5.80684 5.06404 6.54292 4.64003 7.25363C4.20224 7.98741 4 8.58083 4 9ZM13 9C13 11.7614 10.7614 14 8 14C5.23858 14 3 11.7614 3 9C3 6.23858 8 2 8 2C8 2 13 6.23858 13 9Z"
            fill="#c2c3c4"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconEyeDropper = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5658 2.58918C11.3514 1.80361 12.6252 1.80361 13.4108 2.58918C14.1964 3.37476 14.1964 4.64843 13.4108 5.43401L11.2772 7.56749L12.3439 8.63417L11.277 9.70097L10.2107 8.63467L4.845 14L2.03092 13.9691L2 11.1552L7.36566 5.78984L6.29829 4.72253L7.36516 3.65573L8.43217 4.72267L10.5658 2.58918ZM8.07691 6.50105L3.01045 11.5672L3.02591 12.9742L4.43295 12.9896L9.49941 7.92346L8.07691 6.50105Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconPolygonCount = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 5C8.55228 5 9 4.55228 9 4C9 3.44772 8.55228 3 8 3C7.44772 3 7 3.44772 7 4C7 4.55228 7.44772 5 8 5ZM8 13C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13ZM12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9ZM3 8C3 8.55228 3.44772 9 4 9C4.55228 9 5 8.55228 5 8C5 7.44772 4.55228 7 4 7C3.44772 7 3 7.44772 3 8Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconTextResizeHeight = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 3H14V4H2V3ZM2 7.5H14V8.5H2V7.5ZM10 12H2V13H10V12Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconTextAlignLeft = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 3H14V4H2V3ZM2 7.5H8V8.5H2V7.5ZM10 12H2V13H10V12Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconTextAlignCenter = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 3H14V4H2V3ZM5 7.5H11V8.5H5V7.5ZM12 12H4V13H12V12Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconTextAlignRight = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 3H14V4H2V3ZM8 7.5H14V8.5H8V7.5ZM14 12H6V13H14V12Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconAutoWidthHorizontal = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.7932 8.49951L10.1464 10.1464L10.8535 10.8535L13.7073 7.99941L10.8535 5.14636L10.1465 5.85357L11.7929 7.49951H4.20723L5.85347 5.85359L5.14643 5.14641L2.29285 7.99944L5.14636 10.8535L5.85354 10.1465L4.20689 8.49951H11.7932Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconLineheight = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 1H14V2H2V1ZM2 14H14V15H2V14ZM4 12L7.372 3.43201H8.512L11.884 12H10.816L9.904 9.60001H5.968L5.056 12H4ZM7.924 4.48801L6.28 8.77201H9.592L7.972 4.48801H7.924Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconLetterSpacing = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 13.999V1.99902H2L2 13.999H1ZM14 13.999L14 1.99902H15L15 13.999H14ZM4 11.999L7.372 3.43103H8.512L11.884 11.999H10.816L9.904 9.59903H5.968L5.056 11.999H4ZM7.924 4.48703L6.28 8.77103H9.592L7.972 4.48703H7.924Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconLineheight2 = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.35359 5.35351L3.50004 4.20706V11.7928L2.35359 10.6464L1.64648 11.3535L3.64648 13.3535L4.00004 13.7071L4.35359 13.3535L6.35359 11.3535L5.64648 10.6464L4.50004 11.7928V4.20706L5.64648 5.35351L6.35359 4.6464L4.35359 2.6464L4.00004 2.29285L3.64648 2.6464L1.64648 4.6464L2.35359 5.35351ZM8.00004 4.49995H14V3.49995H8.00004V4.49995ZM14 8.49995H8.00004V7.49995H14V8.49995ZM8.00004 12.5H14V11.5H8.00004V12.5Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconTextDecorationUnderline = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.5 7.5L5.5 3L4.5 3L4.5 7.5C4.5 9.433 6.067 11 8 11C9.933 11 11.5 9.433 11.5 7.5V3H10.5V7.5C10.5 8.88071 9.38071 10 8 10C6.61929 10 5.5 8.88071 5.5 7.5ZM12.5 13V12L3.5 12L3.5 13L12.5 13Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconTextDecorationStrikeThrough = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.868 2.5C6.832 2.5 5.978 2.724 5.32 3.172C4.578 3.648 4.214 4.334 4.214 5.202C4.214 6.042 4.592 6.686 5.362 7.134C5.684 7.302 6.454 7.582 7.658 7.946C8.778 8.268 9.492 8.506 9.772 8.674C10.43 9.024 10.766 9.514 10.766 10.144C10.766 10.676 10.514 11.096 10.01 11.404C9.506 11.712 8.834 11.866 8.022 11.866C7.126 11.866 6.454 11.67 5.992 11.306C5.488 10.9 5.18 10.256 5.082 9.388H3.948C4.032 10.62 4.466 11.53 5.25 12.132C5.922 12.636 6.846 12.888 8.022 12.888C9.212 12.888 10.15 12.636 10.85 12.146C11.55 11.642 11.9 10.942 11.9 10.074C11.9 9.164 11.48 8.464 10.654 7.96C10.234 7.708 9.366 7.386 8.05 6.994C7.084 6.714 6.468 6.504 6.216 6.364C5.642 6.056 5.362 5.65 5.362 5.146C5.362 4.572 5.6 4.152 6.076 3.872C6.496 3.62 7.07 3.508 7.812 3.508C8.624 3.508 9.254 3.676 9.702 4.04C10.136 4.39 10.416 4.922 10.542 5.65H11.676C11.578 4.6 11.2 3.802 10.528 3.27C9.884 2.752 8.988 2.5 7.868 2.5Z"
            fill="#1B1F26"
        />
        <path d="M2.5 7.192H13.5V8.192H2.5V7.192Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconTextTransformUpper = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.5 12H1.57549L3.98824 4.91016H4.31259V3.54492H3.52731L0.5 12ZM1.8714 9.58008H6.21889L5.90023 8.68359H2.19006L1.8714 9.58008ZM6.49203 12H7.56753L4.54021 3.54492H4.07929V4.91016L6.49203 12Z"
            fill="#1B1F26"
        />
        <path
            d="M12.0118 12.1992C12.7136 12.1992 13.3244 12.0547 13.8441 11.7656C14.3676 11.4766 14.7735 11.0723 15.0618 10.5527C15.3539 10.0332 15.5 9.42578 15.5 8.73047V7.69922H12.1711V8.625H14.4757V8.83008C14.4757 9.30664 14.3733 9.72461 14.1684 10.084C13.9636 10.4434 13.6753 10.7246 13.3035 10.9277C12.9355 11.127 12.5068 11.2266 12.0175 11.2266C11.4674 11.2266 10.9913 11.0879 10.5892 10.8105C10.1908 10.5293 9.88164 10.1309 9.66161 9.61523C9.44537 9.0957 9.33725 8.48242 9.33725 7.77539V7.76367C9.33725 7.06445 9.44727 6.45703 9.6673 5.94141C9.88733 5.42578 10.1965 5.02734 10.5948 4.74609C10.997 4.46094 11.4693 4.31836 12.0118 4.31836C12.6225 4.31836 13.1309 4.46289 13.5368 4.75195C13.9427 5.04102 14.2291 5.45898 14.3961 6.00586L14.4131 6.06445H15.4488L15.4374 6.00586C15.3312 5.4707 15.1225 5.00391 14.8115 4.60547C14.5042 4.20703 14.1115 3.89844 13.6335 3.67969C13.1593 3.45703 12.6187 3.3457 12.0118 3.3457C11.2644 3.3457 10.61 3.5293 10.0486 3.89648C9.4909 4.25977 9.05842 4.77344 8.75114 5.4375C8.44385 6.09766 8.29021 6.87305 8.29021 7.76367V7.77539C8.29021 8.67383 8.44196 9.45508 8.74545 10.1191C9.05273 10.7832 9.48521 11.2969 10.0429 11.6602C10.6005 12.0195 11.2568 12.1992 12.0118 12.1992Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconTextTransformCapitalize = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1 11.4551H2.13222L4.67223 4.36523H5.01369V3H4.18699L1 11.4551ZM2.44373 9.03516H7.02054L6.68507 8.13867H2.7792L2.44373 9.03516ZM7.30809 11.4551H8.44031L5.25332 3H4.76808V4.36523L7.30809 11.4551Z"
            fill="#1B1F26"
        />
        <path
            d="M12.1245 13.6816C12.7116 13.6816 13.2208 13.5859 13.6521 13.3945C14.0834 13.207 14.4149 12.9395 14.6466 12.5918C14.8822 12.2441 15 11.8359 15 11.3672V5.13867H13.9576V6.18164H13.8858C13.754 5.94336 13.5882 5.73828 13.3885 5.56641C13.1928 5.39453 12.9672 5.26172 12.7116 5.16797C12.456 5.07422 12.1764 5.02734 11.8729 5.02734C11.3098 5.02734 10.8246 5.16016 10.4172 5.42578C10.0098 5.6875 9.69434 6.04883 9.47069 6.50977C9.25103 6.9668 9.14121 7.49023 9.14121 8.08008V8.0918C9.14121 8.68164 9.25103 9.20508 9.47069 9.66211C9.69034 10.1191 10.0019 10.4785 10.4052 10.7402C10.8126 10.998 11.2938 11.127 11.849 11.127C12.1445 11.127 12.4201 11.0859 12.6757 11.0039C12.9313 10.918 13.1609 10.793 13.3646 10.6289C13.5682 10.4648 13.738 10.2676 13.8738 10.0371H13.9696V11.3145C13.9696 11.7793 13.8059 12.1387 13.4784 12.3926C13.1549 12.6504 12.7036 12.7793 12.1245 12.7793C11.6612 12.7793 11.2898 12.7012 11.0103 12.5449C10.7307 12.3926 10.563 12.1973 10.5071 11.959L10.5011 11.9531H9.42276L9.41078 11.959C9.46669 12.3027 9.60847 12.6035 9.83611 12.8613C10.0677 13.123 10.3773 13.3242 10.7647 13.4648C11.152 13.6094 11.6053 13.6816 12.1245 13.6816ZM12.0766 10.2246C11.6772 10.2246 11.3378 10.1348 11.0582 9.95508C10.7826 9.77148 10.571 9.51953 10.4232 9.19922C10.2794 8.87891 10.2075 8.50977 10.2075 8.0918V8.08008C10.2075 7.66211 10.2794 7.29102 10.4232 6.9668C10.571 6.64258 10.7826 6.38867 11.0582 6.20508C11.3378 6.02148 11.6772 5.92969 12.0766 5.92969C12.476 5.92969 12.8174 6.02148 13.101 6.20508C13.3885 6.38867 13.6082 6.64258 13.7599 6.9668C13.9157 7.29102 13.9936 7.66211 13.9936 8.08008V8.0918C13.9936 8.50977 13.9157 8.87891 13.7599 9.19922C13.6082 9.51953 13.3885 9.77148 13.101 9.95508C12.8174 10.1348 12.476 10.2246 12.0766 10.2246Z"
            fill="#1B1F26"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconTextTransformLower = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.10938 11.5391C4.39453 11.5391 4.65625 11.5 4.89453 11.4219C5.13281 11.3398 5.3457 11.2207 5.5332 11.0645C5.72461 10.9082 5.88672 10.7188 6.01953 10.4961H6.11328V11.4277H7.13281V7.10352C7.13281 6.66602 7.03906 6.29102 6.85156 5.97852C6.66797 5.66211 6.39648 5.41992 6.03711 5.25195C5.68164 5.08398 5.24609 5 4.73047 5C4.28125 5 3.88086 5.07031 3.5293 5.21094C3.17773 5.34766 2.89062 5.54102 2.66797 5.79102C2.44922 6.03711 2.3125 6.32617 2.25781 6.6582L2.25195 6.69336H3.27148L3.27734 6.67578C3.35938 6.42969 3.52148 6.24023 3.76367 6.10742C4.00586 5.9707 4.31641 5.90234 4.69531 5.90234C5.16797 5.90234 5.52148 6.00586 5.75586 6.21289C5.99414 6.41602 6.11328 6.71289 6.11328 7.10352V9.07227C6.11328 9.37305 6.03516 9.64258 5.87891 9.88086C5.72656 10.1191 5.51562 10.3086 5.24609 10.4492C4.97656 10.5859 4.66797 10.6543 4.32031 10.6543C3.95312 10.6543 3.64844 10.5664 3.40625 10.3906C3.16406 10.2109 3.04297 9.96094 3.04297 9.64062V9.62891C3.04297 9.31641 3.15234 9.07422 3.37109 8.90234C3.59375 8.73047 3.94531 8.63086 4.42578 8.60352L6.62305 8.46289V7.64844L4.30273 7.78906C3.56836 7.83203 3 8.01367 2.59766 8.33398C2.19922 8.65039 2 9.08984 2 9.65234V9.66406C2 10.0469 2.0918 10.3789 2.27539 10.6602C2.46289 10.9414 2.71484 11.1582 3.03125 11.3105C3.35156 11.4629 3.71094 11.5391 4.10938 11.5391Z"
            fill="#1B1F26"
        />
        <path
            d="M11.5977 13.6543C12.1719 13.6543 12.6699 13.5586 13.0918 13.3672C13.5137 13.1797 13.8379 12.9121 14.0645 12.5645C14.2949 12.2168 14.4102 11.8086 14.4102 11.3398V5.11133H13.3906V6.1543H13.3203C13.1914 5.91602 13.0293 5.71094 12.834 5.53906C12.6426 5.36719 12.4219 5.23438 12.1719 5.14062C11.9219 5.04688 11.6484 5 11.3516 5C10.8008 5 10.3262 5.13281 9.92773 5.39844C9.5293 5.66016 9.2207 6.02148 9.00195 6.48242C8.78711 6.93945 8.67969 7.46289 8.67969 8.05273V8.06445C8.67969 8.6543 8.78711 9.17773 9.00195 9.63477C9.2168 10.0918 9.52148 10.4512 9.91602 10.7129C10.3145 10.9707 10.7852 11.0996 11.3281 11.0996C11.6172 11.0996 11.8867 11.0586 12.1367 10.9766C12.3867 10.8906 12.6113 10.7656 12.8105 10.6016C13.0098 10.4375 13.1758 10.2402 13.3086 10.0098H13.4023V11.2871C13.4023 11.752 13.2422 12.1113 12.9219 12.3652C12.6055 12.623 12.1641 12.752 11.5977 12.752C11.1445 12.752 10.7812 12.6738 10.5078 12.5176C10.2344 12.3652 10.0703 12.1699 10.0156 11.9316L10.0098 11.9258H8.95508L8.94336 11.9316C8.99805 12.2754 9.13672 12.5762 9.35938 12.834C9.58594 13.0957 9.88867 13.2969 10.2676 13.4375C10.6465 13.582 11.0898 13.6543 11.5977 13.6543ZM11.5508 10.1973C11.1602 10.1973 10.8281 10.1074 10.5547 9.92773C10.2852 9.74414 10.0781 9.49219 9.93359 9.17188C9.79297 8.85156 9.72266 8.48242 9.72266 8.06445V8.05273C9.72266 7.63477 9.79297 7.26367 9.93359 6.93945C10.0781 6.61523 10.2852 6.36133 10.5547 6.17773C10.8281 5.99414 11.1602 5.90234 11.5508 5.90234C11.9414 5.90234 12.2754 5.99414 12.5527 6.17773C12.834 6.36133 13.0488 6.61523 13.1973 6.93945C13.3496 7.26367 13.4258 7.63477 13.4258 8.05273V8.06445C13.4258 8.48242 13.3496 8.85156 13.1973 9.17188C13.0488 9.49219 12.834 9.74414 12.5527 9.92773C12.2754 10.1074 11.9414 10.1973 11.5508 10.1973Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconTextAlignJustified = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 3L14 3V4L2 4V3ZM2 7.5L14 7.5V8.5L2 8.5V7.5ZM14 12L2 12L2 13L14 13V12Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconTextResizeFixed = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 3H3L3 13H13V3ZM3 2C2.44772 2 2 2.44772 2 3V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V3C14 2.44772 13.5523 2 13 2H3Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconAlignTop = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.50004 14L8.50004 14V6.20711L10.6465 8.35355L11.3536 7.64645L8.35359 4.64645L8.00004 4.29289L7.64648 4.64645L4.64648 7.64645L5.35359 8.35355L7.50004 6.20711L7.50004 14ZM1 2L15 2V3L1 3L1 2Z"
            fill="#1B1F26"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconAlignVerticalCenter = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.50028 1H7.50028V4.29314L6.35359 3.14645L5.64648 3.85355L7.64648 5.85355L8.00004 6.20711L8.35359 5.85355L10.3536 3.85355L9.64648 3.14645L8.50028 4.29265L8.50028 1ZM8.50028 14L7.50028 14L7.50028 10.7069L6.35359 11.8536L5.64649 11.1465L7.64649 9.14648L8.00004 8.79293L8.35359 9.14648L10.3536 11.1465L9.64649 11.8536L8.50028 10.7074L8.50028 14ZM1 8.00004L15 8.00004V7.00004L1 7.00004L1 8.00004Z"
            fill="#1B1F26"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconAlignBottom = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.50004 9.79289V2H8.50004V9.79289L10.6465 7.64645L11.3536 8.35355L8.00004 11.7071L4.64648 8.35355L5.35359 7.64645L7.50004 9.79289Z"
            fill="#1B1F26"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M15 14H1V13H15V14Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconTextTruncation = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            transform="matrix(1 0 0 1 1.00002 3.50024)"
            d="M0 8.5L1.06519 8.5L1.98413 5.5672L5.01587 5.5672L5.93481 8.5L7 8.5L4.00171 0L2.99829 0L0 8.5ZM3.45483 1.3725L2.26636 4.66595L4.73364 4.66595L3.54517 1.3725L3.45483 1.3725ZM8.25 7C8.66431 7 9 7.33582 9 7.75C9 8.16418 8.66431 8.5 8.25 8.5C7.83569 8.5 7.5 8.16418 7.5 7.75C7.5 7.33582 7.83569 7 8.25 7ZM10.75 7C11.1643 7 11.5 7.33582 11.5 7.75C11.5 8.16418 11.1643 8.5 10.75 8.5C10.3357 8.5 10 8.16418 10 7.75C10 7.33582 10.3357 7 10.75 7ZM13.25 7C13.6643 7 14 7.33582 14 7.75C14 8.16418 13.6643 8.5 13.25 8.5C12.8357 8.5 12.5 8.16418 12.5 7.75C12.5 7.33582 12.8357 7 13.25 7Z"
            fillRule="evenodd"
            fill="rgb(26, 29, 37)"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconVectorEdit = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.97903 6.92857L4.04147 8L2.97903 9.07143L1.91659 8L2.97903 6.92857ZM2.97903 5.5L3.68732 6.21429L4.74976 7.28571L4.98238 7.52031H6.59556C6.80183 6.93179 7.35838 6.51015 8.01258 6.51015C8.66679 6.51015 9.22334 6.93179 9.4296 7.52031H11.0176L11.2502 7.28571L12.3127 6.21429L13.021 5.5L13.7293 6.21429L14.7917 7.28571L15.5 8L14.7917 8.71429L13.7293 9.78571L13.021 10.5L12.3127 9.78571L11.2502 8.71429L11.068 8.53046H9.4296C9.22334 9.11897 8.66679 9.54061 8.01258 9.54061C7.35838 9.54061 6.80183 9.11897 6.59556 8.53046H4.93204L4.74976 8.71429L3.68732 9.78571L2.97903 10.5L2.27073 9.78571L1.20829 8.71429L0.5 8L1.20829 7.28571L2.27073 6.21429L2.97903 5.5ZM14.0834 8L13.021 6.92857L11.9585 8L13.021 9.07143L14.0834 8Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconOptionOff = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 7.5L12 7.5V8.5L4 8.5V7.5Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconOptionOn = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.82846 10.5356L3.64648 7.35357L4.35359 6.64647L7.18202 9.47489L12.1318 4.52515L12.8389 5.23225L7.53557 10.5356L7.18202 10.8891L6.82846 10.5356Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconStrokeDotLine = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 7.5H4V8.5H1V7.5Z" fill="#1B1F26" />
        <path d="M6 7.5H10V8.5H6V7.5Z" fill="#1B1F26" />
        <path d="M12 7.5H15V8.5H12V7.5Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconStrokeNone = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M15 8.5H1V7.5H15V8.5Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconStrokeJoinMiter = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.5 2H2V2.5V14H3V3H14V2H2.5ZM14 7L9 7V6H8H7H6V7V8V9H7V14H8V9H9V8L14 8V7ZM7 8H8V7H7V8Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconStrokeJoinRound = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.5 2C4.91015 2 2 4.91015 2 8.5V14H3V8.5C3 5.46243 5.46243 3 8.5 3H14V2H8.5ZM14 7L9 7V6H8H7H6V7V8V9H7V14H8V9H9V8L14 8V7ZM7 8H8V7H7V8Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconStrokeJoinBevel = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5 2H7.29289L7.14645 2.14645L2.14645 7.14645L2 7.29289V7.5V14H3V7.70711L7.70711 3H14V2H7.5ZM14 7L9 7V6H8H7H6V7V8V9H7V14H8V9H9V8L14 8V7ZM7 8H8V7H7V8Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconStrokeEndPointLineArrow = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.6464 11.1464C10.4512 11.3417 10.4512 11.6583 10.6464 11.8536C10.8417 12.0488 11.1583 12.0488 11.3536 11.8536L14.8536 8.35355C15.0488 8.15829 15.0488 7.84171 14.8536 7.64645L11.3536 4.14645C11.1583 3.95118 10.8417 3.95118 10.6464 4.14645C10.4512 4.34171 10.4512 4.65829 10.6464 4.85355L13.2929 7.5H1V8.5H13.2929L10.6464 11.1464Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconStrokeEndPointTriangle = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M15 8L10 4.5V7.5H1V8.5H10V11.5L15 8Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconStrokeEndPointReversedTriangle = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.7143 7.5L15 4.5V11.5L10.7143 8.5H1V7.5H10.7143Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconStrokeEndPointDiamond = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M15 8L12 5L9.5 7.5H1V8.5H9.5L12 11L15 8Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconStrokeStartPointLine = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.35355 11.1464C5.54882 11.3417 5.54882 11.6583 5.35355 11.8536C5.15829 12.0488 4.84171 12.0488 4.64645 11.8536L1.14645 8.35355C0.951184 8.15829 0.951185 7.84171 1.14645 7.64645L4.64645 4.14645C4.84171 3.95118 5.15829 3.95118 5.35355 4.14645C5.54882 4.34171 5.54882 4.65829 5.35355 4.85355L2.70711 7.5H15V8.5H2.70711L5.35355 11.1464Z"
            fill="#1B1F26"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconStrokeStartPointTriangle = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M1 8L6 4.5V7.5H15V8.5H6V11.5L1 8Z" fill="#1B1F26" />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconStrokeStartPointReversedTriangle = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.28571 7.5L1 4.5V11.5L5.28571 8.5H15V7.5H5.28571Z"
            fill="#1B1F26"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconStrokeStartPointDiamond = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M1 8L4 5L6.5 7.5H15V8.5H6.5L4 11L1 8Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconStrokeEndPointRound = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 8C14 4.96243 11.5376 2.5 8.5 2.5H2V3.5H8.5C10.9853 3.5 13 5.51472 13 8C13 10.4853 10.9853 12.5 8.5 12.5H2V13.5H8.5C11.5376 13.5 14 11.0376 14 8ZM8 8.5H2V7.5H8V6.5H9H10H11V7.5V8.5V9.5H10H9H8V8.5ZM9 7.5V8.5H10V7.5H9Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconStrokeEndPointSquare = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 2.5H13.5H2V3.5H13V12.5H2V13.5H13.5H14V13V3V2.5ZM8 8.5H2V7.5H8V6.5H9H10H11V7.5V8.5V9.5H10H9H8V8.5ZM9 7.5V8.5H10V7.5H9Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconStrokeEndPointNone = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 2.5H9.5H2V3.5H9V6.5H8V7.5H2V8.5H8V9.5H9V12.5H2V13.5H9.5H10V13V9.5H11V8.5V7.5V6.5H10V3V2.5ZM9 8.5H10V7.5H9V8.5Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconLink = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 4V1H5L5 4H6ZM1 5H4V6H1V5ZM15 10H12V9H15V10ZM10 15V12H11V15H10ZM13.0207 2.41421C11.8492 1.24264 9.94967 1.24264 8.77809 2.41421L8.07099 3.12132L7.01033 4.18198L7.71743 4.88909L8.77809 3.82843L9.4852 3.12132C10.2662 2.34027 11.5326 2.34027 12.3136 3.12132C13.0947 3.90241 13.0948 5.16859 12.3138 5.9496L10.5459 7.71751L11.253 8.42462L13.0209 6.6567C14.1925 5.48509 14.1923 3.58575 13.0207 2.41421ZM3.82835 8.77817L4.88901 7.71751L4.1819 7.01041L3.12124 8.07107L2.41413 8.77817C1.24256 9.94975 1.24256 11.8492 2.41413 13.0208C3.58571 14.1924 5.4852 14.1924 6.65677 13.0208L7.36388 12.3137L8.42454 11.253L7.71743 10.5459L6.65677 11.6066L5.94967 12.3137C5.16862 13.0948 3.90229 13.0948 3.12124 12.3137C2.34019 11.5327 2.34019 10.2663 3.12124 9.48528L3.82835 8.77817Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconDropShadowLower = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.50004 7C4.50004 5.067 6.06704 3.5 8.00004 3.5C9.93304 3.5 11.5 5.067 11.5 7C11.5 8.933 9.93304 10.5 8.00004 10.5C6.06704 10.5 4.50004 8.933 4.50004 7ZM8.00004 2.5C5.51476 2.5 3.50004 4.51472 3.50004 7C3.50004 9.48528 5.51476 11.5 8.00004 11.5C10.4853 11.5 12.5 9.48528 12.5 7C12.5 4.51472 10.4853 2.5 8.00004 2.5ZM3.05029 11.9497L3.7574 11.2426C6.10054 13.5858 9.89954 13.5858 12.2427 11.2426L12.9498 11.9497C10.2161 14.6834 5.78396 14.6834 3.05029 11.9497Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconDropShadowLowerRight = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.5 7C3.5 5.067 5.067 3.5 7 3.5C8.933 3.5 10.5 5.067 10.5 7C10.5 8.933 8.933 10.5 7 10.5C5.067 10.5 3.5 8.933 3.5 7ZM7 2.5C4.51472 2.5 2.5 4.51472 2.5 7C2.5 9.48528 4.51472 11.5 7 11.5C9.48528 11.5 11.5 9.48528 11.5 7C11.5 4.51472 9.48528 2.5 7 2.5ZM14 7C14 10.866 10.866 14 7 14V13C10.3137 13 13 10.3137 13 7H14Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconDropShadowLowerLeft = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.5 7C5.5 5.067 7.067 3.5 9 3.5C10.933 3.5 12.5 5.067 12.5 7C12.5 8.933 10.933 10.5 9 10.5C7.067 10.5 5.5 8.933 5.5 7ZM9 2.5C6.51472 2.5 4.5 4.51472 4.5 7C4.5 9.48528 6.51472 11.5 9 11.5C11.4853 11.5 13.5 9.48528 13.5 7C13.5 4.51472 11.4853 2.5 9 2.5ZM3 7C3 10.3137 5.68629 13 9 13V14C5.13401 14 2 10.866 2 7H3Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconDropShadowLeft = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.75736 12.2427C2.41421 9.89954 2.41421 6.10054 4.75736 3.7574L4.05025 3.05029C1.31658 5.78396 1.31658 10.2161 4.05025 12.9498L4.75736 12.2427ZM5.49999 8.00005C5.49999 6.06705 7.067 4.50005 8.99999 4.50005C10.933 4.50005 12.5 6.06705 12.5 8.00005C12.5 9.93304 10.933 11.5 8.99999 11.5C7.067 11.5 5.49999 9.93304 5.49999 8.00005ZM8.99999 3.50005C6.51471 3.50005 4.49999 5.51477 4.49999 8.00005C4.49999 10.4853 6.51471 12.5 8.99999 12.5C11.4853 12.5 13.5 10.4853 13.5 8.00005C13.5 5.51477 11.4853 3.50005 8.99999 3.50005Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconDropShadowRight = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9502 3.05078C14.6839 5.78445 14.6839 10.2166 11.9502 12.9503L11.2431 12.2432C13.5863 9.90002 13.5863 6.10103 11.2431 3.75789L11.9502 3.05078ZM3.5 8.00004C3.5 6.06705 5.067 4.50004 7 4.50004C8.933 4.50004 10.5 6.06705 10.5 8.00004C10.5 9.93304 8.933 11.5 7 11.5C5.067 11.5 3.5 9.93304 3.5 8.00004ZM7 3.50004C4.51472 3.50004 2.5 5.51476 2.5 8.00004C2.5 10.4853 4.51472 12.5 7 12.5C9.48528 12.5 11.5 10.4853 11.5 8.00004C11.5 5.51476 9.48528 3.50004 7 3.50004Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconDropShadowUpperRight = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 9C14 5.13401 10.866 2 7 2V3C10.3137 3 13 5.68629 13 9H14ZM3.5 9C3.5 7.067 5.067 5.5 7 5.5C8.933 5.5 10.5 7.067 10.5 9C10.5 10.933 8.933 12.5 7 12.5C5.067 12.5 3.5 10.933 3.5 9ZM7 4.5C4.51472 4.5 2.5 6.51472 2.5 9C2.5 11.4853 4.51472 13.5 7 13.5C9.48528 13.5 11.5 11.4853 11.5 9C11.5 6.51472 9.48528 4.5 7 4.5Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconDropShadowUpperLeft = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 9C3 5.68629 5.68629 3 9 3V2C5.13401 2 2 5.13401 2 9H3ZM5.5 9C5.5 7.067 7.067 5.5 9 5.5C10.933 5.5 12.5 7.067 12.5 9C12.5 10.933 10.933 12.5 9 12.5C7.067 12.5 5.5 10.933 5.5 9ZM9 4.5C6.51472 4.5 4.5 6.51472 4.5 9C4.5 11.4853 6.51472 13.5 9 13.5C11.4853 13.5 13.5 11.4853 13.5 9C13.5 6.51472 11.4853 4.5 9 4.5Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconDropShadowUpper = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.9503 4.05074C10.2166 1.31707 5.78445 1.31707 3.05078 4.05074L3.75789 4.75785C6.10103 2.4147 9.90002 2.4147 12.2432 4.75785L12.9503 4.05074ZM4.50004 9C4.50004 7.067 6.06704 5.5 8.00004 5.5C9.93303 5.5 11.5 7.067 11.5 9C11.5 10.933 9.93303 12.5 8.00004 12.5C6.06704 12.5 4.50004 10.933 4.50004 9ZM8.00004 4.5C5.51476 4.5 3.50004 6.51472 3.50004 9C3.50004 11.4853 5.51476 13.5 8.00004 13.5C10.4853 13.5 12.5 11.4853 12.5 9C12.5 6.51472 10.4853 4.5 8.00004 4.5Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconInnerShadowLower = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 8C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8C3 5.23858 5.23858 3 8 3C10.7614 3 13 5.23858 13 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM10.4749 10.4744L9.76777 9.76731C8.79146 10.7436 7.20854 10.7436 6.23223 9.76731L5.52513 10.4744C6.89196 11.8413 9.10804 11.8413 10.4749 10.4744Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconInnerShadowLowerRight = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 8C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8C3 5.23858 5.23858 3 8 3C10.7614 3 13 5.23858 13 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM10.5 8C10.5 9.38071 9.38071 10.5 8 10.5V11.5C9.933 11.5 11.5 9.933 11.5 8H10.5Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconInnerShadowLowerLeft = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 8C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8C3 5.23858 5.23858 3 8 3C10.7614 3 13 5.23858 13 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM5.5 8C5.5 9.38071 6.61929 10.5 8 10.5V11.5C6.067 11.5 4.5 9.933 4.5 8H5.5Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconInnerShadowLeft = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 8C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8C3 5.23858 5.23858 3 8 3C10.7614 3 13 5.23858 13 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM5.52558 10.4749L6.23269 9.76777C5.25638 8.79146 5.25638 7.20854 6.23269 6.23223L5.52558 5.52513C4.15874 6.89196 4.15874 9.10804 5.52558 10.4749Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconInnerShadowRight = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 8C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8C3 5.23858 5.23858 3 8 3C10.7614 3 13 5.23858 13 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM10.4744 5.52513L9.76731 6.23223C10.7436 7.20854 10.7436 8.79146 9.76731 9.76777L10.4744 10.4749C11.8413 9.10804 11.8413 6.89196 10.4744 5.52513Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconInnerShadowUpperRight = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 8C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8C3 5.23858 5.23858 3 8 3C10.7614 3 13 5.23858 13 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM10.5 8C10.5 6.61929 9.38071 5.5 8 5.5V4.5C9.933 4.5 11.5 6.067 11.5 8H10.5Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconInnerShadowUpperLeft = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 8C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8C3 5.23858 5.23858 3 8 3C10.7614 3 13 5.23858 13 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM5.5 8C5.5 6.61929 6.61929 5.5 8 5.5V4.5C6.067 4.5 4.5 6.067 4.5 8H5.5Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconInnerShadowUpper = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 8C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8C3 5.23858 5.23858 3 8 3C10.7614 3 13 5.23858 13 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM5.52513 5.52558L6.23223 6.23269C7.20854 5.25638 8.79146 5.25638 9.76777 6.23269L10.4749 5.52558C9.10804 4.15874 6.89196 4.15874 5.52513 5.52558Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconLayerBlur = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.57415 10.5916C2.41652 10.2621 2.28801 9.91611 2.19195 9.55682L3.74894 7.99982L4.45741 8.7083L2.57415 10.5916ZM4.11766 12.59C3.86238 12.3739 3.62536 12.1368 3.40926 11.8815L6.22832 9.06242L6.93679 9.7709L4.11766 12.59ZM6.44348 13.8082C6.08418 13.7121 5.73814 13.5836 5.4087 13.426L7.29181 11.5429L8.00028 12.2513L6.44348 13.8082ZM10.1955 13.5981C9.62646 13.8214 9.01504 13.9605 8.37666 14L10.4797 11.897L11.1881 12.6055L10.1955 13.5981ZM14 8.37566C13.9607 9.01381 13.8218 9.62504 13.5986 10.194L11.8962 11.8964L11.1878 11.1879L14 8.37566ZM13.4256 5.40805C13.5833 5.73746 13.7118 6.08347 13.8079 6.44273L11.542 8.7086L10.8336 8.00013L13.4256 5.40805ZM12.306 3.80506C12.4044 3.90606 12.4993 4.01051 12.5904 4.11821L11.1878 5.52081L10.4794 4.81234L11.882 3.40972C11.9896 3.50083 12.094 3.59566 12.1949 3.69401L12.306 3.80506ZM9.55678 2.19195C9.91607 2.28801 10.2621 2.41652 10.5915 2.57415L8.00006 5.16563L7.29159 4.45716L9.55678 2.19195ZM5.80504 2.40172C6.37407 2.17844 6.98541 2.03942 7.6237 2L4.81228 4.81143L4.10381 4.10296L5.80504 2.40172ZM2.40172 5.80506L3.39489 4.81188L4.10336 5.52036L2 7.62373C2.03942 6.98544 2.17844 6.3741 2.40172 5.80506ZM4.45801 7.29173L6.58342 5.16631L7.29189 5.87478L5.16648 8.0002L4.45801 7.29173ZM6.93724 8.35425L9.77112 5.52036L10.4796 6.22883L7.64571 9.06272L6.93724 8.35425ZM7.99995 10.8339L10.1254 8.70849L10.8338 9.41696L8.70842 11.5424L7.99995 10.8339Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconBackgroundBlur = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 13L7.9504 12.9998L10.4749 10.4752L11.182 11.1824L9.6393 12.7251C9.62743 12.7292 9.61555 12.7333 9.60365 12.7373C11.0637 12.2432 12.2213 11.0931 12.7256 9.6378L11.8887 10.4746L11.1816 9.76754L12.9997 7.94943L13 8C13 7.35509 12.8779 6.7387 12.6556 6.17267L11.5352 7.29301L10.8281 6.5859L12.1715 5.2425C11.8933 4.82237 11.5532 4.44672 11.1643 4.12851L9.06062 6.23223L8.35352 5.52512L10.3128 3.56588C9.81988 3.30828 9.27872 3.13045 8.70641 3.04951L8.70741 3.05052L5.87898 5.87894L5.17188 5.17184L7.2943 3.04941C5.09798 3.35969 3.36034 5.097 3.04958 7.29316L4.46417 5.87857L5.17128 6.58567L3.04964 8.70731C3.13061 9.2791 3.3083 9.81978 3.56563 10.3123L4.81761 9.06028L5.52472 9.76739L4.12817 11.1639C4.44646 11.553 4.82222 11.8932 5.2425 12.1715L7.29327 10.1208L8.00038 10.8279L6.17267 12.6556C6.7387 12.8779 7.35509 13 8 13ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM8.35382 6.93926L6.2325 9.06058L5.52539 8.35348L7.64671 6.23216L8.35382 6.93926ZM10.8284 8L8.70711 10.1213L8 9.41422L10.1213 7.2929L10.8284 8Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconSpacingHorizontal = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 2H5V14H3V13H4V3H3V2Z" fill="#1B1F26" />
        <path d="M8 4H9V12H8V4Z" fill="#1B1F26" />
        <path d="M14 2H12V14H14V13H13V3H14V2Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconSpacingVertical = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.0001 7.00006L12.0001 8.00006L4.00006 8.00006V7.00006L12.0001 7.00006Z" fill="#1B1F26" />
        <path
            d="M14.0001 2.00012V4.00012L2.00012 4.00012L2.00012 2.00012L3.00012 2.00012L3.00012 3.00012L13.0001 3.00012V2.00012L14.0001 2.00012Z"
            fill="#1B1F26"
        />
        <path
            d="M14.0001 11.0001V13.0001H13.0001V12.0001L3.00012 12.0001V13.0001H2.00012L2.00012 11.0001L14.0001 11.0001Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconMarginHorizontal = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 14L2 2L3 2L3 14H2ZM13 14L13 2L14 2L14 14H13ZM6 6L10 6L10 10H6V6ZM5 5H6L10 5H11V6L11 10V11H10L6 11H5V10L5 6V5Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconMarginVertical = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2 2L14 2V3L2 3L2 2Z" fill="#1B1F26" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2 13L14 13V14L2 14L2 13Z" fill="#1B1F26" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10 6H6V10H10V6ZM5 5V11H11V5H5Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconMarginLeft = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10 6H6V10H10V6ZM2 14H3V2H2V14ZM5 5V11H11V5H5Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconMarginRight = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M13 14V2H14V14H13Z" fill="#1B1F26" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10 6H6V10H10V6ZM5 5V11H11V5H5Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconMarginTop = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2 2L14 2V3L2 3L2 2Z" fill="#1B1F26" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10 6H6V10H10V6ZM5 5V11H11V5H5Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconMarginBottom = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2 13L14 13V14L2 14L2 13Z" fill="#1B1F26" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10 5H6V9H10V5ZM5 4V10H11V4H5Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconIndependentPadding = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="5" y="2" width="6" height="1" fill="#1B1F26" />
        <rect x="5" y="13" width="6" height="1" fill="#1B1F26" />
        <path d="M2 5H3V11H2V5Z" fill="#1B1F26" />
        <path d="M13 5H14V11H13V5Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconWidthFix = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 5H3V7.5H13V5H14V7.5V8.5V11H13V8.5H3V11H2V8.5V7.5V5Z"
            fill="#C2C3C4"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconWidthFitContent = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.6465 4.64648L9.6465 7.64648L9.29294 8.00004L9.6465 8.35359L12.6465 11.3536L13.3536 10.6465L10.7072 8.00004L13.3536 5.35359L12.6465 4.64648ZM3.35361 4.64649L6.35348 7.64643L6.70702 7.99997L6.35349 8.35352L3.35361 11.3536L2.64648 10.6465L5.29283 7.99998L2.64649 5.35359L3.35361 4.64649Z"
            fill="#C2C3C4"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconHeightFix = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 14L5 13L7.5 13L7.5 3L5 3L5 2L7.5 2L8.5 2L11 2L11 3L8.5 3L8.5 13L11 13L11 14L8.5 14L7.5 14L5 14Z"
            fill="#C2C3C4"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconHeightFitContent = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.3536 3.35359L8.35355 6.35359L8 6.70714L7.64645 6.35359L4.64645 3.35359L5.35355 2.64648L8 5.29293L10.6464 2.64648L11.3536 3.35359ZM11.3535 12.6465L8.35347 9.64652L7.99992 9.29297L7.64636 9.64652L4.64636 12.6465L5.35347 13.3536L7.99992 10.7072L10.6464 13.3536L11.3535 12.6465Z"
            fill="#C2C3C4"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconAbsolutePosition = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2 2H6V3H3V6H2V2Z" fill="#1B1F26" />
        <path fillRule="evenodd" clipRule="evenodd" d="M3 13V10H2V14H6V13H3Z" fill="#1B1F26" />
        <path fillRule="evenodd" clipRule="evenodd" d="M13 3H10V2H14V6H13V3Z" fill="#1B1F26" />
        <path fillRule="evenodd" clipRule="evenodd" d="M14 10V14H10V13H13V10H14Z" fill="#1B1F26" />
        <path fillRule="evenodd" clipRule="evenodd" d="M11 8.5H5V7.5H11V8.5Z" fill="#1B1F26" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7.5 11L7.5 5H8.5L8.5 11H7.5Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconHeightFullContainer = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.3536 5.1464L8.35359 2.1464L8.00004 1.79285L7.64649 2.1464L4.64648 5.1464L5.35359 5.85351L8.00004 3.20706L10.6465 5.85351L11.3536 5.1464ZM11.3536 10.8535L8.35359 13.8535L8.00004 14.2071L7.64648 13.8535L4.64648 10.8535L5.35359 10.1464L8.00004 12.7928L10.6465 10.1464L11.3536 10.8535Z"
            fill="#C2C3C4"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconWidthFullContainer = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.1464 4.64648L2.1464 7.64648L1.79285 8.00004L2.1464 8.35359L5.1464 11.3536L5.85351 10.6465L3.20706 8.00004L5.85351 5.35359L5.1464 4.64648ZM10.8535 4.64648L13.8535 7.64648L14.2071 8.00004L13.8535 8.35359L10.8535 11.3536L10.1464 10.6465L12.7928 8.00004L10.1464 5.35359L10.8535 4.64648Z"
            fill="#C2C3C4"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconTarget = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5 2.52242V1H8.5V2.52242C11.1386 2.7602 13.2398 4.86143 13.4776 7.5H15V8.5H13.4776C13.2398 11.1386 11.1386 13.2398 8.5 13.4776V15H7.5V13.4776C4.86143 13.2398 2.7602 11.1386 2.52242 8.5H1V7.5H2.52242C2.7602 4.86143 4.86143 2.7602 7.5 2.52242ZM7.5 3.52746C5.41419 3.75804 3.75804 5.41419 3.52746 7.5H5V8.5H3.52746C3.75804 10.5858 5.41419 12.242 7.5 12.4725V11H8.5V12.4725C10.5858 12.242 12.242 10.5858 12.4725 8.5H11V7.5H12.4725C12.242 5.41419 10.5858 3.75804 8.5 3.52746V5H7.5V3.52746Z"
            fill="#828a99"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconStrokePointChnage = () => (
    <svg width="16" height="16" viewBox="0 0 16 16">
        <path
            d="M3 5.896L13 5.896L13 6.896L3 6.896L3 5.896Z"
            fillRule="evenodd"
            fill="rgb(130, 137, 153)"
            fillOpacity="1"
        />
        <path
            d="M10.5 6.896C10.2239 6.896 10 6.67214 10 6.396C10 6.11986 10.2239 5.896 10.5 5.896L12 5.896L12.2929 5.896L10.6464 4.24955C10.4512 4.05429 10.4512 3.7377 10.6464 3.54244C10.8417 3.34718 11.1583 3.34718 11.3536 3.54244L13.8536 6.04244Q13.8884 6.07727 13.9157 6.11821Q13.9431 6.15916 13.9619 6.20465Q13.9808 6.25015 13.9904 6.29845Q14 6.34675 14 6.396Q14 6.44524 13.9904 6.49354Q13.9808 6.54184 13.9619 6.58734Q13.9431 6.63283 13.9157 6.67378Q13.8884 6.71473 13.8536 6.74955Q13.7832 6.81988 13.6913 6.85794Q13.5995 6.896 13.5 6.896L10.5 6.896Z"
            fillRule="evenodd"
            fill="rgb(130, 137, 153)"
            fillOpacity="1"
        />
        <path
            d="M13.5 9.896L3.5 9.896L3.5 10.896L13.5 10.896L13.5 9.896Z"
            fillRule="evenodd"
            fill="rgb(130, 137, 153)"
            fillOpacity="1"
        />
        <path
            d="M5.14645 13.2495C5.34171 13.4448 5.65829 13.4448 5.85355 13.2495C6.04881 13.0543 6.04881 12.7377 5.85355 12.5424L4.20711 10.896L4.5 10.896L6 10.896C6.27614 10.896 6.5 10.6721 6.5 10.396C6.5 10.1199 6.27614 9.896 6 9.896L4.5 9.896L3 9.896Q2.90054 9.896 2.80866 9.93406Q2.71677 9.97212 2.64645 10.0424Q2.57612 10.1128 2.53806 10.2047Q2.5 10.2965 2.5 10.396Q2.5 10.4955 2.53806 10.5873Q2.57612 10.6792 2.64645 10.7495L3.89645 11.9995L5.14645 13.2495Z"
            fillRule="evenodd"
            fill="rgb(130, 137, 153)"
            fillOpacity="1"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconStrokeStartPointRound = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 8C2 4.96243 4.46243 2.5 7.5 2.5H14V3.5H7.5C5.01472 3.5 3 5.51472 3 8C3 10.4853 5.01472 12.5 7.5 12.5H14V13.5H7.5C4.46243 13.5 2 11.0376 2 8ZM8 8.5H14V7.5H8V6.5H7H6H5V7.5V8.5V9.5H6H7H8V8.5ZM7 7.5H6V8.5H7V7.5Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconStrokeStartPointSquare = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 2.5H2.5H14V3.5H3V12.5H14V13.5H2.5H2V13V3V2.5ZM8 8.5H14V7.5H8V6.5H7H6H5V7.5V8.5V9.5H6H7H8V8.5ZM7 7.5H6V8.5H7V7.5Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconFontMiss = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" rx="2" fill="#FFE702" />
        <path
            d="M1.63672 12H2.74414L5.22852 4.91016H5.5625V3.54492H4.75391L1.63672 12ZM3.04883 9.58008H7.52539L7.19727 8.68359H3.37695L3.04883 9.58008ZM7.80664 12H8.91406L5.79688 3.54492H5.32227V4.91016L7.80664 12Z"
            fill="#1B1F26"
        />
        <path
            d="M10.918 9.38672H11.9434V9.00586C11.9434 8.79883 11.9707 8.61914 12.0254 8.4668C12.084 8.31445 12.1816 8.17188 12.3184 8.03906C12.459 7.90625 12.6523 7.76758 12.8984 7.62305C13.2734 7.39648 13.5703 7.11914 13.7891 6.79102C14.0117 6.45898 14.123 6.06445 14.123 5.60742V5.5957C14.123 5.19336 14.0176 4.83398 13.8066 4.51758C13.5996 4.19727 13.3086 3.94531 12.9336 3.76172C12.5625 3.57422 12.1309 3.48047 11.6387 3.48047C11.0918 3.48047 10.6328 3.58203 10.2617 3.78516C9.89062 3.98828 9.60742 4.26172 9.41211 4.60547C9.2168 4.94922 9.10742 5.33008 9.08398 5.74805V5.75977L10.0801 5.75391L10.0918 5.74805C10.1113 5.4707 10.1836 5.23047 10.3086 5.02734C10.4375 4.82422 10.6113 4.66797 10.8301 4.55859C11.0488 4.44922 11.3047 4.39453 11.5977 4.39453C11.8906 4.39453 12.1445 4.44922 12.3594 4.55859C12.5742 4.66797 12.7402 4.81641 12.8574 5.00391C12.9746 5.19141 13.0332 5.40625 13.0332 5.64844V5.66016C13.0332 5.86719 13.002 6.05078 12.9395 6.21094C12.877 6.37109 12.7754 6.51953 12.6348 6.65625C12.498 6.78906 12.3164 6.92578 12.0898 7.06641C11.8242 7.22656 11.6035 7.39453 11.4277 7.57031C11.252 7.74609 11.123 7.93945 11.041 8.15039C10.959 8.35742 10.918 8.58984 10.918 8.84766V9.38672ZM11.5098 12.1055C11.748 12.1055 11.9414 12.0293 12.0898 11.877C12.2422 11.7246 12.3184 11.5293 12.3184 11.291C12.3184 11.0488 12.2422 10.8516 12.0898 10.6992C11.9414 10.5469 11.748 10.4707 11.5098 10.4707C11.2715 10.4707 11.0762 10.5469 10.9238 10.6992C10.7715 10.8516 10.6953 11.0488 10.6953 11.291C10.6953 11.5293 10.7715 11.7246 10.9238 11.877C11.0762 12.0293 11.2715 12.1055 11.5098 12.1055Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconApplyAIReplace = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            transform="matrix(1 0 0 1 0 0)"
            d="M3 0C1.34315 0 0 1.34315 0 3L0 13C0 14.6569 1.34315 16 3 16L13 16C14.6569 16 16 14.6569 16 13L16 3C16 1.34315 14.6569 0 13 0L3 0Z"
            fillRule="nonzero"
            fill="rgb(97, 61, 255)"
        />
        <path
            transform="matrix(1 0 0 1 3 3.7998)"
            d="M7.37495 -0.485803Q8.27684 -0.564366 9.03961 -0.222546Q9.8961 0.161269 10.4247 1.01185L10.5 1.27576L10.5 8.16508L9.64009 8.51216Q9.43244 8.29684 9.08578 8.12392Q8.6517 7.9074 8.14062 7.84048Q6.76811 7.66076 5.25792 8.59082L4.73353 8.59082Q3.22349 7.66085 1.85526 7.84046Q1.346 7.9073 0.913357 8.12368Q0.567945 8.29644 0.359906 8.51216L-0.5 8.16508L-0.5 1.27576L-0.424016 1.01079Q0.107021 0.161006 0.961831 -0.222465Q1.72403 -0.564392 2.62354 -0.485791Q3.43774 -0.414645 4.18595 -0.019525Q4.64458 0.222666 4.99591 0.542397Q5.34796 0.222535 5.8084 -0.0198348Q6.55852 -0.414685 7.37495 -0.485803ZM9.5 7.21322Q8.92931 6.93521 8.27045 6.84894Q6.91044 6.67086 5.49572 7.32746L5.49572 1.45629Q5.80367 1.11273 6.2742 0.865059Q6.84602 0.564058 7.46173 0.510424Q8.10489 0.454399 8.63067 0.690013Q9.15055 0.922984 9.5 1.42513L9.5 7.21322ZM4.49572 7.32738L4.49572 1.45623Q4.18776 1.1123 3.71898 0.864749Q3.14942 0.563972 2.53649 0.510412Q1.89606 0.45445 1.37114 0.689932Q0.851541 0.923024 0.5 1.42578L0.5 7.21253Q1.06874 6.93512 1.72511 6.84896Q3.08179 6.67088 4.49572 7.32738Z"
            fillRule="evenodd"
            fill="rgb(255, 255, 255)"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconMargin = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 3L3 13H13V3L3 3ZM2 13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V3C14 2.44772 13.5523 2 13 2L3 2C2.44772 2 2 2.44772 2 3L2 13Z"
            fill="#1B1F26"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 6V10H10V6H6ZM5 10C5 10.5523 5.44772 11 6 11H10C10.5523 11 11 10.5523 11 10V6C11 5.44772 10.5523 5 10 5L6 5C5.44772 5 5 5.44772 5 6L5 10Z"
            fill="#1B1F26"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconMainComponentOut = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 3.00007V6.08589L12 6.08589L12 4.79297L8.85358 7.93942L8.14648 7.23232L11.3787 4.00006L10 4.00005L10 3.00005L13 3.00007ZM7.71751 1L1 7.71751L8.07107 14.7886L14.7886 8.07107L14.0815 7.36396L8.07107 13.3744L2.41421 7.71751L8.42462 1.70711L7.71751 1Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconRotate = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            d="M11.6823 3.31885C10.8036 2.43994 9.74288 2.00049 8.50021 2.00049L4.50033 2.00073L4.50033 3.00073L8.50021 3.00049C9.46676 3.00073 10.2918 3.34229 10.9752 4.02563C11.6586 4.70923 12.0003 5.53418 12.0003 6.50098C12.0003 6.50098 12.0002 8.60596 12.0001 10.0005C12.0002 10.6003 12.4295 10.7783 12.8537 10.354C13.7338 9.47388 14.9786 8.22925 14.9786 8.22925L14.2716 7.52222L13.0002 8.79346L13.0002 6.50098C13.0003 5.2583 12.561 4.19751 11.6823 3.31885ZM3.00002 5.00024C2.44773 5.00024 2.00002 5.44796 2.00002 6.00024L2.00002 12.0002C2.00002 12.5525 2.44773 13.0002 3.00002 13.0002L9.00002 13.0002C9.55231 13.0002 10 12.5525 10 12.0002L10 6.00024C10 5.44796 9.55231 5.00024 9.00002 5.00024L3.00002 5.00024ZM3.00002 12.0002L3.00002 6.00024L9.00002 6.00024L9.00002 12.0002L3.00002 12.0002Z"
            fillRule="evenodd"
            fill="rgb(130, 138, 153)"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconError = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14ZM1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8ZM8.44043 9.42188H7.54883L7.45752 4.24414H8.53174L8.44043 9.42188ZM8.53174 11.8872C8.39567 12.0269 8.21842 12.0967 8 12.0967C7.78158 12.0967 7.60254 12.0269 7.46289 11.8872C7.32324 11.7476 7.25342 11.5685 7.25342 11.3501C7.25342 11.1281 7.32324 10.9473 7.46289 10.8076C7.60254 10.668 7.78158 10.5981 8 10.5981C8.21842 10.5981 8.39567 10.668 8.53174 10.8076C8.67139 10.9473 8.74121 11.1281 8.74121 11.3501C8.74121 11.5685 8.67139 11.7476 8.53174 11.8872Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconCancel = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            transform="matrix(1 0 0 1 1 1)"
            d="M7 0C10.866 0 14 3.13403 14 7C14 10.866 10.866 14 7 14C3.13403 14 0 10.866 0 7C0 3.13403 3.13403 0 7 0ZM4.52545 3.81763L7.00049 6.29248L9.47522 3.81787L10.1823 4.5249L7.70758 6.99976L10.1827 9.47485L9.47559 10.1819L7.00049 7.70679L4.52509 10.1821L3.81799 9.4751L6.2934 6.99976L3.81836 4.52466L4.52545 3.81763Z"
            fillRule="evenodd"
            fill="rgb(191, 195, 201)"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconArrowRight = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 7.47817L3 8.52183L10.7929 8.52183L8.64645 10.762L9.35355 11.5L12.3536 8.36899L12.7071 8L12.3536 7.63101L9.35355 4.5L8.64645 5.23799L10.7929 7.47816L3 7.47817Z"
            fill="currentColor"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconScale = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.0088 5.69091L11.0089 8.00023L12 8.00018L11.9998 4L8.00019 4.00059L8.00033 4.99169L10.3067 4.99135L4.9913 10.3066L4.99152 8.00043L4.00039 8.00033L4 11.9996L8.00008 12L8.00018 11.0089L5.69083 11.0087L11.0088 5.69091Z"
            fill="#1B1F26"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconCommentDockToSide = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.5 4C1.5 2.61929 2.61929 1.5 4 1.5H12C13.3807 1.5 14.5 2.61929 14.5 4V10.5H13.5V4C13.5 3.17157 12.8284 2.5 12 2.5H4C3.17157 2.5 2.5 3.17157 2.5 4V12C2.5 12.8284 3.17157 13.5 4 13.5H9.38354C10.6967 13.5 11.376 11.9321 10.4778 10.9741L6.13523 6.34197L6.86477 5.65803L11.2074 10.2901C12.7043 11.8868 11.5721 14.5 9.38354 14.5H4C2.61929 14.5 1.5 13.3807 1.5 12V4Z"
            fill="#1B1F26"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M6 5.5H10.5V6.5H7V10H6V5.5Z" fill="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconWrapper = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="16"
        height="16"
        viewBox="0 0 16 16"
    >
        <path
            d="M2.00002 12L10 12L10 13L2.00002 13L2.00002 12ZM14 7.5L2.00002 7.5L2.00002 8.5L14 8.5L14 7.5ZM2.00002 3L14 3L14 4L2.00002 4L2.00002 3Z"
            fillRule="evenodd"
            fill="rgb(48, 113, 255)"
            fillOpacity="1"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconSort = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="16"
        height="16"
        viewBox="0 0 16 16"
    >
        <path
            transform="matrix(1 0 0 1 2 3)"
            d="M0 0L12 0L12 1L0 1L0 0ZM2 4.5L10 4.5L10 5.5L2 5.5L2 4.5ZM8 9L4 9L4 10L8 10L8 9Z"
            fillRule="evenodd"
            fill="rgb(26, 29, 37)"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconResolve = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="6.5" stroke="#1B1F26" />
        <path d="M5 7.5L7.5 10L11.5 6" stroke="#1B1F26" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconCopy = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            d="M2.50002 5.00024C2.50002 4.44796 2.94774 4.00024 3.50002 4.00024L10.5 4.00024C11.0523 4.00024 11.5 4.44796 11.5 5.00024L11.5 13.0002C11.5 13.5525 11.0523 14.0002 10.5 14.0002L3.50002 14.0002C2.94774 14.0002 2.50002 13.5525 2.50002 13.0002L2.50002 5.00024ZM3.50002 5.00024L10.5 5.00024L10.5 13.0002L3.50002 13.0002L3.50002 5.00024ZM12.5 2.00024L5.00002 2.00024L5.00002 3.00024L12.5 3.00024L12.5 11.5002L13.5 11.5002L13.5 3.00024C13.5 2.7241 13.4024 2.4884 13.2071 2.29314C13.0119 2.09788 12.7762 2.00024 12.5 2.00024Z"
            fillRule="evenodd"
            fill="rgb(191, 195, 201)"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconSine = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            d="M6.00002 7.50024L2.51735 7.50024C2.5488 7.05088 2.62302 6.61288 2.74003 6.18626C2.8895 5.64127 3.09688 5.15541 3.36216 4.72869C3.6123 4.32631 3.88843 4.01641 4.19053 3.799C4.46727 3.59983 4.7371 3.50024 5.00002 3.50024C5.30419 3.50024 5.61523 3.69831 5.93312 4.09444C6.23619 4.47209 6.5234 5.00226 6.79477 5.68494C6.9943 6.18691 7.17352 6.74058 7.33243 7.34594C7.41174 7.6481 7.47069 7.8981 7.50926 8.09594C7.55447 8.31737 7.62065 8.58937 7.7078 8.91191C7.88182 9.55599 8.07724 10.1476 8.29404 10.6868C9.04826 12.5624 9.95025 13.5002 11 13.5002C11.4779 13.5002 11.9424 13.3379 12.3937 13.0131C12.8028 12.7187 13.1673 12.3142 13.4872 11.7998C13.8036 11.2908 14.0493 10.7171 14.2244 10.0787C14.365 9.56596 14.4519 9.0398 14.4848 8.50024L15 8.50024L15 7.50024L8.40222 7.50024C8.37239 7.37493 8.3382 7.23887 8.29966 7.09205C8.13044 6.44741 7.93857 5.85525 7.72404 5.31555C6.97799 3.43868 6.06999 2.50024 5.00002 2.50024C4.52215 2.50024 4.05761 2.66261 3.60639 2.98734C3.1972 3.28183 2.8327 3.68629 2.51289 4.20072C2.19648 4.70969 1.95073 5.28337 1.77564 5.92177C1.63501 6.43453 1.54819 6.96069 1.5152 7.50024L1.00002 7.50024L1.00002 8.50024L6.00002 8.50024L6.00002 7.50024ZM8.633 8.50024L13.4827 8.50024C13.4512 8.94961 13.377 9.3876 13.26 9.81423C13.1105 10.3592 12.9032 10.8451 12.6379 11.2718C12.3877 11.6742 12.1116 11.9841 11.8095 12.2015C11.5328 12.4007 11.2629 12.5002 11 12.5002C10.707 12.5002 10.4027 12.3025 10.0873 11.9072C9.78529 11.5287 9.49682 10.9975 9.22184 10.3137C9.01978 9.81121 8.8369 9.257 8.67318 8.65108C8.65917 8.59922 8.64578 8.54894 8.633 8.50024Z"
            fillRule="evenodd"
            fill="rgb(191, 195, 201)"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconRight = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            d="M3.00002 7.47841L3.00002 8.52208L10.7929 8.52208L8.64647 10.7623L9.35357 11.5002L12.3536 8.36924L12.7071 8.00024L12.3536 7.63125L9.35357 4.50024L8.64647 5.23823L10.7929 7.47841L3.00002 7.47841Z"
            fillRule="evenodd"
            fill="rgb(191, 195, 201)"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconExclamation = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            d="M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14ZM1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8ZM8.44043 9.42188L7.54883 9.42188L7.45752 4.24414L8.53174 4.24414L8.44043 9.42188ZM8.53174 11.8872C8.39567 12.0269 8.21842 12.0967 8 12.0967C7.78158 12.0967 7.60254 12.0269 7.46289 11.8872C7.32324 11.7476 7.25342 11.5685 7.25342 11.3501C7.25342 11.1281 7.32324 10.9473 7.46289 10.8076C7.60254 10.668 7.78158 10.5981 8 10.5981C8.21842 10.5981 8.39567 10.668 8.53174 10.8076C8.67139 10.9473 8.74121 11.1281 8.74121 11.3501C8.74121 11.5685 8.67139 11.7476 8.53174 11.8872Z"
            fillRule="evenodd"
            fill="rgb(191, 195, 201)"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconMore16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            d="M4 8C4 8.82843 3.32843 9.5 2.5 9.5C1.67157 9.5 1 8.82843 1 8C1 7.17157 1.67157 6.5 2.5 6.5C3.32843 6.5 4 7.17157 4 8ZM9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8ZM13.5 9.5C14.3284 9.5 15 8.82843 15 8C15 7.17157 14.3284 6.5 13.5 6.5C12.6716 6.5 12 7.17157 12 8C12 8.82843 12.6716 9.5 13.5 9.5Z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconClose16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
        <path
            transform="matrix(1 0 0 1 2.64648 2.64648)"
            d="M4.64645 5.35355L0 10L0.707107 10.7071L5.35355 6.06066L10 10.7071L10.7071 10L6.06066 5.35355L10.7071 0.707107L10 0L5.35355 4.64645L0.707108 0L0 0.707107L4.64645 5.35355Z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconSmartSelectionHorizontal = (props: SVGAttributes<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        {...props}
    >
        <path
            transform="matrix(1 0 0 1 2.00002 2.00024)"
            d="M0 0L2 0L2 12L0 12L0 11L1 11L1 1L0 1L0 0ZM12 0L10 0L10 12L12 12L12 11L11 11L11 1L12 1L12 0ZM6.5 2L5.5 2L5.5 10L6.5 10L6.5 2Z"
            fillRule="evenodd"
            fill="rgb(191, 195, 201)"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconSmartSelectionVertical = (props: SVGAttributes<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        {...props}
    >
        <path
            transform="matrix(1 0 0 1 2.00014 2.00037)"
            d="M12 2L0 2L0 0L1 0L1 1L11 1L11 0L12 0L12 2ZM9.99988 6.49994L9.99988 5.49994L1.99988 5.49994L1.99988 6.49994L9.99988 6.49994ZM12 12L11 12L11 11L1 11L1 12L0 12L0 10L12 10L12 12Z"
            fillRule="evenodd"
            fill="rgb(191, 195, 201)"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconHistoryPublish = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="16"
        height="16"
        viewBox="0 0 16 16"
    >
        <path
            transform="matrix(1 0 0 1 5.50002 5.50024)"
            d="M4.94999 2C4.71836 0.858881 3.70948 0 2.5 0C1.11929 0 0 1.11929 0 2.5C0 3.88071 1.11929 5 2.5 5C3.70948 5 4.71836 4.14112 4.94999 3L8.29288 3L7.14645 4.14649C6.95117 4.3418 6.95117 4.6582 7.14645 4.85352C7.3417 5.04883 7.65829 5.04883 7.85355 4.85352L9.85355 2.85352C9.95792 2.7085 9.97848 2.65625 9.98984 2.60083C9.97983 2.34766 9.95944 2.29468 9.93133 2.24707C9.90994 2.21045 9.88403 2.177 9.85355 2.14648L7.85355 0.146484C7.65829 -0.0488281 7.3417 -0.0488281 7.14645 0.146484C6.95117 0.341797 6.95117 0.658203 7.14645 0.853516L8.29291 2L4.94999 2ZM2.5 1C2.91421 1 3.26777 1.14645 3.56066 1.43934C3.85355 1.73223 4 2.08579 4 2.5C4 2.91421 3.85355 3.26777 3.56066 3.56066C3.26777 3.85355 2.91421 4 2.5 4C2.08579 4 1.73223 3.85355 1.43934 3.56066C1.14645 3.26777 1 2.91421 1 2.5C1 2.08579 1.14645 1.73223 1.43934 1.43934C1.73223 1.14645 2.08579 1 2.5 1Z"
            fillRule="evenodd"
            fill="rgb(130, 138, 153)"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconHistoryNormal = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="16"
        height="16"
        viewBox="0 0 16 16"
    >
        <path
            transform="matrix(1 0 0 1 5.50002 5.50024)"
            d="M2.5 0C3.88071 0 5 1.11929 5 2.5C5 3.88071 3.88071 5 2.5 5C1.11929 5 0 3.88071 0 2.5C0 1.11929 1.11929 0 2.5 0ZM2.5 1C2.91421 1 3.26777 1.14645 3.56066 1.43934C3.85355 1.73223 4 2.08579 4 2.5C4 2.91421 3.85355 3.26777 3.56066 3.56066C3.26777 3.85355 2.91421 4 2.5 4C2.08579 4 1.73223 3.85355 1.43934 3.56066C1.14645 3.26777 1 2.91421 1 2.5C1 2.08579 1.14645 1.73223 1.43934 1.43934C1.73223 1.14645 2.08579 1 2.5 1Z"
            fillRule="evenodd"
            fill="rgb(130, 138, 153)"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconHistoryCancelPublish = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="16"
        height="16"
        viewBox="0 0 16 16"
    >
        <path
            transform="matrix(1 0 0 1 5.50002 5.50024)"
            d="M2.5 0C3.88071 0 5 1.11929 5 2.5C5 3.88071 3.88071 5 2.5 5C1.11929 5 0 3.88071 0 2.5C0 1.11929 1.11929 0 2.5 0ZM6.14645 0.646447C5.95119 0.841707 5.95119 1.15829 6.14645 1.35355L7.29289 2.5L6.14645 3.64645C5.95119 3.84171 5.95119 4.15829 6.14645 4.35355C6.34171 4.54881 6.65829 4.54881 6.85355 4.35355L8 3.20711L9.14645 4.35355C9.34171 4.54881 9.65829 4.54881 9.85355 4.35355C10.0488 4.15829 10.0488 3.84171 9.85355 3.64645L8.70711 2.5L9.85355 1.35355C10.0488 1.15829 10.0488 0.841707 9.85355 0.646446C9.65829 0.451186 9.34171 0.451186 9.14645 0.646447L8 1.79289L6.85355 0.646447C6.65829 0.451186 6.34171 0.451186 6.14645 0.646447ZM1 2.5Q1 1.87868 1.43934 1.43934Q1.87868 1 2.5 1Q3.12132 1 3.56066 1.43934Q4 1.87868 4 2.5Q4 3.12132 3.56066 3.56066Q3.12132 4 2.5 4Q1.87868 4 1.43934 3.56066Q1 3.12132 1 2.5Z"
            fillRule="evenodd"
            fill="rgb(130, 138, 153)"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconGridLayout = (props: SVGAttributes<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        {...props}
    >
        <path
            transform="matrix(-4.37114e-08 -1 1 -4.37114e-08 5.00679e-06 12)"
            d="M2.18557e-07 2L2.97292e-07 3.97233e-08L2 1.38074e-07L2 2L2.18557e-07 2ZM0 7L7.87347e-08 5L2 5L2 7L0 7ZM7.35117e-07 12L8.13852e-07 10L2 10L2 12L7.35117e-07 12ZM5 2L5 1.98618e-08L7 1.18212e-07L7 2L5 2ZM5 7L5 5L7 5L7 7L5 7ZM5 12L5 10L7 10L7 12L5 12ZM10 2L10 0L12 9.83505e-08L12 2L10 2ZM10 7L10 5L12 5L12 7L10 7ZM10 12L10 10L12 10L12 12L10 12Z"
            fillRule="evenodd"
            fill="rgb(26, 29, 37)"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconRowLayout = (props: SVGAttributes<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        {...props}
    >
        <path
            transform="matrix(-4.37114e-08 -1 1 -4.37114e-08 6.67572e-06 12)"
            d="M0 12L4.8707e-07 0L2 9.41476e-08L2 12L0 12ZM5 12L5 8.38598e-08L7 1.85853e-07L7 12L5 12ZM10 12L10 6.55258e-08L12 1.67519e-07L12 12L10 12Z"
            fillRule="evenodd"
            fill="rgb(26, 29, 37)"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconColLayout = (props: SVGAttributes<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        {...props}
    >
        <path
            transform="matrix(-4.37114e-08 -1 1 -4.37114e-08 -7.15256e-06 12)"
            d="M1.2517e-06 0L12 5.68248e-07L12 2L1.19209e-06 2L1.2517e-06 0ZM5.96046e-08 5L12 5L12 7L0 7L5.96046e-08 5ZM12 10L8.34465e-07 10L7.15256e-07 12L12 12L12 10Z"
            fillRule="evenodd"
            fill="rgb(26, 29, 37)"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconUnorderedList = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="16"
        height="16"
        viewBox="0 0 16 16"
    >
        <path
            transform="matrix(1 0 0 1 2 2.5)"
            d="M4 0L12 0L12 1L4 1L4 0ZM2 0L0 0L0 1L2 1L2 0ZM2 5L0 5L0 6L2 6L2 5ZM2 10L0 10L0 11L2 11L2 10ZM4 11L12 11L12 10L4 10L4 11ZM4 6L4 5L12 5L12 6L4 6Z"
            fillRule="evenodd"
            fill="rgb(26, 29, 37)"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconOrderedList = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="16"
        height="16"
        viewBox="0 0 16 16"
    >
        <path
            transform="matrix(1 0 0 1 1.375 2.77246)"
            d="M1.10449 4.22754L1.85742 4.22754L1.85742 0L1.10449 0L0 0.776367L0 1.48535L1.05176 0.744141L1.10449 0.744141L1.10449 4.22754ZM4.625 0.227539L12.625 0.227539L12.625 1.22754L4.625 1.22754L4.625 0.227539ZM4.625 4.72754L12.625 4.72754L12.625 5.72754L4.625 5.72754L4.625 4.72754ZM12.625 9.22754L4.625 9.22754L4.625 10.2275L12.625 10.2275L12.625 9.22754ZM3.00293 10.2275L0.0703125 10.2275L0.0703125 9.71777L1.45605 8.2998C1.64746 8.10645 1.7959 7.94727 1.90137 7.82227C2.00879 7.69531 2.08398 7.58398 2.12695 7.48828C2.16992 7.39062 2.19141 7.28809 2.19141 7.18066L2.19141 7.17188C2.19141 7.04297 2.16309 6.92773 2.10645 6.82617C2.0498 6.72461 1.96875 6.64551 1.86328 6.58887C1.75781 6.53027 1.63184 6.50098 1.48535 6.50098C1.33691 6.50098 1.20605 6.53223 1.09277 6.59473C0.979492 6.65527 0.891602 6.74023 0.829102 6.84961C0.766602 6.95898 0.735352 7.08691 0.735352 7.2334L0.732422 7.24512L0.0292969 7.24219L0.0263672 7.2334C0.0263672 6.96973 0.0898438 6.7373 0.216797 6.53613C0.34375 6.33496 0.519531 6.17773 0.744141 6.06445C0.96875 5.95117 1.22852 5.89453 1.52344 5.89453C1.79688 5.89453 2.04004 5.94629 2.25293 6.0498C2.46777 6.15332 2.63574 6.2959 2.75684 6.47754C2.87793 6.65918 2.93848 6.86914 2.93848 7.10742L2.93848 7.11621C2.93848 7.27441 2.9082 7.42969 2.84766 7.58203C2.78711 7.73242 2.68359 7.89746 2.53711 8.07715C2.39258 8.25684 2.19238 8.4707 1.93652 8.71875L1.08984 9.54171L1.08984 9.60352L3.00293 9.60352L3.00293 10.2275Z"
            fillRule="evenodd"
            fill="rgb(26, 29, 37)"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconPaint = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="16"
        height="16"
        viewBox="0 0 16 16"
    >
        <path
            transform="matrix(1 0 0 1 1.50002 1.50024)"
            d="M13 6.5C13 2.91016 10.0898 0 6.5 0C2.91016 0 0 2.91016 0 6.5C0 10.0898 2.91016 13 6.5 13C6.83655 13 7.16712 12.9744 7.48989 12.9251C8.80481 12.7242 9.99009 12.1293 10.9214 11.2646L10.9214 11.2646C12.2 10.0776 13 8.38222 13 6.5ZM2.44292 2.78612L10.2139 10.5571C10.2733 10.5026 10.3317 10.4466 10.3891 10.3892C10.8969 9.88135 11.2899 9.29834 11.5682 8.64062C11.8561 7.95996 12 7.24634 12 6.5C12 5.75366 11.8561 5.04004 11.5682 4.35938C11.2899 3.70166 10.8969 3.11865 10.3891 2.61084C9.88129 2.10303 9.29846 1.70996 8.64056 1.43188C7.95996 1.14404 7.2464 1 6.5 1C5.7536 1 5.04004 1.14404 4.35944 1.43188C3.70154 1.70996 3.11871 2.10303 2.6109 2.61084C2.55344 2.6683 2.49744 2.72673 2.44292 2.78612Z"
            fillRule="evenodd"
            fill="rgb(26, 29, 37)"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconText = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="16"
        height="16"
        viewBox="0 0 16 16"
    >
        <path
            transform="matrix(1 0 0 1 2.00002 2)"
            d="M12 1L6.5 1.00024L6.5 12L5.5 12L5.5 1.00024L0 1L0 0L5.5 0.000244141L6.5 0.000244141L12 0L12 1Z"
            fillRule="evenodd"
            fill="rgb(26, 29, 37)"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconAiAlign = (props: SVGAttributes<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        {...props}
    >
        <path transform="matrix(1 0 0 1 6 7.5)" d="M0 0L4 0L4 1L0 1L0 0Z" fillRule="nonzero" fill="rgb(26, 29, 37)" />
        <path
            transform="matrix(1 0 0 1 1 7.5)"
            d="M0 0L2.5 0L2.5 1L0 1L0 0Z"
            fillRule="nonzero"
            fill="rgb(26, 29, 37)"
        />
        <path
            transform="matrix(1 0 0 1 12.5 7.5)"
            d="M0 0L2.5 0L2.5 1L0 1L0 0Z"
            fillRule="nonzero"
            fill="rgb(26, 29, 37)"
        />
        <path transform="matrix(0 1 -1 0 8.5 6)" d="M0 0L4 0L4 1L0 1L0 0Z" fillRule="nonzero" fill="rgb(26, 29, 37)" />
        <path
            transform="matrix(1 0 0 1 7.5 1)"
            d="M0 0L1 0L1 2.5L0 2.5L0 0Z"
            fillRule="nonzero"
            fill="rgb(26, 29, 37)"
        />
        <path
            transform="matrix(1 0 0 1 7.5 12.5)"
            d="M0 0L1 0L1 2.5L0 2.5L0 0Z"
            fillRule="nonzero"
            fill="rgb(26, 29, 37)"
        />
        <path
            transform="matrix(1 0 0 1 1 1)"
            d="M3.5 0L10.5 0L10.5 1L3.5 1L3.5 0ZM0 3.5L1 3.5L1 10.5L0 10.5L0 3.5ZM13 3.5L14 3.5L14 10.5L13 10.5L13 3.5ZM3.5 13L10.5 13L10.5 14L3.5 14L3.5 13Z"
            fillRule="evenodd"
            fill="rgb(26, 29, 37)"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconClock = (props: SVGAttributes<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        {...props}
    >
        <path
            transform="matrix(1 0 0 1 1 1)"
            d="M14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7ZM4.66484 1.4711Q5.77864 1 7 1Q8.22136 1 9.33516 1.4711Q10.4117 1.92645 11.2426 2.75736Q12.0736 3.58827 12.5289 4.66484Q13 5.77864 13 7Q13 8.22136 12.5289 9.33516Q12.0736 10.4117 11.2426 11.2426Q10.4117 12.0736 9.33516 12.5289Q8.22136 13 7 13Q5.77864 13 4.66484 12.5289Q3.58827 12.0736 2.75736 11.2426Q1.92645 10.4117 1.4711 9.33516Q1 8.22136 1 7Q1 5.77864 1.4711 4.66484Q1.92645 3.58827 2.75736 2.75736Q3.58827 1.92645 4.66484 1.4711Z"
            fillRule="evenodd"
            fill="rgb(26, 29, 37)"
        />
        <path
            transform="matrix(1 0 0 1 7 4.5)"
            d="M1 3.5L1 0L0 0L0 4.5L4.5 4.5L4.5 3.5L1 3.5Z"
            fillRule="nonzero"
            fill="rgb(26, 29, 37)"
        />
    </svg>
)
