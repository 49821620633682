import { RouteToken } from '../../../../../../util/src'
import classNames from 'classnames'
import { HTMLAttributes, useMemo } from 'react'
import { useParams } from 'react-router'
import { useWindowSize } from 'react-use'
import { useListShowType } from '../../../util/view-show-type'
import { usePageType } from '../use-page-type'
import { BlockUnit } from './block-unit'
import styles from './layout.module.less'
import { ShowType } from '../../../util/types'

const FolderItemBlock = () => {
    return (
        <div className="rounded-3px border border-color-$wk-gray-2 p-5 box-border">
            <div className="grid gap-2.5 grid-cols-2 mb-5">
                <BlockUnit className="pt-60%" />
                <BlockUnit className="pt-60%" />
                <BlockUnit className="pt-60%" />
                <BlockUnit className="pt-60%" />
            </div>
            <BlockUnit className="h-4 w-36% mb-11px" />
            <BlockUnit className="h-14px w-65% mb-2px" />
        </div>
    )
}

export const Block = (props: HTMLAttributes<HTMLDivElement>) => {
    const { style, className, ...rest } = props
    return (
        <div
            style={{ ...style, background: 'rgba(0,0,0,0.04)' }}
            className={classNames(['rounded-3px', className])}
            {...rest}
        />
    )
}

const DocBlock = () => {
    return (
        <div>
            <Block style={{ paddingTop: '60%' }} className={'mb-3'} />
            <Block className={'mb-7px h-4 w-60%'} />
            <Block className={'h-3 mb-2 w-35%'} />
        </div>
    )
}

const FavAndTrashTitle = () => {
    return <BlockUnit className="mt-13px mb-8.5 w-56px h-34px" />
}

const RecentAndDraftsTitle = () => {
    return (
        <div className="mt-13px mb-23px">
            <BlockUnit className="w-56px h-34px mb-33px" />
            <div className={styles.fileCardList}>
                <BlockUnit className="h-56px" />
                <BlockUnit className="h-56px" />
            </div>
        </div>
    )
}

const FolderTitle = () => {
    const { width } = useWindowSize()
    return (
        <div className="mt-13px mb-23px">
            <BlockUnit className="w-140px h-14px mb-13px" />
            <div className="mb-33px flex items-center justify-between">
                <BlockUnit className="w-140px h-34px" />
                <div className="flex items-center">
                    <BlockUnit className="w-12 h-8 mr-2" />
                    <BlockUnit className="w-8 h-8" />
                </div>
            </div>
            <div className={classNames(styles.fileCardList, width < 872 ? '!gap-2' : '')}>
                <BlockUnit className="h-56px" />
                <BlockUnit className="h-56px" />
            </div>
        </div>
    )
}

export const DocList = () => {
    const { isShared, isFav, isTrash, isRecent, isDrafts, isFolder } = usePageType()
    const folderId = useParams().folderId
    const key = useMemo(() => {
        if (isFolder) {
            return `${RouteToken.Project}-${folderId}`
        }
        if (isShared) {
            return `${RouteToken.Shared}-folders`
        }
        if (isFav) {
            return `${RouteToken.Favorites}-${undefined}`
        }
        if (isTrash) {
            return `${RouteToken.Trash}-${undefined}`
        }
        if (isRecent) {
            return `${RouteToken.Recent}-${undefined}`
        }
        if (isDrafts) {
            return `${RouteToken.Drafts}-${undefined}`
        }
        return ''
    }, [folderId, isDrafts, isFav, isFolder, isRecent, isShared, isTrash])
    const { showType } = useListShowType(key, ShowType.Grid)
    return (
        <div className="px-8">
            {(isFav || isTrash || isShared) && <FavAndTrashTitle />}
            {(isRecent || isDrafts) && <RecentAndDraftsTitle />}
            {isFolder && <FolderTitle />}
            {isShared && (
                <div className="flex-1">
                    <BlockUnit className="h-4 w-16 mt-0 mb-23px"></BlockUnit>
                    <div className={styles.folders_grid}>
                        <FolderItemBlock />
                        <FolderItemBlock />
                        <FolderItemBlock />
                        <FolderItemBlock />
                        <FolderItemBlock />
                        <FolderItemBlock />
                        <FolderItemBlock />
                        <FolderItemBlock />
                        <FolderItemBlock />
                        <FolderItemBlock />
                        <FolderItemBlock />
                        <FolderItemBlock />
                    </div>
                </div>
            )}
            {!isShared && showType == ShowType.Grid && (
                <div>
                    <BlockUnit className="h-3.5 w-16 mb-23px" />
                    <div className={styles.fileCardList}>
                        <DocBlock />
                        <DocBlock />
                        <DocBlock />
                        <DocBlock />
                        <DocBlock />
                        <DocBlock />
                        <DocBlock />
                        <DocBlock />
                        <DocBlock />
                        <DocBlock />
                        <DocBlock />
                        <DocBlock />
                        <DocBlock />
                        <DocBlock />
                        <DocBlock />
                        <DocBlock />
                    </div>
                </div>
            )}
        </div>
    )
}
