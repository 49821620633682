import { state } from 'ccstate'
import { traceable } from '../../../../util/src/ccstate-helper/traceable'
import { GlobalCanvas } from '../../main/gpu/global-canvas'

export const getCanvasMaxTextureSize = (): number => {
    const webgl = GlobalCanvas.webgl()
    const size = webgl?.context.getParameter(webgl.context.MAX_TEXTURE_SIZE) ?? 4096
    // 向下取 2 的幂
    // 避免对 canvas 大小向上取 2 的幂时超过 MAX_TEXTURE_SIZE 限制
    const power = Math.floor(Math.log2(size))
    return Math.pow(2, power)
}

export const canvasMaxTextureSize$ = traceable('hulh01@kanyun.com', state(getCanvasMaxTextureSize()))
