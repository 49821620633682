/* eslint-disable no-restricted-imports */
import { useAppContext, usePluginService } from '../../../main/app-context'
import classNames from 'classnames'
import { FC, useEffect, useRef, useState } from 'react'
import styles from './plugin-toast.module.less'

export enum PluginToastType {
    Default,
    Error,
}

export interface PluginToastProps {
    message: string
    type: PluginToastType
}

export const PluginToast: FC = () => {
    const pluginService = usePluginService()

    const toastState = useAppContext().pluginService.states.use.toastState()

    const timer = useRef<NodeJS.Timeout | null>(null)

    const [hidden, setHidden] = useState(false)

    useEffect(() => {
        if (toastState?.show) {
            setHidden(false)
            timer.current = setTimeout(() => {
                setHidden(true)
                setTimeout(() => {
                    pluginService.closeToast()
                }, 300)
            }, 2700)
        }
        return () => {
            if (timer.current) {
                clearInterval(timer.current)
            }
        }
    }, [toastState, pluginService])

    return (
        <div className={styles['toast-container']}>
            <div
                className={classNames(
                    styles.toast,
                    toastState?.type === PluginToastType.Error && styles.error,
                    toastState?.show && styles.show,
                    hidden && styles.hidden
                )}
            >
                {toastState?.message}
            </div>
        </div>
    )
}
