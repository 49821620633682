import { Wukong } from '@wukong/bridge-proto'
import { DeepRequired, useViewState } from '../../../../view-state-bridge'
import { useEffect, useMemo, useState } from 'react'
import { rgb2Style } from '../util/color'
import { getWkCanvasOverlay } from '../../../../main/canvas/canvas'

export function useCoactorObservingBorder() {
    const [currentObservedUser, setCurrentObservedUser] = useState<
        DeepRequired<Wukong.DocumentProto.IVActiveUser> | undefined
    >()
    const [titleShow, setTitleShow] = useState<boolean>(true)
    const coactorMembershipMap = useViewState('coactorMembershipMap')

    const colorStyleString = useMemo(
        () => (currentObservedUser?.color ? rgb2Style(currentObservedUser.color) : undefined),
        [currentObservedUser?.color]
    )

    useEffect(() => {
        const userList = Object.values(coactorMembershipMap?.value ?? [])
        for (const activeUser of userList) {
            if (activeUser.role === Wukong.DocumentProto.ActiveUserRole.ACTIVE_USER_ROLE_OBSERVED) {
                setCurrentObservedUser(activeUser)
                return
            }
        }
        setCurrentObservedUser(undefined)
    }, [coactorMembershipMap])

    // 画布区域宽度小于一定程度隐藏 title
    useEffect(() => {
        const nameLength = (currentObservedUser?.nickname.length ?? 0) * 12
        setTitleShow(nameLength > 0 && getWkCanvasOverlay().getBoundingClientRect().width > nameLength + 160)
    }, [currentObservedUser?.nickname])

    return { currentObservedUser, colorStyleString, titleShow }
}
