/* eslint-disable no-restricted-imports */
import { ReactNode, useCallback, useState } from 'react'
import { collapse, expand } from '../../../../inspect/assets/inspect-svg'
import styles from './preview.module.less'
import { enhancedSessionStorage } from '../../../../../../web-storage/session-storage/storage'
import { SessionStorageKey } from '../../../../../../web-storage/session-storage/config'

interface DevModeExpandProps {
    children: ReactNode
    title: ReactNode
}

export function DevModePreviewExpand(props: DevModeExpandProps) {
    const sessionVal = enhancedSessionStorage.getItem(SessionStorageKey.DevModePreviewExpanded)

    // 研发模式默认展开
    const defaultPreviewExpanded = typeof sessionVal === 'string' ? sessionVal === '1' : true

    const [previewExpanded, setPreviewExpanded] = useState(defaultPreviewExpanded)

    const handlePreviewExpand = useCallback(() => {
        const newVal = !previewExpanded
        setPreviewExpanded(!previewExpanded)
        enhancedSessionStorage.setItem(SessionStorageKey.DevModePreviewExpanded, newVal ? '1' : '0')
    }, [previewExpanded])

    return (
        <div className={styles.preview}>
            <div
                className={styles.previewRow}
                onClick={handlePreviewExpand}
                data-testid="dev-mode-export-preview-expand-select"
            >
                <span className={styles.expand}>{previewExpanded ? expand : collapse}</span>
                {props.title}
            </div>
            {previewExpanded && props.children}
        </div>
    )
}
