import { translation } from './ai-gen-file-info.translation'

export function tryFormatSize(file: File): string | null {
    const size = file.size
    const MB = 1024 * 1024
    const KB = 1024

    if (size > 5 * MB) {
        return null
    }

    if (size >= MB) {
        return `${(size / MB).toFixed(2)} MB`
    }

    if (size >= KB) {
        return `${(size / KB).toFixed(2)} KB`
    }

    return `${size} B`
}

export function imgFileUrl(file: File) {
    return URL.createObjectURL(file)
}

export function getFileInfo(imageFile: File | null, uploadedStatus: string | null) {
    switch (uploadedStatus) {
        case 'success':
            if (imageFile) {
                const size = tryFormatSize(imageFile)
                if (size) {
                    return size
                } else {
                    return translation('Oversized')
                }
            }
            throw new Error('unreachable')
        case 'failed':
            return translation('UploadFailed')
        case 'over-size':
            return translation('Oversized')
        case 'file-format-restricted':
            return translation('FileFormatRestricted')
        default:
            return translation('Uploading')
    }
}
