import { type FeatureGuideScene } from '../../../kernel/interface/account'
import { WKTourProps, WKTourStep } from '../../../../../ui-lib/src/components/wk-tour'
import { environment } from '../../../environment'
import { featureSwitchManager } from '../../../kernel/switch'
import { getDefaultGlobalConfigsWithStorage2 } from '../../user-config/user-config'
import { translation } from './data.translation'
import { AIGenAllInOnePlayground } from '../../../kernel/request/ai-gen'
import { generateRouterPath } from '../../../../../util/src'

export interface TourConfig {
    type: 'tour'
    tourProps: Pick<WKTourProps, 'steps' | 'zIndex' | 'hideProgress' | 'draggable' | 'theme'> & {
        stepsFn?: () => WKTourStep[]
        hideProgressFn?: () => boolean
    }
}
export interface DialogConfig {
    type: 'dialog'
    dialogProps: {
        video: string
        title: string
        description: string
        primaryButton: {
            text: string
            onClick: () => void
        }
        secondaryButton: {
            text: string
            onClick: () => void
        }
    }
}
export interface FeatureGuide {
    // 指引开始时间
    startTime: Date
    // 指引结束时间
    endTime: Date
    // 用户类型
    userType:
        | 'all'
        | {
              // 表示在这个时间点之前的用户还是之后的用户
              type: 'old' | 'new'
              time: Date
          }
    config: TourConfig | DialogConfig
}

export interface FeatureGuideInfos {
    orgId?: string
}

// [how to add a new feature guide 2] 在这里写引导的配置 开始结束时间 用户范围 tourProps
export const FeatureGuideConfig = (infos: FeatureGuideInfos) =>
    ({
        mask: {
            startTime: new Date(2023, 1, 1),
            // 永远生效
            endTime: new Date(2126, 1, 1),
            // 2025/3/5 之后注册的用户才展示
            userType: {
                type: 'new',
                time: new Date(2025, 2, 5),
            },
            config: {
                type: 'tour',
                tourProps: {
                    zIndex: 10,
                    hideProgress: true,
                    theme: 'brand',
                    steps: [
                        {
                            nextButton: {
                                show: false,
                            },
                            title: translation('maskMode'),
                            placement: 'left-center',
                            showArrow: true,
                            targets: () => [document.querySelector('[data-testid="alpha-mask-checkbox"]')],
                            description: translation('maskModeDescription'),
                        },
                    ],
                },
            },
        },
        aiDuplicate: {
            startTime: new Date(2023, 1, 1),
            endTime: new Date(2126, 1, 1),
            // 2025/3/5 之后注册的用户才展示
            userType: {
                type: 'new',
                time: new Date(2025, 2, 5),
            },
            config: {
                type: 'tour',
                tourProps: {
                    draggable: true,
                    hideProgress: true,
                    zIndex: 10,
                    steps: [
                        {
                            video: environment.isAbroad
                                ? `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/ai-duplicate-3n-v2.mp4`
                                : `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/ai-duplicate-v2.mp4`,
                            title: translation('aiDuplicate'),
                            noTargetPosition: {
                                top: 48 + 8,
                                left: 8 + getDefaultGlobalConfigsWithStorage2().leftPanelWidth,
                            },
                            nextButton: {
                                text: translation('done'),
                                show: true,
                            },
                            moreButton: {
                                show: true,
                                onClick: () => {
                                    window.open(
                                        environment.isAbroad
                                            ? 'https://motiff.com/help/docs/articles/232648479013121'
                                            : 'https://motiff.cn/help/docs/articles/232640631466240',
                                        '_blank'
                                    )
                                },
                            },
                            description: translation('aiDuplicateDescription'),
                        },
                    ],
                },
            },
        },
        spaceFavorites: {
            startTime: new Date(2025, 2, 14),
            endTime: new Date(2025, 4, 14),
            userType: {
                type: 'old',
                time: new Date(2025, 4, 14),
            },
            config: {
                type: 'tour',
                tourProps: {
                    theme: 'brand',
                    zIndex: 100,
                    hideProgressFn: () => {
                        return !document.querySelector('[data-testid="shared-projects-block"]')
                    },
                    steps: [],
                    stepsFn: () => {
                        if (!featureSwitchManager.isEnabled('organization-plus')) {
                            return []
                        }
                        const sharedProjectsElement = document.querySelector('[data-testid="shared-projects-block"]')
                        const favoritesElement = document.querySelector('[data-testid="favorite-docs-block-label"]')

                        if (sharedProjectsElement && favoritesElement) {
                            return [
                                {
                                    highlight: true,
                                    placement: 'bottom-left',
                                    showArrow: true,
                                    targets: () => [
                                        document.querySelector('[data-testid="favorite-docs-block-label"]'),
                                    ],
                                    description: translation('spaceFavoritesDesc'),
                                    nextButton: {
                                        show: true,
                                    },
                                },
                                {
                                    highlight: true,
                                    placement: 'bottom-left',
                                    showArrow: true,
                                    targets: () => [document.querySelector('[data-testid="shared-projects-block"]')],
                                    description: translation('sharedProjectsDesc'),
                                    nextButton: {
                                        show: true,
                                        text: translation('done'),
                                    },
                                },
                            ]
                        } else if (favoritesElement) {
                            return [
                                {
                                    highlight: true,
                                    placement: 'bottom-left',
                                    showArrow: true,
                                    targets: () => [
                                        document.querySelector('[data-testid="favorite-docs-block-label"]'),
                                    ],
                                    description: translation('spaceFavoritesDesc'),
                                    nextButton: {
                                        show: true,
                                        text: translation('done'),
                                    },
                                },
                            ]
                        }

                        return []
                    },
                },
            },
        },
        aiGenDesignSystem: {
            startTime: new Date(2000, 3, 26),
            endTime: new Date(2100, 4, 27),
            userType: 'all',
            config: {
                type: 'dialog',
                dialogProps: {
                    title: translation('aiGenDesignSystemTitle'),
                    video: environment.isAbroad
                        ? `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/ai-gen-ui-design-system.en.mp4`
                        : `${environment.ossCdnPath}${environment.publicResourcePrefix}/video/ai-gen-ui-design-system.zh.mp4`,
                    description: translation('aiGenDesignSystemDescription'),
                    primaryButton: {
                        text: translation('aiGenPrimaryBtnText'),
                        onClick: async () => {
                            const allInOnePlaygroundWithinOrdReq = new AIGenAllInOnePlayground(infos.orgId)
                            const { docId } = await allInOnePlaygroundWithinOrdReq
                                .start()
                                .catch(() => {
                                    const allInOnePlaygroundReq = new AIGenAllInOnePlayground()
                                    return allInOnePlaygroundReq.start()
                                })
                                .catch(() => ({ docId: null }))
                            if (docId) {
                                const docPath = generateRouterPath(`/file/${docId}`)
                                window.open(docPath, '_blank')
                            }
                        },
                    },
                    secondaryButton: {
                        text: translation('aiGenKnowMoreBtnText'),
                        onClick: () => {
                            if (environment.isDev) {
                                //《AI生成UI》：https://wukong.yuanfudao.biz/help/docs/articles/339831434163968
                                //《Get started with AI Generates UI》：https://wukong-abroad-test.zhenguanyu.com/help/docs/articles/339887314883072
                                const helpDocPath = environment.isAbroad
                                    ? 'https://wukong-abroad-test.zhenguanyu.com/help/docs/articles/339887314883072'
                                    : 'https://wukong.yuanfudao.biz/help/docs/articles/339831434163968'
                                window.open(helpDocPath, '_blank')
                            } else {
                                //国内线上：https://motiff.cn/help/docs/articles/339831434163968
                                //海外线上：https://motiff.com/help/docs/articles/339887314883072
                                const helpDocPath = environment.isAbroad
                                    ? 'https://motiff.com/help/docs/articles/339887314883072'
                                    : 'https://motiff.cn/help/docs/articles/339831434163968'
                                window.open(helpDocPath, '_blank')
                            }
                        },
                    },
                },
            },
        },
    } as Record<FeatureGuideScene, FeatureGuide>)
