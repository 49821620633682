export function InfoLineIcon(props: { className?: string }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            className={props.className}
        >
            <path
                transform="matrix(1 0 0 1 1 1)"
                d="M7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0ZM7 1Q5.77864 1 4.66484 1.4711Q3.58827 1.92645 2.75736 2.75736Q1.92645 3.58827 1.4711 4.66484Q1 5.77864 1 7Q1 8.22136 1.4711 9.33516Q1.92645 10.4117 2.75736 11.2426Q3.58827 12.0736 4.66484 12.5289Q5.77864 13 7 13Q8.22136 13 9.33516 12.5289Q10.4117 12.0736 11.2426 11.2426Q12.0736 10.4117 12.5289 9.33516Q13 8.22136 13 7Q13 5.77864 12.5289 4.66484Q12.0736 3.58827 11.2426 2.75736Q10.4117 1.92645 9.33516 1.4711Q8.22136 1 7 1ZM7.75 3.75C7.75 3.33579 7.41421 3 7 3C6.58579 3 6.25 3.33579 6.25 3.75C6.25 4.16421 6.58579 4.5 7 4.5C7.41421 4.5 7.75 4.16421 7.75 3.75ZM7.5 10.5L7.5 5.5L6.5 5.5L6.5 10.5L7.5 10.5Z"
                fillRule="evenodd"
            />
        </svg>
    )
}

export function CheckboxCheckedIcon(props: { className?: string }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            className={props.className}
        >
            <path
                transform="matrix(1 0 0 1 3.29297 4.29285)"
                d="M9.41421 1.41421L3.70711 7.12132L0 3.41421L1.41421 2L3.70711 4.29289L8 0L9.41421 1.41421Z"
                fillRule="evenodd"
                fill="rgb(34, 179, 96)"
            />
        </svg>
    )
}

export function WarningLineIcon(props: { className?: string }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            className={props.className}
        >
            <path
                transform="matrix(1 0 0 1 1.00002 1.00024)"
                d="M7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0ZM1 7C1 10.3137 3.68629 13 7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7ZM6.54883 8.42188L7.44043 8.42188L7.53174 3.24414L6.45752 3.24414L6.54883 8.42188ZM7 11.0967Q7.32764 11.0967 7.53174 10.8872C7.67139 10.7476 7.74121 10.5685 7.74121 10.3501C7.74121 10.1281 7.67139 9.94727 7.53174 9.80762Q7.32764 9.59814 7 9.59814Q6.67236 9.59814 6.46289 9.80762C6.32324 9.94727 6.25342 10.1281 6.25342 10.3501C6.25342 10.5685 6.32324 10.7476 6.46289 10.8872C6.60254 11.0269 6.78158 11.0967 7 11.0967Z"
                fillRule="evenodd"
            />
        </svg>
    )
}
