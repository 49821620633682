import { POST, PUT } from '@tutor/network-core'
import pako from 'pako'
import { CandidateComponentPublishRequest } from '../interface/candidate-component'
import { BaseCommonRequest } from './base-request'

export interface CandidateComponentPreviewInfoResponse {
    previewInfo: string
}

/**
 * 发布替换
 */
@PUT
export class CandidateComponentPublish extends BaseCommonRequest<void> {
    constructor(private docId: string, private readonly content: CandidateComponentPublishRequest) {
        super()
    }

    public override requestBody() {
        const { requestContent } = this.content
        const formData = new FormData()
        formData.append('requestContent', new Blob([pako.deflate(JSON.stringify(requestContent))]))
        return formData
    }

    public override requestArgument() {
        return {
            compressType: 'ZLIB',
        }
    }

    public override requestUrl() {
        return `/ai/library-substitute/${this.docId}`
    }
}

/**
 * 获取AI组件替换预览信息
 */
@POST
export class CandidateComponentPreviewInfo extends BaseCommonRequest<CandidateComponentPreviewInfoResponse> {
    constructor(private componentJsonResourceId: string, private readonly content: string[]) {
        super()
    }

    public override requestBody() {
        const formData = new FormData()
        formData.append('candidatesContent', new Blob([pako.deflate(JSON.stringify(this.content))]))
        formData.append('componentJsonResourceId', this.componentJsonResourceId)
        formData.append('compressType', 'ZLIB')
        return formData
    }

    public override requestUrl() {
        return `/ai/library-substitute/preview-info`
    }
}

/**
 * 接受AI组件替换
 */
@PUT
export class CandidateComponentResolve extends BaseCommonRequest<void> {
    constructor(private ids: number[], private readonly version: number) {
        super()
    }

    public override requestBody() {
        return {
            ids: this.ids,
            version: this.version,
            operation: 'ACCEPT',
        }
    }

    public override requestUrl() {
        return `/ai/library-substitute/resolve`
    }
}
