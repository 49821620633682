/* eslint-disable no-restricted-imports */
import classNames from 'classnames'
import { useCallback, useRef, useState } from 'react'
import { isRightClick, ScrollView, TabElement } from '../../../../../../../../ui-lib/src'
import { NodeId } from '../../../../../../document/node/node'
import { ToKeyCode } from '../../../../../../document/util/keycode'
import { useLibraryComponentService } from '../../../../../../main/app-context'
import {
    buildThumbnailDataFromVO,
    LibraryThumbnailImage,
} from '../../../../../../share/component-style-library/library-thumbnail-image'
import { LibraryResourceOssClientType } from '../../../../../../share/component-style-library/service/library-resource-downloader'
import { StyleToolTip } from '../../style-tooltip/style-tooltip'
import style from '../style-color-grid-item.module.less'
import styles from '../style-thumbnail.module.less'
import { RemoteStyleProps } from './remote-style-color-list-item'

export function RemoteStyleColorGridItem(props: RemoteStyleProps) {
    const { item, selectStyleKey = '', docId, onSelectItem, onContextMenu, isRightClickStyle } = props
    const { id, name, description } = props.item
    const [styleNodeId, setStyleNodeId] = useState<NodeId | null>(null)
    const containerRef = useRef<HTMLDivElement>(null)

    const libraryComponentService = useLibraryComponentService()
    const getStyleNodeId = useCallback(async () => {
        if (styleNodeId) {
            return styleNodeId
        }

        const newStyleNodeId = await libraryComponentService.libraryNodeDataService.createRemoteStyleNode({
            isLocal: false,
            localNodeId: null,
            ossClientType: LibraryResourceOssClientType.Style,
            remoteDocId: docId,
            remoteNodeId: item.nodeId,
            nodeDataPath: item.nodeDataPath ?? '',
            key: item.id,
        })

        if (newStyleNodeId) {
            setStyleNodeId(newStyleNodeId)
        }

        return newStyleNodeId
    }, [styleNodeId, libraryComponentService, docId, item])

    const _onContextMenu: React.MouseEventHandler<HTMLDivElement> = useCallback(
        (e) => {
            e.stopPropagation()
            e.preventDefault()
            onContextMenu?.(
                {
                    id: styleNodeId || id,
                    name: name || '',
                    description: description || '',
                    groupName: '',
                    styleName: '',
                },
                e,
                item.nodeId,
                docId
            )
        },
        [description, docId, id, item.nodeId, name, onContextMenu, styleNodeId]
    )

    const _onMouseDown = useCallback(
        async (e: React.MouseEvent<HTMLDivElement>) => {
            if (isRightClick(e)) {
                return
            }

            const styleId = await getStyleNodeId()
            if (styleId) {
                const newValue = {
                    id: styleNodeId || id,
                    name: name || '',
                    description: description || '',
                    groupName: '',
                    styleName: '',
                }
                newValue.id = styleId
                onSelectItem(newValue)
            }
        },
        [description, getStyleNodeId, id, name, onSelectItem, styleNodeId]
    )

    const _onKeydown = useCallback(
        (e: any) => {
            if (e.keyCode === ToKeyCode.Enter) {
                e.stopPropagation()
                _onMouseDown(e)
            }
        },
        [_onMouseDown]
    )

    return (
        <StyleToolTip titleText={item.name || ''} contentText={item.description}>
            <ScrollView.Item
                uniqueKey={item.id}
                ref={containerRef}
                onContextMenu={_onContextMenu}
                onMouseDown={_onMouseDown}
                selectClassName={style.selectItem}
                isSelectFn={(selectKey: any) =>
                    selectKey &&
                    [styleNodeId, selectStyleKey && item.id === selectStyleKey ? selectKey : ''].includes(selectKey)
                }
                className={classNames(
                    style.gridItem,
                    isRightClickStyle && style['right-click-menu-visible'],
                    props.className
                )}
                onKeyDown={_onKeydown}
            >
                <TabElement></TabElement>
                <LibraryThumbnailImage
                    thumbnailData={buildThumbnailDataFromVO(item)}
                    className={classNames(styles.remoteBorder, props.imageClassName)}
                />
            </ScrollView.Item>
        </StyleToolTip>
    )
}
