import { translation } from './paint.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import * as React from 'react'
import { useCallback, useState } from 'react'
import { IconStyle, IconTarget, Tooltip } from '../../../../../../ui-lib/src'
import type { Paint as TypePaint } from '../../../../document/node/node'
import { usePosition } from '../../../utils/use-position'
import { IconButton } from '../../atom/button/icon-button'
import { SingleGrid } from '../../atom/grid/single-grid'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { ScrubbableInputPercent } from '../../atom/inputs/scrubbable-input-percent'
import { Value } from '../../atom/inputs/utils/type'
import { PaintIconColor } from '../../paint-icon-color/paint-icon-color'
import type { BlendContentProps } from '../blend/blend-content'
import { ColorSpace } from '../blend/color-picker/utils/color-translate'
import { ColorInteraction } from '../color-interaction/color-interaction'
import { ColorInteractionFrom, ColorPanelProps, TypeOnChangeStyle } from '../color-interaction/type'
import style from './paint.module.less'

interface PaintProps
    extends Pick<BlendContentProps, 'onChangeColor' | 'onChangeOpacity' | 'onChangeColorStops' | 'onChangeTransform'> {
    paint: TypePaint
    colorSpace: ColorSpace
    isOpen?: boolean
    selected: boolean
    usingNodeCount: number
    onClickAimIcon?: (data: any) => void
    onChangeModalVisible?: (visible: boolean) => void
    onChangeBlendMode?: (blendMode: Wukong.DocumentProto.BlendMode) => void
    onChangeStyle: TypeOnChangeStyle
    onChangeColorVar: (colorVar: Wukong.DocumentProto.IVariableAliasData) => void
    onChangePaints: (paints: Wukong.DocumentProto.IPaint[]) => void
    onChangePaintType: ColorPanelProps['onChangePaintType']
}
function _Paint(props: PaintProps, outRef: React.Ref<HTMLDivElement>) {
    const { paint, colorSpace, isOpen, selected, usingNodeCount, onChangeOpacity, onChangeModalVisible } = props
    const { position, reactRef } = usePosition({ isOpen, outRef })
    const [isFakeStyle, setIsFakeStyle] = useState(false)

    const onClose = useCallback(() => {
        onChangeModalVisible?.(false)
    }, [onChangeModalVisible])

    const onClickOpenModal = useCallback(() => {
        setIsFakeStyle(false)
        onChangeModalVisible?.(!isOpen)
    }, [isOpen, onChangeModalVisible])

    const _onChangeOpacity = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value === 'number') {
                onChangeOpacity?.(value / 100, options)
            }
        },
        [onChangeOpacity]
    )
    const _onClickStyleIcon = useCallback(() => {
        onClickOpenModal()
        setIsFakeStyle(true)
    }, [onClickOpenModal])

    return (
        <div>
            <SingleGrid ref={reactRef} className={classNames(style.selectColor, style.v2, selected && style.selected)}>
                <SingleGrid.Item start={5} span={46}>
                    <SingleGrid className={style.backgroundGrid}>
                        <SingleGrid.Item start={1} span={25}>
                            <PaintIconColor
                                paint={paint}
                                colorSpace={colorSpace}
                                onChangeColor={props.onChangeColor}
                                onMouseDownIcon={onClickOpenModal}
                                onClickInput={onClose}
                            />
                        </SingleGrid.Item>
                        <SingleGrid.Item start={26} span={14}>
                            <ScrubbableInputPercent
                                value={paint.opacity}
                                onChange={_onChangeOpacity}
                                onClick={onClose}
                                min={0}
                                max={100}
                                className={`${paint.visible ? '' : style.hidden}`}
                                testId="select-color-item-opacity"
                            />
                        </SingleGrid.Item>
                        <SingleGrid.Item start={40} span={7} className={style.selectColorIcon}>
                            <Tooltip title={translation('StyleAndVariable')}>
                                <IconButton
                                    icon={<IconStyle />}
                                    selected={false}
                                    onClick={_onClickStyleIcon}
                                    className={style.eyeIcon}
                                    dataTestId="select-color-style-icon"
                                />
                            </Tooltip>
                        </SingleGrid.Item>
                    </SingleGrid>
                </SingleGrid.Item>
                <SingleGrid.Item start={51} span={12} className={style.selectColorIcon} horizontalCenter>
                    <Tooltip
                        title={translation('SelectItemsUsing', {
                            usingNodeCount: String(usingNodeCount),
                            item: usingNodeCount > 1 ? 'items' : 'item',
                        })}
                    >
                        <IconButton
                            icon={<IconTarget />}
                            dataTestId="select-color-inspect-paint"
                            selected={false}
                            onClick={props.onClickAimIcon}
                        />
                    </Tooltip>
                </SingleGrid.Item>
            </SingleGrid>
            {isOpen && position && (
                <ColorInteraction
                    from={
                        isFakeStyle
                            ? ColorInteractionFrom.SELECTION_COLOR_FAKE_STYLE
                            : ColorInteractionFrom.SELECTION_COLOR
                    }
                    position={position}
                    onCancel={onClose}
                    paints={[paint]}
                    styleId={undefined}
                    onChangePaintType={props.onChangePaintType!}
                    onChangeBlendMode={props.onChangeBlendMode!}
                    onChangeColor={props.onChangeColor}
                    onChangeOpacity={props.onChangeOpacity}
                    onChangeColorStops={props.onChangeColorStops!}
                    onChangeTransform={props.onChangeTransform}
                    enterClose={onClose}
                    onChangeStyle={props.onChangeStyle}
                    onChangeColorVar={props.onChangeColorVar}
                    onChangePaints={props.onChangePaints}
                    onChangeImagePaint={() => {}}
                    onClickCreateStyleButton={undefined}
                />
            )}
        </div>
    )
}

export const Paint = React.forwardRef(_Paint)
