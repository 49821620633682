import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    ViewOnly: '仅查看',
    DevMode: '研发模式',
    ApplyEditFile: '编辑文件',
    Applying: '席位申请中',
    ApplyingToolTip: '你已发送申请，可以联系管理员尽快审批。',
    ViewOnlyTooltip: '实际权限为「可查看」，因为该草稿文件的所有者已被限制分享编辑权限。',
} as const

export const enTranslation = {
    ViewOnly: 'View only',
    DevMode: 'Develop Mode',
    ApplyEditFile: 'Edit File',
    Applying: 'Request pending',
    ApplyingToolTip: 'You have sent a request. Contact the admin for prompt approval.',
    ViewOnlyTooltip:
        'The actual permission are "Can View" because the draft owner is restricted from sharing edit access.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
