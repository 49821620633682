import { useUnmountHref } from '../../../../../../ui-lib/src'
import { AdminPayApply } from './admin-pay-apply'
import { AutoPayApply } from './auto-pay-apply'
import { InvitePayApply } from './invite-pay-apply'
import { NormalPayApply } from './normal-pay-apply'
import { RepeatApplication } from './repeat-application'
import { PayApplyDialogStructProps, PayApplyDialogType } from './type'
import { getSeatTypeFromPayApplyDialogSeatType } from './utils'

export function PayApplyDialogStruct(props: PayApplyDialogStructProps) {
    useUnmountHref(props.dialogProps?.onCancel)

    return props.type === PayApplyDialogType.AutoUpgrade ? (
        <AutoPayApply {...props} />
    ) : props.type === PayApplyDialogType.Admin ? (
        <AdminPayApply {...props} />
    ) : props.type === PayApplyDialogType.Normal ? (
        props.unresolvedSeatTypes.includes(getSeatTypeFromPayApplyDialogSeatType(props.seatType)) ? (
            <RepeatApplication {...props} />
        ) : (
            <NormalPayApply {...props} />
        )
    ) : props.type === PayApplyDialogType.Invite ? (
        <InvitePayApply {...props} />
    ) : (
        <></>
    )
}
