import { WKButton } from '../../../../../../../ui-lib/src'
import { useDevModeBatchExport } from '../../dev-mode-batch-export/use-dev-mode-batch-export'
import { logs } from '../../logs'
import { translation } from './index.translation'
import style from './style.module.less'

export function DevModeInspectBatchExportBtn() {
    const { openBatchExport } = useDevModeBatchExport()

    return (
        <div className={style.batchExportBtnContainer}>
            <WKButton
                data-testid={'dev-mode-inspect-open-batch-export-btn'}
                style={{ width: '100%' }}
                type={'secondary'}
                htmlType={'button'}
                onClick={() => {
                    logs.DevMode.exportDialogDisplay({ interaction_type: 'click' })
                    openBatchExport()
                }}
            >
                {translation('Export')}
            </WKButton>
        </div>
    )
}
