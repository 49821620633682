/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { HTMLAttributes, useMemo } from 'react'
import { IconColLayout, IconGridLayout, IconRowLayout } from '../../../../../../../ui-lib/src'
import { LayoutGridStyleIconType } from '../../../../../kernel/interface/component-style'
import style from './style-thumbnail.module.less'

interface LayoutGridStyleThumbnailProps {
    layoutGrids: readonly Wukong.DocumentProto.ILayoutGrid[]
    shallowColor?: boolean
    className?: string
    reversed?: boolean
}

export function LayoutGridStyleThumbnail(props: LayoutGridStyleThumbnailProps) {
    const { layoutGrids, shallowColor = false, className, reversed = false } = props

    const type: Wukong.DocumentProto.LayoutGridPattern = useMemo(() => {
        if (layoutGrids.length === 0) {
            return Wukong.DocumentProto.LayoutGridPattern.LAYOUT_GRID_PATTERN_GRID
        }
        if (reversed) {
            return layoutGrids.at(layoutGrids.length - 1)!.pattern!
        }
        return layoutGrids.at(0)!.pattern!
    }, [layoutGrids, reversed])

    const icon: React.ReactNode = useMemo(() => {
        switch (type) {
            case Wukong.DocumentProto.LayoutGridPattern.LAYOUT_GRID_PATTERN_GRID: {
                return <IconGridLayout />
            }
            case Wukong.DocumentProto.LayoutGridPattern.LAYOUT_GRID_PATTERN_ROWS: {
                return <IconRowLayout />
            }
            case Wukong.DocumentProto.LayoutGridPattern.LAYOUT_GRID_PATTERN_COLUMNS: {
                return <IconColLayout />
            }
        }
    }, [type])

    const thumbnailClassName = useMemo(() => {
        let _thumbnailClassName = style.span
        if (shallowColor) {
            _thumbnailClassName += ' ' + style.selected_shallow
        }
        if (className) {
            _thumbnailClassName += ' ' + className
        }
        return _thumbnailClassName
    }, [className, shallowColor])

    return <span className={thumbnailClassName}>{icon}</span>
}

export interface LayoutGridStyleIconProps extends HTMLAttributes<SVGElement> {
    iconType: LayoutGridStyleIconType
    className?: string
}
export function LayoutGridStyleIcon({ iconType, className, ...props }: LayoutGridStyleIconProps) {
    const IconSvg = useMemo(() => {
        switch (iconType) {
            case LayoutGridStyleIconType.Grid:
                return IconGridLayout
            case LayoutGridStyleIconType.Row:
                return IconRowLayout
            case LayoutGridStyleIconType.Column:
                return IconColLayout
            case LayoutGridStyleIconType.Default:
                return IconGridLayout
        }
    }, [iconType])

    return (
        <span className={classNames(style.container, className)}>
            <IconSvg className={style.svg} {...props} />
        </span>
    )
}
