import { getTranslationValue } from '../../../../../../../../../util/src/i18n'

export const zhTranslation = {
    ComponentCanNotContainItself: '组件不能包含它自身的实例',
    PickInstance: '选择实例',
    PreferredValues: '首选值',
    SelectPreferredValues: '选择首选值',
    PropertyName: '属性名',
    PropertyValue: '属性值',
    CreateProperty: '创建属性',
} as const

export const enTranslation = {
    ComponentCanNotContainItself: `Components can't contain instances of themselves`,
    PickInstance: 'Pick instance',
    PreferredValues: 'Preferred values',
    SelectPreferredValues: 'Select preferred values',
    PropertyName: 'Name',
    PropertyValue: 'Value',
    CreateProperty: 'Create property',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
