import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    ShareProject: '分享项目',
    ShareFile: '分享文件',
    ggHxDU: '确定将{{label}}「{{resourceName}}」所有者转移给{{nickname}}？',
    aTmPyR: '此操作无法撤销',
    TransferOwner: '转移所有者',
    MKWXEt: '确定从{{label}}「{{resourceName}}」移除{{nickname}}？',
    XmMaTE: '移除',
    OlAXkP: '移除后，该成员可能无法访问{{file}}，此操作无法撤销',
    JhjXFc: '当前文件',
    uXbutb: '项目下的文件',
    DliUGl: '无法离开',
    CzCJjB: '请将{{label}}所有者转移给其他成员后再离开',
    OK: '我知道了',
    CPCNWh: '确定离开{{label}}「{{resourceName}}」?',
    Leave: '离开',
    InviteSent: '邀请已发送',
    SendInvite: '发送邀请',
    NoPasswordProtection: '不支持密码保护',
    ThreeFilesOneProject: '3 个文件和 1 个项目',
    ThreePagesPerFile: '每个文件 3 个页面',
    PasswordProtection: '密码保护',
    UnlimitedFilesAndProjects: '无限文件和项目',
    UnlimitedPages: '无限页面',
    WantPasswordProtection: '升级至专业版以设置密码保护',
    UpgradeForPasswordProtection: '专业版支持在分享文件时设置密码保护。',
    ProhibitInvite: '当前文件被设置为禁止查看者邀请其他成员',
    AdminApplyDesignSeatForOther: '这是一个计费动作，作为管理员，你将升级该成员为「设计席位」。',
    'Email,CommaSeparated': '输入姓名、邮箱，回车输入多个',
} as const

export const enTranslation = {
    ShareProject: 'Share project',
    ShareFile: 'Share file',
    ggHxDU: 'Are you sure you want to make "{{nickname}}" the owner of the {{label}} "{{resourceName}}"?',
    aTmPyR: 'You cannot undo this action.',
    TransferOwner: 'Transfer owner',
    MKWXEt: 'Are you sure you want to remove "{{nickname}}" from the {{label}} "{{resourceName}}"?',
    XmMaTE: 'Remove',
    OlAXkP: 'After removal, This member may lose access to {{file}}',
    JhjXFc: 'this file.',
    uXbutb: 'this project and all files within it.',
    DliUGl: 'Unable to leave the ',
    CzCJjB: 'Assign a new owner from the {{label}} member list before leaving this {{label}}.',
    OK: 'OK',
    CPCNWh: 'Are you sure you want to leave the {{label}} "{{resourceName}}"?',
    Leave: 'Leave ',
    InviteSent: 'Invite sent',
    SendInvite: 'Send Invite',
    NoPasswordProtection: 'No password protection',
    ThreeFilesOneProject: '3 design files and 1 project',
    ThreePagesPerFile: '3 pages per file',
    PasswordProtection: 'Password protection',
    UnlimitedFilesAndProjects: 'Unlimited files and projects',
    UnlimitedPages: 'Unlimited pages in design files',
    WantPasswordProtection: 'Want to set password to protect files? ',
    UpgradeForPasswordProtection: 'Upgrade to the Professional plan to set password protection when sharing files.',
    ProhibitInvite: 'This file settings disable viewers from inviting.',
    AdminApplyDesignSeatForOther: 'This is a billable event, as an admin, you will be upgraded to a "Designer seat".',
    'Email,CommaSeparated': 'Email, comma separated',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
