import { EffectStyleNode, LayoutGridStyleNode, PaintStyleNode, TextStyleNode } from '../../../../document/node/node'
import { StyleGetVO } from '../../../../kernel/interface/component-style'
import { getNamePrefix } from '../style/style-util'

type StyleNode = PaintStyleNode | EffectStyleNode | TextStyleNode | LayoutGridStyleNode
export interface CommonStyleInfo {
    id: string
    name: string
    description: string | null
    groupName: string
    styleName: string
}

export interface StyleContainer {
    name: string
    childs: StyleGroup[]
}

type StyleGroup = StyleGetVO | StyleContainer

export function parseStyleName(name: string) {
    const names = name.split(/\//)
    const styleName = names.pop() ?? ''
    const groupName = names.join('/')
    return { styleName, groupName }
}

export function groupStyleVOByName<T extends { name: string }>(styles?: T[]) {
    const groupMap: Map<string, T[]> = new Map()
    styles?.forEach((style) => {
        if (!style.name) return
        const groupName = getNamePrefix(style.name)
        const groupStyles = groupMap.get(groupName)
        if (groupStyles) {
            groupStyles.push(style)
            groupMap.set(groupName, groupStyles)
        } else {
            groupMap.set(groupName, [style])
        }
    })
    return groupMap
}
