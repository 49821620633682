import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    AboutUnsavedChanges: '关于未保存的操作',
    UnsavedChangesCan:
        '不论标签页是否保持开启，离线操作都可以通过浏览器暂时保存在本地。网络恢复后，再次打开文件，可以将离线操作同步至线上。',
    Done: '我知道了',
    ThisFileHas: '文档中存在未保存的操作',
    YourOfflineChanges: '已同步离线操作',
    SynchronizingOfflineChanges: '正在同步离线操作',
    AnErrorOccurred: '离线操作同步发生错误',
    LearnMore: '了解更多',
} as const

export const enTranslation = {
    AboutUnsavedChanges: 'About unsaved changes',
    UnsavedChangesCan:
        'Unsaved changes will be temporarily stored in your browser, even if you close the tab. Once you are back online, the changes will sync automatically when you reopen the file.',
    Done: 'OK',
    ThisFileHas: 'This file has unsaved changes',
    YourOfflineChanges: 'Offline changes were synced',
    SynchronizingOfflineChanges: 'Synchronizing offline changes',
    AnErrorOccurred: 'An error occurred while synchronizing offline changes',
    LearnMore: 'Learn more',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
