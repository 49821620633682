import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    ca: '联系管理员升级',
    ok: '我知道了',
    uu: '无法升级该团队，请联系管理员将团队升级至付费团队。',

    yh: '有账单未支付',
    ct: '当前团队有 {{time}} 的账单逾期未支付，请支付该账单后再升级版本。',
    py: '去支付',
} as const

export const enTranslation = {
    ca: 'Contact the admins to upgrade',
    ok: 'OK',
    uu: 'Unable to upgrade. Please contact the admins to upgrade the team to a paid plan.',

    yh: 'You have unpaid invoice.',
    ct: 'The current team has an overdue invoice incurred on {{time}}. Pay the invoice before upgrading.',
    py: 'Pay now',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
