import { DELETE, GET, POST, PUT } from '@tutor/network-core'
import { domLocation, hashCode } from '../../../../util/src'
import { environment } from '../../environment'
import { WkCLog } from '../clog/wukong/instance'
import {
    AuthGrant,
    AuthSession,
    EmailWithOptRequest,
    FulfillmentBriefVO,
    GoogleAuthResponse,
    UserVOV2,
} from '../interface/account'
import { Sentry } from '../sentry'
import { translation } from './account.translation'
import { BaseCommonRequest } from './base-request'

export enum Usage {
    signup = 'signup',
    login = 'login',
    setPassword = 'setPassword',
}

interface SendEmailOtpRequest {
    email: string
    usage: Usage
}

export enum OtpUsage {
    authenticate = 'authenticate',
}

export interface AuthenticatePhoneRequest {
    phone: string
    otp: string
}
// 获取手机验证码
@POST
export class AuthenticatePhone extends BaseCommonRequest {
    constructor(private params: AuthenticatePhoneRequest) {
        super()
    }
    public override requestUrl() {
        return '/account/authenticate/phone'
    }

    public override requestBody() {
        return this.params
    }
}
// 获取手机验证码
@POST
export class GetOtpPhone extends BaseCommonRequest {
    constructor(private phone: string, private usage: OtpUsage) {
        super()
    }
    public override requestUrl() {
        return '/account/otp/phone'
    }

    public override requestBody() {
        return { phone: this.phone, usage: this.usage }
    }
}
//非登录场景下通过邮件发送一次性验证码，注册场景:要求邮箱未注册，设置密码场景:要求邮箱已注册
@POST
export class GetUnLoginOtpEmail extends BaseCommonRequest<{ otpToken: string }> {
    constructor(private sendEmailOtpRequest: SendEmailOtpRequest) {
        super()
    }

    public override requestUrl() {
        return '/account/otp/unlogin/email'
    }

    public override requestBody() {
        return this.sendEmailOtpRequest
    }
}

//登录场景下通过邮件发送一次性验证码，要求已注册
@POST
export class GetLoginOtpEmail extends BaseCommonRequest<{ otpToken: string }> {
    public override requestUrl() {
        return '/account/otp/login/email'
    }
}

// 使用邮箱注册时，需要验证验证码
@POST
export class VerifySignUpEmailWithOtp extends BaseCommonRequest<{ verifiedOtpKey: string }> {
    constructor(private email: string, private otp: string, private otpToken: string, private usage: Usage) {
        super()
    }

    public override requestUrl(): string {
        return `/account/${this.usage}/verify-email-otp`
    }

    public override requestBody() {
        return {
            email: this.email,
            otp: this.otp,
            otpToken: this.otpToken,
        }
    }
}

//通过邮件注册
@POST
export class SignUpByEmail extends BaseCommonRequest<UserVOV2> {
    constructor(private emailWithOptRequest: EmailWithOptRequest) {
        super()
    }

    public override additionalRequestHeaders(): { [key: string]: string } | undefined {
        return {
            'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'Time-Zone-Offset': new Date().getTimezoneOffset().toString(),
        }
    }

    public override requestUrl() {
        return '/account/signup/email'
    }

    public override requestBody() {
        return { ...this.emailWithOptRequest, password: hashCode(this.emailWithOptRequest.password) }
    }
}

//通过邮箱登录
@POST
export class LoginByEmailAndPassword extends BaseCommonRequest<UserVOV2> {
    constructor(private data: { email: string; password: string }) {
        super()
    }

    public override requestUrl() {
        return '/account/login/email'
    }

    public override requestBody() {
        return { ...this.data, password: hashCode(this.data.password) }
    }
}

/**
 * @see https://www.cookiestatus.com/safari/
 *
 * Safari上需要通过相同的域名设置cookies才能保证有效期正常
 */
@POST
export class LoginByEmailAndPasswordBySameDomainFirst extends LoginByEmailAndPassword {
    public override baseUrl(): string {
        if (environment.availableHostsForEmailLoginApi.includes(domLocation().host)) {
            return domLocation().origin + '/wukong-api/api'
        } else {
            return super.baseUrl()
        }
    }
}

//退出登录
@POST
export class LogoutRequest extends BaseCommonRequest {
    public override requestUrl() {
        return '/account/logout'
    }
    public override requestDidSuccess(): void {
        Sentry.setUser(null)
        WkCLog.log(translation('LogOut'))
        WkCLog.setUser(null)
    }
}

//退出登录
@PUT
export class UpdatePassword extends BaseCommonRequest {
    constructor(private data: { oldPassword: string; newPassword: string }) {
        super()
    }
    public override requestUrl() {
        return '/account/password/update'
    }
    public override requestBody() {
        return {
            oldPassword: hashCode(this.data.oldPassword),
            newPassword: hashCode(this.data.newPassword),
        }
    }
    public override requestDidFailed(): boolean {
        return false
    }
}
//登录状态重置密码
@PUT
export class ResetPasswordWithEmailLogin extends BaseCommonRequest {
    constructor(private data: { email: string; otp: string; otpToken: string; password: string }) {
        super()
    }
    public override requestUrl() {
        return '/account/password/login-reset/email'
    }
    public override requestDidFailed(): boolean {
        return false
    }
    public override requestBody() {
        return {
            ...this.data,
            password: hashCode(this.data.password),
        }
    }
}

//非登录状态重置密码
@PUT
export class ResetPasswordWithEmailUnLogin extends BaseCommonRequest {
    constructor(
        private data: { email: string; otp?: string; otpToken?: string; password: string; verifiedOtpKey?: string }
    ) {
        super()
    }
    public override requestUrl() {
        return '/account/password/unlogin-reset/email'
    }
    public override requestBody() {
        return {
            ...this.data,
            password: hashCode(this.data.password),
        }
    }
}

//获取桌面客户端授权session
@POST
export class GetAuthSession extends BaseCommonRequest<AuthSession> {
    public override requestUrl() {
        return '/account/auth/session'
    }
    public override requestBody() {
        return { appType: 'desktop' }
    }
}

@GET
export class GoogleLoginCheck extends BaseCommonRequest<GoogleAuthResponse> {
    constructor(
        private state: string,
        private code: string,
        private source: 'web' | 'ios' | 'android' = 'web',
        private utmInfo: string,
        private docId: string = ''
    ) {
        super()
    }
    public override additionalRequestHeaders(): { [key: string]: string } | undefined {
        return {
            'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'Time-Zone-Offset': new Date().getTimezoneOffset().toString(),
        }
    }
    public override requestUrl() {
        return '/account/auth/google'
    }
    public override requestArgument() {
        return {
            state: this.state,
            code: this.code,
            source: this.source,
            utmInfo: this.utmInfo,
            docId: this.docId,
        }
    }
}

@POST
export class GetAuthGrant extends BaseCommonRequest<AuthGrant> {
    constructor(private authSessionId: string) {
        super()
    }
    public override requestUrl() {
        return '/account/auth/grant'
    }
    public override requestBody() {
        return { authSessionId: this.authSessionId, appType: 'desktop' }
    }
}

@POST
export class GetAuthCookie extends BaseCommonRequest {
    constructor(private authSessionId: string, private grantCode: string) {
        super()
    }
    public override requestUrl() {
        return '/account/auth'
    }
    public override requestBody() {
        return { authSessionId: this.authSessionId, grantCode: this.grantCode, appType: 'desktop' }
    }
}
@GET
export class GetExpireTime extends BaseCommonRequest {
    public override requestUrl() {
        return '/account/expire'
    }
}

@GET
export class GetCancelAccountPreValidate extends BaseCommonRequest<{
    invalidFulfillmentVOList: FulfillmentBriefVO[]
}> {
    public override requestUrl() {
        return '/account/delete/pre-validate'
    }
}

@POST
export class DeleteAccount extends BaseCommonRequest {
    public override requestUrl() {
        return '/account/delete'
    }
}

@POST
export class DeleteAccountPasswordValidate extends BaseCommonRequest<{ verifyToken: string }> {
    constructor(private password: string) {
        super()
    }
    public override requestUrl() {
        return '/account/delete/password/validate'
    }
    public override requestBody() {
        return { password: hashCode(this.password) }
    }
    public override requestDidFailed() {
        return false
    }
}

@DELETE
export class DeleteAccountV2 extends BaseCommonRequest {
    constructor(private verifyToken: string) {
        super()
    }
    public override requestUrl() {
        return '/account/delete/v2'
    }
    public override requestBody() {
        return { verifyToken: this.verifyToken }
    }
    public override requestDidFailed() {
        return false
    }
}
