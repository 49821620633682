import { useCallback, useEffect, useMemo, useRef } from 'react'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { RangeArea } from '../../../atom/range/range-area'
import type { RGB } from './color-type'
import { ColorSpace, toCSSBackgroundColorWithAlpha } from './utils/color-translate'

interface OpacityRangeProps {
    color: RGB
    opacity: number // [0-1]
    width: number
    height: number
    colorSpace: ColorSpace
    className?: string
    onChange?: (opacity: number, options?: InputOptionsForUndoSquash) => void
}

export function OpacityRange(props: OpacityRangeProps) {
    const { color, opacity, width, height, colorSpace, className, onChange } = props
    const canvasAlpha = useRef<HTMLCanvasElement>(null)

    // 绘制 opacity 区域
    useEffect(() => {
        if (!canvasAlpha.current) return
        const ctx =
            colorSpace === 'srgb'
                ? canvasAlpha.current.getContext('2d')
                : canvasAlpha.current.getContext('2d', {
                      colorSpace,
                  })
        if (!ctx) return
        let { width: _width, height: _height } = canvasAlpha.current.getBoundingClientRect()
        _width = Math.floor(_width)
        _height = Math.floor(_height)
        canvasAlpha.current.width = _width
        canvasAlpha.current.height = _height

        ctx.fillStyle = '#e3e3e3'
        const rectWidth = 3
        for (let i = 0; i < _height; i = i + rectWidth) {
            const offset = (i / rectWidth) % 2 ? 0 : rectWidth
            for (let j = offset; j < _width; j = j + 2 * rectWidth) {
                ctx.fillRect(j, i, rectWidth, rectWidth)
            }
        }

        const linearGradient = ctx.createLinearGradient(0, 0, _width, 0)
        if (colorSpace === 'display-p3') {
            linearGradient.addColorStop(0, `color(display-p3 ${color.r / 255} ${color.g / 255} ${color.b / 255} / 0)`)
            linearGradient.addColorStop(1, `color(display-p3 ${color.r / 255} ${color.g / 255} ${color.b / 255} / 1)`)
        } else {
            linearGradient.addColorStop(
                0,
                `rgba(${Math.round(color.r)},${Math.round(color.g)},${Math.round(color.b)},0)`
            )
            linearGradient.addColorStop(
                1,
                `rgba(${Math.round(color.r)},${Math.round(color.g)},${Math.round(color.b)},1)`
            )
        }

        ctx.fillStyle = linearGradient
        ctx.fillRect(0, 0, _width, _height)
    }, [color, colorSpace])

    // 通过拖动圆圈 修改透明度
    const onChangeOpacity = useCallback(
        (value: any, options?: InputOptionsForUndoSquash) => {
            const { rateX } = value
            onChange?.(rateX, options)
        },
        [onChange]
    )

    const rangeStyle = useMemo(() => {
        return { width: `${width}px`, height: `${height}px`, borderRadius: `${height / 2}px` }
    }, [height, width])

    const canvasStyle = useMemo(() => {
        return { borderRadius: `${height / 2}px` }
    }, [height])

    const handleStyle = (() => {
        return { background: toCSSBackgroundColorWithAlpha({ ...color, a: opacity }, colorSpace) }
    })()

    return (
        <RangeArea
            style={rangeStyle}
            onHandleMove={onChangeOpacity}
            handleStyle={handleStyle}
            canvasStyle={canvasStyle}
            forbidOverflow={true}
            rateX={opacity}
            rateY={0.5}
            className={className}
            testId="opacity-rage-area"
        >
            <canvas ref={canvasAlpha} width={width} height={height} style={{ width: '100%', height: '100%' }} />
        </RangeArea>
    )
}
