import { useMemo } from 'react'
import { DraggablePopupV2 } from '../../../../../../ui-lib/src'
import { isEnglishLanguage } from '../../../../../../util/src'
import { useAppContext } from '../../../../main/app-context'
import { useEditingDetailState } from '../use-view-state-hook'
import { SelectedVariablesEditorContext, useSelectedVariablesEditorContext } from './selected-variables-editor-context'
import { SelectedVariablesEditorDetail } from './selected-variables-editor-detail'
import { SelectedVariablesEditorScope } from './selected-variables-editor-scope'
import { SelectedVariablesEditorTitle } from './selected-variables-editor-title'
import { SelectedVariablesEditorActiveKey } from './utils'

function SelectedVariablesEditorInternal({
    editorPopupPosition,
    closeEditor,
}: {
    editorPopupPosition: { left: number; top: number }
    closeEditor: () => void
}) {
    const { variables } = useSelectedVariablesEditorContext()

    if (!variables.length) {
        return null
    }
    return <_SelectedVariablesEditorInternal editorPopupPosition={editorPopupPosition} closeEditor={closeEditor} />
}
function _SelectedVariablesEditorInternal({
    editorPopupPosition,
    closeEditor,
}: {
    editorPopupPosition: { left: number; top: number }
    closeEditor: () => void
}) {
    const { variables, collection, activeTabKey, onChangeActiveTabKey, scopeCheckboxValues } =
        useSelectedVariablesEditorContext()
    const position = useMemo(() => {
        return {
            left: isEnglishLanguage() ? editorPopupPosition.left - 17 : editorPopupPosition.left,
            top: editorPopupPosition.top,
        }
    }, [editorPopupPosition.left, editorPopupPosition.top])
    return (
        <DraggablePopupV2
            visible
            mask
            position={position}
            enableScrollBar
            closeTestId="selected-variables-editor-close"
            header={<SelectedVariablesEditorTitle activeKey={activeTabKey} onActiveTabChange={onChangeActiveTabKey} />}
            footer={null}
            onCancel={closeEditor}
            styleType="editor"
            bodyClassName="p-0"
            width={isEnglishLanguage() ? 257 : 240}
        >
            {activeTabKey === SelectedVariablesEditorActiveKey.DETAIL && (
                <SelectedVariablesEditorDetail variables={variables} collection={collection} />
            )}
            {activeTabKey === SelectedVariablesEditorActiveKey.SCOPE && (
                <SelectedVariablesEditorScope scopeCheckboxValues={scopeCheckboxValues} />
            )}
        </DraggablePopupV2>
    )
}

export function SelectedVariablesEditor() {
    const service = useAppContext().variableService.localVariableEditorService
    const isOpened = useEditingDetailState()
    if (!isOpened) {
        return null
    }
    return (
        <SelectedVariablesEditorContext>
            <SelectedVariablesEditorInternal
                editorPopupPosition={isOpened}
                closeEditor={() => service.endEditVariable()}
            />
        </SelectedVariablesEditorContext>
    )
}
