import { PUT } from '@tutor/network-core'
import { TeamID } from '../interface/type'
import { BaseCommonRequest } from './base-request'

export interface TeamPreferenceVO {
    teamIds: TeamID[]
    orgId: string
}

// 上传用户团队偏好
@PUT
export class PutTeamPreferences extends BaseCommonRequest<boolean> {
    constructor(private readonly teamPreferenceVO: TeamPreferenceVO) {
        super()
    }

    public override requestUrl() {
        return 'team-preferences'
    }

    public override requestBody() {
        return this.teamPreferenceVO
    }
}
