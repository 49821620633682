import { translation } from './comment-search-dom.translation'
/* eslint-disable no-restricted-imports */
import { useCallback, useState } from 'react'
import {
    DropdownV2,
    InputV2,
    MonoIconPanelAdjust16,
    MonoIconPanelFilter16,
    Tooltip,
} from '../../../../../../ui-lib/src'
import { Strategy } from '../../../../kernel/interface/comment'
import { ShortcutKey, shortcutLabelMap } from '../../../../kernel/interface/shortcut-key'
import classes from './comment-search-dom.module.less'
import { ShowFilterType, SortType } from './type'

enum SortParams {
    sortByTime = 'sortByTime',
    sortByUnread = 'sortByUnread',
    showTypeResolved = 'showTypeResolved',
    showTypeRelative = 'showTypeRelative',
    showTypeCurrentPage = 'showTypeCurrentPage',
}

enum SetParams {
    hideComment = 'HideComment',
    noticeAll = 'NoticeAll',
    noticeSetting = 'NoticeSetting',
    noticeRelative = 'NoticeRelative',
    noticeNone = 'NoticeNone',
}

export interface CommentSearchDOMProps {
    isHideComment?: boolean
    sortType?: SortType
    showFilters?: ShowFilterType[]
    noticeType?: Strategy
    value?: string
    onChange?: (value: string) => void
    onSortByTime?: () => void
    onSortByUnread?: () => void
    onShowTypeChange?: (value: ShowFilterType[]) => void
    onClickHideComment?: () => void
    onSwitchNoticeType?: (value: Strategy) => void
    onClickIcon?: () => void
    dataTestIds?: {
        noticeBtn?: string
        hideComment?: string
        noticeAll?: string
        noticeRelative?: string
        noticeNone?: string
        sortBtn?: string
        sortByTime?: string
        sortByUnread?: string
        showTypeResolved?: string
        showTypeRelative?: string
        showTypeCurrentPage?: string
    }
}
const defaultDataTestIds = {
    noticeBtn: 'comment-noticeBtn',
    hideComment: 'comment-hideComment',
    noticeAll: 'comment-noticeAll',
    noticeRelative: 'comment-noticeRelative',
    noticeNone: 'comment-noticeNone',
    sortBtn: 'comment-sortBtn',
    sortByTime: 'comment-sortByTime',
    sortByUnread: 'comment-sortByUnread',
    showTypeResolved: 'comment-showTypeResolved',
    showTypeRelative: 'comment-showTypeRelative',
    showTypeCurrentPage: 'comment-showTypeCurrentPage',
}
export function CommentSearchDOM(props: CommentSearchDOMProps) {
    const {
        sortType = SortType.Time,
        showFilters = [],
        dataTestIds = defaultDataTestIds,
        onChange,
        onSortByTime,
        onSortByUnread,
        onShowTypeChange,
        onSwitchNoticeType,
        onClickHideComment,
        onClickIcon,
    } = props
    const [openSort, setOpenSort] = useState<boolean>(false)
    const [openNotice, setOpenNotice] = useState<boolean>(false)
    const switchOpenSort = useCallback(() => {
        setOpenSort(!openSort)
    }, [openSort])

    const switchOpenNotice = useCallback(() => {
        setOpenNotice(!openNotice)
    }, [openNotice])

    const _onSortByTime = useCallback(() => {
        switchOpenSort()
        onSortByTime?.()
    }, [switchOpenSort, onSortByTime])

    const _onSortByUnread = useCallback(() => {
        switchOpenSort()
        onSortByUnread?.()
    }, [switchOpenSort, onSortByUnread])

    const _onShowTypeChange = useCallback(
        (switchType: ShowFilterType) => {
            if (showFilters.includes(switchType)) {
                onShowTypeChange?.(showFilters.filter((v) => v !== switchType))
            } else {
                onShowTypeChange?.([...showFilters, switchType])
            }
        },
        [showFilters, onShowTypeChange]
    )

    const onShowSwitchResolved = useCallback(() => {
        switchOpenSort()
        _onShowTypeChange(ShowFilterType.Resolved)
    }, [switchOpenSort, _onShowTypeChange])

    const onShowSwitchRelative = useCallback(() => {
        switchOpenSort()
        _onShowTypeChange(ShowFilterType.Relative)
    }, [switchOpenSort, _onShowTypeChange])

    const onShowSwitchCurrentPage = useCallback(() => {
        switchOpenSort()
        _onShowTypeChange(ShowFilterType.CurrentPage)
    }, [switchOpenSort, _onShowTypeChange])

    const _onClickHideComment = useCallback(() => {
        switchOpenNotice()
        onClickHideComment?.()
    }, [switchOpenNotice, onClickHideComment])

    const onSwitchNotice2All = useCallback(() => {
        switchOpenNotice()
        onSwitchNoticeType?.(Strategy.All)
    }, [switchOpenNotice, onSwitchNoticeType])

    const onSwitchNotice2Relative = useCallback(() => {
        switchOpenNotice()
        onSwitchNoticeType?.(Strategy.Relative)
    }, [switchOpenNotice, onSwitchNoticeType])

    const onSwitchNotice2None = useCallback(() => {
        switchOpenNotice()
        onSwitchNoticeType?.(Strategy.None)
    }, [switchOpenNotice, onSwitchNoticeType])

    const dispatchSortEvent = useCallback(
        (value: SortParams) => {
            switch (value) {
                case SortParams.sortByTime:
                    _onSortByTime()
                    break
                case SortParams.sortByUnread:
                    _onSortByUnread()
                    break
                case SortParams.showTypeResolved:
                    onShowSwitchResolved()
                    break
                case SortParams.showTypeRelative:
                    onShowSwitchRelative()
                    break
                case SortParams.showTypeCurrentPage:
                    onShowSwitchCurrentPage()
                    break
            }
        },
        [_onSortByTime, _onSortByUnread, onShowSwitchCurrentPage, onShowSwitchRelative, onShowSwitchResolved]
    )

    const dispatchSetEvent = useCallback(
        (value: SetParams) => {
            switch (value) {
                case SetParams.hideComment:
                    _onClickHideComment()
                    break
                case SetParams.noticeAll:
                    onSwitchNotice2All()
                    break
                case SetParams.noticeRelative:
                    onSwitchNotice2Relative()
                    break
                case SetParams.noticeNone:
                    onSwitchNotice2None()
                    break
                case SetParams.noticeSetting:
            }
        },
        [_onClickHideComment, onSwitchNotice2All, onSwitchNotice2None, onSwitchNotice2Relative]
    )

    return (
        <div className={classes.search}>
            <InputV2.Search
                rootClassName="flex-1"
                placeholder={translation('Search')}
                value={props.value}
                onSearch={onChange}
                dataTestIds={{ input: 'comment-search-input', clearIcon: 'icon-close-svg' }}
            />
            <Tooltip title={`${translation('Sort')}/${translation('Filter')}`} triggerRefKey="getTriggerElement">
                <DropdownV2.IconSingleLevel
                    style={{ flex: 'none', marginLeft: 10 }}
                    label={<MonoIconPanelFilter16 />}
                    placement="bottom right"
                    dataTestIds={{ triggerFocus: dataTestIds?.sortBtn }}
                    onChange={dispatchSortEvent}
                    className={showFilters.length ? classes.hasFilterItem : undefined}
                    classNameWhenOpen={classes.sortOpen}
                    onOpen={onClickIcon}
                >
                    <DropdownV2.IconSingleLevel.Option
                        value={SortParams.sortByTime}
                        isSelect={sortType === SortType.Time}
                        dataTestId={dataTestIds?.sortByTime}
                    >
                        {translation('SortByDate')}
                    </DropdownV2.IconSingleLevel.Option>
                    <DropdownV2.IconSingleLevel.Option
                        value={SortParams.sortByUnread}
                        isSelect={sortType === SortType.Unread}
                        dataTestId={dataTestIds?.sortByUnread}
                    >
                        {translation('SortByUnread')}
                    </DropdownV2.IconSingleLevel.Option>
                    <DropdownV2.IconSingleLevel.Option
                        value={SortParams.showTypeResolved}
                        isSelect={showFilters.includes(ShowFilterType.Resolved)}
                        dataTestId={dataTestIds?.showTypeResolved}
                        splitLineTop
                    >
                        {translation('ShowResolvedComments')}
                    </DropdownV2.IconSingleLevel.Option>
                    <DropdownV2.IconSingleLevel.Option
                        value={SortParams.showTypeRelative}
                        isSelect={showFilters.includes(ShowFilterType.Relative)}
                        dataTestId={dataTestIds?.showTypeRelative}
                    >
                        {translation('OnlyYourThreads')}
                    </DropdownV2.IconSingleLevel.Option>
                    <DropdownV2.IconSingleLevel.Option
                        value={SortParams.showTypeCurrentPage}
                        isSelect={showFilters.includes(ShowFilterType.CurrentPage)}
                        dataTestId={dataTestIds?.showTypeCurrentPage}
                    >
                        {translation('OnlyCurrentPage')}
                    </DropdownV2.IconSingleLevel.Option>
                </DropdownV2.IconSingleLevel>
            </Tooltip>
            <Tooltip title={translation('Settings')} triggerRefKey="getTriggerElement">
                <DropdownV2.IconSingleLevel
                    style={{ flex: 'none', marginLeft: 4 }}
                    label={<MonoIconPanelAdjust16 />}
                    placement="bottom right"
                    dataTestIds={{ triggerFocus: dataTestIds?.noticeBtn }}
                    onChange={dispatchSetEvent}
                    onOpen={onClickIcon}
                >
                    <DropdownV2.IconSingleLevel.Option
                        value={SetParams.hideComment}
                        isSelect={props.isHideComment}
                        backwardIcon={shortcutLabelMap[ShortcutKey.ShowComment]}
                        dataTestId={dataTestIds?.hideComment}
                    >
                        {translation('HideComments')}
                    </DropdownV2.IconSingleLevel.Option>
                    <DropdownV2.IconSingleLevel.Option
                        value={SetParams.noticeSetting}
                        isSelect={false}
                        disabled
                        splitLineTop
                    >
                        {translation('NotifyMeAbout')}
                    </DropdownV2.IconSingleLevel.Option>
                    <DropdownV2.IconSingleLevel.Option
                        value={SetParams.noticeAll}
                        isSelect={props.noticeType === Strategy.All}
                        dataTestId={dataTestIds?.noticeAll}
                    >
                        {translation('Everything')}
                    </DropdownV2.IconSingleLevel.Option>
                    <DropdownV2.IconSingleLevel.Option
                        value={SetParams.noticeRelative}
                        isSelect={props.noticeType === Strategy.Relative}
                        dataTestId={dataTestIds?.noticeRelative}
                    >
                        {translation('JustMentionsAnd')}
                    </DropdownV2.IconSingleLevel.Option>
                    <DropdownV2.IconSingleLevel.Option
                        value={SetParams.noticeNone}
                        isSelect={props.noticeType === Strategy.None}
                        dataTestId={dataTestIds?.noticeNone}
                    >
                        {translation('Nothing')}
                    </DropdownV2.IconSingleLevel.Option>
                </DropdownV2.IconSingleLevel>
            </Tooltip>
        </div>
    )
}
