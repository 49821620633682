import { ContractSpecification, TeamID } from '../../kernel/interface/type'
import { BusinessStatusCode } from '../../kernel/interface/request-error-code'
import { getPayUpgradeDialogFrom } from '../../utils/payment'
import {
    PayUpgradeCopyFileFileLimitSceneDialog,
    PayUpgradeCopyFilePageLimitSceneDialog,
    PayUpgradeMoveFileFileLimitSceneDiadlog,
    PayUpgradeMoveFilePageLimitSceneDiadlog,
} from './pay-upgrade-scene-modal'

export function handleBusinessStatusInMoveDocs(
    businessStatus: BusinessStatusCode,
    moveInteamId: TeamID,
    initUpgradePayment?: (plan: ContractSpecification, selectedTeamId?: string) => Promise<void>
) {
    if (businessStatus == BusinessStatusCode.Unavailable) {
        PayUpgradeMoveFileFileLimitSceneDiadlog(getPayUpgradeDialogFrom(), moveInteamId, initUpgradePayment)
        return
    }
    if (businessStatus == BusinessStatusCode.DocPagesOverPlanLimit) {
        PayUpgradeMoveFilePageLimitSceneDiadlog(getPayUpgradeDialogFrom(), moveInteamId, initUpgradePayment)
        return
    }
}

export function handleBusinessStatusInCopyDocs(
    businessStatus: BusinessStatusCode,
    teamId: TeamID | undefined,
    initUpgradePayment?: (plan: ContractSpecification, selectedTeamId?: string) => Promise<void>
) {
    if (businessStatus == BusinessStatusCode.Unavailable) {
        PayUpgradeCopyFileFileLimitSceneDialog(getPayUpgradeDialogFrom(), teamId, initUpgradePayment)
        return
    }
    if (businessStatus == BusinessStatusCode.DocPagesOverPlanLimit) {
        PayUpgradeCopyFilePageLimitSceneDialog(getPayUpgradeDialogFrom(), teamId, initUpgradePayment)
        return
    }
}
