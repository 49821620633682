import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    title: '管理企业私有插件',
    description: '启用后，即可在插件列表中使用企业私有插件。',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    title: 'Manage private organization plugins',
    description: 'Once enabled, you can run the private organization plugin from the plugin panel.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
