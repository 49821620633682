import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    Tools: '工具',
    View: '视图',
    Text: '文本',
    Shape: '矢量网络',
    Components: '组件',
    Selection: '选中图层',
    Transform: '变换',
    Object: '图层',
    Edit: '编辑',
    Arrange: '排列',
} as const

export const enTranslation = {
    Tools: 'Tools',
    View: 'View',
    Text: 'Text',
    Shape: 'Shape',
    Components: 'Components',
    Selection: 'Selection',
    Transform: 'Transform',
    Object: 'Object',
    Edit: 'Edit',
    Arrange: 'Arrange',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
