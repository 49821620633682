/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import React, { useCallback, useEffect, useRef } from 'react'
import { cmdTextKeyboardOperation } from '../../../document/command/page-command'
import { isKey, isUndoRedoEvent, KeyboardCode } from '../../../kernel/keyboard/keyboard-event-handler'
import { isWindows } from '../../../kernel/util/ua'
import { useLeftMargin, useTopMargin } from '../../../main/layout/layout-context'
import { focusViewManager } from '../../../main/service/focus-view/focus-view-manager'
import { shouldPassThisEventToWasmWhenTextInputFocused } from '../../../main/service/focus-view/keyboard-event-prevent-or-pass'
import { dispatchKeyboardEventToWasm } from '../../../main/service/focus-view/keyboard-event-to-wasm'
import { TextEditCustomInputId } from '../../../main/text-editing-input/text-editing-input-element'
import { useViewCallback } from '../../../view-state-bridge'
import { useCommand } from '../../context/document-context'
import style from './custom-focus.module.less'
import { useTextEdit } from './use-text-edit'

export interface CustomFocusProps {
    dataTestId?: string
}
export function CustomFocus(props: CustomFocusProps) {
    const inputEl = useRef<HTMLInputElement | null>(null)
    const containerEl = useRef<HTMLDivElement>(null)

    const command = useCommand()
    const isCompositionModRef = useRef<boolean>(false)

    const { isEditing, hyperlinkEditing, textInputChange } = useTextEdit()

    const leftMargin = useLeftMargin()
    const topMargin = useTopMargin()

    const updateInputStyle = useCallback(
        (v: Wukong.DocumentProto.IVTextEditingState) => {
            const { cursorInfo, textEditing } = v
            const input = inputEl.current
            if (!input) {
                return
            }
            if (!textEditing) {
                input.style.left = `${-200}px`
                return
            }
            const left = (cursorInfo?.left ?? 0) + leftMargin
            const top = (cursorInfo?.top ?? 0) + topMargin
            const fontSize = Math.abs((cursorInfo?.bottom ?? 0) - (cursorInfo?.top ?? 0))
            input.style.top = `${top}px`
            input.style.left = `${left}px`
            input.style.height = `${fontSize}px`
            input.style.fontSize = `${fontSize}px`
        },
        [leftMargin, topMargin]
    )
    useViewCallback('textEditingState', updateInputStyle)

    const inputChange = useCallback(
        (mode: Wukong.DocumentProto.InputMode, value?: string) => {
            textInputChange({ mode, value })
            if (mode === Wukong.DocumentProto.InputMode.INPUT_MODE_END) {
                inputEl.current!.value = ''
            }
        },
        [textInputChange]
    )

    const onValueChange = useCallback(
        (e: any) => {
            if (isCompositionModRef.current) {
                return
            }
            const value = e.target.value
            inputChange(Wukong.DocumentProto.InputMode.INPUT_MODE_START)
            inputChange(Wukong.DocumentProto.InputMode.INPUT_MODE_END, value)
        },
        [inputChange]
    )

    const onCompositionStart = useCallback(() => {
        isCompositionModRef.current = true
        inputChange(Wukong.DocumentProto.InputMode.INPUT_MODE_START)
    }, [inputChange])

    const onCompositionUpdate = useCallback(
        (e: any) => {
            inputChange(Wukong.DocumentProto.InputMode.INPUT_MODE_UPDATE, e.data)
        },
        [inputChange]
    )

    const onCompositionEnd = useCallback(
        (e: any) => {
            isCompositionModRef.current = false
            inputChange(Wukong.DocumentProto.InputMode.INPUT_MODE_END, e.data)
        },
        [inputChange]
    )

    const onKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            const keyEvent = e.nativeEvent
            const isBlurEvent =
                isKey(keyEvent, KeyboardCode.ESCAPE) || (e.metaKey && isKey(keyEvent, KeyboardCode.ENTER))
            const isPreventOldKeyboardSystemHandler = isKey(keyEvent, KeyboardCode.END)
            if (isBlurEvent || isPreventOldKeyboardSystemHandler) {
                e.stopPropagation()
            }

            const isRawOrShiftSpace = isKey(keyEvent, KeyboardCode.SPACE) && !e.metaKey && !e.altKey && !e.ctrlKey
            if (isRawOrShiftSpace && e.nativeEvent.key === 'Unidentified') {
                // https://wkong.atlassian.net/browse/WK-24070 某些特殊场景下空格键会多触发一个key=Unidentified的event
                return
            }

            if (isRawOrShiftSpace) {
                // 空格键直接由wasm单独处理
                e.preventDefault()
            }

            const event = { ...e, type: Wukong.DocumentProto.KeyEventType.KEY_EVENT_TYPE_KEY_DOWN }
            command.invoke(cmdTextKeyboardOperation, event)

            const isUndoOrRedo = isUndoRedoEvent(keyEvent)
            // 阻止 windows ctrl + k 调起系统搜索
            const isWindowsCtrlK = isWindows() && isKey(keyEvent, KeyboardCode.K) && e.ctrlKey
            if (isUndoOrRedo || isWindowsCtrlK || isKey(keyEvent, KeyboardCode.TAB)) {
                // 禁掉输入框的原生的 undo/redo,  undo/redo 由 undoManager 来处理
                e.preventDefault()
            }
            shouldPassThisEventToWasmWhenTextInputFocused(keyEvent) &&
                dispatchKeyboardEventToWasm(
                    command,
                    e.nativeEvent,
                    Wukong.DocumentProto.KeyboardEventTraceSource.KEYBOARD_EVENT_TRACE_SOURCE_TEXT_INPUT
                )
        },
        [command]
    )

    const onBlur = useCallback(() => {
        if (focusViewManager.isFocusViewEnabled) {
            return
        }
        inputChange(Wukong.DocumentProto.InputMode.INPUT_MODE_BLUR)
    }, [inputChange])

    const onFocus = useCallback(() => {
        if (focusViewManager.isFocusViewEnabled) {
            return
        }
        inputChange(Wukong.DocumentProto.InputMode.INPUT_MODE_FOCUS)
    }, [inputChange])

    useEffect(() => {
        if (focusViewManager.isFocusViewEnabled) {
            return
        }
        if (isEditing) {
            // NOTE: 编辑链接时不自动focus到text input, 否则影响链接编辑Input自动focus
            if (!hyperlinkEditing) {
                inputEl.current?.focus()
            }
        } else {
            inputEl.current?.blur()
        }
    }, [isEditing, hyperlinkEditing, command])

    const bindTextEditingInput = useCallback((e: HTMLInputElement | null) => {
        inputEl.current = e
        // [devMode 下启用 focusView] 始终挂载 input 元素
        focusViewManager.bindTextEditingInputElement(e)
    }, [])

    return (
        <div ref={containerEl}>
            <input
                tabIndex={-1}
                ref={bindTextEditingInput}
                id={TextEditCustomInputId}
                className={style.custom_focus_input}
                onKeyDown={onKeyDown}
                onChange={onValueChange}
                onCompositionStart={onCompositionStart}
                onCompositionUpdate={onCompositionUpdate}
                onCompositionEnd={onCompositionEnd}
                // 禁止input粘贴, paste事件由clipboard-service统一处理
                onPaste={(e) => e.preventDefault()}
                onBlur={onBlur}
                onFocus={onFocus}
                autoComplete="off"
                data-testid={props.dataTestId}
            />
        </div>
    )
}
