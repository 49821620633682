/* eslint-disable no-restricted-imports */
import {
    HistoryModeGetPageArchive,
    OnBridgeStatusChange,
    OnDocumentCommand,
    SetCurrentPageWasmCall,
    Wukong,
} from '@wukong/bridge-proto'
import constate from 'constate'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { GetHistoryVersionData } from '../../../kernel/request/history-version'
import { useBridge, useCommand, useDocId, useHistoryService } from '../../app-context'
import { getRouteHistoryVersionIdAndNodeId } from '../utlis'

const useHistoryModeContextInternal = () => {
    const [inHistoryWasm, _setInHistoryWasm] = useState(false)
    const historyService = useHistoryService()

    const lastArchiveFromSinglePage = useRef(false)
    const bridge = useBridge()
    const commandInvoker = useCommand()

    const docId = useDocId()

    const [historyModeLoading, setHistoryModeLoading] = useState(false)

    const setInHistoryWasm = useCallback(
        (nextInHistoryWasm: boolean) => {
            _setInHistoryWasm(nextInHistoryWasm)
            historyService.updateIsInHistoryWasm(nextInHistoryWasm)
        },
        [historyService]
    )

    useEffectOnce(() => historyService.updateIsInHistoryWasm(inHistoryWasm))

    // 只有在历史版本的 wasm 下可能从 wasm 触发这个回调
    const handleHistoryModeGetPageArchive = useCallback(
        async (arg: Wukong.DocumentProto.IBridgeProtoString) => {
            setHistoryModeLoading(true)

            const { historyVersionId } = getRouteHistoryVersionIdAndNodeId()

            const pageArchive = await new GetHistoryVersionData(Number(historyVersionId), true, undefined, arg.value!)
                .start()
                .then(async (arrayBuffer) => {
                    return Wukong.ServerProto.SynergyMessageProto.decode(new Uint8Array(arrayBuffer))
                })

            // 以下 wasmCall 的触发在上述请求的回调后，不属于 JCC 中调用写入 WCC 的范畴
            // TODO(hulh01): 当前的 archive 均没有办法使用 firstTick 优化，应该可以更换成 createSubTree 的方案
            {
                // 切换 status 状态 【只有 syncing 阶段可以执行 onDocument】
                commandInvoker.DEPRECATED_invokeBridge(OnBridgeStatusChange, { value: 3 })
                commandInvoker.DEPRECATED_invokeBridge(OnBridgeStatusChange, { value: 1 })

                // 执行 onDocument，处理当页的 archive
                commandInvoker.DEPRECATED_invokeBridge(OnDocumentCommand, {
                    payload: pageArchive.payload,
                    docId,
                    finalFragment: pageArchive.finalFragment ?? true,
                    checksum: pageArchive.checksum,
                    docHash: pageArchive.docHash,
                    index: pageArchive.index ?? {},
                    opLogs: pageArchive.opLogs ?? [],
                    compressType:
                        pageArchive.compressType ?? Wukong.DocumentProto.CompressType.COMPRESS_TYPE_NO_COMPRESS,
                })
            }

            // 切换到对应的 page
            commandInvoker.DEPRECATED_invokeBridge(
                SetCurrentPageWasmCall,
                Wukong.DocumentProto.SetCurrentPageCommandParam.create({
                    nodeId: arg.value,
                })
            )

            setHistoryModeLoading(false)
        },
        [commandInvoker, docId]
    )

    useEffect(() => {
        // 为切换页面注入的回调
        bridge.bind(HistoryModeGetPageArchive, handleHistoryModeGetPageArchive)

        return () => {
            bridge.unbind(HistoryModeGetPageArchive)
        }
    }, [bridge, handleHistoryModeGetPageArchive])

    return {
        inHistoryWasm,
        setInHistoryWasm,
        lastArchiveFromSinglePage,
        setLastArchiveFromSinglePage: (value: boolean) => {
            lastArchiveFromSinglePage.current = value
        },
        historyModeLoading,
        setHistoryModeLoading,
    }
}

export const [HistoryModeContextProvider, useInHistoryWasm, useLastArchiveFromSinglePage, useHistoryModeLoading] =
    constate(
        useHistoryModeContextInternal,
        (ctx) => ({
            inHistoryWasm: ctx.inHistoryWasm,
            setInHistoryWasm: ctx.setInHistoryWasm,
        }),
        (ctx) => ({
            lastArchiveFromSinglePage: ctx.lastArchiveFromSinglePage,
            setLastArchiveFromSinglePage: ctx.setLastArchiveFromSinglePage,
        }),
        (ctx) => ({
            historyModeLoading: ctx.historyModeLoading,
            setHistoryModeLoading: ctx.setHistoryModeLoading,
        })
    )
