import { Wukong } from '@wukong/bridge-proto'
import { SimpleDrag } from '../../../../../../ui-lib/src/components/simple-drag/simple-drag'
import { Effect } from '../../../../document/node/node'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { useRenderColorSpace } from '../../color-profile/hook'
import { useEffectContext } from './context'
import { EffectItem } from './effect-item'

export function EffectsList() {
    return <_ReversedEffectsList></_ReversedEffectsList>
}

function _ReversedEffectsList() {
    const {
        selectionEffectListSelectedReversedIndexes,
        selectionEffectList,
        effectListSelectedChange,
        adjustEffectListOrder,
        hasBackgroundBlur,
        hasLayerBlur,
        openedEffectListIndex,
        updateEffectDetail,
        openEffectEditingModal,
        closeEffectEditingModal,
        removeEffectForSelection,
        enableEffectsSpread,
        selectionVariables,
        selectionFloatVariables,
    } = useEffectContext()
    const colorSpace = useRenderColorSpace()

    const _selectionEffectList = selectionEffectList.slice().reverse()
    const _selectedIndexList =
        selectionEffectListSelectedReversedIndexes?.indexes.map((v) => _selectionEffectList.length - v - 1) ?? []
    const _effectListSelectedChange = (indexes: number[]) => {
        effectListSelectedChange(indexes.map((v) => _selectionEffectList.length - v - 1))
    }
    const _adjustEffectListOrder = (items: Effect[], indexes: number[]) => {
        adjustEffectListOrder(
            items.slice().reverse(),
            indexes.map((v) => _selectionEffectList.length - v - 1)
        )
    }
    const isOpen = (index: number) => openedEffectListIndex === _selectionEffectList.length - index - 1
    const _updateEffectData = (index: number) => (data: Partial<Effect>, options?: InputOptionsForUndoSquash) => {
        updateEffectDetail(data, _selectionEffectList.length - index - 1, options)
    }
    const _updateEffectBlendMode = (index: number) => (blendMode: Wukong.DocumentProto.BlendMode) => {
        updateEffectDetail({ blendMode }, _selectionEffectList.length - index - 1)
    }
    const _updateEffectVisible = (index: number) => (visible: boolean) => {
        updateEffectDetail({ visible }, _selectionEffectList.length - index - 1)
    }
    const _updateEffectModalOpened = (index: number) => (opening: boolean) => {
        if (opening) {
            openEffectEditingModal(_selectionEffectList.length - index - 1)
        } else {
            closeEffectEditingModal()
        }
    }
    const _removeEffectForSelection = (index: number) => () => {
        removeEffectForSelection(_selectionEffectList.length - index - 1)
    }
    const _detachEffectColorVar = (index: number) => () => {
        updateEffectDetail({ colorVar: null }, _selectionEffectList.length - index - 1)
    }

    return (
        <SimpleDrag
            selectedIndexList={_selectedIndexList}
            items={_selectionEffectList}
            onSelectChange={_effectListSelectedChange}
            onDragDone={_adjustEffectListOrder}
            onMouseDown={(e) => e.stopPropagation()}
            dataTestIds={{
                simpleDrag: 'effect-list',
            }}
        >
            {_selectionEffectList.map((effect, index) => (
                <SimpleDrag.Item
                    key={index}
                    itemIndex={index}
                    dataTestIds={{ item: `effect-drag-item-${index}`, dragIcon: 'effect-drag-icon' }}
                >
                    <EffectItem
                        key={index}
                        selected={_selectedIndexList.includes(index)}
                        data={effect}
                        colorSpace={colorSpace}
                        hasBackgroundBlur={hasBackgroundBlur}
                        hasLayerBlur={hasLayerBlur}
                        enableSpread={enableEffectsSpread}
                        variables={selectionVariables}
                        floatVariables={selectionFloatVariables}
                        isOpen={isOpen(index)}
                        onChangeData={_updateEffectData(index)}
                        onChangeBlendMode={_updateEffectBlendMode(index)}
                        onChangeVisible={_updateEffectVisible(index)}
                        onChangeModalVisible={_updateEffectModalOpened(index)}
                        onClickSub={_removeEffectForSelection(index)}
                        onDetachColorVar={_detachEffectColorVar(index)}
                    />
                </SimpleDrag.Item>
            ))}
        </SimpleDrag>
    )
}
