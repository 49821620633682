import { Wukong, ZoomToFitNodesAtMaxScaleByIdsCommand } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useCallback, useMemo } from 'react'
import { IconClock } from '../../../../../../../ui-lib/src'
import { timeDesc } from '../../../../../../../util/src'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'
import { getSVGIconByNodeIconType } from '../../../layer-panel/render-item-icon'
import { CopyableRow } from '../common/copyable-row'
import style from '../inspect.module.less'
import classes from './index.module.less'
import { translation } from './index.translation'

import NodeIconData = Wukong.DocumentProto.NodeIconData

export function DevModeInspectNodeBasicInfo() {
    const { show, selectionInfo } = useViewState('devModeInspectNodeBasicInfoViewState') ?? {}

    if (!show) {
        return null
    }

    const editInfo = getDispalyEditInfo(selectionInfo?.editInfo)

    return (
        <div className={style.panel} data-testid="dev-mode-inspect-node-basic-info">
            <div className={classes.title}>
                <CopyableRow className={classes.nodeName} copyValue={`${selectionInfo?.nodeName}`} noPaddingLeft>
                    {selectionInfo?.nodeName}
                </CopyableRow>
            </div>
            <div className={classes['name-icon-container']}>
                {selectionInfo?.nodeIcon && selectionInfo?.nodeId && (
                    <div style={{ marginRight: '8px' }}>
                        <DevModeInspectNodeBasicInfoIcon
                            nodeId={selectionInfo.nodeId}
                            isPurple={selectionInfo.isPurple}
                            nodeIcon={selectionInfo.nodeIcon as NodeIconData}
                        />
                    </div>
                )}
                <div className={classNames(classes.name, { [classes.purpleName]: selectionInfo?.isPurple ?? false })}>
                    {selectionInfo?.typeName}
                </div>
            </div>
            {editInfo && (
                <div className={classes['edit-info-container']} data-testid={'dev-mode-inspect-node-edit-info'}>
                    <IconClock className={classes.icon} />
                    <span className={classes['edit-info']}>{editInfo}</span>
                </div>
            )}
        </div>
    )
}

interface DevModeInspectNodeBasicInfoIconProps {
    nodeId: string
    nodeIcon: Wukong.DocumentProto.NodeIconData
    isPurple: boolean
}

export function DevModeInspectNodeBasicInfoIcon(props: DevModeInspectNodeBasicInfoIconProps) {
    const { nodeId, nodeIcon, isPurple } = props

    const iconSvg = useMemo(() => {
        return getSVGIconByNodeIconType(nodeIcon)
    }, [nodeIcon])

    const command = useCommand()

    const handleDoubleClick = useCallback(() => {
        command.DEPRECATED_invokeBridge(ZoomToFitNodesAtMaxScaleByIdsCommand, {
            nodeIds: [nodeId],
        })
    }, [command, nodeId])

    return useMemo(
        () => (
            <div
                className={classNames([
                    classes.iconItem,
                    {
                        [classes.shallow]: !isPurple,
                        [classes.purpleShallow]: isPurple,
                    },
                ])}
                onDoubleClick={handleDoubleClick}
                data-testid={'dev-mode-inspect-selection-info-icon'}
            >
                {iconSvg}
            </div>
        ),
        [handleDoubleClick, iconSvg, isPurple]
    )
}

function getDispalyEditInfo(editInfo?: Wukong.DocumentProto.IEditInfo) {
    if (
        !editInfo ||
        editInfo.createdAt == 0 ||
        editInfo.lastEditedAt == 0 ||
        editInfo.createdAt! > editInfo.lastEditedAt!
    ) {
        return ''
    }

    if (editInfo.lastEditedAt! - editInfo.createdAt! < 1800000) {
        return translation('Created') + ' ' + timeDesc(editInfo.createdAt!)
    } else {
        return translation('Edited') + ' ' + timeDesc(editInfo.lastEditedAt!)
    }
}
