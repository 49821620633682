import { useCallback, useLayoutEffect, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

interface ElementResizeProps {
    element?: HTMLElement | null // 监听的元素 长列表
    height?: number // 固定element高度
    minHeight?: number // element最小高度
    maxHeight?: number // element最大高度
    otherHeight?: number // 容器内其他固定高度元素的高度
    maxContainerHeight?: number // 最大容器高度
    otherElements?: HTMLElement[] // 其他元素
}

/**
 * 根据窗口大小调整容器高度，返回一个resizeHeight
 * 当传入height时，容器为固定高度
 * 当传入element时，通过监听element高度变化，动态设置容器高度
 * winHeight = containerHeight + minPadding
 * containerHeight = elementHeight + otherHeight + otherElementsHeight
 */
export const useElementResize = (props: ElementResizeProps) => {
    const {
        height,
        minHeight = 150,
        otherHeight = 0,
        maxHeight,
        element,
        maxContainerHeight = 0,
        otherElements,
    } = props

    const [resizeHeight, setResizeHeight] = useState<number | undefined>(height)

    const resizeCallback = useCallback(() => {
        const winHeight = window.innerHeight
        const minPadding = winHeight * 0.3
        const otherElementsHeight = (otherElements || []).reduce((acc, cur) => acc + cur.offsetHeight, 0)
        const restHeight = winHeight - minPadding - otherHeight - otherElementsHeight

        if (height) {
            height > restHeight ? setResizeHeight(Math.max(restHeight, minHeight)) : setResizeHeight(height)
            return
        }

        if (element) {
            const eleHeight = element.offsetHeight
            const _minHeight = minHeight
            const _maxHeight = maxHeight || maxContainerHeight - otherHeight - otherElementsHeight

            if (restHeight <= _minHeight && eleHeight > _minHeight) {
                setResizeHeight(_minHeight)
            } else if (restHeight >= _maxHeight && eleHeight > _maxHeight) {
                setResizeHeight(_maxHeight)
            } else if (restHeight > _minHeight && restHeight < _maxHeight && eleHeight > restHeight) {
                setResizeHeight(restHeight)
            } else {
                setResizeHeight(eleHeight)
            }
        }
    }, [height, element, otherElements, minHeight, maxHeight, otherHeight, maxContainerHeight])

    useLayoutEffect(() => {
        if (!height && !element) {
            return
        }
        resizeCallback()
        window.addEventListener('resize', resizeCallback, false)
        return () => window.removeEventListener('resize', resizeCallback)
    }, [resizeCallback, element, height])

    useLayoutEffect(() => {
        if (!element) {
            !height && setResizeHeight(undefined)
            return
        }
        const observer = new ResizeObserver(resizeCallback)
        observer.observe(element)
        otherElements?.map((ele) => observer.observe(ele))
        return () => {
            observer.unobserve(element)
            otherElements?.map((ele) => observer.unobserve(ele))
        }
    }, [element, otherElements, height, resizeCallback])

    return { resizeHeight }
}
