import { Wukong } from '@wukong/bridge-proto'
import { useMemo } from 'react'
import { InputV2 } from '../../../../../../ui-lib/src'
import { BlurType } from '../../../../main/service/active-element-blur-service'
import { DeepRequired } from '../../../../view-state-bridge'
import { transformFontSizeForUi, transformLineHeightForUi } from '../styles/style-text/style-text-utils'
import { getNamePrefix, getStyleShortName } from './style-util'
import style from './style.module.less'

interface PropsType {
    textStyleNode?: DeepRequired<Wukong.DocumentProto.ILocalTextStyleNode>
    isEdit: boolean
    fullName: string
    isReadonly?: boolean
    startEditName: () => void
    endEditName: () => void
    rename: (name: string) => void
}
export function StyleName(props: PropsType) {
    const { textStyleNode, fullName, isEdit, isReadonly, startEditName, endEditName, rename } = props

    const name = useMemo(() => getStyleShortName(fullName), [fullName])

    const startEdit = () => !isReadonly && !isEdit && startEditName()

    const endEdit = (v: string) => {
        endEditName()
        if (v) {
            const prefix = getNamePrefix(fullName)
            rename(prefix ? `${prefix}/${v}` : v)
        }
    }

    return (
        <div className={style.row_content} onDoubleClick={startEdit}>
            {isEdit ? (
                <InputV2
                    data-blur-after-capture-mouse-down={BlurType.Immediately}
                    autoFocus
                    size="small"
                    defaultValue={name}
                    onBlur={(e) => endEdit(e.target.value)}
                    rootClassName={style.nameInput}
                ></InputV2>
            ) : textStyleNode ? (
                <span className={style.textNodeNameWrap}>
                    <span className={style.name} data-testid={'text-style-name'}>
                        {name}
                    </span>
                    <span className={style.shallow}>
                        {transformFontSizeForUi(textStyleNode.fontSize)}/
                        {transformLineHeightForUi(textStyleNode.lineHeight as any)}
                    </span>
                </span>
            ) : (
                <span className={style.name}>{name}</span>
            )}
        </div>
    )
}
