import { SVGAttributes } from 'react'

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconArrowDown12 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.64648 4.85359L3.35359 4.14648L6.00005 6.79293L8.6465 4.14649L9.3536 4.85359L6.00005 8.20714L2.64648 4.85359Z"
            fill="currentColor"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconDelete12 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
        <path
            d="M6.70717 5.99991L9.88933 2.8177L9.18221 2.1106L6.00006 5.29281L2.81803 2.11083L2.11093 2.81795L5.29296 5.99992L2.11117 9.18176L2.81828 9.88886L6.00007 6.70702L9.18198 9.88887L9.88908 9.18176L6.70717 5.99991Z"
            fillRule="nonzero"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconClose12 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.29287 6.00004L0.646423 10.6465L1.35353 11.3536L5.99998 6.70715L10.6464 11.3536L11.3535 10.6465L6.70708 6.00004L11.3535 1.35359L10.6464 0.646484L5.99998 5.29293L1.35353 0.646484L0.646423 1.35359L5.29287 6.00004Z"
            fill="currentColor"
        />
    </svg>
)
