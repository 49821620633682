import { translation } from './canvas-search-result-header.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { MonoIconCommonArrowDown16, MonoIconCommonArrowUp16, Select, WKIconButton } from '../../../../../ui-lib/src'
import styles from './canvas-search-result-header.module.less'
import { CanvasSearchParamModel, CanvasSearchResultModel } from './use-canvas-search-model'

interface ModelProps {
    canvasSearchParamModel: CanvasSearchParamModel
    canvasSearchResultModel: CanvasSearchResultModel
}

export function CanvasSearchResultHeader({ canvasSearchParamModel, canvasSearchResultModel }: ModelProps) {
    if (!canvasSearchResultModel.modelState) {
        return null
    }

    const totalCount = Number(canvasSearchResultModel.modelState.totalCount)
    const isCurrentPageScope =
        canvasSearchParamModel.modelState.scope ==
        Wukong.DocumentProto.CanvasSearchScope.CANVAS_SEARCH_SCOPE_CURRENT_PAGE

    return (
        <div className={styles.resultHeader}>
            <div className={styles.resultSummary}>
                {`${totalCount >= 9999 ? '9999+' : totalCount} ${translation('Results', {
                    result: totalCount > 1 ? 'results' : 'result',
                })} · `}
                <Select.MinimalSingleLevel
                    value={canvasSearchParamModel.modelState.scope}
                    label={isCurrentPageScope ? translation('ThisPage') : translation('AllPages')}
                    onChange={canvasSearchParamModel.modelCommand.updateSearchScope}
                    minWidth={112}
                    dataTestIds={{ triggerFocus: 'canvas-search-scope-select' }}
                >
                    <Select.MinimalSingleLevel.Option
                        value={Wukong.DocumentProto.CanvasSearchScope.CANVAS_SEARCH_SCOPE_CURRENT_PAGE}
                        backwardIcon={''}
                        dataTestId="canvas-search-scope-current-page"
                    >
                        {translation('ThisPage')}
                    </Select.MinimalSingleLevel.Option>
                    <Select.MinimalSingleLevel.Option
                        value={Wukong.DocumentProto.CanvasSearchScope.CANVAS_SEARCH_SCOPE_DOC}
                        backwardIcon={''}
                        dataTestId="canvas-search-scope-doc"
                    >
                        {translation('AllPages')}
                    </Select.MinimalSingleLevel.Option>
                </Select.MinimalSingleLevel>
            </div>
            <div className={styles.resultNav}>
                <WKIconButton
                    icon={<MonoIconCommonArrowUp16 />}
                    size="medium"
                    type="secondary"
                    className={styles.iconButton}
                    disabled={totalCount < 2}
                    onClick={canvasSearchResultModel.modelCommand.prevLayerItem}
                />
                <WKIconButton
                    icon={<MonoIconCommonArrowDown16 />}
                    size="medium"
                    type="secondary"
                    className={styles.iconButton}
                    disabled={totalCount < 2}
                    onClick={canvasSearchResultModel.modelCommand.nextLayerItem}
                />
            </div>
        </div>
    )
}
