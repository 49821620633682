import { POST } from '@tutor/network-core'
import { CollectInfoRequestBodyVO } from '../interface/collect-info'
import { BaseCommonRequest } from './base-request'

// 导出组织下的活动日志
@POST
export class CollectInfoRequest extends BaseCommonRequest {
    constructor(private readonly params: CollectInfoRequestBodyVO) {
        super()
    }
    public override additionalRequestHeaders(): { [key: string]: string } | undefined {
        return {
            'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'Time-Zone-Offset': new Date().getTimezoneOffset().toString(),
        }
    }
    public override requestUrl() {
        return 'collect-info'
    }
    public override requestBody() {
        return this.params
    }
}
