import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    ResultsFor: '检索',
    LayerPrefix: '图层「',
    LayerSuffix: '」',
    ImagePrefix: '图片「',
    ImageSuffix: '」',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    ResultsFor: 'Results for',
    LayerPrefix: 'the Layer "',
    LayerSuffix: '"',
    ImagePrefix: 'the Image "',
    ImageSuffix: '"',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
