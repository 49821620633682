import { IDBPTransaction } from 'idb'
import { IndexedDBName, IndexedDBName2DBSchema } from '../config'
import { enhanceObjectStore } from './store'
import { handleIndexedDBError } from '../utils'

/**
 * 增强版本的 IDBPDatabase 实例
 * proxy 了一些写入方法以实现超限重试和 metric 的能力
 *  - objectStore
 * @param db
 * @returns
 */
export const enhanceTransaction = <T extends IndexedDBName>(
    transaction: IDBPTransaction<IndexedDBName2DBSchema[T]>
): IDBPTransaction<IndexedDBName2DBSchema[T]> => {
    const handleErrorFn = async () => {
        const message = transaction.error?.message

        await handleIndexedDBError(`${transaction.db.name} transaction aborted! error: ${message}`)
    }

    // 为事务加上 abort 回调
    transaction.onabort = handleErrorFn
    // 为事务加上 error 回调
    transaction.onerror = handleErrorFn

    return new Proxy(transaction, {
        get: (
            target: IDBPTransaction<IndexedDBName2DBSchema[T]>,
            key: keyof IDBPTransaction<IndexedDBName2DBSchema[T]>
        ) => {
            const method = target[key]

            if (typeof method === 'function') {
                return (...args: any) => {
                    // @ts-expect-error
                    const methodWithArgs = method.bind(target, ...args)

                    try {
                        switch (key) {
                            case 'objectStore': {
                                return enhanceObjectStore(methodWithArgs())
                            }
                            default: {
                                return methodWithArgs()
                            }
                        }
                    } catch (error) {
                        handleIndexedDBError(error as string)
                    }
                }
            } else {
                return method
            }
        },
    })
}
