import { Wukong } from '@wukong/bridge-proto'
import { createSelectors, createStore } from '../../../../util/src'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { EmBridge } from '../../kernel/bridge/em-bridge'
import { WsPropertyChangeMessage } from '../../kernel/notify/notify-message'
import { NotifyService } from '../../kernel/notify/notify-service'
import { CheckDocumentCopyAccess } from '../../kernel/request/accesses'
import { RequestResponseErrorHandler } from '../../kernel/request/error-handler'

export class CopyCheckService {
    private zustandStore = createStore<{
        copyHasBeenProhibited: boolean
    }>(() => ({
        copyHasBeenProhibited: false,
    }))
    useZustandStore = createSelectors(this.zustandStore)

    constructor(
        private signal: TraceableAbortSignal,
        protected readonly bridge: EmBridge,
        private notifyService: NotifyService,
        private readonly docId: string
    ) {
        new CheckDocumentCopyAccess(this.docId)
            .start()
            .then(() => {
                this.zustandStore.setState({
                    copyHasBeenProhibited: false,
                })
            })
            .catch((e) => {
                const msg = RequestResponseErrorHandler(e)
                if (msg.status === 403) {
                    this.zustandStore.setState({
                        copyHasBeenProhibited: true,
                    })
                }
            })

        // 属性变更
        this.notifyService.onBusinessMessageChangeWithSignal(this.signal, (proto) => {
            if (proto.businessCode !== WsPropertyChangeMessage.code) {
                return
            }

            const body = WsPropertyChangeMessage.bodyType.decode(proto.payload)
            if (body.businessEntity?.entityType !== Wukong.NotifyProto.EntityType.DOC) {
                return
            }

            if (body.businessEntity?.entityId !== this.docId) {
                return
            }

            // 等待服务端
            if (body.changedProperties.copyable !== undefined) {
                new CheckDocumentCopyAccess(this.docId)
                    .start()
                    .then(() => {
                        this.zustandStore.setState({
                            copyHasBeenProhibited: false,
                        })
                    })
                    .catch((e) => {
                        const msg = RequestResponseErrorHandler(e)
                        if (msg.status === 403) {
                            this.zustandStore.setState({
                                copyHasBeenProhibited: true,
                            })
                        }
                    })
            }
        })
    }
}
