export const EvenOddDiagonalPattern = () => {
    const img = new Image()
    img.src =
        `data:image/svg+xml;base64,` +
        btoa(`
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="368" height="368" viewBox="0 0 368 368">
      <defs>
          <mask id="mask0533402011" style="mask-type:alpha">
              <path transform="matrix(1 0 0 1 0 0)" d="M0 0L368 0L368 368L0 368L0 0Z" fill-rule="nonzero" fill="rgb(196, 196, 196)"/>
              <path transform="matrix(1 0 0 1 0 0)" d="M-0 -0L368 0L368 368L-0 368L0 -0ZM1 1L1 367L367 367L367 1L1 1Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          </mask>
      </defs>
      <g mask="url('#mask0533402011')">
          <path transform="matrix(1 0 0 1 457.822 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -38.3218 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 209.75 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -286.394 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 333.786 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -162.358 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 85.7142 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -410.43 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 395.804 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -100.34 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 147.732 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -348.412 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 271.768 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -224.376 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 23.6962 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -472.448 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 426.813 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -69.3308 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 178.741 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -317.403 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 302.777 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -193.367 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 54.7052 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -441.439 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 364.795 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -131.349 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 116.723 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -379.421 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 240.759 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -255.385 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -7.31287 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -503.457 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 442.318 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -53.8263 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 194.246 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -301.898 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 318.282 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -177.862 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 70.2097 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -425.934 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 380.3 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -115.844 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 132.228 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -363.916 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 256.264 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -239.88 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 8.19165 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -487.952 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 411.309 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -84.8353 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 163.237 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -332.907 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 287.273 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -208.871 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 39.2007 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -456.943 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 349.291 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -146.853 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 101.219 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -394.925 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 225.255 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -270.889 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -22.8173 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -518.961 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
      </g>
  </svg>
  `)
    return img
}

export const NonZeroDiagonalPattern = () => {
    const img = new Image()
    img.src =
        `data:image/svg+xml;base64,` +
        btoa(`
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="368" height="368" viewBox="0 0 368 368">
      <defs>
          <mask id="mask5521808505" style="mask-type:alpha">
              <path transform="matrix(1 0 0 1 0 0)" d="M0 0L368 0L368 368L0 368L0 0Z" fill-rule="nonzero" fill="rgb(196, 196, 196)"/>
              <path transform="matrix(1 0 0 1 0 0)" d="M-0 -0L368 0L368 368L-0 368L0 -0ZM1 1L1 367L367 367L367 1L1 1Z" fill-rule="nonzero" fill="rgb(255, 189, 48)" fill-opacity="0.30000001"/>
          </mask>
      </defs>
      <g mask="url('#mask5521808505')">
          <path transform="matrix(1 0 0 1 457.822 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -38.3218 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 209.75 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -286.394 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 333.786 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -162.358 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 85.7142 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -410.43 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 395.804 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -100.34 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 147.732 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -348.412 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 271.768 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -224.376 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 23.6962 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -472.448 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 426.813 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -69.3308 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 178.741 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -317.403 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 302.777 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -193.367 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 54.7051 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -441.439 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 364.795 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -131.349 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 116.723 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -379.421 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 240.759 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -255.385 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -7.31284 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -503.457 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 442.318 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -53.8263 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 194.246 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -301.898 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 318.282 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -177.862 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 70.2097 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -425.934 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 380.3 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -115.844 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 132.228 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -363.916 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 256.264 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -239.88 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 8.19165 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -487.952 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 411.309 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -84.8353 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 163.237 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -332.907 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 287.273 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -208.871 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 39.2007 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -456.943 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 349.291 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -146.853 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 101.219 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -394.925 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 225.255 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -270.889 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -22.8173 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
          <path transform="matrix(1 0 0 1 -518.961 -10.1658)" d="M2.12062 -2.12202L389.691 385.193L385.45 389.437L-2.12062 2.12202L2.12062 -2.12202Z" fill-rule="nonzero" fill="rgb(103, 173, 255)" fill-opacity="0.30000001"/>
      </g>
  </svg>
  `)
    return img
}
