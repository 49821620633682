import { Wukong } from '@wukong/bridge-proto'
import IDevModeInspectExportUserConfig = Wukong.DocumentProto.IDevModeInspectExportUserConfig
import PresetCodeType = Wukong.DocumentProto.PresetCodeType
import FileType = Wukong.DocumentProto.FileType

export const defaultDevModeInspectExportUserConfig: IDevModeInspectExportUserConfig = {
    cssConfig: {
        selectedPresetCodeType: PresetCodeType.PRESET_CODE_TYPE_WEB,
        webSelectedFileType: FileType.FILE_TYPE_PNG,
        iosSelectedFileType: FileType.FILE_TYPE_PNG,
        androidSelectedFileType: FileType.FILE_TYPE_PNG,
    },
    iosConfig: {
        selectedPresetCodeType: PresetCodeType.PRESET_CODE_TYPE_I_O_S,
        webSelectedFileType: FileType.FILE_TYPE_PNG,
        iosSelectedFileType: FileType.FILE_TYPE_PNG,
        androidSelectedFileType: FileType.FILE_TYPE_PNG,
    },
    androidConfig: {
        selectedPresetCodeType: PresetCodeType.PRESET_CODE_TYPE_ANDROID,
        webSelectedFileType: FileType.FILE_TYPE_PNG,
        iosSelectedFileType: FileType.FILE_TYPE_PNG,
        androidSelectedFileType: FileType.FILE_TYPE_PNG,
    },
}

export const defaultDevModeInspectCodeConfig: Wukong.DocumentProto.IDevModeInspectCodeConfig = {
    css: {
        unit: Wukong.DocumentProto.CssUnit.CSS_UNITPX,
        ratio: Wukong.DocumentProto.CodeRatio.CODE_RATIO_ONE,
        rem: 16,
        remEnableForText: false,
    },
    ios: {
        unit: Wukong.DocumentProto.IOSUnit.I_O_S_UNITPX,
        ptRatio: Wukong.DocumentProto.CodeRatio.CODE_RATIO_ONE,
        pxRatio: Wukong.DocumentProto.CodeRatio.CODE_RATIO_ONE,
    },
    android: {
        unit: Wukong.DocumentProto.AndroidUnit.ANDROID_UNITPX,
        pxRatio: Wukong.DocumentProto.CodeRatio.CODE_RATIO_ONE,
        dpRatio: Wukong.DocumentProto.CodeRatio.CODE_RATIO_ONE,
    },
}
