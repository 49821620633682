import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    MoveTool: '移动',
    FrameTool: '容器',
    PenTool: '钢笔',
    TextTool: '文本',
    RectangleTool: '矩形',
    EllipseTool: '椭圆',
    LineTool: '直线',
    ArrowTool: '箭头',
    'PlaceImage...': '图片',
    AddComment: '评论',
    PickColor: '吸管工具',
    Scale: '等比缩放',
    HandTool: '抓手',
    SliceTool: '切片',
    Show: '显示',
    HideUI: '隐藏界面',
    OpenLayersPanel: '打开图层面板',
    Libraries: '打开资产面板',
    OpenDevMode: '进入研发模式',
    OpenDesignPanel: '打开设计面板',
    OpenPrototypePanel: '打开原型面板',
    OpenInspectPanel: '打开标注面板',
    MultiplayerCursors: '多人协作光标',
    Rulers: '标尺',
    LayoutGrids: '布局网格',
    PixelGrid: '像素网格',
    Pan: '拖动画布',
    Drag: '拖动',
    ZoomInOut: '放大/缩小',
    ZoomTo: '缩放至',
    ZoomToFit: '缩放至显示全部',
    ZoomToSelection: '缩放至显示选中',
    Bold: '加粗',
    Italic: '斜体',
    Underline: '下划线',
    Strikethrough: '删除线',
    CreateLink: '创建链接',
    TextAlignLeft: '左对齐',
    TextAlignCenter: '水平居中对齐',
    TextAlignRight: '右对齐',
    TextAlignJustified: '两端对齐',
    AdjustFontSize: '调整字号',
    AdjustFontWeight: '调整字重',
    AdjustLetterSpacing: '调整字间距',
    AdjustLineHeight: '调整行高',
    PaintBucket: '油漆桶',
    BendTool: '曲率工具',
    JoinSelection: '接合路径',
    SmoothJoinSelection: '平滑接合路径',
    DeleteAndHeal: '删除并修复路径',
    sldEML: '拖动锚点的控制手柄时',
    MirrorAngleAnd: '角度和长度对称',
    NoMirroring: '不对称',
    TeamLibrary: '打开团队组件库',
    CreateComponent: '创建组件',
    DetachInstance: '分离实例',
    WhileInsertingA: '从资产面板拖拽至画布已有实例时',
    SwapComponentInstance: '替换实例',
    SelectAll: '全选',
    SelectInverse: '反选',
    SelectNone: '取消选中',
    SelectChildren: '选中子图层',
    SelectParent: '选中父图层',
    SelectNextSibling: '选中下一图层',
    SelectPreviousSibling: '选中上一图层',
    DeepSelect: '穿透选择',
    'WhileClicking...': '点击',
    DeepSelectWithin: '穿透框选',
    FlattenSelection: '拼合',
    OutlineStroke: '轮廓化',
    UseAsMask: '设为蒙版',
    EditShape: '进入矢量编辑',
    Image: '图片编辑',
    FlipHorizontal: '水平翻转',
    FlipVertical: '垂直翻转',
    Resize: '调整大小',
    ArrowKeys: '方向键',
    'WhileResizing...': '拖动边界框时',
    ResizeFromCenter: '调整大小时保持中心固定',
    ResizeProportionally: '调整大小时保持宽高比例',
    'Crop(images)': '调整大小时裁切图片',
    'IgnoreConstraints(frames)': '忽略约束',
    GroupSelection: '创建编组',
    UngroupSelection: '取消编组',
    FrameSelection: '创建容器',
    RemoveFill: '移除填充',
    RemoveStroke: '移除描边',
    SwapFillAnd: '交换填充和描边',
    HideSelection: '隐藏图层',
    Lock: '锁定',
    UnlockSelection: '解锁图层',
    SetOpacityTo: '不透明度设为',
    Copy: '复制',
    Cut: '剪切',
    Paste: '粘贴',
    PasteToReplace: '粘贴替换',
    PasteOverSelection: '粘贴至当前选中',
    Duplicate: '创建副本',
    DragToDuplicate: '拖动创建副本',
    CopyAs: '复制为',
    CopyProperties: '复制属性',
    PasteProperties: '粘贴属性',
    RenameSelection: '重命名',
    BringForward: '上移一层',
    SendBackward: '下移一层',
    BringToFront: '移到顶层',
    SendToBack: '移到底层',
    AlignLeft: '左',
    AlignTop: '顶',
    Bottom: '底对齐',
    AlignCenters: '水平/垂直居中对齐',
    DistributeSpacing: '水平/垂直等间距分布',
    TidyUp: '整理',
    AddAutoLayout: '添加自动布局',
    RemoveAutoLayout: '取消自动布局',
    AlignLeftAndRight: '左/右对齐',
} as const

export const enTranslation = {
    MoveTool: 'Move tool',
    FrameTool: 'Frame tool',
    PenTool: 'Pen tool',
    TextTool: 'Text tool',
    RectangleTool: 'Rectangle tool',
    EllipseTool: 'Ellipse tool',
    LineTool: 'Line tool',
    ArrowTool: 'Arrow tool',
    'PlaceImage...': 'Place image...',
    AddComment: 'Add comment',
    PickColor: 'Pick color',
    Scale: 'Scale',
    HandTool: 'Hand tool',
    SliceTool: 'Slice tool',
    Show: 'Show',
    HideUI: 'Hide UI',
    OpenLayersPanel: 'Open layers panel',
    Libraries: 'Show assets',
    OpenDevMode: 'Open Develop Mode',
    OpenDesignPanel: 'Open design panel',
    OpenPrototypePanel: 'Open prototype panel',
    OpenInspectPanel: 'Open inspect panel',
    MultiplayerCursors: 'Multiplayer cursors',
    Rulers: 'Rulers',
    LayoutGrids: 'Layout grids',
    PixelGrid: 'Pixel grid',
    Pan: 'Pan',
    Drag: 'drag',
    ZoomInOut: 'Zoom in/out',
    ZoomTo: 'Zoom to',
    ZoomToFit: 'Zoom to fit',
    ZoomToSelection: 'Zoom to selection',
    Bold: 'Bold',
    Italic: 'Italic',
    Underline: 'Underline',
    Strikethrough: 'Strikethrough',
    CreateLink: 'Create link',
    TextAlignLeft: 'Text align left',
    TextAlignCenter: 'Text align center',
    TextAlignRight: 'Text align right',
    TextAlignJustified: 'Text align justified',
    AdjustFontSize: 'Adjust font size',
    AdjustFontWeight: 'Adjust font weight',
    AdjustLetterSpacing: 'Adjust letter spacing',
    AdjustLineHeight: 'Adjust line height',
    PaintBucket: 'Paint bucket',
    BendTool: 'Bend tool',
    JoinSelection: 'Join selection',
    SmoothJoinSelection: 'Smooth join selection',
    DeleteAndHeal: 'Delete and heal selection',
    sldEML: 'While draging the Bézier handle of the anchor point...',
    MirrorAngleAnd: 'Mirror angle and length',
    NoMirroring: 'No mirroring',
    TeamLibrary: 'Team library',
    CreateComponent: 'Create component',
    DetachInstance: 'Detach instance',
    WhileInsertingA: 'While inserting a component...',
    SwapComponentInstance: 'Swap component instance',
    SelectAll: 'Select all',
    SelectInverse: 'Select inverse',
    SelectNone: 'Select none',
    SelectChildren: ' Select children',
    SelectParent: 'Select parent',
    SelectNextSibling: 'Select next sibling',
    SelectPreviousSibling: 'Select previous sibling',
    DeepSelect: 'Deep select',
    'WhileClicking...': 'click',
    DeepSelectWithin: 'Deep select within rectangle',
    FlattenSelection: 'Flatten selection',
    OutlineStroke: 'Outline stroke',
    UseAsMask: 'Use as mask',
    EditShape: 'Edit shape',
    Image: 'image',
    FlipHorizontal: 'Flip horizontal',
    FlipVertical: 'Flip vertical',
    Resize: 'Resize',
    ArrowKeys: 'Arrow keys',
    'WhileResizing...': 'While resizing...',
    ResizeFromCenter: 'Resize from center',
    ResizeProportionally: 'Resize proportionally',
    'Crop(images)': 'Crop (images)',
    'IgnoreConstraints(frames)': 'Ignore constraints (frames)',
    GroupSelection: 'Group selection',
    UngroupSelection: 'Ungroup selection',
    FrameSelection: 'Frame selection',
    RemoveFill: 'Remove fill',
    RemoveStroke: 'Remove stroke',
    SwapFillAnd: 'Swap fill and stroke',
    HideSelection: 'Hide selection',
    Lock: 'Lock',
    UnlockSelection: 'Unlock selection',
    SetOpacityTo: 'Set opacity to',
    Copy: 'Copy',
    Cut: 'Cut',
    Paste: 'Paste',
    PasteToReplace: 'Paste to replace',
    PasteOverSelection: 'Paste over selection',
    Duplicate: 'Duplicate',
    DragToDuplicate: 'Drag to duplicate',
    CopyAs: 'Copy as',
    CopyProperties: 'Copy properties',
    PasteProperties: 'Paste properties',
    RenameSelection: 'Rename selection',
    BringForward: 'Bring forward',
    SendBackward: 'Send backward',
    BringToFront: 'Bring to front',
    SendToBack: 'Send to back',
    AlignLeft: 'Align left',
    AlignTop: 'Align top',
    Bottom: 'bottom',
    AlignCenters: 'Align centers',
    DistributeSpacing: 'Distribute spacing',
    TidyUp: 'Tidy up',
    AddAutoLayout: 'Add auto layout',
    RemoveAutoLayout: 'Remove auto layout',
    AlignLeftAndRight: 'Align left/right',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
