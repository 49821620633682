import ReactGA from 'react-ga4'

import { useCallback, useEffect, useRef } from 'react'
import { isDocumentVisible } from '../../../../util/src'
import { useUserInfoContext } from '../../auth'
import { LocalStorageKey } from '../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../web-storage/local-storage/storage'
import { UserActiveRecord } from './type'

// 10s检测一次
const DETECT_INTERVAL = 10 * 1000
const FIVE_MINUTES = 5 * 60 * 1000
const TWENTY_MINUTES = 20 * 60 * 1000

/**
 * 用户活跃记录，5min / 20min 活跃会上报 GA
 */
export function useUserActiveRecord() {
    const { userInfo } = useUserInfoContext()
    const timerRef = useRef<NodeJS.Timeout>()
    const startTimestampRef = useRef<number>(Date.now())

    const clear = useCallback(() => {
        clearInterval(timerRef.current)
    }, [])

    const getLocalRecord = useCallback(() => {
        const recordMap = enhancedLocalStorage.getSerializedItem(LocalStorageKey.UserActiveRecord)
        return (
            recordMap?.[userInfo?.userId] ?? {
                timestamp: startTimestampRef.current,
                duration: 0,
                fiveRecord: false,
                twentyRecord: false,
            }
        )
    }, [userInfo])

    const updateLocalRecord = useCallback(
        (record: UserActiveRecord) => {
            const recordMap = enhancedLocalStorage.getSerializedItem(LocalStorageKey.UserActiveRecord) || {}
            if (userInfo?.userId) {
                recordMap[userInfo.userId] = record
                enhancedLocalStorage.setSerializedItem(LocalStorageKey.UserActiveRecord, recordMap)
            }
        },
        [userInfo]
    )

    // 检查记录是否达到上报条件，上报&更新记录
    // 如果已经上报过十分钟记录，则清除定时器，并返回true
    const check = useCallback(
        (needSend = true) => {
            const record = getLocalRecord()
            if (record.twentyRecord) {
                clear()
                return true
            }
            const now = Date.now()
            // 其他 tab 有可能会更新记录，所以取最大值
            const startTimestamp = Math.max(startTimestampRef.current, record.timestamp)
            const duration = record.duration + now - startTimestamp
            if (needSend && duration >= FIVE_MINUTES && !record.fiveRecord) {
                ReactGA.event('Active_5mins')
                record.fiveRecord = true
            }
            if (needSend && duration >= TWENTY_MINUTES && !record.twentyRecord) {
                ReactGA.event('Active_20mins')
                record.twentyRecord = true
            }
            record.timestamp = now
            record.duration = duration
            startTimestampRef.current = now
            updateLocalRecord(record)
        },
        [getLocalRecord, updateLocalRecord, clear]
    )

    const start = useCallback(() => {
        startTimestampRef.current = Date.now()
        if (!check()) {
            clear()
            timerRef.current = setInterval(() => {
                check()
            }, DETECT_INTERVAL)
        }
    }, [check, clear])

    const onVisibilitychange = useCallback(() => {
        const visible = isDocumentVisible()
        if (visible) {
            startTimestampRef.current = Date.now()
            start()
        } else {
            check(false)
            clear()
        }
    }, [start, clear, check])

    const onBeforeunload = useCallback(() => {
        // 页面关闭时，请求会失败，只做记录不上报打点
        check(false)
    }, [check])

    useEffect(() => {
        start()

        return () => {
            check(false)
            clear()
        }
    }, [start, clear, check])

    useEffect(() => {
        window.addEventListener('beforeunload', onBeforeunload)
        document.addEventListener('visibilitychange', onVisibilitychange)

        return () => {
            window.removeEventListener('beforeunload', onBeforeunload)
            document.removeEventListener('visibilitychange', onVisibilitychange)
        }
    }, [onVisibilitychange, onBeforeunload])
}
