import { useMemo } from 'react'
import { PayPlanType } from '../../../../kernel/interface/type'
import { GetTeamsFoldersRequest } from '../../../../kernel/request/team'
import { useLibraryComponentService, usePlanAndUserStateService } from '../../../../main/app-context'
import { PayUpgradePublishLibrary } from '../../../../share/payment/pay-upgrade-scene-modal'
import { isStarterPlan } from '../../../../utils/plan-status-checker'
import { useDocInfoContext } from '../../../context/top-area-context'
import { OpenLibraryPublishModalSource } from './library-modal-router-service'

const OtherTeamOrgId = '-1'

export function useLibraryModalRouter() {
    const { docData } = useDocInfoContext()
    const planAndUserStateService = usePlanAndUserStateService()
    const planInfo = planAndUserStateService.useZustandStore.use.planAndUserState() // 拿到用户 plan
    const planInAllTeams = planAndUserStateService.useZustandStore.use.planInAllTeams()

    const planInAllTeamsMap = useMemo(
        () => new Map((planInAllTeams || []).map((p) => [p.resourceId, p])),
        [planInAllTeams]
    )
    const { libraryModalRouterService } = useLibraryComponentService()

    const shouldUpgradeTeam = async () => {
        if (docData?.draft && docData.orgId === OtherTeamOrgId) {
            const teamsFoldersVO = await new GetTeamsFoldersRequest(OtherTeamOrgId).start()
            const payingTeams = teamsFoldersVO.filter(
                (o) => planInAllTeamsMap.get(o.teamWithAuthorityVO.id)?.planType === PayPlanType.professional
            )
            if (payingTeams.length) {
                return false
            } else {
                PayUpgradePublishLibrary({
                    teamId: planInfo?.resourceId,
                })
                return true
            }
        }

        if (planInfo && isStarterPlan(planInfo)) {
            PayUpgradePublishLibrary({
                teamId: planInfo?.resourceId,
            })
            return true
        }
        return false
    }

    return {
        async openLibraryPublishModalIfPaid(openSource: OpenLibraryPublishModalSource, isDraft: boolean) {
            if (await shouldUpgradeTeam()) {
                return
            }
            libraryModalRouterService.openLibraryPublishModal(openSource, isDraft)
        },
    }
}
