import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    msg: '操作异常，邀请失败',
    ReinviteSuccess: '邀请邮件已发送',
} as const

export const enTranslation = {
    msg: 'An error occurred while inviting others.',
    ReinviteSuccess: 'Invite email sent again',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
