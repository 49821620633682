export class OnceCallbackWithSignal<T extends (...rest: any[]) => void> {
    private signal = false // 是否需要调用
    private done = false // 是否调用完成
    private callback: T | null = null

    public reset = () => {
        this.signal = false
        this.done = false
    }

    public addSign = (b: boolean) => {
        this.signal = this.signal || b
    }

    public setCallback = (c: T) => {
        this.callback = c
    }

    public execCallback = (...rest: Parameters<T>) => {
        if (this.done) {
            return
        }
        this.done = true
        if (this.signal) {
            this.callback?.(...rest)
        }
    }
}
