import { Wukong } from '@wukong/bridge-proto'
import { useAppContext } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge/use-view-state'
import { ScrubbableInputNumber } from '../../atom/inputs/scrubbable-input-number'
import styles from './float-value-cell.module.less'
import { FloatVariablePreviewPure } from './float-variable-alias-preview'

function FloatVariablePreview({
    variable,
    modeId,
    toggleEditing,
}: {
    variable: Wukong.DocumentProto.ILocalVariable
    modeId: string
    toggleEditing: (type: Wukong.DocumentProto.LocalVariableEditorEditingType) => void
}) {
    const service = useAppContext().variableService.localVariableEditorService
    const onClickTag = (e: React.MouseEvent<HTMLDivElement>) => {
        // 点击 tag 打开选择器
        toggleEditing(
            Wukong.DocumentProto.LocalVariableEditorEditingType.LOCAL_VARIABLE_EDITOR_EDITING_TYPE_NUMBER_VALUE_ALIAS
        )
        e.stopPropagation() // 阻止外面的 row 响应 click 关闭 popup
    }
    const onDetach = () => service.detachAliasForVariable(variable, modeId)
    return <FloatVariablePreviewPure variable={variable} modeId={modeId} onClickTag={onClickTag} onDetach={onDetach} />
}

// 单元格中的数值变量组件
export function FloatValueCell({
    variable,
    modeId,
    toggleEditing,
}: {
    variable: Wukong.DocumentProto.ILocalVariable
    modeId: string
    toggleEditing: (type: Wukong.DocumentProto.LocalVariableEditorEditingType) => void
}) {
    const service = useAppContext().variableService.localVariableEditorService
    const editingState = useViewState('localVariableEditorEditingState')
    // 是否正在编辑这个单元格的变量值
    const isEditing =
        editingState?.type ===
            Wukong.DocumentProto.LocalVariableEditorEditingType.LOCAL_VARIABLE_EDITOR_EDITING_TYPE_NUMBER_VALUE_ALIAS &&
        editingState?.editingVariableId === variable.id &&
        editingState?.editingModeId === modeId
    // 绑定了 alias 时，为展示 tag 模式
    const isAlias =
        variable.dataValues[modeId].dataType === Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_ALIAS
    if (isAlias) {
        return <FloatVariablePreview variable={variable} modeId={modeId} toggleEditing={toggleEditing} />
    }
    // 没绑定变量时，为 input 模式
    return (
        <ScrubbableInputNumber
            className={styles['float-input-container']}
            inputClassName={styles['float-input']}
            inputContentClassName={styles['float-input-content']}
            toolClassName={styles['float-input-icon-tool']}
            value={variable.dataValues[modeId].resolvedValue.floatValue}
            focusWithoutBorder={true}
            scrubbingDisabled
            useVariable={{
                notAvailable: false, // 只在没绑定时展示，所以始终为 false
                variable: null, // 只在没绑定时展示，所以始终为 null
                open: isEditing, // 会在打开选择器后始终展示绑定 icon
                onUnbind: () => {}, // 只在没绑定时展示，这里无需实现
                iconBindUnbind: {
                    iconClassName: styles['float-input-icon'],
                    onBind: () =>
                        // 点击绑定按钮打开选择器
                        toggleEditing(
                            Wukong.DocumentProto.LocalVariableEditorEditingType
                                .LOCAL_VARIABLE_EDITOR_EDITING_TYPE_NUMBER_VALUE_ALIAS
                        ),
                },
            }}
            onChange={(value) => {
                if (typeof value === 'number') {
                    service.updateFloatVariableValue(variable.id, modeId, value)
                }
            }}
            onClick={(e) => {
                // 点击输入框内部也要选中这一行
                service.clickToSelectVariable(variable.id, e)
            }}
            testId="local-variable-editor-table-item-number-input"
        />
    )
}
