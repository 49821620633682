import { Wukong } from '@wukong/bridge-proto'
import { domLocation, generateRouterPrefix, safeDecodeUriComponent } from '../../../../util/src'

function pushState(url: URL) {
    history.pushState(null, '', url)
}

function replaceState(url: URL) {
    history.replaceState(null, '', url)
}

export function getURLWithoutParams() {
    return domLocation().href.split('?')[0]
}

function generateURLWithSearchParamsInternal(params: Wukong.DocumentProto.URLSearchParams): URL {
    const url = new URL(getURLWithoutParams())
    if (params.nodeId) {
        url.searchParams.set('nodeId', params.nodeId!)
    }
    if (params.commentId) {
        url.searchParams.set('commentId', params.commentId!)
    }
    if (params.mainComponent) {
        url.searchParams.set('mainComponent', '1')
    } else {
        url.searchParams.delete('mainComponent')
    }
    if (params.aiRecognize) {
        url.searchParams.set('ai-recognize', '1')
    }
    if (params.isAdvanceRender) {
        url.searchParams.set('isAdvanceRender', '1')
    }
    if (params.historyVersionId) {
        url.searchParams.set('historyVersionId', params.historyVersionId!)
    }
    if (params.editorType) {
        url.searchParams.set('type', params.editorType)
    }
    if (params.production) {
        url.searchParams.set('production', '1')
    }
    return url
}

function needToUpdateURL(url: URL) {
    return url.href !== domLocation().href
}

export function generateDocumentNodeUrl(
    arg: Wukong.DocumentProto.IArg_GenerateDocumentNodeUrl
): Wukong.DocumentProto.IBridgeProtoString {
    return { value: `https://${domLocation().host}/file/${arg.docId}?nodeId=${arg.nodeId}` }
}

export function parseSearchParamsValue(searchParams: URLSearchParams, key: string) {
    const rawValue = searchParams.get(key) // 如果有多个则只获得第一个
    return rawValue && safeDecodeUriComponent(rawValue)
}

export function parseSearchParamsHas(searchParams: URLSearchParams, key: string) {
    return searchParams.has(key)
}

export function getURLSearchParams(): Wukong.DocumentProto.IURLSearchParams {
    const searchParams = new URLSearchParams(domLocation().search)

    return {
        nodeId: parseSearchParamsValue(searchParams, 'nodeId'),
        commentId: parseSearchParamsValue(searchParams, 'commentId'),
        mainComponent: parseSearchParamsHas(searchParams, 'mainComponent'),
        aiRecognize: parseSearchParamsHas(searchParams, 'ai-recognize'),
        isAdvanceRender: parseSearchParamsHas(searchParams, 'isAdvanceRender'),
        historyVersionId: parseSearchParamsValue(searchParams, 'historyVersionId'),
        editorType: parseSearchParamsValue(searchParams, 'type'),
        production: parseSearchParamsHas(searchParams, 'production'),
    }
}

export function generateURLWithSearchParams(
    params: Wukong.DocumentProto.IURLSearchParams
): Wukong.DocumentProto.IBridgeProtoString {
    const newUrl = generateURLWithSearchParamsInternal(params as Wukong.DocumentProto.URLSearchParams)
    return {
        value: newUrl.href,
    }
}

export function setURLSearchParams(arg: Wukong.DocumentProto.IArg_SetURLSearchParams) {
    const newUrl = generateURLWithSearchParamsInternal(arg.params as Wukong.DocumentProto.URLSearchParams)
    if (!needToUpdateURL(newUrl)) {
        return
    }
    if (!arg.replace) {
        pushState(newUrl)
    } else {
        replaceState(newUrl)
    }
}

export function tryGetNodeIdInCurrentDocument(url: string): string | null {
    const currentDocPath = getURLWithoutParams()
    if (!url.startsWith(currentDocPath + '?')) {
        return null
    }

    try {
        return new URL(url).searchParams.get('nodeId')
    } catch (error) {
        return null
    }
}

export function generateURLWithNodeId(nodeId: string): string {
    return generateURLWithSearchParamsInternal({ nodeId } as Wukong.DocumentProto.URLSearchParams).href
}

export function generateMotiffDocumentNodeUrl(docId: string, nodeId: string): string {
    return `https://${domLocation().host}/file/${docId}?nodeId=${nodeId}`
}

function getUrlPath(value: string): string | null {
    try {
        const url = new URL(value)
        return url.origin + url.pathname
    } catch (error) {
        return null
    }
}

export function isMotiffDocumentUrl(url: string): boolean {
    const urlPath = getUrlPath(url)
    if (!urlPath) {
        return false
    }

    let routerPrefix = generateRouterPrefix()
    routerPrefix = domLocation().origin + '/' + routerPrefix

    const reg = new RegExp('^' + routerPrefix + 'file/' + '([a-z|A-Z|0-9]+)$')
    return reg.test(urlPath)
}

export const isValidUrl = (url: string) => {
    try {
        new URL(url)
    } catch (error) {
        return false
    }
    return true
}

export function getCreateDocSearchKeyValue() {
    return {
        key: 'message',
        value: 'create-doc',
    }
}
