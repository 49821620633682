type InternalGetNonFunctionKeys<T extends object, K extends keyof T = keyof T> = K extends any
    ? T[K] extends (...args: any[]) => any
        ? never
        : K
    : never

export type GetNonFunctionKeys<T extends object> = T extends any ? InternalGetNonFunctionKeys<T> : any

export type PreserveNonFunction<T extends object> = Pick<T, GetNonFunctionKeys<T>>

export type PartialAttributes<T> = {
    -readonly [P in keyof Required<T>]?: T[P] extends (...args: any[]) => any ? never : T[P]
}

export function keys<T extends object>(obj: T) {
    return Object.keys(obj) as (keyof T)[]
}
