import { useRef } from 'react'
import { ExplicitUndefined } from '../../../utils/explicit-undefined'
import { MultiLevel, MultiLevelProps, MultiLevelRef } from '../picks/multi-level/multi-level'
import { CustomNode } from '../picks/multi-level/type'
import { TriggerNormal, TriggerNormalRef } from '../triggers/trigger-normal'
import { SelectDefaultRenderItem } from './select-default-custom-render'
import { SelectMultiLevelProps, SelectTriggerNormalProps } from './type'
import { useCommentSelect } from './use-comment-select'

export type SelectNormalMultiLevelProps<T extends CustomNode> = SelectTriggerNormalProps & SelectMultiLevelProps<T>

export function SelectNormalMultiLevel<T extends CustomNode>(props: SelectNormalMultiLevelProps<T>) {
    const pickRef = useRef<MultiLevelRef>(null)
    const triggerRef = useRef<TriggerNormalRef>(null)
    const {
        pickRest,
        placement,
        triggerRect,
        worldRect,
        items,
        isMinWidthFromTrigger = true,
        minWidth,
        width,
        maxWidth,
        removeTopPadding,
        removeBottomPadding,
        onKeyboard,
        onChange,
        renderItemPrev,
        renderItem,
        renderItemNext,
        onClose,
        onOpen,
        ...otherProps
    } = props

    const commentSelectState = useCommentSelect({
        triggerRect,
        triggerRef,
        pickRef,
        onOpen,
        onClose,
    })

    return (
        <TriggerNormal
            ref={triggerRef}
            {...otherProps}
            isOpenPick={commentSelectState.isOpenPick}
            openAction={commentSelectState.openAction}
            closeAction={commentSelectState.closeAction}
        >
            <MultiLevel<T, ExplicitUndefined<MultiLevelProps<T>>>
                pickRest={pickRest}
                dataTestIds={props.dataTestIds}
                triggerRect={commentSelectState.triggerRect}
                closeByESC={commentSelectState.closeByESC}
                onClose={commentSelectState.onSelectClose}
                onOpen={commentSelectState.onSelectOpen}
                ref={pickRef}
                items={items}
                placement={placement}
                isMinWidthFromTrigger={isMinWidthFromTrigger}
                minWidth={minWidth}
                width={width}
                maxWidth={maxWidth}
                worldRect={worldRect}
                onKeyboard={onKeyboard}
                onChange={onChange}
                renderItemPrev={renderItemPrev}
                renderItem={renderItem ?? SelectDefaultRenderItem}
                renderItemNext={renderItemNext}
                removeTopPadding={removeTopPadding}
                removeBottomPadding={removeBottomPadding}
                isOpenState={undefined}
                openStateToBeFalse={undefined}
                openStateToBeTrue={undefined}
                getPortalElement={commentSelectState.getPortalElement}
                removeMask={undefined}
                autoAdjustTop={undefined}
            />
        </TriggerNormal>
    )
}
