import { MotiffApi } from '@motiffcom/plugin-api-types'
import { isEqual } from 'lodash-es'

type StyledTextSegmentField = keyof Omit<MotiffApi.StyledTextSegment, 'characters' | 'start' | 'end'>

type PickedStyledTextSegment<T extends StyledTextSegmentField[]> = Pick<
    MotiffApi.StyledTextSegment,
    T[number] | 'characters' | 'start' | 'end'
>

export function geSegmentsByFields<T extends StyledTextSegmentField[]>(
    fullSegs: MotiffApi.StyledTextSegment[],
    fields: T
): PickedStyledTextSegment<T>[] {
    function fieldComparator(a: PickedStyledTextSegment<T>, b: PickedStyledTextSegment<T>): boolean {
        return fields.every((field) => isEqual((a as any)[field], (b as any)[field]))
    }

    const result: PickedStyledTextSegment<T>[] = []
    let currentSegment: PickedStyledTextSegment<T> | null = null

    for (const segment of fullSegs) {
        if (currentSegment && fieldComparator(currentSegment, segment)) {
            currentSegment.end = segment.end
            currentSegment.characters += segment.characters
        } else {
            const newSegment: PickedStyledTextSegment<T> = {
                start: segment.start,
                end: segment.end,
                characters: segment.characters,
            } as PickedStyledTextSegment<T>

            fields.forEach((field) => {
                if (segment[field] !== undefined) {
                    ;(newSegment as any)[field] = segment[field]
                }
            })

            result.push(newSegment)
            currentSegment = newSegment
        }
    }

    return result
}
