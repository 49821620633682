import { useMemo } from 'react'
import { useUserConfigStateV2 } from '../../../../../main/user-config/user-config-hook'

export interface UserConfig {
    smallNudgeAmount: number
    bigNudgeAmount: number
}
export function useUserConfig(): UserConfig {
    const [_smallNudgeAmountV2] = useUserConfigStateV2('smallNudgeAmount')
    const [_bigNudgeAmountV2] = useUserConfigStateV2('bigNudgeAmount')

    const bigNudgeAmount = useMemo(() => {
        const num = Number(_bigNudgeAmountV2)
        return isNaN(num) || num === 0 ? 10 : num
    }, [_bigNudgeAmountV2])

    const smallNudgeAmount = useMemo(() => {
        const num = Number(_smallNudgeAmountV2)
        return isNaN(num) || num === 0 ? 1 : num
    }, [_smallNudgeAmountV2])

    return {
        smallNudgeAmount,
        bigNudgeAmount,
    }
}
