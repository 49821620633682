import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Object: '图层',
    BooleanGroups: '布尔运算',
    CollapseLayers: '折叠图层',
    MoreLayoutOption: '更多布局操作',
    SmartAddAutoLayout: '智能添加自动布局',
} as const

export const enTranslation = {
    Object: 'Object',
    BooleanGroups: 'Boolean groups',
    CollapseLayers: 'Collapse layers',
    MoreLayoutOption: 'More layout options',
    SmartAddAutoLayout: 'AI adds auto layout',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
