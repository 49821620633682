import { GetUsedMemoryCommand } from '@wukong/bridge-proto'
import constate from 'constate'
import { useState } from 'react'
import { useEffectOnce } from 'react-use'
import { isCypress } from '../../../../../../util/src'
import { memoryReportService } from '../../../../kernel/memory-report'
import { useBridge } from '../../../../main/app-context'
import { useCommand } from '../../../context/document-context'

const GET_MEMORY_USAGE_DURATION = 1 * 1000

// 由于 memoryUsage 的开销巨大，web 每隔 1s 主动获取一次
const useUsedMemoryInternal = () => {
    const command = useCommand()
    const bridge = useBridge()

    const [usedMemory, setUsedMemory] = useState<number>(
        () => command.DEPRECATED_invokeBridge(GetUsedMemoryCommand).value ?? 0
    )

    useEffectOnce(() => {
        const timer = setInterval(() => {
            if (bridge.destroyed) {
                clearInterval(timer)
                memoryReportService.clear()
                return
            }
            if (!isCypress()) {
                const currentUsedMemory = command.DEPRECATED_invokeBridge(GetUsedMemoryCommand)
                memoryReportService.recordCurrentMemoryInfo(currentUsedMemory)
                setUsedMemory(currentUsedMemory?.value ?? 0)
            }
        }, GET_MEMORY_USAGE_DURATION)

        return () => {
            clearInterval(timer)
            memoryReportService.clear()
        }
    })

    return {
        usedMemory,
        refresh: () => {
            setUsedMemory(command.DEPRECATED_invokeBridge(GetUsedMemoryCommand).value ?? 0)
        },
    }
}

export const [UsedMemoryProvider, useUsedMemory] = constate(useUsedMemoryInternal, (ctx) => ctx)
