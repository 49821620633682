import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    GettingComponentData: '正在获取组件',
    FailedToGet: '获取组件失败',
    GettingStyleData: '正在获取样式',
    FailedToGet_synonyms1: '获取样式失败',
    GettingVariableData: '正在获取变量',
    FailedToGet_synonyms2: '获取变量失败',
    GettingVariableSetData: '正在获取变量集',
    FailedToGet_synonyms3: '获取变量集失败',
} as const

export const enTranslation = {
    GettingComponentData: 'Accessing component data',
    FailedToGet: 'Unable to acquire component data',
    GettingStyleData: 'Acquiring style data...',
    FailedToGet_synonyms1: 'An error occurred while accessing style data',
    GettingVariableData: 'Acquiring variable data...',
    FailedToGet_synonyms2: 'An error occurred while accessing variable data',
    GettingVariableSetData: 'Acquiring variable set data...',
    FailedToGet_synonyms3: 'An error occurred while accessing variable set data',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
