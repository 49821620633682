import { Wukong } from '@wukong/bridge-proto'
// [how_to_add_NodeProp][7 of n]
// 如若希望在 MotiffScope 中输入指定节点类型快速查询的话，请添加至指定节点类型之后

export const getCommonPropsByNodeType = (nodeType: Wukong.DocumentProto.NodeType) => {
    switch (nodeType) {
        case Wukong.DocumentProto.NodeType.NODE_TYPE_INVALID:
        case Wukong.DocumentProto.NodeType.NODE_TYPE_DOCUMENT:
        case Wukong.DocumentProto.NodeType.NODE_TYPE_PAGE:
        case Wukong.DocumentProto.NodeType.NODE_TYPE_COMMENT:
        case Wukong.DocumentProto.NodeType.NODE_TYPE_STYLE_CONTAINER:
            return []
        case Wukong.DocumentProto.NodeType.NODE_TYPE_RECTANGLE:
        case Wukong.DocumentProto.NodeType.NODE_TYPE_STAR:
        case Wukong.DocumentProto.NodeType.NODE_TYPE_POLYGON:
        case Wukong.DocumentProto.NodeType.NODE_TYPE_GROUP:
        case Wukong.DocumentProto.NodeType.NODE_TYPE_SLICE:
            return nodeTypeShortcutKeyMap['#LayoutMixin']
        case Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME:
            return FrameLikeOwnProps
        case Wukong.DocumentProto.NodeType.NODE_TYPE_BOOL_OPERATION:
            return BoolNodeOwnProps
        case Wukong.DocumentProto.NodeType.NODE_TYPE_ELLIPSE:
            return EllipseNodeOwnProps
        case Wukong.DocumentProto.NodeType.NODE_TYPE_LINE:
            return LineNodeOwnProps
        case Wukong.DocumentProto.NodeType.NODE_TYPE_COMPONENT_SET:
        case Wukong.DocumentProto.NodeType.NODE_TYPE_COMPONENT:
            return ComponentOwnProps
        case Wukong.DocumentProto.NodeType.NODE_TYPE_VECTOR:
            return VectorNodeOwnProps
        case Wukong.DocumentProto.NodeType.NODE_TYPE_TEXT:
            return TextNodeOwnProps
        case Wukong.DocumentProto.NodeType.NODE_TYPE_INSTANCE:
            return InstanceOwnProps
        case Wukong.DocumentProto.NodeType.NODE_TYPE_PAINT_STYLE:
            return PaintStyleNodeOwnProps
        case Wukong.DocumentProto.NodeType.NODE_TYPE_TEXT_STYLE:
            return TextStyleNodeOwnProps
        case Wukong.DocumentProto.NodeType.NODE_TYPE_EFFECT_STYLE:
            return EffectStyleNodeOwnProps
        case Wukong.DocumentProto.NodeType.NODE_TYPE_LAYOUT_GRID_STYLE:
            return LayoutGridStyleNodeOwnProps
        case Wukong.DocumentProto.NodeType.NODE_TYPE_VARIABLE:
            return VariableNodeOwnProps
        case Wukong.DocumentProto.NodeType.NODE_TYPE_VARIABLE_SET:
            return VariableSetNodeOwnProps
    }
}

const CornerMixinProps = ['cornerRadius', 'cornerSmoothing']
const RectangleCornerMixinProps = ['topLeftRadius', 'topRightRadius', 'bottomLeftRadius', 'bottomRightRadius']
const RectangleBorderWeightMixinProps = [
    'borderTopWeight',
    'borderBottomWeight',
    'borderRightWeight',
    'borderLeftWeight',
]
const LayoutMixinProps = ['fillStyleId', 'strokeStyleId', 'effectStyleId', 'gridStyleId', 'fills', 'strokes', 'effects']
const StrokeMixinProps = ['strokeWeight', 'strokeMiterAngle', 'strokeAlign', 'strokeJoin', 'dashPattern', 'dashCap']

const EllipseNodeOwnProps = ['startingAngle', 'endingAngle', ...CornerMixinProps]
const LineNodeOwnProps = ['startCap', 'endCap', ...CornerMixinProps]
const TextNodeOwnProps = [
    'textAlignVertical',
    'textAutoResize',
    'paragraphIndent',
    'paragraphSpacing',
    'autoRename',
    'textStyleId',
    'textData',
    'styleId',
    'textLayoutData',
    'rawComputedStyledTextSegments',
    'textTruncation',
    'maxLines',
    'fontSize',
    'fontName',
    'textDecoration',
    'textCase',
    'lineHeight',
    'letterSpacing',
    'fontVariations',
    'hyperlink',
    'detachOpticalSizeFromFontSize',
]
const VectorNodeOwnProps = ['vectorData', 'VectorHandleMirror']
const BoolNodeOwnProps = ['booleanOperation', ...LayoutMixinProps]
const PublishMixin = ['publishFile', 'publishId']
const VariableNodeOwnProps = [
    'variableSetId',
    'variableResolvedType',
    'variableDataValues',
    'variableCodeSyntax',
    'variableScopes',
    ...PublishMixin,
    ...LayoutMixinProps,
]
const VariableSetNodeOwnProps = ['variableSetModes', 'variableSetCollapsedGroupKeys', ...PublishMixin]
const LayoutGridStyleNodeOwnProps = ['layoutGrids', ...PublishMixin]
const EffectStyleNodeOwnProps = ['effects', ...PublishMixin]
const PaintStyleNodeOwnProps = ['paints', ...PublishMixin]
const TextStyleNodeOwnProps = [
    'fontName',
    'fontSize',
    'fontVariations',
    'lineHeight',
    'letterSpacing',
    'paragraphIndent',
    'paragraphSpacing',
    'textDecoration',
    'detachOpticalSizeFromFontSize',
    ...PublishMixin,
]
const AIPoweredNodeOwnProps = ['aiPoweredNodeId', 'editingTextNodeState']
const FrameLikeOwnProps = [
    'stackVerticalPadding',
    'stackPaddingBottom',
    'stackHorizontalPadding',
    'stackPaddingRight',
    'stackSpacing',
    'stackPrimaryAlignItems',
    'stackCounterAlignItems',
    'stackPrimarySizing',
    'stackCounterSizing',
    'stackMode',
    'stackReverseZIndex',
    'scrollDirection',
    'overlayPositionType',
    'overlayBackgroundInteraction',
    'variantProperties',
    'variantPropKey2OrderedValuesMap',
    'prototypeStartingPoint',
    'overlayBackgroundAppearance',
    'scrollOffset',
    'stackWrap',
    'stackCounterSpacing',
    'rawStackCounterSpacing',
]
const InstanceOwnProps = [
    'mainComponentId',
    'symbolData',
    'derivedSymbolData',
    'scaleFactor',
    'componentPropAssignments',
    'propsAreBubbled',
    ...LayoutMixinProps,
]
const ComponentOwnProps = ['componentPropDefs', 'simplifyInstancePanels', ...PublishMixin]

export const nodeTypeShortcutKeyMap: Record<string, string[]> = {
    '#AIPoweredNode': AIPoweredNodeOwnProps,
    '#StrokeMixin': StrokeMixinProps,
    '#CornerMixin': CornerMixinProps,
    '#RectangleCornerMixin': RectangleCornerMixinProps,
    '#RectangleBorderWeightMixin': RectangleBorderWeightMixinProps,
    '#LayoutMixin': LayoutMixinProps,
    '#FrameLike': FrameLikeOwnProps,
}
