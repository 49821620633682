/* eslint-disable no-restricted-imports */
import { Tooltip, TooltipProps } from '../../../../../../../ui-lib/src'
import { ReactNode, useMemo } from 'react'
import style from './style-tooltip.module.less'

export interface StyleToolTipProps extends OverlayJsxProps {
    children?: ReactNode
    triggerRect?: TooltipProps['triggerRect']
}

export function StyleToolTip(props: StyleToolTipProps): JSX.Element {
    const titleText = useMemo(() => {
        const titleLayer = (props.titleText || '').split('/')
        return titleLayer[titleLayer.length - 1] ?? ''
    }, [props.titleText])

    return (
        <Tooltip
            overlay={<OverlayJsx2 titleText={titleText} contentText={props.contentText} />}
            triggerRect={props.triggerRect}
        >
            {props.children}
        </Tooltip>
    )
}

export interface OverlayJsxProps {
    titleText: string
    contentText?: string | null
}

export function OverlayJsx2(props: OverlayJsxProps) {
    const { titleText, contentText } = props

    return (
        <div className={style.overlay2}>
            <div>{titleText}</div>
            {contentText ? <div className={style.description}>{contentText}</div> : null}
        </div>
    )
}
