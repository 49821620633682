import { Wukong } from '@wukong/bridge-proto'
import {
    getAddInteractionTypeList,
    InteractionActionType,
    InteractionActionTypeWithMixed,
    InteractionsWithEventType,
    InteractionTypeWithMixed,
    MIXED_TYPE,
    PrototypeInteractionWithNodeId,
} from './constants'

import InteractionType = Wukong.DocumentProto.InteractionType
import ConnectionType = Wukong.DocumentProto.ConnectionType
import NavigationType = Wukong.DocumentProto.NavigationType

function generateMixedValue<T>(value: T, oldValue: T) {
    if (oldValue === undefined) {
        return value
    } else if (oldValue !== value) {
        return MIXED_TYPE
    }
    return value
}

export const generateInteractionsWithEventType = (
    interactions: PrototypeInteractionWithNodeId[],
    isSingleNode: boolean
): InteractionsWithEventType[] => {
    if (isSingleNode) {
        return interactions.map((interaction) => ({
            event: { interactionType: interaction.event.interactionType ?? InteractionType.INTERACTION_TYPE_NONE },
            interactions: [interaction],
        }))
    }

    const res: InteractionsWithEventType[] = []
    interactions.forEach((interaction) => {
        const type = interaction.event.interactionType ?? InteractionType.INTERACTION_TYPE_NONE
        const idx = res.findIndex((v) => v.event.interactionType === type)
        if (idx > -1) {
            res[idx].interactions.push(interaction)
        } else {
            res.push({
                event: { interactionType: type },
                interactions: [interaction],
            })
        }
    })
    return res
}

const isConflictWithHover = (type: InteractionType) => {
    return (
        type === InteractionType.INTERACTION_TYPE_ON_CLICK ||
        type === InteractionType.INTERACTION_TYPE_MOUSE_DOWN ||
        type === InteractionType.INTERACTION_TYPE_MOUSE_UP
    )
}

export const generateTriggersConflictList = (triggers: InteractionsWithEventType[]) => {
    const hasHover = triggers.some((v) => v.event.interactionType === InteractionType.INTERACTION_TYPE_ON_HOVER)
    return triggers.map((trigger) => hasHover && isConflictWithHover(trigger.event.interactionType))
}

export const getSingleActionType = (
    action: Pick<Wukong.DocumentProto.IPrototypeAction, 'connectionType' | 'navigationType'>
) => {
    switch (action.connectionType) {
        case ConnectionType.CONNECTION_TYPE_NONE:
            return InteractionActionType.None
        case ConnectionType.CONNECTION_TYPE_INTERNAL_NODE:
            switch (action.navigationType) {
                case NavigationType.NAVIGATION_TYPE_NAVIGATE:
                    return InteractionActionType.NavigateTo
                case NavigationType.NAVIGATION_TYPE_OVERLAY:
                    return InteractionActionType.OpenOverlay
                case NavigationType.NAVIGATION_TYPE_SCROLL_TO:
                    return InteractionActionType.ScrollTo
                case NavigationType.NAVIGATION_TYPE_SWAP:
                    return InteractionActionType.SwapOverlay
                default:
                    break
            }
            break
        case ConnectionType.CONNECTION_TYPE_BACK:
            return InteractionActionType.Back
        case ConnectionType.CONNECTION_TYPE_CLOSE:
            return InteractionActionType.CloseOverlay
        case ConnectionType.CONNECTION_TYPE_URL:
            return InteractionActionType.OpenLink
        default:
            break
    }

    return InteractionActionType.None
}

export const getActionData = ({
    interactions,
    actionIndex = 0,
}: {
    interactions: PrototypeInteractionWithNodeId[]
    actionIndex?: number
}) => {
    let actionType: InteractionActionTypeWithMixed | undefined
    const connectionUrls: string[] = []
    let openInNewTab: boolean | typeof MIXED_TYPE | undefined
    let transitionNodeId: string | typeof MIXED_TYPE | undefined
    const typeList: InteractionActionType[] = []
    interactions.forEach((interaction) => {
        const action = interaction.actions[actionIndex]
        if (action && interaction.event.interactionType !== InteractionType.INTERACTION_TYPE_NONE) {
            const type = getSingleActionType(action)
            const url = action.connectionURL
            if (url && !connectionUrls.includes(url)) {
                connectionUrls.push(url)
            }
            actionType = generateMixedValue(type, actionType)
            openInNewTab = generateMixedValue(action.openUrlInNewTab ?? false, openInNewTab)
            transitionNodeId = generateMixedValue(action.transitionNodeID ?? '', transitionNodeId)
            typeList.push(type)
        }
    })
    return {
        type: actionType,
        openInNewTab,
        connectionUrls,
        transitionNodeId,
        typeList,
    }
}

export const getAddPrototypeInteractionType = (triggers: InteractionsWithEventType[]) => {
    const triggerSet: Set<InteractionType> = new Set()
    for (const { event: eve } of triggers) {
        triggerSet.add(eve.interactionType)
    }
    for (const type of getAddInteractionTypeList()) {
        if (!triggerSet.has(type)) {
            return type
        }
    }
    // 编辑过滤 drag&hover&press
    return InteractionType.INTERACTION_TYPE_DRAG
}

export function canSelectOuterTargets(type: InteractionActionTypeWithMixed) {
    return (
        type === InteractionActionType.NavigateTo ||
        type === InteractionActionType.OpenOverlay ||
        type === InteractionActionType.SwapOverlay
    )
}

export function canSelectTargets(type: InteractionActionTypeWithMixed) {
    return type === InteractionActionType.ScrollTo || canSelectOuterTargets(type)
}

export const changeInteractionType = (type: InteractionType, interaction: PrototypeInteractionWithNodeId) => {
    const newInteraction = { ...interaction }
    newInteraction.event.interactionType = type
    if (type === InteractionType.INTERACTION_TYPE_NONE) {
        newInteraction.actions = []
    }
    return newInteraction
}

export const changeInteractionActionType = (
    type: InteractionActionType,
    interaction: PrototypeInteractionWithNodeId
) => {
    const newInteraction = { ...interaction }
    const newAction = newInteraction.actions[0]
        ? { ...newInteraction.actions[0] }
        : ({} as Wukong.DocumentProto.IPrototypeAction)
    switch (type) {
        case InteractionActionType.None:
            newAction.connectionType = ConnectionType.CONNECTION_TYPE_NONE
            break
        case InteractionActionType.NavigateTo:
            newAction.connectionType = ConnectionType.CONNECTION_TYPE_INTERNAL_NODE
            newAction.navigationType = NavigationType.NAVIGATION_TYPE_NAVIGATE
            break
        case InteractionActionType.Back:
            newAction.connectionType = ConnectionType.CONNECTION_TYPE_BACK
            break
        case InteractionActionType.ScrollTo:
            newAction.connectionType = ConnectionType.CONNECTION_TYPE_INTERNAL_NODE
            newAction.navigationType = NavigationType.NAVIGATION_TYPE_SCROLL_TO
            break
        case InteractionActionType.OpenLink:
            newAction.connectionType = ConnectionType.CONNECTION_TYPE_URL
            break
        case InteractionActionType.OpenOverlay:
            newAction.connectionType = ConnectionType.CONNECTION_TYPE_INTERNAL_NODE
            newAction.navigationType = NavigationType.NAVIGATION_TYPE_OVERLAY
            break
        case InteractionActionType.SwapOverlay:
            newAction.connectionType = ConnectionType.CONNECTION_TYPE_INTERNAL_NODE
            newAction.navigationType = NavigationType.NAVIGATION_TYPE_SWAP
            break
        case InteractionActionType.CloseOverlay:
            newAction.connectionType = ConnectionType.CONNECTION_TYPE_CLOSE
            break
    }

    return { ...newInteraction, actions: [newAction] }
}

export const canShowDelayInput = (type: InteractionTypeWithMixed) => {
    return (
        type === InteractionType.INTERACTION_TYPE_AFTER_TIMEOUT ||
        type === InteractionType.INTERACTION_TYPE_MOUSE_ENTER ||
        type === InteractionType.INTERACTION_TYPE_MOUSE_LEAVE ||
        type === InteractionType.INTERACTION_TYPE_MOUSE_DOWN ||
        type === InteractionType.INTERACTION_TYPE_MOUSE_UP
    )
}

export const getDelayData = (interactions: PrototypeInteractionWithNodeId[]) => {
    let interactionDuration: number | typeof MIXED_TYPE | undefined
    let transitionTimeout: number | typeof MIXED_TYPE | undefined
    interactions.forEach((interaction) => {
        const duration = interaction.event.interactionDuration ?? 0
        const timeout = interaction.event.transitionTimeout ?? 0
        interactionDuration = generateMixedValue(duration, interactionDuration)
        transitionTimeout = generateMixedValue(timeout, transitionTimeout)
    })
    return {
        interactionDuration: interactionDuration || 0,
        transitionTimeout: transitionTimeout || 0.8,
    }
}

export const getScrollOffset = (interactions: PrototypeInteractionWithNodeId[], actionIndex = 0) => {
    let xOffset: number | typeof MIXED_TYPE | undefined
    let yOffset: number | typeof MIXED_TYPE | undefined
    interactions.forEach((interaction) => {
        const x = interaction.actions[actionIndex].extraScrollOffset?.x ?? 0
        const y = interaction.actions[actionIndex].extraScrollOffset?.y ?? 0
        xOffset = generateMixedValue(x, xOffset)
        yOffset = generateMixedValue(y, yOffset)
    })
    return {
        xOffset,
        yOffset,
    }
}

export const getResetScrollPositionState = (interactions: PrototypeInteractionWithNodeId[], actionIndex = 0) => {
    const show = !interactions.every((v) =>
        [
            InteractionActionType.None,
            InteractionActionType.OpenLink,
            InteractionActionType.CloseOverlay,
            InteractionActionType.ScrollTo,
        ].includes(getSingleActionType(v.actions[actionIndex]))
    )

    let checked: boolean | typeof MIXED_TYPE | undefined
    for (const interaction of interactions) {
        if (checked === undefined) {
            checked = interaction.actions[actionIndex].transitionResetScrollPosition
        } else if (checked !== interaction.actions[actionIndex].transitionResetScrollPosition) {
            checked = MIXED_TYPE
            break
        }
    }

    return { show, checked: checked ?? false } as const
}
