/* eslint-disable no-restricted-imports */
import {
    DispatchMouseEventCommand,
    DispatchWheelEventCommand,
    SetDevicePixelRatioCommand,
    ShouldMoveViewportWhenOutsideCanvasCommand,
    TabHiddenCommand,
    TabVisibleCommand,
    UpdateIsViewportZoomingCommand,
    UpdateMouseCameraPositionCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { isDocumentVisible } from '../../../../util/src'
import { cmdResizeCanvas } from '../../document/command/canvas-resize-command'
import { CommandInvoker } from '../../document/command/command-invoker'
import {
    cmdMoveViewport,
    cmdMoveViewportWhenOutsideCanvas,
    cmdScaleViewportByWheel,
} from '../../document/command/viewport-command'
import type { ExecutableDocumentRoot } from '../../document/document-bridge/document-root'
import { Vector } from '../../document/node/node'
import { WkCLog } from '../../kernel/clog/wukong/instance'
import { featureSwitchManager } from '../../kernel/switch/core'
import { isChrome, isEdge } from '../../kernel/util/ua'
import { MouseOnCanvas } from '../../ui/component/mouse-on-canvas/mouse-on-canvas'
import { isRightClickAtCanvas } from '../../ui/utils/is-right-click'
import type { Handler, HandlerContext, HandlerProvider } from '../handler-provider/handler-provider'
import { focusViewManager } from '../service/focus-view/focus-view-manager'
import { focusViewTraceLog } from '../service/focus-view/focus-view-trace-log'
import { RootComponentId } from './canvas'
import { HandleWheelEvent } from './handle-wheel-event'

// 对于 Chrome, Edge, 鼠标中键 UP 于浏览器之外时, 无对应事件
// 故需要 enter 文档时手动构建 mouseup 事件, 以结束对应 drag 事件
class MissingMiddleUpEventHandler {
    middleButtonPressedLast = false

    mouseEventHadMiddleButton(e: MouseEvent) {
        return e.buttons > 3
    }

    shouldFakeMouseUpEventWhenEnterDocument = (e: MouseEvent) => {
        return (isEdge() || isChrome()) && !this.mouseEventHadMiddleButton(e) && this.middleButtonPressedLast
    }

    updateMiddleButtonPressedLast = (value: boolean | MouseEvent) => {
        if (typeof value === 'boolean') {
            this.middleButtonPressedLast = value
        } else {
            this.middleButtonPressedLast = this.mouseEventHadMiddleButton(value)
        }
    }

    fakeMouseUpEvent = (e: MouseEvent) => {
        return new MouseEvent('mouseup', {
            ...e,
            buttons: 0,
        })
    }

    reset = () => {
        this.middleButtonPressedLast = false
    }
}

export const missingMiddleUpEventHandler = new MissingMiddleUpEventHandler()

function preventDefaultZoomBehavior(e: MouseEvent) {
    if (e.metaKey || e.ctrlKey) {
        e.preventDefault()
    }
}

let lastMouseEvent: ReturnType<typeof resolveMouseEventPosition> | null = null
let lastMouseEventTimer: NodeJS.Timeout | null = null
let eventIsInCanvas = false
let previousButtonsStatus: MouseEvent['buttons'] = 0
let resendTimer: any = undefined
let resendUsedEvent: MouseEvent

const ZOOMING_DEBOUNCE_DELAY = 50

export class CanvasEventHandler {
    private handleWheelEvent = new HandleWheelEvent()
    constructor(
        private readonly handlerProvider: HandlerProvider,
        private readonly commandInvoker: CommandInvoker,
        private readonly canvas: HTMLCanvasElement,
        private readonly mouseOnCanvas: MouseOnCanvas
    ) {}

    private viewportZoomingDebounceTimeout: null | ReturnType<typeof setTimeout> = null

    private handleWheel: Handler<WheelEvent> = (ctx, e) => {
        if (e.target instanceof HTMLElement && e.target.id === RootComponentId.CanvasOverlay) {
            // 禁止浏览器对页面的移动和缩放行为
            e.preventDefault()

            ctx.bridge.call(DispatchWheelEventCommand, {
                deltaX: e.deltaX,
                deltaY: e.deltaY,
                deltaZ: e.deltaZ,
                offsetX: e.offsetX,
                offsetY: e.offsetY,
                ctrlKey: e.ctrlKey,
                shiftKey: e.shiftKey,
                altKey: e.altKey,
                metaKey: e.metaKey,
            })

            const isMouseMiddleKeyDown = (event: WheelEvent) => {
                return (event.buttons & 4) === 4
            }

            if (e.metaKey || e.ctrlKey) {
                // 进行缩放
                ctx.commandInvoker.invoke(cmdScaleViewportByWheel, e)
                this.updateViewportZoomingState(ctx)
            } else if (!isMouseMiddleKeyDown(e)) {
                const { deltaX: finalDeltaX, deltaY: finalDeltaY } = this.handleWheelEvent.handleWheel(e)
                ctx.commandInvoker.invoke(cmdMoveViewport, finalDeltaX, finalDeltaY)
                updateCanvasMouseCameraPosition(ctx, e)
            }
            return
        }

        if (!this.mouseOnCanvas.isMouseOnCanvas()) {
            preventDefaultZoomBehavior(e)
        }
    }

    private updateViewportZoomingState = (ctx: HandlerContext) => {
        ctx.commandInvoker.DEPRECATED_invokeBridge(UpdateIsViewportZoomingCommand, { value: true })

        if (this.viewportZoomingDebounceTimeout !== null) {
            clearTimeout(this.viewportZoomingDebounceTimeout)
        }

        this.viewportZoomingDebounceTimeout = setTimeout(() => {
            this.viewportZoomingDebounceTimeout = null
            ctx.commandInvoker.DEPRECATED_invokeBridge(UpdateIsViewportZoomingCommand, { value: false })
        }, ZOOMING_DEBOUNCE_DELAY)
    }

    private changeCanvasSize = () => this.commandInvoker.invoke(cmdResizeCanvas, this.canvas, 0, 0)
    private onVisibilityChange = () => this.handlerProvider.handle(handleVisibilityChange, null)
    private handleMouseWheel = (e: MouseEvent) => this.handlerProvider.handle(this.handleWheel, e as WheelEvent)

    public enable = () => {
        window.addEventListener('wheel', this.handleMouseWheel, { passive: false })
        window.addEventListener('resize', this.changeCanvasSize)
        window.document.addEventListener('visibilitychange', this.onVisibilityChange)
        // 这里手动触发下 onVisibilityChange，保证 wasm 拿到最新值
        this.onVisibilityChange()
    }

    public disable = () => {
        window.removeEventListener('wheel', this.handleMouseWheel)
        window.removeEventListener('resize', this.changeCanvasSize)
        window.document.removeEventListener('visibilitychange', this.onVisibilityChange)
    }

    destroy = () => {
        this.handleWheelEvent.destroy()
    }
}

export type GlobalMouseEvent = MouseEvent | WheelEvent

export interface ModifierKeys {
    shiftKey?: boolean
    ctrlKey?: boolean
    metaKey?: boolean
    altKey?: boolean
}

export function isEventTargetingCanvas(event: GlobalMouseEvent) {
    const target = event.target ?? event.relatedTarget
    return target instanceof HTMLElement && target.id === RootComponentId.CanvasOverlay
}

export function shouldDelegateMouseEventInsideCamera(ctx: HandlerContext, event: GlobalMouseEvent) {
    const documentNode = ctx.documentRoot.currentDocument()
    if (!documentNode) {
        return false
    }

    // 不再拦截手型工具模式下的鼠标事件
    return isEventTargetingCanvas(event)
}

export function shouldContinueResponseMouseEventOutsideCanvas(ctx: HandlerContext, event: GlobalMouseEvent) {
    const documentNode = ctx.documentRoot.currentDocument()
    if (!documentNode) {
        return false
    }

    if (isEventTargetingCanvas(event)) {
        return false
    }

    // 不再拦截手型工具模式下的鼠标事件, 鼠标位于画布之外且左键按下时，向 wasm 传递鼠标事件
    return previousButtonsStatus !== 0
}

export function translateClientRelativePositionToTargetOffsetPosition(
    e: Pick<GlobalMouseEvent, 'clientX' | 'clientY'>,
    canvas: HTMLCanvasElement
): Vector {
    const rect = canvas.getBoundingClientRect()
    return {
        x: e.clientX - rect.x,
        y: e.clientY - rect.y,
    }
}

export function isInDraggingState(documentRoot: ExecutableDocumentRoot): boolean {
    const document = documentRoot.currentDocument()
    if (!document) {
        return false
    }
    return document.handToolDraggingState !== null
}

export const CanvasMouseEventTypeMap: {
    [key: string]: Wukong.DocumentProto.MouseEventType
} = {
    mousedown: Wukong.DocumentProto.MouseEventType.MOUSE_EVENT_TYPE_DOWN,
    mouseup: Wukong.DocumentProto.MouseEventType.MOUSE_EVENT_TYPE_UP,
    mousemove: Wukong.DocumentProto.MouseEventType.MOUSE_EVENT_TYPE_MOVE,
    dblclick: Wukong.DocumentProto.MouseEventType.MOUSE_EVENT_TYPE_DOUBLE_CLICK,
    contextmenu: Wukong.DocumentProto.MouseEventType.MOUSE_EVENT_TYPE_CONTEXT_MENU,
    click: Wukong.DocumentProto.MouseEventType.MOUSE_EVENT_TYPE_CLICK,
}

export function shouldDelegateMouseEvent(ctx: HandlerContext, event: MouseEvent): boolean {
    return shouldDelegateMouseEventInsideCamera(ctx, event) || shouldContinueResponseMouseEventOutsideCanvas(ctx, event)
}

export function resolveMouseEventPosition(ctx: HandlerContext, event: MouseEvent, canvas: HTMLCanvasElement) {
    const delegatingPosition: Wukong.DocumentProto.IArg_dispatchMouseEvent = {
        clientX: event.offsetX,
        clientY: event.offsetY,
        movementX: event.movementX,
        movementY: event.movementY,
        altKey: event.altKey,
        ctrlKey: event.ctrlKey,
        metaKey: event.metaKey,
        shiftKey: event.shiftKey,
        buttons: event.buttons,
    }

    if (shouldContinueResponseMouseEventOutsideCanvas(ctx, event)) {
        const position = translateClientRelativePositionToTargetOffsetPosition(event, canvas)
        delegatingPosition.clientX = position.x
        delegatingPosition.clientY = position.y
    }

    return delegatingPosition
}

///////////
// Handlers
///////////

function startChangeViewPort(ctx: HandlerContext, rect: DOMRect, event: MouseEvent) {
    resendUsedEvent = event

    if (resendTimer) {
        return
    }

    const changeViewport = () => {
        resendTimer = requestAnimationFrame(() => {
            ctx.commandInvoker.invoke(
                cmdMoveViewportWhenOutsideCanvas,
                resendUsedEvent.clientX,
                resendUsedEvent.clientY,
                {
                    x: rect.x,
                    y: rect.y,
                    width: rect.width,
                    height: rect.height,
                }
            )
            changeViewport()
        })
    }

    changeViewport()
}

const sendMouseEventOrTriggerAutoViewporMove = (
    ctx: HandlerContext,
    event: MouseEvent,
    eventType: string,
    canvas: HTMLCanvasElement
) => {
    const tryTriggerOrCancelAutoViewporMove = () => {
        if (eventType !== 'mouseup') {
            // MouseDown / MouseMove / Context

            // 发生于画布边缘以及之外的鼠标事件可能触发「窗口自动移动」
            if (
                !(window as any).e2e &&
                ctx.commandInvoker.DEPRECATED_invokeBridge(ShouldMoveViewportWhenOutsideCanvasCommand)?.value!
            ) {
                // 开始窗口自动移动
                // 鼠标事件由 autoResendMouseMoveOnViewportChanged 发送至 wasm
                const rect = canvas.getBoundingClientRect()
                startChangeViewPort(ctx, rect, event)
            } else {
                // 取消窗口自动移动
                if (resendTimer) {
                    cancelAnimationFrame(resendTimer)
                    resendTimer = undefined
                }
            }
        } else {
            // MouseUp

            // 取消窗口自动移动
            if (resendTimer) {
                cancelAnimationFrame(resendTimer)
                resendTimer = undefined
            }
        }

        return !!resendTimer
    }

    /**
     * mousedown event 发给 wasm 之前先执行 blur，避免 blur 触发 cmd 执行时 selection 已经被 wasm 修改了
     * FIX: https://wkong.atlassian.net/browse/WK-7389
     */
    const tryBlurBeforeMouseDown = () => {
        if (eventType === 'mousedown' && document.activeElement) {
            // 点击画布区时，若焦点位于画布区，则不触发画布区焦点的 blur
            if (
                // [devMode 下启用 focusView] 但不会走到这个分支，这里只处理 designMode
                featureSwitchManager.isEnabled('focus-view') &&
                focusViewManager.isDocumentActiveElementFocusView()
            ) {
                focusViewTraceLog('[CanvasEventHandler] mousedown captured for canvas, not to blur')
                event.preventDefault()
            } else {
                focusViewTraceLog('[CanvasEventHandler] mousedown to blur', document.activeElement)
                ;(document.activeElement as any)?.blur()
            }
        }
    }

    // @lousz [WK-15428]
    const tryToPreventDefaultForFrameTitleInput = () => {
        const isSelectInputContent =
            document.activeElement?.tagName.toLowerCase() === 'input' &&
            event.buttons === 1 &&
            eventType === 'mousemove'
        if (!isSelectInputContent) {
            event.preventDefault()
        }
    }

    if (!tryTriggerOrCancelAutoViewporMove()) {
        tryToPreventDefaultForFrameTitleInput()
        tryBlurBeforeMouseDown()

        if (eventType === 'mouseup') {
            // MouseUp
            missingMiddleUpEventHandler.updateMiddleButtonPressedLast(false)
            sendMouseEvent(CanvasMouseEventTypeMap.mouseup, ctx)
        } else {
            // MouseDown / MouseMove / Context
            sendMouseEvent(CanvasMouseEventTypeMap[eventType], ctx)
        }
    }
}

export const handleCanvasMouseEvent: Handler<[MouseEvent | ModifierKeys, HTMLCanvasElement]> = (
    ctx,
    [event, canvas]
) => {
    if (event instanceof MouseEvent) {
        const newEventIsInCanvas = isEventTargetingCanvas(event)
        // 鼠标移出canvas
        if (eventIsInCanvas && !newEventIsInCanvas) {
            updateCanvasMouseCameraPosition(ctx)

            // 在画布上移动
        } else if (newEventIsInCanvas) {
            updateCanvasMouseCameraPosition(ctx, event)
        }

        eventIsInCanvas = newEventIsInCanvas

        if (!shouldDelegateMouseEvent(ctx, event)) {
            lastMouseEvent = null
            return
        }

        // contextmenu 类型可由对应的 mousedown 转化而来
        if (event.type === 'contextmenu') {
            return
        }

        let eventType = event.type
        if (event.type === 'mousedown') {
            if (previousButtonsStatus === 0) {
                const enableCtrlClickTriggerContextMenu =
                    ctx.userConfigService.useZustandStore2.getState().ctrlClickTriggerContextMenu

                if (isRightClickAtCanvas(event, enableCtrlClickTriggerContextMenu)) {
                    eventType = 'contextmenu'
                } else {
                    missingMiddleUpEventHandler.updateMiddleButtonPressedLast(event)
                    eventType = 'mousedown'
                }
            } else {
                eventType = 'mousemove'
            }
        } else if (event.type === 'mouseup') {
            if (event.buttons === 0) {
                eventType = 'mouseup'
            } else {
                eventType = 'mousemove'
            }
        }

        lastMouseEvent = resolveMouseEventPosition(ctx, event, canvas)
        sendMouseEventOrTriggerAutoViewporMove(ctx, event, eventType, canvas)

        // last step: update previousButtonsStatus
        if (event.type === 'mouseup' || event.type === 'mousedown') {
            previousButtonsStatus = event.buttons
        }
    } else if (lastMouseEvent) {
        if (event.altKey !== undefined) {
            lastMouseEvent.altKey = event.altKey
        }
        if (event.ctrlKey !== undefined) {
            lastMouseEvent.ctrlKey = event.ctrlKey
        }
        if (event.metaKey !== undefined) {
            lastMouseEvent.metaKey = event.metaKey
        }
        if (event.shiftKey !== undefined) {
            lastMouseEvent.shiftKey = event.shiftKey
        }

        if (lastMouseEventTimer) {
            // NOTE: 只需要最新一次mousemove即可, 避免重复发送多次
            clearTimeout(lastMouseEventTimer)
        }
        // 按下修饰键重放的 handler 需要延迟一个 tick 触发，目的是有些修饰键会造成渲染变化，需要延迟一个 tick handler 才能 match changes
        lastMouseEventTimer = setTimeout(() => {
            sendMouseEvent(CanvasMouseEventTypeMap.mousemove, ctx, true)
        }, 0)
    }
}

function sendMouseEvent(type: Wukong.DocumentProto.MouseEventType, ctx: HandlerContext, triggerByKeyboard = false) {
    ctx.bridge.call(DispatchMouseEventCommand, {
        type,
        triggerByKeyboard,
        ...lastMouseEvent,
    })
    ctx.bridge.call(SetDevicePixelRatioCommand, {
        value: window.devicePixelRatio,
    })
}

const DefaultMousePosition: Wukong.DocumentProto.IArg_cmdUpdateMouseCameraPosition = {
    valueType: Wukong.DocumentProto.ProtoValueType.PROTO_VALUE_TYPE_NULLPTR,
}
let prevMousePosition: Wukong.DocumentProto.IArg_cmdUpdateMouseCameraPosition = DefaultMousePosition
// 无条件传递画布区光标变动坐标
function updateCanvasMouseCameraPosition(ctx: HandlerContext, event?: MouseEvent | WheelEvent) {
    if (!event) {
        prevMousePosition = DefaultMousePosition
    } else if (
        event.type === 'wheel' ||
        event.offsetX !== prevMousePosition.clientX ||
        event.offsetY !== prevMousePosition.clientY
    ) {
        prevMousePosition = {
            clientX: event.offsetX,
            clientY: event.offsetY,
            valueType: Wukong.DocumentProto.ProtoValueType.PROTO_VALUE_TYPE_HAS_VALUE,
        }
    } else {
        return
    }

    ctx.bridge.call(UpdateMouseCameraPositionCommand, prevMousePosition)
}

export const handleVisibilityChange: Handler = (ctx) => {
    if (isDocumentVisible()) {
        WkCLog.log('[Editor] visibilitychange event fired, tab visible')
        ctx.commandInvoker.DEPRECATED_invokeBridge(TabVisibleCommand)
    } else {
        WkCLog.log('[Editor] visibilitychange event fired, tab hidden')
        ctx.commandInvoker.DEPRECATED_invokeBridge(TabHiddenCommand)
    }
}
