/* eslint-disable no-restricted-imports */
import { useEffect, useState } from 'react'
import { useDocId } from '../../../../../main/app-context'
import { LocalStorageKey } from '../../../../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../../../../web-storage/local-storage/storage'
import { StyleNodeTypeKey } from '../types'
import { getParentFolderNames } from '../utils'
import { ILocalStyleItem } from './use-local-style-model-v2'

function useDocStyleFolderCollapse(type: StyleNodeTypeKey, folderList: string[]) {
    const docId = useDocId()

    const [collapsedFolderSet, setCollapsedFolderSet] = useState(() => {
        const docStyleFolderCollapseMap = enhancedLocalStorage.getSerializedItem(
            LocalStorageKey.CollapsedStyleFolders,
            {}
        )

        if (docStyleFolderCollapseMap[docId]) {
            if (docStyleFolderCollapseMap[docId][type]) {
                return new Set(docStyleFolderCollapseMap[docId][type])
            }
        } else {
            docStyleFolderCollapseMap[docId] = {}
            enhancedLocalStorage.setSerializedItem(LocalStorageKey.CollapsedStyleFolders, docStyleFolderCollapseMap)
        }

        // 全部为收起状态
        return new Set(folderList)
    })

    useEffect(() => {
        // 每次更新时先读取LocalStorage中的最新值, 避免同时打开多个文档相互覆盖
        const docStyleFolderCollapseMap = enhancedLocalStorage.getSerializedItem(
            LocalStorageKey.CollapsedStyleFolders,
            {}
        )
        if (docStyleFolderCollapseMap[docId]) {
            docStyleFolderCollapseMap[docId][type] = Array.from(collapsedFolderSet)
            enhancedLocalStorage.setSerializedItem(LocalStorageKey.CollapsedStyleFolders, docStyleFolderCollapseMap)
        }
    }, [docId, collapsedFolderSet, type])

    return [collapsedFolderSet, setCollapsedFolderSet] as const
}

export function useStyleFolderCollapseModel(type: StyleNodeTypeKey, items: ILocalStyleItem[]) {
    const folderList = items.filter((item) => item.isFolder).map((item) => item.folderName)

    const [styleCollapsedFolderSet, setStyleCollapsedFolderSet] = useDocStyleFolderCollapse(type, folderList)

    const isFolderCollapse = (folderName: string) => {
        return styleCollapsedFolderSet.has(folderName)
    }

    const isItemVisible = (item: ILocalStyleItem) => {
        const itemName = item.isFolder ? item.folderName : item.styleNode.name
        const parentPaths = getParentFolderNames(itemName)
        return parentPaths.every((folderName) => !isFolderCollapse(folderName))
    }

    const toggleFolderCollapse = (folderName: string) => {
        const next = new Set(styleCollapsedFolderSet)
        if (styleCollapsedFolderSet.has(folderName)) {
            next.delete(folderName)
        } else {
            next.add(folderName)
        }
        return setStyleCollapsedFolderSet(next)
    }

    return {
        isFolderCollapse,
        isItemVisible,
        toggleFolderCollapse,
    }
}
