import { Value } from './type'

function _toFixed2(value: number) {
    return String(parseFloat(value.toFixed(2)))
}
export function formateToFixed2(value: Value): string {
    if (Array.isArray(value)) {
        return value.map(_toFixed2).join(',')
    }
    if (typeof value === 'number') {
        return _toFixed2(value)
    }
    return ''
}

export function formateToFixed2Degree(value: Value): string {
    return formateToFixed2(value) + '°'
}

export function formateToInteger(value: Value): string {
    if (Array.isArray(value)) {
        return value.map(Math.round).join(',')
    }
    if (typeof value === 'number') {
        return String(Math.round(value))
    }
    return ''
}

export function formateToFixed2Percent(value: Value): string {
    return formateToFixed2(value) + '%'
}
export function formateToIntegerPercent(value: Value): string {
    return formateToInteger(value) + '%'
}
