import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    LearnMore: '了解更多',
    Done: '完成',
    Next: '下一步',
    Prev: '上一步',
} as const

export const enTranslation = {
    LearnMore: 'Learn more',
    Done: 'Done',
    Next: 'Next',
    Prev: 'Previous',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
