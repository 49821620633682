import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { IconAdd16, IconStyle, SimpleDrag, Tooltip } from '../../../../../../ui-lib/src'
import { IconButton } from '../../atom/button/icon-button'
import { SingleGrid } from '../../atom/grid/single-grid'
import { Title } from '../../atom/title/title'
import { useRenderColorSpace } from '../../color-profile'
import style from '../../design/fills/fills.module.less'
import { StyleLayoutGrid } from '../../design/styles/style-layout-grid/style-layout-grid'
import { GridComponet } from './layotu-grid-componet'
import { StyleGrid } from './style-layout-grid'
import { useGridLayout } from './use-layout-grid'

export function LayoutGrid() {
    const {
        layoutGrids,
        onClickTitle,
        onClickStyle,
        openStyle,
        isShowAddGridBtn,
        stylePosition,
        onClickAddGrid,
        onChangeStyle,
        onClickCutLink,
        onRemoveStyle,
        onChangeStyleVisible,
    } = useGridLayout()

    return (
        <div className={'pt-8px pb-8px'}>
            <Title
                grayTheme={!layoutGrids?.type}
                onClick={onClickTitle}
                className={!layoutGrids?.type && !openStyle ? style.emptyTitle : ''}
            >
                <Title.Left>{translation('LayoutGrid')}</Title.Left>
                {isShowAddGridBtn && (
                    <Title.Right>
                        <Tooltip title={translation('Style')}>
                            <IconButton
                                icon={<IconStyle />}
                                selected={openStyle}
                                deepColor
                                onClick={onClickStyle}
                                className={style.styleIcon}
                                dataTestId="open-pop"
                            />
                        </Tooltip>
                        <IconButton
                            icon={<IconAdd16 />}
                            selected={false}
                            onClick={onClickAddGrid}
                            dataTestId="grid-add"
                        />
                    </Title.Right>
                )}
            </Title>
            {layoutGrids?.type === Wukong.DocumentProto.VSelectionLayoutGridType.V_SELECTION_LAYOUT_GRID_TYPE_MIXED ? (
                <SingleGrid>
                    <SingleGrid.Item start={5} span={55} className={style.mixedTipText}>
                        {translation('Click+To')}
                    </SingleGrid.Item>
                </SingleGrid>
            ) : layoutGrids?.type ===
              Wukong.DocumentProto.VSelectionLayoutGridType.V_SELECTION_LAYOUT_GRID_TYPE_STYLE ? (
                <StyleGrid
                    name={layoutGrids.layoutGridStyleNode.name}
                    description={layoutGrids.layoutGridStyleNode.description}
                    isOpen={openStyle}
                    layoutgrids={layoutGrids?.layoutGridStyleNode.layoutGrids}
                    onClickCutLink={() => {
                        onClickCutLink(layoutGrids.layoutGridStyleNode.styleNode)
                    }}
                    onClickSub={onRemoveStyle}
                    onClickStyle={onClickStyle}
                    onChangeVisible={onChangeStyleVisible}
                />
            ) : (
                !!layoutGrids?.layoutGrids.length && <ReversedLayoutGridList></ReversedLayoutGridList>
            )}
            {openStyle && (
                <StyleLayoutGrid
                    open={openStyle}
                    position={stylePosition}
                    layoutGrids={layoutGrids?.layoutGrids}
                    selectStyleId={layoutGrids?.layoutGridStyleId}
                    selectStyleNodeKey={layoutGrids?.layoutGridStyleNode?.key ?? ''}
                    onChangeStyle={onChangeStyle}
                    isMixed={
                        layoutGrids?.type ===
                        Wukong.DocumentProto.VSelectionLayoutGridType.V_SELECTION_LAYOUT_GRID_TYPE_MIXED
                    }
                />
            )}
        </div>
    )
}

function ReversedLayoutGridList() {
    const {
        selectionLayoutGridListSelectedReversedIndexes,
        isOpen,
        layoutGrids,
        onClickSub,
        onChangeVisible,
        onChangeLayoutPattern,
        onChangeSectionSize,
        onChangeSectionSizeVar,
        onChangeSolidPaint,
        onChangeCount,
        onChangeCountVar,
        onChangeAlign,
        onChangeGutterSize,
        onChangeGutterSizeVar,
        onChangeOffset,
        onChangeOffsetVar,
        onChangeModalVisible,
        onSelectChange,
        onDropDone,
    } = useGridLayout()
    const colorSpace = useRenderColorSpace()

    const _layoutGrids = layoutGrids?.layoutGrids.slice().reverse() ?? []
    const selectedIndexes =
        selectionLayoutGridListSelectedReversedIndexes?.indexes.map((v) => _layoutGrids.length - 1 - v) ?? []
    const _onSelectChange = (indexes: number[]) => {
        onSelectChange(indexes.map((v) => _layoutGrids.length - 1 - v))
    }
    const _onDropDone = (items: Wukong.DocumentProto.ILayoutGrid[], indexes: number[]) => {
        const newItems = items.slice().reverse()
        onDropDone(
            newItems,
            indexes.map((index) => newItems.length - 1 - index)
        )
    }
    const _isOpen = (index: number) => isOpen(_layoutGrids.length - 1 - index)
    const _onChangeVisible = (index: number) => onChangeVisible(_layoutGrids.length - 1 - index)
    const _onClickSub = (index: number) => onClickSub(_layoutGrids.length - 1 - index)
    const _onChangeLayoutPattern = (index: number) => onChangeLayoutPattern(_layoutGrids.length - 1 - index)
    const _onChangeSectionSize = (index: number) => onChangeSectionSize(_layoutGrids.length - 1 - index)
    const _onChangeSectionSizeVar = (index: number) => onChangeSectionSizeVar(_layoutGrids.length - 1 - index)
    const _onChangeSolidPaint = (index: number) => onChangeSolidPaint(_layoutGrids.length - 1 - index)
    const _onChangeAlign = (index: number) => onChangeAlign(_layoutGrids.length - 1 - index)
    const _onChangeCount = (index: number) => onChangeCount(_layoutGrids.length - 1 - index)
    const _onChangeCountVar = (index: number) => onChangeCountVar(_layoutGrids.length - 1 - index)
    const _onChangeGutterSize = (index: number) => onChangeGutterSize(_layoutGrids.length - 1 - index)
    const _onChangeGutterSizeVar = (index: number) => onChangeGutterSizeVar(_layoutGrids.length - 1 - index)
    const _onChangeOffset = (index: number) => onChangeOffset(_layoutGrids.length - 1 - index)
    const _onChangeOffsetVar = (index: number) => onChangeOffsetVar(_layoutGrids.length - 1 - index)
    const _onChangeModalVisible = (index: number) => onChangeModalVisible(_layoutGrids.length - 1 - index)

    return (
        <SimpleDrag<Wukong.DocumentProto.ILayoutGrid>
            selectedIndexList={selectedIndexes}
            items={_layoutGrids}
            onSelectChange={_onSelectChange}
            onDragDone={_onDropDone}
            onMouseDown={(e) => e.stopPropagation()}
            className={'mb-1'}
            dataTestIds={{
                simpleDrag: 'layout-grid-list',
            }}
        >
            {_layoutGrids.map((layoutGrid, index) => (
                <SimpleDrag.Item
                    key={index}
                    itemIndex={index}
                    dataTestIds={{ item: `grid-${index}`, dragIcon: `grid-${index}-icon` }}
                >
                    <GridComponet
                        key={index}
                        variables={layoutGrids?.variables ?? []}
                        layoutGrid={layoutGrid}
                        colorSpace={colorSpace}
                        isOpen={_isOpen(index)}
                        selected={selectedIndexes.includes(index)}
                        onChangeVisible={_onChangeVisible(index)}
                        onClickSub={() => _onClickSub(index)}
                        onChangeLayoutPattern={_onChangeLayoutPattern(index)}
                        onChangeSectionSize={_onChangeSectionSize(index)}
                        onChangeSectionSizeVar={_onChangeSectionSizeVar(index)}
                        onChangeSolidPaint={_onChangeSolidPaint(index)}
                        onChangeAlign={_onChangeAlign(index)}
                        onChangeCount={_onChangeCount(index)}
                        onChangeCountVar={_onChangeCountVar(index)}
                        onChangeGutterSize={_onChangeGutterSize(index)}
                        onChangeGutterSizeVar={_onChangeGutterSizeVar(index)}
                        onChangeOffset={_onChangeOffset(index)}
                        onChangeOffsetVar={_onChangeOffsetVar(index)}
                        onChangeModalVisible={_onChangeModalVisible(index)}
                    />
                </SimpleDrag.Item>
            ))}
        </SimpleDrag>
    )
}
