export function downloadUrl(url: string, filename: string) {
    const element = document.createElement('a')
    element.href = url
    element.download = filename
    document.body.appendChild(element)
    element.setAttribute('style', 'display: none')
    element.click()
    element.remove()
}

export function downloadBlob(data: Blob, filename: string) {
    const url = window.URL.createObjectURL(data)
    downloadUrl(url, filename)
    setTimeout(function () {
        return window.URL.revokeObjectURL(url)
    }, 1000)
}

export const downloadString = (str: string, filename: string) => {
    downloadBlob(new Blob([str], { type: 'text/plain' }), filename)
}

export const downloadPng = (bytes: Uint8Array, filename: string) => {
    downloadBlob(new Blob([bytes], { type: 'image/png' }), filename)
}

export const downloadBuffer = (bytes: Uint8Array, filename: string) => {
    downloadBlob(new Blob([bytes], { type: 'application/octet-stream' }), filename)
}
