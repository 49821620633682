/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { FC } from 'react'
import { useDevModeAvailabilityVerificationService } from '../../../../../main/app-context'
import { useViewState } from '../../../../../view-state-bridge'
import { LayerPanelLayout } from '../dev-mode-layer-panel/layout'
import { DevModePagePanel } from '../dev-mode-page-panel'
import styles from './index.module.less'
import VPageItem = Wukong.DocumentProto.VPageItem

export interface PagePanelContainerProps {
    dataTestId?: string
}
export const DevModePagePanelContainer: FC<PagePanelContainerProps> = ({ dataTestId }) => {
    const { pages } = useViewState('documentPageList', { pages: [] })
    const devModeAvailablieyVerificationService = useDevModeAvailabilityVerificationService()
    const isDevModeAccessible = devModeAvailablieyVerificationService.useZustandStore.use.isDevModeAvailable()

    return pages.length ? (
        <div className={styles['page-panel-container']} data-testid={dataTestId} style={{ display: 'flex' }}>
            <DevModePagePanel pages={pages as VPageItem[]} />
            {isDevModeAccessible && <LayerPanelLayout />}
        </div>
    ) : null
}
