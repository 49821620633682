import constate from 'constate'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAsync } from 'react-use'
import { fileDialog, WKToast } from '../../../../../../ui-lib/src'
import { PluginVO } from '../../../../kernel/interface/plugin'
import { GetOrganizations } from '../../../../kernel/request/organizations'
import { useAppContext, usePluginService } from '../../../../main/app-context'
import { useDocInfoContext } from '../../../context/top-area-context'
import { fetchUnpublishPlugin } from './plugin-request'
import { saveTemplatePlugin } from './template'
import { LocalPlugin, PluginLocalManifestFileError, PluginManifest } from './template/type'
import { usePluginDevUserConfig } from './use-plugin-dev-user-config'
import { translation } from './use-plugin-development.translation'
import { fileSep } from './util'

export enum PluginDevStatus {
    Main,
    Create,
    CreateSuccess,
    Publish,
    PublishSuccess,
    EditorPublishInfo,
    Republish,
    Permission,
}

function usePluginDevelopmentContext() {
    const pluginService = usePluginService()
    const { docData } = useDocInfoContext()
    const [pluginDevStatus, setPluginDevStatus] = useState<PluginDevStatus>(PluginDevStatus.Main)
    const [createdPluginPath, setCreatedPluginPath] = useState<string>('') // 创建成功后插件的路径
    const [currentPublishPluginLocalPath, setCurrentPublishPluginLocalPath] = useState<string>('') // 发布前编辑正在编辑的插件 path
    const [currentEditorPublishInfoPlugin, setCurrentEditorPublishInfoPlugin] = useState<LocalPlugin | null>(null) // 正在编辑已发布的插件
    const [publishSuccessPlugin, setPublishSuccessPlugin] = useState<PluginVO | null>(null)
    const [currentEditorPermissionPlugin, setCurrentEditorPermissionPlugin] = useState<PluginVO | null>(null) // 正在编辑权限的插件
    const { value: organizations } = useAsync(() => new GetOrganizations().start(), [])
    const currentOrganization = organizations?.find((o) => o.id === docData?.orgId)
    const currentUserId = useAppContext().userId

    const { pluginDevUseHotReload, pluginDevUseSandbox, setPluginDevUseHotReload, setPluginDevUseSandbox } =
        usePluginDevUserConfig()

    const localPlugins = pluginService.states.use.localPlugins()
    const publishedPlugins = pluginService.states.use.editablePublishedPlugins()
    const localPluginsWithPublishInfo = useMemo(() => {
        const mergedPlugins = mergePublishedAndLocalPlugins(publishedPlugins, localPlugins)
        return mergedPlugins.sort((a, b) => {
            const aLastEditTime = Math.max(a.lastEditTime, a.publishInfo?.lastEditTime ?? 0)
            const bLastEditTime = Math.max(b.lastEditTime, b.publishInfo?.lastEditTime ?? 0)
            return bLastEditTime - aLastEditTime
        })
    }, [localPlugins, publishedPlugins])

    useEffect(() => {
        pluginService.fetchEditablePublishedPlugins()
    }, [pluginService])

    const createNewPlugin = useCallback(
        async (name: string) => {
            if (!name) {
                return
            }
            const createdLocalPlugin = await saveTemplatePlugin(name, docData?.orgId ?? '')
            return createdLocalPlugin
        },
        [docData]
    )

    const handleManifestError = useCallback((error: PluginLocalManifestFileError) => {
        switch (error) {
            case PluginLocalManifestFileError.NotFoundError:
                WKToast.error(translation('NoFoundManifestError'))
                break
            case PluginLocalManifestFileError.ReadFileError:
                WKToast.error(translation('ReadFileError'))
                break
            case PluginLocalManifestFileError.NoNameError:
                WKToast.error(translation('NoNameError'))
                break
            case PluginLocalManifestFileError.NoMainError:
                WKToast.error(translation('NoMainError'))
                break
            case PluginLocalManifestFileError.NoIdError:
                WKToast.error(translation('NoIdError'))
                break
            case PluginLocalManifestFileError.NoUiError:
                WKToast.error(translation('NoUiError'))
                break
            case PluginLocalManifestFileError.PermissionDeniedError:
                WKToast.error(translation('PermissionDeniedError'))
                break
        }
    }, [])

    const importPluginFromFile = useCallback(
        async (file: File, manifestPath: string) => {
            const { manifest, error } = assertManifest(await file.text())
            if (error) {
                handleManifestError(error)
                return null
            }

            if (!manifest) {
                throw new Error()
            }

            const importPlugin: LocalPlugin = {
                id: manifest.id,
                name: manifest.name,
                path: manifestPath.replace(`${fileSep()}manifest.json`, ''),
                manifest,
                lastEditTime: Date.now(),
            }

            // 相同文件路径，如果缺失本地版本则视为导入其本地版本（删除旧的），否则禁止重复导入
            const samePathPlugin = localPlugins.find((plugin) => plugin.path === importPlugin.path)
            if (samePathPlugin) {
                if (samePathPlugin.localManifestFileError === PluginLocalManifestFileError.NotFoundError) {
                    pluginService.states
                        .getState()
                        .updateLocalPlugins(localPlugins.filter((p) => p.path !== samePathPlugin.path))
                } else {
                    WKToast.error(translation('DuplicatePluginError'))
                    return null
                }
            }

            pluginService.states
                .getState()
                .updateLocalPlugins([...pluginService.states.getState().localPlugins, importPlugin])
            WKToast.show(translation('ImportSuccess'))
            return importPlugin
        },
        [handleManifestError, pluginService, localPlugins]
    )

    const importCustomPlugin = useCallback(async () => {
        try {
            const { file, path } = await readSingleJsonFileDialog()

            if (!file) {
                return null
            }

            if (file.name !== 'manifest.json') {
                WKToast.error(translation('OnlyManifestJsonError'))
                return null
            }

            return await importPluginFromFile(file, path ?? '')
        } catch (e) {
            console.error(e)
            WKToast.error(translation('ReadFileError'))
            return null
        }
    }, [importPluginFromFile])

    const runPluginPublishVersion = useCallback(
        async (plugin: LocalPlugin) => {
            if (!plugin.publishInfo) {
                return
            }

            pluginService.runPublishedPluginForDevelopment(plugin.publishInfo)
        },
        [pluginService]
    )

    const runPluginLocalVersion = useCallback(
        async (plugin: LocalPlugin) => {
            if (!plugin.path || plugin.localManifestFileError) {
                return
            }
            pluginService.runLocalPlugin(plugin.path)
        },
        [pluginService]
    )

    const runCustomPlugin = useCallback(
        async (plugin: LocalPlugin) => {
            if (plugin.publishInfo?.published) {
                runPluginPublishVersion(plugin)
            } else {
                runPluginLocalVersion(plugin)
            }
        },
        [runPluginLocalVersion, runPluginPublishVersion]
    )

    const unpublishPlugin = useCallback(
        async (plugin: LocalPlugin) => {
            await fetchUnpublishPlugin(plugin.id)

            pluginService.fetchEditablePublishedPlugins()
            pluginService.fetchPublishedPlugins()
            pluginService.states
                .getState()
                .updateLocalPlugins(
                    localPlugins.map((p) => (p.path === plugin.path ? { ...p, lastEditTime: Date.now() } : p))
                )

            // 取消发布后将发布信息作为草稿
            if (plugin.publishInfo && plugin.path) {
                const { base64Data, format } = await getBase64FromUrl(plugin.publishInfo.iconUrl)
                const publishDrafts = pluginService.states.getState().pluginPublishDrafts
                pluginService.states.getState().updatePluginPublishDrafts({
                    ...publishDrafts,
                    [plugin.path]: {
                        name: plugin.publishInfo.name,
                        id: plugin.publishInfo.id,
                        path: plugin.path,
                        iconInfo: {
                            imageData: base64Data,
                            format,
                        },
                    },
                })
            }
        },
        [pluginService, localPlugins]
    )

    const removeLocalPlugin = useCallback(
        (plugin: LocalPlugin) => {
            pluginService.states
                .getState()
                .updateLocalPlugins(pluginService.states.getState().localPlugins.filter((p) => p.path !== plugin.path))
        },
        [pluginService]
    )

    const openLocalFileFolder = useCallback((plugin: LocalPlugin) => {
        if (plugin.path) {
            window.localBridge?.openLocalFileFolder?.(`${plugin.path}${fileSep()}manifest.json`)
        }
    }, [])

    const openPublishDialog = useCallback(
        (plugin: LocalPlugin) => {
            if (!plugin.path) {
                return
            }
            const publishDrafts = pluginService.states.getState().pluginPublishDrafts
            if (!publishDrafts[plugin.path]) {
                pluginService.states.getState().updatePluginPublishDrafts({
                    ...publishDrafts,
                    [plugin.path]: {
                        name: plugin.name,
                        id: plugin.id,
                        path: plugin.path!,
                    },
                })
            } else {
                pluginService.states.getState().updatePluginPublishDrafts({
                    ...publishDrafts,
                    [plugin.path]: {
                        ...publishDrafts[plugin.path],
                        id: plugin.id,
                    },
                })
            }

            setCurrentPublishPluginLocalPath(plugin.path)
            setPluginDevStatus(PluginDevStatus.Publish)
        },
        [pluginService, setCurrentPublishPluginLocalPath, setPluginDevStatus]
    )

    const openEditorPublishInfoDialog = useCallback(
        (plugin: LocalPlugin) => {
            setCurrentEditorPublishInfoPlugin(plugin)
            setPluginDevStatus(PluginDevStatus.EditorPublishInfo)
        },
        [setCurrentEditorPublishInfoPlugin, setPluginDevStatus]
    )

    const openRepublishDialog = useCallback(
        (plugin: LocalPlugin) => {
            setCurrentEditorPublishInfoPlugin(plugin)
            setPluginDevStatus(PluginDevStatus.Republish)
        },
        [setCurrentEditorPublishInfoPlugin, setPluginDevStatus]
    )

    const openPublishSuccessDialog = useCallback(
        (pluginPublishInfo: PluginVO) => {
            setPublishSuccessPlugin(pluginPublishInfo)
            setPluginDevStatus(PluginDevStatus.PublishSuccess)
        },
        [setPublishSuccessPlugin, setPluginDevStatus]
    )

    const openEditorPermissionDialog = useCallback(
        (pluginPublishInfo: PluginVO | null) => {
            if (!pluginPublishInfo) {
                setPluginDevStatus(PluginDevStatus.Main)
                return
            }
            setCurrentEditorPermissionPlugin(pluginPublishInfo)
            setPluginDevStatus(PluginDevStatus.Permission)
        },
        [setCurrentEditorPermissionPlugin, setPluginDevStatus]
    )

    return {
        localPluginsWithPublishInfo,
        pluginDevStatus,
        setPluginDevStatus,
        createNewPlugin,
        importCustomPlugin,
        runCustomPlugin,
        runPluginPublishVersion,
        runPluginLocalVersion,
        unpublishPlugin,
        removeLocalPlugin,
        openLocalFileFolder,
        currentPublishPluginLocalPath,
        createdPluginPath,
        setCreatedPluginPath,
        currentEditorPublishInfoPlugin,
        openPublishDialog,
        openEditorPublishInfoDialog,
        openRepublishDialog,
        openPublishSuccessDialog,
        currentEditorPermissionPlugin,
        openEditorPermissionDialog,
        publishSuccessPlugin,
        setPublishSuccessPlugin,
        currentOrganization,
        currentUserId,
        pluginDevUseHotReload,
        pluginDevUseSandbox,
        setPluginDevUseHotReload,
        setPluginDevUseSandbox,
    }
}

export const [PluginDevelopmentProvider, usePluginDevelopment] = constate(usePluginDevelopmentContext)

const readSingleJsonFileDialog = async (): Promise<{ file?: File; path?: string }> => {
    if (window.localBridge?.localFileDialog) {
        const fileInfos = await window.localBridge.localFileDialog({
            properties: ['openFile'],
            filters: [{ name: 'Json Files', extensions: ['json'] }],
        })
        if (fileInfos.length !== 1) {
            return {}
        }
        const file = new File([fileInfos[0].buffer], fileInfos[0].name, {
            lastModified: fileInfos[0].lastModified,
        })
        return { file, path: fileInfos[0].path }
    } else {
        //TODO(lizhaohui): 兼容 1.1.7 及以前的版本，在线上最小版本为 1.2.0 时可删除
        const file = await fileDialog({
            accept: '.json',
            strict: true,
        })
        return { file, path: (file as any)?.path }
    }
}

// 合并本地插件和已发布插件的信息：1. 本地插件增加对应 id 的发布信息，2. 已发布的插件信息如没有本地插件则转成无本地插件的已发布插件（无 path）
const mergePublishedAndLocalPlugins = (publishedPlugins: PluginVO[], localPlugins: LocalPlugin[]): LocalPlugin[] => {
    const publishedPluginsMap = new Map<string, PluginVO>(publishedPlugins.map((plugin) => [plugin.id, plugin]))

    const localPluginIds = new Set<string>()
    const localPluginsWithPublishInfo = localPlugins.map((plugin) => {
        localPluginIds.add(plugin.id)
        const publishedInfo = publishedPluginsMap.get(plugin.id)
        if (publishedInfo) {
            return {
                ...plugin,
                publishInfo: {
                    ...publishedInfo,
                },
            } as LocalPlugin
        }
        return plugin
    })

    const noLocalPublishedPlugins: LocalPlugin[] = []
    publishedPluginsMap.forEach((publishedPluginInfo, id) => {
        if (!localPluginIds.has(id)) {
            noLocalPublishedPlugins.push({
                id: publishedPluginInfo.id,
                name: publishedPluginInfo.name,
                publishInfo: {
                    ...publishedPluginInfo,
                },
                lastEditTime: publishedPluginInfo.lastEditTime,
            })
        }
    })

    return [...localPluginsWithPublishInfo, ...noLocalPublishedPlugins]
}

export async function readLocalFileAsString(path: string) {
    const data = await window.localBridge!.readLocalFile!(path)
    const file = new Blob([data])
    return file.text()
}

interface ReadManifestResult {
    manifest?: PluginManifest
    error?: PluginLocalManifestFileError
}

export const getLocalPluginManifest = async (manifestFilepath: string): Promise<ReadManifestResult> => {
    const data = await window.localBridge!.readLocalFile!(manifestFilepath).catch((e) => {
        if (e instanceof Error && e.message?.includes('EPERM')) {
            return 'EPERM'
        }
        return null
    })

    if (data === 'EPERM') {
        return { error: PluginLocalManifestFileError.PermissionDeniedError }
    }

    if (!data) {
        return { error: PluginLocalManifestFileError.NotFoundError }
    }

    const textContent = await new Blob([data]).text().catch(() => {
        return null
    })

    if (!textContent) {
        return { error: PluginLocalManifestFileError.ReadFileError }
    }

    return assertManifest(textContent)
}

export const assertManifest = (inputManifest: string | PluginManifest): ReadManifestResult => {
    try {
        const manifest = typeof inputManifest === 'string' ? JSON.parse(inputManifest) : inputManifest

        if (!manifest.name) {
            return { error: PluginLocalManifestFileError.NoNameError }
        }
        if (!manifest.main) {
            return { error: PluginLocalManifestFileError.NoMainError }
        }
        if (!manifest.id) {
            return { error: PluginLocalManifestFileError.NoIdError }
        }
        if (!manifest.ui) {
            return { error: PluginLocalManifestFileError.NoUiError }
        }

        return { manifest }
    } catch (e) {
        return { error: PluginLocalManifestFileError.ReadFileError }
    }
}

export const getLocalPluginData = async (path: string) => {
    try {
        const manifest = JSON.parse(await readLocalFileAsString(`${path}${fileSep()}manifest.json`)) as PluginManifest
        const bundledCode = await getLocalPluginBundledCode(path, manifest)

        return { manifest, codeContent: bundledCode }
    } catch (e) {
        console.error(e)
        return null
    }
}

export const getLocalPluginBundledCode = async (path: string, manifest: PluginManifest) => {
    let bundledCode = ''
    if (manifest.ui) {
        const uiCode = await readLocalFileAsString(`${path}${fileSep()}${manifest.ui}`)
        bundledCode += `const __html__ = ${JSON.stringify(uiCode)};`
    }
    bundledCode += await readLocalFileAsString(`${path}${fileSep()}${manifest.main}`)
    return bundledCode
}

export const transImageFileToBase64 = (file: File | Blob) => {
    return new Promise<{ base64Data: string; format: string }>((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => {
            const result = reader.result as string
            const base64Data = result.split(',')[1]
            const format = result.split(';')[0].split('/')[1]
            resolve({ base64Data, format })
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
    })
}

const getBase64FromUrl = async (url: string) => {
    const base64Data = await fetch(url, { method: 'GET' }).then(async (res) => {
        const blob = await res.blob()
        return await transImageFileToBase64(blob)
    })
    return base64Data
}
