import { getTranslationValue } from '../../../../../../../../../util/src/i18n'

export const zhTranslation = {
    FillValue: '填充',
    FixedValue: '固定',
    HugValue: '包围',
    FillContainer: '填充容器',
    FixedWidth: '固定宽度',
    FixedHeight: '固定高度',
    HugContents: '包围内容',
    Mixed: '多个值',
    AbsolutePosition: '绝对定位',
    DisableFillContainerTooltip: '全选所有子图层后，可统一设置为填充容器',
} as const

export const enTranslation = {
    FillValue: 'Fill',
    FixedValue: 'Fixed',
    HugValue: 'Hug',
    FillContainer: 'Fill container',
    FixedWidth: 'Fixed width',
    FixedHeight: 'Fixed height',
    HugContents: 'Hug contents',
    Mixed: 'Mixed',
    AbsolutePosition: 'Absolute position',
    DisableFillContainerTooltip: 'Select all children to set to fill',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
