import { SVGAttributes } from 'react'

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconCommentEmpty = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
            d="M5 12.5C5 8.35791 8.35785 5 12.5 5L12.5112 5C16.6534 5 20 8.35791 20 12.5L20 12.5112C20 16.6533 16.6422 20 12.5 20L5 20L5 12.5ZM12.5 19C14.296 19 15.8284 18.3669 17.0969 17.1011C18.3657 15.835 19 14.3049 19 12.5112L19 12.5C19 10.7039 18.367 9.17163 17.1011 7.90308C15.835 6.63428 14.3051 6 12.5112 6L12.5 6C10.7051 6 9.17303 6.63452 7.90381 7.90381C6.63458 9.1731 6 10.7051 6 12.5L6 19L12.5 19Z"
            fillRule="evenodd"
            fill="rgb(26, 29, 37)"
            fillOpacity="1"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconCloud = () => (
    <svg
        className="icon-cloud"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="icon-cloud-svg"
    >
        <path
            d="M8.73352 19.7256L17.3335 4.83008L19.0656 5.83008L17.6497 8.28247C18.4519 8.69849 19 9.53687 19 10.5029C19 10.7756 18.9564 11.0381 18.8757 11.2837C20.1248 11.8181 21 13.0583 21 14.5029C21 16.2661 19.6962 17.7249 18 17.9675L18 18.0037L12.0371 18.0037L10.4656 20.7256L8.73352 19.7256ZM11.5 5.00293C12.7591 5.00293 13.9194 5.42603 14.8464 6.1377L7.99567 18.0037L7 18.0037C4.79083 18.0037 3 16.2129 3 14.0037C3 12.1353 4.28101 10.5662 6.0127 10.1265C6.20618 7.2644 8.58899 5.00293 11.5 5.00293Z"
            fillRule="evenodd"
            fill="rgb(69, 73, 89)"
            fillOpacity="1"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconHyperlink = (props: SVGAttributes<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <path
            transform="matrix(1 0 0 1 3.65704 3.65698)"
            d="M6.414 3.58569L8.53576 1.46411C9.51196 0.488037 10.6904 0 12.0709 0C13.4515 0 14.6299 0.488037 15.6061 1.46436L15.6103 1.46851C16.5865 2.44482 17.074 3.6228 17.0726 5.00269C17.0712 6.3811 16.5824 7.55835 15.6063 8.53467L13.4847 10.6565L12.0704 9.24243L14.192 7.1206C14.7782 6.53418 15.0717 5.82764 15.0726 5.00049C15.0734 4.17383 14.7812 3.46777 14.1961 2.88281L14.1919 2.87842C13.6062 2.29272 12.8992 2 12.0709 2C11.2426 2 10.5356 2.29272 9.94989 2.87842L7.82812 5L6.414 3.58569ZM9.94897 5.7063L11.3632 7.1206L7.12054 11.363L5.70636 9.94897L9.94897 5.7063ZM1.46423 8.53564L3.58575 6.41382L5.00006 7.82788L2.87848 9.94971C2.29285 10.5354 2 11.2424 2 12.0708C2.00006 12.8989 2.29291 13.606 2.8786 14.1916L2.88281 14.196C3.46796 14.781 4.17389 15.0732 5.00061 15.0725C5.82764 15.0715 6.5343 14.7781 7.1206 14.1919L9.24237 12.0703L10.6566 13.4844L8.53473 15.6062C7.55847 16.5823 6.38116 17.071 5.00268 17.0725C3.6228 17.0737 2.44482 16.5864 1.46857 15.6101L1.46436 15.606C0.488159 14.6296 6.10351e-05 13.4514 0 12.0708C0 10.6902 0.488037 9.51172 1.46423 8.53564Z"
            fillRule="evenodd"
            fill="rgb(196, 196, 196)"
        />
        <path
            transform="matrix(1 0 0 1 3.65704 3.65698)"
            d="M8.53576 1.46411L6.414 3.58569L7.82812 5L9.94989 2.87842C10.5356 2.29272 11.2426 2 12.0709 2C12.8992 2 13.6062 2.29272 14.1919 2.87842L14.1961 2.88281C14.7812 3.46777 15.0734 4.17383 15.0726 5.00049C15.0717 5.82764 14.7782 6.53418 14.192 7.1206L12.0704 9.24243L13.4847 10.6565L15.6063 8.53467C16.5824 7.55835 17.0712 6.3811 17.0726 5.00269C17.074 3.6228 16.5865 2.44482 15.6103 1.46851L15.6061 1.46436C14.6299 0.488037 13.4515 0 12.0709 0C10.6904 0 9.51196 0.488037 8.53576 1.46411ZM15.4289 7.18618Q16.0713 6.22596 16.0726 5.00167Q16.0713 6.22596 15.4289 7.18618ZM14.9042 2.1766L14.9181 2.19091Q14.9344 2.20721 14.9505 2.22366Q14.9276 2.20002 14.9042 2.1766ZM9.24187 6.41341L5.70636 9.94897L7.12054 11.363L11.3632 7.1206L9.94897 5.7063L9.24187 6.41341ZM9.94895 7.12055L9.94895 7.12055ZM2.8787 7.12098L1.46423 8.53564C0.488037 9.51172 0 10.6902 0 12.0708C6.10351e-05 13.4514 0.488159 14.6296 1.46436 15.606L1.46857 15.6101C2.44482 16.5864 3.6228 17.0737 5.00268 17.0725C6.38116 17.071 7.55847 16.5823 8.53473 15.6062L10.6566 13.4844L9.24237 12.0703L7.1206 14.1919C6.5343 14.7781 5.82764 15.0715 5.00061 15.0725C4.17389 15.0732 3.46796 14.781 2.88281 14.196L2.8786 14.1916C2.29291 13.606 2.00006 12.8989 2 12.0708C2 11.2424 2.29285 10.5354 2.87848 9.94971L5.00006 7.82788L3.58575 6.41382L2.8787 7.12098ZM2.16628 14.8937Q1.00691 13.7342 1.00004 12.0902Q1.00698 13.7342 2.17149 14.8988L2.16082 14.8879Q3.33997 16.067 4.98218 16.0725Q3.33969 16.0671 2.1757 14.903L2.16628 14.8937ZM7.82762 14.8991Q6.65574 16.0708 5.00162 16.0725Q6.65594 16.0705 7.82762 14.8991Z"
            fillRule="evenodd"
            fill="rgb(69, 73, 89)"
        />
    </svg>
)
