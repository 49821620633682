import { Wukong } from '@wukong/bridge-proto'
import {} from 'lodash-es'
import { createSelectors, createStore, generateRouterPath, RouteToken } from '../../../../../../util/src'
import { createSwitchTask } from '../../../../../../util/src/abort-controller/signal-task'
import { TraceableAbortSignal } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { NetworkService } from '../../../../document/synergy/network-service'
import { environment } from '../../../../environment'
import { LibraryId } from '../../../../kernel/interface/component-style-library-id'
import type { DocID } from '../../../../kernel/interface/type'
import { ResourceType } from '../../../../kernel/interface/type'
import {
    GetDefaultLibrarySubscription,
    SubscribeDefaultLibrary,
    UnSubscribeDefaultLibrary,
} from '../../../../kernel/request/library-subscription'
import { LibraryResourceDownloader } from '../../../../share/component-style-library/service/library-resource-downloader'
import { RemoteLibraryNodeThumbnailService } from '../../../../share/component-style-library/service/remote-library-thumbnail-service'
import type { SpaceNotifyStore } from '../../zustand/types'

export enum TeamDefaultLibraryRoutePathType {
    Home = 'home',
    Detail = 'detail',
}

// 团队/草稿箱默认组件库
export class DefaultLibraryService {
    public states = createSelectors(
        createStore<{
            routePathConfig:
                | { routePath: TeamDefaultLibraryRoutePathType.Home }
                | {
                      routePath: TeamDefaultLibraryRoutePathType.Detail
                      libraryId: LibraryId
                      docId: DocID
                  }
            subscribeLibraryIds: LibraryId[] | undefined
        }>(
            () => ({
                routePathConfig: { routePath: TeamDefaultLibraryRoutePathType.Home },
                subscribeLibraryIds: undefined,
            }),
            environment.isDev
        )
    )

    public networkService: NetworkService
    public libraryResourceDownloader: LibraryResourceDownloader
    public libraryThumbnailService: RemoteLibraryNodeThumbnailService

    constructor(
        private readonly signal: TraceableAbortSignal,
        private readonly spaceNotifyStore: SpaceNotifyStore,
        private readonly resourceId: string,
        private readonly resourceType: ResourceType
    ) {
        this.networkService = new NetworkService(signal)
        this.libraryResourceDownloader = new LibraryResourceDownloader(this.networkService, this.signal)
        this.libraryThumbnailService = new RemoteLibraryNodeThumbnailService(this.libraryResourceDownloader)

        this.initStates(this.signal)
    }

    public destroy() {
        this.libraryResourceDownloader.destroy()
        this.libraryThumbnailService.destroy()
    }

    private initStates = (signal: TraceableAbortSignal) => {
        const updateSubscribeLibraryIds = createSwitchTask(signal, async (signal) => {
            try {
                const value = await new GetDefaultLibrarySubscription(
                    this.resourceId,
                    this.resourceType
                ).startWithSignal(signal)
                this.states.setState({ subscribeLibraryIds: value.map(({ libraryId }) => `${libraryId}`) })
            } catch (e) {
                // noop
            }
        })

        this.spaceNotifyStore.instance.addRelationChangeCallbackWithSignal(signal, (body) => {
            if (body.relation?.relation !== Wukong.NotifyProto.Relation.DEFAULT_LIBRARY_SUBSCRIPTION) {
                return
            }

            if (
                !(
                    (body.relation?.one?.entityType == Wukong.NotifyProto.EntityType.TEAM &&
                        body.relation.one.entityId === this.resourceId &&
                        this.resourceType === ResourceType.Team) ||
                    (body.relation?.one?.entityType == Wukong.NotifyProto.EntityType.FOLDER &&
                        body.relation.one.entityId === this.resourceId &&
                        this.resourceType === ResourceType.Folder)
                )
            ) {
                return
            }

            if (
                !(
                    body.relation?.another?.entityType === Wukong.NotifyProto.EntityType.LIBRARY &&
                    !!body.relation?.another.entityId
                )
            ) {
                return
            }

            updateSubscribeLibraryIds()
        })

        updateSubscribeLibraryIds()
    }

    public goBackHome = () => {
        this.states.setState({
            routePathConfig: { routePath: TeamDefaultLibraryRoutePathType.Home },
        })
    }

    public openDetail = (params: { libraryId: LibraryId; docId: DocID }) => {
        this.states.setState({
            routePathConfig: { routePath: TeamDefaultLibraryRoutePathType.Detail, ...params },
        })
    }

    public jump2DetailDoc = (docId: DocID) => {
        window.open(`/${generateRouterPath(RouteToken.File)}/${docId}`, '_blank')
    }

    public subscribeLibrary = (libraryId: LibraryId) => {
        new SubscribeDefaultLibrary(this.resourceId, this.resourceType, libraryId).start()
    }

    public unSubscribeLibrary = (libraryId: LibraryId) => {
        new UnSubscribeDefaultLibrary(this.resourceId, this.resourceType, libraryId).start()
    }
}
