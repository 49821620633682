/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-restricted-imports */
import {
    BatchExportConfigsCommand,
    NodePreviewSubscribeCommand,
    NodePreviewUnsubscribeCommand,
    UpdateBatchExportPopupStateCommandWasmCall,
} from '@wukong/bridge-proto'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounce, useDeepCompareEffect, useWindowSize } from 'react-use'
import { WKToast } from '../../../../../../../ui-lib/src'
import { CommitType } from '../../../../../document/command/commit-type'
import { useCommand, useCopyCheckService } from '../../../../../main/app-context'
import { useRightPanelWidth } from '../../../../../main/layout/layout-context'
import { useViewState } from '../../../../../view-state-bridge'
import { useStableSelected } from '../../../../utils/use-selected'
import { Position } from '../../../comment/type'
import { BatchExportItemContent } from '../batch-export-item'
import { singleNodeMultiExportConfig } from '../util'
import { translation } from './use-batch-export.translation'
import { useExportByConfigs } from './use-export'

const useBatchExportModalPosition = (): Position => {
    const computedWidth = useRightPanelWidth()
    const { width } = useWindowSize()
    return { top: 8, left: width - computedWidth - 9 }
}

const useBatchExportModal = () => {
    const command = useCommand()
    const batchExportPopupV2 = useViewState('batchExportPopupState')?.show ?? false
    const isBatchExportModalOpen = batchExportPopupV2
    const [isOpen, setIsOpen] = useState(false)

    const close = useCallback(() => {
        command.invokeBridge(CommitType.Noop, UpdateBatchExportPopupStateCommandWasmCall, {
            value: false,
        })
    }, [command])

    const copyCheckService = useCopyCheckService()
    const copyHasBeenProhibited = copyCheckService.useZustandStore.use.copyHasBeenProhibited()
    useEffect(() => {
        if (!isBatchExportModalOpen) {
            setIsOpen(isBatchExportModalOpen)
            return
        }
        if (copyHasBeenProhibited) {
            WKToast.show(translation('ProhibitExport'))
            close()
        } else {
            setIsOpen(true)
        }
    }, [isBatchExportModalOpen, close, copyHasBeenProhibited])

    return {
        isOpen,
        close,
    } as const
}

const useBatchExportItems = (isOpen: boolean): BatchExportItemContent[] => {
    const command = useCommand()
    const [init, setInit] = useState<BatchExportItemContent[]>([])

    useEffect(() => {
        if (isOpen) {
            const res = command.DEPRECATED_invokeBridge(BatchExportConfigsCommand)
            if (res.configs) {
                const nameCount = new Map()
                const configs = []
                for (const { node, name, width, height, exportSettings } of res.configs) {
                    if (!node || !name || !width || !height || !exportSettings) {
                        continue
                    }
                    const newConfigs = singleNodeMultiExportConfig(nameCount, [node], name, exportSettings)
                    for (const config of newConfigs) {
                        const content: BatchExportItemContent = { ...config, width: width!, height: height! }
                        configs.push(content)
                    }
                }
                setInit(configs)
            }
        }
    }, [command, isOpen])

    useEffect(() => {
        if (!isOpen) {
            setInit([])
        }
    }, [isOpen])

    return init
}

const useBatchExportItemsV2 = (isOpen: boolean): BatchExportItemContent[] => {
    const batchExportState = useViewState('batchExportPopupState', {
        show: false,
        configs: [],
    })

    const itemConfigs = useMemo(() => {
        if (!isOpen) {
            return []
        }

        const nameCount = new Map()
        const configs = []
        for (const { node, name, width, height, exportSettings } of batchExportState.configs) {
            if (!node || !name || !width || !height || !exportSettings) {
                continue
            }
            const newConfigs = singleNodeMultiExportConfig(nameCount, [node], name, exportSettings)
            for (const config of newConfigs) {
                const content: BatchExportItemContent = { ...config, width: width!, height: height! }
                configs.push(content)
            }
        }
        return configs
    }, [batchExportState.configs, isOpen])

    const [debouncedConfigs, setDebouncedConfigs] = useState<BatchExportItemContent[]>([])

    useDebounce(
        () => {
            setDebouncedConfigs(itemConfigs)
        },
        150,
        [itemConfigs]
    )
    return debouncedConfigs
}

export function useBatchExport() {
    const { isOpen, close } = useBatchExportModal()
    const position = useBatchExportModalPosition()
    const command = useCommand()
    const contents = useBatchExportItemsV2(isOpen)
    const { cleanChecked, replace, ...rest } = useStableSelected(
        contents,
        (c) => `${c.node.join(',')}-${c.fileName}-${c.format}-${c.type}-${c.value}`
    )
    const exportByConfigs = useExportByConfigs<BatchExportItemContent>()

    const exportChecked = useCallback(() => {
        const configs = Array.from(rest.selected)
        exportByConfigs(configs)
        close()
    }, [close, exportByConfigs, rest.selected])

    useDeepCompareEffect(() => {
        if (isOpen) {
            command.DEPRECATED_invokeBridge(NodePreviewSubscribeCommand)
        } else {
            command.DEPRECATED_invokeBridge(NodePreviewUnsubscribeCommand)
        }
    }, [isOpen, contents])

    return {
        isOpen,
        close,
        position,
        contents,
        exportChecked,
        ...rest,
    } as const
}
