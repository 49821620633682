/* eslint-disable no-restricted-imports */
import { LayerPanelUpdateLockCommand, LayerPanelUpdateVisibleCommand, Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { FC, MouseEventHandler, useCallback, useMemo } from 'react'
import { IconEyes, IconEyesClose, IconLock, IconUnLock } from '../../../../../../ui-lib/src'
import { CommitType } from '../../../../document/command/commit-type'
import { NodeId } from '../../../../document/node/node'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { useItemStatusContext } from './context'
import styles from './index.module.less'
import VLayerPanelVisibleStatus = Wukong.DocumentProto.VLayerPanelVisibleStatus
import VLayerPanelLockStatus = Wukong.DocumentProto.VLayerPanelLockStatus

interface LayerPanelItemStatusProps {
    visibleStatus: VLayerPanelVisibleStatus
    lockStatus: VLayerPanelLockStatus
    isPurple: boolean
    id: NodeId
}

enum IconColor {
    Normal = 'normal',
    PurpleNormal = 'purpleNormal',
    Shallow = 'shallow',
    PurpleShallow = 'purpleShallow',
}

export const LayerPanelItemStatus: FC<LayerPanelItemStatusProps> = ({ visibleStatus, lockStatus, isPurple, id }) => {
    const docReadonly = useViewState('docReadonly')

    const visibleIcon = useMemo(() => {
        switch (visibleStatus) {
            case VLayerPanelVisibleStatus.V_LAYER_PANEL_VISIBLE_STATUS_VISIBLE: {
                return <IconEyes />
            }
            case VLayerPanelVisibleStatus.V_LAYER_PANEL_VISIBLE_STATUS_INVISIBLE: {
                return <IconEyesClose />
            }
            case VLayerPanelVisibleStatus.V_LAYER_PANEL_VISIBLE_STATUS_ANCESTOR_INVISIBLE: {
                return (
                    <svg width="16" height="16" viewBox="0 0 16 16" stroke="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="1.5" />
                    </svg>
                )
            }
            default:
                return null
        }
    }, [visibleStatus])

    const lockIcon = useMemo(() => {
        switch (lockStatus) {
            case VLayerPanelLockStatus.V_LAYER_PANEL_LOCK_STATUS_NONE: {
                return null
            }
            case VLayerPanelLockStatus.V_LAYER_PANEL_LOCK_STATUS_LOCKED: {
                return <IconLock />
            }
            case VLayerPanelLockStatus.V_LAYER_PANEL_LOCK_STATUS_UNLOCKED: {
                return <IconUnLock />
            }
            case VLayerPanelLockStatus.V_LAYER_PANEL_LOCK_STATUS_ANCESTOR_LOCKED: {
                return (
                    <svg width="16" height="16" viewBox="0 0 16 16" stroke="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="1.5" />
                    </svg>
                )
            }
        }
    }, [lockStatus])

    const command = useCommand()

    const { lockStatusRef, visibleStatusRef } = useItemStatusContext()

    const handleVisibleClick: MouseEventHandler<HTMLDivElement> = useCallback(
        (event) => {
            if (docReadonly) {
                return
            }
            event.stopPropagation()
            const visible =
                visibleStatus !== VLayerPanelVisibleStatus.V_LAYER_PANEL_VISIBLE_STATUS_VISIBLE &&
                visibleStatus !== VLayerPanelVisibleStatus.V_LAYER_PANEL_VISIBLE_STATUS_ANCESTOR_INVISIBLE
            command.invokeBridge(CommitType.CommitUndo, LayerPanelUpdateVisibleCommand, {
                nodeId: id,
                visible,
            })
            visibleStatusRef.current = visible
        },
        [command, id, visibleStatus, visibleStatusRef, docReadonly]
    )

    const handleLockClick: MouseEventHandler<HTMLDivElement> = useCallback(
        (event) => {
            if (docReadonly) {
                return
            }
            event.stopPropagation()
            const lock = lockStatus !== VLayerPanelLockStatus.V_LAYER_PANEL_LOCK_STATUS_LOCKED
            command.invokeBridge(CommitType.CommitUndo, LayerPanelUpdateLockCommand, {
                nodeId: id,
                lock,
            })
            lockStatusRef.current = lock
        },
        [command, id, lockStatus, lockStatusRef, docReadonly]
    )

    // 连续锁 / 解锁操作
    const handleLockMouseEnter = useCallback(() => {
        if (docReadonly) {
            return
        }
        if (lockStatusRef.current !== undefined) {
            command.invokeBridge(CommitType.CommitUndo, LayerPanelUpdateLockCommand, {
                nodeId: id,
                lock: lockStatusRef.current,
            })
        }
    }, [command, id, lockStatusRef, docReadonly])

    // 连续隐藏 / 显示操作
    const handleVisibleMouseEnter = useCallback(() => {
        if (docReadonly) {
            return
        }
        if (visibleStatusRef.current !== undefined) {
            command.invokeBridge(CommitType.CommitUndo, LayerPanelUpdateVisibleCommand, {
                nodeId: id,
                visible: visibleStatusRef.current,
            })
        }
    }, [command, id, visibleStatusRef, docReadonly])

    const iconColor = useMemo<IconColor>(() => {
        if (
            visibleStatus === VLayerPanelVisibleStatus.V_LAYER_PANEL_VISIBLE_STATUS_INVISIBLE ||
            visibleStatus === VLayerPanelVisibleStatus.V_LAYER_PANEL_VISIBLE_STATUS_ANCESTOR_INVISIBLE
        ) {
            return isPurple ? IconColor.PurpleShallow : IconColor.Shallow
        }
        return isPurple ? IconColor.PurpleNormal : IconColor.Normal
    }, [visibleStatus, isPurple])

    return useMemo(
        () =>
            lockIcon || visibleIcon ? (
                <div
                    data-testid="layer-panel-item-status"
                    className={classNames(styles['layer-panel-item-status'], styles[iconColor])}
                >
                    <div
                        className={styles.icon}
                        onMouseDown={handleLockClick}
                        onMouseEnter={handleLockMouseEnter}
                        data-testid="layer-panel-item-lock"
                    >
                        {lockIcon}
                    </div>
                    <div
                        className={styles.icon}
                        onMouseDown={handleVisibleClick}
                        onMouseEnter={handleVisibleMouseEnter}
                        data-testid="layer-panel-item-visible"
                    >
                        {visibleIcon}
                    </div>
                </div>
            ) : null,
        [
            handleLockClick,
            handleLockMouseEnter,
            handleVisibleClick,
            handleVisibleMouseEnter,
            iconColor,
            lockIcon,
            visibleIcon,
        ]
    )
}
