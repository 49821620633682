import { translation } from './inspect-content.translation'
/* eslint-disable no-restricted-imports */
import { WKToast } from '../../../../../ui-lib/src'
import { useCallback } from 'react'
import { useViewState } from '../../../view-state-bridge'
import { useHandler } from '../../context/document-context'
import { copyText } from '../../handler/copy-handler'
import style from './inspect.module.less'

export function InspectContent() {
    const handler = useHandler()

    const viewState = useViewState('inspectSelectionTextContentViewState')
    const onCopy = useCallback(
        (value: string) => {
            handler.handle(copyText, value)
            WKToast.show(translation('CopiedToClipboard'))
        },
        [handler]
    )

    if (!viewState?.show || !viewState.characters) {
        return null
    }

    return (
        <div className={style.panel}>
            <div className={style.panelTitle}>
                <div className={style.title}>{translation('Content')}</div>
            </div>
            <div className={style.textWrap}>
                <span className={style.textContent} onClick={() => onCopy(viewState.characters)}>
                    {viewState.characters}
                </span>
            </div>
        </div>
    )
}
