/* eslint-disable no-restricted-imports */
import { isHidden, WKSubMenuItem } from '../../../../../../../ui-lib/src'
import { EditorActionStatusConfigProps, useEditorActionStatusConfig } from '../../../../hooks/editor-action-status'

// 根据 editorActionType 及其他 ui 配置获取 subMenuItem 配置
export function useEditorActionStatusSubMenuItem({
    command,
    editorActionType,
    hiddenWhenDisabled,
    hidden,
    handlerCallback,
    ...otherProps
}: EditorActionStatusConfigProps & Pick<WKSubMenuItem, 'forwardIcon' | 'testId' | 'hidden'>): WKSubMenuItem {
    const { hidden: actionStatusHidden, ...otherConfigs } = useEditorActionStatusConfig({
        command,
        editorActionType,
        hiddenWhenDisabled,
        handlerCallback,
    })
    return {
        ...otherProps,
        ...otherConfigs,
        hidden: actionStatusHidden || isHidden(hidden),
    }
}
