import { RouteToken } from '../../../../../util/src'
import { WKFrog } from '../../frog'
import type { DocWithAuthorityVO } from '../../interface/type'

export enum LocatePageType {
    Recent = '最近',
    Drafts = '草稿',
    Favorites = '收藏',
    Team = '团队',
    Project = '项目',
    Trash = '回收站',
    EnterpriseManagement = '企业管理',
    AllTeams = '全部团队',
    Search = '搜索结果页',
}

export const RouteToken2LocatePageType = {
    [RouteToken.Recent]: LocatePageType.Recent,
    [RouteToken.Drafts]: LocatePageType.Drafts,
    [RouteToken.Favorites]: LocatePageType.Favorites,
    [RouteToken.Team]: LocatePageType.Team,
    [RouteToken.Project]: LocatePageType.Project,
    [RouteToken.Trash]: LocatePageType.Trash,
    [RouteToken.Admin]: LocatePageType.EnterpriseManagement,
    [RouteToken.Teams]: LocatePageType.AllTeams,
    [RouteToken.Search]: LocatePageType.Search,
}

export enum MotiffPlanType {
    Team = '团队版',
    Enterprise = '企业版',
}

export enum OpenFileWayType {
    DoubleClick = '双击',
    RightClickOpen = '右键菜单打开',
    CmdDoubleClick = 'cmd+双击',
    ShiftDoubleClick = 'shift+双击',
    RightClickNewTabOpen = '右键菜单新标签页打开',
}

export enum ProjectMenuEntryType {
    ProjectList = '项目列表',
    ProjectPage = '项目页',
    Catalogue = '目录区',
    Search = '搜索结果页',
}

export enum PermissionOptionType {
    Inherited = '继承权限',
    View = '可查看',
    Edit = '可编辑',
    Admin = '管理员',
    Owner = '所有者',
    None = '无权限',
    ViewPrototype = '可查看原型',
}

export enum LinkPublicOptionType {
    OrgViewer = '企业成员获得链接可查看',
    OrgEditor = '企业成员获得链接可编辑',
    AnyoneViewer = '任何人获得链接可查看',
    AnyoneEditor = '任何人获得链接可编辑',
}

export enum TeamMenuEntryType {
    TeamList = '团队首页',
    Catalogue = '目录区',
}

export enum AccountType {
    Admin = '管理员',
    OrgMember = '企业成员',
    OrgVisitor = '访客',
    NotJoinedOrgMember = '未加入的企业成员',
    NotJoinedOrgVisitor = '未加入的访客',
    Others = '-',
}

let locatePage = LocatePageType.Recent
let motiffPlan = MotiffPlanType.Team

export function updateLocatePage(v: LocatePageType) {
    locatePage = v
}

export function updateMotiffPlan(v: MotiffPlanType) {
    motiffPlan = v
}

function getDocsFolder(docs: DocWithAuthorityVO[], draftId: string) {
    if (docs.every((v) => v.folderId === draftId)) {
        return '草稿'
    } else if (docs.every((v) => v.folderId === docs[0].folderId)) {
        return '项目'
    }
    return '多种'
}

export function getFileFlowDirection(
    docs: DocWithAuthorityVO[],
    draftId: string,
    targetInfo: { folderId: string; teamId: string }
) {
    const docsFolder = getDocsFolder(docs, draftId)

    if (!docs.length) {
        return ''
    }

    if (targetInfo.folderId === 'draft') {
        return `${docsFolder}→草稿`
    }
    if (targetInfo.folderId === 'trash') {
        return `${docsFolder}→回收站`
    }
    if (docsFolder === '多种' || docsFolder === '草稿') {
        return `${docsFolder}→项目`
    }
    return docs[0].teamId === targetInfo.teamId ? `${docsFolder}→同团队项目` : `${docsFolder}→跨团队项目`
}

export const SpaceLogs = {
    Files: {
        filesEnter() {
            WKFrog.addFrogRecord({
                url: '/expose/Files/filesEnter',
                eventId: 22668,
                eventName: 'filesEnter',
                eventAction: 'expose',
                customExtend: { locate_page: locatePage, motiff_plan: motiffPlan },
            })
        },
        filesDuration(customExtend: { duration: number }) {
            WKFrog.addFrogRecord({
                url: '/time/Files/filesDuration',
                eventId: 22669,
                eventName: 'filesDuration',
                eventAction: 'time',
                customExtend,
            })
        },
    },
    FileOperations: {
        newFile() {
            WKFrog.addFrogRecord({
                url: '/click/FileOperations/newFile',
                eventId: 22670,
                eventName: 'newFile',
                eventAction: 'click',
                customExtend: {
                    motiff_plan: motiffPlan,
                    locate_page: locatePage,
                },
            })
        },
        openFile(customExtend: { open_file_way: OpenFileWayType }) {
            WKFrog.addFrogRecord({
                url: '/event/FileOperations/openFile',
                eventId: 22671,
                eventName: 'openFile',
                eventAction: 'event',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                    locate_page: locatePage,
                },
            })
        },
        favoriteFileIcon() {
            WKFrog.addFrogRecord({
                url: '/click/FileOperations/favoriteFileIcon',
                eventId: 22672,
                eventName: 'favoriteFileIcon',
                eventAction: 'click',
                customExtend: {
                    motiff_plan: motiffPlan,
                    locate_page: locatePage,
                },
            })
        },
        cancelFavoriteFileIcon() {
            WKFrog.addFrogRecord({
                url: '/click/FileOperations/cancelFavoriteFileIcon',
                eventId: 22673,
                eventName: 'cancelFavoriteFileIcon',
                eventAction: 'click',
                customExtend: {
                    motiff_plan: motiffPlan,
                    locate_page: locatePage,
                },
            })
        },
        dragMoveFile(customExtend: { file_count: number; file_flow_direction: string }) {
            WKFrog.addFrogRecord({
                url: '/event/FileOperations/dragMoveFile',
                eventId: 22674,
                eventName: 'dragMoveFile',
                eventAction: 'event',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        moveFileDialogNewProject() {
            WKFrog.addFrogRecord({
                url: '/event/FileOperations/moveFileDialogNewProject',
                eventId: 22675,
                eventName: 'moveFileDialogNewProject',
                eventAction: 'event',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
        moveFileDialogSuccess(customExtend: { file_count: number; file_flow_direction: string }) {
            WKFrog.addFrogRecord({
                url: '/event/FileOperations/moveFileDialogSuccess',
                eventId: 22676,
                eventName: 'moveFileDialogSuccess',
                eventAction: 'event',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        clickNameToRenameFile() {
            WKFrog.addFrogRecord({
                url: '/event/FileOperations/clickNameToRenameFile',
                eventId: 22677,
                eventName: 'clickNameToRenameFile',
                eventAction: 'event',
                customExtend: {
                    motiff_plan: motiffPlan,
                },
            })
        },
        modifyFilesSortingConditions(customExtend: { before_sorting: string; after_sorting: string }) {
            WKFrog.addFrogRecord({
                url: '/click/FileOperations/modifyFilesSortingConditions',
                eventId: 22678,
                eventName: 'modifyFilesSortingConditions',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                    locate_page: locatePage,
                },
            })
        },
        openFileMenu(customExtend: { file_count: number }) {
            WKFrog.addFrogRecord({
                url: '/click/FileOperations/openFileMenu',
                eventId: 22679,
                eventName: 'openFileMenu',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                    locate_page: locatePage,
                },
            })
        },
    },
    EditorFileOperations: {
        share() {
            WKFrog.addFrogRecord({
                url: '/click/EditorFileOperations/share',
                eventId: 22713,
                eventName: 'share',
                eventAction: 'click',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
    },
    ProjectOperations: {
        pinProjectIconInList() {
            WKFrog.addFrogRecord({
                url: '/click/ProjectOperations/pinProjectIconInList',
                eventId: 22695,
                eventName: 'pinProjectIconInList',
                eventAction: 'click',
                customExtend: {
                    motiff_plan: motiffPlan,
                },
            })
        },
        unpinProjectIconInList() {
            WKFrog.addFrogRecord({
                url: '/click/ProjectOperations/unpinProjectIconInList',
                eventId: 22696,
                eventName: 'unpinProjectIconInList',
                eventAction: 'click',
                customExtend: {
                    motiff_plan: motiffPlan,
                },
            })
        },
        pinProjectIconInProject() {
            WKFrog.addFrogRecord({
                url: '/click/ProjectOperations/pinProjectIconInProject',
                eventId: 22697,
                eventName: 'pinProjectIconInProject',
                eventAction: 'click',
                customExtend: {
                    motiff_plan: motiffPlan,
                },
            })
        },
        unpinProjectIconInProject() {
            WKFrog.addFrogRecord({
                url: '/click/ProjectOperations/unpinProjectIconInProject',
                eventId: 22698,
                eventName: 'unpinProjectIconInProject',
                eventAction: 'click',
                customExtend: {
                    motiff_plan: motiffPlan,
                },
            })
        },
        moveProjectDialogSuccess() {
            WKFrog.addFrogRecord({
                url: '/event/ProjectOperations/moveProjectDialogSuccess',
                eventId: 22699,
                eventName: 'moveProjectDialogSuccess',
                eventAction: 'event',
                customExtend: {
                    motiff_plan: motiffPlan,
                },
            })
        },
        modifyProjectSortingConditions(customExtend: { before_sorting: string; after_sorting: string }) {
            WKFrog.addFrogRecord({
                url: '/click/ProjectOperations/modifyProjectSortingConditions',
                eventId: 22700,
                eventName: 'modifyProjectSortingConditions',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        newProject() {
            WKFrog.addFrogRecord({
                url: '/event/ProjectOperations/newProject',
                eventId: 22701,
                eventName: 'newProject',
                eventAction: 'event',
                customExtend: {
                    motiff_plan: motiffPlan,
                },
            })
        },
    },
    FilesCatalogue: {
        dragMoveProject() {
            WKFrog.addFrogRecord({
                url: '/event/FilesCatalogue/dragMoveProject',
                eventId: 22709,
                eventName: 'dragMoveProject',
                eventAction: 'event',
                customExtend: {
                    motiff_plan: motiffPlan,
                },
            })
        },
        dragSortProject(customExtend: { drag_transition: string }) {
            WKFrog.addFrogRecord({
                url: '/event/FilesCatalogue/dragSortProject',
                eventId: 22710,
                eventName: 'dragSortProject',
                eventAction: 'event',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        dragSortTeam(customExtend: { drag_transition: string }) {
            WKFrog.addFrogRecord({
                url: '/event/FilesCatalogue/dragSortTeam',
                eventId: 22711,
                eventName: 'dragSortTeam',
                eventAction: 'event',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        newProject() {
            WKFrog.addFrogRecord({
                url: '/click/FilesCatalogue/newProject',
                eventId: 22712,
                eventName: 'newProject',
                eventAction: 'click',
                customExtend: {
                    motiff_plan: motiffPlan,
                },
            })
        },
    },
    TeamMembersManage: {
        leaveTeamInMemberList() {
            WKFrog.addFrogRecord({
                url: '/click/TeamMembersManage/leaveTeamInMemberList',
                eventId: 22742,
                eventName: 'leaveTeamInMemberList',
                eventAction: 'click',
                customExtend: {
                    motiff_plan: motiffPlan,
                },
            })
        },
        inviteTeamMember(customExtend: { people_count: number; permission_option: PermissionOptionType }) {
            WKFrog.addFrogRecord({
                url: '/click/TeamMembersManage/inviteTeamMember',
                eventId: 22743,
                eventName: 'inviteTeamMember',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        openTeamViewInviteLink() {
            WKFrog.addFrogRecord({
                url: '/click/TeamMembersManage/openTeamViewInviteLink',
                eventId: 22744,
                eventName: 'openTeamViewInviteLink',
                eventAction: 'click',
                customExtend: {
                    motiff_plan: motiffPlan,
                },
            })
        },
        closeTeamViewInviteLink() {
            WKFrog.addFrogRecord({
                url: '/click/TeamMembersManage/closeTeamViewInviteLink',
                eventId: 22745,
                eventName: 'closeTeamViewInviteLink',
                eventAction: 'click',
                customExtend: {
                    motiff_plan: motiffPlan,
                },
            })
        },
        resetTeamViewInviteLink() {
            WKFrog.addFrogRecord({
                url: '/click/TeamMembersManage/resetTeamViewInviteLink',
                eventId: 22746,
                eventName: 'resetTeamViewInviteLink',
                eventAction: 'click',
                customExtend: {
                    motiff_plan: motiffPlan,
                },
            })
        },
        copyTeamViewInviteLink() {
            WKFrog.addFrogRecord({
                url: '/click/TeamMembersManage/copyTeamViewInviteLink',
                eventId: 22747,
                eventName: 'copyTeamViewInviteLink',
                eventAction: 'click',
                customExtend: {
                    motiff_plan: motiffPlan,
                },
            })
        },
        openTeamEditInviteLink() {
            WKFrog.addFrogRecord({
                url: '/click/TeamMembersManage/openTeamEditInviteLink',
                eventId: 22748,
                eventName: 'openTeamEditInviteLink',
                eventAction: 'click',
                customExtend: {
                    motiff_plan: motiffPlan,
                },
            })
        },
        closeTeamEditInviteLink() {
            WKFrog.addFrogRecord({
                url: '/click/TeamMembersManage/closeTeamEditInviteLink',
                eventId: 22749,
                eventName: 'closeTeamEditInviteLink',
                eventAction: 'click',
                customExtend: {
                    motiff_plan: motiffPlan,
                },
            })
        },
        resetTeamEditInviteLink() {
            WKFrog.addFrogRecord({
                url: '/click/TeamMembersManage/resetTeamEditInviteLink',
                eventId: 22750,
                eventName: 'resetTeamEditInviteLink',
                eventAction: 'click',
                customExtend: {
                    motiff_plan: motiffPlan,
                },
            })
        },
        copyTeamEditInviteLink() {
            WKFrog.addFrogRecord({
                url: '/click/TeamMembersManage/copyTeamEditInviteLink',
                eventId: 22751,
                eventName: 'copyTeamEditInviteLink',
                eventAction: 'click',
                customExtend: {
                    motiff_plan: motiffPlan,
                },
            })
        },
        teamInviteLinkEnter(customExtend: { motiff_plan: MotiffPlanType }) {
            WKFrog.addFrogRecord({
                url: '/expose/TeamMembersManage/teamInviteLinkEnter',
                eventId: 22752,
                eventName: 'teamInviteLinkEnter',
                eventAction: 'expose',
                customExtend,
            })
        },
        joinTheTeamViaLink(customExtend: { motiff_plan: MotiffPlanType }) {
            WKFrog.addFrogRecord({
                url: '/event/TeamMembersManage/joinTheTeamViaLink',
                eventId: 22753,
                eventName: 'joinTheTeamViaLink',
                eventAction: 'event',
                customExtend,
            })
        },
        modifyTeamMemberpermission(customExtend: {
            permission_option: PermissionOptionType
            previous_permissions_option: PermissionOptionType
        }) {
            WKFrog.addFrogRecord({
                url: '/click/TeamMembersManage/modifyTeamMemberpermission',
                eventId: 22754,
                eventName: 'modifyTeamMemberpermission',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        TeamMembersTab() {
            WKFrog.addFrogRecord({
                url: '/click/TeamMembersManage/TeamMembersTab',
                eventId: 22906,
                eventName: 'TeamMembersTab',
                eventAction: 'click',
                customExtend: {
                    motiff_plan: motiffPlan,
                },
            })
        },
    },
    FilesFileMenu: {
        open() {
            WKFrog.addFrogRecord({
                url: '/click/FilesFileMenu/open',
                eventId: 22680,
                eventName: 'open',
                eventAction: 'click',
                customExtend: { locate_page: locatePage, motiff_plan: motiffPlan },
            })
        },
        openInNewTab() {
            WKFrog.addFrogRecord({
                url: '/click/FilesFileMenu/openInNewTab',
                eventId: 22681,
                eventName: 'openInNewTab',
                eventAction: 'click',
                customExtend: { locate_page: locatePage, motiff_plan: motiffPlan },
            })
        },
        favorite(customExtend: { file_count: number }) {
            WKFrog.addFrogRecord({
                url: '/click/FilesFileMenu/favorite',
                eventId: 22682,
                eventName: 'favorite',
                eventAction: 'click',
                customExtend: { ...customExtend, locate_page: locatePage, motiff_plan: motiffPlan },
            })
        },
        cancelFavorite(customExtend: { file_count: number }) {
            WKFrog.addFrogRecord({
                url: '/click/FilesFileMenu/cancelFavorite',
                eventId: 22683,
                eventName: 'cancelFavorite',
                eventAction: 'click',
                customExtend: { ...customExtend, locate_page: locatePage, motiff_plan: motiffPlan },
            })
        },
        copyLink() {
            WKFrog.addFrogRecord({
                url: '/click/FilesFileMenu/copyLink',
                eventId: 22684,
                eventName: 'copyLink',
                eventAction: 'click',
                customExtend: { locate_page: locatePage, motiff_plan: motiffPlan },
            })
        },
        share() {
            WKFrog.addFrogRecord({
                url: '/click/FilesFileMenu/share',
                eventId: 22685,
                eventName: 'share',
                eventAction: 'click',
                customExtend: { locate_page: locatePage, motiff_plan: motiffPlan },
            })
        },
        duplicate() {
            WKFrog.addFrogRecord({
                url: '/click/FilesFileMenu/duplicate',
                eventId: 22686,
                eventName: 'duplicate',
                eventAction: 'click',
                customExtend: { locate_page: locatePage, motiff_plan: motiffPlan },
            })
        },
        moveTo(customExtend: { file_count: number }) {
            WKFrog.addFrogRecord({
                url: '/click/FilesFileMenu/moveTo',
                eventId: 22687,
                eventName: 'moveTo',
                eventAction: 'click',
                customExtend: { ...customExtend, locate_page: locatePage, motiff_plan: motiffPlan },
            })
        },
        rename() {
            WKFrog.addFrogRecord({
                url: '/click/FilesFileMenu/rename',
                eventId: 22688,
                eventName: 'rename',
                eventAction: 'click',
                customExtend: { locate_page: locatePage, motiff_plan: motiffPlan },
            })
        },
        delete(customExtend: { file_count: number }) {
            WKFrog.addFrogRecord({
                url: '/click/FilesFileMenu/delete',
                eventId: 22689,
                eventName: 'delete',
                eventAction: 'click',
                customExtend: { ...customExtend, locate_page: locatePage, motiff_plan: motiffPlan },
            })
        },
        duplicateToDraft(customExtend: { file_count: number }) {
            WKFrog.addFrogRecord({
                url: '/click/FilesFileMenu/duplicateToDraft',
                eventId: 22690,
                eventName: 'duplicateToDraft',
                eventAction: 'click',
                customExtend: { ...customExtend, motiff_plan: motiffPlan },
            })
        },
        deletePermanently(customExtend: { file_count: number }) {
            WKFrog.addFrogRecord({
                url: '/click/FilesFileMenu/deletePermanently',
                eventId: 22691,
                eventName: 'deletePermanently',
                eventAction: 'click',
                customExtend: { ...customExtend, motiff_plan: motiffPlan },
            })
        },
        recoverFile(customExtend: { file_count: number }) {
            WKFrog.addFrogRecord({
                url: '/click/FilesFileMenu/recoverFile',
                eventId: 22692,
                eventName: 'recoverFile',
                eventAction: 'click',
                customExtend: { ...customExtend, motiff_plan: motiffPlan },
            })
        },
        removeFromRecent(customExtend: { file_count: number }) {
            WKFrog.addFrogRecord({
                url: '/click/FilesFileMenu/removeFromRecent',
                eventId: 22693,
                eventName: 'removeFromRecent',
                eventAction: 'click',
                customExtend: { ...customExtend, motiff_plan: motiffPlan },
            })
        },
        showInProject() {
            WKFrog.addFrogRecord({
                url: '/click/FilesFileMenu/showInProject',
                eventId: 22694,
                eventName: 'showInProject',
                eventAction: 'click',
                customExtend: { motiff_plan: motiffPlan, locate_page: locatePage },
            })
        },
    },
    FilesProjectMenu: {
        openProjectMenu(customExtend: { project_menu_entry: ProjectMenuEntryType }) {
            WKFrog.addFrogRecord({
                url: '/click/FilesProjectMenu/openProjectMenu',
                eventId: 22702,
                eventName: 'openProjectMenu',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        rename(customExtend: { project_menu_entry: ProjectMenuEntryType }) {
            WKFrog.addFrogRecord({
                url: '/click/FilesProjectMenu/rename',
                eventId: 22703,
                eventName: 'rename',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        moveTo(customExtend: { project_menu_entry: ProjectMenuEntryType }) {
            WKFrog.addFrogRecord({
                url: '/click/FilesProjectMenu/moveTo',
                eventId: 22704,
                eventName: 'moveTo',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        delete(customExtend: { project_menu_entry: ProjectMenuEntryType }) {
            WKFrog.addFrogRecord({
                url: '/click/FilesProjectMenu/delete',
                eventId: 22705,
                eventName: 'delete',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        copyLink(customExtend: { project_menu_entry: ProjectMenuEntryType }) {
            WKFrog.addFrogRecord({
                url: '/click/FilesProjectMenu/copyLink',
                eventId: 22706,
                eventName: 'copyLink',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        pinProject(customExtend: { project_menu_entry: ProjectMenuEntryType }) {
            WKFrog.addFrogRecord({
                url: '/click/FilesProjectMenu/pinProject',
                eventId: 22707,
                eventName: 'pinProject',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        unpinProject(customExtend: { project_menu_entry: ProjectMenuEntryType }) {
            WKFrog.addFrogRecord({
                url: '/click/FilesProjectMenu/unpinProject',
                eventId: 22708,
                eventName: 'unpinProject',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        share(customExtend: { project_menu_entry: ProjectMenuEntryType }) {
            WKFrog.addFrogRecord({
                url: '/click/FilesProjectMenu/share',
                eventId: 25337,
                eventName: 'share',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        showInTeam(customExtend: { project_menu_entry: ProjectMenuEntryType }) {
            WKFrog.addFrogRecord({
                url: '/click/FilesProjectMenu/showInTeam',
                eventId: 25338,
                eventName: 'showInTeam',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
    },
    FileShareAndPermission: {
        shareFile() {
            WKFrog.addFrogRecord({
                url: '/expose/FileShareAndPermission/shareFile',
                eventId: 22714,
                eventName: 'shareFile',
                eventAction: 'expose',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
        inviteFileMembers(customExtend: { people_count: number; permission_option: PermissionOptionType }) {
            WKFrog.addFrogRecord({
                url: '/click/FileShareAndPermission/inviteFileMembers',
                eventId: 22715,
                eventName: 'inviteFileMembers',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        setProjectInheritedpermission(customExtend: {
            permission_option: PermissionOptionType
            previous_permissions_option: PermissionOptionType
        }) {
            WKFrog.addFrogRecord({
                url: '/click/FileShareAndPermission/setProjectInheritedpermission',
                eventId: 22716,
                eventName: 'setProjectInheritedpermission',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        openFilePublicAccess() {
            WKFrog.addFrogRecord({
                url: '/click/FileShareAndPermission/openFilePublicAccess',
                eventId: 22717,
                eventName: 'openFilePublicAccess',
                eventAction: 'click',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
        closeFilePublicAccess() {
            WKFrog.addFrogRecord({
                url: '/click/FileShareAndPermission/closeFilePublicAccess',
                eventId: 22718,
                eventName: 'closeFilePublicAccess',
                eventAction: 'click',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
        setFilePublicScope(customExtend: { link_public_option: LinkPublicOptionType }) {
            WKFrog.addFrogRecord({
                url: '/click/FileShareAndPermission/setFilePublicScope',
                eventId: 22719,
                eventName: 'setFilePublicScope',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        needPassword() {
            WKFrog.addFrogRecord({
                url: '/click/FileShareAndPermission/needPassword',
                eventId: 22720,
                eventName: 'needPassword',
                eventAction: 'click',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
        resetPassword() {
            WKFrog.addFrogRecord({
                url: '/click/FileShareAndPermission/resetPassword',
                eventId: 22721,
                eventName: 'resetPassword',
                eventAction: 'click',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
        copyLink1InDialog() {
            WKFrog.addFrogRecord({
                url: '/click/FileShareAndPermission/copyLink1InDialog',
                eventId: 22722,
                eventName: 'copyLink1InDialog',
                eventAction: 'click',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
        copyLink2InDialog() {
            WKFrog.addFrogRecord({
                url: '/click/FileShareAndPermission/copyLink2InDialog',
                eventId: 22723,
                eventName: 'copyLink2InDialog',
                eventAction: 'click',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
        copyLinkAndPasswordInDialog() {
            WKFrog.addFrogRecord({
                url: '/click/FileShareAndPermission/copyLinkAndPasswordInDialog',
                eventId: 22724,
                eventName: 'copyLinkAndPasswordInDialog',
                eventAction: 'click',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
        expandProjectMemberList() {
            WKFrog.addFrogRecord({
                url: '/click/FileShareAndPermission/expandProjectMemberList',
                eventId: 22725,
                eventName: 'expandProjectMemberList',
                eventAction: 'click',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
        modifyFileMemberpermission(customExtend: {
            permission_option: PermissionOptionType
            previous_permissions_option: PermissionOptionType
        }) {
            WKFrog.addFrogRecord({
                url: '/click/FileShareAndPermission/modifyFileMemberpermission',
                eventId: 22726,
                eventName: 'modifyFileMemberpermission',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        leaveFile() {
            WKFrog.addFrogRecord({
                url: '/click/FileShareAndPermission/leaveFile',
                eventId: 22727,
                eventName: 'leaveFile',
                eventAction: 'click',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
        enterEncryptedFile(customExtend: {
            motiff_plan: MotiffPlanType
            account_type: AccountType
            resource_org_id: string
        }) {
            WKFrog.addFrogRecord({
                url: '/expose/FileShareAndPermission/enterEncryptedFile',
                eventId: 22728,
                eventName: 'enterEncryptedFile',
                eventAction: 'expose',
                customExtend,
            })
        },
        confirmAccessEncryptedFile(customExtend: {
            motiff_plan: MotiffPlanType
            account_type: AccountType
            resource_org_id: string
        }) {
            WKFrog.addFrogRecord({
                url: '/event/FileShareAndPermission/confirmAccessEncryptedFile',
                eventId: 22729,
                eventName: 'confirmAccessEncryptedFile',
                eventAction: 'event',
                customExtend,
            })
        },
    },
    ProjectShareAndPermission: {
        shareproject() {
            WKFrog.addFrogRecord({
                url: '/expose/ProjectShareAndPermission/shareproject',
                eventId: 22730,
                eventName: 'shareproject',
                eventAction: 'expose',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
        inviteProjectMember(customExtend: { people_count: number; permission_option: PermissionOptionType }) {
            WKFrog.addFrogRecord({
                url: '/click/ProjectShareAndPermission/inviteProjectMember',
                eventId: 22731,
                eventName: 'inviteProjectMember',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        setTeamInheritedpermission(customExtend: {
            permission_option: PermissionOptionType
            previous_permissions_option: PermissionOptionType
        }) {
            WKFrog.addFrogRecord({
                url: '/click/ProjectShareAndPermission/setTeamInheritedpermission',
                eventId: 22732,
                eventName: 'setTeamInheritedpermission',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        expandTeamMemberList() {
            WKFrog.addFrogRecord({
                url: '/click/ProjectShareAndPermission/expandTeamMemberList',
                eventId: 22733,
                eventName: 'expandTeamMemberList',
                eventAction: 'click',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
        modifyProjectMemberpermission(customExtend: {
            permission_option: PermissionOptionType
            previous_permissions_option: PermissionOptionType
        }) {
            WKFrog.addFrogRecord({
                url: '/click/ProjectShareAndPermission/modifyProjectMemberpermission',
                eventId: 22734,
                eventName: 'modifyProjectMemberpermission',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        leaveProject() {
            WKFrog.addFrogRecord({
                url: '/click/ProjectShareAndPermission/leaveProject',
                eventId: 22735,
                eventName: 'leaveProject',
                eventAction: 'click',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
    },
    FilesTeamMenu: {
        openTeamMenu(customExtend: { team_menu_entry: TeamMenuEntryType }) {
            WKFrog.addFrogRecord({
                url: '/click/FilesTeamMenu/openTeamMenu',
                eventId: 22736,
                eventName: 'openTeamMenu',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        copyLink(customExtend: { team_menu_entry: TeamMenuEntryType }) {
            WKFrog.addFrogRecord({
                url: '/click/FilesTeamMenu/copyLink',
                eventId: 22737,
                eventName: 'copyLink',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        rename(customExtend: { team_menu_entry: TeamMenuEntryType }) {
            WKFrog.addFrogRecord({
                url: '/click/FilesTeamMenu/rename',
                eventId: 22738,
                eventName: 'rename',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        modifyTeamIcon(customExtend: { team_menu_entry: TeamMenuEntryType }) {
            WKFrog.addFrogRecord({
                url: '/click/FilesTeamMenu/modifyTeamIcon',
                eventId: 22739,
                eventName: 'modifyTeamIcon',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        leaveTeam(customExtend: { team_menu_entry: TeamMenuEntryType }) {
            WKFrog.addFrogRecord({
                url: '/click/FilesTeamMenu/leaveTeam',
                eventId: 22740,
                eventName: 'leaveTeam',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
        deleteTeam(customExtend: { team_menu_entry: TeamMenuEntryType }) {
            WKFrog.addFrogRecord({
                url: '/click/FilesTeamMenu/deleteTeam',
                eventId: 22741,
                eventName: 'deleteTeam',
                eventAction: 'click',
                customExtend: {
                    ...customExtend,
                    motiff_plan: motiffPlan,
                },
            })
        },
    },
    TeamFontsManage: {
        TeamFontsTab() {
            WKFrog.addFrogRecord({
                url: '/click/TeamFontsManage/TeamFontsTab',
                eventId: 22907,
                eventName: 'TeamFontsTab',
                eventAction: 'click',
                customExtend: {
                    motiff_plan: motiffPlan,
                },
            })
        },
    },
    SearchPanel: {
        focusSearch() {
            WKFrog.addFrogRecord({
                url: '/click/SearchPanel/focusSearch',
                eventId: 25383,
                eventName: 'focusSearch',
                eventAction: 'click',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
        focusSearchShortcuts() {
            WKFrog.addFrogRecord({
                url: '/event/SearchPanel/focusSearchShortcuts',
                eventId: 25384,
                eventName: 'focusSearchShortcuts',
                eventAction: 'event',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
        Search(customExtend: { search_query: string }) {
            WKFrog.addFrogRecord({
                url: '/event/SearchPanel/Search',
                eventId: 25385,
                eventName: 'Search',
                eventAction: 'event',
                customExtend: { ...customExtend, motiff_plan: motiffPlan },
            })
        },
        openFile(customExtend: {
            search_query: string
            file_id: string
            search_relevance_order: number
            if_library: number
            last_viewed: number
            last_modified: number
            last_viewed_this_person: number | string
            last_modified_this_person: number | string
            if_owner_themselves: number
        }) {
            WKFrog.addFrogRecord({
                url: '/click/SearchPanel/openFile',
                eventId: 25386,
                eventName: 'openFile',
                eventAction: 'click',
                customExtend: { ...customExtend, motiff_plan: motiffPlan },
            })
        },
        openProject(customExtend: { search_query: string }) {
            WKFrog.addFrogRecord({
                url: '/click/SearchPanel/openProject',
                eventId: 25398,
                eventName: 'openProject',
                eventAction: 'click',
                customExtend: { ...customExtend, motiff_plan: motiffPlan },
            })
        },
        viewAll() {
            WKFrog.addFrogRecord({
                url: '/click/SearchPanel/viewAll',
                eventId: 25399,
                eventName: 'viewAll',
                eventAction: 'click',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
        openTeam(customExtend: { search_query: string }) {
            WKFrog.addFrogRecord({
                url: '/click/SearchPanel/openTeam',
                eventId: 25400,
                eventName: 'openTeam',
                eventAction: 'click',
                customExtend: { ...customExtend, motiff_plan: motiffPlan },
            })
        },
        viewAllFile() {
            WKFrog.addFrogRecord({
                url: '/click/SearchPanel/viewAllFile',
                eventId: 25401,
                eventName: 'viewAllFile',
                eventAction: 'click',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
        viewAllProject() {
            WKFrog.addFrogRecord({
                url: '/click/SearchPanel/viewAllProject',
                eventId: 25402,
                eventName: 'viewAllProject',
                eventAction: 'click',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
        viewAllTeam() {
            WKFrog.addFrogRecord({
                url: '/click/SearchPanel/viewAllTeam',
                eventId: 25403,
                eventName: 'viewAllTeam',
                eventAction: 'click',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
    },
    SearchResultPage: {
        fileSearchResultPage() {
            WKFrog.addFrogRecord({
                url: '/expose/SearchResultPage/fileSearchResultPage',
                eventId: 25407,
                eventName: 'fileSearchResultPage',
                eventAction: 'expose',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
        projectSearchResultPage() {
            WKFrog.addFrogRecord({
                url: '/expose/SearchResultPage/projectSearchResultPage',
                eventId: 25408,
                eventName: 'projectSearchResultPage',
                eventAction: 'expose',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
        teamSearchResultPage() {
            WKFrog.addFrogRecord({
                url: '/expose/SearchResultPage/teamSearchResultPage',
                eventId: 25409,
                eventName: 'teamSearchResultPage',
                eventAction: 'expose',
                customExtend: { motiff_plan: motiffPlan },
            })
        },
        fileSearchFliter(customExtend: { search_query: string; search_filter: string }) {
            WKFrog.addFrogRecord({
                url: '/click/SearchResultPage/fileSearchFliter',
                eventId: 25410,
                eventName: 'fileSearchFliter',
                eventAction: 'click',
                customExtend: { ...customExtend, motiff_plan: motiffPlan },
            })
        },
        projectSearchFliter(customExtend: { search_query: string; search_filter: string }) {
            WKFrog.addFrogRecord({
                url: '/click/SearchResultPage/projectSearchFliter',
                eventId: 25411,
                eventName: 'projectSearchFliter',
                eventAction: 'click',
                customExtend: { ...customExtend, motiff_plan: motiffPlan },
            })
        },
        teamSearchFliter(customExtend: { search_query: string; search_filter: string }) {
            WKFrog.addFrogRecord({
                url: '/click/SearchResultPage/teamSearchFliter',
                eventId: 25412,
                eventName: 'teamSearchFliter',
                eventAction: 'click',
                customExtend: { ...customExtend, motiff_plan: motiffPlan },
            })
        },
        openProject(customExtend: { search_query: string }) {
            WKFrog.addFrogRecord({
                url: '/click/SearchResultPage/openProject',
                eventId: 25413,
                eventName: 'openProject',
                eventAction: 'click',
                customExtend: { ...customExtend, motiff_plan: motiffPlan },
            })
        },
        openTeam(customExtend: { search_query: string }) {
            WKFrog.addFrogRecord({
                url: '/click/SearchResultPage/openTeam',
                eventId: 25414,
                eventName: 'openTeam',
                eventAction: 'click',
                customExtend: { ...customExtend, motiff_plan: motiffPlan },
            })
        },
        openFile(customExtend: {
            search_query: string
            file_id: string
            search_relevance_order: number
            if_library: number
            last_viewed: number
            last_modified: number
            last_viewed_this_person: number | string
            last_modified_this_person: number | string
            if_owner_themselves: number
        }) {
            WKFrog.addFrogRecord({
                url: '/click/SearchResultPage/openFile',
                eventId: 25415,
                eventName: 'openFile',
                eventAction: 'click',
                customExtend: { ...customExtend, motiff_plan: motiffPlan },
            })
        },
    },
}
