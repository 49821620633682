import { translation } from './menu-utils.translation'
/* eslint-disable no-restricted-imports */
import { RouteToken, RouteTokenValue } from '../../../../../../util/src'
import { SortOrderLabel1, SortOrderLabel2, sortByNumber, sortByString } from '../../../util/sort'
import { DocListPageSortKey, SortOrder, FlatDoc } from '../../../util/types'

export enum SortItem {
    SortKey = 'sortKey',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    SortOrder = 'sortOrder',
}

export interface SortState {
    sortKey: DocListPageSortKey
    sortOrder: SortOrder
}

export const SortItemLabel = {
    [SortItem.SortKey]: translation('SortBy'),
    [SortItem.SortOrder]: translation('Order'),
}

export const SortKeyLabel = {
    [DocListPageSortKey.Visited]: translation('DateViewed'),
    [DocListPageSortKey.Created]: translation('DateCreated'),
    [DocListPageSortKey.Favorite]: translation('EYQEDJ'),
    [DocListPageSortKey.Edited]: translation('LastMotified'),
    [DocListPageSortKey.Deleted]: translation('DateDeleted'),
    [DocListPageSortKey.DeleteUserName]: translation('PvuNHb'),
    [DocListPageSortKey.Name]: translation('Alphabetical'),
}

const routeMenuItems: Record<string, DocListPageSortKey[]> = {
    [RouteToken.Recent]: [DocListPageSortKey.Visited, DocListPageSortKey.Created, DocListPageSortKey.Name],
    [RouteToken.Favorites]: [
        DocListPageSortKey.Favorite,
        DocListPageSortKey.Edited,
        DocListPageSortKey.Created,
        DocListPageSortKey.Name,
    ],
    [RouteToken.Drafts]: [DocListPageSortKey.Edited, DocListPageSortKey.Created, DocListPageSortKey.Name],
    [RouteToken.Trash]: [
        DocListPageSortKey.Deleted,
        DocListPageSortKey.Edited,
        DocListPageSortKey.Created,
        DocListPageSortKey.Name,
    ],
    [RouteToken.Project]: [DocListPageSortKey.Edited, DocListPageSortKey.Created, DocListPageSortKey.Name],
    [RouteToken.Workbench]: [],
}

function getSortItems(keys: DocListPageSortKey[], key: DocListPageSortKey) {
    return [
        { label: SortItemLabel[SortItem.SortKey], value: SortItem.SortKey, disabled: true },
        ...keys.map((k) => ({
            value: k,
            label: SortKeyLabel[k],
        })),
        { label: SortItemLabel[SortItem.SortOrder], value: SortItem.SortOrder, disabled: true, splitLineTop: true },
        ...[SortOrder.Ascending, SortOrder.Descending].map((o) => ({
            value: o,
            label: key === DocListPageSortKey.Name ? SortOrderLabel2[o] : SortOrderLabel1[o],
        })),
    ]
}

export const getSortMenuItems = (props: { route: RouteTokenValue; sortKey?: DocListPageSortKey }) => {
    const { route, sortKey } = props
    const sortKeys = routeMenuItems[route]
    const key = sortKey || sortKeys[0]

    return getSortItems(sortKeys, key)
}

export const getSortedDocs = (docs: ReadonlyArray<FlatDoc>, sortState: SortState): FlatDoc[] => {
    const { sortKey, sortOrder } = sortState
    const sortedDocs = [...docs]
    if (docs.length) {
        sortedDocs.sort((a, b) => {
            switch (sortKey) {
                case DocListPageSortKey.Created:
                    return sortByNumber(b.createdTime, a.createdTime, sortOrder)
                case DocListPageSortKey.Visited:
                    return sortByNumber(b.visitedTime ?? 0, a.visitedTime ?? 0, sortOrder)
                case DocListPageSortKey.Favorite:
                    return sortByNumber(b.favoriteTime ?? 0, a.favoriteTime ?? 0, sortOrder)
                case DocListPageSortKey.Edited:
                    return sortByNumber(
                        Math.max(b.createdTime, b.lastEditedTime),
                        Math.max(a.createdTime, a.lastEditedTime),
                        sortOrder
                    )
                case DocListPageSortKey.Deleted:
                    return sortByNumber(b.deletedTime ?? 0, a.deletedTime ?? 0, sortOrder)
                case DocListPageSortKey.DeleteUserName:
                    return sortByString(a.deleteUser?.nickname ?? '', b.deleteUser?.nickname ?? '', sortOrder)
                case DocListPageSortKey.Name:
                    return sortByString(a.name, b.name, sortOrder)
            }
        })
    }

    return sortedDocs
}

export const getSortStateLabel = (sortState: SortState): string => {
    const { sortKey, sortOrder } = sortState
    if (sortKey && sortOrder) {
        return `${SortKeyLabel[sortKey]}${
            sortKey === DocListPageSortKey.Name ? SortOrderLabel2[sortOrder] : SortOrderLabel1[sortOrder]
        }`
    }
    return ''
}

export const getSortLabelByKey = (key: DocListPageSortKey) => {
    switch (key) {
        case DocListPageSortKey.DeleteUserName:
        case DocListPageSortKey.Name:
            return SortOrderLabel2
        default:
            return SortOrderLabel1
    }
}
