import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Export: '批量导出',
    Settings: '导出设置',
    NoSelectedLayers: '当前选中的范围内没有可导出的切图，请在右边栏的导出模块点击 + 添加导出项。',
    Export_synonyms1: '导出',
    Selected: '已选中（{{count}}/{{quality}}）',
} as const

export const enTranslation = {
    Export: 'Export',
    Settings: 'Settings',
    NoSelectedLayers:
        'The selection have no export settings. Click + in the export section of the properties panel to add one.',
    Export_synonyms1: 'Export',
    Selected: '{{count}} of {{quality}} selected',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string | number>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
