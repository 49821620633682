import { WKItemType, WKMenuConfig } from '../../../../../../../ui-lib/src'
import { useLoadableCanOpenDesktop } from '../../../../../external-store/atoms/desktop-open'

import { downloadDesktopApp, openCurrentPageInDesktop } from '../../../../../main/desktop/desktop'
import { translation } from './invoke-desktop.translation'

export const useInvokeDesktopMenu = (): WKMenuConfig => {
    const loadableCanOpen = useLoadableCanOpenDesktop()

    if (window.localBridge) {
        return []
    }

    if (loadableCanOpen.state === 'loading' || loadableCanOpen.state === 'hasError') {
        return []
    }

    const canOpen = loadableCanOpen.data

    const menus: WKMenuConfig = [
        { type: WKItemType.Divider },
        {
            name: canOpen ? translation('OpenInDesktopApp') : translation('GetDesktopApp'),
            testId: 'invoke-desktop-menu',
            handler: () => {
                if (canOpen) {
                    openCurrentPageInDesktop()
                } else {
                    downloadDesktopApp()
                }
            },
        },
    ].filter((v) => !!v) as WKMenuConfig

    return menus
}
