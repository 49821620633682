import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Join: '节点',
    Miter: '尖角',
    Bevel: '斜面',
    Round: '圆角',
} as const

export const enTranslation = {
    Join: 'Join',
    Miter: 'Miter',
    Bevel: 'Bevel',
    Round: 'Round',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
