/* eslint-disable no-restricted-imports */
import { UpdateCursorWithinCanvasCommand } from '@wukong/bridge-proto'
import { CommandInvoker } from '../../../document/command/command-invoker'
import { RootComponentId } from '../../../main/canvas/canvas'

export class MouseOnCanvas {
    constructor(private commandInvoker: CommandInvoker) {}

    private lastMoveEventWhenMouseOnCanvas: null | MouseEvent = null

    private mouseOnCanvas = false

    private canvasViewElement: HTMLElement | null = null
    // 包含评论本身的 DOM 容器
    private commentOverlayElement: HTMLElement | null = null
    // 包含评论弹框等 DOM 容器
    private commentPopupContainerElement: HTMLElement | null = null

    isMouseOnCanvas() {
        return !!this.mouseOnCanvas
    }

    private getCanvasEventProxyElement = () => {
        return document.getElementById(RootComponentId.CanvasOverlay)
    }

    private mouseEnterCanvas = () => {
        this.mouseOnCanvas = true
        this.commandInvoker.DEPRECATED_invokeBridge(UpdateCursorWithinCanvasCommand, { value: true })
    }

    private mouseMoveOnCanvas = (e: MouseEvent) => {
        this.lastMoveEventWhenMouseOnCanvas = e
    }

    private mouseLeaveCanvas = () => {
        this.lastMoveEventWhenMouseOnCanvas = null
        this.mouseOnCanvas = false
        this.commandInvoker.DEPRECATED_invokeBridge(UpdateCursorWithinCanvasCommand, { value: false })
    }

    provideCanvasViewElement = (viewEl: HTMLElement | null) => {
        if (viewEl && !this.canvasViewElement) {
            this.canvasViewElement = viewEl
            this.canvasViewElement.addEventListener('mousemove', this.mouseMoveOnCanvas, false)
            this.canvasViewElement.addEventListener('mouseenter', this.mouseEnterCanvas, false)
            this.canvasViewElement.addEventListener('mouseout', this.mouseLeaveCanvas, false)
            this.canvasViewElement.addEventListener('dragenter', this.mouseEnterCanvas, false)
            this.canvasViewElement.addEventListener('dragleave', this.mouseLeaveCanvas, false)
        } else if (!viewEl && this.canvasViewElement) {
            this.canvasViewElement.removeEventListener('mousemove', this.mouseMoveOnCanvas)
            this.canvasViewElement.removeEventListener('mouseenter', this.mouseEnterCanvas)
            this.canvasViewElement.removeEventListener('mouseout', this.mouseLeaveCanvas)
            this.canvasViewElement.removeEventListener('dragenter', this.mouseEnterCanvas, false)
            this.canvasViewElement.removeEventListener('dragleave', this.mouseLeaveCanvas, false)
            this.canvasViewElement = null
        }
    }

    replayLastMoveEventOnCanvas = () => {
        const canvasProxyElement = this.getCanvasEventProxyElement()
        if (!canvasProxyElement || !this.lastMoveEventWhenMouseOnCanvas) {
            return
        }
        const mouseEnter = new MouseEvent('mousemove', this.lastMoveEventWhenMouseOnCanvas)
        // 在下一个 tick 重放
        setTimeout(() => {
            canvasProxyElement.dispatchEvent(mouseEnter)
        }, 0)
    }
}
