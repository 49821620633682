import type { TextStyleNode } from '../../../../../document/node/node'
import { LibraryStyleTestId } from '../../../../../window'
import { CommonStyleInfo } from '../get-style-nodes-info-map'
import { StyleListItem, StyleListItemKeyboardProps } from '../style-items-layout/style-list-item'
import style from './style-text-list-item.module.less'
import { createITextStyle } from './style-text-utils'
import { TextStyleThumbnail } from './style-thumbnail'

interface Props {
    item: TextStyleNode
    docId: string
    openStyleId?: string
    className?: string
    disabledOnClick?: boolean
    children?: React.ReactNode
    onSelectItem: (item: CommonStyleInfo) => void
    onClickHoverIcon?: (
        item: CommonStyleInfo,
        containerDomRect: DOMRect,
        remoteStyleId?: string,
        docId?: string,
        name?: string,
        fromFig?: boolean
    ) => void
    onContextMenu?: (
        item: CommonStyleInfo,
        e: any,
        remoteStyleId?: string,
        docId?: string,
        name?: string,
        fromFig?: boolean
    ) => void
}

export function UnknowStyleTextListItem(props: Props & StyleListItemKeyboardProps) {
    const { item, openStyleId, onSelectItem, onClickHoverIcon, onContextMenu } = props
    const { id, name, description, fromFig } = props.item
    const docId = props.docId || item.publishFile

    return (
        <StyleListItem
            item={{
                id: id,
                name: name || '',
                description: description || '',
                groupName: '',
                styleName: '',
            }}
            isReadonly
            openStyleId={openStyleId}
            onSelectItem={onSelectItem}
            onClickHoverIcon={
                onClickHoverIcon &&
                (async (itemValue, containerDomRect) => {
                    onClickHoverIcon(itemValue, containerDomRect, item.publishId, docId, item.name, fromFig)
                })
            }
            onContextMenu={(itemValue, e) => {
                onContextMenu?.(itemValue, e, item.publishId, docId, item.name, fromFig)
            }}
            dataTestId={`${LibraryStyleTestId.UnknownTextStyleItem}-${item.id}`}
            index={props.index}
            selectStyleId={props.selectStyleId}
            setPreselectIndex={props.setPreselectIndex}
            trySetPreselectIndex={props.trySetPreselectIndex}
            recordEnterCallback={props.recordEnterCallback}
        >
            <TextStyleThumbnail
                className={style.thumbnail}
                styleData={createITextStyle(item)}
                width={16}
                height={16}
                textStyleId={item.id}
            />
            <div className={style.name}>{item.name}</div>
        </StyleListItem>
    )
}
