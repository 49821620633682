import { useEffect, useRef, useState } from 'react'
import { WKLoading } from '../../../../../../ui-lib/src/components/wk-loading/wk-loading'
import { TraceableAbortController } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { getEnvironment } from '../../../../environment'
import { OverviewHTML } from './shadcn-overview-html'
import { SampleHTML } from './shadcn-sample-html'
import { useAIGenUIInContext } from './use-ai-gen-ui'

function colorToShadcnTheme(color: string) {
    switch (color) {
        case '#18181B':
            return 'zinc'
        case '#DC2828':
            return 'red'
        case '#E21D48':
            return 'rose'
        case '#F97415':
            return 'orange'
        case '#16A249':
            return 'green'
        case '#2463EB':
            return 'blue'
        case '#FACC14':
            return 'yellow'
        case '#7C3BED':
            return 'violet'
        default:
            return 'zinc'
    }
}

export function ShadcnUISampleOrOverview({ type }: { type: 'sample' | 'overview' }) {
    const { tempRemixConfig, getFontFace } = useAIGenUIInContext()
    const ref = useRef<HTMLDivElement>(null)
    const shadowRootRef = useRef<ShadowRoot | null>(null)
    const [waitForCSS, setWaitForCSS] = useState(false)

    useEffect(() => {
        ;(async () => {
            if (ref.current !== null && shadowRootRef.current === null) {
                shadowRootRef.current = ref.current.attachShadow({ mode: 'closed' })
                const env = getEnvironment()
                const cssURL = `${env.ossCdnPath}app/public/resource/external-style/shadcn.css`
                shadowRootRef.current.innerHTML = type === 'sample' ? SampleHTML(cssURL) : OverviewHTML(cssURL)
            }
        })()
    }, [type])

    useEffect(() => {
        if (shadowRootRef.current) {
            const abc = new TraceableAbortController('Remove listen loaded')
            setWaitForCSS(true)
            const styles = shadowRootRef.current.querySelectorAll('link')
            const rootC = shadowRootRef.current.querySelector('.root-c') as HTMLDivElement
            styles.forEach((style) => {
                style.addEventListener(
                    'load',
                    () => {
                        rootC.style.setProperty('display', 'block')
                        setWaitForCSS(false)
                    },
                    { signal: abc.signal }
                )
            })
            return () => {
                abc.abort('Remove listen loaded')
            }
        }
    }, [])
    const primaryColor = tempRemixConfig?.get('primaryColor') ?? '#18181B'
    const mode = tempRemixConfig?.get('mode') ?? 'light'
    const font = tempRemixConfig?.get('font') ?? 'Geist sans'
    const radius = tempRemixConfig?.get('cornerSize') ?? 0.5
    const shadcnTheme = colorToShadcnTheme(primaryColor)

    useEffect(() => {
        if (shadowRootRef.current) {
            const rootC: HTMLDivElement | null = shadowRootRef.current.querySelector('.root-c')
            const rootTheme: HTMLDivElement | null = shadowRootRef.current.querySelector('.root-theme')
            if (rootC && rootTheme) {
                rootC.className = `root-c ${mode === 'dark' ? 'dark' : 'light'}`
                rootTheme.className = `theme-${shadcnTheme} root-theme`
                rootTheme.style.setProperty('--radius', `${radius}rem`)
                rootC.style.setProperty('color-scheme', mode === 'dark' ? 'dark' : 'light')
                rootC.style.setProperty('font-family', font)
                rootC.style.setProperty('transform', 'scale(1)')
                rootC.style.setProperty('transform-origin', 'center')
                if (type === 'sample') {
                    rootC.style.setProperty('width', '1534px')
                } else {
                    rootC.style.setProperty('width', '1440px')
                    rootC.style.setProperty('height', '1440px')
                }
            }
        }
    }, [shadcnTheme, mode, radius, font, type])

    useEffect(() => {
        const rootC = shadowRootRef.current?.querySelector('.root-c')
        if (rootC) {
            getFontFace()
                .then((fontFace) => {
                    if (fontFace) {
                        document.fonts.add(fontFace)
                    }
                })
                .catch(() => {
                    // Ignore Font Load Error
                })
        }
    }, [getFontFace])

    return (
        <div className="w-full h-full p-4">
            <div
                className={`z-[-1] w-fit pointer-events-none origin-top-left transform-gpu ${
                    type === 'sample'
                        ? 'scale-36.5 translate-x-3.75 translate-y-18'
                        : 'scale-38.80 translate-x-3.85 translate-y-3.75'
                } rounded-3 ${waitForCSS ? 'hidden' : 'block'}`}
                style={{ boxShadow: `0 0 0 ${type === 'sample' ? 5.5 : 0}px var(--wk-gray-13-8)` }}
                ref={ref}
            ></div>
            <div className={`w-full h-full flex justify-center items-center ${waitForCSS ? 'block' : 'hidden'}`}>
                <WKLoading noText />
            </div>
        </div>
    )
}
