import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    contrast: '对比度',
    standard: '标准',
    medium: '中',
    high: '高',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    contrast: 'Contrast',
    standard: 'Standard',
    medium: 'Medium',
    high: 'High',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
