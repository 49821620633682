import { Wukong } from '@wukong/bridge-proto'
import { ContractPeriod, PayChannelType, PayPlanType, SeatType } from '../kernel/interface/type'

///// 工作台
export const SpaceTestId = {
    TeamMember: {
        PermissionSelectTrigger: 'team-member-permission-select-trigger', // 团队成员权限筛选
    },
}

///// 编辑器通用

export const EditorDataTestId = {
    DocumentContentLoading: 'document-content-loading',
    DocumentContentReady: 'document-content-ready',
    CreateDoc: 'file-card-create',
    Canvas: 'canvas',
    CanvasOverlay: 'canvas-overlay',
    Topbar: 'topbar',
    RightPanel: 'right-panel',
    LeftPanel: 'left-panel',
    LayerPanel: 'layer-panel',
    LayerPanelContextMenu: 'layer-panel-right-click-menu',
    PagePanelContextMenu: 'page-panel-right-click-menu',
    ContextMenuHandle: 'right-click-menu-handle',
    PopupContainer: 'pop-up-container',
    PagePanelSwitch: 'page-panel-switch',
    AssetPanelSwitch: 'library-switch', // 资产面板
    // 属性面板-描边
    StrokeAttr: {
        Container: 'stroke-attr-body',
        SelectAlign: 'stroke-attr-select-align',
        SelectAlignTrigger: 'stroke-attr-select-align-trigger',
        SelectAlignPrefix: (index: number) => `stroke-attr-select-align-${index}`,
        WeightInput: 'stroke-attr-weight-input',
        WeightInputLabel: 'stroke-attr-weight-input-label',
        IndependentTypeSelect: 'stroke-attr-independent-type-select',
        IndependentTypeSelectTrigger: 'stroke-attr-independent-type-select-trigger',
        IndependentTypeSelectPrefix: (index: number) => `stroke-attr-independent-type-select-${index}`,
        LeftBorderInputLabel: 'stroke-attr-left-border-input-label',
        TopBorderInputLabel: 'stroke-attr-top-border-input-label',
        RightBorderInputLabel: 'stroke-attr-right-border-input-label',
        BottomBorderInputLabel: 'stroke-attr-bottom-border-input-label',
        MoreSetting: 'stroke-attr-more-setting',
        ModeSelectTrigger: 'stroke-attr-mode-select-trigger',
        DashLenInput: 'stroke-attr-dash-len-input',
        DashLenInputLabel: 'stroke-attr-dash-len-input-label',
        DashGapInput: 'stroke-attr-dash-gap-input',
        DashGapInputLabel: 'stroke-attr-dash-gap-input-label',
        CapSelects: 'stroke-attr-cap-select',
        CapSelectTriggers: 'stroke-attr-cap-select-trigger',
        CapSwitchBtn: 'stroke-attr-cap-switchBtn',
    },
    // 属性面板-基础属性
    BaseAttribute: {
        WidthMinMaxSizeDropdownTrigger: 'width-min-max-size-dropdown-trigger',
        HeightMinMaxSizeDropdownTrigger: 'height-min-max-size-dropdown-trigger',
        MinMaxSizeDropdownOption: (index: number) => `min-max-size-dropdown-option-${index}`,
        MinWInputLabel: 'min-w-input-label',
        MinHInputLabel: 'min-h-input-label',
        MaxWInputLabel: 'max-w-input-label',
        MaxHInputLabel: 'max-h-input-label',
        MinMaxSizeInput: 'min-max-size-inner-input', // 极值输入框
    },
    AutoLayout: {
        AlignPad: 'autolayout-align-pad', // 对齐模块
        SpaceInput: (type: 'space' | 'counterSpace') => `autolayout-input-${type}`,
        SpaceSelectTrigger: (type: 'space' | 'counterSpace') => `autolayout-select-trigger-${type}`,
        SpaceSelectOptionItem: (type: 'space' | 'counterSpace') => `autolayout-select-options-item-${type}`,
    },
    Sync: {
        OfflineIcon: 'sync-offline-icon', // 离线图标
        OfflineEditModal: 'sync-offline-edit-modal', // 离线编辑提示弹窗
        OfflineEditToast: 'sync-offline-edit-toast', // 离线编辑 toast
        OfflineEditMoreDetailBtn: 'sync-offline-edit-more-detail-btn', // 离线编辑更多详情按钮
        OfflineEditSyncProgressToast: 'sync-offline-edit-sync-progress-toast', // 离线编辑同步进度 toast
    },
}

///// 评论

export const CommentDataTestId = {
    CommentContent: 'comment-content',
    CommentBubble: (index: number) => `comment-bubble-${index}`,
    CommentPendingBubble: (index: number) => `comment-pending-bubble-${index}`,
    CommentClusterBubble: (index: number) => `cluster-bubble-${index}`,
    CommentClusterBubbleCover: (index: number) => `cluster-bubble-cover-${index}`,
    CommentClusterBubbleContent: (index: number) => `cluster-bubble-content-${index}`,
    CommentClusterBubbleBackgroud: (index: number) => `cluster-bubble-background-${index}`,
    CommentBubbleDraft: 'comment-draft',
    CommentDraftInput: 'comment-draft-input',
    CommentRangeDraft: 'comment-range-draft', // 草稿的虚线框
    CommentRangeHover: 'comment-range-hover', // 悬浮状态下显示的虚线框
    CommentRangeActive: 'comment-range-active', // 选中状态下的虚线框
    CommentAnchorActive: 'comment-anchor-active', // 选中状态下的锚点
    CommentAnchorDraft: 'comment-anchor-draft',
    CommentDetail: 'comment-detail',
    CommentDetailMoreOperation: 'comment-detail-more-operation',
    CommentDetailCopyLink: 'comment-detail-copy-link',
    CommentDetailUnread: 'comment-detail-unread',
    CommentDetailRemove: 'comment-detail-remove',
    CommentDetailRemoveConfirm: 'comment-detail-remove-confirm',
    CommentDetailResolve: 'comment-detail-resolve',
    CommentDetailLeftTop: 'comment-detail-left-top',
    CommentDetailClose: 'comment-detail-close',
    CommentEditorDraft: 'comment-editor-draft', // 草稿输入框
    CommentEditorDraftInput: 'comment-editor-draft-input', // 草稿输入 input
    CommentEditorReplyInput: 'comment-editor-reply-input', // 编辑回复输入 input
    CommentEditorDetail: 'comment-editor-detail', // 详情输入框
    CommentEditorReply: 'comment-editor-reply', // 编辑回复输入框
    CommentPanel: `comment-panel`,
    CommentPanelItem: `comment-panel-item`,
    CommentPanelDom: {
        container: 'container',
        moreCtrl: 'moreCtrl',
        resolvedIcon: 'resolvedIcon',
        readItem: 'readItem',
        unReadItem: 'unReadItem',
        copyLinkItem: 'copyLinkItem',
    },
    CommentReplyItem: 'comment-reply-item',
    CommentReplyPendingItem: 'comment-reply-pending-item',
} as const

export const ComponentTestIds = {
    nameText: 'component-name-text',
    descText: 'component-desc-text',
    publishBtn: 'component-publish-btn',
}
export const CandidateComponentTestIds = {
    nameText: 'candidate-component-name-text',
    publishBtn: 'candidate-component-publish-btn',
    replaceBtn: 'candidate-component-replace-btn',
}

export const CandidateComponentPublishDialogTestIds = {
    publishIcon: 'candidate-component-publish-icon',
    rightClickWrapper: 'candidate-component-right-click-wrapper',
}

export const CandidateComponentReplaceDialogTestIds = {
    selectBtn: 'candidate-component-replace-dialog-select-btn',
    confirmBtn: 'candidate-component-replace-dialog-confirm-btn',
    cancelBtn: 'candidate-component-replace-dialog-cancel-btn',
    previewWrapper: 'candidate-component-replace-dialog-preview-wrapper',
}

export const CandidateComponentReplacePanelTestIds = {
    popup: `candidate-component-instance-replace-panel-popup`,
    closeBtn: `candidate-component-instance-replace-panel-popup-close-btn`,
    body: `candidate-component-instance-replace-panel`,
    title: 'candidate-component-instance-replace-title',
    searchInput: `candidate-component-instance-replace-panel-search`,
    searchResult: 'candidate-component-instance-replace-panel-search-result',
    noResult: 'candidate-component-instance-replace-panel-search-no-result',
    upper: 'candidate-component-instance-replace-upper',
    folder: (name: string) => `candidate-component-instance-folder-${name}`,
    currentFolder: (name: string) => 'candidate-component-instance-folder-current-' + name,
    currentPreview: 'candidate-component-instance-preview-current',
    preview: (name: string) => 'candidate-component-instance-preview-' + name,
}

export const CandidateStyleTestId = {
    LocalPaintStyleItem: 'local-paint-style-item', // 本地颜色样式Item
}

export const LibraryStyleTestId = {
    RemotePaintStyleItem: 'remote-paint-style-item', // 远程颜色样式Item
    UnknownPaintStyleItem: 'unknown-paint-style-item', // 未知颜色样式Item
    LocalPaintStyleItem: 'local-paint-style-item', // 未知颜色样式Item

    RemoteEffectStyleItem: 'remote-effect-style-item', // 远程效果样式Item
    UnknownEffectStyleItem: 'unknown-effect-style-item', // 未知效果样式Item
    LocalEffectStyleItem: 'local-effect-style-item', // 未知效果样式Item

    RemoteTextStyleItem: 'remote-text-style-item', // 远程文字样式Item
    UnknownTextStyleItem: 'unknown-text-style-item', // 未知文字样式Item
    LocalTextStyleItem: 'local-text-style-item', // 未知文字样式Item

    RemoteLayoutGridStyleItem: 'remote-Layout-grid-style-item', // 远程布局网格样式Item
    UnknownLayoutGridStyleItem: 'unknown-Layout-grid-style-item', // 未知布局网格样式Item
    LocalLayoutGridStyleItem: 'local-Layout-grid-style-item', // 未知布局网格样式Item
}

// 样式相关的TestId
export const LocalStyleTestId = {
    StyleNameInput: 'style-name-input', // 样式弹窗中输入自定义样式名的input
    TextStyleListContainer: 'text-style-list-contianer',
    TextStyleListItem: 'text-style-list-item-',
    PaintStyleListContainer: 'paint-style-list-contianer',
    PaintStyleListItem: 'paint-style-list-item-',
    EffectStyleListContainer: 'effect-style-list-contianer',
    EffectStyleListItem: 'effect-style-list-item-',
    LayoutGridStyleListContainer: 'layout-grid-style-list-contianer',
    LayoutGridStyleListItem: 'layoutgrid-style-list-item-',
}

// toast 相关的 TestId
export const ToastTestId = {
    ErrorMessage: 'toast-error-message',
    CloseButton: 'toast-close-button',
    Toast: 'toast-show',
}

export const ExportTestId = {
    AddExportBtn: 'add-export-btn',
    ExportBtn: 'export-btn',
    ExportType: 'export-type',
}

export const MirrorTestId = {
    Login: {
        email: 'login-email',
        password: 'login-password',
        submit: 'login-button',
    },

    Home: {
        Root: 'home-root',
        TitleRightBtn: 'title-right-btn',
        MenuUser: 'menu-user',
        MenuList: 'menu-list',

        MenuShowMore: 'menu-showmore',
        MenuUnregister: 'menu-unregister',
        MenuExit: 'menu-exit',
        Logout: 'logout',
        Unregister: 'unregister',

        MenoMore: 'more',
        ServicePolicy: 'service-policy',
        PrivacyPolicy: 'privacy-policy',
    },

    Mirror: {
        DocCanvas: 'mirror-doc-canvas',
        DocReady: 'mirror-doc-ready',
    },
}

// 组件库 TestId
export const LibraryTestId = {
    // 首页弹窗
    HomeModal: {
        Id: 'library-home-modal', // 弹窗主体
        CloseBtn: 'close-library-modal', // 弹窗关闭按钮

        CurrentLocalTab: 'library-current-local-tab', // 本地组件库 tab
        CurrentBody: 'current-remote-library-detail', // 本地组件库详情 body
        CurrentEmptyPlaceholder: 'empty-current-library', // 本地组件库为空
        GoPublishLibrary: 'library-go-publish-current', // 去发布按钮
        GoUpdateLibrary: 'library-go-publish-update', // 去发布更新按钮
        UnpublishBtn: 'library-unpublish-button', // 取消发布按钮
        UpdateWarning: 'library-current-detail-change-alert', // 本地组件库发布更新 warning tip

        RemoteTab: 'library-remote-tab', // 引用组件库 tab
        GoUpdateRemoteBtn: 'library-go-update-remote-btn', // 打开引用更新弹窗 warning tip
        RemoteListBody: 'library-home-modal-remote-list-body', // 引用组件库列表 body
        RemoteListSearchInput: 'library-remote-list-search-input', // 引用组件库列表搜索输入框

        InUsedList: 'library-in-used-list', // 此文件使用组件库列表

        SubscriptionSwitch: (libraryName: string, checked: boolean) =>
            `switch-library-${libraryName}-${checked ? 'checked' : 'unchecked'}`, // 订阅其他组件库开关
        RemoteLibraryItem: (libraryName: string) => `open-library-${libraryName}`, // 单条远程组件库条目

        OpenMissingListBtn: 'open-missing-library-list', // 打开缺失组件库列表
        MissingList: 'missing-library-list', // 缺失组件库列表
        MissingItem: (libraryName: string) => `missing-library-item-${libraryName}`, // 缺失组件库列表项
    },
    // 引用更新弹窗
    RemoteUpdateModal: {
        ChangeItem: (libraryName: string) => `library-remote-change-${libraryName}`, // 引用更新列表项
        SingleUpdateBtn: 'library-single-update-btn', // 单个更新按钮
    },
    // 发布弹窗
    PublishModal: {
        SubmitBtn: 'confirm-publish-library', // 确认发布按钮
        Body: 'library-publish-modal-body', // 弹窗主体
        CloseBtn: 'library-publish-modal-close-btn', // 关闭按钮
        ShareScopeSelectContainer: 'library-share-scope-select-container', // 分享范围选择容器
        ShareScopeSelect: 'library-share-scope-select', // 分享范围下拉选择
        ShareScopeSelectItem: (shapeScopeType: number) => `library-share-scope-select-item-${shapeScopeType}`, // 分享范围下拉选择
        PrivateTeamWarning: 'library-private-team-warning', // 私有团队组件库发布到组织时出现的 warning
        SelectedPublishItem: 'library-selected-publish-item', // 选中的发布项
        VariableSetDetail: 'library-variable-set-detail', // 变量合集详情
        VariableDetail: 'library-variable-detail', // 变量详情
        BackBtnFromVariableDetail: 'library-back-btn-from-variable-detail', // 从变量详情返回
        UnchangeItem: 'library-unchange-item', // 未修改项
    },
    DraftPublishModal: {
        ConfirmMoveBtn: 'library-confirm-move-draft', // 确认移动文件按钮
        CancelMoveBtn: 'library-cancel-move-draft', // 取消移动文件按钮
    },
    // 替换弹窗
    ReplaceModal: {
        Body: 'library-replace-modal-body', // 弹窗主体
        TargetSwitchBtn: 'library-replace-modal-target-switch-btn', // 切换目标库按钮
        BackBtn: 'library-replace-modal-back-btn', // 返回按键
        SubmitBtn: 'library-replace-modal-submit-btn', // 提交按键
        StyleReplaceItem: 'library-replace-modal-style-replace-item', // 样式替换项
        ComponentReplaceItem: 'library-replace-modal-component-replace-item', // 组件替换项
        ComponentSetReplaceItem: 'library-replace-modal-component-set-replace-item', // 组件集替换项
    },
    // 恢复弹窗
    RecoverModal: {
        MovedComponentModal: 'library-recover-moved-component-modal', // 恢复被移走的组件弹窗
        MovedComponentToast: 'library-recover-moved-component-toast', // 组件被移走 toast
        RemovedComponentModal: 'library-recover-removed-component-modal', // 恢复被删除的组件弹窗
        RemovedComponentToast: 'library-recover-removed-component-toast', // 组件被删除 toast
    },
    RemoteDetailModal: {
        Body: 'remote-library-detail', // 组件库详情页
        BackBtn: 'library-remote-detail-back-btn', // 返回按键
    },
    // 资产面板
    AssetPanel: {
        Tab: EditorDataTestId.AssetPanelSwitch, // 资产面板 tab
        Body: 'library-asset-panel-body', // 资产面板主体
        ToggleModalBtn: 'IconBookBigOpen16', // toggle 弹窗显示按钮
        ListLayoutBtn: 'icon-layout-list-svg', // 切换至列表视图按钮
        GridLayoutBtn: 'icon-layout-grid-svg', // 切换至网格视图按钮
        SearchInput: 'library-asset-panel-search-input', // 搜索输入框
        SearchEmptyPlaceholder: 'library-asset-panel-search-empty-placeholder', // 搜索为空提示
        HeaderItemPrefix: 'library-asset-panel-collapse-library-',
        HeaderItem: (libraryName?: string) =>
            `${LibraryTestId.AssetPanel.HeaderItemPrefix}${libraryName || '本地组件'}`, // 组件库折叠条目
        FolderItem: 'library-asset-panel-preview-folder-item', // 组件名称路径折叠条目
        UpdateHint: 'library-update-hint', // 提示更新小蓝点
    },
    Viewer: {
        Component: `library-viewer-component`, // 组件预览
        ComponentItem: (nodeId: string) => `library-viewer-component-${nodeId}`, // 组件预览
        Style: `library-viewer-style`, // 样式预览
        DragImage: `library-viewer-drag-image`, // 拖拽时跟随鼠标的图片
    },
    ThumbnailImage: `library-thumbnail-image`, // 缩略图
    TeamSettingDefaultLibraryEntry: 'team-setting-default-library-entry', // 团队设置默认库入口
    OrgDefaultLibrary: {
        DetailModal: 'org-default-library-detail-modal', // 企业默认组件库详情弹窗
        DetailModalSwitch: (enabled: boolean) =>
            'org-default-library-detail-modal-switch' + enabled ? '-checked' : '-unchecked', // 启用为默认组件库切换按钮
        ShareScopeWarningTip: 'org-default-library-share-scope-warning-tip', // 企业默认组件库分享范围警告提示
        EnabledText: `org-default-library-enabled-text`, // 启用为默认组件库文案
    },
}

// 右侧属性栏-替换实例弹窗
export const ReplaceInstancePanelTestId = {
    popup: `instance-replace-panel-popup`,
    closeBtn: `instance-replace-panel-popup-close-btn`,
    body: `instance-replace-panel`,
    title: 'instance-replace-title',
    searchInput: `instance-replace-panel-search`,
    searchResult: 'instance-replace-panel-search-result',
    noResult: 'instance-replace-panel-search-no-result',
    upper: 'instance-replace-upper',
    folder: (name: string) => `instance-folder-${name}`,
    currentFolder: (name: string) => 'instance-folder-current-' + name,
    currentPreview: 'instance-preview-current',
    preview: (name: string) => 'instance-preview-' + name,
    thumbnailTooltipName: 'instance-thumbnail-tooltip-name',
    thumbnailTooltipDesc: 'instance-thumbnail-tooltip-desc',
}

// 右侧属性栏-组件属性
export const ComponentPropTestId = {
    ComponentPicker: {
        currentPreviewImage: (name: string) => `component-picker-current-preview-image-${name}`, // 当前选中的组件预览
        previewImage: (name: string) => `component-picker-preview-image-${name}`, // 组件预览
        preferredValuesItem: (index: number) => `preferred-values-item-${index}`, // 首选值列表项
        preferredValuesList: 'preferred-values-list', // 首选值列表
        librarySelectTrigger: 'library-select-trigger', // 组件库选择器
        librarySelectList: 'library-select-list',
        searchInput: 'component-picker-search-input', // 搜索输入框
        searchResult: 'component-picker-search-result', // 搜索结果
        searchInputClear: 'component-picker-search-input-clear', // 搜索输入框清除按钮
        listViewIcon: 'component-picker-list-view-icon', // 列表视图图标
        gridViewIcon: 'component-picker-grid-view-icon', // 网格视图图标
        preferredValuesAddBtn: 'preferred-values-add-btn', // 打开首选值选择器按钮
        noLocalComponents: 'no-local-components', // 本地没有组件
        currentFolderCheckBox: 'component-picker-current-folder-checkbox', // 当前文件夹复选框
    },
    InstanceAssignmentPanel: {
        InstanceSwapSelectTrigger: (name: string) => `instance-swap-select-trigger-${name}`, // 实例替换下拉选择器
    },
    ComponentConfig: {
        popup: 'component-config-popup', // 组件配置弹窗
        popupSimplifyInstanceBtn: 'simplify-btn', // 简化所有实例按钮
    },
    ComponentTitlePanel: {
        panel: 'component-title-panel', // 组件标题面板
        nameInput: 'component-name-input', // 组件名称输入框
        adjustConfigBtn: 'component-config-adjust-btn', // 调整配置按钮
        descPreview: 'component-desc-preview', // 描述预览
    },
}

// 右侧属性栏-实例面板
export const InstancePanelTestId = {
    mainCompName: 'instance-main-comp-name', // 母版展示区域
    goToMainBtn: 'instance-go-to-main-btn', // 转到母版
    applyInstanceSwapPropBtn: 'instance-apply-swap-prop-btn', // 应用实例替换属性按钮
    goToRemoteBtn: 'instance-go-to-remote-btn', // 转到远程母版
    moreActionBtn: 'instance-more-action-btn', // 更多设置按钮
    detachBtn: 'instance-detach-btn', // 分离实例按钮
    pushChangesBtn: 'instance-push-changes-btn', // 覆盖母版按钮
    noResetItem: 'instance-no-reset-props', // 没有要重置的属性
    resetBtn: (key: string) => `instance-reset-prop-${key}`, // 重置属性
    descPreview: 'instance-desc-preview', // 描述预览
    showMoreDesc: 'instance-show-more-desc', // 显示更多描述
    selectInstance: 'select-instance', // 选中实例按钮
}

// 离线文件同步弹窗 TestId
export const OfflineSyncDocTestId = {
    FooterSyncBtn: 'offline-sync-doc-footer-sync-btn', // 底部同步按钮
    SyncItem: 'offline-sync-doc-sync-item', // 单条同步记录
    SimpleSyncBtn: 'offline-sync-doc-simple-sync-btn', // 单条同步记录-同步按钮
    ModalCloseBtn: 'offline-sync-doc-modal-close-btn', // 弹窗关闭按钮
    SyncedText: 'offline-sync-doc-synced-text', // 已同步文案
}

// 协作 TestId
export const CooperationTestId = {
    ActiveUserList: 'cooperation-active-user-list', // 常驻协作者列表
    MoreActiveUserList: 'cooperation-more-active-user-list', // 更多协作者列表
    AvatarItem: (role: Wukong.DocumentProto.ActiveUserRole) => `cooperation-avatar-item-${role}`, // 协作者头像
    MoreActiveUserTrigger: 'cooperation-more-active-user-trigger', // 更多协作者列表展开按钮
    ObservingBorder: 'cooperation-observing-border', // 观察模式边框
    CursorItem: `cooperation-cursor-item`, // 协作者光标
}

// 原型 TestId
export const PrototypeTestId = {
    InteractionPopup: {
        TransitionTypeSelectTrigger: 'interaction-transition-type-select-trigger', // 交互行为设置-过渡类型下拉选择器
        TransitionTypeSelectMenu: 'interaction-transition-type-select-menu', // 交互行为设置-过渡类型下拉弹窗
        TransitionTypeSelectOption: (disabled: boolean, selected: boolean) =>
            `interaction-transition-type-select-option${disabled ? '-disabled' : ''}${selected ? '-selected' : ''}`, // 交互行为设置-过渡类型下拉选择器选项
        TransitionDirectionBtn: (selected: boolean) =>
            `interaction-transition-direction-btn${selected ? '-selected' : ''}`, // 交互行为设置-过渡方向按钮
        ShouldSmartAnimateCheckbox: 'interaction-should-smart-animate-checkbox', // 交互行为设置-智能动画勾选框
        EasingTypeSelectTrigger: 'interaction-easing-type-select-trigger', // 交互行为设置-缓动类型下拉选择器
        EasingTypeSelectMenu: 'interaction-easing-type-select-menu', // 交互行为设置-缓动类型下拉弹窗
        EasingTypeSelectOption: 'interaction-easing-type-select-option', // 交互行为设置-缓动类型下拉选择器选项
        TransitionDurationInput: 'interaction-transition-duration-input', // 交互行为设置-过渡时长输入框
        EasingFunctionInput: 'interaction-easing-function-input', // 交互行为设置-缓动函数输入框
        AnimationPreviewArea: 'interaction-animation-preview-area', // 交互行为设置-动画预览区域
        AnimationEasingSpringPreview: 'animation-easing-spring-preview', // 交互行为设置-弹性动画预览区域
        AnimationEasingSpringCurve: 'animation-easing-spring-curve', // 交互行为设置-弹性动画曲线
        SpringStiffnessInput: 'interaction-spring-stiffness-input', // 交互行为设置-弹性动画刚度输入框
        SpringDampingInput: 'interaction-spring-damping-input', // 交互行为设置-弹性动画阻尼输入框
        SpringMassInput: 'interaction-spring-mass-input', // 交互行为设置-弹性动画质量输入框
    },
}

// 研发模式-盒模型 TestId
export const DevModeBoxModelTestId = {
    Container: 'dev-mode-box-model',
    Margin: `dev-mode-box-model-margin`,
    Padding: `dev-mode-box-model-padding`,
    BorderRadius: `dev-mode-box-model-border-radius`,
    LayoutContent: 'dev-mode-box-model-layout-content',
    AssetContent: 'dev-mode-box-model-asset-content',
    TextContent: 'dev-mode-box-model-text-content',
}

// 支付 TestId
export const PaymentTestId = {
    Summary: {
        ViewAutoApproveBtn: 'payment-summary-view-auto-approve-btn', // 查看自动审批列表按钮
        ApplicationItem: 'payment-summary-application-item', // 席位申请列表项
        ApplicationListEmpty: 'payment-summary-application-list-empty', // 席位申请列表为空
    },
    MemberList: {
        InGracePeriodPaidSeatCheckbox: (checked: boolean) =>
            `payment-member-list-in-grace-period-paid-seat-${checked ? 1 : 0}`, // 处于宽限期的付费席位复选框
    },
    Contract: {
        AskCreateTeamDialog: 'payment-contract-ask-create-team-dialog', // 询问是否创建团队弹窗
        SelectTeamModal: 'payment-contract-select-team-modal', // 选择团队弹窗
        SelectUpgradeTeamBtn: (teamId: string) => `payment-contract-select-upgrade-team-btn-${teamId}`, // 选择团队按钮
        StepBar: (allStep: number) => `payment-contract-step-bar-${allStep}`, // 步骤条
        Step: (step: number) => `payment-contract-step-${step}`, // 步骤条按钮
        ModalCloseBtn: `payment-contract-modal-close-btn`, // 弹窗关闭按钮
        OrganizationTeamCheckbox: (teamId: string) => `payment-contract-organization-team-checkbox-${teamId}`, // 组织版团队复选框
        NextBtn: `payment-contract-next-btn`, // 下一步按钮
        SeatCountInput: (type: SeatType) => `payment-contract-seat-count-input-${type}`, // 席位数量输入框
        SelectPeriodOption: (period: ContractPeriod) => `payment-contract-select-period-option-${period}`, // 选择付费周期选项
        OrganizationSeatCountMinWarning: 'org-seat-count-min-warning', // 企业版编辑人数警告
        ProfessionalSeatCountMinWarning: 'pro-seat-count-min-warning', // 专业版编辑人数警告
        AgreementCheckbox: 'payment-agreement-checkbox', // 隐私协议 checkbox
        LegalCompanyNameInput: 'legal-company-name-input', // 公司发票用名称输入框
        DisplayCompanyNameInput: 'display-company-name-input', // 公司展示名称输入框
        ExpiredPayWaningModal: 'payment-contract-expired-pay-warning-modal', // 过期账单支付警告弹窗
        ProfessionalCompanyForm: 'professional-company-form', // 专业版公司信息表单
        OrganizationCompanyForm: 'organization-company-form', // 企业版公司信息表单
        NationalBoltPayModal: 'national-bolt-pay-modal', // 国内支付弹窗
        NationalBoltPayChannelTabItem: 'national-bolt-pay-channel-tab-item', // 国内支付方式
        NationalBoltPayPreview: (channelType: PayChannelType) => `national-bolt-pay-preview-${channelType}`, // 国内支付方式预览（二维码、网银列表）
        PayCompleted: 'payment-contract-pay-completed', // 支付成功页
    },
    Settlement: {
        BaseInfo: 'payment-settlement-base-info', // 套餐基本信息
        NextInvoiceDate: 'payment-settlement-next-invoice-date', // 下期账单日期
        NewDesignerSeat: 'payment-settlement-new-designer-seat', // 新增设计席位
        NewDevSeat: 'payment-settlement-new-dev-seat', // 新增研发席位
        NextInvoiceDetailBtn: 'payment-settlement-next-invoice', // 下期核对账单入口
        NextInvoiceDetailNoChangesText: 'payment-settlement-next-invoice-no-changes', // 下期核对账单无变更
        ViewDetailBtn: (envValue: 'abroad' | 'national') => `${envValue}-payment-settlement-view-detail-btn`, // 查看费用明细入口
        NationalNoChangeInvoiceDetailBtn: `national-payment-settlement-no-change-invoice`, // 国内账单无变更入口
        NationalPayViewDetailBtn: 'national-payment-settlement-pay-view-detail-btn', // 国内去支付查看费用明细入口
        NationalFirstOrderInvoiceModal: 'national-first-order-invoice-modal', // 国内首次订购账单弹窗
        NationalRenewOrderInvoiceModal: 'national-renew-order-invoice-modal', // 国内续订账单弹窗
        NationalModalPayBtn: 'national-modal-pay-btn', // 国内账单弹窗支付按钮
        AbroadPayExpiredLink: 'abroad-payment-settlement-pay-expired-link', // 国外账单过期支付链接
        ReactivePlanBtn: 'payment-settlement-reactive-plan-btn', // 恢复订阅按钮
    },
    TeamSetting: {
        AbroadPlanSettlementExpiredWarning: 'payment-team-setting-plan-settlement-expired-warning', // 海外-逾期账单警告
        CancelPlanBtn: 'payment-team-setting-cancel-plan-btn', // 取消订阅按钮
        ReactivePlanBtn: 'payment-team-setting-reactive-plan-btn', // 恢复订阅按钮
        ChangeBillingPeriodBtn: 'payment-team-setting-change-billing-period-btn', // 更改订阅周期按钮
        CancelPlanConfirmModal: 'payment-team-setting-cancel-plan-confirm-modal', // 取消订阅确认弹窗
        CancelPlanConfirmModalOkBtn: 'payment-team-setting-cancel-plan-confirm-modal-ok-btn', // 取消订阅确认弹窗-确认按钮
        UnableCancelPlanModal: 'payment-team-setting-unable-cancel-plan-modal', // 无法取消订阅弹窗
    },
    AlertBanner: {
        TrailNearlyExpired: (planType: PayPlanType) => `payment-alert-banner-trail-nearly-expired-${planType}`, // 试用即将到期警告
    },
}
