import { SVGAttributes, useMemo, forwardRef } from 'react'
export const BicolorIconPanelStrokeTop16 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" ref={ref} {...props}>
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 2 5)"
                d="M0 0L1 0L1 8L11 8L11 0L12 0L12 9L0 9L0 0Z"
                fillRule="evenodd"
                fill="currentColor"
                opacity="0.45"
            />
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 2 2)"
                d="M12 1L0 1L0 0L12 0L12 1Z"
                fillRule="evenodd"
                fill="currentColor"
            />
        </svg>
    )
})
export const BicolorIconPanelStrokeBottom16 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" ref={ref} {...props}>
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 2 2)"
                d="M0 0L12 0L12 9L11 9L11 1L1 1L1 9L0 9L0 0Z"
                fillRule="evenodd"
                fill="currentColor"
                opacity="0.45"
            />
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 2 13)"
                d="M12 1L0 1L0 0L12 0L12 1Z"
                fillRule="evenodd"
                fill="currentColor"
            />
        </svg>
    )
})
export const BicolorIconPanelStrokeLeft16 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" ref={ref} {...props}>
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 5 2)"
                d="M8 1L0 1L0 0L9 0L9 12L0 12L0 11L8 11L8 1Z"
                fillRule="evenodd"
                fill="currentColor"
                opacity="0.45"
            />
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 2 2)"
                d="M0 12L0 0L1 0L1 12L0 12Z"
                fillRule="evenodd"
                fill="currentColor"
            />
        </svg>
    )
})
export const BicolorIconPanelStrokeRight16 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" ref={ref} {...props}>
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 2 2)"
                d="M0 0L9 0L9 1L1 1L1 11L9 11L9 12L0 12L0 0Z"
                fillRule="evenodd"
                fill="currentColor"
                opacity="0.45"
            />
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 13 2)"
                d="M0 12L0 0L1 0L1 12L0 12Z"
                fillRule="evenodd"
                fill="currentColor"
            />
        </svg>
    )
})
export const BicolorIconPanelStrokeTopRight16 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" ref={ref} {...props}>
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 2 4)"
                d="M0 0L1 0L1 9L10 9L10 10L0 10L0 0Z"
                fillRule="evenodd"
                fill="currentColor"
                opacity="0.45"
            />
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 4 2)"
                d="M9 1L0 1L0 0L10 0L10 10L9 10L9 1Z"
                fillRule="evenodd"
                fill="currentColor"
            />
        </svg>
    )
})
export const BicolorIconPanelStrokeLeftBottom16 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" ref={ref} {...props}>
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 4 2)"
                d="M9 1L0 1L0 0L10 0L10 10L9 10L9 1Z"
                fillRule="evenodd"
                fill="currentColor"
                opacity="0.45"
            />
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 2 4)"
                d="M0 0L1 0L1 9L10 9L10 10L0 10L0 0Z"
                fillRule="evenodd"
                fill="currentColor"
            />
        </svg>
    )
})
export const BicolorIconPanelStrokeTopBottom16 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" ref={ref} {...props}>
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 2 5)"
                d="M0 6L0 0L1 0L1 6L0 6ZM12 0L12 6L11 6L11 0L12 0Z"
                fillRule="evenodd"
                fill="currentColor"
                opacity="0.45"
            />
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 2 2)"
                d="M0 1L12 1L12 0L0 0L0 1ZM0 12L12 12L12 11L0 11L0 12Z"
                fillRule="evenodd"
                fill="currentColor"
            />
        </svg>
    )
})
export const BicolorIconPanelStrokeLeftTop16 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" ref={ref} {...props}>
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 2 2)"
                d="M0 0L10 0L10 1L1 1L1 10L0 10L0 0Z"
                fillRule="evenodd"
                fill="currentColor"
            />
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 4 4)"
                d="M10 0L10 10L0 10L0 9L9 9L9 0L10 0Z"
                fillRule="evenodd"
                fill="currentColor"
                opacity="0.45"
            />
        </svg>
    )
})
export const BicolorIconPanelStrokeLeftRight16 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" ref={ref} {...props}>
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 2 2)"
                d="M0 0L0 12L1 12L1 0L0 0ZM11 0L11 12L12 12L12 0L11 0Z"
                fillRule="evenodd"
                fill="currentColor"
            />
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 5 2)"
                d="M0 1L6 1L6 0L0 0L0 1ZM0 11L0 12L5.99995 12L5.99995 11L0 11Z"
                fillRule="evenodd"
                fill="currentColor"
                opacity="0.45"
            />
        </svg>
    )
})
export const BicolorIconPanelStrokeRightBottom16 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" ref={ref} {...props}>
            <path
                transform="matrix(-1 -1.60812e-16 -1.60812e-16 1 12 2)"
                d="M9 1L0 1L0 0L10 0L10 10L9 10L9 1Z"
                fillRule="evenodd"
                fill="currentColor"
                opacity="0.45"
            />
            <path
                transform="matrix(-1 -1.60812e-16 -1.60812e-16 1 14 4)"
                d="M0 0L1 0L1 9L10 9L10 10L0 10L0 0Z"
                fillRule="evenodd"
                fill="currentColor"
            />
        </svg>
    )
})
export const BicolorIconPanelStrokeLeftTopBottom16 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" ref={ref} {...props}>
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 13 2)"
                d="M0 12L0 0L1 0L1 12L0 12Z"
                fillRule="evenodd"
                fill="currentColor"
                opacity="0.45"
            />
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 2 2)"
                d="M0 0L9 0L9 1L1 1L1 11L9 11L9 12L0 12L0 0Z"
                fillRule="evenodd"
                fill="currentColor"
            />
        </svg>
    )
})
export const BicolorIconPanelStrokeLeftTopRight16 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" ref={ref} {...props}>
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 2 13)"
                d="M12 1L0 1L0 0L12 0L12 1Z"
                fillRule="evenodd"
                fill="currentColor"
                opacity="0.45"
            />
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 2 2)"
                d="M0 0L12 0L12 9L11 9L11 1L1 1L1 9L0 9L0 0Z"
                fillRule="evenodd"
                fill="currentColor"
            />
        </svg>
    )
})
export const BicolorIconPanelStrokeTopRightBottom16 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" ref={ref} {...props}>
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 2 2)"
                d="M0 12L0 0L1 0L1 12L0 12Z"
                fillRule="evenodd"
                fill="currentColor"
                opacity="0.45"
            />
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 5 2)"
                d="M8 1L0 1L0 0L9 0L9 12L0 12L0 11L8 11L8 1Z"
                fillRule="evenodd"
                fill="currentColor"
            />
        </svg>
    )
})
export const BicolorIconPanelStrokeLeftRightBotttom16 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" ref={ref} {...props}>
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 2 5)"
                d="M0 0L1 0L1 8L11 8L11 0L12 0L12 9L0 9L0 0Z"
                fillRule="evenodd"
                fill="currentColor"
            />
            <path
                transform="matrix(1 1.60812e-16 -1.60812e-16 1 2 2)"
                d="M12 1L0 1L0 0L12 0L12 1Z"
                fillRule="evenodd"
                fill="currentColor"
                opacity="0.45"
            />
        </svg>
    )
})
export const BicolorIconPanelGradientFill16 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 2 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" ref={ref} {...props}>
            <defs>
                <clipPath id={ids[0]}>
                    <path transform="matrix(1 0 0 1 0 0)" d="M0 0L16 0L16 16L0 16L0 0Z" fillRule="nonzero" />
                </clipPath>
            </defs>
            <g clipPath={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 1 1)"
                    d="M2 0C0.89543 0 0 0.89543 0 2L0 12C0 13.1046 0.89543 14 2 14L12 14C13.1046 14 14 13.1046 14 12L14 2C14 0.89543 13.1046 0 12 0L2 0ZM1 2C1 1.44772 1.44772 1 2 1L12 1C12.5523 1 13 1.44772 13 2L13 12C13 12.5523 12.5523 13 12 13L2 13C1.44772 13 1 12.5523 1 12L1 2Z"
                    fillRule="evenodd"
                    fill="currentColor"
                />
                <defs>
                    <linearGradient
                        id={ids[1]}
                        gradientTransform="matrix(0 11.0414 -11.0414 0 10.5207 -1.04137)"
                        gradientUnits="userSpaceOnUse"
                        x1="0"
                        y1="0.5"
                        x2="1"
                        y2="0.5"
                    >
                        <stop offset="0" stopColor="currentColor" />
                        <stop offset="1" stopColor="currentColor" stopOpacity="0" />
                    </linearGradient>
                </defs>
                <path
                    transform="matrix(1 0 0 1 3 3)"
                    d="M1 0C0.447715 0 0 0.447715 0 1L0 9C0 9.55228 0.447715 10 1 10L9 10C9.55229 10 10 9.55228 10 9L10 1C10 0.447715 9.55228 0 9 0L1 0Z"
                    fillRule="nonzero"
                    fill={`url(#${ids[1]})`}
                />
            </g>
        </svg>
    )
})
