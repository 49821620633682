import classNames from 'classnames'
import { isNumber } from 'lodash-es'
import { useMemo, type HTMLAttributes } from 'react'
import { CopyableRow, CopyableRowProps } from '../../common/copyable-row'
import { formatNumberValue } from '../utils/format-value'

export function DevModeInspectCopyableValue({
    value,
    className,
    emptyClassName,
    feedback,
    paddingMode,
    ...otherProps
}: HTMLAttributes<HTMLSpanElement> & { value: number | string | undefined | null; emptyClassName?: string } & Pick<
        CopyableRowProps,
        'feedback' | 'paddingMode'
    >) {
    const formatValue = useMemo(() => (value && isNumber(value) ? formatNumberValue(value) : value || ''), [value])
    return formatValue ? (
        <CopyableRow copyValue={`${formatValue}`} className={className} feedback={feedback} paddingMode={paddingMode}>
            {formatValue}
        </CopyableRow>
    ) : (
        <span className={classNames(className, emptyClassName)} {...otherProps}>
            -
        </span>
    )
}
