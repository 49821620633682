/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { createSelectors, createStore } from '../../../../util/src/zustand-utils'
import { environment } from '../../environment'
import { MessageContentType } from '../../kernel/interface/notify'
import { WsPropertyChangeMessage, WsRelationChangeMessage } from '../../kernel/notify/notify-message'
import { NotifyService } from '../../kernel/notify/notify-service'

// 监听组织通知

const subscribeParam = (orgId: string) => {
    return {
        [MessageContentType.RelationChange]: {
            filters: [
                {
                    filterParameters: [
                        {
                            name: 'entityType',
                            value: Wukong.NotifyProto.EntityType.ORG - 1 + '',
                        },
                        {
                            name: 'entityId',
                            value: orgId,
                        },
                    ],
                },
            ],
        },
        [MessageContentType.PropertyChange]: {
            filters: [
                {
                    filterParameters: [
                        {
                            name: 'entityType',
                            value: Wukong.NotifyProto.EntityType.ORG - 1 + '',
                        },
                        {
                            name: 'entityId',
                            value: orgId,
                        },
                    ],
                },
            ],
        },
    }
}

export class OrganizationNotifyService {
    public states = createSelectors(
        createStore<{
            relationMessageState: Wukong.NotifyProto.BusinessEntityRelationChange | undefined
            propertiesMessageState: Wukong.NotifyProto.BusinessEntityPropertiesChange | undefined
        }>(
            () => ({
                relationMessageState: undefined,
                propertiesMessageState: undefined,
            }),
            environment.isDev
        )
    )

    constructor(private signal: TraceableAbortSignal, private orgId: string, private notify: NotifyService) {
        this.notify.sendSubscribeProto(this.orgId, subscribeParam(this.orgId))
        this.notify.onConnectChangeWithSignal(signal, (sessionId) => {
            if (sessionId) {
                this.notify.sendSubscribeProto(this.orgId, subscribeParam(this.orgId))
            }
        })

        this.notify.onBusinessMessageChangeWithSignal(signal, (proto) => {
            if (proto.businessCode == WsRelationChangeMessage.code) {
                this.states.setState({
                    relationMessageState: WsRelationChangeMessage.bodyType.decode(proto.payload),
                })
            } else if (proto.businessCode == WsPropertyChangeMessage.code) {
                this.states.setState({
                    propertiesMessageState: WsPropertyChangeMessage.bodyType.decode(proto.payload),
                })
            }
        })

        signal.addEventListener('abort', () => {
            this.notify.sendUnSubscribeProto(this.orgId, subscribeParam(this.orgId))
        })
    }
}
