import { command } from 'ccstate'
import { LoaderFunctionArgs } from 'react-router-dom'
import { TraceableAbortSignal } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { transaction } from '../../../../../../util/src/abort-controller/traceable-transaction'
import { RESET$ } from '../../../../../../util/src/ccstate-helper/action'
import { traceable } from '../../../../../../util/src/ccstate-helper/traceable'
import { createFileManager } from '../../../../main/create-file-manager'
import { openCurrentPageInDesktop } from '../../../../main/desktop/desktop'
import { docAuthStatus$ } from '../../../atoms/doc-auth-status'
import { setupEditorLoaderArgs$ } from '../../../atoms/editor-context'
import { afterDocAuth$ } from './after-doc-auth'

export const startDocAuthFlow$ = traceable(
    'hulh01@kanyun.com',
    command<Promise<boolean>, [TraceableAbortSignal, LoaderFunctionArgs]>(
        async ({ get, set }, signal: TraceableAbortSignal, args: LoaderFunctionArgs) => {
            const { act } = transaction(signal)

            act('inject editor loader args', () => {
                set(setupEditorLoaderArgs$, args)

                return () => {
                    set(setupEditorLoaderArgs$, RESET$)
                }
            })

            act('cleanup when create doc failed', () => {
                return () => {
                    createFileManager.reset()
                }
            })

            act('cleanup preconnect socket', () => {
                return () => {
                    window.preconnectPromise = undefined
                }
            })

            const { status } = (await get(docAuthStatus$))!

            signal.throwIfAborted()

            // 在桌面打开
            if (status === 'open-in-desktop') {
                openCurrentPageInDesktop()
                return false
            }

            // 鉴权失败，交由 React 做路由跳转
            if (status === 'auth-failed') {
                return false
            }

            // 只有鉴权成功时才继续进行鉴权后操作
            await set(afterDocAuth$, signal)

            return true
        }
    )
)
