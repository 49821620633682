import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useEffect, useRef } from 'react'
import { InputV2, ScrollContainer, SearchInputRef } from '../../../../../../ui-lib/src'
import { useLibraryRemoteSearchService } from '../../../../share/component-style-library/hook/use-library-service-remote-search'
import { LibraryComponentViewEditorConfigProvider } from '../../../../share/component-style-library/library-component-viewer/context'
import { LibraryTeamInfoList } from '../../../../share/component-style-library/library-team-info-list/library-team-info-list'
import { LibraryTestId } from '../../../../window'
import { useLibraryModalConfigContext } from '../../../context/library-component-style/library-modal-config'
import { LibraryRemoteChangeWarningTip } from '../library-remote-change-warning-tip/library-remote-change-warning-tip'
import { LibraryRemoteUsedList } from '../library-remote-used-list/library-remote-used-list'
import styles from './library-remote-total-list.module.less'
import { translation } from './library-remote-total-list.translation'

// 所有引用组件库列表（引用组件库 tab 主页）
export function LibraryRemoteTotalList(props: { className?: string }) {
    const libraryRemoteSearchService = useLibraryRemoteSearchService()
    const librarySearchKeyword = libraryRemoteSearchService.states.use.librarySearchKeywordState()
    const isSearching = !!librarySearchKeyword
    const { mainModalConfig } = useLibraryModalConfigContext()
    const searchInputRef = useRef<SearchInputRef>(null)

    const onSearch = (keyword?: string) => {
        libraryRemoteSearchService.search(keyword ?? '')
    }

    useEffect(() => {
        if (
            mainModalConfig.routePath ===
            Wukong.DocumentProto.LibraryMainModalRoutePathType
                .LIBRARY_MAIN_MODAL_ROUTE_PATH_TYPE_REMOTE_LIBRARY_TOTAL_LIST
        ) {
            searchInputRef.current?.getInputElement().focus()
        }
    }, [mainModalConfig.routePath])

    return (
        <ScrollContainer
            data-testid={LibraryTestId.HomeModal.RemoteListBody}
            stickyHeader={
                <div className={styles.searchInput}>
                    <InputV2.Search
                        ref={searchInputRef}
                        autoFocus
                        debounce
                        onSearch={(v) => onSearch?.(v.trim())}
                        placeholder={translation('Search')}
                        dataTestIds={{
                            clearIcon: 'icon-close-svg',
                            input: LibraryTestId.HomeModal.RemoteListSearchInput,
                        }}
                    />
                </div>
            }
            className={classNames(styles.listContainer, props.className)}
        >
            <div className="pb-4">
                <LibraryRemoteChangeWarningTip isSearching={isSearching} />
                <LibraryRemoteUsedList isSearching={isSearching} />
                <LibraryComponentViewEditorConfigProvider draggable={false}>
                    <LibraryTeamInfoList />
                </LibraryComponentViewEditorConfigProvider>
            </div>
        </ScrollContainer>
    )
}
