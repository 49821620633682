function DivideBy255(value: number) {
    return (value + 1 + (value >> 8)) >> 8
}

export function imageDataToPremulAlphaBytes(imageData: ImageData): Uint8Array {
    const data = new Uint8Array(imageData.data)
    for (let i = 3; i < data.byteLength; i += 4) {
        for (let j = i - 3; j < i; j++) {
            data[j] = DivideBy255(data[j] * data[i]) & 255
        }
    }
    return data
}
