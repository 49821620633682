import { isNil } from 'lodash-es'
import { Key, useCallback } from 'react'
import { WKTabsV2 } from '../../../../../../../../ui-lib/src'
import { usePlanAndUserStateService } from '../../../../../../main/app-context'
import { WKFrogTask } from '../../../../../../share/wk-frog-task'
import { isActiveOrgPlan } from '../../../../../../utils/plan-status-checker'
import { useFontManagerService } from '../../../../../context/document-context'
import { FontCloudTabItem } from './font-cloud-tab-item'
import { FontGlobalTabItem } from './font-global-tab-item'
import { FontLocalTabItem } from './font-local-tab-item'
import styles from './font-tab.module.less'
import { translation } from './font-tab.translation'
import { FontSharedTabItem } from './font-team-tab-item'
import { usePluginInstallPoint } from './use-plugin-install-point'

function LocalTabLabel({ showPoint }: { showPoint: boolean }) {
    if (showPoint) {
        return (
            <div className={styles.localTabLabel} data-testid="local-tab-point">
                {translation('Local')}
            </div>
        )
    }
    return <>{translation('Local')}</>
}

export type FontTabKeys = 'All' | 'Team' | 'Cloud' | 'Local'

export function FontTab({
    selectMaxHeight,
    enabledTabs,
    defaultSelectedTab,
}: {
    selectMaxHeight: number
    enabledTabs?: FontTabKeys[] | null
    defaultSelectedTab?: FontTabKeys
}) {
    const fontManagerService = useFontManagerService()
    const docInfo = fontManagerService.states.use.docInfoState()
    const { showPluginPoint, markPointShown } = usePluginInstallPoint()

    const recordTabDurationTime = useCallback((tabKey?: Key) => {
        switch (tabKey) {
            case 'All': {
                WKFrogTask.fontSelector.allTabDuration()
                break
            }
            case 'Team': {
                WKFrogTask.fontSelector.teamTabDuration()
                break
            }
            case 'Cloud': {
                WKFrogTask.fontSelector.cloudTabDuration()
                break
            }
            case 'Local': {
                WKFrogTask.fontSelector.localTabDuration()
                break
            }
            default:
        }
    }, [])

    const enabledTabArr = enabledTabs ?? ['All', 'Team', 'Cloud', 'Local']

    const enabledTabAll = enabledTabArr.includes('All')
    const enabledTabTeam = enabledTabArr.includes('Team')
    const enabledTabCloud = enabledTabArr.includes('Cloud')
    const enabledTabLocal = enabledTabArr.includes('Local')

    const onActiveTabChange = (tabKey: Key, fromTabKey?: Key) => {
        if (tabKey === fromTabKey) {
            return
        }
        switch (tabKey) {
            case 'All': {
                recordTabDurationTime(fromTabKey)
                WKFrogTask.fontSelector.allTab()
                break
            }
            case 'Team': {
                recordTabDurationTime(fromTabKey)
                WKFrogTask.fontSelector.teamTab()
                break
            }
            case 'Cloud': {
                recordTabDurationTime(fromTabKey)
                WKFrogTask.fontSelector.cloudTab()
                break
            }
            case 'Local': {
                recordTabDurationTime(fromTabKey)
                WKFrogTask.fontSelector.localTab()
                markPointShown()
                break
            }
            default:
        }
    }
    const planAndUserStateService = usePlanAndUserStateService()
    const planAndUserState = planAndUserStateService.useZustandStore.use.planAndUserState()
    const showShareFontTab = !isNil(docInfo) && planAndUserState && isActiveOrgPlan(planAndUserState) && enabledTabTeam

    return (
        <WKTabsV2
            defaultActiveKey={defaultSelectedTab ?? 'All'}
            navListClassName={styles.navListClassName}
            navItemClassName={styles.navItemClassName}
            onActiveTabChange={onActiveTabChange}
        >
            {enabledTabAll ? (
                <WKTabsV2.Item tabKey={'All'} label={translation('All')}>
                    <FontGlobalTabItem selectMaxHeight={selectMaxHeight}></FontGlobalTabItem>
                </WKTabsV2.Item>
            ) : null}
            {showShareFontTab ? (
                <WKTabsV2.Item tabKey={'Team'} label={translation('Share')}>
                    <FontSharedTabItem
                        isDraft={docInfo.draft || !docInfo.teamId}
                        teamId={docInfo.teamId ?? ''}
                        selectMaxHeight={selectMaxHeight}
                    ></FontSharedTabItem>
                </WKTabsV2.Item>
            ) : null}
            {enabledTabCloud ? (
                <WKTabsV2.Item tabKey={'Cloud'} label={translation('Cloud')}>
                    <FontCloudTabItem selectMaxHeight={selectMaxHeight}></FontCloudTabItem>
                </WKTabsV2.Item>
            ) : null}
            {enabledTabLocal ? (
                <WKTabsV2.Item tabKey={'Local'} label={<LocalTabLabel showPoint={showPluginPoint} />}>
                    <FontLocalTabItem selectMaxHeight={selectMaxHeight}></FontLocalTabItem>
                </WKTabsV2.Item>
            ) : null}
        </WKTabsV2>
    )
}
