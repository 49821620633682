/* eslint-disable no-restricted-imports */
import {
    CanvasSearchDeleteSelectedCommand,
    CanvasSearchEnterTempMultiSelectionMode,
    CanvasSearchLayerItemClickCommand,
    CanvasSearchLayerItemCommandClickCommand,
    CanvasSearchLayerItemEnterHoverCommand,
    CanvasSearchLayerItemLeaveHoverCommand,
    CanvasSearchLayerItemOnEnter,
    CanvasSearchLayerItemOnShiftEnter,
    CanvasSearchLayerItemShiftClickCommand,
    CanvasSearchLeaveTempMultiSelectionMode,
    CanvasSearchNextLayerItem,
    CanvasSearchPageItemClickCommand,
    CanvasSearchPrevLayerItem,
    CanvasSearchReplaceAllCommand,
    CanvasSearchReplaceSelectedCommand,
    CanvasSearchSelectAllCommand,
    CanvasSearchTogglePageExpand,
    ExitCanvasSearchCommand,
    StartCanvasSearchCommand,
    UpdateCanvasSearchParamCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { useState } from 'react'
import { useCanvasSearchService } from '../../../main/app-context'
import { DeepRequired, useViewState } from '../../../view-state-bridge'
import { useCommand } from '../../context/document-context'

const defaultCanvasSearchParam: DeepRequired<Wukong.DocumentProto.ICanvasSearchParam> = {
    show: false,
    mode: Wukong.DocumentProto.CanvasSearchMode.CANVAS_SEARCH_MODE_SEARCH,
    value: '',
    scope: Wukong.DocumentProto.CanvasSearchScope.CANVAS_SEARCH_SCOPE_CURRENT_PAGE,
    nodeFilterType: Wukong.DocumentProto.CanvasSearchNodeTypeFilter.CANVAS_SEARCH_NODE_TYPE_FILTER_ALL,
    matchCase: false,
    wholeWords: false,
}

export type CanvasSearchParamModel = ReturnType<typeof useCanvasSearchParamModel>
export const useCanvasSearchParamModel = () => {
    const command = useCommand()
    const canvasSearchParam = useViewState('canvasSearchParam', defaultCanvasSearchParam)
    const [searchUid, setSearchUid] = useState(0)
    const [replaceValue, setReplaceValue] = useState<string>('')
    const canvasSearchService = useCanvasSearchService()
    const docReadOnly = useViewState('docReadonly')
    const isDevMode = useViewState('editorType') == Wukong.DocumentProto.EditorType.EDITOR_TYPE_DEV
    const enableEdit = !(docReadOnly || isDevMode)

    const updateSearchParam = (param: DeepRequired<Wukong.DocumentProto.ICanvasSearchParam>) => {
        setSearchUid((v) => v + 1)
        command.DEPRECATED_invokeBridge(UpdateCanvasSearchParamCommand, param)
        command.commitUndo()
    }

    return {
        modelState: canvasSearchParam,
        enableEdit,
        searchUid,
        replaceValue,
        setReplaceValue,
        modelCommand: {
            startSearch: () => {
                command.DEPRECATED_invokeBridge(StartCanvasSearchCommand)
                command.commitUndo()
            },

            exitSearch: () => {
                command.DEPRECATED_invokeBridge(ExitCanvasSearchCommand)
                command.commitUndo()
            },

            updateSearchValue: (value: string) => {
                updateSearchParam({
                    ...canvasSearchParam,
                    value,
                })
            },

            updateMatchCase: (matchCase: boolean) => {
                updateSearchParam({
                    ...canvasSearchParam,
                    matchCase,
                })
            },

            updateWholeWords: (wholeWords: boolean) => {
                updateSearchParam({
                    ...canvasSearchParam,
                    wholeWords,
                })
            },

            updateNodeFilterType: (nodeFilterType: Wukong.DocumentProto.CanvasSearchNodeTypeFilter) => {
                updateSearchParam({
                    ...canvasSearchParam,
                    nodeFilterType,
                })
            },

            updateSearchMode: (mode: Wukong.DocumentProto.CanvasSearchMode) => {
                updateSearchParam({
                    ...canvasSearchParam,
                    mode,
                })

                // 切换模式后, 搜索关键词为空时自动focus到搜索输入框
                if (!canvasSearchParam.value) {
                    canvasSearchService.invokeFocusInputAndSelectAll()
                }
            },

            updateSearchScope: (scope: Wukong.DocumentProto.CanvasSearchScope) => {
                updateSearchParam({
                    ...canvasSearchParam,
                    scope,
                })
            },
        },
    }
}

export type CanvasSearchResultModel = ReturnType<typeof useCanvasSearchResultModel>
export const useCanvasSearchResultModel = () => {
    const command = useCommand()
    const canvasSearchResultRenderInfo = useViewState('canvasSearchResultRenderInfo')

    return {
        modelState: canvasSearchResultRenderInfo,
        modelCommand: {
            clickLayerItem: (layerItem: DeepRequired<Wukong.DocumentProto.ICanvasSearchLayerItem>) => {
                if (layerItem.nodeType == Wukong.DocumentProto.NodeType.NODE_TYPE_PAGE) {
                    command.DEPRECATED_invokeBridge(CanvasSearchPageItemClickCommand, layerItem)
                } else {
                    command.DEPRECATED_invokeBridge(CanvasSearchLayerItemClickCommand, layerItem)
                }
                command.commitUndo()
            },

            shiftClickLayerItem: (layerItem: DeepRequired<Wukong.DocumentProto.ICanvasSearchLayerItem>) => {
                if (layerItem.nodeType == Wukong.DocumentProto.NodeType.NODE_TYPE_PAGE) {
                    command.DEPRECATED_invokeBridge(CanvasSearchPageItemClickCommand, layerItem)
                } else {
                    command.DEPRECATED_invokeBridge(CanvasSearchLayerItemShiftClickCommand, layerItem)
                }
                command.commitUndo()
            },

            comandClickLayerItem: (layerItem: DeepRequired<Wukong.DocumentProto.ICanvasSearchLayerItem>) => {
                if (layerItem.nodeType == Wukong.DocumentProto.NodeType.NODE_TYPE_PAGE) {
                    command.DEPRECATED_invokeBridge(CanvasSearchPageItemClickCommand, layerItem)
                } else {
                    command.DEPRECATED_invokeBridge(CanvasSearchLayerItemCommandClickCommand, layerItem)
                }
                command.commitUndo()
            },

            enterHover: (layerItem: DeepRequired<Wukong.DocumentProto.ICanvasSearchLayerItem>) => {
                command.DEPRECATED_invokeBridge(CanvasSearchLayerItemEnterHoverCommand, layerItem)
            },

            leaveHover: (layerItem: DeepRequired<Wukong.DocumentProto.ICanvasSearchLayerItem>) => {
                command.DEPRECATED_invokeBridge(CanvasSearchLayerItemLeaveHoverCommand, layerItem)
            },

            toggleExpand: (pageId: string) => {
                command.DEPRECATED_invokeBridge(
                    CanvasSearchTogglePageExpand,
                    Wukong.DocumentProto.BridgeProtoString.create({ value: pageId })
                )
            },

            nextLayerItem: () => {
                command.DEPRECATED_invokeBridge(CanvasSearchNextLayerItem)
                command.commitUndo()
            },

            prevLayerItem: () => {
                command.DEPRECATED_invokeBridge(CanvasSearchPrevLayerItem)
                command.commitUndo()
            },

            enterTempMultiSelectionMode: () => {
                command.DEPRECATED_invokeBridge(CanvasSearchEnterTempMultiSelectionMode)
            },

            leaveTempMultiSelectionMode: () => {
                command.DEPRECATED_invokeBridge(CanvasSearchLeaveTempMultiSelectionMode)
            },

            onEnterKeyDown: (isShift: boolean) => {
                if (isShift) {
                    command.DEPRECATED_invokeBridge(CanvasSearchLayerItemOnShiftEnter)
                } else {
                    command.DEPRECATED_invokeBridge(CanvasSearchLayerItemOnEnter)
                }
                command.commitUndo()
            },
            repalceSelected: (replaceValue: string) => {
                const result = command.DEPRECATED_invokeBridge(
                    CanvasSearchReplaceSelectedCommand,
                    Wukong.DocumentProto.BridgeProtoString.create({ value: replaceValue })
                )
                command.commitUndo()
                return result
            },
            repalceAll: (replaceValue: string) => {
                const result = command.DEPRECATED_invokeBridge(
                    CanvasSearchReplaceAllCommand,
                    Wukong.DocumentProto.BridgeProtoString.create({ value: replaceValue })
                )
                command.commitUndo()
                return result
            },

            selectAll: () => {
                command.DEPRECATED_invokeBridge(CanvasSearchSelectAllCommand)
                command.commitUndo()
            },
            deleteSeleted: () => {
                command.DEPRECATED_invokeBridge(CanvasSearchDeleteSelectedCommand)
                command.commitUndo()
            },
        },
    }
}
