export const ARROW_DOWN_SVG = `<svg width="12" height="12" viewBox="0 0 12 12" >
<path
    transform="matrix(1 0 0 1 2.64648 4.14642)"
    d="M0 0.707108L0.707105 0L3.35356 2.64645L6.00001 6.85453e-07L6.70712 0.707108L3.35357 4.06066L0 0.707108Z"
    fill="currentColor"
/>
</svg>`

export const DATE_SVG = `<svg width="16" height="16" viewBox="0 0 16 16">
<path
    transform="matrix(1 0 0 1 2 1)"
    d="M4 0L3 0L3 1L1 1C0.447708 1 0 1.44775 0 2L0 12C0 12.5522 0.447708 13 1 13L11 13C11.5523 13 12 12.5522 12 12L12 2C12 1.44775 11.5523 1 11 1L9 1L9 0L8 0L8 1L4 1L4 0ZM1 2L1 5L11 5L11 2L9 2L9 3L8 3L8 2L4 2L4 3L3 3L3 2L1 2ZM1 6L1 12L11 12L11 6L1 6Z"
    fill-rule="evenodd"
    fill="#6e737d"
/>
</svg>`

export const CHECKBOX_SVG = `<svg width="16" height="16" viewBox="0 0 16 16">
<path
    transform="matrix(1 0 0 1 3.29297 4.29285)"
    d="M9.41421 1.41421L3.70711 7.12132L0 3.41421L1.41421 2L3.70711 4.29289L8 0L9.41421 1.41421Z"
    fill-rule="evenodd"
    fill="#ffffff"
/>
</svg>`
