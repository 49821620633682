import classnames from 'classnames'
import React, { useCallback, useEffect, useMemo } from 'react'
import { CommitType } from '../../../../../document/command/commit-type'
import type { ColorStop } from '../../../../../document/node/node'
import { isKey, KeyboardCode } from '../../../../../kernel/keyboard/keyboard-event-handler'
import { PureBlock } from '../../../atom/color-block/pure'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { ColorSpace } from '../../blend/color-picker/utils/color-translate'
import style from './gradient-block.module.less'

export interface GradientBlockProps {
    isSelect: boolean
    colorStop: ColorStop
    colorSpace: ColorSpace
    dataTestId?: string
    onClickBlock?: (altKey: boolean) => void
    onDeleteBlock?: () => void
    onChangeColorStop?: (colorStop: ColorStop, options?: InputOptionsForUndoSquash) => void
}
export function GradientBlock(props: GradientBlockProps) {
    const { isSelect, colorStop, colorSpace, onDeleteBlock, onClickBlock, onChangeColorStop } = props

    const positionStyle = useMemo(() => {
        return { left: `${colorStop.position * 100}%` }
    }, [colorStop.position])

    const onPointerDownColorTip = useCallback(
        (e: React.PointerEvent<HTMLDivElement>) => {
            onClickBlock?.(e.altKey)
        },
        [onClickBlock]
    )

    const onKeyDownPureBlock = useCallback(
        (e: KeyboardEvent | React.KeyboardEvent<HTMLDivElement>) => {
            if (!isSelect) {
                return
            }
            if (document.activeElement !== document.body && document.body.contains(document.activeElement)) {
                return
            }
            if (isKey(e as KeyboardEvent, [KeyboardCode.TAB])) {
                e.stopPropagation()
                e.preventDefault()
                return
            }
            if (isKey(e as KeyboardEvent, [KeyboardCode.BACKSPACE, KeyboardCode.DELETE])) {
                e.stopPropagation()
                return onDeleteBlock?.()
            }
            const stepSize = e.shiftKey ? 0.05 : 0.01
            if (isKey(e as KeyboardEvent, [KeyboardCode.ARROW_UP, KeyboardCode.ARROW_RIGHT])) {
                e.stopPropagation()
                const position = Math.min(1, Math.max(0, colorStop.position + stepSize))
                return onChangeColorStop?.({ ...colorStop, position }, { commitType: CommitType.CommitSquash })
            }
            if (isKey(e as KeyboardEvent, [KeyboardCode.ARROW_DOWN, KeyboardCode.ARROW_LEFT])) {
                e.stopPropagation()
                const position = Math.min(1, Math.max(0, colorStop.position - stepSize))
                return onChangeColorStop?.({ ...colorStop, position }, { commitType: CommitType.CommitSquash })
            }
        },
        [isSelect, onDeleteBlock, colorStop, onChangeColorStop]
    )

    useEffect(() => {
        document.addEventListener('keydown', onKeyDownPureBlock, false)
        return () => {
            document.removeEventListener('keydown', onKeyDownPureBlock)
        }
    }, [onKeyDownPureBlock])

    return (
        <PureBlock
            rgb={{ ...colorStop.color }}
            colorSpace={colorSpace}
            opacity={colorStop.color.a / 255}
            onKeyDown={onKeyDownPureBlock}
            disabledTab
            size={12}
            onPointerDown={onPointerDownColorTip}
            className={classnames(style.colorTipBlock, {
                [style.select]: isSelect,
            })}
            style={positionStyle}
            dataTestId={props.dataTestId}
        />
    )
}
