import { useState } from 'react'
import { fetchCreateApplication } from '../pay-apply-dialog-request'
import { PayApplyDialogContainer1 } from './pay-apply-dialog-container'
import { AdminPayApplyProps } from './type'
import { getSeatTypeFromPayApplyDialogSeatType } from './utils'

export function AdminPayApply(props: AdminPayApplyProps) {
    const { seatType, dialogProps, httpProps, description } = props
    const [loading, setLoading] = useState<boolean>(false)
    const onOk = async () => {
        setLoading(true)
        await fetchCreateApplication({
            resourceType: httpProps.resourceType,
            resourceId: httpProps.resourceId,
            seatType: getSeatTypeFromPayApplyDialogSeatType(seatType),
            reason: '',
            adminUserIds: [],
            triggerScene: httpProps.triggerScene,
        }).finally(() => {
            setLoading(false)
        })
        dialogProps?.onOk?.()
    }
    return (
        <PayApplyDialogContainer1
            {...dialogProps}
            okButtonProps={{ ...dialogProps?.okButtonProps, loading }}
            onOk={onOk}
            dataTestIds={{ description: 'admin-pay-apply-dialog' }}
        >
            {description}
        </PayApplyDialogContainer1>
    )
}
