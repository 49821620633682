import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    Admin: '这是一个计费动作，作为管理员，你将升级自己为',
    Autograde: '这是一个计费动作，你将升级自己为',
    DesignSeat: '「设计席位」。',
    DevSeat: '「研发席位」。',

    ApplyDesignSeat: '这是一个计费动作，作为管理员，你将升级自己为「设计席位」。',
    ApplyDevSeat: '这是一个计费动作，作为管理员，你将升级自己为「研发席位」。',
} as const

export const enTranslation = {
    Admin: 'This is a billable event, as an admin, you will be upgraded to a ',
    Autograde: 'This is a billable event, you will be upgraded to a ',
    DesignSeat: 'Designer seat.',
    DevSeat: 'Developer seat.',
    ApplyDesignSeat: 'This is a billable event, as an admin, you will be upgraded to a "Designer seat".',
    ApplyDevSeat: 'This is a billable event, as an admin, you will be upgraded to a "Developer seat".',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
