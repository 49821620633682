/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import React, { forwardRef } from 'react'
import {
    MonoIconCommonEyes16,
    MonoIconCommonEyesClose16,
    MonoIconCommonRemove16,
    WKIconButton,
} from '../../../../../../ui-lib/src'
import { IconButtonProps } from '../../atom/button/icon-button'
import { SingleGrid } from '../../atom/grid/single-grid'
import { ScrubbableInputProps } from '../../atom/inputs/components/scrubbable-input'
import { ScrubbableInputPercent } from '../../atom/inputs/scrubbable-input-percent'
import { ColorVarItem } from '../../color-var-item'
import { PaintIconColor, PaintIconProps } from '../../paint-icon-color/paint-icon-color'
import { ColorSpace } from '../blend/color-picker/utils/color-translate'
import classes from './paint-struct.module.less'

export interface PaintStructProps {
    className?: string
    paint: Wukong.DocumentProto.IPaint
    colorSpace: ColorSpace
    colorVar?: Wukong.DocumentProto.ISingleVariable
    selected?: boolean
    isMini?: boolean
    disabled?: boolean
    isStroke?: boolean
    onChangeColor: PaintIconProps['onChangeColor']
    onClickColorInput: PaintIconProps['onClickInput']
    onClickColorBlock: () => void
    onClose: () => void
    onChangeOpacity: ScrubbableInputProps['onChange']
    onChangeVisible: () => void
    onClickSub?: IconButtonProps['onClick']
    onDetachColorVar?: () => void
}

export const PaintStruct = forwardRef(function Component(props: PaintStructProps, outRef: React.Ref<HTMLDivElement>) {
    const { paint, colorVar, onClickColorBlock, onDetachColorVar } = props

    return (
        <>
            {
                <SingleGrid
                    ref={outRef}
                    className={classNames(
                        classes.eventPenetrate,
                        classes.v2,
                        {
                            [classes.selected]: props.selected,
                        },
                        props.className
                    )}
                    style={props.isMini ? { gridTemplateColumns: 'repeat(60, 4px)' } : undefined}
                    testId="paint-struct"
                >
                    {colorVar ? (
                        <SingleGrid.Item start={5} span={props.isMini ? 44 : 46} data-simple-drag-ignore>
                            <ColorVarItem
                                paint={paint}
                                colorSpace={props.colorSpace}
                                variable={colorVar}
                                selected={props.selected}
                                disabled={props.disabled}
                                isStroke={props.isStroke}
                                width={props.isMini ? 176 : 184}
                                onMouseDownItem={onClickColorBlock}
                                onClickDetach={onDetachColorVar}
                            />
                        </SingleGrid.Item>
                    ) : (
                        <SingleGrid.Item start={5} span={props.isMini ? 44 : 46} data-simple-drag-ignore>
                            <SingleGrid
                                className={classes.backgroundGrid}
                                style={props.isMini ? { gridTemplateColumns: `repeat(44, 4px)` } : undefined}
                            >
                                <SingleGrid.Item start={1} span={props.isMini ? 23 : 26}>
                                    <PaintIconColor
                                        paint={props.paint}
                                        colorSpace={props.colorSpace}
                                        onChangeColor={props.onChangeColor}
                                        onMouseDownIcon={props.onClickColorBlock}
                                        onClickInput={props.onClickColorInput}
                                        isWidth={props.isMini ? 92 : 104}
                                        disabled={props.disabled}
                                    />
                                </SingleGrid.Item>
                                <SingleGrid.Item
                                    start={props.isMini ? 24 : 27}
                                    span={props.isMini ? 14 : 13}
                                    data-testid={'fill-opacity'}
                                >
                                    <ScrubbableInputPercent
                                        disabled={props.disabled}
                                        value={props.paint.opacity ?? undefined}
                                        onChange={props.onChangeOpacity}
                                        onClick={props.onClose}
                                        min={0}
                                        max={100}
                                        className={classNames({
                                            [classes.hidden]: !props.paint.visible,
                                        })}
                                        testId="fill-item-opacity"
                                    />
                                </SingleGrid.Item>
                                <SingleGrid.Item start={props.isMini ? 38 : 40} span={7}>
                                    <WKIconButton
                                        icon={
                                            props.paint.visible ? (
                                                <MonoIconCommonEyes16 />
                                            ) : (
                                                <MonoIconCommonEyesClose16 />
                                            )
                                        }
                                        onMouseDown={props.onChangeVisible}
                                        className={classes.eyeIconV2}
                                        disabled={props.disabled}
                                        data-testid="fill-item-visible"
                                        type="primary"
                                        data-testvalue={props.paint.visible ?? undefined}
                                    />
                                </SingleGrid.Item>
                            </SingleGrid>
                        </SingleGrid.Item>
                    )}
                    <SingleGrid.Item start={props.isMini ? 49 : 51} span={12} horizontalCenter>
                        <WKIconButton
                            icon={<MonoIconCommonRemove16 />}
                            onClick={props.onClickSub}
                            disabled={props.disabled}
                            data-simple-drag-ignore
                            data-testid="paint-item-delete"
                        />
                    </SingleGrid.Item>
                </SingleGrid>
            }
        </>
    )
})
