import { Wukong } from '@wukong/bridge-proto'
import { useRef, useState } from 'react'
import { WKIconButton } from '../../../../../../ui-lib/src/components/wk-button/icon-button'
import { MonoIconCommonAdd16, MonoIconCommonClose16 } from '../../../../../../ui-lib/src/icons-v2'
import { CreateFloatVariableData } from './common'
import { FloatVariableCreator } from './components/float-variable-creator'
import {
    PrimitiveVariablePanelBase,
    PrimitiveVariablePanelCommonProps,
} from './components/primitive-variable-panel-base'
import { translation } from './primitive-variable-panel.translation'

export type CreateConfig =
    | {
          type: Wukong.DocumentProto.PrimitiveVariableType.PRIMITIVE_VARIABLE_TYPE_FLOAT
          data?: CreateFloatVariableData
      }
    | {
          type: Wukong.DocumentProto.PrimitiveVariableType.PRIMITIVE_VARIABLE_TYPE_STRING
      }
    | {
          type: Wukong.DocumentProto.PrimitiveVariableType.PRIMITIVE_VARIABLE_TYPE_BOOLEAN
      }

interface PrimitiveVariablePanelProps extends PrimitiveVariablePanelCommonProps {
    createEnable: boolean
    createConfig?: CreateConfig
}

export const PrimitiveVariablePanel = (props: PrimitiveVariablePanelProps) => {
    const { onCancel, onVariableSelected, createEnable, createConfig } = props
    const titleRef = useRef<HTMLDivElement>(null)
    const [creatingVariable, setCreatingVariable] = useState(false)
    const [creatingVariablePosition, setCreatingVariablePosition] = useState<{
        top: number
        left: number
    }>()

    const onAddIconClick = () => {
        setCreatingVariablePosition(titleRef.current?.getBoundingClientRect())
        setCreatingVariable((v) => !v)
    }

    const onClickCreateFloatVariable = (v: { varId: string; name: string }) => {
        setCreatingVariable(false)
        onVariableSelected(v.varId, v.name)
    }

    const renderCreateVariable = () => {
        if (createConfig && creatingVariable && creatingVariablePosition) {
            switch (createConfig.type) {
                case Wukong.DocumentProto.PrimitiveVariableType.PRIMITIVE_VARIABLE_TYPE_FLOAT:
                    return (
                        <FloatVariableCreator
                            initialData={createConfig.data}
                            onCancel={() => setCreatingVariable(false)}
                            position={creatingVariablePosition}
                            onClickCreateFloatVariable={onClickCreateFloatVariable}
                        />
                    )
                case Wukong.DocumentProto.PrimitiveVariableType.PRIMITIVE_VARIABLE_TYPE_BOOLEAN:
                case Wukong.DocumentProto.PrimitiveVariableType.PRIMITIVE_VARIABLE_TYPE_STRING: {
                    throw new Error('Not implemented yet')
                }
            }
        }
    }

    return (
        <div>
            <PrimitiveVariablePanelBase
                {...props}
                header={
                    <div className="flex items-center justify-between pl-16px pr-10px h-40px" ref={titleRef}>
                        <div className="flex-1 wk-font-semibold text-black wk-text-12">{translation('Title')}</div>
                        {createEnable ? (
                            <WKIconButton
                                data-testid="primitive-variable-panel-add-icon"
                                type={creatingVariable ? 'deepBlue' : 'secondary'}
                                icon={<MonoIconCommonAdd16 />}
                                onClick={onAddIconClick}
                            />
                        ) : (
                            <WKIconButton icon={<MonoIconCommonClose16 />} onClick={onCancel} />
                        )}
                    </div>
                }
            />
            {renderCreateVariable()}
        </div>
    )
}
