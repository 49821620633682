import { SetComponentThumbnailDraggingCommand, Wukong } from '@wukong/bridge-proto'
import { throttle } from 'lodash-es'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import { ServiceClass } from '../../../../kernel/util/service-class'
import { RootComponentId } from '../../../../main/canvas/canvas'
import { LibraryResourceOssClientType } from '../../../../share/component-style-library/service/library-resource-downloader'
import { LibraryTestId } from '../../../../window'
import { buildMouseEvent, dispatchMouseEventSpecially } from '../../../utils/event'
import { LibraryNodeDataService } from './library-node-data-service'

// 缩略图拖拽事件管理
export class LibraryDragEventService extends ServiceClass {
    private dragImageDom?: HTMLElement
    private pointerDownPosition?: { clientX: number; clientY: number; moved: boolean }
    private component?: Wukong.DocumentProto.IVLibraryAssetPanelComponentItem

    constructor(
        private readonly command: CommandInvoker,
        private readonly libraryNodeDataService: LibraryNodeDataService,
        private readonly canvas: HTMLCanvasElement
    ) {
        super()
    }

    public override destroy() {
        super.destroy()

        this.cleanup()
    }

    public onPointerDown = (e: PointerEvent, component: Wukong.DocumentProto.IVLibraryAssetPanelComponentItem) => {
        if (e.button !== 0) {
            return
        }

        e.preventDefault()
        ;(document.activeElement as HTMLElement)?.blur?.()

        const currentTarget = e.currentTarget as HTMLDivElement
        const dragImageElement = currentTarget.querySelector('img')
        // 缩略图未加载出来，不响应任何行为
        if (!dragImageElement) {
            return
        }

        // 锁定拖拽目标
        currentTarget.setPointerCapture(e.pointerId)

        currentTarget.addEventListener('pointermove', this.onPointerMove)
        currentTarget.addEventListener('pointerup', this.onPointerUp)
        // currentTarget destroy 后，pointerup 事件不会触发，仅会触发 lostpointercapture 事件
        this.addWindowEventListenerOnce('lostpointercapture', this.onLostPointerCapture)

        // 记录起始点击位置
        this.pointerDownPosition = { clientX: e.clientX, clientY: e.clientY, moved: false }

        // 渲染跟随鼠标的拖拽图片
        this.renderDraggingImage(dragImageElement)

        // 预下载 remote nodeData 资源
        this.component = component
        this.libraryNodeDataService.preFetchRemoteNodeData({
            ...component,
            ossClientType: LibraryResourceOssClientType.Component,
        })
    }

    private onPointerMove = (e: PointerEvent) => {
        if (!this.pointerDownPosition || !this.dragImageDom) {
            return
        }

        if (
            !this.pointerDownPosition.moved &&
            checkMouseMove(e, this.pointerDownPosition.clientX, this.pointerDownPosition.clientY)
        ) {
            this.pointerDownPosition.moved = true
            this.dragImageDom.style.visibility = 'visible'
        }

        if (!this.pointerDownPosition.moved) {
            return
        }

        requestAnimationFrame(() => {
            if (this.dragImageDom) {
                this.dragImageDom.style.transform = `translate3d(${e.clientX}px, ${e.clientY}px, 0px)`
            }
        })

        this.throttleEmitMouseEvent(buildMouseEvent('mousemove', e))
    }

    // 上报 mouseMoveEvent 较耗时，使用节流函数
    private throttleEmitMouseEvent = throttle((e: ReturnType<typeof buildMouseEvent>) => {
        if (!this.pointerDownPosition) {
            return
        }
        dispatchMouseEventSpecially(e, this.command, this.canvas)
        this.setComponentThumbnailDragging(true)
    }, 166)

    private onPointerUp = (e: PointerEvent) => {
        this.onPointerUpImpl(e)
        this.cleanup()
    }

    private onPointerUpImpl = (e: PointerEvent): void => {
        if (!this.pointerDownPosition) {
            return
        }

        this.removeWindowEventListener('lostpointercapture', this.onLostPointerCapture)
        const currentTarget = e.currentTarget as HTMLDivElement | Window
        if (currentTarget instanceof HTMLElement) {
            currentTarget.hasPointerCapture(e.pointerId) && currentTarget.releasePointerCapture(e.pointerId)
            currentTarget.removeEventListener('pointermove', this.onPointerMove)
            currentTarget.removeEventListener('pointerup', this.onPointerUp)
        } else {
            this.removeWindowEventListener('pointermove', this.onPointerMove)
            this.removeWindowEventListener('pointerup', this.onPointerUp)
        }

        const mouseMoved = this.pointerDownPosition.moved

        // 鼠标位置未移动过，不作任何处理，自然冒泡到其父元素进行处理
        if (!mouseMoved) {
            return
        }

        e.stopPropagation()
        e.preventDefault()

        if (!this.component) {
            return
        }

        const canvas = document.getElementById(RootComponentId.CanvasOverlay)
        if (!canvas) {
            return
        }

        const canvasRect = canvas.getBoundingClientRect()
        const dropOffset = {
            x: e.clientX - canvasRect.x,
            y: e.clientY - canvasRect.y,
        }

        // drop 位置超出画布范围，不作任何处理
        if (
            dropOffset.x < 0 ||
            dropOffset.x > canvasRect.width ||
            dropOffset.y < 0 ||
            dropOffset.y > canvasRect.height
        ) {
            return
        }

        // drop 位置在组件库弹窗内，不作任何处理
        const libraryModal = document.querySelector(`[data-testid="${LibraryTestId.HomeModal.Id}"]`)
        if (libraryModal) {
            const libraryModalRect = libraryModal.getBoundingClientRect()
            if (
                e.clientX > libraryModalRect.left &&
                e.clientX < libraryModalRect.right &&
                e.clientY > libraryModalRect.top &&
                e.clientY < libraryModalRect.bottom
            ) {
                return
            }
        }

        const currentMouseEvent = buildMouseEvent('mouseup', e)
        this.libraryNodeDataService
            .createComponentNode({
                ...this.component,
                ossClientType: LibraryResourceOssClientType.Component,
                mousePoint: dropOffset,
                anchorPoint: { x: 0.5, y: 0.5 },
                altKey: e.altKey,
            })
            .then(() => {
                if (!this.isDestroy) {
                    dispatchMouseEventSpecially(currentMouseEvent, this.command, this.canvas)
                }
            })
    }

    private onLostPointerCapture = (e: PointerEvent) => {
        if (!this.pointerDownPosition) {
            return
        }
        // 在当前窗口外 lostpointercapture，直接判定为拖拽结束
        if (e.clientX < 0 || e.clientY < 0 || e.clientX > window.innerWidth || e.clientY > window.innerHeight) {
            this.onPointerUp(e)
        } else {
            // 接管拖拽到 window 上，保证当前拖拽行为的继续进行
            this.addWindowEventListener('pointermove', this.onPointerMove)
            this.addWindowEventListener('pointerup', this.onPointerUp)
        }
    }

    private cleanup = () => {
        if (this.pointerDownPosition && !this.isDestroy) {
            this.setComponentThumbnailDragging(false)
        }
        this.dragImageDom?.remove()
        this.component = undefined
        this.pointerDownPosition = undefined
    }

    private setComponentThumbnailDragging = (value: boolean) => {
        this.command.DEPRECATED_invokeBridge(SetComponentThumbnailDraggingCommand, { value })
    }

    private renderDraggingImage = (dragImageElement: HTMLImageElement) => {
        const targetRect = dragImageElement.getBoundingClientRect()
        this.dragImageDom = dragImageElement.cloneNode(true) as HTMLElement
        this.dragImageDom.setAttribute('data-testid', LibraryTestId.Viewer.DragImage)
        this.dragImageDom.style.position = 'absolute'
        this.dragImageDom.style.willChange = 'transform'
        this.dragImageDom.style.zIndex = '9999'
        this.dragImageDom.style.width = `${targetRect.width}px`
        this.dragImageDom.style.height = `${targetRect.height}px`
        this.dragImageDom.style.left = '0px'
        this.dragImageDom.style.top = '0px'
        this.dragImageDom.style.left = `-${targetRect.width / 2}px`
        this.dragImageDom.style.top = `-${targetRect.height / 2}px`
        this.dragImageDom.style.visibility = 'hidden'
        document.body.appendChild(this.dragImageDom)
    }
}

function checkMouseMove(e: PointerEvent, prevClientX: number, prevClientY: number): boolean {
    return Math.abs(prevClientX - e.clientX) > 3 || Math.abs(prevClientY - e.clientY) > 3
}
