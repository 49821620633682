import { Wukong } from '@wukong/bridge-proto'
import {
    IconComponent16,
    IconTarget,
    MonoIconPanelMainComponentOut16,
    Tooltip,
    useEllipsisTooltip,
    WKIconButton,
} from '../../../../../../../ui-lib/src'
import { LibraryThumbnailImage } from '../../../../../share/component-style-library/library-thumbnail-image'
import { useViewState } from '../../../../../view-state-bridge'
import { useComponentInstanceCommand } from '../../../design-panel-v2/component-instance-panel/commands'
import { CopyableRow } from '../common/copyable-row'
import commonStyle from '../inspect.module.less'
import { translation } from './index.translation'
import componentStyle from './style/component.module.less'

function PropKeyWithTooltip({ value }: { value: string }) {
    const { inactivation, ellipsisRef, onmouseenter } = useEllipsisTooltip<HTMLDivElement>()
    return (
        <Tooltip inactivation={inactivation} title={value}>
            <div className={componentStyle.propKey} ref={ellipsisRef} onMouseEnter={onmouseenter}>
                {value}
            </div>
        </Tooltip>
    )
}

function ComponentPropPanel(props: { componentProps: Wukong.DocumentProto.IComponentRelatedDisplayProp[] }) {
    return (
        <>
            {props.componentProps.length > 0 && (
                <div className={componentStyle.componentVarientPropsBar} data-testid="component-props-panel">
                    <div className={componentStyle.optionPropRow}>{translation('Properties')}</div>

                    <div className={componentStyle.propBlock}>
                        <div className={componentStyle.propKeyArea}>
                            {props.componentProps.map((prop, index) => (
                                <PropKeyWithTooltip value={prop.name} key={index} />
                            ))}
                        </div>
                        <div className={componentStyle.propValueArea}>
                            {props.componentProps.map((prop, index) => (
                                <div className="pt-2 h-20px" key={index}>
                                    {prop.value ? (
                                        <CopyableRow className={componentStyle.propValue} copyValue={prop.value}>
                                            {prop.value}
                                        </CopyableRow>
                                    ) : (
                                        '-'
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export function DevModeInspectComponent() {
    const componentViewState = useViewState('devModeInspectComponentViewState')
    const commands = useComponentInstanceCommand()
    if (!componentViewState?.show) {
        return null
    }

    return (
        <div className={commonStyle.panel} data-testid="dev-mode-inspect-component">
            <div className={commonStyle.panelTitle} style={{ marginBottom: '0px' }}>
                <div className={commonStyle.title}>{translation('Component')}</div>
            </div>
            <div className={componentStyle.componentBody}>
                {componentViewState.isInstance && (
                    <div className={commonStyle.layerNameBar} data-testid="component-name">
                        <div className={`${commonStyle.layerMainPart} ${componentStyle.componentInstanceColor}`}>
                            <div className={commonStyle.icon}>
                                <IconComponent16 />
                            </div>

                            <div className={commonStyle.layerName}>{componentViewState.componentData.name}</div>
                        </div>
                        <WKIconButton
                            className={`${commonStyle.layerPeripheralPart}`}
                            onClick={commands.goToMainComp}
                            icon={componentViewState.isRemote ? <MonoIconPanelMainComponentOut16 /> : <IconTarget />}
                        />
                    </div>
                )}
                <div className={componentStyle.componentPreviewCanvas}>
                    <LibraryThumbnailImage
                        thumbnailData={componentViewState.componentData.thumbnailData}
                    ></LibraryThumbnailImage>
                </div>

                {componentViewState.componentData.variantSetDesc && (
                    <div className={componentStyle.componentPreviewDesc} data-testid="component-set-desc" tabIndex={-1}>
                        {componentViewState.componentData.variantSetDesc}
                    </div>
                )}
                {componentViewState.componentData.desc && (
                    <div className={componentStyle.componentPreviewDesc} data-testid="component-desc" tabIndex={-1}>
                        {componentViewState.componentData.desc}
                    </div>
                )}
                <ComponentPropPanel componentProps={componentViewState.componentData.props} />
            </div>
        </div>
    )
}
