import { DELETE, GET, POST } from '@tutor/network-core'
import {
    AnswerReactionType,
    ConversationId,
    ConversationMetaVO,
    ConversationType,
    ConversationVO,
    DialogueId,
    DialogueInput,
    DialogueResponse,
} from '../interface/chatbot'
import { BaseCommonRequest } from './base-request'

@POST
export class Chat extends BaseCommonRequest<DialogueResponse> {
    constructor(
        private readonly dialogueInput: DialogueInput,
        private readonly docId: string,
        private readonly conversationType: ConversationType,
        private readonly conversationId?: string
    ) {
        super()
    }

    public override requestArgument() {
        return {
            docId: this.docId,
            conversationType: this.conversationType,
            ...(this.conversationId ? { conversationId: this.conversationId } : {}),
        }
    }

    public override requestBody() {
        return {
            ...this.dialogueInput,
        }
    }

    public override requestUrl() {
        return `ai/bot/dialogue`
    }
}

@GET
export class GetUserConversationHistory extends BaseCommonRequest<ConversationMetaVO[]> {
    public override requestUrl() {
        return `ai/bot/conversation/history`
    }
}

@GET
export class GetUserConversationById extends BaseCommonRequest<ConversationVO> {
    constructor(private readonly conversationId: ConversationId) {
        super()
    }

    public override requestArgument() {
        return {
            conversationId: this.conversationId,
        }
    }

    public override requestUrl() {
        return `ai/bot/conversation`
    }
}

@DELETE
export class DeleteConversation extends BaseCommonRequest<void> {
    constructor(private readonly conversationId: ConversationId) {
        super()
    }

    public override requestArgument() {
        return {
            conversationId: this.conversationId,
        }
    }

    public override requestUrl() {
        return `ai/bot/conversation`
    }
}

@POST
export class UpdateDialogueReaction extends BaseCommonRequest<void> {
    constructor(
        private readonly conversationId: ConversationId,
        private readonly dialogueId: DialogueId,
        private readonly answerReactionType: AnswerReactionType
    ) {
        super()
    }

    public override requestArgument() {
        return {
            conversationId: this.conversationId,
            dialogueId: this.dialogueId,
            answerReactionType: this.answerReactionType,
        }
    }

    public override requestUrl() {
        return `ai/bot/dialogue/react`
    }
}
