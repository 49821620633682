import { translation } from './inspect-type.translation'
import { EffectType, StrokeAlign } from '../../../../../document/node/node'

import { Wukong } from '@wukong/bridge-proto'
import PaintType = Wukong.DocumentProto.PaintType
import CodeType2 = Wukong.DocumentProto.CodeType

export const CodeTypeLabels: Record<CodeType2, string> = {
    [CodeType2.CODE_TYPE_CSS]: 'CSS',
    [CodeType2.CODE_TYPE_I_O_S]: 'iOS',
    [CodeType2.CODE_TYPE_ANDROID]: 'Android',
}

export enum AttrType {
    NUMBER = 'NUMBER',
    STRING = 'STRING',
    NONE = 'NONE',
}

export const paintTypeToShow: Omit<{ [key in PaintType]: string }, PaintType.PAINT_TYPE_EMOJI> = {
    [PaintType.PAINT_TYPE_SOLID_PAINT]: translation('Solid'),
    [PaintType.PAINT_TYPE_GRADIENT_LINEAR]: translation('LinearGradient'),
    [PaintType.PAINT_TYPE_GRADIENT_RADIAL]: translation('RadialGradient'),
    [PaintType.PAINT_TYPE_GRADIENT_ANGULAR]: translation('AngularGradient'),
    [PaintType.PAINT_TYPE_GRADIENT_DIAMOND]: translation('DiamondGradient'),
    [PaintType.PAINT_TYPE_IMAGE_PAINT]: translation('Image'),
}

export const strokeAlignToShow: { [key in StrokeAlign]: string } = {
    [StrokeAlign.STROKE_ALIGN_CENTER]: translation('Center'),
    [StrokeAlign.STROKE_ALIGN_INSIDE]: translation('Inside'),
    [StrokeAlign.STROKE_ALIGN_OUTSIDE]: translation('Outside'),
}

export const effectTypeToShow: { [key in EffectType]: string } = {
    [EffectType.InnerShadow]: translation('InnerShadow'),
    [EffectType.DropShadow]: translation('DropShadow'),
    [EffectType.LayerBlur]: translation('LayerBlur'),
    [EffectType.BackgroundBlur]: translation('BackgroundBlur'),
}
