import { useEffect, useRef } from 'react'
import { ScrollbarRef } from '../../../../../ui-lib/src'
import { useAppContext } from '../../../main/app-context'

/**
 * 虚拟列表的实现原理
 * 1. 根据全部 items 计算一个 totalHeight，这个高度用于撑开 table container，显示真实的 scrollbar
 * 2. 根据 scrollbar 组件得到可视区域容器的 scrollTop 和 height
 * 3. 遍历 items，根据 item 的 type 累计高度，直到达到 scrollTop - BUFFER_HEIGHT，这时记录当前累计的 offsetTop 作为虚拟列表之前的占位高度
 * 4. 只保留在 scrollTop - BUFFER_HEIGHT 和 scrollTop + height + BUFFER_HEIGHT 之间的 items，这些 items 就是当前可视区域内的 items
 */
export function useLocalVariableScrollContext() {
    const service = useAppContext().variableService.localVariableEditorService
    const scrollbarRef = useRef<ScrollbarRef>(null)
    const handleScroll = (el: HTMLDivElement) => {
        service.onTableScroll(el.scrollTop)
    }
    useEffect(() => {
        const el = scrollbarRef.current?.getContainerElement()
        if (!el) {
            return
        }
        const observer = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                if (!scrollbarRef.current) {
                    return
                }
                if (
                    entry.target instanceof HTMLElement &&
                    entry.target === scrollbarRef.current.getContainerElement()
                ) {
                    service.onTableContainerHeightChange(entry.contentRect)
                }
            })
        })
        observer.observe(el)
        return () => {
            observer.unobserve(el)
            observer.disconnect()
        }
    }, [service])
    return {
        scrollbarRef,
        handleScroll,
    }
}
