import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    SeeResultsOn: '查看其他页面中的结果',
} as const

export const enTranslation = {
    SeeResultsOn: 'See results on other pages',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
