import { translation } from './styles.translation'
/* eslint-disable no-restricted-imports */
import { GetSelectionNodeIdsCommandForWasm, UpdateLocalStyleSelectionCommand, Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import type { HTMLAttributes } from 'react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
    DropdownIconSingleLevelRef,
    DropdownV2,
    IconAdd16,
    IconEffectStyle,
    IconPaint,
    IconText,
    MonoIconPanelTidyUpGrid16,
    Tooltip,
} from '../../../../../../ui-lib/src'
import { cmdChangePopupState } from '../../../../document/command/document-command'
import { PopupStateType } from '../../../../document/node/node'
import { useCommand } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { LocalStyleTestId } from '../../../../window'
import { Title } from '../../atom/title/title'
import { useRenderColorSpace } from '../../color-profile'
import { Position } from '../../comment/type'
import { useEyeDropper } from '../../eye-dropper/use-eye-dropper'
import { ColorSet } from '../color-interaction/color-set/color-set'
import { getDefaultEffects } from '../effects/context'
import { EffectStyleEditor } from '../styles/style-panel/effect-style-editor/effect-style-editor'
import { LayoutGridStyleEditor } from '../styles/style-panel/layout-grid-style-editor/layout-grid-style-editor'
import { TextStyleEditor } from '../styles/style-panel/text-style-editor/text-style-editor'
import { getDefaultLayoutGrid, getFirstFillPaintDefault } from '../styles/style-panel/utils'
import { EffectStyleList } from './effect-style-list-v2'
import { useLocalStyleModel } from './hooks/use-local-style-model-v2'
import { LayoutGridStyleList } from './layout-grid-style-list-v2/layout-grid-style-list'
import { LocalStyleViewer } from './local-style-viewer'
import { PaintStyleList } from './paint-style-list-v2'
import { getEventComposedPath } from './style-util'
import style from './style.module.less'
import { TextStyleList } from './text-style-list-v2'

const createDefaultPaints = [getFirstFillPaintDefault()]
const createDefaultEffects = [getDefaultEffects()]
const createDefaultLayoutGrids = [getDefaultLayoutGrid()]
interface AttributeProps extends HTMLAttributes<HTMLDivElement> {
    isReadonly?: boolean // 只读模式，禁用拖拽点击条目等交互，只能查看样式
}

export function Styles(props: AttributeProps) {
    const { isReadonly } = props
    const model = useLocalStyleModel({ isReadonly })
    const { modelState, editStyle, modelCommand } = model

    const [hover, setHover] = useState<string>()
    const containerRef = useRef<HTMLInputElement>(null)
    const dropdownRef = useRef<DropdownIconSingleLevelRef>(null)
    const { eyeDropperState } = useEyeDropper()

    const rightClickMenuState = useViewState('rightClickMenuState')

    const isDropdownRightMenuOpen = useMemo(() => {
        // NOTE: 弹窗本地样式编辑右键菜单时，不清理本地样式选区
        return (
            rightClickMenuState?.opened &&
            rightClickMenuState?.source == Wukong.DocumentProto.RightClickSource.RIGHT_CLICK_SOURCE_DESIGN_PANEL
        )
    }, [rightClickMenuState?.opened, rightClickMenuState?.source])

    const positionEditor = useMemo(() => {
        const editingStyleId = editStyle
            ? {
                  [Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_NONE]: '',
                  [Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_TEXT]: 'text-',
                  [Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_PAINT]: 'paint-',
                  [Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_EFFECT]: 'effect-',
                  [Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_LAYOUT_GRID]: 'layoutgrid-',
              }[editStyle.type] + editStyle.editingStyleId
            : ''

        const rect = containerRef.current?.querySelector(`[data-style-id="${editingStyleId}"]`)?.getBoundingClientRect()
        return {
            left: rect?.left || 0,
            top: rect?.top || 0,
        }
    }, [editStyle])

    const onClickToClose = useCallback(
        (event: MouseEvent) => {
            if (
                containerRef.current &&
                !getEventComposedPath(event).includes(containerRef.current) &&
                !eyeDropperState?.isDisplay &&
                !isDropdownRightMenuOpen
            ) {
                modelCommand.clearSelection()
            }
        },
        [eyeDropperState?.isDisplay, isDropdownRightMenuOpen, modelCommand]
    )

    // 清除选中样式
    useEffect(() => {
        document.addEventListener('mousedown', onClickToClose)
        return () => {
            document.removeEventListener('mousedown', onClickToClose)
        }
    }, [onClickToClose])

    const popupState = useViewState('popupState')
    const command = useCommand()
    const titleRef = useRef<HTMLInputElement>(null)
    const [createPosition, setCreatePosition] = useState<Position>()

    const isEmptyStyleList = useMemo(() => {
        const fontNum = modelState?.textStyleItems.length ?? 0
        const effectNum = modelState?.effectStyleItems.length ?? 0
        const paintNum = modelState?.paintStyleItems.length ?? 0
        const layoutGridNum = modelState?.layoutGridStyleItems.length ?? 0
        return fontNum + effectNum + paintNum + layoutGridNum === 0
    }, [
        modelState?.effectStyleItems.length,
        modelState?.layoutGridStyleItems.length,
        modelState?.paintStyleItems.length,
        modelState?.textStyleItems.length,
    ])

    const changePopupState = useCallback(
        (type: PopupStateType) => {
            command.invoke(cmdChangePopupState, {
                type,
                reciprocalIndex: -1,
                multiPopup: [],
            })
        },
        [command]
    )

    const clearStyleSelection = useCallback(() => {
        command.DEPRECATED_invokeBridge(UpdateLocalStyleSelectionCommand, {})
    }, [command])

    const addStyleRefactor = (styleType: 'text' | 'paint' | 'effect' | 'layoutGrid', styleId: string) => {
        switch (styleType) {
            case 'text': {
                model.modelCommand.updateEditStyle({
                    type: Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_TEXT,
                    editingStyleId: styleId,
                    isCreate: true,
                })
                changePopupState(PopupStateType.POPUP_STATE_TYPE_CREATE_TEXT_STYLE)
                break
            }
            case 'paint': {
                model.modelCommand.updateEditStyle({
                    type: Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_PAINT,
                    editingStyleId: styleId,
                    isCreate: true,
                })

                changePopupState(PopupStateType.POPUP_STATE_TYPE_CREATE_PAINT_STYLE)
                break
            }
            case 'effect': {
                model.modelCommand.updateEditStyle({
                    type: Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_EFFECT,
                    editingStyleId: styleId,
                    isCreate: true,
                })

                changePopupState(PopupStateType.POPUP_STATE_TYPE_CREATE_EFFECT_STYLE)
                break
            }
            case 'layoutGrid': {
                model.modelCommand.updateEditStyle({
                    type: Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_LAYOUT_GRID,
                    editingStyleId: styleId,
                    isCreate: true,
                })
                changePopupState(PopupStateType.POPUP_STATE_TYPE_CREATE_LAYOUT_GRID_STYLE)
                break
            }
        }
    }

    const addStyle = useCallback(
        (value: string, iconTop?: number) => {
            const rect = titleRef.current?.getBoundingClientRect()
            if (!rect) {
                return
            }
            setCreatePosition({ top: iconTop !== undefined ? iconTop : rect.top, left: rect.left })
            switch (value) {
                case 'text': {
                    changePopupState(PopupStateType.POPUP_STATE_TYPE_CREATE_TEXT_STYLE)
                    break
                }
                case 'paint': {
                    changePopupState(PopupStateType.POPUP_STATE_TYPE_CREATE_PAINT_STYLE)
                    break
                }
                case 'effect': {
                    changePopupState(PopupStateType.POPUP_STATE_TYPE_CREATE_EFFECT_STYLE)
                    break
                }
                case 'layoutGrid': {
                    changePopupState(PopupStateType.POPUP_STATE_TYPE_CREATE_LAYOUT_GRID_STYLE)
                    break
                }
            }
        },
        [changePopupState]
    )

    const onClickTitle = useCallback(() => {
        if (isEmptyStyleList) {
            dropdownRef.current?.open()
        }
    }, [isEmptyStyleList])

    const addTextStyle = useCallback(
        (e: React.MouseEvent) => {
            clearStyleSelection()
            addStyle('text', e.currentTarget.getBoundingClientRect().top)
        },
        [addStyle, clearStyleSelection]
    )
    const addPaintStyle = useCallback(
        (e: React.MouseEvent) => {
            clearStyleSelection()
            addStyle('paint', e.currentTarget.getBoundingClientRect().top)
        },
        [addStyle, clearStyleSelection]
    )
    const addEffectStyle = useCallback(
        (e: React.MouseEvent) => {
            clearStyleSelection()
            addStyle('effect', e.currentTarget.getBoundingClientRect().top)
        },
        [addStyle, clearStyleSelection]
    )
    const addLayoutGridStyle = useCallback(
        (e: React.MouseEvent) => {
            clearStyleSelection()
            addStyle('layoutGrid', e.currentTarget.getBoundingClientRect().top)
        },
        [addStyle, clearStyleSelection]
    )

    const selectionNodes = useCallback(() => {
        return command.DEPRECATED_invokeBridge(GetSelectionNodeIdsCommandForWasm).value
    }, [command])

    const colorSpace = useRenderColorSpace()
    const openStyleEditor = useMemo(() => {
        return popupState?.type === PopupStateType.POPUP_STATE_TYPE_STYLE_MANAGE
    }, [popupState?.type])

    const onCancel = () => modelCommand.updateEditStyle(null)

    const renderStyleEditor = () => {
        const isCreatePaintStyle = popupState?.type === PopupStateType.POPUP_STATE_TYPE_CREATE_PAINT_STYLE
        const isCreateEffectStyle = popupState?.type === PopupStateType.POPUP_STATE_TYPE_CREATE_EFFECT_STYLE
        const isCreateTextStyle = popupState?.type === PopupStateType.POPUP_STATE_TYPE_CREATE_TEXT_STYLE
        const isCreateLayoutGridStyle = popupState?.type === PopupStateType.POPUP_STATE_TYPE_CREATE_LAYOUT_GRID_STYLE
        if (isCreatePaintStyle) {
            //
        } else if (isCreateEffectStyle) {
            //
        } else if (isCreateTextStyle) {
            //
        } else if (isCreateLayoutGridStyle) {
            //
        } else if (!editStyle) {
            return null
        }
        if (
            editStyle?.type === Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_PAINT ||
            isCreatePaintStyle
        ) {
            return (isCreatePaintStyle && createPosition) || openStyleEditor ? (
                <ColorSet
                    position={isCreatePaintStyle ? createPosition! : positionEditor}
                    isCreateStyle={isCreatePaintStyle}
                    allwaysUseStyleTab={false}
                    isRemoteStyle={false}
                    paints={createDefaultPaints}
                    hideStyleTab={false}
                    hideVariableTab={true}
                    onCancel={onCancel}
                    onClickCreateButton={onCancel}
                    onCreateStyle={() => {}}
                    onCreateColorVariable={() => {}}
                    onClickRemoteStyleLink={undefined}
                    hideCommonFooter={false}
                />
            ) : null
        }
        if (
            editStyle?.type === Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_EFFECT ||
            isCreateEffectStyle
        ) {
            return (
                <EffectStyleEditor
                    styleId={editStyle?.editingStyleId ?? ''}
                    visible={openStyleEditor || isCreateEffectStyle}
                    position={isCreateEffectStyle ? createPosition : positionEditor}
                    onCancel={onCancel}
                    createStyle={{ isCreate: isCreateEffectStyle, effects: createDefaultEffects }}
                    onClickCreate={onCancel}
                    effectCreateStyle={(v) => addStyleRefactor('effect', v)}
                />
            )
        }
        if (
            editStyle?.type === Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_TEXT ||
            isCreateTextStyle
        ) {
            return (
                <TextStyleEditor
                    styleId={editStyle?.editingStyleId ?? ''}
                    visible={openStyleEditor || isCreateTextStyle}
                    position={isCreateTextStyle ? createPosition : positionEditor}
                    onCancel={onCancel}
                    createStyle={{ isCreate: isCreateTextStyle }}
                    onClickCreate={onCancel}
                    effectCreateStyle={(v) => addStyleRefactor('text', v)}
                />
            )
        }
        if (
            editStyle?.type === Wukong.DocumentProto.LocalStyleSelectionType.LOCAL_STYLE_SELECTION_TYPE_LAYOUT_GRID ||
            isCreateLayoutGridStyle
        ) {
            return (
                <LayoutGridStyleEditor
                    colorSpace={colorSpace}
                    styleId={editStyle?.editingStyleId ?? ''}
                    visible={openStyleEditor || isCreateLayoutGridStyle}
                    position={isCreateLayoutGridStyle ? createPosition : positionEditor}
                    onCancel={onCancel}
                    createStyle={{ isCreate: isCreateLayoutGridStyle, layoutGrids: createDefaultLayoutGrids }}
                    onClickCreate={onCancel}
                    effectCreateStyle={(v) => addStyleRefactor('layoutGrid', v)}
                />
            )
        }
        return null
    }

    if (isReadonly && isEmptyStyleList) {
        return null
    }
    return (
        <div ref={containerRef} className={props.className}>
            <Title
                ref={titleRef}
                grayTheme={isEmptyStyleList}
                className={classnames(style.title, { [style.isEmptyStyleList]: isEmptyStyleList })}
                onClick={onClickTitle}
            >
                <Title.Left>{translation('LocalStyles')}</Title.Left>
                <Title.Right>
                    {isReadonly ? null : (
                        <Tooltip title={translation('CreateStyle')} triggerRefKey="getTriggerElement">
                            <DropdownV2.IconSingleLevel
                                ref={dropdownRef}
                                label={
                                    <span className={style.addIcon}>
                                        <IconAdd16 />
                                    </span>
                                }
                                onOpen={clearStyleSelection}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(v) => addStyle(v)}
                                classNameWhenOpen={style.open}
                                dataTestIds={{ triggerFocus: 'create-local-style' }}
                            >
                                <DropdownV2.IconSingleLevel.Option
                                    forwardChildren={<IconText />}
                                    value="text"
                                    dataTestId="create-local-style-text-type"
                                >
                                    {translation('TextStyles')}
                                </DropdownV2.IconSingleLevel.Option>
                                <DropdownV2.IconSingleLevel.Option
                                    forwardChildren={<IconPaint />}
                                    value="paint"
                                    dataTestId="create-local-style-paint-type"
                                >
                                    {translation('ColorStyles')}
                                </DropdownV2.IconSingleLevel.Option>
                                <DropdownV2.IconSingleLevel.Option
                                    forwardChildren={<IconEffectStyle />}
                                    value="effect"
                                    dataTestId="create-local-style-effect-type"
                                >
                                    {translation('EffectStyles')}
                                </DropdownV2.IconSingleLevel.Option>
                                <DropdownV2.IconSingleLevel.Option
                                    forwardChildren={<MonoIconPanelTidyUpGrid16 />}
                                    value="layoutGrid"
                                    dataTestId="create-local-style-layout-gride-type"
                                >
                                    {translation('GridStyles')}
                                </DropdownV2.IconSingleLevel.Option>
                            </DropdownV2.IconSingleLevel>
                        </Tooltip>
                    )}
                </Title.Right>
            </Title>
            {/* <DragArea> */}
            <div key="text" className={style.group} data-testid={LocalStyleTestId.TextStyleListContainer}>
                <TextStyleList
                    model={model}
                    hover={hover}
                    setHover={setHover}
                    isReadonly={isReadonly}
                    onChickAddIcon={addTextStyle}
                />
            </div>
            <div key="paint" className={style.group} data-testid={LocalStyleTestId.PaintStyleListContainer}>
                <PaintStyleList
                    model={model}
                    hover={hover}
                    setHover={setHover}
                    isReadonly={isReadonly}
                    onChickAddIcon={addPaintStyle}
                />
            </div>
            <div key="effect" className={style.group} data-testid={LocalStyleTestId.EffectStyleListContainer}>
                <EffectStyleList
                    model={model}
                    hover={hover}
                    setHover={setHover}
                    isReadonly={isReadonly}
                    onChickAddIcon={addEffectStyle}
                />
            </div>
            {!(isReadonly && selectionNodes.length !== 0) && (
                <div
                    key="layoutgrid"
                    className={style.group}
                    data-testid={LocalStyleTestId.LayoutGridStyleListContainer}
                >
                    <LayoutGridStyleList
                        model={model}
                        hover={hover}
                        setHover={setHover}
                        isReadonly={isReadonly}
                        onChickAddIcon={addLayoutGridStyle}
                    />
                </div>
            )}

            {/* </DragArea> */}
            {isReadonly ? (
                <LocalStyleViewer
                    editStyle={editStyle}
                    positionEditor={positionEditor}
                    onClose={() => modelCommand.updateEditStyle(null)}
                />
            ) : (
                renderStyleEditor()
            )}
        </div>
    )
}
