import { Wukong } from '@wukong/bridge-proto'
import { useAppContext } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge/use-view-state'
import { FloatValueEditingPopupPure } from './float-value-editing-popup-pure'

// 数值变量选择器，受本地变量面板的状态控制
export function FloatValueEditingPopup() {
    const service = useAppContext().variableService.localVariableEditorService
    const editingState = useViewState('localVariableEditorEditingState')
    const onCancel = () => service.closeVariableEditingPopup()
    const onVariableSelected = (id: string) => {
        if (!editingState) {
            return
        }
        service.setAliasForVariable(editingState.editingVariableId, editingState.editingModeId, id)
        service.closeVariableEditingPopup()
    }
    if (
        editingState?.type !==
        Wukong.DocumentProto.LocalVariableEditorEditingType.LOCAL_VARIABLE_EDITOR_EDITING_TYPE_NUMBER_VALUE_ALIAS
    ) {
        return null
    }
    return (
        <FloatValueEditingPopupPure
            position={editingState.position}
            onCancel={onCancel}
            onVariableSelected={onVariableSelected}
            variableData={editingState.editingVariableData}
        />
    )
}
