/* eslint-disable no-restricted-imports */
import { WKFrog } from '../../kernel/frog'
import { WKFrogTaskStateService } from './wk-frog-task-state-service'

export enum File_type {
    isNotDraftFile = '0',
    isDraftFile = '1',
}

export enum FontSelectorTab {
    All = '0', // 全部
    Team = '1', // 团队
    Cloud = '2', // 云端 remote
    Local = '3', // 本地
}

type Milliseconds = number

function getLdap() {
    return WKFrogTaskStateService.getUserVOV2()?.userBrief.email ?? ''
}
function getFileType() {
    return WKFrogTaskStateService.getDocGetVO()?.draft ? File_type.isDraftFile : File_type.isNotDraftFile
}

function getFileId() {
    return WKFrogTaskStateService.getDocGetVO()?.id ?? ''
}

function durationMilliseconds(startTime: Milliseconds | undefined) {
    return startTime === undefined ? -1 : WKFrogTaskStateService.getDurationMilliseconds(startTime)
}
// 不需要重置的字段
let persistentSearchContent = ''

// 需要重置的字段
let startTimeEnter: Milliseconds | undefined = undefined
let startTimeAllTab: Milliseconds | undefined = undefined
let startTimeTeamTab: Milliseconds | undefined = undefined
let startTimeCloudTab: Milliseconds | undefined = undefined
let startTimeLocalTab: Milliseconds | undefined = undefined

// 可能要重置的字段
let fontSelectorTab: FontSelectorTab = FontSelectorTab.All
let existUploadTeamFontsBtn = false
let existDownloadLocalFontsPluginBtn = false
let existSearchContentDuringTheOpening = false

function resetState() {
    startTimeEnter = undefined
    startTimeAllTab = undefined
    startTimeTeamTab = undefined
    startTimeCloudTab = undefined
    startTimeLocalTab = undefined

    const isCache = !!persistentSearchContent
    if (isCache) {
        return
    }
    fontSelectorTab = FontSelectorTab.All
    existUploadTeamFontsBtn = false
    existDownloadLocalFontsPluginBtn = false
    existSearchContentDuringTheOpening = false
}

function visibleTabsAutoEffect() {
    const startTime = Date.now()
    switch (fontSelectorTab) {
        case FontSelectorTab.All: {
            startTimeAllTab = startTime
            break
        }
        case FontSelectorTab.Cloud: {
            startTimeCloudTab = startTime
            break
        }
        case FontSelectorTab.Local: {
            startTimeLocalTab = startTime
            if (existDownloadLocalFontsPluginBtn) {
                fontSelector.exposeDownloadLocalFontsPlugin()
            }
            break
        }
        case FontSelectorTab.Team: {
            startTimeTeamTab = startTime
            if (existUploadTeamFontsBtn) {
                fontSelector.exposeUploadTeamFonts()
            }
            break
        }
    }
}

function hiddenTabsAutoEffect() {
    switch (fontSelectorTab) {
        case FontSelectorTab.All: {
            fontSelector.allTabDuration()
            break
        }
        case FontSelectorTab.Cloud: {
            fontSelector.cloudTabDuration()
            break
        }
        case FontSelectorTab.Local: {
            fontSelector.localTabDuration()
            break
        }
        case FontSelectorTab.Team: {
            fontSelector.teamTabDuration()
            break
        }
    }
    if (existSearchContentDuringTheOpening) {
        fontSelector.searchFonts(persistentSearchContent)
    }
}

export const fontSelector = {
    _recordSearchContent(searchContent: string) {
        if (!existSearchContentDuringTheOpening) {
            existSearchContentDuringTheOpening = !!searchContent
        }
        persistentSearchContent = searchContent
    },
    click() {
        WKFrog.addFrogRecord({
            url: '/click/FontSelector/click',
            eventId: 21486,
            eventName: 'click',
            eventAction: 'click',
            customExtend: {
                ldap: getLdap(),
                file_type: getFileType(),
            },
        })
    },
    enter() {
        WKFrogTaskStateService.recordBrowserPageVisibility()
        resetState()
        visibleTabsAutoEffect()

        startTimeEnter = Date.now()
        WKFrog.addFrogRecord({
            url: '/expose/FontSelector/enter',
            eventId: 21487,
            eventName: 'enter',
            eventAction: 'expose',
            customExtend: {
                ldap: getLdap(),
                file_type: getFileType(),
            },
        })
    },
    panelDuration(isFlash?: boolean) {
        const duration = durationMilliseconds(startTimeEnter)
        WKFrog.addFrogRecord({
            url: '/time/FontSelector/PanelDuration',
            eventId: 21488,
            eventName: 'panelDuration',
            eventAction: 'time',
            customExtend: {
                ldap: getLdap(),
                file_type: getFileType(),
                duration,
            },
        })
        hiddenTabsAutoEffect()
        if (isFlash) {
            WKFrog.flushRecord()
        }
    },
    allTab() {
        fontSelectorTab = FontSelectorTab.All
        startTimeAllTab = Date.now()
        WKFrog.addFrogRecord({
            url: '/click/FontSelector/allTab',
            eventId: 21489,
            eventName: 'allTab',
            eventAction: 'click',
            customExtend: { ldap: getLdap(), file_id: getFileId(), file_type: getFileType() },
        })
    },
    teamTab() {
        fontSelectorTab = FontSelectorTab.Team
        startTimeTeamTab = Date.now()
        WKFrog.addFrogRecord({
            url: '/click/FontSelector/teamTab',
            eventId: 21490,
            eventName: 'teamTab',
            eventAction: 'click',
            customExtend: { ldap: getLdap(), file_id: getFileId(), file_type: getFileType() },
        })
    },
    cloudTab() {
        fontSelectorTab = FontSelectorTab.Cloud
        startTimeCloudTab = Date.now()
        WKFrog.addFrogRecord({
            url: '/click/FontSelector/cloudTab',
            eventId: 21491,
            eventName: 'cloudTab',
            eventAction: 'click',
            customExtend: { ldap: getLdap(), file_id: getFileId(), file_type: getFileType() },
        })
    },
    localTab() {
        fontSelectorTab = FontSelectorTab.Local
        startTimeLocalTab = Date.now()
        WKFrog.addFrogRecord({
            url: '/click/FontSelector/localTab',
            eventId: 21492,
            eventName: 'localTab',
            eventAction: 'click',
            customExtend: { ldap: getLdap(), file_id: getFileId(), file_type: getFileType() },
        })
    },
    allTabDuration() {
        const duration = durationMilliseconds(startTimeAllTab)
        WKFrog.addFrogRecord({
            url: '/time/FontSelector/allTabDuration',
            eventId: 21493,
            eventName: 'allTabDuration',
            eventAction: 'time',
            customExtend: { duration, file_id: getFileId(), file_type: getFileType() },
        })
    },
    teamTabDuration() {
        const duration = durationMilliseconds(startTimeTeamTab)
        WKFrog.addFrogRecord({
            url: '/time/FontSelector/teamTabDuration',
            eventId: 21493,
            eventName: 'teamTabDuration',
            eventAction: 'time',
            customExtend: { duration, file_id: getFileId(), file_type: getFileType() },
        })
    },
    cloudTabDuration() {
        const duration = durationMilliseconds(startTimeCloudTab)
        WKFrog.addFrogRecord({
            url: '/time/FontSelector/cloudTabDuration',
            eventId: 21504,
            eventName: 'cloudTabDuration',
            eventAction: 'time',
            customExtend: { ldap: getLdap(), duration, file_type: getFileType() },
        })
    },
    localTabDuration() {
        const duration = durationMilliseconds(startTimeLocalTab)
        WKFrog.addFrogRecord({
            url: '/time/FontSelector/localTabDuration',
            eventId: 21505,
            eventName: 'localTabDuration',
            eventAction: 'time',
            customExtend: { ldap: getLdap(), duration, file_type: getFileType() },
        })
    },
    exposeUploadTeamFonts() {
        existUploadTeamFontsBtn = true
        WKFrog.addFrogRecord({
            url: '/expose/FontSelector/uploadTeamFonts',
            eventId: 21494,
            eventName: 'uploadTeamFonts',
            eventAction: 'expose',
            customExtend: { ldap: getLdap(), file_id: getFileId(), file_type: getFileType() },
        })
    },
    uploadTeamFonts() {
        WKFrog.addFrogRecord({
            url: '/click/FontSelector/UploadTeamFonts',
            eventId: 21495,
            eventName: 'uploadTeamFonts',
            eventAction: 'click',
            customExtend: { ldap: getLdap(), file_id: getFileId(), file_type: getFileType() },
        })
    },
    exposeDownloadLocalFontsPlugin() {
        existDownloadLocalFontsPluginBtn = true
        WKFrog.addFrogRecord({
            url: '/expose/FontSelector/downloadLocalFontsPlugin',
            eventId: 21506,
            eventName: 'downloadLocalFontsPlugin',
            eventAction: 'expose',
            customExtend: { ldap: getLdap() },
        })
    },
    downloadLocalFontsPlugin() {
        WKFrog.addFrogRecord({
            url: '/click/FontSelector/downloadLocalFontsPlugin',
            eventId: 21506,
            eventName: 'downloadLocalFontsPlugin',
            eventAction: 'click',
            customExtend: { ldap: getLdap() },
        })
    },
    searchFonts(search_query: string) {
        const search_location = fontSelectorTab
        WKFrog.addFrogRecord({
            url: '/event/FontSelector/searchFonts',
            eventId: 21508,
            eventName: 'searchFonts',
            eventAction: 'event',
            customExtend: { search_location, search_query, file_type: getFileType() },
        })
    },
    fontsSearchResults(font_family: string, layer_id: string) {
        const font_location = fontSelectorTab
        WKFrog.addFrogRecord({
            url: '/click/FontSelector/fontsSearchResults',
            eventId: 21513,
            eventName: 'fontsSearchResults',
            eventAction: 'click',
            customExtend: {
                ldap: getLdap(),
                font_location,
                file_type: getFileType(),
                font_family,
                layer_id,
            },
        })
    },
    fontsList(font_family: string, layer_id: string) {
        const font_location = fontSelectorTab
        WKFrog.addFrogRecord({
            url: '/click/FontSelector/fontsList',
            eventId: 21514,
            eventName: 'fontsList',
            eventAction: 'click',
            customExtend: { font_family, file_id: getFileId(), layer_id, file_type: getFileType(), font_location },
        })
    },
    recentlyUsedFonts(font_family: string, layer_id: string) {
        WKFrog.addFrogRecord({
            url: '/click/FontSelector/recentlyUsedFonts',
            eventId: 21515,
            eventName: 'recentlyUsedFonts',
            eventAction: 'click',
            customExtend: {
                ldap: getLdap(),
                file_type: getFileType(),
                font_family,
                layer_id,
            },
        })
    },
}
