import constate from 'constate'
import { RadioGroupStateProps, useRadioGroupState } from '../common/use-radio-group-state'

export interface WKRadioIconButtonGroupContextProps extends RadioGroupStateProps {
    /**
     * @defaultValue 24
     */
    size?: 24 | 28
    activeClassName?: string
}

const useRadioIconButtonGroup = (props: WKRadioIconButtonGroupContextProps) => {
    const { size = 24, activeClassName } = props
    const radioGroupState = useRadioGroupState(props)

    return {
        size,
        activeClassName,
        ...radioGroupState,
    }
}

export const [RadioIconButtonGroupProvider, useRadioIconButtonGroupContext] = constate(useRadioIconButtonGroup)
