import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    TheFollowingFonts: '下列字体无法使用，需要替换后才可正常查看和编辑',
    ZsrELj: '你还没有安装字体助手，安装后刷新页面即可使用本地字体。',
    ljMATz: '安装字体助手',
    yathDl: '你也可以替换下列字体后进行编辑。',
    UpdateTitle: '本地字体版本过低，请更新后刷新页面使用本地字体。',
    UpdateButton: '立即更新',
} as const

export const enTranslation = {
    TheFollowingFonts: 'The following fonts are unavailable. Choose replacement fonts to edit.',
    ZsrELj: 'You have not installed the Motiff Font Agent. Install it and reload the page to enable your local fonts.',
    ljMATz: 'Install',
    yathDl: 'You can also replace the following fonts to edit the text.',
    UpdateTitle: 'The font agent version is out of date. Update it and refresh the page to use the local font.',
    UpdateButton: 'Upgrade now',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
