import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    SortBy: '排序方式',
    Order: '顺序',
    DateViewed: '查看时间',
    DateCreated: '创建时间',
    EYQEDJ: '收藏时间',
    LastMotified: '修改时间',
    DateDeleted: '删除时间',
    PvuNHb: '删除人',
    Alphabetical: '名称',
} as const

export const enTranslation = {
    SortBy: 'Sort by',
    Order: 'Order',
    DateViewed: 'Last viewed',
    DateCreated: 'Date created',
    EYQEDJ: 'Date favorited',
    LastMotified: 'Last modified',
    DateDeleted: 'Date deleted',
    PvuNHb: 'Deleted by',
    Alphabetical: 'Alphabetical',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
