import { UserBriefVO } from './type'

export enum CreateHistoryVersionType {
    // 服务端这个字段枚举值有20个， 客户端只需要知道这四个
    Manual = 'manual',
    PublishLibrary = 'publishLibrary',
    UnPublishLibrary = 'unPublishLibrary',
    UpdateLibrary = 'updateLibrary',
}

export interface GetHistoryVersionResult {
    /**
     * 是否已经全部返回，值为false时表示已经是最后一页，值为true时表示还有下一页
     */
    finish: boolean
    /**
     * 当前页的版本数据
     */
    list: HistoryVersion[]
    /**
     * 是否有超出30天的版本
     */
    existsBeyondThresholdVersion: boolean
}

export interface HistoryVersion {
    id: number
    docId: string
    txId: number
    epoch: number
    name: string
    desc: string
    createType: CreateHistoryVersionType
    createUser: UserBriefVO
    editUsers: UserBriefVO[]
    dbctime: number // 版本创建时间
    dbutime: number // 版本更新时间
}

export interface CreateVersionParams {
    docId: string
    name: string
    desc: string
    createType: CreateHistoryVersionType
}

export interface HistoryVersionInfo {
    name: string
    desc: string
}
