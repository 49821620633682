import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    FileRemovedTo: '文件已取消收藏',
    FileAddedTo: '文件已收藏',
    Delete: '确定删除文件 {{docName}} 吗？',
    PeopleWithEdit: '被删除的文件可以在回收站中找到并恢复。',
    Delete_synonyms1: '删除...',
    Delete_synonyms2: '删除',
    HideVersionHistory: '退出历史版本',
    ShowVersionHistory: '查看历史版本',
    Export: '批量导出',
    FileColorProfile: '颜色配置文件',
    Duplicate: '创建文件副本',
    Rename: '重命名',
    RemoveFromFavorites: '取消收藏',
    AddToFavorites: '收藏',
    'MoveTo...': '移动到...',
    ProhibitCopy: '当前文件被设置为禁止查看者复制',
    ProhibitExport: '当前文件被设置为禁止查看者导出',
} as const

export const enTranslation = {
    FileRemovedTo: 'File removed from favorites',
    FileAddedTo: 'File added to favorites',
    Delete: 'Are you sure you want to delete the file {{docName}}?',
    PeopleWithEdit: 'People with edit access to this file can restore it later from the ',
    Delete_synonyms1: 'Delete...',
    Delete_synonyms2: 'Delete',
    HideVersionHistory: 'Hide version history',
    ShowVersionHistory: 'Show version history',
    Export: 'Export',
    FileColorProfile: 'File color profile',
    Duplicate: 'Duplicate',
    Rename: 'Rename',
    RemoveFromFavorites: 'Remove from favorites',
    AddToFavorites: 'Add to favorites',
    'MoveTo...': 'Move...',
    ProhibitCopy: 'This file settings disable viewers from copying.',
    ProhibitExport: 'This file settings disable viewers from exporting.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
