import { sleep } from '../../../../../../util/src'
import { createSwitchTask, signalDebounce } from '../../../../../../util/src/abort-controller/signal-task'
import {
    TraceableAbortController,
    TraceableAbortSignal,
} from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { ReplayEventEmitter } from '../../../../../../util/src/event-emitter/replay-event-emitter'
import type { NetworkService } from '../../../../document/synergy/network-service'
import { WkCLog } from '../../../../kernel/clog/wukong/instance'
import { AILibraryCredentialsVO, OssCredentials } from '../../../../kernel/interface/oss'
import { CloudAuthClient } from '../../../../kernel/request/cloud-auth-client'
import { GetAILibraryCredentialsRequest } from '../../../../kernel/request/library'
import { ServiceClass } from '../../../../kernel/util/service-class'

const MIN_1 = 60000
const FETCH_TIMEOUT = 3000 // 授权接口请求时长上限

// AI组件库资源下载
export class AILibraryResourceDownloader extends ServiceClass {
    private componentOssClient: CloudAuthClient | undefined
    private expirationTime = 0

    private libraryCredentialsV1$ = new ReplayEventEmitter<AILibraryCredentialsVO>(1)
    private online = false
    private requestAILibraryCredentials: () => void
    private refresh: () => void

    constructor(private networkService: NetworkService, private signal: TraceableAbortSignal) {
        super()
        this.requestAILibraryCredentials = createSwitchTask(this.signal, async (signal: TraceableAbortSignal) => {
            if (!this.online) {
                return
            }
            new GetAILibraryCredentialsRequest()
                .startWithSignal(signal)
                .then((ret) => {
                    this.libraryCredentialsV1$.next(ret)
                })
                .catch(() => {})
        })
        this.refresh = signalDebounce(
            this.signal,
            () => {
                this.requestAILibraryCredentials()
            },
            300
        )
        this.initV2()
    }

    private initV2 = () => {
        this.networkService.onBrowserOnlineChangeWithSignal(this.signal, (online) => {
            this.online = online
            this.requestAILibraryCredentials()
        })

        this.libraryCredentialsV1$.onWithSignal(this.signal, (libraryCredentials) => {
            this.expirationTime = new Date(libraryCredentials.credentials.expiration).getTime()
        })
    }

    private resetLibraryCredentialsV2 = async (needWaitFirstValue?: boolean) => {
        this.componentOssClient = undefined
        const prevLibraryCredentials = needWaitFirstValue ? await this.initLibraryCredentials().catch(() => null) : null
        if (this.isDestroy) {
            return
        }
        this.refresh()
        const fetchLibraryCredentials = async (): Promise<AILibraryCredentialsVO> => {
            return new Promise((resolve) => {
                const checkExpiration = (credentials: OssCredentials) => {
                    return (
                        !prevLibraryCredentials ||
                        credentials.expiration !== prevLibraryCredentials.credentials.expiration
                    )
                }
                const controller = new TraceableAbortController('AILibraryResourceDownloader.fetchLibraryCredentials')
                this.libraryCredentialsV1$.onWithSignal(controller.signal, (libraryCredentials) => {
                    if (checkExpiration(libraryCredentials.credentials)) {
                        resolve(libraryCredentials)
                        controller.abort('AILibraryResourceDownloader.fetchLibraryCredentials success')
                    }
                })
            })
        }

        const newLibraryCredentials = await Promise.race([fetchLibraryCredentials(), sleep(FETCH_TIMEOUT)])

        if (this.isDestroy) {
            return
        }

        if (!newLibraryCredentials) {
            return
        }

        if (typeof newLibraryCredentials === 'string') {
            WkCLog.log(
                `[oss] reset libraryCredentials timeout (online=${
                    this.networkService.states.getState().browserOnlineState ? 'true' : 'false'
                }, current=${JSON.stringify(prevLibraryCredentials?.credentials)})`
            )
            throw new Error('[oss] reset libraryCredentials timeout')
        }

        WkCLog.log(
            `[oss] reset libraryCredentials success (current=${JSON.stringify(newLibraryCredentials.credentials)})`
        )
    }

    private initLibraryCredentials = async () => {
        const libraryCredentials = await Promise.race([
            this.libraryCredentialsV1$.getFirstValue(this.signal),
            sleep(FETCH_TIMEOUT),
        ])

        if (typeof libraryCredentials === 'string') {
            WkCLog.log(
                `[oss] init libraryCredentials timeout (online=${
                    this.networkService.states.getState().browserOnlineState ? 'true' : 'false'
                })`
            )
            throw new Error('[oss] init libraryCredentials timeout')
        }

        return libraryCredentials
    }

    private refreshLibraryCredentials = async () => {
        // 过期则重置超时时间
        if (!this.expirationTime || Date.now() > this.expirationTime - MIN_1) {
            await this.resetLibraryCredentialsV2(!!this.expirationTime)
        }
    }

    private getOssClient = async () => {
        await this.refreshLibraryCredentials()
        const libraryCredentials = await this.initLibraryCredentials()

        if (!this.componentOssClient) {
            this.componentOssClient = new CloudAuthClient({
                dirPath: libraryCredentials.path,
                bucket: libraryCredentials.bucket,
                region: libraryCredentials.region,
                credentials: libraryCredentials.credentials,
            })
            WkCLog.log(`[oss] init componentOssClient by ${JSON.stringify(libraryCredentials.credentials)}`)
        }
        return this.componentOssClient
    }

    public fetchFile = async (path: string): Promise<Response> => {
        const fetch = async () => await (await this.getOssClient()).fetchFileByPath(path)
        try {
            return await fetch()
        } catch {
            WkCLog.log('[oss] fetchFile 开始兜底: ' + path)
            try {
                // 报错可能因为 credential 过期导致，重置 credential
                await this.resetLibraryCredentialsV2()
                return await fetch()
            } catch (err) {
                WkCLog.log('[oss] fetchFile 兜底失败: ' + path)
                throw err
            }
        }
    }

    public fetchUrl = async (path: string): Promise<string> => {
        const fetch = async () => (await this.getOssClient()).fetchUrlByPath(path)
        try {
            return await fetch()
        } catch {
            WkCLog.log('[oss] fetchUrl 开始兜底: ' + path)
            try {
                // 报错可能因为 credential 过期导致，重置 credential
                await this.resetLibraryCredentialsV2()
                return await fetch()
            } catch (err) {
                WkCLog.log('[oss] fetchUrl 兜底失败: ' + path)
                throw err
            }
        }
    }
}
