import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    Exporting: '正在导出',
    FailedToExport: '导出失败',
    VWjsVG: '当前页面没有可导出的最外层容器',
    ProhibitExport: '当前文件被设置为禁止查看者导出',
} as const

export const enTranslation = {
    Exporting: 'Exporting',
    FailedToExport: 'An error occurred while exporting',
    VWjsVG: 'There are no top-level frames on the this page to export',
    ProhibitExport: 'This file settings disable viewers from exporting.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
