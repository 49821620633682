import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import {
    ExportEachAsPDFPage,
    GetCurrentPageIdCommand,
    GetTopFrameWhichSortByArrange,
    PluginApiGetName,
} from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { useCallback, useEffect, useRef } from 'react'
import { WKToast } from '../../../../../ui-lib/src'
import { base64ToBlob, downloadBlob } from '../../../../../util/src'
import { TraceableAbortSignal } from '../../../../../util/src/abort-controller/traceable-abort-controller'
import { featureSwitchManager } from '../../../kernel/switch'
import { useCommand, useCopyCheckService, useExporterService, useRawMemAccessService } from '../../../main/app-context'
import { useDocInfoContext } from '../../context/top-area-context'
import { useExportPreparation } from '../design/export/hooks/use-export-preparation'
import { ToastProgressMessage } from '../top-area/tool-sync-status/sync-toast-progress-message/sync-toast-progress-message'

export function useExportAsDocument() {
    const toastIdRef = useRef<string>()
    const exporterService = useExporterService()
    const docInfo = useDocInfoContext()

    const closeToast = useCallback(() => {
        return new Promise<void>((resolve) => {
            if (toastIdRef.current) {
                const currToastId = toastIdRef.current
                toastIdRef.current = undefined
                // NOTE: 加 setTimeout 避免出现 Warning: Attempted to synchronously unmount a root while React was already rendering
                setTimeout(() => {
                    WKToast.close(currToastId)
                    resolve()
                }, 0)
            } else {
                resolve()
            }
        })
    }, [])

    const showToast = useCallback(() => {
        toastIdRef.current = WKToast.show(
            <ToastProgressMessage
                progress={undefined}
                progressMsg={`${translation('Exporting')}...`}
                onProgressEnd={closeToast}
            />,
            { duration: -1 }
        )
    }, [closeToast])

    const updateToast = useCallback(
        (progress: number) => {
            if (toastIdRef.current) {
                WKToast.updateMessage(
                    toastIdRef.current,
                    <ToastProgressMessage
                        progress={progress}
                        progressMsg={`${translation('Exporting')}...`}
                        onProgressEnd={closeToast}
                    />
                )
            }
        },
        [closeToast]
    )

    const copyCheckService = useCopyCheckService()
    const copyHasBeenProhibited = copyCheckService.useZustandStore.use.copyHasBeenProhibited()
    const exportAsSketchImpl = useCallback(() => {
        if (copyHasBeenProhibited) {
            WKToast.show(translation('ProhibitExport'))
            return
        }
        exporterService.exportAsSketch({
            showToast,
            updateProgressHandler: (progress, error) => {
                if (!error) {
                    updateToast(progress)
                } else {
                    closeToast()
                    WKToast.error(translation('FailedToExport'))
                }
            },
        })
    }, [copyHasBeenProhibited, exporterService, showToast, updateToast, closeToast])

    const exportAsFigmaImpl = useCallback(() => {
        if (!featureSwitchManager.isEnabled('export-ano')) {
            return
        }

        if (copyHasBeenProhibited) {
            WKToast.show(translation('ProhibitExport'))
            return
        }
        exporterService.exportAsFigma({
            showToast,
            updateProgressHandler: (progress, error) => {
                if (!error) {
                    updateToast(progress)
                } else {
                    closeToast()
                    WKToast.error(translation('FailedToExport'))
                }
            },
        })
    }, [copyHasBeenProhibited, exporterService, showToast, updateToast, closeToast])

    useEffect(() => {
        exporterService.updateDocName(docInfo.docData?.name ?? '')
    }, [docInfo.docData?.name, exporterService])

    return {
        exportAsSketch: exportAsSketchImpl,
        exportAsFigma: exportAsFigmaImpl,
    }
}

export function useEachTopFrameAsPDFPage() {
    const { prepare, done } = useExportPreparation()
    const rawMemAcc = useRawMemAccessService()
    const command = useCommand()
    const copyCheckService = useCopyCheckService()
    const copyHasBeenProhibited = copyCheckService.useZustandStore.use.copyHasBeenProhibited()

    const eachTopFrameAsPDFPage = useCallback(
        async (signal: TraceableAbortSignal) => {
            if (copyHasBeenProhibited) {
                WKToast.show(translation('ProhibitExport'))
                return
            }

            const { value: nodeId } = command.DEPRECATED_invokeBridge(GetCurrentPageIdCommand)
            if (!nodeId) {
                return
            }
            const { value: pageName } = command.DEPRECATED_invokeBridge(PluginApiGetName, { nodeId })
            const { mixins } = command.DEPRECATED_invokeBridge(GetTopFrameWhichSortByArrange)
            if (!mixins || mixins.length === 0) {
                const toastID = WKToast.show(translation('VWjsVG'))
                const timer = setTimeout(() => {
                    clearTimeout(timer)
                    WKToast.close(toastID)
                }, 5000)
                return
            }
            const id = await prepare(signal, mixins)
            const { blobID, dataBase64 } = command.DEPRECATED_invokeBridge(ExportEachAsPDFPage, { nodeIds: mixins })
            const blob = isNil(blobID) ? base64ToBlob(dataBase64) : rawMemAcc.getBlobAndDelete(blobID)
            if (blob) {
                downloadBlob(blob, `${pageName}.pdf`)
            } else {
                WKToast.error(translation('FailedToExport'))
            }
            if (id) {
                await done(id)
            }
        },
        [copyHasBeenProhibited, command, prepare, rawMemAcc, done]
    )

    return eachTopFrameAsPDFPage
}
