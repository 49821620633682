import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    BestMatchesFor: '「{{keyword}}」的匹配结果',
    NoContent: '暂无内容',
    PleaseUnpublishAnd: '请取消发布后重新发布',
    And: '和',
    UsedInThis: '在本文件使用',
    SwapLibrary: '替换库',
    VariableCollection: '变量合集',
    Style: '样式',
    Component: '组件',
} as const

export const enTranslation = {
    BestMatchesFor: "Best matches for '{{keyword}}'",
    NoContent: 'No content',
    PleaseUnpublishAnd: 'Please unpublish and then republish',
    And: ',',
    UsedInThis: ' used in this file',
    SwapLibrary: 'Swap library',
    VariableCollection: 'Variable collections',
    Style: 'Styles',
    Component: 'Components',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
