import { GetDeviceOptionsCommand, UpdatePrototypeDeviceCommand, Wukong } from '@wukong/bridge-proto'
import constate from 'constate'
import { uniqBy } from 'lodash-es'
import { useCallback, useMemo, useState } from 'react'
import { useMount } from 'react-use'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { translation } from './hook.translation'

export const isNotRotatable = (deviceName: string) => {
    // macbook 和 tv 不允许旋转
    const list = ['MacBook', 'TV', translation('None'), translation('Presentation'), 'watch'].map((s) =>
        s.toLowerCase()
    )
    return !!list.find((s) => deviceName.toLowerCase().includes(s))
}

const NoneDevice: Wukong.DocumentProto.IDeviceOption = {
    deviceName: translation('None'),
    framePresetSize: { x: 0, y: 0 },
    presetIdentifier: '',
    styleName: '',
    type: Wukong.DocumentProto.PrototypeDeviceType.PROTOTYPE_DEVICE_TYPE_NONE,
    url: '',
    offset: { x: 0, y: 0 },
    imageSize: { x: 0, y: 0 },
    scaleFactor: 0,
    radius: 0,
    isMobileDevice: false,
    thumbnailUrl: '',
}

const CustomDevice: Wukong.DocumentProto.IDeviceOption = {
    deviceName: translation('CustomSize'),
    framePresetSize: { x: 500, y: 500 },
    presetIdentifier: 'CUSTOM',
    styleName: '',
    type: Wukong.DocumentProto.PrototypeDeviceType.PROTOTYPE_DEVICE_TYPE_CUSTOM,
    url: '',
    offset: { x: 0, y: 0 },
    imageSize: { x: 0, y: 0 },
    scaleFactor: 0,
    radius: 0,
    isMobileDevice: false,
    thumbnailUrl: '',
}

const PresentationDevice: Wukong.DocumentProto.IDeviceOption = {
    deviceName: translation('Presentation'),
    framePresetSize: { x: 0, y: 0 },
    presetIdentifier: 'PRESENTATION',
    styleName: '',
    type: Wukong.DocumentProto.PrototypeDeviceType.PROTOTYPE_DEVICE_TYPE_PRESENTATION,
    url: '',
    offset: { x: 0, y: 0 },
    imageSize: { x: 0, y: 0 },
    scaleFactor: 0,
    radius: 0,
    isMobileDevice: false,
    thumbnailUrl: '',
}

// 生成设备列表的数组，有排序，有分割线
const generateDeviceList = (list: Array<Wukong.DocumentProto.IDeviceOption>) => {
    const res: Array<Wukong.DocumentProto.IDeviceOption & { splitLineBottom: boolean }> = []
    // 第一个放 无
    res.push({ ...NoneDevice, splitLineBottom: true })
    // 第二段放手机
    const phones = list.filter((item) => /iphone|android/i.test(item.deviceName))
    res.push(...phones.map((item, index) => ({ ...item, splitLineBottom: index === phones.length - 1 })))
    // 第三段放 iPad
    const ipads = list.filter((item) => /ipad/i.test(item.deviceName))
    res.push(...ipads.map((item, index) => ({ ...item, splitLineBottom: index === ipads.length - 1 })))
    // 第四段放apple watch
    const watches = list.filter((item) => /watch/i.test(item.deviceName))
    res.push(...watches.map((item, index) => ({ ...item, splitLineBottom: index === watches.length - 1 })))
    // 第五段放 mackbook
    const macbooks = list.filter((item) => /macbook|tv/i.test(item.deviceName))
    res.push(...macbooks.map((item, index) => ({ ...item, splitLineBottom: index === macbooks.length - 1 })))
    // 最后放自定义尺寸和演示模式
    res.push({ ...CustomDevice, splitLineBottom: false })
    res.push({ ...PresentationDevice, splitLineBottom: false })
    return res
}

const isValidFrameSize = (size?: Wukong.DocumentProto.IVector) => {
    if (size?.x && size?.y) {
        return true
    }
    return false
}
const presetDeviceList = [NoneDevice, CustomDevice, PresentationDevice]

export const useHook = () => {
    const state = useViewState('prototypeDevice')
    const config = state?.config
    const command = useCommand()
    const rotation = config?.rotation
    const frameSize = config?.size
    // 设备列表的选项
    const [deviceOptions, setDeviceOptions] = useState<Wukong.DocumentProto.IDeviceOption[]>(presetDeviceList)

    const getDeviceOptions = useCallback(() => {
        return command.DEPRECATED_invokeBridge(GetDeviceOptionsCommand).options
    }, [command])

    useMount(() => {
        setDeviceOptions([...presetDeviceList, ...getDeviceOptions()])
    })

    const _deviceOptionsUniqByDeviceName = useMemo(() => uniqBy(deviceOptions, 'deviceName'), [deviceOptions])

    const deviceOptionsUniqByDeviceName = useMemo(
        () => generateDeviceList(_deviceOptionsUniqByDeviceName),
        [_deviceOptionsUniqByDeviceName]
    )

    const changeDeviceByDeviceName = useCallback(
        (deviceName: string) => {
            const item = deviceOptions.find((o) => o.deviceName == deviceName)
            if (!item) {
                return
            }
            if (item?.type == Wukong.DocumentProto.PrototypeDeviceType.PROTOTYPE_DEVICE_TYPE_CUSTOM) {
                const size = (
                    isValidFrameSize(frameSize) ? frameSize : item.framePresetSize
                ) as Wukong.DocumentProto.IVector
                // 自定义设备的默认尺寸展示，要展示上一个选中的设备
                command.DEPRECATED_invokeBridge(UpdatePrototypeDeviceCommand, {
                    config: {
                        presetIdentifier: item.presetIdentifier,
                        size,
                        type: item.type,
                        rotation:
                            size!.x! < size!.y!
                                ? Wukong.DocumentProto.DeviceRotation.DEVICE_ROTATION_NONE
                                : Wukong.DocumentProto.DeviceRotation.CCW_90,
                    },
                })
            } else {
                command.DEPRECATED_invokeBridge(UpdatePrototypeDeviceCommand, {
                    config: {
                        presetIdentifier: item.presetIdentifier,
                        size: item.framePresetSize,
                        type: item.type,
                        rotation: isNotRotatable(item.deviceName)
                            ? Wukong.DocumentProto.DeviceRotation.DEVICE_ROTATION_NONE
                            : (rotation as Wukong.DocumentProto.DeviceRotation),
                    },
                })
            }
            command.commitUndo()
        },
        [command, deviceOptions, frameSize, rotation]
    )

    const changeDeviceByPresetId = useCallback(
        (id: string) => {
            const item = deviceOptions.find((o) => o.presetIdentifier == id)
            if (item) {
                command.DEPRECATED_invokeBridge(UpdatePrototypeDeviceCommand, {
                    config: {
                        presetIdentifier: item.presetIdentifier,
                        size: item.framePresetSize,
                        type: item.type,
                        rotation: isNotRotatable(item.deviceName)
                            ? Wukong.DocumentProto.DeviceRotation.DEVICE_ROTATION_NONE
                            : (rotation as Wukong.DocumentProto.DeviceRotation),
                    },
                })
                command.commitUndo()
            }
        },
        [command, deviceOptions, rotation]
    )

    const selectedItem = useMemo(
        () => deviceOptions.find((o) => o.presetIdentifier == config?.presetIdentifier) ?? deviceOptions[0],
        [config?.presetIdentifier, deviceOptions]
    )

    const changeDeviceProp = useCallback(
        (param: Partial<Wukong.DocumentProto.IPrototypeDevice>) => {
            if (!config) return
            command.DEPRECATED_invokeBridge(UpdatePrototypeDeviceCommand, {
                config: {
                    ...config,
                    ...param,
                },
            })
            command.commitUndo()
        },
        [command, config]
    )

    return {
        changeDeviceByDeviceName,
        deviceOptions,
        deviceOptionsUniqByDeviceName,
        changeDeviceByPresetId,
        rotation,
        selectedItem,
        frameSize,
        changeDeviceProp,
    }
}

export const [PrototypeDeviceProvider, usePrototypeDevice] = constate(useHook)

export const getBackwardIcon = (deviceInfo: Wukong.DocumentProto.IDeviceOption) => {
    switch (deviceInfo.type!) {
        case Wukong.DocumentProto.PrototypeDeviceType.PROTOTYPE_DEVICE_TYPE_NONE:
            return ''
        case Wukong.DocumentProto.PrototypeDeviceType.PROTOTYPE_DEVICE_TYPE_PRESET:
            return `${deviceInfo.framePresetSize.x}×${deviceInfo.framePresetSize.y}`
        case Wukong.DocumentProto.PrototypeDeviceType.PROTOTYPE_DEVICE_TYPE_CUSTOM:
            return translation('Fit')
        case Wukong.DocumentProto.PrototypeDeviceType.PROTOTYPE_DEVICE_TYPE_PRESENTATION:
            return translation('Fill')
    }
}
