import { useAIGenUIInContext } from './use-ai-gen-ui'
import { generateColorConfig, ThemeProps } from './util'

export function IOSUISampleLeft({
    primaryColor = '#1677FF',
    onPrimaryColor = 'white',
    backgroundColor = '#F2F2F7',
    surfaceColor = 'white',
    textPrimaryColor = 'black',
    textSecondaryColor = '#8E8E93',
    dividerColor = '#f3f3f3',
}: ThemeProps = {}) {
    const theme = {
        primary: primaryColor,
        onPrimary: onPrimaryColor,
        background: backgroundColor,
        surface: surfaceColor,
        textPrimary: textPrimaryColor,
        textSecondary: textSecondaryColor,
        divider: dividerColor,
    }

    return (
        <div>
            <div
                className="w-[390px] min-h-[844px] flex flex-col"
                style={{ backgroundColor: theme.background, height: '900px' }}
            >
                <div className="h-11 px-4 flex items-center justify-between" style={{ backgroundColor: theme.surface }}>
                    <span className="text-sm font-medium" style={{ color: theme.textPrimary }}>
                        9:41
                    </span>
                    <div className="flex items-center gap-1" style={{ color: theme.textPrimary }}>
                        <svg
                            className="w-4 h-4"
                            fill="currentColor"
                            height="1em"
                            stroke="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M472 432h-48a24 24 0 01-24-24V104a24 24 0 0124-24h48a24 24 0 0124 24v304a24 24 0 01-24 24zm-128 0h-48a24 24 0 01-24-24V184a24 24 0 0124-24h48a24 24 0 0124 24v224a24 24 0 01-24 24zm-128 0h-48a24 24 0 01-24-24V248a24 24 0 0124-24h48a24 24 0 0124 24v160a24 24 0 01-24 24zm-128 0H40a24 24 0 01-24-24v-96a24 24 0 0124-24h48a24 24 0 0124 24v96a24 24 0 01-24 24z"></path>
                        </svg>
                        <svg
                            className="w-4 h-4"
                            fill="currentColor"
                            height="1em"
                            stroke="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M332.69 320a115 115 0 00-152.8 0m213.85-61a201.26 201.26 0 00-274.92 0M448 191.52a288 288 0 00-383.44 0"
                                fill="none"
                                strokeLinecap="square"
                                strokeLinejoin="round"
                                strokeWidth="42"
                            ></path>
                            <path d="M300.67 384L256 433l-44.34-49a56.73 56.73 0 0188.92 0z"></path>
                        </svg>
                        <svg
                            className="w-4 h-4"
                            fill="currentColor"
                            height="1em"
                            stroke="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                fill="none"
                                height="224"
                                rx="45.7"
                                ry="45.7"
                                strokeLinecap="square"
                                strokeMiterlimit="10"
                                strokeWidth="32"
                                width="400"
                                x="32"
                                y="144"
                            ></rect>
                            <rect
                                height="114.14"
                                rx="4"
                                ry="4"
                                strokeLinecap="square"
                                strokeMiterlimit="10"
                                strokeWidth="32"
                                width="292.63"
                                x="85.69"
                                y="198.93"
                            ></rect>
                            <path
                                d="M480 218.67v74.66"
                                fill="none"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                strokeWidth="32"
                            ></path>
                        </svg>
                    </div>
                </div>
                <div className="flex-1 px-4 overflow-hidden" style={{ height: '805px' }}>
                    <div className="py-6">
                        <h1 className="text-[28px] font-bold mb-1" style={{ color: theme.textPrimary }}>
                            Good Morning, Alex
                        </h1>
                        <div className="flex items-center gap-1" style={{ color: theme.primary }}>
                            <svg
                                className="w-4 h-4"
                                fill="currentColor"
                                height="1em"
                                stroke="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 512 512"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M234 26h44v92h-44zm0 368h44v92h-44zm104.025-251.143l65.054-65.054 31.113 31.113-65.054 65.054zM77.815 403.074l65.054-65.054 31.113 31.113-65.054 65.054zM394 234h92v44h-92zm-368 0h92v44H26zm312.029 135.14l31.112-31.113 65.054 65.054-31.112 31.112zM77.802 108.92l31.113-31.113 65.054 65.054-31.113 31.112zM256 358a102 102 0 11102-102 102.12 102.12 0 01-102 102z"></path>
                            </svg>
                            <span className="text-sm font-regular">Sunny, 68°F</span>
                        </div>
                    </div>
                    <div
                        className="rounded-[10px] p-4 flex items-center justify-between mb-6"
                        style={{ backgroundColor: theme.surface }}
                    >
                        <div className="flex-1">
                            <h2 className="text-xl font-semibold mb-1" style={{ color: theme.textPrimary }}>
                                Your Morning Mix
                            </h2>
                            <p className="text-sm font-regular mb-4" style={{ color: theme.textSecondary }}>
                                Energetic tunes to start your day
                            </p>
                            <button
                                className="px-6 py-2 rounded-full flex items-center gap-2 border-0"
                                style={{ backgroundColor: theme.primary, color: theme.onPrimary }}
                            >
                                <svg
                                    className="w-4 h-4"
                                    fill="currentColor"
                                    height="1em"
                                    stroke="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 512 512"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M96 448l320-192L96 64v384z"></path>
                                </svg>
                                <span className="font-medium">Play Now</span>
                            </button>
                        </div>
                        <img
                            alt="Concert"
                            className="w-24 h-24 rounded-[10px] object-cover"
                            src="https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?w=500&amp;h=500&amp;dpr=3"
                        />
                    </div>
                    <div className="mb-6">
                        <h2 className="text-xl font-semibold mb-4">Recently Played</h2>
                        <div className="flex gap-4 overflow-hidden pb-4">
                            <div className="min-w-[150px]">
                                <img
                                    alt="Morning Jazz"
                                    className="w-[150px] h-[150px] rounded-[10px] object-cover mb-2"
                                    src="https://images.unsplash.com/photo-1511735111819-9a3f7709049c?w=400&amp;h=400&amp;dpr=3"
                                />
                                <h3 className="font-regular truncate" style={{ color: theme.textPrimary }}>
                                    Morning Jazz
                                </h3>
                                <p className="text-sm font-regular truncate" style={{ color: theme.textSecondary }}>
                                    Various Artists
                                </p>
                            </div>
                            <div className="min-w-[150px]">
                                <img
                                    alt="Sunrise Vibes"
                                    className="w-[150px] h-[150px] rounded-[10px] object-cover mb-2"
                                    src="https://images.unsplash.com/photo-1514525253161-7a46d19cd819?w=400&amp;h=400&amp;dpr=3"
                                />
                                <h3 className="font-regular truncate" style={{ color: theme.textPrimary }}>
                                    Sunrise Vibes
                                </h3>
                                <p className="text-sm font-regular truncate" style={{ color: theme.textSecondary }}>
                                    Chill Mood
                                </p>
                            </div>
                            <div className="min-w-[150px]">
                                <img
                                    alt="Wake Up Mix"
                                    className="w-[150px] h-[150px] rounded-[10px] object-cover mb-2"
                                    src="https://images.unsplash.com/photo-1524368535928-5b5e00ddc76b?w=400&amp;h=400&amp;dpr=3"
                                />
                                <h3 className="font-regular truncate" style={{ color: theme.textPrimary }}>
                                    Wake Up Mix
                                </h3>
                                <p className="text-sm font-regular truncate" style={{ color: theme.textSecondary }}>
                                    Energy Mix
                                </p>
                            </div>
                            <div className="min-w-[150px]">
                                <img
                                    alt="Morning Acoustic"
                                    className="w-[150px] h-[150px] rounded-[10px] object-cover mb-2"
                                    src="https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?w=400&amp;h=400&amp;dpr=3"
                                />
                                <h3 className="font-regular truncate" style={{ color: theme.textPrimary }}>
                                    Morning Acoustic
                                </h3>
                                <p className="text-sm font-regular truncate" style={{ color: theme.textSecondary }}>
                                    Acoustic Vibes
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h2 className="text-xl font-semibold mb-4" style={{ color: theme.textPrimary }}>
                            Recommended for Morning
                        </h2>
                        <div className="rounded-[10px]" style={{ backgroundColor: theme.surface }}>
                            <div
                                className="flex items-center p-4 border-b last:border-b-0"
                                style={{ borderColor: theme.divider }}
                            >
                                <img
                                    alt="Morning Energy Boost"
                                    className="w-12 h-12 rounded-[10px] object-cover mr-4"
                                    src="https://images.unsplash.com/photo-1534258936925-c58bed479fcb?w=300&amp;h=300&amp;dpr=3"
                                />
                                <div className="flex-1">
                                    <h3 className="font-regular" style={{ color: theme.textPrimary }}>
                                        Morning Energy Boost
                                    </h3>
                                    <p className="text-sm font-regular" style={{ color: theme.textSecondary }}>
                                        Workout Mix
                                    </p>
                                </div>
                                <div className="flex items-center gap-4">
                                    <span className="text-sm font-regular" style={{ color: theme.textSecondary }}>
                                        3:45
                                    </span>
                                    <svg
                                        className="w-6 h-6"
                                        style={{ color: theme.primary }}
                                        fill="currentColor"
                                        height="1em"
                                        stroke="currentColor"
                                        strokeWidth="0"
                                        viewBox="0 0 512 512"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M96 448l320-192L96 64v384z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div
                                className="flex items-center p-4 border-b last:border-b-0"
                                style={{ borderColor: theme.divider }}
                            >
                                <img
                                    alt="Peaceful Awakening"
                                    className="w-12 h-12 rounded-[10px] object-cover mr-4"
                                    src="https://images.unsplash.com/photo-1483412033650-1015ddeb83d1?w=300&amp;h=300&amp;dpr=3"
                                />
                                <div className="flex-1">
                                    <h3 className="font-regular" style={{ color: theme.textPrimary }}>
                                        Peaceful Awakening
                                    </h3>
                                    <p className="text-sm font-regular" style={{ color: theme.textSecondary }}>
                                        Meditation Sounds
                                    </p>
                                </div>
                                <div className="flex items-center gap-4">
                                    <span className="text-sm font-regular" style={{ color: theme.textSecondary }}>
                                        4:20
                                    </span>
                                    <svg
                                        className="w-6 h-6"
                                        style={{ color: theme.primary }}
                                        fill="currentColor"
                                        height="1em"
                                        stroke="currentColor"
                                        strokeWidth="0"
                                        viewBox="0 0 512 512"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M96 448l320-192L96 64v384z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div
                                className="flex items-center p-4 border-b last:border-b-0"
                                style={{ borderColor: theme.divider }}
                            >
                                <img
                                    alt="Sunrise Acoustic"
                                    className="w-12 h-12 rounded-[10px] object-cover mr-4"
                                    src="https://images.unsplash.com/photo-1510915361894-db8b60106cb1?w=300&amp;h=300&amp;dpr=3"
                                />
                                <div className="flex-1">
                                    <h3 className="font-regular" style={{ color: theme.textPrimary }}>
                                        Sunrise Acoustic
                                    </h3>
                                    <p className="text-sm font-regular" style={{ color: theme.textSecondary }}>
                                        Morning Playlist
                                    </p>
                                </div>
                                <div className="flex items-center gap-4">
                                    <span className="text-sm font-regular" style={{ color: theme.textSecondary }}>
                                        3:55
                                    </span>
                                    <svg
                                        className="w-6 h-6"
                                        style={{ color: theme.primary }}
                                        fill="currentColor"
                                        height="1em"
                                        stroke="currentColor"
                                        strokeWidth="0"
                                        viewBox="0 0 512 512"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M96 448l320-192L96 64v384z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-24"></div>
                </div>
                <div className="border-t py-2" style={{ backgroundColor: theme.surface, borderColor: theme.divider }}>
                    <div className="flex justify-around">
                        <button
                            className="flex flex-col items-center border-0"
                            style={{ backgroundColor: theme.surface }}
                        >
                            <svg
                                className="w-6 h-6"
                                style={{ color: theme.primary }}
                                fill="currentColor"
                                height="1em"
                                stroke="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 512 512"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M416 174.74V48h-80v58.45L256 32 0 272h64v208h144V320h96v160h144V272h64l-96-97.26z"></path>
                            </svg>
                            <span className="text-xs mt-1" style={{ color: theme.primary }}>
                                Home
                            </span>
                        </button>
                        <button
                            className="flex flex-col items-center border-0"
                            style={{ backgroundColor: theme.surface }}
                        >
                            <svg
                                className="w-6 h-6"
                                style={{ color: theme.textSecondary }}
                                fill="currentColor"
                                height="1em"
                                stroke="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 512 512"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M464 428L339.92 303.9a160.48 160.48 0 0030.72-94.58C370.64 120.37 298.27 48 209.32 48S48 120.37 48 209.32s72.37 161.32 161.32 161.32a160.48 160.48 0 0094.58-30.72L428 464zM209.32 319.69a110.38 110.38 0 11110.37-110.37 110.5 110.5 0 01-110.37 110.37z"></path>
                            </svg>
                            <span className="text-xs mt-1" style={{ color: theme.textSecondary }}>
                                Search
                            </span>
                        </button>
                        <button
                            className="flex flex-col items-center border-0"
                            style={{ backgroundColor: theme.surface }}
                        >
                            <svg
                                className="w-6 h-6"
                                style={{ color: theme.textSecondary }}
                                fill="currentColor"
                                height="1em"
                                stroke="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 512 512"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M84 480H28a12 12 0 01-12-12V92a12 12 0 0112-12h56a12 12 0 0112 12v376a12 12 0 01-12 12zm156-272v-52a12 12 0 00-12-12H124a12 12 0 00-12 12v52zM112 416v52a12 12 0 0012 12h104a12 12 0 0012-12v-52zm0-176h128v144H112zm228 240h-72a12 12 0 01-12-12V44a12 12 0 0112-12h72a12 12 0 0112 12v424a12 12 0 01-12 12zm29-379.3l30 367.83a12 12 0 0013.45 10.92l72.16-9a12 12 0 0010.47-12.9L465 91.21a12 12 0 00-13.2-10.94l-72.13 7.51A12 12 0 00369 100.7z"></path>
                            </svg>
                            <span className="text-xs mt-1" style={{ color: theme.textSecondary }}>
                                Library
                            </span>
                        </button>
                        <button
                            className="flex flex-col items-center border-0"
                            style={{ backgroundColor: theme.surface }}
                        >
                            <svg
                                className="w-6 h-6"
                                style={{ color: theme.textSecondary }}
                                fill="currentColor"
                                height="1em"
                                stroke="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 512 512"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M256 256a112 112 0 10-112-112 112 112 0 00112 112zm0 32c-69.42 0-208 42.88-208 128v64h416v-64c0-85.12-138.58-128-208-128z"></path>
                            </svg>
                            <span className="text-xs mt-1" style={{ color: theme.textSecondary }}>
                                Profile
                            </span>
                        </button>
                        <button
                            className="flex flex-col items-center border-0"
                            style={{ backgroundColor: theme.surface }}
                        >
                            <svg
                                className="w-6 h-6"
                                style={{ color: theme.textSecondary }}
                                fill="currentColor"
                                height="1em"
                                stroke="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 512 512"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M256 176a80 80 0 1080 80 80.24 80.24 0 00-80-80zm172.72 80a165.53 165.53 0 01-1.64 22.34l48.69 38.12a11.59 11.59 0 012.63 14.78l-46.06 79.52a11.64 11.64 0 01-14.14 4.93l-57.25-23a176.56 176.56 0 01-38.82 22.67l-8.56 60.78a11.93 11.93 0 01-11.51 9.86h-92.12a12 12 0 01-11.51-9.53l-8.56-60.78A169.3 169.3 0 01151.05 393L93.8 416a11.64 11.64 0 01-14.14-4.92L33.6 331.57a11.59 11.59 0 012.63-14.78l48.69-38.12A174.58 174.58 0 0183.28 256a165.53 165.53 0 011.64-22.34l-48.69-38.12a11.59 11.59 0 01-2.63-14.78l46.06-79.52a11.64 11.64 0 0114.14-4.93l57.25 23a176.56 176.56 0 0138.82-22.67l8.56-60.78A11.93 11.93 0 01209.94 26h92.12a12 12 0 0111.51 9.53l8.56 60.78A169.3 169.3 0 01361 119l57.2-23a11.64 11.64 0 0114.14 4.92l46.06 79.52a11.59 11.59 0 01-2.63 14.78l-48.69 38.12a174.58 174.58 0 011.64 22.66z"></path>
                            </svg>
                            <span className="text-xs mt-1" style={{ color: theme.textSecondary }}>
                                Settings
                            </span>
                        </button>
                    </div>
                </div>
                <div
                    className="h-[34px] w-full flex items-center justify-center"
                    style={{ backgroundColor: theme.surface }}
                >
                    <div
                        className="w-[134px] h-[5px] rounded-full"
                        style={{ backgroundColor: theme.textPrimary }}
                    ></div>
                </div>
            </div>
        </div>
    )
}

export function IOSUISample() {
    const { tempRemixConfig } = useAIGenUIInContext()

    const primaryColor = tempRemixConfig?.get('primaryColor') ?? '#1677FF'

    const mode = tempRemixConfig?.get('mode') as 'light' | 'dark' | undefined
    const themeConfig = generateColorConfig(primaryColor, mode)

    return (
        <div
            className="flex justify-center items-center"
            style={{ width: '150px', height: '348px', overflow: 'hidden' }}
        >
            <div className="w-full h-full flex justify-center items-center">
                <div style={{ transform: 'scale(0.38)' }}>
                    <IOSUISampleLeft {...themeConfig} />
                </div>
            </div>
        </div>
    )
}
