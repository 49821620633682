import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    EditVersionInfo: '编辑版本信息',
    RestoreThisVersion: '恢复到此版本',
    Duplicate: '创建副本',
    DeleteVersionInfo: '清除版本信息',
    CopyLink: '复制版本链接',
    ProhibitCopy: '当前文件被设置为禁止查看者复制',
} as const

export const enTranslation = {
    EditVersionInfo: 'Edit version info',
    RestoreThisVersion: 'Restore this version',
    Duplicate: 'Duplicate',
    DeleteVersionInfo: 'Delete version info',
    CopyLink: 'Copy link',
    ProhibitCopy: 'This file settings disable viewers from copying.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
