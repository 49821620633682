import { translation } from './candidate-component-replace-dialog.translation'
/* eslint-disable no-restricted-imports */
import {
    AICandidateComponentReplaceCommand,
    BuildAISelectedStyleNodeDataCommand,
    FindClosestComponentByVariantCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
    IconClose16,
    MonoIconLayerComponent16,
    MonoIconPanelEffect16,
    MonoIconPanelStyleColor16,
    MonoIconPanelStyleFont16,
    Select,
    WKButton,
    WKDialog,
    WKIconButton,
} from '../../../../../../ui-lib/src'
import { cmdChangePopupState } from '../../../../document/command/document-command'
import { cmdChangeAttrPanelStyleEditorState } from '../../../../document/command/node-props-command'
import { Effect, ITextStyle, Paint, PopupStateType } from '../../../../document/node/node'
import { WKFrog } from '../../../../kernel/frog'
import { LibraryThumbnailImage } from '../../../../share/component-style-library/library-thumbnail-image'
import { ProtoType, useViewState } from '../../../../view-state-bridge'
import { CandidateComponentReplaceDialogTestIds } from '../../../../window'
import { useCommand } from '../../../context/document-context'
import { SingleGrid } from '../../atom/grid/single-grid'
import { CandidateComponentReplacePanel } from '../../design-panel-v2/component-instance-panel/components/candidate-component-replace-panel'
import { CandidateVariantProperty } from '../../design-panel-v2/component-instance-panel/components/candidate-variant-panel'
import { EffectStyleIcon, getEffectStyleIcon } from '../../design/effects/effect-style/effect-style-icon'
import { PaintStyleThumbnail } from '../../design/styles/style-color/style-thumbnail'
import { CommonThumbnail } from '../../design/styles/style-panel/common-thumbnail/common-thumbnail'
import { EffectStyleData } from '../../design/styles/style-panel/effect-style-panel/effect-style-panel'
import { PaintStyleData } from '../../design/styles/style-panel/paint-style-panel/paint-style-panel'
import { TextStyleThumbnail } from '../../design/styles/style-text/style-thumbnail'
import style from './candidate-component-replace-dialog.module.less'
import { CandidateStyleColor } from './candidate-style-color'
import { CandidateStyleEffect } from './candidate-style-effect'
import { CandidateStyleFont } from './candidate-style-font'

export function CandidateComponentReplaceDialog({
    isComponent,
    candidateType,
    onClose,
}: {
    isComponent: boolean
    candidateType: Wukong.DocumentProto.AICandidateComponentType
    onClose: () => void
}) {
    const state = useViewState('componentPanelState')
    const command = useCommand()
    const [selectedNode, setSelectedNode] = useState<Wukong.DocumentProto.IReplaceInstanceNodeData | null>(null)
    const [selectedNodeMainComponent, setSelectedNodeMainComponent] = useState('')
    const [showReplacePanel, setShowReplacePanel] = useState(false)
    const [replacePosition, setReplacePosition] = useState<{ left: number; top: number }>()
    const selectRef = useRef<HTMLDivElement>(null)
    const errorRef = useRef<HTMLDivElement>(null)
    const popupState = useViewState('popupState')

    const [paintStyle, setPaintStyle] = useState<PaintStyleData>()
    const [textStyle, setTextStyle] = useState<ITextStyle>()
    const [effectStyle, setEffectStyle] = useState<EffectStyleData>()
    const [componentThumbnailData, setComponentThumbnailData] = useState<Wukong.DocumentProto.IThumbnailData>()

    const [variantState, setVariantState] = useState<ProtoType<Wukong.DocumentProto.IVariantState>>()

    const [isError, setIsError] = useState(false)

    const candidateComponentReplaceState = useViewState('candidateComponentReplaceState')
    const localStyleNodeState = useViewState('localStyleNodeState')

    const [errorTextWidth, setErrorTextWidth] = useState(0)
    const [dialogWidth, setDialogWidth] = useState(0)

    useEffect(() => {
        if (!localStyleNodeState || !selectedNode?.nodeId) {
            return
        }
        if (paintStyle) {
            const matchItems = localStyleNodeState.paintStyleItems.filter((item) => {
                return item.styleId === selectedNode.nodeId
            })
            setIsError(matchItems.length === 0)
            if (matchItems.length === 1) {
                const item = matchItems[0]
                if (JSON.stringify(item.styleNode) !== JSON.stringify(paintStyle)) {
                    setPaintStyle(item.styleNode)
                }
                if (selectedNode.name !== item.styleNode.name) {
                    setSelectedNode({ ...selectedNode, name: item.styleNode.name })
                }
            }
        }
        if (textStyle) {
            const matchItems = localStyleNodeState.textStyleItems.filter((item) => {
                return item.styleId === selectedNode.nodeId
            })
            setIsError(matchItems.length === 0)
            if (matchItems.length === 1) {
                const item = matchItems[0]
                const newStyle = item.styleNode as unknown as ITextStyle
                if (JSON.stringify(newStyle) !== JSON.stringify(textStyle)) {
                    setTextStyle(newStyle)
                }
                if (selectedNode.name !== item.styleNode.name) {
                    setSelectedNode({ ...selectedNode, name: item.styleNode.name })
                }
            }
        }
        if (effectStyle) {
            const matchItems = localStyleNodeState.effectStyleItems.filter((item) => {
                return item.styleId === selectedNode.nodeId
            })
            setIsError(matchItems.length === 0)
            if (matchItems.length === 1) {
                const item = matchItems[0]
                const newStyle = item.styleNode as unknown as EffectStyleData
                if (JSON.stringify(newStyle) !== JSON.stringify(effectStyle)) {
                    setEffectStyle(newStyle)
                }
                if (selectedNode.name !== item.styleNode.name) {
                    setSelectedNode({ ...selectedNode, name: item.styleNode.name })
                }
            }
        }
    }, [
        selectedNode,
        localStyleNodeState,
        paintStyle,
        textStyle,
        effectStyle,
        setPaintStyle,
        setTextStyle,
        setEffectStyle,
    ])

    useEffect(() => {
        setErrorTextWidth(selectRef.current?.offsetWidth ?? 0)
    }, [])

    useEffect(() => {
        if (!selectedNode?.nodeId) {
            return
        }
        if (!isComponent) {
            return
        }
        const localLibrary = candidateComponentReplaceState?.allLibraryList.at(0)
        const allComponentList = candidateComponentReplaceState?.allComponentList

        if (!localLibrary || !allComponentList || allComponentList.length === 0) {
            return
        }

        const matchItems = allComponentList.filter((item) => {
            return item.nodeData.nodeId === selectedNode.nodeId
        })

        if (matchItems.length === 0) {
            if (selectedNodeMainComponent) {
                const matchMainComponent = allComponentList.filter((item) => {
                    return item.nodeData.nodeId === selectedNodeMainComponent
                })
                if (matchMainComponent.length !== 0) {
                    setSelectedNode(matchMainComponent[0].nodeData)
                } else {
                    setSelectedNodeMainComponent('')
                    setIsError(true)
                }
            } else {
                setIsError(true)
            }
        } else if (matchItems.length === 1) {
            const item = matchItems[0]
            if (JSON.stringify(item.nodeData) !== JSON.stringify(selectedNode)) {
                setSelectedNode(item.nodeData)
            }
            if (item.componentSetMainComponent !== selectedNodeMainComponent) {
                setSelectedNodeMainComponent(item.componentSetMainComponent)
            }
        }
    }, [
        selectedNode,
        candidateComponentReplaceState,
        setIsError,
        isComponent,
        selectedNodeMainComponent,
        setSelectedNodeMainComponent,
    ])

    useEffect(() => {
        if (!isError) {
            return
        }
        setSelectedNode(null)
        setPaintStyle(undefined)
        setTextStyle(undefined)
        setEffectStyle(undefined)
        setComponentThumbnailData(undefined)
    }, [isError])

    const handleReplacePanelSelect = (node: Wukong.DocumentProto.IReplaceInstanceNodeData) => {
        setSelectedNode(node)
    }

    const handleReplacePanelClose = useCallback(() => {
        setShowReplacePanel(false)
        command.invoke(cmdChangeAttrPanelStyleEditorState, null)
    }, [setShowReplacePanel, command])

    useEffect(() => {
        if (popupState && popupState.type !== PopupStateType.POPUP_STATE_TYPE_CANDIDATE_COMPONENT_REPLACE) {
            handleReplacePanelClose()
        }
    }, [popupState, handleReplacePanelClose])

    const handleSelectNormalClick = useCallback(() => {
        if (!selectRef.current) {
            return
        }
        if (showReplacePanel) {
            handleReplacePanelClose()
            return
        }
        const width = selectRef.current!.getBoundingClientRect().width
        setDialogWidth(width)
        const refPosition = selectRef.current!.getBoundingClientRect()
        const errorOffsetY = errorRef.current?.offsetHeight ?? 0
        const position = { left: refPosition.left, top: refPosition.top + 36 + errorOffsetY / 2 }
        setIsError(false)

        setReplacePosition(position)
        command.invoke(cmdChangePopupState, {
            type: PopupStateType.POPUP_STATE_TYPE_CANDIDATE_COMPONENT_REPLACE,
            reciprocalIndex: -1,
            multiPopup: [],
        })
        setShowReplacePanel(true)

        if (!isComponent) {
            let module: Wukong.DocumentProto.EditorStateFromModule
            switch (candidateType) {
                case Wukong.DocumentProto.AICandidateComponentType.A_I_CANDIDATE_COMPONENT_TYPE_COLOR:
                    module = Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_FILL
                    break
                case Wukong.DocumentProto.AICandidateComponentType.A_I_CANDIDATE_COMPONENT_TYPE_FONT:
                    module = Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_TEXT
                    break
                case Wukong.DocumentProto.AICandidateComponentType.A_I_CANDIDATE_COMPONENT_TYPE_EFFECT:
                    module = Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_EFFECT
                    break
                default:
                    module = Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_NONE
            }
            command.invoke(cmdChangeAttrPanelStyleEditorState, {
                openFromModule: module,
                openFromNodeId: [],
                selectReverseIndex: [],
                isCreate: false,
            })
        }
    }, [showReplacePanel, command, isComponent, handleReplacePanelClose, candidateType])

    const getPlaceholderShowText = useCallback(() => {
        if (isComponent) {
            return translation('ChooseComponent')
        }
        switch (candidateType) {
            case Wukong.DocumentProto.AICandidateComponentType.A_I_CANDIDATE_COMPONENT_TYPE_COLOR:
                return translation('ChooseColorStyle')
            case Wukong.DocumentProto.AICandidateComponentType.A_I_CANDIDATE_COMPONENT_TYPE_FONT:
                return translation('ChooseFontStyle')
            case Wukong.DocumentProto.AICandidateComponentType.A_I_CANDIDATE_COMPONENT_TYPE_EFFECT:
                return translation('ChooseEffectStyle')
            default:
                return ''
        }
    }, [candidateType, isComponent])

    const handleReplaceBtnClick = () => {
        if (!selectedNode) {
            return
        }
        WKFrog.addFrogRecord({
            url: '/click/AIReplacesComponent/ClickStartReplace',
            eventId: 26846,
            eventAction: 'click',
            eventName: 'ClickStartReplace',
        })
        command.DEPRECATED_invokeBridge(AICandidateComponentReplaceCommand, {
            targetId: selectedNode.nodeId,
        })
        onClose()
    }

    const onChangeStyle = useCallback(
        (styleId: string, needClosePopup: boolean, name?: string) => {
            setSelectedNode({
                nodeId: styleId,
                name: name ?? '',
                height: 0,
                layoutSizeType: 0,
                key: '',
                thumbnailData: {},
                description: '',
                isCompSet: false,
                contentHash: '',
                isSelected: false,
                nodeDataPath: '',
                width: 0,
                isRemote: false,
            })
            if (needClosePopup) {
                setShowReplacePanel(false)
            }
        },
        [setSelectedNode, setShowReplacePanel]
    )

    useEffect(() => {
        if (selectedNode?.nodeId) {
            const ret = command.DEPRECATED_invokeBridge(BuildAISelectedStyleNodeDataCommand, {
                nodeId: selectedNode.nodeId,
                isCompSet: selectedNode.isCompSet,
            })
            if (ret.selectedPaintStyle?.node) {
                const _paintStyleNodeInfo: PaintStyleData = {
                    id: selectedNode.nodeId,
                    name: selectedNode.name,
                    description: '',
                    paints: [],
                }
                _paintStyleNodeInfo.paints = ret.selectedPaintStyle.paints as Paint[]
                setPaintStyle(_paintStyleNodeInfo)
            } else {
                setPaintStyle(undefined)
            }

            if (ret.selectedTextStyle?.id) {
                setTextStyle(ret.selectedTextStyle as unknown as ITextStyle)
            } else {
                setTextStyle(undefined)
            }
            if (ret.selectedEffectStyle?.id) {
                const _effectStyleNodeInfo: EffectStyleData = {
                    id: selectedNode.nodeId,
                    name: selectedNode.name,
                    description: '',
                    effects: [],
                }
                _effectStyleNodeInfo.effects = ret.selectedEffectStyle.effects as unknown as Effect[]
                setEffectStyle(_effectStyleNodeInfo)
            } else {
                setEffectStyle(undefined)
            }
            if (ret.selectedComponentThumbnailData.id) {
                setComponentThumbnailData(ret.selectedComponentThumbnailData)
            } else {
                setComponentThumbnailData(undefined)
            }
            if (ret.variantState.shouldDisplay) {
                setVariantState(ret.variantState as ProtoType<Wukong.DocumentProto.IVariantState>)
            } else {
                setVariantState(undefined)
            }
        } else {
            setVariantState(undefined)
        }
    }, [command, selectedNode, setPaintStyle, setTextStyle, setEffectStyle, setComponentThumbnailData, setVariantState])

    const getSelectIcon = () => {
        if (paintStyle) {
            return <PaintStyleThumbnail paints={paintStyle.paints} width={16} height={16} multiple={2} />
        }
        if (textStyle) {
            return (
                <TextStyleThumbnail
                    styleData={textStyle}
                    width={16}
                    height={16}
                    multiple={2}
                    className={style.paint_preview_text}
                ></TextStyleThumbnail>
            )
        }
        if (effectStyle) {
            return (
                <EffectStyleIcon
                    iconType={getEffectStyleIcon(
                        effectStyle.effects as unknown as ReadonlyArray<ProtoType<Wukong.DocumentProto.IEffect>>
                    )}
                    className={style.forceSvgGray13}
                />
            )
        }

        if (componentThumbnailData) {
            return <MonoIconLayerComponent16 className={style.forceSvgGray13} />
        }

        if (isComponent) {
            return <MonoIconLayerComponent16 className="color-$wk-gray-6" />
        }

        switch (candidateType) {
            case Wukong.DocumentProto.AICandidateComponentType.A_I_CANDIDATE_COMPONENT_TYPE_COLOR:
                return <MonoIconPanelStyleColor16 className="color-$wk-gray-6" />
            case Wukong.DocumentProto.AICandidateComponentType.A_I_CANDIDATE_COMPONENT_TYPE_FONT:
                return <MonoIconPanelStyleFont16 className="color-$wk-gray-6" />
            case Wukong.DocumentProto.AICandidateComponentType.A_I_CANDIDATE_COMPONENT_TYPE_EFFECT:
                return <MonoIconPanelEffect16 className="color-$wk-gray-6" />
            default:
                return <></>
        }
    }

    const handleVariantChange = useCallback(
        (variantProps: Wukong.DocumentProto.IVariantProp[]) => {
            if (!selectedNode?.nodeId) {
                return
            }

            const ret = command.DEPRECATED_invokeBridge(FindClosestComponentByVariantCommand, {
                nodeId: selectedNode.nodeId,
                variantProps,
            })

            if (ret.nodeData.nodeId) {
                setSelectedNode(ret.nodeData)
            }
        },
        [selectedNode, command, setSelectedNode]
    )

    return (
        <>
            <WKDialog
                visible={true}
                onCancel={onClose}
                bodyStyle={{ padding: 0 }}
                width={320}
                footer={null}
                hideFooterLine={true}
                centered={true}
                titleRender={
                    <div className={`justify-between  flex items-center ${style.dialogTitle}`}>
                        <div className={`wk-text-12 wk-font-medium `}>
                            {isComponent ? translation('ReplaceComponent') : translation('ReplaceStyle')}
                        </div>
                        <WKIconButton className="shrink-0" onClick={onClose} icon={<IconClose16 />}></WKIconButton>
                    </div>
                }
            >
                <div className={style.popupBody}>
                    <div className={style.selectNum}>
                        {translation('Selected')}
                        {state?.candidateComponentState.number}
                        {isComponent
                            ? translation('CandidateComponent', {
                                  component:
                                      (state?.candidateComponentState.number ?? 0) > 1 ? 'components' : 'component',
                              })
                            : translation('CandidateStyle', {
                                  style: (state?.candidateComponentState.number ?? 0) > 1 ? 'styles' : 'style',
                              })}
                    </div>
                    <div className={style.selectInfoWrapper}>
                        <div className={style.selectInfoTip}>{translation('ReplaceTo')}</div>
                        <SingleGrid.Item
                            start={1}
                            span={39}
                            id="instance-no-fold"
                            className={style.candidateSelect}
                            ref={selectRef}
                        >
                            <Select.NormalSingleLevel
                                icon={getSelectIcon()}
                                label={selectedNode?.name || undefined}
                                onClick={handleSelectNormalClick}
                                dataTestIds={{ triggerContainer: CandidateComponentReplaceDialogTestIds.selectBtn }}
                                placeholder={getPlaceholderShowText()}
                                error={{
                                    show: isError,
                                }}
                            ></Select.NormalSingleLevel>
                            {isComponent && showReplacePanel && (
                                <CandidateComponentReplacePanel
                                    onClose={handleReplacePanelClose}
                                    onSelect={handleReplacePanelSelect}
                                    position={replacePosition}
                                    width={dialogWidth}
                                />
                            )}

                            {!isComponent &&
                                showReplacePanel &&
                                (() => {
                                    switch (candidateType) {
                                        case Wukong.DocumentProto.AICandidateComponentType
                                            .A_I_CANDIDATE_COMPONENT_TYPE_COLOR:
                                            return (
                                                <CandidateStyleColor
                                                    open={!isComponent && showReplacePanel}
                                                    position={replacePosition}
                                                    selectStyleNodeKey={''}
                                                    isMixed={false}
                                                    onChangeStyle={onChangeStyle}
                                                    selectStyleId={selectedNode?.nodeId ?? undefined}
                                                    onClose={handleReplacePanelClose}
                                                    width={dialogWidth}
                                                />
                                            )
                                        case Wukong.DocumentProto.AICandidateComponentType
                                            .A_I_CANDIDATE_COMPONENT_TYPE_FONT:
                                            return (
                                                <CandidateStyleFont
                                                    open={true}
                                                    position={replacePosition}
                                                    selectStyleId={selectedNode?.nodeId!}
                                                    onChangeStyle={onChangeStyle}
                                                    onClose={handleReplacePanelClose}
                                                    width={dialogWidth}
                                                />
                                            )
                                        case Wukong.DocumentProto.AICandidateComponentType
                                            .A_I_CANDIDATE_COMPONENT_TYPE_EFFECT:
                                            return (
                                                <CandidateStyleEffect
                                                    onClose={handleReplacePanelClose}
                                                    onChangeStyle={onChangeStyle}
                                                    position={replacePosition!}
                                                    isMixed={false}
                                                    selectStyleId={selectedNode?.nodeId!}
                                                    width={dialogWidth}
                                                ></CandidateStyleEffect>
                                            )
                                        default:
                                            return <></>
                                    }
                                })()}
                        </SingleGrid.Item>
                    </div>
                    {isError && (
                        <div className={style.isDeleted} style={{ width: errorTextWidth || 'auto' }} ref={errorRef}>
                            The component has been deleted, please select again.
                        </div>
                    )}
                    <div
                        className={`${style.thumbnailWrapper} ${
                            isComponent && componentThumbnailData ? style.componentWrapper : ''
                        }`}
                        data-testid={CandidateComponentReplaceDialogTestIds.previewWrapper}
                    >
                        {((!paintStyle && !textStyle && !effectStyle && !componentThumbnailData) || isError) && (
                            <div className={style.thumbnailPlaceholder}>- Preview -</div>
                        )}
                        {paintStyle && (
                            <CommonThumbnail type="paint" data={paintStyle.paints} width={320} height={160} />
                        )}
                        {textStyle && <CommonThumbnail type="text" data={textStyle} width={320} height={160} />}
                        {effectStyle && (
                            <CommonThumbnail type="effect" data={effectStyle.effects} width={320} height={160} />
                        )}
                        {isComponent && componentThumbnailData && (
                            <LibraryThumbnailImage thumbnailData={{ ...componentThumbnailData }} />
                        )}
                    </div>
                    {variantState && <CandidateVariantProperty {...variantState} onChange={handleVariantChange} />}
                    <div className={style.buttonWrapper}>
                        <WKButton
                            type="primary"
                            className={style.button}
                            onClick={handleReplaceBtnClick}
                            disabled={!selectedNode?.nodeId}
                            dataTestId={CandidateComponentReplaceDialogTestIds.confirmBtn}
                        >
                            {translation('Replace')}
                        </WKButton>
                    </div>
                </div>
            </WKDialog>
        </>
    )
}
