/* eslint-disable no-restricted-imports */
import { useMemo } from 'react'
import { MessageText } from '../type'

enum TextSegmentType {
    Text,
    Link,
}

interface TextSegment {
    type: TextSegmentType
    value: string
}

export function ShowMessageText(props: { message: MessageText }) {
    const textSegments = useMemo(() => extractTextSegments(props.message.c), [props.message.c])

    return (
        <span>
            {textSegments.map((segment, index) => (
                <MessageTextSegment key={index} segment={segment}></MessageTextSegment>
            ))}
        </span>
    )
}

function MessageTextSegment({ segment }: { segment: TextSegment }) {
    if (segment.type === TextSegmentType.Link) {
        return (
            <a href={segment.value} target="_blank" rel="noreferrer">
                {segment.value}
            </a>
        )
    } else {
        return <>{segment.value}</>
    }
}

function extractTextSegments(text: string): TextSegment[] {
    const tokens = text.split(/(https?:\/\/\S+)/)

    const segments = tokens.map((token) => {
        return {
            type: isValidUrl(token) ? TextSegmentType.Link : TextSegmentType.Text,
            value: token,
        }
    })

    return segments
}

function isValidUrl(string: string): boolean {
    try {
        new URL(string)
        return true
    } catch (e) {
        return false
    }
}
