/* eslint-disable no-restricted-imports */
import {
    ChangeEditorModeCommandForJs,
    ColorPickCommandWasmCall,
    UpdateColorModeCommand,
    UpdatePopupStateCommand,
    UpdateSelectedGradientColorStopIndexCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { ColorMode, ColorPickCommandType, EditorMode, PopupState } from '../node/node'
import { BridgeCommand } from './command-invoker'

export const cmdSwitchEditorMode: BridgeCommand<[EditorMode]> = (ctx, editorMode) => {
    ctx.commandInvoker.DEPRECATED_invokeBridge(ChangeEditorModeCommandForJs, { editorMode })
}

export const cmdChangePopupState: BridgeCommand<[popupState: PopupState]> = (context, popupState) => {
    context.commandInvoker.DEPRECATED_invokeBridge(
        UpdatePopupStateCommand,
        Wukong.DocumentProto.UpdatePopupStateParam.create({
            value: popupState,
        })
    )
}

export const cmdLocateNodeS: BridgeCommand<[popupState: PopupState]> = (context, popupState) => {
    context.commandInvoker.DEPRECATED_invokeBridge(
        UpdatePopupStateCommand,
        Wukong.DocumentProto.UpdatePopupStateParam.create({
            value: popupState,
        })
    )
}

export const cmdSelectedGradientColorStopIndex: BridgeCommand<[index: number]> = (ctx, index) => {
    ctx.commandInvoker.DEPRECATED_invokeBridge(UpdateSelectedGradientColorStopIndexCommand, {
        index,
    })
}

export const cmdChangeColorPick: BridgeCommand<[ColorPickCommandType]> = (ctx, colorPickCommandType) => {
    ctx.bridge.call(ColorPickCommandWasmCall, {
        colorPickCommandType: colorPickCommandType as unknown as Wukong.DocumentProto.ColorPickCommandType,
    })
}

export const cmdUpdateWasmColorMode: BridgeCommand<[ColorMode]> = (function () {
    let wasmColorMode: undefined | ColorMode = undefined
    return function (ctx, colorMode) {
        if (wasmColorMode !== colorMode) {
            wasmColorMode = colorMode
            ctx.bridge.call(UpdateColorModeCommand, {
                colorMode: colorMode as unknown as Wukong.DocumentProto.ColorMode,
            })
        }
    }
})()
