import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    ShowMore: '展示更多版本',
} as const

export const enTranslation = {
    ShowMore: 'Show more',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
