/* eslint-disable no-restricted-imports */
import { useCallback } from 'react'
import { useCanvas } from '../../../../external-store/atoms/create-canvas'
import { useCommand } from '../../../../main/app-context'
import { handleCommentMouseEvent } from './comment-mouse-event'

export function useEventMinorAnchor() {
    const command = useCommand()
    const canvas = useCanvas()

    const onMinorAnchorMouseMove = useCallback(
        (e: MouseEvent, commentId = -1) => {
            handleCommentMouseEvent(
                e,
                {
                    type: 'mousemove',
                    commentId,
                },
                command,
                canvas
            )
        },
        [command, canvas]
    )

    const onMinorAnchorPointerUp = useCallback(
        (e: MouseEvent, commentId = -1) => {
            handleCommentMouseEvent(
                e,
                {
                    type: 'mouseup',
                    commentId,
                },
                command,
                canvas
            )
        },
        [command, canvas]
    )

    const onMinorAnchorPointerEnter = useCallback(
        (e: MouseEvent, commentId = -1) => {
            handleCommentMouseEvent(
                e,
                {
                    type: 'mouseenter',
                    commentId,
                },
                command,
                canvas
            )
        },
        [command, canvas]
    )

    const onMinorAnchorPointerLeave = useCallback(
        (e: MouseEvent, commentId = -1) => {
            handleCommentMouseEvent(
                e,
                {
                    type: 'mouseenter',
                    commentId,
                },
                command,
                canvas
            )
        },
        [command, canvas]
    )

    return {
        onMinorAnchorPointerEnter,
        onMinorAnchorPointerLeave,
        onMinorAnchorMouseMove,
        onMinorAnchorPointerUp,
    }
}
