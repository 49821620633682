import {
    MonoIconCommonError16,
    MonoIconCommonSuccess16,
    MonoIconPanelTarget16,
    WKButton,
    WKIconButton,
} from '../../../../../../ui-lib/src'

import { useState } from 'react'
import { WKLoading } from '../../../../../../ui-lib/src/components/wk-loading/wk-loading'
import {
    ConversationId,
    ConversationType,
    createJointKey,
    DialogueAnswer,
    DialogueId,
    MessageItemContentProps,
} from '../../../../kernel/interface/chatbot'
import { featureSwitchManager } from '../../../../kernel/switch/core'
import { useAppContext } from '../../../../main/app-context'
import { AgentAnswerReaction } from './chatbot-dialogue-reaction'
import { SuggestMessage } from './chatbot-suggest-dialog'
import { ChatbotTextArea } from './chatbot-text-area'
import { AgentAnswerType, getAgentAnswerType, getPendingAgentAnswerType } from './type'

export type AgentAnswerProps =
    | {
          pending: true
          conversationType: ConversationType
          answer: undefined
          conversationId: string | undefined
          dialogueId: string | undefined
      }
    | {
          pending: false
          answer: DialogueAnswer
          conversationType: undefined
          conversationId: string | undefined
          dialogueId: string | undefined
      }

export const AgentAnswer = ({ pending, conversationType, answer, conversationId, dialogueId }: AgentAnswerProps) => {
    const chatbotService = useAppContext().chatbotService

    const answerType = pending ? getPendingAgentAnswerType(conversationType) : getAgentAnswerType(answer)

    const allConversationStatuses = chatbotService.stateStore.use.allConversationStatuses()

    const isExecuting =
        conversationId && dialogueId && allConversationStatuses[createJointKey(conversationId, dialogueId)]
            ? allConversationStatuses[createJointKey(conversationId, dialogueId)].answerStatus.pending
            : false

    const executingError =
        conversationId && dialogueId && allConversationStatuses[createJointKey(conversationId, dialogueId)]
            ? allConversationStatuses[createJointKey(conversationId, dialogueId)].answerStatus.error
            : false

    if (pending) {
        return (
            <AgentMessage
                message={createPendingAgentMessage(conversationType)}
                dataTestId="chatbot-agent-pending-message"
            />
        )
    }

    // 只保留 assistant 的消息
    const visibleMessages =
        answer.messages?.reduce((acc, item) => {
            if (item.role === 'assistant') {
                return [...acc, ...item.content]
            }
            return acc
        }, [] as MessageItemContentProps[]) ?? []

    const editCompleteMessage = visibleMessages.find((item) => 'text' in item && item.text !== '')?.text

    switch (answerType) {
        case AgentAnswerType.DIALOGUE:
            return <AgentMessage message={visibleMessages} conversationId={conversationId} dialogueId={dialogueId} />
        case AgentAnswerType.EXECUTE: {
            return (
                <EditAgentMessage
                    message={editCompleteMessage}
                    status={executingError ? 'error' : isExecuting ? 'pending' : 'success'}
                    conversationId={conversationId}
                    dialogueId={dialogueId}
                />
            )
        }
        case AgentAnswerType.ASSETS_SEARCH:
            return (
                <AgentMessage
                    message={visibleMessages}
                    onClickSeeMore={() => {
                        // 打开资产搜索
                        chatbotService.openAssetSearchResultPopup(dialogueId)
                    }}
                    conversationId={conversationId}
                    dialogueId={dialogueId}
                />
            )
        case AgentAnswerType.INSPIRE_SEARCH:
            return (
                <AgentMessage
                    message={visibleMessages}
                    onClickSeeMore={() => {
                        // 打开灵感搜索
                        chatbotService.openInspireSearchPopup(dialogueId)
                    }}
                    conversationId={conversationId}
                    dialogueId={dialogueId}
                />
            )
        case AgentAnswerType.DENY:
            return (
                <>
                    <AgentMessage message={visibleMessages} conversationId={conversationId} dialogueId={dialogueId} />
                    <SuggestMessage suggestTexts={answer.suggest ?? []} />
                </>
            )
    }
}

const createPendingAgentMessage = (conversationType: ConversationType): MessageItemContentProps[] => {
    if (conversationType === ConversationType.SEARCH || conversationType === ConversationType.IDEA_DISCOVERY) {
        return [{ text: '正在搜索，请稍候…' }]
    }

    // TODO(jiangjieke): 执行脚本 pending ui
    return [{ text: '正在思考，请稍候…' }]
}

const EditAgentMessage = ({
    message, // 需由 llm 给定
    status,
    conversationId,
    dialogueId,
}: {
    message?: string
    status?: 'pending' | 'success' | 'error'
    conversationId?: ConversationId
    dialogueId?: DialogueId
}) => {
    let text = ''

    switch (status) {
        case 'pending':
            text = '执行中...'
            break
        case 'success':
            text = '任务处理完成'
            break
        case 'error':
            text = '任务出错了'
            break
        default:
            throw new Error('Invalid status')
    }

    const chatbotService = useAppContext().chatbotService
    const isLastDialogue = (() => {
        const allConversations = chatbotService.dataStore.use.allConversations()
        const conversation = conversationId ? allConversations[conversationId] : null
        if (!conversation) {
            return false
        }

        if (!dialogueId) {
            return false
        }

        return conversation.conversationDataList.at(-1)?.dialogueId === dialogueId
    })()

    const [showDebugInfo, setShowDebugInfo] = useState(false)

    const showReactionAndDebugger =
        status === 'success' || (status === 'error' && featureSwitchManager.isEnabled('chatbot-debug'))

    return (
        <div
            className={`group flex-self-start color-$wk-v2-label-color-gray-13 w-full flex flex-col gap-2`}
            onDoubleClick={() => {
                if (featureSwitchManager.isEnabled('chatbot-debug')) {
                    setShowDebugInfo(!showDebugInfo)
                }
            }}
            data-testid="chatbot-agent-edit-answer"
        >
            <div
                className={`box-border flex flex-col gap-y-2 min-h-[48px] px-16px py-12px outline outline-1px outline-color-$wk-gray-3 rounded-4px justify-center`}
                style={{
                    boxShadow: '0px 2px 16px 0px var(--wk-gray-3)',
                }}
            >
                <div className="flex flex-row items-center justify-between">
                    <div className="flex items-center gap-8px ">
                        {status === 'pending' && (
                            <div className={'flex items-center justify-center w-16px h-16px'}>
                                <WKLoading size="small" noText />
                            </div>
                        )}
                        {status === 'success' && (
                            <div className={'flex items-center justify-center w-16px h-16px'}>
                                <MonoIconCommonSuccess16 color="var(--wk-green-7)" />
                            </div>
                        )}
                        {status === 'error' && (
                            <div className={'flex items-center justify-center w-16px h-16px'}>
                                <MonoIconCommonError16 color="var(--wk-red-8)" />
                            </div>
                        )}
                        <div className="text-13px lh-22px font-500">{text}</div>
                    </div>

                    {status === 'success' && (
                        <WKIconButton
                            size="small"
                            onClick={() => {
                                // 聚焦到执行的节点
                                chatbotService.focusChangedNodes(conversationId, dialogueId)
                            }}
                            icon={<MonoIconPanelTarget16 />}
                        />
                    )}
                </div>

                {message && status === 'success' && (
                    <div className="flex items-center text-13px lh-22px font-400 text-color-$wk-gray-11 select-text">
                        {message}
                    </div>
                )}
                {message && status === 'error' && (
                    <div className="flex items-center text-13px lh-22px font-400 text-color-$wk-gray-11">
                        {'好像刚刚有点小问题哦，重试一下可能就 OK 啦。'}
                    </div>
                )}
            </div>
            {showReactionAndDebugger && (
                <div className="h-6">
                    <div className={`${isLastDialogue ? 'block' : 'hidden'} group-hover:block`}>
                        <AgentAnswerReaction
                            conversationId={conversationId}
                            dialogueId={dialogueId}
                            isLastDialogue={isLastDialogue}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

const AgentMessage = ({
    conversationId,
    dialogueId,
    message,
    onClickSeeMore,
    dataTestId,
}: {
    message: MessageItemContentProps[]
    onClickSeeMore?: () => void
    conversationId?: ConversationId
    dialogueId?: DialogueId
    dataTestId?: string
}) => {
    const chatbotService = useAppContext().chatbotService
    const isLastDialogue = (() => {
        const allConversations = chatbotService.dataStore.use.allConversations()
        const conversation = conversationId ? allConversations[conversationId] : null
        if (!conversation) {
            return false
        }

        if (!dialogueId) {
            return false
        }

        return conversation.conversationDataList.at(-1)?.dialogueId === dialogueId
    })()

    return (
        <div
            className={`group flex-self-start color-$wk-v2-label-color-gray-13 w-full flex flex-col ${
                onClickSeeMore ? 'gap-2' : 'gap-2px'
            }`}
            data-testid={dataTestId ?? 'chatbot-agent-message'}
        >
            {message.map((item, index) => {
                if (item.image) {
                    return <img src={item.image} alt="agent-message" key={index} />
                }
                if (item.text) {
                    return <ChatbotTextArea text={item.text} key={index} />
                }
            })}
            {onClickSeeMore && (
                <div className="h-7">
                    <WKButton size="small" type="secondary" onClick={onClickSeeMore}>
                        查看结果
                    </WKButton>
                </div>
            )}
            <div className="h-6">
                <div className={`${isLastDialogue ? 'block' : 'hidden'} group-hover:block`}>
                    <AgentAnswerReaction
                        conversationId={conversationId}
                        dialogueId={dialogueId}
                        isLastDialogue={isLastDialogue}
                    />
                </div>
            </div>
        </div>
    )
}
