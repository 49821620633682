import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Export: '批量导出',
} as const

export const enTranslation = {
    Export: 'Export',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
