import { useCallback, useEffect, useRef, useState } from 'react'
import { isEnglishLanguage } from '../../../../../../util/src'
import { WkCLog } from '../../../../kernel/clog/wukong/instance'
import { AIGenExamples } from '../../../../kernel/request/ai-gen'

export const zhExamples: string[] = [
    '为音乐爱好者提供早间音乐体验的音乐应用首页',
    '一个用于探索音乐类型的音乐应用搜索页，带有顶部搜索栏和网格布局',
    '一个让用户控制播放和暂停的音乐播放器页面，带有充满活力的抽象专辑封面',
]

export const enExamples: string[] = [
    'A music app homepage designed to offer a morning music experience',
    'A search page with a top search bar and a grid layout for music genre exploration',
    'A music player page for users to control playback and enjoy visual art, with a vibrant abstract album cover',
]

export function useAIGenUIExamples() {
    const [examples, setExamples] = useState<string[]>(isEnglishLanguage() ? enExamples : zhExamples)
    const [loading, setLoading] = useState(false)
    const index = useRef(0)

    const nextExample = useCallback(() => {
        if (index.current >= examples.length) {
            index.current = 0
        }
        const currentExample = examples[index.current]
        index.current += 1
        return currentExample
    }, [examples])

    useEffect(() => {
        WkCLog.log('[AI GEN UI] Fetch Examples')
        setLoading(true)
        const req = new AIGenExamples()
        req.start()
            .then((res) => {
                setLoading(false)
                setExamples(res)
            })
            .catch(() => {
                setLoading(false)
                setExamples(isEnglishLanguage() ? enExamples : zhExamples)
            })
        return () => {
            req.cancel()
        }
    }, [])

    return {
        examples,
        loading,
        nextExample,
    } as const
}
