import { forwardRef, useImperativeHandle, useRef } from 'react'
import { ExplicitUndefined } from '../../../utils/explicit-undefined'
import { SelectOption } from '../picks/single-level/select-option'
import { SingleLevel, SingleLevelProps, SingleLevelRef } from '../picks/single-level/single-level'

export interface DropdownNoTriggerSingleLevelRef {
    open: () => void
    close: () => void
}
export type DropdownNoTriggerSingleLevelProps = Omit<
    SingleLevelProps,
    | 'optionTemplate'
    | 'disableDefaultPreselect'
    | 'value'
    | 'mainValue'
    | 'isMixed'
    | 'isAllowEmptyOption'
    | 'isMinWidthFromTrigger'
    | 'width'
    | 'minWidth'
>

function _DropdownNoTriggerSingleLevel(
    props: DropdownNoTriggerSingleLevelProps,
    ref?: React.Ref<DropdownNoTriggerSingleLevelRef>
) {
    const pickRef = useRef<SingleLevelRef>(null)
    useImperativeHandle(ref, () => ({ open: () => pickRef.current?.open(), close: () => pickRef.current?.close() }), [])

    return (
        <SingleLevel<ExplicitUndefined<SingleLevelProps>>
            pickRest={props.pickRest}
            ref={pickRef}
            dataTestIds={props.dataTestIds}
            placement={props.placement ?? 'right top'}
            maxWidth={props.maxWidth}
            worldRect={props.worldRect}
            isOpenState={props.isOpenState}
            onKeyboard={props.onKeyboard}
            onChange={props.onChange}
            onChangePreselect={props.onChangePreselect}
            openStateToBeFalse={props.openStateToBeFalse}
            openStateToBeTrue={props.openStateToBeTrue}
            closeByESC={props.closeByESC}
            triggerRect={props.triggerRect}
            onOpen={props.onOpen}
            onClose={props.onClose}
            getPortalElement={props.getPortalElement}
            removeMask={props.removeMask}
            optionTemplate={SelectOption}
            disableDefaultPreselect={true}
            isAllowEmptyOption={true}
            removeTopPadding={props.removeTopPadding}
            removeBottomPadding={props.removeBottomPadding}
            autoAdjustTop={true}
            value={undefined}
            mainValue={undefined}
            isMixed={undefined}
            isMinWidthFromTrigger={undefined}
            minWidth={undefined}
            width={undefined}
        >
            {props.children}
        </SingleLevel>
    )
}

const forwardRefComponent = forwardRef(_DropdownNoTriggerSingleLevel)

export const DropdownNoTriggerSingleLevel: typeof forwardRefComponent & { Option: typeof SelectOption } = Object.assign(
    forwardRefComponent,
    {
        Option: SelectOption,
    }
)
