import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Copy: '复制',
    PasteHere: '粘贴到这里',
    PasteToReplce: '粘贴替换',
    'Copy/PasteAs': '复制为',
    CopyAs: '复制为',
    CopyAsText: '复制为文本',
    CopyLink: '复制链接',
    CopyProperties: '复制属性',
    PasteProperties: '粘贴属性',
    SelectLayer: '选择图层',
    MoveToPage: '移动到页面',
    SetAs: '设为封面',
    ThumbnailSet: '已设置为封面',
    RestoreDefaultThumbnail: '恢复默认封面',
    DefaultThumbnailRestored: '已恢复默认封面',
    Plugins: '插件',
    RunLastPlugin: '运行最近一次启用',
    CopyLinkTo: '复制页面链接',
    RenamePage: '重命名',
    DuplicatePage: '创建页面副本',
    DeletePage: '删除页面',
    ExpandAllChildren: '展开子图层',
    CollapseAllChildren: '收起子图层',
    GoToMain: '跳转至组件母版',
    EditStyle: '编辑样式',
    AddNewFolder: '添加到新分组',
    Ungroup: '取消分组',
    Delete: '删除',
    OfJwET: '个',
    Style: '样式',
    Cut: '剪切',
    Paste: '粘贴',
    MarkAsResolved: '标为已解决',
    MarkAsUnresolved: '标为未解决',
    MarkAsUnread: '标为未读',
    HideComments: '隐藏评论',
    AddPrototypeStartingPoint: '添加起点',
    DeletePrototypeStartingPoint: '移除起点',
    RemovePrototypeInteraction: '移除交互',
    RemoveAllInteractions: '移除所有交互',
    MainComponent: '组件母版',
    AddVariant: '添加变体',
    PublishSelectedComponents: '发布选中组件',
    AISearch: 'AI 检索',
    MoreLayoutOption: '更多布局操作',
} as const

export const enTranslation = {
    Copy: 'Copy',
    PasteHere: 'Paste here',
    PasteToReplce: 'Paste to replace',
    'Copy/PasteAs': 'Copy/Paste as',
    CopyAs: 'Copy as',
    CopyAsText: 'Copy as text',
    CopyLink: 'Copy link',
    CopyProperties: 'Copy properties',
    PasteProperties: 'Paste properties',
    SelectLayer: 'Select layer',
    MoveToPage: 'Move to page',
    SetAs: ' Set as thumbnail',
    ThumbnailSet: 'Thumbnail set',
    RestoreDefaultThumbnail: 'Restore default thumbnail',
    DefaultThumbnailRestored: 'Default thumbnail restored',
    Plugins: 'Plugins',
    RunLastPlugin: 'Run last plugin',
    CopyLinkTo: 'Copy link to page',
    RenamePage: 'Rename page',
    DuplicatePage: 'Duplicate page',
    DeletePage: 'Delete page',
    ExpandAllChildren: 'Expand all children',
    CollapseAllChildren: 'Collapse all children',
    GoToMain: 'Go to main component',
    EditStyle: 'Edit style',
    AddNewFolder: 'Add new folder',
    Ungroup: 'Ungroup',
    Delete: 'Delete',
    OfJwET: '',
    Style: 'style',
    Cut: 'Cut',
    Paste: 'Paste',
    MarkAsResolved: 'Mark as resolved',
    MarkAsUnresolved: 'Mark as unresolved',
    MarkAsUnread: 'Mark as unread',
    HideComments: 'Hide comments',
    AddPrototypeStartingPoint: 'Add starting point',
    DeletePrototypeStartingPoint: 'Remove starting point',
    RemovePrototypeInteraction: 'Remove interactions',
    RemoveAllInteractions: 'Remove all interactions',
    MainComponent: 'Main component',
    AddVariant: 'Add variant',
    PublishSelectedComponents: 'Publish selected components',
    AISearch: 'AI Search',
    MoreLayoutOption: 'More layout options',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
