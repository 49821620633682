/* eslint-disable no-restricted-imports */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { usePointerCapture } from '../../../../../../../ui-lib/src'
import { CommitType } from '../../../../../document/command/commit-type'
import { cmdSelectedGradientColorStopIndex } from '../../../../../document/command/document-command'
import type { ColorStop } from '../../../../../document/node/node'
import { useCommand } from '../../../../context/document-context'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { ColorSpace } from '../color-picker/utils/color-translate'
import style from './gradient-area.module.less'
import { GradientBlock } from './gradient-block'
import { Ribbon } from './ribbon'
import { copyColorStop, getMinPositionIndex } from './utils'

export interface GradientAreaProps {
    colorStops: ColorStop[]
    colorSpace: ColorSpace
    selectedIndex: number
    delta?: number // (0-1)
    isCycle?: boolean
    onChangeColorStops: (colorStops: ColorStop[], options?: InputOptionsForUndoSquash) => void
}
export function GradientArea(props: GradientAreaProps) {
    const command = useCommand()
    const { colorStops, colorSpace, selectedIndex, delta = 0.05, isCycle, onChangeColorStops } = props
    const canvasRef = useRef<HTMLDivElement>(null)
    const ribbonDOMRect = useRef<DOMRect>()
    const rightIndex = useRef<number>(selectedIndex)
    rightIndex.current = selectedIndex

    const capturing = useCallback(
        (e: React.PointerEvent) => {
            if (!ribbonDOMRect.current || rightIndex.current !== selectedIndex) {
                return
            }
            const selectColorStop = colorStops[selectedIndex]
            if (!selectColorStop) {
                return
            }
            const { width } = ribbonDOMRect.current
            const offsetX = Math.min(width, Math.max(0, e.nativeEvent.offsetX))
            const position = offsetX / width
            Object.assign(selectColorStop, { position })
            onChangeColorStops?.([...colorStops], { commitType: CommitType.Noop })
        },
        [colorStops, onChangeColorStops, selectedIndex]
    )

    const onAddColorStop: (colorStop: ColorStop) => void = useCallback(
        (colorStop: ColorStop) => {
            const newColorStops: ColorStop[] = [...colorStops, colorStop]
            onChangeColorStops?.(newColorStops)
            command.invoke(cmdSelectedGradientColorStopIndex, newColorStops.length - 1)
            rightIndex.current = newColorStops.length - 1
        },
        [colorStops, command, onChangeColorStops]
    )

    const captureStart = useCallback(() => {
        ;(document.activeElement as HTMLElement)?.blur()
        ribbonDOMRect.current = canvasRef.current?.getBoundingClientRect()
    }, [])

    const captureEnd = useCallback(() => {
        ribbonDOMRect.current = undefined
        command.commitUndo()
    }, [command])

    const { pointerdown, pointermove, pointerup } = usePointerCapture({ captureStart, capturing, captureEnd })

    const onClickBlock = useCallback(
        (index: number, colorStop: ColorStop, altKey: boolean) => {
            if (altKey) {
                const newColorStop = copyColorStop(colorStop)
                onAddColorStop(newColorStop)
            } else {
                command.invoke(cmdSelectedGradientColorStopIndex, index)
                rightIndex.current = index
            }
        },
        [command, onAddColorStop]
    )

    const onDeleteBlock = useCallback(
        (index: number) => {
            if (colorStops.length < 2) {
                return
            }
            const newColorStops = colorStops.filter((v, site) => index !== site)
            const nextIndex = getMinPositionIndex(newColorStops)

            command.invoke(cmdSelectedGradientColorStopIndex, nextIndex)
            onChangeColorStops?.(newColorStops)
        },
        [colorStops, command, onChangeColorStops]
    )

    const onChangeIncrease = useCallback(
        (index: number, colorStop: ColorStop, options?: InputOptionsForUndoSquash) => {
            const position = Math.min(1, Math.max(0, colorStop.position + delta))
            colorStops[index] = Object.assign({}, colorStop, { position })
            onChangeColorStops?.([...colorStops], options)
        },
        [colorStops, delta, onChangeColorStops]
    )

    const onChangeDecrease = useCallback(
        (index: number, colorStop: ColorStop, options?: InputOptionsForUndoSquash) => {
            const position = Math.min(1, Math.max(0, colorStop.position - delta))
            colorStops[index] = Object.assign({}, colorStop, { position })
            onChangeColorStops?.([...colorStops], options)
        },
        [colorStops, delta, onChangeColorStops]
    )

    const [active, setActive] = useState<boolean>(false)
    const documentFocusout = useCallback(() => {
        setActive(true)
    }, [])
    const documentFocusin = useCallback(() => {
        setActive(false)
    }, [])

    useEffect(() => {
        document.addEventListener('focusout', documentFocusout, false)
        document.addEventListener('focusin', documentFocusin, false)
        return () => {
            document.removeEventListener('focusout', documentFocusout)
            document.removeEventListener('focusin', documentFocusin)
        }
    }, [documentFocusin, documentFocusout])

    return (
        <div
            ref={canvasRef}
            className={style.canvas}
            onPointerUp={pointerup}
            onPointerMove={pointermove}
            onPointerDown={pointerdown}
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
        >
            {colorStops.map((colorStop, index) => (
                <GradientBlock
                    key={index}
                    index={index}
                    selectedIndex={selectedIndex}
                    selectedActive={active}
                    colorStop={colorStop}
                    colorSpace={colorSpace}
                    onClickBlock={onClickBlock}
                    onDeleteBlock={onDeleteBlock}
                    onChangeIncrease={onChangeIncrease}
                    onChangeDecrease={onChangeDecrease}
                />
            ))}
            <Ribbon width={136} height={12} colorStops={colorStops} isCycle={isCycle} onAddColorStop={onAddColorStop} />
        </div>
    )
}
