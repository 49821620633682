/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { useMemo } from 'react'
import { CommentMessage, CommentUser } from '../../../../kernel/interface/comment'
import { EmojiShortNameMap } from '../comment-editor/comment-emoji/emoji-map'
import { MessageEmoji, MessageType } from '../type'
import { transformOriginMessageToLocal } from '../utils'
import { ShowMessageText } from './show-message-text'
import classes from './show-message.module.less'

export interface ShowMessageProps {
    message: CommentMessage['message']
    usersMap: Map<CommentUser['id'], CommentUser>
    className?: string
}

export function ShowMessage(props: ShowMessageProps) {
    const { message, usersMap, className } = props

    const messages = useMemo(() => {
        return transformOriginMessageToLocal(message)
    }, [message])

    return (
        <div className={classnames(classes.showMessage, [className])}>
            {messages.map((_message, index) => {
                switch (_message.t) {
                    case MessageType.Text:
                        return <ShowMessageText key={index} message={_message} />
                    case MessageType.Emoji:
                        return <ShowMessageEmoji key={index} message={_message} />
                    case MessageType.Mention:
                        return <ShowMessageMention key={index} commentUser={usersMap.get(_message.c)} />
                }
            })}
        </div>
    )
}

export function ShowMessageEmoji(props: { message: MessageEmoji }) {
    const emoji = EmojiShortNameMap.get(props.message.c) ?? props.message.c
    return <span>{emoji}</span>
}

export function ShowMessageMention(props: { commentUser?: CommentUser }) {
    const { commentUser } = props
    return commentUser ? <span className={classes.mention}>@{commentUser?.nickname}</span> : null
}
