import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    NetworkUnavailable: '网络异常',
    OfflineChangesWill: '网络异常，离线操作将在网络恢复后自动同步',
} as const

export const enTranslation = {
    NetworkUnavailable: 'Network unavailable',
    OfflineChangesWill: 'Network unavailable. Offline changes will be synced once you are back online.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
