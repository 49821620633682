import { useCallback, useEffect, useState } from 'react'
import { MonoIconCommonArrowLeft16, Scrollbar, Switch } from '../../../../../../ui-lib/src'
import { useAIDesignLintService } from '../../../../main/app-context'
import classes from './ai-design-lint-modal.module.less'
import { AiDesignInspectionRuleEnum, UpdateAIDesignLintRuleConfig } from './ai-design-lint-request'
import { NetworkStatus, View } from './ai-design-lint-service'
import { PendingWrapper } from './common/pending-wrapper'
import { translation } from './translation/rule-setting-page-translation'

function BackBar() {
    const aiDesignLintService = useAIDesignLintService()
    const setCurrentView = aiDesignLintService.setCurrentView

    const onClick = useCallback(() => {
        setCurrentView(View.Home)
    }, [setCurrentView])

    return (
        <div className={classes.back_bar} onClick={onClick}>
            <MonoIconCommonArrowLeft16 />
            <span>{translation('Preference')}</span>
        </div>
    )
}

function RuleListContainer() {
    const aiDesignLintService = useAIDesignLintService()
    const latestRuleConfig = aiDesignLintService.states.use.latestRuleConfigState()

    const needToWait = !latestRuleConfig

    const [switchStatus, setSwitchStatus] = useState(false)

    const [updating, setUpdating] = useState(true)

    useEffect(() => {
        if (latestRuleConfig) {
            setSwitchStatus(!latestRuleConfig?.includes(AiDesignInspectionRuleEnum.RULE_STRICT_TEXT_HEIGHT))
            setUpdating(false)
        }
    }, [latestRuleConfig])

    const onSwitchChange = (checked_: boolean) => {
        setUpdating(true)
        new UpdateAIDesignLintRuleConfig(
            aiDesignLintService.docID,
            checked_ ? [] : [AiDesignInspectionRuleEnum.RULE_STRICT_TEXT_HEIGHT]
        )
            .start()
            .catch(() => {
                aiDesignLintService.updateNetworkStatus(NetworkStatus.SettingRuleError)
            })
            .finally(() => {
                aiDesignLintService.refresh()
            })
    }

    return (
        <PendingWrapper pending={needToWait}>
            <Scrollbar height="100%">
                <div className={classes.rule_setting_container}>
                    <Switch
                        loading={updating}
                        checked={switchStatus}
                        onChange={onSwitchChange}
                        dataTestIds={{
                            switch: 'ai-design-lint-rule-switch',
                        }}
                    />
                    <span>{translation('RuleDescription')}</span>
                </div>
            </Scrollbar>
        </PendingWrapper>
    )
}

export function RuleSettingPage() {
    return (
        <div className="flex flex-col h-full">
            <BackBar />
            <RuleListContainer />
        </div>
    )
}
