import { createImageBitmapCompat, drawImageBitmapCompatToCanvas } from './image-bitmap-compat'
import { removeICCChunksAndGetMeta, replaceImageICCToDisplayP3 } from './image-lib'
import { ImageFormat, ImageLibContext } from './image-lib/types'
import type { ImageBitmapCompat } from './types'

const kDefaultMaxSize = 4096

export interface CompressedImage {
    blob: Blob
    imageBitmap: ImageBitmapCompat
}

export interface Size {
    width: number
    height: number
}

export async function tryGetCompressedImageAndDiscardGammaIfCompressed(
    cx: ImageLibContext,
    originFile: File | Blob,
    maxWidth = kDefaultMaxSize,
    maxHeight = kDefaultMaxSize
): Promise<{ file: File | Blob; hasCompressed: boolean }> {
    const originBytes = await originFile.arrayBuffer().then((bytes) => new Uint8Array(bytes))
    const { image: convertedBytes, isDisplayP3 } = await removeICCChunksAndGetMeta(cx, originBytes)
    const imageBitmap = await createImageBitmapCompat(cx, new Blob([convertedBytes]))

    const compressedSize = getCompressedSize(
        { width: imageBitmap.width, height: imageBitmap.height },
        { width: maxWidth, height: maxHeight }
    )
    if (!compressedSize) {
        // 无需压缩，也不去掉 gamma
        return { file: originFile, hasCompressed: false }
    }

    const canvas = document.createElement('canvas')
    canvas.width = compressedSize.width
    canvas.height = compressedSize.height

    const context = canvas.getContext('2d', {
        alpha: true,
        // 使用 CPU 渲染
        willReadFrequently: true,
    })
    if (context == null) {
        // 无法创建 context，也不去掉 gamma
        return { file: originFile, hasCompressed: false }
    }

    drawImageBitmapCompatToCanvas(context, imageBitmap, 0, 0, compressedSize.width, compressedSize.height)

    // 有透明像素就用 png 否则用 jpeg
    let type: 'image/png' | 'image/jpeg'
    if (hasTransparentPixel(context, compressedSize.width, compressedSize.height)) {
        type = 'image/png'
    } else {
        type = 'image/jpeg'
    }

    const ret = await new Promise<{ file: Blob; hasCompressed: boolean }>((resolve, reject) => {
        canvas.toBlob((blob) => {
            if (blob) {
                resolve({ file: blob, hasCompressed: true })
            } else {
                reject('compress')
            }
        }, type)
    })
    if (ret.hasCompressed && isDisplayP3) {
        const bytes = await ret.file.arrayBuffer().then((compressed) => new Uint8Array(compressed))
        const format = type === 'image/png' ? ImageFormat.Png : ImageFormat.Jpg
        const withColorSpaceBytes = await replaceImageICCToDisplayP3(cx, bytes, format)
        ret.file = new Blob([withColorSpaceBytes])
    }
    return ret
}

export function getCompressedSize(size: Size, maxSize: Size): Size | undefined {
    if (size.width <= maxSize.width && size.height <= maxSize.height) {
        // 无需压缩
        return
    }

    const compressedSize: Size = { width: 0, height: 0 }
    const whRatio = size.width / size.height

    if (size.width > maxSize.width && size.height > maxSize.height) {
        // 宽高都大于上限
        if (whRatio > maxSize.width / maxSize.height) {
            // 按宽压缩
            compressedSize.width = maxSize.width
            compressedSize.height = Math.max(1, Math.round(maxSize.width / whRatio))
        } else {
            // 按高压缩
            compressedSize.width = Math.max(1, Math.round(maxSize.height * whRatio))
            compressedSize.height = maxSize.height
        }
    } else if (size.width > maxSize.width) {
        // 宽大于上限，按宽压缩
        compressedSize.width = maxSize.width
        compressedSize.height = Math.max(1, Math.round(maxSize.width / whRatio))
    } else {
        // 高大于上限，按高压缩
        compressedSize.width = Math.max(1, Math.round(maxSize.height * whRatio))
        compressedSize.height = maxSize.height
    }

    return compressedSize
}

export function hasTransparentPixel(context: CanvasRenderingContext2D, width: number, height: number) {
    const data = context.getImageData(0, 0, width, height).data
    for (let index = 3; index < data.length; index += 4) {
        if (data[index] != 255) {
            return true
        }
    }
    return false
}
