import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    MissingFonts: '字体缺失',
    MissingFonts_synonyms1: '缺失字体',
    Replacement: '替换字体',
    UpgradeTeamTip: '升级为企业版后可在团队范围内共享字体，团队成员无需下载安装即可使用。',
    LearnMore: '了解更多',
    ReplaceFonts: '替换',
    Cancel: '取消',
    EmptyMissingFonts1: '重新启动了字体插件，',
    EmptyMissingFonts2: '文件中已没有缺失的字体。',
} as const

export const enTranslation = {
    MissingFonts: 'Missing fonts',
    MissingFonts_synonyms1: 'Missing fonts',
    Replacement: 'Replacement',
    UpgradeTeamTip: 'Upgrade to the Organization plan to share fonts with all your team members.',
    LearnMore: 'Learn more',
    ReplaceFonts: 'Replace fonts',
    Cancel: 'Cancel',
    EmptyMissingFonts1: 'Reloaded the font agent, ',
    EmptyMissingFonts2: 'and there are no missing fonts in the file.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
