/**
 * @owner: chenxiangbj@kanyun.com
 */
import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import { fileDialog } from 'file-select-dialog'
import type { Ref } from 'react'
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'
import Cropper from 'react-easy-crop'
// eslint-disable-next-line import/no-restricted-paths
import { uploadAvatarImage } from '../../../../app/src/kernel/request/upload'
import { MonoIconCommonAdd16, MonoIconCommonRemove16 } from '../../icons-v2'
import { WKButton } from '../wk-button/button'
import { WKIconButton } from '../wk-button/icon-button'
import { WKTextButton } from '../wk-button/text-button'
import { WKDialog } from '../wk-dialog'
import { WKToast } from '../wk-toast'
import getCroppedImg from './crop-image'
import styles from './index.module.less'

type Type = 'circle' | 'square'

const imageValidator = (src: string) =>
    new Promise<{ width: number; height: number }>((resolve, reject) => {
        const img = new Image()
        img.onload = () => {
            if (img.width >= 300 && img.height >= 300) {
                resolve({ width: img.width, height: img.height })
            } else {
                WKToast.error(translation('ImageSizePlease'))
                reject(translation('ImageSizePlease'))
            }
        }
        img.src = src
    })

function readFile(file: Blob) {
    return new Promise<string>((resolve, reject) => {
        if (file.size > 10 * 1024 * 1024) {
            WKToast.error(translation('ImageShouldBe'))
            reject(translation('ImageSizePlease'))
        }
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader?.result?.toString?.() ?? ''), false)
        reader.readAsDataURL(file)
    })
}

export interface IconEditorRef {
    open: () => Promise<void>
}

const _IconEditor = (
    {
        title,
        type = 'circle',
        onOk,
        onOpen,
        onClose,
        onClickReupload,
    }: {
        title: string
        type?: Type
        onOk: (imageId: string) => Promise<void>
        onClose?: () => void
        onOpen?: () => void
        onClickReupload?: () => void
    },
    ref: Ref<IconEditorRef>
) => {
    const [imageSrc, setImageSrc] = useState<string>()
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(100)
    const [imgSize, setImgSize] = useState<{ width: number; height: number }>()
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const selectFile = useCallback(async () => {
        const file = await fileDialog({
            accept: 'image/png,image/jpeg,image/bmp,image/webp,image/svg+xml',
            strict: true,
        })
        try {
            const imageDataUrl = await readFile(file)
            const { width, height } = await imageValidator(imageDataUrl)
            setImgSize({ width, height })
            setImageSrc(imageDataUrl)
            setZoom(100)
            setVisible(true)
            onOpen?.()
        } catch (e) {}
    }, [onOpen])

    const close = useCallback(
        (ok?: boolean) => {
            setVisible(false)
            setZoom(100)
            setLoading(false)
            setImageSrc(undefined)
            setCrop({ x: 0, y: 0 })
            !ok && onClose?.()
        },
        [onClose]
    )

    useImperativeHandle(
        ref,
        () => ({
            open: selectFile,
        }),
        [selectFile]
    )

    const objectFit = useMemo(() => {
        if (!imgSize) {
            return
        } else {
            return imgSize.width >= imgSize.height ? 'vertical-cover' : 'horizontal-cover'
        }
    }, [imgSize])
    const step = (400 - 100) * 0.2
    const cropperRef = useRef<Cropper>(null)
    cropperRef.current?.getCropData()
    return (
        <WKDialog
            onCancel={() => close()}
            title={title}
            hideFooterLine={true}
            visible={visible}
            footer={
                <div className={'flex justify-between items-center'}>
                    <WKTextButton
                        onClick={() => {
                            onClickReupload?.()
                            selectFile()
                        }}
                        type={'primary'}
                        size={12}
                    >
                        {translation('Reupload')}
                    </WKTextButton>
                    <div>
                        <WKButton onClick={() => close()} type={'secondary'}>
                            {translation('Cancel')}
                        </WKButton>
                        <WKButton
                            loading={loading}
                            className="align-top"
                            onClick={async () => {
                                try {
                                    setLoading(true)
                                    if (cropperRef.current?.getCropData()?.croppedAreaPixels && imageSrc) {
                                        const croppedImage = await getCroppedImg(
                                            imageSrc,
                                            cropperRef.current.getCropData()!.croppedAreaPixels
                                        )
                                        if (croppedImage) {
                                            const { resourceId } = await uploadAvatarImage(croppedImage)
                                            await onOk(resourceId)
                                            close(true)
                                        }
                                    }
                                } catch (e) {
                                    setLoading(false)
                                }
                            }}
                            type={'primary'}
                        >
                            {translation('Confirm')}
                        </WKButton>
                    </div>
                </div>
            }
        >
            <div className={styles.cropContainer} style={{ borderRadius: type === 'circle' ? '100%' : '24px' }}>
                <Cropper
                    ref={cropperRef}
                    showGrid={false}
                    cropSize={{ width: 280, height: 280 }}
                    image={imageSrc}
                    zoom={zoom / 100}
                    restrictPosition={true}
                    objectFit={objectFit}
                    crop={crop}
                    onCropChange={setCrop}
                    aspect={1}
                />
            </div>
            <div className={'flex h-10 items-center'}>
                <WKIconButton
                    icon={<MonoIconCommonAdd16 />}
                    className="shrink-0"
                    disabled={zoom === 100}
                    type={'primary'}
                    onClick={() => {
                        setZoom((r) => (r >= 100 + step ? r - step : 100))
                    }}
                ></WKIconButton>
                <div className={styles.slider}>
                    <input
                        className={zoom > 100 ? styles.blackDot : ''}
                        type={'range'}
                        value={zoom}
                        onChange={(e) => {
                            setZoom(+e.target.value)
                        }}
                        max={400}
                        min={100}
                    />
                </div>
                <WKIconButton
                    disabled={zoom === 400}
                    type={'primary'}
                    className="shrink-0"
                    icon={<MonoIconCommonRemove16 />}
                    onClick={() => setZoom((r) => (r >= 400 - step ? 400 : r + step))}
                />
            </div>
        </WKDialog>
    )
}

export const IconEditor = forwardRef(_IconEditor)
