import { MotiffApi } from '@motiffcom/plugin-api-types'
import { Wukong } from '@wukong/bridge-proto'
import { convertVComponentPropType2ComponentPropertyType } from './utils'

export function getComponentPropertiesV2(
    originProps: Wukong.DocumentProto.IComponentProperty[]
): MotiffApi.ComponentProperties {
    const properties: MotiffApi.ComponentProperties = {}
    originProps.forEach((p) => {
        properties[p.name] = {
            type: convertVComponentPropType2ComponentPropertyType(p.type),
            value: p.value,
        }

        switch (p.type) {
            case Wukong.DocumentProto.VComponentPropType.V_COMPONENT_PROP_TYPE_BOOL:
                properties[p.name].value = p.value === 'true'
                break
            case Wukong.DocumentProto.VComponentPropType.V_COMPONENT_PROP_TYPE_INSTANCE_SWAP:
                properties[p.name].preferredValues = p.preferredValues?.map((v) => ({
                    type:
                        v.type ===
                        Wukong.DocumentProto.InstanceSwapPreferredValueType.INSTANCE_SWAP_PREFERRED_VALUE_TYPE_COMPONENT
                            ? 'COMPONENT'
                            : 'COMPONENT_SET',
                    key: v.key,
                }))
                break
            default:
                break
        }
    })

    return properties
}

export function getComponentPropertyDefinitionsV2(
    ret: Omit<Wukong.DocumentProto.IRet_ApiComponentPropertyDefinitionsWithError, 'error'>
): MotiffApi.ComponentPropertyDefinitions {
    const properties: MotiffApi.ComponentPropertyDefinitions = {}
    ret.properties.forEach((property) => {
        properties[property.name] = {
            type: convertVComponentPropType2ComponentPropertyType(property.type),
            defaultValue:
                property.type === Wukong.DocumentProto.VComponentPropType.V_COMPONENT_PROP_TYPE_BOOL
                    ? property.defaultValue === 'true'
                    : property.defaultValue,
            preferredValues: property.preferredValues?.map((val) => ({
                type:
                    val.type ==
                    Wukong.DocumentProto.InstanceSwapPreferredValueType.INSTANCE_SWAP_PREFERRED_VALUE_TYPE_COMPONENT
                        ? 'COMPONENT'
                        : 'COMPONENT_SET',
                key: val.key,
            })),
            variantOptions: property.variantOptions,
        }
    })
    return properties
}

export function getComponentPropertyReferencesV2(
    res: Omit<Wukong.DocumentProto.IRet_ApiGetComponentPropertyReferencesWithError, 'error'>
) {
    if (!res.references) {
        return null
    }

    const { visible, characters, mainComponent } = res.references
    const ret: { [_ in 'visible' | 'characters' | 'mainComponent']?: string } = {}

    if (visible) ret.visible = visible
    if (characters) ret.characters = characters
    if (mainComponent) ret.mainComponent = mainComponent

    return ret
}
