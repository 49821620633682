import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    DeleteAccountFailed: '注销失败',
    AreYouSure: '确定注销你的 {{name}}账号？',
    CanNotUndo: '此操作无法撤销，请确认以下事项：',
    tip1: '你的草稿将被彻底删除，如果其他人需要访问请移动到团队。',
    tip2: '对于你所有的团队，若团队中没有其他成员，团队和其中的所有资产将被删除。若有其他成员，你将被从团队移除，其他人中权限较高或较早加入团队的成员将成为新的所有者',
    tip3: '若你是企业成员，你在企业中的草稿和所有团队、项目、文件等资产将交接给企业管理员。',
    tip4: '你已购买的专业版或企业版计划可以继续使用，账单及费用将由其他管理员继续管理。',
    PleaseInputEmail: '确认注销，请在下方输入你的邮箱。',
    InputEmail: '输入邮箱',
    Cancel: '取消',
    Delete: '注销',
    CancelAccount: '注销账号',
} as const

export const enTranslation = {
    DeleteAccountFailed: 'An error occurred while you deleting your account',
    AreYouSure: 'Are you sure you want to delete your {{name}} account? ',
    CanNotUndo: 'This action cannot be undone. Please confirm the following:',
    tip1: 'Your drafts will be permanently deleted. If others still need access, move them to the team.',
    tip2: 'If you are the only member of a team you own, the team and its assets will be deleted. Otherwise, you will be removed from the team, and the member with the highest permissions or the earliest join date will become the new owner.',
    tip3: 'If you are a member of an organization, all assets within the organization will be transferred to the admins.',
    tip4: 'If you have a professional or organization subscription, it will remain active and continue to be managed by the other admins. To prevent further charges, you can cancel your subscription first.',
    PleaseInputEmail: 'Enter your email to confirm.',
    InputEmail: 'Enter your email',
    Cancel: 'Cancel',
    Delete: 'Delete',
    CancelAccount: 'Delete account',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
