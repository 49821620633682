/* eslint-disable import/no-deprecated */
import {
    BatchUpdateNodesKeyCommand,
    FetchRemoteLibraryChanges,
    SyncRemoteDocLibraryNodeKeys,
    UpdateLibraryContentVOCommand,
    UpdateOthersRemoteLibraryChangesCommand,
    UpdateSubscribedLibraryIdsCommand,
    UpdateSyncCurrentUsedPublishMixinInFileLibraryList,
    type Wukong,
} from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { sleep } from '../../../../../../util/src'
import { signalDebounce } from '../../../../../../util/src/abort-controller/signal-task'
import { TraceableAbortSignal } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { ReplayEventEmitter } from '../../../../../../util/src/event-emitter/replay-event-emitter'
import type { CommandInvoker } from '../../../../document/command/command-invoker'
import { Bridge } from '../../../../kernel/bridge/bridge'
import {
    ComponentGetVO,
    StyleGetVO,
    VariableGetVO,
    VariableSetGetVO,
} from '../../../../kernel/interface/component-style'
import { ComponentId, ComponentSetId, LibraryId } from '../../../../kernel/interface/component-style-library-id'
import type { LibraryContentVO, LibraryIdAndNameVO } from '../../../../kernel/interface/library'
import type {
    GetLibraryOperationInfoRequest,
    GetLibraryOperationInfoResponse,
} from '../../../../kernel/interface/library-operation'
import type { DocID } from '../../../../kernel/interface/type'
import { GetLatestLibraryOperation } from '../../../../kernel/request/library-operation'
import { ServiceClass } from '../../../../kernel/util/service-class'
import { ViewStateBridge } from '../../../../view-state-bridge/view-state-bridge'
import { serializedLibraryContentMap } from '../util/transform'
import type { LibraryNotifySyncService } from './library-notify-sync-service'

const HybridPrefix = 'FigmaImport-Hybrid-'
export const FigmaImportStylePublishFile = 'FigmaImport-Style'
// 处理 wasm 和 远端数据的同步
export class LibraryWasmSyncService extends ServiceClass {
    // 拉取引用组件库更新信息
    private getLibraryOperationInfoRequest$ = new ReplayEventEmitter<{
        requestBody: GetLibraryOperationInfoRequest
        key2OldLibraryId: Record<ComponentId | ComponentSetId, LibraryId>
    }>()

    // 当前文档引用的组件库信息
    private syncInUsedDocumentId2LibraryInfoMap$ = new ReplayEventEmitter<Map<DocID, LibraryIdAndNameVO>>(1)
    public onSyncInUsedDocumentId2LibraryInfoMapChanged = (
        signal: TraceableAbortSignal,
        onChange: (newValue: Map<DocID, LibraryIdAndNameVO>) => void
    ) => {
        this.syncInUsedDocumentId2LibraryInfoMap$.onWithSignal(signal, onChange)
    }

    // 当前文档引用的混合远程组件库信息(From Figma Import)
    private syncInUsedDocumentIdOfHybridRemoteLibrary$ = new ReplayEventEmitter<Set<DocID>>(1)
    public onSyncInUsedDocumentIdOfHybridRemoteLibraryChanged = (
        signal: TraceableAbortSignal,
        onChange: (newValue: Set<DocID>) => void
    ) => {
        this.syncInUsedDocumentIdOfHybridRemoteLibrary$.onWithSignal(signal, onChange)
    }
    private currentIsUsedOtherDocumentIdList: Array<DocID> = []

    // 本地没 key，但是远程有 key 的 item（WASM 与 JS 各自维护了缓存以减少序列化次数）
    private toFetchRemoteDocInusePublishedKeyItems$ = new ReplayEventEmitter<
        Wukong.DocumentProto.IArg_syncRemoteDocLibraryNodeKeysItem[]
    >()
    private currentToFetchRemoteDocInusePublishedKeyItems: Wukong.DocumentProto.IArg_syncRemoteDocLibraryNodeKeysItem[] =
        []

    constructor(
        private readonly docId: DocID,
        private readonly docReadonly: boolean,
        private readonly commandInvoker: CommandInvoker,
        private readonly libraryNotifySyncService: LibraryNotifySyncService,
        protected override readonly bridge: Bridge,
        protected override readonly viewStateBridge: ViewStateBridge,
        private readonly signal: TraceableAbortSignal
    ) {
        super(bridge, viewStateBridge)

        this.updatSyncInUsedDocumentIdOfHybridRemoteLibrary = signalDebounce(
            this.signal,
            (documentIds: Array<DocID>) => {
                const ret = new Set(
                    documentIds.filter(
                        (curDocId) => curDocId.startsWith(HybridPrefix) || curDocId === FigmaImportStylePublishFile
                    )
                )
                this.syncInUsedDocumentIdOfHybridRemoteLibrary$.next(ret)
            },
            100
        )
        this.updatSyncInUsedDocumentId2LibraryInfoMap = signalDebounce(
            this.signal,
            (documentIds: Array<DocID>, cachedDocumentId2LibraryIdInfoMap: Map<string, LibraryIdAndNameVO>) => {
                const ret = new Map<DocID, LibraryIdAndNameVO>()
                Array.from(documentIds.values()).forEach((documentId) => {
                    const libraryInfo = cachedDocumentId2LibraryIdInfoMap.get(documentId)
                    if (documentId !== this.docId && libraryInfo) {
                        ret.set(documentId, libraryInfo)
                    }
                })
                this.syncInUsedDocumentId2LibraryInfoMap$.next(ret)
            },
            100
        )
        this.initBindJsCall()
    }

    public init = () => {
        this.initSyncNodeKeysToRemote()
        this.initSyncSubscribeLibraryIdsToWasm()
        this.initSyncLibraryContentToWasm()
        this.initSyncLibraryChangeToRemote()
        this.initSyncInUsedDocumentId2LibraryInfoMap()
    }

    private initBindJsCall = () => {
        // wasm 告知长链接需要同步的文档 ids
        this.bridge.bind(
            UpdateSyncCurrentUsedPublishMixinInFileLibraryList,
            (data) => {
                this.libraryNotifySyncService.handleWasmNeedSyncDocumentIds(
                    data.addedDocumentIds ?? [],
                    data.removedDocumentIds ?? []
                )
            },
            {
                signal: this.signal,
            }
        )

        // 为 WASM 获取远端变更
        this.bridge.bind(
            FetchRemoteLibraryChanges,
            async (data) => {
                if (this.docReadonly) {
                    return
                }
                // 不要阻塞 WASM 后面的行为
                await sleep(0)
                this.getLibraryOperationInfoRequest$.next({
                    requestBody: {
                        componentIds: data.componentKeys ?? [],
                        styleIds: data.styleKeys ?? [],
                        componentSetIds: data.componentSetKeys ?? [],
                        variableIds: data.variableKeys ?? [],
                        variableCollectionIds: data.variableSetKeys ?? [],
                    },
                    key2OldLibraryId: data.key2OldLibraryId ?? {},
                })
            },
            {
                signal: this.signal,
            }
        )

        // WASM 告知 JS 哪些 node 是可能需要写入 key 的
        this.bridge.bind(
            SyncRemoteDocLibraryNodeKeys,
            async (arg) => {
                if (this.docReadonly) {
                    return
                }

                // 不要阻塞 WASM 后面的行为
                await sleep(0)
                this.toFetchRemoteDocInusePublishedKeyItems$.next([
                    ...this.currentToFetchRemoteDocInusePublishedKeyItems,
                    ...(arg?.items ?? []).filter(
                        ({ publishFile, publishId, nodeId }) =>
                            !isNil(publishFile) && !isNil(publishId) && !isNil(nodeId)
                    ),
                ])
            },
            {
                signal: this.signal,
            }
        )
    }
    /**
     * 本文档中没有 key 但是远端发布了的 node 同步 key
     *
     * unPublishDocId2PublishNodeInfosMap$: 需要同步 key 的 node 列表
     * --- toFetchRemoteDocInusePublishedKeyItems$: ([{nodeId: '1', publishId: '2', publishFile: '3'}]) ---
     *             [map]{ keyBy('publishFile') }
     * --- ({'3', { publishId: '2', nodeId: '1' }}) ---
     *
     *
     * toUpdateNodeKeyItems$: 已获取 key 的 node 列表
     * --- <timer, toFetchRemoteDocInusePublishedKeyItems$>: <1, {'3', { publishId: '2', nodeId: '1' }}> ---
     *      [switchMap]{ request library id }
     * --- ({'libraryId', { publishId: '2', nodeId: '1' }}) ---
     *      [switchMap]{ request library }
     * --- ([{ nodeId: '1', key: '100' }]) ---
     */
    private initSyncNodeKeysToRemote = () => {
        const updateNodeKeyItems = (
            unPublishDocId2PublishNodeIdsMap: Map<string, Array<{ nodeId: string; publishId: string }>>,
            remoteLibraryContentMap: Map<LibraryId, LibraryContentVO>
        ) => {
            const convert = () => {
                if (!unPublishDocId2PublishNodeIdsMap.size || !remoteLibraryContentMap.size) {
                    return []
                }
                const docId2LibraryContent: Record<DocID, LibraryContentVO> = [
                    ...remoteLibraryContentMap.values(),
                ].reduce(
                    (res, libraryContent) => ({
                        ...res,
                        [libraryContent.library.document!.id]: libraryContent,
                    }),
                    {}
                )

                const nodeKeyList: Wukong.DocumentProto.IBatchUpdateNodesKeyParamItem[] = []
                unPublishDocId2PublishNodeIdsMap.forEach((publishInfos, docId) => {
                    const libraryContent = docId2LibraryContent[docId]
                    if (!libraryContent) {
                        return
                    }
                    let nodeIdMapVO: Map<string, ComponentGetVO | StyleGetVO | VariableSetGetVO | VariableGetVO> =
                        new Map(
                            [
                                ...(libraryContent.components ?? []),
                                ...(libraryContent.componentSets ?? []),
                                ...(libraryContent.styles ?? []),
                                ...Object.values(libraryContent.componentSetId2ChildrenComponents).flat(1),
                            ].map((vo) => [vo.nodeId, vo] as const)
                        )
                    nodeIdMapVO = new Map(
                        [
                            ...(libraryContent.components ?? []),
                            ...(libraryContent.componentSets ?? []),
                            ...(libraryContent.styles ?? []),
                            ...(libraryContent.variables ?? []),
                            ...(libraryContent.variableSets ?? []),
                            ...Object.values(libraryContent.componentSetId2ChildrenComponents).flat(1),
                        ].map((vo) => [vo.nodeId, vo] as const)
                    )

                    publishInfos.forEach(({ nodeId, publishId }) => {
                        const nodeKey = nodeIdMapVO.get(publishId)?.id
                        if (nodeKey) {
                            nodeKeyList.push({
                                id: nodeId,
                                key: nodeKey,
                            })
                        }
                    })
                })
                return nodeKeyList
            }
            const ret = convert()
            if (ret.length > 0) {
                this.commandInvoker.DEPRECATED_invokeBridge(BatchUpdateNodesKeyCommand, {
                    items: ret,
                })
            }
        }

        let currentUnPublishDocId2PublishNodeInfosMap: Map<
            string,
            Array<{ nodeId: string; publishId: string }>
        > = new Map()

        this.toFetchRemoteDocInusePublishedKeyItems$.onWithSignal(this.signal, async (items) => {
            const docId2NodeIdsMap = new Map<
                string,
                Array<{
                    nodeId: string
                    publishId: string
                }>
            >()
            for (const item of items) {
                const { publishFile, publishId, nodeId } = item
                if (isNil(publishFile) || isNil(publishId) || isNil(nodeId)) {
                    continue
                }
                const nodeIdItem = {
                    publishId,
                    nodeId,
                }
                const list = docId2NodeIdsMap.get(publishFile)
                if (list) {
                    list.push(nodeIdItem)
                } else {
                    docId2NodeIdsMap.set(publishFile, [nodeIdItem])
                }
            }
            currentUnPublishDocId2PublishNodeInfosMap = docId2NodeIdsMap

            updateNodeKeyItems(
                currentUnPublishDocId2PublishNodeInfosMap,
                await this.libraryNotifySyncService.getAllRemoteLibraryContentPromise()
            )
        })

        this.libraryNotifySyncService.onAllRemoteLibraryContentChanged((remoteLibraryContentMap) => {
            updateNodeKeyItems(currentUnPublishDocId2PublishNodeInfosMap, remoteLibraryContentMap)
        }, this.signal)
    }

    private initSyncLibraryChangeToRemote = () => {
        const callback = ({
            resp: {
                operationVOMap,
                operationId2ComponentIds,
                operationId2StyleIds,
                operationId2ComponentSetIds,
                operationId2VariableIds,
                operationId2VariableCollectionIds,
            },
            key2OldLibraryId,
        }: {
            resp: GetLibraryOperationInfoResponse
            key2OldLibraryId: Record<ComponentId | ComponentSetId, LibraryId>
        }) => {
            const uniqKey2OperationVO: Record<string, Wukong.DocumentProto.ILibraryOperation> = {}
            for (const operationVO of Object.values(operationVOMap ?? {})) {
                const existValue: Wukong.DocumentProto.ILibraryOperation | undefined =
                    uniqKey2OperationVO[operationVO.uniqKey]
                uniqKey2OperationVO[operationVO.uniqKey] = {
                    operation:
                        !existValue?.operation || existValue.operation.operationTime! < operationVO.operationTime
                            ? operationVO
                            : existValue.operation,
                    componentKeys: [
                        ...(existValue?.componentKeys ?? []),
                        ...(operationId2ComponentIds?.[operationVO.id] ?? []),
                    ],
                    styleKeys: [...(existValue?.styleKeys ?? []), ...(operationId2StyleIds?.[operationVO.id] ?? [])],
                    componentSetKeys: [
                        ...(existValue?.componentSetKeys ?? []),
                        ...(operationId2ComponentSetIds?.[operationVO.id] ?? []),
                    ],
                    variableSetKeys: [
                        ...(existValue?.variableSetKeys ?? []),
                        ...(operationId2VariableCollectionIds?.[operationVO.id] ?? []),
                    ],
                    variableKeys: [
                        ...(existValue?.variableKeys ?? []),
                        ...(operationId2VariableIds?.[operationVO.id] ?? []),
                    ],
                }
            }
            this.commandInvoker.DEPRECATED_invokeBridge(UpdateOthersRemoteLibraryChangesCommand, {
                operations: Object.values(uniqKey2OperationVO),
                key2OldLibraryId,
            })
        }
        this.getLibraryOperationInfoRequest$.onWithSignal(this.signal, ({ requestBody, key2OldLibraryId }) => {
            new GetLatestLibraryOperation(requestBody, this.docId)
                .startWithSignal(this.signal)
                .then((resp) => {
                    callback({ resp, key2OldLibraryId })
                })
                .catch(() => {})
        })
    }

    private initSyncSubscribeLibraryIdsToWasm = () => {
        this.libraryNotifySyncService.onSubscribedLibraryInfoListChanged((list) => {
            this.commandInvoker.DEPRECATED_invokeBridge(UpdateSubscribedLibraryIdsCommand, {
                libraryIds: list.map(({ libraryId }) => libraryId),
            })
        }, this.signal)
    }

    private initSyncLibraryContentToWasm = () => {
        this.libraryNotifySyncService.onAllRemoteLibraryContentChanged((data) => {
            this.commandInvoker.DEPRECATED_invokeBridge(UpdateLibraryContentVOCommand, {
                vo: serializedLibraryContentMap(data),
            })
        }, this.signal)
    }

    private initSyncInUsedDocumentId2LibraryInfoMap = () => {
        // 当前文件有引用的 docIds
        const onIsUsedOtherDocumentIdListChanged = (documentIds: Array<DocID>) => {
            this.currentIsUsedOtherDocumentIdList = documentIds

            this.updatSyncInUsedDocumentId2LibraryInfoMap(
                documentIds,
                this.libraryNotifySyncService.getLatestCachedDocumentId2LibraryIdInfoMap()
            )
            this.updatSyncInUsedDocumentIdOfHybridRemoteLibrary(documentIds)
        }

        this.viewStateBridge.registerWithSignal(
            this.signal,
            'libraryRemoteListModal',
            (libraryRemoteListModalState) => {
                if (!libraryRemoteListModalState?.open) {
                    return
                }
                onIsUsedOtherDocumentIdListChanged(libraryRemoteListModalState?.inUsedDocumentIds ?? [])
            }
        )

        this.libraryNotifySyncService.onCachedDocumentId2LibraryIdInfoMapChanged(
            (newValue: Map<DocID, LibraryIdAndNameVO>) => {
                this.updatSyncInUsedDocumentId2LibraryInfoMap(this.currentIsUsedOtherDocumentIdList, newValue)
            },
            this.signal
        )
    }

    private updatSyncInUsedDocumentId2LibraryInfoMap: (
        documentIds: Array<DocID>,
        cachedDocumentId2LibraryIdInfoMap: Map<string, LibraryIdAndNameVO>
    ) => void

    private updatSyncInUsedDocumentIdOfHybridRemoteLibrary: (documentIds: Array<DocID>) => void
}
