import { translation } from './edit.translation'
/* eslint-disable no-restricted-imports */
import {
    DuplicateSelection as DuplicateSelectionCommand,
    RemoveSelectedNodesCommand,
    StartCanvasSearchAndReplaceCommand,
    StartCanvasSearchCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { useCallback } from 'react'
import { WKItemType, WKMenuConfig, WKToast } from '../../../../../../../ui-lib/src'
import { CommandInvoker } from '../../../../../document/command/command-invoker'
import { cmdChangeColorPick } from '../../../../../document/command/document-command'
import { ColorPickCommandType } from '../../../../../document/node/node'
import { isFirefox } from '../../../../../kernel/util/ua'
import { useCopyCheckService, useDevModeAvailabilityVerificationService } from '../../../../../main/app-context'
import { useViewState } from '../../../../../view-state-bridge'
import { useClipboardService } from '../../../../context/document-context'
import { useEditorActionStatusSubMenuItem } from '../hook/editor-action-status-menu'
import ActionEnabledType = Wukong.DocumentProto.ActionEnabledType
import { ShortcutKey, shortcutLabelMap } from '../../../../../kernel/interface/shortcut-key'

export const useDesignModeMenuEdit = (command: CommandInvoker): WKMenuConfig => {
    const clipboardService = useClipboardService()
    const docReadonly = useViewState('docReadonly', false)

    const searchMenu: WKMenuConfig = [
        { type: WKItemType.Divider },
        {
            name: translation('Find...'),
            shortCut: shortcutLabelMap[ShortcutKey.CanvasSearch],
            disable: false,
            handler: () => command.DEPRECATED_invokeBridge(StartCanvasSearchCommand),
        },
        {
            name: translation('FindAndReplace...'),
            disable: docReadonly,
            handler: () => command.DEPRECATED_invokeBridge(StartCanvasSearchAndReplaceCommand),
        },
    ]

    const copyCheckService = useCopyCheckService()
    const copyHasBeenProhibited = copyCheckService.useZustandStore.use.copyHasBeenProhibited()
    const checkCopyAccessInvoker = useCallback(
        (block: () => void) => {
            return () => {
                if (copyHasBeenProhibited) {
                    WKToast.show(translation('ProhibitCopy'))
                    return
                }
                block()
            }
        },
        [copyHasBeenProhibited]
    )

    return [
        {
            name: translation('Edit'),
            position: 'right top',
            testId: 'main-menu-edit',
            children: [
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_UNDOABLE,
                    hiddenWhenDisabled: docReadonly,
                    testId: 'main-menu-undo',
                }),
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_REDOABLE,
                    hiddenWhenDisabled: docReadonly,
                    testId: 'main-menu-redo',
                }),
                {
                    name: translation('CopyAsText'),
                    // splitLineTop: true,
                    disable: !useViewState('actionEnabledCopyAsTextStatus'),
                    handler: checkCopyAccessInvoker(() =>
                        clipboardService.copy(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_TEXT)
                    ),
                },
                {
                    name: `${translation('CopyAs')} SVG`,
                    disable: !useViewState('actionEnabledCopyAsSVGStatus') || isFirefox(),
                    handler: checkCopyAccessInvoker(() =>
                        clipboardService.copy(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_S_V_G)
                    ),
                },
                {
                    name: `${translation('CopyAs')} PNG`,
                    shortCut: shortcutLabelMap[ShortcutKey.CopyAsPNG],
                    disable: !useViewState('actionEnabledCopyAsPNGStatus') || isFirefox(),
                    handler: checkCopyAccessInvoker(() =>
                        clipboardService.copy(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_P_N_G)
                    ),
                },
                {
                    name: translation('CopyLink'),
                    disable: !useViewState('actionEnabledCopyAsLinkStatus'),
                    handler: () =>
                        clipboardService.copy(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_LINK),
                },
                { type: WKItemType.Divider, hidden: docReadonly },
                {
                    name: translation('PasteOverSelection'),
                    shortCut: shortcutLabelMap[ShortcutKey.PasteOverSelection],
                    disable: !useViewState('actionEnabledPasteHereStatus'),
                    handler: () =>
                        clipboardService.paste(
                            Wukong.DocumentProto.ClipboardPasteType.CLIPBOARD_PASTE_TYPE_PASTE_OVER_SELECTION
                        ),
                    hidden: docReadonly,
                    autoCommit: true,
                },
                {
                    name: translation('PasteToReplace'),
                    shortCut: shortcutLabelMap[ShortcutKey.PasteToReplace],
                    disable: !useViewState('actionEnabledPasteToReplaceStatus'),
                    handler: () =>
                        clipboardService.paste(
                            Wukong.DocumentProto.ClipboardPasteType.CLIPBOARD_PASTE_TYPE_PASTE_TO_REPLACE
                        ),
                    hidden: docReadonly,
                    autoCommit: true,
                },
                {
                    name: translation('Duplicate'),
                    shortCut: shortcutLabelMap[ShortcutKey.Duplicate],
                    disable: !useViewState('actionEnabledDuplicateSelectionStatus'),
                    handler: () => command.DEPRECATED_invokeBridge(DuplicateSelectionCommand),
                    hidden: docReadonly,
                    autoCommit: true,
                    testId: 'main-menu-duplicate',
                },
                {
                    name: translation('Delete'),
                    shortCut: shortcutLabelMap[ShortcutKey.Delete],
                    disable: !useViewState('actionEnabledRemoveSelectNodesStatus'),
                    handler: () => command.DEPRECATED_invokeBridge(RemoveSelectedNodesCommand),
                    hidden: docReadonly,
                    autoCommit: true,
                },
                ...searchMenu,
                { type: WKItemType.Divider },
                {
                    name: translation('CopyProperties'),
                    shortCut: shortcutLabelMap[ShortcutKey.CopyProperties],
                    disable: !useViewState('actionEnabledCopyPropertiesStatus'),
                    handler: checkCopyAccessInvoker(() =>
                        clipboardService.copy(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_PROPERTY)
                    ),
                },
                {
                    name: translation('PasteProperties'),
                    shortCut: shortcutLabelMap[ShortcutKey.PasteProperties],
                    disable: !useViewState('actionEnabledPastePropertiesStatus'),
                    handler: () =>
                        clipboardService.paste(Wukong.DocumentProto.ClipboardPasteType.CLIPBOARD_PASTE_TYPE_PROPERTY),
                    hidden: docReadonly,
                    autoCommit: true,
                },
                {
                    name: translation('PickColor'),
                    shortCut: shortcutLabelMap[ShortcutKey.PickColor],
                    disable: !useViewState('actionEnabledColorPickStatus'),
                    handler: () => command.invoke(cmdChangeColorPick, ColorPickCommandType.SwitchColorPickVisible),
                },
                { type: WKItemType.Divider },
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_SELECT_ALL,
                    hiddenWhenDisabled: false,
                    testId: 'select-all-button',
                }),
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_CLEAR_SELECT_NODES,
                    hiddenWhenDisabled: false,
                }),
                useEditorActionStatusSubMenuItem({
                    command,
                    editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_SELECT_INVERSE,
                    hiddenWhenDisabled: false,
                }),
            ],
        },
    ]
}

export const useDevModeMenuEdit = (command: CommandInvoker): WKMenuConfig => {
    const clipboardService = useClipboardService()

    const devModeAvailablieyVerificationService = useDevModeAvailabilityVerificationService()
    const isDevModeAccessible = devModeAvailablieyVerificationService.useZustandStore.use.isDevModeAvailable()

    const copyCheckService = useCopyCheckService()
    const copyHasBeenProhibited = copyCheckService.useZustandStore.use.copyHasBeenProhibited()
    const checkCopyAccessInvoker = useCallback(
        (block: () => void) => {
            return async () => {
                if (copyHasBeenProhibited) {
                    WKToast.show(translation('ProhibitCopy'))
                    return
                }
                block()
            }
        },
        [copyHasBeenProhibited]
    )

    return [
        {
            name: translation('Edit'),
            position: 'right top',
            testId: 'main-menu-edit',
            children: [
                {
                    name: translation('CopyAsText'),
                    // splitLineTop: true,
                    disable: !useViewState('actionEnabledCopyAsTextStatus'),
                    handler: checkCopyAccessInvoker(() =>
                        clipboardService.copy(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_TEXT)
                    ),
                },
                {
                    name: `${translation('CopyAs')} SVG`,
                    disable: !useViewState('actionEnabledCopyAsSVGStatus') || isFirefox(),
                    handler: checkCopyAccessInvoker(() =>
                        clipboardService.copy(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_S_V_G)
                    ),
                },
                {
                    name: `${translation('CopyAs')} PNG`,
                    shortCut: shortcutLabelMap[ShortcutKey.CopyAsPNG],
                    disable: !useViewState('actionEnabledCopyAsPNGStatus') || isFirefox(),
                    handler: checkCopyAccessInvoker(() =>
                        clipboardService.copy(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_P_N_G)
                    ),
                },
                {
                    name: translation('CopyLink'),
                    disable: !useViewState('actionEnabledCopyAsLinkStatus'),
                    handler: () =>
                        clipboardService.copy(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_LINK),
                },
                { type: WKItemType.Divider, hidden: !isDevModeAccessible },
                {
                    name: translation('Find...'),
                    shortCut: shortcutLabelMap[ShortcutKey.CanvasSearch],
                    disable: false,
                    hidden: !isDevModeAccessible,
                    handler: () => command.DEPRECATED_invokeBridge(StartCanvasSearchCommand),
                },
                { type: WKItemType.Divider },
                {
                    name: translation('CopyProperties'),
                    shortCut: shortcutLabelMap[ShortcutKey.CopyProperties],
                    disable: !useViewState('actionEnabledCopyPropertiesStatus'),
                    handler: checkCopyAccessInvoker(() =>
                        clipboardService.copy(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_PROPERTY)
                    ),
                },
                {
                    name: translation('PickColor'),
                    shortCut: shortcutLabelMap[ShortcutKey.PickColor],
                    disable: !useViewState('actionEnabledColorPickStatus'),
                    handler: () => command.invoke(cmdChangeColorPick, ColorPickCommandType.SwitchColorPickVisible),
                },
            ],
        },
    ]
}
