import { Wukong } from '@wukong/bridge-proto'
import { forwardRef, useCallback, useEffect, useMemo } from 'react'
import { DraggablePopupV2 } from '../../../../../../../ui-lib/src'
import { RGB, SolidPaint } from '../../../../../document/node/node'
import { usePosition } from '../../../../utils/use-position'
import { useRenderColorSpace } from '../../../color-profile'
import { BlendContent } from '../../../design/blend/blend-content'
import { hex2rgb, rgb2hex } from '../../../design/blend/color-picker/utils/color-translate'
import { PaintIconColor } from '../../../paint-icon-color/paint-icon-color'
import styles from '../index.module.less'

export interface ColorSelectorProps {
    popupKey: number // 用于唯一标识颜色弹窗
    title: string
    colorHexValue: string
    isOpen: (popupKey: number) => boolean // popupKey 对应的弹窗是否在打开状态
    onChangeModalVisible: (popupKey: number, visible: boolean) => void
    className?: string
    selected?: boolean
    onChange?: (hexColor: string) => void
    dataTestIds?: {
        colorInput?: string
        colorSelectorPanel?: string
    }
}

export const _ColorSelector = (props: ColorSelectorProps, outRef: React.Ref<HTMLDivElement>) => {
    const { popupKey, colorHexValue, title, className, isOpen, selected, onChangeModalVisible, onChange, dataTestIds } =
        props

    const paint = useMemo<SolidPaint>(() => {
        return {
            type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
            color: hex2rgb(colorHexValue),
            opacity: 1,
            visible: true,
        }
    }, [colorHexValue])
    const colorSpace = useRenderColorSpace()

    const { position, reactRef } = usePosition({ isOpen: isOpen(popupKey), outRef })
    const _position = useMemo(() => {
        if (!position) return undefined
        return {
            top: position.top,
            left: position.left + 98,
        }
    }, [position])

    const onClose = useCallback(() => {
        onChangeModalVisible(popupKey, false)
    }, [onChangeModalVisible, popupKey])

    const onClickOpenModal = useCallback(() => {
        onChangeModalVisible(popupKey, !isOpen(popupKey))
    }, [isOpen, onChangeModalVisible, popupKey])

    const _onChangeColor = useCallback(
        (value: RGB) => {
            onChange?.(`#${rgb2hex(value.r, value.g, value.b).toUpperCase()}`)
        },
        [onChange]
    )

    useEffect(() => {
        return () => {}
    }, [])

    return (
        <div className={styles.colorSelector}>
            <span className={styles.subTitle}>{title}</span>
            <div
                ref={reactRef}
                className={`${styles.eventPenetrate} ${selected ? styles.selected : ''} ${className ?? ''}`}
                // 避免触发可拖拽弹窗的拖拽
                data-disabled-drag-move={'true'}
            >
                <div className={styles.colorContainer} data-testid={dataTestIds?.colorInput}>
                    <PaintIconColor
                        paint={paint}
                        colorSpace={colorSpace}
                        onChangeColor={_onChangeColor}
                        onMouseDownIcon={onClickOpenModal}
                        onClickInput={onClose}
                        isWidth={92}
                    />
                </div>
            </div>
            <DraggablePopupV2
                visible={isOpen(popupKey)}
                position={_position}
                onCancel={onClose}
                header={title}
                styleType="editor"
                footer={null}
                bodyClassName="p-0"
                testId={dataTestIds?.colorSelectorPanel}
                overflowVisible
            >
                <BlendContent
                    data={paint}
                    colorSpace={colorSpace}
                    onChangeColor={_onChangeColor}
                    enterClose={onClose}
                />
            </DraggablePopupV2>
        </div>
    )
}

export const ColorSelector = forwardRef(_ColorSelector)
