import { translation } from './scale.translation'
/* eslint-disable no-restricted-imports */
import {
    ChangeEditorModeCommandForJs,
    ChangeScaleToolPanelCommand,
    TryExitScaleToolOnInputEnteredCommand,
    Wukong,
} from '@wukong/bridge-proto'
import classnames from 'classnames'
import { isNil } from 'lodash-es'
import { HTMLAttributes, useEffect, useRef } from 'react'
import {
    IconCoordinateH,
    IconCoordinateW,
    IconScale,
    MonoIconCommonClose16,
    Select,
    WKIconButton,
} from '../../../../../../ui-lib/src'
import { EditorMode } from '../../../../document/node/node'
import { ToKeyCode } from '../../../../document/util/keycode'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { SingleGrid } from '../../atom/grid/single-grid'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { ScrubbableInputNumber } from '../../atom/inputs/scrubbable-input-number'
import { ScrubbableInputScale, ScrubbableInputScaleRef } from '../../atom/inputs/scrubbable-input-scale'
import { Value } from '../../atom/inputs/utils/type'
import { Title } from '../../atom/title/title'
import { ScaleCenter } from './scale-center'
import style from './scale.module.less'

interface ScaleProps extends HTMLAttributes<HTMLDivElement> {}

const SCALE_CONFIG = [
    ['0.25x', 0.25],
    ['0.5x', 0.5],
    ['1x', 1],
    ['2x', 2],
    ['3x', 3],
    ['4x', 4],
    ['5x', 5],
    ['10x', 10],
]

export function Scale(props: ScaleProps) {
    const { className } = props
    const command = useCommand()
    const scrubbableInputScaleRef = useRef<ScrubbableInputScaleRef>(null)

    const scaleToolPanelState = useViewState('scaleToolPanel')
    const editorModeJustChanged = scaleToolPanelState?.editorModeJustChanged ?? false

    const handleWidthChange = (value: Value, options?: InputOptionsForUndoSquash) => {
        if (typeof value !== 'number') {
            return
        }
        command.DEPRECATED_invokeBridge(ChangeScaleToolPanelCommand, {
            width: value,
            isIncrease: value > (scaleToolPanelState?.width ?? 0),
        })
        command.commitUndo(options?.commitType)
    }

    const handleHeightChange = (value: Value, options?: InputOptionsForUndoSquash) => {
        if (typeof value !== 'number') {
            return
        }
        command.DEPRECATED_invokeBridge(ChangeScaleToolPanelCommand, {
            height: value,
            isIncrease: value > (scaleToolPanelState?.height ?? 0),
        })
        command.commitUndo(options?.commitType)
    }

    const handleOriginChange = (value: Wukong.DocumentProto.LayoutOrigin) => {
        command.DEPRECATED_invokeBridge(ChangeScaleToolPanelCommand, {
            origin: value,
            isIncrease: false,
        })
    }

    const handleExitScale = () => {
        command.DEPRECATED_invokeBridge(ChangeEditorModeCommandForJs, {
            editorMode: EditorMode.EM_Move,
        })
    }

    const handleScaleChange = (value: Value, options?: InputOptionsForUndoSquash) => {
        if (typeof value !== 'number') {
            return
        }
        command.DEPRECATED_invokeBridge(ChangeScaleToolPanelCommand, {
            scaleFactorPercent: value * 100,
        })
        command.commitUndo(options?.commitType)
    }

    // 时机上，反直觉的，keydown 时机先于 onChange
    // 因此是先退出，再缩放
    const handleKeydownEnter = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.keyCode === ToKeyCode.Enter) {
            command.DEPRECATED_invokeBridge(TryExitScaleToolOnInputEnteredCommand)
        } else if (ev.keyCode === ToKeyCode.Esc) {
            handleExitScale()
        }
    }

    useEffect(() => {
        if (editorModeJustChanged) {
            const inputElement = scrubbableInputScaleRef.current?.getInputElement()
            if (inputElement) {
                inputElement.focus()
                inputElement.select()
            }
        }
    }, [editorModeJustChanged])

    return (
        <div className={classnames(className, style.scaleContainer)}>
            <Title>
                <Title.Left>{translation('Scale')}</Title.Left>
                <Title.Right>
                    <WKIconButton icon={<MonoIconCommonClose16 />} onClick={handleExitScale} />
                </Title.Right>
            </Title>
            <SingleGrid>
                <SingleGrid.Item start={5} span={46}>
                    <Select.ArrowSingleLevel
                        disabled={Boolean(scaleToolPanelState?.disableScaleFactorPercent)}
                        value={
                            typeof scaleToolPanelState?.scaleFactorPercent === 'number'
                                ? scaleToolPanelState.scaleFactorPercent / 100
                                : undefined
                        }
                        onChange={(v) => handleScaleChange(v)}
                        minWidth={184}
                        label={
                            <ScrubbableInputScale
                                ref={scrubbableInputScaleRef}
                                icon={<IconScale />}
                                disabled={Boolean(scaleToolPanelState?.disableScaleFactorPercent)}
                                onKeyDown={handleKeydownEnter}
                                className={style.scalePercent}
                                defaultShowEmpty={isNil(scaleToolPanelState?.scaleFactorPercent)}
                                value={
                                    typeof scaleToolPanelState?.scaleFactorPercent === 'number'
                                        ? scaleToolPanelState.scaleFactorPercent / 100
                                        : undefined
                                }
                                onChange={handleScaleChange}
                            />
                        }
                        dataTestIds={{ triggerFocus: 'scale-trigger' }}
                    >
                        {SCALE_CONFIG.map(([label, value], index) => {
                            return (
                                <Select.ArrowSingleLevel.Option value={value} key={index} backwardIcon={''}>
                                    {label}
                                </Select.ArrowSingleLevel.Option>
                            )
                        })}
                    </Select.ArrowSingleLevel>
                </SingleGrid.Item>
            </SingleGrid>
            <SingleGrid>
                <SingleGrid.Item start={5} span={22}>
                    <ScrubbableInputNumber
                        onKeyDown={handleKeydownEnter}
                        disabled={Boolean(scaleToolPanelState?.disableWidth)}
                        icon={<IconCoordinateW />}
                        value={scaleToolPanelState?.width ?? undefined}
                        onChange={handleWidthChange}
                        min={1}
                        defaultShowEmpty={isNil(scaleToolPanelState?.width)}
                    />
                </SingleGrid.Item>
                <SingleGrid.Item start={29} span={22}>
                    <ScrubbableInputNumber
                        onKeyDown={handleKeydownEnter}
                        disabled={Boolean(scaleToolPanelState?.disableHeight)}
                        icon={<IconCoordinateH />}
                        value={scaleToolPanelState?.height ?? undefined}
                        onChange={handleHeightChange}
                        min={1}
                        defaultShowEmpty={isNil(scaleToolPanelState?.height)}
                    />
                </SingleGrid.Item>
            </SingleGrid>
            <ScaleCenter layoutOrigin={scaleToolPanelState?.origin ?? undefined} onChange={handleOriginChange} />
        </div>
    )
}
