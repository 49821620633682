import { useState } from 'react'
import { InputV2, WKDialog, WKToast } from '../../../../../../../ui-lib/src'
import { usePluginService } from '../../../../../main/app-context'
import { fetchPublishPlugin } from '../plugin-request'
import { PluginIconInfo } from '../template/type'
import { PluginDevStatus, usePluginDevelopment } from '../use-plugin-development'
import { IconImageUploader } from './icon-image-uploader'
import { translation } from './plugin-dev-editor-publish-info.translation'
import { usePluginDevPublishValidate } from './use-plugin-dev-publish-validate'

export function PluginDevelopmentEditorPublishInfo() {
    const pluginService = usePluginService()
    const { setPluginDevStatus, currentEditorPublishInfoPlugin } = usePluginDevelopment()
    const { validStatus, validate, setValidStatus } = usePluginDevPublishValidate()

    const [edtioredIconInfo, setEditorIconInfo] = useState<PluginIconInfo | undefined>()
    const [edtioredName, setEditorName] = useState<string>(currentEditorPublishInfoPlugin?.publishInfo?.name ?? '')
    const [loading, setLoading] = useState<boolean>(false)

    const iconSrc = edtioredIconInfo
        ? `data:${edtioredIconInfo.format};base64,${edtioredIconInfo.imageData}`
        : currentEditorPublishInfoPlugin?.publishInfo?.iconUrl

    if (!currentEditorPublishInfoPlugin?.publishInfo) {
        return null
    }

    const handleIconChange = async (iconInfo: PluginIconInfo) => {
        setEditorIconInfo(iconInfo)
    }

    const handleNameChange = (name: string) => {
        setEditorName(name)
        setValidStatus((state) => ({
            ...state,
            nameErrorText: undefined,
        }))
    }

    const updatePublishInfo = async () => {
        if (!validate(edtioredName, iconSrc)) {
            return
        }

        setLoading(true)
        try {
            await fetchPublishPlugin(currentEditorPublishInfoPlugin.id, {
                updateRequest: {
                    name: edtioredName,
                    ...(edtioredIconInfo
                        ? { iconData: edtioredIconInfo.imageData, format: edtioredIconInfo.format }
                        : {}),
                },
            })

            pluginService.fetchEditablePublishedPlugins()
            pluginService.fetchPublishedPlugins()

            WKToast.show(translation('PluginInfoUpdated'))
            setPluginDevStatus(PluginDevStatus.Main)
        } catch (error) {
            WKToast.error(translation('PluginInfoUpdatedFailed'))
        } finally {
            setLoading(false)
        }
    }

    return (
        <WKDialog
            title={translation('EditPluginInfo')}
            visible={true}
            showTitle={true}
            width={400}
            onCancel={() => {
                setPluginDevStatus(PluginDevStatus.Main)
            }}
            onCloseIconCancel={() => {
                setPluginDevStatus(PluginDevStatus.Main)
            }}
            onOk={updatePublishInfo}
            okText={translation('Update')}
            bodyStyle={{ padding: 0 }}
            okButtonProps={{ loading }}
        >
            <div className="p-24px grid auto-rows-min gap-row-24px gap-col-16px grid-cols-[auto_1fr] wk-text-12 wk-font-regular color-$wk-v2-label-color-gray-13">
                <div className="mt-10px">{translation('Icon')}</div>
                <div>
                    <IconImageUploader
                        iconSrc={iconSrc}
                        onChange={handleIconChange}
                        error={{
                            show: !!validStatus.iconErrorText,
                            tipMessage: validStatus.iconErrorText,
                        }}
                        dataTestIds={{
                            clickButton: 'plugin-dev-icon-uploader',
                        }}
                    />
                </div>
                <div className="mt-6px">{translation('Name')}</div>
                <div>
                    <InputV2
                        dataTestIds={{
                            input: 'plugin-dev-description-input',
                        }}
                        value={edtioredName}
                        onChange={(e) => {
                            handleNameChange(e.target.value)
                        }}
                        error={{
                            show: !!validStatus.nameErrorText,
                            tipMessage: validStatus.nameErrorText,
                        }}
                    />
                </div>
                <div>ID</div>
                <div>
                    <span>{currentEditorPublishInfoPlugin.id}</span>
                </div>
            </div>
        </WKDialog>
    )
}
