import { ToKeyCode } from '../../../document/util/keycode'
import { useAppContext } from '../../../main/app-context'
import { KeyboardReceiver } from '../../../main/keyboard-receiver/component'
import { LocalVariableEditor } from './local-variable-editor'
import {
    LocalVariableEditorContext,
    POPUP_RESIZE_HANDLER_WEIGHT,
    ResizeHandler,
    useLocalVariableEditorContext,
} from './local-variable-editor-context'
import { SelectedVariablesEditor } from './selected-variables-editor'
import { useEditorOpened } from './use-view-state-hook'

function LocalVariableEditorPopupInternal() {
    const { popupSize, popupPosition, popupHandlerPointerDown, popupHandlerPointerMove, popupHandlerPointerUp } =
        useLocalVariableEditorContext()
    const onResizeHandlerPointerDown = (e: React.PointerEvent<HTMLDivElement>) => {
        e.stopPropagation()
        const element = e.currentTarget as HTMLDivElement
        element.setPointerCapture(e.pointerId)
        popupHandlerPointerDown(e.clientX, e.clientY, e.currentTarget.dataset.resizeHandler as ResizeHandler)
    }
    const onResizeHandlerPointerMove = (e: React.PointerEvent<HTMLDivElement>) => {
        e.stopPropagation()
        const element = e.currentTarget as HTMLDivElement
        if (!element.hasPointerCapture(e.pointerId)) {
            return
        }
        popupHandlerPointerMove(e.clientX, e.clientY)
    }
    const onResizeHandlerPointerUp = (e: React.PointerEvent<HTMLDivElement>) => {
        e.stopPropagation()
        const element = e.currentTarget as HTMLDivElement
        if (element.hasPointerCapture(e.pointerId)) {
            element.releasePointerCapture(e.pointerId)
        }
        popupHandlerPointerUp()
    }

    return (
        <>
            <div
                data-testid="local-variable-editor-popup"
                style={{
                    position: 'fixed',
                    zIndex: 10, // 同屏幕上各种 draggable container
                    left: popupPosition.x,
                    top: popupPosition.y,
                    width: popupSize.width,
                    height: popupSize.height,
                    backgroundColor: 'var(--wk-white)',
                    boxShadow: '0px 2px 16px 0px rgba(26, 29, 37, 0.12)',
                    borderRadius: '4px',
                }}
            >
                {/* 上 */}
                <div
                    data-testid="local-variable-editor-popup-resize-handler-n"
                    data-resize-handler="n"
                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: -POPUP_RESIZE_HANDLER_WEIGHT / 2,
                        height: POPUP_RESIZE_HANDLER_WEIGHT,
                        cursor: 'ns-resize',
                    }}
                    onPointerDown={onResizeHandlerPointerDown}
                    onPointerMove={onResizeHandlerPointerMove}
                    onPointerUp={onResizeHandlerPointerUp}
                ></div>
                {/* 下 */}
                <div
                    data-testid="local-variable-editor-popup-resize-handler-s"
                    data-resize-handler="s"
                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        bottom: -POPUP_RESIZE_HANDLER_WEIGHT / 2,
                        height: POPUP_RESIZE_HANDLER_WEIGHT,
                        cursor: 'ns-resize',
                    }}
                    onPointerDown={onResizeHandlerPointerDown}
                    onPointerMove={onResizeHandlerPointerMove}
                    onPointerUp={onResizeHandlerPointerUp}
                ></div>
                {/* 左 */}
                <div
                    data-testid="local-variable-editor-popup-resize-handler-w"
                    data-resize-handler="w"
                    style={{
                        position: 'absolute',
                        left: -POPUP_RESIZE_HANDLER_WEIGHT / 2,
                        top: 0,
                        width: POPUP_RESIZE_HANDLER_WEIGHT,
                        height: '100%',
                        cursor: 'ew-resize',
                    }}
                    onPointerDown={onResizeHandlerPointerDown}
                    onPointerMove={onResizeHandlerPointerMove}
                    onPointerUp={onResizeHandlerPointerUp}
                ></div>
                {/* 右 */}
                <div
                    data-testid="local-variable-editor-popup-resize-handler-e"
                    data-resize-handler="e"
                    style={{
                        position: 'absolute',
                        right: -POPUP_RESIZE_HANDLER_WEIGHT / 2,
                        top: 0,
                        width: POPUP_RESIZE_HANDLER_WEIGHT,
                        height: '100%',
                        cursor: 'ew-resize',
                    }}
                    onPointerDown={onResizeHandlerPointerDown}
                    onPointerMove={onResizeHandlerPointerMove}
                    onPointerUp={onResizeHandlerPointerUp}
                ></div>
                {/* 左上 */}
                <div
                    data-testid="local-variable-editor-popup-resize-handler-nw"
                    data-resize-handler="nw"
                    style={{
                        position: 'absolute',
                        left: -POPUP_RESIZE_HANDLER_WEIGHT / 2,
                        top: -POPUP_RESIZE_HANDLER_WEIGHT / 2,
                        width: POPUP_RESIZE_HANDLER_WEIGHT,
                        height: POPUP_RESIZE_HANDLER_WEIGHT,
                        cursor: 'nwse-resize',
                    }}
                    onPointerDown={onResizeHandlerPointerDown}
                    onPointerMove={onResizeHandlerPointerMove}
                    onPointerUp={onResizeHandlerPointerUp}
                ></div>
                {/* 右上 */}
                <div
                    data-testid="local-variable-editor-popup-resize-handler-ne"
                    data-resize-handler="ne"
                    style={{
                        position: 'absolute',
                        right: -POPUP_RESIZE_HANDLER_WEIGHT / 2,
                        top: -POPUP_RESIZE_HANDLER_WEIGHT / 2,
                        width: POPUP_RESIZE_HANDLER_WEIGHT,
                        height: POPUP_RESIZE_HANDLER_WEIGHT,
                        cursor: 'nesw-resize',
                    }}
                    onPointerDown={onResizeHandlerPointerDown}
                    onPointerMove={onResizeHandlerPointerMove}
                    onPointerUp={onResizeHandlerPointerUp}
                ></div>
                {/* 右下 */}
                <div
                    data-testid="local-variable-editor-popup-resize-handler-se"
                    data-resize-handler="se"
                    style={{
                        position: 'absolute',
                        right: -POPUP_RESIZE_HANDLER_WEIGHT / 2,
                        bottom: -POPUP_RESIZE_HANDLER_WEIGHT / 2,
                        width: POPUP_RESIZE_HANDLER_WEIGHT,
                        height: POPUP_RESIZE_HANDLER_WEIGHT,
                        cursor: 'nwse-resize',
                    }}
                    onPointerDown={onResizeHandlerPointerDown}
                    onPointerMove={onResizeHandlerPointerMove}
                    onPointerUp={onResizeHandlerPointerUp}
                ></div>
                {/* 左下 */}
                <div
                    data-testid="local-variable-editor-popup-resize-handler-sw"
                    data-resize-handler="sw"
                    style={{
                        position: 'absolute',
                        left: -POPUP_RESIZE_HANDLER_WEIGHT / 2,
                        bottom: -POPUP_RESIZE_HANDLER_WEIGHT / 2,
                        width: POPUP_RESIZE_HANDLER_WEIGHT,
                        height: POPUP_RESIZE_HANDLER_WEIGHT,
                        cursor: 'nesw-resize',
                    }}
                    onPointerDown={onResizeHandlerPointerDown}
                    onPointerMove={onResizeHandlerPointerMove}
                    onPointerUp={onResizeHandlerPointerUp}
                ></div>
                {/* 内容 */}
                <div
                    data-testid="local-variable-editor-popup-content"
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <LocalVariableEditor />
                </div>
            </div>
            <SelectedVariablesEditor />
        </>
    )
}

export function LocalVariableEditorPopup() {
    const service = useAppContext().variableService.localVariableEditorService
    const popupOpened = useEditorOpened()
    if (!popupOpened) {
        return null
    }
    return (
        <KeyboardReceiver
            keyCode={ToKeyCode.Esc}
            onKeydown={() => {
                service.popupToggle()
                return false
            }}
        >
            <LocalVariableEditorContext>
                <LocalVariableEditorPopupInternal />
            </LocalVariableEditorContext>
        </KeyboardReceiver>
    )
}
