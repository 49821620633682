import { Wukong } from '@wukong/bridge-proto'
import { Select } from '../../../../../../../ui-lib/src'
import classNames from 'classnames'
import { useCallback, useMemo } from 'react'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import {
    InteractionType2DestopFullLabel,
    InteractionType2MobileFullLabel,
    InteractionTypeWithMixed,
    MIXED_TYPE,
    MIXED_TYPE_LABEL,
    PrototypeInteractionWithNodeId,
    getInteractionTypeList,
} from '../../prototype-interaction/constants'
import { changeInteractionType } from '../../prototype-interaction/utils'
import { InteractionDelayInput } from './interaction-delay-input'
import { useDelayTime } from './use-delay-time'

import InteractionType = Wukong.DocumentProto.InteractionType

export const InteractionTrigger = ({
    type,
    data,
    disabledTypes,
    isPresetMobile,
    onChange,
}: {
    type: InteractionTypeWithMixed
    data: PrototypeInteractionWithNodeId[]
    disabledTypes: InteractionType[]
    isPresetMobile?: boolean
    onChange: (data: PrototypeInteractionWithNodeId[], options?: InputOptionsForUndoSquash) => void
}) => {
    const { isShowDelay, value, isMixed, formatter, onChangeDelayTime } = useDelayTime({ type, data, onChange })
    const type2Label = isPresetMobile ? InteractionType2MobileFullLabel : InteractionType2DestopFullLabel
    const menus = useMemo(() => {
        const ret: any[] = []
        if (type === MIXED_TYPE) {
            ret.push({
                value: MIXED_TYPE,
                label: MIXED_TYPE_LABEL,
                disabled: true,
            })
        }
        getInteractionTypeList().forEach((item) => {
            item.forEach((v, idx) => {
                ret.push({
                    value: v,
                    label: type2Label[v],
                    splitLineTop: idx === 0 && ret.length > 0,
                    disabled: disabledTypes.some((t) => t === v),
                })
            })
        })
        return ret
    }, [type, disabledTypes, type2Label])
    const eventName = useMemo(() => {
        return type === MIXED_TYPE ? MIXED_TYPE_LABEL : type2Label[type] ?? ''
    }, [type, type2Label])

    const onChangeTriggerType = useCallback(
        (t: InteractionType) => {
            onChange(data.map((v) => changeInteractionType(t, v)))
        },
        [data, onChange]
    )

    return (
        <>
            <div className={classNames(isShowDelay ? 'pt-10px pb-2' : 'py-10px')}>
                <Select.MinimalSingleLevel
                    value={type}
                    label={<div className="pl-4 wk-font-semibold">{eventName}</div>}
                    onChange={onChangeTriggerType}
                    dataTestIds={{
                        triggerFocus: 'interaction-trigger-select-trigger',
                    }}
                >
                    {menus.map((item) => {
                        return (
                            <Select.MinimalSingleLevel.Option
                                key={item.value}
                                value={item.value}
                                splitLineTop={item.splitLineTop}
                                disabled={item.disabled}
                                dataTestId="interaction-trigger-select-option"
                            >
                                {item.label}
                            </Select.MinimalSingleLevel.Option>
                        )
                    })}
                </Select.MinimalSingleLevel>
            </div>
            {isShowDelay ? (
                <div className="pl-4 pb-2">
                    <InteractionDelayInput
                        value={value}
                        type={type as InteractionType}
                        isMixed={isMixed}
                        formatter={formatter}
                        onChange={onChangeDelayTime}
                    />
                </div>
            ) : null}
        </>
    )
}
