/**
 * @owner: chenxiangbj@kanyun.com
 */
import { WithoutTitle, WithTitle } from './alert'

const WKAlert: {
    WithTitle: typeof WithTitle
    WithoutTitle: typeof WithoutTitle
} = {
    WithTitle,
    WithoutTitle,
}
export { WKAlert }
