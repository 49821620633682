import { translation } from './no-comments-yet.translation'
/* eslint-disable no-restricted-imports */
import { IconCommentEmpty } from '../../../../../../ui-lib/src'
import classes from './no-comments-yet.module.less'

export function NoCommentsYet() {
    return (
        <div className={classes.container}>
            <div className={classes.icon}>
                <IconCommentEmpty />
            </div>
            <div className={classes.text}>{translation('ClickAnywhereIn')}</div>
        </div>
    )
}
