import { Wukong } from '@wukong/bridge-proto'
import { useCallback, useState } from 'react'
import { flushSync } from 'react-dom'
import { useViewCallbackWithDefaultValue } from '../../view-state-bridge/use-view-state'

const DEFAULT_VIEWPORT = { height: 1920, width: 1080, x: 0, y: 0, zoom: 1 } as const
interface Props {
    /**
     * 动态控制是否需要flushSync, 只在真正需要viewport的条件下才开启以尽量减少flushSync的调用
     * const needSync = xxxState.show && featureSwitchManager.isEnable('xxx')
     * useImperativeViewport({ sync: needSync})
     */
    sync: boolean
}

/**
 * 注意: 使用这个方法是应该尽可能的放到底层子组件中以缩小影响范围, 不要放在顶层组件或者Context中否则可能会造成性能问题
 */
export function useImperativeViewport({ sync = true }: Props) {
    const [viewport, setViewport] = useState<Wukong.DocumentProto.IVisibleViewport>(DEFAULT_VIEWPORT)
    const onViewportUpdate = useCallback(
        (v: Wukong.DocumentProto.IVisibleViewport) => {
            if (!sync) {
                setViewport(v)
                return
            }

            /**
             * NOTE: 放在microtask中, 因为在useLayoutEffect中可能会触发viewport更新会导致下面的错误:
             * flushSync was called from inside a lifecycle method. React cannot flush when React is already rendering. Consider moving this call to a scheduler task or micro task
             */
            queueMicrotask(() => {
                flushSync(() => {
                    setViewport(v)
                })
            })
        },
        [sync]
    )
    useViewCallbackWithDefaultValue('currentViewport', DEFAULT_VIEWPORT, onViewportUpdate)
    return { viewport }
}
