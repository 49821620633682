import { useCallback } from 'react'
import { ScrubbableInput, ScrubbableInputProps } from './components/scrubbable-input'
import { formateToInteger } from './utils/format'
import { Value } from './utils/type'
import { useBaseFormatterTool } from './utils/use-base-formatter-tool'
import { valueFilter10, valueFilterUserConfig } from './utils/value-filter'

/**
 * @param props
 * @returns
 * @description 整数
 */
export function ScrubbableInputInteger(props: ScrubbableInputProps) {
    const baseFormatter = useBaseFormatterTool(props)
    const parse = useCallback(
        (inputValue: string, oldValue?: Value): Value => {
            const res = baseFormatter.parse(inputValue, oldValue)
            if (Array.isArray(res)) {
                return res.map(Math.round)
            } else {
                return Math.round(res)
            }
        },
        [baseFormatter]
    )

    return (
        <ScrubbableInput
            formatter={{ format: formateToInteger, parse }}
            valueFilter={!props.notUseUserConfig ? valueFilterUserConfig : valueFilter10}
            resolution={1}
            {...props}
            allowedUnits={'px'}
        />
    )
}
