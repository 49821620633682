export enum ForceUpgradeScene {
    Editor = 'EDITOR',
    Workbench = 'WORKBENCH',
}

export const ForceUpgradeScene2BodyValue = {
    [ForceUpgradeScene.Editor]: 'editor',
    [ForceUpgradeScene.Workbench]: 'workbench',
}

export const ForceUpgradeScene2Label = {
    [ForceUpgradeScene.Editor]: '编辑器',
    [ForceUpgradeScene.Workbench]: '工作台',
}

export const ForceSceneItems = [
    { value: ForceUpgradeScene.Editor, children: ForceUpgradeScene2Label[ForceUpgradeScene.Editor] },
    { value: ForceUpgradeScene.Workbench, children: ForceUpgradeScene2Label[ForceUpgradeScene.Workbench] },
]
