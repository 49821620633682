import { FeatureSwitchConfig } from '../feature-switch-config'

const Config: FeatureSwitchConfig = {
    name: 'plugin-api-font-load-check',
    owner: '',
    description: '',
    strategies: [],
    alwaysDisableInTests: false,
}
export default Config
