import { Wukong } from '@wukong/bridge-proto'
import { default as classnames, default as classNames } from 'classnames'
import type { HTMLAttributes } from 'react'
import { forwardRef, useCallback } from 'react'
import { toCSSBackgroundColor } from '../../design/blend/color-picker/utils/color-translate'
import styles from './index.module.less'

interface ColorVarIconProps extends HTMLAttributes<HTMLDivElement> {
    opacity?: number
    rgb: Wukong.DocumentProto.IRGB
    colorSpace: 'srgb' | 'display-p3'
    disabledTab?: boolean
    size?: 16 | 24
    isStroke?: boolean
    focusNoneBorder?: boolean
}
const _ColorVarIcon = (props: ColorVarIconProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const {
        rgb,
        colorSpace,
        className,
        opacity = 1,
        disabledTab,
        size = 16,
        isStroke,
        onClick,
        onKeyDown,
        onMouseDown,
        focusNoneBorder,
        ...otherProps
    } = props
    const innerStyle: any = { backgroundColor: toCSSBackgroundColor(rgb, colorSpace), height: size, width: size }

    const _onKeyDown = useCallback(
        (e: any) => {
            if (e.key === 'Enter') {
                e.stopPropagation()
                onMouseDown?.(e)
            }
            onKeyDown?.(e)
        },
        [onMouseDown, onKeyDown]
    )

    const _onClick = useCallback(
        (e: any) => {
            e.stopPropagation()
            onClick?.(e)
        },
        [onClick]
    )

    const _onMouseDown = useCallback(
        (e: any) => {
            e.preventDefault()
            onMouseDown?.(e)
        },
        [onMouseDown]
    )

    return (
        <div
            className={classNames(
                styles.container,
                focusNoneBorder ? styles.outlineNone : styles.focusWithinOutlineBorder,
                className
            )}
            data-testid="color-var-icon"
            {...otherProps}
            onKeyDown={_onKeyDown}
            onClick={_onClick}
            onMouseDown={_onMouseDown}
            ref={ref}
        >
            {disabledTab ? null : <div tabIndex={0} className={styles.focusElement}></div>}
            <div className={styles.pure} style={innerStyle}>
                <div className={classnames(styles.transparent, 'mosaic-3')} style={{ opacity: 1 - opacity }}></div>
                {isStroke && <div className={styles.whiteBlock} />}
            </div>
        </div>
    )
}

export const ColorVarIcon = forwardRef(_ColorVarIcon)
