import { Wukong } from '@wukong/bridge-proto'
import { FC, PropsWithChildren, useEffect, useMemo } from 'react'
import { useDragContext } from './context'
import styles from './index.module.less'
import { caculatePlaceNodeIndent, getItemIndent } from './util'

interface LayerPanelDragSeeAllLayersItemProps {
    item: Wukong.DocumentProto.IVLayerPanelSeeAllLayers
    preItem: Wukong.DocumentProto.VLayerPanelItemInfo | undefined
    nextItem: Wukong.DocumentProto.VLayerPanelItemInfo | undefined
    index: number
}

const SingleIndentMovement = 16

export const LayerPanelDragSeeAllLayersItem: FC<PropsWithChildren<LayerPanelDragSeeAllLayersItemProps>> = ({
    children,
    item,
    nextItem,
    index,
}) => {
    const { handleMouseUp: onMouseUp, dragTargetInfo, selectionMinIndentRef, setDropInfo } = useDragContext()

    const minIndent = useMemo(() => getItemIndent(nextItem), [nextItem])

    // 由位移改变的层级
    const movementIndent = useMemo<number>(() => {
        if (!dragTargetInfo) {
            return 0
        }
        const indent = dragTargetInfo.movementX / SingleIndentMovement
        return indent < 0 ? Math.ceil(indent) : Math.floor(indent)
    }, [dragTargetInfo])

    // 是否展示引导线
    const showLine = useMemo(() => dragTargetInfo?.nodeId === item.seeAllLayerId, [dragTargetInfo, item.seeAllLayerId])

    // 引导线的层级
    const currentIndent = useMemo(() => {
        const currentItemIndent = item.indent
        if (!showLine) {
            return 0
        } else {
            return caculatePlaceNodeIndent(currentItemIndent, minIndent, selectionMinIndentRef.current!, movementIndent)
        }
    }, [item.indent, minIndent, movementIndent, selectionMinIndentRef, showLine])

    // setDropInfo
    useEffect(() => {
        if (!showLine) {
            return
        }
        setDropInfo({
            indent: currentIndent,
            itemInfo: Wukong.DocumentProto.VLayerPanelItemInfo.create({
                type: Wukong.DocumentProto.LayerPanelItemType.LAYER_PANEL_ITEM_TYPE_SEE_ALL_LAYERS,
                layerPanelSeeAllLayersInfo: item,
            }),
        })
    }, [currentIndent, item, setDropInfo, showLine])

    return (
        <div onMouseUp={onMouseUp}>
            {children}
            {showLine && (
                <div
                    className={styles['drag-line']}
                    data-testid={`drag-line-bottom-${index}`}
                    style={{ marginLeft: SingleIndentMovement + currentIndent * SingleIndentMovement + 'px' }}
                />
            )}
        </div>
    )
}
