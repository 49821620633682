import { DELETE, GET, POST, PUT } from '@tutor/network-core'
import {
    InvitationValidateInfoVO,
    InvitationVO,
    JoinApplicationWithTeamBriefVO,
    OrgID,
    ResourceMemberVO,
    ResourceType,
    RoleStatus,
    SeatType,
    TeamID,
    TeamWithAuthorityVO,
    TransferOwnerVO,
    UserID,
    type UserRoleId,
} from '../interface/type'
import { omitByValue } from '../util/omit-by-value'
import { BaseCommonRequest } from './base-request'
import { RequestResponseErrorHandler } from './error-handler'
import { translation } from './user-roles.translation'

// 查询成员列表
@GET
export class GetMembersV3 extends BaseCommonRequest<ResourceMemberVO> {
    constructor(private resourceType: ResourceType, private resourceId: string, private queryString: string) {
        super()
    }

    public override requestArgument() {
        return {
            resourceType: this.resourceType,
            resourceId: this.resourceId,
            queryString: this.queryString,
        }
    }

    public override requestUrl() {
        return 'user-roles/v3/members'
    }
    public override requestDidFailed(): boolean {
        return false
    }
}

//批量邀请用户(允许未注册用户)
/**
 * @deprecated 请使用 share/batchInviteUserRequest.ts
 */
@POST
export class BatchInviteUser extends BaseCommonRequest<InvitationVO[]> {
    constructor(
        private resourceType: ResourceType,
        private resourceId: string,
        private emails: string[],
        private role: RoleStatus,
        private seatType?: SeatType,
        private inviteRedirectUrl?: string
    ) {
        super()
    }

    public override requestBody() {
        return omitByValue({
            resourceType: this.resourceType,
            resourceId: this.resourceId,
            emails: this.emails,
            role: this.role,
            seatType: this.seatType,
            inviteRedirectUrl: this.inviteRedirectUrl,
        })
    }

    public override requestUrl() {
        return 'user-roles/invitations/batch/v2'
    }
}

//批量邀请用户成为组织成员(允许未注册用户)
@POST
export class BatchInviteOrgUser extends BaseCommonRequest<InvitationVO[]> {
    constructor(private orgId: string, private emails: string[], private readonly seatType?: SeatType) {
        super()
    }

    public override requestBody() {
        return omitByValue({
            resourceType: ResourceType.Organization,
            resourceId: this.orgId,
            emails: this.emails,
            seatType: this.seatType,
        })
    }

    public override requestUrl() {
        return 'user-roles/invitations/org/batch/v2'
    }
}

// 高级企业版企业管理员或者工作区管理员批量邀请用户直接成为成员
@POST
export class BatchInviteOrgUserInEnterprise extends BaseCommonRequest {
    constructor(
        private orgId: string,
        private emails: string[],
        private seatType?: SeatType,
        private workspaceId?: string
    ) {
        super()
    }

    public override requestBody() {
        return omitByValue({
            resourceType: ResourceType.Organization,
            resourceId: this.orgId,
            emails: this.emails,
            seatType: this.seatType,
            workspaceId: this.workspaceId,
        })
    }

    public override requestUrl() {
        return 'user-roles/invitations/enterprise/batch'
    }
}

// 重新邀请用户
@POST
export class ReinviteUser extends BaseCommonRequest {
    constructor(
        private readonly resourceType: ResourceType,
        private readonly resourceId: string,
        private readonly userId: UserID
    ) {
        super()
    }

    public override requestBody() {
        return omitByValue({
            resourceType: this.resourceType,
            resourceId: this.resourceId,
            userId: this.userId,
        })
    }

    public override requestUrl() {
        return 'user-roles/invitation/resend'
    }
}

//批量邀请用户时查询席位、权限相关的校验信息，只能查文档
@POST
export class GetBatchInviteValidateInfo extends BaseCommonRequest<InvitationValidateInfoVO> {
    constructor(
        private readonly resourceType: ResourceType,
        private readonly resourceId: string,
        private readonly emails: string[]
    ) {
        super()
    }

    public override requestBody() {
        return omitByValue({
            resourceType: this.resourceType,
            resourceId: this.resourceId,
            emails: this.emails,
        })
    }

    public override requestUrl() {
        return 'user-roles/invitations/batch/v2/info'
    }
}

// 删除成员
@DELETE
export class DeleteMember extends BaseCommonRequest {
    constructor(private userRoleId: UserRoleId) {
        super()
    }

    public override requestUrl() {
        return `user-roles/${this.userRoleId}`
    }
}

// 通过teamId删除成员
@DELETE
export class DeleteMemberByTeamId extends BaseCommonRequest {
    constructor(private teamId: TeamID) {
        super()
    }

    public override requestUrl() {
        return `user-roles/team/${this.teamId}`
    }
}

// 更新成员信息
@PUT
export class UpdateMemberRole extends BaseCommonRequest {
    constructor(private userRoleId: UserRoleId, private newRole: RoleStatus) {
        super()
    }

    public override requestUrl() {
        return `user-roles/role`
    }

    public override requestBody() {
        return {
            userRoleId: this.userRoleId,
            newRole: this.newRole,
        }
    }
}

// 更新成员信息
@PUT
export class AssignUserToWorkspace extends BaseCommonRequest {
    constructor(private userIds: number[], private workspaceId: string, private orgId: OrgID) {
        super()
    }

    public override requestUrl() {
        return `user-roles/assign-to-workspace`
    }

    public override requestBody() {
        return {
            userIds: this.userIds,
            workspaceId: this.workspaceId,
            orgId: this.orgId,
        }
    }
}
// 批量更新企业正式成员的工作区
@PUT
export class AssignUserToWorkspace2 extends BaseCommonRequest {
    constructor(private orgId: OrgID, private workspaceId: string, private userIds: UserID[]) {
        super()
    }

    public override requestUrl() {
        return `user-roles/assign-user-to-workspace`
    }

    public override requestBody() {
        return {
            userIds: this.userIds,
            workspaceId: this.workspaceId,
            orgId: this.orgId,
        }
    }
}

// 转移所有者权限
@PUT
export class UpdateOwner extends BaseCommonRequest {
    constructor(private toUserId: number, private resourceType: ResourceType, private resourceId: string) {
        super()
    }

    public override requestUrl() {
        return `user-roles/owner`
    }

    public override requestBody() {
        return {
            toUserId: this.toUserId,
            resourceType: this.resourceType,
            resourceId: this.resourceId,
        }
    }
}

// 用户通过团队链接加入团队
@GET
export class JoinTeamByInviteLink extends BaseCommonRequest<TeamWithAuthorityVO> {
    constructor(private key: string) {
        super()
    }

    public override requestUrl() {
        return `user-roles/invitation-links/teams/${this.key}`
    }
}

// 添加组织管理员
@POST
export class AddOrgAdmin extends BaseCommonRequest {
    constructor(private orgId: OrgID, private userId: UserID) {
        super()
    }

    public override requestUrl() {
        return `user-roles/org/${this.orgId}/${this.userId}`
    }
}

// 移除组织管理员
@DELETE
export class DeleteOrgAdmin extends BaseCommonRequest {
    constructor(private orgId: OrgID, private userId: UserID) {
        super()
    }

    public override requestUrl() {
        return `user-roles/org/${this.orgId}/${this.userId}`
    }
}

//组织管理员成为无主团队owner
@POST
export class OrgAdminBecameTeamOwner extends BaseCommonRequest {
    constructor(private orgId: OrgID, private teamId: string) {
        super()
    }

    public override requestUrl() {
        return `user-roles/org/${this.orgId}/team/${this.teamId}/owner`
    }
}

//组织管理员成为无主团队owner
@POST
export class TeamMemberBecameTeamOwner extends BaseCommonRequest {
    constructor(private teamId: string, private transferOwnerVO: TransferOwnerVO) {
        super()
    }

    public override requestUrl() {
        return `user-roles/team/${this.teamId}/owner`
    }
    public override requestBody() {
        return this.transferOwnerVO
    }
}

@PUT
export class JoinTeam extends BaseCommonRequest<{ applicationId: number; joined: boolean }> {
    constructor(private teamId: string, private role: RoleStatus, private reason?: string) {
        super()
    }

    public override requestUrl() {
        return `user-roles/teams/join`
    }
    public override requestBody() {
        return {
            teamId: this.teamId,
            role: this.role,
            reason: this.reason,
        }
    }
    public override requestDidFailed(e: any): boolean {
        const { status, businessStatus } = RequestResponseErrorHandler(e)
        if (status == 400 || businessStatus == 75000) {
            window.dispatchEvent(
                new CustomEvent('request-error', {
                    detail: { statusCode: 400, message: translation('RequestedTooFrequently') },
                })
            )
        } else {
            window.dispatchEvent(
                new CustomEvent('request-error', {
                    detail: { statusCode: status },
                })
            )
        }
        return false
    }
}

@DELETE
export class DeleteJoinTeam extends BaseCommonRequest {
    constructor(private applicationId: number) {
        super()
    }

    public override requestUrl() {
        return `user-roles/teams/join`
    }
    public override requestBody() {
        return {
            applicationId: this.applicationId,
        }
    }
}

@POST
export class ResolveJoinTeam extends BaseCommonRequest {
    constructor(private applicationId: number, private approve: boolean, private role: RoleStatus) {
        super()
    }

    public override requestUrl() {
        return `user-roles/teams/join/resolve`
    }
    public override requestBody() {
        return {
            applicationId: this.applicationId,
            approve: this.approve,
            role: this.role,
        }
    }
    public override requestDidFailed(e: any): boolean {
        const { status } = RequestResponseErrorHandler(e)
        if (status == 400) {
            window.dispatchEvent(
                new CustomEvent('request-error', {
                    detail: { statusCode: 400, message: translation('ThisRequestHas') },
                })
            )
        } else {
            window.dispatchEvent(
                new CustomEvent('request-error', {
                    detail: { statusCode: status },
                })
            )
        }
        return false
    }
}

@GET
export class GetJoinTeamInfo extends BaseCommonRequest<JoinApplicationWithTeamBriefVO> {
    constructor(private applicationId: number) {
        super()
    }

    public override requestUrl() {
        return `user-roles/teams/join`
    }
    public override requestArgument() {
        return {
            applicationId: this.applicationId,
        }
    }
}

@PUT
export class BatchJoinTeam extends BaseCommonRequest {
    constructor(private ids: string[]) {
        super()
    }

    public override requestUrl() {
        return `user-roles/teams/join/batch`
    }
    public override requestBody() {
        return {
            ids: this.ids,
        }
    }
}

@DELETE
export class BatchQuitTeam extends BaseCommonRequest {
    constructor(private ids: string[]) {
        super()
    }

    public override requestUrl() {
        return `user-roles/team/batch`
    }
    public override requestBody() {
        return {
            ids: this.ids,
        }
    }
}
