/* eslint-disable no-restricted-imports */
import { CompositeDecorator, ContentBlock, ContentState } from 'draft-js'
import React from 'react'
import { CommentUser } from '../../../../kernel/interface/comment'
import { MessageType } from '../type'
import classes from './decorator.module.less'
import { filterFnEntity } from './draft-editor-utils'

export interface DraftDecoratorComponentProps {
    blockKey: string
    children?: JSX.Element
    contentState: ContentState
    decoratedText: string
    //   dir?: HTMLDir,
    end: number
    entityKey: string
    offsetKey: string
    start: number
}

export interface MentionData {
    user: CommentUser
}

export interface EmojiData {
    emojiName: string
}

function createDecorator() {
    return new CompositeDecorator([
        {
            strategy: findMentionEntities,
            component: Mention,
        },
    ])
}

function findMentionEntities(
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
) {
    contentBlock.findEntityRanges(filterFnEntity(`${MessageType.Mention}`, contentState), callback)
}

function Mention(props: DraftDecoratorComponentProps) {
    // const data: MentionData = props.contentState.getEntity(props.entityKey).getData()
    // 这里一定要使用props.children， 否则极可能出现光标不对。或者就是自己参考页面的dom结构搭建一个结构一样的dom尤其是dom上的自定义属性
    return (
        <span className={classes.mention} spellCheck={false}>
            {props.children}
        </span>
    )
}

// 由于这个@的行为优化比较复杂 这里先不优化
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Mention2(props: DraftDecoratorComponentProps) {
    const onClick = (e: React.MouseEvent) => {
        // 阻止双击产生的默认选中行为
        e.preventDefault()
        e.stopPropagation()
        // 手动选中提及文字
        const selection = window.getSelection()
        if (selection) {
            selection.removeAllRanges()
            const range = document.createRange()
            range.selectNodeContents(e.currentTarget as Node)
            selection.addRange(range)
        }
    }
    const onPointerDown = (e: React.PointerEvent) => {
        // 阻止按下引起的插入光标
        e.stopPropagation()
    }
    // const data: MentionData = props.contentState.getEntity(props.entityKey).getData()
    // 这里一定要使用props.children， 否则极可能出现光标不对。或者就是自己参考页面的dom结构搭建一个结构一样的dom尤其是dom上的自定义属性
    return (
        <span
            className={classes.mention}
            spellCheck={false}
            onClick={onClick}
            onPointerDown={onPointerDown}
            // contentEditable={false}
        >
            {props.children}
        </span>
    )
}

export const decorator = createDecorator()
