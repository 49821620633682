import { Suspense, lazy } from 'react'
import { IconLoading24 } from '../../../../../../../ui-lib/src'
import { IconItemsContainerProps } from './icon-items-container'

const IconItemsContainer = lazy(() =>
    import('./icon-items-container').catch(() => {
        // 产品定义懒加载网络错误时暂时一直显示 loading
        return { default: () => <Loading /> }
    })
)
const Loading = () => (
    <div className="w-382px h-100% relative">
        <div className="absolute top-15% left-50% translate-x--50%">
            <IconLoading24 className="animate-spin" />
        </div>
    </div>
)

const LazyIconItemsContainer = (props: IconItemsContainerProps) => {
    return (
        <Suspense fallback={<Loading />}>
            <IconItemsContainer {...props} />
        </Suspense>
    )
}

export default LazyIconItemsContainer
