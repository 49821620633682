import {
    CheckComponentStyleMovement,
    HandleNeverMovableNodesCommand,
    SetNotificationMovedComponentStyleNodeIdsCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { createSwitchTask } from '../../../../../../util/src/abort-controller/signal-task'
import { signalInterval, signalTimeout } from '../../../../../../util/src/abort-controller/timers'
import { TraceableAbortSignal } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import { NodeId } from '../../../../document/node/node'
import { Bridge } from '../../../../kernel/bridge/bridge'
import { ComponentId, ComponentSetId, StyleVOId } from '../../../../kernel/interface/component-style-library-id'
import { CheckComponentMovementResponse } from '../../../../kernel/interface/library'
import { DocID } from '../../../../kernel/interface/type'
import { CheckComponentMovement } from '../../../../kernel/request/library'
import { ServiceClass } from '../../../../kernel/util/service-class'

// 组件移动相关
export class LibraryMovementService extends ServiceClass {
    // 定期轮询是否移动的 nodeId2ComponentId & nodeId2StyleId
    private intervalCheckNeedNotificationMovedNodeId2ComponentStyleIdMapV2$ = {
        nodeId2ComponentId: new Map<NodeId, ComponentId | ComponentSetId>(),
        nodeId2StyleId: new Map<NodeId, StyleVOId>(),
    }
    private requestCheckComponentMovement: (
        nodeId2ComponentId: Map<string, string>,
        nodeId2StyleId: Map<string, string>,
        callback: (res: CheckComponentMovementResponse) => void
    ) => Promise<void>

    constructor(
        private docId: DocID,
        private readonly commandInvoker: CommandInvoker,
        protected override readonly bridge: Bridge,
        private readonly signal: TraceableAbortSignal
    ) {
        super(bridge)
        this.requestCheckComponentMovement = createSwitchTask(
            this.signal,
            async (
                signal: TraceableAbortSignal,
                nodeId2ComponentId: Map<string, string>,
                nodeId2StyleId: Map<string, string>,
                callback: (res: CheckComponentMovementResponse) => void
            ) => {
                await new CheckComponentMovement(
                    {
                        nodeId2ComponentId: Object.fromEntries(nodeId2ComponentId),
                        nodeId2StyleId: Object.fromEntries(nodeId2StyleId),
                    },
                    this.docId
                )
                    .startWithSignal(signal)
                    .then(callback)
                    .catch(() => {})
            }
        )
        this.initBindJsCall()
        this.initIntervalCheck()
    }

    private initBindJsCall = () => {
        this.bridge.bind(CheckComponentStyleMovement, this.onCheckComponentMovementCallback, {
            signal: this.signal,
        })
    }

    // 将 组件移动信息 上报 server
    private onCheckComponentMovementCallback = async (arg: Wukong.DocumentProto.IArg_checkComponentStyleMovement) => {
        try {
            const res = await new CheckComponentMovement(
                {
                    nodeId2ComponentId: arg.nodeId2ComponentId ?? {},
                    nodeId2StyleId: arg.nodeId2StyleId ?? {},
                },
                this.docId
            ).start()

            if (this.isDestroy) {
                return
            }

            if (res.neverMovableNodeIds.length) {
                this.commandInvoker.DEPRECATED_invokeBridge(HandleNeverMovableNodesCommand, {
                    value: res.neverMovableNodeIds,
                })
            }

            const newValue = {
                nodeId2ComponentId: new Map<NodeId, ComponentId | ComponentSetId>(),
                nodeId2StyleId: new Map<NodeId, StyleVOId>(),
            }

            arg.needNotificationMovedNodeIds?.forEach((nodeId) => {
                if (!res.validNodeId2DocId[nodeId]) {
                    return
                }
                if (arg.nodeId2ComponentId?.[nodeId]) {
                    newValue.nodeId2ComponentId.set(nodeId, arg.nodeId2ComponentId[nodeId])
                    return
                }
                if (arg.nodeId2StyleId?.[nodeId]) {
                    newValue.nodeId2StyleId.set(nodeId, arg.nodeId2StyleId[nodeId])
                    return
                }
            })

            if (newValue.nodeId2ComponentId.size) {
                this.commandInvoker.DEPRECATED_invokeBridge(SetNotificationMovedComponentStyleNodeIdsCommand, {
                    moveNodeType: Wukong.DocumentProto.NotificationMoveNodeType.NOTIFICATION_MOVE_NODE_TYPE_COMPONENT,
                    nodeIds: [...newValue.nodeId2ComponentId.keys()],
                })
            }
            if (newValue.nodeId2StyleId.size) {
                this.commandInvoker.DEPRECATED_invokeBridge(SetNotificationMovedComponentStyleNodeIdsCommand, {
                    moveNodeType: Wukong.DocumentProto.NotificationMoveNodeType.NOTIFICATION_MOVE_NODE_TYPE_STYLE,
                    nodeIds: [...newValue.nodeId2StyleId.keys()],
                })
            }
            this.intervalCheckNeedNotificationMovedNodeId2ComponentStyleIdMapV2$ = newValue
        } catch (e) {
            console.error(e)
        }
    }
    // 轮询检测当前提示移动的组件是否仍可以合法移动（场景：组件被粘贴至多个文档，其中一个文档发布移动后，其他文档需要关闭移动提示）
    private initIntervalCheck = () => {
        signalTimeout(
            () => {
                signalInterval(
                    () => {
                        const { nodeId2ComponentId, nodeId2StyleId } =
                            this.intervalCheckNeedNotificationMovedNodeId2ComponentStyleIdMapV2$
                        if (!(nodeId2ComponentId.size || nodeId2StyleId.size)) {
                            return
                        }
                        this.requestCheckComponentMovement(nodeId2ComponentId, nodeId2StyleId, (res) => {
                            if (res.neverMovableNodeIds.length) {
                                const value = this.intervalCheckNeedNotificationMovedNodeId2ComponentStyleIdMapV2$
                                res.neverMovableNodeIds.forEach((nodeId) => {
                                    value.nodeId2ComponentId.delete(nodeId)
                                    value.nodeId2StyleId.delete(nodeId)
                                })
                                this.intervalCheckNeedNotificationMovedNodeId2ComponentStyleIdMapV2$ = value
                                this.commandInvoker.DEPRECATED_invokeBridge(HandleNeverMovableNodesCommand, {
                                    value: res.neverMovableNodeIds,
                                })
                            }
                        })
                    },
                    8000,
                    { signal: this.signal }
                )
            },
            5000,
            { signal: this.signal }
        )
    }
}
