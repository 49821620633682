/* eslint-disable no-restricted-imports */
import { isNil } from 'lodash-es'
import { useCallback } from 'react'
import { NumberUnit } from '../../../../../document/node/node'
import { FormattedInputCustomProps } from '../components/formatted-input'
import { clampMinMax } from './clamp'
import { parseString } from './parse-string'
import { TextValueUnit } from './type'
import { useUserConfig } from './user-config'

export interface LetterSpacingFormatterToolProps extends Pick<FormattedInputCustomProps<any>, 'notUseUserConfig'> {
    min?: number
    max?: number
}

export function useLetterSpacingFormatterTool(props: LetterSpacingFormatterToolProps) {
    const userConfig = useUserConfig()

    const getBigNudgeAmount = useCallback(
        (unit: NumberUnit) => {
            if (unit !== NumberUnit.Percent && !props.notUseUserConfig) {
                return userConfig.bigNudgeAmount
            }
            return 10
        },
        [props.notUseUserConfig, userConfig.bigNudgeAmount]
    )

    const getSmallNudgeAmount = useCallback(
        (unit: NumberUnit) => {
            if (unit !== NumberUnit.Percent && !props.notUseUserConfig) {
                return userConfig.smallNudgeAmount
            }
            return 1
        },
        [props.notUseUserConfig, userConfig.smallNudgeAmount]
    )

    const parse = useCallback((inputValue: string, oldValue?: TextValueUnit): TextValueUnit => {
        let unit =
            inputValue.indexOf('%') !== -1
                ? NumberUnit.Percent
                : inputValue.indexOf('px') !== -1
                ? NumberUnit.Pixels
                : undefined
        inputValue = inputValue.replace(new RegExp('%|px', 'ig'), '')
        const value = parseString(inputValue, NaN)
        unit = unit !== undefined ? unit : isNil(oldValue?.unit) ? NumberUnit.Pixels : oldValue!.unit
        return { value, unit }
    }, [])

    const format = useCallback((value: TextValueUnit): string => {
        const numValue = parseFloat(value.value.toFixed(2))
        switch (value.unit) {
            case NumberUnit.Percent:
                return `${numValue}%`
            case NumberUnit.Pixels:
                return `${numValue} px`
            default:
                return `${numValue}`
        }
    }, [])

    const increment = useCallback(
        (value: TextValueUnit, isIncrement: boolean, shiftKey: boolean): TextValueUnit => {
            const bigNudgeAmount = getBigNudgeAmount(value.unit)
            const smallNudgeAmount = getSmallNudgeAmount(value.unit)
            let numValue = value.value
            if (shiftKey) {
                numValue = isIncrement ? numValue + bigNudgeAmount : numValue - bigNudgeAmount
            } else {
                numValue = isIncrement ? numValue + smallNudgeAmount : numValue - smallNudgeAmount
            }
            return { value: numValue, unit: value.unit }
        },
        [getBigNudgeAmount, getSmallNudgeAmount]
    )

    const clamp = useCallback(
        (value: TextValueUnit): TextValueUnit => {
            const numValue = clampMinMax(value.value, { min: props.min, max: props.max })
            return { value: numValue, unit: value.unit }
        },
        [props.max, props.min]
    )

    return { parse, format, increment, clamp }
}
