import { imageDataToPremulAlphaBytes } from './image-lib/common'

let _isOffscreenCanvasSupport: boolean | null = null

const PNG_1x1 =
    'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+P///38ACfsD/QVDRcoAAAAASUVORK5CYII='

function base64ToBytes(base64: string) {
    const binaryString = atob(base64)
    const bytes = new Uint8Array(binaryString.length)
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i)
    }
    return bytes
}

export async function isOffscreenCanvasSupport() {
    if (_isOffscreenCanvasSupport !== null) {
        return _isOffscreenCanvasSupport
    }

    try {
        const canvas = new OffscreenCanvas(1, 1)
        const context = canvas.getContext('2d', {
            alpha: true,
            // 使用 CPU 渲染
            willReadFrequently: true,
        })
        if (!context) {
            throw Error('context is null')
        }
        const bytes = base64ToBytes(PNG_1x1)
        const blob = new Blob([bytes], {
            type: 'image/png',
        })
        const bitmap = await createImageBitmap(blob)
        context.drawImage(bitmap, 0, 0)
        const imageData = context.getImageData(0, 0, 1, 1)
        if (imageData.data.length !== 4) {
            throw Error('image data length invalid')
        }
        if (imageData.data[0] !== 255 || imageData.data[1] !== 255 || imageData.data[2] !== 255) {
            throw Error('image data not white')
        }
        _isOffscreenCanvasSupport = true
    } catch (_e) {
        _isOffscreenCanvasSupport = false
    }
    return _isOffscreenCanvasSupport
}

export function imageBitmapToBytesUsingOffscreenCanvas(bitmap: ImageBitmap): Uint8Array | null {
    const canvas = new OffscreenCanvas(bitmap.width, bitmap.height)
    const context = canvas.getContext('2d', {
        alpha: true,
        // 使用 CPU 渲染
        willReadFrequently: true,
    })
    if (!context) {
        return null
    }
    context.drawImage(bitmap, 0, 0)
    const imageData = context.getImageData(0, 0, bitmap.width, bitmap.height)
    return imageDataToPremulAlphaBytes(imageData)
}
