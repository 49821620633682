import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { isNil } from 'lodash-es'
import { HTMLAttributes, useCallback } from 'react'
import { cmdChangePopupState } from '../../../../document/command/document-command'
import { cmdBackground } from '../../../../document/command/page-command'
import { PopupStateType, SolidPaint } from '../../../../document/node/node'
import { useAppContext } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { Title } from '../../atom/title/title'
import { useRenderColorSpace } from '../../color-profile'
import { VariableModeInspect } from '../variable-mode/variable-mode-inspect'
import { VariableModeSelect } from '../variable-mode/variable-mode-select'
import { BackgroundSolidPaint } from './background-solid-paint'

interface BackgroundProps extends HTMLAttributes<HTMLDivElement> {}

export const Background = ({ className }: BackgroundProps) => {
    const paint = useViewState('currentPageBackground')?.paint as SolidPaint | undefined
    const command = useCommand()

    const popupState = useViewState('popupState')
    const isOpen = popupState && popupState.type === PopupStateType.POPUP_STATE_TYPE_CURRENT_PAGE_BACKGROUND_EDIT
    const colorSpace = useRenderColorSpace()

    const variableModeEntries = useViewState('variableModeEntriesInPage')
    const service = useAppContext().variableService
    const onChangeVariableMode = useCallback(
        (value: { variableSetIds?: string[]; modeId?: string; type?: 'value' | 'mixed' | 'auto' }) => {
            switch (value.type) {
                case undefined:
                case 'mixed':
                    break
                case 'auto':
                    if (!isNil(value.variableSetIds)) {
                        service.resetDefaultVariableModeBySetForPage(value.variableSetIds)
                    }
                    break
                case 'value':
                    if (!isNil(value.variableSetIds) && !isNil(value.modeId)) {
                        service.setVariableModeBySetForPage(value.variableSetIds, value.modeId)
                    }
                    break
            }
        },
        [service]
    )

    const onChangeModalVisible = (visible: boolean) => {
        if (visible) {
            command.invoke(cmdChangePopupState, {
                type: PopupStateType.POPUP_STATE_TYPE_CURRENT_PAGE_BACKGROUND_EDIT,
                reciprocalIndex: -1,
                multiPopup: [],
            })
        } else {
            command.invoke(cmdChangePopupState, {
                type: PopupStateType.POPUP_STATE_TYPE_NONE,
                reciprocalIndex: -1,
                multiPopup: [],
            })
        }
    }

    const onChangeSolidPaint = (value: SolidPaint) => {
        command.invoke(cmdBackground, value)
    }
    if (!paint) {
        return null
    }

    return (
        <div className={classnames('pt-8px pb-12px', className)} data-testid="page-current-background">
            <Title>
                <Title.Left>{translation('Page')}</Title.Left>
                {variableModeEntries?.show && (
                    <Title.Right>
                        <VariableModeSelect
                            source="page"
                            data={variableModeEntries as Wukong.DocumentProto.IVariableModeEntriesState}
                            onChange={onChangeVariableMode}
                        />
                    </Title.Right>
                )}
            </Title>

            <BackgroundSolidPaint
                selected={isOpen}
                colorSpace={colorSpace}
                paint={paint as SolidPaint}
                isOpen={isOpen}
                onChangeModalVisible={onChangeModalVisible}
                onChange={onChangeSolidPaint}
            />
            <VariableModeInspect
                source={'page'}
                data={variableModeEntries as Wukong.DocumentProto.IVariableModeEntriesState}
                onChange={onChangeVariableMode}
            />
        </div>
    )
}
