/* eslint-disable no-restricted-imports */
import { HistoryVersion } from '../../../../kernel/interface/history-version'
import { UserBriefVO } from '../../../../kernel/interface/type'

export enum IconType {
    'Null',
    'Normal',
    'Public',
    'CancelPublic',
}
export enum ItemType {
    'CurrentItem',
    'UserItem',
    'AutoItem',
    'AutoItemGroup',
}

export interface CurrentItem {
    type: ItemType.CurrentItem
    iconType: IconType.Normal
    name: string
    versionId: number
    readonly origin: Pick<Readonly<HistoryVersion>, 'id'>
}

export interface UserItem {
    type: ItemType.UserItem
    iconType: IconType
    name: string
    description: string
    time: number
    versionId: number
    primaryUser?: UserBriefVO
    secondaryUsers: ReadonlyArray<UserBriefVO>
    readonly origin: Readonly<HistoryVersion>
}

export interface AutoItem extends Omit<UserItem, 'type' | 'name' | 'description'> {
    type: ItemType.AutoItem
}

export interface AutoItemGroup {
    type: ItemType.AutoItemGroup
    iconType: IconType.Normal
    children: AutoItem[]
}
