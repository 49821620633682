import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    nestedInstance: '内部实例',
    deleteProp: '删除属性',
} as const

export const enTranslation = {
    nestedInstance: 'Nested instances',
    deleteProp: 'Delete property',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
