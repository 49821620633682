import classNames from 'classnames'
import { CopyableRow } from '../comp/copyable-row'
import style from './index.module.less'

export function InspectPropertyCopyableRow({ name, value }: { name: string; value: string }) {
    if (!name || !value) {
        return null
    }

    return (
        <div className={classNames(style.propertyRowNarrow, style.basePropertyRow)}>
            <span className={style.propertyName}>{name}</span>
            <div className={style.properyPairsContainer}>
                <CopyableRow copyValue={`${value}`}>
                    <span className={style.propertyValue}>{value}</span>
                </CopyableRow>
            </div>
        </div>
    )
}
