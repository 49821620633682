import { SVGAttributes } from 'react'

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconAbsolutePositionInstance = () => (
    <svg width="16" height="16" stroke="none" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0H4V1H1V4H0V0Z" fill="currentColor" />
        <path d="M16 0H12V1H15V4H16V0Z" fill="currentColor" />
        <path d="M0 16V12H1V15H4V16H0Z" fill="currentColor" />
        <path d="M16 16H12V15H15V12H16V16Z" fill="currentColor" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.29289 3.05086C7.68342 2.66033 8.31658 2.66033 8.70711 3.05086L12.9497 7.2935C13.3403 7.68402 13.3403 8.31719 12.9497 8.70771L8.70711 12.9504C8.31658 13.3409 7.68342 13.3409 7.29289 12.9504L3.05025 8.70771C2.65973 8.31719 2.65973 7.68402 3.05025 7.2935L7.29289 3.05086ZM8 3.75796L12.2426 8.0006L8 12.2432L3.75736 8.0006L8 3.75796Z"
            fill="currentColor"
        />
    </svg>
)
/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconComponent16 = () => (
    <svg width="16" height="16" stroke="none" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.47487 5.18186L2 7.65674L4.47487 10.1316L6.94975 7.65674L4.47487 5.18186ZM7.65685 8.36385L5.18198 10.8387L7.65685 13.3136L10.1317 10.8387L7.65685 8.36385ZM5.18214 4.47496L7.65684 2.00026L10.1315 4.47496L7.65684 6.94966L5.18214 4.47496ZM10.8388 5.18186L8.36396 7.65674L10.8388 10.1316L13.3137 7.65674L10.8388 5.18186Z"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconExampleImage = () => (
    <svg width="16" height="16" stroke="none" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5 4H3.5L3.5 12H3.85848L9.86907 8.30117C10.2925 8.04059 10.8431 8.13069 11.1614 8.51265L12.5 10.119V4ZM12.5 11.681L10.3932 9.15283L5.76652 12H12.5V11.681ZM3.5 3C2.94772 3 2.5 3.44772 2.5 4V12C2.5 12.5523 2.94771 13 3.5 13H12.5C13.0523 13 13.5 12.5523 13.5 12V4C13.5 3.44772 13.0523 3 12.5 3H3.5ZM6.5 7C6.77614 7 7 6.77614 7 6.5C7 6.22386 6.77614 6 6.5 6C6.22386 6 6 6.22386 6 6.5C6 6.77614 6.22386 7 6.5 7ZM6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconHorizentalCenter = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" stroke="none" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 13H9L9 3L7 3L7 13Z" fill="currentColor" />
        <path d="M3 4H5V12H3V4Z" fill="currentColor" />
        <path d="M11 5H13V11H11V5Z" fill="currentColor" />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconHorizentalTop = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" stroke="none" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 11H3V3H5L5 11ZM13 9H11V3H13L13 9ZM7 13H9L9 3H7L7 13Z"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconVerticalCenter = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" stroke="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 3V5L4 5L4 3H12ZM11 11V13H5L5 11L11 11ZM13 9V7L3 7V9L13 9Z"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconVerticalRight = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" stroke="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 3V5L5 5L5 3H13ZM13 11V13H7V11L13 11ZM13 9V7L3 7V9L13 9Z"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconVerticalLeft = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" stroke="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 3V5L3 5V3H11ZM9 11V13H3V11L9 11ZM13 9V7L3 7V9L13 9Z"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconInstance = (props: SVGAttributes<SVGSVGElement>) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        stroke="none"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.2426 8.0006L8 3.75796L3.75736 8.0006L8 12.2432L12.2426 8.0006ZM8.70711 3.05086C8.31658 2.66033 7.68342 2.66033 7.29289 3.05086L3.05025 7.2935C2.65973 7.68402 2.65973 8.31719 3.05025 8.70771L7.29289 12.9504C7.68342 13.3409 8.31658 13.3409 8.70711 12.9504L12.9497 8.70771C13.3403 8.31719 13.3403 7.68402 12.9497 7.2935L8.70711 3.05086Z"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconVariant16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            transform="matrix(0.707107 0.707107 -0.707107 0.707107 8 3.05005)"
            d="M0 1C0 0.447715 0.447715 0 1 0L6 0C6.55228 0 7 0.447715 7 1L7 6C7 6.55228 6.55228 7 6 7L1 7C0.447715 7 0 6.55228 0 6L0 1Z"
            fill="currentColor"
        />
    </svg>
)

/**
 * @deprecated 不要在使用这里的图标了，详见 https://wukong.yuanfudao.biz/storybook/index.html?path=/docs/components-%E5%9F%BA%E7%A1%80%E7%BB%84%E4%BB%B6-%E5%9B%BE%E6%A0%87--docs
 */
export const IconType16 = (props: SVGAttributes<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
        <path
            transform="matrix(1 0 0 1 3 3)"
            d="M5.5 1L10 1L10 0L5.5 0L4.5 0L0 0L0 1L4.5 1L4.5 10L5.5 10L5.5 1Z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
)
