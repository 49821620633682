/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { CommentId } from '../../../../kernel/interface/comment'
import { featureSwitchManager } from '../../../../kernel/switch'
import { DeepRequired, ViewStateBridge } from '../../../../view-state-bridge'
import { CameraPosition, ClusterId, Position, WorldPosition } from '../type'
import { calculateOverlayPosition } from './comment-overlay-position-service'

export type _CommentId = CommentId | string | undefined | null
type PositionViewState = Wukong.DocumentProto.IVCommentPositionViewState
type Viewport = DeepRequired<Wukong.DocumentProto.IViewport>

type AnchorMinorAnchorCallback = (anchorPosition: Position, minorAnchorPosition: Position) => void
type AnchorCallback = (anchorPosition: Position) => void
type MinorAnchorCallback = (minorAnchorPosition: Position) => void
type CoordinateCallback = (canvasDeltaX: number, canvasDeltaY: number) => void
type ScalePositionCallback = (viewport: Viewport) => void

enum BubbleMap {
    Anchor = 'anchor',
    MinorAnchor = 'minorAnchor',
    AnchorMinorAnchor = 'anchorMinorAnchor',
}
interface BubbleTypeMap {
    [BubbleMap.Anchor]: Map<string, AnchorCallback>
    [BubbleMap.MinorAnchor]: Map<string, MinorAnchorCallback>
    [BubbleMap.AnchorMinorAnchor]: Map<string, AnchorMinorAnchorCallback>
}

export class PositionService {
    private nextRegisterKey = 0
    private draftId = -1
    private lastViewportZoom = -1
    private lastCoordinate: { canvasDeltaX: number; canvasDeltaY: number } | null = null
    private registerBubbleMap: Map<string, BubbleTypeMap> = new Map()
    private registerCoordinateMap: Map<string, CoordinateCallback> = new Map()
    private scalePositionCallbacks: Map<string, ScalePositionCallback> = new Map()
    private lastBubbleWorldPositionMap: Map<string, WorldPosition> = new Map() // 最新的 comment 和 cluster 的世界位置

    constructor(private readonly viewStateBridge: ViewStateBridge) {}

    public init = () => {
        this.viewStateBridge.register('commentsPosition', this.viewStateCommentsPositionCallback)
        this.viewStateBridge.register('draftComment', this.viewStateDraftCommentCallback)
        this.viewStateBridge.register('currentViewport', this.viewStateCurrentViewportCallback)
    }

    public destroy = () => {
        this.viewStateBridge.unregister('commentsPosition', this.viewStateCommentsPositionCallback)
        this.viewStateBridge.unregister('draftComment', this.viewStateDraftCommentCallback)
        this.viewStateBridge.unregister('currentViewport', this.viewStateCurrentViewportCallback)
    }

    private viewStateCommentsPositionCallback = (
        overlayPosition: Wukong.DocumentProto.IVCommentsPositionViewState | null
    ) => {
        const pins = overlayPosition?.pins
        const clusters = overlayPosition?.clusters

        for (const [id, position] of Object.entries(pins ?? {})) {
            const { anchorPosition, minorAnchorPosition } = formatOverlayPosition2RangeParams(position)
            this.updateBubble(id, anchorPosition, minorAnchorPosition)

            this.lastBubbleWorldPositionMap.set(
                id,
                this.transCameraPositionToWorldPosition({
                    cameraX: position.translateX ?? 0,
                    cameraY: position.translateY ?? 0,
                })
            )
        }
        for (const [clusterId, cluster] of Object.entries(clusters ?? {})) {
            const anchorPosition = {
                left: cluster.cameraX ?? 0,
                top: cluster.cameraY ?? 0,
                hasAnchor: false,
                anchorTranslateX: 0,
                anchorTranslateY: 0,
            }

            this.lastBubbleWorldPositionMap.set(
                clusterId,
                this.transCameraPositionToWorldPosition({
                    cameraX: cluster.cameraX ?? 0,
                    cameraY: cluster.cameraY ?? 0,
                })
            )
            this.updateBubble(clusterId, anchorPosition, undefined)
        }
    }
    private viewStateDraftCommentCallback = (state: Wukong.DocumentProto.IVDraftCommentViewState | null) => {
        if (!state?.display) {
            return
        }
        const position = {
            translateX: state.commentPosition?.translateX,
            translateY: state.commentPosition?.translateY,
            hasAnchor: state.hasAnchor,
            anchorTranslateX: state.commentAnchorPosition?.translateX,
            anchorTranslateY: state.commentAnchorPosition?.translateY,
        }
        const { anchorPosition, minorAnchorPosition } = formatOverlayPosition2RangeParams(position)
        this.updateBubble(undefined, anchorPosition, minorAnchorPosition)
    }

    private viewStateCurrentViewportCallback = (viewport: Viewport) => {
        if (this.lastViewportZoom != viewport.zoom) {
            for (const callback of this.scalePositionCallbacks.values()) {
                callback(viewport)
            }
            this.lastViewportZoom = viewport.zoom
        }
        const { originX, originY, translateX, translateY } = calculateOverlayPosition(viewport)
        const canvasDeltaX = originX + translateX
        const canvasDeltaY = originY + translateY
        this.lastCoordinate = { canvasDeltaX, canvasDeltaY }
        this.updateCoordinate(canvasDeltaX, canvasDeltaY)
    }

    private getUniqueRegisterKey = () => {
        return `${this.nextRegisterKey++}`
    }
    /*
     *   bubble start
     */
    private getBubbleMap = (key: string) => {
        let map = this.registerBubbleMap.get(key)
        if (!map) {
            map = {
                [BubbleMap.Anchor]: new Map(),
                [BubbleMap.MinorAnchor]: new Map(),
                [BubbleMap.AnchorMinorAnchor]: new Map(),
            }
            this.registerBubbleMap.set(key, map)
        }
        return map
    }

    private getBubbleId = (id: _CommentId) => {
        return `${id ?? this.draftId}`
    }

    private getLastBubblePosition(id: _CommentId | ClusterId): PositionViewState | undefined {
        const bubbleId = this.getBubbleId(id)
        const commentsPosition = this.viewStateBridge.getDefaultValue('commentsPosition')
        const pins = commentsPosition?.pins
        const clusters = commentsPosition?.clusters
        if (pins && bubbleId in pins) {
            const commentPosition = pins[bubbleId]
            return commentPosition
        }

        if (clusters && bubbleId in clusters) {
            const clusterPosition = clusters[bubbleId]
            return {
                translateX: clusterPosition.cameraX,
                translateY: clusterPosition.cameraY,
            }
        }

        const draftComment = this.viewStateBridge.getDefaultValue('draftComment')
        if (draftComment?.display) {
            const draftPosition = {
                translateX: draftComment.commentPosition?.translateX,
                translateY: draftComment.commentPosition?.translateY,
                hasAnchor: draftComment.hasAnchor,
                anchorTranslateX: draftComment.commentAnchorPosition?.translateX,
                anchorTranslateY: draftComment.commentAnchorPosition?.translateY,
            }
            return draftPosition
        }

        return undefined
    }

    private registerScalePosition(callback: ScalePositionCallback) {
        const key = this.getUniqueRegisterKey()
        this.scalePositionCallbacks.set(key, callback)
        const viewport = this.viewStateBridge.getDefaultValue('currentViewport')
        if (viewport) {
            callback(viewport)
        }
        return key
    }

    private unRegisterScalePosition(key: string) {
        this.scalePositionCallbacks.delete(key)
    }

    private transCameraPositionToWorldPosition = (cameraPos: CameraPosition): WorldPosition => {
        const viewport = this.viewStateBridge.getDefaultValue('currentViewport')
        if (!viewport) {
            return { worldX: cameraPos.cameraX, worldY: cameraPos.cameraY }
        }
        return { worldX: cameraPos.cameraX / viewport.zoom, worldY: cameraPos.cameraY / viewport.zoom }
    }

    public unregisterBubble = (key: string) => {
        for (const [_, map] of this.registerBubbleMap) {
            if (map[BubbleMap.Anchor].delete(key)) {
                break
            }
            if (map[BubbleMap.MinorAnchor].delete(key)) {
                break
            }
            if (map[BubbleMap.AnchorMinorAnchor].delete(key)) {
                break
            }
        }
    }

    /**
     * 在 viewport 快速缩放时会频繁创建或卸载的气泡，需要根据 viewport.zoom 变化实时更新位置，
     * 以解决在「viewState.commentsPositon 删除评论」和「react 卸载组件」的间隙，缩放视窗导致的位置偏移问题
     */
    public registerUpdateBubblePositionWhenScale = (ref: HTMLElement | null, bubbleId: CommentId | ClusterId) => {
        const key = this.registerScalePosition((viewport) => {
            const bubbleWorldPosition = this.lastBubbleWorldPositionMap.get(`${bubbleId}`)
            if (bubbleWorldPosition && ref) {
                if (featureSwitchManager.isEnabled('comment-position-transform')) {
                    if (typeof bubbleId === 'string') {
                        //聚合气泡默认为 dom 左上角，需要偏移居中，修改成 transform 需要手动计算偏移
                        ref.style.transform = `translate(${bubbleWorldPosition.worldX * viewport.zoom - 32}px, ${
                            bubbleWorldPosition.worldY * viewport.zoom - 32
                        }px)`
                    } else {
                        ref.style.transform = `translate(${bubbleWorldPosition.worldX * viewport.zoom}px, ${
                            bubbleWorldPosition.worldY * viewport.zoom
                        }px)`
                    }
                } else {
                    ref.style.left = `${bubbleWorldPosition.worldX * viewport.zoom}px`
                    ref.style.top = `${bubbleWorldPosition.worldY * viewport.zoom}px`
                }
            }
        })
        return key
    }

    public unRegisterUpdateBubblePositionWhenScale = (key: string) => {
        this.unRegisterScalePosition(key)
    }

    public registerBubbleAnchor = (id: _CommentId, callback: AnchorCallback) => {
        const commentId = this.getBubbleId(id)
        const registerKey = this.getUniqueRegisterKey()
        const _callback = callback2safe(callback)
        const bubbleMap = this.getBubbleMap(commentId)
        bubbleMap[BubbleMap.Anchor].set(registerKey, _callback)
        const position = this.getLastBubblePosition(commentId)
        if (position) {
            const { anchorPosition } = formatOverlayPosition2RangeParams(position)
            _callback(anchorPosition)
        }
        return registerKey
    }

    public registerBubbleMinorAnchor = (id: _CommentId, callback: MinorAnchorCallback) => {
        const commentId = this.getBubbleId(id)
        const registerKey = this.getUniqueRegisterKey()
        const _callback = callback2safe(callback)
        const bubbleMap = this.getBubbleMap(commentId)
        bubbleMap[BubbleMap.MinorAnchor].set(registerKey, _callback)

        const position = this.getLastBubblePosition(commentId)
        if (position) {
            const { minorAnchorPosition } = formatOverlayPosition2RangeParams(position)
            minorAnchorPosition && _callback(minorAnchorPosition)
        }
        return registerKey
    }

    public registerBubbleAnchorMinorAnchor = (id: _CommentId, callback: AnchorMinorAnchorCallback) => {
        const commentId = this.getBubbleId(id)
        const registerKey = this.getUniqueRegisterKey()
        const _callback = callback2safe(callback)
        const bubbleMap = this.getBubbleMap(commentId)
        bubbleMap[BubbleMap.AnchorMinorAnchor].set(registerKey, _callback)

        const position = this.getLastBubblePosition(commentId)
        if (position) {
            const { anchorPosition, minorAnchorPosition } = formatOverlayPosition2RangeParams(position)
            minorAnchorPosition && _callback(anchorPosition, minorAnchorPosition)
        }
        return registerKey
    }

    public updateBubble = (id: _CommentId, anchorPosition?: Position | null, minorAnchorPosition?: Position | null) => {
        const commentId = this.getBubbleId(id)
        const bubbleMap = this.registerBubbleMap.get(commentId)
        if (!bubbleMap) {
            return
        }
        if (anchorPosition) {
            bubbleMap[BubbleMap.Anchor].forEach((callback) => callback(anchorPosition))
        }
        if (minorAnchorPosition) {
            bubbleMap[BubbleMap.MinorAnchor].forEach((callback) => callback(minorAnchorPosition))
        }
        if (anchorPosition && minorAnchorPosition) {
            bubbleMap[BubbleMap.AnchorMinorAnchor].forEach((callback) => callback(anchorPosition, minorAnchorPosition))
        }
    }
    /*
     *   bubble end
     */

    public unregisterCoordinate = (key: string) => {
        this.registerCoordinateMap.delete(key)
    }

    public registerCoordinate = (callback: CoordinateCallback) => {
        const registerKey = this.getUniqueRegisterKey()
        const _callback = callback2safe(callback)
        this.registerCoordinateMap.set(registerKey, _callback)
        if (this.lastCoordinate) {
            _callback(this.lastCoordinate.canvasDeltaX, this.lastCoordinate.canvasDeltaY)
        }
        return registerKey
    }
    private updateCoordinate = (canvasDeltaX: number, canvasDeltaY: number) => {
        this.registerCoordinateMap.forEach((callback) => callback(canvasDeltaX, canvasDeltaY))
    }
}

function callback2safe<T extends (...res: any[]) => any>(callback: T) {
    const _callback = (...res: any[]) => {
        try {
            return callback(...res)
        } catch (e) {}
    }
    return _callback as unknown as T
}

function formatOverlayPosition2RangeParams(positionViewState: PositionViewState) {
    const anchorPosition: Position = {
        left: positionViewState.translateX ?? 0,
        top: positionViewState.translateY ?? 0,
    }
    const minorAnchorPosition: Position | undefined = positionViewState.hasAnchor
        ? {
              left: positionViewState.anchorTranslateX ?? 0,
              top: positionViewState.anchorTranslateY ?? 0,
          }
        : undefined
    return { anchorPosition, minorAnchorPosition }
}
