/* eslint-disable no-restricted-imports */
import {
    UpdatePaintStyleNodeDescriptionCommand,
    UpdatePaintStyleNodeNameCommand,
    UpdatePaintStyleNodePaintsCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ExplicitUndefined } from '../../../../../../../ui-lib/src'
import { CommitType } from '../../../../../document/command/commit-type'
import { cmdChangePopupState } from '../../../../../document/command/document-command'
import {
    cmdChangeAttrPanelStyleEditorState,
    wasmShowStartEditingHandlerForPaintStyle,
} from '../../../../../document/command/node-props-command'
import {
    AttrPanelStyleEditorState,
    ColorStop,
    ImagePaint,
    Paint,
    PopupState,
    PopupStateType,
    RGB,
    Transform,
} from '../../../../../document/node/node'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'
import { getDefaultSolidPaint, isGradientType, switchPaintType } from '../../../../utils/switch-paint-type'
import { useGradientEdit } from '../../../../utils/use-gradient-edit'
import { useImageEdit } from '../../../../utils/use-image-edit'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { useRenderColorSpace } from '../../../color-profile'
import { useEditingPaintStyle } from '../../styles/hooks/use-editing-style'
import { modifiedMultiPopup } from '../../styles/modified-multi-popup'
import { CommentAttributeTitle } from '../../styles/style-panel/common-attribute-title/comment-attribute-title'
import { CommonNameDescription } from '../../styles/style-panel/common-name-description/common-name-description'
import { CommonThumbnail } from '../../styles/style-panel/common-thumbnail/common-thumbnail'
import {
    PaintStyleData,
    PaintStylePanelList,
    PaintStylePanelListProps,
} from '../../styles/style-panel/paint-style-panel/paint-style-panel'
import PaintType = Wukong.DocumentProto.PaintType

export interface ColorStyleEditorProps {
    isRemoteStyle: boolean | undefined
    styleId: string
}

export function ColorStyleEditor(props: ColorStyleEditorProps) {
    const { styleId } = props
    const adding = useRef<boolean>(false)
    const { endEditingImage } = useImageEdit()
    const { endEditingGradient } = useGradientEdit()
    const paintStyleNode = useEditingPaintStyle()
    const command = useCommand()
    const [styleNodeInfo, setStyleNodeInfo] = useState<PaintStyleData>(getPaintStyleNodeInfo(paintStyleNode))
    const [selectPaints, setSelectPaints] = useState<Paint[]>([])
    const colorSpace = useRenderColorSpace()
    const attrPanelStyleEditorState = useViewState('attrPanelStyleEditorState')
    const popupState = useViewState('popupState')

    const selectPaintIndexList = useMemo(
        () => attrPanelStyleEditorState?.selectReverseIndex.slice() ?? [],
        [attrPanelStyleEditorState]
    )

    const wasmShowStartEditingHandler = useCallback(
        (
            type: 'image' | 'gradient',
            freshPopupState?: PopupState,
            freshAttrPanelStyleEditorState?: AttrPanelStyleEditorState
        ) => {
            command.invoke(
                wasmShowStartEditingHandlerForPaintStyle,
                type,
                freshPopupState,
                freshAttrPanelStyleEditorState
            )
        },
        [command]
    )

    const updateStyleEditorState = useCallback(() => {
        const styleNode = paintStyleNode
        if (!styleNode) {
            return
        }
        const _styleNodeInfo = getPaintStyleNodeInfo(styleNode)
        setStyleNodeInfo(_styleNodeInfo)
        if (!attrPanelStyleEditorState) {
            return
        }
        const { selectReverseIndex } = attrPanelStyleEditorState
        const reversePaints = _styleNodeInfo.paints.slice()
        if (selectReverseIndex) {
            const _selectPaints = reversePaints.filter((_, index) => selectReverseIndex.includes(index))
            setSelectPaints(_selectPaints)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attrPanelStyleEditorState, paintStyleNode, styleId])

    const setDocumentPopupState = useCallback(
        (openPopup: boolean, reciprocalIndex = -1) => {
            const _popupState = modifiedMultiPopup(
                popupState,
                PopupStateType.POPUP_STATE_TYPE_PAINT_STYLE_EDITOR_PAINT,
                openPopup
                    ? {
                          type: PopupStateType.POPUP_STATE_TYPE_PAINT_STYLE_EDITOR_PAINT,
                          reciprocalIndex,
                          multiPopup: [],
                      }
                    : undefined
            )
            command.invoke(cmdChangePopupState, _popupState)

            // FIXME: 临时修复方案, popupState存在写后读的问题，所以需要返回最新的用于调用wasmShowStartEditingHandlerForPaintStyle
            return _popupState
        },
        [command, popupState]
    )

    const updatePaints = useCallback(
        (newPaints: Wukong.DocumentProto.IPaint[]) => {
            command.invokeBridge(
                CommitType.Noop,
                UpdatePaintStyleNodePaintsCommand,
                Wukong.DocumentProto.UpdatePaintStyleNodePaintsParam.create({
                    styleId,
                    paints: newPaints.slice(),
                })
            )
        },
        [command, styleId]
    )

    const updatePaintProperties = useCallback(
        (paint: Partial<Wukong.DocumentProto.IPaint>, index: number) => {
            const paints = styleNodeInfo.paints
            if (paint.type !== undefined) {
                paints.splice(index, 1, paint as Paint)
            } else {
                Object.assign(paints[index], { visible: true }, paint)
            }
            updatePaints(paints)
        },
        [styleNodeInfo.paints, updatePaints]
    )

    const onChangeName = useCallback(
        (value: string) => {
            command.invokeBridge(
                CommitType.CommitUndo,
                UpdatePaintStyleNodeNameCommand,
                Wukong.DocumentProto.UpdatePaintStyleNodeNameParam.create({
                    styleId,
                    name: value,
                })
            )
        },
        [command, styleId]
    )
    const onChangeDescription = useCallback(
        (value: string) => {
            command.invokeBridge(
                CommitType.CommitUndo,
                UpdatePaintStyleNodeDescriptionCommand,
                Wukong.DocumentProto.UpdatePaintStyleNodeDescriptionParam.create({
                    styleId,
                    description: value,
                })
            )
        },
        [command, styleId]
    )
    const onClickAddPaint = useCallback(() => {
        adding.current = true
        const paints = styleNodeInfo.paints
        let newPaints: Paint[] = []
        if (paints.length === 0) {
            newPaints = [getFirstFillPaintDefault()]
        } else {
            newPaints = [...paints, getDefaultSolidPaint()]
        }
        updatePaints(newPaints)
        adding.current = false
        command.commitUndo()
    }, [command, styleNodeInfo.paints, updatePaints])

    const onClickTitle = useCallback(() => {
        if (styleNodeInfo.paints.length > 0 || adding.current) {
            return
        }
        onClickAddPaint()
    }, [onClickAddPaint, styleNodeInfo.paints.length])

    const onSelectChange = useCallback(
        (itemsIndex: number[]) => {
            command.invoke(cmdChangeAttrPanelStyleEditorState, {
                ...attrPanelStyleEditorState,
                selectReverseIndex: itemsIndex,
            })
        },
        [command, attrPanelStyleEditorState]
    )

    const onDragDone = useCallback(
        (items: Paint[]) => {
            const reverseIndex: number[] = []
            const _items = items.slice()
            _items.forEach((item, index) => {
                if (selectPaints.includes(item)) {
                    reverseIndex.push(index)
                }
            })
            command.invoke(cmdChangeAttrPanelStyleEditorState, {
                ...attrPanelStyleEditorState,
                selectReverseIndex: reverseIndex,
            })
            updatePaints(items)
            command.commitUndo()
        },
        [command, attrPanelStyleEditorState, updatePaints, selectPaints]
    )

    const isOpenPaintPanel = useCallback(
        (index: number) => {
            const multiPopup = popupState?.multiPopup
            if (!multiPopup || multiPopup.length === 0) {
                return false
            }
            const reciprocalIndex = index
            return multiPopup.some(
                (v) =>
                    v.type === PopupStateType.POPUP_STATE_TYPE_PAINT_STYLE_EDITOR_PAINT &&
                    v.reciprocalIndex === reciprocalIndex
            )
        },
        [popupState?.multiPopup]
    )

    const onChangeModalVisible = useCallback(
        (index: number, v: boolean) => {
            const paint = styleNodeInfo.paints[index]
            const currentPaintType = paint.type
            if (v) {
                const reciprocalIndex = index
                const freshPopupState = setDocumentPopupState(true, reciprocalIndex)
                const freshAttrPanelStyleEditorState = attrPanelStyleEditorState
                    ? { ...attrPanelStyleEditorState, selectReverseIndex: [reciprocalIndex] }
                    : undefined
                command.invoke(cmdChangeAttrPanelStyleEditorState, freshAttrPanelStyleEditorState || null)
                if (currentPaintType === PaintType.PAINT_TYPE_IMAGE_PAINT) {
                    wasmShowStartEditingHandler('image', freshPopupState, freshAttrPanelStyleEditorState)
                } else if (isGradientType(currentPaintType)) {
                    wasmShowStartEditingHandler('gradient', freshPopupState, freshAttrPanelStyleEditorState)
                }
            } else if (isOpenPaintPanel(index)) {
                command.invoke(cmdChangeAttrPanelStyleEditorState, {
                    ...attrPanelStyleEditorState,
                    selectReverseIndex: [],
                })
                setDocumentPopupState(false)
                if (currentPaintType === PaintType.PAINT_TYPE_IMAGE_PAINT) {
                    endEditingImage()
                } else if (isGradientType(currentPaintType)) {
                    endEditingGradient()
                }
            }
        },
        [
            styleNodeInfo.paints,
            isOpenPaintPanel,
            setDocumentPopupState,
            attrPanelStyleEditorState,
            command,
            wasmShowStartEditingHandler,
            endEditingImage,
            endEditingGradient,
        ]
    )

    const onChangeColor = (index: number, color: RGB, options?: InputOptionsForUndoSquash) => {
        updatePaintProperties({ color, colorVar: null }, index)
        command.commitUndo(options?.commitType)
    }

    const onChangeOpacity = (index: number, value: string | number, options?: InputOptionsForUndoSquash) => {
        updatePaintProperties({ opacity: Number(value), colorVar: null }, index)
        command.commitUndo(options?.commitType)
    }

    const onChangeVisible = (index: number, v: boolean) => {
        updatePaintProperties({ visible: v, colorVar: null }, index)
        command.commitUndo()
    }

    const onChangeBlendMode = (index: number, blendMode: Wukong.DocumentProto.BlendMode) => {
        updatePaintProperties({ blendMode, colorVar: null }, index)
        command.commitUndo()
    }

    const onDetachColorVar = (index: number) => {
        updatePaintProperties({ colorVar: null }, index)
        command.commitUndo()
    }

    const onChangeImagePaint = (index: number, data: ImagePaint) => {
        updatePaintProperties(data, index)
        command.commitUndo()
    }

    const onChangePaintType = (
        index: number,
        nextPaintType: PaintType,
        paint: Wukong.DocumentProto.IPaint | undefined | null
    ) => {
        const currentPaint = styleNodeInfo.paints[index]
        if (!currentPaint) {
            return
        }
        const newPaint = paint ? paint : switchPaintType(currentPaint, nextPaintType)
        updatePaintProperties(newPaint, index)

        if (nextPaintType === PaintType.PAINT_TYPE_IMAGE_PAINT) {
            wasmShowStartEditingHandler('image')
        } else if (currentPaint.type === PaintType.PAINT_TYPE_IMAGE_PAINT) {
            endEditingImage()
        }

        if (isGradientType(currentPaint.type) && !isGradientType(nextPaintType)) {
            endEditingGradient()
        } else if (isGradientType(nextPaintType) && !isGradientType(currentPaint.type)) {
            wasmShowStartEditingHandler('gradient')
        }
        command.commitUndo()
    }

    const onClickSub = (index: number) => {
        const newPaints = styleNodeInfo.paints.filter((v, i) => index !== i)
        updatePaints(newPaints)
        command.commitUndo()
    }

    const onChangeColorStops = (paintIndex: number, v: ColorStop[], options?: InputOptionsForUndoSquash) => {
        updatePaintProperties({ gradientStops: v }, paintIndex)
        command.commitUndo(options?.commitType)
    }

    const onChangeTransform = (paintIndex: number, v: Transform) => {
        updatePaintProperties({ gradientTransform: v }, paintIndex)
        command.commitUndo()
    }

    const onChangeColorVar = (paintIndex: number, v: Wukong.DocumentProto.IVariableAliasData) => {
        updatePaintProperties({ ...getDefaultSolidPaint(), colorVar: v }, paintIndex)
        command.commitUndo()
    }

    useEffect(() => {
        updateStyleEditorState()
    }, [updateStyleEditorState])

    return (
        <div data-testid={'color-style-editor'}>
            <CommonThumbnail type="paint" data={styleNodeInfo.paints} />
            <CommonNameDescription
                isReadOnly={props.isRemoteStyle}
                name={styleNodeInfo?.name}
                description={styleNodeInfo?.description}
                onChangeName={onChangeName}
                onChangeDescription={onChangeDescription}
                key={styleNodeInfo?.id}
                testId={'paint-style-panel-name-description'}
            />
            <CommentAttributeTitle
                grayTheme={!styleNodeInfo.paints.length}
                onClickTitle={onClickTitle}
                onClickAddIcon={onClickAddPaint}
                disabledIcon={props.isRemoteStyle}
            />
            <PaintStylePanelList<ExplicitUndefined<PaintStylePanelListProps>>
                isRemoteStyle={props.isRemoteStyle}
                colorSpace={colorSpace}
                paintStyleData={styleNodeInfo}
                selectPaintIndexList={selectPaintIndexList}
                onSelectChange={onSelectChange}
                onDragDone={onDragDone}
                isOpenPaintPanel={isOpenPaintPanel}
                onChangeModalVisible={onChangeModalVisible}
                onChangeColor={onChangeColor}
                onChangeOpacity={onChangeOpacity}
                onChangeVisible={onChangeVisible}
                onChangeBlendMode={onChangeBlendMode}
                onChangeImagePaint={onChangeImagePaint}
                onChangePaintType={onChangePaintType}
                onClickSub={onClickSub}
                onChangeColorStops={onChangeColorStops}
                onChangeTransform={onChangeTransform}
                onChangeColorVar={onChangeColorVar}
                onDetachColorVar={onDetachColorVar}
            ></PaintStylePanelList>
        </div>
    )
}

export function getFirstFillPaintDefault() {
    return Object.assign(getDefaultSolidPaint(), {
        color: { r: 196, g: 196, b: 196 },
        opacity: 1,
    })
}

export function getPaintStyleNodeInfo(node?: Wukong.DocumentProto.IVPaintStyleNode | null): PaintStyleData {
    const _paintStyleNodeInfo: PaintStyleData = { id: '', name: '', description: '', paints: [] }
    if (node) {
        _paintStyleNodeInfo.id = node.node
        _paintStyleNodeInfo.name = node.name
        _paintStyleNodeInfo.description = node.description ?? ''
        _paintStyleNodeInfo.paints = node.paints.slice() as unknown as Paint[]
        _paintStyleNodeInfo.variables = node.variables
    }
    return _paintStyleNodeInfo
}
