/* eslint-disable no-restricted-imports */
import { useCallback } from 'react'
import { useCommand } from '../../../../../main/app-context'
import { MainMenuItem } from './type'

export function useMenu() {
    const command = useCommand()

    const onChange = useCallback(
        (item: MainMenuItem, options: { e: React.MouseEvent | React.KeyboardEvent }) => {
            item.customNode?.handler?.(options.e)
            if (item.customNode?.autoCommit) {
                command.commitUndo()
            }
        },
        [command]
    )

    return { onChange }
}
