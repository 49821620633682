import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { uniqWith } from 'lodash-es'
import { useCallback, useMemo, useState } from 'react'
import { useViewState } from '../../../../../view-state-bridge'
import {
    getOtherAxisLabel,
    getWeight,
    isItalAxisItalic,
    isItalic,
    isSlntAxisItalic,
    tryGetAxisOpticalSize,
    tryGetAxisWeight,
} from '../../../../utils/inspect-text-helper'
import { toFixed } from '../../../../utils/to-fixed'
import { CopyableRow } from '../common/copyable-row'
import { ViewAll } from '../common/view-all'
import style from '../inspect.module.less'
import { translation } from './index.translation'
import textSpecialStyle from './text.module.less'

interface TextProperty {
    styleDisplayData?: { name: string; description: string }
    weight: number
    italic: boolean
    localizedStyle: string
    family: string
    textStyle: string
    fontSize: string
    lineHeight: string
    letterSpacing: string
    paragraphSpacing: string
    textAlignHorizontal: Wukong.DocumentProto.TextAlignHorizontal
    detachOpticalSizeFromFontSize: boolean
    fontVariations: Wukong.DocumentProto.IFontVariation[]
}

const getProperyList = (segment: TextProperty) => {
    const result = [
        {
            name: translation('Font'),
            value: segment.family,
        },
        {
            name: translation('Weight'),
            value: String(toFixed(tryGetAxisWeight(segment.fontVariations) ?? segment.weight, 2)),
        },
    ]

    if (segment.italic || isItalAxisItalic(segment.fontVariations) || isSlntAxisItalic(segment.fontVariations)) {
        result.push({
            name: translation('Style'),
            value: translation('Italic'),
        })
    }

    result.push(
        {
            name: translation('Size'),
            value: segment.fontSize,
        },
        {
            name: translation('LineHeight'),
            value: segment.lineHeight,
        }
    )

    if (segment.letterSpacing) {
        result.push({
            name: translation('Letter'),
            value: segment.letterSpacing,
        })
    }

    if (segment.paragraphSpacing) {
        result.push({
            name: translation('ParagraphSpacing'),
            value: segment.paragraphSpacing,
        })
    }

    let alignHorizontalDisplay = ''
    switch (segment.textAlignHorizontal) {
        case Wukong.DocumentProto.TextAlignHorizontal.TEXT_ALIGN_HORIZONTAL_LEFT:
            alignHorizontalDisplay = translation('Left')
            break
        case Wukong.DocumentProto.TextAlignHorizontal.TEXT_ALIGN_HORIZONTAL_CENTER:
            alignHorizontalDisplay = translation('Center')
            break
        case Wukong.DocumentProto.TextAlignHorizontal.TEXT_ALIGN_HORIZONTAL_RIGHT:
            alignHorizontalDisplay = translation('Right')
            break
        default:
            break
    }

    if (alignHorizontalDisplay !== translation('Left')) {
        result.push({
            name: translation('Align'),
            value: alignHorizontalDisplay,
        })
    }

    const opszSize = tryGetAxisOpticalSize(segment.detachOpticalSizeFromFontSize, segment.fontVariations)
    if (opszSize != null) {
        result.push({
            name: translation('OpticalSize'),
            value: toFixed(opszSize, 2) + 'px',
        })
    }

    const otherAxisLabel = getOtherAxisLabel(segment.fontVariations)
    if (otherAxisLabel) {
        result.push({
            name: translation('Variation'),
            value: otherAxisLabel,
        })
    }

    const { styleDisplayData, weight, fontSize } = segment
    return [result, { styleDisplayData, weight, fontSize }] as const
}

export function DevModeInspectText() {
    const viewState = useViewState('devModeInspectTextViewState')

    const [limit, setLimit] = useState<number>(2)
    const limitChange = useCallback((_limit: number) => setLimit(_limit), [])
    const styleDisplayDataMap = viewState?.textStyleDisplayDataMap
    const styledTextSegments = useMemo(() => {
        return uniqWith(
            (viewState?.textStyles || [])
                .map((style_) => {
                    return {
                        styleDisplayData: styleDisplayDataMap?.[style_.inner.textStyleId],
                        weight: getWeight(style_.inner.fontName, viewState?.fontMetaData),
                        italic: isItalic(style_.inner.fontName, viewState?.fontMetaData),
                        paragraphSpacing: viewState?.paragraphSpacing ?? '',
                        localizedStyle: style_.inner.fontName.style,
                        family: style_.inner.fontName.family,
                        textStyle: style_.inner.fontName.style,
                        fontSize: style_.fontSize,
                        lineHeight: style_.lineHeight,
                        letterSpacing: style_.letterSpacing,
                        textAlignHorizontal: viewState!.textAlignHorizontal,
                        detachOpticalSizeFromFontSize: style_.inner.detachOpticalSizeFromFontSize,
                        fontVariations: style_.inner.fontVariations ?? [],
                    }
                })
                .map((segment) => getProperyList(segment))
                .sort((a, b) => {
                    const orderA = a[1].styleDisplayData?.name ? 1 : 0
                    const orderB = b[1].styleDisplayData?.name ? 1 : 0
                    // 没有名字的常规文本在上，有名字的样式文本在下
                    return orderA - orderB
                }),
            (a, b) => {
                return (
                    a[0].length == b[0].length &&
                    a[0].every((val, idx) => val.name === b[0][idx].name && val.value === b[0][idx].value)
                )
            }
        )
    }, [viewState, styleDisplayDataMap])

    if (!viewState?.show || styledTextSegments.length == 0) {
        return null
    }
    return (
        <div className={style.panel} data-testid="dev-mode-inspect-text">
            <div className={style.panelTitle}>
                <div className={style.title}>{translation('Typography')}</div>
            </div>
            {styledTextSegments.map(([proList, extraInfo], idx) => {
                if (idx + 1 <= limit) {
                    return (
                        <div key={idx} className={style.segmentsProperty}>
                            {extraInfo.styleDisplayData?.name && (
                                <div className={`${textSpecialStyle.textStyleTitleBar}`}>
                                    <span className={textSpecialStyle.textStyleTitle}>{translation('Ag')}</span>
                                    <div className={textSpecialStyle.textStyleName}>
                                        <CopyableRow
                                            style={{ padding: '2px 4px' }}
                                            copyValue={extraInfo.styleDisplayData?.name}
                                        >
                                            <span className={style.propertyValue}>
                                                {extraInfo.styleDisplayData?.name}
                                            </span>
                                        </CopyableRow>
                                    </div>
                                </div>
                            )}

                            <div
                                className={
                                    extraInfo.styleDisplayData?.name
                                        ? textSpecialStyle.textStyleBodyWithDivider
                                        : textSpecialStyle.textStyleBody
                                }
                            >
                                <div>
                                    {extraInfo.styleDisplayData?.description && (
                                        <div tabIndex={-1} className={textSpecialStyle.textStyleDescription}>
                                            {extraInfo.styleDisplayData?.description}
                                        </div>
                                    )}
                                    {proList.map((p) => (
                                        <div
                                            key={p.name}
                                            className={`${style.propertyRowNarrow} ${
                                                textSpecialStyle.textPropNormalRow
                                            } ${
                                                extraInfo.styleDisplayData?.name &&
                                                textSpecialStyle.textPropSpecificRowPadding
                                            }`}
                                        >
                                            <span className={style.propertyName}>{p.name}</span>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}
                                                className={classNames(style.properyPairsContainer)}
                                            >
                                                <CopyableRow copyValue={p.value + ''} className="truncate">
                                                    <span className={style.propertyValue}>{p.value}</span>
                                                </CopyableRow>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )
                }
            })}
            {
                <ViewAll
                    limit={limit}
                    count={styledTextSegments.length}
                    onChange={limitChange}
                    className="!ml-4"
                ></ViewAll>
            }
        </div>
    )
}
