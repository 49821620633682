import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    email_n: '邮件通知',
    disabled: '已关闭，仍然会收到账号或管理相关的重要通知',
    file_c: '文件评论',
    all_cmr: '所有评论、提及和回复',
    just_y: '仅与你有关',
    none: '不通知',
    ir: '邀请和审批',
    all_ir: '所有邀请和审批',
    only_i: '仅审批消息',
} as const

export const enTranslation = {
    email_n: 'Email notifications',
    disabled:
        'Notifications disabled. You will still get other emails from Motiff, like important account or manage info.',
    file_c: 'File comments',
    all_cmr: 'All comments, mentions, and replies',
    just_y: 'Just mentions and replies',
    none: 'None',
    ir: 'Invites and requests',
    all_ir: 'All types of invites and requests',
    only_i: 'Only invites and requests that need my response',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
