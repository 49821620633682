/* eslint-disable no-restricted-imports */
import {
    hasCmd,
    isCombo,
    isKey,
    isKeyAsDecimal,
    isKeyAsNumber,
    isKeyAsOperator,
    isUndoRedoEvent,
    KeyboardCode,
    ModifierKeysCombo,
} from '../../../kernel/keyboard/keyboard-event-handler'
import { isWindows } from '../../../kernel/util/ua'

export function shouldPreventThisEventByDefault(e: KeyboardEvent) {
    // https://wkong.atlassian.net/browse/WK-24070 某些特殊场景下空格键会多触发一个 key 为 Unidentified 的 event
    if (e.key?.toLowerCase() === 'unidentified') {
        e.preventDefault()
    }

    // 禁止 windows ctrl + k 调起系统搜索
    if (isWindows() && isCombo(e, ModifierKeysCombo.CTRL) && isKey(e, KeyboardCode.K)) {
        e.preventDefault()
    }

    // 禁止刷新页面
    if ((isCombo(e, ModifierKeysCombo.META) || isCombo(e, ModifierKeysCombo.META_SHIFT)) && isKey(e, KeyboardCode.R)) {
        e.preventDefault()
    }

    // 禁止触发打印
    if (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.P)) {
        e.preventDefault()
    }

    // 禁止触发搜索
    if (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.F)) {
        e.preventDefault()
    }
}

// 文本图层输入框快捷键白名单
export function shouldPassThisEventToWasmWhenTextInputFocused(e: KeyboardEvent) {
    return (
        isUndoRedoEvent(e) ||
        // 下划线
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.U)) ||
        // 超链接
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.K)) ||
        // 删除线
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode.X)) ||
        // 列表
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKeyAsNumber(e, 7)) ||
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKeyAsNumber(e, 8)) ||
        // 左右居中两边对齐
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode.L)) ||
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode.T)) ||
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode.R)) ||
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode.J)) ||
        // 调整字号
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode[',<'])) ||
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKeyAsDecimal(e)) ||
        // 调整字间距
        (isCombo(e, ModifierKeysCombo.ALT) && isKey(e, KeyboardCode[',<'])) ||
        (isCombo(e, ModifierKeysCombo.ALT) && isKeyAsDecimal(e)) ||
        // 调整行高
        (isCombo(e, ModifierKeysCombo.ALT_SHIFT) && isKey(e, KeyboardCode[',<'])) ||
        (isCombo(e, ModifierKeysCombo.ALT_SHIFT) && isKeyAsDecimal(e)) ||
        // 调整缩进
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode['['])) ||
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode[']'])) ||
        // 调整字重
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode[',<'])) ||
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKeyAsDecimal(e)) ||
        // 加粗
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.B)) ||
        // 斜体
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.I)) ||
        // 文字编辑状态下去除样式并粘贴文字
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode.V)) ||
        // 隐藏显示左边栏
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode['\\|'])) ||
        // 隐藏显示操作界面
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode['\\|'])) ||
        // 快捷指令
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.P)) ||
        // 取色器
        (isCombo(e, ModifierKeysCombo.CTRL) && isKey(e, KeyboardCode.C)) ||
        // cmd+shifr+R
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode.R)) ||
        // 复制属性
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode.C)) ||
        // 粘贴属性
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode.V))
    )
}

// 搜索结果列表快捷键白名单
export function shouldPassThisEventToWasmWhenCanvasSearchResultFocused(e: KeyboardEvent) {
    return (
        isUndoRedoEvent(e) ||
        // 隐藏显示左边栏
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode['\\|'])) ||
        // 隐藏显示操作界面
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode['\\|'])) ||
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode['.>'])) ||
        // 创建编组
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.G)) ||
        // 取消编组
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode.G)) ||
        // 创建容器
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode.G)) ||
        // 隐藏/显示已选图层
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode.H)) ||
        // 锁定/解锁已选图层
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode.L)) ||
        // 粘贴至所选位置
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode.V)) ||
        // 粘贴替换
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode.R)) ||
        (isCombo(e, ModifierKeysCombo.META_SHIFT_ALT) && isKey(e, KeyboardCode.V)) ||
        // 创建副本
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.D)) ||
        // 复制属性
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode.C)) ||
        // 粘贴属性
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode.V)) ||
        // 复制为 PNG
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode.C)) ||
        // 上下移动图层
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode['['])) ||
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode[']'])) ||
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode['['])) ||
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode[']'])) ||
        // 重命名
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.R))
    )
}

// 搜索结果按钮快捷键白名单
export function shouldPassThisEventToWasmWhenCanvasSearchResultFocusedOnlyForView(e: KeyboardEvent) {
    return (
        isUndoRedoEvent(e) ||
        // 隐藏显示左边栏
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode['\\|'])) ||
        // 隐藏显示操作界面
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode['\\|'])) ||
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode['.>'])) ||
        // 复制属性
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode.C)) ||
        // 复制为 PNG
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode.C))
    )
}

// 搜索输入框快捷键白名单
export function shouldPassThisEventToWasmWhenCanvasSearchInputFocused(e: KeyboardEvent) {
    return (
        // 隐藏显示左边栏
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode['\\|'])) ||
        // 隐藏显示操作界面
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode['\\|'])) ||
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode['.>'])) ||
        // 重命名
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.R))
    )
}

// 聚焦在Button时，是否应该将 keyboardEvent 传给 wasm
export function shouldPassThisEventToWasmWhenButtonFocused(e: KeyboardEvent) {
    return isUndoRedoEvent(e)
}

// 图层面板中输入框快捷键白名单
// 参考产品文档 https://www.notion.so/kanyun/3e1ed49408d74545b2e85b802afcf628?v=2046aebbbb8645eba9ee6b58d12c4eb0
export function shouldPassThisEventToWasmForDesignPanelInput(e: KeyboardEvent): boolean {
    if (!hasCmd(e)) {
        return false // 前提是必须有 meta/ctrl 否则一定视为输入行为
    }
    return (
        // 切换侧边栏
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode['\\|'])) ||
        // 取色器
        (isCombo(e, ModifierKeysCombo.CTRL) && isKey(e, KeyboardCode.C)) ||
        // 图片
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.K)) ||
        // 像素网格
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode['"'])) ||
        // 缩放
        (isCombo(e, ModifierKeysCombo.META) && isKeyAsOperator(e, '+')) ||
        (isCombo(e, ModifierKeysCombo.META) && isKeyAsOperator(e, '-')) ||
        // 轮廓化
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode.O)) ||
        // 拼合
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.J)) ||
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.E)) ||
        // 创建编组
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.G)) ||
        // 取消编组
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode.G)) ||
        // 创建容器
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode.G)) ||
        // 隐藏/显示已选图层
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode.H)) ||
        // 锁定/解锁已选图层
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode.L)) ||
        // 粘贴至所选位置
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode.V)) ||
        // 粘贴替换
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode.R)) ||
        (isCombo(e, ModifierKeysCombo.META_SHIFT_ALT) && isKey(e, KeyboardCode.V)) ||
        // 创建副本
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.D)) ||
        // 导出
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode.E)) ||
        // 复制属性
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode.C)) ||
        // 粘贴属性
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode.V)) ||
        // 复制为 PNG
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode.C)) ||
        // 上下移动图层
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode['['])) ||
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode[']'])) ||
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode['['])) ||
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode[']'])) ||
        // 创建组件
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode.K)) ||
        // 分离实例
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode.B)) ||
        // 隐藏/显示界面
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode['\\|'])) ||
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode['.>'])) ||
        // 缩放至 100%
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.NUM_0)) ||
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.PAD_0)) ||
        // 解锁所有图层
        (isCombo(e, ModifierKeysCombo.META_SHIFT_ALT) && isKey(e, KeyboardCode.L)) ||
        // 重命名所选图层
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.R)) ||
        // 显示/隐藏协作者光标
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode['\\|'])) ||
        // 快捷操作
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode['/?'])) ||
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.P)) ||
        // 保存历史版本
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode.S)) ||
        // 运动最近一次启动
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode.P)) ||
        // 对齐像素网格
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode['~'])) ||
        // 下划线
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.U)) ||
        // 超链接
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.K)) ||
        // 删除线
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode.X)) ||
        // 列表
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKeyAsNumber(e, 7)) ||
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKeyAsNumber(e, 8)) ||
        // 左右居中两边对齐
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode.L)) ||
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode.T)) ||
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode.R)) ||
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode.J)) ||
        // 调整字号
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode[',<'])) ||
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKeyAsDecimal(e)) ||
        // 调整字间距
        (isCombo(e, ModifierKeysCombo.ALT) && isKey(e, KeyboardCode[',<'])) ||
        (isCombo(e, ModifierKeysCombo.ALT) && isKeyAsDecimal(e)) ||
        // 调整行高
        (isCombo(e, ModifierKeysCombo.ALT_SHIFT) && isKey(e, KeyboardCode[',<'])) ||
        (isCombo(e, ModifierKeysCombo.ALT_SHIFT) && isKeyAsDecimal(e)) ||
        // 调整缩进
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode['['])) ||
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode[']'])) ||
        // 调整字重
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKey(e, KeyboardCode[',<'])) ||
        (isCombo(e, ModifierKeysCombo.META_ALT) && isKeyAsDecimal(e)) ||
        // 加粗
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.B)) ||
        // 斜体
        (isCombo(e, ModifierKeysCombo.META) && isKey(e, KeyboardCode.I)) ||
        // 文字编辑状态下去除样式并粘贴文字
        (isCombo(e, ModifierKeysCombo.META_SHIFT) && isKey(e, KeyboardCode.V))
    )
}
