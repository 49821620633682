/* eslint-disable no-restricted-imports */
import { useEffect, useMemo, useState } from 'react'
import { FontName } from '../../../../../document/node/node'
import { getFontNameUid, getFontNameUidSet, isEqualFontName } from '../../../../../document/util/font'
import { useViewState } from '../../../../../view-state-bridge'
import { useFontManagerService } from '../../../../context/document-context'

export function useStyleNodeFontLoading(fontName: FontName | null): boolean {
    const [fontLoading, setFontLoading] = useState(false)
    const availableFonts = useViewState('availableFonts')?.availableFonts

    const fontManagerService = useFontManagerService()
    const localFontInfos = fontManagerService.states.use.localFontInfosState()
    const localFontNameUidSet = useMemo(() => getFontNameUidSet(localFontInfos), [localFontInfos])

    const needShowFontLoading = useMemo(() => {
        if (!fontName || localFontNameUidSet.has(getFontNameUid(fontName))) {
            return false
        }

        return !availableFonts?.find((v) => isEqualFontName(v, fontName))
    }, [availableFonts, fontName, localFontNameUidSet])

    useEffect(() => {
        setFontLoading(false)

        if (!needShowFontLoading) {
            return
        }

        const timer = setTimeout(() => {
            setFontLoading(true)
        }, 1000)
        return () => clearTimeout(timer)
    }, [needShowFontLoading])

    return fontLoading
}
