import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    CandidateComponent: '候选组件',
    CandidateStyle: '候选样式',
    Create: '创建{{name}}',
    MultipleComponents: '多个组件',
    MultipleStyles: '多个样式',
    Component: '组件',
    Style: '样式',
    ReplaceComponent: '替换组件',
    ReplaceStyle: '替换样式',
} as const

export const enTranslation = {
    CandidateComponent: 'Candidate component',
    CandidateStyle: 'Candidate style',
    Create: 'Create {{name}}',
    MultipleComponents: 'multiple components',
    MultipleStyles: 'multiple styles',
    Component: 'component',
    Style: 'style',
    ReplaceComponent: 'Replace component',
    ReplaceStyle: 'Replace style',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
