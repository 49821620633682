/* eslint-disable no-restricted-imports */
import { HttpPrefixKey, environment } from '../../../../../environment'

export enum Language {
    English = 'en',
    ChineseSimplified = 'zh-Hans',
    ChineseTraditional = 'zh-Hant',
}

enum MethodPath {
    SearchPhotos = '/search/photos',
    GetRandom = '/photos/random',
}

type Nullable<T> = T | null

export interface Photo {
    id: string
    created_at: string
    updated_at: string
    urls: {
        full: string
        raw: string
        regular: string
        small: string
        thumb: string
    }
    alt_description: Nullable<string>
    blur_hash: Nullable<string>
    color: Nullable<string>
    description: Nullable<string>
    height: number
    likes: number
    links: {
        self: string
        html: string
        download: string
        download_location: string
    }
    promoted_at: Nullable<string>
    width: number
    user: User
}

export interface User {
    id: string
    bio: Nullable<string>
    first_name: string
    instagram_username: Nullable<string>
    last_name: Nullable<string>
    links: {
        followers: string
        following: string
        html: string
        likes: string
        photos: string
        portfolio: string
        self: string
    }
    location: Nullable<string>
    name: string
    portfolio_url: Nullable<string>
    profile_image: {
        small: string
        medium: string
        large: string
    }
    total_collections: number
    total_likes: number
    total_photos: number
    twitter_username: Nullable<string>
    updated_at: string
    username: string
}

interface Photos {
    results: Photo[]
    total: number
    total_pages: number
}

export class SearchResultManager {
    index: number
    fetchResult: (index: number) => Promise<Photos>

    constructor(index = 1, fetchResult: (index: number) => Promise<Photos>) {
        this.index = index
        this.fetchResult = fetchResult
    }

    currentIndex(): number {
        return this.index
    }

    flipToNext(): number {
        this.index += 1
        return this.index
    }

    flipToPrevious(): number {
        if (this.index > 1) {
            this.index -= 1
        }
        return this.index
    }

    setCurrentPage(index: number): void {
        this.index = index
    }

    async getItems(): Promise<Photo[]> {
        const data: Photos = await this.fetchResult(this.index)
        return data.results
    }
}

export const unsplashService = (function () {
    // private
    const prefix = environment.httpPrefixes[HttpPrefixKey.COMMON_API] + '/unsplash-proxy'
    const unsplashPrefix = 'https://api.unsplash.com'

    return {
        // public

        // 按关键词搜索图片
        search: function (query: string, lang = Language.ChineseSimplified, per_page = 1) {
            return new SearchResultManager(1, async (index: number) => {
                const url =
                    prefix +
                    MethodPath.SearchPhotos +
                    '?' +
                    new URLSearchParams({
                        query: query,
                        lang: lang,
                        per_page: per_page.toString(),
                        page: index.toString(),
                    })

                const response = await fetch(url, {
                    method: 'GET',
                    credentials: 'include',
                })

                if (!response.ok) {
                    const errorsResponse = await response.json()

                    throw new Error(JSON.stringify(errorsResponse))
                }

                return (await response.json()) as Photos
            })
        },

        // 随机获取图片
        getRandom: async function (query: string, count = 1) {
            const url =
                prefix + MethodPath.GetRandom + '?' + new URLSearchParams({ query: query, count: count.toString() })

            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
            })

            if (!response.ok) {
                const errorsResponse = await response.json()
                throw new Error(JSON.stringify(errorsResponse))
            }

            return (await response.json()) as Photo[]
        },

        replaceUnsplashPrefix: function (url: string): string {
            return url.replace(unsplashPrefix, prefix)
        },

        // unsplash 使用规范: 用户的图片下载行为需要上报至 unsplash
        trackDownload: async function (url: string) {
            // url 由某次查询后 unsplash 直接提供, 需要替换为代理服务地址, 格式如下
            // <unsplashPrefix>/photo/<id>/download?ixid=<ixid>
            fetch(this.replaceUnsplashPrefix(url), {
                method: 'GET',
                credentials: 'include',
            })
        },
    }
})()
