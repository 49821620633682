import { translation } from './stroke-other-setting.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { useCallback, useRef, useState } from 'react'
import { Tooltip } from '../../../../../../../ui-lib/src'
import { useFeatureSwitch } from '../../../../../kernel/switch'
import { StrokeAlign } from '../stroke-align/stroke-align'
import { StrokeAllBorderWeight as StrokeAllBorderWeightV2 } from '../stroke-all-border-weight/index'
import { StrokeAllBorderWeight } from '../stroke-all-border-weight/stroke-all-border-weight'
import { StrokeAllBorderWeightRef } from '../stroke-all-border-weight/use-stroke-all-border-weight'
import { StrokePairEndPoint } from '../stroke-end-point/stroke-end-point'
import { useStrokeEndPoint } from '../stroke-end-point/use-stroke-end-point'
import { StrokeIndependentType } from '../stroke-independent-type/stroke-independent-type'
import { StrokeFourIndependentWeight as StrokeFourIndependentWeightV2 } from '../stroke-independent-weight/index'
import {
    StrokeFourIndependentWeight,
    StrokeFourIndependentWeightRef,
} from '../stroke-independent-weight/stroke-four-independent-weight'
import { StrokeMoreSetting, StrokeMoreSettingRef } from '../stroke-more-setting/stroke-more-setting'
import classes from './stroke-other-setting.module.less'

export function StrokeOtherSetting() {
    const { isShow, showAsPair, showAsSingle } = useStrokeEndPoint()
    const moreSettingRef = useRef<StrokeMoreSettingRef>(null)
    const strokeAllBorderWeightRef = useRef<StrokeAllBorderWeightRef>(null)
    const fourIndependentWeightRef = useRef<StrokeFourIndependentWeightRef>(null)
    const [isOpenFourIndependentWeight, setIsOpenFourIndependentWeight] = useState(false)
    const flag = useFeatureSwitch('float-variable')

    const onChangeType = useCallback((value: Wukong.DocumentProto.IndependentStrokeType) => {
        if (value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_CUSTOM) {
            fourIndependentWeightRef.current?.openFourIndependentWeight()
            setIsOpenFourIndependentWeight(true)
        } else {
            fourIndependentWeightRef.current?.closeFourIndependentWeight()
            setIsOpenFourIndependentWeight(false)
        }
    }, [])

    return isShow ? (
        <>
            <div
                className={classnames(classes.lineContainer)}
                ref={showAsSingle ? moreSettingRef.current?.initTriggerRef : undefined}
            >
                <div className={classnames(classes.lineItem, classes.inputItem)}>
                    <StrokeAlign />
                </div>
                <div className={classnames(classes.lineItem, classes.inputItem)}>
                    {flag ? (
                        <StrokeAllBorderWeightV2
                            isOpenFourIndependentWeight={isOpenFourIndependentWeight}
                            ref={strokeAllBorderWeightRef}
                        />
                    ) : (
                        <StrokeAllBorderWeight
                            isOpenFourIndependentWeight={isOpenFourIndependentWeight}
                            ref={strokeAllBorderWeightRef}
                        />
                    )}
                </div>
                <StrokeIndependentType
                    className={classnames(classes.lineItem, classes.iconItem)}
                    onChangeType={onChangeType}
                    onUserTypeStateChange={strokeAllBorderWeightRef.current?.setTypeState}
                />
                <Tooltip title={translation('AdvancedStrokeSettings')}>
                    <div className={classnames(classes.lineItem, classes.moreItem)}>
                        {showAsSingle && <StrokeMoreSetting ref={moreSettingRef} />}
                    </div>
                </Tooltip>
            </div>

            {flag ? (
                <StrokeFourIndependentWeightV2 ref={fourIndependentWeightRef} />
            ) : (
                <StrokeFourIndependentWeight ref={fourIndependentWeightRef} />
            )}

            {showAsPair && (
                <StrokePairEndPoint moreSettingRef={moreSettingRef}>
                    <Tooltip title={translation('AdvancedStrokeSettings')} addContainer={{ style: { height: '100%' } }}>
                        <StrokeMoreSetting ref={moreSettingRef} />
                    </Tooltip>
                </StrokePairEndPoint>
            )}
        </>
    ) : (
        <></>
    )
}
