/* eslint-disable no-restricted-imports */
import type { Paint } from '../../../../../document/node/node'
import { StyleListItemV2, StyleListItemV2Props } from '../style-items-layout/style-list-item-v2'
import { LocalStyleColorListItemThumnail } from './local-style-color-list-item-thumbnail'
import style from './style-color-list-item-v2.module.less'

export type StyleColorListItemPropsV2 = StyleListItemV2Props<{ paints: readonly Paint[]; contentHash: string }>

export function StyleColorListItemV2(props: StyleColorListItemPropsV2) {
    return (
        <StyleListItemV2 {...props}>
            <LocalStyleColorListItemThumnail
                id={props.item.id}
                contentHash={props.item.contentHash}
                paints={props.item.paints}
                className={style.thumbnail}
            />
            <div className={style.name}>{props.item.styleName}</div>
        </StyleListItemV2>
    )
}
