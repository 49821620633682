export function assert(value: unknown, message: string): asserts value {
    if (!value) {
        throw Error(message)
    }
}

export function isJest() {
    // eslint-disable-next-line no-process-env
    return typeof process !== 'undefined' && process?.env?.JEST_WORKER_ID !== undefined
}
