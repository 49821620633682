import { WKTextButton } from '../../../../../../../ui-lib/src'
import { FeeUnitVO, PayPlanType } from '../../../../../kernel/interface/type'
import { PrivilegeCard, PrivilegeCardProps } from './privilege-card'
import classes from './privilege-card-organization.module.less'
import { translation } from './privilege-card-organization.translation'
import { getPriceLabelFromExpense } from './utils'
export interface PrivilegeCardOrganizationProps
    extends Pick<PrivilegeCardProps, 'className' | 'privilegesTitle' | 'privileges'> {
    currentPlanType: PayPlanType.professional | PayPlanType.starter
    payment: 'monthly' | 'annual'
    mainPriceAnnual: FeeUnitVO | undefined
    subPriceAnnual: FeeUnitVO | undefined
    onSubmit: () => void
    onClickYear: () => void
}

export function PrivilegeCardOrganization(props: PrivilegeCardOrganizationProps) {
    const { payment, mainPriceAnnual, subPriceAnnual, onSubmit, onClickYear, currentPlanType, ...otherProps } = props
    return (
        <PrivilegeCard
            {...otherProps}
            name={translation('Organization')}
            price={{
                designer: {
                    money: getPriceLabelFromExpense(mainPriceAnnual),
                    per: translation('m'),
                },
                developer: {
                    money: getPriceLabelFromExpense(subPriceAnnual),
                    per: translation('m'),
                },
            }}
            priceReactNode={
                payment === 'annual' ? undefined : (
                    <div className={classes.priceDescription}>
                        <WKTextButton type={'primary'} size={12} onClick={onClickYear}>
                            Billed yearly.
                        </WKTextButton>{' '}
                        Switch the billing cycle to <span className={classes.bold}>Yearly</span> to view org pricing.
                    </div>
                )
            }
            buttonProps={{
                type: 'primary',
                children:
                    currentPlanType === PayPlanType.starter
                        ? translation('UpgradeToOrganization')
                        : translation('ChooseOrganization'),
                onClick: onSubmit,
            }}
            dataTestIds={{
                title: 'organization-title',
                price: 'organization-price',
                button: 'organization-button',
                privileges: 'organization-privileges',
                prefixPrivilege: 'organization-privilege',
            }}
        />
    )
}
