import { Wukong } from '@wukong/bridge-proto'
import { useEffect } from 'react'
import { WKDialog, WKRadio, WKRadioGroup, WKTextButton, WKTypography } from '../../../../../ui-lib/src'
import { getMotiffName } from '../../../../../util/src'
import { useColorProfileService } from '../../../main/app-context'
import { useMenuContextState } from '../top-area/menu/context/menu-context'
import { ColorProfieDialogFooter } from './dialog-footer'
import { jumpToCenter, useColorProfileDialog, useUserConfigColorProfile } from './hook'
import styles from './preference-dialog.module.less'
import { translation } from './preference-dialog.translation'

export interface PreferenceColorProfieDialogProps {
    onClose: () => void
}

const SRGB = Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B
const P3 = Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILE_DISPLAY_P3

// 把字符串常量单独提出来，便于写测试
export const PreferenceColorProfileDialogStringResources = {
    Title: translation('ColorProfileSettings'),
    ContentTitle: `${translation('PreferredColorProfile')}`,
    Radio: {
        [SRGB]: 'sRGB',
        [P3]: 'Display P3',
    },
    RadioDescription: {
        [SRGB]: translation('SRGBIsBest'),
        [P3]: translation('BestForApple'),
    },
    AfterRadioDescription: `${translation('ThisPreferenceApplies')}`,
    WarningDeviceNotSupport: {
        [SRGB]: `${translation('YourDisplayCan’t')} sRGB${translation('ButYouCan')}`,
        [P3]: `${translation('YourDisplayCan’t')} Display P3${translation('ButYouCan')}`,
    },
    WarningOtherDeviceSupport: {
        [SRGB]: translation('SupportSRGB', { name: '{name}', motiffName: getMotiffName() }),
        [P3]: translation('SupportP3', { name: '{name}', motiffName: getMotiffName() }),
    },
    WarningHostNotSupport: {
        [SRGB]: `${translation('YourDisplaySupports')} sRGB${translation('ButYouCan')}`,
        [P3]: `${translation('YourDisplaySupports')} Display P3${translation('ButYouCan')}`,
    },
    DeviceSupport: {
        [SRGB]: `${translation('YourBrowserSupportsSRGB')}`,
        [P3]: `${translation('YourBrowserSupportsP3')}`,
    },
}

const PreferenceColorProfieDataTestIds = {
    RadioSRGB: 'preference-color-profile-radio-srgb',
    RadioDisplayP3: 'preference-color-profile-radio-display-p3',
}

const R = PreferenceColorProfileDialogStringResources

function PreferenceColorProfieDialog({ onClose }: PreferenceColorProfieDialogProps) {
    const { colorProfile, saveColorProfileToLocal } = useUserConfigColorProfile()
    const { deviceSupportLevel, isHostSupport } = useColorProfileDialog(colorProfile, true)
    const service = useColorProfileService()

    useEffect(() => {
        service.refreshMonitorDisplayP3SupportedLevel()
    }, [service])

    return (
        <WKDialog
            title={R.Title}
            visible={true}
            onCancel={onClose}
            footer={
                <ColorProfieDialogFooter
                    deviceSupportLevel={deviceSupportLevel}
                    isHostSupport={isHostSupport}
                    colorProfile={colorProfile}
                    warningDeviceNotSupportText={R.WarningDeviceNotSupport}
                    warningOtherDeviceSupportText={R.WarningOtherDeviceSupport}
                    warningHostNotSupportText={R.WarningHostNotSupport}
                    deviceSupportText={R.DeviceSupport}
                    warningShowMoreLink={'#'}
                />
            }
        >
            <div className={styles.preferenceColorProfileDialogContent}>
                <div>{R.ContentTitle}</div>
                <div className="my-8px">
                    <WKRadioGroup
                        name="preference-color-profile-radio"
                        direction="horizontal"
                        value={Wukong.DocumentProto.DocumentColorProfile[colorProfile]}
                        onValueChange={(value) => {
                            saveColorProfileToLocal(
                                Wukong.DocumentProto.DocumentColorProfile[
                                    value as keyof typeof Wukong.DocumentProto.DocumentColorProfile
                                ]
                            )
                        }}
                    >
                        <WKRadio
                            value={Wukong.DocumentProto.DocumentColorProfile[SRGB]}
                            label={R.Radio[SRGB]}
                            dataTestId={PreferenceColorProfieDataTestIds.RadioSRGB}
                        ></WKRadio>
                        <WKRadio
                            value={Wukong.DocumentProto.DocumentColorProfile[P3]}
                            label={R.Radio[P3]}
                            dataTestId={PreferenceColorProfieDataTestIds.RadioDisplayP3}
                        ></WKRadio>
                    </WKRadioGroup>
                </div>
                <WKTypography.Text color="placeholder" className={styles.text}>
                    {R.RadioDescription[colorProfile]}
                </WKTypography.Text>
                <div className={styles.afterRadioContainer}>
                    <WKTypography.Text className={styles.text}>{R.AfterRadioDescription}</WKTypography.Text>{' '}
                    <WKTextButton type="primary" size={12} onClick={jumpToCenter}>
                        {translation('LearnMore')}
                    </WKTextButton>
                </div>
            </div>
        </WKDialog>
    )
}

export function MenuPreferenceColorProfieDialog() {
    const { isOpenPreferenceColorProfileDialog, setIsOpenPreferenceColorProfileDialog } = useMenuContextState()

    if (!isOpenPreferenceColorProfileDialog) {
        return null
    }
    return <PreferenceColorProfieDialog onClose={() => setIsOpenPreferenceColorProfileDialog(false)} />
}
