/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { CommandInvoker } from '../../document/command/command-invoker'
import { isUndoRedoEvent } from '../../kernel/keyboard/keyboard-event-handler'
import { shouldPassThisEventToWasmForDesignPanelInput } from './focus-view/keyboard-event-prevent-or-pass'
import { dispatchKeyboardEventToWasm } from './focus-view/keyboard-event-to-wasm'

export const setupDesignPanelInputKeyboardEventService = (commandInvoker: CommandInvoker) => {
    _divertKeyboardEvent = (e: React.KeyboardEvent<HTMLInputElement>) =>
        dispatchKeyboardEventToWasm(
            commandInvoker,
            e.nativeEvent,
            Wukong.DocumentProto.KeyboardEventTraceSource.KEYBOARD_EVENT_TRACE_SOURCE_BASE_INPUT_COMPONENT
        )
    return () => (_divertKeyboardEvent = () => {})
}

let _divertKeyboardEvent = (_e: React.KeyboardEvent<HTMLInputElement>) => {}

export const divertKeyboardEvent = (e: React.KeyboardEvent<HTMLInputElement>) => _divertKeyboardEvent(e)

export const cppInterceptThisKeyboardEvent = (
    event: React.KeyboardEvent<HTMLInputElement>,
    isValueDirty: boolean,
    passKeyboardEvent: (e: React.KeyboardEvent<HTMLInputElement>) => void,
    disablePassingKeyboardEvent = false
) => {
    if (isUndoRedoEvent(event.nativeEvent)) {
        if (!isValueDirty) {
            passKeyboardEvent(event) // 输入框值没有变化，则交给 wasm 处理 undo
        }
        return true // 但无论如何都要拦截
    }
    if (shouldPassThisEventToWasmForDesignPanelInput(event.nativeEvent) && !disablePassingKeyboardEvent) {
        passKeyboardEvent(event)
        return true
    }
    return false
}
