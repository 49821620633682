import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    Local: '本地',
    All: '全部',
    Team: '团队',
    Cloud: '云端',
    Share: '共享',
} as const

export const enTranslation = {
    Local: 'Local',
    All: 'All',
    Team: 'Team',
    Cloud: 'Cloud',
    Share: 'Shared',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
