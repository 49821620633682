import { translation } from './replace-instance-panel.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
    DraggablePopupV2,
    InputV2,
    ScrollContainer,
    ScrollView,
    Select,
    Tooltip,
    useEllipsisTooltip,
} from '../../../../../../../ui-lib/src'
import { sortArrayByKey } from '../../../../../../../util/src'
import { LibraryThumbnailImage } from '../../../../../share/component-style-library/library-thumbnail-image'
import { ReplaceInstancePanelTestId, ReplaceInstancePanelTestId as ReplaceTestIds } from '../../../../../window'
import { useComponentInstanceCommand } from '../commands'
import {
    ReplaceInstanceLibraryList,
    ReplaceInstanceNodeData,
    ReplaceInstanceSearchResults,
    ReplaceInstanceState,
    ReplaceInstanceTree,
} from '../types'
import { IconBack, IconFolder, IconForward } from './icons'
import style from './instance-panel.module.less'

export function Thumbnail(
    props: ReplaceInstanceNodeData & {
        docId: string
        onClick: () => void
    }
) {
    const { inactivation, ellipsisRef, onmouseenter, onmouseleave } = useEllipsisTooltip<HTMLDivElement>()
    const formatDescription = props.description !== props.name ? props.description : undefined
    const commands = useComponentInstanceCommand()
    const isSelectFn = useCallback(() => props.isSelected, [props.isSelected])
    const nameInactivation =
        inactivation &&
        props.layoutSizeType !== Wukong.DocumentProto.LibraryLayoutSizeType.LIBRARY_LAYOUT_SIZE_TYPE_SMALL
    return (
        <Tooltip
            placement="left"
            overlay={
                <>
                    {!nameInactivation && (
                        <p className="break-word my-0" data-testid={ReplaceInstancePanelTestId.thumbnailTooltipName}>
                            {props.name}
                        </p>
                    )}
                    {!!formatDescription && (
                        <p
                            className={classNames({ ['mt-1']: !inactivation }, 'mb-0 break-word')}
                            data-testid={ReplaceInstancePanelTestId.thumbnailTooltipDesc}
                        >
                            {formatDescription}
                        </p>
                    )}
                </>
            }
            inactivation={nameInactivation && !formatDescription}
        >
            <ScrollView.Item
                isSelectFn={isSelectFn}
                uniqueKey={props.nodeId}
                className={classNames(style.thumbnail, style[`size${props.layoutSizeType}`])}
                onClick={() => {
                    commands.replaceMainCompId(props)
                    props.onClick()
                }}
                data-testid={props.isSelected ? ReplaceTestIds.currentPreview : ReplaceTestIds.preview(props.name)}
                style={{
                    width: props.width,
                }}
                onMouseEnter={onmouseenter}
                onMouseLeave={onmouseleave}
            >
                <div
                    className={`${style.thumbnailImage} ${props.isSelected && style.thumbnailImageSelected}`}
                    style={{
                        height: props.height,
                    }}
                >
                    {JSON.stringify(props.thumbnailData) !== '{}' && (
                        <LibraryThumbnailImage thumbnailData={props.thumbnailData} />
                    )}
                </div>
                {props.layoutSizeType !== Wukong.DocumentProto.LibraryLayoutSizeType.LIBRARY_LAYOUT_SIZE_TYPE_SMALL && (
                    <div className={style.thumbnailText} ref={ellipsisRef}>
                        {props.name}
                    </div>
                )}
            </ScrollView.Item>
        </Tooltip>
    )
}

export function ListItem(props: {
    head?: React.ReactNode
    body?: React.ReactNode
    tail?: React.ReactNode
    onClick?: () => void
    testId?: string
}) {
    return (
        <div className={style.listItem} onClick={props.onClick} data-testid={props.testId}>
            <div className={style.head}>{props.head}</div>
            <div className={style.body}>{props.body}</div>
            <div className={style.tail}>{props.tail}</div>
        </div>
    )
}

export interface FolderTree {
    parent: number
    folderChildren: number[]
    name: string
    nodes: ReplaceInstanceTree['nodes']
}

export function buildFolderTree(root?: ReplaceInstanceTree) {
    const flattenFolders: FolderTree[] = []
    let selectedFolderIndex = -1

    const buildFromInstanceTree = (tree: ReplaceInstanceTree, parent = -1) => {
        const index = flattenFolders.length
        if (tree.nodes.some((node) => node.isSelected)) {
            selectedFolderIndex = index
        }
        flattenFolders.push({
            ...tree,
            parent,
            folderChildren: [],
        })

        flattenFolders.at(-1)!.folderChildren = tree.folderChildren.map((child) => buildFromInstanceTree(child, index))

        return index
    }

    if (root) {
        buildFromInstanceTree(root)
    }
    selectedFolderIndex = Math.max(0, selectedFolderIndex)
    return { flattenFolders, selectedFolderIndex }
}

function ReplacePanelFoldes({
    library,
    onSetStickyTitle,
    onClickThumbail,
}: {
    library: ReplaceInstanceLibraryList[0]
    onSetStickyTitle: (content: ReactNode) => void
    onClickThumbail: () => void
}) {
    const [defaultTree] = useState(() => buildFolderTree(library.tree))
    const [allFolders, setFolders] = useState<FolderTree[]>(defaultTree.flattenFolders)
    const [folderIndex, setFolderIndex] = useState<number>(defaultTree.selectedFolderIndex)

    const currentFolder = allFolders.at(folderIndex)
    const onUpperFolder = useCallback(() => {
        setFolderIndex(currentFolder?.parent ?? 0)
    }, [currentFolder?.parent])

    useEffect(() => {
        setFolders(buildFolderTree(library.tree).flattenFolders)
    }, [library])

    useEffect(() => {
        if (currentFolder?.name !== undefined && currentFolder?.parent >= 0) {
            onSetStickyTitle(
                <ListItem
                    head={<IconBack />}
                    body={currentFolder?.name}
                    testId={ReplaceTestIds.upper}
                    onClick={onUpperFolder}
                />
            )
        } else {
            onSetStickyTitle(null)
        }
    }, [currentFolder?.name, currentFolder?.parent, onSetStickyTitle, onUpperFolder])

    useEffect(() => {
        return () => {
            onSetStickyTitle(null)
        }
    }, [onSetStickyTitle])

    const sortedNodes = useMemo(() => {
        return sortArrayByKey(currentFolder?.nodes.slice() ?? [], 'name')
    }, [currentFolder])
    return (
        <div className={style.currentFolder} data-testid={ReplaceTestIds.currentFolder(currentFolder?.name ?? 'none')}>
            <div className={style.folders}>
                {currentFolder?.folderChildren.map((childFolderIndex) => {
                    const folder = allFolders.at(childFolderIndex)
                    return (
                        folder && (
                            <ListItem
                                key={folder.name}
                                head={<IconFolder />}
                                body={folder.name}
                                tail={<IconForward />}
                                onClick={() => setFolderIndex(childFolderIndex)}
                                testId={ReplaceTestIds.folder(folder.name)}
                            />
                        )
                    )
                })}
            </div>
            {currentFolder && sortedNodes.length > 0 && (
                <div className={style.thumbnailList}>
                    {sortedNodes.map(({ key, ...node }) => (
                        <Thumbnail
                            {...node}
                            docId={library.docId ?? ''}
                            key={node.nodeId || key}
                            onClick={onClickThumbail}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

function ReplacePanelBody(props: {
    allLibraryList: ReplaceInstanceState['allLibraryList']
    position?: {
        left: number
        top: number
    }
}) {
    const commands = useComponentInstanceCommand()
    const defaultIndex = useMemo(() => props.allLibraryList.findIndex((lib) => lib.isSelected), [props.allLibraryList])
    const [selectedIndex, setSelectedIndex] = useState<number>(Math.max(defaultIndex, 0))

    useEffect(() => {
        if (defaultIndex > -1) {
            setSelectedIndex(defaultIndex)
        }
    }, [defaultIndex])

    const selectedLibrary = props.allLibraryList.at(selectedIndex)

    const [searchResults, setSearchResults] = useState<ReplaceInstanceSearchResults>([])
    const [isSearching, setIsSearching] = useState(false)

    const onSearchList = useCallback(
        (searchInput: string) => {
            setIsSearching(!!searchInput)
            if (searchInput) {
                setSearchResults(
                    commands.searchByKeyword(
                        searchInput,
                        Wukong.DocumentProto.ComponentPickerTrigger.COMPONENT_PICKER_TRIGGER_NONE
                    )
                )
            }
        },
        [commands]
    )
    const onChangeLibraryIndex = (index: number) => {
        setSelectedIndex(Math.max(0, index))
    }

    const onClose = useCallback(() => {
        commands.toggleReplacePanel(false)
        onSearchList('')
    }, [commands, onSearchList])

    const isPopupMovedRef = useRef(false)

    const markPopupMoved = useCallback(() => {
        isPopupMovedRef.current = true
    }, [])

    const onClickThumbnail = useCallback(() => {
        if (!isPopupMovedRef.current) {
            onClose()
        }
    }, [onClose])

    const [stickyTitle, setStickyTitle] = useState<ReactNode>(null)
    useEffect(() => {
        if (!selectedLibrary) {
            setStickyTitle(null)
        }
    }, [selectedLibrary])

    return (
        <DraggablePopupV2
            visible
            position={props.position}
            width={216}
            positionRightBase
            onCancel={onClose}
            header={
                <div data-testid={ReplaceTestIds.title} className={style.modalTitle}>
                    <Select.MinimalSingleLevel
                        weight="semibold"
                        value={selectedIndex}
                        label={selectedLibrary?.name}
                        onChange={onChangeLibraryIndex}
                    >
                        {props.allLibraryList?.map((v, i) => (
                            <Select.MinimalSingleLevel.Option value={i} key={i} style={{ maxWidth: 200 }}>
                                {v.name}
                            </Select.MinimalSingleLevel.Option>
                        ))}
                    </Select.MinimalSingleLevel>
                </div>
            }
            bodyClassName="p-0"
            footer={null}
            maxScrollHeight={352}
            minScrollHeight={352}
            onFirstMove={markPopupMoved}
            testId={ReplaceTestIds.popup}
            closeTestId={ReplaceTestIds.closeBtn}
            styleType="editor"
        >
            <ScrollContainer
                className={style.scrollContainer}
                scrollViewProps={{ scrollbar: { autoHeight: true, autoHeightMin: 352, autoHeightMax: 352 } }}
                stickyHeader={
                    <>
                        <div className={style.searchInput}>
                            <InputV2.Search
                                onSearch={onSearchList}
                                onBlur={(e) => onSearchList(e.target.value.trim())}
                                placeholder={translation('Search')}
                                dataTestIds={{ input: ReplaceTestIds.searchInput, clearIcon: 'icon-close-svg' }}
                                autoFocus
                            />
                        </div>
                        {stickyTitle}
                    </>
                }
            >
                {isSearching ? (
                    <div className={style.searchResult} data-testid={ReplaceTestIds.searchResult}>
                        {searchResults.length > 0 ? (
                            <>
                                <div className={style.searchTitle}>{translation('tjXSMQ')}</div>
                                <div className={style.thumbnailList}>
                                    {searchResults.map(({ key, ...node }) => (
                                        <Thumbnail
                                            {...node}
                                            docId={selectedLibrary?.docId ?? ''}
                                            key={node.nodeId || key}
                                            onClick={onClickThumbnail}
                                        />
                                    ))}
                                </div>
                            </>
                        ) : (
                            <div className={style.noResult} data-testid={ReplaceTestIds.noResult}>
                                {translation('ztUfmM')}
                            </div>
                        )}
                    </div>
                ) : (
                    selectedLibrary && (
                        <ReplacePanelFoldes
                            library={selectedLibrary}
                            key={selectedLibrary?.docId}
                            onSetStickyTitle={setStickyTitle}
                            onClickThumbail={onClickThumbnail}
                        />
                    )
                )}
            </ScrollContainer>
        </DraggablePopupV2>
    )
}

export function ReplacePanelV2(props: {
    state: ReplaceInstanceState
    position?: {
        left: number
        top: number
    }
}) {
    return <ReplacePanelBody allLibraryList={props.state.allLibraryList} position={props.position} />
}
