import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Detail: '详情',
    Scope: '适用范围',
} as const

export const enTranslation = {
    Detail: 'Detail',
    Scope: 'Scope',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
