import { translation } from './grid-layout-grid-content.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Position } from '../../../../../../../ui-lib/src'
import { RGB, SolidPaint } from '../../../../../document/node/node'
import { ToKeyCode } from '../../../../../document/util/keycode'
import { featureSwitchManager } from '../../../../../kernel/switch'
import { KeyboardReceiver } from '../../../../../main/keyboard-receiver/component'
import { SingleGrid } from '../../../atom/grid/single-grid'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { ScrubbableInputInteger } from '../../../atom/inputs/scrubbable-input-integer'
import { ScrubbableInputPercent } from '../../../atom/inputs/scrubbable-input-percent'
import { Value } from '../../../atom/inputs/utils/type'
import { ColorSpace } from '../../../design/blend/color-picker/utils/color-translate'
import { ColorInteraction } from '../../../design/color-interaction/color-interaction'
import { ColorInteractionFrom } from '../../../design/color-interaction/type'
import { useFloatVariablePanel } from '../../../design/primitive-variable/use-float-variable-panel'
import { PaintIconColor } from '../../../paint-icon-color/paint-icon-color'
import style from './grid-layout-grid-content.module.less'

export interface GridLayoutContentProps {
    layoutGrid: Wukong.DocumentProto.ILayoutGrid
    variables: Wukong.DocumentProto.ISingleFloatVariable[]
    colorSpace: ColorSpace
    isMini?: boolean
    onChangeSectionSize: (value: Value, options?: InputOptionsForUndoSquash) => void
    onChangeSectionSizeVar: (value: string | null, options?: InputOptionsForUndoSquash) => void
    onChangeColor: (value: RGB, options?: InputOptionsForUndoSquash) => void
    onChangeOpacityValue: (value: Value, options?: InputOptionsForUndoSquash) => void
    onChangeOpacityNumber: (value: Value, options?: InputOptionsForUndoSquash) => void
}

export function GridLayoutContent(props: GridLayoutContentProps) {
    if (featureSwitchManager.isEnabled('float-variable')) {
        return <GridLayoutContentV2 {...props} />
    } else {
        return <GridLayoutContentV1 {...props} />
    }
}

export function GridLayoutContentV1(prop: GridLayoutContentProps) {
    const { layoutGrid, colorSpace, onChangeSectionSize, onChangeColor, onChangeOpacityNumber, onChangeOpacityValue } =
        prop
    const [isOpen, setIsOpen] = useState(false)
    const [position, setPosition] = useState<Position>()
    const ref = useRef<HTMLDivElement>(null)
    const paint = useMemo(() => {
        return layoutGrid.paints as SolidPaint
    }, [layoutGrid.paints])

    const onClickOpenModal = () => {
        setIsOpen(!isOpen)
        setPosition(ref.current?.getBoundingClientRect())
    }

    const onClose = useCallback(() => {
        setIsOpen(false)
    }, [])

    return (
        <>
            <SingleGrid className={style.container}>
                <SingleGrid.Item start={5} span={15} className={style.font}>
                    {translation('Size')}
                </SingleGrid.Item>
                <SingleGrid.Item start={22} span={35} className={style.font}>
                    {translation('Color')}
                </SingleGrid.Item>
                <SingleGrid.Item start={1} span={60}>
                    <SingleGrid ref={ref}>
                        <SingleGrid.Item start={5} span={15}>
                            <ScrubbableInputInteger
                                onChange={onChangeSectionSize}
                                value={layoutGrid.sectionSize!}
                                min={0}
                                max={999999}
                                autoFocus
                                testId={'grid-content-sectionSize'}
                            />
                        </SingleGrid.Item>

                        <SingleGrid.Item start={22} span={23} className={style.backgroundGrid}>
                            <PaintIconColor
                                paint={paint}
                                colorSpace={colorSpace}
                                onChangeColor={onChangeColor}
                                onMouseDownIcon={onClickOpenModal}
                                onClickInput={onClose}
                                isWidth={92}
                                disabled={false}
                                data-testid={'grid-content-paintIcon'}
                            />
                        </SingleGrid.Item>
                        <SingleGrid.Item start={45} span={12}>
                            <ScrubbableInputPercent
                                disabled={false}
                                value={paint.opacity}
                                onChange={onChangeOpacityNumber}
                                onClick={onClose}
                                min={0}
                                max={100}
                                className={`${paint.visible ? '' : style.hidden}`}
                                testId={'grid-content-opacity'}
                            />
                        </SingleGrid.Item>
                    </SingleGrid>
                </SingleGrid.Item>
            </SingleGrid>
            {isOpen && position && (
                <KeyboardReceiver keyCode={ToKeyCode.Esc} onKeydown={() => (onClose(), false)}>
                    <ColorInteraction
                        from={ColorInteractionFrom.GRID}
                        position={position}
                        paint={paint}
                        onCancel={onClose}
                        onChangeColor={onChangeColor}
                        onChangeOpacity={onChangeOpacityValue}
                        enterClose={onClose}
                        styleId={undefined}
                        styleKey={undefined}
                        onChangePaintType={() => {}}
                        onChangeBlendMode={() => {}}
                        onChangeImagePaint={() => {}}
                        onChangeColorStops={() => {}}
                        onChangeTransform={() => {}}
                        onChangeStyle={() => {}}
                        onChangeColorVar={() => {}}
                        onChangePaints={() => {}}
                        onClickCreateStyleButton={undefined}
                    />
                </KeyboardReceiver>
            )}
        </>
    )
}

export function GridLayoutContentV2(prop: GridLayoutContentProps) {
    const {
        layoutGrid,
        variables,
        colorSpace,
        onChangeSectionSize,
        onChangeSectionSizeVar,
        onChangeColor,
        onChangeOpacityNumber,
        onChangeOpacityValue,
    } = prop
    const [isOpen, setIsOpen] = useState(false)
    const [position, setPosition] = useState<Position>()
    const ref = useRef<HTMLDivElement>(null)
    const paint = useMemo(() => {
        return layoutGrid.paints as SolidPaint
    }, [layoutGrid.paints])

    const onClickOpenModal = () => {
        setIsOpen(!isOpen)
        setPosition(ref.current?.getBoundingClientRect())
    }

    const onClose = useCallback(() => {
        setIsOpen(false)
    }, [])

    const flag = featureSwitchManager.isEnabled('float-variable')
    const selectedVariable = flag
        ? variables.find((v) => v.id === layoutGrid.sectionSizeVar?.value.alias) ?? null
        : null
    const sectionSizeVariablePicker = useFloatVariablePanel({
        requiredScopes: [Wukong.DocumentProto.VariableScope.ALL_SCOPES],
        selectedVariable,
        createEnable: true,
        selectedVariableFallbackFloatValue: layoutGrid.sectionSize,
        defaultCreateValue: layoutGrid.sectionSize ?? 0,
        onVariableSelected: (id) => onChangeSectionSizeVar(id),
        onVariableDetach: () => onChangeSectionSizeVar(null),
    })

    return (
        <>
            <SingleGrid className={style.container}>
                <SingleGrid.Item start={5} span={15} className={style.font}>
                    {translation('Size')}
                </SingleGrid.Item>
                <SingleGrid.Item start={22} span={35} className={style.font}>
                    {translation('Color')}
                </SingleGrid.Item>
                <SingleGrid.Item start={1} span={60}>
                    <SingleGrid ref={ref}>
                        <SingleGrid.Item start={5} span={15}>
                            <ScrubbableInputInteger
                                onChange={onChangeSectionSize}
                                value={layoutGrid.sectionSize!}
                                min={0}
                                max={999999}
                                autoFocus
                                testId={'grid-content-sectionSize'}
                                useVariable={sectionSizeVariablePicker.useVariable}
                            />
                        </SingleGrid.Item>

                        <SingleGrid.Item start={22} span={23} className={style.backgroundGrid}>
                            <PaintIconColor
                                paint={paint}
                                colorSpace={colorSpace}
                                onChangeColor={onChangeColor}
                                onMouseDownIcon={onClickOpenModal}
                                onClickInput={onClose}
                                isWidth={92}
                                disabled={false}
                                data-testid={'grid-content-paintIcon'}
                            />
                        </SingleGrid.Item>
                        <SingleGrid.Item start={45} span={12}>
                            <ScrubbableInputPercent
                                disabled={false}
                                value={paint.opacity}
                                onChange={onChangeOpacityNumber}
                                onClick={onClose}
                                min={0}
                                max={100}
                                className={`${paint.visible ? '' : style.hidden}`}
                                testId={'grid-content-opacity'}
                            />
                        </SingleGrid.Item>
                    </SingleGrid>
                </SingleGrid.Item>
            </SingleGrid>
            {isOpen && position && (
                <KeyboardReceiver keyCode={ToKeyCode.Esc} onKeydown={() => (onClose(), false)}>
                    <ColorInteraction
                        from={ColorInteractionFrom.GRID}
                        position={position}
                        paint={paint}
                        onCancel={onClose}
                        onChangeColor={onChangeColor}
                        onChangeOpacity={onChangeOpacityValue}
                        enterClose={onClose}
                        styleId={undefined}
                        styleKey={undefined}
                        onChangePaintType={() => {}}
                        onChangeBlendMode={() => {}}
                        onChangeImagePaint={() => {}}
                        onChangeColorStops={() => {}}
                        onChangeTransform={() => {}}
                        onChangeStyle={() => {}}
                        onChangeColorVar={() => {}}
                        onChangePaints={() => {}}
                        onClickCreateStyleButton={undefined}
                    />
                </KeyboardReceiver>
            )}
            {sectionSizeVariablePicker.renderPanel()}
        </>
    )
}
