import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    baseFontSize: '基础文本尺寸',
    borderRadius: '圆角',
    size: '尺寸',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    baseFontSize: 'Base Font Size',
    borderRadius: 'Border Radius',
    size: 'Size',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
