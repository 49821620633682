import { useParams } from 'react-router-dom'
import { RouteToken } from '../../../../../util/src'

export const usePageType = () => {
    const pathname = useParams()['*'] ?? ''
    const isRecent = pathname?.includes(RouteToken.Recent)
    const isTrash = pathname?.includes(RouteToken.Trash)
    const isDrafts = pathname?.includes(RouteToken.Drafts)
    const isFav = pathname?.includes(RouteToken.Favorites)
    const isFolder = pathname?.includes(RouteToken.Project)
    const isTeam =
        pathname?.includes(RouteToken.Team) &&
        !pathname?.includes(`${RouteToken.Admin}/teams`) &&
        !pathname?.includes(RouteToken.Teams)
    const isTeamMembers = pathname?.includes(RouteToken.Team) && pathname?.includes('members')
    const isTeamFonts = pathname?.includes(RouteToken.Team) && pathname?.includes('fonts')
    const isTeamSetting = pathname?.includes(RouteToken.Team) && pathname?.includes('settings')
    const isTeamsettlement = pathname?.includes(RouteToken.Team) && pathname?.includes('settlement')

    const isTeams = pathname?.includes(RouteToken.Teams) && !pathname?.includes(`${RouteToken.Admin}/teams`)
    const isAdmin = pathname?.includes(`/${RouteToken.Admin}`)
    const isShared = pathname?.includes(`/${RouteToken.Shared}`)
    const isWorkspaces = pathname?.includes(`/${RouteToken.Workspaces}`) && !isAdmin
    const isWorkspace = pathname?.includes(`/${RouteToken.Workspace}/`)

    return {
        pathname,
        isRecent,
        isTrash,
        isDrafts,
        isFav,
        isFolder,
        isTeam,
        isTeamMembers,
        isTeamFonts,
        isTeams,
        isAdmin,
        isShared,
        isTeamSetting,
        isTeamsettlement,
        isWorkspaces,
        isWorkspace,
    }
}
