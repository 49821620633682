/* eslint-disable no-restricted-imports */
import { useCallback } from 'react'
import { domLocation } from '../../../../../../util/src'
import { Sentry } from '../../../../kernel/sentry'
import { CrashReportOption } from '../types'
import { IssusModal } from './issue-modal'

function reloadPage() {
    domLocation().reload()
}

export const CrashPanelTestIds = {
    confirmBtn: 'crash-confirm-btn',
    cancelBtn: 'crash-cancel-btn',
    reloadCheckbox: 'crash-reload-checkbox',
    userInput: 'crash-user-input',
}

export function CrashPanel(props: CrashReportOption) {
    const onModalClose = useCallback(async () => {
        try {
            await props.waitPromise
        } catch (e) {
            Sentry.captureException(e)
        }
        reloadPage()
    }, [props.waitPromise])

    const onSendReport = useCallback(
        async (desc: string) => {
            try {
                await props.onSendReport?.(desc)
            } catch (e) {
                console.error('ignore send report error', e)
            }
        },
        [props]
    )

    return (
        <IssusModal
            type="crash"
            visible
            onCancel={onModalClose}
            onOK={onSendReport}
            dataTestIds={{
                oKButton: CrashPanelTestIds.confirmBtn,
                cancelBtn: CrashPanelTestIds.cancelBtn,
                textarea: CrashPanelTestIds.userInput,
            }}
            onClose={onModalClose}
        />
    )
}
