/* eslint-disable no-restricted-imports */
import { DragLocalSelectionStyleCommand, Wukong } from '@wukong/bridge-proto'
import constate from 'constate'
import { MouseEventHandler, RefObject, useMemo, useRef, useState } from 'react'
import useEvent from 'react-use/lib/useEvent'
import { useCommand } from '../../../../../main/app-context'
import { DeepRequired } from '../../../../../view-state-bridge'
import { useScrollContainerRef } from '../../design-scroll-container-context'
import { ILocalStyleItem } from '../hooks/use-local-style-model-v2'
import { isItemInOrIsFolder, isItemSelected } from '../utils'

interface DragProps {
    renderList: ILocalStyleItem[]
    containerRef: RefObject<HTMLDivElement>
    selection: DeepRequired<Wukong.DocumentProto.ILocalStyleSelection>
    handleToggleFolderCollapse: (folderName: string) => void
}

export enum MouseStatus {
    Up,
    Down,
}

const ITEM_HEIGHT = 32

function useDrag(props: DragProps) {
    const { containerRef, renderList, selection, handleToggleFolderCollapse } = props
    const { getScrollContainer } = useScrollContainerRef()

    const command = useCommand()
    const dragStartRef = useRef<boolean>(false)
    const mouseStatusRef = useRef<MouseStatus>(MouseStatus.Up)
    const mouseDownPositionRef = useRef<{ x: number; y: number }>()
    const dragExpandFolderRef = useRef<string[]>([])

    // 进入目标区域默认的缩进值，如果通过 x 移动后，会变更成新的默认值
    const selectionMinIndentRef = useRef<number>()
    const caculateSelectionMinIndent = () => {
        if (selectionMinIndentRef.current === undefined) {
            // 计算出拖拽开始时选中元素的最小层级，由此来判断初次进入目标图层展示的层级
            const selectedLevels = renderList
                .filter((item) => isItemSelected(item, selection))
                .map((item) => item.level)
                .sort()
            selectionMinIndentRef.current = selectedLevels.length > 0 ? selectedLevels[0] : 0
        }
    }

    const [dragTargetInfo, setDragTargetInfo] = useState<{
        itemIndex: number
        item: ILocalStyleItem
        preItem?: ILocalStyleItem
        nextItem?: ILocalStyleItem
        mouseArea: number
        movementX: number
    }>()
    const [dropInfo, setDropInfo] = useState<{ item?: ILocalStyleItem; indent: number }>()

    const dropContainerInfo = useMemo(() => {
        if (!dropInfo?.item) {
            return
        }
        return renderList.find((item) => {
            return (
                item.isFolder && item.level + 1 == dropInfo.indent && isItemInOrIsFolder(dropInfo.item, item.folderName)
            )
        })
    }, [dropInfo, renderList])

    useEvent('mousemove', (event) => {
        const container = getScrollContainer()
        if (
            mouseStatusRef.current != MouseStatus.Down ||
            !mouseDownPositionRef.current ||
            !containerRef.current ||
            !container
        ) {
            return
        }

        // 计算当前选中item
        const { clientX, clientY, movementY } = event
        if (
            Math.abs(clientX - mouseDownPositionRef.current!.x) < 4 &&
            Math.abs(clientY - mouseDownPositionRef.current!.y) < 4
        ) {
            return
        }

        dragStartRef.current = true

        const { top, height, bottom } = containerRef.current.getBoundingClientRect()
        const scrollTop = container.scrollTop
        const { top: boundingTop } = container.getBoundingClientRect()
        const scrollMovement = Math.abs(movementY) * 10

        let targetPositionY = 0
        if (top < boundingTop && clientY < boundingTop) {
            container.scrollTop = Math.max(0, scrollTop - scrollMovement)
            targetPositionY = boundingTop - top - scrollMovement
        } else if (bottom > window.innerHeight && clientY >= window.innerHeight - 1) {
            // NOTE: clientY >= window.innertHeight-1因为拖动到底部时可能存在1px偏差
            container.scrollTop = Math.max(0, scrollTop + scrollMovement)
            targetPositionY = window.innerHeight - top + scrollMovement
        } else {
            targetPositionY = clientY - top
        }
        targetPositionY = Math.max(0, Math.min(targetPositionY, height))

        const targetItemIndex = Math.max(0, Math.min(Math.floor(targetPositionY / ITEM_HEIGHT), renderList.length - 1))
        const mouseArea = Math.min(targetPositionY / ITEM_HEIGHT - targetItemIndex, 1)
        const movementX = clientX - mouseDownPositionRef.current.x

        caculateSelectionMinIndent()
        setDragTargetInfo({
            itemIndex: targetItemIndex,
            item: renderList[targetItemIndex],
            preItem: renderList[targetItemIndex - 1],
            nextItem: renderList[targetItemIndex + 1],
            mouseArea,
            movementX,
        })
    })

    const handleMouseUp: MouseEventHandler<HTMLDivElement> = () => {
        if (dragStartRef.current && dropInfo) {
            const param = {
                isFolder: dropInfo.item?.isFolder ?? false,
                styleId: dropInfo.item?.styleId ?? '',
                folderName: dropInfo.item?.folderName ?? '',
                indent: dropInfo.indent,
            }

            // 拖拽至目标节点的对应层级位置
            command.DEPRECATED_invokeBridge(
                DragLocalSelectionStyleCommand,
                Wukong.DocumentProto.DragLocalSelectionStyleCommandParam.create(param)
            )
            command.commitUndo()
        }

        // 清空状态
        mouseDownPositionRef.current = undefined
        selectionMinIndentRef.current = undefined
        dragStartRef.current = false
        mouseStatusRef.current = MouseStatus.Up

        // 折叠拖拽过程中自动展开的分组
        dragExpandFolderRef.current
            .filter((folderName) => {
                return folderName && !isItemInOrIsFolder(dropInfo?.item, folderName)
            })
            .map((folderName) => handleToggleFolderCollapse(folderName))
        dragExpandFolderRef.current = []
        setDropInfo(undefined)
        setDragTargetInfo(undefined)
    }

    useEvent('mouseup', (event) => {
        handleMouseUp(event)
    })

    return {
        dragStartRef,
        mouseStatusRef,
        mouseDownPositionRef,
        selectionMinIndentRef,
        dragExpandFolderRef,
        selection,
        dragTargetInfo,
        dropInfo,
        dropContainerInfo,
        setDropInfo,
        handleMouseUp,
        handleToggleFolderCollapse,
    }
}

export const [DragContextProvider, useDragContext] = constate(useDrag, (ctx) => ctx)
