import { useCallback, useLayoutEffect, useRef, useState } from 'react'

// WKDialog 适配有问题。但是设计也没定如何适配。这里保留原来的逻辑下提供个研发认为合理的规则用于适配
export function useWKDialogMarginTop(enabled?: boolean) {
    const [marginTop, setMarginTop] = useState<number | string>()
    const ref = useRef<HTMLDivElement>(null)

    const onChangeResize = useCallback(() => {
        if (!enabled) {
            return
        }
        const rect = ref.current?.getBoundingClientRect()
        if (!rect) {
            return
        }
        const clientHeight = document.documentElement.clientHeight
        if (rect.bottom > clientHeight) {
            const top = Math.max(0, clientHeight - rect.height)
            setMarginTop(top)
        } else {
            // 0.15 是WKDialog指定的marginTop: 15vh
            const top = Math.min(clientHeight * 0.15, (clientHeight - rect.height) / 2)
            setMarginTop(top)
        }
    }, [enabled])

    useLayoutEffect(() => onChangeResize(), [onChangeResize])

    return {
        ref,
        marginTop,
        onChangeResize,
    }
}
