import { EditorMode } from '../../document/node/node'
import { WKFrog } from '../../kernel/frog'

/**
 * 工具切换
 *
 * Trigger Time: 工具发生切换时上报
（打开文件不算，进出研发模式不算、进出历史版本不算）
 *
 * @param {Record<string, string | number | undefined>} params 埋点参数
 */

const editorMode2Labels = (mode: EditorMode) => {
    switch (mode) {
        case EditorMode.EM_Move:
            return '移动工具'
        case EditorMode.EM_Scale:
            return '缩放工具'
        case EditorMode.EM_Frame:
            return '容器工具'
        case EditorMode.EM_Rectangle:
            return '矩形工具'
        case EditorMode.EM_Line:
            return '直线工具'
        case EditorMode.EM_Arrow:
            return '箭头工具'
        case EditorMode.EM_Ellipse:
            return '椭圆工具'
        case EditorMode.EM_Polygon:
            return '多边形工具'
        case EditorMode.EM_Star:
            return '星形工具'
        case EditorMode.EM_MenuPlaceImage:
            return '图片工具'
        case EditorMode.EM_Text:
            return '文本工具'
        case EditorMode.EM_PEN:
            return '钢笔工具'
        case EditorMode.EM_BendTool:
            return '曲率工具'
        case EditorMode.EM_PaintBucket:
            return '填充工具'
        case EditorMode.EM_HandTool:
            return '手形工具'
        case EditorMode.EM_Comment:
            return '评论工具'
        case EditorMode.EM_Slice:
            return '切片工具'
        case EditorMode.EM_DevModeInspect:
            return '标注工具'
        case EditorMode.EM_Copilot:
        case EditorMode.EM_PlaceImage:
        case EditorMode.EM_AiDuplicate:
            return undefined
    }
}

const docMode2Labels = (mode: {
    is_readonly: boolean
    is_dev_mode: boolean
    is_history_version: boolean
    is_vector: boolean
}) => {
    if (mode.is_history_version) {
        if (mode.is_dev_mode) {
            return '历史版本(研发)'
        }
        return '历史版本(非研发)'
    }
    if (mode.is_vector) {
        return '矢量编辑模式'
    }
    if (mode.is_dev_mode) {
        return '研发模式'
    }
    if (mode.is_readonly) {
        return '只读模式'
    }
    return '常规编辑模式'
}

export function frogEventToolsMenusAndActionsToolSelect(params: {
    tool_select_way: '工具栏点击' // 选中工具方式
    current_tool: string | number // 当前已选中的工具
    previous_tool?: string | number // 切换前选中的工具
    doc_mode: {
        is_readonly: boolean
        is_dev_mode: boolean
        is_history_version: boolean
        is_vector: boolean
    }
}) {
    const { ...customExtend } = params
    const current_tool = editorMode2Labels(+customExtend.current_tool as EditorMode)
    WKFrog.addFrogRecord({
        url: '/event/ToolsMenusAndActions/toolSelect',
        eventId: 30457,
        customExtend: {
            current_tool,
            tool_select_way: customExtend.tool_select_way,
            previous_tool: editorMode2Labels(customExtend.previous_tool as EditorMode),
            current_mode: docMode2Labels(customExtend.doc_mode),
        },
        currentPage: 'ToolsMenusAndActions',
        eventAction: 'event',
        eventName: 'toolSelect',
    })
    if (current_tool == '钢笔工具') {
        frogEventToolsMenusAndActionsBasicAction({
            action_name: '进入矢量编辑状态',
            operation_way: '选中钢笔工具',
        })
    }
}

/**
 * 菜单打开
 *
 * Trigger Time: 曝光/展示一次，上报一条数据，不限制用户停留时长。若在页面中，则上下滑动不上报
 *
 * @param {Record<string, string | number | undefined>} params 埋点参数
 */
export function frogExposeToolsMenusAndActionsMenuDisplay(params: {
    doc_mode: {
        is_readonly: boolean
        is_dev_mode: boolean
        is_history_version: boolean
        is_vector: boolean
    } // 当前模式
    displayed_menu?: string // 展示的菜单
}) {
    const { ...customExtend } = params
    WKFrog.addFrogRecord({
        url: '/expose/ToolsMenusAndActions/menuDisplay',
        eventId: 30458,
        customExtend: {
            current_mode: docMode2Labels(customExtend.doc_mode),
            displayed_menu: customExtend.displayed_menu,
        },
        currentPage: 'ToolsMenusAndActions',
        eventAction: 'expose',
        eventName: 'menuDisplay',
    })
}

/**
 * 基础操作
 *
 * Trigger Time: 执行功能时触发（具体功能见 参数 action_name 的值）
 *
 * @param {Record<string, string | number | undefined>} params 埋点参数
 */
export function frogEventToolsMenusAndActionsBasicAction(params: {
    operation_way?: string // 操作方式
    action_name?: string // 功能名
}) {
    const { ...customExtend } = params
    WKFrog.addFrogRecord({
        url: '/event/ToolsMenusAndActions/basicAction',
        eventId: 30459,
        customExtend,
        currentPage: 'ToolsMenusAndActions',
        eventAction: 'event',
        eventName: 'basicAction',
    })
}
