import type { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import type { PropsWithChildren } from 'react'
import type { DeepRequired } from '../../../../../../view-state-bridge'
import { DevModeBoxModelTestId } from '../../../../../../window'
import classes from '../box-model.module.less'
import { DevModeInspectCopyableValue } from '../common/number-value'
import { formatNumberValue } from '../utils/format-value'

export function MarginBox(
    props: PropsWithChildren<{ value: DeepRequired<Wukong.DocumentProto.IDevModeInspectBoxModelCommonLayout> }>
) {
    return (
        // NOTE: gridTemplateColumns 写到 style 里面，是因为 module.less 中不识别 min(calc(100% - 64px), 320px) 这种写法，导致打包为 320px，样式出错
        <div
            className={classNames(classes.marginBoxContainer)}
            style={{
                gridTemplateColumns:
                    'max(32px, calc(50% - 160px)) min(calc(100% - 64px), 320px) max(32px, calc(50% - 160px))',
            }}
            data-testid={DevModeBoxModelTestId.Margin}
        >
            <div />
            <MarginMeasure isVertical margin={props.value.topMargin} />
            <div />
            <MarginMeasure isVertical={false} margin={props.value.leftMargin} />
            <div>{props.children}</div>
            <MarginMeasure isVertical={false} margin={props.value.rightMargin} />
            <div />
            <MarginMeasure isVertical margin={props.value.bottomMargin} />
            <div />
        </div>
    )
}
function MarginMeasure(props: {
    isVertical: boolean
    margin: DeepRequired<Wukong.DocumentProto.IDevModeInspectBoxModelMargin>
}) {
    const marginValue = formatNumberValue(props.margin.value)
    return (
        <div className={classNames(classes.marginMeasureContainer, classes.marginMeasureContainerEnhancement)}>
            {!!marginValue && (
                <div
                    className={classNames(props.isVertical ? classes.verticalContainer : classes.horizontalContainer, {
                        [classes.gapping]: props.margin.isGapping,
                    })}
                >
                    <div className={classes.measureLine} />
                    <DevModeInspectCopyableValue className={classes.measureValue} value={marginValue} feedback="deep" />
                </div>
            )}
        </div>
    )
}
