import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    FillContainer: '填充容器',
    HugContents: '包围内容',
    Auto: '自动',
    AutoLayout: '自动布局',
    VerticalLayout: '垂直',
    HorizontalLayout: '水平',
    Wrap: '换行',
    AdvancedAutoLayout: '更多设置',
    CommonVerticalGap: '间距',
    CommonHorizontalGap: '间距',
    VerticalGap: '垂直间距',
    HorizontalGap: '水平间距',
    LeftPadding: '左边距',
    TopPadding: '上边距',
    IndividualPadding: '独立边距',
    RightPadding: '右边距',
    BottomPadding: '下边距',
    HorizontalPadding: '水平边距',
    VerticalPadding: '垂直边距',
    LeftBracket: '（',
    RightBracket: '）',
} as const

export const enTranslation = {
    FillContainer: 'Fill container',
    HugContents: 'Hug contents',
    Auto: 'Auto',
    AutoLayout: 'Auto layout',
    VerticalLayout: 'Vertical layout',
    HorizontalLayout: 'Horizontal layout',
    Wrap: 'Wrap',
    AdvancedAutoLayout: 'Advanced auto layout settings',
    CommonVerticalGap: 'Vertical gap between items',
    CommonHorizontalGap: 'Horizontal gap between items',
    VerticalGap: 'Vertical gap between items',
    HorizontalGap: 'Horizontal gap between items',
    LeftPadding: 'Left padding',
    TopPadding: 'Top padding',
    IndividualPadding: 'Individual padding',
    RightPadding: 'Right padding',
    BottomPadding: 'Bottom padding',
    HorizontalPadding: 'Horizontal padding',
    VerticalPadding: 'Vertical padding',
    LeftBracket: ' (',
    RightBracket: ')',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
