import { ConversationType, DialogueAnswer, DialogueAnswerType } from '../../../../kernel/interface/chatbot'
import { featureSwitchManager } from '../../../../kernel/switch/core'

export interface ChatbotConversationRef {
    scrollBy: (y: number) => void // 基于当前位置便宜多少
}

export enum AgentAnswerType {
    DIALOGUE = 'dialogue', // 对话
    EXECUTE = 'execute', // 编辑文档(执行脚本)
    DENY = 'deny', // 拒绝执行消息
    ASSETS_SEARCH = 'assets-search', // 资产搜索
    INSPIRE_SEARCH = 'inspire-search', // 灵感搜索
}

export const getPendingAgentAnswerType = (conversationType: ConversationType): AgentAnswerType => {
    switch (conversationType) {
        case ConversationType.ASK:
        case ConversationType.USE_HELP:
            return AgentAnswerType.DIALOGUE
        case ConversationType.SEARCH:
            return AgentAnswerType.ASSETS_SEARCH
        case ConversationType.IDEA_DISCOVERY:
            return AgentAnswerType.INSPIRE_SEARCH
        case ConversationType.EXECUTE:
            return AgentAnswerType.EXECUTE
    }
}

export const getAgentAnswerType = (answer: DialogueAnswer): AgentAnswerType => {
    // 有 type 根据 type 判断
    if (answer?.type) {
        switch (answer.type) {
            case DialogueAnswerType.ASK:
            case DialogueAnswerType.USE_HELP:
                return AgentAnswerType.DIALOGUE
            case DialogueAnswerType.EXECUTE:
                return AgentAnswerType.EXECUTE
            case DialogueAnswerType.DENY:
                return featureSwitchManager.isEnabled('chatbot-v02') ? AgentAnswerType.DENY : AgentAnswerType.DIALOGUE
            case DialogueAnswerType.SEARCH:
                if (!answer.ops || answer.ops.length === 0) {
                    return AgentAnswerType.DIALOGUE
                }
                if ('searchType' in answer.ops[0]) {
                    return AgentAnswerType.ASSETS_SEARCH
                }
                return AgentAnswerType.DIALOGUE
            case DialogueAnswerType.IDEA_DISCOVERY:
                if (!answer.ops || answer.ops.length === 0) {
                    return AgentAnswerType.DIALOGUE
                }
                if ('imageUrls' in answer.ops[0]) {
                    return AgentAnswerType.INSPIRE_SEARCH
                }
                return AgentAnswerType.DIALOGUE
        }
    }

    if (!answer.ops || answer.ops.length === 0) {
        return AgentAnswerType.DIALOGUE
    }

    if ('code' in answer.ops[0] && typeof answer.ops[0].code === 'string') {
        return AgentAnswerType.EXECUTE
    }
    if ('searchType' in answer.ops[0]) {
        return AgentAnswerType.ASSETS_SEARCH
    } else if ('imageUrls' in answer.ops[0]) {
        return AgentAnswerType.INSPIRE_SEARCH
    } else if (answer.suggest && answer.suggest.length > 0) {
        return featureSwitchManager.isEnabled('chatbot-v02') ? AgentAnswerType.DENY : AgentAnswerType.DIALOGUE
    }

    return AgentAnswerType.DIALOGUE
}
