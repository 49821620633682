/* eslint-disable no-restricted-imports */
import {
    DisableSingleViewStateCommand,
    EnableSingleViewStateCommand,
    GetSingleViewStateValueCommand,
    SendViewStateToJs,
    Wukong,
} from '@wukong/bridge-proto'
import { TraceableAbortSignal } from '../../../util/src/abort-controller/traceable-abort-controller'
import { ClassWithEffect, EffectController } from '../../../util/src/effect-controller'
import { EmBridge } from '../kernel/bridge/em-bridge'
import { WK } from '../window'
import { ViewStateTokenTypeMap } from './view-state-token-type-map'
import { ViewStateToken } from './view-state-tokens'

import ViewState = Wukong.DocumentProto.ViewState
import BridgeProtoString = Wukong.DocumentProto.BridgeProtoString

type SyncViewStateFn<T extends ViewStateToken> = (value: ViewStateTokenTypeMap[T]) => void
type RegistersRecord = { [key in ViewStateToken]?: Set<SyncViewStateFn<key>> }
type StateRecord = { [key in ViewStateToken]?: ViewStateTokenTypeMap[key] }

/**
 * 用于连接 WASM 和 UI 的 Bridge
 * 在 init 阶段通过 bridge 绑定 viewStateToken
 * UI 在 runtime 通过 hooks 绑定 viewState 的订阅关系
 * 在 WASM 数据变更后，通过观察订阅者模式分发给订阅者新值
 */
export class ViewStateBridge extends ClassWithEffect {
    private registersMap: RegistersRecord = {}
    private client2StateMap: Record<number, StateRecord> = {}
    private registedFn2Priority: Map<(value: any) => void, number> = new Map()

    // 注册优先级权重
    private prioryty = 1

    constructor(private bridge: EmBridge, controller: EffectController) {
        super(controller)
        this.bindViewState()
        WK.viewState = () => this.client2StateMap[this.bridge.clientId]
        WK.updateViewState = this.updateRegistersAccordingToPriority
    }

    /**
     * 将 viewState 的更新都绑定到 bridge 上
     * @private
     */
    private bindViewState(): void {
        this.bridge.bind(SendViewStateToJs, (_viewState: Wukong.DocumentProto.IViewState) => {
            // 将获取的 viewStateClass toValue 到 object，不携带 optional 的默认值
            const viewState = ViewState.toObject(_viewState as Wukong.DocumentProto.ViewState, { defaults: true })

            this.updateRegistersAccordingToPriority(viewState)
        })
    }

    /**
     * 按照注册顺序反序更新订阅者
     * @param token
     * @param value
     */
    private updateRegistersAccordingToPriority = (viewState: Record<string, any>): void => {
        const updateTasks: { task: () => void; priority: number }[] = []

        Object.entries(viewState).forEach(([key, value]) => {
            const token_ = key as ViewStateToken
            const value_ = value as ViewStateTokenTypeMap[ViewStateToken]

            // 缓存最新的值，在下游订阅的时候可以 replay 出去，这个地方需要注意关联到对应的 clinetId
            this.fillRelpayState(token_, value_)

            // 存储更新任务
            this.registersMap[token_]?.forEach((register) => {
                updateTasks.push({
                    task: () => {
                        register(value_ as never)
                    },
                    priority: this.registedFn2Priority.get(register)!,
                })
            })
        })

        // 按照优先级逆向执行
        // 对应组件后加载的先执行，满足组件依赖顺序
        updateTasks.sort((a, b) => b.priority - a.priority).forEach(({ task }) => task())
    }

    /**
     * 根据 clientId 填充对应的 replayState
     * @param token
     * @param value
     */
    private fillRelpayState<T extends ViewStateToken>(token: T, value: ViewStateTokenTypeMap[T]): void {
        if (!this.client2StateMap[this.bridge.clientId]) {
            this.client2StateMap[this.bridge.clientId] = {}
        }

        ;(this.client2StateMap[this.bridge.clientId] as StateRecord)[token] = value
    }

    /**
     * 删除 token 对应的回放值
     * 避免 token 在 unregister 后重新获取时，获取到过期的值【因为此时 viewstate 为 lazy 状态】
     * @param token
     */
    private deleteRelpayState<T extends ViewStateToken>(token: T): void {
        delete this.client2StateMap[this.bridge.clientId]?.[token]
    }

    /**
     * 注册 viewState 的变更函数
     * @param token
     * @param syncViewStateFn
     */
    public register<T extends ViewStateToken>(token: T, syncViewStateFn: SyncViewStateFn<T>): void {
        if (this.controller.aborted) {
            return
        }
        if (!this.registersMap[token]) {
            ;(this.registersMap[token] as Set<SyncViewStateFn<T>> | undefined) = new Set()
            // 激活订阅
            this.bridge.call(
                EnableSingleViewStateCommand,
                BridgeProtoString.create({
                    value: token,
                })
            )
        }
        ;(this.registersMap[token] as Set<SyncViewStateFn<T>> | undefined)?.add(syncViewStateFn)
        this.registedFn2Priority.set(syncViewStateFn, this.prioryty++)
    }

    public registerWithSignal<T extends ViewStateToken>(
        signal: TraceableAbortSignal,
        token: T,
        syncViewStateFn: SyncViewStateFn<T>
    ): void {
        this.register(token, syncViewStateFn)

        signal.addEventListener('abort', () => {
            this.unregister(token, syncViewStateFn)
        })
    }

    /**
     * 清除 viewState 的变更函数
     * @param token
     * @param updateViewStateFn
     */
    public unregister<T extends ViewStateToken>(token: T, updateViewStateFn: SyncViewStateFn<T>): void {
        if (this.controller.aborted) {
            return
        }

        const registerSet = this.registersMap[token]
        if (registerSet?.has(updateViewStateFn)) {
            registerSet.delete(updateViewStateFn)
            // 删除权重映射
            this.registedFn2Priority.delete(updateViewStateFn)
            // 当删除依赖后对该 token 没有依赖项了，在 wasm 标记不再计算该 token，并删除记录的旧值
            if (registerSet.size == 0) {
                this.bridge.call(
                    DisableSingleViewStateCommand,
                    BridgeProtoString.create({
                        value: token,
                    })
                )
                // 删除旧记录，确保重新触发订阅时重新拉取
                this.deleteRelpayState(token)
            }
        }
    }

    /**
     * 获取 viewstate token 的默认值，当没有建立订阅关系时，会优先通过 viewstate 获取一次
     * 如果存储里已经有 token 了，标明至少在值存储里至少有一份数据，则直接返回对应数据
     * @param token
     * Protobuf 对于对象无值情况的 decode 结果为 undefined，这时候不一定能拿到值，需要自己填入默认值
     * @param defaultValue
     * @returns
     */
    public getDefaultValue<T extends ViewStateToken>(
        token: T,
        defaultValue?: ViewStateTokenTypeMap[T] | undefined
    ): ViewStateTokenTypeMap[T] {
        const tokenStoreValue = this.client2StateMap[this.bridge.clientId]?.[token]
        // 如果不存在 token 对应的存储值，则先从 wasm 拉取并填充
        if (!tokenStoreValue) {
            if (this.controller.aborted) {
                return defaultValue as ViewStateTokenTypeMap[T]
            }

            const _viewState = this.bridge.call(
                GetSingleViewStateValueCommand,
                BridgeProtoString.create({
                    value: token,
                })
            )

            // FIXME: wasm中所有computeViewState确保不返回nullopt后，可以去掉这个检查
            if (!(token in _viewState)) {
                throw new Error(`get ${token} failed from wasm`)
            }

            // 与增量更新保持一致的转换逻辑, 避免首次订阅和增量更新的ViewState数据不一致
            const viewState = ViewState.toObject(_viewState as ViewState, { defaults: true })
            // struct 里的 key 均未被填充时，返回的结果可能是 undefined，此情况可以通过在 struct 加入基础类型或 js 传入默认值来解决
            const value = (viewState[token] as ViewStateTokenTypeMap[T]) ?? defaultValue
            // 将 token 对应的值加入 cache
            this.fillRelpayState(token, value)
            return value
        } else {
            return tokenStoreValue as ViewStateTokenTypeMap[T]
        }
    }

    public destroy() {
        this.bridge.unbind(SendViewStateToJs)
        this.registersMap = {}
        this.client2StateMap = {}
        delete WK.viewState
        delete WK.updateViewState
    }
}
