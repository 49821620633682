import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    BooleanGroups: '布尔运算',
} as const

export const enTranslation = {
    BooleanGroups: 'Boolean groups',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
