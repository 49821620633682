import { Wukong } from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { SpaceStoreType } from './types'
// team的prop变更包括name、icon、publicStatus、font，其中会影响权限的是publicStatus
// folder的prop变更包括name、memberPermission，其中会影响权限的是memberPermission
// doc的prop变更包括status、memberPermission、accessConfig、thumbnailResourceId、canvasBackgroundColor、thumbnailNodeId，其中会影响权限的是status、memberPermission、accessConfig

// team和folder直接根据propchange来刷新接口我觉得没问题，因为毕竟name、icon、font的修改都是极低频操作
// doc的封面图变更可能会比较频繁，所以最好别直接刷接口
export const generateSpaceNotifyFlow = (store: SpaceStoreType) => {
    const addSpaceIntervalCallback = (callback: () => void) =>
        store.getState().spaceNotifyStore.instance.addSpaceIntervalCallback(callback)
    // 属性变更
    const addPropertyChangeCallback = (callback: (msg: Wukong.NotifyProto.BusinessEntityPropertiesChange) => void) => {
        store.getState().spaceNotifyStore.instance.addPropertyChangeCallback(callback)
    }
    const addPropertyChangeCallbackWithoutDocAndUser = (
        callback: (msg: Wukong.NotifyProto.BusinessEntityPropertiesChange) => void
    ) => {
        addPropertyChangeCallback((body) => {
            if (
                body.businessEntity?.entityType != Wukong.NotifyProto.EntityType.DOC &&
                body.businessEntity?.entityType != Wukong.NotifyProto.EntityType.USER
            ) {
                callback(body)
            }
        })
    }

    const addDocNameChangeCallback = (callback: (msg: { id: string; name: string }) => void) => {
        addPropertyChangeCallback((body) => {
            if (body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.DOC && !!body.changedProperties.name) {
                callback({
                    id: body.businessEntity?.entityId!,
                    name: body.changedProperties.name,
                })
            }
        })
    }
    // 文档封面id变更
    const addDocThumbnailIdChangeCallback = (callback: (msg: { id: string; thumbnailNodeId: string }) => void) => {
        addPropertyChangeCallback((body) => {
            if (
                body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.DOC &&
                !isNil(body.changedProperties.thumbnailNodeId)
            ) {
                callback({
                    id: body.businessEntity?.entityId!,
                    thumbnailNodeId: body.changedProperties.thumbnailNodeId!,
                })
            }
        })
    }
    // 文档封面背景色变更
    const addDocThumbnailBgColorChangeCallback = (
        callback: (msg: { id: string; canvasBackgroundColor: string }) => void
    ) => {
        addPropertyChangeCallback((body) => {
            if (
                body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.DOC &&
                !isNil(body.changedProperties.canvasBackgroundColor)
            ) {
                callback({
                    id: body.businessEntity?.entityId!,
                    canvasBackgroundColor: body.changedProperties.canvasBackgroundColor!,
                })
            }
        })
    }
    // 文档封面url变更
    const addDocThumbnailUrlChangeCallback = (callback: (msg: { id: string; thumbnailUrl: string }) => void) => {
        addPropertyChangeCallback((body) => {
            if (
                body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.DOC &&
                !isNil(body.changedProperties.thumbnailUrl)
            ) {
                callback({
                    id: body.businessEntity?.entityId!,
                    thumbnailUrl: body.changedProperties.thumbnailUrl!,
                })
            }
        })
    }

    // user userBrief 变更
    const addUserBriefChangeCallback = (
        callback: (msg: { id: string; nickname: string; avatarId: string }) => void
    ) => {
        addPropertyChangeCallback((body) => {
            if (
                body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.USER &&
                (!!body.changedProperties.nickname || !!body.changedProperties.avatarId)
            ) {
                callback({
                    id: body.businessEntity?.entityId!,
                    nickname: body.changedProperties.nickname,
                    avatarId: body.changedProperties.avatarId,
                })
            }
        })
    }

    // 团队排序修改 属于个人属性变更
    const addTeamOrderChangeCallback = (callback: (msg: string[]) => void) => {
        addPropertyChangeCallback((body) => {
            if (
                body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.USER &&
                !!body.changedProperties['preference:team']
            ) {
                callback(JSON.parse(body.changedProperties['preference:team']) as string[])
            }
        })
    }
    // 项目排序修改 属于个人属性变更 因为可能会移动项目到其他团队 所以应该用接口刷
    const addFolderOrderChangeCallback = (callback: () => void) => {
        addPropertyChangeCallback((body) => {
            if (
                body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.USER &&
                !!body.changedProperties['preference:folder']
            ) {
                callback()
            }
        })
    }
    // 收藏项目修改 属于个人属性变更
    const addFavoriteFolderChangeCallback = (callback: (msg: { id: string; type: 'delete' | 'add' }) => void) => {
        addPropertyChangeCallback((body) => {
            if (
                body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.USER &&
                !!body.changedProperties.favoriteFOLDER
            ) {
                callback({
                    id: body.changedProperties?.favoriteFOLDER!,
                    type: body.changedProperties.operationType as 'delete' | 'add', // 'delete' | 'add'
                })
            }
        })
    }

    // 文档收藏修改 属于个人属性变更
    const addFavoriteDocChangeCallback = (callback: (msg: { id: string; type: 'delete' | 'add' }) => void) => {
        addPropertyChangeCallback((body) => {
            if (
                body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.USER &&
                !!body.changedProperties.favoriteDOC
            ) {
                callback({
                    id: body.changedProperties?.favoriteDOC!,
                    type: body.changedProperties.operationType as 'delete' | 'add', // 'delete' | 'add'
                })
            }
        })
    }

    // 关系变更
    const addRelationChangeCallback = (callback: (msg: Wukong.NotifyProto.BusinessEntityRelationChange) => void) => {
        store.getState().spaceNotifyStore.instance.addRelationChangeCallback(callback)
    }
    const addRelationChangeCallbackWithoutAccess = (
        callback: (msg: Wukong.NotifyProto.BusinessEntityRelationChange) => void
    ) => {
        store.getState().spaceNotifyStore.instance.addRelationChangeCallback((body) => {
            if (body.relation?.relation !== Wukong.NotifyProto.Relation.ACCESS) {
                callback(body)
            }
        })
    }

    const addUserOrganizationRoleChangeCallback = (callback: (msg: { userId: number; orgId: string }) => void) => {
        addRelationChangeCallbackWithoutAccess((body) => {
            if (
                body.relation?.one?.entityType == Wukong.NotifyProto.EntityType.ORG &&
                body.relation?.another?.entityType == Wukong.NotifyProto.EntityType.USER
            ) {
                callback({ userId: +body.relation?.another?.entityId!, orgId: body.relation?.one?.entityId! })
            }
        })
    }

    const addUserTeamPlanChangeCallback = (callback: (msg: { userId: number; teamId: string }) => void) => {
        addRelationChangeCallbackWithoutAccess((body) => {
            if (
                body.relation?.one?.entityType == Wukong.NotifyProto.EntityType.TEAM &&
                body.relation?.another?.entityType == Wukong.NotifyProto.EntityType.USER &&
                body.changeType == Wukong.NotifyProto.RelationChangeType.UPDATE &&
                body.relation?.relation == Wukong.NotifyProto.Relation.AUTHORIZATION
            ) {
                callback({ userId: +body.relation?.another?.entityId!, teamId: body.relation?.one?.entityId! })
            }
        })
    }
    const addUserOrgPlanChangeCallback = (callback: (msg: { userId: number; orgId: string }) => void) => {
        addRelationChangeCallbackWithoutAccess((body) => {
            if (
                body.relation?.one?.entityType == Wukong.NotifyProto.EntityType.ORG &&
                body.relation?.another?.entityType == Wukong.NotifyProto.EntityType.USER &&
                body.changeType == Wukong.NotifyProto.RelationChangeType.UPDATE &&
                body.relation?.relation == Wukong.NotifyProto.Relation.AUTHORIZATION
            ) {
                callback({ userId: +body.relation?.another?.entityId!, orgId: body.relation?.one?.entityId! })
            }
        })
    }
    // 新增 删除团队
    const addTeamRelationChangeCallback = (callback: (msg: { teamId: string }) => void) => {
        addRelationChangeCallbackWithoutAccess((body) => {
            if (body.relation?.one?.entityType == Wukong.NotifyProto.EntityType.TEAM) {
                callback({ teamId: body.relation?.one?.entityId! })
            }
        })
    }

    // 项目关系变更
    const addFolderRelationChangeCallback = (callback: (msg: { folderId: string }) => void) => {
        addRelationChangeCallbackWithoutAccess((body) => {
            if (body.relation?.one?.entityType == Wukong.NotifyProto.EntityType.FOLDER) {
                callback({ folderId: body.relation?.one?.entityId! })
            }
        })
    }
    const addWorkspaceRelationChangeCallback = (callback: () => void) => {
        addRelationChangeCallbackWithoutAccess((body) => {
            if (body.relation?.one?.entityType == Wukong.NotifyProto.EntityType.WORKSPACE) {
                callback()
            }
        })
    }

    // 新增 删除文件 返回的是文档所在的项目id, docId
    const addDocRelationChangeCallback = (callback: (msg: { folderId: string; docId: string }) => void) => {
        addRelationChangeCallbackWithoutAccess((body) => {
            if (
                body.relation?.one?.entityType == Wukong.NotifyProto.EntityType.DOC &&
                [Wukong.NotifyProto.RelationChangeType.ADD, Wukong.NotifyProto.RelationChangeType.REMOVE].includes(
                    body.changeType
                )
            ) {
                callback({ folderId: body.relation?.another?.entityId!, docId: body.relation?.one?.entityId! })
            }
        })
    }

    // 进入/离开文档
    const addDocOnlineUserChangeCallback = (callback: (id: string) => void) => {
        addRelationChangeCallback((body) => {
            if (
                [Wukong.NotifyProto.RelationChangeType.ADD, Wukong.NotifyProto.RelationChangeType.REMOVE].includes(
                    body.changeType
                ) &&
                body.relation?.relation == Wukong.NotifyProto.Relation.ACCESS &&
                body.relation?.one?.entityType == Wukong.NotifyProto.EntityType.USER &&
                body.relation?.another?.entityType == Wukong.NotifyProto.EntityType.DOC
            ) {
                callback(body.relation?.another?.entityId!)
            }
        })
    }

    // 项目属性变更
    const addFolderPropertyChangeCallback = (callback: (id: string) => void) => {
        addPropertyChangeCallback((body) => {
            if (body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.FOLDER) {
                callback(body.businessEntity?.entityId!)
            }
        })
    }

    // 团队属性变更
    const addTeamPropertyChangeCallback = (callback: (id: string) => void) => {
        addPropertyChangeCallback((body) => {
            if (body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.TEAM) {
                callback(body.businessEntity?.entityId!)
            }
        })
    }

    // 新增 删除原型文件 返回的是文档所在的项目id, docId
    const addPrototypeRelationChangeCallback = (callback: (msg: { prototypeId: string }) => void) => {
        addRelationChangeCallbackWithoutAccess((body) => {
            if (
                [Wukong.NotifyProto.RelationChangeType.ADD, Wukong.NotifyProto.RelationChangeType.REMOVE].includes(
                    body.changeType
                ) &&
                body.relation?.one?.entityType == Wukong.NotifyProto.EntityType.DOC_PROTOTYPE
            ) {
                callback({ prototypeId: body.relation?.one?.entityId! })
            }
        })
    }

    // 原型收藏修改 属于个人属性变更
    const addPrototypeFavoriteChangeCallback = (callback: (msg: { id: string; type: 'delete' | 'add' }) => void) => {
        addPropertyChangeCallback((body) => {
            if (
                body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.USER &&
                !!body.changedProperties.favoriteDOC_PROTOTYPE
            ) {
                callback({
                    id: body.changedProperties?.favoriteDOC_PROTOTYPE!,
                    type: body.changedProperties.operationType as 'delete' | 'add', // 'delete' | 'add'
                })
            }
        })
    }

    // 原型文档pageName变更
    const addPrototypePageNameChangeCallback = (callback: (msg: { id: string; name: string }) => void) => {
        addPropertyChangeCallback((body) => {
            if (
                body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.DOC_PROTOTYPE &&
                !!body.changedProperties.pageName
            ) {
                callback({
                    id: body.businessEntity?.entityId!,
                    name: body.changedProperties.pageName,
                })
            }
        })
    }
    // 原型文档封面背景色变更
    const addPrototypeThumbnailBgColorChangeCallback = (
        callback: (msg: { id: string; canvasBackgroundColor: string }) => void
    ) => {
        addPropertyChangeCallback((body) => {
            if (
                body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.DOC_PROTOTYPE &&
                !isNil(body.changedProperties.canvasBackgroundColor)
            ) {
                callback({
                    id: body.businessEntity?.entityId!,
                    canvasBackgroundColor: body.changedProperties.canvasBackgroundColor!,
                })
            }
        })
    }
    // 原型文档封面url变更
    const addPrototypeThumbnailUrlChangeCallback = (callback: (msg: { id: string; thumbnailUrl: string }) => void) => {
        addPropertyChangeCallback((body) => {
            if (
                body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.DOC_PROTOTYPE &&
                !isNil(body.changedProperties.thumbnailUrl)
            ) {
                callback({
                    id: body.businessEntity?.entityId!,
                    thumbnailUrl: body.changedProperties.thumbnailUrl!,
                })
            }
        })
    }

    // 席位类型/席位申请变更
    const addSeatTypeOrSeatApplicationRelationChangeCallback = (
        callback: (msg: { resourceId: string; userId: number }) => void
    ) => {
        addRelationChangeCallbackWithoutAccess((body) => {
            if (
                (body.relation?.one?.entityType == Wukong.NotifyProto.EntityType.TEAM ||
                    body.relation?.one?.entityType == Wukong.NotifyProto.EntityType.ORG) &&
                body.relation?.another?.entityType == Wukong.NotifyProto.EntityType.USER &&
                body.relation?.relation == Wukong.NotifyProto.Relation.AUTHORIZATION
            ) {
                callback({ resourceId: body.relation?.one?.entityId!, userId: +body.relation?.another?.entityId! })
            }
        })
    }
    // 文档增删变化
    const addDocCountChangeCallback = (
        callback: (docInfo: { orgId: string; teamId: string; folderId: string }) => void
    ) => {
        const getDocChangeInfo = (ancestors: Wukong.NotifyProto.IBusinessEntity[]) => {
            const docInfo = { orgId: '', teamId: '', folderId: '' }
            for (const ancestor of ancestors ?? []) {
                if (ancestor.entityType == Wukong.NotifyProto.EntityType.ORG) {
                    docInfo.orgId = ancestor.entityId!
                } else if (ancestor.entityType == Wukong.NotifyProto.EntityType.TEAM) {
                    docInfo.teamId = ancestor.entityId!
                } else if (ancestor.entityType == Wukong.NotifyProto.EntityType.FOLDER) {
                    docInfo.folderId = ancestor.entityId!
                }
            }
            return docInfo
        }
        addPropertyChangeCallback((body) => {
            // 移到回收站
            const isTrashed =
                body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.DOC &&
                body.changedProperties.state === 'trashed'
            // 从回收站恢复
            const isRecovered =
                body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.DOC &&
                body.changedProperties.state === 'normal'
            if (isTrashed || isRecovered) {
                const docInfo = getDocChangeInfo(body.businessEntity?.ancestors ?? [])
                callback(docInfo)
            }
        })
        addRelationChangeCallbackWithoutAccess((body) => {
            // 创建文档
            const isCreateDoc =
                body.relation?.one?.entityType == Wukong.NotifyProto.EntityType.DOC &&
                body.changeType == Wukong.NotifyProto.RelationChangeType.ADD
            // 移动文档
            const isMoveDoc =
                body.relation?.one?.entityType == Wukong.NotifyProto.EntityType.DOC &&
                body.changeType == Wukong.NotifyProto.RelationChangeType.REMOVE
            if (isCreateDoc) {
                const docInfo = getDocChangeInfo(body.relation?.one?.ancestors ?? [])
                callback(docInfo)
            }
            if (isMoveDoc) {
                // 移动文档对外给的是被移出的文档在原始文档的位置信息，而不是移入后文档所在的位置信息
                const docInfo = getDocChangeInfo(
                    body.relation?.another ? [...(body.relation.another.ancestors ?? []), body.relation.another] : []
                )
                callback(docInfo)
            }
        })
    }

    // 创建工作区
    const addCreateWorkspaceCallback = (callback: () => void) => {
        addRelationChangeCallbackWithoutAccess((body) => {
            if (
                body.changeType === Wukong.NotifyProto.RelationChangeType.ADD &&
                body.relation?.relation == Wukong.NotifyProto.Relation.BELONG_TO &&
                body.relation?.one?.entityType == Wukong.NotifyProto.EntityType.WORKSPACE &&
                body.relation?.another?.entityType == Wukong.NotifyProto.EntityType.ORG
            ) {
                callback()
            }
        })
    }
    // 删除工作区
    const addRemoveWorkspaceCallback = (callback: () => void) => {
        addRelationChangeCallbackWithoutAccess((body) => {
            if (
                body.changeType === Wukong.NotifyProto.RelationChangeType.REMOVE &&
                body.relation?.relation == Wukong.NotifyProto.Relation.BELONG_TO &&
                body.relation?.one?.entityType == Wukong.NotifyProto.EntityType.WORKSPACE &&
                body.relation?.another?.entityType == Wukong.NotifyProto.EntityType.ORG
            ) {
                callback()
            }
        })
    }
    // 修改工作区管理员 | 用户归属到工作区
    const addWorkspaceUserAuthorizationChangeCallback = (callback: () => void) => {
        addRelationChangeCallbackWithoutAccess((body) => {
            if (
                body.changeType === Wukong.NotifyProto.RelationChangeType.UPDATE &&
                body.relation?.relation == Wukong.NotifyProto.Relation.AUTHORIZATION &&
                body.relation?.one?.entityType == Wukong.NotifyProto.EntityType.WORKSPACE &&
                body.relation?.another?.entityType == Wukong.NotifyProto.EntityType.USER
            ) {
                callback()
            }
        })
    }

    const addUpdateWorkspaceCallback = (callback: () => void) => {
        // 修改工作区的名称/图标/描述/可见性
        addPropertyChangeCallback((body) => {
            if (
                body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.WORKSPACE &&
                (body.changedProperties.name !== undefined ||
                    body.changedProperties.icon !== undefined ||
                    body.changedProperties.description !== undefined ||
                    body.changedProperties.visibility !== undefined)
            ) {
                callback()
            }
        })
        // 修改工作区管理员 | 用户归属到工作区
        addWorkspaceUserAuthorizationChangeCallback(callback)
        // 修改工作区团队归属
        addRelationChangeCallbackWithoutAccess((body) => {
            if (
                (body.changeType === Wukong.NotifyProto.RelationChangeType.ADD ||
                    body.changeType === Wukong.NotifyProto.RelationChangeType.REMOVE) &&
                body.relation?.relation == Wukong.NotifyProto.Relation.BELONG_TO &&
                body.relation?.one?.entityType == Wukong.NotifyProto.EntityType.TEAM &&
                body.relation?.another?.entityType == Wukong.NotifyProto.EntityType.WORKSPACE
            ) {
                callback()
            }
        })
    }

    return {
        addDocNameChangeCallback,
        addDocCountChangeCallback,
        addDocThumbnailIdChangeCallback,
        addDocThumbnailBgColorChangeCallback,
        addDocThumbnailUrlChangeCallback,
        addUserBriefChangeCallback,
        addTeamOrderChangeCallback,
        addFolderOrderChangeCallback,
        addFavoriteFolderChangeCallback,
        addFavoriteDocChangeCallback,
        addUserOrganizationRoleChangeCallback,
        addRelationChangeCallback,
        addTeamRelationChangeCallback,
        addFolderRelationChangeCallback,
        addDocRelationChangeCallback,
        addDocOnlineUserChangeCallback,
        addPropertyChangeCallbackWithoutDocAndUser,
        addFolderPropertyChangeCallback,
        addTeamPropertyChangeCallback,
        addPrototypeRelationChangeCallback,
        addPrototypeFavoriteChangeCallback,
        addPrototypePageNameChangeCallback,
        addPrototypeThumbnailBgColorChangeCallback,
        addPrototypeThumbnailUrlChangeCallback,
        addUserTeamPlanChangeCallback,
        addUserOrgPlanChangeCallback,
        addSeatTypeOrSeatApplicationRelationChangeCallback,
        addSpaceIntervalCallback,
        addCreateWorkspaceCallback,
        addRemoveWorkspaceCallback,
        addUpdateWorkspaceCallback,
        addWorkspaceRelationChangeCallback,
        addWorkspaceUserAuthorizationChangeCallback,
        addRelationChangeCallbackWithoutAccess,
    } as const
}
export type SpaceNotifyFlow = ReturnType<typeof generateSpaceNotifyFlow>
