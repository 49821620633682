import { isBlurhashValid } from 'blurhash'
import { useCallback, useState } from 'react'
import { Blurhash } from 'react-blurhash'

const fallbackHash = 'U2Mj?o~qfQ~q~qj[fQj[fQfQfQfQ~qj[fQj['

export function ImageWithBlurhash({
    src,
    hash,
    width,
    height,
    onLoadCallback,
}: {
    src: string
    hash: string
    width: number
    height: number
    onLoadCallback: (() => void) | undefined
}) {
    const [showOverlay, setShowOverlay] = useState(true)

    const onLoad = useCallback(() => {
        setShowOverlay(false)
        if (onLoadCallback) {
            onLoadCallback()
        }
    }, [onLoadCallback])

    return (
        <div style={{ position: 'relative' }}>
            <div
                data-testid={'blurhash-overlay'}
                style={{
                    zIndex: '2',
                    position: 'absolute',
                    opacity: !showOverlay ? '0' : '1',
                    pointerEvents: 'none',
                    transition: 'all .2s',
                }}
            >
                <Blurhash width={width} height={height} hash={isBlurhashValid(hash).result ? hash : fallbackHash} />
            </div>

            <div style={{ zIndex: '1' }}>
                <img onLoad={onLoad} alt="" width={width} height={height} src={src}></img>
            </div>
        </div>
    )
}
