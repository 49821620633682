import { forwardRef, useImperativeHandle, useRef } from 'react'
import { ExplicitUndefined } from '../../../utils/explicit-undefined'
import { CustomNode } from '../../selects/picks/multi-level/type'
import { useCommentSelect } from '../../selects/select/use-comment-select'
import { MultiLevel, MultiLevelProps, MultiLevelRef } from '../picks/multi-level/multi-level'
import { TriggerMinimal, TriggerMinimalRef } from '../triggers/trigger-minimal'
import { DropdownDefaultRenderItem } from './dropdown-default-custom-render'
import { DropdownMultiLevelProps, DropdownTriggerMinimalProps } from './type'

export interface DropdownMinimalMultiLevelRef {
    close: () => void
    open: () => void
}

export type DropdownMinimalMultiLevelProps<T extends CustomNode> = DropdownTriggerMinimalProps &
    DropdownMultiLevelProps<T>

function _DropdownMinimalMultiLevel<T extends CustomNode>(
    props: DropdownMinimalMultiLevelProps<T>,
    ref?: React.Ref<DropdownMinimalMultiLevelRef>
) {
    const pickRef = useRef<MultiLevelRef>(null)
    const triggerRef = useRef<TriggerMinimalRef>(null)
    const {
        pickRest,
        placement,
        triggerRect,
        worldRect,
        items,
        maxWidth,
        removeMask,
        removeTopPadding,
        removeBottomPadding,
        onKeyboard,
        onChange,
        renderItemPrev,
        renderItem,
        renderItemNext,
        onClose,
        onOpen,
        ...otherProps
    } = props

    const commentSelectState = useCommentSelect({
        triggerRect,
        triggerRef,
        pickRef,
        onClose,
        onOpen,
    })
    useImperativeHandle(ref, () => ({ close: () => pickRef.current?.close(), open: () => pickRef.current?.open() }), [])

    return (
        <TriggerMinimal
            ref={triggerRef}
            {...otherProps}
            isOpenPick={commentSelectState.isOpenPick}
            openAction={commentSelectState.openAction}
            closeAction={commentSelectState.closeAction}
        >
            <MultiLevel<T, ExplicitUndefined<MultiLevelProps<T>>>
                pickRest={pickRest}
                dataTestIds={props.dataTestIds}
                removeMask={removeMask}
                triggerRect={commentSelectState.triggerRect}
                closeByESC={commentSelectState.closeByESC}
                onClose={commentSelectState.onSelectClose}
                onOpen={commentSelectState.onSelectOpen}
                ref={pickRef}
                items={items}
                placement={placement}
                maxWidth={maxWidth}
                worldRect={worldRect}
                onKeyboard={onKeyboard}
                onChange={onChange}
                renderItemPrev={renderItemPrev}
                renderItem={renderItem ?? DropdownDefaultRenderItem}
                renderItemNext={renderItemNext}
                getPortalElement={commentSelectState.getPortalElement}
                removeTopPadding={removeTopPadding}
                removeBottomPadding={removeBottomPadding}
                isOpenState={undefined}
                openStateToBeFalse={undefined}
                openStateToBeTrue={undefined}
                isMinWidthFromTrigger={undefined}
                minWidth={undefined}
                width={undefined}
                autoAdjustTop={undefined}
            />
        </TriggerMinimal>
    )
}

export const DropdownMinimalMultiLevel = forwardRef(_DropdownMinimalMultiLevel) as <T extends CustomNode = CustomNode>(
    props: DropdownTriggerMinimalProps & DropdownMultiLevelProps<T> & { ref?: React.Ref<DropdownMinimalMultiLevelRef> }
) => React.ReactElement
