import classNames from 'classnames'
import { ForwardedRef, PropsWithChildren, forwardRef } from 'react'
import classes from './overlay.module.less'

interface OverlayProps {
    preventMouseEvent: boolean
}

function OverlayInternal(props: PropsWithChildren<OverlayProps>, ref: ForwardedRef<HTMLDivElement>) {
    return (
        <div className={classes.base}>
            <div
                ref={ref}
                className={classNames(classes.overlay, {
                    [classes.preventMouseEvent]: props.preventMouseEvent,
                })}
                data-should-only-aceept-move-event-for-canvas
            >
                {props.children}
            </div>
        </div>
    )
}

export const Overlay = forwardRef(OverlayInternal)
