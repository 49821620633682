import { WKFrog } from '../../kernel/frog'

export enum UpgradePopupType {
    CreateFile = '新建文件',
    CreateFolder = '新建项目',
    CreatePage = '新建页面',
    CopyFile = '创建副本',
    PublishLibrary = '发布组件库',
    SeeVersionHistory = '查看历史版本',
    RecoverFile = '恢复文件',
    MoveFolder = '移动项目',
    MoveFile = '移动文件',
    RecoverVersionHistory = '恢复历史版本', // TODO: 还没这个功能限制
    SharePrototype = '分享原型',
    PasswordProtection = '密码保护',
    PrivacyFolder = '隐私项目',
    OpenDevMode = '开研发模式',
    GetMorePage = '获取更多页面',
    ClickTopTag = '查看顶部标签',
    ViewFolderLimit = '查看项目限制',
    ViewFileLimit = '查看文件限制',
    ClickUpgradeInSetting = '设置中点击升级',
    ClickFreeTag = '点击免费标签',
}

export const payment = {
    UpgradePopup() {
        WKFrog.addFrogRecord({
            url: '/expose/UpgradePayment/UpgradePopup',
            eventId: 26849,
            eventName: 'UpgradePopup',
            eventAction: 'expose',
        })
    },
    ClickPopupUpgrade() {
        WKFrog.addFrogRecord({
            url: '/click/UpgradePayment/ClickPopupUpgrade',
            eventId: 26850,
            eventAction: 'click',
            eventName: 'ClickPopupUpgrade',
        })
    },
    UpgradePopupType(from: UpgradePopupType) {
        WKFrog.addFrogRecord({
            url: '/expose/UpgradePayment/UpgradePopupType',
            eventId: 26967,
            eventAction: 'expose',
            eventName: 'UpgradePopupType',
            customExtend: {
                upgrade_popup_type: from,
            },
        })
    },
}
