import { translation } from './reply-more-setting.translation'
/* eslint-disable no-restricted-imports */
import { useCallback } from 'react'
import { DropdownIconSingleLevelProps, DropdownV2, MonoIconPanelMore16, Tooltip } from '../../../../../../ui-lib/src'

enum ReplyMoreSettingEnum {
    EDIT = 'EDIT',
    DELETE = 'DELETE',
}

export interface ReplyMoreSettingV2Props
    extends Omit<DropdownIconSingleLevelProps, 'dataTestIds' | 'label' | 'onChange'> {
    omitDeleteItem?: boolean
    onDeleteReply?: () => void
    onEditCommentAndReply?: () => void
}
export function ReplyMoreSettingV2(props: ReplyMoreSettingV2Props) {
    const { omitDeleteItem, onEditCommentAndReply, onDeleteReply, ...otherProps } = props

    const onChange = useCallback(
        (value: ReplyMoreSettingEnum) => {
            switch (value) {
                case ReplyMoreSettingEnum.EDIT:
                    onEditCommentAndReply?.()
                    break
                case ReplyMoreSettingEnum.DELETE:
                    onDeleteReply?.()
                    break
                default:
                    break
            }
        },
        [onDeleteReply, onEditCommentAndReply]
    )

    return (
        <Tooltip title={translation('CommentActions')} triggerRefKey="getTriggerElement">
            <DropdownV2.IconSingleLevel
                label={<MonoIconPanelMore16 />}
                onChange={onChange}
                dataTestIds={{ triggerFocus: 'rely-more-setting' }}
                {...otherProps}
            >
                <DropdownV2.IconSingleLevel.Option value={ReplyMoreSettingEnum.EDIT} dataTestId="edit">
                    {translation('Edit')}...
                </DropdownV2.IconSingleLevel.Option>
                {omitDeleteItem ? null : (
                    <DropdownV2.IconSingleLevel.Option value={ReplyMoreSettingEnum.DELETE}>
                        {translation('Delete')}
                    </DropdownV2.IconSingleLevel.Option>
                )}
            </DropdownV2.IconSingleLevel>
        </Tooltip>
    )
}
