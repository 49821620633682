/* eslint-disable no-restricted-imports */
import { openIndependentWindow } from '../../../../kernel/util/open-window'
import { Tooltip, useEllipsisTooltip } from '../../../../../../ui-lib/src'
import { RouteToken, buildPath, domLocation, generateRouterPath } from '../../../../../../util/src'
import { useCallback } from 'react'
import { svgSlash } from '../../../assets/common/common-svg'
import { useDocInfoContext } from '../../../context/top-area-context'
import style from './tool-title.module.less'

export const FolderName = () => {
    const { folderData, docData, isShow } = useDocInfoContext()
    const { inactivation, ellipsisRef, onmouseenter, onmouseleave } = useEllipsisTooltip<HTMLDivElement>()

    const onClickTitle = useCallback(() => {
        if (folderData) {
            const orgId = folderData.orgId
            const pathname = docData?.draft ? RouteToken.Drafts : `${RouteToken.Project}/${folderData?.id}`
            const link = buildPath(orgId!, pathname)
            openIndependentWindow(`${domLocation().origin}/${generateRouterPath(link)}`)
        }
    }, [docData?.draft, folderData])

    if (!folderData || !isShow) {
        return <></>
    }

    return (
        <>
            <Tooltip title={folderData.name} inactivation={inactivation}>
                <div
                    ref={ellipsisRef}
                    className={style.title}
                    onClick={onClickTitle}
                    data-testid="folder-name"
                    onMouseEnter={onmouseenter}
                    onMouseLeave={onmouseleave}
                >
                    {folderData.name}
                </div>
            </Tooltip>
            <div className={style.splitLineTop}>{svgSlash}</div>
        </>
    )
}
