/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import constate from 'constate'
import { useCallback, useMemo, useState } from 'react'
import { safeCall } from '../../../../../../../ui-lib/src'
import { useViewState } from '../../../../../view-state-bridge/use-view-state'
import { LocalStorageKey } from '../../../../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../../../../web-storage/local-storage/storage'
import { MessageModalProps } from '../../../history-file/message-modal/message-modal'
import { featureSwitchManager } from '../../../../../kernel/switch'

function useChangeColorProfileDialog() {
    const [visible, setVisible] = useState(false)
    const [nextColorProfile, setNextColorProfile] = useState(
        Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B
    )
    const currentColorProfile =
        useViewState('documentColorProfile')?.profile ??
        Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B

    const close = useCallback(() => {
        setVisible(false)
    }, [])

    const openToNext = useCallback((next: Wukong.DocumentProto.DocumentColorProfile) => {
        setNextColorProfile(next)
        setVisible(true)
    }, [])

    const exported = useMemo(
        () => ({
            visible,
            close,
            openToNext,
            currentColorProfile,
            nextColorProfile,
        }),
        [visible, close, openToNext, currentColorProfile, nextColorProfile]
    )

    return exported
}

function useMenuContext() {
    const [isOpenFineTuning, setIsOpenFineTuning] = useState(false)
    const [isOpenReportObstacle, setIsOpenReportObstacle] = useState(false)
    const [isOpenFeedback, setIsOpenFeedback] = useState(false)
    const [isOpenAccountManager, setIsOpenAccountManager] = useState(false)
    const [isOpenPreferenceColorProfileDialog, setIsOpenPreferenceColorProfileDialog] = useState(false)
    const changeColorProfileDialogState = useChangeColorProfileDialog()
    const [messageModalProps, _setMessageModalProps] = useState<MessageModalProps>({ visible: false })

    const [isOpenFontMissDialog, setIsOpenFontMissDialog] = useState(false)
    const [isOpenCandidatePublishDialog, setIsOpenCandidatePublishDialog] = useState(false)
    const [isOpenAiReplaceComponent, setIsOpenAiReplaceComponent] = useState(false)

    const [showMotiffScope, setShowMotiffScope] = useState(false)
    const [showWasmCallMonitor, setShowWasmCallMonitor] = useState(
        (featureSwitchManager.isEnabled('motiff-debugger') &&
            enhancedLocalStorage.getSerializedItem(LocalStorageKey.MotiffScopeWasmCallMonitorRunningState)) ??
            false
    )

    const setMessageModalProps = useCallback((modalProps: MessageModalProps) => {
        const close = () => _setMessageModalProps({ visible: false })
        const nextState: MessageModalProps = {
            ...modalProps,
            onOK: (params) => {
                close()
                modalProps.onOK && safeCall(modalProps.onOK, params)
            },
            onCancel: () => {
                close()
                modalProps.onCancel && safeCall(modalProps.onCancel)
            },
        }
        _setMessageModalProps(nextState)
    }, [])
    return {
        isOpenFineTuning,
        setIsOpenFineTuning,
        messageModalProps,
        setMessageModalProps,
        isOpenReportObstacle,
        setIsOpenReportObstacle,
        isOpenFeedback,
        setIsOpenFeedback,
        isOpenAccountManager,
        setIsOpenAccountManager,
        isOpenPreferenceColorProfileDialog,
        setIsOpenPreferenceColorProfileDialog,
        changeColorProfileDialogState,
        isOpenFontMissDialog,
        setIsOpenFontMissDialog,
        isOpenCandidatePublishDialog,
        setIsOpenCandidatePublishDialog,
        isOpenAiReplaceComponent,
        setIsOpenAiReplaceComponent,
        showMotiffScope,
        setShowMotiffScope,
        showWasmCallMonitor,
        setShowWasmCallMonitor,
    } as const
}

export const [MenuContextProvider, useMenuContextState] = constate(useMenuContext)
