import { isCypress } from './ua'

/**
 * 用于支持插值的翻译，当代有插值是，会把所有 {{}} 内的参数替换为插值
 * @param translation
 * @param key
 * @param insert
 * @returns
 */
export const getTranslationValue = (
    enTranslation: Record<string, string>,
    zhTranslation: Record<string, string>,
    key: string,
    insert?: Record<string, string | number>
) => {
    if (!(key in zhTranslation) || !(key in enTranslation)) {
        throw new Error(`translation error, not find the key: ${String(key)} in translation`)
    }

    let res = isEnglishLanguage() ? enTranslation[key] : (zhTranslation[key] as string)

    if (insert === undefined) {
        return res
    }

    Object.entries(insert).forEach(([insertKey, insertValue]) => {
        res = res.replaceAll(`{{${insertKey}}}`, String(insertValue))
    })

    return res
}

// 目前以静态方式存在
const language =
    typeof window !== 'undefined' && isCypress()
        ? 'zh'
        : typeof localStorage !== 'undefined' && localStorage.getItem('language') !== null
        ? localStorage.getItem('language') === '"en"'
            ? 'en'
            : 'zh'
        : typeof document !== 'undefined' && document.documentElement.lang === 'en'
        ? 'en'
        : 'zh'

declare global {
    interface Window {
        importEnv: {
            lang: string
        }
    }
}
/**
 * 获取是否处于英文环境
 * 这个判断是有代价的，具体参见：https://www.notion.so/kanyun/cfd220466f404cfeb60e11e01d1591c8
 * 如果有新增的使用需要发给相关产品判断一下
 * @returns
 */
export const isEnglishLanguage = (): boolean => {
    if (typeof window !== 'undefined' && typeof window.importEnv !== 'undefined') {
        if (isCypress()) {
            return false
        }
        return window.importEnv.lang === 'en'
    }
    return language === 'en'
}
