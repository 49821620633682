export const AiLab = [
    'd7e45b450b5cd8fb9d806cfa0ace29746b996408',
    '67a1f8eef52da0343c0c4948e56c92f09cfd1c6f',
    '848154b842d852a02cd56aae93f06e95617a3f5b',
    'cc448a05f972eaa52d0745e77d4b9fa4a9cafbc5',
    '3c80e31e15ffc00ad42f561d834062e3aa1163f1',
    '57434b1c0afd109373efd66ab0cc5ed1569a4333',
    'af8a16e4449a82cb7553c13c743936e31957e1d2',
    '30763211f097050569cfba3f76b48c10c6d70261',
    'b67959a4aed14cd4cfb9c523449d62f3165ce575',
    '0031a5000671cdca10b63497d88499c76cf295b1',
    'f7963b3f7e23de6c70e2dbe090cbce97183df403',
    '9df9d0d1b240761a7dddbf7ad5e2c104fe39cb76',
    '7a6d4891e0ed690ca1612ab6125d09ea8140eb5c',
]
