/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { DeepRequired } from '../../../../view-state-bridge'
import { switchPaintType } from '../../../utils/switch-paint-type'

// 改变 paints 指定 index 上的 paintType
export function changePaintType(
    paints: DeepRequired<Wukong.DocumentProto.IPaint>[],
    index: number,
    nextPaintType: Wukong.DocumentProto.PaintType,
    nextPaint?: DeepRequired<Wukong.DocumentProto.IPaint>
) {
    const currentPaint = paints[index]
    if (!currentPaint) {
        return paints
    }
    const targetPaint = nextPaint
        ? nextPaint
        : (switchPaintType(currentPaint, nextPaintType) as DeepRequired<Wukong.DocumentProto.IPaint>)
    const nextPaints = [...paints]
    nextPaints.splice(index, 1, targetPaint)
    return nextPaints
}
