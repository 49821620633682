import classnames from 'classnames'
import { omit } from 'lodash-es'
import type { HTMLAttributes } from 'react'
import React, { Children, forwardRef, isValidElement, useCallback, useEffect, useState } from 'react'
import style from './title.module.less'

export interface TitleProps extends HTMLAttributes<HTMLDivElement> {
    grayTheme?: boolean
}
function _Title(props: TitleProps, ref?: React.Ref<HTMLDivElement>) {
    const { className, grayTheme, onMouseEnter, onMouseLeave, children } = props
    const otherProps = omit(props, 'className', 'grayTheme', 'onMouseEnter', 'onMouseLeave')
    let left: any
    let right: any
    Children.forEach(children, (child) => {
        if (isValidElement(child)) {
            child.type === Left && (left = child)
            child.type === Right && (right = child)
        }
    })
    const [showGaryTheme, setShowGrayTheme] = useState<boolean>(!!grayTheme)
    const _onMouseEnter = useCallback(
        (e: any) => {
            setShowGrayTheme(false)
            onMouseEnter?.(e)
        },
        [onMouseEnter]
    )
    const _onMouseLeave = useCallback(
        (e: any) => {
            setShowGrayTheme(!!grayTheme)
            onMouseLeave?.(e)
        },
        [grayTheme, onMouseLeave]
    )
    useEffect(() => {
        setShowGrayTheme(!!grayTheme)
    }, [grayTheme])
    return (
        <div
            ref={ref}
            onMouseEnter={_onMouseEnter}
            onMouseLeave={_onMouseLeave}
            className={classnames(style.head, [className], {
                [style.grayTheme]: showGaryTheme,
            })}
            {...otherProps}
        >
            {[left, right]}
        </div>
    )
}

function Left(props: HTMLAttributes<HTMLDivElement>) {
    const { className } = props
    const otherProps = omit(props, 'className')
    return (
        <div className={`${style.left} ${className ?? ''}`} {...otherProps}>
            {props.children}
        </div>
    )
}

function Right(props: any) {
    const { className } = props
    const otherProps = omit(props, 'className')
    return (
        <div className={`${style.right} ${className ?? ''}`} {...otherProps}>
            {props.children}
        </div>
    )
}

export const TitleRef = forwardRef(_Title)
export const Title = Object.assign(TitleRef, { Left, Right })
