import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    PassThrough: '穿透',
    Normal: '正常',
    Darken: '变暗',
    Multiply: '正片叠底',
    ColorBurn: '颜色加深',
    Lighten: '变亮',
    Screen: '滤色',
    PlusLighter: '颜色减淡',
    Overlay: '叠加',
    SoftLight: '柔光',
    HardLight: '强光',
    Difference: '差集',
    Exclusion: '排除',
    Hue: '色相',
    Saturation: '饱和度',
    Color: '颜色',
    Luminosity: '明度',
    Blend: '混合模式',
    Opacity: '不透明度',
    LinearBurn: '加暗',
    LinearDodge: '提亮',
} as const

export const enTranslation = {
    PassThrough: 'Pass through',
    Normal: 'Normal',
    Darken: 'Darken',
    Multiply: 'Multiply',
    ColorBurn: 'Color burn',
    Lighten: 'Lighten',
    Screen: 'Screen',
    PlusLighter: 'Color dodge',
    Overlay: 'Overlay',
    SoftLight: 'Soft light',
    HardLight: 'Hard light',
    Difference: 'Difference',
    Exclusion: 'Exclusion',
    Hue: 'Hue',
    Saturation: 'Saturation',
    Color: 'Color',
    Luminosity: 'Luminosity',
    Blend: 'Blend',
    Opacity: 'Opacity',
    LinearBurn: 'Plus darker',
    LinearDodge: 'Plus lighter',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
