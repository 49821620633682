// 这是一个 big store 包含了space所有的 state
// 每个子 store 需要提供类型 默认值
import { command } from 'ccstate'
import { createImmerStore, createSelectors } from '../../../../../util/src'
import { TraceableAbortSignal } from '../../../../../util/src/abort-controller/traceable-abort-controller'
import { traceable } from '../../../../../util/src/ccstate-helper/traceable'
import { notify$ } from '../../../external-store/atoms/notify'
import { generateAllTeamsState, syncAllTeamsState } from './all-teams-state'
import { generateAllWorkspacesState, syncAllWorkspacesState } from './all-workspaces-state'
import { generateDocListPageState, syncDocListPageState } from './doc-list-page-state'
import { generateDocOnlineUserState, syncDocOnlineUsers } from './doc-online-user-state'
import { generateFavoriteDocsState, syncFavoriteDocsState } from './favorite-docs'
import { generateManagedWorkspaceState, syncManagedWorkspaceState } from './managed-workspace-state'
import { generateNoneRightFolderState, syncNoneRightFolder } from './none-right-folder-state'
import { generateSpaceNotifyFlow } from './notify-flow'
import {
    generateOrganizationManageMembersState,
    syncOrganizationManageMembersState,
} from './organization-manage-members-state'
import {
    generateOrganizationManageOperationState,
    syncOrganizationManageOperationState,
} from './organization-manage-operation-state'
import {
    generateOrganizationManageSettlementState,
    syncOrganizationManageSettlementState,
} from './organization-manage-settlement-state'
import {
    generateOrganizationManageSummaryState,
    syncOrganizationManageSummaryState,
} from './organization-manage-summary-state'
import { generateOrganizationManageTeamState, syncOrganizationManageTeamState } from './organization-manage-team-state'
import {
    generateOrganizationManageWorkspaceState,
    syncOrganizationManageWorkspaceState,
} from './organization-manage-workspace-state'
import { generateOrganizationState, syncOrganizationState } from './organization-state'
import { generatePlanAndUserState, syncPlanState } from './plan-and-user-state'
import { generatePlanSubscriptionStatusStore } from './plan-subscription-status'
import { generateReadOnlyTeamState, syncReadOnlyTeamState } from './readonly-team-state'
import { generateSearchPageState, syncSearchPageState } from './search-page-state'
import { generateSpaceNotifyState } from './space-notify-state'
import { setUseSpaceStore } from './space-state'
import { generateTeamMembersPageState, syncTeamMembersPageState } from './team-members-page-state'
import { generateTeamPageState, syncTeamPageState } from './team-page-state'
import { generateTeamSettlementState, syncTeamSettlementState } from './team-settlement-state'
import { generateTeamsFoldersState, syncTeamsFoldersState } from './teams-folders-state'
import { SpaceStore } from './types'
import { generateWorkspaceDetailState, syncWorkspaceDetailState } from './workspace-detail-state'
import { generateWorkspaceUnassignedState, syncWorkspaceUnassignedState } from './workspace-unassigned-state'

export const startSync$ = traceable(
    'jiangzg@kanyun.com',
    command(({ get: getValue }, signal: TraceableAbortSignal) => {
        const notifyService = getValue(notify$)!

        const spaceStoreBase = createImmerStore<SpaceStore>((set, get) => {
            return {
                organizationStore: generateOrganizationState(set, get),
                favoriteDocsStore: generateFavoriteDocsState(set, get),
                teamPageStore: generateTeamPageState(set, get),
                teamsFoldersStore: generateTeamsFoldersState(set, get),
                spaceNotifyStore: generateSpaceNotifyState(set, get, notifyService, signal),
                teamMembersPageStore: generateTeamMembersPageState(set, get),
                docListPageStore: generateDocListPageState(set, get),
                docOnlineUserStore: generateDocOnlineUserState(set, get),
                managedWorkspaceStore: generateManagedWorkspaceState(set, get),
                organizationManageSummaryStore: generateOrganizationManageSummaryState(set, get),
                organizationManageTeamStore: generateOrganizationManageTeamState(set, get),
                organizationManageWorkspaceStore: generateOrganizationManageWorkspaceState(set, get),
                organizationManageMembersStore: generateOrganizationManageMembersState(set, get),
                organizationManageSettlementStore: generateOrganizationManageSettlementState(set, get),
                teamSettlementStore: generateTeamSettlementState(set, get),
                planSubscriptionStatusStore: generatePlanSubscriptionStatusStore(set, get),
                allTeamsStore: generateAllTeamsState(set, get),
                readOnlyTeamStore: generateReadOnlyTeamState(set, get),
                noneRightFolderStore: generateNoneRightFolderState(set, get),
                searchPageStore: generateSearchPageState(set, get),
                planAndUserStateStore: generatePlanAndUserState(set, get),
                allWorkspacesStore: generateAllWorkspacesState(set, get),
                workspaceUnassignedStore: generateWorkspaceUnassignedState(set, get),
                workspaceDetailStore: generateWorkspaceDetailState(set, get),
                organizationManageOperationStore: generateOrganizationManageOperationState(set, get),
            }
        })
        const useSpaceStore = createSelectors(spaceStoreBase)
        setUseSpaceStore(useSpaceStore)
        const spaceNotifyFlow = generateSpaceNotifyFlow(useSpaceStore)
        syncTeamPageState(useSpaceStore, spaceNotifyFlow)
        syncTeamsFoldersState(useSpaceStore, spaceNotifyFlow)
        syncTeamMembersPageState(useSpaceStore, spaceNotifyFlow)
        syncDocListPageState(useSpaceStore, spaceNotifyFlow)
        syncFavoriteDocsState(useSpaceStore, spaceNotifyFlow)
        syncDocOnlineUsers(useSpaceStore, spaceNotifyFlow)
        syncManagedWorkspaceState(useSpaceStore, spaceNotifyFlow)
        syncOrganizationManageSummaryState(useSpaceStore, spaceNotifyFlow)
        syncOrganizationManageMembersState(useSpaceStore, spaceNotifyFlow)
        syncOrganizationState(useSpaceStore, spaceNotifyFlow)
        syncOrganizationManageTeamState(useSpaceStore, spaceNotifyFlow)
        syncOrganizationManageWorkspaceState(useSpaceStore, spaceNotifyFlow)
        syncAllTeamsState(useSpaceStore, spaceNotifyFlow)
        syncReadOnlyTeamState(useSpaceStore, spaceNotifyFlow)
        syncNoneRightFolder(useSpaceStore, spaceNotifyFlow)
        syncSearchPageState(useSpaceStore, spaceNotifyFlow)
        syncPlanState(useSpaceStore, spaceNotifyFlow)
        syncOrganizationManageSettlementState(useSpaceStore, spaceNotifyFlow)
        syncTeamSettlementState(useSpaceStore, spaceNotifyFlow)
        syncAllWorkspacesState(useSpaceStore, spaceNotifyFlow)
        syncWorkspaceUnassignedState(useSpaceStore, spaceNotifyFlow)
        syncWorkspaceDetailState(useSpaceStore, spaceNotifyFlow)
        syncOrganizationManageOperationState(useSpaceStore, spaceNotifyFlow)
    })
)
