import { useEffect, useState } from 'react'
import { AuxiliaryScrollbarService } from './auxiliary-scrollbar-service'

export function useScrollBarWidth() {
    const [scrollbarWidth, setScrollbarWidth] = useState(AuxiliaryScrollbarService.getScrollbarWidth)

    useEffect(() => {
        AuxiliaryScrollbarService.observeScrollbarWidth(setScrollbarWidth)
        return () => AuxiliaryScrollbarService.unobserveScrollbarWidth(setScrollbarWidth)
    }, [])

    return { scrollbarWidth }
}
