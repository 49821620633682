/* eslint-disable no-restricted-imports */
import {
    GetCodeConfigFromJS,
    GetExportUserConfigFromJS,
    GetLeftPanelWidthUserConfig,
    GetRightPanelWidthUserConfig,
    GetUserConfigCommand,
    SetUserConfigCommand,
    UpdateDevModeCodeConfigCommand,
    UpdateDevModeInspectExportCommandWasmCall,
    UpdateExportUserConfigToJS,
    UpdateUserPreferrence2,
    Wukong,
} from '@wukong/bridge-proto'
import { debounce } from 'lodash-es'
import { CommandInvoker } from '../../document/command/command-invoker'
import { Bridge } from '../../kernel/bridge/bridge'
import { WK } from '../../window'

import { createSelectors, createStore, DeepRequired } from '../../../../util/src'
import { ClassWithEffect } from '../../../../util/src/effect-controller'
import { UserPreferenceInner } from '../../kernel/interface/type'
import { GetUserConfigRequest, UpdateUserConfig } from '../../kernel/request/user'
import { OmitToJson } from '../../view-state-bridge'
import { LocalStorageKey } from '../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../web-storage/local-storage/storage'
import { createFileManager } from '../create-file-manager'
import {
    DOC_AUTH_CONFIG_KEYS,
    DOC_CONFIG_KEYS,
    doValidate,
    getDefaultUserConfigs2,
    getDefaultUserConfigsWithStorage2,
    getKeyByStorage,
    getStorageByKey,
    GLOBAL_CONFIG_KEYS,
    isDocAuthConfigKey,
    isDocConfigKey,
    isGlobalConfigKey,
    isMigrated,
    isUserConfigKey,
    SEND_TO_WASM_KEY,
    STORAGE_PREFIX,
    USER_CONFIG_DEFAULT_VALUES2,
    UserConfigKey,
    UserConfigKeyMap,
    UserConfigTypeNew,
} from './user-config'
import { getUserConfigStore } from './user-config-db'
import DevModeInspectExportUserConfig = Wukong.DocumentProto.DevModeInspectExportUserConfig
import DevModeInspectCodeConfig = Wukong.DocumentProto.DevModeInspectCodeConfig
import TextNodeStyle = Wukong.DocumentProto.TextNodeStyle

enum SourceType {
    USER_JS = 1, // 用户行为
    EDITOR_WASM, // WASM
    BROWSER_STORAGE, // 浏览器的 LocalStorage
    SERVER, // 服务端
}

// 数据更新来源到数据更新去向的映射关系（ 基本上除了自己其他的都要更新，来自 JS 的也要更新自己 ）
const StateChangeSourceMap = new Map<SourceType, SourceType[]>([
    // 来自 JS 的更新，不同步给 WASM，从而不更新文档状态
    [SourceType.USER_JS, [SourceType.USER_JS, SourceType.BROWSER_STORAGE, SourceType.SERVER]],
    [SourceType.EDITOR_WASM, [SourceType.USER_JS, SourceType.BROWSER_STORAGE, SourceType.SERVER]],
    [SourceType.BROWSER_STORAGE, [SourceType.USER_JS, SourceType.EDITOR_WASM]],
    [SourceType.SERVER, [SourceType.USER_JS, SourceType.EDITOR_WASM, SourceType.BROWSER_STORAGE]],
])

enum DocAuth {
    Readonly = 'readonly_1',
    Editable = 'readonly_0',
}

export function stringToBool(str: string): boolean {
    return str === '1'
}
export function boolToString(bool: boolean): string {
    return bool ? '1' : '0'
}

export const THROTTLE_UPLOAD_CONFIG_TIME = 500

export interface IUserConfigService {
    // EditorContext 初始化完成
    provideBridge: (bridge: Bridge) => void
    // Document 数据加载完成
    provideDocument: (commandInvoker: CommandInvoker) => void
    // 由 React 视图更新数据
    updateConfig2: <K extends UserConfigKey>(key: K, value: UserConfigTypeNew[K]) => UserConfigTypeNew[K]
    // 获取所有项的当前状态
    getSnapshot2: () => UserConfigTypeNew
    // 销毁
    destroy: () => void
}

export class UserConfigService extends ClassWithEffect implements IUserConfigService {
    private bridge: Bridge | null = null
    private commandInvoker: CommandInvoker | null = null

    private docId = ''
    private authStr = ''
    private zustandStore2 = createStore<UserConfigTypeNew>(getDefaultUserConfigs2)
    useZustandStore2 = createSelectors(this.zustandStore2)

    // 记录每一次更新时的来源，从而决定要回调的去向
    private updateSource: SourceType | null = null
    private doUpdate = async <R>(source: SourceType, doCallback: () => Promise<R> | R) => {
        this.updateSource = source
        const ret = await Promise.resolve(doCallback())
        this.updateSource = null
        return ret
    }

    private throttleUploading: any = null

    public async init(docId: string, docReadonly: boolean) {
        this.docId = docId
        this.authStr = docReadonly ? DocAuth.Readonly : DocAuth.Editable
        const defaultConfigs2 = await getDefaultUserConfigsWithStorage2(docId, this.authStr)
        if (this.controller.aborted) {
            return
        }
        this.zustandStore2 = createStore<UserConfigTypeNew>(() => defaultConfigs2)
        // NOTE: 重新生成 store 之后，useStore 也要重新生成
        this.useZustandStore2 = createSelectors(this.zustandStore2)
        // 注册通用的回调策略函数
        this.registerStateChangeCallback()
        // 获取服务数据，在新建文档时需要延迟进行
        if (createFileManager.isCreatingFile()) {
            createFileManager.injectCreateFileCallBack(this.fetchRemoteConfigs.bind(this))
        } else {
            this.fetchRemoteConfigs()
        }

        // 开启 storage 事件监听
        this.startStorageListener()
        // 初始化 window 对象
        this.injectGlobalSnapshot()
    }

    destroy() {
        window.removeEventListener('storage', this.storageEventHandler)
        if (this.bridge) {
            this.bridge.unbind(GetExportUserConfigFromJS)
            this.bridge.unbind(GetCodeConfigFromJS)
            this.bridge.unbind(UpdateExportUserConfigToJS)
            this.bridge.unbind(GetLeftPanelWidthUserConfig)
            this.bridge.unbind(GetRightPanelWidthUserConfig)
            this.bridge.unbind(SetUserConfigCommand)
            this.bridge.unbind(GetUserConfigCommand)
        }
        this.bridge = null
        this.commandInvoker = null
        delete WK.getUserConfig
        delete WK.setUserConfig
        if (this.throttleUploading) {
            clearTimeout(this.throttleUploading)
        }
    }

    getSnapshot2 = () => {
        return this.zustandStore2.getState()
    }

    private injectGlobalSnapshot() {
        WK.getUserConfig = this.getSnapshot2
        WK.setUserConfig = this.updateConfig2
    }

    public validate<K extends UserConfigKey>(key: K, value: UserConfigTypeNew[K]): boolean {
        if (isMigrated(key)) {
            return doValidate(key, value)
        }
        const defaultValue = USER_CONFIG_DEFAULT_VALUES2[key]
        return typeof value === typeof defaultValue
    }

    private setUserConfigValue<K extends UserConfigKey>(key: K, value: UserConfigTypeNew[K]): void {
        if (this.updateSource === SourceType.BROWSER_STORAGE) {
            // 从localstorage同步数据时,需要通过api取真正的值
            const localStorageValue = enhancedLocalStorage.getSerializedItem(getStorageByKey(key) as LocalStorageKey)
            if (localStorageValue !== null) {
                value = localStorageValue
            }
        }
        const defaultValue = USER_CONFIG_DEFAULT_VALUES2[key]
        if (!this.validate(key, value)) {
            value = defaultValue
        }
        this.zustandStore2.setState({
            [key]: value,
        })
    }

    private fetchRemoteConfigs() {
        return new GetUserConfigRequest(this.docId, this.authStr).start().then((res) => {
            // 标记来自服务端的数据更新
            this.doUpdate(SourceType.SERVER, () => {
                const preference = res.docId2Preference
                if (preference) {
                    try {
                        if (preference.global) {
                            const globalConfig = JSON.parse(preference.global)
                            for (const key in globalConfig) {
                                if (isGlobalConfigKey(key)) {
                                    this.setUserConfigValue(key, globalConfig[key])
                                }
                            }
                        }
                        if (preference[this.docId]) {
                            const docConfig = JSON.parse(preference[this.docId])
                            for (const key in docConfig) {
                                if (isDocConfigKey(key)) {
                                    this.setUserConfigValue(key, docConfig[key])
                                }
                            }
                        }
                        if (preference[this.authStr]) {
                            const authConfig = JSON.parse(preference[this.authStr])
                            for (const key in authConfig) {
                                if (isDocAuthConfigKey(key)) {
                                    this.setUserConfigValue(key, authConfig[key])
                                }
                            }
                        }
                    } catch {}
                }
            })
        })
    }

    private startStorageListener() {
        window.addEventListener('storage', this.storageEventHandler)
    }

    protected storageEventHandler = (e: StorageEvent) => {
        // 标记来自 LocalStorage 的数据更新
        this.doUpdate(SourceType.BROWSER_STORAGE, async () => {
            if (e.key && e.newValue) {
                if (e.key === LocalStorageKey.DocConfigUpdate) {
                    try {
                        const store = await getUserConfigStore()
                        const keyValue = await store.get([STORAGE_PREFIX + this.docId])
                        if (keyValue) {
                            const docConfig = JSON.parse(keyValue.value)
                            for (const storageKey in docConfig) {
                                const key = getKeyByStorage(storageKey)
                                if (isDocConfigKey(getKeyByStorage(key))) {
                                    this.setUserConfigValue(key, docConfig[key])
                                }
                            }
                        }
                    } catch {}
                } else if (e.key === LocalStorageKey.AuthConfigUpdate) {
                    try {
                        const store = await getUserConfigStore()
                        const keyValue = await store.get([STORAGE_PREFIX + this.authStr])
                        if (keyValue) {
                            const authConfig = JSON.parse(keyValue.value)
                            for (const storageKey in authConfig) {
                                const key = getKeyByStorage(storageKey)
                                if (isDocAuthConfigKey(key)) {
                                    this.setUserConfigValue(key, authConfig[key])
                                }
                            }
                        }
                    } catch {}
                } else {
                    const key = getKeyByStorage(e.key)
                    if (isGlobalConfigKey(key)) {
                        this.setUserConfigValue(key, e.newValue)
                    }
                }
            }
        })
    }

    provideBridge(bridge: Bridge) {
        if (this.bridge) {
            return
        }

        this.bridge = bridge

        this.bridge.bind(GetUserConfigCommand, () => {
            return this.getSnapshot2()
        })

        this.bridge.bind(SetUserConfigCommand, (userConfig: Wukong.DocumentProto.IUserConfig2) => {
            // 标记来自 wasm 的数据更新
            this.doUpdate(SourceType.EDITOR_WASM, () => {
                ;(Object.keys(userConfig) as Array<keyof Wukong.DocumentProto.IUserConfig2>).forEach((key) => {
                    if (isUserConfigKey(key)) {
                        // TODO(yangming) 等v2全量后可以改成直接调用setUserConfigValue,不用再手动setState
                        this.zustandStore2.setState({
                            [key]: userConfig[key],
                        })
                    }
                })
            })
        })

        this.bridge.bind(GetExportUserConfigFromJS, () => {
            const config = this.zustandStore2.getState().devModeInspectExportUserConfig
            return config
        })
        this.bridge.bind(GetCodeConfigFromJS, () => {
            const config = this.zustandStore2.getState().devModeInspectCodeConfig
            return { config }
        })

        this.bridge.bind(UpdateExportUserConfigToJS, (_config) => {
            const config = DevModeInspectExportUserConfig.toObject(_config as DevModeInspectExportUserConfig)
            this.doUpdate(SourceType.EDITOR_WASM, () => {
                this.setUserConfigValue('devModeInspectExportUserConfig', config)
            })
        })

        this.bridge.bind(GetLeftPanelWidthUserConfig, () => {
            return {
                value: +this.zustandStore2.getState().leftPanelWidth,
            } as Wukong.DocumentProto.BridgeProtoInt
        })

        this.bridge.bind(GetRightPanelWidthUserConfig, () => {
            return {
                value: +this.zustandStore2.getState().devModeRightPanelWidth,
            } as Wukong.DocumentProto.BridgeProtoInt
        })
    }

    provideDocument(commandInvoker: CommandInvoker) {
        this.commandInvoker = commandInvoker
    }

    updateConfig2 = <K extends UserConfigKey>(key: K, value: typeof USER_CONFIG_DEFAULT_VALUES2[K]) => {
        this.doUpdate(SourceType.USER_JS, () => {
            this.setUserConfigValue(key, value)
        })
        return value
    }

    private registerStateChangeCallback() {
        Object.keys(this.zustandStore2.getState()).forEach((key) => {
            if (isUserConfigKey(key)) {
                this.zustandStore2.subscribe(
                    (store) => store[key],
                    () => {
                        this.onStateChangeCallback(key)
                    }
                )
            }
        })
    }

    private onStateChangeCallback = (key: UserConfigKey) => {
        if (!this.updateSource) {
            return
        }
        const sources = StateChangeSourceMap.get(this.updateSource)
        if (!sources) {
            return
        }
        // 对于当前更新来源的每一个去向，分别调用其回调
        sources.forEach((source) => {
            switch (source) {
                case SourceType.USER_JS: {
                    return // 对 JS 视图的更新请使用 useReactiveState(userConfigService.getConfig(key)) 来订阅
                }
                case SourceType.EDITOR_WASM: {
                    return this.pushConfigToEditor(key)
                }
                case SourceType.BROWSER_STORAGE: {
                    return this.pushConfigToStorage(key)
                }
                case SourceType.SERVER: {
                    return this.pushConfigsToServer()
                }
                default: {
                    return
                }
            }
        })
    }

    private pushConfigToEditor = (key: UserConfigKey) => {
        if (!this.commandInvoker) {
            return
        }
        if (key == UserConfigKeyMap.devModeInspectExportUserConfig) {
            const config = this.zustandStore2.getState().devModeInspectExportUserConfig
            this.commandInvoker.DEPRECATED_invokeBridge(UpdateDevModeInspectExportCommandWasmCall, config)

            return
        }
        if (key == UserConfigKeyMap.devModeInspectCodeConfig) {
            const config = this.zustandStore2.getState()[key] as Wukong.DocumentProto.IDevModeInspectCodeConfig
            this.commandInvoker.DEPRECATED_invokeBridge(UpdateDevModeCodeConfigCommand, { config })

            return
        }

        if (SEND_TO_WASM_KEY.includes(key as keyof Wukong.DocumentProto.IUserConfig2)) {
            if (isMigrated(key)) {
                const data = this.zustandStore2.getState()[key]
                this.commandInvoker.DEPRECATED_invokeBridge(UpdateUserPreferrence2, {
                    [key]: data,
                })
            }
        }
    }

    private debounceSetItem = debounce((key: string, value: string) => {
        enhancedLocalStorage.setItem(
            {
                key: LocalStorageKey.UserPreference,
                suffix: key,
            },
            value
        )
    }, 300)

    private debounceLeftPanelWidth = debounce((value: number) => {
        enhancedLocalStorage.setSerializedItem(LocalStorageKey.LeftPanelWidth, value)
    }, 300)

    private setLocalStorageData<K extends UserConfigKey>(key: K, value: typeof USER_CONFIG_DEFAULT_VALUES2[K]) {
        enhancedLocalStorage.setSerializedItem(getStorageByKey(key) as LocalStorageKey, value)
    }

    private pushConfigToStorage = async (key: UserConfigKey) => {
        if (isGlobalConfigKey(key)) {
            const storage = getStorageByKey(key)
            if (key === 'leftPanelWidth') {
                this.debounceLeftPanelWidth(this.zustandStore2.getState()[key])
            } else {
                if (isMigrated(key)) {
                    const value = this.zustandStore2.getState()[key]
                    if (value !== undefined) {
                        this.setLocalStorageData(key, value)
                    }
                }
            }
        } else if (isDocConfigKey(key)) {
            try {
                const store = await getUserConfigStore()
                store.put({
                    key: STORAGE_PREFIX + this.docId,
                    value: this.getDocConfigsJsonStr(),
                })
                enhancedLocalStorage.setSerializedItem(LocalStorageKey.DocConfigUpdate, Date.now() + '')
            } catch {}
        } else if (isDocAuthConfigKey(key)) {
            try {
                const store = await getUserConfigStore()
                store.put({
                    key: STORAGE_PREFIX + this.authStr,
                    value: this.getDocAuthConfigsJsonStr(),
                })
                enhancedLocalStorage.setSerializedItem(LocalStorageKey.AuthConfigUpdate, Date.now() + '')
            } catch {}
        }
    }

    // 对 server 的更新是全量的
    // 如果一次性更新大量设置，尽管会同步调用多次该函数，但 throttle 会做节流处理，在稍晚的时间统一上传
    private pushConfigsToServer() {
        if (this.throttleUploading) {
            clearTimeout(this.throttleUploading)
            this.throttleUploading = null
        }
        this.throttleUploading = setTimeout(() => {
            try {
                const data = this.generateUploadConfigsData()
                new UpdateUserConfig(data).start()
            } catch {}
        }, THROTTLE_UPLOAD_CONFIG_TIME)
    }

    private generateUploadConfigsData() {
        const preference: UserPreferenceInner = {
            global: this.getGlobalConfigsJsonStr(),
        }
        preference[this.docId] = this.getDocConfigsJsonStr()
        preference[DocAuth.Editable] = this.getDocAuthConfigsJsonStr()

        return { docId2Preference: preference }
    }

    // 递归处理嵌套对象. 移除 proto 自带的 toJSON 方法
    private toPlainObject<T>(v: T): DeepRequired<OmitToJson<T>> {
        if (!v || typeof v !== 'object') {
            return v as any
        }
        const newObj = { ...v } as any
        delete newObj.toJSON

        Object.entries(newObj).forEach(([key, val]) => {
            if (val && typeof val === 'object') {
                newObj[key] = this.toPlainObject(val)
            }
        })

        return newObj
    }

    private getGlobalConfigsJsonStr() {
        const globalConfig = GLOBAL_CONFIG_KEYS.reduce((config, _key) => {
            const rawValue = this.zustandStore2.getState()[_key]
            config[_key] = this.toPlainObject(rawValue)
            return config
        }, {} as any)
        return JSON.stringify(globalConfig)
    }

    private getDocConfigsJsonStr() {
        const docConfig = DOC_CONFIG_KEYS.reduce((config, _key) => {
            config[_key] = this.zustandStore2.getState()[_key]
            return config
        }, {} as any)
        return JSON.stringify(docConfig)
    }

    private getDocAuthConfigsJsonStr() {
        const authConfig = DOC_AUTH_CONFIG_KEYS.reduce((config, _key) => {
            config[_key] = this.zustandStore2.getState()[_key]
            return config
        }, {} as any)
        return JSON.stringify(authConfig)
    }
}

function pushDevModeInspectExportUserConfigToEditor(commandInvoker: CommandInvoker, jsonStr: string) {
    const userConfig = JSON.parse(jsonStr) as Wukong.DocumentProto.IDevModeInspectExportUserConfig
    commandInvoker.DEPRECATED_invokeBridge(UpdateDevModeInspectExportCommandWasmCall, userConfig)
}

function pushDevModeInspectCodeConfigToEditor(commandInvoker: CommandInvoker, jsonStr: string) {
    const config = JSON.parse(jsonStr) as Wukong.DocumentProto.IDevModeInspectCodeConfig
    commandInvoker.DEPRECATED_invokeBridge(UpdateDevModeCodeConfigCommand, { config })
}
