import { WkCLog } from '../../../kernel/clog/wukong/instance'
import { UserVOV2 } from '../../../kernel/interface/account'
import { EditorContextUnion } from '../types/editor'
import {
    AppState,
    AppState2AsyncResourceControl,
    AppState2Callback,
    AppState2CallbackArgs,
    AppState2LifecycleAsyncResource,
    AppState2ResolveType,
    AppStateNeedsAsyncResource,
    CustomCallback,
    DeleteLifecycleContext,
    LifecycleAsyncResource,
    LifecycleContext,
    LifecycleTraceInfo,
    StateCallbackInjector,
    appStateNeedsAsyncResource,
} from '../types/index'

/**
 * 生命周期的内核
 * 业务可以在任何地方开启一个生命周期的 flow，生命周期完成切换依赖业务注入当前需要的依赖。
 * 当前 flow 所需依赖收集完毕后，会自动切换到下一个生命周期
 */
export class Lifecycle<T extends EditorContextUnion> {
    // 维护当前所处的生命周期
    private currentState: AppState = AppState.CreateApp

    // 存储 appState -> 自定义回调
    private appState2CustomCallbacks: Record<AppState, CustomCallback<AppState, T>[]> = Object.values(AppState).reduce(
        (res, cur) => {
            res[cur] = []
            return res
        },
        {} as Record<AppState, CustomCallback<AppState, T>[]>
    )

    // 存储生命周期所需的 resolve / reject，当 resolve 执行时，生命周期对应的 flow 才会继续执行
    private appState2AsyncResourceControl: AppState2AsyncResourceControl<AppStateNeedsAsyncResource> = {
        [AppState.Login]: {},
    }

    // 存储生命周期各阶段生成的 context
    private lifecycleContext: Partial<LifecycleContext<T>> = {}

    /**
     * 提供 lifecycle 的 trace 能力
     */
    private lifecycleTraceInfo: LifecycleTraceInfo = {
        currentState: AppState.CreateApp,
        currentUnFinishedCustomCallbackIndex: -1,
        appState2CallbackDescriptionsList: {},
        appState2AsyncResourceComplete: {
            [AppState.Login]: {
                [LifecycleAsyncResource.UserInfo]: false,
            },
        },
    }

    /**
     * 存储生命周期回调的 cleanup，在生命周期回退时会逆向执行
     */
    private appState2CallbackCleanups: Record<
        AppState,
        ((deleteLifecycleContext: DeleteLifecycleContext<T>) => void)[]
    > = Object.values(AppState).reduce((res, cur) => {
        res[cur] = []
        return res
    }, {} as Record<AppState, ((deleteLifecycleContext: DeleteLifecycleContext<T>) => void)[]>)

    private enterEditorCount = 0

    /**
     * 生命周期在初始化时，回收集注入在各个生命周期的回调。在切换到对应的生命周期时，自动执行其回调
     */
    constructor(forTest: boolean, normal: boolean) {
        if (forTest) {
            return
        }
    }

    /**
     * 开启创建 app 的流程
     */
    public beginStartAppFlow() {
        // 执行 createApp 回调
        this.invokeStateCallback(AppState.CreateApp, { getTraceInfo: this.getLifecycleTraceInfo.bind(this) })
        // 开启 login 的流程
        this.beginLoginFlow()
    }

    /**
     * 应对退出登录的场景，该场景需要重置 State 到 login 并重新开启 beginLogin 的流程
     */
    public resetToBeginLoginFlow() {
        // 清理到 login 为止执行过的 callbacks 副作用
        this.cleanupCallbacks(AppState.Login)

        this.beginLoginFlow()
    }

    /**
     * 注入用户信息
     * @param userInfo
     */
    public injectUserInfo(userInfo: UserVOV2): void {
        const userInfoResolve = this.appState2AsyncResourceControl[AppState.Login].resolve

        if (userInfoResolve) {
            userInfoResolve(userInfo)
        }
    }

    /**
     * 获取 traceInfo
     */
    public getLifecycleTraceInfo(): LifecycleTraceInfo {
        this.generateStateAndCallbackDescription()
        return this.lifecycleTraceInfo
    }

    /**
     * 开启登录的流程
     */
    private async beginLoginFlow() {
        this.switchTargetState(AppState.Login)

        // login 的 flow 需要让外部注入 user 的信息
        const userInfoPromise = this.generateCanResolvePromise(AppState.Login)

        // login 的流程需要等待注入 userInfo 后才可以继续运行
        const userInfo = await userInfoPromise

        // 标记资源完成
        this.markAsyncResourceComplete(AppState.Login, LifecycleAsyncResource.UserInfo)

        if (!userInfo || this.currentState !== AppState.Login) {
            return
        }

        // 执行 login 后的 callback
        this.invokeStateCallback(AppState.Login, {
            ...this.lifecycleContext,
            userInfo,
        } as AppState2CallbackArgs<AppState.Login, T>)
    }

    /**
     * 执行注入在当前生命周期的回调
     * @param targetState
     */
    private invokeStateCallback<S extends AppState>(appState: S, args: AppState2CallbackArgs<S, T>): void {
        const customCallbacks = this.appState2CustomCallbacks[appState]

        customCallbacks.forEach(({ callback }, index) => {
            this.lifecycleTraceInfo.currentUnFinishedCustomCallbackIndex = index
            const cleanup = callback(args, this.fillLifecycleContext)
            if (cleanup && typeof cleanup === 'function') {
                this.appState2CallbackCleanups[appState].push(cleanup)
            }
        })
    }

    /**
     * 根据 context 装填 lifecycleContext
     * @param context
     */
    public fillLifecycleContext = (context: Partial<LifecycleContext<T>>) => {
        this.lifecycleContext = Object.assign(this.lifecycleContext, context)
    }

    /**
     * 获取当前的 app 状态
     * @returns
     */
    public getCurrentState(): AppState {
        return this.currentState
    }

    /**
     * 获取当前的 context
     * @returns
     */
    public getContext(): Partial<LifecycleContext<T>> {
        return this.lifecycleContext
    }

    /**
     * 为对应的生命周期 hook 提供注入回调的方法，通过这个注入器，在添加 callback 时会同步写入描述，并能限制回调执行参数
     * @param appState
     * @returns
     */
    public generateStateCallbackInjector<S extends AppState, E extends EditorContextUnion>(
        appState: S,
        shouldCleanup = false
    ): StateCallbackInjector<S, E> {
        return (description: string, callback: AppState2Callback<S, E>) => {
            // 注入 customCallback
            const customCallback: CustomCallback<S, E> = {
                description,
                callback,
                shouldCleanup,
            }
            // FIXME: 这里类型会报错，需要手动转换一下
            this.appState2CustomCallbacks[appState].push(customCallback as unknown as CustomCallback<S, T>)
        }
    }
    public removeStateCallbacks(appState: AppState) {
        this.appState2CustomCallbacks[appState] = []
    }

    /**
     * 在 react 为生命周期注入回调。这些回调是在 runtime 注入，所以在回退时会自动清理
     * @param appState
     * @param injectCallBack
     */
    public injectStateCallbackForReact<S extends AppState>(
        appState: S,
        injectCallBack: (injector: StateCallbackInjector<S, T>) => void
    ): void {
        injectCallBack(this.generateStateCallbackInjector(appState, true))
    }

    /**
     * 为 promise 生成一个 resolve，主动 resolve 时 promise 完成
     * @param ref
     * @returns
     */
    private generateCanResolvePromise<E extends AppStateNeedsAsyncResource>(
        state: E
    ): Promise<AppState2ResolveType<E> | void> {
        const ref = this.appState2AsyncResourceControl[state]

        const promise = new Promise<AppState2ResolveType<E>>((resolve, reject) => {
            ref.resolve = (args) => {
                resolve(args as AppState2ResolveType<E>)
            }

            ref.reject = (reason?: any) => {
                reject(reason)
            }
        }).catch(() => {
            WkCLog.log(`lifecycle reject at ${state}`)
        })

        return promise
    }

    /**
     * 为 trace 初始化各 appState 和其 callback 描述
     */
    public generateStateAndCallbackDescription(): void {
        Object.values(AppState).forEach((state) => {
            const customCalllbacks = this.appState2CustomCallbacks[state]
            this.lifecycleTraceInfo.appState2CallbackDescriptionsList[state] = customCalllbacks
                ? customCalllbacks.map(({ description }) => description)
                : []
        })
    }

    /**
     * 将 currentState 切换到目标 state
     * @param targetState
     */
    private switchTargetState(targetState: AppState): void {
        this.currentState = targetState
        // 更新 trace
        this.lifecycleTraceInfo.currentState = targetState
        this.lifecycleTraceInfo.currentUnFinishedCustomCallbackIndex = -1
    }

    /**
     * 执行生命周期的回退时，需要清理掉 callback 的副作用
     * 清理当前生命周期到目标生命周期的全部回调
     * @param endState
     */
    private cleanupCallbacks(endState: AppState): void {
        const stateList = Object.values(AppState)

        const startIndex = stateList.indexOf(this.currentState)
        const endIndex = stateList.indexOf(endState)

        // 只有当前生命周期大于等于回溯目标的生命周期才执行
        if (startIndex >= endIndex) {
            for (let index = startIndex; index >= endIndex; index--) {
                const state = stateList[index]

                // 逆向执行清理
                const callbackCleanups = this.appState2CallbackCleanups[state].reverse()

                for (const callbackCleanup of callbackCleanups) {
                    callbackCleanup(this.deleteLifecycleContext.bind(this))
                }

                // 执行完后清理回调
                this.appState2CallbackCleanups[state] = []

                // 按需清理 callback[这些 callback 为 react 中动态插入的 callback]
                this.appState2CustomCallbacks[state].forEach((customCallback, customCallbackIndex) => {
                    if (customCallback.shouldCleanup) {
                        this.appState2CustomCallbacks[state].splice(customCallbackIndex, 1)
                    }
                })

                if (appStateNeedsAsyncResource.includes(state as AppStateNeedsAsyncResource)) {
                    const resourceControl = this.appState2AsyncResourceControl[state as AppStateNeedsAsyncResource]
                    // 清理异步任务
                    resourceControl.reject?.('cleanup')
                    // 清理 trace
                    this.resetAsyncResourceTraceInfo(
                        this.lifecycleTraceInfo.appState2AsyncResourceComplete[state as AppStateNeedsAsyncResource]
                    )
                }
            }
        }
    }

    /**
     * 重置异步资源的完成状态
     * @param asyncResourceComplete
     */
    private resetAsyncResourceTraceInfo(
        asyncResourceComplete: Partial<Record<AppState2LifecycleAsyncResource<AppStateNeedsAsyncResource>, boolean>>
    ): void {
        Object.keys(asyncResourceComplete).forEach((key) => {
            asyncResourceComplete[key as LifecycleAsyncResource] = false
        })
    }

    /**
     * 标记异步资源完成回调
     * @param appState
     * @param resource
     */
    // eslint-disable-next-line @typescript-eslint/no-shadow
    private markAsyncResourceComplete<T extends AppStateNeedsAsyncResource>(
        appState: T,
        resource: AppState2LifecycleAsyncResource<T>
    ): void {
        this.lifecycleTraceInfo.appState2AsyncResourceComplete[appState][resource] = true
    }

    /**
     * 删除 lifecycle 对应的 context
     */
    private deleteLifecycleContext(...args: (keyof LifecycleContext<T>)[]): void {
        for (const arg of args) {
            this.lifecycleContext[arg] = undefined
        }
    }
}
