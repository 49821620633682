import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import { Tooltip, useEllipsisTooltip } from '../../../../../../ui-lib/src'
import { useViewState } from '../../../../view-state-bridge'
import { CopyableRow } from '../comp/copyable-row'
import styles from './index.module.less'

interface ComponentRelatedDisplayProp {
    value: string
    name: string
}

function PropKeyWithTooltip({ value }: { value: string }) {
    const { inactivation, ellipsisRef, onmouseenter } = useEllipsisTooltip<HTMLDivElement>()
    return (
        <Tooltip inactivation={inactivation} title={value}>
            <div
                className={styles.propKey}
                ref={ellipsisRef}
                onMouseEnter={onmouseenter}
                data-testid={'inspect-component-prop-key-' + value}
            >
                {value}
            </div>
        </Tooltip>
    )
}

function ComponentProperties(props: { compProps: ComponentRelatedDisplayProp[] }) {
    const { compProps } = props
    return (
        <>
            <div className={styles.propBlock}>
                <div className={styles.propKeyArea}>
                    {compProps.map((prop, index) => (
                        <PropKeyWithTooltip value={prop.name} key={index} />
                    ))}
                </div>
                <div className={styles.propValueArea}>
                    {compProps.map((prop, index) => (
                        <div
                            className="h-24px"
                            key={index}
                            data-testid={`inspect-component-prop-value-${index}-${prop.value}`}
                        >
                            {prop.value ? (
                                <CopyableRow copyValue={prop.value} className={styles.propValue}>
                                    {prop.value}
                                </CopyableRow>
                            ) : (
                                '-'
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export function InspectComponentPropInfo() {
    const viewState = useViewState('inspectSelectionComponentPropInfoViewState')

    if (!viewState?.show) {
        return null
    }
    if (!viewState.props.length) {
        return null
    }

    return (
        <div className={styles.panel} data-testid="inspect-component-prop-panel">
            <div className={styles['panel-title']}>{translation('ComponentProp')}</div>
            <div className={styles['panel-content']}>
                <ComponentProperties compProps={viewState.props} />
            </div>
        </div>
    )
}
