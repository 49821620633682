import { Wukong } from '@wukong/bridge-proto'
import { translation } from './config.translation'

import PresetCodeType = Wukong.DocumentProto.PresetCodeType
import FileType = Wukong.DocumentProto.FileType
import ScaleItem = Wukong.DocumentProto.ScaleItem

export const presetCodeTypeToName = new Map<PresetCodeType, string>([
    [PresetCodeType.PRESET_CODE_TYPE_WEB, translation('Web')],
    [PresetCodeType.PRESET_CODE_TYPE_I_O_S, translation('iOS')],
    [PresetCodeType.PRESET_CODE_TYPE_ANDROID, translation('Android')],
])

export const fileTypeToName = new Map<FileType, string>([
    [FileType.FILE_TYPE_PNG, 'PNG'],
    [FileType.FILE_TYPE_JPEG, 'JPG'],
    [FileType.FILE_TYPE_WEBP, 'WebP'],
    [FileType.FILE_TYPE_AVIF, 'AVIF'],
    [FileType.FILE_TYPE_SVG, 'SVG'],
    [FileType.FILE_TYPE_PDF, 'PDF'],
])

const scaleItemNameForWebAndIOS = new Map<ScaleItem, string>([
    [ScaleItem.SCALE_ITEMX4, '4x'],
    [ScaleItem.SCALE_ITEMX3, '3x'],
    [ScaleItem.SCALE_ITEMX2, '2x'],
    [ScaleItem.x1_5, '1.5x'],
    [ScaleItem.SCALE_ITEMX1, '1x'],
])

const scaleItemNameForAndroid = new Map<ScaleItem, string>([
    [ScaleItem.SCALE_ITEMX4, 'xxxhdpi 4x'],
    [ScaleItem.SCALE_ITEMX3, 'xxhdpi 3x'],
    [ScaleItem.SCALE_ITEMX2, 'xhdpi 2x'],
    [ScaleItem.x1_5, 'hdpi 1.5x'],
    [ScaleItem.SCALE_ITEMX1, 'mdpi 1x'],
])

export function getScaleItemName(scaleItem: ScaleItem, presetCodeType: PresetCodeType) {
    if (presetCodeType === PresetCodeType.PRESET_CODE_TYPE_ANDROID) {
        return scaleItemNameForAndroid.get(scaleItem)
    }
    return scaleItemNameForWebAndIOS.get(scaleItem)
}
