import { useMemo, useRef } from 'react'
import {
    MonoIconCommonAdd16,
    MonoIconCommonClose16,
    Tooltip,
    WKIconButton,
    WKTabsV2,
} from '../../../../../../../ui-lib/src'
import { ColorInteractionFrom, ColorPanelTitleActiveKey, ColorPanelTitleProps } from '../type'
import classes from './color-panel-title.module.less'
import { translation } from './color-panel-title.translation'

export function ColorPanelTitle(props: ColorPanelTitleProps) {
    const ref = useRef<HTMLDivElement>(null)
    const derivedProps = useMemo(() => {
        const _derivedProps = {
            customLabel: translation('Custom'),
            hideLibraries: false,
            hideAddIcon: false,
            addIconTooltipTitle: translation('CreateStyleOrVariable'),
        }
        switch (props.from) {
            case ColorInteractionFrom.LOCAL_VARIABLE_EDIT:
            case ColorInteractionFrom.LOCAL_VARIABLE_CREATE_ALIAS:
            case ColorInteractionFrom.STYLE_PAINT:
            case ColorInteractionFrom.COLOR_STOP: {
                _derivedProps.hideAddIcon = true
                break
            }
            case ColorInteractionFrom.BACKGROUND: {
                _derivedProps.customLabel = translation('CanvasBackground')
                _derivedProps.hideLibraries = true
                _derivedProps.hideAddIcon = true
                break
            }
            case ColorInteractionFrom.GRID: {
                _derivedProps.customLabel = translation('LayoutGrid')
                _derivedProps.hideLibraries = true
                _derivedProps.hideAddIcon = true
                break
            }
            case ColorInteractionFrom.EFFECT: {
                _derivedProps.addIconTooltipTitle = translation('CreateVariable')
                break
            }
            case ColorInteractionFrom.FILL:
            case ColorInteractionFrom.FILL_PAINT:
            case ColorInteractionFrom.STROKE:
            case ColorInteractionFrom.STROKE_PAINT:
            case ColorInteractionFrom.SELECTION_COLOR:
            case ColorInteractionFrom.SELECTION_COLOR_FAKE_STYLE:
        }
        return _derivedProps
    }, [props.from])

    return (
        <div className={classes.colorPanelTitle} ref={ref} data-testid="color-panel-title">
            <WKTabsV2
                activeKey={props.activeKey}
                size="small"
                className={classes.block}
                onActiveTabChange={(tabKey) => props.onActiveTabChange(tabKey as ColorPanelTitleProps['activeKey'])}
            >
                <WKTabsV2.Item
                    tabKey={ColorPanelTitleActiveKey.CUSTOM}
                    label={derivedProps.customLabel}
                ></WKTabsV2.Item>
                {derivedProps.hideLibraries ? null : (
                    <WKTabsV2.Item
                        tabKey={ColorPanelTitleActiveKey.LIBRARIES}
                        label={translation('Libraries')}
                    ></WKTabsV2.Item>
                )}
            </WKTabsV2>
            <div className={classes.block} style={{ marginRight: 10 }}>
                {derivedProps.hideAddIcon ? null : (
                    <Tooltip title={derivedProps.addIconTooltipTitle}>
                        <WKIconButton
                            icon={<MonoIconCommonAdd16 />}
                            style={{ margin: 0 }}
                            onClick={() => props.onClickAddIcon?.(ref.current?.getBoundingClientRect()!)}
                            data-testid="color-panel-title-add-icon"
                        />
                    </Tooltip>
                )}
                <WKIconButton
                    icon={<MonoIconCommonClose16 />}
                    style={{ marginLeft: 4 }}
                    onClick={props.onClickCloseIcon}
                    data-testid="color-panel-title-close-icon"
                />
            </div>
        </div>
    )
}
