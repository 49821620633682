import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    MoveToTeamTitle: '移动至团队以使用研发模式',
    MoveToTeamContent: '研发模式只能在付费团队中使用，请将此文件移动至付费团队后再开启研发模式。',
    CanNotUseDevModeTitle: '无法进入研发模式',
    CanNotUseDevModeContent: '研发模式只能在付费团队中使用。',
    Move: '移动',
    Cancel: '取消',
    IKnow: '我知道了',
} as const

export const enTranslation = {
    MoveToTeamTitle: 'Move to a team to use Develop Mode',
    MoveToTeamContent:
        'Develop Mode is available only in teams on paid plans. To start using Develop Mode, move the files to these teams.',
    CanNotUseDevModeTitle: 'Unable to enter Develop Mode',
    CanNotUseDevModeContent: 'Develop Mode is available only in teams on paid plans.',
    Move: 'Move',
    Cancel: 'Cancel',
    IKnow: 'OK',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
