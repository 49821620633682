import { Wukong } from '@wukong/bridge-proto'
import { OmitToJSON } from '../../../../../../../ui-lib/src'
import { StyleWithoutDocIdGetVO } from '../../../../../kernel/interface/component-style'
import { parseStyleName } from '../../styles/get-style-nodes-info-map'
import { ColorInteractionFrom } from '../type'

export const splitColorVariableName = (name: string) => {
    const strs = name.split('/')
    const variableName = strs.pop()!
    const groupName = strs.join('/')
    return { groupName, variableName }
}
export interface LocalColorVariableItem {
    name: string
    description: string
    color: Wukong.DocumentProto.IRGBA | null | undefined
    originData: Wukong.DocumentProto.ILocalColorVariable
}
interface LocalColorVariableGroupItem {
    name: string
    variables: LocalColorVariableItem[]
}
interface LocalColorCollection {
    collection: { name: string; nodeId: string }
    freeVariables: LocalColorVariableItem[]
    groups: LocalColorVariableGroupItem[]
    size: number
}

interface Filters {
    from: ColorInteractionFrom
    scope: Wukong.DocumentProto.ISelectionColorScope
    searchText: string
}

function filterMatchScope(
    nodeScopes: Wukong.DocumentProto.VariableScope[],
    from: ColorInteractionFrom,
    selectionScope: Wukong.DocumentProto.ISelectionColorScope
) {
    if (nodeScopes.includes(Wukong.DocumentProto.VariableScope.ALL_SCOPES)) {
        return true
    }
    switch (from) {
        case ColorInteractionFrom.STROKE:
        case ColorInteractionFrom.STROKE_PAINT:
            return nodeScopes.includes(Wukong.DocumentProto.VariableScope.VARIABLE_SCOPE_S_T_R_O_K_E)
        case ColorInteractionFrom.EFFECT:
            return nodeScopes.includes(Wukong.DocumentProto.VariableScope.EFFECT_COLOR)
        case ColorInteractionFrom.FILL:
        case ColorInteractionFrom.FILL_PAINT:
            if (nodeScopes.includes(Wukong.DocumentProto.VariableScope.ALL_FILLS)) {
                return true
            }
            if (selectionScope.hasText && nodeScopes.includes(Wukong.DocumentProto.VariableScope.TEXT_FILL)) {
                return true
            }
            if (selectionScope.hasShape && nodeScopes.includes(Wukong.DocumentProto.VariableScope.SHAPE_FILL)) {
                return true
            }
            if (selectionScope.hasFrame && nodeScopes.includes(Wukong.DocumentProto.VariableScope.FRAME_FILL)) {
                return true
            }
            return false
        case ColorInteractionFrom.SELECTION_COLOR:
        case ColorInteractionFrom.SELECTION_COLOR_FAKE_STYLE:
            return (
                nodeScopes.includes(Wukong.DocumentProto.VariableScope.VARIABLE_SCOPE_S_T_R_O_K_E) ||
                nodeScopes.includes(Wukong.DocumentProto.VariableScope.ALL_FILLS) ||
                nodeScopes.includes(Wukong.DocumentProto.VariableScope.TEXT_FILL) ||
                nodeScopes.includes(Wukong.DocumentProto.VariableScope.SHAPE_FILL) ||
                nodeScopes.includes(Wukong.DocumentProto.VariableScope.FRAME_FILL)
            )
        case ColorInteractionFrom.COLOR_STOP:
        case ColorInteractionFrom.STYLE_PAINT:
        case ColorInteractionFrom.LOCAL_VARIABLE_EDIT:
        case ColorInteractionFrom.LOCAL_VARIABLE_CREATE_ALIAS:
            return true
        case ColorInteractionFrom.BACKGROUND:
        case ColorInteractionFrom.GRID:
            return false
    }
}

function filterMatchText(variableName: string, searchText: string) {
    return variableName.includes(searchText)
}

export function formateLocalColorCollections(
    collections: Wukong.DocumentProto.ILocalColorCollection[],
    filters: Filters
) {
    const colorCollections: LocalColorCollection[] = []
    for (const collection of collections) {
        let size = 0
        const freeVariables: LocalColorVariableItem[] = []
        const groupsMap: Map<string, LocalColorVariableGroupItem> = new Map()
        for (const variable of collection.variables) {
            if (!filterMatchScope(variable.scopes, filters.from, filters.scope)) {
                continue
            }
            if (
                !filterMatchText(variable.name, filters.searchText) &&
                !filterMatchText(variable.description, filters.searchText)
            ) {
                continue
            }
            size++
            // 如果组名也如local面板那里的顺序，可以用如下思路完成排序
            // 1. splitColorVariableName获取所有 groupNameUnits = groupName.split('/')
            // 2. 遍历 groupNameUnits，在groupsMap中找到对应的group，如果找不到，则创建一个新的group。同时这里也会把此次的变量归入到group中（如果有groupName的话）
            // 3. 最后，剔除groupsMap中没有变量的group
            const { groupName, variableName } = splitColorVariableName(variable.name)
            const variableItem: LocalColorVariableItem = {
                name: variableName,
                description: variable.description,
                color: variable.color,
                originData: variable,
            }
            if (groupName) {
                const targetGroup = groupsMap.get(groupName)
                if (targetGroup) {
                    targetGroup.variables.push(variableItem)
                } else {
                    groupsMap.set(groupName, { name: groupName, variables: [variableItem] })
                }
            } else {
                freeVariables.push(variableItem)
            }
        }
        if (size === 0) {
            continue
        }
        colorCollections.push({
            collection: {
                name: collection.name,
                nodeId: collection.nodeId,
            },
            freeVariables,
            groups: [...groupsMap.values()],
            size,
        })
    }
    return colorCollections
}

export interface RemoteColorVariableItem {
    name: string
    description: string
    color: Wukong.DocumentProto.IRGBA | null | undefined
    originData: Wukong.DocumentProto.IRemoteColorVariable
}
interface RemoteColorVariableGroupItem {
    name: string
    variables: RemoteColorVariableItem[]
}
interface RemoteColorCollection {
    collection: { name: string; id: string }
    freeVariables: RemoteColorVariableItem[]
    groups: RemoteColorVariableGroupItem[]
    size: number
}

function formateRemoteColorCollections(collections: Wukong.DocumentProto.IRemoteColorCollection[], filters: Filters) {
    const colorCollections: RemoteColorCollection[] = []
    for (const collection of collections) {
        let size = 0
        const freeVariables: RemoteColorVariableItem[] = []
        const groupsMap: Map<string, RemoteColorVariableGroupItem> = new Map()
        for (const variable of collection.variables) {
            if (!filterMatchScope(variable.scope, filters.from, filters.scope)) {
                continue
            }
            if (
                !filterMatchText(variable.name, filters.searchText) &&
                !filterMatchText(variable.description, filters.searchText)
            ) {
                continue
            }
            size++
            const { groupName, variableName } = splitColorVariableName(variable.name)
            const variableItem: RemoteColorVariableItem = {
                name: variableName,
                description: variable.description,
                color: variable.color,
                originData: variable,
            }
            if (groupName) {
                const targetGroup = groupsMap.get(groupName)
                if (targetGroup) {
                    targetGroup.variables.push(variableItem)
                } else {
                    groupsMap.set(groupName, { name: groupName, variables: [variableItem] })
                }
            } else {
                freeVariables.push(variableItem)
            }
        }
        if (size === 0) {
            continue
        }
        colorCollections.push({
            collection: {
                name: collection.name,
                id: collection.id,
            },
            freeVariables,
            groups: [...groupsMap.values()],
            size,
        })
    }
    return colorCollections
}

export function formateRemoteColorLibraries(libraries: Wukong.DocumentProto.IRemoteColorLibrary[], filters: Filters) {
    const colorLibraries: {
        id: string
        name: string
        documentId: string
        collections: RemoteColorCollection[]
    }[] = []

    for (const library of libraries) {
        const collections = formateRemoteColorCollections(library.collections, filters)
        if (collections.length === 0) {
            continue
        }
        colorLibraries.push({
            id: library.id,
            name: library.name,
            documentId: library.documentId,
            collections,
        })
    }

    return colorLibraries
}

export type LocalStyleCollectionItem = OmitToJSON<Wukong.DocumentProto.LibraryPaintStyleNodeItem> & {
    styleName: string
    groupName: string
}

export function formateLocalStyleCollections(
    collections: Wukong.DocumentProto.ILibraryPaintStyleGroup[],
    filters: { searchText: string }
) {
    const styleCollections: (Omit<Wukong.DocumentProto.ILibraryPaintStyleGroup, 'items'> & {
        items: LocalStyleCollectionItem[]
    })[] = []
    for (const collection of collections) {
        if (!collection.items?.length) {
            continue
        }
        const items = []
        for (const item of collection.items) {
            if (
                !filterMatchText(item.name ?? '', filters.searchText) &&
                !filterMatchText(item.description ?? '', filters.searchText)
            ) {
                continue
            }
            const { styleName, groupName } = parseStyleName(item.name ?? '')
            items.push({
                ...(item as OmitToJSON<Wukong.DocumentProto.LibraryPaintStyleNodeItem>),
                styleName,
                groupName,
            })
        }
        if (!items.length) {
            continue
        }
        styleCollections.push({ ...collection, items })
    }
    return styleCollections
}

export function formateRemoteStyleLibraries(
    libraries: Wukong.DocumentProto.ILibraryPaintStyleRemoteItem[],
    filters: { searchText: string; remoteStyleKey?: string }
) {
    const styleLibraries = []
    for (const library of libraries as OmitToJSON<Wukong.DocumentProto.LibraryPaintStyleRemoteItem>[]) {
        const groups: Array<{ name: string; items: StyleWithoutDocIdGetVO[] }> = []
        for (const group of library.groups ?? []) {
            const items: StyleWithoutDocIdGetVO[] = []
            for (const item of group.items ?? []) {
                if (filters.remoteStyleKey && item.id !== filters.remoteStyleKey) {
                    continue
                }
                if (
                    !filterMatchText(item.name ?? '', filters.searchText) &&
                    !filterMatchText(item.description ?? '', filters.searchText)
                ) {
                    continue
                }
                items.push(item as StyleWithoutDocIdGetVO)
            }
            if (!items.length) {
                continue
            }
            groups.push({ name: group.name ?? '', items })
        }

        if (!groups.length) {
            continue
        }
        styleLibraries.push({
            libraryId: library.libraryId,
            libraryName: library.libraryName,
            docId: library.documentId,
            groups,
        })
    }
    return styleLibraries
}

export const createListChunks = <T, R>(items: T[], chunkSize: number, mapper: (item: T, index: number) => R) => {
    const chunks: R[][] = []
    let chunk: R[] = []
    items.forEach((item, index) => {
        chunk.push(mapper(item, index))
        if (chunk.length === chunkSize) {
            chunks.push(chunk)
            chunk = []
        }
    })

    if (chunk.length > 0) {
        chunks.push(chunk)
    }

    return chunks
}

export const uidGenerator = () => {
    let id = 0
    return () => id++
}
