import { compareString } from '../../../../util/src'
import { translation } from './sort.translation'
import { SortOrder } from './types'

export const SortOrderLabel1 = {
    [SortOrder.Ascending]: translation('Recent2Far'),
    [SortOrder.Descending]: translation('Far2Recent'),
}

export const SortOrderLabel2 = {
    [SortOrder.Ascending]: 'A-Z',
    [SortOrder.Descending]: 'Z-A',
}

export const SortOrderLabel3 = {
    [SortOrder.Ascending]: `${translation('MostToLeast')}`,
    [SortOrder.Descending]: `${translation('LeastToMost')}`,
}

export const SortOrderLabel4 = {
    [SortOrder.Ascending]: `${translation('HighLow')}`,
    [SortOrder.Descending]: `${translation('LowHigh')}`,
}

export const sortByString = (a: string, b: string, order: SortOrder) => {
    if (order === SortOrder.Ascending) {
        return compareString(a, b)
    } else {
        return compareString(b, a)
    }
}
export const sortByNumber = (a: number, b: number, order: SortOrder) => {
    if (order === SortOrder.Ascending) {
        return a - b
    } else {
        return b - a
    }
}

export interface SortState<T> {
    sortKey: T
    sortOrder: SortOrder
}
