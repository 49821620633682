import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    PublishReplace: '发布替换',
    Publishing: '发布中',
    ReplaceStyle: '替换样式',
    ReplaceComponent: '替换组件',
    PublishedSuccessfully: '发布成功',
    PublishFailed: '发布失败',
} as const

export const enTranslation = {
    PublishReplace: 'Publish replacements',
    Publishing: 'Publishing',
    ReplaceStyle: 'Replace styles',
    ReplaceComponent: 'Replace components',
    PublishedSuccessfully: 'Published successfully',
    PublishFailed: 'Publish failed',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
