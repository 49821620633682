import { translation } from './batch-export-item.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { Checkbox, Tooltip } from '../../../../../../ui-lib/src'
import { ReactComponent as IconWarning } from '../../../assets/common/export-warning.svg'
import { useCommand } from '../../../context/document-context'
import { useNodePreviewBase64 } from './hooks/use-get-node-preview'
import { ExportConfig, getNodesPreviewBase64ImageWithFixedSize } from './util'

import { useDebounce } from 'react-use'
import { useViewState } from '../../../../view-state-bridge'
import style from './batch-export-modal.module.less'
import ExportConstraintType = Wukong.DocumentProto.ExportConstraintType

const ExportFormatTypeMap = ['jpg', 'png', 'svg', 'pdf', 'webp', 'avif']

const constraintTypeToStr = (t: number): string => {
    switch (t) {
        case ExportConstraintType.EXPORT_CONSTRAINT_TYPE_SCALE: {
            return 'x'
        }
        case ExportConstraintType.EXPORT_CONSTRAINT_TYPE_HEIGHT: {
            return 'h'
        }
        case ExportConstraintType.EXPORT_CONSTRAINT_TYPE_LARGER_SIDE: {
            return 'l'
        }
        case ExportConstraintType.EXPORT_CONSTRAINT_TYPE_WIDTH: {
            return 'w'
        }
    }
    return ' '
}

export interface BatchExportItemContent extends ExportConfig {
    width: number
    height: number
}

export interface BatchExportItemProps {
    config: BatchExportItemContent
    selected: Set<BatchExportItemContent>
    select: (config: BatchExportItemContent) => void
    unSelect: (config: BatchExportItemContent) => void
    onClick: (nodeId: string) => void
    isSelected: (config: BatchExportItemContent) => boolean
}

const sizeMapByScale = (width: number, height: number, value: number): string => {
    const newWidth = width * value
    const newHeight = height * value
    const size = `${newWidth | 0}x${newHeight | 0}px`
    return size
}

const sizeMapByWidth = (width: number, height: number, value: number): string => {
    const newWidth = value
    const newHeight = (height * value) / width
    const size = `${newWidth | 0}x${newHeight | 0}px`
    return size
}

const sizeMapByHeight = (width: number, height: number, value: number): string => {
    const newWidth = (width * value) / height
    const newHeight = value
    const size = `${newWidth | 0}x${newHeight | 0}px`
    return size
}

const sizeMapByLargeSide = (width: number, height: number, value: number): string => {
    if (width > height) {
        return sizeMapByWidth(width, height, value)
    } else {
        return sizeMapByHeight(width, height, value)
    }
}

function ItemContent(props: BatchExportItemContent) {
    const content: string[] = []
    const constraint = `${props.value}${constraintTypeToStr(props.type)}`
    content.push(constraint)
    const format = ExportFormatTypeMap[props.format].toUpperCase()
    content.push(format)

    switch (props.type) {
        case ExportConstraintType.EXPORT_CONSTRAINT_TYPE_SCALE: {
            const size = sizeMapByScale(props.width, props.height, props.value)
            content.push(size)
            break
        }
        case ExportConstraintType.EXPORT_CONSTRAINT_TYPE_WIDTH: {
            const size = sizeMapByHeight(props.width, props.height, props.value)
            content.push(size)
            break
        }
        case ExportConstraintType.EXPORT_CONSTRAINT_TYPE_HEIGHT: {
            const size = sizeMapByWidth(props.width, props.height, props.value)
            content.push(size)
            break
        }
        case ExportConstraintType.EXPORT_CONSTRAINT_TYPE_LARGER_SIDE: {
            const size = sizeMapByLargeSide(props.width, props.height, props.value)
            content.push(size)
            break
        }
    }

    return <article className="text-gray wk-text-12">{content.join(' , ')}</article>
}

function useDebouncePreviewChange() {
    const changed = useViewState('nodePreviewTracksChanged')
    const [previewChange, setPreviewChange] = useState<number | undefined>(undefined)
    useDebounce(
        () => {
            setPreviewChange(changed)
        },
        150,
        [changed]
    )
    return previewChange
}

export function BatchExportItem(props: BatchExportItemProps) {
    const { config, selected, select, unSelect, onClick, isSelected } = props
    const changed = useDebouncePreviewChange()
    const { base64, setBase64 } = useNodePreviewBase64()
    const command = useCommand()
    const { ref, inView } = useInView()

    useEffect(() => {
        if (inView && base64 === null) {
            setBase64(getNodesPreviewBase64ImageWithFixedSize(command, config.node, 64))
            return
        }
        if (changed !== undefined && inView) {
            setBase64(getNodesPreviewBase64ImageWithFixedSize(command, config.node, 64))
        }
    }, [config.node, changed, setBase64, command, inView, base64])

    return (
        <div
            className={style['batch-export-modal-configs-item']}
            onClick={() => onClick(config.node[0])}
            data-testid={'batch-export-item'}
        >
            <div className={style['batch-export-modal-configs-check-box']}>
                <Checkbox
                    stopPropagation
                    checked={isSelected(config)}
                    onChange={(flag) => (flag ? select(config) : unSelect(config))}
                    testid={'batch-export-item-checkbox'}
                />
            </div>
            <div
                className={style['batch-export-modal-configs-item-img']}
                key={`${config.node[0]}${config.fileName}${selected}-img`}
            >
                <img ref={ref} draggable={false} src={base64 ?? ''} />
            </div>
            <div
                className={style['batch-export-modal-description']}
                key={`${config.node[0]}${config.fileName}${selected}-text`}
            >
                <div className={style['batch-export-modal-configs-item-frame-container']}>
                    <article
                        className={`text-black wk-font-medium wk-text-12 ${style['batch-export-modal-configs-item-frame-name']}`}
                    >
                        {config.name}
                    </article>
                    {config.duplicate ? (
                        <div onClick={(e) => e.stopPropagation()}>
                            <Tooltip title={translation('NameDuplicated')} placement="bottom">
                                <IconWarning />
                            </Tooltip>
                        </div>
                    ) : null}
                </div>
                <ItemContent {...config} />
            </div>
        </div>
    )
}
