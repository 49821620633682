import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    CreateLink: '创建链接',
} as const

export const enTranslation = {
    CreateLink: 'Create link',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
