import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    'Good Morning': '早上好',
    'Sunny, 68°F': '晴朗，68°F',
    'Your Morning Mix': '你的晨间精选',
    'Energetic tunes to start your day': '活力音乐开启美好一天',
    'Play Now': '立即播放',
    'Recently Played': '最近播放',
    'Morning Vibes': '晨间氛围',
    'Various Artists': '多位艺术家',
    'Sunrise Session': '日出时分',
    'Chill Mood': '轻松心情',
    'Wake Up...': '唤醒...',
    'Energy Mix': '能量混音',
    'Morning...': '早晨...',
    'Acoustic Vibes': '原声氛围',
    'Recommended for Morning': '早晨推荐',
    'Morning Energy Boost': '晨间能量提升',
    'Workout Mix': '健身混音',
    'Peaceful Awakening': '平静唤醒',
    'Meditation Sounds': '冥想音乐',
    'Sunrise Acoustic': '日出原声',
    'Morning Playlist': '晨间播放列表',
    Home: '首页',
    Search: '搜索',
    Library: '媒体库',
    Profile: '个人',
    Settings: '设置',
}

export const enTranslation = {
    'Good Morning': 'Good Morning',
    'Sunny, 68°F': 'Sunny, 68°F',
    'Your Morning Mix': 'Your Morning Mix',
    'Energetic tunes to start your day': 'Energetic tunes to start your day',
    'Play Now': 'Play Now',
    'Recently Played': 'Recently Played',
    'Morning Vibes': 'Morning Vibes',
    'Various Artists': 'Various Artists',
    'Sunrise Session': 'Sunrise Session',
    'Chill Mood': 'Chill Mood',
    'Wake Up...': 'Wake Up...',
    'Energy Mix': 'Energy Mix',
    'Morning...': 'Morning...',
    'Acoustic Vibes': 'Acoustic Vibes',
    'Recommended for Morning': 'Recommended for Morning',
    'Morning Energy Boost': 'Morning Energy Boost',
    'Workout Mix': 'Workout Mix',
    'Peaceful Awakening': 'Peaceful Awakening',
    'Meditation Sounds': 'Meditation Sounds',
    'Sunrise Acoustic': 'Sunrise Acoustic',
    'Morning Playlist': 'Morning Playlist',
    Home: 'Home',
    Search: 'Search',
    Library: 'Library',
    Profile: 'Profile',
    Settings: 'Settings',
}

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue ? getTranslationValue(enTranslation, zhTranslation, key, insert) : enTranslation[key]
}
