import {
    ClipboardDataFile,
    ClipboardDataItem,
    ClipboardDataPromiseFile,
    WKClipboardError,
    WKClipboardErrorType,
} from './clipboard-data-types'

const CLIPBOARD_UNAVAILABLE = 'navigator clipboard is not available.'
const CLIPBOARD_ACCESS_DENIED = 'navigator clipboard permission is denied.'

export interface INavigatorClipboardSupports {
    doesClipboardApiAvailable: () => boolean
    writeToClipboard: (data: ReadonlyArray<ClipboardDataItem | ClipboardDataFile>) => Promise<void>
    writeToClipboardPromiseFiles: (data: ReadonlyArray<ClipboardDataPromiseFile>) => Promise<void>
    readFromClipboard: () => Promise<ClipboardItems>
}

export class NavigatorClipboardService implements INavigatorClipboardSupports {
    /**
     * NavigatorAPI 是否可用
     * @returns {boolean}
     */
    public doesClipboardApiAvailable(): boolean {
        const available = !!navigator && !!navigator.clipboard
        if (!available) {
            console.warn(CLIPBOARD_UNAVAILABLE)
        }
        return available
    }

    /**
     * 将数据写入 NavigatorClipboard
     * @param {ReadonlyArray<ClipboardDataItem>} data
     * @returns
     */
    public async writeToClipboard(data: ReadonlyArray<ClipboardDataItem | ClipboardDataFile>) {
        const record: Record<string, Blob> = {}

        data.forEach((item) => {
            if ('buffer' in item) {
                record[item.type] = new Blob([item.buffer], {
                    type: item.type,
                })
            } else {
                record[item.type] = new Blob([item.content], { type: item.type })
            }
        })

        await navigator.clipboard.write([new ClipboardItem(record)])
    }

    public async writeToClipboardPromiseFiles(data: ReadonlyArray<ClipboardDataPromiseFile>) {
        const record: Record<string, Promise<Blob>> = {}

        data.forEach((item) => {
            record[item.type] = item.buffer.then(
                (buf) =>
                    new Blob([buf], {
                        type: item.type,
                    })
            )
        })

        await navigator.clipboard.write([new ClipboardItem(record)]).catch((e) => {
            throw new WKClipboardError(WKClipboardErrorType.NavigatorApi, e)
        })
    }

    /**
     * 从 NavigatorClipboard 中读取剪贴板数据
     * @returns {Promise<DataTransfer>}
     */
    public async readFromClipboard(): Promise<ClipboardItems> {
        if (typeof navigator.clipboard.read !== 'function') {
            console.warn(CLIPBOARD_ACCESS_DENIED)
            throw new Error(CLIPBOARD_ACCESS_DENIED)
        }
        const items = await navigator.clipboard.read()
        return items
    }
}
