export const isDebugEnabled = {
    value: false,
}

export function checkDebugEnabled() {
    if (isDebugEnabled.value) {
        // eslint-disable-next-line no-console
        console.debug('Debug Mode')
    }
}

export function debugLog(...args: Parameters<typeof console.debug>) {
    if (isDebugEnabled.value) {
        // eslint-disable-next-line no-console
        console.debug(...args)
    }
}

export function debugWarn(...args: Parameters<typeof console.warn>) {
    if (isDebugEnabled.value) {
        console.warn(...args)
    }
}

export function debugError(...args: Parameters<typeof console.error>) {
    if (isDebugEnabled.value) {
        console.error(...args)
    }
}
