import constate from 'constate'
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { WKToast } from '../../../../ui-lib/src'
import { useAsyncRetry } from '../../../../util/src'
import { DocWithAuthorityVO, FolderWithAuthorityVO, RoleStatus } from '../../kernel/interface/type'
import { GetDocRequest } from '../../kernel/request/document'
import { GetFolderRequest } from '../../kernel/request/folder'
import { GetTeamRequestV2 } from '../../kernel/request/team'
import { SessionStorageKey } from '../../web-storage/session-storage/config'
import { enhancedSessionStorage } from '../../web-storage/session-storage/storage'
import { createFileManager } from '../create-file-manager'
import { getDefaultGlobalConfigsWithStorage2 } from '../user-config/user-config'
import { translation } from './layout-context.translation'
import {
    DEFAULT_BOTTOM_BANNER_HEIGHT,
    DEFAULT_TOPBAR_HEIGHT,
    DEFAULT_TOP_BANNER_HEIGHT,
    DEFAULT_TOP_BANNER_MIDDLE_HEIGHT,
} from './layout-default-value'

function useAppLayout() {
    // 是否展示
    // [how_to_adjust_layout][0/n] 如四周需要展示更多面板，影响画布区空间，则先要声明一个 boolean 状态。这些状态在 wasm 启动之前就需要，所以不能用 viewState
    const [showTopbar, setTopbarShown] = useState(true)
    const [showTopBanner, setTopBannerShown] = useState(false)
    const [showLeftPanel, setLeftPanelShown] = useState(true)
    const [showRightPanel, setRightPanelShown] = useState(true)
    const [showBottomPanel, setBottomPanelShown] = useState(false)
    const [showDevModeAccessibleBanner, setDevModeAccessibleBannerShown] = useState(false)
    const [showFreezeBanner, setFreezeBannerShown] = useState(false)
    const [showExceedUsageLimitBanner, setExceedUsageLimitBannerShown] = useState(false)

    // 展示数值
    // [how_to_adjust_layout][1/n] 如除了是否展示，还需要数值变化，则还要声明一个数值状态。
    const [leftPanelWidth, updateLeftPanelWidth] = useState<number>(
        getDefaultGlobalConfigsWithStorage2().leftPanelWidth
    )
    const [rightPanelWidth, updateRightPanelWidth] = useState<number>(
        getDefaultGlobalConfigsWithStorage2().devModeRightPanelWidth
    )

    // 四周空间的 margin 计算属性
    // [how_to_adjust_layout][2/n] 是否展示以及数值状态会衍生计算得到四周空间的 margin 值，在这里添加 memo 逻辑
    const topMargin = useMemo(
        () =>
            (showTopbar ? DEFAULT_TOPBAR_HEIGHT : 0) +
            (showTopBanner ? DEFAULT_TOP_BANNER_HEIGHT : 0) +
            (showDevModeAccessibleBanner || showFreezeBanner || showExceedUsageLimitBanner
                ? DEFAULT_TOP_BANNER_MIDDLE_HEIGHT
                : 0),
        [showDevModeAccessibleBanner, showExceedUsageLimitBanner, showFreezeBanner, showTopBanner, showTopbar]
    )
    const leftMargin = useMemo(() => (showLeftPanel ? leftPanelWidth : 0), [leftPanelWidth, showLeftPanel])
    const rightMargin = useMemo(() => (showRightPanel ? rightPanelWidth : 0), [showRightPanel, rightPanelWidth])
    const bottomMargin = useMemo(() => (showBottomPanel ? DEFAULT_BOTTOM_BANNER_HEIGHT : 0), [showBottomPanel])

    useEffect(() => {
        const duplicateResource = enhancedSessionStorage.getItem(SessionStorageKey.DuplicateResource)
        if (duplicateResource) {
            WKToast.show(translation('DuplicateResource'))
            enhancedSessionStorage.removeItem(SessionStorageKey.DuplicateResource)
        }
    }, [])

    return {
        showTopbar,
        setTopbarShown,
        showTopBanner,
        setTopBannerShown,
        setDevModeAccessibleBannerShown,
        setFreezeBannerShown,
        setExceedUsageLimitBannerShown,
        showLeftPanel,
        setLeftPanelShown,
        showRightPanel,
        setRightPanelShown,
        showBottomPanel,
        setBottomPanelShown,
        leftPanelWidth,
        updateLeftPanelWidth,
        rightPanelWidth,
        updateRightPanelWidth,
        topMargin,
        leftMargin,
        rightMargin,
        bottomMargin,
    }
}

export const [
    AppLayoutContextProvider,
    useTopbarShown,
    useSetTopbarShown,
    useTopBannerShown,
    useSetTopBannerShown,
    useSetDevModeAccessibleBannerShown,
    useSetFreezeBannerShown,
    useSetExceedUsageLimitBannerShown,
    useLeftPanelShown,
    useSetLeftPanelShown,
    useRightPanelShown,
    useSetRightPanelShown,
    useBottomPanelShown,
    useSetBottomPanelShown,
    useLeftPanelWidth,
    useSetLeftPanelWidth,
    useRightPanelWidth,
    useSetRightPanelWidth,
    useTopMargin,
    useLeftMargin,
    useRightMargin,
    useBottomMargin,
] = constate(
    useAppLayout,
    (ctx) => ctx.showTopbar,
    (ctx) => ctx.setTopbarShown,
    (ctx) => ctx.showTopBanner,
    (ctx) => ctx.setTopBannerShown,
    (ctx) => ctx.setDevModeAccessibleBannerShown,
    (ctx) => ctx.setFreezeBannerShown,
    (ctx) => ctx.setExceedUsageLimitBannerShown,
    (ctx) => ctx.showLeftPanel,
    (ctx) => ctx.setLeftPanelShown,
    (ctx) => ctx.showRightPanel,
    (ctx) => ctx.setRightPanelShown,
    (ctx) => ctx.showBottomPanel,
    (ctx) => ctx.setBottomPanelShown,
    (ctx) => ctx.leftPanelWidth,
    (ctx) => ctx.updateLeftPanelWidth,
    (ctx) => ctx.rightPanelWidth,
    (ctx) => ctx.updateRightPanelWidth,
    (ctx) => ctx.topMargin,
    (ctx) => ctx.leftMargin,
    (ctx) => ctx.rightMargin,
    (ctx) => ctx.bottomMargin
)

export function AppLayoutWrapper(props: PropsWithChildren) {
    const topMargin = useTopMargin()
    const leftMargin = useLeftMargin()
    const rightMargin = useRightMargin()
    const bottomMargin = useBottomMargin()
    return (
        <div
            style={{
                pointerEvents: 'none',
                position: 'absolute',
                width: 'auto',
                height: 'auto',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: topMargin,
                    right: rightMargin,
                    bottom: bottomMargin,
                    left: leftMargin,
                    display: 'block',
                    width: 'auto',
                    height: 'auto',
                }}
                data-top={topMargin.toString()}
                data-right={rightMargin.toString()}
                data-bottom={bottomMargin.toString()}
                data-left={leftMargin.toString()}
            >
                <div
                    style={{
                        pointerEvents: 'all',
                        position: 'absolute',
                        width: 'auto',
                        height: 'auto',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        overflow: 'hidden',
                    }}
                >
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export const [DocFolderTeamDataProvider, useDocFolderTeamData] = constate(useDocData, (ctx) => ({
    docData: ctx.docData,
    fetchDocData: ctx.fetchDocData,
    setDocData: ctx.setDocData,
    folderData: ctx.folderData,
    setFolderData: ctx.setFolderData,
    teamData: ctx.teamData,
    fetchTeamData: ctx.fetchTeamData,
}))

function useDocData() {
    const params = useParams()
    const docId = params.docId || ''

    const [docData, setDocData] = useState<DocWithAuthorityVO>()
    const [folderData, setFolderData] = useState<FolderWithAuthorityVO>()

    const fetchDocData = useCallback(async () => {
        // 极速新建文档时，需要 mock data 数据
        if (createFileManager.isCreatingFile()) {
            const mockDoc = createFileManager.createMockDoc()
            setDocData(mockDoc)
            return
        }
        if (docId) {
            const data = await new GetDocRequest(docId).start()
            setDocData(data)
        }
    }, [docId])

    useEffect(() => {
        if (createFileManager.isCreatingFile()) {
            createFileManager.injectCreateFileCallBack(fetchDocData)
        }
        fetchDocData()
    }, [fetchDocData])

    useEffect(() => {
        if (docData?.folderId && docData?.folderRole !== RoleStatus.None) {
            new GetFolderRequest(docData.folderId)
                .start()
                .then(setFolderData)
                .catch(() => {})
        }
    }, [docData?.folderId, docData?.folderRole])

    const { value: teamData, retry: fetchTeamData } = useAsyncRetry(
        () =>
            docData?.teamId
                ? new GetTeamRequestV2(docData.teamId).start().catch(() => undefined)
                : Promise.resolve(undefined),
        [docData?.teamId]
    )

    return {
        docData,
        fetchDocData,
        setDocData,
        folderData,
        setFolderData,
        teamData,
        fetchTeamData,
    }
}
