import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    LibrariesUsedIn: '此文件使用组件库',
    Includes: '包含',
    MissingLibrary: '个缺失组件库',
} as const

export const enTranslation = {
    LibrariesUsedIn: 'Libraries used in this file',
    Includes: 'Includes',
    MissingLibrary: 'missing {{library}}',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
