import classnames from 'classnames'
import { useMemo } from 'react'
import { BadgeBase, BadgeBaseProps } from './badge-base'
import classes from './badge-text.module.less'

export interface BadgeTextProps extends BadgeBaseProps {
    color?: 'gray' | 'black' | 'blue' | 'green' | 'red' // 设计定义的 gray 是一个特别的存在
    colorRevert?: boolean // false: color 应用到文字上； true: color 应用到背景
}

export function BadgeText(props: BadgeTextProps) {
    const { color = 'blue', colorRevert, className, ...otherProps } = props

    const badgeClassName = useMemo(() => {
        let colorClassName: string
        if (colorRevert && ['blue', 'green', 'red'].includes(color)) {
            colorClassName = classes[`${color}-revert` as keyof typeof classes]
        } else {
            colorClassName = classes[color]
        }
        return classnames(classes.badgeText, className, colorClassName)
    }, [className, color, colorRevert])

    return (
        <BadgeBase className={badgeClassName} {...otherProps}>
            {props.children}
        </BadgeBase>
    )
}
