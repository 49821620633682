import { SVGAttributes, useMemo, forwardRef } from 'react'
export const MulticolorIconCommonFigmaFile24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 4.5 3)"
                d="M0 1C0 0.447715 0.447715 0 1 0L10.0858 0C10.351 0 10.6054 0.105357 10.7929 0.292893L14.7071 4.20711C14.8946 4.39464 15 4.649 15 4.91421L15 17C15 17.5523 14.5523 18 14 18L1 18C0.447715 18 0 17.5523 0 17L0 1Z"
                fillRule="nonzero"
                fill="rgb(230, 232, 234)"
            />
            <defs>
                <mask id={ids[0]} style={{ maskType: 'alpha' }}>
                    <path
                        transform="matrix(1 0 0 1 4.5 3)"
                        d="M0 1C0 0.447715 0.447715 0 1 0L10.0858 0C10.351 0 10.6054 0.105357 10.7929 0.292893L14.7071 4.20711C14.8946 4.39464 15 4.649 15 4.91421L15 17C15 17.5523 14.5523 18 14 18L1 18C0.447715 18 0 17.5523 0 17L0 1Z"
                        fillRule="nonzero"
                        fill="rgb(0, 0, 0)"
                    />
                </mask>
            </defs>
            <g mask={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 13 3)"
                    d="M2 1C2 0.447715 1.55228 0 1 0L0 0L6.5 0L6.5 7L6.5 5.5C6.5 4.94772 6.05229 4.5 5.5 4.5L3 4.5C2.44772 4.5 2 4.05229 2 3.5L2 1Z"
                    fillRule="nonzero"
                    fill="rgb(218, 220, 224)"
                />
            </g>
            <path
                transform="matrix(1 0 0 1 12 11)"
                d="M0 2C0 0.897196 0.897196 0 2 0C3.1028 0 4 0.897196 4 2C4 3.1028 3.1028 4 2 4C0.897196 4 0 3.1028 0 2Z"
                fillRule="nonzero"
                fill="rgb(26, 188, 254)"
            />
            <path
                transform="matrix(1 0 0 1 8 15)"
                d="M0 2C0 0.897196 0.901408 0 2.00939 0L4 0L4 2C4 3.1028 3.09859 4 1.99061 4C0.882629 4 0 3.1028 0 2L0 2Z"
                fillRule="nonzero"
                fill="rgb(10, 207, 131)"
            />
            <path
                transform="matrix(1 0 0 1 12 7)"
                d="M0 0L0 4L2 4C3.1028 4 4 3.09859 4 1.99061C4 0.88263 3.1028 0 2 0L0 0Z"
                fillRule="nonzero"
                fill="rgb(255, 114, 98)"
            />
            <path
                transform="matrix(1 0 0 1 8 7)"
                d="M0 2C0 3.1028 0.901408 4 2.00939 4L4 4L4 0L1.99061 0C0.882629 0 0 0.897196 0 2L0 2Z"
                fillRule="nonzero"
                fill="rgb(242, 78, 30)"
            />
            <path
                transform="matrix(1 0 0 1 8 11)"
                d="M0 2C0 3.1028 0.901408 4 2.00939 4L4 4L4 0L1.99061 0C0.882629 0 0 0.897196 0 2L0 2Z"
                fillRule="nonzero"
                fill="rgb(162, 89, 255)"
            />
        </svg>
    )
})
export const MulticolorIconCommonComponentsFile24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 4.5 3)"
                d="M0 1C0 0.447715 0.447715 0 1 0L10.1893 0C10.3882 0 10.579 0.079018 10.7197 0.21967L14.7803 4.28033C14.921 4.42098 15 4.61175 15 4.81066L15 17C15 17.5523 14.5523 18 14 18L1 18C0.447715 18 0 17.5523 0 17L0 1Z"
                fillRule="nonzero"
                fill="rgb(204, 95, 255)"
            />
            <defs>
                <mask id={ids[0]} style={{ maskType: 'alpha' }}>
                    <path
                        transform="matrix(1 0 0 1 4.5 3)"
                        d="M0 1C0 0.447715 0.447715 0 1 0L10.1893 0C10.3882 0 10.579 0.079018 10.7197 0.21967L14.7803 4.28033C14.921 4.42098 15 4.61175 15 4.81066L15 17C15 17.5523 14.5523 18 14 18L1 18C0.447715 18 0 17.5523 0 17L0 1Z"
                        fillRule="nonzero"
                        fill="rgb(0, 0, 0)"
                    />
                </mask>
            </defs>
            <g mask={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 12.75 3)"
                    d="M0 0L1.42661 0L0 0ZM6.72693 0L1.42661 0L1.5 0C1.91421 0 2.25 0.335786 2.25 0.75L2.25 3.75C2.25 4.16421 2.58579 4.5 3 4.5L6.00006 4.5C6.38378 4.5 6.7002 4.78817 6.7447 5.15991L6.72693 0ZM1.42661 0L0 0L1.42661 0ZM6.75018 6.75018L6.75006 5.24994C6.75006 5.21947 6.74824 5.18942 6.7447 5.15991L6.75018 6.75018Z"
                    fillRule="nonzero"
                    fill="rgb(255, 255, 255)"
                    opacity="0.3"
                />
                <path
                    transform="matrix(1 0 0 1 6.75 9.01025)"
                    d="M9.14261 0.261136C8.66459 0.0469187 8.14216 -0.0355022 7.57534 0.0138733C7.05971 0.0587887 6.5648 0.206053 6.09059 0.455667C5.76612 0.626464 5.48457 0.827444 5.24595 1.05861C5.00771 0.827488 4.72699 0.626585 4.40377 0.4559C3.93083 0.206147 3.43729 0.0588082 2.92316 0.0138821C2.35791 -0.0355106 1.83647 0.0469275 1.35883 0.261196C0.82402 0.501115 0.390072 0.88758 0.0569878 1.42059L0 1.61932L0 8.67107L0.644929 8.93139C0.789085 8.78191 0.979176 8.64814 1.2152 8.5301C1.5077 8.38381 1.82571 8.28812 2.16924 8.24303C3.085 8.12282 4.04498 8.37193 5.04918 8.99038L5.44247 8.99038C6.44676 8.37188 7.40818 8.12277 8.32671 8.24304C8.67138 8.28817 8.99043 8.38392 9.28385 8.53028C9.52073 8.64843 9.71114 8.78214 9.85507 8.93139L10.5 8.67107L10.5 1.61932L10.4435 1.42139C10.112 0.887988 9.6784 0.501237 9.14261 0.261136ZM8.42408 7.49939C7.51086 7.37981 6.57644 7.5578 5.62083 8.03336L5.62083 1.7529C5.83436 1.50557 6.1074 1.29438 6.43994 1.11934C6.825 0.916648 7.22516 0.797217 7.64042 0.761044C8.07788 0.722937 8.47638 0.784441 8.83591 0.945555C9.20305 1.11008 9.50774 1.37159 9.75 1.73008L9.75 7.92804C9.70733 7.90451 9.66354 7.88154 9.61862 7.85914C9.25013 7.67533 8.85195 7.55541 8.42408 7.49939ZM4.87083 1.75286C4.65726 1.50531 4.38483 1.29405 4.05354 1.1191C3.66993 0.916524 3.27137 0.797167 2.85787 0.761035C2.42216 0.722962 2.02481 0.784448 1.66581 0.945495C1.29895 1.11007 0.993679 1.37176 0.75 1.73057L0.75 7.92749C0.792139 7.90421 0.835379 7.88149 0.879718 7.85931C1.24742 7.67541 1.64472 7.55544 2.07163 7.4994C2.98256 7.37983 3.91563 7.5578 4.87083 8.0333L4.87083 1.75286Z"
                    fillRule="evenodd"
                    fill="rgb(255, 255, 255)"
                />
            </g>
        </svg>
    )
})
export const MulticolorIconCommonFont24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 4.5 3)"
                d="M0 1C0 0.447715 0.447715 0 1 0L10.0858 0C10.351 0 10.6054 0.105357 10.7929 0.292893L14.7071 4.20711C14.8946 4.39464 15 4.649 15 4.91421L15 17C15 17.5523 14.5523 18 14 18L1 18C0.447715 18 0 17.5523 0 17L0 1Z"
                fillRule="nonzero"
                fill="rgb(230, 232, 234)"
            />
            <defs>
                <mask id={ids[0]} style={{ maskType: 'alpha' }}>
                    <path
                        transform="matrix(1 0 0 1 4.5 3)"
                        d="M0 1C0 0.447715 0.447715 0 1 0L10.0858 0C10.351 0 10.6054 0.105357 10.7929 0.292893L14.7071 4.20711C14.8946 4.39464 15 4.649 15 4.91421L15 17C15 17.5523 14.5523 18 14 18L1 18C0.447715 18 0 17.5523 0 17L0 1Z"
                        fillRule="nonzero"
                        fill="rgb(0, 0, 0)"
                    />
                </mask>
            </defs>
            <g mask={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 13 3)"
                    d="M2 1C2 0.447715 1.55228 0 1 0L0 0L6.5 0L6.5 7L6.5 5.5C6.5 4.94772 6.05229 4.5 5.5 4.5L3 4.5C2.44772 4.5 2 4.05229 2 3.5L2 1Z"
                    fillRule="nonzero"
                    fill="rgb(218, 220, 224)"
                />
            </g>
            <path
                transform="matrix(1 0 0 1 6.75 3.75)"
                d="M4.55123 4.50183L1.68526 12L2.99776 12L3.67965 10.121L6.81991 10.121L7.50179 12L8.81429 12L5.94833 4.50183L4.55123 4.50183ZM4.04879 9.11353L5.23312 5.82715L5.2767 5.82715L6.45333 9.11353L4.04879 9.11353Z"
                fillRule="nonzero"
                fill="rgb(130, 138, 153)"
            />
        </svg>
    )
})
export const MulticolorIconCommonFileNew24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 4.5 2.99951)"
                d="M0 1C0 0.447715 0.447715 0 1 0L9.65483 0C9.87584 0 10.0878 0.0877973 10.2441 0.244078L14.7559 4.75592C14.9122 4.9122 15 5.12417 15 5.34518L15 17C15 17.5523 14.5523 18 14 18L1 18C0.447715 18 0 17.5523 0 17L0 1Z"
                fillRule="nonzero"
                fill="rgb(48, 113, 255)"
            />
            <defs>
                <mask id={ids[0]} style={{ maskType: 'alpha' }}>
                    <path
                        transform="matrix(1 0 0 1 4.5 2.99951)"
                        d="M0 1C0 0.447715 0.447715 0 1 0L9.65483 0C9.87584 0 10.0878 0.0877973 10.2441 0.244078L14.7559 4.75592C14.9122 4.9122 15 5.12417 15 5.34518L15 17C15 17.5523 14.5523 18 14 18L1 18C0.447715 18 0 17.5523 0 17L0 1Z"
                        fillRule="nonzero"
                        fill="rgb(0, 0, 0)"
                    />
                </mask>
            </defs>
            <g mask={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 13.9204 2.99951)"
                    d="M0.580302 1.67793L0.5802 0.580117C0.5802 0.259727 0.320473 0 8.27312e-05 0L0.206134 0C0.445707 0 0.675467 0.0951698 0.84487 0.264573L5.31572 4.73542C5.48512 4.90482 5.58029 5.13459 5.58029 5.37417L5.58008 5.58C5.58008 5.25961 5.32035 4.99988 4.99996 4.99988L2.14662 4.99994C1.28157 4.99994 0.580302 4.29867 0.580302 3.43362L0.580302 1.67793Z"
                    fillRule="evenodd"
                    fill="rgb(118, 176, 255)"
                    fillOpacity="0.6"
                />
                <path
                    transform="matrix(1 0 0 1 7.875 8.25)"
                    d="M5.15062 4.2125C4.82104 3.81972 4.30777 3.67743 3.86385 3.81367L0.663865 8.18522e-05C2.2383 0.419287 5.92561 1.50438 6.93979 2.71304C7.81134 3.75172 8.03353 5.11069 7.63988 6.26789L8.51942 7.31609C8.6414 7.46146 8.62244 7.67818 8.47707 7.80016L6.32406 9.60675C6.1787 9.72872 5.96197 9.70976 5.83999 9.56439L4.96057 8.51634C3.75249 8.70321 2.45258 8.24843 1.58094 7.20965C0.569102 6.00379 0.140424 2.19893 -2.72333e-05 0.569265L3.19436 4.37618C2.98386 4.78951 3.0351 5.319 3.36434 5.71137C3.79784 6.228 4.54914 6.31127 5.0424 5.89737C5.53567 5.48347 5.58412 4.72913 5.15062 4.2125Z"
                    fillRule="evenodd"
                    fill="rgb(255, 255, 255)"
                />
            </g>
        </svg>
    )
})
export const MulticolorIconCommonFolder24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 2 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 5 6)"
                d="M1.2 0C0.537258 0 0 0.537258 0 1.2L0 10.8C0 11.4627 0.537258 12 1.2 12L12.8 12C13.4627 12 14 11.4627 14 10.8L14 2.98738C14 2.32463 13.4627 1.78738 12.8 1.78738L6.82551 1.78738C6.45566 1.78738 6.10647 1.61683 5.87908 1.32513L5.20645 0.462248C4.97907 0.170549 4.62988 0 4.26003 0L1.2 0Z"
                fillRule="nonzero"
                fill="rgb(89, 147, 255)"
            />
            <defs>
                <mask id={ids[0]} style={{ maskType: 'alpha' }}>
                    <path
                        transform="matrix(1 0 0 1 5 6)"
                        d="M1.2 0C0.537258 0 0 0.537258 0 1.2L0 10.8C0 11.4627 0.537258 12 1.2 12L12.8 12C13.4627 12 14 11.4627 14 10.8L14 2.98738C14 2.32463 13.4627 1.78738 12.8 1.78738L6.82551 1.78738C6.45566 1.78738 6.10647 1.61683 5.87908 1.32513L5.20645 0.462248C4.97907 0.170549 4.62988 0 4.26003 0L1.2 0Z"
                        fillRule="nonzero"
                        fill="rgb(0, 0, 0)"
                    />
                </mask>
            </defs>
            <g mask={`url(#${ids[0]})`} />
            <path
                transform="matrix(1 0 0 1 5 10)"
                d="M0 1.2C0 0.537258 0.537258 0 1.2 0L12.8 0C13.4627 0 14 0.537258 14 1.2L14 6.8C14 7.46274 13.4627 8 12.8 8L1.2 8C0.537258 8 0 7.46274 0 6.8L0 1.2Z"
                fillRule="nonzero"
                fill="rgb(120, 172, 255)"
            />
            <defs>
                <mask id={ids[1]} style={{ maskType: 'alpha' }}>
                    <path
                        transform="matrix(1 0 0 1 5 10)"
                        d="M0 1.2C0 0.537258 0.537258 0 1.2 0L12.8 0C13.4627 0 14 0.537258 14 1.2L14 6.8C14 7.46274 13.4627 8 12.8 8L1.2 8C0.537258 8 0 7.46274 0 6.8L0 1.2Z"
                        fillRule="nonzero"
                        fill="rgb(0, 0, 0)"
                    />
                </mask>
            </defs>
            <g mask={`url(#${ids[1]})`} />
        </svg>
    )
})
export const MulticolorIconCommonFolderBg24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 2 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 0 9.53674e-07)"
                d="M2 0C0.895431 0 0 0.895431 0 2L0 22C0 23.1046 0.895431 24 2 24L22 24C23.1046 24 24 23.1046 24 22L24 2C24 0.895431 23.1046 0 22 0L2 0Z"
                fillRule="nonzero"
                fill="rgb(224, 239, 255)"
            />
            <path
                transform="matrix(1 0 0 1 4.99997 6.00008)"
                d="M1.2 0C0.537258 0 0 0.537258 0 1.2L0 10.8C0 11.4627 0.537258 12 1.2 12L12.8 12C13.4627 12 14 11.4627 14 10.8L14 2.98738C14 2.32463 13.4627 1.78738 12.8 1.78738L6.82551 1.78738C6.45566 1.78738 6.10647 1.61683 5.87908 1.32513L5.20645 0.462248C4.97907 0.170549 4.62988 0 4.26003 0L1.2 0Z"
                fillRule="nonzero"
                fill="rgb(89, 147, 255)"
            />
            <defs>
                <mask id={ids[0]} style={{ maskType: 'alpha' }}>
                    <path
                        transform="matrix(1 0 0 1 4.99997 6.00008)"
                        d="M1.2 0C0.537258 0 0 0.537258 0 1.2L0 10.8C0 11.4627 0.537258 12 1.2 12L12.8 12C13.4627 12 14 11.4627 14 10.8L14 2.98738C14 2.32463 13.4627 1.78738 12.8 1.78738L6.82551 1.78738C6.45566 1.78738 6.10647 1.61683 5.87908 1.32513L5.20645 0.462248C4.97907 0.170549 4.62988 0 4.26003 0L1.2 0Z"
                        fillRule="nonzero"
                        fill="rgb(0, 0, 0)"
                    />
                </mask>
            </defs>
            <g mask={`url(#${ids[0]})`} />
            <path
                transform="matrix(1 0 0 1 4.99997 10.0001)"
                d="M0 1.2C0 0.537258 0.537258 0 1.2 0L12.8 0C13.4627 0 14 0.537258 14 1.2L14 6.8C14 7.46274 13.4627 8 12.8 8L1.2 8C0.537258 8 0 7.46274 0 6.8L0 1.2Z"
                fillRule="nonzero"
                fill="rgb(120, 172, 255)"
            />
            <defs>
                <mask id={ids[1]} style={{ maskType: 'alpha' }}>
                    <path
                        transform="matrix(1 0 0 1 4.99997 10.0001)"
                        d="M0 1.2C0 0.537258 0.537258 0 1.2 0L12.8 0C13.4627 0 14 0.537258 14 1.2L14 6.8C14 7.46274 13.4627 8 12.8 8L1.2 8C0.537258 8 0 7.46274 0 6.8L0 1.2Z"
                        fillRule="nonzero"
                        fill="rgb(0, 0, 0)"
                    />
                </mask>
            </defs>
            <g mask={`url(#${ids[1]})`} />
        </svg>
    )
})
export const MulticolorIconCommonOtherTeams24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 2 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="24" height="24.0005" viewBox="0 0 24 24.0005" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 0 0)"
                d="M2 0C0.895431 0 0 0.895431 0 2L0 22C0 23.1046 0.89543 24 2 24L22 24C23.1046 24 24 23.1046 24 22L24 2C24 0.895431 23.1046 0 22 0L2 0Z"
                fillRule="nonzero"
                fill="rgb(224, 239, 255)"
            />
            <defs>
                <clipPath id={ids[0]}>
                    <path
                        transform="matrix(1 0 0 1 0 0.000488281)"
                        d="M1.66667 0C0.746192 0 0 0.746192 0 1.66667L0 22.3333C0 23.2538 0.746192 24 1.66667 24L22.3333 24C23.2538 24 24 23.2538 24 22.3333L24 1.66667C24 0.746192 23.2538 0 22.3333 0L1.66667 0Z"
                        fillRule="nonzero"
                    />
                </clipPath>
            </defs>
            <g clipPath={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 5 4.00049)"
                    d="M0 3.8595C0 3.53808 0.184855 3.24531 0.475055 3.10712L6.64172 0.170608C6.86838 0.0626757 7.13162 0.0626757 7.35828 0.170608L13.5249 3.10712C13.8151 3.24531 14 3.53808 14 3.8595L14 11.1729C14 11.4774 13.834 11.7576 13.5669 11.9038L7.40026 15.2808C7.15089 15.4174 6.84911 15.4174 6.59974 15.2808L0.433071 11.9038C0.166034 11.7576 0 11.4774 0 11.1729L0 3.8595Z"
                    fillRule="nonzero"
                    fill="rgb(120, 172, 255)"
                />
                <defs>
                    <mask id={ids[1]} style={{ maskType: 'alpha' }}>
                        <path
                            transform="matrix(1 0 0 1 5 4.00049)"
                            d="M0 3.8595C0 3.53808 0.184855 3.24531 0.475055 3.10712L6.64172 0.170608C6.86838 0.0626757 7.13162 0.0626757 7.35828 0.170608L13.5249 3.10712C13.8151 3.24531 14 3.53808 14 3.8595L14 11.1729C14 11.4774 13.834 11.7576 13.5669 11.9038L7.40026 15.2808C7.15089 15.4174 6.84911 15.4174 6.59974 15.2808L0.433071 11.9038C0.166034 11.7576 0 11.4774 0 11.1729L0 3.8595Z"
                            fillRule="nonzero"
                            fill="rgb(0, 0, 0)"
                        />
                    </mask>
                </defs>
                <g mask={`url(#${ids[1]})`}>
                    <path
                        transform="matrix(1 0 0 1 3.5 7.00049)"
                        d="M8.50029 3.67945L0.223102 -0.447466C-0.024027 -0.570681 -0.32425 -0.47023 -0.447466 -0.223102C-0.570681 0.024027 -0.47023 0.32425 -0.223102 0.447466L8.0003 4.54756L8.00014 13.0003C8.00013 13.2765 8.22399 13.5003 8.50013 13.5003C8.77627 13.5003 9.00013 13.2765 9.00014 13.0003L9.0003 4.54756L17.2231 0.44746C17.4702 0.324237 17.5707 0.0240111 17.4475 -0.223114C17.3242 -0.470239 17.024 -0.570682 16.7769 -0.44746L8.50029 3.67945Z"
                        fillRule="evenodd"
                        fill="rgb(224, 239, 255)"
                    />
                </g>
            </g>
        </svg>
    )
})
export const MulticolorIconCommonLogo24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 0 0)"
                d="M0 0L24 0L24 24L0 24L0 0Z"
                fillRule="nonzero"
                fill="rgb(255, 255, 255)"
            />
            <defs>
                <clipPath id={ids[0]}>
                    <path
                        transform="matrix(1 0 0 1 1.19998 4.5)"
                        d="M0 0L21.7095 0L21.7095 13.5L0 13.5L0 0Z"
                        fillRule="nonzero"
                    />
                </clipPath>
            </defs>
            <g clipPath={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 19.1959 11.8833)"
                    d="M3.37361 5.90851L1.24169 0L0 2.21915L2.0793 6.02128C2.11298 6.08085 2.17401 6.11915 2.24135 6.11915L3.22839 6.11915C3.33573 6.11702 3.40939 6.01064 3.37361 5.90851Z"
                    fillRule="nonzero"
                    fill="rgb(228, 62, 42)"
                />
                <path
                    transform="matrix(1 0 0 1 12.0499 9.60742)"
                    d="M4.81623 0.0290967C4.72363 -0.0283502 4.60157 0.00143731 4.54685 0.0993095L2.98316 2.90995L0.0115214 8.25463C-0.0221516 8.31633 0.022044 8.39293 0.0914949 8.39293L4.90042 8.39293C4.92357 8.38016 4.9404 8.36952 4.96355 8.35676L7.14598 4.49506L8.38767 2.27591L4.81623 0.0290967Z"
                    fillRule="nonzero"
                    fill="rgb(240, 181, 0)"
                />
                <path
                    transform="matrix(1 0 0 1 8.66175 4.52042)"
                    d="M4.48143 2.75225L0.154455 0.0160764C0.0786904 -0.0328598 -0.0181191 0.037353 0.00292614 0.126715L3.28604 13.331C3.30709 13.4182 3.38495 13.4799 3.47545 13.4799L8.29279 13.4799C8.33698 13.4799 8.36855 13.4352 8.35382 13.3927L4.48143 2.75225Z"
                    fillRule="nonzero"
                    fill="rgb(0, 198, 45)"
                />
                <path
                    transform="matrix(1 -4.37114e-08 4.37114e-08 1 1.19998 4.5)"
                    d="M7.28159 0.12374L0.0313821 13.1344C-0.059114 13.2982 0.0566368 13.5003 0.243943 13.5003L5.8084 13.5003C5.89679 13.5003 5.97887 13.4514 6.02307 13.3727L9.29566 7.51097L11.9432 2.77055L7.61832 0.0365057C7.50257 -0.0358347 7.34894 0.00246313 7.28159 0.12374Z"
                    fillRule="nonzero"
                    fill="rgb(0, 91, 255)"
                />
            </g>
        </svg>
    )
})
export const MulticolorIconToolbarAIseacted24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
            <defs>
                <linearGradient
                    id={ids[0]}
                    gradientTransform="matrix(21.6954 25.0661 -29.7079 35.5821 14.8539 -25.8006)"
                    gradientUnits="userSpaceOnUse"
                    x1="0"
                    y1="0.5"
                    x2="1"
                    y2="0.5"
                >
                    <stop offset="0" stopColor="rgb(14, 238, 255)" />
                    <stop offset="0.49537182" stopColor="rgb(97, 61, 255)" />
                    <stop offset="1" stopColor="rgb(250, 33, 255)" />
                </linearGradient>
            </defs>
            <path
                transform="matrix(1 0 0 1 4 5)"
                d="M2.01223 13.5C2.09752 13.5 2.17343 13.4459 2.20127 13.3653L3.38797 9.929L8.64609 9.929L9.82837 13.3651C9.85615 13.4458 9.93211 13.5 10.0175 13.5L11.7453 13.5C11.8837 13.5 11.9803 13.3628 11.9336 13.2325L7.23453 0.132472C7.20602 0.0530084 7.13069 0 7.04627 0L4.98327 0C4.89885 0 4.82352 0.0530082 4.79502 0.132472L0.0959641 13.2325C0.0492266 13.3628 0.145795 13.5 0.284219 13.5L2.01223 13.5ZM3.97973 8.21148L5.96734 2.45166L6.07124 2.45166L8.05433 8.21148L3.97973 8.21148ZM16 0.2C16 0.0895431 15.9105 0 15.8 0L14.1672 0C14.0568 0 13.9672 0.0895431 13.9672 0.2L13.9672 13.3C13.9672 13.4105 14.0568 13.5 14.1672 13.5L15.8 13.5C15.9105 13.5 16 13.4105 16 13.3L16 0.2Z"
                fillRule="nonzero"
                fill={`url(#${ids[0]})`}
            />
        </svg>
    )
})
export const MulticolorIconCommonPresent24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 0 0)"
                d="M12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0Z"
                fillRule="nonzero"
                fill="rgb(238, 239, 240)"
            />
            <path
                transform="matrix(0 1 -1 0 18 6)"
                d="M5.56699 0.75L5.56699 0.75Q5.58337 0.721619 5.60332 0.695619Q5.62327 0.66962 5.64645 0.646446Q5.66962 0.623273 5.69562 0.603323Q5.72162 0.583373 5.75 0.566987Q5.77838 0.550601 5.80866 0.53806Q5.83894 0.525519 5.87059 0.517037Q5.90225 0.508555 5.93474 0.504277Q5.96723 0.5 6 0.5Q6.03277 0.5 6.06526 0.504278Q6.09775 0.508555 6.12941 0.517037Q6.16107 0.525519 6.19134 0.53806Q6.22162 0.550601 6.25 0.566987Q6.27838 0.583373 6.30438 0.603323Q6.33038 0.623273 6.35355 0.646447Q6.37673 0.66962 6.39668 0.695619Q6.41663 0.721619 6.43301 0.75L10.7631 8.25L10.7631 8.25Q10.7795 8.27838 10.7921 8.30866Q10.8046 8.33894 10.8131 8.37059Q10.8216 8.40225 10.8258 8.43474Q10.8301 8.46723 10.8301 8.5Q10.8301 8.53277 10.8258 8.56526Q10.8216 8.59775 10.8131 8.62941Q10.8046 8.66106 10.7921 8.69134Q10.7795 8.72162 10.7631 8.75Q10.7468 8.77838 10.7268 8.80438Q10.7069 8.83038 10.6837 8.85355Q10.6605 8.87673 10.6345 8.89668Q10.6085 8.91663 10.5801 8.93301Q10.5517 8.9494 10.5215 8.96194Q10.4912 8.97448 10.4595 8.98296Q10.4279 8.99145 10.3954 8.99572Q10.3629 9 10.3301 9L1.66987 9L1.66987 9Q1.6371 9 1.60461 8.99572Q1.57212 8.99145 1.54046 8.98296Q1.50881 8.97448 1.47853 8.96194Q1.44825 8.9494 1.41987 8.93301Q1.39149 8.91663 1.36549 8.89668Q1.33949 8.87673 1.31632 8.85355Q1.29315 8.83038 1.2732 8.80438Q1.25325 8.77838 1.23686 8.75Q1.22047 8.72162 1.20793 8.69134Q1.19539 8.66106 1.18691 8.62941Q1.17843 8.59775 1.17415 8.56526Q1.16987 8.53277 1.16987 8.5Q1.16987 8.46723 1.17415 8.43474Q1.17843 8.40225 1.18691 8.37059Q1.19539 8.33894 1.20793 8.30866Q1.22047 8.27838 1.23686 8.25L5.56699 0.75Z"
                fillRule="nonzero"
                fill="rgb(26, 29, 37)"
            />
        </svg>
    )
})
export const MulticolorIconCommonOnlineBanking24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
            <defs>
                <clipPath id={ids[0]}>
                    <path transform="matrix(1 0 0 1 0 0)" d="M0 0L24 0L24 24L0 24L0 0Z" fillRule="nonzero" />
                </clipPath>
            </defs>
            <g clipPath={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 1 3.5)"
                    d="M22 16C22 16.5523 21.5523 17 21 17L1.00331 17C0.451025 17 0.00330997 16.5523 0.00330997 16L0.00330997 6.49999L22 6.49999L22 16ZM3.84926 10.4456L3.29984 12.6433L4.39868 12.6433L4.9481 10.4456L3.84926 10.4456ZM6.04694 10.4456L5.49752 12.6466L6.59636 12.6466L7.14578 10.4489L6.04694 10.4489L6.04694 10.4456ZM8.24793 10.4456L7.69851 12.6433L8.79735 12.6433L9.34677 10.4456L8.24793 10.4456ZM0 1C0 0.447715 0.447715 2.66939e-09 1 5.96226e-09L20.9934 1.25168e-07C21.5457 1.28461e-07 21.9934 0.447715 21.9934 1L21.9934 5.00003L0 5.00003L0 1Z"
                    fillRule="nonzero"
                    fill="rgb(48, 113, 255)"
                />
            </g>
        </svg>
    )
})
export const MulticolorIconCommonGoogle24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="24" height="23.998" viewBox="0 0 24 23.998" ref={ref} {...props}>
            <defs>
                <clipPath id={ids[0]}>
                    <path transform="matrix(1 0 0 1 3 2.99805)" d="M0 0L18 0L18 18L0 18L0 0Z" fillRule="nonzero" />
                </clipPath>
            </defs>
            <g clipPath={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 3.00002 8.04569)"
                    d="M3.7476 4.01356C3.7476 3.42496 3.8466 2.86065 4.0221 2.33146L0.939596 0C0.62957 0.623471 0.3952 1.27419 0.236485 1.95216C0.0777709 2.63013 -0.00105397 3.31726 1.06403e-05 4.01356C1.06403e-05 5.45581 0.337511 6.81615 0.937803 8.02396L4.01941 5.68846C3.83941 5.14846 3.74805 4.58279 3.7476 4.01356Z"
                    fillRule="nonzero"
                    fill="rgb(251, 188, 5)"
                />
                <path
                    transform="matrix(1 0 0 1 3.93961 2.9994)"
                    d="M8.22285 3.70665C8.83692 3.70542 9.4334 3.80722 10.0123 4.01205C10.5912 4.21689 11.119 4.51288 11.5956 4.90004L14.261 2.26485C12.6369 0.865354 10.5547 0 8.22287 0C4.60216 0 1.48996 2.05065 0 5.04629L3.08475 7.37777C3.79485 5.24206 5.81985 3.70622 8.22375 3.70622"
                    fillRule="nonzero"
                    fill="rgb(234, 67, 53)"
                />
                <path
                    transform="matrix(1 0 0 1 3.93736 13.6446)"
                    d="M8.26427 3.6486C5.84775 3.6486 3.81285 2.12265 3.09825 0L0 2.3175C1.49716 5.29471 4.62466 7.33185 8.26425 7.33185C10.5093 7.33185 12.6531 6.55156 14.2627 5.08816L11.3211 2.86154C10.4918 3.37321 9.4464 3.64904 8.26335 3.64904"
                    fillRule="nonzero"
                    fill="rgb(52, 168, 83)"
                />
                <path
                    transform="matrix(1 0 0 1 12.2007 10.3367)"
                    d="M8.7894 1.6371C8.7894 1.10519 8.70525 0.531897 8.58015 0L0 0L0 3.4785L4.93831 3.4785C4.69215 4.66469 4.02031 5.5764 3.05821 6.1695L5.9994 8.3961C7.69006 6.86025 8.7894 4.57198 8.7894 1.6371Z"
                    fillRule="nonzero"
                    fill="rgb(66, 133, 244)"
                />
            </g>
        </svg>
    )
})
export const MulticolorIconCommonTeamMemberBg24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 0 0)"
                d="M2 0C0.895431 0 0 0.895431 0 2L0 22C0 23.1046 0.89543 24 2 24L22 24C23.1046 24 24 23.1046 24 22L24 2C24 0.895431 23.1046 0 22 0L2 0Z"
                fillRule="nonzero"
                fill="rgb(224, 239, 255)"
            />
            <path
                transform="matrix(1 0 0 1 12.6541 6)"
                d="M3.34591 0C5.00276 0 6.34591 1.34315 6.34591 3C6.34591 4.65685 5.00276 6 3.34591 6C1.68905 6 0.345907 4.65685 0.345907 3C0.345907 1.34315 1.68905 0 3.34591 0ZM2.34601 13L8.34591 13L8.34591 11C8.34591 8.79086 6.55505 7 4.34591 7L2.34591 7C1.46955 7 0.659022 7.28182 0 7.75978C1.40917 8.64406 2.34601 10.2116 2.34601 11.998L2.34601 13Z"
                fillRule="evenodd"
                fill="rgb(120, 172, 255)"
            />
            <path
                transform="matrix(1 0 0 1 3 7)"
                d="M9 3C9 1.34315 7.65685 0 6 0C4.34315 0 3 1.34315 3 3C3 4.65685 4.34315 6 6 6C7.65685 6 9 4.65685 9 3ZM4 7C1.79086 7 0 8.79086 0 11L0 12L11 12L11 11C11 8.79086 9.20914 7 7 7L4 7Z"
                fillRule="evenodd"
                fill="rgb(89, 147, 255)"
            />
        </svg>
    )
})
export const MulticolorIconCommonCollection24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 0 0)"
                d="M2 0C0.895431 0 0 0.895431 0 2L0 22C0 23.1046 0.89543 24 2 24L22 24C23.1046 24 24 23.1046 24 22L24 2C24 0.895431 23.1046 0 22 0L2 0Z"
                fillRule="nonzero"
                fill="rgb(249, 249, 250)"
            />
            <path
                transform="matrix(1 0 0 1 0 0)"
                d="M-0 2C0 0.89543 0.89543 -0 2 -0L22 0C23.1046 0 24 0.89543 24 2L24 22C24 23.1046 23.1046 24 22 24L2 24C0.89543 24 -0 23.1046 -0 22L0 2ZM2 1C1.44772 1 1 1.44772 1 2L1 22C1 22.5523 1.44772 23 2 23L22 23C22.5523 23 23 22.5523 23 22L23 2C23 1.44772 22.5523 1 22 1L2 1Z"
                fillRule="nonzero"
                fill="rgb(26, 29, 37)"
                fillOpacity="0.04"
            />
            <defs>
                <clipPath id={ids[0]}>
                    <path transform="matrix(1 0 0 1 6 6)" d="M0 0L12 0L12 12L0 12L0 0Z" fillRule="nonzero" />
                </clipPath>
            </defs>
            <g clipPath={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 7 7)"
                    d="M5 9.5Q5.16093 9.5 5.23736 9.45137Q5.29343 9.4157 5.33721 9.32181Q5.43296 9.11644 5.49716 8.40815Q5.55655 7.75295 5.6355 7.42293Q5.78334 6.80494 6.12574 6.41844Q6.4385 6.0654 7.06807 5.85558Q7.39736 5.74584 8.11182 5.6Q8.95145 5.4286 9.23042 5.29597Q9.39288 5.21874 9.45672 5.13859Q9.5 5.08427 9.5 5Q9.5 4.08415 9.14657 3.24855Q8.80504 2.44108 8.18198 1.81802Q7.55892 1.19496 6.75145 0.853427Q5.91585 0.5 5 0.5Q4.08415 0.5 3.24855 0.853427Q2.44108 1.19496 1.81802 1.81802Q1.19496 2.44108 0.853427 3.24855Q0.5 4.08415 0.5 5Q0.5 5.91585 0.853427 6.75145Q1.19496 7.55892 1.81802 8.18198Q2.44108 8.80504 3.24855 9.14657Q4.08415 9.5 5 9.5ZM5 10.5Q3.88136 10.5 2.859 10.0676Q1.87189 9.65007 1.11091 8.88909Q0.349932 8.12811 -0.0675777 7.141Q-0.5 6.11864 -0.5 5Q-0.5 3.88136 -0.0675775 2.859Q0.349934 1.87189 1.11091 1.11091Q1.87189 0.349933 2.859 -0.0675777Q3.88136 -0.5 5 -0.5Q6.11864 -0.5 7.141 -0.0675775Q8.12811 0.349933 8.88909 1.11091Q9.65007 1.87189 10.0676 2.859Q10.5 3.88136 10.5 5Q10.5 5.79964 9.6598 6.1991Q9.39373 6.3256 8.99776 6.42778Q8.77532 6.48518 8.31182 6.57979Q7.65639 6.71359 7.38425 6.80428Q7.00915 6.92929 6.87426 7.08156Q6.69771 7.28085 6.60806 7.65559Q6.54593 7.91526 6.49308 8.49842Q6.45492 8.91939 6.42205 9.1204Q6.36133 9.49173 6.24354 9.74437Q5.89125 10.5 5 10.5Z"
                    fillRule="nonzero"
                    fill="rgb(69, 73, 89)"
                />
                <path
                    transform="matrix(1 0 0 1 12.25 8.75)"
                    d="M0.75 1Q0.853562 1 0.926781 0.926781Q1 0.853562 1 0.75Q1 0.646438 0.926781 0.573219Q0.853562 0.5 0.75 0.5Q0.646438 0.5 0.573219 0.573219Q0.5 0.646438 0.5 0.75Q0.5 0.853562 0.573219 0.926781Q0.646438 1 0.75 1ZM0.75 2Q0.232224 2 -0.133888 1.63389Q-0.5 1.26778 -0.5 0.75Q-0.5 0.232224 -0.133888 -0.133888Q0.232224 -0.5 0.75 -0.5Q1.26778 -0.5 1.63389 -0.133888Q2 0.232224 2 0.75Q2 1.26778 1.63389 1.63389Q1.26778 2 0.75 2Z"
                    fillRule="nonzero"
                    fill="rgb(69, 73, 89)"
                />
                <path
                    transform="matrix(1 0 0 1 9.25 9.75)"
                    d="M0.75 1Q0.853562 1 0.926781 0.926781Q1 0.853562 1 0.75Q1 0.646438 0.926781 0.573219Q0.853562 0.5 0.75 0.5Q0.646438 0.5 0.573219 0.573219Q0.5 0.646438 0.5 0.75Q0.5 0.853562 0.573219 0.926781Q0.646438 1 0.75 1ZM0.75 2Q0.232224 2 -0.133888 1.63389Q-0.5 1.26778 -0.5 0.75Q-0.5 0.232224 -0.133888 -0.133888Q0.232224 -0.5 0.75 -0.5Q1.26778 -0.5 1.63389 -0.133888Q2 0.232224 2 0.75Q2 1.26778 1.63389 1.63389Q1.26778 2 0.75 2Z"
                    fillRule="nonzero"
                    fill="rgb(69, 73, 89)"
                />
                <path
                    transform="matrix(1 0 0 1 9.5 13)"
                    d="M0.75 1Q0.853562 1 0.926781 0.926781Q1 0.853562 1 0.75Q1 0.646438 0.926781 0.573219Q0.853562 0.5 0.75 0.5Q0.646438 0.5 0.573219 0.573219Q0.5 0.646438 0.5 0.75Q0.5 0.853562 0.573219 0.926781Q0.646438 1 0.75 1ZM0.75 2Q0.232224 2 -0.133888 1.63389Q-0.5 1.26778 -0.5 0.75Q-0.5 0.232224 -0.133888 -0.133888Q0.232224 -0.5 0.75 -0.5Q1.26778 -0.5 1.63389 -0.133888Q2 0.232224 2 0.75Q2 1.26778 1.63389 1.63389Q1.26778 2 0.75 2Z"
                    fillRule="nonzero"
                    fill="rgb(69, 73, 89)"
                />
            </g>
        </svg>
    )
})
export const MulticolorIconPluginArcText24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 7.62939e-06 7.62939e-06)"
                d="M2 0C0.895431 0 0 0.895431 0 2L0 22C0 23.1046 0.89543 24 2 24L22 24C23.1046 24 24 23.1046 24 22L24 2C24 0.895431 23.1046 0 22 0L2 0Z"
                fillRule="nonzero"
                fill="rgb(238, 158, 57)"
            />
            <defs>
                <clipPath id={ids[0]}>
                    <path
                        transform="matrix(1 0 0 1 7.62939e-06 7.62939e-06)"
                        d="M2 0C0.895431 0 0 0.895431 0 2L0 22C0 23.1046 0.89543 24 2 24L22 24C23.1046 24 24 23.1046 24 22L24 2C24 0.895431 23.1046 0 22 0L2 0Z"
                        fillRule="nonzero"
                    />
                </clipPath>
            </defs>
            <g clipPath={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 5.27429 16.8522)"
                    d="M0.274905 -0.40478Q1.68542 0.523654 3.2783 1.01158Q4.92181 1.51502 6.65093 1.51502Q8.38581 1.51502 10.0344 1.00825Q11.632 0.517127 13.0456 -0.41713L13.597 0.41713Q12.0634 1.43071 10.3282 1.96411Q8.53604 2.51502 6.65093 2.51502Q4.77209 2.51502 2.98542 1.96773Q1.2554 1.4378 -0.274905 0.43051L0.274905 -0.40478Z"
                    fillRule="nonzero"
                    fill="rgb(255, 255, 255)"
                />
                <path
                    transform="matrix(1 0 0 1 4.39991 15.9564)"
                    d="M0 0L1.67916 0L1.67916 1.67916L0 1.67916L0 0Z"
                    fillRule="nonzero"
                    fill="rgb(238, 158, 57)"
                />
                <path
                    transform="matrix(1 0 0 1 4.39991 15.9564)"
                    d="M-0.25 -0.25L1.92916 -0.25L1.92916 1.92916L-0.25 1.92916L-0.25 -0.25ZM0.25 0.25L0.25 1.42916L1.42916 1.42916L1.42916 0.25L0.25 0.25Z"
                    fillRule="nonzero"
                    fill="rgb(255, 255, 255)"
                />
                <path
                    transform="matrix(1 0 0 1 17.8999 15.9564)"
                    d="M0 0L1.67916 0L1.67916 1.67916L0 1.67916L0 0Z"
                    fillRule="nonzero"
                    fill="rgb(238, 158, 57)"
                />
                <path
                    transform="matrix(1 0 0 1 17.8999 15.9564)"
                    d="M-0.25 -0.25L1.92916 -0.25L1.92916 1.92916L-0.25 1.92916L-0.25 -0.25ZM0.25 0.25L0.25 1.42916L1.42916 1.42916L1.42916 0.25L0.25 0.25Z"
                    fillRule="nonzero"
                    fill="rgb(255, 255, 255)"
                />
                <path
                    transform="matrix(1 0 0 1 7.50001 5.00001)"
                    d="M1.87486 10L2.67559 7.53273L6.32116 7.53273L7.12514 10L9 10L5.58228 0L3.41447 0L0 10L1.87486 10ZM3.15081 6.07922L5.84919 6.07922L4.53743 2.05102L4.46257 2.05102L3.15081 6.07922Z"
                    fillRule="evenodd"
                    fill="rgb(255, 255, 255)"
                />
            </g>
        </svg>
    )
})
export const MulticolorIconPluginLoopChange24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 0 0)"
                d="M2 0C0.895431 0 0 0.895431 0 2L0 22C0 23.1046 0.89543 24 2 24L22 24C23.1046 24 24 23.1046 24 22L24 2C24 0.895431 23.1046 0 22 0L2 0Z"
                fillRule="nonzero"
                fill="rgb(30, 21, 38)"
            />
            <defs>
                <clipPath id={ids[0]}>
                    <path
                        transform="matrix(1 0 0 1 0 0)"
                        d="M2 0C0.895431 0 0 0.895431 0 2L0 22C0 23.1046 0.89543 24 2 24L22 24C23.1046 24 24 23.1046 24 22L24 2C24 0.895431 23.1046 0 22 0L2 0Z"
                        fillRule="nonzero"
                    />
                </clipPath>
            </defs>
            <g clipPath={`url(#${ids[0]})`}>
                <path
                    transform="matrix(0.173649 0.984808 -0.984808 0.173649 12.9402 4)"
                    d="M4.53754 0C7.04355 0 9.07507 2.03218 9.07507 4.539C9.07507 7.04582 7.04355 9.078 4.53754 9.078C2.03152 9.078 0 7.04582 0 4.539C0 2.03218 2.03152 0 4.53754 0Z"
                    fillRule="nonzero"
                    fill="rgb(20, 6, 43)"
                />
                <path
                    transform="matrix(0.173649 0.984808 -0.984808 0.173649 12.9402 4)"
                    d="M9.07507 4.539C9.07507 2.03218 7.04355 0 4.53754 0C2.03152 0 0 2.03218 0 4.539C0 7.04582 2.03152 9.078 4.53754 9.078C7.04355 9.078 9.07507 7.04582 9.07507 4.539ZM3.04408 1.00137Q3.75637 0.7 4.53754 0.7Q5.3187 0.7 6.03099 1.00137Q6.71953 1.29269 7.251 1.82434Q7.7825 2.35601 8.07375 3.04483Q8.37507 3.75745 8.37507 4.539Q8.37507 5.32055 8.07375 6.03317Q7.7825 6.72199 7.251 7.25366Q6.71953 7.78531 6.03099 8.07663Q5.3187 8.378 4.53754 8.378Q3.75637 8.378 3.04408 8.07663Q2.35554 7.78531 1.82407 7.25366Q1.29257 6.72199 1.00132 6.03317Q0.7 5.32055 0.7 4.539Q0.7 3.75745 1.00132 3.04483Q1.29257 2.35601 1.82407 1.82434Q2.35554 1.29269 3.04408 1.00137Z"
                    fillRule="evenodd"
                    fill="rgb(255, 160, 28)"
                />
                <path
                    transform="matrix(0.5 0.866025 -0.866025 0.5 12.6677 5.34277)"
                    d="M4.22731 0C6.562 0 8.45463 1.89329 8.45463 4.22878C8.45463 6.56427 6.562 8.45756 4.22731 8.45756C1.89263 8.45756 0 6.56427 0 4.22878C0 1.89329 1.89263 0 4.22731 0Z"
                    fillRule="nonzero"
                    fill="rgb(20, 6, 43)"
                />
                <path
                    transform="matrix(0.5 0.866025 -0.866025 0.5 12.6677 5.34277)"
                    d="M8.45463 4.22878C8.45463 1.89329 6.562 0 4.22731 0C1.89263 0 0 1.89329 0 4.22878C0 6.56427 1.89263 8.45756 4.22731 8.45756C6.562 8.45756 8.45463 6.56427 8.45463 4.22878ZM2.85462 0.976988Q3.50927 0.7 4.22731 0.7Q4.94536 0.7 5.60001 0.976988Q6.23288 1.24476 6.72142 1.73347Q7.20998 2.2222 7.47769 2.85535Q7.75463 3.51034 7.75463 4.22878Q7.75463 4.94722 7.47769 5.6022Q7.20998 6.23536 6.72142 6.72409Q6.23288 7.2128 5.60001 7.48057Q4.94536 7.75756 4.22732 7.75756Q3.50926 7.75756 2.85462 7.48057Q2.22175 7.2128 1.73321 6.72409Q1.24465 6.23536 0.97694 5.6022Q0.7 4.94722 0.7 4.22878Q0.7 3.51034 0.97694 2.85535Q1.24465 2.2222 1.73321 1.73347Q2.22175 1.24476 2.85462 0.976988Z"
                    fillRule="evenodd"
                    fill="rgb(240, 255, 46)"
                />
                <path
                    transform="matrix(0.766044 0.642788 -0.642788 0.766044 12.499 7.45978)"
                    d="M3.91709 0C6.08044 0 7.83419 1.7544 7.83419 3.91856C7.83419 6.08272 6.08044 7.83712 3.91709 7.83712C1.75374 7.83712 0 6.08272 0 3.91856C0 1.7544 1.75374 0 3.91709 0Z"
                    fillRule="nonzero"
                    fill="rgb(20, 6, 43)"
                />
                <path
                    transform="matrix(0.766044 0.642788 -0.642788 0.766044 12.499 7.45978)"
                    d="M7.83419 3.91856C7.83419 1.7544 6.08044 0 3.91709 0C1.75374 0 0 1.7544 0 3.91856C0 6.08272 1.75374 7.83712 3.91709 7.83712C6.08044 7.83712 7.83419 6.08272 7.83419 3.91856ZM2.66516 0.952606Q3.26216 0.7 3.91709 0.7Q4.57202 0.7 5.16903 0.952606Q5.74623 1.19683 6.19183 1.6426Q6.63746 2.0884 6.88162 2.66588Q7.13419 3.26323 7.13419 3.91856Q7.13419 4.57388 6.88162 5.17124Q6.63746 5.74872 6.19183 6.19451Q5.74622 6.64028 5.16903 6.88451Q4.57202 7.13712 3.91709 7.13712Q3.26216 7.13712 2.66516 6.88451Q2.08796 6.64029 1.64236 6.19451Q1.19673 5.74872 0.952564 5.17124Q0.7 4.57389 0.7 3.91856Q0.7 3.26323 0.952564 2.66588Q1.19673 2.0884 1.64236 1.6426Q2.08796 1.19683 2.66516 0.952606Z"
                    fillRule="evenodd"
                    fill="rgb(84, 255, 171)"
                />
                <path
                    transform="matrix(0.939693 0.34202 -0.34202 0.939693 12.6868 10.2164)"
                    d="M3.60687 0C5.59889 0 7.21375 1.61551 7.21375 3.60834C7.21375 5.60117 5.59889 7.21667 3.60687 7.21667C1.61485 7.21667 0 5.60117 0 3.60834C0 1.61551 1.61485 0 3.60687 0Z"
                    fillRule="nonzero"
                    fill="rgb(20, 6, 43)"
                />
                <path
                    transform="matrix(0.939693 0.34202 -0.34202 0.939693 12.6868 10.2164)"
                    d="M7.21375 3.60834C7.21375 1.61551 5.59889 0 3.60687 0C1.61485 0 0 1.61551 0 3.60834C0 5.60117 1.61485 7.21667 3.60687 7.21667C5.59889 7.21667 7.21375 5.60117 7.21375 3.60834ZM2.4757 0.928224Q3.01506 0.7 3.60687 0.7Q4.19869 0.7 4.73805 0.928224Q5.25957 1.1489 5.66224 1.55173Q6.06494 1.95459 6.28556 2.4764Q6.51375 3.01612 6.51375 3.60834Q6.51375 4.20055 6.28556 4.74027Q6.06494 5.26208 5.66224 5.66494Q5.25957 6.06778 4.73805 6.28845Q4.19869 6.51667 3.60687 6.51667Q3.01506 6.51667 2.4757 6.28845Q1.95417 6.06778 1.5515 5.66494Q1.14881 5.26208 0.928189 4.74027Q0.7 4.20056 0.7 3.60834Q0.7 3.01612 0.928189 2.4764Q1.14881 1.95459 1.5515 1.55173Q1.95417 1.1489 2.4757 0.928224Z"
                    fillRule="evenodd"
                    fill="rgb(52, 232, 244)"
                />
                <path
                    transform="matrix(1 0 0 1 13.4067 13.404)"
                    d="M3.29665 0C5.11734 0 6.59331 1.47662 6.59331 3.29812C6.59331 5.11962 5.11734 6.59623 3.29665 6.59623C1.47596 6.59623 0 5.11962 0 3.29812C0 1.47662 1.47596 0 3.29665 0Z"
                    fillRule="nonzero"
                    fill="rgb(20, 6, 43)"
                />
                <path
                    transform="matrix(1 0 0 1 13.4067 13.404)"
                    d="M6.59331 3.29812C6.59331 1.47662 5.11734 0 3.29665 0C1.47596 0 0 1.47662 0 3.29812C0 5.11962 1.47596 6.59623 3.29665 6.59623C5.11734 6.59623 6.59331 5.11962 6.59331 3.29812ZM2.28624 0.903842Q2.76796 0.7 3.29665 0.7Q3.82535 0.7 4.30707 0.903842Q4.77292 1.10097 5.13265 1.46086Q5.49242 1.82079 5.68949 2.28692Q5.89331 2.76901 5.89331 3.29812Q5.89331 3.82722 5.68949 4.30931Q5.49242 4.77545 5.13265 5.13537Q4.77292 5.49527 4.30707 5.69239Q3.82535 5.89623 3.29665 5.89623Q2.76796 5.89623 2.28624 5.69239Q1.82038 5.49527 1.46065 5.13537Q1.10089 4.77545 0.903814 4.30931Q0.7 3.82722 0.7 3.29812Q0.7 2.76901 0.903814 2.28692Q1.10089 1.82079 1.46065 1.46086Q1.82039 1.10097 2.28624 0.903842Z"
                    fillRule="evenodd"
                    fill="rgb(82, 166, 255)"
                />
            </g>
        </svg>
    )
})
export const MulticolorIconPluginPicture24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 4 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 0 0)"
                d="M2 0C0.895431 0 0 0.895431 0 2L0 22C0 23.1046 0.89543 24 2 24L22 24C23.1046 24 24 23.1046 24 22L24 2C24 0.895431 23.1046 0 22 0L2 0Z"
                fillRule="nonzero"
                fill="rgb(111, 138, 254)"
            />
            <defs>
                <clipPath id={ids[0]}>
                    <path
                        transform="matrix(1 0 0 1 0 0)"
                        d="M2 0C0.895431 0 0 0.895431 0 2L0 22C0 23.1046 0.89543 24 2 24L22 24C23.1046 24 24 23.1046 24 22L24 2C24 0.895431 23.1046 0 22 0L2 0Z"
                        fillRule="nonzero"
                    />
                </clipPath>
            </defs>
            <g clipPath={`url(#${ids[0]})`}>
                <defs>
                    <filter id={ids[1]} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feComponentTransfer in="SourceAlpha" result="componentTransfer8784831937">
                            <feFuncR type="linear" slope="0" intercept="0" />
                            <feFuncG type="linear" slope="0" intercept="0" />
                            <feFuncB type="linear" slope="0" intercept="0" />
                            <feFuncA type="linear" slope="10000" intercept="0" />
                        </feComponentTransfer>
                        <feComponentTransfer in="componentTransfer8784831937" result="componentTransfer0385189600">
                            <feFuncA type="linear" slope="0.1" intercept="0" />
                        </feComponentTransfer>
                        <feOffset dx="0" dy="1.1428573" in="componentTransfer0385189600" result="offset5931504679" />
                        <feGaussianBlur
                            stdDeviation="0.57142866"
                            in="offset5931504679"
                            result="gaussianBlur9064626702"
                        />
                        <feMerge>
                            <feMergeNode in="gaussianBlur9064626702" />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                </defs>
                <g filter={`url(#${ids[1]})`}>
                    <path
                        transform="matrix(1 0 0 1 4 3.99951)"
                        d="M1.14286 0C0.511675 0 0 0.511675 0 1.14286L0 14.8571C0 15.4883 0.511675 16 1.14286 16L14.8571 16C15.4883 16 16 15.4883 16 14.8571L16 1.14286C16 0.511675 15.4883 0 14.8571 0L1.14286 0Z"
                        fillRule="nonzero"
                        fill="rgb(255, 255, 255)"
                    />
                    <defs>
                        <clipPath id={ids[2]}>
                            <path
                                transform="matrix(1 0 0 1 4 3.99951)"
                                d="M1.14286 0C0.511675 0 0 0.511675 0 1.14286L0 14.8571C0 15.4883 0.511675 16 1.14286 16L14.8571 16C15.4883 16 16 15.4883 16 14.8571L16 1.14286C16 0.511675 15.4883 0 14.8571 0L1.14286 0Z"
                                fillRule="nonzero"
                            />
                        </clipPath>
                    </defs>
                    <g clipPath={`url(#${ids[2]})`}>
                        <path
                            transform="matrix(1 0 0 1 5 4.99951)"
                            d="M14 12L0 12L0 0.571429C0 0.255837 0.255837 0 0.571429 0L13.4286 0C13.7442 0 14 0.255837 14 0.571429L14 12Z"
                            fillRule="nonzero"
                            fill="rgb(166, 203, 255)"
                        />
                        <defs>
                            <clipPath id={ids[3]}>
                                <path
                                    transform="matrix(1 0 0 1 5 4.99951)"
                                    d="M14 12L0 12L0 0.571429C0 0.255837 0.255837 0 0.571429 0L13.4286 0C13.7442 0 14 0.255837 14 0.571429L14 12Z"
                                    fillRule="nonzero"
                                />
                            </clipPath>
                        </defs>
                        <g clipPath={`url(#${ids[3]})`}>
                            <path
                                transform="matrix(1 0 0 1 11.8572 12.2852)"
                                d="M3.89071 0.635436C3.66251 0.321662 3.19464 0.321662 2.96644 0.635436L0 4.71429L6.85714 4.71429L3.89071 0.635436Z"
                                fillRule="nonzero"
                                fill="rgb(90, 147, 228)"
                                opacity="0.5"
                            />
                            <path
                                transform="matrix(1 0 0 1 5 8.99951)"
                                d="M5.06757 0.868243C4.8482 0.484349 4.29466 0.484349 4.07529 0.868243L0 8L9.14286 8L5.06757 0.868243Z"
                                fillRule="nonzero"
                                fill="rgb(91, 148, 228)"
                            />
                            <path
                                transform="matrix(1 0 0 1 14 6.99951)"
                                d="M1.25 0C1.94036 0 2.5 0.559644 2.5 1.25C2.5 1.94036 1.94036 2.5 1.25 2.5C0.559644 2.5 0 1.94036 0 1.25C0 0.559644 0.559644 0 1.25 0Z"
                                fillRule="nonzero"
                                fill="rgb(255, 255, 255)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
})
export const MulticolorIconPluginImporToAE24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 0 7.62939e-06)"
                d="M2 0C0.895431 0 0 0.895431 0 2L0 22C0 23.1046 0.89543 24 2 24L22 24C23.1046 24 24 23.1046 24 22L24 2C24 0.895431 23.1046 0 22 0L2 0Z"
                fillRule="nonzero"
                fill="rgb(0, 0, 0)"
            />
            <defs>
                <clipPath id={ids[0]}>
                    <path
                        transform="matrix(1 0 0 1 0 7.62939e-06)"
                        d="M2 0C0.895431 0 0 0.895431 0 2L0 22C0 23.1046 0.89543 24 2 24L22 24C23.1046 24 24 23.1046 24 22L24 2C24 0.895431 23.1046 0 22 0L2 0Z"
                        fillRule="nonzero"
                    />
                </clipPath>
            </defs>
            <g clipPath={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 12.0001 13)"
                    d="M0.5 0C0.223858 0 0 0.223858 0 0.5L0 7.5C0 7.77614 0.223858 8 0.5 8L7.5 8C7.77614 8 8 7.77614 8 7.5L8 0.5C8 0.223858 7.77614 0 7.5 0L0.5 0Z"
                    fillRule="nonzero"
                    fill="rgb(0, 0, 0)"
                />
                <path
                    transform="matrix(1 0 0 1 12.0001 13)"
                    d="M-0 0.5C0 0.223858 0.223858 -0 0.5 -0L7.5 0C7.77614 0 8 0.223858 8 0.5L8 7.5C8 7.77614 7.77614 8 7.5 8L0.5 8C0.223858 8 -0 7.77614 -0 7.5L0 0.5ZM0.5 1C0.776142 1 1 0.776142 1 0.5L1 7.5C1 7.22386 0.776142 7 0.5 7L7.5 7C7.22386 7 7 7.22386 7 7.5L7 0.5C7 0.776142 7.22386 1 7.5 1L0.5 1Z"
                    fillRule="nonzero"
                    fill="rgb(195, 196, 255)"
                />
                <path
                    transform="matrix(1 0 0 1 8.00006 9.00001)"
                    d="M0.5 0C0.223858 0 0 0.223858 0 0.5L0 7.5C0 7.77614 0.223858 8 0.5 8L7.5 8C7.77614 8 8 7.77614 8 7.5L8 0.5C8 0.223858 7.77614 0 7.5 0L0.5 0Z"
                    fillRule="nonzero"
                    fill="rgb(195, 196, 255)"
                />
                <path
                    transform="matrix(1 0 0 1 4.00006 5.00001)"
                    d="M0.5 0C0.223858 0 0 0.223858 0 0.5L0 7.5C0 7.77614 0.223858 8 0.5 8L7.5 8C7.77614 8 8 7.77614 8 7.5L8 0.5C8 0.223858 7.77614 0 7.5 0L0.5 0Z"
                    fillRule="nonzero"
                    fill="rgb(0, 0, 0)"
                />
                <path
                    transform="matrix(1 0 0 1 4.00006 5.00001)"
                    d="M-0 0.5C0 0.223858 0.223858 -0 0.5 -0L7.5 0C7.77614 0 8 0.223858 8 0.5L8 7.5C8 7.77614 7.77614 8 7.5 8L0.5 8C0.223858 8 -0 7.77614 -0 7.5L0 0.5ZM0.5 1C0.776142 1 1 0.776142 1 0.5L1 7.5C1 7.22386 0.776142 7 0.5 7L7.5 7C7.22386 7 7 7.22386 7 7.5L7 0.5C7 0.776142 7.22386 1 7.5 1L0.5 1Z"
                    fillRule="nonzero"
                    fill="rgb(195, 196, 255)"
                />
                <path
                    transform="matrix(0.707107 -0.707107 0.707107 0.707107 16.1001 7.93)"
                    d="M3.63539 -3.35355L6.63539 -0.353553Q6.67022 -0.318731 6.69757 -0.277785Q6.72493 -0.236839 6.74378 -0.191342Q6.76262 -0.145845 6.77223 -0.0975452Q6.78184 -0.0492457 6.78184 0Q6.78184 0.0492457 6.77223 0.0975452Q6.76262 0.145845 6.74378 0.191342Q6.72493 0.236839 6.69757 0.277785Q6.67022 0.318731 6.63539 0.353553L3.63539 3.35355Q3.60057 3.38838 3.55963 3.41573Q3.51868 3.44309 3.47318 3.46194Q3.42768 3.48078 3.37939 3.49039Q3.33109 3.5 3.28184 3.5Q3.23259 3.5 3.18429 3.49039Q3.136 3.48078 3.0905 3.46194Q3.045 3.44309 3.00405 3.41573Q2.96311 3.38838 2.92829 3.35355Q2.89347 3.31873 2.86611 3.27778Q2.83875 3.23684 2.8199 3.19134Q2.80105 3.14584 2.79145 3.09754Q2.78184 3.04925 2.78184 3Q2.78184 2.95075 2.79145 2.90245Q2.80105 2.85416 2.8199 2.80866Q2.83875 2.76316 2.86611 2.72221Q2.89347 2.68127 2.92829 2.64645L5.07473 0.5L0 0.5L0 -0.5L5.07473 -0.5L2.92829 -2.64645Q2.89347 -2.68127 2.86611 -2.72221Q2.83875 -2.76316 2.8199 -2.80866Q2.80105 -2.85416 2.79145 -2.90245Q2.78184 -2.95075 2.78184 -3Q2.78184 -3.04925 2.79145 -3.09754Q2.80105 -3.14584 2.8199 -3.19134Q2.83875 -3.23684 2.86611 -3.27778Q2.89347 -3.31873 2.92829 -3.35355Q2.96311 -3.38838 3.00406 -3.41573Q3.045 -3.44309 3.0905 -3.46194Q3.136 -3.48078 3.18429 -3.49039Q3.23259 -3.5 3.28184 -3.5Q3.33109 -3.5 3.37939 -3.49039Q3.42768 -3.48078 3.47318 -3.46194Q3.51868 -3.44309 3.55963 -3.41573Q3.60057 -3.38838 3.63539 -3.35355Z"
                    fillRule="evenodd"
                    fill="rgb(195, 196, 255)"
                />
            </g>
        </svg>
    )
})
export const MulticolorIconPluginFillingRule24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 2 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 0 0)"
                d="M2 0C0.895431 0 0 0.895431 0 2L0 22C0 23.1046 0.89543 24 2 24L22 24C23.1046 24 24 23.1046 24 22L24 2C24 0.895431 23.1046 0 22 0L2 0Z"
                fillRule="nonzero"
                fill="rgb(224, 239, 255)"
            />
            <defs>
                <clipPath id={ids[0]}>
                    <path
                        transform="matrix(1 0 0 1 0 0)"
                        d="M2 0C0.895431 0 0 0.895431 0 2L0 22C0 23.1046 0.89543 24 2 24L22 24C23.1046 24 24 23.1046 24 22L24 2C24 0.895431 23.1046 0 22 0L2 0Z"
                        fillRule="nonzero"
                    />
                </clipPath>
            </defs>
            <g clipPath={`url(#${ids[0]})`}>
                <defs>
                    <clipPath id={ids[1]}>
                        <path transform="matrix(1 0 0 1 4 4)" d="M0 0L16 0L16 16L0 16L0 0Z" fillRule="nonzero" />
                    </clipPath>
                </defs>
                <g clipPath={`url(#${ids[1]})`}>
                    <path
                        transform="matrix(1 0 0 1 -51.5 -52)"
                        d="M1.06603 -1.05526L99.066 97.9447L96.934 100.055L-1.06603 1.05526L1.06603 -1.05526Z"
                        fillRule="nonzero"
                        fill="rgb(48, 113, 255)"
                        opacity="0.4"
                    />
                    <path
                        transform="matrix(1 0 0 1 -45.5 -52)"
                        d="M1.06603 -1.05526L99.066 97.9447L96.934 100.055L-1.06603 1.05526L1.06603 -1.05526Z"
                        fillRule="nonzero"
                        fill="rgb(48, 113, 255)"
                        opacity="0.4"
                    />
                    <path
                        transform="matrix(1 0 0 1 -39.5 -52)"
                        d="M1.06603 -1.05526L99.066 97.9447L96.934 100.055L-1.06603 1.05526L1.06603 -1.05526Z"
                        fillRule="nonzero"
                        fill="rgb(48, 113, 255)"
                        opacity="0.4"
                    />
                    <path
                        transform="matrix(1 0 0 1 -63.5 -52)"
                        d="M1.06603 -1.05526L99.066 97.9447L96.934 100.055L-1.06603 1.05526L1.06603 -1.05526Z"
                        fillRule="nonzero"
                        fill="rgb(48, 113, 255)"
                        opacity="0.4"
                    />
                    <path
                        transform="matrix(1 0 0 1 -57.5 -52)"
                        d="M1.06603 -1.05526L99.066 97.9447L96.934 100.055L-1.06603 1.05526L1.06603 -1.05526Z"
                        fillRule="nonzero"
                        fill="rgb(48, 113, 255)"
                        opacity="0.4"
                    />
                </g>
                <path
                    transform="matrix(1 0 0 1 4 4)"
                    d="M-0 -0L16 0L16 16L-0 16L0 -0ZM1 1L1 15L15 15L15 1L1 1Z"
                    fillRule="nonzero"
                    fill="rgb(48, 113, 255)"
                />
                <path
                    transform="matrix(1 0 0 1 8 8)"
                    d="M0 0L8 0L8 8L0 8L0 0Z"
                    fillRule="nonzero"
                    fill="rgb(224, 239, 255)"
                />
                <path
                    transform="matrix(1 0 0 1 8 8)"
                    d="M-0 -0L8 0L8 8L-0 8L0 -0ZM1 1L1 7L7 7L7 1L1 1Z"
                    fillRule="nonzero"
                    fill="rgb(48, 113, 255)"
                />
                <path
                    transform="matrix(0 -1 -1 -0 14 6)"
                    d="M1.5 0L3 3L0 3L1.5 0Z"
                    fillRule="nonzero"
                    fill="rgb(48, 113, 255)"
                />
                <path
                    transform="matrix(0 1 1 0 11 18)"
                    d="M1.5 0L3 3L0 3L1.5 0Z"
                    fillRule="nonzero"
                    fill="rgb(48, 113, 255)"
                />
                <path
                    transform="matrix(1 0 0 -1 18 12.5)"
                    d="M1.5 0L3 3L0 3L1.5 0Z"
                    fillRule="nonzero"
                    fill="rgb(48, 113, 255)"
                />
                <path
                    transform="matrix(-1 0 0 1 6 9.5)"
                    d="M1.5 0L3 3L0 3L1.5 0Z"
                    fillRule="nonzero"
                    fill="rgb(48, 113, 255)"
                />
            </g>
        </svg>
    )
})
export const MulticolorIconPluginIncline24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 2 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 0 0)"
                d="M2 0C0.895431 0 0 0.895431 0 2L0 22C0 23.1046 0.89543 24 2 24L22 24C23.1046 24 24 23.1046 24 22L24 2C24 0.895431 23.1046 0 22 0L2 0Z"
                fillRule="nonzero"
                fill="rgb(221, 221, 255)"
            />
            <path
                transform="matrix(1 0 0 1 5 8.5)"
                d="M3.99999 0L14 0L10 7L0 7L3.99999 0Z"
                fillRule="nonzero"
                fill="rgb(100, 55, 231)"
            />
            <defs>
                <linearGradient
                    id={ids[0]}
                    gradientTransform="matrix(-2.91714 -0 4.09891e-07 0 2.60291 0)"
                    gradientUnits="userSpaceOnUse"
                    x1="0"
                    y1="0.5"
                    x2="1"
                    y2="0.5"
                >
                    <stop offset="0" stopColor="rgb(100, 55, 231)" />
                    <stop offset="1" stopColor="rgb(100, 55, 231)" stopOpacity="0" />
                </linearGradient>
            </defs>
            <path
                transform="matrix(1 0 0 1 10 6.60001)"
                d="M-0.4 0.4L7.40049 0.4L7.40049 -0.4L-0.4 -0.4L-0.4 0.4Z"
                fillRule="evenodd"
                fill={`url(#${ids[0]})`}
            />
            <path
                transform="matrix(1 0 0 1 17.0005 4.5)"
                d="M0 0L3 2.5L3.64139e-05 2.5L0 0Z"
                fillRule="nonzero"
                fill="rgb(100, 55, 231)"
            />
            <defs>
                <linearGradient
                    id={ids[1]}
                    gradientTransform="matrix(-2.91693 -0 4.09872e-07 0 3.00311 0)"
                    gradientUnits="userSpaceOnUse"
                    x1="0"
                    y1="0.5"
                    x2="1"
                    y2="0.5"
                >
                    <stop offset="0" stopColor="rgb(100, 55, 231)" />
                    <stop offset="1" stopColor="rgb(100, 55, 231)" stopOpacity="0" />
                </linearGradient>
            </defs>
            <path
                transform="matrix(-1 0 0 -1 14.0005 17.4)"
                d="M-0.4 0.4L7.40049 0.4L7.40049 -0.4L-0.4 -0.4L-0.4 0.4Z"
                fillRule="evenodd"
                fill={`url(#${ids[1]})`}
            />
            <path
                transform="matrix(-1 0 0 -1 7 19.5)"
                d="M0 0L3 2.5L3.64139e-05 2.5L0 0Z"
                fillRule="nonzero"
                fill="rgb(100, 55, 231)"
            />
        </svg>
    )
})
export const MulticolorIconPluginRotateCopy24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 1 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 0 0)"
                d="M2 0C0.895431 0 0 0.895431 0 2L0 22C0 23.1046 0.89543 24 2 24L22 24C23.1046 24 24 23.1046 24 22L24 2C24 0.895431 23.1046 0 22 0L2 0Z"
                fillRule="nonzero"
                fill="rgb(231, 106, 0)"
            />
            <defs>
                <clipPath id={ids[0]}>
                    <path
                        transform="matrix(1 0 0 1 0 0)"
                        d="M2 0C0.895431 0 0 0.895431 0 2L0 22C0 23.1046 0.89543 24 2 24L22 24C23.1046 24 24 23.1046 24 22L24 2C24 0.895431 23.1046 0 22 0L2 0Z"
                        fillRule="nonzero"
                    />
                </clipPath>
            </defs>
            <g clipPath={`url(#${ids[0]})`}>
                <path
                    transform="matrix(1 0 0 1 11 4)"
                    d="M0 0L2 0L2 4L0 4L0 0Z"
                    fillRule="nonzero"
                    fill="rgb(255, 255, 255)"
                />
                <path
                    transform="matrix(0.5 0.866025 -0.866025 0.5 18.4282 7.13403)"
                    d="M0 0L2 0L2 4L0 4L0 0Z"
                    fillRule="nonzero"
                    fill="rgb(255, 255, 255)"
                />
                <path
                    transform="matrix(-0.5 0.866025 -0.866025 -0.5 19.4282 15.134)"
                    d="M0 0L2 0L2 4L0 4L0 0Z"
                    fillRule="nonzero"
                    fill="rgb(255, 255, 255)"
                />
                <path
                    transform="matrix(-1 0 0 -1 13 20)"
                    d="M0 0L2 0L2 4L0 4L0 0Z"
                    fillRule="nonzero"
                    fill="rgb(255, 255, 255)"
                />
                <path
                    transform="matrix(-0.5 -0.866025 0.866025 -0.5 5.57129 16.866)"
                    d="M0 0L2 0L2 4L0 4L0 0Z"
                    fillRule="nonzero"
                    fill="rgb(255, 255, 255)"
                />
                <path
                    transform="matrix(0.5 -0.866025 0.866025 0.5 4.57178 8.86597)"
                    d="M0 0L2 0L2 4L0 4L0 0Z"
                    fillRule="nonzero"
                    fill="rgb(255, 255, 255)"
                />
                <path
                    transform="matrix(1 0 0 1 10.5 10.5)"
                    d="M3 1.5C3 0.671573 2.32843 0 1.5 0C0.671573 0 0 0.671573 0 1.5C0 2.32843 0.671573 3 1.5 3C2.32843 3 3 2.32843 3 1.5ZM0.96967 0.96967Q1.18934 0.75 1.5 0.75Q1.81066 0.75 2.03033 0.96967Q2.25 1.18934 2.25 1.5Q2.25 1.81066 2.03033 2.03033Q1.81066 2.25 1.5 2.25Q1.18934 2.25 0.96967 2.03033Q0.75 1.81066 0.75 1.5Q0.75 1.18934 0.96967 0.96967Z"
                    fillRule="evenodd"
                    fill="rgb(255, 255, 255)"
                />
            </g>
        </svg>
    )
})
export const MulticolorIconPluginIconLibrary24 = forwardRef(function Component(
    props: SVGAttributes<SVGSVGElement>,
    ref?: React.Ref<SVGSVGElement>
) {
    const ids = useMemo(
        () => Array.from({ length: 5 }).map((_) => Math.floor(Math.random() * 10 ** 8).toString(16)),
        []
    )
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" ref={ref} {...props}>
            <path
                transform="matrix(1 0 0 1 0 0)"
                d="M2 0C0.895431 0 0 0.895431 0 2L0 22C0 23.1046 0.89543 24 2 24L22 24C23.1046 24 24 23.1046 24 22L24 2C24 0.895431 23.1046 0 22 0L2 0Z"
                fillRule="nonzero"
                fill="rgb(26, 29, 37)"
            />
            <defs>
                <clipPath id={ids[0]}>
                    <path
                        transform="matrix(1 0 0 1 0 0)"
                        d="M2 0C0.895431 0 0 0.895431 0 2L0 22C0 23.1046 0.89543 24 2 24L22 24C23.1046 24 24 23.1046 24 22L24 2C24 0.895431 23.1046 0 22 0L2 0Z"
                        fillRule="nonzero"
                    />
                </clipPath>
            </defs>
            <g clipPath={`url(#${ids[0]})`}>
                <defs>
                    <clipPath id={ids[1]}>
                        <path transform="matrix(1 0 0 1 3 3)" d="M0 0L8 0L8 8L0 8L0 0Z" fillRule="nonzero" />
                    </clipPath>
                </defs>
                <g clipPath={`url(#${ids[1]})`}>
                    <path
                        transform="matrix(1 0 0 1 3.5708 5.73657)"
                        d="M0 0L6.85899 0L6.10089 4.69299L0.758099 4.69299L0 0Z"
                        fillRule="nonzero"
                        fill="rgb(112, 163, 255)"
                    />
                    <path
                        transform="matrix(1 0 0 1 3.20923 5.37573)"
                        d="M7.22047 0L0.361486 0L0.332608 0.00115683L0.303917 0.00461987C0.205505 0.0205171 0.127126 0.06888 0.0687797 0.149708C0.0104333 0.230537 -0.0107913 0.320157 0.00510594 0.418568L0.763205 5.11156C0.77733 5.199 0.818114 5.27144 0.885556 5.32886C0.952999 5.38628 1.03101 5.41499 1.11958 5.41499L6.46238 5.41499C6.55095 5.41499 6.62896 5.38628 6.6964 5.32886C6.76385 5.27144 6.80463 5.199 6.81876 5.11156L7.57685 0.418569L7.58032 0.389877L7.58147 0.360999C7.58147 0.261312 7.54623 0.176224 7.47574 0.105734C7.40525 0.0352448 7.32016 2.15172e-08 7.22047 0ZM0.78548 0.721999L6.79648 0.721999L6.15501 4.69299L1.42695 4.69299L0.78548 0.721999Z"
                        fillRule="evenodd"
                        fill="rgb(112, 163, 255)"
                    />
                    <path
                        transform="matrix(1 0 0 1 4.65381 3.20972)"
                        d="M3.97099 2.527C3.97099 2.02249 3.80878 1.59327 3.48434 1.23934C3.16819 0.894447 2.7889 0.721999 2.3465 0.721999C1.90409 0.721999 1.52481 0.894447 1.20865 1.23934C0.884217 1.59327 0.721999 2.02249 0.721999 2.527C0.721999 2.72637 0.560372 2.888 0.360999 2.888C0.161627 2.888 -1.29103e-07 2.72637 0 2.527C-4.30345e-08 1.83526 0.225476 1.24342 0.676428 0.75147C0.892302 0.515971 1.14096 0.33329 1.42241 0.203427C1.71632 0.0678089 2.02435 0 2.3465 0C2.66864 0 2.97667 0.0678089 3.27059 0.203427C3.55203 0.333291 3.80069 0.515972 4.01656 0.75147C4.46752 1.24342 4.69299 1.83526 4.69299 2.527C4.69299 2.72637 4.53137 2.888 4.33199 2.888C4.13262 2.888 3.97099 2.72637 3.97099 2.527Z"
                        fillRule="nonzero"
                        fill="rgb(112, 163, 255)"
                    />
                    <path
                        transform="matrix(1 0 0 1 5.37573 7.00024)"
                        d="M0.360999 0C0.560374 0 0.721999 0.161625 0.721999 0.360999C0.721999 0.560374 0.560374 0.721999 0.360999 0.721999C0.161625 0.721999 0 0.560374 0 0.360999C0 0.161625 0.161625 0 0.360999 0Z"
                        fillRule="nonzero"
                        fill="rgb(26, 29, 37)"
                    />
                    <path
                        transform="matrix(1 0 0 1 5.55615 8.26367)"
                        d="M0.0606673 0.561284C-0.0499248 0.395395 -0.00509761 0.171259 0.160791 0.0606673C0.326679 -0.0499248 0.550815 -0.00509761 0.661407 0.160791C0.698706 0.21674 0.761033 0.277472 0.848386 0.342987C1.02487 0.475354 1.22342 0.541537 1.44404 0.541537C1.66465 0.541537 1.8632 0.475354 2.03968 0.342987C2.12704 0.277472 2.18936 0.21674 2.22666 0.160791C2.33726 -0.00509761 2.56139 -0.0499248 2.72728 0.0606673C2.89317 0.171259 2.938 0.395395 2.8274 0.561284C2.74437 0.685834 2.6262 0.805602 2.47288 0.920586C2.16804 1.14922 1.82509 1.26354 1.44404 1.26354C1.06298 1.26354 0.720031 1.14922 0.415187 0.920586C0.261874 0.805602 0.143701 0.685834 0.0606673 0.561284Z"
                        fillRule="nonzero"
                        fill="rgb(26, 29, 37)"
                    />
                    <path
                        transform="matrix(1 0 0 1 7.90283 7.00024)"
                        d="M0.360999 0C0.560374 0 0.721999 0.161625 0.721999 0.360999C0.721999 0.560374 0.560374 0.721999 0.360999 0.721999C0.161625 0.721999 0 0.560374 0 0.360999C0 0.161625 0.161625 0 0.360999 0Z"
                        fillRule="nonzero"
                        fill="rgb(26, 29, 37)"
                    />
                </g>
                <defs>
                    <clipPath id={ids[2]}>
                        <path transform="matrix(1 0 0 1 13 13)" d="M0 0L8 0L8 8L0 8L0 0Z" fillRule="nonzero" />
                    </clipPath>
                </defs>
                <g clipPath={`url(#${ids[2]})`}>
                    <path
                        transform="matrix(1 0 0 1 13.8492 13.3638)"
                        d="M3.15045 0L6.3009 5.45674L0 5.45674L3.15045 0Z"
                        fillRule="nonzero"
                        fill="rgb(255, 81, 60)"
                    />
                    <path
                        transform="matrix(1 0 0 1 13.8492 15.1826)"
                        d="M3.15045 5.45674L6.3009 0L0 0L3.15045 5.45674Z"
                        fillRule="nonzero"
                        fill="rgb(255, 81, 60)"
                    />
                    <path
                        transform="matrix(1 0 0 1 13.4854 13)"
                        d="M6.97973 5.63869L3.82928 0.181946C3.81332 0.154295 3.79405 0.129181 3.77147 0.106604C3.74889 0.0840267 3.72378 0.0647561 3.69613 0.0487918C3.65435 0.0246715 3.60955 0.009463 3.56172 0.00316635C3.51389 -0.00313029 3.46668 -3.57991e-05 3.42008 0.0124498C3.37348 0.0249354 3.33105 0.0458617 3.29278 0.0752287C3.25451 0.104596 3.22331 0.140168 3.19919 0.181946L0.0487377 5.63869C0.0327733 5.66634 0.0206593 5.69559 0.0123956 5.72643C0.00413184 5.75727 -2.16831e-08 5.78865 0 5.82058C2.16831e-08 5.92103 0.0355165 6.00678 0.10655 6.07781C0.177583 6.14885 0.263327 6.18436 0.363783 6.18436L6.66469 6.18436C6.69661 6.18436 6.728 6.18023 6.75884 6.17197C6.78968 6.1637 6.81893 6.15159 6.84658 6.13562C6.93357 6.0854 6.99007 6.01177 7.01607 5.91473C7.04207 5.8177 7.02996 5.72569 6.97973 5.63869ZM3.51423 1.0914L6.0346 5.4568L0.993873 5.4568L3.51423 1.0914Z"
                        fillRule="evenodd"
                        fill="rgb(255, 81, 60)"
                    />
                    <path
                        transform="matrix(1 0 0 1 13.4854 14.8188)"
                        d="M3.69613 6.13557C3.72378 6.11961 3.74889 6.10034 3.77147 6.07776C3.79405 6.05518 3.81332 6.03007 3.82928 6.00242L6.97973 0.545674C7.02996 0.458677 7.04207 0.366662 7.01607 0.269629C6.99007 0.172596 6.93357 0.0989656 6.84658 0.0487376C6.81893 0.0327733 6.78968 0.0206593 6.75884 0.0123956C6.728 0.00413185 6.69661 0 6.66469 0L0.363783 3.25247e-08C0.263327 5.42079e-08 0.177583 0.0355166 0.10655 0.10655C0.0355165 0.177583 2.16831e-08 0.263327 0 0.363783C-2.16831e-08 0.395711 0.00413184 0.427096 0.0123956 0.457937C0.0206593 0.488777 0.0327733 0.518023 0.0487377 0.545674L3.19919 6.00242C3.24942 6.08941 3.32305 6.14591 3.42008 6.17191C3.51711 6.19791 3.60913 6.1858 3.69613 6.13557ZM3.51423 5.09296L0.993873 0.727566L6.0346 0.727566L3.51423 5.09296Z"
                        fillRule="evenodd"
                        fill="rgb(255, 81, 60)"
                    />
                </g>
                <defs>
                    <clipPath id={ids[3]}>
                        <path transform="matrix(1 0 0 1 3 13)" d="M0 0L8 0L8 8L0 8L0 0Z" fillRule="nonzero" />
                    </clipPath>
                </defs>
                <g clipPath={`url(#${ids[3]})`}>
                    <path
                        transform="matrix(1 0 0 1 3.58276 13.6421)"
                        d="M2.11366 0C2.00432 0.338976 1.94526 0.70054 1.94526 1.07588C1.94526 3.00968 3.51293 4.57735 5.44673 4.57735C5.93978 4.57735 6.40902 4.47546 6.83454 4.29155C6.38058 5.69899 5.05994 6.71714 3.50147 6.71714C1.56767 6.71714 0 5.14947 0 3.21567C0 1.77491 0.870193 0.537371 2.11366 0Z"
                        fillRule="nonzero"
                        fill="rgb(235, 177, 0)"
                    />
                    <path
                        transform="matrix(1 0 0 1 3.19373 13.2517)"
                        d="M2.72337 1.46615C2.72337 1.13773 2.77324 0.818921 2.87298 0.509705C2.88747 0.464791 2.89358 0.418764 2.89132 0.371624C2.88906 0.324485 2.87857 0.279255 2.85985 0.235933C2.81723 0.137315 2.74598 0.068206 2.64611 0.0286077C2.54624 -0.0109906 2.447 -0.00948044 2.34838 0.0331381C1.64695 0.336265 1.08295 0.806142 0.656378 1.44277C0.218793 2.09583 3.4784e-07 2.81689 0 3.60594C9.27573e-08 4.13345 0.101966 4.63827 0.305897 5.12042C0.502789 5.58592 0.78066 5.9981 1.13951 6.35695C1.49836 6.7158 1.91054 6.99367 2.37604 7.19056C2.85819 7.3945 3.36302 7.49646 3.89053 7.49646C4.74608 7.49646 5.5168 7.24269 6.20267 6.73514C6.87468 6.23785 7.33841 5.59322 7.59386 4.80125C7.60835 4.75634 7.61446 4.71031 7.6122 4.66317C7.60994 4.61603 7.59945 4.5708 7.58072 4.52748C7.56026 4.48012 7.53151 4.43848 7.49447 4.40256C7.45744 4.36664 7.41495 4.33917 7.36699 4.32016C7.31903 4.30114 7.26925 4.29203 7.21767 4.29281C7.16608 4.2936 7.11661 4.30423 7.06925 4.3247C6.67764 4.49394 6.26649 4.57857 5.83579 4.57857C5.41347 4.57857 5.00968 4.49709 4.62442 4.33414C4.2521 4.17666 3.92228 3.95427 3.63498 3.66696C3.34767 3.37965 3.12528 3.04984 2.9678 2.67752C2.80484 2.29226 2.72337 1.88847 2.72337 1.46615ZM1.95677 1.16335C1.9491 1.26357 1.94526 1.3645 1.94526 1.46615C1.94526 1.99366 2.04723 2.49849 2.25116 2.98063C2.44805 3.44614 2.72592 3.85831 3.08477 4.21716C3.44362 4.57601 3.8558 4.85388 4.3213 5.05077C4.80345 5.25471 5.30828 5.35667 5.83579 5.35667C6.06371 5.35667 6.28734 5.33766 6.50667 5.29964C6.30702 5.60909 6.0514 5.8791 5.73982 6.10967C5.19145 6.51546 4.57502 6.71836 3.89053 6.71836C3.46821 6.71836 3.06442 6.63688 2.67915 6.47393C2.30683 6.31645 1.97702 6.09406 1.68971 5.80675C1.40241 5.51944 1.18001 5.18963 1.02254 4.81731C0.859582 4.43204 0.778105 4.02825 0.778105 3.60594C0.778105 2.97461 0.953 2.39793 1.30279 1.8759C1.48836 1.59894 1.70635 1.36143 1.95677 1.16335Z"
                        fillRule="evenodd"
                        fill="rgb(235, 177, 0)"
                    />
                </g>
                <defs>
                    <clipPath id={ids[4]}>
                        <path transform="matrix(1 0 0 1 13 3)" d="M0 0L8 0L8 8L0 8L0 0Z" fillRule="nonzero" />
                    </clipPath>
                </defs>
                <g clipPath={`url(#${ids[4]})`}>
                    <path
                        transform="matrix(1 0 0 1 14.4543 3.18164)"
                        d="M2.54548 0C1.13965 0 0 1.13965 0 2.54548L0 6.18188L5.09096 6.18188L5.09096 2.54548C5.09096 1.13965 3.95131 0 2.54548 0Z"
                        fillRule="nonzero"
                        fill="rgb(14, 202, 57)"
                    />
                    <path
                        transform="matrix(1 0 0 1 12.9999 3.20972)"
                        d="M1.09092 2.90912L1.09092 5.38696L0.36364 5.38696C0.162809 5.38696 0 5.59859 0 5.79943C0 6.00026 0.162809 6.25096 0.36364 6.25096L7.63644 6.25096C7.83727 6.25096 8.00008 6.00026 8.00008 5.79943C8.00008 5.59859 7.83727 5.38696 7.63644 5.38696L6.90916 5.38696L6.90916 2.90912C6.90916 2.51471 6.8329 2.13722 6.6804 1.77665C6.53317 1.42856 6.3254 1.12037 6.0571 0.852061C5.78879 0.583756 5.4806 0.37599 5.13251 0.228762C4.77194 0.076254 4.39445 0 4.00004 0C3.60563 2.98023e-08 3.22814 0.0762539 2.86757 0.228762C2.51948 0.375989 2.21129 0.583756 1.94298 0.852061C1.67468 1.12036 1.46691 1.42856 1.31968 1.77665C1.16717 2.13722 1.09092 2.51471 1.09092 2.90912ZM6.18188 5.38696L6.18188 2.90912C6.18188 2.30662 5.96886 1.79236 5.54283 1.36633C5.1168 0.940295 4.60254 0.72728 4.00004 0.72728C3.39754 0.72728 2.88327 0.940295 2.45724 1.36633C2.03121 1.79236 1.8182 2.30662 1.8182 2.90912L1.8182 5.38696L6.18188 5.38696Z"
                        fillRule="evenodd"
                        fill="rgb(14, 202, 57)"
                    />
                    <path
                        transform="matrix(1 0 0 1 16.0907 9.36353)"
                        d="M0.9091 1.09092C1.41118 1.09092 1.8182 0.683897 1.8182 0.18182L1.8182 0L0 0L0 0.18182C0 0.683897 0.407022 1.09092 0.9091 1.09092Z"
                        fillRule="nonzero"
                        fill="rgb(14, 202, 57)"
                    />
                    <path
                        transform="matrix(1 0 0 1 15.7272 9)"
                        d="M0.372778 1.44542C0.621297 1.69394 0.921285 1.8182 1.27274 1.8182C1.62419 1.8182 1.92418 1.69394 2.1727 1.44542C2.42122 1.1969 2.54548 0.896915 2.54548 0.54546L2.54548 0.36364C2.54548 0.263224 2.50998 0.177513 2.43897 0.106508C2.36797 0.0355026 2.28226 2.16746e-08 2.18184 0L0.36364 0C0.263224 2.16746e-08 0.177513 0.0355026 0.106508 0.106508C0.0355026 0.177513 2.16746e-08 0.263224 0 0.36364L0 0.54546C0 0.896915 0.124259 1.1969 0.372778 1.44542ZM1.65844 0.931157C1.71921 0.87038 1.76265 0.802421 1.78875 0.72728L0.756734 0.72728C0.78283 0.802421 0.826266 0.87038 0.887043 0.931157C0.993552 1.03767 1.12212 1.09092 1.27274 1.09092C1.42336 1.09092 1.55193 1.03767 1.65844 0.931157Z"
                        fillRule="evenodd"
                        fill="rgb(14, 202, 57)"
                    />
                </g>
            </g>
        </svg>
    )
})
