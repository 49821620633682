import classNames from 'classnames'
import { WKDivider } from '../../../../../../../ui-lib/src/components/divider/internal/wk-divider'
import { featureSwitchManager } from '../../../../../kernel/switch'
import styles from './index.module.less'

const Block = ({ width, transparent, className }: { className?: string; width?: number; transparent?: boolean }) => {
    return (
        <div
            style={{ background: 'var(--wk-gray-3)', width, opacity: +!transparent }}
            className={classNames(['h-4 w-4 inline-block mr-3 rounded-sm', className])}
        />
    )
}

export const SideBar = () => {
    const flag = featureSwitchManager.isEnabled('organization-plus')
    return (
        <div
            className={classNames(
                'box-border bg-$wk-v2-fill-color-gray-1 flex flex-col px-3 shrink-0 relative',
                flag ? 'w-264px' : 'w-248px'
            )}
        >
            {/* mask */}
            <div
                className="absolute inset-0 top-50 z-1"
                style={{
                    background:
                        'linear-gradient(179.99998deg, rgba(249, 249, 250, 0.00) 0%, rgba(249, 249, 250, 0.93) 50%, #F9F9FA 100%)',
                }}
            />

            <div className={styles['fade-in']}>
                <div className="pt-26px pb-26px px-2 flex items-center">
                    <Block className="m-0 h-5" width={108} />
                </div>

                <WKDivider className="border-$wk-gray-3!" />

                <div className="flex-1 flex flex-col">
                    <div className="py-5 px-3 flex flex-col gap-4.5">
                        <div className="flex gap-10px">
                            <Block className="m-0" width={16} />
                            <Block className="m-0" width={120} />
                        </div>
                        <div className="flex gap-10px">
                            <Block className="m-0" width={16} />
                            <Block className="m-0" width={80} />
                        </div>
                        <div className="flex gap-10px">
                            <Block className="m-0" width={16} />
                            <Block className="m-0" width={120} />
                        </div>
                    </div>

                    <WKDivider className="border-$wk-gray-3!" />

                    {flag && (
                        <>
                            <div className="py-5 px-3 flex flex-col gap-4.5">
                                <div className="flex gap-10px">
                                    <Block className="m-0" width={72} />
                                </div>
                                <div className="flex gap-10px">
                                    <Block className="m-0" width={16} />
                                    <Block className="m-0" width={98} />
                                </div>
                                <div className="flex gap-10px">
                                    <Block className="m-0" width={16} />
                                    <Block className="m-0" width={53} />
                                </div>
                            </div>

                            <WKDivider className="border-$wk-gray-3!" />
                        </>
                    )}
                    <div className="flex flex-col">
                        <div className="py-22px px-3 flex flex-col gap-5">
                            <div className="flex gap-10px">
                                <Block className="m-0" width={16} />
                                <Block className="m-0" width={100} />
                            </div>
                            <div className="flex gap-10px">
                                <Block className="m-0" transparent width={16} />
                                <Block className="m-0" width={72} />
                            </div>
                            <div className="flex gap-10px">
                                <Block className="m-0" transparent width={16} />
                                <Block className="m-0" width={120} />
                            </div>
                        </div>
                    </div>

                    <WKDivider className="border-$wk-gray-3!" />

                    <div className="flex flex-col">
                        <div className="py-22px px-3 flex flex-col gap-5">
                            <div className="flex gap-10px">
                                <Block className="m-0" width={16} />
                                <Block className="m-0" width={100} />
                            </div>
                            <div className="flex gap-10px">
                                <Block className="m-0" transparent width={16} />
                                <Block className="m-0" width={72} />
                            </div>
                            <div className="flex gap-10px">
                                <Block className="m-0" transparent width={16} />
                                <Block className="m-0" width={120} />
                            </div>
                            <div className="flex gap-10px">
                                <Block className="m-0" transparent width={16} />
                                <Block className="m-0" width={72} />
                            </div>
                            <div className="flex gap-10px">
                                <Block className="m-0" transparent width={16} />
                                <Block className="m-0" width={120} />
                            </div>
                            <div className="flex gap-10px">
                                <Block className="m-0" transparent width={16} />
                                <Block className="m-0" width={72} />
                            </div>
                            <div className="flex gap-10px">
                                <Block className="m-0" transparent width={16} />
                                <Block className="m-0" width={120} />
                            </div>
                            <div className="flex gap-10px">
                                <Block className="m-0" transparent width={16} />
                                <Block className="m-0" width={72} />
                            </div>
                            <div className="flex gap-10px">
                                <Block className="m-0" transparent width={16} />
                                <Block className="m-0" width={120} />
                            </div>
                            <div className="flex gap-10px">
                                <Block className="m-0" transparent width={16} />
                                <Block className="m-0" width={72} />
                            </div>
                            <div className="flex gap-10px">
                                <Block className="m-0" transparent width={16} />
                                <Block className="m-0" width={120} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
