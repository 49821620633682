import QOI from '../third-party/qoi'

export function compressBitmapSync(data: Uint8Array, width: number, height: number) {
    const buf = QOI.encode(data, {
        width,
        height,
        channels: 4,
        colorspace: 0,
    })
    return new Uint8Array(buf)
}

export function decompressBitmapSync(data: Uint8Array): Uint8Array {
    const ret = QOI.decode(data, data.byteOffset, data.byteLength)
    return ret.data
}
