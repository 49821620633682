import { translation } from './index.translation'
/* eslint-disable no-restricted-imports */
import { OneInputModal, WKToast } from '../../../../../../../ui-lib/src'
import { useUserInfoContext } from '../../../../../auth'
import { UserVOV2 } from '../../../../../kernel/interface/account'
import { UpdateUserProfile } from '../../../../../kernel/request/user'
import { AccountManage } from '../../../frog-task/account-manage'

export const NicknameDialog = ({ user, closeNickName }: { user: UserVOV2; closeNickName: () => void }) => {
    const { updateUserInfo } = useUserInfoContext()
    return (
        <OneInputModal
            key={user.userBrief.nickname}
            initialValue={user.userBrief.nickname}
            onCancel={closeNickName}
            onSubmit={async (nickname) => {
                await new UpdateUserProfile({ nickname }).start()
                closeNickName()
                AccountManage.changeNameSuccess()
                WKToast.show(translation('NameChanged'))
                updateUserInfo()
            }}
            lowerCaseName={translation('lowerCaseName')}
            title={translation('ChangeName')}
            valueLabel={translation('Name')}
            maxCharCodeLength={32}
            visible={true}
        />
    )
}
