import { domLocation, RouteToken } from '../../../../util/src'
import { GetExpireTime, LogoutRequest } from '../request/account'

const PERIOD_DAY = 864000000
export async function refreshWhenExpiredLogin(advanceDetect: boolean) {
    if (await isLoginExpired(advanceDetect)) {
        reLogin()
    }
}

async function getExpireTime() {
    const res = new GetExpireTime()
    try {
        await res.start()
        return res.response?.data.cookieExpireTime
    } catch (error) {
        if (res.responseStatusCode === 401) {
            return Date.now() - 10 // 当发生401时，弹出弹窗，在此给出一个过期时间
        } else {
            return undefined // 其他错误（如离线时请求）不处理
        }
    }
}

function expireDetectByTime(expireTime: string, advanceDetect: boolean) {
    const currentTime = new Date()
    return (
        (advanceDetect && Number(expireTime) - Number(currentTime) < PERIOD_DAY) ||
        (!advanceDetect && Number(expireTime) < Number(currentTime))
    )
}

export async function isLoginExpired(advanceDetect: boolean) {
    const expireTime = await getExpireTime()
    if (expireTime && expireDetectByTime(expireTime, advanceDetect)) {
        return true
    }

    return false
}

export async function reLogin() {
    const currentUrl = domLocation().href
    const loginToken = RouteToken.LoginDoNotUse
    if (currentUrl.includes(`/${loginToken}`)) {
        return
    }

    const targetUrl = getReLoginURL()

    // 如果在 快过期（距离过期不超过10天） / 过期时请求，那么需要logout，(没过期且距离过期不超过10天) 不需要 logout
    if ((await isLoginExpired(true)) || (await isLoginExpired(false))) {
        await new LogoutRequest().start()
        window.localBridge?.editorLogout()
    }
    domLocation().replace(targetUrl)
}

export function getReLoginURL() {
    const origin = domLocation().origin
    const ref = domLocation().pathname

    return origin + '/' + RouteToken.LoginDoNotUse + '?referer=' + ref
}
