import { FeatureSwitchConfig, SwitchEnvironmentScopeType, SwitchStrategyType } from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'prototype-switch-device-frame',
    owner: '',
    description: '',
    strategies: [
        {
            env: SwitchEnvironmentScopeType.TESTING,
            config: {
                type: SwitchStrategyType.ALL_USER,
            },
        },
        {
            env: SwitchEnvironmentScopeType.PRODUCTION,
            config: {
                type: SwitchStrategyType.SPECIFIC_SUFFIX,
                suffix: ['@kanyun.com'],
            },
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
