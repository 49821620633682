import { DELETE, GET, POST, PUT } from '@tutor/network-core'
import type { DocID, DocPrototypeVO, DocWithAuthorityVO, FolderID, OrgID } from '../interface/type'
import { omitByValue } from '../util/omit-by-value'
import { BaseCommonRequest } from './base-request'

export const batchAddFavoriteDocs = async (data: { id: string; isPrototype: boolean }[], orgId: string) => {
    const prototypeIds = data.filter((o) => o.isPrototype).map((o) => o.id)
    const docIds = data.filter((o) => !o.isPrototype).map((o) => o.id)
    await Promise.all([
        prototypeIds.length ? new BatchAddFavoritePrototypeRequest(prototypeIds).start() : undefined,
        docIds.length ? new BatchAddFavoriteDocRequest(docIds).start() : undefined,
    ])
    // 收藏文件之后，需要给新收藏的文件放到最上面
    const oldPreference = await new GetFavoritePreferenceRequest(orgId).start()
    const newPreference = data.map((o) => ({
        type: o.isPrototype ? ('DOC_PROTOTYPE' as const) : ('DOC' as const),
        id: o.id,
    }))
    await new UpdateFavoritePreferenceRequest({
        orgId,
        list: [...newPreference, ...oldPreference],
    }).start()
}

export const batchRemoveFavoriteDocs = async (data: { id: string; isPrototype: boolean }[], orgId: string) => {
    const prototypeIds = data.filter((o) => o.isPrototype).map((o) => o.id)
    const docIds = data.filter((o) => !o.isPrototype).map((o) => o.id)
    await Promise.all([
        prototypeIds.length ? new BatchRemoveFavoritePrototypeRequest(prototypeIds).start() : undefined,
        docIds.length ? new BatchRemoveFavoriteDocRequest(docIds).start() : undefined,
    ])
    const oldPreference = await new GetFavoritePreferenceRequest(orgId).start()
    const newPreference = oldPreference.filter((o) => !data.some((d) => d.id === o.id))
    await new UpdateFavoritePreferenceRequest({
        orgId,
        list: [...newPreference],
    }).start()
}
// 批量收藏文档
@POST
class BatchAddFavoriteDocRequest extends BaseCommonRequest {
    constructor(private readonly docIds: DocID[]) {
        super()
    }

    public override requestUrl() {
        return 'favorites/docs/batch'
    }

    public override requestBody() {
        return this.docIds
    }
}

// 批量移除收藏文档
@DELETE
class BatchRemoveFavoriteDocRequest extends BaseCommonRequest {
    constructor(private readonly docIds: DocID[]) {
        super()
    }

    public override requestUrl() {
        return 'favorites/docs/batch'
    }

    public override requestBody() {
        return this.docIds
    }
}

// 获取用户的收藏文档列表
@GET
export class GetUserFavoriteDocRequest extends BaseCommonRequest<
    { document: DocWithAuthorityVO; favoriteTime: number }[]
> {
    constructor(private readonly orgId?: OrgID) {
        super()
    }
    public override requestUrl() {
        return 'favorites/docs'
    }
    public override requestArgument() {
        return omitByValue({
            orgId: this.orgId,
        })
    }
}

// 收藏一个项目
@POST
export class AddFavoriteFolderRequest extends BaseCommonRequest {
    constructor(private readonly folderId: FolderID) {
        super()
    }

    public override requestUrl() {
        return `favorites/folders/${this.folderId}`
    }
}

// 移除收藏项目
@DELETE
export class RemoveFavoriteFolderRequest extends BaseCommonRequest {
    constructor(private readonly folderId: FolderID) {
        super()
    }

    public override requestUrl() {
        return `favorites/folders/${this.folderId}`
    }
}

// 获取用户的收藏原型文档列表
@GET
export class GetUserFavoritePrototypeRequest extends BaseCommonRequest<
    { favoriteTime: number; prototype: DocPrototypeVO }[]
> {
    constructor(private readonly orgId?: OrgID) {
        super()
    }
    public override requestUrl() {
        return 'favorites/prototypes'
    }
    public override requestArgument() {
        return omitByValue({
            orgId: this.orgId,
        })
    }
    // TODO: prototype 服务端还没实现这个接口
    public override requestDidFailed(): boolean {
        return false
    }
}

// 批量收藏原型文档
@POST
class BatchAddFavoritePrototypeRequest extends BaseCommonRequest {
    constructor(private readonly prototypeIds: string[]) {
        super()
    }

    public override requestUrl() {
        return 'favorites/prototypes/batch'
    }

    public override requestBody() {
        return this.prototypeIds
    }
}

// 批量移除收藏原型文档
@DELETE
class BatchRemoveFavoritePrototypeRequest extends BaseCommonRequest {
    constructor(private readonly prototypeIds: string[]) {
        super()
    }

    public override requestUrl() {
        return 'favorites/prototypes/batch'
    }

    public override requestBody() {
        return this.prototypeIds
    }
}

@PUT
export class UpdateFavoritePreferenceRequest extends BaseCommonRequest {
    constructor(
        private readonly param: {
            orgId: string
            list: {
                type: 'DOC' | 'FOLDER' | 'DOC_PROTOTYPE'
                id: string
            }[]
        }
    ) {
        super()
    }

    public override requestUrl() {
        return 'favorites/preference'
    }

    public override requestBody() {
        return this.param
    }
}

@GET
export class GetFavoritePreferenceRequest extends BaseCommonRequest<
    {
        type: 'DOC' | 'FOLDER' | 'DOC_PROTOTYPE'
        id: string
    }[]
> {
    constructor(private readonly orgId: string) {
        super()
    }

    public override requestUrl() {
        return 'favorites/preference'
    }

    public override requestArgument() {
        return { orgId: this.orgId }
    }
}
