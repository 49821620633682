/* eslint-disable no-restricted-imports */
import { GetSearchedSortedListCommand } from '@wukong/bridge-proto'
import { CommandInvoker } from '../../document/command/command-invoker'

// 匹配 & 策略排序
export function searchMatchedSorted<T>(
    command: CommandInvoker,
    targetList: T[],
    keyword: string,
    getTargetStringFn: (item: T) => string
): T[] {
    if (!keyword.replace(/\s/g, '').length) {
        return targetList
    }

    if (!targetList.length) {
        return []
    }

    return (
        command
            .DEPRECATED_invokeBridge(GetSearchedSortedListCommand, {
                targetList: targetList.map((item) => getTargetStringFn(item)),
                keyword,
            })
            .sortedOriginalList?.map((index) => targetList[index]) ?? []
    )
}

// 获取匹配的数组
export function searchMatchedFilter<T>(targetList: T[], keyword: string, getTargetStringFn: (item: T) => string): T[] {
    if (!keyword) {
        return targetList
    }

    return targetList.filter((item) => isSearchMatched(getTargetStringFn(item), keyword))
}

// 字符串是否匹配
export function isSearchMatched(targetString: string, keyword: string): boolean {
    const formatKeyword = keyword.toLocaleLowerCase().replace(/\s/g, '')
    const formatTargetString = targetString.toLocaleLowerCase().replace(/\s/g, '')
    return formatTargetString.includes(formatKeyword)
}
