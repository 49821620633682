import { translation } from './canvas-search-result.translation'
import { CanvasSearchResultHeader } from './canvas-search-result-header'
import { CanvasSearchResultList } from './canvas-search-result-list'
import styles from './canvas-search-result.module.less'
import { CanvasSearchParamModel, CanvasSearchResultModel } from './use-canvas-search-model'

interface ModelProps {
    canvasSearchParamModel: CanvasSearchParamModel
    canvasSearchResultModel: CanvasSearchResultModel
}

export function CanvasSearchResult({ canvasSearchParamModel, canvasSearchResultModel }: ModelProps) {
    if (!canvasSearchParamModel.modelState.value) {
        return null
    }

    if (!canvasSearchResultModel.modelState?.totalCount && !canvasSearchResultModel.modelState?.hasResultOnOtherPages) {
        return <div className={styles.empty}>{translation('NoResultsIn')}</div>
    }

    return (
        <div className={styles.root} data-testid="canvas-search-result">
            <CanvasSearchResultHeader
                canvasSearchParamModel={canvasSearchParamModel}
                canvasSearchResultModel={canvasSearchResultModel}
            />
            <CanvasSearchResultList
                canvasSearchParamModel={canvasSearchParamModel}
                canvasSearchResultModel={canvasSearchResultModel}
            />
        </div>
    )
}
