import { useEffect, useState } from 'react'
import { WKButtonProps, wkDialogConfirm } from '../../../../../../ui-lib/src'
import { RoleStatus } from '../../../../kernel/interface/type'
import { usePlanAndUserStateService } from '../../../../main/app-context'
import { BatchMoveDocModal } from '../../../../share/batch-move-doc-modal'
import { useDocInfoContext } from '../../../context/top-area-context'
import { translation } from './index.translation'

/**
 * 非组织下的草稿、在进入研发模式时会出现此弹窗。
 */

export function DevModeBlockedModal() {
    const planAndUserStateService = usePlanAndUserStateService()
    const showDevModeBlockedModal = planAndUserStateService.useZustandStore.use.showDevModeBlockedModal()

    const setShowDevModeBlockedModal = planAndUserStateService.setShowDevModeBlockedModal
    const [visible, setVisible] = useState<boolean>(false)
    const { docData, getCurrentDocOrganization } = useDocInfoContext()
    const isDocOwner = docData?.role === RoleStatus.Owner

    useEffect(() => {
        showDevModeBlockedModal && devModeDialogConfirm(isDocOwner, setVisible, setShowDevModeBlockedModal)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDevModeBlockedModal, isDocOwner])

    return docData && visible ? (
        <BatchMoveDocModal
            docs={[docData]}
            forceHideTipBanner={true}
            organization={getCurrentDocOrganization()!}
            onClose={() => setVisible(false)}
        />
    ) : null
}

export function devModeDialogConfirm(
    isOwner: boolean,
    setVisible: (visible: boolean) => void,
    setShowDevModeBlockedModal?: (value: boolean) => void
) {
    if (isOwner) {
        wkDialogConfirm.info({
            title: translation('MoveToTeamTitle'),
            content: translation('MoveToTeamContent'),
            okText: translation('Move'),
            cancelText: translation('Cancel'),
            onOk: () => {
                setVisible(true)
                setShowDevModeBlockedModal?.(false)
            },
            onClose: () => {
                setVisible(false)
                setShowDevModeBlockedModal?.(false)
            },
            closable: true,
            okButtonProps: {
                'data-testid': 'dev-mode-confirm-move-file',
            } as Partial<WKButtonProps>,
            cancelButtonProps: {
                'data-testid': 'dev-mode-cancel-move-file',
            } as Partial<WKButtonProps>,
        })
    } else {
        wkDialogConfirm.info({
            title: translation('CanNotUseDevModeTitle'),
            content: translation('CanNotUseDevModeContent'),
            okText: translation('IKnow'),
            onOk: () => {
                setVisible(false)
                setShowDevModeBlockedModal?.(false)
            },
            onClose: () => {
                setVisible(false)
                setShowDevModeBlockedModal?.(false)
            },
            cancelButtonProps: { style: { display: 'none' } },
            closable: true,
        })
    }
}
