import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    visible: '可见',
    hidden: '不可见',
    WorkspaceVisibilityChanged: '已将 {{count}} 个工作区设为{{visibility}}。',
} as const

export const enTranslation = {
    visible: 'visible',
    hidden: 'hidden',
    WorkspaceVisibilityChanged: '{{count}} workspace{{s}} {{hasHave}} been set to {{visibility}}.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
