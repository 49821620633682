import { MotiffAutoSaveSchema } from '../../../web-storage/indexed-db/config/schema'
import { translation } from './auto-save-service.translation'
/* eslint-disable no-restricted-imports */
import { IDBPTransaction, StoreNames } from 'idb'
import { uniq } from 'lodash-es'
import { wkDialogConfirm } from '../../../../../ui-lib/src'
import { domLocation } from '../../../../../util/src'
import { WkCLog } from '../../../kernel/clog/wukong/instance'
import { debugLog } from '../../../kernel/debug'
import { MetricCollector, MetricName } from '../../../kernel/metric-collector'
import {
    BLOB_CHANGES_STORE,
    DB_NAME,
    NODE_CHANGES_STORE,
    OFFLINE_SESSIONS_INDEX,
    OFFLINE_SESSIONS_STORE,
    REFERENCED_NODES_STORE,
    openAutoSavedDbWithRetry,
} from './auto-save-db-v2'
import { AutoSaveManager, OfflineOperations, OfflineOperationsOfSession, OfflineSession } from './auto-save-manager'

const LOWER_STR_KEY = ''
const UPPER_STR_KEY = '\uffff'
const LOWER_NUM_KEY = 0
const UPPER_NUM_KEY = Number.MAX_VALUE
const ADD_OFFLINE_DATA_FAILED_MSG = translation('zinPLC')

interface Lock {
    release: () => Promise<void>
}

export async function getAllUnSyncedDocs(userId: number) {
    const db = await openAutoSavedDbWithRetry()
    const transaction = db.transaction([OFFLINE_SESSIONS_STORE], 'readonly')
    const offlineSession2Lock = await getAllUnSyncedofflineSessions(
        transaction,
        IDBKeyRange.bound([userId, LOWER_STR_KEY, LOWER_NUM_KEY], [userId, UPPER_STR_KEY, UPPER_NUM_KEY])
    )
    Array.from(offlineSession2Lock.values()).forEach(async (lock) => {
        await lock.release()
    })
    return uniq(Array.from(offlineSession2Lock.keys()).map((session) => session.docId))
}

export class AutoSaveService implements AutoSaveManager {
    private currentofflineSessionId: number | null = null
    private holdingOfflineSessionId2Lock: Map<number, Lock> = new Map()
    private unsyncedNodeChangesSizeTotal = 0
    private unsyncedBlobChangesSizeTotal = 0

    /**
     * 标记当前是否正在保存或者清理 offline-session 数据
     *
     * 在保存离线数据时, 根据 currentofflineSessionId == null 来判断是该新建 offline-session 还是该更新.
     * currentofflineSessionId 在新建完成后被赋值 (微任务)
     *
     * 因此, 当连续执行保存离线数据方法时可能会由于微任务的延迟导致 currentofflineSessionId 还未被赋值, 从而导致多次新建 offline-session
     */
    private isSavingOfflineSession = false
    private isClearingOfflineSession = false

    async destroy() {
        if (this.hasUnfinishedOfflineSessionTask()) {
            WkCLog.log('do destroy with timeout due to has unfinished offlineSession task')
            setTimeout(async () => {
                await this.destroy()
            }, 0)
            return
        }
        WkCLog.log(
            `onDestroy, release all holding locks. locksCount=${this.holdingOfflineSessionId2Lock.size}. unsyncedNodeChangesSizeTotal: ${this.unsyncedNodeChangesSizeTotal}, unsyncedBlobChangesSizeTotal: ${this.unsyncedBlobChangesSizeTotal}`
        )
        this.resetUnsyncedDataInfo()
        await this.releaseAllHoldingLocksAndOfflineSessions()
    }

    async getAllOfflineOperations(docId: string, userId: number): Promise<Array<OfflineOperationsOfSession>> {
        const db = await openAutoSavedDbWithRetry()
        const offlineSessionTx = db.transaction([OFFLINE_SESSIONS_STORE], 'readonly')

        const offlineSession2Lock = await getAllUnSyncedofflineSessions(
            offlineSessionTx,
            this.getSessionStoreKeyRangeByUserIdAndDocId(userId, docId)
        )

        if (offlineSession2Lock.size == 0) {
            return []
        }

        offlineSession2Lock.forEach((lock, offlineSession) => {
            this.holdingOfflineSessionId2Lock.set(offlineSession.id!, lock)
        })

        debugLog(
            `onGetAllOfflineOperations AllOfflineSessions: ${JSON.stringify(Array.from(offlineSession2Lock.keys()))}`
        )

        const changedDataTx = db.transaction(
            [NODE_CHANGES_STORE, BLOB_CHANGES_STORE, REFERENCED_NODES_STORE],
            'readonly'
        )
        const nodeChangesStore = changedDataTx.objectStore(NODE_CHANGES_STORE)
        const blobChangesStore = changedDataTx.objectStore(BLOB_CHANGES_STORE)
        const referenceNodesStore = changedDataTx.objectStore(REFERENCED_NODES_STORE)

        const requests = Array.from(offlineSession2Lock.keys()).map(async (offlineSession) => {
            const nodeChangesPromise = nodeChangesStore.getAll(
                this.getChangeStoreKeyRangeByOfflineSessionId(offlineSession.id!)
            )
            const blobChangesPromise = blobChangesStore.getAll(
                this.getChangeStoreKeyRangeByOfflineSessionId(offlineSession.id!)
            )
            const refNodesPromise = referenceNodesStore.getAll(
                this.getChangeStoreKeyRangeByOfflineSessionId(offlineSession.id!)
            )

            const [nodeChanges, blobs, refNodes] = await Promise.all([
                nodeChangesPromise,
                blobChangesPromise,
                refNodesPromise,
            ])
            const opMap = nodeChanges.reduce((map, row) => {
                map[row.nodeId] = row.data
                this.unsyncedNodeChangesSizeTotal += row.data.length
                return map
            }, {} as Record<string, Uint8Array>)
            const blobMap = blobs.reduce((map, row) => {
                map[row.blobId] = row.data
                this.unsyncedBlobChangesSizeTotal += row.data.length
                return map
            }, {} as Record<string, Uint8Array>)
            const refNodesMap = refNodes.reduce((map, row) => {
                map[row.nodeId] = row.data
                return map
            }, {} as Record<string, Uint8Array>)
            return {
                offlineSession,
                offlineOperations: { operations: opMap, blobs: blobMap, refNodes: refNodesMap },
            }
        })

        const result = (await Promise.all(requests)).sort((a, b) => {
            return a.offlineSession.lastUpdatedAt! - b.offlineSession.lastUpdatedAt!
        })

        WkCLog.log(
            `getAllOfflineOperations finished, offlineSessionCount = ${offlineSession2Lock.size}, nodeChangesSize: ${this.unsyncedNodeChangesSizeTotal}, blobChangesSize: ${this.unsyncedBlobChangesSizeTotal}`
        )
        return result
    }

    async clearHoldingOfflineOperations(firstOpenDoc = true) {
        this.isClearingOfflineSession = true
        const db = await openAutoSavedDbWithRetry()
        const transaction = db.transaction(
            [OFFLINE_SESSIONS_STORE, NODE_CHANGES_STORE, BLOB_CHANGES_STORE, REFERENCED_NODES_STORE],
            'readwrite'
        )
        const offlineSessionsStore = transaction.objectStore(OFFLINE_SESSIONS_STORE)
        const nodeChangesStore = transaction.objectStore(NODE_CHANGES_STORE)
        const blobChangesStore = transaction.objectStore(BLOB_CHANGES_STORE)
        const referenceNodesStore = transaction.objectStore(REFERENCED_NODES_STORE)

        const promises = Array.from(this.holdingOfflineSessionId2Lock.keys()).map(async (offlineSessionId) => {
            debugLog(`try to delete offline data, offlineSessionId: ${offlineSessionId}`)
            await Promise.all([
                nodeChangesStore.delete(this.getChangeStoreKeyRangeByOfflineSessionId(offlineSessionId)),
                blobChangesStore.delete(this.getChangeStoreKeyRangeByOfflineSessionId(offlineSessionId)),
                offlineSessionsStore.delete(IDBKeyRange.only(offlineSessionId)),
                referenceNodesStore.delete(this.getChangeStoreKeyRangeByOfflineSessionId(offlineSessionId)),
            ])
        })
        MetricCollector.pushMetricToServer(MetricName.SYNERGY_CLEAR_OFFLINE_SESSION_COUNT, promises.length, {
            firstOpenDoc: firstOpenDoc,
        })
        await Promise.all(promises)
        WkCLog.log(
            `clearHoldingOfflineOperations finished, offlineSessionCount = ${this.holdingOfflineSessionId2Lock.size}, nodeChangesSize: ${this.unsyncedNodeChangesSizeTotal}, blobChangesSize: ${this.unsyncedBlobChangesSizeTotal}`
        )
        this.resetUnsyncedDataInfo()
        this.releaseAllHoldingLocksAndOfflineSessions()
        this.isClearingOfflineSession = false
    }

    hasUnfinishedOfflineSessionTask() {
        return this.isSavingOfflineSession || this.isClearingOfflineSession
    }

    async saveOfflineOperationsOfSession(offlineOperationsOfSession: OfflineOperationsOfSession) {
        const startTime = performance.now()
        const db = await openAutoSavedDbWithRetry().catch((e) => {
            WkCLog.log(`Failed to save operations to local db v2 cause of can not open ${DB_NAME}. ${e}`)
            this.pushMetricOnSaveOfflineData(offlineOperationsOfSession, startTime, false)
            this.popover(ADD_OFFLINE_DATA_FAILED_MSG)
            throw e
        })
        const transaction = db.transaction(
            [OFFLINE_SESSIONS_STORE, NODE_CHANGES_STORE, BLOB_CHANGES_STORE, REFERENCED_NODES_STORE],
            'readwrite'
        )

        if (this.hasUnfinishedOfflineSessionTask()) {
            WkCLog.log(`save respository with timeout due to has unfinished offlineSession task`)
            MetricCollector.pushMetricToServer(MetricName.SYNERGY_SAVE_OFFLINE_DATA_QUEUED_TIMES, 1)
            setTimeout(async () => {
                await this.saveOfflineOperationsOfSession(offlineOperationsOfSession)
            }, 0)
            return
        }
        try {
            if (this.currentofflineSessionId == null) {
                this.isSavingOfflineSession = true
                await this.addSessionAndSaveOfflineData(transaction, offlineOperationsOfSession)
                this.isSavingOfflineSession = false
                MetricCollector.pushMetricToServer(MetricName.SYNERGY_CREATE_OFFLINE_SESSION, 1)
            } else {
                await this.updateSessionAndSaveOfflineData(transaction, offlineOperationsOfSession)
                MetricCollector.pushMetricToServer(MetricName.SYNERGY_UPDATE_OFFLINE_SESSION, 1)
            }
        } catch (e) {
            WkCLog.log(
                `Failed to save operations to local db v2, offlineSession: ${JSON.stringify(
                    offlineOperationsOfSession.offlineSession
                )}. ${e}`
            )
            this.pushMetricOnSaveOfflineData(offlineOperationsOfSession, startTime, false)
            this.popover(ADD_OFFLINE_DATA_FAILED_MSG)
            throw e
        }

        this.pushMetricOnSaveOfflineData(offlineOperationsOfSession, startTime, true)
        debugLog(`saveOfflineOperationsOfSession finish`)
    }

    private async updateSessionAndSaveOfflineData(
        transaction: IDBPTransaction<MotiffAutoSaveSchema, ArrayLike<StoreNames<MotiffAutoSaveSchema>>, 'readwrite'>,
        offlineOperationsOfSession: OfflineOperationsOfSession
    ) {
        const offlineSession = offlineOperationsOfSession.offlineSession
        const offlineSessionsStore = transaction.objectStore(OFFLINE_SESSIONS_STORE)
        await offlineSessionsStore
            .put({
                id: this.currentofflineSessionId!,
                docId: offlineSession.docId,
                schemaVersion: offlineSession.schemaVersion,
                sid: offlineSession.sid,
                userId: offlineSession.userId,
                lastUpdatedAt: Date.now(),
                releaseTag: offlineSession.releaseTag,
            })
            .then(async () => {
                await this.saveOfflineDatasToDb(transaction, offlineOperationsOfSession.offlineOperations)
            })
    }

    private async addSessionAndSaveOfflineData(
        transaction: IDBPTransaction<MotiffAutoSaveSchema, ArrayLike<StoreNames<MotiffAutoSaveSchema>>, 'readwrite'>,
        offlineOperationsOfSession: OfflineOperationsOfSession
    ) {
        WkCLog.log(
            `currentofflineSessionId is null, add a new offlineSession: ${JSON.stringify(
                offlineOperationsOfSession?.offlineSession
            )} `
        )
        const offlineSession = offlineOperationsOfSession.offlineSession
        const offlineSessionsStore = transaction.objectStore(OFFLINE_SESSIONS_STORE)
        await offlineSessionsStore
            .add({
                docId: offlineSession.docId,
                schemaVersion: offlineSession.schemaVersion,
                sid: offlineSession.sid,
                userId: offlineSession.userId,
                lastUpdatedAt: Date.now(),
                releaseTag: offlineSession.releaseTag,
            })
            .then(async (id) => {
                WkCLog.log(`add offlineSession finished, id: ${id}`)
                this.currentofflineSessionId = id as number
                debugLog(`currentofflineSessionId: ${this.currentofflineSessionId}`)
                await this.saveOfflineDatasToDb(transaction, offlineOperationsOfSession.offlineOperations)
                const lock = await getAutoSaveSessionLock(
                    buildAutoSaveSessionLockKey(
                        offlineSession.userId,
                        offlineSession.docId,
                        this.currentofflineSessionId!
                    )
                )
                if (!lock) {
                    WkCLog.log(`Failed to get autoSaveSessionLock`)
                    throw new Error(`Failed to get autoSaveSessionLock`)
                }
                this.holdingOfflineSessionId2Lock.set(this.currentofflineSessionId!, lock)
            })
    }

    private async saveOfflineDatasToDb(
        transaction: IDBPTransaction<MotiffAutoSaveSchema, ArrayLike<StoreNames<MotiffAutoSaveSchema>>, 'readwrite'>,
        offlineDatas: OfflineOperations
    ) {
        let nodeChangesSize = 0
        let blobChangesSize = 0

        const nodeChangesStore = transaction.objectStore(NODE_CHANGES_STORE)
        const blobChangesStore = transaction.objectStore(BLOB_CHANGES_STORE)
        const referenceNodesStore = transaction.objectStore(REFERENCED_NODES_STORE)

        const nodeChangeRequests = Object.entries(offlineDatas.operations || {}).map(async ([nodeId, op]) => {
            if (nodeId) {
                debugLog(`save node changes, nodeId: ${nodeId}, offlineSessionId: ${this.currentofflineSessionId}`)
                nodeChangesSize += op.length
                nodeChangesStore.put({
                    offlineSessionId: this.currentofflineSessionId,
                    nodeId: nodeId,
                    data: op,
                })
            }
        })

        const blobChangeRequests = Object.entries(offlineDatas.blobs || {}).map(async ([blobId, blob]) => {
            if (blobId) {
                blobChangesSize += blob.length
                blobChangesStore.put({
                    offlineSessionId: this.currentofflineSessionId,
                    blobId: blobId,
                    data: blob,
                })
            }
        })

        const refNodesRequests = Object.entries(offlineDatas.refNodes || {}).map(async ([nodeId, refNodeVec]) => {
            if (nodeId) {
                referenceNodesStore.put({
                    offlineSessionId: this.currentofflineSessionId,
                    nodeId: nodeId,
                    data: refNodeVec,
                })
            }
        })

        await Promise.all(nodeChangeRequests)
        await Promise.all(blobChangeRequests)
        await Promise.all(refNodesRequests)

        this.unsyncedNodeChangesSizeTotal += nodeChangesSize
        this.unsyncedBlobChangesSizeTotal += blobChangesSize
        WkCLog.log(
            `save offline data to db finished, nodeChangesSize: ${nodeChangesSize}, blobChangesSize: ${blobChangesSize}. unsyncedNodeChangesSizeTotal: ${this.unsyncedNodeChangesSizeTotal}, unsyncedBlobChangesSizeTotal: ${this.unsyncedBlobChangesSizeTotal}`
        )
    }

    private async releaseAllHoldingLocksAndOfflineSessions() {
        debugLog(`allHoldingLocksAndOfflineSessions: ${Array.from(this.holdingOfflineSessionId2Lock.keys())}`)
        Array.from(this.holdingOfflineSessionId2Lock.values()).forEach(async (lock) => await lock.release())
        this.holdingOfflineSessionId2Lock.clear()
        this.currentofflineSessionId = null
        debugLog(`releaseAllHoldingLocksAndOfflineSessions done`)
    }

    private async resetUnsyncedDataInfo() {
        this.unsyncedNodeChangesSizeTotal = 0
        this.unsyncedBlobChangesSizeTotal = 0
    }

    private getChangeStoreKeyRangeByOfflineSessionId(offlineSessionId: number): IDBKeyRange {
        return IDBKeyRange.bound([offlineSessionId, LOWER_STR_KEY], [offlineSessionId, UPPER_STR_KEY])
    }

    private getSessionStoreKeyRangeByUserIdAndDocId(userId: number, docId: string): IDBKeyRange {
        return IDBKeyRange.bound([userId, docId, LOWER_NUM_KEY], [userId, docId, UPPER_NUM_KEY])
    }

    // 给用户弹窗提示
    private popover(content: string) {
        wkDialogConfirm.warning({
            closable: false,
            okText: translation('OeveVP'),
            cancelButtonProps: { style: { display: 'none' } },
            title: content,
            onOk() {
                domLocation().reload()
            },
        })
    }

    private pushMetricOnSaveOfflineData(
        offlineOperationsOfSession: OfflineOperationsOfSession,
        startTime: number,
        success: boolean
    ) {
        MetricCollector.pushMetricToServer(
            MetricName.SYNERGY_SAVE_OFFLINE_DATA_DURATION,
            performance.now() - startTime,
            {
                success: success,
            }
        )
        MetricCollector.pushMetricToServer(
            MetricName.SYNERGY_SAVE_OFFLINE_OP_COUNT,
            Object.keys(offlineOperationsOfSession.offlineOperations.operations).length,
            {
                success: success,
            }
        )
        MetricCollector.pushMetricToServer(
            MetricName.SYNERGY_SAVE_OFFLINE_BLOB_COUNT,
            Object.keys(offlineOperationsOfSession.offlineOperations.blobs).length,
            {
                success: success,
            }
        )

        if (offlineOperationsOfSession.offlineOperations.refNodes) {
            MetricCollector.pushMetricToServer(
                MetricName.SYNERGY_SAVE_OFFLINE_REFERENCED_NODES_COUNT,
                Object.keys(offlineOperationsOfSession.offlineOperations.refNodes).length,
                {
                    success: success,
                }
            )
        }
    }
}

function buildAutoSaveSessionLockKey(userId: number, docId: string, offlineSessionId: number) {
    return `auto-save-${userId}-${docId}-${offlineSessionId}`
}

async function getAutoSaveSessionLock(lockName: string): Promise<Lock | null> {
    // 如果用户的浏览器不支持 navigator.locks 功能, 就退化为打开文档时同步所有离线数据
    return navigator.locks
        ? new Promise((resolve) => {
              const request = navigator.locks.request(
                  lockName,
                  {
                      ifAvailable: true,
                  },
                  async (lock) => {
                      if (!lock) {
                          resolve(null)
                          return
                      }
                      await new Promise((resolve2) => {
                          resolve({
                              release: async () => {
                                  resolve2(lock)
                                  await request
                              },
                          })
                      })
                  }
              )
          })
        : Promise.resolve({
              release: async () => {},
          })
}

async function getAllUnSyncedofflineSessions(
    transaction: IDBPTransaction<MotiffAutoSaveSchema>,
    idKeyRange: IDBKeyRange
) {
    const offlineSessionsStore = transaction.objectStore(OFFLINE_SESSIONS_STORE)
    const index = offlineSessionsStore.index(OFFLINE_SESSIONS_INDEX)

    const allofflineSessions: OfflineSession[] = []

    let offlineSessionCursor = await index.openCursor(idKeyRange)
    while (offlineSessionCursor) {
        allofflineSessions.push(offlineSessionCursor.value)
        offlineSessionCursor = await offlineSessionCursor.continue()
    }

    debugLog(`all offlineSessions: ${JSON.stringify(allofflineSessions)}`)

    const offlineSession2Lock = new Map<OfflineSession, Lock>()
    const promises = allofflineSessions.map(async (offlineSession) => {
        const lock = await getAutoSaveSessionLock(
            buildAutoSaveSessionLockKey(offlineSession.userId, offlineSession.docId, offlineSession.id!)
        )
        let success = true
        if (lock) {
            debugLog(`start holding lock, offlineSessionId: ${offlineSession.id}`)
            offlineSession2Lock.set(offlineSession, lock)
        } else {
            success = false
            WkCLog.log(
                `Failed to get autoSaveSessionLock, another tab holding it. offlineSessionId: ${offlineSession.id}`
            )
        }
        MetricCollector.pushMetricToServer(MetricName.SYNERGY_GET_OFFLINE_SESSION_LOCK, 1, {
            success: success,
        })
    })

    await Promise.all(promises)
    return offlineSession2Lock
}
