import { translation } from './create-doc.translation'
/* eslint-disable no-restricted-imports */
import { WKToast } from '../../../../ui-lib/src'
import { DocWithAuthorityVO } from '../../kernel/interface/type'
import { MetricCollector, MetricName } from '../../kernel/metric-collector'
import { CreateDocRequest } from '../../kernel/request/document'
import { SpaceLogs } from '../../kernel/service/space-frog-service/logs'
import { createFileManager } from '../../main/create-file-manager'
import { onboardingService } from '../../main/service/onboarding-service'

export interface CreateDocEventListener {
    onSuccess?: (doc: DocWithAuthorityVO) => void
    onFail?: () => void
}
export const createDocInFolder = async (
    folderId: string,
    orgId: string,
    createDocEventListener?: CreateDocEventListener
) => {
    const historyLength = history.length
    const startTime = performance.now()
    SpaceLogs.FileOperations.newFile()
    createFileManager.injectCreateFileCallBack(() => {
        MetricCollector.pushMetricToServer(MetricName.SYNERGY_CREATE_DOC_DURATION, performance.now() - startTime, {
            success: 'true',
        })
    })

    // 标记是新建文件
    const { id, data } = createFileManager.startCreateFile(orgId)
    onboardingService.setCreateFile(true)

    // 异步请求创建新文档
    new CreateDocRequest({ name: translation('Untitled'), folderId, id }, data)
        .start()
        .then((newDoc) => {
            createDocEventListener?.onSuccess?.(newDoc)
            // 标记新建文档成功
            createFileManager.markCreateFileSuccessed()
        })
        .catch((err: Error) => {
            createFileManager.reset()
            createDocEventListener?.onFail?.()
            MetricCollector.pushMetricToServer(MetricName.SYNERGY_CREATE_DOC_DURATION, performance.now() - startTime, {
                success: 'false',
            })
            // 一旦新建文档失败了，就需要走特殊策略，返回工作台同时弹窗
            WKToast.error(translation('FailedToCreate'))
            // 如果是打开新标签页创建的话，是不会在当前页产生历史记录的，这时back是不符合预期的。
            if (historyLength > history.length) {
                history.back()
            }
            onboardingService.setCreateFile(false)
        })

    return id
}
