/**
 * @owner: chenxiangbj@kanyun.com
 */
import classNames from 'classnames'
import { FC, useMemo } from 'react'
// eslint-disable-next-line import/no-restricted-paths
import { TeamWithAuthorityVO } from '../../../../app/src/kernel/interface/type'
import { WKAvatar } from '../wk-avatar/wk-avatar'

export const TeamIconForSideBar: FC<{ team: TeamWithAuthorityVO } & { fixedSize?: 14 | 16; className?: string }> = ({
    team,
    fixedSize,
    className,
}) => {
    // 目录区的团队图标 展示规则为
    // 区域大小始终是 24 * 24，如果团队图标存在，内部居中展示 16*16 的图片，如果没有图片，展示 14*14 的纯色
    // 也可以设置为一直强制使用 16*16
    const size = useMemo(() => {
        if (fixedSize) {
            return fixedSize
        }
        return team.icon ? 16 : 14
    }, [fixedSize, team.icon])
    return (
        <div
            data-testid={'team-icon'}
            className={classNames('wk-team-icon flex items-center justify-center shrink-0 w-4 h-4', className)}
        >
            <WKAvatar
                type={'square'}
                backgroundColor={team.iconBackgroundColor}
                name={''}
                imageId={team.icon}
                size={size}
            />
        </div>
    )
}
