import React, { useCallback, useMemo, useState } from 'react'
import { useMount } from 'react-use'
import { InputV2, MonoIconCommonQuestionHelp24, Select, WKAvatar } from '../../../../../../ui-lib/src'
import { isEnglishLanguage } from '../../../../../../util/src'
import { UserBriefVO } from '../../../../kernel/interface/type'
import { WorkspaceSummaryInOrgVOList } from '../../../../kernel/interface/workspace'
import { GetWorkspacesSummary } from '../../../../kernel/request/workspace'
import { featureSwitchManager } from '../../../../kernel/switch'
import { sortByString } from '../../../../space/util/sort'
import { SortOrder } from '../../../../space/util/types'
import { fetchCreateApplication, fetchQueryApplicationAuditors } from '../pay-apply-dialog-request'
import classes from './normal-pay-apply.module.less'
import { translation } from './normal-pay-apply.translation'
import { PayApplyCheckboxGroup, PayApplyCheckboxGroupProps } from './pay-apply-checkbox-group'
import { PayApplyDialogContainer2 } from './pay-apply-dialog-container'
import { NormalPayApplyProps, PayApplyDialogUserType } from './type'
import { getSeatTypeFromPayApplyDialogSeatType, getUINameFromPayApplyDialogSeatType } from './utils'

function generateItems(userList: UserBriefVO[]): PayApplyCheckboxGroupProps['usersInfoMap'] {
    return new Map(userList.map((v) => [v.id, { user: v, checked: true }]))
}

enum WorkspaceSelectOption {
    NoExist = 'noexist',
    Unknown = 'unknown',
}

export function NormalPayApply(props: NormalPayApplyProps) {
    const { seatType, isAllowGracePeriod, isEnterprise, userInfo, dialogProps, httpProps, descriptionPrefix } = props
    const [textareaValue, setTextareaValue] = useState<string>('')
    const [textareaOverflow, setTextareaOverflow] = useState<boolean>(false)
    const [adminsInfoMap, setAdminsInfoMap] = useState(() => generateItems([]))
    const [workspaceInfo, setWorkspaceInfo] = useState<WorkspaceSummaryInOrgVOList>()
    const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<WorkspaceSelectOption | string>('')
    const [loading, setLoading] = useState<boolean>(false)

    const isAllowSelectAdmin = useMemo(() => {
        return (
            !(featureSwitchManager.isEnabled('organization-plus') && isEnterprise) &&
            userInfo.type === PayApplyDialogUserType.Normal
        )
    }, [isEnterprise, userInfo])

    const isAllowSelectWorkspace = useMemo(() => {
        return (
            featureSwitchManager.isEnabled('organization-plus') &&
            isEnterprise &&
            userInfo.type === PayApplyDialogUserType.Normal
        )
    }, [isEnterprise, userInfo])

    const workspacesSelectInfo = useMemo(() => {
        const optionId2labelMap = new Map<string, { name: string; icon: React.ReactNode }>()
        const options: { id: string; name: string; splitTop: boolean; forwardIcon: React.ReactNode }[] = []
        let needSplitLine = false
        if (workspaceInfo) {
            const workspaceList = [...workspaceInfo.assignedWorkspaces, ...workspaceInfo.otherWorkspaces]
            needSplitLine = workspaceList.length > 0
            for (const workspace of workspaceList) {
                const option = {
                    splitTop: false,
                    id: workspace.workspaceId,
                    name: workspace.name,
                    forwardIcon: (
                        <WKAvatar
                            size={16}
                            imageId={workspace.icon}
                            type="square"
                            backgroundColor={''}
                            name={workspace.name}
                        />
                    ),
                }
                optionId2labelMap.set(option.id, { name: option.name, icon: option.forwardIcon })
                options.push(option)
            }
        }
        const noExistOption = {
            splitTop: needSplitLine,
            id: WorkspaceSelectOption.NoExist,
            name: translation('MyWorkspaceIsNotListed'),
            forwardIcon: <MonoIconCommonQuestionHelp24 className={classes.questionIcon} />,
        }
        const unknownOption = {
            splitTop: false,
            id: WorkspaceSelectOption.Unknown,
            name: translation('IDoNotKnowMyWorkspace'),
            forwardIcon: <MonoIconCommonQuestionHelp24 className={classes.questionIcon} />,
        }
        optionId2labelMap.set(noExistOption.id, { name: noExistOption.name, icon: noExistOption.forwardIcon })
        optionId2labelMap.set(unknownOption.id, { name: unknownOption.name, icon: unknownOption.forwardIcon })
        options.sort((a, b) => sortByString(a.name, b.name, SortOrder.Ascending))
        options.push(noExistOption)
        options.push(unknownOption)
        return { options, optionId2labelMap }
    }, [workspaceInfo])

    const disabledSubmit = useMemo(() => {
        if (textareaOverflow) {
            return true
        }
        const isEmptySelected =
            isAllowSelectAdmin && adminsInfoMap.size > 0 && [...adminsInfoMap.values()].every((v) => !v.checked)
        if (isEmptySelected) {
            return true
        }
        if (isAllowSelectWorkspace && !selectedWorkspaceId) {
            return true
        }
        return false
    }, [adminsInfoMap, isAllowSelectAdmin, isAllowSelectWorkspace, selectedWorkspaceId, textareaOverflow])

    const onChangeTextarea = useCallback((e: React.FormEvent<HTMLTextAreaElement>) => {
        const el = e.target as HTMLTextAreaElement
        setTextareaValue(el.value)
    }, [])

    const onOk = async () => {
        setLoading(true)
        await fetchCreateApplication({
            resourceType: httpProps.resourceType,
            resourceId: httpProps.resourceId,
            seatType: getSeatTypeFromPayApplyDialogSeatType(seatType),
            reason: textareaValue,
            adminUserIds: [...adminsInfoMap.values()].filter((v) => v.checked).map((v) => v.user.id),
            triggerScene: httpProps.triggerScene,
            ...(featureSwitchManager.isEnabled('organization-plus')
                ? {
                      workspaceId:
                          !selectedWorkspaceId ||
                          selectedWorkspaceId === WorkspaceSelectOption.NoExist ||
                          selectedWorkspaceId === WorkspaceSelectOption.Unknown
                              ? '-1'
                              : selectedWorkspaceId,
                  }
                : {}),
        }).finally(() => {
            setLoading(false)
        })
        dialogProps?.onOk?.()
    }

    useMount(() => {
        if (isAllowSelectAdmin) {
            fetchQueryApplicationAuditors(httpProps.resourceType, httpProps.resourceId)
                .then((v) => setAdminsInfoMap(generateItems(v)))
                .catch(() => {})
        }
        if (isAllowSelectWorkspace) {
            new GetWorkspacesSummary(httpProps.orgId).start().then((v) => {
                setSelectedWorkspaceId(v.assignedWorkspaces[0]?.workspaceId)
                setWorkspaceInfo(v)
            })
        }
    })

    const mainText = useMemo(() => {
        return isEnglishLanguage() ? (
            <>
                {descriptionPrefix}, you need to request a &quot;{getUINameFromPayApplyDialogSeatType(seatType)}&quot;.
            </>
        ) : (
            <>
                {descriptionPrefix}
                需要向管理员申请「{getUINameFromPayApplyDialogSeatType(seatType)}
                」。
            </>
        )
    }, [descriptionPrefix, seatType])
    return (
        <PayApplyDialogContainer2
            {...dialogProps}
            okText={translation('sr')}
            title={translation('r', {
                seat: getUINameFromPayApplyDialogSeatType(seatType),
            })}
            okButtonProps={{ ...dialogProps?.okButtonProps, disabled: disabledSubmit, loading }}
            onOk={onOk}
        >
            <div className={classes.description} data-testid="grace-apply">
                {mainText}
                {isAllowGracePeriod ? translation('a14') : null}
            </div>
            {isAllowSelectAdmin ? (
                <>
                    <div className={classes.subDescription}>{translation('ca')}</div>
                    <PayApplyCheckboxGroup
                        usersInfoMap={adminsInfoMap}
                        onChange={setAdminsInfoMap}
                        textWhenNotExistChecked={translation('cal')}
                    />
                </>
            ) : null}
            {isAllowSelectWorkspace ? (
                <>
                    <div className={classes.subDescription}>{translation('workspaceDescription')}</div>
                    <Select.NormalSingleLevel
                        height="32px"
                        className="my-4"
                        value={selectedWorkspaceId}
                        onChange={setSelectedWorkspaceId}
                        icon={workspacesSelectInfo.optionId2labelMap.get(selectedWorkspaceId)?.icon}
                        label={workspacesSelectInfo.optionId2labelMap.get(selectedWorkspaceId)?.name}
                        placeholder={translation('SelectWorkspace')}
                        dataTestIds={{
                            triggerFocus: 'select-workspace-trigger-focus',
                        }}
                        placement="bottom left"
                    >
                        {workspacesSelectInfo.options.map((v) => (
                            <Select.NormalSingleLevel.Option
                                data-testid={`select-workspace-option`}
                                key={v.id}
                                value={v.id}
                                forwardIcon={v.forwardIcon}
                                splitLineTop={v.splitTop}
                            >
                                {v.name}
                            </Select.NormalSingleLevel.Option>
                        ))}
                    </Select.NormalSingleLevel>
                </>
            ) : null}
            <InputV2.Textarea
                placeholder={translation('an')}
                height={88}
                value={textareaValue}
                onChange={onChangeTextarea}
                count={{
                    show: textareaOverflow,
                    max: 500,
                    tipMessage: translation('eml'),
                    onOverflow: setTextareaOverflow,
                }}
            />
        </PayApplyDialogContainer2>
    )
}
