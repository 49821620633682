import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Return: '返回',
} as const

export const enTranslation = {
    Return: 'Return to',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string | number>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
