import classNames from 'classnames'
import { CSSProperties, FC, HTMLAttributes, useCallback, useMemo, useState } from 'react'
import { Tooltip } from '../tooltip'
import styles from './index.module.less'
import { WKTextProps } from './type'
import { getFontColor, getFontSize, getFontWeight } from './util'
interface ParagraphProps extends HTMLAttributes<HTMLParagraphElement> {
    size?: WKTextProps['size']
    color?: WKTextProps['color']
    weight?: WKTextProps['weight']
    ellipsis?: {
        rows: number
        tooltip?: string
    }
}
export const Paragraph: FC<ParagraphProps> = (props) => {
    const { size, weight, color, className, ellipsis, ...rest } = props

    const _size = size as WKTextProps['size'] | undefined

    const fontSize = getFontSize(_size)
    const fontWeight = getFontWeight(weight)
    const fontColor = getFontColor(color)
    const my = useMemo(() => {
        if (!_size || _size === 12) {
            return 'mb-2'
        } else {
            return 'mb-3'
        }
    }, [_size])

    const [isTextClamped, _setIsTextClamped] = useState(false)
    const setIsTextClamped = useCallback((elm: HTMLParagraphElement | null) => {
        if (elm) {
            _setIsTextClamped(elm.scrollHeight > elm.clientHeight)
        }
    }, [])

    const showTooltip = ellipsis?.tooltip && isTextClamped

    const lineClampStyle: CSSProperties = {
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitLineClamp: ellipsis?.rows,
        WebkitBoxOrient: 'vertical',
    }

    return (
        <Tooltip inactivation={!showTooltip} title={ellipsis?.tooltip}>
            <p
                ref={setIsTextClamped}
                style={lineClampStyle}
                className={classNames(
                    'mt-0 break-word',
                    styles.paragraph,
                    my,
                    fontSize,
                    fontWeight,
                    fontColor,
                    className
                )}
                {...rest}
            />
        </Tooltip>
    )
}
