import classNames from 'classnames'
import { useMemo, useState } from 'react'
import { useEvent, usePrevious } from 'react-use'
import { InputV2, ScrollContainer, WKDialog } from '../../../../../ui-lib/src'
import { type FolderID, ResourceType, RoleStatus, TeamID } from '../../../kernel/interface/type'
import {
    SearchRemoteLibraryProvider,
    useLibraryRemoteSearchService,
} from '../../../share/component-style-library/hook/use-library-service-remote-search'
import { LibraryComponentViewSpaceConfigProvider } from '../../../share/component-style-library/library-component-viewer/context'
import { LibraryControlListItemDetailContainer } from '../../../share/component-style-library/library-control-list-item-detail/library-control-list-item-detail'
import { LibraryTeamInfoList } from '../../../share/component-style-library/library-team-info-list/library-team-info-list'
import { LibraryThumbnailImageSpaceConfigProvider } from '../../../share/component-style-library/library-thumbnail-image/context'
import { LibraryTestId } from '../../../window'
import { canEditSetting } from '../../util/access/team'
import { DefaultLibraryServiceProvider, useDefaultLibraryService } from './context'
import classes from './default-library-modal.module.less'
import { translation } from './default-library-modal.translation'
import { TeamDefaultLibraryRoutePathType } from './service/default-library-service'

// 团队/草稿箱默认组件库
export function DefaultLibraryModal(props: {
    resourceType: ResourceType
    resourceId: TeamID | FolderID
    role: RoleStatus
    onCancel: () => void
}) {
    const readOnly = useMemo(() => !canEditSetting(props.role), [props.role])

    return (
        <DefaultLibraryServiceProvider resourceId={props.resourceId} resourceType={props.resourceType}>
            <LibraryThumbnailImageSpaceConfigProvider>
                <InnerDefaultLibraryModal
                    resourceId={props.resourceId}
                    resourceType={props.resourceType}
                    readOnly={readOnly}
                    onCancel={props.onCancel}
                />
            </LibraryThumbnailImageSpaceConfigProvider>
        </DefaultLibraryServiceProvider>
    )
}

function InnerDefaultLibraryModal(props: {
    resourceId: TeamID | FolderID
    resourceType: ResourceType
    readOnly: boolean
    onCancel: () => void
}) {
    const defaultLibraryService = useDefaultLibraryService()
    const subscribeLibraryIds = defaultLibraryService.states.use.subscribeLibraryIds()
    const routePathConfig = defaultLibraryService.states.use.routePathConfig()
    const previousRoutePath = usePrevious(routePathConfig.routePath)

    const getPopupHeight = () => {
        const winHeight = window.innerHeight
        let height = 600
        if (winHeight < 48 + 8 * 2 + 600) {
            height = Math.max(winHeight - 48 - 8 * 2, 150 + 55 + 60)
        }
        return height
    }
    const [popupHeight, setPopupHeight] = useState<number>(getPopupHeight())

    // 视窗变化时，动态设置 height
    useEvent('resize', () => {
        setPopupHeight(getPopupHeight())
    })

    return (
        <WKDialog
            width={464}
            bodyStyle={{ padding: 0 }}
            visible
            title={props.readOnly ? translation('ViewLibraries') : translation('EnableLibraries')}
            footer={null}
            onCancel={props.onCancel}
        >
            <div style={{ height: popupHeight }} className="overflow-hidden">
                <SearchRemoteLibraryProvider
                    resourceId={props.resourceId}
                    resourceType={props.resourceType}
                    readOnly={props.readOnly}
                    switchDesc={
                        props.resourceType === ResourceType.Team
                            ? translation('EnabledForTeam')
                            : translation('EnabledForDrafts')
                    }
                    goBackHome={defaultLibraryService.goBackHome}
                    openDetail={defaultLibraryService.openDetail}
                    jump2DetailDoc={defaultLibraryService.jump2DetailDoc}
                    subscribeLibrary={defaultLibraryService.subscribeLibrary}
                    unSubscribeLibrary={defaultLibraryService.unSubscribeLibrary}
                    subscribeLibraryIds={subscribeLibraryIds}
                >
                    <LibraryRemoteTotalList
                        className={classNames(
                            routePathConfig.routePath === TeamDefaultLibraryRoutePathType.Home
                                ? classes.show
                                : classes.hidden,
                            { [classes.back]: previousRoutePath === TeamDefaultLibraryRoutePathType.Detail }
                        )}
                    />
                    <LibraryComponentViewSpaceConfigProvider>
                        <LibraryControlListItemDetailContainer
                            className={classNames(
                                routePathConfig.routePath === TeamDefaultLibraryRoutePathType.Detail
                                    ? classes.show
                                    : classes.hidden,
                                classes.go
                            )}
                            libraryId={
                                routePathConfig.routePath === TeamDefaultLibraryRoutePathType.Detail
                                    ? routePathConfig.libraryId
                                    : undefined
                            }
                        />
                    </LibraryComponentViewSpaceConfigProvider>
                </SearchRemoteLibraryProvider>
            </div>
        </WKDialog>
    )
}

function LibraryRemoteTotalList(props: { className: string }) {
    const libraryRemoteSearchService = useLibraryRemoteSearchService()
    const onSearch = (keyword?: string) => {
        libraryRemoteSearchService.search(keyword ?? '')
    }
    return (
        <ScrollContainer
            data-testid={LibraryTestId.HomeModal.RemoteListBody}
            stickyHeader={
                <div className={classes.searchInput}>
                    <InputV2.Search placeholder={translation('Search')} onSearch={onSearch} />
                </div>
            }
            className={classNames(classes.listContainer, props.className)}
            scrollViewProps={{ className: classes.scrollWrapper }}
            data-disabled-drag-move="true"
        >
            <LibraryTeamInfoList />
        </ScrollContainer>
    )
}
