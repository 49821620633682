/* eslint-disable no-restricted-imports */
import { createSelectors, createStore } from '../../../../../../util/src'
import { CommentId, CommentReply } from '../../../../kernel/interface/comment'
import { Sentry } from '../../../../kernel/sentry'
import { fetchReleaseReply } from '../comment-request'

type CommentReplyMap = Map<CommentReply['uuid'], CommentReply>
interface ReplyCommentServiceZustandStore {
    pendingEditReplies: CommentReplyMap
    pendingCreateReplies: Map<CommentId, CommentReplyMap>
}

export class ReplyCommentService {
    private zustandStore = createStore<ReplyCommentServiceZustandStore>(() => ({
        pendingEditReplies: new Map(),
        pendingCreateReplies: new Map(),
    }))
    useZustandStore = createSelectors(this.zustandStore)

    private getPendingCreateReplies() {
        return this.zustandStore.getState().pendingCreateReplies
    }
    private setPendingCreateReplies(value: Map<CommentId, CommentReplyMap>) {
        this.zustandStore.setState({
            pendingCreateReplies: value,
        })
    }
    getPendingEditReplies() {
        return this.zustandStore.getState().pendingEditReplies
    }
    private setPendingEditReplies(value: CommentReplyMap) {
        this.zustandStore.setState({
            pendingEditReplies: value,
        })
    }

    addPendingCreateReply(commentId: CommentId, commentReply: CommentReply) {
        const createReplyMap = this.getPendingCreateReplies()
        let replyMap = createReplyMap.get(commentId)
        if (replyMap) {
            replyMap.set(commentReply.uuid, commentReply)
        } else {
            replyMap = new Map([[commentReply.uuid, commentReply]])
            createReplyMap.set(commentId, replyMap)
        }
        this.setPendingCreateReplies(new Map(createReplyMap))
    }

    tryRemovePendingCreateReply(commentId: CommentId, replyUUID: CommentReply['uuid']) {
        const createReplyMap = this.getPendingCreateReplies()
        const replyMap = createReplyMap.get(commentId)
        if (replyMap?.delete(replyUUID)) {
            if (replyMap.size === 0) {
                createReplyMap.delete(commentId)
            }
            this.setPendingCreateReplies(new Map(createReplyMap))
        }
    }

    addPendingEditReply(commentReply: CommentReply) {
        const replyMap = this.getPendingEditReplies()
        replyMap.set(commentReply.uuid, commentReply)
        this.setPendingEditReplies(new Map(replyMap))
    }

    tryRemovePendingEditReply(replyUUID: CommentReply['uuid']) {
        const replyMap = this.getPendingEditReplies()
        if (replyMap.delete(replyUUID)) {
            this.setPendingEditReplies(new Map(replyMap))
        }
    }

    isAllowRestorePending(commentId: CommentId, replyUUID: CommentReply['uuid']) {
        const replyMap = this.getPendingCreateReplies().get(commentId)
        return replyMap?.size === 1 && replyMap.has(replyUUID)
    }

    async requestCreateReply(commentId: CommentId, reply: CommentReply) {
        this.addPendingCreateReply(commentId, reply)
        try {
            const commentReply = await fetchReleaseReply({
                message: reply.message,
                messageText: reply.messageText,
                mentionedUserIds: reply.mentionedUsers.map((user) => user.id),
                parentCommentId: commentId,
                uuid: reply.uuid,
            })
            this.tryRemovePendingCreateReply(commentId, reply.uuid)
            return commentReply
        } catch (e) {
            Sentry.captureException(e)
            return undefined
        }
    }
}
