/* eslint-disable no-inner-declarations */

/**
 * 使用 document 上的 copy 能力来完成复制
 * @description 这是一种降级方案
 * @param content
 */
export namespace ExecClipboardCommandHelper {
    export function execCommandToCopy(content: string) {
        console.group('[on document exec command copy]')
        const textarea = document.createElement('textarea')
        textarea.setAttribute('style', 'position: fixed; top: -100px')
        document.body.appendChild(textarea)
        textarea.value = content
        textarea.focus()
        textarea.select()
        try {
            document.execCommand('copy')
        } catch (e) {
            console.error('failed', e)
        }
        textarea.remove()
        console.groupEnd()
    }
}
