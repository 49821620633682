import { FC, PropsWithChildren } from 'react'
import { useLocation } from 'react-router'
import { generateRouterPath } from '../../../../../../util/src'
import { EditorSkeletonWrapper } from './editor'
import { SpaceSkeletonWrapper } from './space'

/**
 * 位于最上游，承接影响用户体验异步请求的 fallback 显示
 * @param param0
 * @returns
 */
export const Skeleton: FC<PropsWithChildren> = ({ children }) => {
    const pathname = useLocation().pathname

    if (pathname.startsWith('/' + generateRouterPath('files'))) {
        return <SpaceSkeletonWrapper>{children}</SpaceSkeletonWrapper>
    }

    if (pathname.startsWith('/' + generateRouterPath('file'))) {
        return <EditorSkeletonWrapper>{children}</EditorSkeletonWrapper>
    }

    return <>{children}</>
}
