import { FC } from 'react'

const Block: FC<{ height: number; width?: number; rounded?: boolean }> = ({ height, width, rounded }) => {
    return <div className="bg-$wk-gray-2" style={{ height, width, borderRadius: rounded ? '100%' : '3px' }} />
}

const WorkspacesItem: FC<{ itemNumber: number }> = ({ itemNumber }) => {
    return (
        <div className="flex flex-col gap-10px">
            <Block height={16} width={65} />
            <div className="grid gap-8 grid-cols-[repeat(auto-fill,minmax(400px,1fr))]">
                {Array.from({ length: itemNumber }).map((_, index) => (
                    <div
                        key={index}
                        className="ring-1 h-222px box-border ring-$wk-gray-2 rounded-3px p-6 flex flex-col gap-9"
                    >
                        <div className="gap-10px flex flex-col">
                            <Block height={48} width={48} />
                            <div className="flex flex-col gap-1.5">
                                <Block height={24} width={113} />
                                <Block height={14} />
                                <Block height={14} width={57} />
                            </div>
                        </div>
                        <Block height={14} width={88} />
                    </div>
                ))}
            </div>
        </div>
    )
}
export const Workspaces = () => {
    return (
        <div className="flex-1 px-8">
            <div className="pt-13px pb-33px">
                <Block height={34} width={140} />
            </div>
            <Block height={1} />
            <div className="flex gap-8">
                <div className="flex-1">
                    <div className="flex gap-6 pt-21px pb-6">
                        <Block height={16} width={64} />
                        <Block height={16} width={64} />
                    </div>
                    <div className="flex flex-col gap-8.5">
                        <WorkspacesItem itemNumber={1} />
                        <WorkspacesItem itemNumber={10} />
                    </div>
                </div>
                <div className="w-62 box-border pl-6 pt-21px flex flex-col gap-5.5">
                    <Block width={64} height={16} />
                    <div className="flex flex-col gap-4">
                        <div className="flex gap-2">
                            <Block height={24} width={24} rounded />
                            <Block height={24} width={109} />
                        </div>
                        <div className="flex gap-2">
                            <Block height={24} width={24} rounded />
                            <Block height={24} width={69} />
                        </div>

                        <div className="flex gap-2">
                            <Block height={24} width={24} rounded />
                            <Block height={24} width={109} />
                        </div>
                        <div className="flex gap-2">
                            <Block height={24} rounded width={24} />
                            <Block height={24} width={69} />
                        </div>
                        <div className="flex gap-2">
                            <Block height={24} width={24} rounded />
                            <Block height={16} width={109} />
                        </div>
                        <div className="flex gap-2">
                            <Block height={24} width={24} rounded />
                            <Block height={24} width={69} />
                        </div>
                        <div className="flex gap-2">
                            <Block height={24} width={24} rounded />
                            <Block height={24} width={109} />
                        </div>
                        <div className="flex gap-2">
                            <Block height={24} width={24} rounded />
                            <Block height={24} width={69} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const WorkspaceItem = () => {
    return (
        <div className="p-6 flex flex-col gap-14">
            <div className="flex gap-2 items-center">
                <Block height={32} width={32} />
                <Block height={24} />
            </div>
            <div className="flex h-5 justify-between">
                <Block height={14} width={72} />
                <Block height={14} width={48} />
            </div>
        </div>
    )
}
export const Workspace = () => {
    return (
        <div className="px-8 flex-1">
            <div className="pt-13px flex justify-between">
                <div className="flex flex-col gap-13px">
                    <Block height={14} width={140} />
                    <div className="flex gap-4 items-center">
                        <Block height={48} width={48} />
                        <Block height={34} width={140} />
                    </div>
                </div>
                <div className="flex gap-2 self-end pb-1.5">
                    <Block height={32} width={92} />
                    <Block height={32} width={92} />
                    <Block height={32} width={84} />
                    <Block height={32} width={32} />
                </div>
            </div>
            <div className="pt-31px">
                <Block height={1} />
            </div>
            <div className="flex gap-8">
                <div className="flex-1">
                    <div className="py-21px flex gap-6">
                        <Block width={64} height={16}></Block>
                        <Block width={64} height={16}></Block>
                    </div>
                    <div className="grid grid-cols-[repeat(auto-fill,minmax(256px,1fr))] gap-8">
                        <WorkspaceItem />
                        <WorkspaceItem />
                        <WorkspaceItem />
                        <WorkspaceItem />
                        <WorkspaceItem />
                        <WorkspaceItem />
                        <WorkspaceItem />
                        <WorkspaceItem />
                        <WorkspaceItem />
                        <WorkspaceItem />
                        <WorkspaceItem />
                        <WorkspaceItem />
                        <WorkspaceItem />
                        <WorkspaceItem />
                    </div>
                </div>
                <div className="w-62 box-border pl-6 pt-22px">
                    <Block width={64} height={16} />
                    <div className="pt-4">
                        <Block width={64} height={16} />
                    </div>
                    <div className="pt-10 flex flex-col gap-5.5">
                        <Block width={64} height={16} />
                        <div className="flex flex-col gap-4">
                            <div className="flex gap-2">
                                <Block height={24} width={24} rounded />
                                <Block height={24} width={109} />
                            </div>
                            <div className="flex gap-2">
                                <Block height={24} width={24} rounded />
                                <Block height={24} width={69} />
                            </div>
                            <div className="flex gap-2">
                                <Block height={24} width={24} rounded />
                                <Block height={24} width={109} />
                            </div>
                            <div className="flex gap-2">
                                <Block height={24} width={24} rounded />
                                <Block height={24} width={69} />
                            </div>
                            <div className="flex gap-2">
                                <Block height={24} width={24} rounded />
                                <Block height={24} width={109} />
                            </div>
                            <div className="flex gap-2">
                                <Block height={24} width={24} rounded />
                                <Block height={24} width={69} />
                            </div>
                            <div className="flex gap-2">
                                <Block height={24} width={24} rounded />
                                <Block height={24} width={109} />
                            </div>
                            <div className="flex gap-2">
                                <Block height={24} width={24} rounded />
                                <Block height={24} width={69} />
                            </div>
                            <div className="flex gap-2">
                                <Block height={24} width={24} rounded />
                                <Block height={24} width={109} />
                            </div>
                            <div className="flex gap-2">
                                <Block height={24} width={24} rounded />
                                <Block height={24} width={69} />
                            </div>
                            <div className="flex gap-2">
                                <Block height={24} width={24} rounded />
                                <Block height={24} width={109} />
                            </div>
                            <div className="flex gap-2">
                                <Block height={24} width={24} rounded />
                                <Block height={24} width={69} />
                            </div>
                            <div className="flex gap-2">
                                <Block height={24} width={24} rounded />
                                <Block height={24} width={109} />
                            </div>
                            <div className="flex gap-2">
                                <Block height={24} width={24} rounded />
                                <Block height={24} width={69} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
