import { createSelectors, createStore } from '../../../../../util/src'
import { FlatDoc, ShowType } from '../../util/types'
interface DocListSelectedState {
    dragType?: ShowType
    selectedDocs: FlatDoc[]
    lastSelectId?: string
    setLastSelectId: (id: string) => void
    set: (docs: FlatDoc[], dragType?: ShowType) => void
    add: (doc: FlatDoc) => void
    remove: (id: string) => void
}
const useDocListSelectedStateBase = createStore<DocListSelectedState>((set, get) => ({
    selectedDocs: [],
    dragType: ShowType.Grid,
    lastSelectId: undefined,
    setLastSelectId: (id) => set({ lastSelectId: id }),
    set(selectedDocs, dragType) {
        set({ selectedDocs, dragType })
        if (selectedDocs.length == 0) {
            set({ lastSelectId: undefined })
        }
    },
    add(doc) {
        set({ selectedDocs: [...get().selectedDocs, doc] })
    },
    remove(id) {
        set({ selectedDocs: get().selectedDocs.filter((doc) => doc.id !== id) })
        if (id == get().lastSelectId) {
            set({ lastSelectId: undefined })
        }
    },
}))
export const useDocListSelectedState = createSelectors(useDocListSelectedStateBase)
