import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMount } from 'react-use'
import { PrototypeTestId } from '../../../../../../window/wk-data-test'
import { EventEmitter } from '../../../../../utils/event-emmitter'
import { getSpringStableDurationByFunc, SpringSolver } from './spring-util'

interface AnimationEasingSpringPreviewProps {
    springEventEmitter: EventEmitter
    func: [number, number, number, number]
}

const CONTAINER_WIDTH = 184
const CONTAINER_PADDING_WIDTH = 30
const BOX_SIZE = 34
const DISTANCE_WIDTH = CONTAINER_WIDTH - CONTAINER_PADDING_WIDTH * 2 - BOX_SIZE

const DELAY_START = 0.1
const DELAY_END = 1

export const AnimationEasingSpringPreview = ({ func, springEventEmitter }: AnimationEasingSpringPreviewProps) => {
    const [play, setPlay] = useState(false)
    const [startFrameTime, setStartFrameTime] = useState(0)
    const [ellapseTime, setEllapseTime] = useState(0)
    const [duration, setDuration] = useState(0)
    const [springSolver, setSpringSolver] = useState<SpringSolver>()

    const startAnimation = useCallback(() => {
        setPlay(true)
        setDuration(getSpringStableDurationByFunc(func) + DELAY_START + DELAY_END)
        setSpringSolver(new SpringSolver(...func))
        setStartFrameTime(performance.now())
        setEllapseTime(0)
    }, [func])

    const resetAnimation = useCallback(() => {
        setPlay(false)
        setDuration(0)
        setStartFrameTime(0)
        setEllapseTime(0)
    }, [])

    useEffect(() => {
        if (!play || duration <= 0) {
            return
        }

        const id = requestAnimationFrame(() => {
            const ellapse = (performance.now() - startFrameTime) / 1000
            if (ellapseTime > duration) {
                resetAnimation()
            } else {
                setEllapseTime(ellapse)
            }
        })

        return () => {
            cancelAnimationFrame(id)
        }
    }, [play, duration, startFrameTime, ellapseTime, resetAnimation])

    const transformX = useMemo(() => {
        if (!springSolver) {
            return 0
        }

        if (ellapseTime < DELAY_START) {
            return 0
        }

        return springSolver.solve(ellapseTime) * DISTANCE_WIDTH
    }, [springSolver, ellapseTime])

    useEffect(() => {
        springEventEmitter.on('startSpringPreview', startAnimation)
        springEventEmitter.on('resetSpringPreview', resetAnimation)
        return () => {
            springEventEmitter.off('startSpringPreview', startAnimation)
            springEventEmitter.off('resetSpringPreview', resetAnimation)
        }
    }, [startAnimation, resetAnimation, springEventEmitter])

    useMount(() => startAnimation())

    return (
        <div
            data-testid={PrototypeTestId.InteractionPopup.AnimationEasingSpringPreview}
            className="box-border flex flex-row items-center bg-$wk-gray-3 rounded-$wk-radius-small overflow-hidden"
            onMouseEnter={startAnimation}
            onMouseLeave={resetAnimation}
            style={{ width: `${CONTAINER_WIDTH}px`, paddingLeft: `${CONTAINER_PADDING_WIDTH}px`, height: '96px' }}
        >
            <div
                className="box-border bg-$wk-brand-7 rounded-$wk-radius-small border border-$wk-gray-13"
                style={
                    play && springSolver
                        ? {
                              width: `${BOX_SIZE}px`,
                              height: `${BOX_SIZE}px`,
                              transform: `translateX(${transformX}px)`,
                              transition: 'none',
                          }
                        : {
                              width: `${BOX_SIZE}px`,
                              height: `${BOX_SIZE}px`,
                              transform: 'none',
                              transition: 'transform 0.2s ease-out',
                          }
                }
            ></div>
        </div>
    )
}
