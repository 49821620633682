import classNames from 'classnames'
import { isEqual } from 'lodash-es'
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useEvent } from 'react-use'
import ResizeObserver from 'resize-observer-polyfill'
import { DraggablePopupV2 } from '../../draggable-popup'
import { StepContent } from './step-content'
import classes from './step.module.less'
import {
    Rect,
    TOUR_ARROW_GAP,
    TOUR_EDGE_DISTANCE,
    TOUR_HIGHLIGHT_WIDTH,
    TOUR_NO_ARROW_GAP,
    WKTourHighlightPosition,
    WKTourPlacement,
    WKTourPosition,
    WKTourStepProps,
} from './type'
import { calculateContentRect, getElementsRect } from './util'

export const Step = (props: WKTourStepProps) => {
    const {
        draggable,
        current,
        length,
        zIndex,
        targets,
        highlightTargets,
        placement = 'bottom-center',
        highlight,
        highlightAlign,
        highlightRange,
        scrollElement,
        noTargetPosition,
        triggerRect,
        worldRect,
        showArrow,
        onClose,
        ...restProps
    } = props
    const [isFirst, setIsFirst] = useState<boolean>(true)
    const [stepContent, setStepContent] = useState<HTMLDivElement>()
    const [innerPlacement, setInnerPlacement] = useState<WKTourPlacement>()
    const [tourPosition, setTourPosition] = useState<WKTourPosition>()
    const [targetNodes, setTargetNodes] = useState<HTMLElement[]>([])
    const [highlightTargetNodes, setHighlightTargetNodes] = useState<HTMLElement[]>([])
    const [highlightPositions, setHighlightPositions] = useState<WKTourHighlightPosition[]>([])
    const [missTarget, setMissTarget] = useState<boolean>(false)

    const getTriggerRect = useCallback(() => {
        if (triggerRect) {
            return typeof triggerRect === 'function' ? triggerRect() : triggerRect
        } else if (targetNodes.length) {
            const targetRect = getElementsRect(targetNodes) as Rect
            // 有箭头 gap 为 4px，无箭头 gap 为 8px
            const gap = showArrow ? TOUR_ARROW_GAP : TOUR_NO_ARROW_GAP
            return {
                left: targetRect.left - gap,
                top: targetRect.top - gap,
                right: targetRect.right + gap,
                bottom: targetRect.bottom + gap,
                width: targetRect.width + gap * 2,
                height: targetRect.height + gap * 2,
            }
        }
    }, [triggerRect, targetNodes, showArrow])

    const getWorldRect = useCallback(() => {
        if (worldRect) {
            return typeof worldRect === 'function' ? worldRect() : worldRect
        }
        return {
            left: TOUR_EDGE_DISTANCE,
            top: TOUR_EDGE_DISTANCE,
            right: window.innerWidth - TOUR_EDGE_DISTANCE,
            bottom: window.innerHeight - TOUR_EDGE_DISTANCE,
            width: window.innerWidth - TOUR_EDGE_DISTANCE * 2,
            height: window.innerHeight - TOUR_EDGE_DISTANCE * 2,
        }
    }, [worldRect])

    const calculateTourPosition = useCallback(() => {
        if (!stepContent) {
            return
        }
        const stepContentRect = stepContent.getBoundingClientRect()
        const targetRect = getTriggerRect()
        if (targetRect && !missTarget) {
            const { newPlacement, newPosition } = calculateContentRect({
                placement,
                triggerRect: targetRect,
                contentRect: stepContentRect,
                worldRect: getWorldRect(),
            })
            setInnerPlacement(newPlacement)
            setTourPosition(newPosition)
        } else {
            const position = typeof noTargetPosition === 'function' ? noTargetPosition() : noTargetPosition
            const centerPos = {
                top: window.innerHeight / 2 - stepContentRect.height / 2,
                left: window.innerWidth / 2 - stepContentRect.width / 2,
            }
            setInnerPlacement(undefined)
            // 如果设置 noTargetPosition = null，则不重新计算位置，保持原位置
            position !== null && setTourPosition(position || centerPos)
        }
    }, [placement, noTargetPosition, missTarget, stepContent, getWorldRect, getTriggerRect])

    const calculateHighlightPosition = useCallback(
        (nodes?: HTMLElement[]) => {
            if (!highlight || (!targetNodes.length && !highlightTargetNodes.length && !nodes?.length)) {
                return
            }
            const elements = nodes || (highlightTargetNodes.length ? highlightTargetNodes : targetNodes)
            const range = typeof highlightRange === 'function' ? highlightRange() : highlightRange
            const positions: WKTourHighlightPosition[] = elements.map((node) => {
                const { top, left, bottom, right, width, height } = node.getBoundingClientRect()
                const centerTop = top + height / 2
                const centerLeft = left + width / 2
                if (highlightAlign) {
                    const hTop = highlightAlign.top
                        ? top + highlightAlign.top
                        : highlightAlign.bottom
                        ? bottom - highlightAlign.bottom
                        : centerTop
                    const hLeft = highlightAlign.left
                        ? left + highlightAlign.left
                        : highlightAlign.right
                        ? right - highlightAlign.right
                        : centerLeft
                    return {
                        top: hTop,
                        left: hLeft,
                    }
                }
                return {
                    top: centerTop,
                    left: centerLeft,
                }
            })
            if (range) {
                const { top, bottom } = range
                positions.forEach((pos) => {
                    if (
                        Number(pos.top) + TOUR_HIGHLIGHT_WIDTH / 2 < top ||
                        Number(pos.top) - TOUR_HIGHLIGHT_WIDTH / 2 > bottom
                    ) {
                        pos.hide = true
                    }
                })
            }
            if (positions.every((pos) => pos.hide)) {
                setMissTarget(true)
            }

            setHighlightPositions(positions)
        },
        [highlight, targetNodes, highlightAlign, highlightRange, highlightTargetNodes]
    )

    const calculateTargets = useCallback(() => {
        let newTargets: HTMLElement[] = []
        let newHighlightTargets: HTMLElement[] = []
        if (targets) {
            const nodes = typeof targets === 'function' ? targets() : targets
            newTargets = (nodes?.filter?.((node) => !!node) as HTMLElement[]) || []
        }
        if (highlightTargets) {
            const nodes = typeof highlightTargets === 'function' ? highlightTargets() : highlightTargets
            newHighlightTargets = (nodes?.filter?.((node) => !!node) as HTMLElement[]) || []
        }
        if (!isEqual(newTargets, targetNodes) || !isEqual(newHighlightTargets, highlightTargetNodes)) {
            setTargetNodes(newTargets)
            setHighlightTargetNodes(newHighlightTargets)
            calculateHighlightPosition(newHighlightTargets.length ? newHighlightTargets : newTargets)
        }
    }, [targets, highlightTargets, targetNodes, highlightTargetNodes, calculateHighlightPosition])

    const stepContentRefCallback = useCallback((node: HTMLDivElement) => {
        setStepContent(node)
    }, [])

    const highlightElements = useMemo(() => {
        if (!highlight) {
            return null
        }
        const nodes = highlightTargetNodes.length ? highlightTargetNodes : targetNodes

        return nodes.map((_, idx) =>
            !highlightPositions[idx]?.hide ? (
                <div
                    className={classes.highlight}
                    style={{ zIndex, ...highlightPositions[idx] }}
                    key={idx}
                    data-testid="wk-tour-highlight"
                >
                    <span className={classes.dot} />
                    <span className={classes.circle} />
                </div>
            ) : null
        )
    }, [highlight, highlightPositions, highlightTargetNodes, targetNodes, zIndex])

    const renderContent = () => {
        return (
            <StepContent
                ref={stepContentRefCallback}
                current={current}
                length={length}
                placement={innerPlacement}
                draggable={draggable}
                showArrow={showArrow}
                onClose={onClose}
                {...restProps}
            />
        )
    }

    useLayoutEffect(() => {
        calculateTourPosition()
    }, [calculateTourPosition])

    useLayoutEffect(() => {
        if (!isFirst) {
            return
        }
        calculateTargets()
        setIsFirst(false)
    }, [isFirst, calculateTargets])

    useEffect(() => {
        if (targetNodes.length || highlightTargetNodes.length) {
            const observer = new ResizeObserver((entries) => {
                if (entries.length > 0) {
                    calculateTargets()
                }
            })
            const nodes = targetNodes.concat(highlightTargetNodes)
            nodes.forEach((target) => {
                observer.observe(target)
            })
            return () => {
                nodes.forEach((target) => {
                    observer.unobserve(target)
                })
            }
        }
    }, [targetNodes, highlightTargetNodes, calculateTargets])

    useEffect(() => {
        if (scrollElement) {
            const scrollNode = typeof scrollElement === 'function' ? scrollElement() : scrollElement
            const onScroll = () => {
                setMissTarget(true)
                calculateHighlightPosition()
            }
            scrollNode?.addEventListener('scroll', onScroll)
            return () => {
                scrollNode?.removeEventListener('scroll', onScroll)
            }
        }
    }, [scrollElement, calculateHighlightPosition])

    // 视窗变化时，动态设置 height
    useEvent('resize', () => {
        calculateTourPosition()
        calculateHighlightPosition()
    })

    if (isFirst) {
        return <></>
    }

    return (
        <>
            {highlightElements}
            {draggable ? (
                <DraggablePopupV2
                    visible
                    testId="wk-tour-draggable-step"
                    position={{ top: tourPosition?.top || 0, left: tourPosition?.left || 0 }}
                    width={280}
                    header={null}
                    footer={null}
                    bodyClassName="p-0!"
                    onCancel={onClose}
                    onFirstMove={() => setMissTarget(true)}
                    style={{ zIndex }}
                    overflowVisible
                    useRawPosition
                >
                    {renderContent()}
                </DraggablePopupV2>
            ) : (
                <>
                    <div
                        data-testid="wk-tour-step"
                        className={classNames(classes.step)}
                        style={{ zIndex, ...tourPosition }}
                    >
                        {renderContent()}
                    </div>
                </>
            )}
        </>
    )
}
