import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    ClickOrDrag: '点击或拖拽来导入',
    PlaceAll: '全部放置',
    DiscardAll: '取消放置',
} as const

export const enTranslation = {
    ClickOrDrag: 'Click or drag to place',
    PlaceAll: 'Place all',
    DiscardAll: 'Discard all',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
