import classnames from 'classnames'
import { forwardRef, useImperativeHandle, useRef } from 'react'
import { ExplicitUndefined } from '../../../utils/explicit-undefined'
import { TriggerContainer, TriggerContainerProps } from './base-components/trigger-container'
import { TriggerFocus, TriggerFocusProps, TriggerFocusRef } from './base-components/trigger-focus'
import classes from './trigger-icon.module.less'
import { TriggerBaseProps, TriggerBaseRef } from './type'
import { useCommonTrigger } from './use-common-trigger'

export interface TriggerIconProps extends TriggerBaseProps {
    // TriggerContainerProps
    classNameWhenOpen?: TriggerContainerProps['classNameWhenOpen']
    isOpenPick: TriggerContainerProps['isOpenPick']
    // 私有配置
    label?: React.ReactNode
    isLightColor?: boolean
    classNameTriggerFocus?: string
}
export interface TriggerIconRef extends TriggerBaseRef {}

function _TriggerIcon(props: TriggerIconProps, ref?: React.Ref<TriggerIconRef>) {
    const {
        className,
        disabled,
        dataTestIds,
        openAction,
        closeAction,
        classNameWhenOpen,
        classNameTriggerFocus,
        isOpenPick,
        label,
        isLightColor,
        ...otherProps
    } = props

    const triggerRef = useRef<TriggerFocusRef>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const commonTrigger = useCommonTrigger({ isOpenPick, openAction, closeAction })

    useImperativeHandle(
        ref,
        () => ({
            getTriggerElement: () => triggerRef.current?.getContainer(),
            triggerRect: () => triggerRef.current?.getContainer().getBoundingClientRect()!,
            focus: () => triggerRef.current?.getFocusElement().focus(),
            getContainer: () => containerRef.current!,
        }),
        []
    )
    return (
        <TriggerContainer<ExplicitUndefined<TriggerContainerProps>>
            ref={containerRef}
            className={classnames(classes.template, [className], {
                [classes.disabled]: disabled,
                [classes.lightColor]: isLightColor,
            })}
            disabled={disabled}
            dataTestIds={dataTestIds}
            classNameWhenOpen={classnames(classNameWhenOpen, classes.isOpenPick)}
            isOpenPick={isOpenPick}
            {...otherProps}
        >
            <TriggerFocus<ExplicitUndefined<TriggerFocusProps>>
                ref={triggerRef}
                className={classnames(classes.trigger, [classNameTriggerFocus])}
                dataTestIds={dataTestIds}
                disabled={disabled}
                onClick={commonTrigger.click}
                onMouseDown={commonTrigger.mousedown}
                onEnter={openAction}
                onBlankSpace={openAction}
                outlineMode={undefined}
            >
                {label}
            </TriggerFocus>
            {props.children}
        </TriggerContainer>
    )
}

export const TriggerIcon = forwardRef(_TriggerIcon)
