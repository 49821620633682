import React, { useCallback, useState } from 'react'
import useRafLoop from 'react-use/lib/useRafLoop'

interface ScrollArrowProps {
    scrollRef: React.RefObject<HTMLDivElement>
    containerRef?: React.RefObject<HTMLDivElement>
}

export const PAGE_STYLE = {
    marginTop: 8,
    marginBottom: 8,
}
export const CONTAINER_STYLE = {
    paddingTop: 8,
    paddingBottom: 8,
}
export const NAVBAR_HEIGHT = 48
export const MARGIN_TOP = NAVBAR_HEIGHT + PAGE_STYLE.marginTop
export const MARGIN_BOTTOM = PAGE_STYLE.marginBottom

export const useScrollArrow = (props: ScrollArrowProps) => {
    const { scrollRef, containerRef } = props
    const [topScrollArrowShow, setTopScrollArrowShow] = useState<boolean>(false)
    const [bottomScrollArrowShow, setBottomScrollArrowShow] = useState<boolean>(false)
    const [scrollerPosition, setScrollerPosition] = useState<object>()

    const [StopScrollDown, StartScrollDown] = useRafLoop(() => {
        const node = scrollRef.current
        const container = containerRef?.current
        if (node && container) {
            const { top, left, height } = container.getBoundingClientRect()
            const topGap = top - MARGIN_TOP
            const heightGap = node.scrollHeight + CONTAINER_STYLE.paddingBottom + CONTAINER_STYLE.paddingTop - height
            if (topGap > 0 && heightGap > 0) {
                const gap = Math.min(topGap, heightGap, 2)
                setScrollerPosition({
                    top: top - gap,
                    left,
                    height: height - CONTAINER_STYLE.paddingBottom - CONTAINER_STYLE.paddingTop + gap,
                })
            } else if (heightGap <= 0) {
                setBottomScrollArrowShow(false)
            } else {
                node.scrollTo({ top: node.scrollTop + 2 })
            }
        } else if (node) {
            node.scrollTo({ top: node.scrollTop + 2 })
        }
    }, false)

    const [StopScrollUp, StartScrollUp] = useRafLoop(() => {
        const node = scrollRef.current
        const container = containerRef?.current
        if (node && container) {
            const { top, left, bottom, height } = container.getBoundingClientRect()
            const bottomGap = window.innerHeight - bottom - MARGIN_BOTTOM
            const heightGap = node.scrollHeight + CONTAINER_STYLE.paddingBottom + CONTAINER_STYLE.paddingTop - height
            if (bottomGap > 0 && heightGap > 0) {
                const gap = Math.min(bottomGap, heightGap, 2)
                setScrollerPosition({
                    top,
                    left,
                    height: height - CONTAINER_STYLE.paddingBottom - CONTAINER_STYLE.paddingTop + gap,
                })
            } else {
                node.scrollTo({ top: node.scrollTop - 2 })
            }
        } else if (node) {
            node.scrollTo({ top: node.scrollTop - 2 })
        }
    }, false)

    const onScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
        const scrollTop = event.currentTarget.scrollTop
        const scrollHeight = event.currentTarget.scrollHeight
        const clientHeight = event.currentTarget.clientHeight
        if (scrollTop === 0) {
            setTopScrollArrowShow(false)
        } else if (scrollHeight === scrollTop + clientHeight) {
            setBottomScrollArrowShow(false)
        } else {
            setTopScrollArrowShow(true)
            setBottomScrollArrowShow(true)
        }
    }, [])

    return {
        scrollerPosition,
        topScrollArrowShow,
        bottomScrollArrowShow,
        setTopScrollArrowShow,
        setBottomScrollArrowShow,
        onScroll,
        StartScrollUp,
        StopScrollUp,
        StartScrollDown,
        StopScrollDown,
    }
}
