import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { MonoIconMenuColorVariable12, MonoIconMenuNumberVariable12 } from '../../../../../../ui-lib/src'
import classes from './library-remote-change-list-item-icon.module.less'

function VariableIcon({ type }: { type: Wukong.DocumentProto.VariableResolvedDataType }) {
    switch (type) {
        case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR:
            return <MonoIconMenuColorVariable12 />
        case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT:
            return <MonoIconMenuNumberVariable12 />
        default:
            return null
    }
}

export function VariableIconBlock({
    type,
    className,
}: {
    type: Wukong.DocumentProto.VariableResolvedDataType
} & React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div className={classNames(classes['icon-block'], className)}>
            <VariableIcon type={type} />
        </div>
    )
}
