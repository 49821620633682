import { useState, useEffect } from 'react'
import type { BaseCommonRequest } from './base-request'

/**
 * NOTE:
 * 1. 每次request变化会导致重新请求，使用时尽量用useMemo来生成request
 * 1. 目前的实现比较简单, 没有提供错误处理、重试、刷新等机制
 *
 * 使用方法:
 * ```tsx
 *  function FontList() {
 *      const request = useMemo(() => new QueryFontsRequest({ systemFonts: 1 }), [])
 *      const { vo } = useBaseCommonRequest(request, [])
 *      return <div>{vo}</>
 *  }
 * ```
 * @param request 发生变更时触发重新请求
 * @param init 初始值 请求未返回时的默认值, 仅首次调用传入的值有效
 * @returns
 */
export function useBaseCommonRequest<V, I>(request: BaseCommonRequest<V>, init: I) {
    const [vo, setVo] = useState<V | I>(init)
    useEffect(() => {
        let aborted = false

        request
            .start()
            .then((resp) => {
                if (aborted) {
                    return
                }
                setVo(resp)
            })
            .catch((e) => {
                console.error('[useBaseCommonRequest] request failed:', request, e)
            })

        return () => {
            aborted = true
        }
    }, [request])
    return { vo }
}
