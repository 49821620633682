import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    RunLastPlugin: '运行最近一次启用',
    DevPrivatePlugin: '开发企业私有插件...',
    RunPluginFailed: '插件运行失败，请检查后再试',
    OpenConsole: '打开控制台',
} as const

export const enTranslation = {
    RunLastPlugin: 'Run last plugin',
    DevPrivatePlugin: 'Develop private organization plugin...',
    RunPluginFailed: 'An error occurred while running the plugin. Check plugin files and try again.',
    OpenConsole: 'Show console',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
