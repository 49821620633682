import { isEqual } from 'lodash-es'
import {
    ComponentGetVO,
    LibraryId,
    StyleGetVO,
    VariableGetVO,
    VariableSetGetVO,
} from '../../../../kernel/interface/component-style'
import type { LibraryContentVO } from '../../../../kernel/interface/library'

function isComponentGetVOEqual(vo1: ComponentGetVO, vo2: ComponentGetVO): boolean {
    const attrNames: (keyof ComponentGetVO)[] = [
        'id',
        'removed',
        'contentHash',
        'containingPageId',
        'containingPageName',
        'containingFrameBackgroundColor',
        'containingFrameId',
        'containingFrameName',
    ]

    for (const attrName of attrNames) {
        if (vo1[attrName] != vo2[attrName]) {
            return false
        }
    }

    return true
}

function isStyleGetVOEqual(vo1: StyleGetVO, vo2: StyleGetVO): boolean {
    const attrNames: (keyof StyleGetVO)[] = ['id', 'removed', 'contentHash', 'sortPosition']

    for (const attrName of attrNames) {
        if (vo1[attrName] != vo2[attrName]) {
            return false
        }
    }

    return true
}

function isVariableSetGetVOEqual(vo1: VariableSetGetVO, vo2: VariableSetGetVO): boolean {
    const attrNames: (keyof VariableSetGetVO)[] = ['id', 'removed', 'contentHash']

    for (const attrName of attrNames) {
        if (vo1[attrName] != vo2[attrName]) {
            return false
        }
    }

    return true
}

function isVariableGetVOEqual(vo1: VariableGetVO, vo2: VariableGetVO): boolean {
    const attrNames: (keyof VariableGetVO)[] = ['id', 'removed', 'contentHash', 'sortPosition']

    for (const attrName of attrNames) {
        if (vo1[attrName] != vo2[attrName]) {
            return false
        }
    }

    return true
}

export function isLibraryContentEqual(item1: LibraryContentVO, item2: LibraryContentVO): boolean {
    if (
        item1.library.id !== item2.library.id ||
        item1.library.shared !== item2.library.shared ||
        item1.library.shareScope !== item2.library.shareScope ||
        item1.library.document?.name !== item2.library.document?.name ||
        item1.components.length !== item2.components.length ||
        item1.styles.length !== item2.styles.length ||
        item1.componentSets.length !== item2.componentSets.length ||
        item1.variableSets.length !== item2.variableSets.length ||
        item1.variables.length !== item2.variables.length ||
        !isEqual(item1.libraryMovedInfos.componentMoveRemappings, item2.libraryMovedInfos.componentMoveRemappings) ||
        !isEqual(item1.libraryMovedInfos.styleMoveRemappings, item2.libraryMovedInfos.styleMoveRemappings)
    ) {
        return false
    }

    for (const index in item1.styles) {
        if (!isStyleGetVOEqual(item1.styles[index], item2.styles[index])) {
            return false
        }
    }

    for (const index in item1.components) {
        if (!isComponentGetVOEqual(item1.components[index], item2.components[index])) {
            return false
        }
    }

    // 对应 componentSetId2ChildrenComponents 不用比较（因为会有 componentSet 的 contentHash 变化）
    for (const index in item1.componentSets) {
        if (!isComponentGetVOEqual(item1.componentSets[index], item2.componentSets[index])) {
            return false
        }
    }

    for (const index in item1.variableSets) {
        if (!isVariableSetGetVOEqual(item1.variableSets[index], item2.variableSets[index])) {
            return false
        }
    }
    for (const index in item1.variables) {
        if (!isVariableGetVOEqual(item1.variables[index], item2.variables[index])) {
            return false
        }
    }

    return true
}

export function isLibraryContentMapEqual(
    map1: Map<LibraryId, LibraryContentVO>,
    map2: Map<LibraryId, LibraryContentVO>
): boolean {
    if (map1.size !== map2.size) {
        return false
    }

    for (const [id, item] of map1.entries()) {
        if (!map2.has(id) || !isLibraryContentEqual(item, map2.get(id)!)) {
            return false
        }
    }

    return true
}
