import { translation } from './stroke-join.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { IconStrokeJoinBevel, IconStrokeJoinMiter, IconStrokeJoinRound, Tooltip } from '../../../../../../../ui-lib/src'
import { useCallback, useMemo } from 'react'
import { SelectIconGroup } from '../../../atom/button/select-button-group'
import { SingleGrid } from '../../../atom/grid/single-grid'
import style from '../advance-stroke/advance-stroke.module.less'
import { useStrokeJoin } from './use-stroke-join'

// 描边-{translation('Join')}
export function StrokeJoin() {
    const { joinState, onChangeStrokeJoin } = useStrokeJoin()

    const onClickIcon = useCallback(
        (value: Wukong.DocumentProto.StrokeJoin) => {
            onChangeStrokeJoin(value)
        },
        [onChangeStrokeJoin]
    )

    const optionValue = useMemo(() => {
        return [
            Wukong.DocumentProto.StrokeJoin.STROKE_JOIN_MITER,
            Wukong.DocumentProto.StrokeJoin.STROKE_JOIN_BEVEL,
            Wukong.DocumentProto.StrokeJoin.STROKE_JOIN_ROUND,
        ]
    }, [])

    return (
        <SingleGrid className={style.grid}>
            <SingleGrid.Item
                start={5}
                span={28}
                className={
                    joinState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_DISABLED
                        ? style.disabled
                        : undefined
                }
            >
                {translation('Join')}
            </SingleGrid.Item>
            <SingleGrid.Item start={35} span={22}>
                <SelectIconGroup
                    onClickIcon={onClickIcon}
                    optionValue={optionValue}
                    disabled={
                        joinState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_DISABLED
                    }
                >
                    <Tooltip title={translation('Miter')}>
                        <SelectIconGroup.Item
                            value={Wukong.DocumentProto.StrokeJoin.STROKE_JOIN_MITER}
                            icon={<IconStrokeJoinMiter />}
                            selected={joinState?.value === Wukong.DocumentProto.StrokeJoin.STROKE_JOIN_MITER}
                        />
                    </Tooltip>
                    <Tooltip title={translation('Bevel')}>
                        <SelectIconGroup.Item
                            value={Wukong.DocumentProto.StrokeJoin.STROKE_JOIN_BEVEL}
                            icon={<IconStrokeJoinBevel />}
                            selected={joinState?.value === Wukong.DocumentProto.StrokeJoin.STROKE_JOIN_BEVEL}
                        />
                    </Tooltip>
                    <Tooltip title={translation('Round')}>
                        <SelectIconGroup.Item
                            value={Wukong.DocumentProto.StrokeJoin.STROKE_JOIN_ROUND}
                            icon={<IconStrokeJoinRound />}
                            selected={joinState?.value === Wukong.DocumentProto.StrokeJoin.STROKE_JOIN_ROUND}
                        />
                    </Tooltip>
                </SelectIconGroup>
            </SingleGrid.Item>
        </SingleGrid>
    )
}
