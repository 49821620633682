import { zodResolver } from '@hookform/resolvers/zod'
import type { FC } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { WKButton, WKDialog, WKTextButton, WKToast, WKTypography } from '../../../../../../../ui-lib/src'
import { WKForm, WKFormItem } from '../../../../../../../ui-lib/src/components/wk-form'
import { FormCompPasswordInput } from '../../../../../../../ui-lib/src/components/wk-form/form-component'
import { UpdatePassword } from '../../../../../kernel/request/account'
import { RequestResponseErrorHandler } from '../../../../../kernel/request/error-handler'
import { AccountManage } from '../../../frog-task/account-manage'
import { translation } from './index.translation'

interface PasswordDialogProps {
    closePasswordDialog: () => void
    openRestPassword: () => void
}

const ErrorMsgMap: Record<number, string> = {
    40000: translation('IncorrectPassword'),
    40010: translation('ThisEmailIs'),
}
const passwordRegexp = /^(?=.*[a-zA-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z]).*$/

const passwordSchema = z
    .string()
    .min(8, translation('password-too-short'))
    .regex(passwordRegexp, translation('password-must-include-two-of-digits-uppercase-lowercase'))
export const PasswordDialog: FC<PasswordDialogProps> = ({ closePasswordDialog, openRestPassword }) => {
    const schema = z
        .object({
            oldPassword: z.string().min(1, translation('password-too-short')),
            newPassword: passwordSchema,
            newPasswordAgin: z.string(),
        })
        .superRefine((data, ctx) => {
            if (data.newPassword !== data.newPasswordAgin) {
                ctx.addIssue({
                    path: ['newPasswordAgin'],
                    code: z.ZodIssueCode.custom,
                    message: translation('reset-password-not-match'),
                })
            }
        })

    type PasswordDialogFormData = z.infer<typeof schema>
    const {
        control,
        handleSubmit,
        setError,
        formState: { isValid },
    } = useForm<PasswordDialogFormData>({
        resolver: zodResolver(schema),
        mode: 'onChange',
    })

    const handleOk = async (data: PasswordDialogFormData) => {
        try {
            await new UpdatePassword({ newPassword: data.newPassword, oldPassword: data.oldPassword }).start()
            AccountManage.changePasswordSuccess()
            WKToast.show(translation('PasswordChanged'))
            closePasswordDialog()
        } catch (e) {
            const code = RequestResponseErrorHandler(e).businessStatus
            setError('oldPassword', { message: ErrorMsgMap[code] ?? translation('FailedToChange') })
        }
    }

    return (
        <WKDialog
            width={400}
            hideFooterLine={true}
            bodyStyle={{ padding: 0 }}
            cancelIconProps={{ 'data-testid': 'close-icon' } as any}
            title={translation('ChangePassword')}
            onCancel={closePasswordDialog}
            visible
            footer={null}
        >
            <div>
                <div className="px-6 pt-6">
                    <WKForm id="password-dialog-form" onSubmit={handleSubmit(handleOk)}>
                        <WKFormItem>
                            <FormCompPasswordInput
                                inputProps={{
                                    placeholder: translation('CurrentPassword'),
                                    dataTestIds: {
                                        input: 'old-password-input',
                                    },
                                }}
                                name="oldPassword"
                                control={control}
                            />
                        </WKFormItem>
                        <WKFormItem>
                            <FormCompPasswordInput
                                inputProps={{
                                    placeholder: translation('NewPassword'),
                                    dataTestIds: {
                                        input: 'new-password-input',
                                    },
                                }}
                                name="newPassword"
                                control={control}
                            />
                        </WKFormItem>
                        <WKFormItem>
                            <FormCompPasswordInput
                                inputProps={{
                                    placeholder: translation('ConfirmPassword'),
                                    dataTestIds: {
                                        input: 're-new-password-input',
                                    },
                                }}
                                name="newPasswordAgin"
                                control={control}
                            />
                        </WKFormItem>
                    </WKForm>
                </div>
                <div className="px-6 py-4 flex justify-between items-center">
                    <div className="flex-1">
                        <WKTypography.Text color="placeholder">{translation('ForgotPassword')}</WKTypography.Text>
                        <WKTextButton
                            size={12}
                            type="primary"
                            onClick={() => {
                                openRestPassword()
                                AccountManage.changePasswordInAccountManage()
                            }}
                        >
                            {translation('ResetPassword')}
                        </WKTextButton>
                    </div>
                    <div className="flex items-center">
                        <WKButton type="secondary" onClick={closePasswordDialog}>
                            {translation('Cancel')}
                        </WKButton>
                        <WKButton
                            disabled={!isValid}
                            formId="password-dialog-form"
                            data-testid="reset-password-confirm"
                            htmlType="submit"
                            type={'primary'}
                        >
                            {translation('Submit')}
                        </WKButton>
                    </div>
                </div>
            </div>
        </WKDialog>
    )
}
