import { forwardRef } from 'react'
import {
    TriggerEmpty as SelectTriggerEmpty,
    TriggerEmptyProps as SelectTriggerEmptyProps,
    TriggerEmptyRef as SelectTriggerEmptyRef,
} from '../../selects/triggers/trigger-empty'

export interface TriggerEmptyProps extends SelectTriggerEmptyProps {}
export interface TriggerEmptyRef extends SelectTriggerEmptyRef {}

function _TriggerEmpty(props: TriggerEmptyProps, ref?: React.Ref<TriggerEmptyRef>) {
    return (
        <SelectTriggerEmpty ref={ref} {...props}>
            {props.children}
        </SelectTriggerEmpty>
    )
}

export const TriggerEmpty = forwardRef(_TriggerEmpty)
