/* eslint-disable no-restricted-imports */
import { useViewState } from '../../../../../../view-state-bridge'
import { Wukong } from '@wukong/bridge-proto'

export function useMaskState() {
    return useViewState('maskPanelState', {
        show: false,
        checked: Wukong.DocumentProto.MixedCheckState.MIXED_CHECK_STATE_FALSE,
    })
}
