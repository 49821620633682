import classNames from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode, useMemo } from 'react'
import { TabElement } from '../tab-element'
import styles from './text-button.module.less'

export interface WKTextButtonProps extends HTMLAttributes<HTMLButtonElement> {
    children: ReactNode
    disabled?: boolean
    htmlType?: 'button' | 'submit' | 'reset'
    type: 'primary' | 'secondary' | 'danger'
    size: 12 | 14 | 16
    icon?: ReactNode
    testId?: string
}
export const WKTextButton = forwardRef(function Component(
    props: WKTextButtonProps,
    ref?: React.Ref<HTMLButtonElement>
) {
    const { disabled, icon, children, size, className, type, htmlType, testId, ...rest } = props
    const _size = useMemo(() => {
        switch (size) {
            case 12:
                return 'wk-text-12'
            case 14:
                return 'wk-text-14'
            case 16:
                return 'wk-text-16'
        }
    }, [size])

    const iconSize = useMemo(() => {
        switch (size) {
            case 12:
            case 14:
                return 'w-4 h-4'
            case 16:
                return 'w-6 h-6'
        }
    }, [size])

    return (
        <button
            ref={ref}
            data-testid={testId}
            type={htmlType}
            tabIndex={-1}
            disabled={disabled}
            className={classNames(
                'p-0 m-0 rounded-3px bg-transparent border-none inline-flex items-center outline-none',
                _size,
                styles[type],
                className
            )}
            {...rest}
        >
            <TabElement disabled={disabled}></TabElement>
            {icon && <span className={classNames('inline-block mr-1', iconSize)}>{icon}</span>}
            {children}
        </button>
    )
})
