import { translation } from './view-history-tool.translation'
/* eslint-disable no-restricted-imports */
import {
    MonoIconToolbarGripper24,
    MonoIconToolbarInspect24,
    MonoIconToolbarMove24,
    WKButton,
} from '../../../../../../ui-lib/src'
import { EditorMode } from '../../../../document/node/node'
import { useLeaveHistoryMode } from '../../../../main/history-mode'
import { Switch } from '../../atom/switch/switch'
import { getEditorModeName, getEditorModeShortCut } from '../utils'
import style from './toolbar.module.less'
import { useToolbar } from './use-toolbar'

export function DesignModeViewHistoryTool() {
    const { editorMode, onSwitchChange, viewHistoryDone } = useToolbar()

    const leaveHistoryMode = useLeaveHistoryMode()

    return (
        <Switch active={editorMode} onSwitchChange={onSwitchChange}>
            <Switch.Item
                className={style.switchItem44}
                key={EditorMode.EM_Move}
                tooltipTitle={getEditorModeName(EditorMode.EM_Move)}
                tooltipShortcut={getEditorModeShortCut(EditorMode.EM_Move)}
                testid="history-move"
            >
                <MonoIconToolbarMove24 />
            </Switch.Item>
            <Switch.Item
                className={style.switchItem44}
                key={EditorMode.EM_HandTool}
                tooltipTitle={getEditorModeName(EditorMode.EM_HandTool)}
                tooltipShortcut={getEditorModeShortCut(EditorMode.EM_HandTool)}
                testid="history-handtool"
            >
                <MonoIconToolbarGripper24 />
            </Switch.Item>
            <div className={style.doneButton} onClick={leaveHistoryMode} data-testid="leave-history-button">
                <WKButton onClick={viewHistoryDone} type="primary">
                    {translation('Back')}
                </WKButton>
            </div>
        </Switch>
    )
}

export function DevModeViewHistoryTool() {
    const { editorMode, onSwitchChangeForDevMode, viewHistoryDoneForDevMode } = useToolbar()

    const leaveHistoryMode = useLeaveHistoryMode()

    return (
        <Switch active={editorMode} onSwitchChange={onSwitchChangeForDevMode}>
            <Switch.Item
                className={`${style.switchItem44}`}
                key={EditorMode.EM_DevModeInspect}
                tooltipTitle={getEditorModeName(EditorMode.EM_DevModeInspect)}
                tooltipShortcut={getEditorModeShortCut(EditorMode.EM_DevModeInspect)}
                testid="dev-mode-history-inspect"
            >
                <MonoIconToolbarInspect24 />
            </Switch.Item>
            <div className={style.doneButton} onClick={leaveHistoryMode} data-testid="dev-mode-leave-history-button">
                <WKButton onClick={viewHistoryDoneForDevMode} type="primary">
                    {translation('Back')}
                </WKButton>
            </div>
        </Switch>
    )
}
