import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    CreateTextProp: '创建文本属性',
    ApplyTextProp: '应用文本属性',
    CreateProp: '创建属性...',
} as const

export const enTranslation = {
    CreateTextProp: 'Create text property',
    ApplyTextProp: 'Apply text property',
    CreateProp: 'Create property...',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
