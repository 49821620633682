import classNames from 'classnames'
import { isEqual, uniqWith } from 'lodash-es'
import { useViewState } from '../../../../view-state-bridge'
import { useRenderColorSpace } from '../../color-profile'
import { ColorModePicker } from '../../dev-mode/inspect/dev-mode-inspect-fill/color-mode-picker'
import { StyleColorsV2 } from '../comp/style-colors-v2'
import { UnStyleColorsV2 } from '../comp/un-style-colors-v2'
import { translation } from './index.translation'

export function InspectColor() {
    const viewState = useViewState('inspectSelectionFillViewState', {
        show: false,
        inspectSelectionFillItems: [],
    })
    const colorSpace = useRenderColorSpace()

    const inspectSelectionFillItems = uniqWith(viewState.inspectSelectionFillItems, isEqual)
    // 没有使用样式
    const isUnStyle = !inspectSelectionFillItems.some((o) => o.styleName)

    if (!viewState.show) {
        return null
    }

    return (
        <div
            style={{ boxShadow: '0px -1px 0px var(--wk-gray-13-6)' }}
            className={classNames('px-4 pt-2 pb-3')}
            data-testid="design-mode-inspect-fill"
        >
            <div className={classNames('flex items-center py-1.5', 'mb-2')}>
                <span className="wk-text-12 wk-font-semibold mr-auto" data-testid="inspect-fill">
                    {translation('Fill')}
                </span>
                <ColorModePicker></ColorModePicker>
            </div>
            {isUnStyle && (
                <UnStyleColorsV2
                    paints={inspectSelectionFillItems.map((o) => o.paints).flat()}
                    colorSpace={colorSpace}
                    variables={inspectSelectionFillItems.map((o) => o.variables).flat()}
                    maxWidth={216}
                />
            )}
            {!isUnStyle && <StyleColorsV2 items={inspectSelectionFillItems} colorSpace={colorSpace} maxWidth={216} />}
        </div>
    )
}
