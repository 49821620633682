/* eslint-disable no-restricted-imports */
import { featureSwitchManager } from '../../../../../kernel/switch/core'
import { readResponse, trackResponseProgress } from './fetch-with-progress'
import { unsplashService } from './unsplash-api'

export interface ImageUrl {
    downloadUrl: string
    trackUrl?: string
}

export function downloadImages(
    imageUrls: ImageUrl[],
    progressHandler?: (num: number, index?: number) => void
): Promise<Blob>[] {
    if (!imageUrls.length) {
        return []
    }

    if (featureSwitchManager.isEnabled('feat-report-to-unsplash')) {
        // Report the event of downloading images to Unsplash
        imageUrls.map((imageUrl) => {
            if (imageUrl.trackUrl) {
                unsplashService.trackDownload(imageUrl.trackUrl)
            }
        })
    }

    const progresses: number[] = []
    const setProgress = (p: number, idx?: number) => {
        progresses[idx ? idx : 0] = p
        const sum = progresses.reduce((partialSum, a) => partialSum + a, 0)

        if (progressHandler) {
            progressHandler(sum / imageUrls.length)
        }
    }

    return imageUrls.map(async (imageUrl, index) => {
        const response = await fetch(imageUrl.downloadUrl)
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const trackedResponse = trackResponseProgress(response, (progress) => {
            setProgress(progress.loaded / progress.total!, index)
        })
        return await readResponse(trackedResponse)
    })
}
